import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { Button } from 'shared/FormControl';
import { push } from 'connected-react-router';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483100;
  top: 0;
  left: 0;

  .overdueAnnuallyOwner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: #fff;
    border-radius: 5px;
    z-index: 2147483100;
    padding: 30px;

    position: relative;

    &__content {
      margin-bottom: 30px;
      width: 360px;

      &--title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        color: #000000;
        margin-bottom: 15px;
      }

      &--description {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #000000;

        opacity: 0.6;
      }
    }

    &__closeButton {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      cursor: pointer;
    }
  }
`;

function OverdueAnnuallyOnwer({user, teamData, ...props}) {

  const path = window.location.pathname;

  if (path.includes('/home/billing')) {
    return null;
  }

  const goToBilling = () => {
    props.push('/home/billing');
  };

  const goToHome = () => {
    props.push('/home/client');
  };

  return (
    <Wrapper >
      <div className="overdueAnnuallyOwner">
        <img onClick={goToHome} className="overdueAnnuallyOwner__closeButton" src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        <div className="overdueAnnuallyOwner__content">
          <div className="overdueAnnuallyOwner__content--title">
            Your payment is overdue
          </div>
          <div className="overdueAnnuallyOwner__content--description">
            <span>Please update your payment method to continue using the Automation add-on.</span>
          </div>
        </div>
        <div className="overdueAnnuallyOwner__logout">
          <Button onClick={goToBilling} purple >Update payment info</Button>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  const {
    user,
    rootReducer,
    router
  } = state;

  return {
    user,
    shouldSelectPlan: rootReducer.pricing.get('shouldSelectPlan'),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    locatoin: router.location
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: bindActionCreators(push, dispatch)
  };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(OverdueAnnuallyOnwer);