import { difference, get, map, union } from 'lodash';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeQueryParams, updateSelectedTask } from 'redux/tasks-library/actions';
import { Checkbox } from 'shared/FormControl';
import * as Layout from 'shared/LibraryLayout';
import { columns, NO_LOADING, MOST_RECENT_KEY, NO_LOAD_MORE } from '../constants';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';

import * as S from '../styles';

function View(props) {
  const { total, sorter, sort, changeQueryParams, selectedTasks, list, updateSelectedTask } = props;
  const ids = list ? map(list, '_id') : [];
  const isSelectedAll = selectedTasks && selectedTasks.length && difference(ids, selectedTasks).length === 0;
  const isHasSelectedPrev = selectedTasks && selectedTasks.length > 0;

  const onSort = key => {
    changeQueryParams &&
      changeQueryParams(
        {
          sorter: key,
          sort: key === sorter ? sort * -1 : key === MOST_RECENT_KEY ? -1 : 1,
        },
        NO_LOAD_MORE,
        NO_LOADING,
      );
  };

  const renderSortIcon = key => {
    if (key !== sorter) {
      return <ArrowDown className="column-symbol arrow" />;
    }

    let arrow = '';

    if (sorter === MOST_RECENT_KEY) {
      arrow = sort === 1 ? 'up' : 'down';
    } else {
      arrow = sort === -1 ? 'up' : 'down';
    }

    return arrow === 'up' ? (
      <ArrowUp className="column-symbol arrow" active />
    ) : (
      <ArrowDown className="column-symbol arrow" active />
    );
  };

  const handleSelectAllTask = e => {
    if (e.target && e.target.checked) {
      updateSelectedTask && updateSelectedTask(union([...selectedTasks, ...ids]));
    } else {
      updateSelectedTask && updateSelectedTask(selectedTasks.filter(id => !ids.includes(id)));
    }
  };

  return (
    <Layout.TableHeader>
      <Layout.TableRow>
        <S.TasksHeader key="title">
          <S.TasksTableHeader>
            <S.CheckBoxContainer isHasSelectedPrev={isHasSelectedPrev}>
              <div className="checkbox__header">
                <Checkbox checked={isSelectedAll} onChange={handleSelectAllTask} />
              </div>
            </S.CheckBoxContainer>
            <S.TasksTableCell active={sorter === 'title' && total !== 0} onClick={() => onSort('title')}>
              <Layout.CellTitle>Tasks ({total})</Layout.CellTitle>
              {renderSortIcon('title')}
            </S.TasksTableCell>
          </S.TasksTableHeader>
        </S.TasksHeader>
        {map(columns, item => {
          return (
            <S.TasksHeader key={item.key} alignCenter={item.alignCenter}>
              <S.TasksTableHeader active={sorter === item.key && total !== 0} onClick={() => onSort(item.key)}>
                {get(item, 'key', '') === 'last_activity_at' ? (
                  <S.TasksTitleContent>
                    <Layout.CellTitle>{item.name}</Layout.CellTitle>
                    {renderSortIcon(item.key)}
                  </S.TasksTitleContent>
                ) : (
                  <>
                    <Layout.CellTitle>{item.name}</Layout.CellTitle>
                    {renderSortIcon(item.key)}
                  </>
                )}
              </S.TasksTableHeader>
            </S.TasksHeader>
          );
        })}
        <S.TasksHeader key="actions" className="actions" />
      </Layout.TableRow>
    </Layout.TableHeader>
  );
}

const TableHeader = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      tasksLibrary: {
        list,
        selectedTasks,
        total,
        queryParam: { sorter, sort },
      },
    },
  } = state;

  return { list, selectedTasks, total, sorter, sort };
};

const mapDispatch = dispatch => {
  return {
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    updateSelectedTask: bindActionCreators(updateSelectedTask, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableHeader);
