import Request, { axiosInstance } from 'configs/request';
import _ from 'lodash';
import { toggleModal } from 'actions/modal';
import { showError } from 'actions/error';

export const GET_GOAL = 'GET_GOAL';

export const updateGoal = listData => {
  return dispatch => {
    return Promise.all(listData.map(data => axiosInstance.post('/api/macro/set-macro-goal', data)))
      .then(() => {
        dispatch(toggleModal(false));
      })
      .catch(error => {
        const responseData = _.get(error, 'response.data');

        if (responseData) {
          dispatch(showError(responseData.message));
        } else {
          console.error('Error API Request util ', error);

          if (error.status) {
            // detect Network Error
            dispatch(showError('Error code 999, please contact admin'));
          }
        }
      });
  };
};

export const getClientInfo = clientId => {
  return Request.get(
    { url: `/api/macro/get-macro-metric-data?client=${clientId}` },
    true,
    resp => {
      return resp;
    },
    err => {
      console.error(err);
      return null;
    },
  );
};

export const getGoal = (clientId, type) => {
  return Request.get(
    { url: `/api/macro/get-macro-goal?client=${clientId}${type ? `&type=${type}` : ''}` },
    true,
    resp => {
      return resp.data;
    },
    err => {
      console.error(err);
      return null;
    },
  );
};

export const getReportMacrosDaily = params => {
  return Request.get({ url: '/api/macro/get-macro-day-view', params }, true);
};

export const getReportMacrosWeekly = params => {
  return Request.get({ url: '/api/macro/get-macro-report-by-time', params }, true);
};

export const saveMacroPersonalInfo = data => {
  return Request.post({ url: '/api/macro/save-macro-personal-info', data }, true);
};
