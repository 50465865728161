// Libs
import React, { useRef, useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

// Components
import FileUpload from 'shared/FileUpload';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

// Constants
import { LIMIT_CHARACTER_STEP_INSTRUCTION, RECIPE_DEFAULT_UPLOAD_SETTINGS } from 'components/RecipeDetail/constants';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import { DIMENSIONS_AT_LEAST_WIDTH } from 'components/Recipes/constants';
import {
  createObjectURL,
  getImageDimensionFromUrl,
  getPresignedUploadUrl,
  mediaLog,
  revokeObjectURL,
} from 'utils/commonFunction';
import { validateFiles } from 'utils/validations';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/close_bold_circle.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/MealPlans/upload_icon.svg';

// Styles
import * as S from './style';

const RECIPE_BUILDER_UPLOAD_URL = '/api/file/gen-presigned-urls-meal-plan';

const StepInstructionItem = props => {
  const {
    index,
    content,
    id,
    handleChangeContent = () => {},
    lengthStep,
    handleRemoveStep = () => {},
    handleUploadImgSuccess = () => {},
    handleRemoveUploadImg = () => {},
    image,
    isLoading,
    setUploadLoading,
    hasError,
    isCookingStepValid,
    placeholder,
    isCookingStepValidEmpty,
    errorMsgEmpty = '',
  } = props;

  const fileInputRef = useRef(null);
  const tooltipRef = useRef(null);

  const [open, setOpen] = useState(false);

  // Upload file
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const [fileInfo, setFileInfo] = useState({ width: 0, height: 0 });
  const [localUrl, setLocalUrl] = useState(image);
  const [file, setFile] = useState(image);
  const [uploadConfigs, setUploadConfigs] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    if (tooltipRef && tooltipRef.current && tooltipRef.current.tooltipRef) {
      tooltipRef.current.tooltipRef = null;
    }
    ReactTooltip.hide();
  };

  const handleChooseFile = async event => {
    const { files } = event.target;
    let errorMaxWidth = false;

    if (files.length === 0) {
      return toast.error('Please upload one file at a time');
    }

    if (files.length) {
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: RECIPE_DEFAULT_UPLOAD_SETTINGS.validateExtensions,
        maxSize: RECIPE_DEFAULT_UPLOAD_SETTINGS.maxSize * CONVERSION.MB_TO_BYTE,
      });

      if (!error) {
        const { width: originalWidth = null } = await getImageDimensionFromUrl(createObjectURL(file));
        if (originalWidth < DIMENSIONS_AT_LEAST_WIDTH) {
          errorMaxWidth = true;
        }
      }

      if (error || errorMaxWidth) {
        let message = '';
        if (get(error, 'type') === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = 'File type not supported.';
        } else if (get(error, 'type') === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = `Please upload an image smaller than ${RECIPE_DEFAULT_UPLOAD_SETTINGS.maxSize}MB.`;
        } else if (errorMaxWidth) {
          message = `Image width should be at least ${DIMENSIONS_AT_LEAST_WIDTH}px for best resolution.`;
        }
        toast.error(message);

        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      } else {
        handleSelectFile(file);
      }
    }
  };

  const handleSelectFile = async file => {
    if (file) {
      const { size, name, type } = file;
      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file to S3',
      });
    }
    if (localUrl) {
      revokeObjectURL(localUrl);
    }

    setUploadLoading && setUploadLoading(true);

    setLocalUrl(createObjectURL(file));

    // Get image dimension
    const { width: originalWidth = null, height: originalHeight = null } = await getImageDimensionFromUrl(
      createObjectURL(file),
    );
    setDimension({ width: originalWidth, height: originalHeight });

    // Get presigned url
    const { configs: fileInfo, uploadUrl } = await getPresignedUploadUrl(RECIPE_BUILDER_UPLOAD_URL, file);

    setFileInfo(fileInfo);

    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    });
    setFile(file);
  };

  const handleUploadSuccess = async () => {
    if (file) {
      const { size, name, type } = file;
      await mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload success file via recipe',
      });
    }
    setUploadLoading && setUploadLoading(false);
    setUploadConfigs(null);
    handleUploadImgSuccess(dimension, fileInfo, uploadConfigs);
  };

  const handleCancelUpload = () => {
    revokeObjectURL(localUrl);
    setUploadLoading && setUploadLoading(false);
    setFile(null);
    setLocalUrl(null);
    setUploadConfigs(null);
  };

  const handleRemoveFile = () => {
    if (localUrl) {
      revokeObjectURL(localUrl);
    }
    setUploadConfigs(null);
    setLocalUrl(null);
    setFile(null);
    handleRemoveUploadImg();
  };

  return (
    <S.Item tabIndex={index} onClick={handleOpen} open={open} hasContent={content}>
      <S.Label>STEP {index}</S.Label>
      {lengthStep > 1 && <RemoveIcon className="icon-remove-step" onClick={() => handleRemoveStep(id)} />}
      <RootCloseWrapper onRootClose={handleClose}>
        <S.Content hasError={hasError}>
          <S.TextEditableWrapper
            value={content}
            onChange={value => {
              if ((value && value.length) <= LIMIT_CHARACTER_STEP_INSTRUCTION) {
                handleChangeContent(value, id);
              }
              const truncatedValue =
                (value && value.length) > LIMIT_CHARACTER_STEP_INSTRUCTION
                  ? value.slice(0, LIMIT_CHARACTER_STEP_INSTRUCTION)
                  : value;
              handleChangeContent(truncatedValue, id);
            }}
            placeholder={placeholder}
            breakLine={true}
            maxLength={LIMIT_CHARACTER_STEP_INSTRUCTION}
          />
          {file ? (
            <S.ViewFile>
              <S3ImageWithFallback src={[localUrl]} />
              {!isLoading && <RemoveIcon className="icon-remove" onClick={handleRemoveFile} />}
              <FileUpload
                onSuccess={handleUploadSuccess}
                onError={() => {}}
                onCancel={handleCancelUpload}
                configs={uploadConfigs}
                progressRadius={25}
              />
            </S.ViewFile>
          ) : (
            <>
              <S.UploadIconWrapper data-tip data-for={`step-instruction-${index}`} onClick={handleUploadFile}>
                <UploadIcon />
                <ReactTooltip
                  className="app-tooltip-step-instruction"
                  id={`step-instruction-${index}`}
                  effect="solid"
                  place={'top'}
                  delayShow={100}
                  ref={tooltipRef}
                >
                  Insert an image
                </ReactTooltip>
              </S.UploadIconWrapper>
              <input
                style={{ display: 'none' }}
                type="file"
                ref={fileInputRef}
                onChange={handleChooseFile}
                multiple={false}
                accept={RECIPE_DEFAULT_UPLOAD_SETTINGS.acceptedImage}
              />
            </>
          )}
        </S.Content>
      </RootCloseWrapper>
      {index === 1 && isCookingStepValid && <S.ErrorMsg>Please add at least 1 step</S.ErrorMsg>}
      {isCookingStepValidEmpty && errorMsgEmpty && <S.ErrorMsg>{errorMsgEmpty}</S.ErrorMsg>}
    </S.Item>
  );
};

export default StepInstructionItem;
