import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import BroadcastMessageLeftSide from './LeftSide';
import BroadcastMessageRightSide from './RightSide';
import { CDN_URL, KEY_CODE } from 'constants/commonData';
import { isESCPress, pluralize } from 'utils/commonFunction';
import { toast } from 'react-toastify';
import { FILTER_STATUS } from './constants';
import * as S from './style';

const BroadcastMessageModal = props => {
  const initStateFilter = {
    status: FILTER_STATUS.connected.value,
    owner_ship: 1,
    groups: [],
  };

  const { onClose, toggleConfirmModal, sendBroadcastMessage, isOpenSecondModal } = props;

  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(initStateFilter);
  const [textSearch, setTextSearch] = useState(FILTER_STATUS.all.value);
  const [messages, setMessages] = useState([]);

  const originData = {
    ...initStateFilter,
    selectedClients: [],
    messages: [],
    textSearch: '',
  };

  const resetState = () => {
    setSelectedClients([]);
    setMessages([]);
    selectedFilter(initStateFilter);
  };

  useEffect(() => {
    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, [selectedClients, messages, textSearch, isOpenSecondModal]);

  const onEscPressed = event => {
    if (event.keyCode === KEY_CODE.esc && isESCPress(event.key) && !isOpenSecondModal) {
      handleCloseModal();
    }
  };

  const handleCloseModal = (hideDiscard = false) => {
    if (hideDiscard) {
      onClose();
    } else {
      const newData = {
        ...selectedFilter,
        selectedClients,
        messages,
        textSearch,
      };

      if (!isEqual(originData, newData)) {
        toggleConfirmModal(
          true,
          <S.CustomConfirmModal
            noBorder
            title="Discard Changes?"
            content={`Are you sure you want to go? Changes have not been saved yet.`}
            onConfirm={() => onClose()}
            confirmButtonTitle="Discard changes"
            hasCloseIcon
            headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          />,
        );
      } else {
        onClose();
      }
    }
  };

  const onChangeSelectedClients = clients => {
    setSelectedClients(clients);
  };

  const onChangeSelectedFilter = data => {
    setSelectedFilter(data);
  };

  const onSearchClient = data => {
    setTextSearch(data);
  };

  const handleGetMessages = messages => {
    setMessages(messages);
  };

  const onSubmit = (data, callback, otherCallback) => {
    sendBroadcastMessage &&
      sendBroadcastMessage(data, otherCallback)
        .then(res => {
          if (res) {
            const {
              data: { number_of_clients },
            } = res.data;

            handleCloseModal(true);
            toast(`Broadcasted to ${pluralize('client', number_of_clients, true)}`);
            resetState();
            callback && callback();
          }
        })
        .catch(() => callback && callback());
  };

  return (
    <S.ModalWrapper open={true} closeOnDimmerClick={false}>
      <button className="close-button" onClick={() => handleCloseModal()}>
        <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
      </button>
      <S.ContentWrapper>
        <BroadcastMessageLeftSide
          onChangeSelectedClients={onChangeSelectedClients}
          selectedClientsProp={selectedClients}
          onChangeSelectedFilter={onChangeSelectedFilter}
          onSearchClient={onSearchClient}
          onClose={handleCloseModal}
        />
        <BroadcastMessageRightSide
          selectedClients={selectedClients}
          onChangeSelectedClients={onChangeSelectedClients}
          onGetMessages={handleGetMessages}
          onClose={handleCloseModal}
          onSubmit={onSubmit}
        />
      </S.ContentWrapper>
    </S.ModalWrapper>
  );
};

export default BroadcastMessageModal;
