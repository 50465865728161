import React from 'react';

import * as S from './style';

const AIConfirmModal = ({ onConfirm }) => {
  return (
    <S.CustomConfirmModal
      confirmButtonTitle="I understand"
      content="Turning off this notification means you will not be able to use the AI feature of Workout Builder. If you change your mind, you can always come back to workout library to continue."
      className="ai-beta-agreement-confirm-modal"
      hasCloseIcon
      isCloseOnDimmer={false}
      isPressEsc
      noBorder
      noIcon
      onConfirm={onConfirm}
      title="Workout Builder AI Beta Agreement"
    />
  );
};

export default AIConfirmModal;
