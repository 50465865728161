import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const PurchaseDetailPopupWrapper = styled(Modal)`
  &.ui.modal {
    width: 868px;
  }

  .header {
    border-radius: 8px;
    padding-top: 24px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 15px !important;
    color: #202353 !important;
  }

  .content {
    padding: 30px !important;
    padding-top: 10px !important;
    max-height: 730px;
  }
  &.ui.modal > .header:not(.ui) {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.125px;
  }
`;
