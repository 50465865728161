import React from 'react';
import * as S from './style';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export default function AddProgramArea(props) {
  return (
    <S.Wrapper>
      <div className="collectionArea__content">
        <div className="collectionArea__image">
          <img src={`${CDN_URL}/images/add_studio_program_banner.png`} alt="" />
        </div>
        <div className="collectionArea__info">
          <div className="collectionArea__info--title">Add Studio Programs</div>
          <div className="collectionArea__info--description">
            Add flexible programs your client can start and stop anytime, without you lifting a finger.
          </div>
          <Button className="collectionArea__info--button" onClick={props.onAddStudioProgram}>
            Add a Studio Program
          </Button>
        </div>
      </div>
    </S.Wrapper>
  );
}
