import _ from 'lodash';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import Premium from './Premium';
import Standard from './Standard';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

const getCurrentTab = url => {
  return url.indexOf('advanced-custom-branding') >= 0 ? 1 : 0;
};
class CustomBranding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      currentIndex: 0,
      discard: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const currentActive = getCurrentTab(location.pathname);
    this.setState({
      currentIndex: currentActive,
      activeIndex: currentActive,
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      if (this.props.location.pathname) {
        if (this.props.location.pathname === '/home/custom-branding') {
          this.setState({ activeIndex: 0, currentIndex: 0 });
        } else {
          this.setState({ activeIndex: 1, currentIndex: 1 });
        }
      }
    }
  }

  handleGoBackEvent = () => {
    const { location } = this.props;
    const currentActive = getCurrentTab(location.pathname);
    this.onRedirectToTab(currentActive);
  };

  componentWillUnmount() {
    this.onCheckSaving(false);

    this.props.changeActiveTab(0);
    window.removeEventListener('onpopstate', this.handleGoBackEvent, false);
  }

  /**
   * onCheckSaving: Check Saving to deny
   * @param {*} saving: true/ false
   */
  onCheckSaving = saving => {
    this.setState({ saving });
  };

  handleTabChange = (e, { activeIndex }) => {
    let { currentIndex, saving } = this.state;

    if (saving) {
      currentIndex = currentIndex;
      this.handleBlockedNavigation();
    } else currentIndex = activeIndex;
    this.handleNextUrl(activeIndex);
    this.setState({ activeIndex, currentIndex: currentIndex });
  };

  onRedirectToTab = activeIndex => {
    let { currentIndex, saving } = this.state;

    if (saving) {
      currentIndex = currentIndex;
      this.handleBlockedNavigation();
    } else currentIndex = activeIndex;
    this.handleNextUrl(activeIndex);
    this.setState({ activeIndex, currentIndex: currentIndex });
  };

  handleNextUrl = index => {
    if (index === 0) {
      this.props.push('/home/custom-branding');
    } else {
      this.props.push('/home/advanced-custom-branding/color-themes');
    }
  };

  handleBlockedNavigation = nextLocation => {
    const { saving } = this.state;

    if (saving) {
      this.props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          confirmButtonTitle="Discard changes"
          onConfirm={() => (nextLocation ? this.handleOnDeny(true, nextLocation.pathname) : this.handleOnDeny(true))}
          onDeny={() => (nextLocation ? this.handleOnDeny(false, nextLocation.pathname) : this.handleOnDeny(false))}
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );

      return false;
    }
  };

  handleOnDeny = (status, pathname = null) => {
    this.setState({ discard: status });

    if (pathname) {
      if (status) {
        this.setState({ saving: false }, () => {
          this.props.push(pathname);
        });
      }
    }
  };

  onChangeDiscard = status => {
    const { activeIndex } = this.state;

    this.setState({ discard: status, currentIndex: activeIndex });
  };

  render() {
    const { saving, currentIndex, discard } = this.state;

    return (
      <S.Wrapper>
        <Tab
          activeIndex={currentIndex}
          panes={[
            {
              menuItem: 'Standard',
              render: () => (
                <Tab.Pane>
                  <Standard
                    {...this.props}
                    onCheckSaving={this.onCheckSaving}
                    discard={discard}
                    onChangeDiscard={this.onChangeDiscard}
                    onRedirectToTab={this.onRedirectToTab}
                    premiumCustomBrandPermission={this.props.premiumCustomBrand}
                  />
                </Tab.Pane>
              ),
            },
            {
              ...(process.env.REACT_APP_ENABLE_HIGHER_CUSTOM_BRAND && {
                menuItem: 'Advanced',
                render: () => (
                  <Tab.Pane>
                    <Premium
                      {...this.props}
                      onCheckSaving={this.onCheckSaving}
                      saving={saving}
                      discard={discard}
                      onChangeDiscard={this.onChangeDiscard}
                    />
                  </Tab.Pane>
                ),
              }),
            },
          ]}
          onTabChange={this.handleTabChange}
        />

        <Prompt message={this.handleBlockedNavigation} when={saving} />
      </S.Wrapper>
    );
  }
}

export default CustomBranding;
