import React from 'react';
import { pluralize } from 'utils/commonFunction';
import Avatar from 'react-avatar';
import { getUserShortName } from 'utils/commonFunction';
import SearchGroupAva from 'assets/icons/search-group.svg';
import { FORUM_AUTHOR_ROLE } from 'constants/commonData';
import * as S from './style';

export default ({ innerProps, children, data, isFocused }) => {
  const { ADMIN, COACH } = FORUM_AUTHOR_ROLE;
  let className = 'multi-select__option';
  if (isFocused) {
    className += ' multi-select__option--is-focused';
  }
  const name = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.name;
  let roleLabel = null;
  if (data.role) {
    if (ADMIN.key === data.role) roleLabel = ADMIN.label;
    if (COACH.key === data.role) roleLabel = COACH.label;
  }
  const handleCountClient = value => {
    const label = pluralize('client', value);
    const val = String(value).concat(' ', label);
    return val;
  };

  return (
    <S.Container {...innerProps} className={className}>
      <Avatar
        name={getUserShortName(data)}
        className="client-avatar"
        size="26"
        src={data.typeName === 'group' ? SearchGroupAva : data.avatar}
        color={data.color}
      />
      {data.typeName === 'group' ? (
        <div className="multi-select__option__label">
          <div className="name">
            <b>Group:</b> {name}
          </div>
          <div className="totalMember">{handleCountClient(data.total || 0)}</div>
        </div>
      ) : (
        <div className="multi-select__option__label">
          <div className="name">{name}</div>
          {!data.email.includes('_client@everfit.io') && <div className="email">{data.email}</div>}
        </div>
      )}
      {roleLabel && (
        <div className="multi-select__option__label rightSide">
          <div className="roleLable">{roleLabel}</div>
        </div>
      )}
    </S.Container>
  );
};
