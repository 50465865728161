import { Types } from 'actions/groupMetric';
import { Types as ClientTypes } from 'redux/client/client.actionCreators';

const initialState = {
  list: [],
  total: 0,
  loading: false,
  selected: {},
  textSearch: '',
  isCreateNew: false,
  groupIdBySearchMetric: '',
};

const groupMetric = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ClientTypes.GET_CLIENT_DATA: {
      return {
        list: [],
        total: 0,
        loading: false,
        selected: {},
        textSearch: '',
        isCreateNew: false,
      };
    }
    case Types.MOVE_METRIC_TO_ANOTHER_GROUP_SUCCESS: {
      return Object.assign({}, state, {
        list: payload.list,
        selected: payload.selected,
      });
    }
    case Types.GROUP_METRIC_SELECTED_ITEM: {
      return Object.assign({}, state, { selected: payload, isCreateNew: false });
    }
    case Types.GROUP_METRIC_ORGANIZE_ITEM: {
      return Object.assign({}, state, { list: payload });
    }
    case Types.GROUP_METRIC_FETCH_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.GROUP_METRIC_FETCH_SUCCESS: {
      return Object.assign({}, state, {
        list: payload.list,
        total: payload.total,
        loading: false,
        selected: payload.selected,
        metrics: payload.metrics,
      });
    }

    case ClientTypes.METRIC_UPDATED_SETTING_SUCCESS: {
      const { metric } = payload;
      return Object.assign({}, state, {
        metrics: { ...state.metrics, [metric._id]: metric },
      });
    }

    case Types.GROUP_METRIC_SEARCH_BY_NAME_SUCCESS: {
      return Object.assign({}, state, {
        textSearch: payload.textSearch,
      });
    }
    case Types.GROUP_METRIC_SELECT_GROUP_HAS_METRIC_BY_SEARCH: {
      return Object.assign({}, state, {
        groupIdBySearchMetric: payload.groupId,
      });
    }
    case Types.GROUP_METRIC_UPDATE_METRICS_NORMALIZED: {
      return Object.assign({}, state, { metrics: { ...state.metrics, ...payload } });
    }
    case Types.GROUP_METRIC_FETCH_FAILURE: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.GROUP_METRIC_CREATE_NEW: {
      return Object.assign({}, state, {
        list: payload.list,
        total: payload.total,
        selected: payload.selected,
        isCreateNew: true,
      });
    }
    case Types.GROUP_METRIC_REMOVE_REQUEST: {
      return Object.assign({}, state, {});
    }
    case Types.GROUP_METRIC_REMOVE_SUCCESS: {
      return Object.assign({}, state, { list: payload.list });
    }
    case Types.GROUP_METRIC_REMOVE_FAILURE: {
      return Object.assign({}, state, {});
    }
    case Types.GROUP_METRIC_ADD_METRICS_TO_GROUP_REQUEST: {
      return Object.assign({}, state, {});
    }
    case Types.GROUP_METRIC_ADD_METRICS_TO_GROUP_SUCCESS: {
      return Object.assign({}, state, {
        list: payload.list,
        selected: payload.selected,
      });
    }
    case Types.GROUP_METRIC_ADD_METRICS_TO_GROUP_FAILURE: {
      return Object.assign({}, state, {});
    }
    case Types.GROUP_METRIC_ORGANIZE_ITEM_METRIC: {
      return Object.assign({}, state, {
        list: payload.list,
        selected: payload.selected,
      });
    }
    case Types.GROUP_METRIC_RENAME: {
      return Object.assign({}, state, {
        list: payload.list,
        selected: payload.selected,
      });
    }
    default:
      return state;
  }
};

export default groupMetric;
