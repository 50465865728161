import React from 'react';
import _ from 'lodash';
import { Modal, Button, Icon, Image, Input } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import GeneralButton from 'shared/GeneralButton';
import SearchInput from 'shared/SearchInput';
import ContentEditable from 'shared/ContentEditable';
import { toast } from 'react-toastify';
import ConfirmModal from 'shared/ConfirmModal';
import { pluralize } from 'utils/commonFunction';

import { CDN_URL } from 'constants/commonData';

import './style.scss';

import { ReactComponent as Plus } from 'assets/icons/new_plus_grey_sm.svg';

class ManageGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: '',
      newGroup: null,
      total: 0,
      submitting: false,
      sort: 1,
      error: false,
    };

    this.inputRef = React.createRef();
    this.requestResetValue = React.createRef();
  }

  sortList = (list, unique = false) => {
    const { sort } = this.state;
    const newList = unique ? _.uniqBy(list, '_id') : list.slice();
    return _.orderBy(newList, ['name'], [sort < 0 ? 'desc' : 'asc']);
  };

  onClose = () => this.props.toggleModal(false);

  onChangeGroupName = (group, value) => {
    const name = value.trim();

    if (!name) {
      return toast.error('Please input group name');
    }

    const { updateGroup } = this.props;
    updateGroup(group._id, { name }, () => this.requestResetValue && this.requestResetValue.current());
  };

  onDeleteGroup = group => {
    const { toggleConfirmModal, deleteGroup } = this.props;

    toggleConfirmModal(
      true,
      <ConfirmModal
        title={'Delete Group?'}
        content={`Are you sure you want to delete the group ‘${group.name}’
          ? This group will be removed from ${group.clients} client${group.clients > 1 ? 's' : ''}.
        `}
        onConfirm={() => deleteGroup(group._id)}
        onDeny={() => {}}
        confirmButtonTitle="Delete"
        cancelButtonTitle="Cancel"
      />,
    );
  };

  renderList = () => {
    const { textSearch, sort } = this.state;
    const { groups } = this.props;
    const search = textSearch.trim().toLowerCase();
    const list = search ? _.filter(groups, item => item.name.toLowerCase().includes(search)) : groups;
    const renderedList = this.sortList(list);

    return (
      <div className={`groups-table`}>
        <div className="groups-table__header">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="name-column" onClick={() => this.setState({ sort: sort * -1 })}>
                    <span>Group Name</span>
                    <Icon name={`chevron ${sort < 0 ? 'down' : 'up'}`} style={{ color: '#716de1' }} />
                  </div>
                </th>
                <th>Your Clients</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
          <div className="divide" />
        </div>
        {renderedList.length < 1 ? (
          <div className="no-results-text">No results found</div>
        ) : (
          <div className="groups-table__body">
            <table>
              <thead>
                <tr>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {_.map(renderedList, item => {
                  const disableDelete = item.clients < item.total_clients;

                  return (
                    <tr key={item._id}>
                      <td>
                        <ContentEditable
                          required={true}
                          value={item.name}
                          onChange={e => this.onChangeGroupName(item, e.value)}
                          maxLength={39}
                          requestResetValue={this.requestResetValue}
                        />
                      </td>
                      <td>{pluralize('client', item.clients || 0, true)}</td>
                      <td>
                        <div
                          className={`delete-group${disableDelete ? ' disabled' : ''}`}
                          data-tip
                          data-for={`group-disable-delete-${item._id}`}
                          onClick={() => {
                            if (!disableDelete) {
                              this.onDeleteGroup(item);
                            }
                          }}
                        >
                          Delete
                        </div>
                        {disableDelete && (
                          <ReactTooltip
                            id={`group-disable-delete-${item._id}`}
                            className="app-tooltip delete-group-tooltip"
                            effect="solid"
                            place="top"
                            delayShow="200"
                          >
                            <span>This group is assigned to some other clients in your workspace</span>
                          </ReactTooltip>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  createNewGroup = event => {
    event.preventDefault();
    const { newGroup, submitting } = this.state;
    const { createGroup } = this.props;

    if (!newGroup || submitting) {
      return null;
    }

    const trimmedGroupName = _.get(newGroup, 'name', '').trim();

    if (!trimmedGroupName) {
      this.setState({ error: true });
      return toast.error('Please input group name.');
    }

    this.setState({ submitting: true });
    createGroup(newGroup)
      .then(() => {
        this.setState({ newGroup: null, submitting: false });
      })
      .catch(error => {
        this.setState({ newGroup: newGroup, submitting: false });
      });
  };

  handleChange = (evt, data) => {
    const { newGroup } = this.state;
    this.setState({
      newGroup: {
        ...newGroup,
        name: data.value.length <= 40 ? data.value : newGroup.name,
      },
      error: false,
    });
  };

  handleOpen = () => {
    this.setState({ newGroup: {} }, () => {
      setTimeout(() => {
        if (this.inputRef.current) {
          this.inputRef.current.focus();
        }
      }, 0);
    });
  };

  handleClose = () => this.setState({ newGroup: null, error: false });

  renderAddNewGroup = () => {
    const { newGroup, error } = this.state;

    if (!newGroup) {
      return null;
    }

    return (
      <form className="create-new-group" onSubmit={this.createNewGroup}>
        <div className="input-group">
          <label>Group name</label>
          <Input
            ref={this.inputRef}
            value={newGroup.name}
            placeholder="Enter Group Name"
            onChange={this.handleChange}
            error={error}
          />
        </div>
        <Button type="submit" className="submit">
          Create
        </Button>
        <Button onClick={this.handleClose} className="close-button" type="button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      </form>
    );
  };

  render() {
    const { isModalOpen } = this.props;
    const { textSearch, newGroup } = this.state;

    return (
      <Modal open={isModalOpen} onClose={this.onClose} className="manage-group-modal" closeOnDimmerClick={false}>
        <Modal.Header>
          <div className="title">
            <span>Manage Groups</span>
            <SearchInput
              value={textSearch}
              onChange={(evt, data) => this.setState({ textSearch: data.value })}
              onAddOnIconClick={() => this.setState({ textSearch: '' })}
              placeholder={'Search'}
            />
          </div>
          <Button onClick={this.onClose} className="close-button" type="button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
        </Modal.Header>
        <Modal.Content>
          {this.renderList()}
          {this.renderAddNewGroup()}
        </Modal.Content>
        <Modal.Actions>
          <GeneralButton onClick={this.handleOpen} className="create-button" withImage disabled={!!newGroup}>
            <Plus />
            <span>Create New Group</span>
          </GeneralButton>
          <GeneralButton className="done-button" onClick={this.onClose} disabled={!!newGroup}>
            Done
          </GeneralButton>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ManageGroups;
