import styled from 'styled-components';
import { Button } from 'shared/FormControl';
import CalculateMacrosModalStyles, { HeaderStyles, ContentStyles, ActionsStyles } from '../CalculateMacrosModal/styles';

export const HeaderModalStyles = styled(HeaderStyles)`
  margin: 0 !important;
  padding: 30px 30px 10px !important;
  color: #202353;

  .title {
    margin-left: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
`;

export const AddNoteModalStyles = styled(CalculateMacrosModalStyles)`
  width: 530px !important;

  &.client-macro-note-discard-change {
    width: 460px !important;

    ${HeaderModalStyles} {
      padding: 30px 30px 15px !important;
    }

    .description {
      font-size: 13px;
      line-height: 150%;
    }
    ${Button} {
      width: 102px;
      margin-left: 5px;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
    }

    .confirm-yes-button {
      min-width: 95px;
      border-radius: 4px;
      background-color: #ea314a;
      box-shadow: 0 5px 14px -2px rgba(255, 101, 98, 0.37);
      font-size: 13px;
      font-weight: 600;
      color: #ffffff;
      padding: 5px 24px;
      line-height: 18px;
      border: 1px solid transparent;

      &:hover,
      &:focus {
        background-color: #ea314a;
        box-shadow: 0 5px 14px -2px rgba(255, 101, 98, 0.37);
        color: #ffffff;
      }
    }
  }
`;

export const ContentModalStyles = styled(ContentStyles)`
  padding: 0 30px !important;

  textarea {
    margin-top: 25px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 111px;
    font-size: 13px;
    line-height: 150%;
    padding: 15px;
    border: 1px solid #d6dae4;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;

    &:focus {
      border: 1px solid #5c5bbd;
    }
    &:placeholder {
      color: #000000;
      opacity: 0.3;
    }
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
  }
`;

export const ActionsModalStyles = styled(ActionsStyles)`
  padding: 30px !important;
  box-shadow: none;
  ${Button} {
    width: 106px;
    margin-left: 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
  }
`;

export default styled.div`
  display: flex;
  align-items: center;

  .note-icon {
    cursor: pointer;

    &:hover,
    &.active {
      path {
        stroke: #5158cf;
      }
    }
  }
`;
