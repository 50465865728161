import React from 'react';
import type { Dispatch } from 'types/redux';
import { Button, Modal, Image } from 'semantic-ui-react';
import { toggleConfirmModal } from 'actions/modal';
import { connect } from 'react-redux';
import { CDN_URL } from 'constants/commonData';

import { DowngradeConfirmModal } from './style';

class DowngradeConfirm extends React.Component<Props, State> {
  onClose = () => {
    this.props.dispatch(toggleConfirmModal(false));
  };

  render() {
    return (
      <DowngradeConfirmModal open={this.props.isConfirmModalOpen} className="confirm-popup-container new-style">
        <Modal.Content className="confirm-content">
          <Button onClick={this.onClose} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="confirm-content-header">
            <Image src={`${CDN_URL}/images/downgrade_start_confirm_icon.svg`} className="confirm-header-image" />
            <p className="confirm-header-label">Downgrade to Starter</p>
          </div>
          <div className="confirm-content-body">
            <div className="confirm-content-label">
              For this downgrade, you will receive a credit for the remaining period of your existing plan.
            </div>
            <div className="confirm-content-label confirm-content-label__second">
              Your workspace will lose access to any premium feature, such as:
            </div>
            <div class="confirm-content-body__feature">
              <div>• Program Library</div>
              <div>• Food Journal</div>
              <div>• Custom Branding</div>
              <div>• Habit</div>
            </div>
            <div className="confirm-content-label">Would you like to continue with the downgrade?</div>
          </div>
        </Modal.Content>
        <Modal.Actions className="confirm-actions">
          <Button
            className="confirm-downgrade-button"
            onClick={() => {
              this.props.dispatch(toggleConfirmModal(false));
              this.props.onConfirm();
            }}
          >
            Downgrade
          </Button>
        </Modal.Actions>
      </DowngradeConfirmModal>
    );
  }
}

const mapStateToProps = (state: State, ownProps: Object) => {
  const { isConfirmModalOpen, confirmModal } = state;
  return {
    isConfirmModalOpen,
    confirmModal,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: State) => {
  return {
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DowngradeConfirm);
