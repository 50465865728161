import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
`;

export const HabitOverview = styled.div`
  border-radius: 12px;
  margin-bottom: 15px;
  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 3%);
  border: solid 1px #dde2ed;
  overflow: hidden;
`;

export const Header = styled.h4`
  ${baseText}
  font-size: 15px;
  line-height: 20px;
  color: #273d52;
  padding: 10px 16px;
  background: #fafbfc;
  border-bottom: 1px solid #dde2ed;
  margin-bottom: 0;
`;

export const List = styled.ul`
  margin: 0;
  padding-left: 0;
  max-height: 160px;
  overflow: auto;
`;

export const Item = styled.li`
  padding: 15px 12px 15px 15px;
  border-bottom: 1px solid #dde2ed;
  display: grid;
  grid-template-columns: 32px 1fr 34px;
  gap: 10px;
  position: relative;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  svg {
    border-radius: 50%;
    overflow: hidden;
    background-color: ${props => props.bgColor || '#fff'};
  }
`;

export const Content = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .tooltip-title-habit {
    ${baseText}
    max-width: 228px;
    background: #2d2e2d;
    border-radius: 5px;
    padding: 15px;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #ffffff;
    overflow-wrap: break-word !important;
  }
`;

export const Title = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 100%;
  color: #273d52;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  padding-bottom: 6px;
`;

export const Desc = styled.p`
  ${baseText}
  font-size: 10px;
  line-height: 14px;
  color: #8f99a3;
`;

export const Streak = styled.div`
  ${baseText}
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: #273d52;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const Tooltip = styled(ReactTooltip)`
  ${baseText}
  padding:6px 10px !important;
  border-radius: 5px !important;
  background: #2d2e2d;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
`;

export const TooltipIcon = styled(ReactTooltip)`
  ${baseText}
  padding:6px 10px !important;
  border-radius: 5px !important;
  background: #2d2e2d;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
  .textBold {
    font-weight: 600;
  }
`;

export const TooltipTitle = styled(ReactTooltip)`
  ${baseText}
  padding: 15px !important;
  border-radius: 5px !important;
  background: #2d2e2d;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
  max-width: 228px !important;
`;
