import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import ButtonIcon from 'shared/ButtonIcon';
import FormItemInput from 'shared/FormItemInput';

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;
    svg {
      color: white;
      margin-right: 5px;
    }
    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }
`;

export const ModalSingleField = styled(Modal)`
  &.ui.modal {
    width: 500px;
    height: 216px;
    border-radius: 8px;

    .close-button {
      right: -12px;
      top: -12px;
      img {
        width: 24px;
        height: 24px;
      }
    }

    > :last-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .header {
    color: #000000 !important;
    background: none !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    border-radius: 0;
  }
  .content {
    background: none !important;
    padding: 20px 30px 30px !important;
    border-radius: 0;
  }
  .formControl {
    margin-bottom: 20px;

    > input {
      ${props =>
        props.inputHeight &&
        css`
          height: ${props.inputHeight}px;
        `}

      &::placeholder {
        color: #bebebe;
      }
    }
  }
  .submit {
    padding: 6px 35px !important;

    &:hover {
      background: #5559ff;
    }
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 5px;
  .label__required {
    color: #ea314a;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  .cancel {
    margin-right: 12px;
  }
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Form = styled.form``;

export const Input = styled(FormItemInput)``;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  svg.back-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    &:hover {
      cursor: pointer;
    }
  }
`;
