import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import FormItemInput from 'shared/FormItemInput';
import { CDN_URL } from 'constants/commonData';

import CheckBlue from 'assets/icons/MealPlans/check_blue.svg';

const textBase = `
    color: #202353;
    font-family: 'Open sans';
    font-style: normal;
    line-height: 150%;
`;

const lineClamp1 = `
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`;

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-add-recipe-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 656px;
  }
  &.ui.modal.evf-add-recipe-modal > .header,
  &.ui.modal.evf-add-recipe-modal > .content,
  &.ui.modal.evf-add-recipe-modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  .close-button {
    position: absolute;
    display: flex;
    top: -9.5px;
    right: -8.5px;
    img {
      flex-shrink: 1;
    }
  }
`;

export const HeaderTitle = styled.div`
  ${textBase}
  font-size: 19px;
  font-weight: 600;
  ${lineClamp1}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding: 15px 30px;
  background-color: #ffffff;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  ${textBase}
  color: #7B7E91;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const Hint = styled.div`
  ${textBase}
  color: #7B7E91;
  font-size: 11px;
  font-weight: 400;
  margin-top: 7px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  .formControl {
    margin-bottom: unset;
    > div:first-child {
      color: #7b7e91;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    input {
      padding: 10px;
      border: 1px solid #e1e1ea;
      border-radius: 5px;
      color: #202353;
    }
  }
  .field > .label {
    padding-left: unset;
    margin-bottom: 3px;
    color: #7b7e91;
    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }
  .ui.dropdown.ownership-dropdown {
    position: relative;
    min-height: auto;
    height: 42px;
    border-radius: 5px !important;
    padding: 10px 34px 10px 15px;
    border-color: #e1e1ea !important;
    background-color: #ffffff !important;
    &:hover {
      background-color: #f8f8f8 !important;
    }
    .text {
      ${textBase};
      font-size: 13px;
      font-weight: 400;
      ${lineClamp1}
      white-space: break-spaces !important;
    }
    .dropdown {
      &.icon {
        position: absolute;
        padding: 0;
        top: calc(50% + 7px);
        padding-right: 13px;
        &::before {
          content: '' !important;
          display: block;
          background-size: contain !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          position: absolute;
          background-image: url(${CDN_URL}/images/dropdown-mark-down.svg) !important;
          width: 16px;
          height: 16px;
          top: 50%;
          right: 14px;
          transform: translateY(-50%);
          color: #7b7e91;
        }
      }
    }
    &.has-spacing .menu {
      border: unset !important;
      border-radius: 10px;
      box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
      padding: 10px;
      margin-top: 5px;
      .text {
        font-weight: 400;
      }
      .selected.item .text {
        color: #5158cf;
        font-weight: 600;
      }
    }
  }
  .ui .ownership-header .sb-avatar.sb-avatar--src {
    flex-shrink: 1;
  }
  .ui.selection.visible.dropdown > .text:not(.default) {
    color: #202353;
    font-weight: 400;
  }
  .ui.selection.dropdown .menu > .item {
    display: flex;
    justify-content: space-between;
    border-top: unset;
    padding: 10px !important;
    border-radius: 5px;
    &:hover {
      background: #f0f1ff;
      .text {
        color: #5158cf;
      }
    }
  }
  .ui.dropdown.ownership-dropdown.has-spacing .menu > .item:not(.selected):hover {
    background: #f0f1ff;
  }
  .ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    background: unset;
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url(${CheckBlue});
      flex-shrink: 0;
    }
  }
  .ui.selection.active.dropdown {
    box-shadow: unset !important;
    border-color: #5158cf !important;
  }
  .field.client_type_field.share-with-org {
    margin-top: 17px;
  }
  .field.client_type_field {
    line-height: 15px;
  }
  .ui.dropdown.ownership-dropdown:hover {
    background-color: #fff !important;
    border: 1px solid #5158cf !important;
  }
`;

export const UploadWrapper = styled.div`
  width: 242px;
  height: 161px;
`;

export const Footer = styled.div`
  padding: 15px 30px;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 10px 45px;
  border: none;
  border-radius: 5px;
  ${textBase}
  background: #5158CF;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
  min-width: 130px;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
    background: #eaeef1;
    color: #7b7e91;
  }
`;

export const InputName = styled(FormItemInput)`
  position: relative;
  .label__maxLength {
    ${textBase}
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #7b7e91;
    opacity: 1;
  }
`;
