// libs
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';

// actions
import { axiosInstance } from 'configs/request';
import { toggleModal } from 'actions/modal';
import { setDefaultAppIcon, setAppSystemIcon, resetDefaultAppIcon } from 'redux/custom-branding/actions';

// shared
import FileUpload from 'shared/FileUpload';
import { Button } from 'shared/FormControl';
import UpgradePath from 'shared/UpgradePath';
import { SelectFile } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import AppIconModal from '../AppIconModal';

// utils
import { Mixpanel } from 'utils/mixplanel';
import { THEMES, APP_ICON_STATUS, APP_ICON_DESCRIPTION_STATE, NEW_APP_ICON_FLOW } from '../../constants';
import { convertS3UrlToCloudFrontUrl, createObjectURL, mediaLog, revokeObjectURL } from 'utils/commonFunction';

// assets
import Logo from 'assets/images/app-icon.png';
import LogoSC from 'assets/images/app-icon-onscreen.png';
import { ReactComponent as UploadIcon } from 'assets/icons/upload-icon.svg';
import { ReactComponent as UploadCloseIcon } from 'assets/icons/upload-close.svg';
import { ReactComponent as PublishIcon } from 'assets/icons/publish.svg';
import { ReactComponent as LoaderIcon } from 'assets/icons/loader.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/file-document.svg';

// styles
import * as S from './style';

const checkAppIconSize = (width, height) => {
  return width >= 1024 && height >= 1024;
};

class CustomAppIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadData: {},
      selected: {
        name: '',
        src: Logo,
        defaultLogo: LogoSC,
        _id: '',
      },
      verifyResolution: {
        width: 1024,
        height: 1024,
        message: 'Resolution should be at least 1024px by 1024px.',
      },
      app_icon_key: null,
      app_icon_status: null,
      publisedAppIcon: null,
      isSaving: false,
      openSubmit: false,
      openCancel: false,
      closeUpload: null,
      discard: false,
      loader: false,
      nonSubmit: false,
      isChangeLogo: false,
    };

    this.maxSize = 25;
    this.defaultTheme = THEMES[0];
    this.uploadConfigs = null;
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      // logic update selected src
      const selectedSrc = this.handleGetLogo();
      if (!_.isEmpty(selectedSrc) && !_.isEqual(selectedSrc, _.get(this.state, 'selected.src', null))) {
        this.setState(it => ({
          selected: {
            ...it.selected,
            src: selectedSrc,
          },
        }));
      }

      // todo something
      this.handleCaseURL(this.props);

      // logic discard change
      if (!_.isEqual(this.state.discard, this.props.discard)) this.handleDenyAppIcon(this.props);
    }
  }

  componentWillUnmount() {
    const { resetDefaultAppIcon } = this.props || {};
    typeof resetDefaultAppIcon === 'function' && resetDefaultAppIcon();
  }

  handleDenyAppIcon = data => {
    const { discard, onChangeDiscard, setCurrentIndex, activeIndex, setScreenActive } = data;
    const { getCustomBrandingData, setAppIconUploading } = this.props;
    const { uploadData } = this.state;

    if (discard) {
      this.setState({ discard: true });
      getCustomBrandingData().finally(() => {
        revokeObjectURL(uploadData.src);
        this.uploadConfigs = null;

        this.setState({
          uploadData: {},
          uploading: false,
          selected: {
            name: '',
            src: this.handleGetLogo(),
            _id: '',
          },
          isSaving: false,
          openSubmit: false,
          openCancel: false,
        });

        this.props.onCheckSaving(false);
        onChangeDiscard(false);
        setCurrentIndex(activeIndex);
        setScreenActive(activeIndex);
        setAppIconUploading(null);
      });
    }
  };

  handleGetLogo = () => {
    const { CANCELLED } = APP_ICON_STATUS;
    const { appIcon, appIconReview, appIconUrl, appIconUploading } = this.props;

    if (appIconUploading) {
      return appIconUploading.src || appIconUploading.defaultLogo;
    }
    if (appIconReview) {
      // return appIconReview.status === CANCELLED ? (appIconUrl ? appIconUrl : Logo) : appIconReview.app_icon_url;
      return appIconReview.status === CANCELLED ? Logo : appIconReview.app_icon_url;
    }
    if (appIconUrl) return appIconUrl;
    if (appIcon) return appIcon.src || Logo;
    return Logo;
  };

  componentDidMount() {
    // logic update selected src
    const selectedSrc = this.handleGetLogo();
    if (!_.isEmpty(selectedSrc) && !_.isEqual(selectedSrc, _.get(this.state, 'selected.src', null))) {
      this.setState(it => ({
        selected: {
          ...it.selected,
          src: selectedSrc,
        },
      }));
    }

    // logic todo something
    this.handleCaseURL(this.props);
  }

  /**
   * handleCaseURL: Handle Case Status of AppIcon
   * @param {*} data: custom app icon responsve
   * @param {*} publisedAppIcon:
   * 0: none have change,
   * 1: pending aprroval,
   * 2: re reject/approval,
   * 3: approval,
   * 4: published
   */
  handleCaseURL = data => {
    const { closeUpload } = this.state;
    const { appIcon, appIconReview, appIconUrl } = data;

    if (appIconReview) {
      if (appIcon && appIconUrl) {
        this.handleCaseURLReChangeAppIcon(data);
      } else {
        this.handleCaseURLChangeFirstAppIcon(data);
      }
    } else this.setState({ loader: true });

    // Cancel Upload
    if (closeUpload) this.setState({ isSaving: null });
  };

  handleCaseURLChangeFirstAppIcon = data => {
    let { publisedAppIcon } = this.state;
    const { appIconReview } = data;
    const { PENDING, REJECTED, CANCELLED, APPROVED, REVIEWED, FINAL_REVIEWED } = APP_ICON_STATUS;

    if (appIconReview.status === APPROVED) publisedAppIcon = 3;
    if (appIconReview.status === REJECTED) publisedAppIcon = 2;
    if (
      appIconReview.status === PENDING ||
      appIconReview.status === REVIEWED ||
      appIconReview.status === FINAL_REVIEWED
    )
      publisedAppIcon = 1;
    if (appIconReview.status === CANCELLED) {
      publisedAppIcon = null;
    }

    this.setState({
      publisedAppIcon,
      app_icon_status: data.appIconReview ? data.appIconReview.status : this.props.appIconReview.status,
      loader: true,
    });
  };

  handleCaseURLReChangeAppIcon = data => {
    const { appIcon, appIconReview, appIconUrl } = data;
    const { REVIEWED } = APP_ICON_STATUS;
    let { publisedAppIcon, uploading, selected } = this.state;

    if (appIcon === null) publisedAppIcon = 0;
    if (appIcon === null && appIconReview !== null) publisedAppIcon = 1;
    if (appIconUrl === null && appIcon === null) publisedAppIcon = 2;
    if (appIconUrl !== null) {
      if (appIconReview === null && appIcon === null) publisedAppIcon = 2;
      else {
        if (appIconReview.app_icon_key === appIcon && appIconReview.app_icon_url === appIconUrl) publisedAppIcon = 4;
        else if (typeof uploading === 'undefined') publisedAppIcon = 3;
      }
    }

    if (appIconReview !== null) {
      this.setState({
        app_icon_status: data.appIconReview ? this.props.appIconReview.status : data.appIconReview.status,
      });
    }
    this.setState({ publisedAppIcon, loader: true });
  };

  // TODO - Handle Render Of Button Description (By State)
  handleRenderDescription = status => {
    const { REJECTED, FINAL_REVIEWED } = APP_ICON_STATUS;
    const { reviewedAppIcon } = APP_ICON_DESCRIPTION_STATE;
    const { appIconReview } = this.props;

    if (status === REJECTED) return <S.RejectText>{appIconReview.reason_reject}</S.RejectText>;
    if (status === FINAL_REVIEWED) return <S.FinalReviewText>{reviewedAppIcon}</S.FinalReviewText>;
  };

  // TODO - Handle Render description text by state
  handleRenderNoteOfAppIcon = status => {
    const { newAppIcon, publishedAppIcon, reviewedAppIcon } = APP_ICON_DESCRIPTION_STATE;
    const { PENDING, REJECTED, APPROVED, PUBLISED, REVIEWED, FINAL_REVIEWED } = APP_ICON_STATUS;

    switch (status) {
      case APPROVED:
      case PUBLISED:
      case REVIEWED:
        return <S.Note>{publishedAppIcon}</S.Note>;
      case FINAL_REVIEWED:
        return <S.Note>{reviewedAppIcon}</S.Note>;
      case PENDING:
      case REJECTED:
      default:
        return <S.Note>{newAppIcon}</S.Note>;
    }
  };

  handleRenderTextButton = status => {
    const { PENDING, REJECTED, REVIEWED, FINAL_REVIEWED } = APP_ICON_STATUS;

    switch (status) {
      case PENDING:
      case REVIEWED:
        return 'In Review';
      case FINAL_REVIEWED:
        return 'Final Review';
      case REJECTED:
        return 'Rejected';
      default:
        return 'Submit';
    }
  };

  renderSaveButton = (publisedAppIcon, status) => {
    const { uploading, isSaving, loader } = this.state;
    const { appIconReview } = this.props;
    const { PENDING, REJECTED, FINAL_REVIEWED, APPROVED, PUBLISED } = APP_ICON_STATUS;

    if (publisedAppIcon >= 3 || status === APPROVED || status === PUBLISED) return;
    return (
      <S.SaveButtonArea className={classnames({ hide: !loader })}>
        <S.SaveButton
          className={classnames({
            disable: !isSaving || status === PENDING,
            reject: typeof uploading === 'undefined' && status === REJECTED,
            reviewed: status === FINAL_REVIEWED,
          })}
          onClick={this.onSave}
        >
          {typeof uploading !== 'undefined' && status === REJECTED
            ? this.handleRenderTextButton(null)
            : this.handleRenderTextButton(status)}
        </S.SaveButton>

        {typeof uploading === 'undefined' && this.handleRenderDescription(status)}
      </S.SaveButtonArea>
    );
  };

  onUploadSuccess = response => {
    const { setAppIcon, setAppIconUploading } = this.props;
    const { uploadData } = this.state;
    const { data } = response.data;
    const { originalname, file_extension } = data;
    mediaLog({
      status: 2,
      name: originalname,
      fileType: file_extension,
      description: `Upload success file via App Icon`,
    });

    const backgroundData = { ...(data.workout_background || {}), src: uploadData.src };

    this.uploadConfigs = null;

    this.setState({
      uploadData: {},
      uploading: false,
      selected: { ...backgroundData },
      isSaving: true,
      publisedAppIcon: null,
      closeUpload: null,
    });

    setAppIcon({ ...backgroundData, uploading: false });
    setAppIconUploading({ ...backgroundData, uploading: false });
  };

  onCancelUpload = () => {
    this.handleCancelAppIcon();
  };

  onCancelLoadImage = () => {
    this.setState({ uploadData: {}, closeUpload: true });
    this.props.getCustomBrandingData().finally(() => {
      this.handleCaseURL(this.props);
      this.setState({
        uploadData: {},
        uploading: false,
        selected: {
          name: '',
          src: this.handleGetLogo(),
          _id: '',
        },
        isSaving: false,
        openSubmit: false,
        openCancel: false,
      });
      this.props.setAppIconUploading({
        name: '',
        src: this.handleGetLogo(),
        _id: '',
      });
    });
  };

  onUploadError = () => {
    this.setState({ uploading: false });
  };

  onSelectFile = async file => {
    const { verifyResolution } = this.state;
    const { setAppIcon, uploadFilesCutomBranding } = this.props;
    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via App Icon',
    });
    this.setState({ isSaving: false });
    this.props.onCheckSaving(true);

    // Check resolution size
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = async () => {
      const { width, height } = img;
      if (!checkAppIconSize(width, height)) {
        toast.error(verifyResolution.message);
      } else {
        const uploadData = { src: createObjectURL(file), file, name: file.name };
        const URLS = await uploadFilesCutomBranding({
          fileNames: [file.name],
        });
        this.uploadConfigs = {
          method: 'PUT',
          url: URLS.data.data[0],
          headers: { 'Content-Type': file.type },
          data: file,
        };
        const app_icon_key = this.uploadConfigs.url.substring(
          this.uploadConfigs.url.indexOf('images'),
          this.uploadConfigs.url.indexOf('?X-Amz-Algorithm'),
        );

        // Set State Link - Upload
        this.setState({ uploadData, uploading: true, app_icon_key: app_icon_key });
        setAppIcon({ uploading: true });

        // Send Image File (API)
        await axiosInstance(this.uploadConfigs);
      }
    };
  };

  renderUpload = () => {
    const { uploadData } = this.state;

    if (uploadData.file) {
      const formData = new FormData();

      formData.append('media', uploadData.file);
      const isDefault = uploadData.src === Logo;
      const background = isDefault ? uploadData.src : this.getCloudUrl(uploadData.src);

      return (
        <S.Item empty className={classnames({ default: isDefault })}>
          <S.Background background={background || uploadData.src} />
          <FileUpload
            onSuccess={this.onUploadSuccess}
            onError={this.onUploadError}
            onCancel={this.onCancelLoadImage}
            configs={{ url: '/api/upload/custom_brand', method: 'post', data: formData }}
            progressRadius={34}
            progressBarWidth={3}
            progressBarBackground="black"
          />
        </S.Item>
      );
    }

    return null;
  };

  onSave = () => {
    this.setState({ openSubmit: true });
  };

  onSaveSystem = () => {
    const { isLoading, currentAppIconDefault, setAppSystemIcon, onCheckSaving } = this.props || {};

    if (isLoading) return;

    typeof setAppSystemIcon === 'function' &&
      setAppSystemIcon((currentAppIconDefault || {})._id).finally(() => {
        this.setState({ isChangeLogo: false });
        onCheckSaving(false);
      });
  };

  handleSubmitAppIcon = () => {
    const { app_icon_key, nonSubmit } = this.state;
    const {
      appIconReview,
      getCustomBrandingData,
      splashLogo,
      splashBackground,
      theme,
      originalWelcomeTextColor,
      selectedTodayScreenLogo,
    } = this.props;

    const data = {
      primary_color: theme.primaryColor,
      secondary_color: theme.secondaryColor,
      app_icon_key: app_icon_key,
      app_icon_review: appIconReview,
      splash_logo: splashLogo.bucketData ? splashLogo.bucketData.location : splashLogo.src,
      splash_logo_name: splashLogo.name,
      splash_background: splashBackground.bucketData ? splashBackground.bucketData.location : splashBackground.src,
      splash_background_name: splashBackground.name,
      splash_screen_welcome_text_color: originalWelcomeTextColor,
      today_screen_logo: _.get(selectedTodayScreenLogo, 'src', ''),
      today_screen_file_name: _.get(selectedTodayScreenLogo, 'name', ''),
    };

    const localData = {
      selectedAppIconKey: app_icon_key,
    };

    if (nonSubmit) {
      return;
    }

    this.setState({ nonSubmit: true });
    this.props.updateCustomBrandingPremium(data, localData).finally(() => {
      //Fetch Custombranding
      getCustomBrandingData().finally(() => {
        Mixpanel.track('advance_custom_brand_submit_app_icon');
        this.setState({ isSaving: false, openSubmit: false, publisedAppIcon: 2, nonSubmit: false });
        this.props.onCheckSaving(false);
      });
    });
  };

  handleSubmitCancelIcon = () => {
    const { setAppIconUploading, cancelAppIcon, appIconReview, getCustomBrandingData } = this.props;
    const { uploadData, nonSubmit } = this.state;

    if (nonSubmit) {
      return;
    }

    this.setState({ nonSubmit: true });
    cancelAppIcon(appIconReview._id).then(res => {
      if (res.status === 200) {
        // Fetch Custombranding
        getCustomBrandingData().finally(() => {
          revokeObjectURL(uploadData.src);
          this.uploadConfigs = null;

          this.setState({
            uploadData: {},
            uploading: false,
            app_icon_key: null,
            app_icon_status: null,
            selected: {
              name: '',
              src: Logo,
              _id: '',
            },
            openCancel: false,
            nonSubmit: false,
          });

          setAppIconUploading({
            selected: {
              name: '',
              src: Logo,
              _id: '',
            },
          });
        });
      }
    });
  };

  handlePublishAppIcon = () => {
    const { publishAppIcon, appIconReview } = this.props;
    publishAppIcon(appIconReview._id);
  };

  handleCancelAppIcon = () => {
    this.setState({ openCancel: true });
  };

  handleRenderUploadComponent = app_icon_status => {
    const { uploading, publisedAppIcon, isSaving } = this.state;
    const { PENDING, REJECTED, APPROVED, PUBLISED, REVIEWED, FINAL_REVIEWED } = APP_ICON_STATUS;

    if (publisedAppIcon === null) app_icon_status = null;
    if (publisedAppIcon === 4) app_icon_status = PUBLISED;

    switch (app_icon_status) {
      case PENDING:
        return (
          <S.DragboxUploadElement>
            {this.renderUpload()}

            <S.UploadInput empty data-tip data-for="tooltip--select-custom-background">
              <S.RemoveFile onClick={() => this.onCancelUpload()}>
                <S.IconCloseSpacing>
                  <UploadCloseIcon />
                </S.IconCloseSpacing>

                <S.SelectFileTriggerCancelText>Cancel Submission</S.SelectFileTriggerCancelText>
              </S.RemoveFile>

              {this.handleRenderNoteOfAppIcon(app_icon_status)}
              {this.handleRenderLinkDocument()}
            </S.UploadInput>
          </S.DragboxUploadElement>
        );

      case REVIEWED:
      case FINAL_REVIEWED:
        return <S.DragboxUploadElement>{this.renderUpload()}</S.DragboxUploadElement>;

      case REJECTED:
        return (
          <S.DragboxUploadElement>
            {this.renderUpload()}

            <S.UploadInput empty data-tip data-for="tooltip--select-custom-background">
              <SelectFile
                trigger={
                  <S.SelectFileTrigger>
                    <S.IconSpacing>
                      <UploadIcon />
                    </S.IconSpacing>

                    <S.SelectFileTriggerText>
                      {uploading ? 'Uploading...' : isSaving ? 'Upload your App Icon' : 'Re-upload your App Icon'}
                    </S.SelectFileTriggerText>
                  </S.SelectFileTrigger>
                }
                onSelect={this.onSelectFile}
                accept="image/png, image/jpeg, image/jpg"
                validateExtentions={['png', 'jpg', 'jpeg']}
                maxSize={this.maxSize}
                fileSizeLimitMessage="Image size limit is 25MB."
              />

              {this.handleRenderNoteOfAppIcon(app_icon_status)}
              {this.handleRenderLinkDocument()}
            </S.UploadInput>
          </S.DragboxUploadElement>
        );

      case APPROVED:
        return (
          <S.DragboxUploadElement>
            <S.UploadInput empty data-tip data-for="tooltip--select-custom-background">
              {this.props.publisedAppIcon === 200 ? (
                <S.PublishedFile>
                  <S.IconPublicSpacing>
                    <PublishIcon />
                  </S.IconPublicSpacing>

                  <S.SelectFileTriggerPublishText>Published</S.SelectFileTriggerPublishText>
                </S.PublishedFile>
              ) : (
                <S.PublishFile onClick={() => this.handlePublishAppIcon()}>
                  <S.IconPublicSpacing>
                    <PublishIcon />
                  </S.IconPublicSpacing>

                  <S.SelectFileTriggerPublishText>Publish</S.SelectFileTriggerPublishText>
                </S.PublishFile>
              )}

              {this.handleRenderNoteOfAppIcon(app_icon_status)}
            </S.UploadInput>
          </S.DragboxUploadElement>
        );

      case PUBLISED:
        return (
          <S.DragboxUploadElement>
            <S.UploadInput empty data-tip data-for="tooltip--select-custom-background">
              <S.PublishedFile>
                <S.IconPublicSpacing>
                  <PublishIcon />
                </S.IconPublicSpacing>

                <S.SelectFileTriggerPublishText>Published</S.SelectFileTriggerPublishText>
              </S.PublishedFile>
              {this.handleRenderNoteOfAppIcon(app_icon_status)}
            </S.UploadInput>
          </S.DragboxUploadElement>
        );

      default:
        return (
          <S.DragboxUploadElement>
            {this.renderUpload()}

            <S.UploadInput empty data-tip data-for="tooltip--select-custom-background">
              <SelectFile
                trigger={
                  <S.SelectFileTrigger>
                    <S.IconSpacing>
                      <UploadIcon />
                    </S.IconSpacing>

                    <S.SelectFileTriggerText>
                      {uploading ? 'Uploading...' : isSaving ? 'Change your App Icon' : 'Upload your App Icon'}
                    </S.SelectFileTriggerText>
                  </S.SelectFileTrigger>
                }
                onSelect={this.onSelectFile}
                accept="image/png, image/jpeg, image/jpg"
                validateExtentions={['png', 'jpg', 'jpeg']}
                maxSize={this.maxSize}
                fileSizeLimitMessage="Image size limit is 25MB."
              />

              {this.handleRenderNoteOfAppIcon(app_icon_status)}
              {this.handleRenderLinkDocument()}
            </S.UploadInput>
          </S.DragboxUploadElement>
        );
    }
  };

  handleRenderLinkDocument = () => {
    return (
      <S.LinkDocument href="https://help.everfit.io/en/articles/5857931-custom-app-icon-requirements" target="_blank">
        <DocumentIcon></DocumentIcon>
        <S.LinkDocumentText>App Icon Requirements</S.LinkDocumentText>
      </S.LinkDocument>
    );
  };

  handleRenderPublishPopup = () => {
    const { openSubmit } = this.state;

    return (
      <S.ModalWrapper open={openSubmit} closeOnDimmerClick={false}>
        <Modal.Content>
          <ModalLayout.Wrapper>
            <ModalLayout.Header>
              <LoaderIcon></LoaderIcon>
              <S.TitleModal>Your app icon has been submitted</S.TitleModal>
            </ModalLayout.Header>
            <ModalLayout.Content>
              <S.DescriptionModal>
                We will review your submission and let you know via email when the submission has been approved and
                ready to use in the next 3-4 weeks.
              </S.DescriptionModal>
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button purple onClick={() => this.handleSubmitAppIcon()}>
                I understand
              </Button>
            </ModalLayout.Actions>
          </ModalLayout.Wrapper>
        </Modal.Content>
      </S.ModalWrapper>
    );
  };

  handleRenderCancelPopup = () => {
    const { openCancel } = this.state;

    return (
      <S.ModalWrapper open={openCancel} closeOnDimmerClick={false}>
        <Modal.Content>
          <ModalLayout.Wrapper>
            <ModalLayout.Header>
              <LoaderIcon></LoaderIcon>
              <S.TitleModal>Cancel your submission</S.TitleModal>
            </ModalLayout.Header>
            <ModalLayout.Content>
              <S.DescriptionModal>
                Your submission will be cancelled, and the process will restart when you submit again to get your app
                icon approved. Would you like to proceed?
              </S.DescriptionModal>
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button onClick={this.handleToggleCancelPopup}>Do not cancel</Button>

              <Button purple onClick={this.handleSubmitCancelIcon}>
                Proceed
              </Button>
            </ModalLayout.Actions>
          </ModalLayout.Wrapper>
        </Modal.Content>
      </S.ModalWrapper>
    );
  };

  handleToggleCancelPopup = () => {
    let { openCancel } = this.state;

    this.setState({ openCancel: !openCancel });
  };

  getCloudUrl = url => {
    const { cloudfrontList } = this.props;
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList) || url;
  };

  handleOpenAppIconModal = () => {
    const { toggleModal, setDefaultAppIcon, currentAppIconDefault, appIconKeySystem, onCheckSaving } = this.props;

    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <AppIconModal
          onClose={() => toggleModal(false)}
          onSave={it => {
            typeof setDefaultAppIcon === 'function' && setDefaultAppIcon(it);

            const isChange = (it || {})._id !== (currentAppIconDefault || {})._id;
            this.setState({ isChangeLogo: isChange });
            onCheckSaving(isChange);
          }}
          currentSelected={currentAppIconDefault}
          appIconKeySystem={appIconKeySystem}
        />,
      );
  };

  render() {
    const { selected, uploading, app_icon_status, isSaving, loader, publisedAppIcon, isChangeLogo } = this.state;
    const { appIconKeySystem, appIconKeySystemUrl, appIconFlowType } = this.props;

    const isNewFlow = appIconFlowType === NEW_APP_ICON_FLOW;

    return (
      <div>
        <UpgradePath pathName="branding">
          <S.FormGroup isNewFlow={isNewFlow}>
            <S.DragboxItem>
              <S.DragboxElement>
                <S.Description>
                  {!isNewFlow && (
                    <>
                      Upload an image to show up as the app icon for your clients.{' '}
                      <b>You may set the app icon only once.</b>
                    </>
                  )}
                  {isNewFlow && (
                    <>
                      Choose an image to show up as the app icon for your clients.{' '}
                      <b>You can change it as often as you'd like.</b>
                    </>
                  )}
                </S.Description>
                <S.Description>
                  <S.DescriptionItem>
                    <a
                      className="link"
                      target="_blank"
                      href="https://developer.apple.com/design/human-interface-guidelines/ios/icons-and-images/app-icon/"
                      rel="noopener noreferrer"
                    >
                      Apple App Icon Guideline
                    </a>
                  </S.DescriptionItem>
                  <S.DescriptionItem>
                    <a
                      className="link"
                      target="_blank"
                      href="https://developer.android.com/google-play/resources/icon-design-specifications"
                      rel="noopener noreferrer"
                    >
                      Android App Icon Guideline
                    </a>
                  </S.DescriptionItem>
                </S.Description>
              </S.DragboxElement>
            </S.DragboxItem>

            <S.DragboxItem isNewFlow={isNewFlow}>
              <S.DragboxUploadElement>
                {!isNewFlow && (
                  <>
                    {typeof uploading !== 'undefined'
                      ? !uploading &&
                        selected && (
                          <S.DragboxElement>
                            <S.Item empty className={classnames({ default: selected.src === Logo })}>
                              <S.Background
                                background={selected.src === Logo ? selected.src : this.getCloudUrl(selected.src)}
                              />
                            </S.Item>
                          </S.DragboxElement>
                        )
                      : selected && (
                          <S.DragboxElement>
                            <S.Item empty className={classnames({ default: selected.src === Logo })}>
                              <S.Background
                                background={selected.src === Logo ? selected.src : this.getCloudUrl(selected.src)}
                              />
                            </S.Item>
                          </S.DragboxElement>
                        )}
                  </>
                )}

                {isNewFlow && (
                  <div className="dragbox-element-wrapper">
                    <span className="label">CHOOSE YOUR APP ICON</span>
                    <div className="dragbox-element-container" onClick={this.handleOpenAppIconModal}>
                      <S.Item empty className={'dragbox-element-item'}>
                        <S.Background
                          className="dragbox-element-background"
                          background={_.get(
                            this.props,
                            'currentAppIconDefault.url',
                            appIconKeySystem ? appIconKeySystemUrl : Logo,
                          )}
                        />
                      </S.Item>
                    </div>
                  </div>
                )}
                {isNewFlow ? null : (
                  <S.DragboxElement>{this.handleRenderUploadComponent(app_icon_status)}</S.DragboxElement>
                )}
              </S.DragboxUploadElement>
            </S.DragboxItem>
          </S.FormGroup>

          {!isNewFlow && this.renderSaveButton(publisedAppIcon, app_icon_status)}
          {isNewFlow && (
            <S.SaveButtonArea className={classnames({ hide: !loader })}>
              <S.NewSaveButton onClick={this.onSaveSystem} disabled={!isChangeLogo}>
                Save
              </S.NewSaveButton>
            </S.SaveButtonArea>
          )}
        </UpgradePath>

        {this.handleRenderPublishPopup()}
        {this.handleRenderCancelPopup()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cloudfrontList: state.cloudfrontList,
  originalWelcomeTextColor: state.rootReducer.customBranding.splash_screen_welcome_text_color,
  currentAppIconDefault: _.get(state, 'rootReducer.customBranding.appIconDefault', {}),
  appIconKeySystemUrl: _.get(state, 'rootReducer.customBranding.appIconKeySystemUrl', ''),
  appIconKeySystem: _.get(state, 'rootReducer.customBranding.appIconKeySystem', ''),
  appIconFlowType: _.get(state, 'rootReducer.customBranding.appIconFlowType', ''),
  isLoading: _.get(state, 'rootReducer.customBranding.isLoading', ''),
});

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  setDefaultAppIcon: bindActionCreators(setDefaultAppIcon, dispatch),
  setAppSystemIcon: bindActionCreators(setAppSystemIcon, dispatch),
  resetDefaultAppIcon: bindActionCreators(resetDefaultAppIcon, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAppIcon);
