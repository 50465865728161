import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import Layout from 'layouts/Login';
import { ErrorMessage, Checkbox } from 'shared/FormControl';
import { axiosInstanceWithoutToken } from 'configs/request';
import ClientLoginWarning from 'components/ClientLoginWarning';
import { saveToken, getCookie, getAccessToken } from 'utils/commonFunction';
import { removeOldTokenSavedOnLocal } from 'utils/commonRequest';
import { hideTheMobileReminder, showTheMobileReminder } from 'utils/event-api';
import { Mixpanel } from 'utils/mixplanel';
import { logSignIn } from 'libs/firebase-analytics';
import ButtonGoogleAuth from 'shared/ButtonGoogleAuth';
import { C_KEY_TOKEN, CDN_URL } from 'constants/commonData';
import VerifyEmailExistingModal from 'shared/VerifyEmailExistingModal';

import CreateAnAccountModal from './components/CreateAnAccountModal';
import { ReactComponent as IconHorn } from 'assets/icons/icon_horns.svg';
import { SubtitleContent } from 'components/Register/style';
import * as FormStyles from 'layouts/Login/style';
import * as S from './style';

class Login extends React.Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.state = {
      email: params.get('email') || '',
      password: '',
      showError: false,
      isRememberMe: true,
      serverError: '',
      isLoginByGoogle: false,
      team_invite_id: '',
      referral_code: params.get('code') || '',
    };
    this.isSubmitting = false;
  }

  // MP
  componentDidMount() {
    const { location, workspace } = this.props;
    const search = get(this.props, 'location.search', '');
    const params = new URLSearchParams(search);
    const teamId = params.get('team_invite_id');

    if (teamId) {
      if (isEmpty(workspace)) {
        this.props.handleVerifyTeam(teamId).then(response => {
          const data = get(response, 'data.data');
          if (data) {
            this.setState({ ...data, team_invite_id: teamId });
          }
        });
      } else {
        this.setState({ ...workspace, team_invite_id: teamId });
      }
    }

    const accessToken = getAccessToken();

    if (location.pathname.startsWith('/login') && accessToken && !search.includes('redirect-platform')) {
      window.location = '/home/client';
    }

    if (!search.includes('redirect-platform')) return;

    const token = getCookie(C_KEY_TOKEN);
    if (token && search.includes('redirect-platform')) {
      this.loginMarketplace({
        token,
        agent: 'web',
      });
    }
  }

  getRegisterData = () => {
    const data = pick(this.state, ['first_name', 'last_name', 'email', 'password', 'team_invite_id', 'referral_code']);
    return { ...data, reward_referral_code: get(window, 'Rewardful.referral', '') };
  };

  loginMarketplace = body => {
    axiosInstanceWithoutToken
      .post('/api/authentication/trainer/gen-marketplace-token', body)
      .then(result => {
        const { isRememberMe, search } = this.extractCommonData();
        this.handleLoginResponse(result.data, isRememberMe, search);
      })
      .catch(error => {
        this.handleExceptionLogin(error, body);
      });
  };

  onEmailChange = event => {
    const { value } = event.target;
    this.setState({ email: value });
  };

  onPasswordChange = event => {
    const { value } = event.target;
    this.setState({ password: value });
  };

  onRememberMeChange = event => {
    const { checked } = event.target;
    this.setState({ isRememberMe: checked });
  };

  extractCommonData = () => {
    const { isRememberMe } = this.state;
    const search = get(this.props, 'location.search', '');

    removeOldTokenSavedOnLocal();
    hideTheMobileReminder();

    return { isRememberMe, search };
  };

  handleLoginResponse = (result, isRememberMe, search) => {
    const { toggleModal, logoutWithoutRedirect } = this.props;
    const { firebase_token, token, refresh_token, user } = result.data;
    const { force_send_verification_email_at, is_verified_email } = user || {};

    const dateNeedVerify = moment(force_send_verification_email_at).add(30, 'days');
    const isBeforeCurrentDate = moment(dateNeedVerify).isBefore(moment());

    try {
      if (!isMobile) {
        localStorage.setItem('login_status', 'just_now');
      }

      localStorage.setItem('showNotificationNewTab', true);
    } catch {}

    if (isBeforeCurrentDate && !is_verified_email) {
      this.isSubmitting = false;

      return toggleModal(
        true,
        <VerifyEmailExistingModal
          token={token}
          firstName={(user || {}).first_name}
          onClose={() => {
            toggleModal(false);
            logoutWithoutRedirect();
          }}
        />,
      );
    }

    if (user.is_trainer) {
      if (user.need_complete_profile) {
        this.props.addRegistrationNeedToComplete(result.data);
        this.props.push(`/complete-registration${window.location.search}`);
        return;
      }

      if (isMobile) {
        showTheMobileReminder();
      }

      logSignIn(user);
      Mixpanel.identify(user._id);
      Mixpanel.track('Login');
      Mixpanel.people.set({ $first_name: user.first_name, $last_name: user.last_name });

      saveToken({ token, refresh_token }, isRememberMe);

      localStorage.setItem('firebase-token', firebase_token);
      !localStorage.getItem(`${user._id}`) && localStorage.setItem(`${user._id}`, 0);

      const paramsString = get(this.props, 'location.search', '');
      let previousPath = '';

      if (paramsString) {
        const params = new URLSearchParams(paramsString);
        previousPath = params.get('prev') || '';
      }

      // MP
      if (previousPath.includes('marketplace')) {
        if (previousPath.includes('pricing-plan')) {
          previousPath = '/pricing-plan?prev=/home/marketplace';
        }
        if (previousPath) {
          window.location = decodeURIComponent(previousPath);
          this.props.push(previousPath);
        }
        return;
      }

      if (
        !user.is_answers_questionnaire ||
        !user.is_watch_video ||
        user.is_answers_questionnaire_direct_traffic_lead_source === false
      ) {
        if (isMobile && user.is_answers_questionnaire) {
          window.location = previousPath ? decodeURIComponent(previousPath) : '/';
        } else {
          window.location = `/onboarding${paramsString}`;
        }
      } else {
        window.location = previousPath ? decodeURIComponent(previousPath) : '/';
        if (search.includes('coupon')) {
          localStorage.setItem('hasCoupon', search);
        }
      }
    }
  };

  loginWeb = body => {
    const { workspace } = this.props;
    const pickedState = this.getRegisterData();
    const registerData = { ...pickedState, ...body };
    registerData.sign_up_info = window.location.search;
    registerData.timezone = moment.tz.guess();

    const requestBody = !isEmpty(workspace) ? registerData : body;

    const { isRememberMe, search } = this.extractCommonData();
    axiosInstanceWithoutToken
      .post('/api/auth/login_lite', { ...requestBody })
      .then(result => {
        this.handleLoginResponse(result, isRememberMe, search);
      })
      .catch(error => {
        this.handleExceptionLogin(error, body);
      });
  };

  handleLogin = (isCreateAccount = false) => {
    const { email, password } = this.state;
    const bodyData = { email: email.trim(), password };

    if (isCreateAccount) {
      bodyData.is_accept_create_profile = true;
    }

    if (!isMobile) {
      bodyData.agent = 'web';
    } else {
      bodyData.agent = 'mobile-web';
    }

    this.isSubmitting = true;
    this.setState({ serverError: '' });
    this.loginWeb(bodyData);
  };

  handleExceptionLogin = (error, body) => {
    const { push, toggleModal } = this.props || {};
    this.isSubmitting = false;

    if (error.response) {
      const { status, data } = error.response;

      if (status === 403) {
        if (get(data, 'additional.has_client_profile', false))
          return toggleModal(
            true,
            <ClientLoginWarning
              onClose={() => toggleModal(false)}
              onSubmit={() => {
                this.handleLogin(true);
                toggleModal(false);
              }}
            />,
          );

        if ((data || {}).errorCode === '14_100')
          return toggleModal(
            true,
            <CreateAnAccountModal
              onClose={() => toggleModal(false)}
              onSubmit={() => {
                this.handleLogin(true);
                toggleModal(false);
              }}
            />,
          );
      }

      if (status === 412) {
        return push('/restrict-login');
      }

      if (status === 406) {
        return push(`/verify-account?email=${encodeURIComponent(body.email)}`);
      }
    }

    if (this) {
      const serverError = get(
        error,
        'response.data.message',
        'Undefined error. Please try again later or contact your administrator.',
      );
      this.setState({ serverError });
      this.setState({ isLoginByGoogle: false });
    }
  };

  onSubmit = event => {
    event.preventDefault();

    if (this.isSubmitting) {
      return;
    }

    const { password } = this.state;
    const email = this.state.email.trim();

    if (!email || !password) {
      return this.setState({ showError: true });
    }

    this.handleLogin();
  };

  onHandleSuccess = tokenResponse => {
    const { workspace } = this.props;
    const { team_invite_id } = this.state;
    let agent = '';
    if (!isMobile) {
      agent = 'web';
    } else {
      agent = 'mobile-web';
    }

    const registerData = pick(this.getRegisterData(), ['team_invite_id', 'referral_code', 'reward_referral_code']);
    registerData.sign_up_info = window.location.search;

    this.setState({ isLoginByGoogle: true });
    const { isRememberMe, search } = this.extractCommonData();

    if (tokenResponse) {
      if (workspace) {
        this.props
          .signUpByGoogle(registerData, team_invite_id, tokenResponse)
          .then(result => {
            this.handleLoginResponse(result.data, isRememberMe, search);
          })
          .catch(error => {
            this.handleExceptionLogin(error);
          })
          .finally(() => {
            this.setState({ isLoginByGoogle: false });
          });
      } else {
        axiosInstanceWithoutToken
          .post('/api/authentication/trainer/login-google', { agent, access_token: tokenResponse.access_token })
          .then(result => {
            this.handleLoginResponse(result.data, isRememberMe, search);
          })
          .catch(error => {
            this.handleExceptionLogin(error);
          })
          .finally(() => {
            this.setState({ isLoginByGoogle: false });
          });
      }
    }
  };

  render() {
    const {
      email,
      password,
      showError,
      serverError,
      isRememberMe,
      isLoginByGoogle,
      team_invite_id,
      team_name,
    } = this.state;

    return (
      <S.Wrapper>
        <Helmet>
          <title>Everfit Login</title>
          <meta property="og:title" content="Sign up | Everfit" />
          <meta name="description" content="Create an account to train with your coach today!" />
          <meta property="og:image" content={`${CDN_URL}/images/invite-preview.png`} />
          <meta property="og:description" content="Create an account to train with your coach today!" />
        </Helmet>
        <Layout>
          <FormStyles.FormContainer onSubmit={this.onSubmit}>
            {team_invite_id ? (
              <FormStyles.FormHeader>
                <S.JoinTeamWrapper>
                  <S.JoinTeamTitle>
                    <IconHorn />
                    Join your team at
                  </S.JoinTeamTitle>
                  <S.JoinTeamName>{team_name}</S.JoinTeamName>
                </S.JoinTeamWrapper>
              </FormStyles.FormHeader>
            ) : (
              <FormStyles.FormHeader>Log in to Everfit</FormStyles.FormHeader>
            )}
            <ButtonGoogleAuth
              title="Log in"
              type="button"
              disabled={isLoginByGoogle}
              onHandleSuccess={this.onHandleSuccess}
            />
            {serverError && <FormStyles.ServerErrorMessage isGoogleAuth>{serverError}</FormStyles.ServerErrorMessage>}
            <SubtitleContent>Or Log in with e-mail</SubtitleContent>
            <FormStyles.FormGroup className={classnames({ error: showError && !email.trim() })}>
              <FormStyles.Controls>
                <label className={email ? '' : 'hide'}>Your Email Address</label>
                <input
                  defaultValue={email}
                  type="text"
                  placeholder="Your Email Address"
                  onChange={this.onEmailChange}
                  name="password"
                />
              </FormStyles.Controls>
              <ErrorMessage>This field is required</ErrorMessage>
            </FormStyles.FormGroup>
            <FormStyles.FormGroup className={classnames({ error: showError && !password })}>
              <FormStyles.Controls>
                <label className={password ? '' : 'hide'}>Password</label>
                <input
                  defaultValue={password}
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.onPasswordChange}
                />
              </FormStyles.Controls>
              <ErrorMessage>This field is required</ErrorMessage>
            </FormStyles.FormGroup>
            <S.Options>
              <Checkbox checked={isRememberMe} onChange={this.onRememberMeChange} title="Remember me" size={20} />
              <Link to={`/forgot-password${email.trim() ? '?email=' + email.trim() : ''}`}>Forgot your password?</Link>
            </S.Options>
            <FormStyles.SubmitButton disabled={!email.trim() || !password || isLoginByGoogle}>
              <span>Login</span>
              <img src={`${CDN_URL}/images/arrow.svg`} alt="" />
            </FormStyles.SubmitButton>
            <FormStyles.FormFooter>
              <span>Don’t have an account?&nbsp;</span>

              <Link to={`/register${window.location.search}`} className="highlight">
                Sign Up
              </Link>
            </FormStyles.FormFooter>
          </FormStyles.FormContainer>
        </Layout>
        {this.props.isModalOpen ? this.props.modal : null}
      </S.Wrapper>
    );
  }
}

export default Login;
