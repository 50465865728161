/**
 * @flow
 */

import React from 'react';
import classnames from 'classnames';
import { Table, Icon, Dropdown, Button, Image, TextArea } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { toggleConfirmModal } from 'actions/modal';
import { ExerciseSet } from 'types/model';
import styles, { ExerciseInfo, ExerciseNote, MarkAllErrorText, CustomConfirmModal } from './styles';
import './styles.scss';
import GeneralButton from 'shared/GeneralButton';
import ExerciseFieldsSelectBox from 'components/ExerciseFieldsSelectBox';
import { convertFromExerciseSetToSupersetsJson } from 'helpers/workout';
import { getSetDescription } from 'helpers/exercise';
import _ from 'lodash';
import { classToPlain } from 'class-transformer';
import diff from 'deep-diff';
import { initExerciseInstance, initIntervalExerciseFields, initSetsData, initFieldsWithORM } from 'helpers/exercise';
import { Checkbox } from 'shared/FormControl';
import Cleave from 'cleave.js/react';
import { DropItem, AltExerciseContainer, SwapArea, DeleteSetsIcon, AltExerciseItem } from './styles';
import { autoFillInput, renderFieldInput } from './helpers';
import {
  AUTO_FILL_FIELDS,
  CDN_URL,
  SECTION_FORMAT_KEY,
  TAGS_TYPE,
  WORKOUT_BUILDER_GUIDE_STEPS,
} from 'constants/commonData';
import SearchExercise from 'shared/SearchExercise';
import ExerciseForm from 'components/ExerciseForm';
import HistoryExercisePopup from 'components/HistoryExercisePopup';
import { showError } from 'actions/error';
import AlternativeExercises from 'components/AlternativeExercises';
import ExerciseQuickView from 'shared/ExerciseQuickView';
import AddMultipleSets from 'shared/AddMultipleSets';
import SelectSetType from 'shared/SelectSetType';

import { roundTwoDigit } from 'helpers/number';
import { MODES as EXERCISE_MODES } from 'components/ExerciseForm/component';
import DropdownIcon from './components/DropdownIcon';
import { toast } from 'react-toastify';
import { convertUnit } from 'utils/commonFunction';

import { ReactComponent as SwapIcon } from 'assets/icons/swap_icon.svg';
import { ReactComponent as CircleIcon } from 'assets/icons/circle-remove-icon.svg';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';

import ChangeExerciseModal from './components/ChangeExerciseModal';
import { SharedTooltip } from 'shared/SharedTooltip';

const MODES = { TRACKING: 'tracking', EDIT: 'edit' };
const ORM_FIELDS = ['orm', 'weight'];

export default class ExerciseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      exerciseSet: props.exerciseSet || new ExerciseSet(),
      originSet: this.getOriginExerciseSet(),
      note: '',
      isAddingNote: false,
      shouldFocusNote: false,
      width: 0,
      height: 0,
      createNewExercise: false,
      usingPercent: false,
      error: '',
      mainExerciseID: props.mode === MODES.TRACKING ? _.get(props.exerciseSet, 'exercise._id') : null,
      showChangeExerciseModal: false,
      isEditExercise: false,
    };
    this.convertUnitOnce = _.once(this.convertUnitWhenMount.bind(this));
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.renderFieldInput = renderFieldInput.bind(this);
    this.currentAddsetButtonRect = {};
    this.isChangingExercise = false;
  }

  componentWillReceiveProps(nextProps) {
    if (_.get(nextProps, 'isHaveRound', false)) {
      this.setState({ exerciseSet: nextProps.exerciseSet || new ExerciseSet() });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.submitStatus.status !== -1) {
      this.props.resetSubmitStatus();
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate() {
    if (this.props.exerciseSet) {
      let origin = classToPlain(this.props.exerciseSet);
      const { tracking } = this.props;
      let current = classToPlain(this.state.exerciseSet);
      let changes = diff(origin, current);

      if (changes && tracking && !this.isChangingExercise) {
        this.setState({ exerciseSet: this.props.exerciseSet });
      }
      if (this.isChangingExercise) {
        if (!(changes && tracking)) {
          // reset the isChangingExercise after the action completes
          this.isChangingExercise = false;
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth - 190,
      height: window.innerHeight,
    });
  }

  isPopupViewMode = () => {
    return ['popup', 'program_library_workout_popup', 'new_popup', 'program_library_workout_new_popup'].includes(
      this.props.mode,
    );
  };

  handleToggleUsePercent = usingPercent => {
    const { exerciseSet } = this.state;
    const { fields, mode, tracking = false } = this.props;
    if (tracking) {
      return;
    }

    if (!exerciseSet.exercise_instance || !exerciseSet.training_sets || exerciseSet.training_sets.length === 0) {
      return;
    }

    let { training_sets } = exerciseSet;
    let exercise_instance = exerciseSet.exercise_instance;
    let exercise_instance_fields = exercise_instance.fields;

    //
    // const exercise = exerciseSet.exercise_instance;
    let weightField = _.find(fields, f => f.unique_code === 'weight');
    let oneRMField = _.find(fields, f => f.unique_code === 'orm');
    if (!weightField || !oneRMField) {
      return;
    }
    let existedWeightFromCurrentFields = exercise_instance_fields.find(item => {
      return item === weightField._id;
    });
    let existed1RMFromCurrentFields = exercise_instance_fields.find(item => {
      return item === oneRMField._id;
    });

    if (usingPercent) {
      if (
        exercise_instance_fields.length > 3 ||
        (existed1RMFromCurrentFields && exercise_instance_fields.length == 3)
      ) {
        this.props.dispatch(showError('Reached maximum number of column. Cannot activate Use %'));
        return;
      } else if (existed1RMFromCurrentFields && exercise_instance_fields.length == 3) {
        this.props.dispatch(showError('Reached maximum number of column. Cannot activate Use %'));
        return;
      }

      //Should have both fields weight and 1rm
      if (existedWeightFromCurrentFields && existed1RMFromCurrentFields) {
        //Exist both field, ignore this case
      } else if (existedWeightFromCurrentFields) {
        //Exist only weight, add 1rm before weight
        const weightFieldIndex = exercise_instance_fields.indexOf(weightField._id);

        if (weightFieldIndex === -1) {
          exercise_instance_fields.push(oneRMField._id);
        } else {
          exercise_instance_fields.splice(weightFieldIndex, 0, oneRMField._id);
        }
      } else if (existed1RMFromCurrentFields) {
        //Exist only 1rm, add weight after 1rm
        const oneRMFieldIndex = exercise_instance_fields.indexOf(oneRMField._id);

        if (oneRMFieldIndex === -1) {
          exercise_instance_fields.push(weightField._id);
        } else {
          exercise_instance_fields.splice(oneRMFieldIndex + 1, 0, weightField._id);
        }
      }

      const { one_rep_max } = exerciseSet;
      const ormValue = Number(_.get(one_rep_max, 'value', 0));

      training_sets = training_sets.map(item => {
        const copyItem = { ...item };

        if (!copyItem.orm) {
          copyItem.orm = { value: '0' };
        }

        if (mode === MODES.TRACKING) {
          const weightValue = roundTwoDigit((ormValue * (Number(copyItem.orm.value) || 0)) / 100);
          copyItem.weight = { ...copyItem.weight, value: weightValue };
        }

        return copyItem;
      });
    } else {
      //Should have only weight, remove 1rm if existed
      if (existedWeightFromCurrentFields && existed1RMFromCurrentFields) {
        //Exist both field, remove 1rm
        exercise_instance_fields = exercise_instance_fields.filter(item => {
          return item != oneRMField._id;
        });
      } else if (existed1RMFromCurrentFields) {
        var oneRMFieldIndex = exercise_instance_fields.indexOf(oneRMField._id);
        if (oneRMFieldIndex == -1) {
          exercise_instance_fields.push(weightField._id);
        } else {
          exercise_instance_fields.splice(oneRMFieldIndex, 1, weightField._id);
        }
      }
      training_sets = training_sets.map(item => {
        if (item.orm) {
          delete item.orm;
        }
        return item;
      });
    }
    exercise_instance.fields = exercise_instance_fields;
    exerciseSet.exercise_instance = exercise_instance;
    exerciseSet.training_sets = training_sets;
    this.setState({ exerciseSet });
  };

  render() {
    const { viewingExercise, exerciseSet, createNewExercise, isEditExercise } = this.state;
    const { pId, sectionIndex, setIdx, exIndex, mode, disabled } = this.props;

    if (this.isPopupViewMode()) {
      const isCreateNew = mode === 'new_popup' || mode === 'program_library_workout_new_popup';
      const trigger = isCreateNew ? (
        <div data-tip data-for="add-new-exercise-icon-tooltip">
          <div className="calendar-plus-icon" />
          <SharedTooltip id="add-new-exercise-icon-tooltip" content="Add exercise" />
        </div>
      ) : (
        this.renderPopupTrigger(this.props.exerciseSet)
      );

      return (
        <ExerciseQuickView
          id={pId || `exercice-${exerciseSet._id}_${sectionIndex}_${setIdx}_${exIndex}`}
          trigger={trigger}
          widthInPercent={35}
          minWidth={500}
          header={() => this.renderExerciseTitle()}
          body={() => this.renderPopupContent()}
          footer={() => this.renderFooter()}
          className={classnames('exercise-container show-in-popup', { readOnly: disabled })}
          onClose={this.closeQuickView}
          disabledCloseListener={!!viewingExercise || !!createNewExercise || isEditExercise}
          onOpen={() => {
            if (isCreateNew) {
              this.setState({
                exerciseSet: new ExerciseSet(),
                isAddingNote: false,
                showCustomizeForm: false,
                manageAltExercises: false,
              });
            } else {
              this.setState({
                exerciseSet: this.props.exerciseSet,
                originSet: this.getOriginExerciseSet(),
              });
            }
          }}
        />
      );
    } else if (this.props.mode === 'preview') {
      return this.renderPopupTrigger(this.props.exerciseSet);
    } else {
      return this.renderMainContainer();
    }
  }

  renderPopupContent = () => {
    return (
      <>
        {this.renderExerciseCustomizeBox()}
        {this.renderAlternativeExercises()}
        {this.renderSetTable()}
        {this.renderActionButtons()}
        {this.renderNote()}
        {this.renderError()}
        {this.renderCreateNewExercise()}
        {this.renderViewExercise()}
      </>
    );
  };

  renderMainContainer() {
    return (
      <div className="exercise-main-content">
        <div className={`exercise-container ${this.isPopupViewMode() ? 'show-in-popup' : ''}`}>
          {this.props.index ? <b style={styles.index}>{this.props.index}</b> : null}
          {this.renderExerciseTitle()}
          {this.renderExerciseCustomizeBox()}
          {this.renderAlternativeExercises()}
          {this.renderErrorMarkAll()}
          {this.renderSetTable()}
          {this.renderActionButtons()}
          {this.renderNote()}
          {this.renderError()}
          {this.renderFooter()}
          {this.renderCreateNewExercise()}
          {this.renderViewExercise()}
        </div>
      </div>
    );
  }

  renderViewExercise = () => {
    const { viewingExercise } = this.state;

    if (!viewingExercise) {
      return null;
    }

    return (
      <ExerciseForm
        open={true}
        originExercise={viewingExercise}
        mode={EXERCISE_MODES.UPDATE}
        closePopup={() => this.setState({ viewingExercise: null })}
        onSubmitSuccess={({ exercise, close }) => {
          let { exerciseSet } = this.state;
          const oldExerciseId = _.get(exerciseSet, 'exercise._id', '');
          const { sectionFormat, fields, categories, unit, mode } = this.props;

          if (mode === 'new_popup' || mode === 'program_library_workout_new_popup') {
            let { exercise_instance } = exerciseSet;
            const intervalSection = sectionFormat === SECTION_FORMAT_KEY.INTERVAL;
            exercise_instance.fields = intervalSection
              ? initIntervalExerciseFields(exercise, categories, fields)
              : exercise.fields;
            const initSetParams = {
              oldSets: exerciseSet.training_sets,
              newFields: exercise_instance.fields,
              systemFields: fields,
              units: unit,
            };
            exerciseSet.training_sets = initSetsData(initSetParams, intervalSection);
            exercise_instance.title = exercise.title;
            exerciseSet.exercise = { ...exercise };
          }

          this.setState({ viewingExercise: close ? null : exercise, exerciseSet }, () => {
            if (typeof this.props.onEditExerciseLibrary === 'function') {
              this.props.onEditExerciseLibrary(oldExerciseId, exercise);
            }
          });
        }}
      />
    );
  };

  calculateSetValueBasedOnORM = (sets, ormObject) => {
    const newTrainingSets = sets.slice();

    if (!ormObject) {
      _.forEach(newTrainingSets, set => {
        set.weight = { ...set.weight, value: '' };
      });
    } else {
      const ormValue = Number(ormObject.value) || 0;

      _.forEach(newTrainingSets, set => {
        const setOrm = Number(_.get(set, 'orm.value', 0)) || 0;
        const newWeight = roundTwoDigit((ormValue * setOrm) / 100);
        set.weight = { ...set.weight, value: newWeight };
      });
    }

    return newTrainingSets;
  };

  onSelectAlternative = (item, index) => {
    const { mode, setIndex, exIndex } = this.props;

    if (mode !== MODES.TRACKING) {
      return false;
    }

    const { exerciseSet } = this.state;
    const { one_rep_max } = item;
    const { exercise, alternatives, exercise_instance } = exerciseSet;
    let newAltternatives = [...alternatives];
    const firstSwitch = !_.find(newAltternatives, ex => ex.isMainExericse);

    if (firstSwitch) {
      _.forEach(newAltternatives, (ex, idx) => {
        ex.originIndex = idx + 1;

        if (ex._id === item._id) {
          item.originIndex = idx + 1;
        }
      });

      newAltternatives.splice(index, 1);
      newAltternatives.unshift({ ...exercise, isMainExericse: true, originIndex: 0 });
    } else {
      newAltternatives.splice(index, 1, { ...exercise, isMainExericse: false });
      newAltternatives = _.orderBy(newAltternatives, 'originIndex');
    }

    exerciseSet.exercise = item;
    exerciseSet.one_rep_max = one_rep_max;
    exerciseSet.alternatives = newAltternatives;
    exerciseSet.exercise_instance = { ...item, fields: exercise_instance.fields };

    if (this.isUseOneRepMaxPercent(exerciseSet)) {
      exerciseSet.training_sets = this.calculateSetValueBasedOnORM(exerciseSet.training_sets, one_rep_max);
    }

    this.setState({ exerciseSet }, () => {
      this.props.onChange({ exerciseSet, setIndex, exIndex });
    });
  };

  renderAlternativeExercises = () => {
    const { exerciseSet } = this.state;
    const { mode } = this.props;

    if (!exerciseSet.alternatives || !exerciseSet.alternatives.length) {
      return null;
    }

    const tracking = mode === MODES.TRACKING;

    const renderAltExerciseItem = (item, index) => {
      if (tracking) {
        return (
          <AltExerciseItem>
            <ExerciseMediaPreview exercise={item || {}} size={{ width: 32, height: 32 }} />
            <span className="count">{item.originIndex !== undefined ? item.originIndex : index + 1}.</span>
            <span className="title">&nbsp;{item.title}</span>
          </AltExerciseItem>
        );
      }

      return (
        <>
          <span>{item.originIndex !== undefined ? item.originIndex : index + 1}.</span>
          <span>{item.title}</span>
        </>
      );
    };

    return (
      <AltExerciseContainer className="alternative-exercises" tracking={tracking}>
        <div className="tille">
          <div>Alternate Exercises</div>
          {!tracking ? <a onClick={() => this.setState({ manageAltExercises: true })}>Edit</a> : null}
        </div>
        <div className="alt-exs__menu">
          {_.map(exerciseSet.alternatives, (item, index) => (
            <div
              className="menu__item"
              key={`${item._id}_${index}`}
              onClick={() => this.onSelectAlternative(item, index)}
            >
              {renderAltExerciseItem(item, index)}
            </div>
          ))}
        </div>
      </AltExerciseContainer>
    );
  };

  renderCreateNewExercise = () => {
    const { createNewExercise } = this.state;

    if (!createNewExercise) {
      return null;
    }

    return (
      <ExerciseForm
        open={true}
        mode={EXERCISE_MODES.CREATE}
        firstTitle={typeof createNewExercise === 'string' ? createNewExercise : ''}
        closePopup={() => this.setState({ createNewExercise: false })}
        onSubmitSuccess={({ exercise, close }) => {
          this.setState({ createNewExercise: false, viewingExercise: close ? null : exercise }, () => {
            this.onSelectExercise({ ...exercise, value: exercise._id });
          });
        }}
      />
    );
  };

  renderExerciseCustomizeBox = () => {
    const { exerciseSet, showCustomizeForm } = this.state;
    const { sectionFormat } = this.props;
    const intervalSection = sectionFormat === SECTION_FORMAT_KEY.INTERVAL;
    let should_show_1rm = this.shouldShowOneRepMaxPercent(_.get(exerciseSet, 'exercise_instance.fields', []));
    let is_use_1rm = this.isUseOneRepMaxPercent(exerciseSet);

    if (exerciseSet && exerciseSet.exercise_instance && showCustomizeForm) {
      return (
        <div className="customize-field-container">
          <ExerciseFieldsSelectBox
            default_fields={_.cloneDeep(exerciseSet.exercise_instance.fields)}
            onSave={this.onUpdateFields}
            handleClose={() => this.setState({ showCustomizeForm: false })}
            intervalSection={intervalSection}
            showButtons={true}
            should_show_1rm={should_show_1rm}
            is_use_1rm={is_use_1rm}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  onUpdateFields = newFields => {
    const { fields, unit } = this.props;
    let { exerciseSet } = this.state;

    if (!exerciseSet) {
      return false;
    }

    const restField = _.find(fields, item => item.unique_code === 'rest');

    if (!newFields.includes(restField._id)) {
      newFields.push(restField._id);
    }

    if (exerciseSet.exercise_instance) {
      exerciseSet.exercise_instance.fields = newFields;
    }

    const initSetParams = {
      oldSets: exerciseSet.training_sets,
      newFields,
      systemFields: fields,
      units: unit,
    };
    exerciseSet.training_sets = initSetsData(initSetParams);
    this.setState({ exerciseSet, showCustomizeForm: false });
  };

  renderPopupTrigger = itm => {
    const { sideBarVisible, boxWidth, mode, onPreviewClick, pId, calendarType, unit, fields } = this.props;

    const title = _.get(itm.exercise || itm.exercise_instance, 'title', '');

    return (
      <div
        className="exercise-preview"
        onClick={() => (mode === 'preview' && typeof onPreviewClick === 'function' ? onPreviewClick() : null)}
      >
        <span className="exercise-preview__times">{itm.training_sets.length}x</span>
        <div className="exercise-cell-set">
          <div className="exercise-preview__name" data-tip data-for={`tooltip-${pId}`}>
            <span>{title}</span>
            {itm.alternatives && itm.alternatives.length ? <Image src={`${CDN_URL}/images/split_grey.svg`} /> : null}
          </div>
          {pId ? (
            <ReactTooltip className="app-tooltip" id={`tooltip-${pId}`} effect="solid" place={'top'} delayShow={1000}>
              <span>{title}</span>
            </ReactTooltip>
          ) : null}
          {calendarType !== 4 ? (
            <span className="exercise-preview__description">{getSetDescription(itm, unit, fields)}</span>
          ) : null}
        </div>
      </div>
    );
  };

  onCloseCurrentPopup = callback => {
    // This is a bad code snippet
    // But must be like this to ensure that the functions work properly
    // This component is not clear between fully controlled and fully uncontrolled
    // NEED TO REFACTOR
    let { originSet, exerciseSet } = this.state;
    let originExSet = _.cloneDeep(originSet);
    exerciseSet.each_side = originExSet.each_side;
    exerciseSet.note = originExSet.note;
    exerciseSet.tempo = originExSet.tempo;
    exerciseSet.alternatives = originExSet.alternatives;
    exerciseSet.exercise = originExSet.exercise;
    exerciseSet.exercise_instance = originExSet.exercise_instance;
    exerciseSet.training_sets = originExSet.training_sets;
    this.setState({
      exerciseSet,
      showCustomizeForm: false,
      isAddingNote: !!originExSet.note,
      manageAltExercises: false,
    });

    if (typeof callback === 'function') {
      callback(true);
    }
  };

  handleCloseAction = callback => {
    let origin = classToPlain(this.state.originSet);
    let current = classToPlain(this.state.exerciseSet);
    let changes = diff(origin, current);
    if (changes) {
      this.props.dispatch(
        toggleConfirmModal(
          true,
          <CustomConfirmModal
            noBorder
            title="Discard Changes?"
            content={`Are you sure you want to go? Changes have not been saved yet.`}
            onConfirm={() => this.onCloseCurrentPopup(callback)}
            confirmButtonTitle="Discard changes"
            hasCloseIcon
            headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          />,
        ),
      );
    } else {
      if (typeof callback === 'function') {
        callback(true);
      }
    }
  };

  closeQuickView = (callback, checkDiff) => {
    this.setState({ manageAltExercises: false });
    if (!checkDiff) {
      this.onCloseCurrentPopup(callback);
    } else {
      this.handleCloseAction(callback);
    }
  };

  renderFooter = () => {
    if (['edits', 'tracking'].includes(this.props.mode)) {
      return null;
    }

    const { setIndex, exIndex, disabled } = this.props;
    const { exerciseSet } = this.state;

    if (['popup', 'program_library_workout_popup'].includes(this.props.mode)) {
      return (
        <div className="exercise-footer">
          {exIndex !== undefined ? (
            <div>
              <Button
                className="action-button duplicate-button tooltip"
                onClick={() => this.handleCloneAction(exerciseSet)}
                disabled={disabled}
              >
                <Image src={`${CDN_URL}/images/duplicate_grey.svg`} />
                <span className="tooltiptext">Duplicate</span>
              </Button>
              <Button
                className="action-button delete-button tooltip"
                onClick={() => this.handleDeleteAction(setIndex, exIndex)}
                disabled={disabled}
              >
                <Image src={`${CDN_URL}/images/trash_grey.svg`} />
                <span className="tooltiptext">Remove</span>
              </Button>
            </div>
          ) : null}
          {this.renderSubmitButton()}
        </div>
      );
    } else {
      return this.renderSubmitButton();
    }
  };

  handleCloneAction(exerciseSet) {
    const { mode, onClone, submitStatus, disabled } = this.props;

    if (disabled) {
      return;
    }

    if (typeof onClone === 'function') {
      onClone(exerciseSet);
    } else {
      if (!submitStatus.status) {
        return false;
      }

      if (mode === 'program_library_workout_popup') {
        const { workout, selectedProgram, addExerciseAndSaveForProgramLibraryWorkout } = this.props;
        if (!workout || !workout._id) {
          console.log('Cannot detect current workout');
        }

        let exerciseJson = convertFromExerciseSetToSupersetsJson(exerciseSet);
        addExerciseAndSaveForProgramLibraryWorkout({
          exercise: exerciseJson,
          workoutId: workout._id,
          programId: selectedProgram._id,
        });
      } else {
        const { assignment, selectedClient, addExerciseAndSave } = this.props;

        if (!assignment || !assignment._id) {
          console.log('Cannot detect current assignment');
        }

        let exerciseJson = convertFromExerciseSetToSupersetsJson(exerciseSet);
        addExerciseAndSave({
          exercise: exerciseJson,
          assignment: assignment._id,
          client: selectedClient,
        });
      }
    }
  }

  handleDeleteAction(setIndex, exIndex) {
    const { unit, sectionIndex, submitStatus, disabled } = this.props;

    if (disabled) {
      return;
    }

    if (this.props.onDelete) {
      this.props.onDelete(setIndex, exIndex);
    } else {
      if (!submitStatus.status) {
        return false;
      }

      if (this.props.mode === 'program_library_workout_popup') {
        const { workout, selectedProgram } = this.props;
        this.props.removeCurrentExerciseFromProgramLibraryWorkout(
          sectionIndex,
          setIndex,
          exIndex,
          workout,
          selectedProgram._id,
          unit,
        );
      } else {
        const { assignment, setIndex, exIndex, selectedClientData } = this.props;
        this.props.removeCurrentExercise(sectionIndex, setIndex, exIndex, assignment, selectedClientData);
      }
    }
  }

  getOriginExerciseSet = () => {
    if (['new', 'new_popup', 'program_library_workout_new_popup'].includes(this.props.mode)) {
      return new ExerciseSet();
    }

    const copyExerciseSet = _.cloneDeep(this.props.exerciseSet);

    return new ExerciseSet(copyExerciseSet || null);
  };

  isUseOneRepMaxPercent = exerciseSet => {
    const { fields } = this.props;
    const currentFields = exerciseSet.exercise_instance ? exerciseSet.exercise_instance.fields : [];
    const weightField = fields.find(item => item.unique_code == 'weight');
    const oneRepMaxField = fields.find(item => item.unique_code == 'orm');

    if (!weightField || !oneRepMaxField) {
      return false;
    }

    const existed1RMFromCurrentFields = currentFields.find(item => item == oneRepMaxField._id);

    if (existed1RMFromCurrentFields) {
      return true;
    }

    return false;
  };

  shouldShowOneRepMaxPercent = currentFields => {
    const { fields } = this.props;

    const weightField = fields.find(item => item.unique_code === 'weight');
    const oneRepMaxField = fields.find(item => item.unique_code === 'orm');

    if (!weightField || !oneRepMaxField) {
      return false;
    }

    const existedWeightFromCurrentFields = currentFields.find(item => item == weightField._id);
    const existed1RMFromCurrentFields = currentFields.find(item => item == oneRepMaxField._id);

    if (existedWeightFromCurrentFields || existed1RMFromCurrentFields) {
      return true;
    }

    return false;
  };

  handleSaveAlternatives = exercises => {
    const newExerciseSet = new ExerciseSet({
      ...this.state.exerciseSet,
      alternatives: exercises,
    });
    this.setState({ exerciseSet: newExerciseSet, manageAltExercises: false, isEditExercise: false });
  };

  toggleChangeExerciseModal = () => {
    this.setState(prevState => ({
      showChangeExerciseModal: !prevState.showChangeExerciseModal,
    }));
  };

  handleCloseChangeExerciseModal = () => {
    this.setState({ showChangeExerciseModal: false });
  };

  // TODO - Swap Exercise
  renderSwapButton = () => {
    const {
      sectionIndex,
      setIndex,
      bodyParts,
      categories,
      exerciseTags,
      mode,
      equipments,
      modalities,
      muscleGroup,
      movementPattern,
    } = this.props;
    const { exerciseSet } = this.state;
    const exerciseId = _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');
    // TODO - Handle Open Exercise List
    const handleOpenExercisePopup = () => {
      this.toggleChangeExerciseModal();
    };
    const isTracking = mode === MODES.TRACKING;

    if (isTracking && exerciseId) {
      return (
        <SwapArea>
          <ReactTooltip id={`swap-tooltip-${sectionIndex}-${setIndex}`} effect="solid" place={'top'} delayShow={100}>
            <span>Change to another exercise</span>
          </ReactTooltip>

          <SwapIcon
            data-tip="tooltip"
            data-for={`swap-tooltip-${sectionIndex}-${setIndex}`}
            onClick={handleOpenExercisePopup}
          />
          <ChangeExerciseModal
            bodyParts={bodyParts}
            categories={categories}
            exerciseTags={exerciseTags}
            onSelectExercise={this.onSelectExercise}
            currentExercise={this.state.exerciseSet.exercise}
            open={this.state.showChangeExerciseModal}
            onClose={this.handleCloseChangeExerciseModal}
            getMostRecentTagsList={this.props.getMostRecentTagsList}
            equipments={equipments}
            modalities={modalities}
            muscleGroup={muscleGroup}
            movementPattern={movementPattern}
          />
        </SwapArea>
      );
    }
    return null;
  };

  renderExerciseTitle = () => {
    const { mostRecents, disabled, tracking, blockChangeExercise = false } = this.props;
    let { manageAltExercises, exerciseSet } = this.state;
    let should_show_1rm = this.shouldShowOneRepMaxPercent(_.get(exerciseSet, 'exercise_instance.fields', []));
    let is_use_1rm = this.isUseOneRepMaxPercent(exerciseSet);
    const exerciseId = _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');

    return (
      <div className={classnames('exercise-header', tracking ? 'exercise-header-tracking' : '')}>
        <div style={styles.exerciseName}>
          {tracking ? (
            <ExerciseMediaPreview exercise={exerciseSet.exercise || {}} size={{ width: 39, height: 39 }} />
          ) : (
            this.renderExerciseNo()
          )}
          <SearchExercise
            defaultOptions={mostRecents}
            defaultValue={this.getExerciseDefault()}
            onSelect={this.onSelectExercise}
            onToggleUsePercent={this.handleToggleUsePercent}
            should_show_1rm={!tracking ? should_show_1rm : is_use_1rm ? true : false}
            is_use_1rm={is_use_1rm}
            menuIsAutoOpen={true}
          />
          {this.renderHistoryPopup()}
          {/* TODO - Swap Exercise not render in timed and amrap */}
          {!blockChangeExercise && this.renderSwapButton()}
          {this.renderActionDropdown()}
        </div>
        {manageAltExercises && (
          <AlternativeExercises
            exercises={exerciseSet.alternatives || []}
            originalExercise={exerciseId}
            onCancel={() => this.setState({ manageAltExercises: false, isEditExercise: false })}
            onSave={this.handleSaveAlternatives}
            onEditExercise={() => this.setState({ isEditExercise: true })}
          />
        )}
      </div>
    );
  };

  renderHistoryPopup() {
    const { exerciseSet } = this.state;
    const { selectedClientData } = this.props;
    const exerciseId = _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');

    if (selectedClientData && exerciseId) {
      return <HistoryExercisePopup exerciseId={exerciseId} deletedExercise={!_.get(exerciseSet, 'exercise._id', '')} />;
    }
    return null;
  }

  renderExerciseNo() {
    if (
      ['popup', 'program_library_workout_popup', 'new_popup', 'new', 'program_library_workout_new_popup'].includes(
        this.props.mode,
      )
    ) {
      return null;
    }
    const { maxLength, setIndex, exIndex, totalPreviousHiddenSection } = this.props;
    const exerciseOrder = _.isNumber(totalPreviousHiddenSection) ? totalPreviousHiddenSection + 1 : setIndex + 1;

    if (maxLength !== 'undefined' && maxLength == 1) {
      return <div className="exercise-no">{exerciseOrder}</div>;
    }

    return (
      <div className="exercise-no">
        {exerciseOrder}.{exIndex + 1}
      </div>
    );
  }

  renderActionDropdown() {
    const {
      setIndex,
      exIndex,
      moveOptions,
      mode,
      viewExercise,
      sectionFormat,
      sectionIndex,
      disabled,
      workoutBuilderGuideSteps,
      isFirstSectionHasAlterExercise,
    } = this.props;

    const { exerciseSet } = this.state;
    const modes = ['edit', 'popup', 'program_library_workout_popup', 'new_popup', 'program_library_workout_new_popup'];

    if (!exerciseSet.exercise_instance) {
      return null;
    }

    if (modes.includes(this.props.mode)) {
      const exerciseIndex = `${sectionIndex}-${setIndex}-${exIndex}`;
      return (
        <>
          <Dropdown
            style={{ float: 'right', paddingLeft: 10 }}
            icon={
              <DropdownIcon
                sectionIndex={sectionIndex}
                setIndex={setIndex}
                exIndex={exIndex}
                isFirstSectionHasAlterExercise={isFirstSectionHasAlterExercise}
              />
            }
            disabled={disabled}
            onOpen={() => {
              if (
                workoutBuilderGuideSteps[WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection] &&
                !workoutBuilderGuideSteps[WORKOUT_BUILDER_GUIDE_STEPS.CustomOptions] &&
                sectionFormat !== SECTION_FORMAT_KEY.INTERVAL
              ) {
                this.props.markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CustomOptions);
              }
            }}
          >
            <Dropdown.Menu className="dropdown-menu more-actions">
              <Dropdown.Item
                onClick={() => {
                  if (!exerciseSet.exercise) {
                    return false;
                  }

                  const exerciseId =
                    _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');

                  viewExercise(exerciseId, exerciseData =>
                    this.setState({
                      viewingExercise: exerciseData,
                      createNewExercise: false,
                    }),
                  );
                }}
              >
                <DropItem data-tip data-for={`deleted-exercise-messaging-${exerciseIndex}`}>
                  <div className="icon view" />
                  <div>
                    <span>View exercise</span> {!exerciseSet.exercise && <span style={{ color: 'red' }}>*</span>}
                  </div>
                </DropItem>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ showCustomizeForm: true })}>
                <DropItem>
                  <div className="icon customize" />
                  <div>Customize fields</div>
                </DropItem>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({ manageAltExercises: true })}>
                <DropItem>
                  <div className="icon split" />
                  <div>
                    {exerciseSet.alternatives && exerciseSet.alternatives.length ? 'Edit' : 'Add'} Alternate Exercises
                  </div>
                </DropItem>
              </Dropdown.Item>

              {mode === 'edit' && (
                <React.Fragment>
                  <Dropdown.Item onClick={() => this.handleCloneAction(exerciseSet)}>
                    <DropItem>
                      <div className="icon duplicate" />
                      <div>Duplicate</div>
                    </DropItem>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.handleDeleteAction(setIndex, exIndex)}>
                    <DropItem>
                      <div className="icon delete" />
                      <div>Delete</div>
                    </DropItem>
                  </Dropdown.Item>
                  {moveOptions && moveOptions.length
                    ? _.map(moveOptions, section => (
                        <Dropdown.Item
                          key={`move-${section._id}`}
                          onClick={() => {
                            this.handleMoveExercise(section);
                          }}
                        >
                          <DropItem>
                            <div className="icon move" />
                            <div>Move to {section.title}</div>
                          </DropItem>
                        </Dropdown.Item>
                      ))
                    : null}
                </React.Fragment>
              )}
            </Dropdown.Menu>
          </Dropdown>
          {!exerciseSet.exercise && (
            <ReactTooltip
              id={`deleted-exercise-messaging-${exerciseIndex}`}
              effect="solid"
              place={'top'}
              delayShow={200}
              className="app-tooltip deleted-exercise-tooltip"
            >
              <span>
                Original exercise has been removed from library. We recommend assigning an existing exercise, so your
                client can get correct exercise information.
              </span>
            </ReactTooltip>
          )}
        </>
      );
    } else {
      return null;
    }
  }

  getExerciseDefault = () => {
    const { exerciseSet } = this.state;

    const exerciseId = _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');
    const exerciseTitle = _.get(exerciseSet.exercise || exerciseSet.exercise_instance, 'title', '');

    if (exerciseId) {
      return {
        key: exerciseId,
        label: exerciseTitle,
        value: exerciseId,
      };
    }

    return null;
  };

  onSelectExercise = async data => {
    const {
      sectionFormat,
      fields,
      categories,
      unit,
      mode,
      dispatch,
      setIndex,
      exIndex,
      modelExerciseLibrary,
      getExercisesByIds,
      onChange,
      sectionIndex,
    } = this.props;

    const oldExerciseSet = _.cloneDeep(this.state.exerciseSet);

    if (data && !data.value) {
      return this.setState({
        createNewExercise: data.textSearch || true,
        viewingExercise: null,
      });
    }

    // let currentExercise = this.state.exerciseSet;
    const currentExercise = _.cloneDeep(this.state.exerciseSet);

    const currentSetsType = [];
    !!oldExerciseSet.training_sets &&
      oldExerciseSet.training_sets.forEach(it => {
        currentSetsType.push(it.set_type);
      });

    // let isSelectedNewExercise = true;
    const useORM = this.isUseOneRepMaxPercent(currentExercise);

    // if (currentExercise && currentExercise.exercise_instance) {
    //   if (typeof currentExercise.exercise_instance === 'string') {
    //     if (data.value == currentExercise.exercise_instance) {
    //       isSelectedNewExercise = false;
    //     }
    //   } else {
    //     if (data.value == currentExercise.exercise_instance._id) {
    //       isSelectedNewExercise = false;
    //     }
    //   }
    // }

    const isSelectedNewExercise =
      typeof currentExercise.exercise_instance === 'string'
        ? !_.isEqual(data.value, currentExercise.exercise_instance)
        : !_.isEqual(data.value, (currentExercise.exercise_instance || {})._id);

    if (currentExercise && currentExercise.alternatives && currentExercise.alternatives.length) {
      const isExistAlternate = _.find(currentExercise.alternatives, ex => ex._id === data.value);
      if (isExistAlternate) {
        return toast.error('Cannot add same alternative exercise with the original exercise');
      }
    }

    if (isSelectedNewExercise) {
      const selectedEx = _.cloneDeep(data);
      delete selectedEx.value;
      delete selectedEx.key;
      delete selectedEx.label;

      const exerciseInstance = initExerciseInstance(selectedEx);
      const intervalSection = sectionFormat === SECTION_FORMAT_KEY.INTERVAL;

      if (intervalSection) {
        exerciseInstance.fields = initIntervalExerciseFields(exerciseInstance, categories, fields);
      }

      const showUseOrmPercent = this.shouldShowOneRepMaxPercent(exerciseInstance.fields || []);

      if (useORM && showUseOrmPercent) {
        exerciseInstance.fields = initFieldsWithORM(exerciseInstance.fields, fields);
      }

      currentExercise.exercise = selectedEx;
      currentExercise.exercise_instance = exerciseInstance;
      currentExercise.each_side = false;
      currentExercise.tempo = '0';
      currentExercise.one_rep_max = selectedEx.one_rep_max;

      const initSetParams = {
        oldSets: currentExercise.training_sets,
        newFields: exerciseInstance.fields,
        systemFields: fields,
        units: unit,
      };

      currentExercise.training_sets = initSetsData(initSetParams, !!intervalSection);

      if (currentSetsType) {
        currentExercise.training_sets.forEach((item, index) => {
          item.set_type = _.get(currentSetsType, `[${index}]`, '');
        });
      }

      if (mode === MODES.TRACKING) {
        if (useORM) {
          currentExercise.training_sets = this.calculateSetValueBasedOnORM(
            currentExercise.training_sets,
            currentExercise.one_rep_max,
          );
        }
      }

      // note
      if (_.isEmpty(currentExercise.note)) {
        currentExercise.note = selectedEx.note || '';
      }

      // alternatives
      if (_.isEmpty(currentExercise.alternatives) && selectedEx.alternatives) {
        let isExistExercise = false;

        if (modelExerciseLibrary) {
          isExistExercise = selectedEx.alternatives.every(id => modelExerciseLibrary.hasIn([id]));
          if (isExistExercise) {
            currentExercise.alternatives = selectedEx.alternatives.map(id => modelExerciseLibrary.getIn([id]).toJS());
          }
        }

        if (!isExistExercise) {
          const responseExercises = await getExercisesByIds({ ids: selectedEx.alternatives, isUpdateEntities: false });
          const { data: { data = [] } = {} } = responseExercises || {};

          if (data) {
            currentExercise.alternatives = data;
          }
        }
      }

      this.isChangingExercise = true;
      this.setState({ exerciseSet: currentExercise });

      if (this.props.onAdd) {
        this.props.onAdd(currentExercise);
      }

      if (mode === MODES.TRACKING && typeof onChange === 'function') {
        onChange({ sectionIndex, setIndex, exIndex, exerciseSet: currentExercise });
      }
    }
  };

  renderSetTitle(field) {
    const { unit } = this.props;

    let title = field.title.replace(' (short)', '');
    let unitTitle = '';

    if (unit[field.unique_code]) {
      unitTitle = '(' + unit[field.unique_code].title + ')';
    }

    return title + unitTitle;
  }

  renderWeightTooltip(field) {
    let is_use_1rm = this.isUseOneRepMaxPercent(this.state.exerciseSet);

    if (!is_use_1rm) {
      return null;
    }

    if (is_use_1rm && field.unique_code === 'weight') {
      return (
        <span>
          <ReactTooltip id="orm-tooltip" effect="solid" place={'top'} delayShow={100}>
            <span>Weight assigned based on most recent 1RM estimation</span>
          </ReactTooltip>
          <Image
            data-tip="tooltip"
            data-for="orm-tooltip"
            src={`${CDN_URL}/images/info_circle.svg`}
            width={10}
            height={10}
            style={{ float: 'right', marginLeft: '5px', marginTop: '1px' }}
          />
        </span>
      );
    }
  }

  renderErrorMarkAll() {
    const { exerciseSet } = this.state;
    const { tracking, fields, currentRound } = this.props;

    if (!tracking || !exerciseSet.training_sets || exerciseSet.training_sets.length === 0) return null;

    const updateWeightTrainingSets = _.get(exerciseSet, 'training_sets', []).map(it => {
      // Set default value weight field
      const weight = _.get(it, 'weight');
      if (weight && !weight.value && weight.is_completed) {
        it['weight'].value = 0;
      }
      return it;
    });

    // Checking round complete
    if (currentRound + 1 >= 1) {
      const set = updateWeightTrainingSets[currentRound];

      if (!set.is_completed) {
        return null;
      }

      const checkingSet = _.omit(set, [
        'is_completed',
        'id',
        '_id',
        'rest',
        'duration',
        'set_type',
        'targetTrainingSet',
        'each_side_mode',
        'records',
        ...AUTO_FILL_FIELDS,
      ]);

      const keys = Object.keys(checkingSet);

      const fieldsData = keys.map(key => {
        return !!checkingSet[key].value;
      });

      return fieldsData.some(item => !item) ? (
        <MarkAllErrorText>Please fill out required fields before checking set off.</MarkAllErrorText>
      ) : null;
    }

    const isValid = fields.map(field => {
      if ([...AUTO_FILL_FIELDS, 'rest', 'duration'].includes(field.unique_code)) {
        return true;
      }
      return !updateWeightTrainingSets.some(item => {
        return item.is_completed && item[field.unique_code] && !item[field.unique_code].value;
      });
    });

    return isValid.some(item => !item) ? (
      <MarkAllErrorText>Please fill out required fields before checking set off.</MarkAllErrorText>
    ) : null;
  }

  renderSetTable() {
    const exerciseSet = _.get(this, 'state.exerciseSet', new ExerciseSet());
    const { fields, unit, mode } = this.props;

    if (!exerciseSet.exercise_instance || !exerciseSet.training_sets || exerciseSet.training_sets.length === 0) {
      return null;
    }

    const isTracking = mode === 'tracking';
    const exercise = exerciseSet.exercise_instance;
    let restField = _.find(fields, f => f.unique_code === 'rest');
    let parsedFields = [];

    _.forEach(exercise.fields, fieldId => {
      let fieldObj = _.find(fields, item => item._id === fieldId);

      if (fieldObj) {
        parsedFields.push(fieldObj);
      }
    });

    if (!_.find(parsedFields, f => f.unique_code === 'rest') && restField) {
      parsedFields.push(restField);
    }

    if (isTracking) {
      parsedFields.push({
        unique_code: 'is_completed',
        title: '',
        _id: 'set_check_mark',
        data_type: 'is_completed',
      });
    } else {
      if (!_.find(parsedFields, f => f.unique_code === 'rest') && restField) {
        parsedFields.push(restField);
      }
    }

    return (
      <div className="exercise-table-container" style={isTracking ? { marginLeft: '23px' } : {}}>
        <table className={'ui table no-border exercise-header-table'} compact="true">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                key="set_number"
                className="exercise-header-title"
                style={isTracking ? { paddingBottom: '11px' } : {}}
              >
                Set
              </Table.HeaderCell>
              {parsedFields.map(field => (
                <Table.HeaderCell
                  key={field._id}
                  className="exercise-header-title"
                  style={
                    isTracking
                      ? {
                          ...(field.unique_code === 'weight' ? { display: 'flex', alignItems: 'center' } : {}),
                          paddingBottom: '11px',
                        }
                      : {}
                  }
                >
                  {this.renderSetTitle(field)}
                  {this.renderWeightTooltip(field)}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
        </table>
        <table className={'ui table no-border exercise-body-table'} compact="true">
          <Table.Body>{this.renderSetList(parsedFields)}</Table.Body>
        </table>
      </div>
    );
  }

  renderDeleteSets = (exerciseSet, trainingSets, index) => {
    const { addSetsSuperset } = this.props;
    let exerciseSets = exerciseSet;
    let trainingSetList = trainingSets || [];

    const handleDeleteSets = () => {
      exerciseSets.training_sets = trainingSets.filter((item, key) => key !== index);
      this.setState({ exerciseSet: exerciseSets });

      if (addSetsSuperset) {
        addSetsSuperset({ exerciseSet: exerciseSets, _, setIndex: index });
      }
    };

    if (trainingSetList.length < 2) return;
    return (
      <DeleteSetsIcon className="delete-sets" onClick={handleDeleteSets}>
        <CircleIcon />
      </DeleteSetsIcon>
    );
  };

  renderSetList = setFields => {
    const exerciseSet = _.get(this, 'state.exerciseSet', new ExerciseSet());
    const { mode, OneRepMaxModel, client, unit, isHaveRound, currentRound } = this.props;
    const trainingSets = exerciseSet.training_sets;
    let is_use_1rm = this.isUseOneRepMaxPercent(exerciseSet);
    let one_rep_max = exerciseSet.one_rep_max || {};
    if (!one_rep_max.value && OneRepMaxModel && client && client._id) {
      const exerciseId = _.get(exerciseSet.exercise || exerciseSet.exercise_instance, '_id');
      const ormModel = OneRepMaxModel.getIn([[client._id, exerciseId].join('_')]);
      one_rep_max = ormModel ? ormModel.toJS() : one_rep_max;
    }
    const checkFields = setFields.filter(
      field => !['weight', 'rest', 'duration', 'is_completed'].includes(_.get(field, 'unique_code', '')),
    );
    const isValid = checkFields.map(field => {
      return trainingSets.map(item => {
        if (['rir', 'hr'].includes(field.unique_code) && _.get(item, `[${field.unique_code}].value`) === 0) {
          return true;
        }
        return !!_.get(item, `[${field.unique_code}].value`, false);
      });
    });

    return (
      <React.Fragment>
        {_.map(trainingSets, (set, index) => {
          if (isHaveRound && currentRound !== index) {
            return null;
          }
          let oneRepMaxValue;

          if (is_use_1rm) {
            const ormSet = set.orm;

            if (ormSet) {
              oneRepMaxValue = Number(ormSet.value) || 0;
            } else {
              oneRepMaxValue = 0;
            }
          }

          return (
            <Table.Row key={index}>
              <Table.Cell>
                {this.renderDeleteSets(exerciseSet, trainingSets, index)}
                <div className="set-table-cell-index">
                  <div>{this.renderSetType(index)}</div>
                  {this.props.mode !== 'tracking' && (
                    <div className="delete-set-button-container">
                      <Image src={`${CDN_URL}/images/remove_purple.svg`} onClick={() => this.handleDeleteSet(index)} />
                    </div>
                  )}
                </div>
              </Table.Cell>
              {_.map(setFields, field => {
                let disabled = false,
                  fieldData = { ...field };

                if (field.unique_code === 'is_completed') {
                  fieldData.value = set.is_completed;
                } else {
                  fieldData.value = _.get(set[field.unique_code], 'value', '');

                  if (is_use_1rm && ORM_FIELDS.includes(field.unique_code)) {
                    if (mode === MODES.TRACKING) {
                      if (field.unique_code === 'orm') {
                        disabled = true;
                      }
                    } else {
                      if (field.unique_code === 'weight') {
                        disabled = true;
                        fieldData.value =
                          (convertUnit(Number(one_rep_max.value) || 0, one_rep_max.set_unit, unit.weight) *
                            oneRepMaxValue) /
                          100;
                        one_rep_max.set_unit = unit.weight;
                      }
                    }
                  }
                }

                return (
                  <Table.Cell
                    key={field.unique_code}
                    className={classnames('set-table-cell', {
                      isBorder: !_.get(fieldData, 'value', ''),
                    })}
                  >
                    {this.renderFieldInput(
                      fieldData,
                      index,
                      disabled,
                      set.is_completed,
                      mode === MODES.TRACKING,
                      isValid,
                    )}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </React.Fragment>
    );
  };

  updateSetCompleted(index, checked) {
    const { exerciseSet } = this.state;
    const { setIndex, exIndex, mode } = this.props;
    let set = exerciseSet.training_sets[index];
    set.is_completed = checked;

    // Set default value fields
    autoFillInput(set, AUTO_FILL_FIELDS);

    this.setState({
      exerciseSet: exerciseSet,
    });

    if (this.props.onChange) {
      if (this.props.mode === 'tracking') {
        this.props.onChange({ exerciseSet, setIndex, exIndex, isTrackingSetCompleted: true });
      }
      this.props.onChange({ exerciseSet, setIndex, exIndex });
    }
  }

  convertUnitWhenMount(setIndex, unique_code, value, newUnit) {
    this.updateSetData(setIndex, unique_code, value, false, newUnit);
    const clonedExerciseSet = _.cloneDeep(this.state.exerciseSet);
    this.setState({ originSet: clonedExerciseSet });
  }

  updateSetData(index, attribute, value, idEdited) {
    const { exerciseSet } = this.state;
    const { setIndex, exIndex } = this.props;

    let currentAddSet = _.cloneDeep(exerciseSet.training_sets[index]);

    if (!currentAddSet[attribute]) {
      currentAddSet[attribute] = {};
    }

    currentAddSet[attribute].value = value;
    if (idEdited) {
      currentAddSet[attribute].idEdited = true;
    }

    exerciseSet.training_sets[index] = currentAddSet; // TODO - IMPORTANT

    this.setState({
      exerciseSet: exerciseSet,
    });

    if (this.props.onChange) {
      this.props.onChange({ exerciseSet, setIndex, exIndex });
    }
  }

  renderSetType = idx => {
    const { exerciseSet } = this.state;
    const { isHaveRound, mode } = this.props;
    const trainingSets = exerciseSet.training_sets;
    const setType = _.get(trainingSets, `[${idx}].set_type`, '');

    return (
      <SelectSetType
        key={idx}
        setIndex={idx}
        value={setType}
        disabled={mode === 'tracking'}
        isHaveRound={isHaveRound}
        onSelect={value => {
          let set = exerciseSet;
          set.training_sets[idx].set_type = value;
          this.setState({ exerciseSet: set });
        }}
      />
    );
  };

  renderActionButtons() {
    const { exerciseSet } = this.state;
    const { setIndex, exIndex, disabled, mode } = this.props;
    const tracking = mode === MODES.TRACKING;

    if (!exerciseSet.exercise_instance) {
      return null;
    }

    const tempoValue = !exerciseSet.tempo || exerciseSet.tempo == '0' ? '' : exerciseSet.tempo;
    let placeholder = 'X-X-X-X';

    if (tempoValue.length < 7) {
      if (tempoValue) {
        let tempoArray = tempoValue.split('-');

        for (let i = 0; i < 4; i++) {
          let value = tempoArray[i];

          if (!value) {
            tempoArray[i] = 0;
          }
        }

        placeholder = tempoArray.join('-');
      }
    } else {
      placeholder = '';
    }
    const isInfo = exerciseSet.each_side || (exerciseSet.tempo && exerciseSet.tempo !== '0');

    return (
      <div
        className="exercise-action-buttons"
        style={tracking ? { margin: '12px 0px 15px 0px', paddingLeft: '23px' } : {}}
      >
        {!tracking ? (
          <>
            {this.renderNoteButton()}
            <Checkbox
              className={`each-side-checkbox ${!exerciseSet.each_side ? 'no-value' : ''}`}
              title="Each side"
              checked={exerciseSet.each_side}
              disabled={disabled}
              onChange={e => {
                if (disabled) {
                  return;
                }

                let exerciseSet = this.state.exerciseSet;
                exerciseSet.each_side = e.target.checked;
                this.setState({ exerciseSet });

                if (this.props.onChange) {
                  this.props.onChange({ exerciseSet, setIndex, exIndex });
                }
              }}
            />
            <div className={`tempo-container ${!tempoValue ? 'no-value' : ''}`}>
              <span>Tempo</span>
              <div className="ui input tempo">
                <Cleave
                  value={tempoValue.toUpperCase()}
                  className="tempo__input"
                  options={{
                    blocks: [1, 1, 1, 1],
                    delimiter: '-',
                    numericOnly: false,
                  }}
                  onChange={e => {
                    if (disabled) {
                      return;
                    }

                    let value = e.target.value || e.target.value === 0 ? e.target.value + '' : '';
                    let valueArray = _.map(value.split('-'), v => {
                      return v === ' ' ? '0' : v;
                    });
                    let str = valueArray.join('-');
                    let exerciseSet = this.state.exerciseSet;
                    exerciseSet.tempo = str.toUpperCase();
                    this.setState({ exerciseSet });

                    if (this.props.onChange) {
                      this.props.onChange({ exerciseSet, setIndex, exIndex });
                    }
                  }}
                  disabled={disabled}
                />
                <input type="text" className="tempo__placeholder" value={placeholder.toUpperCase()} disabled />
              </div>
            </div>
          </>
        ) : (
          <ExerciseInfo isInfo={isInfo}>
            {exerciseSet.each_side ? <strong>Each Side</strong> : null}
            {exerciseSet.each_side && exerciseSet.tempo && exerciseSet.tempo !== '0' ? (
              <div className="divider" />
            ) : null}
            {exerciseSet.tempo && exerciseSet.tempo !== '0' ? (
              <span>
                Tempo <strong>{exerciseSet.tempo.replaceAll('-', ' - ')}</strong>
              </span>
            ) : null}
          </ExerciseInfo>
        )}
        {this.renderAddSetButton()}
      </div>
    );
  }

  renderAddSetButton() {
    const { mode, sectionFormat, disabled } = this.props;
    const { exerciseSet } = this.state;
    const tracking = mode === MODES.TRACKING;

    const maxSets = sectionFormat === SECTION_FORMAT_KEY.AMRAP || sectionFormat === SECTION_FORMAT_KEY.TIMED ? 1 : 0;

    if (maxSets && exerciseSet.training_sets && exerciseSet.training_sets.length >= maxSets) {
      return null;
    }

    let options = [];

    for (let i = 2; i < 9; i++) {
      options.push({ key: `set${i}`, text: `Set ${i}`, value: i });
    }

    return (
      <div className={classnames(tracking ? 'add-set-buttons-tracking' : 'add-set-buttons', { disabled: disabled })}>
        <div className="add-set" onClick={this.handleAddSetAction}>
          Add Set
        </div>
        <AddMultipleSets
          className="add-set-dropdown"
          onOpen={rectObj => {
            this.currentAddsetButtonRect = rectObj;
          }}
          onSelect={this.handleAddMultiset}
          max={8}
          disabled={disabled}
        />
      </div>
    );
  }

  renderNoteButton() {
    let note;
    if (this.props.mode === 'tracking') {
      note = this.state.exerciseSet.comment;
    } else {
      note = this.state.exerciseSet.note;
    }
    if (!note && !this.state.isAddingNote) {
      return (
        <div className="note-button add-note-button" onClick={() => this.handleAddNote()}>
          <Image src={`${CDN_URL}/images/plus_circle_white.svg`} />
          <div>Add note</div>
        </div>
      );
    }

    return (
      <div className="note-button delete-note-button" onClick={() => this.handleDeleteNote()}>
        <Image src={`${CDN_URL}/images/delete_note.svg`} />
        <div>Remove note</div>
      </div>
    );
  }

  handleDeleteNote() {
    let exerciseSet = this.state.exerciseSet;
    exerciseSet.note = '';
    this.setState(prevState => ({
      exerciseSet,
      isAddingNote: false,
      shouldFocusNote: false,
    }));
  }

  handleAddNote() {
    if (this.props.disabled) {
      return;
    }

    this.setState(prevState => ({
      isAddingNote: true,
      shouldFocusNote: true,
    }));
  }

  handleDeleteSet = setIdx => {
    const { exerciseSet } = this.state;
    const { setIndex, exIndex } = this.props;

    if (exerciseSet.training_sets.length > 1) {
      exerciseSet.training_sets.splice(setIdx, 1);
      if (this.props.onAddRemoveSet) {
        this.props.onAddRemoveSet(setIndex, exIndex);
      }
      this.setState({
        exerciseSet: exerciseSet,
      });
    } else {
      console.log('Should not remove if the removing set is only one set in this exercise');
    }
  };

  handleAddSetAction = event => {
    const { exerciseSet } = this.state;
    const { setIndex, exIndex, disabled, mode, addSetsSuperset } = this.props;

    if (disabled) {
      return;
    }

    let lastSet = _.cloneDeep(_.omit(exerciseSet.training_sets[exerciseSet.training_sets.length - 1], ['_id']));

    if (mode === MODES.TRACKING) {
      lastSet = { ...lastSet, is_completed: false };
    }

    exerciseSet.training_sets.push(lastSet);

    if (this.props.onAddRemoveSet) {
      const target = event.currentTarget;

      if (target && typeof target.getBoundingClientRect === 'function') {
        this.currentAddsetButtonRect = target.getBoundingClientRect() || {};
      }

      this.props.onAddRemoveSet(setIndex, exIndex, {
        addSet: true,
        quantity: 1,
        rect: this.currentAddsetButtonRect,
      });
    }

    if (addSetsSuperset) {
      addSetsSuperset({ exerciseSet, quantity: 1, _ });
    }

    this.setState({ exerciseSet: exerciseSet });
  };

  handleAddMultiset = quantity => {
    const { exerciseSet } = this.state;
    const { setIndex, exIndex, disabled, mode, addSetsSuperset } = this.props;

    if (disabled) {
      return;
    }

    let lastSet = _.omit(exerciseSet.training_sets[exerciseSet.training_sets.length - 1], ['_id']);

    if (mode === MODES.TRACKING) {
      lastSet = { ...lastSet, is_completed: false };
    }

    for (let i = 0; i < quantity; i++) {
      exerciseSet.training_sets.push(_.cloneDeep(lastSet));
    }

    if (this.props.onAddRemoveSet) {
      this.props.onAddRemoveSet(setIndex, exIndex, {
        addSet: true,
        quantity,
        rect: this.currentAddsetButtonRect,
      });
    }

    if (addSetsSuperset) {
      addSetsSuperset({ exerciseSet, quantity, _ });
    }

    this.setState({
      exerciseSet: exerciseSet,
    });
  };

  renderError() {
    if (this.state.error !== '') {
      return <div className="error exercise-error">{this.state.error}</div>;
    }
    return null;
  }

  handleContentChange = event => {
    event.preventDefault();
  };

  renderNote() {
    const { setIndex, exIndex, tracking } = this.props;
    const note = this.state.exerciseSet.note;
    if (!note && !this.state.isAddingNote) {
      return null;
    }
    if (this.state.shouldFocusNote) {
      this.setState(prevState => ({
        shouldFocusNote: false,
      }));
    }

    return (
      <div className="note-input-container" style={tracking && { marginLeft: '23px' }}>
        {tracking ? (
          <ExerciseNote contentEditable={true} onKeyDown={this.handleContentChange} spellCheck={false}>
            {note}
          </ExerciseNote>
        ) : (
          <TextArea
            className="note-input"
            value={note}
            placeholder="Add note"
            onChange={(evt, data) => {
              let exerciseSet = this.state.exerciseSet;
              exerciseSet.note = data.value;
              this.setState(prevState => ({
                exerciseSet,
              }));

              if (this.props.onChange) {
                this.props.onChange({ exerciseSet, setIndex, exIndex });
              }
            }}
          />
        )}
      </div>
    );
  }

  handleAddExercise = e => {
    this.props.onAdd(this.state.exerciseSet);
  };

  handleCancelAdd = () => {
    const { onCancel } = this.props;

    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  handleMoveExercise = destination => {
    const { onMove, setIndex, exIndex } = this.props;

    if (typeof onMove === 'function') {
      onMove({ source: { setIndex, exIndex }, destination });
    }
  };

  renderSubmitButton() {
    const { submitStatus, mode, disabled } = this.props;

    if (mode === 'new') {
      return (
        <div className="exercise-footer add-new-exercise">
          <a onClick={this.handleCancelAdd}>Cancel</a>
        </div>
      );
    } else if (['popup', 'program_library_workout_popup'].includes(mode)) {
      return (
        <GeneralButton onClick={() => this.handleSaveAction()} disabled={disabled}>
          Save
        </GeneralButton>
      );
    } else if (['new_popup', 'program_library_workout_new_popup'].includes(mode)) {
      return (
        <div className="exercise-footer">
          <GeneralButton onClick={() => this.handleSaveAction()}>
            {submitStatus.status === 0 ? 'Adding...' : submitStatus.status > 0 && submitStatus.success ? 'Done' : 'Add'}
          </GeneralButton>
        </div>
      );
    } else {
      return null;
    }
  }

  handleSaveAction = () => {
    if (this.props.disabled) {
      return;
    }

    let { exerciseSet } = this.state;

    if (!exerciseSet.training_sets || exerciseSet.training_sets.length == 0) {
      console.log('Empty set! Should add error handling here');
      return;
    }

    let ormError = '';

    for (let idz = 0; idz < exerciseSet.training_sets.length; idz++) {
      _.forEach(exerciseSet.training_sets[idz], (data, key) => {
        if (key === 'orm') {
          if (!data || !data.value || data.value == '') {
            ormError = '%1rm value is required.';
            return false;
          } else {
            let orm_value = parseInt(data.value);
            if (isNaN(orm_value)) {
              ormError = '%1rm value must be a number.';
              return false;
            } else if (orm_value < 0) {
              ormError = '%1rm value cannot be negative.';
              return false;
            }
          }
        }
        if (key === 'reps' && data.value === false) {
          ormError = 'Reps is required';
          return false;
        }
        if (key === 'hr' && data.value === false) {
          ormError = '%HR is required';
          return false;
        }
        if (key === 'rir' && data.value === false) {
          ormError = 'RIR is required';
          return false;
        }
      });
    }
    if (ormError !== '') {
      this.setState({ error: ormError });
      return;
    } else {
      this.setState({ error: '' });
    }

    const {
      assignment,
      selectedClientData,
      setIndex,
      exIndex,
      sectionIndex,
      unit,
      mode,
      selectedClient,
      workout,
      selectedProgram,
      saveCurrentExercise,
      addExerciseAndSave,
      saveCurrentExerciseForProgramLibraryWorkout,
      addExerciseAndSaveForProgramLibraryWorkout,
      submitStatus,
      onSave,
    } = this.props;

    if (!submitStatus.status) {
      return false;
    }

    let exerciseJson;

    if (typeof onSave === 'function') {
      return onSave({ assignment, exercise: exerciseSet });
    }

    switch (mode) {
      case 'popup':
        return saveCurrentExercise({
          exercise: exerciseSet,
          sectionIndex,
          setIndex,
          exIndex,
          assignment,
          client: selectedClientData,
        });

      case 'new_popup':
        exerciseJson = convertFromExerciseSetToSupersetsJson(exerciseSet);

        return addExerciseAndSave({ exercise: exerciseJson, assignment: assignment._id, client: selectedClient });

      case 'program_library_workout_popup':
        if (!workout || !workout._id) {
          return;
        }

        return saveCurrentExerciseForProgramLibraryWorkout({
          exercise: exerciseSet,
          sectionIndex,
          setIndex,
          exIndex,
          workout,
          programId: selectedProgram._id,
          trainer_unit: unit,
        });

      case 'program_library_workout_new_popup':
        if (!workout || !workout._id) {
          return false;
        }

        exerciseJson = convertFromExerciseSetToSupersetsJson(exerciseSet);

        return addExerciseAndSaveForProgramLibraryWorkout({
          exercise: exerciseJson,
          workoutId: workout._id,
          programId: selectedProgram._id,
        });

      default:
        return;
    }
  };
}
