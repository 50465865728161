import styled, { css } from 'styled-components';

export const Input = styled.input`
  background: #fff;
  border: 1px solid #dcdcde;
  box-sizing: border-box;
  border-radius: 4px;
  height: 36px;
  color: #000;
  font-size: 13px;
  line-height: 18px;
  padding: 5px 0;
  outline: none !important;
  width: 40px;
  text-align: center;
  font-weight: 400;
  &.error {
    color: #000;
    border-color: #ea314a;
  }

  ${props =>
    props.habit &&
    css`
      width: 37px;
      border: 1px solid #dadfea;

      :hover:not(.error) {
        border-color: #5158cf;
      }
    `}
`;
export const EWrapper = styled.div`
  margin-right: 5px;
  .evfSelectBox__menu {
    width: 127px;
  }

  ${props =>
    props.habit &&
    css`
      margin-right: 4px;

      .evfSelectBox__menu {
        width: 102px;
      }

      .evfSelectBoxContainer {
        .evfSelectBox__menu {
          margin-top: 4px;
        }

        .evfSelectBox__control {
          padding-right: 12px;

          :hover {
            border: 1px solid #5158cf;
          }
        }

        .evfSelectBox__control .evfSelectBox__single-value {
          font-weight: 400;
          color: #000;
        }

        .evfSelectBox__menu-list {
          padding: 7px 0;
        }
      }
    `}
`;
