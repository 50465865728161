export const Types = {
  UPLOAD_MEDIA_BACKGROUND_REQUEST: 'UPLOAD_MEDIA_BACKGROUND_REQUEST',
  UPLOAD_MEDIA_BACKGROUND_SUCCESS: 'UPLOAD_MEDIA_BACKGROUND_SUCCESS',
};

export const uploadMediaBackgroundRequest = ids => {
  return dispatch => {
    dispatch({ type: Types.UPLOAD_MEDIA_BACKGROUND_REQUEST, data: ids });
  };
};

export const uploadMediaBackgroundSuccess = ids => {
  return dispatch => {
    dispatch({ type: Types.UPLOAD_MEDIA_BACKGROUND_SUCCESS, data: ids });
  };
};
