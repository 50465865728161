import { ReactComponent as DeleteIcon } from 'assets/icons/delete_message_trash_icon.svg';
import get from 'lodash/get';
import React, { useState } from 'react';
import ProgressPhotoTag from 'shared/ProgressPhotoTag';
import PlaceholderLoader from '../../PlaceholderLoader';
import * as S from './style';
import { getThumbnailProgressPhoto } from 'components/EditProgressPhoto/helper';

const ItemPhoto = ({ handleDeletePopup, item, parsedDate, onChooseTag }) => {
  const [loaded, setLoaded] = useState(!window.Cypress ? false : true);

  const onLoad = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  };

  return (
    <S.WrapperAttachment>
      <S.ImageWrapper>
        {!window.Cypress ? (
          <S.S3ImageWithFallbackWrapper
            loaded={loaded}
            onLoad={onLoad}
            src={getThumbnailProgressPhoto([get(item, 'image', '')])}
          />
        ) : (
          <S.MockupImg></S.MockupImg>
        )}

        {!loaded ? (
          <PlaceholderLoader bgBlack />
        ) : (
          <>
            <S.DeleteButton isCypress={window.Cypress} onClick={handleDeletePopup}>
              <DeleteIcon />
            </S.DeleteButton>
            <S.PostedDate>{parsedDate}</S.PostedDate>
            <S.TagWrapper isCypress={window.Cypress}>
              <ProgressPhotoTag value={get(item, 'tag', '')} onChooseTag={onChooseTag} transparent />
            </S.TagWrapper>
            <S.Overlay />
          </>
        )}
      </S.ImageWrapper>
    </S.WrapperAttachment>
  );
};

export default ItemPhoto;
