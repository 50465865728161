import React, { useEffect, useMemo, useState } from 'react';
import { Button, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getZapierUsageHistory } from 'redux/pricing/actions';
import { formatSameYear } from 'utils/commonFunction';

import { CDN_URL } from 'constants/commonData';

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import * as S from './style';
import LoadingIndicator from 'shared/LoadingIndicator';

const init_params = {
  page: 1,
  per_page: 20,
  sort: -1,
};

const HistoricalModal = props => {
  const { toggleModal, getZapierUsageHistory, zapierUsageHistory, zapierUsageLoading } = props;
  const zapierUsageHistoryJS = zapierUsageHistory.toJS();
  const [params, setParams] = useState(init_params);

  useEffect(() => {
    getZapierUsageHistory(params);
  }, [params.page]);

  const handleScrollEnd = event => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 10;
    const lastPage = Math.ceil(get(zapierUsageHistoryJS, 'total', 0) / get(params, 'per_page', 0));
    const isLastPage = get(params, 'page', 0) === lastPage;

    if (bottom && !isLastPage && get(zapierUsageHistoryJS, 'total', 0) > 0) {
      setParams({ ...params, page: params.page + 1 });
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const isFirstLoading = useMemo(() => get(params, 'page', 0) === 1 && zapierUsageLoading, [
    get(params, 'page', 0),
    zapierUsageLoading,
  ]);

  const isShowLoadMore = useMemo(() => get(params, 'page', 0) > 1 && zapierUsageLoading, [
    get(params, 'page', 0),
    zapierUsageLoading,
  ]);

  const isShowTotalHistorical = useMemo(
    () => !zapierUsageLoading || (zapierUsageLoading && get(params, 'page', 0) > 1),
    [get(params, 'page', 0), zapierUsageLoading],
  );

  return (
    <S.ModalWrapper open={true}>
      <S.ModalContainer>
        <Button className="close-button" onClick={() => toggleModal(false)}>
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <S.ModalHeader>Zapier Usage History</S.ModalHeader>
        <S.ModalContent>
          <S.TableContainer>
            <S.HeaderTable>
              <S.LeftHeader>Period </S.LeftHeader>
              <S.RightHeader>Tasks </S.RightHeader>
            </S.HeaderTable>

            {!zapierUsageLoading && !get(zapierUsageHistoryJS, 'total', 0) ? (
              <S.EmptyContent>
                <S.EmptyLabel>No Zapier history.</S.EmptyLabel>
              </S.EmptyContent>
            ) : (
              <>
                <S.ContentTable
                  onScroll={event => {
                    event.persist();
                    onScrollDebounce.call(null, event);
                  }}
                >
                  {isFirstLoading && (
                    <S.FirstLoad>
                      <LoadingIndicator className="loading-indicator" title="Loading..." />
                    </S.FirstLoad>
                  )}
                  {zapierUsageHistoryJS &&
                    get(zapierUsageHistoryJS, 'list', []).map(item => {
                      return (
                        <S.TableItem key={get(item, '_id')}>
                          <S.LeftContent>
                            {formatSameYear(get(item, 'period_start'))} - {formatSameYear(get(item, 'period_end'))}
                          </S.LeftContent>
                          <S.RightContent>{get(item, 'total_request').toLocaleString('en-US')}</S.RightContent>
                        </S.TableItem>
                      );
                    })}
                  {isShowLoadMore && <LoadingIndicator className="loading-indicator" title="Loading more..." />}
                </S.ContentTable>
                {isShowTotalHistorical && (
                  <S.FooterTable>
                    <S.TotalLabel>Total </S.TotalLabel>
                    <S.TotalTasks>{get(zapierUsageHistoryJS, 'total_request', 0).toLocaleString('en-US')}</S.TotalTasks>
                  </S.FooterTable>
                )}
              </>
            )}
          </S.TableContainer>
        </S.ModalContent>
      </S.ModalContainer>
    </S.ModalWrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;

  return {
    zapierUsageHistory: rootReducer.pricing.get('zapierUsageHistory'),
    zapierUsageLoading: rootReducer.pricing.get('zapierUsageLoading'),
  };
};

const mapDispatchToProps = dispatch => ({
  getZapierUsageHistory: bindActionCreators(getZapierUsageHistory, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalModal);
