// Libs
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

// Actions
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { addTrainerInviteCodes, deleteTrainerInviteCodes } from 'redux/onboarding-flow/actions';

// Components
import ButtonCreate from '../ButtonCreate';
import ListModal from '../ListModal';
import ListInviteLink from './ListInviteLink';
import ConfirmModal from '../ConfirmModal';

// Utils
import { SETTINGS_TYPE_MODAL } from '../constants';
import { copyToClipboard } from 'utils/commonFunction';
import { isAdmin, isOnwer } from 'utils/validations';
import { CDN_URL, TEAM_SHARE_PRIVATE } from 'constants/commonData';

// Styles
import * as S from './style';

const CreateInviteLink = ({
  user,
  share,
  authorId,
  isEnabled,
  toggleModal,
  cloudfrontList,
  listInviteCodes,
  isLoadingAction,
  isLoadingSetting,
  onboardingFlowId,
  isEnabledWorkspace,
  toggleConfirmModal,
  addTrainerInviteCodes,
  deleteTrainerInviteCodes,
}) => {
  const isEmptySelectedList = listInviteCodes.length === 0;
  const isArchived = false;
  const isAlreadyAdd = (listInviteCodes || []).some(({ trainer }) => (trainer || {})._id === (user || {})._id);

  const handleSubmit = selectedList => {
    if (isLoadingAction) return;

    const selectedListIds = (selectedList || []).map(({ _id }) => _id).filter(item => !isNil(item));

    const bodyData = {
      trainers: selectedListIds,
    };

    typeof addTrainerInviteCodes === 'function' && addTrainerInviteCodes(onboardingFlowId, bodyData);
  };

  const handleAddCoach = () => {
    handleSubmit([user]);
  };

  const handleRemove = trainerId => {
    if (isLoadingAction) return;

    typeof deleteTrainerInviteCodes === 'function' &&
      deleteTrainerInviteCodes(onboardingFlowId, trainerId, () => {
        typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
      });
  };

  const handleCopy = text => {
    copyToClipboard(text);
    toast('Copied link to clipboard.');
  };

  const handleAddCoaches = () => {
    toggleModal(
      true,
      <ListModal
        user={user}
        title="Coaches"
        cloudfrontList={cloudfrontList}
        type={SETTINGS_TYPE_MODAL.COACHES}
        onClose={() => toggleModal(false)}
        toggleConfirmModal={toggleConfirmModal}
        onSubmit={handleSubmit}
        currentSelectedList={listInviteCodes}
      />,
    );
  };

  const handleOpenRemoveModal = item => {
    const { trainer } = item || {};
    const { _id, full_name = '', first_name = '', last_name = '' } = trainer || {};

    const fullName = full_name || `${first_name || ''} ${last_name || ''}`.trim();

    if (!isEmpty(item)) {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Remove Coach?"
          content={`Are you certain about removing this onboarding flow for clients who join from ${fullName}'s invite link? This change will only affect future users, not current ones.`}
          headerIcon={`${CDN_URL}/images/trash-circle.svg`}
          confirmBtnTitle="Remove"
          className="multiple-popup-red"
          onConfirm={() => handleRemove(_id)}
          onClose={() => toggleConfirmModal(false)}
        />,
      );
    }
  };

  return (
    <S.Wrapper
      isEmpty={isEmptySelectedList}
      isDisabled={isEnabled || isEnabledWorkspace || isArchived || isLoadingSetting}
    >
      <S.LeftSide className="left-side">
        <S.Header>Create Invite Link</S.Header>
        <S.Description>Generate a shareable invite link for this onboarding flow.</S.Description>
      </S.LeftSide>
      <S.RightSide className="right-side">
        {listInviteCodes.length > 0 && (
          <ListInviteLink
            user={user}
            list={listInviteCodes}
            cloudfrontList={cloudfrontList}
            onRemove={handleOpenRemoveModal}
            onCopy={handleCopy}
          />
        )}
        {(isAdmin(user) || isOnwer(user) || (user || {})._id === authorId) && share !== TEAM_SHARE_PRIVATE ? (
          <ButtonCreate title="Add Coaches" onClick={handleAddCoaches} disabled={isLoadingAction} />
        ) : (
          !isAlreadyAdd && (
            <ButtonCreate title="Generate Your Link" onClick={handleAddCoach} disabled={isLoadingAction} />
          )
        )}
      </S.RightSide>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    cloudfrontList,
    rootReducer: {
      onboardingFlow: { isLoadingSetting, isLoadingAction, workingSetting, workingData },
    },
  } = state;

  return {
    user,
    cloudfrontList,
    isLoadingAction,
    isLoadingSetting,
    listInviteCodes: get(workingSetting, 'invite_codes', []),
    onboardingFlowId: get(workingSetting, 'onboarding_flow', ''),
    share: get(workingData, 'share'),
    authorId: get(workingSetting, 'author'),
    isEnabledWorkspace: get(workingData, 'is_default_team', false),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addTrainerInviteCodes: bindActionCreators(addTrainerInviteCodes, dispatch),
  deleteTrainerInviteCodes: bindActionCreators(deleteTrainerInviteCodes, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInviteLink);
