import React, { useState } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';

import { getCurrentUsage } from 'redux/pricing/actions';
import { DropdownIndicator } from 'shared/Icons';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/learn-about-package.svg';
import { ranges } from './priceRanges.js';

import * as S from './style';

const MAX_BUDGET = 500;

const ZapierUsageLimit = ({ zapierCurrentUsage }) => {
  const [budget, setBudget] = useState();
  const [threshold, setThreshold] = useState();
  const [taskQuantity, setTaskQuantity] = useState('0');
  const [thresholdQuantity, setThresholdQuantity] = useState('0');

  const hasUsedZapier = zapierCurrentUsage.totalRequest;

  const parsedBudget = parseInt(budget);
  const thresholdValue = get(threshold, 'value', 0);
  const isErrorBudget = parsedBudget > MAX_BUDGET;
  const isSmallerBudget = parsedBudget < hasUsedZapier;

  const isEmptyBudget = isEmpty(budget) || parsedBudget === 0;
  const isEmptyThreshold = isEmpty(threshold) || thresholdValue === 0;

  const handleChangeBudget = ({ value }) => {
    setBudget(value);
    const range = ranges.find(({ maxPrice }) => value <= maxPrice);
    setTaskQuantity(range.tasks);
  };

  const handleSaveBudget = () => {
    toast(`Monthly budget updated.`);
    // Integrate API save budget/threshold
  };

  const handleChangeThreshold = option => {
    setThreshold(option);
    if (taskQuantity > 0) {
      const thresholdRange = (taskQuantity * option.value) / 100;
      setThresholdQuantity(thresholdRange);
    }
  };

  const CustomMenuList = props => {
    return <components.MenuList {...props}>{props.children}</components.MenuList>;
  };

  const options = [
    { value: 0, label: 'None' },
    ...Array.from({ length: 6 }, (_, index) => {
      const value = (index + 5) * 10;
      return { value, label: `${value}%` };
    }),
  ];

  const errorMessage = isErrorBudget
    ? 'The value must not reach the account limit'
    : isSmallerBudget
    ? 'Please input a value higher than your current usage'
    : null;

  const errorLimit = isErrorBudget || isSmallerBudget;

  const handleMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName('single-select__option--is-selected')[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
      }
    }, 10);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.TotalTask>
          <S.TitleWrapper>
            <S.BoldText>
              Set a monthly budget
              <S.FeatureLearnMore
                href="https://help.everfit.io/en/collections/6758136-zapier-integration"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LearnMoreIcon />
                <S.FeatureLinkTitle>Learn more</S.FeatureLinkTitle>
              </S.FeatureLearnMore>
            </S.BoldText>
            <S.Description>
              If your organization exceeds this budget in a given calendar month (UTC), subsequent API requests will be
              rejected
            </S.Description>
          </S.TitleWrapper>
          <S.ValueInputBudget limitBudget={MAX_BUDGET}>
            <S.InputWrapper>
              <NumberFormat
                onValueChange={handleChangeBudget}
                value={budget}
                decimalScale={0}
                allowNegative={false}
                className={classNames('threshold-value-input', { 'input-error': errorLimit })}
                maxLength={3}
              />
              <S.SaveButton disabled={errorLimit || isEmptyBudget} onClick={handleSaveBudget}>
                Save
              </S.SaveButton>
            </S.InputWrapper>
            {errorMessage ? (
              <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
            ) : (
              <S.Estimation>
                Estimation of{' '}
                <S.EstimationTask>{parseInt(taskQuantity).toLocaleString('en-US')} tasks</S.EstimationTask>
              </S.Estimation>
            )}
          </S.ValueInputBudget>
        </S.TotalTask>
        <S.TotalTask>
          <S.TitleWrapper>
            <S.BoldText>Set an email notification threshold</S.BoldText>
            <S.Description>
              If your organization exceeds this threshold in a given calendar month (UTC), an email notification will be
              sent
            </S.Description>
          </S.TitleWrapper>
          <S.ValueInputBudget>
            <S.InputWrapper>
              <S.ThresholdWrapper>
                <Select
                  options={options}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  components={{
                    IndicatorSeparator: null,
                    DropdownIndicator,
                    MenuList: CustomMenuList,
                  }}
                  classNamePrefix="single-select"
                  className="single-select-container__threshold"
                  placeholder="Select threshold percentage"
                  onChange={handleChangeThreshold}
                  noOptionsMessage={() => 'No results found.'}
                  value={threshold}
                  isSearchable={false}
                  onMenuOpen={handleMenuOpen}
                />
              </S.ThresholdWrapper>
              <S.SaveButton disabled={isEmptyThreshold || isEmptyBudget} onClick={handleSaveBudget}>
                Save
              </S.SaveButton>
            </S.InputWrapper>
            {thresholdValue > 0 && (
              <S.Estimation>
                We will send an email when you reach {''}
                <S.EstimationTask>{parseInt(thresholdQuantity).toLocaleString('en-US')} tasks</S.EstimationTask>
              </S.Estimation>
            )}
          </S.ValueInputBudget>
        </S.TotalTask>
      </S.Header>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  const currentUsageJS = rootReducer.pricing.get('zapierCurrentUsage').toJS();
  return {
    zapierCurrentUsage: currentUsageJS,
  };
};

export default connect(mapStateToProps, { getCurrentUsage })(ZapierUsageLimit);
