import React, { useEffect } from 'react';

const { formatWeeklyLabel, getXLabels } = require('../chartHelper');
const { PERIOD_GROUP, HOUR_LABELS } = require('../constants');

const CustomizedAxisTick = props => {
  const {
    x,
    y,
    dy,
    height,
    width,
    payload,
    filledData,
    xInterval,
    period_group,
    textAnchor,
    bodyMetricId = '',
  } = props;

  const labels = period_group === PERIOD_GROUP.HOURLY ? HOUR_LABELS : getXLabels(filledData, xInterval || 1);

  if (!labels.includes(payload.value)) {
    return null;
  }

  useEffect(() => {
    try {
      const xLine = document.querySelector(`.customized-xAxis-${bodyMetricId} .recharts-cartesian-axis-line`);

      if (xLine) {
        xLine.setAttribute('x1', 35);
      }
    } catch (err) {}
  }, [labels, width]);

  return (
    <g className="recharts-layer recharts-cartesian-axis-tick">
      <line
        dy={dy}
        orientation="bottom"
        width={width}
        height={height}
        x={x}
        y={y - 8}
        className="recharts-cartesian-axis-tick-line"
        stroke="#666"
        fill="none"
        x1={x}
        y1={y - 2}
        x2={x}
        y2={y - 8}
      />
      <text
        orientation="bottom"
        width={width}
        height={height}
        stroke="none"
        x={x}
        y={y + 5}
        className="recharts-text recharts-cartesian-axis-tick-value"
        textAnchor={textAnchor}
        fill="#666"
      >
        <tspan x={x} dy="0.71em">
          {formatWeeklyLabel(payload.value, period_group)}
        </tspan>
      </text>
    </g>
  );
};

export default CustomizedAxisTick;
