import React, { Fragment } from 'react';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import get from 'lodash/get';
import { Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { axiosInstance } from 'configs/request';
import Axios from 'axios';

import LoadingIndicator from 'shared/LoadingIndicator';
import AddResultPopup from './AddResultPopup';
import SleepResultPopup from './SpecialChart/SleepResultPopup';
import DropdownOption from 'components/BodyMetricChartNew/components/DropdownOption';
import ConfirmModalGroup from 'components/MetricGroupLibrary/Parts/ConfirmModal';
import AllEntriesByDayHeartRate from './AllEntriesByDayModal/HeartRate';
import AskToConnectButton from './AskToConnectButton';
import SleepMetricProgress from './SpecialChart/SleepMetricProgress';
import HeartRateProgress from './SpecialChart/HeartRateProgress';

import { SPECIAL_METRICS_CODE } from 'components/BodyMetricChartNew/constants';
import { CDN_URL } from 'constants/commonData';

import { convertFtToFtAndInch, convertUnit, formatMinValueToHM, roundNumberBodyMetric } from 'utils/commonFunction';
import { ReactComponent as PlusIcon } from 'assets/icons/metric-add-icon-circle-purple.svg';
import { ReactComponent as EditIcon } from 'assets/icons/metric-edit-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/delete-trash.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-select-down-middle.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-grey.svg';
import { ReactComponent as SmartWatchIcon } from 'assets/icons/ConnectedApp/smart_watch.svg';
import { TooltipStyle } from 'components/BodyMetricChartNew/components/KeysMetric/style';
import { formatSecValueToSM } from 'components/BodyMetricChartNew/chartHelper';
import './styles.scss';

const DATE_FORMAT = 'YYYY-MM-DD';
const VIEW_BY_OPTIONS = [
  { label: 'By Week', value: 'week' },
  { label: 'All Entries', value: 'all' },
];

export const defaultView = 'metric-view-by';

const CancelToken = Axios.CancelToken;

const resetStates = () => ({
  showAddResultPopup: false,
  editResult: null,
  progressList: [],
  viewingDetailGroupId: null,
  isLoading: true,
  previous_day: null, // if have (YYYY-mm-dd) => able to load more
  fromDate: moment().subtract(4, 'M').add(1, 'd').format(DATE_FORMAT),
  toDate: moment().format(DATE_FORMAT),
  page: 1,
});

export function formatDateChild(dateString, timezone) {
  if (!dateString) return '';
  const date = DateTime.fromISO(dateString, { zone: timezone, locale: 'en-US' });
  const now = DateTime.local().setZone(timezone);

  const month = date.toLocaleString({ month: 'short' });
  const day = date.toLocaleString({ day: '2-digit' }).replace(/^0/, '');
  const year = date.toLocaleString({ year: 'numeric' });
  if (date.year !== now.year) {
    return `${month} ${day}, ${year}`;
  }
  return `${month} ${day}`;
}

export default class NormalMetricProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...resetStates(),
      viewBy: localStorage.getItem(defaultView) || VIEW_BY_OPTIONS[0].value,
    };
    this.cancelRequest = null;
  }

  componentDidMount() {
    this.fetchEntry(this.props.selectedMetric);

    const metricWrapper = document.getElementById('client-metric-content-scroll');
    if (metricWrapper) {
      metricWrapper.addEventListener('scroll', debounce(this.handleScrollEnd), 300);
    }
  }

  isHeartRateChart = unique_code => {
    return SPECIAL_METRICS_CODE.HEART_RATE === unique_code;
  };

  componentWillUnmount() {
    const metricWrapper = document.getElementById('client-metric-content-scroll');

    if (metricWrapper) {
      metricWrapper.removeEventListener('scroll', this.handleScrollEnd);
    }
    if (this.cancelRequest) {
      this.cancelRequest();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    // reload data when change metric
    if (props.selectedMetric.mid !== this.props.selectedMetric.mid) {
      this.setState(
        s => ({ ...s, ...resetStates() }),
        () => {
          if (this.cancelRequest) {
            this.cancelRequest();
            this.cancelRequest = null;
          }
          if (props.selectedMetric.mid) {
            this.fetchEntry(props.selectedMetric);
          }
        },
      );
    }
  }

  fetchEntry = (metric, isLoadMore = false, isAfterEditEntry = false) => {
    if (this.isViewByWeek()) {
      this.getWeeklyEntryHistoryProgress(metric, isLoadMore, isAfterEditEntry);
      return;
    }
    // all entries
    const { selectedMetric: selectedMetricProp } = this.props;
    const { unique_code = '' } = selectedMetricProp;
    const isHeartRate = this.isHeartRateChart(unique_code);
    if (isHeartRate) {
      this.getDailyHeartRateEntry(metric, isLoadMore, isAfterEditEntry);
      return;
    }
    this.getAllEntryHistoryProgress(metric, isLoadMore, isAfterEditEntry);
  };

  callAxios = async (url, params, method = 'get') => {
    return axiosInstance({
      method,
      url,
      params,
      cancelToken: new CancelToken(
        function executor(c) {
          this.cancelRequest = c;
        }.bind(this),
      ),
    });
  };

  fetchAllEntrySleep = async (selectedMetric, isLoadMore = false, isAfterEditEntry) => {
    this.setState({ isLoading: true });
    const { clientId } = this.props;
    const { progressList, previous_day } = this.state;
    const { from_date, to_date } = this.getDateRange(isLoadMore, previous_day, { unit: 'd', value: 20 });

    try {
      const { data = { data: [], total: 0 } } =
        (await this.callAxios('/api/sleep-metric-entries/summary', {
          from_date,
          to_date,
          client: clientId,
          period_group: 'all_entries',
        })) || {};

      const list = get(data, 'data.details', []);
      const previous_date = get(data, 'data.previous_date') || null;
      const newProgressList = isLoadMore ? progressList.concat(list) : list;
      const shouldContinueLoading = !!previous_date && newProgressList.length < 20;

      this.setState(
        s => ({
          ...s,
          previous_day: previous_date,
          progressList: isLoadMore && !isAfterEditEntry ? newProgressList : list,
          isLoading: false,
        }),
        () => {
          if (shouldContinueLoading) {
            this.fetchEntry(selectedMetric, true);
          }
        },
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(s => ({ ...s, isLoading: false }));
    }
  };

  getAllEntryHistoryProgress = async (selectedMetric, isLoadMore = false, isAfterEditEntry) => {
    const { clientId, selectedMetric: selectedMetricProp } = this.props;
    if (isAfterEditEntry && selectedMetricProp.mid !== selectedMetric.mid) {
      return;
    }

    if (selectedMetric.unique_code === SPECIAL_METRICS_CODE.SLEEP) {
      return this.fetchAllEntrySleep(selectedMetric, isLoadMore, isAfterEditEntry);
    }

    this.setState({ isLoading: true });

    const { progressList, page = 1 } = this.state;
    const nextPage = isLoadMore ? page + 1 : page;

    try {
      const { data = { data: [], total: 0 } } =
        (await this.callAxios('/api/body-metric-entry/get-entry-history', {
          per_page: 20,
          page: isAfterEditEntry ? 1 : nextPage,
          client: clientId,
          unique_code: selectedMetric.unique_code,
        })) || {};

      this.setState(s => ({
        ...s,
        progressList: isLoadMore && !isAfterEditEntry ? progressList.concat(data.data) : data.data,
        page: isAfterEditEntry ? 1 : nextPage,
        total: data.total,
        isLoading: false,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(s => ({ ...s, isLoading: false }));
    }
  };

  getWeeklyEntryOfSleep = async (selectedMetric, isLoadMore = false, isAfterEditEntry) => {
    const { clientId } = this.props;
    this.setState({ isLoading: true });

    const { progressList, previous_day } = this.state;
    const { from_date, to_date } = this.getDateRange(isLoadMore, previous_day);

    try {
      const { data = { details: [], ending: false } } =
        (await this.callAxios('/api/sleep-metric-entries/summary', {
          from_date,
          to_date,
          client: clientId,
          period_group: 'weekly',
        })) || {};
      const list = get(data, 'data.details', []);
      const previous_date = get(data, 'data.previous_date', null);
      const newProgressList = isLoadMore ? progressList.concat(list) : list;
      const shouldContinueLoading = !!previous_date && newProgressList.length < 20;
      let firstGroupName = null;

      if (!isLoadMore && list[0]) {
        const { start } = list[0];

        if (moment().startOf('week').format(DATE_FORMAT) === start) {
          firstGroupName = start;
        }
      }

      this.setState(
        s => ({
          ...s,
          progressList: newProgressList,
          previous_day: previous_date,
          isLoading: false,
          fromDate: from_date,
          toDate: to_date,
          viewingDetailGroupId: !isLoadMore && !isAfterEditEntry ? firstGroupName : s.viewingDetailGroupId,
        }),
        () => {
          if (shouldContinueLoading) {
            this.fetchEntry(selectedMetric, true);
          }
        },
      );
    } catch (error) {
      console.error(error);
      this.setState(s => ({ ...s, isLoading: false, isLoadMore: false }));
    }
  };

  getDateRange = (isLoadMore, previous_day, subtract) => {
    const { selectedMetric } = this.props;
    const isSleep = selectedMetric.unique_code === SPECIAL_METRICS_CODE.SLEEP;
    const { value, unit } = subtract || { value: 4, unit: 'M' };

    if (isLoadMore) {
      return {
        from_date: moment(previous_day, DATE_FORMAT).subtract(value, unit).add(1, 'd').format(DATE_FORMAT),
        to_date: previous_day,
      };
    }

    return {
      from_date: moment().subtract(value, unit).add(1, 'd').format(DATE_FORMAT),
      to_date: moment()
        .add(isSleep ? 1 : 0, 'd')
        .format(DATE_FORMAT),
    };
  };

  getWeeklyEntryHistoryProgress = async (selectedMetric, isLoadMore = false, isAfterEditEntry) => {
    const { clientId, selectedMetric: selectedMetricProp } = this.props;
    if (isAfterEditEntry && selectedMetricProp.mid !== selectedMetric.mid) {
      return;
    }
    const { unique_code = '' } = selectedMetricProp;
    const isHeartRate = this.isHeartRateChart(unique_code);
    const deviceTz = moment.tz.guess();

    if (selectedMetricProp.unique_code === SPECIAL_METRICS_CODE.SLEEP) {
      return this.getWeeklyEntryOfSleep(selectedMetric, isLoadMore, isAfterEditEntry);
    }

    // remaining metrics
    this.setState({ isLoading: true });
    const { progressList, previous_day } = this.state;
    const { from_date, to_date } = this.getDateRange(isLoadMore, previous_day);

    const apiString = isHeartRate
      ? '/api/heart-rate-entries/chart-data'
      : '/api/v2/body-metric-entry/chart-data-latest-by-day';
    try {
      const params = {
        from_date,
        to_date,
        client: clientId,
        period_group: 'weekly',
        is_detail: true,
        timezone: deviceTz,
      };
      if (isHeartRate) {
        params.sort = 'desc';
      } else {
        params.metric_code = selectedMetric.unique_code;
      }

      const { data = { data: [], ending: false } } = (!!to_date && (await this.callAxios(apiString, params))) || {};

      const list = isHeartRate
        ? get(data, 'data', [])
        : get(data, 'data', [])
            .sort((a, b) => {
              return moment(b.start, DATE_FORMAT).unix() - moment(a.start, DATE_FORMAT).unix();
            })
            .map(item => ({
              ...item,
              all_entries: (item.all_entries || []).sort((a, b) => moment(b.date).unix() - moment(a.date).unix()),
            }));

      const newProgressList = isLoadMore ? progressList.concat(list) : list;
      const shouldContinueLoading = !!to_date && (!!data.previous_day || newProgressList.length < 20);
      let firstGroupName = null;

      if (!isLoadMore && list[0]) {
        const { start, group_name } = list[0];

        if (moment().startOf('week').format(DATE_FORMAT) === start) {
          firstGroupName = group_name;
        }
      }

      this.setState(
        s => ({
          ...s,
          progressList: newProgressList,
          previous_day: isHeartRate ? data.previous_date : data.previous_day,
          isLoading: false,
          fromDate: from_date,
          toDate: to_date,
          viewingDetailGroupId: !isLoadMore && !isAfterEditEntry ? firstGroupName : s.viewingDetailGroupId,
        }),
        () => {
          if (shouldContinueLoading && !isHeartRate) {
            this.fetchEntry(selectedMetric, true);
          }
        },
      );
    } catch (error) {
      console.error(error);
      this.setState(s => ({ ...s, isLoading: false, isLoadMore: false }));
    }
  };

  getDailyHeartRateEntry = async (selectedMetric, isLoadMore = false, isAfterEditEntry) => {
    const { clientId, selectedMetric: selectedMetricProp } = this.props;
    if (isAfterEditEntry && selectedMetricProp.mid !== selectedMetric.mid) {
      return;
    }
    const deviceTz = moment.tz.guess();
    this.setState({ isLoading: true });
    const { progressList, previous_day } = this.state;
    const { from_date, to_date } = isLoadMore
      ? {
          from_date: moment(previous_day, DATE_FORMAT).subtract(4, 'M').add(1, 'd').format(DATE_FORMAT),
          to_date: previous_day,
        }
      : {
          from_date: moment().subtract(4, 'M').add(1, 'd').format(DATE_FORMAT),
          to_date: moment().format(DATE_FORMAT),
        };

    const apiString = '/api/heart-rate-entries/chart-data';

    try {
      const params = {
        from_date,
        to_date,
        client: clientId,
        period_group: 'daily',
        sort: 'desc',
        timezone: deviceTz,
      };

      const { data = { data: [], ending: false } } = (!!to_date && (await this.callAxios(apiString, params))) || {};
      const list = get(data, 'data', []);

      const newProgressList = isLoadMore ? progressList.concat(list) : list;
      let firstGroupName = null;

      if (!isLoadMore && list[0]) {
        const { start, group_name } = list[0];

        if (moment().startOf('week').format(DATE_FORMAT) === start) {
          firstGroupName = group_name;
        }
      }

      this.setState(s => ({
        ...s,
        progressList: newProgressList,
        previous_day: data.previous_date,
        isLoading: false,
        fromDate: from_date,
        toDate: to_date,
        viewingDetailGroupId: !isLoadMore && !isAfterEditEntry ? firstGroupName : s.viewingDetailGroupId,
      }));
    } catch (error) {
      console.error(error);
      this.setState(s => ({ ...s, isLoading: false, isLoadMore: false }));
    }
  };

  handleEditResultClick = item => () => {
    const { editResult } = this.state;

    if (!editResult) {
      this.setState({ showAddResultPopup: false, editResult: item }, this.updateZIndexOfAppBar(true));
    }
  };

  handleRemoveResultItem = (item, isAllDay = false) => () => {
    const { toggleConfirmModal } = this.props;
    toggleConfirmModal(
      true,
      <ConfirmModalGroup
        modalId="delete-result-modal"
        title="Remove Result"
        content={`Are you sure you want to remove this result?`}
        onConfirm={() => this.onConfirmRemoveResult(item)}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        hasCloseIcon
        toggleConfirmModal={toggleConfirmModal}
      />,
    );
  };

  updateSleepEntry = async item => {
    const { clientId, selectedMetric, updateSleepMetricEntry } = this.props;
    try {
      const { entryId, ...data } = item;
      await updateSleepMetricEntry(entryId, data, clientId);
      this.fetchEntry(selectedMetric, false, true);
    } catch (error) {}
  };

  onConfirmRemoveResult = async item => {
    const { clientId, removeBodyMetricEntry, selectedMetric, removeSleepMetricEntry } = this.props;
    try {
      if (selectedMetric.unique_code === SPECIAL_METRICS_CODE.SLEEP) {
        await removeSleepMetricEntry(item._id, clientId);
      } else {
        await removeBodyMetricEntry({ clientId, entryId: item._id });
      }
      this.fetchEntry(selectedMetric, false, true);
    } catch (error) {}
  };

  handleSaveResultClick = async data => {
    const { editResult } = this.state;
    const {
      addBodyMetricEntry,
      addHeartRateEntry,
      updateBodyMetricEntry,
      clientId,
      selectedMetric,
      getBodyMetricTarget,
      selectedMetric: { unique_code = '' },
    } = this.props;
    const deviceTz = moment.tz.guess();
    const isHeartRate = this.isHeartRateChart(unique_code);

    try {
      const payload = { ...data, client: clientId, timezone: deviceTz };
      if (editResult) {
        if (isEmpty(payload.entryId)) {
          payload.entryId = editResult._id;
        }
        if (isEmpty(payload.unit)) {
          payload.unit = editResult.unit._id;
        }
        await updateBodyMetricEntry(payload);
      } else {
        isHeartRate ? await addHeartRateEntry(payload) : await addBodyMetricEntry(payload);
      }
      this.fetchEntry(selectedMetric, false, true);
      getBodyMetricTarget({
        client: clientId,
        unique_code: (selectedMetric || {}).unique_code,
      });
    } catch (error) {}
    this.handleCloseAddResultPopup();
  };

  handleSaveSleepResult = async data => {
    const { addSleepEntry, clientId, selectedMetric, getBodyMetricTarget } = this.props;

    try {
      const payload = { ...data, unique_code: (selectedMetric || {}).unique_code, client: clientId };

      await addSleepEntry(payload);

      this.fetchEntry(selectedMetric, false, true);
      getBodyMetricTarget({
        client: clientId,
        unique_code: (selectedMetric || {}).unique_code,
      });
    } catch (error) {}
    this.handleCloseAddResultPopup();
  };

  handleScrollEnd = () => {
    const { isLoading, previous_day, total, progressList } = this.state;
    const {
      selectedMetric: { unique_code = '' },
    } = this.props;
    const isHeartRate = this.isHeartRateChart(unique_code);
    const metricWrapper = document.getElementById('client-metric-content-scroll');

    if (!metricWrapper || isLoading) return;
    const { scrollHeight = 0, scrollTop = 0, clientHeight = 0 } = metricWrapper;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + 20;

    if (isAtBottom && !isLoading) {
      if (this.isViewByWeek() || isHeartRate) {
        previous_day && this.fetchEntry(this.props.selectedMetric, true);
      } else {
        (progressList.length < total || !!previous_day) && this.fetchEntry(this.props.selectedMetric, true);
      }
    }
  };

  handleConvert = data => {
    const { metricTypes = [] } = this.props;
    const metricUnit = metricTypes.find(it => it.unique_code === data.metric_code);
    return data.value && metricUnit
      ? roundNumberBodyMetric(convertUnit(data.value, data.unit, metricUnit.unit))
      : data.value;
  };

  handleConvertResults = results => {
    const newResults = orderBy(results, e => e.date || '', 'desc');
    return newResults.map(item => {
      item.value = this.handleConvert(item);
      return item;
    });
  };

  updateZIndexOfAppBar = isLower => () => {
    const [appBar] = document.getElementsByClassName('app-navbar');
    const [mainContent] = document.getElementsByClassName('app-main-content');
    if (appBar) {
      appBar.style.zIndex = isLower ? 1 : 999;
    }
    if (mainContent) {
      mainContent.style.zIndex = isLower ? 2 : 1;
    }
  };

  handleAddResult = () => {
    const { isLoadingMetric = false } = this.props;
    if (isLoadingMetric) return;
    this.setState({ showAddResultPopup: true, editResult: null }, this.updateZIndexOfAppBar(true));
  };

  handleCloseAddResultPopup = () => {
    this.setState({ showAddResultPopup: false, editResult: null }, this.updateZIndexOfAppBar(false));
  };

  formatTimeMetric = ({ value, unit: unitItem, min = 0, max = 0 }) => {
    const { selectedMetric: { metricSettings = {}, unique_code = '' } = {} } = this.props;
    const isHeartRate = this.isHeartRateChart(unique_code);
    const valueRounded2Decimals = roundNumberBodyMetric(value, 2);
    const valueRounded = roundNumberBodyMetric(valueRounded2Decimals, 1);
    const unit = (metricSettings || {}).unit || unitItem;

    if (!unit) return valueRounded;

    if (unit.unique_code === 'ft') {
      const { ft, inch } = convertFtToFtAndInch(value);

      return `${ft > 0 ? `${ft} ft ` : ''}${inch > 0 ? `${inch} in` : ''}`;
    }

    if (unit.unique_code === 'none') return `${valueRounded}`;

    if (unit.unique_code === 'min') return formatMinValueToHM(Math.round(value));

    if (unit.unique_code === 'sec') return formatSecValueToSM(Math.round(value));

    if (isHeartRate) return min === max ? `${min} ${unit.title}` : `${min} - ${max} ${unit.title}`;

    return `${valueRounded} ${unit.title}`;
  };

  onChangeViewBy = viewBy => {
    if (this.state.viewBy !== viewBy) {
      localStorage.setItem(defaultView, viewBy);
      this.setState(
        s => ({ ...s, ...resetStates(), viewBy }),
        () => this.fetchEntry(this.props.selectedMetric),
      );
    }
  };

  handleDetailGroup = groupId => () => {
    const { showAddResultPopup } = this.state || {};
    if (showAddResultPopup) return;

    this.setState(s => ({ ...s, viewingDetailGroupId: s.viewingDetailGroupId === groupId ? null : groupId }));
  };

  renderActions = (item, isEditing, isHide = false) => {
    const { dateFormat, timezone, selectedMetric, metricTypes } = this.props;

    return (
      <Table.Cell>
        {!isHide && (
          <div className="actions progress-item progress-child-item">
            <AddResultPopup
              newUI
              isOpen={isEditing}
              key={`${item._id}_${isEditing ? 'open' : 'close'}`}
              editData={item}
              timezone={timezone}
              dateFormat={dateFormat}
              metric={selectedMetric}
              metricTypes={metricTypes}
              onClose={this.handleCloseAddResultPopup}
              onSave={this.handleSaveResultClick}
              onOpen={this.handleEditResultClick(item)}
              trigger={
                <button
                  onClick={e => e.preventDefault()}
                  className={classNames('btn-icon btn-edit', { active: isEditing })}
                  data-tip
                  data-for={`edit-result-on-tooltip-${item._id}`}
                >
                  <EditIcon />
                  <ReactTooltip
                    className="app-tooltip edit-result-on-tooltip"
                    id={`edit-result-on-tooltip-${item._id}`}
                    effect="solid"
                    place={'top'}
                  >
                    <TooltipStyle>Edit Result</TooltipStyle>
                  </ReactTooltip>
                </button>
              }
            />
            <button
              onClick={this.handleRemoveResultItem(item)}
              className="btn-icon btn-trash"
              data-tip
              data-for={`remove-result-on-tooltip-${item._id}`}
            >
              <TrashIcon />
              <ReactTooltip
                className="app-tooltip remove-result-on-tooltip"
                id={`remove-result-on-tooltip-${item._id}`}
                effect="solid"
                place={'top'}
              >
                <TooltipStyle>Remove Result</TooltipStyle>
              </ReactTooltip>
            </button>
          </div>
        )}
      </Table.Cell>
    );
  };

  navigateWorkoutResult = assignment_id => () => {
    const { clientId } = this.props;
    if (!assignment_id) return;
    const navigateLink = `/home/client/${clientId}/calendar/${assignment_id}/history`;
    window.open(navigateLink, '_blank');
  };

  renderEntryItem = item => {
    const { editResult } = this.state;
    const {
      selectedMetric: { unique_code = '' },
      clientId,
    } = this.props;
    const assignmentId = get(item, 'assignment', '');
    const isAssignment = get(item, 'agent.name', '') === 'smart_watch';
    const isSyncFromOtherWS = get(item, 'sync_from', '') !== clientId;
    const isHeartRate = this.isHeartRateChart(unique_code);
    const { timezone } = this.props;
    const isEditing = editResult && editResult._id === item._id;
    const date = isHeartRate ? DateTime.fromISO(item.group_name) : DateTime.fromISO(item.date).toLocal();
    const isSameCurrentYear = this.isSameCurrentYear(date);
    let dateText = date.toFormat(isSameCurrentYear ? 'MMM d' : 'MMM d, yyyy');
    const isViewByWeek = this.isViewByWeek();

    if (date.toFormat('MM-dd-yyyy') === DateTime.local().setZone(timezone).toFormat('MM-dd-yyyy')) {
      dateText = 'Today';
    }

    return (
      <Table.Row
        key={item._id}
        className={classNames('row-item', { editing: isEditing })}
        onClick={
          isHeartRate
            ? this.openEntriesByDayModal(item, true)
            : this.navigateWorkoutResult(isSyncFromOtherWS ? '' : assignmentId)
        }
      >
        <Table.Cell className="date w-112">
          <div>{dateText}</div>
        </Table.Cell>

        <Table.Cell className={classNames('time', { 'time-week': isViewByWeek })}>
          {!isHeartRate && date.toFormat('hh:mm a')}
          {isAssignment && (
            <SmartWatchIcon className={classNames('app-integration-icon', { disableIcon: isSyncFromOtherWS })} />
          )}
        </Table.Cell>

        <Table.Cell className="value" id={`result-id-${item._id}`}>
          <div>{this.formatTimeMetric(item)}</div>
        </Table.Cell>
        {isHeartRate ? (
          <Table.Cell>
            <div className="actions">
              <button className="btn-icon btn-right-arrow">
                <ArrowRightIcon width={13} />
              </button>
            </div>
          </Table.Cell>
        ) : (
          this.renderActions(item, isEditing, isAssignment)
        )}
      </Table.Row>
    );
  };

  openEntriesByDayModal = (item, fromAllEntries = false) => () => {
    const {
      selectedMetric,
      metricTypes,
      toggleModal,
      toggleConfirmModal,
      dateFormat,
      clientId,
      getBodyMetricTarget,
      updateHeartRateEntry,
      removeHeartRateEntry,
    } = this.props;
    const selectedDate = fromAllEntries ? item.group_name : (item || {}).date;
    const deviceTz = moment.tz.guess();

    toggleModal(
      true,
      <AllEntriesByDayHeartRate
        selectedDate={selectedDate}
        toggleModal={toggleModal}
        toggleConfirmModal={toggleConfirmModal}
        timezone={deviceTz}
        selectedMetric={selectedMetric}
        metricTypes={metricTypes}
        dateFormat={dateFormat}
        clientId={clientId}
        getBodyMetricTarget={getBodyMetricTarget}
        updateHeartRateEntry={updateHeartRateEntry}
        removeHeartRateEntry={removeHeartRateEntry}
        fetchEntry={this.fetchEntry}
      />,
    );
  };

  renderChildItem = (item, weekId) => {
    const { editResult, viewingDetailGroupId } = this.state;
    const {
      timezone,
      selectedMetric: { unique_code = '' },
      clientId,
    } = this.props;

    const isHeartRate = this.isHeartRateChart(unique_code);
    const isOpening = viewingDetailGroupId === weekId;
    const isEditing = editResult && editResult._id === item._id;
    const date = formatDateChild(item.date, timezone);
    const time = moment(item.date).format('hh:mm A');
    const assignmentId = get(item, 'assignment', '');
    const isAssignment = get(item, 'agent.name', '') === 'smart_watch';
    const isSyncFromOtherWS = get(item, 'sync_from', '') !== clientId;

    return (
      <Table.Row
        key={isHeartRate ? `${weekId}-${item.date}` : item._id}
        className={classNames('row-item child-item', {
          editing: isEditing,
          'child-opening': isOpening,
        })}
        onClick={
          isHeartRate
            ? this.openEntriesByDayModal(item)
            : this.navigateWorkoutResult(isSyncFromOtherWS ? '' : assignmentId)
        }
      >
        <Table.Cell className="date">
          <div>
            {date}

            {!isHeartRate && (
              <span className="date-time">
                {time}{' '}
                {isAssignment && (
                  <SmartWatchIcon className={classNames('app-integration-icon', { disableIcon: isSyncFromOtherWS })} />
                )}
              </span>
            )}
          </div>
        </Table.Cell>
        <Table.Cell className="value" id={`result-id-${item._id}`}>
          <div>{this.formatTimeMetric(item)}</div>
        </Table.Cell>
        {isHeartRate ? (
          <Table.Cell>
            <div className="actions progress-child-item">
              <button className="btn-icon btn-right-arrow">
                <ArrowRightIcon width={13} />
              </button>
            </div>
          </Table.Cell>
        ) : (
          this.renderActions(item, isEditing, isAssignment)
        )}
      </Table.Row>
    );
  };

  isSameCurrentYear = time => {
    if (!time) return;
    const currentDate = DateTime.now();
    return currentDate.year === time.year;
  };

  renderEntryGroup = (item, index) => {
    const { viewingDetailGroupId } = this.state;
    const {
      selectedMetric: { unique_code = '' },
    } = this.props;
    const isHeartRate = this.isHeartRateChart(unique_code);
    const isOpening = viewingDetailGroupId === item.group_name;
    const start = DateTime.fromISO(item.start).toLocal();
    const end = DateTime.fromISO(item.end).toLocal();
    const startIsSameCurrentYear = this.isSameCurrentYear(start);
    const endIsSameCurrentYear = this.isSameCurrentYear(end);
    const startAndEndIsSameYear = start.year === end.year;

    return (
      <Fragment key={`${item.group_name}-${index}`}>
        <Table.Row
          className={classNames('row-item collapse-item', { opening: isOpening })}
          onClick={this.handleDetailGroup(item.group_name)}
        >
          <Table.Cell className="date w-56per">
            <div>
              {start.toFormat(startIsSameCurrentYear ? 'MMM d' : startAndEndIsSameYear ? 'MMM d' : 'MMM d, yyyy')} -{' '}
              {end.toFormat(endIsSameCurrentYear ? 'MMM d' : 'MMM d, yyyy')}
            </div>
          </Table.Cell>
          <Table.Cell className="value" id={`result-id-${item._id}`}>
            <div>{this.formatTimeMetric(item)}</div>
          </Table.Cell>
          <Table.Cell>
            <div className="actions progress-item">
              <button className="btn-icon btn-arrow">
                <ArrowDownIcon width={13} />
              </button>
            </div>
          </Table.Cell>
        </Table.Row>
        {get(item, `${isHeartRate ? 'entries' : 'all_entries'}`, []).map(child =>
          this.renderChildItem(child, item.group_name),
        )}
      </Fragment>
    );
  };

  renderEntry = (item, index) => {
    if (this.isViewByWeek()) {
      return this.renderEntryGroup(item, index);
    }
    return this.renderEntryItem(item);
  };

  isViewByWeek = () => this.state.viewBy === VIEW_BY_OPTIONS[0].value;

  convertData = (list = [], selectedMetric) => {
    const { metricSettings: { unit } = {} } = selectedMetric || {};
    return list.map(item => ({
      ...item,
      value: convertUnit(item.value, item.unit, unit),
      unit: unit || item.unit,
      entries: this.convertData(item.entries, selectedMetric),
    }));
  };

  renderProgress = () => {
    const { progressList, isLoading, total, previous_day, viewingDetailGroupId, showAddResultPopup } = this.state;
    const { selectedMetric } = this.props;
    const { unique_code = '' } = selectedMetric;
    const isViewByWeek = this.isViewByWeek();
    const progressListConverted = this.convertData(progressList, selectedMetric);

    if (selectedMetric.unique_code === SPECIAL_METRICS_CODE.SLEEP) {
      return (
        <SleepMetricProgress
          {...this.props}
          progressList={progressList}
          isLoading={isLoading}
          previous_day={previous_day}
          isViewByWeek={isViewByWeek}
          viewingDetailGroupId={viewingDetailGroupId}
          updateDetailGroupId={this.handleDetailGroup}
          confirmRemoveEntry={this.onConfirmRemoveResult}
          updateSleepEntry={this.updateSleepEntry}
          isShowAddResultPopup={showAddResultPopup}
        />
      );
    }

    if (unique_code === SPECIAL_METRICS_CODE.HEART_RATE && progressList.length > 0) {
      return (
        <HeartRateProgress
          progressList={progressList}
          previous_day={previous_day}
          total={total}
          isViewByWeek={isViewByWeek}
          renderEntry={this.renderEntry}
          resultListRef={this.resultListRef}
          isLoading={isLoading}
        />
      );
    }

    if (progressListConverted.length > 0) {
      return (
        <div className="progress__body">
          <Table
            singleLine
            className={classNames('progress-table-head normal-metric-head', {
              'normal-metric-head-all': !isViewByWeek,
            })}
          >
            <Table.Header className="progress-table__head">
              <Table.Row>
                <Table.HeaderCell className={isViewByWeek ? 'w-56per' : 'w-112'}>DATE</Table.HeaderCell>
                {!isViewByWeek && (
                  <Table.HeaderCell className={classNames('head-time', { 'head-time-week': isViewByWeek })}>
                    Time
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell className="result">{isViewByWeek ? 'Weekly average' : 'RESULTS'}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          <div className="result-list">
            <Table singleLine className="progress-table__content normal-metric-content">
              <Table.Body>
                {progressListConverted.map(this.renderEntry)}
                {isLoading && (
                  <Table.Row className="row-item">
                    <Table.Cell colSpan={isViewByWeek ? 3 : 4} textAlign="center" verticalAlign="middle">
                      <LoadingIndicator title="" className="progress-load-more" />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      );
    }

    return !isLoading ? <p className="no-result">No entries yet</p> : null;
  };

  renderAddResult = isSleep => {
    const { showAddResultPopup } = this.state;
    const { dateFormat, timezone, selectedMetric, metricTypes } = this.props;

    if (isSleep) {
      return (
        <SleepResultPopup
          newUI
          isOpen={showAddResultPopup}
          key={`add_new_${showAddResultPopup ? 'open' : 'close'}`}
          timezone={timezone}
          dateFormat={dateFormat}
          metric={selectedMetric}
          metricTypes={metricTypes}
          onClose={this.handleCloseAddResultPopup}
          onSave={this.handleSaveSleepResult}
          onOpen={this.handleAddResult}
          trigger={
            <div
              className={classNames('progress__header-add-result-btn', { active: showAddResultPopup })}
              onClick={e => e.preventDefault()}
            >
              <PlusIcon />
              Add Result
            </div>
          }
        />
      );
    }

    return (
      <AddResultPopup
        newUI
        isOpen={showAddResultPopup}
        key={`add_new_${showAddResultPopup ? 'open' : 'close'}`}
        timezone={timezone}
        dateFormat={dateFormat}
        metric={selectedMetric}
        metricTypes={metricTypes}
        onClose={this.handleCloseAddResultPopup}
        onSave={this.handleSaveResultClick}
        onOpen={this.handleAddResult}
        trigger={
          <div
            className={classNames('progress__header-add-result-btn', { active: showAddResultPopup })}
            onClick={e => e.preventDefault()}
          >
            <PlusIcon />
            Add Result
          </div>
        }
      />
    );
  };

  render() {
    const { viewBy } = this.state;
    const { selectedMetric } = this.props;
    const { sleepSettings } = selectedMetric;
    const { connectedApp = false } = sleepSettings || {};
    const isSleep = selectedMetric.unique_code === SPECIAL_METRICS_CODE.SLEEP;
    const isHearRateChart = selectedMetric.unique_code === SPECIAL_METRICS_CODE.HEART_RATE;

    return (
      <div className="progress-wrapper">
        <div className="progress-content">
          <div className="progress__header">
            <div className="progress__header-title">Progress</div>
            {this.renderAddResult(isSleep, connectedApp)}
            {(isSleep || isHearRateChart) && !connectedApp && <AskToConnectButton className="ask-to-connect" />}
            <DropdownOption
              selected={viewBy}
              options={VIEW_BY_OPTIONS}
              className="view-by-wrap"
              onSelectFilter={this.onChangeViewBy}
            />
          </div>
          {this.renderProgress()}
        </div>
      </div>
    );
  }
}
