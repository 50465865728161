import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const Wrapper = styled.div`
  width: 100vw;
  height: ${props => (props.isUpdateVersion ? '100vh' : '0px')};
  background-color: transparent;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: ${props => (props.isUpdateVersion ? 2147483100 : -1)};

  .errorPopup {
    display: flex;
    align-items: center;
    padding: 0 20px;
    opacity: ${props => (props.isUpdateVersion ? 1 : 0)};
    transition: opacity 0.5s ease-in-out;
    background: #2e2525;
    border-radius: 6px;
    z-index: ${props => (props.isUpdateVersion ? 2147483100 : 0)};
    margin-left: ${props => (props.sideBarVisible ? '230px' : 0)};
    margin-top: 20px;
    height: 64px;
  }

  .errorPopup__text {
    font-size: 13px;
    line-height: 18px;
    margin-right: 60px;
    color: #fff;

    a {
      color: #fff;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const CustomReloadIcon = styled(ReloadIcon)`
  width: 30px;
  height: 30px;
  margin-right: 20px;

  path {
    stroke: rgba(255, 255, 255, 0.3);
  }
`;

const CustomCloseIcon = styled(CloseIcon)`
  width: 11px;
  height: 11px;
  cursor: pointer;

  path {
    fill: #fff;
  }
`;

function NewVersionWarningPopup({ sideBarVisible, home }) {
  const [showPopup, setShowPopup] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(home.version);

  useEffect(() => {
    setCurrentVersion(home.version);
  }, []);

  useEffect(() => {
    setShowPopup(home.version !== currentVersion);
  }, [home.version]);

  return (
    <Wrapper isUpdateVersion={showPopup} sideBarVisible={sideBarVisible}>
      <div className="errorPopup">
        <CustomReloadIcon />
        <div className="errorPopup__text">
          A new version of Everfit is available. To start using it, please{' '}
          <a
            onClick={() => {
              window.location.reload();
            }}
          >
            reload this page.
          </a>
        </div>
        <CustomCloseIcon onClick={() => setShowPopup(false)} />
      </div>
    </Wrapper>
  );
}

const mapStateToProps = state => {
  const { sideBarVisible, home } = state;

  return {
    sideBarVisible,
    home,
  };
};

export default connect(mapStateToProps, null)(NewVersionWarningPopup);
