import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  font-family: 'Open Sans';
  width: 200px;
  left: 0px;
  top: -3px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(38, 38, 38, 0.2);
  font-size: 13px;
  z-index: 99;

  &.sleep-goal-popup {
    width: 247px;

    .sleep-error,
    .sleep-label.error {
      color: #ea314a;
    }

    .sleep-error {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    .footer {
      button {
        margin-top: 1px;
        &.btn-save {
          width: 57px;
        }
      }
    }
  }

  .content {
    padding: 15px 15px 20px;
    border-bottom: 1px #e1e1ea solid;

    h3 {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      color: #202353;
      margin-bottom: 20px;
    }
  }

  .sleep-label {
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    color: #7b7e91;
    margin-bottom: 3px;
    text-transform: uppercase;
  }

  .value-input {
    margin-top: 15px;

    .unit {
      font-size: 12px;
    }

    .metric-value-input {
      height: 36px;
      padding: 10px !important;
      border-radius: 4px;
      border: 1px solid #e1e1ea;
      width: 100%;

      &:focus,
      :hover {
        outline: none;
        border-color: #5158cf;
        cursor: text;
      }
    }

    .label-error {
      color: #ea314a;
    }

    .input-error {
      border-color: #ea314a !important;
    }

    .flex {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .value-error {
      font-size: 12px;
      line-height: 150%;
      color: #ea314a;
    }

    .ft-in-error {
      .ft-error,
      .in-error {
        padding-top: 0;
        margin-top: 8px !important;
      }
    }
  }

  .footer {
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button {
      height: 30px;
      padding: 10px 15px;
      border-radius: 5px;
      min-width: auto;
      height: 36px;
      font-size: 12px;

      &.btn-cancel {
        border: none;
        width: 72px;
      }
    }

    .btn-save {
      width: 70px;
      :hover {
        opacity: 0.9;
      }

      :disabled {
        background-color: #eaeef1;
        color: #7b7e91;
        cursor: not-allowed;
      }
    }
  }
`;
