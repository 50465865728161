import _ from 'lodash';
import { getClientsFullData, getFakeClientData } from 'redux/client/client.actionCreators';
import Request from 'configs/request';
import { DEFAULT_CLIENT_FILTERS } from 'constants/commonData';

export const Types = {
  GET_ALL_SEGMENTS: 'GET_ALL_SEGMENTS',
  SUCCESS_GET_ALL_SEGMENTS: 'SUCCESS_GET_ALL_SEGMENTS',
  FAILED_GET_ALL_SEGMENTS: 'FAILED_GET_ALL_SEGMENTS',
  GET_SEGMENTS: 'GET_SEGMENTS',
  SUCCESS_GET_SEGMENTS: 'SUCCESS_GET_SEGMENTS',
  FAILED_GET_SEGMENTS: 'FAILED_GET_SEGMENTS',
  ADD_NEW_SEGMENT: 'ADD_NEW_SEGMENT',
  SUCCESS_ADD_NEW_SEGMENT: 'SUCCESS_ADD_NEW_SEGMENT',
  FAILED_ADD_NEW_SEGMENT: 'FAILED_ADD_NEW_SEGMENT',
  DELETE_SEGMENT: 'DELETE_SEGMENT',
  SUCCESS_DELETE_SEGMENT: 'SUCCESS_DELETE_SEGMENT',
  FAILED_DELETE_SEGMENT: 'FAILED_DELETE_SEGMENT',
  UPDATE_SEGMENT: 'UPDATE_SEGMENT',
  SUCCESS_UPDATE_SEGMENT: 'SUCCESS_UPDATE_SEGMENT',
  FAILED_UPDATE_SEGMENT: 'FAILED_UPDATE_SEGMENT',
  SEARCH_SEGMENTS: 'SEARCH_SEGMENTS',
  SUCCESS_SEARCH_SEGMENTS: 'SUCCESS_SEARCH_SEGMENTS',
  FAILED_SEARCH_SEGMENTS: 'FAILED_SEARCH_SEGMENTS',
  CHANGE_CLIENT_FILTERS: 'CHANGE_CLIENT_FILTERS',
  SEGMENT_RESET_SELECTED: 'SEGMENT_RESET_SELECTED',
  SELECT_SEGMENT: 'SELECT_SEGMENT',
  ACTIVE_SEGMENT: 'ACTIVE_SEGMENT',
  SUCCESS_ACTIVE_SEGMENT: 'SUCCESS_ACTIVE_SEGMENT',
  FAILED_ACTIVE_SEGMENT: 'FAILED_ACTIVE_SEGMENT',
  HIDE_SEGMENT: 'HIDE_SEGMENT',
  SUCCESS_HIDE_SEGMENT: 'SUCCESS_HIDE_SEGMENT',
  FAILED_HIDE_SEGMENT: 'FAILED_HIDE_SEGMENT',
  SEGMENT_ACTIVE_WAITING_SEGMENT: 'SEGMENT_ACTIVE_WAITING_SEGMENT',
  INBOX_SEGMENT_RESET_SELECTED: 'INBOX_SEGMENT_RESET_SELECTED',
};

export const changeClientFilter = (key, value) => {
  return dispatch => {
    dispatch({ type: Types.CHANGE_CLIENT_FILTERS, payload: { key, value } });
    dispatch(getClientsFullData(true, { page: 1 }));
  };
};

export const resetSelectedSegment = () => ({ type: Types.SEGMENT_RESET_SELECTED });

export const resetRedirectInboxSegment = () => ({ type: Types.INBOX_SEGMENT_RESET_SELECTED });

export const activeWaitingSegment = segmentId => ({ type: Types.SEGMENT_ACTIVE_WAITING_SEGMENT, payload: segmentId });

export const selectSegment = (segment, filters, isPermitDenied) => {
  return (dispatch, getState) => {
    dispatch({
      type: Types.SELECT_SEGMENT,
      payload: { filters: isPermitDenied ? DEFAULT_CLIENT_FILTERS : filters, segment, isPermitDenied },
    });
    if (!isPermitDenied) {
      dispatch(getClientsFullData(true, { page: 1, search: '' }, segment));
    } else {
      const { list } = getState().rootReducer.segment;
      const selectedSegment = list.find(o => o._id === segment);
      dispatch(getFakeClientData(segment, selectedSegment.num_clients));
    }
  };
};

export const getSegments = params => {
  return dispatch => {
    dispatch({ type: Types.GET_SEGMENTS });

    return dispatch(
      Request.get(
        { url: '/api/segment/list', params },
        false,
        () => dispatch({ type: Types.SUCCESS_GET_SEGMENTS }),
        error => dispatch({ type: Types.FAILED_GET_SEGMENTS, error }),
      ),
    );
  };
};

const getAllSegmentSucess = (list, total) => ({
  type: Types.SUCCESS_GET_ALL_SEGMENTS,
  payload: { list, total },
});

export const getAllSegments = () => {
  return dispatch => {
    dispatch({ type: Types.GET_ALL_SEGMENTS });

    return dispatch(
      Request.get(
        { url: '/api/segment/all' },
        false,
        response => {
          if (response.data && response.data.data) {
            dispatch(getAllSegmentSucess(response.data.data, response.data.totalClients || 0));
          }
        },
        error => dispatch({ type: Types.FAILED_GET_ALL_SEGMENTS, error }),
      ),
    );
  };
};

const createSegmentSuccess = segment => ({ type: Types.SUCCESS_ADD_NEW_SEGMENT, payload: { segment } });

export const createSegment = data => {
  return dispatch => {
    dispatch({ type: Types.ADD_NEW_SEGMENT });

    return dispatch(
      Request.post(
        { url: '/api/segment/add', data },
        true,
        response => {
          if (response.data && response.data.data) {
            dispatch(createSegmentSuccess(response.data.data));
          }
        },
        error => {
          dispatch({ type: Types.FAILED_ADD_NEW_SEGMENT, error });
        },
      ),
    );
  };
};

const updateSegmentSuccess = segment => ({ type: Types.SUCCESS_UPDATE_SEGMENT, payload: { segment } });

export const updateSegment = (segmentId, data) => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_SEGMENT });

    return dispatch(
      Request.put(
        { url: `/api/segment/update/${segmentId}`, data },
        true,
        response => {
          if (response.data && response.data.data) {
            dispatch(updateSegmentSuccess(response.data.data));
          }
        },
        error => dispatch({ type: Types.FAILED_UPDATE_SEGMENT, error }),
      ),
    );
  };
};

const deleteSegmentSuccess = segmentId => ({ type: Types.SUCCESS_DELETE_SEGMENT, payload: { segmentId } });

export const deleteSegment = segmentId => {
  return dispatch => {
    dispatch({ type: Types.DELETE_SEGMENT });

    return dispatch(
      Request.delete(
        { url: `/api/segment/delete/${segmentId}` },
        true,
        response => dispatch(deleteSegmentSuccess(segmentId)),
        error => dispatch({ type: Types.FAILED_DELETE_SEGMENT, error }),
      ),
    );
  };
};

const updateSegmentState = (type, segment, isVisible) => ({ type, payload: { segment, isVisible } });

export const activeSegment = segmentId => {
  return dispatch => {
    dispatch({ type: Types.ACTIVE_SEGMENT });

    return dispatch(
      Request.put(
        { url: `/api/segment/visible/${segmentId}` },
        true,
        response => dispatch(updateSegmentState(Types.SUCCESS_ACTIVE_SEGMENT, segmentId, true)),
        error => dispatch({ type: Types.FAILED_ACTIVE_SEGMENT, error }),
      ),
    );
  };
};

export const hideSegment = segmentId => {
  return dispatch => {
    dispatch({ type: Types.HIDE_SEGMENT });

    return dispatch(
      Request.put(
        { url: `/api/segment/hide/${segmentId}` },
        true,
        response => dispatch(updateSegmentState(Types.SUCCESS_HIDE_SEGMENT, segmentId, false)),
        error => dispatch({ type: Types.FAILED_HIDE_SEGMENT, error }),
      ),
    );
  };
};
