import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { likeIcon, unlikeIcon, commentIcon } from 'constants/images';

const Container = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  border-top: 1px solid #f4f4f4;
  display: flex;
  justify-content: flex-start;

  button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    flex: 1 1 50%;
    background-color: #fff;
    cursor: pointer;
    padding: 9px 9px;

    svg {
      margin-right: 5px;
    }

    :hover {
      background-color: #f4f4f4;
    }

    &.like {
      &.active {
        color: #494699;
      }
    }
  }
`;

function Interations({ onLikeClick, onCommentClick, like, comments }) {
  return (
    <Container className="interactions">
      <button
        type="button"
        className={`like ${like ? 'active' : ''}`}
        onClick={() => (typeof onCommentClick === 'function' ? onLikeClick() : null)}
      >
        {like ? likeIcon : unlikeIcon}
        <span>Like</span>
      </button>
      <button
        type="button"
        className="comment"
        onClick={() => (typeof onCommentClick === 'function' ? onCommentClick() : null)}
      >
        {commentIcon}
        <span>
          {comments ? `${comments} ` : ''}Comment{comments && comments > 1 ? 's' : ''}
        </span>
      </button>
    </Container>
  );
}

Interations.propTypes = {
  onLikeClick: PropTypes.func,
  onCommentClick: PropTypes.func,
  like: PropTypes.bool,
  comments: PropTypes.number,
};

export default Interations;
