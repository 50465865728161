import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Prompt } from 'react-router';

import { toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import { FormGroup } from 'shared/FormControl';
import { getGroupDetail, updateGroup } from 'redux/forum/actions';
import { ReactComponent as TitleIcon } from 'assets/icons/forum-advanced-settings-title-icon.svg';

import * as S from './styles';

const validateName = name => {
  if (name.trim() === '') {
    return false;
  }
  return true;
};

const SetupGroupSettings = ({ useStateCallback, updateGroup, group, location, toggleConfirmModal, push }) => {
  const originalGroupName = group.name || '';
  const originalGroupDescription = group.description || '';
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [nameError, setNameError] = useState(false);
  const [isChanged, setIsChanged] = useStateCallback(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const groupId = location.pathname.slice(
    location.pathname.indexOf('/forums/') + '/forums/'.length,
    location.pathname.indexOf('/settings'),
  );
  useEffect(() => {
    if (group.name) {
      setName(group.name);
    }
    if (group.description) {
      setDescription(group.description);
    }
  }, [originalGroupName, originalGroupDescription]);

  useEffect(() => {
    if (isEqual(name, originalGroupName) && isEqual(description, originalGroupDescription)) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }, [name, description, originalGroupName, originalGroupDescription]);

  const handleChangeName = e => {
    setName(e.target.value);
  };

  const handleChangeDescription = e => {
    setDescription(e.target.value);
  };

  const handleSave = async () => {
    setNameError(!validateName(name));
    if (validateName(name)) {
      setIsSaving(true);
      await updateGroup({
        groupId: groupId,
        name: name.trim(),
        description: description,
      });
      setIsSaving(false);
      toast('Settings has been saved');
    } else {
      setName('');
    }
  };

  const handleDiscardChange = nextLocation => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          setIsChanged(false, () => push(nextLocation.pathname));
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
      />,
    );
    return false;
  };

  const toggleFocus = () => {
    setIsFocus(!isFocus);
  };

  return (
    <S.SettingContainer>
      <Prompt when={isChanged} message={handleDiscardChange} />
      <div className="title-container">
        <TitleIcon />
        Setup Forum
        {isChanged && (
          <div className="save-btn-container">
            <S.SaveButton borderPurple={!isSaving} purple={isSaving} onClick={handleSave} disabled={isSaving}>
              Save
            </S.SaveButton>
          </div>
        )}
      </div>

      <FormGroup className="forum-form-group">
        <label>
          <span>Forum NAME</span>
        </label>
        <input
          className={nameError && !name ? 'error' : ''}
          placeholder={nameError ? 'Forum name cannot be empty' : 'e.g. Everfit community'}
          value={name}
          onChange={handleChangeName}
          maxLength={255}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
        />
        <span className={`character-count ${!isFocus && 'not-focused'}`}>{`${name.length}/255`}</span>
      </FormGroup>
      <FormGroup className="forum-form-group no-bottom-margin">
        <label>
          <span>DESCRIPTION</span>
        </label>
        <S.STextArea
          className="forum-description-textarea"
          placeholder="Tell us something about your forum"
          value={description}
          onChange={handleChangeDescription}
        />
      </FormGroup>
    </S.SettingContainer>
  );
};

const mapStateToProps = state => {
  return {
    group: state.rootReducer.forum.group,
    forumList: state.rootReducer.forum.list,
    connectedLeaderboard: state.rootReducer.forum.connectedLeaderboard,
    userId: state.user._id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGroupDetail: bindActionCreators(getGroupDetail, dispatch),
    updateGroup: bindActionCreators(updateGroup, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupGroupSettings);
