import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Image } from 'semantic-ui-react';
import { updateFeaturePreferences } from 'redux/autoflow/settings/actions';
import { Button } from 'shared/FormControl';
import { toggleConfirmModal } from 'actions/modal';
import * as S from './TurnOnFeature.style';
import './style.scss';

function TurnOnFeature(props) {
  const { type, content, icon, title, open, features, workingAutoflow } = props;

  return (
    <Modal open={open} className="autoflow__turn-on-feature-modal">
      <Modal.Content>
        <S.Header>
          {icon && <Image src={icon} />}
          <div>{title}</div>
        </S.Header>
        <S.Content>{content}</S.Content>
        <S.Footer>
          <Button onClick={() => props.toggleConfirmModal(false)}>Cancel</Button>
          <Button
            purple
            onClick={() => {
              const types = [];

              _.forEach(features, (item) => {
                const obj = { type: item.type, state: item.type === type ? true : item.state };
                types.push(obj);
              });
              const bodyData = { autoflow: workingAutoflow._id, types };

              props.updateFeaturePreferences(bodyData).then(() => {
                props.toggleConfirmModal(false);

                if (typeof props.onSuccess === 'function') {
                  props.onSuccess();
                }
              });
            }}
          >
            Turn on
          </Button>
        </S.Footer>
      </Modal.Content>
    </Modal>
  );
}

const mapState = (state) => {
  const {
    isConfirmModalOpen,
    rootReducer: {
      autoflow: {
        settings: { features },
        common: { workingAutoflow },
      },
    },
  } = state;
  return { open: isConfirmModalOpen, features, workingAutoflow };
};

const mapDispatch = (dispatch) => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updateFeaturePreferences: bindActionCreators(updateFeaturePreferences, dispatch),
});

export default connect(mapState, mapDispatch)(TurnOnFeature);
