import { Types } from './actions';

const INITIAL_STATE = {
  loading: false,
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    default:
      return state;
  }
};
