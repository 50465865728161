import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const textBase = `
  color: #202353;
  font-family: 'Open sans';
  font-style: normal;
  line-height: 150%;
`;

const limitText = `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-assign-meal-plan-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 500px;
  }
  &.ui.modal.evf-assign-meal-plan-modal > .header,
  &.ui.modal.evf-assign-meal-plan-modal > .content,
  &.ui.modal.evf-assign-meal-plan-modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const HeaderWrapper = styled.div`
  background: #fff;
  padding: 20px 25px 15px;
  border-radius: 10px 10px 0 0;
`;

export const HeaderTitle = styled.div`
  ${textBase}
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background: #fff;
  padding: 20px 25px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const FormTitle = styled.div`
  ${textBase}
  color: #6a778a;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 11px;
`;

export const FormActions = styled.div`
  width: 360px;
  .multi-select-container {
    .multi-select__menu-list {
      padding-top: 10px;
    }
    .multi-select__control {
      border-color: #e1e1ea;
      padding: 3px 15px;
      min-height: 36px;
      :has(.multi-select__value-container--has-value) {
        .multi-select__value-container {
          div:has(.multi-select__input) {
            margin: unset;
            padding-top: unset;
            padding-bottom: unset;
          }
        }
      }
      &:hover {
        border-color: #5158cf;
        cursor: text;
      }
      .multi-select__value-container {
        gap: 5px;
        align-items: flex-start;
        padding: unset;
        .multi-select__multi-value {
          height: 22px;
          margin: unset;
          border-radius: 5px;
          background-color: #f0f1ff;
          padding: 0 7px;
          gap: 4px;
          align-items: center;
          .multi-select__multi-value__label {
            ${textBase}
            font-size: 12px;
            font-weight: 600;
            ${limitText}
            padding: unset;
          }
          .multi-select__multi-value__remove {
            padding: unset;
            align-items: center;
          }
        }
        .multi-select__placeholder {
          ${textBase}
          color: rgba(163, 163, 181, 0.8);
          font-size: 12px;
          font-weight: 400;
        }
        .multi-select__input {
          ${textBase}
          color: rgba(32, 35, 83, 0.8);
          font-size: 12px;
          font-weight: 400;
          input {
            line-height: 150%;
          }
        }
      }
      ::-webkit-scrollbar {
        width: 16px !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #e1e1ea !important;
        border: 5px solid rgba(0, 0, 0, 0);
        background-clip: padding-box !important;
        border-radius: 9999px;
      }
    }
  }
`;

export const CustomDatetime = styled.div`
  .rdt.new-ui {
    .meal-plan-starting-on {
      height: 36px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 9px 14px;
      border: 1px solid #e1e1ea;
      border-radius: 4px;
      background: #fff;
      cursor: pointer;
      ${textBase}
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      .icon-down {
        transform: translateY(-50%) rotate(180deg);
        right: 20px;
        position: absolute;
        top: 50%;
        path {
          fill: #7b7e91;
        }
      }
      &:hover {
        border-color: #5158cf;
      }
    }
    table {
      border-collapse: collapse;
    }
    thead tr th.dow {
      ${textBase}
      font-size: 13px;
      line-height: 20px;
      color: #202353;
      padding: 16px 4px 8px 1px;
      border-bottom: 1px solid #f6f6f6;
    }
    thead tr:first-child th {
      padding-bottom: 9px;
    }
    .rdtPicker {
      width: 360px;
      padding: 7px 24px 15px 24px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
      bottom: calc(100% + 9px);
      tbody {
        ::before {
          content: '@';
          display: block;
          line-height: 4px;
          text-indent: -99999px;
        }
        tr:has(.rdtDay:not(.rdtDisabled)) {
          position: relative;
          ::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            z-index: -2;
            border-radius: 5px;
          }
          &:hover::after {
            background: #f0f1ff;
          }
        }
        tr td.active {
          position: relative;
          ::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #f0f1ff;
            z-index: -2;
          }
          &:first-child::after {
            border-radius: 5px 0 0 5px;
          }
          &:last-child::after {
            border-radius: 0 5px 5px 0;
          }
        }
      }
      .rdtSwitch {
        ${textBase}
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        position: relative;
        height: unset;
        top: 5px;
        right: 0;
        :hover {
          color: #5158cf;
        }
      }
      .rdtPrev {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/schedule-calendar-change-month.svg);
          position: absolute;
          top: 14px;
          left: 22px;
        }
      }
      .rdtNext {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/schedule-calendar-change-month.svg);
          display: inline-block;
          transform: rotate(180deg);
          position: absolute;
          top: 14px;
          right: 22px;
        }
      }
      .rdtDay.rdtDisabled:not(.rdtActive):hover::before {
        display: block;
      }
      .rdtDisabled::before {
        border: 2px solid #5158cf;
        background-color: #fff;
        width: 34px;
        height: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .rdtDisabled.active::before {
        background-color: #f0f1ff;
      }
    }
    .rdtDay {
      width: 44px;
      height: 36px;
      ${textBase}
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      &::before {
        border: 2px solid #5158cf;
        background-color: #f0f1ff;
        width: 34px;
        height: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.rdtActive::before {
        background-color: #5158cf;
      }
      &.rdtToday {
        color: #5158cf;
      }
      &.rdtDisabled {
        ${textBase}
        color: #cfd0e5;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
      }
      &.rdtToday.rdtActive {
        color: #fff;
        font-weight: 600;
      }
      &.rdtActive {
        color: #fff !important;
      }
      &.rdtNew {
        color: #7b7e91;
        &.rdtDisabled {
          color: #cfd0e5;
        }
      }
    }
    .rdtToday.rdtDay:not(.rdtDisabled):not(.rdtActive):hover {
      color: #5158cf !important;
      font-weight: 600 !important;
    }
    .rdtDay:not(.rdtDisabled):not(.rdtActive):hover {
      color: #202353 !important;
      font-weight: 600 !important;
    }
    .rdtDay.rdtNew:not(.rdtDisabled):not(.rdtActive):hover {
      color: #7b7e91 !important;
      font-weight: 600 !important;
    }
    &.rdtOpen {
      .meal-plan-starting-on {
        border-color: #5158cf;
      }
    }
  }
`;

export const ActionsWrapper = styled.div`
  padding: 15px 25px;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 5px;
  background: #5158cf;
  padding: 7px 30px;
  height: 36px;
  min-width: 101px;
  ${textBase}
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: rgba(81, 88, 207, 0.9);
  }
  ${props =>
    props.isDisabled &&
    css`
      background: #eaeef1 !important;
      color: #7b7e91;
      cursor: not-allowed;
    `}
`;

export const MealPlanName = styled.div`
  ${textBase}
  line-height: 20px;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  border: 1px solid #e1e1ea;
  background: #f8f8f8;
  border-radius: 5px;
  height: 36px;
  width: 100%;
  padding: 7px 14px;
  cursor: not-allowed;
  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const CustomOption = styled.div`
  border-radius: 5px;
  margin: 0 0 0 10px;
  padding: 6px 10px;
  .client-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    column-gap: 10px;
  }
  .client-avatar-wrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 999px;
    border: 1px solid #fbfbfb;
    .client-avatar {
      flex-shrink: 0;
    }
  }
  .client-info-wrapper {
    display: flex;
    flex-direction: column;
    .client-name {
      ${textBase}
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      ${limitText}
    }
    .client-email {
      ${textBase}
      color: #7b7e91;
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
      ${limitText}
    }
    .client-status {
      ${textBase}
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
    }
  }
  .group-info-wrapper {
    display: flex;
    flex-direction: column;
    .group-title-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      ${textBase}
      font-size: 13px;
      line-height: 20px;
      .group-label {
        font-weight: 600;
      }
      .group-name {
        font-weight: 400;
      }
    }
    .group-total {
      ${textBase}
      color: #7B7E91;
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
    }
  }
  &:hover {
    cursor: pointer;
    background: #f0f1ff;
    .client-name {
      color: #5158cf;
    }
  }
  ${props =>
    props.isAssigned &&
    css`
      padding: 8px 10px;
      background: #fff;
      opacity: 0.4;
      &:hover {
        cursor: not-allowed;
        background: #f0f1ff;
        .client-name {
          color: #202353;
        }
      }
    `}
`;

export const MenuTitleContainer = styled.div`
  height: 25px;
  padding: 5px 10px;
`;

export const MenuTitleText = styled.div`
  ${textBase}
  color: #7b7e91;
  font-size: 10px;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
`;

export const ErrorMessage = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  ${({ isHasSpacing }) =>
    isHasSpacing &&
    css`
      margin-bottom: 5px;
    `}
`;

export const ErrorList = styled.ul`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: unset;
  padding-left: 20px;
`;

export const ErrorItem = styled.li`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const ErrorBold = styled.span`
  ${textBase}
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
`;

export const customStyles = {
  control: baseStyle => ({
    ...baseStyle,
    minHeight: 36,
    height: '100%',
    boxShadow: 'none',
    maxHeight: '94px',
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#e1e1ea !important',
    },
  }),
  menuList: baseStyle => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: '10px',
    paddingRight: '4px',
    marginRight: '4px',
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#e1e1ea !important',
    },
  }),
  noOptionsMessage: baseStyle => ({
    ...baseStyle,
    color: '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    paddingTop: '15px',
    paddingBottom: '10px',
  }),
};
