// libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Checkbox } from 'shared/FormControl';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

// store
import { getTrainerMetrics } from 'redux/metric-group-library/action';
import { bindActionCreators } from 'redux';

// components
import MetricSkeleton from '../Skeleton/MetricSkeleton';

// assets

import * as S from './style';

function AddMetricToGroup(props) {
  const {
    selectedMetrics = [],
    getTrainerMetrics,
    onSetSelectedMetrics,
    metrics,
    textSearch,
    loading,
    onClickedCheckbox,
  } = props;
  const [clickedMetrics, setClickMetrics] = useState([]);
  const [metricResults, setMetricResults] = useState([]);
  const [isSelectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    getTrainerMetrics();
  }, []);

  const handleFilterSelectedMetrics = list => {
    const selectedId = selectedMetrics.map(it => it._id);
    return list.filter(item => !(selectedId || []).includes(item._id));
  };

  useEffect(() => {
    setMetricResults(handleFilterSelectedMetrics(metrics));
  }, [metrics]);

  useEffect(() => {
    if (!isEmpty(metricResults)) {
      const isClickAll = metricResults.every(item => {
        return clickedMetrics.some(mt => mt._id === item._id);
      });
      setSelectedAll(isClickAll);
    } else {
      setSelectedAll(false);
    }
    onClickedCheckbox(!isEmpty(clickedMetrics));
  }, [metricResults, clickedMetrics]);

  const handleSearchResults = textSearch => {
    const textLowercase = textSearch.toLowerCase();

    const result = metrics.filter(item => {
      const nameLowercase = ((item || {}).name || '').toLowerCase();
      return nameLowercase.includes(textLowercase);
    });
    const filteredResult = handleFilterSelectedMetrics(result);
    setMetricResults(filteredResult);
  };

  useEffect(() => {
    handleSearchResults(textSearch);
  }, [textSearch]);

  const handleSelect = (metric, isSelected) => {
    if (isSelected) {
      const newList = clickedMetrics.filter(mt => mt._id !== metric._id);
      setSelectedAll(false);
      setClickMetrics(uniq(newList));
      return;
    }

    const newList = [...clickedMetrics, metric];
    setClickMetrics(uniq(newList));
  };

  const handleSelectedAll = event => {
    event.preventDefault();

    if (isEmpty(metricResults)) return;
    setSelectedAll(prev => !prev);
    if (!isSelectedAll) {
      setClickMetrics(uniq([...clickedMetrics, ...metricResults]));
      return;
    }
    const resultIds = metricResults.map(item => item._id);
    const currentClickedMetrics = clickedMetrics.filter(item => {
      return !resultIds.includes(item._id);
    });

    setClickMetrics(uniq(currentClickedMetrics));
  };

  const onAddSelectToGroup = () => {
    onSetSelectedMetrics(clickedMetrics);
  };

  const renderMetric = metric => {
    const type = get(metric, 'category.name');
    const unit = get(metric, 'unit.title');
    const isSelected = clickedMetrics.some(item => (item || {})._id === (metric || {})._id);
    return (
      <S.MetricItemWrapper
        checked={isSelected}
        key={metric._id}
        onClick={event => {
          event.preventDefault();
          handleSelect(metric, isSelected);
        }}
      >
        <S.MetricName>
          <Checkbox
            checked={isSelected}
            onChange={event => {
              event.preventDefault();
              handleSelect(metric, isSelected);
            }}
            size={20}
            className="checkbox-metric-icon"
          />
          <span className="metric-name">{metric.name}</span>
        </S.MetricName>
        <S.MetricType>{type}</S.MetricType>
        <S.MetricUnit>{unit || '--'}</S.MetricUnit>
      </S.MetricItemWrapper>
    );
  };

  return (
    <>
      <S.Wrapper isAdd={true}>
        <S.SelectMetricWrapper>
          {(!isEmpty(metricResults) || (isEmpty(metricResults) && !!textSearch)) && (
            <S.HeaderTable>
              <S.HeaderMetricLabel onClick={handleSelectedAll}>
                <Checkbox
                  checked={isSelectedAll}
                  onChange={handleSelectedAll}
                  size={20}
                  className="checkbox-metric-icon"
                />
                Metrics
              </S.HeaderMetricLabel>
              <S.HeaderTypeLabel>Type</S.HeaderTypeLabel>
              <S.HeaderUnitLabel>Unit</S.HeaderUnitLabel>
            </S.HeaderTable>
          )}
          <S.ContentTable>
            {loading &&
              Array(4)
                .fill(null)
                .map((_, index) => <MetricSkeleton key={index} />)}

            {isEmpty(metricResults) && !loading ? (
              <S.NoResult hasTextSearch={!!textSearch}>
                {textSearch ? 'No results found.' : 'No metrics available.'}
              </S.NoResult>
            ) : (
              !loading && metricResults.map(metric => renderMetric(metric))
            )}
          </S.ContentTable>
        </S.SelectMetricWrapper>
      </S.Wrapper>

      <S.Action isAddMetric={true}>
        <S.SaveButton purple onClick={onAddSelectToGroup} disabled={isEmpty(clickedMetrics)}>
          Add to group
        </S.SaveButton>
      </S.Action>
    </>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { metricGroupLibrary },
  } = state;

  return {
    metrics: (metricGroupLibrary || {}).metrics,
    loading: (metricGroupLibrary || {}).loadingMetric,
  };
};

const mapDispatchToProps = dispatch => ({
  getTrainerMetrics: bindActionCreators(getTrainerMetrics, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMetricToGroup);
