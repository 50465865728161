import React from 'react';

export const Autofill = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F5F6" />
    <g clipPath="url(#clip0)">
      <path d="M18.2615 15.9656L16.0342 13.7383L10.0415 19.731L12.2689 21.9583L18.2615 15.9656Z" fill="#95A6B7" />
      <path d="M20.8069 12.2535L19.7462 11.1929L17.9431 12.996L19.0038 14.0566L20.8069 12.2535Z" fill="#95A6B7" />
      <path d="M22 15.25H19.75V16.75H22V15.25Z" fill="#95A6B7" />
      <path d="M20.773 19.7165L19.182 18.1255L18.1214 19.1861L19.7123 20.7771L20.773 19.7165Z" fill="#95A6B7" />
      <path d="M16.75 10H15.25V12.25H16.75V10Z" fill="#95A6B7" />
      <path d="M13.8786 12.8141L12.2876 11.2231L11.2269 12.2838L12.8179 13.8748L13.8786 12.8141Z" fill="#95A6B7" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
);

export const CameraIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F5F6" />
    <path
      d="M21.25 13H19.375L18.1 11.05C17.95 10.9 17.725 10.75 17.5 10.75H14.5C14.275 10.75 14.05 10.9 13.9 11.05L12.625 13H10.75C10.3 13 10 13.3 10 13.75V20.5C10 20.95 10.3 21.25 10.75 21.25H21.25C21.7 21.25 22 20.95 22 20.5V13.75C22 13.3 21.7 13 21.25 13ZM16 19C14.725 19 13.75 18.025 13.75 16.75C13.75 15.475 14.725 14.5 16 14.5C17.275 14.5 18.25 15.475 18.25 16.75C18.25 18.025 17.275 19 16 19Z"
      fill="#95A6B7"
    />
  </svg>
);
