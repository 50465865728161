import { PACKAGE_STATUS, PURCHASE_STATUS, INVOICE_STATUS } from 'constants/commonData';

export const PACKAGE_LIST_TAB_ITEMS = [
  { key: 'all', value: '', label: 'All Packages', showTotal: true },
  { key: 'publish', value: PACKAGE_STATUS.PUBLISH, label: 'Published', showTotal: true },
  { key: 'draft', value: PACKAGE_STATUS.DRAFT, label: 'Draft', showTotal: true },
  { key: 'archive', value: PACKAGE_STATUS.ARCHIVE, label: 'Archived', showTotal: false },
];

export const PURCHASE_STATUS_TAB_ITEMS = [
  { key: PURCHASE_STATUS.ACTIVE, value: PURCHASE_STATUS.ACTIVE, label: 'Active Purchases' },
  { key: PURCHASE_STATUS.TRIAL, value: PURCHASE_STATUS.TRIAL, label: 'Trial' },
  { key: 'canceled', value: PURCHASE_STATUS.CANCEL, label: 'Cancelled' },
];

export const INVOICE_STATUS_TAB_ITEMS = [
  { key: 'all', value: 'all_invoices', label: 'All Invoices' },
  { key: INVOICE_STATUS.PAID, value: INVOICE_STATUS.PAID, label: 'Paid' },
  { key: INVOICE_STATUS.OVERDUE, value: INVOICE_STATUS.OVERDUE, label: 'Overdue' },
];
