import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 32px;
  color: #202353;
`;

export const Wrapper = styled.div`
  .header {
    ${baseText}
    margin-bottom: 50px;
    @media only screen and (max-height: 768px) {
      margin-bottom: 25px;
    }
    text-align: center;
  }
  .list-format {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
`;

export const Item = styled.div`
  position: relative;
  width: 293px;
  border-radius: 10px;
  border: 1px solid ${({ mainColor }) => mainColor};
  .icon-label-upcoming {
    position: absolute;
    right: -4px;
    top: -10px;
  }
  .banner {
    width: 100%;
    height: 196px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background-color: ${({ secondaryColor }) => secondaryColor};
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    padding: 25px 20px 20px;
    .title {
      ${baseText}
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 10px;
    }
    .desc {
      ${baseText}
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: #7b7e91;
      margin-bottom: 20px;
    }
    .auto-generate {
      display: flex;
      align-items: center;
      gap: 7px;
      height: 21px;
      cursor: default;
      span {
        ${baseText}
        font-size: 12px;
        line-height: 18px;
        color: #7b7e91;
        border-bottom: 1px dashed #e1e1ea;
      }
      .icon-check {
        width: 16px;
        height: 16px;
        margin-bottom: 5px;
        path {
          fill: #7b7e91;
        }
      }

      .__react_component_tooltip {
        ${baseText}
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #fff;
        border-radius: 4px;
        max-width: 184px;
        padding: 8px 16px;
      }
      .__react_component_tooltip.type-dark.place-top:after {
        border-top-width: 8px !important;
        border-top-color: #333 !important;
      }
      .__react_component_tooltip.place-top::after {
        margin-bottom: -1px !important;
      }
      .__react_component_tooltip.place-top {
        margin-top: -13px !important;
      }
    }
    .action {
      ${baseText}
      font-size: 13px;
      line-height: 20px;
      color: #fff;
      background-color: rgba(81, 88, 207, 1);
      margin-top: 30px;
      width: 100%;
      height: 36px;
      border-radius: 5px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.type-notify-me {
        background-color: #f0f0f2;
        color: #202353;
        span {
          display: flex;
          align-items: center;
        }
        .icon-check {
          margin-left: 5px;
          path {
            fill: #7b7e91;
          }
        }
      }
      &:hover {
        background-color: rgba(81, 88, 207, 0.9);
        &.type-notify-me {
          background-color: #f0f1ff;
          color: #5158cf;
        }
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          background-color: rgba(81, 88, 207, 1);
          &.type-notify-me {
            background-color: #f0f0f2;
            color: #202353;
          }
        }
      }
    }
  }
`;
