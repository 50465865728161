import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import * as S from './style';
import moment from 'moment';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import InAppMessageCounter from './InAppMessageCounter';
import { CDN_URL, IN_APP_MESSAGE_STATUS } from 'constants/commonData';

export default props => {
  const { message, id, compactView, mIndex, isPlaceholder, draggableId } = props;

  const onClickMessage = () => {
    if (typeof props.onViewDetail === 'function' && !isPlaceholder) {
      props.onViewDetail(message);
    }
  };

  const onEdit = () => {
    props.onEdit(message);
  };

  const onCopy = () => {
    props.onCopy(message);
  };

  const onDelete = () => {
    props.onDelete(message);
  };

  if (draggableId) {
    return (
      <Draggable draggableId={draggableId} index={mIndex}>
        {provided => (
          <S.MessageWrapper
            className="autoMessage autoMessage--inApp"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={onClickMessage}
          >
            <S.MessageContainer className="autoMessage__container">
              <S.MessageHeader>
                <div className="autoMessage__time">
                  <img src={`${CDN_URL}/images/in-app-message.svg`} alt="" />
                  <span>{message.time ? moment(message.time, 'HH:mm').format('hh:mm A') : ''}</span>
                  {message.status === IN_APP_MESSAGE_STATUS.PAUSE ? <span className="paused">Paused</span> : null}
                </div>
                <Dropdown className="autoMessage__actions">
                  <Option key="edit" onClick={onEdit}>
                    <img src={`${CDN_URL}/images/edit-white.svg`} width={14} style={{ marginRight: 12 }} alt="" />
                    <span>Edit</span>
                  </Option>
                  <Option key="copy" onClick={onCopy}>
                    <img src={`${CDN_URL}/images/duplicate.svg`} width={14} style={{ marginRight: 12 }} alt="" />
                    <span>Copy</span>
                  </Option>
                  <Option key="delete" onClick={onDelete}>
                    <img src={`${CDN_URL}/images/delete.svg`} width={14} style={{ marginRight: 12 }} alt="" />
                    <span>Delete</span>
                  </Option>
                </Dropdown>
              </S.MessageHeader>
              <S.MessageContent className="autoMessage__content">
                <div className="autoMessage__title">{message.subject}</div>
              </S.MessageContent>
              {!compactView ? <InAppMessageCounter message={message} /> : null}
            </S.MessageContainer>
          </S.MessageWrapper>
        )}
      </Draggable>
    );
  }

  return (
    <S.MessageWrapper id={id} className="autoMessage autoMessage--inApp">
      <S.MessageContainer className="autoMessage__container">
        <S.MessageHeader>
          <div className="autoMessage__time">
            <img src={`${CDN_URL}/images/in-app-message.svg`} alt="" />
            <span>{message.time ? moment(message.time, 'HH:mm').format('hh:mm A') : ''}</span>
            {message.status === IN_APP_MESSAGE_STATUS.PAUSE ? <span className="paused">Paused</span> : null}
          </div>
        </S.MessageHeader>
        <S.MessageContent className="autoMessage__content">
          <div className="autoMessage__title">{message.subject}</div>
        </S.MessageContent>
        {!compactView ? <InAppMessageCounter message={message} /> : null}
      </S.MessageContainer>
    </S.MessageWrapper>
  );
};
