import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';

import * as S from '../style';

function ButtonPasteWeek(props) {
  const { calendarType, selectedWeek, calendarStartDate, handlePasteWeek, currentWeekIndexHover, permission } = props;
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V1 || !_.get(permission, 'copy_week')) return <></>;
  if (_.isEmpty(selectedWeek)) return <></>;
  const listAvailablePasteWeek = useMemo(() => {
    const available = [];
    for (let index = 0; index < calendarType; index++) {
      const diffIndex = Number(currentWeekIndexHover) === Number(index);
      available.push(diffIndex);
    }
    return available;
  }, [calendarType, calendarStartDate, selectedWeek, currentWeekIndexHover]);
  const debouncePasteWeek = useCallback(_.debounce(handlePasteWeek, 100), []);
  function onPasteWeek(idx, event) {
    const multiPaste = event.shiftKey;
    const day = DateTime.fromISO(calendarStartDate.toISODate());
    const startDatePaste = day.plus({ days: 7 * idx }).toFormat('MM-dd-yyyy');
    debouncePasteWeek({ startDatePaste, multiPaste });
  }
  return (
    <S.PasteButtonWrapper style={{ height: `calc((100% - 27px) / ${calendarType})` }}>
      {listAvailablePasteWeek.map((availablePaste, idx) =>
        availablePaste ? (
          <S.PasteButtonContainer>
            <S.PasteButton onClick={event => onPasteWeek(idx, event)}>Paste Week</S.PasteButton>
          </S.PasteButtonContainer>
        ) : (
          <S.PasteButtonContainerEmpty />
        ),
      )}
    </S.PasteButtonWrapper>
  );
}

export default ButtonPasteWeek;
