import styled, { css } from 'styled-components';

export const ResponsesContainer = styled.div`
  display: grid;
  grid-template-columns: 280px calc(100% - 280px);
  height: calc(100% - 60px);
  ${props =>
    props.isSummary &&
    css`
      grid-template-columns: 100%;
    `}
`;

export const EmptyResponses = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 60px);
`;

export const EmptyText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #9a9da6;
  margin-top: 13px;
`;
