import React, { useEffect } from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { connect } from 'react-redux';

import {
  timeSince,
  getUserShortName,
  convertS3UrlToCloudFrontUrl,
  getFormatImageUrlWithSML,
} from 'utils/commonFunction';
import { MEDIA_FILE_REJECTED_CONTENT } from 'constants/commonData';
import MediaPlayer from 'shared/MediaPlayer';
import FileUpload from 'shared/FileUpload';
import GiphyWatermark from 'shared/Giphy/components/Watermark';
import S3Video from 'shared/S3Video';
import Urlify from 'shared/Urlify';

import * as S from './style';

function Comment(props) {
  const {
    comment,
    showAttachItem,
    isAutoScroll = false,
    className = '',
    hasReplyButton,
    onReplyMessage,
    isReplyComment,
    dataIndex = '',
    cloudfrontList,
  } = props;
  const {
    author,
    content,
    _id,
    createdAt,
    attach_item,
    attachment,
    attachment_type,
    isLocalFile,
    attachment_thumbnail,
    uploadConfigs,
    loadingReply = false,
  } = comment;
  const name = `${get(author, 'first_name')} ${get(author, 'last_name')}`;
  const avatar = get(author, 'avatar');

  const color = get(author, 'color');
  const duration = timeSince(new Date(createdAt), 'minute');
  const tooltip = 'tooltip_' + _id;
  const isMediaComment = content === MEDIA_FILE_REJECTED_CONTENT && attachment;
  const isVideo = attachment_type && attachment_type.startsWith('video');
  const isImage = attachment_type && attachment_type.startsWith('image');
  const isVideoReply = isReplyComment && attachment_type && attachment_type.startsWith('video');

  const renderMediaFile = () => {
    if (!isMediaComment) {
      return null;
    }

    const src = isLocalFile ? attachment : attachment_thumbnail;
    const srcReply = attachment_thumbnail ? attachment_thumbnail : attachment;
    const preview = [isImage ? getFormatImageUrlWithSML(attachment_thumbnail) : attachment_thumbnail || ''];

    if (!isVideo) {
      preview.push(attachment || '');
    }

    return (
      <S.MediaBox>
        <S.MediaContainer isReplyComment={isReplyComment}>
          {isLocalFile ? (
            <>
              {isVideo ? (
                <S.Video isReplyComment={isReplyComment}>
                  <source src={src} type={attachment_type} />
                  <source src={src} type="video/mp4" />
                </S.Video>
              ) : (
                <S.ImageWithFallbackWrapper isReplyComment={isReplyComment} src={[src]} />
              )}
              <FileUpload
                onSuccess={props.onUploadSuccess}
                onError={props.onUploadFail}
                onCancel={props.onCancelUpload}
                configs={uploadConfigs}
                removeCancelUpload
              />
            </>
          ) : includes(preview[0], 'giphy') ? (
            <GiphyWatermark isReplyComment={isReplyComment} ratioComment>
              <S.S3ImageWithFallbackWrapper isReplyComment={isReplyComment} src={preview} comment={true} />
            </GiphyWatermark>
          ) : (
            <>
              {isVideo ? (
                <S3Video
                  videoLink={attachment}
                  videoType={attachment_type}
                  thumbnail={attachment_thumbnail}
                  mediaId={_id}
                />
              ) : includes(preview[0], 'giphy') ? (
                <GiphyWatermark isReplyComment={isReplyComment} ratioComment>
                  <S.S3ImageWithFallbackWrapper
                    isReplyComment={isReplyComment}
                    src={preview}
                    cover={true}
                    thumbnail={true}
                  />
                </GiphyWatermark>
              ) : (
                <GiphyWatermark isReplyComment={isReplyComment} showWatermark={false} ratioComment>
                  <S.S3ImageWithFallbackWrapper
                    isReplyComment={isReplyComment}
                    src={preview}
                    cover={true}
                    thumbnail={true}
                  />
                </GiphyWatermark>
              )}
            </>
          )}
          {!uploadConfigs && (
            <MediaPlayer url={attachment} type={attachment_type} thumbnail={isVideo ? attachment_thumbnail : ''} />
          )}
        </S.MediaContainer>
      </S.MediaBox>
    );
  };

  useEffect(() => {
    return () => {
      if (isLocalFile) {
        window.URL.revokeObjectURL(attachment);
      }
    };
  }, []);

  return (
    <S.Wrapper key={_id} isAutoScroll={isAutoScroll} className={className} data-index={dataIndex}>
      <Avatar
        name={getUserShortName(author)}
        color={color}
        className="comment__avatar"
        size="20"
        textSizeRatio="2"
        src={convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true)}
      />
      <S.Container className="comment">
        <S.Content className="comment__content">
          <S.Author className="comment__username">{name}</S.Author>{' '}
          <S.Message>
            <Urlify>{content !== MEDIA_FILE_REJECTED_CONTENT ? content : ''}</Urlify>
          </S.Message>
        </S.Content>
        <S.OtherInfo className="comment__other-info">
          {renderMediaFile()}
          <S.CommentInfoWrapper>
            {showAttachItem && attach_item ? (
              <S.CommentAttachItemWrapper>
                <S.CommentAttachItem className="attach_item">{attach_item.title}</S.CommentAttachItem>
                {attach_item.title && <span>&nbsp;•&nbsp;</span>}
              </S.CommentAttachItemWrapper>
            ) : null}
            <S.CommentInfo>
              <S.CommentDate className="comment__date" data-tip data-for={tooltip}>
                {duration}
              </S.CommentDate>
              {showAttachItem && attach_item && hasReplyButton && <S.Seperate>-</S.Seperate>}
              {hasReplyButton && (
                <S.ReplyWrapper loadingReply={loadingReply}>
                  <S.ReplyButton
                    onClick={onReplyMessage && onReplyMessage({ authorId: get(author, '_id', ''), name, comment })}
                  >
                    Reply
                  </S.ReplyButton>
                </S.ReplyWrapper>
              )}
            </S.CommentInfo>
          </S.CommentInfoWrapper>
        </S.OtherInfo>
      </S.Container>
      <ReactTooltip className="app-tooltip" id={tooltip} effect="solid" place={'top'}>
        <span>{moment(createdAt).format('dddd, MMMM DD, YYYY [at] h:mm A')}</span>
      </ReactTooltip>
    </S.Wrapper>
  );
}

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapStateToProps)(Comment);
