// Libs
import React from 'react';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import toNumber from 'lodash/toNumber';
import replace from 'lodash/replace';
import includes from 'lodash/includes';

import { toast } from 'react-toastify';

// Utils
import { roundNumberBodyMetric } from 'utils/commonFunction';

// Constants
import { ENUM_TIME, MACRO_NUTRIENTS } from './constants';

export const getNutrientValue = (list, nutrientType) => {
  return (list.find(item => item.type === nutrientType) || {}).value;
};

export const calculatePercentage = (value, total) => roundNumberBodyMetric((value / total || 0) * 100);

export const calculateNutritionValues = nutrients => {
  const valueProtein = getNutrientValue(nutrients, MACRO_NUTRIENTS.PROTEIN);
  const valueCarbs = getNutrientValue(nutrients, MACRO_NUTRIENTS.CARBS);
  const valueFat = getNutrientValue(nutrients, MACRO_NUTRIENTS.FAT);
  const valueCalories = getNutrientValue(nutrients, MACRO_NUTRIENTS.CALORIES);

  return {
    protein: valueProtein,
    carbs: valueCarbs,
    fat: valueFat,
    calories: valueCalories,
  };
};

export const calculateNutritionChartValues = nutrients => {
  const valueProtein = getNutrientValue(nutrients, MACRO_NUTRIENTS.PROTEIN) || 0;
  const valueCarbs = getNutrientValue(nutrients, MACRO_NUTRIENTS.CARBS) || 0;
  const valueFat = getNutrientValue(nutrients, MACRO_NUTRIENTS.FAT) || 0;
  const valueCalories = getNutrientValue(nutrients, MACRO_NUTRIENTS.CALORIES) || 0;

  const percentProtein = round(calculatePercentage(valueProtein * 4, valueCalories));
  const percentCarbs = round(calculatePercentage(valueCarbs * 4, valueCalories));
  let percentFat = round(calculatePercentage(valueFat * 9, valueCalories));
  if (percentProtein > 0 || percentCarbs > 0) {
    percentFat = 100 - (percentProtein + percentCarbs);
  }
  return {
    protein: { value: valueProtein, percent: percentProtein },
    carbs: { value: valueCarbs, percent: percentCarbs },
    fat: { value: valueFat, percent: percentFat },
    calories: { value: valueCalories },
  };
};

export const convertTimeToString = (str = ':', value, time) => {
  if (!str) return;
  const [hour, minute] = str.split(':');
  if (time === ENUM_TIME.HOUR) {
    return `${value}:${minute}`;
  }
  if (time === ENUM_TIME.MINUTE) {
    return `${hour}:${value}`;
  }
  return str;
};

export const convertTimeStringToArray = (str = ':') => {
  if (!str) return;
  const [hour, minute] = str.split(':');
  return {
    hour: hour,
    minute: minute,
  };
};

export const formatViewTime = (h, m) => {
  const hour = Number(h);
  const minute = Number(m);
  if (hour <= 0 && minute <= 0) return '--';
  const formattedHour = hour > 0 ? `${hour}h` : '';
  const formattedMinute = minute > 0 ? `${minute}m` : '';
  return `${formattedHour} ${formattedMinute}`.trim();
};

export const onlyAllowNumberTime = event => {
  const disallowedKeyCodes = [45, 43, 101, 44, 46];

  if (disallowedKeyCodes.includes(event.which)) {
    event.preventDefault();
  }
};

export const checkAllValuesGreaterThanZero = (list = []) => {
  return list.every(item => item && item.value > 0);
};

export const convertDataMacroNutrients = (macroNutrients = []) => {
  return macroNutrients.map(({ type, value }) => ({
    type,
    value: value !== '' ? value : undefined,
  }));
};

export const sortCaloriesToTop = arr => {
  const result = arr.sort((a, b) => {
    if (a.type === MACRO_NUTRIENTS.CALORIES) {
      return -1;
    }
    if (b.type === MACRO_NUTRIENTS.CALORIES) {
      return 1;
    }
    return 0;
  });
  return result;
};

export const getRandomInt = (max = 0) => {
  return Math.floor(Math.random() * max);
};

export const extractUrlPresigned = (url = '') => url.split('?')[0];

const cookingInstructionFilter = cookingInstructions => {
  const result = cookingInstructions.filter(item => {
    const { content, attachments } = item || {};
    const check = content || !isEmpty(attachments);
    return check;
  });
  return result;
};

const preparationInstructionFilter = preparationInstructions => {
  const result = preparationInstructions.filter(item => {
    const { content, attachments } = item || {};
    const check = content || !isEmpty(attachments);
    return check;
  });
  return result;
};

const cookingInstructionValid = cookingInstructions => {
  const result = cookingInstructions.some(item => {
    const { content, attachments } = item || {};
    const check = content === '' && !isEmpty(attachments);
    return check;
  });
  return result;
};

const preparationInstructionValid = preparationInstructions => {
  const result = preparationInstructions.some(item => {
    const { content, attachments } = item || {};
    const check = content === '' && !isEmpty(attachments);
    return check;
  });
  return result;
};

export const validRecipe = detailRecipe => {
  const {
    name = '',
    number_of_serving,
    cooking_instructions = [],
    prepare_instructions = [],
    ingredients = [],
    error = {},
  } = detailRecipe;

  const { intro_instruction: validIntroInstruction = false } = error || {};

  const filterCookingInstructions = cookingInstructionFilter(cooking_instructions);
  const checkValidCookingInstructions = cookingInstructionValid(filterCookingInstructions);

  const filterPreparationInstructions = preparationInstructionFilter(prepare_instructions);
  const checkValidPreparationInstructions = preparationInstructionValid(filterPreparationInstructions);

  const validName = !name;
  const validNumberOfServing = +number_of_serving <= 0;
  const validIngredients = validAllValueStringIngredient(ingredients);
  const validCookingInstructions = checkValidCookingInstructions;
  const validPreparationInstructions = checkValidPreparationInstructions;

  const isValid =
    validName ||
    validNumberOfServing ||
    !validIngredients ||
    validCookingInstructions ||
    validPreparationInstructions ||
    validIntroInstruction;

  if (validNumberOfServing) {
    toast.error('The number of servings must be greater than 0.');
  }

  if (!validIngredients) {
    toast.error(contentToastIngredient(ingredients));
  }

  if (validCookingInstructions) {
    toast.error('Please add cooking instruction.');
  }

  if (validPreparationInstructions) {
    toast.error('Please add preparation instruction.');
  }

  if (validIntroInstruction) {
    toast.error('Please enter a valid Youtube or Vimeo URL.');
  }

  return isValid;
};

export const validRecipePublish = detailRecipe => {
  const {
    name = '',
    prep_time = 0,
    cooking_time = 0,
    categories = [],
    macro_nutrients = [],
    cooking_instructions = [],
    prepare_instructions = [],
    ingredients = [],
    number_of_serving,
    ingredient_text = '',
  } = detailRecipe;

  const filterCookingInstructions = cookingInstructionFilter(cooking_instructions);
  const checkValidCookingInstructions = cookingInstructionValid(filterCookingInstructions);

  const filterPreparationInstructions = preparationInstructionFilter(prepare_instructions);
  const checkValidPreparationInstructions = preparationInstructionValid(filterPreparationInstructions);

  const checkValidIngredients = !validAllValueStringIngredient(ingredients);

  const getCalories = macro_nutrients.find(item => item && item.type === MACRO_NUTRIENTS.CALORIES);
  const checkValidMacroNutrients = get(getCalories, 'value', 0) <= 0;

  const validName = !name;
  const validCategory = isEmpty(categories);
  const validCookingTime = +cooking_time <= 0 && +prep_time <= 0;
  const validNumberOfServing = +number_of_serving <= 0;
  const validIngredients = (checkValidIngredients || isEmpty(ingredients)) && !ingredient_text;
  const validCookingInstructions = checkValidCookingInstructions;
  const validPreparationInstructions = checkValidPreparationInstructions;
  const validEmptyInstructions = isEmpty(filterCookingInstructions) && isEmpty(filterPreparationInstructions);
  const validMacroNutrients = checkValidMacroNutrients;

  const isValid =
    validName ||
    validCategory ||
    validCookingTime ||
    validNumberOfServing ||
    validIngredients ||
    validCookingInstructions ||
    validPreparationInstructions ||
    validEmptyInstructions ||
    validMacroNutrients;

  return isValid;
};

export const validRecipePublishNotSave = detailRecipe => {
  const {
    name = '',
    prep_time = 0,
    cooking_time = 0,
    categories = [],
    macro_nutrients = [],
    cooking_instructions = [],
    prepare_instructions = [],
    ingredients = [],
    number_of_serving,
    ingredient_text = '',
    error = {},
    is_auto_calculated = false,
    auto_calculated_nutrition = {},
  } = detailRecipe;

  const { intro_instruction: validIntroInstruction = false } = error || {};

  const filterCookingInstructions = cookingInstructionFilter(cooking_instructions);
  const checkValidCookingInstructions = cookingInstructionValid(filterCookingInstructions);

  const filterPreparationInstructions = preparationInstructionFilter(prepare_instructions);
  const checkValidPreparationInstructions = preparationInstructionValid(filterPreparationInstructions);

  const checkValidIngredients = validAllValueStringIngredient(ingredients);

  const getCalories = (is_auto_calculated
    ? get(auto_calculated_nutrition, 'macro_nutrients', [])
    : macro_nutrients
  ).find(item => item && item.type === MACRO_NUTRIENTS.CALORIES);
  const checkValidMacroNutrients = get(getCalories, 'value', 0) <= 0;

  const validName = !name;
  const validCategory = isEmpty(categories);
  const validCookingTime = +cooking_time <= 0 && +prep_time <= 0;
  const validNumberOfServing = +number_of_serving <= 0;
  const validIngredients = (!checkValidIngredients || isEmpty(ingredients)) && !ingredient_text;
  const validCookingInstructions = checkValidCookingInstructions;
  const validPreparationInstructions = checkValidPreparationInstructions;
  const validMacroNutrients = checkValidMacroNutrients;

  if (validNumberOfServing) {
    toast.error('The number of servings must be greater than 0.');
  }

  if (!checkValidIngredients) {
    toast.error(contentToastIngredient(ingredients));
  }

  if (isEmpty(ingredients) && !ingredient_text) {
    toast.error('Please add at least 1 ingredient.');
  }

  if (checkValidMacroNutrients) {
    toast.error('Please add at least 1 nutrient.');
  }

  if (checkValidCookingInstructions) {
    toast.error('Please add cooking instruction.');
  }

  if (checkValidPreparationInstructions) {
    toast.error('Please add preparation instruction.');
  }

  if (isEmpty(filterCookingInstructions) && isEmpty(filterPreparationInstructions)) {
    toast.error('Please add at least 1 step for the preparation either cooking instruction.');
  }

  if (validIntroInstruction) {
    toast.error('Please enter a valid Youtube or Vimeo URL.');
  }

  const isValid =
    validName ||
    validCategory ||
    validCookingTime ||
    validIngredients ||
    validCookingInstructions ||
    validPreparationInstructions ||
    validMacroNutrients ||
    validNumberOfServing ||
    validIntroInstruction ||
    (isEmpty(filterCookingInstructions) && isEmpty(filterPreparationInstructions));

  return isValid;
};

export const convertIngredientList = ingredients => {
  const result = ingredients.map(item => {
    const { unit } = item || {};
    const { unique_code, unit_group, name, formula } = unit || {};

    const convertUnit = {
      ...unit,
      key: unique_code,
      label: unit_group !== 'other' ? `${name} (${formula})` : name,
      acronym: formula,
    };

    return { ...item, unit: convertUnit };
  });

  return result;
};

export const handleEmptyValue = value => {
  return +value > 0 ? value : <span className="value-empty">—</span>;
};

export const validValueStringIngredient = str => {
  if (!hasNoOrOneSpecialChar(str)) return false;
  // Check if the string matches the regex pattern
  const regex = /^[0-9]+(?:[,.][0-9]+)?(?:\/[0-9]+(?:[,.][0-9]+)?)?$/;
  if (!regex.test(str)) {
    return false;
  }

  // Check for division by zero
  if (str.includes('/')) {
    const [, denominator] = str.split('/');
    if (parseFloat(denominator) === 0) {
      return false;
    }
  } else {
    // Convert comma to dot for non-fractional numbers
    const normalizedStr = str.replace(',', '.');
    if (parseFloat(normalizedStr) === 0) {
      return false;
    }
  }

  return true;
};

export const validAllValueStringIngredient = list => {
  return list.every(({ value_as_text }) => {
    return value_as_text && validValueStringIngredient(value_as_text);
  });
};

const hasMoreDecimals = (number, n) => (number.toString().split('.')[1] || '').length > n;

const hasNoOrOneSpecialChar = str => {
  // Match all occurrences of /, ,, or .
  let match = str.match(/[\/,\.]/g);
  // Check if match is null (no occurrences) or length is 1 (exactly one occurrence)
  return !match || match.length === 1;
};

export const checkBlockValueIngredient = (str, max_value = 9999, max_length = 4) => {
  if (!hasNoOrOneSpecialChar(str)) return false;
  if (str.includes('/')) {
    const [first = '', second = ''] = str.split('/');
    const firstValue = parseFloat(str.replace(',', '.'));
    const secondValue = parseFloat(str.replace(',', '.'));
    if (
      first.length > max_length ||
      second.length > max_length ||
      hasMoreDecimals(firstValue, 0) ||
      hasMoreDecimals(secondValue, 0)
    )
      return true;
  } else {
    const numberWithDot = parseFloat(str.replace(',', '.'));
    if (numberWithDot > max_value || hasMoreDecimals(numberWithDot, 2)) return true;
  }
  return false;
};

const contentToastIngredient = list => {
  const checkEmpty = list.some(({ value_as_text }) => !value_as_text || Number(value_as_text) === 0);
  if (checkEmpty) return 'The ingredient value must be greater than 0.';
  return 'The ingredient value must be in a correct format.';
};

export const formatValueIngredient = value_as_text => {
  if (value_as_text.includes('/')) {
    const splits = value_as_text.split('/');
    return `${Number(splits[0])}/${Number(splits[1])}`;
  }
  return String(Number(value_as_text.replace(/[,.]/g, '.'))).replace(/[,.]/g, value_as_text.includes('.') ? '.' : ',');
};

export const conditionalRoundNutrition = number => {
  if (number >= 1) return round(number);
  return round(number, 1);
};

export const hasNutrientData = (macroNutrients = [], otherNutrients = []) => {
  const hasMacroData = macroNutrients.some(item => get(item, 'value', 0) > 0);
  const hasOtherData = otherNutrients.length > 0;
  return hasMacroData || hasOtherData;
};

export const aggregateNutrients = (data, fieldName) => {
  const totals = {};

  data.forEach(item => {
    (item[fieldName] || []).forEach(nutrient => {
      if (!totals[nutrient.type]) {
        totals[nutrient.type] = 0;
      }
      totals[nutrient.type] += nutrient.value;
    });
  });

  return Object.keys(totals).map(type => ({
    type: type,
    value: totals[type],
  }));
};

export const getDataCategory = (categories, nameCategory) => {
  const result = categories.find(item => {
    return (item || {}).name === nameCategory;
  });

  return result;
};

export const getFirstItemUnit = (data, id) => {
  let result = null;
  forEach(data.imperials, group => {
    const found = find(group.units, { _id: id });
    if (found) {
      result = found;
      return false;
    }
  });
  if (!result) {
    forEach(data.metrics, group => {
      const found = find(group.units, { _id: id });
      if (found) {
        result = found;
        return false;
      }
    });
  }

  if (!result) {
    result = find(data.others, { _id: id });
  }

  return result;
};

export const getUniqueUnitsFromCategoryList = (categories, key) => {
  const allUnits = flatMap(categories, item => (item || {})[key] || []);
  return uniqBy(allUnits, 'unit_id');
};

export const getValueCaloriesFromMacro = macroNutrients => {
  const valueProtein = getNutrientValue(macroNutrients, MACRO_NUTRIENTS.PROTEIN) || 0;
  const valueCarbs = getNutrientValue(macroNutrients, MACRO_NUTRIENTS.CARBS) || 0;
  const valueFat = getNutrientValue(macroNutrients, MACRO_NUTRIENTS.FAT) || 0;
  const valueCalories = valueProtein * 4 + valueCarbs * 4 + valueFat * 9;
  return macroNutrients.map(item => {
    if (((item || {}).type || '') === MACRO_NUTRIENTS.CALORIES) {
      return { ...item, value: valueCalories };
    }
    return item;
  });
};

export const convertNutritionFromDetailRecipe = data => {
  const getNutritionData = key =>
    !isEmpty(get(data, `manual_calculated_nutrition`, {})) && get(data, 'is_auto_calculated', false)
      ? get(data, `manual_calculated_nutrition.${key}`, [])
      : get(data, key, []);

  return {
    ...data,
    macro_nutrients: getNutritionData('macro_nutrients'),
    other_nutrients: getNutritionData('other_nutrients'),
  };
};

export const convertStringToNumber = str => {
  const handleFraction = str => {
    const [numerator, denominator] = str.split('/').map(Number);
    return numerator / denominator;
  };

  const handleDecimalFormat = str => {
    return toNumber(replace(str, ',', '.'));
  };

  if (includes(str, '/')) {
    return handleFraction(str);
  }
  return handleDecimalFormat(str);
};

export const convertMacroNutrients = (macroNutrients = []) => {
  return macroNutrients.map(({ type, value }) => ({
    type,
    value: value >= 0 ? value : undefined,
  }));
};
