// Libs
import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

import { Switch, Route, Redirect } from 'react-router-dom';
import ClientOverview from 'components/ClientOverview';
import ListCalendar from 'components/ListCalendar';
import TaskListCalendar from 'components/TaskListCalendar';
import ClientMetric from 'components/ClientMetric';
import ClientSettings from 'components/ClientSettings';
import FoodJournal from 'components/FoodJournal';
import FoodJournalDetail from 'components/FoodJournalDetail';
import Macros from 'components/Macros';
import ClientStudioProgram from 'components/ClientStudioProgram';
import ClientTaskDetail from 'components/TaskCalendarCell/ClientTaskDetail';
import ClientHabitDetail from 'components/TaskCalendarCell/ClientHabitDetail';
import FormDocuments from 'components/FormDocuments';
import ClientMealPlan from 'components/ClientMealPlan';

// Shared
import UpgradePath from 'shared/UpgradePath';
import MealPlanFallBack from 'shared/UpgradePath/components/MealPlanFallBack';

// Styles
import './styles.scss';

export default class Dashboard extends React.Component {
  state = {};

  componentDidMount() {
    const { match } = this.props;
    const clientId = match.params.clientId;
    this.props.changeClient(clientId);
  }

  componentWillUnmount() {
    this.props.resetCalendarStartDate();
  }

  componentDidUpdate(prevProps) {
    const { match, location, changeClient, getClientMetrics } = this.props;
    if (get(match, 'params.clientId') !== get(prevProps, 'match.params.clientId')) {
      changeClient && changeClient(get(match, 'params.clientId'));
    }

    if (
      get(location, 'pathname', '').includes('metrics') &&
      get(location, 'pathname') !== get(prevProps, 'location.pathname')
    ) {
      getClientMetrics && getClientMetrics(get(match, 'params.clientId'));
    }
  }

  render() {
    const { workingClientDetail, bodymetricTypes, permission } = this.props;

    if (!workingClientDetail) {
      return (
        <div className="loading-client-data">
          <h4>Getting client data...</h4>
        </div>
      );
    }

    if (!bodymetricTypes || !bodymetricTypes.length) {
      return null;
    }

    return (
      <Switch>
        <Route
          exact
          path="/home/client/:clientId?"
          render={props => (
            <>
              <Helmet>
                <title>
                  {workingClientDetail.first_name} {workingClientDetail.last_name} - Overview - Everfit
                </title>
              </Helmet>
              <ClientOverview {...props} />
            </>
          )}
        />
        <Route exact path="/home/client/:clientId?/calendar" render={props => <ListCalendar {...props} />} />
        <Route exact path="/home/client/:clientId?/task" render={props => <TaskListCalendar {...props} />} />
        <Route
          exact
          path="/home/client/:clientId?/task/:taskId"
          render={props => (
            <TaskListCalendar {...props}>
              <ClientTaskDetail {...props} key={props.match.params.taskId} />
            </TaskListCalendar>
          )}
        />
        <Route
          exact
          path="/home/client/:clientId?/habit/:habitId"
          render={props => (
            <TaskListCalendar {...props}>
              <ClientHabitDetail {...props} key={props.match.params.habitId} />
            </TaskListCalendar>
          )}
        />
        <Route
          exact
          path="/home/client/:clientId?/calendar/:assignmentId?/detail"
          render={props => <ListCalendar {...props} detailRoute={true} />}
        />
        <Route
          exact
          path="/home/client/:clientId?/calendar/:assignmentId?/history"
          render={props => <ListCalendar {...props} historyRoute={true} />}
        />
        <Route
          exact
          path="/home/client/:clientId?/calendar/:assignmentId?/tracking"
          render={props => <ListCalendar {...props} trackingRoute={true} />}
        />
        <Route
          exact
          path="/home/client/:clientId?/calendar/:activityId?/activity"
          render={props => <ListCalendar {...props} activityRoute={true} />}
        />

        <Route exact path="/home/client/:clientId?/metrics" render={props => <ClientMetric {...props} />} />

        <Route
          exact
          path="/home/client/:clientId?/documents"
          render={props => (
            <>
              <Helmet>
                <title>
                  {workingClientDetail.first_name} {workingClientDetail.last_name} - Documents - Everfit
                </title>
              </Helmet>
              <FormDocuments {...props} />
            </>
          )}
        />

        <Route
          exact
          path="/home/client/:clientId?/settings"
          render={props => (
            <>
              <Helmet>
                <title>
                  {workingClientDetail.first_name} {workingClientDetail.last_name} - Settings - Everfit
                </title>
              </Helmet>
              <ClientSettings {...props} />
            </>
          )}
        />
        <Route
          exact
          path="/home/client/:clientId?/food-journal"
          render={props => (
            <>
              <Helmet>
                <title>
                  {workingClientDetail.first_name} {workingClientDetail.last_name} - Food Journal - Everfit
                </title>
              </Helmet>
              <UpgradePath pathName="food_journal">
                <FoodJournal {...props} />
              </UpgradePath>
            </>
          )}
        />
        <Route
          path="/home/client/:clientId?/food-journal/detail/:pId"
          render={props => (
            <>
              <Helmet>
                <title>
                  {workingClientDetail.first_name} {workingClientDetail.last_name} - Food Journal - Everfit
                </title>
              </Helmet>
              <UpgradePath pathName="food_journal">
                <FoodJournal {...props}>
                  <FoodJournalDetail {...props} />
                </FoodJournal>
              </UpgradePath>
            </>
          )}
        />
        {process.env.REACT_APP_NUTRITION_ENABLED && (
          <Route
            exact
            path="/home/client/:clientId?/macros"
            render={props => (
              <>
                <Helmet>
                  <title>
                    {workingClientDetail.first_name} {workingClientDetail.last_name} - Macros - Everfit
                  </title>
                </Helmet>
                <UpgradePath pathName="macro">
                  <Macros {...props} />
                </UpgradePath>
              </>
            )}
          />
        )}

        <Route
          exact
          path="/home/client/:clientId?/meal-plan"
          render={props => (
            <>
              <Helmet>
                <title>
                  {workingClientDetail.first_name} {workingClientDetail.last_name} - Meal Plan - Everfit
                </title>
              </Helmet>
              <UpgradePath pathName="client_meal_plan" fallback={<MealPlanFallBack isClientTab {...props} />}>
                <ClientMealPlan {...props} />
              </UpgradePath>
            </>
          )}
        />

        {permission.studio_program || permission.studio_resource_collection ? (
          <>
            <Route
              exact
              path="/home/client/:clientId?/studio"
              render={({ match }) => {
                const { clientId } = match.params;
                return <Redirect to={`/home/client/${clientId}/on-demand`} />;
              }}
            />
            <Route
              exact
              path="/home/client/:clientId?/on-demand"
              render={props => (
                <>
                  <Helmet>
                    <title>
                      {workingClientDetail.first_name} {workingClientDetail.last_name} - On-demand - Everfit
                    </title>
                  </Helmet>
                  <ClientStudioProgram {...props} />
                </>
              )}
            />
          </>
        ) : null}
      </Switch>
    );
  }
}
