import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .no-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    p {
      text-align: center;
      margin-top: 10px;
      font-size: 13px;
      line-height: 130%;
      max-width: 139px;
      color: #a6a7ba;
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  padding: 20px;
  background: url(${CDN_URL}/images/workout_builder_arrangement_title_icon.svg);
  padding-left: 50px;
  padding-bottom: 0px;
  background-repeat: no-repeat;
  background-position: 23px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;

export const SectionList = styled.div`
  padding: 3px 20px 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  position: relative;
`;

export const TopSection = styled.div`
  min-height: 20px;
  border-bottom: 1px solid transparent;
  margin: 0px -2px;
  margin-bottom: 2px;
  z-index: 4;
  position: sticky;
  top: -3px;
  background-color: #f6f7fb;

  ${props =>
    props.isDragMode &&
    css`
      :hover {
        cursor: grabbing;
        border-bottom: 1px solid #5c5bbd;
      }
    `}
`;

export const BottomSection = styled.div`
  flex: 1 1;
  min-height: 20px;
  margin: 0px 2px;
  margin-top: -3px;
  z-index: 4;

  ${props =>
    props.isDragMode &&
    css`
      :hover {
        cursor: grabbing;
        border-top: 1px solid #5c5bbd;
      }
    `}
`;

export const DraggingIcon = styled.div`
  width: 12px;
  height: 10px;
  background: url(${CDN_URL}/images/drag_icon_purple.svg);
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ScrollBottomArea = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 3;

  ${props =>
    props.top &&
    css`
      bottom: unset;
      top: 15px;
    `}
`;
