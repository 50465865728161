import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import axios from 'axios';

import { axiosInstance } from 'configs/request';
import { revokeObjectURL, mediaLog } from 'utils/commonFunction';

export const Types = {};

const CancelToken = axios.CancelToken;
const multipleCancelRequest = {};

export const uploadAttachmentsInBackground = ({ uploadConfigs, attachments, changeProgress, id }) => async () => {
  multipleCancelRequest[id] = [];
  try {
    const promises = map(uploadConfigs, (config, idx) => uploadAttachment({ config, idx, changeProgress, id }));
    const responses = await Promise.all(promises);
    const hasEmptyItem = some(responses, isEmpty);
    if (isEmpty(responses) || hasEmptyItem) return;
    forEach(responses, mediaLogStatusSuccess);
    return responses;
  } catch (error) {
    cancelAllUploadRequest({ id });
  } finally {
    forEach(attachments, it => revokeObjectURL(get(it, 'attachment')));
  }
};

export const cancelUploadRequest = ({ id }) => () => cancelAllUploadRequest({ id });

const uploadAttachment = async ({ config, idx, changeProgress, id }) => {
  try {
    const response = await axiosInstance({
      ...config,
      onUploadProgress: progressData => {
        const { loaded, total } = progressData;
        const newProgress = Math.floor((loaded / total) * 100);
        typeof changeProgress === 'function' && changeProgress(it => ({ ...it, [idx]: newProgress }));
      },
      cancelToken: new CancelToken(function executor(cancelRequest) {
        multipleCancelRequest[id].push(cancelRequest);
      }),
    });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      error.message = 'Canceled';
    } else {
      error.message = 'Failed';
    }
    cancelAllUploadRequest({ id });
  }
};

const cancelAllUploadRequest = ({ id }) => {
  if (!isEmpty(get(multipleCancelRequest, id))) {
    forEach(multipleCancelRequest[id], cancelRequest => typeof cancelRequest === 'function' && cancelRequest());
  }
};

const mediaLogStatusSuccess = config => {
  const fileName = get(config, 'config.data.name');
  const fileSize = get(config, 'config.data.size');
  const fileType = get(config, 'config.data.type');
  mediaLog({
    status: 2,
    name: fileName,
    fileSize: fileSize,
    fileType: fileType,
    description: 'Upload success file via Inbox',
  });
};
