import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

const baseText = `
    font-family: Open Sans;
    font-style: normal;
`;

export const DateTimeWrapper = styled.div`
  .purchase-date-time {
    display: flex;
    min-width: 160px;
    padding: 0 12px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #d6dae4;
    background: #ffffff;
    height: 36px;
    color: #262626;
    &:hover {
      background: #f5f5f5;
    }
    &.error-input {
      border: 1px solid #ea314a !important;
    }
    &.placeholder-input {
      color: #747474 !important;
    }
  }

  .rdt.new-ui {
    .rdtPicker {
      bottom: calc(100% + 8px) !important;
      width: 339px;
      padding: 26px 16px 16px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
      margin-top: 4px;
      table {
        border-collapse: collapse;
      }
      thead tr th.dow {
        ${baseText}
        padding: 15px 10px;
        color: #7b7e91;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
      }
      .rdtDays,
      .rdtMonths,
      .rdtYears {
        .rdtSwitch {
          ${baseText}
          color: #262626;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          position: relative;
          height: unset;
          top: -6px;
          right: 0;
          :hover {
            color: #5158cf;
          }
        }
        .rdtPrev {
          width: 40px;
          span {
            display: none;
          }
          &::after {
            content: url(${CDN_URL}/images/schedule-calendar-change-month-purple.svg);
            position: absolute;
            top: 25px;
            left: 14px;
          }
        }
        .rdtNext {
          width: 40px;
          span {
            display: none;
          }
          &::after {
            content: url(${CDN_URL}/images/schedule-calendar-change-month-purple.svg);
            display: inline-block;
            transform: rotate(180deg);
            position: absolute;
            top: 25px;
            right: 14px;
          }
        }
        .rdtDay {
          ${baseText}
          width: 44px;
          height: 45px;
          color: #262626;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 100%;
          text-transform: uppercase;
          &.rdtActive {
            ::before {
              width: 31px;
              height: 32px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #5158cf;
              border: 1px solid #5158cf;
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
          :hover:not(.rdtActive) {
            ::before {
              width: 31px;
              height: 32px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #ffffff;
              border: 1px solid #5158cf;
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
          &.rdtDisabled {
            ${baseText}
            color: #BFBFBF;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 100%;
            text-transform: uppercase;
          }
          &.rdtToday.rdtActive {
            color: #fff !important;
            font-weight: 600 !important;
          }
          &.rdtActive {
            color: #fff !important;
          }
        }
        .rdtDay:not(.rdtDisabled):not(.rdtActive):hover {
          color: #262626 !important;
          font-weight: 600 !important;
        }
      }
      .rdtToday {
        color: #262626 !important;
      }
      .rdtDisabled {
        color: #bfbfbf !important;
      }
      .custom-rdtToday {
        color: #5158cf !important;
      }
      .rdtActive {
        color: #ffffff !important;
      }
    }
  }
`;
