import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled(Button)`
  border-color: transparent;
  background-color: #36b34b;
  color: #fff;
  padding: 5px 10px;
  background-image: url(${CDN_URL}/images/play_icon_white.svg);
  background-position: 20px;
  background-repeat: no-repeat;
  background-size: 12px;
  padding-left: 37px;
  padding-right: 20px;

  ${props =>
    props.isPublished
      ? css`
          background-image: url(${CDN_URL}/images/studio_program_unpublish_icon.svg);
          background-color: #f7c000;

          :not(:disabled) {
            :hover {
              background-color: #ffcc18;
            }
          }
        `
      : css`
          :not(:disabled) {
            :hover {
              background-color: #38c350;
            }
          }
        `}
  ${props =>
    props.isSyncing &&
    props.isPublished &&
    css`
      background-color: #c4c4c4;
      cursor: not-allowed;
      :not(:disabled) {
        :hover {
          background-color: #c4c4c4;
        }
      }
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: #c4c4c4;
      cursor: not-allowed;
    `}
`;

export default function PublishButton(props) {
  return <Wrapper {...props}>{props.children}</Wrapper>;
}
