import styled from 'styled-components';

export const ForumHeader = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 2px 0px #2a2a3426;
`;

export const HeaderBody = styled.div``;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 20px;

  .invite-btn {
    padding: 7px 30px;
  }
`;

export const ForumInfoContainer = styled.div`
  margin: 0;
  & > * {
    margin: 0 0;
  }
  color: #202353;
  .forum-name {
    max-width: 811px;
    font-size: 24px;
    font-weight: bold;
    word-break: break-word;
    margin-right: 10px;
  }

  .members-text {
    display: flex;
    margin-top: 10.83px;
    align-items: center;

    & > p {
      margin: 0 7px;
      font-size: 14px;
      font-weight: 600;
      opacity: 70%;
    }
  }
`;

export const ForumTabs = styled.div``;
