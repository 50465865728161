import React from 'react';

export const LIST_OPTIONS = [
  {
    key: 'Your Tasks',
    text: 'Your Tasks',
    value: 0,
    deactivated: false,
    list_label: 'yourList',
  },
  {
    key: 'Following',
    text: 'Following',
    value: 1,
    deactivated: false,
    list_label: 'followingList',
  },
  {
    key: 'Your Completed Tasks',
    text: 'Your Completed Tasks',
    value: 2,
    deactivated: false,
    list_label: 'completedList',
  },
];

export const TEAMMATES_LIST = [
  {
    is_success: false,
    _id: '60aa5ed9e0dee1ebe7b2c9df',
    email: 'meo-2@everfit.io',
    first_name: 'Meowi',
    last_name: 'Nguyen',
    role: 1,
    teamId: '60aa5e9ce0dee1ebe7b2be4d',
    inviter: '60aa5e90e0dee1ebe7b2be44',
    createdAt: '2021-05-23T13:55:37.385Z',
    updatedAt: '2021-05-23T13:55:47.544Z',
    __v: 0,
  },
  {
    is_success: false,
    _id: '60aa5f37e0dee1ebe7b2c9e1',
    email: 'meo-3@everfit.io',
    first_name: 'Meowere',
    last_name: 'Nguyen',
    role: 2,
    teamId: '60aa5e9ce0dee1ebe7b2be4d',
    inviter: '60aa5e90e0dee1ebe7b2be44',
    createdAt: '2021-05-23T13:57:11.970Z',
    updatedAt: '2021-05-23T13:57:11.970Z',
    __v: 0,
  },
];

export const COLOR_OPTIONS = [
  {
    color: 'Red',
    colorId: '#EA3838',
    key: 1,
  },
  {
    color: 'Orange',
    colorId: '#FF8900',
    key: 2,
  },
  {
    color: 'Yellow',
    colorId: '#FBB503',
    key: 3,
  },
  {
    color: 'Green',
    colorId: '#0FBC3F',
    key: 4,
  },
  {
    color: 'Blue',
    colorId: '#258DEE',
    key: 5,
  },
  {
    color: 'Purple',
    colorId: '#5559FF',
    key: 6,
  },
];
