import styled from 'styled-components';
import * as Layout from 'shared/ModalLayout';
import { FormGroup as FormGroupLayout } from 'shared/FormControl';

export const Message = styled.p`
  margin: 0;
  max-width: 325px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;

export const FormGroup = styled(FormGroupLayout)`
  margin-bottom: 25px;
`;

export const HeaderIcon = styled(Layout.HeaderIcon)``;

export const Header = styled(Layout.Header)``;

export const Content = styled(Layout.Content)`
  ${FormGroup} {
    :last-child {
      margin-bottom: 0;
    }
  }

  .title {
    margin: 0 0 20px;
    line-height: inherit;

    .hightlight {
      font-weight: 600;
    }
  }
`;

export const Actions = styled(Layout.Actions)`
  button {
    &.button--remove-team-member {
      :disabled {
        background-color: #C4C4C4;
        opacity: 1;
      }
    }
  }
`;

export const Wrapper = styled(Layout.Wrapper)``;
