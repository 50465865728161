import _ from 'lodash';
import { CALENDAR_TYPES } from 'constants/commonData';

export const updateWeekView = (totalWeek, currentStartWeek, currentViewMode) => {
  let newStartWeek = currentStartWeek;
  let newViewMode = currentViewMode;

  if (totalWeek <= 1) {
    newViewMode = 1;
  } else {
    if (currentViewMode > 1 && totalWeek < currentViewMode) {
      const findIndex = _.findIndex(CALENDAR_TYPES, item => item.value === currentViewMode);
      newViewMode = findIndex > 0 ? CALENDAR_TYPES[findIndex - 1].value : 1;
    }
  }

  if (newStartWeek >= totalWeek - newViewMode) {
    newStartWeek = totalWeek - newViewMode;
  }

  return { startWeek: newStartWeek, viewMode: newViewMode };
};

export const moveLocalWorkouts = (workouts, data) => {
  const { workoutId, newIndex, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex } = data;
  const oldDayId = `${oldWeekIndex}_${oldDayIndex}`;
  const newDayId = `${newWeekIndex}_${newDayIndex}`;

  if (!workouts[oldDayId] || !workouts[oldDayId].length) {
    return false;
  }

  const oldWorkoutIndex = _.findIndex(workouts[oldDayId] || [], w => w._id === workoutId);
  const workoutData = workouts[oldDayId][oldWorkoutIndex];

  if (!workoutData) {
    return false;
  }

  if (!workouts[newDayId]) {
    workouts[newDayId] = [workoutData];
  } else {
    workouts[newDayId].splice(newIndex, 0, workoutData);
  }

  workouts[oldDayId].splice(oldWorkoutIndex, 1);

  return workouts;
};

export const arrangeLocalWorkouts = (workouts, data) => {
  const { workoutId, newIndex, weekIndex, dayIndex } = data;
  const dayId = `${weekIndex}_${dayIndex}`;

  if (!workouts[dayId] || !workouts[dayId].length) {
    return false;
  }

  const oldWorkoutIndex = _.findIndex(workouts[dayId], w => w._id === workoutId);
  const workoutData = workouts[dayId][oldWorkoutIndex];

  if (!workoutData) {
    return false;
  }

  workouts[dayId].splice(oldWorkoutIndex, 1);
  workouts[dayId].splice(newIndex, 0, workoutData);

  return workouts;
};

export const handleMoveExercise = (workouts, data) => {
  const { workoutId, newIndex, oldIndex, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex, newWorkoutId } = data;

  const fromDayId = `${oldWeekIndex}_${oldDayIndex}`;
  const toDayId = `${newWeekIndex}_${newDayIndex}`;

  const fromWorkoutIndex = workouts[fromDayId] && workouts[fromDayId].findIndex(w => w._id === workoutId);
  const fromWorkout = fromWorkoutIndex && workouts[fromDayId][fromWorkoutIndex];
  const movingExercise = fromWorkout && _.get(fromWorkout, `sections[${oldIndex}]`, null);

  // remove old exercise
  if (fromWorkoutIndex) {
    workouts[fromDayId][fromWorkoutIndex].sections.splice(oldIndex, 1);
    if (!workouts[fromDayId][fromWorkoutIndex].sections.length) {
      workouts[fromDayId].splice(fromWorkoutIndex, 1);
    }
  }

  const toWorkoutIndex = (workouts[toDayId] || []).findIndex(w => w._id === newWorkoutId);

  // update new exercise
  if (toWorkoutIndex !== -1 && movingExercise) {
    workouts[toDayId][toWorkoutIndex].sections.splice(newIndex, 0, movingExercise);
  }

  return workouts;
};
