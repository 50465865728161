import _ from 'lodash';
import Request, { axiosInstance } from 'configs/request';
import { TRIAL_MODE, PLANS } from 'constants/commonData';
import { fromJS } from 'immutable';
import { toast } from 'react-toastify';
import {
  getPromoValue,
  getOneTimeCost,
  getPrevSubTotal,
  getSubTotal,
  calculateRemainCost,
  getAddOnsCoupon,
} from 'components/PricingPlan/helper';
import { ADD_ONS_PRICING_CODE } from 'components/PricingPlan/constants';

export const Types = {
  INIT_PRICING_DATA: 'INIT_PRICING_DATA',
  INIT_PRICING_DATA_FAILED: 'INIT_PRICING_DATA_FAILED',
  INIT_PRICING_DATA_SUCCESS: 'INIT_PRICING_DATA_SUCCESS',
  GET_REFERRAL_PROMOTION_CODE_SUCCESS: 'GET_REFERRAL_PROMOTION_CODE_SUCCESS',
  GET_ZAPIER_KEY_SUCCESS: 'GET_ZAPIER_KEY_SUCCESS',
  RESET_PRICING_FLAG: 'RESET_PRICING_FLAG',
  RESET_ZAPIER_QUANTITY: 'RESET_ZAPIER_QUANTITY',
  UPDATE_PRICING: 'UPDATE_PRICING',
  UPDATE_SELECT_PACKAGE: 'UPDATE_SELECT_PACKAGE',
  UPDATE_ZAPIER_PRICING: 'UPDATE_ZAPIER_PRICING',
  MARK_SHOUlD_SELECT_PLAN: 'MARK_SHOUlD_SELECT_PLAN',
  UPDATING_PROMO: 'UPDATING_PROMO',
  UPDATING_PROMO_SUCCESS: 'UPDATING_PROMO_SUCCESS',
  GET_ZAPIER_USAGE_HISTORY_REQUEST: 'GET_ZAPIER_USAGE_HISTORY_REQUEST',
  GET_ZAPIER_USAGE_HISTORY_SUCCESS: 'GET_ZAPIER_USAGE_HISTORY_SUCCESS',
  GET_ZAPIER_USAGE_HISTORY_FAILED: 'GET_ZAPIER_USAGE_HISTORY_FAILED',
  GET_ZAPIER_CURRENT_USAGE_SUCCESS: 'GET_ZAPIER_CURRENT_USAGE_SUCCESS',
  RESET_ZAPIER_SECRET_KEY_SUCCESS: 'RESET_ZAPIER_SECRET_KEY_SUCCESS',
  INIT_PRICING_DATA_REQUEST: 'INIT_PRICING_DATA_REQUEST',
};

function getPackagePrice(data, selectedTier) {
  let { packageNoClients, last_number_of_clients, is_default_trial, last_billing_tier } = data.teamData;
  if (data.teamData.tier === PLANS.starter.key) {
    packageNoClients = last_number_of_clients;
  }
  const planPackage = _.chain(data.planPricing)
    .groupBy(o => o.code)
    .mapValues(o => o[0])
    .value();
  const pricings = planPackage[selectedTier].pricings;
  const pricingsCanChoose = _.chain(pricings)
    .filter(o => o.packageNoClients > data.teamData.noClients)
    .orderBy(['packageNoClients'], ['asc'])
    .value();
  const currentPackage = _.find(pricings, pricing => pricing.packageNoClients === packageNoClients);
  const lowestPackage = _.get(pricingsCanChoose, 0, _.last(pricings));

  if (!currentPackage) {
    return lowestPackage;
  }

  if (data.teamData.tier === PLANS.starter.key) {
    return lowestPackage;
  }

  if (
    currentPackage.packageNoClients > lowestPackage.packageNoClients &&
    (data.teamData.trialMode === TRIAL_MODE.Expired || data.teamData.trialMode === TRIAL_MODE.Grace)
  ) {
    return currentPackage;
  }

  if (!last_billing_tier && !is_default_trial) {
    return currentPackage;
  }

  return lowestPackage;
}

function transfromPricingData(data, isUpdate) {
  const transformedData = { ...data };
  let selectedTier = data.teamData.tier;
  let zapierQuantity = data.teamData.zapier_quantity;
  let { addOns } = data.teamData;
  if (data.teamData.tier === PLANS.starter.key) {
    selectedTier = PLANS.pro.key;
    addOns = {
      automation: false,
      payment_package: false,
      resource_collections: false,
      zapier: false,
    };
  }

  const planPackage = _.chain(data.planPricing)
    .groupBy(o => o.code)
    .mapValues(o => o[0])
    .value();
  const packagePrice = getPackagePrice(data, selectedTier);

  const selectPackage = {
    tier: selectedTier,
    addOns,
    period: data.teamData.billingCycle === 1 ? 'monthly' : 'annually',
    packagePrice,
    promoCode: null,
    zapier_quantity: zapierQuantity,
  };

  transformedData.planPackage = planPackage;
  transformedData.teamData = {
    ...data.teamData,
    period: data.teamData.billingCycle === 1 ? 'monthly' : 'annually',
  };

  if (!isUpdate) {
    transformedData.selectPackage = selectPackage;
    transformedData.shouldSelectPlan = data.teamData.shouldSelectPlan;
  }
  return transformedData;
}

const addBetaDateForMealPlan = (data = []) => {
  const result = data.map(item => {
    const { code = '', beta_date = '' } = item || {};
    if (code === ADD_ONS_PRICING_CODE.MEAL_PLAN) {
      return { ...item, beta_date: beta_date || null };
    }
    return item;
  });
  return result;
};

export const initPricingData = (isUpdate, coupon) => {
  return dispatch => {
    dispatch({ type: Types.INIT_PRICING_DATA_REQUEST });
    return dispatch(
      Request.get(
        { url: '/api/pricing/get-pricing-data' },
        false,
        (response, { dispatch, getState }) => {
          dispatch({ type: Types.UPDATING_PROMO });
          const state = getState();
          const pricing = state.rootReducer.pricing.toJS();
          const { data } = response.data;
          if (data) {
            let updateData = data;
            if (data.teamData.trialMode === TRIAL_MODE.Active) {
              updateData = {
                ...data,
                teamData: {
                  ...data.teamData,
                  addOns: {
                    automation: false,
                    resource_collections: false,
                    payment_package: false,
                    premiumCustomBrand: false,
                    zapier: false,
                  },
                },
              };
            }
            updateData = transfromPricingData(updateData, isUpdate);
            if (pricing.isInited) {
              updateData.shouldSelectPlan = pricing.shouldSelectPlan;
              if (updateData.teamData.promoCode) {
                dispatch(checkPromoCode({ code: updateData.teamData.promoCode }, true));
              }
            }
            const pricingData = {
              ...updateData,
              addOnsPricing: addBetaDateForMealPlan(updateData.addOnsPricing || []),
            };
            dispatch({ type: Types.INIT_PRICING_DATA_SUCCESS, payload: { data: pricingData } });
            if (coupon) {
              dispatch(checkPromoCode({ code: coupon }, true));
            }
            if (data.teamData.trialMode === TRIAL_MODE.Expired && !coupon) {
              dispatch(updateSelectPackage({}));
            }
            dispatch({ type: Types.UPDATING_PROMO_SUCCESS });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.INIT_PRICING_DATA_FAILED });
        },
      ),
    );
  };
};

export const resetPricingData = () => {
  return (dispatch, getState) => {
    const state = getState();
    const pricing = state.rootReducer.pricing.toJS();
    dispatch({
      type: Types.INIT_PRICING_DATA_SUCCESS,
      payload: { data: { ...transfromPricingData(pricing), canDowngradeToStarter: false } },
    });
    dispatch(resetPricingFlag());
    // dispatch(resetZapierQuantity());
  };
};

export const resetPricingFlag = () => {
  return { type: Types.RESET_PRICING_FLAG };
};

// export const resetZapierQuantity = () => {
//   return { type: Types.RESET_ZAPIER_QUANTITY };
// };

export const markShouldSelectPlan = () => {
  return dispatch => {
    dispatch({ type: Types.MARK_SHOUlD_SELECT_PLAN });
    return dispatch(Request.post({ url: '/api/pricing/mark-visited-select-plan' }, false));
  };
};

export const updateSelectPackage = (data, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data } });
    callback && callback();
    const state = getState();
    const teamData = state.rootReducer.pricing.get('teamData').toJS();
    const selectPackage = state.rootReducer.pricing
      .mergeIn(['selectPackage'], fromJS(data))
      .get('selectPackage')
      .toJS();
    if (
      (teamData.trialMode === TRIAL_MODE.Expired && selectPackage.period !== 'annually') ||
      selectPackage.tier === PLANS.starter.key
    ) {
      return dispatch(calculateProrationPlanCost(data));
    }
  };
};

export const updateZapierPrice = data => {
  return (dispatch, getState) => {
    dispatch({ type: Types.UPDATE_ZAPIER_PRICING, payload: { data } });
  };
};

export const removePromoCode = data => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data: { promoCode: null } } });
  };
};

export const getProratedCredit = (data = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const pricingData = state.rootReducer.pricing.mergeIn(['selectPackage'], fromJS(data));
    const selectPackage = pricingData.get('selectPackage').toJS();
    const teamData = pricingData.get('teamData').toJS();
    const params = {
      addOns: _.get(selectPackage, 'addOns', {}),
      tier: _.get(selectPackage, 'tier'),
      billing_cycle: _.get(selectPackage, 'period', 'monthly') === 'monthly' ? 1 : 2,
      number_of_clients: _.get(selectPackage, 'packagePrice.packageNoClients', 0),
      promoCode: _.get(teamData, 'promoCode') ? null : undefined,
      zapier_quantity: _.get(selectPackage, 'zapier_quantity', 0),
    };

    if (!params.tier) return;
    if (!params.billing_cycle) return;
    if (!params.number_of_clients || (params.number_of_clients && params.number_of_clients <= 0)) return;
    if (params.addOns.zapier && params.zapier_quantity && params.zapier_quantity <= 0) return;

    if (!_.isEmpty(params.addOns)) {
      for (const key in params.addOns) {
        if (!params.addOns[key]) {
          delete params.addOns[key];
        }
      }
    }

    return axiosInstance
      .post('/api/pricing/get-proration-plan-cost', params)
      .then(response => {
        if (response.data) {
          dispatch({
            type: Types.UPDATE_SELECT_PACKAGE,
            payload: {
              data: {
                prorationPlanCost: response.data.data.cost,
                will_schedule: response.data.data.will_schedule,
                will_zapier_schedule: response.data.data.will_zapier_schedule,
                remainingCost: response.data.data.remainingCost,
                unusedCost: response.data.data.unusedCost,
              },
            },
          });
        }
      })
      .catch(() => {
        dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data: { prorationPlanCost: null } } });
      });
  };
};

export const getProratedZapierMonthly = (data = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const pricingData = state.rootReducer.pricing.mergeIn(['selectPackage'], fromJS(data));
    const selectPackage = pricingData.get('selectPackage').toJS();
    const params = {
      zapier_quantity: _.get(selectPackage, 'zapier_quantity', 0),
    };
    return axiosInstance
      .post('/api/pricing/zapier-up-tier-monthly-cost', params)
      .then(response => {
        const responseData = _.get(response, 'data.data');
        if (responseData) {
          const { cost, coupon, remaining, discount } = responseData;
          dispatch({
            type: Types.UPDATE_SELECT_PACKAGE,
            payload: {
              data: {
                prorationPlanCost: cost,
                coupon,
                remainingCost: remaining,
                discount,
              },
            },
          });
        }
      })
      .catch(() => {
        dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data: { prorationPlanCost: null } } });
      });
  };
};

export const getProratedCreditPrev = (data = {}) => {
  return (dispatch, getState) => {
    const params = {
      addOns: {},
      tier: PLANS.starter.key,
      billing_cycle: 1,
      number_of_clients: 5,
    };

    if (!params.tier) return;
    if (!params.billing_cycle) return;
    if (!params.number_of_clients || (params.number_of_clients && params.number_of_clients <= 0)) return;
    if (params.addOns.zapier && params.zapier_quantity && params.zapier_quantity <= 0) return;

    if (!_.isEmpty(params.addOns)) {
      for (const key in params.addOns) {
        if (!params.addOns[key]) {
          delete params.addOns[key];
        }
      }
    }

    return axiosInstance
      .post('/api/pricing/get-proration-plan-cost', params)
      .then(response => {
        if (response.data) {
          dispatch({
            type: Types.UPDATE_SELECT_PACKAGE,
            payload: {
              data: {
                proratedCostPrev: response.data.data.unusedCost || 0,
              },
            },
          });
        }
      })
      .catch(() => {
        dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data: { proratedCostPrev: 0 } } });
      });
  };
};

export const calculateProrationPlanCost = (data = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectPackage = state.rootReducer.pricing
      .mergeIn(['selectPackage'], fromJS(data))
      .get('selectPackage')
      .toJS();
    const addOnsPricing = state.rootReducer.pricing.get('addOnsPricing').toJS();
    const planPricing = state.rootReducer.pricing.get('planPricing').toJS();
    const teamData = state.rootReducer.pricing.get('teamData').toJS();

    const prevSubtotal = getPrevSubTotal(teamData, addOnsPricing, planPricing);
    const subTotal = getSubTotal(selectPackage, addOnsPricing, teamData);
    const oneTimeCost = getOneTimeCost(selectPackage, addOnsPricing, teamData);

    const selectedPlan = planPricing.find(item => item.code === teamData.tier);
    const packagePrice = selectedPlan.pricings.find(item => item.packageNoClients === teamData.number_of_clients);
    const prevSelectPackage = {
      addOns: teamData.addOns,
      packagePrice,
      period: teamData.billing_cycle === 1 ? 'monthly' : 'annually',
      tier: teamData.tier,
    };
    let prevPromoAmount = 0;
    let promoAmount = 0;

    if (teamData.mode === 3) {
      prevPromoAmount = getPromoValue(selectPackage.promoCode, prevSubtotal, prevSelectPackage, addOnsPricing);
      promoAmount = getPromoValue(selectPackage.promoCode, prevSubtotal, selectPackage, addOnsPricing);
    }

    const unusedCost = calculateRemainCost(prevSubtotal - prevPromoAmount, teamData);
    const remainCost = calculateRemainCost(subTotal - oneTimeCost - promoAmount, teamData);
    const prorationPlanCost = _.round(remainCost - unusedCost + oneTimeCost, 2) * 100;

    dispatch({
      type: Types.UPDATE_SELECT_PACKAGE,
      payload: {
        data: {
          prorationPlanCost: _.round(prorationPlanCost),
        },
      },
    });
  };
};

export const checkPromoCode = (promoData, skipValidate) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.UPDATING_PROMO });
    const state = getState();
    const selectPackage = state.rootReducer.pricing.get('selectPackage').toJS();
    const teamData = state.rootReducer.pricing.get('teamData').toJS();
    const addOnsPricing = state.rootReducer.pricing.get('addOnsPricing').toJS();
    const addOns = getAddOnsCoupon(selectPackage, addOnsPricing);

    const params = {
      addOns,
      tier: _.get(selectPackage, 'tier'),
      billing_cycle: _.get(selectPackage, 'period', 'monthly') === 'monthly' ? 1 : 2,
      number_of_clients: _.get(selectPackage, 'packagePrice.packageNoClients', 0),
      zapier_quantity: _.get(selectPackage, 'zapier_quantity', 0),
      ...promoData,
    };

    if (!params.tier) return;
    if (!params.billing_cycle) return;
    if (!params.number_of_clients || (params.number_of_clients && params.number_of_clients <= 0)) return;
    if (params.addOns.zapier && params.zapier_quantity && params.zapier_quantity <= 0) return;

    if (!_.isEmpty(params.addOns)) {
      for (const key in params.addOns) {
        if (!params.addOns[key]) {
          delete params.addOns[key];
        }
      }
    }

    return dispatch(
      Request.post(
        { url: '/api/coupon/get-coupon-detail', data: params },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          if (data) {
            let promoCode = data.valid ? data : { valid: false };
            // for downgrade automation add ons
            const isDowngradeAddOns =
              teamData.trialMode !== TRIAL_MODE.Active &&
              _.get(addOns, 'automation', false) * 1 < _.get(teamData, 'addOns.automation', false) * 1;

            const isDowngradePaymentPackageAddOns =
              teamData.trialMode !== TRIAL_MODE.Active &&
              _.get(addOns, 'payment_package', false) * 1 < _.get(teamData, 'addOns.payment_package', false) * 1;

            const isDowngradeResourceAddOns =
              teamData.trialMode !== TRIAL_MODE.Active &&
              _.get(addOns, 'resource_collections', false) * 1 <
                _.get(teamData, 'addOns.resource_collections', false) * 1;

            const isNoChangeNoClient =
              _.get(selectPackage, 'packagePrice.packageNoClients', 0) === _.get(teamData, 'packageNoClients', 0);

            const promoCodeAddOns = _.get(data, 'metadata.add_ons');

            if (skipValidate) {
              return dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data: { promoCode } } });
            }

            if (
              (teamData.tier === selectPackage.tier &&
                teamData.period === selectPackage.period &&
                isNoChangeNoClient &&
                (isDowngradeAddOns || isDowngradePaymentPackageAddOns || isDowngradeResourceAddOns) &&
                teamData.promoCode !== promoData.code) ||
              (promoCodeAddOns && !addOns[promoCodeAddOns])
            ) {
              promoCode = { valid: false };
            }
            dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data: { promoCode } } });
            dispatch({ type: Types.UPDATING_PROMO_SUCCESS });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.UPDATE_SELECT_PACKAGE, payload: { data: { promoCode: { valid: false } } } });
        },
      ),
    );
  };
};

export const getReferralPromotionCode = () => {
  return Request.get({ url: '/api/pricing/get-referral-promotion-code' }, false, (response, { dispatch, getState }) => {
    const { data } = response.data;
    if (data && !!data.referral_auto_apply) {
      dispatch({ type: Types.GET_REFERRAL_PROMOTION_CODE_SUCCESS, payload: { data: data.promotion_code } });
    }
  });
};

export const confirmPayment = data => {
  return (dispatch, getState) => {
    const state = getState();
    const isDowngradeQuestionnaire =
      data &&
      (data.hasOwnProperty('better_alternative_reason') ||
        data.hasOwnProperty('lacking_feature_reason') ||
        data.hasOwnProperty('other_reason') ||
        data.hasOwnProperty('downgrade_reasons'));

    const dataZapier = _.get(data, 'has_zapier');
    const selectPackage = state.rootReducer.pricing
      .mergeIn(['selectPackage'], fromJS(isDowngradeQuestionnaire ? {} : data))
      .get('selectPackage')
      .toJS();

    let params = {
      addOns: _.get(selectPackage, 'addOns', {}),
      tier: _.get(selectPackage, 'tier'),
      billing_cycle: _.get(selectPackage, 'period', 'monthly') === 'monthly' ? 1 : 2,
      number_of_clients: _.get(selectPackage, 'packagePrice.packageNoClients', 0),
      promoCode: _.get(selectPackage, 'promoCode.id'),
      zapier_quantity: _.get(selectPackage, 'zapier_quantity', 0),
    };

    if (isDowngradeQuestionnaire) {
      const {
        better_alternative_reason = '',
        lacking_feature_reason = '',
        other_reason = '',
        downgrade_reasons = [],
      } = data;
      params = {
        ...params,
        billing_cycle: 1,
        downgrade_reasons,
        better_alternative_reason,
        other_reason,
        lacking_feature_reason,
      };
    }

    if (dataZapier) {
      params = { zapier_quantity: _.get(selectPackage, 'zapier_quantity', 0) };
    }

    const processParams = axiosInstance.post(
      dataZapier ? '/api/pricing/zapier-up-tier-monthly-process-payment' : '/api/pricing/process-payment',
      params,
    );

    return processParams;
  };
};

export const getZapierKey = () => {
  return Request.get({ url: '/api/zapier/api-secret' }, false, (response, { dispatch, getState }) => {
    const { data } = response.data;
    if (data) {
      dispatch({ type: Types.GET_ZAPIER_KEY_SUCCESS, payload: { data: data } });
    }
  });
};

export const resetZapierKey = () => {
  return dispatch => {
    return dispatch(
      Request.post({ url: '/api/zapier/api-secret/reset' }, false, (response, { dispatch }) => {
        const { data } = response.data;
        dispatch({ type: Types.RESET_ZAPIER_SECRET_KEY_SUCCESS, payload: { data } });
        toast('API Secret is reset.');
      }),
    );
  };
};

export const getZapierUsageHistory = params => {
  return (dispatch, getState) => {
    const state = getState();
    const zapierUsageHistory = state.rootReducer.pricing.get('zapierUsageHistory').toJS();
    const zapierHistorical = _.get(zapierUsageHistory, 'list', []);

    dispatch({
      type: Types.GET_ZAPIER_USAGE_HISTORY_REQUEST,
    });

    return dispatch(
      Request.get(
        { url: '/api/zapier/history', params },
        false,
        (response, { dispatch, getState }) => {
          const data = response.data;
          if (data) {
            const total = _.get(data, 'total', 0);
            const lastPage = total > 0 ? Math.ceil(total / _.get(params, 'per_page', 0)) : 1;
            const result = _.get(data, 'data', []);
            const list = _.get(params, 'page', 0) === 1 || lastPage === 1 ? result : [...zapierHistorical, ...result];

            dispatch({
              type: Types.GET_ZAPIER_USAGE_HISTORY_SUCCESS,
              payload: { list: list, total: total, total_request: _.get(data, 'total_request', 0) },
            });
          }
        },
        () => {
          dispatch({
            type: Types.GET_ZAPIER_USAGE_HISTORY_FAILED,
          });
        },
      ),
    );
  };
};

export const getCurrentUsage = () => {
  return Request.get({ url: '/api/zapier/current-usage' }, false, (response, { dispatch }) => {
    const { data } = response.data;
    if (data) {
      dispatch({ type: Types.GET_ZAPIER_CURRENT_USAGE_SUCCESS, payload: { data: data } });
    }
  });
};
