import { round } from 'lodash';

export const calculateMacros = ({
  activityLevel,
  age = 0,
  gender,
  goal,
  height = 0,
  weight = 0,
  heightUnit,
  weightUnit,
}) => {
  let proteinGram,
    carbsGram,
    fatGram = 0;
  const weightLb = weightUnit === 'lb' ? weight : round(weight * 2.204623, 2);
  let BMR = round(
    10 * (weightUnit === 'kg' ? weight : weight / 2.204623) +
      6.25 * (heightUnit === 'cm' ? height : height * 2.54) -
      5 * age,
    2,
  );
  if (gender === '0') BMR += 5;
  if (gender === '1') BMR -= 161;

  let activityFactor;
  switch (activityLevel) {
    case 'lightly-active':
      activityFactor = 1.375;
      break;
    case 'moderately-active':
      activityFactor = 1.55;
      break;
    case 'very-active':
      activityFactor = 1.725;
      break;
    case 'extremely-active':
      activityFactor = 1.9;
      break;
    default:
      activityFactor = 1.2;
  }

  const TDEE = BMR * activityFactor;

  let goalMultiplier;
  switch (goal) {
    case 'lose-weight':
      goalMultiplier = 0.8;
      break;
    case 'maintain-weight':
      goalMultiplier = 1;
      break;
    case 'gain-weight':
      goalMultiplier = 1.2;
      break;
    default:
      goalMultiplier = 1;
  }

  const totalCalo = round(TDEE * goalMultiplier, 1);

  switch (goal) {
    case 'lose-weight':
      proteinGram = round((weightLb * activityFactor) / 1.55, 1);
      if (gender === '1') proteinGram = round(proteinGram * 0.9, 1);
      fatGram = round((totalCalo * 0.25) / 9, 1);
      carbsGram = round((totalCalo - proteinGram * 4 - fatGram * 9) / 4, 1);
      break;
    case 'maintain-weight':
      proteinGram = round((weightLb * activityFactor) / 1.55, 1);
      if (gender === '1') proteinGram = round(proteinGram * 0.9, 1);
      carbsGram = round((weightLb * 1.7 * (200 - age)) / 140, 1);
      if (gender === '1') carbsGram = round(carbsGram * 0.9, 1);
      fatGram = round((totalCalo - proteinGram * 4 - carbsGram * 4) / 9, 1);
      break;
    case 'gain-weight':
      proteinGram = round(((weightLb * activityFactor) / 1.55) * 1.2, 1);
      if (gender === '1') proteinGram = round(proteinGram * 0.9, 1);
      carbsGram = round((weightLb * 2 * (200 - age)) / 140, 1);
      if (gender === '1') carbsGram = round(carbsGram * 0.9, 1);
      fatGram = round((totalCalo - proteinGram * 4 - carbsGram * 4) / 9, 1);
      break;
  }

  return {
    totalCalo,
    protein: proteinGram,
    carbs: carbsGram,
    fat: fatGram,
  };
};
