import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { mongoObjectId } from 'utils/commonFunction';

export const DISPLAY_LIST = 'list';

export const DISPLAY_CART = 'cart';

export const NUTRIENT_TYPE = {
  CALORIES: 'calories',
  FAT: 'fat',
  CARBS: 'carbs',
  PROTEIN: 'protein',
};

export const getMacroNutrients = macroNutrients => {
  const getValue = type => {
    const item = macroNutrients.find(item => get(item, 'type') === type);
    return item ? get(item, 'value') : undefined;
  };

  return [
    {
      id: mongoObjectId(),
      unit: 'cal',
      value: getValue(NUTRIENT_TYPE.CALORIES),
      type: NUTRIENT_TYPE.CALORIES,
    },
    {
      id: mongoObjectId(),
      label: 'P',
      unit: 'g',
      value: getValue(NUTRIENT_TYPE.PROTEIN),
      type: NUTRIENT_TYPE.PROTEIN,
    },
    {
      id: mongoObjectId(),
      label: 'C',
      unit: 'g',
      value: getValue(NUTRIENT_TYPE.CARBS),
      type: NUTRIENT_TYPE.CARBS,
    },
    {
      id: mongoObjectId(),
      label: 'F',
      unit: 'g',
      value: getValue(NUTRIENT_TYPE.FAT),
      type: NUTRIENT_TYPE.FAT,
    },
  ];
};

export const sumMacroNutrients = recipes => {
  if (!isArray(recipes) || recipes.length === 0) {
    return [];
  }

  const totals = recipes.reduce(
    (acc, recipe) => {
      get(recipe, 'recipe.macro_nutrients', []).forEach(nutrient => {
        switch (nutrient.type) {
          case NUTRIENT_TYPE.CALORIES:
            if (nutrient.value) {
              acc.calories += nutrient.value;
            }
            break;
          case NUTRIENT_TYPE.FAT:
            if (nutrient.value) {
              acc.fat += nutrient.value;
            }
            break;
          case NUTRIENT_TYPE.CARBS:
            if (nutrient.value) {
              acc.carbs += nutrient.value;
            }
            break;
          case NUTRIENT_TYPE.PROTEIN:
            if (nutrient.value) {
              acc.protein += nutrient.value;
            }
            break;
          default:
            break;
        }
      });
      return acc;
    },
    {
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  );

  const totalMacroNutrients = Object.entries(totals).map(([type, value]) => ({
    type,
    value,
  }));

  return totalMacroNutrients;
};

export const calculateMacroNutrients = listMealGroup => {
  const listMealGroupMarcoNutrients = listMealGroup
    .map(item => sumMacroNutrients(get(item, 'recipes', [])))
    .reduce((accumulator, currentArray) => {
      currentArray.forEach(nutrient => {
        const { type, value } = nutrient;
        if (accumulator[type]) {
          accumulator[type] += value;
        } else {
          accumulator[type] = value;
        }
      });
      return accumulator;
    }, []);

  const macroNutrients = Object.entries(listMealGroupMarcoNutrients).map(([type, value]) => ({
    type,
    value,
  }));

  return macroNutrients;
};

export const reorderDragDrop = (list, startIndex, endIndex) => {
  if (!isArray(list)) {
    return;
  }

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const DEFAULT_ACTIVE_INDEX = -1;

export const MEAL_NAMES = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];

export const SORT_BY = {
  A_TO_Z: 'name_lowercase',
  MOST_RECENT: 'updated_at',
};

export const MAX_NOTE_CHARACTER_LIMIT = 10000;
