import styled, { css } from 'styled-components';
import AddButton from 'shared/AddButton';
import * as Layout from 'shared/LibraryLayout';
import { CDN_URL } from 'constants/commonData';

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 55px;
  width: 55px;
  min-height: 40px;

  .checkbox__thumbnail {
    width: 40px;
    height: 40px;
    display: none;
  }

  &.checked,
  :hover {
    .thumbnail-container {
      display: none;
    }

    .checkbox__thumbnail {
      display: flex;
      justify-content: center;

      .evf-checkbox {
        width: 100%;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
      }

      .checkbox-icon {
        width: 16px;
        height: 16px;
        left: calc(50%);
        background-size: calc(16px / 2);
      }

      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    }
  }

  .checkbox__thumbnail .checkbox-icon,
  .checkbox-icon {
    width: 15px;
    height: 15px;
    left: calc(50% - calc(13px / 2));
    transform: translateY(-50%) translateX(-50%);
    border: 1px solid #b6b6b6;
    background-image: url(${CDN_URL}/images/checked_white.svg);
    border-radius: 2px;
  }

  .checkbox__thumbnail {
    .checkbox-icon {
      left: calc(50%);
    }
  }

  .evf-checkbox {
    padding-left: 0;
  }

  ${props =>
    props.isHasSelectedPrev &&
    css`
      .checkbox-icon {
        width: 16px;
        height: 16px;
        left: calc(50% - calc(12px / 2));
        background-size: calc(16px / 2);
        background-image: none;
      }

      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    `}
`;

export const ExerciseNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #202353;
    overflow: hidden;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    max-width: 250px;
    @media screen and (min-width: 1441px) {
      max-width: initial;
    }
  }
`;

export const Thumbnail = styled.div`
  background: url(${props => props.thumbnail || `${CDN_URL}/images/exercise_grey.svg`}) no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-blend-mode: multiply;
  position: relative;

  ${props =>
    !props.thumbnail &&
    css`
      background-size: auto;
    `}
`;

export const ThumbnailContainer = styled.div`
  overflow: hidden;
  border-radius: 3px;
  background-color: #eaebef;
  position: relative;
  width: 40px;
  height: 40px;

  ${props =>
    props.isVideo &&
    css`
      ::before {
        content: '';
        position: absolute;
        left: 4px;
        bottom: 4px;
        width: 15px;
        height: 15px;
        background: url(${CDN_URL}/images/video_play.svg) no-repeat left bottom;
        background-size: contain;
        z-index: 1;
      }
    `};
`;

export const FilterTrigger = styled.div`
  padding: 5px 25px;
  padding-left: 50px;
  background: url(${CDN_URL}/images/new_filter.svg) no-repeat 25px 6px;
  background-size: auto;
  background-color: #ffffff;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  cursor: pointer;

  :hover {
    background-color: #f8f8f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  }
`;

export const ResetFilter = styled.div`
  display: inline-block;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  background: transparent url(${CDN_URL}/images/reset_new.svg) no-repeat 0 center;
  background-size: auto;

  :hover {
    cursor: pointer;
    background-image: url(${CDN_URL}/images/reset_new_purple.svg);
    color: #5158cf;
  }

  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const ToolbarLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: 5px;
  }

  .exercise-filter {
    margin-right: 5px;

    &.open {
      ${FilterTrigger} {
        background-color: #f8f8f8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      }
    }

    .exercise-filter__count {
      right: -10px;
      top: -11px;
      border-radius: 6px;
      width: 22px;
      height: 21px;
      line-height: 17px;
      border: 3px solid #ffffff;
    }
  }
`;

export const TagsButton = styled(AddButton)`
  float: left !important;
  padding: 6px 20px;
  border-color: #d4d7d9 !important;
  border-radius: 5px !important;

  .shared-add-button-wrapper {
    margin: 0;
  }

  .shared-add-button-title {
    font-size: 12px;
    line-height: 12px;
    color: #777;
    font-weight: 600 !important;
    letter-spacing: 0;
    padding-left: 10px;
  }

  ${props =>
    props.open &&
    css`
      background-color: #f8f8f8 !important;
    `}

  :hover {
    background-color: #f8f8f8 !important;
    cursor: pointer;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  /* width: 150px; */
  @media screen and (min-width: 1441px) {
    width: initial;
  }

  span {
    color: #222;
    font-size: 13px;
    font-weight: 400;
  }

  .tag-name {
    cursor: pointer;
    margin-right: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5c58c0;

    :hover {
      text-decoration: underline;
    }

    ::after {
      content: ',';
    }

    :last-child::after {
      content: '';
    }
  }
`;

export const WrapperActions = styled.div`
  display: flex;
`;

export const TableWrapper = styled(Layout.Wrapper)`
  z-index: 1;
  padding: 0 20px 0 30px;

  ${Layout.TableContainer} {
    z-index: 1;
    overflow-x: auto;

    ::-webkit-scrollbar {
      width: 3px !important;
      height: 3px !important;
    }

    ::-webkit-scrollbar-track {
      margin-left: 270px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }

    :hover {
      ::-webkit-scrollbar-thumb {
        background-color: #cdcdcd !important;
      }
    }
  }

  ${Layout.TableHeader} {
    z-index: 1001;
  }

  ${Layout.TableHeaderCell} {
    &:nth-child(1) {
      min-width: 270px;
    }

    &:nth-child(2) {
      min-width: 180px;
    }

    &:nth-child(3) {
      min-width: 105px;
    }

    &:nth-child(4) {
      min-width: 125px;
    }

    &:nth-child(5) {
      min-width: 155px;
    }

    &:nth-child(7) {
      min-width: 115px;
    }
  }

  ${Layout.TableRow} {
    .header-fixed {
      z-index: 1001;
    }

    .body-fixed {
      z-index: 1000;
    }

    .header-fixed,
    .body-fixed {
      position: sticky;
      left: 0;
      background-color: #ffffff;
    }

    .__react_component_tooltip.app-tooltip {
      z-index: 1002;
    }

    &:hover {
      .body-fixed {
        background-color: #f8f8f8;
      }
    }
  }

  ${Layout.TableHeaderCellContent} {
    ${CheckBoxContainer} {
      min-height: unset;
      justify-content: center;
    }
  }
`;
