// Libs
import styled from 'styled-components';

export const GoToIconWrapper = styled.div`
  display: inline-flex;

  &.disable {
    cursor: not-allowed;

    .open-in-new-tab-icon {
      pointer-events: none;
      opacity: 0.6;

      &:hover {
        .normal {
          display: block;
        }

        .active {
          display: none;
        }
      }
    }

    .go-to-icon-tooltip.__react_component_tooltip {
      white-space: pre-line;
      word-break: break-word;
      width: 228px;
      text-align: center;

      ::after {
        left: unset;
        right: 12px;
      }
    }
  }

  .open-in-new-tab-icon {
    display: inline-flex;

    .normal {
      display: block;
    }

    .active {
      display: none;
    }

    &:hover {
      .normal {
        display: none;
      }

      .active {
        display: block;
      }
    }
  }

  .go-to-icon-tooltip.__react_component_tooltip {
    width: fit-content;
    padding: 15px 15px !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    background: #2d2e2d;
    text-align: left;
    border-radius: 5px;
    white-space: nowrap;

    &.place-top {
      margin-top: -8px;

      &:after {
        border-top-color: #2d2e2d;
        bottom: -8px !important;
        border-top-width: 8px;
      }
    }
  }
`;
