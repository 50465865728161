import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const Images = styled.img`
  width: 23px;
  height: 23px;
  cursor: pointer;

  &.color-background {
    padding: 5px;
    box-sizing: border-box;
    border-radius: 50%;
  }
`;

export const Tooltip = styled(ReactTooltip)`
  font-size: 13px !important;
  line-height: 19.5px !important;
  font-weight: 400;
  padding: 15px 15px 19px 15px !important;
  border-radius: 5px !important;

  ${props =>
    props.tooltipBackground &&
    css`
      background: ${props.tooltipBackground} !important;

      &:after {
        border-top-color: ${props.tooltipBackground} !important;
      }
    `};

  ${props =>
    props.tooltipTextColor &&
    css`
      color: ${props.tooltipTextColor} !important;
    `};

  ${props =>
    props.maxWidthContent &&
    css`
      max-width: ${props.maxWidthContent}px !important;
    `};
`;
