import styled from 'styled-components';

export const Container  = styled.div`
  background: linear-gradient(180deg, rgba(233, 233, 233, 0) 0%, rgba(1, 1, 1, 0.6) 100%);
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  color: #ffffff;
  z-index: 2;

  img {
    margin-right: 3px;
    height: unset;
    object-fit: unset;
  }

  .duration {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;