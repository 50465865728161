import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'semantic-ui-react';
import _ from 'lodash';

import { CDN_URL, DEFAULT_CLIENT_FILTERS } from 'constants/commonData';
import { Checkbox, Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { QUESTIONNAIRE } from './constants';
import { ModalWrapper, ModalTitle, ModalSubTitle, ListWrapper, Item, Desc, CommentArea, CommentWrapper } from './style';

const DowngradeQuestionnaireCommponent = ({
  user,
  push,
  initPermission,
  initPricingData,
  confirmPayment,
  selectSegment,
  updatePaymentStatus,
}) => {
  const [questions, setQuestions] = useState(_.cloneDeep(QUESTIONNAIRE));
  const [isDisable, setDisable] = useState(true);
  const [isRequiredOther, setRequiredOther] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputEl = useRef(null);

  useEffect(() => {
    checkDisable();
  }, [questions]);

  const handleCheckboxOnChange = item => () => {
    const cloneQuestions = _.cloneDeep(questions);
    cloneQuestions[item].checked = !cloneQuestions[item].checked;
    setQuestions(cloneQuestions);
    setRequiredOther(false);
  };

  const handleCommentChange = item => event => {
    const cloneQuestions = _.cloneDeep(questions);
    cloneQuestions[item].comment = event.target.value;
    setQuestions(cloneQuestions);
  };

  const checkDisable = () => {
    const checked = _.filter(questions, it => it.checked === true).length > 0 ? false : true;
    setDisable(checked);
  };

  const handleDowngrade = () => {
    if (!isOtherEmpty()) {
      let params = {
        downgrade_reasons: [],
        other_reason: '',
        lacking_feature_reason: '',
        better_alternative_reason: '',
      };

      questions.forEach(it => {
        if (it.checked) {
          params.downgrade_reasons.push(it.hs_id);
          switch (it.id) {
            case 0:
              params.lacking_feature_reason = it.comment;
              break;
            case 7:
              params.better_alternative_reason = it.comment;
              break;
            case 9:
              params.other_reason = it.comment;
              break;
            default:
              break;
          }
        }
      });
      setIsLoading(true);
      confirmPayment(params).then(() => {
        setIsLoading(false);
        updatePaymentStatus(null);
        goBackBilling();
        selectSegment(null, Object.assign({}, DEFAULT_CLIENT_FILTERS, { ownerShip: user._id }));
      });
    } else {
      setRequiredOther(true);
    }
  };

  const isOtherEmpty = () => {
    const inValid = questions[9].checked && questions[9].comment.trim() === '';
    if (inValid && inputEl.current) {
      inputEl.current.focus();
      inputEl.current.value = '';
    }
    return inValid;
  };

  const goBackBilling = () => {
    push('/home/billing');
    setTimeout(() => {
      initPermission();
    }, 1000);
    initPricingData();
  };

  return (
    <ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={goBackBilling}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalLayout.Wrapper>
          <ModalTitle>What is the reason for downgrading Everfit?</ModalTitle>
          <ModalSubTitle>Select all that apply</ModalSubTitle>
          <ModalLayout.Content className="wrapper-content">
            <ListWrapper>
              {questions &&
                questions.map(item => (
                  <>
                    <Item className={item.checked && 'checked'}>
                      <Checkbox
                        checked={item.checked}
                        className="select-client-checkbox"
                        onChange={handleCheckboxOnChange(item.id)}
                      />
                      <Desc>{item.title}</Desc>
                    </Item>
                    {item.showComment && (
                      <CommentWrapper>
                        <CommentArea
                          placeholder={item.placeholder}
                          onChange={handleCommentChange(item.id)}
                          required={item.isRequired && isRequiredOther}
                          ref={item.id === 9 ? inputEl : null}
                        />
                      </CommentWrapper>
                    )}
                  </>
                ))}
            </ListWrapper>
          </ModalLayout.Content>
          <ModalLayout.Actions className="wrapper-footer-question">
            <Button purple grey className="btn-back" onClick={goBackBilling}>
              Return to Billing
            </Button>
            <Button
              purple
              primary
              className={`btn-confirm ${(isDisable || isLoading) && 'disable'}`}
              disabled={isDisable || isLoading}
              onClick={handleDowngrade}
            >
              Downgrade
            </Button>
          </ModalLayout.Actions>
        </ModalLayout.Wrapper>
      </Modal.Content>
    </ModalWrapper>
  );
};

export default DowngradeQuestionnaireCommponent;
