import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import MediaPreview from 'shared/MediaPreview';
import ExerciseForm from 'components/ExerciseForm';
import { MODES } from 'components/ExerciseForm/component';
import { axiosInstance } from 'configs/request';
import { isS3FileURL } from 'utils/validations';
import { replaceImageURL } from 'utils/commonFunction';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import { CDN_URL } from 'constants/commonData';

const ExerciseMediaPreview = props => {
  const { exercise, size, indicatorSize, trigger, defaultThumbnail, type, onEditExercise, showError } = props;
  const [exerciseData, setExerciseData] = useState(null);
  const { preview_300, video, videoLink, picture } = exercise;
  const url = (picture || [])[0];
  const isVideo = !!video || !!videoLink;

  const thumbnail = useMemo(() => {
    if (preview_300) return preview_300;
    if (isS3FileURL(url)) return replaceImageURL(url);
    return url;
  }, [preview_300, url]);

  const onEdit = () => {
    if (!exercise || !exercise._id) {
      return;
    }

    axiosInstance
      .get(`/api/exercise/detail/${exercise._id}`)
      .then(response => {
        const { data } = response.data;
        setExerciseData(data);
        typeof onEditExercise === 'function' && onEditExercise();
      })
      .catch(error => {
        const responseData = _.get(error, 'response.data');
        showError && showError(responseData.message);
      });
  };

  const onClose = () => {
    setExerciseData(null);

    if (typeof props.onCloseExercisePopup === 'function') {
      props.onCloseExercisePopup();
    }
  };

  const onSave = data => {
    if (data.close) {
      setExerciseData(null);
    }

    if (typeof props.onSaveExercise === 'function') {
      props.onSaveExercise(data);
    }
  };

  const isNotExist = _.get(exercise, 'is_existing') === false;

  return (
    <>
      <MediaPreview
        isVideo={isVideo}
        thumbnail={isVideo ? thumbnail || MEDIA_PLACEHOLDER : thumbnail}
        src={video || videoLink || (picture || [])[0]}
        defaultThumbnail={defaultThumbnail || `${CDN_URL}/images/exercise_grey.svg`}
        size={size}
        indicatorSize={indicatorSize}
        trigger={trigger}
        type={type}
        disabled={isNotExist}
      >
        <ActionContainer>
          <span onClick={onEdit}>Edit Exercise</span>
        </ActionContainer>
      </MediaPreview>
      {exerciseData ? (
        <ExerciseForm
          open={true}
          originExercise={exerciseData}
          mode={MODES.UPDATE}
          closePopup={onClose}
          onSubmitSuccess={onSave}
        />
      ) : null}
    </>
  );
};

export default ExerciseMediaPreview;

const ActionContainer = styled.div`
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #5c5bbd;

  span {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
