import styled, { css } from 'styled-components';
import { TagsMenuListTitle } from 'shared/TagsFilter/styles';
import { FormGroup as FG, ErrorMessage } from 'shared/FormControl';
import { Modal as ModalElement } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export const FieldGuideContent = styled.div`
  width: 240px;
  text-transform: none;
`;

export const QuestionPlaceHolder = styled.div`
  width: 14px;
  height: 14px;
  background: transparent url(${CDN_URL}/images/question.svg) no-repeat center;
  background-size: contain;
  margin-left: 5px;
`;

export const TrackingFieldsLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FormGroup = styled(FG)`
  margin-bottom: 20px;

  ${TrackingFieldsLabel} {
    display: flex;
  }

  label {
    color: #777c8f;
  }

  input,
  textarea {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dadfea;
    outline: none;
    box-shadow: none;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;

    ::placeholder {
      color: #969fb5;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    }

    :focus {
      border-color: #5158cf;
    }
  }

  input {
    padding: 8px 15px;
  }

  &.error {
    .single-select__control {
      background: rgba(234, 49, 74, 0.05);
      border-color: #ea314a;
    }
  }

  &.modality {
    margin-bottom: 7px;
    .single-select-container {
      min-width: 369px;
      .single-select__control {
        &--is-focused {
          border-color: #dadfea !important;
        }
        &--menu-is-open {
          border-color: #5158cf !important;
        }
      }
    }

    label {
      margin-bottom: 7px;
    }

    .single-select__dropdown-indicator {
      padding-right: 17px;
    }
  }

  &.add-link {
    margin-bottom: 24px;
    height: 30px;
  }

  &.add-link.expanded {
    margin-bottom: 70px;
  }

  &.default-note {
    margin-bottom: 16px;
    .text-editable__wrapper {
      background: #f6f7f9;
      border-radius: 5px;
      border: 1px solid transparent;
      min-width: 369px;

      .text-editable__container {
        &:not(.text-editable__container--active) {
          .text-editable__input {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .text-editable__input {
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;

          :empty::before {
            position: relative;
          }
        }

        &--active {
          min-height: 36px;
          max-height: 220px;
          overflow: auto;
        }

        :hover {
          border: 1px solid #5158cf;
        }
      }
    }
  }

  &.alternate-exercises {
    margin-bottom: 0px;
  }

  &.tracking-category {
    .select-field-dropdown {
      position: relative !important;
    }

    .select-field-dropdown__menu {
      position: absolute !important;
      top: 35px !important;
      left: unset !important;
      bottom: unset !important;
      right: 0px !important;
    }
  }
`;

export const InstructionGroup = styled(FormGroup)`
  label {
    text-transform: none;
  }
`;

export const TagsArea = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
    color: #6a778a;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
  }

  .tags-select__control {
    border-color: #dadfea;
    cursor: pointer;
    min-height: 36px !important;

    .tags-select__placeholder {
      font-size: 13px !important;
      font-weight: 400 !important;
      line-height: 18px !important;
      color: #969fb5 !important;
      opacity: 1 !important;
      margin-left: 3px;
    }
  }

  &:hover {
    .tags-select__control {
      border-color: #5158cf;
    }
  }
`;

export const SelectCategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .single-select-container {
    flex: 1 1;

    .single-select__menu {
      padding: 0;
      overflow: auto;

      .single-select__menu-list {
        padding: 0;
      }
    }

    .single-select__value-container {
      padding: 2px 4px;
    }
  }
`;

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #020202 url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: -6px;
  top: -6px;
  display: none;
`;

export const FieldItem = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  padding: 8px 10px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
  margin-right: 5px;

  .index {
    color: #979797;
  }

  :hover {
    ${RemoveIcon} {
      display: block;
    }
  }
`;

export const Fields = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TrackingFieldContainer = styled.div`
  background: #f6f7fb;
  border-radius: 5px;
  padding: 15px;

  ${FormGroup} {
    margin-top: 20px;
    margin-bottom: 0;

    label {
      margin-bottom: 10px;
      color: #6a778a;
    }
  }

  .select-field-dropdown__trigger {
    height: 32px;
    width: 68px;
  }

  .select-field-dropdown__menu {
    border: 1px solid #dadfea;
    border-radius: 4px;
    padding: 10px;

    .select-field-dropdown__item {
      border-radius: 5px;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000;
      padding: 10px 12px;

      :hover {
        background: #f4f4ff;
      }

      &.selected {
        opacity: 0.3;
      }
    }
  }
`;

export const ExerciseTitleContainer = styled(FG)`
  margin-bottom: 0;
  margin-right: 15px;
  flex: 1 1;
  overflow: hidden;

  ${ErrorMessage} {
    padding-left: 10px;
  }

  &.error {
    .text-editable__wrapper {
      background: rgba(234, 49, 74, 0.05);
    }
  }
`;

export const OwnerShipDisable = styled.div`
  width: 16px;
  height: 16px;
  background: transparent url(${CDN_URL}/images/teammate_grey.svg) no-repeat center;
  background-size: contain;
  margin-right: 25px;
  margin-top: 13px;
  opacity: 0.3;
`;

export const HeaderContainer = styled.div`
  padding: 25px 10px 24px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid transparent;

  .action-menu {
    margin-top: 8px;
    height: auto;

    .action-menu__toggle-button {
      margin-right: 5px;
    }
  }
`;

export const LeftSideContent = styled.div`
  flex: 1 1;
  overflow: auto;
  padding: 0 20px 30px 30px;

  > div:last-child {
    margin-bottom: 0;
  }

  .single-select__control {
    border: 1px solid #dadfea;
    box-sizing: border-box;
    border-radius: 4px;
    background: #fafbfc;
    min-height: 36px;
    padding: 0 6px;
    box-shadow: none !important;

    :hover,
    &.single-select__control--menu-is-open,
    &.single-select__control--is-focused {
      border-color: #5158cf !important;
      cursor: pointer;
    }

    .single-select__indicator-separator {
      display: none;
    }

    .single-select__input {
      input {
        height: auto;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #323c47;
      }
    }

    .single-select__placeholder,
    .single-select__single-value {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #323c47;
    }
  }

  .single-select__menu {
    margin: 0;
    padding: 0 5px;
    overflow: hidden;

    .single-select__menu-list {
      padding: 10px 3px 0 6px;
      max-height: 248px;

      .single-select__option {
        background: #fff;
        border-radius: 5px;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
        padding: 9px 10px;
        cursor: pointer;
        text-transform: capitalize;

        &.single-select__option--is-selected,
        &.single-select__option--is-focused {
          background: #f4f4ff !important;
        }
      }
    }
  }
`;

export const Instructions = styled.textarea`
  resize: none;
  height: 275px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  box-shadow: none;
  outline: none;
  padding: 15px;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;

  :focus {
    border-color: #5158cf;
  }
`;

export const AddLinkIcon = styled.div`
  width: 30px;
  height: 30px;
  background: #e4e5e7 url(${CDN_URL}/images/paper_clip.svg) no-repeat center center;
  background-size: 14px;
  margin-right: 10px;
  border-radius: 50%;
`;

export const LinkButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #6a778a;
  border: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  padding: 0;

  ${props =>
    props.adding &&
    css`
      margin-bottom: 10px;
    `}
`;

export const LeftSide = styled.div`
  background: #ffffff;
  width: 419px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const RightSide = styled.div`
  width: 368px;
  height: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1;
  overflow: auto;
  border-radius: 8px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 30px;
  width: 100%;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
  z-index: 2;

  button {
    margin-left: 5px;
    padding: 6px 25px;

    &.btn-save {
      width: 80px;
    }

    &.btn-save-and-close {
      width: 150px;
    }
  }
`;

export const Wrapper = styled.div`
  height: 700px;
  max-height: min(620px, calc(100vh - 100px));
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-height: 780px) {
    max-height: min(620px, calc(100vh - 150px));
  }

  @media screen and (min-height: 820px) {
    max-height: min(620px, calc(100vh - 200px));
  }
`;

export const Modal = styled(ModalElement)`
  &.modal--exercise-detail + div {
    z-index: 2001;
    cursor: pointer;
    text-align: initial;

    .tags-select__menu {
      margin-top: 1px;

      .tags-select__menu-list {
        padding: 15px 10px 10px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);

        div[mode='default'] {
          color: #000000;
          margin: 0;
          margin-bottom: 10px;
          padding: 0 5px;
          font-weight: 700;
          font-size: 10px;
          line-height: 100%;
          text-transform: uppercase;
        }

        div:not[mode='default'] {
          &:hover {
            background: #f4f4ff;
          }
        }
      }
    }

    ${TagsMenuListTitle} {
      text-align: left;
    }
  }

  .tags-select__control {
    padding-right: 5px;
    height: auto;
    min-width: 369px;

    .tags-select__placeholder {
      color: #000000;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      opacity: 0.4;
    }

    .tags-select__value-container {
      &.tags-select__value-container--is-multi {
        padding-top: 4px;
        padding-bottom: 4px;

        > div:not(.tags-select__placeholder) {
          input {
            line-height: 18px;
          }
        }
      }

      &.tags-select__value-container--has-value {
        padding: 8px;
        display: inline-flex;
        max-height: 67px;
        flex-wrap: wrap;
        gap: 5px 0;
        overflow: auto;
        overflow-y: auto;

        > div:not(.tags-select__multi-value) {
          margin: 0;
          padding: 0;
        }

        &::-webkit-scrollbar {
          width: 3px !important;
        }

        &::-webkit-scrollbar-track {
          margin-block: 7px;
        }
      }
    }

    .tags-select__clear-indicator {
      display: none;
    }

    .tags-select__multi-value {
      border-radius: 4px;
      margin: 0;
      margin-right: 5px;
    }

    .tags-select__multi-value__label {
      color: #444444;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      padding: 4px 0 4px 7px;
    }
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;

export const AlternateExercisesWrapperContent = styled.div`
  margin-bottom: 20px;
  cursor: pointer;

  .search-exercise-container .box {
    background-color: #ffffff;
    border: 1px solid #dadfea;
    min-height: 36px;
    min-width: 369px;

    input {
      cursor: pointer;
      font-weight: 400 !important;
    }

    input::placeholder {
      color: #969fb5;
    }

    :hover {
      border: 1px solid #5158cf;
    }
  }

  .info .title {
    font-family: 'Open Sans' !important;
    font-size: 13px !important;
    color: #444444 !important;
    padding: 0px !important;
  }
`;

export const AdvancedTitleWrapper = styled.div`
  display: flex;
  gap: 1px;
  align-items: center;
  > label {
    margin-bottom: 0px;
  }

  .tooltip--default-note {
    width: 225px !important;
  }

  .tooltip--alternate-exercises {
    width: 283px !important;
  }

  .tooltip--synonyms {
    width: 172px !important;
  }

  .__react_component_tooltip.place-top {
    margin-top: -13px;
    margin-left: 1px;
    font-size: 12px;
    line-height: 18px;
    text-align: start;
    font-weight: 400;
    padding: 8px 2px 8px 10px;

    ::after {
      bottom: -10px !important;
    }
  }
`;

export const InfoIconWrapper = styled.label`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
    path {
      fill: #c5c5ce;
    }
  }

  :hover {
    svg path {
      fill: #5158cf;
    }
  }
`;
