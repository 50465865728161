import { propTypes } from 'cleave.js/react';
import React from 'react';
import Select from 'react-select';
import { FormGroup } from 'shared/FormControl';
import { DropdownIndicator } from 'shared/Icons';

const ReactSelectFormik = ({
  options,
  field,
  form: { touched, errors, setFieldValue },
  label,
  className,
  ...props
}) => (
  <FormGroup className={className}>
    {label && <label>{label}</label>}
    {!label && <label dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
    <Select
      {...props}
      classNamePrefix="single-select"
      className={`single-select-container ${errors[field.name] && touched[field.name] ? 'error' : ''}`}
      components={{ DropdownIndicator }}
      options={options}
      name={field.name}
      value={options ? options.find(option => option.value === field.value) : ''}
      onChange={option => setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
    />
    {errors[field.name] && touched[field.name] && <div className="error error-msg">{errors[field.name]}</div>}
  </FormGroup>
);

export default ReactSelectFormik;
