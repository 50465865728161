import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Image, Icon } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import ReactTooltip from 'react-tooltip';

import { toggleModal } from 'actions/modal';
import ManageGroups from 'components/ManageGroups';
import CreateGroup from 'components/CreateGroup';
import GeneralButton from 'shared/GeneralButton';

import { CDN_URL } from 'constants/commonData';

import { Wrapper, MenuContainer, MenuFooter } from './style';
import { changeClientFilter } from 'redux/segment/actions';
import { ReactComponent as Plus } from 'assets/icons/new_plus_grey_sm.svg';
import { ReactComponent as Setting } from 'assets/icons/new_setting_grey.svg';

class GroupFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      textSearch: '',
      focus: false,
      openUpdate: false,
    };
    this.inputRef = React.createRef();
    this.hiddenText = React.createRef();
  }

  calculatorInputWidth = () => {
    const { textSearch } = this.state;
    let width = 6;
    _.forEach(textSearch, character => {
      width += character === ' ' ? 6 : character === character.toLowerCase() ? 7 : 10;
    });

    return width + 'px';
  };

  removeGroup = removeId => {
    const { filters, allGroup, changeClientFilter } = this.props;
    const { groups } = filters;
    let selectValues = [];
    _.forEach(groups, gId => {
      const obj = _.find(allGroup, g => g._id === gId);

      if (obj && gId !== removeId) {
        selectValues.push(gId);
      }
    });
    changeClientFilter('groups', selectValues);
  };

  renderValueItem = group => {
    return (
      <div className="multi-select__multi-value" key={group._id}>
        <div className=" multi-select__multi-value__label">{group.name}</div>
        <div
          className="multi-select__multi-value__remove"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.removeGroup(group._id);
          }}
        >
          <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-19bqh2r">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
          </svg>
        </div>
      </div>
    );
  };

  renderGroups = selectValues => {
    const { textSearch } = this.state;
    const { allGroup, changeClientFilter } = this.props;
    let selectedIds = _.map(selectValues, '_id');
    const search = textSearch.trim().toLowerCase();
    const renderedGroup = _.filter(
      allGroup,
      g => g.name.toLowerCase().includes(search) && !selectedIds.includes(g._id),
    );

    return (
      <ul>
        {_.map(renderedGroup, g => (
          <li
            key={g._id}
            onClick={() => {
              this.setState({ textSearch: '' });
              selectedIds.push(g._id);
              changeClientFilter('groups', selectedIds);
            }}
          >
            {g.name}
          </li>
        ))}
        {!renderedGroup.length && <li className="no-option">No results</li>}
      </ul>
    );
  };

  handleRootClose = () => {
    this.setState({ open: false, textSearch: '' });
  };

  handleOpenCreateGroup = () => {
    const { clients, toggleModal } = this.props;
    const clientIds = _.map(clients, item => item._id);
    toggleModal(
      true,
      <CreateGroup clients={clients.length} onSuccess={this.onCreateGroupSuccess} clientIds={clientIds} />,
    );
  };

  handleOpenManageGroup = () => {
    const { toggleModal } = this.props;
    toggleModal(true, <ManageGroups />);
  };

  render() {
    const { open, textSearch, focus } = this.state;
    const { filters, allGroup, toggleModal, clients, errorPopup, isModalOpen } = this.props;
    const { groups } = filters;
    let selectValues = [];

    _.forEach(groups, gId => {
      const obj = _.find(allGroup, g => g._id === gId);

      if (obj) {
        selectValues.push(obj);
      }
    });
    let title = '';

    _.forEach(selectValues, (item, index) => {
      if (index > 0) {
        title += ', ';
      }
      title += item.name;
    });

    return (
      <RootCloseWrapper onRootClose={this.handleRootClose} event="click" disabled={!open || errorPopup || isModalOpen}>
        <Wrapper>
          <div className={`filter__dropdown${open ? ' active' : ''}`} onClick={() => this.setState({ open: !open })}>
            <div className="filter__dropdown__title" data-tip data-for="group-filter-tooltip">
              <span>Group:&nbsp;</span>
              <span className="selected">{title || 'All'}</span>
              <Icon className="chevron down" />
            </div>
          </div>
          {open && (
            <MenuContainer>
              <div className="form-group">
                <label>Group</label>
                <div
                  className={`groups-value-container multi-select-container${focus ? ' input-focus' : ''}`}
                  onClick={() => this.inputRef.current && this.inputRef.current.focus()}
                >
                  {_.map(selectValues || [], this.renderValueItem)}
                  <div className="multi-select__input">
                    <input
                      ref={this.inputRef}
                      value={textSearch}
                      style={{ width: this.calculatorInputWidth(), maxWidth: '260px' }}
                      onFocus={() => this.setState({ focus: true })}
                      onBlur={() => this.setState({ focus: false })}
                      onChange={e => {
                        this.setState({ textSearch: e.target.value });
                      }}
                    />
                  </div>
                  {!selectValues.length && !textSearch ? <div className="placeholder">Enter group...</div> : null}
                </div>
              </div>
              <div className="form-group">
                <label>
                  <Image src={`${CDN_URL}/images/group_grey.svg`} />
                  Group
                </label>
                <div className="groups">{this.renderGroups(selectValues)}</div>
              </div>
              <MenuFooter>
                <div className="footer_left">
                  <GeneralButton onClick={this.handleOpenCreateGroup} className="create-button" withImage>
                    <Plus />
                    <span>Create</span>
                  </GeneralButton>
                  <GeneralButton onClick={this.handleOpenManageGroup} className="manage-button" withImage>
                    <Setting />
                    <span>Manage</span>
                  </GeneralButton>
                </div>
              </MenuFooter>
            </MenuContainer>
          )}
          {title && (
            <ReactTooltip
              className="app-tooltip"
              id={`group-filter-tooltip`}
              effect="solid"
              place={'top'}
              delayShow={1000}
            >
              <span>{title}</span>
            </ReactTooltip>
          )}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    filters: rootReducer.segment.filters,
    allGroup: rootReducer.group.list,
    errorPopup: state.errorPopup,
    isModalOpen: state.isModalOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  changeClientFilter: bindActionCreators(changeClientFilter, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupFilter);
