import React from 'react';
import * as S from './style';

const LoadingSkeleton = () => {
  const renderRowSkeleton = (_, idx) => (
    <S.TableRow key={idx}>
      <S.TableCell>
        <div className="avatar"></div>
      </S.TableCell>
      <S.TableCell>
        <div className="name"></div>
      </S.TableCell>
      <S.TableCell>
        <div className="category"></div>
      </S.TableCell>
      <S.TableCell>
        <div className="unit"></div>
      </S.TableCell>
      <S.TableCell>
        <div className="most-recent"></div>
      </S.TableCell>
    </S.TableRow>
  );
  return <S.TableBody>{Array(20).fill(null).map(renderRowSkeleton)} </S.TableBody>;
};

export default LoadingSkeleton;
