import React, { memo, useMemo } from 'react';
import 'react-quill/dist/quill.snow.css';

import { ReactComponent as UploadIcon } from 'assets/icons/upload_banner_icon.svg';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

import * as S from './styles';

const CustomBannerImage = memo(({ src }) => {
  return <S.CoverImage src={src}>{src ? null : <UploadIcon />}</S.CoverImage>;
});

export default function PackageViewMode(props) {
  const { packageData, cloudfrontList } = props;

  const imgUrl = useMemo(() => {
    if (packageData) {
      return convertS3UrlToCloudFrontUrl(packageData.cover_image, cloudfrontList, true);
    }
    return null;
  }, [packageData]);

  if (!packageData) {
    return null;
  }

  return (
    <S.Wrapper>
      <CustomBannerImage src={imgUrl} />
      <S.PackageInfo>
        <S.PackageName>{packageData.name}</S.PackageName>
        {packageData.headline && <S.PackageHeadline>{packageData.headline}</S.PackageHeadline>}
        <S.Description className="ql-snow">
          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: packageData.description }}></div>
        </S.Description>
      </S.PackageInfo>
    </S.Wrapper>
  );
}
