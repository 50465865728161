import styled, { css } from 'styled-components';

export const Input = styled.input`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  padding: 10px 30px 10px 15px;
  outline: none !important;
  background-color: transparent;
  border: 1px solid transparent;
  flex: 1 1;
  background: #F8F8F8;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  ::placeholder {
    color: rgba(119, 119, 119, .6);
  }
`;

export const Button = styled.button.attrs({
  type: 'button'
})`
  width: 60px;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ECECEC;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 36px;

  .clear-icon {
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;