import React from 'react';
import Filter from './ClientFilterPopup';
import { FILTER_STATUS } from '../constants';
import { FilterStyles, UpdatedFilterIcon } from './style';
import isEqual from 'lodash/isEqual';

import { ReactComponent as FilterIcon } from 'assets/icons/workout_builder_filter_exercise_icon.svg';

const FilterBtn = ({ currentSelectedFilter, onSelectedFilterChange, openSecondToggle, getGroupsFilterByParams }) => {
  const onSelectedFilterApply = data => {
    onSelectedFilterChange(data);
  };

  const initStateFilter = {
    status: FILTER_STATUS.connected.value,
    owner_ship: 1,
    groups: [],
  };

  return (
    <Filter
      currentSelectedFilter={currentSelectedFilter}
      onSelectedFilterApply={onSelectedFilterApply}
      getGroupsFilterByParams={getGroupsFilterByParams}
      openSecondToggle={openSecondToggle}
      menuHeight={180}
      trigger={
        <FilterStyles className="broadcastMessage__filter-icon">
          {!isEqual(initStateFilter, currentSelectedFilter) && <UpdatedFilterIcon />}
          <FilterIcon className="filter-icon" />
        </FilterStyles>
      }
    />
  );
};

export default FilterBtn;
