import styled from 'styled-components';
import * as MetricChart from './components/styles';

export const Wrapper = styled.div`
  font-family: 'Open Sans';

  &.metric-chart-wrapper {
    padding: 24px 0 26px 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #f0f0f2;

    .chart-no-data {
      padding-left: 30px;
    }

    .metric-chart-content {
      margin: auto;
      margin-right: 30px;
      margin-left: 30px;
    }

    .panel-header {
      font-size: 13px;
      font-weight: 600;
      color: #273d52;
      display: block;
      padding-bottom: 25px;
      background: #fff;
      border-color: #fff;
    }

    .panel-header > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* flex-flow: row wrap; */
    }

    .panel-header .panel-title {
      font-size: 21px;
      color: #273d52;
      line-height: 20px;
      display: flex;
      align-items: center;
    }

    .panel-header .current-value {
      color: #42be83;
      font-size: 33px;
    }

    .panel-header .current-value .unit {
      font-size: 17px;
    }

    .panel-header .percent {
      color: #273d52;
    }

    .panel-header .time-range {
      position: relative;
      padding-left: 13px;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #273d52;
    }

    .panel-header .time-range span.active::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #48c98c;
    }

    .panel-header .time-range span {
      cursor: pointer;
      color: #6a7988;
    }

    .panel-header .time-range span.active {
      color: #273d52;
      font-weight: 600;
    }
  }

  .body-metric-scroll-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    height: ${({ isHideKeyMetric }) => `calc(100vh - ${isHideKeyMetric ? '170px' : '225px'})`};
    padding-right: 0;
    padding-top: 29px;
    display: flex;
    flex-direction: column;

    ${MetricChart.Wrapper} {
      margin-left: 40px;
      margin-bottom: 27px;
      z-index: 2;
    }

    ::-webkit-scrollbar {
      width: 6px !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #e1e1ea !important;
    }
  }
`;
