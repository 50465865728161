import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { updateWorkingForm } from 'redux/form-details/actions';
import { FORM_STATUS } from 'constants/commonData';
import { TextEditable } from './style';
import { setCaretToEnd } from 'utils/commonFunction';

const LIMIT_LENGTH = 40;

const FormHeader = ({ workingForm, updateWorkingForm }) => {
  const { name, _id } = workingForm;
  const [inputValue, setInputValue] = useState(name);
  const formNameRef = useRef();
  useEffect(() => {
    setInputValue(name);
  }, [name]);
  const handleChange = newValue => {
    if (newValue.trim().length <= LIMIT_LENGTH) {
      setInputValue(newValue);
    } else {
      if (newValue.trim().length === LIMIT_LENGTH + 1) {
        setInputValue(inputValue.trim().slice(0, LIMIT_LENGTH));
        formNameRef.current.focus();
        setCaretToEnd(get(formNameRef, 'current.inputRef.current'));
      } else {
        setInputValue(newValue.trim().slice(0, LIMIT_LENGTH));
      }
    }
  };
  const handleBlur = () => {
    if (inputValue.trim().length > 0) {
      updateWorkingForm({ name: inputValue.trim() }, _id);
    }
  };
  const isPublished = get(workingForm, 'status') === FORM_STATUS.PUBLISHED;
  const isEditMode = get(workingForm, 'is_edit_mode', false);
  const allowEdit = !isPublished || (isPublished && isEditMode);
  return (
    <TextEditable
      readOnly={!allowEdit}
      className="form-title-input"
      ref={formNameRef}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Name your form"
      invalid={inputValue.trim().length === 0}
      maxLength={40}
    />
  );
};

const mapState = state => {
  return {
    workingForm: state.rootReducer.formDetails.workingForm,
  };
};

const mapDispatch = dispatch => {
  return { updateWorkingForm: bindActionCreators(updateWorkingForm, dispatch) };
};

export default connect(mapState, mapDispatch)(FormHeader);
