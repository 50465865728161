import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { MAX_TAG_NAME_LENGTH, TAGS_TYPE } from 'constants/commonData';
import ModalSingleField from 'shared/ModalSingleField';

function CreateNewTags({ onSubmit, open, onCloseModal }) {
  const [value, setValue] = useState('');
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!open) {
      setValue('');
      setIsSubmitted(false);
    }
  }, [open]);

  const handleChangeValue = e => {
    const value = e.target.value.replace('  ', ' ');

    if (!MAX_TAG_NAME_LENGTH) setValue(value);
    if (value.length <= MAX_TAG_NAME_LENGTH) {
      setValue(value);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!value.trim()) {
      // Show Toast when have empty value
      toast.error('Please input tag name');
      return;
    }

    let params = {
      value,
    };

    if (onSubmit) {
      setIsDisabledSubmit(true);
      const data = {
        type: TAGS_TYPE.WORKOUT,
        name: get(params, 'value', ''),
      };
      onSubmit && onSubmit(data, onClose);
      setIsDisabledSubmit(false);
    }
  };

  const onClose = () => {
    setIsDisabledSubmit(false);
    typeof onCloseModal === 'function' && onCloseModal();
  };

  return (
    <>
      <ModalSingleField
        value={value}
        open={open}
        titlePopup="Create New Tag"
        titleInput="Tag Name"
        placeholderInput="Enter Tag Name"
        submitName="Create"
        maxLength={MAX_TAG_NAME_LENGTH}
        inputHeight={38}
        isSubmitted={isSubmitted}
        isDisabledSubmit={isDisabledSubmit}
        onClose={onClose}
        onChangeValue={e => handleChangeValue(e)}
        onSubmit={e => handleSubmit(e)}
      />
    </>
  );
}

export default CreateNewTags;
