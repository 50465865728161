import React from 'react';
import { isEdge } from 'react-device-detect';

export const AI_TEXT_EXAMPLE = `Full Body: Strength + Core Stability

Superset workout
1a. BB Deadlift 3x10-12 with tempo 3-X-X-X
1b. Bird Dog 3x8-10 (each side) rest for 1 minute 30 seconds
2a. Dumbbell Step Up 3x10-12 (each side)
2b. TRX push-up 3x8-10
2c. Plank 3 x 1-minute rest for 1 minute 30 seconds
3a. Leg press 3x10-12 with tempo 3-X-X-X
3b. Band Side Plank Row 3x8-10 (each side)

AMRAP
10 Burpee
10 Mountain climbers
Complete as many rounds as possible in 5 minutes`;

export const ONBOARDING_AI_TEXT_EXAMPLE = `<div class="ai-workout-name">Upper Push, Lower Pull Day</div>
<br/>
<div class="ai-section">Regular Section 1</div>
<div class="ai-section-format">
  <div>1a. BB Deadlift 3sets x 10reps RM% 60%, 65%, 70%</div>
  <div>1b. Shoulder Press 3x8 (each side), 90sec rest</div>
  <br/>
  <div>2a. Dumbbell Step Up 3x10 e/s</div>
  <div>2b. TRX push-up 3x8-10</div>
  <div>2c. Plank 3 x 1-min rest 60 sec</div>
</div>
<br/>
<div class="ai-section">Regular Section 2</div>
<div class="ai-single-exercise">3. Leg press 4x6 at 100lb, 120lb, 140lb, 160lb</div>
<div class="ai-single-exercise-format">4. Push Press 3x3, Tempo X-1-X-1</div>
<br/>
<div class="ai-interval-section">Interval Section, 5min duration</div>
<div>Burpee 30s on, 30s off</div>
`;

export const AI_TEXT_EXAMPLE_TRIMMED =
  'Full Body: Strength + Core Stability\n\nSuperset workout\n1a. BB Deadlift 3x10-12 with tempo 3-X-X-X\n1b. Bird Dog 3x8-10 (each side) rest for 1 minute 30 seconds\n2a. Dumbbell Step Up 3x10-12 (each side)\n2b. TRX push-up 3x8-10\n2c. Plank 3 x 1-minute rest for 1 minute 30 seconds\n3a. Leg press 3x10-12 with tempo 3-X-X-X\n3b. Band Side Plank Row 3x8-10 (each side)\n\nAMRAP\n10 Burpee\n10 Mountain climbers\nComplete as many rounds as possible in 5 minutes';

export const ONBOARDING_AI_TEXT_EXAMPLE_TRIMMED =
  'Upper Push, Lower Pull Day\n\nRegular Section 1\n1a. BB Deadlift 3sets x 10reps RM% 60%, 65%, 70%\n1b. Shoulder Press 3x8 (each side), 90sec rest\n\n2a. Dumbbell Step Up 3x10 e/s\n2b. TRX push-up 3x8-10\n2c. Plank 3 x 1-min rest 60 sec\n\nRegular Section 2\n3. Leg press 4x6 at 100lb, 120lb, 140lb, 160lb\n4. Push Press 3x3, Tempo X-1-X-1\n\nInterval Section, 5min duration\nBurpee 30s on, 30s off';

export const AI_JSON_EXAMPLE = {
  title: 'Full Body: Strength + Core Stability',
  user_input: AI_TEXT_EXAMPLE,
  sections: [
    {
      type: 'hidden',
      format: 'regular',
      time: null,
      exercises: [
        [
          {
            title: 'BB Deadlift',
            each_side: false,
            tempo: '3-X-X-X',
            training_sets: [
              { type_rest: '0', type_reps: '10-12' },
              { type_rest: '0', type_reps: '10-12' },
              { type_rest: '0', type_reps: '10-12' },
            ],
          },
          {
            title: 'Bird Dog',
            each_side: true,
            tempo: '0',
            training_sets: [
              { type_rest: '90', type_reps: '8-10' },
              { type_rest: '90', type_reps: '8-10' },
              { type_rest: '90', type_reps: '8-10' },
            ],
          },
        ],
      ],
    },
    {
      type: 'hidden',
      format: 'regular',
      time: null,
      exercises: [
        [
          {
            title: 'Dumbbell Step Ups',
            each_side: true,
            tempo: '',
            training_sets: [
              { type_rest: '0', type_reps: '10-12' },
              { type_rest: '0', type_reps: '10-12' },
              { type_rest: '0', type_reps: '10-12' },
            ],
          },
          {
            title: 'TRX push up',
            each_side: false,
            tempo: '',
            training_sets: [
              { type_rest: '0', type_reps: '8-10' },
              { type_rest: '0', type_reps: '8-10' },
              { type_rest: '0', type_reps: '8-10' },
            ],
          },
          {
            title: 'Plank',
            each_side: false,
            tempo: '',
            training_sets: [
              { type_rest: '90', type_duration: '60' },
              { type_rest: '90', type_duration: '60' },
              { type_rest: '90', type_duration: '60' },
            ],
          },
        ],
      ],
    },
    {
      type: 'hidden',
      format: 'regular',
      time: null,
      exercises: [
        [
          {
            title: 'Leg press',
            each_side: false,
            tempo: '3-X-X-X',
            training_sets: [
              { type_rest: '0', type_reps: '10-12' },
              { type_rest: '0', type_reps: '10-12' },
              { type_rest: '0', type_reps: '10-12' },
            ],
          },
          {
            title: 'Bank Side Plank Row',
            each_side: true,
            tempo: '',
            training_sets: [
              { type_rest: '0', type_reps: '8-10' },
              { type_rest: '0', type_reps: '8-10' },
              { type_rest: '0', type_reps: '8-10' },
            ],
          },
        ],
      ],
    },
    {
      type: 'workout',
      title: 'AMRAP',
      format: 'amrap',
      time: 300,
      exercises: [
        [{ title: 'Burpee', each_side: false, tempo: '', training_sets: [{ type_rest: '', type_reps: '10' }] }],
        [
          {
            title: 'Mountain climbers',
            each_side: false,
            tempo: '',
            training_sets: [{ type_rest: '', type_reps: '10' }],
          },
        ],
      ],
    },
  ],
};

const options = {
  disableBeacon: true,
  offset: 10,
  spotlightPadding: 5,
};

export const STEPS = [
  {
    content: (
      <>
        In it’s current form the AI Workout Builder is equipped to turn your <span className="bold italic">text</span>{' '}
        of a <span className="bold italic">single workout</span> into an everfit workout. Let's{' '}
        <span className="bold">input the entire workout text</span> in a single prompt!
      </>
    ),
    placement: 'bottom-start',
    target: '#AI_Assistant',
    title: 'Welcome to Everfit’s AI WorkoutBuilder!',
    ...options,
    styles: {
      spotlight: {
        marginTop: isEdge ? '1px' : 'unset',
        marginLeft: '-1px',
      },
    },
  },
  {
    content: (
      <>
        To add a title to the workout, write the <span className="bold">title on the top line</span> of text and then{' '}
        <span className="bold">leave a blank line</span> before inputting the exercises.
      </>
    ),
    placement: 'top-start',
    target: '.ai-workout-name',
    title: 'Workout Name',
    ...options,
    styles: {
      spotlight: {
        marginTop: '1px',
      },
    },
  },
  {
    content: (
      <>
        To create different Section Types, clearly state the type of section you want on one line and then list the
        exercises you want to include in the section on the lines directly below. Use at least 1 blank line between
        different sections and/or supersets circuits.
      </>
    ),
    placement: 'top-start',
    target: '.ai-section',
    title: 'Section Type',
    ...options,
    styles: {
      spotlight: {
        marginTop: '1px',
      },
    },
  },
  {
    content: (
      <>
        To create super sets, circuits etc use <span className="bold">sequential characters</span> to denote which
        exercises are linked
        <br />
        1.1 or 1.A or A1
        <br />
        1.2, 1.B or A2
        <br />
        2.1, 2.A or B1
        <br />
        2.2, 2.B or B2
        <br />
        2.3, 2.C or B3 etc
        <br />
        Once again only list <span className="bold">1 exercise per line</span>.
      </>
    ),
    placement: 'top-start',
    target: '.ai-section-format',
    title: 'Section Format',
    ...options,
    styles: {
      spotlight: {
        marginTop: '5px',
      },
    },
  },
  {
    content: (
      <>
        Only place <span className="bold">1 exercise per line</span>. Write out the exercise name, sets, other tracking
        fields and information on the same line.
      </>
    ),
    placement: 'top-start',
    target: '.ai-single-exercise-format',
    title: 'Single Exercise Format',
    ...options,
    styles: {
      spotlight: {
        marginTop: '4px',
      },
    },
  },
  {
    content: (
      <>
        To build a program of multiple workouts, you will need to create each workout individually. Once all the
        workouts have been created and saved, you can build the program in the regular program library.
      </>
    ),
    placement: 'top-end',
    target: '.footer-actions',
    ...options,
    styles: {
      spotlight: {
        marginTop: isEdge ? '1px' : 'unset',
        marginLeft: '1px',
      },
    },
  },
];
