import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import * as S from './style';

const getValue = (meal, key, view) => {
  if (_.get(meal, ['value', key])) {
    if (view === 'percent') return `${_.round(_.get(meal, ['value', key]))}%`;
    else return _.round(_.get(meal, ['value', key]));
  } else {
    return '-';
  }
};

const MacroItem = props => {
  const { valueKey, meal, goalByType, view } = props;
  const dayType = _.get(meal, 'day_type', 'training_day');
  const goalValue = _.get(goalByType, [dayType, 'value', valueKey], 0);
  const mealValue = _.get(meal, ['value', valueKey], 0);
  const isHighlight = mealValue && goalValue && (mealValue / goalValue > 1.1 || mealValue / goalValue < 0.9); //more than 10% higher or more than 10% lower
  return <td className={classnames({ highlight: isHighlight })}>{getValue(meal, valueKey, view)}</td>;
};

const MealItem = props => {
  const { day, reportDataByDay, view } = props;
  const meal = _.get(reportDataByDay, day.format('MM-DD-YYYY'));
  const dayType = _.get(meal, 'day_type');
  return (
    <S.MealItemRow>
      <td>
        {day.format('MMM DD')}
        {dayType && <S.DayTypeIcon type={dayType} />}
      </td>
      <MacroItem {...props} meal={meal} valueKey={`protein_${view}`} />
      <MacroItem {...props} meal={meal} valueKey={`carbs_${view}`} />
      <MacroItem {...props} meal={meal} valueKey={`fat_${view}`} />
      <td>{getValue(meal, `calories`)}</td>
    </S.MealItemRow>
  );
};
const TotalsByDay = ({ reportData, trainingGoal, weekCalendar, clientTimezone, viewSize }) => {
  const [view, setView] = useState('gram');
  const meals = _.get(reportData, 'meals', []);

  const { startDate, endDate } = weekCalendar;
  const days = viewSize === 'last14' ? 14 : 7;
  const reportDataByDay = _.mapKeys(reportData.meals, value => value.day);
  const goalByType = _.mapKeys(reportData.goals, goal => goal.type);

  useEffect(() => {
    const viewType = _.get(trainingGoal, 'value.type');
    if (viewType) {
      setView(viewType);
    }
  }, [reportData]);

  return (
    <S.Wrapper>
      <S.Heading>
        <div className="total-by-day__title">Totals by day</div>
        <div className="total-by-day__view-btn">
          <S.ViewButton onClick={() => setView('percent')} className={classnames({ active: view === 'percent' })}>
            Percent (%)
          </S.ViewButton>
          <S.ViewButton onClick={() => setView('gram')} className={classnames({ active: view === 'gram' })}>
            Gram (g)
          </S.ViewButton>
        </div>
      </S.Heading>
      <S.TableWrapper>
        <thead>
          <th>Date</th>
          <th width="19%">Protein</th>
          <th width="19%">Carbs</th>
          <th width="19%">Fat</th>
          <th width="19%">Cal</th>
        </thead>
        <tbody>
          {meals.length ? (
            _.range(0, days).map(day => (
              <MealItem
                key={day}
                day={moment.tz(startDate.format(), clientTimezone).add(day, 'd')}
                reportDataByDay={reportDataByDay}
                goalByType={goalByType}
                view={view}
              />
            ))
          ) : (
            <>
              <tr className="no-data">
                <td colspan="5" rowspan="7">
                  No data in range
                </td>
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
            </>
          )}
          <tr className="tr__average">
            <td>Average</td>
            <td>{getValue(reportData.averageMeal, `protein_${view}`, view)}</td>
            <td>{getValue(reportData.averageMeal, `carbs_${view}`, view)}</td>
            <td>{getValue(reportData.averageMeal, `fat_${view}`, view)}</td>
            <td>{getValue(reportData.averageMeal, 'calories')}</td>
          </tr>
        </tbody>
      </S.TableWrapper>
    </S.Wrapper>
  );
};

export default TotalsByDay;
