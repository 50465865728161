// Lib
import React from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Components
import ViewRecipeDetail from 'components/RecipeDetail/ViewRecipeDetail';
import { conditionalRoundNutrition } from 'components/RecipeDetail/helper';
import { getMacroNutrients } from 'components/MealPlanDayDetail/constants';

// Utils
import { pluralize } from 'utils/commonFunction';

// Assets
import DefaultImage from 'assets/icons/MealPlans/meal_plan_default.png';
import { ReactComponent as EyeIcon } from 'assets/icons/MealPlans/eye_icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/MealPlans/check_icon.svg';

// Style
import * as S from './style';

const ItemContent = ({
  id = '',
  name = '',
  coverImageThumbnail = '',
  coverImage = '',
  active = false,
  hideWeek = false,
  totalWeek,
  isRecipe,
  showViewDetail = false,
  title = '',
  toggleSecondModal,
  onAdd,
  item,
  disabled,
  idMealClient,
  showCheckbox = false,
}) => {
  const handleOpenViewDetail = e => {
    if (disabled) return;

    e.stopPropagation();

    typeof toggleSecondModal === 'function' &&
      toggleSecondModal(
        true,
        <ViewRecipeDetail
          recipeId={id}
          isShowFooterActions
          buttonContent={title}
          onClose={() => toggleSecondModal(false)}
          onAdd={() => onAdd(item)}
          isGetFromClientTab
          mealPlanId={idMealClient}
        />,
      );
  };

  const renderNutritionItem = item => {
    const { label = '', unit = '', value = '', id } = item;
    const isEmptyValue = +value === 0;

    return (
      <S.ItemNutrition key={id}>
        <S.SeparateDot />
        {`${label} ${isEmptyValue ? '—' : conditionalRoundNutrition(value)} ${unit}`.trim()}
      </S.ItemNutrition>
    );
  };

  const renderRecipeNutrition = recipe => {
    const nutrients =
      get(recipe, 'macro_nutrients.length', 0) > 0 ? getMacroNutrients(get(recipe, 'macro_nutrients', [])) : [];

    return <S.RecipeUnit>{nutrients.length > 0 && nutrients.map(renderNutritionItem)}</S.RecipeUnit>;
  };

  return (
    <S.Wrapper active={active} isRecipe={isRecipe} disabled={disabled} className="client-recipe-item">
      <S.ThumbnailWrapper disabled={disabled}>
        <S.Thumbnail src={[coverImageThumbnail, coverImage]} defaultImage={DefaultImage} isRecipe={isRecipe} />
        {!hideWeek && <div className="week">{pluralize('Week', totalWeek, true)}</div>}
        {showViewDetail && (
          <>
            <div
              className="view-detail"
              data-tip
              data-for={`client-view-recipe-${id}`}
              onClick={e => handleOpenViewDetail(e)}
            >
              <EyeIcon />
            </div>
            <ReactTooltip
              className="app-tooltip client-view-recipe"
              id={`client-view-recipe-${id}`}
              effect="solid"
              place="top"
            >
              <div>View Recipe</div>
            </ReactTooltip>
          </>
        )}
        {showCheckbox && (
          <div className="checkbox">
            <CheckIcon />
          </div>
        )}
      </S.ThumbnailWrapper>
      <S.Content isRecipe={isRecipe}>
        <S.RecipeName>{name}</S.RecipeName>
        {!isEmpty((item || {}).macro_nutrients) && renderRecipeNutrition(item)}
      </S.Content>
    </S.Wrapper>
  );
};

export default ItemContent;
