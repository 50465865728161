import styled, { css } from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';

export const MainPage = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: #fafbfc;
  /* NOTE: Remove this code to support cut off tooltip */
  /* z-index: 998; */

  ${props =>
    props.isOverviewTab &&
    css`
      height: 100%;
      overflow: hidden;
      position: relative;
    `}
`;

export const Container = styled.div`
  height: calc(100% - 77px);
  max-height: calc(100% - 77px);
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background: #fff;

  .onboarding-flow-title-input {
    padding-top: 16px;
    padding-left: 30px;
    .text-editable__container {
      padding: 0;
    }
    .text-editable__input {
      line-height: normal;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: #202353;
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  padding: 16px 0 14px 30px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background: #fff;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 4px;
  }
`;

export const ToolbarWrapper = styled.div`
  background-color: #fff;
  padding: 24px 30px;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 28px;
`;

export const TabWrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 33px;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  span {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #76778a;
    text-transform: capitalize;
  }

  .__react_component_tooltip.app-tooltip {
    padding: 15px;
    max-width: 228px;
    background-color: #2d2e2d;
    text-align: left;
    margin-top: -7px;
    p {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    &.tab-tooltip.place-top:after {
      bottom: -7px;
    }
  }

  ${props =>
    props.isActive &&
    css`
      span {
        font-weight: 700;
        color: #5158cf;
      }
      border-bottom: 2px solid #5158cf;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #76778a;
      cursor: not-allowed;
    `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  .onboarding-flow-btn {
    height: 34px;
    font-weight: 600;
    svg {
      margin-right: 5px;
    }
  }
  .onboarding-flow-save-draft {
    width: 166px;
    border: 1px solid #d6dae4;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .onboarding-flow-edit {
    width: 102px;
  }
  .onboarding-flow-publish {
    width: 104px;
    background-color: #36b24b;
    color: #fff;
    border-color: transparent;
    :not(:disabled):hover {
      background-color: #38c350;
    }
  }
  .onboarding-flow-unpublish {
    width: 144px;
  }
  .onboarding-flow-cancel-changes {
    width: 154px;
  }
  .onboarding-flow-save-changes {
    width: 170px;
    background-color: #36b24b;
    color: #fff;
    border-color: transparent;
    :not(:disabled):hover {
      background-color: #38c350;
    }
  }
`;

export const AssetsWrapper = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;

  ${props =>
    props.disabled &&
    css`
      justify-content: flex-start;
    `}

  svg.center {
    align-self: flex-start;
    margin-top: 118px;
    flex-shrink: 0;
    width: 25px;
  }

  .onboarding-flow-loading {
    display: flex;
    width: 100%;
    min-height: 416px;
    padding-top: unset;
    align-items: center;
    justify-content: center;
  }

  .cheat-space-right {
    min-width: 14px;
    height: 100%;
    background-color: transparent;
  }
`;

export const UnpublishConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;

    .confirm-content-header {
      img {
        margin-right: 10px;
      }
    }
  }

  .confirm-header-label {
    color: #000;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
  }

  .confirm-content-label {
    color: #323c47;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    p {
      margin: 0;
    }
  }

  .ui.button.confirm-no-button {
    min-width: 102px;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 4px 29px !important;
  }

  .ui.button.confirm-unpublish-button {
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 4px 29px !important;

    &:hover {
      background: rgba(255, 196, 69, 0.9);
    }
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const EmptyIconsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f9f9fa;
  width: 40px;
  height: 40px;

  svg path {
    fill: #bfc5d2;
    stroke: transparent;

    ${props =>
      props.thin &&
      css`
        stroke: #f9f9fa;
      `}
  }
`;

export const EmptyMessage = styled.p`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  opacity: 0.3;
`;

export const ExitConfirm = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;

    .close-button {
      right: -8px;
      top: -8px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .confirm-content-header {
      padding: 30px 30px 0 30px !important;
      .confirm-header-label {
        line-height: 25px;
      }
    }

    .confirm-header-image {
      width: 25px;
      height: 25px;
    }

    .confirm-content-body {
      padding: 15px 30px 30px 30px;
    }

    .ui.button.confirm-no-button {
      max-height: 30px;
      border-radius: 5px;
      line-height: 150%;
      letter-spacing: 0.4px;
    }

    .ui.button.confirm-yes-button {
      background-color: #5158cf;
      min-width: 90px;
      max-height: 30px;
      box-shadow: none;
      border-radius: 5px;
      line-height: 150%;
      &:hover {
        background-color: rgba(81, 88, 207, 0.9) !important;
      }
    }
    &.publish-confirm {
      .ui.button.confirm-yes-button {
        min-width: 94px;
        font-weight: 700;
      }
    }
  }
`;
