export default {
  q1: [
    { label: 'Personal trainer', key: 'personal_trainer' },
    { label: 'Sports coach', key: 'sports_coach' },
    { label: 'Gym manager', key: 'gym_manager' },
    { label: 'Owner', key: 'owner' },
    { label: 'Health professional', key: 'health_professional' },
  ],
  q2: [
    { label: 'My own training business', selectedLabel: 'my own training business', key: 'my_own_business' },
    { label: 'Boutique gym', selectedLabel: 'a boutique gym', key: 'boutique_gym' },
    { label: 'Multi-location gym', selectedLabel: 'a multi-location gym', key: 'multi_location_gym' },
    { label: 'Wellness center', selectedLabel: 'a wellness center', key: 'wellness_center' },
    { label: 'University', selectedLabel: 'a university', key: 'a_university' },
  ],
  q4: [
    { label: '1-10 clients', key: '1_10' },
    { label: '11-25 clients', key: '11_25' },
    { label: '26-50 clients', key: '26_50' },
    { label: '51-100', key: '51_100' },
    { label: '101-250', key: '101_200' },
    { label: '251-500', key: '251_500' },
    { label: '500+', key: '500_' },
  ],
  q6: [
    { label: 'Google Ad', key: 'google_ad' },
    { label: 'Facebook/IG Ad', key: 'facebook_ig_ad' },
    { label: 'Bing Ad', key: 'bing_ad' },
    { label: 'Email campaign', key: 'email' },
    { label: 'Capterra or G2', key: 'capterra_g2' },
    { label: 'Instagram', key: 'instagram' },
    { label: 'Facebook', key: 'facebook' },
    { label: 'LinkedIn', key: 'linkedin' },
    { label: 'Reddit', key: 'reddit' },
    { label: 'Heja', key: 'heja' },
    { label: 'X', key: 'x' },
    { label: 'Tiktok', key: 'tiktok' },
    { label: 'Other social media', key: 'other_social_media' },
    { label: 'From a friend', key: 'friend' },
    { label: 'From a client', key: 'client' },
    { label: 'Other', key: 'other' },
  ],
};
