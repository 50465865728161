import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import { Wrapper } from './style';
import Rooms from './Rooms';
import Content from './Content';
import RoomInformation from './RoomInformation';
import { isTeamAdmin } from 'utils/commonFunction';

class Inbox extends React.Component {
  constructor(props) {
    super(props);

    this.onMounting = false;
  }

  componentDidMount() {
    const currentParams = get(this.props, 'match.params', {});
    const {
      rooms,
      newConversation,
      changeProfileId,
      getClientsFullData,
      loadedRooms,
      getAllRooms,
      getRoomIfExists,
      user,
    } = this.props;

    getClientsFullData && getClientsFullData();

    if (!loadedRooms && !rooms.length) {
      const selectProfileId = currentParams.profileId !== 'new' && currentParams.profileId;
      getAllRooms(null, selectProfileId);
      return;
    }

    if (currentParams.profileId !== 'new') {
      setTimeout(() => {
        this.onMounting = true;
        getRoomIfExists({ profileId: currentParams.profileId, saveToStore: false })
          .then(existed => {
            if (existed) {
              const isOwner = isTeamAdmin(user) && (user.team_member.length > 1 || user.team_old_members.length > 0);
              this.props.selectRoom(existed.id, !isOwner);
            } else {
              changeProfileId(currentParams.profileId);
            }
          })
          .finally(() => {
            this.onMounting = false;
          });
      }, 0);
    } else {
      if (!newConversation) {
        this.props.createNewConversation();
      }
    }
  }

  componentDidUpdate() {
    const { rooms, currentRoomId, newConversation, query } = this.props;
    const profileId = get(this.props, 'match.params.profileId');

    setTimeout(() => {
      if (this.onMounting) return;
      if (profileId !== 'new' && !currentRoomId.includes(profileId)) {
        const existed = find(uniqBy([...rooms, ...query.rooms], 'id'), item => {
          const id = get(item, 'id') || get(item, 'room_id', '');
          return !isEmpty(id) && id.includes(profileId);
        });
        if (existed) {
          if (existed.type === 'group') {
            this.props.selectRoom(existed.id || existed.room_id);
            return;
          } else if (existed.type === 'direct' && get(existed, 'room_id', '').includes('_')) {
            this.props.selectRoom(existed.room_id);
            return;
          } else {
            this.props.selectRoom(existed.id);
          }
        }
      } else if (profileId === 'new' && !newConversation) {
        this.props.createNewConversation();
      }
    }, 0);
  }

  render() {
    const { profileId, match } = this.props;
    return (
      <Wrapper>
        <Rooms />
        <Content key={`inbox-content-${profileId}`} match={match} />
        <RoomInformation key={`inbox-information-${profileId}`} />
      </Wrapper>
    );
  }
}

export default Inbox;
