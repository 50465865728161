import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 604px;
  position: relative;
  box-shadow: -1px 0px 20px 0px rgba(30, 46, 103, 0.1);
`;

export const SendToWrapper = styled.div`
  display: flex;
  margin: 25px 25px 0 20px;
  border-bottom: 1px solid #dadfea;
  padding-bottom: 8px;
`;

export const MessageWrapper = styled.div`
  display: flex;
  height: calc(100% - ${props => props.headerHeight}px - 140px);
`;

export const Messages = styled.div`
  background-color: #fff;
  padding: 25px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

export const Tip = styled.div`
  color: #000;
  text-align: right;
  margin-right: 35px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.3;
`;

export const SendToLabel = styled.div`
  color: #202353;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 4px;
`;

export const SelectedClientWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 25%;
  margin-left: 25px;
  margin-right: 4px;
  width: 474px;
  max-height: 99px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 4px !important;
  }
`;

export const SelectedClientItem = styled.div`
  display: flex;
  padding: 6px 10px;
  align-items: center;
  border-radius: 4px;
  background: #e7e7fd;
  margin-right: 5px;
  margin-bottom: 5px;
  max-height: 28px;

  .remove-selected-icon {
    margin-left: 4px;
    width: 12px;
    height: 13px;
    cursor: pointer;
    path {
      fill: #5d5bbe;
      stroke: #5d5bbe;
    }
  }
`;

export const ClientName = styled.div`
  color: #202353;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
`;

export const ChatActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  right: 0;
  left: 0;
  margin-top: 4px;
  .chat-input {
    margin-top: 5px;
  }

  border-radius: 0 0 12px 0;
`;

export const BottomActionWrapper = styled.div`
  display: flex;
  padding: 10px 5px 0 20px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  .broadcast-button {
    padding: 8px 27px;
    margin-right: 14px;
    margin-bottom: 1px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
  }
`;

export const MessagesRemaining = styled.div`
  color: ${props => (props.disabled ? '#ff7875' : '#848484')};
  font-size: 12px;
  font-weight: 600;
`;

export const WarningBroadcastModal = styled(Modal)`
  display: flex;
  flex-direction: row;
  &.ui.modal {
    padding: 30px;
    border-radius: 8px;
    width: 467px;
    height: 180px;

    .confirm-footer {
      padding: 30px 0 0;

      button {
        padding: 7px 42px;
      }
    }
  }

  &.ui.modal > :last-child {
    padding: 9px 15px;
  }
`;
