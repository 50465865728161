import React, { useEffect } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import * as S from './style';
import CreateNewStudioProgram from './CreateNewStudioProgram';
import CopyFromProgramLibrary from './CopyFromProgramLibrary';
import { CDN_URL } from 'constants/commonData';

function CreateStudioProgramModal(props) {
  useEffect(() => {
    window.addEventListener('popstate', handleBackButtonToggleModal);
    return () => {
      window.removeEventListener('popstate', handleBackButtonToggleModal, false);
    };
  }, []);
  const handleBackButtonToggleModal = () => {
    if (props.isModalOpen) {
      props.toggleModal(false);
    }
  };
  return (
    <Modal
      open={true}
      className="modal--create-workout-template"
      closeOnDimmerClick={true}
      onClose={() => props.toggleModal(false)}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <S.Title>Create Program</S.Title>
        <S.SubTitle>Please select how you would like to start</S.SubTitle>
      </Modal.Header>
      <Modal.Content>
        <S.OptionContainer>
          <S.Option
            onClick={() => {
              props.toggleModal(true, <CopyFromProgramLibrary />);
            }}
          >
            <S.OptionIcon src={`${CDN_URL}/images/studio_program_copy_from_library.svg`} />
            <S.OptionIcon src={`${CDN_URL}/images/studio_program_copy_from_library_hover.svg`} className="hover" />
            <S.OptionLabel>Copy From Library</S.OptionLabel>
            <div>Choose one from your Program library</div>
          </S.Option>
          <S.Option
            onClick={() => {
              props.toggleModal(true, <CreateNewStudioProgram />);
            }}
          >
            <S.OptionIcon src={`${CDN_URL}/images/new_workout.svg`} />
            <S.OptionIcon src={`${CDN_URL}/images/new_workout_purple.svg`} className="hover" />
            <S.OptionLabel>New Program</S.OptionLabel>
            <div>Create your own</div>
          </S.Option>
        </S.OptionContainer>
      </Modal.Content>
    </Modal>
  );
}

const actionCreators = { toggleModal };

const mapState = state => {
  return {
    isModalOpen: state.isModalOpen,
  };
};

export default connect(mapState, actionCreators)(CreateStudioProgramModal);
