import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';
import { Container, SignupButton } from './style';
import './style.scss';

function Popup(props) {
  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
      onClose={props.onClose}
      className="modal--restrict-client-login"
    >
      <Modal.Content>
        <Container>
          <div className="hand">👋</div>
          <h1>Welcome to Everfit</h1>
          <h3>To use Everfit as a client, please download the client app and log in to begin training.</h3>
          <div className="links">
            <a href="/views/download_ios" target="_blank">
              <img alt="" src={`${CDN_URL}/images/appstore_available.svg`} />
            </a>
            <a href="/views/download_android" target="_blank">
              <img src={`${CDN_URL}/images/googleplay_available.svg`} alt="" />
            </a>
          </div>
          <div className="create-account-container">
            <div className="create-account__label">or Create a Coach Account</div>
            <div className="create-account__button">
              <SignupButton purple onClick={props.onSubmit}>
                <span>SIGN UP FOR FREE</span> <img src="/images/arrow.svg" alt="arrow" />
              </SignupButton>
            </div>
          </div>
        </Container>
      </Modal.Content>
    </Modal>
  );
}

export default Popup;
