import React from 'react';

import classNames from 'classnames';
import './styles.scss';

const CheckBox = ({ title, className = '', onClick, onChange, checked, style = {} }) => {
  return (
    <label style={style} onClick={onClick} className={classNames('check-box-container', className)}>
      {title}
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span class="checkmark" />
    </label>
  );
};

export default CheckBox;
