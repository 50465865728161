import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 69px 118px;
  overflow: auto;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 60px;
`;
export const CenterContainer = styled.div`
  width: 910px;
  margin: auto;
`;
export const SectionContainer = styled.div`
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 35px 30px;
  background-repeat: no-repeat;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
    `}
  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}

  button {
    border: 1px solid #5158cf;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
    padding: 10px 18px;
    height: 38px;
    color: #5158cf;
    &:disabled {
      border: 1px solid #c4c4c4;
      color: #c4c4c4;
      background-color: transparent;
    }
    &.verified {
      color: #212121;
      pointer-events: none;
      border-color: #dadfea;
      padding: 10px 18px 10px 16px;
      svg {
        margin-right: 3px;
      }
    }
  }
  .evfSelectBoxContainer.evfSelectBox--is-disabled .evfSelectBox__value-container {
    opacity: 1;
  }
  .evfSelectBoxContainer .evfSelectBox__control .evfSelectBox__single-value {
    color: #323c47;
  }
`;

export const VerificationWrapper = styled.div`
  display: flex;
  gap: 8px;
  .app-tooltip.verified-id-tooltip,
  .app-tooltip.verified-document-tooltip {
    border-radius: 5px;
    margin-top: -6px;
    color: #fff;
    padding: 15px;
    width: 228px;
    text-align: left;
    background-color: #2d2e2d;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    &.type-dark.place-top:after {
      border-top-color: #2d2e2d;
      bottom: -8px;
    }
  }
`;

export const SectionTeamContainer = styled(SectionContainer)`
  flex-direction: column;
  padding-bottom: 20px;
  .evfSelectBoxContainer .evfSelectBox__control {
    max-width: 100%;
    max-height: 70px;
    overflow: auto;
    height: 100%;
  }

  .evfSelectBoxContainer .evfSelectBox__control.evfSelectBox__control--menu-is-open {
    border-color: #dadfea !important;
  }

  .evfSelectBoxContainer {
    width: 458px;
    height: 100%;
  }
  .select__option {
    padding: 13px 15px;
    cursor: pointer;
    &:hover {
      background-color: #f4f4ff;
    }
  }

  .evfSelectBox__multi-value {
    background: #e7e7fd;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
  }
  .evfSelectBox__multi-value__remove {
    :hover {
      background-color: transparent;
    }
    svg {
      color: #5d5bbe;
    }
  }
`;

export const SectionPayment = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  justify-items: center;
  margin-bottom: 15px;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  margin-bottom: 20px;
`;

export const SubTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  margin-bottom: 8px;
`;

export const Desc = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  display: flex;
  align-items: center;
`;

export const DescWrapper = styled.div`
  width: 490px;
`;

export const ContactInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .sb-avatar {
    margin-right: 14px;
    flex-shrink: 0;
  }
`;

export const SingleLabel = styled.div`
  .name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    padding-right: 20px;

    .is-me {
      font-weight: 400;
    }
  }

  .email {
    margin-top: 3px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.4;
    overflow: hidden;
    white-space: nowrap;
    width: 185px;
  }
`;

export const Teams = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid #dadfea;
  padding-top: 10px;
  flex-wrap: wrap;
  .team {
    background: #f7f7fa;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    width: 276px;
    position: relative;
    word-break: break-all;
    margin-bottom: 10px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:hover {
      svg {
        display: block;
      }
    }
  }
  svg {
    position: absolute;
    right: 15px;
    cursor: pointer;
    display: none;
  }
`;

export const InfoIcon = styled.div`
  width: 14px;
  height: 14px;
  background-size: cover;
  display: inline-block;
  margin-right: 10px;
  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
    `}
`;

export const Name = styled.span`
  font-weight: 700;
  margin-left: 5px;

  &::first-letter {
    text-transform: capitalize;
  }
`;
