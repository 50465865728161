import { Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import Button from 'shared/FormControl/Button';
import { DEFAULT_FORUM_BANNER } from 'components/CommunityForum/constants';
import { MB_SIDE_BAR_HEIGHT } from 'constants/commonData';

export const SubMenuContainer = styled.div`
  padding: 0 15px 0 13px;

  &.active {
    color: #ffffff;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.2);

    .menu-list-item {
      color: #ffffff;
    }
  }

  .menu-list-item {
    padding-right: 0 !important;
  }
`;

export const SidebarItemContainer = styled.div`
  width: 60px;
  height: 60px;
  position: relative;

  > a {
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    height: 100%;
    color: #a8a7d2;

    :hover {
      color: #fff;

      svg g path {
        fill: #fff;
      }
    }
  }

  :hover {
    background-color: #494699;

    .menu-item-dropdown-content {
      visibility: visible;
      left: 60px;
      opacity: 1;
      transition: opacity 0.75s ease, left 0.25s linear;
    }
  }

  &.active {
    background-color: #494699;

    > a {
      color: #fff;

      svg g path {
        fill: #fff;
      }
    }

    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: #55eda5;
    }
  }

  &.current-user {
    padding: 15px 0;

    .menu-item-dropdown-content {
      svg {
        &.icon {
          margin-right: 10px;
        }
      }
    }

    .work-space-title {
      svg {
        margin-left: 10px;
      }
    }

    :hover {
      .menu-item-dropdown-content {
        visibility: visible;
        left: 60px;
        opacity: 1;
        transition: opacity 0.75s ease, left 0.25s linear;
      }
    }
    .client-detail-avatar.sb-avatar__text span > span {
      font-size: 14px !important;
    }
  }

  @media only screen and (max-height: ${MB_SIDE_BAR_HEIGHT}) {
    ${props =>
      props.hideOnMobile &&
      css`
        display: none;
      `}
  }

  .integration-item {
    :hover {
      .integration-icon {
        g {
          path {
            fill: #5a57cb;
          }
        }
      }
    }
  }
`;

export const MainAppContainer = styled.div`
  height: calc(100vh - ${props => props.paymentOverdueHeight + navbarHeight || '0'}px);

  &.app-main-content {
    :has(.client-meal-plans) {
      height: calc(100% - 77px) !important;
    }
    :has(.mainOnboardingFlow) {
      overflow-x: hidden !important;
    }
  }
`;

export const LeftColumnContainer = styled.div``;

export const SubMenuHeader = styled.div`
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #ffffff;
  padding: 0 15px 0 13px;
  margin-bottom: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .back {
    margin-right: 15px;
    cursor: pointer;
    display: none;
  }
`;

export const SidebarContent = styled.div`
  width: 230px;
  background-color: #4a469a;
  height: 100%;
  padding: 30px 10px 15px 13px;
  box-shadow: none;

  ${props =>
    props.forums &&
    css`
      width: 85px !important;
    `}

  @media only screen and (max-height: ${MB_SIDE_BAR_HEIGHT}) {
    ${props =>
      props.hideOnMobile &&
      css`
        display: none;
      `}

    ${props =>
      props.fixedOnMobile &&
      css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      `}

    ${SubMenuHeader} {
      margin-bottom: 22px;
      font-size: 20px;
      line-height: 27px;

      .back {
        display: inline;
      }
    }
  }
`;

export const ForumListSidebarContent = styled(SidebarContent)`
  @media only screen and (max-height: ${MB_SIDE_BAR_HEIGHT}) {
    &.sidebar-content:not(.settings) {
      display: flex !important;
    }
  }
`;

export const MobileSidebarTrigger = styled.div`
  cursor: pointer;
  width: 60px;
  height: 60px;
  color: #a8a7d2;
  color: #fff;
  display: none;
  position: relative;

  :hover,
  &.open,
  &.active {
    background-color: #494699;
  }

  &.active {
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: #55eda5;
    }
  }

  :hover {
    cursor: pointer;
  }

  @media only screen and (max-height: ${MB_SIDE_BAR_HEIGHT}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CopyLinkContainer = styled.div`
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  padding: 0 20px;
  background: inherit;

  .title {
    font-weight: bold;
    font-size: 10px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #9493be;
    margin-bottom: 10px;
  }
`;

const navbarHeight = '70px';
const higherNavbar = '85px';
const highestNavbar = '104px';
const sidebarWidth = '60px';
const mediumNavbar = '104px';

export default {
  leftMenu: {
    width: sidebarWidth,
    height: '60px',
    position: 'relative',
    cursor: 'pointer',
  },

  leftMenuActive: {
    margin: '0',
    display: 'block',
    top: '57%',
    left: '-3px',
    transform: 'translate(0%, -50%)',
    position: 'absolute',
    width: '10px',
    height: '60px',
  },
  leftMenuImage: {
    margin: '0',
    display: 'block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    cursor: 'pointer',
  },
  leftColShadow: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: 1000,
    width: sidebarWidth,
    backgroundColor: '#5c58c0',
    boxShadow: '-4px 15px 20px 0 rgba(29, 28, 66, 0.36)',
  },
  leftCol: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: 1000,
    width: sidebarWidth,
    backgroundColor: '#5c58c0',
  },
  leftColAvatar: {
    position: 'absolute',
    bottom: '0px',
    width: sidebarWidth,
  },

  leftColAvatarImage: {
    display: 'block !important',
  },

  leftColLogo: {
    height: navbarHeight,
    position: 'relative',
    background: '#5C58C0',
    zIndex: 1000,
  },

  leftColLogoImage: {
    margin: '0',
    display: 'block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    cursor: 'pointer',
  },

  mainContainer: {
    display: 'flex',
    background: 'white',
    overflow: 'visible',
    flexGrow: 1,
    height: `calc(100% - ${navbarHeight})`,
    position: 'relative',
  },

  contentWidthHigherNavbar: {
    height: `calc(100% - ${higherNavbar})`,
  },
  contentWidthMediumNavbar: {
    height: `calc(100% - ${mediumNavbar})`,
  },
  topBar: {
    height: navbarHeight,
  },
  topBarHigher: {
    height: higherNavbar,
  },
  topBarHighest: {
    height: highestNavbar,
  },
  topBarMedium: {
    height: mediumNavbar,
  },
  rootContainer: {
    overflow: 'hidden',
  },
};

export const DownloadCoachAppWrapper = styled.div`
  background-color: #eceeef;
  border-radius: 3px;
  padding: 7px 10px 3px 13px;
  cursor: pointer;

  .__react_component_tooltip.place-bottom.type-dark {
    font-weight: 600;
  }

  :hover {
    background-color: #ced7dd;
  }
`;

export const ForumArea = styled.div`
  display: inherit;
  flex-direction: inherit;
  overflow: auto;
  ${props =>
    props.loading &&
    css`
      button {
        cursor: not-allowed;
      }
    `}
`;

export const ForumAvatar = styled.button`
  height: 48px;
  min-height: 48px;
  width: 48px;
  border-radius: 7px;
  margin: 10px auto !important;
  position: relative;
  border: none;

  ${props =>
    props.isSelected &&
    `
      .white-dot {
        display: none !important;
      }

      .border-hover {
        border: 2px solid white !important;
      }
    `}

  &:hover {
    .white-dot {
      display: none !important;
    }

    .border-hover {
      border: 2px solid white;
    }
  }

  &:focus .white-dot {
    display: none !important;
  }

  .border-hover {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 58px;
    height: 58px;
    border: 2px solid transparent;
    border-radius: 7px;
  }

  background-image: url(${props => (props.src ? props.src : DEFAULT_FORUM_BANNER)});
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: white;
  cursor: pointer;

  .white-dot {
    &.active {
      display: inline-block !important;
    }
    right: -7px;
    top: -7px;
    height: 14px;
    width: 14px;
    background-color: #f9a014;
    border-radius: 50%;
    display: none;
    position: absolute;
    border: 2px solid #494699;
    z-index: 1;
  }
`;

export const PlusButton = styled(Button)`
  min-width: 16px !important;
  background-color: transparent;
  margin: 20px auto;
`;

export const SPopup = styled(Popup)`
  &.ui.inverted.popup {
    background: #2d2e2d;
    border-radius: 5px;
    transform: translateX(12px);

    &:after,
    &:before {
      background: #2d2e2d !important;
    }
  }
`;

export const ContentPopup = styled.div`
  display: flex;
  color: #ffffff;
  width: 110px;
  height: 50px;
  font-family: Open Sans;
  font-size: 13px;
  line-height: 150%;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
`;

export const ItemCountList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;

  .menu-list-item {
    width: 100%;
  }

  .non-spacing {
    margin: 0 !important;
  }
`;

export const NumberTotal = styled.span`
  min-width: 23px;
  text-align: center;
`;

export const NewLabel = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  top: 6px;
  right: 1px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
`;

export const BetaLabel = styled.div`
  padding: 4px 6px;
  border-radius: 4px;
  background: linear-gradient(112deg, #ffc90c 25.07%, #ff4d00 180.06%);
  color: #443604;
  text-align: center;
  font-size: 7px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 7px */
  text-transform: uppercase;
`;
