// Libs
import React, { useMemo, useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import isEmpty from 'lodash/isEmpty';

// Constants
import { ASSET_TYPE } from 'components/Product/constants';
import { ON_DEMAND_PLANS } from 'components/OnboardingFlowDetail/constants';

// Assets
import { ReactComponent as ArrowUpIcon } from 'assets/icons/OnboardingFlow/arrow_up_upgrade_path.svg';
import upgradePathOnboardingSocial from 'assets/images/OnboardingFlow/upgrade_path_on_demand_social.png';
import upgradePathAutoflow from 'assets/images/OnboardingFlow/upgrade_path_studio.png';

// Styles
import * as S from './style';

const UpgradePathPopup = ({ permission, isTrialMode }) => {
  const [open, setOpen] = useState(false);

  const isOndemandAddOn = permission['studio_resource_collection'] || permission['on_demand_workout'];
  const isStudioProgram = permission[ASSET_TYPE.STUDIO];
  const isAutomationAddOn = permission[ASSET_TYPE.AUTOFLOW];

  const isFullPermission = isOndemandAddOn && isStudioProgram && isAutomationAddOn && !isTrialMode;

  if (isFullPermission) return <></>;

  const getActionDescriptions = types => {
    const descriptions = {
      resourceCollection: {
        contentBold: 'Resource Collection:',
        description:
          'Amplify your coaching and share documents, videos, and links effortlessly with any group of clients.',
      },
      workoutCollection: {
        contentBold: 'Workout Collection:',
        description: 'Offer on-demand workouts that your clients can browse and start anytime.',
      },
      studioPrograms: {
        contentBold: 'Studio Program:',
        description:
          'Build ready-made programs you can assign to clients for them to start and stop anytime on their own',
      },
      autoflow: {
        contentBold: 'Autoflow:',
        description: 'Spend less time managing, and \nmore time training clients.',
      },
      multipleOnboarding: {
        contentBold: 'Multiple Onboarding Flows:',
        description: 'Create and assign multiple onboarding flows for specific coaches and clients.',
      },
    };

    return types.map(type => descriptions[type]);
  };

  const permissionConfig = {
    addAutoflowAndOnDemand: {
      banner: upgradePathOnboardingSocial,
      title: ' Get On-Demand and Automation features',
      actions: ['resourceCollection', 'workoutCollection', 'studioPrograms', 'autoflow', 'multipleOnboarding'],
      buttonAction: { contentTitle: 'Upgrade now', targetPlan: ON_DEMAND_PLANS.studioAndAutomation },
      backgroundLinear: { background: 'linear-gradient(90deg, #26A3B3 0%, #00F5A0 100%)' },
    },
    getOnDemandFeatures: {
      banner: upgradePathOnboardingSocial,
      title: 'Get On-Demand feature',
      actions: ['resourceCollection', 'workoutCollection', 'studioPrograms'],
      buttonAction: { contentTitle: 'Upgrade now', targetPlan: ON_DEMAND_PLANS.studio },
      backgroundLinear: { background: 'linear-gradient(341.54deg, #FDC830 4.59%, #F37335 170.42%)' },
    },
    getStudioAndAutoflow: {
      banner: upgradePathOnboardingSocial,
      title: ' Get Studio Program and Automation features',
      actions: ['studioPrograms', 'autoflow', 'multipleOnboarding'],
      buttonAction: { contentTitle: 'Upgrade now', targetPlan: ON_DEMAND_PLANS.studioAndAutomation },
      backgroundLinear: { background: 'linear-gradient(90deg, #9796F0 0%, #FBC7D4 100%)' },
    },
    getStudioProgram: {
      banner: upgradePathOnboardingSocial,
      title: 'Get Studio Program feature',
      actions: ['studioPrograms'],
      buttonAction: { contentTitle: 'Upgrade now', targetPlan: ON_DEMAND_PLANS.studio },
      backgroundLinear: { background: 'linear-gradient(90deg, #091E3A 0%, #2F80ED 50%, #2D9EE0 100%)' },
    },
    getAutoflow: {
      banner: upgradePathAutoflow,
      title: 'Get Automation features',
      actions: ['autoflow', 'multipleOnboarding'],
      buttonAction: { contentTitle: 'Upgrade now', targetPlan: ON_DEMAND_PLANS.studioAndAutomation },
      backgroundLinear: { background: 'linear-gradient(90deg, #5433FF 0%, #20BDFF 50%, #A5FECB 100%)' },
    },
    getTrial: {
      banner: upgradePathAutoflow,
      title: 'Get Automation features',
      actions: ['multipleOnboarding'],
      buttonAction: { contentTitle: 'Upgrade now', targetPlan: ON_DEMAND_PLANS.proAndAutomation },
      backgroundLinear: { background: 'linear-gradient(90deg, #20D7FF 0%, #B71EFF 100%)' },
    },
    default: {
      banner: null,
      title: '',
      actions: [],
      buttonAction: {},
      backgroundLinear: {},
    },
  };

  const { banner, title, actions, buttonAction, backgroundLinear } = useMemo(() => {
    let config = permissionConfig.default;

    // Case: Trial mode
    if (isTrialMode) {
      config = permissionConfig['getTrial'];
    }

    // Case: Pro plan && dont have on-demand add on && dont have automation add on
    if (!isOndemandAddOn && !isStudioProgram && !isAutomationAddOn) {
      config = permissionConfig['addAutoflowAndOnDemand'];
    }

    // Case: Pro plan && have automation add on && dont have on-demand add on
    if (isAutomationAddOn && !isOndemandAddOn && !isStudioProgram) {
      config = permissionConfig['getOnDemandFeatures'];
    }

    // Case: Pro plan && have on-demand add on && dont have automation add on
    if (isOndemandAddOn && !isAutomationAddOn && !isStudioProgram) {
      config = permissionConfig['getStudioAndAutoflow'];
    }

    // Case: Pro plan && have on-demand add on && have automation add on
    if (isAutomationAddOn && isOndemandAddOn && !isStudioProgram) {
      config = permissionConfig['getStudioProgram'];
    }

    // Case: Studio plan && dont have automation add on
    if (isOndemandAddOn && isStudioProgram && !isAutomationAddOn) {
      config = permissionConfig['getAutoflow'];
    }

    return {
      banner: config.banner,
      title: config.title,
      actions: getActionDescriptions(config.actions),
      buttonAction: config.buttonAction,
      backgroundLinear: config.backgroundLinear,
    };
  }, [permission]);

  return (
    <S.Wrapper>
      <S.ButtonWrapper onClick={() => setOpen(!open)}>
        <ArrowUpIcon className="custom-arrow-icon" />
        <span>Upgrades</span>
      </S.ButtonWrapper>
      {open && (
        <RootCloseWrapper
          onRootClose={() => {
            setOpen(false);
          }}
          event="click"
          disabled={!open}
        >
          <S.TooltipWrapper>
            <S.Header style={backgroundLinear}>
              <S.Banner src={banner} alt="Banner" />
              <S.Title>
                <span>{title}</span>
              </S.Title>
            </S.Header>
            <S.Footer>
              {!isEmpty(actions) &&
                actions.map((item, index) => {
                  const { contentBold, description } = item || {};

                  return (
                    <S.Description key={index}>
                      <S.Bold>{contentBold}</S.Bold> {description}
                    </S.Description>
                  );
                })}
              <S.ButtonUpgradePath text="Upgrade now" planWillSelect={buttonAction.targetPlan} />
            </S.Footer>
          </S.TooltipWrapper>
        </RootCloseWrapper>
      )}
    </S.Wrapper>
  );
};

export default UpgradePathPopup;
