import styled, { css } from 'styled-components';
import WarningIcon from 'assets/icons/warning.svg';

export const FormGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0;
  }

  .label {
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 36px;
    letter-spacing: normal;
    color: #6a778a;
    text-transform: uppercase;
    width: 82px;
  }

  .form-control {
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #323c47;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #dcdcde;
    background-color: #fafbfc;
    flex: 1 1;
    padding: 0 10px;
    line-height: 36px;
  }

  &.assign-to {
    .multi-select-container {
      flex: 1 1;

      .multi-select__value-container {
        padding: 4px 10px;

        &.multi-select__value-container--has-value {
          padding: 2.5px 10px;
        }
      }

      .multi-select__control {
        border: solid 1px #dcdcde;
      }
    }
  }

  &.program {
    .select-program {
      flex: 1 1;
    }

    .evfSelectBoxContainer {
      .evfSelectBox__control {
        .evfSelectBox__value-container {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .evfSelectBox__dropdown-indicator {
        padding-right: 14px;
      }
    }
  }

  &.dayRangeOption {
    .select-program-day {
      flex: 1 1;
      height: 36px;
    }

    .select-program-day__menu {
      max-height: calc(100vh / 2 - 95px);
    }
  }

  &.starting-on {
    .form-control {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }

    .calendar-icon {
      margin-right: 10px;
    }

    .rdt {
      flex: 1 1;

      .rdtPicker {
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
        border: solid 1px #d3d7d9;
        width: 290px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-top: 0;
        padding: 5px 10px 10px;

        .rdtSwitch {
          font-size: 13px;
          font-weight: bold;
          color: #383838;
        }

        .rdtPrev,
        .rdtNext {
          font-size: 28px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgb(56, 56, 56, 0.3);
          display: inline;
          line-height: 20px;
        }

        .dow {
          font-size: 12px;
          font-weight: 600;
          color: #8c8f93;
        }

        .rdtDays {
          thead {
            tr:first-child {
              th {
                padding: 5px 0;
                border: none;
                :hover {
                  background-color: transparent;
                  color: #5c5bbd;
                }
              }
            }
          }
          td {
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #6b6d6f;
            background-color: #fff !important;

            ::before,
            ::after {
              display: none;
            }

            .rdt__custom-day {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              margin: auto;
              line-height: 24px;
            }

            &.rdtDisabled {
              color: #d7d7d7;
            }
            &.rdtActive {
              .rdt__custom-day {
                background-color: #5c58c0;
                color: #fff;
              }
            }
            :not(.rdtDisabled):hover {
              .rdt__custom-day {
                background-color: #edecfc;
                color: #6b6d6f;
              }
            }
          }
        }
      }
    }
  }
`;

export const CustomOptionProgramWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  min-height: 62px;
  cursor: pointer;
  border-bottom: 1px solid #eceff1;

  .sb-avatar__image,
  .sb-avatar__text {
    border-radius: 50%;
    object-fit: cover;
  }

  &.select__option--is-focused {
    background-color: #fafbfc;
  }

  :last-child {
    border-color: transparent;
  }
`;

export const ProgramOptionNameContainer = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #111111;
  flex: 1 1;

  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;

    :last-child {
      margin-bottom: 0;
    }
  }

  .live-sync {
    margin-left: 0px;
  }
`;

export const ProgramOptionOwnerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90px;

  .last-used {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #2a394c;
    margin-right: 10px;
  }
`;

export const DayRangeContainer = styled.div`
  .dayRangeOption--endDay {
    margin-bottom: 20px;
  }

  .select-program-day {
    .text {
      img {
        padding: 5px 3px;
        border: 1px solid transparent;
        border-radius: 4px;
      }
    }
  }

  ${props =>
    props.invalid &&
    css`
      .dayRangeOption--endDay {
        .text {
          color: #ea314a;
          border-color: #ea314a;
          background-color: #fff;

          img {
            border-color: #ea314a;
          }
        }
      }
    `}
`;

export const InvalidEndDate = styled.div`
  background: #fef4f6;
  border-radius: 4px;
  padding: 8px 12px 8px 36px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ff1b1b;
  background-image: url(${WarningIcon});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 10px center;
`;
