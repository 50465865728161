import React, { memo, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';

import {
  createObjectURL,
  getImageDimensionFromUrl,
  getPresignedUploadUrl,
  mediaLog,
  revokeObjectURL,
  setCaretToEnd,
} from 'utils/commonFunction';
import { DIMENSIONS, FORM_DEFAULT_UPLOAD_SETTINGS } from 'components/FormDetails/constants';
import { CONVERSION, FILE_ERRORS, QUESTION_NAME_LIMIT_LENGTH } from 'constants/commonData';
import { validateFiles } from 'utils/validations';
import TextEditable from 'shared/TextEditable';
import FileUpload from 'shared/FileUpload';
import S3Images from 'shared/S3Images';
import MediaPlayer from 'shared/MediaPlayer';
import { ReactComponent as InfoIcon } from 'assets/icons/tooltip-icon.svg';

import * as S from './style';

const FORM_BUILDER_UPLOAD_URL = '/api/file/gen-presigned-urls-form-questionnaires';

const ImageText = props => {
  const {
    workingQuestion,
    idx,
    isSelected,
    updatePartialQuestion,
    allowEdit,
    onScrollTo,
    selectedQuestion,
    loading,
    isDragging = false,
    mouseLeaveRef,
    getIsUploading,
    isPublished,
  } = props;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const storedTitle = get(workingQuestion, 'title', '');
  const storedDescription = get(workingQuestion, 'description', '');
  const questionNameRef = useRef();
  const descriptionRef = useRef();
  const isFocus = useRef(`name-${idx}`);
  const isRequire = get(workingQuestion, 'is_require', false);
  // Upload Image
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [localUrl, setLocalUrl] = useState(null);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  const [scaleImg, setScaleImg] = useState({ scaleWidth: false, scaleHeight: false });

  const attachmentUrl = get(workingQuestion, 'background_image', '');
  const attachmentThumbnailUrl = get(workingQuestion, 'background_image_thumbnail', '');
  const workingQuestionId = get(workingQuestion, '_id', '');

  useEffect(() => {
    setTitle(storedTitle);
    setDescription(storedDescription);
  }, [storedTitle, storedDescription]);

  useEffect(() => {
    if (isSelected) {
      onScrollTo();
      mouseLeaveRef.current = handleOnMouseOutParent;
    }
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && questionNameRef.current && isFocus.current === `name-${idx}`) {
      questionNameRef.current.focus();
      setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
    }
  }, []);

  useEffect(() => {
    getIsUploading && getIsUploading(isUploading);
  }, [isUploading]);

  useEffect(() => {
    setLocalUrl(null);
  }, [workingQuestionId]);

  const handleChangeQuestionName = value => {
    if (!isDragging) {
      const trimmedValue = value.trim();
      if (trimmedValue.length <= QUESTION_NAME_LIMIT_LENGTH) {
        setTitle(trimmedValue);
      } else {
        // handle copy paste case out of chars limit
        if (trimmedValue.length === QUESTION_NAME_LIMIT_LENGTH + 1) {
          setTitle(title.trim().slice(0, QUESTION_NAME_LIMIT_LENGTH));
          questionNameRef.current.focus();
          setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
        } else {
          setTitle(trimmedValue.slice(0, QUESTION_NAME_LIMIT_LENGTH));
        }
      }
    }
  };

  const handleBlurQuestionName = () => {
    if (workingQuestion.title !== title && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        title,
      });
    }
  };

  const handleBlurDescription = () => {
    if (workingQuestion.description !== description && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        description,
      });
    }
  };

  const handleChangeDescription = value => {
    const trimmedValue = value.trim();
    setDescription(trimmedValue);
  };

  const handleFocus = type => () => {
    isFocus.current = type;
  };

  const handleOnMouseOutParent = () => {
    if (questionNameRef.current) {
      const inputRef = get(questionNameRef, 'current.inputRef.current');
      questionNameRef.current.onBlur();
      inputRef && inputRef.blur();
    }
    if (descriptionRef.current) {
      const inputRef = get(descriptionRef, 'current.inputRef.current');
      descriptionRef.current.onBlur();
      inputRef && inputRef.blur();
    }
  };

  const handleOnClickQuestion = () => {
    allowEdit && selectedQuestion && selectedQuestion(workingQuestion);
  };

  const handleSelectFile = async (file, fileDimension) => {
    if (file) {
      const { size, name, type } = file;
      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file to S3',
      });
    }
    // Get presigned url
    const { configs: fileInfo, uploadUrl } = await getPresignedUploadUrl(FORM_BUILDER_UPLOAD_URL, file);
    setIsUploading(true);
    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    });
    fileInfo &&
      updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        background_image: fileInfo.url,
        background_image_thumbnail: fileInfo.thumbnail_url,
        image_width: fileDimension.width,
        image_height: fileDimension.height,
      });
  };

  const handleDropFile = async files => {
    let errorMaxWidth = false;
    if (files.length === 0) {
      return toast.error('Please upload one file at a time');
    }
    if (files.length) {
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: FORM_DEFAULT_UPLOAD_SETTINGS.validateExtensions,
        maxSize: FORM_DEFAULT_UPLOAD_SETTINGS.maxSize * CONVERSION.MB_TO_BYTE,
      });
      if (!error) {
        const objUrl = createObjectURL(file);
        const { width: originalWidth = null } = await getImageDimensionFromUrl(objUrl);
        if (originalWidth < DIMENSIONS.AT_LEAST_WIDTH) {
          errorMaxWidth = true;
        }
      }
      if (error || errorMaxWidth) {
        let message = '';
        if (get(error, 'type') === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = 'File type not supported.';
        } else if (get(error, 'type') === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = `Please upload an image smaller than ${FORM_DEFAULT_UPLOAD_SETTINGS.maxSize}MB`;
        } else if (errorMaxWidth) {
          message = `Png or jpg files. Image width should be at least ${DIMENSIONS.AT_LEAST_WIDTH}px for best resolution.`;
        }
        toast.error(message, { className: errorMaxWidth && 'wider' });
      } else {
        if (localUrl) {
          revokeObjectURL(localUrl);
        }
        const objUrl = createObjectURL(file);
        const newDimension = await getImageDimensionFromUrl(objUrl);
        const newScaleImg = handleScaleImage(newDimension);
        setFile(file);
        setScaleImg(newScaleImg);
        setLocalUrl(objUrl);
        handleSelectFile(file, newDimension);
      }
    }
  };

  const handleUploadSuccess = async () => {
    if (file) {
      const { size, name, type } = file;
      await mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload a file to S3',
      });
      setFile(null);
    }
    setUploadConfigs(null);
    setIsUploading(false);
  };

  const handleCancelUpload = () => {
    setFile(null);
    setLocalUrl(null);
    setUploadConfigs(null);
    setIsUploading(false);
    updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        background_image: null,
        background_image_thumbnail: null,
        image_width: null,
        image_height: null,
      });
  };

  const handleScaleImage = dimension => {
    if (isEmpty(dimension)) {
      return;
    }

    const { width = 0, height = 0 } = dimension;
    const scaleWidth = width > DIMENSIONS.MAX_WIDTH;
    const scaleHeight = height > DIMENSIONS.MAX_HEIGHT && !scaleWidth;

    return { scaleWidth, scaleHeight };
  };

  const renderPhoto = () => {
    const imageUrl = localUrl ? localUrl : attachmentUrl || attachmentThumbnailUrl;
    const { scaleWidth, scaleHeight } = localUrl
      ? scaleImg
      : handleScaleImage({
          width: get(workingQuestion, 'image_width', 0),
          height: get(workingQuestion, 'image_height', 0),
        });

    return (
      <S.ImageTextPreview isScaleWidth={scaleWidth} isScaleHeight={scaleHeight}>
        {isUploading && uploadConfigs ? (
          <>
            <img src={imageUrl} alt="" />
            <FileUpload onSuccess={handleUploadSuccess} onCancel={handleCancelUpload} configs={uploadConfigs} />
          </>
        ) : (
          <>
            <S3Images src={[imageUrl]} onLoadError={handleCancelUpload} />
            {isPublished && !allowEdit && <MediaPlayer url={attachmentUrl || attachmentThumbnailUrl} isVideo={false} />}
          </>
        )}
      </S.ImageTextPreview>
    );
  };

  return (
    <S.ContentContainer isRequire={isRequire} onClick={handleOnClickQuestion}>
      <TextEditable
        ref={questionNameRef}
        className="title"
        value={title}
        placeholder="Enter the title"
        lineHeight={28}
        onFocus={handleFocus(`name-${idx}`)}
        onBlur={handleBlurQuestionName}
        onChange={handleChangeQuestionName}
        maxLength={QUESTION_NAME_LIMIT_LENGTH}
        notRequired
        readOnly={!allowEdit}
      />
      <TextEditable
        ref={descriptionRef}
        className="description"
        value={description}
        placeholder="Description (optional)"
        lineHeight={22}
        onFocus={handleFocus('desc')}
        onBlur={handleBlurDescription}
        onChange={handleChangeDescription}
        notRequired
        breakLine
        readOnly={!allowEdit}
      />
      {allowEdit && (
        <Dropzone onDrop={handleDropFile} multiple={false} disabled={!allowEdit}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <S.ImageTextPlaceholder
                id={`image-text-${workingQuestion._id}`}
                {...getRootProps()}
                isDragging={isDragActive}
                style={attachmentUrl ? { display: 'none' } : {}}
                className={!allowEdit ? 'not-allowed' : ''}
              >
                <S.TooltipWrapper>
                  <InfoIcon data-tip data-for="tooltip--image-text" onClick={e => e.preventDefault()} />
                  <ReactTooltip
                    id="tooltip--image-text"
                    effect="solid"
                    place-="top"
                    className="app-tooltip tooltip-image-text"
                  >
                    <S.TooltipContent>
                      Png or jpg files. Image width should be at least 335px for best resolution.
                    </S.TooltipContent>
                  </ReactTooltip>
                </S.TooltipWrapper>
                <S.ImageTextIcon />
                <S.ImageTextContent>
                  Drag and drop your image here
                  <br /> or&nbsp;<span className="custom-image-text">Choose a file</span>
                </S.ImageTextContent>
                <input {...getInputProps()} type="file" accept={FORM_DEFAULT_UPLOAD_SETTINGS.acceptedImage} />
              </S.ImageTextPlaceholder>
            );
          }}
        </Dropzone>
      )}
      {attachmentUrl && renderPhoto()}
    </S.ContentContainer>
  );
};

export default memo(ImageText);
