import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import ResourceDropdownOptions from '../ResourceDropdownOptions';
import { SOCIAL_STRING, TASK_TYPES, TEAM_SHARE_SHARED } from 'constants/commonData';
import { ShareOwnerAvatar } from 'shared/AssetsShareSetting';
import { convertS3UrlToCloudFrontUrl, getStatusCodeUrl } from 'utils/commonFunction';
import { getPageMetaDataFromURL } from 'utils/commonRequest';

import * as S from './style';

function getAvatarClass(resource) {
  const type = resource.link_type || resource.document_type;
  switch (type) {
    case 'youtube':
    case 'vimeo':
    case 'instagram':
    case 'twitter':
    case 'facebook':
    case 'spotify':
    case 'document':
      return type;
    case 'pdf':
      return 'document';
    case 'form':
      return 'form';
    default:
      return 'link';
  }
}

const ResourceItem = props => {
  const { resource, cloudfrontList, user } = props;
  const [coverImage, setCover] = useState(resource.cover_image || '');
  const isArchivedForm = resource.source_type === TASK_TYPES.FORM && get(resource, 'form.is_archived', false);
  const author = typeof resource.author_info === 'object' ? resource.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isShare = resource.share === TEAM_SHARE_SHARED;

  const resourceDescription = useMemo(() => {
    const documentName = get(resource, 'document_name');
    const link = get(resource, 'link');
    const formName = get(resource, 'form.name');
    const regex = /^(https:\/\/)$/g;
    if (!isEmpty(documentName)) {
      return documentName;
    } else if (!isEmpty(link) && !regex.test(link)) {
      return link;
    } else if (!isEmpty(formName)) {
      return formName;
    }
    return '';
  }, [resource]);

  useEffect(() => {
    getPresignedURLs([resource.cover_image]);
  }, []);

  const getPresignedURLs = async list => {
    try {
      const imageCloudUrl = convertS3UrlToCloudFrontUrl(resource.cover_image, cloudfrontList);
      if (imageCloudUrl) {
        setCover(imageCloudUrl);
      } else {
        const statusCodeExistedThumbnail = await getStatusCodeUrl(list[0]);
        if (
          statusCodeExistedThumbnail !== 200 &&
          list[0] &&
          (getAvatarClass(resource) === SOCIAL_STRING.instagram || resource.link.includes(SOCIAL_STRING.instagram))
        ) {
          const metaDataFromURL = await getPageMetaDataFromURL(resource.link);
          const { data: { data: { image = '' } = {} } = {} } = metaDataFromURL;

          setCover(image);
        } else {
          const response = await props.getS3presignedURL(list);
          setCover(response[0]);
        }
      }
    } catch {}
  };

  return (
    <S.Wrapper onClick={() => props.onGoDetail({ ...resource, cover_image: coverImage })}>
      <S.ResourceAvatar coverImage={coverImage} className={getAvatarClass(resource)} />
      <S.ResourceInfo isArchivedForm={isArchivedForm}>
        <div className="resourceInfo__name">{resource.name}</div>
        <div className="resourceInfo__wrapper">
          <div className="resourceInfo__description">{resourceDescription}</div>
          {isArchivedForm && <div className="resourceInfo__archived">Archived</div>}
        </div>
      </S.ResourceInfo>
      <S.ResourceActionsWrapper>
        <ResourceDropdownOptions studioResource={resource} className="resourceInfo__options" />
        {author && !isCreator && isShare && (
          <ShareOwnerAvatar
            user={author}
            tooltipId={`avatar-tooltip-${get(resource, '_id')}`}
            cloudfrontList={cloudfrontList}
          />
        )}
      </S.ResourceActionsWrapper>
    </S.Wrapper>
  );
};

const mapStateToProps = state => ({
  cloudfrontList: state.cloudfrontList,
  user: state.user,
});

const mapDispatchToProps = {
  getS3presignedURL: getS3presignedURLFromLocalDatabase,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceItem);
