import { connect } from 'react-redux';
import ExerciseHistoryRecord from './component';
import { getExerciseHistoryEntries } from 'actions/client';

const mapStateToProps = state => {
  const { user, rootReducer, bodyMetric } = state;
  const selectedClient = rootReducer.client.workingClientDetail;
  return {
    timezone: user.timezone,
    clientId: rootReducer.client.selected,
    name: bodyMetric.name,
    unit: bodyMetric.unit,
    unitId: bodyMetric.unitId,
    fields: rootReducer.exercise.fields,
    unit: selectedClient ? selectedClient.preferences : user.preferences,
    bodymetricTypes: rootReducer.client.bodymetricTypes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getExerciseHistoryEntries: data => dispatch(getExerciseHistoryEntries(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseHistoryRecord);
