import { connect } from 'react-redux';
import { getBodyMetricTargetOverview, getMetricSettingsOverview, updateSleepView } from 'actions/bodyMetric';
import BodyMetricChartOverviewNew from './component';
import { getChartDataLatestByDay, getSleepChartDataLatestByDay } from 'redux/client/client.actionCreators';
import get from 'lodash/get';
import { getHeartRateChartDataOverview } from 'redux/heart-rate/action';

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      client: { workingClientDetail, bodymetricTypes },
      customBranding,
    },
  } = state;

  return {
    clientId: workingClientDetail && workingClientDetail._id,
    date_format: user.date_format,
    bodymetricTypes,
    customBrandingColor: get(customBranding, 'originalTheme.primaryColor', ''),
  };
};

const mapDispatchToProps = {
  getBodyMetricTargetOverview,
  getMetricSettingsOverview,
  getChartDataLatestByDay,
  getHeartRateChartDataOverview,
  getSleepChartDataLatestByDay,
  updateSleepView,
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyMetricChartOverviewNew);
