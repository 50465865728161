import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Home from './Home';
import UpgradePath from 'shared/UpgradePath';

const HomeLayout = ({ component: Component, upgradePath, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <Home {...rest}>
            <UpgradePath pathName={upgradePath}>
              <Component {...matchProps} isMP={rest.isMP} />
            </UpgradePath>
          </Home>
        );
      }}
    />
  );
};

export default HomeLayout;
