import { connect } from 'react-redux';
import ExerciseFieldsSelectBox from './component';

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    systemFields: rootReducer.exercise.fields,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseFieldsSelectBox);
