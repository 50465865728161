import styled from 'styled-components';

const textBase = `
    color: #202353;
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 150%;
`;

export const RecipeHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RecipeHeader = styled.div`
  ${textBase}
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.2px;
`;

export const RecipeStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid ${props => (props.isPublished ? '#65cc9c' : '#e1e1ea')};
  background: ${props => (props.isPublished ? '#e8fff4' : '#f0f0f2')};
  user-select: none;
`;

export const RecipeLabel = styled.div`
  ${textBase}
  color: ${props => (props.isPublished ? '#0b7c47' : '#202353')};
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
`;
