import styled from 'styled-components';
import ButtonIcon from 'shared/ButtonIcon';
import { ReactComponent as AddFirstIcon } from 'assets/icons/add-first-on-demand-workout.svg';

export const EmptyList = styled.div`
  height: calc(100% - 110px);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .text {
    margin-top: 20px;
    color: #9a9da6;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
  }
`;

export const CustomAddFirstIcon = styled(AddFirstIcon)`
  width: 76px;
  height: 69px;
`;

export const Button = styled(ButtonIcon)`
  margin-top: 30px;
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }
`;
