import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { ModalWrapper, Description, Mark } from './style';
import { turnOffAutoflowSetting } from 'redux/autoflow/actions';
import { CDN_URL } from 'constants/commonData';

function ConfirmTurnOff({ autoflow, onClose, onSubmit }) {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    onSubmit(autoflow)
      .then(() => {
        setSubmitting(false);
        onClose();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalLayout.Wrapper>
          <ModalLayout.Header>Are you sure?</ModalLayout.Header>
          <ModalLayout.Content>
            <Description>
              The Autoflow will lose the ability to sync settings with clients. But after the change, you will be able
              to add this Autoflow to any client.
            </Description>
            <Mark>This change will be permanent.</Mark>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button onClick={onClose}>Cancel</Button>
            <Button purple onClick={handleSubmit} delete disabled={submitting}>
              I understand
            </Button>
          </ModalLayout.Actions>
        </ModalLayout.Wrapper>
      </Modal.Content>
    </ModalWrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
      },
    },
  } = state;
  return { autoflow: workingAutoflow._id };
};

export default connect(mapState, { onSubmit: turnOffAutoflowSetting })(ConfirmTurnOff);
