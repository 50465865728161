import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { PURCHASE_VALUE } from 'components/Package/PackageDetail/Settings/constants';
import Request from 'configs/request';
import { CONTACT_STATUS } from 'components/Package/PackageDetail/ContactList/constants';
import { cancelPreviousRequest } from 'utils/commonRequest';
import { updateTotalOfferPackageSetting } from 'redux/package-detail/actions';
import { getClientWord } from 'components/Package/components/common';
import { STATUS_IS_NOT_NO_RESPONSE, UNDO_REJECTED_ERROR } from 'constants/commonData';

const CancelToken = axios.CancelToken;
let cancelRequests = [];

export const Types = {
  CONTACT_LIST_RESET_STATE: 'CONTACT_LIST_RESET_STATE',
  CONTACT_LIST_GET_LIST_REQUEST: 'CONTACT_LIST_GET_LIST_REQUEST',
  CONTACT_LIST_GET_LIST_SUCCESS: 'CONTACT_LIST_GET_LIST_SUCCESS',
  CONTACT_LIST_GET_LIST_FAILED: 'CONTACT_LIST_GET_LIST_FAILED',
  CONTACT_LIST_CHANGE_QUERY_PARAMS: 'CONTACT_LIST_CHANGE_QUERY_PARAMS',
  CONTACT_LIST_APPROVE_REQUEST: 'CONTACT_LIST_APPROVE_REQUEST',
  CONTACT_LIST_APPROVE_SUCCESS: 'CONTACT_LIST_APPROVE_SUCCESS',
  CONTACT_LIST_APPROVE_FAILED: 'CONTACT_LIST_APPROVE_FAILED',
  CONTACT_LIST_REJECT_REQUEST: 'CONTACT_LIST_REJECT_REQUEST',
  CONTACT_LIST_REJECT_SUCCESS: 'CONTACT_LIST_REJECT_SUCCESS',
  CONTACT_LIST_REJECT_FAILED: 'CONTACT_LIST_REJECT_FAILED',
  CONTACT_LIST_SELECT_CLIENT_ID: 'CONTACT_LIST_SELECT_CLIENT_ID',
  CONTACT_LIST_SELECT_CLIENT_IDS: 'CONTACT_LIST_SELECT_CLIENT_IDS',
  CONTACT_LIST_MARKED_READ_MESSAGE: 'CONTACT_LIST_MARKED_READ_MESSAGE',
  CONTACT_LIST_UNDO_REQUEST: 'CONTACT_LIST_UNDO_REQUEST',
  CONTACT_LIST_UNDO_SUCCESS: 'CONTACT_LIST_UNDO_SUCCESS',
  CONTACT_LIST_UNDO_FAILED: 'CONTACT_LIST_UNDO_FAILED',
  CONTACT_LIST_SURVEY_SUBMISSION_REQUEST: 'CONTACT_LIST_SURVEY_SUBMISSION_REQUEST',
  CONTACT_LIST_SURVEY_SUBMISSION_SUCCESS: 'CONTACT_LIST_SURVEY_SUBMISSION_SUCCESS',
  CONTACT_LIST_SURVEY_SUBMISSION_FAILED: 'CONTACT_LIST_SURVEY_SUBMISSION_FAILED',
  CONTACT_LIST_RESEND_REQUEST: 'CONTACT_LIST_RESEND_REQUEST',
  CONTACT_LIST_RESEND_SUCCESS: 'CONTACT_LIST_RESEND_SUCCESS',
  CONTACT_LIST_RESEND_FAILED: 'CONTACT_LIST_RESEND_FAILED',
};

export const resetContactListState = () => {
  return { type: Types.CONTACT_LIST_RESET_STATE };
};

export const selectedClientIdsContactList = clientIds => {
  return { type: Types.CONTACT_LIST_SELECT_CLIENT_IDS, payload: { clientIds } };
};

export const selectedClientIdContactList = clientId => {
  return { type: Types.CONTACT_LIST_SELECT_CLIENT_ID, payload: { clientId } };
};

export const changeQueryParams = (params, isLoading = true, isCancelRequest = false) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        packageDetail,
        contactList: { queryParam },
      },
    } = getState();
    const workingPackage = packageDetail.get('workingPackage').toJS();
    const packageId = get(workingPackage, 'id', null);

    const newParams = { ...queryParam, ...params };

    dispatch({
      type: Types.CONTACT_LIST_CHANGE_QUERY_PARAMS,
      payload: { data: newParams },
    });
    dispatch(getContactList(packageId, newParams, isLoading, isCancelRequest));
  };
};

export const getContactList = (packageId, params, isLoading = true, isCancelRequest = false) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        contactList: { queryParam },
      },
    } = getState();
    if (!packageId) return;

    const queryParams = params || queryParam;
    const newQueryParams = {
      ...queryParams,
      type: PURCHASE_VALUE.CONTACT,
      package_id: packageId,
    };
    if (isLoading) {
      dispatch({ type: Types.CONTACT_LIST_GET_LIST_REQUEST });
    }

    cancelPreviousRequest(isCancelRequest, cancelRequests);

    return dispatch(
      Request.get(
        {
          url: '/api/payment/package-wishlists/trainer-get-list',
          params: newQueryParams,
          cancelToken: new CancelToken(cancelRequest => {
            cancelRequests.push(cancelRequest);
          }),
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;

          cancelRequests = [];

          return dispatch({
            type: Types.CONTACT_LIST_GET_LIST_SUCCESS,
            payload: { data },
          });
        },
        () => {
          dispatch({ type: Types.CONTACT_LIST_GET_LIST_FAILED });
        },
      ),
    );
  };
};

export const approveRequestContactList = (packageId, { callbackSuccess, callbackFailure }) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        contactList: { clientIdSelected, clientIdsSelected },
      },
    } = getState();

    const ids = !isEmpty(clientIdSelected) ? clientIdSelected : clientIdsSelected;
    const data = { package_id: packageId, ids };
    const trainer_info = pick(user, ['color', 'avatar', 'first_name', 'last_name']);

    dispatch({ type: Types.CONTACT_LIST_APPROVE_REQUEST });

    return dispatch(
      Request.post(
        { url: '/api/payment/package-wishlists/approve', data },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          const processIds = get(data, 'processIds', []);
          const notProcessIds = get(data, 'notProcessIds', []);
          const countProcessClientSuccess = get(processIds, 'length', 0);
          const clientWord = getClientWord(countProcessClientSuccess);
          const message = `You have offered this package to ${countProcessClientSuccess} ${clientWord}.`;
          callbackSuccess(message);
          dispatch(updateTotalOfferPackageSetting(get(processIds, 'length', 0)));
          dispatch({
            type: Types.CONTACT_LIST_APPROVE_SUCCESS,
            payload: { ids: [...processIds, ...notProcessIds], trainer_info },
          });
          if (notProcessIds.length) {
            dispatch(getContactList(packageId));
          }
        },
        error => {
          const errorCode = get(error, 'response.data.errorCode', '');
          callbackFailure(errorCode);
          dispatch({ type: Types.CONTACT_LIST_APPROVE_FAILED });
        },
        false,
      ),
    );
  };
};

export const rejectRequestContactList = (params, { callbackSuccess, callbackFailure }) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        contactList: { clientIdSelected, clientIdsSelected },
      },
    } = getState();
    const { rejectedOption, ...restParams } = params;
    const reject_with_consultation = rejectedOption === CONTACT_STATUS.REJECTED_BY_COACH_AFTER_CONSULTATION;

    const ids = !isEmpty(clientIdSelected) ? clientIdSelected : clientIdsSelected;
    const data = { ...restParams, ids, reject_with_consultation };
    const trainer_info = pick(user, ['color', 'avatar', 'first_name', 'last_name']);

    dispatch({ type: Types.CONTACT_LIST_REJECT_REQUEST });

    return dispatch(
      Request.post(
        { url: '/api/payment/package-wishlists/trainer-reject', data },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          const processIds = get(data, 'processIds', []);
          const notProcessIds = get(data, 'notProcessIds', []);
          const countProcessClientSuccess = get(processIds, 'length', 0);
          const clientWord = getClientWord(countProcessClientSuccess);
          const message = `You have declined the purchase request from ${countProcessClientSuccess} ${clientWord}.`;
          callbackSuccess(message);
          dispatch({
            type: Types.CONTACT_LIST_REJECT_SUCCESS,
            payload: { ids: [...processIds, ...notProcessIds], option: rejectedOption, trainer_info },
          });
          if (notProcessIds.length) {
            dispatch(getContactList(params.package_id));
          }
        },
        error => {
          callbackFailure(get(error, 'response.data', {}));
          dispatch({ type: Types.CONTACT_LIST_REJECT_FAILED });
        },
        false,
      ),
    );
  };
};

export const updateMarkedReadMessage = requestId => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: `/api/payment/package-wishlists/${requestId}/mark-as-read` },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          return dispatch({
            type: Types.CONTACT_LIST_MARKED_READ_MESSAGE,
            payload: { requestId: data.id },
          });
        },
      ),
    );
  };
};

export const undoRequestContactList = (id, { callbackSuccess, callbackFailure }) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { packageDetail },
    } = getState();
    const workingPackage = packageDetail.get('workingPackage').toJS();
    const packageId = get(workingPackage, 'id', null);

    dispatch({ type: Types.CONTACT_LIST_UNDO_REQUEST });

    return dispatch(
      Request.post(
        { url: `/api/payment/package-wishlists/${id}/undo-reject` },
        true,
        (response, { dispatch }) => {
          const { data } = response;
          const message = 'You have reverted the client request\nsuccessfully';
          callbackSuccess(message);
          return dispatch({
            type: Types.CONTACT_LIST_UNDO_SUCCESS,
            payload: data,
          });
        },
        error => {
          const errorCode = get(error, 'response.data.errorCode', '');
          if (errorCode === UNDO_REJECTED_ERROR) {
            dispatch(getContactList(packageId));
          }
          callbackFailure(errorCode);
          dispatch({ type: Types.CONTACT_LIST_UNDO_FAILED });
        },
        false,
      ),
    );
  };
};

export const getListSurveySubmission = id => {
  return dispatch => {
    dispatch({ type: Types.CONTACT_LIST_SURVEY_SUBMISSION_REQUEST });

    return dispatch(
      Request.get(
        { url: `/api/payment/survey_client_submissions/${id}` },
        true,
        (response, { dispatch }) => {
          const { data } = response;
          return dispatch({
            type: Types.CONTACT_LIST_SURVEY_SUBMISSION_SUCCESS,
            payload: data,
          });
        },
        () => {
          dispatch({ type: Types.CONTACT_LIST_SURVEY_SUBMISSION_FAILED });
        },
      ),
    );
  };
};

export const resendRequestContactList = (id, { callbackSuccess, callbackFailure }) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: { packageDetail },
    } = getState();

    const trainer_info = pick(user, ['color', 'avatar', 'first_name', 'last_name']);
    const workingPackage = packageDetail.get('workingPackage').toJS();
    const packageId = get(workingPackage, 'id', null);

    dispatch({ type: Types.CONTACT_LIST_RESEND_REQUEST });

    return dispatch(
      Request.post(
        { url: `/api/payment/package-wishlists/${id}/resend-offer` },
        true,
        (response, { dispatch }) => {
          const message = 'You have resent the offer.';
          callbackSuccess(message);
          return dispatch({
            type: Types.CONTACT_LIST_RESEND_SUCCESS,
            payload: { id, trainer_info },
          });
        },
        error => {
          const errorCode = get(error, 'response.data.errorCode', '');
          if (errorCode === STATUS_IS_NOT_NO_RESPONSE) {
            dispatch(getContactList(packageId));
          }
          callbackFailure(errorCode);
          dispatch({ type: Types.CONTACT_LIST_RESEND_FAILED });
        },
        false,
      ),
    );
  };
};
