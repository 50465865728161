import React from 'react';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

export default props => {
  return (
    <Container {...props} share={props.share} className="teammate-status">
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-tip
        data-for={`teammate-status__tooltip-${props.itemId}`}
      >
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EAEEF1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0001 8.83333C18.0001 9.84586 17.1793 10.6667 16.1667 10.6667C15.5279 10.6667 14.9654 10.3399 14.6372 9.84442L10.497 11.7286C10.6059 11.9631 10.6667 12.2244 10.6667 12.5C10.6667 12.815 10.5872 13.1114 10.4473 13.3704L13.3434 15.0425C13.6788 14.611 14.2029 14.3333 14.7917 14.3333C15.8043 14.3333 16.6251 15.1541 16.6251 16.1667C16.6251 17.1792 15.8043 18 14.7917 18C13.7792 18 12.9584 17.1792 12.9584 16.1667C12.9584 15.9072 13.0123 15.6603 13.1095 15.4366L10.1792 13.7449C9.84435 14.1068 9.3653 14.3333 8.83333 14.3333C7.82081 14.3333 7 13.5125 7 12.5C7 11.4875 7.82081 10.6667 8.83333 10.6667C9.40439 10.6667 9.91446 10.9278 10.2507 11.3371L14.4339 9.43338C14.3688 9.24537 14.3334 9.04348 14.3334 8.83333C14.3334 7.82081 15.1542 7 16.1667 7C17.1793 7 18.0001 7.82081 18.0001 8.83333Z"
          fill="#fff"
        />
      </svg>
      <ReactTooltip
        id={`teammate-status__tooltip-${props.itemId}`}
        effect="solid"
        place={props.place || 'left'}
        className={classNames('app-tooltip', { 'is-place': props.place })}
      >
        <span>{props.share ? 'Shared' : 'Private'}</span>
      </ReactTooltip>
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  cursor: pointer;

  .__react_component_tooltip.app-tooltip {
    width: 85px;
    margin-left: -8px;

    ::after {
      right: -4px;
    }

    &.is-place {
      width: 59px;
      height: 36px;
      font-size: 13px;
      padding: 8px;
      line-height: 19.5px;
      font-weight: 400;
      margin-left: 0px;
      background-color: #2d2e2d;
    }
  }

  > * {
    vertical-align: middle;
  }

  ${props =>
    props.share &&
    css`
      svg {
        circle {
          fill: #f0f1ff;
        }

        path {
          fill: #5158cf;
        }
      }
    `}
`;
