import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isArray from 'lodash/isArray';

import Header from './components/Header';
import Footer from './components/Footer';
import Item from './components/Item';

import * as S from './style';
import {
  getClientsDocuments,
  changeQueryParams,
  getOnboardingflowForm,
  resetChangeQueryParams,
} from 'redux/form-documents/actions';
import LoadingIndicator from 'shared/LoadingIndicator';
import { PER_PAGE_DOCUMENTS } from 'redux/form-documents/reducers';
import { ReactComponent as EmptyUI } from 'assets/images/empty_form_documents.svg';
import ResponsesFormModal from './components/ResponsesModal';
import { toggleModal } from 'actions/modal';

const FormDocuments = props => {
  const {
    match: {
      params: { clientId },
    },
    getClientsDocuments,
    changeQueryParams,
    list,
    loading,
    query,
    timezone,
    sideBarVisible,
    toggleModal,
    resetChangeQueryParams,
    getOnboardingflowForm,
    onboardingFormList,
    date_format,
  } = props;

  const newList = useMemo(() => {
    let arrList = [];
    if (isArray(list) && isArray(onboardingFormList)) {
      arrList = [...list, ...onboardingFormList];
    }
    if (arrList.length > query.per_page) {
      arrList = [...arrList.slice(0, query.per_page)];
    }
    return arrList;
  }, [list, onboardingFormList]);

  useEffect(() => {
    if (clientId && getClientsDocuments) {
      getClientsDocuments(clientId).finally(() => {
        if (clientId && getOnboardingflowForm) {
          getOnboardingflowForm(clientId);
        }
      });
    }
    return () => {
      toggleModal(false);
      resetChangeQueryParams && resetChangeQueryParams();
    };
  }, []);

  const isOnePage = useMemo(() => {
    return query.total < PER_PAGE_DOCUMENTS;
  }, [query.total]);

  const handleOpen = data => {
    toggleModal(
      true,
      <ResponsesFormModal
        onClose={() => toggleModal(false)}
        data={data}
        date_format={date_format}
        timezone={timezone}
      />,
    );
  };

  return (
    <>
      <S.DocumentWrapper sideBarVisible={sideBarVisible}>
        {newList.length ? <Header total={query.total} /> : null}
        <S.ListWrapper isOnePage={isOnePage}>
          {loading && <LoadingIndicator />}
          {!loading && !newList.length && (
            <S.EmptyUI>
              <EmptyUI />
            </S.EmptyUI>
          )}
          {!loading &&
            newList.map(it => (
              <Item key={it._id} item={it} timezone={timezone} clientId={clientId} onOpen={handleOpen} />
            ))}
        </S.ListWrapper>
        {!isOnePage && (
          <Footer query={query} total={query.total} changeQueryParams={changeQueryParams} clientId={clientId} />
        )}
      </S.DocumentWrapper>
    </>
  );
};

const mapStateToProps = state => {
  const {
    user: { timezone, date_format },
    rootReducer: {
      formDocuments: { list, query, loading, onboardingFormList },
    },
    sideBarVisible,
  } = state;
  return {
    list,
    query,
    loading,
    sideBarVisible,
    timezone,
    onboardingFormList,
    date_format,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getClientsDocuments: bindActionCreators(getClientsDocuments, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resetChangeQueryParams: bindActionCreators(resetChangeQueryParams, dispatch),
    getOnboardingflowForm: bindActionCreators(getOnboardingflowForm, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDocuments);
