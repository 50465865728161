import styled, { css } from 'styled-components';
import Button from 'shared/FormControl/Button';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export const Arrow = styled.div`
  flex: 0 0 16px;
  width: 16px;
  height: 10px;
  margin: 0 20px;
  background: url(${CDN_URL}/images/arrow_long_right.svg) no-repeat center center;
  background-size: auto;
  margin-top: 20px;
`;

export const FormGroup = styled.div`
  label {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #777c8f;
    margin-bottom: 6px;
    text-transform: uppercase;
    margin-bottom: 6px;
    display: block;
  }

  .inputContainer {
  }

  &.datePicker {
    margin-right: 5px;

    .custom-date-input {
      padding: 10px 15px;
      background-color: #fff;
      border: 1px solid #dadfea;
      border-radius: 4px;

      > span {
        display: flex;
        color: #202353;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        align-items: center;
      }
    }
  }

  &.timePicker {
    input {
      border: 1px solid #dadfea;
      border-radius: 4px;
      padding: 10px 15px;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      box-shadow: none;
      outline: none;

      :hover,
      :focus {
        border-color: #726ee4 !important;
      }
    }
  }
`;

export const DateTimeGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DateTimePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  margin-top: 0;
  padding-bottom: 16px;
  flex: 0 0 auto;
  border-bottom: 1px solid #dadfea;

  ${FormGroup} {
    ${props =>
      props.tracking &&
      css`
        .custom-date-input {
          width: 150px;
          min-width: auto;
          padding: 9px 15px !important;

          &:hover,
          &:focus {
            border-color: #726ee4 !important;
          }
        }

        &.timePicker {
          input {
            padding: 9px 15px !important;
          }
        }
      `}
  }

  ${Arrow} {
    ${props =>
      props.tracking &&
      css`
        margin: 0 16px;
        margin-top: 20px;
      `}
  }
`;

export const GeneralButton = styled(Button)`
  padding: 6px 35px;
  ${props =>
    props.purple &&
    css`
      :hover {
        background: #5559ff;
      }
    `}
`;

export const ButtonArea = styled.div`
  button {
    margin-right: 5px;
    max-width: 100px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const MarkAllLabel = styled.span`
  color: #758094;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const MarkAll = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${MarkAllLabel} {
    margin-left: 6px;
  }
`;

export const CancelContentPopup = styled.div`
  .text {
    color: #323c47;
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    margin-bottom: 21px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DiscardChangeModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 484px !important;
  }

  .confirm-content-header {
    padding: 30px 30px 15px 30px !important;
  }

  .confirm-content-body {
    padding: 0 30px 10px 30px !important;
  }

  .confirm-actions {
    padding: 20px 30px 30px 30px !important;

    button {
      min-width: 99px !important;
      box-shadow: none !important;
      border-radius: 5px !important;
    }

    .confirm-yes-button {
      &:hover {
        background-color: #ea314a;
        box-shadow: 0 5px 14px -2px rgb(255 101 98 / 37%) !important;
      }
    }
  }

  .close-button {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const WorkoutContainer = styled.div`
  display: flex;
`;

export const WorkoutContent = styled.div`
  background: white;
  width: 604px;
  position: relative;
  border-radius: 8px;
`;

export const CommentContainer = styled.div`
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const CommentToggle = styled.div`
  background-color: #fff;
  background-image: url(${CDN_URL}/images/comment_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-bottom: 15px;

  :hover {
    background-image: url(${CDN_URL}/images/comment_hover_purple_icon.svg);
  }

  ${props =>
    props.showing &&
    css`
      background-color: #5158cf;
      background-image: url(${CDN_URL}/images/comment_hover_icon.svg);

      :hover {
        background-image: url(${CDN_URL}/images/comment_hover_icon.svg);
      }
    `}
`;

export const CommentsWrapper = styled.div`
  height: 100%;
  max-height: calc(100vh - 172px);

  > div:first-child {
    width: 345px;
    height: 100%;
    border-radius: 8px;
  }
`;

export const WorkoutDescription = styled.div`
  /* TODO: max-width: 424px; */
  padding: 5px 0 20px;

  h5 {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #202353;
    opacity: 0.4;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    margin-top: 7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #202353;
    white-space: pre-wrap;
    word-break: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    &:focus {
      -webkit-box-orient: initial;
      display: block;
      -webkit-line-clamp: initial;
      overflow: initial;
      outline: 0;
    }
  }
`;
