import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Chart, Bar } from 'react-chartjs-2';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

// import containers
// import icons
import * as S from './style';
import { extendChartBorderRadius } from '../helper';
import GoalLine from '../GoalLine';

const caloriesTypes = ['protein', 'carbs', 'fat'];
const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
const daysOfWeekExpand = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const datasetsDefault = {
  protein: {
    type: 'bar',
    label: 'Protein',
    backgroundColor: '#57B5EA',
    borderColor: '#ffffff',
    hoverBorderColor: '#ffffff',
    borderSkipped: 'bottom',
    hoverBorderWidth: 2,
    borderWidth: 2,
    data: [],
  },
  carbs: {
    type: 'bar',
    label: 'Carbs',
    backgroundColor: '#65CC9C',
    borderColor: '#ffffff',
    hoverBorderColor: '#ffffff',
    borderSkipped: 'bottom',
    hoverBorderWidth: 2,
    borderWidth: 2,
    data: [],
  },
  fat: {
    type: 'bar',
    label: 'Fat',
    backgroundColor: '#FFBE49',
    borderColor: '#ffffff',
    hoverBorderColor: '#ffffff',
    borderSkipped: 'bottom',
    hoverBorderWidth: 2,
    borderWidth: 2,
    data: [],
  },
};

const initChartData = (reportData, weekCalendar, types) => {
  const { startDate, endDate } = weekCalendar;
  const days = endDate.diff(startDate, 'days') > 6 ? 14 : 7;
  const reportDataByDay = _.mapKeys(reportData.meals, value => value.day);

  const labels = [];

  const datasets = _.pick(_.cloneDeep(datasetsDefault), types);
  _.range(0, days).forEach(day => {
    const thisDate = moment(startDate).add(day, 'd');
    const thisDay = moment(thisDate).format('MM-DD-YYYY');
    let label = moment(thisDate, 'MM-DD-YYYY').format('DD');
    if (day === 0 || day === _.last(_.range(0, days))) {
      label = _.upperCase(moment(thisDate, 'MM-DD-YYYY').format('ddd DD'));
    }
    labels.push(label);

    _.forEach(types, type => {
      datasets[type].data.push(_.round(_.get(reportDataByDay, [thisDay, 'value', `${type}_calories`], 0), 0));
    });
  });

  return {
    labels,
    datasets: _.values(datasets),
  };
};

const getGoalValue = (reportData, viewType, goalType) => {
  const foundGoal = _.find(reportData.goals, g => g.type === goalType);
  if (viewType === 'all') {
    return _.round(_.get(foundGoal, ['value', 'calories'], 0), 0);
  } else {
    if (viewType === 'fat') return _.round(_.get(foundGoal, ['value', viewType], 0) * 9);
    return _.round(_.get(foundGoal, ['value', viewType], 0) * 4);
  }
};

const getGoalsValue = (reportData, viewType, goalType) => {
  const items = _.filter(reportData.multiGoals, g => g.type === goalType);

  const points = [];
  items.forEach(it => {
    if (viewType === 'all') {
      points.push(_.round(_.get(it, ['value', 'calories'], 0), 0));
    } else if (viewType === 'fat') {
      points.push(_.round(_.get(it, ['value', viewType], 0) * 9));
    } else {
      points.push(_.round(_.get(it, ['value', viewType], 0) * 4));
    }
  });
  return points;
};

class DailyCaloriesChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.goalRef = React.createRef();
    this.goalRestDayRef = React.createRef();
    this.dayRef = {};
    this.state = {
      baseChartInfo: {
        limit: 0,
        width: 0,
        height: 0,
        xPositions: [],
      },
    };
  }

  componentDidMount() {
    this.handlePositionAnnotations();
    this.handlePositionTooltip();
  }

  UNSAFE_componentWillMount() {
    Chart.helpers.extend(Chart.elements.Rectangle.prototype, extendChartBorderRadius());
  }

  componentDidUpdate(prevProps, prevState) {
    this.handlePositionAnnotations();
    this.handlePositionTooltip();
    this.getChartInformation();
  }

  getChartInformation = () => {
    const { viewSize } = this.props;
    if (this.chartRef.current) {
      const { chartInstance } = this.chartRef.current;

      if (_.get(chartInstance, 'scales.y-axis-0.max', 0) !== _.get(this.state, 'baseChartInfo.limit')) {
        let positions = [];
        const items = viewSize === 'last14' ? daysOfWeekExpand : daysOfWeek;

        items.forEach((it, index) => {
          const x = _.get(chartInstance.getDatasetMeta(0).data[index], '_model.x', 0);
          positions.push(x);
        });

        const info = {
          limit: _.get(chartInstance, 'scales.y-axis-0.max', 1),
          height: _.get(chartInstance, 'height'),
          width: _.get(chartInstance, 'width'),
          xPositions: positions,
        };
        this.setState({
          baseChartInfo: info,
        });
      }
    }
  };

  handlePositionAnnotations = () => {
    if (this.chartRef.current) {
      const { reportData, viewType } = this.props;
      const trainingGoal = getGoalValue(reportData, viewType, 'training_day');
      const restDayGoal = getGoalValue(reportData, viewType, 'rest_day');
      const { chartInstance } = this.chartRef.current;
      const maxValue = chartInstance.scales['y-axis-0'].end;
      const heighYAxis = chartInstance.scales['y-axis-0'].height;

      let top;
      if (this.goalRef.current) {
        top = heighYAxis - (trainingGoal / maxValue) * heighYAxis;
        this.goalRef.current.style.top = viewType === 'all' ? `${top - 5}px` : `${top}px`;
      }
      if (this.goalRestDayRef.current) {
        const topRestDay = (heighYAxis / maxValue) * (maxValue - restDayGoal) - 2;
        this.goalRestDayRef.current.style.top = `${topRestDay}px`;
        if (Math.abs(topRestDay - top) < 12) {
          if (top <= topRestDay) {
            this.goalRef.current.style.top = `${top - (12 - Math.abs(topRestDay - top)) / 2}px`;
            this.goalRestDayRef.current.style.top = `${topRestDay + (12 - Math.abs(topRestDay - top)) / 2}px`;
          } else {
            this.goalRef.current.style.top = `${top + (12 - Math.abs(topRestDay - top)) / 2}px`;
            this.goalRestDayRef.current.style.top = `${topRestDay - (12 - Math.abs(topRestDay - top)) / 2}px`;
          }
        }
      }
    }
  };

  handlePositionTooltip = () => {
    const { viewSize } = this.props;
    if (this.chartRef.current) {
      const { chartInstance } = this.chartRef.current;
      const maxYValue = chartInstance.scales['y-axis-0'].end;
      const heighYAxis = chartInstance.scales['y-axis-0'].height;
      const widthXaxis = chartInstance.scales['x-axis-0'].width;
      const viewDaysOfWeek = viewSize === 'last14' ? daysOfWeekExpand : daysOfWeek;

      if (this.dayRef) {
        _.forEach(viewDaysOfWeek, day => {
          const refKey = `day_${day}`;
          const ref = this.dayRef[refKey];
          if (ref) {
            const value = Number(ref.dataset.value);
            ref.style.visibility = value > 0 ? 'visible' : 'hidden';
            const top = (heighYAxis / maxYValue) * (maxYValue - value);
            ref.style.top = `${top - 5}px`; // 5 is paddding bottom
            const right =
              (widthXaxis / viewDaysOfWeek.length) * (viewDaysOfWeek.length - day) -
              widthXaxis / (viewDaysOfWeek.length * 2);
            ref.style.right = `${right + 2}px`;
          }
        });
      }
    }
  };

  renderTrainingTooltipText = restDayGoal => {
    const { viewType } = this.props;
    if (viewType === 'all') {
      if (restDayGoal > 0) return 'Training day calories goal';
      return 'Daily Calories goal';
    }
    if (restDayGoal > 0) return `Training day ${viewType} goal`;
    return `Daily ${_.capitalize(viewType)} goal`;
  };

  renderRestTooltipText = () => {
    const { viewType } = this.props;
    if (viewType === 'all') {
      return 'Rest day calories goal';
    }
    return `Rest day ${viewType} goal`;
  };

  render() {
    const { reportData, weekCalendar, viewType, viewSize, timezone } = this.props;
    let types = caloriesTypes;
    if (types.includes(viewType)) {
      types = [viewType];
    }

    const data = initChartData(reportData, weekCalendar, types);
    const trainingGoal = getGoalValue(reportData, viewType, 'training_day');
    const restDayGoal = getGoalValue(reportData, viewType, 'rest_day');
    const viewDaysOfWeek = viewSize === 'last14' ? daysOfWeekExpand : daysOfWeek;
    const reportDataByDay = _.mapKeys(reportData.meals, value => value.day);
    const { startDate } = weekCalendar;

    const maxTrainingGoal = Math.max(...getGoalsValue(reportData, viewType, 'training_day'));
    const maxRestDayGoal = Math.max(...getGoalsValue(reportData, viewType, 'rest_day'));
    const trainingData = getGoalsValue(reportData, viewType, 'training_day');
    const restData = getGoalsValue(reportData, viewType, 'rest_day');
    const lastTrainingValue = trainingData[trainingData.length - 1];
    const lastRestValue = restData[restData.length - 1];

    return (
      <S.Wrapper isOneWeek={viewSize !== 'last14'}>
        <S.Legend>
          {types.map(type => (
            <div className={`legend-item legend-item--${type}`}>{_.upperFirst(type)}</div>
          ))}
        </S.Legend>
        <div className="weekly-report__chart">
          {viewDaysOfWeek.map((day, index) => {
            const date = moment.tz(startDate.format(), timezone).add(day, 'd');
            const meal = _.get(reportDataByDay, date.format('MM-DD-YYYY'));
            return (
              <S.DayTooltip
                ref={ref => (this.dayRef[`day_${day}`] = ref)}
                data-value={_.sumBy(data.datasets, `data.${day}`)}
              >
                {_.round(
                  _.get(meal, `value.${viewType === 'all' ? 'calories' : `${viewType}_calories`}`) ||
                    _.sumBy(data.datasets, `data.${index}`),
                )}
              </S.DayTooltip>
            );
          })}

          {reportData.meals && !reportData.meals.length && <S.NoData>No data in range</S.NoData>}
          <Bar
            ref={this.chartRef}
            data={data}
            height={190}
            options={{
              annotation: {
                events: ['mouseover', 'mouseleave'],
                annotations: [
                  {
                    type: 'line',
                    mode: 'horizontal',
                    drawTime: 'beforeDatasetsDraw',
                    scaleID: 'y-axis-0',
                    value: trainingGoal,
                    borderColor: 'transparent',
                    borderWidth: 1,
                    borderDash: [5, 3],
                  },
                  {
                    type: 'line',
                    drawTime: 'beforeDatasetsDraw',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: restDayGoal,
                    borderColor: 'transparent',
                    borderWidth: 1,
                    borderDash: [5, 3],
                  },
                ],
              },
              cornerRadius: 3,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              drawOnChartArea: false,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      drawOnChartArea: false,
                      drawTicks: false,
                    },
                    stacked: true,
                    barPercentage: 0.3,
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#20235350',
                      fontSize: 11,
                      fontStyle: 'bold',
                      padding: 9,
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: false,
                      drawOnChartArea: false,
                    },
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#20235350',
                      fontSize: 11,
                      fontStyle: 'bold',
                      suggestedMax: (maxRestDayGoal > maxTrainingGoal ? maxRestDayGoal : maxTrainingGoal) + 10,
                    },
                  },
                ],
              },
            }}
            plugins={ChartAnnotation}
          />
          {trainingGoal > 0 && (
            <GoalLine
              viewSize={viewSize}
              chart={this.state.baseChartInfo}
              data={trainingData}
              lastValue={trainingGoal}
              note="training-day"
              tooltipText={this.renderTrainingTooltipText(trainingGoal)}
              isClose={
                lastRestValue &&
                lastTrainingValue &&
                lastTrainingValue - lastRestValue > 0 &&
                lastTrainingValue - lastRestValue <= 40
              }
            />
          )}
          {restDayGoal > 0 && (
            <GoalLine
              viewSize={viewSize}
              chart={this.state.baseChartInfo}
              data={restData}
              lastValue={restDayGoal}
              note="rest-day"
              tooltipText={this.renderRestTooltipText(restDayGoal)}
              isSame={lastRestValue && lastTrainingValue && lastRestValue === lastTrainingValue}
              isClose={
                lastRestValue &&
                lastTrainingValue &&
                lastRestValue - lastTrainingValue > 0 &&
                lastRestValue - lastTrainingValue <= 40
              }
            />
          )}
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateToProps = state => {
  const { client } = state.rootReducer;
  return {
    timezone: _.get(client, 'workingClientDetail.timezone'),
  };
};

export default connect(mapStateToProps)(DailyCaloriesChart);
