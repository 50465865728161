import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #A3A3B5;
`;

export const AddNoteWrapper = styled.div`
  ${baseText}
  height: 30px;
  padding: 0 15px 0 11px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  user-select: none;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    svg path {
      fill: #5158cf;
    }
  }

  ${props =>
    props.hasNote &&
    css`
      border: 1px solid #e1e1ea;
      background-color: #ffffff;
      color: #202353;
    `}

  ${({ hasShowContent, hasNote }) =>
    hasShowContent &&
    hasNote &&
    css`
      &.meal-plan-add-note {
        width: 100% !important;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ isOneline }) =>
    isOneline &&
    css`
      svg {
        align-self: self-start;
        margin-top: 5px;
      }
    `}
`;
