import React from 'react';
import { Input } from 'semantic-ui-react';

import * as S from '../style';

const ShortAnswerForm = () => {
  return (
    <S.FormType>
      <S.TypeInbox>
        <Input readOnly className="welcome-form-input" placeholder="Short answer text" />
      </S.TypeInbox>
    </S.FormType>
  );
};

export default ShortAnswerForm;
