import React from 'react';
import { connect } from 'react-redux';
import * as S from './style';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
import { changeQueryParams } from 'redux/package-list/actions';
import { countProducts } from 'redux/product/actions';
import { SORT_ENUM, PACKAGE_SORT_BY } from 'constants/commonData';

const TableHeader = props => {
  const { sort, order, sortMP, orderMP, loading, isMP } = props;
  const currentSort = isMP ? sortMP : sort;
  const currentOrder = isMP ? orderMP : order;

  const renderSortIcon = key => {
    if (key === currentSort) {
      return currentOrder === SORT_ENUM.ASCENDING ? <ArrowDown /> : <ArrowUp />;
    }
    return <ArrowDown />;
  };

  const handleSort = event => {
    event.persist();
    if (!loading) {
      const { sortValue } = event.currentTarget.dataset;
      props.changeQueryParams(
        {
          sort: sortValue,
          order:
            currentSort !== sortValue || currentOrder === SORT_ENUM.DESCENDING
              ? SORT_ENUM.ASCENDING
              : SORT_ENUM.DESCENDING,
        },
        isMP,
      );
    }
  };

  return (
    <S.TableRow>
      <S.TableCellHeader className="name" isSorting={currentSort === PACKAGE_SORT_BY.NAME} sortable>
        <S.TableCellContent data-sort-value={PACKAGE_SORT_BY.NAME} onClick={handleSort}>
          <span>Package Name</span>
          {renderSortIcon('name')}
        </S.TableCellContent>
      </S.TableCellHeader>
      <S.TableCellHeader className="pricing">
        <S.TableCellContent>
          <span>Pricing</span>
        </S.TableCellContent>
      </S.TableCellHeader>
      <S.TableCellHeader isSorting={currentSort === PACKAGE_SORT_BY.TOTAL_SOLD} sortable className="total-sold">
        <S.TableCellContent data-sort-value={PACKAGE_SORT_BY.TOTAL_SOLD} onClick={handleSort}>
          <span>Total sold</span>
          {renderSortIcon('total_sold')}
        </S.TableCellContent>
      </S.TableCellHeader>
      <S.TableCellHeader className="active-user" isSorting={currentSort === PACKAGE_SORT_BY.ACTIVE_USERS} sortable>
        <S.TableCellContent data-sort-value={PACKAGE_SORT_BY.ACTIVE_USERS} onClick={handleSort}>
          <span>Active Users</span>
          {renderSortIcon('active_users')}
        </S.TableCellContent>
      </S.TableCellHeader>
      <S.TableCellHeader className="revenues" isSorting={currentSort === PACKAGE_SORT_BY.REVENUE} sortable>
        <S.TableCellContent data-sort-value={PACKAGE_SORT_BY.REVENUE} onClick={handleSort}>
          <span>Revenue</span>
          {renderSortIcon('revenue')}
        </S.TableCellContent>
      </S.TableCellHeader>
      <S.TableCellHeader className="actions"></S.TableCellHeader>
    </S.TableRow>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageList },
  } = state;
  return {
    sort: packageList.getIn(['query', 'sort']),
    order: packageList.getIn(['query', 'order']),
    sortMP: packageList.getIn(['queryMP', 'sort']),
    orderMP: packageList.getIn(['queryMP', 'order']),
    loading: packageList.get('loadingPackages'),
  };
};

export default connect(mapState, { changeQueryParams, countProducts })(TableHeader);
