import styled, { css } from 'styled-components';

const baseText = `
  font-weight: 600;
  font-size: 12px;
  line-height: 16.34px;
  color: #000000;
`;

export const UnitWrapper = styled.div`
  width: 302px;
  border-radius: 8px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 12px;
  position: relative;
  svg {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    cursor: pointer;
    :hover {
      g {
        opacity: 1;
      }
      path {
        stroke: #5e59ff;
      }
    }
  }

  ${props =>
    props.isCompareMode &&
    css`
      .compare-mode-edit {
        display: none;
      }
    `}
`;

export const ItemUnitWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:last-child::after {
    content: '';
    height: 11px;
    width: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.1;
    background: #000;
  }
`;

export const ItemUnitInfo = styled.div`
  width: fit-content;
  text-align: center;
`;

export const Title = styled.p`
  ${baseText}
  margin-bottom: 0;
  opacity: 0.5;
`;

export const Value = styled.span`
  ${baseText}
  font-size: 14px;
  line-height: 19.07px;
`;
