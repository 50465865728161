import { connect } from 'react-redux';
import {
  addSleepEntry,
  removeSleepMetricEntry,
  updateSleepMetricEntry,
  addBodyMetricEntry,
  updateBodyMetricEntry,
  removeBodyMetricEntry,
  getChartDataLatestByDay,
  getBodyMetricEntryHistory,
  removeAllMetricEntryByDay,
} from 'redux/client/client.actionCreators';
import { refreshManualStepChart } from 'redux/client-step/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import MetricProgressWrapper from './MetricProgressWrapper';
import { getBodyMetricTarget } from 'actions/bodyMetric';
import moment from 'moment';
import { addHeartRateEntry, removeHeartRateEntry, updateHeartRateEntry } from 'redux/heart-rate/action';

const mapStateToProps = state => {
  const { user, rootReducer, bodyMetric } = state;
  const deviceTimezone = moment.tz.guess();

  return {
    selectedMetric: bodyMetric,
    timezone: deviceTimezone,
    clientId: rootReducer.client.selected,
    dateFormat: user.date_format,
    metricTypes: rootReducer.client.bodymetricTypes,
  };
};

const actionCreators = {
  addSleepEntry,
  removeSleepMetricEntry,
  updateSleepMetricEntry,
  toggleConfirmModal,
  toggleModal,
  addBodyMetricEntry,
  updateBodyMetricEntry,
  removeBodyMetricEntry,
  removeAllMetricEntryByDay,
  refreshManualStepChart,
  getChartDataLatestByDay,
  getBodyMetricEntryHistory,
  getBodyMetricTarget,
  addHeartRateEntry,
  updateHeartRateEntry,
  removeHeartRateEntry,
};

export default connect(mapStateToProps, actionCreators)(MetricProgressWrapper);
