// Lib
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Image, Modal } from 'semantic-ui-react';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { diff } from 'deep-diff';

// Store
import { toggleConfirmModal } from 'actions/modal';
import { addNewForms } from 'redux/forms/actions';

// Shared
import SingleChoiceForm from './QuestionsType/SingleChoiceForm';
import MultipleChoiceForm from './QuestionsType/MultipleChoiceForm';
import ShortAnswerForm from './QuestionsType/ShortAnswerForm';
import LongAnswerForm from './QuestionsType/LongAnswerForm';
import SignatureForm from './QuestionsType/SignatureForm';
import DatetimeForm from './QuestionsType/DatetimeForm';
import MetricForm from './QuestionsType/MetricForm';
import { Toggle } from 'shared/FormControl';
import WelcomeForm from '..';
import FormTemplatesPopup from '../../DuplicateFormTemplates';
import { TypingModal, TYING_TYPES, TriggerAppliedIcon } from 'shared/TriggerForms';

// Constants
import { CDN_URL, QUESTION_TYPES } from 'constants/commonData';
import { ASSET_TYPES } from 'components/OnboardingFlowDetail/constants';
import {
  getFormProperties,
  formatTriggerAfterToggleStatusQuestion,
} from 'components/OnboardingFlowDetail/constants/helper';

// Assets
import { ReactComponent as RestartIcon } from 'assets/icons/form_restart.svg';
import { ReactComponent as Duplicate } from 'assets/icons/action_duplicate_left.svg';
import WarningIcon from 'assets/icons/warning_red_light.svg';

import * as S from './style';

const WelcomeFormQuestions = props => {
  const {
    onCloseSecondModal,
    handleSelectedForm,
    onboardingForm,
    formDetailData,
    defaultDateFormat,
    defaultMetric,
    isEditForm,
    toggleModal,
    formTemplates,
    getFormTemplates,
    toggleConfirmModal,
    forms,
    firstFormId,
    user,
    addNewForms,
    selectedAsset,
    handleChangeAsset,
    isChangeForm,
  } = props;
  const { questions } = formDetailData;
  const currentFormId = get(onboardingForm, 'form._id', '');
  const [isLoadingWelcomeForm, setIsLoadingWelcomeForm] = useState(false);
  const [selectedList, setSelectedList] = useState(
    !isEmpty(onboardingForm) ? get(onboardingForm, 'selected_questions', []) : [],
  );
  const [turnOffTriggers, setTurnOffTriggers] = useState({});
  const [turnOnTriggers, setTurnOnTriggers] = useState({});
  let numberIndex = 1;

  useEffect(() => {
    if (get(formDetailData, '_id', '') !== currentFormId) {
      const questionIds = map(questions, item => item._id);
      setSelectedList(questionIds);
    }
  }, [currentFormId]);

  const renderType = ({ type, option_answers, title }) =>
    useMemo(() => {
      switch (type) {
        case QUESTION_TYPES.SINGLE_CHOICE:
          return <SingleChoiceForm options={option_answers} />;
        case QUESTION_TYPES.MULTIPLE_CHOICE:
          return <MultipleChoiceForm options={option_answers} />;
        case QUESTION_TYPES.SHORT_ANSWER:
          return <ShortAnswerForm />;
        case QUESTION_TYPES.LONG_ANSWER:
          return <LongAnswerForm />;
        case QUESTION_TYPES.SIGNATURE:
          return <SignatureForm />;
        case QUESTION_TYPES.DATE_TIME:
          return <DatetimeForm defaultDateFormat={defaultDateFormat} />;
        case QUESTION_TYPES.METRIC:
          return <MetricForm defaultMetric={defaultMetric} title={title} />;

        default:
          return <></>;
      }
    }, [type]);

  const handleClose = () => {
    const questionIds = get(formDetailData, 'questions', []).map(item => item._id);
    if (
      !isEmpty(onboardingForm) &&
      (isEditForm
        ? get(formDetailData, '_id', '') !== currentFormId ||
          diff(get(onboardingForm, 'selected_questions'), selectedList)
        : get(formDetailData, '_id', '') === currentFormId || diff(questionIds, selectedList))
    ) {
      handleDiscardChange();
    } else {
      onCloseSecondModal && onCloseSecondModal();
    }
  };

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  const handleToggleStatus = question => {
    const { _id: id, type } = question;

    // Check form assign to trigger
    if (selectedAsset) {
      const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(it => it.question === id);
      const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(it => it.question === id);

      // turn off question
      if (
        type === QUESTION_TYPES.SINGLE_CHOICE &&
        selectedList.includes(id) &&
        (isAssignTriggerProgram || isAssignTriggerForum)
      ) {
        typeof toggleConfirmModal &&
          toggleConfirmModal(
            true,
            <TypingModal
              isCloseAfterSubmit
              type={TYING_TYPES.TURN_OFF_QUESTION}
              closeModal={closeConfirmModal}
              submitAction={() => {
                setSelectedList(selectedList.filter(item => item !== id));
                setTurnOnTriggers(it => ({
                  ...it,
                  [id]: undefined,
                }));
                setTurnOffTriggers(it => ({
                  ...it,
                  [id]: {
                    isAssignTriggerProgram,
                    isAssignTriggerForum,
                  },
                }));
              }}
            />,
          );

        return;
      }

      // turn on question
      if (
        type === QUESTION_TYPES.SINGLE_CHOICE &&
        !selectedList.includes(id) &&
        (isAssignTriggerProgram || isAssignTriggerForum)
      ) {
        setTurnOffTriggers(it => ({
          ...it,
          [id]: undefined,
        }));
        setTurnOnTriggers(it => ({
          ...it,
          [id]: {
            isAssignTriggerProgram,
            isAssignTriggerForum,
          },
        }));
      }
    }

    if (selectedList.includes(id)) {
      setSelectedList(selectedList.filter(item => item !== id));
    } else {
      setSelectedList([...selectedList, id]);
    }
  };

  const createForm = ({ formDetailData, signature, selectedList, hasTrigger = false }) => {
    return {
      form: {
        _id: get(formDetailData, '_id', ''),
        title: get(formDetailData, 'title', ''),
        description: get(formDetailData, 'description', ''),
        number_of_questions: get(selectedList, 'length', 0),
        hasSignature: !!signature.length,
        hasTrigger,
        questions: get(formDetailData, 'questions', []),
      },
      selected_questions: selectedList,
    };
  };

  const updateTriggerState = newOnboardingForm => {
    try {
      // turn off question
      updateTriggerStateAfterToggleStatus({
        newOnboardingForm,
        isDeleted: true,
        toggleStatusTriggers: turnOffTriggers,
      });

      // turn on question
      updateTriggerStateAfterToggleStatus({
        newOnboardingForm,
        isDeleted: false,
        toggleStatusTriggers: turnOnTriggers,
      });

      // recheck status trigger
      if ((!isEditForm && selectedAsset) || (isChangeForm && selectedAsset)) {
        newOnboardingForm.forEach(form => {
          const { _id: formId } = getFormProperties(form);

          // Check form assign to trigger
          const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(
            it => it.form === formId || it.custom_form === formId,
          );
          const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(
            it => it.form === formId || it.custom_form === formId,
          );

          if (typeof handleChangeAsset === 'function') {
            isAssignTriggerProgram &&
              handleChangeAsset(
                {
                  ...selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0],
                  is_form_deleted: false,
                },
                ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
              );
            isAssignTriggerForum &&
              handleChangeAsset(
                {
                  ...selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0],
                  is_form_deleted: false,
                },
                ASSET_TYPES.FORUM_TRIGGER_FORMS,
              );
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateTriggerStateAfterToggleStatus = ({ newOnboardingForm, isDeleted, toggleStatusTriggers }) => {
    if (
      isEmpty(toggleStatusTriggers) ||
      typeof toggleStatusTriggers !== 'object' ||
      typeof handleChangeAsset !== 'function' ||
      !isEditForm
    ) {
      return;
    }

    for (const id in toggleStatusTriggers) {
      if (Object.hasOwnProperty.call(toggleStatusTriggers, id)) {
        if (isEmpty(toggleStatusTriggers[id])) continue;

        const { isAssignTriggerForum, isAssignTriggerProgram } = toggleStatusTriggers[id];

        // program
        if (!isEmpty(isAssignTriggerProgram)) {
          const newProgramTriggerState = formatTriggerAfterToggleStatusQuestion({
            triggerForms: get(selectedAsset, ASSET_TYPES.PROGRAM_TRIGGER_FORMS, []),
            forms: newOnboardingForm,
            questions,
            isCustomForm: false,
            selectedQuestions: selectedList,
            isDeleted,
          });

          handleChangeAsset(newProgramTriggerState, ASSET_TYPES.PROGRAM_TRIGGER_FORMS);
        }

        // forum
        if (!isEmpty(isAssignTriggerForum)) {
          const newForumTriggerState = formatTriggerAfterToggleStatusQuestion({
            triggerForms: get(selectedAsset, ASSET_TYPES.FORUM_TRIGGER_FORMS, []),
            forms: newOnboardingForm,
            questions,
            isCustomForm: false,
            selectedQuestions: selectedList,
            isDeleted,
          });

          handleChangeAsset(newForumTriggerState, ASSET_TYPES.FORUM_TRIGGER_FORMS);
        }
      }
    }
  };

  const handleApproveChangeForm = (isAssignTriggerProgram, isAssignTriggerForum) => () => {
    if (typeof handleChangeAsset === 'function') {
      isAssignTriggerProgram &&
        handleChangeAsset(
          {
            ...selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0],
            is_form_deleted: true,
          },
          ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
        );
      isAssignTriggerForum &&
        handleChangeAsset(
          {
            ...selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0],
            is_form_deleted: true,
          },
          ASSET_TYPES.FORUM_TRIGGER_FORMS,
        );
    }

    handleSaveForm();
  };

  const confirmChangeForm = () => {
    if (isChangeForm && isEditForm) {
      // Check form assign to trigger
      const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(
        it => it.form === currentFormId || it.custom_form === currentFormId,
      );
      const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(
        it => it.form === currentFormId || it.custom_form === currentFormId,
      );
      if ((isAssignTriggerProgram || isAssignTriggerForum) && typeof toggleConfirmModal === 'function') {
        return toggleConfirmModal(
          true,
          <TypingModal
            isCloseAfterSubmit
            type={TYING_TYPES.CHANGE_FORM}
            closeModal={closeConfirmModal}
            submitAction={handleApproveChangeForm(isAssignTriggerProgram, isAssignTriggerForum)}
          />,
        );
      }
    }

    handleSaveForm();
  };

  const handleSaveForm = () => {
    const signature = filter(questions, question => ['signature'].includes(get(question, 'type', '')));
    let newOnboardingForm = [];
    const hasTrigger = questions.some(question => question.type === QUESTION_TYPES.SINGLE_CHOICE);

    if (isEmpty(onboardingForm)) {
      newOnboardingForm = [createForm({ formDetailData, signature, selectedList, hasTrigger })];
    } else if (firstFormId && !isEditForm) {
      newOnboardingForm = [onboardingForm, createForm({ formDetailData, signature, selectedList, hasTrigger })];
    } else {
      newOnboardingForm = forms.map(item => {
        const updateForm = {
          form: { ...item.form, number_of_questions: get(selectedList, 'length', 0) },
          selected_questions: selectedList,
        };

        return !get(item, 'custom_form')
          ? get(item, 'form._id') !== get(formDetailData, '_id')
            ? get(item, 'form._id') !== firstFormId
              ? createForm({ formDetailData, signature, selectedList, hasTrigger })
              : item
            : updateForm
          : get(item, 'custom_form')
          ? item
          : updateForm;
      });
    }

    handleSelectedForm && handleSelectedForm(newOnboardingForm);
    onCloseSecondModal && onCloseSecondModal();
    updateTriggerState(newOnboardingForm);
  };

  const handleChangeForm = () => {
    if (isLoadingWelcomeForm) return;
    if (!isEmpty(formTemplates)) {
      onChangeForm({ formData: formTemplates });
    } else {
      setIsLoadingWelcomeForm(true);
      getFormTemplates &&
        getFormTemplates()
          .then(response => {
            const formData = get(response, 'data.data', []).reverse();
            if (formData.length) {
              onChangeForm({ formData });
            }
          })
          .finally(() => {
            setIsLoadingWelcomeForm(false);
          });
    }
  };

  const onChangeForm = ({ formData }) => {
    const anotherForm = forms.find(
      item => ![get(item, 'form._id'), get(item, 'custom_form._id')].includes(get(formDetailData, '_id')),
    );

    onCloseSecondModal && onCloseSecondModal();
    toggleModal(
      true,
      <WelcomeForm
        onClose={() => toggleModal(false)}
        formData={formData}
        onboardingForm={{
          form: formDetailData,
          selected_questions: selectedList,
        }}
        getFormTemplates={getFormTemplates}
        isEditForm={isEditForm}
        forms={forms}
        firstFormId={get(anotherForm, 'form._id') || get(anotherForm, 'custom_form._id')}
        selectedAsset={selectedAsset}
        handleChangeAsset={handleChangeAsset}
        isChangeForm={{
          form: formDetailData,
          selected_questions: selectedList,
        }}
      />,
    );
  };

  const handleDiscardChange = () => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content="Are you sure you want to go? Changes have not been saved yet."
          onConfirm={onCloseSecondModal}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={WarningIcon}
        />,
      );
  };

  const handleOpenDuplicateForm = () => {
    toggleModal(
      true,
      <FormTemplatesPopup
        onSubmit={handleDuplicateForm}
        onClose={() => toggleModal(false)}
        user={user}
        data={formDetailData}
      />,
    );
  };

  const handleDuplicateForm = (params, callback) => {
    addNewForms && addNewForms(params, true);
    callback && callback();
  };

  return (
    <S.CustomModal open closeOnDimmerClick={false} className="welcome-form-questions-modal" onClose={handleClose}>
      <Modal.Header>
        <Button onClick={handleClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <S.HeaderWrapper>
          <S.Title>{formDetailData.title}</S.Title>
          <Duplicate
            onClick={handleOpenDuplicateForm}
            data-tip
            data-for={`onboarding-form-duplicate-${currentFormId}`}
            className="action-duplicate-form"
          />
          <ReactTooltip
            id={`onboarding-form-duplicate-${currentFormId}`}
            className="app-tooltip onboarding-form-duplicate"
            effect="solid"
            place="top"
          >
            Duplicate and Edit in Library
          </ReactTooltip>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.FormQuestionWrapper>
          {questions &&
            questions.map(question => {
              const { _id, type = '', option_answers = {}, title = '', description = '' } = question;
              const isEnabled = selectedList.includes(question._id);
              const isRequired = get(question, 'is_require', false);
              // Order number question
              numberIndex = isEnabled ? numberIndex + 1 : numberIndex;
              let numberQuestion = isEnabled ? numberIndex - 1 : numberIndex;

              let hasTriggerInUse = false;
              if (type === QUESTION_TYPES.SINGLE_CHOICE && selectedList.includes(_id) && isEditForm) {
                const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(
                  it => it.question === _id,
                );
                const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(
                  it => it.question === _id,
                );
                hasTriggerInUse = isAssignTriggerProgram || isAssignTriggerForum;
              }

              return (
                <S.FormContainer key={question._id}>
                  <S.NumberOfQuestion>{isEnabled && numberQuestion}</S.NumberOfQuestion>
                  <S.FormWrapper>
                    {!isRequired ? (
                      <>
                        <Toggle
                          className="welcome-form-toggle"
                          checked={isEnabled}
                          onChange={() => handleToggleStatus(question)}
                          data-tip
                          data-for={`onboarding-form-tooltip-${question._id}`}
                        />
                        <ReactTooltip
                          className="app-tooltip welcome-form-tooltip"
                          id={`onboarding-form-tooltip-${question._id}`}
                          effect="solid"
                          place={'top'}
                        >
                          <S.TooltipContent>
                            {isEnabled ? 'Hide this question' : 'Add in this question'}
                          </S.TooltipContent>
                        </ReactTooltip>
                      </>
                    ) : null}
                    <S.FormHeader>
                      <S.FormTitle isRequired={!isRequired}>
                        {title.replace('/', '/ ')}&nbsp;
                        {hasTriggerInUse && (
                          <span className="form-title__trigger-applied">
                            <TriggerAppliedIcon />
                          </span>
                        )}
                      </S.FormTitle>
                      {description && <S.FormSubTitle isRequired={!isRequired}>{description}</S.FormSubTitle>}
                    </S.FormHeader>
                    {renderType({ type, option_answers, title })}
                  </S.FormWrapper>
                </S.FormContainer>
              );
            })}
        </S.FormQuestionWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.Footer>
          <S.FooterLeft>
            {isEditForm ? (
              <Button type="button" className="btn-welcome-form-question btn-change-form" onClick={handleChangeForm}>
                <RestartIcon />
                Change Form
              </Button>
            ) : // )
            null}
          </S.FooterLeft>
          <S.FooterRight>
            <Button
              type="button"
              className="btn-welcome-form-question btn-welcome-form-question-cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-welcome-form-question btn-welcome-form-question-save"
              onClick={confirmChangeForm}
            >
              {isEditForm ? 'Update' : 'Add'} Form
            </Button>
          </S.FooterRight>
        </S.Footer>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      onboardingFlow: { metricSettings },
    },
    user,
  } = state;

  return {
    defaultDateFormat: get(user, 'date_format', 'MM/DD/YYYY'),
    defaultMetric: metricSettings.filter(
      item => get(item, 'unique_code') === 'weight' || get(item, 'unique_code') === 'height',
    ),
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addNewForms: bindActionCreators(addNewForms, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeFormQuestions);
