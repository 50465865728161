import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

//import components
import GroupButton from 'shared/GroupButton';
import ExercisesTab from './ExercisesTab';
import SectionsTab from './SectionsTab';
import { getWorkoutBuilderSetting } from 'components/WorkoutDetailModal/helper';
import OnboardingTooltip, { Content, Title } from 'components/Onboarding/Tooltip';
import { markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { WORKOUT_BUILDER_GUIDE_STEPS } from 'constants/commonData';
import LeftPanelStyles from './styles';

const LeftPanel = ({
  className = '',
  user,
  loadedWorkoutOnboardingData,
  alreadyDragAndDropExercise,
  markWorkoutBuilderGuide,
  isEmptyWorkout,
}) => {
  const [tabSelected, setTabSelected] = useState(getWorkoutBuilderSetting(user._id).tabLeftPanel || 'Exercises');
  const listHeaderTabs = [
    {
      text: 'Exercises',
      onClick: () => {
        setTabSelected('Exercises');
      },
    },
    {
      text: 'Sections',
      onClick: () => {
        setTabSelected('Sections');
      },
    },
  ];

  const onGuideDone = () => {
    markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection);
  };

  return (
    <LeftPanelStyles className={classNames('workoutBuilder__leftPanel', className)}>
      {isEmptyWorkout && loadedWorkoutOnboardingData && !alreadyDragAndDropExercise ? (
        <OnboardingTooltip onClose={onGuideDone} place="right" align="start" showInModal>
          <Content style={{ width: 360 }}>
            <Title>Drag and Drop Exercises or Sections to the right</Title>
            Build workout with Exercises or special Section types, such as interval training, AMRAP, or strength
            workouts.
          </Content>
        </OnboardingTooltip>
      ) : null}
      <div className="left-pannel__container">
        <div className="left-pannel__tabs">
          <GroupButton data={listHeaderTabs} tabSelected={tabSelected} />
        </div>
        {tabSelected === 'Exercises' ? <ExercisesTab /> : <SectionsTab />}
      </div>
    </LeftPanelStyles>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: {
      onboarding: {
        workoutBuilderGuideSteps: { loaded, create_exercise_section },
      },
      workoutBuilder,
    },
  } = state;

  const listSections = workoutBuilder.getIn(['selectedWorkout', 'sections']);
  const isEmptyWorkout = !listSections || listSections.size === 0;

  return {
    loadedWorkoutOnboardingData: loaded,
    alreadyDragAndDropExercise: create_exercise_section,
    user,
    isEmptyWorkout,
  };
};

export default connect(mapState, { markWorkoutBuilderGuide })(LeftPanel);
