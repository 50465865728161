import { get } from 'lodash';
import React from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

import { HowToCalculateModalWrapper, PricingRowWrapper } from './style';

const PricingRow = props => {
  let prefix = props.index === 0 ? 'First' : 'From';
  if (props.packageNoClients === -1) {
    prefix = 'Above';
  }
  const getNoClients = () => {
    if (props.packageNoClients === -1) {
      return props.pricings[props.index - 1].packageNoClients;
    }
    if (props.index === 0) {
      return props.packageNoClients;
    } else {
      const fromClients = props.pricings[props.index - 1].packageNoClients + 1;
      return `${fromClients} - ${props.packageNoClients}`;
    }
  };

  return (
    <PricingRowWrapper>
      <div className="pricingRow__noClients">
        <span>{prefix}</span>
        <strong>&nbsp;{getNoClients()}</strong>
        <span>&nbsp;clients</span>
      </div>
      <div className="pricingRow__price">
        <strong>
          {props.index === 0 ? (
            <span>&nbsp;&nbsp;&nbsp;${get(props, 'monthlyPricing', 0)}</span>
          ) : (
            <span>+&nbsp;${get(props, 'monthlyPricing', 0)}</span>
          )}
        </strong>
        <span>&nbsp;{props.addOnsText}</span>
      </div>
    </PricingRowWrapper>
  );
};

export default function HowToCalculateModal({ addOn, period, ...props }) {
  return (
    <div>
      <HowToCalculateModalWrapper
        size={'tiny'}
        open={props.isModalOpen}
        onClose={() => props.closeModal()}
        closeOnDimmerClick={false}
        closeIcon={
          <Button className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
        }
      >
        <Modal.Content className="pricingAuto__content">
          <div className="pricingAuto__heading">
            <span className="pricingAuto__heading--text">Pricing for Automation</span>
            <span className="pricingAuto__heading--tag">Money Saver Policy</span>
          </div>
          <div className="pricingAuto__description">
            <span>
              You will be charged monthly only for your actual usage, counting the number of clients that have
              participated in at least one active <b>Autoflow</b> in the past month.
            </span>
          </div>
          <div className="pricingAuto__table">
            <div className="pricingAuto__pricingHeader">
              <div>Number of Autoflow Clients</div>
              <div>Price/month</div>
            </div>
            {(addOn.pricings || []).map((pricing, index) => (
              <PricingRow
                pricings={addOn.pricings}
                period={period}
                {...pricing}
                index={index}
                lastIndex={addOn.pricings.length - 1 === index}
              />
            ))}
          </div>
        </Modal.Content>
      </HowToCalculateModalWrapper>
    </div>
  );
}
