import styled, { css } from 'styled-components';
import { TableHeaderCell } from 'shared/LibraryLayout';

export const ContactListHeader = styled(TableHeaderCell)`
  padding: 0;
  cursor: pointer;
  user-select: none;
  &:nth-child(1) {
    width: 232px !important;
  }
  &:nth-child(2) {
    width: 100px !important;
  }
  &:nth-child(3) {
    width: 160px !important;
  }
  &:nth-child(4) {
    width: 160px !important;
  }
  &:nth-child(5) {
    width: auto !important;
  }

  ${props =>
    !props.hasSurveyResponse &&
    css`
      &:nth-child(4) {
        width: auto !important;
      }
    `}
`;
