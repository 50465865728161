import { fromJS } from 'immutable';
import { Types } from './actions';

const INITIAL_STATE = fromJS({
  onboarding: {
    object: '',
    created: '',
    expires_at: '',
    url: '',
  },
  account: {
    payment_account_fetching: false,
    charges_enabled: false,
  },
  currency: {
    usd: { value: 'usd', label: 'USD ($)', symbol: '$', formatOptions: { code: 'en-US', currency: 'USD' } },
  },
  trialTime: null,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  const accountParsed = state.get('account').toJS();

  switch (type) {
    case Types.SETUP_PAYMENT_GET_ON_BOARDING_SUCCESS: {
      return state.set('onboarding', fromJS(payload));
    }

    case Types.SETUP_PAYMENT_CONNECT_ACCOUNT_FETCHING: {
      return state.set('account', fromJS({ ...accountParsed, payment_account_fetching: true }));
    }

    case Types.SETUP_PAYMENT_CONNECT_ACCOUNT_SUCCESS: {
      return state.set('account', fromJS({ ...accountParsed, ...payload, payment_account_fetching: false }));
    }

    case Types.SETUP_PAYMENT_CONNECT_ACCOUNT_FAILED: {
      return state.set('account', fromJS({ ...accountParsed, payment_account_fetching: false }));
    }

    default:
      return state;
  }
};
