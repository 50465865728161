import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  line-height: 150%;
  font-style: normal;
  color: #202353;
  text-align: left;
`;

export const CustomModal = styled(Modal)`
  &.ui.modal {
    width: 638px;
    height: 100%;
    max-height: 708px;

    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    > .header,
    > .content,
    > .actions {
      border: unset !important;
      border-radius: unset !important;
      padding: unset !important;
      background: transparent !important;
    }

    > .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;
    }
  }
`;

export const HeaderWrapper = styled.div`
  background: #fff;
  padding: 24px 32px 16px;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderRightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderLeftSide = styled.div`
  width: 275px;
  height: 36px;

  .search-input {
    width: 100%;
    height: 100%;
    color: #202353;

    svg {
      right: 10px;
    }

    input {
      &:focus,
      &:focus-within {
        border-color: #5158cf;
      }
      &::placeholder {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #bababa;
      }
    }
  }
`;

export const HeaderTitle = styled.div`
  ${textBase}
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-height: 568px;
  height: 100%;
  background: #f6f6f6;
  padding: 16px 8px 0 32px;

  .loading--on-demand-asset {
    top: 50%;
    transform: translateY(-50%);
    padding: unset;

    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
    &.loading-more {
      top: auto;
      transform: none;
      padding-top: 14px;
      .custom-indicator {
        padding-top: 1.75rem;
        &::after {
          width: 20px;
          height: 20px;
          border-width: 3px;
        }
        &::before {
          border-width: 3px;
          width: 20px;
          height: 20px;
          border-color: #e1e1ea !important;
        }
      }
    }
  }

  ${props =>
    props.isEmptyOrLoading &&
    css`
      padding: 0;
    `}
`;

export const ContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 0 24px 16px 0;

  ${props =>
    props.isEmptyOrLoading &&
    css`
      padding: 0;
    `}

  &::-webkit-scrollbar {
    width: 4px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e6e6e6 !important;
  }
  .loading-source {
    padding-top: 8px;
    .ui.dimmer .ui.loader:after {
      border-color: #5158cf;
    }
  }
`;

export const ContentWrapperEmpty = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;
  padding-top: 148px;
  display: flex;
  row-gap: 32px;
  flex-direction: column;
  align-items: center;

  .empty-icon {
    flex-shrink: 0;
  }

  span {
    ${textBase}
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  ${props =>
    props.isSearchLoading &&
    css`
      height: 100%;
    `}

  ${props =>
    props.isSearchEmptyList &&
    css`
      height: 100%;
    `}
`;

export const ContentBodyEmpty = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 128px;
  padding-right: 8px;
  flex-direction: column;
  gap: 0;

  .empty-search-icon {
    flex-shrink: 0;
  }

  span {
    ${textBase}
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    padding-left: 5px;
  }
`;

export const TotalContent = styled.div`
  ${textBase}
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
`;

export const SelectedWrapper = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #818299;
  padding-top: 3px;
`;

export const ActionsWrapper = styled.div`
  padding: 12px 16px;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px 16px;
  height: 40px;
  min-width: 101px;
  ${textBase}
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &.btn {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    min-width: 30px;
    user-select: none;

    &__cancel {
      color: #818da1;
      background: #fff;
      padding: 10px 30px;
      width: 107px;
      border: 1px solid #d6dae4;
    }

    &__save {
      color: #fff;
      width: 79px;
      background: #5158cf;
    }

    &__cancel:hover {
      background-color: #f8f8f8;
    }

    &__save:hover {
      opacity: 0.9;
    }
  }

  &:disabled {
    background: #d5d5d5;
    cursor: not-allowed;
  }
`;
