import React from 'react';
import styled, { css } from 'styled-components';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { Button } from 'shared/FormControl';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_square.svg';
import { CALENDAR_LIMIT_WEEKS } from 'constants/commonData';

const AddWeekButton = styled(Button)`
  background: #e9edf0;
  padding: 5px 20px;
  color: #718094;
  border-color: transparent;

  :hover {
    color: #fff;
    background-color: #5158cf;
  }

  ${props =>
    props.loadingPaste &&
    css`
      &:hover {
        color: #718094;
        background-color: #e9edf0;
      }
    `}

  :disabled {
    background: #e9edf0;
  }
`;

const AddWeekGuide = styled.div`
  color: #fff;
  opacity: 0.6;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 13px 20px 9px;
  max-width: 200px;
  svg {
    margin: 2px 8px 0 0;
    flex: 0 0 14px;
  }
`;

const Wrapper = styled(DropDown)`
  .evf-dropdown__option {
    font-weight: 600;
    padding: 10px 20px;

    svg {
      margin-right: 10px;
    }
  }

  &.open {
    ${AddWeekButton} {
      color: #fff;
      background-color: #5158cf;
    }
  }

  .evf-dropdown__menu {
    overflow: visible;

    :before {
      content: '';
      position: absolute;
      top: -3px;
      left: 15px;
      border: solid 1px #2d2e2d;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      background-color: #2d2e2d;
      border-radius: 1px;
    }
  }
`;

export default ({
  currentWeek,
  onAddWeekBefore,
  onAddWeekAfter,
  totalWeek,
  onlyAddToTheEnd,
  onAddWeekToTheEnd,
  warningMessage,
  loadingPaste,
}) => {
  if (totalWeek >= CALENDAR_LIMIT_WEEKS) {
    return null;
  }
  return (
    <Wrapper
      triggerIcon={() => (
        <AddWeekButton disabled={loadingPaste} loadingPaste={loadingPaste}>
          + Add Week
        </AddWeekButton>
      )}
      className="dropdown--addWeek"
    >
      {!onlyAddToTheEnd && (
        <Option onClick={onAddWeekBefore} key="add-week-before">
          <PlusIcon />
          <div>Add before "Week {currentWeek + 1}"</div>
        </Option>
      )}
      {!onlyAddToTheEnd && (
        <Option onClick={onAddWeekAfter} key="add-week-after">
          <PlusIcon />
          <div>Add after "Week {currentWeek + 1}"</div>
        </Option>
      )}
      {onlyAddToTheEnd && (
        <Option onClick={onAddWeekToTheEnd} key="add-to-the-end">
          <PlusIcon />
          <div>Add after “Week {totalWeek}”</div>
        </Option>
      )}
      <AddWeekGuide>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 12.25C4.1125 12.25 1.75 9.8875 1.75 7C1.75 4.1125 4.1125 1.75 7 1.75C9.8875 1.75 12.25 4.1125 12.25 7C12.25 9.8875 9.8875 12.25 7 12.25Z"
            fill="white"
          />
          <path d="M7.875 6.125H6.125V10.5H7.875V6.125Z" fill="white" />
          <path
            d="M7 5.25C7.48325 5.25 7.875 4.85825 7.875 4.375C7.875 3.89175 7.48325 3.5 7 3.5C6.51675 3.5 6.125 3.89175 6.125 4.375C6.125 4.85825 6.51675 5.25 7 5.25Z"
            fill="white"
          />
        </svg>
        <span>
          {warningMessage && onlyAddToTheEnd
            ? warningMessage
            : onlyAddToTheEnd
            ? 'Pause Autoflow to add a week in between'
            : 'Or hover between 2 weeks to add a week'}
        </span>
      </AddWeekGuide>
    </Wrapper>
  );
};
