import React from 'react';
import { connect } from 'react-redux';
// import components
import { Button } from 'shared/FormControl';
import ConnectForumModal from '../ConnectForumModal';
// import illustrator
import { ReactComponent as ForumIllus } from 'assets/icons/illustrator-forum.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
// import actions
import { toggleModal } from 'actions/modal';
import EmptyStyles from './style';

const Empty = ({ autoflowConnectForum, autoflowId, numberClients, dispatch }) => {
  const openModal = () => {
    dispatch(
      toggleModal(
        true,
        <ConnectForumModal
          onClose={() => dispatch(toggleModal(false))}
          autoflowConnectForum={autoflowConnectForum}
          autoflowId={autoflowId}
          numberClients={numberClients}
        />,
      ),
    );
  };

  return (
    <EmptyStyles>
      <ForumIllus />
      <div className="empty-title">Community Forums</div>
      <div className="empty-description">Connect and showcase the leaderboard in one of your community forums</div>
      <Button transparent onClick={openModal}>
        <PlusIcon />
        Connect Forum
      </Button>
    </EmptyStyles>
  );
};

export default connect()(Empty);
