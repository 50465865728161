import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Image, Icon, Dropdown } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import classNames from 'classnames';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash_note.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil_note.svg';
import EmptyIconImage from 'assets/images/empty_note.png';

import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { getClientNote, addClientNote, updateClientNote, deleteClientNote } from 'actions/client';
import { getPlainText, getHTMLText, removeScriptTags } from 'components/AnnouncementPopup/helper';
import { formatDateCreatedNote, mongoObjectId, clearInlineStylesAndClasses } from 'utils/commonFunction';
import {
  LIST_SORTER,
  EDITOR_MODE,
  MAX_CONTENT_LENGTH,
  LABELS_BY_TYPES,
  addIsShowDateTag,
  getParamsContent,
  scrollItemIntoView,
  checkIsChangeContentNote,
  getListAndSelectNext,
} from './helper';

import * as S from './styles';
import { NewSearchInput } from 'shared/SearchInput';
import ComposerNote from 'shared/ComposerNote';
import IconButton from 'shared/IconButton';

import RemoveConfirmModal from '../RemoveConfirmModal';
import DiscardChangeConfirmModal from '../DiscardChangeConfirmModal';
import { CDN_URL } from 'constants/commonData';

const NoteBuilder = props => {
  const {
    timezone,
    initNotes = [],
    editData,
    viewData,
    selectedClient,
    addClientNote,
    updateClientNote,
    deleteClientNote,
    toggleModal,
    toggleConfirmModal,
    onCallbackChangeNotes,
    title,
    type,
    openNewNote = false,
    getClientNote,
  } = props;

  const sortWrapperRef = useRef();
  const requestResetTextRef = useRef();

  const [openingPopup, setOpeningPopup] = useState(false);
  const [sorter, setSorter] = useState(LIST_SORTER[0]);
  const [sort, setSort] = useState(-1);
  const [editorMode, setEditorMode] = useState(EDITOR_MODE.READ_ONLY);
  const [noteSelected, setNoteSelected] = useState();
  const [listNotes, setNotes] = useState(initNotes);
  const [isLoading, setIsLoading] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const {
    total,
    notes,
    isEmptyNotes,
    isDisableSave,
    createdFormat,
    editedFormat,
    isChangeContentNote,
    isNotFoundNotes,
  } = useMemo(() => {
    let filterNotes = listNotes.slice();
    let isNotFoundNotes = false;
    let total = listNotes.length;

    if (!isEmpty(textSearch)) {
      filterNotes = filter(filterNotes, it => {
        const { content_composer, content, isNewLocal, title } = it;
        if (isNewLocal) {
          return title.toLowerCase().includes(textSearch.toLowerCase().trim());
        }
        const initContent = getPlainText(content_composer).trimAny('↵').trim() || content.trimAny('↵').trim() || '';
        return initContent.toLowerCase().includes(textSearch.toLowerCase().trim());
      });

      isNotFoundNotes = filterNotes.length === 0;
      total = filterNotes.length;
    }

    const orderNotes = orderBy(filterNotes, [sorter.key], sort === -1 ? ['desc'] : ['asc']);
    const notes = addIsShowDateTag({ initArray: orderNotes, key: sorter.key });

    const isEmptyNotes = listNotes.length === 0;

    const { content, createdAt, updatedAt } = noteSelected || {};

    const isEmptyContent = (content || '').length <= 0;
    const isChangeContentNote =
      editorMode === EDITOR_MODE.READ_ONLY ? false : checkIsChangeContentNote(listNotes, noteSelected);

    const contentPlainText = getPlainText(content || '')
      .trimAny('↵')
      .trim();

    const isDisableSave = isLoading || isEmptyContent || !isChangeContentNote || !contentPlainText;

    const createdFormat = createdAt ? formatDateCreatedNote({ dateString: createdAt, timezone, isShowTime: true }) : '';
    const editedFormat = updatedAt ? formatDateCreatedNote({ dateString: updatedAt, timezone, isShowTime: true }) : '';

    return {
      total,
      notes,
      isEmptyNotes,
      isDisableSave,
      createdFormat,
      editedFormat,
      isChangeContentNote,
      isNotFoundNotes,
    };
  }, [listNotes, sorter, sort, noteSelected, isLoading, timezone, textSearch, editorMode]);

  useEffect(() => {
    if (openNewNote) {
      handleAddNewNote();
    } else if (!isEmpty(viewData) && isEmpty(editData)) {
      setEditorMode(EDITOR_MODE.READ_ONLY);
      setNoteSelected(viewData);
      scrollItemIntoView({ id: (viewData || {})._id, block: 'center' });
    } else if (isEmpty(viewData) && !isEmpty(editData)) {
      setEditorMode(EDITOR_MODE.WRITE);
      setNoteSelected(editData);
      scrollItemIntoView({ id: (editData || {})._id, block: 'center' });
    }
  }, []);

  const handleClose = () => {
    typeof onCallbackChangeNotes === 'function' && onCallbackChangeNotes(listNotes);
    typeof toggleModal === 'function' && toggleModal(false);
  };

  const handleConfirmClose = () => {
    if (editorMode === EDITOR_MODE.WRITE && isChangeContentNote) {
      toggleConfirmModal(true, <DiscardChangeConfirmModal onConfirm={handleClose} />);
    } else {
      typeof onCallbackChangeNotes === 'function' && onCallbackChangeNotes(listNotes);
      typeof toggleModal === 'function' && toggleModal(false);
    }
  };

  const handleCreateNewDraftNote = () => {
    handelClearSearch();
    typeof requestResetTextRef.current === 'function' && requestResetTextRef.current();

    const { _id, isNewLocal } = noteSelected || {};

    if (_id && isNewLocal) {
      removeStateNotes(_id);
    }

    const createdAtString = new Date().toISOString();
    const newNoteLocal = {
      isNewLocal: true,
      isShowDateTag: true,
      _id: mongoObjectId(),
      title: LABELS_BY_TYPES[type].new_note_title,
      content: '',
      content_composer: '',
      createdAt: createdAtString,
      updatedAt: createdAtString,
    };

    setNoteSelected(newNoteLocal);
    setNotes(it => [newNoteLocal, ...it]);
    setEditorMode(EDITOR_MODE.WRITE);
    scrollItemIntoView({ id: (newNoteLocal || {})._id, block: 'nearest' });
  };

  const handleAddNewNote = () => {
    if (editorMode === EDITOR_MODE.WRITE && !isEmpty(noteSelected) && !isDisableSave) {
      toggleConfirmModal(true, <DiscardChangeConfirmModal onConfirm={handleCreateNewDraftNote} />);
      return;
    }

    handleCreateNewDraftNote();
  };

  const handleSearch = (event, { value }) => {
    setTextSearch(value || '');
  };

  const searchDebounce = debounce(handleSearch, 500);

  const handelClearSearch = () => {
    handleSearch(null, { value: '' });
  };

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      handleSearch(null, { value: textSearch });
    }
  };

  const handleNoteSortBy = sorter => () => {
    setSorter(sorter);
    setOpeningPopup(false);
    scrollItemIntoView({ id: (noteSelected || {})._id, block: 'center' });
  };

  const handleNoteSort = () => {
    setSort(it => (it === 1 ? -1 : 1));
    scrollItemIntoView({ id: (noteSelected || {})._id, block: 'center' });
  };

  const onSortPopupToggle = openingPopup => () => {
    setOpeningPopup(openingPopup);
  };

  const handleInputNote = inputTextHTML => {
    const contentPlainText = getPlainText(inputTextHTML) || '';

    setNoteSelected(it => ({
      ...it,
      content_composer: inputTextHTML,
      content: contentPlainText,
    }));
  };

  const handleChangeNoteSelected = note => () => {
    const { _id, isNewLocal } = noteSelected || {};

    if (_id && isNewLocal) {
      removeStateNotes(_id);
    }

    setNoteSelected(note);
    setEditorMode(EDITOR_MODE.READ_ONLY);
    scrollItemIntoView({ id: (note || {})._id });
  };

  const handleViewNoteItem = note => () => {
    if (isEmpty(noteSelected)) {
      handleChangeNoteSelected(note)();

      return;
    }

    if (note._id === noteSelected._id) return;

    if (isChangeContentNote) {
      toggleConfirmModal(true, <DiscardChangeConfirmModal onConfirm={handleChangeNoteSelected(note)} />);

      return;
    }

    handleChangeNoteSelected(note)();
  };

  const handleSave = () => {
    if (selectedClient && !isEmpty(noteSelected) && !isLoading) {
      setIsLoading(true);
      const paramsContent = getParamsContent(noteSelected.content_composer || noteSelected.content || '');

      if (noteSelected._id && !noteSelected.isNewLocal) {
        const paramsPost = { id: noteSelected._id, ...paramsContent };

        typeof updateClientNote === 'function' &&
          updateClientNote(type, paramsPost)
            .then(() => {
              const updatedAtString = new Date().toISOString();
              const listNote = notes.map(it => {
                if (it._id === noteSelected._id) {
                  return { ...noteSelected, ...paramsContent, updatedAt: updatedAtString };
                }
                return it;
              });

              setNotes(listNote);
              setNoteSelected(it => ({ ...it, ...paramsContent, updatedAt: updatedAtString }));
              setEditorMode(EDITOR_MODE.READ_ONLY);
              scrollItemIntoView({ id: noteSelected._id, block: 'nearest' });
            })
            .catch(error => {
              const errorMessage = (error || {}).message;
              if (errorMessage === 'Note not found' || errorMessage === 'Limitation not found') {
                fetchClientNotesAfterNotFound(paramsPost.id);
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
      } else {
        const paramsPost = { client: selectedClient._id, ...paramsContent };

        typeof addClientNote === 'function' &&
          addClientNote(type, paramsPost)
            .then(response => {
              const { data: { data } = {} } = response;

              if (!isEmpty(data)) {
                const listNote = notes.filter(it => !it.isNewLocal);
                listNote.unshift(data);

                setNotes(listNote);
                setNoteSelected(data);
                setEditorMode(EDITOR_MODE.READ_ONLY);
                scrollItemIntoView({ id: (data || {})._id, block: 'start' });
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
      }
    }
  };

  const removeStateNotes = id => {
    const { newListNote, newSelected } = getListAndSelectNext({
      currentList: listNotes,
      removeId: id,
    });
    setEditorMode(EDITOR_MODE.READ_ONLY);
    setNotes(newListNote);
    setNoteSelected(newSelected);
    newSelected && scrollItemIntoView({ id: (newSelected || {})._id, block: 'nearest' });
  };

  const onRemoveNote = () => {
    const { _id, isNewLocal } = noteSelected || {};

    if (!_id) return;

    if (isNewLocal) {
      removeStateNotes(_id);
    } else {
      typeof deleteClientNote === 'function' &&
        deleteClientNote(type, { id: _id })
          .then(() => {
            removeStateNotes(_id);
          })
          .catch(error => {
            const errorMessage = (error || {}).message;
            if (errorMessage === 'Note not found' || errorMessage === 'Limitation not found') {
              fetchClientNotesAfterNotFound(_id);
            }
          });
    }
  };

  const handleConfirmRemoveNote = () => {
    if (!isEmpty(noteSelected._id)) {
      toggleConfirmModal(
        true,
        <RemoveConfirmModal
          title={LABELS_BY_TYPES[type].remove_modal_title}
          description={LABELS_BY_TYPES[type].remove_modal_description}
          onConfirm={onRemoveNote}
        />,
      );
    }
  };

  const handleEditNote = () => {
    setEditorMode(EDITOR_MODE.WRITE);
  };

  const onListNotesScroll = event => {
    const { scrollTop } = event.target;

    if (sortWrapperRef.current) {
      if (scrollTop > 0) {
        sortWrapperRef.current.classList.add('shadow-bottom');
      } else {
        sortWrapperRef.current.classList.remove('shadow-bottom');
      }
    }
  };

  const fetchClientNotesAfterNotFound = noteId => {
    if (!type || !selectedClient._id) return;

    typeof getClientNote === 'function' &&
      getClientNote(type, selectedClient._id).then(response => {
        const { data: { data = [] } = {} } = response;
        const newList = orderBy(data, ['createdAt'], ['desc']);

        const { newListNote, newSelected } = getListAndSelectNext({
          currentList: listNotes,
          newList: newList,
          removeId: noteId,
        });

        setEditorMode(EDITOR_MODE.READ_ONLY);
        setNotes(newListNote);
        setNoteSelected(newSelected);
        newSelected && scrollItemIntoView({ id: (newSelected || {})._id, block: 'nearest' });
      });
  };

  const renderSortNotes = () => {
    if (isEmptyNotes) return null;

    const iconName = 'chevron ' + (sort === -1 ? 'down' : 'up');

    return (
      <S.SortWrapper ref={sortWrapperRef}>
        <S.CustomSortPopup
          basic
          on="click"
          position="bottom right"
          horizontalOffset={20}
          open={openingPopup}
          className="note-sort-popup"
          onOpen={onSortPopupToggle(true)}
          onClose={onSortPopupToggle(false)}
          trigger={
            <S.TriggerSort>
              <S.SortByLabel>Sort by:</S.SortByLabel>
              <span className={classNames('label', { 'is-show': openingPopup })}>{sorter.label}</span>
            </S.TriggerSort>
          }
        >
          <Dropdown.Menu>{LIST_SORTER.map(renderDropItem)}</Dropdown.Menu>
        </S.CustomSortPopup>
        <Icon name={iconName} onClick={handleNoteSort} />
      </S.SortWrapper>
    );
  };

  const renderScrollableNotes = () => {
    if (isEmptyNotes) return null;

    return <S.ScrollListNoteItem onScroll={onListNotesScroll}>{notes.map(renderNoteItem)}</S.ScrollListNoteItem>;
  };

  const renderDropItem = item => {
    const { key, label } = item;
    const active = key === sorter.key;

    return (
      <Dropdown.Item key={key} onClick={handleNoteSortBy(item)} className={classNames({ active: active })}>
        {label}
      </Dropdown.Item>
    );
  };

  const renderNoteItem = item => {
    const { _id, title, content, createdAt, updatedAt, isShowDateTag = false, isHiddenLineBottom = false } = item;
    const isActive = _id === (noteSelected || {})._id;

    const initContent = (title || content || '').substring(0, 200);

    const dateString = sorter.key === 'createdAt' ? createdAt : updatedAt;
    const dateTagTitle = isShowDateTag ? formatDateCreatedNote({ dateString, timezone, isShowTime: false }) : '';
    const dateTimestamp = formatDateCreatedNote({ dateString, timezone, isShowTime: true });
    const formatDateLabel = sorter.key === 'updatedAt' ? `Edited: ${dateTimestamp}` : dateTimestamp;

    return (
      <S.NoteItemContainerWrapper key={_id} data-id={_id}>
        {isShowDateTag && <S.NoteItemTitleMonth>{dateTagTitle}</S.NoteItemTitleMonth>}
        <S.NoteItemWrapper onClick={handleViewNoteItem(item)} className={classNames({ active: isActive })}>
          <S.NoteItemContent dangerouslySetInnerHTML={{ __html: initContent }} />
          <S.NoteItemCreatedAt>{formatDateLabel}</S.NoteItemCreatedAt>
          <div className={classNames('line-bottom', { 'hidden-line': isHiddenLineBottom })} />
        </S.NoteItemWrapper>
      </S.NoteItemContainerWrapper>
    );
  };

  const renderHeaderMainSide = () => {
    if (!noteSelected || isEmptyNotes) return null;

    if (editorMode === EDITOR_MODE.READ_ONLY) {
      return (
        <S.HeaderMainSide>
          <S.TimeWrapper>
            <S.TimeLabelWrapper>
              <Image src={`${CDN_URL}/images/clock_grey_3.svg`} />
              <S.TimeLabel>Created: {createdFormat}</S.TimeLabel>
            </S.TimeLabelWrapper>
            <S.TimeLabelWrapper>
              <Image src={`${CDN_URL}/images/clock_grey_3.svg`} />
              <S.TimeLabel>Last Edited: {editedFormat}</S.TimeLabel>
            </S.TimeLabelWrapper>
          </S.TimeWrapper>
          <S.ActionsWrapper>
            <IconButton
              icon={<DeleteIcon />}
              tooltip="Remove"
              tooltipKey="remove-button-tooltip"
              id="remove-button"
              delayShow={0}
              onClick={handleConfirmRemoveNote}
            />
            <IconButton
              icon={<EditIcon />}
              tooltip="Edit"
              tooltipKey="edit-button-tooltip"
              id="edit-button"
              delayShow={0}
              onClick={handleEditNote}
            />
          </S.ActionsWrapper>
        </S.HeaderMainSide>
      );
    }

    return (
      <S.HeaderMainSide>
        <S.TimeWrapper>
          <S.TimeLabelWrapper>
            <Image src={`${CDN_URL}/images/clock_grey_3.svg`} />
            <S.TimeLabel>Created: {createdFormat}</S.TimeLabel>
          </S.TimeLabelWrapper>
          {!(noteSelected || {}).isNewLocal && (
            <S.TimeLabelWrapper>
              <Image src={`${CDN_URL}/images/clock_grey_3.svg`} />
              <S.TimeLabel>Last Edited: {editedFormat}</S.TimeLabel>
            </S.TimeLabelWrapper>
          )}
        </S.TimeWrapper>
        <S.SaveButton disabled={isDisableSave} onClick={handleSave}>
          Save
        </S.SaveButton>
      </S.HeaderMainSide>
    );
  };

  const renderComposerMainSide = () => {
    if (!noteSelected || isEmptyNotes) return null;

    const { content_composer, content, isNewLocal } = noteSelected;
    let initContent = removeScriptTags(content_composer || '') || getHTMLText(content || '') || '';
    initContent = clearInlineStylesAndClasses(initContent);

    if (editorMode === EDITOR_MODE.READ_ONLY) {
      return (
        <S.ScrollContentMainSide key={noteSelected._id}>
          <div dangerouslySetInnerHTML={{ __html: initContent }} id="note-content-viewer" />
        </S.ScrollContentMainSide>
      );
    }

    return (
      <S.ComposerMainSide>
        <ComposerNote
          key={noteSelected._id}
          autoFocus={isNewLocal}
          initContent={initContent}
          onChange={handleInputNote}
          placeholder={LABELS_BY_TYPES[type].placeholder_composer}
          maxCharacters={MAX_CONTENT_LENGTH}
          isClearStyleBeforePaste={true}
        />
      </S.ComposerMainSide>
    );
  };

  const renderEmptyLeftSide = () => {
    if (!isNotFoundNotes) return null;

    return (
      <S.EmptyLeftSideWrapper $notFound={isNotFoundNotes}>
        <div className="message-title">{LABELS_BY_TYPES[type].empty_search_label}</div>
      </S.EmptyLeftSideWrapper>
    );
  };

  const renderEmptyMainSide = () => {
    if (!isEmptyNotes) return null;

    const title = LABELS_BY_TYPES[type].empty_items_title;
    const description = getHTMLText(LABELS_BY_TYPES[type].empty_items_description);

    return (
      <S.EmptyMainSideWrapper>
        <img src={EmptyIconImage} alt="" className="empty-illustration" />
        <div className="message-title">{title}</div>
        <div className="message-content" dangerouslySetInnerHTML={{ __html: description }} />
      </S.EmptyMainSideWrapper>
    );
  };

  return (
    <S.CustomModal open closeOnDimmerClick={false} onClose={handleConfirmClose}>
      <Modal.Content>
        <Button onClick={handleConfirmClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <S.WrapperModalContent>
          <S.LeftSide>
            <S.PanelHeader>
              <div className="panel-title">
                <Image src={`${CDN_URL}/images/dashboard_${type}.svg`} className="note-icon" />
                <S.TitleHeader className={type}>{`${title} (${total})`}</S.TitleHeader>
              </div>
              <S.ButtonAdd text={LABELS_BY_TYPES[type].button_add} icon={<PlusIcon />} onClick={handleAddNewNote} />
            </S.PanelHeader>

            <S.SearchBox>
              <NewSearchInput
                onChange={searchDebounce}
                onClearSearch={handelClearSearch}
                placeholder={LABELS_BY_TYPES[type].placeholder_search}
                onKeyPress={onKeyPress}
                requestResetText={requestResetTextRef}
              />
            </S.SearchBox>

            {renderSortNotes()}
            {renderScrollableNotes()}
            {renderEmptyLeftSide()}
          </S.LeftSide>
          <S.MainSide>
            {renderHeaderMainSide()}
            {renderComposerMainSide()}
            {renderEmptyMainSide()}
          </S.MainSide>
        </S.WrapperModalContent>
      </Modal.Content>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const { user = {} } = state;

  return {
    user,
    timezone: user.timezone,
  };
};

const mapDispatchToProps = {
  getClientNote,
  addClientNote,
  updateClientNote,
  deleteClientNote,
  toggleModal,
  toggleConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteBuilder);
