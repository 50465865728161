import React from 'react';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import { Dropdown, Button } from 'semantic-ui-react';
import { Container, Wrapper, CalendarIcon } from './style';
import { Arrow } from '../DateRangePicker/styles';

class ProgramCalendarWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      week: props.currentWeek,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentWeek !== prevProps.currentWeek) {
      this.setState({ week: this.props.currentWeek });
    }
  }

  onGoButtonClick = () => {
    let week = this.state.week;
    const { goToWeek, currentWeek, calendarType } = this.props;
    week = week - (week % calendarType);

    if (week !== currentWeek) {
      goToWeek(week);
    }

    this.setState({ open: false });
  };

  renderDropdown = () => {
    const { totalWeek } = this.props;
    const { week } = this.state;
    const options = _.map(Array(totalWeek), (item, index) => ({
      key: index,
      value: index,
      text: index + 1,
    }));

    return (
      <Dropdown
        value={week}
        selection
        icon="chevron down"
        options={options}
        onChange={(evt, data) => this.setState({ week: data.value })}
      />
    );
  };

  renderTimeRange = () => {
    const { totalWeek, currentWeek, calendarType } = this.props;
    const { open } = this.state;

    if (!totalWeek) {
      return <span>Week</span>;
    }

    const startWeek = currentWeek + 1;
    const endWeek = startWeek === totalWeek || calendarType === 1 ? 0 : Math.min(currentWeek + calendarType, totalWeek);

    return (
      <div onClick={() => this.setState({ open: !open, week: currentWeek })} className="weeks-range">
        <CalendarIcon />
        <span>
          Week {startWeek}
          {endWeek ? ` - ${endWeek}` : ''} of {totalWeek}
        </span>
      </div>
    );
  };

  render() {
    const { className, totalWeek, currentWeek, calendarType } = this.props;
    const { open } = this.state;

    return (
      <RootCloseWrapper event="click" disabled={!open} onRootClose={() => this.setState({ open: false })}>
        <Wrapper className={className || ''}>
          <div className="weeks">
            <Arrow
              left
              onClick={() => {
                if (currentWeek !== 0) {
                  this.props.goToWeek(currentWeek - 1);
                }
              }}
            />
            {this.renderTimeRange()}
            <Arrow
              right
              onClick={() => {
                if (totalWeek - currentWeek > calendarType) {
                  this.props.goToWeek(currentWeek + 1);
                }
              }}
            />
          </div>
          {open && (
            <Container>
              <label>Go to WEEKS</label>
              <div className="content">
                {this.renderDropdown()}
                <span> of {totalWeek}</span>
                <Button onClick={this.onGoButtonClick}>Go</Button>
              </div>
            </Container>
          )}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

export default ProgramCalendarWeek;
