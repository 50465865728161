import { pluralize } from 'utils/commonFunction';
import { CONTACT_STATUS } from 'components/Package/PackageDetail/ContactList/constants';

export const DECLINE_REQUEST_TYPE = {
  WAITLIST: 'waitlist',
  INTROCALL: 'introCall',
};

export const DECLINE_STEP = {
  STEP_ONE: 'step-one',
  STEP_TWO: 'step-two',
};

export const getContentByType = (type, { selectedRequestsCount, selectedDeclineOption }) => {
  const clientText = pluralize('client', selectedRequestsCount, true);
  const thisClient = selectedRequestsCount > 1 ? 'these clients' : 'this client';
  if (type === DECLINE_REQUEST_TYPE.WAITLIST) {
    return {
      declineDescription: `By declining, we will inform ${clientText} via email that this package is sold out. Are you sure you don't want ${thisClient} to purchase your package?`,
      emailDescription: 'I hope this message finds you well. Thank you for your interest in',
      defaultDeclineReason: `Unfortunately, I’m not going to be able to take on new clients for this package at this time.\n\nI wish you all the best for your healthy journey.`,
    };
  }

  if (type === DECLINE_REQUEST_TYPE.INTROCALL) {
    const defaultDeclineReasonAfterConsultation = `Based on our consultation, I don’t think this package is a match for your fitness goals.\n\nThank you for your understanding and considering me as your fitness partner.`;
    const defaultDeclineReasonWithoutConsultation = `Unfortunately, I’m not going to be able to take on new clients for this package at this time.\n\nI hope you’ll consider a different package or working with me again in the future.`;

    return {
      declineDescription: `By declining, we will email ${clientText} that the package is sold out. You can customize the response on the next screen. Confirm below if you met with the client before declining. This info won’t be shared with the client.`,
      emailDescription: 'Thanks for your time and interest in training with me via',
      defaultDeclineReason:
        selectedDeclineOption === CONTACT_STATUS.REJECTED_BY_COACH_AFTER_CONSULTATION
          ? defaultDeclineReasonAfterConsultation
          : defaultDeclineReasonWithoutConsultation,
    };
  }

  return null;
};

export const declineOptions = [
  {
    value: CONTACT_STATUS.REJECTED_BY_COACH_AFTER_CONSULTATION,
    label: 'Declined after consultation',
  },
  {
    value: CONTACT_STATUS.REJECTED_BY_COACH_WITHOUT_CONSULTATION,
    label: 'Declined without consultation',
  },
];

export const defaultFormData = {
  declineReason: '',
  selectedDeclineOption: CONTACT_STATUS.REJECTED_BY_COACH_AFTER_CONSULTATION,
};

export const defaultErrors = {
  declineReason: '',
};
