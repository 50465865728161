// Libs
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { convertMinToFromHourMin } from 'utils/commonFunction';

// Constants
import { SLEEP_COLOR, SLEEP_STATES } from './constants';

// Helpers
import { getTimeLabel } from 'components/BodyMetricChartNew/chartHelper';

// Styles
import { SleepStates } from './SleepStyle';
import * as S from '../../styles';

const HourlyTimeConnectedStagesTooltip = props => {
  const { entry = {}, payloadTooltip } = props;
  const [isMoveLeft, setIsMoveLeft] = useState(false);
  const startMoment = moment(entry.start);
  const endMoment = moment(entry.end);
  const minutes = endMoment.diff(startMoment, 'm');

  const label = useMemo(() => getTimeLabel({ date: entry.start }), [entry]);

  useEffect(() => {
    const checkElementOutsideScreen = element => {
      const rect = element.getBoundingClientRect();
      if (rect) {
        const WIDTH_BUFFER_LEFT = 50;
        const value = window.innerWidth - WIDTH_BUFFER_LEFT < rect.right;
        setIsMoveLeft(value);
      }
    };
    const elementSleepTooltipContent = document.getElementById('sleep-tooltip-content');
    if (elementSleepTooltipContent) {
      checkElementOutsideScreen(elementSleepTooltipContent);
    }
  }, [payloadTooltip && payloadTooltip._id]);

  const renderValueUnit = (value, unit) => (
    <>
      {value > 0 && (
        <>
          {value}
          <span className="unit"> {unit}</span>
        </>
      )}
    </>
  );

  const renderSleepState = () => {
    const { min, hour } = convertMinToFromHourMin(minutes);
    return (
      <div className="sleep-state">
        <div className="sleep-state-left">
          <div
            className={classNames('sleep-state-color', { 'sleep-special': entry.isAsleep })}
            style={{ backgroundColor: entry.isAsleep ? undefined : SLEEP_COLOR[entry.type] }}
          />
          <span className="sleep-state-title">{entry.isAsleep ? SLEEP_STATES.asleep : SLEEP_STATES[entry.type]}</span>
        </div>
        <div className="sleep-state-time">
          {renderValueUnit(hour, ' h ')}
          {renderValueUnit(min, ' min')}
        </div>
      </div>
    );
  };

  const getValueTime = () => {
    return (
      <p className="tooltip-value">
        <S.UnitValue>{startMoment.format('h:mm A')}</S.UnitValue>
        <span className="divider" />
        <S.UnitValue>{endMoment.format('h:mm A')}</S.UnitValue>
      </p>
    );
  };

  return (
    <S.TooltipWrapper
      className="sleep-tooltip special-hourly"
      isOutsideRight={isMoveLeft}
      widthCurrentTarget={(payloadTooltip || {}).widthBlock}
      id="sleep-tooltip-content"
    >
      <S.RangeTimeLabel>{label}</S.RangeTimeLabel>
      {getValueTime()}
      <SleepStates>{renderSleepState()}</SleepStates>
    </S.TooltipWrapper>
  );
};

export default HourlyTimeConnectedStagesTooltip;
