import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const ExerciseDraggingIcon = styled.div`
  width: 12px;
  height: 10px;
  background: url(${CDN_URL}/images/drag_icon_purple.svg);
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Wrapper = styled.div`
  position: relative;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: grab;
  user-select: none;

  ${ExerciseDraggingIcon} {
    visibility: hidden;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  ${props =>
    props.isDragging
      ? css`
          border: 1px solid #5c5bbd;
          box-sizing: border-box;
          cursor: move !important;
          padding-right: 20px;

          ${ExerciseDraggingIcon} {
            visibility: visible;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        `
      : props.isDragMode
      ? css`
          cursor: grabbing;
        `
      : css`
          :hover {
            border: 1px solid #5c5bbd;
            box-sizing: border-box;
            padding-right: 20px;

            ${ExerciseDraggingIcon} {
              visibility: visible;
            }
          }
        `}
`;
