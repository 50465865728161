import React, { useEffect, useMemo } from 'react';
import { toggleModal } from 'actions/modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HistoricalModal from './ViewHistoricalUsageModal';
import get from 'lodash/get';
import moment from 'moment';
import { getCurrentUsage } from 'redux/pricing/actions';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-grey.svg';
import { ReactComponent as UsageInfo } from 'assets/icons/information.svg';

import * as S from './style';
import ReactTooltip from 'react-tooltip';

const ZapierProgressBar = props => {
  const { toggleModal, getCurrentUsage, zapierCurrentUsage, zapierCurrentPrice, zapierCurrentTier, user } = props;

  const isOverUsedTasks = get(zapierCurrentUsage, 'total_request', 0) >= get(zapierCurrentUsage, 'zapier_quantity', 0);
  const handleOpenHistoricalUsage = () => {
    toggleModal(true, <HistoricalModal toggleModal={toggleModal} />);
  };

  // TODO: SHOULD check spam api
  useEffect(() => {
    getCurrentUsage();
  }, []);

  const progressPercent = useMemo(() => {
    return (get(zapierCurrentUsage, 'total_request', 0) / get(zapierCurrentUsage, 'zapier_quantity', 0)) * 100;
  }, [zapierCurrentUsage]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.TotalTask>
          <S.BoldText>Total Tasks</S.BoldText> {`Tier ${zapierCurrentTier} - ${zapierCurrentPrice} `}
        </S.TotalTask>
        <S.ViewHistory onClick={handleOpenHistoricalUsage}>
          View Historical Usage <ArrowRightIcon className="icon" />
        </S.ViewHistory>
      </S.Header>
      <S.ProgressBarContainer>
        <S.UsedAPI isOver={isOverUsedTasks}>
          {get(zapierCurrentUsage, 'total_request', 0).toLocaleString('en-US')} /
          {get(zapierCurrentUsage, 'zapier_quantity', 0).toLocaleString('en-US')}
        </S.UsedAPI>
        <S.ProgressBar>
          <S.ProgressPercent percent={progressPercent || 0} />
        </S.ProgressBar>
        {get(zapierCurrentUsage, 'usage_reset', null) && (
          <>
            <S.UsageReset>
              Usage Reset: {''}
              {moment(get(zapierCurrentUsage, 'usage_reset', '')).tz(get(user, 'timezone', '')).format('MMM D, YYYY')}
              <S.UsageDetail>
                <div className="usage-icon" data-tip data-for={`usage-${get(zapierCurrentUsage, 'usage_reset', '')}`}>
                  <UsageInfo />
                </div>
                <ReactTooltip
                  className="app-tooltip custom-usage"
                  id={`usage-${get(zapierCurrentUsage, 'usage_reset', '')}`}
                  effect="solid"
                  place={'top'}
                >
                  <div className="custom-usage--tooltip">
                    <b>Timezone</b>
                    <br />
                    <span>
                      {moment(get(zapierCurrentUsage, 'usage_reset', '')).local().format('MMM D, YYYY ([GMT]Z)')}
                    </span>
                  </div>
                </ReactTooltip>
              </S.UsageDetail>
            </S.UsageReset>
          </>
        )}
      </S.ProgressBarContainer>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { user, rootReducer } = state;
  const currentUsageJS = rootReducer.pricing.get('zapierCurrentUsage').toJS();
  return {
    user,
    zapierCurrentUsage: currentUsageJS,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getCurrentUsage: bindActionCreators(getCurrentUsage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZapierProgressBar);
