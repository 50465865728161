import React, { useEffect, useState, useRef, useCallback } from 'react';

import { ReactQuillWrapper } from './styles';
import { resetIcons, loadCustomIcons, modules, formats } from './constant';
import { clearInlineStylesAndClasses } from 'utils/commonFunction';

const Composer = props => {
  const {
    initContent = '',
    onChange,
    placeholder = 'Add your message',
    autoFocus = false,
    isResetIcons = false,
    maxCharacters = 0,
    isClearStyleBeforePaste = false,
  } = props;

  const [content, setContent] = useState(initContent);
  const editorRef = useRef();

  loadCustomIcons();

  useEffect(() => {
    focusLastPosition();
    limitMaxCharacters();

    return () => {
      isResetIcons && resetIcons();
    };
  }, []);

  const focusLastPosition = useCallback(() => {
    if (autoFocus && editorRef.current) {
      typeof editorRef.current.focus === 'function' && editorRef.current.focus();

      if (typeof editorRef.current.getEditor === 'function') {
        const editor = editorRef.current.getEditor();
        typeof editor.setSelection === 'function' && editor.setSelection(initContent.length);
      }
    }
  }, []);

  const limitMaxCharacters = useCallback(() => {
    if (!!maxCharacters && editorRef.current) {
      if (typeof editorRef.current.getEditor === 'function') {
        const editor = editorRef.current.getEditor();
        const LIMIT = maxCharacters + 1;

        editor.on('text-change', function (delta, oldContents, source) {
          try {
            const newContentLength = editor.getLength();
            const oldContentLength = oldContents.length();
            const { index: selectionRangeIndex = 0, length: selectionRangeLength = 0 } =
              editor.getSelection(true) || {};
            if (source !== 'user' || newContentLength <= LIMIT) return;

            const isEmptyContent = oldContentLength === 1;
            const isPasteAtSelectionAll =
              oldContentLength > 1 && selectionRangeIndex === 0 && selectionRangeLength >= oldContentLength - 1;
            const isDisableInputAndPaste = oldContentLength === LIMIT;

            if (isEmptyContent || isPasteAtSelectionAll) {
              editor.deleteText(maxCharacters, newContentLength);
              return;
            }

            if (isDisableInputAndPaste) {
              if (newContentLength - oldContentLength === 1) {
                const insertCharacter = editor.getText(selectionRangeIndex, 1);
                if (['\n', '\t'].includes(insertCharacter)) {
                  editor.deleteText(selectionRangeIndex, 1);
                } else {
                  editor.deleteText(selectionRangeIndex - 1, 1);
                }
              } else {
                if (selectionRangeLength === 0) {
                  editor.deleteText(selectionRangeIndex, newContentLength - oldContentLength);
                } else {
                  editor.deleteText(selectionRangeIndex + selectionRangeLength, newContentLength - oldContentLength);
                }
              }
              return;
            }

            if (!isDisableInputAndPaste && newContentLength >= LIMIT) {
              if (selectionRangeLength === 0) {
                editor.deleteText(selectionRangeIndex, newContentLength - oldContentLength);
              } else {
                editor.deleteText(selectionRangeIndex + selectionRangeLength, newContentLength - oldContentLength);
              }
              return;
            }
          } catch (error) {
            console.error(error);
          }
        });
      }
    }
  }, []);

  const handleOnChangeInput = (content, delta, source, editor) => {
    if (source !== 'user') return;

    const newContent = isClearStyleBeforePaste ? clearInlineStylesAndClasses(content) : content;
    setContent(newContent);
    onChange && onChange(newContent);
  };

  return (
    <ReactQuillWrapper
      ref={editorRef}
      theme="snow"
      onChange={handleOnChangeInput}
      value={content || ''}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
    />
  );
};

export default Composer;
