import styled from 'styled-components';

export const ItemWrapper = styled.div`
  padding: 20px 44px;
  display: grid;
  grid-template-columns: 65% 35%;
  border-bottom: 1px solid #eeeeee;
  :hover {
    background: #f8f8f8;
    cursor: pointer;
  }
`;

export const ItemName = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2a394c;
  display: flex;
  align-items: center;
`;

export const ItemSubmittedDate = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #222222;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export const FormNameDetail = styled.div`
  max-width: calc(100% - 294px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  .signature-icon {
    margin-left: 6px;
  }
`;
