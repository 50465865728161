import styled from 'styled-components';
import DietaryInfoItem from '../../../DietaryInfoItem';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px
  color: #7B7E91;
`;

export const ListIngredientSelectedWrapper = styled.div`
  position: relative;
`;

export const Title = styled.div`
  ${baseText}
  line-height: 18px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

export const NumberSelected = styled.span`
  ${baseText}
  background-color: #f0f1ff;
  font-size: 12px;
  line-height: 18px;
  color: #5158cf;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 27px;
  height: 18px;
  margin-left: 5px;
`;

export const List = styled.div`
  height: 378px;
  overflow-y: scroll;
  padding-right: 9px;
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #f0f0f2 !important;
  }
  ::-webkit-scrollbar-track {
    margin-bottom: 5px;
  }
`;

export const Item = styled(DietaryInfoItem)`
  cursor: default;
  height: 36px;
  width: 100%;
  background-color: #f0f1ff;
  margin-bottom: 5px;
  padding-right: 35px;
  .avatar {
    width: 24px;
    height: 24px;
    border: 1px solid #fff;
  }
  .label {
    .name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  &:hover {
    .label {
      .name {
        color: #202353;
      }
    }
  }
`;
