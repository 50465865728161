// Libs
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { Button as CloseButton } from 'semantic-ui-react';
import classNames from 'classnames';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import diff from 'deep-diff';

// Actions
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';
import { getListOtherNutrient } from 'redux/recipes/actions';
import { getIngredientDetail } from 'redux/ingredient-library/actions';

// Helpers
import { convertDataAmountPer, convertUnitName } from 'components/IngredientLibrary/helpers';
import { convertDataMacroNutrients, getNutrientValue } from 'components/RecipeDetail/helper';
import { MACRO_NUTRIENTS, NUTRITION_TAB } from 'components/RecipeDetail/constants';

// Component
import InputName from '../InputName';
import CategorySelectWrapper from './component/CategorySelectWrapper';
import UnitSelectWrapper from './component/UnitSelectWrapper';
import AddMultiIngredientModal from '../AddMultiIngredientModal';
import DiscardChangeModal from 'components/Recipes/Parts/DiscardChange';
import NutritionInfo from './component/NutritionInfo';

import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/add_tag_icon.svg';

// Style
import * as S from './style';

const dataDefault = {
  name: '',
  categories: [],
  default_unit: '',
};

const macroNutritionDefault = [
  { type: MACRO_NUTRIENTS.PROTEIN, value: '' },
  { type: MACRO_NUTRIENTS.CARBS, value: '' },
  { type: MACRO_NUTRIENTS.FAT, value: '' },
  { type: MACRO_NUTRIENTS.CALORIES, value: '' },
];

const AddSingleIngredientModal = ({
  toggleModal,
  toggleSecondModal,
  isSecondModal = false,
  isViewMode = false,
  errorMsg = 'Please complete all required fields',
  onSubmitForm = () => {},
  optionsCategory = [],
  isLoading,
  initData = {},
  toggleConfirmModal,
  addMultipleIngredient,
  isEditMode = false,
  initLabelViewMode = {},
  unitType = '',
  listUnitIngredient = {},
  getListOtherNutrient = () => {},
  addFromMultiple = false,
  getIngredientDetail = () => {},
  ingredientId = '',
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState({
    ...dataDefault,
    ...initData,
  });
  const [loading, setLoading] = useState(false);
  const [listOtherNutrients, setListOtherNutrients] = useState([]);
  const [macroNutrients, setMacroNutrients] = useState(macroNutritionDefault);
  const [otherNutrients, setOtherNutrients] = useState([]);
  const [dataAmountPer, setDataAmountPer] = useState({});
  const [originalData, setOriginalData] = useState({});

  const hasError = useMemo(() => {
    const { name, categories, default_unit } = data;
    if (!(name || '').trim() || isEmpty(categories) || isEmpty(default_unit)) return true;
    setIsSubmitted(false);
    return false;
  }, [data]);

  useEffect(() => {
    const categoryId = get(data, 'categories.[0]', '');
    const itemCategory = optionsCategory.find(item => get(item, '_id', '') === categoryId);
    const listAmountPer = get(itemCategory, 'serving_sizes', []);
    const listAmountPerConvert = listAmountPer.map(item => {
      const { unit = '', value, unit_id = '' } = item || {};
      return {
        ...item,
        _id: unit_id,
        name: `${value} ${unit}`,
      };
    });
    const firstId = get(listAmountPerConvert, '[0]._id', '');
    const value = firstId ? [firstId] : [];
    const checkExistUnit = (listAmountPerConvert || []).some(item =>
      get(dataAmountPer, 'value', []).includes(get(item, '_id', '')),
    );
    setDataAmountPer(prevState => ({
      list: listAmountPerConvert,
      value: checkExistUnit ? get(prevState, 'value', []) : value,
    }));
  }, [get(data, 'categories', [])]);

  const convertNameListUnit = convertUnitName(listUnitIngredient);

  useEffect(() => {
    getListOtherNutrient().then(res => {
      setListOtherNutrients(get(res, 'data.data', []));
    });
  }, []);

  useEffect(() => {
    if (ingredientId) {
      setLoading(true);
      getIngredientDetail(ingredientId)
        .then(res => {
          const {
            name = '',
            categories = [],
            default_unit = {},
            macro_nutrients = macroNutritionDefault,
            other_nutrients = [],
            serving_size = {},
          } = get(res, 'data.data', {});
          const initData = {
            name,
            categories: categories.map(({ _id }) => _id) || [],
            default_unit: get(default_unit, '_id', ''),
          };
          const dataMacroNutrition = isEmpty(macro_nutrients) ? macroNutritionDefault : macro_nutrients;

          setData(initData);
          setOtherNutrients(other_nutrients.map(item => omit(item, ['_id'])));
          setMacroNutrients(dataMacroNutrition.map(item => omit(item, ['_id'])));

          const initialUnitId = get(categories, '[0].allow_units.[0].unit_id', '');

          setDataAmountPer(prevState => {
            const servingUnitId = get(serving_size, 'unit_id', '');
            const unitIds = servingUnitId ? [servingUnitId] : initialUnitId ? [initialUnitId] : [];

            return {
              ...prevState,
              value: unitIds,
              servingSize: serving_size,
            };
          });

          setOriginalData({
            data: initData,
            macroNutrients: dataMacroNutrition.map(item => omit(item, ['_id'])),
            otherNutrients: other_nutrients.map(item => omit(item, ['_id'])),
            servingSize: serving_size,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ingredientId]);

  const handleCloseModal = () => {
    const toggleFunction = isSecondModal ? toggleSecondModal : toggleModal;
    if (typeof toggleFunction === 'function') {
      toggleFunction(false);
    }
  };

  const onSubmit = () => {
    setIsSubmitted(true);
    if (hasError) return;
    const dataSubmit = {
      ...data,
      macro_nutrients: convertDataMacroNutrients(macroNutrients),
      other_nutrients: otherNutrients,
      serving_size: convertDataAmountPer(dataAmountPer),
    };
    onSubmitForm(dataSubmit);
  };

  const renderViewDetail = (label = '', content = '') => {
    return (
      <S.ViewDetail>
        <span className="label">{label}</span>
        <div className="content">{content}</div>
      </S.ViewDetail>
    );
  };

  const handleChangeName = value => {
    setData(prevState => ({ ...prevState, name: value }));
  };

  const handleChangeCategory = value => {
    setData(prevState => ({ ...prevState, categories: [value] }));
  };

  const handleChangeUnit = value => {
    setData(prevState => ({ ...prevState, default_unit: value }));
  };

  const handleOpenNewMultiIngredient = () => {
    if (isSecondModal) {
      toggleSecondModal(
        true,
        <AddMultiIngredientModal
          isSecondModal
          optionsCategory={optionsCategory}
          onSubmitForm={addMultipleIngredient}
        />,
      );
    } else {
      toggleModal(
        true,
        <AddMultiIngredientModal optionsCategory={optionsCategory} onSubmitForm={addMultipleIngredient} />,
      );
    }
  };

  const checkDiscardChange = () => {
    if (isEditMode) {
      return diff(originalData, {
        data,
        macroNutrients,
        otherNutrients,
        servingSize: dataAmountPer.servingSize,
      });
    } else {
      return (
        diff(dataDefault, data) ||
        otherNutrients.length > 0 ||
        macroNutrients.filter(item => item.type !== 'calories').some(item => (item || {}).value !== '')
      );
    }
  };

  const handleDiscardChange = () => {
    const isDiscardChange = checkDiscardChange();

    if (isViewMode || !isDiscardChange || addFromMultiple) {
      handleCloseModal();
    } else {
      typeof toggleConfirmModal === 'function' &&
        toggleConfirmModal(true, <DiscardChangeModal onConfirm={handleCloseModal} />);
    }
  };

  const updateNutrientList = (nutrients, type, value) => {
    const index = nutrients.findIndex(item => get(item, 'type', '') === type);
    if (index === -1) {
      return [...nutrients, { type, value }];
    } else {
      return nutrients.map(item => (item.type === type ? { ...item, value } : item));
    }
  };

  const onChangeNutrients = useCallback(
    (e, type, tab) => {
      const isMacro = tab.key === NUTRITION_TAB.MACRO_NUTRIENTS;
      const value = isNumber(e.floatValue) ? e.floatValue : '';
      let result = isMacro ? macroNutrients : otherNutrients;

      result = updateNutrientList(result, type, value);

      if (isMacro) {
        result = calculateCalories(result);
        setMacroNutrients(result);
      } else {
        result = result.filter(item => isNumber(item.value));
        setOtherNutrients(result);
      }
    },
    [macroNutrients, otherNutrients],
  );

  const calculateCalories = useCallback(list => {
    const getValueOrZero = nutrientType => getNutrientValue(list, nutrientType) || 0;

    const valueProtein = getValueOrZero(MACRO_NUTRIENTS.PROTEIN);
    const valueCarbs = getValueOrZero(MACRO_NUTRIENTS.CARBS);
    const valueFat = getValueOrZero(MACRO_NUTRIENTS.FAT);

    const caloriesValue = valueProtein * 4 + valueCarbs * 4 + valueFat * 9;
    const updatedList = list.map(item =>
      item.type === MACRO_NUTRIENTS.CALORIES ? { ...item, value: caloriesValue } : item,
    );

    return updatedList;
  }, []);

  const renderInfoIngredient = () => {
    return (
      <S.InfoIngredient>
        <div className="title">Add nutrition info</div>
        <div className="name">Papaya</div>
      </S.InfoIngredient>
    );
  };

  const handleChangeAmountPer = value => {
    setDataAmountPer(prevState => ({
      ...prevState,
      value: value ? [value] : [],
    }));
  };

  return (
    <S.Wrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={handleDiscardChange}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      {loading ? (
        <S.LoadingWrapper />
      ) : (
        <>
          <S.Content>
            {!addFromMultiple ? (
              <>
                <S.InputNameWrapper>
                  <InputName
                    isViewMode={isViewMode}
                    onChange={handleChangeName}
                    hasError={isSubmitted && !get(data, 'name', '').trim()}
                    value={data.name}
                    onFocus
                  />
                </S.InputNameWrapper>
                <S.OptionWrapper>
                  <S.CategorySelect>
                    {isViewMode ? (
                      renderViewDetail('Category', (initLabelViewMode || {}).categoryLabel)
                    ) : (
                      <CategorySelectWrapper
                        options={optionsCategory}
                        isViewMode={isViewMode}
                        onChangeCategory={handleChangeCategory}
                        hasError={isSubmitted && isEmpty(data.categories)}
                        values={get(data, 'categories', [])}
                        isMulti
                      />
                    )}
                  </S.CategorySelect>
                  <S.UnitSelect>
                    {isViewMode ? (
                      renderViewDetail('Default Unit', (initLabelViewMode || {}).unitLabel)
                    ) : (
                      <UnitSelectWrapper
                        data={convertNameListUnit}
                        onChangeUnit={handleChangeUnit}
                        hasError={isSubmitted && isEmpty(data.default_unit)}
                        value={get(data, 'default_unit', '')}
                        unitType={unitType}
                        isMulti
                      />
                    )}
                  </S.UnitSelect>
                </S.OptionWrapper>
                {!isViewMode && isSubmitted && hasError && <span className="error-msg">{errorMsg}</span>}
              </>
            ) : (
              renderInfoIngredient()
            )}
            <NutritionInfo
              listUnit={convertNameListUnit}
              listOtherNutrients={listOtherNutrients}
              isViewMode={isViewMode}
              onChangeNutrients={onChangeNutrients}
              MacroNutrients={macroNutrients}
              OtherNutrients={otherNutrients}
              addFromMultiple={addFromMultiple}
              dataAmountPer={dataAmountPer}
              handleChangeAmountPer={handleChangeAmountPer}
            />
          </S.Content>
          <S.Action>
            {!isViewMode && (
              <>
                <div className="left-action">
                  {!isEditMode && (
                    <button className="add-multi-btn" onClick={handleOpenNewMultiIngredient} disabled={isLoading}>
                      <PlusIcon />
                      Add multiple ingredients
                    </button>
                  )}
                </div>
              </>
            )}
            <div className={classNames('right-action', { 'is-view-mode': isViewMode })}>
              {isViewMode ? (
                <button className={classNames('action-btn', { 'close-btn': true })} onClick={handleCloseModal}>
                  Close
                </button>
              ) : (
                <>
                  {!isEditMode && (
                    <button className="action-btn" onClick={handleDiscardChange} disabled={isLoading}>
                      Cancel
                    </button>
                  )}
                  <button
                    className={classNames('action-btn', { 'add-btn': true })}
                    onClick={onSubmit}
                    disabled={isLoading}
                  >
                    {isEditMode ? 'Save' : 'Add'}
                  </button>
                </>
              )}
            </div>
          </S.Action>
        </>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { ingredientLibrary },
  } = state;

  const { isLoading = false, listUnitIngredient = {} } = ingredientLibrary || {};

  return { isLoading, listUnitIngredient };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getListOtherNutrient: bindActionCreators(getListOtherNutrient, dispatch),
  getIngredientDetail: bindActionCreators(getIngredientDetail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSingleIngredientModal);
