import styled, { css } from 'styled-components';

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6A778A;
  margin-bottom: 5px;
`;

export const DocumentAttachmentWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;

  &.isUploaded {
    background: #F4F4F5;
    mix-blend-mode: normal;
    border-radius: 5px;
    padding: 20px;

    .taskAttachment__closeIcon {
      top: -7px;
    }

    .taskAttachment__documentUpload {
      margin-bottom: 20px;
    }
  }

  .taskAttachment__closeIcon {
    top: 13px;
  }

  .taskAttachment__documentUpload {
    margin-bottom: 0px;
  }

  .taskAttachment__uploadCover {
    width: 215px;
    height: 125px;
  }
`;
