import React from 'react';
import isEmpty from 'lodash/isEmpty';

const horizontalPadding = 3;
const verticalPadding = 3;
const lineBarWidth = 6;
const lineBarHeight = 8;
const verticalChartHeight = 239;

const CustomLineBar = ({
  color,
  barWidth,
  chartBarData,
  isChartCompare = false,
  isFillTransparent = false,
  isModeCompare = false,
}) => {
  if (isEmpty(chartBarData)) return null;

  const {
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    positionY = 0,
    customHeight = 0,
    hasDataBar1 = false,
    isMetricChartBar = false,
  } = chartBarData || {};

  let finalViewBoxWidth = width + lineBarWidth;
  let finalViewBoxHeight = height + lineBarHeight;
  let finalXPosition = x - horizontalPadding;
  let finalYPosition = y - verticalPadding;

  const defaultHeight = height + lineBarHeight;
  const adjustedHeightForNoData = customHeight + lineBarHeight;

  if (!isMetricChartBar) {
    finalViewBoxWidth = barWidth + lineBarWidth;
    finalViewBoxHeight = isChartCompare
      ? hasDataBar1
        ? defaultHeight
        : adjustedHeightForNoData
      : height + (isModeCompare ? verticalChartHeight : lineBarHeight);

    finalXPosition = x - horizontalPadding;
    finalYPosition = isChartCompare ? y - positionY - verticalPadding : y - verticalPadding;
  }

  const pathProps = {
    d: `M0.5 5C0.5 2.23858 2.73858 0 5.5 0H${finalViewBoxWidth - 6}.5C${finalViewBoxWidth - 3}.2614 0 ${
      finalViewBoxWidth - 1
    }.5 2.23858 ${finalViewBoxWidth - 1}.5 5V${finalViewBoxHeight - 5}H0.5V5Z`,
    fill: color,
    fillOpacity: isFillTransparent ? 0 : 0.2,
    rx: finalViewBoxWidth / 2,
    ry: finalViewBoxHeight / 2,
  };

  return (
    <svg
      width={finalViewBoxWidth}
      height={finalViewBoxHeight - 5}
      x={finalXPosition}
      y={finalYPosition}
      viewBox={`0 0 ${finalViewBoxWidth} ${finalViewBoxHeight - 5}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path {...pathProps} />
    </svg>
  );
};

export default CustomLineBar;
