import React, { useMemo } from 'react';
import moment from 'moment';
import * as S from './style';
import Select from 'react-select';
import { NEXT_MONTHS } from '../constants';
import { DropdownIndicator } from 'shared/Icons';
import { pluralize } from 'utils/commonFunction';

const MonthlyRepeat = ({ selectedDate, totalMonth, onChange }) => {
  const momentObj = useMemo(() => {
    return moment.isMoment(selectedDate) ? selectedDate : moment(selectedDate);
  }, [selectedDate]);

  const monthObj = useMemo(() => {
    return NEXT_MONTHS.find(item => item.value === Number(totalMonth));
  }, [totalMonth]);

  const handleSelectNumberOfMonth = ({ value }) => {
    onChange(value);
  };

  return (
    <S.Wrapper>
      <S.RepeatInstance>
        <span>On the</span>
        <S.Value>{momentObj.format('Do')}</S.Value>
        <span>day of the month</span>
      </S.RepeatInstance>
      <S.RepeatSetting>
        <span>For the next</span>
        <Select
          options={NEXT_MONTHS}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          onChange={handleSelectNumberOfMonth}
          classNamePrefix="evfSelectBox"
          className="evfSelectBoxContainer"
          value={monthObj}
          isSearchable={false}
          styles={{
            control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 74 }),
          }}
          menuPosition="fixed"
        />
        <span>{pluralize('month', totalMonth)}</span>
        <span className="start-date">(start from {momentObj.format('MMM D')})</span>
      </S.RepeatSetting>
    </S.Wrapper>
  );
};

export default MonthlyRepeat;
