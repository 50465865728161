import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import round from 'lodash/round';
import isEqual from 'lodash/isEqual';
import upperCase from 'lodash/upperCase';

import FormItemInput from 'shared/FormItemInput';
import DropdownOptions from 'shared/DropdownOptions';
import { ReactComponent as InfoIcon } from 'assets/icons/refund-info-icon.svg';
import { Button } from 'shared/FormControl';
import { CURRENCIES, REFUND_REASONS, nullOption, TEXT_CURRENCIES, CDN_URL } from 'constants/commonData';
import { Mixpanel } from 'utils/mixplanel';

import ConfirmModal from 'shared/ConfirmModal';
import ConfirmRefundPopup from './ConfirmRefundPopup';
import * as S from './styles';
import './styles.scss';
import { formatMoney } from 'utils/commonFunction';

const REFUND_TYPE = {
  FULL: 'full',
  PARTIAL: 'partial',
};

export default function RefundPaymentPopup(props) {
  const [refundType, setRefundType] = useState(REFUND_TYPE.FULL);
  const selectedFull = refundType === REFUND_TYPE.FULL;
  const selectedPartial = refundType === REFUND_TYPE.PARTIAL;
  const [refundAmount, setRefundAmount] = useState(0);
  const [reason, setReason] = useState(nullOption);
  const [details, setDetails] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const inputRef = useRef(null);
  const { currency, invoice } = props;
  const invoiceAmount = get(invoice, 'price.amount', '');
  const handleClose = () => {
    props.toggleSecondModal(false);
  };
  const handleSelectRefundType = type => () => {
    setRefundType(type);
    refundType === REFUND_TYPE.FULL && type === REFUND_TYPE.PARTIAL && setRefundAmount('');
  };
  const handleSelectReason = value => {
    if (isEqual(value, nullOption)) {
      setReason(nullOption);
    } else {
      setReason(value);
    }
    setIsConfirmModalOpen(false);
  };
  const handleChangeRefundAmount = useCallback(values => {
    const newValue = parseFloat(values.value);
    setRefundAmount(newValue);
    setIsConfirmModalOpen(false);
  }, []);
  const handleChangeDetail = e => {
    setDetails(e.target.value);
    setIsConfirmModalOpen(false);
  };
  const handleConfirm = () => {
    const { invoice, refundInvoice } = props;
    const handleSuccessRefund = () => {
      toast(`Refund for ${invoice.customer.full_name} is processing.`);
      Mixpanel.track(`refund_${invoice.id}`);
    };
    const handleFailedRefund = err => {
      if (get(err, 'response.data.errorCode') === 'REFUND_AMOUNT_IS_GREATER_THAN_ACCOUNT_BALANCE') {
        props.toggleConfirmModal(
          true,
          <ConfirmModal
            title="Refund failed"
            content="Sorry you don't have enough credit in your Stripe balance account at the moment, please use another method outside of Everfit to proceed with the refund."
            headerIcon={`${CDN_URL}/images/refund_failed.svg`}
            noBorder
            newStyle
            hasCloseIcon={false}
            hasHoverState
            onDeny={() => toggleConfirmModal(false)}
            disableConfirmButton
            cancelButtonTitle="Cancel"
            className="refund-failed-popup"
          />,
        );
      } else {
        props.showError(get(err, 'response.data.message'));
        toast('Refund failed. Please try again.');
      }
    };
    const isFullRefund = refundType === REFUND_TYPE.FULL;
    const submitData = {
      invoiceId: invoice.id,
      isFullRefund: isFullRefund,
      refundAmount: isFullRefund ? parseFloat(invoice.price.amount) : parseFloat(refundAmount),
      reason: reason.value,
      note: details,
    };
    if (reason.value !== null) {
      refundInvoice(submitData, handleSuccessRefund, handleFailedRefund);
    }
    handleClose();
  };
  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };
  const handleMoneyChange = (e, inputComponentRef) => {
    if (e.key !== 'Backspace') {
      if (inputComponentRef.current.value.includes('.')) {
        if (inputComponentRef.current.value.split('.')[1].length >= 2) {
          var num = parseFloat(inputComponentRef.current.value);
          var cleanNum = num.toFixed(2);
          inputComponentRef.current.value = cleanNum;
          e.preventDefault();
        }
      }
    }
  };
  const onKeyDown = e => {
    handleMoneyChange(e, inputRef);
  };

  const currencyObj = get(CURRENCIES, `${currency || 'usd'}`);
  const symbol = TEXT_CURRENCIES.includes(currency) ? `${get(currencyObj, 'symbol')} ` : get(currencyObj, 'symbol');
  const formattedCurrency = ['NOK', 'CHF', 'AED', 'SEK'].includes(upperCase(currency))
    ? `${currencyObj.symbol} `
    : `${currencyObj.formatOptions.currency} ${currencyObj.symbol}`;
  const feeNumber = get(invoice, 'application_fee', 0);
  const isMaxAmount = parseFloat(refundAmount) > parseFloat(invoiceAmount);
  const hasReason = useMemo(() => {
    if (reason.value === null && reason.value !== 'other') return false;
    if (reason.value === 'other' && !details) return false;
    return true;
  }, [reason.value, details]);
  const disabledRefund = useMemo(() => {
    if (reason.value === null) return true;
    if (selectedPartial && !refundAmount) return true;
    if (reason.value === 'other' && !details) return true;
    return false;
  }, [reason.value, selectedPartial, refundAmount, details]);

  return (
    <S.RefundPaymentPopupWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close_circle" />
        </CloseButton>
      }
    >
      <Modal.Header>Refund Payment</Modal.Header>
      <Modal.Content>
        <S.InfoContainer>
          <InfoIcon className="info-icon" />
          <div>
            <div className="info-description">
              <span className="semi-bold">Refunds take 5-10 days to appear on a customer's statement.</span>
              <br />
              Payment processing fee of {formatMoney(round(feeNumber / 100, 2), symbol)} will not change with the
              refund, but there are no additional fees to refund.
            </div>
            <a
              className="info-learn_more"
              href="https://help.everfit.io/en/articles/6369732-refunds-for-client-purchases"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
          </div>
        </S.InfoContainer>

        <div>
          <S.RefundOption onClick={handleSelectRefundType(REFUND_TYPE.FULL)} selected={selectedFull}>
            <div className="primary-field">
              <S.RadioButton checked={selectedFull} />
              <div>
                <div className="option-label">Full Refund</div>
                <div className="option-description">
                  Refund the full amount:{' '}
                  <span>
                    {formattedCurrency}
                    {invoiceAmount}
                  </span>
                </div>
              </div>
            </div>
          </S.RefundOption>

          <S.RefundOption onClick={handleSelectRefundType(REFUND_TYPE.PARTIAL)} selected={selectedPartial}>
            <div className="primary-field">
              <S.RadioButton checked={selectedPartial} />
              <div>
                <div className="option-label">Partial Refund</div>
                <div className="option-description">Refund a partial amount</div>
              </div>
            </div>
            {selectedPartial && (
              <div className="secondary-field">
                <div className="refund-input-label">Refund amount</div>
                <div className="refund-input-container">
                  <S.NumberInput
                    value={refundAmount}
                    allowNegative={false}
                    decimalSeparator="."
                    decimalScale={2}
                    onValueChange={handleChangeRefundAmount}
                    isNumericString={true}
                    placeholder="Enter Refund amount"
                    error={isConfirmModalOpen && !refundAmount}
                    textError={refundAmount === 0 || parseFloat(refundAmount) > parseFloat(invoiceAmount)}
                  />
                  <div className="refund-currency-text">{formattedCurrency}</div>
                </div>
              </div>
            )}
          </S.RefundOption>
        </div>
        <S.DetailFieldsContainer>
          <div className="detail-fields-label">Reason</div>
          <DropdownOptions
            nullOption={nullOption}
            hasNullOption
            width="100%"
            height="40px"
            options={REFUND_REASONS}
            selectedValue={reason}
            onSelect={handleSelectReason}
            placeholder={'Select a reason'}
            className="refund-reason-dropdown"
            required={isConfirmModalOpen && reason.value === null}
          />
          <div className="detail-fields-label">Details</div>
          <FormItemInput
            type="textarea"
            hideLabel
            placeholder="Add more details about this refund (Optional)"
            value={details}
            onChange={handleChangeDetail}
            required
            isSubmitted={isConfirmModalOpen && reason.value === 'other' && !details}
            isHideErrorMessage
          />
        </S.DetailFieldsContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button className="cancel-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          purple
          className="refund-btn"
          onClick={toggleConfirmModal}
          disabled={(isConfirmModalOpen && disabledRefund) || (selectedPartial && (isMaxAmount || !refundAmount))}
        >
          Refund
        </Button>
      </Modal.Actions>
      <ConfirmRefundPopup
        open={isConfirmModalOpen && hasReason && ((selectedPartial && refundAmount && !isMaxAmount) || selectedFull)}
        onClose={handleConfirm}
        onCloseRefund={toggleConfirmModal}
        invoiceAmount={selectedPartial ? refundAmount : invoiceAmount}
        formattedCurrency={formattedCurrency}
        invoice={invoice}
        reason={reason}
        details={details}
      />
    </S.RefundPaymentPopupWrapper>
  );
}
