import styled, { css } from 'styled-components';
import ButtonIcon from 'shared/ButtonIcon';

export const HeaderArea = styled.div`
  display: flex;
  width: 837px;
  margin: auto;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 38px;
  padding-right: 47px;
`;

export const HeaderTitle = styled.div`
  color: #151619;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
`;

export const HeaderDescription = styled.div`
  color: #4a4d56;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;

export const HeaderLeft = styled.div`
  max-width: 450px;

  ${HeaderTitle} {
    margin-bottom: 4px;
  }
`;

export const HeaderRight = styled.div`
  .buttonClassName {
    button {
      margin-right: 0;
      border-radius: 4px;

      svg {
        margin-right: 3px;
      }
    }
  }
`;

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }
`;
