import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const Wrapper = styled(Modal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  &.ui.modal {
    width: ${({ step }) => (step === 1 ? '590px' : '1105px')};
    .header {
      padding: 21px 30px 19px 30px;
      display: flex;
      align-items: center;
      gap: 25px;
      border-bottom: 1px solid #f0f0f2;
      .label {
        min-width: 122px;
        h3 {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #7b7e91;
          margin: 0;
        }
        h2 {
          font-family: 'Open Sans';
          font-size: 19px;
          font-weight: 600;
          line-height: 29px;
          color: #202353;
          margin: 0;
        }
      }
      .progress {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .step {
          font-family: 'Open Sans';
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          color: #7b7e91;
          background: linear-gradient(90deg, #5158cf 50%, #f0f0f2 0);
          background-size: 200% 100%;
          background-position: 100% 100%;
          transition: all 0.5s ease-out;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &.completed {
            background-position: 0 100%;
            color: #fff;
          }
          .circle {
            width: 35px;
            height: 35px;
            overflow: hidden;
            position: relative;
            text-align: center;
            line-height: 35px;
            cursor: pointer;
            &:after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 100%;
              width: 35px;
              height: 35px;
              box-shadow: 0 0 0 2000px #fff;
            }
          }
          .bar {
            width: 38px;
            height: 35px;
            border-top: 16px solid #fff;
            border-bottom: 17px solid #fff;
            box-sizing: border-box;
          }
        }
      }
    }
    .content {
      height: 555px;
      padding: 0;
      @media only screen and (max-height: 803px) {
        height: calc(100vh - 248px);
      }
    }
    .actions {
      border-top: 1px solid #f0f0f2;
      padding: 14px 30px 15px 30px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-actions {
        .text-error {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          color: #ea314a;
        }
      }
      .right-actions {
        .wrapper-action {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        button {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          height: 30px;
          padding: 0 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          border: none;
          outline: none;
          cursor: pointer;
        }
        .btn-normal {
          background-color: rgba(81, 88, 207, 1);
          color: #fff;
          border: 1px solid rgba(81, 88, 207, 1);
          &:hover {
            background-color: rgba(81, 88, 207, 0.9);
          }
          &:disabled {
            background-color: #eaeef1;
            color: #7b7e91;
            cursor: not-allowed;
            border: none;
          }
        }

        .btn-back {
          border: 1px solid #e1e1ea;
          background-color: #ffffff;
          color: #202353;
        }
      }
    }
  }
`;

export const UploadFile = styled.div`
  position: relative;
  border-radius: 5px;
  border: solid 1px #e1e1ea;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 13px;
  width: 100%;
  height: 44px;

  .name {
    flex: 1 1;
    padding: 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: normal;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #202353;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .browse {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #202353;
    background-color: #f0f0f2;
    padding: 12px 37px;
    z-index: 2;
    width: 117px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #f0f1ff;
      color: #5158cf;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none !important;
    outline: none !important;
    z-index: 1;
    text-indent: -9999px;
    height: 100%;
    width: 100%;
    color: transparent;
  }
`;

export const FistStepContainer = styled.div`
  width: 530px;
  margin: 0 auto;
  padding-top: 50px;

  .form-group {
    line-height: normal;
    margin-bottom: 30px;

    label {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 600;
      line-height: 15px;
      color: #7b7e91;
      margin-bottom: 2px;
    }

    .tip {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #7b7e91;

      a {
        font-weight: 600;
        color: #5158cf;
      }

      img.ui {
        display: inline-block;
      }
    }

    ${UploadFile} {
      margin-bottom: 9px;
    }
  }
  .desc {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #202353;
  }
`;

export const SecondStepContainer = styled.div`
  padding: 10px 0 0 30px;
  height: 100%;
  margin-right: 10px;

  .table-container {
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    height: 100%;
    padding-right: 14px;
    padding-bottom: 20px;
    ::-webkit-scrollbar {
      width: 6px !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1ea !important;
    }
    ::-webkit-scrollbar-track {
      margin-bottom: 20px;
      margin-top: 50px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #fff;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      z-index: 1;
    }
    tbody {
      tr {
        border-bottom: 1px solid #f0f0f2;
        border-top: 1px solid #f0f0f2;
      }
    }
    tr {
      :nth-child(even) {
        background-color: #fbfbfb;
      }

      th {
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: #a3a3b5;
        padding: 0 10px;
        padding-top: 5px;

        :nth-child(1) {
          width: 21%;
        }
        :nth-child(2) {
          width: 17.2%;
        }
        :nth-child(3) {
          width: 19.2%;
        }
        :nth-child(4) {
          width: 9.6%;
        }
      }

      td {
        height: 60px;
        p {
          /* overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          font-family: 'Open Sans'; */
          font-size: 13px;
          font-weight: 400;
          line-height: 19.5px;
          color: #202353;
          padding-left: 10px;
          &.text-bold {
            font-weight: 600;
          }

          &.limit-3-line {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
            margin: 10px 0;
          }
          &.text-macro {
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            .info-nutrient-wrapper {
              margin-left: 10px;
            }
          }
          &.text-invalid {
            font-family: 'Open Sans';
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
            text-align: right;
            color: #ea314a;
            padding: 0;
          }
          &.amount-per-text {
            word-break: break-all;
            margin: 10px 0;
          }
        }
        .text-empty {
          color: #7b7e91;
        }
        &.invalid {
          background: #fff3f3;
          p,
          .text-empty {
            color: #ea314a;
          }
        }
        &.invalid-macro {
          background: #fff3f3;
        }
        &.invalid-other {
          .icon-info {
            svg path {
              fill: #ea314a;
            }
          }
        }
        .nutrition-info-wrapper {
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        .macro-wrapper {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #202353;
          display: flex;
          align-items: center;
          .macro-item {
            padding-right: 14px;
            position: relative;
            .macro-dot {
              width: 2px;
              height: 2px;
              background: #7b7e91;
              border-radius: 50%;
              top: calc(50% - 2px);
              transform: translateX(-50%);
              right: 6px;
              position: absolute;
            }
            &:last-child {
              padding-right: 10px;
              .macro-dot {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.list-failed {
    height: 390px;
    padding-top: 0;

    @media only screen and (max-height: 803px) {
      height: calc(100vh - 411.5px);
    }

    table tr th {
      :nth-child(1) {
        width: 18.1%;
      }
      :nth-child(2) {
        width: 15.3%;
      }
      :nth-child(3) {
        width: 13.4%;
      }
      :nth-child(4) {
        width: 9.6%;
      }
      :nth-child(5) {
        width: 31.1%;
      }
      :last-child {
        text-align: right;
      }
    }

    table tr td p {
      padding-top: 4px;
    }
  }
`;

export const CompletedContainer = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-third-step {
    margin-top: 20px;
  }
  h3 {
    font-family: 'Open Sans';
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    color: #202353;
    margin-bottom: 7px;
  }
  p {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    color: #202353;

    span {
      font-weight: 600;
      color: #5158cf;
    }
  }

  .loading-indicator-add-ingredient {
    padding-top: 0;
    span {
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
      color: #7b7e91;
      opacity: 1;
    }
    .ui.loader:after {
      border-color: #5158cf #e1e1ea #e1e1ea !important;
    }
  }
`;

export const ThirdStepContainer = styled.div`
  .info-complete {
    padding: 30px 30px 25px;
    h3 {
      font-family: 'Open Sans';
      font-size: 21px;
      font-weight: 600;
      line-height: 31.5px;
      color: #202353;
      margin-bottom: 7px;
    }
    p {
      font-family: 'Open Sans';
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px;
      color: #202353;
      span {
        font-weight: 600;
        color: #5158cf;
      }
    }
  }
  .title-failed {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    color: #202353;
    padding: 25px 30px 0;
  }
`;
