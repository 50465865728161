import { getAccessToken } from 'utils/commonFunction';

export const socketAuthenticate = (socket, cb) => {
  if (getAccessToken()) {
    socket
      .emit('authenticate', { token: getAccessToken() }) //send the jwt
      .on('authenticated', () => {
        console.log('Socket authenticated successfully');
        cb();
      })
      .on('unauthorized', err => {
        console.log('Socket authenticated failed', JSON.stringify(err.data));
        cb(err.data);
      });
  }
};
