import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;

  .package-detail__name {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #202353;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const PackageStatusWrapper = styled.span`
  background: #ebedf4;
  border: 1px solid #d8dceb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 9px;
  margin-left: 10px;

  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #202353;

  ${(props) =>
    props.isPublished &&
    css`
      color: #36b24b;
      background: #dff5e1;
      border-color: #36b24b;
      border-radius: 5px;
    `}
`;

