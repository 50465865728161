import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import ReactJoyride, { STATUS, EVENTS } from 'react-joyride';

// actions
import { convertDataToOurModel } from 'components/WorkoutDetailModalAIDemo/convert-data';
import { updateEntities } from 'redux/model/actions';
import {
  updateSections,
  updateTitle,
  updateDescription,
  addExercisesFromAIToRecentOption,
  updateTab,
  clearWorkoutData,
  updateAllExercises,
  updateGenerateWorkoutAIFromPage,
  validateWorkoutData,
  updateTempWorkoutAI,
} from 'redux/workout-builder/actions';
import { mongoObjectId } from 'utils/commonFunction';
import { toggleConfirmModal } from 'actions/modal';
import { createNewAISession, createNewAIConversation, getAIModels } from 'components/WorkoutDetailModalAIDemo/actions';
import { updateFlagWobOnboardingTour } from 'redux/general-settings/actions';

// components
import { Checkbox } from 'shared/FormControl';
import TabBar, { AI_TABS } from './TabBar';
import TellUsMoreModal from './TellUsMore';
import OnboardingTourTooltip from './OnboardingTourTooltip';
import WorkoutBuilderLeftPanel from 'components/WorkoutDetailModal/components/LeftPanel';
import ConfirmModalGroup from 'components/MetricGroupLibrary/Parts/ConfirmModal';
import ConversionItem from 'components/WorkoutDetailModalAIDemo/components/LeftPanel/ConversionItem';
import SendingMsg from 'components/WorkoutDetailModalAIDemo/components/LeftPanel/ConversionItem/SendingMsg';
import ChartInput from 'components/WorkoutDetailModalAIDemo/components/LeftPanel/ChatInput';
import DropdownOption from 'components/BodyMetricChartNew/components/DropdownOption';

// constants
import { KEY_CODE } from 'constants/commonData';
import { Mixpanel } from 'utils/mixplanel';
import { STEPS } from 'components/WorkoutDetailModalAIDemo/constants';

// assets
import { ReactComponent as AIIcon } from 'assets/icons/ai-icon.svg';
import { ReactComponent as ReadIcon } from 'assets/icons/read-book.svg';
import { ReactComponent as TellUsMoreIcon } from 'assets/icons/tell_us_more_icon.svg';

// styles
import * as S from './style';

const PROMPT_LINK = process.env.REACT_APP_AI_PROMPT_ARTICLE_LINK || '#';

const LeftPanel = ({
  dispatch,
  fields,
  user_id,
  exerciseLibrary,
  user_full_name,
  user_email,
  enableAIworkoutModel,
  hasUpdateFromStandardBuilder,
  unitCategoriesByCoach,
  userSelectedUnit,
  allExercises,
  userPreferences,
  updateWorkoutFromAI,
  workoutAIPage,
  isAIOnboardingTour,
  onChangeStatusIsUpdated,
}) => {
  const [currentExercisesLength, setCurrentExerciseLength] = useState(0);
  const [isLoadingSession, setIsLoadingSession] = useState(true);
  const [conversionList, setConversionList] = useState([]);
  const [sessionAI, setSessionAI] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [currentTab, setCurrentTab] = useState(AI_TABS.AI);
  const [showTellMeUs, setShowTellMeUs] = useState(false);
  const [isGeneratedWorkout, setIsGeneratedWorkout] = useState(false);
  const [selectedModel, setSelectedModel] = useState();
  const [allModels, setAllModels] = useState([]);
  const [generateError, setGenerateError] = useState(false);
  const [dataForRequest, setDataForRequest] = useState(null);
  const [isUseJSON, setIsUseJSON] = useState(!!enableAIworkoutModel);
  const [isUseRAG, setIsUseRAG] = useState(!!enableAIworkoutModel);
  const [isUseBAML, setIsUseBAML] = useState(!!enableAIworkoutModel);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      setRunning(!isAIOnboardingTour);
    }, 0);

    return () => {
      clearInterval(interval);
    };
  }, [isAIOnboardingTour]);

  useEffect(() => {
    if (currentTab) {
      dispatch(updateTab(currentTab));
    }
  }, [currentTab]);

  const generateErrorResponse = (resID = null, user_input = '') => {
    const res = [
      {
        id: resID || mongoObjectId(),
        json_output: { title: `Sorry, I cannot generate this workout. Please try again.` },
        isError: true,
        createdAt: moment().toISOString(),
        isAnimated: false,
        isAbleFeedback: Boolean(resID),
        user_input,
      },
      {
        id: mongoObjectId(),
        json_output: {
          title: `You can look how to write a workout prompt <a href=${PROMPT_LINK} class="prompt-link here-link" target="_blank">here</a>`,
        },
        isError: true,
        isAnimated: true,
        createdAt: null,
        isAbleFeedback: false,
      },
    ];
    return res;
  };

  const handleArticleLink = () => {
    Mixpanel.track('click_article_link_ai_workout_builder', { component: workoutAIPage });
  };

  const scrollToEnd = () => {
    const elm = document.getElementById('conversations');
    if (elm) {
      elm.scrollTo({
        top: elm.scrollHeight + 50,
        behavior: 'smooth',
      });
    }
  };

  const handleClickTellUsMore = () => {
    setShowTellMeUs(true);
    Mixpanel.track('open_tell_us_more_ai_workout_builder', { component: workoutAIPage });
  };

  const createNewSession = async () => {
    try {
      setConversionList([]);
      const { data } = await dispatch(createNewAISession({ user_id }));

      setSessionAI(get(data, 'data.id', ''));
      setIsLoadingSession(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRetry = value => {
    onSubmit(value, true);
  };

  const clearAllHereLinks = () => {
    const hereLinks = document.querySelectorAll('.here-link');

    hereLinks.forEach(item => {
      item.removeEventListener('click', handleArticleLink);
    });
  };

  const handleClearAll = () => {
    clearAllHereLinks();
    setIsGeneratedWorkout(false);
    setGenerateError(false);
    createNewSession();
    if (updateWorkoutFromAI) {
      updateWorkoutFromAI({ is_generated_by_ai: false, conversion_id: null });
    }
    onChangeStatusIsUpdated(false);
  };

  const renderConversation = (item, index) => (
    <ConversionItem
      key={item.id}
      item={item}
      sessionId={sessionAI}
      isLastItem={index === conversionList.length - 1}
      dispatch={dispatch}
      onRetry={handleRetry}
      onClearAll={handleClearAll}
      scrollToEnd={scrollToEnd}
      gotoStandardBuilderTab={gotoStandardBuilderTab}
      animatedCallback={animatedCallback}
      workoutAIPage={workoutAIPage}
    />
  );

  const updateCursorElm = (elm, isDisabled) => {
    if (elm) {
      elm.style.cursor = isDisabled ? 'not-allowed' : 'default';
    }
  };

  const updatePointerElm = (elm, isDisabled) => {
    if (elm) {
      elm.style.pointerEvents = isDisabled ? 'none' : 'unset';
    }
  };

  const toggleLoading = (elm, isDisabled) => {
    if (elm) {
      elm.style.visibility = isDisabled ? 'visible' : 'hidden';
    }
  };

  const disableMainPanel = (isDisabled = false) => {
    const mainPanelContainer = document.querySelector('.workoutBuilder__mainPanelContainer');
    const mainPanel = document.querySelector('.workoutBuilder__mainPanel');
    const workoutArrangementContainer = document.querySelector('.workoutBuilder__workoutArrangementContainer');
    const arrangementAction = document.querySelector('.workoutBuilder__workoutArrangementContainer .arrangementAction');
    const workoutArrangement = document.querySelector('.workoutBuilder__workoutArrangement');
    const workoutFooterActions = document.querySelector('.workoutBuilder_footerActions');
    const aiGeneratingLoading = document.querySelector('.ai-generating-loading');

    updateCursorElm(workoutArrangementContainer, isDisabled);
    updateCursorElm(mainPanelContainer, isDisabled);
    updatePointerElm(mainPanel, isDisabled);
    updatePointerElm(workoutArrangement, isDisabled);
    updatePointerElm(arrangementAction, isDisabled);
    updatePointerElm(workoutFooterActions, isDisabled);
    toggleLoading(aiGeneratingLoading, isDisabled);
  };

  const saveTempWorkout = () => {
    if (process.env.REACT_APP_AI_ENABLE_FORCE_RATING === 'true') {
      const wk = dispatch(validateWorkoutData());
      dispatch(updateTempWorkoutAI(wk));
    }
  };

  const onSubmit = async (value, isRetry = false, forceSubmit = false, shouldAddMsg = true) => {
    if (hasUpdateFromStandardBuilder && !isGeneratedWorkout && !forceSubmit) {
      toggleConfirmResetPopup(currentTab, value);
      return;
    }

    if (!value) return;

    const isUseExample = typeof value === 'object';

    let user_input = isUseExample ? value.user_input : value;

    if (isUseJSON) {
      try {
        user_input = JSON.stringify(JSON.parse(value));
      } catch (error) {
        console.error('Invalid JSON');
        return;
      }
    }

    disableMainPanel(true);
    setGenerateError(false);
    setIsSending(true);

    if (shouldAddMsg) {
      setConversionList(s => [
        ...(isRetry ? [] : s),
        {
          id: mongoObjectId(),
          user_input,
          isMe: true,
          createdAt: moment().toISOString(),
          isAnimated: true,
        },
      ]);
    }
    setTimeout(scrollToEnd, 300);
    if (!sessionAI) {
      setDataForRequest(value);
      return;
    }

    try {
      const {
        data: { data },
      } = await dispatch(
        createNewAIConversation({
          session_id: sessionAI,
          user_input,
          model: enableAIworkoutModel ? selectedModel : undefined,
          use_json: enableAIworkoutModel ? isUseJSON : undefined,
          use_example: isUseExample,
          use_rag: enableAIworkoutModel ? isUseRAG : undefined,
          use_baml: enableAIworkoutModel ? isUseBAML : undefined,
        }),
      );
      const conversionId = get(data, 'id');

      if (data.json_output) {
        const dataConverted = await convertDataToOurModel(data.json_output, fields, {
          unitCategoriesByCoach,
          userSelectedUnit,
          userPreferences,
        });

        // JSON.parse(JSON.stringify(dataConverted))
        // this used for avoiding reference data
        const { title, description, selectedSectionIds, exercises, entities, error } = JSON.parse(
          JSON.stringify(dataConverted),
        );

        if (error) {
          setConversionList(s => [...s, ...generateErrorResponse(conversionId, user_input)]);
          setGenerateError(true);
        } else {
          setIsGeneratedWorkout(true);
          dispatch(addExercisesFromAIToRecentOption(exercises));
          dispatch(updateEntities(entities, [], false));
          dispatch(updateSections(selectedSectionIds));
          dispatch(updateTitle(title));
          dispatch(updateDescription(description));
          dispatch(updateGenerateWorkoutAIFromPage(workoutAIPage));
          const titleHtml = title ? `<b> ${title}</b>` : '';
          setConversionList(s => [
            ...s,
            {
              ...data,
              json_output: {
                ...data.json_output,
                title: `I have built your workout${titleHtml}. Now, you can edit the workout or switch to the <span class="standard-builder-text">Standard Builder</span> to add more exercises.`,
              },
              createdAt: moment().toISOString(),
              isUseExample,
              isAnimated: false,
            },
          ]);
          toast('New workout generated.');
          if (updateWorkoutFromAI) {
            updateWorkoutFromAI({
              is_generated_by_ai: true,
              conversion_id: conversionId,
            });
            saveTempWorkout();
          }
        }
      }
    } catch (error) {
      const conversionId = get({ ...error }, 'response.data.additional.conversion_id');
      setConversionList(s => [...s, ...generateErrorResponse(conversionId, user_input)]);
      setGenerateError(true);
    } finally {
      setIsSending(false);
      setTimeout(scrollToEnd, 300);
      disableMainPanel(false);
    }
  };

  const fetchModels = async () => {
    try {
      const { data: { data = [] } = {} } = await dispatch(getAIModels());
      const list = (data || []).map(({ id, name }) => ({ value: id, label: name }));
      setAllModels(list);
      if (list[0]) {
        setSelectedModel(list[0].value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onEscPressed = event => {
    if (event.keyCode === KEY_CODE.esc) {
      dispatch(toggleConfirmModal(false));
    }
  };

  useEffect(() => {
    if (sessionAI && dataForRequest) {
      onSubmit(dataForRequest, false, true, false);
      setDataForRequest(null);
    }
  }, [dataForRequest, sessionAI]);

  useEffect(() => {
    try {
      const allPromise = [createNewSession];
      if (enableAIworkoutModel) {
        allPromise.push(fetchModels);
      }
      Promise.allSettled(allPromise.map(item => item()));
    } catch (error) {
      console.error(error);
    }

    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, []);

  useEffect(() => {
    const hereLinks = document.querySelectorAll('.here-link');

    hereLinks.forEach(item => {
      item.removeEventListener('click', handleArticleLink);
      item.addEventListener('click', handleArticleLink);
    });

    return () => {
      hereLinks.forEach(item => {
        item.removeEventListener('click', handleArticleLink);
      });
    };
  }, [conversionList]);

  useEffect(() => {
    if (currentExercisesLength !== exerciseLibrary.length) {
      if (exerciseLibrary.length) {
        const newExercise = {
          ...exerciseLibrary[exerciseLibrary.length - 1],
          is_existing: true,
        };
        dispatch(updateAllExercises([...allExercises, newExercise]));
      }
      setCurrentExerciseLength(exerciseLibrary.length);
    }
  }, [exerciseLibrary]);

  const onConfirmedResetAll = (nextTab, inputValue = null) => () => {
    dispatch(clearWorkoutData());
    setConversionList([]);
    setIsGeneratedWorkout(false);
    setGenerateError(false);
    setCurrentTab(nextTab);
    if (inputValue) {
      onSubmit(inputValue, false, true);
    }
    onChangeStatusIsUpdated(false);
  };

  const toggleConfirmResetPopup = (nextTab, inputValue = null) => {
    dispatch(
      toggleConfirmModal(
        true,
        <ConfirmModalGroup
          modalId="change-to-ai-modal"
          title="Your current workout will be reset"
          content="By using the AI Assistant, the current workout you have created will be cleared out and replaced. Are you sure you want to proceed?"
          onConfirm={onConfirmedResetAll(nextTab, inputValue)}
          hasCloseIcon
          noIcon
          confirmButtonTitle="Proceed"
        />,
      ),
    );
  };

  const handleChangeTab = tab => {
    setCurrentTab(tab);
  };

  const gotoStandardBuilderTab = () => {
    setCurrentTab(AI_TABS.STANDARD);
  };

  const animatedCallback = mId => {
    setConversionList(list =>
      list.map(item => {
        if (item.id === mId) {
          return { ...item, isAnimated: true };
        }
        return item;
      }),
    );
  };

  const handleJoyrideCallback = data => {
    const { status, type } = data || {};

    if (STATUS.FINISHED === status && EVENTS.TOUR_END === type && !isAIOnboardingTour) {
      dispatch(updateFlagWobOnboardingTour());
    }
  };

  const isFirstMsg = conversionList.length < 1;
  const showAIAssistantText = !isLoadingSession && (!isFirstMsg || isGeneratedWorkout);
  const isViewStandardTab = currentTab === AI_TABS.STANDARD;

  return (
    <S.Wrapper>
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous
        disableCloseOnEsc
        disableOverlayClose
        floaterProps={{
          hideArrow: true,
        }}
        run={running}
        steps={STEPS}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        tooltipComponent={OnboardingTourTooltip}
      />
      <S.Content>
        <TabBar currentTab={currentTab} onChangeTab={handleChangeTab} isSending={isSending} />
        {/* <button style={{ display: 'none' }} id="resetAIBuilderBtn" onClick={() => onConfirmedResetAll(currentTab)()} /> */}
        <div
          className={classNames('conversations', {
            'has-list': showAIAssistantText,
            hidden: isViewStandardTab,
          })}
          id="conversations"
        >
          {(isLoadingSession || isFirstMsg) && !isGeneratedWorkout && !isSending ? (
            <div className="loading-wrap" id="loadingWrap">
              <AIIcon className="svg-ani" />
              <h5>Let’s build a workout</h5>
              <p>{'I’ll assist you with creating a workout faster!'}</p>

              {enableAIworkoutModel && !!selectedModel && (
                <div className="json-model">
                  <DropdownOption
                    selected={selectedModel}
                    onSelectFilter={setSelectedModel}
                    options={allModels}
                    className="models-filter"
                  />
                  <div className="checkbox-wrapper">
                    <Checkbox title="Use JSON" checked={isUseJSON} onChange={() => setIsUseJSON(s => !s)} />
                    <Checkbox title="Use RAG" checked={isUseRAG} onChange={() => setIsUseRAG(s => !s)} />
                    <Checkbox title="Use BAML" checked={isUseBAML} onChange={() => setIsUseBAML(s => !s)} />
                  </div>
                </div>
              )}
              <ChartInput
                workoutAIPage={workoutAIPage}
                onSendMessage={onSubmit}
                isSending={isSending}
                isFirstMessage={isFirstMsg}
                showExample={true}
                // disabled={!sessionAI || allExercise.length === 0}
                scrollToEnd={scrollToEnd}
                isOnboardingTour={!isAIOnboardingTour}
                toggleConfirmModal={toggleConfirmModal}
                dispatch={dispatch}
              />
              <a
                href={PROMPT_LINK}
                className="read-more-link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleArticleLink}
              >
                <ReadIcon />
                Learn how to write a workout prompt
              </a>
            </div>
          ) : (
            <>{conversionList.map(renderConversation)}</>
          )}
          {isSending && (!isFirstMsg || isGeneratedWorkout) && <SendingMsg isFirstMsg={!isGeneratedWorkout} />}
          {((!isFirstMsg && !isSending && !isGeneratedWorkout) || generateError) && (
            <ChartInput
              onSendMessage={onSubmit}
              isSending={isSending}
              // disabled={!sessionAI || allExercise.length === 0}
              isFirstMessage
              showExample={false}
              scrollToEnd={scrollToEnd}
              workoutAIPage={workoutAIPage}
            />
          )}
        </div>
        {showAIAssistantText && (
          <div className={classNames('tell-us-more-wrapper', { hidden: isViewStandardTab })}>
            <div className="tell-us-more" onClick={handleClickTellUsMore}>
              <TellUsMoreIcon />
              Tell us more
            </div>
          </div>
        )}
        {isViewStandardTab && <WorkoutBuilderLeftPanel className="ai-builder-content" />}
      </S.Content>
      <TellUsMoreModal
        onClose={() => {
          setShowTellMeUs(false);
        }}
        open={showTellMeUs}
        dispatch={dispatch}
        workoutAIPage={workoutAIPage}
        nameUser={user_full_name}
        emailUser={user_email}
      />
    </S.Wrapper>
  );
};

const getUnitByCoach = unitCategories => {
  const codes = ['mass', 'length', 'distance'];
  const units = {};

  for (const item of unitCategories) {
    if (codes.includes(item.unique_code)) {
      (item.units || []).forEach(unitItem => {
        units[unitItem.unique_code] = { ...unitItem };
      });
    }
  }
  return units;
};

const mapState = ({ rootReducer, user }) => {
  const { exercise, model, workoutBuilder, unit } = rootReducer;
  const { ExerciseLibrary, Superset } = model.toJS();
  const { selectedWorkout, hasUpdateFromStandardBuilder, allExercises } = workoutBuilder.toJS();
  const enableAIworkoutModel = Boolean(process.env.REACT_APP_AI_ENABLE_SELECT_MODEL) || false;
  const isEmptyWorkout = isEmpty(Superset) && get(selectedWorkout, 'sections', []).length === 0;
  const { weight = {}, height = {}, distance = {} } = get(user, 'preferences', {});
  const unitCategoriesByCoach = getUnitByCoach(get(unit, 'unit_categories', []));

  return {
    fields: exercise.fields,
    user_id: user._id,
    exerciseLibrary: Object.values(ExerciseLibrary || {}),
    user_full_name: user && user.full_name,
    user_email: user && user.email,
    userSelectedUnit: { weight, height, distance },
    unitCategoriesByCoach: unitCategoriesByCoach,
    enableAIworkoutModel: enableAIworkoutModel,
    allExercises: allExercises || [],
    userPreferences: get(user, 'preferences', {}),
    hasUpdateFromStandardBuilder: isEmptyWorkout ? false : hasUpdateFromStandardBuilder,
  };
};

export default connect(mapState)(LeftPanel);
