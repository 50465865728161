export const CATEGORIES_FILTER_OPTIONS = [
  {
    value: 'All',
    key: 'all',
  },
  {
    value: 'Forum',
    key: 'forum',
  },
  {
    value: 'Training',
    key: 'training',
  },
  {
    value: 'Task & Habit',
    key: 'task_habit',
  },
  {
    value: 'Nutrition',
    key: 'nutrition',
  },
  {
    value: 'Progress Photo',
    key: 'progress_photo',
  },
  {
    value: 'Payment',
    key: 'payment',
  },
  {
    value: 'Admin',
    key: 'admin',
  },
];

export const PAYMENT_FILTER_OPTIONS_DEFAULT = [
  {
    value: 'all',
    label: 'All States',
  },
  {
    value: 'successful_payment',
    label: 'Successful Payment',
  },
  {
    value: 'declined_payment',
    label: 'Declined Payment',
  },
  {
    value: 'activate_payment',
    label: 'Activate Purchase',
  },
  {
    value: 'cancellation',
    label: 'Cancellation',
  },
  {
    value: 'trial',
    label: 'Trial',
  },
];

export const PAYMENT_FILTER_OPTIONS_OWNER_WORKSPACE = [
  ...PAYMENT_FILTER_OPTIONS_DEFAULT,
  {
    value: 'payment_summary',
    label: 'Payment Summary',
  },
];
