import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { Button } from 'shared/FormControl';
import SingleBanner from 'shared/SingleBanner';
import { NewSearchInput } from 'shared/SearchInput';
import { SharedTooltip } from 'shared/SharedTooltip';

import { CDN_URL } from 'constants/commonData';

import BannerSrc from 'assets/images/form_banner.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MiniSearchIcon } from 'assets/icons/mini-search-icon.svg';

import * as S from './style';

const ButtonContainer = ({
  onOpenAddNewForm,
  showSearchBox,
  handleSearch,
  handleClearSearch,
  handleKeyPress,
  handleOpenSearchBox,
}) => {
  return (
    <>
      <S.Button text="Create New Form" icon={<PlusIcon />} onClick={onOpenAddNewForm} data-btn-banner="create-form" />
      {showSearchBox ? (
        <NewSearchInput
          placeholder="Search by keyword or name"
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
          onKeyPress={handleKeyPress}
        />
      ) : (
        <>
          <Button
            className="button--search-box-trigger"
            onClick={handleOpenSearchBox}
            data-tip
            data-for="form-banner-search-tooltip"
          >
            <MiniSearchIcon />
          </Button>
          <SharedTooltip content="Search" id="form-banner-search-tooltip" />
        </>
      )}
    </>
  );
};

const Banner = ({ onOpenAddNewForm, isHideFormBanner, onHideFormBanner, changeQueryParams }) => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isHideBanner, setIsHideBanner] = useState(true);

  useEffect(() => {
    setIsHideBanner(isHideFormBanner);
  }, [isHideFormBanner]);

  const handleHideBanner = () => {
    onHideFormBanner && onHideFormBanner();
  };

  const handleOpenSearchBox = () => {
    setShowSearchBox(true);
  };

  const handleSearch = (event, { value }) => {
    changeQueryParams({
      page: 1,
      text_search: value,
    });
  };

  const handleClearSearch = () => {
    changeQueryParams({
      page: 1,
      text_search: '',
    });
  };

  const handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 });
    }
  };

  return (
    !isHideBanner && (
      <S.BannerWrapper>
        <SingleBanner
          arrowIcon={`${CDN_URL}/images/arrow_up_7e7e7e.svg`}
          articleIcon={`${CDN_URL}/images/learn_more_icon.svg`}
          articleLink="https://help.everfit.io/en/articles/6633667-how-to-create-forms-questionnaires"
          bannerImageSrc={BannerSrc}
          bannerLabel="FORMS AND QUESTIONNAIRES"
          bannerTitle="Check-in & Engage Clients Seamlessly"
          bannerDescription="Create different types of questionnaires to engage with clients at a deeper level and boost your retention rate."
          customClasses="bannerContainer"
          buttonContent={
            <ButtonContainer
              onOpenAddNewForm={onOpenAddNewForm}
              showSearchBox={showSearchBox}
              handleSearch={handleSearch}
              handleClearSearch={handleClearSearch}
              handleKeyPress={handleKeyPress}
              handleOpenSearchBox={handleOpenSearchBox}
            />
          }
          onSetIsHideBanner={handleHideBanner}
        />
      </S.BannerWrapper>
    )
  );
};

export default Banner;
