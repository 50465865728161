import React, { useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

// Components
import TagChanged from '../TagChanged';
import AddGoalPopup from '../AddGoalPopup';

// Constants
import { DEFAULT_COLOR, KEYS_METRIC, METRIC_CHANGED_STATUS } from 'components/BodyMetricChartNew/constants';
import {
  convertFtToFtAndInch,
  convertMinToFromHourMin,
  convertUnit,
  formatMinValueToHM,
  roundNumberBodyMetric,
} from 'utils/commonFunction';
import { convertSecToSecMin, formatSecValueToSM } from 'components/BodyMetricChartNew/chartHelper';

// Assets
import { ReactComponent as GoalIcon } from 'assets/icons/goal-line.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-edit-goal.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_upward.svg';

import * as S from './style';

const KeysMetric = props => {
  const { bodyMetric, metricTypes, updateBodyMetricTarget, clientId, customBrandingColor, isLoading } = props;
  const {
    target,
    metricSettings,
    unit: unitDefaultTitle = '',
    mid: metricId,
    isGettingTarget,
    isModeCompare,
    average,
    isGettingChartData,
    isGettingSetting,
  } = bodyMetric;
  const { key_metrics = [], unit: unitSetting = {}, color = '' } = metricSettings;
  const [isShowGoalPopup, setIsShowGoalPopup] = useState(false);
  const [newGoal, setNewGoal] = useState('');

  const unitDefault = useMemo(() => {
    const metric = metricTypes.find(item => item._id === metricId) || {};
    return metric.unit;
  }, []);

  const handleConvert = data => {
    return convertUnit(get(data, 'value', 0), get(data, 'unit_data', null) || unitDefault, unitSetting);
  };

  const toggleGoalPopup = () => {
    if (isLoading) return;
    setIsShowGoalPopup(s => !s);
  };

  const showedUnit = unitSetting ? unitSetting.title : unitDefaultTitle;
  const isFtUnit = showedUnit === 'ft';
  const isMinUnit = showedUnit === 'min';
  const isSecUnit = showedUnit === 'sec';

  const metricValues = useMemo(() => {
    const { starting: firstData = null, ending: lastData = null } = target || {};
    const starting = firstData ? handleConvert(firstData) : '';
    const current = lastData ? handleConvert(lastData) : '';
    return { current, starting };
  }, [target, showedUnit]);

  const dataChanged = useMemo(() => {
    const { current, starting } = metricValues;
    const { starting: firstData = null, ending: lastData = null } = target || {};
    if (current === '' || get(firstData, 'day') === get(lastData, 'day')) return null;

    const percent = ((current - starting) / starting) * 100;
    const status = percent > 0 ? METRIC_CHANGED_STATUS.INCREASE : METRIC_CHANGED_STATUS.DECREASE;
    return {
      status: percent === 0 ? METRIC_CHANGED_STATUS.NO : status,
      percent: percent === 0 ? percent : Math.abs(percent).toFixed(1),
    };
  }, [metricValues, target]);

  const [showCurrentKeyMetric, showAverageKeyMetric, showGoalKeyMetric, showStartingKeyMetric] = useMemo(() => {
    return KEYS_METRIC.map(item => key_metrics.includes(item.key));
  }, [key_metrics]);

  const handleSavingGoal = async value => {
    try {
      setIsShowGoalPopup(false);
      await updateBodyMetricTarget({
        client: clientId,
        unique_code: bodyMetric.unique_code,
        value,
        unit: showedUnit,
      });
      setNewGoal(value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    isShowGoalPopup && toggleGoalPopup();
    // update goal if unit change
    const value = get(target, 'data', null);
    const goal = value !== null ? handleConvert(value) : '';
    setNewGoal(goal);
  }, [showedUnit, target]);

  const progressSinceStart = metricValues.starting - metricValues.current;
  const progressUntilGoal = newGoal - metricValues.current;

  const formatNumber = (number = 0) => {
    const num = Math.abs(number);
    return Number.isInteger(num) ? num : num.toFixed(1);
  };

  const renderFtAndInch = (data, withStyle = true, allowFt0 = false) => {
    if (data.ft === '' && data.inch === '') {
      return (
        <>
          {allowFt0 ? 0 : <span className="empty-line">—</span>} {withStyle ? <S.Unit>ft </S.Unit> : 'ft '}
        </>
      );
    }
    return (
      <>
        {data.ft > 0 && (
          <>
            {data.ft} {withStyle ? <S.Unit>ft </S.Unit> : 'ft '}
          </>
        )}
        {data.inch > 0 && (
          <>
            {data.inch} {withStyle ? <S.Unit>in</S.Unit> : 'in'}
          </>
        )}
      </>
    );
  };

  const renderMin = value => {
    const { min, hour } = convertMinToFromHourMin(value);
    if (!min && !hour) {
      return (
        <>
          <span className="empty-line">—</span>
          <S.Unit> min </S.Unit>
        </>
      );
    }

    return (
      <>
        {hour > 0 ? (
          <>
            {hour} <S.Unit>h </S.Unit>
          </>
        ) : (
          ''
        )}
        {min > 0 ? (
          <>
            {Math.round(min)} <S.Unit>min</S.Unit>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderSec = value => {
    const { sec, min } = convertSecToSecMin(value);

    if (!sec && !min) {
      return (
        <>
          <span className="empty-line">—</span>
          <S.Unit> sec </S.Unit>
        </>
      );
    }

    return (
      <>
        {min > 0 ? (
          <>
            {Math.round(min)} <S.Unit>min </S.Unit>
          </>
        ) : (
          ''
        )}
        {sec > 0 ? (
          <>
            {Math.round(sec)} <S.Unit>sec</S.Unit>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  const currFtInch = isFtUnit ? convertFtToFtAndInch(metricValues.current || 0) : {};
  const avgFtInch = isFtUnit ? convertFtToFtAndInch(average || 0) : {};
  const goalFtInch = isFtUnit ? convertFtToFtAndInch(newGoal || 0) : {};
  const startingFt = isFtUnit ? convertFtToFtAndInch(metricValues.starting || 0) : {};
  const progressUntilGoalFtInch = isFtUnit ? convertFtToFtAndInch(Math.abs(progressUntilGoal) || 0) : {};
  const progressSinceStartFtInch = isFtUnit ? convertFtToFtAndInch(Math.abs(progressSinceStart) || 0) : {};
  // const dateStart = target.starting ? moment(target.starting.day, 'MM-DD-YYYY') : null;
  // const dateCompare = dateStart ? dateStart.format(dateStart.isSame(moment(), 'year') ? 'MMM D' : 'MMM D, YYYY') : '';

  if (
    (!showCurrentKeyMetric && !showGoalKeyMetric && !showStartingKeyMetric) ||
    isGettingTarget ||
    isGettingChartData ||
    isGettingSetting
  )
    return null;

  return (
    <S.Wrapper isModeCompare={isModeCompare}>
      {showCurrentKeyMetric && (
        <S.CurrentWrapper isEmpty={!metricValues.current}>
          <S.KeyLabel>Current</S.KeyLabel>
          {!isGettingTarget && (
            <>
              <S.Number current>
                {isFtUnit || isMinUnit || isSecUnit ? (
                  isFtUnit ? (
                    renderFtAndInch(currFtInch)
                  ) : isMinUnit ? (
                    renderMin(formatNumber(metricValues.current))
                  ) : (
                    renderSec(formatNumber(metricValues.current))
                  )
                ) : (
                  <>
                    {roundNumberBodyMetric(metricValues.current, 1) || <span className="empty-line">—</span>}{' '}
                    <S.Unit> {showedUnit}</S.Unit>
                  </>
                )}
              </S.Number>
              {!!dataChanged && (
                <>
                  <S.Tag data-tip data-for="current-metric-changed-tooltip">
                    <TagChanged info={dataChanged} />
                    <ReactTooltip
                      className="app-tooltip"
                      id="current-metric-changed-tooltip"
                      effect="solid"
                      place="bottom"
                    >
                      <S.TooltipStyle>Compared to Starting value</S.TooltipStyle>
                    </ReactTooltip>
                  </S.Tag>
                </>
              )}
            </>
          )}
        </S.CurrentWrapper>
      )}
      {showAverageKeyMetric && (
        <S.CurrentWrapper isEmpty={!average} className="average-wrapper">
          <S.KeyLabel style={{ color: `${color || customBrandingColor || DEFAULT_COLOR}` }}>Daily Average</S.KeyLabel>
          {!isGettingChartData && (
            <>
              <S.Number current>
                {isFtUnit || isMinUnit || isSecUnit ? (
                  isFtUnit ? (
                    renderFtAndInch(avgFtInch)
                  ) : isMinUnit ? (
                    renderMin(formatNumber(average))
                  ) : (
                    renderSec(formatNumber(average))
                  )
                ) : (
                  <>
                    {roundNumberBodyMetric(average, 1) || <span className="empty-line">—</span>}{' '}
                    <S.Unit> {showedUnit}</S.Unit>
                  </>
                )}
              </S.Number>
            </>
          )}
        </S.CurrentWrapper>
      )}
      {showGoalKeyMetric && (
        <S.GoalWrapper isEmpty={!goalFtInch || !newGoal}>
          <S.KeyLabel>
            <GoalIcon className="goal-icon" />
            Goal
          </S.KeyLabel>
          {!isGettingTarget && (
            <S.StatWrapper>
              <S.Number data-tip data-for="goal-metric-on-tooltip">
                {isFtUnit || isMinUnit || isSecUnit ? (
                  isFtUnit ? (
                    renderFtAndInch(goalFtInch)
                  ) : isMinUnit ? (
                    renderMin(newGoal)
                  ) : (
                    renderSec(newGoal)
                  )
                ) : (
                  <>
                    {roundNumberBodyMetric(newGoal, 1) || <span className="empty-line">—</span>}{' '}
                    <S.Unit>{showedUnit}</S.Unit>
                  </>
                )}
              </S.Number>
              <S.IconWrapper data-tip data-for="add-goal-on-tooltip" isLoading={isLoading}>
                <EditIcon className="pencil" onClick={toggleGoalPopup} />
                <ReactTooltip className="app-tooltip" id="add-goal-on-tooltip" effect="solid" place={'top'}>
                  <S.TooltipStyle>{newGoal ? 'Edit' : 'Add'} Goal</S.TooltipStyle>
                </ReactTooltip>
              </S.IconWrapper>
              {!!newGoal && (
                <ReactTooltip className="app-tooltip" id="goal-metric-on-tooltip" effect="solid" place={'bottom'}>
                  <S.TooltipStyle>
                    Progress until Goal:{' '}
                    <S.BoldText>
                      {isFtUnit ? (
                        renderFtAndInch(progressUntilGoalFtInch, false, true)
                      ) : (
                        <>
                          {isMinUnit || isSecUnit
                            ? isSecUnit
                              ? formatSecValueToSM(formatNumber(progressUntilGoal))
                              : formatMinValueToHM(formatNumber(progressUntilGoal))
                            : formatNumber(roundNumberBodyMetric(progressUntilGoal, 1), showedUnit)}{' '}
                          {isMinUnit || isSecUnit ? '' : showedUnit}
                        </>
                      )}
                    </S.BoldText>
                    {progressUntilGoal !== 0 && (
                      <S.IconWrapper isIncrease={metricValues.current < newGoal}>
                        <ArrowIcon className="arrow-icon" width={16} height={16} />
                      </S.IconWrapper>
                    )}
                  </S.TooltipStyle>
                </ReactTooltip>
              )}
            </S.StatWrapper>
          )}
          {isShowGoalPopup && (
            <AddGoalPopup
              isOpen
              currentValue={newGoal === 0 ? '' : newGoal}
              metricUnit={{
                metric_code: metricSettings.metric_code,
                unit: showedUnit,
              }}
              onClose={toggleGoalPopup}
              onSave={handleSavingGoal}
            />
          )}
        </S.GoalWrapper>
      )}
      {showStartingKeyMetric && (
        <S.StartingWrapper data-tip data-for="starting-metric-on-tooltip">
          <S.KeyLabel>Starting</S.KeyLabel>
          {!isGettingTarget && (
            <S.Number>
              {isFtUnit || isMinUnit || isSecUnit ? (
                isFtUnit ? (
                  renderFtAndInch(startingFt)
                ) : isMinUnit ? (
                  renderMin(formatNumber(metricValues.starting))
                ) : (
                  renderSec(formatNumber(metricValues.starting))
                )
              ) : (
                <>
                  {roundNumberBodyMetric(metricValues.starting, 1) || <span className="empty-line">—</span>}{' '}
                  <S.Unit>{showedUnit}</S.Unit>
                </>
              )}
            </S.Number>
          )}
          {metricValues.starting && (
            <ReactTooltip
              className="app-tooltip start-tooltip"
              id="starting-metric-on-tooltip"
              effect="solid"
              place={'bottom'}
            >
              <S.TooltipStyle>
                Progress since Start:{' '}
                <S.BoldText>
                  {isFtUnit ? (
                    renderFtAndInch(progressSinceStartFtInch, false, true)
                  ) : (
                    <>
                      {isMinUnit || isSecUnit
                        ? isSecUnit
                          ? formatSecValueToSM(formatNumber(progressSinceStart))
                          : formatMinValueToHM(formatNumber(progressSinceStart))
                        : formatNumber(roundNumberBodyMetric(progressSinceStart, 1), showedUnit)}{' '}
                      {isMinUnit || isSecUnit ? '' : showedUnit}
                    </>
                  )}
                </S.BoldText>{' '}
                {progressSinceStart !== 0 && (
                  <S.IconWrapper isIncrease={metricValues.current > metricValues.starting}>
                    <ArrowIcon className="starting-arrow-icon" width={16} height={16} />
                  </S.IconWrapper>
                )}
              </S.TooltipStyle>
            </ReactTooltip>
          )}
        </S.StartingWrapper>
      )}
    </S.Wrapper>
  );
};

export default KeysMetric;
