import styled, { css } from 'styled-components';

import { FormGroup } from 'shared/FormControl';

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  .label__maxLength {
    opacity: 0.3;
  }
`;

export const FormControl = styled(FormGroup)`
  margin-bottom: 0;
  input {
    background-color: #fafbfc;
    border: 1px solid #dadfea;
    height: 36px;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
    padding-right: 5px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${props =>
      props.addOnsButton &&
      css`
        padding-right: 50px;
      `}
  }

  textarea {
    background: #ffffff;
    border: 1px solid #dcdcde;
    box-sizing: border-box;
    border-radius: 4px;
    color: #323c47;
    font-size: 12px;
    line-height: 18px;
    padding: 5px 15px;
    outline: none !important;
    width: 100%;
    font-weight: normal;
    font-family: Open Sans;
    height: 134px;
    resize: none;

    &::placeholder {
      color: #969fb5;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .formItem__input {
    position: relative;

    &--addOns {
      position: absolute;
      right: 15px;
      top: 50%;
      background-repeat: no-repeat;
      background-position: center;
      transform: translateY(-50%);
    }
  }

  &.error {
    input:hover:not(:disabled),
    input:focus {
      background: rgba(234, 49, 74, 0.05);
      border-color: #ea314a;
    }

    input {
      background: rgba(234, 49, 74, 0.05);
      ::placeholder {
        color: #ff1b1b;
      }
    }
  }
`;

export const AddOnLabel = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #bebebe;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  ${FormControl} {
    :first-child {
      grid-column-start: 1;
      grid-column-end: span 2;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;

  .add-section-form__cancel {
    /* font-weight: 600; */
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    cursor: pointer;
  }

  .add-section-form__add-btn {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #5158cf;
    border-color: #5158cf;
    padding: 7px 35px;
    margin-left: 25px;

    :hover {
      background-color: #5158cf;
      color: #ffffff;
    }
  }
`;

export const Wrapper = styled.div``;
