import React from 'react';
import ReactTooltip from 'react-tooltip';

import * as S from './styles';

export default function IconButton(props) {
  const { icon, tooltip, tooltipKey, delayShow = 300, ...other } = props;
  return (
    <S.Wrapper {...other} data-tip data-for={tooltipKey}>
      {tooltip && (
        <ReactTooltip id={tooltipKey} effect="solid" place="top" delayShow={delayShow}>
          <p>{tooltip}</p>
        </ReactTooltip>
      )}
      {icon}
    </S.Wrapper>
  );
}
