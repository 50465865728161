import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import UpgradePathButton from '../UpgradePathButton';

export const ModalWrapper = styled(Modal)`
  border-radius: 8px;
  width: 646px !important;

  .content {
    border-radius: 8px;
    padding: 45px !important;
    background-color: red;

    ${props =>
      props.src &&
      css`
        width: 646px !important;
        height: 656px !important;
        background: url(${props.src}) !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
      `}
  }

  .upgradeModal__upgradeBtn {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }
`;

export const SUpgradePathButton = styled(UpgradePathButton)`
  padding: 9px 47px;
  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 19.5px;
    text-transform: initial;
  }
`;

export const NoAccessTitle = styled.div`
  color: #000;
  font-size: 26px;
  line-height: 39px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`;

export const NoAccessDescription = styled.div`
  display: block;
  color: #000;
  width: 444px;
  margin: 0 auto 20px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const Content = styled.div``;
