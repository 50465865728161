import { COUNTDOWN_TYPES } from './action';

const initialState = {
  isRequestCountdown: false,
  isRequestTemplate: false,
  countdownList: [],
  templateList: [],
  currentGoal: null,
  totalCountdown: 0,
  loadingCountdowns: false,
  pageCountdowns: 1,
};

const clientCountdown = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case COUNTDOWN_TYPES.RESET_CLIENT_GOAL_COUNTDOWN:
      return Object.assign({}, state, {
        isRequestCountdown: false,
        isRequestTemplate: false,
        countdownList: [],
        templateList: [],
        currentGoal: null,
        totalCountdown: 0,
        loadingCountdowns: false,
        pageCountdowns: 1,
      });
    case COUNTDOWN_TYPES.FETCH_COUNTDOWN_LIST_REQUEST:
      return Object.assign({}, state, { isRequestCountdown: true, loadingCountdowns: true });
    case COUNTDOWN_TYPES.FETCH_COUNTDOWN_LIST_FAILED:
      return Object.assign({}, state, { isRequestCountdown: false, loadingCountdowns: false });
    case COUNTDOWN_TYPES.FETCH_COUNTDOWN_TEMPLATE_LIST_REQUEST:
      return Object.assign({}, state, { isRequestTemplate: true });

    case COUNTDOWN_TYPES.FETCH_COUNTDOWN_LIST_SUCCESS:
      return Object.assign({}, state, {
        isRequestCountdown: false,
        loadingCountdowns: false,
        countdownList: payload.data,
        totalCountdown: payload.total,
        pageCountdowns: payload.page,
      });

    case COUNTDOWN_TYPES.FETCH_GOAL_LIST_SUCCESS:
      return Object.assign({}, state, { isRequestCountdown: false, currentGoal: payload });

    case COUNTDOWN_TYPES.FETCH_COUNTDOWN_TEMPLATE_LIST_SUCCESS:
      return Object.assign({}, state, { isRequestTemplate: false, templateList: payload });

    case COUNTDOWN_TYPES.ADD_COUNTDOWN_SUCCESS:
      return Object.assign({}, state, { countdownList: [payload, ...state.countdownList] });

    case COUNTDOWN_TYPES.UPDATE_COUNTDOWN_SUCCESS:
    case COUNTDOWN_TYPES.REMOVE_COUNTDOWN_SUCCESS:
      return Object.assign({}, state, { countdownList: payload });

    default:
      return state;
  }
};

export default clientCountdown;
