import React from 'react';
import { connect } from 'react-redux';

import { toggleFullscreenModal } from 'redux/program-library-and-templates/actions';

import { ReactComponent as WorkoutHeaderIcon } from 'assets/icons/program_workout_plan_header.svg';

import * as S from './styles';

const Header = props => {
  const { isHideBanner, toggleFullscreenModal } = props;

  return (
    <S.HeaderWrapper isHideBanner={isHideBanner}>
      <div className="program-header-title">Program Library</div>
      {isHideBanner && (
        <div className="program-explore-banner-header-wrapper">
          <div className="program-explore-banner-header">
            <div className="program-header-icon">
              <WorkoutHeaderIcon />
            </div>
            <div className="program-explore-title">Save time by exploring curated program templates</div>
            <button onClick={toggleFullscreenModal} className="program-explore-templates-button">
              <span>Explore Templates</span>
            </button>
          </div>
        </div>
      )}
    </S.HeaderWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      generalSettings: { loaded = false, is_hide_program_library_banner = false } = {},
      permission: { program = false },
    } = {},
  } = state;

  return {
    isHideBanner: loaded && is_hide_program_library_banner && program,
  };
};

const mapDispatchToProps = {
  toggleFullscreenModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
