import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const EXERCISE_CONTAINER_HEIGHT = 53;
export const EXERCISE_CONTAINER_MARGIN = 2;
export const ASSIGNMENT_CONTAINER_HEIGHT = 54;
export const ASSIGNMENT_CONTAINER_MARGIN = 6;

export default {
  popup: {
    borderRadius: '0',
    padding: '10px 30px 10px 10px',
    borderRadius: '8px',
    background: '#2D2E2D',
    color: '#ffffff',
  },
};

export const AssignmentActionsContainer = styled.div`
  width: 150px;
  padding: 15px 0;

  .actions__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 5px 0 5px 15px;

    img {
      width: 14px;
      margin-right: 12px !important;
    }

    :hover {
      background-color: #696969;
    }
  }
`;

export const SectionContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 6px;
  padding: 5px 7px;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), inset 3px 0px 0px #cdd1db;
  border-radius: 3px;
  height: 70px;

  .section-overview {
    width: 100%;
    height: 100%;

    .section-overview__header {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }
    }

    .section-overview__footer {
      font-size: 10px;
      line-height: 14px;
      color: #000000;
    }
  }

  &.workout__section--hidden {
    border: 1px solid #dfdfdf;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    height: auto;
    padding: 0;

    .exercise-preview {
      min-height: 46px;
      padding: 6px 7px;

      .exercise-cell-set {
        flex: 1 auto;
        overflow: hidden;
        align-items: flex-start;
        padding-left: 10px;

        .exercise-preview__name {
          img {
            margin-left: 3px;
          }
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #000000;
          margin-bottom: 2px;
          width: 100%;
        }

        .exercise-preview__description {
          width: 100%;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 14px;
          color: #95a6b7;
        }
      }
    }
  }
`;

export const AssignmentContainer = styled.div`
  margin: 0px 5px;
  padding: 0px 5px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none !important;

  ${SectionContainer} {
    margin-bottom: 5px;
    :last-child {
      margin-bottom: 0;
    }
  }

  .workout-cell-title {
    font-weight: bold;
  }

  &:hover {
    border: 1px solid #5a57cb;

    .calendar-client--checkbox-workout {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const AssignmentHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  padding: 0 3px;
  .hidden {
    visibility: hidden;
    opacity: 0;
  }
  .show {
    visibility: visible;
    opacity: 1;
  }
  .calendar-client--checkbox-workout {
    height: 14px;
    width: 14px;
    padding-left: 18px;
    margin-left: 4px;
    display: block;
    & > span {
      width: 14px;
      height: 14px;
    }
    input:checked ~ .checkbox-icon {
      border: 1px solid #5158cf;
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      background-position: 2px 2px;
      background-size: 70%;
      top: 7px;
    }
    &:hover > span.checkbox-icon {
      border: 1px solid #5158cf;
    }
  }
`;

export const AssignmentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;

  .view-more-exercises {
    cursor: pointer;
    color: #676767;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
    line-height: 11px;
    font-weight: 600;
    letter-spacing: normal;
  }

  .calendar-plus-icon {
    margin: 0;
    margin-left: 2px;
  }
`;

export const CellHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  padding: 0 10px;

  .day {
    font-size: 11px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    border-radius: 50%;
    color: #728096;
    text-align: center;
    font-weight: 600;
    font-family: Open Sans;

    &.today {
      background-color: #726ee4;
      color: #fff;
    }
  }

  .add-workout-button {
    visibility: hidden;
    cursor: pointer;

    .plus-icon {
      width: 16px;
      height: 16px;
      display: block;
      background: url(${CDN_URL}/images/plus_circle_white.svg) no-repeat;
      background-size: contain;
      background-position: center;
      position: relative;
      z-index: 10000;
    }
  }
`;

export const Content = styled.div`
  border-radius: 6px;
  display: block;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  &.no-workouts {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${AssignmentContainer} {
    margin-bottom: 5px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const Container = styled.div`
  border-radius: 6px;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  background: #f3f5f8;
  position: relative;
  flex-basis: 0;
  margin: 0;
  height: 100%;

  .cell__footer {
    padding: 0 5px;
    width: 100%;

    .paste {
      height: 22px;
      line-height: 19.5px;
      border-radius: 5px;
      background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
      font-size: 13px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      display: none;
      user-select: none;
      align-items: center;
      justify-content: center;
    }

    .remain-items {
      display: inline;
      font-size: 11px;
      font-weight: 600;
      color: #5a636c;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .draggableWorkout {
    margin-bottom: 5px;
  }
`;

export const Wrapper = styled.div`
  &.view-more {
    ${Container} {
      background: rgb(255, 255, 255);
      border: 1px solid rgb(114, 110, 228);

      ${CellHeader} {
        border-bottom: 1px solid #e5eced;
        .day {
          width: auto;
          color: #2d2e2d;
        }
      }
    }
  }

  :hover {
    ${CellHeader} {
      .add-workout-button {
        visibility: visible;
        z-index: 1;
      }
    }

    ${Container} {
      ${Content}.copying,
      ${Content}.copying_workouts {
        .cell__footer {
          .remain-items {
            display: none;
          }
          .paste {
            display: flex;
          }
        }
      }
    }
  }
`;
