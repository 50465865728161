import React from 'react';
import { get } from 'lodash';

import { ReactComponent as ResourceIcon } from 'assets/icons/asset-resource-icon.svg';
import { ReactComponent as ResourceGrayIcon } from 'assets//icons/resource-collection-gray-icon.svg';
import UpgradePath from 'shared/UpgradePath';

import AssetCard from './AssetCard';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

export default function ResourceAsset({ data, onDeleteAsset, isEdit, cloudfrontList }) {
  const background = get(data, 'asset_data.background', null);
  return (
    <UpgradePath
      pathName="studio_resource_collection"
      fallback={
        <AssetCard
          icon={<ResourceGrayIcon />}
          title={data.asset_data.name}
          asset_type={data.asset_type}
          onDeleteAsset={onDeleteAsset}
          isEdit={isEdit}
          disabled={true}
          disabledText="inactive"
        >
          <span className="badge">Collection</span>
        </AssetCard>
      }
    >
      <AssetCard
        icon={
          background ? (
            <img src={convertS3UrlToCloudFrontUrl(background, cloudfrontList, true)} alt="cover" />
          ) : !data.is_deleted ? (
            <ResourceIcon className="resource" />
          ) : (
            <ResourceGrayIcon />
          )
        }
        title={data.asset_data.name}
        asset_type={data.asset_type}
        onDeleteAsset={onDeleteAsset}
        isEdit={isEdit}
        disabled={data.is_deleted}
        disabledText="deleted"
      >
        <span className="badge">Resource Collection</span>
      </AssetCard>
    </UpgradePath>
  );
}
