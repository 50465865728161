import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  background: #f4f4f5;
  border-radius: 5px;
  margin-bottom: 14px;
`;

export const AddFormTitle = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 5px;
`;

export const AddFormContainer = styled.div`
  padding: 11px 70px;
  background: #fff;
  border: 1px dashed #c8cfe0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #c4c4c4;

  svg {
    margin-right: 4px;
    path:first-child {
      fill: #c4c4c4;
    }
  }

  :hover {
    color: #5158cf;
    border-color: #5158cf;
    cursor: pointer;

    svg {
      path:first-child {
        fill: #5158cf;
      }
    }
  }
`;

export const FormItem = styled.div`
  padding: 10px;
  background: #fff;
  border: 1px solid #ebedf4;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;

  .delete {
    cursor: pointer;
  }
`;

export const FormItemContent = styled.div`
  display: flex;
  gap: 7px;

  svg {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
  }

  ${props =>
    props.isArchived &&
    css`
      opacity: 0.6;
    `}
`;

export const FormNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

export const FormName = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArchivedText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 19px;
  background: #fff4da;
  border: 1px solid #ffbb40;
  border-radius: 2px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  text-align: center;

  color: #758094;
  border-radius: 2px;
  border: 1px solid #dadfea;
  background: #f4f4f5;
`;

export const SubmissionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;

  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #5158cf;

    :hover {
      text-decoration: underline;
    }
  }

  svg {
    path {
      fill: #5158cf;
    }
  }
`;
