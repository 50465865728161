// Libs
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { Route, Switch, withRouter } from 'react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Actions
import { toggleSideBar } from 'actions/sidebar';
import { resetFormResponses } from 'redux/form-responses/actions';
import { getCustomBrandingData } from 'redux/custom-branding/actions';
import { getDefaultMetrics, getDetails, getQuestionTypes, resetFormDetail } from 'redux/form-details/actions';

// Shared
import UpgradePath from 'shared/UpgradePath';

// Components
import FormsUpgradePath from 'components/Forms/components/FormsUpgradePath';
import Header from './components/Header';
import Sections from './components/Sections';
import Settings from './components/Settings';
import MainPanel from './components/MainPanel';
import Responses from './components/Responses';

// Styles
import * as S from './style';

const Questions = () => {
  const [isDragging, setIsDragging] = useState(false);
  return (
    <S.Container>
      <Sections onDraggingSections={setIsDragging} />
      <MainPanel isDragging={isDragging} />
      <Settings />
    </S.Container>
  );
};

const FormDetails = ({
  workingForm,
  push,
  toggleSideBar,
  getDetails,
  match,
  getQuestionTypes,
  resetFormDetail,
  resetFormResponses,
  getCustomBrandingData,
  isUploading,
  bodyMetrics,
  getDefaultMetrics,
}) => {
  useEffect(() => {
    getCustomBrandingData && getCustomBrandingData();

    if (isEmpty(bodyMetrics)) {
      getDefaultMetrics(getDefaultMetrics);
    }
  }, []);

  // Get form details
  useEffect(() => {
    const id = get(match, 'params.id', '');
    if (id) {
      getDetails && getDetails(id);
    }
    return () => {
      resetFormDetail();
      resetFormResponses();
    };
  }, []);

  useEffect(() => {
    toggleSideBar(false);
  }, []);

  useEffect(() => {
    getQuestionTypes && getQuestionTypes();
  }, []);

  return (
    <S.Wrapper>
      <Helmet>
        <title>{workingForm.name} - Form - Everfit</title>
      </Helmet>
      <Header name={workingForm.name} push={push} isUploading={isUploading} />
      <Switch>
        <Route exact path="/home/forms/:id/questions" component={Questions} />
        <Route exact path="/home/forms/:id/responses" component={Responses} />
      </Switch>
    </S.Wrapper>
  );
};

const mapStateToProps = state => ({
  workingForm: state.rootReducer.formDetails.workingForm,
  workingQuestion: state.rootReducer.formDetails.workingQuestion,
  questions: state.rootReducer.formDetails.questions,
  isUploading: state.rootReducer.formDetails.isUploading,
  bodyMetrics: state.rootReducer.formDetails.bodyMetrics || [],
});

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  getDetails: bindActionCreators(getDetails, dispatch),
  getQuestionTypes: bindActionCreators(getQuestionTypes, dispatch),
  resetFormDetail: bindActionCreators(resetFormDetail, dispatch),
  resetFormResponses: bindActionCreators(resetFormResponses, dispatch),
  getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
  getDefaultMetrics: bindActionCreators(getDefaultMetrics, dispatch),
});

const ConnectedFormDetails = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormDetails));

export default function UpgradePathWrapper() {
  return (
    <UpgradePath fallback={<FormsUpgradePath />} pathName="form_questionnaire">
      <ConnectedFormDetails />
    </UpgradePath>
  );
}
