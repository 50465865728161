import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const baseText = `
    font-family: 'Open Sans';
    font-style: normal;
`;

export const CustomModal = styled(Modal)`
  &.ui.modal.responses-form-modal {
    width: calc(100% - 440px);
    height: calc(100% - 150px);
    max-width: 1000px;
    position: relative;
    @media only screen and (max-height: 430px) {
      height: calc(100% - 6px);
      width: calc(100% - 168px);
      > :first-child {
        height: 129px !important;
      }
      > :last-child {
        padding: 129px 0 0 !important;
      }
      .header-wrapper {
        align-items: flex-start;
        flex-direction: column;
        row-gap: 8px;
        .header-right {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
    .close-button {
      top: -10px;
      right: -10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  &.ui.modal.responses-form-modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    position: absolute;
    width: 100%;
    height: 88px;
    padding: 24px 24px 0;
  }
  &.ui.modal.responses-form-modal.scroll > :first-child {
    box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
    padding: 24px 24px 12px;
  }
  &.ui.modal.responses-form-modal > :last-child {
    border-radius: 8px !important;
    padding: 88px 0 0;
  }
  &.ui.modal > .content {
    height: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`;

export const HeaderTitle = styled.div`
  ${baseText}
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const InforWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 4px;
`;

export const ClientName = styled.div`
  ${baseText}
  color: #202353;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
`;

export const Datetime = styled.div`
  ${baseText}
  color: #808199;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
`;

export const ButtonPrintPdf = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 54px;
  background: #fff;
  border: 1px solid #dadfea;
  border-radius: 8px;
  opacity: unset;
  cursor: pointer;
  &:not(:hover) {
    svg {
      path {
        stroke: #202353;
      }
    }
  }
  &:hover {
    border: 1px solid #5158cf;
  }
  ${props =>
    props.isLoading &&
    css`
      cursor: not-allowed;
      &:hover {
        border: 1px solid #dadfea;
      }
    `}
`;

export const LoadingWrapper = styled.div`
  display: block;
  .custom-loading-print.ui.loader:before {
    border: 2px solid rgba(33, 33, 33, 0.4);
  }
  .custom-loading-print.ui.loader:after {
    border-color: #dadfea transparent #dadfea !important;
    border-style: solid;
    border-width: 2px;
  }
`;

export const ContentContainer = styled.div`
  height: 100%;
  padding: 0 16px 24px 24px;
  margin-right: 8px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9 !important;
  }
`;

export const QuestionFormWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const TitleQuestion = styled.div`
  ${baseText}
  color: #202353;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
`;

export const QuestionAnswer = styled.div`
  ${baseText}
  color: #202353;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  ${props =>
    props.isMulti &&
    css`
      display: list-item;
      list-style: disc;
      margin-left: 24px;
    `}
  ${props =>
    props.isLongAnswer &&
    css`
      overflow: hidden;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    `}
`;

export const OtherAnswer = styled.span`
  ${baseText}
  color: #202353;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
`;

export const MultiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const SignatureContent = styled.div`
  border-radius: 5px;
  display: flex;
  -webkit-box-align: left;
  align-items: left;
  flex-direction: column;
  img {
    width: 136px;
    height: 100px;
    border-radius: 8px;
  }
`;

export const FullNameText = styled.p`
  ${baseText}
  width: 136px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000;
  padding-top: 8px;
  letter-spacing: -0.32px;
  text-align: center;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;
  color: #000;
`;

export const SignatureDesc = styled.p`
  ${baseText}
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  margin: unset;
`;
