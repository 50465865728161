import Request from 'configs/request';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { showError } from 'actions/error';
import { Mixpanel } from 'utils/mixplanel';
const ONE_HUNDRED_PERCENT = 100;

export const Types = {
  SELECT_GROUP: 'SELECT_GROUP',
  FORUM_GET_LIST: 'FORUM_GET_LIST',
  FORUM_GET_LIST_SUCCESS: 'FORUM_GET_LIST_SUCCESS',
  ADD_NEW_GROUP_SUCCESS: 'ADD_NEW_GROUP_SUCCESS',
  ADD_NEW_GROUP_ERROR: 'ADD_NEW_GROUP_ERROR',
  GET_GROUP_DETAIL_SUCCESS: 'GET_GROUP_DETAIL_SUCCESS',
  GET_GROUP_DETAIL_FAILURE: 'GET_GROUP_DETAIL_FAILURE',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  GET_LIST_GROUP_REQUEST: 'GET_LIST_GROUP_REQUEST',
  GET_LIST_GROUP_SUCCESS: 'GET_LIST_GROUP_SUCCESS',
  GET_LIST_GROUP_FAILURE: 'GET_LIST_GROUP_FAILURE',
  REMOVE_GROUP_SUCCESS: 'REMOVE_GROUP_SUCCESS',
  GET_GROUP_SETTINGS: 'GET_GROUP_SETTINGS',
  GET_GROUP_SETTINGS_SUCCESS: 'GET_GROUP_SETTINGS_SUCCESS',
  GET_GROUP_SETTINGS_ERROR: 'GET_GROUP_SETTINGS_ERROR',
  UPDATE_GROUP_SETTINGS: 'UPDATE_GROUP_SETTINGS',
  UPDATE_GROUP_SETTINGS_SUCCESS: 'UPDATE_GROUP_SETTINGS_SUCCESS',
  GET_LIST_POST_SUCCESS: 'GET_LIST_POST_SUCCESS',
  GET_LIST_POST_REQUEST: 'GET_LIST_POST_REQUEST',
  ADD_MEMBER_SUCCESS: 'ADD_MEMBER_SUCCESS',
  GET_LIST_USER_SUCCESS: 'GET_LIST_USER_SUCCESS',
  LIKE_POST_SUCCESS: 'LIKE_POST_SUCCESS',
  LIKE_POST_ERROR: 'LIKE_POST_ERROR',
  UNLIKE_POST_SUCCESS: 'UNLIKE_POST_SUCCESS',
  UNLIKE_POST_ERROR: 'UNLIKE_POST_ERROR',
  DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
  DELETE_POST_ERROR: 'DELETE_POST_ERROR',
  ADD_POST_SUCCESS: 'ADD_POST_SUCCESS',
  ADD_POST_FAIL: 'ADD_POST_FAIL',
  GET_POST_DETAIL_SUCCESS: 'GET_POST_DETAIL_SUCCESS',
  EDIT_POST_SUCCESS: 'EDIT_POST_SUCCESS',
  EDIT_POST_FAIL: 'EDIT_POST_FAIL',
  PIN_POST_SUCCESS: 'PIN_POST_SUCCESS',
  UNPIN_POST_SUCCESS: 'UNPIN_POST_SUCCESS',
  FORUM_UPDATE_POSTS_AFTER_VIEW_DETAIL: 'FORUM_UPDATE_POSTS_AFTER_VIEW_DETAIL',
  REMOVE_MEMBER_SUCCESS: 'REMOVE_MEMBER_SUCCESS',
  REMOVE_MEMBER_ERROR: 'REMOVE_MEMBER_ERROR',
  TRANSFER_OWNERSHIP_SUCCESS: 'TRANSFER_OWNERSHIP_SUCCESS',
  GET_CONNECTED_LEADERBOARD: 'GET_CONNECTED_LEADERBOARD',
  GET_CONNECTED_LEADERBOARD_SUCCESS: 'GET_CONNECTED_LEADERBOARD_SUCCESS',
  LEAVE_GROUP_SUCCESS: 'LEAVE_GROUP_SUCCESS',
  RESET_LOAD_POSTS_QUERY_REDUX: 'RESET_LOAD_POSTS_QUERY_REDUX',
  RESET_FORUM_DATA_REDUX: 'RESET_FORUM_DATA_REDUX',
  GET_SETTING_LEADERBOARD_SUCCESS: 'GET_SETTING_LEADERBOARD_SUCCESS',
  SHOW_FORUM_INDICATOR_SUCCESS: 'SHOW_FORUM_INDICATOR_SUCCESS',
  UPDATE_SHOW_POST_INDICATOR_SUCCESS: 'UPDATE_SHOW_POST_INDICATOR_SUCCESS',
  UPDATE_NEW_COMMENT_SHOW_INDICATOR_SUCCESS: 'UPDATE_NEW_COMMENT_SHOW_INDICATOR_SUCCESS',
  SAVE_SCHEDULE_POST_TEMP: 'SAVE_SCHEDULE_POST_TEMP',
  GET_LIST_SCHEDULE_SUCCESS: 'GET_LIST_SCHEDULE_SUCCESS',
  ADD_SCHEDULED_POST_SUCCESS: 'ADD_SCHEDULED_POST_SUCCESS',
  GET_SCHEDULED_LIST_ALL_SUCCESS: 'GET_SCHEDULED_LIST_ALL_SUCCESS',
  GET_SCHEDULED_LIST_MY_POST_SUCCESS: 'GET_SCHEDULED_LIST_MY_POST_SUCCESS',
  GET_LIST_SCHEDULE_REQUEST: 'GET_LIST_SCHEDULE_REQUEST',
  SCHEDULED_LIST_GET_MORE_REQUEST: 'SCHEDULED_LIST_GET_MORE_REQUEST',
  RESET_CREATE_SCHEDULE_POST: 'RESET_CREATE_SCHEDULE_POST',
  RESET_CHANGE_TAB_REDUX: 'RESET_CHANGE_TAB_REDUX',
  RESET_CHANGE_FORUM_REDUX: 'RESET_CHANGE_FORUM_REDUX',
  HIDE_SCHEDULE_POST_BANNER: 'HIDE_SCHEDULE_POST_BANNER',
  GET_LIST_COMMENT_FORUM_SUCCESS: 'GET_LIST_COMMENT_FORUM_SUCCESS',
  ADD_COMMENT_FORUM_SUCCESS: 'ADD_COMMENT_FORUM_SUCCESS',
  ADD_COMMENT_WITH_MEDIA_FORUM_SUCCESS: 'ADD_COMMENT_WITH_MEDIA_FORUM_SUCCESS',
  DELETE_COMMENT_FORUM_SUCCESS: 'DELETE_COMMENT_FORUM_SUCCESS',
  GET_LIST_REPLY: 'GET_LIST_REPLY',
  RESET_LIST_COMMENT_OF_POST: 'RESET_LIST_COMMENT_OF_POST',
  SHOW_POST_PUBLISHED: 'SHOW_POST_PUBLISHED',
  FETCH_POSTS_START: 'FETCH_POSTS_START',
  FETCH_POSTS_DONE: 'FETCH_POSTS_DONE',
  FETCH_SCHEDULE_POSTS_START: 'FETCH_SCHEDULE_POSTS_START',
  FETCH_SCHEDULE_POSTS_DONE: 'FETCH_SCHEDULE_POSTS_DONE',
  DISABLE_LOAD_MORE_POSTS: 'DISABLE_LOAD_MORE_POSTS',
  DISABLE_LOAD_MORE_SCHEDULE_POSTS: 'DISABLE_LOAD_MORE_SCHEDULE_POSTS',
  RESET_DISABLE_LOAD_MORE_SCHEDULE_POSTS: 'RESET_DISABLE_LOAD_MORE_SCHEDULE_POSTS',
  ADD_POLL_OPTION_SUCCESS: 'ADD_POLL_OPTION_SUCCESS',
  REMOVE_POLL_OPTION_SUCCESS: 'REMOVE_POLL_OPTION_SUCCESS',
  VOTE_POLL_OPTION_SUCCESS: 'VOTE_POLL_OPTION_SUCCESS',
  UNVOTE_POLL_OPTION_SUCCESS: 'UNVOTE_POLL_OPTION_SUCCESS',
  ACTION_POLL_OPTION_FAILED: 'ACTION_POLL_OPTION_FAILED',
};

export const markReadForumIndicator = data => {
  return dispatch => {
    return dispatch(Request.put({ url: `/api/forum/check-forum-indicator`, data: data }, true));
  };
};

export const checkPostsIndicator = request => {
  return dispatch => {
    return dispatch(
      Request.put({ url: `/api/forum-post/mark-as-read`, data: request }, true, response => {
        const { data } = response.data;
        if (data) {
          dispatch({ type: Types.UPDATE_SHOW_POST_INDICATOR_SUCCESS, payload: { request } });
        }
      }),
    );
  };
};

export const checkPostsIndicatorAndGotoPostDetail = (request, groupID, postID, openNewTab = false) => {
  return dispatch => {
    return dispatch(
      Request.put({ url: `/api/forum-post/mark-as-read`, data: request }, true, async response => {
        const { data } = response.data;
        if (data) {
          dispatch({ type: Types.UPDATE_SHOW_POST_INDICATOR_SUCCESS, payload: { request } });
          const url = `/home/forums/${groupID}/post-item/${postID}`;
          if (openNewTab) {
            window.open(url, '_blank');
          } else {
            await dispatch(getGroupDetail(groupID));
            dispatch(push(url));
            dispatch(getListUser(groupID));
            dispatch(getListPost(groupID));
          }
        }
      }),
    );
  };
};

export const showForumIndicator = data => {
  return dispatch => {
    dispatch({ type: Types.SHOW_FORUM_INDICATOR_SUCCESS, payload: data });
  };
};

export const showForumIndicatorByNewComment = data => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_NEW_COMMENT_SHOW_INDICATOR_SUCCESS, payload: data });
  };
};

export const getListForum = (textSearch = '') => {
  return dispatch => {
    return dispatch(
      Request.get({ url: `/api/leaderboard/fetch-forum-group?textSearch=${textSearch}` }, true, response => {
        const { data } = response.data;
        dispatch({ type: Types.FORUM_GET_LIST_SUCCESS, payload: { data } });
      }),
    );
  };
};

export const selectGroup = groupId => {
  return dispatch => {
    return dispatch({ type: Types.SELECT_GROUP, payload: { data: { _id: groupId } } });
  };
};

export const addNewGroup = formData => {
  return dispatch => {
    dispatch(resetLoadPostsQuery());
    dispatch(resetChangeForum());

    return dispatch(
      Request.post(
        { url: `/api/forum/add-new-group`, data: formData },
        true,
        async (response, { dispatch }) => {
          Mixpanel.track('add_new_forum');
          const { data } = response.data;
          await dispatch({ type: Types.ADD_NEW_GROUP_SUCCESS, payload: { data } });
          await dispatch(addMember(data._id, _.map(formData.members, '_id')));
        },
        (error, { dispatch }) => dispatch({ type: Types.ADD_NEW_GROUP_ERROR, error }),
      ),
    );
  };
};

export const getGroupDetail = id => {
  if (!id) return;
  const params = {
    groupId: id,
  };
  return dispatch => {
    dispatch({ type: Types.SCHEDULED_LIST_GET_MORE_REQUEST });
    return dispatch(
      Request.get(
        {
          url: '/api/forum/get-group-detail',
          params,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch(getGroupSetting(id));
          dispatch({ type: Types.GET_GROUP_DETAIL_SUCCESS, payload: { data } });
        },
        error => {
          dispatch({ type: Types.GET_GROUP_DETAIL_FAILURE, payload: { error } });
        },
      ),
    );
  };
};

export const updateGroup = params => {
  return dispatch => {
    return dispatch(
      Request.put({ url: '/api/forum/update-group', data: params }, false, response => {
        dispatch({ type: Types.UPDATE_GROUP_SUCCESS, payload: params });
      }),
    );
  };
};

export const getListGroup = params => {
  return dispatch => {
    dispatch({ type: Types.GET_LIST_GROUP_REQUEST });
    return dispatch(
      Request.get(
        {
          url: '/api/forum/get-list-group',
          params,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.GET_LIST_GROUP_SUCCESS, payload: { data } });
        },
        error => {
          dispatch({ type: Types.GET_LIST_GROUP_FAILURE, payload: { error } });
        },
      ),
    );
  };
};

export const removeGroup = groupId => {
  return dispatch => {
    return dispatch(
      Request.post({ url: `/api/forum/remove-group`, data: { groupId: groupId } }, true, (response, { dispatch }) => {
        Mixpanel.track('delete_forum');
        dispatch({ type: Types.REMOVE_GROUP_SUCCESS, payload: { data: groupId } });
      }),
    );
  };
};

export const getListPost = id => {
  const params = {
    group_id: id,
  };
  return (dispatch, getState) => {
    const state = getState();
    const query = _.get(state, 'rootReducer.forum.query');
    const postsLoading = _.get(state, 'rootReducer.forum.postsLoading');
    const disableLoadMorePosts = _.get(state, 'rootReducer.forum.disableLoadMorePosts');
    if (_.isEmpty(query) || postsLoading || disableLoadMorePosts) return;

    let queryParams = _.omit(_.cloneDeep(query), ['isEnd', 'page', 'tab']);

    dispatch({ type: Types.GET_LIST_POST_REQUEST });
    dispatch({ type: Types.FETCH_POSTS_START });
    return dispatch(
      Request.get(
        {
          url: '/api/v2/forum-post/get-list-post',
          params: {
            ...params,
            ..._.pickBy(queryParams, _.identity),
          },
        },
        true,
        response => {
          const data = _.get(response, 'data.data', []);
          dispatch({ type: Types.GET_LIST_POST_SUCCESS, payload: { data } });
          dispatch({ type: Types.FETCH_POSTS_DONE });
          if (_.isEmpty(data)) {
            dispatch({ type: Types.DISABLE_LOAD_MORE_POSTS });
          }
        },
      ),
    );
  };
};

export const getAllListPost = id => {
  const params = {
    group_id: id,
  };
  return (dispatch, getState) => {
    const {
      rootReducer: {
        forum: { query },
      },
    } = getState();
    const _query = { ...query, perPage: null };

    return dispatch(
      Request.get(
        {
          url: '/api/v2/forum-post/get-list-post',
          params: {
            ...params,
            ..._.pickBy(_query, _.identity),
          },
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.GET_LIST_POST_SUCCESS, payload: { data } });
        },
      ),
    );
  };
};

export const addMember = (groupId, userIds) => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: `/api/forum-member/add-user`, data: _.omitBy({ groupId: groupId, userIds: userIds }, _.isEmpty) },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.ADD_MEMBER_SUCCESS, payload: { data } });
        },
      ),
    );
  };
};

export const getListUser = id => {
  if (!id) return;

  const params = {
    groupId: id,
  };
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: '/api/forum-member/get-list-user',
          params,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.GET_LIST_USER_SUCCESS, payload: { data } });
        },
      ),
    );
  };
};

export const likePost = (groupId, postId, userId) => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: `/api/forum-post/like-post`, data: { postId } },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.LIKE_POST_SUCCESS, payload: { data, postId, userId } });
        },
        () => dispatch({ type: Types.LIKE_POST_ERROR }),
      ),
    );
  };
};

export const unlikePost = (groupId, postId, userId) => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: `/api/forum-post/unlike-post`, data: { postId } },
        false,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.UNLIKE_POST_SUCCESS, payload: { data, postId, userId } });
        },
        () => dispatch({ type: Types.UNLIKE_POST_ERROR }),
      ),
    );
  };
};

export const deletePost = postId => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        forum: { tab, totalScheduledPost, schedulePosts },
      },
    } = getState();
    const isLastSchedulePost = totalScheduledPost === 1;
    const selectedPost = _.find(schedulePosts, it => _.get(it, '_id', '') === postId);
    return dispatch(
      Request.delete(
        { url: `/api/forum-post/delete-post`, data: { postId } },
        true,
        (response, { dispatch }) => {
          Mixpanel.track('forum_delete_post');
          dispatch({ type: Types.DELETE_POST_SUCCESS, payload: { postId, tab } });
          if (isLastSchedulePost && selectedPost) {
            dispatch(push(`/home/forums/${_.get(selectedPost, 'group', '')}/discussion`));
          }
        },
        (error, { dispatch }) => dispatch(dispatch({ type: Types.DELETE_POST_ERROR })),
      ),
    );
  };
};
export const addPost = (formData, groupId) => {
  const body = {
    groupId,
    ...formData,
  };
  let updatedBodyPreview;
  const isPublisherEmpty = !_.get(formData, 'preview.publisher', '');
  const isTitleEmpty = !_.get(formData, 'preview.title', '');

  if ((isPublisherEmpty || isTitleEmpty) && !_.isEmpty(body.preview)) {
    updatedBodyPreview = {
      ...body,
      preview: {
        ...body.preview,
        publisher: isPublisherEmpty ? _.get(body, 'preview.domain', '') : _.get(body, 'preview.publisher', ''),
        title: isTitleEmpty ? _.get(body, 'preview.domain', '') : _.get(body, 'preview.title', ''),
      },
    };
  } else {
    updatedBodyPreview = body;
  }

  const { scheduleTime, status } = formData;
  const checkSchedule = scheduleTime && status === 'IN_SCHEDULE';
  const forumId = groupId || _.get(formData, 'groupId');

  return (dispatch, getState) => {
    dispatch({ type: Types.GET_LIST_SCHEDULE_REQUEST });

    const {
      rootReducer: {
        forum: { totalScheduledPost },
      },
    } = getState();
    return dispatch(
      Request.post(
        { url: `/api/forum-post/add-post`, data: updatedBodyPreview },
        false,
        (response, { dispatch }) => {
          Mixpanel.track('forum_add_new_post');
          const { data } = response.data;
          if (data.schedule_time) {
            dispatch({ type: Types.ADD_SCHEDULED_POST_SUCCESS, payload: { data, totalScheduledPost } });
          } else {
            dispatch({ type: Types.ADD_POST_SUCCESS, payload: { data } });
          }
          if (checkSchedule) {
            checkSchedule && dispatch(getListSchedulePost(forumId));
          }
        },
        (err, { dispatch }) => {
          dispatch({ type: Types.ADD_POST_FAIL });
        },
      ),
    );
  };
};

export const pinPost = postId => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: `/api/forum-post/pin-post`, data: { postId } },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.PIN_POST_SUCCESS, payload: { postId } });
        },
        (error, { dispatch }) => {
          const message = _.get(error, 'response.data.message', error.message || '');
          const errorCode = _.get(error, 'response.data.errorCode', '');
          const errorCodeUpTo3Posts = '12_5'; // Up To Pin over 3 posts - pin post

          dispatch(showError(message, errorCode === errorCodeUpTo3Posts ? 'Max posts already pinned' : 'Error'));
        },
        false,
      ),
    );
  };
};

export const unPinPost = postId => {
  return dispatch => {
    return dispatch(
      Request.post({ url: `/api/forum-post/unpin-post`, data: { postId } }, true, (response, { dispatch }) => {
        dispatch({ type: Types.UNPIN_POST_SUCCESS, payload: { postId } });
      }),
    );
  };
};

export const editPost = (formData, postId) => {
  const body = {
    postId,
    ...formData,
  };

  let updatedBodyPreview;
  const isPublisherEmpty = !_.get(formData, 'preview.publisher', '');
  const isTitleEmpty = !_.get(body, 'preview.title', '');

  if ((isPublisherEmpty || isTitleEmpty) && !_.isEmpty(body.preview)) {
    updatedBodyPreview = {
      ...body,
      preview: {
        ...body.preview,
        publisher: isPublisherEmpty ? _.get(body, 'preview.domain', '') : _.get(body, 'preview.publisher', ''),
        title: isTitleEmpty ? _.get(body, 'preview.domain', '') : _.get(body, 'preview.title', ''),
      },
    };
  } else {
    updatedBodyPreview = body;
  }
  return (dispatch, getState) => {
    const {
      rootReducer: {
        forum: {
          query: { tab },
        },
      },
    } = getState();
    dispatch({ type: Types.GET_LIST_SCHEDULE_REQUEST });
    return dispatch(
      Request.put(
        { url: `/api/forum-post/edit-post`, data: updatedBodyPreview },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.EDIT_POST_SUCCESS, payload: { data: response.data.data, tab } });
          dispatch(resetCreatePost());
        },
        (err, { dispatch }) => {
          dispatch({ type: Types.EDIT_POST_FAIL });
        },
      ),
    );
  };
};

export const getListComments = postId => {
  return Request.get(
    { url: `/api/comment/list`, params: { topic: 'forum_post', item: postId } },
    true,
    (response, { dispatch }) => {
      const { data, total_comments } = response.data;
      dispatch({ type: Types.GET_LIST_COMMENT_FORUM_SUCCESS, payload: { data, postId, total_comments } });
    },
  );
};

export const resetListComment = postId => {
  return dispatch => {
    dispatch({ type: Types.RESET_LIST_COMMENT_OF_POST, payload: postId });
  };
};

export const addComment = (data, commentLocalId = '', index) => {
  return Request.post({ url: `/api/comment/add`, data }, true, (response, { dispatch }) => {
    const { data, total_comment } = response.data;
    dispatch({
      type: Types.ADD_COMMENT_FORUM_SUCCESS,
      payload: {
        data,
        postId: data.topic === 'comment' ? data.parent_item : data.item,
        total_comment,
        commentLocalId,
        index,
      },
    });
  });
};

export const addCommentWithMedia = (data, index) => {
  return dispatch => {
    dispatch({ type: Types.ADD_COMMENT_WITH_MEDIA_FORUM_SUCCESS, payload: { ...data, index } });
  };
};

export const deleteComment = (data, postId) => {
  const commentId = _.get(data, '_id');
  return Request.delete({ url: `/api/comment/remove/${commentId}` }, true, (response, { dispatch }) => {
    dispatch({ type: Types.DELETE_COMMENT_FORUM_SUCCESS, payload: { data, postId } });
  });
};

export const getListReply = (params, postId, hasDispatch = true) => {
  const { comment_id } = params;
  return Request.get({ url: `/api/comment/${comment_id}/replies`, params }, true, (response, { dispatch }) => {
    const { data = [] } = response.data;
    if (hasDispatch) {
      dispatch({ type: Types.GET_LIST_REPLY, payload: { data, postId, commentId: comment_id } });
    }
  });
};

export const updatePostsAfterViewDetail = (postId, data) => ({
  type: Types.FORUM_UPDATE_POSTS_AFTER_VIEW_DETAIL,
  payload: { postId, data },
});

export const removeMember = (groupId, userIds) => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: `/api/forum-member/remove-user`, data: { groupId, userIds } },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.REMOVE_MEMBER_SUCCESS, payload: userIds[0] });
        },
      ),
    );
  };
};
export const getGroupSetting = groupId => {
  return dispatch => {
    return dispatch(
      Request.get({ url: '/api/forum/get-group-setting', params: { groupId } }, false, (response, { dispatch }) => {
        const { data } = response.data;
        dispatch({ type: Types.GET_GROUP_SETTINGS_SUCCESS, payload: data });
      }),
    );
  };
};

export const transferOwnership = (groupId, userId) => {
  return (dispatch, getState) => {
    const { user } = getState();
    return dispatch(
      Request.post(
        { url: `/api/forum/transfer-ownership`, data: { groupId, transferOwnership: userId } },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.TRANSFER_OWNERSHIP_SUCCESS, payload: { ownership: userId, currentUser: user } });
        },
      ),
    );
  };
};

export const updateGroupSetting = (groupId, data) => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_GROUP_SETTINGS });
    return dispatch(
      Request.put(
        { url: '/api/forum/update-group-setting', data: { groupId, ...data } },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.UPDATE_GROUP_SETTINGS_SUCCESS, payload: data });
        },
      ),
    );
  };
};

export const getConnectedLeaderboard = (groupId, callback) => {
  if (!groupId) return;

  return dispatch => {
    dispatch({ type: Types.GET_CONNECTED_LEADERBOARD });
    return dispatch(
      Request.get({ url: '/api/forum/get-leaderboards', params: { groupId } }, false, (response, { dispatch }) => {
        const { data } = response.data;
        if (typeof callback === 'function') {
          callback(data);
        }
        dispatch({ type: Types.GET_CONNECTED_LEADERBOARD_SUCCESS, payload: data });
      }),
    );
  };
};

export const leaveGroup = data => {
  return dispatch => {
    const { group, teammate } = data;
    let params = {
      groupId: group._id,
    };
    if (teammate) {
      params.transferOwnership = teammate._id;
    }
    return dispatch(
      Request.post(
        {
          url: '/api/forum/leave-group',
          data: params,
        },
        false,
        (response, { dispatch }) => {
          dispatch({ type: Types.LEAVE_GROUP_SUCCESS, payload: { groupId: group._id } });
          if (teammate) {
            const name = `${teammate.first_name} ${teammate.last_name}`;
            toast.success(`You just left “${group.name}”. The forum’s ownership has been transfer to ${name}.`);
          } else {
            toast(`You just left “${group.name}” forum.`);
          }
        },
      ),
    );
  };
};

export const leaveAndDeleteGroup = group => {
  return dispatch => {
    return dispatch(
      Request.post({ url: `/api/forum/remove-group`, data: { groupId: group._id } }, true, (response, { dispatch }) => {
        Mixpanel.track('delete_forum');
        dispatch({ type: Types.LEAVE_GROUP_SUCCESS, payload: { groupId: group._id } });
        toast(`You just deleted “${group.name}” forum.`);
      }),
    );
  };
};

export const resetLoadPostsQuery = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_LOAD_POSTS_QUERY_REDUX });
  };
};

export const resetCreatePost = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_CREATE_SCHEDULE_POST });
  };
};

export const resetForumData = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_FORUM_DATA_REDUX });
  };
};

export const resetChangeTab = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_CHANGE_TAB_REDUX });
  };
};

export const resetChangeForum = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_CHANGE_FORUM_REDUX });
  };
};

export const getPostDetail = postId => {
  return dispatch => {
    return dispatch(
      Request.get(
        { url: '/api/v2/forum-post/get-post-detail', params: { post_id: postId } },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.GET_POST_DETAIL_SUCCESS, payload: data });
        },
      ),
    );
  };
};

export const saveScheduleTemp = formDataSchedule => {
  return dispatch => {
    dispatch({ type: Types.SAVE_SCHEDULE_POST_TEMP, payload: formDataSchedule });
  };
};

export const getListSchedulePost = (id, params) => {
  if (!id) return;
  return (dispatch, getState) => {
    const state = getState();
    const query = _.get(state, 'rootReducer.forum.query');
    const schedulePostsLoading = _.get(state, 'rootReducer.forum.schedulePostsLoading');
    const disableLoadMoreSchedulePosts = _.get(state, 'rootReducer.forum.disableLoadMoreSchedulePosts');
    if (_.isEmpty(query) || schedulePostsLoading || disableLoadMoreSchedulePosts) return;
    dispatch({ type: Types.SCHEDULED_LIST_GET_MORE_REQUEST });

    let tabType = _.get(params, 'tab', 'all');
    let queryParams = _.omit(_.cloneDeep(query), ['isEnd', 'perPage', 'lastPostId']);

    queryParams = _.merge(queryParams, params);

    dispatch({ type: Types.FETCH_SCHEDULE_POSTS_START });
    return dispatch(
      Request.get(
        {
          url: `/api/forum-post/${id}/get-list-schedule-post?per_page=5`,
          params: {
            ..._.pickBy(queryParams, _.identity),
          },
        },
        true,
        response => {
          const data = _.get(response, 'data.data', []);
          const total = _.get(response, 'data.total');
          if (tabType === 'all') {
            dispatch({
              type: Types.GET_LIST_SCHEDULE_SUCCESS,
              payload: {
                data,
                total,
                tabType,
              },
            });
          } else if (tabType === 'my_post') {
            dispatch({
              type: Types.GET_LIST_SCHEDULE_SUCCESS,
              payload: {
                data,
                tabType,
              },
            });
          }
          dispatch({ type: Types.FETCH_SCHEDULE_POSTS_DONE });
          if (_.isEmpty(data)) {
            dispatch({ type: Types.DISABLE_LOAD_MORE_SCHEDULE_POSTS });
          }
        },
      ),
    );
  };
};

export const loadMoreScheduledPost = (id, params) => {
  if (!id) return;

  return (dispatch, getState) => {
    const state = getState();
    const query = _.get(state, 'rootReducer.forum.query');
    const schedulePostsLoading = _.get(state, 'rootReducer.forum.schedulePostsLoading');
    const disableLoadMoreSchedulePosts = _.get(state, 'rootReducer.forum.disableLoadMoreSchedulePosts');
    if (_.isEmpty(query) || schedulePostsLoading || disableLoadMoreSchedulePosts) return;
    dispatch({ type: Types.SCHEDULED_LIST_GET_MORE_REQUEST });

    const { page, tab } = params;

    let queryParams = _.omit(_.cloneDeep(query), ['isEnd', 'lastPostId', 'perPage']);
    queryParams = _.merge(queryParams, params);

    dispatch({ type: Types.FETCH_SCHEDULE_POSTS_START });
    return dispatch(
      Request.get(
        { url: `/api/forum-post/${id}/get-list-schedule-post?per_page=5`, params: queryParams },
        true,
        response => {
          const data = _.get(response, 'data.data', []);
          const total = _.get(response, 'data.total');
          if (tab === 'all') {
            dispatch({
              type: Types.GET_SCHEDULED_LIST_ALL_SUCCESS,
              payload: {
                data,
                total,
                page,
                tab,
              },
            });
          } else if (tab === 'my_post') {
            dispatch({
              type: Types.GET_SCHEDULED_LIST_MY_POST_SUCCESS,
              payload: {
                data,
                total,
                page,
                tab,
              },
            });
          }
          dispatch({ type: Types.FETCH_SCHEDULE_POSTS_DONE });
          if (_.isEmpty(data)) {
            dispatch({ type: Types.DISABLE_LOAD_MORE_SCHEDULE_POSTS });
          }
        },
        error => {},
      ),
    );
  };
};

export const hideScheduleBanner = data => {
  return dispatch => {
    dispatch({ type: Types.SCHEDULED_LIST_GET_MORE_REQUEST });
    return dispatch(
      Request.put({ url: `/api/forum/hide-schedule-post-banner`, data: data }, true, response => {
        const { data } = response.data;
        if (data) {
          dispatch({
            type: Types.HIDE_SCHEDULE_POST_BANNER,
          });
        }
      }),
    );
  };
};

export const defaultSettingLeaderboard = () => {
  return dispatch => {
    return dispatch(
      Request.get({ url: `/api/body-metric/get-list-trainer-default-body-metric` }, true, (response, { dispatch }) => {
        const { data } = response.data;
        dispatch({ type: Types.GET_SETTING_LEADERBOARD_SUCCESS, payload: data });
      }),
    );
  };
};

export const showPostPublished = ({ newPostPublished }) => {
  return (dispatch, getState) => {
    if (_.isEmpty(newPostPublished)) return;
    const state = getState();
    const groupId = _.get(state, 'rootReducer.forum.group._id');
    if (!_.isEqual(groupId, _.get(newPostPublished, 'group'))) return;
    const posts = _.get(state, 'rootReducer.forum.posts', []);
    const schedulePosts = _.get(state, 'rootReducer.forum.schedulePosts', []);
    const newPosts = _.unionBy([newPostPublished, ...posts], '_id');
    const newScheduledPosts = _.filter(
      schedulePosts,
      post => !_.isEqual(_.get(post, '_id'), _.get(newPostPublished, '_id')),
    );

    dispatch({
      type: Types.SHOW_POST_PUBLISHED,
      payload: { posts: newPosts, schedulePosts: newScheduledPosts },
    });
  };
};

export const resetDisableLoadMoreSchedulePosts = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_DISABLE_LOAD_MORE_SCHEDULE_POSTS });
  };
};

// Poll Option
const roundDecimalForNumber = (number, decimal = 2) => {
  return parseFloat(number.toFixed(decimal));
};

const containsObjectId = (listObjects = [], checkId) => {
  if (_.isEmpty(listObjects)) {
    return false;
  }
  return listObjects.some(_id => _id === checkId);
};

const handleAfterAddPollOption = ({ data, posts, isAuthorPost, isMultiple, poll_id, thisVoter }) => {
  if (isAuthorPost) {
    const newPostsByOwner = posts.map(post => {
      if (poll_id === _.get(post, 'poll._id', '')) {
        const newOptions = _.get(post, 'poll.options', []).concat(data);
        return {
          ...post,
          poll: {
            ...post.poll,
            options: newOptions,
          },
        };
      }
      return post;
    });

    return newPostsByOwner;
  }

  const newPollPosts = posts.map(post => {
    if (poll_id === _.get(post, 'poll._id', '')) {
      const { total_votes = 0, options = [] } = _.get(post, 'poll');
      const hasVoteByUser = options.some(({ viewer_has_voted }) => viewer_has_voted);
      const newTotalVote = isMultiple ? total_votes + 1 : hasVoteByUser ? total_votes : total_votes + 1;

      const currentOptions = _.get(post, 'poll.options', []);
      const changedOptions = currentOptions.map(option => {
        const { viewer_has_voted } = option;
        const viewerUserId = thisVoter;

        if (!isMultiple) {
          const newVotes = viewer_has_voted ? option.votes - 1 : option.votes;
          const newVoters = viewer_has_voted ? option.voters.filter(_id => _id !== viewerUserId) : option.voters;

          return {
            ...option,
            percentage: newTotalVote === 0 ? 0 : roundDecimalForNumber((newVotes / newTotalVote) * ONE_HUNDRED_PERCENT),
            viewer_has_voted: containsObjectId(newVoters, viewerUserId),
            votes: newVotes,
            voters: newVoters,
          };
        }

        return {
          ...option,
          percentage:
            newTotalVote === 0 ? 0 : roundDecimalForNumber((option.votes / newTotalVote) * ONE_HUNDRED_PERCENT),
          viewer_has_voted: containsObjectId(option.voters, viewerUserId),
        };
      });

      const newOptions = [...changedOptions, data];

      const newPoll = {
        ...post.poll,
        options: newOptions,
        total_votes: newTotalVote,
      };

      return {
        ...post,
        poll: newPoll,
      };
    }
    return post;
  });

  return newPollPosts;
};

export const addPollOption = ({ poll_id, option_value, isMultiple, isAuthorPost }) => {
  return (dispatch, getState) => {
    const { user, rootReducer } = getState();
    const posts = _.get(rootReducer, 'forum.posts', []);

    return dispatch(
      Request.post({ url: `/api/v2/forum-post/poll/add-option`, data: { poll_id, option_value } }, true, response => {
        const { data } = response.data;
        const newPosts = handleAfterAddPollOption({
          data,
          posts,
          isAuthorPost,
          isMultiple,
          poll_id,
          thisVoter: (user || {})._id,
        });
        dispatch({
          type: Types.ADD_POLL_OPTION_SUCCESS,
          payload: { newPosts },
        });
      }),
    );
  };
};

// Handle after remove a poll option
const handleAfterRemovePollOption = ({ posts, poll_id, option_id }) => {
  const afterRemoveOptionPosts = posts.map(post => {
    if (poll_id === _.get(post, 'poll._id', '')) {
      const currentOptions = _.get(post, 'poll.options', []);
      const currentTotal = _.get(post, 'poll.total_votes', 0);
      const removedOption = currentOptions.find(({ _id }) => _id === option_id);
      const filteredOptions = currentOptions.filter(({ _id }) => _id !== option_id);

      if (removedOption) {
        if (removedOption.votes === 0) {
          return {
            ...post,
            poll: {
              ...post.poll,
              options: filteredOptions,
            },
          };
        }

        const newTotal = currentTotal - removedOption.votes;
        const newOptions = filteredOptions.map(option => {
          return {
            ...option,
            percentage: newTotal === 0 ? 0 : roundDecimalForNumber((option.votes / newTotal) * ONE_HUNDRED_PERCENT),
          };
        });

        return {
          ...post,
          poll: {
            ...post.poll,
            options: newOptions,
            total_votes: newTotal,
          },
        };
      }
    }
    return post;
  });

  return afterRemoveOptionPosts;
};

export const removePollOption = ({ poll_id, option_id }) => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const posts = _.get(rootReducer, 'forum.posts', []);

    return dispatch(
      Request.post({ url: `/api/v2/forum-post/poll/remove-option`, data: { poll_id, option_id } }, true, response => {
        const newPosts = handleAfterRemovePollOption({ posts, poll_id, option_id });
        dispatch({ type: Types.REMOVE_POLL_OPTION_SUCCESS, payload: { newPosts } });
      }),
    );
  };
};

// Handle vote and unvote option
const transformPollOptions = ({
  pollOptions,
  totalVotes,
  viewerUserId,
  isMultiple = false,
  selectedId,
  isUnVote = false,
}) => {
  if (!(pollOptions || {}).length) return pollOptions;

  // Convert the poll options to percentage with two decimal and the total of percentage is 100%
  let transformedPollOptions = pollOptions.map(option => {
    const { viewer_has_voted = false } = option;

    if (isUnVote) {
      return {
        ...option,
        percentage: totalVotes === 0 ? 0 : roundDecimalForNumber((option.votes / totalVotes) * ONE_HUNDRED_PERCENT),
      };
    }

    if (!isMultiple) {
      const isSelectedOptions = selectedId === option._id;
      const newVotes = isSelectedOptions ? option.votes + 1 : viewer_has_voted ? option.votes - 1 : option.votes;
      const newVoters = isSelectedOptions
        ? [...option.voters, viewerUserId]
        : viewer_has_voted
        ? option.voters.filter(_id => _id !== viewerUserId)
        : option.voters;

      return {
        ...option,
        percentage: totalVotes === 0 ? 0 : roundDecimalForNumber((newVotes / totalVotes) * ONE_HUNDRED_PERCENT),
        viewer_has_voted: containsObjectId(newVoters, viewerUserId),
        votes: newVotes,
        voters: newVoters,
      };
    }

    const isSelectedOptions = selectedId === option._id;
    const newVotes = isSelectedOptions ? option.votes + 1 : option.votes;
    const newVoters = isSelectedOptions ? [...option.voters, viewerUserId] : option.voters;

    return {
      ...option,
      percentage: totalVotes === 0 ? 0 : roundDecimalForNumber((newVotes / totalVotes) * ONE_HUNDRED_PERCENT),
      viewer_has_voted: containsObjectId(newVoters, viewerUserId),
      votes: newVotes,
      voters: newVoters,
    };
  });

  if (totalVotes === 0) return transformedPollOptions;

  // Calculate the total percentage
  const totalPercentage = transformedPollOptions.reduce((total, option) => total + option.percentage, 0);

  // Adjust the percentages to ensure the total percentage is 100%
  if (totalPercentage !== ONE_HUNDRED_PERCENT && transformedPollOptions[0].votes > 0) {
    const adjustment = roundDecimalForNumber(ONE_HUNDRED_PERCENT - totalPercentage);
    transformedPollOptions[0].percentage = roundDecimalForNumber(transformedPollOptions[0].percentage + adjustment);
  }

  return transformedPollOptions;
};

const handleVoteOption = ({ posts, poll_id, option_id, isMultiple, thisVoter }) => {
  const afterVotePosts = posts.map(post => {
    if (poll_id === _.get(post, 'poll._id', '')) {
      const { total_votes = 0, options = [] } = _.get(post, 'poll');
      const hasVoteByUser = options.some(({ viewer_has_voted }) => viewer_has_voted);
      const newTotalVote = isMultiple ? total_votes + 1 : hasVoteByUser ? total_votes : total_votes + 1;

      const newPoll = {
        ...post.poll,
        options: transformPollOptions({
          pollOptions: _.get(post, 'poll.options', 0),
          totalVotes: newTotalVote,
          viewerUserId: thisVoter,
          isMultiple,
          selectedId: option_id,
        }),
        total_votes: newTotalVote,
      };

      return {
        ...post,
        poll: newPoll,
      };
    }
    return post;
  });

  return afterVotePosts;
};

export const votePollOption = ({ poll_id, option_id }, isMultiple) => {
  return (dispatch, getState) => {
    const { user, rootReducer } = getState();
    const posts = _.get(rootReducer, 'forum.posts', []);
    const newPosts = handleVoteOption({
      posts,
      poll_id,
      option_id,
      isMultiple,
      thisVoter: (user || {})._id,
    });
    dispatch({
      type: Types.VOTE_POLL_OPTION_SUCCESS,
      payload: { newPosts },
    });
    return dispatch(
      Request.post({ url: `/api/v2/forum-post/poll/vote`, data: { poll_id, option_id } }, false, () => {}),
      () => {
        dispatch({
          type: Types.ACTION_POLL_OPTION_FAILED,
          payload: { newPosts: posts },
        });
      },
    );
  };
};

const handleUnVoteOption = ({ posts, poll_id, option_id, thisVoter }) => {
  const afterUnVotePosts = posts.map(post => {
    if (poll_id === _.get(post, 'poll._id', '')) {
      const newPollOptions = _.get(post, 'poll.options', []).map(option => {
        if (option._id === option_id) {
          return {
            ...option,
            viewer_has_voted: false,
            votes: option.votes - 1,
            voters: option.voters.filter(_id => _id !== thisVoter),
          };
        }

        return option;
      });

      const newTotalVote = _.get(post, 'poll.total_votes', 0) - 1;

      return {
        ...post,
        poll: {
          ...post.poll,
          options: transformPollOptions({
            pollOptions: newPollOptions,
            totalVotes: newTotalVote,
            viewerUserId: thisVoter,
            selectedId: option_id,
            isUnVote: true,
          }),
          total_votes: newTotalVote,
        },
      };
    }

    return post;
  });

  return afterUnVotePosts;
};

export const unvotePollOption = ({ poll_id, option_id }) => {
  return (dispatch, getState) => {
    const { user, rootReducer } = getState();
    const posts = _.get(rootReducer, 'forum.posts', []);
    const newPosts = handleUnVoteOption({ posts, poll_id, option_id, thisVoter: (user || {})._id });
    dispatch({
      type: Types.UNVOTE_POLL_OPTION_SUCCESS,
      payload: { newPosts },
    });
    return dispatch(
      Request.post({ url: `/api/v2/forum-post/poll/unvote`, data: { poll_id, option_id } }, false, () => {}),
      () => {
        dispatch({
          type: Types.ACTION_POLL_OPTION_FAILED,
          payload: { newPosts: posts },
        });
      },
    );
  };
};

export const getVoters = params => {
  return dispatch => {
    return dispatch(Request.get({ url: `/api/v2/forum-post/poll/get-voters`, params }, false));
  };
};
