import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onDragSectionLeftPanelEnd } from 'redux/workout-builder/actions';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  .droppable-container {
    height: 46px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 15px;
    margin-top: 15px;
    max-height: 0px;
    overflow: hidden;

    span {
      font-weight: normal;
      font-size: 13px;
      line-height: 120%;
      color: #5158cf;
      margin-left: 10px;
    }
  }

  &.hover {
    .droppable-container {
      display: flex;
      max-height: 200px;
      border: 1px dashed #5158cf;
    }
  }
`;

export const DroppableContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0px;
  z-index: 10;

  ${props =>
    props.bottom &&
    css`
      top: unset;
      bottom: 0px;
      /* background-color: #2a307c50; */
    `}
`;

function SectionDroppable(props) {
  const [hover, setHover] = useState(false);

  const onDragEnter = e => {
    setHover(true);
  };

  const onDrop = e => {
    e.preventDefault();
    props.onDragSectionLeftPanelEnd({
      sectionId: props.sectionId,
      sectionIndex: props.sectionIndex,
    });
  };

  const onDragOver = e => {
    e.preventDefault();
  };

  const onDragleave = e => {
    e.preventDefault();
    setHover(false);
  };

  return (
    <Wrapper
      className={classnames({ hover: hover })}
      bottom={props.bottom}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragleave}
      onDragEnter={onDragEnter}
    >
      <div className="droppable-container">
        <img src={`${CDN_URL}/images/section_droppable_icon.svg`} alt="section_droppble_icon" />
        <span>Drop your {props.dragType} here</span>
      </div>
      <DroppableContainer bottom={props.bottom} />
    </Wrapper>
  );
}

const mapDispatch = dispatch => ({
  onDragSectionLeftPanelEnd: bindActionCreators(onDragSectionLeftPanelEnd, dispatch),
});

export default connect(null, mapDispatch)(SectionDroppable);
