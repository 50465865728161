import { Types } from './actions';

const INITIAL_STATE = {
  broadcastSubmittedTime: null,
  clients: [],
  total: 0,
  clientsLoading: false,
  allClients: [],
  isOpenSecondModal: false,
  isAllClientsLoading: false,
  isHideBroadcast: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.BROADCAST_MESSAGES_SUCCESS:
      return Object.assign({}, state, { broadcastSubmittedTime: payload });

    case Types.BROADCAST_MESSAGES_WORKOUT_SUMMARY_SUCCESS:
      return Object.assign({}, state, { broadcastWorkoutSummarySubmittedTime: payload });

    case Types.BROADCAST_MESSAGES_GET_CLIENTS_REQUEST:
      return Object.assign({}, state, { clientsLoading: true });

    case Types.BROADCAST_MESSAGES_GET_CLIENTS_SUCCESS:
      const { clients, total } = payload;
      return Object.assign({}, state, { clients: clients, total: total, clientsLoading: false });

    case Types.BROADCAST_MESSAGES_GET_CLIENTS_FAILED:
      return Object.assign({}, state, { clientsLoading: false });

    case Types.BROADCAST_MESSAGES_GET_ALL_CLIENTS_SUCCESS:
      return Object.assign({}, state, { isAllClientsLoading: false });

    case Types.BROADCAST_MESSAGES_GET_ALL_CLIENTS_REQUEST:
      return Object.assign({}, state, { isAllClientsLoading: true });

    case Types.BROADCAST_MESSAGES_RESET_STATE:
      return Object.assign({}, state, {
        clientsLoading: false,
        isAllClientsLoading: false,
        clients: [],
        total: 0,
        allClients: [],
      });

    case Types.OPEN_SECOND_TOGGLE_MODAL:
      return Object.assign({}, state, { isOpenSecondModal: payload });

    case Types.HIDE_BROADCAST:
      return Object.assign({}, state, { isHideBroadcast: payload });

    default:
      return state;
  }
};
