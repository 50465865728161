import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .custom-arrow-icon {
    width: 24px;
    height: 24px;
  }
`;

export const CoachWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  width: 292px;
  height: 50px;
  padding: 0 12px;
  border-radius: 4px;
  background-color: #f7f7fa;

  .sb-avatar.sb-avatar--src {
    flex-shrink: 0;
  }
`;

export const CoachName = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #202353;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  max-width: 300px;
  height: 50px;
  padding: 0 12px 0 15px;
  border-radius: 4px;
  border: 1px solid #dadfea;
  background-color: #ffffff;

  .__react_component_tooltip.app-tooltip {
    border-radius: 4px;
    background-color: #333333;
    padding: 7px 15px;

    &.place-top {
      margin-top: -7px;
      &:after {
        bottom: -9px;
        border-top-color: #333333;
      }
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #fff;
    }
  }
`;

export const TextUrl = styled.div`
  width: 240px;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #202353;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CopyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  width: 24px;
  height: 24px;
  border-radius: 4px;

  .custom-copy-icon path {
    fill: #202353;
  }

  &:hover {
    background-color: #f0f1ff;
    cursor: pointer;

    .custom-copy-icon path {
      fill: #5158cf;
    }
  }
`;

export const Actions = styled.div`
  .evf-dropdown.custom-dropdown-actions {
    .evf-dropdown__trigger-container {
      .dropdown-trigger {
        border-radius: 50%;
        background-color: transparent;
        .dot,
        .dot::after,
        .dot::before {
          background-color: #728096;
        }
        &:hover {
          background-color: #e7ebee;
        }
      }
      .__react_component_tooltip.app-tooltip.place-top:after {
        bottom: -7px;
      }
      .__react_component_tooltip.place-top {
        margin-top: -8px;
      }
    }
    .evf-dropdown__menu {
      padding: 8px 0;
      border-radius: 8px;
      .evf-dropdown__option {
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        user-select: none;
        .icon {
          display: flex;
          justify-content: center;
        }
        span {
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: #ffffff;
        }
      }
    }
    &.open .dropdown-trigger {
      background-color: #e7ebee !important;
    }
  }
`;

export const OptionIcon = styled.div`
  display: flex;
  align-items: center;

  .remove-icon {
    width: 16px;
    height: 16px;
  }
`;
