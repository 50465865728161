export const PROGRESS_PHOTO_DEFAULT_SETTINGS = {
  MAX_SIZE: 25,
  MAX_LENGTH_PHOTOS: 10,
  IMAGE_VALIDATE_EXTENTION: ['png', 'jpg', 'jpeg', 'heic'],
  ACCEPTED_IMAGE: 'image/png, image/jpeg, image/jpg, image/heic',
  FILE_TYPE_SUPPORTED_MESSAGE: 'File type not supported.',
  MAX_SIZE_MESSAGE: 'Please upload an image smaller than 25MB',
  MAX_PHOTO_NUMBER_MESSAGE: 'You can only upload 10 pictures at the time',
};

export const DEFAULT_STATE = {
  photoData: {
    list: [],
  },
};
