import styled, { css } from 'styled-components';
import { Modal, Popup } from 'semantic-ui-react';

import ButtonIcon from 'shared/ButtonIcon';
import { CDN_URL } from 'constants/commonData';

export const CustomModal = styled(Modal)`
  &.ui.modal {
    width: 948px;
    height: 100%;
    max-height: min(692px, calc(100vh - 100px));
    border-radius: 8px !important;
    background-color: #ffffff !important;

    .content {
      height: 100%;
    }

    > :first-child {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      padding: unset !important;
    }

    > :last-child {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      padding: unset !important;
    }
  }

  .close-button {
    right: -10px;
    top: -10px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const WrapperModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
`;

export const LeftSide = styled.div`
  width: 333px;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  .panel-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .note-icon {
    width: 18px;
    height: 18px;
  }
`;

export const TitleHeader = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #202353;

  &.limitation {
    font-size: 16px;
  }
`;

export const ButtonAdd = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    background: transparent !important;
    border: none !important;
    margin: unset !important;
    padding: unset !important;

    svg {
      color: #5e59ff;
      margin-right: 7px;
      width: 13px;
      height: 13px;
    }

    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #5e59ff;
      padding: unset !important;
    }
  }
`;

export const SearchBox = styled.div`
  padding: 7px 16px 16px 16px;

  > .search-input {
    width: 100%;
    height: 40px;

    input {
      border-radius: 8px;
      padding-left: 52px;
      padding-right: 28px;
      background: url(${CDN_URL}/images/search_grey_thin.svg) no-repeat;
      background-size: auto;
      background-position: 15px center;
      background-color: #f6f6f6;
      border: 1px solid rgba(0, 0, 0, 0.1);

      ::placeholder {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: rgba(123, 126, 145, 0.6);
        opacity: 1;
      }

      :focus,
      :hover {
        border-color: #5e59ff;
      }
    }
  }
`;

export const SortByLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #8f99a3;
`;

export const TriggerSort = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #323c47;

    :hover,
    .is-show {
      color: #5e59ff;
    }
  }
`;

export const CustomSortPopup = styled(Popup)`
  &.ui.popup.note-sort-popup {
    z-index: 9999;
    width: 164px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px;

    background: #ffffff;
    border: 1px solid #ebebeb;

    box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.2) !important;
    border-radius: 5px !important;
  }

  .item {
    cursor: pointer;
    padding: 12px 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000000;

    &.active {
      font-weight: 600;
    }

    &:hover,
    &.active {
      color: #5e59ff;
      background-color: #f6f5ff;
    }
  }
`;

export const SortWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  padding: 0px 16px 16px 16px;

  &.shadow-bottom {
    z-index: 1;
    box-shadow: 0 6px 4px -4px rgba(38, 38, 38, 0.2);
  }

  i.icon {
    width: 16px;
    height: 16px;
    font-size: 10px;
    margin: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    :hover {
      background-color: #f0f1ff;
      color: #5158cf;
      border-radius: 5px;
    }
  }
`;

export const ScrollListNoteItem = styled.div`
  position: relative;
  overflow-y: scroll;

  padding-left: 16px;
  padding-right: 8px;
  margin-right: 4px;
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #b2b7c4 !important;
  }
`;

export const NoteItemTitleMonth = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  padding: 4px 0px;

  :not(:first-child) {
    margin-top: 8px;
  }

  color: #8f99a3;
`;

export const NoteItemContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &:last-child {
    .line-bottom {
      display: none;
    }
  }
`;

export const NoteItemWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  padding: 16px;
  overflow: hidden;

  &:hover {
    border-radius: 8px;
    background-color: #f8f8f8;
  }

  &.active {
    border-radius: 8px;
    background-color: #f0f1ff;
  }

  .line-bottom {
    position: absolute;
    width: 285px;
    height: 1px;
    left: 8px;
    bottom: 0px;

    background: #0000001a;

    &.hidden-line {
      display: none;
    }
  }
`;

export const NoteItemContent = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  white-space: pre-wrap;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const NoteItemCreatedAt = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #636f7a;
`;

export const MainSide = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  height: 100%;
`;

export const HeaderMainSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 12px 16px 12px 24px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 4px 0px;
`;

export const TimeLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const TimeLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  color: #636f7a;
`;

export const SaveButton = styled.button`
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #ffffff;

  padding: 10px 16px;

  background: #5e59ff;
  border-radius: 4px;
  border: none;

  :disabled {
    cursor: not-allowed;
    background: #c0befa;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  margin-right: 24px;

  button {
    height: 16px !important;
    width: 16px !important;
    padding: 0px !important;
    margin: 0px !important;
    outline: none !important;

    &:hover {
      background: transparent !important;

      &#remove-button {
        svg g {
          stroke: #ff4d4f;
          fill: #ff4d4f;
          opacity: 1;
        }
      }

      &#edit-button {
        svg path {
          stroke: #5e59ff;
          opacity: 1;
        }
      }
    }
  }

  .__react_component_tooltip#edit-button-tooltip,
  .__react_component_tooltip#remove-button-tooltip {
    padding: 15px;
    background: #151619;
    border-radius: 5px;

    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;

      color: #ffffff;
    }

    ::after {
      border-top-color: #151619 !important;
    }
  }
`;

export const ComposerMainSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;

  flex: 1;
  margin: 0px 16px 0px 24px;

  > .quill {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1;

    .ql-editor {
      &::-webkit-scrollbar {
        width: 4px !important;
      }

      &::-webkit-scrollbar-thumb {
        background: #b2b7c3 !important;
      }
    }
  }
`;

export const ScrollContentMainSide = styled.div`
  position: relative;
  overflow-y: scroll;

  margin: 0px 16px 0px 24px;
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #b2b7c3 !important;
  }

  #note-content-viewer {
    word-break: break-word;
    padding-right: 20px;

    * {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 175%;
      color: #323c47;
    }

    p {
      margin-bottom: 0;
    }

    strong,
    strong em {
      font-weight: 700 !important;
    }

    em {
      font-style: italic !important;
    }

    ul,
    ol {
      padding: revert;
      margin-top: 0px;
      margin-bottom: 0px;

      li {
        padding-left: revert;

        ::before {
          content: '';
        }
      }
    }

    ul li {
      list-style-type: disc;
    }

    ol li {
      list-style-type: decimal;
    }
  }
`;

export const EmptyLeftSideWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 63px;

  ${props =>
    !!props.$notFound &&
    css`
      padding-bottom: 46px;
    `}

  .message-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: #8d8f96;

    padding-left: 2px;
  }
`;

export const EmptyMainSideWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;

  .message-title,
  .message-content {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    text-align: center;

    color: #8d8f96;
  }

  .message-title {
    padding-right: 3px;
  }

  .message-content {
    padding-left: 1px;
  }

  .empty-illustration {
    width: 163px;
    height: 163px;
  }
`;
