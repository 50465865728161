import axios from 'axios';

import { axiosInstance } from 'configs/request';
import { DEFAULT_FILTERS } from '../ExerciseFilterPopup';
import { formatTags } from 'redux/exercise/exercise.actionCreators';

const CancelToken = axios.CancelToken;
const cancelRequests = [];
const searchGeneralCancelRequests = [];
const searchRecentCancelRequests = [];
export const DEBOUNCE_TIME = 300;

export const DEFAULT_GENERAL_EXERCISE = {
  page: 1,
  per_page: 20,
  q: '',
  list: [],
  total: 0,
  loading: false,
  sortAlphabetical: true,
  isEnd: false,
};

export const DEFAULT_RECENT_EXERCISE = {
  page: 1,
  per_page: 20,
  q: '',
  list: [],
  total: 0,
  loading: false,
  filter: DEFAULT_FILTERS,
  showPopup: false,
  showFilter: false,
  isEnd: false,
  all_exercise_ids: [],
};

export const cancelAllRequest = () => {
  if (Array.isArray(cancelRequests)) {
    cancelRequests.forEach(c => typeof c === 'function' && c());
    cancelRequests.length = 0;
  }
};

export const fetchGeneralExercise = ({
  data = {},
  callbackInit = () => {},
  callbackSuccess = () => {},
  callbackFailure = () => {},
  callbackFinally = () => {},
} = {}) => {
  if (data.loading && !!searchGeneralCancelRequests.length) {
    searchGeneralCancelRequests.forEach(
      cancelRequest =>
        typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_SEARCH_GENERAL_EXERCISE_POPUP'),
    );
  }
  callbackInit();
  const params = {
    page: data.page,
    per_page: data.per_page,
    text_search: (data.q || '').toLowerCase().trim(),
    sort: data.sortAlphabetical ? 1 : -1,
    sorter: data.sortAlphabetical ? 'exercise_title' : 'exercise_last_interacted',
  };
  axiosInstance
    .get('/api/exercise-clients/default-exercises', {
      params,
      cancelToken: new CancelToken(cancelRequest => {
        cancelRequests.push(cancelRequest);
        searchGeneralCancelRequests.push(cancelRequest);
      }),
    })
    .then(callbackSuccess)
    .catch(callbackFailure)
    .finally(callbackFinally);
};

export const fetchRecentExercise = ({
  data = {},
  callbackInit = () => {},
  callbackSuccess = () => {},
  callbackFailure = () => {},
  callbackFinally = () => {},
} = {}) => {
  if (data.loading && !!searchRecentCancelRequests.length) {
    searchRecentCancelRequests.forEach(
      cancelRequest =>
        typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_SEARCH_RECENT_EXERCISE_POPUP'),
    );
  }

  callbackInit();
  const formatFilters = formatTags(data.filter);
  const textSearch = (data.q || '').toLowerCase().trim();
  const paramsSort = textSearch === '' ? { sort: -1, sorter: 'last_interacted' } : {};
  const params = {
    page: data.page,
    per_page: data.per_page,
    text_search: textSearch,
    ...paramsSort,
    ...formatFilters,
  };
  axiosInstance
    .get('/api/exercise-clients/exercises', {
      params,
      cancelToken: new CancelToken(cancelRequest => {
        cancelRequests.push(cancelRequest);
        searchRecentCancelRequests.push(cancelRequest);
      }),
    })
    .then(callbackSuccess)
    .catch(callbackFailure)
    .finally(callbackFinally);
};

export const addExerciseToGeneralList = ({
  data = {},
  callbackInit = () => {},
  callbackSuccess = () => {},
  callbackFailure = () => {},
  callbackFinally = () => {},
} = {}) => {
  let exercises = [];

  if (data.exerciseId) {
    exercises = [data.exerciseId];
  }

  if (data.exerciseIds) {
    exercises = data.exerciseIds;
  }

  if (data.loading || !exercises.length) return;

  callbackInit();
  const params = { exercises };
  axiosInstance
    .post('/api/exercise-clients', params, {
      cancelToken: new CancelToken(cancelRequest => cancelRequests.push(cancelRequest)),
    })
    .then(callbackSuccess)
    .catch(callbackFailure)
    .finally(callbackFinally);
};

export const removeExerciseFromGeneralList = ({
  data = {},
  callbackInit = () => {},
  callbackSuccess = () => {},
  callbackFailure = () => {},
  callbackFinally = () => {},
} = {}) => {
  if (data.loading || !data.exerciseId) return;
  callbackInit();
  axiosInstance
    .delete(`/api/exercise-clients/${data.exerciseId}`, {
      cancelToken: new CancelToken(cancelRequest => cancelRequests.push(cancelRequest)),
    })
    .then(callbackSuccess)
    .catch(callbackFailure)
    .finally(callbackFinally);
};

export const clearSearchGeneralCancelTokens = () => {
  searchGeneralCancelRequests.length = 0;
};

export const clearSearchRecentCancelTokens = () => {
  searchRecentCancelRequests.length = 0;
};

export const isIncludesAll = (arr1, arr2) => {
  const set1 = new Set(arr1);
  return arr2.every(item => set1.has(item));
};
