import React from 'react';
import * as S from './style';
import { Container } from 'shared/UpgradePath/style';
import { ReactComponent as UsdSymbol } from 'assets/icons/usd-symbol.svg';
import ProductUpgradePathSrc from 'assets/images/product_upgrade_path.svg';
import NewCountryFallBack from '../../../shared/UpgradePath/components/NewCountryFallBack';

const ProductUpgradePath = () => {
  const isPublic = process.env.REACT_APP_SUPPORT_PAYMENT_MULTI_COUNTRIES;

  return (
    <S.FallbackWrapperS>
      <S.ComingSoon>
        <UsdSymbol />
        <S.ComingText>
          {!isPublic ? (
            'Currently only available for US-based bank accounts. Coming soon for other countries!'
          ) : (
            <NewCountryFallBack />
          )}
        </S.ComingText>
      </S.ComingSoon>

      <Container>
        <S.Section>
          <S.Body>
            <S.Title>Onboard Clients Automatically</S.Title>
            <S.Description>
              Create sequences that automatically onboard and roll out training assignments, resources and community
              forums to your clients.
            </S.Description>
            <S.SUpgradePathButton className="upgrade-path-button" text="Get Payment & Packages"></S.SUpgradePathButton>
            <S.UpgradePathImg src={ProductUpgradePathSrc} />
          </S.Body>
        </S.Section>
      </Container>
    </S.FallbackWrapperS>
  );
};

export default ProductUpgradePath;
