import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { Wrapper } from './components/FreestyleAttachment/style';

export const InstructionContainer = styled.div`
  background-color: #f7f8fc;
  .freestyleNode {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: rgb(32, 35, 83);
    &:focus {
      -webkit-box-orient: initial;
      display: block;
      -webkit-line-clamp: initial;
      overflow: initial;
      outline: 0;
    }
  }
  .text-editable__container {
    padding-top: 4px;
    padding-bottom: 4px;

    .text-editable__input {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #202353;

      :empty::before {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #a3a8bc;
        opacity: 1;
      }
    }

    :not(.text-editable__container--active) {
      .text-editable__input {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        box-orient: vertical;
        overflow: hidden;
      }
    }

    :hover:not(.text-editable__container--active) {
      background-color: #e9ecf7;
    }

    &.text-editable__container--active {
      .text-editable__input {
        min-height: 40px;
      }
    }
  }

  &.white,
  &.minimizeOfFreestyle {
    width: calc(100% - 30px);
    margin: auto;
    padding: 0;
    background-color: white;
    box-shadow: 0px 4px 8px #f1f2f6;
    border-radius: 3px;

    .text-editable__container {
      padding: 7px 15px;

      .text-editable__input {
        line-height: 150%;
      }

      :hover:not(.text-editable__container--active) {
        background-color: white;
      }
    }

    &.minimizeOfFreestyle {
      margin-bottom: 5px;
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: transparent;
      box-shadow: none;

      .text-editable__container {
        padding: 4px;

        &:hover {
          padding: 4px 9px;
          background-color: #f1f1f3;
        }

        &.text-editable__container--active {
          padding: 7px 15px;

          &:hover {
            padding: 7px 15px;
            background-color: white;
          }
        }
      }
    }
  }
`;

export const MarkAllLabel = styled.span`
  color: #c0c4ca;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const MarkAllSections = styled.div`
  display: flex;
  width: 72px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 5px;

    circle {
      fill: rgba(255, 255, 255, 1);
      opacity: 0.8;
    }
  }

  &:hover {
    ${MarkAllLabel} {
      color: #ffffff;
    }

    svg {
      circle {
        opacity: 1;
      }
    }
  }
`;

export const CollapseArea = styled.div`
  cursor: pointer;

  ${props =>
    props.isCollapse &&
    css`
      svg {
        transform: rotateZ(270deg);
      }
    `}
`;

export const LinkSet = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  svg {
    z-index: 2;
  }

  ${props =>
    props.linked &&
    css`
      :before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: 100%;
        background: #dadfea;
        z-index: 1;
      }
    `}
`;

export const FreestyleSection = styled.div`
  ${props =>
    props.tracking &&
    css`
      ${Wrapper} {
        border: 1px solid #dadfea;
        box-shadow: 0px 2px 3px rgba(76, 98, 135, 0.25);
        border-radius: 8px;

        .upload__file {
          margin-bottom: 5px;
        }

        .file__name {
          color: #111111;
          font-weight: 600;
          font-size: 11px;
          opacity: 0.8;
        }

        .file__size {
          font-weight: 600;
          font-size: 11px;
          color: #979797;

          svg {
            width: 11px;
            height: 11px;
          }
        }

        .duration-text {
          p {
            line-height: 11px;
          }
        }
      }

      ${InstructionContainer} {
        .text-editable__container {
          &.text-editable__container--active {
            color: #202353;
            background: transparent;
            font-weight: 400;
            font-size: 13px;
            line-height: 150%;
            padding: 0;
            border-radius: 0;

            .text-editable__input {
              min-height: auto;
            }
          }
        }
      }

      .exercise-list-header-log-workout {
        margin: 30px -15px -5px;
        padding: 5px 18px 5px 15px;

        &:hover {
          background: #e8ebf3;
          border-radius: 4px;
        }
      }

      .alt-exs {
        margin-top: 12px;
        padding: 0;
        border: none;
        box-shadow: 0px 4px 8px #f1f2f6;

        .alt-exs__exercise-menu {
          padding: 15px;
        }
      }

      .media-preview__trigger {
        border-radius: 3px;
      }
    `}
`;

export const MarkAllInSection = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 10px;
  justify-content: flex-end;
  align-items: center;

  ${MarkAllLabel} {
    color: #5158cf;
  }

  svg {
    border: 1px solid #5158cf;
    border-radius: 50%;

    circle {
      fill: rgba(255, 255, 255, 1);
      opacity: 1;
    }

    path {
      fill: #5158cf;
    }
  }

  ${MarkAllSections} {
    &:hover {
      ${MarkAllLabel} {
        color: #5158cf;
      }

      svg {
        circle {
          opacity: 1;
        }
      }
    }
  }
`;

export const MarkAllInSet = styled(MarkAllInSection)`
  padding: 15px 0;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dadfea;
`;

export const RoundOfSection = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  background: #eef2fb;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #dadfea;
  }

  .round-item {
    color: #000000;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    min-width: 134px;
    background: #eff2fb;
    padding: 16px 0;
    border-right: 1px solid #dadfea;

    &:last-child {
      border-right: none;
    }

    &.resizeWidth {
      width: 50px;
      min-width: auto;
      &:last-child {
        border-right: 1px solid #dadfea;
      }
    }

    &.default {
      width: 86px;
      min-width: auto;
    }

    &.active {
      background: #f7f8fc;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: #f7f8fc;
      }
    }
  }
`;

export const StartTimeButton = styled.div`
  display: flex;
  width: 40px;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255 / 40%);
  cursor: pointer;
  border-top-right-radius: 5px;

  &:hover {
    background: #ffffff;
    opacity: 0.8;

    svg {
      path {
        fill: #262751;
      }
    }
  }
`;

export const Timer = styled.div`
  display: flex;
  min-width: 92px;
  height: 100%;
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  background: rgb(255 255 255 / 20%);
  justify-content: center;
  align-items: center;
`;

export const RoundSections = styled.div`
  display: flex;
  min-width: 132px;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const TrainingTimeOfSection = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #edf0f5;
  border-radius: 5px;
`;

export const TrainingTimeTitle = styled.div`
  color: #202353;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 5px;
`;

export const InputTimer = styled.input`
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #dadfea;
  max-width: 194px;
  max-height: 44px;
  border: 1px solid #dadfea;
  border-radius: 4px;

  &:focus,
  &:hover {
    border-color: #5158cf;
    outline: 0;
  }
  &:disabled {
    color: #dadfea;
    cursor: not-allowed;
    border: 1px solid #dadfea;
    background-color: #fff;
  }

  ${props =>
    props.disable &&
    css`
      cursor: not-allowed;
    `}

  ${props =>
    (props.isErrorTrainingSet || (!props.disabled && props.trainingTime < 1)) &&
    css`
      border: 1px solid #ea314a !important;
    `}
`;

export const TimeWrapper = styled.div`
  display: flex;
`;

export const TimeText = styled.span`
  padding: 11px 0;
  width: 92px;
  text-align: center;
  font-size: 13px;
  color: #fff;
  line-height: 18px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const ButtonWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.showIcon
      ? css`
    :hover {
      background-color: rgba(255, 255, 255, 0.8);
  
      svg {
        path {
          fill: #262751;
        }
      }
    }
      }
    `
      : css`
          background-color: rgba(255, 255, 255, 0.8);
        `}

  ${props =>
    props.isStop &&
    css`
      pointer-events: none;
      background-color: rgba(255, 255, 255, 0.2);
    `}
`;

export const Tooltip = styled(ReactTooltip)`
  line-height: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  background: #2d2e2d;
`;

export const SectionNote = styled.div`
  padding: 15px 20px 0;
  font-size: 13px;
  color: #202353;
  background: #f7f8fc;
  word-break: break-word;
  white-space: pre-wrap;

  ${props =>
    props.hasRoundsBySection &&
    css`
      padding-bottom: 15px;
      box-shadow: inset 0px -1px 0px #dadfea;
    `}

  ${props =>
    props.isCollapse &&
    css`
      box-shadow: none;
      padding-bottom: 15px;
    `}

  p {
    margin: 0;
    ${props =>
      props.isCollapse &&
      css`
        &.sectionNote {
          margin-bottom: 15px;
        }
      `}

    :first-child {
      line-height: 20px !important;
    }
    &.sectionNote {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      &:focus {
        -webkit-box-orient: initial;
        display: block;
        -webkit-line-clamp: initial;
        overflow: initial;
        outline: 0;
      }
    }

    :last-child {
      line-height: 13px !important;
      &.sectionNote {
        margin-bottom: 0;
      }
      ${props =>
        props.tracking &&
        css`
          line-height: 20px !important;
        `}
    }
  }

  strong {
    font-weight: 600;
  }
`;

export const RoundWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  box-shadow: inset 0px -1px 0px #dadfea;
  margin-top: -1px;
`;

export const RoundText = styled.span`
  padding: 16px 35px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #758094;
  cursor: pointer;
  background-color: #eff2fb;
  box-shadow: inset -1px -1px 0px #dadfea;
  border-top: 1px solid #dadfea;

  ${props =>
    !props.isValidField &&
    !props.text &&
    // props.isMarkAllBySection && // TODO
    !props.active &&
    css`
      border: 1px solid #ea314a;
      margin-left: -1px;
      margin-top: 0;
      padding: 16px 33px;
      box-shadow: none;
      z-index: 1;

      &:nth-child(1) {
        margin-left: 1px;
      }
    `}

  ${props =>
    props.active &&
    css`
      color: #000;
      position: relative;
      background-color: #f7f8fc;
      box-shadow: inset -1px 0px 0px #dadfea;
      border-top: 1px solid #dadfea;

      ${props =>
        props.isNumberOfRound === 1 &&
        css`
          ${props.isManyRound &&
          css`
            border-left: 1px solid #dadfea;
          `}
        `}

      &:before {
        content: '';
        width: calc(100% - 1px);
        height: 1px;
        background: #f7f8fc;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    `}

  ${props =>
    props.text &&
    css`
      padding-left: 20px;
      padding-right: 25px;
      border-top: 1px solid #dadfea;
      box-shadow: inset 0px -1px 0px #dadfea;
    `}

  ${props =>
    props.number &&
    css`
      padding: 16px 21px;
      border-top: 1px solid #dadfea;

      ${props =>
        props.isNumberOfRound === 1 &&
        css`
          border-left: 1px solid #dadfea;
        `}
    `}

    ${props =>
    !props.isValidField &&
    props.number &&
    !props.active &&
    css`
      border-top: 1px solid #ea314a;

      ${props =>
        props.isNumberOfRound === 1 &&
        css`
          border-left: 1px solid #ea314a;
        `}
    `}
`;

export const ManyRoundWrapper = styled.div`
  display: flex;
  overflow: auto;
  ::-webkit-scrollbar:horizontal {
    background-color: #dadfea;
    height: 3px !important;
  }
  &:hover {
    ::-webkit-scrollbar:horizontal {
      height: 7px !important;
    }
  }
`;

export const RoundAdd = styled.span`
  padding: 19px;
  background: #f7f8fc;
  cursor: pointer;
  box-shadow: inset -1px -1px 0px #dadfea;
  border-top: 1px solid #dadfea;

  svg {
    path {
      fill: #bcc5d8;
    }
  }
`;
