import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import SelectProgram from './component';
import { assignProgram } from 'redux/program_library/program_library.actionCreators';
import { assignProgramToStudioProgram } from 'redux/studio-program/actions';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { all_items } = rootReducer.program_library;
  return {
    all_items,
    previewItem: rootReducer.program_library.preview_selected_item,
    loadingPreviewItem: rootReducer.program_library.loadingPreviewItem,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    assignProgramToStudioProgram: data => {
      return dispatch(assignProgramToStudioProgram(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProgram);
