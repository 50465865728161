import React, { useEffect, useRef, useState } from 'react';
import Editor from 'draft-js-plugins-editor';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { isUndefined } from 'lodash';

import 'draft-js/dist/Draft.css';
import * as S from './style';

const CoachBioEditor = ({ isOwnerEdit, initEditor, placeholder, onInputChange, loading }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef(null);
  const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
  const plugins = [linkifyPlugin];

  useEffect(() => {
    const initState = isUndefined(initEditor)
      ? EditorState.createEmpty()
      : EditorState.createWithContent(ContentState.createFromText(initEditor));
    setEditorState(() => initState);
  }, [loading]);

  const onChange = editorState => {
    const rawData = convertToRaw(editorState.getCurrentContent());
    const { blocks } = rawData;
    const value = blocks
      .map(block => (!block.text.trim() && '') || block.text.trim())
      .join('\n')
      .trim();
    onInputChange && onInputChange(value);
    setEditorState(editorState);
  };

  return (
    <S.CoachEditor>
      <S.CoachLabel>{isOwnerEdit ? 'Your Bio' : 'Coach Bio'}</S.CoachLabel>
      <Editor
        plugins={plugins}
        ref={editorRef}
        editorState={editorState}
        onChange={onChange}
        placeholder={placeholder}
      />
    </S.CoachEditor>
  );
};

export default CoachBioEditor;
