import _ from 'lodash';
import { Types } from './actions';
import { SUCCESS_LOGIN_USER } from 'actions/auth/login';
import { DEFAULT_CLIENT_FILTERS } from 'constants/commonData';

const INITIAL_STATE = {
  list: [],
  selected: null,
  filters: DEFAULT_CLIENT_FILTERS,
  totalClients: 0,
};

export default (state = INITIAL_STATE, action) => {
  let list = state.list.slice(),
    index;
  let filters = Object.assign({}, state.filters);
  const { payload } = action;

  switch (action.type) {
    case Types.SUCCESS_GET_ALL_SEGMENTS:
      if (payload.list) {
        list = payload.list;
      }

      return Object.assign({}, state, { list, totalClients: payload.total });

    case Types.SUCCESS_ADD_NEW_SEGMENT:
      if (payload.segment) {
        list.push(payload.segment);
      }

      return Object.assign({}, state, { list, selected: payload.segment._id });

    case Types.SUCCESS_UPDATE_SEGMENT:
      if (payload.segment) {
        index = _.findIndex(list, item => item._id === payload.segment._id);

        if (index != -1) {
          list[index] = payload.segment;
        }
      }

      return Object.assign({}, state, { list });

    case Types.SUCCESS_DELETE_SEGMENT:
      if (payload.segmentId) {
        list.splice(
          _.findIndex(list, item => item._id === payload.segmentId),
          1,
        );
      }

      return Object.assign({}, state, { list });

    case Types.SEGMENT_RESET_SELECTED:
      return Object.assign({}, state, { selected: null });

    case Types.INBOX_SEGMENT_RESET_SELECTED:
      return Object.assign({}, state, { selected: null, filters: DEFAULT_CLIENT_FILTERS });

    case Types.CHANGE_CLIENT_FILTERS:
      if (payload.key === 'last_activity') {
        filters.last_activity = payload.value.days;
        filters.last_activity_type = payload.value.type;
      } else if (payload.key === 'last_assigned_workout') {
        filters.last_assigned_workout = payload.value.days;
        filters.last_assigned_workout_type = payload.value.type;
      } else {
        filters[payload.key] = payload.value;
        return Object.assign({}, state, { filters });
      }

    case SUCCESS_LOGIN_USER:
      const { user } = action;

      if (user && filters.ownerShip === '') {
        filters.ownerShip = user._id;
      }

      return Object.assign({}, state, { filters });

    case Types.SELECT_SEGMENT:
      return Object.assign({}, state, {
        selected: payload.segment,
        filters: payload.filters,
        isPermitDenied: payload.isPermitDenied,
      });

    case Types.SEGMENT_ACTIVE_WAITING_SEGMENT:
      return Object.assign({}, state, { selected: payload });

    case Types.SUCCESS_ACTIVE_SEGMENT:
    case Types.SUCCESS_HIDE_SEGMENT:
      _.forEach(list, item => {
        if (item._id === payload.segment) {
          item.is_visible = payload.isVisible;
          return false;
        }
      });

      return Object.assign({}, state, { list });

    default:
      return state;
  }
};
