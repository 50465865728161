import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Image } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import { Wrapper, Trigger, Item, Menu } from './style';
import { CDN_URL } from 'constants/commonData';

const SET_TYPES = [
  { value: '', key: 'regular', text: 'Regular', label: 'R' },
  { value: 'warm_up', key: 'warm_up', text: 'Warm up', label: 'W' },
  { value: 'drop_set', key: 'drop_set', text: 'Drop set', label: 'D' },
  { value: 'failure', key: 'failure', text: 'Failure', label: 'F' },
];

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.trigger = React.createRef();
  }

  renderMenu = () => {
    if (!this.trigger.current || !this.state.open) {
      return null;
    }

    const { onSelect, value, setIndex } = this.props;
    const viewportHeight = window.innerHeight;
    const { height, x, y } = this.trigger.current.getBoundingClientRect();
    const styles = { left: x };
    const total = SET_TYPES.length;
    const bottomSpacing = viewportHeight - (y + height + 2);
    const topSpacing = y - 2;

    if (bottomSpacing >= total * 35 || bottomSpacing >= topSpacing) {
      styles.top = y + height + 2;
      styles.maxHeight = bottomSpacing - 5;
    } else {
      styles.bottom = viewportHeight - topSpacing;
      styles.maxHeight = topSpacing - 5;
    }

    return (
      <Menu style={styles}>
        {_.map(SET_TYPES, item => (
          <Item
            key={item.key}
            className={item.value === value ? 'seleted' : ''}
            onClick={() => {
              this.setState({ open: false });

              if (item.value !== value) {
                onSelect(item.value, setIndex);
              }
            }}
          >
            <div className={`type-label ${item.value}`}>{item.label}</div> <div>{item.text}</div>
          </Item>
        ))}
      </Menu>
    );
  };

  render() {
    const { open } = this.state;
    const { className, value, setIndex, disabled } = this.props;
    let wrapperClass = 'setTypeSelection';

    if (className) {
      wrapperClass += ' ' + className;
    }

    if (disabled) {
      wrapperClass += ' disabled';
    }

    return (
      <RootCloseWrapper disabled={!open || disabled} event="click" onRootClose={() => this.setState({ open: false })}>
        <Wrapper className={wrapperClass}>
          <Trigger
            className={classnames('setTypeSelection__trigger', value || 'regular-set')}
            onClick={() => (disabled ? null : this.setState({ open: !open }))}
            ref={this.trigger}
          >
            {this.props.isHaveRound && !value ? (
              <span>1</span>
            ) : (
              <span>{value ? value.substring(0, 1).toUpperCase() : setIndex + 1}</span>
            )}
            <Image src={`${CDN_URL}/images/arrow-down.svg`} />
          </Trigger>
          {this.renderMenu()}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}
