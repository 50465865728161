export const DEVICES = ['android', 'ios'];
export const UNITS = [
  {
    key: 'weight',
    dbName: 'mass',
    label: 'Weight Unit',
    units: [
      { key: 'kg', label: 'Metric (kg)' },
      { key: 'lb', label: 'US/Imperial (lb)' },
    ],
  },
  {
    key: 'distance',
    dbName: 'distance',
    label: 'Distance Unit',
    units: [
      { key: 'km', label: 'Metric (km)' },
      { key: 'mi', label: 'US/Imperial (miles)' },
    ],
  },
  {
    key: 'length',
    dbName: 'length',
    label: 'Length Unit',
    units: ['cm', 'in'],
    units: [
      { key: 'cm', label: 'Metric (cm)' },
      { key: 'in', label: 'US/Imperial (inch)' },
    ],
  },
];

export const ANDROID_TIP = 'Client is using an Android device.';
export const IOS_TIP = 'Client is using an iOS device. All features are available to the client.';
export const FEATURES_TIP = `Create a custom experience for every client by disabling or enabling certain features. When a feature is disabled, it will not appear on your client's mobile app`;

export const SIDEBAR_ID = {
  units: 'units',
  timezone: 'timezone',
  features: 'features',
  workout: 'workout',
  permission: 'permission',
  sync_setting: 'sync_setting',
};

export const SIDEBAR_SETTING = [
  {
    title: 'Units',
    id: SIDEBAR_ID.units,
  },
  {
    title: 'Timezone',
    id: SIDEBAR_ID.timezone,
  },
  {
    title: 'Features',
    id: SIDEBAR_ID.features,
  },
  {
    title: 'Workout Settings',
    id: SIDEBAR_ID.workout,
  },
  {
    title: 'Team Permission',
    id: SIDEBAR_ID.permission,
  },
];
