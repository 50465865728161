import React from 'react';
import { Modal, List, Segment } from 'semantic-ui-react';
import styles, {
  ActionsContainer,
  SelectDayInputValue,
  ChooseStartDateLabel,
  SelectStartDayContainer,
  WarningContainer,
} from './styles';
import './styles.scss';
import { toggleModal } from 'actions/modal';
import SearchInput from 'shared/SearchInput';
import _ from 'lodash';
import {
  getProgramLibrary,
  loadPreviewItem,
  loadingPreviewItem,
} from 'redux/program_library/program_library.actionCreators';
import Avatar from 'react-avatar';
import Floater from 'react-floater';
import { CDN_URL, floaterStyles } from 'constants/commonData';
import { toast } from 'react-toastify';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import SelectProgramDay from 'shared/SelectProgramDay';
import { Button } from 'shared/FormControl';
import { pluralize } from 'utils/commonFunction';
import LoadingIndicator from 'shared/LoadingIndicator';
import { connect } from 'react-redux';

class SelectProgramForStudioProgramModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: '',
      startingAt: 0,
      endingAt: _.get(props, 'previewItem.workout_sets.length') ? props.previewItem.workout_sets.length * 7 - 1 : 1,
      isSubmitting: false,
    };
    this.selectDayPopupOffset = { top: 6 };
  }

  componentDidMount() {
    this.props.dispatch(getProgramLibrary(true));
  }

  componentDidUpdate(prevProps) {
    const { all_items, previewItem, dispatch } = this.props;

    if (all_items.length > 0 && !previewItem) {
      let sorted = all_items.slice();
      sorted.sort((lhs, rhs) => (lhs.last_interacted < rhs.last_interacted ? 1 : -1));
      dispatch(loadPreviewItem(_.get(sorted, '[0]._id')));
    }

    if (previewItem && _.get(prevProps, 'previewItem._id') !== _.get(previewItem, '_id')) {
      this.setState({ startingAt: 0, endingAt: previewItem.workout_sets.length * 7 - 1 });
    }
  }

  onSelectProgram = () => {
    const { previewItem: programLibrary, programId, assignProgramToStudioProgram, dayIndex, weekIndex } = this.props;
    const { isSubmitting, startingAt } = this.state;
    //     {
    //   "programId": "629d6a458da4ed4bc9d3f85c", //done
    //   "programLibraryId": "629d6a458da4ed4bc9d3f85c",
    //   "dayIndex": 0, //done
    //   "weekIndex": 0, //done
    //   "startingDay": 0
    // }

    if (isSubmitting) {
      return;
    }

    try {
      assignProgramToStudioProgram({
        programId: programId, //done
        programLibraryId: programLibrary._id,
        dayIndex: dayIndex, //done
        weekIndex: weekIndex, //done
        startingDay: startingAt,
      });
    } catch {}

    // try {
    //   this.props.assignProgram(date, programId._id, startingAt, endingAt).then(() => {
    //     const totalDay = programId.workout_sets.length * 7;

    //     if (showEndingOption && endingAt < totalDay - 1) {
    //       Mixpanel.track(`set_end_date_program_on_${from}`);
    //     }
    //   });
    // } catch {}

    toast.success(`The program has been assigned, this action might take several minutes`, {
      className: 'margin-right--small',
    });
    this.closeModel();
  };

  closeModel = () => this.props.dispatch(toggleModal(false));

  onSelectStartingDay = dayIndex => {
    this.setState({ startingAt: dayIndex });
  };

  renderWarning = () => {
    const { date, previewItem, totalDays } = this.props;
    const { startingAt } = this.state;

    const programDuration = previewItem.workout_sets.length * 7 - startingAt;
    const instanceDuration = totalDays - date;
    const diff = programDuration - instanceDuration;

    if (diff > 0) {
      return (
        <WarningContainer>
          The selected program is longer than the Studio Program duration. {pluralize('remaining day', diff, true)} will
          not be assigned.
        </WarningContainer>
      );
    }

    return null;
  };

  handleSelectStartDay = day => {
    this.setState({ startingAt: day });
  };

  handleSelectEndDay = day => {
    this.setState({ endingAt: day });
  };

  render() {
    const { startingDayControlled, previewItem } = this.props;
    const { startingAt, isSubmitting } = this.state;

    return (
      <Modal
        className="new-workout-modal selectProgramForStudioProgramModal"
        open={true}
        onClose={this.closeModel}
        style={{ minWidth: 400 }}
      >
        <Modal.Header style={styles.headerContainer}>
          <span className="assign-workout-title">Choose Program</span>
          <button onClick={this.closeModel} className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        </Modal.Header>
        <Modal.Content>
          <div style={styles.contentContainer}>
            {this.renderSearch()}
            {this.renderPreview()}
          </div>
        </Modal.Content>
        <Modal.Actions>
          {startingDayControlled && <ChooseStartDateLabel>Choose Start Date Of program Content </ChooseStartDateLabel>}
          <ActionsContainer>
            {startingDayControlled && previewItem ? (
              <SelectStartDayContainer>
                <SelectProgramDay
                  value={startingAt}
                  totalWeek={previewItem.workout_sets.length}
                  onSelect={this.onSelectStartingDay}
                  inputValue={
                    <SelectDayInputValue>
                      <span>
                        Day {startingAt + 1} - Week {Math.floor(startingAt / 7) + 1}
                      </span>
                      <img src={`${CDN_URL}/images/new_calendar.svg`} alt="" />
                    </SelectDayInputValue>
                  }
                />
                {this.renderWarning()}
              </SelectStartDayContainer>
            ) : (
              <div />
            )}
            <Button purple onClick={this.onSelectProgram}>
              {!isSubmitting ? 'Assign' : 'Assigning'}
            </Button>
          </ActionsContainer>
        </Modal.Actions>
      </Modal>
    );
  }

  onChangePreviewItem = event => {
    const { itemId } = event.currentTarget.dataset;
    const { previewItem } = this.props;

    if (!previewItem || previewItem._id !== itemId) {
      this.props.dispatch(loadingPreviewItem());
      this.setState({ startingAt: 0 });
      this.props.dispatch(loadPreviewItem(itemId));
    }
  };

  renderSearch = () => {
    const { previewItem, all_items } = this.props;
    const { textSearch } = this.state;
    let sorted = all_items.slice();
    sorted.sort((lhs, rhs) => (lhs.last_interacted < rhs.last_interacted ? 1 : -1));
    const search = textSearch.trim().toLowerCase();
    const filteredItems = _.filter(sorted, item => item.title.toLowerCase().includes(search));

    return (
      <div className="new-workout-select-side">
        <div className="new-workout-col-title search-title">SEARCH</div>
        <div className="new-workout-search-bar">
          <SearchInput
            value={textSearch}
            onChange={(evt, data) => this.setState({ textSearch: data.value })}
            placeholder={'Search program'}
            onAddOnIconClick={() => this.setState({ textSearch: '' })}
          />
        </div>
        <div className="new-workout-col-title most-recent-title">MOST RECENTS ({sorted.length})</div>
        <Segment fluid style={styles.workoutListContainer} className="find-workout-list-item">
          <List verticalAlign={'middle'}>
            {filteredItems.map((item, idx) => {
              let numWorkouts = item.total_workouts;
              let workouts_text = '';
              if (numWorkouts) {
                workouts_text = `${numWorkouts} workouts •`;
              }
              return (
                <List.Item
                  key={item._id}
                  active={previewItem && previewItem._id == item._id ? true : false}
                  className="new-workout-list select-program-list"
                  data-item-id={item._id}
                  onClick={this.onChangePreviewItem}
                >
                  <div className="new-workout-list-item">
                    <span>{item.title}</span>
                    {item.active_sync && <span className="live-sync">Live Sync</span>}
                  </div>
                  <div className="new-workout-list-detail">
                    {workouts_text} {item.workout_sets.length} weeks
                  </div>
                </List.Item>
              );
            })}
          </List>
        </Segment>
      </div>
    );
  };

  renderPreview() {
    const { previewItem, loadingPreviewItem, cloudfrontList } = this.props;
    const { startingAt, endingAt } = this.state;

    if (!previewItem) {
      return null;
    }

    let { workout_sets } = previewItem;
    const views = [];
    let numDay = 0;
    let weekIndex = 0;
    let numDayWorkouts = 0;
    workout_sets.map(item => {
      let workouts = [];

      if (item.days_workout) {
        numDayWorkouts += item.days_workout.length;
        item.days_workout.map(day_workout_item => {
          day_workout_item.day_workout.workouts.map(workouts_item => {
            let workout_item = workouts_item;
            workout_item.day_index = day_workout_item.day_index;
            workouts.push(workout_item);
          });
        });
      }

      workouts = workouts.sort((wk1, wk2) => {
        let compare = wk1.day_index - wk2.day_index;
        return compare;
      });
      workouts.map(wkItem => {
        let dayIndex = wkItem.day_index + 1;
        numDay = 7 * weekIndex + dayIndex;
        let title = '';
        title = wkItem.title;
        views.push(
          <div
            className={`ui segment small new-workout-exercise-cell ${
              (numDay < startingAt + 1 || numDay > endingAt + 1) && 'in-past'
            }`}
            key={wkItem._id}
          >
            <span>{title} </span>
            <span>
              •{' Day '}
              {numDay}
            </span>
          </div>,
        );
      });
      weekIndex++;
    });

    const author = previewItem.author;

    return (
      <div className="new-workout-preview">
        <div className={`workout-preview__header${author ? ' has-author' : ''}`}>
          <div className="new-workout-col-title">
            {numDayWorkouts} DAY{numDayWorkouts > 1 ? 's' : ''}
          </div>
          {author && (
            <Floater
              content={<div>{`${author.first_name} ${author.last_name}`}</div>}
              event="hover"
              eventDelay={0}
              placement="top"
              offset={5}
              styles={{
                ...floaterStyles,
              }}
              id="studio-program-modal-floater"
            >
              <div className="author">
                <Avatar
                  name={getUserShortName(author)}
                  className="client-avatar"
                  size="24"
                  src={convertS3UrlToCloudFrontUrl(author.avatar, cloudfrontList, true)}
                  color={author.color}
                />
              </div>
            </Floater>
          )}
        </div>
        <Segment fluid style={styles.previewContainer}>
          <div className={'ui segment small preview-segment'} style={styles.exerciseListContainer}>
            {this.renderExercisesHeader()}
            {loadingPreviewItem && <LoadingIndicator className="loading-preview-items" />}
            {views}
          </div>
        </Segment>
      </div>
    );
  }

  renderExercisesHeader() {
    const { previewItem } = this.props;
    return (
      <div className="exercise-list-header">
        <div className="workout-title-status">{this.renderWorkoutTitle(previewItem)}</div>
      </div>
    );
  }

  renderWorkoutTitle(item) {
    let className = 'workout-cell-title';
    return <div className={className}>{item.title ? item.title.toUpperCase() : ''}</div>;
  }
}

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(SelectProgramForStudioProgramModal);
