// libs
import React from 'react';

// assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_grey.svg';

// styles
import * as S from './style';

const AddMealButton = ({ disabled, onClick }) => {
  return (
    <S.Button disabled={disabled} onClick={onClick}>
      <PlusIcon className="plus-icon" />
      <span className="label">Add meals</span>
    </S.Button>
  );
};

export default AddMealButton;
