import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

export const ModalWrapper = styled(Modal)`
  &.ui.modal.country-modal {
    width: 459px;
    border-radius: 8px;
    padding: 30px;
    > .header {
      padding: 0 !important;
    }
    > :last-child {
      padding: 35px 0 0;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .evfSelectBoxContainer {
    .evfSelectBox__control {
      width: 100%;
      height: 62px;
      padding-left: 15px;
      padding-right: 7px;
    }
    .evfSelectBox__control.evfSelectBox__control--menu-is-open {
      border-color: #dcdcde !important;
    }
    .evfSelectBox__menu {
      width: 325px;
      right: 0;
      border-color: #5158cf;
    }
    .evfSelectBox__menu-list {
      padding: 0 5px;
    }
    .evfSelectBox__control .evfSelectBox__value-container {
      padding-left: 0;

      .select__option {
        padding: 0;
      }
    }
    .evfSelectBox__dropdown-indicator {
      svg {
        color: #202353;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  align-items: center;
  position: relative;
  display: flex;
`;

export const HeaderTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 16px;
`;

export const HeaderMessage = styled.p`
  font-size: 13px;
  line-height: 150%;
  font-weight: normal;
  color: #323c47;
`;
export const Actions = styled.div`
  text-align: right;
  margin-top: 30px;
  button {
    height: 30px;
    padding: 0 29px;
    margin-left: 5px;
  }
`;
export const CountryInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  &.select__option.select__option--is-focused {
    background: #f7f7ff;
    border-radius: 5px;
  }
  .sb-avatar {
    margin-right: 10px;
  }
`;

export const SingleLabel = styled.div`
  &.select__option__label {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
  }
`;

export const Note = styled.p`
  font-weight: normal;
  padding-top: 5px;
  font-size: 10px;
  line-height: 150%;
  color: #323c47;
`;

export const ImageWrapper = styled.div`
  width: 40px;
  height: 30px;
  border-radius: 2px;
  overflow: hidden;
  margin-right: 10px;
  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
    `}
`;
