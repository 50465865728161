// libs
import React, { useEffect, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import debounce from 'lodash/debounce';

// actions
import { axiosInstance } from 'configs/request';

// shared
import LoadingIndicator from 'shared/LoadingIndicator';

// assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';

// styles
import * as S from './style';

const keyDefault = 'icon_everfit_default';

const AppIconModal = ({ onClose, onSave, currentSelected, appIconKeySystem }) => {
  const [selected, setSelected] = useState(currentSelected);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    fetchListIconSystem();
  }, []);

  useEffect(() => {
    if (!currentSelected) {
      const keyToFind = appIconKeySystem || keyDefault;
      const item = (list || []).find(({ key }) => key === keyToFind);
      setSelected(item);
    }
  }, [list]);

  const fetchListIconSystem = (isNextPage = false) => {
    !isNextPage && setIsLoading(true);
    isNextPage && setIsLoadingMore(true);

    const queryParams = {
      page: page,
      per_page: 20,
    };

    axiosInstance({
      method: 'GET',
      url: '/api/team/app-icon-systems',
      params: queryParams,
    })
      .then(response => {
        const { data, page, total } = (response || {}).data;

        setList(isNextPage ? [...list, ...data] : data);
        setTotal(total);
        setPage(page + 1);
      })
      .catch(error => {})
      .finally(() => {
        !isNextPage && setIsLoading(false);
        isNextPage && setIsLoadingMore(false);
      });
  };

  const handleLoadMore = event => {
    const { scrollLeft, clientWidth, scrollWidth } = event.target;
    const bottom = scrollWidth - (scrollLeft + clientWidth);

    if (list.length < total && bottom < 200 && !isLoading && !isLoadingMore) {
      fetchListIconSystem(true);
    }
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const renderLoadingIndicator = () => {
    return <LoadingIndicator title="Loading" size="medium" className="loading--custom-branding" />;
  };

  const renderItem = item => {
    const { _id, url, key } = item || {};

    return (
      <S.ContentItem
        key={_id}
        onClick={() => {
          setSelected(item);
          setIsChanged(true);
        }}
        isSelected={(selected || {})._id === _id}
      >
        <img src={url} alt={key} className="item-image" />
        {key === keyDefault && <div className="item-label">default</div>}
      </S.ContentItem>
    );
  };

  const renderList = () => {
    return <S.ContentBody>{(list || []).map(renderItem)}</S.ContentBody>;
  };

  return (
    <S.CustomModal open={true} onClose={onClose} closeOnDimmerClick={false} className="evf-app-icon-modal">
      <Modal.Header>
        <S.HeaderWrapper>
          <S.HeaderTitle>Choose App Icon</S.HeaderTitle>
          <CloseButton className="close-button" onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <S.ContentContainer onScroll={handleScroll}>
            {isLoading ? renderLoadingIndicator() : renderList()}
            {isLoadingMore ? (
              <LoadingIndicator title="Loading..." size="small" className="loading--custom-branding loading-more" />
            ) : null}
          </S.ContentContainer>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.FooterWrapper>
          <S.ButtonSave
            onClick={() => {
              onSave(selected);
              onClose();
            }}
            disabled={!isChanged}
          >
            Select
          </S.ButtonSave>
        </S.FooterWrapper>
      </Modal.Actions>
    </S.CustomModal>
  );
};

export default AppIconModal;
