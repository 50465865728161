export const SETTINGS_TYPE_MODAL = {
  COACHES: 'coaches',
  PACKAGES: 'packages',
};

export const ONBOARDING_SETTINGS_POPUP_GET_LIST = {
  [SETTINGS_TYPE_MODAL.COACHES]: {
    method: 'GET',
    url: '/api/v2/team/list-member',
    query: {
      page: 1,
      per_page: 20,
      search: '',
      sort: 1,
      sorter: 'full_name',
    },
  },
  [SETTINGS_TYPE_MODAL.PACKAGES]: {
    method: 'GET',
    url: '/api/payment/packages',
    query: {
      page: 1,
      pageSize: 20,
      sort: 'most_recent',
      order: 'DESC',
      status: 'publish',
    },
  },
};

export const SET_AS_DEFAULT_LIST = [
  { key: 'none', label: 'Not using as default' },
  { key: 'workspace_default', label: 'Use as workspace default onboarding flow' },
  { key: 'trainer_default', label: 'Use as my default onboarding flow' },
];
