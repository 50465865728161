import React from 'react';
import _ from 'lodash';
import { initIntervalExerciseFields, initSetsData } from './exercise';
import { SECTION_FORMAT_KEY } from 'constants/commonData';
import { ReactComponent as VideoTypeIcon } from 'assets/icons/section_type_video.svg';
import { ReactComponent as PhotoTypeIcon } from 'assets/icons/section_type_photo.svg';
import { ReactComponent as AudioTypeIcon } from 'assets/icons/section_type_audio.svg';
import { ReactComponent as DocumentTypeIcon } from 'assets/icons/section_type_document.svg';
import { ReactComponent as LinkTypeIcon } from 'assets/icons/freestyle_link_icon.svg';
import { validateVimeoUrl, validateYouTubeUrl } from 'utils/validations';
import { convertUnit } from 'utils/commonFunction';

export const parseTrainingSets = (trainingSets, unit = null, isLog = false) => {
  let result = [];

  _.forEach(trainingSets, set => {
    const setCopy = _.omit(set, ['_id']);

    _.forEach(setCopy, (data, key) => {
      if (_.isObject(data) && data.value !== undefined) {
        const setUnit = _.get(unit, key, '');

        if ((isLog && data.value === '-') || data.value === null) {
          data.value = '';
        }

        if (setUnit && typeof setUnit === 'object') {
          data.set_unit = setUnit._id || '';
        } else {
          const unit_id = _.get(setCopy[key], 'unit');
          if (unit_id) {
            data.set_unit = unit_id;
          }
        }
      }
    });

    result.push(setCopy);
  });

  return result;
};

export const parseSectionExercises = (section, unit, isTracking) => {
  let exercises = [];
  _.forEach(section.exercises, itm => {
    let supersets = [];
    itm.supersets.map(exercise => {
      let superset = {};
      if (isTracking) {
        superset.comment = exercise.comment;
      } else {
        superset.note = exercise.note;
      }

      superset.exercise = _.get(exercise, 'exercise._id', '') || _.get(exercise, 'exercise_instance.exercise', '');

      let tempoValue = '';
      let tempoStr = exercise.tempo && exercise.tempo !== '0' ? exercise.tempo + '' : '';

      if (tempoStr) {
        let tempoArray = tempoStr.split('-');

        for (let i = 0; i < 4; i++) {
          let value = tempoArray[i];

          if (!value) {
            tempoArray[i] = 0;
          }
        }

        tempoValue = tempoArray.join('-');
      } else {
        tempoValue = '0';
      }

      superset.tempo = tempoValue.toUpperCase();
      superset.each_side = exercise.each_side;
      superset.exercise_instance = exercise.exercise_instance;
      superset.training_sets = parseTrainingSets(exercise.training_sets, unit);
      superset.alternatives = _.map(exercise.alternatives || [], '_id');

      if (superset.exercise_instance) {
        superset.exercise_instance.exercise = superset.exercise;
      }

      supersets.push(superset);
    });

    exercises.push({ supersets });
  });

  return exercises;
};

export const convertFromWorkoutToAPIExercise = (workout, unit = null, isTracking = false) => {
  let newWorkout = _.cloneDeep(workout);

  _.forEach(newWorkout.sections, ssItem => {
    ssItem.exercises = parseSectionExercises(ssItem, unit, isTracking);

    if (ssItem._id && ssItem._id.startsWith('new_section')) {
      delete ssItem._id;
    }
  });

  return newWorkout.sections;
};

export const convertFromWorkoutSetsAPIExercise = (sets, unit = null, isTracking = false) => {
  const exercises = [];
  sets.map(itm => {
    let supersets = [];
    itm.supersets.map(exercise => {
      let superset = {};
      if (isTracking) {
        superset.comment = exercise.comment;
        superset.each_side = exercise.each_side;
      } else {
        superset.note = exercise.note;
      }

      superset.exercise = _.get(exercise, 'exercise._id', '') || _.get(exercise, 'exercise_instance.exercise', '');
      superset.training_sets = parseTrainingSets(exercise.training_sets, unit, true);
      superset.exercise_instance = exercise.exercise_instance;
      superset.alternatives = _.map(exercise.alternatives || [], '_id');

      if (superset.exercise_instance) {
        superset.exercise_instance.exercise = superset.exercise;
      }

      supersets.push(superset);
    });

    exercises.push({ supersets });
  });
  return exercises;
};

export const deleteExerciseSet = (workout, sectionIndex, setIndex, exIndex) => {
  let section = workout.sections[sectionIndex];

  if (section) {
    let set = section.exercises[setIndex];
    set.supersets.splice(exIndex, 1);

    if (!set.supersets.length) {
      section.exercises.splice(setIndex, 1);

      if (!section.exercises.length) {
        workout.sections.splice(sectionIndex, 1);
      }
    }
  }
};

export const updateSetNumberInWorkout = (workout, setIndex, exIndex) => {
  let exSet = workout.training_sets[setIndex];
  let ex = exSet.supersets[exIndex];
  exSet.supersets.forEach(item => {
    if (item.training_sets.length != ex.length) {
      if (item.training_sets.length < ex.training_sets.length) {
        let diff = ex.training_sets.length - item.training_sets.length;
        let lastSet = item.training_sets.length
          ? _.omit(item.training_sets[item.training_sets.length - 1], ['_id'])
          : {};

        for (let idx = 0; idx < diff; idx++) {
          item.training_sets.push(_.cloneDeep(lastSet));
        }
      } else {
        let diff = item.training_sets.length - ex.training_sets.length;
        for (let idx = 0; idx < diff; idx++) {
          item.training_sets.splice('-1');
        }
      }
    }
  });
};

export const updateSetNumberInSection = (section, setIndex, exIndex) => {
  let exSet = section.exercises[setIndex];
  let ex = exSet.supersets[exIndex];
  exSet.supersets.forEach(item => {
    if (item.training_sets.length != ex.length) {
      if (item.training_sets.length < ex.training_sets.length) {
        let diff = ex.training_sets.length - item.training_sets.length;
        let lastSet = item.training_sets.length
          ? _.omit(item.training_sets[item.training_sets.length - 1], ['_id'])
          : {};

        for (let idx = 0; idx < diff; idx++) {
          item.training_sets.push(_.cloneDeep(lastSet));
        }
      } else {
        let diff = item.training_sets.length - ex.training_sets.length;
        for (let idx = 0; idx < diff; idx++) {
          item.training_sets.splice('-1');
        }
      }
    }
  });
};

export const convertFromExerciseSetToSupersetsJson = exerciseSet => {
  let exerciseJson = {
    exercise: _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', ''),
    note: exerciseSet.note,
    each_side: _.get(exerciseSet, 'each_side', false),
    tempo: _.get(exerciseSet, 'tempo', ''),
  };

  exerciseJson.training_sets = parseTrainingSets(exerciseSet.training_sets);
  exerciseJson.exercise_instance = exerciseSet.exercise_instance;
  exerciseJson.alternatives = _.map(exerciseSet.alternatives || [], '_id');

  if (exerciseJson.exercise_instance) {
    exerciseJson.exercise_instance.exercise = exerciseJson.exercise;
  }

  let json = { supersets: exerciseJson };

  return json;
};

export const maximumRenderedExercises = (workout, containerHeight, sectionHeight = 79, exerciseHeight = 53) => {
  let tmpHeight = 0;
  const { sections } = workout;
  let maxRender = 0;

  if (tmpHeight > containerHeight) {
    return;
  }
  _.forEach(sections, (section, index) => {
    if (section.type === 'hidden') {
      const exercise = section.exercises ? section.exercises[0] : {};
      _.forEach(exercise.supersets, ex => {
        tmpHeight += exerciseHeight;
        if (tmpHeight > containerHeight) {
          return;
        } else {
          maxRender++;
        }
      });
    } else {
      tmpHeight += sectionHeight;
      if (tmpHeight > containerHeight) {
        return;
      } else {
        maxRender++;
      }
    }
  });

  return maxRender;
};

// export const maximumRenderedExercises = (workout, containerHeight, sectionHeight = 79, exerciseHeight = 53) => {
//   let maxRender = 0;
//   let tmpHeight = 0;
//   const { sections } = workout;

//   let emptySections = 0;

//   _.forEach(sections, (section, index) => {
//     if (section.type === 'hidden') {
//       const exercise = section.exercises ? section.exercises[0] : {};
//       _.forEach(exercise.supersets, ex => {
//         tmpHeight += exerciseHeight;

//         if (tmpHeight > containerHeight) {
//           return false;
//         } else {
//           maxRender++;
//         }
//       });
//     } else {
//       tmpHeight += sectionHeight;

//       if (tmpHeight > containerHeight) {
//         return false;
//       } else {
//         if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
//           maxRender += section.exercise_references.length || 1;
//           if (!section.exercise_references.length) {
//             emptySections++;
//           }
//         } else {
//           maxRender += _.sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
//         }
//       }
//     }

//     if (tmpHeight > containerHeight) {
//       return false;
//     }
//   });

//   return maxRender - emptySections;
// };

export const breadownIntoMultiExercises = exercises => {
  let result = [];
  _.forEach(exercises, set => {
    _.forEach(set.supersets, ex => {
      if (ex.training_sets) {
        const list = _.map(ex.training_sets, (exSet, index) => {
          const exId = index === 0 ? ex._id : `breakdown_${ex._id}_${index}`;
          return Object.assign({}, ex, { training_sets: [exSet], _id: exId });
        });

        result = result.concat(list);
      } else {
        result.push(Object.assign({}, ex, { training_sets: [] }));
      }
    });
  });

  return _.map(result, ex => ({ supersets: [ex] }));
};

export const spacingToBottom = domRect => {
  const workoutBottomSpace = 85,
    windowheight = window.innerHeight;

  return windowheight - (domRect.top + domRect.height + workoutBottomSpace);
};

export const updateExerciseLibraryInWorkout = (sections, oldExerciseId, newExerciseLibrary, commonData) => {
  //commonData : { categories, fields, unit }
  if (!commonData) {
    console.error(' commonData: { categories, fields, unit } are required');
    return sections;
  }

  const { categories, fields, unit } = commonData;

  if (!categories || !fields || !unit) {
    console.error('categories, fields, unit are required');
    return sections;
  }

  const newSections = sections.slice();

  _.forEach(newSections, section => {
    _.forEach(section.exercises, set => {
      _.forEach(set.supersets, exerciseSet => {
        let { exercise_instance, exercise } = exerciseSet;
        const exerciseLibraryId = _.get(exercise, '_id', '') || _.get(exercise_instance, 'exercise', '');

        if (exerciseLibraryId && exerciseLibraryId === oldExerciseId) {
          const intervalSection = section.format === SECTION_FORMAT_KEY.INTERVAL;
          exercise_instance.fields = intervalSection
            ? initIntervalExerciseFields(newExerciseLibrary, categories, fields)
            : newExerciseLibrary.fields;
          const oldSets = exerciseSet.training_sets,
            newFields = exercise_instance.fields;
          const initSetParams = { oldSets, newFields, systemFields: fields, units: unit };

          exerciseSet.training_sets = initSetsData(initSetParams, intervalSection);
          exercise_instance.title = newExerciseLibrary.title;
          exercise_instance.exercise = newExerciseLibrary._id;
          exerciseSet.exercise = newExerciseLibrary;
        }
      });
    });
  });

  return newSections;
};

export const prepareDataToTrackWorkout = (workout, systemFields) => {
  const newWorkout = _.cloneDeep(workout);

  _.forEach(newWorkout.sections, section => {
    _.forEach(section.exercises, supersets => {
      _.forEach(supersets.supersets, exercise => {
        const exerciseFields = exercise.exercise_instance.fields;
        const fieldUniqueCodes = _.map(exerciseFields, fieldId => {
          return (_.find(systemFields, fieldObj => fieldObj._id === fieldId) || {}).unique_code;
        });

        const verifiedTrainingSets = [];
        const ormValue = Number(_.get(exercise.one_rep_max, 'value')) || 0;

        _.forEach(exercise.training_sets, setValue => {
          const set = _.pickBy(
            setValue,
            (fieldValue, fieldCode) => !_.isObject(fieldValue) || fieldUniqueCodes.includes(fieldCode),
          );

          if (set.orm) {
            const percent = Number(set.orm.value) || 0;
            const weightValue = (percent * ormValue) / 100;

            if (set.weight) {
              set.weight.value = weightValue;
            } else {
              set.weight = { value: weightValue };
            }
          }

          verifiedTrainingSets.push(set);
        });

        exercise.training_sets = verifiedTrainingSets;
      });
    });
  });

  return newWorkout;
};

/**
 * handleGetExerciseType: Handle Get Exercise Type
 * @param {*} attachments: attachments file
 * TRAINING_SECTION_ATTACHMENT_TYPE_VIDEO: 1
 * TRAINING_SECTION_ATTACHMENT_TYPE_AUDIO: 2
 * TRAINING_SECTION_ATTACHMENT_TYPE_IMAGE: 3
 * TRAINING_SECTION_ATTACHMENT_TYPE_DOCUMENT: 4
 * TRAINING_SECTION_ATTACHMENT_TYPE_LINK: 5
 * @returns type file
 */
export const handleGetExerciseType = attachments => {
  if (attachments) {
    let type = null;
    _.map(attachments, item => {
      switch (item.type) {
        case 1:
          type = <VideoTypeIcon />;
          break;
        case 2:
          type = <AudioTypeIcon />;
          break;
        case 3:
          type = <PhotoTypeIcon />;
          break;
        case 4:
          type = <DocumentTypeIcon />;
          break;
        case 5:
          const link =
            item.link.includes('https://') || item.link.includes('http://') ? item.link : `https://${item.link}`;

          if (validateVimeoUrl(link) || validateYouTubeUrl(link)) {
            type = <VideoTypeIcon />;
          } else {
            type = <LinkTypeIcon width={13} />;
          }
          break;
        default:
          break;
      }
    });

    return type;
  }
};

export const convertWorkoutUnits = (workout, unitsObj) => {
  const units = _.omit(unitsObj, ['createdAt', 'profile', 'updatedAt', '__v', '_id']);
  const clonedWorkout = _.cloneDeep(workout);
  clonedWorkout.sections = convertSections(clonedWorkout.sections, units);
  clonedWorkout.c_sections = convertSections(clonedWorkout.c_sections, units);

  return clonedWorkout;
};

const convertSections = (sections, units) => {
  return _.forEach(sections, section => {
    _.forEach(section.exercises, exercise => {
      _.forEach(exercise.supersets, superset => {
        if (
          _.has(superset, 'one_rep_max') &&
          _.has(superset, 'one_rep_max.value') &&
          _.has(superset, 'one_rep_max.set_unit')
        ) {
          const convertedOneRepMaxValue = convertUnit(
            superset.one_rep_max.value,
            superset.one_rep_max.set_unit,
            units.weight,
          );
          superset.one_rep_max.value = convertedOneRepMaxValue;
          superset.one_rep_max.set_unit = units.weight;
        }
        _.forEach(superset.training_sets, training_set => {
          _.forEach(units, (unit, unitKey) => {
            if (_.has(training_set, unitKey) && training_set[unitKey].set_unit) {
              const convertedValue = convertUnit(training_set[unitKey].value, training_set[unitKey].set_unit, unit);
              training_set[unitKey].value = convertedValue;
              training_set[unitKey].set_unit = unit;
            }
          });
        });
        if (_.has(superset, 'exercise.category')) {
          superset.exercise.category = _.omit(superset.exercise.category, ['id']);
        }
      });
    });
  });
};

export const checkValidationTrainingSet = section => {
  const exercise = _.get(section, 'exercises', []);
  let isDisable = false;

  _.forEach(exercise, item => {
    if (item.supersets) {
      _.forEach(item.supersets, ele => {
        if (ele.training_sets) {
          _.forEach(ele.training_sets, set => {
            // When is_completed of set is true and time_complete of section is not null
            // OR all set is not checked
            if (set.is_completed && section.time_complete > 0) isDisable = true;
            // if (
            //   (set.is_completed && section.time_complete > 0) ||
            //   _.every(ele, item => !_.get(item, 'training_sets.is_completed', false))
            // )
            // isDisable = true;
          });
        }
      });
    }
  });
  return isDisable;
};

export const checkRoundCompleted = (section, currentRound) => {
  const exerciseList = _.get(section, 'exercises', []);
  let isRoundCompleted = [];
  let roundCompletedValue = null;
  let isValidField = [];

  // Default
  roundCompletedValue = {
    round: currentRound ? currentRound + 1 : 1,
    roundCompleted: isRoundCompleted.includes(false) ? false : true,
    isValidField: true,
  };

  _.forEach(exerciseList, item => {
    if (item.supersets) {
      _.forEach(_.get(item, 'supersets', []), ele => {
        if (ele.training_sets) {
          _.forEach(_.get(ele, 'training_sets', []), (sets, index) => {
            if (index === currentRound && sets.is_completed) {
              const keys = _.filter(
                _.keys(sets) || [],
                item =>
                  ![
                    'is_completed',
                    'id',
                    '_id',
                    'rest',
                    'duration',
                    'set_type',
                    'weight',
                    'targetTrainingSet',
                    'each_side_mode',
                  ].includes(item),
              );
              _.forEach(keys, k => {
                const value = /^\d+$/.test(sets[k].value);

                isRoundCompleted.push(value && sets.is_completed);
                isValidField.push(value);
              });

              roundCompletedValue = {
                ...roundCompletedValue,
                roundCompleted: isRoundCompleted.includes(false) ? false : true,
                isValidField: _.every(isValidField, item => item),
              };
            }
          });
        }
      });
    }
  });

  return roundCompletedValue;
};
