import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

import * as S from './style';

const COMMA_KEYCODE = 188;
const COMMA_KEY = ',';
const DOT_KEYCODE = 190;
const DOT_KEY = '.';
const ARRAY_KEYCODE = [COMMA_KEYCODE, COMMA_KEY, DOT_KEYCODE, DOT_KEY];

const SliderRangeInput = props => {
  const {
    open,
    clear,
    min = 0,
    max = 100,
    step = 1,
    onWarning,
    onInputRangeChange,
    currentInputRange = { from: 0, to: 100 },
    appliedFilter = { from: 0, to: 100 },
  } = props;
  const [inputFrom, setInputFrom] = useState(min);
  const [inputTo, setInputTo] = useState(max);

  useEffect(() => {
    setInputFrom(get(currentInputRange, 'from', min));
    setInputTo(get(currentInputRange, 'to', max));
  }, [open, clear, min, max]);

  useEffect(() => {
    const slider = document.querySelector('.range-selected');
    const dragFrom = document.querySelector('.range-input__input-from');
    const dragTo = document.querySelector('.range-input__input-to');

    if (slider) {
      if (dragFrom && dragTo) {
        if (inputFrom === max) {
          dragFrom.style.zIndex = 1;
          dragTo.style.zIndex = 0;
        }

        if (inputTo === min) {
          dragTo.style.zIndex = 1;
          dragFrom.style.zIndex = 0;
        }
      }

      if (inputFrom > inputTo) {
        slider.style.right = `${100 - ((inputFrom - min) / (max - min)) * 100}%`;
        slider.style.left = `${((inputTo - min) / (max - min)) * 100}%`;
      } else {
        slider.style.right = `${100 - ((inputTo - min) / (max - min)) * 100}%`;
        slider.style.left = `${((inputFrom - min) / (max - min)) * 100}%`;
      }

      if (min === max || isNaN(inputFrom) || isNaN(inputTo)) {
        slider.style.right = '100%';
        slider.style.left = '100%';
      }
    }
  }, [inputFrom, inputTo, min, max]);

  const handleInputChange = (e, isFromInput, isBlur = false, isDrag = false) => {
    const value = isBlur
      ? isFromInput
        ? get(currentInputRange, 'from', min)
        : get(currentInputRange, 'to', max)
      : parseFloat(get(e, 'target.value', 0));

    if (value < min || value > max) return;

    if (isFromInput) {
      const invalidInput = value > inputTo;
      if (invalidInput && isDrag) return;
      const isEmptyInput = isNaN(value) || isNaN(inputTo);
      const hasBlurValue = isBlur && invalidInput && !isEmptyInput;
      const newInputFrom = hasBlurValue ? inputFrom : value;
      const inputBlur = isBlur
        ? isNaN(newInputFrom) || isNaN(inputFrom) || inputFrom > inputTo
          ? appliedFilter.from
          : newInputFrom
        : value;

      onWarning(inputBlur > inputTo || isNaN(inputBlur));
      setInputFrom(inputBlur);
      onInputRangeChange(inputBlur, inputTo);
    } else {
      const invalidInput = value < inputFrom;
      if (invalidInput && isDrag) return;
      const isEmptyInput = isNaN(value) || isNaN(inputFrom);
      const hasBlurValue = isBlur && invalidInput && !isEmptyInput;
      const newInputTo = hasBlurValue ? inputTo : value;
      const inputBlur = isBlur ? (isNaN(newInputTo) || value > inputTo ? appliedFilter.to : newInputTo) : value;

      onWarning(inputBlur < inputFrom || isNaN(inputBlur));
      setInputTo(inputBlur);
      onInputRangeChange(inputFrom, inputBlur);
    }
  };

  const handleKeyDown = e => {
    if (ARRAY_KEYCODE.includes(e.keyCode) || ARRAY_KEYCODE.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <S.Container>
      <S.SliderContainer>
        <S.RangeSlider className="range-slider">
          <S.RangeSelected className="range-selected" />
        </S.RangeSlider>
        <S.RangeInput className="range-input">
          <input
            className="range-input__input-from"
            type="range"
            onChange={e => handleInputChange(e, true, false, true)}
            min={min}
            max={max}
            step={step}
            defaultValue={get(currentInputRange, 'from', min)}
            value={inputFrom ? inputFrom : 0}
            placeholder={`${min}`}
            onBlur={e => handleInputChange(e, true, true)}
          />
          <input
            type="range"
            className="range-input__input-to"
            onChange={e => handleInputChange(e, false, false, true)}
            min={min}
            max={max}
            step={step}
            defaultValue={get(currentInputRange, 'to', max)}
            value={inputTo ? inputTo : 0}
            placeholder={`${max}`}
            onBlur={e => handleInputChange(e, false, true)}
          />
        </S.RangeInput>
      </S.SliderContainer>
      <S.NumberInputWrapper>
        <S.NumberInput
          type="number"
          value={inputFrom}
          onChange={e => handleInputChange(e, true)}
          min={min}
          max={max}
          onBlur={e => handleInputChange(e, true, true)}
          onKeyDown={handleKeyDown}
        />
        <S.RangeLine />
        <S.NumberInput
          type="number"
          value={inputTo}
          onChange={e => handleInputChange(e, false)}
          min={min}
          max={max}
          onBlur={e => handleInputChange(e, false, true)}
          onKeyDown={handleKeyDown}
        />
      </S.NumberInputWrapper>
    </S.Container>
  );
};

export default SliderRangeInput;
