import styled, { css } from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

const limitText = `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const baseText = `
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #202353;
`;

export const SettingName = styled.div`
  ${baseText}
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
  padding-right: 10px;
  padding-left: 28px;
  background: transparent url(${CDN_URL}/images/icon-lock.svg) no-repeat;
  background-position: left center;
  background-size: 18px auto;
`;

export const Description = styled.div`
  ${baseText}
  line-height: 18px;
  font-size: 13px;
  color: #000000;
`;

export const Module = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
`;

export const Content = styled.div`
  max-width: 650px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ContentTable = styled.div`
  max-width: calc(29% + 650px);
`;

export const WrapperMain = styled.div`
  display: grid;
  padding: 25px 0;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 55% 1fr;
  margin-bottom: 25px;
  ${Module} {
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const WrapperTable = styled.div`
  display: grid;
  grid-template-columns: 77.5% 1fr;
  ${Module} {
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const WrapperGrantAccess = styled.div`
  padding: 40px 30px;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleMain = styled.h4`
  ${baseText}
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #222222;
  margin-bottom: 0;
`;

export const TableWrap = styled.div`
  margin-top: 24px;
  margin-bottom: 18px;
`;

export const TableHeaderWrap = styled.div`
  display: grid;
  grid-template-columns: 37% 29% 32%;
  gap: 1%;
  padding: 8px 0;
  border-bottom: 1px solid #f0f2f7;
`;

export const HeaderItem = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #a1a1a5;
  ${props =>
    props.textCenter &&
    css`
      text-align: center;
    `}
`;

export const TableContentWrap = styled.div``;

export const TableItemWrap = styled.div`
  display: grid;
  grid-template-columns: 37% 29% 32%;
  gap: 1%;
  border-bottom: 1px solid #f0f2f7;
  padding: 8px 0;
  &:last-child {
    border-bottom: none;
  }
`;
export const TableItemContent = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    &:hover path {
      stroke: #5c5bbd;
    }
  }
  ${props =>
    props.textCenter &&
    css`
      justify-content: center;
    `}
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h4`
  ${baseText}
  ${limitText}
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 4px;
  word-break: break-word;
`;

export const Avatar = styled.div`
  flex-shrink: 0;
`;

export const ContentUserInfo = styled.div`
  margin-left: 10px;
`;

export const Desc = styled.p`
  ${baseText}
  ${limitText}
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
`;

export const Text = styled.p`
  ${baseText}
  font-size: 13px;
  line-height: 27px;
  color: #323c47;
`;

export const ConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;

    &.custom-popup {
      .confirm-header-image {
        width: 25px;
        height: 25px;
      }
      .confirm-header-label {
        color: #000000;
        line-height: 25px;
        font-style: normal;
      }
      .confirm-content-header {
        padding: 30px 30px 0;
      }
      .confirm-content-body {
        padding: 15px 30px 30px;
      }
      .confirm-content-label {
        color: #4a4d56;
      }
      .confirm-no-button {
        min-width: 102px;
        border-radius: 5px;
      }
      .confirm-yes-button {
        min-width: 111px;
        border-radius: 5px;
        box-shadow: none;
      }
    }
  }
`;
