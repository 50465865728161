import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import get from 'lodash/get';

import UpgradePathAutoflowImg from 'assets/images/upgrade_path_autoflow.png';
import UpgradePathScheduledPost from 'assets/images/upgrade_path_scheduled_post.png';
import { CDN_URL, PLANS } from 'constants/commonData';
import * as S from '../style';

export default function AutoflowFallback(props) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 50);
    // reset index state
    window.history.replaceState({}, '', '/home/autoflow');
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots',
    initialSlide: get(props, 'startIndex', 0),
    customPaging: i => <S.Dots key={`dots-${i}`} className={'dots-item'} />,
    lazyLoad: true,
    useTransform: false,
  };

  const data = [
    {
      id: 'master_group_training',
      imageSrc: UpgradePathAutoflowImg,
      title: 'Master Group Training',
      description: `Automate, scale, motivate, and manage your group training programs with ease, because more clients shouldn’t
      mean more work.`,
      imageOrder: 'first',
    },
    {
      id: 'schedule_forum_post',
      imageSrc: UpgradePathScheduledPost,
      title: 'Schedule Forum Posts',
      description:
        'Plan out your posts for the month and schedule ahead. Keep your clients engaged, while giving you ultimate flexibility.',
      learnMore: `<img src=${CDN_URL}/images/learn_more_icon.svg /> Learn how to Schedule Forum Posts`,
      learnMoreURL: 'https://help.everfit.io/en/articles/7974438-scheduling-posts-in-community-forums',
      imageOrder: 'second',
    },
  ];

  const planWillSelect = { tier: PLANS.pro.key };

  if (!loaded) {
    return <></>;
  }
  return (
    <S.AutoflowFallbackWrapper>
      <S.Container>
        <S.SectionAutoflow>
          <S.Slider>
            <Slider {...settings}>
              {data.map((obj, idx) => (
                <div key={`${obj.id}_${idx}`}>
                  <S.BodyAutoflow>
                    <S.UpgradePathBackground className={obj.imageOrder}>
                      <img src={obj.imageSrc} alt="" />
                    </S.UpgradePathBackground>
                    <S.TitleAutoflow className="shift-up">{obj.title}</S.TitleAutoflow>
                    <S.DescriptionAutoflow className="shift-up">{obj.description}</S.DescriptionAutoflow>
                    {obj.learnMore && obj.learnMoreURL && (
                      <S.LearnMore
                        dangerouslySetInnerHTML={{ __html: obj.learnMore }}
                        className="shift-up"
                        href={obj.learnMoreURL}
                        target="_blank"
                      ></S.LearnMore>
                    )}
                    <S.UpgradePathAutoflowButton
                      className="upgrade-path-button"
                      text="Add Automation"
                      planWillSelect={planWillSelect}
                    />
                  </S.BodyAutoflow>
                </div>
              ))}
            </Slider>
          </S.Slider>
        </S.SectionAutoflow>
      </S.Container>
    </S.AutoflowFallbackWrapper>
  );
}
