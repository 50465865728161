import styled from 'styled-components';

export const SwitchPurchaseOptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Open Sans';
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  margin-left: 6px;
  .toggle-input {
    input {
      height: 100% !important;
    }
    label {
      padding-left: 41px !important;
      span {
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 400;
        color: #202353;
      }
    }
  }
`;

export const PurchasePreviewValue = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #4a4d74;
  white-space: pre-wrap;
`;

export const SurveyNamePreview = styled.p`
  margin-top: 22px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: #202353;
`;

export const SurveyNameEdit = styled.button`
  cursor: pointer;
  margin-top: 10px;
  min-width: 136px;
  width: fit-content;
  min-height: 34px;
  padding: 6px 8px;
  gap: 4px;
  border-radius: 5px;
  border: 1px solid #5158cf;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  span {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #5158cf;
    flex: 1;
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

export const SurveyViewModeItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 32px 8px 12px;
  border: 1px solid rgba(235, 237, 244, 1);
  height: 52px;
  border-radius: 4px;

  .survey__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    h6 {
      max-width: 318px;
      font-size: 13px;
      font-weight: 600;
      color: rgba(32, 35, 83, 1);
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      margin: 0;
    }
    .survey__info-questions {
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      color: rgba(151, 151, 151, 1);
    }
  }
  .survey__action {
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    color: rgba(81, 88, 207, 1);
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SurveyEditModeActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .manage-action {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    height: 34px;
    color: rgba(81, 88, 207, 1);
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const ErrorMessage = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: rgba(234, 49, 74, 1);
  margin-top: 2px;
`;

export const SurveyInformationOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #c4c4c4;
  }
  .author-name {
    font-size: 11px;
    line-height: 15px;
    color: #aaacae;
    font-weight: 600 !important;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    box-orient: vertical;
    overflow: hidden;
    flex: 1;
    white-space: pre-wrap;
  }
`;

export const CustomLoadingMessage = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #999999;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
`;
