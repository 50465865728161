// Lib
import React, { useState, useEffect } from 'react';
import get from 'lodash/get';

// Shared
import ModalSingleField from 'shared/ModalSingleField';
import ShareSetting from './ShareSetting';

// Constants
import { TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER, MAX_FORM_NAME_LENGTH } from 'constants/commonData';

// Assets
import { AddNewFormModal } from './style';

const FormTemplatesPopup = ({ onSubmit, onClose, user, data }) => {
  const [value, setValue] = useState('');
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ownerValue, setOwnerValue] = useState({ owner: get(user, '_id') });
  const [shareValue, setShareValue] = useState({ share: TEAM_SHARE_PRIVATE });

  const { title, _id } = data;

  useEffect(() => {
    if (title) {
      setValue(`${title.trim()} - Copy`);
    }
  }, [title]);

  const handleChangeValue = e => {
    const value = e.target && e.target.value;

    if (!MAX_FORM_NAME_LENGTH) setValue(value);
    if (value.length <= MAX_FORM_NAME_LENGTH) {
      setValue(value);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!value.trim()) {
      return;
    }

    const params = {
      name: value.trim(),
      owner: shareValue.share === TEAM_SHARE_NOOWNER ? null : ownerValue.owner,
      ...shareValue,
      duplicated_system_form: _id,
    };

    if (onSubmit) {
      setIsDisabledSubmit(true);
      onSubmit && onSubmit(params, handleClose);
      setIsDisabledSubmit(false);
    }
  };

  const handleClose = () => {
    setIsDisabledSubmit(false);
    onClose && onClose();
  };

  return (
    <ModalSingleField
      value={value}
      open={true}
      titlePopup="Duplicate Form"
      titleInput="Form name"
      placeholderInput="Enter Form Name"
      submitName="Save"
      maxLength={MAX_FORM_NAME_LENGTH}
      inputHeight={40}
      isSubmitted={isSubmitted}
      isDisabledSubmit={isDisabledSubmit}
      isShowCancelButton={true}
      isHideCloseButton={true}
      onClose={handleClose}
      onChangeValue={handleChangeValue}
      onSubmit={handleSubmit}
      elementModal={AddNewFormModal}
      childrenForm={
        <ShareSetting
          userId={get(user, '_id', '')}
          ownerValue={ownerValue}
          shareValue={shareValue}
          setOwnerValue={setOwnerValue}
          setShareValue={setShareValue}
          hasAvatar
          hasSpacing
        />
      }
    />
  );
};

export default FormTemplatesPopup;
