import React from 'react';
import { get } from 'lodash';
import {
  ProductListNavArea,
  ProductListUpgradePathText,
  ProductListUpgradePathLink,
  Label,
  Title,
  ButtonArea,
  Link,
  LinkText,
} from './style';
import UpgradePathIcon from 'shared/UpgradePathIcon';
import { ReactComponent as LearMoreIcon } from 'assets/icons/learn_more_icon.svg';

const ProductListNavTitle = props => {
  const { checkPermission, isHideBanner } = props;

  const textComponent = () => {
    return (
      <ProductListUpgradePathLink
        text={
          <>
            <UpgradePathIcon />
            <Label>Upgrade to get Sequences</Label>
          </>
        }
      />
    );
  };

  return (
    <ProductListNavArea>
      <Title>
        <span>Sequences</span>
        {/* {!permission.payment && <ProductListUpgradePathText>{textComponent()}</ProductListUpgradePathText>} */}
      </Title>
      {checkPermission && isHideBanner && (
        <ButtonArea>
          <Link
            href="https://help.everfit.io/en/articles/6047264-introducing-products-an-automation-for-payment-packages"
            target="_blank"
          >
            <LearMoreIcon />
            <LinkText>Set up automated Sequences</LinkText>
          </Link>

          <Link href="https://help.everfit.io/en/articles/6062108-how-to-add-a-product-to-a-package" target="_blank">
            <LearMoreIcon />
            <LinkText>How to add a Sequence to a Package</LinkText>
          </Link>
        </ButtonArea>
      )}
    </ProductListNavArea>
  );
};

export default ProductListNavTitle;
