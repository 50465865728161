import React from 'react';
import { Image } from 'semantic-ui-react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { FormGroup, SignUpContainer } from './style';
import { validateEmail } from 'utils/validations';
import { axiosInstanceWithoutToken } from 'configs/request';
import { CDN_URL } from 'constants/commonData';

const FIELDS = [
  { key: 'first_name', label: 'First name' },
  { key: 'last_name', label: 'Last name' },
  { key: 'email', label: 'Email', type: 'email' },
];

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    const { client } = props;
    this.state = {
      first_name: client.first_name || '',
      last_name: client.last_name || '',
      email: client.email || '',
      password: '',
      showPassword: false,
      emailExisted: false,
      isSubmitting: false,
      isValidatingEmail: false,
    };
  }

  validateField = (field, value) => {
    const { emailExisted } = this.state;
    const trimmedValue = value.trim();

    if (this.showError && !trimmedValue) {
      return <div className="error">This field is required</div>;
    }

    if (field === 'email' && trimmedValue) {
      if (!validateEmail(trimmedValue)) {
        return <div className="error">Please check formatting of email</div>;
      }

      if (emailExisted) {
        return <div className="error">This email is already in use. Please use another email.</div>;
      }
    }

    if (this.showError && field === 'password' && trimmedValue.length < 8) {
      return <div className="error">The password must have at least 8 characters</div>;
    }

    return null;
  };

  checkEmailExist = async () => {
    const { email } = this.state;
    const { inviteCode } = this.props;
    const emailTrim = email.trim().toLowerCase();
    const newState = { emailExisted: false, isValidatingEmail: false };

    if (emailTrim && validateEmail(emailTrim)) {
      this.setState({ isValidatingEmail: true });

      try {
        const response = await axiosInstanceWithoutToken.post('/api/v2/authentication/check-exist-email', {
          email: emailTrim,
          code: inviteCode,
        });
        const { data } = response.data;
        newState.emailExisted = data.exist;
      } catch (err) {}

      this.setState(newState);
    }

    return newState.emailExisted;
  };

  handleSubmit = event => {
    event.preventDefault();
    const { onSuccess, inviteCode } = this.props;
    this.showError = true;
    const { isValidatingEmail, isSubmitting } = this.state;

    if (isValidatingEmail || isSubmitting) {
      return false;
    }

    let error = null;
    const { email, first_name, last_name, password } = this.state;
    const params = {
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      password: password.trim(),
      email: email.trim().toLowerCase(),
    };

    _.forEach(params, (value, key) => {
      const err = this.validateField(key, value);

      if (err) {
        error = err;
        return false;
      }
    });

    if (error) {
      return this.setState({});
    }
    this.checkEmailExist().then(existed => {
      if (!existed) {
        this.setState({ isSubmitting: true });
        params.invite_code = inviteCode;
        params.is_trainer = false;
        this.props
          .onSubmit(params, true)
          .then(response => {
            const { user, token } = response.data;
            this.setState({ isSubmitting: false });
            typeof onSuccess === 'function' && onSuccess(token, user);
          })
          .catch(error => {
            let messaging = '';

            if (error.response) {
              messaging = error.response.data.message;
            } else {
              messaging = 'Can not sign up, please contact admin';
            }

            toast(<div style={{ marginRight: 30 }}>{messaging}</div>);
            this.setState({ isSubmitting: false });
          });
      }
    });
  };

  onInputChange = (fieldName, event) => {
    const { value } = event.target;

    if (fieldName === 'email') {
      this.setState({ [fieldName]: value, emailExisted: false });
    } else {
      this.setState({ [fieldName]: value });
    }
  };

  render() {
    const { trainer, mainColor, autoFocus, team } = this.props;
    const { password, showPassword, isValidatingEmail, isSubmitting } = this.state;
    const isEnableWhiteLabel = _.get(team, 'white_label', false);

    return (
      <SignUpContainer onSubmit={this.handleSubmit} className="sign-up" mainColor={mainColor}>
        <h1>Create your account to train with {trainer.full_name}</h1>
        {_.map(FIELDS, item => (
          <FormGroup key={item.key}>
            <label>{item.label}</label>
            <input
              value={this.state[item.key]}
              onChange={event => this.onInputChange(item.key, event)}
              placeholder={item.label}
              autoComplete="new-password"
              type={item.type || 'text'}
              disabled={!!isValidatingEmail || !!isSubmitting}
              autoFocus={autoFocus === item.key}
            />
            {this.validateField(item.key, this.state[item.key])}
          </FormGroup>
        ))}
        <FormGroup className="">
          <label>Password</label>
          <div className="password">
            <input
              value={password}
              type={showPassword ? 'text' : 'password'}
              onChange={event => this.onInputChange('password', event)}
              placeholder="8+ characters"
              autoComplete="new-password"
              autoFocus={autoFocus === 'password'}
              name="password"
              disabled={!!isValidatingEmail || !!isSubmitting}
            />
            <Image
              className="eye"
              src={`${CDN_URL}/images/eye_${showPassword ? 'purple' : 'grey'}.svg`}
              width={18}
              onClick={() => this.setState({ showPassword: !showPassword })}
            />
          </div>
          {this.validateField('password', password)}
        </FormGroup>
        <div className="form__footer">
          <button className="form__footer--btn" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Signing' : 'Sign'} up
          </button>
          {!isEnableWhiteLabel && (
            <div className="form__footer--link">
              <div>
                By signing up, I agree to Everfit&nbsp;
                <a target="_blank" href="https://everfit.io/privacy/">
                  Privacy Policy
                </a>
              </div>
              &nbsp;and&nbsp;
              <a target="_blank" href="https://everfit.io/mobile-tos">
                Terms of Service
              </a>
            </div>
          )}
        </div>
      </SignUpContainer>
    );
  }
}

export default SignUpForm;
