import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { connect } from 'react-redux';

import { toggleModal } from 'actions/modal';
import PackagesPopup from './PackagesPopup';
import PaymentCounter from './PaymentCounter';
import { CURRENCIES, ACTIVE_PACKAGE_STATUS_COUNT } from 'constants/commonData';
import { getPurchasesOfClient } from 'redux/package-analytics/actions';

const PaymentOverviewContainer = ({ toggleModal, client, packageAnalytics, getPurchasesOfClient }) => {
  const clientId = get(client, 'workingClientDetail._id' || '');

  useEffect(() => {
    getPurchasesOfClient(
      {
        client_id: clientId,
      },
      ['everfit', 'marketplace'],
    );
  }, [clientId]);

  const handleClosePopup = () => {
    toggleModal(false);
  };

  const purchases = useMemo(() => {
    const results = get(packageAnalytics.toJS(), 'clientPurchases.purchases', []);
    return results;
  }, [packageAnalytics]);

  const counter = useMemo(() => {
    const activePurchases = get(packageAnalytics.toJS(), 'clientPurchases.purchases', []).filter(it =>
      ACTIVE_PACKAGE_STATUS_COUNT.includes(it.status),
    );
    const ids = activePurchases.map(purchase => purchase._package.id);
    return uniq(ids).length;
  }, [packageAnalytics]);

  const allPackages = useMemo(() => {
    const activePurchases = get(packageAnalytics.toJS(), 'clientPurchases.purchases', []);
    return activePurchases.length;
  }, [packageAnalytics]);

  const currency = useMemo(() => {
    const results = get(packageAnalytics.toJS(), 'clientPurchases.purchases', []);
    const firstPurchase = results.find(it => it.currency);
    const obj = CURRENCIES[get(firstPurchase, 'currency')] || {};
    return obj.symbol;
  }, [packageAnalytics]);

  const handlePackagesPopup = () => {
    const currentClient = get(client, 'workingClientDetail');
    toggleModal(
      true,
      <PackagesPopup onClose={handleClosePopup} purchases={purchases} currency={currency} client={currentClient} />,
    );
  };

  if (!allPackages) {
    return null;
  }

  return (
    <PaymentCounter currency={currency} counter={counter} onOpenPopup={handlePackagesPopup} allPackages={allPackages} />
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { client, packageAnalytics },
  } = state;
  return {
    client,
    packageAnalytics,
  };
};

const actionCreators = { toggleModal, getPurchasesOfClient };

export default connect(mapStateToProps, actionCreators)(PaymentOverviewContainer);
