import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 200px;
  height: 100px;
  position: relative;
  background: #F9F9FF;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #5C5BBD;
  cursor: pointer;
  text-align: center;

  .image-container {
    min-height: 34px;
    margin-bottom: 10px;

    img {
      margin: auto;
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none !important;
    box-shadow: none !important;
    text-indent: -9999px;
    opacity: 0;
    border: none !important;
    cursor: pointer;
  }
`;