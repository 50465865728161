import { fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';

import { Types } from './actions';
import { moveLocalWorkouts, arrangeLocalWorkouts, handleMoveExercise } from '../helper';

const INITIAL_STATE = fromJS({
  workouts: {},
  copying: null,
  copyingWorkouts: false,
  selectedWorkouts: [],
  selectedWeek: null,
  startDateCopy: null,
  workoutsInWeeks: {},
  isLoadingWorkouts: false,
});

export const removeWorkoutById = (allWorkouts, removedIds) => {
  const newWorkouts = {};
  for (const weekDayIndex in allWorkouts) {
    if (Object.hasOwnProperty.call(allWorkouts, weekDayIndex)) {
      const workouts = allWorkouts[weekDayIndex];
      if (!isEmpty(workouts)) {
        newWorkouts[weekDayIndex] = filter(workouts, it => !removedIds.includes(get(it, '_id')));
      }
    }
  }
  return newWorkouts;
};

export const sortAddedWorkoutBySocket = (allWorkouts, workout, workoutsKey) => {
  const newWorkouts = {};

  if (isEmpty(allWorkouts)) {
    allWorkouts.workoutsKey = [];
  }

  for (const weekDayIndex in allWorkouts) {
    if (Object.hasOwnProperty.call(allWorkouts, weekDayIndex)) {
      if (weekDayIndex === workoutsKey) {
        newWorkouts[workoutsKey] = [...allWorkouts[weekDayIndex], workout];
      } else if (!Object.hasOwnProperty.call(newWorkouts, workoutsKey)) {
        newWorkouts[workoutsKey] = [workout];
      }
    }
  }
  return newWorkouts;
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.AUTOFLOW_INTERVAL_TRAINING_SUCCESS_GET_WORKOUTS: {
      return state.set('workouts', fromJS(payload.data)).set('isLoadingWorkouts', false);
    }

    case Types.AUTOFLOW_INTERVAL_FETCH_WORKOUT_BY_WEEK_REQUEST: {
      return state.set('isLoadingWorkouts', true);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_SINGLE_DAY:
    case Types.AUTOFLOW_INTERVAL_TRAINING_UPDATE_WORKOUTS_OF_MULTIPLE_DAYS:
      return state.mergeIn(['workouts'], payload.data);

    case Types.AUTOFLOW_INTERVAL_TRAINING_COPY_WORKOUT:
      return state.set('copying', fromJS(payload.data));

    case Types.AUTOFLOW_INTERVAL_TRAINING_COPY_MULTIPLE_WORKOUTS:
      return state.set('copyingWorkouts', true).set('copyId', fromJS(payload.copyId));

    case Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS:
      const newWorkouts = removeWorkoutById(state.get('workouts').toJS(), payload);
      return state.mergeIn(['workouts'], newWorkouts);

    case Types.AUTOFLOW_INTERVAL_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST:
      if (action.payload.multiPaste) return state;
      const currentWorkouts = state.get('selectedWorkouts').toJS();
      currentWorkouts.splice(0, currentWorkouts.length);
      return state.set('selectedWorkouts', fromJS(currentWorkouts)).set('copyingWorkouts', false);

    case Types.AUTOFLOW_INTERVAL_TRAINING_MOVE_WORKOUT_ON_LOCAL: {
      const workouts = moveLocalWorkouts(state.get('workouts').toJS(), payload.data);

      if (!workouts) {
        return state;
      }

      return state.mergeIn(['workouts'], workouts);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_ARRANGE_WORKOUT_ON_LOCAL: {
      const workouts = arrangeLocalWorkouts(state.get('workouts').toJS(), payload.data);

      if (!workouts) {
        return state;
      }

      return state.mergeIn(['workouts'], workouts);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_RESET_COPY_ITEM:
      return state.set('copying', null);

    case Types.AUTOFLOW_INTERVAL_TRAINING_MOVE_EXERCISE: {
      const workouts = handleMoveExercise(state.get('workouts').toJS(), payload.data);
      return state.mergeIn(['workouts'], workouts);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_SELECT_WEEK: {
      return state.set('selectedWeek', payload.weekIndex).set('weekCopyId', payload.weekCopyId);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_COPY_WEEK_REQUEST: {
      if (action.payload.multiPaste) return state;
      return state.set('selectedWeek', null);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_REMOVE_WEEK_SUCCESS:
      return state.mergeIn(['workouts'], removeWorkoutById(state.get('workouts').toJS(), payload));

    case Types.AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WEEK: {
      return state.set('selectedWeek', null).set('weekCopyId', null);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_RESET_SELECTED_WORKOUTS: {
      const currentWorkouts = state.get('selectedWorkouts').toJS();
      currentWorkouts.splice(0, currentWorkouts.length);
      return state.set('selectedWorkouts', fromJS(currentWorkouts)).set('copyingWorkouts', false);
    }

    case Types.AUTOFLOW_INTERVAL_TRAINING_SELECT_WORKOUT: {
      const currentWorkouts = state.get('selectedWorkouts').toJS();
      if (!currentWorkouts.includes(payload.data)) {
        currentWorkouts.push(payload.data);
      } else {
        currentWorkouts.splice(currentWorkouts.indexOf(payload.data), 1);
      }
      if (isEmpty(currentWorkouts)) {
        return state.set('selectedWorkouts', fromJS(currentWorkouts)).set('copyingWorkouts', false);
      }

      return state.set('selectedWorkouts', fromJS(currentWorkouts));
    }

    case Types.AUTOFLOW_INTERVAL_SOCKET_WORKOUT_ADDED:
      const { workout, weekDayIndex } = payload;
      return state.mergeIn(['workouts'], sortAddedWorkoutBySocket(state.get('workouts').toJS(), workout, weekDayIndex));

    case Types.AUTOFLOW_INTERVAL_TRAINING_RESET_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
