import React, { memo, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { uploadAttachmentsInBackground } from 'redux/attachments/actions';

const AttachmentsUploadInBackground = ({
  disabled = true,
  callbackProcess,
  callbackSuccess,
  callbackCancel,
  uploadConfigs,
  attachments,
  uploadAttachmentsInBackground,
  id,
}) => {
  useEffect(() => {
    let ignore = false;
    if (!disabled && !isEmpty(uploadConfigs) && !isEmpty(attachments) && !isEmpty(id)) {
      uploadAttachmentsInBackground({
        uploadConfigs,
        attachments,
        changeProgress: callbackProcess,
        id,
      }).then(responses => {
        typeof callbackSuccess === 'function' && callbackSuccess({ responses, ignore });
      });
    }
    return () => {
      ignore = true;
      typeof callbackCancel === 'function' && callbackCancel();
    };
  }, [disabled, uploadConfigs, attachments, id]);

  return <></>;
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  uploadAttachmentsInBackground: bindActionCreators(uploadAttachmentsInBackground, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(AttachmentsUploadInBackground));
