// Libs
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { getListCategoryIngredient } from 'redux/recipes/actions';

// Assets
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_bold.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/MealPlans/check_blue.svg';

// Styles
import * as S from './style';

/**
 * @typedef {Object} CategorySelectProps
 * @property {Array<Object>} [options] - options = [{ name = '', _id = '', ... }]
 * @property {Array<string|number>} [values] - values = ['636e18117693472e9789eed2']
 * @property {Function} [onChangeCategory]
 * @property {string} [label]
 * @property {string} [placeholder]
 * @property {string} [placeholderInputSearch]
 * @property {string} [textNoResult]
 * @property {boolean} [hasError]
 * @property {boolean} [isMulti]
 */

/**
 * @param {CategorySelectProps} props
 * @returns {JSX.Element}
 */

const CategorySelect = props => {
  const {
    options = [],
    values = [],
    onChangeCategory = () => {},
    label = 'Category',
    placeholder = 'e.g. Fruits, Meat...',
    placeholderInputSearch = 'Search category',
    textNoResult = 'No results found.',
    hasError = false,
    isMulti = false,
    loading = false,
    categoryIngredient = [],
    getListCategoryIngredient,
    showInputSearch = true,
    className,
    listAmountPer = false,
  } = props;

  const selectGroupRef = useRef();
  const listUnitRef = useRef();

  const [listCategory, setListCategory] = useState(options);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState(values);
  const [textSearch, setTextSearch] = useState('');

  const updateSelectGroupStyles = () => {
    const selectGroup = selectGroupRef.current;
    if (selectGroup) {
      const rect = selectGroup.getBoundingClientRect();
      const isDropdownVisible = window.innerHeight < rect.bottom;

      selectGroup.style.visibility = 'visible';

      if (isDropdownVisible) {
        selectGroup.style.top = 'unset';
        selectGroup.style.bottom = 'calc(100% + 8px)';
        selectGroup.style.paddingBottom = '0';
      }
    }
  };

  useEffect(() => {
    setSelected(values);
  }, [values]);

  useEffect(() => {
    if (isEmpty(categoryIngredient) && openDropdown) {
      typeof getListCategoryIngredient === 'function' && getListCategoryIngredient();
    }
  }, [openDropdown, categoryIngredient]);

  useEffect(() => {
    setListCategory(options);
  }, [options]);

  useEffect(() => {
    updateSelectGroupStyles();
    if (!openDropdown && textSearch) {
      handleClearSearch();
    }
  }, [openDropdown]);

  useEffect(() => {
    const itemTop = document.getElementById(`item-category-${values[0]}`);
    if (itemTop) {
      listUnitRef.current.scrollTop = itemTop.offsetTop;
    }
  }, [openDropdown]);

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
    if (isMulti) {
      setTimeout(() => {
        const element = selectGroupRef.current;
        if (element) {
          const rect = element.getBoundingClientRect();
          const { x, y } = rect;
          element.style.left = `${x}px`;
          element.style.top = `${y}px`;
          if (!listAmountPer) {
            element.style.width = `238px`;
          }
          element.style.position = 'fixed';
        }
      }, 0);
    }
  };

  const handleClosePopUp = () => {
    setOpenDropdown(false);
    setTextSearch('');
  };

  const handleOnClick = category => {
    const { _id = '' } = category || {};

    setSelected([_id]);
    setOpenDropdown(false);
    onChangeCategory(_id);
  };

  const handleSearchText = (_, { value = '' }) => {
    setTextSearch(value);
    const searchResult = options.filter(({ name = '' }) => name.toLowerCase().includes(value.trim().toLowerCase()));
    setListCategory(searchResult);
  };

  const handleClearSearch = () => {
    setTextSearch('');
    setListCategory(options);
  };

  const triggerLabel = useMemo(() => {
    return !isEmpty(selected) && ((options || []).find(({ _id = '' }) => selected.includes(_id)) || {}).name;
  }, [selected, options]);

  return (
    <S.Wrapper className={className}>
      {label && <S.Label>{label}</S.Label>}
      <S.TriggerWrapper
        onClick={handleOpenDropdown}
        openDropdown={openDropdown}
        hasError={hasError}
        className="trigger-wrapper"
      >
        {triggerLabel || <span className="text-placeholder">{placeholder}</span>}
        <ArrowDownIcon />
      </S.TriggerWrapper>
      {openDropdown && (
        <RootCloseWrapper onRootClose={handleClosePopUp}>
          <S.ListWrapper ref={selectGroupRef} listAmountPer={listAmountPer}>
            <S.ListContent>
              {showInputSearch && (
                <S.InputSearch
                  placeholder={placeholderInputSearch}
                  onChange={debounce(handleSearchText, 300)}
                  onClearSearch={handleClearSearch}
                />
              )}
              <S.List ref={listUnitRef}>
                {loading ? (
                  <S.Loading />
                ) : textSearch.trim() && get(listCategory, 'length', 0) <= 0 ? (
                  <span className="no-result">{textNoResult}</span>
                ) : (
                  <>
                    {listCategory.map(item => {
                      const { name = '', _id = '' } = item;
                      const active = !isEmpty(values) && values.includes(_id);
                      return (
                        <S.Item
                          active={active}
                          onClick={() => handleOnClick(item)}
                          key={_id}
                          id={`item-category-${_id}`}
                        >
                          {name}
                          {active && <CheckIcon />}
                        </S.Item>
                      );
                    })}
                  </>
                )}
              </S.List>
            </S.ListContent>
          </S.ListWrapper>
        </RootCloseWrapper>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes },
  } = state;

  return { categoryIngredient: get(recipes, 'ingredient.category', []) };
};

const mapDispatchToProps = dispatch => ({
  getListCategoryIngredient: bindActionCreators(getListCategoryIngredient, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelect);
