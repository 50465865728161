import styled from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
