import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const TimeInfoWrapper = styled.div`
  margin-top: 10px;
`;

export const Time = styled.div`
  padding-left: 9px;
  width: 100%;
  display: flex;
  gap: 13px;
`;

export const TimeItem = styled.div`
  flex: 1;
  position: relative;
  &:last-child {
    flex: unset;
    width: 111px;
  }

  &.error {
    .time-input {
      border: 1px solid #ea314a;
    }
  }
  .error-title {
    ${baseText}
    font-size: 12px;
    line-height: 18px;
    color: #ea314a;
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    width: max-content;
  }

  &.number-of-serving {
    &.error {
      .number-input {
        border: 1px solid #ea314a;
      }
    }
  }
`;

export const TimeDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  padding-left: 10px;
`;

export const TimeDetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TimeTitle = styled.div`
  ${baseText}
`;

export const TimeValue = styled.div`
  ${baseText}
  font-weight: 600;
`;
