import _ from 'lodash';
import { Types } from 'actions/sectionLibrary';

const INITIAL_STATE = {
  list: [],
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  let list = state.list.slice();
  switch (action.type) {
    case Types.LOAD_SECTION_LIBRARY_SUCCESS:
      const listSection = [...list];
      list = action.payload.list.slice();
      list.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      return Object.assign({}, state, { list: [...listSection, ...list] });

    case Types.LOAD_SECTION_LIBRARY_FAILED:
    case Types.ADD_SECTION_TO_LIBRARY_FAILED:
      return Object.assign({}, state, { error: action.payload.error });

    case Types.ADD_SECTION_TO_LIBRARY_SUCCESS:
      list.unshift(action.payload.section);
      return Object.assign({}, state, { list });

    case Types.SELECT_SECTION_SUCCESS:
      const { section } = action.payload;
      _.forEach(list, item => {
        if (item._id === section._id) {
          item.updatedAt = section.updatedAt;
        }
      });
      list.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      return Object.assign({}, state, { list });

    case Types.SUCCESS_DELETE_SECTION:
      return Object.assign({}, state, { list: _.filter(list, item => item._id !== action.payload.sectionId) });

    case Types.SELECT_SECTION_RESET:
      return Object.assign({}, state, { list: [], error: null });

    default:
      return state;
  }
};
