import styled from 'styled-components';

export const Item = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #222222;
  display: flex;
  padding: 10px 12px;
  cursor: pointer;

  &.seleted,
  :hover {
    color: #5c54c3;
    background-color: #ececfa;
  }

  .type-label {
    width: 15px;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    margin-right: 5px;
    color: #ffa22d;
  }
`;

export const Trigger = styled.div`
  display: flex;
  align-items: center;
  width: 44px;
  height: 26px;
  border-radius: 8px;
  justify-content: space-evenly;
  background-color: transparent;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: rgba(0,0,0,.87);
  cursor: pointer;

  &.regular-set {
    font-weight: normal;
  }

  img {
    display: none !important;
  }
`;

export const Menu = styled.div`
  position: fixed;
  border-radius: 4px;
  box-shadow: 0 4px 8px -4px rgba(0,0,0,.3);
  border: 1px solid #e0e0e8;
  background-color: #fff;
  z-index: 1000;
`;

export const  Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .warm_up {
    color: #a0a8b1;
  }

  .drop_set {
    color: #4881f9;
  }

  .failure {
    color: #fd6666;
  }

  &.disabled {
    ${Trigger} {
      cursor: default;
    }
  }

  :not(.disabled):hover {
    ${Trigger} {
      background-color: #fff;
      font-weight: 700;

      &.regular-set {
        color: #ffa22d;
      }

      img {
        display: block !important;
      }
    }
  }
`;