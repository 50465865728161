import React from 'react';
import { connect } from 'react-redux';
import * as S from './style';
import GuideLink from 'shared/Styles/GuideLink';
import HideBanner from 'shared/Styles/HideBanner';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { toggleModal } from 'actions/modal';
import AddPackagePopup from '../AddPackagePopup';
import { hideBanner } from 'redux/package-list/actions';

function Banner(props) {
  const { shouldHide, counter } = props;

  const handleClickHideBanner = () => {
    props.hideBanner();
  };

  const handleCreatePackage = () => {
    props.toggleModal(true, <AddPackagePopup />);
  };

  if (counter.all && shouldHide) {
    return null;
  }

  if (shouldHide && !counter.all) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.ContentContainer>
        <S.Slogan>Easy Revenue Engine</S.Slogan>
        <S.Title>Time to “Show you the money”</S.Title>
        <S.Description>
          Set up and seamlessly launch a web page to market and sell your coaching services. Collect money through
          Everfit’s Payment portal today.
        </S.Description>
        <S.GuideContainer>
          <GuideLink to="http://help.everfit.io/en/collections/3210719-payment-packages" title="Learn about Packages" />
        </S.GuideContainer>
        <S.CreateButton onClick={handleCreatePackage}>
          <PlusIcon />
          <span>{counter.all > 0 ? 'Create Package' : 'Create My First Package'}</span>
        </S.CreateButton>
      </S.ContentContainer>
      <HideBanner onClick={handleClickHideBanner} />
    </S.Wrapper>
  );
}

const mapState = state => {
  const {
    user,
    rootReducer: { packageList },
  } = state;

  return {
    shouldHide: user.is_hide_banner_package,
    counter: packageList.get('counter').toJS(),
  };
};

const actions = {
  toggleModal,
  hideBanner,
};

export default connect(mapState, actions)(Banner);
