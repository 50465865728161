import styled, { css } from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { OptionWrapper } from '../AssetSelect/style';
import { ReactComponent as CloseIcon } from 'assets/icons/close_progress_photo_image.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 6px 16px 16px;
  min-width: 258px;
  max-width: 258px;
  height: fit-content;
  border-radius: 8px;
  border: 1px solid #eaeef1;
  background: #fff;
  box-shadow: 0px 16px 20px 0px rgba(20, 58, 157, 0.08);
  max-height: 100%;

  > div {
    width: 224px;
  }

  .ofp__single-value,
  .off__single-value,
  .ofa__single-value {
    -webkit-line-clamp: 1;
  }

  .ofp__menu-notice,
  .off__menu-notice,
  .ofa__menu-notice {
    width: 100%;
    margin-top: 8px;
    color: hsl(0, 0%, 60%);
    text-align: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .ofp__control--menu-is-open,
  .off__control--menu-is-open,
  .ofa__control--menu-is-open {
    border: 1px solid #5e59ff;
  }

  .ofa__menu {
    right: 79px;
  }

  .disabled.ofa--is-disabled {
    opacity: 0.4;
  }

  .ofp__control,
  .off__control,
  .ofa__control {
    cursor: pointer;

    .ofp__indicator.ofp__dropdown-indicator,
    .off__indicator.off__dropdown-indicator,
    .ofa__indicator.ofa__dropdown-indicator {
      padding-right: 1px;
      padding-left: 6px;

      svg:hover path {
        fill: #5e59ff;
      }
    }
  }

  .ofa__control--menu-is-open {
    border: 1px solid #5e59ff;
    :has(.autoflow-type__label) {
      .ofa__input {
        margin-bottom: 30px;
      }
    }

    :has(.status-label) {
      .ofa__input {
        padding-top: 22px;
      }
    }
  }

  .ofp__control--menu-is-open,
  .off__control--menu-is-open {
    border: 1px solid #5e59ff;
    :has(.status-label) {
      .ofp__input,
      .off__input {
        padding-top: 22px;
      }
    }
  }

  .ofa__control--is-focused {
    :has(.autoflow-type__label) {
      .ofa__input {
        margin-bottom: 30px;
      }
    }

    :has(.status-label) {
      .ofa__input {
        padding-top: 22px;
      }
    }
  }

  .ofp__control--is-focused,
  .off__control--is-focused {
    :has(.status-label) {
      .ofp__input,
      .off__input {
        padding-top: 22px;
      }
    }
  }

  .ofp__menu {
    ${OptionWrapper} {
      box-shadow: 0px -1px 0px 0px #eeeff2 inset;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  .asset-toggle {
    min-width: 32px;
    min-height: 18px;
    margin-right: 1px;
    label {
      min-width: 32px;
      min-height: 23px;
    }
    input ~ label::before {
      width: 32px;
      height: 18px;
    }
    input:checked ~ label::before,
    input:checked:focus ~ label::before {
      background-color: #5559ff;
    }
    input ~ label::after {
      width: 14px;
      height: 14px;
      top: 40%;
    }
    input:checked ~ label::after,
    input:checked:focus ~ label::after {
      left: calc(32px - 16px);
    }

    &.disabled input {
      cursor: not-allowed;
    }
  }

  .__react_component_tooltip {
    width: 100%;
  }
  .__react_component_tooltip.type-dark.asset-toggle-tooltip {
    max-width: 228px;
    border-radius: 5px;
    padding: 15px;
    line-height: 20px;

    &.show {
      background-color: #151619;
    }

    &.upgrade-path-tooltip {
      max-width: 159px;
      text-align: center;
      margin-top: -5px;
    }
  }
`;

export const HeaderIcon = styled.div`
  padding: 8px;
  border-radius: 8px;
  max-height: 40px;
  color: #1a5ff6;
  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${props =>
    props.isHasPadding &&
    css`
      padding: 16px 0;
    `}
`;

export const ContentScroll = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100% !important;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    color: #d9d9d9;
  }

  ${props =>
    props.isCustomGap &&
    css`
      gap: 10px;
    `}

  ${props =>
    props.isCustomPadding &&
    css`
      padding-right: 6px;
    `}

  ${props =>
    props.isHasCloseButton &&
    css`
      padding-top: 10px;
      margin-top: -10px;

      &::-webkit-scrollbar-track {
        margin-top: 10px;
      }
    `}
`;

export const Title = styled.p`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

export const Description = styled.p`
  color: #797b98;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`;

export const CustomCloseIcon = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -11px;
  right: -11px;
  cursor: pointer;
  opacity: 0;
`;

export const Footer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #dadfea;
  background: #fff;
  box-shadow: 0px 4px 8px 0px #f1f2f6;
  ${props =>
    props.isEditForm &&
    css`
      position: relative;
      :hover {
        border-color: #5e59ff;
        ${CustomCloseIcon} {
          opacity: 1;
        }
      }
    `}
  ${props =>
    props.isArchived &&
    css`
      background: #eaedf4;
    `}
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 11px;
  padding-right: 12px;
  border-radius: 5px;
  background: #f8fafc;
`;

export const TitleSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .asset-icon {
    margin-right: 4px;
    width: 32px;
    height: 24px;
  }
  .info-icon {
    width: 30px;
    &:hover {
      path {
        fill: #5e59ff;
      }
    }
  }
  .__react_component_tooltip.app-tooltip {
    padding: 15px;
    max-width: 250px;
    background-color: #2d2e2d;
    text-align: left;
    margin-top: -7px;
    p {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    &.add-on-demand-tooltip.place-top:after {
      bottom: -7px;
    }
  }

  .resource-collections-icon,
  .workout-collections-icon,
  .studio-programs-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    margin-left: 1px;
    flex-shrink: 0;
  }
`;

export const SubTitle = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #202353;
  text-overflow: ellipsis;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  width: 100%;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 21px;
  padding: 2px 4px;
  border-radius: 3px;
  background: #dad9fc;
  color: #202870;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
`;

export const SubDescription = styled.p`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  margin: 0;
  margin-top: 4px;
`;

export const LabelsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Label = styled.div`
  display: flex;
  padding: 2px 4px;
  gap: 4px;
  border-radius: 3px;
  background: #edf0fa;
  color: #202870;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const OnDemandList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const OnDemandItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 44px;
  padding: 7px 4px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);

  .on-demand-item {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .image {
      flex-shrink: 0;
      width: 40px;
      height: 36px;
      border-radius: 4px;
      object-fit: cover;
    }

    .name-wrapper {
      .label {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 700;
        line-height: 14px;
        color: #7f88a5;
        text-align: left;
        text-transform: uppercase;
      }
      .name {
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #202353;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  &:hover {
    .evf-dropdown.custom-dropdown-actions {
      visibility: visible;
      opacity: 1;
    }
  }
  .evf-dropdown.custom-dropdown-actions.open {
    visibility: visible;
    opacity: 1;
    .dropdown-trigger {
      background-color: #f3f3f3 !important;
    }
  }
  .evf-dropdown.custom-dropdown-actions {
    visibility: hidden;
    opacity: 0;
    .evf-dropdown__trigger-container {
      .dropdown-trigger {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: transparent;
        .dot,
        .dot::after,
        .dot::before {
          background-color: #7b7e91;
        }
        .dot::before {
          left: -4px;
        }
        .dot::after {
          left: 4px;
        }
        &:hover {
          background-color: #f0f1ff;
        }
      }
    }
    .evf-dropdown__menu {
      padding: 6px 0;
      border-radius: 8px;
      .evf-dropdown__option {
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        user-select: none;
        .icon {
          display: flex;
          justify-content: center;
        }
        span {
          font-family: 'Open Sans';
          font-style: normal;
          color: #202353;
          line-height: 150%;
          color: #f7f8f9;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }

  ${props =>
    props.isDisabled &&
    css`
      background-color: #eaedf4;
      border: 1px solid rgba(218, 223, 234, 1);
      .on-demand-item .name-wrapper .name {
        -webkit-line-clamp: 1;
      }
    `}
`;

export const ButtonContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  width: 100%;
  color: #5e59ff;
  border-radius: 5px;
  border: 1px solid #5e59ff;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;
  :hover {
    border: 1px solid #2b26c1;
    color: #2b26c1;
    cursor: pointer;
    .onboarding-flow-edit-icon {
      g {
        stroke: #2b26c1;
      }
    }
  }
  .onboarding-flow-edit-icon {
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
    g {
      stroke: #5e59ff;
    }
  }
  ${props =>
    props.isLoading &&
    css`
      ${ButtonContent} {
        pointer-events: none;
      }
      :hover {
        cursor: not-allowed;
        color: #5e59ff;
        border-color: #5e59ff;
      }
    `}
`;

export const FormInfoWrapper = styled.div`
  width: 192px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  ${props =>
    props.isEdit &&
    css`
      padding-bottom: 16px;
    `}
`;

export const Status = styled.div`
  color: #7f88a5;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const FormInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormInfoContent = styled.div`
  display: flex;
  justify-content: start;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const FormInfoLayout = styled.div`
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  background-color: #edf0fa;

  &.trigger {
    display: flex;
    padding: 1px 6px 2px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;

    background: linear-gradient(91.25deg, rgba(255, 76, 0, 0.14) 0.48%, rgba(224, 241, 27, 0.14) 98.93%), #ffffff;
  }
`;

export const FormInfoText = styled.span`
  color: #202870;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  &.trigger {
    color: #ff6433;
    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const MessageInfoWrapper = styled.div`
  position: relative;
`;

export const MessageInfoTitle = styled.div`
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  color: #202353;
  text-overflow: ellipsis;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
`;

export const MessageInfoContent = styled.div`
  margin: 16px 0;
  margin-right: -10px;
  padding-right: 8px;
  max-height: 185px;
  overflow-y: scroll;
`;

export const CustomUpgradePathButton = styled(UpgradePathButton)`
  height: 24px;
  width: 24px;
  padding: 0;
  background: transparent;

  :hover {
    background: transparent;
  }
`;

export const TextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 124px;
  min-height: 20px;
  color: #5e59ff;
  border-radius: 5px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    color: #2b26c1;
  }
`;

export const AddIcon = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 1px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bec9d5;
  border-radius: 50%;

  .plus-icon {
    width: 14px;
    height: 14px;
    flex-shrink: 0;

    path {
      fill: #f9fafc;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #5158cf;

    .plus-icon {
      path {
        fill: white;
      }
    }
  }
`;

export const OptionIcon = styled.div`
  display: flex;
  align-items: center;
  .meal-plan-remove-icon {
    margin-top: 1px;
    width: 16px;
    height: 16px;
  }
`;
