export const QUESTIONNAIRE = [
  {
    id: 0,
    title: "Everfit doesn't have certain features I need",
    comment: '',
    checked: false,
    showComment: true,
    placeholder: 'What features are you looking for?',
    hs_id: 'dr_lacking_feature',
  },
  {
    id: 1,
    title: 'The pricing does not match the value received',
    comment: '',
    checked: false,
    hs_id: 'dr_pricing_not_work',
  },
  {
    id: 2,
    title: 'Everfit has been harder to use than expected',
    comment: '',
    checked: false,
    hs_id: 'dr_long_set_up',
  },
  {
    id: 3,
    title: 'There are not enough exercise videos and training templates',
    comment: '',
    checked: false,
    hs_id: 'dr_lacking_templates',
  },
  {
    id: 4,
    title: 'My clients want to use another platform',
    comment: '',
    checked: false,
    hs_id: 'dr_client_want_another_platform',
  },
  {
    id: 5,
    title: 'My business has been affected by COVID-19',
    comment: '',
    checked: false,
    hs_id: 'dr_covid_affected',
  },
  {
    id: 6,
    title: 'I no longer provide fitness coaching',
    comment: '',
    checked: false,
    hs_id: 'dr_no_more_business',
  },
  {
    id: 7,
    title: 'I found a better alternative',
    comment: '',
    checked: false,
    showComment: true,
    placeholder: 'Which platform are you moving to?',
    hs_id: 'dr_better_alternative',
  },
  {
    id: 8,
    title: 'I need more time to try out Everfit',
    comment: '',
    checked: false,
    hs_id: 'dr_more_time',
  },
  {
    id: 9,
    title: 'Others',
    comment: '',
    checked: false,
    showComment: true,
    isRequired: true,
    placeholder: 'Tell us more',
    hs_id: 'dr_other',
  },
];
