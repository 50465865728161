// Libs
import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';

// Shared
import ModalSingleField from 'shared/ModalSingleField';
import RenderOwnShip from './RenderOwnShip';

// Constants
import { TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER, MAX_FORM_NAME_LENGTH } from 'constants/commonData';

// Styles
import { AdddNewFormModal } from './style';
import AddFormBuilder from '../AddFormBuilder';

export const TYPE = {
  DUPLICATE: 'duplicate',
  UPDATE: 'update',
  ADD: 'add',
};

function AddNewForm({
  onSubmit,
  toggleModal,
  user,
  workingItem,
  type = TYPE.ADD,
  isShowBackButton,
  onAddFormTemplate,
  formTemplates,
  selected,
}) {
  const [value, setValue] = useState('');
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ownerValue, setOwnerValue] = useState({});
  const [shareValue, setShareValue] = useState({ share: TEAM_SHARE_PRIVATE });
  const { titlePopup, submitName } = useMemo(() => {
    switch (type) {
      case TYPE.DUPLICATE:
        return { titlePopup: 'Duplicate Form', submitName: 'Save' };

      case TYPE.UPDATE:
        return { titlePopup: 'Edit Form', submitName: 'Save' };

      default:
        return { titlePopup: 'Add New Form', submitName: 'Add New' };
    }
  }, [type]);

  useEffect(() => {
    setOwnerValue({
      owner: user._id,
    });
  }, [user]);

  useEffect(() => {
    if (workingItem) {
      setValue(type === TYPE.DUPLICATE ? `${workingItem.name.substring(0, 33)} - Copy` : workingItem.name);
      setOwnerValue({
        owner: type === TYPE.DUPLICATE ? user._id : get(workingItem, 'author._id', null),
      });
      setShareValue({
        share: type === TYPE.DUPLICATE ? TEAM_SHARE_PRIVATE : workingItem.share,
      });
    }
  }, [workingItem, type]);

  const onSetShareValue = value => {
    setShareValue(value);
  };

  const onSetOwnerValue = value => {
    setOwnerValue(value);
  };

  const handleChangeValue = e => {
    const value = e.target.value.replace('  ', ' ');

    if (!MAX_FORM_NAME_LENGTH) setValue(value);
    if (value.length <= MAX_FORM_NAME_LENGTH) {
      setValue(value);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!value.trim()) {
      return;
    }

    let params = {
      name: value.trim(),
      owner: shareValue.share === TEAM_SHARE_NOOWNER ? null : ownerValue.owner,
      ...shareValue,
    };

    if (onSubmit) {
      setIsDisabledSubmit(true);

      // TODO - submit add new form
      onSubmit && onSubmit(params, onClose);
      setIsDisabledSubmit(false);
    }
  };

  const onClose = () => {
    setIsDisabledSubmit(false);
    toggleModal && toggleModal(false);
  };

  const handleClickBackButton = () => {
    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <AddFormBuilder
          user={user}
          toggleModal={toggleModal}
          onAddNewForm={onSubmit}
          formTemplates={formTemplates}
          onAddFormTemplate={onAddFormTemplate}
          oldSelected={selected}
        />,
      );
  };

  return (
    <>
      <ModalSingleField
        value={value}
        open={true}
        titlePopup={titlePopup}
        titleInput="Form name"
        placeholderInput="Enter Form Name"
        submitName={submitName}
        maxLength={MAX_FORM_NAME_LENGTH}
        inputHeight={40}
        isSubmitted={isSubmitted}
        isDisabledSubmit={isDisabledSubmit || !value}
        isShowCancelButton={true}
        isHideCloseButton={false}
        onClose={onClose}
        onChangeValue={e => handleChangeValue(e)}
        onSubmit={e => handleSubmit(e)}
        elementModal={AdddNewFormModal}
        childrenForm={
          <RenderOwnShip
            userId={user._id}
            type={type}
            ownerValue={ownerValue}
            shareValue={shareValue}
            hasAvatar
            hasSpacing
            onSetShareValue={onSetShareValue}
            onSetOwnerValue={onSetOwnerValue}
          />
        }
        isShowBackButton={isShowBackButton}
        onClickBackButton={handleClickBackButton}
      />
    </>
  );
}

export default AddNewForm;
