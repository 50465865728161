// Libs
import React from 'react';
import { Modal, Input, Button, Form, Label, Image, Radio } from 'semantic-ui-react';
import _ from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

// Shared
import GeneralButton from 'shared/GeneralButton';
import UpgradePath from 'shared/UpgradePath';
import LimitClientsFailbackPopup from 'shared/UpgradePath/components/LimitClientsFailbackPopup';
import TeammateDropdown, { TYPE } from 'shared/TeammateDropdown';
import OnboardingFlowSelect from 'shared/OnboardingFlowSelect';

// Components
import AddMultipleClients from 'components/AddMultipleClients';

// Utils
import { validateEmail } from 'utils/validations';
import { isTeamAdmin, upperFirstCharacter } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';
import { ClientInvite } from 'types/model';

// Assets
import { ReactComponent as HintIcon } from 'assets/icons/hint.svg';

// Styles
import styles from './styles';
import './styles.scss';

const REQUIRE_FIELDS = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
};

export default class ClientInviteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newClient: new ClientInvite(),
      client_type: 'Online',
      send_email: true,
      stepIndex: 0,
      runGuide: false,
      errors: [],
      assignTo: props.user,
      assignOnboarding: null,
    };
  }

  handleInviteButton = () => {
    const { submitStatus, inviteNewClient, onInviteSuccess, permission } = this.props;
    const { newClient, client_type, send_email, assignTo, assignOnboarding } = this.state;
    const currentErrors = [];

    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;
    const onboarding_flow_id = hasPermissionMultiple ? _.get(assignOnboarding, '_id') : null;
    const onboarding_flow_type =
      hasPermissionMultiple && onboarding_flow_id === 'not_using' ? onboarding_flow_id : undefined;

    if (!newClient[REQUIRE_FIELDS.firstName]) {
      currentErrors.push(REQUIRE_FIELDS.firstName);
    }

    if (!newClient[REQUIRE_FIELDS.lastName]) {
      currentErrors.push(REQUIRE_FIELDS.lastName);
    }

    if (
      (!validateEmail(newClient[REQUIRE_FIELDS.email]) && send_email) ||
      (!send_email && newClient[REQUIRE_FIELDS.email] && !validateEmail(newClient[REQUIRE_FIELDS.email]))
    ) {
      currentErrors.push(REQUIRE_FIELDS.email);
    }

    if (currentErrors.length) {
      this.setState({
        errors: currentErrors,
      });
      return;
    }
    if (submitStatus.status) {
      const assign_to_id = _.get(assignTo, '_id');
      inviteNewClient(
        newClient,
        client_type,
        send_email,
        onInviteSuccess,
        assign_to_id,
        onboarding_flow_id,
        onboarding_flow_type,
      );
    }
  };

  render() {
    const { toggleModal } = this.props;

    return (
      <div>
        <Modal
          size={'tiny'}
          open={true}
          onClose={() => toggleModal(false)}
          className="client-invite-modals"
          closeOnDimmerClick={false}
          closeIcon={
            <Button className="close-button">
              <Image src={`${CDN_URL}/images/close_circle.svg`} />
            </Button>
          }
        >
          <Modal.Header style={styles.headerContainer} className="invite-header invite-client">
            <div>Add new client</div>
            <Button onClick={this.addMultiClients} className="add-multiple-client">
              <Image src={`${CDN_URL}/images/add_multiple_client.svg`} />
              <span>Add Multiple Clients</span>
              <UpgradePath
                pathName="bulk_invite_clients"
                fallback={
                  <img style={{ marginLeft: '10px' }} src={`${CDN_URL}/images/upgrade_path_invite_multi_clients.svg`} />
                }
              />
            </Button>
          </Modal.Header>
          <Modal.Content>
            <Form>{this.renderInputs()}</Form>
          </Modal.Content>
          <Modal.Actions>{this.renderActionButtons()}</Modal.Actions>
        </Modal>
      </div>
    );
  }

  addMultiClients = () => {
    if (this.props.permission.bulk_invite_clients) {
      this.props.toggleModal(true, <AddMultipleClients />);
    } else {
      this.props.toggleSecondModal(
        true,
        <LimitClientsFailbackPopup
          title="Speed up Growth"
          description="Save time and add or import multiple clients <br/> to your client list in one go."
        />,
      );
    }
  };

  onChooseClientType = (e, { value }) => {
    this.setState(p => ({
      client_type: value,
    }));
  };

  renderActionButtons() {
    return (
      <GeneralButton className="setting-save-button" onClick={this.handleInviteButton}>
        Invite
      </GeneralButton>
    );
  }

  handleInputBlur = e => {
    const { name, value } = e.target;
    this.setState({
      errors: [],
    });
    switch (name) {
      case REQUIRE_FIELDS.firstName:
        const firstName = upperFirstCharacter(value);
        e.target.value = firstName;
        this.setState(p => ({
          newClient: p.newClient.setFirstName(firstName || ''),
        }));
        break;
      case REQUIRE_FIELDS.lastName:
        const lastName = upperFirstCharacter(value);
        e.target.value = lastName;
        this.setState(p => ({
          newClient: p.newClient.setLastName(lastName || ''),
        }));
        break;

      default:
        break;
    }
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({
      errors: [],
    });
    switch (name) {
      case REQUIRE_FIELDS.firstName:
        const firstName = upperFirstCharacter(value);
        this.setState(p => ({
          newClient: p.newClient.setFirstName(firstName || ''),
        }));
        break;
      case REQUIRE_FIELDS.lastName:
        const lastName = upperFirstCharacter(value);
        this.setState(p => ({
          newClient: p.newClient.setLastName(lastName || ''),
        }));
        break;
      case REQUIRE_FIELDS.email:
        this.setState(p => ({
          newClient: p.newClient.setEmail(value.trim() || ''),
        }));
        break;
      default:
        break;
    }
  };

  renderInputs() {
    const { errors, assignOnboarding } = this.state;
    const { user, cloudfrontList, toggleSecondModal, toggleConfirmModal, permission } = this.props;
    const teamAdmin = isTeamAdmin(user);
    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

    return (
      <div style={styles.inputContainer}>
        <div className="radios">
          <Radio
            label="Online"
            name="clientType"
            value="Online"
            checked={this.state.client_type === 'Online'}
            onChange={this.onChooseClientType}
          />
          <Radio
            label="In-Person"
            name="clientType"
            value="In-Person"
            style={{ paddingLeft: 30 }}
            checked={this.state.client_type === 'In-Person'}
            onChange={this.onChooseClientType}
          />
          <Radio
            label="Hybrid"
            name="clientType"
            value="Hybrid"
            style={{ paddingLeft: 30 }}
            checked={this.state.client_type === 'Hybrid'}
            onChange={this.onChooseClientType}
          />
        </div>
        <Label className="form-label">FIRST NAME</Label>
        <Input
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
          placeholder={'First name'}
          style={{ width: '100%' }}
          autoFocus
          name={REQUIRE_FIELDS.firstName}
          autoComplete="new-password"
          className={classNames({ error: _.includes(errors, REQUIRE_FIELDS.firstName) })}
          maxLength={30}
        />
        <Label className="form-label">LAST NAME</Label>
        <Input
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
          placeholder={'Last name'}
          style={{ width: '100%' }}
          autoComplete="new-password"
          name={REQUIRE_FIELDS.lastName}
          className={classNames({ error: _.includes(errors, REQUIRE_FIELDS.lastName) })}
          maxLength={30}
        />
        <Label className="form-label">EMAIL {this.state.send_email ? '' : '(Optional)'}</Label>
        <Input
          onChange={this.handleInputChange}
          placeholder={'client@email.com'}
          style={{ width: '100%' }}
          autoComplete="new-password"
          // name="email"
          name={REQUIRE_FIELDS.email}
          className={classNames({ error: _.includes(errors, REQUIRE_FIELDS.email) })}
        />
        {teamAdmin && (
          <>
            <Label className="form-label">ASSIGN TO</Label>
            <TeammateDropdown
              type={TYPE.SINGLE}
              value={this.state.assignTo}
              onChange={it => this.setState({ assignTo: it })}
            />
          </>
        )}
        <Label className="form-label" />
        <div className="metric-type-column send_mail_checkbox">
          <label className="metric-container">
            <input type="checkbox" onChange={this.toggleSendMail} checked={this.state.send_email} />
            <span className="checkmark" />
            Send email invitation
          </label>
        </div>
        <div className="send_mail_note">Client will receive an invitation to create a client account </div>
        {hasPermissionMultiple && (
          <>
            <Label className="form-label">
              <div className="custom-form-label">
                ONBOARDING FLOW (OPTIONAL)
                <HintIcon className="custom-hint-icon" data-tip data-for="tooltip-onboarding-flow" />
              </div>
              <ReactTooltip
                id="tooltip-onboarding-flow"
                effect="solid"
                place="top"
                className="app-tooltip onboarding-flow-tooltip"
              >
                <span>
                  With the Automation add-on, you can select different Onboarding Flows to assign to this client.
                </span>
              </ReactTooltip>
            </Label>
            <OnboardingFlowSelect
              type={TYPE.SINGLE}
              value={assignOnboarding}
              onChange={it => this.setState({ assignOnboarding: it })}
              cloudfrontList={cloudfrontList}
              toggleSecondModal={toggleSecondModal}
              toggleConfirmModal={toggleConfirmModal}
            />
          </>
        )}
      </div>
    );
  }

  toggleSendMail = () => {
    this.setState(p => ({
      send_email: !this.state.send_email,
    }));
  };

  onInputChange(field, event) {
    const partialState = {};
    partialState[field] = event.target.value;
    this.setState(partialState);
  }
}
