import { get } from 'lodash';
import { toast } from 'react-toastify';
import Request from 'configs/request';
import { Mixpanel } from 'utils/mixplanel';

export const Types = {
  TASKS_LIBRARY_GET_LIST_REQUEST: 'TASKS_LIBRARY_GET_LIST_REQUEST',
  TASKS_LIBRARY_GET_LIST_SUCCESS: 'TASKS_LIBRARY_GET_LIST_SUCCESS',
  TASKS_LIBRARY_GET_LIST_FAILED: 'TASKS_LIBRARY_GET_LIST_FAILED',
  TASKS_LIBRARY_CHANGE_QUERY_PARAMS: 'TASKS_LIBRARY_CHANGE_QUERY_PARAMS',
  TASKS_LIBRARY_RESET_QUERY_PARAMS: 'TASKS_LIBRARY_RESET_QUERY_PARAMS',
  TASKS_LIBRARY_DUPLICATE_REQUEST: 'TASKS_LIBRARY_DUPLICATE_REQUEST',
  TASKS_LIBRARY_DUPLICATE_SUCCESS: 'TASKS_LIBRARY_DUPLICATE_SUCCESS',
  TASKS_LIBRARY_DUPLICATE_FAILED: 'TASKS_LIBRARY_DUPLICATE_FAILED',
  TASKS_LIBRARY_DELETE_SINGLE_REQUEST: 'TASKS_LIBRARY_DELETE_SINGLE_REQUEST',
  TASKS_LIBRARY_DELETE_SINGLE_SUCCESS: 'TASKS_LIBRARY_DELETE_SINGLE_SUCCESS',
  TASKS_LIBRARY_DELETE_SINGLE_FAILED: 'TASKS_LIBRARY_DELETE_SINGLE_FAILED',
  TASKS_LIBRARY_UPDATE_SELECTED_TASK: 'TASKS_LIBRARY_UPDATE_SELECTED_TASK',
  TASKS_LIBRARY_RESET_SELECTED_TASK: 'TASKS_LIBRARY_RESET_SELECTED_TASK',
  TASKS_LIBRARY_BULK_DELETE_TASKS_REQUEST: 'TASKS_LIBRARY_BULK_DELETE_TASKS_REQUEST',
  TASKS_LIBRARY_BULK_DELETE_TASKS_SUCCESS: 'TASKS_LIBRARY_BULK_DELETE_TASKS_SUCCESS',
  TASKS_LIBRARY_BULK_DELETE_TASKS_FAILED: 'TASKS_LIBRARY_BULK_DELETE_TASKS_FAILED',
  TASKS_LIBRARY_UPDATE_SHARING_STATUS_REQUEST: 'TASKS_LIBRARY_UPDATE_SHARING_STATUS_REQUEST',
  TASKS_LIBRARY_UPDATE_SHARING_STATUS_SUCCESS: 'TASKS_LIBRARY_UPDATE_SHARING_STATUS_SUCCESS',
  TASKS_LIBRARY_UPDATE_SHARING_STATUS_FAILED: 'TASKS_LIBRARY_UPDATE_SHARING_STATUS_FAILED',
  TASKS_LIBRARY_GET_DETAILS_REQUEST: 'TASKS_LIBRARY_GET_DETAILS_REQUEST',
  TASKS_LIBRARY_GET_DETAILS_SUCCESS: 'TASKS_LIBRARY_GET_DETAILS_SUCCESS',
  TASKS_LIBRARY_GET_DETAILS_FAILED: 'TASKS_LIBRARY_GET_DETAILS_FAILED',
  ADD_FROM_TASK_LIBRARY_REQUEST: 'ADD_FROM_TASK_LIBRARY_REQUEST',
  ADD_FROM_TASK_LIBRARY_SUCCESS: 'ADD_FROM_TASK_LIBRARY_SUCCESS',
  ADD_FROM_TASK_LIBRARY_FAILED: 'ADD_FROM_TASK_LIBRARY_FAILED',
  UPDATE_FROM_TASK_LIBRARY_REQUEST: 'UPDATE_FROM_TASK_LIBRARY_REQUEST',
  UPDATE_FROM_TASK_LIBRARY_SUCCESS: 'UPDATE_FROM_TASK_LIBRARY_SUCCESS',
  UPDATE_FROM_TASK_LIBRARY_FAILED: 'UPDATE_FROM_TASK_LIBRARY_FAILED',
  RESET_TASK_LIBRARY_STORE: 'RESET_TASK_LIBRARY_STORE',
};

export const addFromTaskLibrary = (values, callBack) => {
  return (dispatch, getState) => {
    const { user } = getState();

    dispatch({ type: Types.ADD_FROM_TASK_LIBRARY_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/task-libraries`,
          data: values,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          if (data) {
            dispatch({ type: Types.ADD_FROM_TASK_LIBRARY_SUCCESS, payload: { addTask: data, userAdd: user } });
            toast('Task has been saved');
            Mixpanel.track('add_task_library');
          }
        },
        error => {
          dispatch({ type: Types.ADD_FROM_TASK_LIBRARY_FAILED });
        },
      ),
    );
  };
};

export const updateFromTaskLibrary = (values, callBack) => {
  const taskId = get(values, '_id', '');

  return (dispatch, getState) => {
    const { user } = getState();
    dispatch({ type: Types.UPDATE_FROM_TASK_LIBRARY_REQUEST });

    return dispatch(
      Request.patch(
        {
          url: `/api/task-libraries/${taskId}`,
          data: values,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          if (data) {
            dispatch({
              type: Types.UPDATE_FROM_TASK_LIBRARY_SUCCESS,
              payload: { updateTask: data, loggedId: get(user, '_id', '') },
            });
          }
        },
        error => {
          dispatch({ type: Types.UPDATE_FROM_TASK_LIBRARY_FAILED });
        },
      ),
    );
  };
};

export const getTasksLibraryDetails = (taskId, callBack) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.TASKS_LIBRARY_GET_DETAILS_REQUEST });

    return dispatch(
      Request.get(
        { url: `/api/task-libraries/${taskId}` },
        true,
        (response, { dispatch }) => {
          dispatch({ type: Types.TASKS_LIBRARY_GET_DETAILS_SUCCESS });
        },
        error => {
          dispatch({ type: Types.TASKS_LIBRARY_GET_DETAILS_FAILED });
        },
      ),
    );
  };
};

export const updateTaskSharingStatus = (values, callBack) => {
  const taskId = get(values, 'taskLibraryId', '');

  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        tasksLibrary: { list, page },
      },
    } = getState();

    dispatch({ type: Types.TASKS_LIBRARY_UPDATE_SHARING_STATUS_REQUEST });

    return dispatch(
      Request.patch(
        {
          url: `/api/task-libraries/${taskId}`,
          data: values,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          if (data) {
            dispatch({ type: Types.TASKS_LIBRARY_UPDATE_SHARING_STATUS_SUCCESS, payload: { updateShare: data, user } });
            const newList = list.filter(item => item._id !== taskId);
            if (!newList.length && page > 1) {
              dispatch(changeQueryParams({ page: page - 1 }, false, false));
            } else {
              dispatch(changeQueryParams({}, false, false));
            }
            callBack && callBack();
          }
        },
        error => {
          dispatch({ type: Types.TASKS_LIBRARY_UPDATE_SHARING_STATUS_FAILED });
        },
      ),
    );
  };
};

export const bulkDeleteTask = (taskIds, callBack) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        tasksLibrary: { list, page, total },
      },
    } = getState();

    dispatch({ type: Types.TASKS_LIBRARY_BULK_DELETE_TASKS_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/task-libraries/bulk-delete`,
          data: { listIds: taskIds },
        },
        true,
        (response, { dispatch }) => {
          const {
            data: {
              data: { invalidIds, deleted },
            },
          } = response;
          const toDelete = taskIds.filter(item => !invalidIds.includes(item));
          const newList = list.filter(item => !toDelete.includes(item._id));
          const newTotal = total - toDelete.length;

          dispatch({ type: Types.TASKS_LIBRARY_BULK_DELETE_TASKS_SUCCESS, payload: { newList, newTotal } });
          Mixpanel.track('remove_task_library');

          if (!newList.length && page > 1) {
            dispatch(changeQueryParams({ page: page - 1 }, false, false));
          } else {
            dispatch(changeQueryParams({}, false, false));
          }
          callBack && callBack(deleted);
        },
        error => {
          dispatch({ type: Types.TASKS_LIBRARY_BULK_DELETE_TASKS_FAILED });
        },
      ),
    );
  };
};

export const resetSelectedTask = () => {
  return { type: Types.TASKS_LIBRARY_RESET_SELECTED_TASK };
};

export const updateSelectedTask = taskIds => {
  return { type: Types.TASKS_LIBRARY_UPDATE_SELECTED_TASK, payload: { data: taskIds } };
};

export const duplicateTasksLibrary = (taskId, taskTitle, callBack) => {
  return dispatch => {
    dispatch({ type: Types.TASKS_LIBRARY_DUPLICATE_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/task-libraries/duplicate`,
          data: {
            taskLibraryId: taskId,
            name: taskTitle,
          },
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          if (data) {
            dispatch({ type: Types.TASKS_LIBRARY_DUPLICATE_SUCCESS, payload: { duplicateData: data } });
            callBack && callBack();
          }
        },
        error => {
          dispatch({ type: Types.TASKS_LIBRARY_DUPLICATE_FAILED, error: error });
        },
      ),
    );
  };
};

export const removeTask = (taskId, callBack) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        tasksLibrary: { list, page },
      },
    } = getState();

    dispatch({ type: Types.TASKS_LIBRARY_DELETE_SINGLE_REQUEST });

    return dispatch(
      Request.delete(
        { url: `/api/task-libraries/${taskId}` },
        false,
        response => {
          const { data } = response;
          const newList = list.filter(item => item._id !== taskId);
          dispatch({ type: Types.TASKS_LIBRARY_DELETE_SINGLE_SUCCESS, payload: { data, taskId } });
          if (!newList.length && page > 1) {
            dispatch(changeQueryParams({ page: page - 1 }, false, false));
          } else {
            dispatch(changeQueryParams({}, false, false));
          }
          callBack && callBack();
          Mixpanel.track('remove_task_library');
        },
        error => {
          dispatch({ type: Types.TASKS_LIBRARY_DELETE_SINGLE_FAILED, error: error });
        },
      ),
    );
  };
};

export const changeQueryParams = (data, isLoadMore = false, isLoading = true) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        tasksLibrary: { queryParam },
      },
    } = getState();

    const newData = { ...queryParam, ...data };

    dispatch({
      type: Types.TASKS_LIBRARY_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });
    dispatch(getTasksLibrary(newData, isLoadMore, isLoading));
  };
};

export const resetQueryParams = () => ({ type: Types.TASKS_LIBRARY_RESET_QUERY_PARAMS });

export const resetTaskLibraryStore = () => ({ type: Types.RESET_TASK_LIBRARY_STORE });

export const getTasksLibrary = (params, isLoadMore = false, isLoading = true) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        tasksLibrary: { queryParam, list },
      },
    } = getState();

    const queryParams = params ? params : queryParam;
    const prevList = list;
    if (isLoading) {
      dispatch({ type: Types.TASKS_LIBRARY_GET_LIST_REQUEST });
    }

    return dispatch(
      Request.get(
        { url: `/api/task-libraries`, params: queryParams },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;

          const isEnd = queryParams.page * queryParams.limit >= data.total;
          return dispatch({
            type: Types.TASKS_LIBRARY_GET_LIST_SUCCESS,
            payload: { data, isEnd, isLoadMore, prevList },
          });
        },
        error => {
          dispatch({ type: Types.TASKS_LIBRARY_GET_LIST_FAILED, error: error });
        },
      ),
    );
  };
};
