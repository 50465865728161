import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => `${props.size || 30}px`};
  height: ${(props) => `${props.size || 30}px`};
  border-radius: 50%;
  background-color: #eaeef1;
  cursor: pointer;

  :hover {
    background-color: #dae0e4;
  }

  .dot {
    width: 4px;
    height: 4px;
    position: relative;
    border-radius: 50%;
    background-color: #728096;

    ::before,
    ::after {
      content: '';
      width: 4px;
      height: 4px;
      position: absolute;
      border-radius: 50%;
      background-color: #728096;
      top: 0;
      left: -5px;
    }

    ::after {
      left: 5px;
    }
  }
`;

export default (props) => (
  <Container {...props} className="actions-trigger">
    <div className="dot" />
  </Container>
);
