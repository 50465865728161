import styled from 'styled-components';

export const LibraryContainer = styled.div`
  width: 100%;
  margin-left: 30px;
  margin-right: 9px;
`;

export const TextBase = `
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
`;

export const TextOverflow = `
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
