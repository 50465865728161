import React from 'react';
import { get } from 'lodash';

import CardThumbnail from './CardThumbnail';
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';

import { getLabelAndDuration } from './commonFunction';
import { MAX_ITEM } from './constants';

import * as S from './style';

function PreviewNarrowCard(props) {
  const { section } = props;

  return (
    <S.SectionMobilePreview>
      <S.PreviewTitle>
        <S.ViewName>{section.name}</S.ViewName>
        <S.ViewMore>See more</S.ViewMore>
      </S.PreviewTitle>
      <S.PreviewLargeCardWrapper>
        {get(section, 'workouts', [])
          .filter((item, index) => index < MAX_ITEM)
          .map(resource => (
            <S.NarrowCard key={resource._id}>
              <CardThumbnail resource={resource} showType={true}>
                <S.LargeCardName>{resource.title}</S.LargeCardName>
                <S.LabelAndDuration>{getLabelAndDuration(resource)}</S.LabelAndDuration>

                {resource.type.includes('video') && (
                  <div className="previewResource__playIcon">
                    <PlayIcon />
                  </div>
                )}
              </CardThumbnail>
            </S.NarrowCard>
          ))}
      </S.PreviewLargeCardWrapper>
    </S.SectionMobilePreview>
  );
}

export default PreviewNarrowCard;
