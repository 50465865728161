import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const RemoveIcon = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url(${CDN_URL}/images/trash.svg) no-repeat;

  :hover {
    background: url(${CDN_URL}/images/trash_hover.svg) no-repeat;
  }
`;

export const ResourceAvatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;

  ${props =>
    props.coverImage
      ? css`
          background-image: url(${props.coverImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          &.youtube {
            background-color: #ffe7e7;
            background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
          }

          &.vimeo {
            background-color: #e8fbff;
            background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
          }

          &.instagram {
            background-color: #ffe2ec;
            background-image: url(${CDN_URL}/images/studio_resource_instagram_icon.svg);
          }

          &.spotify {
            background-color: #e7ffef;
            background-image: url(${CDN_URL}/images/studio_resource_spotify_icon.svg);
          }

          &.facebook {
            background-color: #e0f0ff;
            background-image: url(${CDN_URL}/images/studio_resource_facebook_icon.svg);
          }

          &.twitter {
            background-color: #e8f6ff;
            background-image: url(${CDN_URL}/images/studio_resource_twitter_icon.svg);
          }

          &.link {
            background-color: #f7f8fc;
            background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
          }

          &.document {
            background-color: #e8f4ff;
            background-image: url(${CDN_URL}/images/studio_resource_document_icon.svg);
          }

          &.form {
            background-color: rgba(76, 189, 54, 0.1);
            background-image: url(${CDN_URL}/images/studio_resource_form_icon.svg);
          }
        `}
`;

export const ResourceInfo = styled.div`
  width: calc(100% - 80px);

  .resourceInfo__name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
    width: 100%;
  }

  .resourceInfo__description {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .resourceInfo__wrapper {
    display: flex;
    align-items: center;
  }

  .resourceInfo__archived {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 18px;
    background: #fff4da;
    border: 1px solid #ffbb40;
    border-radius: 2px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    text-align: center;
    color: #e79512;
    margin-left: 4px;
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #ebedf4;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  padding: 12px 15px;
  cursor: pointer;
  margin-bottom: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;

  ${RemoveIcon} {
    visibility: hidden;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  ${props =>
    !props.disabled &&
    css`
      :hover {
        border: 1px solid #5158cf;
        box-shadow: 0px 4px 8px #f1f2f6;

        ${RemoveIcon} {
          visibility: visible;
        }

        ${ResourceAvatar} {
          background-image: url(${CDN_URL}/images/humburger.svg);
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto;
        }
      }
    `}

  ${props =>
    props.isDraging &&
    css`
      ${ResourceAvatar} {
        background-image: url(${CDN_URL}/images/humburger.svg);
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
    `}
`;
