import React from 'react';

import { DateTime } from 'luxon';

import TaskCalendarCell from 'components/TaskCalendarCell';

import './styles.scss';
import UpgradePath from 'shared/UpgradePath';
import UnlimitedSchedulingFailBack from 'shared/UpgradePath/components/UnlimitedSchedulingFailBack';
import { resetCopyItem } from 'redux/task/task.actionCreators';

const weekday = new Array(7);
weekday[0] = 'Mon';
weekday[1] = 'Tue';
weekday[2] = 'Wed';
weekday[3] = 'Thu';
weekday[4] = 'Fri';
weekday[5] = 'Sat';
weekday[6] = 'Sun';

export default class TaskCalendar extends React.Component {
  componentWillUnmount() {
    this.props.dispatch(resetCopyItem());
  }

  render() {
    const { taskCalendarType, taskCalendarStartDate } = this.props;
    const rows = [];
    for (let i = 0; i < 7; i++) {
      rows.push(this.renderColumn(i));
    }

    return (
      <div className="calendar-columns">
        {rows}
        <UpgradePath
          pathName="unlimitedScheduling"
          fallback={
            <UnlimitedSchedulingFailBack noWeeks={taskCalendarType} startDate={taskCalendarStartDate.toISODate()} />
          }
        />
      </div>
    );
  }

  renderColumn(column) {
    const { taskCalendarType, taskCalendarStartDate } = this.props;
    const columns = [];
    const dates = [];
    let dayofWeek = weekday[column];
    for (let i = 0; i < taskCalendarType; i++) {
      let day = DateTime.fromISO(taskCalendarStartDate.toISODate());
      day = day.plus({ days: 7 * i + column });
      dayofWeek = day.toFormat('EEE');
      columns.push(
        <TaskCalendarCell
          key={day.toString()}
          day={day}
          dayofWeek={dayofWeek}
          column={column}
          isDragging={this.props.isDragging}
        />,
      );
      dates.push(day);
    }
    return (
      <div key={column} className="calendar-column">
        <div className="calendar-column__header">{dayofWeek.toUpperCase()}</div>
        <div className="calendar-column__body">{columns}</div>
      </div>
    );
  }
}
