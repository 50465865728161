import styled, { css } from 'styled-components';

const baseText = `
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
line-height: 150%; 
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const IconWrapper = styled.span`
  font-weight: 600;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer') + ' !important'};

  .arrow-icon {
    margin-bottom: -4px;
    transform: ${({ isIncrease }) => !isIncrease && 'rotate(180deg)'};
  }

  .starting-arrow-icon {
    margin: 5px 2px -4px 5px;
    transform: ${({ isIncrease }) => !isIncrease && 'rotate(180deg)'};
  }

  .__react_component_tooltip.app-tooltip {
    min-height: 34px !important;
  }
  .__react_component_tooltip.app-tooltip.place-top:after {
    bottom: -8px !important;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 35px;
  flex: 1 0 0;
  margin-bottom: 3px;
  height: ${({ isShowRangeTitle }) => (isShowRangeTitle ? '50px' : '74px')};

  &.sleep-keys {
    gap: 36px;

    .empty-line {
      margin-right: 4px;
    }

    .sleep-goal .goal-icon {
      margin-top: 2px;
    }
  }

  &.sleep-keys {
    gap: 36px;

    .empty-line {
      margin-right: 4px;
    }

    .sleep-goal .goal-icon {
      margin-top: 2px;
    }
  }

  .__react_component_tooltip.app-tooltip {
    border-radius: 4px;
    display: flex;
    padding: 8px 13px 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-height: 36px;
  }

  .average-wrapper {
    margin-right: -11px;
  }
`;

export const CurrentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
  margin-right: ${({ isEmpty }) => (isEmpty ? '10px' : '0')};
`;

export const KeyLabel = styled.div`
  ${baseText}
  color: ${({ color }) => color || '#7b7e91'};
  font-size: 10px;
  text-transform: uppercase;

  &.sleep-goal {
    font-size: 11px;
    line-height: 8.25px;
  }

  .goal-icon {
    margin-right: 2px;
    margin-top: 1px;
  }
`;

export const HeartRateKeyLabel = styled.div`
  ${baseText}
  color: ${({ color }) => `${color || '#7b7e91'}`};
  font-size: 10px;
  text-transform: uppercase;
  height: 15px;
`;

export const Unit = styled.span`
  ${baseText}
  color: #202353;
`;

export const Number = styled.div`
  ${baseText}
  color: #202353;

  &.sleep-avg-num {
    .sec-unit {
      margin-left: -3px !important;
    }
  }

  &.sleep-goal-num {
    margin-top: 14px;
  }

  ${({ current }) =>
    current
      ? css`
          font-size: 30px;
          margin-top: -4px;
          ${Unit} {
            font-size: 19px;
            line-height: 130%;
            margin-left: -5px;
          }
        `
      : css`
          font-size: 15px;
          margin-top: 12px;
          margin-left: 12px;
          ${Unit} {
            font-size: 13px;
          }
        `}

  .empty-line {
    color: #a3a3b5;
    font-weight: 400;
  }
`;

export const Tag = styled.div`
  display: flex;
  width: max-content;
  cursor: pointer;

  .no-change {
    color: #7b7e91;
  }
`;

export const StartingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;

  ${Number} {
    margin-top: 12px;
    margin-left: 0px;
  }
`;

export const GoalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: ${({ isEmpty }) => (isEmpty ? '15px' : '0')};
  cursor: default;

  ${KeyLabel} {
    margin-top: -6px;
    margin-left: 11px;
  }

  ${IconWrapper} {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;

    &.sleep-edit-goal {
      margin-top: 12px;
    }

    :hover {
      background: #f0f1ff;
      .pencil {
        g {
          path {
            fill: #5158cf;
          }
        }
      }
    }
  }
`;

export const StatWrapper = styled.div`
  display: flex;
  gap: 7px;
`;

export const TooltipStyle = styled.p`
  ${baseText}
  display: flex;
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-top: 2px;
  word-break: keep-all;

  ${BoldText} {
    padding-left: 7px;
  }

  ${IconWrapper} {
    margin-top: -5px;
  }
`;
