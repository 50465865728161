import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudioProgramCalendarCell from './component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  getStudioProgramWorkoutDetail,
  pasteWorkoutToDay,
  saveStudioProgramWorkoutToLibrary,
  deleteStudioProgramWorkout,
  copyStudioProgramWorkout,
  arrangeStudioProgramWorkoutInDay,
  addSectionToStudioProgramWorkout,
  updateStudioProgramSectionFromCalendar,
  handlePasteMultipleWorkout,
  handleSelectWorkout,
  handleResetWorkout,
} from 'redux/studio-program/actions';
import { togglePopup } from 'actions/popup';

const mapState = (state, ownerProps) => {
  const {
    user,
    rootReducer: {
      studioProgram: {
        calendarViewMode,
        startWeek,
        workingStudio,
        workoutsByDay,
        copying,
        selectedWorkout,
        isCopySelectedWorkout,
      },
    },
  } = state;

  const totalWeek = workingStudio.studio_program_weeks.length;
  const workouts = workoutsByDay[ownerProps.cellId] || [];

  return {
    calendarViewMode,
    startWeek,
    totalWeek,
    workouts,
    user,
    copying,
    studioProgramId: workingStudio._id,
    selectedWorkout,
    isCopySelectedWorkout,
  };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getStudioProgramWorkoutDetail: bindActionCreators(getStudioProgramWorkoutDetail, dispatch),
  pasteWorkoutToDay: bindActionCreators(pasteWorkoutToDay, dispatch),
  saveStudioProgramWorkoutToLibrary: bindActionCreators(saveStudioProgramWorkoutToLibrary, dispatch),
  deleteStudioProgramWorkout: bindActionCreators(deleteStudioProgramWorkout, dispatch),
  copyStudioProgramWorkout: bindActionCreators(copyStudioProgramWorkout, dispatch),
  arrangeStudioProgramWorkoutInDay: bindActionCreators(arrangeStudioProgramWorkoutInDay, dispatch),
  addSectionToStudioProgramWorkout: bindActionCreators(addSectionToStudioProgramWorkout, dispatch),
  togglePopup: bindActionCreators(togglePopup, dispatch),
  updateStudioProgramSectionFromCalendar: bindActionCreators(updateStudioProgramSectionFromCalendar, dispatch),
  handleSelectWorkout: bindActionCreators(handleSelectWorkout, dispatch),
  handlePasteMultipleWorkout: bindActionCreators(handlePasteMultipleWorkout, dispatch),
  handleResetWorkout: bindActionCreators(handleResetWorkout, dispatch),
});

export default connect(mapState, mapDispatch)(StudioProgramCalendarCell);
