import styled, { css } from 'styled-components';

import { CDN_URL } from 'constants/commonData';

import AddMediaIcon from 'assets/icons/media_2.svg';
import AddMediaDraggingIcon from 'assets/icons/media_line_purple.svg';
import Audio from 'assets/icons/audio_wrapper.svg';
import AudioHover from 'assets/icons/audio_wrapper_hover.svg';

export const AddMediaPlaceholder = styled.div`
  border: 1px dashed #d1d1d1;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 148px;
  padding: 58px 120px 58px 202px;
  position: relative;
  background: #fbfbfb url(${AddMediaIcon}) no-repeat;
  background-size: auto;
  background-position: 130px center;
  box-shadow: none;
  outline: none;

  ${props =>
    props.isDragging &&
    css`
      border-color: #5158cf;
      background-image: url(${AddMediaDraggingIcon});
    `}

  :hover {
    border-color: #5158cf;
    background-image: url(${AddMediaDraggingIcon});
  }
`;

export const AddMediaContainer = styled.div`
  margin-bottom: 20px;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 1;
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: 14px;
  border-radius: 50%;
`;

export const TextPlaceholder = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;

  button {
    color: #5158cf;
    white-space: nowrap;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    background: transparent;
    min-width: unset;

    > * {
      margin-right: 0;
    }

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .choose-file {
    position: relative;

    .select-file__input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      outline: none !important;
      box-shadow: none !important;
      text-indent: -9999px;
      opacity: 0;
      border: none !important;
      cursor: pointer;
    }
  }
`;

export const MediaDetailContainer = styled.div`
  background: #f4f4f5;
  border-radius: 5px;
  padding: 20px 15px;
  position: relative;
`;

export const AttachmentItem = styled.div`
  background: #ffffff;
  border: 1px solid #ebedf4;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  padding: 10px 15px 10px 10px;
`;

export const MediaLinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .form-group--link {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      flex: 1 1;
    }
  }

  .remove-link {
    flex: 0 0 12px;
    margin-left: 15px;
    cursor: pointer;

    path {
      fill: #111111;
    }
  }

  .video-title {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #979797;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .invalid-link {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    margin-top: 0;
  }
`;

export const MediaLinkPreview = styled.div`
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  margin-right: 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fafbfc;
  background-image: url(${CDN_URL}/images/create_resource_link_icon.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  overflow: hidden;

  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
      background-size: cover;
    `}

  :before {
    content: '';
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: 16px;
    height: 16px;
    z-index: 1;
    display: none;
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
  }

  &.vimeo {
    :before {
      display: block;
      background-image: url(${CDN_URL}/images/logo_vimeo.png);
    }
  }

  &.youtube {
    :before {
      display: block;
      background-image: url(${CDN_URL}/images/logo_youtube.png);
    }
  }

  .media-player__trigger,
  .media-player__button {
    background: transparent;
  }
`;

export const AttachmentPreview = styled.div`
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  margin-right: 15px;
  border-radius: 5px;
  position: relative;
  background-color: #fafbfc;
  overflow: hidden;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${props =>
    props.isVideo &&
    css`
      :before {
        content: '';
        position: absolute;
        left: 4px;
        bottom: 4px;
        width: 15px;
        height: 15px;
        background: url(${CDN_URL}/images/video_play.svg) left bottom / contain no-repeat;
        z-index: 1;
      }
    `}

  .media-player__trigger,
  .media-player__button {
    background: transparent;
  }

  .image-with-fallback {
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.isAudio &&
    css`
      :before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #fff url(${Audio}) left bottom / contain no-repeat;
        z-index: 1;
      }

      :hover {
        :before {
          background: #fff url(${AudioHover}) left bottom / contain no-repeat;
        }
      }
    `}
`;
