import get from 'lodash/get';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Datetime from 'react-datetime';

import moment from 'moment';
import classNames from 'classnames';

import { formatDateSafari, pluralize, useOnClickOutside } from 'utils/commonFunction';
import { CheckboxCircle } from 'shared/FormControl';
import { DateInput } from 'shared/FormControl';
import * as S from './styles';
import TooltipIcon from 'shared/InfoTooltipIcon';
import { CDN_URL, RESUME_TYPE } from 'constants/commonData';

const isStagingPayment = process.env.REACT_APP_ENABLE_PAYMENT_STAGING_HACK;

const OPTION_TYPES = {
  WEEK: 'week',
  MONTH: 'month',
};

const MORE_OPTIONS = [
  {
    value: 1,
    type: OPTION_TYPES.WEEK,
  },
  {
    value: 2,
    type: OPTION_TYPES.WEEK,
  },
  {
    value: 3,
    type: OPTION_TYPES.WEEK,
  },
  {
    value: 4,
    type: OPTION_TYPES.WEEK,
  },
  {
    value: 1,
    type: OPTION_TYPES.MONTH,
  },
  {
    value: 2,
    type: OPTION_TYPES.MONTH,
  },
];

const DATE_FORMAT = 'MMMM D, YYYY';

const handleInitResumeDay = (purchase, checkDisable = false) => {
  const every = get(purchase, 'price.every', 0);
  const type = get(purchase, 'price.recurring_interval_type');

  const today = moment(new Date());
  const periodEnd = purchase.current_period_end ? moment.unix(purchase.current_period_end) : today;
  const resumeDay = today <= periodEnd ? periodEnd : checkDisable ? today : today.add(every, type);

  return resumeDay;
};

const initSelectedDate = (isResume, item) => {
  if (isResume) {
    const resumeDay = item.resume_day || handleInitResumeDay(item);
    return formatDateSafari(resumeDay); // Resume date
  }
  // Next invoice for pause
  const nextInvoice = get(item, 'next_invoice', '');
  return nextInvoice;
};

const checkingSelectedOption = (item, selectedItem) => {
  return selectedItem && selectedItem.value === item.value && selectedItem.type === item.type;
};

const PauseSubscriptionPopup = ({
  isConfirmModalOpen,
  title = '',
  handleConfirm,
  dispatch,
  item,
  isResume = false,
}) => {
  const [pauseType, setPauseType] = useState('next_payment');
  const [resumeType, setResumeType] = useState(RESUME_TYPE.IMMEDIATELY);
  const [selectedDate, setSelectedDate] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const refCalendar = useRef();

  useEffect(() => {
    setSelectedDate(initSelectedDate(isResume, item));
  }, []);

  const handlePauseOnChange = value => {
    setPauseType(value);
  };

  const handleReSumeOnChange = value => {
    setResumeType(value);
  };

  const handleSelectDate = date => {
    setSelectedDate(date);
    setSelectedOption(null);
  };

  const renderDay = (props, currentDate, selectedDate) => {
    return <td {...props}>{currentDate.date() < 10 ? '0' + currentDate.date() : currentDate.date()}</td>;
  };

  const handleOpenCalendar = status => () => {
    setShowCalendar(status);
  };

  const handleConfirmResume = () => {
    if (resumeType === RESUME_TYPE.IMMEDIATELY) {
      handleConfirm(item, moment(), resumeType);
    } else {
      handleConfirm(item, selectedDate, resumeType);
    }
  };

  const handleConfirmPause = () => {
    if (pauseType === 'next_payment') {
      handleConfirm(item, pauseNextPayment);
    } else {
      handleConfirm(item, selectedDate);
    }
  };

  const handleSelectMoreOption = option => () => {
    setSelectedOption(option);
    const selectedOption = moment().add(option.value, option.type);
    setSelectedDate(selectedOption);
    setShowCalendar(false);
  };

  const clientFullName = useMemo(() => {
    return get(item, 'customer.full_name') || get(item, 'client.full_name');
  }, [item]);

  const titlePopup = `${isResume ? 'Resume' : 'Pause'} ${clientFullName}’s Subscription?`;

  const isShowResume = useMemo(() => {
    return showCalendar && resumeType === RESUME_TYPE.EXACT_DATE;
  }, [resumeType, showCalendar]);

  const isShowPause = useMemo(() => {
    return showCalendar && pauseType === 'end_on_date';
  }, [pauseType, showCalendar]);

  const pauseNextPayment = useMemo(() => {
    const nextInvoice = get(item, 'next_invoice', '');
    const every = get(item, 'price.every', 0);
    const intervalType = get(item, 'price.recurring_interval_type', 'week');

    // Hacking next_invoice
    if (isStagingPayment && intervalType === 'month') {
      return moment().add(every + 1, 'day');
    }

    // Skip one payment
    const pause = moment(nextInvoice).add(every, intervalType).format(DATE_FORMAT);

    return pause;
  }, [item]);

  const isValidData = (currentDate, selectedDate) => {
    const nextInvoice = get(item, 'next_invoice', '');
    return currentDate.isSameOrAfter(nextInvoice, 'day');
  };

  const isValidResume = (currentDate, selectedDate) => {
    const resumeDay = item.resume_day || handleInitResumeDay(item, true);
    return currentDate.isSameOrAfter(moment(formatDateSafari(resumeDay)), 'day');
  };

  useEffect(() => {
    setTimeout(() => {
      const currentDOM = document.querySelector(`${isResume ? '.resume' : '.pause'}-option`);

      if (currentDOM) {
        const parent = currentDOM.closest('.modals.dimmer');
        if (parent) {
          parent.style.overflowY = 'scroll';
          parent.style.justifyContent = 'center';
        }
      }
    }, 0);
  }, [isConfirmModalOpen]);

  useOnClickOutside(refCalendar, () => {
    setShowCalendar(false);
  });

  return (
    <S.PauseWrapper
      isConfirmModalOpen={isConfirmModalOpen}
      title={titlePopup}
      onConfirm={isResume ? handleConfirmResume : handleConfirmPause}
      confirmButtonTitle={isResume ? 'Resume Subscription' : 'Pause Subscription'}
      cancelButtonTitle="Nevermind"
      headerIcon={`${CDN_URL}/images/${
        isResume ? 'resume_subscription_confirm_icon.svg' : 'pause_subscription_confirm_icon.svg'
      }`}
      newStyle
      hasCloseIcon
      dispatch={dispatch}
      className={`${isResume ? 'resume' : 'pause'}-option`}
      content={
        <S.Wrapper>
          <S.Message>
            {isResume
              ? `The subscription is set to resume on ${moment(formatDateSafari(item.resume_day)).format(
                  'MMMM D',
                )}. You can change the date when to resume the client’s payment and subscription. We will help notify your client by email.`
              : `How long would you like to pause the subscription for? Choose a date to resume the payment and the
            subscription.`}
          </S.Message>
          {isResume ? (
            <>
              <CheckboxCircle
                label={
                  <S.Option className="has-tooltip">
                    Resume Immediately <TooltipIcon message="Client will be charged today" className="resume-tooltip" />
                  </S.Option>
                }
                value={RESUME_TYPE.IMMEDIATELY}
                checked={resumeType === RESUME_TYPE.IMMEDIATELY}
                onChange={handleReSumeOnChange}
                className="first"
              />
              <CheckboxCircle
                label={
                  <S.Option ref={refCalendar}>
                    Resume on
                    <Datetime
                      value={moment(selectedDate)}
                      renderInput={props => {
                        return <DateInput {...props} text={moment(selectedDate).format(DATE_FORMAT)} />;
                      }}
                      onFocus={handleOpenCalendar(true)}
                      onBlur={handleOpenCalendar(false)}
                      dateFormat={'MM-DD'}
                      open={isShowResume}
                      viewMode="days"
                      renderDay={renderDay}
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={handleSelectDate}
                      isValidDate={isValidResume}
                      disableOnClickOutside={true}
                      className={classNames('custom-calendar', isResume && 'resume')}
                    />
                    {/* {isShowResume && (
                      <S.MoreOptionsWrapper>
                        {MORE_OPTIONS.map(it => (
                          <S.MoreOption
                            onClick={handleSelectMoreOption(it)}
                            active={checkingSelectedOption(it, selectedOption)}
                          >
                            {it.value} {pluralize(it.type, it.value)} from today
                          </S.MoreOption>
                        ))}
                      </S.MoreOptionsWrapper>
                    )} */}
                  </S.Option>
                }
                value={RESUME_TYPE.EXACT_DATE}
                checked={resumeType === RESUME_TYPE.EXACT_DATE}
                onChange={handleReSumeOnChange}
                className="seconds"
              />
            </>
          ) : (
            <>
              <CheckboxCircle
                label={
                  <S.Option>
                    Skip next payment<S.SubLabel> (Resumes {moment(pauseNextPayment).format('MMMM DD')})</S.SubLabel>
                  </S.Option>
                }
                value="next_payment"
                checked={pauseType === 'next_payment'}
                onChange={handlePauseOnChange}
                className="first pause-ref"
              />
              <CheckboxCircle
                label={
                  <S.Option ref={refCalendar}>
                    Pause until
                    <Datetime
                      onFocus={handleOpenCalendar(true)}
                      onBlur={handleOpenCalendar(false)}
                      value={moment(selectedDate)}
                      renderInput={props => {
                        return <DateInput {...props} text={moment(selectedDate).format(DATE_FORMAT)} />;
                      }}
                      dateFormat={'MM-DD'}
                      viewMode="days"
                      open={isShowPause}
                      renderDay={renderDay}
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={handleSelectDate}
                      className="custom-calendar"
                      isValidDate={isValidData}
                      disableOnClickOutside={true}
                    />
                  </S.Option>
                }
                value="end_on_date"
                checked={pauseType === 'end_on_date'}
                onChange={handlePauseOnChange}
                className="seconds pause-ref"
              />
            </>
          )}
        </S.Wrapper>
      }
    ></S.PauseWrapper>
  );
};

const mapStateToProps = state => {
  const { isConfirmModalOpen, confirmModal } = state;
  return {
    isConfirmModalOpen,
    confirmModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PauseSubscriptionPopup);
