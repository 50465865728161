import React, { Component } from 'react';
import ColorThemes from './component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCustomBranding } from 'redux/custom-branding/actions';

const mapState = state => {
  const {
    rootReducer: {
      customBranding: { loading, originalTheme },
    },
    sideBarVisible,
  } = state;

  return { loading, originalTheme, sideBarVisible };
};

const mapDispatch = dispatch => ({
  updateCustomBranding: bindActionCreators(updateCustomBranding, dispatch),
});

export default connect(mapState, mapDispatch)(ColorThemes);
