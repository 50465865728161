import React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';

import * as S from '../style';

import { useMyContext } from '../context/ProgramTemplatesContext';
import DescModalContent from './DescModalContent';

const DescModal = () => {
  const { openFullscreenModal, openDetailModal, toggleDescriptionModal, openDescriptionModal } = useMyContext();
  const opened = openFullscreenModal && openDetailModal && openDescriptionModal;

  return (
    <S.FullscreenModalWrapper
      className={classNames('description', {
        open: openFullscreenModal && openDetailModal && openDescriptionModal,
      })}
    >
      <RootCloseWrapper event="click" disabled={!openDescriptionModal} onRootClose={toggleDescriptionModal}>
        <S.FullscreenModalContainer
          className={classNames('description', {
            open: opened,
          })}
        >
          {openFullscreenModal && openDetailModal && openDescriptionModal && <DescModalContent />}
        </S.FullscreenModalContainer>
      </RootCloseWrapper>
    </S.FullscreenModalWrapper>
  );
};

export default DescModal;
