// libs
import React from 'react';

// components
import RecipeItem from '../RecipeItem';

// styles
import * as S from './style';

const MealItem = ({ meal, cloudfrontList }) => {
  const { name, recipes = [] } = meal || {};

  const hasRecipes = recipes.length > 0;

  if (!hasRecipes) return null;

  return (
    <S.Wrapper className="category-wrapper">
      <div className="category-header">
        <span className="category-name">{name}</span>
      </div>
      <div className="category-container">
        {recipes.map(recipeItem => {
          const { _id, recipe } = recipeItem || {};
          return <RecipeItem key={_id} recipe={recipe} cloudfrontList={cloudfrontList} onClick={() => {}} />;
        })}
      </div>
    </S.Wrapper>
  );
};

export default MealItem;
