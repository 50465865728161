// Libs
import React, { useEffect } from 'react';

// Components
import Header from './components/Header';
import Tabs from './components/Tabs';
import TableList from './components/TableList';
import Pagination from './components/Pagination';

// Utils
import { saveLastAutomationRoute } from 'utils/commonFunction';

// Styles
import * as S from './style';

const OnboardingFlowLibrary = ({ currentTab, resetOnboardingFilter }) => {
  const isOnboardingFlowsTab = currentTab === 0;

  useEffect(() => {
    typeof saveLastAutomationRoute === 'function' && saveLastAutomationRoute('/home/onboarding-flow');

    return () => {
      typeof resetOnboardingFilter === 'function' && resetOnboardingFilter();
    };
  }, []);

  return (
    <S.Wrapper>
      <Header />
      <Tabs />
      <TableList />
      {!isOnboardingFlowsTab && <Pagination />}
    </S.Wrapper>
  );
};

export default OnboardingFlowLibrary;
