import styled from 'styled-components';

import ConfirmModal from 'shared/ConfirmModal';

const baseText = `
    font-family: 'Open Sans';
    color: #202353;
    font-style: normal;
    line-height: 150%;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container.delete-recipe-modal {
    width: 467px !important;
    border-radius: 8px;
    background: #ffffff !important;
    box-shadow: unset !important;

    .confirm-content-header {
      padding: 30px 30px 0;

      .confirm-header-image {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .confirm-header-label {
        ${baseText}
        color: #202353;
        font-size: 17px;
        font-weight: 600;
      }
    }

    .confirm-content-body {
      padding: 16px 30px 31px;

      .confirm-content-label {
        ${baseText}
        color: #202353;
        font-size: 13px;
        font-weight: 400;
      }
    }

    .confirm-actions {
      background: #fff;
      border-top: none;
      padding: 0 19px 30px;

      .confirm-no-button,
      .confirm-yes-button {
        ${baseText}
        font-size: 13px;
        font-weight: 600;
        border-radius: 5px;
        height: 30px;
        min-width: 111px;
      }

      .confirm-no-button {
        background: #ffffff;
        border: 1px solid #d4d7d9;
        min-width: 102px;
        padding: 5px 24px;

        :hover {
          background: #f5f7f9 !important;
        }
      }

      .confirm-yes-button {
        color: #ffffff;
        background: #ea314a;
        border: unset;
        box-shadow: unset;
        padding: 5px 30px;
        margin-right: 11px !important;

        :hover {
          background: rgb(234, 49, 74, 0.9);
        }
      }
    }
  }
`;
