import React from 'react';
import { Icon, Popup, Loader, Label, Image } from 'semantic-ui-react';

// Import from lodash
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

import { Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import { getProgramWorkoutDetail } from 'redux/program_library/program_library.actionCreators';
import ExerciseDetail from 'components/ExerciseDetail';
import AssignWorkoutModal from './AssignWorkoutModal';
import { Workout, ExerciseSet } from 'types/model';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import SectionOverview from 'components/SectionOverview';
import { maximumRenderedExercises } from 'helpers/workout';
import { counterBehindExercises, pluralize } from 'utils/commonFunction';
import styles, { ASSIGNMENT_CONTAINER_HEIGHT, ASSIGNMENT_CONTAINER_MARGIN } from './styles';
import ConfirmModal from 'shared/ConfirmModal';
import ProgramWorkoutDraggable from './ProgramWorkoutDraggable';
import { ExerciseListHeader, WorkoutWrapper } from './style';
import { CDN_URL, SECTION_FORMAT_KEY } from 'constants/commonData';
import { Checkbox } from 'shared/FormControl';
import { SharedTooltip } from 'shared/SharedTooltip';
import './styles.scss';

const LIMIT_SELECTED_WORKOUTS = 20;
export default class ProgramCalendarCell extends React.Component {
  calendarCell;

  constructor() {
    super();
    this.workoutCellRef = {};
    this.state = {
      calendarCellHeight: 0,
    };
  }

  componentDidMount() {
    this.updateCalendarCellHeight();
    window.addEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  componentDidUpdate() {
    if (this.calendarCell && this.calendarCell.offsetHeight !== this.state.calendarCellHeight) {
      this.updateCalendarCellHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  handleMouseEnter = () => {
    const { onMouseEnter, weekIndex } = this.props;
    onMouseEnter && onMouseEnter(weekIndex);
  };

  render() {
    const {
      emptyCell,
      currentWorkouts,
      calendarDayIndex,
      totalWeek,
      calendarType,
      dayIndex,
      weekIndex,
      isEmptyWeek,
    } = this.props;
    const { isViewMore } = this.state;
    const droppableId = `workout_${weekIndex}_${dayIndex}`;

    if (emptyCell) {
      return (
        <div className="calendar-cell" style={{ height: `${100 / calendarType}%` }}>
          <div style={{ background: '#f3f5f8', border: '1px solid transparent' }} className="calendar-cell__content" />
        </div>
      );
    }

    let dayLabel = 'DAY ';
    let dayNo = calendarDayIndex + 1;

    let isShowLabel = true;
    if (dayNo > totalWeek * 7) {
      isShowLabel = false;
    }
    const views = [];
    if (currentWorkouts && currentWorkouts.length > 0) {
      let maxRendered = this.getMaximumRenderedWorkouts();
      let isShowFull = maxRendered >= currentWorkouts.length;
      for (let i = 0; i < currentWorkouts.length && (isViewMore || i < maxRendered); i++) {
        views.push(this.renderAssignment(currentWorkouts[i], !isShowFull || i < currentWorkouts.length - 1, i));
      }
    }

    return (
      <Droppable droppableId={droppableId} type="workout">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={`calendar-cell ${isEmptyWeek && `week-${weekIndex} empty-week`}`}
            style={{ height: `${100 / calendarType}%` }}
            onMouseEnter={this.handleMouseEnter}
          >
            <div
              style={{
                background: isViewMore ? '#ffffff' : '#f3f5f8',
                border: isViewMore ? 'solid 1px #726ee4' : '1px solid transparent',
              }}
              className="calendar-cell__content"
            >
              {isShowLabel ? (
                isViewMore ? (
                  <div className="day-info-over">
                    <div>{dayLabel + dayNo}</div>
                    <div className="add-workout-button" data-tip data-for="tooltip--programCalendarAssignWorkout">
                      <div className="add-new" onClick={this.handleAddWorkout} />
                    </div>
                  </div>
                ) : (
                  <div className="day-info-over view-more">
                    <Label color={'transparent'} style={{ ...styles.day }} className={'day-label'}>
                      {dayLabel + dayNo}
                    </Label>
                    <div className="add-workout-button" data-tip data-for="tooltip--programCalendarAssignWorkout">
                      <div className="add-new" onClick={this.handleAddWorkout} />
                    </div>
                  </div>
                )
              ) : null}
              <ReactTooltip
                className="app-tooltip tooltip--programCalendarAssignWorkout"
                id="tooltip--programCalendarAssignWorkout"
                place="top"
                effect="solid"
              >
                <span>Add Workout</span>
              </ReactTooltip>
              {isViewMore && (
                <Image
                  className="btntask-close-over"
                  src={`${CDN_URL}/images/close_circle.svg`}
                  onClick={() => {
                    this.handleViewMore(false);
                  }}
                  width={16}
                  height={16}
                />
              )}
              {this.renderCellWithViews(views, isViewMore)}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  renderCellWithViews(views, isViewMore) {
    const { copyingWorkout, isCopingWorkouts, isLoadingWorkouts } = this.props;
    const className = `calendar-cell__main-content${copyingWorkout ? ' copying' : ''}`;

    if (views.length == 0) {
      const { dayIndex, weekIndex } = this.props;
      return (
        <Droppable droppableId={`${dayIndex};-1;${weekIndex}`} type="exercise">
          {(provided, snapshot) => (
            <div
              ref={ref => this.onRefInnerCalendarDiv(ref, provided)}
              style={{
                background: isViewMore ? '#ffffff' : '#f3f5f8',
                marginBottom: isViewMore ? 0 : 4,
                overflowY: isViewMore || snapshot.isDragging ? 'auto' : 'unset',
                height: isViewMore ? this.state.calendarCellHeight : 'unset',
              }}
              className={`${className}`}
            >
              {views}
              {this.renderViewMore()}
              {copyingWorkout && this.renderBottom()}
              {isCopingWorkouts && !isLoadingWorkouts && this.renderButtonPasteWorkouts()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    } else {
      return (
        <div
          ref={ref => (this.calendarCell = ref)}
          style={{
            background: isViewMore ? '#ffffff' : '#f3f5f8',
            marginBottom: isViewMore ? 0 : 4,
            overflowY: isViewMore ? 'auto' : 'unset',
            height: isViewMore ? this.state.calendarCellHeight : 'unset',
          }}
          className={className}
        >
          {views}
          {this.renderViewMore()}
          {copyingWorkout && this.renderBottom()}
          {isCopingWorkouts && !isLoadingWorkouts && this.renderButtonPasteWorkouts()}
        </div>
      );
    }
  }

  renderViewMore() {
    const { currentWorkouts } = this.props;
    if (!currentWorkouts) {
      return null;
    }
    const { isViewMore } = this.state;
    const maxRendered = this.getMaximumRenderedWorkouts();
    let remainTasks = currentWorkouts.length - maxRendered;
    if (remainTasks <= 0 || isViewMore) {
      return null;
    }
    let taskText = '+ ' + remainTasks;
    if (remainTasks === 1) {
      taskText += ' more workout';
    } else {
      taskText += ' more workouts';
    }
    return (
      <div
        className="view-more-task"
        onClick={() => {
          this.handleViewMore(true);
        }}
      >
        {taskText}
      </div>
    );
  }

  handleViewMore(isViewMore, id) {
    this.setState({ isViewMore: isViewMore }, () => {
      if (id) {
        this.workoutCellRef[id].scrollIntoView();
      }
    });
  }

  renderBottom = () => {
    const { currentWorkouts, copyingWorkout } = this.props;
    const { isViewMore, calendarCellHeight } = this.state;

    if (isViewMore || !copyingWorkout) {
      return null;
    }

    return (
      <div
        className="calendar-cell_paste-button"
        style={{ marginTop: !currentWorkouts ? calendarCellHeight / 2 - 22 : 2 }}
        onClick={this.handlePaste}
      >
        <div>Paste</div>
      </div>
    );
  };

  handlePasteWorkouts = event => {
    const multiPaste = event.shiftKey;
    const { dayIndex, weekIndex } = this.props;
    const debouncePasteMultipleWorkout = debounce(this.props.handlePasteMultipleWorkout, 100);
    debouncePasteMultipleWorkout({ dayIndex, weekIndex, multiPaste });
  };

  renderButtonPasteWorkouts = () => {
    const { isCopingWorkouts, currentWorkouts } = this.props;
    const { isViewMore, calendarCellHeight } = this.state;

    if (isViewMore || !isCopingWorkouts) {
      return null;
    }

    if (isCopingWorkouts) {
      const marginTop = !currentWorkouts || currentWorkouts.length === 0 ? calendarCellHeight / 2 - 22 : 0;

      return (
        <div className="calendar-cell_paste-button" style={{ marginTop }} onClick={this.handlePasteWorkouts}>
          <div>Paste</div>
        </div>
      );
    }
  };

  handleOpenWorkoutDetail = currentWorkout => {
    const { weekIndex, dayIndex, selectedProgram } = this.props;
    this.props.dispatch(getProgramWorkoutDetail(currentWorkout._id, weekIndex, dayIndex, selectedProgram._id));
  };

  handleAddWorkout = () => {
    this.props.dispatch(
      toggleModal(true, <AssignWorkoutModal dayIndex={this.props.dayIndex} weekIndex={this.props.weekIndex} />),
    );
  };

  updateCalendarCellHeight() {
    if (this.calendarCell) {
      this.setState({
        calendarCellHeight: this.calendarCell.offsetHeight,
        width: this.calendarCell.offsetWidth,
      });
    }
  }

  onRefInnerDiv(ref, currentWorkout, provided) {
    provided.innerRef(ref);
    if (currentWorkout) {
      this.workoutCellRef[currentWorkout._id] = ref;
    }
  }

  onRefInnerCalendarDiv(ref, provided) {
    provided.innerRef(ref);
    this.calendarCell = ref;
  }

  onPopupToggle(popup) {
    if (popup == null) {
      console.log('Closing');
    }
    //should open popup
    this.onPopupToggleAction(popup);
  }

  onPopupToggleAction(popup) {
    this.resetActions(() => {
      setTimeout(() => this.setState({ openingPopup: popup }), 0);
    });
  }

  resetActions = callback => {
    this.setState({
      openingPopup: null,
    });
    callback && callback();
  };

  renderSuperset = (currentWorkout, section, renderable, sectionIndex) => {
    // render exercise outside section : section.type === 'hidden'
    const { dayIndex, weekIndex, updateExerciseLibraryFromProgramCalendar } = this.props;
    const views = [];
    const set = section.exercises ? section.exercises[0] : {};
    set.supersets.map((exSet, idx) => {
      if (idx < renderable) {
        let currentSet = cloneDeep(exSet);
        let originExSet = cloneDeep(exSet);
        const pId = `${sectionIndex}-${idx}-${dayIndex}-${currentWorkout._id}`;
        views.push(
          <div key={idx}>
            <ExerciseDetail
              style={{ marginTop: 16 }}
              containerStyle={{ marginLeft: 12, marginRight: 12 }}
              exerciseSet={new ExerciseSet(currentSet)}
              pId={pId}
              exIndex={idx}
              day={dayIndex}
              week={weekIndex}
              setIndex={0}
              sectionIndex={sectionIndex}
              mode={'program_library_workout_popup'}
              workout={new Workout(currentWorkout)}
              onEditExerciseLibrary={(oldExerciseId, newExerciseLibrary) => {
                const data = {
                  dayIndex,
                  weekIndex,
                  oldExerciseId,
                  newExerciseLibrary,
                  assignmentId: currentWorkout._id,
                };
                updateExerciseLibraryFromProgramCalendar(data);
              }}
            />

            {idx < renderable - 1 ? (
              <div
                style={{
                  backgroundColor: 'gainsboro',
                  height: 1,
                  margin: '0 8px',
                }}
              />
            ) : null}
          </div>,
        );
      }
    });

    return views;
  };

  renderSection = (currentWorkout, section, renderable, sectionIndex) => {
    const { dayIndex } = this.props;
    const draggableId = `${section._id};${dayIndex}`;
    const sClass = section.type !== 'hidden' ? 'ui segment small section-wrapper' : 'ui segment small';
    return (
      <Draggable key={draggableId} draggableId={draggableId} index={sectionIndex} type="exercise">
        {(provided, snapshot) => (
          <div
            className={`${sClass} ${!snapshot.isDragging ? 'exercise-cell' : 'drag'}`}
            style={{ ...provided.draggableProps.style }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {this.props.movingSet ? <Loader /> : null}
            {section.type === 'hidden' ? (
              this.renderSuperset(currentWorkout, section, renderable, sectionIndex)
            ) : renderable ? (
              <SectionOverview
                key={section._id}
                section={section}
                onClick={() => this.handleOpenWorkoutDetail(currentWorkout)}
              />
            ) : null}
          </div>
        )}
      </Draggable>
    );
  };

  renderAssignment = (currentWorkout, isShowOverviewOnly, workoutIndex) => {
    const { isViewMore } = this.state;
    const { dayIndex, weekIndex, calendarDayIndex, isDragging } = this.props;

    if (!currentWorkout) {
      return null;
    }

    const { sections } = currentWorkout;
    const { day } = this.props;
    const views = [];
    const maxRendered = isViewMore ? 100 : isShowOverviewOnly ? 0 : this.getMaximumRenderedExercises(currentWorkout);
    const isCompressed = !isShowOverviewOnly || isViewMore;
    let droppableId = `${dayIndex};${currentWorkout._id};${weekIndex}`;

    if (isCompressed && !isDragging) {
      let totalRendered = 0;

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (!section) {
          continue;
        }

        let numOfExercises = sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
        if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
          numOfExercises = section.exercise_references.length || 1;
        }

        const rendered = Math.min(numOfExercises, maxRendered - totalRendered);

        if (!rendered) {
          break;
        } else {
          totalRendered += rendered;
          views.push(this.renderSection(currentWorkout, section, rendered, i));
        }
      }
    } else {
      const total = sumBy(sections, section => {
        return sumBy(section.exercises, exercise => {
          return exercise.supersets ? exercise.supersets.length : 0;
        });
      });
      droppableId += `;${total}`;
    }

    return (
      <ProgramWorkoutDraggable
        key={currentWorkout._id}
        currentWorkout={currentWorkout}
        day={calendarDayIndex}
        workoutIndex={workoutIndex}
      >
        <Droppable droppableId={droppableId} type="exercise">
          {(provided, snapshot) => {
            const dragOver = snapshot.isDraggingOver ? { background: '#f3f5f8' } : {};

            return (
              <WorkoutWrapper
                ref={ref => this.onRefInnerDiv(ref, currentWorkout, provided)}
                className={'ui segment small workout-cell'}
                style={{ ...styles.exerciseListContainer, ...dragOver }}
              >
                {this.renderExercisesHeader(currentWorkout, isCompressed)}
                <div>{views}</div>
                {this.renderExercisesFooter(currentWorkout, maxRendered)}
                {provided.placeholder}
              </WorkoutWrapper>
            );
          }}
        </Droppable>
      </ProgramWorkoutDraggable>
    );
  };

  renderWorkoutStatus(currentWorkout) {
    if (currentWorkout.status == 2) {
      return <Image className="workout-status-icon" src={`${CDN_URL}/images/checkbox_greenwhite.svg`} />;
    }
    return null;
  }

  renderWorkoutTitle(currentWorkout) {
    let className = 'workout-cell-title';
    let width = this.state.width - 60;
    return (
      <div className={className} style={{ maxWidth: width, width: 'unset' }}>
        {currentWorkout.title ? currentWorkout.title.toUpperCase() : ''}
      </div>
    );
  }

  handleChangeWorkout = (currentWorkout, checked) => {
    const {
      handleSelectWorkout,
      selectedWorkout,
      handleResetSelectWorkout,
      resetCopyItem,
      copyingWorkout,
    } = this.props;
    copyingWorkout && resetCopyItem();

    const selectedLength = selectedWorkout.length;
    if (checked) {
      if (selectedWorkout && selectedLength === LIMIT_SELECTED_WORKOUTS) {
        this.handlePopupLimitWorkout();
      } else {
        handleSelectWorkout([...selectedWorkout, currentWorkout._id]);
      }
    } else {
      const newSelectedWorkout = filter(selectedWorkout, id => id !== currentWorkout._id);
      handleSelectWorkout(newSelectedWorkout);
      if (isEmpty(newSelectedWorkout)) {
        handleResetSelectWorkout();
      }
    }
  };

  handlePopupLimitWorkout = () => {
    const { selectedWorkout = [], toggleConfirmModal } = this.props;
    const selectedWorkoutLength = selectedWorkout.length;
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          headerIcon={`${CDN_URL}/images/warning_purple.svg`}
          title={`Maximum of ${selectedWorkoutLength} workouts selected`}
          content={`You can select up to ${selectedWorkoutLength} workouts at a time. Please complete your action and then select more.`}
          onConfirm={() => {}}
          onDeny={() => {}}
          confirmButtonTitle="Okay"
          hideCancelButton
          noBorder={true}
          hasCloseIcon={true}
          className="calendar--max-select"
        />,
      );
  };

  renderExercisesHeader(currentWorkout, isCompressed) {
    const { dayofWeek, selectedWorkout, permission } = this.props;
    const { openingPopup = '' } = this.state;

    const isChecked = selectedWorkout ? selectedWorkout.includes(currentWorkout._id) : null;
    const dropdownIcon = (
      <div data-tip data-for={`workout-cell-tooltip-${(currentWorkout || {})._id}`}>
        <Icon color={'grey'} name={'ellipsis horizontal'} size={'small'} className="more-actions-icon" />
        {openingPopup !== (currentWorkout || {})._id && (
          <SharedTooltip id={`workout-cell-tooltip-${(currentWorkout || {})._id}`} />
        )}
      </div>
    );
    let direction = dayofWeek == 'Sun' ? 'left' : 'right';
    return (
      <ExerciseListHeader
        isSelectedWorkout={!isEmpty(selectedWorkout)}
        className={isCompressed ? 'exercise-list-header' : 'exercise-list-header-compressed'}
      >
        <div className="workout-title-status" onClick={() => this.handleOpenWorkoutDetail(currentWorkout)}>
          {this.renderWorkoutTitle(currentWorkout)}
          {this.renderWorkoutStatus(currentWorkout)}
        </div>
        {process.env.REACT_APP_COPY_WEEK_ENABLE_V2 && get(permission, 'copy_week') && (
          <Checkbox
            checked={isChecked}
            onChange={event => {
              event.stopPropagation();
              this.handleChangeWorkout(currentWorkout, event.target.checked);
            }}
            className={`workout-cell--checkbox-workout ${isChecked && 'workout-checked'}`}
          />
        )}
        <Popup
          trigger={dropdownIcon}
          on={'click'}
          style={styles.popup}
          open={this.state.openingPopup === currentWorkout._id}
          onOpen={() => this.onPopupToggle(currentWorkout._id)}
          onClose={() => this.onPopupToggle(null)}
          position={direction}
          basic
          className="action-popup calendar-menu-popup"
        >
          {this.renderPopupContent(currentWorkout)}
        </Popup>
      </ExerciseListHeader>
    );
  }

  renderMoveUpWorkout(currentWorkout) {
    const { currentWorkouts } = this.props;
    let idx = currentWorkouts.findIndex(item => {
      return item._id == currentWorkout._id;
    });
    let count = currentWorkouts.length;
    if (count >= 2) {
      if (idx == count - 1 || (count >= 3 && idx > 0 && idx < count - 1)) {
        return (
          <li
            style={styles.dropdownListItem}
            className={'list-item'}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleMoveUp(currentWorkout, idx);
            }}
          >
            <div style={styles.dropdownListItemContainer}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/workout_up.svg`} />{' '}
              <div style={styles.dropdownListItemTitle}>Move up</div>
            </div>
          </li>
        );
      }
    }
    return null;
  }

  renderMoveDownWorkout(currentWorkout) {
    const { currentWorkouts } = this.props;
    let idx = currentWorkouts.findIndex(item => {
      return item._id == currentWorkout._id;
    });
    let count = currentWorkouts.length;
    if (count >= 2) {
      if (idx === 0 || (count >= 3 && idx > 0 && idx < count - 1)) {
        return (
          <li
            style={styles.dropdownListItem}
            className={'list-item'}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleMoveDown(currentWorkout, idx);
            }}
          >
            <div style={styles.dropdownListItemContainer}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/workout_down.svg`} />{' '}
              <div style={styles.dropdownListItemTitle}>Move down</div>
            </div>
          </li>
        );
      }
    }
    return null;
  }

  handleMoveDown = (currentWorkout, index) => {
    const { dayIndex, selectedProgram, weekIndex, currentWorkouts } = this.props;
    let newIndex = index + 1;
    if (newIndex > currentWorkouts.length - 1) {
      return;
    }

    let params = {
      weekIndex: weekIndex,
      dayIndex: dayIndex,
      programId: selectedProgram._id,
      workoutId: currentWorkout._id,
      newIndex: newIndex,
      fromIndex: index,
    };

    this.props.arrangeWorkout(params);
  };

  handleMoveUp = (currentWorkout, index) => {
    let newIndex = index - 1;
    if (newIndex < 0) {
      return;
    }
    const { dayIndex, selectedProgram, weekIndex } = this.props;
    let params = {
      weekIndex: weekIndex,
      dayIndex: dayIndex,
      programId: selectedProgram._id,
      workoutId: currentWorkout._id,
      newIndex: newIndex,
      fromIndex: index,
    };
    this.props.arrangeWorkout(params);
  };

  renderPopupContent(currentWorkout) {
    return (
      <ul style={styles.dropdownList}>
        {this.renderMoveUpWorkout(currentWorkout)}
        {this.renderMoveDownWorkout(currentWorkout)}
        <li
          style={styles.dropdownListItem}
          className={'list-item'}
          onClick={() => {
            this.setState({ openingPopup: '' });
            this.handleSaveToLibraryAction(currentWorkout);
          }}
        >
          <div style={styles.dropdownListItemContainer}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/save.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Save to library</div>
          </div>
        </li>
        <li
          style={styles.dropdownListItem}
          className={'list-item'}
          onClick={() => {
            this.setState({ openingPopup: '' });
            this.handleCopyAction(currentWorkout);
          }}
        >
          <div style={styles.dropdownListItemContainer}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/duplicate.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Copy</div>
          </div>
        </li>
        <li
          style={styles.dropdownListItem}
          className={'list-item'}
          onClick={() => {
            this.setState({ openingPopup: '' });
            this.handleDeleteAction(currentWorkout);
          }}
        >
          <div style={styles.dropdownListItemContainer}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/delete.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Delete</div>
          </div>
        </li>
      </ul>
    );
  }

  handleSaveToLibraryAction = currentWorkout => {
    this.props.saveItemToWorkoutLibrary(currentWorkout);
  };

  handleCopyAction = currentWorkout => {
    const {
      selectedWorkout,
      selectedWeek,
      handleResetSelectWorkout,
      handleResetSelectedWeek,
      copyCurrenWorkout,
    } = this.props;

    if (currentWorkout) {
      if (!isEmpty(selectedWorkout)) {
        handleResetSelectWorkout();
      }

      if (!isEmpty(selectedWeek)) {
        handleResetSelectedWeek();
      }

      copyCurrenWorkout(currentWorkout);
    }
  };

  handlePaste = event => {
    const { copyingWorkout, dayIndex, selectedProgram, weekIndex, pasteCopiedWorkout } = this.props;
    const multiPaste = event.shiftKey;
    let params = {
      weekIndex: weekIndex,
      dayIndex: dayIndex,
      programId: selectedProgram._id,
      workoutId: copyingWorkout._id,
    };
    pasteCopiedWorkout(params, multiPaste);
  };

  handleDeleteAction = currentWorkout => {
    if (currentWorkout) {
      const { dayIndex, weekIndex, selectedProgram } = this.props;
      const params = {
        weekIndex: weekIndex,
        dayIndex: dayIndex,
        programId: selectedProgram._id,
        workoutId: currentWorkout._id,
      };

      this.props.dispatch(
        toggleConfirmModal(
          true,
          <ConfirmModal
            title="Delete workout"
            content={'Are you sure that you want to delete this workout?'}
            onConfirm={() => {
              const { removeWorkout, selectedWorkout, handleSelectWorkout } = this.props;
              const newSelectedWorkouts = filter(selectedWorkout, id => id !== get(currentWorkout, '_id'));
              handleSelectWorkout(newSelectedWorkouts);
              return removeWorkout(params);
            }}
          />,
        ),
      );
    }
  };

  renderExercisesFooter = (currentWorkout, maxRendered) => {
    const { dayIndex, weekIndex, updateExerciseLibraryFromProgramCalendar } = this.props;
    const popupId = `new-${this.props.dayIndex}-${currentWorkout._id}`;
    const footerIcon = (
      <ExerciseDetail
        mode={'program_library_workout_new_popup'}
        workout={new Workout(currentWorkout)}
        pId={popupId}
        onEditExerciseLibrary={(oldExerciseId, newExerciseLibrary) => {
          const data = {
            dayIndex,
            weekIndex,
            oldExerciseId,
            newExerciseLibrary,
            assignmentId: currentWorkout._id,
          };
          updateExerciseLibraryFromProgramCalendar(data);
        }}
      />
    );

    const remainTasks = counterBehindExercises(currentWorkout, maxRendered);

    if (!currentWorkout || currentWorkout.sections.length === 0 || remainTasks < 1) {
      return <div style={styles.exercisesListFooter}>{footerIcon}</div>;
    }

    const extraStyle = {
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    let taskText = `+ ${remainTasks} more ${pluralize('exercise', remainTasks)}`;

    return (
      <div style={{ ...styles.exercisesListFooter, ...extraStyle }}>
        <div
          style={{
            cursor: 'pointer',
            marginLeft: 4,
            fontSize: 11,
            color: '#5a636c',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            this.handleViewMore(true, currentWorkout._id);
          }}
        >
          {taskText}
        </div>
        {footerIcon}
      </div>
    );
  };

  getMaximumRenderedWorkouts() {
    const { calendarType, selectedWorkout, weekIndex, totalWeek, currentWeek } = this.props;
    const endWeek = Math.min(currentWeek + calendarType, totalWeek);
    const needOverlapWeek = (endWeek - currentWeek) % calendarType === 0 && endWeek;

    const menuBottomBar = weekIndex + 1 === needOverlapWeek && !isEmpty(selectedWorkout) ? 42 : 0; // 42: care overlap menuBottomBar

    const wrapperMargin = 4 + 2; // 2: border width
    const pasteButtonHeight = 22;
    const containerHeight = this.state.calendarCellHeight - (wrapperMargin + pasteButtonHeight) - menuBottomBar;
    const workoutHeight = ASSIGNMENT_CONTAINER_HEIGHT + ASSIGNMENT_CONTAINER_MARGIN;
    const maxHeigh = parseInt(containerHeight / workoutHeight, 10);
    return maxHeigh;
  }

  getMaximumRenderedExercises = workout => {
    const { calendarType, selectedWorkout, weekIndex, currentWeek, totalWeek } = this.props;

    const endWeek = Math.min(currentWeek + calendarType, totalWeek);
    const needOverlapWeek = (endWeek - currentWeek) % calendarType === 0 && endWeek;
    const menuBottomBar = weekIndex + 1 === needOverlapWeek && !isEmpty(selectedWorkout) ? 42 : 0;

    let factor = 6 + 22;
    const workoutHeaderHeight = 22;
    const workoutFooterHeight = 27;
    const assignmentContainerHeight = ASSIGNMENT_CONTAINER_HEIGHT + ASSIGNMENT_CONTAINER_MARGIN;

    factor += workoutHeaderHeight + workoutFooterHeight;
    factor += (this.props.currentWorkouts.length - 1) * assignmentContainerHeight;

    // 10: first and last card not margin top and bottom
    const containerHeight = this.state.calendarCellHeight - factor + 10 - menuBottomBar;
    return maximumRenderedExercises(workout, containerHeight);
  };

  getExerciseNameById(exId) {
    const { allExercises } = this.props;
    const found = allExercises.filter(itm => itm._id === exId);
    if (found.length > 0) {
      return found[0].title;
    }

    return '';
  }
}
