import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

export const ModalWrapper = styled(Modal)`
  &.ui.modal > :last-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    padding: 30px;
  }

  .ui.modal.autoflow-add-clients-modal {
    border-radius: 8px;
    width: 490px !important;
    max-width: 100% !important;
  }

  .ui.modal.autoflow-add-clients-modal > .header {
    font-family: Open Sans;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    color: #000000;
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ui.modal.autoflow-add-clients-modal > .content {
    padding: 0 20px 10px;
  }

  .ui.modal.autoflow-add-clients-modal > .actions {
    background: #fff;
    border: none;
    padding: 20px;
  }

  .multi-select-container.multi-select--autoflow__clients .multi-select__value-container {
    padding: 9px 15px;
  }

  .multi-select-container .multi-select__control {
    border-color: #d4d7d9;
  }

  .multi-select--autoflow__clients .multi-select__placeholder {
    font-size: 12px;
    line-height: 12px;
    color: #777777;
    margin-top: 2px;
  }

  .multi-select--autoflow__clients .multi-select__input {
    color: #323c47;
  }
`;

export const ActionButton = styled(Button)`
  padding: 12px 30px 12px 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  .choose-start-date__form-item {
    .custom-date-input.form-control {
      line-height: 18px;
      background: #fafbfc;
      border: 1px solid #d4d7d9;
      border-radius: 5px;
      padding: 11px 14px;
    }

    .ui.dropdown.new-ui,
    .ui.dropdown.selection.new-ui {
      line-height: 18px;
      background: #fafbfc;
      border: 1px solid #d4d7d9;
      border-radius: 5px;
      padding: 11px 14px;
    }
  }
  .choose-start-date__form-label {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
  }
`;

export const Heading = styled.div`
  margin-bottom: 25px;

  .choose-start-date__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }

  .choose-start-date__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #323c47;
    margin-top: 5px;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 7px 42px;
  }
`;

export const Wrapper = styled.div``;
