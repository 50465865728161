import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button as ButtonElement } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import RegularWorkoutInactiveIcon from 'assets/icons/regular-workout-inactive.svg';
import RegularWorkoutActiveIcon from 'assets/icons/regular-workout-active.svg';
import VideoWorkoutInactiveIcon from 'assets/icons/video-workout-inactive.svg';
import VideoWorkoutActiveIcon from 'assets/icons/video-workout-active.svg';

export const ModalWrapper = styled(Modal)`
  border-radius: 8px;
  max-width: 684px;

  .actions {
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    border-top: none !important;
    background-color: #ffffff !important;
  }

  .header {
    padding: 24px 24px 0px 24px !important;
    border-radius: 8px;
  }

  .content {
    border-radius: 8px;
    padding: 0px 24px 24px 24px !important;
    max-height: calc(100vh - 175px);
    overflow: auto;
  }

  .modal-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #202353;
  }

  .upgradeModal__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__description {
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__upgradeBtn {
    margin-top: 30px;
    text-align: center;
  }
  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 213px;
    margin-top: 20px;
    column-gap: 20px;
    .button {
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      border-radius: 5px;
      text-align: center;
      padding-top: 24px;
      cursor: pointer;
      :hover {
        border: 1px solid #5158cf;
        .icon-container svg path {
          stroke: #5158cf;
        }
        .regular {
          background-image: url(${RegularWorkoutActiveIcon}) !important;
        }
        .video {
          background-image: url(${VideoWorkoutActiveIcon}) !important;
        }
      }
      .icon-container {
        width: 61px;
        height: 61px;
        margin: auto;
        background-color: rgba(32, 35, 83, 0.1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        background-repeat: no-repeat;
        background-position: center;
        &.regular {
          background-image: url(${RegularWorkoutInactiveIcon});
        }
        &.video {
          background-image: url(${VideoWorkoutInactiveIcon});
        }
      }
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #000000;
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #202353;
        margin: auto;
        margin-top: 10px;
        &.regular-text {
          width: 164px;
        }
        &.video-text {
          width: 216px;
        }
      }
    }
  }
  .text {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #5f5f5f;
  }
`;

export const SubmitButton = styled(ButtonElement)`
  width: 153px;
  margin-left: 10px;

  &:hover,
  &:focus,
  &:active {
    background: #5559ff;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 1;
      background-color: #c4c4c4;

      &:hover,
      &:focus,
      &:active {
        background: #c4c4c4;
      }
    `}
`;

export const CancelButton = styled(ButtonElement)`
  width: 100px;
`;

export const AddWorkoutContainer = styled.div`
  background: rgba(167, 173, 184, 0.1);
  border-radius: 4px;
  padding: 10px;
  .workout-select__menu-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .workout-select__menu {
    width: 363px;
    border: 1px solid #dadfea;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .workout-select__control {
    cursor: pointer;
    border: 1px solid #d4d7d9;
    box-sizing: border-box;
    border-radius: 5px;
    background: #fafbfc;
    height: 36px;
    padding: 0 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);

    :hover {
      border-color: #5158cf;
    }

    &.workout-select__control--menu-is-open,
    &.workout-select__control--is-focused {
      border-color: #5158cf !important;
    }

    .workout-select__indicator-separator {
      display: none;
    }

    .workout-select__dropdown-indicator svg {
      width: 12px;
      height: 12px;
    }

    .workout-select__input {
      transform: translate(0px, -1px);
      input {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #777777;
        opacity: 0.5;
      }
    }
    .workout-select__placeholder,
    .workout-select__single-value {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #777777;
      opacity: 0.5;
    }
    .workout-select__value-container {
      transform: translateY(1px);
    }
  }
`;
export const CustomPlaceholderContainer = styled.div`
  display: flex;
  span {
    margin-left: 10px;
  }
`;

export const CustomOption = styled.div`
  height: 56px;
  border-radius: 4px;
  margin: 0px 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background: #ecebfe;
    .workout-title {
      color: #5158cf;
    }
    .workout-info {
      color: #5158cf;
    }
  }
  .base-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .workout-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .workout-info {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #818da1;
  }
  .extra-info-container {
    width: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 55px;
  }
  .last-used {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #818da1;
  }
`;

export const SelectedWorkout = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 10px;
  :hover {
    .actions-container {
      opacity: 1;
    }
  }
  .left {
    display: flex;
  }
  .background-container {
    height: 50px;
    width: 88px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafbfc;
    border: 1px solid #dadfea;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
    svg {
      width: 27px;
      path {
        fill: #d3d7da;
      }
      rect {
        stroke: #d3d7da;
      }
    }
  }
  .background-container.hide-border {
    border-width: 0px;
  }
  .workout-base-info-container {
    .title {
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      margin-top: 7px;
      margin-bottom: 4px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-width: 380px;
    }
    .extra-info-text {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #818da1;
    }
  }
  .actions-container {
    transition: ease opacity 0.1s;
    display: flex;
    opacity: 0;
    width: 39px;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
      width: 12px;
      height: 12px;
    }
    svg.gray-fill {
      path {
        fill: #758094;
      }
    }
  }
`;

export const AddVideoContainer = styled.div`
  background: rgba(167, 173, 184, 0.1);
  border-radius: 4px;
  padding: 10px 25px 10px 10px;
`;

export const Title = styled.p`
  color: #6a778a;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  margin-top: 24px;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
