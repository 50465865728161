import React, { useMemo } from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import ConfirmModal from 'shared/ConfirmModal';
import {
  checkingValidUrl,
  timeSince,
  replaceImageURL,
  isTeamAdmin,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_thin.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import * as S from './style';
import { isS3FileURL } from 'utils/validations';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';
import { OwnershipIconStatus, ShareOwnerAvatar, ShareSettingModal } from 'shared/AssetsShareSetting';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { SharedTooltip } from 'shared/SharedTooltip';

const MAX_LENGTH = 100;

const getLevelLabel = item => {
  const currentLabel = get(item, 'level.item.name', '');
  return currentLabel === 'All' ? 'All levels' : currentLabel;
};

const getLabelDuration = item => get(item, 'duration.item.name', '');

function renderLabels(item) {
  const labels = get(item, 'labels', []);
  if (!isEmpty(labels)) {
    return sortBy(labels, obj => obj.label.order).map((it, idx) => (
      <S.ChipText key={`item-chip-${(it.item || {})._id || idx}`}>{get(it, 'item.name', '')}</S.ChipText>
    ));
  }
  return <></>;
}

export function cutString(str) {
  return str.length > MAX_LENGTH ? str.substring(0, MAX_LENGTH) + '... ' : str;
}

export const Item = ({
  item = {},
  onRemove,
  customBrandingBackground,
  onEdit,
  onDuplicate,
  user,
  toggleModal,
  updateShareStatus,
  cloudfrontList,
}) => {
  const author = typeof item.author_info === 'object' ? item.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = item.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;
  const workoutLibraryId = item._id;

  const thumbnail = useMemo(() => {
    if (item.background_thumbnail && checkingValidUrl(item.background_thumbnail)) {
      return item.background_thumbnail;
    }
    if (item.background) {
      return isS3FileURL(item.background) ? replaceImageURL(item.background) : item.background;
    }
    return get(customBrandingBackground, 'src', '');
  }, [item, customBrandingBackground]);

  const renderTriggerIcon = ({ open }) => (
    <div data-tip data-for="more-options-tooltip">
      <S.TriggerIcon className="trigger-icon" active={!!open} />
      <SharedTooltip id="more-options-tooltip" />
    </div>
  );

  const handleSaveShare = ({ params } = {}) => {
    typeof toggleModal === 'function' && toggleModal(false);
    if (!params || !workoutLibraryId) return;
    typeof updateShareStatus === 'function' && updateShareStatus({ params, workoutLibraryId });
  };

  const onShare = () => {
    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <ShareSettingModal
          user={user}
          owner={author}
          shareStatus={item.share}
          closeModal={onCloseModal}
          onSave={handleSaveShare}
        />,
      );
  };

  const onCloseModal = () => {
    typeof toggleModal === 'function' && toggleModal(false);
  };

  const handleOpenShareSetting = event => {
    event.preventDefault();
    event.stopPropagation();
    onShare();
  };

  return (
    <>
      <S.WorkoutInfo>
        <S.WorkoutInfoDetail>
          <S.MediaWrapper thumbnail={convertS3UrlToCloudFrontUrl(thumbnail, cloudfrontList, true)}>
            {item.type === 'video' && <S.VideoIcon />}
          </S.MediaWrapper>
          <S.Desc>{cutString(item.title)}</S.Desc>
        </S.WorkoutInfoDetail>
        <S.ChipWrapper>{renderLabels(item)}</S.ChipWrapper>
      </S.WorkoutInfo>
      <S.LevelLabel>{getLevelLabel(item)}</S.LevelLabel>
      <S.DurationLabel>{getLabelDuration(item)}</S.DurationLabel>
      <S.MostRecentLabel>{item.last_activity_at ? timeSince(new Date(item.last_activity_at)) : '1s'}</S.MostRecentLabel>
      <S.ActionsWrapper>
        {author && !isNoOwner ? (
          <ShareOwnerAvatar
            user={author}
            tooltipId={`avatar-tooltip-${get(item, '_id')}`}
            size={25}
            cloudfrontList={cloudfrontList}
          />
        ) : (
          <div />
        )}
        <OwnershipIconStatus
          place="top"
          itemId={workoutLibraryId}
          share={item.share}
          onItemClick={handleOpenShareSetting}
        />
        <DropDown
          className="category-actions-dropdown"
          triggerIcon={renderTriggerIcon}
          direction="left"
          shouldCheckItem
        >
          <div className="dropdown-area">
            {isCreatorOrOwnerOrAdmin && (
              <Option onClick={onEdit(workoutLibraryId)}>
                <EditIcon />
                <span>Edit</span>
              </Option>
            )}
            <Option onClick={onDuplicate(workoutLibraryId)}>
              <DuplicateIcon />
              <span>Duplicate</span>
            </Option>
            {isCreatorOrOwnerOrAdmin && (
              <Option onClick={onShare}>
                <ShareIcon className="stroke-unset" />
                <span>Sharing settings</span>
              </Option>
            )}
            {isCreatorOrOwnerOrAdmin && (
              <Option onClick={onRemove(workoutLibraryId)}>
                <DeleteIcon />
                <span>Remove</span>
              </Option>
            )}
            <div className="dropdown-arrow" />
          </div>
        </DropDown>
      </S.ActionsWrapper>
    </>
  );
};

const TableContent = ({
  workouts = [],
  removeWorkout,
  toggleConfirmModal,
  customBrandingBackground,
  push,
  toggleModal,
  user,
  updateShareStatus,
  cloudfrontList,
}) => {
  const handleRemoveWorkout = id => {
    typeof removeWorkout === 'function' && removeWorkout(id);
  };

  const handleConfirmRemove = id => () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove"
        content="Are you sure you want to delete this workout?  It will be removed from any Collection that it is currently in."
        onConfirm={() => handleRemoveWorkout(id)}
        onDeny={() => {}}
        newStyle
        largeSpacing
        hasHoverState
        confirmButtonTitle="Remove"
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
      />,
    );
  };

  const handleEdit = id => () => {
    push(`/home/on-demand-workouts/${id}`);
  };

  const handleDuplicate = id => () => {
    push(`/home/on-demand-workouts/${id}/duplicate`);
  };

  const renderItem = it => (
    <S.TableRow key={`item-${it._id}`} withHoverStyle onClick={handleEdit(it._id)}>
      <Item
        item={it}
        onRemove={handleConfirmRemove}
        cloudfrontList={cloudfrontList}
        customBrandingBackground={customBrandingBackground}
        push={push}
        onEdit={handleEdit}
        onDuplicate={handleDuplicate}
        user={user}
        toggleModal={toggleModal}
        updateShareStatus={updateShareStatus}
      />
    </S.TableRow>
  );

  return <S.TableBody>{workouts.map(renderItem)}</S.TableBody>;
};

export default TableContent;
