import _ from 'lodash';
import { Types } from './calendar.actionCreators';

export const DEFAULT_STATE = {
  view_mode: 'assignment',
  loading: false,
  selectedWeek: null,
  selectedWorkout: [],
  excludeWorkout: [],
  isCopySelectedWorkout: false,
  weekCopyId: null,
  is_hide_workout: false,
};

const CalendarReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'CHANGE_CALENDAR_VIEW_TYPE':
      return Object.assign({}, state, {
        view_mode: action.payload.mode,
      });

    case Types.GET_PDF_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case Types.GET_PDF_FINISH: {
      return Object.assign({}, state, {
        loading: false,
      });
    }
    case Types.CLIENT_CALENDAR_TRAINING_SELECTED_WEEK:
      return Object.assign({}, state, {
        selectedWeek: action.payload.selectedWeek,
        weekCopyId: action.payload.weekCopyId,
      });
    case Types.CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WEEK:
      return Object.assign({}, state, { selectedWeek: null });
    case Types.CLIENT_CALENDAR_TRAINING_COPY_WEEK_REQUEST:
      if (action.payload.multiPaste) return state;
      return Object.assign({}, state, { selectedWeek: null });
    case Types.CLIENT_CALENDAR_TRAINING_SELECTED_WORKOUT:
      return Object.assign({}, state, {
        selectedWorkout: action.payload.workoutIds,
        excludeWorkout: action.payload.excludeWorkoutIds,
      });
    case Types.CLIENT_CALENDAR_TRAINING_RESET_SELECTED_WORKOUT:
      return Object.assign({}, state, {
        selectedWorkout: [],
        excludeWorkout: [],
        isCopySelectedWorkout: false,
      });
    case Types.CLIENT_CALENDAR_TRAINING_COPY_WORKOUT:
      return Object.assign({}, state, { isCopySelectedWorkout: true });
    case Types.CLIENT_CALENDAR_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST:
      if (action.payload.multiPaste) return state;
      return Object.assign({}, state, {
        selectedWorkout: [],
        excludeWorkout: [],
        isCopySelectedWorkout: false,
      });
    case Types.CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS:
      return Object.assign({}, state, {
        selectedWorkout: [],
        excludeWorkout: [],
        isCopySelectedWorkout: false,
      });
    case Types.CLIENT_CALENDAR_TRAINING_GET_ID_SINGLE_WORKOUT: {
      const currentSelectedWorkout = state.selectedWorkout.filter(id => id !== action.payload);
      return Object.assign({}, state, {
        selectedWorkout: currentSelectedWorkout,
        isCopySelectedWorkout: state.isCopySelectedWorkout && !_.isEmpty(currentSelectedWorkout),
      });
    }
    case Types.CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST: {
      return Object.assign({}, state, { isCopySelectedWorkout: false });
    }
    case Types.CLIENT_CALENDAR_TRAINING_HIDE_WORKOUT: {
      return Object.assign({}, state, { is_hide_workout: action.payload });
    }
    default:
      return state;
  }
};

export default CalendarReducer;
