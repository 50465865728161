import styled, { css } from 'styled-components';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

export const WrapperAttachment = styled.div`
  display: flex !important;
  align-items: center;
  position: relative;
  justify-content: center;
  max-width: 548px !important;
  height: 535px !important;
  @media (max-height: 762px) {
    max-width: unset !important;
    height: unset !important;
  }
`;

export const DeleteButton = styled.button`
  align-items: center;
  display: flex;
  height: 28px;
  top: 16px;
  right: 16px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 1;
  ${props =>
    props.isCypress &&
    css`
      display: block;
      padding: 0 5px;
    `}

  svg {
    width: 16px;
    height: 16px;
  }

  :hover {
    background: #fff;
    cursor: pointer;
    svg g {
      stroke: #5e59ff;
    }
  }
`;

export const TagWrapper = styled.div`
  display: none;
  width: 100px;
  height: auto;
  position: absolute;
  top: 16px;
  left: 66px;
  transform: translateX(-50%);
  z-index: 1;
  ${props =>
    props.isCypress &&
    css`
      display: block;
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  img {
    display: block;
    border-radius: 6px;
    object-fit: cover;
    max-width: 548px;
    max-height: 535px;
    margin: auto;
    @media (max-height: 762px) {
      object-fit: contain;
      max-width: unset;
      max-height: unset;
      margin: unset;
    }
  }

  .posted-date {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.2px;
    bottom: 16px;
    left: 16px;
    position: absolute;
  }

  :hover {
    ${TagWrapper}, ${DeleteButton} {
      display: flex;
    }
  }
`;

export const S3ImageWithFallbackWrapper = styled(S3ImageWithFallback)`
  display: none;
  img {
    max-height: 450px;
    max-width: 444px;
    min-width: 280px;
    min-height: 234px;
    width: unset;
    @media (max-height: 762px) {
      min-width: unset;
      min-height: unset;
      max-height: calc(100vh - 238px);
    }
  }
  ${props =>
    props.loaded &&
    css`
      display: flex;
    `}
`;

export const PostedDate = styled.span`
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
  bottom: 16px;
  left: 16px;
  position: absolute;
  z-index: 1;
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 132px;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const MockupImg = styled.div`
  width: 302px;
  height: 535px;
  background-color: #f5f5f5;
  border-radius: 8px;
`;
