import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import styled from 'styled-components';
import { axiosInstanceWithoutRefreshToken } from 'configs/request';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .resetPassword__heading {
    padding: 20px;
    margin-top: 50px;

    img {
      height: 36px;
    }
  }

  .resetPassword__body {
    border-top: solid 1px #dee3e6;
    height: 1px;
    margin-bottom: 10px;
    text-align: center;
    padding: 20px;

    .no-border {
      border: none;
    }

    .resetPassword__title {
      text-align: center;
      font-weight: bold;
      font-size: 26px;
      line-height: 44px;
      color: rgb(25, 23, 83);
    }

    .resetPassword__destination {
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      color: rgb(25, 23, 83);
      margin-bottom: 40px;
      text-align: center;
      max-width: 472px;
    }
  }
`;

export default function ConfirmResetPassword() {
  const [state, setState] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    axiosInstanceWithoutRefreshToken
      .post('/api/auth/confirm-reset-password', { token: params.get('token') })
      .then(response => {
        if (response.data) {
          setState(response.data);
        }
      })
      .catch(error => {
        setState(error.response.data);
      });
  }, []);

  const isEnableWhiteLabel = _.get(state, 'white_label', false);

  return (
    !_.isNil(state) && (
      <Wrapper>
        <div className="resetPassword__heading">
          {!isEnableWhiteLabel && (
            <img src="https://everfit-web-asset.s3.us-west-1.amazonaws.com/images/logo.png" alt="" />
          )}
        </div>
        <div className={classNames('resetPassword__body', { 'no-border': isEnableWhiteLabel })}>
          <div className="resetPassword__title">Successful Password Reset</div>
          <div className="resetPassword__destination">
            {/* {state.error ? state.message : 'Success, a new temporary password was sent to your email.'} */}
            <div>A temporary password has been sent to your email address.</div>
            <div>Please check your inbox and sign in to change your password as soon as possible.</div>
          </div>
        </div>
      </Wrapper>
    )
  );
}
