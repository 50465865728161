import styled from 'styled-components';
import LoadingIndicator from 'shared/LoadingIndicator';

export const LeftPanel = styled.div`
  width: 362px;
  background-color: #f3f5f8;
  padding-bottom: 24px;
`;

export const List = styled.div`
  position: relative;
  height: calc(100% - 108px);
  overflow-y: scroll;
  padding: 0 14px 22px 22px;
  ::-webkit-scrollbar {
    width: 8px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    border-radius: 24px !important;
  }
  ::-webkit-scrollbar-track {
    margin-top: 2px;
    margin-bottom: 24px;
  }
  .loading-photo {
    padding-top: 0;
  }
`;

export const Empty = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: -0.02em;
  opacity: 0.4;
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingIndicatorWrapper = styled(LoadingIndicator)`
  padding-top: 0;
  top: calc(50% - 24px);
  left: 50%;
  transform: translate(-50%, -50%);
  span {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    color: #8c8c8c;
    opacity: 1;
  }
  .ui.loader:after {
    border-color: #5e59ff #fbfbff #fbfbff !important;
    border-width: 3px;
    width: 32px;
    height: 32px;
  }
  .ui.loader:before {
    width: 32px;
    height: 32px;
    border-width: 3px;
  }
  .ui.small.text.loader {
    padding-top: 37px;
  }
`;
