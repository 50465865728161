// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { get, isEmpty, groupBy, map, sumBy, round } from 'lodash';
import classNames from 'classnames';

// Constants
import { LEGEND_STAGE_ORDER, SLEEP_VIEW } from './constants';
import { PERIOD_GROUP } from 'components/BodyMetricChartNew/constants';

// Helpers
import { getTimeLabel } from 'components/BodyMetricChartNew/chartHelper';
import { sortByOrder } from './helpers';

import { SleepState } from './HourlyChartTooltip';

// Styles
import { SleepStates } from './SleepStyle';
import * as S from '../../styles';

export const convertMinToFromHourMin = value => {
  if (value < 60) return { hour: 0, min: value };

  const hour = Math.floor(value / 60);
  const min = Math.round(value - hour * 60);
  return { hour, min };
};

const RenderTime = ({ minutes }) => {
  const { min, hour } = convertMinToFromHourMin(minutes);
  return (
    <p className="tooltip-value">
      {hour > 0 && (
        <S.UnitValue>
          {hour}
          <S.UnitStyle> h </S.UnitStyle>
        </S.UnitValue>
      )}
      {min > 0 && (
        <S.UnitValue>
          {round(min)}
          <S.UnitStyle> min</S.UnitStyle>
        </S.UnitValue>
      )}
    </p>
  );
};

const CustomTooltipContentVertical = props => {
  const {
    dataPayload: dataPayloadProps,
    rangeTime = false,
    sleepSettings,
    isOverview = false,
    tooltipPosition,
    isLessThan1Hour = false,
  } = props;
  const { viewBy } = sleepSettings;

  const dataPayload = useMemo(() => {
    const { rangeValues = [], dataType = [] } = dataPayloadProps;
    if (isLessThan1Hour) {
      return {
        ...dataPayloadProps,
        rangeValues: rangeValues.map(item => item / 60),
        dataType: dataType.map(item => ({ ...item, value: item.value / 60 })),
      };
    }
    return dataPayloadProps;
  }, [dataPayloadProps]);

  const { dataType = [] } = !isEmpty(dataPayload) ? dataPayload : get(props, 'payload.[0].payload', {});

  const { payload, dataRangeTime, positionVertical, widthCurrentTarget } = tooltipPosition || {};
  const { period_group = '' } = payload || {};

  const [isOutsideRight, setIsOutsideRight] = useState(false);

  useEffect(() => {
    const checkElementOutsideScreen = element => {
      const rect = element.getBoundingClientRect();
      if (rect) {
        const WIDTH_BUFFER_LEFT = 50;
        setIsOutsideRight(window.innerWidth - WIDTH_BUFFER_LEFT < rect.right);
      }
    };
    const elementSleepTooltipContent = document.getElementById('sleep-tooltip-content');
    if (elementSleepTooltipContent) {
      checkElementOutsideScreen(elementSleepTooltipContent);
    }
  }, [payload && payload._id]);

  const label = useMemo(() => getTimeLabel(dataPayload || {}), [dataPayload]);

  const isDurationView = viewBy === SLEEP_VIEW.DURATION;
  const isTimeView = viewBy === SLEEP_VIEW.TIME;

  const finalDataType = useMemo(() => {
    if (isDurationView) {
      const newData = [...dataType];
      const groupedData = groupBy(newData, 'type');
      const newTypes = map(groupedData, (items, type) => ({
        type,
        value: sumBy(items, 'value'),
      }));
      return newTypes;
    }

    if (isTimeView) {
      const isManual = get(dataRangeTime, 'isManual', false);
      const newData = !isEmpty(dataRangeTime) ? get(dataRangeTime, 'dataType', []) || [] : [];
      const groupedData = groupBy(newData, 'type');
      const result = map(groupedData, (entries, type) => ({
        type,
        value: sumBy(entries, entry => (isManual ? entry.value[1] - entry.value[0] : entry.value)),
      }));

      return result;
    }
    return [];
  }, [rangeTime, dataType]);

  const sortedNewTypes = sortByOrder(finalDataType, 'type', LEGEND_STAGE_ORDER);

  const renderSleepState = ({ type, value }, index) => {
    const minutes = value * 60;

    return <SleepState key={[type, index].join('-')} minutes={round(minutes)} type={type} />;
  };

  const getValueTime = () => {
    const { startDate = '', endDate = '', rangeValues = [] } = !isEmpty(dataRangeTime) ? dataRangeTime : dataPayload;

    if (isDurationView) {
      const [minValue, maxValue] = rangeValues;
      const hourValue = Math.abs(maxValue - minValue);
      const minutes = hourValue * 60;
      return <RenderTime minutes={minutes} />;
    }

    return (
      <p className="tooltip-value">
        <S.UnitValue>{startDate}</S.UnitValue>
        <span className="divider" />
        <S.UnitValue>{endDate}</S.UnitValue>
      </p>
    );
  };

  return (
    <S.TooltipWrapper
      className={classNames('sleep-tooltip', {
        'is-range-time': rangeTime,
        'recharts-tooltip-wrapper-overview': isOverview,
      })}
      id="sleep-tooltip-content"
      positionVertical={positionVertical}
      widthCurrentTarget={widthCurrentTarget}
      isOutsideRight={isOutsideRight}
    >
      <S.RangeTimeLabel className="label-tool-tip">{label}</S.RangeTimeLabel>
      <div className="tooltip-value-wrapper">
        {getValueTime()}{' '}
        {(period_group === PERIOD_GROUP.WEEKLY || period_group === PERIOD_GROUP.MONTHLY) && (
          <span className="label-average">Average</span>
        )}
      </div>
      <SleepStates className="list-stage">{sortedNewTypes.map(renderSleepState)}</SleepStates>
    </S.TooltipWrapper>
  );
};

export default CustomTooltipContentVertical;
