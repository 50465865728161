import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import type { State, Dispatch } from 'types/redux';
import styled from 'styled-components';
import { TRIAL_MODE, PLANS } from 'constants/commonData';
import ClientGroup from 'components/ClientGroup';

const Wrapper = styled.div`
  background: #e1f3ff;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 25px;

  font-size: 14px;
  line-height: 150%;
  color: #2379c5;
`;

const msgData = {
  monthly_monthly_upgrade:
    '* For this upgrade, you will receive a credit for the remaining period of your existing plan, and be charged a prorated amount for the new plan. This monthly plan will <b>renew on <%= expiredDate %></b>.',
  monthly_monthly_downgrade:
    '* For this downgrade, you will receive a credit for the remaining period of your existing plan, and be charged a prorated amount for the new plan. This monthly plan will <b>renew on <%= expiredDate %></b>.',
  annually_annually_upgrade:
    '* For this upgrade, you will receive a credit for the remaining period of your existing plan, and be charged a prorated amount for the new plan starting today. This annual plan will <b>renew on <%= expiredDate %></b>.',
  annually_annually_downgrade:
    '* Your current annual plan is paid through <b>until <%= expiredDate %></b>. The new annual plan will begin and be charged starting the next billing cycle.',
  monthly_annually_upgrade:
    '* For this upgrade, you will receive a credit for the unused portion of your existing plan, and be charged in full for the new annual plan starting today. This annual plan will <b>renew on <%= expiredDate %></b>.',
  annually_monthly_downgrade:
    '* Your current annual plan is paid through <b>until <%= expiredDate %></b>. Your new monthly plan will be charged and begin starting the next billing cycle.',
};

const zapierMsgData = {
  monthly_monthly_upgrade:
    '* Your current tier of the Zapier plugin is paid through <b>until <%= expiredDate %></b>. The new <b>"Tier <%= zapierTier %> - <%= zapierQuantityScheduled %> tasks"</b> will begin and be charged starting the next billing cycle.',
  monthly_monthly_downgrade: `* Your current tier of the Zapier plugin is paid through <b>until <%= expiredDate %></b>. The new <b>"Tier <%= zapierTier %> - <%= zapierQuantityScheduled %> tasks"</b> will begin and be charged starting the next billing cycle.`,
  annually_monthly_downgrade:
    '* Your current annual plan is paid through <b>until <%= expiredDate %></b>. Your new monthly plan will be charged and begin starting the next billing cycle.',
  annually_monthly_upgrade:
    '* Your current tier of the Zapier plugin is paid through <b>until <%= expiredDate %></b>. The new <b>"Tier <%= zapierTier %> - <%= zapierQuantityScheduled %> tasks"</b> will begin and be charged starting the next billing cycle.',
  monthly_annually_upgrade:
    '* For this upgrade, you will receive a credit for the unused portion of your existing plan, and be charged in full for the new annual plan starting today. This annual plan will <b>renew on <%= expiredDate %></b>.',
  annually_annually_downgrade:
    '* Your current annual plan is paid through <b>until  <%= expiredDate %></b>. The new annual plan will begin and be charged starting the next billing cycle.',
  annually_annually_upgrade:
    '* For this upgrade, you will receive a credit for the remaining period of your existing plan, and be charged a prorated amount for the new plan starting today. This annual plan will <b>renew on <%= expiredDate %></b>.',
  monthly_monthly_downgrade_removed: `* For this downgrade, you won't receive credit for the remaining period of your Zapier plan. This monthly plan will <b>renew on <%= expiredDate %></b>.`,
  annually_annually_downgrade_removed:
    '* Your current annual plan is paid through <b>until  <%= expiredDate %></b>. The new annual plan will begin and be charged starting the next billing cycle.',
};

const FORMAT_DATE = 'MMM D, YYYY';

const getRenewDate = (key, teamData) => {
  let renewDate = moment(teamData.expiredDate).format(FORMAT_DATE);
  switch (key) {
    case 'monthly_monthly_upgrade':
    case 'monthly_monthly_downgrade':
    case 'annually_annually_upgrade':
    case 'annually_annually_downgrade':
    case 'annually_monthly_downgrade':
      break;
    case 'monthly_annually_upgrade':
      renewDate = moment(teamData.expiredDate).add(1, 'year').format(FORMAT_DATE);
      break;
    default:
      break;
  }
  return renewDate;
};

function UpgradeMessage(props) {
  const { selectedPackage, teamData, downgrade, zapierBasePricings } = props;
  if (teamData.trialMode !== TRIAL_MODE.Expired || teamData.tier === PLANS.starter.key) {
    return null;
  }

  let isDowngrade = downgrade;
  if (teamData.period === 'annually') {
    isDowngrade = selectedPackage.will_schedule;
  }

  const zapierQuantity = _.get(selectedPackage, 'zapier_quantity', 0);

  function filterPricingByPlan(pricings, plan) {
    return pricings
      .map((pricing, index) => ({ ...pricing, index }))
      .filter(pricing => _.get(pricing, 'packageNoVolumes', 0) === plan);
  }
  const filteredPricing = filterPricingByPlan(zapierBasePricings, zapierQuantity);

  const omitZapier = _.omit(selectedPackage, 'addOns.zapier');
  const omitTeamDataZapier = _.omit(teamData, 'addOns.zapier');

  const isPeriodMatch = _.get(selectedPackage, 'period') === _.get(teamData, 'period');
  const isAddOnsMatch = _.isEqual(_.get(omitZapier, 'addOns', []), _.get(omitTeamDataZapier, 'addOns', []));
  const isClientsMatch =
    _.get(selectedPackage, 'packagePrice.packageNoClients', false) === _.get(teamData, 'packageNoClients', false);

  // Add new only Zapier will remove label
  const isNewZapier =
    !_.get(teamData, 'addOns.zapier') &&
    _.get(selectedPackage, 'addOns.zapier') &&
    isPeriodMatch &&
    isAddOnsMatch &&
    isClientsMatch;

  // Remove only Zapier
  const isRemovedOnlyZapier =
    _.get(teamData, 'addOns.zapier') &&
    !_.get(selectedPackage, 'addOns.zapier') &&
    isPeriodMatch &&
    isAddOnsMatch &&
    isClientsMatch;

  const isDowngradeOnlyZapier =
    _.get(selectedPackage, 'zapier_quantity') < _.get(teamData, 'zapier_quantity') &&
    isPeriodMatch &&
    isAddOnsMatch &&
    isClientsMatch;

  // Add/Down Base plan - Add new Zapier
  const isDownBasePlanAddZapier =
    !_.get(teamData, 'addOns.zapier') &&
    _.get(selectedPackage, 'addOns.zapier') &&
    isPeriodMatch &&
    _.get(selectedPackage, 'packagePrice.packageNoClients', false) <= _.get(teamData, 'packageNoClients', false) &&
    !isAddOnsMatch &&
    !isDowngrade;

  if (isRemovedOnlyZapier || isDowngradeOnlyZapier || isDownBasePlanAddZapier) {
    isDowngrade = true;
  }
  const key = `${teamData.period}_${selectedPackage.period}_${isDowngrade ? 'downgrade' : 'upgrade'}${
    isNewZapier ? '_zapier' : ''
  }${isRemovedOnlyZapier ? '_removed' : ''}`;
  const renewDate = getRenewDate(key, teamData);

  const zapierTier = (_.get(filteredPricing, '[0].index', 0) + 1).toLocaleString('en-US');
  const zapierQuantityString = zapierQuantity.toLocaleString('en-US');

  const isChangeOnlyZapier =
    _.get(teamData, 'zapier_quantity') !== !_.get(selectedPackage, 'zapier_quantity') &&
    isAddOnsMatch &&
    isClientsMatch;

  var compiled = _.template(
    isRemovedOnlyZapier ? zapierMsgData[key] : isChangeOnlyZapier ? zapierMsgData[key] : msgData[key],
  );

  const text = compiled({
    expiredDate: renewDate,
    zapierQuantityScheduled: zapierQuantityString,
    zapierTier: zapierTier,
  });

  return (
    !!text && (
      <Wrapper>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Wrapper>
    )
  );
}

const mapStateToProps = (state: State) => {
  const { rootReducer } = state;
  const addOnsPricing = rootReducer.pricing.get('addOnsPricing').toJS() || [];
  const zapierPricing = addOnsPricing.find(item => item.code === 'zapier');
  const zapierBasePricings = _.get(zapierPricing, 'basePricings', []);

  return {
    zapierBasePricings,
    selectedPackage: rootReducer.pricing.get('selectPackage').toJS(),
    teamData: rootReducer.pricing.getIn(['teamData']).toJS(),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeMessage);
