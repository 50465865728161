import omit from 'lodash/omit';
import get from 'lodash/get';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { PER_PAGE } from 'components/RecipeDetail/constants';
import { Types } from './actions';
import { ERROR_CODE_BAD_REQUEST, ERROR_CODE_FORBIDDEN, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { checkIsOwner } from 'redux/tasks-library/reducers';
import { MAX_CALORIES, RECIPE_HEADER_TABS, RECIPE_STATUS } from 'components/Recipes/constants';
import { convertIngredientList, convertNutritionFromDetailRecipe } from 'components/RecipeDetail/helper';

export const INITIAL_STATE_RECIPES = {
  list: [],
  loading: false,
  filters: {
    page: 1,
    per_page: PER_PAGE,
    sort: -1,
    tab: '',
    category: '',
    dietaryInformation: [],
    ingredientType: [],
    sorter: 'updated_at',
    dietaries: [],
    ingredient_ids: [],
    only_my_recipes: false,
    text_search: '',
    recipe_from: [],
    status: [RECIPE_STATUS.PUBLISH],
    from_calories: 0,
    to_calories: MAX_CALORIES,
  },
  detailRecipe: {},
  isEditMode: true,
  ingredient: {
    category: [],
    list: [],
    total: 0,
    filters: {
      text_search: '',
      categories: null,
      page: 1,
      per_page: PER_PAGE,
    },
    loadingIngredient: false,
    loadingIngredientCategory: false,
  },
  isLoading: false,
  allTotal: 0,
  myRecipeTotal: 0,
  totalDraft: 0,
  total: 0,
  selectedCoverBackground: {},
  coverBackground: {
    list: [],
    total: 0,
    loadingCoverBackground: false,
  },
  initTotal: {
    all: 0,
    only_my_recipes: 0,
    min_calories: 0,
    max_calories: MAX_CALORIES,
    hasGetToTal: false,
    existed_recipes: false,
  },
  maxCalories: MAX_CALORIES,
  tab: RECIPE_HEADER_TABS.ALL_RECIPE,
  otherNutrient: [],
  listDietary: [],
  loadingListDietary: false,
  totalRecipeInTeam: 0,
};

const ERROR_CODE_STATUS = [ERROR_CODE_FORBIDDEN, ERROR_CODE_BAD_REQUEST];

export default (state = INITIAL_STATE_RECIPES, action) => {
  const { type, payload } = action;
  const { detailRecipe } = { ...state };

  switch (type) {
    case Types.RECIPE_DETAIL_TOGGLE_EDIT_MODE: {
      const { status } = payload;

      if (status) {
        return Object.assign({}, state, {
          detailRecipe: { ...detailRecipe, is_edit_mode: status },
          isEditMode: status,
          loading: false,
        });
      }

      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, is_edit_mode: status, isChange: status },
        isEditMode: status,
        loading: false,
      });
    }

    case Types.RECIPE_GET_LIST_SUCCESS: {
      const { data, total, newPage } = payload;
      const list = get(state, 'list', []);
      return Object.assign({}, state, {
        list: newPage === 1 ? data : [...list, ...data],
        loading: false,
        total: total,
      });
    }

    case Types.RECIPE_GET_LIST_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.RECIPE_GET_LIST_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.RECIPE_GET_DETAIL_SUCCESS: {
      const { data, unitType } = payload;

      const ingredients = get(data, 'ingredients', []).map(item => {
        const { unit = {}, value_as_text } = item || {};

        const { unique_code, unit_group, name, formula } = unit || {};
        const convertUnit = {
          ...unit,
          key: unique_code,
          label: unit_group !== 'other' ? `${name} (${formula})` : name,
          acronym: formula,
        };

        return { ...item, unit: convertUnit, value_as_text };
      });

      const detailRecipe = omit(data, [
        '__v',
        'updated_at',
        'created_at',
        'last_edit_by',
        'last_used',
        'name_lowercase',
      ]);

      const dataDetailRecipe = convertNutritionFromDetailRecipe(detailRecipe);

      return Object.assign({}, state, {
        detailRecipe: {
          ...dataDetailRecipe,
          ingredients,
          unit: unitType,
        },
        isEditMode: get(dataDetailRecipe, 'is_edit_mode'),
        loading: false,
      });
    }

    case Types.RECIPE_UPDATE_DATA_DETAIL: {
      const { data, status } = payload;
      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, isChange: status, ...data },
      });
    }
    case Types.RECIPES_CHANGE_QUERY_PARAMS: {
      const filters = { ...state.filters, ...payload };
      return Object.assign({}, state, {
        filters: filters,
      });
    }

    case Types.RECIPE_DELETE_REQUEST: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case Types.RECIPE_DELETE_SUCCESS: {
      const { recipeId, isList, user } = payload;
      const { list } = state;

      let currentRecipes = list;
      const isOwner = list.some(
        item => item._id === recipeId && (get(item, 'author._id') || get(item, 'author')) === get(user, '_id'),
      );

      if (isList && list.length > 0) {
        currentRecipes = list.filter(it => it._id !== recipeId);
      }

      return Object.assign({}, state, {
        isLoading: false,
        list: currentRecipes,
        // allTotal: state.allTotal - 1,
        // myRecipeTotal: isOwner ? state.myRecipeTotal - 1 : state.myRecipeTotal,
      });
    }

    case Types.RECIPE_DELETE_FAILED: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    case Types.RECIPE_GET_LIST_CATEGORY_INGREDIENT_REQUEST:
      return Object.assign({}, state, {
        ingredient: {
          ...state.ingredient,
          loadingIngredientCategory: true,
        },
      });

    case Types.RECIPE_GET_LIST_CATEGORY_INGREDIENT_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, {
        ingredient: {
          ...state.ingredient,
          category: data,
          loadingIngredientCategory: false,
        },
      });
    }

    case Types.RECIPE_GET_LIST_CATEGORY_INGREDIENT_FAILED:
      return Object.assign({}, state, {
        ingredient: {
          ...state.ingredient,
          loadingIngredientCategory: false,
        },
      });

    case Types.RECIPE_GET_LIST_INGREDIENT_REQUEST: {
      const { ingredient } = { ...state };
      return Object.assign({}, state, {
        ingredient: { ...ingredient, loadingIngredient: true },
      });
    }

    case Types.RECIPE_GET_LIST_INGREDIENT_SUCCESS: {
      const { data } = payload;

      const list = get(data, 'data', []).map(item => ({ ...item, ingredient: get(item, '_id', '') }));

      const { ingredient } = { ...state };
      return Object.assign({}, state, {
        ingredient: {
          ...ingredient,
          total: get(data, 'total', 0),
          list: get(ingredient, 'filters.page', 0) === 1 ? list : uniqBy([...ingredient.list, ...list], '_id'),
          loadingIngredient: false,
        },
      });
    }

    case Types.RECIPE_GET_LIST_INGREDIENT_FAILED: {
      const { ingredient } = { ...state };
      return Object.assign({}, state, {
        ingredient: { ...ingredient, loadingIngredient: false },
      });
    }

    case Types.RECIPE_UPDATE_FILTER_INGREDIENT: {
      const { ingredient } = { ...state };
      const { filters } = ingredient;
      return Object.assign({}, state, {
        ingredient: { ...ingredient, filters: { ...filters, ...payload } },
      });
    }

    case Types.RECIPE_GET_TOTAL_SUCCESS: {
      const { data, isDraft } = payload;
      const totalKey = isDraft ? 'totalDraft' : 'myRecipeTotal';
      return Object.assign({}, state, {
        allTotal: get(data, 'all', 0),
        [totalKey]: get(data, 'only_my_recipes', 0),
        totalDraft: get(data, 'only_draft', 0),
      });
    }

    case Types.RECIPE_GET_INIT_TOTAL_SUCCESS: {
      const { data = {} } = payload;
      const { max_calories = MAX_CALORIES } = data;

      const { initTotal, filters } = state;
      return Object.assign({}, state, {
        initTotal: { ...initTotal, ...data, hasGetToTal: true },
        filters: { ...filters, to_calories: max_calories > MAX_CALORIES ? max_calories : MAX_CALORIES },
        maxCalories: max_calories > MAX_CALORIES ? max_calories : MAX_CALORIES,
        loading: true,
      });
    }

    case Types.RECIPE_GET_INIT_TOTAL_AFTER_UPDATE: {
      const { data = {} } = payload;
      const { max_calories = MAX_CALORIES } = data;
      return Object.assign({}, state, {
        maxCalories: max_calories > MAX_CALORIES ? max_calories : MAX_CALORIES,
      });
    }

    case Types.RECIPE_RESET_DETAIL: {
      return Object.assign({}, state, {
        detailRecipe: get(INITIAL_STATE_RECIPES, 'detailRecipe', {}),
      });
    }

    case Types.RECIPE_RESET_FILTERS: {
      return Object.assign({}, state, {
        filters: INITIAL_STATE_RECIPES.filters,
        initTotal: { ...state.initTotal, hasGetToTal: false },
        maxCalories: state.initTotal.max_calories,
      });
    }

    case Types.RECIPE_RESET_LISTS: {
      return Object.assign({}, state, {
        list: [],
        allTotal: 0,
        // TODO: Keep myRecipeTotal & totalDraft
        // myRecipeTotal: 0,
        // totalDraft: 0,
        total: 0,
      });
    }

    case Types.RECIPE_UPDATE_SHARING_STATUS_SUCCESS: {
      const { list, allTotal } = state;
      const { recipeId, updateShare, user } = payload;

      const newListShare = map(list, item =>
        get(item, '_id', '') === recipeId ? { ...item, share: get(updateShare, 'share', TEAM_SHARE_PRIVATE) } : item,
      );

      let filterDataShare = [];
      newListShare.forEach(it => {
        if (it.share !== 0 || (it.share === 0 && checkIsOwner(it.author, get(user, '_id', '')))) {
          filterDataShare.push(it);
        }
      });

      const isChangeTotalList = list.length !== filterDataShare.length;

      return Object.assign({}, state, {
        list: filterDataShare,
        allTotal: isChangeTotalList ? allTotal - 1 : allTotal,
      });
    }

    case Types.RECIPE_SELECTED_COVER_IMAGE: {
      return Object.assign({}, state, {
        selectedCoverBackground: payload,
      });
    }

    case Types.RECIPE_ADD_BACKGROUND_LIBRARIES_SUCCESS: {
      const { coverBackground } = { ...state };

      return Object.assign({}, state, {
        coverBackground: {
          ...coverBackground,
          list: [payload, ...coverBackground.list.slice(1)],
        },
      });
    }

    case Types.RECIPE_GET_BACKGROUND_LIBRARIES_REQUEST: {
      const { coverBackground } = { ...state };

      return Object.assign({}, state, {
        coverBackground: { ...coverBackground, loadingCoverBackground: true },
      });
    }

    case Types.RECIPE_GET_BACKGROUND_LIBRARIES_SUCCESS: {
      const { coverBackground } = { ...state };
      const { data, total } = payload;

      return Object.assign({}, state, {
        coverBackground: {
          ...coverBackground,
          list: [...coverBackground.list, ...data],
          total: total,
          loadingCoverBackground: false,
        },
      });
    }

    case Types.RECIPE_GET_BACKGROUND_LIBRARIES_FAILED: {
      const { coverBackground } = { ...state };

      return Object.assign({}, state, {
        coverBackground: { ...coverBackground, loadingCoverBackground: false },
      });
    }

    case Types.RECIPE_DELETE_BACKGROUND_LIBRARIES_SUCCESS: {
      const { coverBackground } = { ...state };
      const { id } = payload;

      return Object.assign({}, state, {
        coverBackground: { ...coverBackground, list: coverBackground.list.filter(item => item._id !== id) },
      });
    }

    case Types.RECIPE_RESET_INGREDIENT: {
      return Object.assign({}, state, {
        ingredient: { ...state.ingredient, ...omit(INITIAL_STATE_RECIPES.ingredient, ['category']) },
      });
    }

    case Types.RECIPE_DUPLICATE_LIBRARIES_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.RECIPE_DUPLICATE_LIBRARIES_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.RECIPE_DUPLICATE_LIBRARIES_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.RECIPE_EDIT_SUCCESS: {
      const { data } = payload;
      const { is_edit_mode = false, owner } = data || {};
      const { _id = '' } = owner || {};

      const dataConvert = omit(data, [
        '__v',
        'updated_at',
        'created_at',
        'last_edit_by',
        'last_used',
        'name_lowercase',
      ]);

      const ingredients = convertIngredientList(get(dataConvert, 'ingredients', []));
      const dataDetailRecipe = convertNutritionFromDetailRecipe(dataConvert);

      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, ...dataDetailRecipe, ingredients, isChange: false, owner: _id },
        isEditMode: is_edit_mode,
        loading: false,
      });
    }

    case Types.RECIPE_RESET_BACKGROUND_LIBRARY: {
      return Object.assign({}, state, {
        coverBackground: INITIAL_STATE_RECIPES.coverBackground,
      });
    }

    case Types.RECIPE_ADD_LOCAL_TO_BACKGROUND_LIBRARY: {
      const { coverBackground } = { ...state };
      const { data } = payload;
      return Object.assign({}, state, {
        coverBackground: { ...coverBackground, list: [data, ...coverBackground.list] },
      });
    }

    case Types.RECIPE_REMOVE_LOCAL_TO_BACKGROUND_LIBRARY: {
      const { coverBackground } = { ...state };
      return Object.assign({}, state, {
        coverBackground: {
          ...coverBackground,
          list: coverBackground.list.slice(1),
        },
      });
    }

    case Types.RECIPE_ADD_LIBRARIES_SUCCESS: {
      const { data } = payload;
      const { is_edit_mode = false, owner } = data || {};
      const { _id = '' } = owner || {};

      const dataDetailRecipe = convertNutritionFromDetailRecipe(data);

      return Object.assign({}, state, {
        detailRecipe: { ...dataDetailRecipe, owner: _id },
        isEditMode: is_edit_mode,
        loading: false,
      });
    }

    case Types.RECIPE_DETAIL_START_SUBMIT: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.RECIPE_DETAIL_END_SUBMIT: {
      const { errorCode } = payload;

      return Object.assign({}, state, {
        loading: false,
        detailRecipe: { ...detailRecipe, isChange: !ERROR_CODE_STATUS.includes(errorCode) },
      });
    }

    case Types.RECIPE_PUBLISH_SUCCESS: {
      const { data } = payload;
      const { is_edit_mode = false, author, owner } = data || {};
      const { _id: authorId = '' } = author || {};
      const { _id: ownerId = '' } = owner || {};

      const ingredients = convertIngredientList(get(data, 'ingredients', []));

      const dataDetailRecipe = convertNutritionFromDetailRecipe(data);

      return Object.assign({}, state, {
        detailRecipe: {
          ...detailRecipe,
          ...dataDetailRecipe,
          ingredients,
          owner: authorId || ownerId,
          isChange: false,
        },
        isEditMode: is_edit_mode,
        loading: false,
      });
    }

    case Types.RECIPE_UNPUBLISH_SUCCESS: {
      const { success } = payload;
      const newStatus = success ? RECIPE_STATUS.DRAFT : RECIPE_STATUS.PUBLISH;

      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, status: newStatus },
        loading: false,
      });
    }

    case Types.RECIPE_DETAIL_RESET_EDIT_MODE: {
      const { status } = payload;

      return Object.assign({}, state, {
        isEditMode: status,
      });
    }

    case Types.RECIPE_DETAIL_SET_LOADING: {
      const { status } = payload;

      return Object.assign({}, state, {
        isLoading: status,
      });
    }

    case Types.RECIPE_DETAIL_IS_SUBMITTED: {
      const { data } = payload;

      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, ...data },
      });
    }

    case Types.RECIPE_SET_HEADER_TABS: {
      const { tab } = payload;

      return Object.assign({}, state, {
        tab: tab,
      });
    }

    case Types.RECIPE_GET_LIST_OTHER_NUTRIENT_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, {
        otherNutrient: data,
      });
    }

    case Types.RECIPE_GET_LIST_DIETARY_REQUEST: {
      return Object.assign({}, state, {
        loadingListDietary: true,
      });
    }

    case Types.RECIPE_GET_LIST_DIETARY_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, {
        listDietary: data,
        loadingListDietary: false,
      });
    }

    case Types.RECIPE_GET_LIST_DIETARY_FAILED: {
      return Object.assign({}, state, {
        loadingListDietary: false,
      });
    }

    case Types.RECIPE_UPDATE_DATA_INGREDIENT: {
      const { data } = payload;

      return Object.assign({}, state, {
        ingredient: {
          ...state.ingredient,
          ...data,
        },
      });
    }

    case Types.RECIPE_GET_TOTAL_RECIPE_IN_TEAM_SUCCESS: {
      const { total } = payload || {};

      return Object.assign({}, state, {
        totalRecipeInTeam: total,
      });
    }

    case Types.RECIPE_UPDATE_LOADING_VIDEO_INSTRUCTION: {
      const { status } = payload;
      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, loadingVideoInstruction: status },
      });
    }

    case Types.RECIPE_TOGGLE_AUTO_CALCULATED: {
      const { status } = payload;
      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, is_auto_calculated: status },
      });
    }

    case Types.RECIPE_UPDATE_INGREDIENT_DATA: {
      const { data = [] } = payload;
      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, ingredients: data },
      });
    }

    case Types.RECIPE_UPDATE_AUTO_CALCULATED_NUTRITION: {
      const { data = [] } = payload;
      return Object.assign({}, state, {
        detailRecipe: { ...detailRecipe, auto_calculated_nutrition: data },
      });
    }

    default:
      return state;
  }
};
