// Libs
import styled, { css } from 'styled-components';

// Shared
import { Button } from 'shared/FormControl';

// Assets
import IconSearch from 'assets/icons/search_icon_grey.svg';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #202353;
`;

export const FilterSearch = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  height: 40px;
  background-color: #f8f8f8;
  margin: 0 30px;
  .search-input {
    flex: 1;
    height: 100%;
    input {
      ${baseText}
      font-weight: 400;
      border: none;
      font-size: 13px;
      line-height: 20px;
      background-color: transparent;
      background: url(${IconSearch}) no-repeat;
      background-size: 18px 18px;
      background-position: 15px center;
      padding-left: 44px;
      ::placeholder {
        ${baseText}
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #b4b4c2;
        opacity: 0.8;
      }
    }
  }
  &:hover {
    border-color: #5158cf;
  }
  ${({ isFocus }) =>
    isFocus &&
    css`
      background-color: #fff;
      border-color: #5158cf;
    `}
`;

export const FilterButton = styled(Button)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  min-width: unset;
  background-color: transparent;
  padding: unset;
  svg {
    flex-shrink: 0;
  }
  :hover {
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }

  ${props =>
    props.active &&
    css`
      background-color: #f0f1ff;
      svg path {
        fill: #5158cf;
      }
    `}

  * {
    margin-right: 0;
  }

  .__react_component_tooltip.filter-action-tooltip {
    ${baseText}
    border-radius: 5px;
    background: #333;
    padding: 8px 10px;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    &.type-dark.place-top:after {
      border-top-color: #333;
    }
    &.place-top {
      margin-top: -8px;
    }
  }
`;

export const FilterNumber = styled.div`
  width: 16px;
  height: 14px;
  position: absolute;
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  right: -9px;
  top: -9px;
  border-radius: 3px;
  box-shadow: 0 0 0 3px #fff;
  background: #ffbe49;
  color: #fff;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;
