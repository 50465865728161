import styled, { css } from 'styled-components';

const textBase = css`
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  color: #000000;
`;

export const ChartWrapper = styled.div`
  .recharts-layer .recharts-cartesian-axis-tick {
    ${textBase}
    color: #1e0a3c !important;
    text-align: left;
    opacity: 1 !important;

    text {
      fill: #1e0a3c !important;
    }
  }
`;

export const SectionContainer = styled.div`
  padding: 11px 0 5px;
  ${textBase}
  font-size: 14px;
  line-height: 24px;
  color: #5f5f5f;
  background: #ffffff;
  border: 1px solid #dde2ed;
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 65px;
  align-items: flex-end;
  padding: 0 20px;
  margin-bottom: 25px;
`;

export const SectionBody = styled.div``;

export const SectionFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 24px;
  margin-top: -5px;
  margin-bottom: 15px;
  padding: 0 20px;
  margin-top: 25px;
`;

export const LegendSection = styled.div`
  display: flex;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 222px;
  justify-content: space-between;
  align-items: center;
  padding: 14.5px 0;

  :last-child {
    border-bottom: none;
  }
`;

export const LegendSectionLeft = styled.div`
  display: flex;
  gap: 6px;
  width: 140px;
`;

export const LegendZone = styled.div`
  ${textBase}
  font-weight: 600;
  white-space: nowrap;
`;

export const LegendColor = styled.div`
  background-color: ${({ color }) => color};
  width: ${({ width }) => `${width}px`};
  height: 10px;
  border-radius: 999px;
  align-self: center;
`;

export const LegendTime = styled.div`
  ${textBase}
`;

export const LegendRange = styled.div`
  ${textBase}
  opacity: 0.7;
  width: 75px;
  text-align: end;
`;

export const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 8px;
`;

export const RightHeader = styled.div`
  display: flex;
  height: 32px;
  border-radius: 6px;
  padding: 8px;
  background-color: #f7f8fc;
  color: #000000;
  font-weight: 600;
  font-size: 12px;
  align-items: center;
`;

export const AvgNumber = styled.div`
  color: #1e0a3c;
  font-size: 50px;
  font-weight: 700;
  line-height: 100%;
`;

export const AvgUnit = styled.span`
  color: #7b7e91;
  font-size: 12px;
  font-weight: 700;
  margin-left: 8px;
  margin-bottom: -2px;
`;

export const RangeNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const LongDash = styled.div`
  width: 8px;
  height: 1px;
  background-color: #000000;
`;

export const RangeUnit = styled.div`
  opacity: 0.4;
  margin-left: 7px;
`;
