// Libs
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// Constants
import { DATE_FORMATS } from 'constants/commonData';

// Assets
import { ReactComponent as CircleYellowAlert } from 'assets/icons/VerifyEmail/circle-yellow-alert.svg';

// Styles
import * as S from './style';

export const NAVBAR_HEIGHT_ALERT = [44, 62, 80];

const PaymentOverdueWhiteLabelAlert = props => {
  const { paymentOverdueEnterprise, timeOverdueEnterprise, linkPaymentInvoiceOverdueEnterprise, user } = props || {};
  const { date_format } = user || {};

  const headerWrapperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const container = headerWrapperRef.current;
      const appMainContent = document.querySelector('.app-content-custom.white-label-overdue');

      if (!container || !appMainContent) {
        const appMainContentCustom = document.querySelector('.app-content-custom');
        appMainContentCustom.style.paddingTop = 0;
        return;
      }

      const { height } = container.getBoundingClientRect();

      if (NAVBAR_HEIGHT_ALERT.includes(Math.ceil(height))) {
        appMainContent.style.paddingTop = `${height}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOpenInvoice = () => {
    window.open(linkPaymentInvoiceOverdueEnterprise, '_blank');
  };

  if (!paymentOverdueEnterprise) {
    return null;
  }

  return (
    <S.Wrapper ref={headerWrapperRef}>
      <CircleYellowAlert className="custom-icon" />
      <S.ContentWrapper>
        We were unable to bill the card on file for renewal on{' '}
        <S.Bold>{moment.unix(timeOverdueEnterprise).format(date_format || DATE_FORMATS[0].label)}</S.Bold>. Unless you
        update your billing, your Everfit Enterprise Plan will become limited in 14 days.{' '}
        <S.ButtonRequest onClick={handleOpenInvoice}>LINK TO INVOICE</S.ButtonRequest>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
const mapStateToProps = state => {
  const {
    user,
    rootReducer: { pricing },
  } = state;

  return {
    paymentOverdueEnterprise: pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
    timeOverdueEnterprise: pricing.getIn(['teamData', 'time_base_over_due_enterprise']),
    linkPaymentInvoiceOverdueEnterprise: pricing.getIn(['teamData', 'link_payment_invoice_over_due_enterprise']),
    user,
  };
};

export default connect(mapStateToProps, null)(PaymentOverdueWhiteLabelAlert);
