/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { State, Dispatch } from 'types/redux';
import ChangePassword from './component';
import { changePassword } from 'actions/client';

const mapStateToProps = (state: State) => {
  const { user } = state;
  return {
    user
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    changePassword: (data) => {
      return dispatch(changePassword(data));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePassword)
);
