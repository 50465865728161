import React from 'react';
import { Toggle } from 'shared/FormControl';
import { mongoObjectId } from 'utils/commonFunction';
import { OtherOptionWrapper } from './style';

export default function OtherOption({ workingQuestion, onChange, disabled }) {
  let options = [...workingQuestion.options];
  const hasOther = options.some(item => item.is_other);

  const handleChange = () => {
    if (hasOther) {
      options.pop();
    } else {
      options = [...options, { _id: mongoObjectId(), label: 'Other', is_other: true }];
    }
    onChange &&
      onChange({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        options,
      });
  };

  return (
    <OtherOptionWrapper disabled={disabled} data-id="other-option">
      <div>“Other” option</div>
      <Toggle
        checked={hasOther}
        onChange={handleChange}
        width={28}
        height={16}
        className="form-builder-required-toggle"
        disabled={disabled}
      />
    </OtherOptionWrapper>
  );
}
