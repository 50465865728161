import styled, { css } from 'styled-components';

import Button from 'shared/FormControl/Button';

export const PollPostTrigger = styled(Button)`
  display: flex;
  height: 38px;
  width: 38px;
  min-width: unset !important;
  left: 0px;
  top: 0px;
  padding: 10px 0;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10000px;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0;
  }

  ${({ hasTooltip }) =>
    hasTooltip
      ? css`
          :hover {
            background-color: #f6f5fe;
            border: 1px solid #5258c8;
            box-shadow: 0px 2px 2px #0000001a !important;
          }
        `
      : css`
          :hover {
            background-color: #fff;
            cursor: default;
          }
        `}

  ${props =>
    props.open &&
    css`
      background-color: #f6f5fe;
      border: 1px solid #5258c8;
      box-shadow: 0px 2px 2px #0000001a !important;
    `}

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
    background-color: #f9f9f9;
    box-shadow: none !important;
    color: #b0b0be;
    border: none !important;

    svg {
      rect {
        fill-opacity: 0.4;
      }
    }
  }

  .__react_component_tooltip.poll-tooltip {
    padding: 15px;
    border-radius: 5px;
    width: 53px;
    line-height: 19.5px;
  }
`;
