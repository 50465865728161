import styled from 'styled-components';

export const LegendWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  margin: ${({ hourlyView }) => `${hourlyView ? '-4px' : '-1px'} auto 20px`};

  .legend-item {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    position: relative;

    &__box {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 4px;

      &.sleep-special {
        background: linear-gradient(
          180deg,
          rgba(114, 190, 244, 0.2) 2.1%,
          rgba(52, 120, 246, 0.2) 57.39%,
          rgba(54, 52, 157, 0.2) 107.42%
        );
      }
    }

    &__label {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #7b7e91;
    }
  }
`;

export const SleepStates = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 7px;
  font-family: 'Open Sans';

  .sleep-state {
    display: flex;
    justify-content: space-between;
    height: 18px;
    padding: 5px 0;
    gap: 15px;
    align-items: center;
  }

  .sleep-state-left {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    width: 60px;
  }

  .sleep-state-title {
    color: #7b7e91;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .sleep-state-time {
    color: #7b7e91;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    flex: 1;

    .unit {
      font-size: 11px;
      line-height: 16.5px;
    }
  }

  .sleep-state-color {
    width: 12px;
    height: 12px;
    border-radius: 4px;

    &.sleep-special {
      background: linear-gradient(
        180deg,
        rgba(114, 190, 244, 0.2) 2.1%,
        rgba(52, 120, 246, 0.2) 57.39%,
        rgba(54, 52, 157, 0.2) 107.42%
      );
    }
  }
`;

export const SleepSwitchWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  &:hover {
    .ask-connect-tooltip {
      visibility: visible;
    }
  }

  .ask-connect-tooltip {
    background-color: #fff !important;
    padding: 15px 10px 10px !important;
    box-shadow: 0px 4px 16px 0px #26262633;
    width: 266px;
    height: 131px;
    gap: 15px;
    flex-direction: column;
    padding: 15px 10px 10px;
    position: absolute;
    display: flex;
    top: -132px;
    right: -67px;
    border-radius: 10px;
    visibility: hidden;

    &.hidden {
      visibility: hidden !important;
    }

    .connect-content {
      display: flex;
      gap: 16px;
      padding: 0 5px;

      .text {
        width: 151.93px;
        font-weight: 400;
        text-align: left;
        color: #202353;
        font-size: 13px;
        line-height: 19.5px;
        font-family: 'Open Sans';

        span {
          display: inline-block;
          width: 17px;
        }
      }

      svg {
        width: 68.07px;
        height: 61px;
      }
    }

    .ask-connect-btn {
      background-color: #5158cf;
      color: #fff;
      height: 30px;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      width: 100%;
      border: none;
      position: relative;
      top: -1px;

      :hover {
        opacity: 0.9;
      }
    }
  }

  .switch-wrapper {
    display: flex;
    height: 30px;
    width: 259px;
    border: 1px solid #e1e1ea;
    border-radius: 5px;
    overflow: hidden;

    .switch-wrapper-item {
      flex: 1;
      height: 100%;
      padding: 7px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }

      &:hover {
        opacity: 0.8;
      }

      &.active {
        background-color: #f0f0f2;

        &.sleep-duration {
          border-right: 1px solid #e1e1ea;
        }
        &.sleep-time {
          border-left: 1px solid #e1e1ea;
        }
        span {
          color: #202353;
        }
      }

      span {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: #7b7e91;
        user-select: none;
        white-space: nowrap;
      }
    }
  }

  .switch-stages {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    span {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #7b7e91;
    }

    .switch-box {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid #e1e1ea;
      background: #fff;

      svg {
        position: relative;
        left: 3px;
        top: -4px;
        width: 8px;
        height: 8px;

        path {
          stroke: #fff;
        }
      }

      &.active {
        background: linear-gradient(
          180deg,
          #ff7557 0.5%,
          #ff7557 16.5%,
          #ff7557 31.76%,
          #72bef4 31.77%,
          #72bef4 53.65%,
          #3478f6 53.66%,
          #3478f6 78.65%,
          #36349d 78.66%,
          #36349d 100%
        );
      }
    }
  }
`;
