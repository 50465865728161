import styled from 'styled-components';

export const Value = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  margin: 0 5px;
`;

export const RepeatInstance = styled.div`
  padding: 10px 0 20px;
`;

export const RepeatSetting = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .evfSelectBoxContainer {
    margin: 0 10px;
  }

  .start-date {
    color: #000;
    opacity: 0.3;
    margin-left: 10px;
  }
`;

export const Wrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
  font-style: normal;
`;
