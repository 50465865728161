import React, { useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import { Checkbox } from 'shared/FormControl';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';

import * as S from './style';

const PaymentFilter = props => {
  const { selectedPayment, setSelectedPayment, options } = props;
  const [open, setOpen] = useState(false);

  const isExistValue = value => findIndex(selectedPayment, it => it.value === value) !== -1;

  const handleSelectPayment = option => {
    if (isExistValue(option.value)) {
      if (option.value === 'all' || (isExistValue('all') && selectedPayment.length === 2)) {
        setSelectedPayment([]);
        return;
      }
      if (option.value !== 'all' && selectedPayment.length === options.length) {
        setSelectedPayment(filter(selectedPayment, item => item.value !== option.value && item.value !== 'all'));
        return;
      }
      setSelectedPayment(filter(selectedPayment, item => item.value !== option.value));
    } else {
      if (option.value === 'all' || (!isExistValue('all') && selectedPayment.length === options.length - 2)) {
        setSelectedPayment(options);
        return;
      }
      setSelectedPayment(uniqBy([...selectedPayment, option], 'value'));
    }
  };

  const renderLabel = () => {
    if (selectedPayment.length === options.length) {
      return (
        <>
          <span className="filter-name">State</span>
          <span className="colons">:</span>
          <span className="selected">All</span>
        </>
      );
    } else if (selectedPayment.length === 1) {
      return (
        <>
          <span className="selected text">{get(selectedPayment, '[0].label', '')}</span>
        </>
      );
    } else if (selectedPayment.length > 1 && selectedPayment.length < options.length) {
      return (
        <>
          <span className="selected text">{selectedPayment.length}&nbsp;States</span>
        </>
      );
    } else if (selectedPayment.length === 0) {
      return (
        <>
          <span className="filter-name">State</span>
          <span className="colons">:</span>
          <span className="selected">None</span>
        </>
      );
    }
  };

  return (
    <RootCloseWrapper
      onRootClose={() => {
        setOpen(false);
      }}
      event="click"
      disabled={!open}
    >
      <S.Wrapper>
        <div className={`filter__dropdown ${open ? 'active' : ''}`} onClick={() => setOpen(!open)}>
          <div className="filter__dropdown__title">{renderLabel()}</div>
        </div>
        {open && (
          <S.MenuContainer>
            {map(options, option => (
              <S.PaymentOption onClick={() => handleSelectPayment(option)} key={option.value}>
                <S.PaymentOptionTitle>{option.label}</S.PaymentOptionTitle>
                <S.ButtonWrapper>
                  <Checkbox
                    checked={isExistValue(option.value)}
                    onClick={event => event.preventDefault()}
                    onChange={event => event.preventDefault()}
                    size={16}
                    className="payment-checkbox__icon"
                  />
                </S.ButtonWrapper>
              </S.PaymentOption>
            ))}
          </S.MenuContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

export default PaymentFilter;
