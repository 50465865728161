import styled, { css } from 'styled-components';

export const FitInchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7b7e91;
  flex: 1;
  height: 36px;

  label {
    margin-bottom: 2px;
    padding-left: 1px;
    text-transform: uppercase;
  }
  ${props =>
    props.isFocus &&
    css`
      ${FitInchInput} {
        border: 1px solid #5158cf;
      }
    `}

  ${props =>
    props.hasError &&
    css`
      ${FitInchInput} {
        border: 1px solid #ea314a !important;
      }
    `}
`;

export const FitInchInput = styled.div`
  border: 1px solid #dadbea;
  border-radius: 4px;
  overflow: hidden;
  padding: 8px 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  &:hover {
    border: 1px solid #5158cf;
  }
`;

export const InputItem = styled.div`
  padding: 0 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1;
  position: relative;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #202353;
    border: none;
    padding: 0;
    outline: none;
    width: 100%;
  }
  &:first-child {
    border-right: 1px solid #dadbea;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 19.5px;
    color: #7b7e91;
  }

  &:hover {
    .arrow-inputs {
      display: flex;
    }
  }

  .arrow-inputs {
    right: 22px !important;
    top: -1px !important;

    .arrow-input.disabled {
      opacity: 0.7;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }
  }
`;
