import _ from 'lodash';
import Request from 'configs/request';
import { revokeMultipleObjectURL } from 'utils/commonFunction';
import { THEMES, CUSTOM_ONBOARDING_VIDEO_TYPE } from 'components/CustomBranding/constants';

export const Types = {
  CUSTOM_BRANDING_GET_DATA: 'CUSTOM_BRANDING_GET_DATA',
  CUSTOM_BRANDING_SUCCESS_GET_DATA: 'CUSTOM_BRANDING_SUCCESS_GET_DATA',
  CUSTOM_BRANDING_ERROR_GET_DATA: 'CUSTOM_BRANDING_ERROR_GET_DATA',
  CUSTOM_BRANDING_DELETE_WORKOUT_BACKGROUND: 'CUSTOM_BRANDING_DELETE_WORKOUT_BACKGROUND',
  CUSTOM_BRANDING_SUCCESS_DELETE_WORKOUT_BACKGROUND: 'CUSTOM_BRANDING_SUCCESS_DELETE_WORKOUT_BACKGROUND',
  CUSTOM_BRANDING_SUCCESS_UPDATE_PARTIAL_DATA: 'CUSTOM_BRANDING_SUCCESS_UPDATE_PARTIAL_DATA',
  CUSTOM_BRANDING_ADD_WORKOUT_BACKGROUND: 'CUSTOM_BRANDING_ADD_WORKOUT_BACKGROUND',
  CUSTOM_BRANDING_REVOKE_OBJECT_URL: 'CUSTOM_BRANDING_REVOKE_OBJECT_URL',
  CUSTOM_BRANDING_RENDER_FILE_URL_REQUEST: 'CUSTOM_BRANDING_RENDER_FILE_URL_REQUEST',
  CUSTOM_BRANDING_RENDER_FILE_URL_SUCCESS: 'CUSTOM_BRANDING_RENDER_FILE_URL_SUCCESS',
  CUSTOM_BRANDING_UPLOAD_FILES_REQUEST: 'CUSTOM_BRANDING_UPLOAD_FILES_REQUEST',
  CUSTOM_BRANDING_UPLOAD_FILES_SUCCESS: 'CUSTOM_BRANDING_UPLOAD_FILES_SUCCESS',
  CUSTOM_BRANDING_APPICON_PUBLISH_REQUEST: 'CUSTOM_BRANDING_APPICON_PUBLISH_REQUEST',
  CUSTOM_BRANDING_APPICON_PUBLISH_SUCCESS: 'CUSTOM_BRANDING_APPICON_PUBLISH_SUCCESS',
  CUSTOM_BRANDING_APPICON_CANCEL_REQUEST: 'CUSTOM_BRANDING_APPICON_CANCEL_REQUEST',
  CUSTOM_BRANDING_APPICON_CANCEL_SUCCESS: 'CUSTOM_BRANDING_APPICON_CANCEL_SUCCESS',
  CUSTOM_BRANDING_CHANGE_ACTIVE_TAB_REDUX: 'CUSTOM_BRANDING_CHANGE_ACTIVE_TAB_REDUX',
  CUSTOM_BRANDING_CHANGE_WELCOME_TEXT_COLOR: 'CUSTOM_BRANDING_CHANGE_WELCOME_TEXT_COLOR',
  CUSTOM_BRANDING_PREVIEW_DRAFT: 'CUSTOM_BRANDING_PREVIEW_DRAFT',
  CUSTOM_BRANDING_SET_DEFAULT_APP_ICON: 'CUSTOM_BRANDING_SET_DEFAULT_APP_ICON',
  CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_REQUEST: 'CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_REQUEST',
  CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_SUCCESS: 'CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_SUCCESS',
  CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_FAILED: 'CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_FAILED',
  CUSTOM_BRANDING_RESET_DEFAULT_APP_ICON: 'CUSTOM_BRANDING_RESET_DEFAULT_APP_ICON',
};

const getDataSuccess = data => ({ type: Types.CUSTOM_BRANDING_SUCCESS_GET_DATA, payload: { data } });

const parseMobileSettingData = originalData => {
  const selectedBackground = originalData.selected_workout_background
    ? _.find(originalData.workout_background_libraries, w => w._id === originalData.selected_workout_background)
    : {};

  return {
    workoutBackgrounds: originalData.workout_background_libraries,
    selectedSplashLogo: { name: originalData.splash_logo_name || '', src: originalData.splash_logo || '' },
    selectedTodayScreenLogo: {
      src: originalData.today_screen_logo || null,
      name: originalData.today_screen_file_name || null,
    },
    selectedSplashBackground: {
      name: originalData.splash_background_name || '',
      src: originalData.splash_background || '',
    },
    selectedWorkoutBackground: Object.assign({}, selectedBackground),
    selectedAppIconKey: originalData.app_icon_key,
    selectedAppIconReview: _.get(originalData, 'app_icon_review', null),
    selectedAppIconUrl: _.get(originalData, 'app_icon_review.app_icon_url', null),
    publisedAppIcon: _.get(originalData, 'app_icon_review.status', null),
    customOnboardingVideoType: _.get(
      originalData,
      'custom_onboarding_video_type',
      CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT,
    ),
    customOnboardingYoutube: _.get(originalData, 'custom_onboarding_youtube', null),
    customOnboardingUpload: _.get(originalData, 'custom_onboarding_upload', null),
    appIconKeySystem: _.get(originalData, 'app_icon_key_system', ''),
    appIconKeySystemUrl: _.get(originalData, 'app_icon_url', ''),
    appIconFlowType: _.get(originalData, 'app_icon_flow_type', 1),
  };
};

export const getCustomBrandingData = () => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_GET_DATA });

    return dispatch(
      Request.get(
        { url: '/api/team' },
        true,
        response => {
          const { data } = response.data;
          let theme;
          if (data.primary_color && data.secondary_color) {
            theme = { primaryColor: data.primary_color, secondaryColor: data.secondary_color };
          } else {
            theme = THEMES[0];
          }

          dispatch(
            getDataSuccess({
              ...parseMobileSettingData(data),
              emailBanner: { name: data.invite_email_banner_name || '', src: data.invite_email_banner || '' },
              originalTheme: theme,
              splash_screen_welcome_text_color: data.splash_screen_welcome_text_color,
            }),
          );
        },
        () => dispatch({ type: Types.CUSTOM_BRANDING_ERROR_GET_DATA }),
      ),
    );
  };
};

const updatePartialData = data => ({
  type: Types.CUSTOM_BRANDING_SUCCESS_UPDATE_PARTIAL_DATA,
  payload: { data },
});

export const updateCustomBranding = (bodyData, localData) => {
  return Request.post({ url: '/api/team/custom_brand/update', data: bodyData }, true, (response, { dispatch }) => {
    dispatch(updatePartialData(localData));
  });
};

export const updateCustomBrandingPremium = (bodyData, localData) => {
  return Request.put({ url: '/api/team/custom-branding', data: bodyData }, true, (response, { dispatch }) => {
    dispatch(updatePartialData(localData));
  });
};

export const addWorkoutBackgroundLibrary = bodyData => {
  return Request.patch(
    { url: '/api/team/workout-background-library', data: bodyData },
    true,
    (response, { dispatch }) => {
      dispatch(updatePartialData({ selectedWorkoutBackground: response.data.data.workout_background }));
    },
  );
};

export const publishAppIconCustomBrandingPremium = id => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_APPICON_PUBLISH_REQUEST });

    return dispatch(
      Request.put({ url: `/api/team/app-icon/${id}/publish` }, true, (response, { dispatch }) => {
        if (response.status) {
          dispatch(getCustomBrandingData());
        } else {
          dispatch(
            getDataSuccess({
              ...parseMobileSettingData(null),
            }),
          );
        }
      }),
    );
  };
};

export const cancelAppIconCustomBrandingPremium = id => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_APPICON_CANCEL_REQUEST });

    return dispatch(
      Request.put({ url: `/api/team/app-icon/${id}/cancel` }, true, (response, { dispatch }) => {
        dispatch({ type: Types.CUSTOM_BRANDING_APPICON_CANCEL_SUCCESS });
      }),
    );
  };
};

export const uploadFilesCustomBrandingPremium = urls => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_RENDER_FILE_URL_REQUEST });

    return dispatch(
      Request.post({ url: '/api/file/gen-presigned-urls-custom-brand', data: urls }, true, (response, { dispatch }) => {
        dispatch({ type: Types.CUSTOM_BRANDING_RENDER_FILE_URL_SUCCESS });
      }),
    );
  };
};

export const uploadAppIconCustomBrandingPremium = (url, data) => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_UPLOAD_FILES_REQUEST });

    return dispatch(
      Request.put({ url: url, data: data }, true, (response, { dispatch }) => {
        dispatch({ type: Types.CUSTOM_BRANDING_UPLOAD_FILES_SUCCESS });
      }),
    );
  };
};

export const addWorkoutBackground = data => ({
  type: Types.CUSTOM_BRANDING_ADD_WORKOUT_BACKGROUND,
  payload: { data },
});

export const deleteWorkoutBackground = id => {
  return Request.delete(
    { url: `/api/team/custom_brand/delete_workout_background/${id}` },
    false,
    (response, { dispatch }) => {
      dispatch({ type: Types.CUSTOM_BRANDING_SUCCESS_DELETE_WORKOUT_BACKGROUND, payload: { data: id } });
    },
  );
};

export const revokeLocalURL = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.CUSTOM_BRANDING_REVOKE_OBJECT_URL });
    const list = [];

    const {
      rootReducer: {
        customBranding: {
          selectedSplashLogo,
          selectedSplashBackground,
          selectedWorkoutBackground,
          emailBanner,
          workoutBackgrounds,
        },
      },
    } = getState();

    if (selectedSplashLogo.src && selectedSplashLogo.src.startsWith('blob:')) {
      list.push(selectedSplashLogo.src);
    }

    if (selectedSplashBackground.src && selectedSplashBackground.src.startsWith('blob:')) {
      list.push(selectedSplashBackground.src);
    }

    if (selectedWorkoutBackground.src && selectedWorkoutBackground.src.startsWith('blob:')) {
      list.push(selectedWorkoutBackground.src);
    }

    if (emailBanner.src && emailBanner.src.startsWith('blob:')) {
      list.push(emailBanner.src);
    }

    const localBackgrounds = _.filter(workoutBackgrounds, item => item.src.startsWith('blob:'));
    list.concat(_.map(localBackgrounds, 'src'));

    if (list.length) {
      revokeMultipleObjectURL(list);
    }
  };
};

export const changeActiveTab = index => ({
  type: Types.CUSTOM_BRANDING_CHANGE_ACTIVE_TAB_REDUX,
  payload: { index },
});

export const changeWelcomeTextColor = newColor => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_CHANGE_WELCOME_TEXT_COLOR, payload: { data: newColor } });
  };
};

export const showPreviewDraft = data => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_PREVIEW_DRAFT, payload: { data } });
  };
};

export const setDefaultAppIcon = item => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_SET_DEFAULT_APP_ICON, payload: { item } });
  };
};

export const resetDefaultAppIcon = () => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_RESET_DEFAULT_APP_ICON });
  };
};

export const setAppSystemIcon = id => {
  return dispatch => {
    dispatch({ type: Types.CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_REQUEST });

    return dispatch(
      Request.post(
        { url: '/api/team/app-icon-system/select', data: { id } },
        true,
        (response, { dispatch }) => {
          const { data } = response.data || {};

          dispatch({ type: Types.CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_SUCCESS, payload: { dataSystem: data } });
        },
        error => {
          dispatch({ type: Types.CUSTOM_BRANDING_SET_APP_ICON_SYSTEM_FAILED });
        },
      ),
    );
  };
};
