import React, { useState } from 'react';
import { cloneDeep, get } from 'lodash';

import Ownership from 'components/Ownership';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

import { TYPE } from './index';

function RenderOwnShip({
  ownerValue,
  shareValue,
  hasAvatar,
  hasSpacing,
  onSetShareValue,
  onSetOwnerValue,
  type,
  userId,
  hasYou,
  isEditAble = false,
  isTrainer = false,
  disableOwner = false,
}) {
  const [shared, setShared] = useState(shareValue);
  const [owner, setOwner] = useState(ownerValue);

  const handleChangeShare = (event, data) => {
    let shareVal = shareValue;

    shareVal.share = data.value;

    setShared(shareVal);
    onSetShareValue && onSetShareValue(shareVal);
  };

  const handleChangeOwner = (event, data) => {
    let ownerVal = cloneDeep(ownerValue);
    let shareVal = cloneDeep(shareValue);

    if (!ownerVal) {
      ownerVal = {};
    }
    ownerVal.owner = data.value;
    if (data.value === 'no_owner') {
      ownerVal.owner = null;
      shareVal.share = TEAM_SHARE_NOOWNER;
    } else if (shareVal.share === TEAM_SHARE_NOOWNER) {
      shareVal.share = TEAM_SHARE_PRIVATE;
    }

    onSetShareValue && onSetShareValue(shareVal);
    onSetOwnerValue && onSetOwnerValue(ownerVal);

    setShared(shareVal);
    setOwner(ownerVal);
  };

  return (
    <Ownership
      isEditAble={
        isEditAble
          ? isEditAble
          : type === TYPE.ADD ||
            type === TYPE.DUPLICATE ||
            (type === TYPE.UPDATE &&
              (get(shared, 'share', null) === TEAM_SHARE_NOOWNER || get(owner, 'owner', null) === userId))
      }
      owner={get(owner, 'owner', null)}
      share={get(shared, 'share', null)}
      hasAvatar={hasAvatar}
      hasSpacing={hasSpacing}
      handleChangeShare={handleChangeShare}
      handleChangeOwner={handleChangeOwner}
      hasYou={hasYou}
      isTrainer={isTrainer}
      disableOwner={disableOwner}
    />
  );
}

export default RenderOwnShip;
