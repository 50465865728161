import styled from 'styled-components';

const textBase = `
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: rgba(32, 35, 83, 1);
`;

export const AddNewButton = styled.div`
  border: 1px solid rgb(225, 225, 234);
  border-radius: 5px;
  padding: 11px 20px 10px 15px;
  gap: 8px;
  white-space: nowrap;
  color: rgba(32, 35, 83, 1);
  display: flex;
  align-items: center;
  ${textBase}
  font-size: 13px;
  height: 36px;
  cursor: pointer;
  margin-left: 4px;
  :hover {
    background-color: rgb(240, 241, 255);
    color: #5158cf;
    .plus-icon {
      path {
        stroke: #5158cf;
      }
    }
  }
  .plus-icon {
    width: 20px;
    min-width: 20px;
    height: 11.67px;
    path {
      stroke: rgba(32, 35, 83, 1);
    }
  }
  span {
    margin-left: -3px;
  }
`;

export const EmptyUIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-top: 200px;
  padding-right: 5px;
  .empty-banner {
    margin-bottom: 24px;
  }
  ${AddNewButton} {
    background-color: #fff;
  }
`;
