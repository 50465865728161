import React from 'react';
import styled from 'styled-components';
import { isMobile, isIOS } from 'react-device-detect';
import { CDN_URL } from 'constants/commonData';

function DownLoadAppBanner({ ratio, onClose }) {
  if (!isMobile) {
    return null;
  }

  const onClickDownload = () => {
    if (isIOS) {
      window.location = 'https://apps.apple.com/us/app/everfit-for-coach/id1485827117';
      return;
    }

    window.location = 'https://play.google.com/store/apps/details?id=com.everfit.coach';
  };

  return (
    <Container ratio={ratio}>
      <img src={`${CDN_URL}/images/close_icon_new.svg`} alt="" className="close" onClick={onClose} />
      <div className="center-content">
        <img src={`${CDN_URL}/images/logo_dark_bg.png`} alt="" />
        <div className="text">
          <h1>Everfit for Coach</h1>
          <h3>The most powerful Coach app!</h3>
        </div>
      </div>
      <div className="install" onClick={onClickDownload}>
        Install
      </div>
    </Container>
  );
}

export default DownLoadAppBanner;

const Container = styled.div`
  background: #f5f5f5;
  box-shadow: inset 0px -1px 0px #eaeaea;
  padding: ${props => `${9 * props.ratio}px ${24 * props.ratio}px ${9 * props.ratio}px ${18 * props.ratio}px`};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 999999;
  position: fixed;
  left: 0;
  right: 0;

  .close {
    margin-right: 15px;
    zoom: ${props => props.ratio};
  }

  .center-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 41px;
      height: 41px;
      margin-right: 10px;
      zoom: ${props => props.ratio};
    }

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 150%;
      color: #000000;
      margin: 0;
      zoom: ${props => props.ratio};
    }

    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #000000;
      margin: 0;
      zoom: ${props => props.ratio};
    }
  }

  .install {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #0171c2;
    zoom: ${props => props.ratio};
  }
`;
