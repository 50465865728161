import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import * as S from '../style';

const MultipleChoiceForm = ({ options }) => {
  return (
    <S.FormType>
      {options.map((option, idx) => (
        <S.TypeCheckbox key={idx}>
          <S.ContentCheckboxWrapper>
            <Checkbox checked={false} readOnly className="welcome-form-checkbox" />
            <S.Content>{option.label}</S.Content>
          </S.ContentCheckboxWrapper>
        </S.TypeCheckbox>
      ))}
    </S.FormType>
  );
};

export default MultipleChoiceForm;
