import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal } from 'semantic-ui-react';
import { toggleConfirmModal } from 'actions/modal';
import { openSecondToggle } from 'redux/broadcast-messages/actions';

import { convertMsToTime, isESCPress } from 'utils/commonFunction';
import { KEY_CODE } from 'constants/commonData';

import { ReactComponent as WarningIcon } from 'assets/icons/warning_red_light.svg';
import * as S from './style';

const TimerModal = props => {
  const { toggleConfirmModal, openSecondToggle, lastTime, countDownTime, timeStringProp } = props;
  const [timeString, setTimeString] = useState(timeStringProp);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const timeString = convertMsToTime(countDownTime - (currentTime - lastTime));
      setTimeString(timeString);
      if (currentTime - lastTime >= countDownTime) {
        setTimeString('00:00:00');
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, [timeString]);

  const onEscPressed = event => {
    if (event.keyCode === KEY_CODE.esc && isESCPress(event.key)) {
      openSecondToggle(false);
      toggleConfirmModal(false);
    }
  };

  return (
    <S.ModalWrapper open={true} closeOnDimmerClick={false} className="timer-confirm-modal">
      <Modal.Content className="confirm-content">
        <S.HeaderWrapper>
          <WarningIcon />
          <S.HeaderLabel className="confirm-header-label">Cannot send</S.HeaderLabel>
        </S.HeaderWrapper>
        <S.ContentWrapper>
          Please wait <b>{timeString.slice(3)}</b> before you can send new broadcast messages.
        </S.ContentWrapper>
        <S.ModalAction>
          <S.ConfirmButton
            onClick={() => {
              openSecondToggle(false);
              toggleConfirmModal(false);
            }}
          >
            Okay
          </S.ConfirmButton>
        </S.ModalAction>
      </Modal.Content>
    </S.ModalWrapper>
  );
};
const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  openSecondToggle: bindActionCreators(openSecondToggle, dispatch),
});

export default connect(null, mapDispatchToProps)(TimerModal);
