import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { NewSearchInput } from 'shared/SearchInput';
import { CDN_URL } from 'constants/commonData';

const baseText = `
    font-family: Open Sans;
    font-style: normal;
    color: #202353;
`;

const limitText = `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const CustomModal = styled(Modal)`
  &.ui.modal.welcome-form-modal {
    width: 660px;
    .close-button {
      top: -10px;
      right: -10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  &.ui.modal.welcome-form-modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: unset !important;
    background-color: #fff;
  }
  &.ui.modal.welcome-form-modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal.welcome-form-modal > .header {
    padding: 30px 30px 24px;
  }
  &.ui.modal.welcome-form-modal > .content {
    padding: 0 8px 0 30px;
    height: 528px;
  }
  &.ui.modal.welcome-form-modal > .actions {
    border-top: unset;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
  }
`;

export const ListWrapper = styled.div`
  padding: 0 18px 0 0;
  height: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    color: #d9d9d9 !important;
  }
`;

export const Header = styled.div`
  display: flex;
  row-gap: 24px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TabWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
  margin-bottom: 16px;
`;

export const TabItem = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  user-select: none;
  width: 125px;
  .tab-container {
    color: #202353;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    opacity: 0.6;
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;
  }
  ${props =>
    props.enableTab &&
    css`
      .tab-container {
        color: #5158cf;
        opacity: 1;
        border-bottom: 2px solid;
        font-weight: 700;
      }
    `}
`;

export const Title = styled.div`
  ${baseText}
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
`;

export const NewSearchForm = styled(NewSearchInput)`
  width: 270px;
  height: 40px;
  input {
    padding-left: 42px !important;
    border-radius: 5px;
    border: 1px solid #ebedf4;
    background: url(${CDN_URL}/images/search_new.svg) no-repeat;
    background-size: auto;
    background-position: 12px center;
    background-color: #fff !important;
    ::placeholder {
      color: #a6acc6;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &:focus {
      border: 1px solid #7072dd;
    }
  }
`;

export const EmptyFormWrapper = styled.div`
  margin-bottom: 58px;
`;

export const BodyTemplates = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 8px;
  cursor: pointer;
  margin-bottom: 32px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const FormHeader = styled.div`
  color: #202353;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 12px;
  opacity: 0.8;
`;

export const OptionWrapper = styled.div`
  width: 100%;
  height: 184px;
  padding: 19px;
  position: relative;
  border: 1px solid #dadfea;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: unset;
  gap: 16px;
  box-sizing: border-box;
  ${props =>
    props.active &&
    !props.disabled &&
    css`
      border-color: #5158cf;
    `}
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
  ${props =>
    props.isCustom &&
    css`
      height: auto;
      width: 570px;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: unset;
    `}
  &:hover {
    .action-duplicate-form {
      display: block;
      cursor: pointer;
    }
  }
`;

export const FormTemplatesHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OptionMainContent = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.isCustom &&
    css`
      padding-left: 16px;
    `}
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const FormIconWrapper = styled.div`
  display: flex;
  ${props =>
    props.isCustom &&
    css`
      align-items: center;
      svg {
        width: 36px;
        height: 36px;
      }
    `}
  ${props =>
    props.isTemplates &&
    css`
      svg {
        width: 24px;
        height: 24px;
      }
    `}
`;

export const OptionTitle = styled.div`
  ${baseText}
  color: #202353;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const OptionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OptionLabel = styled.div`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  justify-content: center;
  min-width: 87px;
  border-radius: 3px;
  background: #edf0fa;
  ${baseText}
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
`;

export const OptionDesc = styled.div`
  ${baseText}
  width: calc(100% - 18px);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgb(32, 35, 83, 0.8);
  margin-bottom: 8px;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;
export const OptionQuestion = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 4px;
  > span {
    ${baseText}
    color: rgb(32, 35, 83, 0.5);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }
  .action-duplicate-form {
    display: none;
    width: 20px;
    height: 20px;
    &:hover {
      path {
        fill: #5e59ff;
      }
    }
  }
  .__react_component_tooltip.app-tooltip.onboarding-form-duplicate {
    padding: 15px;
    background-color: #2d2e2d !important;
    ${baseText}
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    &.place-top {
      margin-top: -8px;
      &::after {
        bottom: -7px;
        border-top-color: #2d2e2d;
      }
    }
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const OptionContainer = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const QuestionNumber = styled.div`
  ${baseText}
  color: rgb(32, 35, 83, 0.5);
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
`;

export const CheckBoxCircle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 11px;
  width: 11px;
  background-color: #5e59ff;
  border-radius: 50%;
  opacity: 0;
`;

export const CheckBoxIconWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #c3c5cb;
  background-color: #fff;
  ${props =>
    props.active &&
    css`
      border: 1px solid #5e59ff;
      box-sizing: border-box;
      ${CheckBoxCircle} {
        opacity: 1;
      }
    `}
  ${props =>
    props.isCustom &&
    css`
      left: unset;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
  .ui.button.btn-welcome-form {
    width: 100%;
    height: 32px;
    ${baseText}
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .ui.button.btn-welcome-form-cancel {
    width: 92px;
    padding: 7px 25px;
    color: #818da1;
    border: 1px solid #d6dae4;
    background-color: #fff;
    margin-right: 5px;
    :hover {
      background-color: #f5f7f9;
    }
  }
  .ui.button.btn-welcome-form-next {
    min-width: 95px;
    padding: 7px 35px;
    background-color: #5158cf;
    color: #fff;
    margin-right: 0;
    :hover {
      background-color: rgb(81, 88, 207, 0.9) !important;
    }
  }
`;

export const ButtonWrapper = styled.div`
  ${props =>
    props.isLoading &&
    css`
      cursor: not-allowed;
      .btn-welcome-form-next {
        :hover {
          opacity: 1;
        }
        pointer-events: none;
      }
    `}
`;

export const SignatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4px;
  gap: 8px;
  .symbol-bullet {
    font-weight: 700;
    color: #c4c4c4;
  }
`;

export const FormInfoLayout = styled.div`
  width: 81px;
  height: 18px;
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  background-color: #edf0fa;

  :not(&.trigger) {
    svg {
      fill: #202870;
    }
  }

  &.trigger {
    width: unset;
    display: flex;
    padding: 1px 6px 2px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;

    background: linear-gradient(91.25deg, rgba(255, 76, 0, 0.14) 0.48%, rgba(224, 241, 27, 0.14) 98.93%), #ffffff;
  }

  .has-trigger-icon-tooltip {
    .__react_component_tooltip {
      &.app-tooltip {
        text-align: left;
        padding: 15px !important;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        background: #2d2e2d;
      }
      &.type-dark.place-top:after {
        border-top-color: #2d2e2d;
      }
    }
  }
`;

export const FormInfoText = styled.div`
  ${baseText}
  color: #202870;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const EmptySearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 161px 30px;
`;

export const EmptySearchText = styled.div`
  ${baseText}
  color: #b8bfda;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
`;

export const OwnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 6px;
  gap: 8px;
  .symbol-bullet {
    font-weight: 700;
    color: #c4c4c4;
  }
`;

export const OwnerContent = styled.div`
  display: flex;
  width: 100%;
  margin-left: 4px;
`;

export const OwnerLabel = styled.div`
  ${baseText}
  color: rgb(32, 35, 83, 0.5);
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
`;

export const OwnerName = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 150%;
  color: rgb(32, 35, 83, 0.5);
  font-weight: 700;
  ${limitText}
`;
