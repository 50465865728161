// Libs
import React from 'react';
import classNames from 'classnames';

// Shared
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

// Commons
import { PLANS } from 'constants/commonData';

// Styles
import * as S from './styles';

const UpgradePathIconTextButton = props => {
  const { wrapperClassNames } = props;

  return (
    <S.UpgradePathIconTextButton className={classNames('upgrade-path-icon-text-button', wrapperClassNames)}>
      <UpgradePathButton
        isHasChildren={true}
        planWillSelect={{
          tier: PLANS.pro.key,
          addOns: {
            automation: true,
          },
        }}
      >
        <img src="/images/circle-arrow-up-round.svg" alt="" />
        <div className="upgrade-path-button-label">Upgrade to use trigger</div>
      </UpgradePathButton>
    </S.UpgradePathIconTextButton>
  );
};

export default UpgradePathIconTextButton;
