import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import { CDN_URL } from 'constants/commonData';
import { TimeOptions } from '../constants';
import * as S from './style';

const DuringPeriodSelect = props => {
  const { newOnboardingMessage, setNewOnboardingMessage, onCloseRef } = props;
  const [open, setOpen] = useState(false);

  const from = useMemo(() => {
    const fromLabel = get(newOnboardingMessage, 'during_period_from', '09:00 AM');
    return isNil(fromLabel) ? TimeOptions[36] : find(TimeOptions, it => it.label === fromLabel);
  }, [newOnboardingMessage]);

  const to = useMemo(() => {
    const toLabel = get(newOnboardingMessage, 'during_period_to', '05:00 PM');
    return isNil(toLabel) ? TimeOptions[68] : find(TimeOptions, it => it.label === toLabel);
  }, [newOnboardingMessage]);

  const label = useMemo(() => `${from.label} - ${to.label}`, [from, to]);

  const isSelectedFrom = useCallback(it => it.label === from.label, [from]);
  const isSelectedTo = useCallback(it => it.label === to.label, [to]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const optionFrom = document.getElementById(`from-${from.minutes}`);
        optionFrom && optionFrom.scrollIntoView({ block: 'start' });

        const optionTo = document.getElementById(`to-${to.minutes}`);
        optionTo && optionTo.scrollIntoView({ block: 'start' });
      }, 0);
      onCloseRef.current = closePopup;
    }
  }, [open]);

  const openPopup = () => {
    setOpen(true);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const handleClickFrom = (e, { label }) => {
    e.preventDefault();
    setNewOnboardingMessage(it => ({ ...it, during_period_from: label }));
  };

  const handleClickTo = (e, { label }) => {
    e.preventDefault();
    setNewOnboardingMessage(it => ({ ...it, during_period_to: label }));
  };

  return (
    <S.FromToPopup
      trigger={
        <S.ButtonSelect open={open}>
          <S.Label>{label}</S.Label>
          <img src={`${CDN_URL}/images/arrow_down_black.svg`} alt="" />
        </S.ButtonSelect>
      }
      flowing={true}
      pinned={true}
      on="click"
      position="bottom left"
      open={open}
      onOpen={openPopup}
      onClose={closePopup}
      keepInViewPort={true}
    >
      <RootCloseWrapper>
        <S.Content>
          <S.Row>
            <S.Column left>
              <S.Title left>From</S.Title>
              <S.List>
                {TimeOptions &&
                  TimeOptions.map((it, index) => (
                    <S.Option
                      id={`from-${it.minutes}`}
                      key={`from-${it.value}-${index}`}
                      onClick={e => handleClickFrom(e, it)}
                      className={classNames({ selected: isSelectedFrom(it) })}
                    >
                      {it.label}
                    </S.Option>
                  ))}
              </S.List>
            </S.Column>
            <S.Column right>
              <S.Title right>To</S.Title>
              <S.List>
                {TimeOptions &&
                  TimeOptions.map((it, index) => (
                    <S.Option
                      id={`to-${it.minutes}`}
                      key={`to-${it.value}-${index}`}
                      onClick={e => handleClickTo(e, it)}
                      className={classNames({ selected: isSelectedTo(it) })}
                    >
                      {it.label}
                    </S.Option>
                  ))}
              </S.List>
            </S.Column>
          </S.Row>
        </S.Content>
      </RootCloseWrapper>
    </S.FromToPopup>
  );
};

export default DuringPeriodSelect;
