import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const CommentToggle = styled.div`
  background-color: #fff;
  background-image: url(${CDN_URL}/images/comment_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: #5158cf;
    background-image: url(${CDN_URL}/images/comment_hover_icon.svg);
  }

  ${props =>
    props.showing &&
    css`
      background-color: #5158cf;
      background-image: url(${CDN_URL}/images/comment_hover_icon.svg);
    `}
`;

export const CommentListContainer = styled.div`
  width: 345px;
  flex: 1;
  background: #ffffff;
  box-shadow: -2px 0px 16px rgba(108, 108, 108, 0.1);
  border-radius: 8px;
  margin-top: 10px;
`;

export const Wrapper = styled.div`
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  max-height: 773px;
`;
