import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import {
  changeTaskCalendarType,
  changeTaskCalendarStartDate,
  arrangeTask,
  moveTask,
  resetCopyItem,
} from 'redux/task/task.actionCreators';
import { toggleSideNav } from 'actions/sidenav';
import { toggleSideBar } from 'actions/sidebar';
import TaskListCalendar from './component';
import _ from 'lodash';

const mapStateToProps = state => {
  const {
    user,
    sideNavVisible,
    isModalOpen,
    modal,
    isConfirmModalOpen,
    confirmModal,
    errorPopup,
    isShowingError,
    rootReducer,
    calendarType,
    calendarStartDate,
  } = state;

  const { workingClientDetail } = rootReducer.client;
  let task_preference = workingClientDetail
    ? workingClientDetail.feature_preferences.find(item => item.type === 'task')
    : null;
  const isTaskFeaturing = task_preference ? task_preference.state : false;
  let { task } = rootReducer;

  return {
    user,
    taskCalendarType: calendarType,
    taskCalendarStartDate: calendarStartDate,
    day_tasks: task.day_tasks,
    sideNavVisible,
    isModalOpen,
    modal,
    isConfirmModalOpen,
    confirmModal,
    errorPopup,
    isShowingError,
    isTaskFeaturing,
    selectedClient: rootReducer.client.selected,
    isShowingSuccess: rootReducer.modal.isShowingSuccess,
    successPopup: rootReducer.modal.successPopup,
    copyingTask: task.copyingTask,
    clientName: `${workingClientDetail.first_name} ${workingClientDetail.last_name}`,
    repeatTaskPermission: rootReducer.permission.repeat_task,
    repeatTaskAutoFlowPermission: rootReducer.permission.repeat_task_autoflow,
  };
};

const handleInitData = dispatch => {
  dispatch(changeTaskCalendarStartDate());
};

const handleTodayClick = (dispatch, clientId, calendarType) => {
  const today = DateTime.local().startOf('week');
  const newEndDate = today.plus({ days: 7 * calendarType });

  dispatch(
    changeTaskCalendarStartDate(
      {
        client: clientId,
        start_date: today.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      },
      today,
    ),
  );
};

const handleTaskCalendarTypeChange = (dispatch, newStartDate, clientId, calendarType) => {
  const newEndDate = newStartDate.plus({ days: 7 * calendarType });
  dispatch(
    changeTaskCalendarType(
      {
        client: clientId,
        start_date: newStartDate.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      },
      calendarType,
    ),
  );
};

const handleTaskCalendarDateChange = (dispatch, goPrev, date, clientId, calendarType) => {
  const extra = goPrev ? -7 : 7;
  let newDate = DateTime.fromISO(date.toISODate());
  const newStartDate = newDate.plus({ days: extra });
  const newEndDate = newStartDate.plus({ days: 7 * calendarType });

  dispatch(
    changeTaskCalendarStartDate(
      {
        client: clientId,
        start_date: newStartDate.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      },
      newStartDate,
    ),
  );
};

const toggleSideNavigation = (dispatch, visible) => {
  dispatch(toggleSideNav(visible));
};

const onDragEnd = (dispatch, result, client, day_tasks) => {
  if (!result.source || !result.destination) {
    return;
  }

  const source = result.source;
  const destination = result.destination;

  if (source.droppableId === destination.droppableId && destination.index === source.index) {
    return;
  }

  const [to, toTaskDayId] = destination.droppableId.split(';');
  const [, fromTaskDayId] = source.droppableId.split(';');
  const taskId = result.draggableId.split(';')[0];

  let newIndex = parseInt(destination.index);
  let tasksToTaskDay = [];
  if (!_.isEmpty(toTaskDayId)) {
    const toTaskDay = _.filter(day_tasks, ['_id', toTaskDayId]);
    tasksToTaskDay = _.get(toTaskDay, '[0].tasks', []);
  }

  if (destination && source.droppableId !== destination.droppableId) {
    //Move from one workout to another
    if (!taskId) {
      console.error('Something wrong! Should add error handling here');
      return;
    }

    if (!_.isEmpty(toTaskDayId) && newIndex > tasksToTaskDay.length) {
      newIndex = tasksToTaskDay.length;
    }
    const currentDate = DateTime.local();
    const toDate = DateTime.fromFormat(to, 'MM-dd-yyyy');
    const newToDate = toDate.set({
      hour: currentDate.get('hour'),
      minute: currentDate.get('minute'),
      second: currentDate.get('second'),
    });
    dispatch(
      moveTask({
        taskId,
        newIndex,
        newDate: newToDate.toString(),
        client,
        dayTaskId: fromTaskDayId,
        timezone: DateTime.local().zoneName,
      }),
    );
  } else {
    //Move inside one workout
    if (!taskId) {
      console.error('Something wrong for rearrange! Should add error handling here');
      return;
    }

    if (!_.isEmpty(toTaskDayId) && newIndex > tasksToTaskDay.length - 1) {
      newIndex = tasksToTaskDay.length - 1;
      if (newIndex === source.index) return;
    }
    const currentDate = DateTime.local();
    const toDate = DateTime.fromFormat(to, 'MM-dd-yyyy');
    const newToDate = toDate.set({
      hour: currentDate.get('hour'),
      minute: currentDate.get('minute'),
      second: currentDate.get('second'),
    });
    dispatch(
      arrangeTask({
        taskId,
        newIndex,
        newDate: newToDate.toString(),
        dayTaskId: fromTaskDayId,
        client,
      }),
    );
  }
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    handleTodayClick: (clientId, calendarType) => handleTodayClick(dispatch, clientId, calendarType),
    handleInitData: () => handleInitData(dispatch),
    handleTaskCalendarTypeChange: (startDate, clientId, calendarType) =>
      handleTaskCalendarTypeChange(dispatch, startDate, clientId, calendarType),
    handleTaskCalendarDateChange: (goPrev, startDate, clientId, calendarType) =>
      handleTaskCalendarDateChange(dispatch, goPrev, startDate, clientId, calendarType),
    toggleSideNavigation: visible => toggleSideNavigation(dispatch, visible),
    onDragEnd: (result, client, day_tasks) => onDragEnd(dispatch, result, client, day_tasks),
    toggleSideBar: visible => dispatch(toggleSideBar(visible)),
    changeTaskCalendarStartDate: bindActionCreators(changeTaskCalendarStartDate, dispatch),
    resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskListCalendar);
