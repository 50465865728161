import { get, isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import ChooseFormPopup from 'shared/ChooseFormPopup';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { ReactComponent as CloseIcon } from 'assets/icons/close_thin.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add_grey.svg';
import { ReactComponent as FormItem } from 'assets/icons/form_doc.svg';
import { ReactComponent as SubmissionIcon } from 'assets/icons/submission.svg';

import * as S from './style';

const FormQuestionnaire = ({
  title,
  form,
  task,
  className,
  permission,
  onSelectForm,
  push,
  toggleModal,
  toggleSecondModal,
  disabled = false,
}) => {
  const formId = get(task, 'form._id', '');
  const submittedId = get(task, 'form_answer._id', '');
  const completedTask = get(task, 'status', 0) === 1;

  const handleOpenPopup = () => {
    toggleSecondModal &&
      toggleSecondModal(
        true,
        <ChooseFormPopup
          form={form}
          push={push}
          toggleModal={toggleModal}
          onSelectForm={onSelectForm}
          onClose={handleClosePopup}
        />,
      );
  };

  const handleClosePopup = () => {
    toggleSecondModal(false);
  };

  const handleOpenFormSubmission = () => {
    if (formId) {
      window.open(`/home/forms/${formId}/responses?client=${task.client}&submitted=${submittedId}`, '_blank');
    }
  };

  const handleRemoveForm = () => {
    if (disabled) return;
    onSelectForm({});
  };

  const renderCloseButton = () => {
    if (disabled) return;
    return <CloseIcon onClick={handleRemoveForm} className="delete" />;
  };

  return (
    <S.Wrapper className={className}>
      <S.AddFormTitle>{title || 'Forms & Questionnaires'}</S.AddFormTitle>
      {isEmpty(form) ? (
        <S.AddFormContainer onClick={handleOpenPopup}>
          <AddIcon width={16} height={16} />
          <span>Add a form</span>
        </S.AddFormContainer>
      ) : (
        <S.FormItem>
          <S.FormItemContent isArchived={form.is_archived}>
            <FormItem />
            <S.FormNameWrapper>
              <S.FormName>{form.name}</S.FormName>
              {form.is_archived ? <S.ArchivedText>Archived</S.ArchivedText> : null}
            </S.FormNameWrapper>
          </S.FormItemContent>
          {completedTask && permission.form_questionnaire ? (
            <S.SubmissionWrapper onClick={handleOpenFormSubmission}>
              <span>View submission</span>
              <SubmissionIcon />
            </S.SubmissionWrapper>
          ) : (
            renderCloseButton()
          )}
        </S.FormItem>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    permission: state.rootReducer.permission,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormQuestionnaire);
