// Libs
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as CloseButton } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// Actions
import { getFormTemplates } from 'redux/forms/actions';

// Components
import AddNewForm from '../AddNewForm';
import LoadingIndicator from 'shared/LoadingIndicator';

// Constants
import { pluralize } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_PRIVATE } from 'constants/commonData';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/FormQuestionnaires/plus.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/onboarding-flow-signature.svg';
import { ReactComponent as FormDocumentSvg } from 'assets/icons/onboarding-forms-document.svg';

// Styles
import * as S from './style';

const AddFormBuilder = ({
  user,
  toggleModal,
  onAddNewForm,
  formTemplates,
  onAddFormTemplate,
  oldSelected,
  getFormTemplates,
  isLoadingTemplate,
}) => {
  const [selected, setSelected] = useState(oldSelected || {});

  useEffect(() => {
    if (!isEmpty(formTemplates)) return;
    typeof getFormTemplates === 'function' && getFormTemplates();
  }, []);

  const openAddNewForm = () => {
    toggleModal(
      true,
      <AddNewForm
        user={user}
        toggleModal={toggleModal}
        onSubmit={onAddNewForm}
        onAddFormTemplate={onAddFormTemplate}
        formTemplates={formTemplates}
        isShowBackButton
        selected={selected}
      />,
    );
  };

  const handleAddFormTemplate = () => {
    if (isEmpty(selected) || isEmpty(user)) return;
    const { _id = '', title = '' } = selected || {};

    const params = {
      duplicated_system_form: _id,
      name: title,
      owner: (user || {})._id,
      share: TEAM_SHARE_PRIVATE,
    };

    typeof onAddFormTemplate === 'function' && onAddFormTemplate(params, handleCloseModal);
  };

  const handleCloseModal = () => {
    if (typeof toggleModal === 'function') {
      toggleModal(false);
    }
  };

  const CheckBoxIcon = ({ isCustom, active }) => {
    return (
      <S.CheckBoxIconWrapper isCustom={isCustom} active={active}>
        <S.CheckBoxCircle />
      </S.CheckBoxIconWrapper>
    );
  };

  return (
    <S.Wrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={handleCloseModal}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <S.Header>
        <h2 className="title">Add New Form</h2>
      </S.Header>
      <S.Content>
        <S.AddFormFromScratch onClick={openAddNewForm}>
          <PlusIcon />
          <span>Start from scratch</span>
        </S.AddFormFromScratch>
        <S.FormTemplateWrapper>
          <S.HeaderTemplate>Form Templates</S.HeaderTemplate>
          <S.BodyTemplate>
            {!isLoadingTemplate && !isEmpty(formTemplates) ? (
              formTemplates.map(form => {
                const { _id = '', title = '', description = '', number_of_questions = 0, has_signature = false } =
                  form || {};
                const isActive = _id === (selected || {})._id;

                return (
                  <S.OptionWrapper key={_id} active={isActive} onClick={() => setSelected(form)}>
                    <S.OptionTopContent>
                      <S.TopLeft>
                        <S.FormIconWrapper>
                          <FormDocumentSvg />
                        </S.FormIconWrapper>
                      </S.TopLeft>
                      <CheckBoxIcon active={isActive} />
                    </S.OptionTopContent>
                    <S.OptionMainContent>
                      <S.OptionTitle>{title}</S.OptionTitle>
                      <S.OptionDesc>{description}</S.OptionDesc>
                      <S.OptionQuestion>
                        <S.OptionContainer>
                          <span>{`${pluralize('Question', number_of_questions)}`}: </span>
                          <S.QuestionNumber>{number_of_questions}</S.QuestionNumber>
                          {has_signature && (
                            <S.SignatureWrapper>
                              <span className="symbol-bullet">•</span>
                              <S.FormInfoLayout>
                                <SignatureIcon />
                                <S.FormInfoText>Signature</S.FormInfoText>
                              </S.FormInfoLayout>
                            </S.SignatureWrapper>
                          )}
                        </S.OptionContainer>
                      </S.OptionQuestion>
                    </S.OptionMainContent>
                  </S.OptionWrapper>
                );
              })
            ) : (
              <S.OptionEmptyWrapper>
                <LoadingIndicator title="Loading" size="small" className="loading--form-templates" />
              </S.OptionEmptyWrapper>
            )}
          </S.BodyTemplate>
        </S.FormTemplateWrapper>
      </S.Content>
      <S.Actions>
        <S.RightAction>
          <S.Button onClick={handleCloseModal}>Cancel</S.Button>
          <S.Button className="add-btn" onClick={handleAddFormTemplate} disabled={isEmpty(selected)}>
            Add
          </S.Button>
        </S.RightAction>
      </S.Actions>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { forms },
  } = state;
  return {
    formTemplates: get(forms, 'formTemplates', []),
    isLoadingTemplate: get(forms, 'isLoadingTemplate'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFormTemplates: bindActionCreators(getFormTemplates, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFormBuilder);
