import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 345px;

  margin: 8px 3px 7px 14px;

  font-family: 'Open Sans';
  .checkbox-icon {
    background-size: 69%;
    background-position: 3px 4px;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  height: 25px;
  margin-top: 18px;

  .info-icon {
    width: 12px;
    height: 12px;
    margin: 10px 6px;
    :hover {
      cursor: pointer;
      path {
        fill: #5158cf;
        opacity: 1;
      }
    }
  }

  .__react_component_tooltip {
    padding: 15px;
    border-radius: 5px;
    background: #2d2e2d;
    width: 244px;
    color: #fff;

    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const HeaderLeftBar = styled.div``;

export const BroadcastTitle = styled.div`
  margin: 7px 4px;

  color: #202353;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
`;

export const FilterWrapper = styled.div`
  margin-top: ${props => !props.isFromWorkoutSummary && '23px'};
  margin-left: 5px;
  margin-bottom: ${props => (props.isFromWorkoutSummary ? '30px' : '27px')};
  width: 323px;
`;

export const SearchContainer = styled.div`
  display: flex;
  background: #f6f7f9;
  border-radius: 5px;
  max-height: 40px;

  border: 1px solid transparent;

  :has(.search-input:focus-within) {
    border: 1px solid #726ee4 !important;
  }

  .search-input {
    flex: 1;

    svg {
      width: 15px;
      height: 15px;
      right: 10px;
      top: 49%;
    }

    input {
      padding-top: 12px;
      padding-bottom: 12px;
      color: #7b7e91;
      background-color: #f6f7f9;
      border: none;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;

      background: url(${CDN_URL}/images/search_grey_purple.svg) no-repeat;
      background-position: 13px 10px;

      ::placeholder {
        color: #a6acc6;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .clients-filter {
    margin-right: 0;
    margin: auto;
    margin-right: 10px;

    &.open {
      .broadcastMessage__filter-icon {
        background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon_hover.svg);
        background-color: #eaebff;
        border-radius: 3px;
      }
    }

    position: relative;

    ::after {
      position: absolute;
      z-index: 99;

      width: 8px;
      height: 8px;

      border-radius: 100px;
      border: 1px solid #fff;
      background: #ffa31a;
    }
  }
`;
export const UpdatedFilterIcon = styled.div`
  position: absolute;
  z-index: 99;

  width: 8px;
  height: 8px;

  border-radius: 50%;
  border: 1px solid #fff;
  background: #ffa31a;
  top: 4px;
  right: 3px;
`;

export const FilterStyles = styled.div`
  display: flex;
  position: relative;
  padding: 5px;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #a6acc6;
  cursor: pointer;
  width: 28px;
  height: 28px;

  :hover {
    background-color: #eaebff;
    border-radius: 3px;
  }
`;

export const ClientListWrapper = styled.div``;

export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 21px;
  margin-top: 2px;
  height: 24px;
  margin-bottom: 3px;
`;

export const ClientListTitle = styled.div`
  margin-left: 1px;
  color: #202353;
  font-size: 11px;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
`;
export const SelectedAllAction = styled.div`
  display: flex;
  color: #202353;
  font-size: 13px;
  font-weight: 400;
  line-height: 120%;
  align-items: center;

  margin-right: 22px;
  margin-bottom: 12px;

  .all-text {
    opacity: 0.4;
  }

  .checkbox-icon {
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 16px;
    font-weight: 600;
  }
`;

export const ContentListWrapper = styled.div`
  padding-top: 8px;
  height: 530px;
  @media (max-height: 700px) {
    max-height: calc(100vh - 130px);
  }
`;

export const ContentList = styled.div`
  overflow-y: scroll;
  height: 530px;
  @media (max-height: 700px) {
    max-height: calc(100% - 28px);
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    margin-right: 3px;
  }
`;

export const ClientInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const ClientName = styled.div`
  color: #111;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  margin-left: 11px;
  margin-top: 2px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LoadingWrapper = styled.div`
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-client {
    padding-top: 0;
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const ClientItemWrapper = styled.div`
  display: flex;
  height: 54px;
  margin-bottom: 4px;
  margin-left: 5px;
  padding: 10px 16px;
  width: 323px;

  cursor: pointer;

  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 5px;
  background: ${props => (props.checked ? '#f6f7ff' : '#fff')};

  :hover {
    background: #f6f7ff;
  }

  .checkbox-client-icon {
    font-size: 13px;
    margin-left: 5px;
    .checkbox-icon {
      border-radius: 5px;
    }
  }
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 455px;
  overflow: hidden;
  margin-right: 8px;
  margin-top: 1px;
`;

export const NotFoundIcon = styled.div`
  display: flex;
`;

export const NotFoundLabel = styled.div`
  color: #9a9da6;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CreateNewClientBtn = styled.div`
  height: 36px;
  width: 167px;
  padding: 8px 11px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #273d52;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
  .add-new-client-icon {
    margin-right: 12px;
  }
`;

export const LoadingItemStyles = styled.div`
  padding: 10px 16px 10px 16px;
`;
