import React from 'react';

function CoachBiosDetailHeader({ coachBioDetails }) {
  return (
    <>
      <div>{coachBioDetails.loading ? '' : `Edit ${coachBioDetails.isOwnerEdit ? 'Your' : 'Coach'} Bio`}</div>
    </>
  );
}
export default CoachBiosDetailHeader;
