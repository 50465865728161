import { GENERAL_STATUS_WAITLIST } from 'components/Package/components/common';

export const columns = [
  {
    key: 'phone-message',
    name: '',
  },
  {
    key: 'survey',
    name: 'Survey response',
  },
  {
    key: 'id',
    name: 'Requested date',
    sortable: true,
  },
  {
    key: 'status',
    name: 'Status',
  },
];

export const MOST_RECENT_KEY = 'id';

export const NO_LOAD_MORE = false;
export const NO_LOADING = false;

export const CONTACT_STATUS = {
  WAITING: GENERAL_STATUS_WAITLIST.WAITING,
  OFFERED: GENERAL_STATUS_WAITLIST.OFFERED,
  PURCHASED: GENERAL_STATUS_WAITLIST.PURCHASED,
  REJECTED_BY_CLIENT: GENERAL_STATUS_WAITLIST.REJECTED_BY_CLIENT,
  REJECTED_BY_COACH_WITHOUT_CONSULTATION: GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH_WITHOUT_CONSULTATION,
  REJECTED_BY_COACH_AFTER_CONSULTATION: GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH_AFTER_CONSULTATION,
  CANCELLED_BY_CLIENT: GENERAL_STATUS_WAITLIST.CANCELLED_BY_CLIENT,
  NO_RESPONSE: GENERAL_STATUS_WAITLIST.NO_RESPONSE,
};

export const CONTACT_MESSAGE_STATUS = {
  ALL: undefined,
  UNREAD_ONLY: false,
  READ_ONLY: true,
};
