import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 710px;
  margin: auto;
  padding-top: 60px;
  height: 100%;
  padding-bottom: 10px;
  overflow: auto;

  .addingClient__heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #202353;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .__react_component_tooltip {
      max-width: 450px;
    }
  }

  .addingClient__body {
    background: #FCFCFF;
    border: 1px solid #EBEDF4;
    box-shadow: 0px 4px 8px #F1F2F6;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .addingClient__search {
    width: 42.8%;
    background: #FFFFFF;
    border-right: 1px solid #EBEDF4;
    box-shadow: 0px 4px 8px #F1F2F6;
  }
  
  .addingClient__selectedClient {
    width: 57.2%;
  }
`;

export const InfoTooltipIcon = styled.div`
  width: 14px;
  height: 14px;
  background: url(${CDN_URL}/images/info_tooltip_icon.svg) no-repeat center center;
  margin-left: 10px;
`;