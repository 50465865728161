import React, { useState } from 'react';
import get from 'lodash/get';
import { RootCloseWrapper } from 'react-overlays';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_bold.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/MealPlans/check_blue.svg';
import * as S from './style';

const DropdownUnit = props => {
  const { options = [], value = {}, onchange } = props;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState(value);

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
  };

  const handleClosePopUp = () => {
    setOpenDropdown(false);
  };

  const handleOnClick = data => {
    setSelected(data);
    setOpenDropdown(false);
    onchange(data);
  };

  return (
    <S.DropdownWrapper>
      <S.TriggerWrapper onClick={handleOpenDropdown}>
        {get(selected, 'label', '')} <ArrowDownIcon />
      </S.TriggerWrapper>
      {openDropdown && (
        <RootCloseWrapper onRootClose={handleClosePopUp}>
          <S.List>
            {options.map(item => {
              const { key, label } = item;
              const active = key === get(selected, 'key', '');
              return (
                <S.Item active={active} onClick={() => handleOnClick(item)} key={key}>
                  {label}
                  {active && <CheckIcon />}
                </S.Item>
              );
            })}
          </S.List>
        </RootCloseWrapper>
      )}
    </S.DropdownWrapper>
  );
};

export default DropdownUnit;
