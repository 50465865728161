import { Types } from './actions';
import get from 'lodash/get';

const INITIAL_STATE = {
  surveyList: {
    data: [],
    total: 0,
  },
  statistic: {
    active: 0,
    all: 0,
    archive: 0,
  },
  savedSurvey: {},
  surveyDetails: {},
  loading: false,
  surveyDetailsIsLoading: false,
  surveyActionIsLoading: false,
  surveyStatisticIsLoading: false,
  isSubmitting: false,
  surveyQuestionLibraries: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SURVEY_CONTACT_RESET_SURVEY_LIST: {
      return {
        ...state,
        surveyList: {
          ...state.surveyList.total,
          data: [],
        },
        loading: true,
        isSubmitting: false,
      };
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_LIST_LOADING: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_LIST_SUCCESS:
      const surveyList = get(state, 'surveyList.data', []);
      const page = get(payload, 'filters.page', 1);
      let currentList = [];
      if (page > 1) {
        currentList = [...surveyList];
      }

      return {
        ...state,
        loading: false,
        surveyList: {
          total: get(payload, 'data.total', 0),
          data: [...currentList, ...get(payload, 'data.data', [])],
        },
      };
    case Types.SURVEY_CONTACT_FETCH_SURVEY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_QUESTION_LIBRARIES_SUCCESS: {
      return {
        ...state,
        surveyQuestionLibraries: get(payload, 'data.data', []),
      };
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_LOADING: {
      return Object.assign({}, state, {
        surveyStatisticIsLoading: true,
      });
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_SUCCESS: {
      const data = get(payload, 'data', {});
      return {
        ...state,
        surveyStatisticIsLoading: false,
        statistic: {
          ...state.statistic,
          ...data,
        },
      };
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_STATISTIC_FAIL: {
      return {
        ...state,
        surveyStatisticIsLoading: false,
      };
    }

    case Types.SURVEY_CONTACT_FETCH_SURVEY_DETAILS_LOADING: {
      return {
        ...state,
        surveyDetailsIsLoading: true,
      };
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_DETAILS_SUCCESS: {
      const data = get(payload, 'data', {});
      return {
        ...state,
        surveyDetailsIsLoading: false,
        surveyDetails: data,
      };
    }
    case Types.SURVEY_CONTACT_FETCH_SURVEY_DETAILS_FAIL: {
      return {
        ...state,
        surveyDetailsIsLoading: false,
        surveyDetails: {},
      };
    }
    case Types.SURVEY_CONTACT_ARCHIVE_SURVEY_LOADING: {
      return {
        ...state,
        surveyActionIsLoading: true,
      };
    }
    case Types.SURVEY_CONTACT_ARCHIVE_SURVEY_SUCCESS: {
      const currentId = get(payload, 'id', '');
      const surveyList = get(state, 'surveyList.data', []);
      const newSurveyList = surveyList.filter(item => currentId && item.id !== currentId);
      return {
        ...state,
        surveyActionIsLoading: false,
        surveyList: {
          data: newSurveyList,
          total: newSurveyList - 1,
        },
      };
    }
    case Types.SURVEY_CONTACT_ARCHIVE_SURVEY_FAIL: {
      return {
        ...state,
        surveyActionIsLoading: false,
      };
    }

    case Types.SURVEY_CONTACT_UNARCHIVE_SURVEY_LOADING: {
      return {
        ...state,
        surveyActionIsLoading: true,
      };
    }
    case Types.SURVEY_CONTACT_UNARCHIVE_SURVEY_SUCCESS: {
      const currentId = get(payload, 'id', '');
      const surveyList = get(state, 'surveyList.data', []);
      const newSurveyList = surveyList.filter(item => currentId && item.id !== currentId);
      return {
        ...state,
        surveyActionIsLoading: false,
        surveyList: {
          data: newSurveyList,
          total: newSurveyList - 1,
        },
      };
    }
    case Types.SURVEY_CONTACT_UNARCHIVE_SURVEY_FAIL: {
      return {
        ...state,
        surveyActionIsLoading: false,
      };
    }

    case Types.SURVEY_CONTACT_DELETE_SURVEY_LOADING: {
      return {
        ...state,
        surveyActionIsLoading: true,
      };
    }
    case Types.SURVEY_CONTACT_DELETE_SURVEY_SUCCESS: {
      const currentId = get(payload, 'id', '');
      const surveyList = get(state, 'surveyList.data', []);
      const newSurveyList = surveyList.filter(item => currentId && item.id !== currentId);
      return {
        ...state,
        surveyActionIsLoading: false,
        surveyList: {
          data: newSurveyList,
          total: newSurveyList - 1,
        },
      };
    }
    case Types.SURVEY_CONTACT_DELETE_SURVEY_FAIL: {
      return {
        ...state,
        surveyActionIsLoading: false,
      };
    }
    case Types.SURVEY_CONTACT_RESET_SURVEY_DETAILS: {
      return {
        ...state,
        surveyDetailsIsLoading: false,
        surveyDetails: {},
      };
    }
    case Types.SURVEY_CONTACT_SET_SAVED_SURVEY: {
      const survey = get(payload, 'survey', {});
      return {
        ...state,
        savedSurvey: {
          ...state.savedSurvey,
          ...survey,
        },
      };
    }
    case Types.SURVEY_CONTACT_RESET_SAVED_SURVEY: {
      return {
        ...state,
        savedSurvey: {},
      };
    }

    default:
      return state;
  }
};
