import styled, { css } from 'styled-components';

export const SortIcon = styled.svg`
  margin-left: 8px;
  flex: 0 0 10px;

  ${props =>
    props.active &&
    css`
      path {
        stroke: #5158cf;
      }
    `}
`;

export const CellIcon = styled.img`
  margin-right: 5px;
`;

export const CellTitle = styled.span``;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 15px;

    :first-child {
      margin-left: 0;
    }
  }

  .evf-dropdown {
    .evf-dropdown__menu {
      background: #2d2e2d;
      border-radius: 5px;
      padding: 7px 0;
      min-width: 140px;
    }

    .evf-dropdown__option {
      padding: 8px 15px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #ffffff;

      .icon {
        width: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 12px;
        }
      }
    }

    :hover,
    &.open {
      .actions-trigger {
        background-color: #dae0e4;
      }
    }
  }

  .owner-avatar {
    opacity: 1;
  }
`;

export const TableCell = styled.td`
  padding: 20px 15px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #222222;

  ${props =>
    props.alignCenter &&
    css`
      text-align: center;
    `}

  ${props =>
    props.alignRight &&
    css`
      text-align: right;
    `}

  ${props =>
    props.flexCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${props =>
    props.verticalAlignTextTop &&
    css`
      vertical-align: text-top;
    `}
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #f0f0f0;
`;

export const TableHeaderCellContent = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  .column-symbol {
    margin-right: 5px;
  }

  svg {
    path {
      /* fill: #a1a1a5; */
    }
  }

  ${props =>
    props.active &&
    css`
      color: #5158cf;
      .column-symbol {
        fill: #5158cf;

        path {
          fill: #5158cf;
        }
      }
    `}

  ${props =>
    props.hasSort &&
    css`
      cursor: pointer;
    `}
`;

export const TableHeaderCell = styled.th`
  padding: 18px 15px;
  z-index: 1;
  box-shadow: 0px 2px 0px #f1f3f4;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #a1a1a5;
  text-align: left;
  &:nth-child(1) {
    width: 29% !important;
  }
  &:nth-child(2) {
    width: 12.5% !important;
  }
  &:nth-child(3) {
    width: 13% !important;
  }

  ${props =>
    props.alignCenter &&
    css`
      text-align: center;

      ${TableHeaderCellContent} {
        justify-content: center;
      }
    `}
  ${props =>
    props.isActive &&
    css`
      span {
        color: #5158cf;
      }
    `}
`;

export const TableHeader = styled.thead`
  position: relative;
  z-index: 1;
`;

export const TableBody = styled.tbody`
  ${TableRow} {
    :hover {
      background-color: #f8f8f8;
      cursor: pointer;

      .thumbnail-task {
        background-color: #f8f8f8;
      }
    }
  }
`;

export const TableFooter = styled.tfoot``;

export const Table = styled.table`
  border-spacing: 0 0;
  border-collapse: collapse;
  position: relative;
  width: 100%;
`;

export const NoResultText = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  opacity: 0.3;
  margin: 0 0 90px;
  text-align: center;
`;

export const NoResult = styled.div`
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button.button--add-new-item {
    background: #ffffff;
    border: 1px solid #5c5bbd;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #5c5bbd;
    padding: 7px 20px;
  }
`;

export const TableContainer = styled.div`
  flex: 1 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const PaginationContainer = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;
`;

export const ToolbarContainer = styled.div`
  flex: 0 0 auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  /* padding-right: 20px; */
  padding-top: 10px;
  .__react_component_tooltip.app-tooltip {
    max-width: 330px;
  }

  button.ai-btn {
    margin-left: 8px;
    margin-right: 3px;
    height: 30px;
    background: linear-gradient(265.24deg, #ffd1b7 0%, #f9fce2 100%);
    border: none;
    color: #202353;
    padding: 10px 15px;
    &:focus-visible {
      outline: none;
    }
    svg {
      margin-right: 5px;
    }

    &:hover {
      background: linear-gradient(265.24deg, rgba(255, 209, 183, 0.9) 0%, rgba(249, 252, 226, 0.9) 100%);
    }
  }

  button.button--add-new-library-instance {
    cursor: pointer;
    padding: 0 24px;
    margin-left: 5px;
    height: 30px;

    border-radius: 5px;
    background: #5158cf;
    border: none;

    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    img {
      width: 12px;
      height: 12px;
      margin-right: 10px;
    }

    &:hover {
      background: rgba(81, 88, 207, 0.9);
    }
  }

  button.button--hide-exercise-instance {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 21px;
  }

  button.button--remove-section-instance {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 21px;
    img {
      margin-bottom: 3px;
    }
  }

  button.button--add-new-section-lib {
    cursor: pointer;
    padding: 0 24px;
    margin-left: 5px;
    height: 30px;

    border-radius: 5px;
    background: #5158cf;
    border: none;

    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    img {
      width: 12px;
      height: 12px;
      margin-right: 10px;
    }

    &:hover {
      background: rgba(81, 88, 207, 0.9);
    }
  }

  .search-input {
    width: 265px;
    height: 30px;

    input {
      padding-left: 45px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px 0 30px;
`;
