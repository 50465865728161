import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import { ReactComponent as TrashIcon } from 'assets/icons/trash_swap_out_exercise.svg';

import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

import OwnerAvatar from 'shared/OwnerAvatar';
import { Checkbox } from 'shared/FormControl';
import { SharedTooltip } from 'shared/SharedTooltip';
import { CDN_URL } from 'constants/commonData';

function ItemExercise(props) {
  const {
    onRemove,
    onClick,
    isDefaultExerciseItem = false,
    isPopupExerciseItem = false,
    item = {},
    cloudfrontList,
    user,
    getS3presignedURLFromLocalDatabase,
    isChecked = false,
  } = props;

  const itemExercise = isPopupExerciseItem ? item : item.exercise;
  const { title = '', preview_300 = '', picture = [], video = '', videoLink = '', custom = false, author, _id = '' } =
    itemExercise || {};

  const [thumbnail, setThumbnail] = useState();

  const dataAuthor = useMemo(() => {
    if (!user || !author) return null;
    const hasTeam = user.team_member && user.team_member.length > 1;
    let authorId;
    if (typeof author === 'object') {
      authorId = author._id;
    } else if (typeof author === 'string') {
      authorId = author;
    }
    return hasTeam && authorId ? find(user.team_member, item => item && item._id === authorId) : null;
  }, [user, author]);

  const getThumbnail = useCallback(async () => {
    const urlThumbnail = preview_300 || picture[0] || '';

    try {
      const itemCloudUrl = convertS3UrlToCloudFrontUrl(urlThumbnail, cloudfrontList);
      if (itemCloudUrl) {
        setThumbnail(itemCloudUrl);
        return;
      }

      const [s3PresignedUrl] = await getS3presignedURLFromLocalDatabase([urlThumbnail]);
      setThumbnail(s3PresignedUrl || urlThumbnail);
    } catch {
      setThumbnail(urlThumbnail);
    }
  }, []);

  useEffect(() => {
    getThumbnail();
  }, []);

  const isVideo = video || videoLink || '';
  const showActions = isDefaultExerciseItem;

  const handleItemClick = event => {
    event.preventDefault();
    event.stopPropagation();
    typeof onClick === 'function' && onClick(itemExercise || {});
  };

  const handleRemove = event => {
    event.preventDefault();
    event.stopPropagation();
    typeof onRemove === 'function' && onRemove(item);
  };

  const onLoadThumbnailError = event => {
    event.preventDefault();

    event.target.onError = null;
    event.target.src = `${CDN_URL}/images/exercise_grey.svg`;
    event.target.className = 'thumbnail-default-image';
  };

  const renderThumbnail = () => {
    if (isEmpty(thumbnail)) return <div className="thumbnail" />;

    return <img src={thumbnail} alt="" className="thumbnail" onError={onLoadThumbnailError} />;
  };

  return (
    <div
      className={classNames('exercise-item-wrapper', {
        'default-exercise-item': isDefaultExerciseItem,
        'popup-exercise-item': isPopupExerciseItem,
        'selected-exercise': isChecked,
      })}
      onClick={handleItemClick}
    >
      <div
        className={classNames('exercise-thumbnail', {
          video: isVideo,
        })}
      >
        {isPopupExerciseItem && (
          <div className="checkbox-thumbnail">
            <Checkbox checked={isChecked} className="exercise-checkbox" size={16} />
          </div>
        )}
        {renderThumbnail()}
      </div>
      <div className="exercise-title-wrapper">
        <div className="exercise-title">{title}</div>
        {custom && <div className="exercise-custom-tag">Custom</div>}
      </div>
      {isPopupExerciseItem && custom && dataAuthor && (
        <OwnerAvatar
          data={dataAuthor}
          cloudfrontList={cloudfrontList}
          tooltipId={`tooltip--owner-${_id}`}
          size={24}
          className="exercise-owner-avatar"
        />
      )}
      {showActions && (
        <div className="exercise-actions">
          {isDefaultExerciseItem && (
            <>
              <div
                className="exercise-btn-remove"
                onClick={handleRemove}
                data-tip
                data-for="remove-exercise-icon-tooltip"
              >
                <TrashIcon />
              </div>
              <SharedTooltip id="remove-exercise-icon-tooltip" content="Remove" />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ItemExercise;
