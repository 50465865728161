import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TaskComment from './component';
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';

const mapStateToProps = state => {
  const { isModalOpen, rootReducer } = state;
  return {
    clientId: rootReducer.client.selected,
    isModalOpen,
    location: state.router.location,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskComment);
