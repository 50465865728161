import React, { useEffect, useRef, useState } from 'react';
import Select from '../Select';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProfileTempOnboardingQuestionnaire, logout, submitQuestionnaireTraffic } from 'actions/auth/login';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import Options from '../options';
import { CDN_URL } from 'constants/commonData';
import { ReactComponent as Banner } from 'assets/icons/onboarding-banner.svg';
import { Header, NotACoachSection, QuestionContainer } from '../style';
import * as S from './style';
import NotACoachModal from '../NotACoachModal';

function TrackingFrom(props) {
  const [selectedValue, setSelectedValue] = useState();
  const [openModal, setOpenModal] = useState(false);
  const timeoutIdRef = useRef(null);

  const onSelectAnswer = value => {
    setSelectedValue(value);
    const params = { q_6: get(value, 'label', ''), is_click_next: false };
    const submitTraffic = () => props.submitQuestionnaireTraffic(params);

    if (window.Cypress) {
      timeoutIdRef.current = setTimeout(submitTraffic, 2000);
    } else {
      submitTraffic();
    }
  };

  const handleUpdateFillOptionBefore = () => {
    props.getProfileTempOnboardingQuestionnaire().then(res => {
      const data = get(res, 'data.data', {});
      const filledData = Options.q6.find(it => it.label === data['q_6']);
      !!filledData && setSelectedValue(filledData);
    });
  };

  useEffect(() => {
    handleUpdateFillOptionBefore();
  }, []);

  useEffect(() => {
    const isIOSDevice = /iPad|iPhone/.test(navigator.userAgent);
    const actionString = isIOSDevice ? 'pagehide' : 'beforeunload';

    const handleLogout = () => {
      logout();
    };

    if (openModal) {
      window.addEventListener(actionString, handleLogout);
    }

    return () => {
      window.removeEventListener(actionString, handleLogout);
    };
  }, [openModal]);

  const handleOpenModalNotACoach = () => {
    setOpenModal(true);
    const params = { not_a_coach: true, q_6: get(selectedValue, 'label', ''), is_click_next: false };
    props.submitQuestionnaireTraffic(params);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmitAnswer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    if (selectedValue) {
      const param = {
        q_6: get(selectedValue, 'label'),
        not_a_coach: false,
        is_click_next: true,
      };

      props.submitQuestionnaireTraffic(param).then(() => {
        props.onFinished();
      });
    }
  };

  return (
    <S.QuestionnaireContainer isMobile={isMobile}>
      <Helmet>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Helmet>
      <Header>
        <img src={`${CDN_URL}/images/everfit_new_logo.svg`} width="171" alt="everfit logo" />
      </Header>
      <S.Body>
        <S.Banner>
          <Banner />
          <S.BannerIntro>
            <S.BannerTitle>We’d like to get to know you</S.BannerTitle>
            <S.BannerDesc>
              Based on your answers, we will suggest features that work best for you and your team.
            </S.BannerDesc>
          </S.BannerIntro>
        </S.Banner>
        <S.Questionnaire>
          <NotACoachModal onCloseModal={handleCloseModal} openModal={openModal} />
          <S.QuestionSelect isMobile={isMobile}>
            <S.QuestionTitle>How did you hear about us?</S.QuestionTitle>
            <QuestionContainer className="questionnaire questionnaire-custom">
              <Select
                autoOpen={!selectedValue}
                options={Options.q6}
                value={selectedValue}
                onSelect={option => onSelectAnswer(option)}
              />
            </QuestionContainer>
          </S.QuestionSelect>

          <S.ButtonWrapper>
            <div className="actions">
              <S.NextButton purple disabled={!selectedValue} onClick={handleSubmitAnswer}>
                Next
              </S.NextButton>
              {isMobile && <NotACoachSection onClick={handleOpenModalNotACoach}>Not a Coach?</NotACoachSection>}
            </div>
          </S.ButtonWrapper>
        </S.Questionnaire>
      </S.Body>
      {!isMobile && <NotACoachSection onClick={handleOpenModalNotACoach}>Not a Coach?</NotACoachSection>}
    </S.QuestionnaireContainer>
  );
}
const mapDispatch = dispatch => {
  return {
    submitQuestionnaireTraffic: bindActionCreators(submitQuestionnaireTraffic, dispatch),
    getProfileTempOnboardingQuestionnaire: bindActionCreators(getProfileTempOnboardingQuestionnaire, dispatch),
  };
};

const mapProps = state => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapProps, mapDispatch)(TrackingFrom);
