// Libs
import React from 'react';

// Parts
import OptionItem from './OptionItem';

const TabContent = props => {
  const { options, type, currentTab } = props;

  if (currentTab !== type || !Array.isArray(options)) return null;

  return (
    <div className="scrollable-options">
      {options.map((option, idx) => (
        <OptionItem option={option} type={type} key={`item-${idx}`} />
      ))}
    </div>
  );
};

export default TabContent;
