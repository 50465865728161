import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import CreateNewForum from './component';
import { toggleConfirmModal } from 'actions/modal';
import { addNewGroup, addMember, getListPost, getListSchedulePost, getListUser } from 'redux/forum/actions';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleConfirmModal: (isOpen, modal) => dispatch(toggleConfirmModal(isOpen, modal)),
    addNewGroup: data => dispatch(addNewGroup(data)),
    push: url => dispatch(push(url)),
    addMember: (forumId, members) => dispatch(addMember(forumId, members)),
    getListPost: id => dispatch(getListPost(id)),
    getListSchedulePost: id => dispatch(getListSchedulePost(id)),
    getListUser: id => dispatch(getListUser(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewForum);
