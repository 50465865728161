import React from 'react';
import { connect } from 'react-redux';
import Pagination from 'shared/Pagination';
import { changeQueryParams } from 'redux/package-list/actions';

const Footer = ({ page, pageSize, changeQueryParams, total, isMP, pageMP }) => {
  const handleClickPrev = newPage => {
    changeQueryParams({ page: newPage }, isMP);
  };

  const handleClickNext = newPage => {
    changeQueryParams({ page: newPage }, isMP);
  };

  return (
    <Pagination
      page={isMP ? pageMP : page}
      perPage={pageSize}
      totalPage={total}
      onPrev={handleClickPrev}
      onNext={handleClickNext}
    />
  );
};

const mapStateToProps = state => {
  const { packageList } = state.rootReducer;

  return {
    page: packageList.getIn(['query', 'page']),
    pageMP: packageList.getIn(['queryMP', 'page']),
    pageSize: packageList.getIn(['query', 'pageSize']),
    total: packageList.get('total'),
  };
};

const actions = { changeQueryParams };

export default connect(mapStateToProps, actions)(Footer);
