import React from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import moment from 'moment';
import classNames from 'classnames';
import './style.scss';
import { Wrapper, Tabs, ClientListContainer, AssignMoreClient } from './style';
import { PROGRAM_IN_PROGRESS, PROGRAM_COMPLETED, CDN_URL } from 'constants/commonData';
import { Checkbox } from 'shared/FormControl';
import ButtonApp from 'shared/FormControl/Button';
import ConfirmModal from 'shared/ConfirmModal';
import { getUserShortName } from 'utils/commonFunction';
import AssignProgram from 'components/AssignProgram';

const DATE_FORMAT = 'M/D/YY';

class ProgramViewCLients extends React.Component {
  constructor(props) {
    super(props);
    const inProgress = _.filter(props.clients, item => !!item.program && item.program.status !== PROGRAM_COMPLETED);
    const complete = _.filter(props.clients, item => !!item.program && item.program.status === PROGRAM_COMPLETED);

    this.state = {
      status: PROGRAM_IN_PROGRESS,
      inProgress,
      complete,
      isSelectedInProgress: false,
      isSelectedComplete: false,
      loading: false,
    };
  }

  onSelectClient = (client, checked) => {
    const { status, inProgress, complete } = this.state;
    const filteredClients = status === PROGRAM_COMPLETED ? complete : inProgress;
    const foundIndex = filteredClients.findIndex(o => o._id === client._id);
    filteredClients[foundIndex].checked = checked;
    if (status === PROGRAM_IN_PROGRESS) {
      const isSelectedInProgress = _.findIndex(filteredClients, o => o.checked) !== -1;
      this.setState({ inProgress: [...filteredClients], isSelectedInProgress });
    }
  };

  onSelectAll = () => {
    const { status, inProgress, complete, loading } = this.state;
    if (loading) {
      return;
    }
    let filteredClients = status === PROGRAM_COMPLETED ? complete : inProgress;
    filteredClients = filteredClients.map(o => ({ ...o, checked: true }));
    if (status === PROGRAM_IN_PROGRESS) {
      const isSelectedInProgress = _.findIndex(filteredClients, o => o.checked) !== -1;
      this.setState({ inProgress: [...filteredClients], isSelectedInProgress });
    }
  };

  onDelete = () => {
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove clients from Program"
        content="When clients are removed, all workouts from the program that are assigned today onwards will be removed for each client. Would you like to proceed?"
        onConfirm={this.doDeleteClients}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
        confirmButtonTitle="Confirm"
        cancelButtonTitle="Cancel"
      />,
    );
  };

  doDeleteClients = () => {
    const { status } = this.state;
    let currentTab = status === PROGRAM_COMPLETED ? 'complete' : 'inProgress';
    const filteredClients = this.state[currentTab];
    const programs = _.filter(filteredClients, o => o.checked).map(item => item.program._id);
    this.setState({ loading: true });
    this.props
      .removeClientFromProgram({ programs })
      .then(() => {
        this.setState({ loading: false });
        const newList = filteredClients.filter(o => !programs.includes(o.program._id));
        this.setState(
          {
            [currentTab]: [...newList],
            isSelectedComplete: false,
            isSelectedInProgress: false,
          },
          () => {
            // check close popup
            if (!this.state.complete.length && !this.state.inProgress.length) {
              this.props.toggleModal(false);
            }
          },
        );
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  onAssignMoreClients = () => {
    this.props.toggleModal(true, <AssignProgram program={this.props.program} from="program_calendar" />);
  };

  renderNoClient = () => {
    const { status } = this.state;
    const clientType = status === PROGRAM_COMPLETED ? 'Completed' : 'Active';
    return (
      <div className="viewClient__noClients">
        <div>
          <img className="viewClient__noClients--img" src={`${CDN_URL}/images/no_client_active.svg`} alt="" />
          <div className="viewClient__noClients--text">{`No ${clientType} Clients`}</div>
        </div>
      </div>
    );
  };

  renderList = () => {
    const { toggleModal, date_format } = this.props;
    const { status, inProgress, complete, loading } = this.state;
    const filteredClients = status === PROGRAM_COMPLETED ? complete : inProgress;
    const splitDate = date_format && date_format.split('/');
    const dateFormat = splitDate
      ? `${splitDate[0].slice(1)}/${splitDate[1].slice(1)}/${splitDate[2].slice(2)}`
      : DATE_FORMAT;

    if (!filteredClients.length) {
      return this.renderNoClient();
    }

    return (
      <div className="program__assignedClient__list">
        {_.map(filteredClients, item => (
          <div key={item._id} className="item">
            <div className={classNames('clientAvatar', { 'clientAvatar--checked': item.checked })}>
              <Checkbox
                checked={item.checked}
                className="clientAvatar__checkbox"
                onChange={event => {
                  event.stopPropagation();
                  this.onSelectClient(item, event.target.checked);
                }}
                disabled={loading}
              />
              <Avatar
                name={getUserShortName(item)}
                className="clientAvatar__avatar"
                size="36"
                src={item.avatar}
                color={item.color}
              />
            </div>
            <Link
              className="info app-link"
              to={`/home/client/${item._id}/calendar`}
              onClick={event => {
                if (event.metaKey || event.ctrlKey || event.shiftKey) {
                  return false;
                }

                toggleModal(false);
              }}
            >
              <div className="name">{`${item.first_name} ${item.last_name}`}</div>
              <div className="date">
                {moment(item.program.start_date).utc().format(dateFormat)} -{' '}
                {moment(item.program.end_date, 'MM-DD-YYYY').format(dateFormat)}
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { isModalOpen, toggleModal } = this.props;
    const { status, inProgress, complete, isSelectedInProgress, isSelectedComplete, loading } = this.state;
    const isShowActions = status === PROGRAM_COMPLETED ? isSelectedComplete : isSelectedInProgress;
    return (
      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        className="program-view-clients-modal"
        closeOnDimmerClick={false}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
      >
        <Modal.Header>
          <h3>Clients assigned to program</h3>
          <AssignMoreClient onClick={this.onAssignMoreClients}>
            <img src={`${CDN_URL}/images/add_multiple_client.svg`} alt="" />
            <span>Assign More Clients</span>
          </AssignMoreClient>
        </Modal.Header>
        <Modal.Content>
          <Wrapper>
            <Tabs className="program__assignedClients__tabs">
              <div
                className={`tab${status === PROGRAM_IN_PROGRESS ? ' active' : ''}`}
                onClick={() => this.setState({ status: PROGRAM_IN_PROGRESS })}
              >
                <div className="name">Active</div> <div className="count">({inProgress.length})</div>
              </div>
              <div
                className={`tab${status === PROGRAM_COMPLETED ? ' active' : ''}`}
                onClick={() => this.setState({ status: PROGRAM_COMPLETED })}
              >
                <div className="name">Completed</div> <div className="count">({complete.length})</div>
              </div>
            </Tabs>
            <ClientListContainer isCompeleted={status === PROGRAM_COMPLETED}>{this.renderList()}</ClientListContainer>
          </Wrapper>
        </Modal.Content>
        {isShowActions ? (
          <div className="assignClientModal__actions">
            <span className="assignClientModal__selectAll" onClick={this.onSelectAll}>
              Select All
            </span>
            <ButtonApp delete onClick={this.onDelete} disabled={loading}>
              <span>Remove</span>
            </ButtonApp>
          </div>
        ) : (
          <div style={{ minHeight: '59px' }} />
        )}
      </Modal>
    );
  }
}

export default ProgramViewCLients;
