// libs
import React from 'react';

// utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

// styles
import * as S from './style';

const RecipeItem = ({ recipe, onClick, cloudfrontList }) => {
  const { name, cover_image, cover_image_thumbnail } = recipe || {};

  const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
  const coverImageThumbnail = convertS3UrlToCloudFrontUrl(cover_image_thumbnail, cloudfrontList, true);

  return (
    <S.Wrapper className="item-wrapper" onClick={onClick}>
      <img className="item-background" src={coverImageThumbnail || coverImage} alt={name} />
      <div className="item-content">
        <span className="item-name">{name}</span>
      </div>
    </S.Wrapper>
  );
};

export default RecipeItem;
