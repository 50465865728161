import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import { toggleConfirmModal } from 'actions/modal';
import { sendBroadcastMessage } from 'redux/broadcast-messages/actions';

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  sendBroadcastMessage: bindActionCreators(sendBroadcastMessage, dispatch),
});

const mapStateToProps = state => {
  const { broadcastMessages } = state.rootReducer;
  const { isOpenSecondModal } = broadcastMessages;

  return {
    isOpenSecondModal: isOpenSecondModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
