export const EVERY = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
];

export const NEXT_WEEKS = Array.from(new Array(26)).map((_, index) => ({ value: index + 1, label: index + 1 }));

export const INTERVAL_TYPES = [
  { value: 'day', label: 'Days' },
  { value: 'week', label: 'Weeks' },
];

export const LIST_DAYS = [
  {
    value: 1,
    checked: false,
    label: 'M',
  },
  {
    value: 2,
    checked: false,
    label: 'T',
  },
  {
    value: 3,
    checked: false,
    label: 'W',
  },
  {
    value: 4,
    checked: false,
    label: 'T',
  },
  {
    value: 5,
    checked: false,
    label: 'F',
  },
  {
    value: 6,
    checked: false,
    label: 'S',
  },
  {
    value: 7,
    checked: false,
    label: 'S',
  },
];

export const LIST_ORDER_DAYS = [
  {
    value: 1,
    checked: false,
    label: '1st',
  },
  {
    value: 2,
    checked: false,
    label: '2nd',
  },
  {
    value: 3,
    checked: false,
    label: '3rd',
  },
  {
    value: 4,
    checked: false,
    label: '4th',
  },
  {
    value: 5,
    checked: false,
    label: '5th',
  },
  {
    value: 6,
    checked: false,
    label: '6th',
  },
  {
    value: 7,
    checked: false,
    label: '7th',
  },
];

export const NEXT_MONTHS = Array.from(new Array(11)).map((_, index) => ({ value: index + 2, label: index + 2 }));
