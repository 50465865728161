import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientInviteModalComponent from './component';
import { inviteNormalClient } from 'redux/client/client.actionCreators';
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import { DEFAULT_SUBMIT_STATUS } from 'constants/commonData';

const mapStateToProps = state => {
  const { isModalOpen, submitStatus, rootReducer, user, cloudfrontList } = state;
  return {
    isModalOpen,
    submitStatus: submitStatus.INVITE_CLIENT || DEFAULT_SUBMIT_STATUS,
    permission: rootReducer.permission,
    user,
    cloudfrontList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    inviteNewClient: bindActionCreators(inviteNormalClient, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInviteModalComponent);
