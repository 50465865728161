import React from 'react';

import * as S from './style';

const RemoveWeek = props => {
  const {
    noBorder,
    onConfirm,
    title,
    content,
    headerIcon,
    confirmButtonTitle,
    shouldCloseAfterConfirm,
    className,
  } = props;

  return (
    <S.CustomConfirmModal
      title={title || 'Remove Week'}
      content={content || 'Would you like to remove this week from the meal plan?'}
      onConfirm={onConfirm}
      noBorder={noBorder}
      headerIcon={headerIcon}
      confirmButtonTitle={confirmButtonTitle}
      hasCloseIcon={true}
      shouldCloseAfterConfirm={shouldCloseAfterConfirm}
      className={className}
      isPressEsc
      isCloseOnDimmer={false}
    />
  );
};

export default RemoveWeek;
