export const WAITLIST_VALUE = {
  IMMEDIATELY: 'immediately',
  QUANTITY: 'quantity_limit',
  TIME: 'time_limit',
};

export const CONTACT_VALUE = {
  INFORMATION: 'information',
  MESSAGE: 'message',
  SURVEY: 'survey',
};

export const PURCHASE_VALUE = {
  INSTANT: 'instant_purchase',
  WAITLIST: 'waitlist',
  CONTACT: 'contact_list',
};

export const WAITLIST_OPTION = [
  {
    label: 'Turn on waitlist immediately',
    value: WAITLIST_VALUE.IMMEDIATELY,
    name: 'WaitlistOption',
  },
  {
    label: 'Turn on waitlist when purchase limit is reached',
    value: WAITLIST_VALUE.QUANTITY,
    name: 'WaitlistOption',
  },
  {
    label: 'Turn on waitlist after specified date',
    value: WAITLIST_VALUE.TIME,
    name: 'WaitlistOption',
  },
];

export const CONTACT_OPTION = [
  {
    label: 'Take contact information only',
    value: CONTACT_VALUE.INFORMATION,
  },
  {
    label: 'Let clients also leave you a message',
    value: CONTACT_VALUE.MESSAGE,
  },
  {
    label: 'Ask clients to fill out a survey',
    value: CONTACT_VALUE.SURVEY,
  },
];

export const PURCHASE_OPTIONS = [
  {
    label: `Instant book (default)`,
    value: PURCHASE_VALUE.INSTANT,
  },
  {
    label: `Enable waitlist`,
    value: PURCHASE_VALUE.WAITLIST,
  },
  {
    label: 'Require intro call before purchase',
    value: PURCHASE_VALUE.CONTACT,
  },
];

export const DEFAULT_TIME = { label: '08:00 AM', value: '08:00' };

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
