import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Urlify from 'shared/Urlify';

import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { detectDocumentType, limitText } from 'utils/commonFunction';
import { getLinkType } from 'utils/validations';

import { ReactComponent as NoSelectedTaskIcon } from 'assets/icons/no-selected-task.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play-filled.svg';
import { ReactComponent as AudioWaveIcon } from 'assets/icons/audio-wave.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link-preview.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import { ReactComponent as ProgressPhotoIcon } from 'assets/icons/progress-photo.svg';
import { ReactComponent as BodyMetricIcon } from 'assets/icons/body-metric-task-preview.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-grey.svg';
import { ReactComponent as NoAttachmentIcon } from 'assets/icons/no-attacment-preview.svg';
import { ReactComponent as DoneIcon } from 'assets/icons/done-task-preview.svg';
import { ReactComponent as CommentIcon } from 'assets/icons/metric-comment.svg';

import * as S from './style';

const TASK_TYPES = {
  general: 'general',
  progressPhoto: 'progress_photo',
  bodyMetric: 'body_metric',
  form: 'form',
};

const GENERAL_TYPE = {
  video: 'video',
  image: 'image',
  audio: 'audio',
  link: 'link',
  document: 'document',
};

const VIDEO_TYPE = ['vimeo', 'youtube', 'video'];
const attachment_link_type = 3;

function PreviewTask(props) {
  const { task, bodymetricTypes } = props;
  const [attachment, setAttachment] = useState();
  const [taskType, setTaskType] = useState();

  useEffect(() => {
    if (task) {
      setTaskType(get(task, 'type'));
      handleThumbnail(task);
    }
    return () => {
      setAttachment(null);
    };
  }, [task]);

  const isEnableComment = get(task, 'enable_comment');
  const renderAddComment = (isAttachment = true) => {
    if (!isEnableComment) return;
    return <S.AddComment attachment={isAttachment}>Add comments</S.AddComment>;
  };

  const handleThumbnail = async task => {
    if (!task) return;
    const attachments = get(task, 'attachments.attachments', []);
    const attachment = !isEmpty(attachments) && attachments[0];
    const thumbnail = get(attachment, 'thumbnail_url', '');
    const response = await props.getS3presignedURLFromLocalDatabase([thumbnail]);
    let generalType = get(attachment, 'type', '');

    if (generalType.includes('audio')) {
      generalType = 'audio';
    }

    const urlType = getLinkType(get(attachment, 'url', ''));
    if (VIDEO_TYPE.includes(urlType)) {
      generalType = 'video';
    }

    const linkTitle = get(task, 'attachments.title', '') || get(attachment, 'name', '');
    const resolvedAttachment = {
      type: generalType,
      baseType: get(task, 'attachments.type', null),
      thumbnail: response[0],
      name: linkTitle,
    };

    if (get(attachment, 'type', '') === 'document') {
      resolvedAttachment.typeDocument = detectDocumentType(get(attachment, 'url', '')).toUpperCase();
    }
    setAttachment(resolvedAttachment);
  };

  const renderPreviewAttachment = attachment => {
    const thumbnail = get(attachment, 'thumbnail', null);
    const type = get(attachment, 'type', '');

    switch (type) {
      case GENERAL_TYPE.video:
        return (
          <S.VideoPreview>
            <S.Thumbnail image={thumbnail}>
              <PlayIcon />
            </S.Thumbnail>
            <S.TitleMedia>
              {get(attachment, 'baseType', null) === attachment_link_type
                ? limitText(get(attachment, 'name', ''), 70)
                : limitText(get(task, 'attachments.title', ''), 70)}
            </S.TitleMedia>
            {renderAddComment()}
            <S.TaskAction>
              <S.IconWrapper>
                <DoneIcon />
              </S.IconWrapper>
            </S.TaskAction>
          </S.VideoPreview>
        );
      case GENERAL_TYPE.image:
        return (
          <S.ImagePreview>
            <S.GeneralWraper>
              <S.Thumbnail image={thumbnail} />
              <S.TitleMedia>{limitText(get(task, 'attachments.title', ''), 70)}</S.TitleMedia>
              {renderAddComment()}
            </S.GeneralWraper>
            <S.TaskAction>
              <S.IconWrapper>
                <DoneIcon />
              </S.IconWrapper>
            </S.TaskAction>
          </S.ImagePreview>
        );
      case GENERAL_TYPE.audio:
        return (
          <S.GeneralWraper>
            <S.AudioPreview>
              <PlayIcon className="play-icon" />
              <AudioWaveIcon className="wave-icon" />
            </S.AudioPreview>
            <S.TitleMedia>{limitText(get(task, 'attachments.title', ''), 70)}</S.TitleMedia>
            {renderAddComment()}
            <S.TaskAction>
              <S.IconWrapper>
                <DoneIcon />
              </S.IconWrapper>
            </S.TaskAction>
          </S.GeneralWraper>
        );
      case GENERAL_TYPE.link: {
        if (thumbnail) {
          return (
            <S.GeneralWraper>
              <S.LinkPreview image={thumbnail}>
                <S.LinkTitle>
                  <LinkIcon />
                  {limitText(get(attachment, 'name', ''), 30)}
                </S.LinkTitle>
              </S.LinkPreview>
              {renderAddComment()}
              <S.TaskAction>
                <S.IconWrapper>
                  <DoneIcon />
                </S.IconWrapper>
              </S.TaskAction>
            </S.GeneralWraper>
          );
        }

        return (
          <S.GeneralWraper>
            <S.LinkPreview>
              <S.LinkTitle>
                <LinkIcon />
                {limitText(get(attachment, 'name', ''), 30)}
              </S.LinkTitle>
            </S.LinkPreview>
            {renderAddComment()}
            <S.TaskAction>
              <S.IconWrapper>
                <DoneIcon />
              </S.IconWrapper>
            </S.TaskAction>
          </S.GeneralWraper>
        );
      }
      case GENERAL_TYPE.document: {
        if (thumbnail) {
          return (
            <S.GeneralWraper>
              <S.DocumentPreview image={thumbnail}>
                <S.LinkTitle>
                  <S.DocumentType>{get(attachment, 'typeDocument', null)}</S.DocumentType>
                  {limitText(get(task, 'attachments.title', ''), 30)}
                </S.LinkTitle>
              </S.DocumentPreview>
              {renderAddComment()}
              <S.TaskAction>
                <S.IconWrapper>
                  <DoneIcon />
                </S.IconWrapper>
              </S.TaskAction>
            </S.GeneralWraper>
          );
        }
        return (
          <S.GeneralWraper>
            <S.DocumentNoThumbNail>
              <S.DocumentIcon>
                <DocumentIcon />
              </S.DocumentIcon>
              <S.DocumentTitle> {limitText(get(task, 'attachments.title', ''), 30)}</S.DocumentTitle>
              <S.OpenDocument>Open document</S.OpenDocument>
            </S.DocumentNoThumbNail>
            {renderAddComment()}
            <S.TaskAction>
              <S.IconWrapper>
                <DoneIcon />
              </S.IconWrapper>
            </S.TaskAction>
          </S.GeneralWraper>
        );
      }
      default:
        return (
          <S.GeneralWraper>
            <S.NoAttachment>
              <NoAttachmentIcon />
            </S.NoAttachment>
          </S.GeneralWraper>
        );
    }
  };

  const renderPreviewType = type => {
    switch (type) {
      case TASK_TYPES.general: {
        if (!get(attachment, 'baseType')) {
          return (
            <>
              {renderPreviewAttachment()}
              <S.TitlePreview>
                <S.Title>
                  <S.Name>{get(task, 'title', '')}</S.Name>
                  <S.Note>
                    <Urlify>{get(task, 'note', '')}</Urlify>
                  </S.Note>
                  {renderAddComment(false)}
                </S.Title>
              </S.TitlePreview>
              <S.TaskAction>
                <S.IconWrapper>
                  <DoneIcon />
                </S.IconWrapper>
              </S.TaskAction>
            </>
          );
        }
        return (
          <>
            <S.TitlePreview>
              <S.Title>
                <S.Name>{get(task, 'title', '')}</S.Name>
                <S.Note>
                  <Urlify>{get(task, 'note', '')}</Urlify>
                </S.Note>
              </S.Title>
            </S.TitlePreview>
            {renderPreviewAttachment(attachment)}
          </>
        );
      }
      case TASK_TYPES.progressPhoto:
        return (
          <S.Body>
            <S.ProgressPhotoPreview>
              <ProgressPhotoIcon />
            </S.ProgressPhotoPreview>
            <S.TitlePreview>
              <S.Title>
                <S.Name>{get(task, 'title', '')}</S.Name>
                <S.Note>
                  <Urlify>{get(task, 'note', '')}</Urlify>
                </S.Note>
                {renderAddComment()}
              </S.Title>
            </S.TitlePreview>
            <S.TaskAction>
              <S.PhotoAction>
                <S.ActionButton>Take photo</S.ActionButton>
                <S.ActionButton>Open album</S.ActionButton>
              </S.PhotoAction>
            </S.TaskAction>
          </S.Body>
        );
      case TASK_TYPES.bodyMetric: {
        const bodyMetrics = get(task, 'metrics', []);
        const metrics = bodyMetrics.map(item => {
          return bodymetricTypes.find(metric => metric._id === item);
        });

        return (
          <S.Body>
            <S.BodyMetricPreview>
              <BodyMetricIcon />
            </S.BodyMetricPreview>
            <S.MetricTitle>
              <S.Title>
                <S.Name>{get(task, 'title', '')}</S.Name>
                <S.Note>
                  <Urlify>{get(task, 'note', '')}</Urlify>
                </S.Note>
              </S.Title>
            </S.MetricTitle>
            <S.MetricType>
              {metrics.map(metric => (
                <S.MetricItem>
                  {get(metric, 'name', '')} <ArrowRight />
                </S.MetricItem>
              ))}
            </S.MetricType>
            <S.MetricAction>
              <S.AddComment>Log all metrics</S.AddComment>
              <S.IconWrapper>
                <DoneIcon />
                {isEnableComment && (
                  <S.CommentIcon>
                    <CommentIcon />
                  </S.CommentIcon>
                )}
              </S.IconWrapper>
            </S.MetricAction>
          </S.Body>
        );
      }
      case TASK_TYPES.form: {
        return (
          <S.Body>
            <S.TitlePreview>
              <S.Title>
                <S.Name>{get(task, 'title', '')}</S.Name>
                <S.Note>
                  <Urlify>{get(task, 'note', '')}</Urlify>
                </S.Note>
              </S.Title>
            </S.TitlePreview>
            <S.FormPreview>
              <DocumentIcon />
              <S.FormTitle>{get(task, 'form.name', '')}</S.FormTitle>
            </S.FormPreview>
            <S.FormAction>
              {renderAddComment()}
              <S.ActionButton>Start Form</S.ActionButton>
            </S.FormAction>
          </S.Body>
        );
      }

      default:
        return (
          <S.NoSlectedTask>
            <NoSelectedTaskIcon />
            <S.NoSlectedTaskText>Select a task to preview</S.NoSlectedTaskText>
          </S.NoSlectedTask>
        );
    }
  };

  return (
    <S.Container>
      <S.MobileFrame>
        {task ? (
          <S.Content>{taskType && renderPreviewType(taskType)}</S.Content>
        ) : (
          <S.Content>
            <S.NoSlectedTask>
              <NoSelectedTaskIcon />
              <S.NoSlectedTaskText>Select a task to preview</S.NoSlectedTaskText>
            </S.NoSlectedTask>
          </S.Content>
        )}
      </S.MobileFrame>
    </S.Container>
  );
}

const mapStateToProps = state => {
  const {
    client: { bodymetricTypes = [] },
    autoflow: {
      settings: { metrics },
    },
  } = state.rootReducer;

  return { bodymetricTypes: bodymetricTypes.length === 0 ? metrics : bodymetricTypes };
};
const mapDispatchToProps = dispatch => ({
  getS3presignedURLFromLocalDatabase: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewTask);
