import { formatSecValueToSM } from 'components/BodyMetricChartNew/chartHelper';
import {
  convertFtToFtAndInch,
  formatDateLastUpdate,
  formatLongNumber,
  formatMinValueToHM,
  formatNumberEven,
  roundNumberBodyMetric,
} from 'utils/commonFunction';

export const formatDate = ({ time, timezone }) => {
  if (!time) return '—';
  return formatDateLastUpdate(time, timezone);
};

export const formatValueUnit = ({ value = 0, unit = {} }) => {
  if (!value) return '—';

  const formatNumber = Number(roundNumberBodyMetric(value, 1)).toLocaleString('en-US').replace(/,/g, '');
  const formatUnit = unit.unique_code === 'none' ? '' : unit.title;

  if (unit.unique_code === 'ft') {
    const isNewFtUnit = unit.unique_code === 'ft';
    const { ft, inch } = isNewFtUnit ? convertFtToFtAndInch(value) : { ft: '', inch: '' };

    return `${ft > 0 ? `${ft} ft ` : ''}${inch > 0 ? `${inch} in` : ''}`;
  }

  if (formatUnit === 'min') {
    return formatMinValueToHM(+formatNumber);
  }

  if (formatUnit === 'sec') {
    return formatSecValueToSM(+formatNumber);
  }

  return `${formatNumberEven(formatNumber, 10000)} ${formatUnit}`;
};

export const formatHeartRateValueUnit = ({ minHeartRate = 0, maxHeartRate = 0, heartRateUnit = {} }) => {
  if (!minHeartRate && !maxHeartRate) return '—';
  const formatUnit = (heartRateUnit || {}).title || '';
  return minHeartRate === maxHeartRate
    ? `${minHeartRate} ${formatUnit}`
    : `${minHeartRate} - ${maxHeartRate} ${formatUnit}`;
};

export const getUnitObject = item => {
  if (item.unit) {
    return item.unit;
  } else if (item.category && item.category.default_unit) {
    return item.category.default_unit;
  }
  return {};
};

export const DEFAULT_TITLE_GROUP = 'New Group';

export const getStyleLockedDraggable = style => {
  if (style && style.transform) {
    const axisLockY = 'translate(0px' + style.transform.slice(style.transform.indexOf(','), style.transform.length);
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};
