import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const baseText = `
  color: #1f2023;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
`;

export const CustomModal = styled(Modal)`
  padding: unset;
  font-family: 'Open Sans';

  &.ui.modal.evf-enable-metric-modal {
    margin: unset;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: unset;
    width: 359px;

    & > .header,
    & > .content,
    & > .actions {
      border: unset;
      border-radius: unset;
      padding: unset;
      background: transparent;
    }

    & > .modal-enable-metric-header {
      position: relative;

      .close-button {
        position: absolute;
        display: flex;
        top: -8px;
        right: -10px;

        .close-icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
`;

export const EnableMetricHeaderWrapper = styled.div`
  padding: 30px 30px 16px 25px;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HeaderTitle = styled.div`
  ${baseText}
  font-size: 18px;
  font-weight: 600;
`;

export const HeaderDesc = styled.div`
  ${baseText}
  font-size: 14px;
  font-weight: 400;
`;

export const ContentWrapper = styled.div`
  height: 319px;
  overflow-y: auto;
  padding: 0 24px 0 30px;
  margin-right: 6px;

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    position: relative;
    width: 100%;

    tr {
      td,
      th {
        :nth-child(1) {
          width: 250px;
        }
      }
    }

    thead tr {
      th {
        ${baseText}
        color: #6a778a;
        font-size: 10px;
        font-weight: 600;
        line-height: 26px;
        text-transform: uppercase;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
        padding: 8px 0 0 0;
      }
    }

    tbody tr {
      height: 42px;

      td:first-child {
        padding-right: 10px;
      }

      td {
        ${baseText}
        color: #000;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        padding: 0;

        :nth-child(2) {
          letter-spacing: 0.4px;
        }
      }
    }

    &.selected {
      .evf-checkbox {
        font-weight: 600;
      }
    }
  }

  .evf-checkbox {
    padding-left: 26px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #000;
  }
`;

export const MetricName = styled.span`
  ${baseText}
  color: #000;
  font-size: 13px;
  font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
  line-height: 20px;

  ${({ isHeader }) =>
    isHeader &&
    css`
      color: #6a778a;
      font-size: 10px;
      line-height: 26px;
      text-transform: uppercase;
    `}
`;

export const ExtendInfo = styled.span`
  ${baseText}
  color: #000;
  font-size: 12px;
  opacity: 0.5;
  margin-left: 4px;
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 30px !important;
  box-shadow: 0px 2px 21px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: 54px;
  z-index: 2;

  button {
    user-select: none;
    width: 107px;

    &:hover {
      background-color: rgba(81, 88, 207, 0.9);
    }
  }
`;
