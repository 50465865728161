import React, { useState, useRef } from 'react';
import _ from 'lodash';
import * as S from './style';

export default function CopyButton(props) {
  const [hovered, setHovered] = useState(false);
  const [copied, setCopied] = useState(false);
  const inputRef = useRef();

  const onCopy = () => {
    inputRef.current.focus();
    inputRef.current.select();
    document.execCommand('copy');
    try {
      const successful = document.execCommand('copy');

      if (successful) {
        setCopied(true);
      }
    } catch (err) {
      console.error('Oops, unable to copy');
    }
  };

  const onMouseLeave = () => {
    setHovered(false);
    setCopied(false);
  };

  return (
    <div>
      <S.InputCopy ref={inputRef} className="inputCopy" value={props.copyValue} />
      <S.CopyButton onClick={onCopy} onMouseLeave={onMouseLeave} onMouseOver={() => setHovered(true)} >
        {copied ? `Referral ${props.copyType} copied!` : hovered ? 'Copy to clipboard' : (
          <>Copy&nbsp;<b>Referral {_.upperFirst(props.copyType)}</b></>
        )}
      </S.CopyButton>
    </div>
  )
}