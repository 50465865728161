import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import UpgradePathButton from './UpgradePathButton';
import { markShouldSelectPlan } from 'redux/pricing/actions';
import { bindActionCreators } from 'redux';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483100;

  .wariningExpiredPopup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: #fff;
    border-radius: 5px;
    z-index: 2147483100;
    padding: 30px;

    &__heading {
      margin-bottom: 30px;
      img {
        margin-top: -135px;
      }
    }

    &__content {
      margin-bottom: 20px;
      width: 360px;

      &--title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        color: #000000;
        margin-bottom: 5px;
      }

      &--description {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #000000;

        opacity: 0.6;
      }
    }
  }
`;

function WarningTrialExpiredPopup({ user, teamData, shouldSelectPlan, ...props }) {
  const path = window.location.pathname;

  const [visible, setVisible] = useState(true);

  if (!shouldSelectPlan || !visible || path.includes('/pricing-plan')) {
    return null;
  }

  const handleChoosePlan = () => {
    setVisible(false);
    props.markShouldSelectPlan();
  };

  return (
    <Wrapper>
      <div className="wariningExpiredPopup">
        <div className="wariningExpiredPopup__heading">
          <img src={`${CDN_URL}/images/warning_trial_expired_popup_icon.svg`} alt="warning_trial_expired_popup_icon" />
        </div>
        <div className="wariningExpiredPopup__content">
          <div className="wariningExpiredPopup__content--title">Hi {user.first_name}! Your trial has expired</div>
          <div className="wariningExpiredPopup__content--description">
            <span>
              Hope you enjoyed your trial of the {_.upperFirst(teamData.lastBillingTier)} plan. To continue training
              clients with the premium features, please choose a plan to upgrade to.
            </span>
          </div>
        </div>
        <div className="wariningExpiredPopup__logout">
          <UpgradePathButton onClick={handleChoosePlan} text="Choose a plan" />
        </div>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = (state: State) => {
  const { user, rootReducer } = state;

  return {
    user,
    shouldSelectPlan: rootReducer.pricing.get('shouldSelectPlan'),
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markShouldSelectPlan: bindActionCreators(markShouldSelectPlan, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarningTrialExpiredPopup);
