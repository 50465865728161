import React from 'react';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import * as S from './style';
import ConfirmModal from 'shared/ConfirmModal';
import { toggleConfirmModal } from 'actions/modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SharedTooltip } from 'shared/SharedTooltip';
import { CDN_URL } from 'constants/commonData';

function DropdownStudioItem(props) {
  return (
    <S.DropdownWrapper>
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="client-studio-program-item-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="client-studio-program-item-options-tooltip" />
          </div>
        )}
      >
        <Option
          key="delete"
          onClick={e => {
            props.toggleConfirmModal(
              true,
              <ConfirmModal
                headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
                newStyle
                confirmButtonTitle="Remove Program"
                title="Remove Program"
                content="Removing this Studio program will remove any workouts from this program on your client’s training calendar, and your client will lose access to the program."
                onConfirm={() => props.onRemove(props.studioProgram.studio_program)}
              />,
            );
          }}
        >
          <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
          <span>Remove</span>
        </Option>
      </DropDown>
    </S.DropdownWrapper>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToprops = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToprops)(DropdownStudioItem);
