import React from 'react';
import classNames from 'classnames';
import _, { find, includes, remove, split } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { Workout } from 'types/model';
import { Modal, Image } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import ReactTooltip from 'react-tooltip';
import LogWorkoutModal from 'components/LogWorkoutModal';
import './styles.scss';
import { getSetItemDescription } from 'helpers/exercise';
import moment from 'moment';
import { convertSecondToTimeString } from 'helpers/time';
import Comments from './Comments';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';
import WorkoutViewOnly from 'shared/WorkoutViewOnly';
import { ReactComponent as FreestyleCompletedIcon } from 'assets/icons/workout-result-freestyle-completed.svg';
import { ReactComponent as FreestyleExsIcon } from 'assets/icons/workout-result-freestyle-reference-ex.svg';
import { ReactComponent as FreestyleAttachmentDuration } from 'assets/icons/workout-result-freestyle-duration.svg';
import * as S from './styles.js';

import {
  ResultContainer,
  Wrapper,
  MainInfoContainer,
  HeaderContainer,
  BaseInforContainer,
  Details,
  SectionContainer,
  SectionHeader,
  SectionBody,
  SetContainer,
  SetItem,
  Assigned,
  EachSideLabel,
} from './styles';
import { convertUnit, formatNumber, parseDurationVideo, pluralize } from 'utils/commonFunction';
import { CDN_URL, CLIENT_RATINGS, KEY_CODE, LOGGING_WORKOUT_MODES, SECTION_FORMAT_KEY } from 'constants/commonData';
import ConfirmModal from 'shared/ConfirmModal';
import { prepareDataToTrackWorkout } from 'helpers/workout';

import HistoryExercisePopup from 'components/HistoryExercisePopup';
import NoteModal from 'components/NoteModal';
import WorkoutDetailHeader from './WorkoutDetailHeader';
import { ReactComponent as HistoryIcon } from 'assets/icons/history_bold.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/note_purple.svg';
import HeartRateResultChart from './HeartRateChart';

export const EDIT_MODE = 'EDIT_MODE';
export const VIEW_MODE = 'VIEW_MODE';

const TIME_FORMAT = 'h:mm A';
export default class WorkoutHistoryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.uploadedAllComment = true;
    this.viewNote = null;
  }

  state = {
    workout: this.getDefaultWorkout(),
    isAddingNote: false,
    shouldFocusNote: false,
    originWorkout: this.getOriginWorkout(),
    viewNote: null,
    viewDetail: false,
    viewMore: null,
    viewFilter: null,
    isHighFive: _.get(this.props.workingWorkout, 'is_high_five', false),
  };

  handleViewNote = ex => {
    this.setState({ viewNote: ex });
  };

  handleCloseViewNote = () => {
    this.setState({
      viewNote: null,
    });
  };

  getDefaultWorkout() {
    const { workingWorkout } = this.props;
    return workingWorkout || new Workout();
  }

  getOriginWorkout() {
    const { originWorkout } = this.props;
    return originWorkout || new Workout();
  }

  performCloseHistory = () => {
    this.props.dispatch(toggleModal(false));
    const { pathname } = window.location;

    if (pathname.includes('history')) {
      const { selectedClient } = this.props;

      if (selectedClient) {
        this.props.dispatch(push(`/home/client/${selectedClient}/calendar`));
      } else {
        this.props.dispatch(push(`/home/client`));
      }
    }
  };

  handleCloseAction = () => {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy) return null;
    if (!this.uploadedAllComment) {
      this.props.dispatch(
        toggleConfirmModal(
          true,
          <ConfirmModal
            title="Upload in progress"
            content="If you close the pop-up, all incomplete uploads will be discarded. Would you like to exit without finishing?"
            onConfirm={() => this.performCloseHistory()}
            newStyle={true}
            headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          />,
        ),
      );
    } else {
      this.performCloseHistory();
    }
  };

  onEditWorkoutResult = () => {
    const { workout } = this.state;
    const { fields } = this.props;
    const data = prepareDataToTrackWorkout(workout, fields);
    const convertedData = _.cloneDeep(data);
    convertedData.sections = convertedData.c_sections.slice();
    this.props.dispatch(
      toggleModal(
        true,
        <LogWorkoutModal
          date={DateTime.fromISO(workout.date)} // type is DateTime, not string
          mode={LOGGING_WORKOUT_MODES.UPDATING}
          day={DateTime.fromISO(workout.date).toFormat('MM-dd-yyyy')}
          tracking
          originWorkout={new Workout(_.cloneDeep(convertedData))}
          workingWorkout={new Workout(_.cloneDeep(convertedData))}
        />,
      ),
    );
  };

  renderHistoryPopup = exerciseSet => {
    const exerciseId = _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');
    const tooltipId = `tooltip--history-${exerciseId}`;
    return (
      <HistoryExercisePopup
        exerciseId={exerciseId}
        deletedExercise={!_.get(exerciseSet, 'exercise._id', '')}
        trigger={
          <>
            <HistoryIcon data-tip="View History" data-for={tooltipId} />
            <ReactTooltip id={tooltipId} effect="solid" className="app-tooltip app-tooltip-bold" />
          </>
        }
      />
    );
  };

  renderNotePopup = exerciseSet => {
    const exerciseId = _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');
    const tooltipId = `note-${exerciseId}`;
    return (
      <>
        <NoteIcon
          data-for={tooltipId}
          data-tip="View Note"
          onClick={() => this.handleViewNote(exerciseSet)}
          className="view-note"
        />
        <ReactTooltip id={tooltipId} place="top" effect="solid" className="app-tooltip" />
      </>
    );
  };

  renderHeader = () => {
    const { workout } = this.state;

    return (
      <HeaderContainer className="history__header">
        <div className="workout-title">{workout.title}</div>
        {workout.trackedAgent === 'react' && (
          <div className="history__edit-icon" onClick={this.onEditWorkoutResult}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12.6" height="12.599" viewBox="0 0 12.6 12.599">
              <path
                id="Shape"
                d="M1.909,14h0L0,12.091V3.181H3.818v8.909L1.91,14ZM3.818,1.909H0V0H3.818V1.908Z"
                transform="translate(9.9) rotate(45)"
                fill="#5C5BBD"
              />
            </svg>
            <span>Edit</span>
          </div>
        )}
      </HeaderContainer>
    );
  };

  renderSetRow = (itm, fields, exercise, hasOneRepMax, format) => {
    const rirField = find(this.props.fields, field => field.unique_code === 'rir');
    let hasRIRField = false;
    if (rirField) {
      hasRIRField = includes(fields, rirField._id);
    }
    const hrField = find(this.props.fields, field => field.unique_code === 'hr');
    let hasHRField = false;
    if (hrField) {
      hasHRField = includes(fields, hrField._id);
    }

    const { INTERVAL } = SECTION_FORMAT_KEY;
    const hasPersonalBest = itm.records && itm.records.length > 0;
    let oneRepMax = '';

    if (hasOneRepMax) {
      const weight = parseFloat(_.get(itm, 'weight.input_value') || _.get(itm, 'weight.value') || 0);
      const reps = parseFloat(_.get(itm, 'reps.value') || 0);

      if (reps === 1) {
        oneRepMax = Math.round(Number(weight));
      } else {
        oneRepMax = Math.round(Number(weight) * (1 + Number(reps) / 30));
      }
    }
    const trackedValue = getSetItemDescription(itm, this.props.unit, false, fields, this.props.fields);
    let splittedTrackedValue = trackedValue.split(' x ');
    const assignedValue = getSetItemDescription(
      itm.targetTrainingSet,
      this.props.unit,
      false,
      fields,
      this.props.fields,
    );
    const isDifferent = trackedValue !== assignedValue;
    let formattedTrackedValue = '';
    if (hasHRField) {
      remove(splittedTrackedValue, val => val.includes('%HR'));
      formattedTrackedValue = splittedTrackedValue.join(' x ');
    }
    if (hasRIRField) {
      remove(splittedTrackedValue, val => val.includes('RIR'));
      formattedTrackedValue = splittedTrackedValue.join(' x ');
    }

    return (
      <SetItem key={itm._id} className={hasPersonalBest ? 'personal-cup' : ''}>
        <div className="set__value">
          <div
            data-for={`tracked-${itm.id}`}
            data-tip={'Tracked Values'}
            className={classNames('track-value', { highlighted: !itm.is_completed })}
          >
            {hasHRField || hasRIRField ? formattedTrackedValue : trackedValue}
          </div>
          <ReactTooltip id={`tracked-${itm.id}`} place="top" className="app-tooltip app-tooltip-small" />
          {itm.is_completed && hasPersonalBest ? (
            <React.Fragment>
              <div className="personal-best" data-tip data-for={itm._id}>
                {itm.records.map((item, index) => (
                  <Image key={index} src={`${CDN_URL}/images/pbsmall.svg`} />
                ))}
              </div>
              <ReactTooltip
                className="app-tooltip-white custom-triangle-bottom"
                id={itm._id}
                effect="solid"
                place={'top'}
                delayShow={100}
              >
                <div className="personal-best-info">
                  <div className="exercise__title">{_.get(exercise, 'title')}</div>
                  {_.map(itm.records, (record, index) => {
                    const isReps = record.type === 'reps';
                    const isWeight = ['weight', 'volume', '1rm'].includes(record.type);
                    const unit = isWeight ? this.props.unit.weight : this.props.unit[record.type];
                    const value = convertUnit(record.value, record.set_unit, unit) || record.value;

                    return (
                      <div key={index} className="item">
                        <div className="name">
                          <Image key={index} src={`${CDN_URL}/images/pbsmall.svg`} />
                          <div>{record.type === '1rm' ? '1RM' : `Max ${record.type}`}</div>
                        </div>
                        <div className="value">
                          {value ? +Number(value).toFixed(2) : 0}{' '}
                          {isReps ? 'reps' : _.get(unit || record.set_unit, 'title')}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </ReactTooltip>
            </React.Fragment>
          ) : null}
        </div>

        {isDifferent && format !== INTERVAL && (
          <Assigned>
            <span data-for={`assigned-${itm.id}`} data-tip="Assigned Values">
              {assignedValue}
            </span>
          </Assigned>
        )}

        {isDifferent && format !== INTERVAL && (
          <ReactTooltip id={`assigned-${itm.id}`} place="top" type="dark" className="app-tooltip assign-values" />
        )}
        {!itm.is_completed ? <div className="missed-workout">Missed</div> : <div className="one-max">{oneRepMax}</div>}
      </SetItem>
    );
  };

  renderBaseInfo = () => {
    const { workout } = this.state;
    const { timezone } = this.props;
    const numOfExercises = _.sumBy(workout.sections, section =>
      _.sumBy(section.exercises, exercise => (exercise.supersets || []).length),
    );
    let numOfDoneExercises = 0;
    workout.c_sections.map(csItem => {
      csItem.exercises.map(cItem => {
        cItem.supersets.map(ssItem => {
          if (
            ssItem.training_sets.some(s => {
              return s.is_completed;
            })
          ) {
            numOfDoneExercises += 1;
          }
        });
        return cItem;
      });
    });

    let start_date = DateTime.fromJSDate(new Date(workout.start_date));
    let end_date = DateTime.fromJSDate(new Date(workout.end_date));
    let totalTime = '00:00';

    if (workout.duration) {
      totalTime = Math.round(workout.duration / 60);
    } else {
      const diff = Interval.fromDateTimes(start_date, end_date).toDuration('seconds').toObject();

      if (diff && diff.seconds) {
        totalTime = Math.round(diff.seconds / 60);
      }
    }

    const endDateFormat = moment(workout.end_date).tz(timezone).format('MMM D');
    let timeText = endDateFormat;

    if (workout.start_date && workout.end_date) {
      const startDateMoment = moment(workout.start_date).tz(timezone);
      const endDateMoment = moment(workout.end_date).tz(timezone);
      const DATE_FORMAT = startDateMoment.isSame(endDateMoment, 'year') ? 'MMM D' : 'MMM D, YYYY';

      if (endDateMoment.isSame(startDateMoment, 'day')) {
        timeText = `${endDateFormat} | ${startDateMoment.format(TIME_FORMAT)} - ${endDateMoment.format(TIME_FORMAT)}`;
      } else {
        timeText = `${startDateMoment.format(`${DATE_FORMAT} - ${TIME_FORMAT}`)} | ${endDateMoment.format(
          `${DATE_FORMAT} - ${TIME_FORMAT}`,
        )}`;
      }
    }

    const rating = CLIENT_RATINGS[workout.rating] || 'No rating';

    return (
      <BaseInforContainer className="workout-base-info">
        <div className="workout__name">{workout.title || ''}</div>
        <div className="workout__tracked">
          {numOfDoneExercises}/{pluralize('exercise', numOfExercises, true)} tracked
        </div>
        <div className="workout__summarize">
          <div className="completion">
            <div className="item">
              <Image src={`${CDN_URL}/images/watch.svg`} width={36} />
              <div className="value">
                <div>{formatNumber(totalTime)}</div>
                <div className="unit">Training Min</div>
              </div>
            </div>
            <div className="item">
              <Image src={`${CDN_URL}/images/badges.svg`} width={36} />
              <div className="value">
                <div>{workout.records ? workout.records.length : 0}</div>
                <div className="unit">Personal Bests</div>
              </div>
            </div>
          </div>
          {timeText && <div className="duration">{timeText}</div>}
          <div className="rating">
            <div className="title">Client Rating</div>
            <div className="level">{rating}</div>
          </div>
        </div>
      </BaseInforContainer>
    );
  };

  /**
   * renderSet: Render Superset
   * @param {*} set: Set row
   * @param {*} format: format of section
   * @returns
   */
  renderSet = (set, format) => {
    const { INTERVAL } = SECTION_FORMAT_KEY;
    const { selectedClient, fields } = this.props;
    const repsField = fields.find(item => item.unique_code === 'reps');
    const weightField = fields.find(item => item.unique_code === 'weight');

    return (
      <SetContainer className={`set-history${set.supersets.length > 1 ? ' set-history--super-set' : ''}`}>
        {set.supersets.length > 1 ? <div className="super-set">Super set</div> : null}
        <div className="sets__container">
          {_.map(set.supersets, (ex, exIndex) => {
            const exTitle = _.get(ex, 'exercise_instance.title', '');
            const { exercise_instance } = ex;
            let hasOneRepMax = false;
            if (exercise_instance && repsField && weightField) {
              hasOneRepMax =
                _.get(ex, 'exercise.category_type_name') === 'Strength' &&
                exercise_instance.fields.includes(repsField._id) &&
                exercise_instance.fields.includes(weightField._id);
            }

            const isTrackMobile =
              ex.each_side && ex.training_sets.find(it => ['right', 'left'].includes(it.each_side_mode));
            const exerciseId = _.get(ex, 'exercise._id', '') || _.get(ex, 'exercise_instance.exercise', '');
            const exercise = _.get(ex, 'exercise', '') || _.get(ex, 'exercise_instance', '');
            return (
              <div className="set-history__item" key={ex._id}>
                <div className="item__title">
                  <ExerciseMediaPreview
                    exercise={exercise}
                    trigger={
                      <>
                        <div data-for={exerciseId} data-tip="View Exercise Detail" className="exercise-name">
                          {exTitle}
                        </div>
                        <ReactTooltip
                          id={exerciseId}
                          place="top"
                          className="app-tooltip app-tooltip-bold"
                          effect="solid"
                        />
                      </>
                    }
                  />
                  <div className="one_rep_max">{hasOneRepMax && '1RM'}</div>
                  <div className="icons_wrapper">
                    {ex.note && this.renderNotePopup(ex)}
                    {this.renderHistoryPopup(ex)}
                  </div>
                </div>

                <div className="item__body">
                  {!isTrackMobile
                    ? this.handelTrackDefault(ex, format, hasOneRepMax)
                    : this.handleTrackEachSideMobile(ex, format, hasOneRepMax)}
                </div>
              </div>
            );
          })}
        </div>
      </SetContainer>
    );
  };

  handelTrackDefault = (ex, format, hasOneRepMax) => {
    const { INTERVAL } = SECTION_FORMAT_KEY;

    if (ex.each_side) {
      return (
        <>
          {ex.each_side && format === INTERVAL && <EachSideLabel>Right</EachSideLabel>}
          {
            <ol>
              {_.map(ex.training_sets, exSet => (
                <li>{this.renderSetRow(exSet, ex.exercise_instance.fields, ex.exercise, hasOneRepMax, format)}</li>
              ))}
            </ol>
          }
          {ex.each_side && format === INTERVAL && <EachSideLabel>Left</EachSideLabel>}
          {ex.each_side && format === INTERVAL && (
            <ol>
              {_.map(ex.training_sets, exSet => (
                <li>{this.renderSetRow(exSet, ex.exercise_instance.fields, ex.exercise, hasOneRepMax, format)}</li>
              ))}
            </ol>
          )}
        </>
      );
    } else {
      return (
        <ol>
          {_.map(ex.training_sets, exSet => (
            <li>{this.renderSetRow(exSet, ex.exercise_instance.fields, ex.exercise, hasOneRepMax, format)}</li>
          ))}
        </ol>
      );
    }
  };
  handleTrackEachSideMobile = (ex, format, hasOneRepMax) => {
    const { INTERVAL } = SECTION_FORMAT_KEY;
    return (
      <>
        {ex.each_side && format === INTERVAL && <EachSideLabel>Right</EachSideLabel>}
        {
          <ol>
            {_.map(ex.training_sets, exSet => (
              <>
                {exSet.each_side_mode === 'right' && (
                  <li>{this.renderSetRow(exSet, ex.exercise_instance.fields, ex.exercise, hasOneRepMax, format)}</li>
                )}
              </>
            ))}
          </ol>
        }
        {ex.each_side && format === INTERVAL && <EachSideLabel>Left</EachSideLabel>}
        {ex.each_side && format === INTERVAL && (
          <ol>
            {_.map(ex.training_sets, exSet => (
              <>
                {exSet.each_side_mode === 'left' && (
                  <li>{this.renderSetRow(exSet, ex.exercise_instance.fields, ex.exercise, hasOneRepMax, format)}</li>
                )}
              </>
            ))}
          </ol>
        )}
      </>
    );
  };

  renderSectionInfoByFormat = section => {
    const { AMRAP, INTERVAL, REGULAR, TIMED, FREESTYLE } = SECTION_FORMAT_KEY;

    switch (section.format) {
      case AMRAP:
        const round = parseInt(section.round_complete || 0);
        return (
          <div className="item">
            <Image src={`${CDN_URL}/images/refresh_gray.svg`} />
            <span>
              {round} round{round !== 1 ? 's' : ''}
            </span>
          </div>
        );

      case INTERVAL:
        const interval = _.sumBy(section.exercises, superset => {
          let data = [];
          _.map(superset.supersets, exercise => {
            const isTrackMobile =
              exercise.each_side && exercise.training_sets.find(it => ['right', 'left'].includes(it.each_side_mode));

            _.map(exercise.training_sets, re => {
              if (re.is_completed) {
                if (!isTrackMobile && exercise.each_side) {
                  data.push(re, re);
                } else {
                  data.push(re);
                }
              }
            });
          });

          if (data) {
            return data.length || 0;
          }
        });
        return (
          <div className="item">
            <Image src={`${CDN_URL}/images/interval.svg`} />
            <span>
              {interval} interval{interval !== 1 ? 's' : ''}
            </span>
          </div>
        );

      case REGULAR:
        const exercise = _.sumBy(section.exercises, superset => superset.supersets.length) || 0;

        return (
          <div className="item">
            <Image src={`${CDN_URL}/images/exercise.svg`} />
            <span>
              {exercise} exercise{exercise !== 1 ? 's' : ''}
            </span>
          </div>
        );

      case TIMED:
        const time = convertSecondToTimeString(parseInt(section.time_complete || 0), true);
        return (
          <div className="item">
            <Image src={`${CDN_URL}/images/new_clock_grey.svg`} />
            <span>{time}</span>
          </div>
        );
      case FREESTYLE:
        const freestyle_section_exercises = _.get(section, 'exercise_references', []);
        const video_attachment_duration = _.get(section, 'attachments[0].duration', 0);
        const attachment_type = _.get(section, 'attachments[0].type', 0);
        const shouldShowBreaker =
          (freestyle_section_exercises.length > 0 || video_attachment_duration > 0) && section.note !== '';
        return (
          <div className="item" style={{ width: '100%' }}>
            <div className="freestyle-section-info">
              {video_attachment_duration > 0 && attachment_type === 1 && (
                <div className="freestyle-info-item">
                  <FreestyleAttachmentDuration />
                  {parseDurationVideo(video_attachment_duration, true)}
                </div>
              )}
              {freestyle_section_exercises.length > 0 && (
                <div className="freestyle-info-item">
                  <FreestyleExsIcon />
                  {freestyle_section_exercises.length}{' '}
                  {freestyle_section_exercises.length > 1 ? 'exercises' : 'exercise'}
                </div>
              )}
            </div>
            {shouldShowBreaker && <div className="breaker" />}
            <S.Description>{section.note}</S.Description>
            <br />
            <div className="view-more" textOnly onClick={() => this.handleViewWorkout(section)}>
              View more
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  renderSection = (section, sectionIndex) => {
    const { FREESTYLE } = SECTION_FORMAT_KEY;
    let exercise;
    let superset;
    let training_set;
    let personalBestNum = 0;

    for (let i = 0; i < section.exercises.length; i++) {
      exercise = section.exercises[i];
      for (let j = 0; j < exercise.supersets.length; j++) {
        superset = exercise.supersets[j];
        for (let k = 0; k < superset.training_sets.length; k++) {
          training_set = superset.training_sets[k];
          if (training_set.records && training_set.records.length > 0) {
            personalBestNum += training_set.records.length;
          }
        }
      }
    }

    const sectionClass = `section${section.type === 'hidden' ? ' section--hidden' : ''}`;
    const isFreestyleSection = section.format === FREESTYLE;
    const freestyle_section_completed = isFreestyleSection ? _.get(section, 'section_completed', false) : false;

    return (
      <SectionContainer key={section._id} className={sectionClass}>
        {section.type !== 'hidden' && (
          <SectionHeader
            className={classNames({ 'note-border': isFreestyleSection })}
            isFreestyle={isFreestyleSection && !freestyle_section_completed}
          >
            <div className="section__title">
              {section.title}
              {freestyle_section_completed ? (
                <FreestyleCompletedIcon className="section-completed-icon" />
              ) : isFreestyleSection ? (
                <div className="missed-workout freestyle-section">Missed</div>
              ) : null}
            </div>
            <div className="section__description">
              {this.renderSectionInfoByFormat(section)}
              {personalBestNum > 0 ? (
                <div className="item">
                  <Image src={`${CDN_URL}/images/personal_best_thin.svg`} />
                  <span>{personalBestNum} personal bests</span>
                </div>
              ) : null}
            </div>
          </SectionHeader>
        )}
        <SectionBody>
          {_.map(section.exercises, (set, setIndex) => {
            return this.renderSet(set, section.format);
          })}
        </SectionBody>
      </SectionContainer>
    );
  };

  renderNoteExercise = () => {
    const exTitle = _.get(this.state.viewNote, 'exercise_instance.title', '');
    return (
      <NoteModal
        onClose={this.handleCloseViewNote}
        value={this.state.viewNote.note}
        onSubmit={this.handleCloseViewNote}
        title={exTitle}
      />
    );
  };

  handleViewWorkout = section => {
    let { viewDetail, workout } = this.state;
    const freestyleWorkout = workout;
    this.setState({ viewDetail: !viewDetail, viewMore: freestyleWorkout, viewFilter: section });
  };

  handleViewWorkoutClose = () => {
    this.setState({ viewDetail: false });
  };

  handleClickHighFive = id => {
    this.props.highFiveWorkoutHistory(id, 2).then(() => {
      this.setState({
        isHighFive: true,
      });
    });
  };

  render() {
    const { workout, viewDetail, viewNote, viewMore, viewFilter, isHighFive } = this.state;
    const { workingWorkout, view_mode } = this.props;

    return (
      <Modal
        closeOnDimmerClick={false}
        size={'tiny'}
        open={this.props.isModalOpen}
        className="workout-history-modal"
        onClose={this.handleCloseAction}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
      >
        <Modal.Header></Modal.Header>
        <Modal.Content className="workout-history-content">
          <Wrapper>
            <ResultContainer>
              {this.renderHeader()}
              <MainInfoContainer>
                {this.renderBaseInfo()}
                <Details className="history__details">
                  <HeartRateResultChart
                    assignmentId={workout._id}
                    workout={workout}
                    getHeartRateAssignment={this.props.getHeartRateAssignment}
                  />
                </Details>
                <Details className="history__details">
                  <WorkoutDetailHeader workout={workout} />
                  <div className="history__sections">
                    {_.map(workout.c_sections, (section, sectionIndex) => this.renderSection(section, sectionIndex))}
                  </div>
                </Details>
              </MainInfoContainer>
            </ResultContainer>
            {workout.status !== 2 && view_mode === 'history' ? (
              ''
            ) : (
              <>
                <Comments
                  assignmentId={workout._id}
                  onChangeUploadStatus={noUploadedComments => {
                    this.uploadedAllComment = !noUploadedComments.length;
                  }}
                  highFives={_.get(workingWorkout, 'high_fives', [])}
                  handleClickHighFive={this.handleClickHighFive}
                  isHighFive={isHighFive}
                  isHasHighFive
                />
              </>
            )}
          </Wrapper>
        </Modal.Content>
        {viewNote && this.renderNoteExercise()}
        {viewDetail && (
          <WorkoutViewOnly workout={viewMore} viewFilter={viewFilter} onClose={this.handleViewWorkoutClose} />
        )}
      </Modal>
    );
  }
}
