import React, { useRef, useState } from 'react';
import { Radio } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';

import { Button } from 'shared/FormControl';

import * as S from '../styles';

export const OWNER_TYPE = {
  ALL: 'all',
  YOU: 'you',
};

const FiltersPopup = ({ currentFilters, onUpdateFilters, onCloseFilters, className = '' }) => {
  const [owner, setOwner] = useState(currentFilters.owner || OWNER_TYPE.ALL);
  const ref = useRef();

  const handleClearAll = () => {
    setOwner(OWNER_TYPE.ALL);
    typeof onUpdateFilters === 'function' && onUpdateFilters({ owner: OWNER_TYPE.ALL });
  };

  const handleUpdateFilterOptions = () => {
    typeof onUpdateFilters === 'function' && onUpdateFilters({ owner });
    typeof onCloseFilters === 'function' && onCloseFilters();
  };

  const handleSelectOwner = type => {
    setOwner(type);
  };

  return (
    <RootCloseWrapper event="click" onRootClose={onCloseFilters}>
      <S.PopupWrapper ref={ref} className={className}>
        <S.PopupHeader>
          <S.PopupHeaderTitle>Filter by:</S.PopupHeaderTitle>
          <S.ClearLabel onClick={handleClearAll}>Clear</S.ClearLabel>
        </S.PopupHeader>
        <S.PopupBody>
          <S.PopupGroup>
            <S.PopupGroupTitle>Owner</S.PopupGroupTitle>
            <S.PopupGroupContent className="owner">
              <Radio
                checked={owner === OWNER_TYPE.ALL}
                label="All"
                onClick={handleSelectOwner.bind(this, OWNER_TYPE.ALL)}
              />
              <Radio
                checked={owner === OWNER_TYPE.YOU}
                label="You"
                onClick={handleSelectOwner.bind(this, OWNER_TYPE.YOU)}
              />
            </S.PopupGroupContent>
          </S.PopupGroup>
          <S.PopupFooter>
            <Button onClick={handleUpdateFilterOptions}>Update</Button>
          </S.PopupFooter>
        </S.PopupBody>
      </S.PopupWrapper>
    </RootCloseWrapper>
  );
};

export default FiltersPopup;
