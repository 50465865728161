import styled, { css } from 'styled-components';
import RemoveWeek from 'components/MealPlanDetail/Parts/RemoveWeek';

const limitText = `
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    word-break: break-word;
`;

const textBase = `
    font-family: 'Open Sans';
    color: #202353;
    font-style: normal;
    line-height: 150%;
`;

export const AccordionContainer = styled.div`
  width: 278px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px #e1e1ea;
  background-color: #fff;
  ${props =>
    props.isActive &&
    css`
      border-color: #5158cf;
    `}
  ${props =>
    props.isDragging &&
    css`
      box-shadow: 0px 2px 8px 0px #26262633;
    `}
`;

export const AccordionHeader = styled.div`
  padding: 0 14px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
  user-select: none;
  span.arrow-down-wrapper {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    svg.arrow-down {
      fill: #202353;
    }
  }
  ${props =>
    props.isActive &&
    css`
      border-bottom: 1px solid #f0f0f2;
      span.arrow-down-wrapper {
        transform: rotate(180deg);
      }
    `}
  &:hover {
    .trailing-header,
    .custom-actions-meal {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const AccordionHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const AccordionHeaderRight = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  .custom-actions-meal {
    visibility: hidden;
    opacity: 0;
    .evf-dropdown__trigger-container .drop-down-trigger {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      :hover {
        background: #f0f1ff;
        .dot,
        .dot::after,
        .dot::before {
          background-color: #5158cf;
        }
      }
    }
    &.open .evf-dropdown__trigger-container .drop-down-trigger {
      background: #f0f1ff;
      .dot,
      .dot::after,
      .dot::before {
        background-color: #5158cf;
      }
    }
    .evf-dropdown__menu {
      padding: 4px 0;
      width: 150px;
      .evf-dropdown__option {
        padding: 8px 12px;
        justify-content: flex-start;
        gap: 12px;
        user-select: none;
        .icon {
          display: flex;
          justify-content: center;
        }
        span {
          ${textBase}
          color: #f7f8f9;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
`;

export const AccordionTrailingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .trailing-header {
    display: block;
    visibility: hidden;
    opacity: 0;
  }
`;

export const HeaderTitle = styled.div`
  ${textBase}
  font-size: 15px;
  font-weight: 600;
  ${limitText}
  -webkit-line-clamp: 1;
  line-clamp: 1;
`;

export const HeaderTotal = styled.div`
  ${textBase}
  font-size: 15px;
  font-weight: 600;
`;

export const AccordionContent = styled.div`
  padding: 15px 10px;
  ${props =>
    props.isEmpty &&
    css`
      padding: 17px 20px 13px;
    `}
`;

export const AccordionItemWrapper = styled.div`
  display: flex;
  gap: 13px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  align-items: center;
  ${props =>
    props.isDragging &&
    css`
      box-shadow: 0px 2px 8px 0px #26262633;
    `}
  &:hover {
    .trailing-item {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const AccordionItemEmpty = styled.div`
  ${textBase}
  color: #7B7E91;
  font-size: 13px;
  font-weight: 400;
`;

export const AccordionItemImage = styled.div`
  .image-with-fallback {
    width: 63px;
    height: 42px;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
    }
  }
`;

export const AccordionItemTitle = styled.div`
  flex: 1 1 0;
  height: 100%;
  ${textBase}
  font-size: 11px;
  font-weight: 400;
  ${limitText}
`;

export const AccordionItemIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin: auto;
  .trailing-item {
    display: block;
    visibility: hidden;
    opacity: 0;
  }
`;

export const AddMealTitle = styled.div`
  ${textBase}
  font-size: 15px;
  font-weight: 600;
  user-select: none;
`;

export const AddMealWrapper = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #f0f0f2;
  cursor: pointer;
  .add-meal-wrapper {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  &:hover {
    background-color: #f0f1ff;
    ${AddMealTitle} {
      color: #5158cf;
    }
    .add-meal-wrapper svg.add-meal-icon path {
      fill: #5158cf;
    }
  }
`;

export const AccordionSkeletonWrapper = styled.div`
  display: flex;
  gap: 13px;
  padding: 10px;
  animation: custom-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes custom-pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  .skeleton-thumbnail {
    flex-shrink: 0;
    width: 63px;
    height: 42px;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
  .skeleton-name {
    width: 55%;
    height: 17px;
    background: #f8f8f8;
    border-radius: 5px;
  }
`;

export const RemoveMeal = styled(RemoveWeek)`
  &.ui.modal.confirm-popup-container.custom-remove-meal {
    width: 418px !important;
    .confirm-header-image {
      width: 25px;
      height: 25px;
    }
    .ui.button.confirm-no-button {
      min-width: 102px;
    }
    .ui.button.confirm-yes-button {
      min-width: 111px;
      padding: 5px 30px;
    }
  }
`;

export const AccordionWrapperSkeleton = styled.div`
  width: 100%;
  height: 48px;
  margin-bottom: 14px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #f8f8f8;
  animation: custom-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes custom-pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  border: dashed 1px #5158cf;
`;
