import React, { useEffect, useRef, useState, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getIntervalMessageSent } from 'redux/autoflowInterval/auto-message/actions';
import { IN_APP_AUTO_MESSAGE } from 'constants/commonData';
import { getUserShortName } from 'utils/commonFunction';
import Avatar from 'react-avatar';

const perPage = 20;

function ClientList(props) {
  const { message, autoflowId } = props;

  //state
  const [data, setData] = useState({ list: [], total: 0, page: 1 });
  const [element, setElement] = useState(null);

  //ref
  const loading = useRef(false);
  const currentData = useRef({});
  const observer = useRef(
    new IntersectionObserver(
      entries => {
        const firstEntry = entries[0];

        if (firstEntry.isIntersecting) {
          handleLoadMore();
        }
      },
      { threshold: 1 },
    ),
  );

  //memoize
  const showLoadmoreArea = useMemo(() => data.page * perPage < data.total, [data]);

  //effect
  useEffect(() => {
    props
      .getIntervalMessageSent({
        dayIndex: message.dayIndex,
        weekIndex: message.weekIndex,
        type: IN_APP_AUTO_MESSAGE,
        messageId: message._id,
        autoflowId,
        perPage,
        page: 1,
      })
      .then(response => {
        const { data } = response.data;
        setData(data);
      });
  }, []);

  useEffect(() => {
    currentData.current = data;
  }, [data]);

  useEffect(() => {
    const currentElement = element;

    if (currentElement) {
      observer.current.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.current.disconnect();
      }
    };
  }, [element]);

  const handleLoadMore = () => {
    if (loading.current || currentData.current.page * perPage >= currentData.current.total) {
      return;
    }

    loading.current = true;
    props
      .getIntervalMessageSent({
        dayIndex: message.dayIndex,
        weekIndex: message.weekIndex,
        type: IN_APP_AUTO_MESSAGE,
        messageId: message._id,
        autoflowId,
        perPage,
        page: currentData.current.page + 1,
      })
      .then(response => {
        const { data } = response.data;
        loading.current = false;
        setData(prev => ({ list: [...prev.list, ...data.list], total: Number(data.total), page: Number(data.page) }));
      });
  };

  const dayLabel = dateString => {
    const date = moment(dateString);

    if (!date.isSame(moment(), 'year')) {
      return date.format('MMM D, YYYY');
    }

    return date.format('MMM D');
  };

  if (!data.list.length) {
    return null;
  }

  return (
    <Container>
      <div className="list__header">
        <h3>Already sent to</h3>
        <div className="thead">
          <div>Name</div>
          <div>Sent On</div>
        </div>
      </div>
      <div className="list__content">
        {_.map(data.list, (item, index) => {
          let dateIndicator = null;
          const { day, version } = item.version_info;
          const prevItems = data.list[index - 1];

          if (
            (prevItems && (day !== prevItems.version_info.day || version !== prevItems.version_info.version)) ||
            (index === 0 && (day !== message.version_info.day || version !== message.version_info.version))
          ) {
            dateIndicator = (
              <div className="version-label">
                Version {moment(item.version_info.day, 'MM-DD-YYYY').format('MMM D, YYYY')}
                {item.version_info.version !== 1 ? ` - ${item.version_info.version}` : ''}
              </div>
            );
          } else if (index === 0) {
            dateIndicator = <div className="version-label">Current Version</div>;
          }

          return (
            <React.Fragment key={item._id}>
              {dateIndicator}
              <div className="item">
                <Link className="clientInfoContainer" to={`/home/client/${item.client._id}`}>
                  <Avatar
                    name={getUserShortName(item.client)}
                    size="34"
                    src={item.client.avatar}
                    color={item.client.color}
                  />
                  <div className="name">
                    {item.client.first_name} {item.client.last_name}
                  </div>
                </Link>
                <div className="day" data-tip data-for={`tooltip--${item._id}`}>
                  {dayLabel(item.date)}
                </div>
                <ReactTooltip id={`tooltip--${item._id}`} effect="solid" place="top" className="app-tooltip">
                  <span>{moment(item.date).format('hh:mm A')}</span>
                </ReactTooltip>
              </div>
              {showLoadmoreArea && index < data.list.length - 6 ? (
                <div style={{ width: '100%', height: '5px' }} ref={setElement} />
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    </Container>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const {
    common: { workingAutoflow },
  } = rootReducer.autoflow;

  return {
    autoflowId: workingAutoflow._id,
  };
};

export default connect(mapState, { getIntervalMessageSent })(ClientList);

const Container = styled.div`
  width: 328px;
  height: 675px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .list__header {
    padding: 37px 25px 0;

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin-bottom: 26px;
    }

    .thead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #111111;
    }
  }

  .list__content {
    flex: 1 1;
    overflow: auto;
    padding: 0 22px 10px;
    margin-right: 3px;

    .__react_component_tooltip.app-tooltip {
      padding: 7px 15px;
      width: 100px;
    }
  }

  .version-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #111111;
    opacity: 0.4;
    padding: 10px 0;
    margin-bottom: 4px;
  }

  .item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    flex: 1 1;

    .clientInfoContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;

      :hover {
        text-decoration: none !important;
      }
    }

    .sb-avatar {
      flex: 0 0 34px;
      margin-right: 10px;
    }

    .name {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #111111;
      flex: 1 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .day {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #111111;
      opacity: 0.4;
      text-align: right;
      flex: 0 0 auto;
      cursor: default;
    }
  }
`;
