import React from 'react';
import { Label, Image } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import ReactTooltip from 'react-tooltip';
import './styles.scss';
import _, { get } from 'lodash';
import { push } from 'connected-react-router';
import { CDN_URL, CLIENT_RATINGS } from 'constants/commonData';
import styles, {
  ASSIGNMENT_CONTAINER_HEIGHT,
  ASSIGNMENT_CONTAINER_MARGIN,
  ASSIGNMENT_COMPLETED_CONTAINER_HEIGHT,
  ASSIGNMENT_COMPLETED_CONTAINER_MARGIN,
} from './styles';
import moment from 'moment';
import Emoji from 'react-emoji-render';

export default class CalendarHistoryCell extends React.Component {
  calendarCell;

  state = {
    calendarCellHeight: 0,
    isViewMore: false,
  };

  componentDidMount() {
    this.updateCalendarCellHeight();
    window.addEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  componentDidUpdate() {
    if (this.calendarCell && this.calendarCell.offsetHeight !== this.state.calendarCellHeight) {
      this.updateCalendarCellHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  updateCalendarCellHeight() {
    if (this.calendarCell) {
      this.setState({ calendarCellHeight: this.calendarCell.offsetHeight });
    }
  }

  render() {
    const { day, currentWorkouts, calendarType, timezone, selectedClient } = this.props;
    const currentActivities = get(this.props, 'currentActivities', []);

    if (!selectedClient) {
      return null;
    }

    const { isViewMore } = this.state;
    const active = DateTime.local().setZone(timezone).toFormat('MM-dd-yyyy') === day.toFormat('MM-dd-yyyy');
    const views = [];

    if (currentWorkouts && currentWorkouts.length > 0) {
      let { maxRendered } = this.getMaximumRenderedWorkouts();
      for (let i = 0; i < currentWorkouts.length && (isViewMore || i < maxRendered); i++) {
        views.push(this.renderExercises(currentWorkouts[i]));
      }
    }
    if (currentActivities && currentActivities.length > 0) {
      let { shouldRenderActivities } = this.getMaximumRenderedWorkouts();
      if (isViewMore || shouldRenderActivities) {
        views.push(this.renderLoggedActivities(currentActivities));
      }
    }

    return (
      <div className="calendar-cell" style={{ height: `${100 / calendarType}%` }}>
        <div
          style={{
            border: isViewMore ? 'solid 1px #726ee4' : '1px solid transparent',
            background: isViewMore ? '#ffffff' : '#f3f5f8',
          }}
          className="calendar-cell__content calendar-history-cell"
        >
          {isViewMore ? (
            <div className="day-info-over">
              <div>{day.toFormat('LLL dd')}</div>
            </div>
          ) : (
            <div className="day-info-over view-more">
              <Label
                color={active ? 'purple' : 'transparent'}
                circular={active}
                inverted={active}
                style={{ ...styles.day }}
                className={active ? 'day-label active' : 'day-label'}
              >
                {day.toFormat('dd')}
              </Label>
            </div>
          )}
          {isViewMore && (
            <Image
              className="btntask-close-over"
              src={`${CDN_URL}/images/close_circle.svg`}
              onClick={() => {
                this.handleViewMore(false);
              }}
              width={16}
              height={16}
            />
          )}
          <div
            ref={ref => (this.calendarCell = ref)}
            style={{
              background: isViewMore ? '#ffffff' : '#f3f5f8',
              marginBottom: isViewMore ? 0 : 4,
              overflowY: isViewMore ? 'auto' : 'unset',
              height: isViewMore ? this.state.calendarCellHeight : 'unset',
              padding: '1px 0px',
            }}
            className="calendar-cell__main-content"
          >
            {views}
            {this.renderViewMore()}
          </div>
        </div>
      </div>
    );
  }

  renderViewMore() {
    const { currentWorkouts } = this.props;
    const currentActivities = get(this.props, 'currentActivities', []);
    if (!currentWorkouts && currentActivities.length === 0) {
      return null;
    }
    const { isViewMore } = this.state;
    const { maxRendered, shouldRenderActivities } = this.getMaximumRenderedWorkouts();
    let remainTasks = currentWorkouts ? currentWorkouts.length - maxRendered : 0 - maxRendered;
    if ((remainTasks <= 0 && shouldRenderActivities) || isViewMore) {
      return null;
    }
    let taskText = '+ ' + remainTasks;
    if (remainTasks === 1) {
      taskText += ' more workout';
    } else {
      taskText += ' more workouts';
    }
    if (remainTasks <= 0) {
      taskText = '+ ';
    }

    const activityCountWord = currentActivities.length > 1 ? 'activities' : 'activity';

    if (!shouldRenderActivities) {
      const haveWorkoutRemains = remainTasks > 0 && currentActivities.length > 0;
      const activitiesCountText =
        currentActivities.length > 0 ? `${currentActivities.length} ${activityCountWord}` : '';
      taskText += `${haveWorkoutRemains ? ' & ' : ''}${activitiesCountText}`;
    }
    if (taskText === '+ ') {
      return null;
    }
    return (
      <div
        className="view-more-task with-activities"
        onClick={() => {
          this.handleViewMore(true);
        }}
      >
        {taskText}
      </div>
    );
  }

  handleViewMore(isViewMore) {
    this.setState({ isViewMore: isViewMore });
  }

  getMaximumRenderedWorkouts() {
    const { currentWorkouts = [] } = this.props;
    const currentActivities = get(this.props, 'currentActivities', []);
    const { calendarCellHeight } = this.state;
    const workoutHeight = ASSIGNMENT_CONTAINER_HEIGHT + ASSIGNMENT_CONTAINER_MARGIN;
    const workoutCompletedHeight = ASSIGNMENT_COMPLETED_CONTAINER_HEIGHT + ASSIGNMENT_COMPLETED_CONTAINER_MARGIN;
    const firstCompletedWorkout =
      currentWorkouts.length && currentWorkouts[0].status === 2 && !currentWorkouts[0].total_comment;
    const firstWorkoutHasComment =
      currentWorkouts.length && currentWorkouts[0].status === 2 && currentWorkouts[0].total_comment;
    let maxHeight = 0;
    let containerHeight = calendarCellHeight - 6 + 4; // last card not margin bottom
    let activitiesHeight = currentActivities.length > 0 ? 78 : 0;
    let shouldRenderActivities = false;
    for (let i = 0; i < currentActivities.length; i++) {
      if (i === 0) {
        continue;
      }
      activitiesHeight += 38;
    }

    currentWorkouts &&
      currentWorkouts.forEach(element => {
        let minus = element.status === 2 ? workoutCompletedHeight : workoutHeight;

        if (containerHeight - minus >= 0) {
          maxHeight++;
          containerHeight -= minus;
        }
      });

    if (
      maxHeight &&
      containerHeight < 32 && // Case show workout in 2-week mode
      firstWorkoutHasComment && // Case show first completed workout has comment
      (currentWorkouts.length > 1 || currentActivities.length) // Case workouts or 1 workout & 1 activity
    ) {
      maxHeight--;
    }

    // Case show a completed workout with comment or without comment in 4-week mode
    if (
      maxHeight &&
      currentWorkouts.length === 1 &&
      maxHeight <= currentWorkouts.length &&
      ((firstCompletedWorkout && containerHeight < 7) || (firstWorkoutHasComment && containerHeight < 19))
    ) {
      maxHeight--;
    }

    // Case show completed workout in 4-week mode
    if (maxHeight && maxHeight <= currentWorkouts.length && currentWorkouts.length > 1 && containerHeight < 25) {
      maxHeight--;
    }

    // Case show 1 activity in 4-week mode
    if (containerHeight - 30 >= activitiesHeight) {
      shouldRenderActivities = true;
    }

    return { maxRendered: maxHeight, shouldRenderActivities };
  }

  renderExercises(currentWorkout) {
    if (!currentWorkout) {
      return null;
    }
    const isOnDemandVideo = _.get(currentWorkout, 'log_type', '') === 'ondemand_video';
    if (isOnDemandVideo) {
      return (
        <div className={'ui segment small workout-cell'} style={{ ...styles.exerciseListContainer }}>
          {this.renderOnDemandVideoWorkout(currentWorkout)}
        </div>
      );
    }
    return (
      <div className={'ui segment small workout-cell'} style={{ ...styles.exerciseListContainer }}>
        {this.renderExercisesHeader(currentWorkout)}
        <div className="workout-cell-content workout-history-cell-content">
          {currentWorkout.status === 2 ? (
            this.renderContentItem(currentWorkout)
          ) : (
            <div className="missed-workout">Missed</div>
          )}
        </div>
      </div>
    );
  }

  renderOnDemandVideoWorkout(onDemandVideoWorkout) {
    const { name: title, _id } = onDemandVideoWorkout;
    return (
      <>
        <div style={styles.exercisesListHeader} onClick={this.navigateToLogActivityModal(_id)}>
          <div className="workout-title-status-history">
            {this.renderWorkoutTitle({ title, _id })}
            {this.renderWorkoutStatus({ status: 2 })}
            <ReactTooltip
              className="app-tooltip"
              id={`workout-title-${onDemandVideoWorkout._id}`}
              effect="solid"
              place={'top'}
              delayShow={1000}
            >
              <span>{onDemandVideoWorkout.name}</span>
            </ReactTooltip>
          </div>
        </div>
        <div className="workout-cell-content workout-history-cell-content on-demand-video">
          {this.renderClientRating(onDemandVideoWorkout)}
        </div>
      </>
    );
  }

  renderContentItem(currentWorkout) {
    return (
      <div style={{ width: '100%' }}>
        {this.renderExercisesTracked(currentWorkout)}
        {this.renderClientRating(currentWorkout)}
        {this.renderComments(currentWorkout)}
      </div>
    );
  }

  renderExercisesTracked(currentWorkout) {
    const { c_sections } = currentWorkout;
    let numOfExercises = 0;
    let numOfDoneExercises = 0;

    c_sections.map(csItem => {
      csItem.exercises.map(cItem => {
        numOfExercises += cItem.supersets.length;
        cItem.supersets.map(ssItem => {
          if (
            ssItem.training_sets.some(s => {
              return s.is_completed;
            })
          ) {
            numOfDoneExercises += 1;
          }
        });
        return cItem;
      });
    });
    return (
      <div className="delete-note-button client-rating">
        <Image src={`${CDN_URL}/images/new_gym.svg`} />
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {numOfDoneExercises}/{numOfExercises} exercises tracked
        </div>
      </div>
    );
  }

  renderClientRating(currentWorkout) {
    let rating =
      (currentWorkout.rating || currentWorkout.rating === 0) && currentWorkout.rating !== -1
        ? CLIENT_RATINGS[currentWorkout.rating]
        : 'N/A';

    return (
      <div className="delete-note-button client-rating">
        <Image src={`${CDN_URL}/images/heart.svg`} />
        <div>
          Client Rating:{' '}
          <b>
            <i>{rating}</i>
          </b>
        </div>
      </div>
    );
  }

  renderClientNote(currentWorkout) {
    if (currentWorkout.completed_note) {
      return (
        <div className="delete-note-button client-rating completed_note">
          <Image src={`${CDN_URL}/images/completed_note.svg`} />
          <div>Client Note</div>
        </div>
      );
    }
    return null;
  }

  renderComments = currentWorkout => {
    const { total_comment } = currentWorkout;

    if (total_comment) {
      return (
        <div className="client-rating">
          <Image src={`${CDN_URL}/images/comment.svg`} />
          <div>
            {total_comment} comment{total_comment > 1 ? 's' : ''}
          </div>
        </div>
      );
    }

    return null;
  };

  renderWorkoutStatus(item) {
    if (item.status === 2) {
      return <Image className="workout-status-icon" src={`${CDN_URL}/images/checkbox_greenwhite.svg`} />;
    }
    return <Image className="workout-status-icon" src={`${CDN_URL}/images/workout_missed.svg`} />;
  }

  renderWorkoutTitle(item) {
    let className = 'workout-cell-title tracked-workout';
    return (
      <div className={className} data-tip data-for={`workout-title-${item._id}`}>
        {item.title ? item.title.toUpperCase() : ''}
      </div>
    );
  }

  renderExercisesHeader(currentWorkout) {
    const { selectedClient } = this.props;
    // const dropdownIcon = <Icon color={'grey'} name={'ellipsis horizontal'} size={'small'} />;
    return (
      <div
        style={styles.exercisesListHeader}
        onClick={() => {
          this.props.dispatch(push(`/home/client/${selectedClient}/calendar/${currentWorkout._id}/history`));
        }}
      >
        <div className="workout-title-status-history">
          {this.renderWorkoutTitle(currentWorkout)}
          {this.renderWorkoutStatus(currentWorkout)}
          <ReactTooltip
            className="app-tooltip"
            id={`workout-title-${currentWorkout._id}`}
            effect="solid"
            place={'top'}
            delayShow={1000}
          >
            <span>{currentWorkout.title ? currentWorkout.title : ''}</span>
          </ReactTooltip>
        </div>
      </div>
    );
  }

  navigateToLogActivityModal = activityId => () => {
    this.props.push(`/home/client/${this.props.selectedClient}/calendar/${activityId}/activity`);
  };

  renderLoggedActivities = activities => {
    const { timezone } = this.props;
    if (activities.length === 0) {
      return null;
    }
    return (
      <div className={'ui segment small workout-cell'} style={{ ...styles.activitiesListContainer }}>
        {/* {this.renderExercisesHeader(currentWorkout)} */}
        <div style={{ ...styles.activitiesListHeader }}>Activities</div>
        {activities.map(activity => (
          <div
            key={activity._id}
            onClick={this.navigateToLogActivityModal(activity._id)}
            className="workout-cell-content workout-history-cell-content activity-cell"
          >
            <div
              className="activity-icon-container"
              style={{ backgroundColor: activity.background_icon_color || '#EAEBFF' }}
            >
              <div className="icon">
                {activity.icon}
                {/* <Emoji text={activity.icon} onlyEmojiClassName="activity-emoji" /> */}
              </div>
            </div>
            <div>
              <div className="activity-title">{activity.name}</div>
              <div className="activity-time">{moment(activity.end_time).tz(timezone).format('h:mm a')}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };
}
