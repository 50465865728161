import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

export const WorkoutCompletionButton = styled(Button)`
  display: flex;

  justify-content: center;
  align-items: center;
  max-height: 30px;
  border-radius: 4px;
  height: 30px;
  padding: 6px 12px;

  border-radius: 4px;
  border: 1px solid #bfbfbf;
  background: #fff;

  font-weight: 600;
  font-size: 11px;
  color: #777;

  :hover {
    background: #f5f5f5;
  }

  .analytic-icon {
    margin-right: 10px;
  }

  ${props => props.isDashboardNotification && ButtonOnDashboardNotification}
`;

const ButtonOnDashboardNotification = css`
  height: 36px;
  max-height: 36px;
  padding: 6px 12px;
  color: #262626;
  font-size: 14px;
`;
