import React, { useEffect } from 'react';
import { isTeamAdmin } from 'utils/commonFunction';
import * as S from './style';
import BillingDetails from './BillingDetails';
import BillHistory from './BillHistory';
import CurrentPlan from './CurrentPlan';
import BillingContact from './BillingContact';

function BillingSetting(props) {
  useEffect(() => {
    if (!props.user || !isTeamAdmin(props.user)) {
      return props.push('/home/client');
    } else {
      props.getBillingOverviewData();
    }
  }, []);

  if (!props.user || !isTeamAdmin(props.user) || props.loading) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.Container>
        <CurrentPlan />
        <BillingDetails />
        <BillHistory />
        <BillingContact />
      </S.Container>
    </S.Wrapper>
  );
}

export default BillingSetting;
