// libraries
import React, { useMemo } from 'react';
import Avatar from 'react-avatar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

// store
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { getMetricGroupDetail, removeGroupMetricLibrary } from 'redux/metric-group-library/action';

// components
import DropDown, { Option } from 'shared/Dropdown/Basic';
import NewGroupModal from '../NewGroupModal';
import ConfirmModalGroup from '../ConfirmModal';

// constants
import { getUserShortName, pluralize, isTeamAdmin } from 'utils/commonFunction';
import { MODE } from 'components/MetricGroupLibrary/constants';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';

// assets
import { ReactComponent as GroupIcon } from 'assets/icons/metric_groups.svg';
import { ReactComponent as TriggerIcon } from 'assets/icons/vertical_ellipsis.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/MealPlans/action_duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/icons/MealPlans/action_edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';

import * as S from './style';

function GroupItem(props) {
  const { toggleConfirmModal, item, toggleModal, removeGroupMetric, user, key, getMetricGroupDetail } = props;

  const { title, metrics = [], owner = {}, _id, share } = item;

  const noOwner = share === TEAM_SHARE_NOOWNER;
  const isOwner = (owner || {})._id === user._id;

  const renderMetricNames = () => {
    return (
      <S.MetricNameWrapper>
        {metrics.map((metric, index) => (
          <>
            <S.NameSpan>{metric.name}</S.NameSpan>
            {index + 1 < metrics.length && <S.NameSpan className="dot" />}
          </>
        ))}
      </S.MetricNameWrapper>
    );
  };

  const handleEditGroup = () => {
    getMetricGroupDetail((item || {})._id, () => {
      toggleModal(false);
    });
    toggleModal(true, <NewGroupModal toggleModal={toggleModal} mode={MODE.EDIT} oldData={item} />);
  };

  const handleDuplicateGroup = () => {
    getMetricGroupDetail((item || {})._id, () => {
      toggleModal(false);
    });
    toggleModal(true, <NewGroupModal toggleModal={toggleModal} mode={MODE.DUPLICATE} oldData={item} />);
  };

  const removeGroup = () => {
    removeGroupMetric(_id, () => {
      toast('Metric group has been removed.');
    });
  };

  const handleRemoveGroup = () => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModalGroup
          onConfirm={removeGroup}
          toggleConfirmModal={toggleConfirmModal}
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
          hasCloseIcon
          content="Are you sure that you want to remove this metric group?"
        />,
      );
  };

  const renderAction = (Icon, label, action) => {
    return (
      <Option key={label} className="custom-dropdown-option" onClick={action && action}>
        <S.OptionIcon>
          <Icon className="group-action-icon" />
        </S.OptionIcon>
        <span>{label}</span>
      </Option>
    );
  };

  const renderActions = () => {
    const hasPermissionAction = isTeamAdmin(user) || get(item, 'owner._id', '') === get(user, '_id', '') || noOwner;

    return (
      <DropDown
        className="custom-dropdown-actions"
        triggerIcon={({ open }) => (
          <TriggerIcon
            className="trigger-icon dropdown-trigger"
            vertical
            active={!!open}
            data-tip
            data-for={tooltipId}
          />
        )}
        direction="left"
        isNewSpacing
      >
        {hasPermissionAction && renderAction(EditIcon, 'Edit', handleEditGroup)}
        {renderAction(DuplicateIcon, 'Duplicate', handleDuplicateGroup)}
        {hasPermissionAction && renderAction(RemoveIcon, 'Remove', handleRemoveGroup)}
      </DropDown>
    );
  };

  const tooltipId = `option-metric-tooltip-${_id}`;
  const tooltipAvatarId = `group-metric-avatar-tooltip-${_id}`;

  return (
    <S.ItemWrapper onClick={handleEditGroup} key={key}>
      <S.ItemHeader>
        <S.GroupLabel>
          <GroupIcon className="header-icon" />
          <S.GroupName>{title}</S.GroupName>
        </S.GroupLabel>
        <S.Trigger>
          {renderActions()}
          <ReactTooltip className="app-tooltip" id={tooltipId} effect="solid" place="top">
            <span>More options</span>
          </ReactTooltip>
        </S.Trigger>
      </S.ItemHeader>
      <S.ItemContent>
        <S.Metrics>{renderMetricNames()}</S.Metrics>
        <S.BottomGroup>
          <S.MetricNumber>{pluralize('Metric', metrics.length, true)} </S.MetricNumber>
          <S.AvatarWrapper data-tip data-for={tooltipAvatarId}>
            {!noOwner && !isOwner && (
              <Avatar
                name={getUserShortName(owner)}
                className="client-detail-avatar"
                size="20"
                src={owner.avatar}
                color={owner.color}
              />
            )}
            <ReactTooltip className="app-tooltip" id={tooltipAvatarId} effect="solid" place="top">
              <span>{`${owner.first_name} ${owner.last_name}`}</span>
            </ReactTooltip>
          </S.AvatarWrapper>
        </S.BottomGroup>
      </S.ItemContent>
    </S.ItemWrapper>
  );
}
const mapStateToProps = state => {
  const { user } = state;
  return {
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeGroupMetric: bindActionCreators(removeGroupMetricLibrary, dispatch),
  getMetricGroupDetail: bindActionCreators(getMetricGroupDetail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupItem);
