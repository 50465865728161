import React, { useEffect, useState } from 'react';

import { ReactComponent as GiphyIcon } from 'assets/icons/giphy_forum_comment_icon.svg';
import { ReactComponent as GiphyIconHover } from 'assets/icons/giphy_forum_comment_hover_icon.svg';
import * as S from 'shared/Giphy/styles';

function ForumCommentTrigger(props) {
  const { open } = props;
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (open !== isHovered) {
      setIsHovered(open);
    }
  }, [open]);

  return (
    <S.ForumCommentTrigger
      {...props}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => !open && setIsHovered(false)}
    >
      {isHovered ? <GiphyIconHover /> : <GiphyIcon />}
    </S.ForumCommentTrigger>
  );
}

export default ForumCommentTrigger;
