import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'shared/Pagination';
import { changeAutoflowQueryParam, getAutoflowList } from 'redux/autoflow/actions';

const Toolbar = ({ page, per_page, total, changeAutoflowQueryParam, getAutoflowList }) => (
  <Pagination
    page={page}
    perPage={per_page}
    totalPage={total}
    onPrev={() => {
      if (page > 1) {
        changeAutoflowQueryParam({ page: page - 1 });
        getAutoflowList();
      }
    }}
    onNext={() => {
      if (page * per_page < total) {
        changeAutoflowQueryParam({ page: page + 1 });
        getAutoflowList();
      }
    }}
  />
)

Toolbar.displayName = 'AutoflowListToolbar';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { query, total } = rootReducer.autoflow.common;
  const { page, per_page, } = query

  return { page, total, per_page }
}

const mapDispatchToProps = dispatch => ({
  changeAutoflowQueryParam: bindActionCreators(changeAutoflowQueryParam, dispatch),
  getAutoflowList: bindActionCreators(getAutoflowList, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
