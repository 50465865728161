import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import { hideGuideHowItWork } from 'redux/studio-program/actions';

class GuideStudioWork extends React.PureComponent {

  render() {
    if (this.props.user.is_hide_guide_studio_program) {
      return null;
    }
    return (
      <S.Wrapper>
        <div className="guideStudio__closeIcon" onClick={this.props.hideGuideHowItWork} />
        <div className="guideStudio__title">
          How do Studio programs work?
        </div>
        <div className="guideStudio__description">
          Program a common training regimen you want to offer clients. Your clients can start and stop the program anytime and even switch between programs. You see progress and results without lifting a finger. And if you need to update the program, edits only go live after you publish changes.
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatch = ({
  hideGuideHowItWork
});

export default connect(mapStateToProps, mapDispatch)(GuideStudioWork);
