export const LIST_DAY_OF_WEEK = [
  {
    dayOfWeek: 'Monday',
    dayIndex: 0,
  },
  {
    dayOfWeek: 'Tuesday',
    dayIndex: 1,
  },
  {
    dayOfWeek: 'Wednesday',
    dayIndex: 2,
  },
  {
    dayOfWeek: 'Thursday',
    dayIndex: 3,
  },
  {
    dayOfWeek: 'Friday',
    dayIndex: 4,
  },
  {
    dayOfWeek: 'Saturday',
    dayIndex: 5,
  },
  {
    dayOfWeek: 'Sunday',
    dayIndex: 6,
  },
];

export const STATUS_ADD_WEEK = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
};

export const STATUS_SCROLL = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
};

export const PER_PAGE = 20;

export const PER_PAGE_GET_UNASSIGNED_CLIENTS = 100;

export const ASSIGNED_STATUS = {
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

export const FORMAT_DATE_WEEK_IN = 'YYYY-MM-DD';

export const WIDTH_EACH_ITEM = 250;

export const SPACING_WITH_TRAIL = 32;

export const MEAL_PLAN_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
};

export const TYPE_REARRANGE = {
  MEAL: 'MEAL',
};
