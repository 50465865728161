import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  .models-filter {
    & > div {
      padding: 4px;
    }

    .options {
      width: auto;
      max-height: 200px;
      overflow-y: auto;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  background: #ffffff;
  /* border: 1px solid #f0f1f2; */
  box-sizing: border-box;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Open Sans';
  box-shadow: -9px 3px 12px -8px rgba(30, 46, 103, 0.1) inset;

  &.hiddenInput .input-wrapper {
    display: none;
  }

  .hidden {
    display: none;
  }

  .json-model {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .models-filter {
      background: #f0f1ff;
      border-radius: 8px;
      padding: 0 4px;

      svg {
        position: relative;
        top: 3px;
      }
    }

    .checkbox-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .checkbox-version {
    margin-bottom: -6px;
    margin-left: 20px;

    & input:checked ~ .checkmark {
      background-color: #5158cf;
    }

    .checkmark {
      border-radius: 4px;
    }
  }

  h2.ai-assistant {
    color: #202353;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 28px 20px 12px;
  }

  .loading-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: center;
    color: #202353;
    position: relative;

    @media screen and (max-height: 768px) {
      top: unset;
    }

    /* .icon-text-wrap {
      display: flex;
      position: relative;
    }

    .loading-right-text {
      padding-left: 10px;
    } */

    svg.svg-ani {
      width: 96px;
      height: 96px;
      transform: scale(2.7) translateX(2px);
      /* width: 126px;
      height: 126px;
      position: absolute;
      display: inline-block;
      left: -100px;
      top: -15px; */
    }
    h5 {
      margin: 31px 0 10px;
      padding: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 19.5px */
      margin-bottom: 16px;
    }
  }

  .conversations {
    position: relative;
    padding: 16px 10px 20px 20px;
    flex: 1;
    overflow-y: scroll;
    margin: 0 15px 0 0;

    &.has-list {
      max-height: calc(100% - 125px);
      padding: 0 10px 20px 20px;
      margin-top: 16px;
    }

    ::-webkit-scrollbar {
      width: 5px !important;
    }
  }

  .tell-us-more-wrapper {
    box-shadow: -9px 0px 12px -8px rgba(30, 46, 103, 0.1) inset;
    background-color: #fff;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 8px;
    svg {
      margin-right: 8px;
    }
    .tell-us-more {
      opacity: 0.3;
      display: flex;
      align-items: center;
      width: fit-content;
      margin: auto;
      cursor: pointer;
      :hover {
        opacity: 1;
        color: #5158cf;
        svg path {
          fill: #5158cf;
        }
      }
    }
  }

  .ai-thinking {
    display: flex;
    position: relative;
    bottom: 4px;
    align-items: center;
    gap: 8px;
    align-items: center;

    .ai-thinking-text {
      color: #000;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  a.read-more-link {
    color: #5158cf;
    font-size: 12px;
    line-height: 16.34px;
    font-weight: 700;
    display: inline-flex;
    margin-top: 17px;
    text-decoration: none !important;

    &:hover {
      color: #6456c4;
      text-decoration: underline !important;
    }

    svg {
      margin-right: 4px;
    }
  }

  @keyframes strokeChange {
    0% {
      fill: #ff78c9;
    }
    50% {
      fill: #7000ff;
    }
    100% {
      fill: #0029ff;
    }
  }

  .loading-rotate {
    position: relative;
    left: -3px;
    width: 24px;
    height: 24px;

    path {
      animation: strokeChange 2s infinite alternate;
      transform-origin: center;
    }
  }

  .__react_component_tooltip.app-tooltip.ai-tooltip {
    background-color: #2d2e2d;
    min-width: 57px;
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
    padding: 15px;
  }
  .__react_component_tooltip.app-tooltip.ai-tooltip.place-top::after {
    border-top-color: #2d2e2d !important;
    border-top-width: 7px !important;
    bottom: -6px !important;
    margin-bottom: 0px !important;
  }

  .input-wrapper {
    position: relative;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 20px;
    border: 1px solid #efefef;
    padding: 16px 4px 16px 16px;
    border-radius: 12px;

    span.input-placeholder {
      position: absolute;
      top: 16px;
      color: #000;
      opacity: 0.6;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      cursor: text;
    }

    &:hover,
    &:focus-within {
      border-color: #5158cf;
    }

    .input-actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 16px;
      padding-right: 12px;

      &.sending {
        svg path {
          fill: #5158cf;
        }
      }
    }

    #btn-example-wk-tooltip {
      width: 225px;
      text-align: left;
      height: 70px;
    }

    .ai-btn {
      width: 136px;
      height: 38px;
      border-radius: 8px;
      border: none;
      background: #010101;
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      line-height: 17.7px;
      font-weight: 600;
      color: #fff;
      &:focus-visible {
        outline: none;
      }

      &:disabled {
        cursor: default;
      }

      @keyframes flashAnimation {
        0% {
          background-position: 0% 20%;
        }
        50% {
          background-position: 100% 81%;
        }
        100% {
          background-position: 0% 20%;
        }
      }

      &.generate-btn {
        background: linear-gradient(332deg, #ff1fa2, #8349ff, #00f0ff, #0011e0);
        background-size: 800% 800%;
        animation: flashAnimation 8s ease infinite;

        svg {
          width: 24px;
          height: 24px;
          path {
            fill: #fff;
          }
        }
      }

      &.copy-btn {
        background-color: #e9ecf5;
        color: #202353;
        width: 169px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    &.is-first {
      width: 100%;
      min-height: 242px;
      max-height: 393px;
      display: block;

      & > .input {
        min-height: 169px;
        height: fit-content;
        max-height: 310px;
        max-width: 100%;
        transition: height 0.2s ease-in-out;
        &:empty::before {
          font-size: 14px !important;
          line-height: 21px;
        }
      }

      &.no-show-example {
        margin: 16px 0 !important;
      }
    }

    &:has(.input:focus-visible) {
      background-color: #fff;
    }

    .input {
      flex: 1;
      max-width: calc(100% - 34px);
    }

    .icon-wrap {
      position: absolute;
      right: 12px;
      bottom: 12px;
      transform: translateY(50% - 13px);
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: #5158cf;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        position: relative;
        left: 2px;
      }
    }

    .ai-section-format,
    .ai-single-exercise {
      padding-top: 3px;
    }

    .ai-section-format {
      height: 105px;
    }

    .ai-single-exercise-format {
      height: 14px;
    }

    .ai-interval-section {
      margin-top: 4px;
    }
  }
`;

export const TextEditable = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  max-height: 90px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none !important;
  word-break: break-word;
  cursor: text;
  padding-right: 8px;

  ::-webkit-scrollbar {
    opacity: 0;
    width: 4px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }

  :empty::before {
    content: ${props => `'${props.placeholder}'`};
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    opacity: 0.6;
  }
`;
