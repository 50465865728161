import styled, { css } from 'styled-components';
import { Radio } from 'semantic-ui-react';

export const Title = styled.div`
  color: #222;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
`;

export const Description = styled.div`
  color: #888;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 10px;
    `}
`;

export const DropdownArrowArea = styled.div`
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  padding-top: 2px;
`;

export const Ranges = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;

  .item {
    background: #eaeef1;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    font-family: 'Open Sans';
    color: #979797;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 13px 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: #e7e6fe;
      border: 1px solid #6456c4;
      color: #6456c4;

      .next-week-dropdown__indicators {
        svg {
          path {
            stroke: #6456c4;
          }
        }
      }
    }

    &:not(.disabled) {
      &.active {
        &:hover {
          color: #6456c4;
        }
      }

      &:hover {
        background: #e7e6fe;
        color: #979797;

        .next-week-dropdown__indicators {
          svg {
            path {
              stroke: #979797;
            }
          }
        }
      }
    }

    &.disabled {
      cursor: not-allowed !important;
    }

    &.dropdown {
      padding: 0;

      &:not(.active) {
        border: 1px solid transparent;
      }

      &.active,
      &.isOpen {
        .next-week-dropdown__indicators {
          svg {
            path {
              stroke: #6456c4;
            }
          }
        }
      }

      &.isOpen {
        border: 1px solid #6456c4 !important;
      }

      .next-week-dropdown__value-container {
        padding-left: 15px;
        padding-right: 15px;
      }

      .next-week-dropdown__value-container,
      .next-week-dropdown__control {
        width: 100%;
        height: 100%;
        padding: 0;
        min-height: auto;
        color: inherit;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      .next-week-dropdown__control {
        &.next-week-dropdown__control--is-focused {
          border: none !important;
          box-shadow: none;

          &.next-week-dropdown__control--menu-is-open {
            box-shadow: none;
            background: #e7e6fe;
            border: 1px solid #6456c4;
            color: #6456c4;

            .next-week-dropdown__indicators {
              svg {
                path {
                  stroke: #6456c4;
                }
              }
            }
          }
        }
      }

      .next-week-dropdown__single-value {
        display: flex;
        width: 100%;
        height: 100%;
        max-width: 100%;
        color: inherit;
        margin: 0;
        align-items: center;
        justify-content: center;
      }

      .next-week-dropdown__indicators {
        position: absolute;
        padding-right: 0;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          path {
            stroke: #979797;
          }
        }
      }

      .next-week-dropdown__menu {
        margin: 0;
        margin-top: 2px;
        background: #ffffff;
        border: 1px solid #dadfea;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        z-index: 3;

        .next-week-dropdown__menu-list {
          padding: 3px 0;

          .next-week-dropdown__option {
            color: #222222;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            text-align: left;
            padding: 0;
            cursor: pointer;

            &.next-week-dropdown__option--is-focused {
              background: none;
            }

            &.next-week-dropdown__option--is-selected,
            &:hover {
              font-weight: 600;
              font-size: 13px;
              line-height: 18px;
              color: #5158cf;
              background: #f6f5ff;
            }

            > div {
              display: inherit;
              width: 100%;
              padding: 8px 12px;
            }
          }
        }
      }
    }
  }
`;

export const Module = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;

  .info {
    flex: 1 1;
  }

  ${props =>
    props.disable &&
    css`
      ${Title} {
        color: #909090;
      }
      ${Description} {
        color: #c3c3c3;
      }
    `}

  .list-options {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 20px;
    cursor: pointer;

    ~ .child-radio-container {
      margin-left: 24px;
    }
  }

  .evf-checkbox {
    padding-left: 20px;
  }

  .checkbox-icon {
    background-size: 12px;
    border-radius: 4px;
    border-color: #c3c5cb;
  }

  .checkbox-label {
    color: #000;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 8px;
  }

  .btn-view-list {
    margin-left: 15px;
    color: #5158cf;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;

    :hover {
      text-decoration-line: underline;
    }
  }

  .tooltip {
    color: #fff;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #warning-tooltip {
    padding: 12px 16px;
    border-radius: 6px;
    background-color: #262626;
    margin-left: 1px;

    :after {
      border-top-color: #262626;
      border-top-width: 7px;
      border-left-width: 7px;
      border-right-width: 7px;
      margin-bottom: 1px !important;
    }
  }

  .checkbox-wrapper {
    display: flex;
    height: 20px;
  }

  .disable {
    cursor: not-allowed;
    .evf-checkbox {
      cursor: not-allowed;
    }
  }
`;

export const Content = styled.div`
  max-width: 650px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 55% 1fr;
  padding: 25px 0;

  ${Module} {
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const SelectValue = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 15px;
`;

export const CustomRadioSwap = styled(Radio)`
  &.ui.checkbox label:before {
    top: 0px !important;
  }
  &.ui.checkbox label:before {
    border-color: #c3c5cb !important;
  }
  &.ui.radio.checkbox input:checked ~ label:before {
    border-color: #5158cf !important;
  }

  &.ui.radio.checkbox label:after {
    top: 0px !important;
  }
  &.ui.radio.checkbox input:checked ~ label:after,
  &.ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #5158cf !important;
  }

  &.radio-exercise {
    width: 20px !important;
    height: 20px !important;
    min-width: unset !important;
    min-height: unset !important;
    line-height: 20px !important;

    input {
      width: 20px !important;
      height: 20px !important;
    }

    label {
      &::before {
        width: 20px !important;
        height: 20px !important;
        border-color: #c3c5cb !important;
      }

      &::after {
        width: 20px !important;
        height: 20px !important;
        transform: scale(0.55) !important;
      }
    }
  }
`;
