// Libs
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/lib/Async';
import moment from 'moment';
import Datetime from 'react-datetime';
import map from 'lodash/map';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

// Actions
import { assignMealPlan, getAssignedList, searchAssignMealPlanList } from 'redux/meal-plans/actions';
import { toggleModal } from 'actions/modal';
import { toggleSecondModal } from 'actions/modal';
import {
  getClientMealPlanByDay,
  getClientMealPlanByMonth,
  getClientMealPlanByWeek,
  setCurrentRangeWeek,
} from 'redux/client-meal-plan/actions';
import { getReportMacrosDaily, getReportMacrosWeekly } from 'actions/marcos';
import { showError, hideError } from 'actions/error';

// Shared
import CustomMultiValueLabel from './CustomMultiValueLabel';
import CustomOption from './CustomOption';

// Constants
import { DAY_FORMAT } from 'constants/time-format';
import { DATE_FORMAT } from 'constants/commonData';

// Assets
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_up_bold.svg';
import CloseIcon from 'assets/icons/close_bold_circle.svg';

// Style
import * as S from './style';

const getWeekNumber = date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 4 - (newDate.getDay() || 7));
  const yearStart = new Date(newDate.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((newDate - yearStart) / 86400000 + 1) / 7);
  return weekNumber;
};

const AssignMealPlanModal = props => {
  const {
    detailMealPlan,
    assignMealPlan,
    searchAssignMealPlanList,
    toggleSecondModal,
    readOnlyAssignTO,
    workingClientDetail,
    toggleModal,
    getClientMealPlanByMonth,
    getClientMealPlanByDay,
    getReportMacrosDaily,
    clientTimezone,
    assignedDay,
    currentMonth,
    showError,
    hideError,
    onCloseError,
    mealPlanByDay = {},
    viewMode,
    getClientMealPlanByWeek,
    getReportMacrosWeekly,
    setCurrentRangeWeek,
    selectedDay,
  } = props;

  const [assignTo, setAssignTo] = useState([]);
  const [startingOn, setStartingOn] = useState(moment(assignedDay).tz(clientTimezone));
  const [isAssigning, setIsAssigning] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClose = () => {
    toggleSecondModal && toggleSecondModal(false);
  };

  const searchClient = (inputValue, callback) => {
    const except = map(
      assignTo.filter(({ typeName = '' }) => typeName === 'group'),
      '_id',
    );
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    searchAssignMealPlanList({
      search,
      startingDay: moment(startingOn).format('YYYY-MM-DD'),
      mealPlanId: get(detailMealPlan, '_id', ''),
      except,
    })
      .then(response => {
        if (response.length) {
          const data = response.map(item => get(item, 'data.data', {}));

          const clients = data[1].data.map(client => ({
            ...client,
            typeName: 'client',
            name: `${client.first_name} ${client.last_name}`,
          }));

          const groups = data[0]
            .filter(({ total_clients = 0 }) => total_clients > 0)
            .map(group => ({
              ...group,
              typeName: 'group',
            }));

          const results = map([...groups, ...clients], item => {
            const { _id, typeName, total_clients, name } = item || {};

            return {
              ...item,
              key: _id,
              value: _id,
              label: name,
              total: total_clients,
              typeName: typeName,
            };
          });

          callback(results);
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const handleAssignToMember = list => {
    setAssignTo(list);
  };

  const handleDisabledOption = ({ has_other_meal_plan, has_conflicted_meal_plan }) =>
    has_other_meal_plan || has_conflicted_meal_plan;

  const defaultValue = () => {
    return assignTo.map(item => {
      const { _id, full_name, first_name, last_name } = item || {};

      return {
        ...item,
        key: _id,
        value: _id,
        label: full_name || `${first_name} ${last_name}`,
      };
    });
  };

  const searchClientDebounce = debounce(searchClient, 200);

  const renderAssignTo = () => {
    return (
      <S.FormGroup>
        <S.FormTitle>Assign To</S.FormTitle>
        <S.FormActions>
          {readOnlyAssignTO ? (
            <S.ViewAssignTO>{workingClientDetail && workingClientDetail.full_name}</S.ViewAssignTO>
          ) : (
            <AsyncSelect
              key={JSON.stringify(startingOn)}
              value={assignTo}
              isMulti
              defaultOptions
              cacheOptions
              components={{
                Option: CustomOption,
                MultiValueLabel: CustomMultiValueLabel,
                DropdownIndicator: null,
                LoadingIndicator: null,
              }}
              loadOptions={(inputValue, callback) => searchClientDebounce(inputValue, callback)}
              onChange={handleAssignToMember}
              className="multi-select-container"
              classNamePrefix="multi-select"
              placeholder="Add Client..."
              noOptionsMessage={() => 'No results.'}
              defaultValue={defaultValue()}
              styles={S.customStyles}
              isOptionDisabled={handleDisabledOption}
            />
          )}
        </S.FormActions>
      </S.FormGroup>
    );
  };

  const renderMealPlan = () => {
    return (
      <S.FormGroup>
        <S.FormTitle>Meal Plan</S.FormTitle>
        <S.FormActions>
          <S.MealPlanName>
            <span>{get(detailMealPlan, 'name', '')}</span>
          </S.MealPlanName>
        </S.FormActions>
      </S.FormGroup>
    );
  };

  const renderDatetimeInput = props => {
    const mondayOfWeek = startingOn.clone().startOf('isoWeek');

    let inputClass = 'meal-plan-starting-on';

    if (props.className) {
      inputClass += ' ' + props.className;
    }

    return (
      <div {...props} className={inputClass}>
        Week of {mondayOfWeek.format('MMMM DD, YYYY')}
        <ArrowDown className="icon-down" />
      </div>
    );
  };

  const renderDay = (props, currentDate) => {
    const formatDate = (date, timezone) => date.tz(timezone).format(DAY_FORMAT.YYYY_MM_DD);
    const checkDateSameToday =
      getWeekNumber(formatDate(currentDate, clientTimezone)) === getWeekNumber(formatDate(startingOn, clientTimezone));
    const selectWeek = checkDateSameToday ? 'active' : '';

    return (
      <td {...props} className={`${props.className} ${selectWeek}`}>
        <div className="rdt__custom-day">
          <div>{currentDate.date()}</div>
        </div>
      </td>
    );
  };

  const renderStartingOn = () => {
    return (
      <S.FormGroup>
        <S.FormTitle>Starting On</S.FormTitle>
        <S.FormActions>
          <S.CustomDatetime>
            <Datetime
              className="new-ui"
              value={startingOn}
              renderInput={renderDatetimeInput}
              timeFormat={false}
              closeOnSelect={true}
              onChange={date => {
                setStartingOn(date);
                setErrorMessage(false);
                setIsAssigning(false);
              }}
              isValidDate={current => current.isSameOrAfter(moment(), 'day')}
              renderDay={renderDay}
            />
          </S.CustomDatetime>
        </S.FormActions>
      </S.FormGroup>
    );
  };

  const handleSubmit = () => {
    if (isAssigning) {
      return;
    }

    setIsAssigning(true);

    const starting_day = moment(startingOn).format('YYYY-MM-DD');

    const data = {
      meal_plan_id: get(detailMealPlan, '_id', ''),
      clients: [workingClientDetail && workingClientDetail._id],
      starting_day,
    };

    assignMealPlan &&
      assignMealPlan(
        data,
        () => {
          toggleModal && toggleModal(false);
          toggleSecondModal && toggleSecondModal(false);
          setIsAssigning(false);
        },
        false,
        true,
      )
        .then(response => {
          const { valid_clients = [], invalid_clients = [] } = get(response, 'data.data', {});

          if (!!valid_clients.length) {
            toast('Meal Plan has been assigned.');
            handleCloseNotiModal();
          } else {
            setErrorMessage(!!invalid_clients.length);
          }
        })
        .catch(err => {
          showError(err && err.message, null, null, null, null, () => handleCloseError());
        });
  };

  const handleCloseError = () => {
    hideError(false);
    toggleSecondModal && toggleSecondModal(false);
    onCloseError();
  };

  const handleCloseNotiModal = () => {
    toggleSecondModal(false);

    const clientId = get(workingClientDetail, '_id');
    const fromDay = currentMonth.clone().set('D', 1).subtract(7, 'd').format(DAY_FORMAT.YYYY_MM_DD);
    const toDay = currentMonth
      .clone()
      .set('D', 1)
      .add(1, 'month')
      .subtract(1, 'day')
      .add(7, 'd')
      .format(DAY_FORMAT.YYYY_MM_DD);

    if (clientId) {
      typeof getClientMealPlanByMonth === 'function' &&
        getClientMealPlanByMonth({
          client: clientId,
          from_day: fromDay,
          to_day: toDay,
        });

      if (viewMode.key === 'day') {
        typeof getClientMealPlanByDay === 'function' && getClientMealPlanByDay(clientId, mealPlanByDay.day);
        typeof getReportMacrosDaily === 'function' &&
          getReportMacrosDaily({ client: clientId, day: startingOn.clone().format(DATE_FORMAT) });
      } else if (viewMode.key === 'week') {
        const startDayWithFormatYMD = startingOn.clone().startOf('isoWeek').format(DAY_FORMAT.YYYY_MM_DD);
        const startDayWithFormatMDY = startingOn.clone().startOf('isoWeek').format(DATE_FORMAT);
        const endDayWithFormatMDY = startingOn.clone().endOf('isoWeek').format(DATE_FORMAT);
        const selectedDayFormatYMD = selectedDay.clone().format(DAY_FORMAT.YYYY_MM_DD);

        const currentRangeWeek = () => {
          const start = moment(startDayWithFormatYMD);
          let weekRange = [];
          for (let i = 0; i < 7; i++) {
            weekRange.push(start.clone().add(i, 'days').format(DAY_FORMAT.YYYY_MM_DD));
          }
          return weekRange;
        };

        if (currentRangeWeek().includes(selectedDayFormatYMD)) {
          typeof getClientMealPlanByWeek === 'function' && getClientMealPlanByWeek(clientId, startDayWithFormatYMD);
          typeof getReportMacrosWeekly === 'function' &&
            getReportMacrosWeekly({
              client: clientId,
              startDay: startDayWithFormatMDY,
              endDay: endDayWithFormatMDY,
            });
          typeof setCurrentRangeWeek === 'function' && setCurrentRangeWeek(currentRangeWeek());
        }
      }
    }
  };

  return (
    <S.CustomModal open={true} onClose={handleClose} closeOnDimmerClick={false} className="evf-assign-meal-plan-modal">
      <Modal.Header>
        <S.HeaderWrapper>
          <S.HeaderTitle>Assign Meal Plan</S.HeaderTitle>
          <CloseButton className="close-button" onClick={handleClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          {renderAssignTo()}
          {renderMealPlan()}
          {renderStartingOn()}
          {errorMessage ? (
            <S.ErrorMessageWrapper>
              <S.ErrorMessage>
                Please select a future week which does not overlap the period of your current meal plans.
              </S.ErrorMessage>
            </S.ErrorMessageWrapper>
          ) : null}
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ActionsWrapper>
          <S.Button onClick={handleSubmit} isDisabled={isAssigning}>
            Assign
          </S.Button>
        </S.ActionsWrapper>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapState = state => {
  const { rootReducer } = state;

  return {
    mealPlanByDay: get(rootReducer, 'clientMealPlan.mealPlanByDay', {}),
    viewMode: get(rootReducer, 'clientMealPlan.viewMode'),
  };
};

const mapDispatch = dispatch => ({
  assignMealPlan: bindActionCreators(assignMealPlan, dispatch),
  getAssignedList: bindActionCreators(getAssignedList, dispatch),
  searchAssignMealPlanList: bindActionCreators(searchAssignMealPlanList, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  getClientMealPlanByMonth: bindActionCreators(getClientMealPlanByMonth, dispatch),
  getClientMealPlanByDay: bindActionCreators(getClientMealPlanByDay, dispatch),
  getClientMealPlanByWeek: bindActionCreators(getClientMealPlanByWeek, dispatch),
  getReportMacrosDaily: bindActionCreators(getReportMacrosDaily, dispatch),
  getReportMacrosWeekly: bindActionCreators(getReportMacrosWeekly, dispatch),
  showError: bindActionCreators(showError, dispatch),
  hideError: bindActionCreators(hideError, dispatch),
  setCurrentRangeWeek: bindActionCreators(setCurrentRangeWeek, dispatch),
});

export default connect(mapState, mapDispatch)(AssignMealPlanModal);
