import styled from 'styled-components';
import { Button } from 'shared/FormControl';
import { TableContainer, Wrapper } from 'shared/LibraryLayout';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
`;

export const NotFoundWrapper = styled.div`
  margin-bottom: 15px;
`;

export const NoResultText = styled.div`
  ${baseText}
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #9a9da6;
  text-align: center;
`;

export const EmptyWrapper = styled.div`
  margin-bottom: 43px;
`;

export const FirstSectionButton = styled(Button)`
  ${baseText}
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
  width: 180px;
  height: 36px;

  .plus-icon {
    margin-right: 10px;
  }

  :hover {
    background-color: rgba(81, 88, 207, 0.9);
  }
`;

export const LayoutWrapper = styled(Wrapper)`
  padding-right: 20px;

  .evfPagination {
    padding-right: 40px;
  }
`;

export const TableContainerWrapper = styled(TableContainer)`
  overflow-x: scroll;
  padding-right: 40px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;
