import { connect } from 'react-redux';
import Section from './component';
import { updateEntity } from 'redux/model/actions';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import { Exercise } from 'database/schema';
import { ENTITY_NAME } from 'database/constants';
import {
  duplicateSection,
  saveSectionToLibrary,
  removeSection,
  customizeSection,
  addExerciseInsideSection,
  changeOrderSection,
} from 'redux/workout-builder/actions';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = (state, ownProps) => {
  const {
    rootReducer: { model },
  } = state;

  const section = state.rootReducer.model.getIn([ENTITY_NAME.Section, ownProps.sectionId]);
  let totalExercises = 0;
  if (section.get('exercises')) {
    const exercises = denormalize(section.get('exercises'), [Exercise], state.rootReducer.model).toJS();
    totalExercises = _.sumBy(exercises, e => e.supersets.length || 0);
  } else {
    const exerciseReferences = section.get('exercise_references');
    totalExercises = _.sumBy(exerciseReferences, e => e.length || 0);
  }

  return {
    totalExercises,
    model: model.getIn([ENTITY_NAME.Section, ownProps.sectionId]),
    draggingItemLeft: state.rootReducer.workoutBuilder.getIn(['draggingItemLeft']),
    showError: state.rootReducer.workoutBuilder.getIn(['showError']),
  };
};

const actionCreators = {
  updateEntity,
  duplicateSection,
  saveSectionToLibrary,
  removeSection,
  customizeSection,
  addExerciseInsideSection,
  changeOrderSection,
  toggleConfirmModal,
};

export default connect(mapStateToProps, actionCreators)(Section);
