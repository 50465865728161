import Request, { axiosInstance } from 'configs/request';
import { toggleModal } from 'actions/modal';
import { get } from 'lodash';

export const Types = {
  AUTOFLOW_FORUM_CONNECT_SUCCESS: 'AUTOFLOW_FORUM_CONNECT_SUCCESS',
};

export const autoflowConnectForum = (autoflowId, forumId, inviteMode) =>
  Request.post(
    { url: '/api/autoflow/connect-forum', data: { autoflowId, forumId, inviteMode } },
    true,
    (response, { dispatch }) => {
      dispatch({ type: Types.AUTOFLOW_FORUM_CONNECT_SUCCESS, payload: get(response, 'data.data.forum') });
    },
  );

export const autoflowDisconnectForum = autoflowId =>
  Request.post({ url: '/api/autoflow/disconnect-forum', data: { autoflowId } }, true, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_FORUM_CONNECT_SUCCESS, payload: null });
    dispatch(toggleModal(false));
  });

export const autoflowInvite = (autoflowId, forumId, inviteMode) =>
  Request.post(
    { url: '/api/autoflow/invite-clients-to-forum', data: { autoflowId, forumId, inviteMode } },
    true,
    (response, { dispatch }) => {
      dispatch(toggleModal(false));
    },
  );

export const autoflowCheckInviteClient = autoflowId =>
  axiosInstance.get(`/api/autoflow/client/count?autoflow=${autoflowId}`);
