import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TRIAL_MODE } from 'constants/commonData';

const WarningText = styled.span`
  background: #FFF6DE;
  border-radius: 3px;
  padding: 5px 15px;
  font-size: 13px;
  line-height: 120%;
  color: #65462A;
  font-weight: normal;
  margin-left: 10px;
`;


const AutoflowListHeader = ({ total, teamData }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Autoflow</span>
      {teamData.trialMode === TRIAL_MODE.Active ? (
        <WarningText>
          Try out the Automation add-on during your Trial!&nbsp;&nbsp;🎉
        </WarningText>
      ) : null}
    </div>
  );
}

const mapStateToPropss = state => {
  const { rootReducer } = state;
  const { autoflow } = rootReducer;
  
  return {
    total: autoflow.common.total,
    teamData: rootReducer.pricing.get('teamData').toJS()
  }
}

export default connect(mapStateToPropss, null)(AutoflowListHeader);