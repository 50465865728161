import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { PLANS, TRIAL_MODE } from 'constants/commonData';
import classNames from 'classnames';
import get from 'lodash/get';

const UpgradeButtonCustom = styled(UpgradePathButton)`
  padding: 9px 40px;
  height: 30px;
  margin-left: 10px !important;
`;

function getTimeRemain(expiredDate) {
  const expired = moment(expiredDate);
  const current = moment();
  const dayRemain = expired.diff(current, 'days');
  return dayRemain > 1 ? dayRemain + ' days left' : '1 day left';
}

function UpgradeButton(props) {
  const { teamData, isHideProgramLibraryBanner, location } = props;
  const isShowButton = teamData.tier === PLANS.starter.key || teamData.trialMode === TRIAL_MODE.Active;
  const isShowText = teamData.trialMode === TRIAL_MODE.Active;
  const programRegex = /^\/home\/program\/?$/;
  const pathname = get(location, 'pathname');
  const isProgramBanner = isHideProgramLibraryBanner && programRegex.test(pathname);

  return (
    <>
      {isShowText && (
        <span
          className={classNames('trialExpired__text', {
            'program-library': isProgramBanner,
          })}
          style={{ fontSize: '12px' }}
        >
          <strong>{getTimeRemain(teamData.expiredDate)}</strong> <span className="break-trial-text" />
          <span className="trial-bold"> until trial ends</span>
        </span>
      )}
      {isShowButton && <UpgradeButtonCustom data-btn-upgrade="btn-upgrade" notSendPrev text="Upgrade" />}
    </>
  );
}

const mapStateToProps = state => {
  const { user, rootReducer, router } = state;

  return {
    user,
    teamData: rootReducer.pricing.get('teamData').toJS(),
    isHideProgramLibraryBanner: rootReducer.generalSettings.is_hide_program_library_banner,
    location: router.location,
  };
};

export default connect(mapStateToProps)(UpgradeButton);
