import React, { useMemo } from 'react';
import { get } from 'lodash';
import { FormGroup } from 'shared/FormControl';
import { initialValues } from 'components/Leaderboard/components/FormModal/helper';
import * as S from './styles';

export default function LeaderBoard({ leaderBoardList, push, autoflowId, leaderboardTemplates, rankingTypes }) {
  const currentLeaderboard = get(leaderBoardList, '[0]', null);
  const originalValues = useMemo(() => {
    return initialValues(currentLeaderboard, leaderboardTemplates, rankingTypes);
  }, [currentLeaderboard]);
  const open = url => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };
  const handleCreateLeaderboard = () => {
    open(`/home/autoflow/${autoflowId}/leaderboard`);
  };
  if (!currentLeaderboard) {
    return (
      <FormGroup className="forum-form-group no-bottom-margin" style={{ marginTop: '20px' }}>
        <label>
          <span>CREATE LEADERBOARD</span>
        </label>
        <S.LeaderBoardContainer>
          <div className="create-leaderboard-description">
            Create a competitive leaderboard to motivate members of this Autoflow
          </div>
          <div className="create-leaderboard-btn" onClick={handleCreateLeaderboard}>
            Create Leaderboard
          </div>
        </S.LeaderBoardContainer>
      </FormGroup>
    );
  } else {
    const currLeaderboardTemplate = leaderboardTemplates.find(
      el => el.ranking_type === currentLeaderboard.ranking_type,
    );
    const { banner_url, description } = currLeaderboardTemplate;
    const labelType = originalValues.type.label;
    const unit =
      originalValues.type.unit.value !== '' ? originalValues.type.unit.value : originalValues.type.unitInfo.title;
    return (
      <FormGroup className="forum-form-group no-bottom-margin no-padding" style={{ marginTop: '20px' }}>
        <label>
          <span>LEADERBOARD</span>
        </label>
        <S.LeaderBoardContainer noBackgroundImage>
          <S.LeaderboardTemplateBanner src={banner_url} alt="some" />
          <div>
            <div className="leaderboard-name">{currentLeaderboard.name}</div>
            <div className="leaderboard-description">
              {labelType} ({unit}) - {description}
            </div>
          </div>
        </S.LeaderBoardContainer>
      </FormGroup>
    );
  }
}
