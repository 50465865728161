// libraries
import React from 'react';

import * as S from './style';

function GroupSkeleton() {
  return (
    <S.ItemWrapper>
      <S.ItemHeader />
      <S.ItemContent>
        <S.MetricWrapper>
          <S.Metrics />
        </S.MetricWrapper>
        <S.BottomGroup>
          <S.MetricNumber />
          <S.AvatarWrapper />
        </S.BottomGroup>
      </S.ItemContent>
    </S.ItemWrapper>
  );
}

export default GroupSkeleton;
