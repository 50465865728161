// Libs
import React, { useState, useRef, useEffect } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';

// Style
import * as S from './style';

const InputName = ({
  placeholder = 'Add Ingredient name',
  onChange = () => {},
  value = '',
  numberLimit = 50,
  numberLimitShow = 45,
  isViewMode = false,
  hasError = false,
  isMulti = false,
  onFocus = false,
  showTextLimit = true,
}) => {
  const inputRef = useRef(null);

  const [data, setData] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (onFocus && inputRef.current && !isViewMode) {
      inputRef.current.focus();
    }
  }, []);

  const handleChangeName = e => {
    const value = get(e, 'target.value', '');
    if (value.length > numberLimit) {
      setData(value.slice(0, 50));
    }
    setData(value);
    onChange(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <S.Wrapper isViewMode={isViewMode} hasError={hasError} isMulti={isMulti}>
      <input
        ref={inputRef}
        placeholder={placeholder}
        onChange={handleChangeName}
        value={data}
        maxLength={numberLimit}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {data.length >= numberLimitShow && showTextLimit && isFocused && (
        <span className={classNames('text-limit', { error: data.length >= numberLimit, 'is-multi': isMulti })}>
          {data.length}/{numberLimit}
        </span>
      )}
    </S.Wrapper>
  );
};

export default InputName;
