import styled, { css } from 'styled-components';
import * as Layout from 'shared/LibraryLayout';
import DeleteModalMealPlan from 'components/MealPlans/parts/DeleteModalMealPlan';
import { CDN_URL } from 'constants/commonData';

const baseText = `
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
`;

export const TableBody = styled(Layout.TableBody)``;

export const TableRow = styled(Layout.TableRow)``;

export const TableCell = styled(Layout.TableCell)`
  ${baseText}
  padding: 19.5px 10px;
  &.custom-align-center {
    padding: 19.5px 0;
    .__react_component_tooltip.app-tooltip.show {
      background: #333;
      padding: 8px 16px;
      border-radius: 4px;
      ${baseText}
      color: #fff;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      width: 136px;
      height: 34px;
    }
    .__react_component_tooltip.type-dark.place-top:after {
      border-top-color: #333;
      margin-bottom: -2px !important;
    }
    .__react_component_tooltip.place-top {
      margin-top: -11px;
    }
  }
  .custom-name {
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .type-ingredient {
    vertical-align: bottom;
  }
  .evf-dropdown.custom-dropdown-actions {
    width: fit-content;
    .evf-dropdown__trigger-container {
      .dropdown-trigger {
        background-color: transparent;
        border-radius: 5px;
        .dot,
        .dot::after,
        .dot::before {
          width: 4px;
          height: 4px;
          background-color: #7b7e91;
        }
        .dot::before {
          left: -6px;
        }
        .dot::after {
          left: 6px;
        }
        :hover {
          background-color: #f0f1ff;
          .dot,
          .dot::after,
          .dot::before {
            background-color: #5158cf;
          }
        }
      }
    }
    &.open {
      .evf-dropdown__trigger-container .dropdown-trigger {
        background-color: #f0f1ff;
        .dot,
        .dot::after,
        .dot::before {
          background-color: #5158cf;
        }
      }
    }
    .evf-dropdown__menu {
      padding: 5px 0;
      width: 150px;
      .evf-dropdown__option {
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        user-select: none;
        .icon {
          display: flex;
          justify-content: center;
        }
        span {
          color: #f7f8f9;
          font-family: 'Open Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
  &.action {
    padding: 0;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .checkbox__thumbnail {
    width: 40px;
    height: 40px;
    display: none;
  }
  .checkbox__header {
    display: flex;
    justify-content: center;
    padding-left: 26px;
  }
  &.checked,
  :hover {
    .thumbnail-container {
      display: none;
    }
    .checkbox__thumbnail {
      display: flex;
      justify-content: center;
      .evf-checkbox {
        width: 100%;
        border: none;
      }
      .checkbox-icon {
        width: 15px;
        height: 15px;
        left: calc(50%);
        background-size: calc(15px / 2);
      }
      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    }
  }
  .checkbox__thumbnail .checkbox-icon,
  .checkbox-icon {
    width: 15px;
    height: 15px;
    left: calc(50% - calc(13px / 2));
    transform: translateY(-50%) translateX(-50%);
    border: 1px solid #e0e0e0;
    background-image: url(${CDN_URL}/images/checked_white.svg);
    border-radius: 2px;
  }
  .checkbox__thumbnail {
    .checkbox-icon {
      left: calc(50%);
    }
  }
  .evf-checkbox {
    padding-left: 0;
  }
  ${({ allowActive }) =>
    allowActive &&
    css`
      :hover {
        .thumbnail-container {
          display: flex;
        }
        .checkbox__thumbnail {
          display: none;
        }
      }
    `}
`;

export const ThumbnailContainer = styled.div`
  overflow: hidden;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  width: 40px;
  height: 40px;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
`;

export const RemoveIngredientModal = styled(DeleteModalMealPlan)`
  .confirm-content-body {
    padding: 16px 30px 30px !important;
  }
`;

export const Category = styled.div`
  position: relative;
  .dot {
    display: inline-block;
    margin: 0 5px 4px 5px;
    width: 2px;
    height: 2px;
    background: #a3a3b5;
    border-radius: 50%;
  }
`;

export const ActionContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;

  .custom-dropdown-actions {
    margin-right: 10px;
    &.hidden {
      visibility: hidden;
    }
  }
  .owner-avatar {
    opacity: 1;
  }
  .__react_component_tooltip {
    &.app-tooltip {
      max-width: 165px;
      padding: 8px 16px !important;
      ${baseText}
      font-size: 12px !important;
      font-weight: 600 !important;
      line-height: 18px !important;

      &.place-top {
        margin-top: -14px !important;
        &:after {
          border-top-width: 8px !important;
          bottom: -9px !important;
        }
      }
      .title {
        font-weight: 400 !important;
      }
    }
  }
`;
