// Libs
import React from 'react';
import isEmpty from 'lodash/isEmpty';

// Constants
import { conditionalRoundNutrition, getNutrientValue, sortCaloriesToTop } from 'components/RecipeDetail/helper';
import { listMacronutrients } from 'components/RecipeDetail/constants';

// Styles
import * as S from './style';

const NutritionView = ({ macroNutrients }) => {
  return (
    <S.NutritionWrapper>
      {sortCaloriesToTop([...listMacronutrients]).map((item, index) => {
        const { label = '', unit = '', type = '' } = item || {};

        const value = getNutrientValue(macroNutrients, type);
        const isEmptyValue = +value === 0 || isEmpty(macroNutrients);

        return (
          <S.NutritionItem key={index}>
            <S.NutritionValues>
              <span className={isEmptyValue ? 'is-empty' : ''}>
                {isEmptyValue ? '—' : conditionalRoundNutrition(value)}
              </span>
              <S.NutritionUnit>{unit}</S.NutritionUnit>
            </S.NutritionValues>
            <S.NutritionMetric>{label}</S.NutritionMetric>
          </S.NutritionItem>
        );
      })}
    </S.NutritionWrapper>
  );
};

export default NutritionView;
