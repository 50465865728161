import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { AUTOFLOW_CLIENT_STATUS } from 'constants/commonData';
// import svg
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg';

const Wrapper = styled.div`
  .started_date {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &.warining {
      color: #ea314a;
    }

    .resumed-icon {
      margin-left: 10px;
    }

    .client-listing {
      text-align: left;
      padding: 15px;

      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #b6b6b6;
      }

      .date {
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }

  .started_on {
    font-weight: normal;
    font-size: 11px;
    line-height: 100%;
    color: #77849d;
  }
`;

const FORMAT_DATE = 'MMM D, YYYY';

const StartDateColumn = ({ item }) => {
  const { started_date, started_on, status, resumed_date, resumed_on } = item;
  const isHighlightStartDate =
    status === AUTOFLOW_CLIENT_STATUS.WAITING_TO_START &&
    moment(started_date, 'MM-DD-YYYY').isBefore(moment().startOf('day'));
  return (
    <Wrapper>
      <div className={classnames('started_date', { warining: isHighlightStartDate })}>
        <div>{moment(started_date, 'MM-DD-YYYY').format(FORMAT_DATE)}</div>
        {status === AUTOFLOW_CLIENT_STATUS.ACTIVE && resumed_date && resumed_on && (
          <>
            <div className="resumed-icon" data-tip data-for={`resumed-${item._id}`}>
              <ArrowIcon />
            </div>
            <ReactTooltip
              delayShow={500}
              className="app-tooltip client-listing"
              id={`resumed-${item._id}`}
              effect="solid"
              place={'top'}
            >
              <div className="title">Start date</div>
              <div className="date">{`${moment(started_date, 'MM-DD-YYYY').format(
                FORMAT_DATE,
              )} (Day ${started_on})`}</div>
              <br />
              <div className="title">
                <ArrowIcon /> Resumed date
              </div>
              <div className="date">
                {`${moment(resumed_date, 'MM-DD-YYYY').format(FORMAT_DATE)} (Day ${resumed_on})`}
              </div>
            </ReactTooltip>
          </>
        )}
      </div>
      <div className="started_on">Day {started_on}</div>
    </Wrapper>
  );
};

export default StartDateColumn;
