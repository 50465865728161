import styled from 'styled-components';

export const EmojiHabit = styled.div`
  display: inline-block;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: ${props => props.color || '#FCF5DE'};
  position: relative;

  img {
    filter: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;
