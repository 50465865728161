// Libs
import React, { useMemo } from 'react';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// Constants
import { RECIPE_STATUS } from 'components/Recipes/constants';

// Styles
import * as S from './style';

const RecipeHeader = ({ name, status, match }) => {
  const recipeId = get(match, 'params.recipeId');

  const title = useMemo(() => {
    // waiting load data
    if (recipeId && recipeId !== 'create' && !status) return '';

    const text = (name || '').length > 40 ? name.slice(0, 40).concat('...') : name;

    return text || 'Untitled';
  }, [name, status, recipeId]);

  const renderStatusLabel = useMemo(() => {
    switch (status) {
      case RECIPE_STATUS.PUBLISH:
        return (
          <S.RecipeStatus isPublished>
            <S.RecipeLabel isPublished>Published</S.RecipeLabel>
          </S.RecipeStatus>
        );
      case RECIPE_STATUS.DRAFT:
        return (
          <S.RecipeStatus>
            <S.RecipeLabel>Draft</S.RecipeLabel>
          </S.RecipeStatus>
        );
      default:
        return null;
    }
  }, [status]);

  return (
    <>
      <Helmet>
        <title>{name ? `${name} - Recipe Library -  Everfit` : 'Recipe Library -  Everfit'}</title>
      </Helmet>
      <S.RecipeHeaderWrapper>
        <S.RecipeHeader>{title}</S.RecipeHeader>
        {renderStatusLabel}
      </S.RecipeHeaderWrapper>
    </>
  );
};

const mapState = state => {
  const {
    rootReducer: { recipes },
  } = state;

  const name = get(recipes, 'detailRecipe.name', '');
  const status = get(recipes, 'detailRecipe.status', '');

  return { name, status };
};

export default withRouter(connect(mapState, null)(RecipeHeader));
