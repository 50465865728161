import React from 'react';
import { get } from 'lodash';
import { AutomationWrapper, PurchasedButton } from './style';
import { ReactComponent as CheckIcon } from 'assets/icons/checked_purple.svg';

export const getPeriodName = period => {
  return period === 'monthly' ? 'mo' : 'yr';
};

function OneTimeAddOnPurchased(props) {
  const { addOn } = props;

  return (
    <AutomationWrapper data-billing-automation="Advanced Custom Branding">
      <div className="automation__left">
        <div className="automation__icon">
          <img src={addOn.icon} />
        </div>
        <div className="automationContent">
          <div className="automationContent__name">
            <a target="_blank" href={get(addOn, 'articleLink', '')}>
              {addOn.title}
            </a>
            <span className="automationContent__name--addOn">Add-on</span>
          </div>
          <div className="automationContent__featureTitle">{addOn.description}</div>
        </div>
      </div>
      <div className="automation__purchased">
        <PurchasedButton className="add-on" data-btn="button-purchased">
          <CheckIcon />
          Purchased
        </PurchasedButton>
      </div>
    </AutomationWrapper>
  );
}

export default OneTimeAddOnPurchased;
