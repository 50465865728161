import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import {
  getListTasks,
  changeViewMode,
  changeStartDate,
  arrangeTask,
  moveTask,
  resetData,
  resetCopyItem,
} from 'redux/autoflow/task/actions';
import { replace } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import HabitsBuilderPopup from 'components/TaskCreate/HabitsBuilderPopup';
import { getDetailAutoflowHabit } from 'redux/autoflow/habit/actions';
import CalendarLayout from 'components/AutoflowCalendar';
import RepeatTaskConfirm from 'components/TaskRepeat/ConfirmModal';
import { CONFIRM_TYPES } from 'components/TaskRepeat/ConfirmModal/constants';
import { AUTOFLOW_TYPES } from 'constants/commonData';

function TaskCalendarLayout(props) {
  const [isConfirm, setConfirm] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const habitId = get(props, 'match.params.taskId');

  useEffect(() => {
    if (habitId) {
      props.getDetailAutoflowHabit &&
        props.getDetailAutoflowHabit(habitId).then(response => {
          const data = get(response, 'data.data');
          props.toggleModal(
            true,
            <HabitsBuilderPopup prevHabit={data} isEdit onClose={onClose} autoflowType={AUTOFLOW_TYPES.EXACT_DATE} />,
          );
        });
    }
  }, [habitId]);

  const onClose = () => {
    props.toggleModal(false);
    props.replace(`/home/autoflow/${props.autoflow}/task/calendar`);
  };

  const toggleMoveRepeat = () => {
    setConfirm(!isConfirm);
  };
  const onDragEnd = result => {
    const { source, destination } = result;
    const { autoflow, moveTask, arrangeTask } = props;
    if (!autoflow || !source || !destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const [newDay] = destination.droppableId.split(';');
    const [, dayTaskId] = source.droppableId.split(';');
    const taskId = result.draggableId.split(';')[0];

    if (destination && source.droppableId !== destination.droppableId) {
      const newIndex = parseInt(destination.index);
      const params = { newIndex, autoflow, newDay, taskId, dayTaskId };
      moveTask(params);
    } else {
      const newIndex = parseInt(destination.index);
      const params = { newIndex, autoflow, newDay, taskId, dayTaskId };
      arrangeTask(params);
    }
  };

  const handleConfirmMove = () => {
    onDragEnd(selectedTask);
    toggleMoveRepeat();
  };

  const onMoveItem = item => {
    const { destination, source } = item;
    // Check task repeating or normal
    const isRepeating = item.draggableId.indexOf('isRepeating') >= 0 && props.repeatTaskAutoFlowPermission;
    if (isRepeating && destination.droppableId !== source.droppableId) {
      toggleMoveRepeat();
      setSelectedTask(item);
    } else {
      onDragEnd(item);
    }
  };

  return (
    <CalendarLayout {...props} onDragEnd={onMoveItem}>
      {isConfirm && (
        <RepeatTaskConfirm
          type={CONFIRM_TYPES.MOVE}
          title="Move a Repeating Task"
          message={`You are moving a task that is in a repeating sequence. \n\n This change will detach the task from the original sequence.`}
          onClose={toggleMoveRepeat}
          onConfirm={handleConfirmMove}
        />
      )}
    </CalendarLayout>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { autoflow, permission } = rootReducer;
  const { copying } = autoflow.task;
  const { viewMode, startDate, workingAutoflow } = autoflow.common;
  const { features } = autoflow.settings;
  const taskSetting = features.find(item => item.type === 'task');
  const featureTurnOff = taskSetting ? !taskSetting.state : false;

  return {
    viewMode,
    startDate,
    copying,
    featureTurnOff,
    name: 'Tasks',
    autoflow: get(workingAutoflow, '_id'),
    repeatTaskAutoFlowPermission: permission.repeat_task_autoflow,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDidMount: bindActionCreators(getListTasks, dispatch),
  onWillUnmount: bindActionCreators(resetData, dispatch),
  changeViewMode: bindActionCreators(changeViewMode, dispatch),
  changeStartDate: bindActionCreators(changeStartDate, dispatch),
  resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
  arrangeTask: bindActionCreators(arrangeTask, dispatch),
  moveTask: bindActionCreators(moveTask, dispatch),
  replace: bindActionCreators(replace, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getDetailAutoflowHabit: bindActionCreators(getDetailAutoflowHabit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskCalendarLayout);
