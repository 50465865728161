import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createTrainerProfile, completeTrainerRegistration, signUpByGoogle } from 'actions/auth/register';
import { verifyTeam } from 'actions/client';
import { toggleModal } from 'actions/modal';
import RegisterComponent from 'components/Register/component';

const mapStateToProps = state => {
  const { isModalOpen, modal, workspace = {} } = state;
  return {
    isModalOpen,
    modal,
    workspace,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleVerifyTeam: bindActionCreators(verifyTeam, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    createTrainerProfile: bindActionCreators(createTrainerProfile, dispatch),
    completeTrainerRegistration: bindActionCreators(completeTrainerRegistration, dispatch),
    signUpByGoogle: bindActionCreators(signUpByGoogle, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
