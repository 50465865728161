import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import {
  toggleFullscreenModal,
  toggleFilterModal,
  toggleDetailModal,
  toggleInstructionModal,
  toggleTagsModal,
  toggleDescriptionModal,
  getProgramTemplate,
  changeQueryParams,
  saveAsProgramLibrary,
  getProgramWeekDetail,
  resetQueryPopupFilter,
} from 'redux/program-library-and-templates/actions';

import ProgramTemplatesContext from './context/ProgramTemplatesContext';
import FullscreenModal from './components/FullscreenModal';
import FilterModal from './components/FilterModal';
import DetailModal from './components/DetailModal';
import InstructionModal from './components/InstructionModal';
import TagsModal from './components/TagsModal';
import DescModal from './components/DescModal';

import { DEFAULT_FILTERS_POPUP } from './constants';

const ProgramTemplatesFullscreenModal = props => {
  const {
    templates = {},
    toggleFullscreenModal,
    toggleFilterModal,
    toggleDetailModal,
    toggleInstructionModal,
    toggleTagsModal,
    toggleDescriptionModal,
    getProgramTemplate,
    loading = false,
    cloudfrontList,
    templateProgram = {},
    changeQueryParams,
    queryTemplates,
    saveAsProgramLibrary,
    getProgramWeekDetail,
    programWeekDetail,
    fields,
    userPreferences,
    resetQueryPopupFilter,
    forceOpenFullscreenModal = false,
  } = props;
  const {
    openFullscreenModal = false,
    openFilterModal = false,
    openDetailModal = false,
    openInstructionModal = false,
    openTagsModal = false,
    openDescriptionModal = false,
  } = templates;

  const unit = userPreferences;

  const [showSortPopup, setShowSortPopup] = useState(false);
  const [showExperiencePopup, setShowExperiencePopup] = useState(false);
  const [showDurationPopup, setShowDurationPopup] = useState(false);
  const [detailModalItem, setDetailModalItem] = useState({});
  const [currentFilters, hideResetButton] = useMemo(() => {
    const queryFilters = pick(queryTemplates, Object.keys(DEFAULT_FILTERS_POPUP));
    const isDefault = isEqual(queryFilters, DEFAULT_FILTERS_POPUP);

    return [queryFilters, isDefault];
  }, [queryTemplates]);

  const contextValue = {
    openFullscreenModal: forceOpenFullscreenModal || openFullscreenModal,
    openFilterModal,
    openDetailModal,
    openInstructionModal,
    openTagsModal,
    openDescriptionModal,
    toggleFullscreenModal,
    toggleFilterModal,
    toggleDetailModal,
    toggleInstructionModal,
    toggleTagsModal,
    toggleDescriptionModal,
    getProgramTemplate,
    templateProgram,
    loading,
    setDetailModalItem,
    detailModalItem,
    showSortPopup,
    setShowSortPopup,
    showExperiencePopup,
    setShowExperiencePopup,
    showDurationPopup,
    setShowDurationPopup,
    cloudfrontList,
    changeQueryParams,
    queryTemplates,
    saveAsProgramLibrary,
    getProgramWeekDetail,
    programWeekDetail,
    unit,
    fields,
    currentFilters,
    hideResetButton,
    resetQueryPopupFilter,
  };

  return (
    <ProgramTemplatesContext.Provider value={contextValue}>
      <FullscreenModal />
      <FilterModal />
      <DetailModal />
      <InstructionModal />
      <TagsModal />
      <DescModal />
    </ProgramTemplatesContext.Provider>
  );
};

const mapStateToProps = state => ({
  templates: state.rootReducer.programLibraryAndTemplates.templates,
  loading: state.rootReducer.programLibraryAndTemplates.templateProgram.loading,
  cloudfrontList: state.cloudfrontList,
  templateProgram: state.rootReducer.programLibraryAndTemplates.templateProgram,
  queryTemplates: state.rootReducer.programLibraryAndTemplates.query_templates,
  programWeekDetail: state.rootReducer.programLibraryAndTemplates.programWeekDetail,
  workingClientDetail: state.rootReducer.client.workingClientDetail,
  userPreferences: state.user.preferences,
  fields: state.rootReducer.exercise.fields,
});

const mapDispatchToProps = {
  toggleFullscreenModal,
  toggleFilterModal,
  toggleDetailModal,
  toggleInstructionModal,
  toggleTagsModal,
  toggleDescriptionModal,
  getProgramTemplate,
  changeQueryParams,
  saveAsProgramLibrary,
  getProgramWeekDetail,
  resetQueryPopupFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramTemplatesFullscreenModal);
