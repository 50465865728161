import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { PromoCostWrapper } from './style';
import { bindActionCreators } from 'redux';
import { checkPromoCode, removePromoCode } from 'redux/pricing/actions';

function PrommoCode(props) {
  const { selectPackage, promoAmount, onlyZapier } = props;
  const promoCode = selectPackage.promoCode || {};

  if (!promoCode.valid || promoAmount < 0) {
    return null;
  }

  const promoCodeAddOns = _.get(promoCode, 'metadata.add_ons');

  if (!promoCodeAddOns && promoAmount === 0) {
    return null;
  }

  const removePromoCode = () => {
    props.removePromoCode();
  };

  return (
    <PromoCostWrapper>
      <div className="promoCost__code">
        Promo - {promoCode.id}
        {onlyZapier ? null : <div onClick={removePromoCode} className="promoCost__removeIcon" />}
      </div>
      <div className="promoCost__value">- {Number(promoAmount).toUSDFormat(false)}</div>
    </PromoCostWrapper>
  );
}

const mapState = state => {
  const { rootReducer } = state;

  return {
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkPromoCode: bindActionCreators(checkPromoCode, dispatch),
    removePromoCode: bindActionCreators(removePromoCode, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(PrommoCode);
