import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import ReactTooltip from 'react-tooltip';
import { get } from 'lodash';
import { toast } from 'react-toastify';
// import icons
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip-icon.svg';
import MyFitnessPalStyles, { MFPTooltipGlobalStyle } from './styles';
import { axiosInstance } from 'configs/request';

const MyFitnessPal = ({
  date,
  viewBy,
  data,
  clientId,
  myfitnessPalLastSynced,
  setMyfitnessPalLastSynced,
  MFPStatus,
}) => {
  const [connectedCronometer, setConnectedCronometer] = useState(false);
  let currentData = null;
  if (
    data &&
    ((data.type === 'week' && viewBy === 'weekly') || (data.type === 'day' && viewBy === 'daily')) &&
    date.format('MM-DD-YYYY') === data.day
  ) {
    currentData = data;
  }

  useEffect(() => {
    if (currentData && currentData.status === 'synced') {
      setMyfitnessPalLastSynced(currentData.last_sync);
    }
  }, [currentData]);

  useEffect(() => {
    axiosInstance
      .get('api/integration-source/connected-app', { params: { client: clientId } })
      .then(res => {
        setConnectedCronometer(get(res, 'data.data.source', '') === 'CRONOMETER');
      })
      .catch(err => toast.error(get(err, 'response.data.message', '')));
  }, []);

  return (
    <MyFitnessPalStyles>
      <div className="sync-section">
        {currentData && currentData.status === 'syncing' && (
          <>
            <div className="loading-icon">
              <LoadingIcon />
            </div>
            <div className="text">
              Syncing with <span>{connectedCronometer ? 'Cronometer' : 'MyFitnessPal'} ...</span>
            </div>
          </>
        )}
        {(!currentData || currentData.status !== 'syncing') && myfitnessPalLastSynced && (
          <>
            <div className="text">
              <span>Synced: </span>
              {DateTime.fromMillis(myfitnessPalLastSynced).toFormat('LLL dd, yyyy - hh:mma')}
            </div>
          </>
        )}
      </div>
      {connectedCronometer && <div className="connected-text">Connected to Cronometer</div>}
      {MFPStatus.status && (
        <div className="myfitnesspal-link">
          <a href={`https://www.myfitnesspal.com/food/diary/${MFPStatus.sourceId}`} target="_blank">
            Go to client’s MyFitnessPal
          </a>

          <div className="myfitnesspal-link-tooltip" data-for="myfitnesspal-link" data-tip="myfitnesspal-link-tooltip">
            <TooltipIcon />
          </div>
          <ReactTooltip id="myfitnesspal-link" effect="solid" place={'top'}>
            <div>Your client’s MyFitnessPal Food Diary settings should be set to “Public” to allow syncing</div>
          </ReactTooltip>
        </div>
      )}
      <MFPTooltipGlobalStyle />
    </MyFitnessPalStyles>
  );
};

export default connect((state, { clientId }) => ({
  data: state.rootReducer.macros.listMyFitnessPal[clientId],
  clientId,
}))(MyFitnessPal);
