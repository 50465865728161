// Libs
import React, { useEffect, useState } from 'react';
import union from 'lodash/union';
import difference from 'lodash/difference';

// Shared
import { Checkbox } from 'shared/FormControl';

// Constants
import { MOST_RECENT_KEY, TABLE_COLUMNS } from 'components/IngredientLibrary/constants';

// Assets
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';

// Styles
import * as S from './style';
import { isEmpty } from 'lodash';

const IngredientTableHeader = props => {
  const { filters, total, selectedIngredient, selectedIngredients, ingredients, userId = '', loading } = props;
  const { sorter, sort } = filters || {};
  const ingredientIds = !isEmpty(ingredients)
    ? ingredients
        .filter(({ type = '', author = '' }) => type === 'custom' && author._id === userId)
        .map(({ _id = '' }) => _id)
    : [];
  const isSelectedAll =
    !isEmpty(selectedIngredients) &&
    selectedIngredients.length &&
    difference(ingredientIds, selectedIngredients).length === 0;
  const isHasSelectedPrev = !isEmpty(selectedIngredients) && selectedIngredients.length > 0;

  const [currentSorter, setCurrentSorter] = useState(sorter);

  useEffect(() => {
    setCurrentSorter(sorter);
  }, [sorter]);

  const onSort = key => {
    if (!key) return;
    setCurrentSorter(key);
    props.changeQueryParams({
      sorter: key,
      sort: key === sorter ? sort * -1 : key === MOST_RECENT_KEY ? -1 : 1,
      page: 1,
    });
  };

  const renderSortIcon = key => {
    if (key !== sorter) {
      return <ArrowDown className="column-symbol arrow" />;
    }

    let arrow = '';

    if (sorter === MOST_RECENT_KEY) {
      arrow = sort === 1 ? 'up' : 'down';
    } else {
      arrow = sort === -1 ? 'up' : 'down';
    }

    return arrow === 'up' ? (
      <ArrowUp className="column-symbol arrow" active />
    ) : (
      <ArrowDown className="column-symbol arrow" active />
    );
  };

  const handleSelectAllIngredient = e => {
    if (e.target && e.target.checked) {
      typeof selectedIngredient === 'function' && selectedIngredient(union([...selectedIngredients, ...ingredientIds]));
    } else {
      typeof selectedIngredient === 'function' &&
        selectedIngredient(selectedIngredients.filter(id => !ingredientIds.includes(id)));
    }
  };

  return (
    <S.TableHeader>
      <S.TableRow>
        <S.TableHeaderCell>
          <S.TableHeaderCellContent>
            <S.TableCell>
              <S.CheckboxContainer isHasSelectedPrev={isHasSelectedPrev}>
                <div className="checkbox__header">
                  <Checkbox checked={isSelectedAll} onChange={handleSelectAllIngredient} />
                </div>
              </S.CheckboxContainer>
            </S.TableCell>
          </S.TableHeaderCellContent>
        </S.TableHeaderCell>
        <S.TableHeaderCell>
          <S.TableHeaderCellContent onClick={() => onSort('name')} active={currentSorter === 'name'}>
            <S.TableCell>
              {loading ? (
                <div className="name-skeleton"></div>
              ) : (
                <>
                  <S.CellTitle>Ingredients ({total})</S.CellTitle>
                  {renderSortIcon('name')}
                </>
              )}
            </S.TableCell>
          </S.TableHeaderCellContent>
        </S.TableHeaderCell>
        {TABLE_COLUMNS.map(item => {
          const { key, alignCenter, name } = item;
          return (
            <S.TableHeaderCell key={key} alignCenter={alignCenter} onClick={() => onSort(key)}>
              <S.TableHeaderCellContent active={currentSorter === key}>
                <S.TableCell>
                  <S.CellTitle>{name}</S.CellTitle>
                  {renderSortIcon(key)}
                </S.TableCell>
              </S.TableHeaderCellContent>
            </S.TableHeaderCell>
          );
        })}
        <S.TableHeaderCell key="actions" className="actions" />
      </S.TableRow>
    </S.TableHeader>
  );
};

export default IngredientTableHeader;
