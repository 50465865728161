import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  .search-exercise-container {
    flex: 1 1;
  }

  .section__header__actions__group {
    display: flex;
    align-items: center;

    .section__header__onboarding {
      position: relative;
      margin-left: -5px;
      margin-top: 6px;
    }
  }

  .evf-dropdown {
    margin-left: 10px;

    .evf-dropdown__option {
      padding: 0;
      width: 100%;
    }

    .evf-dropdown__menu {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border: 1px solid #dadfea;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .evf-dropdown__trigger {
      width: 24px;
      height: 24px;

      .dots {
        background-color: #758094;
      }

      :hover {
        background-color: #eaebff;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #eaebff;
      }
    }
  }

  ${props =>
    !props.newUIPopup &&
    css`
      .alt-exs {
        position: absolute;
        z-index: 999;
        right: -40px;
        top: 38px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-color: #d4d7d9;

        :before {
          content: '';
          position: absolute;
          top: -5px;
          right: 45px;
          box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3) !important;
          width: 10px;
          height: 10px;
          display: block;
          transform: rotate(45deg);
          background: #fff;
          border-color: #e0e0e8 transparent transparent #e0e0e8 !important;
          border-style: solid !important;
          border-width: 1px !important;
        }
      }
    `};

  .media-preview {
    margin-right: 10px;
  }

  .exHistory__trigger {
    margin-left: 10px;
  }
`;

export const CancelButton = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #5158cf;
  padding-left: 15px;
  cursor: pointer;
`;
