import React from 'react';

export const unlikeIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 15.5H0.5V8.5H3.5"
      stroke="#626262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 8.5L6.5 0.5H7.5C8.052 0.5 8.5 0.948 8.5 1.5V6.5H13.499C14.822 6.5 15.78 7.761 15.426 9.035L14.037 14.035C13.797 14.901 13.008 15.5 12.11 15.5H3.5V8.5Z"
      stroke="#626262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const likeIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M13.3 6H9V2C9 0.5 8.2 0 7 0C6.7 0 6.4 0.2 6.4 0.5C6.4 0.5 4.1 8 4 8V16H12.6C13.9 16 15 15 15.2 13.7L16 9.1C16.1 8.3 15.9 7.5 15.4 7C14.9 6.3 14.1 6 13.3 6Z"
        fill="#494699"
      />
      <path d="M2 8H0V16H2V8Z" fill="#494699" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const commentIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 0.5H1.5C0.948 0.5 0.5 0.948 0.5 1.5V11.5C0.5 12.052 0.948 12.5 1.5 12.5H5.5L8 15.5L10.5 12.5H14.5C15.052 12.5 15.5 12.052 15.5 11.5V1.5C15.5 0.948 15.052 0.5 14.5 0.5Z"
      stroke="#626262"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const clockIcon = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.375 11.625C9.2745 11.625 11.625 9.2745 11.625 6.375C11.625 3.47551 9.2745 1.125 6.375 1.125C3.47551 1.125 1.125 3.47551 1.125 6.375C1.125 9.2745 3.47551 11.625 6.375 11.625Z"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 3.375V6.375H9.375"
      stroke="black"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const deleteIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.72217 5.83301V11.2775C2.72217 12.1369 3.41828 12.833 4.27772 12.833H9.72217C10.5816 12.833 11.2777 12.1369 11.2777 11.2775V5.83301"
      stroke="#A1A1A5"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.16663 3.5H12.8333"
      stroke="#A1A1A5"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.05554 3.49984V1.1665H8.94443V3.49984"
      stroke="#A1A1A5"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const cameraIcon = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-tip
    data-for="chat-input-has-text-tooltip"
  >
    <rect width="26" height="26" rx="4" fill="#F5F5F6" />
    <path
      d="M18.25 10H16.375L15.1 8.05C14.95 7.9 14.725 7.75 14.5 7.75H11.5C11.275 7.75 11.05 7.9 10.9 8.05L9.625 10H7.75C7.3 10 7 10.3 7 10.75V17.5C7 17.95 7.3 18.25 7.75 18.25H18.25C18.7 18.25 19 17.95 19 17.5V10.75C19 10.3 18.7 10 18.25 10ZM13 16C11.725 16 10.75 15.025 10.75 13.75C10.75 12.475 11.725 11.5 13 11.5C14.275 11.5 15.25 12.475 15.25 13.75C15.25 15.025 14.275 16 13 16Z"
      fill="#95A6B7"
    />
  </svg>
);

export const timed = (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 9.81824V14.1819H14.4444" stroke="#BFC5CD" strokeMiterlimit="10" strokeLinecap="square" />
    <path
      d="M9.99997 22.9091C14.9092 22.9091 18.8889 19.0018 18.8889 14.1819C18.8889 9.36192 14.9092 5.45459 9.99997 5.45459C5.09078 5.45459 1.11108 9.36192 1.11108 14.1819C1.11108 19.0018 5.09078 22.9091 9.99997 22.9091Z"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M7.03711 1.09094H12.963" stroke="#BFC5CD" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M10 1.09094V3.27276" stroke="#BFC5CD" strokeMiterlimit="10" />
    <path
      d="M17.4075 5.45459L18.8889 6.90914"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const strength = (
  <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 7.5H16.5" stroke="#BFC5CD" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M2.5 7.5H0.5" stroke="#BFC5CD" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M22.5 7.5H20.5" stroke="#BFC5CD" strokeMiterlimit="10" strokeLinecap="square" />
    <path
      d="M6.5 2.5C6.5 1.39543 5.60457 0.5 4.5 0.5C3.39543 0.5 2.5 1.39543 2.5 2.5V12.5C2.5 13.6046 3.39543 14.5 4.5 14.5C5.60457 14.5 6.5 13.6046 6.5 12.5V2.5Z"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16.5 12.5C16.5 13.6046 17.3954 14.5 18.5 14.5C19.6046 14.5 20.5 13.6046 20.5 12.5V2.5C20.5 1.39543 19.6046 0.5 18.5 0.5C17.3954 0.5 16.5 1.39543 16.5 2.5V12.5Z"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export const distance = (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9999 5.29199L17.4759 12.91L21.0889 15.582C21.4224 15.7918 21.7112 16.0653 21.9389 16.3868C22.1667 16.7083 22.3289 17.0714 22.4162 17.4556C22.5036 17.8398 22.5145 18.2374 22.4482 18.6257C22.3819 19.0141 22.2398 19.3856 22.0299 19.719L21.4999 20.565C21.2173 21.0139 20.768 21.3321 20.2508 21.4497C19.7336 21.5673 19.1908 21.4746 18.7419 21.192L2.43292 10.531C1.98531 10.2473 1.66813 9.79804 1.55065 9.28129C1.43317 8.76454 1.52495 8.2223 1.80592 7.77299L5.62392 1.70599C6.02992 1.06099 7.13192 1.02399 7.34392 1.75599C7.57105 2.48697 7.94887 3.16222 8.45302 3.7382C8.95717 4.31417 9.57645 4.77808 10.2709 5.09999C11.7799 5.69843 13.4474 5.76613 14.9999 5.29199V5.29199Z"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M0.5 21.5H10.5" stroke="#BFC5CD" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M0.5 16.5H3.5" stroke="#BFC5CD" strokeMiterlimit="10" strokeLinecap="square" />
  </svg>
);

export const bodyweight = (
  <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 4.5C11.6046 4.5 12.5 3.60457 12.5 2.5C12.5 1.39543 11.6046 0.5 10.5 0.5C9.39543 0.5 8.5 1.39543 8.5 2.5C8.5 3.60457 9.39543 4.5 10.5 4.5Z"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M1.5 9.5L7.346 7.551C7.44791 7.51714 7.55461 7.49992 7.662 7.5H13.338C13.4454 7.49992 13.5521 7.51714 13.654 7.551L19.5 9.5"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M7.5 7.51404V18.5" stroke="#BFC5CD" strokeMiterlimit="10" />
    <path d="M13.5 7.51294V22.4999" stroke="#BFC5CD" strokeMiterlimit="10" />
    <path
      d="M1.5 13.5C1.5 15.8869 2.44821 18.1761 4.13604 19.864C5.82387 21.5518 8.11305 22.5 10.5 22.5"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16.5 20.194C17.4437 19.3527 18.1988 18.3215 18.7158 17.1678C19.2328 16.0142 19.5001 14.7642 19.5 13.5"
      stroke="#BFC5CD"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
