// Lib
import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { RootCloseWrapper } from 'react-overlays';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

// Actions
import { INITIAL_STATE } from 'redux/ingredient-library/reducers';

// Components
import { NewSearchInput } from 'shared/SearchInput';
import FilterCategoriesPopup from 'components/RecipeDetail/AddIngredient/components/FilterCategoriesPopup';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down_normal.svg';
import { ReactComponent as AddMultipleIcon } from 'assets/icons/add_new_ingredient.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/MealPlans/meal_plan_filter_icon.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/Ingredients/reload.svg';

// Style
import { FilterButton, FilterNumber } from 'components/Recipes/Parts/FilterPopup/style';
import * as S from './style';

const ToolBar = props => {
  const {
    onChangeQueryParams,
    onAddNewIngredient,
    onAddMultiIngredient,
    onRemoveMultipleIngredient,
    selectedIngredients,
    isLoading,
    filters,
    ingredientCategory,
    resetSelectedIngredient,
  } = props;
  const { categories = [] } = filters || {};
  const [openAddNew, setOpenAddNew] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterNumber, setFilterNumber] = useState(0);

  const handleSearch = (event, { value }) => {
    const queryParams = {
      text_search: value.trim(),
      page: 1,
      sort: filters.sort || -1,
      sorter: filters.sorter || 'last_interacted',
    };

    if (value) {
      queryParams.sort = undefined;
      queryParams.sorter = undefined;
    }

    onChangeQueryParams(queryParams);
  };

  const handleClearSearch = () => {
    onChangeQueryParams({
      text_search: '',
      page: 1,
      sort: filters.sort ? filters.sort : -1,
      sorter: filters.sorter ? filters.sorter : 'last_interacted',
    });
  };

  const toggleAddNew = () => {
    setOpenAddNew(!openAddNew);
  };

  const handleAddNewIngredient = () => {
    typeof onAddNewIngredient === 'function' && onAddNewIngredient();
  };

  const handleAddMultiIngredient = () => {
    typeof onAddMultiIngredient === 'function' && onAddMultiIngredient();
  };
  const handleChangeNumberFilter = number => {
    setFilterNumber(number);
  };

  const handleSelectedFilterApply = filters => {
    const ids = JSON.stringify(get(filters, 'categories', []));
    setAppliedFilter(filters);
    typeof onChangeQueryParams === 'function' && onChangeQueryParams({ categories: ids, page: 1 });
  };

  const onResetFilter = () => {
    setFilterNumber(0);
    setAppliedFilter({});
    typeof resetSelectedIngredient === 'function' && resetSelectedIngredient();
    typeof onChangeQueryParams === 'function' && onChangeQueryParams({ categories: [] });
  };

  const renderTrigger = open => {
    return (
      <FilterButton className="filter-button" active={open}>
        <FilterIcon className="filter-icon" />
        <span className="filter-label">Filter</span>
        {filterNumber > 0 && <FilterNumber className="filter-number">{filterNumber}</FilterNumber>}
      </FilterButton>
    );
  };

  return (
    <S.ToolbarWrapper>
      <S.LeftToolbar>
        <S.SearchWrapper>
          <NewSearchInput
            onChange={debounce(handleSearch, 300)}
            placeholder="Search ingredient name"
            onClearSearch={handleClearSearch}
            className="ingredients-search"
          />
        </S.SearchWrapper>
        <S.FilterWrapper>
          <FilterCategoriesPopup
            onChangeNumberFilter={handleChangeNumberFilter}
            onSelectedFilterApply={handleSelectedFilterApply}
            allCategory={ingredientCategory}
            appliedFilter={appliedFilter}
            trigger={renderTrigger}
            classNameCustom="custom-filter-popup"
          />
        </S.FilterWrapper>
        <S.ResetFilter
          onClick={onResetFilter}
          hide={isEqual(typeof categories === 'string' ? JSON.parse(categories) : categories, [])}
        >
          <ReloadIcon />
          Reset
        </S.ResetFilter>
        {selectedIngredients.length > 0 && (
          <S.RemoveButton onClick={onRemoveMultipleIngredient} disabled={isLoading}>
            <RemoveIcon />
            Remove
          </S.RemoveButton>
        )}
      </S.LeftToolbar>
      <S.RightToolbar>
        <S.AddNewWrapper>
          <S.AddNewButton onClick={handleAddNewIngredient}>
            <PlusIcon className="plus-icon" />
            <span>Add New Ingredient</span>
          </S.AddNewButton>
          <S.AddNewDropdown onClick={toggleAddNew} active={openAddNew}>
            <ArrowIcon className="arrow-icon" />
            {openAddNew && (
              <RootCloseWrapper event="click" onRootClose={() => setOpenAddNew(false)} disabled={!openAddNew}>
                <S.AddNewPopup>
                  <S.Option onClick={handleAddNewIngredient}>
                    <PlusIcon className="add-single-icon" />
                    <span>Add Single Ingredient</span>
                  </S.Option>
                  <S.Option onClick={handleAddMultiIngredient}>
                    <AddMultipleIcon className="add-multiple-icon" /> <span>Add Multiple Ingredients</span>
                  </S.Option>
                </S.AddNewPopup>
              </RootCloseWrapper>
            )}
          </S.AddNewDropdown>
        </S.AddNewWrapper>
      </S.RightToolbar>
    </S.ToolbarWrapper>
  );
};

export default ToolBar;
