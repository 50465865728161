import React, { useState } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import _ from 'lodash';
import { Image, Dropdown } from 'semantic-ui-react';

import { DateInput } from 'shared/FormControl';

import { Button } from 'shared/FormControl';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { connect } from 'react-redux';

function ChooseStartDate(props) {
  const [startDate, setStartDate] = useState(moment());
  const [startOn, setStartOn] = useState(1);
  const days = _.range(1, (props.workingAutoflow.interval_weeks || []).length * 7 + 1).map(d => {
    return {
      key: d,
      text: `Day ${d}`,
      value: d,
    };
  });

  const handleConfirm = () => {
    props.onConfirm({
      started_on: startOn,
      started_date: startDate.format('MM-DD-YYYY'),
    });
  };

  return (
    <S.Wrapper>
      <S.Heading>
        <span className="choose-start-date__title">Choose Start Date</span>
        <div className="choose-start-date__description">
          Choose which date and day of the Autoflow the selected clients should start the Autoflow on.
        </div>
      </S.Heading>
      <S.Content>
        <div className="choose-start-date__form-item">
          <div className="choose-start-date__form-label">Start Date</div>
          <Datetime
            value={startDate}
            renderInput={props => {
              return <DateInput {...props} text={startDate.format('MMM D, YYYY')} />;
            }}
            timeFormat={false}
            closeOnSelect={true}
            onChange={newDate => {
              setStartDate(newDate);
            }}
            className="new-ui"
            isValidDate={currentDate => currentDate.isSameOrAfter(moment(), 'day')}
          />
        </div>
        <div className="choose-start-date__form-item">
          <div className="choose-start-date__form-label">Start on</div>
          <Dropdown
            value={startOn}
            selection
            selectOnBlur={false}
            selectOnNavigation={false}
            options={days}
            onChange={(event, { value }) => {
              if (value !== startOn) {
                setStartOn(value);
              }
            }}
            icon={<Image src={`${CDN_URL}/images/arrow_down_black.svg`} className="dropdown-icon" />}
            className="new-ui"
          />
        </div>
      </S.Content>
      <S.Footer>
        <Button purple onClick={handleConfirm} disabled={props.isSubmitting}>
          Confirm
        </Button>
      </S.Footer>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  return {
    workingAutoflow,
  };
};

export default connect(mapStateToProps, {})(ChooseStartDate);
