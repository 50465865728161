import React from 'react';
import Request from 'configs/request';
import SectionDetailModal from 'components/SectionDetailModal';
import { Workout } from 'types/model';
import { toggleModal } from 'actions/modal';
import {
  WORKOUT_BUILDER_TYPES,
  SECTION_FORMAT_KEY,
  TEAM_SHARE_PRIVATE,
  TEAM_SHARE_NOOWNER,
  MEDIA_PLACEHOLDER,
} from 'constants/commonData';
import { push } from 'connected-react-router';
import { isEmpty, omit, get, cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

export const Types = {
  GET_SECTION_LIBRARY_REQUEST: 'GET_SECTION_LIBRARY_REQUEST',
  GET_SECTION_LIBRARY_SUCCESS: 'GET_SECTION_LIBRARY_SUCCESS',
  GET_SECTION_LIBRARY_FAILED: 'GET_SECTION_LIBRARY_FAILED',
  SECTION_LIBRARY_CHANGE_QUERY_PARAMS: 'SECTION_LIBRARY_CHANGE_QUERY_PARAMS',
  SECTION_LIBRARY_RESET_QUERY_PARAMS: 'SECTION_LIBRARY_RESET_QUERY_PARAMS',
  SECTION_LIBRARY_DUPLICATE_REQUEST: 'SECTION_LIBRARY_DUPLICATE_REQUEST',
  SECTION_LIBRARY_DUPLICATE_SUCCESS: 'SECTION_LIBRARY_DUPLICATE_SUCCESS',
  SECTION_LIBRARY_DUPLICATE_FAILED: 'SECTION_LIBRARY_DUPLICATE_FAILED',
  SECTION_LIBRARY_DELETE_SINGLE_REQUEST: 'SECTION_LIBRARY_DELETE_SINGLE_REQUEST',
  SECTION_LIBRARY_DELETE_SINGLE_SUCCESS: 'SECTION_LIBRARY_DELETE_SINGLE_SUCCESS',
  SECTION_LIBRARY_DELETE_SINGLE_FAILED: 'SECTION_LIBRARY_DELETE_SINGLE_FAILED',
  SECTION_LIBRARY_BULK_DELETE_REQUEST: 'SECTION_LIBRARY_BULK_DELETE_REQUEST',
  SECTION_LIBRARY_BULK_DELETE_SUCCESS: 'SECTION_LIBRARY_BULK_DELETE_SUCCESS',
  SECTION_LIBRARY_BULK_DELETE_FAILED: 'SECTION_LIBRARY_BULK_DELETE_FAILED',
  SECTION_LIBRARY_UPDATE_SELECTED_SECTION: 'SECTION_LIBRARY_UPDATE_SELECTED_SECTION',
  SECTION_LIBRARY_UPDATE_SHARING_STATUS: 'SECTION_LIBRARY_UPDATE_SHARING_STATUS',
  SECTION_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS: 'SECTION_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS',
  SECTION_LIBRARY_FAILED_UPDATE_SHARING_STATUS: 'SECTION_LIBRARY_FAILED_UPDATE_SHARING_STATUS',
  SECTION_LIBRARY_GET_DETAILS_REQUEST: 'SECTION_LIBRARY_GET_DETAILS_REQUEST',
  SECTION_LIBRARY_GET_DETAILS_SUCCESS: 'SECTION_LIBRARY_GET_DETAILS_SUCCESS',
  SECTION_LIBRARY_GET_DETAILS_FAILED: 'SECTION_LIBRARY_GET_DETAILS_FAILED',
};

export const getSections = (params, isLoading = true) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        sectionLibrary: { query },
      },
    } = getState();
    let queryParams;

    if (params) {
      queryParams = { ...query, ...params };
    } else {
      queryParams = query;
    }

    const requestParams = isEmpty(queryParams.formats) ? omit(queryParams, 'formats') : queryParams;

    if (isLoading) {
      dispatch({ type: Types.GET_SECTION_LIBRARY_REQUEST });
    }

    return dispatch(
      Request.post(
        { url: '/api/training-section-library/get-list', data: requestParams },
        false,
        response => {
          const {
            data: { data },
          } = response;

          dispatch({ type: Types.GET_SECTION_LIBRARY_SUCCESS, payload: { data } });
          const currentPage = queryParams.page;
          if (!data.list.length && currentPage > 1) {
            dispatch(changeQueryParams({ page: currentPage - 1 }));
          }
        },
        error => dispatch({ type: Types.GET_SECTION_LIBRARY_FAILED, payload: error }),
      ),
    );
  };
};

export const openSectionWorkoutDetail = sectionId => {
  return dispatch => {
    return dispatch(
      Request.get({ url: `/api/training-section-library/${sectionId}` }, false, response => {
        const { share, team, title, user } = response.data.data;

        const woData = {
          sections: [
            {
              ...omit(response.data.data, ['share', 'share', 'author']),
            },
          ],
          author: user,
          share,
          team,
          title,
          _id: sectionId,
        };

        const workout = new Workout();
        const parseData = workout.parseFromWorkout(woData);
        const workingWorkout = cloneDeep(parseData);

        dispatch(
          toggleModal(
            true,
            <SectionDetailModal
              key={`section-detail__${workingWorkout._id}`}
              workingWorkout={workingWorkout}
              pdfType="workout"
              onSave={async (workoutData, saveAsCopy) => {
                if (saveAsCopy) {
                  return dispatch(addSectionLibrary(workoutData, true));
                }
                try {
                  const response = await dispatch(updateSectionLibrary(workoutData));
                  return response.data.data;
                } catch (err) {
                  return Promise.resolve(null);
                }
              }}
              type={WORKOUT_BUILDER_TYPES.TEMPLATE}
              onClose={() => dispatch(push('/home/sections'))}
              onDelete={workout => dispatch(removeSectionLibrary(workout._id))}
            />,
          ),
        );
      }),
    );
  };
};

export const changeQueryParams = (data, isLoading = true) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        sectionLibrary: { query },
      },
    } = getState();
    const newData = { ...query, ...data };

    dispatch({
      type: Types.SECTION_LIBRARY_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });

    dispatch(getSections(newData, isLoading));
  };
};

export const resetQueryParams = () => ({ type: Types.SECTION_LIBRARY_RESET_QUERY_PARAMS });

export const duplicateSectionLibrary = sectionId => {
  return dispatch => {
    return dispatch(
      Request.get({ url: `/api/training-section-library/${sectionId}` }, false, response => {
        const { team, owner } = response.data.data;

        const woData = {
          sections: [
            {
              ...omit(response.data.data, ['share', 'share', 'author']),
            },
          ],
          author: '',
          owner,
          share: TEAM_SHARE_PRIVATE,
          team,
          title: '',
          _id: '',
        };

        const workout = new Workout();
        const parseData = workout.parseFromWorkout(woData);
        const workingWorkout = cloneDeep(parseData);

        dispatch(
          toggleModal(
            true,
            <SectionDetailModal
              key={`section-duplicate__${workingWorkout._id}`}
              workingWorkout={workingWorkout}
              pdfType="workout"
              onSave={async workoutData => {
                try {
                  const response = await dispatch(addSectionLibrary(workoutData));
                  return response.data.data;
                } catch (err) {
                  return Promise.resolve(null);
                }
              }}
              type={WORKOUT_BUILDER_TYPES.TEMPLATE}
              onClose={() => dispatch(push('/home/sections'))}
              onDelete={workout => dispatch(removeSectionLibrary(workout._id))}
            />,
          ),
        );
      }),
    );
  };
};

export const removeSectionLibrary = (id, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        sectionLibrary: { list, query },
      },
    } = getState();

    dispatch({ type: Types.SECTION_LIBRARY_DELETE_SINGLE_REQUEST });
    return dispatch(
      Request.delete(
        { url: `/api/training-section-library/delete-training-section-library/${id}` },
        false,
        response => {
          const { data } = response;
          const newList = list.filter(item => item._id !== id);
          dispatch({ type: Types.SECTION_LIBRARY_DELETE_SINGLE_SUCCESS, payload: { data, id } });
          if (query.page > 1 && !newList.length) {
            dispatch(getSections({ page: query.page - 1 }));
          } else {
            dispatch(getSections());
          }
          callback && callback();
          toast('This section has been removed');
        },
        error => {
          dispatch({ type: Types.SECTION_LIBRARY_DELETE_SINGLE_FAILED, error: error });
        },
      ),
    );
  };
};

export const removeSelectedSections = (ids, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        sectionLibrary: {
          list,
          query: { page },
        },
      },
    } = getState();

    const sectionIds = { listIds: ids };

    dispatch({ type: Types.SECTION_LIBRARY_BULK_DELETE_REQUEST });
    return dispatch(
      Request.post(
        { url: `/api/training-section-library/bulk-delete`, data: sectionIds },
        false,
        response => {
          const {
            data: {
              data: { deletedIds },
            },
          } = response;
          dispatch(updateSelectedSection());
          if (page > 1 && list.length === 1) {
            dispatch(getSections({ page: page - 1 }));
          } else {
            dispatch(getSections());
          }
          callback && callback(deletedIds);
        },
        error => {
          dispatch({ type: Types.SECTION_LIBRARY_BULK_DELETE_FAILED, error: error });
        },
      ),
    );
  };
};

export const updateSelectedSection = sectionIds => {
  return { type: Types.SECTION_LIBRARY_UPDATE_SELECTED_SECTION, payload: { data: sectionIds || [] } };
};

export const updateSectionSharingStatus = (data, isOwner, callback) => {
  const { authorId, share, sectionLibraryId } = data;
  const params = {
    share,
    owner: authorId,
  };

  return dispatch => {
    dispatch({ type: Types.SECTION_LIBRARY_UPDATE_SHARING_STATUS });
    return dispatch(
      Request.patch(
        { url: `/api/training-section-library/${sectionLibraryId}`, data: params },
        true,
        () => {
          if (isOwner) {
            dispatch({
              type: Types.SECTION_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS,
              payload: { data, sectionLibraryId },
            });
          }
          dispatch(changeQueryParams({}, false));
          callback && callback();
        },
        () => dispatch({ type: Types.SECTION_LIBRARY_FAILED_UPDATE_SHARING_STATUS }),
      ),
    );
  };
};

export const addSectionLibrary = (workoutData, saveAsCopy = false) => {
  return (dispatch, getState) => {
    const { user } = getState();

    const section = get(workoutData, 'sections[0]', {});

    let params = {
      title: workoutData.title || '',
      format: get(section, 'format', ''),
      type: get(section, 'type', ''),
      note: get(section, 'note', ''),
      owner: get(
        workoutData,
        'author._id',
        get(workoutData, 'share', TEAM_SHARE_PRIVATE) !== TEAM_SHARE_NOOWNER ? user._id : null,
      ),
      share: get(workoutData, 'share', TEAM_SHARE_PRIVATE),
      exercises: get(section, 'exercises', []),
      time: get(section, 'time', 0),
      round: get(section, 'round', 0),
      attachments: get(section, 'attachments', []),
      exercise_references: get(section, 'exercise_references', []),
    };

    if (saveAsCopy) {
      params.owner = user._id;
      params.share = 0;
    }
    if (get(section, 'format', '') === SECTION_FORMAT_KEY.FREESTYLE) {
      params = omit(params, ['exercises']);
    } else {
      params = omit(params, ['attachments', 'exercise_references']);
    }

    const dataRequest = {
      ...params,
      attachments: (params.attachments || []).map(item => {
        if (item.thumbnail_url === MEDIA_PLACEHOLDER) {
          item.thumbnail_url = '';
        }
        return item;
      }),
    };

    return dispatch(
      Request.post({ url: '/api/training-section-library', data: dataRequest }, true, (response, { dispatch }) => {
        dispatch(getSections());
      }),
    );
  };
};

const updateSectionLibrary = workoutData => {
  return (dispatch, getState) => {
    const { user } = getState();

    const section = get(workoutData, 'sections[0]', {});

    let params = {
      title: workoutData.title || '',
      format: get(section, 'format', ''),
      type: get(section, 'type', ''),
      note: get(section, 'note', ''),
      owner: get(
        workoutData,
        'author._id',
        get(workoutData, 'share', TEAM_SHARE_PRIVATE) !== TEAM_SHARE_NOOWNER ? user._id : null,
      ),
      share: get(workoutData, 'share', TEAM_SHARE_PRIVATE),
      exercises: get(section, 'exercises', []),
      time: get(section, 'time', 0),
      round: get(section, 'round', 0),
      attachments: get(section, 'attachments', []),
      exercise_references: get(section, 'exercise_references', []),
    };

    if (get(section, 'format', '') === SECTION_FORMAT_KEY.FREESTYLE) {
      params = omit(params, ['exercises']);
    } else {
      params = omit(params, ['attachments', 'exercise_references']);
    }

    return dispatch(
      Request.patch(
        { url: `/api/training-section-library/${get(section, '_id')}`, data: params },
        true,
        (response, { dispatch }) => {
          dispatch(getSections());
        },
      ),
    );
  };
};
