import React, { useState, useRef, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import moment from 'moment';
import Avatar from 'react-avatar';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { getVideoType, getUserShortName, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { removeScriptTags, getPlainText, TITLE_LINE_HEIGHT, addInlineStyleToTags } from '../helper';

function Preview(props) {
  const [step, setStep] = useState(1);
  const { type, title, message, attachment, user, date, cloudfrontList, attachmentLink, isInterval } = props;
  const name = `${user.first_name} ${user.last_name}`;
  const noAttachment = !attachment && !(attachmentLink || {}).attachment;

  const htmlContent = useMemo(() => {
    let content = removeScriptTags(message);
    content = addInlineStyleToTags(content);
    return content;
  }, [message]);

  const plainContent = useMemo(() => {
    let content = getPlainText(message);
    content = (content || '').trimAny('↵').trim();
    return content;
  }, [message]);

  const overviewTittleRef = useRef();
  const overviewMessageRef = useRef();

  useEffect(() => {
    if (overviewTittleRef.current && overviewMessageRef.current) {
      const titleHeight = overviewTittleRef.current.clientHeight;

      if (titleHeight > TITLE_LINE_HEIGHT) {
        overviewMessageRef.current.style['-webkit-line-clamp'] = 1;
      } else {
        overviewMessageRef.current.style['-webkit-line-clamp'] = 2;
      }
    }
  }, [title, step]);

  const renderAttachment = () => {
    if (attachment) {
      const { url, type, thumbnail_url, localFile } = attachment;

      if (type.startsWith('image')) {
        return (
          <S.Attachment>
            <S3ImageWithFallback src={[url]} />
          </S.Attachment>
        );
      }

      if (type.startsWith('video')) {
        const src = localFile || url;
        return (
          <S.Attachment>
            {thumbnail_url ? (
              <S3ImageWithFallback src={[thumbnail_url, url || '']} />
            ) : (
              <video>
                <source src={src} type={getVideoType(type)} />
                <source src={src} type="video/mp4" />
              </video>
            )}
            <S.PlayButton />
          </S.Attachment>
        );
      }
    }

    if (attachmentLink) {
      const { attachment_thumbnail = '' } = attachmentLink;
      const thumbnail = convertS3UrlToCloudFrontUrl(attachment_thumbnail, cloudfrontList, true);
      if (thumbnail) {
        return (
          <S.Attachment>
            <img src={thumbnail} alt="" />
          </S.Attachment>
        );
      }
    }

    return null;
  };

  const renderDatePreview = () => {
    const isToday = moment(date).isSame(new Date(), 'day');
    if (isInterval || isToday) {
      return <>TODAY, {moment(new Date()).format('MMM Do')}</>;
    }

    return <>{moment(date).format('dddd, MMM Do')}</>;
  };

  return (
    <S.Container>
      <S.Header>Preview Announcement</S.Header>
      <S.ContentWrapper>
        <S.ContentContainer detail={step === 2}>
          <S.Content>
            {step === 1 ? (
              <S.TodayScreen>
                <div className="header">
                  <div className="date">{renderDatePreview()}</div>
                  <div>Hello {user.first_name}!</div>
                </div>
                <S.Overview className="overview">
                  <Avatar
                    name={getUserShortName(user)}
                    color={user.color}
                    className="avatar"
                    size="33"
                    src={convertS3UrlToCloudFrontUrl(user.avatar, cloudfrontList, true)}
                  />
                  <div>
                    <S.Type>{type || 'Announcement'}</S.Type>
                    <S.OverviewMainContent>
                      <S.Title ref={overviewTittleRef}>{title || 'Announcement Title'}</S.Title>
                      <S.Message ref={overviewMessageRef} dangerouslySetInnerHTML={{ __html: plainContent }} />
                    </S.OverviewMainContent>
                    <button className="read-more">Read More</button>
                  </div>
                </S.Overview>
                <S.Alarm />
              </S.TodayScreen>
            ) : (
              <S.DetailScreen noAttachment={noAttachment}>
                {renderAttachment()}
                <div className="content">
                  <S.Type>{type || 'Announcement'}</S.Type>
                  <S.Title>{title || 'Announcement Title'}</S.Title>
                  <div className="author">
                    <Avatar
                      name={getUserShortName(user)}
                      color={user.color}
                      className="avatar"
                      size="23"
                      src={user.avatar}
                    />
                    <span>by {name}</span>
                  </div>
                  <S.Message>
                    {/* <Urlify>{message}</Urlify> */}
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                  </S.Message>
                </div>
              </S.DetailScreen>
            )}
          </S.Content>
          {/* <div className="frame" /> */}
          <Image src={`${CDN_URL}/images/iphone_ear.svg`} className="phone_ear" />
          <Image src={`${CDN_URL}/images/announcemnent_preview_bottom.svg`} className="bottom" />
        </S.ContentContainer>
        <S.Navigations>
          <S.Dot active={step === 1} onClick={() => setStep(1)} />
          <S.Dot active={step === 2} onClick={() => setStep(2)} />
        </S.Navigations>
        {step === 2 ? (
          <img
            src={`${CDN_URL}/images/square_arrow_left.svg`}
            className="arrow left"
            onClick={() => setStep(1)}
            alt=""
          />
        ) : (
          <img
            src={`${CDN_URL}/images/square_arrow_right.svg`}
            className="arrow right"
            onClick={() => setStep(2)}
            alt=""
          />
        )}
      </S.ContentWrapper>
    </S.Container>
  );
}

const mapState = state => ({
  user: state.user,
  cloudfrontList: state.cloudfrontList,
});

export default connect(mapState)(Preview);
