import styled, { css } from 'styled-components';
export const ButtonWrapper = styled.div`
  .ui.button {
    border: 1px solid #d6dae4;
    border-radius: 5px;
    background-color: #ffffff;
    color: #818da1;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    span {
      padding: 0 5px;
    }

    ${props =>
      !props.isShowFilter &&
      css`
        :focus {
          background-color: rgb(245, 247, 249);
        }
      `}

    :hover {
      background-color: rgb(245, 247, 249);
    }

    ${props =>
      props.bgColor &&
      css`
        background-color: ${props.bgColor};
      `}
    ${props =>
      props.color &&
      css`
        color: ${props.color};
      `}
      ${props =>
      props.width &&
      css`
        width: ${`${props.width}px`};
      `}
      ${props =>
      props.height &&
      css`
        height: ${`${props.height}px`};
      `}
      ${props =>
      props.bgHoverColor &&
      css`
        :hover {
          background-color: ${props.bgHoverColor};

          ${props =>
            !props.isShowFilter &&
            css`
              :focus {
                background-color: ${props.bgHoverColor};
              }
            `}
        }
      `}
      ${props =>
      props.textHoverColor &&
      css`
        :hover {
          color: ${props.textHoverColor};
        }

        ${props =>
          !props.isShowFilter &&
          css`
            :focus {
              color: ${props.textHoverColor};
            }
          `}
      `}
      ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}
  }
`;
