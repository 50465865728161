import { connect } from 'react-redux';
import _ from 'lodash';
import TrainingSet from './component';
import { updateEntity } from 'redux/model/actions';
import { ENTITY_NAME } from 'database/constants';
import { removeSet } from 'redux/workout-builder/actions';

const mapStateToProps = (state, ownProps) => {
  const {
    user,
    rootReducer: {
      model,
      exercise: { fields },
      client: { workingClientDetail },
    },
  } = state;
  const clientId = _.get(workingClientDetail, '_id');
  const oneRepMaxData = clientId ? model.getIn([ENTITY_NAME.OneRepMax, [clientId, ownProps.originalExercise].join('_')]) : null;

  return {
    model: model.getIn([ENTITY_NAME.TrainingSet, ownProps.setId]),
    systemFields: fields,
    units: _.get(workingClientDetail || user, 'preferences', []),
    oneRepMaxData,
  };
};

const actionCreators = {
  updateEntity,
  removeSet,
};

export default connect(mapStateToProps, actionCreators)(TrainingSet);
