import styled, { css } from 'styled-components';
import CheckIcon from 'assets/icons/MealPlans/check_blue.svg';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid #f0f0f2;
`;

export const AddWeekWrapper = styled.div`
  display: flex;
  align-items: center;
  .calendar-icon {
    margin-right: 10px;
  }
  span {
    ${baseText}
  }
  .custom-drop-down-actions {
    .drop-down-trigger {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      border: 1px solid #e1e1ea;
      .dot {
        background-color: #202353;
      }
      .dot::after,
      .dot::before {
        background-color: #202353;
      }
      .dot::after {
        left: -4px;
      }
      .dot::before {
        left: 4px;
      }
      &:hover {
        background-color: #f0f1ff;
        .dot {
          background-color: #5158cf;
        }
        .dot::after,
        .dot::before {
          background-color: #5158cf;
        }
      }
    }
    .evf-dropdown__menu {
      padding: 4px 0;
      background-color: #2d2e2d;
      .evf-dropdown__option {
        padding: 8px 12px;
        .icon {
          margin-right: 12px;
          display: flex;
        }
        span {
          ${baseText}
          line-height: 20px;
          color: #f7f8f9;
        }
        &:hover {
          background-color: #535353;
        }
      }
    }
    &.open {
      .drop-down-trigger {
        background-color: #f0f1ff;
        .dot {
          background-color: #5158cf;
        }
        .dot::after,
        .dot::before {
          background-color: #5158cf;
        }
      }
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      .evf-dropdown__trigger-container {
        pointer-events: none;
      }
    }
    &.custom-drop-down-add-week {
      .evf-dropdown__menu {
        padding: 8px 0;
        .evf-dropdown__option {
          padding: 0 16px;
          height: 32px;
          span {
            margin-left: 8px;
          }
          margin-bottom: 4px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export const WeekWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const PrevIcon = styled.div`
  display: flex;
  transform: rotate(90deg);
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
  svg {
    width: 14px;
    height: 10px;
    path {
      stroke: #141b34;
    }
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      svg path {
        stroke: #e1e1ea;
      }
    `}
`;

export const NextIcon = styled.div`
  display: flex;
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  svg {
    width: 14px;
    height: 10px;
    path {
      stroke: #141b34;
    }
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      svg path {
        stroke: #e1e1ea;
      }
    `}
`;

export const WeekRange = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    span {
      color: #5158cf;
    }
    svg path {
      stroke: #5158cf;
    }
  }
`;

export const AddWeekButton = styled.div`
  margin-left: 10px;
  margin-right: 5px;
`;

export const AssignButton = styled.button`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 149px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  padding: 0;
  padding: 0 14px;
  outline: none;
  cursor: pointer;
  .assign-icon {
    margin-right: 6px;
    width: 14px;
    height: 12px;
    path {
      fill: #202353;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    .assign-icon {
      path {
        fill: #5158cf;
      }
    }
  }
  &:disabled {
    opacity: 0.5;
    &:hover {
      background-color: #eaeef1;
      color: #7b7e91;
      border: 1px solid #e1e1ea;
      opacity: 1;
      cursor: not-allowed;
      .assign-icon {
        path {
          fill: #7b7e91;
        }
      }
    }
  }
`;

export const MenuRight = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  .assign-meal-plan-tooltip {
    ${baseText}
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    margin-top: -9px !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    max-width: 370px;
  }
  .unpublish-meal-plan-tooltip {
    ${baseText}
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    margin-top: -11px !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    max-width: 446px;
    text-align: center;
    margin-left: -200px !important;
    &.type-dark.place-top:after {
      border-top-width: 8px;
    }
    &.place-top::after {
      margin-bottom: 0px !important;
      left: calc(100% - 23px);
    }
  }
`;

export const AssignedClientWrapper = styled.div`
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  border-bottom: 1px dashed #e1e1ea;
  cursor: pointer;
  margin-right: 15px;
  .meal-plan-group {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
  span {
    ${baseText}
    text-align: center;
  }
`;

export const DotActive = styled.div`
  width: 5px;
  height: 5px;
  background-color: #65cc9c;
  border-radius: 50%;
`;

export const JumpWeek = styled.div`
  position: absolute;
  height: 87px;
  top: calc(100% + 8px);
  left: 8px;
  box-shadow: 0px 2px 8px 0px #26262633;
  padding: 15px;
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
`;

export const JumpWeekTitle = styled.div`
  ${baseText}
  font-size: 11px;
  line-height: 17px;
  color: #7b7e91;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const JumpWeekContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WeekOption = styled.div`
  ${baseText}
  color: #000;
  display: flex;
  align-items: center;
  .week-option {
    margin-right: 6px;
    border-radius: 5px !important;
    border: 1px solid #e1e1ea;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 5px 19px 4px 14px;
    &:hover {
      border: 1px solid #5158cf;
    }
    &.active {
      border: 1px solid #5158cf;
    }
    .chevron.icon {
      display: flex;
      align-items: center;
      margin-right: 0;
      justify-content: end;
      color: #7b7e91;
      padding-left: 26px;
    }
    .visible.menu.transition {
      top: calc(100% + 8px);
      border-radius: 10px;
      padding: 10px;
      max-height: 380px;
      overflow-y: scroll;
      border-right: 3px solid transparent !important;
      padding-right: 3px;
      box-shadow: 0px 2px 8px 0px #26262633;
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: #e1e1ea;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-track {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .item {
        width: 65px;
        height: 40px;
        border-radius: 5px;
        padding: 10px !important;
        position: relative;

        span {
          ${baseText}
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
        }
        &.selected {
          background-color: transparent !important;
          &:after {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            background-image: url(${CheckIcon});
            background-repeat: no-repeat;
          }
          span {
            color: #5158cf;
            font-weight: 600 !important;
          }
        }
        &:hover {
          background: #f0f1ff !important;
          span {
            color: #5158cf;
          }
        }
      }
    }
  }
`;

export const JumpWeekButton = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  width: 67px;
  height: 30px;
  border-radius: 5px;
  background-color: rgba(81, 88, 207, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(81, 88, 207, 0.9);
  }
`;

export const Rearrange = styled.button`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
  }
  svg {
    margin-right: 5px;
    path:first-child {
      fill: #5158cf;
    }
  }
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      color: #5158cf;
      border: 1px solid #5158cf;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        color: #202353;
        background-color: #fff;
        .add-icon path {
          stroke: #202353;
        }
      }
    `}
`;

export const ActionStatus = styled.button`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  height: 30px;
  margin-left: 5px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
  }
  &.btn-exit-edit {
    width: 114px;
    padding: 0;
  }
  &.btn-edit {
    width: 53px;
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          background-color: #fff;
          color: #202353;
        }
      `}
  }
  &.btn-publish {
    padding-left: 16px;
    background-color: rgba(54, 179, 75, 1);
    color: #fff;
    border: none;
    width: 94px;
    svg {
      margin-right: 5px;
    }
    &:hover {
      background-color: rgba(54, 179, 75, 0.9);
    }
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          background-color: rgba(54, 179, 75, 1);
        }
      `}
  }
  &.btn-unpublish {
    background-color: rgba(255, 190, 73, 1);
    color: #fff;
    border: none;
    width: 111px;
    svg {
      margin-right: 5px;
    }
    &:hover {
      background-color: rgba(255, 190, 73, 0.9);
    }
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: #e9eef1;
        color: #7b7e91;
        cursor: not-allowed;
        svg path {
          fill: #7b7e91;
        }
        &:hover {
          background-color: #e9eef1;
        }
      `}
  }
  &.btn-cancel-edit {
    width: 94px;
    padding: 0;
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          background-color: #fff;
          color: #202353;
        }
      `}
  }
  &.btn-publish-changes {
    width: 147px;
    background-color: rgba(81, 88, 207, 1);
    color: #fff;
    border: none;
    svg {
      margin-right: 5px;
    }
    &:hover {
      background-color: rgba(81, 88, 207, 0.9);
    }
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: #eaeef1;
        color: #7b7e91;
        svg path {
          fill: #7b7e91;
        }
        &:hover {
          background-color: #eaeef1;
        }
      `}
  }
`;
