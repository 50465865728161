import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
// import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import get from 'lodash/get';
import filter from 'lodash/filter';
// import map from 'lodash/map';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import TextEditable from 'shared/TextEditable';
import StatusDropdown from 'shared/StatusDropdown';

import Banner from './Banner';
import AddNewForm from '../AddNewForm';
import PopupModal from '../AddNewForm/PopupModal';
import Category from './Category';
import MobilePreviewCollection from './MobilePreviewCollection';

import { TEAM_SHARE_NOOWNER, WORKOUT_COLLECTION_STATUS } from 'constants/commonData';
import { VALIDATION_LIST, VALIDATION_FIELD, WORKOUT_TYPE } from '../constants';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EmptyCategory } from 'assets/images/empty-workout-collection-detail.svg';

import * as S from './style';
import { convertS3UrlToCloudFrontUrl, isTeamAdmin, reorder } from 'utils/commonFunction';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';

const LIMIT_LENGTH = 30;
const LIMIT_DESCRIPTION = 120;
const LIMIT_LAST_ROW = 80;
const MAX_HEIGHT = 55;
const MAX_REVIEW_ROW = 2;
const MAX_DESCRIPTION_TYPING_HEIGHT = 98;

// const STATUS_OPTIONS = [
//   {
//     value: 'publish',
//     label: 'Published',
//   },
//   {
//     value: 'draft',
//     label: 'Draft',
//   },
// ];

const queryAttr = 'data-rbd-drag-handle-draggable-id';
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: '100%',
  position: 'relative',
});

const WorkoutCollectionsHeader = props => {
  const { onSubmitAddNew, collectionId, categories, disabled = false } = props;

  const handleSubmit = (val, callback = null) => {
    val = {
      ...val,
      collectionId: collectionId,
    };

    onSubmitAddNew && onSubmitAddNew(val);
    callback && callback();
  };

  return (
    <S.CollectionsHeader>
      <S.CategoryTotal>Categories ({categories.length})</S.CategoryTotal>
      <AddNewForm
        text="Create New Category"
        heightButton={36}
        widthButton={194}
        icon={<PlusIcon />}
        buttonClassName="buttonClassName"
        titlePopup="Create New Category"
        titleInput="Category Name"
        placeholderInput="Category Name"
        description="Use categories to separate and sort workouts in the Collection."
        submitName="Create"
        onSubmit={handleSubmit}
        popupModal={PopupModal}
        disabled={disabled}
      />
    </S.CollectionsHeader>
  );
};

const RenderDescription = ({ description, onBlur, onDescriptionChange, readOnly = false }) => {
  const titleRef = React.createRef();
  const [isFocus, setIsFocus] = useState(false);
  const [isView, setIsView] = useState(false);
  const refContainer = useRef(null);

  useEffect(() => {
    description.length === 0 && setIsFocus(true);
  }, [description]);

  const handleSetIsFocus = value => setIsFocus(value);

  const handleBlur = () => {
    onBlur('description', description);
    handleSetIsFocus(false);
    // TODO - scroll view of description on top
    if (titleRef.current) {
      const child = get(titleRef.current, 'inputRef.current', false);
      if (child) child.scrollTop = 0;
    }
    // Focus when description is empty
    if (description.length === 0) setIsFocus(true);
  };

  const handleReadMore = () => {
    setIsView(true);
  };

  const handleCloseReadMore = () => {
    // TODO - close view of description
    setIsView(false);
  };

  const handleClickView = () => {
    setIsView(false);
  };

  // TODO - Handle Count Break Line Of String
  const handleCountBreakLine = val => {
    const value = val ? val.trim() : '';
    const breakLineTotal = value.split('\n').length;
    return breakLineTotal;
  };

  const handleCutStringLastRow = val => {
    const breakLineTotal = val ? val.split('\n').length : 0;

    if (breakLineTotal === 0) return;
    if (breakLineTotal === 1) return val;

    let value = val.split('\n');
    value[MAX_REVIEW_ROW - 1] = value[MAX_REVIEW_ROW - 1]
      ? value[MAX_REVIEW_ROW - 1].substring(0, LIMIT_LAST_ROW)
      : null;
    value = value.join('\n');
    return value;
  };

  const handleRenderValue = value => {
    return (
      <S.DescriptionView className={classnames({ active: isView })}>
        <span onClick={handleClickView} className="view">
          {value}
        </span>

        <div
          className="descriptionInput__cancelViewMode"
          data-tip
          data-for="cancel-view-mode"
          onClick={handleCloseReadMore}
        >
          <ReactTooltip id="cancel-view-mode" effect="solid" place={'top'}>
            Minimize description
          </ReactTooltip>
        </div>
      </S.DescriptionView>
    );
  };

  const handleDescriptionChange = value => {
    // show the new line after click Enter key
    if (titleRef.current) {
      const child = get(titleRef.current, 'inputRef.current', false);
      if (child && child.clientHeight >= MAX_DESCRIPTION_TYPING_HEIGHT) {
        child.scrollTop = child.scrollHeight;
      }
    }

    // onchange description
    onDescriptionChange && onDescriptionChange(value);
  };

  const hasReadMore = handleCountBreakLine(description) > 2 || description.length > LIMIT_DESCRIPTION;
  let checkRefReadMore = hasReadMore;
  if (refContainer.current) {
    checkRefReadMore = refContainer.current.clientHeight > MAX_HEIGHT;
  }
  const shortDesc = checkRefReadMore ? handleCutStringLastRow(description) : description;
  const showReadMore = !isFocus && checkRefReadMore && !isView;

  return (
    <S.DescriptionArea ref={refContainer} isView={isView} hasReadMore={showReadMore}>
      {isView && handleRenderValue(description || '')}

      {!isView && (
        <TextEditable
          ref={titleRef}
          readOnly={readOnly}
          className="collection-description"
          value={isFocus ? description : shortDesc}
          onBlur={handleBlur}
          onChange={handleDescriptionChange}
          onFocus={() => handleSetIsFocus(true)}
          placeholder="Add collection description"
          lineHeight={19.5}
          breakLine={true}
        />
      )}

      {showReadMore && <S.ReadMore onClick={handleReadMore}>Read More</S.ReadMore>}
    </S.DescriptionArea>
  );
};

export default function Overview({
  workingCollection: originalWorkingCollection,
  getPreSignedUrl,
  cloudfrontList,
  updateWorkoutCollectionDetail,
  addNewCategory,
  push,
  toggleCategory,
  toggleCollection,
  toggleConfirmModal,
  removeCategory,
  duplicateCategory,
  rearrangeCategories,
  updateSettingFormat,
  getCustomBrandingData,
  customBrandingBackground,
  user,
}) {
  const [workingCollection, setWorkingCollection] = useState(originalWorkingCollection);
  const [name, setName] = useState(workingCollection.name);
  const [description, setDescription] = useState(workingCollection.description || '');
  const [type, setType] = useState(workingCollection.type || '');
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [categories, setCategories] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isEmpty, setEmpty] = useState(false);
  const [validationList, setValidationList] = useState(VALIDATION_LIST);

  const isShowViewRight = isPreview || !isEmpty ? true : false;

  const statusOptions = [
    { value: WORKOUT_COLLECTION_STATUS.DRAFT, label: 'Draft', color: '#818DA1' },
    { value: WORKOUT_COLLECTION_STATUS.PUBLISH, label: 'Published', color: '#49CA8D' },
  ];

  const detailCategoryList = useMemo(() => {
    let list = get(workingCollection, 'categories', []);
    list = filter(list, item => get(item, 'status', '') === 'active' && get(item, 'workouts', []).length > 0);

    // Get default for a background's workout
    list.forEach(item => {
      let workouts = get(item, 'workouts', []);
      workouts.forEach((ele, index) => {
        if (ele.type === WORKOUT_TYPE.REGULAR) {
          workouts[index].background = ele.background ? ele.background : customBrandingBackground.src;
        }
      });
    });
    return list;
  }, [workingCollection.categories, customBrandingBackground]);

  // const otherOption = find(STATUS_OPTIONS, option => option.value !== status);

  const { status } = workingCollection;
  const author =
    typeof originalWorkingCollection.author_info === 'object' ? originalWorkingCollection.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = originalWorkingCollection.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;

  useEffect(() => {
    getCustomBrandingData && getCustomBrandingData();
  }, []);

  useEffect(() => {
    setWorkingCollection(originalWorkingCollection);
  }, [originalWorkingCollection]);

  useEffect(() => {
    setCategories(workingCollection.categories);

    // TODO - Check category to set open collapse
    const categoryList = get(workingCollection, 'categories', []);
    if (categoryList.length === 0) {
      setEmpty(true);
      setIsPreview(false);
    } else {
      setEmpty(false);
      setIsPreview(true);
    }
  }, [workingCollection.categories]);

  useEffect(() => {
    if (
      !isEqual(
        omit(workingCollection, ['updated_at', 'status']),
        omit(originalWorkingCollection, ['updated_at', 'status']),
      )
    ) {
      let updateFields = cloneDeep(workingCollection);
      // only keep updated fields
      forEach(originalWorkingCollection, (value, key) => {
        if (isEqual(originalWorkingCollection[key], updateFields[key])) {
          delete updateFields[key];
        }
      });

      // TODO - Handle IsValidation
      if (!handleIsValidation()) return;

      delete updateFields['updated_at'];
      if (Object.keys(updateFields).length > 0) {
        debounceUpdateData(updateFields, workingCollection._id);
      }
    }
  }, [workingCollection]);

  const debounceUpdateData = useCallback(debounce(updateWorkoutCollectionDetail, 1000), []);

  const handleChangeCoverImage = url => {
    setWorkingCollection({ ...workingCollection, background: url });
  };

  const startUploadCoverImage = () => {
    setUploading(true);
  };

  const endUploadCoverImage = () => {
    setUploading(false);
  };

  const handleNameChange = value => {
    if (value.length <= LIMIT_LENGTH) {
      setName(value);
    } else {
      // handle copy paste case out of chars limit
      setName(value.slice(0, LIMIT_LENGTH));
    }

    // Check Validation
    handleCheckValidation(value, 'name');
  };

  const handleTypeChange = value => {
    if (value.length <= LIMIT_LENGTH) {
      setType(value);
    } else {
      // handle copy paste case out of chars limit
      setType(value.slice(0, LIMIT_LENGTH));
    }

    // Check Validation
    handleCheckValidation(value, 'type');
  };

  const handleCheckValidation = (val, name) => {
    let value = val || '';
    let validations = validationList;

    value = value.trim();

    if (value.length > 0) validations[name] = true;
    else validations[name] = false;

    setValidationList(validations);
  };

  const handleIsValidation = () => {
    const validationKeys = VALIDATION_FIELD;
    let isValidation = true;

    forEach(validationKeys, item => {
      if (!validationList[item]) isValidation = false;
    });

    return isValidation;
  };

  const handleDescriptionChange = value => {
    setDescription(value || '');
  };

  const handleBlur = (name, value) => {
    setWorkingCollection({ ...workingCollection, [name]: value });
  };

  const handleGetValidation = (name, value) => {
    const val = value || '';
    if (val.length === 0) return !validationList[name];
  };

  const handleOnDragEnd = item => {
    const { destination, source, draggableId } = item;
    if (!destination || !source || destination.index === source.index) {
      return;
    }
    const clone = cloneDeep(categories);

    const data = reorder(clone, source.index, destination.index);
    // Update current list first
    setCategories(data);
    // Call api to update late
    setTimeout(() => {
      rearrangeCategories && rearrangeCategories(draggableId, destination.index, source.index, true);
    }, 500);
  };

  const onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleCategoryClick = categoryId => () => {
    push(`/home/workout-collection-category/${categoryId}`);
  };

  const handleRemoveCategory = categoryId => () => {
    removeCategory(categoryId);
  };

  const handleDuplicateCategory = (categoryId, name) => () => {
    let newName = `Copy of ${name}`;
    if (newName.length > 30) {
      newName = newName.slice(0, 30);
    }
    duplicateCategory(categoryId, newName);
  };

  const handleMoveCategory = (categoryId, destinationIndex, originalIndex) => {
    rearrangeCategories && rearrangeCategories(categoryId, destinationIndex, originalIndex);
  };

  const handleRemoveBackground = () => {
    handleChangeCoverImage(null);
  };

  const handleChangeStatus = newValue => {
    if (status === WORKOUT_COLLECTION_STATUS.PUBLISH) {
      toggleCollection(workingCollection._id, workingCollection.status);
      setWorkingCollection({ ...workingCollection, status: WORKOUT_COLLECTION_STATUS.DRAFT });
    } else {
      toggleCollection(workingCollection._id, workingCollection.status);
      setWorkingCollection({ ...workingCollection, status: WORKOUT_COLLECTION_STATUS.PUBLISH });
    }
  };

  const handleChangePreview = () => {
    setIsPreview(!isPreview);
  };

  const handleChangeOwnershipStatus = ({ author, share }) => {
    debounceUpdateData({ author, share }, workingCollection._id);
  };

  return (
    <>
      <Helmet>
        <title>{workingCollection.name} - Workout Collection - Everfit</title>
      </Helmet>
      <S.Wrapper>
        <S.BaseInfoWrapper>
          <div className="base-info-container">
            <div className="banner-container">
              <Banner
                onRemove={handleRemoveBackground}
                isSubmitting={uploading}
                isEdit={isCreatorOrOwnerOrAdmin}
                coverImage={convertS3UrlToCloudFrontUrl(workingCollection.background, cloudfrontList, true)}
                getPreSignedUrl={getPreSignedUrl}
                onUploadImageChange={handleChangeCoverImage}
                startUploadCoverImage={startUploadCoverImage}
                endUploadCoverImage={endUploadCoverImage}
              />
            </div>
            <div className="info-container">
              <TextEditable
                readOnly={!isCreatorOrOwnerOrAdmin}
                className="workout-collection-type"
                value={type}
                onBlur={() => handleBlur('type', type)}
                onChange={handleTypeChange}
                placeholder="Add collection type"
                lineHeight={27}
                maxLength={LIMIT_LENGTH}
                invalid={handleGetValidation('type', type)}
              />
              <S.TextEditableArea>
                <TextEditable
                  readOnly={!isCreatorOrOwnerOrAdmin}
                  className="workout-collection-title"
                  value={name}
                  onBlur={() => handleBlur('name', name)}
                  onChange={handleNameChange}
                  placeholder="Add collection name"
                  lineHeight={29}
                  maxLength={LIMIT_LENGTH}
                  invalid={handleGetValidation('name', name)}
                />
                <S.CountCharacter>{`${name.length}/${LIMIT_LENGTH}`}</S.CountCharacter>
              </S.TextEditableArea>

              <RenderDescription
                readOnly={!isCreatorOrOwnerOrAdmin}
                description={description || ''}
                onBlur={handleBlur}
                onDescriptionChange={handleDescriptionChange}
              />
            </div>
          </div>
          <div className="ownership-container">
            {/* {isCreatorOrOwnerOrAdmin && (
              <OwnershipPopupStudio
                user={user}
                owner={author}
                shareStatus={workingCollection.share}
                saveChangeOnClose={handleChangeOwnershipStatus}
              />
            )} */}
          </div>
          <div className="status-container">
            <StatusDropdown
              readOnly={!isCreatorOrOwnerOrAdmin}
              options={statusOptions}
              value={status}
              onChange={handleChangeStatus}
            />
          </div>
        </S.BaseInfoWrapper>

        <S.CategoryBodyArea isPreview={isPreview} isEmpty={isEmpty}>
          <S.LeftCategory>
            <WorkoutCollectionsHeader
              collectionId={workingCollection._id}
              onSubmitAddNew={addNewCategory}
              categories={categories || []}
              disabled={!isCreatorOrOwnerOrAdmin}
            />
            {isEmpty ? (
              <S.EmptyCategoryWrapper>
                <EmptyCategory />
              </S.EmptyCategoryWrapper>
            ) : (
              <S.CategoriesListWrapper>
                <DragDropContext onDragEnd={handleOnDragEnd} onDragUpdate={onDragUpdate}>
                  <Droppable droppableId="droppable" isDropDisabled={!isCreatorOrOwnerOrAdmin}>
                    {(provided, snapshot) => (
                      <div
                        className="category-list"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {categories.map((category, idx) => {
                          return (
                            <Draggable
                              key={category._id}
                              draggableId={category._id}
                              index={idx}
                              isDragDisabled={!isCreatorOrOwnerOrAdmin}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={classnames('wrapper-category', { isDragging: snapshot.isDragging })}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  <Category
                                    onRemoveCategory={handleRemoveCategory(category._id)}
                                    onDuplicateCategory={handleDuplicateCategory(category._id, category.name)}
                                    key={category._id}
                                    category={category}
                                    toggleCategory={toggleCategory}
                                    toggleConfirmModal={toggleConfirmModal}
                                    handleCategoryClick={handleCategoryClick(category._id)}
                                    isFirst={idx === 0}
                                    isLast={idx === categories.length - 1}
                                    index={idx}
                                    onMoveCategory={handleMoveCategory}
                                    updateSettingFormat={updateSettingFormat}
                                    disabled={!isCreatorOrOwnerOrAdmin}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        {snapshot.isUsingPlaceholder && snapshot.isDraggingOver && (
                          <div
                            style={{
                              position: 'absolute',
                              top: placeholderProps.clientY,
                              left: placeholderProps.clientX,
                              height: 89,
                              borderRadius: '8px',
                              width: placeholderProps.clientWidth,
                              background: 'rgb(250, 251, 252)',
                              border: '1px dashed rgb(81, 88, 207)',
                              marginBottom: 10,
                              borderWidth: placeholderProps.clientWidth ? '1px' : '0px',
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </S.CategoriesListWrapper>
            )}
          </S.LeftCategory>

          <S.RightCategory>
            {isShowViewRight && (
              <MobilePreviewCollection
                name={name}
                workingCollection={detailCategoryList || []}
                customBrandingBackground={customBrandingBackground}
              />
            )}
          </S.RightCategory>

          <S.CollapseWrapper onClick={handleChangePreview}>
            <Icon className={`chevron ${isPreview ? 'right' : 'left'}`} />
          </S.CollapseWrapper>
        </S.CategoryBodyArea>
      </S.Wrapper>
    </>
  );
}
