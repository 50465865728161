import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #8789a8;
  user-select: none;

  .custom-icon {
    rotate: 90deg;
    width: 20px;
    height: 20px;
    &.is-show-less {
      rotate: 270deg;
    }
    path {
      fill: #8789a8;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;
