import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

const limitText = `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 15px;
`;

export const Day = styled.div`
  ${baseText}
  font-size: 15px;
  line-height: 23px;
  text-transform: uppercase;
  span {
    margin-left: 5px;
  }
`;

export const EditButton = styled.button`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  width: 43px;
  height: 30px;
  border-radius: 5px;
  background-color: #f0f0f2;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        background-color: #f0f0f2;
        color: #202353;
      }
    `}
`;

export const NutritionList = styled.div`
  display: flex;
  align-items: center;
`;

export const NutritionItem = styled.div`
  ${baseText}
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  position: relative;
  padding: 0 13px 0 10px;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #a3a3b5;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`;

export const NutritionLabel = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  margin-right: 3px;
`;

export const NutritionValue = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  margin-right: 3px;
`;

export const ButtonArrow = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 30px 0px rgba(172, 172, 172, 0.2);
  background-color: #fff;
  border: 1px solid #e1e1ea;
  position: absolute;
  top: calc(50% + 15px);
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  &:hover {
    background-color: #f0f1ff;
  }
  svg path {
    fill: #202353;
  }
  &.button-prev {
    left: 30px;
    svg {
      transform: rotate(-90deg);
    }
  }
  &.button-next {
    right: 30px;
    svg {
      transform: rotate(90deg);
    }
  }
`;

export const MealListWrapper = styled.div`
  position: relative;
`;

export const MealList = styled.div`
  padding: 0 22.5px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 0 !important;
  }
  &:hover {
    ${ButtonArrow} {
      display: flex;
    }
  }
  ${({ isDragging }) =>
    isDragging &&
    css`
      height: 122px;
      position: relative;
      overflow-x: unset;
    `}
`;

export const MealTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 24px;
  margin-bottom: 5px;
  .icon-trailing {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const MealTitle = styled.div`
  ${baseText}
  line-height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  .recipe-name {
    ${limitText}
  }
  .macro-info-wrapper {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #a3a3b5;
      top: 50%;
      transform: translateY(-50%);
      left: -7px;
    }
  }
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      width: 155px;
    `}
`;

export const MealItemWrapper = styled.div`
  opacity: 1 !important;
`;

export const MealItem = styled.div`
  border: 1px dashed #e1e1ea;
  border-radius: 5px;
  padding: 9px;
  background-color: #fff;
  margin: 0 7.5px;
  position: relative;
  &:hover {
    ${MealTitleWrapper} {
      svg {
        display: block;
      }
    }
  }
  ${({ isDraggingMeal }) =>
    isDraggingMeal &&
    css`
      border: none;
      box-shadow: 0px 2px 8px 0px #26262633;
      ${MealTitleWrapper} {
        svg {
          display: block;
        }
      }
    `}

  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      background-color: #f8f8f8;
      width: 195px;
      &:hover {
        border: 1px dashed #5158cf !important;
      }
    `}
`;

export const RecipeList = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      flex-wrap: wrap;
      height: 73px;
      overflow: hidden;
      align-items: start;
    `}
`;

export const RecipeItem = styled.div`
  width: 252px;
  height: 261px;
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      width: 82px;
      height: 55px;
    `}
`;

export const MealEmptyList = styled.div`
  margin-bottom: 40px;
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      height: 122px;
      border-radius: 5px;
      border: 1px dashed #e1e1ea;
      margin: 0 30px;
    `}
`;

export const Placeholder = styled.div`
  position: absolute;
  border: 1px dashed #5158cf;
  border-radius: 5px;
`;

export const LabelMerge = styled.div`
  display: none;
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      &.show-label {
        ${baseText}
        line-height: 20px;
        color: #fff;
        position: absolute;
        height: 30px;
        width: max-content;
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
        background: #000;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        &::before {
          content: '';
          position: absolute;
          bottom: -6px;
          left: 50%;
          width: 8px;
          height: 8px;
          background-color: #000;
          transform: rotate(45deg) translateX(-50%);
        }
      }
    `}
`;
