import React from 'react';
import classNames from 'classnames';

import { ReactComponent as PlusPurpleIcon } from 'assets/icons/plus_light_purple.svg';

import { getFormProperties } from 'components/OnboardingFlowDetail/constants/helper';

import * as S from './style';
import TagFormInfo from 'components/OnboardingFlowDetail/components/TagFormInfo';

const TriggerPopup = props => {
  const { selectedForm, showPopup, isUnavailable = false, ...rest } = props;
  const { formTitle } = getFormProperties(selectedForm);

  return (
    <S.TriggerPopupWrapper className={classNames({ 'is-selected': !!selectedForm })} {...rest}>
      {selectedForm ? (
        <S.FormItemWrapper
          className={classNames('form-item-container trigger-popup button-trigger-popup', {
            focus: showPopup,
            'is-unavailable': isUnavailable,
          })}
        >
          <div className="main-content">
            <div className="form-title">
              <span className="name-form">{formTitle}</span>
              {isUnavailable && <span className="text-unavailable">unavailable</span>}
            </div>
            <TagFormInfo form={selectedForm} />
          </div>

          <div className="right-content">
            <div className="change-button">Change</div>
          </div>
        </S.FormItemWrapper>
      ) : (
        <S.ChooseOnboardingFormButton className={classNames('button-trigger-popup', { focus: showPopup })}>
          <PlusPurpleIcon />
          <span className="label">Choose Onboarding Form</span>
        </S.ChooseOnboardingFormButton>
      )}
    </S.TriggerPopupWrapper>
  );
};

export default TriggerPopup;
