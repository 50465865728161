import { isMobile } from 'react-device-detect';
import { logSignUp } from 'libs/firebase-analytics';
import { push } from 'connected-react-router';
import { auth } from 'database/firebase';
import moment from 'moment';
import _ from 'lodash';
import { axiosInstanceWithoutToken } from 'configs/request';
import { saveToken, getCookie } from 'utils/commonFunction';
import { logRequestError } from 'utils/commonRequest';
import { sendSignupData } from 'utils/hubspot';
import { sendFacebookSignupData } from 'utils/pixel';
import { Mixpanel } from 'utils/mixplanel';
import { addRegistrationNeedToComplete } from 'redux/auth/actions';
import { showTheMobileReminder } from 'utils/event-api';
import { REFERAL_CODE_KEY } from 'constants/commonData';

export const FAILED_REGISTER_USER = 'FAILED_REGISTER_USER';
export const ERROR_REGISTER_USER = 'ERROR_REGISTER_USER';

export const register = (usr, isClientRegister = false) => {
  const registerData = {
    ..._.omit(usr, ['referredBy', 'referralObj']),
    timezone: moment.tz.guess(),
    agent: !isMobile ? 'web' : 'mobile-web',
  };
  return axiosInstanceWithoutToken
    .post('/api/auth/register', registerData)
    .then(result => {
      const { user, token, refresh_token, firebase_token } = result.data;

      if (!isClientRegister) {
        logSignUp(user);
        sendSignupData(usr);
        Mixpanel.identify(user._id);
        Mixpanel.track('Register');
        Mixpanel.people.set({
          $first_name: usr.first_name,
          $last_name: usr.last_name,
        });

        saveToken({ token, refresh_token }, false);

        auth
          .signInWithCustomToken(firebase_token)
          .catch(error => {
            logRequestError('Firebase authen issue', error);
          })
          .finally(() => {
            if (isMobile) {
              showTheMobileReminder();
            }

            window.location = `/welcome${window.location.search}`;
          });
      }

      return result;
    })
    .catch(err => {
      throw err;
    });
};

export const createTrainerProfile = data => {
  sendSignupData(data);

  return dispatch => {
    return axiosInstanceWithoutToken
      .post('/api/authentication/sign-up', { ...data, agent: !isMobile ? 'web' : 'mobile-web' })
      .then(result => {
        const { user } = result.data;

        logSignUp(user);
        if (!data.referral_code) {
          const referralCode = getCookie(REFERAL_CODE_KEY);
          if (referralCode) {
            result.data.user.signupData.referral_code = referralCode;
          }
        }
        sendFacebookSignupData(data);
        Mixpanel.identify(user._id);
        Mixpanel.track('Register');
        dispatch(addRegistrationNeedToComplete(result.data));
        dispatch(push(`/complete-registration${window.location.search}`));
        return result.data;
      })
      .catch(err => {
        throw err;
      });
  };
};

export const completeTrainerRegistration = (data, token) => {
  sendSignupData(data);

  return () => {
    const registerData = _.omit(data, ['referredBy', 'referralObj', 'email']);
    return axiosInstanceWithoutToken
      .post(
        '/api/authentication/complete-registration',
        { ...registerData, agent: !isMobile ? 'web' : 'mobile-web' },
        { headers: { 'x-access-token': token } },
      )
      .then(result => {
        const { user, token, refresh_token, firebase_token } = result.data.data;
        logSignUp(user);
        Mixpanel.identify(user._id);
        Mixpanel.track('Complete profile');
        Mixpanel.people.set({ $first_name: data.first_name, $last_name: data.last_name });

        saveToken({ token, refresh_token }, false);

        auth
          .signInWithCustomToken(firebase_token)
          .catch(error => {
            logRequestError('Firebase authen issue', error);
          })
          .finally(() => {
            if (isMobile) {
              showTheMobileReminder();
            }

            if (!user.is_answers_questionnaire || !user.is_watch_video) {
              window.location = `/welcome${window.location.search}`;
            } else {
              window.location = '/home/client';
            }
          });

        return result;
      })
      .catch(err => {
        throw err;
      });
  };
};

export const signUpByGoogle = (submitData, team_invite_id, tokenResponse, isClientRegister = false) => {
  let registerData = {
    timezone: moment.tz.guess(),
    agent: !isMobile ? 'web' : 'mobile-web',
  };

  if (team_invite_id) {
    registerData = {
      ...registerData,
      ..._.omit(submitData, ['referralObj']),
    };
  } else {
    registerData = {
      ...registerData,
      ...submitData,
    };
  }

  return dispatch => {
    return axiosInstanceWithoutToken
      .post('/api/authentication/trainer/login-google', { ...registerData, access_token: tokenResponse.access_token })
      .then(result => {
        const { user, token, refresh_token, firebase_token } = result.data.data;

        logSignUp(user);
        Mixpanel.identify(user._id);
        Mixpanel.track('Register');

        if (team_invite_id) {
          if (!isClientRegister) {
            sendSignupData(user);
            Mixpanel.people.set({
              $first_name: user.first_name,
              $last_name: user.last_name,
            });
            saveToken({ token, refresh_token }, false);
            auth
              .signInWithCustomToken(firebase_token)
              .catch(error => {
                logRequestError('Firebase authen issue', error);
              })
              .finally(() => {
                if (isMobile) {
                  showTheMobileReminder();
                }
                window.location = `/welcome${window.location.search}`;
              });
          }
        } else {
          if (!user.referral_code) {
            const referralCode = getCookie(REFERAL_CODE_KEY);
            if (referralCode) {
              user.signupData.referral_code = referralCode;
            }
          }
          sendFacebookSignupData(user);
          if (user.is_trainer) {
            if (user.need_complete_profile) {
              dispatch(addRegistrationNeedToComplete(result.data.data));
              dispatch(push(`/complete-registration${window.location.search}`));
              return;
            } else {
              sendSignupData(user);
              Mixpanel.people.set({
                $first_name: user.first_name,
                $last_name: user.last_name,
              });
              saveToken({ token, refresh_token }, false);
              auth
                .signInWithCustomToken(firebase_token)
                .catch(error => {
                  logRequestError('Firebase authen issue', error);
                })
                .finally(() => {
                  if (isMobile) {
                    showTheMobileReminder();
                  }
                  if (!user.is_answers_questionnaire || !user.is_watch_video) {
                    window.location = `/welcome${window.location.search}`;
                  } else {
                    window.location = '/home/client';
                  }
                });
            }
          }
        }

        return result;
      })
      .catch(err => {
        throw err;
      });
  };
};
