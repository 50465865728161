// libraries
import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';

// store
import { changeQueryParams } from 'redux/metric-group-library/action';

// components
import { NewSearchInput } from 'shared/SearchInput';
import NewGroupModal from '../NewGroupModal';

// constants

// assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/settings_gear_icon.svg';

import * as S from './style';

function Header(props) {
  const { toggleModal, changeQueryParams, push } = props;

  const handleOpenAddNewModal = () => {
    toggleModal(true, <NewGroupModal toggleModal={toggleModal} />);
  };

  const handleNavigateSetting = () => {
    push({ pathname: '/home/default-client-settings', state: { isOpenSetting: true } });
  };

  const handleSearch = (event, { value }) => {
    changeQueryParams({ q: value.trim(), page: 1 });
  };

  const handleClearSearch = () => {
    changeQueryParams({ q: '', page: 1 });
  };

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <NewSearchInput
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
          placeholder="Search by keyword or name"
          className="metric-group-search"
        />
        <S.RightActionWrapper>
          <S.ManageMetricButton onClick={handleNavigateSetting}>
            <SettingIcon className="setting-icon" />
            <span className="label">Manage Metrics</span>
          </S.ManageMetricButton>
          <S.AddNewButton purple onClick={handleOpenAddNewModal}>
            <PlusIcon className="plus-icon" />
            <span className="label">Add New Metric Group</span>
          </S.AddNewButton>
        </S.RightActionWrapper>
      </S.HeaderWrapper>
    </S.Wrapper>
  );
}

const mapDispatchToProps = dispatch => ({
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(null, mapDispatchToProps)(Header);
