import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import SearchInput from 'shared/SearchInput';
import SearchBox from 'shared/SearchBox';
import { changeAutoflowQueryParam, getAutoflowList } from 'redux/autoflow/actions';
import { toggleModal } from 'actions/modal';
import AutoflowForm from './Form';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  .evf-search-box {
    width: 300px;
  }

  button.add-new {
    background-color: #5c5bbd;
    color: #fff;
    width: 165px;
    height: 36px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    box-shadow: none;
    line-height: 18px;
    outline: none !important;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: #FFFFFF;
  }
`;

const Header = ({ search, changeAutoflowQueryParam, getAutoflowList, toggleModal }) => (
  <Container className="autoflow-list__toolbar">
    <SearchBox
      value={search}
      onChange={event => changeAutoflowQueryParam({ search: event.target.value })}
      onClear={() => {
        changeAutoflowQueryParam({ search: '', page: 1 });
        getAutoflowList();
      }}
      placeholder={'Search for autoflow'}
      onSearch={() => {
        changeAutoflowQueryParam({ page: 1 });
        getAutoflowList();
      }}
      onKeyPress={event => {
        event.persist();

        if (event.key === 'Enter') {
          changeAutoflowQueryParam({ page: 1 });
          getAutoflowList();
        }
      }}
    />
    <button type="button" className="add-new" onClick={() => toggleModal(true, <AutoflowForm />)}>
      Create Autoflow
    </button>
  </Container>
);

Header.displayName = 'AutoflowListHeader';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { query } = rootReducer.autoflow.common;

  return { search: query.search };
};

const mapDispatchToProps = dispatch => ({
  changeAutoflowQueryParam: bindActionCreators(changeAutoflowQueryParam, dispatch),
  getAutoflowList: bindActionCreators(getAutoflowList, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
