import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #5158cf;

  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  .custom-plus-icon {
    width: 12px;
    height: 12px;
    path {
      fill: #ffffff;
    }
  }
`;
