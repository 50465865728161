/**
 * @flow
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import { getExerciseLibrary } from 'redux/exercise/exercise.actionCreators';
import ExerciseDetailComponent from './component';

const mapStateToProps = state => ({
  isModalOpen: state.isModalOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getExerciseLibrary: bindActionCreators(getExerciseLibrary, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExerciseDetailComponent);
