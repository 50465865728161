import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const Icon = styled.div`
  margin-bottom: 10px;
  width: 86px;
  height: 51px;
  background: transparent url(${CDN_URL}/images/document_upload_icon.svg) no-repeat center;
  background-size: contain;
`;

export const Text = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #6A778A;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  opacity: 0;

  .select-file__trigger {
    color: #5158cf;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ${props => props.isDragging ? css`
    opacity: 1;
  ` : css`
    :hover {
      background-color: #00000030;
      opacity: 1;

      ${Icon} {
        background-image: url(${CDN_URL}/images/document_upload_icon.svg)
      }

      ${Text} {
        color: #FFFFFF;
      }

      .select-file__trigger {
        color: #FFFFFF;
      }
    }
  `}
`;

export const Wrapper = styled.div`
  height: 132px;
  width: 100%;
  outline: none;
  position: relative;
  background: #FAFBFC;
  border: 1px solid #DADFEA;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 288px;
  margin-bottom: 30px;

  .media-upload-progress {
    padding: 20px;
    background: #FFF;
    border: 1px solid #DADFEA;
    border-radius: 4px;
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 1px dashed #5158CF;

      .media-upload-progress {
        display: none;
      }

      ${Icon} {
        background-image: url(${CDN_URL}/images/drop_image_purple.svg);
      }

      ${Placeholder} {
        opacity: 1;
      }
    `}

  ${props => props.src && !props.isDragging && css`
    background: none;
    border: none;
    height: auto;
    min-height: unset;
  `}
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;