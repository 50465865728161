import _ from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = {
  is_payment_success: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.UPDATE_PAYMENT_STATUS:
      return Object.assign({}, state, { is_payment_success: payload.data });
    case Types.RESET_REDUCER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
