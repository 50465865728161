import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';

import {
  convertDataAddLocal,
  convertDataCancelUpload,
  convertDataGiphyAndText,
  convertDataUploadSuccess,
  convertDataViewMore,
  getAttachmentIDFromUrl,
  initDataList,
  initLocalDataFromS3,
} from 'helpers/replyComment';
import LeaveAComment from 'shared/LeaveAComment';
import HighFive from 'assets/icons/icon-2-hand.svg';
import CommentReplyItem from 'shared/CommentReplyItem';
import { changeCalendarStartDate } from 'actions/calendar';
import DropZonePlaceholder from 'shared/DropZonePlaceholder';
import { getReplyMessage } from 'components/CommunityForum/helper';
import ChatInput, { TYPES as COMMENT_TYPES } from 'shared/ChatInput';
import { TOPICS, MEDIA_FILE_REJECTED_CONTENT } from 'constants/commonData';
import { getPresignedUploadUrlMultiple, pluralize } from 'utils/commonFunction';
import { getComments, addCommentForAssignment } from 'redux/assignment/assignment.actionCreators';
import { uploadMediaBackgroundRequest, uploadMediaBackgroundSuccess } from 'redux/upload-background/actions';

import * as S from './style';
import { getListReplies } from 'redux/task/task.actionCreators';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total_comments: 0,
      replyMessage: {},
      loading: false,
      listMediaUpload: [],
    };

    this.bodyRef = React.createRef();
    this.inputRef = React.createRef();
    this.addedComment = false;
    this.cancelAddComment = false;
  }

  componentDidMount() {
    const { getComments, assignmentId } = this.props;
    getComments(assignmentId, TOPICS.LOG_ACTIVITY).then(response => {
      const { data, total_comments } = response.data;
      this.setState({ list: initDataList(data), total_comments });
    });

    this.inputRef.current && this.inputRef.current.focus();

    window.addEventListener('beforeunload', this.windowBeforeUnloadCallback);
  }

  componentWillUnmount() {
    if (this.addedComment) {
      this.props.changeCalendarStartDate();
    }

    window.removeEventListener('beforeunload', this.windowBeforeUnloadCallback);
  }

  windowBeforeUnloadCallback = event => {
    const { list } = this.state;
    const uploading = _.filter(list, c => !!c.uploadConfigs);

    if (uploading.length) {
      if (event) {
        event.returnValue = 'Changes you made may not be saved.';
      }

      return 'Changes you made may not be saved.';
    }
  };

  addLocalCommentToList = (localComments, replyMessage) => {
    const { list, listMediaUpload } = this.state;
    const { uploadMediaBackgroundRequest } = this.props;

    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');

    const listMediaUploadConvert = listMediaUpload.concat(localComments.map(item => item._id));

    const ids = localComments.map(item => _.get(item, '_id', ''));
    if (uploadMediaBackgroundRequest) {
      uploadMediaBackgroundRequest(ids);
    }

    this.setState(
      {
        listMediaUpload: listMediaUploadConvert,
        list: isReply ? convertDataAddLocal(list, commentId, localComments) : list.concat(localComments),
      },
      () => {
        setTimeout(() => {
          if (!isReply) {
            this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
          }
        }, 300);
      },
    );
  };

  onSubmit = async (comment, callback) => {
    const { list, replyMessage, loading } = this.state;
    const { addCommentForAssignment, assignmentId, user } = this.props;
    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');
    const { type, data } = comment;

    if (type === COMMENT_TYPES.TEXT && !loading) {
      this.setState({ loading: true });
      const body = {
        item: isReply ? commentId : assignmentId,
        topic: isReply ? TOPICS.REPLY_COMMENT : TOPICS.LOG_ACTIVITY,
        content: data,
      };

      addCommentForAssignment(body)
        .then(response => {
          this.addedComment = true;
          const { data, total_comment } = response.data;
          const isCommentReply = _.get(data, 'topic', '') === 'comment';
          let newList = [];

          if (isCommentReply) {
            newList = convertDataGiphyAndText(list, data, commentId);
          } else {
            newList = [...list, data];
          }

          this.setState({ list: newList, total_comments: total_comment }, () => {
            if (!isCommentReply) {
              this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
            }
          });
          this.handleCancelReply();
          callback && callback();
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }

    if (type === COMMENT_TYPES.FILE) {
      const localComments = _.map(data, (item, index) => initLocalDataFromS3({ user, item, index, replyMessage }));
      this.addLocalCommentToList(localComments, replyMessage);
    }
    if (type !== COMMENT_TYPES.TEXT) {
      this.handleCancelReply();
    }
  };

  onDrop = files => {
    if (files.length && this.inputRef.current) {
      typeof this.inputRef.current.onDropFiles === 'function' && this.inputRef.current.onDropFiles(files);
    }
  };

  cancelUpload = (comment, error) => {
    if (!this.cancelAddComment) {
      const { replyMessage, _id = '' } = comment;
      const { list, listMediaUpload } = this.state;
      const { uploadMediaBackgroundSuccess } = this.props;
      if (uploadMediaBackgroundSuccess && _id) {
        uploadMediaBackgroundSuccess([_id]);
      }
      const listMediaUploadConvert = listMediaUpload.filter(item => item !== _id);
      this.setState({ listMediaUpload: listMediaUploadConvert });
      const isReply = !_.isEmpty(replyMessage);
      if (isReply) {
        this.setState({ list: convertDataCancelUpload(list, comment) });
      } else {
        this.setState({ list: _.filter(list, c => c._id !== comment._id) });
      }
    }
  };

  uploadSuccess = (item, responseData) => {
    const { assignmentId, addCommentForAssignment, uploadMediaBackgroundSuccess } = this.props;
    const { list } = this.state;
    const { replyMessage } = item;
    const commentId = _.get(replyMessage, 'commentId', '');
    const isReply = !_.isEmpty(replyMessage);
    const comment = isReply ? item : _.find(list, c => c._id === item._id);

    if (uploadMediaBackgroundSuccess && _.get(item, '_id', '')) {
      uploadMediaBackgroundSuccess([item._id]);
    }

    if (!comment || this.cancelAddComment) {
      return false;
    }

    if ((responseData || {}).status === 200) {
      const { attachment_type, attachment_height, attachment_width, uploadConfigs } = comment;
      const url = (uploadConfigs || {}).url || '';

      const body = {
        attachment: url,
        attachment_id: getAttachmentIDFromUrl(url),
        attachment_type,
        attachment_height,
        attachment_width,
        topic: isReply ? TOPICS.REPLY_COMMENT : TOPICS.LOG_ACTIVITY,
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : assignmentId,
      };

      addCommentForAssignment(body).then(response => {
        const { listMediaUpload, list } = this.state;
        this.addedComment = true;
        const { data, total_comment } = response.data;
        let listToUpdate = [];

        if (isReply) {
          listToUpdate = convertDataUploadSuccess(list, data, comment);
        } else {
          listToUpdate = _.map(list, item => (item._id === comment._id ? { ...data, loadingReply: false } : item));
        }

        const listMediaUploadConvert = listMediaUpload.filter(i => i !== item._id);

        this.setState(
          { listMediaUpload: listMediaUploadConvert, list: listToUpdate, total_comments: total_comment },
          () => {
            this.inputRef.current && this.inputRef.current.focus();
            if (!isReply) {
              setTimeout(() => {
                this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
              }, 300);
            }
          },
        );
      });
    }
  };

  renderHighFivesName = list => {
    const { user } = this.props;
    const personName = list.map(item => (item._id === user._id ? 'You' : item.first_name));

    if (personName.length === 2) {
      const first = personName[0];
      const last = personName[1];
      return (
        <>
          <span>{first}</span> and <span>{last}</span>
        </>
      );
    }

    if (personName.length > 2) {
      const firstTwo = personName.slice(0, 2);
      if (personName.length === 3) {
        const last = personName.slice(-1);
        return (
          <>
            <span>{firstTwo.join(', ')}</span> and <span>{last}</span>
          </>
        );
      }

      const others = list.map(item => (item._id === user._id ? 'You' : item.full_name)).slice(2);
      return (
        <>
          <span>{firstTwo.join(', ')}</span> and{' '}
          <span data-tip data-for="high-five-tooltip" className="others-name">
            {others.length} others
          </span>
          <ReactTooltip
            id="high-five-tooltip"
            effect="solid"
            place={'bottom'}
            delayShow={500}
            className="high-five-tooltip"
            show
          >
            {others.map(item => (
              <p>{item}</p>
            ))}
          </ReactTooltip>
        </>
      );
    }

    return <span>{personName.join(', ')}</span>;
  };

  onGifClick = gif => {
    const { list, replyMessage } = this.state;
    const { addCommentForAssignment, assignmentId } = this.props;
    const url = _.get(gif, 'images.original.url');
    const id = _.get(gif, 'id');
    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');

    if (!_.isEmpty(url) && !_.isEmpty(id)) {
      const body = {
        topic: isReply ? TOPICS.REPLY_COMMENT : TOPICS.LOG_ACTIVITY,
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : assignmentId,
        attachment: url,
        attachment_id: id,
        attachment_type: 'image/gif',
        attachment_source: 'giphy',
      };

      let newList = [];

      this.handleCancelReply();

      addCommentForAssignment(body).then(response => {
        this.addedComment = true;
        const { data, total_comment } = response.data;
        const isCommentReply = _.get(data, 'topic', '') === 'comment';

        if (isCommentReply) {
          newList = convertDataGiphyAndText(list, data, commentId);
        } else {
          newList = [...list, data];
        }

        this.setState({ list: newList, total_comments: total_comment }, () => {
          this.inputRef.current && this.inputRef.current.focus();

          if (!isCommentReply) {
            this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
          }
        });
      });
    }
  };

  handleGetListViewMore = params => {
    const { getListReplies } = this.props;
    const { list } = this.state;

    getListReplies &&
      getListReplies(params).then(res => {
        const data = _.get(res, 'data.data', []).reverse();
        this.setState({ list: convertDataViewMore(list, params, data) });
      });
  };

  handleReplyMessage = data => () => {
    const { user } = this.props;
    const newData = { ...data, userId: _.get(user, '_id', '') };
    const { type, content, owner, commentId } = getReplyMessage(newData);

    this.inputRef.current && this.inputRef.current.focus();

    this.setState({
      replyMessage: {
        type,
        content,
        owner,
        commentId,
      },
    });
  };

  handleCancelReply = () => {
    this.setState({
      replyMessage: {},
    });
  };

  render() {
    const { list, total_comments, replyMessage } = this.state;
    const { user, highFives = [], handleClickHighFive, assignmentId, isHighFive } = this.props;

    return (
      <S.Wrapper>
        <Dropzone onDrop={this.onDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <S.Container {...getRootProps()} empty={!list.length}>
                <S.Header>{`Comments${total_comments ? ` (${total_comments})` : ''}`}</S.Header>
                {highFives.length ? (
                  <S.HighFiveMessage>
                    {this.renderHighFivesName(highFives)}
                    {highFives.length === 1 ? ' gave a high' : ' gave high'} {pluralize('five', highFives.length)} 🙌
                  </S.HighFiveMessage>
                ) : null}
                <S.Body ref={this.bodyRef}>
                  {list && list.length > 0 ? (
                    <S.ListCommentWrapper>
                      {_.map(list, item => {
                        const { latestReplyList, _id = '' } = item;
                        return (
                          <CommentReplyItem
                            key={_id}
                            item={item}
                            onReplyMessage={this.handleReplyMessage}
                            showAttachItem={true}
                            onUploadSuccess={this.uploadSuccess}
                            onUploadFail={data => {}}
                            onCancelUpload={_.debounce(this.cancelUpload, 300)}
                            onGetListViewMore={this.handleGetListViewMore}
                            replyList={latestReplyList}
                          />
                        );
                      })}
                    </S.ListCommentWrapper>
                  ) : (
                    <LeaveAComment />
                  )}
                </S.Body>
                <S.Footer>
                  {!isHighFive ? (
                    <S.HighFiveWrapper
                      data-for="high-five"
                      data-tip="Send a high five"
                      onClick={() => handleClickHighFive(assignmentId)}
                    >
                      <img src={HighFive} width={18} />
                      <ReactTooltip id="high-five" place="top" effect="solid" className="app-tooltip" />
                    </S.HighFiveWrapper>
                  ) : null}
                  <ChatInput
                    placeholder={!_.isEmpty(replyMessage) ? 'Write a reply...' : 'Add a comment..'}
                    onPost={this.onSubmit}
                    userId={user._id}
                    topic={TOPICS.LOG_ACTIVITY}
                    ref={this.inputRef}
                    autoFocus={true}
                    giphyEnable={true}
                    onGifClick={this.onGifClick}
                    replyMessage={replyMessage}
                    onCancelReply={this.handleCancelReply}
                  />
                </S.Footer>
                <input {...getInputProps({ onClick: event => event.preventDefault() })} />
                <DropZonePlaceholder show={isDragActive} />
              </S.Container>
            );
          }}
        </Dropzone>
      </S.Wrapper>
    );
  }
}

const mapState = state => ({ user: state.user });

const mapDispatchToProps = dispatch => ({
  getComments: bindActionCreators(getComments, dispatch),
  addCommentForAssignment: bindActionCreators(addCommentForAssignment, dispatch),
  changeCalendarStartDate: bindActionCreators(changeCalendarStartDate, dispatch),
  getListReplies: bindActionCreators(getListReplies, dispatch),
  uploadMediaBackgroundRequest: bindActionCreators(uploadMediaBackgroundRequest, dispatch),
  uploadMediaBackgroundSuccess: bindActionCreators(uploadMediaBackgroundSuccess, dispatch),
});

export default connect(mapState, mapDispatchToProps)(Component);
