import styled, { css } from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';

export const Wrapper = styled.div`
  max-width: 990px;
  background: #fafbfc;
  margin: 0 auto;
`;

export const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 333px auto;
  grid-gap: 20px;
`;

export const DetailWrapper = styled.div``;

export const AssetWrapper = styled.div``;

export const DropdownWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  padding: 25px 20px 35px 20px;
  margin-bottom: 15px;
  ${props =>
    !props.isEdit &&
    css`
      padding: 25px 20px 25px 20px;
    `}

  .rootOptions {
    .common-select-container__menu {
      z-index: 2;
    }
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  .product-tooltip {
    margin-left: 10px;
  }
  .__react_component_tooltip {
    padding: 15px 15px;
  }
`;

export const DropdownTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
`;

export const DropdownReadOnly = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
`;

export const TextBold = styled.strong`
  font-weight: 700;
`;
export const ExitConfirm = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    .confirm-header-image {
      width: 25px;
      height: 25px;
    }
    .confirm-content-body {
      padding: 18px 30px 30px 30px;
    }
    &.custom {
      .confirm-yes-button {
        background-color: #5158cf;
        min-width: 90px;
        box-shadow: none;
        &:hover {
          background-color: rgba(81, 88, 207, 0.9) !important;
        }
      }
    }

    &.delete {
      .confirm-yes-button {
        min-width: 100px;
      }
    }

    &.publish-confirm {
      .confirm-yes-button {
        min-width: 179px;
      }
    }
  }

  .confirm-content-header {
    padding: 30px 30px 0 30px !important;
    margin-bottom: -3px;
  }
`;

export const CustomPopupMessage = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  margin-top: 15px;
  padding-right: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ArchiveClientLabel = styled.span`
  color: #151619;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

export const ArchiveClientArea = styled.div`
  margin-top: 16px;

  .unit-client-toggle {
    min-width: 28px;
    min-height: 16px;
    line-height: 13px;

    input {
      width: 28px;
      height: 16px;
      line-height: 16px;

      &:checked ~ label::after,
      &checked:focus ~ label::after {
        left: calc(28px - 14px);
      }

      &:checked ~ label::before,
      &checked:focus ~ label::before {
        background-color: #36ba7b;
      }
    }

    label {
      min-width: 28px;
      min-height: 16px;

      &:before {
        width: 28px;
        height: 16px;
        background-color: #e0e1e4;
      }

      &:after {
        width: 12px;
        height: 12px;
      }
    }
  }

  ${ArchiveClientLabel} {
    margin-right: 16px;
  }
`;
