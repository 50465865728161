import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
