import { fromJS, Map, List } from 'immutable';
import { Types } from './actions';
import { Types as ModelTypes } from 'redux/model/actions';
import { Types as SectionLibraryTypes } from 'actions/sectionLibrary';
import { DEFAULT_FILTERS } from 'shared/ExerciseFilterPopup';
import { Mixpanel } from 'utils/mixplanel';

const INITIAL_STATE = Map({
  selectedWorkout: new Map({ sections: new List([]) }),
  draggingItem: null,
  draggingItemLeft: null,
  showError: false,
  allExercises: [],
  exerciseTab: fromJS({
    textSearch: '',
    list: [],
    filters: DEFAULT_FILTERS,
    page: 1,
    total: 0,
    searching: false,
  }),
  sectionTab: fromJS({
    textSearch: '',
    list: [],
    page: 1,
    total: 0,
  }),
  workoutSchedule: new Map({}),
  isChanged: false,
  droppedExercise: '',
  originalWorkout: null,
  isUploading: false,
  hasUpdateFromStandardBuilder: false,
  aiTab: '',
  tempWorkoutAI: null,
});

const logEditWorkoutGeneratedFromAI = page => {
  if (page) {
    Mixpanel.track('edit_ai_workout_builder', { component: page });
  }
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.WORKOUT_BUILDER_INIT_WORKOUT_DATA:
      return state.merge({ selectedWorkout: payload.data, originalWorkout: payload.originalData });

    case Types.UPDATE_TEMP_WORKOUT_AI:
      return state.merge({ tempWorkoutAI: payload.data });

    case Types.WORKOUT_BUILDER_RESET_ERROR_REDUX:
      return state.merge({ showError: false });

    case Types.WORKOUT_BUILDER_UPDATE_TITLE:
      return state.setIn(['selectedWorkout', 'title'], payload.data).set('isChanged', true);

    case Types.WORKOUT_BUILDER_UPDATE_GENERATE_WORKOUT_AI_FROM_PAGE:
      return state.setIn(['selectedWorkout', 'generatedWorkoutAIFromPage'], payload);

    case Types.WORKOUT_BUILDER_UPDATE_HIDE_WORKOUT:
      const { selectedWorkout } = state.toJS();
      if (selectedWorkout.workout_settings) {
        selectedWorkout.workout_settings.hide_workout = payload.data.hide_workout;
      } else {
        selectedWorkout.workout_settings = payload.data;
      }
      return state.merge({ selectedWorkout: fromJS(selectedWorkout) });

    case Types.WORKOUT_BUILDER_UPDATE_DESCRIPTION:
      logEditWorkoutGeneratedFromAI(state.getIn(['selectedWorkout', 'generatedWorkoutAIFromPage']));
      return state.setIn(['selectedWorkout', 'description'], payload.data).set('isChanged', true);

    case Types.WORKOUT_BUILDER_UPDATE_BACKGROUND:
      logEditWorkoutGeneratedFromAI(state.getIn(['selectedWorkout', 'generatedWorkoutAIFromPage']));
      return state.setIn(['selectedWorkout', 'background'], payload.data).set('isChanged', true);

    case Types.WORKOUT_BUILDER_UPDATE_TAGS:
      logEditWorkoutGeneratedFromAI(state.getIn(['selectedWorkout', 'generatedWorkoutAIFromPage']));
      return state.setIn(['selectedWorkout', 'tags'], fromJS(payload.data)).set('isChanged', true);

    case Types.WORKOUT_BUILDER_UPDATE_SECTIONS:
      logEditWorkoutGeneratedFromAI(state.getIn(['selectedWorkout', 'generatedWorkoutAIFromPage']));
      return state
        .setIn(['selectedWorkout', 'sections'], fromJS(payload.data))
        .set('isChanged', true)
        .set('showError', false);

    case Types.WORKOUT_BUILDER_UPDATE_ID_AFTER_ADDING:
      return state.setIn(['selectedWorkout', '_id'], fromJS(payload.data)).set('isChanged', false);

    case Types.WORKOUT_BUILDER_UPDATE_PARTIAL_DATA:
      return state.mergeIn(['selectedWorkout'], fromJS(payload.data)).set('isChanged', true);

    case Types.WORKOUT_BUILDER_SET_DRAG_ITEM:
      return state.merge({ draggingItem: payload.data });

    case Types.WORKOUT_BUILDER_SET_DRAG_LEFT_PANEL_ITEM:
      return state.merge({ draggingItemLeft: payload.data });

    case Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB:
      return state.merge({ exerciseTab: payload.data });

    case Types.WORKOUT_BUILDER_UPDATE_SECTION_TAB:
      return state.merge({ sectionTab: payload.data });

    case Types.WORKOUT_BUILDER_UPDATE_WORKOUT_SCHEDULE:
      return state.merge({ workoutSchedule: payload.data });

    case Types.WORKOUT_BUILDER_SHOW_ERROR:
      return state.merge({ showError: true });

    case Types.WORKOUT_UPDATE_ALL_EXERCISES:
      return state.merge({ allExercises: payload.data });

    case Types.WORKOUT_BUILDER_CLEAR_DATA:
      const { allExercises = [] } = state.toJS();
      return INITIAL_STATE.merge({ allExercises });

    case Types.ADD_NEW_EXERCISE_TO_ALL_LIST: {
      const { allExercises = [] } = state.toJS();
      allExercises.push(payload.exercise);
      return state.merge({ allExercises });
    }

    case Types.WORKOUT_BUILDER_SET_JUST_DROPPED_EXERCISE:
      return state.set('droppedExercise', payload.data);

    case Types.WORKOUT_BUILDER_UPDATE_ORIGINAL_WORKOUT:
      return state.merge({ originalWorkout: payload.data }).set('isChanged', false);

    case Types.WORKOUT_BUILDER_UPLOAD_ATTACHMENT_REQUEST:
      return state.set('isUploading', true);

    case Types.WORKOUT_BUILDER_UPLOAD_ATTACHMENT_SUCCESS:
      return state.set('isUploading', false);

    case ModelTypes.UPDATE_ENTITY: {
      // tracking when update entities wk from AI
      logEditWorkoutGeneratedFromAI(state.getIn(['selectedWorkout', 'generatedWorkoutAIFromPage']));
      return state.set('isChanged', action.isChanged);
    }
    case ModelTypes.SET_ENTITIES: {
      // tracking when update entities wk from AI
      logEditWorkoutGeneratedFromAI(state.getIn(['selectedWorkout', 'generatedWorkoutAIFromPage']));
      return state;
    }
    case ModelTypes.DELETE_ENTITY:
    case ModelTypes.DELETE_ENTITIES:
    case ModelTypes.UPDATE_ENTITIES: {
      logEditWorkoutGeneratedFromAI(state.getIn(['selectedWorkout', 'generatedWorkoutAIFromPage']));
      const { hasUpdateFromStandardBuilder = true } = payload;
      return state.set('isChanged', true).set('hasUpdateFromStandardBuilder', hasUpdateFromStandardBuilder); // TODO
    }

    case Types.RESET_DISCARD_CHANGE:
      return state.set('isChanged', false);

    case SectionLibraryTypes.SUCCESS_DELETE_SECTION: {
      return state.setIn(
        ['sectionTab', 'list'],
        state.getIn(['sectionTab', 'list']).filterNot(item => item.get('_id') === payload.sectionId),
      );
    }

    case Types.WORKOUT_BUILDER_UPDATE_TAB:
      return state.merge({ aiTab: payload.data });

    case Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_REQUEST:
      return state.setIn(['exerciseTab', 'searching'], true).setIn(['exerciseTab', 'textSearch'], payload.textSearch);
    case Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_SUCCESS:
      return state.merge({ exerciseTab: { ...payload.data, searching: false } });
    case Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_FAILURE:
      return state.setIn(['exerciseTab', 'searching'], false);

    default:
      return state;
  }
};
