import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Trigger = styled.div`
  width: ${props => `${(props.size && props.size.width) || 38}px`};
  height: ${props => `${(props.size && props.size.height) || 38}px`};
  border: 1px solid #f0f2f7;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgb(234, 235, 239);
  position: relative;
  overflow: hidden;

  :hover {
    border-color: #5158cf;
    cursor: pointer;
  }

  ${props =>
    props.isVideo &&
    css`
      :before {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 4px;
        z-index: 1;
        background: transparent url(${CDN_URL}/images/video_play.svg) no-repeat center center;
        width: 14px;
        height: 14px;
        width: ${props => `${(props.indicatorSize && props.indicatorSize.width) || 14}px`};
        height: ${props => `${(props.indicatorSize && props.indicatorSize.height) || 14}px`};
        background-size: contain;
      }
    `}

  ${props =>
    !props.thumbnail &&
    css`
      img {
        object-fit: auto;
      }
    `}

  ${props =>
    props.noData &&
    css`
      background: url(${props => props.thumbnail || props.defaultThumbnail}) center center no-repeat rgb(234, 235, 239);
    `}
`;

export const MediaContainer = styled.div`
  width: 272px;
  height: 153px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;

  &.media-preview__content--empty {
    background: #f3f3f3 url(${CDN_URL}/images/exercise_overview_no_picture.svg) no-repeat center center;
    background-size: auto;
  }
`;

export const PopupContent = styled.div`
  padding: 10px;
  background: #fff;
  position: relative;
  background: #ffffff;
  border: 1px solid #d4d4d5;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const PopupContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 9999;

  :before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid transparent;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 1;
    width: 8px;
    height: 8px;
    background-color: #fff;
  }
`;

export const Wrapper = styled.div`
  &.media-preview.disabled-action {
    cursor: not-allowed;
    .media-preview__trigger {
      pointer-events: none;
    }
  }

  &.media-preview--open {
    ${PopupContainer} {
      visibility: visible;
      opacity: 1;
    }

    ${Trigger} {
      border-color: #5158cf;
    }
  }

  &.top {
    ${PopupContainer} {
      :before {
        bottom: -3px;
        border-right-color: #d4d4d5;
        border-bottom-color: #d4d4d5;
      }
    }
  }

  &.bottom {
    ${PopupContainer} {
      :before {
        top: -3px;
        border-left-color: #d4d4d5;
        border-top-color: #d4d4d5;
      }
    }
  }
`;
