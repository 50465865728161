import _ from 'lodash';
import { fromJS, List } from 'immutable';
import { Types } from './actions';
import { IN_APP_AUTO_MESSAGE } from 'constants/commonData';

const INITITAL_STATE = fromJS({ messages: {}, copying: null });

export default (state = INITITAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.AUTOFLOW_INTERVAL_SUCCESS_GET_AUTO_MESSAGES:
      return state.set('messages', fromJS(payload.data));

    case Types.AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_SINGLE_DAY:
    case Types.AUTOFLOW_INTERVAL_UPDATE_MESSAGE_OF_MULTIPLE_DAYS:
      return state.mergeIn(['messages'], payload.data);

    case Types.AUTOFLOW_INTERVAL_SUCCESS_UPDATE_MESSAGE_DETAIL: {
      return state.mergeIn(['messages'], payload.data);
    }

    case Types.AUTOFLOW_INTERVAL_COPY_AUTO_MESSAGE:
      return state.set('copying', fromJS(payload.data));

    case Types.AUTOFLOW_INTERVAL_AUTO_MESSAGE_CLEAR_COPY_ITEM:
      return state.set('copying', null);

    case Types.AUTOFLOW_INTERVAL_ARRANGE_MESSAGE_ON_LOCAL: {
      const { data } = payload;
      const dayMessages = state.getIn(['messages', `${data.weekIndex}_${data.dayIndex}`]);

      if (!dayMessages || !dayMessages.size || !dayMessages.get(data.oldIndex)) {
        return state;
      }

      const message = dayMessages.get(data.oldIndex);

      if (message.get('type') === IN_APP_AUTO_MESSAGE) {
        return state;
      }

      const newDayMessages = dayMessages.splice(data.oldIndex, 1).splice(data.newIndex, 0, message);

      return state.setIn(['messages', `${data.weekIndex}_${data.dayIndex}`], newDayMessages);
    }

    case Types.AUTOFLOW_INTERVAL_MOVE_MESSAGE_ON_LOCAL: {
      const { data } = payload;
      const toDayId = `${data.newWeekIndex}_${data.newDayIndex}`;
      const fromDayId = `${data.oldWeekIndex}_${data.oldDayIndex}`;
      const toDayMessages = state.getIn(['messages', toDayId]);
      const fromDayMessages = state.getIn(['messages', fromDayId]);

      if (!fromDayMessages || !fromDayMessages.size || !fromDayMessages.get(data.oldIndex)) {
        return state;
      }

      const message = fromDayMessages
        .get(data.oldIndex)
        .mergeDeep({ dayIndex: Number(data.newDayIndex), weekIndex: Number(data.newWeekIndex) });

      return state
        .setIn(['messages', toDayId], toDayMessages ? toDayMessages.splice(data.newIndex, 0, message) : List([message]))
        .setIn(['messages', fromDayId], fromDayMessages.splice(data.oldIndex, 1));
    }

    case Types.AUTOFLOW_INTERVAL_AUTO_MESSAGE_RESET_DATA:
      return INITITAL_STATE;

    default:
      return state;
  }
};
