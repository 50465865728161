import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  transform: translateX(${({ isOverview }) => (isOverview ? '0' : '-20')}px);

  ${({ minHeight = 300 }) => css`
    .recharts-responsive-container {
      min-height: ${minHeight}px;
    }
  `}

  ${({ color }) => css`
    .recharts-active-bar {
      stroke: ${color};
      stroke-opacity: 0.2;
      stroke-width: 5px;
      cursor: pointer;
    }
  `}

  .recharts-responsive-container {
    /* &.chart-line .xAxis .recharts-cartesian-axis-ticks .recharts-layer.recharts-cartesian-axis-tick:last-child text {
      transform: translateX(-16px);
    } */

    line.recharts-cartesian-axis-tick-line,
    .recharts-layer.xAxis .recharts-cartesian-axis-line {
      stroke: #e1e1ea;
    }

    &.no-data .yAxis {
      display: none;
    }

    path.recharts-curve.recharts-tooltip-cursor {
      display: none;
    }

    .recharts-surface {
      cursor: default !important;
      overflow: visible;
    }

    text {
      color: #7b7e91;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 13.5px;
      text-transform: uppercase;
    }

    .yAxis text {
      text-transform: lowercase;

      &.recharts-label {
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
  }

  .recharts-active-dot {
    display: none;
  }

  .recharts-tooltip-wrapper {
    z-index: 2;
    @keyframes delayedShow {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
        visibility: visible;
      }
    }
    &.show-my-tooltip {
      animation: 0.2s linear 0.3s forwards delayedShow;
      transform: unset !important;
      transition: unset !important;
    }
  }

  .one-point {
    .recharts-xAxis.xAxis .recharts-layer.recharts-cartesian-axis-tick:not(:nth-child(4)) {
      display: none;
    }
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(38, 38, 38, 0.2);
  min-width: 106px;
  max-height: 93px;
  font-family: 'Open Sans';
  color: #7b7e91;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;

  &.has-change {
    min-width: 136px;
    width: max-content;
  }

  h6,
  p {
    margin: 0;
  }

  p {
    color: #202353;
  }

  .tooltip-value {
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;
  }

  .value-change {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;

    .from-time {
      font-size: 11px;
      line-height: 18px;
      font-weight: 400;
    }
  }
`;

export const RangeTimeLabel = styled.span`
  ${textBase}
  color: #7B7E91;
  font-size: 12px;
`;

export const UnitValue = styled.span`
  ${textBase}
  color: #202353;
  font-size: 15px;
  padding-bottom: 2px;
`;

export const UnitStyle = styled.span`
  ${textBase}
  color: #202353;
  font-size: 12px;
  align-self: center;
`;
