import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconSave } from 'assets/icons/icon-save.svg';
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg';
import { ReactComponent as IconEnd } from 'assets/icons/icon-end.svg';
import { connect } from 'react-redux';
import { Dropdown, Icon, Modal, Tab } from 'semantic-ui-react';
import Insight from './components/Insight';
import Overview from './components/Overview';
import TaskComment from './components/TaskComment';
import { toggleConfirmModal } from 'actions/modal';
import { removeHabit, saveHabitToLibrary, endHabit } from 'redux/habits/actions';
import { CDN_URL, KEY_CODE } from 'constants/commonData';

import * as S from './style';

const HabitDetail = ({
  onClose,
  prevHabit,
  toggleConfirmModal,
  removeCurrentHabit,
  saveHabitToLibrary,
  endCurrentHabit,
}) => {
  const contentRef = useRef();
  const [tabActive, setTabActive] = useState(1);
  const [open, setOpen] = useState(false);
  const [habit, setHabit] = useState(prevHabit);
  const [uploadedAllComment, setUploadedAllComment] = useState(true);
  const panes = [
    {
      menuItem: 'Habit',
      render: () => (
        <Tab.Pane attached={false}>
          <Overview
            prevHabit={prevHabit}
            habit={habit}
            setHabit={setHabit}
            open={open}
            setOpen={setOpen}
            ref={contentRef}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Insight',
      render: () => (
        <Tab.Pane attached={false}>
          <Insight data={habit} />
        </Tab.Pane>
      ),
    },
  ];
  const params = new URLSearchParams(window.location.search);
  const openCommentTab = _.isEqual(params.get('comment'), 'show');

  const handleSaveToLibraryHabit = event => {
    event.preventDefault();
    saveHabitToLibrary(habit._id);
  };

  const handleEndHabit = () => {
    endCurrentHabit &&
      endCurrentHabit(habit._id, () => {
        onClose && onClose();
      });
  };

  const handleConfirmEndHabit = event => {
    event.preventDefault();
    toggleConfirmModal(
      true,
      <S.HabitConfirmModal
        noIcon
        title="End Habit"
        content="If you end this habit, all upcoming occurrences today onwards will be removed. Would you like to continue?"
        confirmButtonTitle="End habit"
        onConfirm={handleEndHabit}
        noBorder
        hasHoverState
      />,
    );
  };

  const handleRemoveHabit = () => {
    removeCurrentHabit && removeCurrentHabit(habit._id);
    onClose && onClose();
  };

  const handleConfirmDeleteHabit = event => {
    event.preventDefault();
    toggleConfirmModal(
      true,
      <S.HabitConfirmModal
        noIcon
        title="Delete Habit"
        content="If you delete this habit, all occurrences of this habit (in the past and future) and insights will be permanently lost. Would you like to continue?"
        confirmButtonTitle="Delete habit entirely"
        onConfirm={handleRemoveHabit}
        noBorder
        hasHoverState
      />,
    );
  };

  const handleChangeTabs = (_, { activeIndex }) => setTabActive(activeIndex);

  const handleCloseAction = () => {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy || open) return null;
    if (!uploadedAllComment) {
      return toggleConfirmModal(
        true,
        <S.HabitConfirmModal
          noIcon
          title="Upload in progress"
          content="If you close the pop-up, all incomplete uploads will be discarded. Would you like to exit without finishing?"
          onConfirm={onClose}
          newStyle={true}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          noBorder
          hasHoverState
        />,
      );
    }

    const isSame =
      contentRef.current &&
      contentRef.current.wrappedInstance.isSameOriginData &&
      contentRef.current.wrappedInstance.isSameOriginData(habit);
    if (isSame === false) {
      return toggleConfirmModal(
        true,
        <S.HabitConfirmModal
          noIcon
          title="Discard Changes?"
          content={
            _.isEmpty(habit)
              ? 'Are you sure you want to go? Changes have not been saved yet.'
              : 'You have unsaved changes. Would you like to leave this page and discard your changes?'
          }
          confirmButtonTitle="Discard Changes"
          onConfirm={() => onClose && onClose()}
          noBorder
          hasHoverState
        />,
      );
    }

    onClose && onClose();
  };

  return (
    <S.TaskModal size={'tiny'} open={true} closeOnDimmerClick={false} onClose={handleCloseAction}>
      <S.TaskModalContainer>
        <S.TaskForm>
          <Modal.Header className="modal__header">
            <button className="close-button" onClick={handleCloseAction}>
              <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
            </button>
          </Modal.Header>
          <S.Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            onTabChange={handleChangeTabs}
            activeIndex={tabActive}
          />
          <Dropdown
            text={<Icon name={'ellipsis horizontal'} color={'grey'} className="icon-more-action" />}
            pointing
            className="link item"
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleSaveToLibraryHabit}>
                <IconSave />
                Save to Library
              </Dropdown.Item>
              {!habit.has_ended && (
                <Dropdown.Item onClick={handleConfirmEndHabit}>
                  <IconEnd className="icon-end" />
                  End Habit
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={handleConfirmDeleteHabit}>
                <IconTrash />
                Delete Habit
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </S.TaskForm>
        {_.get(habit, 'enable_comment', false) && (
          <TaskComment
            task={habit}
            openCommentTab={openCommentTab}
            onChangeUploadStatus={noUploadedComments => setUploadedAllComment(!noUploadedComments.length)}
          />
        )}
      </S.TaskModalContainer>
    </S.TaskModal>
  );
};

const mapStateToProps = state => ({
  selectedClient: _.get(state.rootReducer.client.workingClientDetail, '_id'),
});

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeCurrentHabit: params => dispatch(removeHabit(params)),
  saveHabitToLibrary: params => dispatch(saveHabitToLibrary(params)),
  endCurrentHabit: bindActionCreators(endHabit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HabitDetail);
