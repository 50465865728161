import React, { useState } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { Radio } from 'semantic-ui-react';
import { map, debounce, get } from 'lodash';
import { toast } from 'react-toastify';
import { Mixpanel } from 'utils/mixplanel';
// import components
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { Button } from 'shared/FormControl';
import { FormGroup } from 'shared/FormControl';
import SelectForumOption, { OptionLabel } from './SelectForumOption';
import NoOptionsMessage from './NoOptionsMessage';
// import actions
import { getListForum } from 'actions/forum';
import { toggleModal } from 'actions/modal';
// import assets
import PrefixImg from 'assets/images/forum-search-input-prefix.png';
// import constant
import { CDN_URL, CONNECT_FORUM_TYPES } from 'constants/commonData';
import { LeaderboardModal } from './style';
import connect from 'react-redux/lib/connect/connect';

const ConnectForumModal = ({ onClose, getListForum, autoflowConnectForum, autoflowId, numberClients, toggleModal }) => {
  const [selectedForum, setSelectedForum] = useState(null);
  const [selectedType, setSelectedType] = useState(CONNECT_FORUM_TYPES.ACTIVE);

  const onSubmit = e => {
    e.preventDefault();
    if (selectedType === CONNECT_FORUM_TYPES.ACTIVE) {
      Mixpanel.track('autoflow_leaderboard_screen_invite_active_client');
    } else if (selectedType === CONNECT_FORUM_TYPES.ALL) {
      Mixpanel.track('autoflow_leaderboard_screen_invite_all_client');
    } else {
      Mixpanel.track('autoflow_leaderboard_screen_invite_no_client');
    }
    autoflowConnectForum(autoflowId, selectedForum._id, selectedType).then(response => {
      if (get(response, 'data.data.errorCode') === 'FORUM_CONNECTED') {
        toast.error(
          <>
            <h4>Forum already connected</h4>
            {get(response, 'data.data.conntectedAutoflow.name') && (
              <div>{`This forum is already connected to Autoflow ‘${get(
                response,
                'data.data.conntectedAutoflow.name',
              )}’`}</div>
            )}
          </>,
        );
      } else {
        toast(
          `The leaderboard has been connected to the forum ‘${selectedForum.name}’, and ${get(
            response,
            'data.data.addedClient',
          )} new clients have been added to the forum.`,
        );

        onClose();
      }
    });
  };

  const onChangeType = (e, { value }) => setSelectedType(value);

  const searchClient = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    getListForum(search)
      .then(response => {
        const { data } = response.data;
        if (data) {
          const titleOption = {
            key: 'title',
            value: `Most Recent (${data.length})`,
            isDisabled: true,
          };
          const listOptions = map(data, item => ({
            ...item,
            key: item._id,
            value: item._id,
            label: `${item.first_name} ${item.last_name}`,
          }));
          if (listOptions.length > 0) callback([titleOption, ...listOptions]);
          else callback([]);
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const searchClientDebounce = debounce(searchClient, 200);

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          <div style={{ position: 'absolute', padding: '10px 15px 10px 0', height: 65 }}>
            {!selectedForum && (
              <img
                src={PrefixImg}
                alt=""
                className="forum-select-prefix"
                style={{ width: 45, height: 45, objectFit: 'cover', borderRadius: 5 }}
              />
            )}
            {selectedForum && (
              <img
                src={selectedForum.banner || PrefixImg}
                alt=""
                className="forum-select-prefix"
                style={{ width: 45, height: 45, objectFit: 'cover', borderRadius: 5 }}
              />
            )}
          </div>
          {!selectedForum && children}
          {selectedForum && (
            <div style={{ paddingLeft: 45 }}>
              <OptionLabel data={selectedForum} />
            </div>
          )}
        </components.ValueContainer>
      )
    );
  };

  return (
    <LeaderboardModal
      className="modal--connect-forum"
      open={true}
      onClose={onClose}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <LeaderboardModal.Content>
        <form onSubmit={onSubmit}>
          <ModalLayout.Wrapper>
            <ModalLayout.Header className="modalLayout__header">Connect with forum</ModalLayout.Header>
            <ModalLayout.Content className="modalLayout__content">
              <FormGroup>
                <label>Choose forum to show leaderboard</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isClearable
                  components={{ ValueContainer, Option: SelectForumOption, NoOptionsMessage }}
                  styles={{
                    control: base => ({ ...base, minHeight: 67, height: 67 }),
                    menu: base => ({ ...base, marginTop: 2, marginBottom: 5 }),
                    valueContainer: base => ({
                      ...base,
                      padding: '0 10px',
                      height: 65,
                    }),
                    singleValue: base => ({
                      ...base,
                      left: 71,
                    }),
                    placeholder: base => ({
                      ...base,
                      left: 71,
                    }),
                    input: base => ({
                      ...base,
                      paddingLeft: 54,
                    }),
                  }}
                  loadOptions={searchClientDebounce}
                  value={selectedForum}
                  onChange={list => setSelectedForum(list)}
                  classNamePrefix="evfSelectBox"
                  className="evfSelectBoxContainer connectForum"
                  placeholder="Choose forum"
                  noOptionsMessage={() => 'No clients found.'}
                />
              </FormGroup>
              {selectedForum && (
                <div className="connect-forum-type">
                  <Radio
                    label={
                      <label>
                        Invite <b>only Active Autoflow Clients {numberClients && `(${numberClients.active})`}</b> to the
                        Forum
                      </label>
                    }
                    name="connectForumType"
                    value={CONNECT_FORUM_TYPES.ACTIVE}
                    checked={selectedType === CONNECT_FORUM_TYPES.ACTIVE}
                    onChange={onChangeType}
                  />
                  <Radio
                    label={
                      <label>
                        Invite <b>All Autoflow Clients {numberClients && `(${numberClients.total})`}</b> to the Forum
                      </label>
                    }
                    name="connectForumType"
                    value={CONNECT_FORUM_TYPES.ALL}
                    checked={selectedType === CONNECT_FORUM_TYPES.ALL}
                    onChange={onChangeType}
                  />
                  <Radio
                    label={<label>I don’t want to invite any clients</label>}
                    name="connectForumType"
                    value={CONNECT_FORUM_TYPES.NONE}
                    checked={selectedType === CONNECT_FORUM_TYPES.NONE}
                    onChange={onChangeType}
                  />
                </div>
              )}
            </ModalLayout.Content>
            <ModalLayout.Actions className="modalLayout__actions">
              <Button type="button" onClick={() => toggleModal(false)}>
                Cancel
              </Button>
              <Button type="submit" purple disabled={!selectedForum}>
                Confirm
              </Button>
            </ModalLayout.Actions>
          </ModalLayout.Wrapper>
        </form>
      </LeaderboardModal.Content>
    </LeaderboardModal>
  );
};

export default connect(null, { getListForum, toggleModal })(ConnectForumModal);
