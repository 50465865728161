import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

// components
import { Button } from 'shared/FormControl';

// actions
import { sendFeedback, sendFeedbackForExample } from 'components/WorkoutDetailModalAIDemo/actions';

// assets
import AIIconPng from 'assets/images/ai-msg-icon.png';
import { ReactComponent as ThumbDownIcon } from 'assets/icons/thumb-down.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/icons/thumb-up.svg';
// import { ReactComponent as RepeatIcon } from 'assets/icons/repeat-cycle.svg';
import { ReactComponent as FeedbackIcon } from 'assets/icons/feedback.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-ai.svg';
import { ReactComponent as AIBeta } from 'assets/icons/ai-beta.svg';
import repeatIconPath from 'assets/icons/repeat-cycle-white.svg';

import * as S from './style';
import { clearWorkoutData } from 'redux/workout-builder/actions';
import ConfirmModalGroup from 'components/MetricGroupLibrary/Parts/ConfirmModal';
import { toggleConfirmModal } from 'actions/modal';
import FeedbackModal from '../Feedback';
import { KEY_CODE } from 'constants/commonData';
import { Mixpanel } from 'utils/mixplanel';
// import { AI_JSON_EXAMPLE } from 'components/WorkoutDetailModalAIDemo/constants';

const ReactionStatus = {
  like: 'like',
  dislike: 'dislike',
  good: 'good',
};

const ConversionItem = ({
  item,
  sessionId,
  dispatch,
  // onRetry,
  onClearAll,
  scrollToEnd,
  workoutAIPage,
  gotoStandardBuilderTab = () => {},
  animatedCallback = () => {},
}) => {
  const {
    json_output,
    user_input,
    isMe,
    feedback,
    reaction,
    id,
    createdAt,
    isAnimated = false,
    isError = false,
    isAbleFeedback = true,
    isUseExample = false,
  } = item;
  const inputRef = createRef();
  const contentGenerateRef = createRef(null);
  const [showFb, setShowFB] = useState(false);
  const [fbText] = useState(feedback || '');
  const [, setIsSendingFB] = useState(false);
  const [responseText, setResponseText] = useState(isAnimated ? (json_output || {}).title || '' : '');
  const [isDoneTyping, setIsDoneTyping] = useState(false);
  const [dataFb, setDataFb] = useState({
    rating: [],
    reaction: reaction || '',
    detail: '',
  });

  const onSubmitFB = async (data, showToast = true, isShowFeedback = false) => {
    try {
      const body = { ...data };
      const isReactionBefore = dataFb.reaction === data.reaction;

      if (isReactionBefore) {
        body.reaction = '';
        setDataFb(s => ({ ...s, reaction: '' }));
      }

      if (isShowFeedback && !isReactionBefore && data.reaction === ReactionStatus.dislike) {
        setShowFB(true);
      }

      setIsSendingFB(true);

      if (isUseExample) {
        await dispatch(
          sendFeedbackForExample(
            {
              ...body,
              user_input: user_input || '',
              json_output: JSON.stringify(json_output),
              session_id: sessionId,
            },
            showToast,
          ),
        );
      } else {
        await dispatch(sendFeedback(id, body, showToast));
      }

      if (!isShowFeedback && fbText) {
        setShowFB(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendingFB(false);
    }
  };

  const handleReaction = status => () => {
    Mixpanel.track('reaction_ai_workout_builder', { component: workoutAIPage, reaction: status });

    const data = { ...dataFb, reaction: status };
    setDataFb(data);
    onSubmitFB(data, false, status === ReactionStatus.dislike);
  };

  const handleConfirmReset = () => {
    dispatch(clearWorkoutData());
    onClearAll();
  };

  const handleClickFeedback = () => {
    Mixpanel.track('open_feedback_ai_workout_builder', { component: workoutAIPage });
    setShowFB(true);
  };

  const handleReset = () => {
    Mixpanel.track('click_start_over_ai_workout_builder', { component: workoutAIPage });
    dispatch(
      toggleConfirmModal(
        true,
        <ConfirmModalGroup
          modalId="reset-session-modal"
          className="reset-modal"
          title="Create a new workout"
          content="Starting over will clear the original prompt and all exercise details. Would you like to continue?"
          onConfirm={handleConfirmReset}
          headerIcon={repeatIconPath}
          hasCloseIcon
          confirmButtonTitle="Start Over"
        />,
      ),
    );
  };

  // const onConfirmRetry = () => {
  //   onRetry(isUseExample ? JSON.parse(JSON.stringify(AI_JSON_EXAMPLE)) : user_input);
  // };

  // const handleRetryClick = () => {
  //   dispatch(
  //     toggleConfirmModal(
  //       true,
  //       <ConfirmModalGroup
  //         modalId="reset-session-modal"
  //         className="generate-confirm-modal"
  //         title="Regenerate workout"
  //         content="Are you sure to regenerate workout? It will replace the current workout."
  //         onConfirm={onConfirmRetry}
  //         headerIcon={repeatIconPath}
  //         hasCloseIcon
  //         confirmButtonTitle="Regenerate"
  //       />,
  //     ),
  //   );
  // };

  const timestamp = useMemo(() => {
    return createdAt ? moment(createdAt).format('hh:mm A') : '';
  }, []);

  useEffect(() => {
    if (showFb && inputRef.current) {
      inputRef.current.innerText = fbText;
      setTimeout(() => {
        const sel = window.getSelection();
        sel.selectAllChildren(inputRef.current);
        sel.collapseToEnd();
        scrollToEnd();
      }, 200);
    }
  }, [showFb]);

  useEffect(() => {
    if (!isMe && json_output.title && !isAnimated) {
      const words = json_output.title.split(' ');
      let i = 0;
      const typeWord = () => {
        if (i < words.length) {
          setResponseText(s => s + words[i] + ' ');
          i++;
          setTimeout(typeWord, 40); // Adjust typing speed here
        } else {
          setIsDoneTyping(true);
          animatedCallback(id);
        }
        scrollToEnd();
      };
      typeWord();
    } else {
      setIsDoneTyping(true);
    }
  }, [isMe, json_output, isAnimated]);

  useEffect(() => {
    if (isDoneTyping) {
      setTimeout(() => {
        const standardElm = document.querySelector('.standard-builder-text');
        if (standardElm) {
          standardElm.addEventListener('click', gotoStandardBuilderTab);
          return () => {
            standardElm.removeEventListener('click', gotoStandardBuilderTab);
          };
        }
      }, 100);
    }
  }, [isDoneTyping]);

  const renderTooltip = (key, label) => (
    <ReactTooltip
      className="app-tooltip ai-tooltip"
      effect="solid"
      id={`${key}-${id}`}
      place={'top'}
      delayShow={300}
      eventOff="mouseleave scroll mousewheel blur"
    >
      {label || key}
    </ReactTooltip>
  );

  const handleCopyText = () => {
    if (contentGenerateRef && contentGenerateRef.current) {
      const textToCopy = contentGenerateRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast('Copied text to clipboard.');
        })
        .catch(error => {
          console.error('Copy failed:', error);
        });
    }
  };

  const handleKeyDown = useCallback(event => {
    if (event.keyCode === KEY_CODE.esc) {
      setShowFB(false);
      dispatch(toggleConfirmModal(false));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <S.ConversationItem className={classNames({ me: isMe })}>
      {!isMe && (
        <div className="ai-header">
          <span className="avt">
            <img src={AIIconPng} alt="" className="ai-msg-icon" />
          </span>
          <h6>Everfit AI</h6>
          <AIBeta className="ai-beta" />
          <span className="timestamp">{timestamp}</span>
        </div>
      )}
      {isMe ? (
        <div className="message">
          <span className="copy-text-btn" data-for="button-copy-text-tooltip" data-tip onClick={handleCopyText}>
            <CopyIcon />
          </span>
          <ReactTooltip
            className="app-tooltip ai-tooltip"
            id="button-copy-text-tooltip"
            effect="solid"
            place="top"
            delayShow={100}
          >
            Copy
          </ReactTooltip>
          <div ref={contentGenerateRef} className="user-input">
            {user_input}
          </div>
          <span className="timestamp">{timestamp}</span>
        </div>
      ) : (
        <>
          <div className="message ai-msg" dangerouslySetInnerHTML={{ __html: responseText }} />
          {!!createdAt && isError && (
            <div className="actions">
              <div className="reactions">
                {/* <Button
                  className={classNames('like-btn', { active: dataFb.reaction === ReactionStatus.dislike })}
                  onClick={handleReaction(ReactionStatus.dislike)}
                  data-for={`Dislike-${id}`}
                  data-tip
                >
                  <ThumbDownIcon />
                </Button>
                {renderTooltip('Dislike')} */}
                {/* <Button onClick={handleRetryClick} data-for={`Regenerate-${id}`} data-tip>
                  <RepeatIcon />
                </Button> */}
                {/* {renderTooltip('Regenerate')} */}
                {isAbleFeedback && (
                  <Button className={classNames('feedback-btn', { active: showFb })} onClick={() => setShowFB(true)}>
                    <FeedbackIcon /> Feedback
                  </Button>
                )}
              </div>
              <Button className="ai-btn start-btn generate-btn" onClick={handleReset}>
                Start Over
              </Button>
            </div>
          )}
        </>
      )}
      {!isMe && !isError && isDoneTyping && (
        <div className="actions">
          <div className="reactions">
            <Button
              className={classNames('like-btn', { active: dataFb.reaction === ReactionStatus.like })}
              onClick={handleReaction(ReactionStatus.like)}
              data-for={`Like-${id}`}
              data-tip
            >
              <ThumbUpIcon />
            </Button>
            {renderTooltip('Like')}
            <Button
              className={classNames('like-btn', { active: dataFb.reaction === ReactionStatus.dislike })}
              onClick={handleReaction(ReactionStatus.dislike)}
              data-for={`Dislike-${id}`}
              data-tip
            >
              <ThumbDownIcon />
            </Button>
            {renderTooltip('Dislike')}
            <Button className={classNames('feedback-btn', { active: showFb })} onClick={handleClickFeedback}>
              <FeedbackIcon /> Feedback
            </Button>
          </div>
          <Button className="ai-btn start-btn generate-btn" onClick={handleReset}>
            Start Over
          </Button>
        </div>
      )}
      <FeedbackModal
        onClose={data => {
          setShowFB(false);
          setDataFb(data);
        }}
        workoutAIPage={workoutAIPage}
        conversation={item}
        sessionId={sessionId}
        open={showFb}
        dispatch={dispatch}
        respondId={isUseExample ? null : id}
        reaction={dataFb.reaction}
      />
    </S.ConversationItem>
  );
};

export default ConversionItem;
