import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .filter__dropdown {
    background-color: #f5f5f5;
    cursor: pointer;
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    color: #262626;
    &.active,
    :hover {
      background-color: #f4f4fc !important;
    }
    .filter__dropdown__title {
      .filter-name {
        width: 45px;
      }

      .colons {
        margin-left: 1px;
        margin-right: 4px;
        width: 4px;
      }

      .selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }
    }
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  margin-top: -1px;
  margin-left: -1px;
  z-index: 999;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  width: 130px;
  flex-direction: column;
  align-items: flex-start;

  .item {
    display: flex;
    width: 100%;
    padding: 8px 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    color: #595959;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    :first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    :last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &.active {
      font-weight: 600;
    }
    :hover,
    &.active {
      background: #f4f4fc;
      color: #5158cf;
    }
  }
`;
