import React from 'react';
import { get, map } from 'lodash';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import * as Layout from 'shared/LibraryLayout';
import { CoachColumnHeader } from './style';

const columns = [
  {
    name: 'Name',
    icon: <ProfileIcon />,
  },
  {
    name: 'Title',
  },
  {
    name: 'Biography',
  },
];

export default function Header() {
  return (
    <Layout.TableHeader>
      <Layout.TableRow>
        {map(columns, item => {
          return (
            <CoachColumnHeader>
              <Layout.TableHeaderCellContent>
                {item.icon && (
                  <div className="coach-icon-header">
                    <ProfileIcon />
                  </div>
                )}
                <Layout.CellTitle>{get(item, 'name', '').toUpperCase()}</Layout.CellTitle>
              </Layout.TableHeaderCellContent>
            </CoachColumnHeader>
          );
        })}
        <CoachColumnHeader />
      </Layout.TableRow>
    </Layout.TableHeader>
  );
}
