import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export const Item = styled.div`
  text-align: center;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  color: #202353;
  padding: 30px;
  cursor: pointer;
  position: relative;

  .img-container {
    height: 64px;
    margin-bottom: 35px;
  }

  img.ui {
    display: inline-block;
    margin: auto;
  }

  .text {
    margin: 0 10px;

    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #202353;
      margin-bottom: 7px;
    }
  }

  :hover {
    background: #fbfbff;
    border: 1px solid #5158cf;
    box-sizing: border-box;
  }

  .__react_component_tooltip.app-tooltip {
    padding: 15px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #ffffff;
    max-width: 230px;
    text-align: left;
  }
`;
