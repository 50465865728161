import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { isTeamAdmin } from 'utils/commonFunction';
import UpgradeYourPlanModal from './UpgradeYourPlanModal';
import { bindActionCreators } from 'redux';
import { updateSelectPackage } from 'redux/pricing/actions';
import { toggleModal } from 'actions/modal';
import { PLANS, TRIAL_MODE } from 'constants/commonData';
import { getLowestPackagePrice } from 'components/PricingPlan/helper';
import { togglePaymentInformation } from 'redux/billing/actions';
import { toast } from 'react-toastify';

const Button = styled.button`
  background: linear-gradient(76.71deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  padding: 12px 30px;
  border: none;
  cursor: pointer;
  outline: none;

  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
`;

function UpgradePathButton(props) {
  const {
    currentPackage,
    teamData,
    planWillSelect,
    planPricing,
    paymentAddOn,
    invoiceOverDue,
    togglePaymentInformation,
    isPreventDefault,
    isHasChildren = false,
    children = null,
  } = props;

  const handleCloseUpgradeModal = () => {
    props.toggleModal(false);
  };

  const handleClick = () => {
    if (isPreventDefault) {
      return;
    }
    const isAdmin = isTeamAdmin(props.user);
    const pathname = window.location.pathname;
    let search = window.location.search;
    if (search) {
      search = search.replace('?', '&');
    }
    const isTrialOrStarter =
      teamData.get('tier') === PLANS.starter.key || teamData.get('trialMode') !== TRIAL_MODE.Expired;
    const defaultAddOns = {
      automation: false,
      payment_package: false,
      resource_collections: false,
      premiumCustomBrand: false,
      zapier: false,
    };

    if (invoiceOverDue && pathname.includes('billing')) {
      handleCloseUpgradeModal();
      togglePaymentInformation(true);
    } else {
      if (isAdmin) {
        const path = props.notSendPrev ? '/pricing-plan' : `/pricing-plan?prev=${pathname}${search}`;
        props.dispatch(push(path));
        const isNavigateIntegration = path.includes('?prev=/home/integration');
        if (isNavigateIntegration) {
          toast(`Zapier integration is only available in Studio plan. The subscription was automatically modified to reflect
          the upgrade`);
        }
        if (props.notSendPrev && isTrialOrStarter) {
          return props.updateSelectPackage({
            addOns: {
              ...currentPackage.get('addOns').toJS(),
              automation: false,
            },
          });
        }
      } else {
        props.toggleModal(true, <UpgradeYourPlanModal onClose={handleCloseUpgradeModal} />);
      }

      if (props.onClick) {
        props.onClick();
      }

      if (pathname.includes('autoflow')) {
        return props.updateSelectPackage({
          addOns: {
            ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
            automation: true,
          },
        });
      }

      if (
        pathname.includes('packages') ||
        pathname.includes('payment-activities') ||
        pathname.includes('sequences') ||
        pathname.includes('marketplace')
      ) {
        const isPayment = paymentAddOn.toJS().find(it => it.code === 'payment_package') ? true : false;
        return props.updateSelectPackage({
          addOns: {
            ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
            payment_package: isPayment,
          },
        });
      }

      if (planWillSelect) {
        if (teamData.get('tier') !== PLANS.starter.key && planWillSelect.tier !== PLANS.studio.key) {
          planWillSelect.tier = teamData.get('tier');
        }

        const selectPackage = currentPackage.mergeDeep(planWillSelect).toJS();
        selectPackage.addOns = {
          ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
          ...planWillSelect.addOns,
        };
        selectPackage.packagePrice = getLowestPackagePrice(
          planPricing.toJS(),
          selectPackage.tier,
          teamData.get('noClients'),
          teamData.get('packageNoClients'),
        );
        props.updateSelectPackage(selectPackage);
      } else if (isTrialOrStarter) {
        props.updateSelectPackage({
          addOns: {
            ...currentPackage.get('addOns').toJS(),
            automation: false,
          },
        });
      }
    }
  };

  return (
    <>
      <Button {...props} onClick={handleClick}>
        {isHasChildren && children}
        {isHasChildren ? null : <span>{props.text || 'Get this feature'}</span>}
      </Button>
    </>
  );
}

const mapStateToProps = state => {
  const { user, rootReducer } = state;

  return {
    user,
    currentPackage: rootReducer.pricing.getIn(['selectPackage']),
    planPricing: rootReducer.pricing.getIn(['planPricing']),
    teamData: rootReducer.pricing.getIn(['teamData']),
    paymentAddOn: rootReducer.pricing.getIn(['addOnsPricing']),
    invoiceOverDue: rootReducer.billing.invoiceOverDue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    togglePaymentInformation: bindActionCreators(togglePaymentInformation, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePathButton);
