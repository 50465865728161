import React, { useState } from 'react';
import * as S from './style.js';
import _ from 'lodash';
import './style.scss';
import { toast } from 'react-toastify';
import { Button as CloseButton } from 'semantic-ui-react';
import { ReactComponent as CloseCircleIcon } from 'assets/icons/close_circle.svg';
import { pluralize } from 'utils/commonFunction.js';

import ConfirmModal from 'shared/ConfirmModal';

const Popup = props => {
  const { user, members } = props;
  const { group, addMember, getListUser, getGroupDetail } = props;
  const [clientsData, setClientsData] = useState([]);
  const [assignToGroup, setAssignToGroup] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const handleClose = () => {
    if (isChanged) {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title={'Discard Invite Members?'}
          content={`Leaving already? You have not completed invite members yet.`}
          onConfirm={handleCloseCurrentPopup}
          confirmButtonTitle="Discard"
          cancelButtonTitle="Cancel"
          noIcon
          noBorder
        />,
      );
    } else {
      props.toggleModal(false);
      handleCloseCurrentPopup();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (assignToGroup.length > 0) {
      _.map(clientsData, item => {
        if (item.typeName !== 'group') assignToGroup.push(item);
      });
    }

    const userIds =
      assignToGroup.length > 0 ? [...new Set(_.map(assignToGroup, '_id'))] : _.map(clientsData, item => item._id);
    const currentMember = _.map(members, item => item._id);
    let userIdsCurrent = [];
    _.map(userIds, item => !currentMember.includes(item) && userIdsCurrent.push(item));

    addMember(group._id, userIdsCurrent).then(res => {
      getListUser();
      getGroupDetail();
      props.toggleModal(false);
      toast(`${pluralize('member', userIdsCurrent.length, true)} was added to the Forum.`);
    });
  };

  const handleCloseCurrentPopup = () => {
    props.toggleConfirmModal(false);
    props.toggleModal(false);
  };

  const handleSelectClient = clients => {
    setIsChanged(clients.length > 0 ? true : false);
    setClientsData(clients);
  };
  return (
    <S.SModal
      open={true}
      className="modal--invite-member"
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon={
        <CloseButton className="close-button">
          <CloseCircleIcon />
        </CloseButton>
      }
    >
      <S.SModal.Content className="content">
        <S.Title>Invite Members to Forum</S.Title>
        <S.Description>Search for clients and coaches by name.</S.Description>
        <S.SelectClientsEle
          id="members"
          assignToGroup={assignToGroup}
          onSetAssignToGroup={setAssignToGroup}
          onSelectClient={handleSelectClient}
          placeholder="Search by name..."
          currentEmail={user.email || null}
          members={members}
        />
        <S.ButtonArea>
          <S.SubmitButton
            type="submit"
            purple={clientsData.length > 0 ? true : false}
            disable={clientsData.length === 0 ? true : false}
            onClick={handleSubmit}
          >
            Invite
          </S.SubmitButton>
        </S.ButtonArea>
      </S.SModal.Content>
    </S.SModal>
  );
};

export default Popup;
