import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import BackToTop from './BackToTop';
import LoadingIndicator from 'shared/LoadingIndicator';
import { convertS3UrlToCloudFrontUrl, encryptData, getUserShortName } from 'utils/commonFunction';
import { ReactComponent as EmptyBanner } from 'assets/icons/workout_empty_client.svg';
import { ReactComponent as InboxIcon } from 'assets/icons/inbox-icon.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import ReactTooltip from 'react-tooltip';

import * as S from '../style';

const CompletedTab = props => {
  const {
    onScrollToTop,
    isEndPage = false,
    isLoading,
    hasLoadMore,
    page,
    data = [],
    toggleModal,
    push,
    cloudfrontList,
  } = props;

  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (page === 1 && !isLoading) {
      setClients(data);
    }
    if (page > 1 && !isLoading) {
      const newList = [...clients, ...data];
      const filteredDuplicate = uniqBy(newList, 'client._id');
      setClients(filteredDuplicate);
    }
  }, [page, isLoading, data]);

  if (isLoading && !hasLoadMore) {
    return (
      <S.EmptyContainer>
        <LoadingIndicator className="wc-loading-indicator" />
      </S.EmptyContainer>
    );
  }

  const handleRedirectToInbox = client => {
    toggleModal && toggleModal(false);
    push(`/home/inbox?profileId=${get(client, '_id', '')}&ufn=${encryptData(get(client, 'full_name', ''))}`);
  };

  return (
    <div className="completed-tab-wrapper">
      {!isLoading && isEmpty(clients) ? (
        <S.EmptyContainer isCompletedTab={true}>
          <S.EmptyBanner>
            <EmptyBanner />
          </S.EmptyBanner>
          <S.EmptyText>No completed workout.</S.EmptyText>
        </S.EmptyContainer>
      ) : (
        clients.map(item => {
          const makeKey = `completed-${get(item, 'client._id', '')}-${get(item, '_id', '')}`;
          return (
            <S.TableBodyContainer key={makeKey}>
              <tr>
                <S.TableBodyCell>
                  <S.TableCellContent>
                    <S.CheckboxClientContainer>
                      <Avatar
                        src={convertS3UrlToCloudFrontUrl(get(item, 'client.avatar'), cloudfrontList, true)}
                        size="24"
                        name={getUserShortName(get(item, 'client', ''))}
                        alt="avatar"
                        className="workout-completed-avatar"
                      />
                      <S.TableBodyContent>{get(item, 'client.full_name')}</S.TableBodyContent>
                    </S.CheckboxClientContainer>
                  </S.TableCellContent>
                </S.TableBodyCell>
                <S.TableBodyCell />
                <S.TableBodyCell>
                  <S.TableCellContent isIcon>
                    <S.TableBodyIcon
                      onClick={() => {
                        handleRedirectToInbox(get(item, 'client'));
                      }}
                      data-tip
                      data-for={`inbox-${makeKey}`}
                    >
                      <InboxIcon className="inbox-icon" />
                      {get(item, 'has_unread_message', false) && <S.InboxUnread />}
                    </S.TableBodyIcon>
                    <ReactTooltip className="inbox-tooltip" id={`inbox-${makeKey}`} effect="solid" place={'bottom'}>
                      Open inbox
                    </ReactTooltip>
                  </S.TableCellContent>
                </S.TableBodyCell>
              </tr>
            </S.TableBodyContainer>
          );
        })
      )}
      {hasLoadMore && <LoadingIndicator className="wc-loading-indicator" />}
      {isEndPage && !isLoading && !isEmpty(clients) && <BackToTop onScrollToTop={onScrollToTop} />}
    </div>
  );
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatchToProps)(CompletedTab);
