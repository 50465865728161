// Libs
import React, { useEffect } from 'react';
import get from 'lodash/get';
import moment from 'moment';

// Constants
import { pluralize } from 'utils/commonFunction';

// Components

// Styles
import * as S from './styles';

const CustomStepTooltipContent = ({
  payload: tooltipPayload,
  active,
  chartId,
  isHoveringChartBar = false,
  tooltipActiveCallback = () => {},
}) => {
  const { payload: itemData = {} } = tooltipPayload[0] || {};

  // don't render tooltip without label or value is undefined
  if (!!itemData && itemData.value === 0) {
    tooltipActiveCallback(false);
    return null;
  }

  useEffect(() => {
    tooltipActiveCallback(active);
  }, [active]);

  const date = moment(itemData.date);
  const dateLabel = date.format(date.year() === moment().year() ? 'MMM D' : 'MMM D, YYYY');

  return (
    <S.TooltipWrapper
      id={chartId || 'metric-step-chart-tooltip-customized'}
      style={{ opacity: isHoveringChartBar ? 1 : 0 }}
    >
      <S.RangeTimeLabel>{dateLabel}</S.RangeTimeLabel>
      <p className="tooltip-value">
        <S.UnitValue>
          {get(itemData, 'value') || ''}{' '}
          {get(itemData, 'value') && <S.UnitStyle>{pluralize('step', get(itemData, 'value'))}</S.UnitStyle>}{' '}
        </S.UnitValue>
      </p>
    </S.TooltipWrapper>
  );
};

export default CustomStepTooltipContent;
