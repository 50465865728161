import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleModal } from 'actions/modal';
import { createGroup } from 'redux/group/actions';
import './styles.scss';
import ModalSingleField from 'shared/ModalSingleField';
import { pluralize } from 'utils/commonFunction';

class CreateGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      submitting: false,
      isDisabledSubmit: false,
    };
    this.inputRef = React.createRef();
  }

  createGroup = event => {
    event.preventDefault();
    const { createGroup, onSuccess, toggleModal, clientIds = [] } = this.props;
    const { name, submitting } = this.state;
    const nameTrim = name.trim();

    if (submitting) {
      return false;
    }

    if (!nameTrim) {
      this.setState({ submitting: true });
      return toast.error('Please input group name');
    }

    this.setState({ submitting: true, isDisabledSubmit: true });

    createGroup({ name: nameTrim, clients: clientIds })
      .then(response => {
        this.setState({ submitting: false, isDisabledSubmit: false });
        const { data } = response.data;

        if (typeof onSuccess === 'function') {
          onSuccess(data);
        }

        toggleModal(false);
      })
      .catch(error => {
        this.setState({ submitting: false, isDisabledSubmit: false });
      });
  };

  handleClose = () => {
    const { onOpenUpdate, toggleModal } = this.props;

    this.setState({
      isDisabledSubmit: false,
      submitting: false,
    });
    if (onOpenUpdate) onOpenUpdate(false);
    toggleModal(false);
  };

  handleChangeValue = e => {
    const value = e.target.value;

    if (value.length <= 40) {
      this.setState({ name: value, submitting: false });
    }
  };

  render() {
    const { isModalOpen, clients } = this.props;
    const { name, submitting, isDisabledSubmit } = this.state;

    return (
      <ModalSingleField
        value={name}
        open={isModalOpen}
        titlePopup="Create New Group"
        titleInput="Group Name"
        placeholderInput="Enter Group Name"
        submitName={clients ? `Create Group for ${pluralize('client', clients, true)}` : 'Create Group'}
        maxLength={40}
        inputHeight={38}
        isSubmitted={submitting}
        isDisabledSubmit={isDisabledSubmit}
        onClose={this.handleClose}
        onChangeValue={this.handleChangeValue}
        onSubmit={this.createGroup}
      />
    );
  }
}

CreateGroupModal.propTypes = {
  onSuccess: PropTypes.func,
  toggleModal: PropTypes.func,
  createGroup: PropTypes.func,
  clients: PropTypes.number,
  name: PropTypes.string,
};

const mapStateToProps = state => ({
  isModalOpen: state.isModalOpen,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  createGroup: bindActionCreators(createGroup, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupModal);
