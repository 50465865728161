/**
 * @flow
 */

import React from 'react';
import { Modal, Button, Form, Label, Image, Radio, Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import _ from 'lodash';
import Avatar from 'react-avatar';
import { classToPlain } from 'class-transformer';
import diff from 'deep-diff';
import Datetime from 'react-datetime';
import moment from 'moment';
import Floater from 'react-floater';
import {
  convertS3UrlToCloudFrontUrl,
  disableFutureDateTime,
  getPresignedUploadUrl,
  getUserShortName,
  mediaLog,
} from 'utils/commonFunction';
import { validateEmail } from 'utils/validations';
import { toggleModal, toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import GeneralButton from 'shared/GeneralButton';
import { showError } from 'actions/error';
import ConfirmModal from 'shared/ConfirmModal';
import {
  floaterStyles,
  GENDERS,
  CLIENT_CATEGORY,
  CLIENT_STATUS,
  FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF,
  CONVERSION,
  CDN_URL,
} from 'constants/commonData';
import { FILE_VALIDATIONS } from 'shared/ChatInput';
import TransferClient from 'components/TransferClient';
import { ReactComponent as CheckedIcon } from 'assets/icons/check_checked.svg';
import { ReactComponent as ImageHoverIcon } from 'assets/icons/image_hover.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar_profile.svg';
import 'react-datetime/css/react-datetime.css';
import styles, { CustomConfirmModal } from './styles';
import './styles.scss';
import { toast } from 'react-toastify';
import AvatarWrapper from 'components/ClientPage/components/AvatarWrapper';

const CATEGORIES = [
  { text: CLIENT_CATEGORY.ONLINE, value: CLIENT_CATEGORY.ONLINE },
  { text: CLIENT_CATEGORY.IN_PERSON, value: CLIENT_CATEGORY.IN_PERSON },
  { text: CLIENT_CATEGORY.HYBRID, value: CLIENT_CATEGORY.HYBRID },
];

export default class ClientProfileModal extends React.Component {
  state = {
    client: this.getDefaultClient(),
    originClient: this.getOriginClient(),
    isOwner: this.getIsOwner(),
    error: false,
    isSubmitted: false,
  };

  getDefaultClient() {
    const { workingClient } = this.props;
    return workingClient || {};
  }

  getOriginClient() {
    const { originClient } = this.props;
    return originClient || {};
  }

  getIsOwner() {
    const { isOwner } = this.props;
    return isOwner || false;
  }

  handleCloseAction() {
    let { originClient, client } = this.state;
    let origin = classToPlain(originClient);
    let current = classToPlain(client);
    let changes = diff(origin, current);

    if (changes || this.state.uploadConfig) {
      this.props.dispatch(
        toggleConfirmModal(
          true,
          <CustomConfirmModal
            noBorder
            title="Discard Changes?"
            content={`Are you sure you want to go? Changes have not been saved yet.`}
            onConfirm={() => this.onCloseCurrentPopup()}
            confirmButtonTitle="Discard changes"
            hasCloseIcon
            headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          />,
        ),
      );
    } else {
      this.onCloseCurrentPopup();
    }
  }

  onCloseCurrentPopup() {
    const { displayWithSecondModal, dispatch } = this.props;

    if (displayWithSecondModal) {
      dispatch(toggleSecondModal(false));
    } else {
      dispatch(toggleModal(false));
    }
  }

  getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, this.props.cloudfrontList, true);

  renderAvatar() {
    let user = this.state.client;
    let avatar = '';
    if (user.avatar) {
      avatar = user.avatar;
    }
    if (this.state.pendingAvatarImage) {
      avatar = this.state.pendingAvatarImage;
    }
    return (
      <div className="avatar-container">
        <AvatarWrapper user={user} classNames="client-detail-avatar" size="85" imageLocal={this.getCloudUrl(avatar)} />
        <div className="avatar-camera-container client-profile-avatar" onClick={() => this.triggerInputFile()}>
          <input
            style={{ visibility: 'hidden' }}
            type="file"
            ref={fileInput => (this.fileInput = fileInput)}
            onChange={this.onSelectPhoto}
            accept={FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF}
          />
          {this.renderVerified()}
          <ImageHoverIcon className="avatar-image" />
        </div>
      </div>
    );
  }

  renderVerified() {
    let { client } = this.state;
    if (client.client_connection === 1) {
      return <CheckedIcon className="avatar-verified" />;
    }
    return null;
  }

  triggerInputFile = () => this.fileInput.click();

  onSelectPhoto = async e => {
    const files = Array.from(e.target.files);
    if (files[0]) {
      const { size, name, type } = files[0];
      const maxImageSize = FILE_VALIDATIONS.MAX_SIZE_IMAGE * CONVERSION.MB_TO_BYTE;

      if (!FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF.includes(type)) {
        return toast.error('File type not supported');
      }

      if (size > maxImageSize) {
        return toast.error(`Please upload an image smaller than ${FILE_VALIDATIONS.MAX_SIZE_IMAGE} MB`);
      }

      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file via Profile',
      });

      const { uploadUrl, configs } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-avatar', files[0]);
      if (!uploadUrl || !configs) {
        return;
      }
      let client = this.state.client;
      client.avatar = configs.url;

      this.setState({
        client,
        uploadConfig: {
          url: uploadUrl,
          headers: { 'Content-Type': type },
          data: files[0],
        },
      });

      let reader = new FileReader();
      reader.onload = e => {
        let pendingAvatarImage = this.state.pendingAvatarImage;
        pendingAvatarImage = e.target.result;
        this.setState(prevState => ({
          pendingAvatarImage,
        }));
      };
      reader.readAsDataURL(files[0]);
    }
  };

  submitForm = () => {};

  onChooseClientType = (e, { value }) => {
    this.setState(p => ({
      client_type: value,
    }));
  };

  renderResendButton = () => {
    const { displayWithSecondModal, saveClientInfoAndSendInvitetoConnect } = this.props;
    const { uploadConfig } = this.state;

    return (
      <Floater
        content={<div>Resend invitation</div>}
        event="hover"
        eventDelay={0}
        placement="top"
        offset={5}
        styles={{
          ...floaterStyles,
        }}
        id="profile-floater-resend"
      >
        <button
          className="client-profile-button resend-button"
          onClick={() => {
            const newClient = this.validateName();

            if (newClient) {
              const params = { email: newClient.email };

              this.props.dispatch(
                toggleConfirmModal(
                  true,
                  <ConfirmModal
                    title={'Resend Invitation'}
                    content={'Resend an invitation to the client?'}
                    confirmButtonClass="general-button"
                    onConfirm={() =>
                      saveClientInfoAndSendInvitetoConnect(
                        newClient._id,
                        params,
                        newClient,
                        uploadConfig,
                        displayWithSecondModal,
                      )
                    }
                    onDeny={() => {}}
                    headerIcon={`${CDN_URL}/images/checkbox_greenwhite.svg`}
                  />,
                ),
              );
            }
          }}
        >
          <Image src={`${CDN_URL}/images/email.svg`} width={15} />
        </button>
      </Floater>
    );
  };

  renderInviteToConnectButton = () => {
    const { displayWithSecondModal, saveClientInfoAndSendInvitetoConnect, dispatch } = this.props;
    const { uploadConfig } = this.state;

    return (
      <Button
        className="intive-connect-button"
        onClick={() => {
          const newClient = this.validateName();

          if (newClient && newClient.email && validateEmail(newClient.email)) {
            const params = { invite_to_connect: true, email: newClient.email };

            dispatch(
              toggleConfirmModal(
                true,
                <ConfirmModal
                  title={'Confirm'}
                  content={'Client will receive an invitation to create a client account'}
                  confirmButtonClass="general-button"
                  onConfirm={() =>
                    saveClientInfoAndSendInvitetoConnect(
                      newClient._id,
                      params,
                      newClient,
                      uploadConfig,
                      displayWithSecondModal,
                    )
                  }
                  onDeny={() => {}}
                  headerIcon={`${CDN_URL}/images/checkbox_greenwhite.svg`}
                />,
              ),
            );
          } else {
            dispatch(
              showError(
                'Please have a vaild email entered for your client',
                'Email missing',
                'OK',
                `${CDN_URL}/images/close_circle_grey.svg`,
              ),
            );
          }
        }}
      >
        Invite to Connect
      </Button>
    );
  };

  renderInvitationButton() {
    const { client } = this.state;

    switch (client.client_connection) {
      case CLIENT_STATUS.connected:
        return null;

      case CLIENT_STATUS.offline:
        return this.renderInviteToConnectButton();

      default:
        return this.renderResendButton();
    }
  }

  renderDeleteButton = checkSubTrainer => {
    const { client } = this.state;
    const { removeClient, archiveOneClient } = this.props;
    const { is_archived } = client;
    let icon, description, warning;

    if (is_archived) {
      icon = `${CDN_URL}/trash_grey.svg`;
      description = 'Delete client';
      warning = 'Are you sure that you want to delete this client?';
    } else {
      icon = `${CDN_URL}/images/archive.svg`;
      description = 'Archive client';
      warning = 'Are you sure that you want to archive this client?';
    }

    if (!is_archived && checkSubTrainer) return null;

    return (
      <Floater
        content={<div>{description}</div>}
        event="hover"
        eventDelay={0}
        placement="top"
        offset={5}
        styles={{ ...floaterStyles }}
        id="profile-floater-archive"
      >
        <button
          className="client-profile-button deactivate-button"
          onClick={() =>
            this.props.dispatch(
              toggleConfirmModal(
                true,
                <ConfirmModal
                  title={description}
                  content={warning}
                  onConfirm={() => {
                    if (is_archived) {
                      removeClient(client._id);
                    } else {
                      archiveOneClient(client._id).then(() => this.onCloseCurrentPopup());
                    }
                  }}
                  onDeny={() => {}}
                />,
              ),
            )
          }
        >
          <Image src={icon} width={13} />
        </button>
      </Floater>
    );
  };

  validateName = () => {
    const { client } = this.state;
    const newClient = { ...client, first_name: client.first_name.trim(), last_name: client.last_name.trim() };

    if (!newClient.first_name || !newClient.last_name) {
      this.setState({ error: true, client: newClient });

      return null;
    }

    return newClient;
  };

  renderSaveButton() {
    const { client, uploadConfig } = this.state;
    const { saveClientInfo, displayWithSecondModal, isUploading } = this.props;

    return (
      <GeneralButton
        className="setting-save-button client-profile-save-button"
        disabled={isUploading}
        onClick={() => {
          const newClient = this.validateName();

          if (newClient) {
            saveClientInfo(newClient, uploadConfig, client._id, displayWithSecondModal);
          }
        }}
      >
        Save
      </GeneralButton>
    );
  }

  renderActionButtons(checkSubTrainer) {
    return (
      <Modal.Actions>
        <div className="client-profile-buttons-container">
          <div className="left-buttons">
            {this.renderDeleteButton(checkSubTrainer)}
            {this.renderInvitationButton()}
          </div>
          {this.renderSaveButton()}
        </div>
      </Modal.Actions>
    );
  }

  renderInputs(checkSubTrainer) {
    const { error, client } = this.state;
    const { dob, timezone } = client;
    let isEditAble = this.state.client.client_connection !== 1;
    let emailInputClass = isEditAble ? '' : ' no-edit';
    let clientOwner = this.props.user.team_member.find(item => {
      return item._id == this.state.client.trainers[0].trainer;
    });

    const dobTimezone = moment.utc(dob);

    return (
      <div style={styles.inputContainer}>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <Label className="client-profile-label">FIRST NAME</Label>
              <input
                className={classNames('form-text', { error: error && !this.state.client.first_name })}
                onChange={evt => {
                  let client = this.state.client;
                  client.first_name = evt.target.value;
                  this.setState(p => ({ client }));
                }}
                value={this.state.client.first_name}
                placeholder={error && !this.state.client.first_name ? 'First name cannot be empty' : 'First name'}
                maxLength={30}
              />
            </Form.Field>
            <Form.Field>
              <Label className="client-profile-label">LAST NAME</Label>
              <input
                className={classNames('form-text', { error: error && !this.state.client.last_name })}
                onChange={evt => {
                  let client = this.state.client;
                  client.last_name = evt.target.value;
                  this.setState(p => ({ client }));
                }}
                value={this.state.client.last_name}
                placeholder={error && !this.state.client.last_name ? 'Last name cannot be empty' : 'Last name'}
                maxLength={30}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Label className="client-profile-label">EMAIL</Label>
              <input
                className={'form-text' + emailInputClass}
                onChange={evt => {
                  let client = this.state.client;
                  if (isEditAble) {
                    client.email = evt.target.value;
                    this.setState(p => ({
                      client,
                    }));
                  }
                }}
                value={this.state.client.email}
                placeholder="Email"
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <Label className="client-profile-label">PHONE</Label>
              <input
                className={'form-text'}
                onChange={evt => {
                  let client = this.state.client;
                  client.phone = evt.target.value;
                  this.setState(p => ({
                    client,
                  }));
                }}
                value={this.state.client.phone}
                placeholder="Phone"
              />
            </Form.Field>
            <Form.Field>
              <Label className="client-profile-label">BIRTHDATE</Label>
              <Datetime
                value={dobTimezone}
                renderDay={(props, currentDate) => (
                  <td {...props}>
                    <div className="rdtCustom">
                      <div>{currentDate.format('DD')}</div>
                    </div>
                  </td>
                )}
                renderInput={props => {
                  return (
                    <div>
                      <div className="ui icon input birthday-field profile-birthday">
                        <input
                          {...props}
                          placeholder={isEditAble ? 'Birthdate' : ''}
                          className={'form-text'}
                          type="text"
                          value={dob ? dobTimezone.format('MMM Do YYYY') : ''}
                        />
                        <CalendarIcon className="profile-calendar-icon" />
                      </div>
                    </div>
                  );
                }}
                timeFormat={false}
                onChange={momentData => {
                  if (moment.isMoment(momentData)) {
                    client.dob = momentData.toISOString();
                    this.setState(p => ({
                      client,
                    }));
                  }
                }}
                onBlur={momentData => {
                  if (moment.isMoment(momentData)) {
                    client.dob = momentData.toISOString();
                    this.setState(p => ({
                      client,
                    }));
                  }
                }}
                className="client-profile-calendar"
                isValidDate={disableFutureDateTime}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ marginLeft: '0' }}>
            {_.map(GENDERS, item => (
              <Form.Field
                key={item.value}
                control={Radio}
                className={'general ' + (this.state.client.sex === item.value ? 'active' : 'isactive')}
                label={item.label}
                value={item.value}
                checked={this.getCurrentGender() === item.value}
                onChange={(evt, { value }) => {
                  this.setState(p => ({ client: { ...p.client, sex: value } }));
                }}
              />
            ))}
          </Form.Group>
          {this.state.isOwner ? null : (
            <div>
              <Form.Field className="client_type_field">
                <Label className="client-profile-label">Category</Label>
              </Form.Field>
              <Dropdown
                value={this.state.client.client_type}
                selection
                className="exercise-dropdown exercise-input client-type-dropdown"
                options={CATEGORIES}
                onChange={(evt, { value }) => {
                  let client = this.state.client;
                  client.client_type = value;
                  this.setState(p => ({
                    client,
                  }));
                }}
              />
            </div>
          )}
          {!this.state.isOwner && this.state.client.trainers && this.state.client.trainers.length > 0 && (
            <div>
              <Form.Field className="client_type_field">
                <Label className="client-profile-label">OWNER</Label>
              </Form.Field>
              <div className="client__owner">
                <div
                  style={{ width: '50%' }}
                  className={'form-text'}
                >{`${clientOwner.first_name} ${clientOwner.last_name}`}</div>
                {!checkSubTrainer && (
                  <div
                    className="transfer-client__trigger"
                    onClick={() => {
                      this.props.dispatch(toggleModal(true, <TransferClient />));
                    }}
                  >
                    Transfer client
                  </div>
                )}
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }

  getCurrentGender() {
    const { client } = this.state;
    return client.sex;
  }

  render() {
    const {
      isSecondModalOpen,
      isModalOpen,
      displayWithSecondModal,
      subTrainers,
      user: { _id },
    } = this.props;

    const subTrainersConvert = (subTrainers || []).map(item => item.trainer);
    const checkSubTrainer = (subTrainersConvert || []).includes(_id);

    return (
      <Modal
        size={'tiny'}
        open={displayWithSecondModal ? isSecondModalOpen : isModalOpen}
        onClose={() => this.handleCloseAction()}
        closeOnDimmerClick={false}
        className="client-profile-container"
      >
        <Modal.Header style={styles.headerContainer} className="invite-header">
          <Button onClick={() => this.handleCloseAction()} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          {this.renderAvatar()}
        </Modal.Header>
        <Modal.Content>
          <Form>{this.renderInputs(checkSubTrainer)}</Form>
        </Modal.Content>
        {this.renderActionButtons(checkSubTrainer)}
      </Modal>
    );
  }
}
