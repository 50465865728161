import React from 'react';

const CustomDot = props => {
  const { cx, cy, payload, color, index, isReduce, isModeCompare, bodyMetricId } = props;
  if (payload && payload.value === undefined) return null;

  const cus_w = isReduce ? 9 : 12;
  const cus_h = isReduce ? 9 : 12;
  const cus_cx = isReduce ? 4.5 : 6;
  const cus_cy = isReduce ? 4.5 : 6;
  const cus_r = isReduce ? 2.25 : 3;

  const dotClass = isModeCompare
    ? `custom-dot my-custom-dot dot-${index}`
    : `custom-dot-${bodyMetricId} my-custom-dot dot-${index}`;

  return (
    <svg
      width={cus_w}
      height={cus_h}
      x={cx - cus_cx}
      y={cy - cus_cy}
      viewBox={`0 0 ${cus_w} ${cus_h}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={dotClass}
    >
      <circle cx={cus_cx} cy={cus_cy} r={cus_r} fill={color} stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default CustomDot;
