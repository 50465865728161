import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import TextareaAutosize from 'react-autosize-textarea';
import Button from 'shared/FormControl/Button';
import ConfirmModal from 'shared/ConfirmModal';
import { Container as VideoDurationContainer } from 'shared/VideoDuration/style';

export const SModal = styled(Modal)`
  &.ui.modal.modal--create-new-post {
    width: 558px !important;
    border-radius: 8px !important;
    padding: 0 !important;

    .close-button svg {
      width: 20px;
      height: 20px;
    }
  }

  &.ui.modal.modal--create-new-post > .header {
    padding: 0 !important;
    border-radius: inherit;
  }

  &.ui.modal.modal--modal--create-new-post > .content {
    padding: 0 !important;
  }

  .content {
    padding: 1.43rem;

    section {
      margin-bottom: 0;
      min-height: 38px;

      button {
        margin-top: 0;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px !important;
  }

  ${VideoDurationContainer} {
    z-index: 1;
  }
`;

export const Title = styled.div`
  height: 66px;
  width: 100%;
  background: #f7f8fa;
  box-shadow: 0px 1px 0px 0px #e9ecf2 !important;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0px;
  color: #202353;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AuthorNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 13px !important;
  margin-left: 10px;
  align-self: center;
  color: #202353;
  overflow: hidden;

  .author-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    align-items: center;
  }

  .author-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    display: block;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .author-you {
    display: block;
    margin-right: 10px;
  }

  .author-role {
    color: #202353;
    font-size: 13px;
    line-height: 100%;
    opacity: 0.5;
    margin-top: 5px;
  }
`;

export const Header = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #000000;
  display: flex;
  position: relative;
  justify-content: flex-start;
  margin: 0 0 20px 0;
`;

export const TextInput = styled(TextareaAutosize)`
  font-family: Open Sans;
  font-size: ${props => props.fontSize} !important;
  resize: none;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  border: none !important;
  padding: 0 !important;
  ${props => props.isShareLeaderboardPost && 'margin-bottom: 7px;'}
  ${props => !props.isShareLeaderboardPost && 'min-height: 200px !important'};
  max-height: 200px !important;
  width: 100%;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  ::placeholder {
    color: #202353;
    font-size: 20px;
    line-height: 27px;
    opacity: 0.4;
  }
`;

export const PostButton = styled(Button)`
  height: 34px;
  width: 141px;
  padding: 8px 30px;
  margin-left: 10px;

  ${props =>
    props.disable &&
    css`
      background: #c4c4c4;
      color: white;
      cursor: not-allowed;
      pointer-events: none;

      &:hover,
      &:active,
      &:focus {
        background: #c4c4c4;
        color: white;
      }
    `}

  &:hover,
  &:active,
  &:focus {
    background: #5559ff;
  }
`;

export const PostScheduleButton = styled(Button)`
  height: 34px;
  width: 141px;
  padding: 8px 30px;
  margin-left: 12px;
  border-radius: 4px 0px 0px 4px;
  border: 0;

  ${props =>
    props.normalBtn &&
    css`
      height: 34px;
      width: 241px;
      padding: 8px 30px;
      margin-left: 10px;
      border-radius: 4px;

      &:hover,
      &:active,
      &:focus {
        background: #5559ff;
      }
    `}

  &:hover,
  &:active {
    background: #5559ff;
  }

  ${props =>
    props.disable &&
    css`
      background: #c4c4c4;
      color: white;
      cursor: not-allowed;
      pointer-events: none;

      &:hover,
      &:active,
      &:focus {
        background: #c4c4c4;
        color: white;
      }
    `}
`;

export const ScheduleButton = styled(Button)`
  height: 34px;
  min-width: 34px !important;
  border-radius: 0px 4px 4px 0px;
  padding: 12px 0;
  border: 0;
  position: relative;

  &:after {
    content: '';
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    bottom: 5px;
    left: 0;
    height: 24px;
    width: 1px;
    border-radius: 1px;
  }

  ${props =>
    props.disable &&
    css`
      background: #c4c4c4;
      color: white;
      cursor: not-allowed;
      pointer-events: none;

      &:hover,
      &:active,
      &:focus {
        background: #c4c4c4;
        color: white;
      }
    `}

  &:hover,
  &:active,
  &:focus {
    background: #5559ff;
  }
`;

export const ScheduledBox = styled.div`
  width: 190px;
  height: 34px;
  background-color: #f7f8fa;
  margin-left: auto;
  border-radius: 4px;
  padding: 8px 12px;
  justify-content: end;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;

  ${props =>
    props.enabled &&
    css`
      color: #ea314a;
      svg path {
        fill: #ea314a;
      }
    `}

  &:hover {
    border-color: #5e59ff;
  }
`;

export const ScheduledTime = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-left: 5.5px;
  white-space: nowrap;
`;

export const CancelButton = styled(Button)`
  height: 34px;
  width: 102px;
  padding: 8px 30px !important;
`;

export const SConfirmModal = styled(ConfirmModal)`
  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }

  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    background-color: #ec465c !important;
  }

  .ui.button.confirm-no-button,
  .ui.button.confirm-yes-button {
    line-height: 20px !important;
  }

  .confirm-content-header {
    padding: 30px 30px 0 !important;

    .confirm-header-label {
      color: #000000;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .confirm-content-body {
    padding: 15px 30px 30px !important;

    .confirm-content-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  .confirm-header-image {
    width: 25px;
    height: 25px;
  }
`;

export const MentionInput = styled.div`
  height: ${props => (props.isSchedulePost ? '137px' : '205px')};
  overflow-y: scroll;
  margin-bottom: 15px;

  &.small .DraftEditor-editorContainer,
  &.small .mention-item {
    font-size: 14px;
  }

  ${props =>
    props.isLeaderboard &&
    css`
      max-height: 205px;
      height: auto !important;
    `}

  ${props =>
    props.enableForumPoll &&
    css`
      height: unset;
      min-height: 30px;
      overflow: auto;
      margin-bottom: 20px;
    `}
`;

export const MentionEditorWrapper = styled.div`
  .DraftEditor-editorContainer {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #141632;
  }
  .draftJsMentionPlugin__mentionSuggestions__2DWjA {
    box-shadow: 0 4px 8px -4px rgb(0 0 0 / 30%) !important;
    border: solid 1px #e0e0e8 !important;
    border-radius: 5px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: visible;
    width: auto;
    max-height: 205px;
  }
  .draftJsMentionPlugin__mention__29BEd,
  .draftJsMentionPlugin__mention__29BEd:hover,
  .draftJsMentionPlugin__mention__29BEd:visited {
    background-color: transparent;
    font-weight: 600;
    color: #0068e1;
    font-size: 20px;
    font-weight: 400;
    word-break: break-all;
    display: initial;
  }
  .public-DraftEditorPlaceholder-inner {
    position: absolute;
    color: #202353;
    opacity: 0.4;
    font-size: 20px;
    font-weight: 400;
  }

  .mention-item {
    display: flex;

    &:hover {
      background-color: #f6f5ff;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      .client-detail-avatar {
        font-size: 10px !important;
        span {
          font-size: 10px !important;
        }
      }

      .name {
        width: 200px;
        padding-left: 10px;
        font-size: 14px;
        font-family: 'Open Sans';
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .role {
        color: #202353;
        background: #eeeff4;
        font-size: 10px;
        line-height: 18px;
        padding: 2px 10px;
        margin-left: 8px;
        text-align: center;
        border-radius: 20px;
        white-space: nowrap;
      }
    }
  }
`;

export const ContentScrollWrapper = styled.div`
  max-height: 55vh;
  overflow: auto;
  margin: 0px -1.5rem 20px;
  padding: 0px 22px 0px 20px;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
  }
`;
