// Libs
import React, { useEffect, useRef, useMemo } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Shared
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import StartTrialModal from './StartTrialModal';

// Constants
import { PLANS } from 'constants/commonData';

// Actions
import { startTrialMealPlan } from 'redux/permission/actions';
import { initPricingData } from 'redux/pricing/actions';

// Assets
import MealPlanUpgradePathBanner from 'assets/images/meal_plan_upgrade_path_banner.jpg';
import RecipeUpgradePathBanner from 'assets/images/recipe_upgrade_path_banner.jpg';
import IngredientUpgradePathBanner from 'assets/images/ingredient_upgrade_path_banner.jpg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';

// Style
import * as S from './style';
import { isTrainer } from 'utils/validations';

const data = [
  {
    id: 'meal_plan',
    image: MealPlanUpgradePathBanner,
    title: 'Meal Plans',
    description: `Create easy to roll out meal plan templates for different diets and training phases. Provide sophisticated nutrition guidance in a snap.`,
    buttonGetPlan: 'get meal plans',
    hasTrial: true,
    planWillSelect: {
      tier: PLANS.pro.key,
      addOns: {
        meal_plan: true,
      },
    },
    permission: 'meal_plan',
  },
  {
    id: 'recipe',
    image: RecipeUpgradePathBanner,
    title: 'Recipes',
    description:
      'Choose from 500+ recipes approved by registered dietitians or set up custom recipes easy for your clients to follow along and prepare.',
    buttonGetPlan: 'get recipes',
    planWillSelect: { tier: PLANS.pro.key },
    permission: 'recipe',
  },
  {
    id: 'ingredient',
    image: IngredientUpgradePathBanner,
    title: 'Ingredient Library',
    description: 'Set up custom ingredients for your personal recipes',
    buttonGetPlan: 'get ingredients',
    planWillSelect: { tier: PLANS.pro.key },
    permission: 'recipe',
  },
];

const CustomMealPlanFallBack = ({
  startIndex = 0,
  toggleModal,
  startTrialMealPlan,
  initPricingData,
  permission,
  tier,
  isTrainer,
  ...rest
}) => {
  const sliderRef = useRef(null);

  const list = useMemo(() => {
    return data.filter(item => (permission.recipe ? item.permission !== 'recipe' : true));
  }, [permission]);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(startIndex);
    }
  }, [startIndex]);

  const NextArrow = props => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowIcon />
      </div>
    );
  };

  const PrevArrow = props => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowIcon />
      </div>
    );
  };

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: startIndex,
    lazyLoad: true,
    useTransform: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const openStartTrial = () => {
    toggleModal(true, <StartTrialModal onClose={closeStartTrial} onConfirmed={handleStartTrial} />);
  };

  const closeStartTrial = () => {
    toggleModal(false);
  };

  const handleStartTrial = () => {
    startTrialMealPlan().then(() => {
      initPricingData();
      toggleModal(false);
      toast('7-day trial of Advanced Meal Plans has started.');
    });
  };

  const handleMouseEnter = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  return (
    <S.Wrapper>
      <Slider ref={sliderRef} {...settings} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}>
        {list.map(obj => {
          const { id, image, title, description, buttonGetPlan, hasTrial, planWillSelect } = obj;

          return (
            <div key={id}>
              <div className={classNames('item', { 'item-ingredient': id === 'ingredient' })}>
                {id === 'ingredient' ? (
                  <>
                    <div className="content">
                      <div className="title">{title}</div>
                      <div className="description">{description}</div>
                    </div>
                    <img src={image} alt={title} />
                    <UpgradePathButton className="get-plan-btn" text={buttonGetPlan} planWillSelect={planWillSelect} />
                  </>
                ) : (
                  <>
                    <img src={image} alt={title} />
                    <div className="content">
                      <div className="title">{title}</div>
                      <div className="description">{description}</div>
                      <UpgradePathButton
                        className="get-plan-btn"
                        text={buttonGetPlan}
                        planWillSelect={planWillSelect}
                      />
                      {hasTrial && !permission.is_used_trial_meal_plan && tier !== PLANS.starter.key && !isTrainer && (
                        <button className="trial-btn" onClick={openStartTrial}>
                          Start 7-day trial
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, user } = state;

  return {
    tier: rootReducer.pricing.getIn(['teamData', 'tier']),
    isTrainer: isTrainer(user),
  };
};

const mapDispatchToProps = dispatch => ({
  startTrialMealPlan: bindActionCreators(startTrialMealPlan, dispatch),
  initPricingData: bindActionCreators(initPricingData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomMealPlanFallBack);
