import { cloneDeep, get, orderBy } from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = {
  list: [],
  loading: false,
  selectedWorkoutLabel: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_WORKOUT_LABELS_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.GET_WORKOUT_LABELS_SUCCESS: {
      return Object.assign({}, state, { list: payload.data, loading: false });
    }
    case Types.REMOVE_WORKOUT_LABEL_SUCCESS: {
      let cloned = cloneDeep(state);
      const { list } = cloned;
      const newList = list.filter(label => label._id !== payload.data);
      return Object.assign({}, state, { list: newList });
    }
    case Types.ADD_NEW_WORKOUT_LABELS_SUCCESS: {
      let list = [...state.list, payload.data];
      list = orderBy(list, obj => obj.order, 'desc');

      return Object.assign({}, state, { list: list });
    }
    case Types.GET_WORKOUT_LABEL_DETAIL_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.GET_WORKOUT_LABEL_DETAIL_SUCCESS: {
      return Object.assign({}, state, { selectedWorkoutLabel: payload.data, loading: false });
    }
    case Types.ADD_NEW_PROPERTY_LABELS_SUCCESS: {
      let items = get(state, 'selectedWorkoutLabel.items', []);

      items = [payload.data, ...items];
      return Object.assign({}, state, {
        selectedWorkoutLabel: {
          ...state.selectedWorkoutLabel,
          items: items,
        },
      });
    }
    case Types.REMOVE_PROPERTY_LABELS_SUCCESS: {
      let items = get(state, 'selectedWorkoutLabel.items', []);
      const newItems = items.filter(property => property._id !== payload.data);
      return Object.assign({}, state, {
        selectedWorkoutLabel: {
          ...state.selectedWorkoutLabel,
          items: newItems,
        },
      });
    }
    case Types.RESET_WORKOUT_LABEL_DETAIL: {
      return Object.assign({}, state, { selectedWorkoutLabel: null });
    }
    case Types.EDIT_WORKOUT_LABEL_SUCCESS: {
      let cloned = cloneDeep(state);
      const { list } = cloned;
      const newList = list.map(label => (label._id === payload.labelId ? { ...label, name: payload.name } : label));
      return Object.assign({}, state, { list: newList });
    }
    case Types.EDIT_WORKOUT_LABEL_OPTION_SUCCESS: {
      const items = get(state, 'selectedWorkoutLabel.items', []);
      const newItems = items.map(property =>
        property._id === payload.data.labelItemId ? { ...property, name: payload.data.name } : property,
      );
      return Object.assign({}, state, {
        selectedWorkoutLabel: {
          ...state.selectedWorkoutLabel,
          items: newItems,
        },
      });
    }
    default:
      return state;
  }
};
