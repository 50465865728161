import styled from 'styled-components';
import { Panel } from '../style';

export const HistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;

  .amount {
    font-weight: 600;
    font-size: 14px;
  }
`;

export const List = styled.div`
  margin-top: 10px;
`;

export const CurrentPlanWrapper = styled(Panel)`
  .billing-contact {
    margin-top: 10px;
  }
`;
