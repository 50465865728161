import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import AccountDetails from './component';
import {
  updateUserProfileThenUploadAvatar,
  updateUserPreferences,
  updateTimezoneSettings,
  getProfile,
} from 'actions/profile';
import { toggleSideBar } from 'actions/sidebar';
import { DEFAULT_SUBMIT_STATUS } from 'constants/commonData';
import { push } from 'connected-react-router';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = state => {
  const { user, rootReducer, submitStatus, cloudfrontList } = state;
  let trainer_unit = user.preferences;
  return {
    cloudfrontList,
    user,
    unit_categories: rootReducer.unit.unit_categories,
    trainer_unit,
    unitSubmitStatus: submitStatus.TRAINER_UNITS || DEFAULT_SUBMIT_STATUS,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    saveUserPreferences: bindActionCreators(updateUserPreferences, dispatch),
    saveOwnerInfo: bindActionCreators(updateUserProfileThenUploadAvatar, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
    push: bindActionCreators(push, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    updateTimezoneSettings: bindActionCreators(updateTimezoneSettings, dispatch),
    getProfile: bindActionCreators(getProfile, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountDetails));
