import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import NumberFormat from 'react-number-format';
import isEmpty from 'lodash/isEmpty';

import { Button, ErrorMessage } from 'shared/FormControl';
import FitInchUnit from 'components/BodyMetricProgressNew/AddResultPopup/FitInchUnit';

import {
  convertFtAndInchToFt,
  convertFtToFtAndInch,
  mongoObjectId,
  processMetricResultValue,
  getTimeFromString,
  formatInputTime,
  convertMinToFromHourMin,
} from 'utils/commonFunction';
import { KEY_CODE, METRIC_UNIT_ERRORS } from 'constants/commonData';
import * as S from './style';

function AddGoalPopup({ isOpen, onClose, metricUnit, onSave, currentValue = '', style = {} }) {
  const timeMetricInputRef = useRef();

  const [ftErrors, setFtErrors] = useState({
    ft: null,
    inch: null,
  });
  const [isError, setIsError] = useState(false);
  const isNoUnit = metricUnit.unit === '';
  const isFtUnit = metricUnit.unit === 'ft';
  const [goalValue, setGoalValue] = useState(() => {
    if (!isNaN(currentValue)) {
      if (isFtUnit) {
        return convertFtToFtAndInch(+currentValue, 2);
      }

      return currentValue;
    }
    return isFtUnit ? { ft: '', inch: '' } : '';
  });

  const isDisabledSubmit = useMemo(() => {
    if (isFtUnit) {
      return !(+goalValue.ft > 0 || +goalValue.inch > 0);
    }

    return isError || goalValue === '' || goalValue === 0 || false;
  }, [isError, goalValue, isFtUnit]);

  const isTimeMetric = useMemo(() => {
    const { unit } = metricUnit;
    return unit === 'min' || unit === 'sec';
  }, [metricUnit]);

  useEffect(() => {
    if (isOpen && isTimeMetric && currentValue) {
      const { hour = 0, min = 0 } = convertMinToFromHourMin(Number(currentValue));
      setTimeout(() => {
        const defaultTimeValue = hour > 0 ? `${hour}:${min || '00'}` : `${min}`;
        if (timeMetricInputRef.current) {
          timeMetricInputRef.current.value = defaultTimeValue;
          handleChangeLabelTime(defaultTimeValue.replace(/\D/g, ''));
        }
      }, 0);
    }
  }, [isOpen, currentValue, isTimeMetric]);

  const handleBlurValue = event => {
    const { value } = event.target;

    if (isNaN(value) || value.trim() === '') {
      return;
    }

    const validatedValue = processMetricResultValue(value, metricUnit.metric_code);

    if (+validatedValue === 0) {
      setIsError(true);
    }

    setGoalValue(validatedValue);
  };

  const handleChangeValue = ({ value }) => {
    if (isNaN(value) && isError) {
      setIsError(false);
    }

    if (value.trim() === '' && isError) {
      setIsError(false);
    }

    const processedValue = processMetricResultValue(value, metricUnit.metric_code);

    if (+processedValue > 0 && isError) {
      setIsError(false);
    }

    setGoalValue(processedValue);
  };

  const handleSave = async () => {
    setIsError(false);

    if (isFtUnit) {
      const error = handleBlurFtInInput();
      if (Object.keys(error).length > 0) {
        return;
      }
    }

    const finalVal = isFtUnit ? convertFtAndInchToFt(goalValue.ft, goalValue.inch) : goalValue;

    if (+finalVal === currentValue) {
      onClose();
      return;
    }

    onSave(Number(finalVal) || '');
  };

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_CODE.esc && isOpen) {
        onClose();
      }
    },
    [isOpen],
  );

  const handleBlurFtInInput = () => {
    const error = {};

    if (!(+goalValue.ft > 0 || +goalValue.inch > 0) && (goalValue.ft !== '' || goalValue.inch !== '')) {
      error.ft_inch = METRIC_UNIT_ERRORS.VALUE;
    }

    setIsError(!isEmpty(error));
    setFtErrors(error);
    return error;
  };

  const handleChangeFtInValue = (key, value) => {
    const error = {};

    const shouldDeleteError = secondary =>
      goalValue[secondary] > 0 || value > 0 || (goalValue[secondary] === '' && value === '');

    if (shouldDeleteError('inch') || shouldDeleteError('ft')) {
      error.ft_inch = undefined;
    }

    setFtErrors(s => ({ ...s, ...error }));
    setGoalValue(s => ({ ...s, [key]: value }));
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const handleChangeLabelTime = inputTime => {
    const value = `${inputTime}`;
    const labelUnit = document.getElementById('goal-time-metric-unit');
    if (!labelUnit) return;

    if (metricUnit.unit === 'sec') {
      if (value.length >= 3) {
        labelUnit.innerText = 'min';
      } else if (value.length < 3 && labelUnit.innerText === 'min') {
        labelUnit.innerText = 'sec';
      }
    }

    if (metricUnit.unit === 'min') {
      if (value.length >= 3) {
        labelUnit.innerText = 'h';
      } else if (value.length < 3 && labelUnit.innerText === 'h') {
        labelUnit.innerText = 'min';
      }
    }
  };

  const handleChangeTimeMetric = () => {
    if (!timeMetricInputRef.current) return;

    const value = timeMetricInputRef.current.value.replace(/\D/g, '');
    if (isEmpty(value)) {
      timeMetricInputRef.current.value = '';
      handleChangeValue({ value: '' });
      return;
    }

    const validValue = processMetricResultValue(Number(value), metricUnit.unique_code);
    const format = formatInputTime(validValue);
    handleChangeLabelTime(validValue);

    const timeFormat = format || value;
    const timeValue = getTimeFromString(timeFormat);

    timeMetricInputRef.current.value = timeFormat;
    handleChangeValue({ value: `${timeValue}` });
  };

  const renderTimeMetricInput = () => {
    return (
      <>
        <input
          ref={timeMetricInputRef}
          type="text"
          placeholder="Add Goal"
          autoFocus
          onChange={handleChangeTimeMetric}
          onBlur={handleBlurValue}
          className={classNames('metric-value-input', { 'input-error': isError })}
        />
        <span className="unit" id="goal-time-metric-unit">
          {metricUnit.unit}
        </span>
      </>
    );
  };

  return (
    <RootCloseWrapper event="click" onRootClose={onClose}>
      <S.Wrapper className="add-goal-popup" style={style}>
        <div className="content">
          <h3>{currentValue ? 'Edit' : 'Add'} Goal</h3>
          <div className="value-input">
            <div className="flex">
              {isFtUnit ? (
                <FitInchUnit
                  hasError={!!ftErrors.ft_inch}
                  fitValue={goalValue.ft}
                  inchValue={goalValue.inch}
                  onChange={handleChangeFtInValue}
                  onBlur={handleBlurFtInInput}
                  idMetricType={mongoObjectId()}
                />
              ) : isTimeMetric ? (
                renderTimeMetricInput()
              ) : (
                <>
                  <NumberFormat
                    onValueChange={handleChangeValue}
                    value={goalValue}
                    allowNegative={false}
                    decimalSeparator="."
                    placeholder="Add Goal"
                    onBlur={handleBlurValue}
                    decimalScale={2}
                    autoFocus
                    className={classNames('metric-value-input', { 'input-error': isError })}
                  />
                  {!isNoUnit && <span className="unit">{metricUnit.unit}</span>}
                </>
              )}
            </div>
            {isFtUnit ? (
              <div className="ft-in-error">
                {!!ftErrors.ft_inch && <ErrorMessage className="value-error in-error">{ftErrors.ft_inch}</ErrorMessage>}
              </div>
            ) : (
              <>
                {isError && (
                  <ErrorMessage marginTop="8px" className="value-error">
                    Value must be greater than 0
                  </ErrorMessage>
                )}
              </>
            )}
          </div>
        </div>
        <div className="footer">
          <Button className="btn-cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button purple className="btn-save" onClick={handleSave} disabled={isDisabledSubmit}>
            Save
          </Button>
        </div>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}

export default AddGoalPopup;
