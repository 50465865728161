import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7b7e91;
`;

export const Wrapper = styled.div`
  position: relative;
  input {
    ${baseText}
    font-weight: 600;
    font-size: 19px;
    line-height: 29px;
    color: #202353;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0 9px;
    outline: none;
    &::placeholder {
      ${baseText}
      font-weight: 400;
      font-size: 19px;
      line-height: 29px;
      color: #a3a3b5;
    }
    &:placeholder-shown {
      border: 1px solid #dadbea;
    }
    &:hover {
      border: 1px solid #5158cf;
    }
    &:focus {
      border: 1px solid #5158cf;
    }
  }
  .text-limit {
    ${baseText}
    font-weight: 400;
    display: inline-block;
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    &.is-multi {
      min-width: 42px;
      height: 18px;
      top: calc(100% + 1px);
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &.error {
      color: #ea314a;
    }
  }
  ${({ isViewMode }) =>
    isViewMode &&
    css`
      pointer-events: none;
    `}
  ${({ hasError }) =>
    hasError &&
    css`
      input {
        background: #fffafb;
        border: 1px solid #ea314a !important;
      }
    `}

  ${({ isMulti }) =>
    isMulti &&
    css`
      input {
        ${baseText}
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #202353;
        border: 1px solid #e1e1ea;
        height: 36px;
        &::placeholder {
          ${baseText}
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #a3a3b5;
        }
      }
    `}
`;
