import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const DEFAULT_THUMBNAIL = `${CDN_URL}/images/gym.png`;

export const Description = styled.div`
  margin-top: 17px;
  opacity: ${props => (props.loading ? 0 : 1)}
  transition: opacity 0.3s ease-in-out;

  .label {
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    color: #222222;
    margin-bottom: 3px;
  }

  .text {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  color: #ffffff;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;
  margin-bottom: 5px;
`;

export const Summary = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.6;
`;

export const Header = styled.div`
  width: 100%;
  height: 134px;
  background: url('${props => props.background}') no-repeat;
  background-position: center;
  background-size: cover;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  ${props =>
    props.loading
      ? css`
          background: #f9f9f9;
        `
      : ''};
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;

  .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1;
  }

  .time {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    font-size: 10px;
    line-height: 100%;
    color: #7470ef;
    text-transform: uppercase;
    margin-left: 10px;
    white-space: nowrap;

    img {
      margin-right: 4px;
    }
  }
  .attachment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const ExerciseTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #222222;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ExerciseSummary = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #5f5f5f;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 2px;
`;

export const NumberOfSets = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #5f5f5f;
  margin-left: 10px;
`;

export const ExerciseThumbnail = styled.div`
  width: 42px;
  height: 42px;
  flex: 0 0 42px;
  border-radius: 5px;
  background: #eaebef url(${props => props.thumb || DEFAULT_THUMBNAIL}) no-repeat center;
  background-size: cover;
  background-color: #eaebef;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  ${props =>
    !props.thumb &&
    css`
      background-size: contain;
      border: 6px solid transparent;
    `}
`;

export const ExerciseBaseInfo = styled.div`
  overflow: hidden;
  flex: 1 1;
`;

export const Exercise = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
`;

export const Exercises = styled.div`
  position: relative;

  .exercise {
    :first-child {
      padding-top: 0;
    }

    :last-child {
      padding-bottom: 0;
    }
  }
`;

export const Supersets = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #ededed;

  .superset-label {
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    color: #7b7e91;
    margin-bottom: 5px;
  }

  &.is-supersets {
    ${Exercises} {
      ::before {
        content: '';
        width: 6px;
        top: 20px;
        bottom: 20px;
        left: 42px;
        border: 1px dashed #d6d6d6;
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        position: absolute;
      }
    }
  }
`;

export const SectionExercises = styled.div``;

export const SectionContainer = styled.div`
  position: relative;
  padding-top: 20px;

  .dot {
    width: 9px;
    height: 9px;
    position: absolute;
    left: -14px;
    background-color: #e3e3e3;
    border: 1px solid #fafafa;
    border-radius: 50%;
    top: 23px;
    z-index: 2;
    content: '';
  }

  &.section--hidden {
    padding-top: 0;

    .dot {
      top: 28px;
    }
  }

  &.section--supersets {
    .dot {
      top: 37px;
    }
  }
`;

export const Sections = styled.div`
  padding-left: 14px;
  position: relative;
  opacity: ${props => (props.loading ? 0 : 1)}
  transition: opacity 0.3s ease-in-out;

  ::before {
    content: '';
    position: absolute;
    left: 4px;
    border-right: 1px dashed #d6d6d6;
    top: 0;
    bottom: 0;
  }

  ${SectionContainer} {
    :last-child {
      ::after {
        content: '';
        position: absolute;
        left: -14px;
        top: 32px;
        bottom: 0;
        width: 9px;
        z-index: 3;
        background-color: #fafafa;
      }

      &.section--hidden {
        ::after {
          top: 36px;
        }

        :first-child {
         ::after {
           top: 56px;
         } 
        }
      }

      &.section--supersets {
        ::after {
          top: 45px;
        }
      }
    }

    :first-child {
      ::before {
        content: '';
        position: absolute;
        left: -14px;
        top: 0;
        width: 9px;
        height: 23px;
        z-index: 3;
        background-color: #fafafa;
      }

      &.section--hidden {
        padding-top: 20px;

        ::before {
          height: 49px;
        }

        .dot {
          top: 48px;
        }
      }

      &.section--supersets {
        ::before {
          height: 38px;
        }
      }
    }
  }
`;

export const Body = styled.div`
  padding: 0 17px 30px 15px;

  ${props =>
    props.loading
      ? css`
          background: #ffffff;
          height: 310px;
        `
      : ''};
`;

export const Detail = styled.div`
  background-color: #fafafa;
  margin-left: 0;
  margin-right: 4px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  overflow: hidden;
  position: relative;

  .workoutPreview__loader {
    position: absolute;
    opacity: ${props => (props.loading ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
    height: 134px;
    background: #f9f9f9;
  }
  .workoutPreview__contentHeader {
    position: absolute;
    opacity: ${props => (props.loading ? 0 : 1)};
    transition: opacity 0.3s ease-in-out;
  }

  @-moz-document url-prefix() {
    margin-right: 7px;
  }
`;

export const Content = styled.div`
  margin-left: 11px;
  margin-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: transparent;
  height: 100%;

  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2) !important;
  }

  ::-webkit-scrollbar-track {
    margin-top: 30px;
  }
`;

export const OwnerContainer = styled.div`
  border: 4px solid #f8f8f8;
  border-radius: 50%;
  position: absolute;
  right: -12px;
  top: -17px;
  z-index: 12;
`;

export const Container = styled.div`
  position: relative;
  padding: 6px 1px 0;
  background: url(${CDN_URL}/images/iphone_bg.svg) no-repeat;
  background-position: top left;
  background-size: 100% 100%;
  height: 100%;
  overflow: visible;
  width: 286px;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 9px;
    background-color: #f8f8f8;
    z-index: 9;
  }

  ::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 9px;
    background-color: #f8f8f8;
    z-index: 9;
  }

  .phone-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
`;

export const Footer = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 41px;
  background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #f8f8f8 50%);
`;

export const Wrapper = styled.div`
  width: auto;
  height: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: flex-start;
  padding: 5px 5px 0;
  border-left: 1px solid #f0f0f0;
  flex-direction: column;
  padding: 52px 24px 0;
`;
