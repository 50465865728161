import React from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

import { CDN_URL } from 'constants/commonData';

import ConfirmModal from 'shared/ConfirmModal';
import Filter from 'shared/SearchExercise/Filter';
import { NewSearchInput } from 'shared/SearchInput';
import { ToolbarContainer } from 'shared/LibraryLayout';
import { DEFAULT_CUSTOM_FILTERS } from 'shared/ExerciseFilterPopup';
import { changeQueryParams, removeSelectedSections, updateSelectedSection } from 'redux/section-library/actions';
import { FilterTrigger, ToolbarLeft, WrapperActions, ResetFilter } from 'components/ExerciseLibrary/style';
import CreateSectionTemplate from 'components/CreateSectionTemplate';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import * as S from './style';

function Toolbar(props) {
  const { query = '', changeQueryParams, selectedSection, bulkDelete, currentFilters, updateSelectedSection } = props;

  const onResetFilter = () => {
    updateSelectedSection();
    changeQueryParams({ owners: [], formats: [], page: 1 });
  };

  const hideResetButton = _.isEqual(currentFilters, _.omit(DEFAULT_CUSTOM_FILTERS, 'types'));

  const selectedLength = selectedSection ? selectedSection.length : 0;

  const searchSection = (event, { value }) => {
    const search = value.toLowerCase().trim();
    if (!search) {
      updateSelectedSection();
      changeQueryParams({ page: 1, query: '' });
    }
    if (search !== query) {
      updateSelectedSection();
      changeQueryParams({ page: 1, query: search });
    }
  };

  const searchDebounce = _.debounce(searchSection, 300);

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      updateSelectedSection();
      changeQueryParams({ page: 1 });
    }
  };

  const handleSectionFilter = filter => {
    const formats = _.get(filter, 'formats', []);
    const owners = _.get(filter, 'owners', []).map(owner => (_.isString(owner) ? owner : _.get(owner, '_id', '')));
    updateSelectedSection();
    changeQueryParams({ formats, owners, page: 1 });
  };

  const onDeleteSections = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Sections"
        content="Are you sure that you want to delete these sections?"
        confirmButtonTitle="Delete"
        newStyle={true}
        onConfirm={() =>
          bulkDelete &&
          bulkDelete(selectedSection, deletedIds => {
            handleToastMessage(deletedIds);
          })
        }
      />,
    );
  };

  const handleToastMessage = deletedIds => {
    if (deletedIds.length <= 1) {
      toast(`${deletedIds.length} section has been deleted`);
    } else {
      toast(`${deletedIds.length} sections have been deleted`);
    }
  };

  const onNew = () => {
    props.toggleModal(true, <CreateSectionTemplate />);
  };

  return (
    <ToolbarContainer>
      <ToolbarLeft>
        <NewSearchInput
          onChange={searchDebounce}
          onClearSearch={() => searchSection(null, { value: '' })}
          placeholder="Search by keyword or name"
          onKeyPress={onKeyPress}
        />
        <S.FilterWrapper>
          <Filter
            hideDefaultFilter
            title="Filter by:"
            hideTag
            isShowCustomFilter={true}
            onCustomFilter={handleSectionFilter}
            currentCustomFilters={currentFilters}
            menuWidth={379}
            menuHeight={327}
            trigger={<FilterTrigger>Filter</FilterTrigger>}
            classNameButtonUpdate="button--custom-purple"
          />
        </S.FilterWrapper>

        <ResetFilter onClick={onResetFilter} hide={hideResetButton}>
          Reset
        </ResetFilter>
        {selectedSection && !!selectedLength && (
          <S.DeleteButton onClick={onDeleteSections}>
            <DeleteIcon className="button--remove-section-icon"></DeleteIcon>
            <span>
              {selectedLength <= 1 ? 'Delete Section' : 'Delete Sections'} ({selectedLength})
            </span>
          </S.DeleteButton>
        )}
      </ToolbarLeft>
      <WrapperActions>
        <S.AddSectionButton purple className="button--add-new-section-lib" onClick={onNew}>
          <img className="add-section-icon" src={`${CDN_URL}/images/plus_white.svg`} alt="" />
          <span>Add New Section</span>
        </S.AddSectionButton>
      </WrapperActions>
    </ToolbarContainer>
  );
}

const mapState = state => {
  const {
    user,
    rootReducer: {
      sectionLibrary: {
        total,
        selectedSection,
        query: { page, limit, query, formats, owners },
      },
    },
  } = state;

  const currentFilters = { formats, owners };
  return { total, page, limit, user, query, selectedSection, currentFilters };
};

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  bulkDelete: bindActionCreators(removeSelectedSections, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  updateSelectedSection: bindActionCreators(updateSelectedSection, dispatch),
});

export default connect(mapState, mapDispatch)(Toolbar);
