import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManageGroups from './component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { getGroups, createGroup, updateGroup, deleteGroup, autocomplete } from 'redux/group/actions';

const mapStateToProps = state => ({
  isModalOpen: state.isModalOpen,
  groups: state.rootReducer.group.list,
});

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    getGroups: bindActionCreators(getGroups, dispatch),
    createGroup: bindActionCreators(createGroup, dispatch),
    updateGroup: bindActionCreators(updateGroup, dispatch),
    deleteGroup: bindActionCreators(deleteGroup, dispatch),
    autocomplete: bindActionCreators(autocomplete, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGroups);
