import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import ColumnItem from './ColumnItem';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const getProgressData = (item, type, view, isTotal) => {
  let percentCompleted = isTotal ? _.get(item, `${type}_${view}`, 0) : _.get(item, `${type}_${view}`, 0) * 100;
  if (view === 'all') {
    percentCompleted = isTotal ? _.get(item, type, 0) : _.get(item, type, 0) * 100;
  }
  return _.round(percentCompleted, 0);
};

const ComplianceColumn = ({ item, view }) => {
  const { status } = item;
  return (
    <Wrapper>
      <ColumnItem
        status={status}
        progressCircle={getProgressData(item, 'percent_completed_workout', view)}
        progress={`${getProgressData(item, 'total_completed_workout', view, true)} / ${getProgressData(
          item,
          'total_workout',
          view,
          true,
        )}`}
        progressPercent={`${getProgressData(item, 'percent_completed_workout', view)}%`}
        color="#45A5FF"
      />
      <ColumnItem
        status={status}
        progressCircle={getProgressData(item, 'percent_completed_task', view)}
        progress={`${getProgressData(item, 'total_completed_task', view, true)} / ${getProgressData(
          item,
          'total_task',
          view,
          true,
        )}`}
        progressPercent={`${getProgressData(item, 'percent_completed_task', view)}%`}
        color="#BC7CF8"
      />
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  const view = rootReducer.autoflowInterval.client.get('complianceView');
  return {
    view,
  };
};

export default connect(mapStateToProps, null)(ComplianceColumn);
