import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const ContentStyles = styled(Modal.Content)`
  padding: 30px !important;
  max-height: calc(100vh - 175px);
  text-align: center;
  overflow: auto;
  border-radius: 8px !important;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    img {
      margin-bottom: 15px;
    }
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    color: #000000;
    margin: 15px 0 35px;
  }

  .actions {
    button {
      width: 253px;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      padding: 12px;
    }
  }
`;

export default styled(Modal)`
  border-radius: 8px !important;
  width: 437px !important;
  & > form {
    padding: 0 !important;
  }
`;
