import React from "react";
import _ from "lodash";
import { CDN_URL } from "constants/commonData";
import * as S from "./style";

export default class UnsubscribeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initDefaultState(props);
  }

  initDefaultState = (props) => {
    return {};
  };

  componentDidMount() {
    let token = "";
    let type = "";
    const paramsString = _.get(this.props, "location.search", "");

    if (paramsString) {
      const params = new URLSearchParams(paramsString);
      token = params.get("token");
      type = params.get("type");
    }

    this.props
      .verifyUnsubscribeEmailInfo(token,type)
      .then((data, error) => {
        if (data && data.data && data.data.data) {
        }
      })
      .catch((data) => {
        this.setState({ error: data.message });
      });
  }
  render() {
    let color = this.state.error ? "red" : "#000000"
    return (
      <S.Wrapper>
        <S.LogoContainer>
          <img src={`${CDN_URL}/images/logo.png`} alt="" className="logo" />
        </S.LogoContainer>
        <S.Content>
          <img src={`${CDN_URL}/images/email_unsubscribed.svg`} alt="" className="email" />
          <S.Heading style={{ color: color }}>
            { this.state.error
              ? this.state.error
              : "You have unsubscribed from the Daily digest email"}
          </S.Heading>
          <S.Description>
            <p>
              If you would like to manage other emails from Everfit, please log
              in and check your{" "}
              <a href="/home/notifications">notifications settings</a>.
            </p>
          </S.Description>
          <S.BackToHome href="/">Close</S.BackToHome>
        </S.Content>
      </S.Wrapper>
    );
  }
}
