import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image, Icon } from 'semantic-ui-react';

import styled from 'styled-components';
import { Mixpanel } from 'utils/mixplanel';
import { deleteClients } from 'redux/autoflow/client/actions';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import FixedDropdown, { Option } from 'shared/Dropdown/Basic';
import {
  RemoveAutoflowClientModal,
  AutoflowPausedWarningModal,
  ActivateClientsModal,
  PauseClientsModal,
  MaskAsCompleteModal,
} from 'components/AutoflowConfirmModals';
import ChangeStartDateClientModal from './ChangeStartDateModal';
import { AUTOFLOW_STATUS, AUTOFLOW_TYPES, AUTOFLOW_CLIENT_STATUS, CDN_URL } from 'constants/commonData';
// import svg
import { ReactComponent as DateIcon } from 'assets/icons/date_gray.svg';

function RemoveClients(props) {
  const { workingAutoflow, clients, status } = props;

  if (!clients.length || !workingAutoflow) {
    return null;
  }

  return (
    <Container>
      <FixedDropdown
        triggerIcon={() => (
          <Button className="">
            <Image src={`${CDN_URL}/images/setting_fill_grey.svg`} />
            <span>Actions</span>
            <Icon className="chevron down" />
          </Button>
        )}
        type="light"
      >
        {status !== AUTOFLOW_CLIENT_STATUS.ACTIVE && (
          <Option
            onClick={() => {
              Mixpanel.track('autoflow_interval_client_listing_screen_start_resume_multi_client');
              if (workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED) {
                props.toggleModal(true, <AutoflowPausedWarningModal type={AUTOFLOW_TYPES.INTERVAL} multiple />);
              } else {
                props.toggleModal(
                  true,
                  <ActivateClientsModal type={AUTOFLOW_TYPES.INTERVAL} multiple clients={clients} />,
                );
              }
            }}
          >
            <div className="image-container">
              <Image src={`${CDN_URL}/images/play_fill_grey.svg`} />
            </div>
            <span>Start / Resume</span>
          </Option>
        )}
        {workingAutoflow.status === AUTOFLOW_STATUS.ACTIVATED &&
          status !== AUTOFLOW_CLIENT_STATUS.PAUSE &&
          status !== AUTOFLOW_CLIENT_STATUS.WAITING_TO_START && (
            <Option
              onClick={() => {
                Mixpanel.track('autoflow_interval_client_listing_screen_pause_multi_client');
                props.toggleModal(
                  true,
                  <PauseClientsModal type={AUTOFLOW_TYPES.INTERVAL} multiple clients={clients} />,
                );
              }}
            >
              <div className="image-container">
                <Image src={`${CDN_URL}/images/pause_fill_grey.svg`} />
              </div>
              <span>Pause</span>
            </Option>
          )}
        {status !== AUTOFLOW_CLIENT_STATUS.WAITING_TO_START && (
          <Option
            onClick={() => {
              Mixpanel.track('autoflow_interval_client_listing_screen_mark_as_complete_multi_client');
              props.toggleModal(
                true,
                <MaskAsCompleteModal type={AUTOFLOW_TYPES.INTERVAL} multiple clients={clients} />,
              );
            }}
          >
            <div className="image-container">
              <Image src={`${CDN_URL}/images/mark_as_completed.svg`} />
            </div>
            <span>Mark as Complete</span>
          </Option>
        )}
        {status !== AUTOFLOW_CLIENT_STATUS.ACTIVE && status !== AUTOFLOW_CLIENT_STATUS.PAUSE && (
          <Option
            onClick={() => {
              Mixpanel.track('autoflow_interval_client_listing_screen_change_start_date_multi_client');
              props.toggleModal(true, <ChangeStartDateClientModal type={AUTOFLOW_TYPES.INTERVAL} clients={clients} />);
            }}
          >
            <div className="image-container">
              <DateIcon />
            </div>
            <span>Change Start Date</span>
          </Option>
        )}
        <Option
          onClick={() => {
            Mixpanel.track('autoflow_interval_client_listing_screen_remove_multi_client');
            props.toggleModal(
              true,
              <RemoveAutoflowClientModal type={AUTOFLOW_TYPES.INTERVAL} multiple clients={clients} />,
            );
          }}
        >
          <div className="image-container">
            <Image src={`${CDN_URL}/images/trash_bordered_grey.svg`} />
          </div>
          <span>Remove</span>
        </Option>
      </FixedDropdown>
    </Container>
  );
}

const mapStateToProps = state => {
  const { autoflow, autoflowInterval } = state.rootReducer;
  const { common } = autoflow;
  const { client } = autoflowInterval;
  const { workingAutoflow } = common;

  return {
    workingAutoflow,
    clients: client.get('selectedClients').toJS(),
    status: client.getIn(['query', 'status']),
  };
};

const mapDispatchToProps = dispatch => ({
  deleteClients: bindActionCreators(deleteClients, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveClients);

const Container = styled.div`
  display: inline-block;

  ${Button} {
    display: inline-flex;
    width: 120px;
    padding-left: 15px;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;

    i.icon {
      line-height: 9px;
      color: #a1a1a5;
      font-size: 9px;
    }
  }

  .evf-dropdown__menu {
    width: 200px;
  }

  .evf-dropdown__option {
    padding: 7px 17px;

    .image-container {
      margin-right: 14px;
      width: 14px;
    }
  }
`;
