import React from 'react';
import { Image, List, Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import Urlify from 'shared/Urlify';
import NoteBuilder from './components/NoteBuilder';
import { formatDateCreatedNote } from 'utils/commonFunction';
import { SharedTooltip } from 'shared/SharedTooltip';

import { CDN_URL } from 'constants/commonData';

import { NoteWrapper, IndicatorWrapper } from './styles';

class ClientOverviewNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openingPopup: null,
      data: [],
      isLoadingNotes: true,
    };
  }

  componentDidMount() {
    const { selectedClient } = this.props;

    if (selectedClient && selectedClient._id) {
      this.getClientNote();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedClient } = this.props;

    if (selectedClient && selectedClient._id !== prevProps.selectedClient._id) {
      this.getClientNote();
    }
  }

  getClientNote = () => {
    const { getClientNote, type, selectedClient } = this.props;
    this.isLoadingNotes = true;
    this.setState({ isLoadingNotes: true });

    typeof getClientNote === 'function' &&
      getClientNote(type, selectedClient._id)
        .then(response => {
          const { data: { data = [] } = {} } = response;

          if (!isEmpty(data)) {
            const newData = orderBy(data, ['createdAt'], ['desc']);
            this.setState({ data: newData });
          }
        })
        .finally(() => {
          this.isLoadingNotes = false;
          this.setState({ isLoadingNotes: false });
        });
  };

  handleOpenNoteItem = item => () => {
    this.handleOpenNoteBuilder({ viewData: item });
  };

  onCallbackChangeNotes = notes => {
    let newNotesData = notes.filter(it => !it.isNewLocal);
    newNotesData = orderBy(newNotesData, ['createdAt'], ['desc']);

    this.setState({ data: newNotesData });
  };

  handleOpenNoteBuilder = ({ editData, viewData, openNewNote } = {}) => {
    const { data } = this.state;
    const { title, type, toggleModal, selectedClient } = this.props;

    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <NoteBuilder
          initNotes={data}
          onCallbackChangeNotes={this.onCallbackChangeNotes}
          editData={editData}
          viewData={viewData}
          selectedClient={selectedClient}
          title={title}
          type={type}
          openNewNote={openNewNote}
        />,
      );
  };

  addNewNote = () => {
    if (this.isLoadingNotes) return;

    this.handleOpenNoteBuilder({ openNewNote: true });
  };

  viewFirstNote = () => {
    if (this.isLoadingNotes) return;
    const { data } = this.state;

    this.handleOpenNoteBuilder({ viewData: !!data.length && data[0] });
  };

  renderItemNote = item => {
    const { readOnly } = this.state;
    const { user } = this.props;

    const dateString = formatDateCreatedNote({
      dateString: item.createdAt,
      timezone: user.timezone,
      isShowTime: true,
      isShowToday: true,
    });

    return (
      <div
        className={classnames('advanced-note-item', { 'read-only': readOnly })}
        key={item._id}
        onClick={this.handleOpenNoteItem(item)}
      >
        <div className="advanced-note-content">
          <span className="indicator">•&nbsp;</span>
          <span className="note-content">
            <Urlify>{item.content}</Urlify>
          </span>
        </div>
        <div className="advanced-note-date">{dateString}</div>
      </div>
    );
  };

  render() {
    const { title, type, selectedClient, showOnInbox, isPrivate, readOnly } = this.props;
    const { data, isLoadingNotes } = this.state;

    if (!this.props.selectedClient) {
      return null;
    }

    let typeEmpty = `Add a note about ${selectedClient.first_name}...`;

    if (type === 'limitation') {
      typeEmpty = `Add any medical note or injury about ${selectedClient.first_name}...`;
    }

    return (
      <NoteWrapper>
        <div
          className={classnames('eve-panel note-panel advanced', {
            'on-inbox': showOnInbox,
          })}
        >
          <div
            className={classnames('panel-header', {
              empty: data.length === 0,
            })}
          >
            {!showOnInbox && (
              <Image
                src={`${CDN_URL}/images/dashboard_${type}.svg`}
                className="header-title"
                onClick={this.viewFirstNote}
              />
            )}
            <div className="panel-title header-title" onClick={this.viewFirstNote}>
              {showOnInbox ? (
                <div>
                  <span>{title}</span>
                  <span> ({data.length})</span>
                </div>
              ) : (
                <div>
                  {title}&nbsp;<span className="small-title">({isPrivate ? 'private' : 'shared with client'})</span>
                </div>
              )}
            </div>
            {!isLoadingNotes && (
              <>
                <span
                  className={classnames('edit-icon', { 'read-only': readOnly })}
                  onClick={this.addNewNote}
                  data-for="note-icon-tooltip"
                  data-tip
                />

                <SharedTooltip content="Edit" position="top" id="note-icon-tooltip" />
              </>
            )}
          </div>
          {data && data.length > 0 && (
            <div className="panel-footer" id="advanced-note-footer">
              <List className="advanced-note-list">{data.map(this.renderItemNote)}</List>
            </div>
          )}
          {!isLoadingNotes && data && data.length === 0 && !showOnInbox && (
            <p>
              <div className="no-data" disabled={true} onClick={this.addNewNote} value={typeEmpty}>
                {typeEmpty}
              </div>
            </p>
          )}
          {isLoadingNotes && data && data.length === 0 && (
            <IndicatorWrapper>
              <Loader active inline="centered" className="custom-indicator" size="small">
                <span>Loading...</span>
              </Loader>
            </IndicatorWrapper>
          )}
        </div>
      </NoteWrapper>
    );
  }
}

export default ClientOverviewNote;
