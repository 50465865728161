import React from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import moment from 'moment';

import { Button } from 'shared/FormControl';
import LeftPanel from './components/LeftPanel';
import RightPanel from '../WorkoutDetailModal/components/RightPanel';
import MainPanel from './components/MainPanel';
import ConfirmModal from 'shared/ConfirmModal';
import { getWorkoutBuilderSetting, saveWorkoutSetting } from 'components/WorkoutDetailModal/helper';
import FeedbackAfterSaved from './components/LeftPanel/Feedback/FeedbackAfterSaved';
import AIWelcome from './components/LeftPanel/BetaAgreement/Welcome';
import AIConfirmModal from './components/LeftPanel/BetaAgreement/ConfirmModal';

import { WORKOUT_BUILDER_TYPES, WORKOUT_BUILDER_GUIDE_STEPS, CDN_URL } from 'constants/commonData';

import { Workout } from 'types/model';
import { checkDisabledFromAI } from './convert-data';
import { Mixpanel } from 'utils/mixplanel';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-circle.svg';

import * as S from 'components/WorkoutDetailModal/style';
import './styles.scss';

const shouldDisableSaveAction = workoutData => {
  let disabled = false;

  const { sections = [] } = workoutData;

  sections.forEach(section => {
    const { exercises = [] } = section;

    if (exercises.length) {
      exercises.forEach(it => {
        const { supersets = [] } = it;

        if (supersets.length) {
          supersets.forEach(superset => {
            const { exercise, alternatives = [] } = superset;

            if (alternatives.includes(exercise)) {
              disabled = true;
            }
          });
        }
      });
    }
  });

  return disabled;
};

export default class WorkoutDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isDeleting: false,
      isHideLeftPanel: getWorkoutBuilderSetting(props.user._id).isHideLeftPanel || false,
      clickCloseButton: false,
      isChanging: false,
      isReset: false,
      is_generated_by_ai: false,
      conversion_id: null,
      isAgreementAIBeta: props.isAgreementAIBeta || false,
    };
    this.mainPanelRef = null;
  }

  componentDidMount() {
    Mixpanel.track('open_ai_workout_builder', { component: this.props.workoutAIPage });
    this.openWorkoutAIAt = moment();

    if (!this.props.loadedOnboardingData) {
      this.props.getOnboardingWorkoutBuiler();
    }
    if (this.props.workingWorkout) {
      this.props.initWorkoutData(this.props.workingWorkout);
    } else {
      if (this.props.date) {
        this.props.initWorkoutData({ day: this.props.date, sections: [] });
      }
    }

    window.addEventListener('beforeunload', this.handleBeforeUnload);

    window.addEventListener('popstate', () => {
      this.onCloseCurrentPopup();
    });

    const pathName = _.get(window, 'location.pathname');
    if (!pathName.includes('/home/client')) {
      this.props.resetWorkingClientData();
    }
  }

  componentWillUnmount() {
    this.props.clearWorkoutData();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    window.removeEventListener('popstate', () => {}, false);

    const duration = moment().diff(this.openWorkoutAIAt, 'second');
    Mixpanel.track('close_ai_workout_builder', {
      component: this.props.workoutAIPage,
      duration,
    });
  }

  updateWorkoutFromAI = data => {
    this.setState(s => ({ ...s, ...data }));
  };

  handleChangeStatusIsUpdated = status => {
    this.setState({ isUpdated: status });
  };

  handleBeforeUnload = event => {
    if (this.props.isChanged) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  generateMainPanelRef = ref => {
    if (ref && ref.getWrappedInstance) {
      this.mainPanelRef = ref.getWrappedInstance();
    }
  };

  handleAfterSavedWK = (workoutSaved, workoutSubmitted) => {
    const { type, updateWorkoutId, updateOriginalWorkout, initWorkoutData } = this.props;
    const newWorkoutRes = { ...workoutSaved };

    if (newWorkoutRes.sections_target) {
      newWorkoutRes.sections = newWorkoutRes.sections_target;
    }

    const newOriginalWorkout =
      type === WORKOUT_BUILDER_TYPES.ASSIGNMENT
        ? _.toPlainObject(new Workout().parseFromAssignment(newWorkoutRes))
        : newWorkoutRes;

    updateOriginalWorkout(newOriginalWorkout);
    initWorkoutData(newWorkoutRes);

    if (!workoutSubmitted._id) {
      updateWorkoutId(newWorkoutRes._id);
    }
  };

  onClickSaveButton = () => {
    if (this.mainPanelRef) {
      this.mainPanelRef.handleCancelAddSection();
    }

    if (this.state.isSaving || this.props.isUploading) {
      return;
    }

    const { is_generated_by_ai, conversion_id, isUpdated } = this.state;
    const { tempWorkoutAI, workoutAIPage, validateWorkoutData, forceRating, selectedWorkout } = this.props;
    const workoutData = validateWorkoutData();

    if (workoutData && isUpdated) {
      this.handleSave({ ...workoutData, workoutId: (selectedWorkout || {})._id }, isUpdated);
      return;
    }

    if (workoutData) {
      Mixpanel.track('save_ai_workout_builder', { component: workoutAIPage });
      const hasChanged = _.isEqual(tempWorkoutAI, workoutData) === false;
      this.setState({ isSaving: true });
      this.props
        .onSave({ ...workoutData, is_generated_by_ai, conversion_id, is_edited: hasChanged })
        .then(newWorkout => {
          if (newWorkout) {
            toast('Workout has been saved');
            // const resetBtn = document.getElementById('resetAIBuilderBtn');
            // if (resetBtn) {
            //   resetBtn.click();
            // }
          } else {
            console.error('newWorkout undefined');
            return;
          }

          this.handleAfterSavedWK(newWorkout, workoutData);
          if (forceRating && conversion_id) {
            this.toggleFeedbackModal(conversion_id, hasChanged);
          }
          this.setState({ isUpdated: true });
        })
        .finally(() => {
          this.setState({ isSaving: false });
        });
    }
  };

  toggleFeedbackModal = (conversion_id, hasChanged) => {
    const { toggleConfirmModal, sendFeedback } = this.props;
    toggleConfirmModal(
      true,
      <FeedbackAfterSaved
        onClose={() => {
          toggleConfirmModal(false);
        }}
        hasChanged={hasChanged}
        sendFeedback={sendFeedback}
        conversion_id={conversion_id}
      />,
    );
  };

  onClickSaveAndCloseButton = (__, isSaveCopy = false) => {
    if (this.state.isSaving || this.props.isUploading) {
      return;
    }

    const { is_generated_by_ai, conversion_id, isUpdated } = this.state;
    const { tempWorkoutAI, workoutAIPage, validateWorkoutData, forceRating, selectedWorkout } = this.props;
    const workoutData = validateWorkoutData();

    if (workoutData && isUpdated && !isSaveCopy) {
      this.handleSave({ ...workoutData, workoutId: (selectedWorkout || {})._id }, isUpdated, true);
      return;
    }

    if (workoutData && isSaveCopy) {
      this.handleSave({ ...workoutData, is_generated_by_ai: false, conversion_id: null }, false, isSaveCopy);
      return;
    }

    if (workoutData) {
      if (shouldDisableSaveAction(workoutData)) {
        toast.error('Cannot add same alternative exercise with the original exercise');
      } else {
        Mixpanel.track('save_ai_workout_builder', { component: workoutAIPage });
        this.setState({ isSaving: true });

        const hasChanged = _.isEqual(tempWorkoutAI, workoutData) === false;
        this.props
          .onSave({
            ...workoutData,
            is_generated_by_ai,
            conversion_id,
            is_edited: hasChanged,
          })
          .then(response => {
            response && toast('Workout has been saved');
            this.onCloseCurrentPopup();
            if (forceRating && conversion_id) {
              this.toggleFeedbackModal(conversion_id, hasChanged);
            }
          });
      }
    } else {
      if (this.mainPanelRef) {
        this.mainPanelRef.handleCancelAddSection();
      }
    }
  };

  handleSave = (workoutData, isUpdated, isSaveAsCopy) => {
    this.setState({ isSaving: true });

    this.props
      .onSave(workoutData, isUpdated)
      .then(newWorkout => {
        if (newWorkout) {
          this.onIsChanging(false);
          toast('Workout has been saved');
          isSaveAsCopy && this.onCloseCurrentPopup();
        } else {
          console.error('newWorkout undefined');
          return;
        }
        !isSaveAsCopy && this.handleAfterSavedWK(newWorkout, workoutData);
      })
      .finally(() => {
        this.setState({ isSaving: false });
      });
  };

  onCloseCurrentPopup = () => {
    const { hideWorkout, selectedWorkout } = this.props;
    this.props.toggleModal(false);

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }

    this.props.markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection);

    hideWorkout && hideWorkout(false, _.get(selectedWorkout.toJS(), '_id', ''), true);
  };

  handleDenyClosePopup = () => {
    this.setState({ clickCloseButton: false });
  };

  handleCloseAction = () => {
    this.setState({ clickCloseButton: true }, () => {
      setTimeout(() => {
        if (this.state.isChanging) {
          this.props.toggleConfirmModal(
            true,
            <S.CustomConfirmModal
              onConfirm={this.onCloseCurrentPopup}
              onDeny={this.handleDenyClosePopup}
              noBorder
              title="Discard Changes?"
              content={`Are you sure you want to go? Changes have not been saved yet.`}
              confirmButtonTitle="Discard changes"
              hasCloseIcon
              headerIcon={`${CDN_URL}/images/discard_changes_icon.svg`}
            />,
          );
        } else {
          this.onCloseCurrentPopup();
        }
      }, 0);
    });
  };

  onDeleteWorkout = workout => {
    const { type } = this.props;
    this.setState({
      isDeleting: true,
    });
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title={type === WORKOUT_BUILDER_TYPES.TEMPLATE ? 'Remove Workout from Library' : 'Delete workout'}
        content={'Are you sure that you want to delete this workout?'}
        onConfirm={() => {
          this.onCloseCurrentPopup();

          if (typeof this.props.onDelete === 'function') {
            this.props.onDelete(workout);
          }
        }}
        onDeny={() => {
          this.setState({
            isDeleting: false,
          });
        }}
      />,
    );
  };

  onSaveAsCopy = () => {
    this.onClickSaveAndCloseButton(null, true);
  };

  toggleHideLeftPanel = () => {
    const { isHideLeftPanel } = this.state;
    this.setState({ isHideLeftPanel: !isHideLeftPanel });
    saveWorkoutSetting(this.props.user._id, { isHideLeftPanel: !isHideLeftPanel });
  };

  onIsChanging = value => {
    this.setState({ isChanging: value });
  };

  onCheckField = checked => {
    const { workingWorkout } = this.props;
    let { isDeleting, isChanging } = this.state;

    if (!isDeleting && workingWorkout && workingWorkout._id && (checked || isChanging)) {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          content="Would you like to leave the current screen? You will lose any unsaved data."
          onConfirm={() => {
            this.onIsChanging(false);
            this.props.logWorkout(this.props.workingWorkout);
          }}
        />,
      );
    }
  };

  handleConfirmReset = () => {
    this.props.clearWorkoutData();
    this.setState({ isReset: !this.state.isReset });
  };

  handleCloseBetaAgreement = () => {
    const { isAgreementAIBeta, toggleConfirmModal } = this.props;

    if (!isAgreementAIBeta) {
      toggleConfirmModal(
        true,
        <AIConfirmModal
          onConfirm={() => {
            toggleConfirmModal(false);
            this.handleCloseAction();
          }}
        />,
      );
    } else {
      this.handleCloseAction();
    }
  };

  render() {
    const {
      type,
      trackable,
      readOnly,
      isUploading,
      isCreateNew,
      workoutModel,
      selectedWorkout,
      workoutAIPage,
      updateFlagBetaAgreement,
      isAIOnboardingTour,
    } = this.props;
    const { isSaving, isHideLeftPanel, isChanging, isAgreementAIBeta, isSubmittedBetaAgree, isUpdated } = this.state;
    const isDisabled = checkDisabledFromAI(workoutModel, selectedWorkout);

    if (!isAgreementAIBeta) {
      return (
        <S.ModalWrapper
          open
          onClose={!isSubmittedBetaAgree && this.handleCloseBetaAgreement}
          closeOnDimmerClick={false}
          className="beta-agreement-wrapper"
        >
          <Modal.Content>
            {!isSubmittedBetaAgree && (
              <button className="close-button" onClick={this.handleCloseBetaAgreement}>
                <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
              </button>
            )}
            <AIWelcome
              onLetStartClick={() => this.setState({ isAgreementAIBeta: true })}
              onSubmitSuccess={() => this.setState({ isSubmittedBetaAgree: true })}
              updateFlagBetaAgreement={updateFlagBetaAgreement}
            />
          </Modal.Content>
        </S.ModalWrapper>
      );
    }

    return (
      <S.ModalWrapper open={true} onClose={isAIOnboardingTour && this.handleCloseAction} closeOnDimmerClick={false}>
        <Modal.Content>
          <S.ContentStyles className="leftContent">
            <button className="close-button" onClick={this.handleCloseAction}>
              <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
            </button>
            <S.ContentContainer className={classnames('leftContent__contentContainer', { minimize: true })}>
              <div className={classnames('workout-left-panel ai-left-panel', { hidden: isHideLeftPanel })}>
                <LeftPanel
                  updateWorkoutFromAI={this.updateWorkoutFromAI}
                  workoutAIPage={workoutAIPage}
                  isAIOnboardingTour={isAIOnboardingTour}
                  onChangeStatusIsUpdated={this.handleChangeStatusIsUpdated}
                />
              </div>
              <S.MainPanelContainer className="workoutBuilder__mainPanelContainer">
                <div
                  className={classnames('arrow-icon', { 'hidden-icon': isHideLeftPanel })}
                  onClick={this.toggleHideLeftPanel}
                >
                  <ArrowIcon data-tip={`${isHideLeftPanel ? 'Open' : 'Close'} Quick Panel`} />
                </div>
                <ReactTooltip place="top" type="dark" effect="solid" className="app-tooltip" />
                <MainPanel
                  workoutType={type}
                  trackable={trackable && isUpdated}
                  onSave={this.onClickSaveButton}
                  saveToLibrary={this.props.saveToLibrary}
                  saveAsCopy={this.onSaveAsCopy}
                  logWorkout={this.props.logWorkout}
                  onDelete={this.onDeleteWorkout}
                  ref={this.generateMainPanelRef}
                  readOnly={readOnly}
                  isHideLeftPanel={isHideLeftPanel}
                  workoutData={this.props.workingWorkout}
                  clientData={this.props.clientData}
                  pdfType={this.props.pdfType}
                  isChanging={isChanging}
                  onIsChanging={this.onIsChanging}
                  onCheckField={this.onCheckField}
                  isCreateNew={isCreateNew}
                />
              </S.MainPanelContainer>
            </S.ContentContainer>
            <S.Footer className="workoutBuilder_footerActions">
              <div className="footer-actions">
                <Button
                  purple
                  onClick={this.onClickSaveButton}
                  disabled={isSaving || readOnly || isUploading || isDisabled}
                  className="save"
                >
                  Save
                </Button>
                <Button
                  purple
                  onClick={this.onClickSaveAndCloseButton}
                  disabled={isSaving || readOnly || isUploading || isDisabled}
                  className="saveAndClose"
                >
                  Save &amp; Close
                </Button>
              </div>
            </S.Footer>
          </S.ContentStyles>
          <RightPanel
            fetchWorkoutsInRange={this.props.fetchWorkoutsInRange}
            fetchWorkoutsByWeek={this.props.fetchWorkoutsByWeek}
            maxWeeks={this.props.maxWeeks}
            hasTrackedWorkout={this.props.hasTrackedWorkout}
          />
        </Modal.Content>
      </S.ModalWrapper>
    );
  }
}
