import styled, { css } from 'styled-components';

export const LogoContainer = styled.div`
  min-height: 50px;
  margin: 0 auto 80px;
  img.logo {
    width: 85px;
  }
`;

export const Heading = styled.div`
  padding: 0 15px;
  position: relative;
  top: -10px;
  font-family: PN Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  display: inline-block;
  align-items: center;
  text-align: center;
  color: #000000;
`;

export const Description = styled.div`
  position: relative;
  top: 0px;
  font-family: PN;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

export const Content = styled.div`
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;
  padding: 0 40px;
  width: 500px;
  height: 410px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  img {
    position: relative;
    top: -27px;
    left: 25px;
    width: 155px;
  }
`;

export const Wrapper = styled.div`
  padding: 0 35px;
  text-align: center;
  padding-top: 30px;
  height: 100vh;
  min-height: 500px;
  position: relative;
  background: #FAFAFA;
  .download-link {
    display: inline-block;
    margin: 0 5px 10px;
  }

  @media (max-width: 500px) {
    ${Heading} {
      max-width: 300px;
    }
  }

  @media (max-height: 600px) {
    padding-top: 60px;
  }
`;

export const BackToHome = styled.a`
  margin: auto;
  margin-top: 55px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 10px 62px;
  width: 159px;
  height: 36px;
  background: #7470EF;
  border-radius: 5px;

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  align-self: center;
`;
