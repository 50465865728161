import React, { useRef, useState, useLayoutEffect } from 'react';
import classnames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import PropTypes from 'prop-types';
import MediaPlayer from 'shared/MediaPlayer';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import * as S from './style';

function MediaPreview(props) {
  const { isVideo, thumbnail, src, defaultThumbnail, trigger, type, disabled = false } = props;
  const [open, setOpen] = useState(false);
  const [popupStyles, setPopupStyles] = useState({});
  const triggerRef = useRef();
  const popup = useRef();

  useLayoutEffect(() => {
    if (open) {
      const rect = triggerRef.current.getBoundingClientRect();

      const { x, y, height, width } = rect;
      const windowHeight = window.innerHeight;
      const popupWidth = popup.current.clientWidth;
      const popupHeight = popup.current.clientHeight;
      const styles = { left: x + width / 2 - popupWidth / 2 };
      let className = 'top';

      if (y >= popupHeight + 10 || y >= windowHeight - y - height) {
        if (type === 'change-exercise') {
          styles.top = y - popupHeight - 10 - 63;
        } else {
          styles.top = y - popupHeight - 10;
        }
      } else {
        styles.top = y + height + 10;
        className = 'bottom';
      }

      setPopupStyles({ styles, className });
    }
  }, [open]);

  const toggleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <RootCloseWrapper disabled={!open} onRootClose={closePopup} event="click">
      <S.Wrapper
        className={classnames(
          'media-preview',
          {
            'media-preview--open': open,
            'disabled-action': disabled,
          },
          popupStyles.className,
        )}
      >
        {trigger ? (
          <div className="custom-trigger-container" ref={triggerRef} onClick={disabled ? undefined : toggleOpen}>
            {trigger}
          </div>
        ) : (
          <S.Trigger
            onClick={disabled ? undefined : toggleOpen}
            className="media-preview__trigger"
            ref={triggerRef}
            thumbnail={!!thumbnail && thumbnail !== MEDIA_PLACEHOLDER ? thumbnail : null}
            defaultThumbnail={defaultThumbnail}
            isVideo={isVideo}
            size={props.size}
            indicatorSize={props.indicatorSize}
            noData={!src}
          >
            {thumbnail ? (
              <S3ImageWithFallback
                cover
                fluid
                src={[thumbnail]}
                className={thumbnail === MEDIA_PLACEHOLDER ? 'media-placeholder' : ''}
              />
            ) : null}
          </S.Trigger>
        )}

        {open && (
          <S.PopupContainer className="media-preview__popup" ref={popup} style={popupStyles.styles}>
            <S.PopupContent className="media-preview__popupContent">
              <S.MediaContainer
                className={classnames('media-preview__content', { 'media-preview__content--empty': !src })}
              >
                {src ? (
                  <MediaPlayer thumbnail={thumbnail} url={src} type={isVideo ? 'video' : 'image'} generatePreview />
                ) : null}
              </S.MediaContainer>
              {props.children}
            </S.PopupContent>
          </S.PopupContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
}

MediaPreview.propTypes = {
  src: PropTypes.string,
  thumbnail: PropTypes.string,
};

export default MediaPreview;
