import styled from 'styled-components';

export const MenuContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  border-radius: 4px;
  width: 200px;
  display: none;
  padding-top: 4px;
  display: none;

  .add-client-menu {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eeeeee;
    background-color: #fff;
    padding: 8px 0;
  }

  .menu__item {
    cursor: pointer;
    color: #000;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 8px 18px 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > div:first-child {
      width: 26px;
      text-align: left;
    }

    :hover {
      /* color: #5c58c0; */
      background-color: #edecfc;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;

  .add-client__button {
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #777777;
    border-radius: 4px;
    border: 1px solid #d4d7d9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    height: 30px;

    i.icon {
      margin: 0;
      font-size: 10px;
    }

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      :hover {
        background-color: #f5f7f9;
      }
    }

    .add-button__single {
      padding: 0 11px 0 14px;
      white-space: nowrap;
     img { margin-right: 10px; }
    }

    .add-button__multiple {
      height: 100%;
      width: 30px;
      border-left: 1px solid #d4d7d9;
    }
  }

  &.open {
    ${MenuContainer} {
      display: block;
      width: 210px;
    }
  }
`;