import React, { useState } from 'react';
import { toUpper } from 'lodash';
import { connect } from 'react-redux';
import { Modal, Button, Image } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import ReactTooltip from 'react-tooltip';

import MediaPlayButton from 'shared/MediaPlayButton';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import './styles.scss';
import { convertS3UrlToCloudFrontUrl, formatShortLink, makeFriendlyYoutubeURL } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

function MediaModal(props) {
  const {
    video,
    image,
    presigned,
    validVideo,
    fileType,
    className = '',
    cloudfrontList,
    getS3presignedURLFromLocalDatabase,
  } = props;

  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(convertS3UrlToCloudFrontUrl(video || image, cloudfrontList, true));

  const onOpen = async () => {
    try {
      let newURL = convertS3UrlToCloudFrontUrl(video || image, cloudfrontList);
      if (!newURL) {
        const response = await getS3presignedURLFromLocalDatabase([video || image]);
        newURL = response[0];
      }
      setSrc(newURL || video || image);
      setOpen(true);
    } catch {
      if (!presigned) {
        setSrc(video || image);
      }

      setOpen(true);
    }
  };
  const onClose = () => {
    setOpen(false);
    setSrc('');
  };

  const contextMenu = e => {
    e.preventDefault();
  };

  const renderPlayButton = () => {
    const videoTypeName = toUpper(fileType);
    return (
      <div>
        <MediaPlayButton
          isVideo={!!video}
          className="media__play-button"
          data-tip={`${videoTypeName} files are not supported by your browser, but will be converted to MP4 and playable after you save.`}
          data-for="video-tooltip"
        />
        {!validVideo && !presigned && <ReactTooltip id="video-tooltip" />}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      onContextMenu={contextMenu}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      trigger={props.trigger || renderPlayButton()}
      className={`modal--player-with-react-player ${className}`}
    >
      <Modal.Content className="media-content">
        {video ? (
          <ReactPlayer
            url={makeFriendlyYoutubeURL(formatShortLink(src))}
            playing
            controls={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
          />
        ) : (
          <Image src={src || image} />
        )}
      </Modal.Content>
    </Modal>
  );
}

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapStateToProps, { getS3presignedURLFromLocalDatabase })(MediaModal);
