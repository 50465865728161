import styled from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';

export const ShowError = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
  }
  .ui.button.confirm-yes-button {
    background: rgb(245, 34, 45);
    border-radius: 5px;
    box-shadow: none;
    min-width: 93px;
    padding: 5px 30px;
  }
  .ui.button.confirm-yes-button:hover {
    box-shadow: none;
    background: rgba(245, 34, 45, 0.9);
  }
  .confirm-content-header {
    padding: 30px 30px 0 30px !important;
  }
  .confirm-header-image {
    width: 25px;
    height: 25px;
  }
  .confirm-header-label {
    line-height: 25px;
    color: #000000;
  }
  .confirm-content-body {
    padding: 15px 30px 30px 30px !important;
  }
`;
