import React, { useState } from 'react';
import { LIMIT_LOAD_ITEMS } from '../constants';
import ReactTooltip from 'react-tooltip';

import * as S from './style';

const AddNewForm = ({
  onCreateNew,
  icon,
  position,
  text,
  heightButton,
  widthButton,
  buttonClassName,
  titlePopup,
  description,
  titleInput,
  placeholderInput,
  submitName,
  maxLength,
  onSubmit,
  fields,
  popupModal = null,
  children,
  showLabel,
  shouldDisable,
  onClosePopup,
  onCreateNewWorkout,
  onSearchWorkouts,
  isDisabled,
  groupLabels,
  onUpdateFilters,
  currentFilters,
  onChangeSearchWorkouts,
  onClearSearchWorkouts,
  onSelectAll,
  disableSelectAll,
  categoryLabels,
  isDisabledOwner = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleCreateNew = () => {
    onCreateNew && onCreateNew();
    setOpen(true);
    onSearchWorkouts &&
      onSearchWorkouts({
        perPage: LIMIT_LOAD_ITEMS,
      });
  };

  const handleClosePopup = val => {
    setOpen(val);
    onClosePopup();
  };

  const handleAddWorkouts = () => {
    onSubmit && onSubmit();
    setOpen(false);
  };

  // TODO - TYPE MODE
  const PopupModal = popupModal;

  return (
    <>
      <S.Button
        text={text}
        icon={icon}
        position={position}
        height={heightButton}
        width={widthButton}
        className={buttonClassName}
        onClick={handleCreateNew}
        disabled={isDisabledOwner || isDisabled}
        data-tip
        data-for="add-btn-disabled"
      />
      {!isDisabledOwner && (
        <ReactTooltip id="add-btn-disabled" effect="solid" place={'top'} disable={!isDisabled}>
          <p>Clear search input before adding new workout</p>
        </ReactTooltip>
      )}

      <PopupModal
        open={open}
        titlePopup={titlePopup}
        description={description}
        titleInput={titleInput}
        placeholderInput={placeholderInput}
        submitName={submitName}
        maxLength={maxLength}
        onSetOpen={handleClosePopup}
        onSubmit={handleAddWorkouts}
        fields={fields}
        children={children}
        showLabel={showLabel}
        shouldDisable={shouldDisable}
        onSearchWorkouts={onSearchWorkouts}
        onCreateNewWorkout={onCreateNewWorkout}
        groupLabels={groupLabels}
        categoryLabels={categoryLabels}
        onUpdateFilters={onUpdateFilters}
        currentFilters={currentFilters}
        onChangeSearchWorkouts={onChangeSearchWorkouts}
        onClearSearchWorkouts={onClearSearchWorkouts}
        onSelectAll={onSelectAll}
        disableSelectAll={disableSelectAll}
      />
    </>
  );
};

export default AddNewForm;
