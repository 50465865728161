/**
 * @flow
 */

export const EXERCISE_CONTAINER_HEIGHT = 58;
export const EXERCISE_CONTAINER_MARGIN = 2;
export const ASSIGNMENT_CONTAINER_HEIGHT = 53;
export const ASSIGNMENT_CONTAINER_MARGIN = 6;

export default {
  calendarCell: {
    marginBottom: 4,
    borderRadius: 6,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    background: '#f3f5f8',
    border: '1px solid transparent',
    position: 'relative',
  },

  cellActive: {
    borderStyle: 'solid',
    borderColor: '#726EE4',
    borderWidth: '1px',
  },

  exercise: {
    display: 'flex',
    margin: EXERCISE_CONTAINER_MARGIN,
    backgroundColor: 'white',
    maxHeight: EXERCISE_CONTAINER_HEIGHT * 2,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    flexDirection: 'column',
    marginBottom: 6,
  },

  exerciseListContainer: {
    margin: '0px 4px 6px',
    padding: '0px 2px',
    background: 'rgba(255, 255, 255, 0.8)',
  },

  middleHover: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '100',
    alignItems: 'center',
    justifyContent: 'center',
  },

  exercisesListHeader: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 4,
  },

  exercisesListFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '6px 4px 0 2px',
  },

  popup: {
    borderRadius: '0',
    padding: '10px 30px 10px 10px',
    borderRadius: '8px',
    background: '#2D2E2D',
    color: '#ffffff',
  },
  popupIcon: {
    float: 'right',
    marginTop: '6px',
    cursor: 'pointer',
    marginLeft: 'auto',
    order: 2,
  },

  dropdownList: {
    width: '150px',
    position: 'relative',
    padding: '0px',
    paddingBottom: '15px',
  },

  dropdownListItem: {
    listStyle: 'none',
    float: 'left',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
  },

  dropdownListItemContainer: {
    float: 'left',
    cursor: 'pointer',
    paddingLeft: '15px',
  },

  dropdownListItemImage: {
    float: 'left',
    paddingTop: '3px',
    width: 14,
  },

  dropdownListItemTitle: {
    float: 'left',
    paddingBottom: '2px',
    paddingLeft: '12px',
  },
};
