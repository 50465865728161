import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

const statusVariants = {
  protein: {
    backgroundColor: '#57B5EA',
  },
  carbs: {
    backgroundColor: '#65CC9C',
  },
  fat: {
    backgroundColor: '#FFBE49',
  },
};

export const NutritionInfoDetailWrapper = styled.div`
  padding: 25px 0 0 30px;
  gap: 35px;
  display: flex;
  ${({ isAutoCalculated }) =>
    isAutoCalculated &&
    css`
      padding: 9px 0 0 0;
      margin-right: -35px;
    `}
`;

export const MacroSection = styled.div`
  border-radius: 10px;
  background: #fbfbfb;
  display: flex;
  gap: 31px;
  padding: 0 35px 25px 25px;
  ${({ isAutoCalculated }) =>
    isAutoCalculated &&
    css`
      padding-right: 27px;
    `}
  ${({ isEmptyValue }) =>
    isEmptyValue &&
    css`
      gap: 20px;
    `}
`;

export const MacroList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 25px;
  min-width: 96px;
`;

export const MacroItem = styled.div`
  ${baseText}
  position: relative;
  font-size: 12px;
  line-height: 18px;
  padding-left: 20px;
  letter-spacing: -0.6px;
  span.percent {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.6px;
    padding-left: 3px;
    span.percentage {
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.5px;
    }
  }
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => statusVariants[props.status].backgroundColor || 'transparent'};
    position: absolute;
    top: 7px;
    left: 0;
  }

  ${props =>
    props.isBlankData &&
    css`
      &::before {
        background-color: #a3a3b5;
      }
    `}
`;

export const Value = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: -0.95px;
  .value-empty {
    font-weight: 400;
    color: #7b7e91;
  }
`;

export const ValueUnit = styled.div`
  display: inline-block;
  ${baseText}
  color: #202353;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.75px;
`;

export const MacroMain = styled.div`
  text-align: center;
  flex: 1;
  padding-top: 25px;
`;

export const MacroChart = styled.div`
  margin: auto;
  position: relative;
  width: 104px;
  height: 104px;
  padding-left: 2px;
  padding-top: 2px;
`;

export const IconMacroChart = styled.div`
  position: absolute;
  top: calc(50% + 2px);
  left: 50%;
  transform: translate(-50%, -50%);
  ${props =>
    props.isBlankData &&
    css`
      svg path {
        fill: #a3a3b5;
      }
    `}
`;

export const MacroValue = styled.div`
  margin-top: 12px;
  ${baseText}
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  letter-spacing: -1.6px;
  .unit {
    font-size: 24px;
    line-height: 36px;
    padding-left: 2px;
    letter-spacing: -1.2px;
  }
  .value-empty {
    font-weight: 400;
    color: #7b7e91;
  }
`;

export const MacroName = styled.div`
  ${baseText}
  line-height: 20px;
  letter-spacing: -0.65px;
`;

export const NutritionInfo = styled.div`
  margin-top: 6px;
  flex: 1;
  max-height: 428px;
  overflow-y: scroll;
  padding-right: 15px;
  margin-right: 10px;
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1ea !important;
  }
`;

export const NutritionInfoItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
`;

export const Unit = styled.div`
  ${baseText}
  font-weight: 600;
  span {
    font-size: 11px;
    line-height: 16.5px;
  }

  .value-empty {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #7b7e91;
  }
`;

export const Name = styled.div`
  ${baseText}
  text-transform: capitalize;
`;

export const TitleNutritionInfo = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7b7e91;
  margin-bottom: 5px;
  text-transform: uppercase;
  ${({ isAutoCalculated }) =>
    isAutoCalculated &&
    css`
      font-size: 13px;
      line-height: 20px;
      color: #202353;
      text-transform: none;
    `}
`;

export const LabelOtherNutrients = styled.div`
  ${baseText}
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  color: #7b7e91;
  text-transform: uppercase;
  padding-top: 16px;
  padding-bottom: 5px;
  margin-top: -1px;
  background-color: #fff;
`;
