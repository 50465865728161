import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListStudioProgram, resetListData } from 'redux/studio-program/actions';
import { toggleSideBar } from 'actions/sidebar';
import StudioProgramList from './component';

const mapDispatch = (dispatch) => ({
  getListStudioProgram: bindActionCreators(getListStudioProgram, dispatch),
  resetListData: bindActionCreators(resetListData, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
});

export default connect(null, mapDispatch)(StudioProgramList);
