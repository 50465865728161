import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import moment from 'moment';

import { Button } from 'shared/FormControl';
import { Checkbox } from 'shared/FormControl';
import MetricDetail from './MetricDetail';
import CustomOption from './CustomOption';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import { MODES } from './constant';
import Header from './Header';
import OnboardingTooltip, { Content } from 'components/Onboarding/Tooltip';
import UpgradePathTooltipContent from 'shared/UpgradePath/components/UpgradePathTooltipContent';
import { CDN_URL, STEP_UNIQUE_CODE } from 'constants/commonData';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { addMultipleMetricToGroup } from 'actions/groupMetric';
import LoadingIndicator from 'shared/LoadingIndicator';
import { SPECIAL_METRICS_CODE } from 'components/BodyMetricChartNew/constants';

import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';
import './style.scss';

const MAX_PINNED_ITEM = 4;

function MetricSetting(props) {
  const {
    title,
    subTitle,
    showGuide,
    permission,
    modeProp = '',
    originMetrics,
    isFromToolbar = false,
    toggleModal,
    isLoadingGetMetric = false,
  } = props;
  const [metrics, setMetrics] = useState(originMetrics || []);
  const [mode, setMode] = useState(MODES.LIST);
  const [entity, setEntity] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [pinWarning, setPinWarning] = useState(false);
  const totalPin = _.sumBy(metrics, m => (m.pinned ? 1 : 0));
  const hasPermission = permission.custom_metrics;
  const [isPinProcessing, setIsPinProcessing] = useState(false);

  useEffect(() => {
    modeProp && setMode(modeProp);
  }, []);

  useEffect(() => {
    setMetrics(oldMetrics => {
      return _.map(originMetrics, item => {
        const obj = _.find(oldMetrics, m => m._id === item._id);

        if (obj) {
          return { ...item, selected: obj.selected, pinned: obj.pinned };
        }

        return { ...item };
      });
    });
  }, [originMetrics, mode]);

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const onSave = () => {
    if (isSaving) {
      return false;
    }

    const originalData = _.map(originMetrics, item => {
      const { pinned, selected, unit, unique_code, last_pin_at } = item;
      return { pinned, selected, unique_code, unit: unit._id, last_pin_at };
    });

    const newData = _.map(metrics, item => {
      const { pinned, selected, unit, unique_code, last_pin_at } = item;
      return { pinned, selected, unique_code, unit: unit._id, last_pin_at };
    });

    const changes = _.differenceWith(newData, originalData, _.isEqual);

    if (changes.length) {
      setSaving(true);
      props
        .onSave(changes)
        .then(response => {
          setSaving(false);
          onClose(response.data.data);
        })
        .catch(() => setSaving(false));
    } else {
      onClose();
    }
  };

  const onClose = (data = {}) => {
    if (typeof props.onClose === 'function') {
      return props.onClose(data);
    }
  };

  const onToggleMetricSelection = (metricId, checked) => {
    const newMetrics = _.map(metrics, m => (m._id === metricId ? { ...m, selected: checked, pinned: false } : m));
    const count = _.sumBy(newMetrics, m => (m.pinned ? 1 : 0));

    if (count < MAX_PINNED_ITEM) {
      setPinWarning(false);
    }

    setMetrics(newMetrics);
  };

  const onPinClick = event => {
    if (isPinProcessing) return;
    setIsPinProcessing(true);
    const { dataset } = event.target;
    const metric = _.find(metrics, item => item._id === dataset.metricId);

    if (metric) {
      if (metric.pinned) {
        const newMetrics = _.map(metrics, m => (m._id === metric._id ? { ...m, pinned: false } : m));
        setMetrics(newMetrics);
        setPinWarning(false);
      } else if (totalPin >= MAX_PINNED_ITEM) {
        setPinWarning(true);
      } else {
        const newMetrics = _.map(metrics, m => {
          if (m._id === metric._id) {
            return {
              ...m,
              pinned: true,
              last_pin_at: moment().toISOString(),
            };
          }
          return m;
        });
        setMetrics(newMetrics);
      }
      setTimeout(() => {
        setIsPinProcessing(false);
      }, 300);
    }
  };

  const onSelectUnit = data => {
    const metric = _.find(metrics, item => item._id === data.metricId);

    if (metric && _.get(metric, 'unit._id') !== data.value) {
      const newMetrics = _.map(metrics, m => (m._id === metric._id ? { ...m, unit: data } : m));
      setMetrics(newMetrics);
    }
  };

  const onEditMetric = item => {
    setMode(MODES.EDIT);
    setEntity(item);
  };

  const backToList = () => {
    setMode(MODES.LIST);
    setEntity(null);
  };

  const onAddNewMetric = () => {
    if (!hasPermission) {
      return;
    }
    setEntity(null);
    setMode(MODES.ADD);
  };

  const isPinned = useMemo(() => {
    return metrics.find(it => it.pinned);
  }, [metrics]);

  const renderMetricList = () => {
    return (
      <S.MetricListContainer className="metrics">
        <S.ListLabel className="metrics__label">
          <span>Metrics of the Team</span>
          {showGuide && (
            <OnboardingTooltip onClose={props.onCloseOnboardingTooltip} place="right" align="start" showInModal>
              <Content maxWidth="270">
                We’ve populated the list with some common metrics. Let’s choose one you would like to track and save
                your choice.
              </Content>
            </OnboardingTooltip>
          )}
        </S.ListLabel>
        <S.TableContainer className="metrics__tableContainer">
          {isLoadingGetMetric ? (
            <S.LoadingWrapper>
              <LoadingIndicator className="loading-metric-indicator" />
            </S.LoadingWrapper>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Metric</th>
                  <th>Unit</th>
                  <th>
                    <div className="header__pin-to-overview">
                      <span>Pin to Overview</span>
                      <img
                        src={`${CDN_URL}/images/new_info.svg`}
                        width="12"
                        data-tip
                        data-for="tooltip--pin-metric"
                        alt=""
                      />
                      <ReactTooltip
                        id="tooltip--pin-metric"
                        effect="solid"
                        place="top"
                        className="app-tooltip metric-pin-tooltip"
                      >
                        <span>{`Choose up to ${MAX_PINNED_ITEM} metrics to pin to client’s Overview`}</span>
                      </ReactTooltip>
                    </div>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {isLoadingGetMetric ? (
                  <S.LoadingWrapper>
                    <LoadingIndicator className="loading-metric-indicator" />
                  </S.LoadingWrapper>
                ) : (
                  _.map(metrics, item => {
                    const { name, selected, pinned, _id, category, unit, unique_code } = item;
                    let unitOptions = _.map(_.get(category, 'units', []), unitObject => ({
                      ...unitObject,
                      key: unitObject._id,
                      value: unitObject._id,
                      label: (
                        <span>
                          <b>{unitObject.name}</b> ({unitObject.title || '-'})
                        </span>
                      ),
                      metricId: _id,
                    }));

                    if (unique_code === SPECIAL_METRICS_CODE.SLEEP) {
                      unitOptions = unitOptions.filter(item => item.title !== 'sec');
                    }

                    const unitValue = unit
                      ? {
                          ...unit,
                          key: unit._id,
                          value: unit._id,
                          label: unit.title || '-',
                        }
                      : null;

                    const backgroundImageUrl = !!pinned
                      ? `${CDN_URL}/images/pin_yellow.svg`
                      : `${CDN_URL}/images/pin_grey.svg`;

                    return (
                      <tr key={item._id} className={selected ? 'selected' : ''}>
                        <td>
                          <Checkbox
                            checked={!!selected}
                            size={16}
                            circle
                            title={
                              <span>
                                {name}
                                {unique_code === STEP_UNIQUE_CODE && <S.ExtendInfo>(Automatic)</S.ExtendInfo>}
                              </span>
                            }
                            onChange={event => onToggleMetricSelection(item._id, event.target.checked)}
                          />
                        </td>
                        <td>
                          <Select
                            key={`unit-select-box--${_id}`}
                            options={unitOptions}
                            components={{
                              DropdownIndicator: props => (
                                <components.DropdownIndicator {...props}>
                                  <img src={`${CDN_URL}/images/down_arrowp.svg`} width="8" alt="" />
                                </components.DropdownIndicator>
                              ),
                              IndicatorSeparator: null,
                              Option: CustomOption,
                            }}
                            classNamePrefix="unit-select"
                            className="unit-select-box"
                            placeholder="-"
                            onChange={onSelectUnit}
                            value={unitValue}
                            isSearchable={false}
                            menuPosition="fixed"
                            styles={{
                              control: base => ({ ...base, minHeight: 28, height: 28, maxWidth: 80 }),
                              menu: base => ({
                                ...base,
                                minWidth: 170,
                                border: '1px solid #DADFEA',
                                marginTop: 2,
                                boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
                                borderRadius: 4,
                              }),
                            }}
                          />
                        </td>
                        <td>
                          {selected ? (
                            <S.Pin
                              data-metric-id={_id}
                              onClick={onPinClick}
                              style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                            />
                          ) : null}
                        </td>
                        <td>
                          {item.custom && (
                            <Dropdown direction="left">
                              <Option onClick={() => onEditMetric(item)}>
                                <img src={`${CDN_URL}/images/edit-white.svg`} width={14} alt="" />
                                <span>Edit</span>
                              </Option>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </S.TableContainer>
      </S.MetricListContainer>
    );
  };

  return (
    <Modal
      open={true}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      className={classnames('metrics-setting-modal', { detail: mode !== MODES.LIST })}
      onClose={() => onClose()}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.MetricSettingsWrapper>
          <ModalLayout.Header className="metric-settings__header">
            <Header
              mode={mode}
              title={title}
              subTitle={subTitle}
              onBack={backToList}
              showPinWarning={pinWarning}
              isFromToolbar={isFromToolbar}
            />
            {mode === MODES.LIST && pinWarning && (
              <S.WarningContainer>{`You can choose maximum ${MAX_PINNED_ITEM} metrics to pin`}</S.WarningContainer>
            )}
          </ModalLayout.Header>
          <ModalLayout.Content className="metric-settings__content">
            {mode === MODES.LIST ? (
              renderMetricList()
            ) : (
              <MetricDetail
                mode={mode}
                unitCategories={props.unitCategories}
                originalData={entity || {}}
                onBack={backToList}
                onAdd={props.onAdd}
                onUpdate={props.onUpdate}
                onDelete={props.onDelete}
              />
            )}
          </ModalLayout.Content>
          {mode === MODES.LIST ? (
            <ModalLayout.Actions className="metric-settings__actions">
              <Button className="button--add-new-metric features__toggle" onClick={onAddNewMetric}>
                <img src={`${CDN_URL}/images/grey_plus_icon.svg`} alt="" />
                <span>Add new metric</span>
                {hasPermission ? null : (
                  <UpgradePathTooltipContent
                    className="features__tooltipUpgradePath"
                    id="feat_custom-metrics"
                    heading="Add your own metrics"
                    description="Create custom metrics to track anything you want for your clients"
                    btnFullWidth
                    onClickUpgrade={onClose}
                  />
                )}
              </Button>
              <Button purple onClick={onSave} disabled={!isPinned}>
                Save
              </Button>
            </ModalLayout.Actions>
          ) : null}
        </S.MetricSettingsWrapper>
      </Modal.Content>
    </Modal>
  );
}

MetricSetting.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  originMetrics: PropTypes.array.isRequired,
  unitCategories: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    groupMetric: { metrics, list = [] },
  } = state;

  return {
    permission: state.rootReducer.permission,
    metricsNormalized: metrics,
    listGroupMetric: list,
  };
};

const mapDispatchToProps = {
  toggleModal,
  toggleConfirmModal,
  addMultipleMetricToGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricSetting);
