import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  .collectionHeading {
    padding: 15px;
    background: #fafbfc;
    border-bottom: 1px solid #dadfea;
    display: flex;

    &__icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background: url(${CDN_URL}/images/folder_icon.svg);
    }

    &__name {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      color: #202353;
      width: calc(100% - 50px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__dropdownOptions {
    }
  }

  .collectionInfo {
    padding: 15px;

    &__item {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #202353;

      :not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &.share-owner-avatar {
      display: grid;
      grid-template-columns: 1fr 20px;
      gap: 20px;
      justify-content: start;
      align-items: end;
    }
  }

  :hover {
    border-color: #5c5bbd;

    .collectionHeading {
      border-color: #5c5bbd;
      background: #f0f1ff;

      &__icon {
        background-image: url(${CDN_URL}/images/folder_icon_hover.svg);
      }

      &__name {
        color: #5c5bbd;
      }
    }
  }
`;

export const CollectionAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CollectionInfo = styled.div`
  .collectionInfo__name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
    max-width: 140px;
    min-width: 140px;
  }

  .collectionInfo__description {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
    min-width: 160px;
  }
`;
