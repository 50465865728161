import React from 'react';
import { components } from 'react-select';

const CustomLoadingMessage = props => {
  return (
    <components.LoadingMessage {...props}>
      <p>{`Loading...`}</p>
    </components.LoadingMessage>
  );
};

export default CustomLoadingMessage;
