import styled from 'styled-components';

export const Section = styled.div`
  padding: 60px 0;
  font-size: 14px;
  .upgrade-path-button {
    display: block;
    padding: 11px 13px;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 100%;
    border-radius: 3px;
  }
`;

export const Header = styled.div``;

export const Title = styled.div`
  font-size: 1.858rem;
  line-height: 150%;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3px;
`;

export const Description = styled.div`
  font-size: 1.15rem;
  line-height: 150%;
  text-align: center;
`;

export const Body = styled.div`
  display: block;
`;

export const Dots = styled.div`
  width: 10px;
  height: 10px;
  padding: 0;
  border: 1px solid #e8ebed;
  border-radius: 50%;

  &:before {
    content: none;
  }
`;

export const Slider = styled.div`
  display: block;

  .slick-dots {
    top: 10px;

    .slick-active {
      ${Dots} {
        background: #5c5bbd;
        border: none;
      }
    }

    li {
      width: initial;
      height: initial;
      margin: 0 4px;
    }
  }

  .slick-list {
    top: calc(25px + 89px);
    z-index: 1;
  }
`;

export const ItemText = styled.div`
  max-width: 349px;
  font-size: 1.15rem;
  line-height: 150%;
  margin-bottom: 40px;

  &.last {
    max-width: 380px;
  }
`;

export const Item = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: 0;
`;

export const ItemImage = styled.img`
  width: 100%;
`;

export const ItemContent = styled.div`
  padding-left: 42px;
`;

export const ItemTitle = styled.div`
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 12px;
`;

export const ItemLink = styled.a`
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ItemButton = styled.button`
  display: block;
  padding: 11px 13px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 700;
  font-size: .8rem;
  line-height: 100%;
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  text-transform: uppercase;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg,#fdc830 -18.69%,#f37335 182.76%);
  }
}`;

export const ItemImageArea = styled.div`
  display: inherit;
`;

export const ArrowSliderLeft = styled.div`
  left: 8px !important;
  z-index: 2;

  &:before {
    content: none !important;
  }
  &:hover,
  &:active,
  &:focus {
    svg {
      g {
        opacity: 1;
      }
    }
  }
  &.active {
    svg {
      g {
        opacity: 1;
      }
    }
  }
  &.disable {
    pointer-events: none;
  }
`;

export const ArrowSliderRight = styled.div`
  right: 20px !important;
  z-index: 2;

  svg {
    transform: rotateZ(180deg);
  }

  &:before {
    content: none !important;
  }
  &:hover,
  &:active,
  &:focus {
    svg {
      g {
        opacity: 1;
      }
    }
  }

  &.active {
    svg {
      g {
        opacity: 1;
      }
    }
  }

  &.disable {
    pointer-events: none;
  }
`;
