import React from 'react';
import { Button, Form, Label } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import GeneralButton from 'shared/GeneralButton';
import { validateEmail } from 'utils/validations';
import './styles.scss';

export default class ChangeEmail extends React.Component {
  state = {};

  handleSubmit(event) {
    event.preventDefault();

    this.handleChangeEmail();
  }

  handleChangeEmail() {
    if (!validateEmail(this.state.email)) {
      this.setState({ error: 'Please enter a valid email address' });
      return;
    }
    this.props.changeEmail({
      email: this.state.email,
      password: this.state.password,
    });
  }

  render() {
    return (
      <div className="accountdetails-container">
        <div className="changeemail">
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <Form.Group>
              <Form.Field>
                <Label className="setting-label">NEW EMAIL ADDRESS</Label>
                <input
                  className={'form-text'}
                  name="password"
                  onChange={evt => {
                    this.setState({ email: evt.target.value });
                  }}
                  value={this.state.email}
                  placeholder={'Enter your email'}
                  autoComplete="new-password"
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <Label className="setting-label">CURRENT PASSWORD</Label>
                <input
                  className={'form-text'}
                  type="password"
                  name="password"
                  onChange={evt => {
                    this.setState({ password: evt.target.value });
                  }}
                  value={this.state.password}
                  placeholder={'Enter your current password'}
                  autoComplete="new-password"
                />
              </Form.Field>
            </Form.Group>
            {this.state.error && (
              <Form.Group>
                <Form.Field>
                  <Label className="error-lable">{this.state.error}</Label>
                </Form.Field>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Field>
                <Button
                  className="setting-cancel-button"
                  onClick={() => {
                    this.props.dispatch(push('/home/accountdetails'));
                  }}
                  type="button"
                >
                  Cancel
                </Button>
                <GeneralButton
                  className="setting-save-button"
                  disabled={!this.state.email || !this.state.password}
                  type="submit"
                >
                  Save
                </GeneralButton>
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}
