import styled from 'styled-components';

export const UnlimitedSchedulingFailBackWrapper = styled.div`
  background: #FFFFFF70;
  position: absolute;
  z-index: 99;
  bottom: 0;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .fallback__content {
    text-align: left;

    .fallback__contentTitle {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #000000;
    }


    .fallback__upgradeBtn {
      background: transparent;
      padding: 0;
      
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #5158CF;
        text-transform: unset;


        :hover {
          text-decoration: underline;
        }
      }
    }

    .fallback__contentDescription {
      font-size: 14px;
      line-height: 150%;
    }

  }
`;