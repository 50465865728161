import styled from 'styled-components';
import Banner from 'assets/images/package_top_banner.png';
import { Button } from 'shared/FormControl';

export const Wrapper = styled.div`
  background: #eff6ff url(${Banner}) no-repeat left top;
  background-size: auto 100%;
  border-radius: 8px;
  min-height: 260px;
  width: 100%;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .button--hide-banner {
    position: absolute;
    z-index: 1;
    right: 15px;
    bottom: 15px;
    color: #978bb9;
  }
  @media screen and (max-width: 1210px) {
    background-image: none;
  }
`;

export const SingleBtnWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  button {
    width: 158px;
    padding: 8px 10px;
  }
`;

export const Slogan = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 150%;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  opacity: 0.8;
  margin-bottom: 15px;
`;

export const GuideContainer = styled.div`
  margin-bottom: 35px;

  .guide-link {
    color: #3d21ff;
    font-weight: 600;
  }
`;

export const ContentContainer = styled.div`
  margin: 30px 0 0 490px;
  max-width: 465px;

  @media screen and (max-width: 1210px) {
    margin-left: 30px;
    max-width: 386px;
  }
`;

export const CreateButton = styled(Button)`
  background: #5559ff;
  box-shadow: 0px 2px 8px rgba(0, 56, 255, 0.25) !important;
  border-radius: 5px;
  padding: 9px 14px;
  color: #fff;
  border-color: transparent;

  :hover {
    background: #5559ff;
  }
`;
