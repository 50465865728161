import { get } from 'lodash';
import { Types } from './actions';

export const INITIAL_STATE = {
  coachBioInfo: {},
  loading: false,
  updating: false,
  isOwnerEdit: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.RESET_COACH_BIOS_DETAIL_DATA:
      return Object.assign({}, state, { ...INITIAL_STATE });
    case Types.GET_COACH_BIOS_DETAIL_REQUEST:
      return Object.assign({}, state, { loading: true });
    case Types.GET_COACH_BIOS_DETAIL_SUCCESS:
      const { data, user } = payload;
      return Object.assign({}, state, {
        coachBioInfo: data,
        isOwnerEdit: get(user, '_id', '') === get(data, '_id', '') ? true : false,
        loading: false,
      });
    case Types.GET_COACH_BIOS_DETAIL_FAILED:
      return Object.assign({}, state, { loading: false });
    case Types.UPDATE_COACH_BIOS_DETAIL_REQUEST:
      return Object.assign({}, state, { updating: true });
    case Types.UPDATE_COACH_BIOS_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        coachBioInfo: payload.data,
        updating: false,
      });
    case Types.UPDATE_COACH_BIOS_DETAIL_FAILED:
      return Object.assign({}, state, { updating: false });

    default:
      return state;
  }
};
