// Libs
import React, { useState } from 'react';
import classNames from 'classnames';

// Constants
import { LIST_DISH_TYPE } from 'components/RecipeDetail/constants';

// Style
import * as S from './style';

const DishType = ({ onSelectedDishType = () => {}, value = '', label = 'Dish type', list = LIST_DISH_TYPE }) => {
  const [checked, setChecked] = useState(value);

  const handleChoiceDishType = value => {
    const newData = value !== checked ? value : checked;
    setChecked(newData);
    typeof onSelectedDishType === 'function' && onSelectedDishType(newData);
  };

  return (
    <S.Wrapper>
      <div className="label">{label}</div>
      <div className="list">
        {list.map(item => {
          const { label, key } = item || {};
          return (
            <div
              className={classNames('item', { active: checked === key })}
              key={key}
              onClick={() => handleChoiceDishType(key)}
            >
              <div className="checker"></div>
              {label}
            </div>
          );
        })}
      </div>
    </S.Wrapper>
  );
};

export default DishType;
