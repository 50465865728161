import React from 'react';
import _ from 'lodash';
import * as S from './style';
import { connect } from 'react-redux';
import { axiosInstance } from 'configs/request';
import SearchClientItem from './SearchClientItem';
import { bindActionCreators } from 'redux';
import { addClientToRedux } from 'redux/studio-program/actions';
import { CLIENT_STATUS } from 'constants/commonData';

class SearchClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      txtSearch: '',
      clients: [],
      results: [],
      isAddingAll: false,
    };
    this.handleSearch = _.debounce(this.handleSearch, 300);
  }
  componentDidMount() {
    this._ísMounted = true;
    this.handleSearch();
  }

  componentWillUnmount() {
    this._ísMounted = false;
  }

  onSearch = e => {
    const value = e.target.value;
    this.setState({ txtSearch: value }, () => {
      this.handleSearch(value.trim());
    });
  };

  onClearSearch = () => {
    this.setState({ txtSearch: '' }, this.handleSearch);
  };

  handleAddGroup = group => {
    if (this._adding) {
      return;
    }
    this._adding = true;
    const params = { group: group._id, include_trainer_info: true };
    axiosInstance
      .post('/api/client-management/client-group-list', params)
      .then(response => {
        const { data } = response.data;
        if (data.clients.length) {
          const { availableClients } = this.props;
          const unAssignClients = data.clients.filter(o => {
            return (
              (o.client_connection === CLIENT_STATUS.connected || o.client_connection === CLIENT_STATUS.pending) &&
              !o.is_archived &&
              availableClients.findIndex(c => c._id === o._id) === -1
            );
          });
          this.props.addClientToRedux(unAssignClients);
        }
      })
      .finally(() => {
        this._adding = false;
      });
  };

  handleSelectClient = client => {
    if (client.type === 'group') {
      this.handleAddGroup(client);
    } else {
      this.props.addClientToRedux([client]);
    }
  };

  onAddAllClient = async () => {
    if (this.state.isAddingAll) {
      return;
    }

    const { availableClients } = this.props;

    try {
      this.setState({ isAddingAll: true });
      const response = await axiosInstance.get('/api/studio-program/get-all-clients', {
        params: { include_trainer_info: true },
      });
      this.setState({ isAddingAll: false });
      const { data } = response.data;
      const unAssignClients = data.filter(o => {
        return (
          (o.client_connection === CLIENT_STATUS.connected || o.client_connection === CLIENT_STATUS.pending) &&
          !o.is_archived &&
          availableClients.findIndex(c => c._id === o._id) === -1
        );
      });
      this.props.addClientToRedux(unAssignClients);
    } catch {
      this.setState({ isAddingAll: false });
    }
  };

  handleSearch = txtSearch => {
    this.setState({ isSearching: true });
    if (!txtSearch) {
      return this.getRecentClients();
    }
    const params = { txt_search: txtSearch, include_trainer_info: true };
    axiosInstance
      .post('/api/client-management/client-group-list', params)
      .then(response => {
        const { data } = response.data;
        const clients = data.clients.map(o => ({
          ...o,
          type: 'client',
          name: `${o.first_name} ${o.last_name}`,
        }));
        const groups = data.groups.map(g => ({
          ...g,
          type: 'group',
        }));
        const results = _.orderBy([...clients, ...groups], ['name'], ['asc']).filter(
          item =>
            item.type === 'group' ||
            ((item.client_connection === CLIENT_STATUS.connected || item.client_connection === CLIENT_STATUS.pending) &&
              !item.is_archived),
        );
        this.setState({ clients, results });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  };

  getRecentClients = () => {
    axiosInstance
      .get('/api/client-management/client-most-recent-list', { params: { total: 500, include_trainer_info: true } })
      .then(response => {
        const { data } = response.data;
        const clients = data
          .filter(
            o =>
              (o.client_connection === CLIENT_STATUS.connected || o.client_connection === CLIENT_STATUS.pending) &&
              !o.is_archived,
          )
          .map(o => ({
            ...o,
            type: 'client',
            name: `${o.first_name} ${o.last_name}`,
          }));
        this.setState({ clients, results: clients });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  };

  render() {
    const { txtSearch, results } = this.state;
    const { availableClients, cloudfrontList } = this.props;
    const unAssignClients = results.filter(o => {
      return availableClients.findIndex(c => c._id === o._id) === -1;
    });
    return (
      <S.Wrapper>
        <S.SearchClientInput
          value={txtSearch}
          onChange={this.onSearch}
          onClearSearch={this.onClearSearch}
          placeholder="Search for your clients or groups"
        />
        <S.ResultsHeading>
          <div className="resultsHeading__mostRecent">
            {txtSearch.trim() ? 'Results' : 'Most Recent'} ({unAssignClients.length})
          </div>
          {txtSearch.trim() ? (
            <div />
          ) : (
            <div className="resultsHeading__addAll" onClick={this.onAddAllClient}>
              Add all clients
            </div>
          )}
        </S.ResultsHeading>
        <S.ResultsContainer>
          {unAssignClients.map(client => (
            <SearchClientItem
              key={client._id}
              client={client}
              onSelect={this.handleSelectClient}
              cloudfrontList={cloudfrontList}
            />
          ))}
        </S.ResultsContainer>
      </S.Wrapper>
    );
  }
}

const mapStateTopProps = state => {
  return {
    cloudfrontList: state.cloudfrontList,
    workingStudio: state.rootReducer.studioProgram.workingStudio,
    availableClients: state.rootReducer.studioProgram.availableClients,
  };
};

const mapDispatch = dispatch => ({
  addClientToRedux: bindActionCreators(addClientToRedux, dispatch),
});

export default connect(mapStateTopProps, mapDispatch)(SearchClients);
