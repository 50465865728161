import React from 'react';
import { connect } from 'react-redux';
import * as S from './style';
import PurchaseTable from './PurchaseTable';
import InvoiceTable from './InvoiceTable';
import { PACKAGE_ANALYTICS_VIEW_TYPE } from 'constants/commonData';

function Body({ viewType, isMP }) {
  return (
    <S.TableContainer>
      {viewType === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE ? <PurchaseTable isMP={isMP} /> : <InvoiceTable isMP={isMP} />}
    </S.TableContainer>
  );
}

const mapState = state => {
  const {
    rootReducer: { packageAnalytics },
  } = state;
  const { viewType } = packageAnalytics.toJS();
  return {
    viewType: viewType,
  };
};

export default connect(mapState, {})(Body);
