import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as CustomPointer } from 'assets/images/custom-color-picker-cursor.svg';
import { Button as PrimitiveButton } from 'shared/FormControl';

export const InputStyles = {
  input: {
    width: '235px',
    height: '42px',
    paddingLeft: '12px',
    border: 'none',
    outline: 'none',
  },
  label: {
    fontSize: '12px',
    color: '#000000',
    lineHeight: '18px',
  },
  wrap: {
    border: '1px solid #E1E5EC',
    borderRadius: '3px',
  },
};

const Button = ({ className, children, onClick, visible }) => (
  <PrimitiveButton className={className} onClick={onClick} visible={visible}>
    {children}
  </PrimitiveButton>
);

export const SaveButton = styled(Button)`
  flex: 0 0 110px;
  height: 32px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  background: ${props => (props.disabled ? '#c4c4c4' : '#5158cf')}
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${props => (props.disabled ? '#c4c4c4' : '#5559FF')}
  }
`;

export const ColorTitle = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 9px;
`;

export const ColorThemesContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
`;

export const ThemeSelectContainer = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 14px;
  }
`;

export const SaturationPointer = styled(CustomPointer)`
  transform: translate(-16px, -15px);
`;

export const HuePointer = styled(CustomPointer)`
  transform: translate(-15px, -10px);
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.01);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
`;

export const Header = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px;
  color: #202353;
`;

export const Close = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const ColorInput = styled.input`
  width: 65px;
  font-size: 13px;
  line-height: 18px;
  margin-left: 13px;
  outline: none;
  color: #000000;
  border: none;
  display: inline-block;
`;

export const ColorPickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const FloaterTargetContainer = styled.div`
  span {
    height: 34px;
  }
`;

export const ColorPickerContainer = styled.div`
  width: 255px;
  height: 378px;
  padding: 10px;
  background: #fff;
  border: 1px solid #dadaea;
  box-sizing: border-box;

  border-radius: 5px;
  user-select: none;
`;

export const SaturationContainer = styled.div`
  width: 235;
  height: 235px;
  position: relative;
  padding-bottom: 75%;
`;

export const HueContainer = styled.div`
  margin-top: 20px;
  width: 235px;
  height: 10px;
  position: relative;
  border-radius: 99999px;
  margin-bottom: 15px;
  div.hue-horizontal {
    border-radius: 99999px;
  }
`;

export const EditableInputContainer = styled.div`
  display: flex;
`;
export const color = styled.div`
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background: ${props => (props.color.includes('#') ? props.color : `#${props.color}`)};
  cursor: pointer;
`;
export const Swatch = styled.div`
  width: 160px;
  padding: 4px;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  span {
    height: 34px !important;
  }
`;
export const popover = styled.div`
  position: absolute;
  z-index: 2;
`;
export const cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const ColorBallsHeader = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 5px;

  color: #353766;
`;

export const ThemeContent = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  :before,
  :after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }

  :before {
    top: 0;
    background-color: ${props => (props.default ? props.primaryColor : props.secondaryColor)};
  }

  :after {
    bottom: 0;
    background-color: ${props => (props.default ? props.secondaryColor : props.primaryColor)};
  }
`;

export const ColorBallsContainer = styled.div`
  margin-left: 62px;
  width: 100%;
`;

export const ColorBallsSliderWrapper = styled.div`
  height: 51px;
  overflow-x: scroll;
  overflow-y: hidden;
  box-shadow: -200px 1px 20px -200px rgba(0, 0, 0, 0.27) inset;
  width: calc(180px ${props => !props.sideBarVisible && '+ 230px'});
  white-space: nowrap;
  @media only screen and (min-width: 1250px) {
    width: calc(325px ${props => !props.sideBarVisible && '+ 230px'});
    ${props => !props.sideBarVisible && 'box-shadow: none;'}
  }
  @media only screen and (min-width: 1400px) {
    width: calc(400px ${props => !props.sideBarVisible && '+ 230px'});
    ${props => !props.sideBarVisible && 'box-shadow: none;'}
  }
  @media only screen and (min-width: 1455px) {
    width: 100%;
    box-shadow: none;
  }
`;

export const ColorBallsSliderContainer = styled.div`
  // width: 494px;
`;

export const ThemeItem = styled.div`
  margin-left: ${props => (props.index === 0 ? '0px' : '12px')};
  background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
  border: 1px solid #dadfea;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${props =>
    props.customTheme &&
    `
  height: 44px;
  margin-top: 23px;
  margin-left: 20px;
  visibility: hidden !important;
  &.active {
    visibility: visible !important;
  }
  `}

  :hover {
    border-color: #7470ef;
    cursor: pointer;
  }

  ${props =>
    props.active &&
    css`
      border-color: #7470ef;
    `}

  ${props =>
    props.addNew &&
    css`
      width: 72px;
      height: 72px;

      .add-new-theme-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        display: none;
      }

      :hover {
        border-color: transparent;

        .add-new-theme-hover {
          display: block;
        }
      }
    `}

  .__react_component_tooltip.app-tooltip-white {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #323c47 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-color: #e8e8e8;
    position: absolute !important;
    top: unset !important;
    left: 50% !important;
    bottom: 60px !important;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;
