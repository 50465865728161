import styled, { css } from 'styled-components';

export const Comments = styled.div`
  padding: 0;
`;

export const Content = styled.div`
  flex: 1 1;
  padding: 15px 15px 0;
  overflow-x: hidden;
  overflow-y: overlay;

  .interactions {
    padding: 5px 0;
    border-top: 1px solid #dde2ed;
    border-bottom: 1px solid #dde2ed;
    margin-bottom: 15px;
  }

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  overflow: visible;
  font-family: 'Open Sans';
  color: #000;
  width: 100%;
  border-top: 1px solid #f4f4f4;
`;

export const Body = styled.div`
  padding: 5px 20px;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  outline: none !important;

  ${props =>
    props.empty &&
    css`
      ${Body} {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    `}
`;
