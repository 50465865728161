// Libs
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as CloseButton } from 'semantic-ui-react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Actions
import {
  getDetailRecipe,
  getDetailRecipeFromClientTab,
  getDetailRecipeFromMealPlan,
  getUnitType,
  getListOtherNutrient,
  getListDietary,
} from 'redux/recipes/actions';
import { toggleConfirmModal, toggleSecondModal } from 'actions/modal';

// Constants
import { CATEGORY_RECIPE, ENUM_DISH_TYPE, ListUnit, LIST_DISH_TYPE } from '../constants';
import { CDN_URL } from 'constants/commonData';

// Shared
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

// Parts
import Thumbnail from '../Thumbnail';
import RecipeName from '../RecipeName';
import DescriptionEditable from '../DescriptionEditable';
import TimeInfo from '../TimeInfo';
import DietaryInfo from '../DietaryInfo';
import NutritionOverview from '../NutritionOverview';
import MealDetail from '../MealDetail';
import LoadingIndicator from 'shared/LoadingIndicator';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';
import { ReactComponent as ReplaceIcon } from 'assets/icons/MealPlans/action_replace.svg';

// Styles
import * as S from './style';

const ViewRecipeDetail = ({
  toggleSecondModal,
  toggleConfirmModal,
  recipeId,
  getDetailRecipe,
  recipeDetail = {},
  onClose = () => {},
  onReplace = () => {},
  onRemove = () => {},
  isShowHeaderActions = false,
  isShowFooterActions = false,
  buttonContent = '',
  onAdd = () => {},
  mealGroup = {},
  getUnitType,
  isLogged = false,
  mealClientCompleted = false,
  mealPlanId,
  getDetailRecipeFromMealPlan,
  isGetFromMealPlan = false,
  mealPlanClient = '',
  isGetFromClientTab = false,
  getDetailRecipeFromClientTab,
  getListOtherNutrient,
  listOtherNutrients,
  listDietary = [],
  cloudfrontList,
  getListDietary = () => {},
}) => {
  const [data, setData] = useState({});
  const [isScroll, setIsScroll] = useState(false);

  const contentRef = useRef(null);

  const {
    cover_image = '',
    name = '',
    description = '',
    number_of_serving = 0,
    categories = [],
    dietaries = [],
    macro_nutrients = [],
    other_nutrients = [],
    intro_instruction = {},
    prepare_instructions = [],
    cooking_instructions = [],
    ingredients = [],
    prep_time_text,
    cooking_time_text,
    unit: unitType,
    dish_type: dishType = ENUM_DISH_TYPE.MAIN_DISH,
    ingredient_note: ingredientNote = '',
    ingredient_text: ingredientText = '',
  } = data;

  const resultCategory = CATEGORY_RECIPE.filter(item => categories.includes(get(item, 'value', '')));
  const resultDietary = listDietary.filter(item => dietaries.includes(get(item, 'value', '')));

  useEffect(() => {
    typeof getListOtherNutrient === 'function' && getListOtherNutrient();
    if (recipeId) {
      getDetail(recipeId);
    }
  }, []);

  const getDetail = async recipeId => {
    if (recipeId) {
      try {
        // Get unit type
        const resUnitType = await getUnitType();
        const unitTypeKey = get(resUnitType, 'data.data.unit_type');
        const unitType = ListUnit.find(item => item && item.key === unitTypeKey);

        let res = {};

        if (!isEmpty(recipeDetail)) {
          setData({ ...recipeDetail, unit: unitType });
          return;
        }

        if (isGetFromMealPlan && mealPlanId) {
          res = await getDetailRecipeFromMealPlan(mealPlanId, recipeId);
        } else if (isGetFromClientTab && mealPlanId) {
          res = await getDetailRecipeFromClientTab(mealPlanId, recipeId);
        } else {
          res = await getDetailRecipe(recipeId, false);
        }

        const result = get(res, 'data.data', {});

        setData({ ...result, unit: unitType });
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const onScroll = () => {
    const contentElement = contentRef && contentRef.current;
    setIsScroll(contentElement && contentElement.scrollTop > 0);
  };

  const renderHeaderActions = () => {
    return (
      <DropDown
        className="custom-dropdown-actions"
        triggerIcon={({ open }) => (
          <MenuTrigger className="dropdown-trigger" vertical active={!!open} itemId={recipeId} />
        )}
        direction="left"
        isNewSpacing
      >
        <Option key="duplicate" onClick={() => onReplace(mealGroup, mealPlanClient)}>
          <S.OptionIcon className="icon">
            <ReplaceIcon className="client-recipe-replace-icon" />
          </S.OptionIcon>
          <span>Replace</span>
        </Option>
        <Option key="edit" onClick={() => onRemove(mealPlanClient)}>
          <S.OptionIcon className="icon">
            <RemoveIcon className="client-recipe-remove-icon" />
          </S.OptionIcon>
          <span>Remove</span>
        </Option>
      </DropDown>
    );
  };

  const renderFooterActions = () => {
    return (
      <S.FooterButtonActions onClick={onAdd}>
        <S.FooterButtonContent>{buttonContent || 'Default'}</S.FooterButtonContent>
      </S.FooterButtonActions>
    );
  };

  const renderDishType = () => {
    const dataDishType = LIST_DISH_TYPE.find(item => get(item, 'key', '') === dishType);
    const { label, key, icon } = dataDishType || {};

    if (isEmpty(dataDishType)) return;

    return (
      <S.CategoryItem dishType={key}>
        {icon}
        {label}
      </S.CategoryItem>
    );
  };

  if (isEmpty(data))
    return (
      <S.Wrapper
        open={true}
        closeOnDimmerClick={false}
        onClose={onClose}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <S.LoadingContainer>
          <LoadingIndicator className="recipe-loading-indicator" />
        </S.LoadingContainer>
      </S.Wrapper>
    );

  return (
    <S.Wrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <S.Header isScroll={isScroll && isShowHeaderActions}>
        {isShowHeaderActions && !isLogged && !mealClientCompleted ? renderHeaderActions() : null}
      </S.Header>
      <S.Content ref={contentRef} onScroll={onScroll} isShowFooterActions={isShowFooterActions}>
        <S.Overview>
          <S.Thumbnail>
            <Thumbnail src={cover_image} isEditAble={false} />
          </S.Thumbnail>
          <S.Information>
            <RecipeName value={name} isEditMode={false} />
            <S.ListCategory>
              {renderDishType()}
              {resultCategory.map(item => {
                const { label, value } = item;
                return <S.CategoryItem key={value}>{label}</S.CategoryItem>;
              })}
            </S.ListCategory>
            <DescriptionEditable
              description={description}
              isEditMode={false}
              toggleModal={isShowFooterActions ? toggleConfirmModal : toggleSecondModal}
            />
            <TimeInfo
              numberOfServing={number_of_serving}
              isEditMode={false}
              PrepTimeText={prep_time_text}
              CookingTimeText={cooking_time_text}
            />
          </S.Information>
        </S.Overview>
        <S.DietaryInfo>
          <DietaryInfo
            value={resultDietary}
            isEditMode={false}
            dietaryRecipe={listDietary}
            cloudfrontList={cloudfrontList}
            getListDietary={getListDietary}
          />
        </S.DietaryInfo>
        <S.NutritionOverview>
          <NutritionOverview MacroNutrients={macro_nutrients} />
        </S.NutritionOverview>
        <S.MealDetail isEdit={false}>
          <MealDetail
            isEditMode={false}
            MacroNutrients={macro_nutrients}
            OtherNutrients={other_nutrients}
            IntroInstruction={intro_instruction}
            PrepareInstructions={prepare_instructions}
            CookingInstructions={cooking_instructions}
            listIngredient={ingredients}
            unitType={unitType}
            listOtherNutrients={listOtherNutrients}
            ingredientNote={ingredientNote}
            ingredientText={ingredientText}
          />
        </S.MealDetail>
      </S.Content>
      {isShowFooterActions ? <S.Footer>{renderFooterActions()}</S.Footer> : null}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes },
    cloudfrontList,
  } = state;

  const listOtherNutrients = get(recipes, 'otherNutrient', []);
  const listDietary = get(recipes, 'listDietary', []);

  return { listOtherNutrients, listDietary, cloudfrontList };
};

const mapDispatchToProps = dispatch => ({
  getDetailRecipe: bindActionCreators(getDetailRecipe, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getUnitType: bindActionCreators(getUnitType, dispatch),
  getDetailRecipeFromMealPlan: bindActionCreators(getDetailRecipeFromMealPlan, dispatch),
  getDetailRecipeFromClientTab: bindActionCreators(getDetailRecipeFromClientTab, dispatch),
  getListOtherNutrient: bindActionCreators(getListOtherNutrient, dispatch),
  getListDietary: bindActionCreators(getListDietary, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecipeDetail);
