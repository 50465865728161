export default [
  { key: 'AL', label: 'Alabama', value: 'AL' },
  { key: 'AK', label: 'Alaska', value: 'AK' },
  { key: 'AS', label: 'American Samoa', value: 'AS' },
  { key: 'AZ', label: 'Arizona', value: 'AZ' },
  { key: 'AR', label: 'Arkansas', value: 'AR' },
  { key: 'CA', label: 'California', value: 'CA' },
  { key: 'CO', label: 'Colorado', value: 'CO' },
  { key: 'CT', label: 'Connecticut', value: 'CT' },
  { key: 'DE', label: 'Delaware', value: 'DE' },
  { key: 'DC', label: 'District Of Columbia', value: 'DC' },
  { key: 'FM', label: 'Federated States Of Micronesia', value: 'FM' },
  { key: 'FL', label: 'Florida', value: 'FL' },
  { key: 'GA', label: 'Georgia', value: 'GA' },
  { key: 'GU', label: 'Guam Gu', value: 'GU' },
  { key: 'HI', label: 'Hawaii', value: 'HI' },
  { key: 'ID', label: 'Idaho', value: 'ID' },
  { key: 'IL', label: 'Illinois', value: 'IL' },
  { key: 'IN', label: 'Indiana', value: 'IN' },
  { key: 'IA', label: 'Iowa', value: 'IA' },
  { key: 'KS', label: 'Kansas', value: 'KS' },
  { key: 'KY', label: 'Kentucky', value: 'KY' },
  { key: 'LA', label: 'Louisiana', value: 'LA' },
  { key: 'ME', label: 'Maine', value: 'ME' },
  { key: 'MH', label: 'Marshall Islands', value: 'MH' },
  { key: 'MD', label: 'Maryland', value: 'MD' },
  { key: 'MA', label: 'Massachusetts', value: 'MA' },
  { key: 'MI', label: 'Michigan', value: 'MI' },
  { key: 'MN', label: 'Minnesota', value: 'MN' },
  { key: 'MS', label: 'Mississippi', value: 'MS' },
  { key: 'MO', label: 'Missouri', value: 'MO' },
  { key: 'MT', label: 'Montana', value: 'MT' },
  { key: 'NE', label: 'Nebraska', value: 'NE' },
  { key: 'NV', label: 'Nevada', value: 'NV' },
  { key: 'NH', label: 'New Hampshire', value: 'NH' },
  { key: 'NJ', label: 'New Jersey', value: 'NJ' },
  { key: 'NM', label: 'New Mexico', value: 'NM' },
  { key: 'NY', label: 'New York', value: 'NY' },
  { key: 'NC', label: 'North Carolina', value: 'NC' },
  { key: 'ND', label: 'North Dakota', value: 'ND' },
  { key: 'MP', label: 'Northern Mariana Islands', value: 'MP' },
  { key: 'OH', label: 'Ohio', value: 'OH' },
  { key: 'OK', label: 'Oklahoma', value: 'OK' },
  { key: 'OR', label: 'Oregon', value: 'OR' },
  { key: 'PW', label: 'Palau', value: 'PW' },
  { key: 'PA', label: 'Pennsylvania', value: 'PA' },
  { key: 'PR', label: 'Puerto Rico', value: 'PR' },
  { key: 'RI', label: 'Rhode Island', value: 'RI' },
  { key: 'SC', label: 'South Carolina', value: 'SC' },
  { key: 'SD', label: 'South Dakota', value: 'SD' },
  { key: 'TN', label: 'Tennessee', value: 'TN' },
  { key: 'TX', label: 'Texas', value: 'TX' },
  { key: 'UT', label: 'Utah', value: 'UT' },
  { key: 'VT', label: 'Vermont', value: 'VT' },
  { key: 'VI', label: 'Virgin Islands', value: 'VI' },
  { key: 'VA', label: 'Virginia', value: 'VA' },
  { key: 'WA', label: 'Washington', value: 'WA' },
  { key: 'WV', label: 'West Virginia', value: 'WV' },
  { key: 'WI', label: 'Wisconsin', value: 'WI' },
  { key: 'WY', label: 'Wyoming', value: 'WY' },
];
