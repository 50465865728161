/**
 * @flow
 */

import React from 'react';
import './styles.scss';
import { Button } from 'semantic-ui-react';

export default class GeneralButton extends React.Component<Props, State> {
  render() {
    const { title, className, withImage, onKeydown, type = 'button' } = this.props;
    let buttonClass = 'general-button ';

    if (className) {
      buttonClass += `${className} `;
    }

    if (withImage) {
      buttonClass += `with-image`;
    }

    return (
      <Button
        style={this.props.style}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        className={buttonClass}
        onKeyDown={onKeydown}
        type={type}
      >
        {title ? title : null}
        {this.props.children}
      </Button>
    );
  }
}
