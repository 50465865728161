// Libs
import React from 'react';

// Shared
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

// Assets
import DefaultImage from 'assets/icons/MealPlans/meal_plan_default.png';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-round.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/Ingredients/view.svg';

// Styles
import * as S from './style';

const renderActions = (id, handleGotoStartingDate, handleViewDetail) => {
  return (
    <DropDown
      className="custom-dropdown-actions"
      triggerIcon={({ open }) => <MenuTrigger className="drop-down-trigger" vertical active={!!open} itemId={id} />}
      direction="left top"
      isNewSpacing
    >
      <Option key="starting-date" onClick={handleGotoStartingDate}>
        <div className="icon">
          <ArrowRightIcon className="arrow-right-icon" />
        </div>
        <span>Go to the starting date</span>
      </Option>
      <Option key="view-detail" onClick={handleViewDetail}>
        <div className="icon">
          <EyeIcon className="eye-icon" />
        </div>
        <span>View detail</span>
      </Option>
    </DropDown>
  );
};

const MealPlanItem = ({
  name,
  coverImage,
  coverImageThumbnail,
  startDay,
  endDay,
  active = false,
  handleGotoStartingDate = () => {},
  handleViewDetail = () => {},
  id,
}) => {
  return (
    <S.Wrapper active={active}>
      {renderActions(id, handleGotoStartingDate, handleViewDetail)}
      <S.Thumbnail src={[coverImageThumbnail, coverImage]} defaultImage={DefaultImage} />
      <S.Content>
        <S.Name>{name}</S.Name>
        <S.Date>
          {startDay} - {endDay}
        </S.Date>
      </S.Content>
    </S.Wrapper>
  );
};

export default MealPlanItem;
