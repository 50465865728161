import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getImageDimensionFromUrl } from 'utils/commonFunction';
import SettingMobileAppPremium from './SettingMobileAppPremium';
import UpgradePath from 'shared/UpgradePath';
import CustomBrandingNoPermission from './CustomBrandingNoPermission';

import PremiumOptions from './PremiumOptions';
import {
  updateCustomBranding,
  updateCustomBrandingPremium,
  uploadFilesCustomBrandingPremium,
  uploadAppIconCustomBrandingPremium,
  publishAppIconCustomBrandingPremium,
  cancelAppIconCustomBrandingPremium,
} from 'redux/custom-branding/actions';

import { getPreSignedUrl } from 'redux/package-detail/actions';
import * as S from './style';

const Premium = props => {
  const {
    getCustomBrandingData,
    updateCustomBranding,
    updateCustomBrandingPremium,
    publishAppIcon,
    revokeLocalURL,
    uploadAppIconCustomBranding,
    uploadFilesCutomBranding,
    selectedWorkoutBackground,
    originalTheme,
    cancelAppIcon,
    publisedAppIcon,
    onCheckSaving,
    saving,
    discard,
    onChangeDiscard,
    user,
    selectedTodayScreenLogo,
    splash_screen_welcome_text_color,
    enableTodayScreenLogo,
    permission,
    appIconFlowType,
  } = props;
  const [screenActive, setScreenActive] = useState(0);
  const [splashLogo, setSplashLogo] = useState({ name: '', src: '' });
  const [splashBackground, setSplashBackground] = useState({ name: '', src: '' });
  const [welcomeTextColor, setWelcomeTextColor] = useState(splash_screen_welcome_text_color);
  const [appIcon, setAppIcon] = useState({});
  const [appIconReview, setAppIconReview] = useState({});
  const [appIconUrl, setAppIconUrl] = useState(null);
  const [appIconUploading, setAppIconUploading] = useState(null);
  const [workoutBackground, setWorkoutBackground] = useState(selectedWorkoutBackground);
  const [theme, setTheme] = useState(originalTheme);
  const [todayScreenLogo, setTodayScreenLogo] = useState({ name: '', src: '' });
  const [isSubmitLogo, setIsSubmitLogo] = useState(false);
  const [originalTodayLogoSize, setOriginalTodayLogoSize] = useState(null);

  useEffect(() => {
    revokeLocalURL();
    getCustomBrandingData();
  }, []);

  useEffect(() => {
    setWorkoutBackground(selectedWorkoutBackground);
  }, [selectedWorkoutBackground]);

  useEffect(() => {
    setWelcomeTextColor(splash_screen_welcome_text_color);
  }, [splash_screen_welcome_text_color]);

  useEffect(() => {
    setTheme(originalTheme);
  }, [originalTheme]);

  useEffect(() => {
    setTodayScreenLogo(selectedTodayScreenLogo);
  }, [selectedTodayScreenLogo]);

  useEffect(() => {
    handleGetOriginalSizeLogo(_.get(todayScreenLogo, 'src', ''));
  }, [todayScreenLogo]);

  const handleGetOriginalSizeLogo = async src => {
    if (!src) return src;

    const { width: originalWidth, height: originalHeight } = await getImageDimensionFromUrl(src);

    setOriginalTodayLogoSize({
      originalWidth: originalWidth,
      originalHeight: originalHeight,
      horizontalSide: originalWidth > originalHeight,
      verticalSide: originalWidth < originalHeight,
    });
  };

  return (
    <S.PremiumWrapper>
      <UpgradePath
        pathName="premiumCustomBrand"
        fallback={<CustomBrandingNoPermission onCheckSaving={onCheckSaving} appIconFlowType={appIconFlowType} />}
      >
        <SettingMobileAppPremium
          screenActive={screenActive}
          splashLogo={splashLogo}
          workoutBackground={workoutBackground}
          theme={theme}
          splashBackground={splashBackground}
          welcomeTextColor={welcomeTextColor}
          setSplashLogo={setSplashLogo}
          setWorkoutBackground={setWorkoutBackground}
          setSplashBackground={setSplashBackground}
          setAppIcon={setAppIcon}
          setAppIconReview={setAppIconReview}
          setAppIconUrl={setAppIconUrl}
          appIconUploading={appIconUploading}
          appIcon={appIcon}
          appIconReview={appIconReview}
          appIconUrl={appIconUrl}
          todayScreenLogo={todayScreenLogo}
          setTodayScreenLogo={setTodayScreenLogo}
          isSubmitLogo={isSubmitLogo}
          originalTodayLogoSize={originalTodayLogoSize}
        />

        <S.PremiumSetting />

        <PremiumOptions
          theme={theme}
          appIcon={appIcon}
          appIconReview={appIconReview}
          appIconUrl={appIconUrl}
          splashLogo={splashLogo}
          splashBackground={splashBackground}
          setWorkoutBackground={setWorkoutBackground}
          setTheme={setTheme}
          setScreenActive={setScreenActive}
          setSplashLogo={setSplashLogo}
          welcomeTextColor={welcomeTextColor}
          setWelcomeTextColor={setWelcomeTextColor}
          setSplashBackground={setSplashBackground}
          setAppIcon={setAppIcon}
          setAppIconReview={setAppIconReview}
          setAppIconUrl={setAppIconUrl}
          appIconUploading={appIconUploading}
          setAppIconUploading={setAppIconUploading}
          getCustomBrandingData={getCustomBrandingData}
          updateCustomBranding={updateCustomBranding}
          updateCustomBrandingPremium={updateCustomBrandingPremium}
          uploadFilesCutomBranding={uploadFilesCutomBranding}
          uploadAppIconCustomBranding={uploadAppIconCustomBranding}
          publishAppIcon={publishAppIcon}
          publisedAppIcon={publisedAppIcon}
          cancelAppIcon={cancelAppIcon}
          onCheckSaving={onCheckSaving}
          saving={saving}
          discard={discard}
          onChangeDiscard={onChangeDiscard}
          user={user}
          todayScreenLogo={todayScreenLogo}
          setTodayScreenLogo={setTodayScreenLogo}
          selectedTodayScreenLogo={selectedTodayScreenLogo}
          isSubmitLogo={isSubmitLogo}
          onSetIsSubmitLogo={setIsSubmitLogo}
          originalTodayLogoSize={originalTodayLogoSize}
          permission={permission}
          enableTodayScreenLogo={enableTodayScreenLogo}
        />
      </UpgradePath>
    </S.PremiumWrapper>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: {
      customBranding: {
        loading,
        selectedWorkoutBackground,
        selectedSplashLogo,
        selectedSplashBackground,
        splash_screen_welcome_text_color,
        selectedAppIconKey,
        selectedAppIconReview,
        selectedAppIconUrl,
        originalTheme,
        publisedAppIcon,
        selectedTodayScreenLogo,
        appIconFlowType,
      },
      generalSettings: { is_enable_today_screen },
      permission,
    },
  } = state;

  return {
    user,
    loading,
    selectedWorkoutBackground,
    selectedSplashLogo,
    selectedSplashBackground,
    splash_screen_welcome_text_color,
    selectedAppIconKey,
    selectedAppIconReview,
    selectedAppIconUrl,
    originalTheme,
    publisedAppIcon,
    selectedTodayScreenLogo,
    enableTodayScreenLogo: is_enable_today_screen,
    permission,
    appIconFlowType,
  };
};

const mapDispatch = dispatch => ({
  getPreSignedUrl: bindActionCreators(getPreSignedUrl, dispatch),
  updateCustomBranding: bindActionCreators(updateCustomBranding, dispatch),
  updateCustomBrandingPremium: bindActionCreators(updateCustomBrandingPremium, dispatch),
  uploadFilesCutomBranding: bindActionCreators(uploadFilesCustomBrandingPremium, dispatch),
  uploadAppIconCustomBranding: bindActionCreators(uploadAppIconCustomBrandingPremium, dispatch),
  publishAppIcon: bindActionCreators(publishAppIconCustomBrandingPremium, dispatch),
  cancelAppIcon: bindActionCreators(cancelAppIconCustomBrandingPremium, dispatch),
});

export default connect(mapState, mapDispatch)(Premium);
