import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';

import CommentItem from 'shared/CommentItem';
import { ReactComponent as IconViewmore } from 'assets/icons/icon-viewmore.svg';

import * as S from './style';

export default function CommentReplyItem(props) {
  const {
    item = {},
    onReplyMessage,
    showAttachItem,
    onUploadSuccess,
    onUploadFail,
    onCancelUpload,
    onGetListViewMore,
    replyList = [],
  } = props;
  const totalReply = get(item, 'total_replies', 0);
  const isViewMore = get(item, 'isViewMore', false);
  const latestReplyList = get(item, 'latestReplyList', []);
  const idComment = get(item, '_id', '');
  const latestReply = latestReplyList[0];
  const lengthReplyList = replyList.length;
  const isHasViewMore = lengthReplyList > 1;
  const isShowViewMore =
    (!isViewMore && totalReply > 0 && totalReply !== lengthReplyList) || (isViewMore && totalReply > lengthReplyList);

  const renderViewMore = () => {
    const numberViewMore = totalReply - lengthReplyList;
    const isViewMoreThanTen = numberViewMore >= 10;

    return (
      <S.CommentItemReplyWrapper>
        <S.TreeItem isViewMore={isHasViewMore}></S.TreeItem>
        <S.ViewMoreWrapper
          onClick={() => onGetListViewMore && onGetListViewMore({ comment_id: item._id, reply_id: latestReply._id })}
        >
          <IconViewmore />
          <span>{`View ${isViewMoreThanTen ? '' : numberViewMore} more ${
            numberViewMore !== 1 ? 'replies' : 'reply'
          }`}</span>
        </S.ViewMoreWrapper>
      </S.CommentItemReplyWrapper>
    );
  };

  return (
    <S.CommentItemWrapper id={idComment}>
      <S.CommentItemChild hasReply={lengthReplyList}>
        <CommentItem
          comment={item}
          showAttachItem={showAttachItem}
          onUploadSuccess={responseData => onUploadSuccess(item, responseData)}
          onUploadFail={onUploadFail}
          onCancelUpload={error => onCancelUpload(item, error)}
          hasReplyButton
          onReplyMessage={onReplyMessage}
        />
      </S.CommentItemChild>
      <S.ListReplyWrapper>
        {isShowViewMore && renderViewMore()}
        {map(replyList, item => {
          return (
            <S.CommentItemReplyWrapper key={item && item._id}>
              <S.TreeItem></S.TreeItem>
              <S.CommentItemReply
                comment={item}
                showAttachItem={showAttachItem}
                onUploadSuccess={responseData => onUploadSuccess(item, responseData)}
                onUploadFail={onUploadFail}
                onCancelUpload={error => onCancelUpload(item, error)}
                isReplyComment={true}
              />
            </S.CommentItemReplyWrapper>
          );
        })}
      </S.ListReplyWrapper>
    </S.CommentItemWrapper>
  );
}
