import React, { useRef, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { showOnboardingUserGuide, maximizeCheckList } from 'redux/onboarding/actions';
import VideoPlayer from 'shared/VideoPlayer';
import { axiosInstance } from 'configs/request';
import { Mixpanel } from 'utils/mixplanel';
import * as S from './style';
import { markIntroVideoWatched } from 'actions/auth/login';
import { isMobile } from 'react-device-detect';
import { hideTheMobileReminder, showTheMobileReminder, addResponsiveMetaTag } from 'utils/event-api';
import { CDN_URL } from 'constants/commonData';

function Component(props) {
  const { user } = props;
  const video = useRef();
  const button = useRef();

  if (isMobile) {
    addResponsiveMetaTag();
    props.maximizeCheckList();
    return <Redirect to={{ pathname: '/home/client' }} />;
  }

  const onReady = () => {
    if (isMobile) {
      showTheMobileReminder();
    } else {
      hideTheMobileReminder();
    }

    const currentTime = video.current.getVideoCurrentTime();
    axiosInstance.post('/api/profile/welcome_video_duration', { duration: currentTime });
    Mixpanel.track('Watched onboarding video', { duration: currentTime });
    props.markIntroVideoWatched();
    const params = new URLSearchParams(window.location.search);
    if (params.get('coupon')) {
      localStorage.setItem('hasCoupon', window.location.search);
    }
    props.push('/home/client');
    props.showOnboardingUserGuide();
    props.maximizeCheckList();
  };

  const onVideoEnded = event => {
    button.current.style.background = '#7470EF';
  };

  return (
    <S.Wrapper>
      <S.SideBar>
        <img src={`${CDN_URL}/images/logo_neon.png`} width="25" alt="" />
        <S.Dot />
        <S.Dot />
        <S.Dot />
      </S.SideBar>
      <S.ContentWrapper>
        <S.Content data-pop-up="welcome-message">
          <S.VideoContainer>
            <VideoPlayer
              ref={video}
              src="https://everfit-web-asset.s3-us-west-1.amazonaws.com/videos/everfit_welcome.mp4"
              onEnded={onVideoEnded}
            />
          </S.VideoContainer>
          <S.Information>
            <h1>
              Your clients will
              <S.HeartWrapper />
              Everfit, <span data-name-user="name-user">{user.first_name}!</span>
            </h1>
            <h3>Take 60 seconds to experience Everfit as your client.</h3>
            <S.Button onClick={onReady} ref={button} data-btn="button-ready">
              I’m ready!
            </S.Button>
          </S.Information>
        </S.Content>
      </S.ContentWrapper>
    </S.Wrapper>
  );
}

const mapState = state => {
  return { user: state.user };
};

const mapDispatch = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    showOnboardingUserGuide: bindActionCreators(showOnboardingUserGuide, dispatch),
    maximizeCheckList: bindActionCreators(maximizeCheckList, dispatch),
    markIntroVideoWatched: bindActionCreators(markIntroVideoWatched, dispatch),
  };
};

export default connect(mapState, mapDispatch)(Component);
