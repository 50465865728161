import styled from 'styled-components';

const horizontalPadding = 20;

export const Wrapper = styled.div`
  overflow: auto;
  padding: 24px ${horizontalPadding}px;
  .right-side-bar-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #000000;
  }
  .breaker {
    height: 1px;
    width: calc(100% + ${horizontalPadding * 2}px);
    background-color: #eeeeee;
    margin: 16px -${horizontalPadding}px;
  }
  .setting-trigger-banner-wrapper {
    margin-top: 20px;
  }
`;
