import React from 'react';
import _ from 'lodash';
import Select from 'react-select';

import FormItem from './FormItem';
import { DropdownIndicator } from 'shared/Icons';

import { SECTION_FORMAT_KEY } from 'constants/commonData';
import * as S from './style';
import { Button } from 'shared/FormControl';

export const SECTION_TYPE = {
  workout: 'Workout',
  warm_up: 'Warm up',
  cool_down: 'Cool down',
  recovery: 'Recovery',
};

const typeOptions = _.keys(SECTION_TYPE).map(value => ({
  value,
  key: value,
  label: SECTION_TYPE[value],
}));

class SectionForm extends React.PureComponent {
  state = {
    type: typeOptions[0],
  };

  componentDidMount() {
    const { format } = this.props;
    const { FREESTYLE } = SECTION_FORMAT_KEY;

    if (format === FREESTYLE) {
      this.setState({
        type: {
          key: FREESTYLE,
          label: null,
          value: _.keys(SECTION_TYPE)[0],
        },
      });
    }
  }

  handleChangeTitle = e => {
    this.setState({ title: e.target.value });
  };

  handleChangeRound = e => {
    const value = e.target.value;

    if (value > 99) {
      return;
    }

    const parsedValue = parseInt(value, 10);
    this.setState({ round: value !== 0 && isNaN(parsedValue) ? '' : parsedValue });
  };

  handleChangeTime = e => {
    const value = e.target.value;

    if (value > 999) {
      return;
    }

    const parsedValue = parseFloat(value, 10);
    this.setState({ time: isNaN(parsedValue) ? '' : parsedValue });
  };

  handleSelectType = type => {
    this.setState({ type });
  };

  isInvalidForm = () => {
    const { title } = this.state;
    return !title;
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmited: true });

    if (this.isInvalidForm()) {
      return;
    }

    const { format } = this.props;
    const { title, round, time, type } = this.state;
    const { FREESTYLE } = SECTION_FORMAT_KEY;
    let params = {
      format,
      title,
      type: type.value,
    };

    if (FREESTYLE === format) {
      params = {
        ...params,
        attachments: [],
      };
    } else {
      params = {
        ...params,
        round,
        time,
      };
      params.time = isNaN(parseFloat(params.time)) ? '' : params.time * 60;
    }
    this.props.onSubmit(params);
  };

  onCancel = () => {
    const { setStep } = this.props;

    setStep(1);
  };

  render() {
    const { format } = this.props;

    return (
      <S.Wrapper>
        <S.Content>
          <FormItem
            label={'Section Name'}
            placeholder="Please add a section name"
            required
            inputProps={{ autoFocus: true }}
            isSubmited={this.state.isSubmited}
            value={this.state.title}
            onChange={this.handleChangeTitle}
          />
          {format !== SECTION_FORMAT_KEY.FREESTYLE && (
            <S.FormControl>
              <S.Label>Section Type</S.Label>
              <Select
                key="section type"
                isSearchable={false}
                options={typeOptions}
                components={{ DropdownIndicator }}
                classNamePrefix="evfSelectBox"
                className="evfSelectBoxContainer"
                placeholder="Choose section type"
                onChange={this.handleSelectType}
                value={this.state.type}
              />
            </S.FormControl>
          )}
          {format === SECTION_FORMAT_KEY.AMRAP && (
            <FormItem
              label="duration"
              placeholder="Set duration in minutes"
              inputProps={{ min: 0 }}
              isSubmited={this.state.isSubmited}
              value={this.state.time}
              type="number"
              addOnsButton={<S.AddOnLabel>Min</S.AddOnLabel>}
              onChange={this.handleChangeTime}
            />
          )}
          {format === SECTION_FORMAT_KEY.TIMED && (
            <FormItem
              label="rounds"
              placeholder="Set number of rounds to complete"
              inputProps={{ min: 0 }}
              isSubmited={this.state.isSubmited}
              value={this.state.round}
              type="number"
              addOnsButton={<S.AddOnLabel>Round</S.AddOnLabel>}
              onChange={this.handleChangeRound}
            />
          )}
        </S.Content>

        <S.Footer>
          <div onClick={this.onCancel} className="add-section-form__cancel">
            Cancel
          </div>
          <Button onClick={this.handleSubmit} className="add-section-form__add-btn">
            Create Section
          </Button>
        </S.Footer>
      </S.Wrapper>
    );
  }
}

export default SectionForm;
