import styled, { keyframes, createGlobalStyle } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`;
export const MFPTooltipGlobalStyle = createGlobalStyle`
  #myfitnesspal-link {
    padding: 15px;
    width: 228px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
  }
`;

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
  height: 16px;

  .sync-section {
    display: flex;
    align-items: center;

    .loading-icon {
      display: flex;
      margin-right: 10px;
      align-items: center;
      animation: ${rotate} 2s linear infinite;

      * {
        fill: #6a778a;
        opacity: 0.7;
      }
    }
    .text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #202353;
      opacity: 0.5;

      span {
        font-weight: 700;
      }
    }
  }

  .connected-text {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-align: right;
    color: #a6acc6;
    user-select: none;
  }

  .myfitnesspal-link {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 150%;
    a {
      color: #5158cf;
    }

    .myfitnesspal-link-tooltip {
      display: flex;
      margin-left: 5px;

      &:hover {
        svg {
          * {
            fill: #5158cf;
          }
        }
      }

      svg {
        * {
          fill: #a6acc6;
        }
      }
    }
  }
`;
