import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const textBase = `
  font-family: 'Open Sans';
  font-style: normal;
  color: #202353;
  line-height: 150%;
`;

const activeTrigger = css`
  color: #202353;
  background: #f0f0f2;
`;

export const MealPlanHeaderContainer = styled.div`
  width: 100%;
`;

export const MealPlanHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 18px 20px 30px;
`;

export const MealPlanFilterWrapper = styled.div`
  display: flex;
  align-items: center;

  .search-input-meal-plan {
    width: 266px;
    height: 30px;
    input {
      padding-left: 44px;
      background: url(${CDN_URL}/images/search_bold.svg) no-repeat;
      background-size: auto;
      background-position: 15px center;
      background-color: #f8f8f8;

      &::placeholder {
        font-size: 13px;
      }
    }
  }
`;

export const NewMealPlanButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px 24px;
  height: 30px !important;
  border: none;
  background: #5158cf;

  .plus-icon {
    width: 12px;
    height: 12px;
    fill: #fff;
    margin-right: 10px;
  }

  .label {
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &:hover {
    background: rgb(81, 88, 207, 0.9) !important;
  }
`;

export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 18px 25px 30px;

  ${({ isShowDraft }) =>
    isShowDraft &&
    css`
      justify-content: flex-end;
    `}
`;

export const ResultTotal = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const MealPlansShowByOwner = styled.div`
  display: flex;
  border-radius: 5px;
  border: 1px solid #dadbea;
  height: 30px;
  overflow: hidden;
`;

export const AllPlans = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${textBase}
  font-size: 12px;
  font-weight: 600;
  width: 140px;
  padding: 0 14px;
  white-space: nowrap;
  cursor: pointer;
  ${props =>
    props.active
      ? activeTrigger
      : css`
          :hover {
            color: #5158cf;
            background: #f0f1ff;
          }
        `}
`;

export const YourPlans = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${textBase}
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding-left: 15px;
  border-left: 1px solid #dadbea;
  padding: 0 14px;
  white-space: nowrap;
  cursor: pointer;
  ${props =>
    props.active
      ? activeTrigger
      : css`
          :hover {
            color: #5158cf;
            background: #f0f1ff;
          }
        `}
`;

export const ActionsRight = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const ButtonContent = styled.div`
  ${textBase}
  text-align: center;
  font-size: 12px;
  font-weight: 600;
`;

export const ButtonShowDraft = styled.div`
  display: flex;
  height: 30px;
  padding: 0 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 99px;
  border: 1px solid #e1e1ea;
  background: #fff;
  cursor: pointer;

  .custom-close-circle {
    display: none;
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: #f0f1ff;
    ${ButtonContent} {
      color: #5158cf;
    }
  }

  ${({ isShowDraft }) =>
    isShowDraft &&
    css`
      border: 1px solid #5158cf;
      background: #f0f1ff;

      .custom-close-circle {
        display: block;

        path {
          fill: #5158cf;
        }
      }

      ${ButtonContent} {
        color: #5158cf;
      }
    `}
`;
