import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles.js';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';

function CircleDownload({
  onClick,
  backgroundColor = '#F0F1FF',
  height = '16px',
  width = '16px',
  hoverBackgroundColor = '#7470EF',
  hoverIconColor = '#ffffff',
  iconColor = '#7470EF;',
  className = '',
}) {
  const handleClick = () => {
    onClick && onClick();
  };
  return (
    <S.CircleContainer
      className={className}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverIconColor={hoverIconColor}
      iconColor={iconColor}
      onClick={onClick}
    >
      <DownloadIcon className="download-icon" />
    </S.CircleContainer>
  );
}

CircleDownload.propTypes = {
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  hoverIconColor: PropTypes.string,
  className: PropTypes.string,
};

export default CircleDownload;
