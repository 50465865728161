// Libs
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';

// Actions
import { toggleModal } from 'actions/modal';
import { changeOnboardingQuery, createOnboardingFlow } from 'redux/onboarding-flow-library/actions';

// Shared
import { NewSearchInput } from 'shared/SearchInput';

// Components
import CreateButton from '../CreateButton';
import CreateModal from '../CreateModal';

// Utils
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';

// Styles
import * as S from './style';

const Header = ({ toggleModal, changeOnboardingQuery, createOnboardingFlow, query, user }) => {
  const { text_search } = query || {};

  const handleSearch = (_, { value }) => {
    const trimmed = value.toLowerCase().trim();
    if (trimmed !== text_search) {
      changeOnboardingQuery({ text_search: trimmed });
    }
  };

  const handleClearSearch = () => {
    changeOnboardingQuery({ text_search: '' });
  };

  const handleSubmit = data => {
    const params = {
      owner: data.author,
      name: data.name,
      share: data.share,
    };

    typeof createOnboardingFlow === 'function' &&
      createOnboardingFlow(params, () => {
        toggleModal(false);
        if (params.owner !== (user || {})._id && params.share === TEAM_SHARE_PRIVATE) {
          toast('Onboarding flow has been saved');
        }
      });
  };

  const handleOpenCreatePopup = () => {
    toggleModal(true, <CreateModal onClose={() => toggleModal(false)} onSubmit={handleSubmit} />);
  };

  useEffect(() => {
    return () => {
      changeOnboardingQuery({ text_search: '' });
    };
  }, []);

  return (
    <S.Wrapper>
      <S.LeftActions>
        <NewSearchInput
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
          placeholder="Search by keyword or name"
          className="onboarding-library-search"
        />
      </S.LeftActions>
      <S.RightActions>
        <CreateButton contentButton="Create New Onboarding Flow" onClick={handleOpenCreatePopup} />
      </S.RightActions>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      onboardingLibrary: { filters },
    },
  } = state || {};

  return {
    user,
    query: filters,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  changeOnboardingQuery: bindActionCreators(changeOnboardingQuery, dispatch),
  createOnboardingFlow: bindActionCreators(createOnboardingFlow, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
