import React, { useMemo, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { DragDropContext } from 'react-beautiful-dnd';
import get from 'lodash/get';
import range from 'lodash/range';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { Mixpanel } from 'utils/mixplanel';
import { CALENDAR_TYPES, AUTOFLOW_STATUS, KEY_CODE, CLASSNAME_TOAST, CDN_URL } from 'constants/commonData';
import { hasWorkoutInWeek, pluralize } from 'utils/commonFunction';
import Icons from 'shared/Icons';

// import components
import SelectCalendarType from 'shared/SelectCalendarType';
import ProgramCalendarWeek from 'shared/ProgramCalendarWeek';
import ProgramAddWeek from 'shared/ProgramAddWeek';
import ConfirmModal from 'shared/ConfirmModal';
import RemoveWeekAction from 'shared/RemoveWeekAction';

// import actions
import { toggleConfirmModal } from 'actions/modal';
import { showError } from 'actions/error';
import { toggleSideBar } from 'actions/sidebar';

import { ReactComponent as CopyWeek } from 'assets/icons/duplicate_week.svg';
import { ReactComponent as RemoveWeek } from 'assets/icons/remove_week.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-copy-workouts.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-workouts.svg';

import {
  removeAutoflowIntervalWeek,
  addAutoflowIntervalWeek,
  changeViewMode,
  goToWeek,
} from 'redux/autoflowInterval/actions';
import {
  selectWeek,
  handlePasteWeek,
  resetSelectedWeek,
  handleCopyWorkouts,
  handleRemoveWorkouts,
  resetSelectedWorkouts,
  handleRemoveWorkoutWeek,
  resetAutoflowIntervalCopyItem,
} from 'redux/autoflowInterval/training/actions';

// styles
import { RemoveMultipleWorkoutPopup } from 'components/ProgramListCalendar/style';
import * as S from './styles';

const Calendar = props => {
  const {
    cell,
    workouts,
    pasteWeek,
    startWeek,
    selectWeek,
    permission,
    viewMode = 2,
    isTrainingTab = false,
    resetCopyItem,
    copyingWorkout,
    copyingWorkouts,
    workingAutoflow,
    selectedWorkouts,
    resetSelectedWeek,
    selectedWeekIndex,
    isLoadingWorkouts,
    handleRemoveWorkouts,
    resetSelectedWorkouts,
    resetAutoflowIntervalCopyItem,
    toggleSideBar,
  } = props;
  const totalWeek = useMemo(() => (workingAutoflow.interval_weeks ? workingAutoflow.interval_weeks.length : 0), [
    workingAutoflow,
  ]);
  const Cell = useMemo(() => cell, [cell]);
  const NavBar = useMemo(() => props.components && props.components.NavBar, [props.components]);
  const copying = useRef();
  const copyingWeek = useRef();
  const [deletingWeek, setDeletingWeek] = useState(null);
  const [addingWeek, setAddingWeek] = useState(false);

  const [isDragging, setIsDragging] = useState(false);

  const currentSelectedWorkouts = selectedWorkouts ? selectedWorkouts.toJS() : [];
  const isDisableAddWeek = !isNil(selectedWeekIndex) || !isEmpty(currentSelectedWorkouts);

  const onPressEscapeKey = event => {
    if (event.keyCode !== KEY_CODE.esc) return;
    if (document.querySelector('.ui.modal')) {
      props.toggleConfirmModal(false);
      return;
    }

    if (copying.current && typeof resetCopyItem === 'function') {
      resetCopyItem();
    }

    if (copyingWeek.current && typeof resetSelectedWeek === 'function') {
      resetSelectedWeek();
    }

    if (!isEmpty(selectedWorkouts)) {
      resetSelectedWorkouts && resetSelectedWorkouts();
    }
  };

  const resetCopyWeekState = () => {
    if (copying.current && typeof resetCopyItem === 'function') {
      resetCopyItem();
    }
    resetSelectedWeek && resetSelectedWeek();
    resetSelectedWorkouts && resetSelectedWorkouts();
  };

  useEffect(() => {
    toggleSideBar && toggleSideBar(false);
  }, []);

  useEffect(() => {
    copying.current = props.copying;
  }, [props.copying]);

  useEffect(() => {
    copyingWeek.current = !isNil(selectedWeekIndex);
  }, [selectedWeekIndex]);

  useEffect(() => {
    document.addEventListener('keydown', onPressEscapeKey);

    if (typeof props.onDidMount === 'function') {
      props.onDidMount();
    }

    return () => {
      document.removeEventListener('keydown', onPressEscapeKey);

      if (typeof props.onUnmount === 'function') {
        props.onUnmount();
      }
    };
  }, []);

  const onDragStart = result => {
    if (get(result, 'type') === 'DEFAULT') {
      setIsDragging(true);
    }
  };

  const onDragEnd = result => {
    const [type] = get(result, 'draggableId', '').split('_');
    setIsDragging(false);

    if (typeof props.onDragEnd === 'function') {
      props.onDragEnd(result);
    }
  };

  const onBeforeCapture = event => {
    const [type] = event.draggableId.split('_');

    if (type === 'workout') {
      setIsDragging(true);
    }
  };

  const handleDeleteWeek = weekIndex => {
    if (totalWeek < 2) {
      return props.showError('Program should have at least 1 week');
    }

    if (workingAutoflow.interval_weeks && workingAutoflow.interval_weeks[weekIndex]) {
      setDeletingWeek(weekIndex);
      props
        .removeAutoflowIntervalWeek({
          weekId: workingAutoflow.interval_weeks[weekIndex],
          autoflowId: workingAutoflow._id,
        })
        .then(repsonse => {
          const { data } = repsonse.data;
          setDeletingWeek(null);

          if (typeof props.onUpdateWeeks === 'function') {
            props.onUpdateWeeks(data);
          }
          toast(`Week ${weekIndex + 1} has been removed.`, {
            className: CLASSNAME_TOAST.TRAINING_CALENDAR,
          });
        });
    }
  };

  const onDeleteWeek = weekId => () => {
    props.toggleConfirmModal(
      true,
      <S.RemovePopup
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Delete "Week ${Number(weekId) + 1}"`}
        content="Would you like to delete this week from the autoflow?"
        onConfirm={() => handleDeleteWeek(weekId)}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Confirm"
        hasCloseIcon={true}
        noBorder={true}
        className="remove-entire-week-popup"
      />,
    );
  };

  const handleRemoveWorkoutWeek = weekIndex => {
    if (totalWeek < 2) {
      return props.showError('Program should have at least 1 week');
    }

    if (workingAutoflow.interval_weeks && workingAutoflow.interval_weeks[weekIndex]) {
      setDeletingWeek(weekIndex);
      props
        .removeAutoflowIntervalWeek({
          weekId: workingAutoflow.interval_weeks[weekIndex],
          autoflowId: workingAutoflow._id,
        })
        .then(repsonse => {
          const { data } = repsonse.data;
          setDeletingWeek(null);

          if (typeof props.onUpdateWeeks === 'function') {
            props.onUpdateWeeks(data);
          }
        })
        .then(() => {
          toast(`All workouts from Week ${Number(weekIndex) + 1} have been removed`, {
            className: CLASSNAME_TOAST.TRAINING_CALENDAR,
          });
        });
    }
  };

  const onRemoveWorkoutWeek = event => {
    const { week } = event.target.dataset;
    if (!isEnableActions) {
      return;
    }

    const hasSelectedWorkout = selectedWorkouts ? selectedWorkouts.toJS() : [];
    (!isEmpty(hasSelectedWorkout) || copyingWorkouts) && resetSelectedWorkouts();
    copyingWorkout && resetAutoflowIntervalCopyItem();

    props.toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove workouts from Week ${Number(week) + 1}?`}
        content={`All workouts from Week ${Number(week) + 1} will be deleted. Would you like to continue?`}
        onConfirm={() => handleRemoveWorkoutWeek(week)}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        noBorder={true}
        hasCloseIcon={true}
      />,
    );
  };

  const onRemoveAllWorkoutInWeek = weekIndex => () => {
    const { handleRemoveWorkoutWeek, toggleConfirmModal } = props;
    const weekNumber = weekIndex + 1;

    toggleConfirmModal(
      true,
      <S.RemoveAllWorkoutInWeekPopup
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove workouts from Week ${weekNumber}?`}
        content={`All workouts from Week ${weekNumber} will be deleted. Would you like to continue?`}
        onConfirm={() => handleRemoveWorkoutWeek(weekIndex)}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        noBorder={true}
        hasCloseIcon={true}
        className="remove-workout-in-week-popup"
      />,
    );
  };

  const handleAddWeek = week => {
    setAddingWeek(true);
    props
      .addAutoflowIntervalWeek({ weekIndex: week, autoflowId: workingAutoflow._id })
      .then(repsonse => {
        const { data } = repsonse.data;

        if (typeof props.onUpdateWeeks === 'function') {
          props.onUpdateWeeks(data);
        }
        setAddingWeek(false);
      })
      .catch(() => {
        setAddingWeek(false);
      });
  };

  const onAddWeek = event => {
    const { week } = event.currentTarget.dataset;
    Mixpanel.track('autoflow_interval_training_calendar_add_week');
    handleAddWeek(Number(week));
  };

  const onAddWeekBefore = () => {
    Mixpanel.track('autoflow_interval_training_calendar_add_week_before');
    handleAddWeek(startWeek);
  };

  const onAddWeekAfter = () => {
    Mixpanel.track('autoflow_interval_training_calendar_add_week_after');
    handleAddWeek(startWeek + 1);
  };

  const onAddWeekToTheEnd = () => {
    Mixpanel.track('autoflow_interval_training_calendar_add_week_to_the_end');
    handleAddWeek(totalWeek);
  };

  const onGoToWeek = week => {
    props.goToWeek(week);

    if (typeof props.onStartWeekChange === 'function') {
      props.onStartWeekChange(week);
    }
  };

  const onViewModeChange = mode => {
    props.changeViewMode(mode);

    if (mode === totalWeek) {
      props.goToWeek(0);
    }
    if (typeof props.onViewModeChange === 'function') {
      props.onViewModeChange(mode);
    }
  };

  const handleCopyWeek = index => {
    const selectedWeekNumber = index + 1;
    if (copying.current && typeof resetCopyItem === 'function') {
      resetCopyItem();
    }
    selectWeek && selectWeek(index);
    toast(`Week ${selectedWeekNumber} Copied. Click on week to paste workouts.`, {
      className: CLASSNAME_TOAST.TRAINING_CALENDAR,
    });
  };

  // Select week to paste
  const handlePasteWeek = (weekIndex, event) => {
    const multiPaste = event.shiftKey;
    const debouncePasteWeek = debounce(pasteWeek, 100);
    debouncePasteWeek(weekIndex, multiPaste);
  };

  // Select workout to copy
  const handleCopyMultipleWorkout = () => {
    const { handleCopyWorkouts } = props;
    toast(
      `${pluralize('Workout', currentSelectedWorkouts.length, true)} copied. Click on date to paste ${pluralize(
        'workout',
        currentSelectedWorkouts.length,
        true,
      )}.`,
      {
        className: CLASSNAME_TOAST.TRAINING_CALENDAR,
      },
    );
    handleCopyWorkouts && handleCopyWorkouts(currentSelectedWorkouts);
  };

  // Delete workout selected
  const handleDeleteWorkout = () => {
    props.toggleConfirmModal(
      true,
      <RemoveMultipleWorkoutPopup
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove ${currentSelectedWorkouts.length} ${pluralize('workout', currentSelectedWorkouts.length)}`}
        content={`The selected workouts will be permanently deleted. Would you like to continue?`}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        onConfirm={handleRemoveWorkouts}
        noBorder={true}
        hasCloseIcon={true}
        className="program-remove-multiple-popup"
      />,
    );
  };

  const isEnableActions = useMemo(() => {
    return !deletingWeek && !addingWeek;
  }, [deletingWeek, addingWeek]);

  const renderSelectedWorkoutBottom = () => {
    return (
      <S.AlertBottomWrapper>
        <S.SelectedNumberWorkoutWrapper>
          <S.SelectedWorkoutNumber>{currentSelectedWorkouts.length}</S.SelectedWorkoutNumber>
          <S.SelectedWorkoutText>
            {`${pluralize('Workout', currentSelectedWorkouts.length)} selected`}{' '}
          </S.SelectedWorkoutText>
        </S.SelectedNumberWorkoutWrapper>
        <S.SelectedWorkoutAction>
          <S.BottomActions data-tip data-for="tooltip--remove-multiple-workout" onClick={() => handleDeleteWorkout()}>
            <S.IconActions>
              <TrashIcon />
            </S.IconActions>
            <S.TextActions>Remove</S.TextActions>
            <ReactTooltip
              className="app-tooltip remove-workouts copy-remove-bottom-calendar__tooltip"
              id="tooltip--remove-multiple-workout"
              effect="solid"
              place={'top'}
              delayShow={100}
            >
              <span>Remove Workouts</span>
            </ReactTooltip>
          </S.BottomActions>
          <S.BottomActions
            data-tip
            data-for="tooltip--copy-multiple-workout"
            onClick={() => handleCopyMultipleWorkout()}
          >
            <S.IconActions>
              <CopyIcon />
            </S.IconActions>
            <S.TextActions>Copy</S.TextActions>
            <ReactTooltip
              className="app-tooltip copy-workouts copy-remove-bottom-calendar__tooltip"
              id="tooltip--copy-multiple-workout"
              effect="solid"
              place={'top'}
              delayShow={100}
            >
              <span>Copy</span>
            </ReactTooltip>
          </S.BottomActions>
        </S.SelectedWorkoutAction>
        <S.BottomCancel>
          <S.CancelBold>Esc</S.CancelBold>&nbsp;to cancel
          <CloseIcon onClick={() => resetCopyWeekState()} className="cancel-copy" />
        </S.BottomCancel>
      </S.AlertBottomWrapper>
    );
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
      <S.Wrapper className="autoflowIntervalCalendar_wrapper">
        <S.NavigatorBarContainer>
          <S.NavigatorBarLeftContent>
            <ProgramCalendarWeek
              currentWeek={startWeek}
              totalWeek={totalWeek}
              calendarType={viewMode}
              goToWeek={onGoToWeek}
            />
            {totalWeek < 52 && (
              <ProgramAddWeek
                currentWeek={startWeek}
                onAddWeekBefore={onAddWeekBefore}
                onAddWeekAfter={onAddWeekAfter}
                onAddWeekToTheEnd={onAddWeekToTheEnd}
                totalWeek={totalWeek}
                onlyAddToTheEnd={workingAutoflow.status === AUTOFLOW_STATUS.ACTIVATED}
                loadingPaste={isDisableAddWeek}
              />
            )}
          </S.NavigatorBarLeftContent>
          <S.NavigatorBarCenter>{NavBar && <NavBar />}</S.NavigatorBarCenter>
          <S.NavigatorBarRightContent>
            <SelectCalendarType
              selected={viewMode}
              options={CALENDAR_TYPES}
              onSelect={onViewModeChange}
              disabled={item => (item.value === 1 ? false : totalWeek < item.value)}
              uiOptions={{ newUI: true }}
            />
          </S.NavigatorBarRightContent>
        </S.NavigatorBarContainer>
        <S.CalendarContainer>
          <S.Grid numRows={viewMode} className="autoflowIntervalCalendar__grid">
            {range(viewMode).map(weekIndex => {
              const weekId = startWeek + weekIndex;
              const isOverWeekRange = totalWeek < weekId + 1;

              const decodeWorkouts = workouts.toJS();
              const hasWorkout = hasWorkoutInWeek(decodeWorkouts, weekId);

              return (
                <S.GridRow
                  key={weekIndex}
                  className="autoflowIntervalCalendar__row"
                  isCopingWeek={!isNil(selectedWeekIndex)}
                >
                  {process.env.REACT_APP_COPY_WEEK_ENABLE_V2 &&
                    get(permission, 'copy_week') &&
                    isTrainingTab &&
                    !addingWeek &&
                    !isOverWeekRange && (
                      <>
                        {isNil(selectedWeekIndex) && isEmpty(currentSelectedWorkouts) && hasWorkout && (
                          <S.CopyWrapper>
                            {workingAutoflow.status === AUTOFLOW_STATUS.ACTIVATED && (
                              <S.IconWrapper>
                                <RemoveWeek
                                  data-week={weekId}
                                  data-tip
                                  data-for="remove-week-on-tooltip"
                                  onClick={onRemoveAllWorkoutInWeek(weekId)}
                                />
                                <ReactTooltip id="remove-week-on-tooltip" effect="solid" place={'right'}>
                                  <div>Remove workouts of the week</div>
                                </ReactTooltip>
                              </S.IconWrapper>
                            )}

                            {workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED && (
                              <RemoveWeekAction
                                onRemoveAllWorkoutInWeek={onRemoveAllWorkoutInWeek(weekId)}
                                onDeleteWeek={onDeleteWeek(weekId)}
                              />
                            )}

                            {hasWorkout && (
                              <S.IconWrapper>
                                <CopyWeek
                                  data-tip
                                  data-for="copy-week-on-tooltip"
                                  onClick={() => handleCopyWeek(weekId)}
                                />
                                <ReactTooltip id="copy-week-on-tooltip" effect="solid" place={'right'}>
                                  <div>Copy Week</div>
                                </ReactTooltip>
                              </S.IconWrapper>
                            )}
                          </S.CopyWrapper>
                        )}
                        {isNil(selectedWeekIndex) &&
                          workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED &&
                          !hasWorkout && (
                            <S.CopyWrapper>
                              <S.IconWrapper>
                                <RemoveWeek
                                  data-week={weekId}
                                  data-tip
                                  data-for="remove-week-on-tooltip"
                                  onClick={onDeleteWeek(weekId)}
                                />
                                <ReactTooltip id="remove-week-on-tooltip" effect="solid" place={'right'}>
                                  <div>Remove entire week</div>
                                </ReactTooltip>
                              </S.IconWrapper>
                            </S.CopyWrapper>
                          )}

                        {!isNil(selectedWeekIndex) && !isLoadingWorkouts && (
                          <S.PasteWeek>
                            <S.PasteButton onClick={event => handlePasteWeek(weekId, event)}>Paste Week</S.PasteButton>
                          </S.PasteWeek>
                        )}
                      </>
                    )}
                  {Cell &&
                    range(7).map(dayIndex => {
                      const cellId = weekId < totalWeek ? `${weekId}_${dayIndex}` : null;
                      return (
                        <Cell
                          cellId={cellId}
                          key={`${weekId}_${dayIndex}`}
                          weekIndex={weekId}
                          dayIndex={dayIndex}
                          draggingWorkout={isDragging}
                          calendarType={viewMode}
                        />
                      );
                    })}
                  {weekId < totalWeek && (
                    <>
                      <S.WeekIndicatorContainer>
                        {workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED && !isTrainingTab && (
                          <Icons
                            className={classnames('delete-icon', addingWeek && 'deleting')}
                            name="delete"
                            onClick={onDeleteWeek(weekId)}
                          />
                        )}
                        <div
                          className={classnames('week__title', {
                            'hide-week__title': hasWorkout && viewMode === 4,
                          })}
                        >
                          Week {weekId + 1}
                        </div>
                      </S.WeekIndicatorContainer>
                      {weekIndex === 0 &&
                        workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED &&
                        totalWeek < 52 &&
                        !isDisableAddWeek && (
                          <S.AddWeekContainer
                            className={classnames('autoflowIntervalCalendar__add-week__container firstLine')}
                          >
                            <S.AddWeekIcon
                              onClick={onAddWeek}
                              data-tip
                              data-for={`tooltip--add-week-${weekId}`}
                              data-week={weekId}
                            />
                            <ReactTooltip
                              className="app-tooltip"
                              id={`tooltip--add-week-${weekId}`}
                              effect="solid"
                              place="bottom"
                            >
                              <span>Add 1 week</span>
                            </ReactTooltip>
                          </S.AddWeekContainer>
                        )}
                      {workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED && totalWeek < 52 && !isDisableAddWeek && (
                        <S.AddWeekContainer
                          className={classnames('autoflowIntervalCalendar__add-week__container', {
                            lastLine: weekIndex === viewMode - 1,
                          })}
                        >
                          <S.AddWeekIcon
                            onClick={onAddWeek}
                            data-tip
                            data-for={`tooltip--add-week-${weekId + 1}`}
                            data-week={weekId + 1}
                          />
                          <ReactTooltip
                            className="app-tooltip"
                            id={`tooltip--add-week-${weekId + 1}`}
                            effect="solid"
                            place="top"
                          >
                            <span>Add 1 week</span>
                          </ReactTooltip>
                        </S.AddWeekContainer>
                      )}
                    </>
                  )}
                </S.GridRow>
              );
            })}
          </S.Grid>
        </S.CalendarContainer>
        {process.env.REACT_APP_COPY_WEEK_ENABLE_V2 && (
          <>
            {!isNil(selectedWeekIndex) && (
              <S.AlertBottomWrapper>
                <S.SelectedWeekText>
                  <strong>Week {selectedWeekIndex + 1}</strong>&nbsp;copied
                </S.SelectedWeekText>
                <S.BottomCancel>
                  <S.CancelBold>Esc </S.CancelBold>&nbsp;to cancel
                  <CloseIcon onClick={() => resetCopyWeekState()} className="cancel-copy-week" />
                </S.BottomCancel>
              </S.AlertBottomWrapper>
            )}
            {!isEmpty(currentSelectedWorkouts) && renderSelectedWorkoutBottom()}
          </>
        )}
        {props.children}
      </S.Wrapper>
    </DragDropContext>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    goToWeek: bindActionCreators(goToWeek, dispatch),
    showError: bindActionCreators(showError, dispatch),
    selectWeek: bindActionCreators(selectWeek, dispatch),
    pasteWeek: bindActionCreators(handlePasteWeek, dispatch),
    handleCopyWorkouts: bindActionCreators(handleCopyWorkouts, dispatch),
    changeViewMode: bindActionCreators(changeViewMode, dispatch),
    resetSelectedWeek: bindActionCreators(resetSelectedWeek, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    handleRemoveWorkouts: bindActionCreators(handleRemoveWorkouts, dispatch),
    resetSelectedWorkouts: bindActionCreators(resetSelectedWorkouts, dispatch),
    handleRemoveWorkoutWeek: bindActionCreators(handleRemoveWorkoutWeek, dispatch),
    addAutoflowIntervalWeek: bindActionCreators(addAutoflowIntervalWeek, dispatch),
    removeAutoflowIntervalWeek: bindActionCreators(removeAutoflowIntervalWeek, dispatch),
    resetAutoflowIntervalCopyItem: bindActionCreators(resetAutoflowIntervalCopyItem, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  };
};

const mapState = state => {
  const {
    rootReducer: {
      autoflowInterval: { common, training },
      autoflowInterval,
      autoflow: {
        common: { workingAutoflow },
      },
      permission,
    },
  } = state;
  const { selectedWeek, copyingWorkouts } = autoflowInterval.training.toJS();

  return {
    permission,
    workingAutoflow,
    selectedWeekIndex: selectedWeek,
    viewMode: common.get('viewMode'),
    workouts: training.get('workouts'),
    startWeek: common.get('startWeek'),
    copyingWorkout: training.get('copying'),
    copyingWorkouts: copyingWorkouts,
    selectedWorkouts: training.get('selectedWorkouts'),
    isLoadingWorkouts: training.get('isLoadingWorkouts'),
  };
};

export default connect(mapState, mapDispatchToProps)(Calendar);
