import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Members from '../Members';
import { isTeamAdmin } from 'utils/commonFunction';
import { toggleModal } from 'actions/modal';
import { ReactComponent as BroadcastIcon } from 'assets/icons/broadcast_icon.svg';
import { ReactComponent as NewInboxIcon } from 'assets/icons/create_new_inbox.svg';
import { hideBroadcast, resetBroadcastMessageState } from 'redux/broadcast-messages/actions';
import GroupChatUpgradeButton from 'layouts/home/Home/GroupChatUpgradeButton/GroupChatUpgradeButton';
import BroadcastMessageModal from 'components/Inbox/BroadcastMessage';

import * as S from './style';
import ReactTooltip from 'react-tooltip';

const InboxHeader = ({
  push,
  user,
  viewTeammate,
  roomInformation,
  toggleModal,
  isPermissionBroadCast,
  resetBroadcastMessageState,
  isHideBroadcast,
  hideBroadcast,
}) => {
  const pathname = window.location.pathname;
  const firstName = get(roomInformation, 'first_name');
  const lastName = get(roomInformation, 'last_name');
  const roomName = firstName && lastName ? `${firstName} ${lastName}` : null;
  const isInbox = pathname && pathname.includes('home/inbox') && process.env.REACT_APP_GROUP_CHAT_ENABLE;

  useEffect(() => {
    if (isPermissionBroadCast) {
      hideBroadcast && hideBroadcast();
    }
  }, []);

  const onClick = () => {
    !viewTeammate && push('/home/inbox/new');
  };

  const onOpenBroadcastModal = () => {
    toggleModal(
      true,
      <BroadcastMessageModal
        onClose={() => {
          resetBroadcastMessageState();
          toggleModal(false);
        }}
      />,
    );
  };

  return (
    <>
      <Helmet>
        <title>{roomName ? `${roomName} - ` : ''}Inbox - Everfit</title>
      </Helmet>
      <S.Container>
        {isTeamAdmin(user) && (user.team_member.length > 1 || user.team_old_members.length > 0) ? (
          <Members />
        ) : (
          <S.Title>Inbox</S.Title>
        )}
        <S.HeaderActions>
          {isInbox && <GroupChatUpgradeButton />}
          {isPermissionBroadCast && !isHideBroadcast && (
            <S.CreateBroadcast onClick={() => onOpenBroadcastModal()}>
              <S.BroadcastButton>
                <BroadcastIcon className="broadcast-icon" />
                <span>Broadcast</span>
              </S.BroadcastButton>
            </S.CreateBroadcast>
          )}
          <S.CreateNewInbox onClick={onClick} readOnly={viewTeammate} data-for="new-inbox-tooltip" data-tip>
            <NewInboxIcon className="new-inbox" />
          </S.CreateNewInbox>
          <ReactTooltip id="new-inbox-tooltip" className="app-tooltip" place="bottom" effect="solid">
            New Message
          </ReactTooltip>
        </S.HeaderActions>
      </S.Container>
    </>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: {
      inboxNew: { viewTeammate, roomInformation },
      broadcastMessages: { isHideBroadcast },
      permission,
    },
  } = state;
  return {
    user,
    viewTeammate,
    roomInformation,
    isPermissionBroadCast: get(permission, 'broadcast_message', false),
    isHideBroadcast,
  };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  resetBroadcastMessageState: bindActionCreators(resetBroadcastMessageState, dispatch),
  hideBroadcast: bindActionCreators(hideBroadcast, dispatch),
});

export default connect(mapState, mapDispatch)(InboxHeader);
