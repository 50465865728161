import React from 'react';
import { ANNOUNCEMENT_AUTO_MESSAGE, IN_APP_AUTO_MESSAGE } from 'constants/commonData';
import Announcement from './Announcement';
import InAppMessage from './InAppMessage';

export default props => {
  const { data } = props;
  const message = typeof data.toJS === 'function' ? data.toJS() : data;

  if (message.type === ANNOUNCEMENT_AUTO_MESSAGE) {
    return <Announcement {...props} message={message} />;
  }

  if (message.type === IN_APP_AUTO_MESSAGE) {
    return <InAppMessage {...props} message={message} />;
  }

  return null;
};
