import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import TrialSetting from './TrialSettings';
import CurrencyInput from './CurrencyInput';
import * as S from './styles';

const OneTimePayment = props => {
  const { data, onChange, showError, amountErrorField, permission } = props;

  const isEnableTrialPeriod = get(permission, 'trial_package', false);
  const handleSettingToggle = status => {
    onChange({ enable_trial: status });
  };

  const handleChangeTrialPeriod = params => {
    const { time, isArchiveClient, isTrialOnce } = params;
    onChange({ trial_period: time, is_archive_on_trial_cancellation: isArchiveClient, allow_trial_once: isTrialOnce });
  };

  return (
    <S.Wrapper>
      <S.Header>One-time Payment</S.Header>
      <S.Description>
        This package will charge the user one time. To register for this package, your client will need to enter their
        email and credit card information.
      </S.Description>
      <S.FormGroup>
        <label>Set Price</label>
        <CurrencyInput data={data} onChange={onChange} showError={showError} errorField={amountErrorField} />
      </S.FormGroup>
      {isEnableTrialPeriod && (
        <S.FormGroup>
          <TrialSetting
            onChangeTrial={handleChangeTrialPeriod}
            onChangeToggle={handleSettingToggle}
            currentData={data}
            isOnetime={true}
          />
        </S.FormGroup>
      )}
    </S.Wrapper>
  );
};

const mapState = state => {
  const {
    rootReducer: { permission },
  } = state;

  return {
    permission,
  };
};

export default connect(mapState, null)(OneTimePayment);
