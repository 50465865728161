import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Modal, Button, Image } from 'semantic-ui-react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import { axiosInstance } from 'configs/request';

import Comments from './Comments';

import { CDN_URL } from 'constants/commonData';

import Slider from 'shared/Slider';
import ConfirmModal from 'shared/ConfirmModal';
import { Container, CommentsContainer } from './style';
import { addComment, deleteComment, getListComments, getListPost, resetLoadPostsQuery } from 'redux/forum/actions';
import { showError, hideError } from 'actions/error';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { convertS3UrlToCloudFrontUrl, getFormatImageUrlWithSML, getStatusCodeUrl } from 'utils/commonFunction';
import './style.scss';

const CONVERTED_FOLDER = 'thumbnails-converted';
const ORIGINAL_FILE = '/original/';

function PostDetail(props) {
  const { forumId, postId, cloudfrontList } = props;
  const [postData, setPostData] = useState();
  const [uploadedComment, setUploadedComment] = useState(true);
  const uploadedAllComment = useRef(true);
  const onlyCaption = !postData || !postData.attachments || !postData.attachments.length;

  const params = new URLSearchParams(get(window, 'location.search', ''));
  const startAt = params && params.get('startAt');

  useEffect(() => {
    getData();
  }, []);

  const getData = useCallback(() => {
    if (!isEmpty(postId)) {
      axiosInstance
        .get('/api/v2/forum-post/get-post-detail', { params: { post_id: postId } })
        .then(response => {
          const { data } = response.data;
          setPostData(data);
        })
        .catch(err => {
          const { showError, hideError, toggleModal, getListPost, forumId, push, resetLoadPostsQuery } = props;
          const message = get(err, 'response.data.message');
          showError(message, null, null, null, null, () => {
            resetLoadPostsQuery();
            hideError(false);
            toggleModal(false);
            push(`/home/forums/${forumId}/discussion`);
            getListPost();
          });
        });
    }
  }, [postId]);

  const renderSlider = () => {
    if (onlyCaption) {
      return null;
    }

    const items = map(postData.attachments || [], item => {
      const url = get(item, 'url', '');

      if (url.includes(CONVERTED_FOLDER) || (url.includes(ORIGINAL_FILE) && url.includes('/images/'))) {
        const replaceUrl = url.replace('/images/', '/thumbnails-converted/');
        return {
          src: convertS3UrlToCloudFrontUrl(getFormatImageUrlWithSML(replaceUrl), cloudfrontList, true),
          type: item.type,
          isAttachmentGiphy: isEqual(get(item, 'attachment_source'), 'giphy'),
        };
      }

      return {
        src: convertS3UrlToCloudFrontUrl(url, cloudfrontList, true),
        type: item.type,
        isAttachmentGiphy: isEqual(get(item, 'attachment_source'), 'giphy'),
      };
    });

    return <Slider startAt={Number(startAt)} width={830} height={711} items={items} viewingName="forum" />;
  };

  const handleClose = () => {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy) return null;
    if (!uploadedComment) {
      return props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Upload in progress"
          content="If you close the pop-up, all incomplete uploads will be discarded. Would you like to exit without finishing?"
          onConfirm={() => {
            props.push(`/home/forums/${forumId}/discussion`);
            props.toggleModal(false);
          }}
          newStyle={true}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    } else {
      props.push(`/home/forums/${forumId}/discussion`);
      return props.toggleModal(false);
    }
  };

  return (
    <Modal
      open={true}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      className={`fj-detail-modal${onlyCaption ? ' only-caption' : ''}`}
      onClose={handleClose}
      closeOnDimmerClick={false}
    >
      <Modal.Content className="content-post-detail">
        <Container onlyCaption={onlyCaption}>
          {renderSlider()}
          <CommentsContainer onlyCaption={onlyCaption}>
            {!!postData && (
              <Comments
                {...props}
                onRemovePost={handleClose}
                onChangeUploadStatus={noUploadedComments => {
                  if (uploadedAllComment) {
                    uploadedAllComment.current = !noUploadedComments.length;
                  }
                  setUploadedComment(!noUploadedComments.length);
                }}
                onlyCaption={onlyCaption}
                postData={postData}
              />
            )}
          </CommentsContainer>
        </Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = (state, ownProps) => {
  const postId = ownProps.match.params.postId || ownProps.postId;
  const { rootReducer } = state;
  const members = rootReducer.forum.members;
  const forumId = ownProps.match.params.forumId || ownProps.match.params.id;
  const posts = get(rootReducer, 'forum.posts', []);

  return {
    forumId,
    members,
    postId,
    posts,
  };
};

const mapDispatch = (dispatch, ownProps) => {
  const forumId = get(ownProps, 'match.params.forumId') || get(ownProps, 'match.params.id');
  return {
    push: bindActionCreators(push, dispatch),
    addComment: bindActionCreators(addComment, dispatch),
    deleteComment: bindActionCreators(deleteComment, dispatch),
    getListComments: bindActionCreators(getListComments, dispatch),
    showError: bindActionCreators(showError, dispatch),
    hideError: bindActionCreators(hideError, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    getListPost: () => dispatch(getListPost(forumId)),
    resetLoadPostsQuery: bindActionCreators(resetLoadPostsQuery, dispatch),
  };
};

export default connect(mapState, mapDispatch)(PostDetail);
