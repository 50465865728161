import styled, { css, keyframes } from 'styled-components';
import * as SUpload from '../../Upload/style';
import { FormGroup as FG, Button } from 'shared/FormControl';
import { Header } from 'shared/Styles/ModalLayout';
import { Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

const Fadein = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

export const FormGroup = styled(FG)`
  display: flex;
  justify-content: flex-start;
  align-items: ${({ isNewFlow }) => (isNewFlow ? 'flex-start' : 'center')};
  margin-bottom: 0;

  &.clientMobileApp {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1279px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Tip = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #868c9a;
  padding-left: 20px;
  background: transparent url(${CDN_URL}/images/new_info.svg) no-repeat;
  background-size: 14px 14px;
  background-position: 0 2px;
  margin-top: 10px;

  .hightlight {
    font-weight: 600;
  }
`;

export const DragboxItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:last-child {
    margin-left: ${({ isNewFlow }) => (isNewFlow ? '50px' : '56px')};

    @media screen and (max-width: 1279px) {
      margin-left: 0;
      margin-top: 28px;
    }
  }
`;

export const DragboxElement = styled.div`
  display: flex;
  flex-direction: column;

  ${SUpload.Wrapper} {
    width: 282px;
  }

  ${SUpload.Icon} {
    margin-right: 10px;
  }

  ${SUpload.Text} {
    width: calc(100% - 120px);
  }
`;

export const DragboxUploadElement = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  div.dragbox-element-wrapper {
    display: flex;
    flex-direction: column;

    .dragbox-element-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      border: 1px solid transparent;
      border-radius: 25px;
      cursor: pointer;

      &:hover {
        border: 1px solid #5c5bbd;
        box-shadow: 0px 0px 20px 0px rgba(13, 25, 54, 0.1);
      }

      .dragbox-element-item {
        margin-right: unset;
        border: 0.5px solid transparent;

        .dragbox-element-background {
          box-shadow: 0px 0px 20px 0px rgba(13, 25, 54, 0.1);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }

  span.label {
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    color: #818da1;

    margin-left: 5px;
    margin-bottom: 9px;
  }
`;

export const UploadInputArea = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Note = styled.div`
  display: table;
  width: 282px;
  height: inherit;
  font-size: 13px;
  line-height: 16px;
  color: #868c9a;
  padding: 0 !important;
  margin-top: 8px;

  ${Tip} {
    display: table-cell;
    height: inherit;
    margin: 0;
    vertical-align: middle;
    background-position: left calc(50% - 8px);
  }
`;

export const SaveButton = styled(Button)`
  background-color: #5c5bbd;
  color: #fff;
  min-width: 110px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background-color: #5559ff;
  }

  &.disable {
    background-color: #c4c4c4;
    color: #fff;
    pointer-events: none;
  }

  &.reject {
    color: #ea314a;
    background: transparent;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    border: 1px solid #ea314a;
  }

  &.reviewed {
    min-width: 158px;
  }
`;

export const NewSaveButton = styled(SaveButton)`
  background-color: #5c5bbd;
  color: #fff;
  min-width: 110px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background-color: #5559ff;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const RejectText = styled.div`
  color: #ea314a;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  margin-top: 12px;
  text-align: right;
`;

export const FinalReviewText = styled.div`
  max-width: 158px;
  color: #868c9a;
  font-size: 12px;
  line-height: 16.34px;
  margin-top: 12px;
  text-align: left;
`;

export const SaveButtonArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;

  &.hide {
    display: none;
  }
`;

export const saveActiveButton = {
  minWidth: 110,
};

export const Item = styled.div`
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 25px;
  height: 100px;
  width: 100px;
  border: 0.5px solid #dadfea;
  position: relative;
  cursor: pointer;

  .select-file__container,
  .select-file__trigger {
    width: 100px;
    height: 100px;
  }

  &.default {
    box-shadow: 0px 0px 20px rgb(13 25 54 / 10%);
  }

  :hover {
    ${RemoveIcon} {
      display: block;
    }
  }

  ${props =>
    !props.empty &&
    css`
      :hover {
        border-color: #5158cf;
      }
    `}

  ${props =>
    props.active &&
    css`
      border-color: #5158cf;
    `}

  .evf-file-upload {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 25px;
    width: 100px;
    height: 100px;
  }

  ${Background} {
    margin-right: 20px;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: transparent url(${props => props.background}) no-repeat center;
  background-size: cover;
`;

export const SelectFileTrigger = styled.div`
  display: flex;
  padding: 4.5px 15px;
  min-width: 104px;
  background: #ffffff;
  border: 1px solid #d6dae4;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;

export const RemoveFile = styled(SelectFileTrigger)`
  max-width: 165px;
  padding: 4.5px 0;
`;

export const PublishFile = styled(SelectFileTrigger)`
  max-width: 104px;
  padding: 4.5px 0;
  background: #36b24b;
  animation: ${Fadein} 1s;
`;

export const PublishedFile = styled(PublishFile)`
  background: #c4c4c4;
`;

export const IconSpacing = styled.div`
  margin-right: 10px;
`;

export const IconCloseSpacing = styled.div`
  height: 17px;
  margin-right: 5px;
`;

export const IconPublicSpacing = styled(IconCloseSpacing)``;

export const RemoveIcon = styled.div`
  width: 15px;
  height: 15px;
  background: transparent url(${CDN_URL}/images/delete_bg_white.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  display: none;
`;

export const Description = styled.div`
  color: #202353;
  width: 235px;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DescriptionItem = styled.div`
  margin-bottom: 5px;
  color: #5158cf;

  &:last-child {
    margin-bottom: 0;
  }

  .link {
    color: #5158cf;
  }
`;

export const UploadInput = styled.div`
  animation: ${Fadein} 1.5s;
`;

export const SelectFileTriggerText = styled.div`
  color: #818da1;
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
`;

export const SelectFileTriggerCancelText = styled(SelectFileTriggerText)`
  font-size: 13px;
`;

export const SelectFileTriggerPublishText = styled(SelectFileTriggerText)`
  font-size: 13px;
  line-height: 18px;
  color: white;
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 466px;
  }

  > .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }

  ${Header} {
    padding-bottom: 16px;
  }
`;

export const DescriptionModal = styled.div`
  color: #323c47;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  padding-right: 6px;
`;

export const TitleModal = styled.span`
  margin-left: 10px;
`;

export const LinkDocument = styled.a`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6456c4;
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: #6456c4;
  }
`;

export const LinkDocumentText = styled.span`
  margin-left: 4px;
`;
