// Libs
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

// Helpers
import { conditionalRoundNutrition, handleEmptyValue } from 'components/RecipeDetail/helper';

// Styles
import * as S from './style';

const MainPanelHeader = props => {
  const { meal, listMealGroup, updateMealPlanByDay, selected, listNutrients, loadingByDay, mouseLeaveRef } = props;
  const { name: value, recipes = [], is_system = false } = meal || {};

  const [title, setTitle] = useState(value || 'Untitled');
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    disableInput();
  }, [selected]);

  useEffect(() => {
    if (selected) {
      mouseLeaveRef.current = handleOnMouseOutParent;
    }
  }, [selected]);

  const handleOnMouseOutParent = () => {
    if (inputRef && inputRef.current && typeof inputRef.current.blur === 'function') {
      inputRef.current.blur();
    }
  };

  const enableInput = () => {
    if (is_system) return;
    if (isEditing) return;

    setTitle(value);
    setIsEditing(true);
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const disableInput = () => setIsEditing(false);

  const handleChange = event => {
    if (event && event.target) {
      setTitle(event.target.value);
    }
  };

  const handleKeyDown = event => {
    if (event && (event.key === 'Enter' || event.key === 13)) {
      event.preventDefault();
      handleBlur();
    }
  };

  const handleBlur = () => {
    const isChangeName = !isEqual(value, title);
    const updatedListMealGroup = listMealGroup.map(item => {
      if (get(item, '_id') === selected) {
        const updatedItem = {
          ...item,
          name: title || 'Untitled',
          is_system: get(item, 'is_system') && isChangeName ? false : get(item, 'is_system'),
        };

        return updatedItem;
      }

      return item;
    });

    updateMealPlanByDay && updateMealPlanByDay({ meal_group: updatedListMealGroup }, isChangeName);

    disableInput();
  };

  return (
    <S.MainPanelHeaderWrapper>
      <S.HeaderTitleWrapper>
        {!loadingByDay &&
          (isEditing ? (
            <S.HeaderInput
              ref={inputRef}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              value={title}
              placeholder="Untitled"
            />
          ) : (
            <S.HeaderButton onClick={enableInput} isSystem={is_system}>
              <span className="truncate">{value}</span>
            </S.HeaderButton>
          ))}
        {loadingByDay && <S.HeaderTitleSkeleton />}
        {listNutrients && listNutrients.length > 0 && recipes.length > 0 && (
          <S.TotalNutrition>
            {listNutrients.map(item => {
              const { label, unit, value, id } = item;
              return (
                <S.TotalNutritionItem key={id}>
                  {label && <S.NutritionLabelItem>{label}</S.NutritionLabelItem>}
                  <S.NutritionValueItem>{handleEmptyValue(conditionalRoundNutrition(value))}</S.NutritionValueItem>
                  {unit}
                </S.TotalNutritionItem>
              );
            })}
          </S.TotalNutrition>
        )}
      </S.HeaderTitleWrapper>
    </S.MainPanelHeaderWrapper>
  );
};

export default MainPanelHeader;
