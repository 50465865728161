import React from 'react';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import AddAndEditCountdown from './AddAndEditCountdown';

import * as S from './style';

const DetailComponents = props => {
  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.TitleWrapper>Add Countdowns</S.TitleWrapper>
        <AddAndEditCountdown {...props} />
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

const mapDispatch = {
  toggleModal,
};

export default connect(null, mapDispatch)(DetailComponents);
