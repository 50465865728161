/**
 * @flow
 */
export const TOGGLE_SIDE_BAR: TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';

export const toggleSideBar = (visible: boolean) => {
  return {
    type: TOGGLE_SIDE_BAR,
    visible,
  };
};
