import styled from 'styled-components';

export const ManuallyWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  border: none;
  padding: 20px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ManuallySet1RMButton = styled.button`
  cursor: pointer;
  border: none;
  font-weight: 600;
  color: #7b7e91;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Open Sans';
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0;
  outline: none;

  svg {
    margin-right: 5px;
  }

  :hover {
    color: #5158cf;
    background-color: transparent;

    svg {
      path {
        stroke: #5158cf;
      }
    }
  }
`;
