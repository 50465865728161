import styled from 'styled-components';
import { Panel } from '../style';

export const OpenPaymentFormButton = styled.button`
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  padding: 5px 28px 7px;
  background: linear-gradient(76.98deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  color: #fff;
`;

export const ScheduledInformation = styled.div`
  max-width: 355px;
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 15px;
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
  }
`;

export const Free = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  margin-top: 25px;
`;

export const Fee = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 10px;

  .fee {
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: #202353;
  }

  .tax-inclusive {
    font-weight: 600;
    font-size: 13px;
    opacity: 0.6;
    margin-left: 5px;
  }
`;

export const NextCharge = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;

export const Clients = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #202353;
  opacity: 0.6;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .current-plan {
    margin-right: 10px;
  }

  .__react_component_tooltip.app-tooltip-white {
    width: 355px;
    padding: 20px;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  button.button--scheduled-plan {
    font-size: 11px;
    line-height: 15px;
    padding: 4px 9px;
  }
`;

export const CurrentPlanWrapper = styled(Panel)`
  .button--upgrade-path {
    padding: 5px 28px 7px;
    background: linear-gradient(76.98deg, #fdc830 -18.89%, #f37335 99.03%);
    border-radius: 3px;

    span {
      text-transform: none;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: #ffffff;
    }
  }
`;

export const AutoUpgrade = styled.div`
  display: flex;
  margin-left: 2px;

  .checkbox-icon {
    width: 16px;
    height: 16px;
  }
`;

export const AutoUpgradeLabel = styled.div`
  display: inline-flex;
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;

  margin-left: 2px;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const ZapierToolTipContent = styled.div`
  width: 315px;
  color: #202353;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  word-wrap: break-word;
`;
