import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  &.ui.modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: ${({ width }) => width || '500px'};
    height: ${({ height }) => height || 'auto'};

    border-radius: 10px;
  }
  &.ui.modal > .header,
  &.ui.modal > .content,
  &.ui.modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 30px 0;
  border-radius: 8px;
  background-color: #fff;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 9px;

    .header-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: #141414;
    }
  }

  .content {
    margin-top: 16px;
    padding: 0 32px;

    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #141414;
  }

  .actions {
    width: 100%;
    margin-top: 24px;
    padding: 0 89px;

    button.resend {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 36px;
      padding: 8px 20px 8px 20px;
      border: none;
      border-radius: 5px;
      outline: none;
      cursor: pointer;

      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
      background-color: #5158cf;

      &:hover {
        background: rgba(81, 88, 207, 0.8);
      }

      .resend-bold {
        font-weight: 600;
      }

      &:disabled {
        font-weight: 400;
        background-color: #a7a7a7;
        cursor: not-allowed;
      }
    }
  }
`;
