import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const EmptyMealByDay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 250px;

  img.ui {
    margin-bottom: 20px;
    height: 140px;
    display: inline-block !important;
  }
`;

export const EmptyTitle = styled.div`
  color: #202353;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const FeatureTurnedOff = styled.div`
  background: #fffae6;
  border: 1px solid #eea827;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #eea827;
  width: fit-content;
  margin: 25px auto 0;

  img {
    margin-right: 6px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;

  .left-panel:hover + .button-collapse,
  .button-collapse:hover {
    opacity: 1;
    visibility: visible;
  }
`;

export const CalendarWrapper = styled.div`
  padding: 0 2px 20px 25px;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 297px;
    left: 30px;
    background-color: #f0f0f2;
    height: 1px;
  }
`;

export const LeftPanel = styled.div`
  width: 302px;
  padding: 31px 0;
  border-right: 1px solid #f0f0f2;
  background-color: #fbfbfb;
  box-shadow: -4px 0px 12px 0px #00000003 inset;
  flex-shrink: 0;
  transition: width 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;

  ${({ hideLeftPanel }) =>
    hideLeftPanel &&
    css`
      width: 0;
    `}

  ::-webkit-scrollbar {
    width: 22px !important;
  }

  ::-webkit-scrollbar-track {
    margin-top: 22px;
    margin-bottom: 22px;
    border-radius: 15px !important;
    background: #fbfbfb;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1ea !important;
    border-radius: 15px !important;
    border: 8px solid #fbfbfb !important;
  }

  ${props =>
    props.noPermissionAddOn &&
    css`
      overflow-y: auto;

      ${CalendarWrapper} {
        padding: 0 24px 25px 25px;
      }
    `}

  @media screen and (max-width: 1279px) {
    &.left-panel {
      display: none;
    }

    &.left-panel-xl {
      position: fixed;
      z-index: 1002;
      left: 0;
      height: 100%;
    }
  }
`;

export const LeftPanelWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: unset;
  right: 0;
  z-index: 1001;
  ${props =>
    props.open &&
    css`
      top: 0;
    `}
`;

export const RightPanelWrapper = styled.div`
  flex: 1;
  padding: 30px 16px 30px 30px;
  overflow-y: scroll;
  margin-right: 14px;
  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1ea !important;
    border-radius: 6px !important;
  }
  ::-webkit-scrollbar-track {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
`;

export const ButtonCollapse = styled.div`
  cursor: pointer;
  position: absolute;
  top: 31px;
  left: 288px;
  width: 32px;
  height: 32px;
  border: 1px solid #f0f0f2;
  box-shadow: 0px 2px 30px 0px #acacac33;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;

  .__react_component_tooltip {
    padding: 8px 16px;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &:hover {
    background-color: #f0f1ff;
    svg {
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const ButtonExpand = styled.div`
  cursor: pointer;
  position: absolute;
  top: 31px;
  left: 30px;
  width: 32px;
  height: 32px;
  border: 1px solid #f0f0f2;
  box-shadow: 0px 2px 30px 0px #acacac33;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .__react_component_tooltip {
    padding: 8px 16px;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &:hover {
    background-color: #f0f1ff;
    svg {
      path {
        fill: #5158cf;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    &.button-expand-xl {
      display: none;
    }
  }
`;

export const MealPlanFormatWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 82px);
  @media only screen and (max-height: 768px) {
    height: 100%;
  }
`;
