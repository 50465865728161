import styled, { css } from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export default {
  index: { marginRight: 12, fontSize: 18 },
  exerciseName: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export const DropItem = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222222;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 28px;
    height: 15px;
    background-image: url(${CDN_URL}/images/customize.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center left;

    &.edit {
      background-image: url(${CDN_URL}/images/customize.svg);
    }
    &.customize {
      background-image: url(${CDN_URL}/images/customize.svg);
    }
    &.new-section {
      background-image: url(${CDN_URL}/images/plus_circle_black.svg);
    }
    &.duplicate {
      background-image: url(${CDN_URL}/images/duplicate_black.svg);
    }
    &.delete {
      background-image: url(${CDN_URL}/images/delete_black.svg);
    }
    &.move {
      background-image: url(${CDN_URL}/images/arrow_right_bold_black.svg);
    }
    &.view {
      background-image: url(${CDN_URL}/images/view.svg);
    }
    &.split {
      background-image: url(${CDN_URL}/images/split.svg);
    }
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
    .icon {
      &.edit {
        background-image: url(${CDN_URL}/images/customize_purple.svg);
      }
      &.customize {
        background-image: url(${CDN_URL}/images/customize_purple.svg);
      }
      &.new-section {
        background-image: url(${CDN_URL}/images/plus_circle_purple.svg);
      }
      &.duplicate {
        background-image: url(${CDN_URL}/images/duplicate_purple.svg);
      }
      &.delete {
        background-image: url(${CDN_URL}/images/delete_purple.svg);
      }
      &.move {
        background-image: url(${CDN_URL}/images/arrow_right_bold_purple.svg);
      }
      &.view {
        background-image: url(${CDN_URL}/images/view_purple.svg);
      }
      &.split {
        background-image: url(${CDN_URL}/images/split_purple.svg);
      }
    }
  }
`;

export const AltExerciseContainer = styled.div`
  margin: 3px 22px 20px 43px;

  ${props =>
    props.tracking &&
    css`
      margin: 3px 23px 20px 23px;
    `}

  .tille {
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a778a;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #726ee4;
      font-size: 12px;
      text-transform: none;
      cursor: pointer;
    }
  }

  .alt-exs__menu {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #444444;
    border: solid 1px #dcdcde;
    background-color: #f6f6f6;
    border-radius: 4px;
    padding: 8px;
    padding-bottom: 0;

    ${props =>
      props.tracking &&
      css`
        padding: 15px;
        padding-bottom: 7px;
      `}

    .menu__item {
      padding: 2px 10px;
      margin-bottom: 8px;
      margin-right: 8px;
      display: inline-block;
      border-radius: 4px;
      background-color: #e9e9e9;
      cursor: not-allowed;
      span:first-child {
        color: #c9c9c9;
        margin-right: 5px;
        font-weight: bold;
      }

      ${props =>
        props.tracking &&
        css`
          width: calc(50% - 3px);
          padding: 8px 10px;
          margin-right: 5px;

          &:nth-child(2n) {
            margin-right: 0;
          }
        `}
    }
  }
  ${props =>
    props.tracking &&
    css`
      .alt-exs__menu {
        border: none;
        background: #f7f8fc;
        border-radius: 8px;

        .menu__item {
          cursor: pointer;
          border: 1px solid #dadfea;
          border-radius: 5px;
          background: #ffffff;

          &:hover {
            border: 1px solid #5158cf;
          }
        }
      }
    `}
`;

export const SwapArea = styled.div`
  display: flex;
  height: 30px;
  margin-left: 12px;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;

    &:hover {
      path {
        stroke: #5158cf;
      }
    }
  }

  .__react_component_tooltip {
    &.place-top {
      margin-top: -8px;
      margin-left: 3px;

      &.type-dark {
        padding: 9px 29px;
        border-radius: 5px;

        > span {
          line-height: 19.5px;
          font-weight: 400;
        }

        &::after {
          content: '';
          bottom: -7px;
          border-top-color: #2d2e2d;
          border-top-width: 5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }
    }
  }
`;

export const DeleteSetsIcon = styled.div`
  position: absolute;
  display: none;
  top: 50%;
  left: 0;
  height: 17px;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
`;

export const ExerciseInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 21px;
  font-size: 13px;
  line-height: 13px;
  color: #202353;
  font-weight: 400;

  ${props =>
    !props.isInfo &&
    css`
      height: auto;
    `}

  .divider {
    width: 1px;
    height: 100%;
    background: #dadfea;
  }

  strong {
    font-weight: 600;
  }
`;

export const ExerciseNote = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  &:focus {
    -webkit-box-orient: initial;
    display: block;
    -webkit-line-clamp: initial;
    overflow: initial;
    outline: 0;
  }
`;

export const MarkAllErrorText = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ea314a;
  margin: 5px 22px 17px 23px;
`;

export const TrackingChecked = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 4px;

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;

    path:first-child {
      fill: #dadfea;

      ${props =>
        props.isChecked &&
        css`
          fill: #63bb6c;
        `}
    }
  }
`;

export const AltExerciseItem = styled.div`
  display: flex;
  align-items: center;

  .media-preview {
    margin-right: 10px;
  }

  .count {
    color: #979797;
  }

  .title {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
