import React from 'react';
import get from 'lodash/get';
import * as S from './style';

const ListIngredientSelected = props => {
  const { handleRemoveDietary, list } = props;

  return (
    <S.ListIngredientSelectedWrapper>
      <S.Title>
        SELECTED <S.NumberSelected>{get(list, 'length', 0)}</S.NumberSelected>
      </S.Title>
      <S.List>
        {list.map(item => {
          const { _id, image_thumbnail } = item;
          const data = { ...item, src: image_thumbnail };
          return (
            <S.Item
              key={_id}
              item={data}
              hasCheckbox={false}
              hasClose={true}
              handleRemoveDietary={handleRemoveDietary}
            />
          );
        })}
      </S.List>
    </S.ListIngredientSelectedWrapper>
  );
};

export default ListIngredientSelected;
