import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #7B7E91;
`;

export const Overlay = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 18;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  visibility: hidden;
  opacity: 0;
`;

export const IconInfo = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  z-index: 19;
  visibility: hidden;
  opacity: 0;

  ${({ isEditAble }) =>
    isEditAble &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  svg {
    width: 17px;
    height: 17px;
    path {
      fill: #e1e1ea;
    }
  }

  &:hover {
    background: #f0f1ff !important;

    svg path {
      fill: #5158cf !important;
    }
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    ${Overlay} {
      visibility: visible;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }

    ${IconInfo} {
      visibility: visible;
      opacity: 1;

      svg path {
        fill: #e1e1ea;
      }
    }

    ${props =>
      props.isEditAble &&
      css`
        cursor: default;

        ${Overlay} {
          visibility: hidden;
          opacity: 0;
        }

        ${IconInfo} {
          visibility: hidden;
          opacity: 0;
        }
      `}
  }

  .thumbnail-info {
    ${textBase}
    font-size: 12px;
    line-height: 18px;
    padding: 8px 10px;
    width: 172px !important;
    background-color: #333333 !important;
    color: #fff;
    margin-top: -12px !important;
    border-radius: 4px;
    z-index: 99;
    user-select: none;

    &.type-dark.place-top:after {
      content: '';
      width: 16px;
      height: 16px;
      background: #333333;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
      border-bottom-right-radius: 2px;
      z-index: -1;
      border: none !important;
      margin-bottom: 0 !important;
      margin-left: -2px;
    }
  }

  .image-with-fallback {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
      outline: none;
    }
  }
`;

export const Desc = styled.div`
  ${textBase}
  color: #F0F0F2;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 150%;
`;
