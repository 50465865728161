import React, { useState, memo, useMemo } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';

// Assets
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_currentColor.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/check-mark-freestyle-section.svg';

import * as S from './style';

const DropdownOption = props => {
  const {
    onSelectFilter,
    selected,
    options = [],
    className,
    placeholder = '',
    onOpen,
    isLoadingMetric = false,
  } = props;
  const [open, setOpen] = useState(false);

  const activeLabel = useMemo(() => {
    const item = options.find(item => item.value === selected);
    return item ? item.shortLabel || item.label : '';
  }, [selected, options]);

  const handleOpenDropdown = val => () => {
    if (isLoadingMetric) return;
    setOpen(val);
    if (val && onOpen) {
      onOpen();
    }
  };

  const handleSelectFilter = value => () => {
    onSelectFilter(value);
    setOpen(false);
  };

  return (
    <S.Wrapper className={className}>
      <S.Trigger
        onClick={handleOpenDropdown(!open)}
        active={open}
        className={classNames('dropdown-wrapper', { 'active-select': open, disabled: isLoadingMetric })}
      >
        {activeLabel || placeholder}
        <ArrowDownIcon className="arrow-down-icon" />
      </S.Trigger>
      {open && (
        <RootCloseWrapper event="click" onRootClose={handleOpenDropdown(false)}>
          <S.Popup className="options">
            {options.map(item => (
              <S.Option onClick={handleSelectFilter(item.value)} active={selected === item.value} key={item.value}>
                <div className="sort-item-label">{item.label}</div>
                {selected === item.value && <CheckedIcon className="checked-icon" />}
              </S.Option>
            ))}
          </S.Popup>
        </RootCloseWrapper>
      )}
    </S.Wrapper>
  );
};

export default memo(DropdownOption);
