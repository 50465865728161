import React, { useEffect } from 'react';
import classnames from 'classnames';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Icon } from 'semantic-ui-react';

import {
  timeSince,
  getUserShortName,
  convertS3UrlToCloudFrontUrl,
  getFormatImageUrlWithSML,
} from 'utils/commonFunction';
import { MEDIA_FILE_REJECTED_CONTENT } from 'constants/commonData';
import MediaPlayer from 'shared/MediaPlayer';
import FileUpload from 'shared/FileUpload';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import S3Video from 'shared/S3Video';
import MentionEditor from 'components/CommunityForum/components/MentionEditor';
import GiphyWatermark from 'shared/Giphy/components/Watermark';
import { ReactComponent as RemovePostIcon } from 'assets/icons/remove_comment.svg';
import { FORUM_MOMENT_SETTING } from 'components/CommunityForum/constants';
import { SharedTooltip } from 'shared/SharedTooltip';

import * as S from './style';

function CommentItem(props) {
  const {
    cloudfrontList,
    comment,
    showAttachItem,
    members,
    userId,
    hasReplyComment,
    onReplyComment,
    headerMedia,
    sizeAvatar = 32,
  } = props;

  const {
    author,
    content,
    _id,
    createdAt,
    attach_item,
    attachment,
    attachment_type,
    isLocalFile,
    attachment_thumbnail,
    uploadConfigs,
    loadingReply = false,
  } = comment;
  const name = `${get(author, 'first_name')} ${get(author, 'last_name')}`;
  const avatar = get(author, 'avatar');
  const color = get(author, 'color');
  const duration = timeSince(new Date(createdAt), 'minute');

  const tooltip = 'tooltip_' + _id;
  const isMediaComment = content === MEDIA_FILE_REJECTED_CONTENT && attachment;
  const headerForMedia = !(headerMedia && isMediaComment);
  const isVideo = attachment_type && attachment_type.startsWith('video');
  const isImage = attachment_type && attachment_type.startsWith('image');
  const isAuthorComment = userId === author._id;

  useEffect(() => {
    moment.locale(moment.locale(), {
      relativeTime: FORUM_MOMENT_SETTING,
    });
  }, []);

  const renderMediaFile = () => {
    if (!isMediaComment) {
      return null;
    }

    const src = isLocalFile ? attachment : attachment_thumbnail;
    const preview = [isImage ? getFormatImageUrlWithSML(attachment_thumbnail) : attachment_thumbnail || ''];
    if (!isVideo) {
      preview.push(attachment || '');
    }

    return (
      <>
        {headerMedia && (
          <S.HeaderMedia>
            <p>{name}</p>
            {isAuthorComment && !isLocalFile && (
              <S.DropdownWrapper
                direction="middle"
                className="dropdown"
                upward={false}
                icon="ellipsis horizontal"
                onOpen={() => {}}
              >
                <Option onClick={props.onDelete}>
                  <S.DropdownOption>
                    <RemovePostIcon />
                    <span>Remove Comment</span>
                  </S.DropdownOption>
                </Option>
              </S.DropdownWrapper>
            )}
          </S.HeaderMedia>
        )}
        <S.MediaBox isReplyComment={!hasReplyComment} headerMedia={headerMedia}>
          <S.MediaContainer isReplyComment={!hasReplyComment}>
            {isLocalFile ? (
              <>
                {isVideo ? (
                  <S.Video isReplyComment={!hasReplyComment}>
                    <source src={src} type={attachment_type} />
                    <source src={src} type="video/mp4" />
                  </S.Video>
                ) : (
                  <S3ImageWithFallback src={[src]} cover={true} thumbnail={true} />
                )}
                <FileUpload
                  onSuccess={props.onUploadSuccess}
                  onError={props.onUploadFail}
                  onCancel={props.onCancelUpload}
                  configs={uploadConfigs}
                  removeCancelUpload
                />
              </>
            ) : (
              <>
                {isVideo ? (
                  <S3Video
                    videoLink={attachment}
                    videoType={attachment_type}
                    thumbnail={attachment_thumbnail}
                    mediaId={_id}
                  />
                ) : includes(preview[0], 'giphy') ? (
                  <GiphyWatermark ratioCommentForum isReplyComment={!hasReplyComment}>
                    <S3ImageWithFallback src={preview} cover={true} thumbnail={true} />
                  </GiphyWatermark>
                ) : (
                  <GiphyWatermark ratioCommentForum isReplyComment={!hasReplyComment} showWatermark={false}>
                    <S3ImageWithFallback src={preview} cover={true} thumbnail={true} />
                  </GiphyWatermark>
                )}
              </>
            )}
            {!uploadConfigs && (
              <MediaPlayer url={attachment} type={attachment_type} thumbnail={isVideo ? attachment_thumbnail : ''} />
            )}
          </S.MediaContainer>
        </S.MediaBox>
      </>
    );
  };

  useEffect(() => {
    return () => {
      if (isLocalFile) {
        window.URL.revokeObjectURL(attachment);
      }
    };
  }, []);

  const handleRenderTriggerIcon = ({ open }) => {
    return (
      <>
        <Icon
          name="ellipsis horizontal"
          className={classnames('evf-dropdown__trigger--icon', { 'icon-checked': open })}
          data-for="comment-options-tooltip"
          data-tip
        />
        {!open && <SharedTooltip id="comment-options-tooltip" noWrap />}
      </>
    );
  };

  return (
    <S.Wrapper key={_id}>
      <Avatar
        name={getUserShortName(author)}
        color={color}
        className="comment__avatar"
        size={sizeAvatar}
        src={convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true)}
      />
      <S.Container className={classnames('comment', { commentMedia: renderMediaFile() })}>
        {headerForMedia && <S.Author className="comment__username">{name}</S.Author>}
        {headerForMedia && isAuthorComment && !isLocalFile && (
          <S.DropdownWrapper
            direction="middle"
            className="dropdown"
            upward={false}
            onOpen={() => {}}
            triggerIcon={handleRenderTriggerIcon}
          >
            <Option onClick={props.onDelete}>
              <S.DropdownOption>
                <RemovePostIcon />
                <span>Remove Comment</span>
              </S.DropdownOption>
            </Option>
          </S.DropdownWrapper>
        )}
        <S.Content className="comment__content">
          <S.Message>
            {content !== MEDIA_FILE_REJECTED_CONTENT && (
              <MentionEditor members={members} initEditor={comment} readOnly={true} />
            )}
          </S.Message>
        </S.Content>
        <S.OtherInfo className="comment__other-info">
          {renderMediaFile()}
          {showAttachItem && attach_item ? (
            <S.CommentAttachItem className="attach_item">{attach_item.title}&nbsp;•&nbsp;</S.CommentAttachItem>
          ) : null}
        </S.OtherInfo>
        <S.ReplyWrapper>
          <S.CommentDate className="comment__date" data-tip data-for={tooltip}>
            {duration}
          </S.CommentDate>
          {hasReplyComment && (
            <S.ReplyButtonWrapper loadingReply={loadingReply}>
              <S.ReplyButton onClick={onReplyComment({ authorId: get(author, '_id', ''), name, comment })}>
                Reply
              </S.ReplyButton>
            </S.ReplyButtonWrapper>
          )}
        </S.ReplyWrapper>
      </S.Container>

      <ReactTooltip className="app-tooltip" id={tooltip} effect="solid" place={'top'}>
        <span>{moment(createdAt).format('dddd, MMMM DD, YYYY [at] h:mm A')}</span>
      </ReactTooltip>
    </S.Wrapper>
  );
}

const mapState = state => {
  const { user, cloudfrontList } = state;
  return { userId: user._id, user, cloudfrontList };
};

export default connect(mapState)(CommentItem);
