import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Wrapper } from './style';
import UpgradePath from 'shared/UpgradePath';
import { Helmet } from 'react-helmet';
import { INITIAL_WORKING_COLLECTION } from 'constants/commonData';

const NAVS = [
  { label: 'Overview', route: '' },
  { label: 'Clients', route: 'clients' },
];

const WorkingCollectionDetailHeader = props => {
  const { workingCollection, location, loading } = props;
  if (_.isEqual(workingCollection, INITIAL_WORKING_COLLECTION) && loading) {
    return null;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{workingCollection.name} - Everfit</title>
      </Helmet>
      <div className="workout-collection-detail__name">
        <span>
          <UpgradePath pathName="on_demand_workout" fallback={<></>}>
            {workingCollection.name}
          </UpgradePath>
        </span>
      </div>
      <div className="workout-collection-detail__nav-menu">
        {_.map(NAVS, (nav, index) => (
          <NavLink
            key={index}
            className="workout-collection-detail__nav-menu__item"
            exact={nav.route === ''}
            to={`/home/workout-collections/${workingCollection._id}${nav.route ? '/' + nav.route : ''}`}
            isActive={() => {
              return (
                location.pathname ===
                `/home/workout-collections/${workingCollection._id}${nav.route ? '/' + nav.route : ''}`
              );
            }}
          >
            {nav.label}
          </NavLink>
        ))}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, router } = state;
  const { workoutCollection } = rootReducer;
  const { workingCollection, loading } = workoutCollection;

  return {
    workingCollection,
    location: router.location,
    loading,
  };
};

export default connect(mapStateToProps, null)(WorkingCollectionDetailHeader);
