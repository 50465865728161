import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Header } from 'shared/Styles/ModalLayout';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 524px;
  }

  > .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }

  ${Header} {
    padding-bottom: 16px;
  }
  .wrapper-content {
    line-height: 0;
    padding-bottom: 13px;
  }
  .wrapper-footer-question {
    padding-bottom: 20px;
    button {
      padding: 7px 30px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      &.disable {
        background: #c4c4c4;
        color: #fff;
      }
    }
    .btn-back {
      border: 1px solid #d6dae4;
      box-sizing: border-box;
      color: #818da1;
      background-color: #fff;
    }
    .btn-confirm {
      background: #5158cf;
    }
  }
`;
export const ModalSubTitle = styled.h5`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #202353;
  margin: 0;
  padding-left: 30px;
  padding-right: 30px;
`;
export const ModalTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  padding: 30px 30px 0;
  margin-top: 0;
  margin-bottom: 10px;
`;

export const ListWrapper = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-top: 35px;
`;

export const CommentWrapper = styled.div`
  padding-left: 25px;
  display: none;
`;

export const Item = styled.li`
  display: flex;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }
  .checkbox-icon {
    width: 16px;
    height: 16px;
    border: 1px solid #d9dadf;
    border-radius: 3px;
  }
  &.checked + ${CommentWrapper} {
    display: flex;
  }
`;

export const Desc = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  padding-left: 5px;
`;

export const CommentArea = styled.textarea`
  width: 100%;
  border: 1px solid #dadfea;
  border-radius: 3px;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  padding: 12px;
  outline: none;
  resize: none;
  min-height: 68px;
  background: transparent;
  margin-bottom: 14px;
  overflow: scroll;
  white-space: pre-line;
  &:focus {
    border: 1px solid #5158cf;
  }
  &:required:invalid {
    border: 1px solid #ea314a;
  }
`;
