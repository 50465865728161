import React from 'react';
import { CDN_URL } from 'constants/commonData';
import { Container } from './style';

export default class VideoDuration extends React.Component {
  render() {
    return (
      <Container {...this.props} className="video-duration">
        <div className="duration">
          <img src={`${CDN_URL}/images/video_duration.svg`} alt="" />
          <span className="time">00:00</span>
        </div>
      </Container>
    );
  }
}
