import { connect } from 'react-redux';

import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { getClientNote, addClientNote, updateClientNote, deleteClientNote } from 'actions/client';
import AdvancedNote from './component';

const mapStateToProps = state => {
  const { user } = state;

  return {
    user,
  };
};

const mapDispatchToProps = {
  getClientNote,
  addClientNote,
  updateClientNote,
  deleteClientNote,
  toggleModal,
  toggleConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedNote);
