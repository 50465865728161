export const ACTION_TYPE = {
  ADD: 'add',
  REPLACE: 'replace',
};

export const ASSIGNED_STATUS = {
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

export const CLIENT_MEAL_PLAN_VIEW_MODE = [
  { key: 'day', label: 'Day' },
  { key: 'week', label: 'Week' },
];

export const MAX_ITEM_PER_ROW = 4;
