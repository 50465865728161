import _ from 'lodash';
import Request from 'configs/request';

export const Types = {
  BILLING_SUCCESS_GET_OVERVIEW_DATA: 'BILLING_SUCCESS_GET_OVERVIEW_DATA',
  BILLING_FAILED_GET_OVERVIEW_DATA: 'BILLING_FAILED_GET_OVERVIEW_DATA',
  BILLING_SUCCESS_UPDATE_PAYMENT_INFO: 'BILLING_SUCCESS_UPDATE_PAYMENT_INFO',
  BILLING_SUCCESS_UPDATE_BILLING_CONTACT: 'BILLING_SUCCESS_UPDATE_BILLING_CONTACT',
  BILLING_TOGGLE_SHOW_PAYMENT_INFORMATION_FORM: 'BILLING_TOGGLE_SHOW_PAYMENT_INFORMATION_FORM',
};

export const getBillingOverviewData = (isLoading = true) => {
  return Request.get(
    {
      url: '/api/pricing/get-team-billing-data',
    },
    isLoading,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.BILLING_SUCCESS_GET_OVERVIEW_DATA, payload: { data } });
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.BILLING_FAILED_GET_OVERVIEW_DATA });
    },
  );
};

export const updatePaymentInfo = bodyData => {
  return Request.post(
    {
      url: '/api/pricing/add-team-payment-info',
      data: bodyData,
    },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.BILLING_SUCCESS_UPDATE_PAYMENT_INFO, payload: { data } });
      dispatch(getBillingOverviewData());
    },
  );
};

export const updateBillingContact = bodyData => {
  return Request.post(
    {
      url: '/api/pricing/update-team-billing-contact',
      data: bodyData,
    },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.BILLING_SUCCESS_UPDATE_BILLING_CONTACT, payload: { data } });
    },
  );
};

export const togglePaymentInformation = (data, billingData) => ({
  type: Types.BILLING_TOGGLE_SHOW_PAYMENT_INFORMATION_FORM,
  payload: { data, billingData },
});

export const downgradeToStarter = () => {
  return Request.post(
    {
      url: '/api/pricing/downgrade-to-starter',
    },
    true,
  );
};
