import React, { useState, useEffect, useRef } from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { Mixpanel } from 'utils/mixplanel';
import Workout, { DRAGGALE_TYPE } from 'shared/WorkoutCalendarCompactView';
import { pluralize } from 'utils/commonFunction';
import { CloseIcon } from 'shared/Icons';
import AssignWorkoutModal from './AssignWorkoutModal';
import AssignProgramModal from './AssignProgramModal';
import ConfirmModal from 'shared/ConfirmModal';
import { convertFromExerciseSetToSupersetsJson, convertFromWorkoutToAPIExercise } from 'helpers/workout';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { togglePopup } from 'actions/popup';
import {
  viewAutoflowIntervalWorkoutDetail,
  deleteAutoflowIntervalWorkout,
  copyAutoflowIntervalWorkout,
  saveAutoflowIntervalWorkoutToLibrary,
  pasteAutoflowIntervalWorkoutToDay,
  addSectionToAutoflowIntervalWorkout,
  updateAutoflowIntervalSectionFromCalendar,
  arrangeAutoflowIntervalWorkoutInDay,
} from 'redux/autoflowInterval/training/actions';
import * as CellLayout from '../Calendar/CellLayout';
import AssignMenu from './AssignMenu';
import {
  assignProgramToAutoflowInterval,
  selectWorkout,
  handlePasteMultipleWorkout,
  resetSelectedWorkouts,
} from 'redux/autoflowInterval/training/actions';

function AutoflowIntervalCalendarCell(props) {
  const {
    autoflowId,
    weekIndex,
    dayIndex,
    copying,
    copyingWorkouts,
    user,
    cellId,
    draggingWorkout,
    selectWorkout,
    resetSelectedWorkouts,
    selectedWorkouts,
    calendarType,
    handlePasteMultipleWorkout,
    isLoadingWorkouts,
    startWeek,
    totalWeek,
  } = props;
  const workouts = props.workouts.toJS();
  const currentSelectedWorkouts = selectedWorkouts ? selectedWorkouts.toJS() : [];
  const [state, setState] = useState({ cellContentHeight: 0, maximumRenderedWorkouts: 0, isShowAllWorkouts: false });

  const wrapperRef = useRef();
  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      if (entries[0]) {
        const {
          contentRect: { height },
          target,
        } = entries[0];

        if (height !== state.cellContentHeight) {
          const hasSelectedWorkout = target.classList.contains('has-overlap');
          const menuBottomBar = hasSelectedWorkout ? 52 : 0;

          const cellHeaderHeight = 34;
          const cellFooterHeight = 22;
          const containerHeight = height - cellFooterHeight - cellHeaderHeight - menuBottomBar;
          const workoutHeight = 52;
          const maximumRenderedWorkouts = parseInt(containerHeight / workoutHeight);
          setState(prevState => ({ ...prevState, cellContentHeight: containerHeight, maximumRenderedWorkouts }));
        }
      }
    }),
  );

  useEffect(() => {
    if (wrapperRef.current) {
      resizeObserver.current.observe(wrapperRef.current);
    }

    return () => {
      resizeObserver.current.disconnect();
    };
  }, [wrapperRef.current, selectedWorkouts]);

  useEffect(() => {
    if (copying) {
      (!isEmpty(selectedWorkouts) || copyingWorkouts) && resetSelectedWorkouts();
    }
  }, [copying]);

  const viewAllWorkouts = () => {
    setState(prevState => ({ ...prevState, isShowAllWorkouts: true }));
  };

  const hideViewAllMode = () => {
    setState(prevState => ({ ...prevState, isShowAllWorkouts: false }));
  };

  const onViewWorkoutDetail = workout => {
    const otherData = { weekIndex, dayIndex, autoflowId };
    const params = { autoflowId, workoutId: workout._id };
    props.viewAutoflowIntervalWorkoutDetail(params, otherData);
  };

  const pasteWorkout = event => {
    (!isEmpty(selectedWorkouts) || copyingWorkouts) && resetSelectedWorkouts();
    if (!copying) {
      return;
    }

    const multiPaste = event.shiftKey;
    const bodyData = { weekIndex, dayIndex, autoflowId, workoutId: copying.toJS()._id };
    props.pasteAutoflowIntervalWorkoutToDay(bodyData, multiPaste);
  };

  const handlePasteWorkouts = event => {
    if (!copyingWorkouts) {
      return;
    }
    const multiPaste = event.shiftKey;
    const debouncePasteMultipleWorkout = debounce(handlePasteMultipleWorkout, 100);
    debouncePasteMultipleWorkout(dayIndex, weekIndex, multiPaste);
  };

  const onAssignWorkout = () => {
    Mixpanel.track('autoflow_interval_training_calendar_screen_add_workout');
    props.toggleModal(true, <AssignWorkoutModal weekIndex={weekIndex} dayIndex={dayIndex} autoflowId={autoflowId} />);
  };

  const onSubmitSelectedProgram = (d, programId, startingAt) => {
    const bodyData = {
      autoflow_id: autoflowId,
      program_id: programId,
      day_index: dayIndex,
      week_index: weekIndex,
      starting_at: startingAt + 1,
    };

    return props.assignProgramToAutoflowInterval(bodyData);
  };

  const onAssignProgram = () => {
    const { totalWeek } = props;
    Mixpanel.track('autoflow_interval_training_calendar_screen_add_program');
    props.toggleModal(
      true,
      <AssignProgramModal
        assignProgram={onSubmitSelectedProgram}
        startingDayControlled
        date={Number(weekIndex) * 7 + Number(dayIndex)}
        totalDays={totalWeek * 7}
      />,
    );
  };

  const saveWorkoutToLibrary = workout => {
    props.saveAutoflowIntervalWorkoutToLibrary({ workoutId: workout._id, autoflowId });
  };

  const deleteWorkout = workout => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete workout"
        content="Are you sure that you want to delete this workout?"
        onConfirm={() => {
          const bodyData = { autoflowId, weekIndex, dayIndex, workoutId: workout._id };
          const { deleteAutoflowIntervalWorkout, selectWorkout } = props;
          const isExistingWorkout = selectedWorkouts.includes(workout._id);
          isExistingWorkout && selectWorkout(get(workout, '_id'));
          return deleteAutoflowIntervalWorkout(bodyData);
        }}
      />,
    );
  };

  const arrangeWorkoutInDay = (workout, newIndex) => {
    const bodyData = { weekIndex, dayIndex, newIndex, autoflowId, workoutId: workout._id };
    props.arrangeAutoflowIntervalWorkoutInDay(bodyData);
  };

  const handleSelectWorkout = id => {
    selectWorkout && selectWorkout(id);
  };

  const addSection = ({ assignment, exercise }) => {
    props.togglePopup(null);
    const exerciseJson = convertFromExerciseSetToSupersetsJson(exercise);
    props.addSectionToAutoflowIntervalWorkout({
      weekIndex,
      dayIndex,
      autoflowId,
      workoutId: assignment._id,
      exercise: exerciseJson,
    });
  };

  const updateSection = newWorkoutData => {
    props.togglePopup(null);
    const bodyData = {
      weekIndex,
      dayIndex,
      workoutId: newWorkoutData._id,
      autoflowId,
      title: newWorkoutData.title,
      description: newWorkoutData.description,
      sections: convertFromWorkoutToAPIExercise(newWorkoutData, user.preferences),
    };
    props.updateAutoflowIntervalSectionFromCalendar(bodyData);
  };

  const deleteSection = data => {};

  const renderCellContent = () => {
    const remain = workouts.length - state.maximumRenderedWorkouts;
    const views = [];

    if (workouts.length) {
      const showAllWorkouts = state.maximumRenderedWorkouts >= workouts.length;

      for (let i = 0; i < workouts.length && (state.isShowAllWorkouts || i < state.maximumRenderedWorkouts); i++) {
        const onlyShowOverview = !showAllWorkouts || i < workouts.length - 1;
        views.push(
          <Workout
            key={`${cellId}_${workouts[i]._id}`}
            onlyShowOverview={onlyShowOverview}
            workoutIndex={i}
            currentWorkout={workouts[i]}
            cellId={cellId}
            totalWorkouts={workouts.length}
            cellContentHeight={state.cellContentHeight}
            isShowAllWorkouts={state.isShowAllWorkouts}
            draggingWorkout={draggingWorkout}
            viewWorkoutDetail={onViewWorkoutDetail}
            viewAllData={viewAllWorkouts}
            saveWorkoutToLibrary={saveWorkoutToLibrary}
            copyWorkout={props.copyAutoflowIntervalWorkout}
            deleteWorkout={deleteWorkout}
            arrangeWorkoutInDay={arrangeWorkoutInDay}
            onAddSection={addSection}
            onUpdateSection={updateSection}
            onDeleteSection={deleteSection}
            onSelectWorkout={handleSelectWorkout}
          />,
        );
      }
    }

    const content = (
      <CellLayout.CellContentContainer className="itvAutoflowCalCell__contentContainer">
        {views}
        {state.isShowAllWorkouts ? null : (
          <CellLayout.CellFooter>
            {remain > 0 ? (
              <CellLayout.ViewMoreItem onClick={viewAllWorkouts}>
                {pluralize(`+ ${remain} more workout`, remain)}
              </CellLayout.ViewMoreItem>
            ) : null}
            {!isLoadingWorkouts && (
              <CellLayout.PasteButton onClick={copyingWorkouts ? handlePasteWorkouts : pasteWorkout}>
                Paste
              </CellLayout.PasteButton>
            )}
          </CellLayout.CellFooter>
        )}
      </CellLayout.CellContentContainer>
    );

    if (!views.length) {
      return (
        <Droppable droppableId={cellId} type={DRAGGALE_TYPE.SECTION} key={`${cellId}_workout`}>
          {provided => {
            return (
              <CellLayout.CellContent
                ref={provided.innerRef}
                className="itvAutoflowCalCell__content itvAutoflowCalCell__content--empty"
              >
                {content}
              </CellLayout.CellContent>
            );
          }}
        </Droppable>
      );
    } else {
      return <CellLayout.CellContent className="itvAutoflowCalCell__content">{content}</CellLayout.CellContent>;
    }
  };

  if (!cellId) {
    return <CellLayout.CellWrapper />;
  }

  const endWeek = Math.min(startWeek + calendarType, totalWeek);
  const needOverlapWeek = (endWeek - startWeek) % calendarType === 0 && endWeek;

  const hasOverlap = !isEmpty(currentSelectedWorkouts) && calendarType && weekIndex + 1 === needOverlapWeek;
  return (
    <Droppable droppableId={`wokout_${cellId}`} type={DRAGGALE_TYPE.WORKOUT} key={`${cellId}_cell`}>
      {provided => (
        <CellLayout.CellWrapper
          data-cell-id={cellId}
          ref={ref => {
            provided.innerRef(ref);
            wrapperRef.current = ref;
          }}
          copying={(copying || copyingWorkouts) && !draggingWorkout}
          showAll={state.isShowAllWorkouts}
          className={hasOverlap && 'has-overlap'}
        >
          <CellLayout.CellContainer className="itvAutoflowCalCellndar__cell-container">
            <CellLayout.CellHeader className="itvAutoflowCalCell__header">
              <div className="day">Day {Number(weekIndex) * 7 + Number(dayIndex) + 1}</div>
              <AssignMenu onAssignWorkout={onAssignWorkout} onAssignProgram={onAssignProgram} />
              {state.isShowAllWorkouts ? <CloseIcon onClick={hideViewAllMode} /> : null}
            </CellLayout.CellHeader>
            {renderCellContent()}
          </CellLayout.CellContainer>
          {provided.placeholder}
        </CellLayout.CellWrapper>
      )}
    </Droppable>
  );
}

const mapState = (state, ownProps) => {
  const {
    user,
    rootReducer: {
      autoflowInterval: { training, common },
      autoflow: {
        common: { workingAutoflow },
      },
    },
  } = state;

  const { weekIndex, dayIndex } = ownProps;
  const workouts = training.getIn(['workouts', `${weekIndex}_${dayIndex}`]) || List([]);

  return {
    workouts,
    user,
    autoflowId: get(workingAutoflow, '_id'),
    copying: training.get('copying'),
    copyingWorkouts: training.get('copyingWorkouts'),
    selectedWorkouts: training.get('selectedWorkouts'),
    isLoadingWorkouts: training.get('isLoadingWorkouts'),
    totalWeek: workingAutoflow.interval_weeks.length,
    startWeek: common.get('startWeek'),
  };
};

const actionCreators = {
  togglePopup,
  toggleModal,
  toggleConfirmModal,
  viewAutoflowIntervalWorkoutDetail,
  deleteAutoflowIntervalWorkout,
  copyAutoflowIntervalWorkout,
  saveAutoflowIntervalWorkoutToLibrary,
  pasteAutoflowIntervalWorkoutToDay,
  addSectionToAutoflowIntervalWorkout,
  updateAutoflowIntervalSectionFromCalendar,
  arrangeAutoflowIntervalWorkoutInDay,
  assignProgramToAutoflowInterval,
  selectWorkout,
  handlePasteMultipleWorkout,
  resetSelectedWorkouts,
};

export default connect(mapState, actionCreators)(AutoflowIntervalCalendarCell);
