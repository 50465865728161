import styled, { css } from 'styled-components';

export const MetadataWrapper = styled.div`
  display: flex;
  max-width: 518px;
  max-height: 374px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid rgba(32, 35, 83, 0.08);

  ${({ isThumbDefault }) =>
    isThumbDefault &&
    css`
      max-height: auto;
    `}

  ${props =>
    props.onTimeline &&
    css`
      max-width: unset;
      max-height: unset;
      border: unset;
      width: 583px;
      margin-bottom: 0px !important;
    `}
  ${props =>
    props.onPostDetail &&
    css`
      max-width: unset;
      max-height: unset;
      border: unset;
      margin-bottom: 0px;
    `}
`;

export const MetaContent = styled.div`
  width: 518px;
  /* height: 139px; */
  display: flex;
  position: relative;

  img {
    width: 139px;
    height: 139px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    object-fit: cover;
  }

  ${props =>
    props.onTimeline &&
    css`
      width: 598px;
      margin-left: -15px;
      cursor: pointer;
      img {
        border-top-left-radius: unset;
        border-bottom-left-radius: ${props => (props.isScheduledPost ? '8px !important' : 'unset')};
      }
    `}

  ${props =>
    props.onPostDetail &&
    css`
      width: 100%;
      cursor: pointer;
      img {
        border-radius: 0;
      }
    `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  gap: 4px;
  background: #edf0f7;
  width: ${props => (props.hasImage ? '376px' : '100%')};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  ${props =>
    props.onPostDetail &&
    css`
      border: unset;
      border-radius: unset;
      width: 100%;
      margin-left: 0px;
    `}

  ${props =>
    props.onTimeline &&
    css`
      border-top-right-radius: unset;
      border-bottom-right-radius: ${props => (props.isScheduledPost ? '8px !important' : 'unset')};
      width: ${props => (props.hasImage ? '459px' : '100%')};
    `}
`;

export const TextURL = styled.span`
  color: #62637f;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;

export const TextTitle = styled.span`
  color: #202353;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const TextDesc = styled.span`
  color: #62637f;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const MetaTextWrapper = styled.div`
  padding: 12px;
  flex-direction: column;
  position: relative;
  display: flex;
  gap: 4px;
`;

export const MetaVideoContent = styled.div`
  width: 518px;
  height: 372px;
  position: relative;
  display: flex;
  background: #edf0f7;
  border-radius: 8px;
  flex-direction: column;

  ${({ isThumbDefault, onTimeline }) =>
    isThumbDefault &&
    css`
      flex-direction: row;
      height: auto;

      img {
        width: 139px;
        height: auto;
        min-height: 139px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: ${onTimeline ? '0px' : '8px'};
      }
    `}

  ${props =>
    props.onPostDetail &&
    css`
      max-width: unset;
      max-height: unset;
      border: unset;
      border-radius: unset;
      width: 100%;
      height: 374px;
      margin-left: 0px;
      cursor: pointer;
    `}

  ${props =>
    props.onTimeline &&
    css`
      width: 598px;
      height: 100%;
      margin-left: -15px;
      border-radius: unset;
      cursor: pointer;
    `};

  ${props =>
    props.isScheduledPost &&
    css`
      border-radius: 8px;
    `};
`;

export const MetaVideoCover = styled.img`
  width: 518px;
  height: 286px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  object-fit: cover;

  ${props =>
    props.onTimeline &&
    css`
      width: unset;
      height: unset;
      border-top-right-radius: unset;
      border-top-left-radius: unset;
    `}

  ${props =>
    props.onPostDetail &&
    css`
      width: 100%;
      height: 286px;
      border-radius: unset;
    `}
`;

export const NoPreviewWrapper = styled.div`
  width: 514px;
  height: 286px;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ${props =>
    props.onTimeline &&
    css`
      width: 598px;
      height: 286px;
      border-top-right-radius: unset;
      border-top-left-radius: unset;
      svg {
        width: 100%;
        height: 100%;
      }
    `}
`;

export const MetaVideoURL = styled.span`
  color: #62637f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
`;

export const MetaVideoTitle = styled.span`
  color: #202353;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const MetaVideoDesc = styled.span`
  color: #62637f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const LoadingMetaWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(32, 35, 83, 0.08);
  justify-content: center;
  height: 144px;
  align-items: center;
  margin-bottom: 12px;
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;

  .ui.loader:before {
    border-color: transparent !important;
  }
  .ui.loader:after {
    border-color: #d9d9d9 transparent transparent !important;
  }
`;

export const CloseMetaData = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
`;
