// Lib
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash/debounce';

// Components
import FilterPopup from 'components/Recipes/Parts/FilterPopup';

// Shared
import { NewSearchInput } from 'shared/SearchInput';

// Assets
import { ReactComponent as FilterIcon } from 'assets/icons/MealPlans/meal_plan_filter_icon.svg';

// Style
import * as S from './style';

const FilterSearch = ({
  hideFilter = false,
  onSearch,
  onClearSearch,
  onSelectedFilterApply,
  appliedFilter,
  maxCalories,
  placeholder,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const [filterNumber, setFilterNumber] = useState(0);

  const handleSearch = (e, { value }) => {
    onSearch(value);
  };

  const handleClearSearch = () => {
    onClearSearch();
  };

  const renderTrigger = open => {
    return (
      <S.FilterButton data-for="filter-action" data-tip active={open}>
        <FilterIcon className="filter-icon" />
        {filterNumber > 0 ? <S.FilterNumber className="filter-number">{filterNumber}</S.FilterNumber> : null}
        <ReactTooltip className="filter-action-tooltip" id="filter-action" effect="solid" place="top">
          Filter
        </ReactTooltip>
      </S.FilterButton>
    );
  };

  const handleChangeNumberFilter = number => {
    setFilterNumber(number);
  };

  const onFocusSearch = () => {
    setIsFocus(true);
  };

  const onBlurSearch = () => {
    setIsFocus(false);
  };

  return (
    <S.FilterSearch onFocus={onFocusSearch} onBlur={onBlurSearch} isFocus={isFocus}>
      <NewSearchInput
        placeholder={placeholder || 'Search by keywords or name'}
        onChange={debounce(handleSearch, 300)}
        onClearSearch={handleClearSearch}
      />
      {!hideFilter && (
        <FilterPopup
          menuHeight={180}
          trigger={renderTrigger}
          onSelectedFilterApply={onSelectedFilterApply}
          onChangeNumberFilter={handleChangeNumberFilter}
          appliedFilter={appliedFilter}
          maxCalories={maxCalories}
          classNameCustom="filter-recipe-wrapper"
        />
      )}
    </S.FilterSearch>
  );
};

export default FilterSearch;
