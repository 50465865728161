import React from 'react';
import _ from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import { push } from 'connected-react-router';

import { Button } from 'shared/FormControl';
import { Checkbox } from 'shared/FormControl';
import MediaModal from 'shared/MediaModal/component';
import { axiosInstance } from 'configs/request';
import { getListStudioResource, updateStudioResourceToRedux } from 'redux/studio-resource/actions';
import UploadCoverImage from 'components/StudioProgram/List/CreateStudioProgramModal/CreateNewStudioProgram/UploadCoverImage';
import { toggleModal } from 'actions/modal';
import { removeUploadedFileFromServer, getPageMetaDataFromURL } from 'utils/commonRequest';
import { getLinkType, validateLink, validateYouTubeUrl, checkLinkFormat } from 'utils/validations';
import { checkYoutubeThumbnailUrl, formatShortLink, generateVimeoAPIUrl, isTeamAdmin } from 'utils/commonFunction';
import DocumentUpload from './DocumentUpload';
import AddResourcePopup from '../Heading/AddResourcePopup';
import FormItem from './FormItem';
import FormQuestionnaire from 'components/TaskCreate/FormQuestionnaire';
import * as S from './style';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import {
  TEAM_SHARE_NOOWNER,
  TEAM_SHARE_PRIVATE,
  LIMIT_SIZE_IMAGE_UPLOAD_MB,
  showMsgForSize,
  MEDIA_PLACEHOLDER,
  CDN_URL,
} from 'constants/commonData';
import { generateParams } from 'shared/AssetsShareSetting/constants';
import { hideError, showError } from 'actions/error';

export const RESOURCE_TYPES = {
  LINK: 'link',
  DOCUMENT: 'document',
  FORM: 'form',
};

const OpenLinkIcon = props => {
  return (
    <>
      <S.OpenLink onClick={props.onClick} data-tip data-for="open_link" />
      <ReactTooltip id="open_link" effect="solid" place={'top'} delayShow={100}>
        Open link
      </ReactTooltip>
    </>
  );
};

const formatLink = url => {
  const link = url.charAt(0).toLowerCase() + url.slice(1);
  const regex = /^(https|http)(.*)/i;
  if (!regex.test(link)) {
    return 'https://' + link;
  }
  return url;
};

class AddResourceFormPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      link: '',
      form: {},
      isUploadingImage: false,
      link_type: props.source_type,
      ownership: (props.defaultResource || {}).author || props.user._id,
      share: (props.defaultResource || {}).share || TEAM_SHARE_PRIVATE,
      ...(props.defaultResource || {}),
    };
    this.requestResetValue = React.createRef();

    this.checkOwnerInfo();
  }

  componentDidMount() {
    this.checkOwnerInfo();
    window.addEventListener('popstate', this.handleBackButtonToggleModal);
  }

  componentDidUpdate(prevProps) {
    const { defaultResource: { author, share } = {} } = this.props;
    const { defaultResource: { author: prevAuthor, share: prevShare } = {} } = prevProps;

    if (author !== prevAuthor || share !== prevShare) {
      this.checkOwnerInfo();
    }
  }

  checkOwnerInfo() {
    const { defaultResource = {}, user = {} } = this.props;
    this.author = typeof defaultResource.author_info === 'object' ? defaultResource.author_info : undefined;
    // add mode
    if (!defaultResource._id) {
      this.author = user;
    }
    this.authorId = (this.author || {})._id;
    this.isCreator = user._id === this.authorId;
    this.isOwnerOrAdmin = isTeamAdmin(user);
    this.isNoOwner = defaultResource.share === TEAM_SHARE_NOOWNER;
    this.isCreatorOrOwnerOrAdmin = this.isCreator || this.isOwnerOrAdmin || this.isNoOwner;
  }

  handleChangeField = (field, e) => {
    if (!this.isCreatorOrOwnerOrAdmin) return;
    this.setState({ [field]: e.target.value });
  };

  handleChangeLink = e => {
    if (!this.isCreatorOrOwnerOrAdmin) return;
    const link = e.target.value.trim();
    const link_type = getLinkType(link);
    this.setState({ link, link_type }, () => {
      this.handleBlurLink();
    });
  };

  handleBlurLink = async e => {
    const { link_type } = this.state;
    let { link } = this.state;
    link = formatLink(link);

    if (link_type === 'vimeo') {
      axios
        .get(generateVimeoAPIUrl(link))
        .then(response => {
          const data = response.data || {};
          const updateState = {
            name: data.title,
            cover_image: data.thumbnail_url || MEDIA_PLACEHOLDER,
          };
          this.setState(updateState);
        })
        .catch(() => {
          const updateState = {
            name: '',
            cover_image: '',
          };
          this.setState(updateState);
        });
    } else if (link_type === 'youtube') {
      const youtubeID = validateYouTubeUrl(link);
      const thumbnailUrl = await checkYoutubeThumbnailUrl(youtubeID);

      const updateState = {
        cover_image: thumbnailUrl,
      };
      this.setState(updateState);
    } else {
      this.getLinkMetaData(link);
    }
  };

  validateForm = () => {
    const { name, number_of_weeks } = this.state;

    if (!name) {
      return false;
    }

    if (parseInt(number_of_weeks, 10) < 1) {
      return false;
    }
    return true;
  };

  handleClose = () => {
    this.props.getListStudioResource();
    if (this.state.addAnother) {
      let updateState = {
        name: '',
        link: '',
        link_type: this.props.source_type,
        document_size: '',
        document_name: '',
        document_type: '',
        document_url: '',
        isSubmited: false,
      };
      if (this.props.source_type === RESOURCE_TYPES.FORM) {
        updateState = {
          ...updateState,
          cover_image: null,
          form: {},
        };
      }
      this.setState(updateState);
    } else {
      this.props.toggleModal(false);
    }
  };

  handleBack = () => {
    this.props.toggleModal(true, <AddResourcePopup />);
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.isValidForm()) {
      return;
    }
    this.setState({ isSubmited: true });
    const { source_type, showError, hideError } = this.props;
    const {
      name,
      link,
      link_type,
      cover_image,
      document_size,
      document_name,
      document_type,
      document_url,
      _id,
      form,
      author,
      share,
    } = this.state;

    const paramsShare = generateParams({
      ownership: author,
      shareStatus: share,
    });

    let params = {
      name: name.trim(),
      source_type,
      link: link_type.includes('youtube') ? formatShortLink(link) : link,
      link_type,
      cover_image,
      document_size,
      document_name,
      document_type,
      document_url,
      owner: paramsShare.owner,
      share: paramsShare.share,
    };

    // logic auto add https on attachments type link video
    const regex = /^(https:\/\/)$/g;
    if (!isEmpty(get(params, 'link'))) {
      if (!regex.test(get(params, 'link'))) {
        params.link = formatLink(params.link);
      } else {
        params.link = '';
      }
    }

    if (source_type === RESOURCE_TYPES.FORM) {
      params = {
        ...params,
        form: !_.isEmpty(form) && form._id,
      };
    }
    let endPoint = '/api/studio-resource/add';
    if (_id) {
      params.resourceId = _id;
      endPoint = '/api/studio-resource/update';
    }
    this.setState({ loading: true });
    axiosInstance
      .post(endPoint, params)
      .then(response => {
        this.props.updateStudioResourceToRedux(_.get(response, 'data.data'));
        this.handleClose();
        if (this.props.source_type === RESOURCE_TYPES.FORM && this.requestResetValue) {
          this.requestResetValue && this.requestResetValue.current();
        }
        this.setState({ cover_image: '' });
      })
      .catch(error => {
        const responseData = _.get(error, 'response.data', {});
        const message = _.get(responseData, 'message');

        if (responseData.error === 'E_FORBIDDEN' || responseData.error === 'E_BAD_REQUEST') {
          showError(message, null, null, null, null, () => {
            hideError();
            this.handleClose();
          });
          return;
        }
      })
      .finally(() => {
        this.setState({ loading: false, isSubmited: false });
      });
  };

  onRemove = () => {
    const { bucketData } = this.state;
    this.setState({ cover_image: null, bucketData: null, isUploading: false }, () => {
      if (bucketData) {
        removeUploadedFileFromServer(bucketData);
      }
    });
  };

  onRemoveDocument = () => {
    this.setState(
      {
        document_size: '',
        document_name: '',
        document_type: '',
        document_url: '',
        isUploading: false,
      },
      () => {
        // remove convert image after delete document
        if (this.state.cover_image) {
          this.onRemove();
        }
      },
    );
  };

  isValidForm = () => {
    const { source_type } = this.props;
    const { name, document_url, form } = this.state;
    let { link } = this.state;
    link = formatLink(link);

    switch (source_type) {
      case RESOURCE_TYPES.LINK:
        return (!checkLinkFormat(link) && !validateLink(link)) || !name;
      case RESOURCE_TYPES.DOCUMENT:
        return !document_url || !name;
      case RESOURCE_TYPES.FORM:
        return _.isEmpty(form) || !name;
      default:
        return !name;
    }
  };

  onUploadSuccess = ({ src, bucketData, url }) => {
    this.setState({
      cover_image: url.substring(0, url.indexOf('?X-Amz-Algorithm')),
      bucketData,
      isUploadingImage: false,
    });
  };

  onUploadDocumentSuccess = ({ src, bucketData, fileSize }) => {
    this.setState({
      document_size: fileSize,
      document_name: bucketData.originalname,
      document_type: bucketData.file_extension,
      document_url: bucketData.location,
      isUploading: false,
    });
  };

  onStartUpload = () => {
    this.setState({ isUploading: true });
  };

  onStartUpImageUpload = () => {
    this.setState({ isUploadingImage: true });
  };

  onEndUpload = () => {
    this.setState({ isUploading: false });
  };

  onEndImageUpload = () => {
    this.setState({ isUploadingImage: false });
  };

  onOpenLink = () => {
    let { link } = this.state;
    const regex = /^(https|http)(.*)/i;
    if (!regex.test(link)) {
      link = 'http://' + link;
    }
    window.open(link, '_blank');
  };

  handleOnClose = () => {
    this.props.toggleModal(false);
  };

  handleBackButtonToggleModal = () => {
    if (this.props.isModalOpen) {
      this.handleOnClose();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButtonToggleModal, false);
  }

  getLinkMetaData = url => {
    getPageMetaDataFromURL(url).then(response => {
      const {
        data: {
          data: { image },
        },
      } = response;

      const updateState = {
        cover_image: image,
      };
      this.setState(updateState);
    });
  };

  handleSelectForm = form => {
    this.setState({
      form,
    });
  };

  handleChangeOwnershipStatus = ({ author, share }) => {
    this.setState({ author, share });
  };

  renderContent = (source_type, isEdit, isUploading) => {
    switch (source_type) {
      case RESOURCE_TYPES.LINK:
        return (
          <>
            <FormItem
              label="Link"
              placeholder="https://example.com"
              required
              inputProps={{ autoFocus: !isEdit, onBlur: this.handleBlurLink, disabled: !this.isCreatorOrOwnerOrAdmin }}
              isSubmited={this.state.isSubmited}
              value={this.state.link}
              addOnsButton={<OpenLinkIcon onClick={this.onOpenLink} />}
              onChange={this.handleChangeLink}
            />
            {!!this.state.link && (
              <FormItem
                label="Resource Name"
                placeholder="Resource Name"
                required
                isSubmited={this.state.isSubmited}
                value={this.state.name}
                onChange={e => this.handleChangeField('name', e)}
                inputProps={{ disabled: !this.isCreatorOrOwnerOrAdmin }}
              />
            )}
            {!!this.state.link && (
              <>
                <S.Label>Cover Image (optional)</S.Label>
                <UploadCoverImage
                  key="cover_image"
                  className="addStudioResource__coverImageInput"
                  onRemove={this.onRemove}
                  onUploadSuccess={this.onUploadSuccess}
                  onStartUpload={this.onStartUpImageUpload}
                  onEndUpload={this.onEndImageUpload}
                  data={this.state}
                  src={this.state.cover_image}
                  uploadKey="media"
                  hasRemoveIcon={this.isCreatorOrOwnerOrAdmin}
                  source="Resource"
                  hideFileInput={['youtube', 'vimeo'].includes(this.state.link_type)}
                  disabled={!this.isCreatorOrOwnerOrAdmin}
                  maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
                  msgMaxSizeImg={showMsgForSize(LIMIT_SIZE_IMAGE_UPLOAD_MB)}
                >
                  {['youtube', 'vimeo'].includes(this.state.link_type) && (
                    <MediaModal video={this.state.link} validVideo={true} />
                  )}
                </UploadCoverImage>
              </>
            )}
          </>
        );
      case RESOURCE_TYPES.DOCUMENT:
        return (
          <>
            <FormItem
              label="Resource Name"
              placeholder="Resource Name"
              required
              inputProps={{ autoFocus: true, disabled: !this.isCreatorOrOwnerOrAdmin }}
              isSubmited={this.state.isSubmited}
              value={this.state.name}
              onChange={e => this.handleChangeField('name', e)}
            />
            <DocumentUpload
              key="document_file"
              onRemove={this.onRemoveDocument}
              onUploadSuccess={this.onUploadDocumentSuccess}
              onStartUpload={this.onStartUpload}
              onEndUpload={this.onEndUpload}
              data={this.state}
              uploadKey="media"
              disabled={!this.isCreatorOrOwnerOrAdmin}
            />
            {(!!this.state.document_url || isUploading) && (
              <>
                <S.Label>Cover Image (optional)</S.Label>
                <UploadCoverImage
                  key="cover_image"
                  className="addStudioResource__coverImageInput"
                  onRemove={this.onRemove}
                  onUploadSuccess={this.onUploadSuccess}
                  onStartUpload={this.onStartUpImageUpload}
                  onEndUpload={this.onEndImageUpload}
                  data={this.state}
                  src={this.state.cover_image}
                  uploadKey="media"
                  hasRemoveIcon={this.isCreatorOrOwnerOrAdmin}
                  source="Resource"
                  disabled={!this.isCreatorOrOwnerOrAdmin}
                  maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
                  msgMaxSizeImg={showMsgForSize(LIMIT_SIZE_IMAGE_UPLOAD_MB)}
                />
              </>
            )}
          </>
        );
      case RESOURCE_TYPES.FORM:
        return (
          <>
            <FormItem
              label="Resource Name"
              placeholder="Resource Name"
              required
              inputProps={{ autoFocus: true, disabled: !this.isCreatorOrOwnerOrAdmin }}
              isSubmited={this.state.isSubmited}
              value={this.state.name}
              onChange={e => this.handleChangeField('name', e)}
            />
            <FormQuestionnaire
              className="addStudioResource__formWrapper"
              form={this.state.form}
              onSelectForm={this.handleSelectForm}
              toggleSecondModal={this.props.toggleSecondModal}
              getAllForms={this.props.getAllForms}
              disabled={!this.isCreatorOrOwnerOrAdmin}
            />
            <S.Label>Cover Image (optional)</S.Label>
            <UploadCoverImage
              key="cover_image"
              className="addStudioResource__coverImageInput"
              onRemove={this.onRemove}
              onUploadSuccess={this.onUploadSuccess}
              onStartUpload={this.onStartUpImageUpload}
              onEndUpload={this.onEndImageUpload}
              data={this.state}
              src={this.state.cover_image}
              requestResetValue={this.requestResetValue}
              uploadKey="media"
              hasRemoveIcon={this.isCreatorOrOwnerOrAdmin}
              source="Resource"
              disabled={!this.isCreatorOrOwnerOrAdmin}
              maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
              msgMaxSizeImg={showMsgForSize(LIMIT_SIZE_IMAGE_UPLOAD_MB)}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  render() {
    const { source_type, defaultResource, user } = this.props;
    const { link_type, loading, isUploading, isUploadingImage, _id, share } = this.state;
    const isDisabledSubmit =
      !this.isCreatorOrOwnerOrAdmin || isUploading || isUploadingImage || loading || this.isValidForm();
    const isEdit = !!_id;
    const resourceName = _.get(defaultResource, 'name');
    const shouldShowPageTitle = defaultResource && resourceName;
    const author = isEdit ? defaultResource.author_info || {} : user;

    const content = this.renderContent(source_type, isEdit, isUploading);
    const isAvailableChangeOwner = this.isCreatorOrOwnerOrAdmin || !isEdit;

    return (
      <>
        {shouldShowPageTitle && (
          <Helmet>
            <title>{resourceName} - Resources - Everfit</title>
          </Helmet>
        )}
        <S.CreateNewStudioResourceModal
          type={source_type}
          open={true}
          closeOnDimmerClick={false}
          onClose={this.handleOnClose}
          closeIcon={
            <CloseButton className="close-button">
              <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
            </CloseButton>
          }
        >
          <Modal.Header>
            {!isEdit && (
              <img
                onClick={this.handleBack}
                className="addStudioResource__backIcon"
                src={`${CDN_URL}/images/arrow_back_purple_new.svg`}
                alt=""
              />
            )}
            {!isEdit ? (source_type === RESOURCE_TYPES.LINK ? 'Add ' : null) : 'Edit '}
            {_.upperFirst(source_type)}
            <S.ResourceTypeIcon className={link_type} />
            {!isEdit && (
              <S.OwnershipWrapper>
                {isAvailableChangeOwner && (
                  <OwnershipPopupStudio
                    size={28}
                    user={user}
                    owner={author}
                    shareStatus={share}
                    saveChangeOnClose={this.handleChangeOwnershipStatus}
                    updateImmediately={true}
                  />
                )}
              </S.OwnershipWrapper>
            )}
          </Modal.Header>
          <Modal.Content>
            <form className="addStudioResource__form" onSubmit={this.onSubmit}>
              {content}
            </form>
            <div className="addStudioResource__footer">
              {isEdit ? (
                <div />
              ) : (
                <div
                  className="addStudioResource__addAnother"
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({
                      addAnother: !this.state.addAnother,
                    });
                  }}
                >
                  <Checkbox
                    checked={this.state.addAnother}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    onChange={e => {
                      e.stopPropagation();
                      this.setState({
                        addAnother: e.target.checked,
                      });
                    }}
                  />
                  Add Another
                </div>
              )}
              <Button disabled={isDisabledSubmit} onClick={this.onSubmit} purple>
                {this.state._id ? 'Update' : 'Add'}
              </Button>
            </div>
          </Modal.Content>
        </S.CreateNewStudioResourceModal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    isModalOpen: state.isModalOpen,
  };
};

const mapDispatchToProps = {
  toggleModal,
  push,
  getListStudioResource,
  updateStudioResourceToRedux,
  showError,
  hideError,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddResourceFormPopup);
