import styled, { createGlobalStyle } from 'styled-components';

export const GlobalTooltipStyle = createGlobalStyle`
  #tooltip--metric--min {
    width: 228px;
    padding: 15px;

    span {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
    }
  }
`;

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .metric-tooltip {
    &:hover {
      * {
        fill: #716de0;
      }
    }

    * {
      fill: #a6acc6;
    }
  }
`;
