import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import * as S from './style';
import './style.scss';

const SendInvitationSuccess = ({ isOpen, onClose }) => {
  return (
    <Modal closeOnDimmerClick={true} open={isOpen} onClose={onClose} className="modal--bulk-send-invitation">
      <Modal.Content>
        <S.Wrapper>
          <S.Header>
            <S.HeaderIcon />
            <div>Send Invites</div>
          </S.Header>
          <S.Content>
            <p>Your clients will be receiving email invitations shortly.</p>
            <br />
            <p>
              In some cases, the email may enter their spam mailbox, so your clients can check there, if they do not see
              the email.
            </p>
          </S.Content>
          <S.Actions>
            <Button purple onClick={onClose}>
              Got it
            </Button>
          </S.Actions>
        </S.Wrapper>
      </Modal.Content>
    </Modal>
  );
};

export default SendInvitationSuccess;
