import React from 'react';
import classNames from 'classnames';

import { ReactComponent as TrashIcon } from 'assets/icons/trash_gray.svg';

import { CardWrapper } from './style';
import ReactTooltip from 'react-tooltip';

export default function ({ icon, title, children, asset_type, onDeleteAsset, isEdit, disabled, disabledText }) {
  return (
    <CardWrapper isEdit={isEdit}>
      <div
        className={classNames('asset', {
          'asset--disabled': disabled,
        })}
      >
        <div className={classNames('asset__icon', asset_type)}>{icon}</div>
        <div className="asset__info">
          {disabled ? (
            <div className="asset__info__title-container">
              <div className="asset__info__title">{title}</div>
              <div className="delete-text">({disabledText})</div>
            </div>
          ) : (
            <div className="asset__info__title">{title}</div>
          )}

          <div className="asset__info__description">{children}</div>
        </div>
        {isEdit && (
          <div
            className="asset__delete"
            data-tip="Remove Asset"
            data-for="delete-asset-tooltip"
            onClick={onDeleteAsset}
          >
            <TrashIcon />
          </div>
        )}
        <ReactTooltip
          id="delete-asset-tooltip"
          className="delete-tooltip"
          effect="solid"
          place={'left'}
          delayShow={500}
        />
      </div>
    </CardWrapper>
  );
}
