import _ from 'lodash';
import { normalize } from 'normalizr';
import { generateS3DataToPresigned } from 'utils/commonFunction';
import { isS3FileURL } from 'utils/validations';
import { ENTITY_NAME } from 'database/constants';
import { S3PresignedURL } from 'database/schema';
import { axiosInstance } from 'configs/request';

export const Types = {
  ADD_ENTITIES: 'ADD_ENTITIES',
  UPDATE_ENTITY: 'UPDATE_ENTITY',
  DELETE_ENTITY: 'DELETE_ENTITY',
  DELETE_ENTITIES: 'DELETE_ENTITIES',
  UPDATE_ENTITIES: 'UPDATE_ENTITIES',
  SET_ENTITIES: 'SET_ENTITIES',
};

export const addEntities = entities => ({
  type: Types.ADD_ENTITIES,
  payload: entities,
});

export const updateEntity = (entity, id, data, isChanged = true) => {
  return {
    type: Types.UPDATE_ENTITY,
    entity,
    id,
    data,
    isChanged,
  };
};

export const deleteEntity = (entity, id) => ({
  type: Types.DELETE_ENTITY,
  entity,
  id,
});

export const deleteEntities = (entity, ids) => ({
  type: Types.DELETE_ENTITIES,
  entity,
  ids,
});

export const updateEntities = (entities, deleteEntities, hasUpdateFromStandardBuilder = true) => ({
  type: Types.UPDATE_ENTITIES,
  payload: { entities, deleteEntities, hasUpdateFromStandardBuilder },
});

export const setEntities = entities => ({
  type: Types.SET_ENTITIES,
  payload: { entities },
});

export const getS3presignedURLFromLocalDatabase = (list, expireSeconds = 7200) => {
  return async (dispatch, getState) => {
    try {
      const {
        rootReducer: { model },
      } = getState();

      const currentTime = new Date().getTime();

      const parsedData = list.map(url => {
        let shouldGenURL = isS3FileURL(url),
          newURL = url;

        if (
          shouldGenURL &&
          model.getIn([ENTITY_NAME.S3PresignedURL, url]) &&
          new Date(model.getIn([ENTITY_NAME.S3PresignedURL, url]).get('expiredAt')).getTime() > currentTime
        ) {
          newURL = model.getIn([ENTITY_NAME.S3PresignedURL, url]).get('presignedURL');
          shouldGenURL = false;
        }

        return { shouldGenURL, url: newURL };
      });

      const s3URLs = _.filter(parsedData, o => !!o.shouldGenURL).map(o => o.url);

      if (s3URLs.length) {
        const listRequestURLs = _.map(s3URLs, url => generateS3DataToPresigned(url, expireSeconds));
        const response = await axiosInstance.post('/api/file/gen-presigned-urls', { data: listRequestURLs });
        const presignedURLs = response.data.data;

        let counter = 0;

        _.forEach(parsedData, o => {
          if (o.shouldGenURL) {
            o.url = presignedURLs[counter++];
          }
        });

        const expiredAt = new Date();
        expiredAt.setSeconds(expiredAt.getSeconds() + expireSeconds);
        const listObject = s3URLs.map((url, index) => ({ src: url, presignedURL: presignedURLs[index], expiredAt }));
        const normalized = normalize(listObject, [S3PresignedURL]);

        dispatch(addEntities(normalized.entities));
      }

      return _.map(parsedData, 'url');
    } catch (err) {
      return list;
    }
  };
};
