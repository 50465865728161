import React from 'react';

import * as S from './style';
import ResourceMobilePreviewItem from './ResourceMobilePreviewItem';

function MobilePreviewCollection(props) {
  const { section } = props;
  return (
    <S.SectionMobilePreview>
      <S.PreviewTitle>
        <S.ViewName>{section.section_name}</S.ViewName>
        <S.ViewMore>View more</S.ViewMore>
      </S.PreviewTitle>
      {section.resources.map((resource, index) => (
        <>{index < 5 ? <ResourceMobilePreviewItem key={resource._id} resource={resource} /> : null}</>
      ))}
    </S.SectionMobilePreview>
  );
}

export default MobilePreviewCollection;
