import { toast } from 'react-toastify';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import omit from 'lodash/omit';
import queryString from 'query-string';

import Request from 'configs/request';

import { pluralize } from 'utils/commonFunction';

export const Types = {
  GROUP_METRIC_FETCH_REQUEST: 'GROUP_METRIC_FETCH_REQUEST',
  GROUP_METRIC_FETCH_SUCCESS: 'GROUP_METRIC_FETCH_SUCCESS',
  GROUP_METRIC_FETCH_FAILURE: 'GROUP_METRIC_FETCH_FAILURE',
  GROUP_METRIC_SELECTED_ITEM: 'GROUP_METRIC_SELECTED_ITEM',
  GROUP_METRIC_ORGANIZE_ITEM: 'GROUP_METRIC_ORGANIZE_ITEM',
  GROUP_METRIC_CREATE_NEW: 'GROUP_METRIC_CREATE_NEW',
  GROUP_METRIC_REMOVE_REQUEST: 'GROUP_METRIC_REMOVE_REQUEST',
  GROUP_METRIC_REMOVE_SUCCESS: 'GROUP_METRIC_REMOVE_SUCCESS',
  GROUP_METRIC_REMOVE_FAILURE: 'GROUP_METRIC_REMOVE_FAILURE',
  GROUP_METRIC_UPDATE_METRICS_NORMALIZED: 'GROUP_METRIC_UPDATE_METRICS_NORMALIZED',
  GROUP_METRIC_ADD_METRICS_TO_GROUP_REQUEST: 'GROUP_METRIC_ADD_METRICS_TO_GROUP_REQUEST',
  GROUP_METRIC_ADD_METRICS_TO_GROUP_SUCCESS: 'GROUP_METRIC_ADD_METRICS_TO_GROUP_SUCCESS',
  GROUP_METRIC_ADD_METRICS_TO_GROUP_FAILURE: 'GROUP_METRIC_ADD_METRICS_TO_GROUP_FAILURE',
  GROUP_METRIC_ORGANIZE_ITEM_METRIC: 'GROUP_METRIC_ORGANIZE_ITEM_METRIC',
  GROUP_METRIC_RENAME: 'GROUP_METRIC_RENAME',
  MOVE_METRIC_TO_ANOTHER_GROUP_SUCCESS: 'MOVE_METRIC_TO_ANOTHER_GROUP_SUCCESS',
  GROUP_METRIC_SEARCH_BY_NAME_SUCCESS: 'GROUP_METRIC_SEARCH_BY_NAME_SUCCESS',
  GROUP_METRIC_SELECT_GROUP_HAS_METRIC_BY_SEARCH: 'GROUP_METRIC_SELECT_GROUP_HAS_METRIC_BY_SEARCH',
};

export const updateMetricNormalized = (metricsNormalized = {}) => ({
  type: Types.GROUP_METRIC_UPDATE_METRICS_NORMALIZED,
  payload: metricsNormalized,
});

export const selectGroupMetric = (item = {}) => (dispatch, getState) => {
  const {
    rootReducer: {
      client: { selected: clientId },
    },
  } = getState();

  dispatch({
    type: Types.GROUP_METRIC_SELECTED_ITEM,
    payload: item,
  });

  dispatch(getGroupMetrics(clientId));
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const organizeGroupMetric = ({ startIndex, endIndex }) => (dispatch, getState) => {
  const {
    groupMetric: { list = [] } = {},
    rootReducer: {
      client: { selected: clientId },
    },
  } = getState();
  const newOrderList = reorder(list, startIndex, endIndex);
  const group = list[startIndex];

  dispatch({
    type: Types.GROUP_METRIC_ORGANIZE_ITEM,
    payload: newOrderList,
  });

  return dispatch(
    Request.post(
      {
        url: `/api/group-metrics/${group._id}/rearrange`,
        data: { index: endIndex },
      },
      true,
      (response, { dispatch }) => {},
      error => {
        console.error('organizeGroupMetric  error:', error);
        dispatch({ type: Types.GROUP_METRIC_FETCH_FAILURE });
        getGroupMetrics(clientId);
      },
    ),
  );
};

const formatMetricGroups = lisGroup => {
  return lisGroup.reduce(
    (obj, item) => {
      const { metrics, ...groupItem } = item;
      const metricObj = metrics.reduce((obj, mItem) => ({ ...obj, [mItem._id]: mItem }), {});
      const newGroup = {
        ...groupItem,
        metrics: Object.keys(metricObj),
      };
      obj.metricsNormalized = {
        ...obj.metricsNormalized,
        ...metricObj,
      };
      obj.list.push(newGroup);

      return obj;
    },
    { metricsNormalized: {}, list: [] },
  );
};

export const getGroupMetrics = clientId => {
  return (dispatch, getState) => {
    const {
      groupMetric: { selected },
      rootReducer: {
        client: { bodymetricTypes = [] },
      },
      router,
    } = getState();

    dispatch({ type: Types.GROUP_METRIC_FETCH_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/group-metrics`,
          params: { client: clientId },
        },
        false,
        (response, { dispatch }) => {
          const data = formatMetricGroups(get(response, 'data.data', []));
          const selectedG = data.list.find(item => item._id === (selected || {})._id);
          const selectedMetrcis = bodymetricTypes.filter(item => item.selected);
          const currentMetric = selectedMetrcis.reduce((obj, item) => ({ ...obj, [item._id]: item }), {});
          const parsed = queryString.parse(router.location.search);
          let finalSelectedGroup = selectedG || data.list[0];
          const finalMetrics = { ...currentMetric, ...data.metricsNormalized };

          if (parsed.unique_code) {
            const isExist = finalSelectedGroup.metrics.find(id => {
              return finalMetrics[id].unique_code === parsed.unique_code;
            });

            if (!isExist) {
              const groupHaveMetric = data.list.find(gItem => {
                return gItem.metrics.some(id => finalMetrics[id].unique_code === parsed.unique_code);
              });
              if (groupHaveMetric) {
                finalSelectedGroup = groupHaveMetric;
              }
            }
          }

          if (data) {
            dispatch({
              type: Types.GROUP_METRIC_FETCH_SUCCESS,
              payload: {
                list: data.list,
                selected: finalSelectedGroup,
                metrics: finalMetrics,
              },
            });
          }
        },
        error => {
          dispatch({ type: Types.GROUP_METRIC_FETCH_FAILURE });
        },
      ),
    );
  };
};

export const organizeItemMetric = ({ startIndex, endIndex }) => (dispatch, getState) => {
  const { groupMetric: { list = [], selected = {} } = {} } = getState();

  const newSelected = {
    ...selected,
    metrics: reorder(selected.metrics, startIndex, endIndex),
  };

  const newList = list.map(it => {
    if (it._id === selected._id) {
      return newSelected;
    }
    return it;
  });

  return dispatch({
    type: Types.GROUP_METRIC_ORGANIZE_ITEM_METRIC,
    payload: {
      list: newList,
      selected: newSelected,
    },
  });
};

export const rearrangeMetricInGroup = param => {
  return dispatch => {
    dispatch(organizeItemMetric({ startIndex: param.startIndex, endIndex: param.endIndex }));
    return dispatch(
      Request.post(
        {
          url: `/api/group-metrics/${param.group_id}/rearrange-metric`,
          params: { metric: param.metric_id, index: param.endIndex },
        },
        false,
      ),
    );
  };
};

export const addNewMetricGroup = clientId => {
  return (dispatch, getState) => {
    const {
      groupMetric: { list: groups },
    } = getState();

    return dispatch(
      Request.post(
        {
          url: `/api/group-metrics`,
          data: { client: clientId },
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          const newGroups = [...groups, data];

          if (data) {
            dispatch({
              type: Types.GROUP_METRIC_CREATE_NEW,
              payload: {
                list: newGroups,
                selected: data,
                total: newGroups.length,
              },
            });
          }
        },
      ),
    );
  };
};

export const editMetricGroup = params => {
  return dispatch => {
    dispatch(changeGroupName({ groupId: params.groupId, title: params.title || 'New Group' }));

    return dispatch(
      Request.patch(
        {
          url: `/api/group-metrics/${params.groupId}`,
          data: { title: params.title || 'New Group' },
        },
        true,
      ),
    );
  };
};

export const addMultipleMetricToGroup = params => {
  const groupId = (params || {}).groupId;
  const newParams = omit(params, ['groupId']);

  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: `/api/group-metrics/add-metrics`,
          data: newParams,
        },
        true,
        (response, { dispatch }) => {
          const metrics = get(response, 'data.data.metrics');
          dispatch(getGroupMetrics(params.client));
          if (groupId) {
            dispatch(addMetricsToGroup({ groupId, metricIds: metrics }));
            toast(`${metrics.length} ${pluralize('metric', metrics.length)} has been added.`);
          }
        },
      ),
    );
  };
};

export const removeGroupMetric = data => (dispatch, getState) => {
  const { groupId, selectedNewGroups = {}, metrics = [] } = data || {};

  const {
    groupMetric: { list = [] } = {},
    rootReducer: {
      client: { selected: clientId },
    },
  } = getState();
  const groupDeleted = list.find(item => item._id === groupId);

  if (!groupDeleted) return;

  dispatch({ type: Types.GROUP_METRIC_REMOVE_REQUEST });

  // remove group
  return dispatch(
    Request.delete(
      { url: `/api/group-metrics/${groupId}` },
      true,
      (response, { dispatch }) => {
        // move metrics to new group or create new group for metric not choose group
        const selectedMetrics = flatten(Object.values(selectedNewGroups));
        const metricsToNewGroup = difference(metrics, selectedMetrics);
        const payload = {
          client: clientId,
          data: Object.keys(selectedNewGroups).map(id => ({
            group: id,
            metrics: selectedNewGroups[id],
          })),
        };

        if (!isEmpty(metricsToNewGroup)) {
          payload.data.push({
            group: null,
            metrics: metricsToNewGroup,
          });
        }

        const remainingGroups = list.filter(item => item._id !== groupId);
        dispatch({
          type: Types.GROUP_METRIC_REMOVE_SUCCESS,
          payload: { list: remainingGroups },
        });

        payload.data = payload.data.filter(item => item.metrics.length > 0);
        if (payload.data.length === 0) {
          dispatch(getGroupMetrics(clientId));
          return;
        }

        return dispatch(
          Request.post(
            {
              url: `/api/group-metrics/add-metrics`,
              data: payload,
            },
            true,
            (response, { dispatch }) => {
              dispatch(getGroupMetrics(clientId));
            },
          ),
        );
      },
      error => {
        console.error('removeGroupMetric  error:', error);
        getGroupMetrics(clientId);
      },
    ),
  );
};

export const addMetricsToGroup = ({ groupId, metricIds = [] } = {}) => (dispatch, getState) => {
  if (!groupId) return;
  dispatch({ type: Types.GROUP_METRIC_ADD_METRICS_TO_GROUP_REQUEST });

  const { groupMetric: { list = [], selected = {} } = {} } = getState();
  const newListGroupMetric = list.map(it => {
    if (it._id === groupId) {
      return { ...it, metrics: uniq([...it.metrics, ...metricIds]) };
    }
    return it;
  });

  const newSelected = { ...selected };
  if (newSelected._id === groupId) {
    newSelected.metrics = uniq([...newSelected.metrics, ...metricIds]);
  }

  return dispatch({
    type: Types.GROUP_METRIC_ADD_METRICS_TO_GROUP_SUCCESS,
    payload: {
      list: newListGroupMetric,
      selected: newSelected,
    },
  });
};

export const changeGroupName = ({ groupId, title }) => (dispatch, getState) => {
  if (!groupId) return;
  const { groupMetric: { list = [], selected = {} } = {} } = getState();

  const newListGroupMetric = list.slice().map(it => {
    if (it._id === groupId) {
      it.title = title;
    }
    return it;
  });
  const newSelected = { ...selected, title };

  return dispatch({
    type: Types.GROUP_METRIC_RENAME,
    payload: {
      list: newListGroupMetric,
      selected: newSelected,
    },
  });
};

export const removeMetricInGroup = data => (dispatch, getState) => {
  const { groupId, metricId } = data;
  const { groupMetric: { list = [], selected } = {} } = getState();

  return dispatch(
    Request.delete(
      {
        url: `/api/group-metrics/${groupId}/metric/${metricId}`,
      },
      true,
      (response, { dispatch }) => {
        const newListGroupMetric = list.map(it => {
          if (it._id === groupId) {
            return {
              ...it,
              metrics: it.metrics.filter(i => i !== metricId),
            };
          }
          return it;
        });
        let newSelected = selected;
        if (selected) {
          newSelected = newListGroupMetric.find(item => item._id === selected._id) || selected;
        }

        return dispatch({
          type: Types.MOVE_METRIC_TO_ANOTHER_GROUP_SUCCESS,
          payload: {
            list: newListGroupMetric,
            selected: newSelected,
          },
        });
      },
    ),
  );
};

export const saveGroupToLibrary = groupId => dispatch => {
  return dispatch(
    Request.post(
      {
        url: `/api/group-metrics/${groupId}/save-to-library`,
      },
      true,
      () => {
        toast(`Metric group has been saved to library.`);
      },
    ),
  );
};

export const searchGroupMetricsByName = textSearch => {
  return (dispatch, getState) => {
    dispatch({
      type: Types.GROUP_METRIC_SEARCH_BY_NAME_SUCCESS,
      payload: {
        textSearch,
      },
    });
  };
};

export const selectGroupHasMetricBySearch = groupId => {
  return dispatch => {
    dispatch({
      type: Types.GROUP_METRIC_SELECT_GROUP_HAS_METRIC_BY_SEARCH,
      payload: {
        groupId,
      },
    });
  };
};
