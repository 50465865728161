import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import './styles.scss';
import _ from 'lodash';
import { classToPlain } from 'class-transformer';
import diff from 'deep-diff';

import {
  TEAM_SHARE_PRIVATE,
  TEAM_SHARE_NOOWNER,
  TAGS_TYPE,
  EXERCISE_LIMIT_CHARACTER_NOTE,
  CDN_URL,
} from 'constants/commonData';
import OwnershipPopup from 'components/OwnershipPopup';
import MediaSide from './MediaSide';
import { removeUploadedFileFromServer } from 'utils/commonRequest';
import * as S from './style';
import { DropdownIndicator } from 'shared/Icons';
import { convertExerciseToAPIFormat, initBaseExerciseData } from 'helpers/exercise';
import ExerciseFields from './Fields';
import { Button, ErrorMessage } from 'shared/FormControl';
import CategoryOption from './CategoryOption';
import TextEditable from 'shared/TextEditable';
import TagsFilter, { MODE } from 'shared/TagsFilter';
import InstructionInput from './InstructionInput';
import { isTeamAdmin } from 'utils/commonFunction';
import AdvancedSetting from './AdvancedSetting';
import SearchExercise from 'shared/SearchExercise';
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';

import CustomModalitySingleValue from './components/CustomModalitySingleValue';
import MuscleGroup from './components/MuscleGroup';
import MovementPattern from './components/MovementPattern';
import Equipment from './components/Equipment';
import SortableList from './components/SortableList';
import Synonyms from './components/Synonyms';

const DEFAULT_MEDIA_DATA = {
  video: '',
  videoLink: '',
  picture: [],
  preview_300: '',
  thumbnail_url: '',
  canSave: true,
};

export const MODES = {
  CREATE: 'create_exercise',
  CLONE: 'clone_exercise',
  UPDATE: 'update_exercise',
  SAVE_A_COPY: 'save_a_copy',
};

export default class ExerciseForm extends React.Component {
  constructor(props) {
    super(props);
    const mode = this.getMode(props.mode);
    this.state = {
      exercise: this.getDefaultExercise(props.originExercise, mode),
      originExercise: this.getOriginExercise(props.originExercise, mode),
      isAddingLink: false,
      mediaData: { ...DEFAULT_MEDIA_DATA },
      submitting: false,
      showError: false,
      mode,
      menuPortalTarget: null,
    };

    this.originalMediaData = { ...DEFAULT_MEDIA_DATA };
    this.temporaryMediaFiles = [];
    this.exerciseNameRef = React.createRef();
    this.linkRef = React.createRef();
    this.headerRef = React.createRef();
    this.leftContentRef = React.createRef();
    this.isHiddenMuscleGroupOption = this.checkHiddenMuscleGroupOption();
  }

  componentDidMount() {
    const { originExercise } = this.props;

    if (originExercise) {
      const mediaData = {
        ...this.state.mediaData,
        video: originExercise.video || '',
        videoLink: originExercise.videoLink || '',
        picture: originExercise.picture || [],
        preview_300: originExercise.preview_300 || '',
        thumbnail_url: originExercise.thumbnail_url || '',
      };
      this.originalMediaData = { ...mediaData };
      this.setState({ mediaData, isAddingLink: !!originExercise.link });
    } else {
      setTimeout(() => {
        if (this.exerciseNameRef.current) {
          this.exerciseNameRef.current.focus();
        }
      }, 0);
    }
    const modalElements = document.querySelectorAll('.ui.page.modals.dimmer');
    const target = modalElements.length > 0 ? modalElements[modalElements.length - 1] : null;
    this.setState({ menuPortalTarget: target });
    this.retryFetchCategories();
  }

  componentWillUnmount() {
    if (this.temporaryMediaFiles.length) {
      _.forEach(this.temporaryMediaFiles, bucketData => {
        if (bucketData) {
          removeUploadedFileFromServer(bucketData);
        }
      });
    }
  }

  checkHiddenMuscleGroupOption = () => {
    const { muscleGroup = [], originExercise } = this.props;

    if (_.get(originExercise, 'muscle_groups')) {
      const selectedMuscleGroups = originExercise.muscle_groups.map(it => it.muscle_group);

      if (!!selectedMuscleGroups.length) {
        const hiddenOptionIds = [];
        muscleGroup.forEach(it => {
          if (it.is_hidden) {
            hiddenOptionIds.push(it._id);
          }
        });

        if (!!hiddenOptionIds.length && selectedMuscleGroups.some(it => hiddenOptionIds.includes(it._id))) {
          return false;
        }
      }
    }

    return true;
  };

  retryFetchCategories = () => {
    const { categories, getAllCategories } = this.props;
    if (categories.length <= 0 && getAllCategories) {
      getAllCategories();
    }
  };

  getMode = originalMode => {
    const { user, originExercise } = this.props;
    const saveACopy = !isTeamAdmin(user) && user._id !== _.get(originExercise, 'author._id');

    if (originalMode === MODES.UPDATE && originExercise.custom && saveACopy) {
      return MODES.SAVE_A_COPY;
    }

    return originalMode;
  };

  getDefaultExercise = (exercise, mode) => {
    const { firstTitle, user, modalities } = this.props;

    if (!exercise) {
      const defaultModality = modalities.find(it => it.unique_code === '-');
      return initBaseExerciseData(user._id, firstTitle, defaultModality);
    }

    const workingExercise = _.cloneDeep(exercise);

    if (mode === MODES.CLONE) {
      workingExercise.title = '';
    }

    if (mode === MODES.CLONE || mode === MODES.SAVE_A_COPY) {
      workingExercise.author = { _id: user._id };
      workingExercise.share = TEAM_SHARE_PRIVATE;
    }

    if (!_.get(workingExercise, 'author')) {
      workingExercise.author = {};
    }

    if (_.get(workingExercise, 'muscle_groups')) {
      workingExercise.muscle_groups = workingExercise.muscle_groups.map(it => it.muscle_group);
    }

    if (_.get(workingExercise, 'movement_patterns')) {
      workingExercise.movement_patterns = workingExercise.movement_patterns.map(it => it.movement_pattern);
    }

    return workingExercise;
  };

  getOriginExercise = (exercise, mode) => {
    const { user, modalities } = this.props;

    if (!exercise) {
      const defaultModality = modalities.find(it => it.unique_code === '-');
      return initBaseExerciseData(user._id, '', defaultModality);
    }

    const originExercise = _.cloneDeep(exercise);

    if (mode === MODES.CLONE || mode === MODES.SAVE_A_COPY) {
      originExercise.author = { _id: user._id };
      originExercise.share = TEAM_SHARE_PRIVATE;
    }

    if (!_.get(originExercise, 'author')) {
      originExercise.author = {};
    }

    if (_.get(originExercise, 'muscle_groups')) {
      originExercise.muscle_groups = originExercise.muscle_groups.map(it => it.muscle_group);
    }

    if (_.get(originExercise, 'movement_patterns')) {
      originExercise.movement_patterns = originExercise.movement_patterns.map(it => it.movement_pattern);
    }

    return originExercise;
  };

  checkMediaChange = () => {
    const { mediaData } = this.state;
    const { originalMediaData } = this;

    return (
      originalMediaData.video !== mediaData.video ||
      originalMediaData.videoLink !== mediaData.videoLink ||
      !_.isEqual(mediaData.picture, originalMediaData.picture)
    );
  };

  handleCloseAction = () => {
    const { originExercise, exercise, mode } = this.state;
    const origin = classToPlain(originExercise);
    const current = classToPlain(exercise);

    if (mode === MODES.CLONE || !origin.title) {
      origin.title = '';
    }

    if (!current.title) {
      current.title = '';
    }

    const changes = diff(origin, current);
    const isMediaChange = this.checkMediaChange();

    if (changes || isMediaChange) {
      this.props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={this.props.closePopup}
          onDeny={typeof this.props.onDenyClosingPopup === 'function' ? this.props.onDenyClosingPopup : () => {}}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
        />,
      );
    } else {
      this.props.closePopup();
    }
  };

  handleChangeOwner = (event, data) => {
    let { exercise } = this.state;

    if (!exercise.author) {
      exercise.author = {};
    }

    exercise.author._id = data.value;

    if (data.value == 'no_owner') {
      exercise.author._id = null;
      exercise.share = TEAM_SHARE_NOOWNER;
    } else if (exercise.share === TEAM_SHARE_NOOWNER) {
      exercise.share = TEAM_SHARE_PRIVATE;
    }

    this.setState({ exercise });
  };

  handleChangeShare = (event, data) => {
    let { exercise } = this.state;
    exercise.share = data.value;
    this.setState(prevState => ({
      exercise,
    }));
  };

  checkValidation = () => {
    const { exercise } = this.state;

    if (!exercise.title || !exercise.title.trim()) {
      this.setState({ showError: true, exercise: { ...exercise, title: '' } });
      return false;
    }

    // if (!exercise.body_part) {
    //   this.setState({ showError: true });
    //   return false;
    // }

    // if (!exercise.modality) {
    //   this.setState({ showError: true });
    //   return false;
    // }

    // if (_.isEmpty(exercise.muscle_groups)) {
    //   this.setState({ showError: true });
    //   return false;
    // }

    if (!exercise.modality && _.isEmpty(exercise.muscle_groups)) {
      this.setState({ showError: true });
      return false;
    }

    if (!exercise.category_type) {
      this.setState({ showError: true });
      return false;
    }

    return true;
  };

  save = close => {
    const { mediaData, submitting, mode } = this.state;
    const { categories, user } = this.props;

    if (!mediaData.canSave || submitting) {
      return false;
    }

    const isValid = this.checkValidation();

    if (!isValid) {
      return false;
    }

    let exercise = _.cloneDeep(this.state.exercise);
    let authorObject = user.team_member.find(item => item._id === exercise.author._id);

    if (authorObject) {
      exercise.author_name = `${authorObject.first_name} ${authorObject.last_name}`;
    } else {
      exercise.author_name = '';
    }
    if (!_.isEmpty(exercise.synonyms)) {
      const capitalizedStrings = exercise.synonyms.map(str => {
        const [firstWord, ...restWords] = str.split(' ');
        return [firstWord.charAt(0).toUpperCase() + firstWord.slice(1), ...restWords].join(' ');
      });
      exercise.synonyms = capitalizedStrings;
    }

    exercise.link = _.get(this.linkRef, 'current.value', '');
    exercise.category_type_name = categories.find(item => item._id === exercise.category_type).title;
    exercise.video = mediaData.video;
    exercise.videoLink = mediaData.videoLink;
    exercise.picture = mediaData.picture;

    // if (mode !== MODES.CREATE) {
    exercise.preview_300 = mediaData.preview_300;
    exercise.thumbnail_url = mediaData.thumbnail_url;
    // }

    if (mediaData.video_attachment_id) {
      exercise.attachment_id = mediaData.video_attachment_id;
    } else {
      delete exercise.attachment_id;
    }

    this.temporaryMediaFiles = [];
    this.doSave(exercise, close);
  };

  doSave = (exercise, close) => {
    const { updateExercise, addExercise } = this.props;

    const {
      mode,
      mediaData: { videoType },
    } = this.state;
    const params = convertExerciseToAPIFormat(exercise);
    let action;

    if (mode === MODES.UPDATE) {
      action = updateExercise;
    } else {
      action = addExercise;
    }
    if (videoType) {
      params.file_extension = videoType;
    }

    this.setState({ submitting: true });
    action(params)
      .then(response => {
        const { data, exercise } = response.data;
        const newExercise = mode === MODES.UPDATE ? data : exercise;
        toast('Exercise has been saved');

        if (!close) {
          this.originalMediaData = { ...this.state.mediaData };
          this.setState(
            {
              mode: MODES.UPDATE,
              exercise: this.getDefaultExercise(newExercise, MODES.UPDATE),
              originExercise: this.getOriginExercise(newExercise, MODES.UPDATE),
              submitting: false,
            },
            () => {
              if (typeof this.props.onSubmitSuccess === 'function') {
                this.props.onSubmitSuccess({ exercise: newExercise, close });
              }
            },
          );
        } else {
          if (typeof this.props.onSubmitSuccess === 'function') {
            this.props.onSubmitSuccess({ exercise: newExercise, close });
          }
        }
      })
      .catch(error => {
        if (!close || !!error) {
          this.setState({ submitting: false });
        }
      });
  };

  saveWithoutClose = () => {
    this.save(false);
  };

  saveAndClose = () => {
    this.save(true);
  };

  onChangeExerciseName = (evt, data) => {
    const { exercise } = this.state;
    this.setState({
      exercise: { ...exercise, title: data.value },
    });
  };

  onUpdateMediaData = ({ videoData, photoData }) => {
    let canSave = true;
    const newTempMedias = [];

    newTempMedias.push(videoData.bucketData);

    _.forEach(photoData.list, item => {
      const data = _.get(item, 'bucketData');

      if (data) {
        newTempMedias.push(data);
      }
    });

    this.temporaryMediaFiles = newTempMedias;

    if (videoData.file || (videoData.usingLink && videoData.invalidLink)) {
      canSave = false;
    } else {
      const uploadingPhoto = _.find(photoData.list, item => !!item && !!item.file);

      if (uploadingPhoto) {
        canSave = false;
      }
    }

    const newMediaData = { ...this.state.mediaData, canSave };

    if (videoData.usingLink) {
      newMediaData.video = '';
      newMediaData.videoLink = videoData.src;
    } else {
      newMediaData.video = videoData.apiURL;
      newMediaData.videoLink = '';
      newMediaData.videoType = videoData.videoType;
      newMediaData.preview_300 = videoData.preview_300;
      newMediaData.thumbnail_url = videoData.thumbnail || videoData.preview_300;
    }

    const attachmentId = _.get(videoData, 'bucketData.attachment_id');

    if ((!newMediaData.video && !newMediaData.videoLink) || attachmentId) {
      newMediaData.preview_300 = '';
      newMediaData.thumbnail_url = '';
    }

    if (attachmentId) {
      newMediaData.video_attachment_id = attachmentId;
    } else {
      delete newMediaData.video_attachment_id;
    }

    const photos = _.filter(photoData.list, item => !!item && !!item.apiURL);
    newMediaData.picture = _.map(photos, 'apiURL');
    this.setState({ mediaData: newMediaData });
  };

  onSelectPrimaryFocus = data => {
    const { exercise } = this.state;
    this.setState({ exercise: { ...exercise, body_part: data.value } });
  };

  onSelectModality = option => {
    this.setState(prevState => ({ exercise: { ...prevState.exercise, modality: option } }));
  };

  onSelectMuscleGroup = options => {
    this.setState(prevState => ({ exercise: { ...prevState.exercise, muscle_groups: options } }));
  };

  onSelectMovementPattern = options => {
    this.setState(prevState => ({ exercise: { ...prevState.exercise, movement_patterns: options } }));
  };

  renderPrimaryFocus = () => {
    const { body_parts } = this.props;
    const { exercise, showError } = this.state;
    const options = _.map(body_parts, i => ({ key: i, value: i, label: i }));
    const value = exercise.body_part
      ? { key: exercise.body_part, value: exercise.body_part, label: exercise.body_part }
      : null;

    return (
      <S.FormGroup className={classNames({ error: showError && !exercise.body_part })}>
        <label>Primary Focus</label>
        <div>
          <Select
            key="primary-focus"
            options={options}
            components={{ DropdownIndicator }}
            classNamePrefix="single-select"
            className="single-select-container"
            placeholder="Choose primary focus"
            onChange={this.onSelectPrimaryFocus}
            noOptionsMessage={() => 'No result'}
            value={value}
          />
        </div>
        <ErrorMessage>Please select primary focus</ErrorMessage>
      </S.FormGroup>
    );
  };

  renderModality = () => {
    const { modalities = [] } = this.props;
    const { exercise, showError } = this.state;

    const filterOption = (option = {}, inputValue = '') => {
      const { label = '' } = option;
      return label.toLowerCase().includes(inputValue.toLowerCase());
    };

    const isError = showError && _.isEmpty(exercise.modality) && _.isEmpty(exercise.muscle_groups);

    return (
      <S.FormGroup className={classNames('modality', { error: isError })}>
        <label>Primary Focus</label>
        <Select
          key="modalities"
          options={modalities}
          getOptionLabel={option => option.title}
          getOptionValue={option => option._id}
          classNamePrefix="single-select"
          className="single-select-container"
          placeholder="Select modality"
          onChange={this.onSelectModality}
          filterOption={filterOption}
          noOptionsMessage={() => 'No results.'}
          value={exercise.modality}
          components={{
            DropdownIndicator,
            SingleValue: CustomModalitySingleValue,
          }}
          styles={{
            control: (baseStyle, state) => ({
              ...baseStyle,
              padding: '0px !important',
            }),
            valueContainer: (baseStyle, state) => ({
              ...baseStyle,
              padding: '2px 12px !important',
            }),
            placeholder: (baseStyle, state) => ({
              ...baseStyle,
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '13px',
              lineHeight: '18px',
              color: '#323c47',
              opacity: '1',
            }),
            dropdownIndicator: (baseStyle, state) => ({
              ...baseStyle,
              paddingRight: '15px',
              paddingTop: '9px',
            }),
            noOptionsMessage: (baseStyle, state) => ({
              ...baseStyle,
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '13px',
              lineHeight: '18px',
              color: '#000000',
            }),
          }}
        />
        <ErrorMessage>Please select modality</ErrorMessage>
      </S.FormGroup>
    );
  };

  renderMuscleGroup = () => {
    const { muscleGroup = [] } = this.props;
    const { exercise: { muscle_groups = [], modality = [] } = {}, showError } = this.state;
    const isError = showError && _.isEmpty(modality) && _.isEmpty(muscle_groups);

    return (
      <MuscleGroup
        selectedOptions={muscle_groups}
        options={muscleGroup}
        onChange={this.onSelectMuscleGroup}
        showError={isError}
        isHiddenMuscleGroupOption={this.isHiddenMuscleGroupOption}
      />
    );
  };

  renderMovementPattern = () => {
    const { movementPattern = [] } = this.props;
    const { exercise: { movement_patterns = [] } = {} } = this.state;

    return (
      <MovementPattern
        selectedOptions={movement_patterns}
        options={movementPattern}
        onChange={this.onSelectMovementPattern}
      />
    );
  };

  onSelectCategory = data => {
    const { exercise } = this.state;

    if (data.value !== exercise.category_type) {
      const { fields } = this.props;
      const newFields = _.map(data.fields || [], code => {
        return (_.find(fields, fieldObj => fieldObj.unique_code === code) || {})._id;
      });
      this.setState({ exercise: { ...exercise, category_type: data.value, fields: newFields } });
    }
  };

  onUpdateFields = fields => {
    this.setState({ exercise: { ...this.state.exercise, fields } });
  };

  renderTrackingFields = () => {
    const { exercise, showError } = this.state;
    const { categories } = this.props;

    const options = _.map(categories, category => {
      const fields = _.filter(category.fields, code => code !== 'rest');
      const text = _.map(fields, code => {
        return (_.find(this.props.fields, obj => obj.unique_code === code) || {}).title;
      });

      return {
        key: category._id,
        value: category._id,
        label: category.title,
        ...category,
        description: text.join(' x '),
      };
    });
    const tmp = exercise.category_type ? _.find(options, o => o.value === exercise.category_type) : null;
    const value = tmp ? { key: tmp._id, value: tmp._id, label: tmp.title } : null;

    return (
      <S.FormGroup className={classNames('tracking-category', { error: showError && !exercise.category_type })}>
        <label>Category</label>
        <S.TrackingFieldContainer>
          <S.SelectCategoryContainer>
            <Select
              key="category"
              options={options}
              components={{ DropdownIndicator, Option: CategoryOption }}
              classNamePrefix="single-select"
              className="single-select-container category"
              placeholder="Select exercise category"
              onChange={this.onSelectCategory}
              noOptionsMessage={() => 'No result'}
              value={value}
            />
          </S.SelectCategoryContainer>
          <ErrorMessage>Please select category</ErrorMessage>
          {exercise.category_type && (
            <S.FormGroup>
              <S.TrackingFieldsLabel>
                <span>Tracking Fields</span>
                <S.QuestionPlaceHolder data-tip data-for="exercise-fields-guide" />
                <ReactTooltip id="exercise-fields-guide" effect="solid" place-="top" className="app-tooltip">
                  <S.FieldGuideContent>
                    Choose up to 3 fields used for assigning the exercise. Drag and drop to rearrange the order of the
                    fields.
                  </S.FieldGuideContent>
                </ReactTooltip>
              </S.TrackingFieldsLabel>
              <ExerciseFields
                fields={exercise.fields}
                systemFields={this.props.fields}
                onChange={this.onUpdateFields}
              />
            </S.FormGroup>
          )}
        </S.TrackingFieldContainer>
      </S.FormGroup>
    );
  };

  onInstructionsChange = value => {
    const { exercise } = this.state;
    this.setState({ exercise: { ...exercise, instructions: value } });
  };

  renderInstructions = () => {
    const { exercise } = this.state;
    const instructions = _.filter(exercise.instructions || [], item => !!item);

    return (
      <S.InstructionGroup>
        <label>INSTRUCTIONS (Separate each step on a new line)</label>
        <InstructionInput value={instructions} onChange={this.onInstructionsChange} />
      </S.InstructionGroup>
    );
  };

  handleUpdateSynonyms = updatedSynonym => {
    const { exercise } = this.state;
    const updatedExercise = {
      ...exercise,
      synonyms: updatedSynonym,
    };
    this.setState({ exercise: updatedExercise });
  };

  renderSynonyms = () => {
    const { exercise: { synonyms = [] } = {} } = this.state;

    return (
      <S.InstructionGroup>
        <S.AdvancedTitleWrapper>
          <label>SYNONYMS</label>
          <S.InfoIconWrapper data-tip data-for="tooltip--synonyms">
            <InfoIcon />
          </S.InfoIconWrapper>
          <ReactTooltip id="tooltip--synonyms" effect="solid" place-="top" className="app-tooltip tooltip--synonyms">
            Only for searching purpose
          </ReactTooltip>
        </S.AdvancedTitleWrapper>
        <Synonyms synonyms={synonyms} handleUpdateSynonyms={this.handleUpdateSynonyms} />
      </S.InstructionGroup>
    );
  };

  onAddLinkClick = () => {
    const { isAddingLink, exercise } = this.state;
    const newValue = !isAddingLink;
    this.setState({ isAddingLink: newValue, exercise: { ...exercise, link: '' } });
  };

  onLinkChange = event => {
    const { value } = event.target;
    this.setState({ exercise: { ...this.state.exercise, link: value } });
  };

  renderExerciseLink = () => {
    const { exercise, isAddingLink } = this.state;

    return (
      <S.FormGroup className={classNames('add-link', { expanded: isAddingLink })}>
        <S.LinkButton adding={isAddingLink} onClick={this.onAddLinkClick}>
          <S.AddLinkIcon />
          <span>{isAddingLink ? 'Delete' : 'Add'} Link</span>
        </S.LinkButton>
        {!!isAddingLink && (
          <input
            defaultValue={exercise.link || ''}
            placeholder="Add link"
            ref={this.linkRef}
            autoFocus={!!isAddingLink && !exercise.link}
            onChange={this.onLinkChange}
          />
        )}
      </S.FormGroup>
    );
  };

  handleSelectEquipment = selectedOption => {
    this.setState(prevState => ({ exercise: { ...prevState.exercise, equipment: selectedOption } }));
  };

  renderEquipment = () => {
    const { equipments = [] } = this.props;
    const { exercise: { equipment } = {} } = this.state;

    return (
      <S.FormGroup>
        <label>Equipment</label>
        <Equipment selectedOption={equipment} options={equipments} onChange={this.handleSelectEquipment} />
      </S.FormGroup>
    );
  };

  onNoteChange = (value = '') => {
    const { exercise: { note = '' } = {} } = this.state;

    if (note.length === EXERCISE_LIMIT_CHARACTER_NOTE && value.length > EXERCISE_LIMIT_CHARACTER_NOTE) {
      return;
    }

    this.setState(prevState => ({
      exercise: { ...prevState.exercise, note: value.substring(0, EXERCISE_LIMIT_CHARACTER_NOTE) },
    }));
  };

  renderDefaultNote = () => {
    const { exercise } = this.state;

    return (
      <S.FormGroup className="default-note">
        <S.AdvancedTitleWrapper>
          <label>DEFAULT NOTE</label>
          <S.InfoIconWrapper data-tip data-for="tooltip--default-note">
            <InfoIcon />
          </S.InfoIconWrapper>
          <ReactTooltip
            id="tooltip--default-note"
            effect="solid"
            place-="top"
            className="app-tooltip tooltip--default-note"
          >
            Note will show up whenever <br /> the exercise is assigned in a workout
          </ReactTooltip>
        </S.AdvancedTitleWrapper>
        <TextEditable
          value={exercise.note}
          onChange={this.onNoteChange}
          placeholder="Add default note for this exercise"
          lineHeight={19.5}
          breakLine
          maxLength={EXERCISE_LIMIT_CHARACTER_NOTE}
        />
      </S.FormGroup>
    );
  };

  onSelectAlternateExercises = data => {
    const { originExercise } = this.props;
    const { exercise: { alternatives = [] } = {}, mode } = this.state;

    if (mode === MODES.UPDATE && !_.isEmpty(originExercise) && data._id === originExercise._id) {
      return toast.error('Cannot add same alternative exercise with the original exercise');
    }

    if (_.find(alternatives, item => item._id === data._id)) {
      return false;
    }

    const newExercises = alternatives.slice();

    newExercises.push(data);

    this.setState(prevState => ({ exercise: { ...prevState.exercise, alternatives: newExercises } }));
  };

  removeExercise = index => {
    const { exercise: { alternatives = [] } = {} } = this.state;

    const newExercises = alternatives.slice();
    newExercises.splice(index, 1);

    this.setState(prevState => ({ exercise: { ...prevState.exercise, alternatives: newExercises } }));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { exercise: { alternatives = [] } = {} } = this.state;
    const newExercises = alternatives.slice();
    const removed = newExercises.splice(oldIndex, 1);
    newExercises.splice(newIndex, 0, removed[0]);

    this.setState(prevState => ({ exercise: { ...prevState.exercise, alternatives: newExercises } }));
  };

  renderAlternateExercises = () => {
    const { exercise: { alternatives = [] } = {} } = this.state;
    const { mostRecents = [] } = this.props;
    const alternativeIds = alternatives.map(it => it._id);

    return (
      <>
        <S.FormGroup className="alternate-exercises">
          <S.AdvancedTitleWrapper>
            <label>Default Alternate Exercises</label>
            <S.InfoIconWrapper data-tip data-for="tooltip--alternate-exercises">
              <InfoIcon />
            </S.InfoIconWrapper>
            <ReactTooltip
              id="tooltip--alternate-exercises"
              effect="solid"
              place-="top"
              className="app-tooltip tooltip--alternate-exercises"
            >
              Exercises will show up as alternate exercises whenever the exercise is assigned in a workout
            </ReactTooltip>
          </S.AdvancedTitleWrapper>
        </S.FormGroup>
        <S.AlternateExercisesWrapperContent>
          {!_.isEmpty(mostRecents) && (
            <SearchExercise
              defaultOptions={mostRecents.slice()}
              defaultValue={null}
              onSelect={this.onSelectAlternateExercises}
              onlySelect={true}
              disableCreateNew={true}
              menuIsAutoOpen={false}
              excludes={alternativeIds}
              placeholder="Add alternate exercises"
              showNameMultiline={true}
            />
          )}
          {!_.isEmpty(alternatives) && (
            <SortableList
              helperClass="sortableHelper"
              items={alternatives}
              onSortEnd={this.onSortEnd}
              onClickRemoveIcon={this.removeExercise}
              useDragHandle
            />
          )}
        </S.AlternateExercisesWrapperContent>
      </>
    );
  };

  renderAdvancedSetting = () => {
    return (
      <AdvancedSetting>
        {this.renderEquipment()}
        {this.renderAlternateExercises()}
        {this.renderDefaultNote()}
        {this.renderSynonyms()}
        {this.renderTags()}
      </AdvancedSetting>
    );
  };

  renderOwnerShip = () => {
    const { user } = this.props;
    const { exercise, mode } = this.state;
    const authorId = _.get(exercise, 'author._id', null);

    if (mode === MODES.SAVE_A_COPY) {
      return (
        <>
          <S.OwnerShipDisable data-tip data-for="tooltip--save-a-copy" />
          <ReactTooltip id="tooltip--save-a-copy" effect="solid" place-="top" className="app-tooltip">
            <S.FieldGuideContent>
              Please create your own copy of the exercise to edit sharing settings.
            </S.FieldGuideContent>
          </ReactTooltip>
        </>
      );
    }

    if (mode === MODES.CREATE || mode === MODES.CLONE || exercise.custom) {
      return (
        <OwnershipPopup
          isEditAble={
            mode === MODES.CREATE ||
            mode === MODES.CLONE ||
            (mode === MODES.UPDATE && (exercise.share === TEAM_SHARE_NOOWNER || authorId === user._id))
          }
          owner={authorId}
          handleChangeOwner={this.handleChangeOwner}
          share={exercise.share}
          handleChangeShare={this.handleChangeShare}
        />
      );
    } else {
      return (
        <>
          <S.OwnerShipDisable data-tip data-for="tooltip--system-exercise" />
          <ReactTooltip id="tooltip--system-exercise" effect="solid" place-="top" className="app-tooltip">
            <S.FieldGuideContent>
              This is a system exercise. Please create a duplicate to edit sharing settings.
            </S.FieldGuideContent>
          </ReactTooltip>
        </>
      );
    }
  };

  onTitleChange = value => {
    this.setState({ exercise: { ...this.state.exercise, title: value } });
  };

  onContentScroll = event => {
    const { scrollTop, clientHeight } = event.target;
    if (this.leftContentRef.current) {
      const leftContentRect = this.leftContentRef.current.getBoundingClientRect();
      const { height } = leftContentRect || {};

      if (clientHeight === height) {
        if (scrollTop) {
          this.headerRef.current.style.borderBottomColor = '#DADFEA';
        } else {
          this.headerRef.current.style.borderBottomColor = 'transparent';
        }
      }
    }
  };

  handleSelectTags = values => {
    // let { exercise } = this.state;
    // let selectedTags = exercise.tags;
    this.setState({ exercise: { ...this.state.exercise, tags: values } });
  };

  handleGetCurrentTags = data => {
    const selectedTags = this.state.exercise.tags;
    let newData = data;

    newData.mostUsedList = _.filter(_.get(newData, 'mostUsedList', []), item => !selectedTags.includes(item._id));
    return newData;
  };

  handleCreateNewExerciseTag = (data, selectCreatedTagCallback) => {
    const callback = newTagData => {
      selectCreatedTagCallback(newTagData);
    };
    this.props.createNewTag({ name: data, type: TAGS_TYPE.EXERCISE }, callback);
  };

  renderTags = () => {
    const { exercise, menuPortalTarget } = this.state;
    // let currentTags = this.handleGetCurrentTags(_.cloneDeep(this.props.tags));
    // let currentTags = this.props.;
    // const modalElements = document.querySelectorAll('.ui.page.modals.dimmer');
    // const menuPortalTarget = modalElements.length > 0 ? modalElements[modalElements.length - 1] : null; // NOTE: use the last div modal element

    // TODO: handle menuPlacement auto detect small screen
    return (
      <S.TagsArea>
        <label>Tags</label>
        <TagsFilter
          allowCreateNew
          onCreateNew={this.handleCreateNewExerciseTag}
          tags={this.props.tags}
          selectedTags={exercise.tags}
          sorter="most_recent"
          type={TAGS_TYPE.EXERCISE}
          onChange={this.handleSelectTags}
          menuPortalTarget={null}
          menuPlacement="top"
          placeholder={'Add exercise tags'}
        />
      </S.TagsArea>
    );
  };

  checkIsChange = () => {
    const { originExercise, exercise, mode } = this.state;
    const origin = classToPlain(originExercise);
    const current = classToPlain(exercise);

    if (mode === MODES.CLONE || !origin.title) {
      origin.title = '';
    }

    if (!current.title) {
      current.title = '';
    }

    const changes = diff(origin, current);
    const isMediaChange = this.checkMediaChange();

    return changes || isMediaChange;
  };

  render() {
    const { open, maxSizeVideo, msgMaxSizeImg } = this.props;
    const { mediaData, showError, exercise, mode, submitting } = this.state;

    const isChange = this.checkIsChange();

    return (
      <S.Modal
        open={open}
        className="exercise-popup-container modal--exercise-detail"
        onClose={this.handleCloseAction}
        closeOnDimmerClick={false}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <Modal.Content>
          <S.Wrapper>
            <S.Content>
              <S.LeftSide>
                <S.HeaderContainer ref={this.headerRef}>
                  <S.ExerciseTitleContainer className={classNames({ error: showError && !exercise.title })}>
                    <TextEditable
                      ref={this.exerciseNameRef}
                      value={_.get(exercise, 'title', '')}
                      onChange={this.onTitleChange}
                      placeholder="Name your Exercise"
                      lineHeight={27}
                    />
                    <ErrorMessage>Please add exercise name</ErrorMessage>
                  </S.ExerciseTitleContainer>
                  {this.renderOwnerShip()}
                </S.HeaderContainer>
                <S.LeftSideContent onScroll={this.onContentScroll} ref={this.leftContentRef}>
                  {/* {this.renderPrimaryFocus()} */}
                  {this.renderModality()}
                  {this.renderMuscleGroup()}
                  {this.renderMovementPattern()}
                  {this.renderTrackingFields()}
                  {this.renderInstructions()}
                  {this.renderExerciseLink()}
                  {this.renderAdvancedSetting()}
                </S.LeftSideContent>
              </S.LeftSide>
              <S.RightSide>
                <MediaSide
                  {...mediaData}
                  onUpdate={this.onUpdateMediaData}
                  validVideo={exercise.validVideo}
                  fileType={exercise.file_extension}
                  maxSizeVideo={maxSizeVideo}
                  msgMaxSizeImg={msgMaxSizeImg}
                />
              </S.RightSide>
            </S.Content>
            <S.Actions>
              {mode === MODES.SAVE_A_COPY ? (
                <Button
                  purple
                  disabled={!isChange || !mediaData.canSave || submitting}
                  onClick={this.saveAndClose}
                  className="btn-save-and-close"
                >
                  Save a Copy
                </Button>
              ) : (
                <>
                  <Button
                    disabled={!isChange || !mediaData.canSave || submitting}
                    onClick={this.saveWithoutClose}
                    className="btn-save"
                  >
                    Save
                  </Button>
                  <Button
                    purple
                    disabled={!isChange || !mediaData.canSave || submitting}
                    onClick={this.saveAndClose}
                    className="btn-save-and-close"
                  >
                    Save & Close
                  </Button>
                </>
              )}
            </S.Actions>
          </S.Wrapper>
        </Modal.Content>
      </S.Modal>
    );
  }
}
