import { Types } from './actions';
import { fromJS } from 'immutable';

const formatRankingName = (data = []) => {
  data.forEach(it => {
    if (it.unit && it.unit.value) {
      switch (it.unit.value) {
        case 'tasks':
          it.unit.value = 'task';
          break;
        case 'steps':
          it.unit.value = 'step';
          break;
        case 'reps':
          it.unit.value = 'rep';
          break;
        case 'workouts':
          it.unit.value = 'workout';
          break;
        default:
          break;
      }
    }
  });
  return data;
};

const INITIAL_STATE = fromJS({
  types: [],
  categories: [],
  templates: [],
  loadingTypes: false,
  rankingTypes: [],
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.LEADERBOARD_FETCH_GLOBAL_DATA_SUCCESS:
      return state.merge(payload);

    case Types.LEADERBOARD_FETCH_RANKING_TYPES_SUCCESS:
      return state.set('rankingTypes', fromJS(formatRankingName(payload)));

    default:
      return state;
  }
};
