import styled from 'styled-components';
import { Modal, TextArea } from 'semantic-ui-react';
import { Header } from 'shared/Styles/ModalLayout';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 531px;
  }

  > .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }

  ${Header} {
    padding-bottom: 16px;
  }
  .wrapper-content {
    line-height: 0;
  }
  .wrapper-footer {
    padding-bottom: 20px;
    button {
      padding: 7px 30px;
    }
  }
`;
export const ModalSubTitle = styled.h5`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #202353;
  padding: 30px 30px 0;
  margin-bottom: 0;
`
export const ModalTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #202353;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 0;
  margin-bottom: 25px;
`

export const NoteArea = styled.div`
  width: 100%;
  border: 1px solid #D6DAE4;
  border-radius: 3px;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  padding: 15px;
  outline: none;
  resize: none;
  min-height: 111px;
  background: transparent;
  max-height: 175px;
  overflow: scroll;
  white-space: pre-line;
`