import React, { useMemo, useEffect } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { DropdownIndicator } from 'shared/Icons';
import _ from 'lodash';
import TIME_OPTIONS from 'constants/time-options';
import { ToggleReminder } from '../styles';
import { Toggle } from 'shared/FormControl';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip-icon.svg';
import moment, { relativeTimeRounding } from 'moment';

let Options = TIME_OPTIONS.filter(item => item.value > '03:45');

export const Reminder = ({
  starting,
  scheduleReminder,
  reminderTime,
  handleChangeReminderTime,
  handleToggleScheduleReminder,
}) => {
  const value = useMemo(() => {
    return Options.find(item => item.label === reminderTime);
  }, [reminderTime]);
  const getOption = option => option.label;
  const currentTime = moment();

  useEffect(() => {
    if (starting && currentTime.isSame(starting, 'date')) {
      const time = currentTime.format('HH:mm');
      Options = Options.filter(item => item.value > time);
    } else {
      Options = TIME_OPTIONS.filter(item => item.value > '03:45');
    }
  }, [starting]);

  return (
    <ToggleReminder>
      <div className="toggle">
        <Toggle
          disabled={!!!starting}
          className="toggle-input"
          checked={scheduleReminder}
          onChange={handleToggleScheduleReminder}
          width={36}
          height={20}
          label={
            <div className="text-content">
              <span>Set Reminder</span>
            </div>
          }
        />
        <TooltipIcon
          data-for="tooltip--personal-task-reminder"
          data-tip="A notification will be sent based on the assigner's timezone"
        />
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          className="app-tooltip"
          id="tooltip--personal-task-reminder"
          show={true}
        />
      </div>
      <div>
        {scheduleReminder && (
          <Select
            options={Options}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            placeholder="-"
            onChange={handleChangeReminderTime}
            classNamePrefix="evfSelectBox"
            className="evfSelectBoxContainer"
            value={value}
            isSearchable={true}
            styles={{
              control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 110 }),
              menu: base => ({ ...base, zIndex: 999999, marginTop: 2, marginBottom: 5, minWidth: 110 }),
            }}
            getOptionLabel={getOption}
            getOptionValue={getOption}
            menuPlacement="top"
            maxMenuHeight="250"
            menuPosition="absolute"
          />
        )}
      </div>
    </ToggleReminder>
  );
};
