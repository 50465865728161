import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import classnames from 'classnames';

import * as S from './style';
import MinimizeCalendar from './MinimizeCalendar';

class DayCalendar extends React.Component {
  state = {
    isHide: true,
    currentMonth: moment(this.props.defaultDay, 'MM-DD-YYYY').month(),
    currentDay: moment(this.props.defaultDay, 'MM-DD-YYYY'),
    workoutData: {},
  };

  componentDidMount() {
    this.fetchWorkouts(true);
  }

  fetchWorkouts = isInit => {
    const { currentMonth, currentDay } = this.state;
    const { workoutData } = this.props;
    const start_date = moment(currentDay).month(currentMonth).startOf('month').format('MM-DD-YYYY');
    const end_date = moment(currentDay).month(currentMonth).endOf('month').format('MM-DD-YYYY');

    this.props.fetchWorkoutsInRange({ start_date, end_date }).then(result => {
      this.props.updateWorkoutSchedule({ ...workoutData, ...result });
      this.handleChangeSelectedDay({ ...workoutData, ...result });
    });
  };

  onNavigateBack = month => {
    const { currentMonth } = this.state;
    this.setState({ currentMonth: currentMonth - 1 }, () => {
      this.fetchWorkouts();
      this.refs.datetime.setDate(moment.months(currentMonth - 1));
    });
  };

  onNavigateForward = month => {
    const { currentMonth } = this.state;
    this.setState({ currentMonth: currentMonth + 1 }, () => {
      this.fetchWorkouts();
      this.refs.datetime.setDate(moment.months(currentMonth + 1));
    });
  };

  onChange = day => {
    const month = day.month();
    if (month !== this.state.currentMonth) {
      this.setState({ currentDay: day });
      this.setState({ currentMonth: month }, this.fetchWorkouts);
    } else {
      this.setState({ currentDay: day }, () => {
        this.handleChangeSelectedDay(this.props.workoutData);
      });
    }
  };

  handleChangeSelectedDay = workoutData => {
    const { currentDay } = this.state;
    const selectedDay = _.get(workoutData, [currentDay.format('YYYY-MM-DD')], {
      day: currentDay.format('YYYY-MM-DD'),
      workouts: [],
    });
    this.props.onSelectDay(selectedDay);
  };

  renderDay = (props, currentDate, selectedDate) => {
    const { workoutData } = this.props;
    const workouts = _.get(workoutData, [currentDate.format('YYYY-MM-DD'), 'workouts'], []);
    const noDots = workouts.length > 3 ? 3 : workouts.length;
    return (
      <td {...props}>
        <S.DayCalendar>
          {currentDate.date()}
          <S.WorkoutDotContainer>
            {_.range(0, noDots).map(i => (
              <S.WorkoutDot key={i} />
            ))}
          </S.WorkoutDotContainer>
        </S.DayCalendar>
      </td>
    );
  };

  render() {
    const { currentMonth, currentDay } = this.state;
    const { isHide, workoutData } = this.props;
    const isShowMinimizeCalendar = currentMonth === currentDay.month() && isHide;

    return (
      <>
        <MinimizeCalendar
          className={classnames({ hide: !isShowMinimizeCalendar })}
          currentMonth={currentMonth}
          currentDay={currentDay}
          workoutData={workoutData}
          onNavigateBack={this.onNavigateBack}
          onNavigateForward={this.onNavigateForward}
          onChange={this.onChange}
        />
        <S.CustomDatetime
          ref="datetime"
          className={classnames({ hide: isShowMinimizeCalendar })}
          input={false}
          timeFormat={false}
          renderDay={this.renderDay}
          onNavigateBack={this.onNavigateBack}
          onNavigateForward={this.onNavigateForward}
          onChange={this.onChange}
          viewDate={moment().month(currentMonth)}
          defaultValue={currentDay}
        />
      </>
    );
  }
}

export default DayCalendar;
