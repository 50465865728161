import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import AssignProgram from './component';
import { getProgramLibrary, programAssignMultiple } from 'redux/program_library/program_library.actionCreators'

const mapStateToProps = state => {
  const { isModalOpen } = state;

  return {
    isModalOpen,
    programs: state.rootReducer.program_library.all_items || []
  }
}

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  programAssignMultiple: bindActionCreators(programAssignMultiple, dispatch),
  getProgramLibrary: bindActionCreators(getProgramLibrary, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignProgram);
