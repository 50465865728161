import React from 'react';
import _ from 'lodash';
import * as S from '../style';
import * as SettingStyles from './style';
import Upload from '../Upload';
import { SettingLoader } from '../Loader';
import UpgradePath from 'shared/UpgradePath';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { LIMIT_SIZE_IMAGE_UPLOAD_MB, showMsgForSize } from 'constants/commonData';

const DEFAULT_STATE = { src: '', name: '' };

class SettingEmailBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.isSaving = false;
  }

  componentDidUpdate(prevProps) {
    const { emailBanner, loading } = this.props;

    if (!loading && prevProps.loading) {
      this.setState({ ...emailBanner });
    }
  }

  onRemove = () => {
    // const { bucketData } = this.state;
    this.setState({ ...DEFAULT_STATE }, () => {
      // TODO
      // if (bucketData) {
      //   removeUploadedFileFromServer(bucketData);
      // }
    });
  };

  onUploadSuccess = ({ src, name, bucketData }) => {
    this.setState({ src, name, bucketData });
  };

  onSave = () => {
    if (this.isSaving) {
      return false;
    }

    const { src, name, bucketData } = this.state;
    const data = {
      invite_email_banner: _.get(bucketData, 'url', src),
      invite_email_banner_name: name,
    };
    const localData = { emailBanner: { src, name } };
    this.isSaving = true;
    this.props.updateCustomBranding(data, localData).finally(() => {
      this.isSaving = false;
    });
  };

  getCloudUrl = url => {
    const { cloudfrontList } = this.props;
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList) || url;
  };

  render() {
    const { src } = this.state;
    const { loading, emailBanner, user, cloudfrontList } = this.props;
    const newSrc = this.getCloudUrl(src);

    return (
      <S.Section>
        <div
          className="breaker"
          style={{
            display: 'block',
            width: '100%',
            height: '1px',
            background: '#eeeeee',
            transform: 'translateY(-30px)',
          }}
        ></div>
        <S.SectionHeader>
          <S.SectionHeaderTitle>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
            >
              <path d="M15 1H1C0.4 1 0 1.4 0 2V3.4L8 7.9L16 3.5V2C16 1.4 15.6 1 15 1Z" fill="#90949C" />
              <path
                d="M7.5 9.89995L0 5.69995V14C0 14.6 0.4 15 1 15H15C15.6 15 16 14.6 16 14V5.69995L8.5 9.89995C8.22 10.04 7.78 10.04 7.5 9.89995Z"
                fill="#90949C"
              />
            </svg>
            <span>Client Invitation</span>
          </S.SectionHeaderTitle>
          {!loading && emailBanner.src !== src ? (
            <S.SaveButton className="save" onClick={this.onSave}>
              Save
            </S.SaveButton>
          ) : null}
        </S.SectionHeader>
        <S.SectionContent>
          <S.SettingArea>
            {loading ? (
              <SettingLoader />
            ) : (
              <S.SettingAreaContent>
                <S.SectionDescription>
                  Add your branding to the client invite email and client sign up process.
                </S.SectionDescription>
                <UpgradePath
                  pathName="branding"
                  description="Add your own flare to create attractive, custom-branded email"
                >
                  <S.FormGroup>
                    <label>Invite email banner</label>
                    <Upload
                      key={src || 'emailBanner'}
                      onRemove={this.onRemove}
                      cloudfrontList={cloudfrontList}
                      onUploadSuccess={this.onUploadSuccess}
                      data={this.state}
                      source="Invite email banner"
                      isEmailBanner
                      maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
                      overSizeMessage={showMsgForSize(LIMIT_SIZE_IMAGE_UPLOAD_MB)}
                    />
                    <S.Tip>
                      Add a banner image with width-to-height ratio that is between 5:1 and 7:1. Images outside that
                      dimension will be cropped. Suggested image width of at least{' '}
                      <span className="hightlight">690px</span> for best resolution.
                    </S.Tip>
                  </S.FormGroup>
                </UpgradePath>
              </S.SettingAreaContent>
            )}
          </S.SettingArea>
          <SettingStyles.PreviewContainer>
            <SettingStyles.EmailContainer>
              <SettingStyles.EmailBanner background={!!src}>
                {!src ? <span>Email Banner</span> : <img src={newSrc} alt="" />}
              </SettingStyles.EmailBanner>
              <SettingStyles.EmailBody>
                <SettingStyles.Avatar />
                <div>You have an invitation from</div>
                <div>
                  {user.first_name} {user.last_name}
                </div>
              </SettingStyles.EmailBody>
              <SettingStyles.PhoneContainer>
                <SettingStyles.PhoneEmailBanner background={newSrc}>
                  {!src ? <span>Sign up Banner</span> : <img src={newSrc} alt="" />}
                </SettingStyles.PhoneEmailBanner>
                <SettingStyles.PhoneEmailBody>
                  <div className="text">
                    Create your account to train with {user.first_name} {user.last_name}
                  </div>
                  <S.Skeleton width={46} height={6} style={{ marginBottom: '5px' }} />
                  <SettingStyles.PhoneEmailContentPlaceholder />
                </SettingStyles.PhoneEmailBody>
              </SettingStyles.PhoneContainer>
            </SettingStyles.EmailContainer>
          </SettingStyles.PreviewContainer>
        </S.SectionContent>
      </S.Section>
    );
  }
}

export default SettingEmailBanner;
