import React, { useMemo, useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import moment from 'moment';

import { Button, ErrorMessage } from 'shared/FormControl';
import GroupInput from 'components/BodyMetricProgressNew/AddResultPopup/GroupInput';
import TimeInput from 'components/BodyMetricProgressNew/AddResultPopup/TimeInput';

import { convertMinToFromHourMin } from 'utils/commonFunction';
import { METRIC_UNIT_ERRORS } from 'constants/commonData';
import * as S from './style';

const SleepGoalPopup = props => {
  const { currentGoal, onClose, onSave, isEdit } = props;
  const [errors, setErrors] = useState({});
  const [goalState, setGoalState] = useState(() => {
    const { value, sleep_time_goal } = currentGoal;
    const { hour, min } = convertMinToFromHourMin(value || 0);
    const timeGoalMM = sleep_time_goal ? moment(sleep_time_goal, 'HH:mm') : null;
    const time = timeGoalMM ? timeGoalMM.format('hh : mm') : '';
    const isAM = timeGoalMM ? timeGoalMM.format('A') === 'AM' : false;
    return {
      duration: {
        input1: {
          value: hour || '',
          label: 'h',
        },
        input2: {
          value: min || '',
          label: 'min',
        },
      },
      time: time,
      isAMTime: isAM,
    };
  });

  const isDisabledSubmit = useMemo(() => {
    const {
      duration: {
        input1: { value: value1 },
        input2: { value: value2 },
      },
      time,
    } = goalState;
    const hasError = Object.keys(errors || {}).some(key => !!errors[key]);
    return hasError || (value1 === '' && value2 === '') || time === '';
  }, [goalState, errors]);

  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const minutes = Number(goalState.duration.input1.value) * 60 + Number(goalState.duration.input2.value);
    let duration_time_goal = '';
    if (goalState.time) {
      const timeGoalMM = moment(goalState.time, 'HH : mm');
      if (!goalState.isAMTime) {
        timeGoalMM.add(12, 'h');
      }
      duration_time_goal = timeGoalMM.format('HH:mm');
    }
    if (minutes > 24 * 60) {
      setErrors({ duration: 'Duration should be less than 24 hours' });
    } else {
      onSave({
        value: minutes,
        sleep_time_goal: duration_time_goal,
      });
    }
  };

  const handleChangeDurationGoal = (key, value) => {
    setGoalState(s => ({
      ...s,
      duration: {
        ...s.duration,
        [key]: value,
      },
    }));
  };

  const handleBlurDurationGoal = () => {
    const { input1, input2 } = goalState.duration;
    const isInput1Empty = input1.value === '';
    const isInput2Empty = input2.value === '';
    const input1Value = input1.value;
    const input2Value = input2.value;
    const isErrorDuration =
      (!isInput1Empty && input1Value === '0' && !isInput2Empty && input2Value === '0') ||
      (isInput1Empty && input2Value === '0') ||
      (isInput2Empty && input1Value === '0');

    let over24hError = undefined;
    if (input1.value || input2.value) {
      const minutes = Number(input1.value) * 60 + Number(input2.value);
      over24hError = minutes > 24 * 60 ? 'Duration should be less than 24 hours' : undefined;
    }

    setErrors({ ...errors, duration: isErrorDuration ? METRIC_UNIT_ERRORS.VALUE : over24hError });
  };

  const handleChangeTime = newTime => {
    setGoalState(s => ({ ...s, ...newTime }));
  };

  const handleNewErrors = newErrors => {
    setErrors(s => ({ ...s, ...newErrors }));
  };

  return (
    <RootCloseWrapper event="click" onRootClose={onClose}>
      <S.Wrapper className="sleep-goal-popup">
        <div className="content">
          <h3>{isEdit ? 'Edit' : 'Add'} Goal</h3>
          <div className="value-input">
            <div className="flex-column">
              <div className={classNames('sleep-label', { error: !!errors.duration })}>Sleep duration</div>
              <GroupInput
                hasError={!!errors.duration}
                autoFocus
                input1={goalState.duration.input1}
                input2={goalState.duration.input2}
                onChange={handleChangeDurationGoal}
                onBlur={handleBlurDurationGoal}
                input2NumberDecimal={0}
              />
              {!!errors.duration && <ErrorMessage className="sleep-error">{errors.duration}</ErrorMessage>}
            </div>
            <div className="value-input">
              <div className="flex-column">
                <div className={classNames('sleep-label', { error: !!errors.time })}>Sleep Time</div>
                <TimeInput
                  isError={!!errors.time}
                  value={goalState.time}
                  isAM={goalState.isAMTime}
                  className="sleep-goal-time"
                  onFocus={handleBlurDurationGoal}
                  onChange={handleChangeTime}
                  setErrors={handleNewErrors}
                />
                {!!errors.time && (
                  <ErrorMessage marginTop="8px" className="value-error">
                    {METRIC_UNIT_ERRORS.TIME}
                  </ErrorMessage>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <Button className="btn-cancel" onMouseDown={onClose}>
            Cancel
          </Button>
          <Button purple className="btn-save" onClick={handleSave} disabled={isDisabledSubmit}>
            Save
          </Button>
        </div>
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

export default SleepGoalPopup;
