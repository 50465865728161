import get from 'lodash/get';
import find from 'lodash/find';
import Avatar from 'react-avatar';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { convertS3UrlToCloudFrontUrl, getUserShortName, pluralize } from 'utils/commonFunction';
import { getCoachBiosDetail } from 'redux/coach-bios-detail/actions';
import { CURRENCIES, PACKAGE_PRICING_TYPES, PACKAGE_WISHLIST_TYPES } from 'constants/commonData';

import { ReactComponent as Stripe } from 'assets/icons/stripe_grey.svg';
import { ReactComponent as Credit } from 'assets/icons/credit-card-ex.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow_right_light.svg';
import { ReactComponent as Close } from 'assets/icons/close_current_color.svg';
import { ReactComponent as MobileBlack } from 'assets/icons/mobile_view_black.svg';
import { ReactComponent as MobileWhite } from 'assets/icons/mobile_view_white.svg';
import { ReactComponent as DesktopBlack } from 'assets/icons/desktop_view_black.svg';
import { ReactComponent as DesktopWhite } from 'assets/icons/desktop_view_white.svg';
import { ReactComponent as EverfitBrandingIcon } from 'assets/icons/everfit-branding.svg';
import { ReactComponent as NewEverfitBrandingIcon } from 'assets/icons/new-everfit-branding.svg';
import { ReactComponent as ArrowRightICon } from 'assets/icons/arrow-right-gray.svg';
import { ReactComponent as NewCredit } from 'assets/icons/new-credit-card.svg';

import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';

const VIEW_MODES = {
  desktop: 'desktop',
  mobile: 'mobile',
};

const PreviewModal = props => {
  const { packageData, onClose, coachBioInfo, trainer, user, cloudfrontList, isMP } = props;
  const [viewMode, setMode] = useState(VIEW_MODES.desktop);
  const [isReadMore, setReadMore] = useState(false);
  const [isOver, setOver] = useState(false);
  const [previewDetailHeight, setPreviewDetailHeight] = useState(null);

  const coachBiosRef = useRef();

  const { price, coach_bio, package_purchase_option = {} } = packageData;
  const { type: packagePurchaseType, is_turned_on } = package_purchase_option;
  const isWaitlist = packagePurchaseType === PACKAGE_WISHLIST_TYPES.WAITLIST;
  const isContact = packagePurchaseType === PACKAGE_WISHLIST_TYPES.CONTACT;
  const isTurnedOnWaitlistOrIntroCall = (isWaitlist || isContact) && is_turned_on;
  const coachBioPreview = get(coachBioInfo, '_id') ? coachBioInfo : coach_bio;
  const bio = get(coachBioPreview, 'bio', '');
  const isTrial = get(price, 'enable_trial', false);
  const trialPeriod = get(price, 'trial_period', 0);
  const isWhiteLabelAccount = find(user.teams, item => get(item, 'team.white_label', false));

  useEffect(() => {
    setTimeout(() => {
      if (coachBiosRef.current) {
        if (coachBiosRef.current.offsetHeight < coachBiosRef.current.scrollHeight) {
          setOver(true);
        }
      }
    }, 500);
  }, [trainer]);

  const handleCurrentCoachBio = useCallback(() => {
    trainer && props.getCoachBiosDetail(trainer);
  }, [trainer]);

  const handleReadMore = () => {
    setReadMore(!isReadMore);
  };

  useEffect(() => {
    handleCurrentCoachBio();
  }, [handleCurrentCoachBio]);

  const handleChangeMode = mode => () => {
    setMode(mode);
  };

  const isDesktopView = viewMode === VIEW_MODES.desktop;

  const {
    every = 0,
    recurring_interval_type = 'month',
    end_after = null,
    amount = 0,
    type = PACKAGE_PRICING_TYPES.one_time.value,
  } = price || {};
  const symbol = useMemo(() => {
    if (!price) return '$';
    const currency = get(price, 'currency', '');
    if (['chf', 'nok', 'sek', 'aed'].includes(currency)) return '';
    return get(CURRENCIES[currency], 'symbol', '$');
  }, [price]);
  const currency = useMemo(() => {
    const currency = get(price, 'currency', '');
    if (currency === 'aed') return 'Dh';
    return currency.toUpperCase();
  }, [price]);

  const repeatMessageMn = () => {
    if (!end_after) {
      return '';
    }
    return isMP ? `${end_after} times` : <S.TextBold>Repeat {end_after} Times</S.TextBold>;
  };

  const EverfitFooter = ({ desktop }) => {
    return (
      <S.EverfitFooter desktop={desktop}>
        <p>
          <S.EverfitFooterText>Powered by</S.EverfitFooterText>
          <EverfitBrandingIcon />
        </p>
      </S.EverfitFooter>
    );
  };

  const NewEverfitFooter = ({ desktop }) => {
    return (
      <S.EverfitFooter desktop={desktop}>
        <p>
          <S.EverfitFooterText>Powered by</S.EverfitFooterText>
          <NewEverfitBrandingIcon />
        </p>
      </S.EverfitFooter>
    );
  };

  const getMPSubmitText = () => {
    if (isTurnedOnWaitlistOrIntroCall) {
      if (isWaitlist) return 'Join Waitlist';
      if (isContact) return "Interested? Let's talk";
    }

    return isTrial ? `Start ${trialPeriod}-Day Trial` : 'Purchase';
  };

  const getWaitlistOrIntroCallHelpText = () => {
    if (!isTurnedOnWaitlistOrIntroCall) return null;

    const helpText = isContact
      ? 'Click to request a free introduction call with this coach.'
      : 'Click here to join the waitlist for this package.';

    return <S.NewTrialDesc>{helpText}</S.NewTrialDesc>;
  };

  const getFooterMobileDom = footerMobileDom => {
    if (footerMobileDom) {
      setPreviewDetailHeight(footerMobileDom.offsetHeight);
    }
  };

  const InformationContent = () => {
    const getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);
    if (isMP) {
      return (
        <S.Container isMobile={!isDesktopView}>
          <S.NewPreviewDetail
            isMobile={!isDesktopView}
            isRecurring={type === 'recurring'}
            previewDetailHeight={previewDetailHeight}
          >
            <S.PreviewBanner background={getCloudUrl(packageData.cover_image) || ''} isMobile={!isDesktopView} />
            <S.NewPreviewInfoWrapper isMobile={!isDesktopView}>
              <S.NewPreviewFormWrapper flex className="ql-snow" isMobile={!isDesktopView}>
                <S.NewPreviewBreadcrumb>
                  <S.NewPreviewBreadcrumbLink>{`Home`}</S.NewPreviewBreadcrumbLink>
                  <ArrowRightICon />
                  <S.NewPreviewBreadcrumbLink>
                    {!isEmpty(user) ? user.full_name || `${user.first_name} ${user.last_name}` : `Trainer profile`}
                  </S.NewPreviewBreadcrumbLink>
                </S.NewPreviewBreadcrumb>
                <div>
                  <S.NewPreviewTitle isMobile={!isDesktopView}>{packageData.name}</S.NewPreviewTitle>
                  <S.NewPreviewHeading isMobile={!isDesktopView}>{packageData.headline}</S.NewPreviewHeading>
                </div>
                <S.NewGroupPreviewContent isMobile={!isDesktopView}>
                  {packageData.description && (
                    <S.Group isMobile={!isDesktopView} className="desc" isBio={!isEmpty(bio)} isMP={isMP}>
                      <div className="ql-editor" dangerouslySetInnerHTML={{ __html: packageData.description }} />
                    </S.Group>
                  )}
                </S.NewGroupPreviewContent>
              </S.NewPreviewFormWrapper>
              {isDesktopView && (
                <S.NewPreviewFormWrapper>
                  <S.PreviewNested>
                    <S.NewFormContainer>
                      <S.NewPreviewPricing>
                        <S.PreviewPaymentType>
                          <S.NewPaymentTitle>
                            {type === PACKAGE_PRICING_TYPES.one_time.value ? 'Total' : 'Recurring Subscription'}
                          </S.NewPaymentTitle>
                          {end_after > 0 && type !== PACKAGE_PRICING_TYPES.one_time.value && (
                            <S.NewPaymentTime>{`${end_after} times`}</S.NewPaymentTime>
                          )}
                        </S.PreviewPaymentType>
                        <S.PreviewPricingDetail>
                          <S.NewCurrencyContainer>
                            <S.NewCurrencyName>{currency}</S.NewCurrencyName>
                            <div>
                              <S.NewCurrency>{symbol}</S.NewCurrency>
                              <S.NewPricing>
                                <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} />
                              </S.NewPricing>
                            </div>
                          </S.NewCurrencyContainer>
                          <S.NewPaymentTime>
                            {every > 0 &&
                              type !== PACKAGE_PRICING_TYPES.one_time.value &&
                              `every ${every} ${pluralize(recurring_interval_type, every)}`}
                          </S.NewPaymentTime>
                        </S.PreviewPricingDetail>
                      </S.NewPreviewPricing>
                      <S.NewPreviewFormWrapper isTurnedOnWaitlistOrIntroCall={isTurnedOnWaitlistOrIntroCall}>
                        {!isTurnedOnWaitlistOrIntroCall && (
                          <S.NewCardForm>
                            <S.NewCardInput borderBottom>Name on Card</S.NewCardInput>
                            <S.NewCardInput borderBottom>
                              <NewCredit />
                              Card Number
                            </S.NewCardInput>
                            <S.NewCardExpired>
                              <S.NewCardInput borderRight>MM / YY</S.NewCardInput>
                              <S.NewCardInput>CVC</S.NewCardInput>
                            </S.NewCardExpired>
                          </S.NewCardForm>
                        )}
                        <S.NewPaymentButton isTrial={isTrial}>
                          {getMPSubmitText()}
                          <Arrow />
                        </S.NewPaymentButton>
                        {!isTurnedOnWaitlistOrIntroCall && isTrial && (
                          <>
                            <S.NewTrialDesc>
                              Your payment will be charged after the {trialPeriod}-day trial, unless you cancel during
                              the trial period.
                            </S.NewTrialDesc>
                          </>
                        )}
                        {getWaitlistOrIntroCallHelpText()}
                      </S.NewPreviewFormWrapper>
                    </S.NewFormContainer>
                    <S.FormFooter isWhiteLabelAccount={isWhiteLabelAccount}>
                      {!isWhiteLabelAccount && (
                        <>
                          By continuing, you’re agreeing to the
                          <br />
                          <S.RedirectLink href="https://everfit.io/tos" target="_blank">
                            Everfit Terms of Service
                          </S.RedirectLink>{' '}
                          and{' '}
                          <S.RedirectLink href="https://everfit.io/privacy" target="_blank">
                            Privacy Policy
                          </S.RedirectLink>
                        </>
                      )}
                      <S.FooterLogo isWhiteLabelAccount={isWhiteLabelAccount}>
                        <S.Copyright>Powered by</S.Copyright>
                        <Stripe />
                      </S.FooterLogo>
                    </S.FormFooter>
                  </S.PreviewNested>
                </S.NewPreviewFormWrapper>
              )}
              {!isDesktopView && !isWhiteLabelAccount && <NewEverfitFooter />}
            </S.NewPreviewInfoWrapper>
            {isDesktopView && !isWhiteLabelAccount && <NewEverfitFooter desktop />}
          </S.NewPreviewDetail>
          {!isDesktopView && (
            <S.FooterMobile ref={getFooterMobileDom}>
              <S.NewFooterMobileItem>
                <S.NewFooterCurrencyContainer>
                  <div>
                    <S.NewFooterTotalLabel>{`Total`}</S.NewFooterTotalLabel>
                    <S.NewFooterRepeat>{type === 'recurring' && repeatMessageMn()}</S.NewFooterRepeat>
                  </div>
                  <div className="align-right">
                    <div className="currency">
                      <S.NewFooterCurrencyName>{currency}</S.NewFooterCurrencyName>
                      <div>
                        <S.NewFooterCurrency>{symbol}</S.NewFooterCurrency>
                        <S.NewFooterPricing>
                          <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} />
                        </S.NewFooterPricing>
                      </div>
                    </div>
                    <S.NewFooterRepeat>
                      {type === 'recurring' && `every ${every} ${pluralize(recurring_interval_type, every)}`}
                    </S.NewFooterRepeat>
                  </div>
                </S.NewFooterCurrencyContainer>

                <S.NewFooterPaymentButton isTrial={isTrial}>
                  {getMPSubmitText()}
                  <Arrow />
                </S.NewFooterPaymentButton>
                {getWaitlistOrIntroCallHelpText()}
              </S.NewFooterMobileItem>
            </S.FooterMobile>
          )}
        </S.Container>
      );
    }
    return (
      <S.Container isMobile={!isDesktopView}>
        <S.PreviewDetail isMobile={!isDesktopView}>
          <S.PreviewBanner background={packageData.cover_image || ''} isMobile={!isDesktopView} />
          <S.PreviewInfoWrapper isMobile={!isDesktopView}>
            <S.PreviewFormWrapper className="ql-snow" isMobile={!isDesktopView}>
              <S.Group isMobile={!isDesktopView}>
                <S.PreviewTitle isMobile={!isDesktopView}>{packageData.name}</S.PreviewTitle>
                <S.PreviewHeading isMobile={!isDesktopView}>{packageData.headline}</S.PreviewHeading>
              </S.Group>
              {packageData.description && (
                <S.Group isMobile={!isDesktopView} className="desc" isBio={!isEmpty(bio)}>
                  <div className="ql-editor" dangerouslySetInnerHTML={{ __html: packageData.description }} />
                </S.Group>
              )}

              {bio && (
                <S.Group isMobile={!isDesktopView}>
                  <S.PreviewCoachBio isMobile={!isDesktopView}>
                    <S.CoachTitle isMobile={!isDesktopView}>Meet the coach</S.CoachTitle>
                    <S.CoachHeader>
                      <S.Avatar isMobile={!isDesktopView}>
                        <Avatar
                          src={get(coachBioPreview, 'avatar', '')}
                          name={getUserShortName(coachBioPreview)}
                          round={true}
                          size="36"
                          color={get(coachBioPreview, 'color', '')}
                        />
                      </S.Avatar>
                      <S.AccountWrapper isMobile={!isDesktopView}>
                        <S.CoachName>{get(coachBioPreview, 'full_name', '')}</S.CoachName>
                        <S.CoachRole>{get(coachBioPreview, 'role', '')}</S.CoachRole>
                      </S.AccountWrapper>
                    </S.CoachHeader>
                    <S.BioContent
                      isMobile={!isDesktopView}
                      isReadMore={isReadMore}
                      id="coach-bio-info"
                      ref={coachBiosRef}
                    >
                      {get(coachBioPreview, 'bio', '')}
                    </S.BioContent>
                    {isOver && (
                      <S.ShowLess onClick={handleReadMore} isReadMore={isReadMore} isMobile={!isDesktopView}>
                        {isReadMore ? 'Show less' : <span className="read-more">Read more</span>}
                      </S.ShowLess>
                    )}
                  </S.PreviewCoachBio>
                </S.Group>
              )}
            </S.PreviewFormWrapper>

            {isDesktopView && (
              <S.PreviewFormWrapper>
                <S.PreviewNested>
                  <S.FormContainer>
                    <S.PreviewPricing>
                      <S.PreviewPaymentType>
                        <S.PaymentTitle>
                          {type === PACKAGE_PRICING_TYPES.one_time.value ? 'Total' : 'Recurring Subscription'}
                        </S.PaymentTitle>
                        {end_after > 0 && type !== PACKAGE_PRICING_TYPES.one_time.value && (
                          <S.PaymentTime>{end_after} Times</S.PaymentTime>
                        )}
                      </S.PreviewPaymentType>
                      <S.PreviewPricingDetail>
                        <S.CurrencyContainer>
                          <S.CurrencyName>{currency}</S.CurrencyName>
                          <S.Currency>{symbol}</S.Currency>
                          <S.Pricing>
                            <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} />
                          </S.Pricing>
                        </S.CurrencyContainer>
                        <S.RepeatTimes>
                          {every > 0 &&
                            type !== PACKAGE_PRICING_TYPES.one_time.value &&
                            `every ${every} ${pluralize(recurring_interval_type, every)}`}
                        </S.RepeatTimes>
                      </S.PreviewPricingDetail>
                    </S.PreviewPricing>
                    <S.PreviewFormWrapper>
                      <S.FakeInput>Your Name</S.FakeInput>
                      <S.FakeInput>Your Email</S.FakeInput>
                      <S.FakeInput>Phone Number</S.FakeInput>
                      <S.CardForm>
                        <S.CardNumber>
                          <Credit />
                          Card Number
                        </S.CardNumber>
                        <S.CardExpired>
                          <S.CardNumber>MM / YY</S.CardNumber>
                          <S.CardNumber>CVC</S.CardNumber>
                        </S.CardExpired>
                      </S.CardForm>
                      <S.PaymentButton isTrial={isTrial}>
                        {isTrial ? `Start ${trialPeriod}-Day Trial` : 'Purchase'}
                        <Arrow />
                      </S.PaymentButton>
                      {isTrial && (
                        <>
                          <S.TrialDesc>
                            Your payment will be charged after the {trialPeriod}-day trial, unless you cancel during the
                            trial period.
                          </S.TrialDesc>
                        </>
                      )}
                    </S.PreviewFormWrapper>
                  </S.FormContainer>
                  <S.FormFooter isWhiteLabelAccount={isWhiteLabelAccount}>
                    {!isWhiteLabelAccount && (
                      <>
                        By continuing, you’re agreeing to the
                        <br />
                        <S.RedirectLink href="https://everfit.io/tos" target="_blank">
                          Everfit Terms of Service
                        </S.RedirectLink>{' '}
                        and{' '}
                        <S.RedirectLink href="https://everfit.io/privacy" target="_blank">
                          Privacy Policy
                        </S.RedirectLink>
                      </>
                    )}
                    <S.FooterLogo isWhiteLabelAccount={isWhiteLabelAccount}>
                      <S.Copyright>Powered by</S.Copyright>
                      <Stripe />
                    </S.FooterLogo>
                  </S.FormFooter>
                </S.PreviewNested>
              </S.PreviewFormWrapper>
            )}
            {!isDesktopView && !isWhiteLabelAccount && <EverfitFooter />}
          </S.PreviewInfoWrapper>
          {isDesktopView && !isWhiteLabelAccount && <EverfitFooter desktop />}
        </S.PreviewDetail>
        {!isDesktopView && (
          <S.FooterMobile>
            <S.FooterMobileItem>
              <div>
                <S.FooterCurrencyContainer>
                  <S.FooterCurrencyName>{currency}</S.FooterCurrencyName>
                  <S.FooterCurrency>{symbol}</S.FooterCurrency>
                  <S.FooterPricing>
                    <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} />
                  </S.FooterPricing>
                </S.FooterCurrencyContainer>
                <S.FooterRepeat>
                  {type === 'one_time' ? 'Total' : `Every ${every} ${pluralize(recurring_interval_type, every)} `}
                  {type === 'recurring' && repeatMessageMn()}
                </S.FooterRepeat>
              </div>
              <div>
                <S.FooterPaymentButton isTrial={isTrial}>
                  {isTrial ? `Start ${trialPeriod}-Day Trial` : 'Purchase'}
                </S.FooterPaymentButton>
              </div>
            </S.FooterMobileItem>
          </S.FooterMobile>
        )}
      </S.Container>
    );
  };

  return (
    <S.ModalWrapper open={true} closeOnDimmerClick={false}>
      <Modal.Header>
        <S.HeaderContainer>
          <S.HeaderMessage>This is a preview of the package your clients will see.</S.HeaderMessage>
          <S.HeaderPreviewDevicesWrapper>
            <S.HeaderPreviewDevice active={isDesktopView} onClick={handleChangeMode(VIEW_MODES.desktop)}>
              {isDesktopView ? <DesktopBlack /> : <DesktopWhite />}
            </S.HeaderPreviewDevice>
            <S.HeaderPreviewDevice active={!isDesktopView} onClick={handleChangeMode(VIEW_MODES.mobile)}>
              {!isDesktopView ? <MobileBlack /> : <MobileWhite />}
            </S.HeaderPreviewDevice>
          </S.HeaderPreviewDevicesWrapper>
          <Close onClick={onClose} />
        </S.HeaderContainer>
      </Modal.Header>
      <ModalLayout.Content>
        <InformationContent />
      </ModalLayout.Content>
    </S.ModalWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      coachBioDetails: { coachBioInfo },
    },
    user,
  } = state;

  return { coachBioInfo, user };
};

const actionCreators = {
  getCoachBiosDetail,
};

export default connect(mapStateToProps, actionCreators)(PreviewModal);
