import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleModal } from 'actions/modal';
import { archiveConversation, markAsUnread, muteConversation, unmuteConversation } from 'redux/inbox-new/actions';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import { SharedTooltip } from 'shared/SharedTooltip';
import MuteConversationModal from './MuteConversationModal';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const RoomAction = ({
  type = 'dark',
  isRoom,
  roomId,
  markAsUnread,
  archiveConversation,
  readOnly,
  isGroup,
  toggleModal,
  muteConversation,
  unmuteConversation,
  isMute = false,
  searching = false,
}) => {
  const [isReadOnly, setIsReadOnly] = useState(readOnly);

  useEffect(() => {
    setIsReadOnly(readOnly);
  }, [readOnly]);

  const handleArchive = () => {
    archiveConversation(roomId);
  };

  const handleMarkAsUnread = () => {
    markAsUnread(roomId);
  };

  const handleOpenModal = () => {
    toggleModal &&
      toggleModal(true, <MuteConversationModal onMute={handleMuteConversation} onClose={() => toggleModal(false)} />);
  };

  const handleMuteConversation = time => {
    muteConversation && muteConversation(roomId, time);
  };

  const handleUnmuteConversation = () => {
    unmuteConversation && unmuteConversation(roomId);
  };

  return (
    <>
      <S.InboxAction
        readOnly={isReadOnly}
        isMute={isMute}
        searching={searching}
        isGroup={isGroup}
        data-for="room-action-tooltip"
        data-tip
      >
        <Dropdown
          direction="left"
          className={`inbox-action ${isRoom && 'room-item'}`}
          type={type}
          disabled={isReadOnly}
          shouldCheckItem
        >
          <Option onClick={handleMarkAsUnread} key="read">
            <img
              src={`${CDN_URL}/images/unread${type === 'dark' ? '_light' : '_dark'}.svg`}
              className="action"
              alt=""
            />
            <span className="action-name">Mark as unread</span>
          </Option>
          <Option onClick={handleArchive} key="archive">
            <img
              src={`${CDN_URL}/images/archive${type === 'dark' ? '_light' : '_dark'}.svg`}
              className="action"
              alt=""
            />
            <span className="action-name">Archive</span>
          </Option>
          {isGroup ? (
            isMute ? (
              <Option onClick={handleUnmuteConversation} key="unmute">
                <img
                  src={`${CDN_URL}/images/unmute${type === 'dark' ? '_light' : '_dark'}.svg`}
                  className="action"
                  alt=""
                />
                <span className="action-name">Unmute conversation</span>
              </Option>
            ) : (
              <Option onClick={handleOpenModal} key="mute">
                <img
                  src={`${CDN_URL}/images/mute${type === 'dark' ? '_light' : '_dark'}.svg`}
                  className="action"
                  alt=""
                />
                <span className="action-name">Mute conversation</span>
              </Option>
            )
          ) : null}
        </Dropdown>
        <SharedTooltip id="room-action-tooltip" />
      </S.InboxAction>
    </>
  );
};

const mapStateToProps = state => ({});

const mapDispatch = dispatch => ({
  archiveConversation: bindActionCreators(archiveConversation, dispatch),
  markAsUnread: bindActionCreators(markAsUnread, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  muteConversation: bindActionCreators(muteConversation, dispatch),
  unmuteConversation: bindActionCreators(unmuteConversation, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(RoomAction);
