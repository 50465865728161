// libs
import React, { useMemo } from 'react';
import { encryptData } from 'utils/commonFunction';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

// redux
import { addRoomDraftMessage } from 'redux/inbox-new/actions';
import { updateSleepView } from 'actions/bodyMetric';

// assets
import { ReactComponent as AskToConnectIconHasOura } from 'assets/icons/ask-to-connect-has-oura.svg';
import { ReactComponent as AskToConnectIconHideOura } from 'assets/icons/ask-to-connect.svg';
import { SPECIAL_METRICS_CODE } from 'components/BodyMetricChartNew/constants';

import * as S from './style';

export const getMessageRequest = code => {
  let msg = '';
  if (code === SPECIAL_METRICS_CODE.STEPS) msg = 'steps';
  if (code === SPECIAL_METRICS_CODE.SLEEP) msg = 'sleep';
  if (code === SPECIAL_METRICS_CODE.HEART_RATE) msg = 'heart rate';

  return (
    `Let's turn on your activity tracker integration to start tracking your ${msg} automatically!` +
    '<br>' +
    'https://help.everfit.io/en/collections/2993019-integrations'
  );
};

export const HEALTH_APP_KEY = {
  APPLE_HEALTH: 'APPLE_HEALTH',
  GOOGLE_FIT: 'GOOGLE_FIT',
  FITBIT: 'FITBIT',
  GARMIN: 'GARMIN',
  CRONOMETER: 'CRONOMETER',
  MY_FITNESS_PAL: 'MY_FITNESS_PAL',
  OURA: 'OURA',
};

export const checkIsConnectedApp = (data = {}) => {
  const isConnected = Object.keys(data || {}).some(key => {
    if (data[key]) {
      if (key === HEALTH_APP_KEY.FITBIT && data[key].is_connected) {
        return true;
      }
      if ((key === HEALTH_APP_KEY.GOOGLE_FIT || key === HEALTH_APP_KEY.APPLE_HEALTH) && data[key].status) {
        return true;
      }
      if (key === HEALTH_APP_KEY.OURA && ['CONNECTED', 'SYNCING'].includes(data[key].status)) {
        return true;
      }
      if (data[key].status === 'CONNECTED') return true;
    }
    return false;
  });
  return isConnected;
};

const AskToConnectButton = props => {
  const {
    client,
    clientName,
    push,
    addRoomDraftMessage,
    device,
    className = '',
    showIcon = true,
    label,
    unique_code,
    isConnected3rdParty,
  } = props;
  const message = useMemo(() => getMessageRequest(unique_code), [unique_code]);
  const isOuraPermission = process.env.REACT_APP_ENABLE_OURA_INTEGRATION === 'true';
  const AskToConnectIcon = isOuraPermission ? AskToConnectIconHasOura : AskToConnectIconHideOura;

  const handleAskToConnect = () => {
    addRoomDraftMessage(client, message);
    push(`/home/inbox?profileId=${client}&ufn=${encryptData(clientName)}`);
  };

  if (device && !isConnected3rdParty)
    return (
      <S.ButtonWrapper onClick={handleAskToConnect} className={className}>
        {showIcon && <AskToConnectIcon className="ask-to-connect-icon" />}
        {label || 'Ask to Connect'}
      </S.ButtonWrapper>
    );

  return null;
};

const mapState = state => {
  const {
    rootReducer: {
      client: { workingClientDetail, isConnected3rdParty },
    },
    bodyMetric,
  } = state;
  const { unique_code } = bodyMetric;

  const { _id = '', full_name = '', first_name = '', last_name = '', last_used_device = false } =
    workingClientDetail || {};

  return {
    client: _id,
    clientName: full_name || `${first_name} ${last_name}`,
    device: last_used_device,
    unique_code,
    isConnected3rdParty,
  };
};

export default connect(mapState, { push, addRoomDraftMessage, updateSleepView })(AskToConnectButton);
