import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import Linkify from 'react-linkify';

import { CheckboxCircle } from 'shared/FormControl';

import * as S from '../styles';

const Option = ({ option, optionAnswer }) => {
  const [alignTop, setAlignTop] = useState(false);
  useEffect(() => {
    const element = document.getElementById(`${option._id}-element`);
    if (element && element.offsetHeight) {
      setAlignTop(element.offsetHeight > 28);
    }
  }, []);
  const isChecked = option._id === get(optionAnswer, '_id');
  if (!isChecked) {
    return null;
  }
  return (
    <div className="single-option-container" key={option._id} id={`${option._id}-element`}>
      <CheckboxCircle
        label={option.label}
        className={classNames('option-radio', { checked: option._id === get(optionAnswer, '_id'), alignTop: alignTop })}
        checked
      />
    </div>
  );
};

export default function SingleChoiceResponse({ answer }) {
  const originalOptions = get(answer, 'originalQuestion.options', []);
  const optionAnswer = get(answer, 'answer.option_answer');
  const otherAnswer = get(answer, 'answer.other_answer');
  return (
    <S.SingleChoiceContainer>
      {originalOptions.map(option => (
        <Option option={option} optionAnswer={optionAnswer} />
      ))}
      {otherAnswer && (
        <S.AnswerContentWrapper>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {otherAnswer}
          </Linkify>
        </S.AnswerContentWrapper>
      )}
    </S.SingleChoiceContainer>
  );
}
