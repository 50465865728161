import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 15px;
  min-height: 18px;
  margin-right: 8px;
`;

export const ContentContainer = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #3b4859;
  overflow: hidden;

  a {
    color: #5158cf;

    :hover {
      color: #5158cf;
    }

    &.no-style {
      color: inherit;
    }
  }
`;

export const PanelBody = styled.div`
  padding: 17px 14px 14px !important;

  .hide-section {
    display: none;
  }

  .one-line {
    height: 18px;
    overflow: hidden;
  }

  .two-line {
    height: 36px;
    overflow: hidden;
  }

  .three-line {
    height: 54px;
    overflow: hidden;
  }
`;

export const ProfilePart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const TimezoneInfo = styled(ProfilePart)`
  .edit-icon {
    :hover {
      cursor: pointer;
    }
  }

  :not(:hover) {
    .edit-icon {
      display: none;
    }
  }
`;

export default {
  clientItemInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconStyle: {
    minWidth: '15px',
  },
};

export const GroupOpenPopup = styled.a`
  color: #5158cf;
  cursor: pointer;
  &:hover {
    color: #5158cf;
    text-decoration: underline;
  }
`;

export const ViewMoreAction = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 2px;
  color: #5e5e5e;

  :hover {
    color: #5158cf;
    cursor: pointer;
  }
  .arrow-icon {
    margin-left: 7px;
    margin-top: ${props => !props.openViewMore && '2px'};
    transform: ${props => props.openViewMore && 'rotate(180deg)'};
  }
`;
