import React from 'react';
import Avatar from 'react-avatar';
import get from 'lodash/get';

import IconButton from 'shared/IconButton';
import { ReactComponent as Message } from 'assets/icons/message_light.svg';
import { getUserShortName, encryptData } from 'utils/commonFunction';

import * as S from './styles';

export default function CustomerInfo(props) {
  const { client, coach, push } = props;

  const handleInboxClient = () => {
    const clientId = client._id;
    const clientName = get(client, 'full_name', '');
    if (clientId) {
      push(`/home/inbox?profileId=${clientId}&ufn=${encryptData(clientName)}`);
    }
  };

  return (
    <S.Wrapper>
      <S.CustomerAvatar>
        <Avatar name={getUserShortName(client)} className="avatar" size="46" src={client.avatar} />
      </S.CustomerAvatar>
      <S.CustomerInfo>
        <div className="customerInfo__name">
          <S.OverviewLink href={client._id ? `/home/client/${client._id}` : null} target="_blank">
            {client.full_name}
          </S.OverviewLink>
          {client._id && (
            <IconButton
              icon={<Message />}
              tooltip="Message Client"
              tooltipKey={`message-client-tooltip-${client._id}`}
              onClick={handleInboxClient}
            />
          )}
        </div>
        <div className="customerInfo__item">
          <div>Email</div>
          <div>{client.email}</div>
        </div>
        <div className="customerInfo__item">
          <div>Phone</div>
          <div>{client.phone}</div>
        </div>
        {coach && (
          <div className="customerInfo__item">
            <div>Coach</div>
            <div className="customerInfo__coach">
              <Avatar name={getUserShortName(coach)} className="avatar" size="22" src={coach.avatar} />
              {coach.full_name}
            </div>
          </div>
        )}
      </S.CustomerInfo>
    </S.Wrapper>
  );
}
