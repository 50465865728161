// Libs
import styled from 'styled-components';

export const IconWrapper = styled.div`
  &.trigger-icon-wrapper {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    gap: 4px;
    background: linear-gradient(91.25deg, rgba(255, 76, 0, 0.14) 0.48%, rgba(224, 241, 27, 0.14) 98.93%), #ffffff;
    border-radius: 4px;
  }

  .trigger-icon-label {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ff6433;
  }
`;
