import { Types } from './actions';

export const SORTER = {
  NAME: 'name',
  ID: '_id',
};

export const SORT = {
  ASC: 1,
  DESC: -1,
};

export const INITIAL_STATE_HABITS = {
  data: [],
  listMostPopular: [],
  listMostRecent: [],
  listCustom: [],
  listYourTeam: [],
  loading: false,
  query: {
    page: 1,
    per_page: 100,
    text_search: '',
    sort: SORT.DESC,
    sorter: SORTER.ID,
    isEnd: false,
    lastHabitId: '',
  },
  total: null,
  habitUnits: [],
  habitOverview: {
    data: [],
    loading: false,
  },
  listSystem: [],
};

export default (state = INITIAL_STATE_HABITS, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_HABIT_UNITS_REQUEST:
      return Object.assign({}, state, { loading: true });
    case Types.GET_HABIT_UNITS_SUCCESS:
      return Object.assign({}, state, { loading: false, habitUnits: payload.data });
    case Types.GET_HABIT_UNITS_FAILED:
      return Object.assign({}, state, { loading: false });
    case Types.GET_HABITS_MOST_POPULAR_REQUEST:
      return Object.assign({}, state, { loading: true });
    case Types.GET_HABITS_MOST_POPULAR_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        listMostPopular: payload.data,
      });
    case Types.GET_HABITS_MOST_POPULAR_FAILED:
      return Object.assign({}, state, { loading: false });
    case Types.GET_HABITS_MOST_RECENT_REQUEST:
      return Object.assign({}, state, { loading: true });
    case Types.GET_HABITS_MOST_RECENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        listMostRecent: payload.data,
      });
    case Types.GET_HABITS_MOST_RECENT_FAILED:
      return Object.assign({}, state, { loading: false });
    case Types.CHANGE_HABITS_LIST_QUERY_PARAMS: {
      return Object.assign({}, state, { ...state.query, ...payload, loading: true });
    }
    case Types.GET_HABITS_CUSTOM_REQUEST:
      return Object.assign({}, state, { loading: true });
    case Types.GET_HABITS_CUSTOM_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        listCustom: payload.data,
        query: { ...state.query, ...payload },
        isEnd: payload.data.length === payload.total,
        total: payload.total,
      });
    case Types.GET_HABITS_YOUR_TEAM_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        listYourTeam: payload.data,
        query: { ...state.query, ...payload },
        isEnd: payload.data.length === payload.total,
        total: payload.total,
      });
    }
    case Types.GET_HABITS_CUSTOM_FAILED:
      return Object.assign({}, state, { loading: false });
    case Types.DELETE_HABIT_LIBRARY_REQUEST: {
      const newListCustom = state.listCustom.filter(item => item._id !== payload);
      const newListYourTeam = state.listYourTeam.filter(item => item._id !== payload);
      const newQuery = { ...state.query, total: state.total - 1 };
      return Object.assign({}, state, {
        listCustom: newListCustom,
        listYourTeam: newListYourTeam,
        query: newQuery,
        loading: false,
      });
    }
    // GET LIST HABIT OVERVIEW
    case Types.GET_HABIT_OVERVIEW_REQUEST: {
      return Object.assign({}, state, {
        habitOverview: { ...state.habitOverview, loading: true },
      });
    }
    case Types.GET_HABIT_OVERVIEW_SUCCESS: {
      return Object.assign({}, state, {
        habitOverview: { ...state.habitOverview, loading: false, data: payload },
      });
    }
    case Types.GET_HABIT_OVERVIEW_FAIL: {
      return Object.assign({}, state, {
        habitOverview: { ...state.habitOverview, loading: false },
      });
    }
    case Types.GET_HABITS_SYSTEM_REQUEST:
      return Object.assign({}, state, { loading: true });
    case Types.GET_HABITS_SYSTEM_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        listSystem: payload,
      });
    case Types.GET_HABITS_SYSTEM_FAILED:
      return Object.assign({}, state, { loading: false });

    default:
      return state;
  }
};
