import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Chart, Bar } from 'react-chartjs-2';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { pluralize } from 'utils/commonFunction';

// import containers
import * as S from './style';
import { extendChartBorderRadius } from 'helpers/chart';
import { ReactComponent as ShoeIcon } from 'assets/icons/shoe_orange.svg';
import AverageStepTooltip from '../AverageStepTooltip';
import GoalStepTooltip from '../GoalStepTooltip';
import { SWITCH_MODE } from 'shared/TimeSwitch';

Chart.Tooltip.positioners.center = function (elements) {
  const { x, y, base } = elements[0]._model;
  const height = base - y;
  return { x, y: y + height / 2 };
};

const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
const daysOfWeekExpand = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const datasetsDefault = {
  reps: {
    type: 'bar',
    label: '',
    backgroundColor: '#FF4C14',
    borderColor: '#FF4C14',
    hoverBorderColor: '#FF4C14',
    borderSkipped: 'bottom',
    hoverBorderWidth: 0,
    borderWidth: 0,
    data: [],
  },
};

const initChartData = (chartData, fromDate, toDate, currentMode) => {
  const labels = [];
  const days = currentMode === 'last14' ? 14 : 7;

  const startDate = ['last7', 'last14'].includes(currentMode)
    ? moment(fromDate, 'MM-DD-YYYY')
    : moment(fromDate, 'MM-DD-YYYY').startOf('isoWeek');
  const datasets = _.cloneDeep(datasetsDefault);
  const chartDataObj = _.mapKeys(chartData, 'day');

  _.range(0, days).forEach(day => {
    const thisDate = moment(startDate).add(day, 'day');
    const thisDay = moment(thisDate).format('MM-DD-YYYY');
    const label = _.upperCase(moment(thisDate, 'MM-DD-YYYY').format('MMDDYYYY'));
    labels.push(label);
    datasets.reps.data.push(_.get(chartDataObj, [thisDay, 'value']));
  });

  return {
    labels,
    datasets: _.values(datasets),
  };
};

const getAverageGoal = chartData => {
  const validData = _.filter(chartData || [], o => _.isNumber(o.value));
  const sum = _.sumBy(validData, 'value');
  return sum / validData.length;
};

class StepTrackingWeeklyChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.goalRef = React.createRef();
    this.averageRepsRef = React.createRef();
    this.dayRef = {};
  }

  componentDidMount() {
    this.handlePositionAnnotations();
    this.handlePositionTooltip();
  }

  UNSAFE_componentWillMount() {
    Chart.helpers.extend(Chart.elements.Rectangle.prototype, extendChartBorderRadius());
  }

  componentDidUpdate() {
    this.handlePositionAnnotations();
    this.handlePositionTooltip();
  }

  handlePositionAnnotations = () => {
    if (this.chartRef.current) {
      const { chartData, dailyGoal } = this.props;
      const stepGoal = dailyGoal || 0;
      const averageStep = getAverageGoal(chartData);
      const { chartInstance } = this.chartRef.current;
      const maxValue = chartInstance.scales['y-axis-0'].end;
      const heighYAxis = chartInstance.scales['y-axis-0'].height;

      let top;
      if (this.goalRef.current) {
        top = (heighYAxis / maxValue) * (maxValue - stepGoal);
        this.goalRef.current.style.top = `${top}px`;
      }
      if (this.averageRepsRef.current) {
        const topRestDay = (heighYAxis / maxValue) * (maxValue - averageStep) - 2;
        this.averageRepsRef.current.style.top = `${topRestDay}px`;
        if (Math.abs(topRestDay - top) < 12) {
          if (top <= topRestDay) {
            this.goalRef.current.style.top = `${top - (12 - Math.abs(topRestDay - top)) / 2}px`;
            this.averageRepsRef.current.style.top = `${topRestDay + (12 - Math.abs(topRestDay - top)) / 2}px`;
          } else {
            this.goalRef.current.style.top = `${top + (12 - Math.abs(topRestDay - top)) / 2}px`;
            this.averageRepsRef.current.style.top = `${topRestDay - (12 - Math.abs(topRestDay - top)) / 2}px`;
          }
        }
      }
    }
  };

  handlePositionTooltip = () => {
    const { currentMode } = this.props;

    if (this.chartRef.current) {
      const { chartInstance } = this.chartRef.current;
      const maxYValue = chartInstance.scales['y-axis-0'].end;
      const heighYAxis = chartInstance.scales['y-axis-0'].height;
      const widthXaxis = chartInstance.scales['x-axis-0'].width;
      const viewDaysOfWeek = currentMode === 'last14' ? daysOfWeekExpand : daysOfWeek;

      if (this.dayRef) {
        _.forEach(viewDaysOfWeek, day => {
          const refKey = `day_${day}`;
          const ref = this.dayRef[refKey];
          if (ref) {
            const value = Number(ref.dataset.value);
            ref.style.visibility = value > 0 ? 'visible' : 'hidden';
            const top = (heighYAxis / maxYValue) * (maxYValue - value);
            ref.style.top = `${top - 5}px`; // 5 is paddding bottom
            const right =
              (widthXaxis / viewDaysOfWeek.length) * (viewDaysOfWeek.length - day) - viewDaysOfWeek.length * 2;

            ref.style.right = `${right + 2}px`;
          }
        });
      }
    }
  };

  getFormatDay = (day, isShouldShowName) => {
    const { currentMode } = this.props;
    const _label = [SWITCH_MODE.LAST7, SWITCH_MODE.LAST14].includes(currentMode)
      ? isShouldShowName
        ? moment(day, 'MMDDYYYY').format('MMM D')
        : moment(day, 'MMDDYYYY').date()
      : moment(day, 'MMDDYYYY').format('ddd');
    return _label;
  };

  render() {
    const { chartData, dailyGoal, fromDate, toDate, currentMode, loadingChartData } = this.props;

    const data = initChartData(chartData, fromDate, null, currentMode);
    const stepGoal = dailyGoal || 0;
    const averageStep = getAverageGoal(chartData);
    const isCurrentWeek = [SWITCH_MODE.LAST7, SWITCH_MODE.LAST14].includes(currentMode)
      ? true
      : moment().isSame(moment(fromDate, 'MM-DD-YYYY'), 'isoWeek');
    const annotations = [
      {
        type: 'line',
        mode: 'vetical',
        drawTime: 'beforeDatasetsDraw',
        scaleID: 'x-axis-0',
        value: -0.5,
        borderColor: '#8A8A8A',
        borderWidth: 1,
        borderDash: [5, 3],
      },
    ];
    if (chartData && chartData.length) {
      annotations.push({
        type: 'line',
        drawTime: 'beforeDatasetsDraw',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: averageStep,
        borderColor: '#E9A38F',
        borderWidth: 1,
        borderDash: [5, 3],
      });
    }

    if (stepGoal > 0) {
      annotations.push({
        type: 'line',
        mode: 'horizontal',
        drawTime: 'beforeDatasetsDraw',
        scaleID: 'y-axis-0',
        value: stepGoal,
        borderColor: '#8A8A8A',
        borderWidth: 1,
        borderDash: [5, 3],
      });
    }

    return (
      <S.Wrapper>
        <div className="weekly-report__chart">
          {stepGoal > 0 && (
            <div ref={this.goalRef} className="weekly-report__goal-tooltip weekly-report__goal-tooltip--step-goal">
              <GoalStepTooltip id="step_goal" effect="float" place={'top'} delayShow={200} steps={stepGoal} />
              <div className="weekly-report__goal-tooltip--line" data-tip data-for="step_goal">
                &nbsp;
              </div>
            </div>
          )}
          {averageStep > 0 && (
            <div
              ref={this.averageRepsRef}
              className="weekly-report__goal-tooltip weekly-report__goal-tooltip--average-reps"
            >
              <AverageStepTooltip
                id="average_reps"
                effect="float"
                place={'top'}
                delayShow={200}
                steps={averageStep}
                fromDate={fromDate}
                toDate={toDate}
              />
              <div className="weekly-report__goal-tooltip--value" data-tip data-for="average_reps">
                <ShoeIcon />
                <div className="weekly-report__goal-tooltip--avgLine" />
              </div>
            </div>
          )}
          {(!chartData || !chartData.length) && !loadingChartData && <S.NoData>No data available</S.NoData>}
          <Bar
            ref={this.chartRef}
            data={data}
            height={300}
            width={700}
            options={{
              annotation: {
                events: ['mouseover', 'mouseleave'],
                annotations: annotations,
              },
              cornerRadius: 3,
              noRadiusBottom: true,
              borderSkipped: 'bottom',
              legend: {
                display: false,
              },
              tooltips: {
                // Disable the on-canvas tooltip
                enabled: false,

                custom: function (tooltipModel) {
                  // Tooltip Element
                  var tooltipEl = document.getElementById('chartjs-tooltip-steps');
                  var tooltipCaretEl = document.getElementById('chartjs-tooltip-caret');

                  // Create element on first render
                  if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip-steps';
                    tooltipEl.innerHTML = '<div id ="evf-tooltip"></div>';
                    document.body.appendChild(tooltipEl);
                  }
                  if (!tooltipCaretEl) {
                    tooltipCaretEl = document.createElement('div');
                    tooltipCaretEl.id = 'chartjs-tooltip-caret';
                    document.body.appendChild(tooltipCaretEl);
                  }
                  // Hide if no tooltip
                  if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    tooltipCaretEl.style.opacity = 0;
                    return;
                  }

                  // Set caret Position
                  tooltipEl.classList.remove('above', 'below', 'no-transform');
                  if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                  } else {
                    tooltipEl.classList.add('no-transform');
                  }

                  function getBody(bodyItem) {
                    return bodyItem.lines;
                  }

                  // Set Text
                  if (tooltipModel.body) {
                    var innerHtml = '<div>';
                    const day = moment(fromDate, 'MM-DD-YYYY')
                      .add(tooltipModel.dataPoints[0].index, 'day')
                      .format('MMM DD');
                    innerHtml += `${day} - <span style="font-weight: 600;">${Number(
                      tooltipModel.dataPoints[0].value,
                    ).toSeperateFormat()} ${pluralize('step', tooltipModel.dataPoints[0].value)}</span>`;
                    innerHtml += '</div>';

                    var tableRoot = tooltipEl.querySelector('#evf-tooltip');
                    if (tableRoot) {
                      tableRoot.innerHTML = innerHtml;
                    }
                  }

                  // `this` will be the overall tooltip
                  var position = this._chart.canvas.getBoundingClientRect();

                  /* FUNCTION TO GET BAR PROPS */
                  const getBARSegment = chart => {
                    const dataPoints = tooltipModel.dataPoints,
                      bar = chart.active[dataPoints[0].datasetIndex]._model,
                      canvasPosition = chart.canvas.getBoundingClientRect(),
                      paddingLeft = parseFloat(getComputedStyle(chart.canvas).paddingLeft),
                      paddingTop = parseFloat(getComputedStyle(chart.canvas).paddingTop),
                      scrollLeft = document.body.scrollLeft,
                      scrollTop = document.body.scrollTop;

                    return {
                      top: bar.y + canvasPosition.top + paddingTop + scrollTop,
                      left: bar.x - bar.width / 2 + canvasPosition.left + paddingLeft + scrollLeft,
                      width: bar.width,
                      height: bar.base - bar.y,
                    };
                  };

                  /* GET BAR PROPS (width, height, top, left) */
                  const barHeight = getBARSegment(this._chart).height,
                    barTop = getBARSegment(this._chart).top,
                    barWidth = getBARSegment(this._chart).width;
                  const topY = barTop;
                  const tooltipCaretElExpand = currentMode === 'last14' ? 12 : 5;

                  // Display, position, and set styles for font
                  tooltipEl.style.opacity = 1;
                  tooltipEl.style.position = 'absolute';

                  tooltipEl.style.left =
                    position.left + window.pageXOffset + tooltipModel.caretX - tooltipModel.width - barWidth / 2 + 'px';
                  tooltipEl.style.top = topY - tooltipModel.height + 2 + 'px';
                  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                  tooltipEl.style.padding = '6px 10px';
                  tooltipEl.style.pointerEvents = 'none';
                  tooltipEl.style.background = '#2D2E2D';
                  tooltipEl.style.textAlign = 'left';
                  tooltipEl.style.fontSize = '13px';
                  tooltipEl.style.fontWeight = '700';
                  tooltipEl.style.lineHeight = '19px';
                  tooltipEl.style.borderRadius = '5px';
                  // Display, position, and set styles for caret
                  tooltipCaretEl.style.opacity = 1;
                  tooltipCaretEl.style.position = 'absolute';
                  tooltipCaretEl.style.left =
                    position.left +
                    window.pageXOffset +
                    tooltipModel.caretX +
                    tooltipModel.caretSize +
                    tooltipModel.xPadding -
                    barWidth / 2 -
                    tooltipCaretElExpand +
                    'px';
                  // tooltipCaretEl.style.width = 0;
                  // tooltipCaretEl.style.height = 0;
                  tooltipCaretEl.style.top = topY - 13 + 'px';
                  tooltipCaretEl.style.pointerEvents = 'none';
                  tooltipCaretEl.style.borderStyle = 'solid';
                  tooltipCaretEl.style.borderWidth = '9px 8px 0';
                  tooltipCaretEl.style.borderColor = '#2D2E2D transparent transparent transparent';
                },
              },
              drawOnChartArea: false,
              // responsive: true,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      drawOnChartArea: false,
                      drawTicks: false,
                    },
                    stacked: true,
                    barPercentage: 0.3,
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#20235350',
                      fontSize: 12,
                      padding: 20,
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: false,
                      drawOnChartArea: false,
                    },
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#20235350',
                      fontSize: 12,
                      fontStyle: 'bold',
                      suggestedMax: (averageStep > stepGoal ? averageStep : stepGoal) + 10,
                      padding: 20,
                      callback: function (label) {
                        return label === 0 ? '' : Number(label).toSeperateFormat();
                      },
                    },
                  },
                ],
              },
              layout: {
                padding: {
                  bottom: 60,
                },
              },
            }}
            plugins={[
              ChartAnnotation,
              {
                afterDraw: chart => {
                  if (isCurrentWeek) {
                    let ctx = chart.chart.ctx;
                    let xAxis = chart.scales['x-axis-0'];
                    let yAxis = chart.scales['y-axis-0'];
                    const currentDay = moment().format('MMDDYYYY');
                    chart.data.labels.forEach((label, i) => {
                      const showName = i === 0 || i === chart.data.labels.length - 1;
                      let x = xAxis.getPixelForValue(label);
                      ctx.save();
                      ctx.translate(x, yAxis.bottom + 34);

                      if (currentDay === label) {
                        ctx.font = 'bold 12px Open Sans';
                        ctx.fillStyle = '#5158CF';
                      } else {
                        ctx.font = '600 12px Open Sans';
                        ctx.fillStyle = '#20235350';
                      }

                      const _label = this.getFormatDay(label, showName);

                      const width = ctx.measureText(_label).width;
                      ctx.fillText(_label, -width / 2 + 2, 0);
                      ctx.restore();
                    });
                  }
                },
              },
            ]}
          />
        </div>
      </S.Wrapper>
    );
  }
}
const mapState = state => {
  const {
    rootReducer: {
      clientStep: { loadingChartData },
    },
  } = state;

  return { loadingChartData };
};

export default connect(mapState, null)(StepTrackingWeeklyChart);
