import React, { useEffect, useState } from 'react';
import { toggleModal } from 'actions/modal';
import { toggleConfirmModal } from 'actions/modal';
import { readAllCommentFormAnswer } from 'redux/form-responses/actions';
import { CDN_URL, KEY_CODE } from 'constants/commonData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import { Button as CloseButton } from 'semantic-ui-react';
import CommentsList from './CommentsList';
import * as S from './style';

const CommentModal = ({ toggleModal, toggleConfirmModal, id, push, match, readAllCommentFormAnswer }) => {
  const [uploadedAllComment, setUploadedAllComment] = useState(true);
  const formId = match && get(match, 'params.id', '');

  useEffect(() => {
    if (id) readAllCommentFormAnswer(id);
  }, [id]);

  const handleClose = () => {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy) return null;
    if (!uploadedAllComment) {
      return toggleConfirmModal(
        true,
        <S.CommentConfirmModal
          noIcon
          title="Upload in progress"
          content="If you close the pop-up, all incomplete uploads will be discarded. Would you like to exit without finishing?"
          onConfirm={() => {
            push && formId && push(`/home/forms/${formId}/responses`);
            toggleModal(false);
          }}
          newStyle={true}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          noBorder
          hasHoverState
        />,
      );
    } else {
      push && formId && push(`/home/forms/${formId}/responses`);
      return toggleModal(false);
    }
  };

  return (
    <S.WrapperCommentModal
      open={true}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon={
        <CloseButton className="close-button" data-btn="close">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <CommentsList showing={true} taskId={id} />
    </S.WrapperCommentModal>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  readAllCommentFormAnswer: bindActionCreators(readAllCommentFormAnswer, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentModal);
