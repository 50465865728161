import styled, { css } from 'styled-components';
import S3Images from 'shared/S3Images';

const limitText = `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

const textBase = `
    font-family: 'Open Sans';
    color: #202353;
    font-style: normal;
    line-height: 150%;
`;

export const ContentTabWrapper = styled.div`
  height: 100%;
`;

export const ContentTabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  padding: 0 30px;
  &.cart {
    padding-bottom: 7px;
  }
  &.list {
    padding-bottom: 10px;
  }
`;

export const HeaderTitle = styled.div`
  ${textBase}
  color: #7b7e91;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const HeaderShowList = styled.div`
  display: flex;
`;

export const HeaderShowIcon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .show-gallery,
  .show-list {
    flex-shrink: 0;
    margin: 4px;
  }
  &.selected {
    svg.show-gallery path,
    svg.show-list path {
      fill: #5158cf;
    }
  }
  .__react_component_tooltip.display-view-tooltip {
    border-radius: 5px;
    background: #333;
    padding: 8px 10px;
    &.type-dark.place-top:after {
      border-top-color: #333;
    }
    &.place-top {
      margin-top: -9px;
    }
    &.show-gallery-tooltip {
      left: calc(100% - 107px) !important;
      &.place-top::after {
        margin-left: 13px;
      }
    }
    div {
      ${textBase}
      color: #fff;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const ContentListWrapper = styled.div`
  display: grid;
  overflow-y: scroll;
  height: calc(100% - 270px);
  padding: 0 17px 30px 30px;
  margin-right: 7px;
  align-content: start;

  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e1e1ea !important;
  }
  &.cart {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;

    @media screen and (min-width: 1750px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  &.list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 10px;
  }
  ${props =>
    props.isEmpty &&
    css`
      display: block;
    `}
`;

export const ContentItemThumbnail = styled(S3Images)`
  &.image-with-fallback {
    flex-shrink: 0;
    height: 108px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0 0;
      object-fit: cover;
    }
  }
`;

export const ContentItemInfo = styled.div`
  height: 80px;
  padding: 15px;
  border: 1px solid #f0f0f2;
  border-top: unset;
  border-radius: 0 0 5px 5px;
`;

export const ContentItemTitle = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  ${limitText}
`;

export const ContentItemWrapper = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  .add-circle-icon {
    display: none;
  }
  &.list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    padding: 9px;
    border: 1px solid #f0f0f2;
    border-radius: 10px;
    ${ContentItemThumbnail} {
      height: 70px;
      width: 105px;
      flex-shrink: 0;
      img {
        border-radius: 5px;
      }
    }
    ${ContentItemInfo} {
      height: auto;
      border: unset;
      border-radius: unset;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    ${ContentItemTitle} {
      padding-right: 10px;
    }
    .add-circle-icon {
      position: absolute;
      top: 50%;
      left: 90%;
      transform: translate(-26%, -50%);
    }
  }
  &.cart {
    .add-circle-icon {
      position: absolute;
      top: 55%;
      left: 83%;
      transform: translate(-50%, -50%);
    }
  }
  &:hover {
    ::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 2;
      border: 1px solid #5158cf;
    }
    .add-circle-icon {
      display: block;
      z-index: 3;
    }
  }
  &.list:hover {
    ::after {
      border-radius: 10px;
    }
  }
  &.cart:hover {
    ::after {
      border-radius: 5px;
    }
  }
  .app-tooltip.recipe-item-tooltip {
    background-color: #333333;
    border-radius: 4px;
    padding: 8px 10px;
    ${textBase}
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    ::after {
      border-top-color: #333333 !important;
      bottom: -8px !important;
    }
  }
  svg.add-circle-icon.disabled {
    cursor: not-allowed;
    path {
      fill: #a3a3b5;
    }
  }
`;

export const ContentEmpty = styled.div`
  width: 100%;
  height: 100%;
  padding: 195px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ContentNoResult = styled.div`
  ${textBase}
  font-size: 15px;
  font-weight: 600;
`;

export const ContentSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  .skeleton-thumbnail {
    height: 108px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    background-color: #f8f8f8;
  }
  .skeleton-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 79px;
    padding: 10px 15px 15px;
    border: 1px solid #f0f0f2;
    border-top: unset;
    border-radius: 0 0 5px 5px;
  }
  .skeleton-name {
    height: 18px;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
  &.list {
    padding: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    border: 1px solid #f0f0f2;
    border-radius: 10px;
    .skeleton-thumbnail {
      flex-shrink: 0;
      height: 70px;
      width: 105px;
      border-radius: 5px;
    }
    .skeleton-info {
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
      width: 100%;
      border: unset;
      padding: unset;
    }
  }
`;
