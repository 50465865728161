import styled from 'styled-components';

export const ConfirmPaymentWrapper = styled.div`
  max-width: 880px;
  min-width: 880px;

  .confirmPayment__body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 40px;

    &--paymentMethod {
      width: 62.5%
    }
    &--summary {
      margin-left: 20px;
      width: 37.5%
    }
  }
`;

export const ErrorContainer = styled.div`
  background: #FEF5F6;
  padding: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  text-align: center;

  color: #EA314A;
`;