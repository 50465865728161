/**
 * @flow
 */

import { toggleConfirmModal } from 'actions/modal';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  copyWorkout,
  copyWorkoutToDate,
  saveWorkoutToWorkoutLibrary,
  removeWorkoutFromDate,
  arrangeProgramLibraryWorkout,
  updateExerciseLibraryFromProgramCalendar,
  handleSelectWorkout,
  handleResetSelectWorkout,
  handleResetSelectedWeek,
  handlePasteMultipleWorkout,
  resetCopyItem,
} from 'redux/program_library/program_library.actionCreators';
import { Workout } from 'types/model';
import ProgramCalendarCellComponent from './component';

const mapStateToProps = (state, ownProps) => {
  const { allExercises, isModalOpen, modal, popup, movingSet, rootReducer } = state;

  const {
    program_library,
    program_library: { selectedWorkout, selectedWeek },
    permission,
  } = rootReducer;
  let currentWorkout;
  let currentWorkouts;

  if (program_library.selected) {
    let sets = program_library.selected.workout_sets;
    let dayIndex = ownProps.dayIndex;
    let weekIndex = ownProps.weekIndex;
    if (weekIndex >= 0 && sets.length > weekIndex) {
      let workouts = sets[weekIndex].days_workout;

      let filter =
        workouts &&
        workouts.find(item => {
          return item && item.day_index === dayIndex;
        });
      if (filter && filter.day_workout.workouts && filter.day_workout.workouts.length > 0) {
        currentWorkouts = [];
        for (let i = 0; i < filter.day_workout.workouts.length; i++) {
          currentWorkout = new Workout();
          currentWorkout.parseFromWorkout(filter.day_workout.workouts[i]);
          currentWorkouts.push(currentWorkout);
        }
      }
    }
  }

  return {
    selectedWorkout,
    selectedWeek,
    allExercises,
    currentWorkouts,
    isModalOpen,
    modal,
    permission,
    selectedClient: rootReducer.client.selected,
    popup,
    movingSet,
    totalWeek: program_library.selected_total_week,
    selectedProgram: program_library.selected,
    calendarType: program_library.calendar_type,
    copyingWorkout: program_library.copying_workout,
    isCopingWorkouts: program_library.isCopy,
    isLoadingWorkouts: program_library.isLoadingWorkouts,
    currentWeek: program_library.selected_current_week,
  };
};

const copyCurrenWorkout = (dispatch, assignment) => {
  dispatch(copyWorkout(assignment));
};

const removeWorkout = (dispatch, params) => {
  dispatch(removeWorkoutFromDate(params));
};

const arrangeWorkout = (dispatch, params) => {
  dispatch(arrangeProgramLibraryWorkout(params));
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    arrangeWorkout: params => arrangeWorkout(dispatch, params),
    copyCurrenWorkout: assignment => copyCurrenWorkout(dispatch, assignment),
    pasteCopiedWorkout: (params, multiPaste) => dispatch(copyWorkoutToDate(params, multiPaste)),
    removeWorkout: params => removeWorkout(dispatch, params),
    saveItemToWorkoutLibrary: workout => {
      let params = {
        workoutId: workout._id,
      };

      dispatch(saveWorkoutToWorkoutLibrary(params));
    },
    updateExerciseLibraryFromProgramCalendar: bindActionCreators(updateExerciseLibraryFromProgramCalendar, dispatch),
    handleSelectWorkout: bindActionCreators(handleSelectWorkout, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    handleResetSelectWorkout: bindActionCreators(handleResetSelectWorkout, dispatch),
    handleResetSelectedWeek: bindActionCreators(handleResetSelectedWeek, dispatch),
    handlePasteMultipleWorkout: bindActionCreators(handlePasteMultipleWorkout, dispatch),
    resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCalendarCellComponent);
