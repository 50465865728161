import styled, { css } from 'styled-components';

export const AddMetricLabel = styled.div`
  color: #202353;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const AddNewMetricBox = styled.div`
  position: relative;
  cursor: pointer;
  width: 345px;
  height: 295px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: #f0f0f2;

  .icon-plus path {
    fill: #202353;
  }

  &:hover {
    background: #f0f1ff;

    .icon-plus path {
      fill: #5158cf;
    }

    ${AddMetricLabel} {
      color: #5158cf;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 459px;

  .text-editable__input {
    color: #202353;
    font-family: 'Open Sans';
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    :empty::before {
      color: #a3a3b5;
      font-family: 'Open Sans';
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .text-editable__container {
    padding: 0px 10px;
    border-radius: 5px;
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }

  .text-editable__container:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
    background-color: #fff !important;
    border: 1px solid #5158cf;
  }

  .nameInput__warning {
    position: absolute;
    top: calc(100% + 3px);
    left: 10px;
    display: inline-flex;

    &.full-limit {
      .nameInput__warning--text {
        color: #ea314a;
      }
    }

    &--text {
      color: #7b7e91;
      font-family: 'Open Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;

export const HeaderInput = styled.input`
  outline: unset;
  padding: 0 10px;
  line-height: 40px !important;
  color: #202353;
  font-size: 19px;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 5px;
  background: transparent;
  max-width: 459px;
  margin-bottom: 2px;
  width: 100%;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:focus,
      &:hover {
        border: 1px solid #5158cf;
        background: #fff;
      }
    `}

  ::placeholder {
    font-size: 19px;
    color: #a3a3b5;
    font-weight: 400 !important;
  }
`;
