// libs
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';

// actions
import { axiosInstance } from 'configs/request';
import {
  archiveOnboardingFlow,
  changeOnboardingQuery,
  deleteOnboardingFlow,
  duplicateOnboardingFlow,
  fetchOnboardingLibrary,
  unarchiveOnboardingFlow,
  updateOnboardingFlowSharingStatus,
  fetchDefaultOnboardingLibrary,
} from 'redux/onboarding-flow-library/actions';
import { editOnboardingFlow } from 'redux/onboarding-flow/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

// shared
import OwnerAvatar from 'shared/OwnerAvatar';
import SharingStatus from 'shared/SharingStatus';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

// components
import LoadingIndicator from 'shared/LoadingIndicator';
import Pagination from '../Pagination';
import ListEmpty from './ListEmpty';
import ConfirmModal from 'components/OnboardingFlowDetail/components/OnboardingFlowSettings/ConfirmModal';
import ModalShareOwner from 'components/TasksLibrary/ModalShareOwner';

// constants
import { columns, defaultOnboardingFlowColumns } from './columns';
import { isTeamAdmin, formatSameYear, getUserShortName, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { ONBOARDING_FLOW_STATUS } from 'components/OnboardingFlowDetail/constants';

// assets
import { ReactComponent as ToolHandIcon } from 'assets/icons/OnboardingFlow/tool_hand.svg';
import { ReactComponent as EditIcon } from 'assets/icons/MealPlans/action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/MealPlans/action_duplicate.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/action_archive.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/MealPlans/action_share.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';
import { ReactComponent as StarOutlineIcon } from 'assets/icons/OnboardingFlow/star_outline.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/form-list-view-white.svg';

// styles
import * as S from './style';

const TableList = props => {
  const {
    list,
    user,
    push,
    total,
    query = {},
    isLoading,
    currentTab,
    listDefault,
    toggleModal,
    isAddLoading,
    toggleConfirmModal,
    editOnboardingFlow,
    deleteOnboardingFlow,
    archiveOnboardingFlow,
    changeOnboardingQuery,
    fetchOnboardingLibrary,
    duplicateOnboardingFlow,
    unarchiveOnboardingFlow,
    updateOnboardingFlowSharingStatus,
    fetchDefaultOnboardingLibrary,
    cloudfrontList,
  } = props;
  const { sorter, sort } = query || { sorter: 'updated_at', sort: -1 };

  const isOnboardingFlowsTab = currentTab === 0;
  const isDefaultOnboardingFlow = currentTab === 3;
  const isSearch = query.text_search.trim().length > 0;

  const [isFetchLoading, setIsFetchLoading] = useState(false);

  const tableHeading = useMemo(() => {
    return columns.map(item => {
      if (item.key === 'name_lowercase') {
        return { ...item, icon: <ToolHandIcon />, title: item.title + ` (${total})` };
      }
      return item;
    });
  }, [list]);

  const tableDefaultHeading = useMemo(() => {
    return columns.map(item => {
      if (item.key === 'name_lowercase') {
        return { ...item, icon: <ToolHandIcon />, title: item.title + ` (${listDefault.length})`, sortable: false };
      }
      return { ...item, sortable: false };
    });
  }, [list]);

  useEffect(() => {
    if (isDefaultOnboardingFlow) {
      fetchDefaultOnboardingLibrary({ ...query, sorter: 'full_name' });
    } else {
      fetchOnboardingLibrary({
        ...query,
        only_my_onboarding_flows: currentTab === 1 || undefined,
        only_archived_onboarding_flows: currentTab === 2 || undefined,
      });
    }
  }, [query]);

  const handleSort = item => () => {
    if (!item.sortable || list.length === 0) return;

    changeOnboardingQuery({
      ...query,
      sorter: item.sortKey,
      sort: query.sort === 1 ? -1 : 1,
    });
  };

  const handleEdit = item => {
    push(`/home/onboarding-flow/${(item || {})._id}`);
    editOnboardingFlow();
  };

  const handleDuplicate = item => {
    if (isAddLoading) return;

    const { _id, name, author } = item || {};
    const data = {
      name: `Copied - ${name}`,
      owner: (author || {})._id,
      share: TEAM_SHARE_PRIVATE,
    };

    typeof duplicateOnboardingFlow === 'function' && duplicateOnboardingFlow(_id, data);
  };

  const handleArchive = item => {
    if (isAddLoading) return;

    const { _id, name } = item || {};

    typeof archiveOnboardingFlow === 'function' &&
      archiveOnboardingFlow(_id, () => {
        toast(`'${name}' has been archived.`);
      });
  };

  const handleUnarchive = item => {
    if (isAddLoading) return;

    const { _id, name } = item || {};

    typeof unarchiveOnboardingFlow === 'function' &&
      unarchiveOnboardingFlow(_id, () => {
        toast(`'${name}' has been unarchived.`);
      });
  };

  const handleUpdateSharing = params => {
    if (isAddLoading) return;

    const { onboardingFlowId, owner, share } = params || {};

    const data = {
      owner,
      share,
    };

    typeof updateOnboardingFlowSharingStatus === 'function' &&
      updateOnboardingFlowSharingStatus(onboardingFlowId, data, () => {
        typeof toggleModal === 'function' && toggleModal(false);
      });
  };

  const handleRemove = item => {
    if (isAddLoading) return;

    typeof deleteOnboardingFlow === 'function' &&
      deleteOnboardingFlow((item || {})._id, () => {
        toast('Onboarding Flow has been removed.');
        typeof toggleModal === 'function' && toggleModal(false);
      });
  };

  const handleRedirectToDetail = item => {
    push(`/home/onboarding-flow/${(item || {})._id}`);
  };

  const handleOpenArchiveModal = item => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Archive Onboarding Flow"
        content="Archiving this onboarding flow will remove access to it for all associated coaches and packages. Clients who have purchased the package already will not be affected. Would you like to continue?"
        headerIcon={`${CDN_URL}/images/archive_light_package.svg`}
        confirmBtnTitle="Yes"
        className="multiple-popup-purple"
        onConfirm={() => handleArchive(item)}
        onClose={() => toggleConfirmModal(false)}
      />,
    );
  };

  const handleOpenSharingModal = async (e, item) => {
    e.stopPropagation();
    const { share, author } = item || {};

    const isOwner = get(author, '_id') === (user || {})._id;
    const canAction = isTeamAdmin(user) || isOwner || share === TEAM_SHARE_NOOWNER;
    let hasAtLeastValidField = false;

    try {
      const response = await axiosInstance.get(`/api/onboarding-flow-settings/${(item || {})._id}`);
      const { data } = get(response, 'data', {});

      if (!isEmpty(data)) {
        const { invite_codes, packages, trainer_default_settings } = data;

        const hasInviteCodes = invite_codes.length > 0;
        const hasPackages = packages.length > 0;
        const hasTrainerDefaultSettings = trainer_default_settings.length > 0;

        hasAtLeastValidField = hasInviteCodes || hasPackages || hasTrainerDefaultSettings;
      }
    } catch (error) {
      // TODO: Handle error
    } finally {
      setIsFetchLoading(false);
    }

    toggleModal(
      canAction,
      <ModalShareOwner
        isShowCancelButton={true}
        isHideCloseButton={true}
        toggleModal={toggleModal}
        workingItem={item}
        user={user}
        type="update"
        onSubmit={params => {
          const isChangeToPrivate =
            share !== TEAM_SHARE_PRIVATE && params.share === TEAM_SHARE_PRIVATE && share !== params.share;

          if (hasAtLeastValidField && isChangeToPrivate) {
            handleOpenConfirmModal(params);
          } else {
            handleUpdateSharing(params);
          }
        }}
        headerTitle="Sharing Settings"
        disabled={isAddLoading || isFetchLoading}
        isOnboardingFlow
      />,
    );
  };

  const handleOpenRemoveModal = item => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete Onboarding Flow"
        content="This onboarding flow will be permanently deleted from your account. Would you like to continue?"
        headerIcon={`${CDN_URL}/images/trash-circle.svg`}
        confirmBtnTitle="Delete"
        className="multiple-popup-red remove-popup"
        onConfirm={() => handleRemove(item)}
        onClose={() => toggleConfirmModal(false)}
      />,
    );
  };

  const handleOpenConfirmModal = params => {
    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Set Onboarding Flow to Private"
          content="This will revoke access for all coaches in the workspace, and any coaches currently added to the onboarding flow will be removed. Would you like to continue?"
          headerIcon={`${CDN_URL}/images/alert_recycle.svg`}
          confirmBtnTitle="Yes"
          className="multiple-popup-yellow"
          onConfirm={() => handleUpdateSharing(params)}
          onClose={() => toggleConfirmModal(false)}
        />,
      );
  };

  const renderHeadItem = item => {
    const { key, title, sortable, sortKey: columnSortKey, icon: columnIcon } = item;
    let sortClass = '';

    if (sortable) {
      sortClass = sorter === columnSortKey ? 'chevron active ' : 'chevron ';
      sortClass += sort === 1 ? 'up' : 'down';
    }

    return (
      <th
        key={key}
        onClick={handleSort(item)}
        className={classNames({ sortable: item.sortable, active: columnSortKey === sorter })}
      >
        {!!columnIcon && <span className="column-icon">{columnIcon}</span>}
        <span className="column-title">{title}</span>
        {sortable && (
          <span className="column-sort-icon" click>
            <Icon className={sortClass} />
          </span>
        )}
      </th>
    );
  };

  const renderList = list => {
    if (!isOnboardingFlowsTab && (isLoading || list.length === 0)) {
      return (
        <tr className={classNames({ 'is-loading': !isOnboardingFlowsTab && (isLoading || list.length === 0) })}>
          <td colSpan={5} className="loading-empty">
            {isLoading ? (
              <LoadingIndicator title="Loading..." />
            ) : (
              <ListEmpty isSearch={isSearch} isAchieved={currentTab === 2} />
            )}
          </td>
        </tr>
      );
    }

    return list.map(isDefaultOnboardingFlow ? renderItemDefault : renderItem);
  };

  const renderItem = item => {
    const {
      _id = '',
      name = '',
      total_invite_codes,
      total_packages,
      author,
      status,
      share,
      updated_at,
      is_default_team,
      is_my_default,
    } = item || {};

    const hasTeam = !!user.team_member && user.team_member.length > 1;
    const owner =
      share !== TEAM_SHARE_NOOWNER && hasTeam
        ? find(user.team_member, item => item._id === (get(author, '_id', '') || author))
        : null;

    return (
      <tr key={_id} onClick={e => handleRedirectToDetail(item)}>
        <td>
          {status === 'publish' ? (
            <span className="status status__publish" />
          ) : (
            <span className="status status__draft" />
          )}
          <span className="col-name">
            <span className={classNames('name', { 'name-default': is_default_team || is_my_default })}>{name}</span>
            {(is_default_team || is_my_default) && (
              <>
                <span className="name-tag" data-tip data-for={`name-tag-tooltip-${_id}`}>
                  {is_default_team ? 'Workspace Default' : is_my_default ? 'Your Default' : ''}
                </span>
                <ReactTooltip
                  className="app-tooltip name-tag-tooltip"
                  id={`name-tag-tooltip-${_id}`}
                  effect="solid"
                  place="top"
                >
                  <p>
                    {is_default_team
                      ? "All your new clients will be assigned to the workspace default onboarding flow if you don't set your default one."
                      : 'All your new clients will be assigned to this onboarding flow unless a different onboarding flow is specified.'}
                  </p>
                </ReactTooltip>
              </>
            )}
          </span>
        </td>
        <td>
          <span className="number">
            {total_invite_codes > 0 ? `${total_invite_codes} ${total_invite_codes === 1 ? 'Coach' : 'Coaches'}` : '-'}
          </span>
        </td>
        <td>
          <span className="number">
            {total_packages > 0 ? `${total_packages} ${total_packages === 1 ? 'Package' : 'Packages'}` : '-'}
          </span>
        </td>
        <td>
          <span className="last_updated">{updated_at ? formatSameYear(updated_at) : '-'}</span>
        </td>
        <td>
          <div className="actions">
            {hasTeam ? (
              <>
                {owner ? (
                  <S.OwnerAvatarWrapper>
                    <OwnerAvatar
                      data={owner}
                      tooltipId={`tooltip--owner-${(item || {})._id}`}
                      size={25}
                      className="owner-avatar"
                      cloudfrontList={cloudfrontList}
                    />
                  </S.OwnerAvatarWrapper>
                ) : null}
                <SharingStatus
                  place="top"
                  share={share}
                  itemId={(item || {})._id}
                  onClick={e => {
                    e.stopPropagation();
                    handleOpenSharingModal(e, item);
                  }}
                />
              </>
            ) : null}
            {renderActions(item)}
          </div>
        </td>
      </tr>
    );
  };

  const renderItemDefault = item => {
    const {
      _id = '',
      full_name = '',
      color = '',
      avatar = '',
      default_onboarding_flow = {},
      first_name = '',
      last_name = '',
    } = item || {};
    const { name: nameOnboardingFlow = '' } = default_onboarding_flow || {};

    return (
      <tr key={_id} className="default-onboarding-tab">
        <td className="coach-name">
          <Avatar
            name={getUserShortName({ full_name, first_name, last_name })}
            size={32}
            src={convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true)}
            color={color}
          />
          <span>{full_name}</span>
        </td>
        <td>
          <span className="text-onboarding-flow">{nameOnboardingFlow || '-'}</span>
        </td>
      </tr>
    );
  };

  const renderActions = item => {
    const canAction =
      isTeamAdmin(user) ||
      get(item, 'author._id', '') === get(user, '_id', '') ||
      get(item, 'share', TEAM_SHARE_PRIVATE) === TEAM_SHARE_NOOWNER;
    const isDraft = get(item, 'status', '') === ONBOARDING_FLOW_STATUS.DRAFT;

    return (
      <DropDown
        className="custom-dropdown-actions"
        triggerIcon={({ open }) => <MenuTrigger vertical active={!!open} itemId={(item || {})._id} />}
        isMealPlanAndRecipe
      >
        {canAction && (
          <Option key="edit" onClick={() => handleEdit(item)}>
            <S.OptionIcon className="icon">
              <EditIcon className="onboarding-edit-icon" />
            </S.OptionIcon>
            <span>Edit</span>
          </Option>
        )}
        <Option key="duplicate" onClick={() => handleDuplicate(item)}>
          <S.OptionIcon className="icon">
            <DuplicateIcon className="onboarding-duplicate-icon" />
          </S.OptionIcon>
          <span>Duplicate</span>
        </Option>
        {canAction && (
          <Option
            key="archive"
            onClick={() => {
              if (get(item, 'is_archived')) {
                handleUnarchive(item);
              } else {
                handleOpenArchiveModal(item);
              }
            }}
          >
            <S.OptionIcon className="icon">
              <ArchiveIcon className="onboarding-archive-icon" />
            </S.OptionIcon>
            <span>{get(item, 'is_archived') ? 'Unarchive' : 'Archive'}</span>
          </Option>
        )}
        {canAction && (
          <Option key="update-share" onClick={e => handleOpenSharingModal(e, item)}>
            <S.OptionIcon className="icon">
              <ShareIcon className="onboarding-share-icon" />
            </S.OptionIcon>
            <span>Sharing settings</span>
          </Option>
        )}
        {canAction && isDraft && (
          <Option onClick={() => handleOpenRemoveModal(item)}>
            <S.OptionIcon className="icon">
              <RemoveIcon className="onboarding-remove-icon" />
            </S.OptionIcon>
            <span>Delete</span>
          </Option>
        )}
      </DropDown>
    );
  };

  const SectionComponent = ({ icon: Icon, iconClassName, label, children }) => {
    return (
      <S.SectionWrapper>
        <S.SectionHeader>
          <Icon className={iconClassName} />
          <span className="section-label">{label}</span>
        </S.SectionHeader>
        {children}
      </S.SectionWrapper>
    );
  };

  const renderTable = (heading, list) => {
    return (
      <table>
        <thead>
          <tr>{heading.map(renderHeadItem)}</tr>
        </thead>
        <tbody>{renderList(list)}</tbody>
      </table>
    );
  };

  return (
    <>
      {isOnboardingFlowsTab ? (
        <S.Wrapper>
          <S.NewContainer isLoading={isLoading} isEmpty={listDefault.length === 0 && list.length === 0}>
            {!isLoading && (
              <>
                {listDefault.length > 0 && (
                  <SectionComponent
                    label="Default"
                    icon={StarOutlineIcon}
                    iconClassName="star-icon"
                    children={
                      <S.NewTableWrapper className="section-default">
                        {renderTable(tableDefaultHeading, listDefault)}
                      </S.NewTableWrapper>
                    }
                  />
                )}
                {list.length > 0 && (
                  <SectionComponent
                    label="General"
                    icon={MenuIcon}
                    iconClassName="menu-icon"
                    children={
                      <S.NewTableWrapper className="section-general">
                        {renderTable(tableHeading, list)}
                      </S.NewTableWrapper>
                    }
                  />
                )}
                {listDefault.length === 0 && list.length === 0 && <ListEmpty isSearch={isSearch} />}
                {total > 20 && <Pagination />}
              </>
            )}
            {isLoading ? <LoadingIndicator title="Loading..." className="custom-loading" /> : null}
          </S.NewContainer>
        </S.Wrapper>
      ) : (
        <S.Container hasPagination={total > 20}>
          <S.TableWrapper className={classNames({ 'default-onboarding-flow': isDefaultOnboardingFlow })}>
            {renderTable(isDefaultOnboardingFlow ? defaultOnboardingFlowColumns : tableHeading, list)}
          </S.TableWrapper>
        </S.Container>
      )}
    </>
  );
};

const mapStateToProps = ({ user, rootReducer: { onboardingLibrary }, cloudfrontList }) => {
  const { currentTab, filters, isLoading, list, total, isAddLoading, listDefault } = onboardingLibrary || {};

  return {
    user,
    currentTab,
    query: filters,
    isLoading,
    list,
    total,
    isAddLoading,
    cloudfrontList,
    listDefault,
  };
};

const mapDispatchToProps = dispatch => ({
  changeOnboardingQuery: bindActionCreators(changeOnboardingQuery, dispatch),
  fetchOnboardingLibrary: bindActionCreators(fetchOnboardingLibrary, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  push: bindActionCreators(push, dispatch),
  updateOnboardingFlowSharingStatus: bindActionCreators(updateOnboardingFlowSharingStatus, dispatch),
  deleteOnboardingFlow: bindActionCreators(deleteOnboardingFlow, dispatch),
  editOnboardingFlow: bindActionCreators(editOnboardingFlow, dispatch),
  duplicateOnboardingFlow: bindActionCreators(duplicateOnboardingFlow, dispatch),
  archiveOnboardingFlow: bindActionCreators(archiveOnboardingFlow, dispatch),
  unarchiveOnboardingFlow: bindActionCreators(unarchiveOnboardingFlow, dispatch),
  fetchDefaultOnboardingLibrary: bindActionCreators(fetchDefaultOnboardingLibrary, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
