import React, { useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Teammates from './Teammates';
import { getAllRoomsByAdmin, getAllRooms, resetAllInbox } from 'redux/inbox-new/actions';

const MembersContainer = ({ user, getAllRoomsByAdmin, getAllRooms, loaded, resetAllInbox, cloudfrontList }) => {
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    const owner = teams.find(item => item.isMe);
    setSelectedMember(owner);
    return () => {
      resetAllInbox();
    };
  }, []);

  const oldTeamIds = useMemo(() => {
    return user.team_old_members.map(it => it._id) || [];
  }, [user.team_old_members]);

  const teams = useMemo(() => {
    const all = [...user.team_member, ...user.team_old_members];
    return all.map(item => ({
      ...item,
      key: item._id,
      value: item._id,
      label: `${item.first_name} ${item.last_name}`,
      isMe: item._id === user._id,
      isRemoved: oldTeamIds.includes(item._id),
    }));
  }, [user.team_member, user.team_old_members]);

  const options = useMemo(() => {
    const owner = teams.find(item => item.isMe);
    return { label: owner.label, avatar: owner.avatar, options: teams.filter(item => !item.isMe) };
  }, [user]);

  const owner = useMemo(() => {
    return teams.find(item => item.isMe);
  }, [user]);

  const handleSelectTeam = item => {
    const convosTeam = get(user, `teams[${0}].team._id`);
    item.isMe ? getAllRooms(null, null, true) : getAllRoomsByAdmin(convosTeam, item);
    setSelectedMember(item);
  };

  return (
    <Teammates
      options={[options]}
      onSelect={handleSelectTeam}
      selected={selectedMember}
      owner={owner}
      loaded={loaded}
      cloudfrontList={cloudfrontList}
    />
  );
};

const mapState = state => {
  const {
    user,
    cloudfrontList,
    rootReducer: {
      inboxNew: { loadedRooms },
    },
  } = state;
  return { user, loaded: loadedRooms, cloudfrontList };
};

const mapDispatch = dispatch => ({
  getAllRoomsByAdmin: bindActionCreators(getAllRoomsByAdmin, dispatch),
  getAllRooms: bindActionCreators(getAllRooms, dispatch),
  resetAllInbox: bindActionCreators(resetAllInbox, dispatch),
});

const Members = connect(mapState, mapDispatch)(MembersContainer);
export default Members;
