// Libs
import React, { useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

// Shared
import { Toggle } from 'shared/FormControl';
import AssetSelect from '../AssetSelect';
import MessageItem from 'shared/AssignOnboardingMessage/components/MessageItem';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import { ConditionalAssignment } from 'shared/TriggerForms';
import BasedOnTrigger from 'components/OnboardingFlowDetail/components/BasedOnTrigger';
import { SharedTooltip } from 'shared/SharedTooltip';
import { TypingModal, TYING_TYPES, TriggerIcon } from 'shared/TriggerForms';

// Constants
import { getField } from 'components/OnboardingFlowDetail/helper';
import { ASSET_TYPES, MAXIMUM_SELECTED_ASSETS, ON_DEMAND_ASSET_KEY } from '../../constants';
import { pluralize, getUserShortName, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { ASSET_TYPE } from 'components/Product/constants';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as FormIcon } from 'assets/icons/form_asset_purple.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message_asset_orange.svg';
import { ReactComponent as AssignmentsIcon } from 'assets/icons/assignments_asset_green.svg';
import { ReactComponent as ProgramIcon } from 'assets/icons/program_asset.svg';
import { ReactComponent as ForumIcon } from 'assets/icons/forum_asset_violet.svg';
import { ReactComponent as AutoflowIcon } from 'assets/icons/route_autoflow.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/new_info_grey.svg';
import { ReactComponent as TextIcon } from 'assets/icons/onboarding-flow-text.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/onboarding-flow-signature.svg';
import { ReactComponent as OnDemandIcon } from 'assets/icons/OnboardingFlow/on_demand.svg';
import { ReactComponent as StudioProgramIcon } from 'assets/icons/OnboardingFlow/studio_program_asset.svg';
import { ReactComponent as ResourceCollectionIcon } from 'assets/icons/OnboardingFlow/resource_collection_asset.svg';
import { ReactComponent as WorkoutCollectionIcon } from 'assets/icons/OnboardingFlow/workout_collection_asset.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_thin.svg';
import DefaultImg from 'assets/images/OnboardingFlow/default_image.png';
import { ReactComponent as RemoveIcon } from 'assets/icons/delete_message_trash_icon.svg';

// Styles
import * as S from './style';

const STUDIO_DEFAULT_THUMBNAIL = `${CDN_URL}/images/studio_program_default_cover_image.png`;

const AssetCard = ({
  asset,
  title = '',
  description = '',
  buttonIcon,
  selectedAsset = {},
  onToggleStatus,
  onSelect,
  onOpen,
  isEditForm = false,
  isEditMessage = false,
  onboardingForm = [],
  isLoading = false,
  user,
  permission = {},
  disabled = false,
  isShowTooltip = false,
  removeForm,
  isEditMode = false,
  cloudfrontList,
  toggleConfirmModal,
  handleChangeAsset,
  hasPermissionUseTrigger,
}) => {
  const type = useMemo(() => get(asset, 'type', ''), [asset]);
  const userInfo = useMemo(
    () => ({
      src: get(user, 'avatar'),
      name: getUserShortName(user),
      color: get(user, 'color'),
    }),
    [user],
  );
  const { icon, backgroundColor, assetTitle, assetDescription, buttonText } = useMemo(() => {
    switch (type) {
      case ASSET_TYPES.WELCOME_FORM:
        return {
          icon: <FormIcon />,
          backgroundColor: '#F1F2FD',
          assetTitle: 'Onboarding Forms',
          assetDescription: !isEmpty(get(selectedAsset, 'form', []))
            ? ''
            : 'Collect crucial client information like medical & training history, goals & preferences.',
          buttonText: isEditForm ? 'Edit' : 'Choose Form',
        };
      case ASSET_TYPES.ONBOARDING_MESSAGE:
        return {
          icon: <MessageIcon />,
          backgroundColor: '#FBF1E2',
          assetTitle: 'Onboarding Messages',
          assetDescription: isEditMessage
            ? ''
            : 'Welcome new clients, and deliver important information to guide them through their onboarding process.',
          buttonText: isEditMessage ? 'Edit' : 'Setup Message',
        };
      case ASSET_TYPES.ASSIGNMENTS:
        return {
          icon: <AssignmentsIcon />,
          backgroundColor: '#EBFFF0',
          assetTitle: 'Assignments',
          assetDescription:
            selectedAsset[ASSET_TYPES.PROGRAM] ||
            selectedAsset[ASSET_TYPES.FORUM] ||
            get(selectedAsset, `[${ASSET_TYPES.PROGRAM_TRIGGER_FORMS}].length`, []) > 0 ||
            get(selectedAsset, `[${ASSET_TYPES.FORUM_TRIGGER_FORMS}].length`, []) > 0
              ? ''
              : 'Ensure clients hit the ground running by automatically assigning a program & enrolling them into a forum.',
          buttonText: '',
        };
      case ASSET_TYPES.AUTOFLOW:
        return {
          icon: <AutoflowIcon />,
          backgroundColor: '#E8F3FD',
          assetTitle: 'Autoflow',
          assetDescription: selectedAsset[ASSET_TYPES.AUTOFLOW]
            ? ''
            : 'Upgrade the onboarding process with advanced features and settings.',
          buttonText: '',
        };
      case ASSET_TYPES.ON_DEMAND:
        return {
          icon: <OnDemandIcon />,
          backgroundColor: '#E6FAFF',
          assetTitle: 'On-Demand',
          assetDescription:
            selectedAsset[ASSET_TYPES.ON_DEMAND] &&
            selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset &&
            (!isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[ASSET_TYPES.RESOURCE_COLLECTIONS]) ||
              !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[ASSET_TYPES.WORKOUT_COLLECTIONS]) ||
              !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[ASSET_TYPES.STUDIO_PROGRAMS]))
              ? ''
              : 'Provide clients instant access to your on-demand content.',
          buttonText: '',
        };
      default:
        return { icon: null, assetTitle: title, assetDescription: description, buttonText: '' };
    }
  }, [type, selectedAsset, isEditForm, isEditMessage]);

  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const contentEle = document.querySelector('.custom-content');
    function checkScroll() {
      if (contentEle && contentEle.scrollHeight > contentEle.clientHeight) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, [selectedAsset]);

  const hasPermission = type === ASSET_TYPES.AUTOFLOW ? permission[type] : true;

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  const handleRemoveForm = (event, deleteFormId, isCustomForm) => {
    event.stopPropagation();

    // Check form assign to trigger
    const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(
      it => it.form === deleteFormId || it.custom_form === deleteFormId,
    );
    const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(
      it => it.form === deleteFormId || it.custom_form === deleteFormId,
    );
    if (isAssignTriggerProgram || isAssignTriggerForum) {
      typeof toggleConfirmModal === 'function' &&
        toggleConfirmModal(
          true,
          <TypingModal
            isCloseAfterSubmit
            type={TYING_TYPES.REMOVE_ONBOARDING_FORM}
            closeModal={closeConfirmModal}
            submitAction={() => {
              typeof removeForm === 'function' && removeForm(deleteFormId, isCustomForm);
              if (typeof handleChangeAsset === 'function') {
                isAssignTriggerProgram &&
                  handleChangeAsset(
                    {
                      ...selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0],
                      is_form_deleted: true,
                    },
                    ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
                  );
                isAssignTriggerForum &&
                  handleChangeAsset(
                    {
                      ...selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0],
                      is_form_deleted: true,
                    },
                    ASSET_TYPES.FORUM_TRIGGER_FORMS,
                  );
              }
            }}
          />,
        );

      return;
    }

    removeForm && removeForm(deleteFormId, isCustomForm);
  };

  const handleOpen = (isCustom, form) => {
    if (disabled) {
      return;
    }

    if (isCustom) {
      window.open(`/home/forms/${get(form, 'custom_form._id')}/questions`, '_blank');
    } else {
      onOpen(get(form, 'form._id'));
    }
  };

  const handleEditProgramTrigger = () => {
    typeof onOpen === 'function' && onOpen({ type: ASSET_TYPES.PROGRAM_TRIGGER_FORMS, isUpdate: true });
  };

  const handleEditForumTrigger = () => {
    typeof onOpen === 'function' && onOpen({ type: ASSET_TYPES.FORUM_TRIGGER_FORMS, isUpdate: true });
  };

  const handleRemoveProgramTrigger = () => {
    if (typeof handleChangeAsset === 'function') {
      handleChangeAsset(undefined, ASSET_TYPES.PROGRAM_TRIGGER_FORMS);
    }
  };

  const handleRemoveForumTrigger = () => {
    if (typeof handleChangeAsset === 'function') {
      handleChangeAsset(undefined, ASSET_TYPES.FORUM_TRIGGER_FORMS);
    }
  };

  const renderOnboardingForm = () => {
    if (isEmpty(selectedAsset.form)) {
      return !disabled ? (
        <S.Footer>
          <S.ButtonWrapper isLoading={isLoading}>
            <S.ButtonContent onClick={() => onOpen()}>
              {buttonIcon}
              {buttonText}
            </S.ButtonContent>
          </S.ButtonWrapper>
        </S.Footer>
      ) : null;
    }
    return (selectedAsset.form || []).map((form, index) => {
      const isCustom = !isEmpty(get(form, 'custom_form'));
      const isArchived = get(form, 'custom_form.is_archived', false);

      const currentForm = get(form, 'form') || get(form, 'custom_form');
      const { _id, hasTrigger } = currentForm;
      const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(
        it => it.form === _id || it.custom_form === _id,
      );
      const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(
        it => it.form === _id || it.custom_form === _id,
      );
      const isShowTriggerAppliedIcon = hasTrigger && (isAssignTriggerForum || isAssignTriggerProgram);

      return (
        <S.Footer isEditForm={isEditMode} isArchived={isArchived}>
          {isCustom ? (
            <>
              <S.CustomCloseIcon onClick={event => handleRemoveForm(event, get(form, 'custom_form._id'), isCustom)} />
              <S.FormInfoWrapper isEdit={!disabled}>
                {isArchived && <S.Status>Archived</S.Status>}
                <S.FormInfoHeader>
                  <S.SubTitleWrapper>
                    <S.Counter>#{index + 1}</S.Counter>
                    <S.SubTitle>{get(form, 'custom_form.name', '')}</S.SubTitle>
                  </S.SubTitleWrapper>
                </S.FormInfoHeader>
                <S.FormInfoContent>
                  <S.FormInfoLayout>
                    <TextIcon />
                    <S.FormInfoText>
                      {pluralize('question', get(form, 'custom_form.number_of_questions', 0), true)}
                    </S.FormInfoText>
                  </S.FormInfoLayout>
                  {get(form, 'custom_form.hasSignature') && (
                    <S.FormInfoLayout>
                      <SignatureIcon />
                      <S.FormInfoText>Signature</S.FormInfoText>
                    </S.FormInfoLayout>
                  )}
                  {isShowTriggerAppliedIcon && <TriggerIcon tooltipContent="Trigger Applied" />}
                </S.FormInfoContent>
              </S.FormInfoWrapper>
            </>
          ) : (
            <>
              <S.CustomCloseIcon onClick={event => handleRemoveForm(event, get(form, 'form._id'))} />
              <S.FormInfoWrapper isEdit={!disabled}>
                <S.FormInfoHeader>
                  <S.SubTitleWrapper>
                    <S.Counter>#{index + 1}</S.Counter>
                    <S.SubTitle>{get(form, 'form.title', '')}</S.SubTitle>
                  </S.SubTitleWrapper>
                  <S.SubDescription>{get(form, 'form.description', '')}</S.SubDescription>
                </S.FormInfoHeader>
                <S.FormInfoContent>
                  <S.FormInfoLayout>
                    <TextIcon />
                    <S.FormInfoText>
                      {pluralize('question', get(form, 'selected_questions.length', 0), true)}
                    </S.FormInfoText>
                  </S.FormInfoLayout>
                  {get(form, 'form.hasSignature') && (
                    <S.FormInfoLayout>
                      <SignatureIcon />
                      <S.FormInfoText>Signature</S.FormInfoText>
                    </S.FormInfoLayout>
                  )}
                  {isShowTriggerAppliedIcon && <TriggerIcon tooltipContent="Trigger Applied" />}
                </S.FormInfoContent>
              </S.FormInfoWrapper>
            </>
          )}
          {!disabled ? (
            <S.ButtonWrapper isLoading={isLoading}>
              <S.ButtonContent onClick={() => handleOpen(isCustom, form)}>
                {buttonIcon}
                {isCustom ? 'Edit in Library' : buttonText}
              </S.ButtonContent>
            </S.ButtonWrapper>
          ) : null}
        </S.Footer>
      );
    });
  };

  const renderOnDemandAsset = ({ disabled, selectedAsset, onOpen, permission }) => {
    const assetTypes = [
      {
        type: ASSET_TYPES.RESOURCE_COLLECTIONS,
        icon: ResourceCollectionIcon,
        title: 'Resource Collection',
      },
      {
        type: ASSET_TYPES.WORKOUT_COLLECTIONS,
        icon: WorkoutCollectionIcon,
        title: 'Workout Collection',
      },
      {
        type: ASSET_TYPES.STUDIO_PROGRAMS,
        icon: StudioProgramIcon,
        title: 'Studio Program',
        isHideAsset: !permission[ASSET_TYPE.STUDIO],
      },
    ];

    return (
      <>
        {assetTypes.map(({ type, icon: Icon, title, isHideAsset }) => {
          const isHasOnDemandAsset =
            !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND]) &&
            !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset) &&
            !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[type]);
          const onDemandSelectedList = isHasOnDemandAsset
            ? selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[type]
            : [];
          const listWithoutDeleteOrDraft = (onDemandSelectedList || []).filter(item => {
            const { [`${ON_DEMAND_ASSET_KEY[type]}_data`]: assetData } = item || {};
            const { is_deleted: isDelete, status } = assetData || {};
            const isDraft =
              [ASSET_TYPES.WORKOUT_COLLECTIONS, ASSET_TYPES.STUDIO_PROGRAMS].includes(type) &&
              status !== 'publish' &&
              !getField(assetData, 'isPublished');

            return !(isDelete || isDraft);
          });

          if ((disabled && isEmpty(onDemandSelectedList)) || isHideAsset) return null;

          return (
            <S.InfoWrapper key={type}>
              <S.SelectWrapper>
                <S.TitleSelectWrapper>
                  <Icon className={`${type.toLowerCase().replace('_', '-')}-icon`} />
                  <S.SubTitle>{title}</S.SubTitle>
                  {!disabled && listWithoutDeleteOrDraft.length < MAXIMUM_SELECTED_ASSETS && (
                    <>
                      <S.AddIcon
                        onClick={() => onOpen(type)}
                        data-tip
                        data-for={`${type.toLowerCase().replace('_', '-')}`}
                      >
                        <PlusIcon className="plus-icon" />
                      </S.AddIcon>
                      <ReactTooltip
                        className="app-tooltip add-on-demand-tooltip"
                        id={`${type.toLowerCase().replace('_', '-')}`}
                        effect="solid"
                        place="top"
                      >
                        <p>Add</p>
                      </ReactTooltip>
                    </>
                  )}
                </S.TitleSelectWrapper>
                {isHasOnDemandAsset && (
                  <S.ContentWrapper>
                    <S.OnDemandList>
                      {onDemandSelectedList.map((item, index) => renderSelectedList(item, index, type))}
                    </S.OnDemandList>
                  </S.ContentWrapper>
                )}
              </S.SelectWrapper>
            </S.InfoWrapper>
          );
        })}
      </>
    );
  };

  const onRemove = (index, type) => {
    const isHasOnDemandAsset =
      !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND]) &&
      !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset) &&
      !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[type]);
    const onDemandSelectedList = isHasOnDemandAsset ? selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[type] : [];
    const newOnDemandSelectedList = onDemandSelectedList.filter((_, idx) => idx !== index);

    onSelect(newOnDemandSelectedList, type);
  };

  const renderTrigger = ({ open }) => {
    return (
      <div data-for="more-action-asset-icon-tooltip" data-tip>
        <MenuTrigger className="dropdown-trigger" vertical active={!!open} />
        {!open && <SharedTooltip id="more-action-asset-icon-tooltip" />}
      </div>
    );
  };

  const renderActions = (index, type) => {
    return (
      <DropDown className="custom-dropdown-actions" triggerIcon={renderTrigger}>
        <Option key="delete" onClick={() => onRemove(index, type)}>
          <S.OptionIcon>
            <RemoveIcon className="meal-plan-remove-icon" />
          </S.OptionIcon>
          <span>Remove</span>
        </Option>
      </DropDown>
    );
  };

  const renderSelectedList = (item, index, type) => {
    const { [`${ON_DEMAND_ASSET_KEY[type]}_data`]: assetData } = item || {};
    const {
      background,
      cover_image,
      cover_image_thumbnail,
      background_thumbnail,
      name,
      program_name,
      is_deleted,
      status,
    } = assetData || {};

    const isDraft =
      [ASSET_TYPES.WORKOUT_COLLECTIONS, ASSET_TYPES.STUDIO_PROGRAMS].includes(type) &&
      status !== 'publish' &&
      !getField(assetData, 'isPublished');
    const isDeleted = is_deleted;

    const assetBackground = () => {
      switch (type) {
        case ASSET_TYPES.RESOURCE_COLLECTIONS:
          return convertS3UrlToCloudFrontUrl(background_thumbnail || background, cloudfrontList, true);
        case ASSET_TYPES.WORKOUT_COLLECTIONS:
          return convertS3UrlToCloudFrontUrl(background_thumbnail || background, cloudfrontList, true);
        case ASSET_TYPES.STUDIO_PROGRAMS:
          return (
            convertS3UrlToCloudFrontUrl(cover_image_thumbnail || cover_image, cloudfrontList) ||
            STUDIO_DEFAULT_THUMBNAIL
          );
        default:
          return null;
      }
    };

    const assetName = () => {
      switch (type) {
        case ASSET_TYPES.RESOURCE_COLLECTIONS:
        case ASSET_TYPES.WORKOUT_COLLECTIONS:
          return name;
        case ASSET_TYPES.STUDIO_PROGRAMS:
          return program_name;
        default:
          return null;
      }
    };

    const getLabel = () => {
      if (isDeleted) {
        return 'Deleted';
      } else if (isDraft) {
        return 'Draft';
      }
    };

    return (
      <S.OnDemandItem key={`${type}-${index}`} isDisabled={isDeleted || isDraft}>
        <div className="on-demand-item">
          <img className="image" src={assetBackground() || DefaultImg} alt="" />
          <div className="name-wrapper">
            {(isDeleted || isDraft) && <span className="label">{getLabel()}</span>}
            <span className="name">{assetName()}</span>
          </div>
        </div>
        {!disabled && renderActions(index, type)}
      </S.OnDemandItem>
    );
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderIcon backgroundColor={backgroundColor}>{icon}</S.HeaderIcon>
        {disabled ? null : (
          <>
            <Toggle
              className="asset-toggle"
              checked={get(asset, 'is_active', false)}
              onChange={onToggleStatus}
              data-tip
              data-for={`toggle-status-${type}`}
              data-iscapture="true"
            />
            <ReactTooltip
              className="asset-toggle-tooltip"
              id={`toggle-status-${type}`}
              effect="solid"
              place={'top'}
              delayShow={500}
            >
              <span>
                {get(asset, 'is_active', false)
                  ? 'Deactivate to exclude from your Onboarding Flow'
                  : 'Activate to include in your Onboarding Flow'}
              </span>
            </ReactTooltip>
          </>
        )}
      </S.Header>
      <S.Content isHasPadding>
        <S.Title>{assetTitle}</S.Title>
        {assetDescription ? <S.Description>{assetDescription}</S.Description> : null}
      </S.Content>
      {type === ASSET_TYPES.AUTOFLOW ? (
        <div id={`${ASSET_TYPES.AUTOFLOW}-select`}>
          <AssetSelect
            url="/api/autoflow/v2/list"
            params={{
              sorter: 'last_used',
              status: 2,
            }}
            value={selectedAsset[ASSET_TYPES.AUTOFLOW]}
            type={type}
            disabled={!hasPermission || disabled}
            placeholder="Select Autoflow"
            className={classNames({ disabled: !hasPermission })}
            classNamePrefix="ofa"
            onSelect={onSelect}
            idSelect={`${ASSET_TYPES.AUTOFLOW}-select`}
          />
        </div>
      ) : null}
      {type === ASSET_TYPES.ASSIGNMENTS ? (
        <S.ContentScroll isCustomPadding={isScrollable} className="custom-content">
          {disabled &&
          !selectedAsset[ASSET_TYPES.PROGRAM] &&
          !selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0] ? null : (
            <S.InfoWrapper>
              <S.SelectWrapper id={`${ASSET_TYPES.PROGRAM}-select`}>
                <S.TitleSelectWrapper>
                  <ProgramIcon className="asset-icon" />
                  <S.SubTitle>Program</S.SubTitle>
                  {isShowTooltip && (
                    <>
                      <InfoIcon className="info-icon" data-tip data-for="program-asset-tooltip" />
                      <ReactTooltip
                        className="app-tooltip"
                        id="program-asset-tooltip"
                        effect="solid"
                        place={'top'}
                        delayShow={500}
                      >
                        <p>Seamlessly integrate workouts into a client's training calendar.</p>
                      </ReactTooltip>
                    </>
                  )}
                </S.TitleSelectWrapper>
                {isEmpty(selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS]) ? (
                  <AssetSelect
                    url="/api/program-library"
                    params={{
                      sorter: 'updated_at',
                      sort: -1,
                    }}
                    value={selectedAsset[ASSET_TYPES.PROGRAM]}
                    type={ASSET_TYPES.PROGRAM}
                    disabled={disabled}
                    placeholder="Select Program"
                    classNamePrefix="ofp"
                    onSelect={onSelect}
                    onOpen={onOpen}
                    isEnableTrigger={true}
                    idSelect={`${ASSET_TYPES.PROGRAM}-select`}
                    isHasPermissionUseTrigger={hasPermissionUseTrigger}
                  />
                ) : (
                  <BasedOnTrigger
                    disabled={disabled}
                    onOpen={handleEditProgramTrigger}
                    value={selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0]}
                    type={ASSET_TYPES.PROGRAM_TRIGGER_FORMS}
                    onRemove={handleRemoveProgramTrigger}
                    isHasPermissionUseTrigger={hasPermissionUseTrigger}
                  />
                )}
              </S.SelectWrapper>
            </S.InfoWrapper>
          )}
          {disabled &&
          !selectedAsset[ASSET_TYPES.FORUM] &&
          !selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0] ? null : (
            <S.InfoWrapper>
              <S.SelectWrapper id={`${ASSET_TYPES.FORUM}-select`}>
                <S.TitleSelectWrapper>
                  <ForumIcon className="asset-icon" />
                  <S.SubTitle>Forum</S.SubTitle>
                  {isShowTooltip && (
                    <>
                      <InfoIcon className="info-icon" data-tip data-for="forum-asset-tooltip" />

                      <ReactTooltip
                        className="app-tooltip"
                        id="forum-asset-tooltip"
                        effect="solid"
                        place={'top'}
                        delayShow={500}
                      >
                        <p>Communicate and share knowledge and experiences related to fitness, health, and wellness.</p>
                      </ReactTooltip>
                    </>
                  )}
                </S.TitleSelectWrapper>
                {isEmpty(selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS]) ? (
                  <AssetSelect
                    url="/api/v2/forum/get-list-group"
                    params={{
                      sorter: 'last_activity_at',
                      sort: -1,
                    }}
                    value={selectedAsset[ASSET_TYPES.FORUM]}
                    type={ASSET_TYPES.FORUM}
                    disabled={disabled}
                    placeholder="Select Forum"
                    classNamePrefix="off"
                    onSelect={onSelect}
                    onOpen={onOpen}
                    isEnableTrigger={true}
                    idSelect={`${ASSET_TYPES.FORUM}-select`}
                    isHasPermissionUseTrigger={hasPermissionUseTrigger}
                  />
                ) : (
                  <BasedOnTrigger
                    disabled={disabled}
                    onOpen={handleEditForumTrigger}
                    value={selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0]}
                    type={ASSET_TYPES.FORUM_TRIGGER_FORMS}
                    onRemove={handleRemoveForumTrigger}
                    isHasPermissionUseTrigger={hasPermissionUseTrigger}
                  />
                )}
              </S.SelectWrapper>
            </S.InfoWrapper>
          )}
          <ConditionalAssignment />
        </S.ContentScroll>
      ) : null}
      {type === ASSET_TYPES.WELCOME_FORM ? (
        <S.ContentScroll isHasCloseButton className="custom-content">
          {renderOnboardingForm()}
        </S.ContentScroll>
      ) : null}
      {type === ASSET_TYPES.WELCOME_FORM && !disabled && (selectedAsset.form || []).length === 1 ? (
        <S.TextButtonWrapper
          onClick={() =>
            onOpen(null, get(selectedAsset, 'form[0].form._id') || get(selectedAsset, 'form[0].custom_form._id'))
          }
        >
          + Add another form
        </S.TextButtonWrapper>
      ) : null}
      {type === ASSET_TYPES.ONBOARDING_MESSAGE ? (
        <S.ContentScroll isCustomPadding={isScrollable} className="custom-content">
          <S.Footer>
            {isEditMessage ? (
              <S.MessageInfoWrapper>
                <S.MessageInfoTitle>{get(selectedAsset, 'message.title', '')}</S.MessageInfoTitle>
                <S.MessageInfoContent>
                  {get(selectedAsset, 'message.messages', []).map((it, index) => (
                    <MessageItem
                      key={`message-${it._id}-${index}`}
                      message={it}
                      avatarData={userInfo}
                      index={index}
                      disabled
                      preview
                    />
                  ))}
                </S.MessageInfoContent>
              </S.MessageInfoWrapper>
            ) : null}
            {!disabled ? (
              <S.ButtonWrapper isLoading={isLoading}>
                <S.ButtonContent onClick={onOpen}>
                  {buttonIcon}
                  {buttonText}
                </S.ButtonContent>
              </S.ButtonWrapper>
            ) : null}
          </S.Footer>
        </S.ContentScroll>
      ) : null}
      {type === ASSET_TYPES.ON_DEMAND ? (
        <S.ContentScroll isCustomGap isCustomPadding={isScrollable} className="custom-content">
          {renderOnDemandAsset({ disabled, selectedAsset, onOpen, permission })}
        </S.ContentScroll>
      ) : null}
    </S.Wrapper>
  );
};

export default AssetCard;
