import _ from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = {
  isSearching: true,
  workingCollection: null,
  list: [],
  total: 0,
  query: {
    page: 1,
    per_page: 40,
    search: '',
    only_my_studio_collection: false,
  },
  availableClients: [],
  originClients: [],
  assignedClients: [],
  removedClients: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.STUDIO_COLLECTIONS_LOADING_DATA:
      return Object.assign({}, state, { isSearching: true });

    case Types.STUDIO_COLLECTIONS_FAILED_GET_LIST:
      return Object.assign({}, state, { isSearching: false });

    case Types.STUDIO_COLLECTIONS_SUCCESS_GET_LIST:
      return Object.assign({}, state, {
        isSearching: false,
        total: payload.total,
        list: payload.data,
        query: {
          ...state.query,
          page: 1,
        },
      });

    case Types.STUDIO_COLLECTIONS_SUCCESS_LOAD_MORE_LIST:
      return Object.assign({}, state, {
        isSearching: false,
        total: payload.total,
        list: [...state.list, ...payload.data],
        query: {
          ...state.query,
          page: payload.page,
        },
      });

    case Types.STUDIO_COLLECTIONS_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...payload.data } });

    case Types.STUDIO_COLLECTIONS_LIST_RESET_DATA:
      return Object.assign({}, state, { isSearching: false, query: INITIAL_STATE.query, list: [], total: 0 });

    case Types.STUDIO_COLLECTION_RESET_CLIENT_AND_UPDATE_TO_REDUX:
      return Object.assign({}, state, {
        removedClients: [],
      });

    case Types.STUDIO_COLLECTION_SUCCESS_DELETE_COLLECTION:
      return Object.assign({}, state, {
        list: state.list.filter(o => o._id !== payload.data.collectionId),
        total: state.total - 1,
      });

    case Types.STUDIO_COLLECTION_SUCCESS_GET_DETAIL:
      return Object.assign({}, state, {
        workingCollection: payload.data,
        originCollection: _.cloneDeep(payload.data),
      });

    case Types.STUDIO_COLLECTIONS_UPDATE_WORKING_COLLECTION:
      return Object.assign({}, state, {
        workingCollection: {
          ...state.workingCollection,
          ...payload.data,
        },
      });
    case Types.STUDIO_COLLECTION_SUCCESS_UPDATE: {
      const newWorkingCollection = _.merge(
        state.workingCollection,
        _.pick(payload.data, ['share', 'author', 'author_info']),
      );
      return Object.assign({}, state, {
        workingCollection: newWorkingCollection,
        originCollection: _.cloneDeep(newWorkingCollection),
      });
    }

    case Types.STUDIO_COLLECTION_ADD_RESOURCES_TO_REDUX: {
      const sections = state.workingCollection.sections.map(s => {
        if (s._id === payload.data.sectionId) {
          s.resources = [...s.resources, ...payload.data.resources];
        }
        return s;
      });
      return Object.assign({}, state, {
        workingCollection: {
          ...state.workingCollection,
          sections,
        },
      });
    }

    case Types.STUDIO_COLLECTION_GET_UPLOAD_URL_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.STUDIO_COLLECTION_GET_UPLOAD_URL_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.STUDIO_COLLECTION_REMOVE_RESOURCE_FROM_REDUX: {
      const sections = state.workingCollection.sections.map(s => {
        if (s._id === payload.data.sectionId) {
          s.resources = s.resources.filter(r => r._id !== payload.data.resourceId);
        }
        return { ...s };
      });
      return Object.assign({}, state, {
        workingCollection: {
          ...state.workingCollection,
          sections: [...sections],
        },
      });
    }

    case Types.STUDIO_COLLECTION_UPDATE_SECTION_INFO: {
      let newSection = [];
      const sections = state.workingCollection.sections.map(s => {
        if (s._id === payload.data.sectionId) {
          s = {
            ...s,
            ...payload.data.params,
          };
        }
        return { ...s };
      });
      if (payload.data.isNew) {
        newSection.push(payload.data.params);
      }

      return Object.assign({}, state, {
        workingCollection: {
          ...state.workingCollection,
          sections: [...sections, ...newSection],
        },
      });
    }

    case Types.STUDIO_COLLECTION_MOVE_RESOURCE: {
      return handleMoveResource(state, payload.data);
    }

    case Types.STUDIO_COLLECTION_RESET_DETAIL_DATA:
      return Object.assign({}, state, {
        workingCollection: null,
        originCollection: null,
        availableClients: [],
        originClients: [],
        assignedClients: [],
      });

    case Types.STUDIO_COLLECTION_RESET_CHHANGE_COLLECTION:
      return Object.assign({}, state, {
        workingCollection: _.cloneDeep(state.originCollection),
      });

    case Types.STUDIO_COLLECTION_SUCCESS_GET_CLIENTS:
      return Object.assign({}, state, {
        availableClients: [...payload.data],
        originClients: [...payload.data],
      });

    case Types.STUDIO_COLLECTION_SUCCESS_SAVE_CLIENTS:
      return Object.assign({}, state, { originClients: state.availableClients });

    case Types.STUDIO_COLLECTION_ADD_CLIENT_TO_REDUX:
      return Object.assign({}, state, {
        availableClients: _.uniqBy([...payload.data, ...state.availableClients], '_id'),
      });

    case Types.STUDIO_COLLECTION_REMOVE_CLIENT_TO_REDUX:
      return Object.assign({}, state, {
        availableClients: state.availableClients.filter(item => item._id !== payload.data._id),
        removedClients: [...state.removedClients, payload.data],
      });

    case Types.STUDIO_COLLECTION_SUCCESS_GET_ASSIGNED_CLIENTS:
      if (payload.data) {
        return Object.assign({}, state, {
          assignedClients: payload.data,
        });
      }
      return state;
    case Types.STUDIO_COLLECTIONS_CHANGE_SHARE_STATUS: {
      return Object.assign({}, state, {
        list: payload || state.list,
      });
    }

    default:
      return state;
  }
};

const handleMoveResource = (oldState, data) => {
  const { fromSection, fromIndex, toSection, toIndex } = data;
  const workingCollection = _.cloneDeep(oldState.workingCollection);

  const fromSectionIndex = workingCollection.sections.findIndex(s => s._id === fromSection);
  const toSectionIndex = workingCollection.sections.findIndex(s => s._id === toSection);
  const movingResource = workingCollection.sections[fromSectionIndex].resources[fromIndex];

  // remove from resource
  workingCollection.sections[fromSectionIndex].resources.splice(fromIndex, 1);

  // update to resource
  if (toSectionIndex !== -1) {
    workingCollection.sections[toSectionIndex].resources.splice(toIndex, 0, movingResource);
  }

  return Object.assign({}, oldState, { workingCollection });
};
