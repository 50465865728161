// Lib
import React from 'react';
import get from 'lodash/get';

// Share
import ContentItem from './ContentItem';
import ContentSkeleton from './ContentSkeleton';

import * as S from './style';

const ContentList = props => {
  const { displayType, listRecipes = [], loadingRecipes, filters, initTotal } = props;
  const { hasGetToTal } = initTotal || {};
  const { text_search = '', page = 1 } = filters || {};

  const hasList = listRecipes && listRecipes.length > 0;

  return (
    <S.ContentListWrapper isEmpty={!hasList && !loadingRecipes} className={displayType}>
      {loadingRecipes &&
        page === 1 &&
        Array(6)
          .fill(null)
          .map((_, index) => {
            return <ContentSkeleton key={`skeleton-${index}`} displayType={displayType} />;
          })}

      {hasList &&
        !(loadingRecipes && page === 1) &&
        listRecipes.map(recipe => {
          return <ContentItem key={get(recipe, '_id', '')} recipe={recipe} {...props} />;
        })}

      {loadingRecipes &&
        page > 1 &&
        Array(6)
          .fill(null)
          .map((_, index) => {
            return <ContentSkeleton key={`skeleton-${index}`} displayType={displayType} />;
          })}

      {!hasList && !loadingRecipes && hasGetToTal && (
        <S.ContentEmpty>
          <S.ContentNoResult>{text_search ? 'No results found.' : 'No recipes.'}</S.ContentNoResult>
        </S.ContentEmpty>
      )}
    </S.ContentListWrapper>
  );
};

export default ContentList;
