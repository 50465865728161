// Libs
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

// Actions
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { updatePartialQuestion, removeBackgroundLibraryImage, getBackgroundLibrary } from 'redux/form-details/actions';

// Shared
import QuestionType from './components/QuestionType';
import SettingsType from './components/SettingsType';
import { NoneTriggerBanner, AssignedTriggerBanner } from 'shared/TriggerForms';

// Constants
import { FORM_STATUS, QUESTION_TYPES } from 'constants/commonData';

// Styles
import * as S from './style';

const Settings = ({
  workingQuestion,
  questionTypes,
  questions,
  updatePartialQuestion,
  toggleConfirmModal,
  toggleModal,
  workingForm,
  removeBackgroundLibraryImage,
  getBackgroundLibrary,
  imageLibrary,
  defaultBackgroundColor,
  user,
  bodyMetrics,
  hasPermissionUseTrigger,
}) => {
  const isPublished = get(workingForm, 'status') === FORM_STATUS.PUBLISHED;
  const isEditMode = get(workingForm, 'is_edit_mode', false);
  const allowEdit = !isPublished || (isPublished && isEditMode);

  const settingsRef = useRef();

  useEffect(() => {
    return () => {
      toggleModal(false);
    };
  }, []);

  const handleMouseLeave = () => {
    settingsRef.current && settingsRef.current();
  };

  const renderBannerTrigger = () => {
    const { _id, type, has_trigger_in_use, related_question_onboarding_flows, options } = workingQuestion;

    if (type !== QUESTION_TYPES.SINGLE_CHOICE) return null;

    const isUseTrigger = hasPermissionUseTrigger && has_trigger_in_use;

    return (
      <div className="setting-trigger-banner-wrapper">
        {!isUseTrigger && <NoneTriggerBanner isHasPermissionUseTrigger={hasPermissionUseTrigger} questionId={_id} />}
        {isUseTrigger && (
          <AssignedTriggerBanner
            relatedQuestionOnboardingFlows={related_question_onboarding_flows}
            user={user}
            options={options}
          />
        )}
      </div>
    );
  };

  return (
    <S.Wrapper onMouseLeave={handleMouseLeave}>
      {workingQuestion && (
        <>
          <div className="right-side-bar-title">Settings</div>
          <div className="breaker" />
          <QuestionType
            workingQuestion={workingQuestion}
            questionTypes={questionTypes}
            questions={questions}
            toggleConfirmModal={toggleConfirmModal}
            updatePartialQuestion={updatePartialQuestion}
            allowEdit={allowEdit}
            defaultBackgroundColor={defaultBackgroundColor}
            user={user}
            bodyMetrics={bodyMetrics}
            isPublished={isPublished}
            workingForm={workingForm}
          />
          <SettingsType
            settingsRef={settingsRef}
            workingQuestion={workingQuestion}
            updatePartialQuestion={updatePartialQuestion}
            toggleModal={toggleModal}
            isPublished={isPublished}
            allowEdit={allowEdit}
            removeBackgroundLibraryImage={removeBackgroundLibraryImage}
            imageLibrary={imageLibrary}
            getBackgroundLibrary={getBackgroundLibrary}
            toggleConfirmModal={toggleConfirmModal}
            defaultBackgroundColor={defaultBackgroundColor}
            bodyMetrics={bodyMetrics}
            questions={questions}
          />
          {renderBannerTrigger()}
        </>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { formDetails, customBranding, permission, pricing } = rootReducer;
  const enabledAddons = pricing.getIn(['teamData']).toJS();
  const hasPermissionUseTrigger = get(permission, 'autoflow') || get(enabledAddons, 'automation');

  return {
    questionTypes: formDetails.questionTypes,
    questions: formDetails.questions || [],
    workingQuestion: formDetails.workingQuestion,
    workingForm: formDetails.workingForm,
    imageLibrary: formDetails.imageLibrary,
    defaultBackgroundColor: get(customBranding, 'originalTheme.primaryColor', '').toUpperCase(),
    user,
    bodyMetrics: formDetails.bodyMetrics || [],
    hasPermissionUseTrigger,
  };
};

const mapDispatchToProps = dispatch => ({
  updatePartialQuestion: bindActionCreators(updatePartialQuestion, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  removeBackgroundLibraryImage: bindActionCreators(removeBackgroundLibraryImage, dispatch),
  getBackgroundLibrary: bindActionCreators(getBackgroundLibrary, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
