// Libs
import React from 'react';
import { Modal } from 'semantic-ui-react';
import classNames from 'classnames';

// Assets
import { ReactComponent as AssignCheckedIcon } from 'assets/icons/check_all_grey.svg';

// Styles
import * as S from './style';

const AssignNotificationModal = ({ onClose, title, successMessage, errorContent, icon, isShowError }) => {
  return (
    <S.CustomModal
      open={true}
      closeIcon={null}
      onClose={onClose}
      className="meal-plan-assign-noti"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            {icon || (
              <AssignCheckedIcon
                className={classNames('assign-noti-header-icon fill-green', { 'fill-yellow': isShowError })}
              />
            )}
            <span>{title || 'Summary'}</span>
          </S.Header>
          <S.Body>
            {successMessage && <S.SuccessMessage isShowError={isShowError}>{successMessage}</S.SuccessMessage>}
            {errorContent && errorContent}
          </S.Body>
          <S.Footer>
            <S.Button isFillYellow={isShowError} onClick={onClose}>
              OK
            </S.Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </S.CustomModal>
  );
};

export default AssignNotificationModal;
