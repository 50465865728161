import styled from 'styled-components';
import * as Layout from 'shared/LibraryLayout';

export const TableBody = styled(Layout.TableBody)``;

export const TableRow = styled(Layout.TableRow)`
  &:hover {
    background-color: unset !important;
    cursor: unset !important;
  }
`;

export const TableCell = styled(Layout.TableCell)`
  height: 80px;
  padding: 0 10px;
  div {
    height: 16px;
    border-radius: 3px;
    background: #f8f8f8;
  }
  .name {
    max-width: 195px;
  }
  .category {
    max-width: 93px;
  }
  .unit {
    max-width: 66px;
  }
  .most-recent {
    max-width: 31px;
    margin: auto;
  }
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: #f8f8f8;
  }
`;
