import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import { DragDropContext } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import SelectCalendarType from 'shared/SelectCalendarType';
import { CALENDAR_LIMIT_WEEKS, CALENDAR_TYPES, CDN_URL, KEY_CODE, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import ProgramCalendarWeek from 'shared/ProgramCalendarWeek';
import Cell from './Cell';
import { DRAGGALE_TYPE } from './constants';
import PublishButton from '../StudioProgramOverview/components/OverviewHeading/PublishButton';
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import Modals from './ConfirmModal';
import ProgramAddWeek from 'shared/ProgramAddWeek';
import { hasWorkoutInWeek, isTeamAdmin, pluralize } from 'utils/commonFunction';
import { ReactComponent as CopyWeekIcon } from 'assets/icons/copy-calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TrashWorkoutIcon } from 'assets/icons/trash-copy-workouts.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-workouts.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-calendar.svg';
import PasteButtonWeek from './components/PasteButtonWeek';
import RemoveWeekAction from 'shared/RemoveWeekAction';

import * as S from './style';

class StudioProgramCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { draggingWorkout: null, deletingWeek: null, isDragging: false };
    this.isUpdatingEditMode = false;
    this.intervalId = null;
  }

  componentDidMount() {
    this.props.getListWorkouts();
    document.addEventListener('keydown', this.onPressEscapeKey);
    this.handleRetryCheckSyncing();
  }

  componentDidUpdate(prevProps) {
    if (!get(this.props, 'workingStudio.isSyncing', false)) {
      this.handleStopCheckSyncing();
    } else {
      if (!this.intervalId) {
        this.handleRetryCheckSyncing();
      }
    }
  }

  componentWillUnmount() {
    const { handleResetWeek, handleResetWorkout } = this.props;
    document.removeEventListener('keydown', this.onPressEscapeKey);
    this.handleStopCheckSyncing();
    handleResetWeek && handleResetWeek();
    handleResetWorkout && handleResetWorkout();
  }

  handleRetryCheckSyncing = () => {
    const isPublic = process.env.REACT_APP_ENABLE_RETRY;
    const isSyncing = get(this.props, 'workingStudio.isSyncing', false);

    if (isPublic && isSyncing) {
      this.intervalId = setInterval(() => {
        this.props.getStudioProgramDetail(get(this.props, 'workingStudio._id', ''));
      }, 10000);
    }
  };

  handleStopCheckSyncing = () => {
    clearInterval(this.intervalId);
  };

  onPressEscapeKey = event => {
    const {
      copying,
      handleResetWeek,
      selectedWeek,
      handleResetWorkout,
      selectedWorkout,
      toggleConfirmModal,
    } = this.props;

    if (event.keyCode === 27 && !document.querySelector('.ui.modals') && copying) {
      this.props.resetStudioProgramCopyItem();
    }
    if (event.keyCode === 27 && !document.querySelector('.ui.modal') && !isNil(selectedWeek)) {
      handleResetWeek && handleResetWeek();
    }
    if (event.keyCode === 27 && !document.querySelector('.ui.modal') && !isEmpty(selectedWorkout)) {
      handleResetWorkout && handleResetWorkout();
    }
    if (
      event.keyCode === KEY_CODE.esc &&
      document.querySelector('.remove-week-popup, .remove-workout-popup, .calendar--max-select')
    ) {
      toggleConfirmModal && toggleConfirmModal(false);
    }
  };

  onBeforeCapture = event => {
    const [type] = event.draggableId.split('_');

    if (type === 'workout') {
      this.setState({ draggingWorkout: event.draggableId });
    }
  };

  onDragEnd = result => {
    this.setState({ draggingWorkout: null });

    const { workingStudio } = this.props;

    if (workingStudio.isPublished && !workingStudio.isEditMode) {
      return false;
    }

    const { type, draggableId, source, destination } = result;

    if (!destination || !source || !draggableId) {
      return;
    }

    if (isEqual(source, destination)) {
      return;
    }

    const newIndex = destination.index;
    const oldIndex = source.index;

    if (type === DRAGGALE_TYPE.WORKOUT) {
      const [, oldWeekIndex, oldDayIndex] = source.droppableId.split('_');
      const [, newWeekIndex, newDayIndex] = destination.droppableId.split('_');
      const [, , , workoutId] = draggableId.split('_');
      let bodyData = { programId: workingStudio._id, workoutId, newIndex };

      if (source.droppableId === destination.droppableId) {
        bodyData = { ...bodyData, weekIndex: oldWeekIndex, dayIndex: oldDayIndex };
        this.props.arrangeStudioProgramWorkoutInDay(bodyData, oldIndex);
      } else {
        bodyData = { ...bodyData, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex };
        this.props.moveStudioProgramWorkout(bodyData, oldIndex);
      }
    } else if (type === DRAGGALE_TYPE.SECTION) {
      const [oldWeekIndex, oldDayIndex, workoutId] = source.droppableId.split('_');
      const [newWeekIndex, newDayIndex, newWorkoutId] = destination.droppableId.split('_');
      const [, , , , sectionId] = draggableId.split('_');
      let bodyData = { programId: workingStudio._id, workoutId, newIndex, sectionId };

      if (source.droppableId === destination.droppableId) {
        bodyData = { ...bodyData, weekIndex: oldWeekIndex, dayIndex: oldDayIndex, oldIndex };
        this.props.arrangeSectionInsideStudipProgramWorkout(bodyData, oldIndex);
      } else {
        bodyData = { ...bodyData, oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex, newWorkoutId, oldIndex };
        this.props.moveStudioProgramSection(bodyData, oldIndex);
      }
    }
  };

  onPublishChanges = () => {
    if (this.isUpdatingEditMode) {
      return;
    }

    this.props.toggleModal(
      true,
      <Modals.ConfirmPublishChangesModal
        onClose={() => this.props.toggleModal(false)}
        onConfirm={() => {
          const { workingStudio } = this.props;
          this.isUpdatingEditMode = true;
          this.handleRetryCheckSyncing();
          this.props
            .publishStudioProgramChanges({ programId: workingStudio._id })
            .then(() => {
              this.props.resetStudioProgramCopyItem();
              toast(
                <div style={{ maxWidth: 300, fontSize: 14 }}>
                  Changes have been published. Clients using the program will see the update in minutes.
                </div>,
              );
            })
            .finally(() => {
              if (this) {
                this.isUpdatingEditMode = false;
              }
            });
        }}
      />,
    );
  };

  onTurnOnEdit = () => {
    const { workingStudio } = this.props;
    this.props.toggleModal(
      true,
      <Modals.ConfirmTurnOnEditModeModal programId={workingStudio._id} onClose={() => this.props.toggleModal(false)} />,
    );
  };

  onCancelChanges = () => {
    const { workingStudio } = this.props;

    if (this.isUpdatingEditMode) {
      return;
    }

    if (!workingStudio.last_edit_by) {
      this.isUpdatingEditMode = true;
      this.props.cancelStudioProgramChanges({ programId: workingStudio._id }).finally(() => {
        if (this) {
          this.isUpdatingEditMode = false;
        }
      });
    } else {
      this.props.toggleModal(
        true,
        <Modals.ConfirmCancelChangesModal
          onClose={() => this.props.toggleModal(false)}
          onConfirm={() => {
            this.isUpdatingEditMode = true;
            this.props.cancelStudioProgramChanges({ programId: workingStudio._id }).finally(() => {
              if (this) {
                this.isUpdatingEditMode = false;
              }
            });
          }}
        />,
      );
    }
  };

  handleDeleteWeek = weekIndex => {
    const { workingStudio } = this.props;

    if (workingStudio.number_of_weeks < 2) {
      return this.props.showError('Program should have at least 1 week');
    }

    if (workingStudio.studio_program_weeks && workingStudio.studio_program_weeks[weekIndex]) {
      this.setState({
        deletingWeek: weekIndex,
      });
      this.props
        .removeStudioProgramWeek({
          weekId: workingStudio.studio_program_weeks[weekIndex],
          programId: workingStudio._id,
          weekIndex: weekIndex + 1,
        })
        .then(() => {
          this.setState({
            deletingWeek: null,
          });
        });
    }
  };

  deleteWeek = weekIndex => {
    const { deletingWeek } = this.state;
    if (deletingWeek) {
      return;
    }
    this.props.toggleConfirmModal(
      true,
      <S.RemoveEntireWeekPopup
        title={`Delete "Week ${weekIndex + 1}"`}
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        className="remove-entire-week-popup"
        content="Would you like to delete this week from the program?"
        onConfirm={() => this.handleDeleteWeek(weekIndex)}
        onDeny={() => {}}
        hasCloseIcon={true}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Confirm"
      />,
    );
  };

  addWeek = weekIndex => {
    const { workingStudio } = this.props;
    this.props.addStudioProgramWeek({ weekIndex, programId: workingStudio._id });
  };
  debounceAddWeek = debounce(this.addWeek, 300);

  handleAddWeekBefore = () => {
    const { startWeek } = this.props;
    this.addWeek(startWeek);
  };
  debounceAddWeekBefore = debounce(this.handleAddWeekBefore, 300);

  handleAddWeekAfter = () => {
    const { startWeek } = this.props;
    this.addWeek(startWeek + 1);
  };
  debounceAddWeekAfter = debounce(this.handleAddWeekAfter, 300);

  handleAddWeekToTheEnd = () => {
    const { workingStudio } = this.props;
    this.addWeek((workingStudio.studio_program_weeks || []).length + 1);
  };
  debounceAddWeekToTheEnd = debounce(this.handleAddWeekToTheEnd, 300);

  renderEditButtons = () => {
    const { workingStudio } = this.props;
    const buttons = [];

    if (!workingStudio.isEditMode) {
      if (workingStudio.isPublished) {
        buttons.push(
          <Button purple className="button--turn-on-edit-mode" onClick={this.onTurnOnEdit} key="turn-on-edit-mode">
            Edit
          </Button>,
        );
      }
    } else {
      buttons.push(
        <S.CancelChangeButton onClick={this.onCancelChanges} key="cancel-changes">
          {workingStudio.last_edit_by ? 'Cancel Changes' : 'Exit Edit Mode'}
        </S.CancelChangeButton>,
      );

      if (!workingStudio.last_edit_by || workingStudio.isSyncing) {
        buttons.push(
          <PublishButton
            className="button--publish-disabled"
            data-tip
            data-for="tooltip--no-changes-yet"
            key="publish-changes-disabled"
          >
            Publish Changes
          </PublishButton>,
        );
        buttons.push(
          <ReactTooltip
            className="app-tooltip"
            id="tooltip--no-changes-yet"
            effect="solid"
            place="top"
            key="publish-changes-tooltip"
          >
            <span>
              {workingStudio.isSyncing
                ? 'The Studio Program syncing is still in-progress. Please wait a few minutes before publishing the program'
                : 'No changes yet'}
            </span>
          </ReactTooltip>,
        );
      } else {
        buttons.push(
          <PublishButton
            onClick={this.onPublishChanges}
            data-tip
            data-for="tooltip--no-changes-yet"
            key="publish-changes"
          >
            Publish Changes
          </PublishButton>,
        );
      }
    }

    if (buttons.length) {
      return <S.EditButtonsContainer className="edit-buttons-container">{buttons}</S.EditButtonsContainer>;
    }

    return null;
  };

  handleCopyWeek = index => {
    const { handleSelectWeek } = this.props;
    handleSelectWeek && handleSelectWeek(index);
  };

  onRemoveWeek = weekIndex => {
    const { toggleConfirmModal, handleRemoveWeek } = this.props;

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.ConfirmModalCustom
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          title={`Remove workouts from Week ${weekIndex + 1}?`}
          content={`All workouts from Week ${weekIndex + 1} will be deleted. Would you like to continue?`}
          onConfirm={() => handleRemoveWeek && handleRemoveWeek(weekIndex)}
          onDeny={() => {}}
          cancelButtonTitle="Cancel"
          confirmButtonTitle="Remove"
          noBorder={true}
          hasCloseIcon={true}
          className="remove-week-popup"
        />,
      );
  };

  handleCopyMultipleWorkout = selectedWorkout => {
    const { handleCopyWorkout } = this.props;
    handleCopyWorkout && handleCopyWorkout(selectedWorkout);
  };

  onRemoveWorkout = selectedWorkout => {
    const { toggleConfirmModal, handleRemoveMultipleWorkout } = this.props;

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.RemoveMultipleWorkoutPopup
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          title={`Remove ${selectedWorkout.length} ${pluralize('workout', selectedWorkout.length)}`}
          content={`The selected workouts will be permanently deleted. Would you like to continue?`}
          onConfirm={() => handleRemoveMultipleWorkout && handleRemoveMultipleWorkout(selectedWorkout)}
          onDeny={() => {}}
          cancelButtonTitle="Cancel"
          confirmButtonTitle="Remove"
          noBorder={true}
          hasCloseIcon={true}
          className="remove-workout-popup"
        />,
      );
  };

  renderBottomCopyWeek = () => {
    const { handleResetWeek, selectedWeek } = this.props;

    return (
      <S.AlertBottomWrapper>
        <div>
          <strong>Week {selectedWeek + 1}</strong>&nbsp;Copied
        </div>
        <S.BottomCancel>
          <S.CancelBold>Esc</S.CancelBold>&nbsp;to cancel
          <CloseIcon className="cancel-copy" onClick={() => handleResetWeek && handleResetWeek()} />
        </S.BottomCancel>
      </S.AlertBottomWrapper>
    );
  };

  renderSelectedWorkoutBottom = () => {
    const { selectedWorkout, handleResetWorkout } = this.props;

    return (
      <S.BottomSelectWorkoutWrapper>
        <S.BottomNumberSelect>
          <S.BottomNumber>{selectedWorkout.length}</S.BottomNumber>
          {`${pluralize('Workout', selectedWorkout.length)} selected`}
        </S.BottomNumberSelect>
        <S.BottomActionsContainer>
          <S.BottomActions
            data-tip
            data-for="remove-multiple-workouts"
            onClick={() => this.onRemoveWorkout(selectedWorkout)}
          >
            <S.IconActions>
              <TrashWorkoutIcon />
            </S.IconActions>
            <S.TextActions>Remove</S.TextActions>
            <ReactTooltip
              className="app-tooltip remove-workouts bottom-calendar__tooltip"
              id="remove-multiple-workouts"
              effect="solid"
              place={'top'}
              delayShow={100}
            >
              <span>Remove Workouts</span>
            </ReactTooltip>
          </S.BottomActions>
          <S.BottomActions
            data-tip
            data-for="copy-multiple-workouts"
            onClick={() => this.handleCopyMultipleWorkout(selectedWorkout)}
          >
            <S.IconActions>
              <CopyIcon />
            </S.IconActions>
            <S.TextActions>Copy</S.TextActions>
            <ReactTooltip
              className="app-tooltip copy-workouts bottom-calendar__tooltip"
              id="copy-multiple-workouts"
              effect="solid"
              place={'top'}
              delayShow={100}
            >
              <span>Copy</span>
            </ReactTooltip>
          </S.BottomActions>
        </S.BottomActionsContainer>
        <S.BottomCancel>
          <S.TextCancel>
            <S.CancelBold>Esc</S.CancelBold>&nbsp;to cancel
          </S.TextCancel>
          <CloseIcon onClick={() => handleResetWorkout && handleResetWorkout()} />
        </S.BottomCancel>
      </S.BottomSelectWorkoutWrapper>
    );
  };

  renderTrashToolTip(index, weekHasWorkout, isDeleting) {
    const { loadingPaste } = this.props;
    document.addEventListener('keydown', this.onPressEscapeKey);

    if (!weekHasWorkout) {
      return (
        <>
          <S.WrapperTrashIcon loadingPaste={loadingPaste}>
            <S.TrashIcon>
              <TrashIcon
                className={isDeleting && 'deleting'}
                data-tip
                data-for={`tooltip-trash-${index}`}
                onClick={() => this.deleteWeek(index)}
              />
            </S.TrashIcon>
          </S.WrapperTrashIcon>
          <ReactTooltip
            className="app-tooltip studio-tooltip"
            id={`tooltip-trash-${index}`}
            effect="solid"
            place="right"
          >
            Remove entire week
          </ReactTooltip>
        </>
      );
    }

    return (
      <RemoveWeekAction
        onRemoveAllWorkoutInWeek={() => this.onRemoveWeek(index)}
        onDeleteWeek={() => this.deleteWeek(index)}
        disabled={loadingPaste}
      />
    );
  }

  renderDeleteWeek(index, isDeleting) {
    const { loadingPaste } = this.props;
    document.addEventListener('keydown', this.onPressEscapeKey);

    return (
      <>
        <S.WrapperTrashIcon loadingPaste={loadingPaste}>
          <S.TrashIcon>
            <TrashIcon
              className={isDeleting && 'deleting'}
              data-tip
              data-for={`tooltip-trash-${index}`}
              onClick={() => this.onRemoveWeek(index)}
            />
          </S.TrashIcon>
        </S.WrapperTrashIcon>
        <ReactTooltip className="app-tooltip studio-tooltip" id={`tooltip-trash-${index}`} effect="solid" place="right">
          Remove workouts of the week
        </ReactTooltip>
      </>
    );
  }

  renderCopyToolTip(index) {
    document.addEventListener('keydown', this.onPressEscapeKey);

    return (
      <>
        <CopyWeekIcon data-tip data-for={`tooltip-copy-${index}`} onClick={() => this.handleCopyWeek(index)} />
        <ReactTooltip className="app-tooltip studio-tooltip" id={`tooltip-copy-${index}`} effect="solid" place="right">
          Copy Week
        </ReactTooltip>
      </>
    );
  }

  render() {
    const {
      calendarViewMode,
      startWeek,
      workingStudio = {},
      selectedWeek,
      selectedWorkout,
      workoutsByDay,
      handlePasteWeek,
      loadingPaste,
      isGetWorkoutsLoading,
      user,
    } = this.props;

    const author = typeof workingStudio.author_info === 'object' ? workingStudio.author_info : undefined;
    const authorId = (author || {})._id;
    const isCreator = user._id === authorId;
    const isOwnerOrAdmin = isTeamAdmin(user);
    const isNoOwner = workingStudio.share === TEAM_SHARE_NOOWNER;
    const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;

    const { draggingWorkout, deletingWeek } = this.state;
    const { isViewMore } = this.state;
    const totalWeek = (workingStudio.studio_program_weeks || []).length;
    const isEnableAddWeek = isCreatorOrOwnerOrAdmin
      ? (workingStudio.isPublished && workingStudio.isEditMode) || !workingStudio.isPublished
      : false;
    const isEnableCopyWeek = isEnableAddWeek;
    const studioProgramName = get(workingStudio, 'program_name', '');

    return (
      <DragDropContext onDragEnd={this.onDragEnd} onBeforeCapture={this.onBeforeCapture}>
        <Helmet>
          <title>{studioProgramName} - Studio Program - Everfit</title>
        </Helmet>
        <S.Wrapper className="studo-program-calendar__wrapper">
          <S.NavigatorBarContainer>
            <S.CalendarWeekWrapper>
              <ProgramCalendarWeek
                currentWeek={startWeek}
                totalWeek={totalWeek}
                calendarType={calendarViewMode}
                goToWeek={this.props.goToWeek}
              />
              {isEnableAddWeek && (
                <ProgramAddWeek
                  currentWeek={startWeek}
                  onAddWeekBefore={this.debounceAddWeekBefore}
                  onAddWeekAfter={this.debounceAddWeekAfter}
                  onAddWeekToTheEnd={this.debounceAddWeekToTheEnd}
                  totalWeek={totalWeek}
                  warningMessage="Unpublish program to add a week in between"
                  onlyAddToTheEnd={workingStudio.isPublished}
                  loadingPaste={loadingPaste || !(isNil(selectedWeek) && isEmpty(selectedWorkout))}
                />
              )}
            </S.CalendarWeekWrapper>
            <S.NavigatorBarRightContent>
              {isCreatorOrOwnerOrAdmin && this.renderEditButtons()}
              <SelectCalendarType
                selected={calendarViewMode}
                options={CALENDAR_TYPES}
                onSelect={this.props.changeCalendarViewMode}
                disabled={item => (item.value === 1 ? false : totalWeek < item.value)}
                uiOptions={{ newUI: true }}
              />
            </S.NavigatorBarRightContent>
          </S.NavigatorBarContainer>
          <S.CalendarContainer>
            <S.Grid
              numRows={calendarViewMode}
              className="studio-program-calendar__grid"
              isCopyingWeek={!isNil(selectedWeek)}
            >
              {range(calendarViewMode).map(weekIndex => {
                const weekId = startWeek + weekIndex;
                const isDeleting = deletingWeek === weekId;
                const hasWorkout = hasWorkoutInWeek(workoutsByDay, weekId);
                const isCopyWeek = isNil(selectedWeek) && isEmpty(selectedWorkout) && !isGetWorkoutsLoading;

                return (
                  <S.GridRow key={weekIndex} className="studio-program-calendar__row">
                    {range(7).map(dayIndex => {
                      const cellId = weekId < totalWeek ? `${weekId}_${dayIndex}` : null;
                      const isDisableCell = isCreatorOrOwnerOrAdmin
                        ? workingStudio.isPublished && !workingStudio.isEditMode
                        : true;

                      return (
                        <Cell
                          key={`${weekId}_${dayIndex}`}
                          cellId={cellId}
                          draggingWorkout={draggingWorkout}
                          isViewMore={isViewMore}
                          disabled={isDisableCell}
                        />
                      );
                    })}
                    {weekId < totalWeek ? (
                      <>
                        {process.env.REACT_APP_COPY_WEEK_ENABLE_V3 && (
                          <>
                            {isEnableCopyWeek ? (
                              <>
                                {isCopyWeek && (
                                  <S.ActionsWeek isEditMode={workingStudio.isEditMode && !hasWorkout}>
                                    {workingStudio.isEditMode
                                      ? hasWorkout && this.renderDeleteWeek(weekId, isDeleting)
                                      : this.renderTrashToolTip(weekId, hasWorkout, isDeleting)}
                                    {hasWorkout && this.renderCopyToolTip(weekId)}
                                  </S.ActionsWeek>
                                )}
                                {!isNil(selectedWeek) && (
                                  <PasteButtonWeek
                                    handlePasteWeek={() => handlePasteWeek(weekId)}
                                    weekId={weekId}
                                    {...this.props}
                                  />
                                )}
                              </>
                            ) : null}
                          </>
                        )}
                        <S.WeekIndicatorContainer>
                          <div
                            className={classNames('week__title', {
                              'hide-week__title': hasWorkout && calendarViewMode === 4,
                            })}
                          >
                            Week {weekId + 1}
                          </div>
                        </S.WeekIndicatorContainer>
                        {!workingStudio.isPublished ? (
                          <>
                            {isCreatorOrOwnerOrAdmin && weekIndex === 0 && isCopyWeek && (
                              <S.AddWeekContainer className="studio-program-calendar__add-week__container firstLine">
                                <S.WrapperAddWeekIcon loadingPaste={loadingPaste}>
                                  <S.AddWeekIcon
                                    onClick={() => this.debounceAddWeek(weekId)}
                                    data-tip
                                    data-for={`tooltip--add-week-${weekId}`}
                                  />
                                </S.WrapperAddWeekIcon>
                                <ReactTooltip
                                  className="app-tooltip"
                                  id={`tooltip--add-week-${weekId}`}
                                  effect="solid"
                                  place="bottom"
                                >
                                  <span>Add 1 week</span>
                                </ReactTooltip>
                              </S.AddWeekContainer>
                            )}
                            {isCreatorOrOwnerOrAdmin && totalWeek < CALENDAR_LIMIT_WEEKS && isCopyWeek && (
                              <S.AddWeekContainer
                                className={classNames('studio-program-calendar__add-week__container', {
                                  lastLine: weekIndex === calendarViewMode - 1,
                                })}
                              >
                                <S.WrapperAddWeekIcon loadingPaste={loadingPaste}>
                                  <S.AddWeekIcon
                                    onClick={() => this.debounceAddWeek(weekId + 1)}
                                    data-tip
                                    data-for={`tooltip--add-week-${weekId + 1}`}
                                  />
                                </S.WrapperAddWeekIcon>
                                <ReactTooltip
                                  className="app-tooltip"
                                  id={`tooltip--add-week-${weekId + 1}`}
                                  effect="solid"
                                  place="top"
                                >
                                  <span>Add 1 week</span>
                                </ReactTooltip>
                              </S.AddWeekContainer>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </S.GridRow>
                );
              })}
            </S.Grid>
          </S.CalendarContainer>
          {process.env.REACT_APP_COPY_WEEK_ENABLE_V3 && (
            <>
              {!isNil(selectedWeek) && this.renderBottomCopyWeek()}
              {!isEmpty(selectedWorkout) && this.renderSelectedWorkoutBottom()}
            </>
          )}
        </S.Wrapper>
      </DragDropContext>
    );
  }
}

export default StudioProgramCalendar;
