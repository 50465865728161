import { Button } from 'shared/FormControl';
import { MenuTrigger } from 'shared/Icons';
import styled from 'styled-components';

export const CancelContent = styled.div`
  background: linear-gradient(90deg, #ffffff 66.88%, rgba(255, 255, 255, 0) 100%);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  margin-top: 15px;
`;

export const CancelButton = styled(Button)`
  color: #ea314a;
  border-color: #ea314a;
  border-radius: 3px;
  margin-right: 15px;
`;

export const CancelDescription = styled.div`
  flex: 1;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
`;

export const PurchaseDescription = styled.div`
  flex: 1;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
`;

export const PausedButton = styled(CancelButton)`
  border-color: #7b7e9a;
  color: #7b7e9a;
  cursor: default;

  &:hover {
    background: #fff;
  }
`;

export const ResumeDate = styled.span`
  font-weight: 600;
`;

export const UndoCancel = styled.div`
  cursor: pointer;
`;

export const PurchaseId = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #202353;
  opacity: 0.6;
`;

export const PackageInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PackageInfo = styled.div`
  display: flex;

  .evf-dropdown__menu {
    background: #363636;
    border: 1px solid #ebedf4;
    box-shadow: 0px 2px 4px rgba(42, 42, 52, 0.15);
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #fff;
    transform: translateX(${props => (props.isTrialPackage ? '-53px' : '-78.5px ')}) translateY(5px);
    overflow: initial;
    padding: 10px 2px;
    &::before {
      content: ' ';
      width: 7px;
      height: 7px;
      background-color: #363636;
      position: absolute;
      left: 47%;
      transform: rotate(45deg);
      top: -4px;
    }
  }

  .evf-dropdown__option {
    padding: 7px 15px;
    &.isLoading {
      cursor: not-allowed;
    }
    svg {
      margin-right: 10px;
    }

    &:hover {
      background-color: #696969;
    }
  }
`;

export const PackageName = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #202353;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  margin-right: 15px;
`;

export const CreditInfoDetail = styled.div`
  width: 100%;
  margin-left: 15px;

  &.resendActive {
    min-width: 134px;
    margin-left: 18px;
  }
`;

export const CreditInfo = styled.div`
  font-size: 13px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #202353;

  svg {
    width: 18px;
  }

  b {
    font-weight: 600;
  }
`;

export const TriggerCancelIcon = styled(MenuTrigger)`
  border-radius: 3px;
  background-color: #e4e7eb;

  &:hover {
    background-color: #b2bbcb;

    .dot,
    .dot::before,
    .dot::after {
      background-color: #fff;
    }
  }
`;

export const Wrapper = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 18px 25px 20px;
`;

export const PurchaseInfoRow = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
`;

export const PackageInfoResendLabel = styled.div`
  color: #5158cf;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 15px;
`;

export const PurchaseInfoResend = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-content: center;

  svg {
    height: 18px;
  }
`;

export const PurchaseStatus = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 15px;
  margin: 18px -10px -5px;

  width: 778px;
  height: 49px;

  background: linear-gradient(90deg, #ffffff 66.88%, rgba(255, 255, 255, 0) 100%);
  border-radius: 5px;
`;

export const Label = styled.div`
  margin-right: 18px;
`;

export const Status = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #202353;

  span {
    font-weight: 600;
    padding-left: 3px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;
