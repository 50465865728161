import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Mixpanel } from 'utils/mixplanel';
import { Toggle } from 'shared/FormControl';
import ReactTooltip from 'react-tooltip';
import { AutoflowName, Container } from './style';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { ActiveModal, PauseModal } from 'components/AutoflowConfirmModals';
import { AUTOFLOW_STATUS } from 'constants/commonData';
import { isTeamAdmin, checkAssetAssignedToProduct } from 'utils/commonFunction';
import { countToActive } from 'redux/autoflow/actions';
import UpgradePath from 'shared/UpgradePath';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { ASSET_TYPE } from 'components/Product/constants';

const NAVS = [
  // { label: 'Overview', route: '' },
  { label: 'Leaderboard', route: 'leaderboard', upgradePath: 'leaderboard' },
  { label: 'Training', route: 'training/calendar' },
  { label: 'Tasks', route: 'task/calendar' },
  { label: 'Auto Message', route: 'auto-message' },
  { label: 'Clients', route: 'clients' },
  { label: 'Settings', route: 'settings' },
];

const AutoflowDetailHeader = props => {
  const { workingAutoflow, location, toggleModal, user, onboarding_flow, toggleConfirmModal } = props;

  if (!workingAutoflow) {
    return null;
  }

  const onToggleAutoflowStatus = () => {
    const { author } = workingAutoflow;

    if (isTeamAdmin(user) || user._id === get(author, '_id')) {
      if (workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED) {
        Mixpanel.track('start_autoflow');
        props.countToActive({ autoflow: workingAutoflow._id }).then(response => {
          const { data } = response.data;

          if (data) {
            toggleModal(true, <ActiveModal type={workingAutoflow.type} clientStatistic={data} />);
          }
        });
      } else {
        Mixpanel.track('stop_autoflow');
        toggleModal(true, <PauseModal type={workingAutoflow.type} />);
      }
    }
  };

  const checkAssetAssignedToOnboardingFlow = async () => {
    if (workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED) {
      onToggleAutoflowStatus();
      return;
    }

    const isAssigned = onboarding_flow
      ? await checkAssetAssignedToProduct(ASSET_TYPE.AUTOFLOW, workingAutoflow._id, true)
      : false;

    if (isAssigned) {
      toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          isOnboardingFlow
          title="Autoflow is assigned to an Onboarding Flow"
          content="This Autoflow is in at least 1 onboarding flow. If you change its permission to “Private”, it will be inactivated in all onboarding flows as well."
          assetType={ASSET_TYPE.AUTOFLOW}
          onConfirm={() => {
            toggleConfirmModal(false);
            onToggleAutoflowStatus();
          }}
        />,
      );
      return isAssigned;
    }

    onToggleAutoflowStatus();
  };

  return (
    <Container>
      <div className="name">
        <AutoflowName>{workingAutoflow.name}</AutoflowName>
        <Toggle
          data-tip
          data-for="toggle-autoflow-status-tooltip"
          checked={workingAutoflow.status === AUTOFLOW_STATUS.ACTIVATED}
          onChange={checkAssetAssignedToOnboardingFlow}
          label={workingAutoflow.status === AUTOFLOW_STATUS.ACTIVATED ? 'Active' : 'Inactive'}
          width={32}
          height={18}
        />
        <ReactTooltip className="app-tooltip" id="toggle-autoflow-status-tooltip" effect="solid" place="bottom">
          <span>{`${workingAutoflow.status === AUTOFLOW_STATUS.ACTIVATED ? 'Pause' : 'Activate'} Autoflow`}</span>
        </ReactTooltip>
      </div>
      <div className="nav-menu">
        {map(NAVS, (nav, index) => {
          const label = (
            <span>
              {nav.label}
              {nav.route === 'settings' && workingAutoflow.isSettingTurnedOff ? (
                <span className="extend">&nbsp;(Off)</span>
              ) : null}
            </span>
          );

          return nav.upgradePath ? (
            <UpgradePath pathName={nav.upgradePath} fallback={<></>} key={index}>
              <NavLink
                key={index}
                className="nav-menu__item"
                data-intercom-target={`Autoflow detail - ${nav.label}`}
                exact={nav.route === ''}
                to={`/home/autoflow/${workingAutoflow._id}${nav.route ? '/' + nav.route : ''}`}
                isActive={() => {
                  return (
                    location.pathname === `/home/autoflow/${workingAutoflow._id}${nav.route ? '/' + nav.route : ''}`
                  );
                }}
              >
                {label}
              </NavLink>
            </UpgradePath>
          ) : (
            <NavLink
              key={index}
              className="nav-menu__item"
              data-intercom-target={`Autoflow detail - ${nav.label}`}
              exact={nav.route === ''}
              to={`/home/autoflow/${workingAutoflow._id}${nav.route ? '/' + nav.route : ''}`}
              isActive={() => {
                return location.pathname === `/home/autoflow/${workingAutoflow._id}${nav.route ? '/' + nav.route : ''}`;
              }}
            >
              {label}
            </NavLink>
          );
        })}
      </div>
    </Container>
  );
};

const mapStateToProps = state => {
  const { rootReducer, router, user } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    workingAutoflow,
    location: router.location,
    user,
    onboarding_flow: rootReducer.permission.onboarding_flow,
  };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  countToActive: bindActionCreators(countToActive, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(AutoflowDetailHeader);
