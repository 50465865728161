import { toggleModal } from 'actions/modal';
import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';
import { ManuallySet1RMButton, ManuallyWrapper } from './style';
import { ReactComponent as Manually1RMIcon } from 'assets/icons/manually_set_1rm.svg';
import SetOneRepMaxModal from 'shared/SetOneRepMaxModal/index';

const ManuallySet1RM = props => {
  const onOpenModal = () => {
    props.toggleModal(true, <SetOneRepMaxModal {...props} />);
  };
  return (
    <ManuallyWrapper>
      <ManuallySet1RMButton onClick={onOpenModal}>
        <Manually1RMIcon />
        Manually Set 1RM
      </ManuallySet1RMButton>
    </ManuallyWrapper>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(ManuallySet1RM);
