import React, { useRef, useState, useImperativeHandle } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import { Button } from 'shared/FormControl';
import BuilderContent from '../../../TaskCreate/HabitsBuilderPopup/BuilderContent';

import { toggleModal } from 'actions/modal';
import { updateHabit } from 'redux/habits/actions';

import * as S from './style';

const Overview = ({
  date,
  prevHabit,
  habit,
  setHabit,
  clientId,
  habitUnits,
  toggleModal,
  updateHabit,
  replace,
  innerRef,
  clientTimezone,
  open,
  setOpen,
}) => {
  const contentRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [invalidInput, setInvalidInput] = useState({
    title: false,
    goalValue: false,
  });
  const hasEnded = _.get(prevHabit, 'has_ended', false);

  useImperativeHandle(innerRef, () => ({
    isSameOriginData: it => contentRef.current.isSameOriginData(it),
  }));

  const disabledSave = submitting || invalidDate || invalidInput.title || invalidInput.goalValue;

  return (
    <>
      <S.Overview>
        <BuilderContent
          ref={contentRef}
          date={date}
          habitUnits={habitUnits}
          prevHabit={habit || prevHabit}
          isEdit
          viewDetails
          clientId={clientId}
          toggleModal={toggleModal}
          updateHabit={updateHabit}
          replace={replace}
          habit={habit}
          setHabit={setHabit}
          submitting={submitting}
          invalidInput={invalidInput}
          editMode
          setSubmitting={setSubmitting}
          setInvalidDate={setInvalidDate}
          setInvalidInput={setInvalidInput}
          clientTimezone={clientTimezone}
          open={open}
          setOpen={setOpen}
        />
      </S.Overview>
      {!hasEnded && (
        <S.ActionsWrapper>
          <Button
            disabled={disabledSave}
            className="actions__saveClose"
            onClick={() => contentRef.current && contentRef.current.handleSave(false)}
          >
            Save
          </Button>
          <Button
            disabled={disabledSave}
            purple
            className={classnames('actions__save', {
              disabled: disabledSave,
            })}
            onClick={() => contentRef.current && contentRef.current.handleSave(true)}
          >
            {'Save & Close'}
          </Button>
        </S.ActionsWrapper>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    clientId: _.get(rootReducer, 'client.selected'),
    habitUnits: _.get(rootReducer, 'habits.habitUnits'),
    clientTimezone: _.get(rootReducer, 'client.workingClientDetail.timezone'),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  updateHabit: bindActionCreators(updateHabit, dispatch),
  replace: bindActionCreators(replace, dispatch),
});

const wrapper = React.forwardRef((props, ref) => <Overview {...props} innerRef={ref} />);
export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(wrapper);
