import styled from 'styled-components';

export const Header = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #dadfea;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #202353;
  font-family: 'Roboto';

  svg {
    opacity: 0.5;

    path {
      fill: #202353;
    }

    :hover {
      cursor: pointer;
      opacity: 1;

      path {
        fill: #5158cf;
      }
    }
  }
`;

export const AddButton = styled.button`
  display: block;
  padding: 9px;
  border: 1px solid #5158cf;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #5158cf;
  width: 100%;
  box-shadow: none;
  outline: none;
  background-color: #fff;

  :hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  padding: 30px 19px 19px;
  margin-bottom: 17px;
  border-radius: 5px;
`;

export const PricingTypeLabel = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #202353;
  opacity: 0.5;
  padding-top: 5px;
`;

export const PricingValue = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: #202353;
  margin-left: 6px;
`;

export const CurrencySymbol = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 150%;
  color: #202353;
`;

export const PricingValueContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const PricingDetail = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Over = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: #202353;
  letter-spacing: 0.1px;
`;

export const TrialPeriod = styled.div`
  margin-top: 9px;
  min-width: 67px;
  text-align: right;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  opacity: 0.3;
`;
