import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { SOCIAL_STRING, TASK_TYPES } from 'constants/commonData';
import { convertS3UrlToCloudFrontUrl, getStatusCodeUrl } from 'utils/commonFunction';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import * as S from './style';

function getAvatarClass(resource) {
  const type = resource.link_type || resource.document_type;
  switch (type) {
    case 'youtube':
    case 'vimeo':
    case 'instagram':
    case 'twitter':
    case 'spotify':
    case 'facebook':
    case 'document':
      return type;
    case 'pdf':
      return 'document';
    case 'form':
      return 'form';
    default:
      return 'link';
  }
}

function ResourceMobilePreviewItem(props) {
  const [coverImage, setCover] = useState('');
  const { resource, cloudfrontList } = props;

  if (resource.source_type === TASK_TYPES.FORM && get(resource, 'form.is_archived', false)) {
    return null;
  }

  const getPresignedURLs = async list => {
    try {
      const newSrc = convertS3UrlToCloudFrontUrl(list[0], cloudfrontList);
      if (newSrc) {
        setCover(newSrc);
      } else {
        const statusCodeExistedThumbnail = await getStatusCodeUrl(list[0]);
        if (
          resource.link &&
          statusCodeExistedThumbnail !== 200 &&
          (getAvatarClass(resource) === SOCIAL_STRING.instagram || resource.link.includes(SOCIAL_STRING.instagram))
        ) {
          const metaDataFromURL = await getPageMetaDataFromURL(resource.link);
          const { data: { data: { image = '' } = {} } = {} } = metaDataFromURL;

          setCover(image);
        } else {
          const response = await props.getS3presignedURL(list);
          setCover(response[0]);
        }
      }
    } catch {}
  };

  useEffect(() => {
    resource.cover_image && getPresignedURLs([resource.cover_image]);
  }, [resource.cover_image]);

  return (
    <S.ResourceItemWrapper coverImage={coverImage}>
      <div className={`previewResource__avatar ${getAvatarClass(resource)}`} />
      <div className="previewResource__info">{resource.name}</div>
    </S.ResourceItemWrapper>
  );
}

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceMobilePreviewItem);
