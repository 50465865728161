import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  .filter__dropdown {
    cursor: pointer;
  }
`;

export const MenuContainer = styled.div`
  width: 318px;
  border-radius: 4px;
  box-shadow: 0 5px 12px 2px rgba(0, 0, 0, 0.1);
  border: solid 1px #dfe4e5;
  background-color: #fff;
  padding: 16px 24px 30px;
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  z-index: 999;

  .form-group label {
    margin-bottom: 4px;
  }

  > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }

  .ui.dropdown {
    width: 100%;
    padding: 7px 15px 5px 11px;
    border-radius: 4px;
    border: solid 1px #dfe4e5;
    
    > .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #323c47;

      i.icon {
        margin-right: 0;
        color: #6f7076;
        font-size: 11px;
        line-height: normal;
      }
    }

    .menu {
      width: 100%;
      top: calc(100% + 4px);

      .item {
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        padding: 8px 11px !important;

        :hover,
        &.active {
          color: #5c58c0;
          background-color: #edecfc;
        }
      }
    }

    &:hover {
      i.icon {
        color: #745ee6;
      }
    }
  }
`;