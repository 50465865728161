import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px
  color: #7B7E91;
`;

export const MealDetailWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 30px 0px #acacac33;
  padding: 30px 0;
`;

export const TabHeader = styled.div`
  display: flex;
`;

export const TabHeaderItem = styled.div`
  ${baseText}
  flex: 1;
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #dadbea;
  cursor: pointer;
  user-select: none;
  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid #5158ce;
      color: #5158cf;
    `}
`;

export const TabContent = styled.div``;
