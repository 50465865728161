import styled, { css } from 'styled-components';

const baseText = `
    color: #202353;
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 150%;
`;

export const NoteWrapper = styled.div`
  margin-top: 15px;

  .meal-plan-add-note {
    padding: 10px 15px 10px 10px;
    width: fit-content;
    height: unset;

    svg {
      margin-right: 5px;
      flex-shrink: 0;

      path {
        fill: #7b7e91;
      }
    }

    .meal-plan-note-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &:hover {
      svg path {
        fill: #5158cf;
      }
    }
  }

  ${({ hasNote }) =>
    hasNote &&
    css`
      .meal-plan-add-note {
        padding: 10px 15px;
        border-radius: 10px;
        box-shadow: 0px 2px 30px 0px rgba(172, 172, 172, 0.2);
        border-radius: 10px;
        border: unset;

        svg {
          margin-right: 10px;

          path {
            fill: #7b7e91;
          }
        }

        .meal-plan-note-content {
          ${baseText}
          font-size: 13px;
          font-weight: 400;
          line-height: 23px;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          display: -webkit-box;
          -webkit-line-clamp: 3;
          box-orient: vertical;
          overflow: hidden;
          word-break: break-all;
          white-space: break-spaces;
        }

        .meal-plan-read-more {
          ${baseText}
          color: #5158cf;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          margin-top: 5px;

          &:hover {
            cursor: pointer;
            text-decoration-line: underline;
          }
        }
      }
    `}
`;
