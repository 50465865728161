import styled, { css } from 'styled-components';

export const BasedOnTriggerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;

  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;

  position: relative;

  ${props =>
    props.isNoPermission &&
    css`
      .question-label,
      .tag-wrapper {
        opacity: 0.5;
      }

      &:hover {
        .remove-button-wrapper {
          display: inline-flex;
          visibility: visible;
        }
      }
    `}

  .question-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #323c47;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .edit-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    gap: 8px;

    width: 100%;
    background: #ffffff;
    border: 1px solid #5e59ff;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      border-color: #2b26c1;

      .button-label {
        color: #2b26c1;
      }

      .onboarding-flow-edit-icon-trigger {
        g {
          stroke: #2b26c1;
        }
      }
    }

    .onboarding-flow-edit-icon-trigger {
      width: 15px;
      height: 15px;
      margin-bottom: 1px;

      g {
        stroke: #5e59ff;
      }
    }

    .button-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;

      color: #5e59ff;
    }
  }

  &.is-unavailable {
    border: 1px solid #dadfea;
    box-shadow: 0px 4px 8px 0px #f1f2f6;
    background: #eaedf4;
    .edit-button {
      background: #eaedf4;
    }
  }

  .warning-icon-wrapper {
    position: absolute;
    top: 12px;
    right: 3px;
    cursor: pointer;
  }
`;

export const RemoveButtonWrapper = styled.div`
  &.remove-button-wrapper {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    display: none;
    visibility: hidden;

    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 60%, #ffffff 100%);
    border-radius: 10px;
    padding: 6px 12px 12px;
  }

  .remove-button {
    position: relative;
    display: inline-flex;
    gap: 8px;
    border: unset;
    background: #ffffff;
    padding: unset;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      svg.onboarding-flow-trash-icon-trigger path {
        stroke: #5559ff;
      }

      .button-label {
        color: #5559ff;
      }
    }

    svg.onboarding-flow-trash-icon-trigger {
      width: 16px;
      height: 16px;
    }

    .button-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #28293f;
    }
  }
`;
