import styled, { keyframes } from 'styled-components';
import photo from './../../assets/images/task_list_banner.png';
import check from './../../assets/icons/checked_purple.svg';

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0)
  }
  10%{
    transform: translateX(-10px);
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
  }
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
  button {
    width: 117px;
    height: 34px;
    margin-bottom: 30px;
  }
`;

export const Footer = styled.div`
  background: #ffffff;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
  border-radius: 8px;
  height: 53px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  padding-right: 20px;
  button {
    width: 117px;
    height: 34px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 78px;
  button {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    outline: none;
    border: none;
    color: #5158cf;
    background: none;
    :hover {
      border: none;
      color: #5158cf;
      background: none;
      outline: none;
    }
  }
`;

export const TaskListContainer = styled.div`
  height: 100%;
  .no_task {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 405px;
    span {
      margin-top: 25.5px;
      color: #a6a7ba;
      font-size: 13px;
      line-height: 130%;
      text-align: center;
    }
  }
`;

export const Container = styled.div`
  height: 100%;
  margin: 0 25px;
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .task_label {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #202353;
    opacity: 0.4;
    padding: 0 10px;
  }
  .input_title {
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #202353;
    :hover:not(:active, :focus) {
      background: #f1f1f3;
    }
    .text-editable__container {
      overflow-y: scroll;
      max-height: 100px;
    }
  }
  .input_details {
    .text-editable__container {
      overflow-y: scroll;
      max-height: 80px;
    }
    .text-editable__input {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
    }
  }
`;

export const FormGroup = styled.div`
  transition: border-color 100ms ease 0s;
  margin-bottom: 10px;
  width: 100%;
  font-weight: unset;
  font-family: Open Sans;
  input,
  textarea {
    background: #fafbfc;
    border: 1px solid #dcdcde;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    color: #323c47;
    font-size: 13px;
    line-height: 18px;
    padding: 5px 15px;
    outline: none !important;
    width: 100%;
    font-weight: 600;
    font-family: Open Sans;
    ::placeholder {
      color: #969fb5;
      font-weight: normal;
    }
    :disabled {
      background: #fafbfc;
    }
    :hover:not(:disabled),
    :focus {
      border-color: #5c5bbd;
    }
  }
  .error_message {
    padding-left: 10px;
  }
  &.created_by {
    font-size: 12px;
    line-height: 150%;
    color: #202353;
    opacity: 0.6;
    margin-top: 20px;
  }
  &.error {
    input {
      border-color: #ee2525;
      ::placeholder {
        font-style: normal;
        font-weight: normal;
      }
    }
  }
  &.assign_to {
    position: relative;
    .user_icon {
      color: #8e90a9;
      top: 9px;
      z-index: 1;
      position: absolute;
      left: 10px;
      &.has_value {
        color: #202353;
      }
      /* :hover{
      color
    } */
    }
  }

  .multi-select-container {
    width: 361px;
    .multi-select__value-container {
      padding-left: 40px;
    }
    .multi-select__control {
      border-color: #fff;
      background: #f8f8f9;
      :hover {
        border-color: #5c5bbd;
      }
    }
  }
`;

export const TaskDate = styled.div`
  .badge {
    color: red;
    background: #fff7f8;
    border-radius: 3px;
    width: 37px;
    text-align: center;
    padding: 5px 0px;
    margin-left: 5px;
    margin-top: -5px;
  }
  width: 223px;
  font-size: 11px;
  line-height: 15px;
  color: #8f91a9;
  display: flex;
  margin-top: 10px;
  font-weight: 600;
  cursor: default;
`;

export const TaskTitle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 25px;
  margin-top: 3px;
  width: 223px;
  :hover {
    color: #5158cf;
  }
  &.completed {
    text-decoration: line-through;
  }
`;

export const TaskContent = styled.div`
  background: #ffffff;
  padding: 10px 0px;
  transition: border 0.2s ease 0s;
  border: 1px solid ${props => (props.isDragging ? '#5158CF !important' : '#E9ECEE')};
  border-left: 1px solid
    ${props => {
      if (props.isDragging) {
        return '#5158CF';
      } else {
        if (props.theme.color !== '#fff') {
          return '#FFF';
        } else {
          return '#E9ECEE';
        }
      }
    }};
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0 25px;
  margin-bottom: 5px;
  font-family: Open Sans;
  min-height: 55px;
  display: flex;
  box-shadow: -4px 0px 0px
      ${props => {
        if (props.theme.color == '#fff') {
          return 'none';
        } else {
          return props.theme.color;
        }
      }},
    0px 4px 16px rgba(196, 196, 196, 0.25);

  .program-calendar_clients {
    margin-top: 20px;
    margin-left: 3px;
    display: flex;
    .avatar-container {
      margin-left: -6px;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }

  .drag_drop_icon {
    display: ${props => (props.isDragging ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    img {
      height: 20px;
      width: 20px;
      :hover {
        svg {
          path {
            stroke: #5158cf;
          }
        }
      }
    }
  }
  &.active {
    animation: ${fadeOut} 0.8s forwards;
    animation-delay: 0.3s;
  }
  :hover {
    .drag_drop_icon {
      display: flex;
      :hover {
        color: #5158cf !important;
      }
    }
  }
  .left_content {
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 4px;
    .__react_component_tooltip.app-::-ms-tooltip.app-tooltip-mark {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 40px;
      background: #2d2e2d;
      border-radius: 5px;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
    }
    .evf-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 25px;
      .checkbox-icon {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        border: 1px solid #dcdcdc;
        background-color: #f9f9f9;
        :hover {
          border: 1px solid #5158cf;
        }
      }

      input {
        :checked ~ .checkbox-icon {
          background-color: #f2f1ff;
          border-color: #5158cf;
          background-image: url(${check});
        }
      }
    }
  }
  .right_content {
    &.completed {
      opacity: 0.7;
    }
  }
`;

export const Trigger = styled.div`
  position: relative;
  cursor: pointer;
  &.open-task-trigger {
    height: 20px;
    background: #fff;
  }
  .red-dot {
    &.active {
      display: inline-block;
    }
    left: 12px;
    top: -1px;
    width: 8px;
    height: 8px;
    border: 1px solid white;
    background-color: red;
    border-radius: 50%;
    display: none;
    position: absolute;
  }
  .open-task-icon {
    width: 18px;
    height: 20px;
    color: #aaacae;
    &.open,
    :hover {
      color: #5158cf;
    }
  }
`;

export const InputHeader = styled.div`
  max-height: 64px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
`;

export const Header = styled.div`
  max-height: 64px;
  display: flex;
  justify-content: space-between;
  margin: 30px 25px 15px 25px;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #ffffff;
  letter-spacing: normal;
  flex: 1 0 42px;
  align-items: center;
  .menu.transition.visible {
    .active {
      color: #5158cf !important;
    }
  }
  .task_dropdown_text {
    font-weight: 600;
    font-size: 22px;
    .item {
      font-weight: bold;
    }
  }
`;

export const TaskButton = styled.button`
  span {
    font-size: 24px;
  }
  .add_task {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  background: none;
  color: #ffffff;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  padding: 0;
  cursor: pointer;
  :active,
  :focus {
    border: none;
    outline: none;
  }
  svg {
    height: 20px;
    width: 20px;
    :hover {
      path {
        stroke: #5158cf;
      }
    }
  }
  .__react_component_tooltip {
    &.app-tooltip-delete {
      &.show {
        font-size: 13px;
        color: #ffffff;
        line-height: 150%;
        display: flex;
        font-family: Open Sans;
        width: 103px;
        padding: 0px 0px;
        margin: 3px 0px 0px 2px;
        font-style: normal;
        font-weight: normal;
        justify-content: center;
        align-items: center;
        top: 43px !important;
        height: 40px;
        background: #2d2e2d;
        border-radius: 5px;
      }
      ::after {
        left: 49.5%;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        bottom: -3px;
        border-top-color: #2d2e2d;
      }
    }
  }
`;

export const InputContainer = styled.div`
  position: absolute;
  width: 339px;
  height: 400px;
  border-radius: 8px;
  border: 1px solid #eceef4;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  background: #fafbfc;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 9999;
  background: #ffffff;
  .rdt {
    .rdtPicker {
      border: none;
      outline: none;
      box-shadow: none;
      margin: 0;
      top: -330px;
      left: 11px;
      width: 290px;
      .rdtDays,
      .rdtMonths,
      .rdtYears {
        .rdtSwitch {
          height: 70px;
        }
        .rdtDay {
          &.rdtActive {
            ::before {
              width: 33px;
              height: 33px;
              top: 0px;
              background: #5158cf;
              border: 1px solid #5158cf;
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
          :hover:not(.rdtActive) {
            ::before {
              width: 33px;
              height: 33px;
              top: 0px;
              background: #ffffff;
              border: 1px solid #5158cf;
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
`;

export const TaskDetail = styled.div`
  width: 223px;
  font-size: 11px;
  font-weight: 400;
  margin-top: 10px;
  line-height: 19.98px;
  line-height: 15px;
  color: #8f91a9;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  cursor: default;
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: -91.5px;
  top: calc(100% + 12px);
  width: 411px;
  max-height: calc(100vh - 60px);
  height: 690px;
  border-radius: 8px;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #e9ecee;
  display: none;
  background: #ffffff;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 999 !important;
  &.list {
    background: #fafbfc;
    background-image: url(${photo});
    background-repeat: no-repeat;
  }
  :before {
    content: '';
    position: absolute;

    top: ${props => (!props.mode ? '-6px' : '-7px')};
    right: 91.5px;
    border-top: 1px solid ${props => (!props.mode ? '#525bb8' : '#E9ECEE')};
    border-left: 1px solid ${props => (!props.mode ? '#525bb8' : '#E9ECEE')};
    width: 13px;
    height: 13px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: ${props => (!props.mode ? '#525bb8' : '#FFFFFF')};
  }
`;

export const InputBody = styled.div`
  height: 70px;
  border-top: 1px solid #d9dfe5;
  margin: 0 25px;
`;

export const ToggleReminder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  .toggle {
    display: flex;
    align-items: center;
    .__react_component_tooltip.app-tooltip {
      z-index: 999999;
    }
    svg {
      width: 12px;
      height: 12px;
      margin-left: 6px;
      :not(:hover) {
        path {
          fill: #a6acc6;
        }
      }
      :hover {
        path {
          fill: #5158cf;
        }
      }
    }
  }

  .toggle-input {
    /* margin-right: 15px; */
    font-size: 12px;
    input {
      ~ label {
        ::before {
          background-color: #5158cf;
        }
      }
    }
    :not(.checked) {
      color: #728096;
      input {
        ~ label {
          ::before {
            background-color: #e4e5e7;
          }
        }
      }
    }
    .text-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;

export const Body = styled.div`
  min-height: auto;
  max-height: 582px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  flex: 1 1;
  &.notlist {
    height: 553px;
    overflow-x: unset !important;
    overflow-y: visible !important;
  }
  /* .Toastify__toast.notification_due{
    width: 419px !important;
    height:157px !important;
  }  */
`;

export const Wrapper = styled.div`
  position: relative;
  background: #fafbfc;
  &.open {
    ${MenuContainer} {
      display: flex;
    }
  }
`;

export const CalendarWrapper = styled.div`
  position: relative;
  :hover {
    background: #fff;
  }
  &.open {
    ${InputContainer} {
      display: flex;
    }
  }
`;

export const InputTask = styled.div`
  width: 361px;
  height: 36px;
  border: 1px solid transparent;
  background: #f8f8f9;
  border-radius: 3px;
  display: flex;
  font-size: 13px;
  line-height: 19.5px;
  align-items: center;
  transition: border-color 100ms ease 0s;
  justify-content: space-between;
  cursor: pointer;

  .margin-right {
    margin-right: 14px;
  }
  .space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left_content {
    display: flex;
    width: 100%;
  }
  :hover,
  :active,
  :focus {
    border: 1px solid #5158cf;
  }
  .date_time {
    color: #202353;
    padding-left: 11.5px;
    font-weight: 600;
  }
  .image {
    padding-left: 9.5px;
    height: 100%;
    display: flex;
    align-items: center;
    .clock_icon {
      color: #8e90a9;
      &.has_value {
        color: #202353;
      }
    }
  }
  .due_time {
    padding-left: 9px;
    opacity: 0.4;
  }
  .evf-dropdown--basic {
    width: 100%;
    &.open {
      .evf-dropdown__menu {
        display: flex;
        width: 306px;
        height: 76px;
        align-items: center;
        justify-content: space-around;
        background: #fafbfc;
        border: 1px solid #e9ecee;
        box-sizing: border-box;
        box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 0 25px;
        margin-left: 55px;
        margin-top: 6px;
      }
    }
  }
  .evf-dropdown__option {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    .color_block {
    }
  }
  .selected_color_block {
    padding: 13px;
    border-radius: 5px;
  }
  .no_color {
    display: flex;
    padding-left: 11.5px;
    .input_text {
      padding-left: 11px;
      opacity: 0.4;
    }
  }
  .input_color {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    cursor: pointer;
    border: 1px solid transparent;
    .input_text {
      padding-left: 10.5px;
      font-weight: 600;
      color: #202353;
    }
  }
`;
