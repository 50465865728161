import React from 'react';
import * as S from './style';

const ExerciseReferences = props => {
  const { set, setIndex } = props;

  return (
    <S.ExerciseReferences key={set._id || setIndex} className="exercise">
      <S.ExerciseBaseInfo>
        <S.ExerciseTitle>{set.title}</S.ExerciseTitle>
      </S.ExerciseBaseInfo>
    </S.ExerciseReferences>
  );
};

export default ExerciseReferences;
