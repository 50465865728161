import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Mixpanel } from 'utils/mixplanel';
import { pluralize } from 'utils/commonFunction';
import { Modal, Button as CloseButton, Image, Radio } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
// import constant
import { CDN_URL, CONNECT_FORUM_TYPES } from 'constants/commonData';
// import actions
import { toggleModal } from 'actions/modal';
import { autoflowInvite } from 'redux/autoflow/forum/actions';
// import assets
import ForumInviteImg from 'assets/images/forum-invite.png';
import * as S from './style';

const PauseModal = props => {
  const [selectedType, setSelectedType] = useState(CONNECT_FORUM_TYPES.ACTIVE);
  const { autoflowId, forumDetail, autoflowInvite, numberClients } = props;
  const forumInfo = forumDetail ? forumDetail.toJS() : {};

  const onChangeType = (e, { value }) => setSelectedType(value);

  const getClients = () => {
    if (!numberClients) return 'Clients';

    if (selectedType === CONNECT_FORUM_TYPES.ACTIVE) return pluralize(`active client`, numberClients.active, true);

    return pluralize(`active client`, numberClients.total, true);
  };

  const onInviteClient = () => {
    if (selectedType === CONNECT_FORUM_TYPES.ACTIVE) {
      Mixpanel.track('autoflow_leaderboard_screen_invite_active_client');
    } else {
      Mixpanel.track('autoflow_leaderboard_screen_invite_all_client');
    }
    autoflowInvite(autoflowId, forumInfo._id, selectedType).then(response => {
      toast(`${pluralize(`new client`, get(response, 'data.data.addedClient'), true)}  have been added to Forum`);
    });
  };

  return (
    <S.ModalStyle
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={ForumInviteImg} />
            <span>Invite Autoflow clients</span>
          </S.Header>
          <S.Body>
            {selectedType === CONNECT_FORUM_TYPES.ACTIVE && (
              <div>
                {getClients()} from the Autoflow will be added to ‘{forumInfo.name}’. Some clients may already be in the
                forum.
              </div>
            )}
            {selectedType === CONNECT_FORUM_TYPES.ALL && (
              <div>
                {getClients()} including active, paused, pending, and completed from the Autoflow will be added to ‘
                {forumInfo.name}’. Some clients may already be in the forum.
              </div>
            )}

            <div className="connect-forum-type">
              <Radio
                label={
                  <label>
                    Invite <b>only Active Autoflow Clients {numberClients && `(${numberClients.active})`}</b> to the
                    Forum
                  </label>
                }
                name="connectForumType"
                value={CONNECT_FORUM_TYPES.ACTIVE}
                checked={selectedType === CONNECT_FORUM_TYPES.ACTIVE}
                onChange={onChangeType}
              />
              <Radio
                label={
                  <label>
                    Invite <b>All Autoflow Clients {numberClients && `(${numberClients.total})`}</b> to the Forum
                  </label>
                }
                name="connectForumType"
                value={CONNECT_FORUM_TYPES.ALL}
                checked={selectedType === CONNECT_FORUM_TYPES.ALL}
                onChange={onChangeType}
              />
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button className="action-btn" onClick={onInviteClient}>
              Invite
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </S.ModalStyle>
  );
};

const mapState = (state, props) => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  const { forum } = rootReducer.autoflow;

  return {
    autoflowId: workingAutoflow && workingAutoflow._id,
    forumDetail: forum.get('forumDetail'),
  };
};

const mapDispatch = (dispatch, props) => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    autoflowInvite: bindActionCreators(autoflowInvite, dispatch),
  };
};

export default connect(mapState, mapDispatch)(PauseModal);
