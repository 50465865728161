import get from 'lodash/get';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: block;
  background: #fff;
  border: 1px solid #dcdcde;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 10px;
`;

export const MenuTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 34px;
  background: #f8f8f8;
  padding-left: 15px;
`;

export const MenuTitleText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #000;
`;

export const SizedBox = styled.div`
  height: 10px;
`;

function Menu(props) {
  return (
    <components.Menu {...props}>
      {get(props, 'selectProps.className') !== 'select-participants' ? (
        <>
          <MenuTitleContainer>
            <MenuTitleText>contacts</MenuTitleText>
          </MenuTitleContainer>
          <SizedBox />
        </>
      ) : null}
      {props.children}
    </components.Menu>
  );
}

export default Menu;
