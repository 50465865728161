import { schema } from 'normalizr';
import { ENTITY_NAME } from './constants';
import { localSectionId } from 'utils/commonFunction';

export const ExerciseLibrary = new schema.Entity(ENTITY_NAME.ExerciseLibrary, {}, { idAttribute: '_id' });

export const TrainingSet = new schema.Entity(ENTITY_NAME.TrainingSet, {}, { idAttribute: '_id' });

export const SuperSet = new schema.Entity(
  ENTITY_NAME.Superset,
  { training_sets: [TrainingSet], exercise: ExerciseLibrary, alternatives: [ExerciseLibrary] },
  { idAttribute: '_id' },
);

export const Exercise = new schema.Entity(ENTITY_NAME.Exercise, { supersets: [SuperSet] }, { idAttribute: '_id' });

export const Section = new schema.Entity(
  ENTITY_NAME.Section,
  { exercises: [Exercise] },
  { idAttribute: localSectionId },
);

export const Workout = new schema.Entity(ENTITY_NAME.Workout, { sections: [Section] }, { idAttribute: '_id' });

export const S3PresignedURL = new schema.Entity(ENTITY_NAME.S3PresignedURL, {}, { idAttribute: 'src' });

export const OneRepMax = new schema.Entity(ENTITY_NAME.OneRepMax, {}, { idAttribute: 'id' });
