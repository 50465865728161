import { getGroupMetrics } from 'actions/groupMetric';
import get from 'lodash/get';
import omit from 'lodash/omit';
import Request from 'configs/request';
import moment from 'moment';
import { getOptionFromRange } from 'components/BodyMetricChartNew/constants';
import { Types as BodyMetricTypes } from 'actions/bodyMetric';

export const Types = {
  CLIENT_REQUEST_GET_HEART_CHART_DATA: 'CLIENT_REQUEST_GET_HEART_CHART_DATA',
  CLIENT_SUCCESS_GET_HEART_CHART_DATA: 'CLIENT_SUCCESS_GET_HEART_CHART_DATA',
  CLIENT_FAILED_GET_HEART_CHART_DATA: 'CLIENT_FAILED_GET_HEART_CHART_DATA',
  CLIENT_REQUEST_GET_HEART_ENTRIES_BY_DAY: 'CLIENT_REQUEST_GET_HEART_ENTRIES_BY_DAY',
  CLIENT_SUCCESS_GET_HEART_ENTRIES_BY_DAY: 'CLIENT_SUCCESS_GET_HEART_ENTRIES_BY_DAY',
  CLIENT_FAILED_GET_HEART_ENTRIES_BY_DAY: 'CLIENT_FAILED_GET_HEART_ENTRIES_BY_DAY',
  CLIENT_SUCCESS_ADD_HEART_RATE_ENTRY: 'CLIENT_SUCCESS_ADD_HEART_RATE_ENTRY',
  CLIENT_SUCCESS_UPDATE_HEART_RATE_ENTRY: 'CLIENT_SUCCESS_UPDATE_HEART_RATE_ENTRY',
  CLIENT_SUCCESS_REMOVE_HEART_RATE_ENTRY: 'CLIENT_SUCCESS_REMOVE_HEART_RATE_ENTRY',
};

const updateChartAfterActionEntry = uniqueCode => {
  return (dispatch, getState) => {
    const currentState = getState();
    const {
      bodyMetric: { filterTime = {}, unique_code },
      rootReducer: { client },
    } = currentState;

    const timezone = moment.tz.guess();

    if (unique_code === uniqueCode) {
      const clientId = client.workingClientDetail ? client.workingClientDetail._id : undefined;

      const params = {
        period_group: filterTime.period_group,
        from_date: filterTime.from_date,
        to_date: filterTime.to_date,
        client: clientId,
        timezone,
      };
      dispatch(getHeartRateChartData(params));
    }
  };
};

export const getHeartRateEntriesByDay = (isByWeek = false) => {
  return (dispatch, getState) => {
    const currentState = getState();
    const {
      rootReducer: { heartRateMetric, client },
    } = currentState;
    const { next_date = null, previous_date = null } = heartRateMetric;
    const clientId = client.workingClientDetail ? client.workingClientDetail._id : undefined;
    const timeString = 'YYYY-MM-DD';
    const today = moment(new Date()).format(timeString);
    const add1Month = today.subtract(1, 'M');

    const defaultParams = {
      period_group: isByWeek ? 'weekly' : 'daily',
      from_date: previous_date || add1Month,
      to_date: next_date || today,
      client: clientId,
      sort: 'desc',
    };

    dispatch({
      type: Types.CLIENT_REQUEST_GET_HEART_ENTRIES_BY_DAY,
    });

    return dispatch(
      Request.get(
        {
          url: '/api/heart-rate-entries/chart-data',
          params: defaultParams,
        },
        true,
        (response, { dispatch }) => {
          const { data } = response;
          dispatch({ type: Types.CLIENT_SUCCESS_GET_HEART_ENTRIES_BY_DAY, payload: data });
        },
      ),
      () => {
        dispatch({
          type: Types.CLIENT_FAILED_GET_HEART_ENTRIES_BY_DAY,
        });
      },
    );
  };
};

export const getHeartRateChartData = params => {
  return dispatch => {
    dispatch({
      type: Types.CLIENT_REQUEST_GET_HEART_CHART_DATA,
    });
    const FORMAT_DATE = 'yyyy-MM-DD';
    const startDate = moment(params.from_date, FORMAT_DATE);
    const endDate = moment(params.to_date, FORMAT_DATE);

    const filterByDate = {
      from_date: params.from_date,
      to_date: params.to_date,
      period_group: params.period_group,
      timeRangeString: getOptionFromRange({ startDate, endDate }),
    };
    dispatch({ type: BodyMetricTypes.UPDATE_FILTER_TIME_METRIC_CHART, payload: filterByDate });

    return dispatch(
      Request.get(
        {
          url: '/api/heart-rate-entries/chart-data',
          params: { ...omit(params, ['timeRangeString', 'data_point', 'metric_code']) },
        },
        false,
        (response, { dispatch }) => {
          const { data, value_range } = response.data;
          dispatch({ type: Types.CLIENT_SUCCESS_GET_HEART_CHART_DATA, payload: { data, value_range } });
        },
      ),
      () => {
        dispatch({
          type: Types.CLIENT_FAILED_GET_HEART_CHART_DATA,
        });
      },
    );
  };
};

export const addHeartRateEntry = params => {
  const unitId = get(params, 'unit._id', '');
  return Request.post(
    { url: `/api/heart-rate-entries`, data: { ...params, unit: unitId } },
    true,
    (response, { dispatch }) => {
      dispatch(updateChartAfterActionEntry(params.unique_code));
      const { data } = response.data;
      dispatch(getGroupMetrics((params || {}).client));
      dispatch({ type: Types.CLIENT_SUCCESS_ADD_HEART_RATE_ENTRY, payload: { data } });
    },
  );
};

export const updateHeartRateEntry = (params, entryId) => {
  return Request.patch(
    { url: `/api/heart-rate-entries/${entryId}`, data: { ...params } },
    true,
    (response, { dispatch }) => {
      dispatch(updateChartAfterActionEntry(params.unique_code));
      const { data } = response.data;
      dispatch(getGroupMetrics((params || {}).client));
      dispatch({ type: Types.CLIENT_SUCCESS_UPDATE_HEART_RATE_ENTRY, payload: { data } });
    },
  );
};

export const removeHeartRateEntry = (entryId, clientId) => {
  return Request.delete({ url: `/api/heart-rate-entries/${entryId}` }, false, (response, { dispatch, getState }) => {
    const { bodyMetric } = getState();
    dispatch(updateChartAfterActionEntry(bodyMetric.unique_code));
    dispatch(getGroupMetrics(clientId));
    dispatch({ type: Types.CLIENT_SUCCESS_REMOVE_HEART_RATE_ENTRY });
  });
};

export const getHeartRateDetailEntriesByDay = (params, isLoading = false) => {
  return Request.get({ url: '/api/heart-rate-entries', params }, isLoading);
};

export const getHeartRateChartDataOverview = (params, isLoading = false) => {
  return Request.get({ url: '/api/heart-rate-entries/chart-data', params }, isLoading);
};
