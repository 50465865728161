import React from 'react';
import { bindActionCreators } from 'redux';
import { Accordion, Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';

import * as S from './style';
import CollectionResourceItem from './CollectionResourceItem';
import { toggleModal } from 'actions/modal';
import AddResourcesPopup from './AddResourcesPopup';
import { removeResourceFromRedux, updateSectionInfo } from 'redux/studio-collection/actions';
import SectionNameInput from './SectionNameInput';
import { mongoObjectId } from 'utils/commonFunction';
import SettingLayout from './SettingLayout';
import SectionOptions from './SectionOptions';
import EditModal from './EditModal';

const allResources = sections => {
  let resources = sections.map((it = {}) => it.resources || []);
  resources = flatten(resources);
  resources = uniqBy(resources, '_id');
  return resources;
};

class CollectionSection extends React.PureComponent {
  state = {
    isActive: true,
  };
  onAddResource = () => {
    const { section, workingCollection, disabled = false } = this.props;
    if (disabled) return;
    this.props.toggleModal(
      true,
      <AddResourcesPopup section={section} resources={allResources(workingCollection.sections)} />,
    );
  };

  onRemove = resourceId => {
    this.props.removeResourceFromRedux(this.props.section._id, resourceId);
  };

  onChangeName = name => {
    const { section, isNewSection, onCreateSection, updateSectionInfo } = this.props;
    if (isNewSection && name) {
      const id = mongoObjectId();
      onCreateSection();
      updateSectionInfo(id, { _id: id, section_name: name, resources: [], format: 'list' }, true);
      this.scrollToBottom();
      return;
    }
    updateSectionInfo(section._id, { section_name: name });
  };

  handleOnMouseOut = event => {
    event.target.blur();
  };

  handleExpand = () => {
    const { isActive } = this.state;
    const { isNewSection } = this.props;
    !isNewSection && this.setState({ isActive: !isActive });
  };

  handleChangeFormat = format => {
    const { section, updateSectionInfo } = this.props;
    updateSectionInfo(section._id, { format: format });
  };

  // Scroll to bottom of div.collectionResources__info
  scrollToBottom = () => {
    // TODO
    setTimeout(() => {
      const element = document.getElementById('collectionResources__info');
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }, 200);
  };

  handleEditDescription = () => {
    const { section } = this.props;
    this.props.toggleModal(
      true,
      <EditModal section={section} onClose={this.handleCancelEdit} onSave={this.handleSaveEditDesc} />,
    );
  };

  handleCancelEdit = () => {
    this.props.toggleModal(false);
  };

  handleSaveEditDesc = desc => {
    const { section, updateSectionInfo } = this.props;
    updateSectionInfo(section._id, { description: desc });
    this.handleCancelEdit();
  };

  render() {
    const {
      section,
      placeholderProps,
      isNewSection,
      isFirstSection,
      isLastSection,
      currentIndex,
      onRemove,
      onSwap,
      disabled = false,
      resources_per_collection,
    } = this.props;
    const { isActive } = this.state;
    return (
      <S.Wrapper>
        <Accordion>
          <Accordion.Title onClick={this.handleExpand} index={0}>
            <div className={`collectionSection__heading ${isNewSection && 'collectionSection__new'}`}>
              <S.SectionInfoWrapper className={`${isNewSection && 'newSectionInput'}`}>
                {!isNewSection && <Icon name={`caret ${isActive ? 'down' : 'right'}`} />}
                <SectionNameInput
                  name={section.section_name}
                  counter={section.resources.length || 0}
                  onConfirmChange={this.onChangeName}
                  onMouseOut={this.handleOnMouseOut}
                  isNewSection={isNewSection}
                  isCollapse={!isActive}
                  disabled={disabled}
                />
              </S.SectionInfoWrapper>
              {!disabled && !isNewSection && (
                <S.SectionActions>
                  <S.SettingsWrapper>
                    <SettingLayout format={section.format} onChangeFormat={this.handleChangeFormat} />
                  </S.SettingsWrapper>
                  <S.MoreActionsWrapper>
                    <SectionOptions
                      isFirstSection={isFirstSection}
                      isLastSection={isLastSection}
                      currentIndex={currentIndex}
                      onSwap={onSwap}
                      onRemove={onRemove}
                      onEdit={this.handleEditDescription}
                    />
                  </S.MoreActionsWrapper>
                </S.SectionActions>
              )}
            </div>
          </Accordion.Title>
          <Accordion.Content active={isActive && !isNewSection}>
            {!isNewSection && (
              <>
                <Droppable droppableId={section._id}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className="collectionSection__list">
                      {!isNewSection &&
                        section.resources.map((resource, index) => (
                          <CollectionResourceItem
                            index={index}
                            resource={resource}
                            key={resource._id}
                            onRemove={this.onRemove}
                            disabled={disabled}
                          />
                        ))}
                      {provided.placeholder}
                      {snapshot.isUsingPlaceholder && snapshot.isDraggingOver && (
                        <S.DraggablePlacehoder
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight,
                            width: placeholderProps.clientWidth,
                            borderWidth: placeholderProps.clientWidth ? '1px' : '0px',
                          }}
                        />
                      )}
                      {!section.resources.length ? (
                        <S.AddResource onClick={this.onAddResource} disabled={disabled}>
                          Add Resource
                        </S.AddResource>
                      ) : section.resources.length < resources_per_collection ? (
                        <div
                          onClick={this.onAddResource}
                          className={classNames('collectionSection__addResourseBtn', { disabled })}
                        >
                          + Add Resource
                        </div>
                      ) : null}
                    </div>
                  )}
                </Droppable>
              </>
            )}
          </Accordion.Content>
        </Accordion>
      </S.Wrapper>
    );
  }
}

const mapStateToProps = state => {
  const {
    rootReducer: { permission = {} },
  } = state;

  return {
    resources_per_collection: permission.resources_per_collection || 25,
    workingCollection: state.rootReducer.studioCollection.workingCollection,
  };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  removeResourceFromRedux: bindActionCreators(removeResourceFromRedux, dispatch),
  updateSectionInfo: bindActionCreators(updateSectionInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(CollectionSection);
