import styled from 'styled-components';

export const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-bottom: 40px;
`;

export const Wrapper = styled.div`
  margin-bottom: 5px;
`;

export const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
    display: block;
  }
`;

export const GroupControls = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Input = styled.input`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
  padding: 11px 164px 11px 29px;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const EndPeriodContainer = styled.div`
  margin-top: -6px;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;

  .evfSelectBoxContainer {
    margin: 0 10px 0 15px;
  }

  .ui {
    &.checkbox {
      label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal !important;
        padding-left: 28px !important;
        font-size: 13px;
        line-height: 150%;
        color: #202353 !important;
      }
    }
  }
`;

export const InvoiceRepeatContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
`;

export const TotalAmountContainer = styled.div`
  div {
    :first-child {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: right;
      color: #202353;
      opacity: 0.8;
      margin-bottom: 4px;

      .value {
        font-weight: 600;
      }
    }

    :last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #202353;
      opacity: 0.6;
    }
  }
`;
