import React from 'react';
import { connect } from 'react-redux';
import { TYPE_FREQUENCY } from '../../../../constants';
import { getPercent, formatDate } from '../../../../helps';
import * as S from './style';

const CompleteWeek = ({
  type,
  currentAverageHabitCompleted,
  currentTotalHabitCompleted,
  averageHabitCompleted,
  totalHabitCompleted,
  sinceDate,
  monthCurrent,
}) => {
  return (
    <S.CompleteWeek>
      <S.Label>Completed {type === TYPE_FREQUENCY.PER_DAY ? 'Days' : 'Weeks'}</S.Label>
      <S.Item textAlign="right" paddingRight="10px">
        <S.Title>
          <span className="percent">{getPercent(currentAverageHabitCompleted, currentTotalHabitCompleted)}</span>
          <span className="ratio">
            {currentAverageHabitCompleted}/{currentTotalHabitCompleted}
          </span>
        </S.Title>
        <S.Desc>{monthCurrent}</S.Desc>
      </S.Item>
      <S.Item textAlign="right">
        <S.Title>
          <span className="percent">{getPercent(averageHabitCompleted, totalHabitCompleted)}</span>
          <span className="ratio">
            {averageHabitCompleted}/{totalHabitCompleted}
          </span>
        </S.Title>
        <S.Desc>Since {formatDate(sinceDate, 'MMM DD')}</S.Desc>
      </S.Item>
    </S.CompleteWeek>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteWeek);
