import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import VideoDuration from 'shared/VideoDuration';
import { getDuration } from 'utils/commonFunction';
import { FILE_VALIDATIONS } from '../constants';
import { CDN_URL } from 'constants/commonData';

export const SelectedFiles = props => {
  const { index, selectedFiles } = props;

  return (
    <MediaContainer className="selected-files">
      {selectedFiles.map(item => {
        const { attachment_type, objectURL } = item;

        return (
          <MediaItem className="item" key={objectURL}>
            {attachment_type.includes('video') ? (
              <>
                <video
                  className="media"
                  onLoadedMetadata={event => {
                    if (event.target && event.target.duration) {
                      const { duration, nextElementSibling } = event.target;

                      if (nextElementSibling && nextElementSibling.className.includes('video-duration')) {
                        const time = getDuration(duration);
                        const timeEl = nextElementSibling.querySelector('.time');

                        if (timeEl) {
                          timeEl.innerText = time;
                        }
                      }
                    }

                    event.persist();
                  }}
                >
                  <source src={objectURL} type={attachment_type} />
                  <source src={objectURL} type="video/mp4" />
                </video>
                <VideoDuration />
              </>
            ) : (
              <img className="media" src={objectURL} alt="" />
            )}
            <img
              src={`${CDN_URL}/images/close_circle.svg`}
              onClick={() => props.onDelete(index)}
              className="remove-icon"
              alt=""
            />
          </MediaItem>
        );
      })}
    </MediaContainer>
  );
};

export const SelectFileInput = props => {
  const { disabled, hasText } = props;
  const disableSelectFile = disabled || hasText;

  return (
    <FileInputContainer
      className={classnames('select-files-input', { disabled: disableSelectFile })}
      data-tip
      data-for="chat-input-upload-media-tooltip"
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="#F5F5F6" />
        <path
          d="M21.25 13H19.375L18.1 11.05C17.95 10.9 17.725 10.75 17.5 10.75H14.5C14.275 10.75 14.05 10.9 13.9 11.05L12.625 13H10.75C10.3 13 10 13.3 10 13.75V20.5C10 20.95 10.3 21.25 10.75 21.25H21.25C21.7 21.25 22 20.95 22 20.5V13.75C22 13.3 21.7 13 21.25 13ZM16 19C14.725 19 13.75 18.025 13.75 16.75C13.75 15.475 14.725 14.5 16 14.5C17.275 14.5 18.25 15.475 18.25 16.75C18.25 18.025 17.275 19 16 19Z"
          fill="#95A6B7"
        />
      </svg>
      {hasText ? (
        <ReactTooltip
          className="app-tooltip autoflow-exactdate-tooltip"
          id="chat-input-upload-media-tooltip"
          effect="solid"
          place="top"
        >
          <p>Please send message first before adding attachment</p>
        </ReactTooltip>
      ) : (
        <ReactTooltip className="app-tooltip" id="chat-input-upload-media-tooltip" effect="solid" place="top">
          <span>Upload Media</span>
        </ReactTooltip>
      )}
      <input
        type="file"
        accept={FILE_VALIDATIONS.ACCEPTED}
        disabled={disableSelectFile}
        multiple={false}
        onChange={props.onChange}
      />
    </FileInputContainer>
  );
};

const MediaItem = styled.div`
  width: 72px;
  height: 72px;
  margin-right: 10px;
  position: relative;

  img,
  video {
    &.media {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .remove-icon {
    position: absolute;
    right: -6px;
    top: -6px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    display: block;
  }

  :last-child {
    margin-right: 0;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const FileInputContainer = styled.div`
  position: relative;
  height: 32px;

  :not(.disabled):hover {
    svg {
      rect {
        fill: #d9dfe3;
      }
    }
  }

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 1;
    text-indent: -9999px;
    cursor: pointer;
  }

  &.disabled {
    input {
      cursor: not-allowed;
    }
  }

  .__react_component_tooltip.app-tooltip-white {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    padding: 12px 15px 10px;
    width: 180px;
  }
`;
