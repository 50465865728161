import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeQueryParams } from 'redux/contact-list/actions';
import Pagination from 'shared/Pagination';

function View(props) {
  const { page, pageSize, total, changeQueryParams, list } = props;

  const length = list ? list.length : null;

  if (!length || total <= pageSize) {
    return null;
  }

  return (
    <Pagination
      page={page}
      perPage={pageSize}
      totalPage={total}
      onPrev={() => {
        changeQueryParams({ page: page - 1 });
      }}
      onNext={() => {
        changeQueryParams({ page: page + 1 });
      }}
    />
  );
}

const TablePagination = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      contactList: {
        list,
        total,
        queryParam: { page, pageSize },
      },
    },
  } = state;

  return { total, page, pageSize, list };
};

const mapDispatch = dispatch => ({
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
});

export default connect(mapState, mapDispatch)(TablePagination);
