import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleConfirmModal } from 'actions/modal';
import { saveSectionToLibrary } from 'actions/sectionLibrary';
import SectionDetail from './component';

const mapStateToProps = state => ({
  unit: state.user.preferences,
});

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  saveSectionToLibrary: bindActionCreators(saveSectionToLibrary, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionDetail);
