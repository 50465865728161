import React from 'react';
import ListExercises from 'shared/ListExercises';
import _ from 'lodash';
import moment from 'moment';
import diff from 'deep-diff';
import Floater from 'react-floater';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { Segment, Image, Icon, Dropdown, Button, Modal } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import Countdown, { zeroPad } from 'react-countdown';
import CountUp from 'react-countup';
import TimeField from 'react-simple-timefield';
import { mongoObjectId, pluralize } from 'utils/commonFunction';
import { ExerciseSet } from 'types/model';
import {
  SECTION_FORMAT,
  SECTION_TYPE,
  SECTION_ACTIONS,
  SECTION_FORMAT_KEY,
  SECTION_FORMAT_OPTIONS,
  floaterStyles,
  AUTO_FILL_FIELDS,
  CDN_URL,
} from 'constants/commonData';
import ImageButton from 'shared/ImageButton';
import ExerciseDetail from 'components/ExerciseDetail';
import ConfirmModal from 'shared/ConfirmModal';
import ContentEditable from 'shared/ContentEditable';
import MenuItem from 'shared/MenuItem';
import { Button as FreestyleButton } from 'shared/FormControl';
import { roundTwoDigit } from 'helpers/number';
import { parseSectionExercises, spacingToBottom, checkRoundCompleted } from 'helpers/workout';
import { convertSecondToTimeStringForRest, convertHoursStringToSecondForRest } from 'helpers/time';
import SelectSection from 'components/SelectSection';

import SupersetLink from './components/SupersetLink';
import FreestyleAttachment from './components/FreestyleAttachment';

import classnames from 'classnames';
import './style.scss';
import * as S from './style.js';

import { ReactComponent as CheckMark } from 'assets/icons/check-mark-freestyle-section.svg';
import { ReactComponent as CollapseUp } from 'assets/icons/collapse_up.svg';
import { ReactComponent as MarkAllIcon } from 'assets/icons/mask-all-icon.svg';
import { ReactComponent as SectionCollapseIcon } from 'assets/icons/arrow-collapse-icon.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link_set.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play_icon_white.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/pause_icon.svg';
import { ReactComponent as StopIcon } from 'assets/icons/stop_icon_white.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TimeUp } from 'assets/images/time-up.svg';
import TextEditable from 'shared/TextEditable';
import { convertSecondToMinuteString, convertSecondToTimeString } from 'helpers/time';
import { copyStudioProgramWorkout } from 'redux/studio-program/actions';
import ReactTooltip from 'react-tooltip';
import { autoFillInput } from 'components/ExerciseDetail/helpers';

const SECTION_TYPE_OPTIONS = _.map(SECTION_TYPE, (text, key) => {
  return { key, text, value: key, notIncludedChart: key !== 'workout' };
});

const floaterOptions = {
  event: 'hover',
  eventDelay: 0,
  placement: 'top',
  offset: 5,
  styles: {
    ...floaterStyles,
  },
};

const defaultRounds = [
  {
    key: 1,
    value: 1,
    active: true,
    isRoundCompleted: false,
    isValidField: true,
  },
];

const generateExerciseByRound = (props, exercise, format, rounds, init = null) => {
  const { onUpdateRoundOnSection, sectionIndex, section, onUpdateOriginWorkout } = props;
  const round = rounds;
  let exerciseList = [];
  let currentSection = section;

  if (![SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(format)) return;

  _.forEach(exercise, item => {
    _.forEach(_.get(item, 'supersets', []), ele => {
      if (_.get(ele, 'training_sets', false)) {
        let trainingSets = [];
        let trainingSetsDefault = _.cloneDeep(_.get(ele, 'training_sets', []));

        if (round > 0) {
          for (let i = 0; i < round; i++) {
            trainingSets.push(...trainingSetsDefault);
            const trainingSetsRoot = _.cloneDeep(trainingSets[i]);
            const trainingSetsRootKeys =
              trainingSetsRoot &&
              _.filter(_.keys(trainingSetsRoot), item => !['id', '_id', 'is_completed'].includes(item));

            if (!trainingSetsRoot) return;

            // When add new round
            if (i === round - 1 && SECTION_FORMAT_KEY.AMRAP === format) {
              trainingSets[i] = {
                ...trainingSetsRoot,
              };

              let newSets = trainingSets[i];

              _.forEach(trainingSetsRootKeys, keys => {
                newSets = {
                  ...newSets,
                  [keys]: trainingSetsRoot[keys], // TODO - Vinh please help to check differentiation between trainingSetsRoot[keys] and { ...trainingSetsRoot[keys] }.
                };
              });

              trainingSets[i] = {
                ...newSets,
                is_completed: false,
              };
            }

            if (i > 0 && SECTION_FORMAT_KEY.TIMED === format) {
              trainingSets[i] = {
                ...trainingSetsRoot,
              };

              let newSets = trainingSets[i];

              _.forEach(trainingSetsRootKeys, keys => {
                newSets = {
                  ...newSets,
                  [keys]: trainingSetsRoot[keys], // TODO - Vinh please help to check differentiation between trainingSetsRoot[keys] and { ...trainingSetsRoot[keys] }.
                };
              });

              trainingSets[i] = {
                ...newSets,
                is_completed: trainingSetsRoot.is_completed,
              };
            }
          }

          trainingSets = trainingSets.filter((res, index) => index < round);
        } else {
          trainingSets.push(...trainingSetsDefault);
        }

        exerciseList.push({
          ...item,
          supersets: [
            {
              ...ele,
              training_sets: trainingSets,
            },
          ],
        });
      }
    });
  });

  if (currentSection) {
    currentSection = {
      ...currentSection,
      exercises: exerciseList,
      time_complete: _.get(section, 'time_complete', 0),
      round_complete: _.get(section, 'round_complete', 0),
    };

    onUpdateRoundOnSection(currentSection, sectionIndex);
    if (init) onUpdateOriginWorkout(currentSection, sectionIndex);
  }
};

const generateTrainingTime = time => {
  const formatted = moment.utc(time * 1000).format('HH:mm:ss');
  return formatted;
};

const generateRoundTimed = (round, section, handleCountRoundCompleted) => {
  let roundByTrainingSets = 0;

  if (section.format !== SECTION_FORMAT_KEY.AMRAP && section.format !== SECTION_FORMAT_KEY.TIMED) return;

  _.forEach(section, item => {
    _.forEach(item, ele => {
      _.forEach(_.get(ele, 'supersets', []), el => {
        roundByTrainingSets = _.get(el, 'training_sets', []).length;
      });
    });
  });

  const length = roundByTrainingSets === 1 ? round : roundByTrainingSets;

  if (!length) return defaultRounds;
  let rounds = [];

  for (let i = 1; i < length + 1; i++) {
    rounds.push({
      key: i,
      value: i,
      active: false,
      isRoundCompleted: false,
      isValidField: true,
    });
  }
  rounds[0].active = true;

  _.forEach(rounds, (item, index) => {
    // Handle Check Complete ROUND
    if ([SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(section.format)) {
      handleCountRoundCompleted && handleCountRoundCompleted(section, rounds, index);
    }
  });

  return rounds;
};

class SectionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: false,
      isAddingExercise: false,
      addingExercise: this.initDefaultExecise(),
      openMenu: false,
      showCustomSection: false,
      addSectionErrors: null,
      numOfNewExercise: 0,
      sectionType: props.section.type || '',
      sectionFormat: props.section.format || '',
      openSelectSection: false,
      openExercise: false,
      showIcon: true,
      rounds: generateRoundTimed(
        _.get(props, 'section.round', 0),
        _.get(props, 'section', []),
        this.handleCountRoundCompleted,
      ),
      date: Date.now() + _.get(props, 'section.time', 0) * 1000,
      showTimeUp: false,
      trainingTime: generateTrainingTime(_.get(props, 'section.time_complete', 0)),
      isHaveNewRound: false,
      isMarkAllBySection: false,
      isTrackingSetCompleted: false,
    };
    this.boxRef = React.createRef();
    this.previousValue = 0;
  }

  componentWillReceiveProps(nextProps) {
    const { section, sectionIndex, isMarkAllSection } = nextProps;
    const { isHaveNewRound } = this.state;

    // Handle Select active round after add new (AMRAP)
    if (diff(nextProps.section, this.props.section) && isHaveNewRound) {
      this.handleSelectRound(_.get(this, 'state.rounds', []).length);
      this.setState({ isHaveNewRound: false });
    }

    // Mark All Section Handle - For checkout show highlight on round, and count round completed
    this.handleShowValidForRound(isMarkAllSection, section, sectionIndex);
  }

  componentDidUpdate(prevProps, prevState) {
    const { section } = this.props;

    if (_.get(section, 'format', '') === SECTION_FORMAT_KEY.AMRAP) {
      if (_.get(prevState, 'rounds', []).length !== _.get(this, 'state.rounds', []).length) {
        this.setState({ isHaveNewRound: true });
      }
    }

    // Check Hide Highlight Round when click checked on set (TIMED, AMRAP)
    if (prevState.isTrackingSetCompleted !== this.state.isTrackingSetCompleted) {
      let currentRound = this.state.rounds;

      _.forEach(currentRound, (item, index) => {
        if (item.active) {
          const isCheckRoundCompleted = checkRoundCompleted(section, index);

          if (isCheckRoundCompleted && !_.get(isCheckRoundCompleted, 'roundCompleted', false))
            currentRound[index].isValidField = isCheckRoundCompleted.roundCompleted;
        }
      });

      this.setState({ rounds: currentRound, isTrackingSetCompleted: false });
    }
  }

  componentDidMount() {
    const isHaveRound = [SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(
      _.get(this, 'props.section.format', ''),
    );

    if (!isHaveRound) return;

    generateExerciseByRound(
      this.props,
      _.get(this.props, 'section.exercises', []),
      _.get(this.props, 'section.format', ''),
      _.get(this.props, 'section.round', 0),
      true,
    );
  }

  initDefaultExecise = num => {
    const { section } = this.props;
    const numOfNewExercise = this.state && this.state.numOfNewExercise ? this.state.numOfNewExercise : 0;
    let exerciseInstance = new ExerciseSet();
    exerciseInstance._id = `new_exercise_${num || numOfNewExercise}-${section ? section._id : ''}`;
    return exerciseInstance;
  };

  toggleCollapse = () => {
    this.setState(prevState => ({
      isCollapse: !prevState.isCollapse,
    }));
  };

  reorderSection = (x, y) => {
    this.props.onActions(SECTION_ACTIONS.REORDER, { x, y });
  };

  moveExercise = data => {
    const { sectionIndex, section, onActions } = this.props;

    onActions(SECTION_ACTIONS.MOVE_EXERCISE, {
      ...data,
      source: {
        ...data.source,
        sectionIndex,
        _id: section._id,
      },
    });
  };

  onSelectSection = item => {
    const { onActions, section, sectionIndex } = this.props;
    let newSection = _.cloneDeep(item);
    newSection._id = section._id;
    delete newSection.isAdding;
    let num = 0;
    _.forEach(newSection.exercises, set => {
      _.forEach(set.supersets, exSet => {
        exSet._id = `new_exercise_${num}-${section._id}`;
        num++;
      });
    });

    this.setState({
      numOfNewExercise: num,
      addingExercise: this.initDefaultExecise(num),
    });

    return onActions(SECTION_ACTIONS.ADD, {
      sectionIndex,
      section: newSection,
    });
  };

  renderMoreExercises = (num, idx) => {
    return (
      <div className="more-exercises">
        <span onClick={this.toggleCollapse}>
          + {num} more exercise{num > 1 ? 's' : ''}
        </span>
      </div>
    );
  };

  renderExercises = (sets, setIdx, indexSetLinkSupperset) => {
    const {
      onChangeExerciseData,
      onCloneExercise,
      onDeleteExercise,
      onExerciseAddRemoveSet,
      unlinkSuperSet,
      tracking,
      sectionIndex,
      section,
      totalPreviousHiddenSection,
      otherSections,
      onEditExerciseLibrary,
      isFirstSectionHasLink,
      isFirstSectionHasAlterExercise,
    } = this.props;

    let exs = [];
    for (let idx = 0; idx < sets.length; idx++) {
      let itm = sets[idx];

      exs.push(
        <div key={`exercise-${itm._id}-${idx}`} style={{ padding: '8px 4px 8px 4px' }}>
          <ExerciseDetail
            mode={'edit'}
            sectionId={section._id}
            sectionFormat={section.format}
            tracking={tracking}
            setIndex={setIdx}
            exIndex={idx}
            maxLength={sets.length}
            exerciseSet={itm}
            totalPreviousHiddenSection={totalPreviousHiddenSection}
            moveOptions={otherSections}
            sectionIndex={sectionIndex}
            onChange={data => onChangeExerciseData({ ...data, sectionIndex })}
            onClone={exerciseSet => onCloneExercise(sectionIndex, exerciseSet)}
            onDelete={(setIndex, exIndex) => onDeleteExercise(sectionIndex, setIndex, exIndex)}
            onAddRemoveSet={(setIndex, exIndex, extendData = {}) =>
              onExerciseAddRemoveSet(sectionIndex, setIndex, exIndex, extendData)
            }
            onMove={this.moveExercise}
            onEditExerciseLibrary={onEditExerciseLibrary}
            isFirstSectionHasAlterExercise={isFirstSectionHasAlterExercise}
          />
          {idx !== sets.length - 1 ? (
            <SupersetLink
              unlink
              floaterOptions={floaterOptions}
              onClick={() => unlinkSuperSet(sectionIndex, idx + 1, setIdx)}
              isFirst={indexSetLinkSupperset === setIdx && idx === 0 && isFirstSectionHasLink}
              index={idx}
              indexSetLinkSupperset={idx}
            />
          ) : null}
        </div>,
      );
    }
    return exs;
  };

  renderSets = sets => {
    const {
      reorderSet,
      linkSuperSet,
      section,
      sectionIndex,
      totalSection,
      nextSectionType,
      nextSection,
      isFirstSectionHasLink,
    } = this.props;

    if (!sets) {
      return null;
    }

    if (this.props.tracking) {
      return this.renderTrackingSets(section.exercises);
    }

    let setsView = [];
    let indexSetLinkSupperset = null;
    for (let idx = 0; idx < sets.length; idx++) {
      let itm = sets[idx];
      const key = `section-${section._id}-set-${itm._id}-${idx}`;
      let reorderIcons = [],
        showLinkSuperset = false;
      const isOutsideExercise = section.type === 'hidden';

      if (isOutsideExercise) {
        if (sectionIndex > 0) {
          reorderIcons.push(
            <div key="up" className="up" onClick={() => this.reorderSection(sectionIndex, sectionIndex - 1)} />,
          );
        }

        if (sectionIndex < totalSection - 1) {
          reorderIcons.push(
            <div key="down" className="down" onClick={() => this.reorderSection(sectionIndex, sectionIndex + 1)} />,
          );
        }

        showLinkSuperset =
          nextSectionType === 'hidden' && !!_.get(nextSection, 'exercises', []).length && !!section.exercises.length;
      } else {
        if (idx > 0) {
          reorderIcons.push(<div key="up" className="up" onClick={() => reorderSet(sectionIndex, idx, idx - 1)} />);
        }

        if (idx < sets.length - 1) {
          reorderIcons.push(<div key="down" className="down" onClick={() => reorderSet(sectionIndex, idx, idx + 1)} />);

          if (section.format !== SECTION_FORMAT_KEY.AMRAP && section.format !== SECTION_FORMAT_KEY.TIMED) {
            const nextItem = _.get(sets, [idx + 1, 'supersets'], []).length;
            showLinkSuperset = !!nextItem;
          }
        }
      }

      if (indexSetLinkSupperset === null && itm.supersets.length > 1) {
        indexSetLinkSupperset = idx;
      }

      if (indexSetLinkSupperset === null && showLinkSuperset) {
        indexSetLinkSupperset = idx;
      }

      setsView.push(
        <React.Fragment key={key}>
          <div className={'ui segment hoverable'}>
            {reorderIcons.length ? <div className="reoder-set">{reorderIcons}</div> : null}
            {this.renderExercises(itm.supersets, idx, indexSetLinkSupperset)}
          </div>
          {showLinkSuperset && (
            <SupersetLink
              floaterOptions={floaterOptions}
              onClick={() => linkSuperSet(sectionIndex, idx + 1)}
              isFirst={indexSetLinkSupperset === idx && isFirstSectionHasLink && itm.supersets.length <= 1}
              indexSetLinkSupperset={indexSetLinkSupperset}
              index={idx}
            />
          )}
        </React.Fragment>,
      );
    }

    return setsView;
  };

  handleGetTrainingSet = data => {
    const newTrainingSet = _.get(data, 'exerciseSet.training_sets', []);
    let value = null;
    _.forEach(newTrainingSet, item => {
      value = { ...item };
    });

    return value;
  };

  handleChangeRound = (data, currentRound, exIndex, setIndex, section, sectionIndex, rounds) => {
    const { onUpdateSection } = this.props;
    let currentSection = _.cloneDeep(section);
    let exerciseList = _.get(section, 'exercises', []);
    let originalExerciseList = _.get(currentSection, 'exercises', []);
    const newTrainingSet = this.handleGetTrainingSet(data);

    // _.forEach(exerciseList, (item, index) => {
    //   if (setIndex === index) {
    //     _.forEach(item.supersets, (ele, o) => {
    //       if (o === exIndex) {
    //         // ele.training_sets = [..._.get(originalExerciseList, `[${index}].supersets[${o}].training_sets`)];
    //         // if (newTrainingSet) {
    //         const originalExercise = _.get(originalExerciseList, `[${index}].supersets[${o}].exercise`);

    //         // ele.training_sets[currentRound] = newTrainingSet;
    //         ele.exercise = _.get(data, 'exerciseSet.exercise', originalExercise);
    //         // ele.alternatives = _.get(data, 'exerciseSet.alternatives', []);
    //         // }
    //       }
    //     });
    //   }
    // });

    currentSection = {
      ...currentSection,
      exercises: exerciseList,
    };

    // Handle Check Complete ROUND
    if ([SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(section.format)) {
      const roundComplete = this.handleCountRoundCompleted(section, rounds, currentRound);
      currentSection = {
        ...currentSection,
        round_complete: SECTION_FORMAT_KEY.AMRAP === section.format ? roundComplete : 0,
      };

      if (_.get(data, 'isTrackingSetCompleted', false)) {
        this.setState({ isTrackingSetCompleted: true });
      }
    }

    currentSection && onUpdateSection(currentSection, sectionIndex);
  };

  handleCountRoundCompleted = (section, rounds, currentRound) => {
    let newRounds = rounds;
    let isCompleted = checkRoundCompleted(section, currentRound);

    _.forEach(newRounds, item => {
      if (_.get(isCompleted, 'round', false)) {
        if (item.key === isCompleted.round) {
          item.isRoundCompleted = isCompleted.roundCompleted;
          item.isValidField = isCompleted.isValidField;
        }
      }
    });

    this.setState({ rounds: newRounds });
    const roundComplete = _.filter(newRounds, item => item.isRoundCompleted).length;
    return roundComplete;
  };

  handleAddSetsSuperset = ({ exerciseSet, quantity, setIndex, exIndex, superSetIndex }) => {
    const { section, sectionIndex, onUpdateSection } = this.props;
    const exercises = _.get(section, `exercises[${superSetIndex}].supersets`, []);

    const otherExercises = exercises.filter(ex => ex._id !== exerciseSet._id && ex.link === exerciseSet.link);

    const other = otherExercises.map(it => {
      let newSets = [...it.training_sets];

      if (setIndex !== undefined) {
        newSets.splice(setIndex, 1);
      } else {
        for (let i = 0; i < quantity; i++) {
          const lastSet = it.training_sets.slice(-1)[0];
          newSets.push({ ...lastSet, _id: mongoObjectId(), is_completed: false });
        }
      }

      return {
        ...it,
        training_sets: newSets,
      };
    });

    const updatedSection = {
      ...this.props.section,
    };

    updatedSection.exercises[superSetIndex].supersets = [...other];
    updatedSection.exercises[superSetIndex].supersets.splice(exIndex, 0, exerciseSet);
    onUpdateSection(updatedSection, sectionIndex);
  };

  renderTrackingSets = exercises => {
    const { sectionIndex, onChangeExerciseData, section, isFirstSectionHasAlterExercise } = this.props;
    const { rounds } = this.state;
    const isHaveRound =
      SECTION_FORMAT_KEY.AMRAP === _.get(section, 'format', '') ||
      SECTION_FORMAT_KEY.TIMED === _.get(section, 'format', '');
    let currentRound = 0;

    if (rounds && isHaveRound) {
      _.map(rounds, item => {
        if (item.active) currentRound = item.value - 1;
      });

      currentRound = currentRound < 0 ? 0 : currentRound;
    }

    const blockChangeExercise = currentRound !== 0;

    if (exercises) {
      return _.map(exercises, (set, setIndex) => {
        if (set && set.supersets) {
          return (
            <div className={'ui segment hoverable'} key={set._id}>
              {isHaveRound &&
                _.map(set.supersets, (ex, exIndex) => {
                  return (
                    <>
                      <ExerciseDetail
                        blockChangeExercise={blockChangeExercise}
                        key={ex._id}
                        mode={'tracking'}
                        sectionFormat={section.format}
                        tracking={this.props.tracking}
                        sectionIndex={sectionIndex}
                        setIndex={setIndex}
                        exIndex={exIndex}
                        exerciseSet={ex}
                        isHaveRound={true}
                        currentRound={currentRound}
                        isFirstSectionHasAlterExercise={isFirstSectionHasAlterExercise}
                        onChange={data => {
                          onChangeExerciseData && onChangeExerciseData({ ...data, sectionIndex });
                          this.handleChangeRound(
                            data,
                            currentRound,
                            exIndex,
                            setIndex,
                            section,
                            sectionIndex,
                            rounds || [],
                          );
                        }}
                      />
                      {exIndex < set.supersets.length - 1 && (
                        <S.LinkSet linked>
                          <LinkIcon />
                        </S.LinkSet>
                      )}
                    </>
                  );
                })}
              {!isHaveRound &&
                _.map(set.supersets, (ex, exIndex) => {
                  return (
                    <>
                      <ExerciseDetail
                        key={ex._id}
                        mode={'tracking'}
                        sectionFormat={section.format}
                        tracking={this.props.tracking}
                        sectionIndex={sectionIndex}
                        setIndex={setIndex}
                        exIndex={exIndex}
                        exerciseSet={ex}
                        isFirstSectionHasAlterExercise={isFirstSectionHasAlterExercise}
                        onChange={data =>
                          onChangeExerciseData ? onChangeExerciseData({ ...data, sectionIndex }) : null
                        }
                        addSetsSuperset={data =>
                          this.handleAddSetsSuperset({ ...data, exIndex, superSetIndex: setIndex })
                        }
                      />
                      {exIndex < set.supersets.length - 1 && (
                        <S.LinkSet linked>
                          <LinkIcon />
                        </S.LinkSet>
                      )}
                    </>
                  );
                })}
              {![SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(section.format) && (
                <S.MarkAllInSet>{this.renderExercisesMarkAll(section, sectionIndex, set._id)}</S.MarkAllInSet>
              )}
            </div>
          );
        }

        return null;
      });
    }

    return null;
  };

  renderAddNewExercise = () => {
    const { addingExercise, isAddingExercise } = this.state;
    const {
      tracking,
      onChangeExerciseData,
      onCloneExercise,
      onDeleteExercise,
      onAddExercise,
      onActions,
      section,
      sectionIndex,
      isFirstSectionHasAlterExercise,
    } = this.props;

    if (tracking) {
      return null;
    }

    const isOutsideExercise = section.type === 'hidden' && section.isAdding;

    if (isOutsideExercise || (!section.isAdding && isAddingExercise)) {
      return (
        <Segment className="add-new-exercise-segment">
          <ExerciseDetail
            mode={'new'}
            sectionId={section._id}
            sectionFormat={section.format}
            exerciseSet={addingExercise}
            tracking={tracking}
            sectionIndex={sectionIndex}
            isFirstSectionHasAlterExercise={isFirstSectionHasAlterExercise}
            onChange={data =>
              onChangeExerciseData({
                ...data,
                sectionIndex,
                addingExercise: true,
              })
            }
            onAdd={exerciseSet => {
              this.setState(
                {
                  isAddingExercise: false,
                  addingExercise: this.initDefaultExecise(),
                },
                () => {
                  onAddExercise(sectionIndex, exerciseSet);
                },
              );
            }}
            onClone={exerciseSet => onCloneExercise(sectionIndex, exerciseSet)}
            onDelete={(setIndex, exIndex) => onDeleteExercise(sectionIndex, setIndex, exIndex)}
            onCancel={() => {
              this.setState(
                {
                  isAddingExercise: false,
                  addingExercise: this.initDefaultExecise(),
                },
                () => {
                  if (isOutsideExercise) {
                    onActions(SECTION_ACTIONS.CANCEL, sectionIndex);
                  }
                },
              );
            }}
          />
        </Segment>
      );
    }

    return null;
  };

  renderAddExerciseButton = () => {
    const { section } = this.props;
    const { numOfNewExercise, isAddingExercise } = this.state;

    if (section.isAdding || isAddingExercise) {
      return null;
    }

    return (
      <ImageButton
        className={'add-exercise-button'}
        image={`${CDN_URL}/images/plus_green.svg`}
        title="Add Exercise"
        onClick={event => {
          const currentTarget = event ? event.currentTarget : null;
          const rect = currentTarget ? currentTarget.getBoundingClientRect() : null;

          this.setState(
            {
              isAddingExercise: true,
              numOfNewExercise: numOfNewExercise + 1,
            },
            () => {
              const parentNode = this.boxRef.current ? this.boxRef.current.parentNode : null;

              if (rect && parentNode) {
                const bottomSpacing = spacingToBottom(rect);

                if (bottomSpacing < 145) {
                  setTimeout(() => {
                    parentNode.scrollTop = parentNode.scrollTop + (145 - bottomSpacing) + 15;
                  }, 0);
                }
              }
            },
          );
        }}
      />
    );
  };

  renderSectionTypeDropDown = () => {
    const { sectionIndex } = this.props;
    const { sectionType } = this.state;

    return (
      <div className="form-group section-dropdown section-type" key={'sectionType' + sectionIndex}>
        <label>Section type</label>
        <Dropdown
          placeholder="Select type"
          text={SECTION_TYPE[sectionType]}
          className="selection"
          icon={'chevron down'}
        >
          <Dropdown.Menu>
            {_.map(SECTION_TYPE_OPTIONS, item => {
              let className = item.notIncludedChart ? 'has-extend' : '';
              className += item.value === sectionType ? ' selected' : '';

              return (
                <Dropdown.Item
                  key={item.key}
                  value={item.value}
                  className={className}
                  onClick={() => {
                    if (item.value !== sectionType) {
                      this.setState({ sectionType: item.value });
                    }
                  }}
                >
                  <div>{item.text}</div>
                  {item.notIncludedChart && <div className="extend">* Not included in exercise charts</div>}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderSectionFormatDropDown = () => {
    const { sectionIndex } = this.props;
    const { sectionFormat } = this.state;

    return (
      <div className="form-group section-dropdown section-format" key={'sectionFormat' + sectionIndex}>
        <label>Section format</label>
        <Dropdown
          placeholder="Select format"
          text={SECTION_FORMAT[sectionFormat]}
          className="selection"
          icon={'chevron down'}
        >
          <Dropdown.Menu>
            {_.map(SECTION_FORMAT_OPTIONS, item => {
              return (
                <Dropdown.Item
                  key={item.key}
                  className={item.value === sectionFormat ? ' selected' : ''}
                  onClick={() => {
                    if (item.value !== sectionFormat) {
                      this.setState({ sectionFormat: item.value });
                    }
                  }}
                >
                  <div className="flex">
                    <div>{item.text}</div>
                    {item.description ? (
                      <Floater
                        {...floaterOptions}
                        placement={'right'}
                        content={<div className="section-tooltip-content">{item.description}</div>}
                      >
                        <Image src={`${CDN_URL}/images/info.svg`} width={18} height={18} />
                      </Floater>
                    ) : null}
                  </div>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  renderCustomSection = () => {
    const { showCustomSection } = this.state;
    const { onActions, sectionIndex } = this.props;

    if (!showCustomSection) {
      return null;
    }

    return (
      <div className="customize-section">
        <div className="customize-section__content">
          {this.renderSectionTypeDropDown()}
          {this.renderSectionFormatDropDown()}
          <div className="buttons">
            <Button
              className="button-cancel"
              onClick={() => {
                this.setState({ showCustomSection: false });
              }}
            >
              Cancel
            </Button>
            <Button
              className="button-save"
              onClick={() => {
                const { sectionFormat, sectionType } = this.state;
                this.setState({ showCustomSection: false, openMenu: false }, () => {
                  onActions(SECTION_ACTIONS.CUSTOMIZE, {
                    sectionIndex,
                    type: sectionType,
                    format: sectionFormat,
                  });
                });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderActionMenu = () => {
    const { section, sectionIndex, totalSection, tracking, onActions, unit, saveSectionToLibrary } = this.props;
    const { openMenu } = this.state;

    if (section.isAdding || tracking) {
      return null;
    }

    return (
      <RootCloseWrapper disabled={!openMenu} onRootClose={() => this.setState({ openMenu: false })} event="click">
        <div className={`action-menu ${openMenu ? 'open' : ''}`}>
          <div
            className="action-menu__toggle-button"
            onClick={() => this.setState(prevState => ({ openMenu: !prevState.openMenu }))}
          >
            <Icon name={'ellipsis horizontal'} color={'grey'} />
          </div>
          <div className="action-menu__content">
            <MenuItem
              icon="customize"
              onClick={() => {
                this.setState(prevState => ({
                  showCustomSection: !prevState.showCustomSection,
                  sectionType: section.type,
                  sectionFormat: section.format,
                }));
              }}
            >
              Customize section
            </MenuItem>
            {this.renderCustomSection()}
            <MenuItem
              icon="duplicate"
              onClick={() => {
                this.setState({ openMenu: false }, () => {
                  onActions(SECTION_ACTIONS.DUPLICATE, sectionIndex);
                });
              }}
            >
              Duplicate
            </MenuItem>
            <MenuItem
              icon="save"
              onClick={() => {
                const exercises = parseSectionExercises(section, unit);
                let sectionData = Object.assign({}, section, { exercises });
                delete sectionData._id;
                saveSectionToLibrary(sectionData);
                this.setState({ openMenu: false });
              }}
            >
              Save to library
            </MenuItem>
            <MenuItem
              icon="move-up"
              disabled={sectionIndex === 0}
              onClick={() => {
                if (sectionIndex === 0) {
                  return false;
                }
                this.setState({ openMenu: false }, () => {
                  this.reorderSection(sectionIndex, sectionIndex - 1);
                });
              }}
            >
              Move up
            </MenuItem>
            <MenuItem
              icon="move-down"
              disabled={sectionIndex === totalSection - 1}
              onClick={() => {
                if (sectionIndex === totalSection - 1) {
                  return false;
                }
                this.setState({ openMenu: false }, () => {
                  this.reorderSection(sectionIndex, sectionIndex + 1);
                });
              }}
            >
              Move down
            </MenuItem>
            <MenuItem icon="delete" onClick={this.handleDeleteSection}>
              Delete section
            </MenuItem>
          </div>
        </div>
      </RootCloseWrapper>
    );
  };

  handleDeleteSection = () => {
    const { toggleConfirmModal, sectionIndex, onActions } = this.props;
    this.setState(
      {
        openMenu: false,
      },
      () => {
        toggleConfirmModal(
          true,
          <ConfirmModal
            title={'Delete section'}
            content={'Are you sure that you want to delete this section?'}
            onConfirm={() => onActions(SECTION_ACTIONS.DELETE, sectionIndex)}
            onDeny={() => {}}
          />,
        );
      },
    );
  };

  handleAddSectionClick = () => {
    const { onActions, section, sectionIndex } = this.props;
    const { sectionType, sectionFormat } = this.state;
    let errors = [];

    if (!section.title) {
      errors.push('Title cannot be empty');
    }

    if (!section.type) {
      errors.push('Please select section type');
    }

    if (!section.format) {
      errors.push('Please select section format');
    }

    if (errors.length) {
      return this.setState({ addSectionErrors: errors });
    } else {
      this.setState({ addSectionErrors: null });

      let newSection = Object.assign({}, section, {
        type: sectionType,
        format: sectionFormat,
      });
      delete newSection.isAdding;
      return onActions(SECTION_ACTIONS.ADD, {
        sectionIndex,
        section: newSection,
      });
    }
  };

  onChangeSectionData = (fieldName, value) => {
    const { sectionIndex, onActions } = this.props;

    onActions(SECTION_ACTIONS.UPDATE, { sectionIndex, fieldName, value });
  };

  handleChangeIcon = () => {
    this.setState({
      showIcon: !this.state.showIcon,
    });
    if (this.state.showIcon) {
      this.startTimer();
    } else {
      this.pauseTimer();
    }
  };

  renderAddForm = () => {
    const { section, sectionIndex, onActions } = this.props;
    const { addSectionErrors } = this.state;

    return (
      <div className="add-section-form">
        <div className="form-group section-title">
          <label>Title</label>
          <div className="ui fluid input" style={{ width: '100%' }}>
            <input value={section.title || ''} onChange={e => this.onChangeSectionData('title', e.target.value)} />
          </div>
        </div>
        <div className="section-dropdowns">
          {this.renderSectionTypeDropDown()}
          {this.renderSectionFormatDropDown()}
        </div>
        <div className="add-section__footer">
          <Button className="button-cancel" onClick={() => onActions(SECTION_ACTIONS.CANCEL, sectionIndex)}>
            Cancel
          </Button>
          <Button className="button-save" onClick={this.handleAddSectionClick}>
            Add
          </Button>
        </div>
        {addSectionErrors ? (
          <ul className="error">
            {addSectionErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  };

  renderSectionFormatInput = () => {
    const { section, tracking } = this.props;

    if (tracking || section.isAdding) {
      return null;
    }

    const numberOptions = {
      type: 'number',
      className: 'small-input',
      min: 0,
      onFocus: evt => evt.target.select(),
    };
    let value = '';

    switch (section.format) {
      case 'amrap':
        value = parseFloat(section.time);
        value = isNaN(value) ? '' : value / 60;
        return (
          <div className="input-container">
            <input
              {...numberOptions}
              step={0.1}
              value={value}
              onChange={e => {
                let inputValue = e.target.value;
                inputValue = isNaN(parseFloat(inputValue)) ? '' : inputValue * 60;
                this.onChangeSectionData('time', inputValue);
              }}
            />
            <span>min(s)</span>
          </div>
        );
      case 'timed':
        value = parseInt(section.round);
        value = isNaN(value) ? '' : value;
        return (
          <div className="input-container">
            <input
              {...numberOptions}
              step={1}
              value={value}
              onChange={e => {
                if (e.target.value !== '' && isNaN(parseInt(e.target.value))) {
                  return false;
                }

                this.onChangeSectionData('round', parseInt(e.target.value));
              }}
            />
            <span>round(s)</span>
          </div>
        );
      case 'interval':
        const totalDuration = _.sumBy(section.exercises, set => {
          return _.sumBy(set.supersets, ex => {
            return _.sumBy(ex.training_sets, exSet => {
              const duration = parseInt(_.get(exSet, 'duration.value', 0));
              const rest = parseInt(_.get(exSet, 'rest.value', 0));
              return duration + rest;
            });
          });
        });

        value = roundTwoDigit(totalDuration / 60);
        return (
          <div className="input-container input-container--interval">
            <Floater
              {...floaterOptions}
              content={
                <div className="interval-tooltip-content">
                  The duration is calculated based on the workout and rest times for each of the exercises.
                </div>
              }
            >
              <input disabled value={value} onChange={() => {}} className="small-input" />
            </Floater>
            <span>min(s)</span>
          </div>
        );
      default:
        return null;
    }
  };

  handleMarkAllBySection = (section, sectionIndex) => {
    const { onUpdateSection } = this.props;
    const { rounds } = this.state;
    let currentSection = _.cloneDeep(section);
    const exercises = _.get(currentSection, 'exercises', []);
    const isHaveRound =
      SECTION_FORMAT_KEY.AMRAP === _.get(this, 'props.section.format', '') ||
      SECTION_FORMAT_KEY.TIMED === _.get(this, 'props.section.format', '');
    let currentRound = 0;

    if (isHaveRound) {
      if (rounds && isHaveRound) {
        _.map(rounds, item => {
          if (item.active) currentRound = item.value - 1;
        });

        currentRound = currentRound < 0 ? 0 : currentRound;
      }
    }

    _.forEach(exercises, set => {
      if (set && set.supersets) {
        _.forEach(set.supersets, ex => {
          const updateWeightTrainingSets = _.get(ex, 'training_sets', []).map(it => {
            // Set default value fields
            autoFillInput(it, AUTO_FILL_FIELDS);
            return it;
          });

          ex.training_sets = _.map(updateWeightTrainingSets, (exSet, index) => {
            if (isHaveRound) {
              if (currentRound === index) exSet.is_completed = true;
            } else {
              exSet.is_completed = true;
            }
            return exSet;
          });
        });
      }
    });
    section = currentSection;

    // Handle Check Complete ROUND
    if ([SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(section.format)) {
      const roundComplete = this.handleCountRoundCompleted(section, rounds, currentRound);
      section = { ...section, round_complete: SECTION_FORMAT_KEY.AMRAP === section.format ? roundComplete : 0 };
    }

    this.setState({ isMarkAllBySection: true });
    onUpdateSection(section, sectionIndex);
  };

  renderSectionMarkAll = (section, sectionIndex) => {
    return (
      <S.MarkAllSections onClick={() => this.handleMarkAllBySection(section, sectionIndex)}>
        <S.MarkAllLabel>Mark All</S.MarkAllLabel>
        <MarkAllIcon />
      </S.MarkAllSections>
    );
  };

  // TODO: Ben
  // checkInvalidSet = (fields, trainingSet) => {
  //   const checkFields = fields.filter(
  //     field =>
  //       ![
  //         'is_completed',
  //         'id',
  //         '_id',
  //         'rest',
  //         'duration',
  //         'set_type',
  //         'weight',
  //         'targetTrainingSet',
  //         'each_side_mode',
  //       ].includes(field),
  //   );

  //   const isValid = checkFields.map(field => {
  //     const value = _.get(trainingSet, `[${field}].value`);
  //     if (['rir', 'hr'].includes(field) && value === 0) {
  //       return true;
  //     }
  //     return !!value;
  //   });

  //   return isValid.length ? isValid.some(item => !item) : [false];
  // };

  // canMarkAllSection = section => {
  //   const { rounds } = this.state;
  //   const exercises = _.get(section, 'exercises', []);

  //   const isValidSection = exercises.map(set => {
  //     return (set.supersets || []).map(ex => {
  //       return (ex.training_sets || []).map((exSet, idx) => {
  //         if (rounds) {
  //           const roundSet = rounds.some(round => round.value - 1 === idx && round.active);
  //           return roundSet && this.checkInvalidSet(Object.keys(exSet), exSet);
  //         }
  //         return this.checkInvalidSet(Object.keys(exSet), exSet);
  //       });
  //     });
  //   });

  //   return _.flattenDeep(isValidSection).some(item => item);
  // };

  renderExercisesMarkAll = (section, sectionIndex, setIndex) => {
    const { onUpdateSection } = this.props;

    const handleMarkAllBySection = () => {
      let currentSection = _.cloneDeep(section);
      const exercises = _.get(currentSection, 'exercises', []);

      _.forEach(exercises, set => {
        if (set && set.supersets && set._id === setIndex) {
          _.forEach(set.supersets, ex => {
            _.forEach(ex.training_sets, exSet => {
              exSet.is_completed = true;
              // Set default value fields
              autoFillInput(exSet, AUTO_FILL_FIELDS);
            });
          });
        }
      });

      section = currentSection;
      onUpdateSection(section, sectionIndex);
    };

    return (
      <S.MarkAllSections onClick={() => handleMarkAllBySection()}>
        <S.MarkAllLabel>Mark All</S.MarkAllLabel>
        <MarkAllIcon />
      </S.MarkAllSections>
    );
  };

  renderCollapse = isCollapse => {
    const { tracking } = this.props;

    if (tracking) {
      return (
        <S.CollapseArea isCollapse={isCollapse} onClick={this.toggleCollapse}>
          <SectionCollapseIcon />
        </S.CollapseArea>
      );
    }

    return (
      <>
        <Image src={`${CDN_URL}/images/section_expand_purple.svg`} width={0} height={0} style={{ display: 'none' }} />
        <Image src={`${CDN_URL}/images/section_collapse_purple.svg`} width={0} height={0} style={{ display: 'none' }} />
        <div className={`app-icon ${isCollapse ? 'collapse' : 'expand'}`} onClick={this.toggleCollapse} />
      </>
    );
  };

  renderMarkAllOnFreestyle = (isCompleted, section, sectionIndex) => {
    const { tracking } = this.props;

    if (tracking) {
      return (
        <div
          className={`freestyle-section-mark-complete-btn${isCompleted ? ' completed' : ''}`}
          onClick={() => {
            if (!isCompleted) {
              this.props.onUpdateSection({ ...section, section_completed: true }, sectionIndex);
            } else {
              let newSection = _.omit(section, 'section_completed');
              this.props.onUpdateSection(newSection, sectionIndex);
            }
          }}
          section_completed={_.get(section, 'section_completed')}
        >
          <span className="label">{isCompleted ? 'Completed' : 'Mark as Complete'}</span>
          <MarkAllIcon />
        </div>
      );
    }

    return (
      <FreestyleButton
        className={`freestyle-section-mark-complete-btn ${isCompleted ? 'completed' : ''}`}
        onClick={() => {
          if (!isCompleted) {
            this.props.onUpdateSection({ ...section, section_completed: true }, sectionIndex);
          } else {
            let newSection = _.omit(section, 'section_completed');
            this.props.onUpdateSection(newSection, sectionIndex);
          }
        }}
        section_completed={_.get(section, 'section_completed')}
      >
        <CheckMark /> {isCompleted ? 'Completed' : 'Mark Complete'}
      </FreestyleButton>
    );
  };

  setTimerRef = ref => {
    this.timerRef = ref;
  };

  startTimer = () => {
    this.timerRef.start();
    this.setState({
      showTimeUp: true,
    });
  };

  pauseTimer = () => {
    this.timerRef.pause();
  };

  closeTimeUpPopup = () => {
    this.setState({
      showTimeUp: false,
    });
  };

  handleUpdate = () => {
    this.forceUpdate();
  };

  handleFormatTimer = value => {
    if (value > this.previousValue) {
      this.previousValue = value;
    }
    return this.previousValue < 3600
      ? convertSecondToMinuteString(this.previousValue)
      : convertSecondToTimeString(this.previousValue);
  };

  handleChangeTrainingTime = value => {
    const { section, sectionIndex, onUpdateSection, onSetValidationTrainingSet, isErrorTrainingSet } = this.props;
    let newSection = section;

    newSection = {
      ...newSection,
      time_complete: convertHoursStringToSecondForRest(value),
    };

    onUpdateSection && onUpdateSection(newSection, sectionIndex);

    // If training set not null
    if (isErrorTrainingSet && convertHoursStringToSecondForRest(value) > 1) onSetValidationTrainingSet(false);

    this.setState({
      trainingTime: value,
    });
  };

  renderer = ({ hours, minutes, seconds }) => {
    return hours !== 0 ? (
      <span>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    ) : (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  renderSectionTimer = () => {
    const { showIcon, date } = this.state;
    const { section } = this.props;
    const isAMRAPSection = section.format === SECTION_FORMAT_KEY.AMRAP;
    const isStopTimer = this.timerRef && this.timerRef.isCompleted();

    return (
      <S.TimeWrapper>
        {isAMRAPSection ? (
          <>
            <S.TimeText>
              <Countdown
                key={date}
                ref={this.setTimerRef}
                date={date}
                intervalDelay={3}
                autoStart={false}
                daysInHours
                onMount={this.handleUpdate}
                onStart={this.handleUpdate}
                onPause={this.handleUpdate}
                onComplete={this.handleUpdate}
                renderer={this.renderer}
              />
            </S.TimeText>
            <S.ButtonWrapper
              showIcon={showIcon}
              isStop={isStopTimer}
              onClick={this.handleChangeIcon}
              data-tip={showIcon ? 'Start Section' : 'Pause Section'}
              data-for="timer-tooltip"
            >
              {isStopTimer ? <StopIcon /> : showIcon ? <PlayIcon /> : <PauseIcon />}
            </S.ButtonWrapper>
            <S.Tooltip id="timer-tooltip" place="bottom" />
            {isStopTimer ? this.renderTimeUpPopup(section.title) : null}
          </>
        ) : (
          <>
            <CountUp
              end={86400}
              delay={1000}
              duration={86400}
              formattingFn={this.handleFormatTimer}
              onEnd={() => console.log('Ended! 👏')}
              onStart={() => console.log('Started! 💨')}
            >
              {({ countUpRef, pauseResume }) => (
                <>
                  <S.TimeText ref={countUpRef} />
                  <S.ButtonWrapper
                    showIcon={showIcon}
                    onClick={() => {
                      this.setState({
                        showIcon: !showIcon,
                      });
                      pauseResume();
                    }}
                    data-tip={showIcon ? 'Start Timer' : 'Pause Timer'}
                    data-for="timer-tooltip"
                  >
                    {showIcon ? <PlayIcon /> : <PauseIcon />}
                  </S.ButtonWrapper>
                  <S.Tooltip id="timer-tooltip" place="bottom" />
                </>
              )}
            </CountUp>
          </>
        )}
      </S.TimeWrapper>
    );
  };

  renderTimeUpPopup = sectionName => {
    return (
      <Modal open={this.state.showTimeUp} closeOnDimmerClick={false} className="time-up-popup">
        <TimeUp />
        <Modal.Content>
          <h3>Time's up!</h3>
          <p>
            The countdown timer for the AMRAP section ”{sectionName}” is done. But you can continue to edit the section
            and check off sets.
          </p>
          <Button className="confirm-button" onClick={this.closeTimeUpPopup}>
            Got it
          </Button>
        </Modal.Content>
      </Modal>
    );
  };

  renderSectionHeader = sectionIndex => {
    const { isCollapse } = this.state;
    const { section, tracking } = this.props;
    const isFreestyleSection = section.format === SECTION_FORMAT_KEY.FREESTYLE;
    const hasTimerSection = [SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(section.format);
    const isCompleted = _.get(section, 'section_completed', false);
    const isShowTooltip = section.title.length > 25;

    return (
      <div
        className="section-header"
        style={
          tracking && {
            background: 'linear-gradient(90.16deg, #2a307c 0.08%, #23233f 99.94%)',
            paddingRight: hasTimerSection && 0,
          }
        }
      >
        <div className="section-header__left">
          {this.renderCollapse(isCollapse)}
          <div className={tracking ? 'section-header-menu-tracking' : 'section-header-menu'}>
            <div className="section-title-container">
              {tracking ? (
                <>
                  <span
                    style={{ marginLeft: '10px', wordBreak: 'break-word' }}
                    className="section-title-text"
                    data-tip={isShowTooltip ? section.title : ''}
                    data-for="section-header-tooltip"
                  >
                    {section.title}
                  </span>
                  <ReactTooltip id="section-header-tooltip" effect="solid" place="top" />
                </>
              ) : section.isAdding ? (
                <span style={{ marginLeft: '10px' }}>New Section</span>
              ) : (
                <ContentEditable
                  value={section.title || ''}
                  onChange={e => this.onChangeSectionData('title', e.value)}
                />
              )}
              {tracking ? null : !isFreestyleSection && <span>{_.get(section, 'exercises.length', 0)}</span>}
            </div>
            <div className="section-format-title">&nbsp;&nbsp;-&nbsp;&nbsp;{SECTION_FORMAT[section.format]}</div>
          </div>
        </div>
        <div className="section-header__right">
          {isFreestyleSection && this.renderMarkAllOnFreestyle(isCompleted, section, sectionIndex)}
          {this.renderSectionFormatInput()}
          {this.renderActionMenu()}
          {section.isAdding ? (
            <div
              className="select-section-button"
              role="button"
              onClick={() => {
                this.setState({ openSelectSection: true });
              }}
            >
              Select from library
            </div>
          ) : null}
          {tracking &&
            !isFreestyleSection &&
            (SECTION_FORMAT_KEY.INTERVAL === section.format || SECTION_FORMAT_KEY.REGULAR === section.format) &&
            this.renderSectionMarkAll(section, sectionIndex)}
          {tracking && hasTimerSection && this.renderSectionTimer()}
        </div>
      </div>
    );
  };

  renderFreestyleSection = (section, tracking, isCollapse, sectionIndex) => {
    const { openExercise } = this.state;
    const exerciseReferences = section.exercise_references || [];
    const originalExerciseReferences = section.exercise_references || [];

    return (
      <S.FreestyleSection tracking={tracking}>
        {!isCollapse && (
          <>
            {section.attachments.length > 0 && (
              <FreestyleAttachment originalAttachments={section.attachments} viewOnly />
            )}
            {section.note && (
              <S.InstructionContainer>
                <p
                  contentEditable={true}
                  onKeyDown={this.handleContentChange}
                  className="freestyleNode"
                  spellcheck="false"
                >
                  {section.note}
                </p>
              </S.InstructionContainer>
            )}
            {exerciseReferences.length > 0 && (
              <div className="exercise-list-header-log-workout" onClick={() => this.handleCollapseExerciseList()}>
                <p className="exercise-list-label">
                  <span className="highlight">List of Exercises</span> (for reference)
                </p>
                <div
                  className={classnames({
                    collapse__list__exercise: !openExercise,
                    expand__list__exercise: openExercise,
                  })}
                >
                  <CollapseUp />
                </div>
              </div>
            )}

            {exerciseReferences.length > 0 && openExercise && (
              <ListExercises
                hideSearch={true}
                disabledEdit={true}
                exerciseReferences={exerciseReferences}
                originalExerciseReferences={originalExerciseReferences}
              />
            )}
          </>
        )}
        {isCollapse && this.renderMoreExercises(section.exercise_references.length, sectionIndex)}
      </S.FreestyleSection>
    );
  };

  renderSectionBody = (section, tracking, isCollapse, sectionIndex) => {
    const isFreestyleSection = section.format === 'freestyle';
    if (isFreestyleSection) {
      return (
        <div className={classnames('section-body freestyle-section', { isCollapseTracking: tracking && isCollapse })}>
          {this.renderFreestyleSection(section, tracking, isCollapse, sectionIndex)}
        </div>
      );
    } else {
      return (
        <div
          className={classnames('section-body', {
            markAllInSection:
              SECTION_FORMAT_KEY.AMRAP === section.format || SECTION_FORMAT_KEY.TIMED === section.format,
            trainingTimeInSection: SECTION_FORMAT_KEY.TIMED === section.format,
            isCollapseTracking: tracking && isCollapse,
          })}
        >
          {!isCollapse &&
          tracking &&
          (SECTION_FORMAT_KEY.AMRAP === section.format || SECTION_FORMAT_KEY.TIMED === section.format) ? (
            <S.MarkAllInSection>{this.renderSectionMarkAll(section, sectionIndex)}</S.MarkAllInSection>
          ) : null}
          {tracking ? null : section.isAdding ? (
            this.renderAddForm()
          ) : (
            <TextareaAutosize
              className={'section-instructions text-autosize'}
              placeholder="Add instructions"
              value={section.note}
              onChange={e => this.onChangeSectionData('note', e.target.value)}
            />
          )}
          {!isCollapse
            ? this.renderSets(section.exercises)
            : this.renderMoreExercises(section.exercises.length, sectionIndex)}
          {this.renderAddNewExercise()}
        </div>
      );
    }
  };

  handleDisableTrainingSet = section => {
    const exercise = _.get(section, 'exercises', []);
    let isDisable = true;

    _.forEach(exercise, item => {
      if (item.supersets) {
        _.forEach(item.supersets, ele => {
          if (ele.training_sets) {
            _.forEach(ele.training_sets, set => {
              if (set.is_completed) isDisable = false;
            });
          }
        });
      }
    });

    return isDisable;
  };

  renderTrainingTime = (section, tracking, isCollapse, trainingTime, isErrorTrainingSet, isErrorSets) => {
    if (!tracking || SECTION_FORMAT_KEY.TIMED !== section.format || isCollapse) return null;
    const isDisable = this.handleDisableTrainingSet(section) || '';

    return (
      <S.TrainingTimeOfSection>
        <S.TrainingTimeTitle>Training Time</S.TrainingTimeTitle>
        <TimeField
          disable={isDisable && SECTION_FORMAT_KEY.TIMED === section.format}
          value={trainingTime}
          onChange={this.handleChangeTrainingTime}
          input={
            <S.InputTimer
              isErrorTrainingSet={isErrorTrainingSet && isErrorSets.includes(section._id)}
              disabled={isDisable && SECTION_FORMAT_KEY.TIMED === section.format}
              trainingTime={convertHoursStringToSecondForRest(trainingTime)}
            />
          }
          style={trainingTime !== '00:00:00' ? { color: '#202353' } : {}}
          showSeconds
        />
      </S.TrainingTimeOfSection>
    );
  };

  handleCollapseExerciseList = () => {
    let { openExercise } = this.state;

    this.setState({ openExercise: !openExercise });
  };

  handleAddRound = () => {
    const { rounds } = this.state;
    const roundLength = rounds.length;
    const newRound = {
      key: roundLength + 1,
      value: roundLength + 1,
      active: false,
      isRoundCompleted: false,
      isValidField: true,
    };
    this.setState(
      {
        rounds: [...rounds, newRound],
      },
      () => {
        const isHaveRound =
          SECTION_FORMAT_KEY.AMRAP === _.get(this, 'props.section.format', '') ||
          SECTION_FORMAT_KEY.TIMED === _.get(this, 'props.section.format', '');

        if (!isHaveRound) return;
        const newLength = [...rounds, newRound].length;
        generateExerciseByRound(
          this.props,
          _.get(this.props, 'section.exercises', []),
          _.get(this.props, 'section.format', ''),
          newLength,
        );
      },
    );
  };

  handleSelectRound = id => {
    const newRounds = _.get(this, 'state.rounds', []).map(round =>
      round.key === id ? { ...round, active: true } : { ...round, active: false },
    );
    this.setState({
      rounds: newRounds,
    });
  };

  renderSectionRounds = () => {
    const { rounds, isCollapse, isMarkAllBySection } = this.state;
    const { section, tracking } = this.props;
    const isAMRAPSection = section.format === SECTION_FORMAT_KEY.AMRAP;

    if (!tracking || isCollapse) return null;
    if (
      [SECTION_FORMAT_KEY.INTERVAL, SECTION_FORMAT_KEY.REGULAR, SECTION_FORMAT_KEY.FREESTYLE].includes(section.format)
    )
      return null;

    return (
      <S.RoundWrapper>
        {rounds.length > 4 ? (
          <>
            <S.RoundText text isManyRound={true}>
              Round
            </S.RoundText>
            <S.ManyRoundWrapper>
              {rounds.map(round => (
                <S.RoundText
                  key={round.key}
                  number
                  active={round.active}
                  onClick={() => this.handleSelectRound(round.key)}
                  isValidField={round.isValidField}
                  isMarkAllBySection={isMarkAllBySection}
                  isNumberOfRound={round.key}
                  isManyRound={true}
                >
                  {round.value}
                </S.RoundText>
              ))}
              {isAMRAPSection ? (
                <S.RoundAdd onClick={this.handleAddRound}>
                  <PlusIcon />
                </S.RoundAdd>
              ) : null}
            </S.ManyRoundWrapper>
          </>
        ) : (
          <>
            {rounds.map(round => (
              <S.RoundText
                key={round.key}
                isValidField={round.isValidField}
                isMarkAllBySection={isMarkAllBySection}
                isNumberOfRound={round.key}
                active={round.active}
                onClick={() => this.handleSelectRound(round.key)}
              >
                Round {round.value}
              </S.RoundText>
            ))}
            {isAMRAPSection ? (
              <S.RoundAdd onClick={this.handleAddRound}>
                <PlusIcon />
              </S.RoundAdd>
            ) : null}
          </>
        )}
      </S.RoundWrapper>
    );
  };

  handleShowValidForRound = (isMarkAllSection, section, sectionIndex) => {
    const { onUpdateSection, onSetIsMarkAllSection } = this.props;

    if (isMarkAllSection) {
      let markAllUpdateRound = this.state.rounds;
      this.setState({ isMarkAllBySection: true });

      _.forEach(markAllUpdateRound, (item, index) => {
        // Handle Check Complete ROUND
        if ([SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(section.format)) {
          let isCompleted = checkRoundCompleted(section, index);

          if (_.get(isCompleted, 'round', false)) {
            if (item.key === isCompleted.round) {
              item.isRoundCompleted = isCompleted.roundCompleted;
              item.isValidField = isCompleted.isValidField;
            }
          }

          this.setState({ rounds: markAllUpdateRound });

          const roundComplete = _.filter(markAllUpdateRound, item => item.isRoundCompleted).length;

          const updateSection = {
            ...section,
            round_complete: SECTION_FORMAT_KEY.AMRAP === section.format ? roundComplete : 0,
          };

          onUpdateSection(updateSection, sectionIndex);
        }
      });

      onSetIsMarkAllSection(false);
    }
  };

  handleCountExercise = value => {
    const label = pluralize('exercise', value);
    const val = String(value).concat(' ', label);

    return val;
  };

  handleContentChange = event => {
    event.preventDefault();
  };

  render() {
    const { isCollapse, openSelectSection, trainingTime } = this.state;
    const { sectionIndex, section, tracking, isErrorTrainingSet, isErrorSets } = this.props;
    const containerClass = `section-container${tracking ? ' tracking' : ''}${
      section.type === 'hidden' ? ' section-hidden' : ''
    }`;
    const isFreestyleSection = section.format === 'freestyle';
    const sectionExercises = _.get(section, 'exercises.length', 0);
    const freestyleExercises = _.get(section, 'exercise_references.length', 0);
    const hasSectionNote = section.note && (!isFreestyleSection || isCollapse);
    const hasExercises = isCollapse && (sectionExercises || freestyleExercises);
    const hasRoundsBySection = [SECTION_FORMAT_KEY.TIMED, SECTION_FORMAT_KEY.AMRAP].includes(section.format);

    if (section.type === 'hidden') {
      return (
        <div className={containerClass} id={'section_' + section._id}>
          {this.renderSets(section.exercises)}
          {this.renderAddNewExercise()}
        </div>
      );
    }

    return (
      <div className={containerClass} id={'section_' + section._id} ref={this.boxRef}>
        {this.renderSectionHeader(sectionIndex)}
        {hasSectionNote || hasExercises ? (
          <S.SectionNote tracking={tracking} hasRoundsBySection={hasRoundsBySection} isCollapse={isCollapse}>
            {section.note ? (
              <p contentEditable={true} onKeyDown={this.handleContentChange} spellCheck={false} className="sectionNote">
                {section.note}
              </p>
            ) : null}
            {hasExercises ? (
              <p>
                <strong>Total: </strong>
                {isFreestyleSection
                  ? this.handleCountExercise(freestyleExercises)
                  : this.handleCountExercise(sectionExercises)}
              </p>
            ) : null}
          </S.SectionNote>
        ) : null}
        {this.renderSectionRounds()}
        {this.renderSectionBody(section, tracking, isCollapse, sectionIndex)}
        {this.renderTrainingTime(section, tracking, isCollapse, trainingTime, isErrorTrainingSet, isErrorSets)}
        {!isFreestyleSection && !tracking ? (
          <div className="section-footer">{this.renderAddExerciseButton()}</div>
        ) : null}
        {openSelectSection && (
          <SelectSection
            onSelect={this.onSelectSection}
            open={true}
            onClose={e => this.setState({ openSelectSection: false })}
          />
        )}
      </div>
    );
  }
}

SectionDetail.propTypes = {
  reorderSet: PropTypes.func,
  linkSuperSet: PropTypes.func,
  unlinkSuperSet: PropTypes.func,
  onChangeExerciseData: PropTypes.func,
  onAddExercise: PropTypes.func,
  onCloneExercise: PropTypes.func,
  onDeleteExercise: PropTypes.func,
  onExerciseAddRemoveSet: PropTypes.func,
  tracking: PropTypes.bool,
  sectionIndex: PropTypes.number,
  section: PropTypes.object,
};

export default SectionDetail;
