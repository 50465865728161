import styled, { css } from 'styled-components';

export const Container = styled.div`
  text-align: center;

  .thumbnail {
    width: 283px;
    height: 159px;
  }

  ${props =>
    props.fluid &&
    css`
      width: 100%;
      height: 100%;
    `}

  ${props =>
    props.cover &&
    css`
      img {
        object-fit: cover;
      }
    `}

  ${props =>
    props.noSizeLimit &&
    css`
      min-width: unset;
      min-height: unset;
      max-width: unset;
      max-height: unset;
    `}
`;
