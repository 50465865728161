import React, { useMemo } from 'react';
import { components } from 'react-select';

import { ReactComponent as OptionIcon } from 'assets/icons/option_group_tag.svg';
import { pluralize } from 'utils/commonFunction';

function CustomOption(props) {
  const totalClient = useMemo(() => {
    const client = props.data.total_clients || props.data.clients || 0;
    return pluralize(`${client} client`, client);
  }, [props]);
  return (
    <components.Option {...props}>
      <div className="group-icon">
        <OptionIcon />
      </div>
      <div className="group-content">
        <span className="group-name">
          <strong>Group:&nbsp;</strong>
          {props.label}
        </span>
        <div className="total-clients">{totalClient}</div>
      </div>
    </components.Option>
  );
}

export default CustomOption;
