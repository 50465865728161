import React, { useMemo, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import classNames from 'classnames';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import * as S from './style';
import { selectDelayLaterStyles, selectTypeStyle } from './style';
import { TimeDelayOptions, TypeOptions } from '../constants';
import { CDN_URL } from 'constants/commonData';
import { DropdownIndicator } from 'shared/Icons';
import { Checkbox } from 'shared/FormControl';
import CustomOption from './CustomOption';
import DuringPeriodSelect from '../DuringPeriodSelect';

export default props => {
  const { newOnboardingMessage, setNewOnboardingMessage, showError, onCloseRef } = props;

  const titleRef = useRef();

  const title = useMemo(() => get(newOnboardingMessage, 'title', ''), [newOnboardingMessage]);
  const type = useMemo(() => {
    const value = get(newOnboardingMessage, 'type');
    return isNil(value) ? TypeOptions[0] : find(TypeOptions, it => it.value === value);
  }, [newOnboardingMessage]);
  const isDelayAfterEnable = useMemo(() => get(newOnboardingMessage, 'delay_after_enable', false), [
    newOnboardingMessage,
  ]);
  const delayAfterValue = useMemo(() => {
    const value = get(newOnboardingMessage, 'delay_after_value');
    return isNil(value) ? TimeDelayOptions[0] : find(TimeDelayOptions, it => it.value === value);
  }, [newOnboardingMessage]);
  const isShowDuringPeriodSelect = useMemo(() => type.value === 'during_period', [newOnboardingMessage, type]);

  const handleTypeChange = ({ value }) => {
    setNewOnboardingMessage(it => ({
      ...it,
      type: value,
      during_period_from: '09:00 AM',
      during_period_to: '05:00 PM',
      delay_after_enable: false,
      delay_after_value: 5,
      delay_after_unit: 'minutes',
    }));
  };

  const handleChangeTitle = () => {
    if (titleRef.current) {
      const title = get(titleRef.current, 'value', '');
      setNewOnboardingMessage(it => ({ ...it, title }));
    }
  };

  const handleChangeTitleDebounce = debounce(handleChangeTitle, 300);

  const toggleDelayAfter = () => {
    setNewOnboardingMessage(it => ({ ...it, delay_after_enable: !get(it, 'delay_after_enable', false) }));
  };

  const handleChangeDelayAfterValue = ({ value }) => {
    setNewOnboardingMessage(it => ({ ...it, delay_after_value: value }));
  };

  return (
    <S.Wrapper className="inAppMessageForm__schedule">
      <S.FormGroup
        key="title"
        className={classNames({
          error: showError && isEmpty(title.trim()),
        })}
      >
        <div className="label">
          <span>Title</span>
          <img src={`${CDN_URL}/images/new_info.svg`} width="14" data-tip data-for="tooltip--message-title" alt="" />
          <ReactTooltip
            id="tooltip--message-title"
            effect="solid"
            place="top"
            className="app-tooltip announcement-tooltip"
          >
            <span>
              The title is only visible to you and meant to help remind what the scheduled messages are about.
            </span>
          </ReactTooltip>
        </div>
        <div className="controls">
          <input
            className="message__title"
            placeholder="Add a name for this message sequence"
            defaultValue={title}
            ref={titleRef}
            onChange={handleChangeTitleDebounce}
          />
        </div>
      </S.FormGroup>
      <S.FormGroup key="time">
        <div className="label">Send</div>
        <div className="controls">
          <Select
            options={TypeOptions}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.value}
            components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomOption }}
            onChange={handleTypeChange}
            value={type}
            isSearchable={false}
            blurInputOnSelect
            styles={selectTypeStyle}
          />
          {isShowDuringPeriodSelect && (
            <DuringPeriodSelect
              newOnboardingMessage={newOnboardingMessage}
              setNewOnboardingMessage={setNewOnboardingMessage}
              onCloseRef={onCloseRef}
            />
          )}
          <S.CheckboxWrapper>
            <Checkbox
              checked={isDelayAfterEnable}
              onChange={toggleDelayAfter}
              size={16}
              className="card-checkbox"
              title="Delay after"
            />
          </S.CheckboxWrapper>
          {isDelayAfterEnable && (
            <Select
              options={TimeDelayOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomOption }}
              onChange={handleChangeDelayAfterValue}
              value={delayAfterValue}
              isSearchable={false}
              blurInputOnSelect
              styles={selectDelayLaterStyles}
            />
          )}
        </div>
      </S.FormGroup>
    </S.Wrapper>
  );
};
