import React from 'react';
import { connect } from 'react-redux';
import LeftPanelStyles from './styles';
import ExercisesTab from './ExercisesTab';
import OnboardingTooltip, { Content, Title } from 'components/Onboarding/Tooltip';
import { markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { WORKOUT_BUILDER_GUIDE_STEPS } from 'constants/commonData';

const LeftPanel = ({
  loadedWorkoutOnboardingData,
  alreadyDragAndDropExercise,
  markWorkoutBuilderGuide,
  isEmptyWorkout,
}) => {
  const onGuideDone = () => {
    markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection);
  };

  return (
    <LeftPanelStyles className="workoutBuilder__leftPanel">
      {isEmptyWorkout && loadedWorkoutOnboardingData && !alreadyDragAndDropExercise ? (
        <OnboardingTooltip onClose={onGuideDone} place="right" align="start" showInModal>
          <Content style={{ width: 360 }}>
            <Title>Drag and Drop Exercises or Sections to the right</Title>
            Build workout with Exercises or special Section types, such as interval training, AMRAP, or strength
            workouts.
          </Content>
        </OnboardingTooltip>
      ) : null}
      <div className="left-pannel__container">
        <ExercisesTab />
      </div>
    </LeftPanelStyles>
  );
};

const mapState = state => {
  const {
    rootReducer: {
      onboarding: {
        workoutBuilderGuideSteps: { loaded, create_exercise_section },
      },
      workoutBuilder,
    },
  } = state;

  const listSections = workoutBuilder.getIn(['selectedWorkout', 'sections']);
  const isEmptyWorkout = !listSections || listSections.size === 0;

  return {
    loadedWorkoutOnboardingData: loaded,
    alreadyDragAndDropExercise: create_exercise_section,
    isEmptyWorkout,
  };
};

export default connect(mapState, { markWorkoutBuilderGuide })(LeftPanel);
