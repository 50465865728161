import { find, get } from 'lodash';
import React, { useState } from 'react';
import { Checkbox } from 'shared/FormControl';

import * as S from './style';
import { ACTION } from './constants';

const LabelContainer = ({ onClose, labels, savedLabels, onChangeSavedLabels, handleSaveLabels, userId }) => {
  const [data, setData] = useState(labels);

  const handleSubmit = () => {
    // TODO - Close pop-up
    handleSaveLabels(savedLabels);
    const currentLocalStorageSavedLabels = JSON.parse(localStorage.getItem('saved_labels')) || {};
    currentLocalStorageSavedLabels[userId] = savedLabels;
    localStorage.setItem('saved_labels', JSON.stringify(currentLocalStorageSavedLabels));
    onClose(false);

    // TODO - Save Confirm
  };

  const handleOnClickItem = (item, isChecked) => () => {
    if (!isChecked) {
      onChangeSavedLabels(item._id, ACTION.ADD);
    } else {
      onChangeSavedLabels(item._id, ACTION.REMOVE);
    }
  };

  return (
    <div className="content">
      <S.MoreLabelHeader>Add Label</S.MoreLabelHeader>
      <S.MoreLabelBody>
        <S.MoreLabelBodyDescription>Choose additional labels to add to your workout</S.MoreLabelBodyDescription>
        {/* TODO - Label List */}
        <S.MoreLabelList>
          {data.map((item, key) => {
            if (!get(item, 'items', []).length) {
              return null;
            }

            const isChecked = find(savedLabels, savedLabel => savedLabel === item._id);
            return (
              <S.MoreLabelItem key={key} onClick={handleOnClickItem(item, isChecked)}>
                <Checkbox
                  checked={isChecked}
                  onChange={handleOnClickItem(item, isChecked)}
                  onClick={e => e.stopPropagation()}
                />
                <S.MoreLabelText>{item.name}</S.MoreLabelText>
              </S.MoreLabelItem>
            );
          })}
        </S.MoreLabelList>
      </S.MoreLabelBody>
      <S.MoreLabelFooter>
        <S.Button text="Cancel" height={26} width={91} className="cancelButtonClassName" onClick={onClose} />
        <S.Button text="Save" height={26} width={91} className="buttonClassName" onClick={handleSubmit} />
      </S.MoreLabelFooter>
    </div>
  );
};

export default LabelContainer;
