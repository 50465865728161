import get from 'lodash/get';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import Request from 'configs/request';
import { omitEmptyRequestParams } from 'utils/commonFunction';

export const Types = {
  GET_CLIENT_DOCUMENTS_REQUEST: 'GET_CLIENT_DOCUMENTS_REQUEST',
  GET_CLIENT_DOCUMENTS_SUCCESS: 'GET_CLIENT_DOCUMENTS_SUCCESS',
  GET_CLIENT_DOCUMENTS_FAILED: 'GET_CLIENT_DOCUMENTS_FAILED',
  CLIENT_DOCUMENTS_CHANGE_QUERY_PARAMS: 'CLIENT_DOCUMENTS_CHANGE_QUERY_PARAMS',
  CLIENT_DOCUMENTS_RESET_CHANGE_QUERY_PARAMS: 'CLIENT_DOCUMENTS_RESET_CHANGE_QUERY_PARAMS',
  GET_ONBOARDING_FLOW_FORM_SUCCESS: 'GET_ONBOARDING_FLOW_FORM_SUCCESS',
  EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_REQUEST: 'EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_REQUEST',
  EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_SUCCESS: 'EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_SUCCESS',
  EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_FAILED: 'EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_FAILED',
};

export const getClientsDocuments = (clientId, params, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        formDocuments: { query: stateParams },
      },
    } = getState();
    dispatch({ type: Types.GET_CLIENT_DOCUMENTS_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `api/forms/client/${clientId}/history`,
          params: omitEmptyRequestParams(omit(merge(stateParams, params), 'sort', 'sorter', 'total')),
        },
        true,
        response => {
          const { data } = response.data;

          if (data) {
            dispatch({ type: Types.GET_CLIENT_DOCUMENTS_SUCCESS, payload: data });
          }
          callback && callback();
        },
        () => {
          dispatch({ type: Types.GET_CLIENT_DOCUMENTS_FAILED });
        },
      ),
    );
  };
};

export const getOnboardingflowForm = (clientId, callback) => {
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: `api/onboarding-flow-form-clients/submissions`,
          params: { client_id: clientId },
        },
        true,
        response => {
          const { data } = response.data;

          if (data) {
            dispatch({ type: Types.GET_ONBOARDING_FLOW_FORM_SUCCESS, payload: data });
          }
          callback && callback();
        },
        () => {},
      ),
    );
  };
};

export const changeQueryParams = (clientId, params) => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_DOCUMENTS_CHANGE_QUERY_PARAMS, payload: params });
    dispatch(getClientsDocuments(clientId, params));
  };
};

export const resetChangeQueryParams = () => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_DOCUMENTS_RESET_CHANGE_QUERY_PARAMS });
  };
};

export const exportOnboardingResponsesPdf = formAnswerId => {
  return (dispatch, getState) => {
    dispatch({ type: Types.EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_REQUEST });

    return dispatch(
      Request.post(
        { url: `/api/onboarding-flow-form-clients/${formAnswerId}/export` },
        true,
        (response, { dispatch }) => {
          const url = get(response, 'data.data.url', '');

          if (url) {
            window.open(url, '_blank');
            dispatch({ type: Types.EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_SUCCESS });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.EXPORT_ONBOARDING_FLOW_FORM_RESPONSES_FAILED });
        },
      ),
    );
  };
};
