import styled, { css } from 'styled-components';

export const ListMessageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  padding: 0 7px;

  .listPlaceholder {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;

    .autoMessage {
      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .minimize {
    width: 100%;

    .autoMessage {
      :nth-last-child(2) {
        margin-bottom: 5px;
      }
    }
  }

  .autoMessageCell__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .autoMessageCell__paste {
      flex: 1 1;
    }
  }

  ${props =>
    props.showAll &&
    css`
      overflow: auto;

      .minimize {
        padding: 5px 0;

        .autoMessage {
          :nth-last-child(2) {
            margin-bottom: 5px;
          }
        }
      }
    `}

  ${props =>
    props.empty &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  &.compactView {
    .autoMessage__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .autoMessage--announcement__media {
        flex: 0 0 30px;
        height: 20px;
        border-radius: 3px;
        order: 2;
        margin-left: 14px;
        margin-bottom: 0;

        .video-duration {
          height: 100%;

          .time {
            display: none;
          }
        }
      }

      .autoMessage__title {
        order: 1;
        flex: 1 1;
        -webkit-line-clamp: 1;
      }
    }
  }
`;
