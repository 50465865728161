import styled from 'styled-components';

import {
  FallbackWrapper,
  Description,
  MenuItem,
  MenuItemActive,
  ItemContent,
  ItemTitle,
  ItemText,
  ItemButton,
  Menu,
} from '../../style';

export const SFallbackWrapper = styled(FallbackWrapper)`
  padding-top: 80px;
`;

export const SDescription = styled(Description)`
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

export const SMenuItem = styled(MenuItem)`
  opacity: 0.5;
`;

export const SMenuItemActive = styled(MenuItemActive)`
  color: #5e59ff;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  border: none;
  padding-bottom: 0;
  opacity: 1;
  &::after {
    content: '';
    position: relative;
    display: block;
    height: 2px;
    width: 100%;
    background: #5e59ff;
    margin-top: 8px;
    border-radius: 4px;
    z-index: 1;
  }
`;

export const SItemContent = styled(ItemContent)`
  padding-left: 69px;
  &.big-spacing {
    padding-left: 110px;
  }
`;

export const SItemTitle = styled(ItemTitle)`
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 5px;
`;

export const SItemText = styled(ItemText)`
  color: #000000;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 30px;
`;

export const SItemButton = styled(ItemButton)`
  min-width: 226px;
  padding: 13px 34px;
  font-weight: bold;
  font-size: 11px;
  line-height: 100%;
  text-transform: uppercase;
`;

export const TabsMenu = styled(Menu)`
  position: relative;
  div {
    margin-right: 48px;
    &:last-child {
      margin-right: 0;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 29px;
    width: 100%;
    height: 1px;
    background: #e0e1e4;
    z-index: 0;
  }
`;
