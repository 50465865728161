import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Modal, List, Segment, Button, Image } from 'semantic-ui-react';
import SearchInput from 'shared/SearchInput';
import GeneralButton from 'shared/GeneralButton';
import ExerciseDetail from 'components/ExerciseDetail';
import { CDN_URL, SECTION_FORMAT } from 'constants/commonData';
import { ExerciseSet } from 'types/model';
import Icons from 'shared/Icons';
import ConfirmModal from 'shared/ConfirmModal';
import LoadingIndicator from 'shared/LoadingIndicator';
import './styles.scss';

const LIMIT_PERPAPER = 20;

class SelectSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: '',
      selected: props.list.length ? props.list[0] : null,
      loading: false,
      searchEnd: false,
      page: 1,
    };
    this.searchDebounce = _.debounce(this.getSectionList, 300);
  }

  componentDidMount() {
    this.getSectionList({ page: 1 });
  }

  getSectionList = filters => {
    this.props
      .getSections({ ...filters, per_page: LIMIT_PERPAPER })
      .then(res => {
        this.setState({ searchEnd: _.get(res, 'data.data.length') < LIMIT_PERPAPER });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidUpdate() {
    const { list } = this.props;
    const { selected } = this.state;

    if (list.length && (!selected || !_.find(list, item => item._id === selected._id))) {
      this.setState({ selected: list[0] });
    }
  }

  componentWillUnmount() {
    const { resetSection } = this.props;
    resetSection && resetSection();
  }

  handleScrollBottom = () => {
    const { loading, searchEnd, page, textSearch } = this.state;
    const listSection = document.querySelector('.find-workout-list-item');
    if (listSection && !loading && !searchEnd) {
      const isAtBottom = listSection.scrollTop + listSection.clientHeight >= listSection.scrollHeight;
      if (isAtBottom) {
        const nextPage = page + 1;
        this.setState({
          loading: true,
          page: nextPage,
          textSearch: textSearch,
        });
        this.searchDebounce({
          page: nextPage,
          q: textSearch,
        });
      }
    }
  };

  handleSearch = (value = '') => {
    const { resetSection } = this.props;
    this.setState({
      loading: true,
      textSearch: value,
      page: 1,
    });
    resetSection && resetSection();
    this.searchDebounce({ q: value, page: 1 });
  };

  renderSearch = () => {
    const { toggleConfirmModal, deleteSection, list } = this.props;
    const { selected, loading, textSearch } = this.state;
    const showedSections = list;

    return (
      <div className="new-workout-select-side">
        <div className="new-workout-col-title search-title">SEARCH</div>
        <div className="new-workout-search-bar">
          <SearchInput
            value={textSearch}
            onChange={(evt, data) => {
              this.handleSearch(data.value);
            }}
            placeholder={'Search Section'}
            onAddOnIconClick={() => {
              this.handleSearch();
            }}
          />
        </div>
        <div className="new-workout-col-title most-recent-title">MOST RECENTS ({list.length})</div>
        <Segment fluid className="find-workout-list-item" onScroll={this.handleScrollBottom}>
          <List verticalAlign={'middle'}>
            {showedSections.map(item => (
              <List.Item
                key={item._id}
                active={selected && selected._id === item._id}
                className="new-workout-list"
                onClick={() => {
                  if (selected && selected._id === item._id) {
                    return false;
                  }

                  this.setState({ selected: item });
                }}
              >
                <div className="new-workout-list-item">
                  <div>{item.title}</div>
                  <Icons
                    className="icon"
                    name="delete"
                    width={12}
                    height={12}
                    onClick={event => {
                      event.stopPropagation();
                      toggleConfirmModal(
                        true,
                        <ConfirmModal
                          title="Remove Section"
                          content="Are you sure that you want to delete this section?"
                          onConfirm={() => deleteSection(item._id)}
                        />,
                      );
                    }}
                  />
                </div>
              </List.Item>
            ))}
            {loading && _.get(showedSections, 'length', 0) > 0 && (
              <LoadingIndicator title="Loading..." className="loading-section" />
            )}
          </List>
        </Segment>
      </div>
    );
  };

  renderSuperset = set => {
    let views = [];
    set.supersets.map((exSet, idx) => {
      views.push(
        <div key={idx}>
          <ExerciseDetail
            style={{ marginTop: 16 }}
            containerStyle={{ marginLeft: 12, marginRight: 12 }}
            exerciseSet={new ExerciseSet(exSet)}
            mode={'preview'}
            boxWidth={'230'}
          />

          {idx < set.supersets.length - 1 ? <div className="exercise-divide" /> : null}
        </div>,
      );
    });

    return <div className="ui segment small find-workout-exercise-cell">{views}</div>;
  };

  renderPreview = () => {
    const { list } = this.props;
    const { selected } = this.state;

    if (!selected || !list.length) {
      return null;
    }

    const totalEx = _.sumBy(selected.exercises, set => set.supersets.length);

    return (
      <div className="new-workout-preview">
        <div className="new-workout-col-title">
          <span className="section-format-tag">{SECTION_FORMAT[selected.format]}</span>
          <span className="dot" />
          <span>{totalEx} Exercises</span>
        </div>
        <Segment fluid className="preview-segment">
          <div className="section-title">{selected.title ? selected.title.toUpperCase() : ''}</div>
          <div className="preview-section" key={selected._id}>
            <div className="preview-section__body">
              {_.map(selected.exercises, set => (set ? this.renderSuperset(set) : null))}
            </div>
          </div>
        </Segment>
      </div>
    );
  };

  render() {
    const { open, onClose, onSelect, list, selectSection, newUI } = this.props;
    const { selected } = this.state;

    return (
      <Modal
        className={classnames('new-workout-modal select-section-modal', { 'new-ui': newUI })}
        open={open}
        onClose={onClose}
      >
        <Modal.Header>
          <span className="assign-workout-title">Select a Section</span>
          <Button onClick={onClose} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
        </Modal.Header>
        <Modal.Content>
          <div className="select-section__content">
            {this.renderSearch()}
            {this.renderPreview()}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <GeneralButton
              className="find-workout-select-button"
              onClick={() => {
                if (!list.length || !selected) {
                  return false;
                }
                selectSection(selected._id);
                onSelect(selected);
                onClose();
              }}
              disabled={!list.length || !selected}
            >
              Select
            </GeneralButton>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

SelectSection.propTypes = {};

export default SelectSection;
