/**
 * @flow
 */

import { connect } from 'react-redux';
import { getClientInfo, addClientInfo } from 'actions/client';
import Note from './component';
import { getClientCountdownList, getClientGoalList } from 'redux/client-countdown/action';

const mapStateToProps = state => {
  const { user, rootReducer } = state;
  const { client } = rootReducer;
  const { workingClientDetail, selected: selectedClientId } = client || {};

  return {
    user,
    selectedClient: workingClientDetail,
    selectedClientId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getClientCountdownList: data => dispatch(getClientCountdownList(data)),
    getClientGoalList: data => dispatch(getClientGoalList(data)),
    getClientInfo: data => {
      return dispatch(getClientInfo(data));
    },
    addClientInfo: data => {
      return dispatch(addClientInfo(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Note);
