import styled from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';
import * as Layout from 'shared/LibraryLayout';

const textBase = `
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: rgba(32, 35, 83, 1);
`;

export const LayoutWrapper = styled(Layout.Wrapper)`
  padding-right: 9px;
`;

export const TableWrapper = styled(Layout.TableContainer)`
  flex: unset;
  overflow-x: scroll;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1;

  ::-webkit-scrollbar {
    width: 6px !important;
  }
`;

export const Table = styled(Layout.Table)`
  table-layout: fixed;
`;

export const NoResultWrapper = styled(Layout.NoResult)`
  justify-content: start;
  padding-top: 319px;
  padding-right: 8px;
`;

export const NoResult = styled.div`
  ${textBase}
  font-size: 17px;
  margin-bottom: 30px;
`;

export const AddNewButton = styled.div`
  border: 1px solid rgb(225, 225, 234);
  border-radius: 5px;
  padding: 11px 20px 10px 15px;
  gap: 8px;
  white-space: nowrap;
  color: rgba(32, 35, 83, 1);
  display: flex;
  align-items: center;
  ${textBase}
  font-size: 13px;
  height: 36px;
  cursor: pointer;
  margin-left: 4px;
  :hover {
    background-color: rgb(240, 241, 255);
    color: #5158cf;
    .plus-icon {
      path {
        stroke: #5158cf;
      }
    }
  }
  .plus-icon {
    width: 20px;
    min-width: 20px;
    height: 11.67px;
    path {
      stroke: rgba(32, 35, 83, 1);
    }
  }
  span {
    margin-left: -3px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.custom-confirm-modal-ingredient {
    width: 467px !important;
    .close-button {
      right: -7px;
    }
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
      .confirm-header-label {
        ${textBase}
        font-size: 17px;
        font-weight: 600;
        line-height: 26px;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
      .confirm-content-label {
        ${textBase}
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .confirm-actions {
      &.no-border {
        padding: 0 30px 28px 0 !important;
      }
      .button {
        border-radius: 5px;
        ${textBase}
        padding: 5px 24px;
        font-weight: 600;
        margin-left: 5px !important;
        height: 30px;
      }
      .confirm-no-button {
        min-width: unset;
        width: 102px;
        border: 1px solid #d6dae4 !important;
        color: #818da1 !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        width: 111px;
        color: #fff;
      }
    }
  }
`;
