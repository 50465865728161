import React, { memo, useMemo } from 'react';

import * as S from '../style';
import { ReactComponent as EmptyNotificationIcon } from 'assets/icons/empty_notification_sidebar.svg';
import { useMyContext } from '../context/NotificationContext';

const EmptyNotification = () => {
  const { onlyUnread } = useMyContext();
  const content = useMemo(() => (onlyUnread ? `No unread notifications. You’re all set!` : 'No notifications.'), [
    onlyUnread,
  ]);
  return (
    <S.EmptyWrapper>
      <EmptyNotificationIcon />
      <S.EmptyText>{content}</S.EmptyText>
    </S.EmptyWrapper>
  );
};

export default memo(EmptyNotification);
