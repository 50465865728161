import React from 'react';
import { isMobile } from 'react-device-detect';
import { Modal } from 'semantic-ui-react';

import { ReactComponent as ContentIcon } from 'assets/icons/not_a_coach.svg';

import * as S from './style';

const NotACoachModal = props => {
  const { onCloseModal, openModal } = props;

  const navigateLanding = () => {
    const navigateLink = 'https://everfit.io/';
    window.open(navigateLink, '_self');
  };

  return (
    <S.ModalWrapper open={openModal} closeOnDimmerClick={false} closeIcon={false} className="modal" isMobile={isMobile}>
      <Modal.Content>
        <div className="content-icon">
          <ContentIcon />
        </div>
        <S.Title>Thanks for letting us know</S.Title>
        <S.Description>Please connect with your coach for the Client app</S.Description>
        <S.BackToHomeButton purple onClick={navigateLanding}>
          Back to Home
        </S.BackToHomeButton>
      </Modal.Content>
    </S.ModalWrapper>
  );
};

export default NotACoachModal;
