import { connect } from 'react-redux';
import CompeleteRegistration from './component';
import { completeTrainerRegistration } from 'actions/auth/register';

const mapState = state => {
  const data = state.rootReducer.auth.get('registration');
  return { data };
};

const actions = {
  completeTrainerRegistration,
};

export default connect(mapState, actions)(CompeleteRegistration);
