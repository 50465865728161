// Lib
import React, { useRef } from 'react';
import get from 'lodash/get';

// Constants
import { getMacroNutrients, sumMacroNutrients } from 'components/MealPlanDayDetail/constants';

// Shared
import MainPanelHeader from './components/MainPanelHeader';
import MainPanelContent from './components/MainPanelContent';

import * as S from './style';

const MainPanelDayDetail = props => {
  const { selected, listMealGroup } = props;

  const mouseLeaveRef = useRef();

  const selectedMeal = listMealGroup.find(item => get(item, '_id') === selected);

  const macroNutrients = sumMacroNutrients(get(selectedMeal, 'recipes', []));
  const listNutrients = getMacroNutrients(macroNutrients);

  const handleMouseLeave = () => {
    mouseLeaveRef.current && mouseLeaveRef.current();
  };

  return (
    <S.Wrapper onMouseLeave={handleMouseLeave}>
      <MainPanelHeader {...props} meal={selectedMeal} listNutrients={listNutrients} mouseLeaveRef={mouseLeaveRef} />
      <MainPanelContent {...props} meal={selectedMeal} />
    </S.Wrapper>
  );
};

export default MainPanelDayDetail;
