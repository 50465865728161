import { Button } from 'shared/FormControl';
import styled from 'styled-components';

export const ButtonWrapper = styled(Button)`
  border: 1px solid #e1e1ea;
  background-color: #fff;
  padding: 4px 13px;
  font-size: 12px;
  color: #202353;

  .ask-to-connect-icon,
  .ask-to-connect {
    margin-right: 9px;
  }
`;
