import styled from 'styled-components';
import { Wrapper, Header, Content } from 'shared/Styles/ModalLayout';

export const SingleLabel = styled.div`
  .name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    margin-bottom: 3px;
  }

  .email {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.4;
  }
`;

export const ContactInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .sb-avatar {
    margin-right: 14px;
  }
`;

export const ContentWrapper = styled(Wrapper)`
  ${Header} {
    padding-bottom: 15px;
  }

  ${Content} {
    overflow: unset;
  }

  .select-container {
    .select-admin__menu {
      box-shadow: none;
      border: 1px solid #7470ef;
      padding: 0 4px;

      .select_option {
        border: none;
      }
    }

    .select-admin__control {
      background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
      border: 1px solid #dadfea;
      box-sizing: border-box;
      border-radius: 5px;
      box-shadow: none;
      padding: 0 6px;

      &--menu-is-open,
      &--is-focused {
        border-color: #7470ef;
      }
    }

    .select_option {
      padding: 10px 15px;
      cursor: pointer;

      :hover {
        background: #F7F7FF;
        border-radius: 5px;
      }
    }
  }
`;
