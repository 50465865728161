import React, { useMemo } from 'react';
import { components } from 'react-select';

import { pluralize } from 'utils/commonFunction';

function CustomMultiValueLabel(props) {
  const totalClient = useMemo(() => {
    const client = props.data.total_clients || props.data.clients || 0;
    return pluralize(`${client} client`, client);
  }, [props]);
  return (
    <components.MultiValueLabel {...props}>
      <strong>Group:&nbsp;</strong>
      {props.children}&nbsp;
      <span>({totalClient})</span>
    </components.MultiValueLabel>
  );
}

export default CustomMultiValueLabel;
