// Libs
import React, { useEffect, useRef, useState } from 'react';

// Utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import DefaultImage from 'assets/icons/MealPlans/meal_plan_default.png';

// Style
import * as S from './style';

const SCROLL_WIDTH_THRESHOLD = 465; // Width threshold for determining scroll behavior.

const ListSelected = ({
  scrollWidthFilter = SCROLL_WIDTH_THRESHOLD,
  list,
  cloudfrontList,
  handleRemove = () => {},
}) => {
  const listRef = useRef(null);
  const [statusScroll, setStatusScroll] = useState('left');

  useEffect(() => {
    const listContent = listRef.current;

    const handleScroll = () => {
      const tolerancePercentage = 5;
      if (listContent) {
        if (listContent.scrollLeft === 0) {
          setStatusScroll('left');
        } else if (listContent.scrollLeft + listContent.clientWidth + tolerancePercentage >= listContent.scrollWidth) {
          setStatusScroll('right');
        } else {
          setStatusScroll('center');
        }
      }
    };

    if (listContent) {
      listContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listContent) {
        listContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleArrowButton = value => {
    if (listRef && listRef.current)
      listRef.current.scrollTo({
        left: listRef.current.scrollLeft + value,
        behavior: 'smooth',
      });
  };

  return (
    <S.FilterCategoryWrapper>
      <S.List ref={listRef}>
        {list.map(item => {
          const { _id, name, cover_image, cover_image_thumbnail } = item;

          const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
          const coverImageThumbnail = convertS3UrlToCloudFrontUrl(cover_image_thumbnail, cloudfrontList, true);

          return (
            <S.Item key={_id}>
              <S.Thumbnail src={[coverImageThumbnail, coverImage]} defaultImage={DefaultImage} />
              <span>{name}</span>
              <div className="close-btn" onClick={() => handleRemove(item)}>
                <CloseIcon />
              </div>
            </S.Item>
          );
        })}
      </S.List>
      {list.length >= 4 ? (
        <>
          {(statusScroll === 'right' || statusScroll === 'center') && (
            <>
              <S.Overlay className="left" />
              <S.Button className="left" onClick={() => handleArrowButton(-scrollWidthFilter)}>
                <ArrowIcon />
              </S.Button>
            </>
          )}
          {(statusScroll === 'left' || statusScroll === 'center') && (
            <>
              <S.Overlay className="right" />
              <S.Button className="right" onClick={() => handleArrowButton(scrollWidthFilter)}>
                <ArrowIcon />
              </S.Button>
            </>
          )}
        </>
      ) : (
        ''
      )}
    </S.FilterCategoryWrapper>
  );
};

export default ListSelected;
