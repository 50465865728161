import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { MultiplePasteMessage } from 'shared/Notifications';
import AutoflowInAppMessage from 'components/AutoflowAutoMessage/InAppMessage';
import Request from 'configs/request';
import { Types as AutoflowTypes } from 'redux/autoflow/actions';
import { IN_APP_AUTO_MESSAGE, DATE_FORMAT } from 'constants/commonData';
import { toggleModal } from 'actions/modal';

export const Types = {
  AUTOFLOW_GET_AUTO_MESSAGE: 'AUTOFLOW_GET_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_GET_AUTO_MESSAGES: 'AUTOFLOW_SUCCESS_GET_AUTO_MESSAGES',
  AUTOFLOW_FAILED_GET_AUTO_MESSAGES: 'AUTOFLOW_FAILED_GET_AUTO_MESSAGES',
  AUTOFLOW_COPY_AUTO_MESSAGE: 'AUTOFLOW_COPY_AUTO_MESSAGE',
  AUTOFLOW_AUTO_MESSAGE_CLEAR_COPY_ITEM: 'AUTOFLOW_AUTO_MESSAGE_CLEAR_COPY_ITEM',
  AUTOFLOW_AUTO_MESSAGE_RESET_DATA: 'AUTOFLOW_AUTO_MESSAGE_RESET_DATA',
  AUTOFLOW_DELETE_AUTO_MESSAGE: 'AUTOFLOW_DELETE_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_DELETE_AUTO_MESSAGE: 'AUTOFLOW_SUCCESS_DELETE_AUTO_MESSAGE',
  AUTOFLOW_FAILED_DELETE_AUTO_MESSAGE: 'AUTOFLOW_FAILED_DELETE_AUTO_MESSAGE',
  AUTOFLOW_ADD_AUTO_MESSAGE: 'AUTOFLOW_ADD_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_ADD_AUTO_MESSAGE: 'AUTOFLOW_SUCCESS_ADD_AUTO_MESSAGE',
  AUTOFLOW_FAILED_ADD_AUTO_MESSAGE: 'AUTOFLOW_FAILED_ADD_AUTO_MESSAGE',
  AUTOFLOW_UPDATE_AUTO_MESSAGE: 'AUTOFLOW_UPDATE_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_UPDATE_AUTO_MESSAGE: 'AUTOFLOW_SUCCESS_UPDATE_AUTO_MESSAGE',
  AUTOFLOW_FAILED_UPDATE_AUTO_MESSAGE: 'AUTOFLOW_FAILED_UPDATE_AUTO_MESSAGE',
  AUTOFLOW_PASTE_AUTO_MESSAGE: 'AUTOFLOW_PASTE_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_PASTE_AUTO_MESSAGE: 'AUTOFLOW_SUCCESS_PASTE_AUTO_MESSAGE',
  AUTOFLOW_FAILED_PASTE_AUTO_MESSAGE: 'AUTOFLOW_FAILED_PASTE_AUTO_MESSAGE',
  AUTOFLOW_EDIT_THAN_PASTE_AUTO_MESSAGE: 'AUTOFLOW_EDIT_THAN_PASTE_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_EDIT_THAN_PASTE_AUTO_MESSAGE: 'AUTOFLOW_SUCCESS_EDIT_THAN_PASTE_AUTO_MESSAGE',
  AUTOFLOW_FAILED_EDIT_THAN_PASTE_AUTO_MESSAGE: 'AUTOFLOW_FAILED_EDIT_THAN_PASTE_AUTO_MESSAGE',
  AUTOFLOW_MOVE_AUTO_MESSAGE: 'AUTOFLOW_MOVE_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_MOVE_AUTO_MESSAGE: 'AUTOFLOW_SUCCESS_MOVE_AUTO_MESSAGE',
  AUTOFLOW_FAILED_MOVE_AUTO_MESSAGE: 'AUTOFLOW_FAILED_MOVE_AUTO_MESSAGE',
  AUTOFLOW_ARRANGE_AUTO_MESSAGE: 'AUTOFLOW_ARRANGE_AUTO_MESSAGE',
  AUTOFLOW_SUCCESS_ARRANGE_AUTO_MESSAGE: 'AUTOFLOW_SUCCESS_ARRANGE_AUTO_MESSAGE',
  AUTOFLOW_FAILED_ARRANGE_AUTO_MESSAGE: 'AUTOFLOW_FAILED_ARRANGE_AUTO_MESSAGE',
  AUTOFLOW_MOVE_MESSAGE_IN_DATE: 'AUTOFLOW_MOVE_MESSAGE_IN_DATE',
  AUTOFLOW_MOVE_IN_APP_MESSAGE_TO_THE_PAST: 'AUTOFLOW_MOVE_IN_APP_MESSAGE_TO_THE_PAST',
};

const sortMessages = list => {
  const daysData = _.isArray(list) ? list.slice() : [{ ...list }];

  _.forEach(daysData, data => {
    if (data.messages.length) {
      const messages = data.messages.slice();
      const inAppMessages = _.remove(messages, m => m.type === IN_APP_AUTO_MESSAGE);

      if (inAppMessages.length) {
        inAppMessages.sort((m1, m2) => {
          if (m1.time > m2.time) {
            return 1;
          }

          if (m1.time < m2.time) {
            return -1;
          }

          if (m1.time === m2.time) {
            if (moment(m1.createdAt).isAfter(m2.createdAt)) {
              return 1;
            }
          }

          return 0;
        });
      }

      data.messages = [...messages, ...inAppMessages];
    }
  });

  return daysData;
};

export const resetData = () => ({ type: Types.AUTOFLOW_AUTO_MESSAGE_RESET_DATA });

export const changeViewMode = mode => {
  return dispatch => {
    dispatch({
      type: AutoflowTypes.AUTOGLOW_CHANGE_VIEW_MODE,
      payload: { mode },
    });
    dispatch(getListMessages());
  };
};

export const changeStartDate = startDate => {
  return dispatch => {
    dispatch({
      type: AutoflowTypes.AUTOGLOW_CHANGE_START_DATE,
      payload: { startDate },
    });
    dispatch(getListMessages());
  };
};

export const getListMessages = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { viewMode, startDate } = autoflow.common;
    const { workingAutoflow } = autoflow.common;

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_ITEM' });
    }

    const start = moment(startDate, DATE_FORMAT);
    const params = {
      autoflow: workingAutoflow._id,
      start_date: startDate,
      end_date: start.add(7 * viewMode, 'day').format(DATE_FORMAT),
    };

    dispatch({ type: Types.AUTOFLOW_GET_AUTO_MESSAGE });

    return dispatch(
      Request.get(
        { url: '/api/autoflow/message/list', params },
        true,
        response => {
          const data = sortMessages(_.get(response, 'data.data', []));
          dispatch({ type: Types.AUTOFLOW_SUCCESS_GET_AUTO_MESSAGES, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_GET_AUTO_MESSAGES }),
      ),
    );
  };
};

export const addMessage = (data, extendData = {}) => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_ADD_AUTO_MESSAGE });
    const { action, multiPaste } = extendData;

    if (action === 'paste' && !multiPaste) {
      dispatch(resetCopyItem());
    }

    return dispatch(
      Request.post(
        { url: '/api/autoflow/message/add', data },
        true,
        response => {
          const data = sortMessages([_.get(response, 'data.data', {})]);
          dispatch({ type: Types.AUTOFLOW_SUCCESS_ADD_AUTO_MESSAGE, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_ADD_AUTO_MESSAGE }),
      ),
    );
  };
};

export const updateMessage = params => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_UPDATE_AUTO_MESSAGE });

    return dispatch(
      Request.put(
        { url: '/api/autoflow/message/update', data: params },
        true,
        response => {
          const responseData = sortMessages(_.get(response, 'data.data', []));
          const payload = { data: responseData };
          dispatch({ type: Types.AUTOFLOW_SUCCESS_UPDATE_AUTO_MESSAGE, payload: payload });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_UPDATE_AUTO_MESSAGE }),
      ),
    );
  };
};

export const deleteMessage = (params, day) => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_DELETE_AUTO_MESSAGE });
    return dispatch(
      Request.delete(
        { url: '/api/autoflow/message/remove', params },
        true,
        response => {
          dispatch({ type: Types.AUTOFLOW_SUCCESS_DELETE_AUTO_MESSAGE, payload: { day, id: params.id } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_DELETE_AUTO_MESSAGE }),
      ),
    );
  };
};

export const getMessageDetail = params => Request.get({ url: '/api/autoflow/message/detail', params }, true);

export const resetCopyItem = () => ({ type: Types.AUTOFLOW_AUTO_MESSAGE_CLEAR_COPY_ITEM });

export const copy = data => {
  toast(<MultiplePasteMessage title="messages" />);

  return {
    type: Types.AUTOFLOW_COPY_AUTO_MESSAGE,
    payload: { data },
  };
};

export const paste = (params, multiPaste) => {
  return dispatch => {
    if (!multiPaste) {
      dispatch(resetCopyItem());
    }

    dispatch({ type: Types.AUTOFLOW_PASTE_AUTO_MESSAGE });

    return dispatch(
      Request.post(
        { url: '/api/autoflow/message/copy', data: params },
        true,
        response => {
          const data = sortMessages([_.get(response, 'data.data', {})]);
          dispatch({ type: Types.AUTOFLOW_SUCCESS_PASTE_AUTO_MESSAGE, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_DELETE_AUTO_MESSAGE }),
      ),
    );
  };
};

export const moveMessage = bodyData => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_MOVE_AUTO_MESSAGE });

    return dispatch(
      Request.put(
        { url: `/api/autoflow/message/move`, data: bodyData },
        true,
        response => {
          const data = sortMessages(_.get(response, 'data.data', []));
          dispatch({ type: Types.AUTOFLOW_SUCCESS_MOVE_AUTO_MESSAGE, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_MOVE_AUTO_MESSAGE }),
      ),
    );
  };
};

export const arrangeMessage = bodyData => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_ARRANGE_AUTO_MESSAGE });

    return dispatch(
      Request.put(
        { url: `/api/autoflow/message/arrange`, data: bodyData },
        true,
        response => {
          const data = sortMessages(_.get(response, 'data.data', []));
          dispatch({ type: Types.AUTOFLOW_SUCCESS_ARRANGE_AUTO_MESSAGE, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_ARRANGE_AUTO_MESSAGE }),
      ),
    );
  };
};

export const onDragMessageEnd = dropResult => {
  const { source, destination, draggableId } = dropResult;

  if (!source || !destination || !draggableId) {
    return { type: 'MOVE_MESSAGE_PRECONDITION_FAILED' };
  }

  const [newDate] = destination.droppableId.split(';');
  const [oldDate, messageId, messageType, dayMessageId] = draggableId.split(';');
  const newIndex = destination.index;

  return (dispatch, getState) => {
    const currentState = getState();
    const {
      rootReducer: {
        autoflow: {
          autoMessage: { messages },
          common: { workingAutoflow },
        },
      },
    } = currentState;

    const autoflow = _.get(workingAutoflow, '_id');

    if (messageType === IN_APP_AUTO_MESSAGE) {
      const today = moment();
      const newDateMoment = moment(newDate, DATE_FORMAT);

      if (newDateMoment.isBefore(today, 'day')) {
        return { type: Types.AUTOFLOW_MOVE_IN_APP_MESSAGE_TO_THE_PAST };
      }

      let shouldAdjust = false,
        originalMessage;
      const dayData = _.find(messages, item => item.day === oldDate);

      if (newDateMoment.isSame(today, 'day') && dayData) {
        originalMessage = _.find(dayData.messages, m => m._id === messageId);

        if (originalMessage) {
          const newDateTime = moment(`${newDate} ${originalMessage.time}`, `${DATE_FORMAT} HH:mm`);

          if (newDateTime.isBefore(today, 'minute')) {
            shouldAdjust = true;
          }
        }
      }

      if (shouldAdjust) {
        const newMessage = { ...originalMessage, day: newDate };
        dispatch(toggleModal(true, <AutoflowInAppMessage originData={newMessage} day={newDate} isEditMode />));
      } else {
        dispatch(moveMessage({ autoflow, messageId, newDay: newDate, newIndex, dayMessageId }));
      }
    } else {
      const selectedDay = messages.find(mes => mes.day === newDate);
      let listAnnouncementsSelectedDay = [];
      if (selectedDay && selectedDay.messages) {
        const { messages: listMessagesSelectedDay } = selectedDay;
        listAnnouncementsSelectedDay = listMessagesSelectedDay.filter(mes => mes.type !== IN_APP_AUTO_MESSAGE);
      }
      if (oldDate === newDate) {
        return dispatch(
          arrangeMessage({
            autoflow,
            dayMessageId,
            messageId,
            newIndex:
              newIndex > listAnnouncementsSelectedDay.length ? listAnnouncementsSelectedDay.length - 1 : newIndex,
          }),
        );
      }
      dispatch(
        moveMessage({
          autoflow,
          messageId,
          newDay: newDate,
          newIndex: newIndex > listAnnouncementsSelectedDay.length ? listAnnouncementsSelectedDay.length : newIndex,
          dayMessageId,
        }),
      );
    }
  };
};

export const uploadAttachment = data => Request.post({ url: '/api/autoflow/message/upload_attachment', data }, false);
