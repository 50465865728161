import React from 'react';
import { SECTION_FORMAT_KEY } from 'constants/commonData';
import Superset from './Superset';
import * as S from '../style';
import { ReactComponent as LinkIcon } from 'assets/icons/link_set.svg';

export default ({ exerise, sectionFormat, exerciseIndex, systemFields }) => (
  <React.Fragment>
    {exerciseIndex > 0 && (
      <S.LinkSet>
        {sectionFormat !== SECTION_FORMAT_KEY.AMRAP && sectionFormat !== SECTION_FORMAT_KEY.TIMED ? <LinkIcon /> : null}
      </S.LinkSet>
    )}
    <S.ExerciseSupersets key={exerise._id}>
      {exerise.supersets.map((superset, supersetIndex) => {
        return (
          <React.Fragment key={superset._id}>
            {supersetIndex > 0 && (
              <S.LinkSet linked={true}>
                <LinkIcon />
              </S.LinkSet>
            )}
            <Superset supersetData={superset} key={superset._id} systemFields={systemFields} />
          </React.Fragment>
        );
      })}
    </S.ExerciseSupersets>
  </React.Fragment>
);
