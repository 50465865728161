// Libs
import React from 'react';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

// Styles
import * as S from './style';

const CreateButton = ({ contentButton, onClick, disabled }) => {
  return (
    <S.Button onClick={onClick} disabled={disabled}>
      <PlusIcon className="custom-plus-icon" />
      {contentButton}
    </S.Button>
  );
};

export default CreateButton;
