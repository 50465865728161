import { Modal } from 'semantic-ui-react';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import styled from 'styled-components';

export const StudioUpgradeModal = styled(Modal)`
  &.ui.modal {
    width: 383px;
    height: 220px;
    border-radius: 8px !important;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-family: 'Open Sans';

    :last-child {
      padding: 30px !important;
    }
  }

  &.ui.modal > :last-child {
    padding: 0px;
  }

  .close-icon {
    width: 18px !important;
    height: 18px !important;
  }
`;

export const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`;

export const Content = styled.div`
  color: #000;

  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  margin-top: 15px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center !important;
  align-items: center !important;
`;

export const StudioUpgradePathButton = styled(UpgradePathButton)`
  margin-top: 30px;
  display: flex;
  width: 225px;
  height: 32px;
  padding: 7px 35px;
  flex-direction: column;
  align-items: center;

  span {
    text-transform: none !important;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    margin-top: 3px;
  }
`;
