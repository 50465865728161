import React from 'react';
import { get } from 'lodash';
import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import Avatar from 'react-avatar';
import { getUserShortName } from 'utils/commonFunction';

export default function ProgramOption({ data, user, cloudfrontList }) {
  const no_of_weeks = get(data, 'workout_sets', []).length;
  const shouldRenderAvatar = user._id !== data.author._id;

  return (
    <div className="asset-content">
      <div className="program-info-container">
        <div className="program-title-container">
          <div className="title">{data.title}</div>
          {data.active_sync && <div className="live-sync-badge">Live Sync</div>}
        </div>
        {shouldRenderAvatar && (
          <Avatar
            className="program-author-avatar"
            name={getUserShortName(data.author)}
            size="25"
            src={convertS3UrlToCloudFrontUrl(data.author.avatar, cloudfrontList, true)}
            color={data.author.color}
          />
        )}
      </div>
      {no_of_weeks > 0 && (
        <div className="asset-info">
          {no_of_weeks} {pluralize('WEEK', no_of_weeks)}
        </div>
      )}
    </div>
  );
}
