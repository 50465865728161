import Request, { axiosInstance } from 'configs/request';
import pick from 'lodash/pick';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment';
import { STEP_UNIQUE_CODE } from 'constants/commonData';
import { getGroupMetrics } from 'actions/groupMetric';
import { getAllClientMetricsSetting } from 'redux/client/client.actionCreators';
import { SPECIAL_METRICS_CODE } from 'components/BodyMetricChartNew/constants';
import { getHeartRateChartData } from 'redux/heart-rate/action';
import { getOptionFromRange } from 'components/BodyMetricChartNew/constants';

export const Types = {
  SELECT_BODY_METRIC: 'SELECT_BODY_METRIC',
  RESET_SELECTED_BODY_METRIC: 'RESET_SELECTED_BODY_METRIC',
  CHANGE_METRIC_SETTING_REQUEST: 'CHANGE_METRIC_SETTING_REQUEST',
  SUCCESS_GET_BODY_METRIC_CHART_DATA: 'SUCCESS_GET_BODY_METRIC_CHART_DATA',
  GETTING_BODY_METRIC_CHART_DATA: 'GETTING_BODY_METRIC_CHART_DATA',
  GETTING_BODY_METRIC_TARGET: 'GETTING_BODY_METRIC_TARGET',
  SUCCESS_GET_BODY_METRIC_TARGET: 'SUCCESS_GET_BODY_METRIC_TARGET',
  SUCCESS_UPDATE_BODY_METRIC_TARGET: 'SUCCESS_UPDATE_BODY_METRIC_TARGET',
  RESET_BODY_METRIC_TARGET: 'RESET_BODY_METRIC_TARGET',
  CHANGE_VIEW_MODE: 'CHANGE_VIEW_MODE',
  GETTING_BODY_METRIC_SETTING: 'GETTING_BODY_METRIC_SETTING',
  SUCCESS_GET_BODY_METRIC_SETTING: 'SUCCESS_GET_BODY_METRIC_SETTING',
  SUCCESS_UPDATE_BODY_METRIC_SETTING: 'SUCCESS_UPDATE_BODY_METRIC_SETTING',
  UPDATE_FILTER_TIME_METRIC_CHART: 'UPDATE_FILTER_TIME_METRIC_CHART',
  TOGGLE_COMPARISION_MODE_METRIC_CHART: 'TOGGLE_COMPARISION_MODE_METRIC_CHART',
  BODY_METRIC_CHANGE_GETTING_CHART_DATA: 'BODY_METRIC_CHANGE_GETTING_CHART_DATA',
  BODY_METRIC_RESET_FILTER_TIME: 'BODY_METRIC_RESET_FILTER_TIME',
  BODY_METRIC_LOADING_COMPARE_CHART: 'BODY_METRIC_LOADING_COMPARE_CHART',
  BODY_METRIC_SLEEP_UPDATE_VIEW: 'BODY_METRIC_SLEEP_UPDATE_VIEW',
  BODY_METRIC_SLEEP_UPDATE_GOAL_REQUEST: 'BODY_METRIC_SLEEP_UPDATE_GOAL_REQUEST',
  BODY_METRIC_SLEEP_UPDATE_GOAL_SUCCESS: 'BODY_METRIC_SLEEP_UPDATE_GOAL_SUCCESS',
  BODY_METRIC_SLEEP_UPDATE_GOAL_FAILED: 'BODY_METRIC_SLEEP_UPDATE_GOAL_FAILED',
  SUCCESS_GET_BODY_METRIC_SLEEP_CHART_DATA: 'SUCCESS_GET_BODY_METRIC_SLEEP_CHART_DATA',
  BODY_METRIC_UPLOAD_LOADING_EXERCISE: 'BODY_METRIC_UPLOAD_LOADING_EXERCISE',
};

// payload: { viewBy: 'duration' | 'time', isShowStages: boolean }
export const updateSleepView = payload => ({
  type: Types.BODY_METRIC_SLEEP_UPDATE_VIEW,
  payload,
});

export const updateSleepGoal = data => {
  return dispatch => {
    dispatch({ type: Types.BODY_METRIC_SLEEP_UPDATE_GOAL_REQUEST });
    dispatch(
      Request.post(
        {
          url: '/api/body-metric/add-client-target',
          data,
        },
        true,
        () => {
          dispatch({
            type: Types.BODY_METRIC_SLEEP_UPDATE_GOAL_SUCCESS,
            payload: data,
          });
        },
        error => {
          console.error(error);
          dispatch({
            type: Types.BODY_METRIC_SLEEP_UPDATE_GOAL_FAILED,
          });
        },
        false,
      ),
    );
  };
};

export const updateMetricData = metricData => {
  return (dispatch, getState) => {
    const currentState = getState();
    const {
      bodyMetric: { filterTime = {}, mode = '' },
      rootReducer,
    } = currentState;
    const clientId = rootReducer.client.workingClientDetail ? rootReducer.client.workingClientDetail._id : undefined;
    const isStep = metricData && [metricData.unit].includes(STEP_UNIQUE_CODE);
    const timezone = moment.tz.guess();

    const params = isStep
      ? {
          uniqueCode: metricData.unique_code,
          mode: mode,
          client: clientId,
          timezone,
        }
      : {
          period_group: filterTime.period_group,
          from_date: filterTime.from_date,
          to_date: filterTime.to_date,
          metric_code: metricData.unique_code,
          client: clientId,
          timezone,
        };

    const getTargetParams = {
      client: clientId,
      unique_code: metricData.unique_code,
    };

    dispatch({ type: Types.SELECT_BODY_METRIC, data: metricData });
    const isHeartRate = SPECIAL_METRICS_CODE.HEART_RATE === (metricData || {}).unique_code;
    if (isHeartRate) {
      dispatch(getHeartRateChartData(params));
    } else {
      isStep ? dispatch(selectBodyMetric(params)) : dispatch(getNormalMetricChartDataLatestByDay(params));
    }

    dispatch(getBodyMetricTarget(getTargetParams));
    dispatch(getMetricSettings(metricData.unique_code));
  };
};

export const selectBodyMetric = params => {
  return dispatch => {
    dispatch({ type: Types.GETTING_BODY_METRIC_CHART_DATA });
    dispatch(
      Request.get(
        {
          url: '/api/body-metric-entry/get-entry-list',
          params,
        },
        true,
        result => {
          const { data } = result.data;
          const sortedData = data
            .sort((a, b) => (moment(a.day, 'MM-DD-YYYY').isAfter(moment(b.day, 'MM-DD-YYYY')) ? 1 : -1))
            .slice();
          dispatch({
            type: Types.SUCCESS_GET_BODY_METRIC_CHART_DATA,
            payload: { data: sortedData, unique_code: params.uniqueCode },
          });
        },
      ),
    );
  };
};

export const getSleepChartData = (params, filterByDate) => {
  return dispatch => {
    dispatch(
      Request.get(
        {
          url: '/api/sleep-metric-entries/chart-data',
          params: { ...omit(params, ['timeRangeString']) },
        },
        true,
        result => {
          const {
            data: { summary_data, details },
          } = result.data;
          dispatch({
            type: Types.SUCCESS_GET_BODY_METRIC_SLEEP_CHART_DATA,
            payload: {
              data: details,
              keyMetricData: summary_data,
              unique_code: params.metric_code,
              filterByDate,
              isFromNormalChart: true,
            },
          });
        },
      ),
    );
  };
};

export const getNormalMetricChartDataLatestByDay = params => {
  return async (dispatch, getState) => {
    const {
      bodyMetric: {
        metricSettings: { data_point },
      },
    } = getState();

    let finalDataPoint = data_point;
    if (params.metric_code) {
      try {
        const { data } = await axiosInstance.get(`/api/v2/body-metric-entry/chart-settings/${params.client}`, {
          params: { metric_code: params.metric_code },
        });
        finalDataPoint = data.data.data_point;
      } catch (error) {}
    }

    const FORMAT_DATE = 'yyyy-MM-DD';
    const startDate = moment(params.from_date, FORMAT_DATE);
    const endDate = moment(params.to_date, FORMAT_DATE);

    const filterByDate = {
      from_date: params.from_date,
      to_date: params.to_date,
      period_group: params.period_group,
      timeRangeString: getOptionFromRange({ startDate, endDate }),
    };
    dispatch({ type: Types.GETTING_BODY_METRIC_CHART_DATA });
    dispatch({ type: Types.UPDATE_FILTER_TIME_METRIC_CHART, payload: filterByDate });

    if (params.metric_code) {
      if (params.metric_code === SPECIAL_METRICS_CODE.SLEEP) {
        return dispatch(getSleepChartData(params, filterByDate));
      }

      dispatch(getBodyMetricTarget({ client: params.client, unique_code: params.metric_code }));
      dispatch(
        Request.get(
          {
            url: '/api/v2/body-metric-entry/chart-data-latest-by-day',
            params: { ...omit(params, ['timeRangeString']), data_point: finalDataPoint },
          },
          true,
          result => {
            const {
              data,
              previous_day = '',
              previous_value = '',
              previous_entry = {},
              average = null,
              previous_item = {},
              next_item = {},
            } = result.data;
            dispatch({
              type: Types.SUCCESS_GET_BODY_METRIC_CHART_DATA,
              payload: {
                data: data,
                unique_code: params.metric_code,
                filterByDate,
                isFromNormalChart: true,
                previous_day,
                previous_value,
                previous_entry,
                average,
                previous_item,
                next_item,
              },
            });
          },
        ),
      );
    }
  };
};

export const getBodyMetricTarget = params => {
  return (dispatch, getState) => {
    const { bodyMetric } = getState();
    if (bodyMetric && bodyMetric.unique_code === params.unique_code) {
      dispatch({ type: Types.GETTING_BODY_METRIC_TARGET });
      dispatch(
        Request.get(
          {
            url: '/api/body-metric/get-client-target',
            params,
          },
          false,
          result => {
            if (result && result.data.data) {
              dispatch({
                type: Types.SUCCESS_GET_BODY_METRIC_TARGET,
                payload: { data: result.data.data, unique_code: params.unique_code },
              });
            }
          },
        ),
      );
    }
  };
};

export const getBodyMetricTargetOverview = params => {
  return Request.get(
    {
      url: '/api/body-metric/get-client-target',
      params,
    },
    false,
  );
};

export const getMetricSettingsOverview = metric_code => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const clientId = (rootReducer.client || {}).selected;

    return dispatch(
      Request.get(
        {
          url: `/api/v2/body-metric-entry/chart-settings/${clientId}`,
          params: { metric_code },
        },
        true,
      ),
    );
  };
};

// Action for manual step
export const changeViewMode = timeRange => {
  return (dispatch, getState) => {
    dispatch({ type: Types.CHANGE_VIEW_MODE, payload: { data: timeRange } });
    const currentState = getState();
    const { bodyMetric, user, rootReducer } = currentState;
    const clientId = rootReducer.client.workingClientDetail ? rootReducer.client.workingClientDetail._id : undefined;
    const params = {
      uniqueCode: bodyMetric.unique_code,
      mode: timeRange,
      client: clientId,
      timezone: user.timezone,
    };
    dispatch(selectBodyMetric(params));
  };
};

export const filterChartViewMode = (fromDate, toDate, periodGroup, timeRangeString) => {
  return (dispatch, getState) => {
    const { bodyMetric, rootReducer } = getState();
    const { client } = rootReducer;
    const clientId = client.workingClientDetail ? client.workingClientDetail._id : undefined;
    const timezone = moment.tz.guess();
    const params = {
      metric_code: bodyMetric.unique_code,
      client: clientId,
      period_group: periodGroup,
      from_date: fromDate,
      to_date: toDate,
      timezone,
      timeRangeString,
    };
    const isHeartRate = bodyMetric.unique_code === SPECIAL_METRICS_CODE.HEART_RATE;
    if (isHeartRate) {
      dispatch(getHeartRateChartData(params));
    } else {
      dispatch(getNormalMetricChartDataLatestByDay(params));
    }
  };
};

export const updateBodyMetricTarget = bodyRequest => {
  return Request.post(
    {
      url: '/api/body-metric/add-client-target',
      data: bodyRequest,
    },
    true,
    (result, { dispatch }) => {
      const { data } = result.data;
      dispatch({ type: Types.SUCCESS_UPDATE_BODY_METRIC_TARGET, payload: { data } });
      dispatch(getBodyMetricTarget({ client: bodyRequest.client, unique_code: bodyRequest.unique_code }));
    },
  );
};

export const resetBodyMetricData = () => ({
  type: Types.RESET_SELECTED_BODY_METRIC,
});

export const getMetricSettings = metricCode => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { bodymetricTypes = [], workingClientDetail = {} } = rootReducer.client;
    const clientId = workingClientDetail ? workingClientDetail._id : undefined;
    dispatch({ type: Types.GETTING_BODY_METRIC_SETTING });

    dispatch(
      Request.get(
        {
          url: `/api/v2/body-metric-entry/chart-settings/${clientId}`,
          params: { metric_code: metricCode },
        },
        true,
        (result, { dispatch }) => {
          dispatch({
            type: Types.SUCCESS_GET_BODY_METRIC_SETTING,
            payload: { data: get(result, 'data.data', null), bodymetricTypes },
          });
        },
      ),
    );
  };
};

export const changeMetricSettingsRequest = () => ({
  type: Types.CHANGE_METRIC_SETTING_REQUEST,
});

export const updateMetricSettings = (clientId, settings, metricCode = '') => {
  const pickParams = pick(settings, ['type', 'color', 'key_metrics', 'display_data_points', 'data_point']);
  return Request.patch(
    {
      url: `/api/v2/body-metric-entry/chart-settings/${clientId}`,
      data: { ...pickParams, metric_code: metricCode, unit: get(settings, 'unit._id', '') },
    },
    true,
    (result, { dispatch }) => {
      dispatch(getMetricSettings(metricCode));
      dispatch(getGroupMetrics(clientId));
      dispatch(getAllClientMetricsSetting(clientId));
    },
  );
};

export const toggleComparisonMode = (status, bodyMetricCompare) => {
  return dispatch =>
    dispatch({ type: Types.TOGGLE_COMPARISION_MODE_METRIC_CHART, payload: { status, bodyMetricCompare } });
};

export const changeGettingChartData = status => {
  return dispatch => {
    dispatch({ type: Types.BODY_METRIC_CHANGE_GETTING_CHART_DATA, payload: { status } });
  };
};

export const resetFilterTime = () => {
  return dispatch => {
    dispatch({ type: Types.BODY_METRIC_RESET_FILTER_TIME });
  };
};

export const resetBodyMetricTarget = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_BODY_METRIC_TARGET });
  };
};

export const updateLoadingExerciseMetric = (status = false) => {
  return dispatch => {
    dispatch({ type: Types.BODY_METRIC_UPLOAD_LOADING_EXERCISE, payload: { status } });
  };
};
export const updateLoadingCompareChart = (status = false) => {
  return dispatch => {
    dispatch({ type: Types.BODY_METRIC_LOADING_COMPARE_CHART, payload: { status } });
  };
};
