import styled, { css } from 'styled-components';

export const Option = styled.div`
  padding: 5px 12px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  :hover {
    background-color: #696969;
  }
  ${props =>
    props.disabled &&
    css`
      & > div {
        cursor: not-allowed !important;
      }
      span {
        color: #202353;

        opacity: 0.2;
      }
    `}
`;

export const Trigger = styled.div`
  width: 20px;
  height: 12px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;

  .dots {
    width: 3px;
    height: 3px;
    position: relative;
    border-radius: 50%;
    background-color: #dedede;

    ::before,
    ::after {
      content: '';
      width: 3px;
      height: 3px;
      position: absolute;
      border-radius: 50%;
      background-color: inherit;
      top: 0;
      left: -5px;
    }

    ::after {
      left: 5px;
    }
  }

  :hover {
    background-color: #f2f2f2;

    .dots {
      background-color: #726ee4;
    }
  }
`;

export const Menu = styled.div`
  width: auto;
  z-index: 9999;
  padding: 6px 0;
  background-color: #363636;
  color: #fff;
  font-size: 13px;
  line-height: 150%;
  border-radius: 5px;
  position: fixed;
  display: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  overflow: auto;
`;

export const Wrapper = styled.div`
  position: relative;

  &.open {
    ${Trigger} {
      background-color: #f2f2f2;

      .dots {
        background-color: #726ee4;
      }
    }

    ${Menu} {
      display: block;
    }
  }

  &.light {
    ${Menu} {
      color: #000;
      background-color: #fff;
      border: 1px solid #d4d7d9;
      box-sizing: border-box;

      ${Option} {
        :hover {
          background: #f5f7f9;
        }
      }
    }
  }
`;
