import styled, { css } from 'styled-components';

export const ChooseIconsPopupWrapper = styled.div`
  width: 380px;
  height: 450px;
  border-radius: 10px;

  .icon-content {
    overflow: hidden;
    height: 100%;
    max-height: 390px;
    padding: 0;
    display: flex;

    .epr-category-nav {
      display: none;
    }

    .epr-header .epr-header-overlay {
      padding: 10px 15px 15px;

      .epr-search-container input.epr-search {
        background: #f9f9fa;
        ::placeholder {
          opacity: 0.2;
        }
      }
      .epr-search-container .epr-icn-search .epr_i3a6gx {
        opacity: 0.2;
      }

      .epr-search-container button.epr-btn-clear-search:hover {
        background: transparent;
      }
    }

    aside.EmojiPickerReact.epr-main {
      border-radius: 0;
      border: none;
      .epr-body {
        padding: 0;
        margin-right: 8px;
        ::-webkit-scrollbar {
          width: 6px !important;
        }
      }

      &.custom-emoji-picker {
        input:placeholder-shown ~ .epr-btn-clear-search {
          visibility: hidden;
          pointer-events: none;
          opacity: 0;
          display: none;
        }

        input:not(:placeholder-shown) ~ .epr-btn-clear-search {
          visibility: visible;
          pointer-events: auto;
          opacity: 1;
          display: flex;
        }
      }

      .epr-emoji-category-label {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: #6a778a;
      }

      .epr-emoji-category-content button.epr-emoji {
        .epr-emoji-native {
          transform: translateY(1px);
        }

        &:hover {
          background-color: #f5f5f5;
          border-radius: 50%;
          > * {
            background-color: transparent;
          }
        }

        &:focus {
          background-color: transparent;
          border-radius: 50%;
          > * {
            background-color: transparent;
          }
        }
      }

      ${props => css`
        .epr-emoji-category-content {
          button[data-unified='${props.unified}'] {
            border: 1px solid #5158cf;
            border-radius: 50%;

            &.epr-emoji:focus img {
              background: transparent;
            }
          }

          button img {
            padding: 6px;
          }
        }
      `}
    }
  }

  .modal__actions {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 14px 20px;
    text-align: right;
    background: #fff;
    font-size: 12px;

    .actions__update {
      min-width: 73px;
      max-width: 73px;
      padding: 6px 15px;
      font-size: 12px;
      :hover {
        opacity: 0.9;
      }
    }
  }
`;

export const OverviewWrapper = styled.div`
  overflow: hidden;
  min-height: 390px;
  position: relative;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const BackgroundWrapper = styled.div`
  max-width: 100%;
  padding: 19px 0 13px;
  margin: 0 15px;

  display: flex;
  flex-direction: column;
  gap: 14px;
  border-bottom: 1px solid #f0f0f2;
`;

export const IconSection = styled.div`
  width: 100%;
  padding: 19px 15px 0px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #202353;

  svg {
    cursor: pointer;
  }
`;

export const BackgroundList = styled.div`
  display: flex;
  gap: 6.5px;
`;

export const BackgroundSelected = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border-radius: 50%;
      border: 1px solid #5158cf;
    `}
`;

export const BackgroundItem = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: ${props => props.background};
`;

export const IconWrapper = styled.div`
  display: block;
  position: relative;
  padding: 0 5px;
  .custom-emoji-picker {
    .epr-search-container input {
      padding: 5px 15px 5px 35px;
      width: 350px;
    }
  }
  &.EmojiPickerReact .epr-body {
    ::-webkit-scrollbar {
      width: 5px !important;
    }
  }
`;

export const EmptyMessage = styled.span`
  position: absolute;
  top: calc(50% + 25px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #9a9da6;
`;

export const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 264px;
`;

export const PreviewWrapper = styled.div`
  width: 210px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${props =>
    css`
      border: 3px solid ${props.border};
    `};
`;

export const PreviewBackground = styled.div`
  width: 161px;
  height: 161px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${props =>
    css`
      background: ${props.background};
    `};

  span {
    font-size: 32px;
  }
`;
