import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { get } from 'lodash';

import * as S from './style';

import Card from 'shared/Card';
import EmptyProductList from './EmptyProductList';

import { ReactComponent as Bullet } from 'assets/icons/product-gray-bullet.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/action_archive.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/task_bin.svg';
import ArchiveLight from 'assets/icons/archive_light.svg';
import PlaceholderCover from 'assets/images/product-no-cover.png';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import StatusChip from 'shared/StatusChip';
import { PRODUCT_STATUS } from 'components/Product/constants';
import TrashIcon from 'assets/icons/red_trash_circle.svg';
import LoadingIndicator from 'shared/LoadingIndicator';
import { connect } from 'react-redux';
import { SharedTooltip } from 'shared/SharedTooltip';

const List = props => {
  const {
    duplicating,
    loading: isLoading,
    products,
    productTotal,
    isHideBanner,
    onAddNewProduct,
    getProductDetail,
    toggleConfirmModal,
    archiveProduct,
    deleteProduct,
    unArchiveProduct,
    duplicateProduct,
    push: pushRoute,
  } = props;
  const [workingItem, setWorkingItem] = useState(null);

  const handleOpenEdit = ({ id, is_purchased, hash_id }) => {
    getProductDetail(id, !is_purchased);
    pushRoute(`/home/sequences/${hash_id}`);
  };

  const handleOpenDetail = ({ id, hash_id }) => {
    const fId = hash_id || id;
    getProductDetail(fId);
    pushRoute(`/home/sequences/${fId}`);
  };

  const handleNotFound = () => {
    if (productTotal <= 0) {
      return <EmptyProductList isHideBanner={isHideBanner} onAddNewProduct={onAddNewProduct} />;
    }

    // search or empty category
    if ((products || []).length === 0) {
      return <S.NotFoundText>No sequences found</S.NotFoundText>;
    }
  };

  const handleArchive = val => {
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="exit-confirm archive"
        noBorder
        headerIcon={ArchiveLight}
        hasCloseIcon
        hasHoverState
        title={`Archive ${val.name}?`}
        content={
          <>
            <S.CustomPopupMessage>
              Once the sequence is archived, it will not remain active in any package it is in. Clients purchasing the
              packages will not receive the sequence or its assets.
            </S.CustomPopupMessage>
            <S.CustomPopupMessage>
              Clients who have purchased the sequence already will not be affected.
            </S.CustomPopupMessage>
            <S.CustomPopupMessage>Would you like to archive the sequence?</S.CustomPopupMessage>
          </>
        }
        onConfirm={() => {
          archiveProduct && archiveProduct(val, false);
        }}
        onDeny={handleClosePopup}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Archive sequence"
      />,
    );
  };

  const handleClosePopup = () => {
    toggleConfirmModal(false);
  };

  const handleDelete = val => {
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="exit-confirm"
        noBorder
        headerIcon={TrashIcon}
        hasCloseIcon
        hasHoverState
        title="Delete Sequence"
        content="This sequence will be permanently deleted from your account. Would you like to continue?"
        onConfirm={() => {
          deleteProduct && deleteProduct(val.id);
        }}
        onDeny={handleClosePopup}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Delete"
      />,
    );
  };

  const handleUnArchive = val => {
    unArchiveProduct && unArchiveProduct(val);
  };

  const handleDuplicate = val => {
    setWorkingItem(val);
    duplicateProduct && duplicateProduct(val);
  };

  const handleRenderTriggerIcon = open => {
    return (
      <div data-for="sequences-options-tooltip" data-tip>
        <S.TriggerIcon className="trigger-icon" active={!!open} />
        {!open && <SharedTooltip id="sequences-options-tooltip" noWrap />}
      </div>
    );
  };

  const handleErrorImg = ({ banner, banner_s3 }) => evt => {
    evt.target.src = evt.target.src === banner ? banner_s3 : PlaceholderCover;
  };

  return (
    <>
      <S.Wrapper productTotal={productTotal}>
        {products.map((product, idx) => (
          <Card
            key={idx}
            banner={product.banner || PlaceholderCover}
            title={product.name}
            description={product.description || '-'}
            className="product-card"
            onClick={() => handleOpenDetail(product)}
            onImageError={handleErrorImg(product)}
          >
            <span className="extra-info">
              Assets: <b>{product.assets_count}</b>
              <Bullet />
              Owner: <b>{product.owner && product.owner.full_name}</b>
            </span>
            <StatusChip status={product.status} />
            <S.DropdownWrapper>
              <DropDown
                direction="left"
                triggerIcon={({ open }) => handleRenderTriggerIcon(open)}
                shouldCheckItem={true}
              >
                <S.DropdownArea>
                  {product.status !== PRODUCT_STATUS.ARCHIVE && (
                    <Option key={`edit-${product.id}`} onClick={() => handleOpenEdit(product)}>
                      <EditIcon />
                      <span>Edit</span>
                    </Option>
                  )}

                  <Option key={`duplicate-${product.id}`} onClick={() => handleDuplicate(product)}>
                    <DuplicateIcon />
                    <span>Duplicate</span>
                  </Option>

                  {(product.status === PRODUCT_STATUS.PUBLISH || product.status === PRODUCT_STATUS.DRAFT) && (
                    <Option key={`archive-${product.id}`} onClick={() => handleArchive(product)}>
                      <ArchiveIcon />
                      <span>Archive</span>
                    </Option>
                  )}

                  {product.status === PRODUCT_STATUS.DRAFT && (
                    <Option key={`delete-${product.id}`} onClick={() => handleDelete(product)}>
                      <DeleteIcon />
                      <span>Delete</span>
                    </Option>
                  )}

                  {product.status === PRODUCT_STATUS.ARCHIVE && (
                    <Option key={`unarchive-${product.id}`} onClick={() => handleUnArchive(product)}>
                      <ArchiveIcon />
                      <span>Unarchive</span>
                    </Option>
                  )}
                </S.DropdownArea>
                <S.DropdownArrow></S.DropdownArrow>
              </DropDown>
            </S.DropdownWrapper>
          </Card>
        ))}

        {!isLoading && handleNotFound()}

        {duplicating && (
          <S.DuplicateWrapper>
            <Loader active inline="centered" className="duplicate-indicator" />
            <span>Duplicating {get(workingItem, 'name', '')} ...</span>
          </S.DuplicateWrapper>
        )}
      </S.Wrapper>
      <S.LoadingWrapper>{isLoading && <LoadingIndicator className="loading" />}</S.LoadingWrapper>
    </>
  );
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(List);
