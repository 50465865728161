import { toggleSecondModal } from 'actions/modal';
import find from 'lodash/find';
import get from 'lodash/get';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateMetric } from 'redux/progress-photo/actions';
import { Button, Modal } from 'semantic-ui-react';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const UpdateMetric = ({
  toggleSecondModal,
  weightMetric,
  idBodyFatUnit,
  idBodyFatMetric,
  weight,
  bodyFat,
  updateMetric,
  measurementId,
  countPhotoUpdate,
  selectedDate,
}) => {
  const defaultData = {
    value: weight > 0 ? weight : '',
    unit: get(weightMetric, 'id', ''),
    other_metrics: [
      {
        unit: idBodyFatUnit,
        body_metric: idBodyFatMetric,
        value: bodyFat > 0 ? bodyFat : '',
      },
    ],
  };

  const [error, setError] = useState({});
  const [data, setData] = useState(defaultData);
  const [isChangeValue, setIsChangeValue] = useState(false);

  const handleChangeValueUnit = (value, name) => {
    setIsChangeValue(true);
    const newValue = get(value, 'value');
    const floatValue = get(value, 'floatValue');
    if (name === 'weight') {
      setError({ ...error, weight_empty: false });
      if (floatValue >= 1000) {
        setData({ ...data, value: '999.99' });
      } else {
        setData({ ...data, value: newValue });
      }
    } else if (name === 'bodyFat') {
      setError({ ...error, body_fat_empty: false });
      if (floatValue >= 100) {
        setData({
          ...data,
          other_metrics: [{ ...data.other_metrics[0], value: '99.99' }],
        });
      } else {
        setData({
          ...data,
          other_metrics: [{ ...data.other_metrics[0], value: newValue }],
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (checkValidateData(data)) {
      const weightValue = get(data, 'value', 0);
      const bodyFatValue = get(data, 'other_metrics[0].value', 0);

      data.value = Number(weightValue) > 0 ? Number(weightValue) : 0;
      data.other_metrics[0].value = Number(bodyFatValue) > 0 ? Number(bodyFatValue) : 0;

      if (measurementId) {
        updateMetric({ measurementId, data, selectedDate }).then(() => {
          toggleSecondModal(false);
        });
      }
    }
  };

  const checkValidateData = data => {
    let checkError = { ...error };

    const weightData = get(data, 'value', 0);
    const bodyFatData = get(data, 'other_metrics[0].value', 0);

    const weightValue = parseFloat(weightData) < 1 ? true : false;
    const bodyFatValue = parseFloat(bodyFatData) < 0.1 ? true : false;

    if (weightValue) {
      checkError.weight_empty = true;
    }

    if (bodyFatValue) {
      checkError.body_fat_empty = true;
    }

    if (weight > 0 && weightData === '') {
      checkError.weight_empty = true;
    }

    if (bodyFat > 0 && bodyFatData === '') {
      checkError.body_fat_empty = true;
    }

    setError(checkError);
    return !get(checkError, 'weight_empty', false) && !get(checkError, 'body_fat_empty', false);
  };

  return (
    <S.UpdateMetric open={true} closeOnDimmerClick={false} onClose={() => toggleSecondModal(false)}>
      <Button className="close-button" onClick={() => toggleSecondModal(false)}>
        <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
      </Button>
      <Modal.Content>
        <S.Title>Update Metric </S.Title>
        <S.UnitWrapper>
          <S.MetricItem>
            <S.MetricName>Weight</S.MetricName>
            <S.MetricInput isError={error && error.weight_empty}>
              <NumberFormat
                className="progress-photo-weight-input"
                onValueChange={e => handleChangeValueUnit(e, 'weight')}
                value={get(data, 'value') === 0 ? '' : get(data, 'value')}
                allowNegative={false}
                decimalSeparator="."
                isNumericString={true}
                decimalScale={get(data, 'value') > 1000 ? 0 : 2}
                maxLength={get(data, 'value') > 1000 ? 4 : null}
              />
              <S.MetricUnit>{get(weightMetric, 'title', '')}</S.MetricUnit>
            </S.MetricInput>
          </S.MetricItem>
          <S.MetricItem>
            <S.MetricName>Body Fat</S.MetricName>
            <S.MetricInput isError={error && error.body_fat_empty}>
              <NumberFormat
                className="progress-photo-weight-input"
                onValueChange={e => handleChangeValueUnit(e, 'bodyFat')}
                value={get(data, 'other_metrics[0].value') === 0 ? '' : get(data, 'other_metrics[0].value')}
                allowNegative={false}
                decimalSeparator="."
                decimalScale={get(data, 'other_metrics[0].value') > 100 ? 0 : 2}
                maxLength={get(data, 'other_metrics[0].value') > 100 ? 3 : null}
                isNumericString={true}
              />
              <S.MetricUnit>%</S.MetricUnit>
            </S.MetricInput>
          </S.MetricItem>
        </S.UnitWrapper>
        <S.Desc>This change will apply to {pluralize('other photo', countPhotoUpdate, true)}</S.Desc>
      </Modal.Content>
      <Modal.Actions>
        <S.ButtonSave onClick={handleSubmit} disabled={!isChangeValue}>
          Save
        </S.ButtonSave>
      </Modal.Actions>
    </S.UpdateMetric>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    rootReducer: {
      client: { bodymetricTypes: bodyMetricTypes = [] },
      progressPhoto: { photoList },
    },
  } = state;

  const { measurementId, selectedDate } = ownProps;

  const weightMetric = get(
    find(bodyMetricTypes, it => it.unique_code === 'weight'),
    'unit',
    [],
  );

  const bodyFatMetric = get(
    find(bodyMetricTypes, it => it.unique_code === 'body_fat'),
    'category.units',
    [],
  );

  const idBodyFatUnit = get(bodyFatMetric, '[0]._id', '');

  const idBodyFatMetric = get(
    find(bodyMetricTypes, it => it.unique_code === 'body_fat'),
    '_id',
    '',
  );

  // countPhotoUpdate
  const photByDate = photoList.find(item => get(item, 'day', '') === selectedDate);
  const measurement = get(photByDate, 'measurements', []).find(item => get(item, '_id', '') === measurementId);
  const countPhotoUpdate = get(measurement, 'tags.length', 0);

  return {
    weightMetric,
    idBodyFatUnit,
    idBodyFatMetric,
    countPhotoUpdate,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  updateMetric: bindActionCreators(updateMetric, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMetric);
