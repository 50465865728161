import React, { useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactAvatar from 'react-avatar';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { Button } from 'shared/FormControl';
import * as S from './style';
import {
  getAvailableClients,
  getResultAddClients,
  removeClientFromRedux,
  saveClientsAvailable,
} from 'redux/studio-program/actions';
import {
  convertS3UrlToCloudFrontUrl,
  getUserShortName,
  isTeamAdmin,
  pluralize,
  splitOwner,
} from 'utils/commonFunction';
import { LoaderRing } from 'shared/Loading';
import AddClientConfirm from './AddClientConfirm';
import { toggleModal } from 'actions/modal';

const ClientItem = ({ client, onRemove, getUrl, isOwnerOrAdmin = false, user = {}, originClients = [] }) => {
  const canRemove = useMemo(() => {
    const assignedClient = originClients.find(it => it._id === client._id) || {};
    if (!_.isEmpty(assignedClient) && !_.isEmpty(user)) {
      const trainers = assignedClient.trainers || [];
      const subTrainers = assignedClient.sub_trainers || [];
      const isTrainer = trainers.findIndex(it => it.trainer === user._id);
      const isSubTrainer = subTrainers.findIndex(it => it.trainer === user._id);
      return isOwnerOrAdmin || isTrainer !== -1 || isSubTrainer !== -1;
    }
    return true;
  }, [client, user, originClients, isOwnerOrAdmin]);

  return (
    <S.ClientItemWrapper>
      <div className="clientItem__info">
        <Link to={canRemove ? `/home/client/${client._id}` : '#'}>
          <div>
            <ReactAvatar
              name={getUserShortName(client)}
              className="client-detail-avatar"
              size="34"
              src={getUrl(client.avatar)}
              color={client.color}
            />
          </div>
          <div className="clientItem__name">
            {client.first_name} {client.last_name}
          </div>
        </Link>
      </div>
      {canRemove && <div className="clientItem__removeIcon" onClick={() => onRemove(client)} />}
    </S.ClientItemWrapper>
  );
};

class SelectedClients extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.isOwnerOrAdmin = isTeamAdmin(props.user);
    this.selectedClientBodyRef = React.createRef();
  }

  componentDidMount() {
    const { workingStudio: { _id } = {}, getAvailableClients } = this.props;

    if (_id) {
      this.setState({ isLoadingClients: true });
      getAvailableClients(_id).finally(() => {
        this.setState({ isLoadingClients: false });
      });
    }
  }

  handleRemoveClient = client => {
    this.props.removeClientFromRedux(client);
  };

  doSave = () => {
    const { workingStudio: { _id } = {}, saveClientsAvailable, getAvailableClients } = this.props;

    this.setState({ loading: true });
    saveClientsAvailable().finally(() => {
      this.setState({ loading: false });

      if (_id) getAvailableClients(_id);
    });
  };

  handleSave = () => {
    this.setState({ loading: true });
    this.props
      .getResultAddClients()
      .then(({ data }) => {
        this.props.toggleModal(true, <AddClientConfirm onConfirm={this.doSave} results={data.data} />);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  isChangeClient = () => {
    const { originClients, availableClients } = this.props;
    return _.xor(_.map(originClients, '_id'), _.map(availableClients, '_id')).length;
  };

  getUrl = url => convertS3UrlToCloudFrontUrl(url, this.props.cloudfrontList, true);

  renderClients = ({ id = '', title, clients, headerSticky = false, scrollSticky = false }) => {
    const { user, assignedClients } = this.props;

    const total = clients.length;

    if (!total) return null;

    return (
      <>
        <div className={classNames({ 'header-sticky': headerSticky, 'scroll-sticky': scrollSticky })}>
          <span className="title-clients">
            {title} ({total})
          </span>
        </div>
        <div className="list-clients" data-title-id={id}>
          {clients.map(client => (
            <ClientItem
              client={client}
              key={client._id}
              onRemove={this.handleRemoveClient}
              getUrl={this.getUrl}
              isOwnerOrAdmin={this.isOwnerOrAdmin}
              user={user}
              assignedClients={assignedClients}
            />
          ))}
        </div>
      </>
    );
  };

  render() {
    const { availableClients, user, originClients } = this.props;
    const { loading, isLoadingClients } = this.state;
    const isChange = this.isChangeClient();
    const totalClients = availableClients.length;

    const [yourClients, otherClients] = splitOwner({ user, clients: availableClients });

    return (
      <S.Wrapper>
        <div className="selectClient_heading">
          Clients ({totalClients})
          {!!isChange && (
            <Button className="selectClient_saveBtn" onClick={this.handleSave} disabled={loading || isLoadingClients}>
              Save
            </Button>
          )}
        </div>
        <div className="selectClient__body" ref={this.selectedClientBodyRef}>
          {isLoadingClients && (
            <S.LoadingClients>
              <LoaderRing />
              <span className="loading-text">Loading your clients list</span>
            </S.LoadingClients>
          )}
          {!totalClients && !isLoadingClients && (
            <div className="selectClient__noResult">
              <div className="selectClient__noResult--title">No clients selected</div>
              <div className="selectClient__noResult--description">
                Search and add clients to make the program available for
              </div>
            </div>
          )}
          {/* {!!totalClients &&
            !isLoadingClients &&
            availableClients.map(client => (
              <ClientItem
                client={client}
                key={client._id}
                onRemove={this.handleRemoveClient}
                getUrl={this.getUrl}
                isOwnerOrAdmin={this.isOwnerOrAdmin}
                user={user}
                originClients={originClients}
              />
            ))} */}

          {!!totalClients && !isLoadingClients && (
            <>
              {this.renderClients({
                id: 'your_clients',
                title: 'Your Clients',
                clients: yourClients,
                headerSticky: !!yourClients.length,
                scrollSticky: false,
              })}
              {this.renderClients({
                id: 'others_clients',
                title: 'Other’s Clients',
                clients: otherClients,
                headerSticky: !yourClients.length,
                scrollSticky: !!yourClients.length,
              })}
            </>
          )}
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateTopProps = state => {
  return {
    user: state.user,
    cloudfrontList: state.cloudfrontList,
    workingStudio: state.rootReducer.studioProgram.workingStudio,
    availableClients: state.rootReducer.studioProgram.availableClients,
    originClients: state.rootReducer.studioProgram.originClients,
  };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getAvailableClients: bindActionCreators(getAvailableClients, dispatch),
  removeClientFromRedux: bindActionCreators(removeClientFromRedux, dispatch),
  saveClientsAvailable: bindActionCreators(saveClientsAvailable, dispatch),
  getResultAddClients: bindActionCreators(getResultAddClients, dispatch),
});

export default connect(mapStateTopProps, mapDispatch)(SelectedClients);
