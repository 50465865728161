import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import MainPanel from './component';
import {
  updateTitle,
  updateDescription,
  updateWorkoutPartialData,
  updateTags,
  updateBackground,
  getPdf,
  resetWorkoutError,
} from 'redux/workout-builder/actions';
import { toggleSecondModal } from 'actions/modal';
import { Workout } from 'database/schema';
import { HIDDEN_SECTION, SECTION_FORMAT_KEY } from 'constants/commonData';
import { createNewTag } from 'redux/tags/actions';
import { getPdfRequest, getPdfFinish } from 'redux/calendar/calendar.actionCreators';
import { getMostRecentExercises } from 'redux/exercise/exercise.actionCreators';
import { isAdmin, isOnwer } from 'utils/validations';

const findTheFirstLinkSuperset = sections => {
  if (!sections) {
    return '';
  }

  let id = '';

  const parsedSections = sections.toJS();

  for (let i = 0; i < parsedSections.length; i++) {
    const section = parsedSections[i];

    if (section.type === HIDDEN_SECTION) {
      const nextSection = parsedSections[i + 1];

      if (nextSection && nextSection.type === HIDDEN_SECTION) {
        id = `${section._id}`;
        break;
      }
    } else {
      if (section.format !== SECTION_FORMAT_KEY.AMRAP && section.format !== SECTION_FORMAT_KEY.TIMED) {
        if (section.exercises) {
          if (section.exercises.length > 1) {
            id = `${section._id}-${section.exercises[0]._id}`;
            break;
          }
        }
        if (section.exercise_references) {
          id = `${section._id}-exercise_references`;
          break;
        }
      }
    }
  }

  return id;
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      workoutBuilder,
      model,
      customBranding: { selectedWorkoutBackground },
      workoutTags,
    },
    router,
  } = state;
  const currentWorkoutData = denormalize(workoutBuilder.get('selectedWorkout'), Workout, model);

  return {
    firstLinkSupersetId: findTheFirstLinkSuperset(currentWorkoutData.get('sections')),
    workout: workoutBuilder.getIn(['selectedWorkout']),
    originalWorkout: workoutBuilder.getIn(['originalWorkout']),
    draggingItemLeft: workoutBuilder.getIn(['draggingItemLeft']),
    showError: workoutBuilder.getIn(['showError']),
    currentUserId: user._id,
    location: router.location,
    defaultWorkoutBackground: selectedWorkoutBackground,
    workoutTags,
    workoutModel: model,
    isTrainer: !isOnwer(user) && !isAdmin(user),
    currentWorkoutData,
    isChanged: workoutBuilder.getIn(['isChanged']),
  };
};

const actionCreators = {
  updateTitle,
  updateDescription,
  updateWorkoutPartialData,
  updateBackground,
  updateTags,
  getPdf,
  resetWorkoutError,
  createNewTag,
  getPdfRequest,
  getPdfFinish,
  getMostRecentExercises,
  toggleSecondModal,
};

export default connect(mapStateToProps, actionCreators, null, { withRef: true })(MainPanel);
