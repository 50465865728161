import React, { useMemo } from 'react';
import get from 'lodash/get';

// Store
import ColorPicker from '../ColorPicker';

// Components
import { Checkbox, Toggle } from 'shared/FormControl';
import {
  COLOR_HEART_RATE_CHART,
  COLOR_OPTIONS,
  DATA_POINT_KEYS,
  DATA_POINT_OPTIONS,
  KEYS_METRIC,
} from 'components/BodyMetricChartNew/constants';

//Assets
import { ReactComponent as LineChartIcon } from 'assets/icons/line-chart.svg';
import { ReactComponent as BarChartIcon } from 'assets/icons/range-chart.svg';

import * as S from './style';

const RANGE_KEY_METRIC = {
  title: 'Range',
  key: 'range',
};

const HeartRateSetting = props => {
  const {
    settings,
    handleClose,
    handleSelectChartType,
    handleSelectDataPointOption,
    customBrandColor,
    handleSelectColor,
    metricSettings,
    unitOptions,
    handleSelectUnit,
    defaultUnit,
    isCheckedUpdateUnit,
    isChangeUnit,
    handleChangeToggle,
    handleSelectKeyMetric,
    applySetting,
  } = props;
  const listUnit = useMemo(() => unitOptions.sort(item => (item.name === 'Minute' ? -1 : 1)), [unitOptions]);

  const keysMetric = KEYS_METRIC.map(item => {
    if (item.key === 'current') return RANGE_KEY_METRIC;
    return item;
  });

  return (
    <S.Wrapper open={true} onClose={handleClose} closeOnDimmerClick={false}>
      <S.Title>Chart Settings</S.Title>
      <S.Body>
        <S.ItemWrapper>
          <S.TitleSettingItem>Chart Type</S.TitleSettingItem>
          <S.ChartTypeSection>
            <S.ChartOption active={false} disabled>
              <LineChartIcon className="line-chart-icon" />
              Line
            </S.ChartOption>
            <S.ChartOption active right>
              <BarChartIcon className="bar-chart-icon" />
              Range
            </S.ChartOption>
          </S.ChartTypeSection>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Data Point</S.TitleSettingItem>
          <S.SectionWrapper className="data-point-wrapper">
            {DATA_POINT_OPTIONS.map((opt, index) => {
              const { value, label } = opt;
              if (opt.value === DATA_POINT_KEYS.all_values) {
                return null;
              }
              const isDisable = opt.value === DATA_POINT_KEYS.average_value;

              return (
                <S.UnitItem
                  key={value}
                  onClick={isDisable ? undefined : handleSelectDataPointOption(value)}
                  className="data-point"
                  disabled={isDisable}
                >
                  <S.UnitRadio
                    checked={settings.data_point === value}
                    className="data-point-radio"
                    disabled={isDisable}
                  />
                  {label}
                </S.UnitItem>
              );
            })}
          </S.SectionWrapper>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Chart Color</S.TitleSettingItem>
          <ColorPicker
            disabled
            color={COLOR_HEART_RATE_CHART}
            options={[customBrandColor, ...COLOR_OPTIONS]}
            onSelectColor={handleSelectColor}
            placeholder="Select color..."
          />
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Unit</S.TitleSettingItem>
          <S.SectionWrapper>
            {listUnit.map(unit => {
              const selectedUnitId =
                get(settings, 'unit._id', '') || get(metricSettings, 'unit._id', '') || get(defaultUnit, '_id', '');
              return (
                <S.UnitItem key={unit._id} onClick={handleSelectUnit(unit)}>
                  <S.UnitRadio checked={selectedUnitId === unit._id} onChange={handleSelectUnit(unit)} />
                  {unit.name} ({unit.title})
                </S.UnitItem>
              );
            })}
            {isChangeUnit && (
              <S.ToggleWrapper>
                <Toggle
                  className="unit-client-toggle"
                  checked={isCheckedUpdateUnit}
                  onChange={handleChangeToggle}
                  label="Also update units for Metrics"
                />
              </S.ToggleWrapper>
            )}
          </S.SectionWrapper>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Key Metric</S.TitleSettingItem>
          <S.SectionWrapper className="key-metric">
            {keysMetric.map(keyMetric => {
              const selectedKey = settings.key_metrics.includes(keyMetric.key);
              const checked = keyMetric.key === RANGE_KEY_METRIC.key;
              return (
                <S.KeyMetricItem key={keyMetric.key} isHeartRate checked={checked}>
                  <Checkbox
                    disabled
                    title={keyMetric.title}
                    checked={checked}
                    className="key-metric__checkbox"
                    onChange={handleSelectKeyMetric(keyMetric.key, selectedKey)}
                  />
                </S.KeyMetricItem>
              );
            })}
          </S.SectionWrapper>
        </S.ItemWrapper>
      </S.Body>
      <S.Action>
        <S.CancelButton onClick={handleClose}>Cancel</S.CancelButton>
        <S.ApplyButton onClick={handleClose}>Apply</S.ApplyButton>
      </S.Action>
    </S.Wrapper>
  );
};

export default HeartRateSetting;
