/**
 * @flow
 */

import { connect } from 'react-redux';
import type { State, Dispatch } from 'types/redux';
import ReferralProgram from './component';

const mapStateToProps = (state: State) => {
  const { rootReducer } = state;
  return {
 };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralProgram);
