import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeMode } from 'redux/progress-photo/actions';
import * as S from './style';

const Action = ({ compareMode, changeMode, handleAddPhoto }) => {
  return (
    <S.Action>
      <S.CompareMode>
        <S.ToggleWrapper checked={compareMode} onChange={changeMode} />
        Compare mode
      </S.CompareMode>
      <S.ButtonWrapper show={compareMode} purple onClick={() => handleAddPhoto(false)}>
        Add new
      </S.ButtonWrapper>
    </S.Action>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      progressPhoto: { compareMode },
    },
  } = state;
  return { compareMode };
};

const mapDispatchToProps = dispatch => ({
  changeMode: bindActionCreators(changeMode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Action);
