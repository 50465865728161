import { diff } from 'deep-diff';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { debounce, get } from 'lodash';

import { CDN_URL } from 'constants/commonData';

import ProductItem from './ProductItem';
import { Button } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import GeneralButton from 'shared/GeneralButton';
import { ReactComponent as FirstCreateNewIconSrc } from 'assets/icons/first_create_new_product.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/white_plus.svg';

import * as S from './style';

const ProductList = ({
  onClose,
  onClickCreateNew,
  getPublishedProducts,
  counter,
  onSave,
  product,
  is_allow_multiple_products,
}) => {
  const [selected, setSelected] = useState(product);
  const [list, setList] = useState([]);
  const disabledSelectProduct = is_allow_multiple_products ? selected.length === 2 : selected.length === 1;

  const productSorted = useMemo(() => {
    const selectedProduct = list.filter(it => selected.find(item => item.id === it.id));
    const otherProduct = list.filter(it => selected.find(item => item.id !== it.id));
    return selectedProduct.length ? otherProduct : list;
  }, [list]);

  useEffect(() => {
    handelGetProductList({
      pageSize: counter,
    });
  }, []);

  const handelGetProductList = param => {
    getPublishedProducts(param).then(res => {
      if (res) {
        const data = get(res, 'data.data.data');
        setList(data);
      }
    });
  };

  const onKeyPress = event => {
    event.persist();
  };

  const onSearch = (event, { value }) => {
    const trimmed = value.toLowerCase().trim();
    handelGetProductList({ q: trimmed, pageSize: counter });
  };

  const searchDebounce = debounce(onSearch, 300);

  const handleCreatePackage = () => {
    onClickCreateNew && onClickCreateNew();
  };

  const handleSelectedProduct = (item, checked) => {
    let selectedProduct = [];
    if (checked) {
      selectedProduct = [...selected, item];
    } else {
      selectedProduct = selected.filter(it => it.id !== item.id);
    }
    setSelected(selectedProduct);
  };

  const isEmpty = useMemo(() => {
    return list.length < 1 || !diff(selected, product);
  }, [list, selected]);

  const handleSaveButton = () => {
    onSave && onSave(selected);
  };

  return (
    <S.ProductListWrapper>
      <S.ModalWrapper
        open={true}
        closeOnDimmerClick={false}
        onClose={onClose}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
      >
        <Modal.Content>
          <S.ModalHeader>
            <S.ModalTitle>Choose a Sequence</S.ModalTitle>
            <S.SearchWrapper>
              <NewSearchInput
                onChange={searchDebounce}
                onClearSearch={() => onSearch(null, { value: '' })}
                placeholder="Search Sequence name"
                onKeyPress={onKeyPress}
                className="search-products"
              />
            </S.SearchWrapper>
          </S.ModalHeader>
          <S.ListWrapper>
            <S.ListTitleWrapper>
              <S.ListTitle>All Published Sequences ({counter || 0})</S.ListTitle>
              {is_allow_multiple_products ? <S.ListSubTitle>Add up to 2 sequences</S.ListSubTitle> : null}
            </S.ListTitleWrapper>
            {!counter ? (
              <S.Empty>
                <S.EmptyBanner>
                  <FirstCreateNewIconSrc />
                </S.EmptyBanner>
                <S.EmptyDesc>
                  Add to your Package a Sequence that automatically onboards and rolls out training assignments,
                  resources, and community forums to your clients.
                </S.EmptyDesc>
                <S.AddNewWrapper>
                  <Button onClick={handleCreatePackage}>
                    <span>Create a sequence</span>
                  </Button>
                </S.AddNewWrapper>
              </S.Empty>
            ) : (
              <S.List>
                {productSorted.length < 1 && <S.NotFound>No results found</S.NotFound>}
                {productSorted.length > 0 &&
                  productSorted.map(it => (
                    <ProductItem
                      onSelect={handleSelectedProduct}
                      selected={selected.some(item => item.id === it.id)}
                      item={it}
                      disabled={disabledSelectProduct && selected.some(item => item.id !== it.id)}
                    />
                  ))}
              </S.List>
            )}
          </S.ListWrapper>
        </Modal.Content>
        <Modal.Actions>
          {productSorted.length ? (
            <GeneralButton onClick={handleCreatePackage} className="create-button" withImage>
              <S.AddNewIcon>
                <PlusIcon />
              </S.AddNewIcon>
              <span className="create-label">Create a sequence</span>
            </GeneralButton>
          ) : (
            <div />
          )}
          <div>
            <Button onClick={onClose}>Cancel</Button>
            <Button purple disabled={isEmpty} onClick={handleSaveButton}>
              Add
            </Button>
          </div>
        </Modal.Actions>
      </S.ModalWrapper>
    </S.ProductListWrapper>
  );
};

export default ProductList;
