// Libs
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

// Actions
import {
  getDetailRecipe,
  editRecipe,
  updateDataDetailRecipe,
  resetDetailRecipe,
  resetBackgroundLibrary,
  addRecipeLibraries,
  resetEditMode,
  enableEditModeRecipe,
  cancelEditModeRecipe,
  setUploadLoading,
  handleSubmitted,
  updateUnitType,
  getListOtherNutrient,
  getListDietary,
  updateLoadingVideoInstruction,
  changeAutoCalculated,
  updateIngredientData,
  updateAutoCalculatedNutrition,
} from 'redux/recipes/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

// Components
import Component from './component';

const mapStateToProps = state => {
  const {
    rootReducer: { recipes, generalSettings, ingredientLibrary },
    user,
    cloudfrontList,
  } = state;

  const isEditMode = get(recipes, 'isEditMode', false);
  const detailRecipe = get(recipes, 'detailRecipe', {});
  const isChangeRecipeDefaultImage = get(generalSettings, 'is_change_recipe_default_image', '');
  const isLoading = get(recipes, 'isLoading', false);
  const listOtherNutrients = get(recipes, 'otherNutrient', []);
  const listDietary = get(recipes, 'listDietary', []);
  const loadingListDietary = get(recipes, 'loadingListDietary', []);
  const listUnitIngredient = get(ingredientLibrary, 'listUnitIngredient', {});

  return {
    isEditMode,
    detailRecipe,
    isChangeRecipeDefaultImage,
    isLoading,
    currentUser: user,
    listOtherNutrients,
    listDietary,
    cloudfrontList,
    loadingListDietary,
    listUnitIngredient,
  };
};

const mapDispatchToProps = dispatch => ({
  getDetailRecipe: bindActionCreators(getDetailRecipe, dispatch),
  editRecipe: bindActionCreators(editRecipe, dispatch),
  updateDataDetailRecipe: bindActionCreators(updateDataDetailRecipe, dispatch),
  resetDetailRecipe: bindActionCreators(resetDetailRecipe, dispatch),
  resetBackgroundLibrary: bindActionCreators(resetBackgroundLibrary, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addRecipeLibraries: bindActionCreators(addRecipeLibraries, dispatch),
  resetEditMode: bindActionCreators(resetEditMode, dispatch),
  enableEditModeRecipe: bindActionCreators(enableEditModeRecipe, dispatch),
  cancelEditModeRecipe: bindActionCreators(cancelEditModeRecipe, dispatch),
  push: bindActionCreators(push, dispatch),
  setUploadLoading: bindActionCreators(setUploadLoading, dispatch),
  handleSubmitted: bindActionCreators(handleSubmitted, dispatch),
  updateUnitType: bindActionCreators(updateUnitType, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getListOtherNutrient: bindActionCreators(getListOtherNutrient, dispatch),
  getListDietary: bindActionCreators(getListDietary, dispatch),
  updateLoadingVideoInstruction: bindActionCreators(updateLoadingVideoInstruction, dispatch),
  changeAutoCalculated: bindActionCreators(changeAutoCalculated, dispatch),
  updateIngredientData: bindActionCreators(updateIngredientData, dispatch),
  updateAutoCalculatedNutrition: bindActionCreators(updateAutoCalculatedNutrition, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
