import React, { useEffect, useState } from 'react';
import { ReactQuillWrapper } from './styles';
import { Quill } from 'react-quill';
const icons = Quill.import('ui/icons');

const modules = {
  toolbar: [
    [{ align: '' }, { align: 'center' }],
    ['bold', 'italic'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'align',
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link', ignored
];
const loadCustomIcons = () => {
  icons['align'][''] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path d="M13 2.5H1V3.5H13V2.5Z" fill="currentColor"/>
      <path d="M8 5.5H1V6.5H8V5.5Z" fill="currentColor"/>
      <path d="M13 8.5H1V9.5H13V8.5Z" fill="currentColor"/>
      <path d="M8 11.5H1V12.5H8V11.5Z" fill="currentColor"/>
      </svg>`;

  icons['align'][
    'center'
  ] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path d="M13 2.5H1V3.5H13V2.5Z" fill="currentColor"/>
      <path d="M11 5.5H3V6.5H11V5.5Z" fill="currentColor"/>
      <path d="M13 8.5H1V9.5H13V8.5Z" fill="currentColor"/>
      <path d="M11 11.5H3V12.5H11V11.5Z" fill="currentColor"/>
      </svg>`;

  icons['list'][
    'ordered'
  ] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path d="M14 2.5H6V3.5H14V2.5Z" fill="currentColor"/>
      <path d="M14 6.5H6V7.5H14V6.5Z" fill="currentColor"/>
      <path d="M2.30103 6.14014H1.32031V3.45508L1.32983 3.01392L1.3457 2.53149C1.18278 2.69442 1.06958 2.80127 1.0061 2.85205L0.4729 3.28052L0 2.69019L1.49487 1.5H2.30103V6.14014Z" fill="currentColor"/>
      <path d="M3.25635 12.2068H0.0126953V11.5244L1.17749 10.3469C1.52238 9.99357 1.74772 9.74919 1.85352 9.61377C1.95931 9.47624 2.03548 9.34928 2.08203 9.23291C2.12858 9.11654 2.15186 8.99593 2.15186 8.87109C2.15186 8.6849 2.10002 8.54631 1.99634 8.45532C1.89478 8.36434 1.7583 8.31885 1.58691 8.31885C1.40706 8.31885 1.2325 8.36011 1.06323 8.44263C0.893962 8.52515 0.717285 8.64258 0.533203 8.79492L0 8.16333C0.228516 7.96867 0.417887 7.83114 0.568115 7.75073C0.718343 7.67033 0.882324 7.60897 1.06006 7.56665C1.23779 7.52222 1.43669 7.5 1.65674 7.5C1.94661 7.5 2.20264 7.5529 2.4248 7.65869C2.64697 7.76449 2.81942 7.9126 2.94214 8.10303C3.06486 8.29346 3.12622 8.51139 3.12622 8.75684C3.12622 8.97054 3.08813 9.17155 3.01196 9.35986C2.93791 9.54606 2.82153 9.73755 2.66284 9.93433C2.50627 10.1311 2.22909 10.4115 1.8313 10.7754L1.23462 11.3372V11.3816H3.25635V12.2068Z" fill="currentColor"/>
      <path d="M14 10.5H6V11.5H14V10.5Z" fill="currentColor"/>
      </svg>`;

  icons['list'][
    'bullet'
  ] = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path d="M2.5 4.5C3.32843 4.5 4 3.82843 4 3C4 2.17157 3.32843 1.5 2.5 1.5C1.67157 1.5 1 2.17157 1 3C1 3.82843 1.67157 4.5 2.5 4.5Z" fill="currentColor"/>
        <path d="M13 2.5H6V3.5H13V2.5Z" fill="currentColor"/>
        <path d="M2.5 8.5C3.32843 8.5 4 7.82843 4 7C4 6.17157 3.32843 5.5 2.5 5.5C1.67157 5.5 1 6.17157 1 7C1 7.82843 1.67157 8.5 2.5 8.5Z" fill="currentColor"/>
        <path d="M13 6.5H6V7.5H13V6.5Z" fill="currentColor"/>
        <path d="M2.5 12.5C3.32843 12.5 4 11.8284 4 11C4 10.1716 3.32843 9.5 2.5 9.5C1.67157 9.5 1 10.1716 1 11C1 11.8284 1.67157 12.5 2.5 12.5Z" fill="currentColor"/>
        <path d="M13 10.5H6V11.5H13V10.5Z" fill="currentColor"/>
      </svg>`;
  return;
};

const resetIcons = () => {
  icons['align'][
    ''
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=3 x2=15 y1=9 y2=9></line> <line class=ql-stroke x1=3 x2=13 y1=14 y2=14></line> <line class=ql-stroke x1=3 x2=9 y1=4 y2=4></line> </svg>`;
  icons['align'][
    'center'
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=15 x2=3 y1=9 y2=9></line> <line class=ql-stroke x1=15 x2=3 y1=14 y2=14></line> <line class=ql-stroke x1=15 x2=3 y1=4 y2=4></line> </svg>`;
  icons['list'][
    'ordered'
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=7 x2=15 y1=4 y2=4></line> <line class=ql-stroke x1=7 x2=15 y1=9 y2=9></line> <line class=ql-stroke x1=7 x2=15 y1=14 y2=14></line> <line class="ql-stroke ql-thin" x1=2.5 x2=4.5 y1=5.5 y2=5.5></line> <path class=ql-fill d=M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z></path> <path class="ql-stroke ql-thin" d=M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156></path> <path class="ql-stroke ql-thin" d=M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109></path> </svg>`;
  icons['list'][
    'bullet'
  ] = `<svg viewbox="0 0 18 18"> <line class=ql-stroke x1=6 x2=15 y1=4 y2=4></line> <line class=ql-stroke x1=6 x2=15 y1=9 y2=9></line> <line class=ql-stroke x1=6 x2=15 y1=14 y2=14></line> <line class=ql-stroke x1=3 x2=3 y1=4 y2=4></line> <line class=ql-stroke x1=3 x2=3 y1=9 y2=9></line> <line class=ql-stroke x1=3 x2=3 y1=14 y2=14></line> </svg>`;
  return;
};

const Composer = ({ initContent = '', onChange }) => {
  const [content, setContent] = useState(initContent);

  loadCustomIcons();
  useEffect(() => {
    return () => {
      resetIcons();
    };
  }, []);

  const handleOnChangeInput = content => {
    setContent(content);
    onChange && onChange(content);
  };

  return (
    <ReactQuillWrapper
      theme="snow"
      onChange={handleOnChangeInput}
      value={content || ''}
      modules={modules}
      formats={formats}
      placeholder="Add your message"
    />
  );
};

export default Composer;
