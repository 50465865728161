import React from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin } from '@react-oauth/google';

import { ReactComponent as GoogleIcon } from 'assets/icons/google-icon.svg';

import * as S from './style';

/**
 * A button component that allows users to authenticate with Google.
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the button.
 * @param {string} props.type - The type of button.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {Function} props.onHandleSuccess - A function that is called when the user successfully signs in.
 */
const ButtonGoogleAuth = props => {
  const { title, type, disabled, onHandleSuccess } = props;

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: tokenResponse => onHandleSuccess(tokenResponse),
  });

  return (
    <S.Button
      onClick={e => {
        e.preventDefault();
        handleLoginGoogle();
      }}
      type={type || 'button'}
      disabled={disabled}
      isDisabled={disabled}
    >
      <S.ButtonIcon>
        <GoogleIcon />
      </S.ButtonIcon>
      <S.ButtonTitle>{title} with Google</S.ButtonTitle>
    </S.Button>
  );
};

ButtonGoogleAuth.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onHandleSuccess: PropTypes.func,
};

export default ButtonGoogleAuth;
