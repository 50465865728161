import styled, { css } from 'styled-components';
import Button from 'shared/FormControl/Button';
import { CDN_URL } from 'constants/commonData';
import CloseCircle from 'assets/icons/close_circle_grey.svg';

export const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 25px;
`;

export const Preview = styled.div`
  width: 96px;
  height: 96px;
  position: relative;
  border-radius: 5px !important;

  .media__play-button {
    border-radius: 5px;
  }

  ${props =>
    props.thumbnail &&
    css`
      background: url(${props => props.thumbnail}) no-repeat center;
      background-size: cover;
    `}

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px !important;
  }
`;

export const PreviewContainer = styled.div`
  width: 100%;
`;

export const VideoContainer = styled.div``;

export const UploadPhotoArea = styled.div`
  padding: 16px 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
  padding-left: 76px;
  background: #fff url(${CDN_URL}/images/drop_image.svg) no-repeat 30px center;
  background-size: 34px auto;
  min-height: 70px;
  border: 1px dashed #dadfea;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;

  .choose-file {
    color: #5158cf;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const PhotoUploadTrigger = styled.div`
  width: 100%;
  height: 100%;
  background: #fff url(${CDN_URL}/images/image_upload_bg.svg) no-repeat center;
  background-size: auto;
  border-radius: 3px;
  border: 1px dashed #dadfea;
  cursor: pointer;
`;

export const RemoveIcon = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #f6f7fb url(${CloseCircle}) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: -7px;
  top: -6px;
  display: none;
`;

export const PhotoItem = styled.div`
  width: 96px;
  height: 96px;
  flex: 0 0 96px;
  position: relative;
  border-radius: 5px;

  ${props =>
    props.isLoadingGiphy &&
    css`
      background-color: #f6f6f6;
    `}

  .thumbnail-item {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover;

    img {
      object-fit: cover;
    }
  }

  .select-file__container {
    display: block;
    width: 100%;
    height: 100%;

    .select-file__trigger {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .file-upload__cancel-button {
    width: 18px;
    height: 18px;
    background-size: 8px auto;
  }

  &:hover {
    .remove-icon {
      display: block;
    }
  }

  .s3-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    border-radius: inherit;
  }
`;

export const PhotoItemContainer = styled.div`
  /* order: ${props => props.order}; */
  margin-right: 10px;

  :last-child {
    margin-right: 0;
  }
`;

export const ListPhoto = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-items: center;
`;

export const SectionTitle = styled.h3`
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #777c8f;
`;

export const Section = styled.div``;

export const Content = styled.div``;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;
`;

export const AddAssetButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
  height: 38px;
  width: 152px;
  left: 0px;
  top: 0px;
  border-radius: 10000px;
  padding: 10px, 20px, 10px, 20px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 0px #0000001a !important;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  color: #696974;

  &:hover {
    border: 1px solid #5158cf;
    background: #f6f5ff;
    box-shadow: 0px 2px 2px #0000001a !important;
    color: #5158cf;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
    background-color: #f9f9f9;
    box-shadow: none !important;
    color: #b0b0be;
    border: none !important;

    svg {
      path {
        fill-opacity: 0.4;
        stroke: none;
      }
    }
  }
`;
