import Request from 'configs/request';
import isObject from 'lodash/isObject';

export const Types = {
  USER_GET_GENERAL_SETTINGS_REQUEST: 'USER_GET_GENERAL_SETTINGS_REQUEST',
  USER_GET_GENERAL_SETTINGS_SUCCESS: 'USER_GET_GENERAL_SETTINGS_SUCCESS',
  USER_HIDE_WORKOUT_COLLECTION_REQUEST: 'USER_HIDE_WORKOUT_COLLECTION_REQUEST',
  USER_HIDE_WORKOUT_COLLECTION_SUCCESS: 'USER_HIDE_WORKOUT_COLLECTION_SUCCESS',
  USER_HIDE_FORM_BANNER_REQUEST: 'USER_HIDE_FORM_BANNER_REQUEST',
  USER_HIDE_FORM_BANNER_SUCCESS: 'USER_HIDE_FORM_BANNER_SUCCESS',
  USER_HIDE_TEXT_DEFAULT_IMAGE: 'USER_HIDE_TEXT_DEFAULT_IMAGE',
  USER_HIDE_BANNER_STARTING: 'USER_HIDE_BANNER_STARTING',
  USER_HIDE_BANNER_ENDING: 'USER_HIDE_BANNER_ENDING',
  USER_HIDE_MEAL_PLAN_BANNER_SUCCESS: 'USER_HIDE_MEAL_PLAN_BANNER_SUCCESS',
  USER_HIDE_PROGRAM_LIBRARY_BANNER_SUCCESS: 'USER_HIDE_PROGRAM_LIBRARY_BANNER_SUCCESS',
  USER_HIDE_UPGRADE_PATH_ONBOARDING_SUCCESS: 'USER_HIDE_UPGRADE_PATH_ONBOARDING_SUCCESS',
  USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_REQUEST: 'USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_REQUEST',
  USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_SUCCESS: 'USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_SUCCESS',
  USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_FAILED: 'USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_FAILED',
  USER_HIDE_ONBOARDING_FLOW_TRIGGER_FORM_NOTE_SUCCESS: 'USER_HIDE_ONBOARDING_FLOW_TRIGGER_FORM_NOTE_SUCCESS',
  USER_UPDATE_FLAG_BETA_AGREEMENT_SUCCESS: 'USER_UPDATE_FLAG_BETA_AGREEMENT_SUCCESS',
  USER_DONE_AI_WOB_ONBOARDING_TOUR_SUCCESS: 'USER_DONE_AI_WOB_ONBOARDING_TOUR_SUCCESS',
};

export const getUserGeneralSettings = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_GET_GENERAL_SETTINGS_REQUEST,
    });
    return dispatch(
      Request.get({ url: '/api/profile/general-setting' }, false, (response, { dispatch }) => {
        const { data } = response.data;
        if (data) {
          dispatch({
            type: Types.USER_GET_GENERAL_SETTINGS_SUCCESS,
            payload: { data: data },
          });
        }
      }),
    );
  };
};

export const hideWorkoutCollectionBanner = () => {
  return dispatch => {
    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: {
            is_passed_workout_collection_banner_onboarding: true,
          },
        },
        true,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.USER_HIDE_WORKOUT_COLLECTION_SUCCESS,
            });
          }
        },
      ),
    );
  };
};

export const hideFormBanner = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_HIDE_FORM_BANNER_REQUEST,
    });

    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: {
            is_hide_form_banner: true,
          },
        },
        true,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.USER_HIDE_FORM_BANNER_SUCCESS,
            });
          }
        },
      ),
    );
  };
};

export const hideTextDefaultImage = () => {
  return dispatch => {
    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: {
            is_change_recipe_default_image: true,
          },
        },
        true,
        response => {
          const { data } = response.data;
          if (isObject(data)) {
            dispatch({
              type: Types.USER_HIDE_TEXT_DEFAULT_IMAGE,
            });
          }
        },
      ),
    );
  };
};

export const hideMealPlanBanner = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_HIDE_BANNER_STARTING,
    });

    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: {
            is_hide_meal_plan_banner: true,
          },
        },
        true,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.USER_HIDE_MEAL_PLAN_BANNER_SUCCESS,
            });
          }
        },
        error => {
          dispatch({ type: Types.USER_HIDE_BANNER_ENDING });
        },
      ),
    );
  };
};

export const hideProgramLibraryBanner = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_HIDE_BANNER_STARTING,
    });

    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: {
            is_hide_program_library_banner: true,
          },
        },
        true,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.USER_HIDE_PROGRAM_LIBRARY_BANNER_SUCCESS,
            });
          }
        },
        error => {
          dispatch({ type: Types.USER_HIDE_BANNER_ENDING });
        },
      ),
    );
  };
};

export const hideUpgradePathOnboarding = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_HIDE_BANNER_STARTING,
    });

    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: { is_show_upgrade_path_onboarding_flow: true },
        },
        false,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({ type: Types.USER_HIDE_UPGRADE_PATH_ONBOARDING_SUCCESS });
          }
        },
        error => {
          dispatch({ type: Types.USER_HIDE_BANNER_ENDING });
        },
      ),
    );
  };
};

export const updateStatusMealPlanFormat = data => {
  return dispatch => {
    dispatch({
      type: Types.USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_REQUEST,
    });
    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: data,
        },
        true,
        response => {
          const { data: resData } = response.data;
          if (isObject(resData)) {
            dispatch({
              type: Types.USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_SUCCESS,
              payload: { data },
            });
          }
        },
        () => {
          dispatch({ type: Types.USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_FAILED });
        },
      ),
    );
  };
};

export const hideOnboardingTriggerBanner = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_HIDE_BANNER_STARTING,
    });

    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',

          data: {
            is_hide_onboarding_flow_trigger_form_note: true,
          },
        },
        true,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.USER_HIDE_ONBOARDING_FLOW_TRIGGER_FORM_NOTE_SUCCESS,
            });
          }
        },
        error => {
          dispatch({ type: Types.USER_HIDE_BANNER_ENDING });
        },
      ),
    );
  };
};
export const updateFlagWobOnboardingTour = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_HIDE_BANNER_STARTING,
    });
    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: {
            is_done_ai_wob_onboarding_tour: true,
          },
        },
        false,
        response => {
          const { data } = response.data;
          const { is_done_ai_wob_onboarding_tour } = data || {};
          if (is_done_ai_wob_onboarding_tour) {
            dispatch({
              type: Types.USER_DONE_AI_WOB_ONBOARDING_TOUR_SUCCESS,
              payload: { isDoneAIOnboardingTour: is_done_ai_wob_onboarding_tour },
            });
          }
        },
        error => {
          dispatch({ type: Types.USER_HIDE_BANNER_ENDING });
        },
      ),
    );
  };
};

export const updateFlagBetaAgreement = () => {
  return dispatch => {
    dispatch({
      type: Types.USER_HIDE_BANNER_STARTING,
    });
    return dispatch(
      Request.patch(
        {
          url: '/api/profile/general-setting',
          data: {
            is_agreed_ai_wob_beta_agreement: true,
          },
        },
        false,
        response => {
          const { data } = response.data;
          const { is_agreed_ai_wob_beta_agreement } = data || {};
          if (is_agreed_ai_wob_beta_agreement) {
            dispatch({
              type: Types.USER_UPDATE_FLAG_BETA_AGREEMENT_SUCCESS,
              payload: { dataBetaAgreement: data },
            });
          }
        },
        error => {
          dispatch({ type: Types.USER_HIDE_BANNER_ENDING });
        },
      ),
    );
  };
};
