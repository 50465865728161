import React, { useCallback, useEffect, useRef, useState } from 'react';

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import SurveyItem from './SurveyItem';
import SearchInput from 'shared/SearchInput';
import SurveyDetailModal from '../SurveyDetailModal';
import * as S from './SurveyModal.style';
import { ReactComponent as PlusIcon } from 'assets/icons/white_plus.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/autoflow-search.svg';
import { ReactComponent as EmptySurveyIcon } from 'assets/icons/empty_survey.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/popup-warning-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';

import {
  archiveSurvey,
  deleteSurvey,
  getSurveyList,
  getSurveyStatistic,
  resetSurveyList,
  unarchiveSurvey,
} from 'redux/survey-contact/actions';
import { updatePackageSurveyFlag } from 'redux/package-detail/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

import Tabs from './Tabs';
import LoadingIndicator from 'shared/LoadingIndicator';
import SurveyPreviewDetailModal from '../SurveyPreviewDetailModal';
import { CDN_URL } from 'constants/commonData';

export const SURVEY_ACTIONS = {
  ARCHIVE: 'archive',
  EDIT: 'edit',
  PREVIEW: 'preview',
  DUPLICATE: 'duplicate',
  UNARCHIVE: 'unarchive',
  DELETE: 'delete',
};

const TAB_KEYS = {
  MY_SURVEY: 'mySurvey',
  ACTIVE: 'active',
  ARCHIVE: 'archive',
};

const initFilter = {
  page: 1,
  pageSize: 20,
  q: '',
  sort: 'last_activity',
  order: 'DESC',
  active: true,
  is_my_survey: true,
};

const TABS = [
  {
    label: 'Your Surveys',
    value: TAB_KEYS.MY_SURVEY,
  },
  {
    label: 'All surveys',
    value: TAB_KEYS.ACTIVE,
  },
  {
    label: 'Archived',
    value: TAB_KEYS.ARCHIVE,
  },
];

const SurveyModal = ({
  getSurveyList,
  surveyContactList,
  getSurveyStatistic,
  resetSurveyList,
  toggleModal,
  surveyIsLoading,
  onClose,
  statistic,
  toggleConfirmModal,
  archiveSurvey,
  unarchiveSurvey,
  deleteSurvey,
  workingPackage,
  updatePackageSurveyFlag,
  user,
  permission,
  onChangeSurvey,
}) => {
  const packageData = workingPackage ? workingPackage.toJS() : null;

  const listRef = useRef(null);
  const searchRef = useRef(null);

  const surveyList = get(surveyContactList, 'data', []);
  const totalSurvey = get(surveyContactList, 'total', 0);
  const [isMounted, setIsMounted] = useState(false);
  const [isShowSearchField, setIsShowSearchField] = useState(false);
  const [filters, setFilters] = useState(initFilter);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    getSurveyStatistic();
    return () => {
      resetSurveyList();
    };
  }, []);

  useEffect(() => {
    debounceRequest(filters);
  }, [filters]);

  const request = debounce(filterParams => {
    const newParams = {
      ...filterParams,
      ...(get(filterParams, 'is_my_survey') && {
        active: true,
      }),
    };
    getSurveyList(newParams).then(() => {
      setIsSearching(false);
      if (!isMounted) {
        setIsMounted(true);
      }
    });
  }, 300);

  const debounceRequest = useCallback(filterParams => {
    request(filterParams);
  }, []);

  const handleCancel = () => {
    toggleModal(false);
    onClose();
  };

  const handleCreateNew = () => {
    toggleModal(true, <SurveyDetailModal isCreateNew onClose={onClose} onChangeSurvey={onChangeSurvey} />);
  };

  const handleSearch = (_, { value }) => {
    setIsSearching(true);
    const newFilter = {
      ...initFilter,
      q: value,
      active: filters.active,
      is_my_survey: filters.is_my_survey,
    };
    setFilters(newFilter);
  };

  const handleBlurSearchField = () => {
    const q = get(filters, 'q', '');
    if (!q.trim()) {
      setIsShowSearchField(false);
    }
  };

  const handleChangeTab = newTab => {
    if (surveyIsLoading) return;

    const isMySurvey = newTab === TAB_KEYS.MY_SURVEY;
    const isActive = newTab === TAB_KEYS.ACTIVE;
    const newFilter = {
      ...initFilter,
      page: 1,
      q: filters.q,
      active: isActive,
      is_my_survey: isMySurvey,
    };

    setFilters(newFilter);
    resetSurveyList();
  };

  const archiveFailed = () => {
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="error"
        noIcon
        newStyle
        hasCloseIcon
        hideCancelButton
        isPressEsc
        confirmButtonTitle="OK"
        title={
          <>
            <WarningIcon />
            <span>Cannot be archived</span>
          </>
        }
        content="This survey is applied to a package and cannot be archived."
        onClose={() => getSurveyList(initFilter)}
        onConfirm={() => getSurveyList(initFilter)}
      />,
    );
  };

  const archiveSuccessful = surveyId => {
    const packagePurchaseOption = get(packageData, 'package_purchase_option');
    const currentSurveyId = get(packagePurchaseOption, 'survey_id');

    if (currentSurveyId !== surveyId) return;

    const package_purchase_option = {
      ...packagePurchaseOption,
      survey_id: null,
      survey: null,
      author_name: '',
    };
    updatePackageSurveyFlag({ has_survey_list: true, package_purchase_option });
  };

  const handleArchiveSurvey = survey => {
    const surveyName = get(survey, 'survey_name', '');
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        noIcon
        newStyle
        hasCloseIcon
        isPressEsc
        confirmButtonTitle="Archive"
        title={
          <>
            <WarningIcon />
            <span>Archive survey</span>
          </>
        }
        content={
          <>
            Are you sure you want to archive <b className="survey-name">{surveyName}</b>?
          </>
        }
        onConfirm={() => archiveSurvey({ id: survey.id, filters }, archiveSuccessful, archiveFailed)}
      />,
    );
  };

  const handleUnarchiveSurvey = survey => {
    const surveyName = get(survey, 'survey_name', '');
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        noIcon
        newStyle
        hasCloseIcon
        isPressEsc
        confirmButtonTitle="Unarchive"
        title={
          <>
            <WarningIcon />
            <span>Unarchive survey</span>
          </>
        }
        content={
          <>
            Are you sure you want to unarchive <b className="survey-name">{surveyName}</b>?
          </>
        }
        onConfirm={() => unarchiveSurvey({ id: survey.id, filters })}
      />,
    );
  };

  const handleDeleteSurvey = survey => {
    const surveyName = get(survey, 'survey_name', '');
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="error"
        noIcon
        newStyle
        hasCloseIcon
        isPressEsc
        confirmButtonTitle="Delete"
        title={
          <>
            <WarningIcon />
            <span>Delete survey</span>
          </>
        }
        content={
          <>
            Are you sure you want to permanently delete <b className="survey-name">{surveyName}</b>?
          </>
        }
        onConfirm={() => deleteSurvey({ id: survey.id, filters })}
      />,
    );
  };

  const handleDuplicateSurvey = survey => {
    const resolveSurvey = {
      ...survey,
      survey_name: `Copy of ${get(survey, 'survey_name', '')}`,
    };
    toggleModal(
      true,
      <SurveyDetailModal
        isCreateNew
        isDuplicate
        choosingSurvey={resolveSurvey}
        onClose={onClose}
        onChangeSurvey={onChangeSurvey}
      />,
    );
  };

  const handleSurveyAction = (action, survey) => {
    switch (action) {
      case SURVEY_ACTIONS.EDIT:
        toggleModal(
          true,
          <SurveyDetailModal choosingSurvey={survey} onClose={onClose} onChangeSurvey={onChangeSurvey} />,
        );
        break;
      case SURVEY_ACTIONS.DUPLICATE:
        handleDuplicateSurvey(survey);
        break;
      case SURVEY_ACTIONS.PREVIEW:
        toggleModal(
          true,
          <SurveyPreviewDetailModal
            isPreview
            surveyId={get(survey, 'id', '')}
            onClose={onClose}
            onChangeSurvey={onChangeSurvey}
          />,
        );
        break;
      case SURVEY_ACTIONS.ARCHIVE:
        handleArchiveSurvey(survey);
        break;
      case SURVEY_ACTIONS.UNARCHIVE:
        handleUnarchiveSurvey(survey);
        break;
      case SURVEY_ACTIONS.DELETE:
        handleDeleteSurvey(survey);
        break;
      default:
        break;
    }
  };

  const handleActiveTab = () => {
    if (get(filters, 'is_my_survey')) return TAB_KEYS.MY_SURVEY;
    if (get(filters, 'active')) return TAB_KEYS.ACTIVE;
    return TAB_KEYS.ARCHIVE;
  };

  const onScroll = () => {
    const listSurvey = listRef && listRef.current;
    if (listSurvey && get(surveyList, 'length', 0) < totalSurvey && !surveyIsLoading) {
      const isAtBottom = listSurvey.scrollTop + listSurvey.clientHeight >= listSurvey.scrollHeight - 25;

      if (isAtBottom) {
        const newFilter = {
          ...filters,
          page: filters.page + 1,
        };
        setFilters(newFilter);
      }
    }
  };

  const renderList = () => {
    const isActive = get(filters, 'active', false);
    if (!surveyIsLoading && !surveyList.length && isMounted) {
      let emptyMessage = 'No surveys found.';
      if (!get(filters, 'q', '')) {
        emptyMessage = isActive ? 'No surveys available.' : 'No archived surveys.';
      }
      return (
        <S.EmptySurveyWrapper>
          <EmptySurveyIcon />
          <p className="empty__message">{emptyMessage}</p>
        </S.EmptySurveyWrapper>
      );
    }
    return (
      <div className="wrapper">
        {!isSearching &&
          surveyList.map(item => (
            <SurveyItem
              key={`survey-${item.id}`}
              survey={item}
              onAction={handleSurveyAction}
              permission={permission}
              user={user}
            />
          ))}
        {(isSearching || surveyIsLoading) && <LoadingIndicator />}
      </div>
    );
  };

  return (
    <S.Wrapper
      open={true}
      onClose={handleCancel}
      closeOnDimmerClick={false}
      isShowSearchField={isShowSearchField}
      isPressEsc
      closeIcon={
        <S.ModalButton className="close-button" padding="0px" onClick={handleCancel}>
          <img alt="close-button" src={`${CDN_URL}/images/close_circle.svg`} />
        </S.ModalButton>
      }
    >
      <S.Header isShowSearchField={isShowSearchField}>
        <S.SurveyName>
          Manage Surveys
          <InfoIcon width={16} height={16} data-tip data-for="survey-name" />
          <ReactTooltip
            id="survey-name"
            className="survey-name-tooltip"
            effect="solid"
            place="bottom"
            offset={{ bottom: 4 }}
          >
            Surveys will be visible to all coaches in this workspace.
          </ReactTooltip>
        </S.SurveyName>
        <div className="survey__search-field">
          {isShowSearchField ? (
            <SearchInput
              autoFocus
              ref={searchRef}
              value={get(filters, 'q', '')}
              onChange={handleSearch}
              placeholder="Search by name"
              onBlur={handleBlurSearchField}
              onAddOnIconClick={() => {
                setFilters({ ...filters, q: '', page: 1 });
                setIsShowSearchField(false);
              }}
            />
          ) : (
            <S.GhostPrimaryButton minWidth="42px" height="42px" onClick={() => setIsShowSearchField(true)}>
              <SearchIcon />
            </S.GhostPrimaryButton>
          )}

          <S.PrimaryButton width="123px" padding="11px 12px" height="42px" onClick={handleCreateNew}>
            <PlusIcon />
            Create new
          </S.PrimaryButton>
        </div>
      </S.Header>
      <Tabs tabs={TABS} activeTab={handleActiveTab()} total={statistic} onChange={handleChangeTab} margin="0 24px" />
      <S.Content>
        <div className="survey__list" ref={listRef} onScroll={debounce(onScroll, 300)}>
          {renderList()}
        </div>
      </S.Content>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { surveyContact, permission },
    user,
  } = state;

  return {
    surveyContactList: get(surveyContact, 'surveyList', { total: 0, data: [] }),
    surveyIsLoading: get(surveyContact, 'loading', true),
    statistic: get(surveyContact, 'statistic', { active: 0, all: 0, archive: 0, mySurvey: 0 }),
    workingPackage: get(state, 'rootReducer.packageDetail', {}).get('workingPackage'),
    user,
    permission,
  };
};

const mapDispatchToProps = dispatch => ({
  getSurveyStatistic: bindActionCreators(getSurveyStatistic, dispatch),
  getSurveyList: bindActionCreators(getSurveyList, dispatch),
  resetSurveyList: bindActionCreators(resetSurveyList, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  archiveSurvey: bindActionCreators(archiveSurvey, dispatch),
  unarchiveSurvey: bindActionCreators(unarchiveSurvey, dispatch),
  deleteSurvey: bindActionCreators(deleteSurvey, dispatch),
  updatePackageSurveyFlag: bindActionCreators(updatePackageSurveyFlag, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyModal);
