// Libs
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

// Assets
import { ReactComponent as AvgIcon } from 'assets/icons/avg_label_chart.svg';

// Styles
import { AvgLabel } from './styles';

const CustomAvgLabel = ({ color, viewBox }) => {
  const { x, y, width } = viewBox || { x: 0, y: 0, width: 0 };
  useEffect(() => {
    const avgLineElms = document.querySelectorAll('.avg-line .recharts-reference-line-line');
    if (!isEmpty(avgLineElms)) {
      for (let i = 0; i < avgLineElms.length; i++) {
        if (avgLineElms[i]) {
          avgLineElms[i].setAttribute('x1', x + 15);
        }
      }
    }
  }, []);

  return (
    <AvgLabel color={color}>
      <AvgIcon x={width + x + 3} y={y - 8} stroke="transparent" />
    </AvgLabel>
  );
};

export default CustomAvgLabel;
