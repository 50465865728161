import React from 'react';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { BrandingFallBackWrapper } from '../style';

export default function BrandingFallBack(props) {
  return (
    <BrandingFallBackWrapper>
      <div className="brandingFallBack__wrapper">
        <div className="brandingFallBack__topIcon"/>
        <div className="brandingFallBack__content">
          <div className="brandingFallBack__contentTitle">
            Upgrade to use this function
          </div>
          <div className="brandingFallBack__contentDescription">
            {props.description || 'Add your logo, color scheme, and custom banner for an inspiring aesthetic'}
          </div>
          <UpgradePathButton text="Upgrade" style={{padding: '12px 60px'}} />
        </div>
      </div>
    </BrandingFallBackWrapper>
  );
}
