import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 467px;
    height: 180px;
    border-radius: 8px;
  }
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const HeaderLabel = styled.div`
  margin-left: 10px;
  color: #000;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
`;

export const ContentWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
  word-break: keep-all;

  color: #323c47;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  b {
    font-weight: 600;
  }
`;

export const ModalAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled(Button)`
  display: flex;
  padding: 5px 30px;
  align-items: center;

  border-radius: 5px !important;
  border: none;
  background: #f5222d;
  color: #fff;

  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;

  :hover {
    color: #fff;
    background: #f5222d;
    opacity: 0.9;
  }
`;
