// libs
import React from 'react';
import ReactTooltip from 'react-tooltip';

// assets
import { ReactComponent as RearrangeIcon } from 'assets/icons/MealPlans/rearrange_icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_cicre.svg';

// styles
import * as S from './style';

const RearrangeButton = ({ isRearrangeMode, disabled, onClick }) => {
  return (
    <>
      {isRearrangeMode ? (
        <S.ButtonWrapper
          data-for="rearrange-tooltip-in"
          data-tip
          disabled={disabled}
          onClick={onClick}
          isRearrangeMode={isRearrangeMode}
        >
          <CloseIcon className="close-icon" />
          <span>Rearrange</span>
          <ReactTooltip className="app-tooltip rearrange-tooltip" id="rearrange-tooltip-in" effect="solid" place="top">
            <span>Exit Rearrange mode</span>
          </ReactTooltip>
        </S.ButtonWrapper>
      ) : (
        <S.ButtonWrapper
          data-for="rearrange-tooltip-out"
          data-tip
          disabled={disabled}
          onClick={() => onClick(isRearrangeMode)}
        >
          <RearrangeIcon className="rearrange-icon" />
          <ReactTooltip className="app-tooltip rearrange-tooltip" id="rearrange-tooltip-out" effect="solid" place="top">
            <span>Rearrange recipes</span>
          </ReactTooltip>
        </S.ButtonWrapper>
      )}
    </>
  );
};

export default RearrangeButton;
