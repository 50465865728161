import React from 'react';

import { ReactComponent as GiphyIcon } from 'assets/icons/giphy_inbox_icon.svg';
import { SharedTooltip } from 'shared/SharedTooltip';
import * as S from 'shared/Giphy/styles';

function InboxTrigger(props) {
  return (
    <S.InboxTrigger data-input="select-giphy" {...props} data-tip data-for="chat-input-giphy-tooltip">
      <GiphyIcon />
      <SharedTooltip content="Choose a GIF" position="top" id="chat-input-giphy-tooltip" />
    </S.InboxTrigger>
  );
}

export default InboxTrigger;
