import styled from 'styled-components';
import { MenuTrigger } from 'shared/Icons';
import ConfirmModalEle from 'shared/ConfirmModal';

export const DropdownWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  .evf-dropdown__option {
    padding: 8px 50px 8px 20px;

    .icon {
      margin-right: 10px;
    }
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: #00000010;
  border-radius: 6px;
  width: 24px;
  height: 24px;

  .dot,
  .dot::before,
  .dot::after {
    background-color: #ffffff;
  }

  &:hover {
    background: #ffffff;

    .dot,
    .dot::before,
    .dot::after {
      background-color: #000000;
    }
  }
`;

export const ConfirmModal = styled(ConfirmModalEle)`
  &.new-style {
    .confirm-content-header {
      padding: 30px 30px 15px 30px !important;
    }

    .confirm-content-body {
      padding: 0 30px !important;
      min-height: 40px;
    }

    .confirm-actions {
      padding: 30px !important;

      .confirm-yes-button {
        box-shadow: none;
      }
    }
  }
`;
