import styled, { css } from 'styled-components';
import LoadingIndicator from 'shared/LoadingIndicator';
import DietaryInfoItem from '../../../DietaryInfoItem';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7B7E91;
`;

export const ListIngredientWrapper = styled.div`
  padding: 0 0 0 20px;
  margin-right: 10px;
  height: 100%;
`;

export const Result = styled.div`
  ${baseText}
  margin-bottom: 5px;
  padding-left: 10px;
  padding-top: 15px;
`;

export const List = styled.div`
  position: relative;
  overflow-y: scroll;
  padding-right: 9px;
  padding-bottom: 5px;
  padding-top: 15px;
  height: calc(100% - 39px);
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #f0f0f2 !important;
  }
  ::-webkit-scrollbar-track {
    margin-bottom: 5px;
    margin-top: 15px;
  }
  ${({ isSearch }) =>
    isSearch &&
    css`
      height: calc(100% - 35px);
      padding-top: 0;
      ::-webkit-scrollbar-track {
        margin-top: 0;
      }
    `}
`;

export const DietaryInfoItemWrapper = styled(DietaryInfoItem)`
  height: 56px;
  width: 100%;
`;

export const NoResult = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  span {
    ${baseText}
    font-size: 13px;
    line-height: 20px;
    color: #202353;
  }
  .apple-icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f0f0f2;
    margin: 0 auto 20px;
  }
`;

export const LoadingIndicatorWrapper = styled(LoadingIndicator)`
  height: fit-content;
  padding-top: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  span {
    ${baseText}
    font-weight: 400;
    color: #7b7e91;
  }
  .ui.loader:after {
    border-color: #5158cf #e1e1ea #e1e1ea !important;
    border-width: 3px;
    width: 19px !important;
    height: 19px !important;
  }
  .ui.loader:before {
    width: 19px !important;
    height: 19px !important;
    border-width: 3px;
  }
  .ui.small.text.loader {
    padding-top: 25px;
  }
`;

export const AddIngredientBtn = styled.div`
  ${baseText}
  color: #202353;
  font-size: 12px;
  line-height: 18px;
  margin-top: 20px;
  display: flex;
  align-items: start;
  width: fit-content;
  background: #f0f0f2;
  border-radius: 5px;
  padding: 10px 15px 10px 30px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
  svg {
    position: absolute;
    left: 9px;
    width: 16px;
    height: 16px;
  }
  &:hover {
    background: #f0f1ff;
    border: 1px solid #e1e1ea;
    color: #5158cf;
    svg path {
      fill: #5158cf;
    }
  }
  ${({ hasResult }) =>
    hasResult &&
    css`
      margin-top: 5px;
      margin-bottom: 5px;
      background: transparent;
      &:hover {
        border: 1px solid transparent;
      }
    `}
`;
