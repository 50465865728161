import React from 'react';
import _ from 'lodash';
import { axiosInstance } from 'configs/request';
import { ClientAutocompleteWrapper, SearchClientInputContainer } from './style';
import { GlobalSearchIcon } from '../../style';
import GlobalSearchResults from '../GlobalSearchResults';

export default class ClientAutocomplete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      txtSearch: '',
      clients: [],
    };

    this.onSearch = _.debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.onSearch();
  }

  onChangeTxtSearch = e => {
    this.setState({ txtSearch: e.target.value });
    this.onSearch(e.target.value);
  };

  onSearch = txtSearch => {
    this.setState({ isSearching: true });
    if (!txtSearch) {
      return this.getRecentClients();
    }
    const params = {
      q: txtSearch,
      total: 10,
      should_search_archived: true,
    };
    axiosInstance
      .get('/api/trainer/clients_search', { params })
      .then(response => {
        const { data } = response.data;
        this.setState({ clients: data });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  };

  getRecentClients = () => {
    axiosInstance
      .get('/api/client-management/client-most-recent-list', { params: { total: 10 } })
      .then(response => {
        const { data } = response.data;
        this.setState({ clients: data });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  };

  render() {
    const { txtSearch, clients, isSearching } = this.state;
    return (
      <ClientAutocompleteWrapper>
        <SearchClientInputContainer>
          <input autoFocus placeholder="Client name" value={txtSearch} onChange={this.onChangeTxtSearch} />
          <GlobalSearchIcon className="searchInput__icon" />
        </SearchClientInputContainer>
        <GlobalSearchResults
          isSearching={isSearching}
          clients={clients}
          txtSearch={txtSearch}
          onSelectClient={this.props.onSelectClient}
          onCloseSearch={this.props.onCloseSearch}
        />
      </ClientAutocompleteWrapper>
    );
  }
}
