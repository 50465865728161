import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ImageGotoBilling from 'components/Product/ProductGotoBilling/ImageGotoBilling';

import * as S from './style';

const CardWithText = ({ title, icon, description, onClick, className, shouldUpgrade, tooltip, planWillSelect }) => {
  const handleOnClick = () => {
    onClick && onClick();
  };

  const handleRenderPathName = () => {
    let pathname = window.location.pathname;

    if (get(window.location, 'search', '').includes('skip')) {
      pathname = pathname + window.location.search;
    }

    return pathname;
  };

  return (
    <S.Wrapper onClick={handleOnClick} className={className} disabled={shouldUpgrade}>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
      <S.DetailWrapper>
        <S.Title>
          {title}
          {shouldUpgrade && (
            <ImageGotoBilling
              imageTitle={tooltip}
              maxWidthContent={234}
              planWillSelect={planWillSelect}
              notSendPrev={false}
              isNotUsePathName={true}
              pathname={handleRenderPathName()}
            ></ImageGotoBilling>
          )}
        </S.Title>
        {description && <S.Desc>{description}</S.Desc>}
      </S.DetailWrapper>
    </S.Wrapper>
  );
};

CardWithText.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  description: PropTypes.string,
  onClick: PropTypes.func,
  handleUpgradeIcon: PropTypes.func,
  className: PropTypes.string,
  shouldUpgrade: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default CardWithText;
