import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const DefaultUIContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .search-input {
    width: 265px;
    height: 36px;
  }

  button {
    padding: 8px 20px;
  }
`;

export const BannerTextContent = styled.div`
  max-width: 405px;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #1c1919;
  position: relative;
  z-index: 1;

  .teammates__banner__label {
    font-size: 12px;
    line-height: 150%;
    font-weight: 600;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  .teammates__banner__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    margin-bottom: 2px;
  }

  .teammates__banner__description {
    margin-bottom: 30px;
    span {
      opacity: 0.8;
    }

    a {
      margin-left: 10px;
      color: #5c5bbd;
      font-weight: bold;
      opacity: 1;

      img {
        margin-bottom: -3px;
      }
    }
  }
`;

export const BannerContainer = styled.div`
  background: #fff7f7;
  background-size: auto 100%;
  padding: 50px 50px 10px;
  min-height: 250px;
  border-radius: 8px;
  position: relative;

  .buttons__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    z-index: 1;
    position: relative;

    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }

  button {
    &.button--hide-banner {
      border: none;
      background-color: transparent;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #7e7e7e;
      border-radius: 5px;

      :hover {
        background-color: #bdbab0;
      }

      > * {
        :first-child {
          margin-right: 10px;
        }
      }
    }

    &.button--create-new {
      background-color: #f55e5e;
      padding: 10px 15px;
      color: #fff;

      svg {
        margin-right: 10px;

        path {
          fill: #fff;
        }
      }
    }

    &.button--search-box-trigger {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #f55e5e;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
      min-width: unset;

      svg {
        path {
          stroke: #f55e5e;
        }
      }

      :hover {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #f55e5e;

        svg {
          path {
            stroke: #f55e5e;
          }
        }
      }
    }
  }

  .search-input {
    width: 272px;
    height: 40px;
    border-radius: 5px;

    input {
      background-color: #ffffff;
      border-color: #fff;
      background-image: url(${CDN_URL}/images/search_black.svg);
      background-position: 11px center;
    }
  }

  .button-hidden-container {
    text-align: center;
    position: relative;
    z-index: 2;
  }
`;

export const Wrapper = styled.div`
  margin: 30px 0px 10px;

  ${props =>
    props.showBanner &&
    css`
      margin: 30px 0px 20px;
      margin-bottom: 10px;
    `}
`;

export const BackgroundBanner = styled.div`
  background: url(${CDN_URL}/images/teammates_banner.png) no-repeat top right;
  background-size: auto 100%;
  height: 105%;
  position: absolute;
  right: 70px;
  bottom: 0px;
  width: 400px;
`;
