import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import {
  viewWorkoutDetail,
  copyWorkout,
  deleteWorkout,
  pasteWorkout,
  arrangeWorkout,
  saveWorkoutToLibrary,
  updateWorkout,
  addSetToWorkout,
  updateViewDetailStatus,
  updateExerciseLibrary,
  resetCopyItem,
} from 'redux/autoflow/training/actions';
import {
  handleSelectMultipleWorkout,
  handleResetSelectedMultipleWorkout,
  handlePasteMultipleWorkout,
  handleResetSelectedWeek,
} from 'redux/autoflow/training/copy-remove-week-workout.actions';
import { toggleSideBar } from 'actions/sidebar';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import Cell from './component';

const mapStateToProps = (state, ownProps) => {
  const { rootReducer } = state;
  const { autoflow, permission } = rootReducer;
  const {
    copying,
    assignments,
    viewDetailStatus,
    selectedWeek,
    selectedWorkouts,
    isCopySelectedWorkout,
    isLoadingWorkouts,
  } = autoflow.training;
  const { viewMode } = autoflow.common;
  const day = ownProps.day.format('MM-DD-YYYY');
  const dayData = find(assignments, item => item.day === day);
  return {
    workingAutoflow: autoflow.common.workingAutoflow,
    copying,
    assignments,
    currentWorkouts: dayData ? dayData.workouts : [],
    viewDetailStatus,
    selectedWeek,
    selectedWorkouts,
    permission,
    isCopySelectedWorkout,
    hasSelectedWorkout: !isEmpty(selectedWorkouts),
    viewMode,
    isLoadingWorkouts,
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  viewWorkoutDetail: bindActionCreators(viewWorkoutDetail, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  copyWorkout: bindActionCreators(copyWorkout, dispatch),
  deleteWorkout: bindActionCreators(deleteWorkout, dispatch),
  pasteWorkout: bindActionCreators(pasteWorkout, dispatch),
  arrangeWorkout: bindActionCreators(arrangeWorkout, dispatch),
  saveWorkoutToLibrary: bindActionCreators(saveWorkoutToLibrary, dispatch),
  updateWorkout: bindActionCreators(updateWorkout, dispatch),
  addSetToWorkout: bindActionCreators(addSetToWorkout, dispatch),
  updateExerciseLibrary: bindActionCreators(updateExerciseLibrary, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updateViewDetailStatus: bindActionCreators(updateViewDetailStatus, dispatch),
  resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
  handleSelectMultipleWorkout: bindActionCreators(handleSelectMultipleWorkout, dispatch),
  handleResetSelectedMultipleWorkout: bindActionCreators(handleResetSelectedMultipleWorkout, dispatch),
  handlePasteMultipleWorkout: bindActionCreators(handlePasteMultipleWorkout, dispatch),
  handleResetSelectedWeek: bindActionCreators(handleResetSelectedWeek, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);
