import NumberFormat from 'react-number-format';
import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import TextEditableEl from 'shared/TextEditable';
import ConfirmModalElement from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
`;

export const TaskModalContainer = styled.div`
  display: flex;
`;

export const TaskForm = styled.div`
  position: relative;
  background: #fff;
  border-radius: 8px;
  width: 566px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${props =>
    props.editMode &&
    css`
      .input--task-name {
        margin-top: 40px;
      }
    `}

  .ui.pointing.dropdown {
    position: absolute;
    top: 31px;
    right: 35px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    .dropdown.icon {
      display: none;
    }
    .icon.icon-more-action {
      margin-right: 0;
    }
    .menu {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(42, 42, 52, 0.15);
      border-radius: 8px;
      width: 190px;
      left: -87px;
      border: 1px solid #c4c4c4 !important;
      top: 8px;
      padding: 10px 0;
      .item {
        font-weight: 600;
        padding: 10px 20px !important;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 100%;
        color: #202353;
        svg {
          margin-right: 10px;
        }
        &:hover {
          background: #f6f5ff;
          color: #5158cf;
          svg path {
            stroke: #5158cf;
          }
          svg.icon-end {
            path {
              fill: #5158cf;
              stroke: none;
            }
          }
        }
      }
      &:after {
        top: -4px;
        border-top: 1px solid #c4c4c4 !important;
        border-left: 1px solid #c4c4c4 !important;
        box-shadow: none;
      }
    }
  }

  .content {
    padding: 0 32px;
    margin-bottom: 22px;
    display: block;
    width: 100%;
    font-size: 1em;
    line-height: 1.4;
    flex: 1 1;
    overflow: visible;

    .title__wrapper {
      gap: 8px;

      .count {
        margin-top: 7px;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #8c8c8c;
        opacity: 1;
      }

      .evf-dropdown__menu {
        padding: 0;
        background: #fff;
        overflow: hidden;
        border: 1px solid #dadfea;
      }

      .modal__actions {
        border-top: 1px solid #dadfea;
        padding: 10px 25px;
        box-shadow: none;
      }
    }

    .accordion.ui.task-accordion {
      &:not(:has(.active)) {
        height: 15px;
      }

      .task-advanced-settings-title {
        padding: 0;
      }
    }
  }

  .modal__actions {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 10px 32px;
    text-align: right;
    background: #fff;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    height: 54px;

    .actions__saveClose {
      margin-right: 5px;
      min-width: 90px;
      padding: 7px 10px;
      border: 1px solid #d6dae4;
      line-height: 150%;
      height: 34px;

      :disabled {
        background: #fff;
        opacity: 0.3;
      }
    }

    .actions__save {
      min-width: 140px;
      max-width: 140px;
      padding: 7px 30px;
      :hover:not(&.disabled) {
        background: rgba(81, 88, 207, 0.9);
      }

      &.disabled {
        color: #fff;
        background: #bfbfbf;
        opacity: 0.5;
      }
    }
  }

  .modal__header {
    flex: 0 0 auto;
    padding: 32px 32px 0;

    .button--back {
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      padding: 0;
      cursor: pointer;
      opacity: 0.5;
      gap: 10px;
      width: min-content;
      height: 20px;
      margin-bottom: 20px;

      .text--back {
        width: 32px;
      }

      svg {
        path {
          stroke: #202353;
        }
      }
    }
  }
`;

export const TaskModal = styled(Modal)`
  &.ui.tiny.modal {
    background: transparent;
    box-shadow: none;
    width: unset;
  }
`;

export const ExtendActionsStyle = styled.div`
  position: absolute;
  right: 35px;
  top: 24px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .evf-dropdown {
    margin-left: 10px;

    .evf-dropdown__option {
      padding: 0;
      width: 100%;
    }

    .evf-dropdown__menu {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .evf-dropdown__trigger {
      width: 28px;
      height: 28px;
      border-radius: 5px;

      .dots {
        background-color: #838d9f;
        width: 4px;
        height: 4px;

        :before {
          width: 4px;
          height: 4px;
          left: -6px;
        }

        :after {
          width: 4px;
          height: 4px;
          left: 6px;
        }
      }

      :hover {
        background-color: #ebedf3;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #ebedf3;

        .dots {
          background-color: #838d9f;
        }
      }
    }
  }
`;

export const OptionIcon = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  padding: 7px 15px 7px 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;

  ::before {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(${CDN_URL}/images/${props => props.icon}.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  :hover {
    color: #5158cf;
    background-color: #eaebff;
    cursor: pointer;

    ${props =>
      props.hoverIcon &&
      css`
        ::before {
          background-image: url(${CDN_URL}/images/${props => props.hoverIcon}.svg);
        }
      `}
  }

  ${props =>
    props.darkMode &&
    css`
      color: #fff;

      :hover {
        color: #fff;
        background-color: #696969;
      }
    `}
`;

export const HabitIcon = styled.div`
  width: 44px;
  height: 44px;
  background: ${props => props.background};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  span {
    font-size: 32px;
  }
`;

export const TextEditable = styled(TextEditableEl)`
  .text-editable__container {
    padding: 8px 15px;
    width: fit-content;
    min-width: 450px;
    max-width: 450px;

    :not(.text-editable__container--active, .text-editable__container--invalid) {
      border-color: #dadfea;
      border-radius: 5px;
    }

    ${props =>
      props.readOnly &&
      css`
        &--active {
          border: 1px solid #dadfea;
          border-radius: 5px;
          cursor: not-allowed;
        }
      `}

    &--invalid {
      background: #fff;
    }

    .text-editable__input {
      color: #323c47;
      height: 27px;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: scroll;
      text-overflow: clip;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const GoalValuesWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  ${props =>
    props.hasError &&
    css`
      margin-bottom: 4px;
    `}

  .dropdown--habit-unit .common-select-container__control,
  .dropdown--habit-frequency .common-select-container__control {
    background: #fafbfc;
    border: 1px solid #dadfea;

    :hover {
      border: 1px solid #5158cf;
    }

    &--is-focused {
      border: 1px solid #5158cf;
      background: #fff;
    }

    span {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #323c47;
    }
  }

  .dropdown--habit-unit:has(.common-select-container__control--is-disabled),
  .dropdown--habit-frequency:has(.common-select-container__control--is-disabled) {
    cursor: not-allowed;
  }

  .dropdown--habit-unit {
    .common-select-container__control {
      border-radius: 0;
      padding: 0 18px 0 5px;
      border-left-color: transparent;
      border-right-color: transparent;
    }
    .common-select-container__option {
      font-size: 13px;
      line-height: 18px;
      color: #000;

      &--is-focused:not(.common-select-container__option--is-selected) {
        background: transparent !important;
        color: #000 !important;
        font-weight: 400 !important;
      }

      :hover {
        background: #f4f3ff !important;
        color: #5158cf !important;
        font-weight: 600 !important;
      }
    }
  }

  .dropdown--habit-frequency {
    .common-select-container__control {
      border-top-left-radius: 0;
      padding: 0 14px 0 5px;
      border-bottom-left-radius: 0;
    }

    .common-select-container__option {
      font-size: 13px;
      line-height: 18px;
      color: #000;
    }
  }
`;

export const GoalValueErrorMessage = styled.p`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: #ea314a;
  margin-bottom: 0;
  ${props =>
    !props.viewDetails &&
    css`
      margin-bottom: 20px;
    `}
`;

export const NumberInput = styled(NumberFormat)`
  width: 255px;
  height: 44px;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #dadfea;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  padding: 12px 15px;
  outline: none;

  ::placeholder {
    color: #202353;
    opacity: 0.4;
    font-weight: 400;
  }

  ${props =>
    props.fullWidth &&
    css`
      width: 540px;
    `}

  ${props =>
    !props.disabled
      ? css`
          :hover,
          :focus {
            border-color: #5158cf;
          }
        `
      : css`
          cursor: not-allowed;
        `}

  ${props =>
    props.error &&
    css`
      border-color: #ea314a !important;
    `}
`;

export const HabitPeriodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

export const PeriodLabel = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #6a778a;

  ${props =>
    props.paddingRight &&
    css`
      padding-right: ${props.paddingRight};
    `}
`;

export const RepeatTitle = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
`;

export const DayOfWeeksWrapper = styled.div`
  .list-rest-day {
    border: 1px solid #dadfea;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    gap: 3px;
    background: #fff;
    border-radius: 5px;

    .rest-day-spec {
      width: 30px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 10px;
      line-height: 100%;
      color: rgba(50, 60, 71, 0.62);
      cursor: pointer;
    }
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox']:checked + .rest-day-spec {
      background: #e7e6ff;
      border-radius: 3px;
      color: #7166ea;
    }
  }

  ${props =>
    props.disabled &&
    css`
      .list-rest-day {
        cursor: not-allowed;
        .rest-day-spec {
          cursor: not-allowed;
        }
      }
    `}
`;

export const PeriodWrapper = styled.div`
  background: rgba(243, 245, 248, 0.8);
  border-radius: 6px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${props =>
    props.isInterval &&
    css`
      gap: 16px;
    `}

  ${props =>
    props.longMessage &&
    css`
      gap: 48px;
    `}
`;

export const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;

  ${props =>
    props.isInterval &&
    css`
      gap: 23px;
    `}

  .rdt.new-ui {
    width: 143px;

    ${props =>
      props.disabled &&
      css`
        .custom-date-input {
          cursor: not-allowed;

          :hover,
          :focus {
            border: 1px solid #d4d7d9;
          }
        }
      `}

    thead tr th.dow {
      font-family: 'Open Sans';
      font-size: 13px;
      line-height: 100%;
      color: #202353;
      height: 33px;
    }

    thead tr:first-child {
      th {
        padding-bottom: 24px;
      }
    }

    tbody {
      tr:last-child {
        .rdtNew {
          display: none;
        }
      }
      /* TODO - Please consider this code for styles of date picker */
      tr:has(.rangeStart) .rdtDay div {
        padding-top: 14px;
      }
      tr:has(.rangeEnd) .rdtDay div {
        padding-top: 14px;
      }
    }

    .rdtPicker {
      width: 339px;
      padding: 22px 24px;
      border: 1px solid #eceef4;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-top: 4px;

      .rdtSwitch {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px !important;
        line-height: 19px !important;
        color: #202353 !important;

        :hover {
          color: #7470ef !important;
        }
      }

      .rdtPrev {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/icon-calendar-change-month.svg);
          position: absolute;
          top: 26px;
          left: 30px;
        }
      }
      .rdtNext {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/icon-calendar-change-month.svg);
          display: inline-block;
          transform: rotate(180deg);
          position: absolute;
          top: 26px;
          right: 28px;
        }
      }
    }

    .rdtDay {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 13px;
      line-height: 100%;
      color: #202353;

      div {
        width: 33px;
        height: 33px;
        padding: 18px 13px 14px;
      }

      ::before {
        top: 9px !important;
        left: 56% !important;
        width: 33px !important;
        height: 33px !important;
      }

      &.rdtToday {
        font-weight: 700;
        color: #5158cf;
      }

      &.rdtActive {
        font-weight: 600;
        color: #fff;

        ::before {
          background: #5158cf !important;
        }

        &.rangeStart {
          ::before {
            background: #f4f4f4 !important;
            border-radius: 0;
            left: 53% !important;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            box-shadow: 4px 0px 0px 0px #f4f4f4;
          }

          div {
            background: #5158cf;
            border-radius: 50%;
            padding: 0;
            margin-top: 8px;
            margin-left: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.rangeEnd {
          ::before {
            background: #f4f4f4 !important;
            border-radius: 0;
            left: 45% !important;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            box-shadow: -4px 0px 0px 0px #f4f4f4;
          }

          div {
            background: #5158cf;
            border-radius: 50%;
            padding: 0;
            margin-top: 8px;
            margin-left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &.inRange {
        div {
          font-weight: 600;
          background: #f4f4f4;
          padding: 0;
          margin-top: 8px;
          margin-left: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: -4px 0px 0px 0px #f4f4f4, 6px 0px 0px 0px #f4f4f4;
        }
      }
    }

    .rdtPicker tr:has(.inRange) {
      .rdtDay div {
        padding: 14px 13px;
      }
    }
  }

  .custom-date-input {
    background: #fafbfc;
    min-width: 143px;
    padding: 8px 13px;

    span {
      line-height: 18px;
    }
  }

  .error {
    .custom-date-input {
      border-color: #ea314a;
    }
  }
  ${props =>
    props.position &&
    css`
      .rdt {
        .rdtPicker {
          position: fixed;
          bottom: calc(100% - ${props.position - 4}px);
          @media (max-height: 768px) {
            position: absolute;
            bottom: auto;
          }
        }
      }
    `}

  @media screen and (min-width: 1441px) and (min-height: 790px) {
    .rdt {
      .rdtPicker {
        bottom: calc(100% + 4px);
      }
    }
    ${props =>
      props.position &&
      css`
        .rdt {
          .rdtPicker {
            bottom: calc(100% - ${props.position - 4}px);
          }
        }
      `}
  }

  .interval-select-date {
    .evfSelectBox__control {
      border: 1px solid #dadfea;
      border-radius: 5px;
      padding-right: 15px;
      background-color: #fff;

      :hover {
        border-color: #5158cf;
      }

      .evfSelectBox__single-value {
        font-weight: 400;
        color: #000;
      }

      .evfSelectBox__value-container {
        padding-left: 13px;
      }

      .evfSelectBox__indicators i.icon {
        margin-right: 0;
      }
    }

    &.invalid-date {
      .evfSelectBox__control {
        border-color: #ea314a;
      }
    }

    .evfSelectBox__menu {
      margin-top: 2px;
    }

    .evfSelectBox__menu-list {
      padding: 7px 0 2px;
      max-height: 234px;
      margin-right: 4px;

      ::-webkit-scrollbar {
        width: 4px !important;
      }

      ::-webkit-scrollbar-thumb {
        background: #ebebeb !important;
        border-radius: 9999px !important;
      }
    }

    .evfSelectBox__option {
      &--is-focused:not(.evfSelectBox__option--is-selected) {
        background: transparent !important;
        color: #000 !important;
        font-weight: 400 !important;
      }

      :hover {
        background: #f4f3ff !important;
        color: #5158cf !important;
        font-weight: 600 !important;
      }
    }

    .evfSelectBox__group {
      padding: 0;
      padding-top: 2px;
      padding-right: 4px;

      &:first-child {
        padding-top: 3px;
      }

      .evfSelectBox__group-heading {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #000;
        opacity: 0.4;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 8px;
        margin: 0;
        text-transform: none;
      }
    }
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ea314a;
  left: 60px;
  bottom: -20px;
  width: 100%;

  ${props =>
    props.longMessage &&
    css`
      bottom: -36px;
    `}
`;

export const RepeatWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .dropdown--habit-repeat .common-select-container__control {
    min-height: 36px;
    padding: 0 12px 0 5px;
    border: 1px solid #dadfea;
    border-radius: 5px;
    background: #fff;

    :hover,
    :focus {
      border-color: #5158cf;
    }
  }

  .dropdown--habit-repeat .rootOptions .common-select-container__menu {
    margin: 0;
    padding: 0;
    margin-top: 4px;

    .common-select-container__menu-list {
      padding: 9px 0;
    }
  }

  .dropdown--habit-repeat:has(.common-select-container__control--is-disabled) {
    cursor: not-allowed;
  }

  .dropdown--habit-repeat .common-select-container__option {
    font-size: 13px;
    line-height: 18px;
    color: #000;

    &.common-select-container__option--is-focused {
      background: #f6f5ff;
    }
  }
`;

export const SetTimeWrapper = styled.div`
  .custom-reminder {
    margin-left: 0;
    height: 36px;

    ${props =>
      props.disabled &&
      css`
        .evfSelectBox__control {
          pointer-events: none;
        }
      `}

    .evfSelectBox__menu {
      margin-bottom: 4px;
    }

    label {
      padding-left: 44px;
    }
  }
`;

export const Breaker = styled.div`
  background: #e2e3e8;
  height: 1px;
  width: 100%;
  opacity: 0.7;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const HabitConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 447px !important;

    .confirm-content-header {
      padding: 30px 0 0 30px !important;
    }

    .confirm-content-body {
      padding: 15px 30px 40px 30px !important;
    }
    .confirm-actions {
      .confirm-yes-button {
        ${baseText};
      }

      .confirm-no-button {
        ${baseText};
      }
    }
  }
`;

export const SyncedAutoflow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const GreenDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #2fd787;
`;

export const SyncedAutoflowText = styled.p`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
  opacity: 0.9;
  margin: 0;
  width: fit-content;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  box-orient: vertical;
  overflow: hidden;
`;

export const AutoflowName = styled.a`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5158cf;
  opacity: 0.9;

  :hover {
    color: #5158cf;
    text-decoration: none !important;
  }
`;
