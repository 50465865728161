import moment from 'moment';

export const initChartDataEmpty = (time = '') => {
  const momentTime = moment(time, 'MMM d');
  return [
    {
      group_name: time,
      min: 0,
      max: 0,
      start: time,
      end: time,
      time: momentTime,
    },
  ];
};
