import { Form } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Trigger = styled.div`
  width: 42px;
  height: 24px;
  background-color: #e4e5e7;
  border-radius: 3px;
  background-image: url(${CDN_URL}/images/filter.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
  z-index: 999;

  :hover {
    background-color: #e7e6fe;
    background-image: url(${CDN_URL}/images/filter_purple.svg);
  }
`;

export const TriggerContainer = styled.div`
  ::after {
    content: '';
    width: 7px;
    height: 7px;
    background: #ffffff;
    border: 1px solid #d4d7d9;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    z-index: 1000000;
    display: none;
  }
`;

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
`;

export const SectionHeader = styled.div`
  color: #202353;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
`;

export const SectionBody = styled.div`
  margin-top: 9px;
  display: flex;

  .multi-select__input {
    line-height: 150%;
    input {
      line-height: 150%;
    }
  }

  .multi-select__placeholder {
    color: #c3c5cb;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }

  .multi-select-container .multi-select__control {
    width: 342px;
    min-height: 44px;
    max-height: 104px;
    margin-top: 5px;
    overflow-y: scroll;
  }

  .multi-select__menu-list {
    padding: 0;
    max-height: 150px;
    .multi-select__menu-notice {
      padding: 0;
    }
  }

  .multi-select-container .multi-select__value-container {
    padding: 2px 6px;
  }

  .client-type-radio-wrapper {
    width: 342px;
    gap: 24px;
  }

  .owner-radio-wrapper {
    width: 220px;
  }

  .group-loading {
    padding: 10px;
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }

  .multi-select-container .multi-select__menu-list .boxLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-height: 50px;
    margin: 10px 0;

    &.textLoading {
      font-size: 13px;
      line-height: 18px;
      color: rgba(32, 35, 83, 0.6);
    }

    .ringLoading div {
      box-sizing: border-box;
      display: block;
      width: 24px;
      height: 24px;
      margin: 8px;
      border: 2px solid #5158cf;
      border-radius: 50%;
      animation: lds-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #5158cf transparent transparent transparent;

      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;

export const Section = styled.div`
  padding: 15px 20px;

  border-bottom: 1px solid #d4d7d9;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #111111;

  :last-child {
    border-bottom: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0;
`;

export const PopupTitleHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
`;
export const ClearAllFilter = styled.div`
  color: #5c5bbd;
  text-align: right;
  font-size: 13px;
  font-weight: 600;
  margin-right: -2px;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Body = styled.div`
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 15px 0 5px;
`;

export const Footer = styled.div`
  text-align: right;
  padding: 10px 15px;
  border-top: 1px solid #d4d7d9;
`;

export const Popup = styled.div`
  width: 382px;
  background: #ffffff;
  border: 1px solid #d4d7d9;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible;
  display: none;
  position: fixed;
  height: auto;
  z-index: 999;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-right: 10px;
  user-select: none;

  &.bottom {
    ${TriggerContainer} {
      ::after {
        top: calc(100% + 2px);
        border-right: none;
        border-bottom: none;
      }
    }
  }

  &.open {
    ${Trigger} {
      background-color: #e7e6fe;
      background-image: url(${CDN_URL}/images/filter_purple.svg);
    }

    ${TriggerContainer} {
      ::after {
        display: block;
      }
    }

    ${Popup} {
      display: flex;
    }

    .broadcastMessage__filter-icon {
      background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon_hover.svg);
      background-color: #eaebff;
      border-radius: 3px;
    }

    .filter-icon {
      path {
        fill: #5c5bbd;
      }
    }
  }
  :hover {
    .broadcastMessage__filter-icon {
      background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon.svg);
      background-color: #eaebff;
      border-radius: 3px;
    }
  }
`;

export const RadioFormField = styled(Form.Field)`
  margin-bottom: 16px !important;
  width: 98px;

  :last-child {
    margin-bottom: 0px !important;
  }

  label {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #000000 !important;

    padding-left: 29px !important;

    :before {
      width: 18px !important;
      height: 18px !important;
      border: 1px solid #cbcfd3 !important;
    }

    :after {
      width: 18px !important;
      height: 18px !important;
    }
  }

  .checked > label {
    :before {
      border: 2px solid #5158cf !important;
    }

    :after {
      background-color: #5158cf !important;
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 34px;
  padding: 8px 0px 5px 0px;
  margin-left: 2px;
`;

export const SelectGroupContainer = styled.div`
  position: relative;

  &.multi-select__option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 13px;
    border-bottom: 1px solid #eceff1;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 50px;
  }

  &.multi-select__option.multi-select__option--is-focused {
    background-color: #edecfc;
  }

  &.multi-select-container .multi-select__control {
    max-height: 150px;
    overflow-y: scroll;
  }

  .multi-select__option__label {
    z-index: 100 !important;
    max-width: 280px;
    display: flex;
    align-items: center;
    margin-left: -2px;
    margin-bottom: -2px;

    .avatar {
      margin-right: 12px;
    }

    .name {
      max-width: 280px;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #111111;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .totalMember {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8d8c92;

      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .group-loading {
    padding-top: 0;
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const EmptyMessage = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  opacity: 0.2;
`;
