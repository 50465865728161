import Request from 'configs/request';
import _ from 'lodash';

export const Types = {
  AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_START: 'AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_START',
  AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_END: 'AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_END',
  AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_FAILED: 'AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_FAILED',
  AUTOFLOW_EXACT_DATE_UPDATE_LEADERBOARD_SUCCESS: 'AUTOFLOW_EXACT_DATE_UPDATE_LEADERBOARD_SUCCESS',
  AUTOFLOW_EXACT_DATE_CREATE_LEADERBOARD_SUCCESS: 'AUTOFLOW_EXACT_DATE_CREATE_LEADERBOARD_SUCCESS',
  AUTOFLOW_EXACT_DATE_REMOVE_LEADERBOARD_SUCCESS: 'AUTOFLOW_EXACT_DATE_REMOVE_LEADERBOARD_SUCCESS',
  AUTOFLOW_EXACT_DATE_GET_LEADERBOARD_CLIENT_SUCCESS: 'AUTOFLOW_EXACT_DATE_GET_LEADERBOARD_CLIENT_SUCCESS',
  AUTOFLOW_EXACT_DATE_LEADERBOARD_UNMOUNT: 'AUTOFLOW_EXACT_DATE_LEADERBOARD_UNMOUNT',
};

export const autoflowLeaderboardUnmount = () => ({ type: Types.AUTOFLOW_EXACT_DATE_LEADERBOARD_UNMOUNT });

export const autoflowFetchLeaderboards = autoflowId => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_START });

    return dispatch(
      Request.get(
        { url: `/api/leaderboard/get-leaderboard-by-autoflow?autoflowId=${autoflowId}` },
        true,
        response => {
          dispatch({ type: Types.AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_END, payload: { list: response.data.data } });
        },
        () => {
          dispatch({ type: Types.AUTOFLOW_EXACT_DATE_FETCHING_LEADERBOARD_FAILED });
        },
      ),
    );
  };
};

export const autoflowCreateLeaderboard = body => {
  return Request.post({ url: `/api/leaderboard/add-new-leaderboard`, data: body }, true, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_EXACT_DATE_CREATE_LEADERBOARD_SUCCESS, payload: response.data.data });
  });
};

export const autoflowUpdateLeaderboard = body => {
  return Request.post({ url: `/api/leaderboard/edit-leaderboard`, data: body }, true, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_EXACT_DATE_UPDATE_LEADERBOARD_SUCCESS, payload: response.data.data });
  });
};

export const autoflowRemoveLeaderboard = body => {
  return Request.post({ url: `/api/leaderboard/remove-leaderboard`, data: body }, true, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_EXACT_DATE_REMOVE_LEADERBOARD_SUCCESS });
  });
};

export const autoflowGetLeaderboardClients = params => {
  return (dispatch, getState) => {
    const clients = getState().rootReducer.autoflow.leaderboard.get('clients').toJS();
    const queryParams = { per_page: clients.per_page, ...params };
    return dispatch(
      Request.get({ url: `/api/leaderboard/get-list-members`, params: queryParams }, false, response => {
        const {
          rootReducer: {
            autoflow: { leaderboard },
          },
        } = getState();
        const currentLeaderboard = leaderboard.getIn(['list', '0']);

        if (currentLeaderboard && currentLeaderboard.get('_id') === queryParams.leaderboardId) {
          const { data } = response.data;
          const currentList = leaderboard.getIn(['clients', 'list']).toJS();
          const newList = _.unionBy(currentList, data.list, '_id');
          dispatch({
            type: Types.AUTOFLOW_EXACT_DATE_GET_LEADERBOARD_CLIENT_SUCCESS,
            payload: { ...data, list: newList, isLoaded: true },
          });
        } else {
          const { data } = response.data;
          const currentList = leaderboard.getIn(['clients', 'list']).toJS();
          const newList = _.unionBy(currentList, data.list, '_id');
          dispatch({
            type: Types.AUTOFLOW_EXACT_DATE_GET_LEADERBOARD_CLIENT_SUCCESS,
            payload: { ...data, list: newList, isLoaded: true },
          });
        }
      }),
    );
  };
};
