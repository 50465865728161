import difference from 'lodash/difference';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import union from 'lodash/union';
import React from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Checkbox } from 'shared/FormControl';
import * as Layout from 'shared/LibraryLayout';
import { selectedClientIdsWaitList, changeQueryParams } from 'redux/waitlist/actions';
import { columns, WAITLIST_STATUS } from '../constants';
import * as S from '../../../components/CustomTable.style';
import { WaitListHeader } from '../styles';
import { SORT_ENUM } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';

function View({ list, total, sort, order, changeQueryParams, clientIdsSelected, selectedClientIdsWaitList }) {
  const ids = map(
    filter(list, item => item.status === WAITLIST_STATUS.WAITING),
    'id',
  );
  const hasWaitingRequest = !!get(ids, 'length', 0);
  const hasSelected = !!get(clientIdsSelected, 'length', 0);
  const idsSelected = hasSelected ? clientIdsSelected : [];
  const isSelectedAll = hasWaitingRequest && hasSelected && difference(ids, idsSelected).length === 0;

  const onSort = key => {
    changeQueryParams({
      sort: key,
      order: key === sort && order === SORT_ENUM.DESCENDING ? SORT_ENUM.ASCENDING : SORT_ENUM.DESCENDING,
    });
  };

  const renderSortIcon = key => {
    if (key !== sort) {
      return <ArrowDown className="column-symbol arrow" />;
    }

    const arrow = order === SORT_ENUM.DESCENDING ? 'up' : 'down';

    return arrow === 'up' ? (
      <ArrowUp className="column-symbol arrow" active />
    ) : (
      <ArrowDown className="column-symbol arrow" active />
    );
  };

  const handleSelectAllTask = event => {
    event.stopPropagation();

    if (event.target && event.target.checked) {
      selectedClientIdsWaitList(union([...clientIdsSelected, ...ids]));
    } else {
      selectedClientIdsWaitList(filter(clientIdsSelected, id => !ids.includes(id)));
    }
  };

  return (
    <S.TableHeaderWrapper>
      <Layout.TableRow>
        <WaitListHeader key="client_name">
          <S.CustomTableCellWrapper>
            <S.CheckBoxContainer isHasSelectedPrev={hasSelected}>
              <div className="checkbox__header">
                <Checkbox checked={isSelectedAll} onChange={handleSelectAllTask} />
              </div>
              <S.TableHeaderCellContentWrapper
                className="sortable"
                active={sort === 'client_name'}
                onClick={() => onSort('client_name')}
              >
                <Layout.CellTitle>{pluralize('Client', total, true)}</Layout.CellTitle>
                {renderSortIcon('client_name')}
              </S.TableHeaderCellContentWrapper>
            </S.CheckBoxContainer>
          </S.CustomTableCellWrapper>
        </WaitListHeader>
        {map(columns, item => {
          return (
            <WaitListHeader key={item.key}>
              <S.TableHeaderCellContentWrapper
                className={classNames(item.sortable ? 'sortable' : '')}
                active={sort === item.key}
                onClick={() => item.sortable && onSort(item.key)}
              >
                <Layout.CellTitle>{item.name}</Layout.CellTitle>
                {get(item, 'sortable', false) && renderSortIcon(item.key)}
              </S.TableHeaderCellContentWrapper>
            </WaitListHeader>
          );
        })}
        <WaitListHeader key="actions" />
      </Layout.TableRow>
    </S.TableHeaderWrapper>
  );
}

const TableHeader = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      waitList: {
        list,
        loading,
        total,
        clientIdsSelected,
        queryParam: { sort, order },
      },
    },
  } = state;
  return {
    list,
    loading,
    total,
    sort,
    order,
    clientIdsSelected,
  };
};

const mapDispatch = dispatch => {
  return {
    selectedClientIdsWaitList: bindActionCreators(selectedClientIdsWaitList, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableHeader);
