import { Types } from './actions';
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';

const PER_PAGE = 20;

export const DEFAULT_STATE = {
  total: 0,
  list: [],
  selectedSection: [],
  loading: false,
  query: {
    page: 1,
    limit: PER_PAGE,
    query: '',
    sorter: 'last_used',
    sort: -1,
    owners: [],
    formats: [],
  },
};

export default (state = DEFAULT_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case Types.GET_SECTION_LIBRARY_REQUEST:
      return Object.assign({}, state, { loading: true });

    case Types.GET_SECTION_LIBRARY_SUCCESS: {
      const {
        data: { list, total },
      } = payload;

      return Object.assign({}, state, { list, total, loading: false });
    }

    case Types.SECTION_LIBRARY_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { query: { ...payload.data } });

    case Types.SECTION_LIBRARY_RESET_QUERY_PARAMS:
      return Object.assign({}, state, { query: DEFAULT_STATE.query });

    case Types.SECTION_LIBRARY_DUPLICATE_SUCCESS:
      return Object.assign({}, state, { loading: false });

    case Types.SECTION_LIBRARY_DELETE_SINGLE_SUCCESS:
      const { id } = payload;
      const { selectedSection } = state;
      let currentSelectedSections = selectedSection;
      if (id && selectedSection.length > 0) {
        currentSelectedSections = selectedSection.filter(it => it !== id);
      }
      return Object.assign({}, state, { loading: false, selectedSection: currentSelectedSections });

    case Types.SECTION_LIBRARY_BULK_DELETE_SUCCESS:
      const { total, list } = payload;
      return Object.assign({}, state, { loading: false, total: total, list: list });

    case Types.SECTION_LIBRARY_UPDATE_SELECTED_SECTION:
      return Object.assign({}, state, { selectedSection: payload.data });

    case Types.SECTION_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS:
      const { sectionLibraryId, data } = payload;
      let currentSharing = [...state.selectedSection];
      if (sectionLibraryId && currentSharing.length > 0) {
        currentSharing = currentSharing.filter(it => it !== sectionLibraryId);
      }
      if (data && data.share === TEAM_SHARE_PRIVATE) {
        return Object.assign({}, state, { loading: false, selectedSection: currentSharing });
      }
      return Object.assign({}, state, { loading: false });

    case Types.GET_SECTION_LIBRARY_FAILED:
    case Types.SECTION_LIBRARY_DUPLICATE_FAILED:
    case Types.SECTION_LIBRARY_DELETE_SINGLE_FAILED:
    case Types.SECTION_LIBRARY_BULK_DELETE_FAILED:
    case Types.SECTION_LIBRARY_FAILED_UPDATE_SHARING_STATUS:
      return Object.assign({}, state, { loading: false });

    default:
      return state;
  }
};
