import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

import { capitalizeFirstChar } from 'utils/commonFunction';

import { LIMIT_OPTION_CHARACTER, LIMIT_QUESTION_CHARACTER, SHOW_OPTION_CHARACTER } from './constants';
import { KEY_CODE } from 'constants/commonData';

import * as S from './style';

const QuestionInput = props => {
  const { placeholder = '', question = '', autoFocus = false, onInputChange, disableEditPoll } = props;

  const inputRef = useRef();
  const [isFocus, setIsFocus] = useState(false);

  const handleInputChange = event => {
    if (disableEditPoll) return;
    if (inputRef.current) {
      const { value } = inputRef.current || {};
      const finalValue = capitalizeFirstChar(value);
      if (finalValue.length > LIMIT_QUESTION_CHARACTER) return;

      if (typeof onInputChange === 'function') {
        event.target.value = finalValue;
        onInputChange(finalValue);
      }
    }
  };

  const { isDisable, isShowCharacter, isShowWarning } = useMemo(() => {
    const isDisable = (question || '').length >= LIMIT_OPTION_CHARACTER;
    const isShowCharacter = (question || '').length >= SHOW_OPTION_CHARACTER;
    const isShowWarning = isShowCharacter;

    return { isDisable, isShowCharacter, isShowWarning };
  }, [question]);

  const handleFocus = () => {
    if (disableEditPoll) return;
    inputRef.current && inputRef.current.focus();
  };

  const handleOnFocus = () => {
    if (disableEditPoll) return;
    setIsFocus(true);
  };

  const handleOnBlur = () => {
    setIsFocus(false);
  };

  const onKeyPress = event => {
    if (event.keyCode === KEY_CODE.enter) {
      setIsFocus(false);
      inputRef.current && inputRef.current.blur();
    }
  };

  return (
    <S.QuestionContainer>
      <S.QuestionEditorWrapper
        className={classNames({ 'has-focus': isFocus })}
        onClick={handleFocus}
        disableEditPoll={disableEditPoll}
      >
        <TextareaAutosize
          type="text"
          ref={inputRef}
          defaultValue={question}
          value={question}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onChange={handleInputChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          maxLength={LIMIT_QUESTION_CHARACTER}
          className="question-input"
          disabled={disableEditPoll}
          onKeyDown={onKeyPress}
        />
      </S.QuestionEditorWrapper>
      {isShowWarning && (
        <div className="label-warning">
          {isShowCharacter && (
            <S.WarningOptionLabel
              className={classNames('count-character', {
                'is-disable': isDisable,
              })}
            >
              {`${question.length}/${LIMIT_OPTION_CHARACTER}`}
            </S.WarningOptionLabel>
          )}
        </div>
      )}
    </S.QuestionContainer>
  );
};

export default QuestionInput;
