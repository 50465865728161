import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import Datetime from 'react-datetime';
import { DateInput } from 'shared/FormControl';
import { ANNOUNCEMENT_SCHEDULE_TYPES, CDN_URL } from 'constants/commonData';

const SCHEDULES = [
  { key: 'single', text: 'Single Day', value: ANNOUNCEMENT_SCHEDULE_TYPES.SINGLE },
  { key: 'multiple', text: 'Multiple Days', value: ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE },
];

export default ({ onUpdateSchedule, onUpdateStartDate, onUpdateEndDate, schedule, start, end, error }) => {
  return (
    <div className="schedule">
      <Dropdown
        value={schedule}
        selection
        selectOnBlur={false}
        selectOnNavigation={false}
        options={SCHEDULES}
        onChange={onUpdateSchedule}
        icon={<img src={`${CDN_URL}/images/arrow_down_black.svg`} className="dropdown-icon" alt="" />}
        className="new-ui"
      />
      <Datetime
        value={start}
        renderInput={props => {
          return <DateInput {...props} text={start.format('MMM D, YYYY')} />;
        }}
        timeFormat={false}
        closeOnSelect={true}
        onChange={onUpdateStartDate}
        className="new-ui"
        isValidDate={currentDate => currentDate.isSameOrAfter(moment(), 'day')}
      />
      {schedule === ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE && (
        <>
          <img src={`${CDN_URL}/images/arrow_long_right.svg`} alt="" />
          <Datetime
            value={end}
            renderInput={props => {
              return (
                <DateInput
                  {...props}
                  text={end.format('MMM D, YYYY')}
                  isInvalidDate={error && end.isBefore(moment(), 'day')}
                />
              );
            }}
            timeFormat={false}
            closeOnSelect={true}
            onChange={onUpdateEndDate}
            className="new-ui"
            isValidDate={currentDate => currentDate.isAfter(start, 'day') && currentDate.isSameOrAfter(moment(), 'day')}
          />
          {error && end.isBefore(moment(), 'day') ? (
            <div className="schedule__end-date--invalid">End date should not be in the past</div>
          ) : null}
        </>
      )}
    </div>
  );
};
