import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import DropDown, { Option, Trigger } from 'shared/Dropdown/Basic';
import { CDN_URL, LEADERBOARD_STATUS } from 'constants/commonData';
import { getLeaderboardUnitLabel } from 'utils/commonFunction';
import { ReactComponent as ShareLeaderboardButton } from 'assets/icons/forum-leaderboard-tab-share-btn.svg';

import * as S from './style';
import Clients from './Clients';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import Ranking from './Ranking';
import InfoEnding from './InfoEnding';
import Duration from './Duration';
import { SharedTooltip } from 'shared/SharedTooltip';

export default ({
  leaderboard,
  onEdit,
  onRemove,
  rankingTypes,
  onLoadClients,
  clients,
  noActions,
  onlyTop3,
  shareLeaderboardAction,
  loadMoreOffset,
}) => {
  const [status, setStatus] = useState(leaderboard.status);
  const { isLoaded, total } = clients;
  const list = _.uniqBy(clients.list, 'user._id');
  const fistClient = list.length > 0 ? list[0] : null;
  const rankingType = rankingTypes.find(item => item.value === leaderboard.ranking_type);

  const unit = useMemo(() => {
    if (rankingType) {
      return getLeaderboardUnitLabel(rankingType, fistClient);
    }
    return '';
  }, [fistClient]);

  const startDate = moment(leaderboard.start_date);
  const endDate = moment(leaderboard.end_date);

  useEffect(() => {
    if (leaderboard) setStatus(leaderboard.status);
  }, [leaderboard]);

  const onCompleted = () => {
    setStatus(LEADERBOARD_STATUS.COMPLETED);
  };

  const renderTriggerIcon = ({ open }) => {
    return (
      <div data-tip data-for="forum-leaderboard-tooltip">
        <Trigger />
        {!open && <SharedTooltip id="forum-leaderboard-tooltip" />}
      </div>
    );
  };

  return (
    <S.Wrapper className="leaderboardDetail">
      <S.BaseInforContainer>
        <div className="info">
          <div
            className={classnames('info__ending', {
              upcoming: status === LEADERBOARD_STATUS.UPCOMING,
              completed: status === LEADERBOARD_STATUS.COMPLETED,
            })}
          >
            <InfoEnding
              status={status}
              startDate={leaderboard.start_date}
              endDate={leaderboard.end_date}
              onCompleted={onCompleted}
            />
          </div>
          <div className="info__name">
            <span>{leaderboard.name}</span>
            {status === LEADERBOARD_STATUS.LIVE && <S.MarkLive>Live</S.MarkLive>}
          </div>
          <div className="info__other">
            <Duration startDate={startDate} endDate={endDate} />
            {isLoaded && (
              <S.PartialInfo>
                <UserIcon />
                <span>{total}</span>
              </S.PartialInfo>
            )}
          </div>
        </div>
        {isLoaded && <Ranking top1={list[0]} top2={list[1]} top3={list[2]} unit={unit} status={status} />}
        {!noActions && (
          <DropDown direction="left" triggerIcon={renderTriggerIcon}>
            <Option onClick={onEdit}>
              <img src={`${CDN_URL}/images/edit-white.svg`} alt="" />
              <span>Edit</span>
            </Option>
            <Option onClick={onRemove}>
              <img src={`${CDN_URL}/images/delete.svg`} alt="" />
              <span>Remove</span>
            </Option>
          </DropDown>
        )}
        {shareLeaderboardAction && total > 0 && (
          <S.ShareLeaderboardButtonContainer onClick={shareLeaderboardAction} data-tip="Share in a post">
            <ShareLeaderboardButton />
            <ReactTooltip effect="solid" className="consultation-tooltip" arrowColor="#2d2e2d" />
          </S.ShareLeaderboardButtonContainer>
        )}
      </S.BaseInforContainer>
      <Clients
        unit={unit}
        onLoad={onLoadClients}
        clientsData={clients}
        onlyTop3={onlyTop3}
        loadMoreOffset={loadMoreOffset}
      />
    </S.Wrapper>
  );
};
