import styled, { css } from 'styled-components';

export const ListWrapper = styled.div`
  max-height: 252px;
  overflow-y: hidden;

  ${props =>
    props.empty &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}

  ::-webkit-scrollbar {
    width: 4px !important;
  }
`;
export const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: relative;

  .list-dropdown {
    max-width: 134px;
    max-height: 48px;
    padding: 8px 0px;
    margin: 0;
    background: #2d2e2d;
    border-radius: 5px;

    &::after {
      content: '';
      position: absolute;
      top: -10px;
      left: 106px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #2d2e2d transparent;
    }
  }

  .list-dropdown-item {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 8px 58px 8px 18px;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
    }
  }

  .menu-item-dropdown-content {
    display: none;
    top: 26px;
    left: -100px;
    transition: opacity 0.3s ease-out;
  }

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          i.icon.grey {
            background: #e7ebee;
          }

          .menu-item-dropdown-content.open {
            display: block;
            opacity: 1;
            visibility: visible;
          }
        `}

  ${props =>
    props.isMe &&
    css`
      .list-dropdown::after {
        left: 102px;
      }

      .list-dropdown-item {
        gap: 8px;
        padding: 8px 33px 8px 15px;
      }

      .menu-item-dropdown-content {
        left: -96px;
      }
    `}
`;

export const ParticipantItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 15px;

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;

          .btn {
            cursor: not-allowed;
          }
        `
      : css`
          :hover {
            background: #fafbfc;

            .btn {
              background: #fafbfc;
            }

            i.icon {
              opacity: 1;
              &:hover {
                cursor: pointer;
              }
            }
          }
        `}

  ${props =>
    !props.searching &&
    css`
      :last-child {
        .menu-item-dropdown-content {
          top: -54px;

          .list-dropdown::after {
            top: 48px;
            transform: rotate(180deg);
          }
        }
      }
    `}

  .btn {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #5f5bca;
    min-width: 60px;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    margin-right: 10px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 30px;
    color: #263d52;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 200px;

    ${props =>
      props.isMe &&
      css`
        width: 175px;
      `}
  }

  ${ItemWrapper} {
    .menu-item-dropdown-content.open ~ i.icon {
      opacity: 1;
      background: #e7ebee;
      &.grey {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: #728096 !important;
      }
    }
  }

  i.icon {
    opacity: 0;

    &.grey {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      color: #728096 !important;
    }

    &:hover {
      background: #e7ebee;
    }
  }
`;

export const ItemLabel = styled.span`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #a0a6b2;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 5px 9px 0;
  border-bottom: 1px solid #e8e8e8;

  ${props =>
    props.isViewMore &&
    css`
      padding-bottom: 0;

      ${ListWrapper} {
        padding-bottom: 0;
        overflow-y: auto;

        @media screen and (max-width: 1440px) {
          min-height: calc(100vh - 395px);
          max-height: calc(100vh - 395px);
        }

        @media screen and (min-width: 1441px) {
          min-height: calc(100vh - 518px);
          max-height: calc(100vh - 518px);
        }
      }
    `}

  ${props =>
    props.hasScroll &&
    css`
      ${ParticipantItem} {
        padding-right: 11px;
      }
    `}

  .search-participants {
    padding: 0 11px 5px 15px;

    input {
      padding: 10px 12px 10px 34px;
      background-position: 10px center;
      background-color: #f7f8fc;
      border-radius: 4px;
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7e91;
      border: 1px solid #f7f8fc;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        border-color: #5e59ff;
        background-color: #fff;
      }

      ::placeholder {
        color: rgba(123, 126, 145, 0.6);
      }
    }

    svg {
      right: 28px;
      width: 15px;
      height: 15px;
      top: 44%;
    }
  }
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px 5px 15px;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    !props.isCollapse &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const SectionTitle = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #273d52;
`;

export const ActionImage = styled.div`
  width: 30px;
  height: 30px;
  background: #5f5bca;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 5px 0;
  padding-left: 15px;

  .btn {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #5f5bca;
    min-width: 60px;
  }

  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;

          .btn {
            cursor: not-allowed;
          }
        `
      : css`
          .btn {
            color: #7671e9;
            &:hover {
              cursor: pointer;
            }
          }
        `}
`;

export const NoResultsWrapper = styled.div`
  svg {
    margin-left: 50px;
    margin-bottom: 12px;

    @media screen and (min-width: 1441px) {
      margin-bottom: 40px;
    }
  }
`;
