import styled from 'styled-components';

export const UpgradePathIconTextButton = styled.div`
  &.upgrade-path-icon-text-button {
    background: unset;
    border: unset;
    padding: unset;
    margin: unset;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    > button {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      border: unset;
      padding: unset;
      background: unset;
    }

    img {
      width: 20px;
      height: 20px;
    }

    .upgrade-path-button-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #ed6e52;
    }
  }
`;
