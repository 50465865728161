import Request from 'configs/request';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { push } from 'connected-react-router';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

export const Types = {
  STUDIO_COLLECTIONS_CHANGE_QUERY_PARAMS: 'STUDIO_COLLECTIONS_CHANGE_QUERY_PARAMS',
  STUDIO_COLLECTIONS_LOADING_DATA: 'STUDIO_COLLECTIONS_LOADING_DATA',
  STUDIO_COLLECTIONS_SUCCESS_GET_LIST: 'STUDIO_COLLECTIONS_SUCCESS_GET_LIST',
  STUDIO_COLLECTIONS_FAILED_GET_LIST: 'STUDIO_COLLECTIONS_FAILED_GET_LIST',
  STUDIO_COLLECTIONS_SUCCESS_LOAD_MORE_LIST: 'STUDIO_COLLECTIONS_SUCCESS_LOAD_MORE_LIST',
  STUDIO_COLLECTIONS_SUCCESS_HIDE_BANNER: 'STUDIO_COLLECTIONS_SUCCESS_HIDE_BANNER',
  STUDIO_COLLECTION_SUCCESS_HIDE_GUIDE: 'STUDIO_COLLECTION_SUCCESS_HIDE_GUIDE',
  STUDIO_COLLECTIONS_LIST_RESET_DATA: 'STUDIO_COLLECTIONS_LIST_RESET_DATA',
  STUDIO_COLLECTION_SUCCESS_GET_DETAIL: 'STUDIO_COLLECTION_SUCCESS_GET_DETAIL',
  STUDIO_COLLECTION_SUCCESS_UPDATE: 'STUDIO_COLLECTION_SUCCESS_UPDATE',
  STUDIO_COLLECTION_SUCCESS_DELETE_COLLECTION: 'STUDIO_COLLECTION_SUCCESS_DELETE_COLLECTION',
  STUDIO_COLLECTION_RESET_DETAIL_DATA: 'STUDIO_COLLECTION_RESET_DETAIL_DATA',
  STUDIO_COLLECTIONS_UPDATE_WORKING_COLLECTION: 'STUDIO_COLLECTIONS_UPDATE_WORKING_COLLECTION',
  STUDIO_COLLECTION_ADD_RESOURCES_TO_REDUX: 'STUDIO_COLLECTION_ADD_RESOURCES_TO_REDUX',
  STUDIO_COLLECTION_REMOVE_RESOURCE_FROM_REDUX: 'STUDIO_COLLECTION_REMOVE_RESOURCE_FROM_REDUX',
  STUDIO_COLLECTION_UPDATE_SECTION_INFO: 'STUDIO_COLLECTION_UPDATE_SECTION_INFO',
  STUDIO_COLLECTION_MOVE_RESOURCE: 'STUDIO_COLLECTION_MOVE_RESOURCE',
  STUDIO_COLLECTION_RESET_CHHANGE_COLLECTION: 'STUDIO_COLLECTION_RESET_CHHANGE_COLLECTION',
  STUDIO_COLLECTION_SUCCESS_GET_CLIENTS: 'STUDIO_COLLECTION_SUCCESS_GET_CLIENTS',
  STUDIO_COLLECTION_ADD_CLIENT_TO_REDUX: 'STUDIO_COLLECTION_ADD_CLIENT_TO_REDUX',
  STUDIO_COLLECTION_REMOVE_CLIENT_TO_REDUX: 'STUDIO_COLLECTION_REMOVE_CLIENT_TO_REDUX',
  STUDIO_COLLECTION_RESET_CLIENT_AND_UPDATE_TO_REDUX: 'STUDIO_COLLECTION_RESET_CLIENT_AND_UPDATE_TO_REDUX',
  STUDIO_COLLECTION_SUCCESS_SAVE_CLIENTS: 'STUDIO_COLLECTION_SUCCESS_SAVE_CLIENTS',
  STUDIO_COLLECTION_SUCCESS_GET_ASSIGNED_CLIENTS: 'STUDIO_COLLECTION_SUCCESS_GET_ASSIGNED_CLIENTS',
  STUDIO_COLLECTION_GET_UPLOAD_URL_REQUEST: 'STUDIO_COLLECTION_GET_UPLOAD_URL_REQUEST',
  STUDIO_COLLECTION_GET_UPLOAD_URL_SUCCESS: 'STUDIO_COLLECTION_GET_UPLOAD_URL_SUCCESS',
  STUDIO_COLLECTION_UPLOAD_COVER_IMAGE_REQUEST: 'STUDIO_COLLECTION_UPLOAD_COVER_IMAGE_REQUEST',
  STUDIO_COLLECTION_UPLOAD_COVER_IMAGE_SUCCESS: 'STUDIO_COLLECTION_UPLOAD_COVER_IMAGE_SUCCESS',
  STUDIO_COLLECTIONS_CHANGE_SHARE_STATUS: 'STUDIO_COLLECTIONS_CHANGE_SHARE_STATUS',
};

export const changeStudioCollectionQueryParams = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioCollection: { query },
      },
    } = getState();

    const newData = { ...query, ...data };

    dispatch({
      type: Types.STUDIO_COLLECTIONS_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });
    dispatch(getListStudioCollection(newData));
  };
};

export const getListStudioCollection = (params, isLoading = false) => {
  return (dispatch, getState) => {
    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      const {
        rootReducer: {
          studioCollection: { query },
        },
      } = getState();

      queryParams = query;
    }

    queryParams.page = 1;

    if (isLoading) {
      dispatch({ type: Types.STUDIO_COLLECTIONS_LOADING_DATA });
    }

    return dispatch(
      Request.post(
        { url: '/api/studio-collection/v2/fetch-by-trainer', data: queryParams },
        true,
        response => {
          const { data, total } = response.data;
          dispatch({ type: Types.STUDIO_COLLECTIONS_SUCCESS_GET_LIST, payload: { data, total } });
        },
        error => {
          dispatch({ type: Types.STUDIO_COLLECTIONS_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const loadMoreStudioCollection = (isLoading = false) => {
  return (dispatch, getState) => {
    let queryParams;

    const {
      rootReducer: {
        studioCollection: { query },
      },
    } = getState();

    queryParams = query;
    queryParams.page = queryParams.page + 1;

    if (isLoading) {
      dispatch({ type: Types.STUDIO_COLLECTIONS_LOADING_DATA });
    }

    return dispatch(
      Request.post(
        { url: '/api/studio-collection/v2/fetch-by-trainer', data: queryParams },
        true,
        response => {
          const { data, total, page } = response.data;
          dispatch({ type: Types.STUDIO_COLLECTIONS_SUCCESS_LOAD_MORE_LIST, payload: { data, total, page } });
        },
        error => {
          dispatch({ type: Types.STUDIO_COLLECTIONS_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const hideStudioCollectionBanner = () => {
  return Request.post({ url: '/api/studio-collection/hide-banner' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.STUDIO_COLLECTIONS_SUCCESS_HIDE_BANNER });
  });
};

export const resetListData = () => ({ type: Types.STUDIO_COLLECTIONS_LIST_RESET_DATA });

export const getStudioCollectionDetail = collectionId => {
  return Request.post(
    { url: '/api/studio-collection/v2/get-detail', data: { collectionId, include_trainer_info: true } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_COLLECTION_SUCCESS_GET_DETAIL, payload: { data } });
      dispatch({ type: Types.STUDIO_COLLECTION_SUCCESS_GET_CLIENTS, payload: { data: data.clients } });
    },
  );
};

export const updateWorkingCollection = data => ({
  type: Types.STUDIO_COLLECTIONS_UPDATE_WORKING_COLLECTION,
  payload: { data },
});

export const getAssignedClients = collectionId => {
  return Request.post(
    { url: '/api/studio-collection/v3/get-assigned-clients', data: { collectionId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_COLLECTION_SUCCESS_GET_ASSIGNED_CLIENTS, payload: { data } });
    },
  );
};

export const addClientToRedux = clients => {
  return { type: Types.STUDIO_COLLECTION_ADD_CLIENT_TO_REDUX, payload: { data: clients } };
};

export const addResourcesToRedux = (sectionId, resources) => {
  return { type: Types.STUDIO_COLLECTION_ADD_RESOURCES_TO_REDUX, payload: { data: { sectionId, resources } } };
};

export const removeResourceFromRedux = (sectionId, resourceId) => {
  return { type: Types.STUDIO_COLLECTION_REMOVE_RESOURCE_FROM_REDUX, payload: { data: { sectionId, resourceId } } };
};

export const updateSectionInfo = (sectionId, params, isNew = false) => {
  return { type: Types.STUDIO_COLLECTION_UPDATE_SECTION_INFO, payload: { data: { sectionId, params, isNew } } };
};

export const moveResource = data => {
  return { type: Types.STUDIO_COLLECTION_MOVE_RESOURCE, payload: { data } };
};

export const removeClientFromRedux = client => {
  return { type: Types.STUDIO_COLLECTION_REMOVE_CLIENT_TO_REDUX, payload: { data: client } };
};

export const saveClientsAvailable = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioCollection: { availableClients, workingCollection },
      },
    } = getState();

    const collectionId = _.get(workingCollection, '_id');
    const clients = _.uniq(_.map(availableClients, '_id'));
    return dispatch(
      Request.post(
        {
          url: '/api/studio-collection/v2/add-clients',
          data: { collectionId, clients },
        },
        false,
        response => {
          dispatch({ type: Types.STUDIO_COLLECTION_SUCCESS_SAVE_CLIENTS });
        },
        error => {
          dispatch(getStudioCollectionDetail(collectionId));
        },
      ),
    );
  };
};

export const updateStudioCollection = (collectionId, params) => {
  return Request.post(
    { url: '/api/studio-collection/update', data: { collectionId, ...params } },
    false,
    (response, { dispatch, getState }) => {
      const { user } = getState();
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_COLLECTION_SUCCESS_UPDATE, payload: { data } });
      if (data.author !== user._id && data.share === TEAM_SHARE_PRIVATE) {
        dispatch(push('/home/studio-collection'));
      }
      toast('This collection has been updated successfully.');
    },
  );
};

export const duplicateStudioCollection = collection => {
  const body = {
    collectionId: collection._id,
    name: `Copy of ${collection.name}`,
  };
  if (body.name.length > 100) {
    body.name = _.truncate(body.name, { length: 100 });
  }
  return Request.post({ url: '/api/studio-collection/duplicate', data: body }, false, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch(push(`/home/studio-collection/${data._id}`));
  });
};

export const removeStudioCollection = collection => {
  const body = {
    collectionId: collection._id,
  };
  return Request.post(
    { url: '/api/studio-collection/remove-collection', data: body },
    false,
    (response, { dispatch }) => {
      dispatch({ type: Types.STUDIO_COLLECTION_SUCCESS_DELETE_COLLECTION, payload: { data: body } });
    },
  );
};

export const resetStudioCollectionDetail = () => ({ type: Types.STUDIO_COLLECTION_RESET_DETAIL_DATA });

export const resetChangeCollection = () => ({ type: Types.STUDIO_COLLECTION_RESET_CHHANGE_COLLECTION });

export const resetClientAndUpdateRedux = () => ({ type: Types.STUDIO_COLLECTION_RESET_CLIENT_AND_UPDATE_TO_REDUX });

export const confirmFirstTime = () => {
  return Request.put(
    {
      url: '/api/studio-collection/v2/got-it-add-cover-image',
      data: {
        is_hide_banner_studio_collection: true,
      },
    },
    false,
  );
};

export const getPreSignedUrl = fileName => {
  return dispatch => {
    dispatch({ type: Types.STUDIO_COLLECTION_GET_UPLOAD_URL_REQUEST });
    const data = { fileNames: [fileName] };
    return dispatch(
      Request.post(
        { url: '/api/file/gen-presigned-urls-studio-collection', data },
        true,
        (response, { dispatch }) => {},
      ),
    );
  };
};

export const uploadCoverSuccess = () => {
  return dispatch => {
    dispatch({ type: Types.STUDIO_COLLECTION_GET_UPLOAD_URL_SUCCESS });
  };
};

export const updateShareStatus = ({ params = {}, studioCollectionId } = {}) => (dispatch, getState) => {
  if ((!params.owner && params.share !== TEAM_SHARE_NOOWNER) || !studioCollectionId) return;
  const {
    user: { _id: userId, team_member = [] },
    rootReducer: {
      studioCollection: { list },
    },
  } = getState();

  return dispatch(
    Request.patch(
      {
        url: `/api/studio-collection/${studioCollectionId}/share`,
        data: params,
      },
      true,
      response => {
        if (((response.data || {}).data || {}).success) {
          let newList = [];
          if (params.owner === userId || params.share !== TEAM_SHARE_PRIVATE) {
            newList = [...list].map(it => {
              if (it._id === studioCollectionId) {
                const author_info = team_member.find(it => it._id === params.owner);
                return { ...it, share: params.share, author: params.owner, author_info };
              }
              return it;
            });
          } else {
            newList = [...list].filter(it => it._id !== studioCollectionId);
          }

          dispatch({
            type: Types.STUDIO_COLLECTIONS_CHANGE_SHARE_STATUS,
            payload: newList,
          });
        }
      },
    ),
  );
};
