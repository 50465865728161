import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import * as S from './style';
import TextEditable from 'shared/TextEditable';
import { ReactComponent as AddIcon } from 'assets/icons/add_grey.svg';
import { Button, FormGroup } from 'shared/FormControl';
import MediaIcon from 'assets/icons/media_icon.svg';
import MediaIconHover from 'assets/icons/media_icon_hover.svg';
import DocumentIcon from 'assets/icons/document_icon.svg';
import DocumentIconHover from 'assets/icons/document_icon_hover.svg';
import LinkIcon from 'assets/icons/link_icon.svg';
import LinkIconHover from 'assets/icons/link_icon_hover.svg';
import DropZonePlaceholder from 'shared/DropZonePlaceholder';
import { VALIDATIONS, ATTACHMENT_TYPES } from './helper';
import AddMedia from './AddMedia';
import DocumentAttachment from './DocumentAttachment';
import LinkAttachment from './LinkAttachment';

class GeneralTaskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingNote: false,
      attachmentType: null,
      file: null,
    };
    this.noteInput = React.createRef();
  }

  componentDidMount() {
    const { attachments, note } = this.props;
    const updatedStates = {};

    if (attachments && attachments.type && attachments.attachments) {
      updatedStates.attachmentType = attachments.type;
    }

    if (note) updatedStates.addingNote = true;

    if (!_.isEmpty(updatedStates)) {
      this.setState({ ...updatedStates });
    }
  }

  onToggleAddNote = () => {
    this.setState({ addingNote: true }, () => {
      if (this.noteInput.current) {
        this.noteInput.current.focus();
      }
    });
  };

  onDropfile = files => {
    if (files.length > 1) {
      return toast.error('Please upload one file at a time');
    }

    if (files.length) {
      const file = files[0];
      const { name, size } = file;
      const fileExtension = name.split('.').pop().toLowerCase();
      const validateObj = _.find(VALIDATIONS, obj => obj.extensions.includes(fileExtension));

      console.log('NEW', file[0]);

      if (!validateObj) {
        return toast.error('File type not supported.');
      } else {
        if (size > validateObj.maxSize) {
          return toast.error(validateObj.errorMessage);
        }
      }

      this.setState({ file, attachmentType: validateObj.type });
    }
  };

  onSelectAttachmentType = event => {
    const { type } = event.currentTarget.dataset;
    this.setState({ attachmentType: parseInt(type) });
  };

  onTurnOffAttachmentUI = () => {
    this.setState({ attachmentType: null, file: null }, () => {
      this.props.onChangeAttachments(null);
    });
  };

  onUpdateAttachmentData = data => {
    const { attachments } = this.props;
    this.props.onChangeAttachments({ ...attachments, type: this.state.attachmentType, ...data });
  };

  render() {
    const { addingNote } = this.state;
    const { note } = this.props;

    return (
      <S.GeneralTaskWrapper>
        {this.renderAttachmentUI()}
        <FormGroup largeMargin>
          {addingNote ? (
            <>
              <label>Note</label>
              <TextEditable
                value={note}
                onChange={this.props.onNoteChange}
                placeholder="Add Note"
                lineHeight={20}
                breakLine={true}
                ref={this.noteInput}
              />
            </>
          ) : (
            <Button onClick={this.onToggleAddNote} textOnly>
              <AddIcon />
              <span>Add note</span>
            </Button>
          )}
        </FormGroup>
      </S.GeneralTaskWrapper>
    );
  }

  renderAttachmentUI = () => {
    const { attachments } = this.props;
    const { attachmentType, file } = this.state;

    switch (attachmentType) {
      case ATTACHMENT_TYPES.MEDIA:
        return (
          <AddMedia
            onClose={this.onTurnOffAttachmentUI}
            onUpdateAttachmentData={this.onUpdateAttachmentData}
            file={file}
            attachments={attachments}
            updateUploadStatus={this.props.updateUploadStatus}
          />
        );

      case ATTACHMENT_TYPES.DOCUMENT:
        return (
          <DocumentAttachment
            onClose={this.onTurnOffAttachmentUI}
            attachments={attachments}
            onUpdateAttachmentData={this.onUpdateAttachmentData}
            file={file}
            updateUploadStatus={this.props.updateUploadStatus}
            onGetAttachment={this.props.onGetAttachment}
            taskId={this.props.taskId}
            shouldAllowDownload={this.props.shouldAllowDownload}
          />
        );

      case ATTACHMENT_TYPES.LINK:
        return (
          <LinkAttachment
            onClose={this.onTurnOffAttachmentUI}
            attachments={attachments}
            onUpdateAttachmentData={this.onUpdateAttachmentData}
            updateUploadStatus={this.props.updateUploadStatus}
          />
        );

      default:
        return (
          <FormGroup>
            <label>Attachment</label>
            <Dropzone onDrop={this.onDropfile}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <S.AttachmentTypeContainer {...getRootProps()}>
                    <S.TypeItem
                      key="media"
                      icon={MediaIcon}
                      hoverIcon={MediaIconHover}
                      data-type={ATTACHMENT_TYPES.MEDIA}
                      onClick={this.onSelectAttachmentType}
                    >
                      <div>Media</div>
                    </S.TypeItem>
                    <S.TypeItem
                      key="document"
                      icon={DocumentIcon}
                      hoverIcon={DocumentIconHover}
                      data-type={ATTACHMENT_TYPES.DOCUMENT}
                      onClick={this.onSelectAttachmentType}
                    >
                      <div>Document</div>
                    </S.TypeItem>
                    <S.TypeItem
                      key="link"
                      icon={LinkIcon}
                      hoverIcon={LinkIconHover}
                      data-type={ATTACHMENT_TYPES.LINK}
                      onClick={this.onSelectAttachmentType}
                    >
                      <div>Link</div>
                    </S.TypeItem>
                    <DropZonePlaceholder show={isDragActive} unknowFileType />
                  </S.AttachmentTypeContainer>
                );
              }}
            </Dropzone>
          </FormGroup>
        );
    }
  };
}

export default GeneralTaskForm;
