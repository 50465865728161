import React, { useEffect } from 'react';

// components
import Header from './Parts/Header';
import Content from './Parts/Content';

// common
import { saveLastLibraryRoute } from 'utils/commonFunction';

// assets
import * as S from './style';

function MetricGroupLibrary(props) {
  const { toggleModal, toggleSideBar } = props;

  useEffect(() => {
    toggleSideBar(true);
    saveLastLibraryRoute('/home/metric-group');
  }, []);

  return (
    <S.LibraryContainer>
      <Header toggleModal={toggleModal} />
      <Content />
    </S.LibraryContainer>
  );
}

export default MetricGroupLibrary;
