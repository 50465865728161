import styled, { css } from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';

export const Wrapper = styled.div`
  padding: 36px 27px 121px 28px;
  width: 100%;
  overflow: auto;
`;

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
`;

export const ContainerZapier = styled.div`
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.hasKey ? 'start' : 'center')};
`;

export const ProgressBarContainer = styled.div`
  padding: 30px;
  width: 100%;
  border-top: 1px solid #d9d9d9;
`;

export const ChangePlanContainer = styled.div`
  padding: 30px;
  width: 100%;
  /* TODO V2 */
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-end;
`;

export const LogoZapierWrapper = styled.div`
  img {
    width: 86px;
    height: 23px;
  }
`;

export const LeftContainer = styled.div``;

export const RightContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ResetKeyButton = styled.button`
  color: #5158cf;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 0;
  background: 0;
  padding: 0;
  margin-left: 78px;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
    text-decoration-line: underline;
  }
`;

export const CopyKeyButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 0;
  background: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
    background: #f0f1ff;
    svg path {
      stroke: #5158cf;
    }
  }
`;

export const DescriptionContainer = styled.div`
  margin-top: ${props => (props.hasKey ? '11px' : '7px')};
  color: #202353;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 452px;
`;

export const APILabel = styled.div`
  color: #6a778a;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const GetKeyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  margin-top: 30px;
  ${props =>
    props.hasKey &&
    css`
      margin-top: 0px;
    `}
`;

export const SecretKeyContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: ${props => (props.hasKey ? `386px` : `245px`)};
  height: 36px;
  padding: ${props => (props.hasKey ? `8px 14px` : `8px 20px`)};
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  border: 1px solid #dadfea;
  background: #f9f9fb;
  margin-left: 25px;

  .tooltip-copy-zapier {
    width: 140px;
    min-width: 120px;
    height: 36px;
    text-align: center;
    margin-top: -5px !important;
  }
`;

export const SecretKey = styled.span`
  color: #000;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  max-width: 328px;
  ${props =>
    props.emptyKey &&
    css`
      opacity: 0.2;
    `}
`;

export const UpgradePathAutoflowButton = styled(UpgradePathButton)`
  background: linear-gradient(44deg, #fdc830 0%, #f37335 100%);
  border-radius: 3px;
  display: flex;
  border: none;
  align-items: center;
  text-align: center;
  width: 141px;
  height: 36px;
  padding: 10px 24px;
  margin-left: 10px;
  justify-content: center;

  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
  span {
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize !important;
  }
`;

export const Question = styled.div`
  color: #202353;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

export const ChangePlanButton = styled(Button)`
  background: linear-gradient(44deg, #fdc830 0%, #f37335 100%);
  border-radius: 3px;
  display: flex;
  border: none;
  width: 141px;
  height: 36px;
  padding: 10px 24px;
  margin-left: 10px;

  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize !important;

  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const GuideLink = styled.a`
  color: #6456c4;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
    color: #6456c4;
  }

  .learn-more-icon {
    margin: 0px 8px 0px 8px;
    path {
      fill: #6456c4;
    }
  }
`;

export const FeatureLearnMore = styled.a`
  position: relative;
  padding-left: 9px;
  top: 2px;
  display: inline-flex;

  :hover {
    color: #6456c4 !important;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    path {
      fill: #6456c4;
    }
  }
`;

export const FeatureLinkTitle = styled.span`
  color: #6456c4;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 17px;
  position: relative;
  bottom: 2px;
  left: 1px;
`;

export const WarningConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    border-radius: 8px !important;
    width: 467px !important;
  }
  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }

  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    opacity: 0.9;
  }

  .ui.button.confirm-yes-button {
    background-color: #5158cf !important;
    box-shadow: none !important;
    padding: 4px 24px;
    border-radius: 5px;
  }

  .ui.button.confirm-no-button {
    min-width: 102px !important;
    padding: 4px 24px;
    border-radius: 5px;
  }

  .ui.button.confirm-no-button,
  .ui.button.confirm-yes-button {
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  .confirm-content-header {
    padding: 30px 30px 0 !important;

    .confirm-header-label {
      color: #000000;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .confirm-content-body {
    padding: 15px 30px 30px !important;

    .confirm-content-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  .confirm-header-image {
    width: 25px;
    height: 25px;
  }
`;
