import React from 'react';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { CDN_URL } from 'constants/commonData';
import { FoodJournalsFallBackWrapper } from '../style';

export default function FoodJournalsFallBack(props) {
  return (
    <FoodJournalsFallBackWrapper>
      <div className="fallback__content">
        <div className="fallback__contentTitle">Easy-baked nutrition coaching</div>
        <div className="fallback__contentDescription">
          Encourage clients’ positive eating habits. Leave likes and timely feedback for a personalized nutrition
          coaching experience.
        </div>
        <UpgradePathButton />
      </div>
      <div className="fallback__image">
        <img src={`${CDN_URL}/images/upgrade_path_food_journal.png`} alt="upgrade_path_program.png" />
      </div>
    </FoodJournalsFallBackWrapper>
  );
}
