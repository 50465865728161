import React from 'react';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

const DiscardChangeTriggerModal = props => {
  const { onConfirm } = props;

  const handleConfirm = () => {
    typeof onConfirm === 'function' && onConfirm();
  };

  return (
    <S.CustomModal
      isPressEsc
      noBorder
      hasCloseIcon
      hasHoverState
      headerIcon={`${CDN_URL}/images/alert_warning.svg`}
      title="Discard Changes?"
      content="Are you sure you want to go? Changes have not been saved yet."
      confirmButtonTitle="Discard changes"
      className="discard-change-trigger-modal"
      onConfirm={handleConfirm}
    />
  );
};

export default DiscardChangeTriggerModal;
