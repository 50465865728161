import styled from 'styled-components';

export const HeaderIcon = styled.div`
  flex: 0 0 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  background: ${props => props.backgroundColor || 'transparent'} url(${props => props.icon}) no-repeat center;
  background-size: auto;
`;

export const Header = styled.div`
  padding: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-word;
`;

export const Content = styled.div`
  padding: 0 30px 30px;
  flex: 1 1;
  overflow: auto;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
`;

export const Actions = styled.div`
  padding: 0 30px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 5px;
    padding: 6px 30px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
