import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'shared/FormControl';
import moment from 'moment';
import { markAsCompleted, fetchCompletedTask, fetchFollowingTask, fetch } from 'redux/personal-task-reminder/action';
import { CDN_URL } from 'constants/commonData';

const NotificationMessage = ({ closeToast, task, markAsCompleted, fetchCompletedTask, fetchFollowingTask, fetch }) => {
  const handleMarkAsFinish = () => {
    try {
      markAsCompleted(task._id).then(() => {
        fetchCompletedTask();
        fetchFollowingTask();
        fetch();
      });

      closeToast();
    } catch {
      closeToast();
    }
  };

  return (
    <div className="container_wrapper">
      <div className="title"> {task.title} </div>
      <div className="time">
        <img src={`${CDN_URL}/images/clock_gray.svg`} height={13} alt="" />
        &nbsp; &nbsp;
        <div>
          {moment(task.date.date, 'MM DD YYYY').format('MMM DD')}
          {` - ${task.date.time}`}
        </div>
        &nbsp; &nbsp;
        <div className="badge"> Due Now</div>
      </div>
      <div className="button">
        <Button className="dismiss_button" onClick={closeToast} grey>
          Dismiss
        </Button>
        &nbsp;
        <Button className="mark_button" purple onClick={handleMarkAsFinish}>
          Mark As Finish
        </Button>
      </div>
    </div>
  );
};

export default connect(null, { markAsCompleted, fetchCompletedTask, fetchFollowingTask, fetch })(NotificationMessage);
