// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Avatar from 'react-avatar';
import get from 'lodash/get';
import { CDN_URL } from 'constants/commonData';

// Actions
import { axiosInstance } from 'configs/request';
import { toggleConfirmModal } from 'actions/modal';
import { setAsDefaultOnboardingFlow } from 'redux/onboarding-flow/actions';

// Components
import ConfirmModal from '../ConfirmModal';

// Utils
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import { SET_AS_DEFAULT_LIST } from '../constants';
import { isTrainer } from 'utils/validations';

// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/MealPlans/action_arrow_right.svg';

// Styles
import * as S from './style';

const SHOW_UP_FOUR = 4;
const SHOW_UP_THREE = 3;

const SetAsDefaultOverview = ({
  user,
  isEnabled,
  listTrainer,
  cloudfrontList,
  isLoadingAction,
  isLoadingSetting,
  onboardingFlowId,
  isEnabledWorkspace,
  toggleConfirmModal,
  setAsDefaultOnboardingFlow,
}) => {
  const visibleCount = listTrainer.length <= SHOW_UP_FOUR ? SHOW_UP_FOUR : SHOW_UP_THREE;
  const remainingCount = listTrainer.length - SHOW_UP_THREE;
  const remainingCoaches = listTrainer.slice(SHOW_UP_THREE);

  const [isLoadingMyDefault, setIsLoadingMyDefault] = useState(false);
  const [isLoadingWorkspaceDefault, setIsLoadingWorkspaceDefault] = useState(false);
  const [myDefaultData, setDefaultData] = useState({});
  const [workspaceDefaultData, setWorkspaceDefaultData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSetDefault, setSelectedSetDefault] = useState(SET_AS_DEFAULT_LIST[0]);

  const alreadyMyDefault = useMemo(() => `${(myDefaultData || {}).name}`, [myDefaultData]);
  const alreadyWorkspaceDefault = useMemo(() => `${(workspaceDefaultData || {}).name}`, [workspaceDefaultData]);

  const notUsingKey = SET_AS_DEFAULT_LIST[0].key;
  const workspaceDefaultKey = SET_AS_DEFAULT_LIST[1].key;
  const myDefaultKey = SET_AS_DEFAULT_LIST[2].key;
  const isTrainerRole = isTrainer(user);

  useEffect(() => {
    getMyDefaultOnboardingFlow();
    getWorkspaceOnboardingFlow();

    setSelectedSetDefault(
      isEnabled ? SET_AS_DEFAULT_LIST[2] : isEnabledWorkspace ? SET_AS_DEFAULT_LIST[1] : SET_AS_DEFAULT_LIST[0],
    );
  }, [isEnabled, isEnabledWorkspace]);

  // Get workspace default
  const getWorkspaceOnboardingFlow = () => {
    setIsLoadingWorkspaceDefault(true);

    axiosInstance({
      url: 'api/onboarding-flows/get-default-by-team',
      method: 'GET',
    })
      .then(response => {
        const { data } = get(response, 'data');
        setWorkspaceDefaultData(data || {});
        setIsLoadingWorkspaceDefault(false);
      })
      .catch(error => {
        setWorkspaceDefaultData({});
        setIsLoadingWorkspaceDefault(false);
      });
  };

  // Get my default
  const getMyDefaultOnboardingFlow = () => {
    setIsLoadingMyDefault(true);

    axiosInstance({
      url: 'api/onboarding-flows/default',
      method: 'GET',
    })
      .then(response => {
        const { data } = get(response, 'data');
        setDefaultData(data || {});
        setIsLoadingMyDefault(false);
      })
      .catch(error => {
        setDefaultData({});
        setIsLoadingMyDefault(false);
      });
  };

  const getMessageConfirmModal = key => {
    switch (key) {
      case myDefaultKey:
        return {
          title: 'Set as Default Onboarding Flow',
          content:
            'Setting this onboarding flow as default will apply it to all new user signups, except for clients who activate a package associated with another onboarding flow. Would you like to continue?',
          headerIcon: `${CDN_URL}/images/alert_star.svg`,
        };
      case workspaceDefaultKey:
        return {
          title: 'Set as Workspace Default Onboarding Flow',
          content:
            'Setting this onboarding flow as default will apply it to all new user signups, except for clients who activate a package associated with another onboarding flow. Would you like to continue?',
          headerIcon: `${CDN_URL}/images/alert_star.svg`,
        };
      case notUsingKey:
        return {
          title: isEnabled ? 'Remove Your Default Onboarding Flow' : 'Remove Workspace Default Onboarding Flow',
          content:
            'This onboarding flow is no longer automatically applied to new user signups. Existing clients who have access to its assets will not be affected. Would you like to continue?',
          headerIcon: `${CDN_URL}/images/trash-circle.svg`,
        };
      default:
        return {
          title: '',
          content: '',
          headerIcon: '',
        };
    }
  };

  const handleSetAsDefault = item => {
    const { key } = item || {};
    if (isLoadingSetting || isLoadingAction) return;

    typeof setAsDefaultOnboardingFlow === 'function' && setAsDefaultOnboardingFlow(onboardingFlowId, key);
    setSelectedSetDefault(item);
  };

  const handleSelectSetAsDefault = item => {
    const { key } = item || {};
    if (isLoadingMyDefault || isLoadingWorkspaceDefault || selectedSetDefault.key === key) return;

    if (
      (!isEnabled && !isEmpty(myDefaultData) && key === myDefaultKey) ||
      (!isEnabledWorkspace && !isEmpty(workspaceDefaultData) && key === workspaceDefaultKey) ||
      (isEnabled && key === workspaceDefaultKey) ||
      (isEnabledWorkspace && key === myDefaultKey)
    ) {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title={
            key === myDefaultKey ? 'Change Your Default Onboarding Flow' : 'Change Workspace Default Onboarding Flow'
          }
          content={
            (isEnabled && key === workspaceDefaultKey) || (isEnabledWorkspace && key === myDefaultKey) ? (
              <>
                This change will make this onboarding flow automatically assigned to all new client signups, except for
                the packages that are specified with other onboarding flows. Would you like to continue?
              </>
            ) : (
              <>
                This will apply the new onboarding flow to all new user signups. The current{' '}
                {key === myDefaultKey ? 'default' : 'workspace default'} onboarding flow{' '}
                <S.Bold>{key === myDefaultKey ? alreadyMyDefault : alreadyWorkspaceDefault}</S.Bold> will be replaced
                with this onboarding flow. Would you like to continue?
              </>
            )
          }
          headerIcon={`${CDN_URL}/images/alert_recycle.svg`}
          confirmBtnTitle="Change Default"
          className={classNames('multiple-popup-yellow ', { 'extra-large-width': workspaceDefaultKey === key })}
          onConfirm={() => handleSetAsDefault(item)}
          onClose={() => toggleConfirmModal(false)}
          hasCloseIcon={false}
        />,
      );
    } else {
      const { title, content, headerIcon } = getMessageConfirmModal(key);
      toggleConfirmModal(
        true,
        <ConfirmModal
          title={title}
          content={content}
          headerIcon={headerIcon}
          confirmBtnTitle="Yes"
          className={classNames({
            'multiple-popup-purple': [myDefaultKey, workspaceDefaultKey].includes(key),
            'extra-width': workspaceDefaultKey === key,
            'multiple-popup-red remove-popup': notUsingKey === key,
          })}
          onConfirm={() => handleSetAsDefault(item)}
          onClose={() => toggleConfirmModal(false)}
          width={isEnabledWorkspace ? '507px' : undefined}
        />,
      );
    }
  };

  const getStrCoachesName = () => {
    let fullNames = [];

    for (const item of remainingCoaches) {
      const { full_name = '', first_name = '', last_name = '' } = item || {};
      const fullName = full_name || `${first_name || ''} ${last_name || ''}`.trim();

      fullNames.push(fullName);
    }

    if (!fullNames || fullNames.length === 0) {
      return '';
    }

    const fullNameStr = fullNames.slice(0, -1).join(', ');
    const lastItem = fullNames[fullNames.length - 1];

    return `${fullNameStr}, ${lastItem}`;
  };

  const renderAvatars = () => {
    if (isEmpty(listTrainer)) return <></>;

    return (
      <S.AvatarWrapper>
        {(listTrainer || []).slice(0, visibleCount).map(item => {
          const { _id = '', full_name = '', first_name = '', last_name = '', color } = item || {};

          const fullName = full_name || `${first_name || ''} ${last_name || ''}`.trim();
          const isShowYou = _id === (user || {})._id;

          return (
            <S.AvatarContainer key={item._id}>
              <div data-tip data-for={`coach-${_id}`}>
                <Avatar
                  color={color}
                  name={getUserShortName(item)}
                  className="avatar"
                  size="32"
                  src={convertS3UrlToCloudFrontUrl((item || {}).avatar, cloudfrontList, true)}
                />
              </div>
              <ReactTooltip
                className="app-tooltip set-as-default-tooltip"
                id={`coach-${_id}`}
                effect="solid"
                place="top"
              >
                <p>{isShowYou ? 'You' : fullName}</p>
              </ReactTooltip>
            </S.AvatarContainer>
          );
        })}
        {visibleCount === SHOW_UP_THREE && (
          <S.AvatarContainer>
            <S.AvatarRemaining data-tip data-for="coaches">
              +{remainingCount}
            </S.AvatarRemaining>
            <ReactTooltip className="app-tooltip set-as-default-tooltip" id="coaches" effect="solid" place="top">
              <p>{getStrCoachesName()}</p>
            </ReactTooltip>
          </S.AvatarContainer>
        )}
      </S.AvatarWrapper>
    );
  };

  return (
    <S.Wrapper isOnlyView={isTrainerRole && isEnabledWorkspace}>
      <S.LeftSide>
        <S.HeaderWrapper>
          <S.Header>Set as default Onboarding Flow</S.Header>
          {/* {isTeamAdmin(user) && renderAvatars()} */}
        </S.HeaderWrapper>
        <S.Description>Select a default option for this onboarding flow.</S.Description>
      </S.LeftSide>
      <S.RightSide>
        {isTrainerRole && isEnabledWorkspace ? (
          <span className="label">{SET_AS_DEFAULT_LIST[1].label}</span>
        ) : (
          <S.SelectWrapper>
            <S.SelectedItem
              isDisabled={isLoadingAction || isLoadingSetting}
              isOpen={isOpen}
              onClick={() => {
                if (isLoadingAction || isLoadingSetting) return;
                setIsOpen(true);
              }}
            >
              <span className="label">{selectedSetDefault.label}</span>
              <ArrowIcon className="arrow-icon" />
            </S.SelectedItem>
            {isOpen && (
              <RootCloseWrapper event="click" onRootClose={() => setIsOpen(false)} disabled={!isOpen}>
                <S.Dropdown>
                  {SET_AS_DEFAULT_LIST.map(item => {
                    if (isTrainerRole && item.key === workspaceDefaultKey) return null;

                    return (
                      <span
                        key={item.key}
                        className={classNames('option', { 'is-active': item.key === selectedSetDefault.key })}
                        onClick={() => handleSelectSetAsDefault(item)}
                      >
                        {item.label}
                      </span>
                    );
                  })}
                </S.Dropdown>
              </RootCloseWrapper>
            )}
          </S.SelectWrapper>
        )}
      </S.RightSide>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    cloudfrontList,
    rootReducer: {
      onboardingFlow: { workingSetting, isLoadingAction, isLoadingSetting, workingData },
    },
  } = state;

  return {
    user,
    cloudfrontList,
    listTrainer: get(workingSetting, 'trainer_default_settings', []).reverse(),
    onboardingFlowId: get(workingSetting, 'onboarding_flow', ''),
    isEnabledWorkspace: get(workingData, 'is_default_team', false),
    isLoadingAction,
    isLoadingSetting,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  setAsDefaultOnboardingFlow: bindActionCreators(setAsDefaultOnboardingFlow, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetAsDefaultOverview);
