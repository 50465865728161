import React from 'react';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { showSuccess } from 'redux/modal/modal.actionCreators';
import { onSubmitting, onSubmitted } from 'actions/submitStatus';
import { convertFiltersToClientParams, getQueryParamsFromObject, isTeamAdmin, mediaLog } from 'utils/commonFunction';
import { getOnboardingCheckList } from 'redux/onboarding/actions';
import { getAllSegments } from 'redux/segment/actions';
import Request from 'configs/request';
import { ONBOARDING_STEPS, METRIC_SETTING_MODULE } from 'constants/commonData';
import InviteClientSuccess from 'components/InviteClientSuccess';
import clientFakeData from './fakeData';
import { axiosInstance } from 'configs/request';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getGroupMetrics } from 'actions/groupMetric';
import { getNormalMetricChartDataLatestByDay, updateSleepView } from 'actions/bodyMetric';
import { checkIsConnectedApp } from 'components/BodyMetricProgressNew/AskToConnectButton';

export const Types = {
  UPDATE_CLIENT_PROFILE_REQUEST: 'UPDATE_CLIENT_PROFILE_REQUEST',
  UPDATE_CLIENT_PROFILE_SUCCESS_LOAD_IMAGE: 'UPDATE_CLIENT_PROFILE_SUCCESS_LOAD_IMAGE',
  UPDATE_CLIENT_PROFILE_SUCCESS: 'UPDATE_CLIENT_PROFILE_SUCCESS',
  INVITE_TO_CONNECT_SUCCESS: 'INVITE_TO_CONNECT_SUCCESS',
  UPDATE_CLIENT_CONNECTED_INTEGRATION_SUCCESS: 'UPDATE_CLIENT_CONNECTED_INTEGRATION_SUCCESS',
  RESET_WORKING_CLIENT_DATA: 'RESET_WORKING_CLIENT_DATA',
  GET_CLIENT_DATA: 'GET_CLIENT_DATA',
  ERROR_GET_CLIENT_PROFILE: 'ERROR_GET_CLIENT_PROFILE',
  SUCCESS_GET_CLIENT_PROFILE: 'SUCCESS_GET_CLIENT_PROFILE',
  UPDATE_CLIENT_AVATAR_SUCCESS: 'UPDATE_CLIENT_AVATAR_SUCCESS',
  CLIENTS_SELECTED: 'CLIENTS_SELECTED',
  RESET_CLIENTS_SELECT_STATE: 'RESET_CLIENTS_SELECT_STATE',
  ARCHIVE_CLIENTS: 'ARCHIVE_CLIENTS',
  SUCCESS_ARCHIVE_CLIENTS: 'SUCCESS_ARCHIVE_CLIENTS',
  FAILED_ARCHIVE_CLIENTS: 'FAILED_ARCHIVE_CLIENTS',
  SUCCESS_ADD_NEW_CLIENT: 'SUCCESS_ADD_NEW_CLIENT',
  ADD_MULTIPLE_CLIENTS: 'ADD_MULTIPLE_CLIENTS',
  SUCCESS_ADD_MULTIPLE_CLIENTS: 'SUCCESS_ADD_MULTIPLE_CLIENTS',
  FAILED_ADD_MULTIPLE_CLIENTS: 'FAILED_ADD_MULTIPLE_CLIENTS',
  SEARCH_CLIENTS: 'SEARCH_CLIENTS',
  CLIENT_GET_ASSIGNED_PROGRAMS: 'CLIENT_GET_ASSIGNED_PROGRAMS',
  CLIENT_REMOVE_ASSIGNED_PROGRAM: 'CLIENT_REMOVE_ASSIGNED_PROGRAM',
  UN_ARCHIVED_MULTIPLE_CLIENTS: 'UN_ARCHIVED_MULTIPLE_CLIENTS',
  DELETE_MULTIPLE_CLIENTS: 'DELETE_MULTIPLE_CLIENTS',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  ARCHIVE_ONE_CLIENT: 'ARCHIVE_ONE_CLIENT',
  SUCCESS_ARCHIVE_ONE_CLIENT: 'SUCCESS_ARCHIVE_ONE_CLIENT',
  FAILED_ARCHIVE_ONE_CLIENT: 'FAILED_ARCHIVE_ONE_CLIENT',
  SUCCESS_GET_NEWEST_CLIENTS: 'SUCCESS_GET_NEWEST_CLIENTS',
  CLIENT_LIST_SUCCESS_BULK_RESEND_INVITATION: 'CLIENT_LIST_SUCCESS_BULK_RESEND_INVITATION',
  CLIENT_LIST_FALIED_BULK_RESEND_INVITATION: 'CLIENT_LIST_FALIED_BULK_RESEND_INVITATION',
  CLIENT_SUCCESS_GET_METRICS: 'CLIENT_SUCCESS_GET_METRICS',
  CLIENT_SUCCESS_GET_METRICS_SETTING_ALL: 'CLIENT_SUCCESS_GET_METRICS_SETTING_ALL',
  CLIENT_REQUEST_GET_METRICS: 'CLIENT_REQUEST_GET_METRICS',
  CLIENT_SUCCESS_UPDATE_METRIC: 'CLIENT_SUCCESS_UPDATE_METRIC',
  CLIENT_REQUEST_UPDATE_MULTIPLE_METRIC: 'CLIENT_REQUEST_UPDATE_MULTIPLE_METRIC',
  CLIENT_SUCCESS_UPDATE_MULTIPLE_METRIC: 'CLIENT_SUCCESS_UPDATE_MULTIPLE_METRIC',
  CLIENT_SUCCESS_ADD_METRIC: 'CLIENT_SUCCESS_ADD_METRIC',
  CLIENT_SUCCESS_DELETE_METRIC: 'CLIENT_SUCCESS_DELETE_METRIC',
  CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRIES: 'CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRIES',
  CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRY: 'CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRY',
  CLIENT_SUCCESS_UPDATE_BODY_METRIC_ENTRY: 'CLIENT_SUCCESS_UPDATE_BODY_METRIC_ENTRY',
  CLIENT_SUCCESS_REMOVE_BODY_METRIC_ENTRY: 'CLIENT_SUCCESS_REMOVE_BODY_METRIC_ENTRY',
  CLIENT_SUCCESS_REMOVE_ALL_METRIC_ENTRY_BY_DAY: 'CLIENT_SUCCESS_REMOVE_ALL_METRIC_ENTRY_BY_DAY',
  CLIENT_SET_TOTAL_ACTIVE_CLIENTS: 'CLIENT_SET_TOTAL_ACTIVE_CLIENTS',
  CLIENT_CONSULTATION_FILE_UPLOAD_URL_SUCCESS: 'CLIENT_CONSULTATION_FILE_UPLOAD_URL_SUCCESS',
  CLIENT_CONSULTATION_FILE_UPLOAD_URL_REQUEST: 'CLIENT_CONSULTATION_FILE_UPLOAD_URL_REQUEST',
  CLIENT_CONSULTATION_FILE_UPLOAD_SUCCESS: 'CLIENT_CONSULTATION_FILE_UPLOAD_SUCCESS',
  CLIENT_CONSULTATION_FILE_UPLOAD_REQUEST: 'CLIENT_CONSULTATION_FILE_UPLOAD_REQUEST',
  CLIENT_CONSULTATION_FILE_DELETE_REQUEST: 'CLIENT_CONSULTATION_FILE_DELETE_REQUEST',
  CLIENT_CONSULTATION_FILE_DELETE_SUCCESS: 'CLIENT_CONSULTATION_FILE_DELETE_SUCCESS',
  // SUBCOẠCH
  GET_SUB_COACH_REQUEST: 'GET_SUB_COACH_REQUEST',
  GET_SUB_COACH_SUCCESS: 'GET_SUB_COACH_SUCCESS',
  GET_SUB_COACH_FAIL: 'GET_SUB_COACH_FAIL',
  ADD_SUB_COACH_REQUEST: 'ADD_SUB_COACH_REQUEST',
  ADD_SUB_COACH_SUCCESS: 'ADD_SUB_COACH_SUCCESS',
  ADD_SUB_COACH_FAIL: 'ADD_SUB_COACH_FAIL',
  DELETE_SUB_COACH_REQUEST: 'DELETE_SUB_COACH_REQUEST',
  DELETE_SUB_COACH_SUCCESS: 'DELETE_SUB_COACH_SUCCESS',
  DELETE_SUB_COACH_FAILED: 'DELETE_SUB_COACH_FAILED',

  METRIC_UPDATED_SETTING_SUCCESS: 'METRIC_UPDATED_SETTING_SUCCESS',
};

const updateProfileSuccess = data => ({
  type: Types.UPDATE_CLIENT_PROFILE_SUCCESS,
  payload: { client: data },
});

const inviteConnectSuccess = data => ({ type: Types.INVITE_TO_CONNECT_SUCCESS, data });

const updateClientConnectedIntegration = isConnected => ({
  type: Types.UPDATE_CLIENT_CONNECTED_INTEGRATION_SUCCESS,
  payload: { isConnected },
});

const updateClientAvatarSuccess = avatar => ({ type: Types.UPDATE_CLIENT_AVATAR_SUCCESS, avatar });

const successInviteCLient = client => ({ type: Types.SUCCESS_ADD_NEW_CLIENT, payload: { client } });

export const resetWorkingClientData = () => ({ type: Types.RESET_WORKING_CLIENT_DATA });

export const selectClients = (clients, checked) => ({
  type: Types.CLIENTS_SELECTED,
  payload: { clients, checked },
});

export const resetSelectState = () => ({ type: Types.RESET_CLIENTS_SELECT_STATE });

export const searchClients = search => ({ type: Types.SEARCH_CLIENTS, payload: { search: search } });

export const inviteNormalClient = (
  client,
  type,
  send_mail,
  onSuccess,
  assign_to_id,
  onboarding_flow_id,
  onboarding_flow_type,
) => {
  const { first_name = '', last_name = '', email } = client;
  const params = {
    client_info: { first_name: first_name.trim(), last_name: last_name.trim(), email, client_type: type },
    send_mail,
    assign_to: assign_to_id,
    onboarding_flow: onboarding_flow_id === 'not_using' ? null : onboarding_flow_id,
    onboarding_flow_type,
  };

  return (dispatch, getState) => {
    dispatch(onSubmitting('INVITE_CLIENT'));
    const { user } = getState();

    const isTrainer = !isTeamAdmin(user);

    const omittedParams = isTrainer ? _.omit(params, 'assign_to') : params;

    return dispatch(
      Request.post(
        { url: '/api/trainer/invite', data: omittedParams },
        true,
        result => {
          if (typeof onSuccess === 'function') {
            onSuccess(result.data);
          }

          const { data } = result.data;

          dispatch(onSubmitted('INVITE_CLIENT', true));
          dispatch(successInviteCLient(data));
          dispatch(toggleModal(false));

          const {
            rootReducer: {
              onboarding: { hideFeature, checkList },
            },
          } = getState();

          if (!hideFeature) {
            // dispatch(getNewestClients());

            const unfinished = _.find(checkList, item => item.type === ONBOARDING_STEPS.ADD_CLIENT && !item.state);
            dispatch(getOnboardingCheckList(!!unfinished));
          }

          dispatch(
            toggleModal(true, <InviteClientSuccess clientData={data} onClose={() => dispatch(toggleModal(false))} />),
          );
        },
        error => {
          dispatch(onSubmitted('INVITE_CLIENT', false));
          dispatch({ type: 'ERROR_INVITE_REGULAR', error });
        },
      ),
    );
  };
};

export const removeClient = clientId => {
  return Request.delete(
    { url: `/api/trainer/client/${clientId}` },
    true,
    (result, { dispatch }) => {
      dispatch(toggleModal(false));
      dispatch(showSuccess('This client has been deleted successfully', 'Success!'));
      dispatch(push('/home/client'));
      dispatch({ type: Types.DELETE_CLIENT_SUCCESS });
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_REMOVE_CLIENT', error }),
  );
};

export const resendInvitationClient = (clientId, params = {}, displayWithSecondModal) => {
  return Request.post({ url: `/api/trainer/client/resend/${clientId}`, data: params }, true, (result, { dispatch }) => {
    if (params.invite_to_connect) {
      dispatch(inviteConnectSuccess({ clientId, ...params }));
    }

    dispatch(displayWithSecondModal ? toggleSecondModal(false) : toggleModal(false));
    toast('Invitation sent!');
  });
};

export const getClientIntegration = client => {
  return Request.get({ url: `/api/integration-source`, params: { client } }, true, (result, { dispatch }) => {
    const data = _.get(result, 'data.data', {});
    const isConnected = checkIsConnectedApp(data);
    dispatch(updateSleepView({ connectedApp: isConnected }));
    dispatch(updateClientConnectedIntegration(isConnected));
  });
};

export const getClientProfile = (clientId, updateStore = false) => {
  return dispatch => {
    dispatch({ type: Types.GET_CLIENT_DATA });
    dispatch(getClientIntegration(clientId));

    return dispatch(
      Request.get(
        { url: `/api/trainer/client_full/${clientId}` },
        true,
        result => {
          if (updateStore) {
            const client = result.data.data;
            dispatch({ type: Types.SUCCESS_GET_CLIENT_PROFILE, payload: { client } });
          }
        },
        error => dispatch({ type: Types.ERROR_GET_CLIENT_PROFILE, error }),
      ),
    );
  };
};

export const starClient = (data, isStar) => {
  return Request.put(
    { url: isStar ? '/api/profile/favorite' : '/api/profile/unfavorite', data },
    true,
    (r, { dispatch }) => {
      dispatch({ type: 'UPDATE_CLIENT_FAVORITE', payload: { isStar, client: data.user } });
    },
  );
};

export const getClientsSimpleData = () => {
  return Request.get(
    { url: '/api/trainer/clients_simple' },
    false,
    (result, { dispatch }) => dispatch({ type: 'SUCCESS_GET_CLIENTS_SIMPLE', payload: result.data }),
    (error, { dispatch }) => dispatch({ type: 'ERROR_GET_CLIENT_LIST_SIMPLE', error }),
  );
};

let lastSegmentRequestId;

export const getClientsFullData = (loading, params, segmentId) => {
  lastSegmentRequestId = segmentId;

  return (dispatch, getState) => {
    dispatch({ type: 'IS_GETTING_CLIENTS' });
    const currentState = getState();
    const { rootReducer } = currentState;
    let queryParams = Object.assign(
      {},
      _.pick(rootReducer.client, ['page', 'per_page', 'search', 'sorter', 'sort']),
      params,
    );

    params = Object.assign({}, queryParams, convertFiltersToClientParams(rootReducer.segment.filters), {
      segmentId,
    });

    return dispatch(
      Request.get(
        { url: `/api/client-management/client-list?${getQueryParamsFromObject(params)}` },
        !!loading,
        result => {
          if (!lastSegmentRequestId || lastSegmentRequestId === result.data.segmentId) {
            dispatch({ type: 'SUCCESS_GET_CLIENTS_FULL', payload: result.data });
          }
        },
        error => {
          dispatch({ type: 'ERROR_GET_CLIENT_LIST_FULL', error });
        },
      ),
    );
  };
};

export const getFakeClientData = (segment, num_clients) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SUCCESS_GET_CLIENTS_FULL',
      payload: {
        ...clientFakeData,
        all_clients_total: num_clients || 0,
        total_except_search: num_clients || 0,
        total: num_clients || 0,
        segmentId: segment,
      },
    });
  };
};

export const resendInvitationClientAndUpdateProfileThenUploadAvatar = (
  clientId,
  paramsInvite,
  paramsSave,
  uploadConfig,
  displayWithSecondModal,
) => {
  return Request.post(
    { url: `/api/trainer/client/resend/${clientId}`, data: paramsInvite },
    false,
    (result, { dispatch }) => {
      dispatch(inviteConnectSuccess({ clientId, ...paramsInvite }));
      dispatch(updateProfileThenUploadAvatar(paramsSave, uploadConfig, clientId, displayWithSecondModal, true));
    },
  );
};

export const updateProfileThenUploadAvatar = (
  params,
  uploadConfig,
  clientId,
  displayWithSecondModal,
  isAfterResendInvite = false,
) => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_CLIENT_PROFILE_REQUEST });

    return dispatch(
      Request.put(
        { url: `/api/trainer/client/${clientId}`, data: params },
        false,
        (result, { dispatch }) => {
          const { data } = result.data;
          const callback = () => {
            if (isAfterResendInvite) {
              dispatch(showSuccess('Your invitation has been sent successfully', 'Success!'));
            } else {
              dispatch(showSuccess('Profile has been saved', 'Success!'));
            }

            dispatch(displayWithSecondModal ? toggleSecondModal(false) : toggleModal(false));
          };

          if (data) {
            dispatch(updateProfileSuccess(data));
          }

          if (uploadConfig) {
            dispatch(uploadClientAvatar(uploadConfig, callback));
          } else {
            callback();
            dispatch({ type: Types.UPDATE_CLIENT_PROFILE_SUCCESS_LOAD_IMAGE });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: 'ERROR_UPDATE_CLIENT_PROFILE', error });
        },
      ),
    );
  };
};

export const uploadClientAvatar = (uploadConfig, callback) => {
  return Request.put(
    uploadConfig,
    false,
    (result, { dispatch }) => {
      dispatch({ type: Types.UPDATE_CLIENT_PROFILE_SUCCESS_LOAD_IMAGE });
      if ((uploadConfig || {}).url) {
        dispatch(updateClientAvatarSuccess(uploadConfig.url));
        mediaLog({
          status: 2,
          name: uploadConfig.url,
          description: 'Upload success file via Client Profile',
        });
      }

      typeof callback === 'function' && callback();
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_UPLOAD_CLIENT_AVATAR', error });
    },
  );
};

export const updateClientPreference = (clientId, unitData) => {
  return { type: 'UPDATE_REDUX_CLIENT_UNIT', payload: { client: clientId, unit: unitData } };
};

const archiveClientsSuccess = clients => ({ type: Types.SUCCESS_ARCHIVE_CLIENTS, payload: { clients } });

const archiveClientsFailed = () => ({ type: Types.FAILED_ARCHIVE_CLIENTS });

export const archiveClients = clients => {
  return dispatch => {
    dispatch({ type: Types.ARCHIVE_CLIENTS });

    return dispatch(
      Request.post(
        { url: '/api/trainer/client/archive', data: { clients } },
        true,
        result => {
          dispatch(archiveClientsSuccess(clients));
          // dispatch(getNewestClients());
        },
        error => {
          dispatch(archiveClientsFailed());
        },
      ),
    );
  };
};

export const archiveOneClient = clientId => {
  return dispatch => {
    let params = { clients: [clientId] };
    dispatch({ type: Types.ARCHIVE_ONE_CLIENT });

    return dispatch(
      Request.post(
        { url: '/api/trainer/client/archive', data: params },
        true,
        result => {
          dispatch(getAllSegments());
          dispatch({ type: Types.SUCCESS_ARCHIVE_ONE_CLIENT, payload: { clientId } });
          dispatch(showSuccess('This client has been archived successfully', 'Success!'));
          // dispatch(getNewestClients());
        },
        error => {
          dispatch(dispatch({ type: Types.FAILED_ARCHIVE_ONE_CLIENT, payload: { clientId } }));
        },
      ),
    );
  };
};

export const submitMultipleClients = ({
  data,
  autoClose = true,
  fromCSV = false,
  assignTo = null,
  onboarding_flow_id = null,
  onboarding_flow_type = undefined,
}) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const isTrainer = !isTeamAdmin(user);
    const dataFormat = data.map(it => {
      const formatItem = {
        ...it,
        client_info: {
          ...it.client_info,
          email: _.get(it, 'client_info.email', '').trim(),
          first_name: _.get(it, 'client_info.first_name', '').trim(),
          last_name: _.get(it, 'client_info.last_name', '').trim(),
        },
      };
      if (!isTrainer) {
        return {
          ...formatItem,
          assign_to: fromCSV ? _.get(assignTo, '_id') : _.get(it, 'assign_to._id'),
        };
      }

      return _.omit(formatItem, 'assign_to');
    });

    dispatch({ type: Types.ADD_MULTIPLE_CLIENTS });

    return dispatch(
      Request.post(
        {
          url: '/api/trainer/inviteBulk',
          data: {
            data: dataFormat,
            fromCSV,
            onboarding_flow: onboarding_flow_id === 'not_using' ? null : onboarding_flow_id,
            onboarding_flow_type,
          },
        },
        true,
        respose => {
          dispatch({ type: Types.SUCCESS_ADD_MULTIPLE_CLIENTS });

          if (autoClose) {
            dispatch(toggleModal(false));
            dispatch(
              showSuccess(
                'We will notify you via email when the upload is complete.',
                'Your request is being processed!',
              ),
            );
          }
        },
        error => {
          dispatch({ type: Types.FAILED_ADD_MULTIPLE_CLIENTS });
        },
      ),
    );
  };
};

export const getAssignedPrograms = client => {
  return Request.get(
    { url: '/api/program/v2/list_program', params: { client } },
    true,
    (result, { dispatch }) => {
      const { data } = result.data;
      const programs = _.map(data, item =>
        !item.program ? null : { ...item, startDate: moment(item.day, 'MM-DD-YYYY'), endDate: moment(item.end_date) },
      );
      dispatch({ type: Types.CLIENT_GET_ASSIGNED_PROGRAMS, payload: { programs } });
      return result.data;
    },
    (error, { dispatch }) => dispatch({ type: 'FAILED_CLIENT_GET_ASSIGNED_PROGRAMS', error }),
  );
};

export const removeAssignedProgram = assignId => {
  return Request.delete(
    { url: `/api/program/v2/delete/${assignId}`, method: 'delete' },
    true,
    (result, { dispatch }) => {
      dispatch({ type: Types.CLIENT_REMOVE_ASSIGNED_PROGRAM, payload: { assignId } });
      return result.data;
    },
    (error, { dispatch }) => {
      dispatch({ type: 'FAILED_CLIENT_REMOVE_ASSIGNED_PROGRAM', error });
    },
  );
};

export const activateClients = clients => {
  return Request.post(
    { url: '/api/trainer/client/unarchive', data: { clients } },
    true,
    (result, { dispatch }) => {
      dispatch({ type: Types.UN_ARCHIVED_MULTIPLE_CLIENTS });
      // dispatch(getNewestClients());
      return result.data;
    },
    (error, { dispatch }) => {
      dispatch({ type: 'FAILED_UN_ARCHIVED_MULTIPLE_CLIENTS', error });
    },
  );
};

export const deleteClients = clients => {
  return Request.post(
    { url: '/api/trainer/client/delete', data: { clients } },
    true,
    (result, { dispatch }) => {
      dispatch({ type: Types.DELETE_MULTIPLE_CLIENTS });
      // dispatch(getNewestClients());
      return result.data;
    },
    (error, { dispatch }) => {
      dispatch({ type: 'FAILED_DELETE_MULTIPLE_CLIENTS', error });
    },
  );
};

export const inviteToConnect = (clientId, email) => {
  const data = { invite_to_connect: true, email };

  return Request.post({ url: `/api/trainer/client/resend/${clientId}`, data }, false, (result, { dispatch }) => {
    dispatch(inviteConnectSuccess({ clientId, ...data }));
    toast('Invitation sent!');
  });
};

export const getNewestClients = (per_page = 5) => {
  const params = {
    page: 1,
    per_page,
    sorter: 'createdAt',
    sort: -1,
    is_archive: false,
  };

  return Request.get({ url: '/api/trainer/clients_new_list_simple', params }, false, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch({ type: Types.SUCCESS_GET_NEWEST_CLIENTS, payload: { data } });
  });
};

export const bulkSendInvitation = data => {
  return Request.post(
    { url: '/api/client-invitation/bulk-resend-invitation', data },
    true,
    (response, { dispatch }) => {
      dispatch({ type: Types.CLIENT_LIST_SUCCESS_BULK_RESEND_INVITATION });
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.CLIENT_LIST_FALIED_BULK_RESEND_INVITATION });
    },
  );
};

export const getClientMetrics = clientId => {
  return dispatch => {
    dispatch({
      type: Types.CLIENT_REQUEST_GET_METRICS,
    });
    return dispatch(
      Request.get(
        { url: '/api/body-metric/get-full-list-client-body-metric', params: { clientId } },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.CLIENT_SUCCESS_GET_METRICS, payload: { data } });
          dispatch(getAllClientMetricsSetting(clientId));
        },
      ),
    );
  };
};

export const getAllClientMetricsSetting = clientId => {
  return dispatch => {
    return dispatch(
      Request.get(
        { url: `/api/v2/body-metric-entry/chart-settings/${clientId}/all` },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          dispatch({ type: Types.CLIENT_SUCCESS_GET_METRICS_SETTING_ALL, payload: { data } });
        },
      ),
    );
  };
};

export const refreshTheOnboarding = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        onboarding: { hideFeature, checkList },
      },
    } = getState();

    if (!hideFeature) {
      const unfinished = _.find(checkList, item => item.type === ONBOARDING_STEPS.CUSTOMIZE_METRIC && !item.state);
      dispatch(getOnboardingCheckList(!!unfinished));
    }
  };
};

export const updateClientMetric = data => {
  const uniqueCode = (data || {}).unique_code;
  return (dispatch, getState) => {
    const {
      groupMetric: { selected, list, metrics },
    } = getState();
    return dispatch(
      Request.put(
        {
          url: `/api/body-metric/update-metric`,
          data: { ...data, module: METRIC_SETTING_MODULE.CLIENT },
        },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          const newMetric = data.find(metric => {
            return uniqueCode === (metric || {}).unique_code;
          });

          if (newMetric) {
            dispatch({
              type: Types.METRIC_UPDATED_SETTING_SUCCESS,
              payload: {
                metric: newMetric,
              },
            });
          }

          dispatch({ type: Types.CLIENT_SUCCESS_UPDATE_METRIC, payload: { data } });
          dispatch(refreshTheOnboarding());
        },
      ),
    );
  };
};

export const requestConsultationFileUploadUrl = (file, clientId) => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_CONSULTATION_FILE_UPLOAD_URL_REQUEST });

    return dispatch(
      Request.post(
        { url: '/api/file/gen-presigned-urls-consultation-document', data: { fileNames: [file.name] } },
        true,
        async (response, { dispatch }) => {
          dispatch({ type: Types.CLIENT_CONSULTATION_FILE_UPLOAD_URL_SUCCESS });
          const url = response.data.data[0];
          dispatch(showLoading());
          dispatch({ type: Types.CLIENT_CONSULTATION_FILE_UPLOAD_REQUEST });
          await uploadConsultationFile(file, url, clientId);
          const document_key = url.substring(url.indexOf('documents'), url.indexOf('?X-Amz-Algorithm'));
          const res = await setConsultationFile(document_key, clientId);
          const consultDocUrl = res.data.data.document_url;
          dispatch({ type: Types.CLIENT_CONSULTATION_FILE_UPLOAD_SUCCESS, payload: consultDocUrl });
          dispatch(hideLoading());
          toast('Consultation Document uploaded');
        },
      ),
    );
  };
};

export const deleteConsultationFile = clientId => {
  return dispatch => {
    dispatch({ type: Types.CLIENT_CONSULTATION_FILE_DELETE_REQUEST });
    dispatch(showLoading());
    return dispatch(
      Request.delete({ url: `/api/client-management/${clientId}/consultation-document` }, true, (res, { dispatch }) => {
        dispatch(hideLoading());
        toast(res.data.message);
        dispatch({ type: Types.CLIENT_CONSULTATION_FILE_DELETE_SUCCESS });
      }),
    );
  };
};

const uploadConsultationFile = async (fileToUpload, url, clientId) => {
  let uploadConfigs = {
    method: 'PUT',
    url: url,
    headers: { 'Content-Type': fileToUpload.type },
    data: fileToUpload,
  };

  await axiosInstance(uploadConfigs);
};

const setConsultationFile = async (document_key, clientId) => {
  let uploadConfigs = {
    method: 'PUT',
    url: `/api/client-management/${clientId}/consultation-document`,
    data: { document_key: document_key },
  };

  return await axiosInstance(uploadConfigs);
};

export const updateMultipleClientMetricRequest = () => ({
  type: Types.CLIENT_REQUEST_UPDATE_MULTIPLE_METRIC,
});

export const updateMultipleClientMetric = (data, shouldRefreshOnBoarding = true) => {
  const clientId = (data || {}).clientId;
  return Request.put(
    {
      url: `/api/body-metric/update-client-body-metric-setting`,
      data: { ...data, module: METRIC_SETTING_MODULE.CLIENT },
    },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.CLIENT_SUCCESS_UPDATE_MULTIPLE_METRIC, payload: { data } });
      dispatch(getGroupMetrics(clientId));
      if (shouldRefreshOnBoarding) {
        dispatch(refreshTheOnboarding());
      }
    },
  );
};

export const addClientMetric = data => {
  return Request.post(
    { url: `/api/body-metric/add-new-metric`, data: { ...data, module: METRIC_SETTING_MODULE.CLIENT } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.CLIENT_SUCCESS_ADD_METRIC, payload: { data } });
      dispatch(refreshTheOnboarding());
    },
  );
};

export const deleteClientMetric = data => {
  return Request.post(
    { url: `/api/body-metric/remove-metric`, data: { ...data, module: METRIC_SETTING_MODULE.CLIENT } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.CLIENT_SUCCESS_DELETE_METRIC, payload: { data } });
      dispatch(refreshTheOnboarding());
    },
  );
};

export const addSleepEntry = params => {
  return Request.post(
    { url: `/api/sleep-metric-entries`, data: { ...params, module: METRIC_SETTING_MODULE.CLIENT } },
    true,
    (response, { dispatch }) => {
      // const { data } = response.data;
      dispatch(updateChartAfterActionEntry(params.unique_code));
      dispatch(getGroupMetrics((params || {}).client));
    },
  );
};

export const removeSleepMetricEntry = (entryId, clientId) => {
  return Request.delete({ url: `/api/sleep-metric-entries/${entryId}` }, true, (response, { dispatch, getState }) => {
    const { bodyMetric } = getState();
    dispatch(updateChartAfterActionEntry(bodyMetric.unique_code));
    dispatch(getGroupMetrics(clientId));
  });
};

export const updateSleepMetricEntry = (entryId, data, clientId) => {
  return Request.patch(
    { url: `/api/sleep-metric-entries/${entryId}`, data },
    true,
    (response, { dispatch, getState }) => {
      const { bodyMetric } = getState();
      dispatch(updateChartAfterActionEntry(bodyMetric.unique_code));
      dispatch(getGroupMetrics(clientId));
    },
  );
};

export const addBodyMetricEntries = params => {
  return Request.post(
    { url: `/api/body-metric-entry/add-entries`, data: { ...params, module: METRIC_SETTING_MODULE.CLIENT } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(getGroupMetrics((params || {}).client));
      dispatch({ type: Types.CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRIES, payload: { data } });
    },
  );
};

export const addBodyMetricEntry = params => {
  return Request.post(
    { url: `/api/body-metric-entry/add-entry`, data: { ...params, module: METRIC_SETTING_MODULE.CLIENT } },
    true,
    (response, { dispatch }) => {
      dispatch(updateChartAfterActionEntry(params.unique_code));
      const { data } = response.data;
      dispatch(getGroupMetrics((params || {}).client));
      dispatch({ type: Types.CLIENT_SUCCESS_ADD_BODY_METRIC_ENTRY, payload: { data } });
    },
  );
};

export const updateBodyMetricEntry = params => {
  return Request.put(
    { url: `/api/body-metric-entry/update-entry`, data: { ...params, module: METRIC_SETTING_MODULE.CLIENT } },
    true,
    (response, { dispatch }) => {
      dispatch(updateChartAfterActionEntry(params.unique_code));
      const { data } = response.data;
      dispatch(getGroupMetrics((params || {}).client));
      dispatch({ type: Types.CLIENT_SUCCESS_UPDATE_BODY_METRIC_ENTRY, payload: { data } });
    },
  );
};

export const removeBodyMetricEntry = params => {
  return Request.post(
    { url: `/api/body-metric-entry/remove-entry`, data: { ...params, module: METRIC_SETTING_MODULE.CLIENT } },
    true,
    (response, { dispatch, getState }) => {
      const { bodyMetric } = getState();
      dispatch(updateChartAfterActionEntry(bodyMetric.unique_code));
      const { data } = response.data;
      dispatch(getGroupMetrics((params || {}).clientId));
      dispatch({ type: Types.CLIENT_SUCCESS_REMOVE_BODY_METRIC_ENTRY, payload: { data } });
    },
  );
};

export const removeAllMetricEntryByDay = params => {
  return Request.delete(
    { url: `/api/v2/body-metric-entry/delete-entries-by-day`, params },
    true,
    (response, { dispatch, getState }) => {
      const { bodyMetric } = getState();
      dispatch(updateChartAfterActionEntry(bodyMetric.unique_code));
      dispatch(getGroupMetrics((params || {}).client));
      dispatch({ type: Types.CLIENT_SUCCESS_REMOVE_ALL_METRIC_ENTRY_BY_DAY });
    },
  );
};

const updateChartAfterActionEntry = uniqueCode => {
  return (dispatch, getState) => {
    const currentState = getState();
    const {
      bodyMetric: { filterTime = {}, unique_code, metricSettings },
      rootReducer: { client },
    } = currentState;

    const timezone = moment.tz.guess();

    if (unique_code === uniqueCode) {
      const clientId = client.workingClientDetail ? client.workingClientDetail._id : undefined;

      const params = {
        data_point: (metricSettings || {}).data_point,
        period_group: filterTime.period_group,
        from_date: filterTime.from_date,
        to_date: filterTime.to_date,
        metric_code: unique_code,
        client: clientId,
        timezone,
      };
      dispatch(getNormalMetricChartDataLatestByDay(params));
    }
  };
};

export const getBodyMetricEntryHistory = params => {
  return Request.get({ url: '/api/body-metric-entry/get-entry-history', params }, false);
};

export const getChartDataLatestByDay = (params, isLoading = false) => {
  return Request.get({ url: '/api/v2/body-metric-entry/chart-data-latest-by-day', params }, isLoading);
};

export const getSleepChartDataLatestByDay = (params, isLoading = false) => {
  return Request.get({ url: '/api/sleep-metric-entries/chart-data', params }, isLoading);
};

export const setTotalActiveClients = value => ({ type: Types.CLIENT_SET_TOTAL_ACTIVE_CLIENTS, payload: value });

export const getClientListSubCoach = (clientId, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.GET_SUB_COACH_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/profile/sub-coach/${clientId}`,
        },
        true,
        response => {
          const { data = [] } = response.data;
          if (data) {
            dispatch({ type: Types.GET_SUB_COACH_SUCCESS, payload: data });
          }
          callback && callback();
        },
        () => {
          dispatch({ type: Types.GET_SUB_COACH_FAIL });
        },
      ),
    );
  };
};

export const clientAddSubCoach = (data, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        client: {
          subCoach: { data: listSubCoach },
        },
      },
      user: { team_member },
    } = getState();
    const selected = (team_member || []).filter(item => (data.dataSubCoach || []).includes(item._id));

    const selectedConvert = (selected || []).map(item => {
      return {
        trainer: {
          email: item.email,
          full_name: item.full_name,
          _id: item._id,
          avatar: item.avatar,
          color: item.color,
        },
      };
    });

    const dataSubCoach = selectedConvert.concat(listSubCoach);

    dispatch({ type: Types.ADD_SUB_COACH_REQUEST });
    return dispatch(
      Request.post(
        { url: '/api/profile/sub-coach', data: { client: data.clientId, sub_trainers: data.dataSubCoach } },
        true,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.ADD_SUB_COACH_SUCCESS,
              payload: dataSubCoach,
            });
            callback && callback();
          }
        },
        () => {
          dispatch({ type: Types.ADD_SUB_COACH_FAIL });
          callback && callback();
        },
      ),
    );
  };
};

export const clientRemoveSubCoach = (idClient, idTrainer, nameTrainer) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        client: {
          subCoach: { data },
        },
      },
    } = getState();

    const dataSubCoach = (data || []).filter(item => idTrainer !== item.trainer._id);

    dispatch({ type: Types.DELETE_SUB_COACH_REQUEST });

    return dispatch(
      Request.delete(
        { url: `/api/profile/sub-coach/${idClient}/remove/${idTrainer}` },
        true,
        response => {
          const { data } = response.data;
          if (data) {
            dispatch({
              type: Types.DELETE_SUB_COACH_SUCCESS,
              payload: dataSubCoach,
            });
            toast(`${nameTrainer || ''} has been removed`);
          }
        },
        () => {
          dispatch({ type: Types.DELETE_SUB_COACH_FAILED });
        },
      ),
    );
  };
};
