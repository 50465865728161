// Lib
import React, { useEffect, useState } from 'react';

// Shared
import HeaderMealPlan from './parts/Header';
import MealPlanContentList from './parts/ContentMealPlan';

// Constants
import { saveLastLibraryRoute } from 'utils/commonFunction';

import * as S from './style';

const MealPlans = props => {
  const { toggleSideBar, resetFilters, toggleModal, resetCurrentList, resetDetailMealPlan } = props;

  const [isShowDraft, setIsShowDraft] = useState(false);

  useEffect(() => {
    resetDetailMealPlan();

    toggleSideBar && toggleSideBar(true);
    saveLastLibraryRoute('/home/meal-plans');
    return () => {
      resetFilters && resetFilters();
      resetCurrentList && resetCurrentList();
      toggleModal(false);
    };
  }, []);

  return (
    <S.MealPlansWrapper>
      <HeaderMealPlan isShowDraft={isShowDraft} setIsShowDraft={setIsShowDraft} />
      <MealPlanContentList isShowDraft={isShowDraft} />
    </S.MealPlansWrapper>
  );
};

export default MealPlans;
