import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;

  svg {
    margin-right: 6px;
  }
`;

export const Note = styled.div`
  background: #f3f5f8;
  border-radius: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #4e5662;
  margin: 15px 0px;

  ul {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    li {
      margin-bottom: 6px;
    }
  }
`;

export const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  padding: 15px 0 30px;

  b {
    color: #000;
    font-weight: 600;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 5px;
  }
`;

export const Container = styled.div`
  padding: 30px;
  border-radius: 5px;
`;

export const LoadingClients = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 150px;

  .loading-text {
    margin-top: 17px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #202353;
    opacity: 0.5;
  }
`;

export const Wrapper = styled.div`
  padding: 20px 20px 15px 20px;

  .selectClient_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    margin-bottom: 24px;
    min-height: 32px;

    .selectClient_saveBtn {
      color: #5c5bbd;
      border: 1px solid #5c5bbd;
      box-sizing: border-box;
      border-radius: 5px;
      background: transparent;
    }
  }

  .selectClient__body {
    position: relative;
    max-height: 424px;
    overflow: auto;
    margin-right: -15px;
    padding-right: 15px;

    .selectClient__noResult {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
      margin-top: 150px;

      &--title {
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        color: #8989a0;
        margin-bottom: 12px;
      }

      &--description {
        font-weight: normal;
        font-size: 13px;
        line-height: 130%;
        text-align: center;
        color: #8989a0;
        max-width: 260px;
      }
    }
  }

  .title-clients {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 120%;
    text-transform: uppercase;
    color: #202353;
  }

  .list-clients {
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .header-sticky {
    position: sticky;
    top: 0;
    background: #fcfcff;
    line-height: 20px;
  }

  .scroll-sticky {
    position: sticky;
    top: 20px;
    background: #fcfcff;
    line-height: 20px;
    padding-top: 15px;
  }
`;

export const ClientItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: #ffffff;
  border: 1px solid #ebedf4;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  margin-bottom: 5px;

  .clientItem__info > a {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none !important;
    }
  }

  .clientItem__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #111111;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 275px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .clientItem__removeIcon {
    width: 16px;
    height: 16px;
    visibility: hidden;
    cursor: pointer;
    background: url(${CDN_URL}/images/trash.svg) no-repeat;

    :hover {
      background: url(${CDN_URL}/images/trash_hover.svg) no-repeat;
    }
  }

  &:hover {
    .clientItem__removeIcon {
      visibility: visible;
    }
  }
`;
