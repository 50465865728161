import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function CopyText(props) {
  const { value } = props;
  const copyInput = useRef();

  const onClick = () => {
    copyInput.current.focus();
    copyInput.current.select();

    try {
      const successful = document.execCommand('copy');

      if (successful && typeof props.onSuccess === 'function') {
        props.onSuccess();
      } else if (typeof props.onError === 'function') {
        props.onError();
      }
    } catch (err) {
      if (typeof props.onError === 'function') {
        props.onError();
      }
    }
  };

  return (
    <Container className="copy-text">
      {props.children({ onClick })}
      <CopyInput value={value} ref={copyInput} />
    </Container>
  );
}

CopyText.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  value: PropTypes.string.isRequired,
  trigger: PropTypes.node.isRequired,
};

export default CopyText;

const CopyInput = styled.textarea`
  resize: none;
  opacity: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  color: transparent;
  position: absolute;
  z-index: -1;

  ::selection {
    color: transparent;
    background-color: transparent;
  }
`;

const Container = styled.div`
  display: inline-flex;
  position: relative;
`;
