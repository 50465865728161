import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changePhotoCompareMode,
  dragDropPhoto,
  removePhotoCompare,
  updatePhotoZoom,
  openZoomSetting,
  resetChangePhotoCompareMode,
} from 'redux/progress-photo/actions';
import InformationUnit from '../InformationUnit';
import ItemCompare from '../ItemCompare';
import { handleConvertUnit } from '../../../helper';
import * as S from './style';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getListStyle = () => ({
  display: 'flex',
  overflow: 'auto',
  gap: '24px',
  justifyContent: 'center',
});
const ComparePhoto = ({
  comparePhotoList,
  changePhotoCompareMode,
  photoChangeCompare,
  dragDropPhoto,
  removePhotoCompare,
  weightMetric,
  bodymetricTypes,
  updatePhotoZoom,
  openZoomSetting,
  listPhotoZoom,
  resetChangePhotoCompareMode,
}) => {
  const fullInfo = !isEmpty(comparePhotoList[0]) && !isEmpty(comparePhotoList[1]);
  const isOpenZoomSetting =
    fullInfo &&
    (get(comparePhotoList, '[0].isOpenZoomSettingItem', false) ||
      get(comparePhotoList, '[1].isOpenZoomSettingItem', false));

  const onDragEnd = data => {
    if (!data.destination) {
      return;
    }
    const result = reorder(comparePhotoList, data.source.index, data.destination.index);
    dragDropPhoto && dragDropPhoto(result);
  };

  const handleChangePhoto = data => {
    changePhotoCompareMode && changePhotoCompareMode(data);
  };

  const handlePhotoCompare = (e, photo) => {
    e.stopPropagation();
    removePhotoCompare && removePhotoCompare(photo);
  };

  if (!get(comparePhotoList, '[0].measurementId', '') && !get(comparePhotoList, '[1].measurementId', '')) return null;

  return (
    <S.ComparePhotoWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <S.ComparePhoto
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {comparePhotoList.map((item, index) => {
                const {
                  bodyFat,
                  selectedPhoto = '',
                  weight,
                  selectedDate = '',
                  tag = '',
                  weightUnit,
                  isOpenZoomSettingItem,
                  measurementId,
                } = item;
                const isSelected = get(photoChangeCompare, 'selectedPhoto', '') === selectedPhoto;
                const weightConvert = handleConvertUnit(weight, weightUnit, bodymetricTypes);

                const itemPhotoZoom = listPhotoZoom.find(item => get(item, 'image', '') === selectedPhoto);
                const scale = get(itemPhotoZoom, 'scale', 0);

                return (
                  <Draggable
                    key={selectedPhoto}
                    draggableId={selectedPhoto}
                    index={index}
                    isDragDisabled={!fullInfo || isOpenZoomSetting}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <S.ItemPhotoWrapper choiceImg={!!isEmpty(item)}>
                          {!isEmpty(item) ? (
                            <>
                              <ItemCompare
                                isDragging={snapshot.isDragging}
                                handleChangePhoto={handleChangePhoto}
                                item={item}
                                isSelected={isSelected}
                                selectedPhoto={selectedPhoto}
                                tag={tag}
                                comparePhotoList={comparePhotoList}
                                handlePhotoCompare={handlePhotoCompare}
                                selectedDate={selectedDate}
                                updatePhotoZoom={updatePhotoZoom}
                                openZoomSetting={openZoomSetting}
                                isOpenZoomSettingItem={isOpenZoomSettingItem}
                                resetChangePhotoCompareMode={resetChangePhotoCompareMode}
                                scale={scale}
                              />
                              <InformationUnit
                                weight={weightConvert}
                                bodyFat={bodyFat}
                                clientMetrics={weightMetric}
                                measurementId={measurementId}
                                selectedDate={selectedDate}
                              />
                            </>
                          ) : (
                            <S.EmptyCompareImg>Choose images on the left panel to compare</S.EmptyCompareImg>
                          )}
                        </S.ItemPhotoWrapper>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </S.ComparePhoto>
          )}
        </Droppable>
      </DragDropContext>
    </S.ComparePhotoWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      progressPhoto: { comparePhotoList, photoChangeCompare, listPhotoZoom },
      client: { bodymetricTypes = [] },
    },
  } = state;

  const weightMetric = get(
    find(bodymetricTypes, it => it.unique_code === 'weight'),
    'unit',
    [],
  );

  return { comparePhotoList, photoChangeCompare, weightMetric, bodymetricTypes, listPhotoZoom };
};

const mapDispatchToProps = dispatch => ({
  changePhotoCompareMode: bindActionCreators(changePhotoCompareMode, dispatch),
  dragDropPhoto: bindActionCreators(dragDropPhoto, dispatch),
  removePhotoCompare: bindActionCreators(removePhotoCompare, dispatch),
  updatePhotoZoom: bindActionCreators(updatePhotoZoom, dispatch),
  openZoomSetting: bindActionCreators(openZoomSetting, dispatch),
  resetChangePhotoCompareMode: bindActionCreators(resetChangePhotoCompareMode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComparePhoto);
