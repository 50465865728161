import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 204px;
`;

export const SidebarTitle = styled.div`
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 18px;

  font-weight: 700;
  font-size: 16px;
`;

export const SidebarItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 4px 11px 0 10px;
  padding: 6px 14px;

  cursor: pointer;

  color: #202353;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;

  &.active {
    border-radius: 5px;
    background: #eaeaff;
  }
`;
