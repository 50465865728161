import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

// import icons
import { ReactComponent as NoResultFound } from 'assets/icons/workout_builder_no_result_found.svg';

import SectionItem from './SectionItem';
import LoadingItem from './LoadingItem';

const SectionList = ({ listItem, isLoading, loadMore, page, isSearching, hasMore }) => {
  const [element, setElement] = useState(null);
  const observer = useRef(
    new IntersectionObserver(
      entries => {
        const firstEntry = entries[0];

        if (firstEntry.isIntersecting && !isLoading & !isSearching) {
          handleLoadMore();
        }
      },
      { threshold: 1 },
    ),
  );

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  const handleLoadMore = () => {
    loadMore(page + 1);
  };

  return (
    <div className={classnames('workout-left-panel-content-listing sectionList')}>
      {isSearching && (
        <>
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
          <LoadingItem />
        </>
      )}
      {listItem.length === 0 && !isSearching && (
        <div div className="no-result-section">
          <NoResultFound />
          <div className="title">No result found</div>
        </div>
      )}
      {listItem.length !== 0 && !isSearching && (
        <>
          {listItem.map(data => (
            <SectionItem key={data._id} section={data} />
          ))}
          <div ref={setElement} className="bottomSentinal"></div>
        </>
      )}
      {isLoading && (
        <>
          <LoadingItem />
          <LoadingItem />
        </>
      )}
    </div>
  );
};

SectionList.propTypes = {};

export default connect()(SectionList);
