/**
 * @flow
 */

import React from 'react';
import { Form, Image } from 'semantic-ui-react';
import type { User } from 'types/model';
import type { Dispatch } from 'types/redux';
import GeneralButton from 'shared/GeneralButton';
import CheckBox from 'shared/CheckBox';
import diff from 'deep-diff';

import { CDN_URL } from 'constants/commonData';

import { TableContainer, TableWrapper } from './styles';
import './styles.scss';
import _ from 'lodash';

type Props = {
  dispatch: Dispatch,
};

type State = {};

export default class Notifications extends React.Component<Props> {
  state = {};

  componentDidMount() {
    this.props.getNotificationPreferences().then(result => {
      if (result && result.data && result.data.data) {
        this.setState({
          originNotification: _.sortBy(result.data.data, 'index'),
          editNotification: _.cloneDeep(_.sortBy(result.data.data, 'index')),
        });
      }
    });
    this.props.getEmailPreferences().then(result => {
      if (result && result.data && result.data.data) {
        this.setState({
          originEmail: result.data.data,
          editEmail: _.cloneDeep(result.data.data),
        });
      }
    });
  }

  renderNotificationSettings = () => {
    const { editNotification } = this.state;

    return (
      <TableWrapper>
        <TableContainer>
          <table border="0" cellSpacing="0" cellPadding="0" className="table-setting-notification">
            <thead>
              <tr>
                <th />
                <th>
                  <div>
                    <div className="title">In-app</div>
                    <div className="description">
                      (Activity that appears <br /> on Notifications log)
                    </div>
                  </div>
                </th>
                <th>
                  <div>
                    <div className="title">Push</div>
                    <div className="description">
                      (Notification alerts sent <br /> to your mobile app)
                    </div>
                  </div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {editNotification &&
                editNotification.map((item, idx) => {
                  return (
                    <tr>
                      <td>{item.title}</td>
                      <td align="center">
                        <div>
                          <CheckBox
                            checked={item.in_app}
                            onChange={() => {
                              let newEdit = editNotification.map(mItm => {
                                if (mItm._id === item._id) {
                                  mItm.in_app = !mItm.in_app;
                                }
                                return mItm;
                              });
                              this.setState({ editNotification: newEdit });
                            }}
                          />
                        </div>
                      </td>
                      <td align="center">
                        <CheckBox
                          checked={item.push_notification}
                          onChange={() => {
                            let newEdit = editNotification.map(mItm => {
                              if (mItm._id === item._id) {
                                mItm.push_notification = !mItm.push_notification;
                              }
                              return mItm;
                            });
                            this.setState({ editNotification: newEdit });
                          }}
                        />
                      </td>
                      <td align="center">
                        {/*<CheckBox checked={item.email} onChange={() => {
                      let newEdit = editNotification.map(mItm => {
                        if (mItm._id === item._id) {
                          mItm.email = !mItm.email;
                        }
                        return mItm;
                      })
                      this.setState({ editNotification: newEdit })
                    }} />*/}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </TableContainer>
      </TableWrapper>
    );
  };

  render() {
    let isDiffNotification = diff(this.state.originNotification, this.state.editNotification);
    let isDiffEmail = diff(this.state.originEmail, this.state.editEmail);
    return (
      <div className="accountdetails-container notification-settings">
        <div className="basic_info email_update">
          <div className="one">
            <Image width={17} height={17} src={`${CDN_URL}/images/email_updates.svg`} />
            <span>Email Updates</span>
          </div>
          <div className="three">
            <Form>
              {this.state.editEmail &&
                this.state.editEmail.map(item => {
                  return (
                    <Form.Group>
                      <Form.Field>
                        <CheckBox
                          title={item.title}
                          checked={item.state}
                          onChange={() => {
                            let newEdit = this.state.editEmail.map(mItm => {
                              if (mItm._id === item._id) {
                                mItm.state = !mItm.state;
                              }
                              return mItm;
                            });
                            this.setState({ editEmail: newEdit });
                          }}
                        />
                      </Form.Field>
                    </Form.Group>
                  );
                })}
            </Form>
          </div>
          <div className="save_area one">
            {isDiffEmail && (
              <div>
                <GeneralButton onClick={this.handleUpdateEmailPreferences.bind(this)}>Save</GeneralButton>
              </div>
            )}
          </div>
        </div>
        <div className="divider email-update" />
        <div className="basic_info notification">
          <div className="one">
            <Image width={17} height={17} src={`${CDN_URL}/images/notifications.svg`} />
            <span>Notifications</span>
          </div>
          <div className="three"></div>
          <div className="save_area one">
            {isDiffNotification && (
              <div>
                <GeneralButton onClick={this.handleUpdateNotificationPreferences.bind(this)}>Save</GeneralButton>
              </div>
            )}
          </div>
        </div>
        <div className="basic_info notification notification-table">
          <div className="one"></div>
          <div className="four">{this.renderNotificationSettings()}</div>
        </div>
      </div>
    );
  }

  handleUpdateEmailPreferences() {
    let postData = this.state.editEmail.map(item => {
      return {
        type: item.type,
        state: item.state,
      };
    });
    this.props.updateEmailPreferences({ types: postData }).then(result => {
      if (result && result.data && result.data.data) {
        this.setState({
          originEmail: result.data.data,
          editEmail: _.cloneDeep(result.data.data),
        });
      }
    });
  }

  handleUpdateNotificationPreferences() {
    let postData = this.state.editNotification.map(item => {
      return {
        type: item.type,
        in_app: item.in_app,
        email: item.email,
        push_notification: item.push_notification,
      };
    });
    this.props.updateNotificationPreferences({ types: postData }).then(result => {
      if (result && result.data && result.data.data) {
        this.setState({
          originNotification: _.sortBy(result.data.data, 'index'),
          editNotification: _.cloneDeep(_.sortBy(result.data.data, 'index')),
        });
      }
    });
  }
}
