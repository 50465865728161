import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { convertS3UrlToCloudFrontUrl, getResourceType } from 'utils/commonFunction';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { ReactComponent as Hyperlink } from 'assets/icons/hyperlink.svg';

import * as S from './style';

const CardThumbnail = ({
  resource,
  getS3presignedURL,
  showType,
  children,
  showPhoto = true,
  format,
  cloudfrontList,
}) => {
  const [thumbnail, setThumbnail] = useState('');

  const getPresignedURLs = async list => {
    try {
      let newUrl = convertS3UrlToCloudFrontUrl(list[0], cloudfrontList);
      if (!newUrl) {
        const response = await getS3presignedURL(list);
        newUrl = response[0];
      }
      setThumbnail(newUrl);
    } catch {}
  };

  useEffect(() => {
    resource.cover_image && getPresignedURLs([resource.cover_image]);
  }, []);

  return (
    <S.CardPhoto coverImage={showPhoto ? thumbnail : null} className={getResourceType(resource)} format={format}>
      <div>
        {showType && (
          <S.ResourceType coverImage={thumbnail}>
            {resource.source_type === 'link' ? <Hyperlink /> : resource.document_type}
          </S.ResourceType>
        )}
        {children}
      </div>
    </S.CardPhoto>
  );
};

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardThumbnail);
