import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as S from './styles';
import { CDN_URL, CONNECT_FORUM_TYPES } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';
import { get } from 'lodash';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import { CheckboxCircle } from 'shared/FormControl';
import { Button } from 'shared/FormControl';

export default function InviteModal({ toggleModal, selectedAutoflow, autoflowInvite, groupId }) {
  const originalInviteMode = CONNECT_FORUM_TYPES.ACTIVE;
  const ModalStyles = { width: '430px' };
  const [currInviteMode, setCurrInviteMode] = useState(originalInviteMode);
  const handleInviteModeCheckBox = value => {
    setCurrInviteMode(value);
  };
  const handleCloseAction = () => {
    toggleModal(false);
  };
  const handleInvite = () => {
    if (get(selectedAutoflow, 'forum', null)) {
      autoflowInvite(selectedAutoflow._id, groupId, currInviteMode).then(response => {
        toast(
          `${pluralize(
            `new client`,
            get(response, 'data.data.addedClient'),
            true,
          )} from the Autoflow have been added to this Forum`,
        );
      });
    } else {
      toast.error('Should connect group to autoflow before inviting clients'); // TODO: should confirm with QA
    }
  };
  return (
    <S.Modal
      open={true}
      onClose={handleCloseAction}
      closeOnDimmerClick={true}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
      style={ModalStyles}
    >
      <Modal.Header className="modal-header">
        <div>Invite Autoflow Clients</div>
      </Modal.Header>
      <Modal.Content className="modal-content">
        <div className="invite-description">
          You can invite only clients that are Active in the Autoflow, or you can invite all clients.
        </div>
        <CheckboxCircle
          value={CONNECT_FORUM_TYPES.ACTIVE}
          checked={currInviteMode === CONNECT_FORUM_TYPES.ACTIVE}
          label={
            <p className="checkbox-label">
              Invite <b>only Active Autoflow Clients ({selectedAutoflow.clients_count.active})</b> to the Forum
            </p>
          }
          className="invite-checkbox"
          onChange={handleInviteModeCheckBox}
        />
        <CheckboxCircle
          value={CONNECT_FORUM_TYPES.ALL}
          checked={currInviteMode === CONNECT_FORUM_TYPES.ALL}
          label={
            <p className="checkbox-label">
              Invite <b>All Autoflow Clients ({selectedAutoflow.clients_count.total})</b> to the Forum
            </p>
          }
          className="invite-checkbox"
          onChange={handleInviteModeCheckBox}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button gray className="actions-btn" onClick={handleCloseAction}>
          Cancel
        </Button>
        <Button
          purple
          className="actions-btn"
          onClick={handleInvite}
          disabled={currInviteMode === CONNECT_FORUM_TYPES.NONE}
        >
          Invite
        </Button>
      </Modal.Actions>
    </S.Modal>
  );
}
