// Libs
import React from 'react';

// Components
import TimeInput from '../TimeInput';
import NumberInput from '../NumberInput';

// Constants
import { ENUM_TIME_PREP, MAX_VALUE_MINUTE } from '../constants';

// Assets
import { ReactComponent as PrepIcon } from 'assets/icons/MealPlans/prep_icon.svg';
import { ReactComponent as CookingIcon } from 'assets/icons/MealPlans/cooking_icon.svg';
import { ReactComponent as ServingIcon } from 'assets/icons/MealPlans/serving_icon.svg';

// commonFunction
import { pluralize } from 'utils/commonFunction';

// Helper
import { convertTimeStringToArray, formatViewTime } from '../helper';

// Style
import * as S from './style';

const TimeInfo = props => {
  const {
    isEditMode,
    numberOfServing,
    onChangeNumberOfServings = () => {},
    onChangeTime,
    handlePlusSub = () => {},
    handleBlurNumberOfServing,
    PrepTime,
    PrepTimeText,
    CookingTime,
    CookingTimeText,
    error = {},
    isSubmittedPublish,
  } = props;

  const resultPrepTime = convertTimeStringToArray(PrepTimeText);
  const resultCookingTime = convertTimeStringToArray(CookingTimeText);

  const renderTimeDetail = () => {
    const { hour: hourPrep, minute: minutePrep } = resultPrepTime || {};
    const { hour: hourCooking, minute: minuteCooking } = resultCookingTime || {};
    return (
      <S.TimeDetail>
        <S.TimeDetailItem>
          <PrepIcon />
          <S.TimeTitle>Prep:</S.TimeTitle>
          <S.TimeValue>{formatViewTime(hourPrep, minutePrep)}</S.TimeValue>
        </S.TimeDetailItem>
        <S.TimeDetailItem>
          <CookingIcon />
          <S.TimeTitle>Cooking:</S.TimeTitle>
          <S.TimeValue>{formatViewTime(hourCooking, minuteCooking)}</S.TimeValue>
        </S.TimeDetailItem>
        <S.TimeDetailItem>
          <ServingIcon />
          <S.TimeTitle>{pluralize('Serving', numberOfServing)}:</S.TimeTitle>
          <S.TimeValue>{numberOfServing}</S.TimeValue>
        </S.TimeDetailItem>
      </S.TimeDetail>
    );
  };

  const validTime = CookingTime <= 0 && isSubmittedPublish && PrepTime <= 0;

  const validNumberOfServing = error && error.number_of_serving;

  return (
    <S.TimeInfoWrapper>
      {isEditMode ? (
        <S.Time>
          <S.TimeItem className={`${validTime && 'error'}`}>
            <TimeInput
              valueHour={resultPrepTime.hour}
              valueMinute={resultPrepTime.minute}
              label="Prep time"
              onChange={(e, time) => onChangeTime(e, time, ENUM_TIME_PREP.PREP_TIME)}
            />
            {validTime && <span className="error-title">Please add prep time either cooking time</span>}
          </S.TimeItem>
          <S.TimeItem className={`${validTime && 'error'}`}>
            <TimeInput
              valueHour={resultCookingTime.hour}
              valueMinute={resultCookingTime.minute}
              label="Cooking time"
              max={MAX_VALUE_MINUTE}
              onChange={(e, type) => onChangeTime(e, type, ENUM_TIME_PREP.COOKING_TIME)}
            />
          </S.TimeItem>
          <S.TimeItem className={`number-of-serving ${validNumberOfServing && 'error'}`}>
            <NumberInput
              value={numberOfServing}
              label="Number of servings"
              onChange={onChangeNumberOfServings}
              handlePlusSub={handlePlusSub}
              onBlur={handleBlurNumberOfServing}
            />
          </S.TimeItem>
        </S.Time>
      ) : (
        renderTimeDetail()
      )}
    </S.TimeInfoWrapper>
  );
};

export default TimeInfo;
