import React from 'react';
import _ from 'lodash';
import { Image } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import { Checkbox } from 'shared/FormControl';
import SearchInput from 'shared/SearchInput';
import GeneralButton from 'shared/GeneralButton';
import { CDN_URL } from 'constants/commonData';
import { Wrapper, MenuContainer, MenuBody, MenuFooter, MenuHeader, GroupButton } from './style';
import ManageGroups from 'components/ManageGroups';
import CreateGroup from 'components/CreateGroup';
import { getSelectedGroups, countSelected } from './helper';
import { ReactComponent as Plus } from 'assets/icons/new_plus_grey_sm.svg';
import { ReactComponent as Setting } from 'assets/icons/new_setting_grey.svg';

class ClientGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      textSearch: '',
      selectedGroups: [],
      countOriginSelected: countSelected(props.clients),
      isCreating: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { clients, groups } = this.props;
    const { open } = this.state;
    if ((open && !prevState.open) || groups.length !== prevProps.groups.length) {
      this.lastScroll = 0;
      const selectedGroups = getSelectedGroups(clients);
      this.setState({ textSearch: '', selectedGroups, countOriginSelected: countSelected(clients) });
    }
  }

  closePopup = () => this.setState({ open: false, textSearch: '' });

  applyChange = () => {
    const { clients, assignClients, updateSuccess, groups } = this.props;
    const { selectedGroups, countOriginSelected } = this.state;
    let unchecked_groups = [];

    _.forEach(countOriginSelected, (item, groupId) => {
      if (!item.state) {
        unchecked_groups.push(groupId);
      }
    });

    const data = {
      clients: _.map(clients, item => item._id),
      groups: selectedGroups,
      unchecked_groups,
    };

    _.remove(data.groups, gId => !_.find(groups, g => g._id === gId));
    _.remove(data.unchecked_groups, gId => !_.find(groups, g => g._id === gId));

    if (!data.unchecked_groups.length && !data.groups.length) {
      this.closePopup();
      return updateSuccess(null);
    }

    assignClients(data).then(response => {
      if (typeof updateSuccess === 'function') {
        updateSuccess({ ...response.data.data, clients: data.clients });
      }

      this.closePopup();
    });
  };

  createGroupInline = ({ name }) => {
    const { createGroup } = this.props;
    const { isCreating } = this.state;

    if (isCreating || !name) {
      return false;
    }

    this.setState({ isCreating: true });
    createGroup({ name })
      .then(response => {
        const group = response.data.data;
        let selectedGroups = this.state.selectedGroups.slice();
        selectedGroups.push(group._id);

        this.setState({
          selectedGroups,
          textSearch: '',
          isCreating: false,
        });
      })
      .catch(() => this.setState({ isCreating: false }));
  };

  onCreateGroupSuccess = group => {
    let selectedGroups = this.state.selectedGroups.slice();
    selectedGroups.push(group._id);
    this.setState({ selectedGroups });
  };

  onSelectGroup = (group, checked) => {
    const { selectedGroups, countOriginSelected } = this.state;
    const { clients } = this.props;
    let newSelected = selectedGroups.slice();
    let newCount = Object.assign({}, countOriginSelected);
    let countObj = countOriginSelected[group._id] ? Object.assign({}, countOriginSelected[group._id]) : null;

    if (countObj) {
      switch (countObj.state) {
        case 2:
          countObj.state = 0;
          newSelected.splice(selectedGroups.indexOf(group._id), 1);
          break;

        case 1:
          countObj.state = 2;
          newSelected.push(group._id);
          break;

        default:
          if (countObj.total === clients.length) {
            countObj.state = 2;
            newSelected.push(group._id);
          } else {
            countObj.state = 1;
          }

          break;
      }

      newCount[group._id] = countObj;
    } else {
      if (checked) {
        newSelected.push(group._id);
      } else {
        newSelected.splice(selectedGroups.indexOf(group._id), 1);
      }
    }

    this.setState({
      selectedGroups: newSelected,
      countOriginSelected: newCount,
    });
  };

  renderGroupItem = item => {
    const count = this.state.countOriginSelected;
    const { clients } = this.props;
    const { selectedGroups } = this.state;
    const isSelected = selectedGroups.includes(item._id);
    const countItem = count[item._id];
    const extendData =
      clients.length < 2 || !countItem || !countItem.state ? '' : countItem.state === 2 ? 'all' : countItem.total;

    return (
      <li key={item._id}>
        <Checkbox
          thirdState={countItem && countItem.state === 1}
          checked={isSelected}
          className="group-checkbox"
          onChange={event => this.onSelectGroup(item, event.target.checked)}
          title={
            <div className="group-checkbox__title">
              <div>
                <span>{item.name}</span>
              </div>
              {extendData && <div className="clients">({extendData})</div>}
            </div>
          }
        />
      </li>
    );
  };

  isChanged = () => {
    const { countOriginSelected, selectedGroups } = this.state;
    const { clients } = this.props;

    let changeOrigin = _.find(countOriginSelected, item => item.state !== item.originState);

    if (changeOrigin) {
      return true;
    }

    const originSelectedGroups = getSelectedGroups(clients);

    if (
      selectedGroups.length !== originSelectedGroups.length ||
      _.difference(selectedGroups, originSelectedGroups).length
    ) {
      return true;
    }

    return false;
  };

  renderBody = () => {
    const { textSearch } = this.state;
    const { groups } = this.props;
    const search = textSearch.trim().toLowerCase();
    const renderedList = search ? _.filter(groups, item => item.name.toLowerCase().includes(search)) : groups;

    return (
      <ul>
        {_.map(renderedList, item => this.renderGroupItem(item))}
        {search && !renderedList.length ? (
          <li>
            <div
              className="create-new-group-option"
              onClick={() => this.createGroupInline({ name: textSearch.trim() })}
            >
              <Image src={`${CDN_URL}/images/client_group_purple.svg`} width={12} />
              <span>Create "{textSearch.trim()}"</span>
            </div>
          </li>
        ) : null}
      </ul>
    );
  };

  handleOpenGroup = () => {
    const { clients, toggleModal } = this.props;
    const clientIds = _.map(clients, item => item._id);
    toggleModal(true, <CreateGroup clients={clients.length} clientIds={clientIds} />);
  };

  handleOpenManage = () => {
    const { toggleModal } = this.props;
    toggleModal(true, <ManageGroups />);
  };

  render() {
    const { open, textSearch } = this.state;
    const { toggleModal, clients, errorPopup, isModalOpen } = this.props;

    if (!clients || !clients.length) {
      return null;
    }

    return (
      <RootCloseWrapper event="click" disabled={!open || errorPopup || isModalOpen} onRootClose={this.closePopup}>
        <Wrapper className={open ? 'open' : ''}>
          <GroupButton
            title="Groups"
            img={`${CDN_URL}/images/group.svg`}
            onClick={() => {
              this.setState(prevState => ({ open: !prevState.open }));
            }}
          />
          {open && !errorPopup ? (
            <MenuContainer>
              <MenuHeader>
                <SearchInput
                  value={textSearch}
                  onChange={(evt, data) => this.setState({ textSearch: data.value })}
                  onAddOnIconClick={() => this.setState({ textSearch: '' })}
                  placeholder="Search"
                />
              </MenuHeader>
              <MenuBody>{this.renderBody()}</MenuBody>
              <MenuFooter>
                <div className="footer_left">
                  <GeneralButton onClick={this.handleOpenGroup} className="create-button" withImage>
                    <Plus />
                    <span>Create</span>
                  </GeneralButton>
                  <GeneralButton onClick={this.handleOpenManage} className="manage-button" withImage>
                    <Setting />
                    <span>Manage</span>
                  </GeneralButton>
                </div>
                {this.isChanged() ? (
                  <GeneralButton onClick={this.applyChange}>Apply</GeneralButton>
                ) : (
                  <div className="apply-button" />
                )}
              </MenuFooter>
            </MenuContainer>
          ) : null}
        </Wrapper>
      </RootCloseWrapper>
    );
  }
}

export default ClientGroup;
