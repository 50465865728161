import { INVOICE_STATUS, PURCHASE_STATUS, INVOICE_REFUND_STATUS } from 'constants/commonData';
import styled, { css } from 'styled-components';

// TODO: Seperate to Purchase, Invoice

export default styled.div`
  border-radius: 3px;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 4px 8px;
  ${props =>
    [PURCHASE_STATUS.ACTIVE, INVOICE_STATUS.PAID].includes(props.status) &&
    css`
      background: #eaf8ec;
      color: #2fb845;
    `}
  ${props =>
    [INVOICE_REFUND_STATUS.SUCCESSED, INVOICE_REFUND_STATUS.PENDING].includes(props.status) &&
    css`
      background: rgba(94, 89, 255, 0.08);
      color: #5e59ff;
      cursor: pointer;
    `}
  ${props =>
    [INVOICE_STATUS.OVERDUE, INVOICE_STATUS.FAILED].includes(props.status) &&
    css`
      background: rgba(234, 49, 74, 0.06);
      color: #ee5066;
    `}
    
  ${props =>
    [PURCHASE_STATUS.CANCEL, INVOICE_STATUS.SCHEDULED, PURCHASE_STATUS.PAUSED].includes(props.status) &&
    css`
      background: rgba(123, 126, 154, 0.1);
      color: #7b7e9a;
    `}

    ${props =>
    [PURCHASE_STATUS.TRIAL].includes(props.status) &&
    css`
      background: #ffb2241a;
      color: #ffb224; ;
    `}
`;

// TODO: Purchase Detail

export const StatusLabelOutline = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;

  width: 78px;
  max-width: 78px;
  height: 32px;
  max-height: 32px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  padding: 5px 12px;
  margin-right: 15px;

  ${props =>
    [PURCHASE_STATUS.ACTIVE, INVOICE_STATUS.PAID].includes(props.status) &&
    css`
      background: #ffffff;
      color: #2fb845;
      border: 1px solid #2fb845;
    `}

  ${props =>
    [INVOICE_REFUND_STATUS.SUCCESSED, INVOICE_REFUND_STATUS.PENDING].includes(props.status) &&
    css`
      background: #ffff;
      color: #5e59ff;
      cursor: pointer;
      border: 1px solid #5e59ff;
    `}

  ${props =>
    [INVOICE_STATUS.OVERDUE].includes(props.status) &&
    css`
      background: #ffff
      color: #ee5066;
      border: 1px solid #ee5066;
      text-transform: uppercase;
    `}

    ${props =>
    [INVOICE_STATUS.SCHEDULED].includes(props.status) &&
    css`
      background: #ffff
      color: #ee5066;
      border: 1px solid #ee5066;
    `}
  
  ${props =>
    [PURCHASE_STATUS.CANCEL].includes(props.status) &&
    css`
      background: #ffff;
      color: #ff4d4f !important;
      border: 1px solid #ff4d4f !important;
    `}

    ${props =>
    [PURCHASE_STATUS.PAUSED].includes(props.status) &&
    css`
      background: rgba(123, 126, 154, 0.1);
      color: #7b7e9a;
      border: 1px solid #7b7e9a;
    `}

    ${props =>
    [PURCHASE_STATUS.TRIAL].includes(props.status) &&
    css`
      background: #ffff;
      color: #ffb224;
      border: 1px solid #ffb224;
    `}
`;
