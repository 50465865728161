import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ItemMember from './ItemMember';
import { Modal, Button, Image } from 'semantic-ui-react';
import { getVoters } from 'redux/forum/actions';
import LoadingIndicator from 'shared/LoadingIndicator';
import debounce from 'lodash/debounce';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const PER_PAGE = 20;
const VotedDetailModal = props => {
  const { closeModal, voteDetail, cloudfrontList, getVoters } = props;
  const { percent = 0, votes = 0, title = '', optionId, pollId } = voteDetail || {};
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  const getVotersByPage = (page = 1) => {
    const params = {
      page: page,
      per_page: PER_PAGE,
      poll_id: pollId,
      option_id: optionId,
    };

    setLoading(true);
    getVoters(params)
      .then(res => {
        const { data = [], total } = res.data;
        const newData = page === 1 ? data : [...members, ...data];
        setMembers(newData);
        setLoading(false);
        const endPage = Math.ceil(total / PER_PAGE);
        setIsEnd(page >= endPage);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getVotersByPage();
  }, []);

  const handleCloseModal = () => {
    typeof closeModal === 'function' && closeModal();
  };

  const renderItemMember = (it, idx) => (
    <ItemMember key={`member-item-${it._id || idx}`} user={it} cloudfrontList={cloudfrontList} />
  );

  const handleScrollEnd = event => {
    const { clientHeight = 0, scrollHeight = 0, scrollTop = 0 } = event.target;
    const bottom = scrollHeight - scrollTop <= clientHeight + 10;
    if (bottom && !loading && !isEnd) {
      const currentPage = page + 1;
      getVotersByPage(currentPage);
      setPage(currentPage);
    }
  };
  const onScrollDebounce = debounce(handleScrollEnd, 300);

  return (
    <S.CustomModal
      open
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onClose={handleCloseModal}
    >
      <Modal.Header>
        <div className="header-title-container">
          <div className="title">{title}</div>
        </div>
        <div className="description">{`${percent}% - ${pluralize('vote', votes, true)} `}</div>
      </Modal.Header>
      <Modal.Content>
        <div className="member-container">
          {loading && page === 1 && (
            <div className="first-loading">
              <LoadingIndicator title="" className="loading-indicator" />
            </div>
          )}
          <div
            className="member-scroll-wrapper"
            onScroll={event => {
              event.persist();
              onScrollDebounce.call(null, event);
            }}
          >
            {!!members.length && members.map(renderItemMember)}
            {loading && page > 1 && (
              <div className="loading-wrapper">
                <LoadingIndicator title="" className="loading-indicator" />
              </div>
            )}
          </div>
        </div>
      </Modal.Content>
    </S.CustomModal>
  );
};

const mapStateToProps = state => ({
  cloudfrontList: state.cloudfrontList,
});

const mapDispatchToProps = {
  getVoters,
};

export default connect(mapStateToProps, mapDispatchToProps)(VotedDetailModal);
