import React from 'react';
import { components } from 'react-select';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as StudioProgramWarningIcon } from 'assets/icons/studio_program_warning_icon_gray.svg';

import * as S from './styles';

function CustomOptionModality(props) {
  const { data: { key, label, tooltip } = {} } = props;

  return (
    <components.Option {...props}>
      <S.ModalityOptionWrapper>
        <div className="label">{label}</div>
        {tooltip && (
          <>
            <StudioProgramWarningIcon className="icon" data-tip data-for={key} />
            <ReactTooltip place="top" type="dark" effect="solid" id={key} className="modality-tooltip">
              {tooltip}
            </ReactTooltip>
          </>
        )}
      </S.ModalityOptionWrapper>
    </components.Option>
  );
}

export default CustomOptionModality;
