// libs
import React from 'react';
import { components } from 'react-select';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';

// assets
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_cicre.svg';

const CustomDropdownIndicator = props => {
  const { innerProps, selectProps, hasValue } = props || {};
  const { isDisabled, onConfirmRemove, value } = selectProps || {};

  const handleRemove = () => {
    if (isDisabled) return;
    onConfirmRemove(props);
  };

  if (isDisabled) return null;

  if (hasValue && !(value || {}).is_none_option)
    return (
      <components.DropdownIndicator
        {...props}
        innerProps={{
          ...innerProps,
          onMouseDown: e => {
            e.stopPropagation();
            e.preventDefault();
          },
          onTouchEnd: e => {
            e.stopPropagation();
            e.preventDefault();
          },
        }}
      >
        <div className={classNames('custom-close-icon', { disabled: isDisabled })}>
          <CloseIcon onMouseDown={handleRemove} onTouchEnd={handleRemove} />
        </div>
      </components.DropdownIndicator>
    );

  return <Icon name="chevron down" className="custom-chevron-down" size="small" />;
};

export default CustomDropdownIndicator;
