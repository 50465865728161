import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const ExtendActions = styled.div`
  position: absolute;
  right: 30px;
  top: 38px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .__react_component_tooltip.app-tooltip-white {
    min-width: fit-content;
  }

  .ownershipWrapper {
    .action-menu__toggle-button {
      margin-right: 0;
      width: auto;
      border-radius: 5px;

      :hover {
        background: #f6f7fb;
      }

      .teammate-icon {
        width: 28px;
        height: 28px;
        border-radius: 5px;
      }
    }

    .ownership {
      right: -12px;

      &.action-menu__content {
        width: 312px;
        /* height: 228px; */

        .bg-transparent.bg-ownership.image-button {
          margin-bottom: 20px;
        }

        .field > .label {
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
          color: #6a778a;
          padding: 0;
          margin-bottom: 10px;
        }

        .ui.dropdown.ownership-dropdown {
          border: 1px solid #dcdcde !important;
          border-radius: 8px !important;
          font-weight: 600 !important;
          font-size: 13px !important;
          line-height: 18px !important;
        }
      }
    }

    &.open .ownership.action-menu__toggle-button {
      background: #f6f7fb;

      .teammate-icon {
        border: solid 1px #5a57cb;
        background: url(${CDN_URL}/images/teammate_purple.svg) center center no-repeat;
      }
    }
  }

  .dropdown-wrapper {
    margin-left: 10px;
  }

  .evf-dropdown {
    .evf-dropdown__option {
      padding: 0;
      width: 100%;
    }

    .evf-dropdown__menu {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .evf-dropdown__trigger {
      width: 28px;
      height: 28px;
      border-radius: 5px;

      .dots {
        background-color: #777c8f;
        width: 4px;
        height: 4px;

        :before {
          width: 4px;
          height: 4px;
          left: -6px;
        }

        :after {
          width: 4px;
          height: 4px;
          left: 6px;
        }
      }

      :hover {
        background-color: #ebedf2;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #ebedf2;

        .dots {
          background-color: #777c8f;
        }
      }
    }
  }
`;

export const Header = styled.div`
  position: relative;
  padding: 30px 135px 20px 20px;
  flex: 0;

  .text-editable__container {
    :not(.text-editable__container--active) {
      .text-editable__input {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        box-orient: vertical;
        overflow: hidden;
      }
    }
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid #dadfea;
  margin: 0 30px 20px;
`;

export const Content = styled.div`
  flex: 1 1;
  overflow: auto;
  scroll-behavior: smooth;
  margin-right: 10px;
  padding-bottom: 20px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const DescriptionContainer = styled.div`
  width: 100%;
`;

export const DescriptionAndBackgroundContainer = styled.div`
  padding: 0 20px 20px 30px;
  display: flex;
  justify-content: space-between;

  label {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #a6a7b9;
    display: block;
    padding-left: 10px;
    padding-bottom: 5px;
  }

  .text-editable__wrapper {
    .text-editable__container {
      .text-editable__input {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #202353;

        :empty::before {
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          color: #aeaebb;
          opacity: 1;
        }
      }

      :not(.text-editable__container--active) {
        .text-editable__input {
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          box-orient: vertical;
          overflow: hidden;
        }
      }

      &.text-editable__container--active {
        .text-editable__input {
          min-height: 20px;
        }
      }
    }
  }
`;

export const SectionListContainer = styled.div`
  padding: 0;
`;

export const ActionFixedContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 38px;
  padding: 10px 35px;
  background-image: url(${CDN_URL}/images/arrow_up_purple.svg),
    linear-gradient(180deg, rgba(112, 114, 221, 0) 0%, #7072dd2b 100%);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 5;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  .btn-actions {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease;
  }

  :hover {
    background-color: #ffffff;
    background-image: none;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    opacity: 1;

    .btn-actions {
      max-height: 100px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .workoutError--noExercise {
    background: rgba(234, 49, 74, 0.05);
    border-radius: 4px;
    display: block;
    padding: 8px 15px;
    margin-top: 15px;
    margin-bottom: -7px;
  }

  input.invalid {
    border-color: #ea314a;
    background-color: #fef4f6;
  }

  .mainPanel__sections {
    padding-bottom: 30px;
  }

  &.scrolling {
    ${Header} {
      padding-top: 7px;
      padding-bottom: 7px;

      .text-editable__input {
        font-size: 14px;
        line-height: 19px;
      }

      :before {
        content: '';
        position: absolute;
        left: 30px;
        right: 35px;
        bottom: 0;
        height: 1px;
        background-color: #dadfea;
      }
    }

    ${ExtendActions} {
      top: 11px;
    }

    ${Content} {
      padding-bottom: 20px;
    }
  }
`;

export const BlockSelectSection = styled.div`
  padding: 0px 20px 30px 30px;
  display: flex;
  align-items: flex-end;
`;

export const BlockSelectSectionItem = styled.div`
  width: 142px;
  margin-right: 8px;
  flex-shrink: 0;
`;

export const SectionFormatInterval = styled.div`
  width: 100%;
  .app-tooltip {
    max-width: 228px !important;
    margin-top: 0 !important;
    text-align: left !important;
  }
  .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
  }
  .inputContainer {
    width: 100%;
    height: 36px;
    background: #fafbfc;
    opacity: 0.5;
    border: 1px solid #dadfea;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #969fb5;
    }
    &.readOnly {
      cursor: not-allowed;
    }
  }
`;

export const SectionFormatAmrap = styled.div`
  width: 100%;
`;

export const SectionFormatTimed = styled.div`
  width: 100%;
`;

export const WrapTextEditable = styled.div`
  width: 100%;
  position: relative;

  .name-limit {
    display: none;
    position: absolute;
    bottom: 14px;
    right: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #6a778a;
    opacity: 0.3;
    &.scrolling {
      bottom: 11px;
    }
  }

  .text-editable__container {
    padding-right: 50px;
  }

  :has(.text-editable__container--active) {
    .name-limit {
      display: inline;
    }
  }

  &:hover {
    .name-limit {
      display: inline;
    }
  }
`;
