import styled, { css } from 'styled-components';

export const CalendarActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: calc(100% - 28px);
  width: 26px;
  bottom: 0px;
  left: -26px;
  padding-left: 4px;

  ${props =>
    props.right &&
    css`
      right: -26px;
      left: unset;
      padding-left: unset;
    `}
`;

export const CalendarActionsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 28px;
  border-right: 1px solid #202353;

  svg {
    cursor: pointer;
  }

  svg:hover {
    path {
      stroke: #5158cf;
    }
  }
`;

export const CalendarActionsContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${props => props.calendarType && css`calc(100% / ${props.calendarType || 1})`};
  padding-bottom: 4px;

  &:hover {
    ${CalendarActionsContent} {
      opacity: 1 !important;
      background-color: #e7e8ff;
      border-radius: 2px;
      border-right: unset;
    }
  }
`;

export const CalendarActionsContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 28px;
  width: 100%;
  height: ${props => props.calendarType && css`calc(100% / ${props.calendarType || 1})`};
`;

export const PasteButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  padding-bottom: 4px;
  top: 28px;
  left: 4px;
`;

export const PasteButtonContainer = styled.div`
  height: 100%;
  width: calc(100% - 8px);
  background-color: rgba(108, 115, 154, 0.1);
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin-bottom: 4px;
  position: relative;
  z-index: 10;

  &:hover {
    opacity: 1 !important;
  }
`;

export const PasteButtonContainerEmpty = styled.div`
  height: 100%;
  width: calc(100% - 8px);
  background-color: transparent;
  margin-bottom: 4px;
  position: relative;
  z-index: -1;
  display: flex;
`;

export const PasteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 34px;
  background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
  border-radius: 5px;
  font-family: 'Open Sans';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
`;
