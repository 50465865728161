import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';

import Filter from '../Filter';
import SearchInput from '../SearchInput';

import { SearchContainer } from '../styles';

import ExerciseList from './ExerciseList';

// import icons
import { ReactComponent as ShowCardIcon } from 'assets/icons/show-card.svg';
import { ReactComponent as ShowListIcon } from 'assets/icons/show-row.svg';
import { loadMoreExercise, searchExercise, searchExerciseWithLoading } from 'redux/workout-builder/actions';
import { DEFAULT_FILTERS } from 'shared/ExerciseFilterPopup';
import { getWorkoutBuilderSetting, saveWorkoutSetting } from 'components/WorkoutDetailModal/helper';

class ExercisesTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      textSearch: this.props.exerciseTab.get('textSearch'),
      filters: DEFAULT_FILTERS,
      list: [],
      page: 1,
      displayType: getWorkoutBuilderSetting(this.props.user._id).displayTypeLeftPanel || 'card',
    };

    this.searchExerciseDebounce = debounce(this.searchExercise, 500);
  }

  componentDidMount() {
    const { exerciseTab } = this.props;
    const { list, textSearch } = exerciseTab.toJS();

    if (!list.length) {
      this.searchExercise();
    } else {
      this.setState({ textSearch });
    }
  }

  onChangeTextSearch = textSearch => {
    this.setState({ textSearch }, this.searchExerciseDebounce);
  };

  onChangeFilter = filters => {
    this.setState({ filters }, this.searchExercise);
  };

  // searchExercise = () => {
  //   const { textSearch, filters, isSearching } = this.state;
  //   if (isSearching) {
  //     return;
  //   }
  //   this.setState({ isSearching: true });
  //   this.props.searchExercise({ filters, textSearch }).finally(() => {
  //     this.setState({ isSearching: false });
  //   });
  // };

  searchExercise = () => {
    const { searchExerciseWithLoading } = this.props;
    const { textSearch, filters } = this.state;

    const params = { filters, textSearch };
    searchExerciseWithLoading(params);
  };

  loadMore = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    this.props.loadMoreExercise().finally(() => {
      this.setState({ isLoading: false });
    });
  };

  onAddExercise = () => {
    this.searchExercise();
  };

  render() {
    const { textSearch, isLoading, displayType } = this.state;
    const { exerciseTab } = this.props;
    const { list, total, filters, page, searching = false } = exerciseTab.toJS();
    return (
      <>
        <SearchContainer>
          <SearchInput
            defaultValue={textSearch}
            onChange={this.onChangeTextSearch}
            placeholder={`Search for your Exercises`}
          />
          <Filter currentFilters={filters} onChange={this.onChangeFilter} />
        </SearchContainer>
        <div className="listItem__wrapper">
          <div className="listItem__container">
            <div className="workout-left-panel-content-title">
              <div className="title">{`${textSearch ? 'Results' : 'Most Recent'} (${total})`}</div>
              <div className="icons">
                <ShowListIcon
                  className={classnames({ selected: displayType === 'list' })}
                  onClick={() => {
                    this.setState({ displayType: 'list' });
                    saveWorkoutSetting(this.props.user._id, { displayTypeLeftPanel: 'list' });
                  }}
                />
                <ShowCardIcon
                  className={classnames({ selected: displayType === 'card' })}
                  onClick={() => {
                    this.setState({ displayType: 'card' });
                    saveWorkoutSetting(this.props.user._id, { displayTypeLeftPanel: 'card' });
                  }}
                />
              </div>
            </div>
            <ExerciseList
              displayType={displayType}
              textSearch={textSearch}
              listItem={list}
              tabSelected="Exercises"
              isLoading={isLoading}
              isSearching={searching}
              loadMore={this.loadMore}
              page={page}
              onAddExercise={this.onAddExercise}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    rootReducer: { workoutBuilder },
    user,
  } = state;
  return {
    exerciseTab: workoutBuilder.getIn(['exerciseTab']),
    user,
  };
};

const mapDispatch = dispatch => ({
  searchExercise: bindActionCreators(searchExercise, dispatch),
  loadMoreExercise: bindActionCreators(loadMoreExercise, dispatch),
  searchExerciseWithLoading: bindActionCreators(searchExerciseWithLoading, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(ExercisesTab);
