import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { toggleModal } from 'actions/modal';
import { addWorkoutCollectionsToClient } from 'redux/client-studio/actions';
import { loadMoreWorkoutCollections, changeQueryParams, resetLoadMore } from 'redux/workout-collection/actions';
import { Button } from 'shared/FormControl';
import WorkoutCollectionItem from './WorkoutCollectionItem';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

function AddWorkoutCollectionsClient(props) {
  const {
    selectedClient,
    onAddSuccess,
    toggleModal,
    addWorkoutCollectionsToClient,
    query,
    loadMoreWorkoutCollections,
    workouts,
    changeQueryParams,
    loading,
    resetLoadMore,
    total,
    excludeIds,
  } = props;
  const [searchResults, setSearchResults] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [selectedWorkout, setSelectedWorkout] = useState({});
  const hasWorkoutSelect = useMemo(() => {
    return _.keys(selectedWorkout).filter(id => !!selectedWorkout[id]).length;
  }, [selectedWorkout]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = React.createRef();

  useEffect(() => {
    return () => {
      resetLoadMore && resetLoadMore();
    };
  }, []);

  useEffect(() => {
    let data = workouts || [];

    setSearchResults(data);
  }, [workouts]);

  const handleLoadMore = event => {
    const { total, isEnd } = query;
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && workouts.length < total && !loading && !isEnd) {
      loadMoreWorkoutCollections();
    }
  };

  const onScrollDebounce = _.debounce(handleLoadMore, 300);

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const onChangeSearch = e => {
    const value = _.get(e, 'target.value', '');

    setSearchResults([]);
    setTextSearch(value);
    setSelectedWorkout({});
    setIsSelectedAll(false);
    handleSearch(value);
  };

  const handleSearch = (textSearch, nextPage = 1) => {
    changeQueryParams({
      ...query,
      page: nextPage,
      textSearch: textSearch || '',
      excludeIds: excludeIds,
      status: 'publish',
    });
  };

  const onSelectWorkout = workout => {
    const id = _.get(workout, '_id', '');
    selectedWorkout[id] = !selectedWorkout[id];

    setIsSelectedAll(handleIsSelectedAll(searchResults, selectedWorkout));
    setSelectedWorkout({ ...selectedWorkout });
  };

  const handleSelectAll = e => {
    const checked = _.get(e, 'target.checked', false);
    if (checked) {
      const selectedWorkout = _.reduce(
        searchResults,
        (obj, item) => {
          obj[item._id] = true;
          return obj;
        },
        {},
      );
      setIsSelectedAll(checked);
      setSelectedWorkout(selectedWorkout);
    } else {
      setIsSelectedAll(checked);
      setSelectedWorkout({});
    }
  };

  const handleIsSelectedAll = (searchResults, selectedWorkout) => {
    const selected = _.keys(selectedWorkout).filter(id => !!selectedWorkout[id]);
    return selected.length === searchResults.length;
  };

  const onClearSearch = () => {
    setTextSearch('');
    setSearchResults([]);
    handleSearch('');
  };

  const handleClose = () => {
    toggleModal(false);
  };

  const onSubmit = () => {
    const { onFetchOnDemandWorkoutCollectionsByClient } = props;
    setIsSubmitting(true);
    const ids = _.keys(selectedWorkout).filter(id => !!selectedWorkout[id]);

    addWorkoutCollectionsToClient({ collectionIds: ids, clientId: selectedClient._id }).then(response => {
      const { status } = response;
      handleClose();

      if (status === 200) {
        onAddSuccess();
        setIsSubmitting(true);
        onFetchOnDemandWorkoutCollectionsByClient();
      }
    });
  };

  return (
    <S.AddWorkoutCollectionClientModal
      open={true}
      closeOnDimmerClick={false}
      onClose={() => toggleModal(false)}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <div className="header__title">Add a Workout Collection</div>
        <S.SearchInput
          value={textSearch}
          onChange={onChangeSearch}
          onClearSearch={onClearSearch}
          placeholder="Search collection name"
        />
      </Modal.Header>
      <Modal.Content>
        <div className="workoutResult__heading">
          <div className="workoutResult__title">All Collections ({total})</div>
          <div className="workoutResult__selectAll">
            <span className="workoutResult__selectAll--text">Select All</span>
            <S.CheckboxWorkout checked={isSelectedAll} onChange={handleSelectAll} />
          </div>
        </div>
        <S.WorkoutResultContainer onScroll={event => handleScroll(event)}>
          {!searchResults.length && (
            <div className="workoutResult__noResult">{loading ? 'Searching...' : 'No results found'}</div>
          )}
          {searchResults.map(item => {
            return excludeIds.includes(item._id) ? (
              <></>
            ) : (
              <WorkoutCollectionItem
                key={item._id}
                workout={item}
                onSelect={onSelectWorkout}
                active={selectedWorkout[item._id]}
              />
            );
          })}
          <div ref={loadingRef} />
        </S.WorkoutResultContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button className="actions__cancelBtn" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="actions__createBtn" disabled={!hasWorkoutSelect || isSubmitting} purple onClick={onSubmit}>
          Add
        </Button>
      </Modal.Actions>
    </S.AddWorkoutCollectionClientModal>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { workoutCollection },
  } = state;

  return {
    selectedClient: state.rootReducer.client.workingClientDetail,
    query: workoutCollection.query,
    workouts: _.get(workoutCollection, 'list', []),
    loading: workoutCollection.loading,
    total: _.get(workoutCollection, 'query.total', 0),
  };
};

const actionCreators = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    push: bindActionCreators(push, dispatch),
    addWorkoutCollectionsToClient: bindActionCreators(addWorkoutCollectionsToClient, dispatch),
    loadMoreWorkoutCollections: bindActionCreators(loadMoreWorkoutCollections, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetLoadMore: bindActionCreators(resetLoadMore, dispatch),
  };
};

export default connect(mapStateToProps, actionCreators)(AddWorkoutCollectionsClient);
