import styled from 'styled-components';
import { MenuTrigger } from 'shared/Icons';

export const DropdownWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;

  .evf-dropdown__menu {
    padding: 4px 0;
  }

  .evf-dropdown__option {
    padding: 8px 12px;

    .icon {
      margin-right: 10px;

      &.edit {
        width: 16px;
      }
      &.clone {
      }
      &.share {
        margin-right: 13px;
      }
      &.remove {
        margin-right: 12px;
      }
    }

    .text {
      color: #f7f8f9;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: transparent;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);

  .dot,
  .dot::before,
  .dot::after {
    background-color: #728096;
  }

  &:hover {
    background: #dadfea;

    .dot,
    .dot::before,
    .dot::after {
      background-color: #000000;
    }
  }
`;
