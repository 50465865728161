import styled from 'styled-components';
import ButtonIcon from 'shared/ButtonIcon';
import { CDN_URL } from 'constants/commonData';

export const ToolbarWrapper = styled.div`
  display: flex;

  margin: 32px 0;
  padding: 0 60px 0 30px;
  justify-content: space-between;
  align-items: center;

  .search-input {
    width: 272px;
    height: 36px;
    border-radius: 5px;

    > input {
      padding-left: 42px;
      background: #f8f8f8;
      border: 1px solid #f0f0f0;
      background-image: url(${CDN_URL}/images/search_light.svg);
      background-position: 16px center;
      background-repeat: no-repeat;
    }
  }
`;

export const Button = styled(ButtonIcon)`
  button {
    color: #fff;
    height: 30px !important;
    padding: 0px 24px !important;
    font-family: 'Open Sans' !important;
    background-color: #5158cf !important;
    border: none !important;
    margin: 0px !important;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(81, 88, 207, 0.9) !important;
    }

    svg {
      color: white;
      margin-right: 10px !important;
    }

    span {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 0px !important;
    }
  }
`;
