import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const LIGHT_DARK = '#7B7E91';
const FOCUS_COLOR = '#5158cf';
const ERROR_COLOR = '#ea314a';
const LIGHT_BORDER = '#F0F0F2';
const UNCHECK_TOGGLE_COLOR = '#A3A3B5';
const DASKEST = '#262626';
const TEXT_COLOR_NORMAL = '#202353';

const TextBase = css`
  font-family: 'Open Sans';
  color: #202353;
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 622px;
  border-radius: 0px 8px 8px 0px;
  ${TextBase}
`;

export const BodyWrapper = styled.div`
  display: flex;
  padding: 0 25px;
  flex-direction: column;
`;

export const ActionWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
`;

export const TitleWrapper = styled.div`
  display: flex;
  font-size: 19px;
  margin-bottom: 23px;
  margin-top: 11px;
`;

export const NameCountdownWrapper = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  margin-bottom: 15px;
`;

export const SelectedIconWrapper = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 4.5px;
  margin-right: 8px;

  background-color: ${({ selectedColor }) => selectedColor || '#E9F7FF'};

  .evf-dropdown__menu {
    background-color: #fff;
    box-shadow: 0px 4px 16px 0px rgba(38, 38, 38, 0.2);
    border-radius: 10px;
    padding: 0;
  }
`;

export const CountdownIconWrapper = styled.div`
  width: 53px;
  height: 53px;
  margin-top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  background-color: #fff;
  border: ${({ open }) => open && '1px solid #5158cf'};
  overflow: hidden;

  :hover {
    border-radius: 8px;
    border: 1px solid #5158cf;
  }
`;

export const CountdownIcon = styled.div`
  width: 45px;
  height: 45px;
  background: ${props => props.background};
  border-radius: 4.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  font-size: 24px;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  span {
    font-size: 32px;
  }
`;

export const InputNameWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  width: 100%;
  flex-direction: column;
`;

export const ValidateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  visibility: ${({ isFocusNameInput }) => (isFocusNameInput ? 'visibility' : 'hidden')};
  height: 10px;
  margin-top: 10px;
`;

export const NameInput = styled.input`
  flex: 1 1;
  border-radius: 8px;
  background: #fff;
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 11px;
  height: 46px;
  border: 1px solid transparent;
  font-size: 19px;
  font-weight: 600;
  &:focus-within,
  &:focus-visible {
    font-weight: 400;
  }

  &:focus-within,
  &:focus-visible,
  :hover {
    border: 1px solid ${FOCUS_COLOR};
    background: #fff;
    outline: 1px solid transparent !important;
    ${ValidateWrapper} {
      visibility: visible;
    }
  }

  ::placeholder {
    font-size: 19px;
    color: ${LIGHT_DARK};
  }
`;

export const ErrorMessage = styled.div`
  ${TextBase}
  color: ${ERROR_COLOR};
  font-size: 12px;
  margin-top: 4px;
  min-width: 10px;
`;

export const LimitTitle = styled.div`
  ${TextBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  color: ${({ isFull }) => (isFull ? ERROR_COLOR : LIGHT_DARK)};
  margin-right: 1px;
`;

export const TimeSettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 131px;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin-bottom: 15px;
  padding: 17px 15px 19px;
`;

export const SelectDetailTime = styled.div`
  display: flex;
`;

export const DateTimeTitle = styled.div`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  line-height: 16.5px;
  color: #7b7e91;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .rdt.new-ui {
    width: 339px;

    ${props =>
      props.disabled &&
      css`
        .custom-date-input {
          cursor: not-allowed;

          :hover,
          :focus {
            border: 1px solid #d4d7d9;
          }
        }
      `}

    thead tr th.dow {
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      text-align: center;
      color: #7b7e91;
      height: 33px;
    }

    thead tr:first-child {
      th {
        padding-bottom: 24px;
      }
    }

    tbody {
      tr:last-child {
        .rdtNew {
          display: none;
        }
      }
      /* TODO - Please consider this code for styles of date picker */
      tr:has(.rangeStart) .rdtDay div {
        padding-top: 14px;
      }
      tr:has(.rangeEnd) .rdtDay div {
        padding-top: 14px;
      }
    }

    .rdtPicker {
      width: 339px;
      padding: 22px 24px;
      border: 1px solid #eceef4;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-top: 4px;

      .rdtSwitch {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px !important;
        line-height: 19px !important;
        color: ${TEXT_COLOR_NORMAL} !important;

        :hover {
          color: ${FOCUS_COLOR} !important;
        }
      }

      .rdtPrev {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/left_arrow_primary.svg);
          position: absolute;
          top: 26px;
          left: 30px;
        }
      }
      .rdtNext {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/right_arrow_primary.svg);
          position: absolute;
          top: 26px;
          right: 28px;
        }
      }
    }

    .rdtDay {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 13px;
      line-height: 100%;
      color: ${TEXT_COLOR_NORMAL};

      &.rdtNew {
        color: #e1e1ea;
      }
      &.rdtOld {
        color: #e1e1ea;
      }

      div {
        width: 33px;
        height: 33px;
        padding: 18px 13px 14px;
      }

      ::before {
        border-radius: 3px;
        top: 9px !important;
        left: 56% !important;
        width: 33px !important;
        height: 33px !important;
      }

      &.rdtToday {
        font-weight: 600;
        color: ${FOCUS_COLOR};
      }

      &.rdtActive {
        font-weight: 600;
        color: #fff;

        ::before {
          background: ${FOCUS_COLOR} !important;
          border-radius: 3px;
        }

        &.rangeStart {
          ::before {
            background: #f4f4f4 !important;
            border-radius: 0;
            left: 53% !important;
            border-top-left-radius: 3px !important;
            border-bottom-left-radius: 3px !important;
            box-shadow: 4px 0px 0px 0px #f4f4f4;
          }

          div {
            background: ${FOCUS_COLOR};
            border-radius: 3px !important;
            padding: 0;
            margin-top: 8px;
            margin-left: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.rangeEnd {
          ::before {
            background: #f4f4f4 !important;
            border-radius: 0;
            left: 45% !important;
            border-top-right-radius: 3px !important;
            border-bottom-right-radius: 3px !important;
            box-shadow: -4px 0px 0px 0px #f4f4f4;
          }

          div {
            background: ${FOCUS_COLOR};
            border-radius: 3px !important;
            padding: 0;
            margin-top: 8px;
            margin-left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &.inRange {
        div {
          font-weight: 600;
          background: #f4f4f4;
          padding: 0;
          margin-top: 8px;
          margin-left: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: -4px 0px 0px 0px #f4f4f4, 6px 0px 0px 0px #f4f4f4;
        }
      }
    }

    .rdtPicker tr:has(.inRange) {
      .rdtDay div {
        padding: 14px 13px;
      }
    }
  }

  .custom-date-input {
    background: #fafbfc;
    min-width: 339px;
    padding: 8px 13px;

    .calendar-icon {
      g path {
        fill: ${DASKEST};
      }
    }

    span {
      line-height: 18px;
      color: ${DASKEST};
    }
  }

  .error {
    .custom-date-input {
      border-color: #ea314a;
    }
  }
`;

export const ReminderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const ReminderTitle = styled.div`
  display: flex;
  font-size: 11px;
  color: ${LIGHT_DARK};
`;

export const ReminderSettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -6px;
`;

export const SettingTitle = styled.div`
  display: flex;
`;

export const SettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  border-top: 1px solid ${LIGHT_BORDER};

  :first-child {
    border-top: none;
  }
`;

export const LeftSetting = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const TimeLabel = styled.div`
  display: flex;
  color: ${LIGHT_DARK};
`;

export const RightSetting = styled.div`
  display: flex;

  input ~ label::before {
    background-color: ${UNCHECK_TOGGLE_COLOR} !important;
  }

  input:checked ~ label::before {
    background-color: ${FOCUS_COLOR} !important;
  }
`;

export const SelectTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 54px;
  gap: 32px;

  .toggle-input {
    font-size: 12px;

    input {
      ~ label {
        ::before {
          background-color: #5158cf;
        }
      }
    }

    :not(.checked) {
      color: #728096;

      input {
        ~ label {
          ::before {
            background-color: ${UNCHECK_TOGGLE_COLOR};
          }
        }
      }
    }
  }
`;

export const SelectTimeSection = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const SetTimeWrapper = styled.div`
  .custom-reminder {
    margin-left: 0;
    height: 36px;

    ${props =>
      props.disabled &&
      css`
        .evfSelectBox__control {
          pointer-events: none;
        }
      `}

    .evfSelectBox__menu {
      margin-bottom: 4px;
    }

    label {
      padding-left: 44px;
    }
  }
`;

export const ToggleLabel = styled.div`
  text-align: left;
`;

export const DropdownLabel = styled.div`
  text-align: left;
  color: ${LIGHT_DARK};
  font-size: 11px;
`;
