import React, { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import moment from 'moment';
import filter from 'lodash/filter';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

// Components
import DropdownOption from '../DropdownOption';
import ChartSettingModal from '../ChartSettings';
import ComparisonButton from './ComparisonButton';

// Store
import { toggleModal } from 'actions/modal';
import { filterChartViewMode, toggleComparisonMode } from 'actions/bodyMetric';

// Components
import DatePicker from '../DatePicker';

//Constants
import {
  LAST_TIME_OPTIONS,
  NORMALIZING_TIME_OPTIONS,
  SPECIAL_METRICS_CODE,
  VIEW_TYPE_OPTIONS,
  getDateFromRange,
  getOptionFromRange,
  getViewTypeFromRange,
  getViewTypeOptionsFromRange,
} from 'components/BodyMetricChartNew/constants';

// Assets
import { ReactComponent as SettingIcon } from 'assets/icons/settings_gear_icon.svg';

import * as S from './style';

const FORMAT_DATE = 'yyyy-MM-DD';

const ChartHeader = props => {
  const {
    bodyMetric,
    toggleModal,
    filterChartViewMode,
    location,
    replaceRouter,
    metricTypes = [],
    toggleComparisonMode,
    onFilterRangeTime = () => {},
    hideFilterToday = false,
    enableCompareChart = false,
    enableSetting = false,
    isLoadingMetric = false,
  } = props;
  const { filterTime = {}, isGettingSetting } = bodyMetric;
  const { timeRangeString = NORMALIZING_TIME_OPTIONS.last_2_month.value, from_date = '', to_date = '' } = filterTime;
  const today = moment(new Date(), FORMAT_DATE);
  const [rangeFilter, setRangeFilter] = useState(timeRangeString);
  const [viewType, setViewType] = useState(filterTime.period_group);
  const [appliedRange, setAppliedRange] = useState({
    startDate: moment(filterTime.from_date, FORMAT_DATE),
    endDate: moment(filterTime.to_date, FORMAT_DATE),
  });

  const listMetrics = useMemo(() => {
    const specialMetricsCode = ['steps', 'sleep', SPECIAL_METRICS_CODE.HEART_RATE];

    const filterMetrics = ({ selected = false, unique_code }) => {
      const selectingBodyUniqueCode = get(bodyMetric, 'unique_code', '');
      return selected && unique_code !== selectingBodyUniqueCode && !specialMetricsCode.includes(unique_code);
    };

    const filteredMetrics = filter(metricTypes, filterMetrics);
    return sortBy(filteredMetrics, ({ name = '' }) => name.toLowerCase());
  }, [metricTypes, bodyMetric]);

  useEffect(() => {
    const startDate = moment(from_date, FORMAT_DATE);
    const endDate = moment(to_date, FORMAT_DATE);
    if (today.format(FORMAT_DATE) !== endDate.format(FORMAT_DATE)) {
      setRangeFilter(NORMALIZING_TIME_OPTIONS.custom.value);
    } else {
      setRangeFilter(getOptionFromRange({ startDate, endDate }));
    }
    !isGettingSetting && setAppliedRange({ startDate, endDate });
  }, [timeRangeString, from_date, to_date, isGettingSetting]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const time_range = query.get('time_range');
    if (time_range) {
      const unique_code = query.get('unique_code');
      replaceRouter({ search: `?unique_code=${encodeURIComponent(unique_code)}` });
    }
  }, []);

  const handleSelectRangeFilter = value => {
    setRangeFilter(value);
  };

  const handleApplyCustomFilter = customDate => {
    setRangeFilter(NORMALIZING_TIME_OPTIONS.custom.value);
    const defaultViewTypeCustom = getViewTypeFromRange(customDate);
    const types = getViewTypeOptionsFromRange(customDate);
    const hasViewCurrentType = types.includes(viewType);

    handleSelectViewTypeFilter(hasViewCurrentType ? viewType : defaultViewTypeCustom, customDate);
  };

  const handleSelectViewTypeFilter = (value, customDate = null, isFromType = false) => {
    setViewType(value);
    if (customDate) {
      const startDate = customDate.startDate.format(FORMAT_DATE);
      const endDate = customDate.endDate.format(FORMAT_DATE);
      onFilterRangeTime(startDate, endDate, value);
      filterChartViewMode(startDate, endDate, value, 'custom');
      return;
    }

    const { fromDate, toDate } = getDateFromRange(rangeFilter);

    const newFromDate = isFromType ? filterTime.from_date : fromDate || filterTime.from_date;
    const newToDate = isFromType ? filterTime.to_date : toDate || filterTime.to_date;
    onFilterRangeTime(newFromDate, newToDate, value);
    filterChartViewMode(newFromDate, newToDate, value, rangeFilter);
  };

  const filteredViewOption = (type, option) => {
    const viewTypes = NORMALIZING_TIME_OPTIONS[option].viewTypes;
    return isEmpty(viewTypes) || viewTypes.includes(type);
  };

  const viewTypeOptions = useMemo(() => {
    if (rangeFilter === NORMALIZING_TIME_OPTIONS.custom.value) {
      const options = getViewTypeOptionsFromRange(appliedRange) || [];
      return VIEW_TYPE_OPTIONS.filter(item => options.includes(item.value));
    }
    return VIEW_TYPE_OPTIONS.filter(item => filteredViewOption(item.value, rangeFilter));
  }, [rangeFilter, appliedRange]);

  const keepViewType = viewType => {
    const hasViewTypeOption = viewTypeOptions.some(item => item.value === viewType);
    return hasViewTypeOption ? viewType : viewTypeOptions[0].value;
  };

  useEffect(() => {
    if (rangeFilter !== NORMALIZING_TIME_OPTIONS.custom.value && !isGettingSetting) {
      handleSelectViewTypeFilter(keepViewType(viewType));
    }
  }, [rangeFilter]);

  const handleOpenSetting = () => {
    if (isLoadingMetric) return;
    toggleModal(true, <ChartSettingModal />);
  };

  return (
    <S.HeaderContainer>
      <S.MetricTitle className="metric-title">{bodyMetric.name || ''}</S.MetricTitle>
      <S.ChartFilterWrapper className="chart-filter-wrapper">
        {enableCompareChart && listMetrics.length !== 0 && (
          <ComparisonButton
            options={listMetrics}
            toggleComparisonMode={toggleComparisonMode}
            bodyMetric={bodyMetric}
            isLoadingMetric={isLoadingMetric}
          />
        )}
        <S.SelectRangeWrapper>
          <S.SelectRangeButton>
            <DropdownOption
              selected={rangeFilter}
              onSelectFilter={handleSelectRangeFilter}
              options={hideFilterToday ? LAST_TIME_OPTIONS.filter(({ value }) => value !== 'today') : LAST_TIME_OPTIONS}
              className="time-filter"
              isLoadingMetric={isLoadingMetric}
            />
          </S.SelectRangeButton>
          <DatePicker
            startDate={appliedRange.startDate}
            endDate={appliedRange.endDate}
            rangeFilter={rangeFilter}
            onApplyCustomFilter={handleApplyCustomFilter}
            hideFilterToday={hideFilterToday}
            isLoadingMetric={isLoadingMetric}
          />
        </S.SelectRangeWrapper>
        <S.SelectViewType>
          <DropdownOption
            selected={viewType}
            onSelectFilter={val => handleSelectViewTypeFilter(val, null, true)}
            options={viewTypeOptions}
            className="view-type-filter"
            isLoadingMetric={isLoadingMetric}
          />
        </S.SelectViewType>
        {enableSetting && (
          <S.SettingButton
            data-tip
            data-for="chart-setting-tooltip"
            onClick={handleOpenSetting}
            isLoadingMetric={isLoadingMetric}
          >
            <SettingIcon className="setting-icon" />
          </S.SettingButton>
        )}
      </S.ChartFilterWrapper>
    </S.HeaderContainer>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    replaceRouter: bindActionCreators(replace, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    filterChartViewMode: bindActionCreators(filterChartViewMode, dispatch),
    toggleComparisonMode: bindActionCreators(toggleComparisonMode, dispatch),
  };
};

const mapState = ({ router }) => ({
  location: router.location,
});

export default connect(mapState, mapDispatchToProps)(ChartHeader);
