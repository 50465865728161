import { get, merge, cloneDeep, sortBy } from 'lodash';
import Request from 'configs/request';
import { getQueryParamsFromObject, omitEmptyRequestParams } from 'utils/commonFunction';
import { Mixpanel } from 'utils/mixplanel';
import { MEDIA_PLACEHOLDER, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

export const Types = {
  ON_DEMAND_WORKOUTS_GET_LIST_REQUEST: 'ON_DEMAND_WORKOUTS_GET_LIST_REQUEST',
  ON_DEMAND_WORKOUTS_GET_LIST_SUCCESS: 'ON_DEMAND_WORKOUTS_GET_LIST_SUCCESS',
  ON_DEMAND_WORKOUTS_GET_LIST_FAIL: 'ON_DEMAND_WORKOUTS_GET_LIST_FAIL',
  ON_DEMAND_WORKOUTS_ADD_NEW_REQUEST: 'ON_DEMAND_WORKOUTS_ADD_NEW_REQUEST',
  ON_DEMAND_WORKOUTS_ADD_NEW_SUCCESS: 'ON_DEMAND_WORKOUTS_ADD_NEW_SUCCESS',
  ON_DEMAND_WORKOUTS_ADD_NEW_FAIL: 'ON_DEMAND_WORKOUTS_ADD_NEW_FAIL',
  ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_REQUEST: 'ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_REQUEST',
  ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_SUCCESS: 'ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_SUCCESS',
  ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_FAIL: 'ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_FAIL',
  ON_DEMAND_WORKOUTS_UPDATE_REQUEST: 'ON_DEMAND_WORKOUTS_UPDATE_REQUEST',
  ON_DEMAND_WORKOUTS_UPDATE_SUCCESS: 'ON_DEMAND_WORKOUTS_UPDATE_SUCCESS',
  ON_DEMAND_WORKOUTS_UPDATE_FAIL: 'ON_DEMAND_WORKOUTS_UPDATE_FAIL',
  ON_DEMAND_WORKOUTS_CHANGE_QUERY_PARAMS: 'ON_DEMAND_WORKOUTS_CHANGE_QUERY_PARAMS',
  ON_DEMAND_WORKOUTS_GET_DETAILS_REQUEST: 'ON_DEMAND_WORKOUTS_GET_DETAILS_REQUEST',
  ON_DEMAND_WORKOUTS_GET_DETAILS_SUCCESS: 'ON_DEMAND_WORKOUTS_GET_DETAILS_SUCCESS',
  ON_DEMAND_WORKOUTS_GET_DETAILS_FAILED: 'ON_DEMAND_WORKOUTS_GET_DETAILS_FAILED',
  ON_DEMAND_WORKOUTS_CHANGE_SHARE_STATUS: 'ON_DEMAND_WORKOUTS_CHANGE_SHARE_STATUS',
};

export const getAllOnDemandWorkouts = (body, isLoading = false) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        onDemandWorkouts: { query },
      },
    } = getState();
    const queryBody = merge(query, body);

    if (isLoading) {
      dispatch({ type: Types.ON_DEMAND_WORKOUTS_GET_LIST_REQUEST });
    }

    return dispatch(
      Request.post(
        { url: `/api/on-demand-workout-libraries/v2`, data: omitEmptyRequestParams(queryBody) },
        true,
        res => {
          const list = get(res, 'data.data', []);
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_GET_LIST_SUCCESS, payload: list });
        },
        error => {
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_GET_LIST_FAIL, error });
        },
      ),
    );
  };
};

export const addNewOnDemandWorkouts = data => {
  return dispatch => {
    dispatch({ type: Types.ON_DEMAND_WORKOUTS_ADD_NEW_REQUEST });
    Mixpanel.track('on_demand_add_new_workout_lib');

    if (data.background === MEDIA_PLACEHOLDER) {
      delete data.background;
    }

    return dispatch(
      Request.post(
        { url: '/api/on-demand-workout-libraries', data: data },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data');
          let clonedData = cloneDeep(data);
          let sortedLabels = sortBy(data.labels, obj => obj.label.order);
          clonedData.labels = sortedLabels;
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_ADD_NEW_SUCCESS, payload: clonedData });
          dispatch(getAllOnDemandWorkouts());
        },
        () => {
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_ADD_NEW_FAIL });
        },
      ),
    );
  };
};

export const getWorkoutLibrary = queryParams =>
  Request.get({ url: `/api/on-demand-workout-libraries/origin-workouts?${getQueryParamsFromObject(queryParams)}` });

export const removeWorkoutFromList = id => {
  return (dispatch, getState) => {
    const {
      rootReducer: { onDemandWorkouts },
    } = getState();
    const { list, query } = onDemandWorkouts;
    dispatch({ type: Types.ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_REQUEST });
    Mixpanel.track('on_demand_remove_workout_lib');

    return dispatch(
      Request.delete(
        { url: `/api/on-demand-workout-libraries/${id}` },
        true,
        res => {
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_SUCCESS, payload: { data: id } });
          if (query.page > 1 && list.length === 1) {
            // last element of page after delete will navigate to previous page
            dispatch(changeQueryParams({ page: query.page - 1 }));
          } else {
            dispatch(getAllOnDemandWorkouts());
          }
        },
        error => {
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_FAIL, error });
        },
      ),
    );
  };
};

export const getOnDemandWorkoutDetail = workoutId => {
  return dispatch => {
    dispatch({ type: Types.ON_DEMAND_WORKOUTS_GET_DETAILS_REQUEST });

    return dispatch(
      Request.get({ url: `/api/on-demand-workout-libraries/${workoutId}` }, true, (response, { dispatch }) => {
        dispatch({ type: Types.ON_DEMAND_WORKOUTS_GET_DETAILS_SUCCESS });
      }),
      error => {
        dispatch({ type: Types.ON_DEMAND_WORKOUTS_GET_DETAILS_FAILED });
      },
    );
  };
};

export const updateOnDemandWorkouts = (data, workoutLibraryId) => {
  return dispatch => {
    dispatch({ type: Types.ON_DEMAND_WORKOUTS_UPDATE_REQUEST });

    return dispatch(
      Request.put(
        { url: `/api/on-demand-workout-libraries/${workoutLibraryId}`, data: data },
        true,
        (response, { dispatch }) => {
          const { data } = response.data;
          let clonedData = cloneDeep(data);
          let sortedLabels = sortBy(clonedData.labels, obj => obj.label.order);
          clonedData.labels = sortedLabels;
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_UPDATE_SUCCESS, payload: { data: clonedData } });
          dispatch(getAllOnDemandWorkouts());
        },
        () => {
          dispatch({ type: Types.ON_DEMAND_WORKOUTS_UPDATE_FAIL });
        },
      ),
    );
  };
};

export const searchAllOnDemandWorkouts = params => {
  return Request.post({ url: `/api/on-demand-workout-libraries/v2`, data: params });
};

export const changeQueryParams = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: { onDemandWorkouts },
    } = getState();

    const query = { ...onDemandWorkouts.query, ...params };
    dispatch({ type: Types.ON_DEMAND_WORKOUTS_CHANGE_QUERY_PARAMS, payload: query });
    dispatch(getAllOnDemandWorkouts(query));
  };
};

export const updateShareStatus = ({ params = {}, workoutLibraryId } = {}) => (dispatch, getState) => {
  if ((!params.owner && params.share !== TEAM_SHARE_NOOWNER) || !workoutLibraryId) return;
  const {
    user: { _id: userId, team_member = [] },
    rootReducer: {
      onDemandWorkouts: { list },
    },
  } = getState();

  return dispatch(
    Request.patch(
      {
        url: `/api/on-demand-workout-libraries/${workoutLibraryId}/share`,
        data: params,
      },
      true,
      response => {
        if (((response.data || {}).data || {}).success) {
          let newList = [];
          if (params.owner === userId || params.share !== TEAM_SHARE_PRIVATE) {
            newList = [...list].map(it => {
              if (it._id === workoutLibraryId) {
                const author_info = team_member.find(it => it._id === params.owner);
                return { ...it, share: params.share, author: params.owner, author_info };
              }
              return it;
            });
          } else {
            newList = [...list].filter(it => it._id !== workoutLibraryId);
          }

          dispatch({
            type: Types.ON_DEMAND_WORKOUTS_CHANGE_SHARE_STATUS,
            payload: newList,
          });
        }
      },
    ),
  );
};
