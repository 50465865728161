import React from 'react';
import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

const default_image = `${CDN_URL}/images/studio_program_default_cover_image.png`;

export default function StudioProgramOption({ data, cloudfrontList }) {
  return (
    <div className="asset-content studio-collection">
      <div className="studio-cover-container">
        <img
          src={data.cover_image ? convertS3UrlToCloudFrontUrl(data.cover_image, cloudfrontList, true) : default_image}
          alt={data.program_name}
        />
      </div>
      <div className="studio-info-container">
        <div className="title">{data.program_name}</div>
        <div className="asset-info">
          {data.number_of_weeks} {pluralize('WEEK', data.number_of_weeks)}
        </div>
      </div>
    </div>
  );
}
