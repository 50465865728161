import { debounce, get, isEqual, isString, omit } from 'lodash';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  bulkDeleteTask,
  changeQueryParams,
  getTasksLibrary,
  resetQueryParams,
  resetSelectedTask,
} from 'redux/tasks-library/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { changeClientFilter } from 'redux/segment/actions';
import { Button } from 'shared/FormControl';
import { ToolbarContainer } from 'shared/LibraryLayout';
import Filter from 'shared/SearchExercise/Filter';
import { NewSearchInput } from 'shared/SearchInput';
import { DEFAULT_CUSTOM_FILTERS } from 'shared/ExerciseFilterPopup';
import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { NO_LOADING, MESSAGE, NO_LOAD_MORE, taskType, toastMessage } from '../constants';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import TaskAddNew from 'components/TaskAddNew';
import { CDN_URL } from 'constants/commonData';

import * as S from '../styles';

function View(props) {
  const {
    getTasksLibrary,
    resetQueryParams,
    changeQueryParams,
    query,
    currentFilters,
    user,
    changeClientFilter,
    filters,
    selectedTasks,
    bulkDeleteTask,
    toggleConfirmModal,
    toggleModal,
    deleting,
    bodyMetricsFromLibrary,
    resetSelectedTask,
  } = props;

  useEffect(() => {
    getTasksLibrary && getTasksLibrary();

    return () => {
      resetQueryParams && resetQueryParams();
    };
  }, []);

  const searchTask = (event, { value }) => {
    const search = value.toLowerCase().trim();

    if (search !== query) {
      resetSelectedTask();
      changeQueryParams && changeQueryParams({ page: 1, query: search }, false, false);
    }
  };

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      resetSelectedTask();
      changeQueryParams && changeQueryParams({ page: 1 }, false, false);
    }
  };

  const searchDebounce = debounce(searchTask, 300);

  const handleTaskFilter = filter => {
    const ownersId = filter
      ? get(filter, 'owners', []).map(owner => (isString(owner) ? owner : get(owner, '_id', '')))
      : [];
    const newFilter = { ...filter, owners: ownersId };
    resetSelectedTask();
    changeQueryParams && changeQueryParams({ ...newFilter, page: 1 }, NO_LOAD_MORE, NO_LOADING);
  };

  const onResetFilter = () => {
    resetSelectedTask();
    changeQueryParams && changeQueryParams({ ...DEFAULT_CUSTOM_FILTERS, page: 1 }, NO_LOAD_MORE, NO_LOADING);
  };

  const hideResetButton = isEqual(currentFilters, omit(DEFAULT_CUSTOM_FILTERS, 'formats'));

  const selectedLength = selectedTasks ? selectedTasks.length : 0;

  const handleToastMessage = deleted => {
    if (deleted && deleted === 1) {
      toastMessage(`${deleted} ${MESSAGE.DELETE_SINGLE}`);
    } else {
      toastMessage(`${deleted} ${MESSAGE.DELETE_MULTIPLE}`);
    }
  };

  const handleBulkDeleteTask = () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        newStyle
        confirmButtonTitle="Delete"
        title="Delete Tasks?"
        content="Would you like to delete this task?"
        onConfirm={() =>
          bulkDeleteTask &&
          bulkDeleteTask(selectedTasks, deleted => {
            handleToastMessage(deleted);
          })
        }
      />,
    );
  };

  const handleAddNewTask = () => {
    toggleModal &&
      toggleModal(
        true,
        <TaskAddNew
          owner={get(user, '_id', '')}
          isAddTaskFromLibrary={true}
          bodyMetricsFromLibrary={bodyMetricsFromLibrary}
        />,
      );
  };

  return (
    <>
      <ToolbarContainer>
        <S.ToolbarLeft>
          <NewSearchInput
            onChange={searchDebounce}
            onClearSearch={() => searchTask(null, { value: '' })}
            onKeyPress={onKeyPress}
            placeholder="Search by keyword or name"
            className="search--task-lib"
          />
          <Filter
            menuWidth={379}
            menuHeight={327}
            title="Filter by:"
            hideDefaultFilter
            hideCustomFilter={false}
            hideTag={true}
            taskType={taskType}
            onCustomFilter={handleTaskFilter}
            currentCustomFilters={currentFilters}
            user={user}
            filterClient={filters}
            changeClientFilter={changeClientFilter}
            isShowCustomFilter={true}
            trigger={<S.FilterTrigger>Filter</S.FilterTrigger>}
            classNameButtonUpdate="button--custom-purple"
          />
          <S.ResetFilter onClick={onResetFilter} hide={hideResetButton}>
            Reset
          </S.ResetFilter>
          {selectedTasks && !!selectedLength && (
            <Button disabled={deleting} onClick={handleBulkDeleteTask} className="button--delete-tasks-lib">
              <DeleteIcon></DeleteIcon>
              <span>
                {selectedLength <= 1 ? 'Delete Task' : 'Delete Tasks'} ({selectedLength})
              </span>
            </Button>
          )}
        </S.ToolbarLeft>
        <S.ToolbarRight>
          <S.Button text="Add New Task" icon={<PlusIcon />} onClick={handleAddNewTask} />
        </S.ToolbarRight>
      </ToolbarContainer>
    </>
  );
}

const TasksLibraryToolbar = React.memo(View);

const mapState = state => {
  const {
    user,
    rootReducer: {
      tasksLibrary: {
        selectedTasks,
        queryParam: { query, types, owners },
        deleting,
      },
      segment: { filters },
    },
  } = state;

  const currentFilters = { types, owners };

  return { user, query, currentFilters, filters, selectedTasks, deleting };
};

const mapDispatch = dispatch => {
  return {
    getTasksLibrary: bindActionCreators(getTasksLibrary, dispatch),
    resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    changeClientFilter: bindActionCreators(changeClientFilter, dispatch),
    bulkDeleteTask: bindActionCreators(bulkDeleteTask, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resetSelectedTask: bindActionCreators(resetSelectedTask, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TasksLibraryToolbar);
