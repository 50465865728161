// Lib
import React, { useRef, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Draggable, Droppable } from 'react-beautiful-dnd';

// Shared
import Recipe from '../RecipeItem';
import MealEmptyList from '../MealEmptyList';

// Components
import ViewRecipeDetail from 'components/RecipeDetail/ViewRecipeDetail';

// constants
import { getMacroNutrients, calculateMacroNutrients } from 'components/MealPlanDayDetail/constants';
import { STATUS_SCROLL, WIDTH_EACH_ITEM, TYPE_REARRANGE } from 'components/MealPlanDetail/constants';
import { conditionalRoundNutrition, handleEmptyValue } from 'components/RecipeDetail/helper';

// Assets
import { ReactComponent as Trailing } from 'assets/icons/MealPlans/trailing_icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';

// Styles
import * as S from './style';
import MacroInfo from 'components/ClientMealPlan/components/MealCategory/components/MacroInfo';

const DayMeal = props => {
  const {
    dayOfWeek,
    data,
    onAddMeal,
    dayIndex,
    weekIndex,
    isPublish,
    isEditMealPlan,
    rearrangeMode,
    isDragging,
    placeholderProps,
    toggleModal,
    disabledAction,
    mealPlanId,
  } = props;

  const listMealRef = useRef(null);

  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
  const [statusScroll, setStatusScroll] = useState(STATUS_SCROLL.LEFT);

  const mealGroup = get(data, 'day_meal.meal_group', []);

  const allRecipesEmpty = mealGroup.every(group => get(group, 'recipes.length', 0) === 0);

  const disabled = isPublish && !isEditMealPlan;

  const hasMealPlan = !isEmpty(data);

  useEffect(() => {
    const elementListMeal = listMealRef && listMealRef.current;

    const detectHorizontalScroll = () => {
      if (elementListMeal) {
        setHasHorizontalScroll(elementListMeal.scrollWidth > elementListMeal.clientWidth);
      }
    };

    const handleScroll = () => {
      if (elementListMeal) {
        switch (true) {
          case elementListMeal.scrollLeft === 0:
            setStatusScroll(STATUS_SCROLL.LEFT);
            break;
          case elementListMeal.scrollLeft + elementListMeal.clientWidth >= elementListMeal.scrollWidth:
            setStatusScroll(STATUS_SCROLL.RIGHT);
            break;
          default:
            setStatusScroll(STATUS_SCROLL.CENTER);
        }
      }
    };

    if (elementListMeal) {
      detectHorizontalScroll();
      elementListMeal.addEventListener('scroll', handleScroll);

      return () => {
        elementListMeal.removeEventListener('scroll', handleScroll);
      };
    }
  }, [listMealRef && listMealRef.current, hasMealPlan]);

  const macroNutrients = calculateMacroNutrients(mealGroup);
  const listNutrients = getMacroNutrients(macroNutrients);

  const handleArrowButton = actionNext => {
    const elementListMeal = listMealRef && listMealRef.current;

    if (elementListMeal) {
      const scrollLeft = actionNext
        ? elementListMeal.scrollLeft + elementListMeal.clientWidth
        : elementListMeal.scrollLeft - elementListMeal.clientWidth;

      elementListMeal.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleOpenRecipeDetail = (_id, rearrangeMode) => {
    if (!_id || rearrangeMode) return;

    toggleModal &&
      toggleModal(
        true,
        <ViewRecipeDetail
          mealPlanId={mealPlanId}
          isGetFromMealPlan
          recipeId={_id}
          onClose={() => toggleModal(false)}
        />,
      );
  };

  return (
    <S.Wrapper>
      <Droppable droppableId={`${dayIndex}`} direction="horizontal" type={TYPE_REARRANGE.MEAL} isCombineEnabled>
        {(provided, snapshot) => (
          <S.MealListWrapper ref={provided.innerRef} {...provided.droppableProps}>
            <S.Title>
              <S.Day>
                {dayOfWeek}
                <span>(DAY {dayIndex + 1 + weekIndex * 7})</span>
              </S.Day>
              {hasMealPlan && !allRecipesEmpty && (
                <>
                  {!rearrangeMode && (
                    <S.EditButton disabled={disabled || disabledAction} onClick={() => onAddMeal('edit')}>
                      Edit
                    </S.EditButton>
                  )}
                  <S.NutritionList>
                    {listNutrients.map(item => {
                      const { label, unit, value, id } = item;
                      return (
                        <S.NutritionItem key={id}>
                          {label && <S.NutritionLabel>{label}</S.NutritionLabel>}
                          <S.NutritionValue>{handleEmptyValue(conditionalRoundNutrition(value))}</S.NutritionValue>
                          {unit}
                        </S.NutritionItem>
                      );
                    })}
                  </S.NutritionList>
                </>
              )}
            </S.Title>
            {hasMealPlan && !allRecipesEmpty ? (
              <S.MealList ref={listMealRef} isDragging={isDragging}>
                {hasHorizontalScroll && statusScroll !== STATUS_SCROLL.LEFT && !isDragging && (
                  <S.ButtonArrow className="button-prev" onClick={() => handleArrowButton(false)}>
                    <ArrowIcon />
                  </S.ButtonArrow>
                )}
                {mealGroup.map((meal, indexMeal) => {
                  const id = get(meal, '_id', '');
                  if (get(meal, 'recipes.length', 0) <= 0) return null;
                  const macroNutrients = calculateMacroNutrients([{ recipes: get(meal, 'recipes', []) }]);

                  const recipeLength = get(meal, 'recipes.length', 0);
                  const maxWidth = `calc((${WIDTH_EACH_ITEM}px * ${recipeLength}) + (10px * ${
                    recipeLength - 1
                  }) - 86px)`;

                  return (
                    <Draggable key={id} draggableId={id} index={indexMeal} isDragDisabled={!rearrangeMode}>
                      {(provided, snapshot) => {
                        const isDraggingMeal = snapshot.isDragging;
                        return (
                          <S.MealItemWrapper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <S.MealItem
                              key={get(meal, '_id', '')}
                              isDraggingMeal={isDraggingMeal}
                              rearrangeMode={rearrangeMode}
                              className={`meal-item-wrapper meal-item-by-${id}`}
                            >
                              <S.MealTitleWrapper>
                                <S.MealTitle rearrangeMode={rearrangeMode}>
                                  <span
                                    className="recipe-name"
                                    style={{
                                      maxWidth: !rearrangeMode ? maxWidth : 'auto',
                                    }}
                                  >
                                    {get(meal, 'name', '')}
                                  </span>
                                  {!rearrangeMode && (
                                    <div className="macro-info-wrapper">
                                      <MacroInfo macroNutrients={macroNutrients} />
                                    </div>
                                  )}
                                </S.MealTitle>
                                {rearrangeMode && <Trailing className="icon-trailing" />}
                              </S.MealTitleWrapper>
                              <S.RecipeList rearrangeMode={rearrangeMode}>
                                {get(meal, 'recipes', []).map(recipe => {
                                  const { _id, cover_image_thumbnail, name, macro_nutrients } = get(
                                    recipe,
                                    'recipe',
                                    {},
                                  );
                                  const listNutrients = getMacroNutrients(macro_nutrients);

                                  return (
                                    <S.RecipeItem
                                      key={_id}
                                      rearrangeMode={rearrangeMode}
                                      onClick={() => handleOpenRecipeDetail(_id, rearrangeMode)}
                                    >
                                      <Recipe
                                        thumbnail={cover_image_thumbnail}
                                        name={name}
                                        nutrition={listNutrients}
                                        rearrangeMode={rearrangeMode}
                                      />
                                    </S.RecipeItem>
                                  );
                                })}
                              </S.RecipeList>
                              <S.LabelMerge
                                className={`meal-item-label-merge meal-item-label-merge-${id}`}
                                rearrangeMode={rearrangeMode}
                              >
                                Merge to {get(meal, 'name', '')}
                              </S.LabelMerge>
                            </S.MealItem>
                          </S.MealItemWrapper>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                  <S.Placeholder
                    style={{
                      top: placeholderProps.clientY,
                      left: placeholderProps.clientX,
                      height: placeholderProps.clientHeight,
                      width: placeholderProps.clientWidth,
                    }}
                  />
                )}
                {hasHorizontalScroll && statusScroll !== STATUS_SCROLL.RIGHT && !isDragging && (
                  <S.ButtonArrow className="button-next" onClick={() => handleArrowButton(true)}>
                    <ArrowIcon />
                  </S.ButtonArrow>
                )}
              </S.MealList>
            ) : (
              <S.MealEmptyList
                rearrangeMode={rearrangeMode}
                className={`meal-empty-list-wrapper meal-empty-list-by-${dayIndex}`}
              >
                {!rearrangeMode && (
                  <MealEmptyList
                    onAddMeal={() => {
                      onAddMeal(hasMealPlan ? 'edit' : undefined);
                    }}
                    disabled={disabled || disabledAction}
                  />
                )}
              </S.MealEmptyList>
            )}
          </S.MealListWrapper>
        )}
      </Droppable>
    </S.Wrapper>
  );
};

export default DayMeal;
