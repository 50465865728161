import { connect } from 'react-redux';
import _ from 'lodash';
import ListExercises from './component';
import {
  addNewExerciseToAllList,
  removeExerciseNotExisted,
  updateExerciseInFreestyleSection,
} from 'redux/workout-builder/actions';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { exercise } = rootReducer;
  const exerciseItem = exercise.mostRecents || [];

  return {
    mostRecents: _.map(exerciseItem, item =>
      Object.assign({}, item, {
        key: item._id,
        label: item.title,
        value: item._id,
      }),
    ),
  };
};

const actions = {
  addNewExerciseToAllList,
  updateExerciseInFreestyleSection,
  removeExerciseNotExisted,
};

export default connect(mapStateToProps, actions)(ListExercises);
