import { Modal } from 'semantic-ui-react';
import FormItemInput from 'shared/FormItemInput';
import styled from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-weight: 400;
  line-height: 150%;
  color: #202353;
`;

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-add-onboarding-flow-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 490px;
  }
  &.ui.modal.evf-add-onboarding-flow-modal > .header,
  &.ui.modal.evf-add-onboarding-flow-modal > .content,
  &.ui.modal.evf-add-onboarding-flow-modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  padding: 24px 24px 20px;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 9px;

  .close-button {
    position: absolute;
    display: flex;
    top: -10px;
    right: -9px;
    img {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  ${textBase}
  font-size: 18px;
  font-weight: 600;
`;

export const Description = styled.div`
  ${textBase}
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
`;

export const ContentWrapper = styled.div`
  padding: 0 24px 24px;
  background-color: #ffffff;

  .formControl {
    margin-bottom: unset;
    div {
      margin-bottom: 4px;
      span {
        ${textBase}
        font-size: 9px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: #9a9da6;
      }
    }
    input {
      height: 40px;
      ${textBase}
      font-size: 13px;
      line-height: 8px;
      color: #202353;

      &::placeholder {
        ${textBase}
        font-size: 13px;
        line-height: 8px;
        color: rgba(50, 60, 71, 0.5);
      }

      &:hover:not(:disabled),
      &:focus {
        border-color: #5d59fa;
      }
    }
    .label__maxLength {
      opacity: 1;
      font-weight: 600;
    }
  }
`;

export const FormInput = styled(FormItemInput)``;

export const Footer = styled.div`
  padding: 24px 24px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  width: 112px;
  height: 40px;
  padding: 10px 16px;
  gap: 8px;
  border-radius: 4px;
  background-color: #5158cf;

  ${textBase}
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #bdbdbd;
  }
`;
