import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';

export const TableCellContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const TableCell = styled.td`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  padding: 16px 15px;

  &.actions {
    ${TableCellContent} {
      justify-content: flex-end;
    }
    .evf-dropdown__menu {
      min-width: 192px;
    }

    .evf-dropdown__option {
      font-weight: 600;
      &.isLoading {
        cursor: not-allowed;
      }

      svg {
        margin-right: 11px;
        width: 16px;

        &.white-color {
          path {
            fill: white;
          }
        }
      }
    }
  }

  ${props =>
    props.noResult &&
    css`
      text-align: center;
    `}
`;

export const TableRow = styled.tr`
  box-shadow: inset 0px -1px 0px #f0f0f0;
  &.disabled {
    pointer-events: none;
  }
`;

export const TableCellHeader = styled.th`
  box-shadow: inset 0px -2px 0px #f1f3f4;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  color: #6a778a;
  padding: 19px 15px 15px;

  span {
    margin-right: 8px;
  }

  &.actions {
    width: 60px;
  }

  ${props =>
    props.isSorting &&
    css`
      color: #5158cf;
    `}
  ${props =>
    props.sortable &&
    css`
      :hover {
        cursor: pointer;
        color: #5158cf;
      }
    `}
`;

export const TableHeader = styled.thead`
  position: relative;
  z-index: 1;
`;

export const TableBody = styled.tbody`
  ${TableRow} {
    :hover {
      background-color: #fafbfc;
      cursor: pointer;
    }

    :last-child {
      box-shadow: none;
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0 0;
  border-collapse: collapse;
  position: relative;
  width: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  flex: 1 1;
  height: 100%;
  overflow: auto;
`;

export const CustomerEmail = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 150%;
  color: #202353;
  opacity: 0.8;
`;

export const CustomerName = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
`;

export const CanceledAt = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 180%;
  color: #202353;
`;

export const OverdueAt = styled(CanceledAt)`
  font-weight: normal;
`;

export const InvoiceOverdue = styled(CanceledAt)``;

export const PriceValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  .cost {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 7px;
      width: 100%;
      height: 0.75px;
      background: #202353;
    }
  }

  .cost-by-refunded {
    margin-left: 4px;
  }
`;

export const ListWrapper = styled.div`
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  padding-top: 120px;
  .ui.loader:before {
    border-color: #ededed;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
  }
`;

export const RefundCost = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 19.5px;
`;

export const RefundDetails = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const RefundReason = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
`;

export const Tooltip = styled(Popup)`
  &.ui.inverted.popup {
    background: #2d2e2d;
    border-radius: 5px;
    z-index: 2000;
    &:after,
    &:before {
      background: #2d2e2d !important;
    }
    &:before {
      bottom: -5px !important;
    }
  }
`;

export const TooltipContent = styled.div`
  width: 228px;
  padding: 15px;
  border-radius: 5px;
  ${RefundCost} {
    margin-bottom: 17px;
  }
  .highlight {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
`;
