import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import { STEP_VIEW_MODES, DATE_FORMAT, CDN_URL } from 'constants/commonData';
import TimeSwitch from 'shared/TimeSwitch';
import { changeTimeRange, getStepGoal, setStepGoal } from 'redux/client-step/actions';
import { pluralize } from 'utils/commonFunction';
import SetTargetModal from './SetTargetModal';

import * as S from './style';
import { ReactComponent as ShoeIcon } from 'assets/icons/shoe_orange.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down_fill_black.svg';

function ChartHeader(props) {
  const { dailyAverage, dailyGoal, manualStepSelected } = props;
  const [currentMode, setCurrentMode] = useState(STEP_VIEW_MODES.week);
  const [range, setRange] = useState({
    fromDate: moment().startOf(STEP_VIEW_MODES.week.value),
    toDate: moment().endOf(STEP_VIEW_MODES.week.value),
  });
  const [showGoalForm, setShowGoalForm] = useState(false);

  useEffect(() => {
    const params = {
      fromDate: range.fromDate.format(DATE_FORMAT),
      toDate: range.toDate.format(DATE_FORMAT),
    };

    props.changeTimeRange({
      ...params,
      currentMode: currentMode.value,
    });
  }, [currentMode, range, manualStepSelected]);

  useEffect(() => {
    props.getStepGoal();
  }, [manualStepSelected]);

  const handleSelectMode = e => {
    const { mode } = e.currentTarget.dataset;
    setCurrentMode(STEP_VIEW_MODES[mode]);
    if (['last7', 'last14'].includes(mode)) {
      const toDate = moment(moment().format());
      const fromDate = moment(moment().format()).subtract(['last7'].includes(mode) ? 6 : 13, 'days');

      setRange({
        fromDate,
        toDate,
      });
    } else {
      setRange({
        fromDate: moment().startOf(STEP_VIEW_MODES[mode].value),
        toDate: moment().endOf(STEP_VIEW_MODES[mode].value),
      });
    }
  };

  const handleStartDateChange = newDate => {
    setRange({
      fromDate: moment(newDate).startOf(currentMode.value),
      toDate: moment(newDate).endOf(currentMode.value),
    });
  };

  const handleClickSetTarget = () => {
    setShowGoalForm(true);
  };

  const onCloseGoalForm = () => {
    setShowGoalForm(false);
  };

  const onSaveStepGoal = value => {
    if (parseInt(value)) {
      props.setStepGoal(value);
    }

    onCloseGoalForm();
  };

  return (
    <S.ChartHeaderWrapper className="chart-header">
      <div className="daily-average">
        <div className="title">
          <ShoeIcon />
          &nbsp;
          <span>Daily average</span>
        </div>
        <div className="value">
          <span>{dailyAverage ? dailyAverage.toLocaleString() : '-'}</span>
          <span>{pluralize('step', dailyAverage)}</span>
        </div>
      </div>
      <div className="daily-goal">
        <div className="title">
          <img src={`${CDN_URL}/images/macros_training_day_bold.svg`} width="10" style={{ marginRight: 5 }} alt="" />
          <span>Daily Steps Goal</span>
        </div>
        <div className="value">
          {!dailyGoal ? (
            <span className="set-target" onClick={handleClickSetTarget}>
              <span>Set target</span>
              <EditIcon />
            </span>
          ) : (
            <>
              <span>{dailyGoal.toLocaleString()}</span>
              <span>{pluralize('step', dailyGoal)}</span>
              <EditIcon onClick={handleClickSetTarget} />
            </>
          )}
        </div>
      </div>
      <div className="time-range">
        <Dropdown
          triggerIcon={({ open }) => (
            <div className={classnames('mode__trigger', { open })}>
              <span>by</span>&nbsp;
              <span className="value">{currentMode.label}</span>
              <ArrowIcon />
            </div>
          )}
          className="dropdown--client-group-actions"
          type="light"
        >
          {_.map(STEP_VIEW_MODES, (item, key) => (
            <Option onClick={handleSelectMode} data-mode={key} key={key}>
              <div className="mode__label">
                <span className="value">{item.label}</span>
              </div>
            </Option>
          ))}
        </Dropdown>
        <TimeSwitch
          mode={currentMode.value}
          startDate={range.fromDate}
          onChange={handleStartDateChange}
          key={currentMode.value}
          notAllowFuture
        />
      </div>
      {showGoalForm && <SetTargetModal currentValue={dailyGoal} onClose={onCloseGoalForm} onSave={onSaveStepGoal} />}
    </S.ChartHeaderWrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      clientStep: { dailyAverage, dailyGoal, manualStepSelected },
    },
  } = state;
  return { dailyAverage, dailyGoal, manualStepSelected };
};

const actions = { changeTimeRange, getStepGoal, setStepGoal };

export default connect(mapState, actions)(ChartHeader);
