import { connect } from 'react-redux';
import WorkoutDetailComponent from 'components/WorkoutDetailModal/component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  initWorkoutData,
  clearWorkoutData,
  validateWorkoutData,
  updateWorkoutId,
  updateOriginalWorkout,
} from 'redux/workout-builder/actions';
import { resetWorkingClientData } from 'redux/client/client.actionCreators';

import { getOnboardingWorkoutBuiler, markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { hideWorkout } from 'redux/calendar/calendar.actionCreators';

const mapState = state => {
  return {
    isHideLeftPanel: state.rootReducer.workoutBuilder.getIn(['isHideLeftPanel']),
    isChanged: state.rootReducer.workoutBuilder.getIn(['isChanged']),
    user: state.user,
    loadedOnboardingData: state.rootReducer.onboarding.workoutBuilderGuideSteps.loaded,
    isUploading: state.rootReducer.workoutBuilder.getIn(['isUploading']),
    workout: state.rootReducer.workoutBuilder.getIn(['selectedWorkout']),
  };
};

const actionCreators = {
  clearWorkoutData,
  toggleModal,
  initWorkoutData,
  validateWorkoutData,
  toggleConfirmModal,
  updateWorkoutId,
  updateOriginalWorkout,
  getOnboardingWorkoutBuiler,
  markWorkoutBuilderGuide,
  resetWorkingClientData,
  hideWorkout,
};

export default connect(mapState, actionCreators)(WorkoutDetailComponent);
