import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeQueryParams } from 'redux/tasks-library/actions';
import Pagination from 'shared/Pagination';
import { NO_LOADING, NO_LOAD_MORE } from '../constants';

function View(props) {
  const { page, limit, total, changeQueryParams, list } = props;

  const length = list ? list.length : null;

  if (!length || total <= limit) {
    return null;
  }

  return (
    <Pagination
      page={page}
      perPage={limit}
      totalPage={total}
      onPrev={() => {
        changeQueryParams({ page: page - 1 }, NO_LOAD_MORE, NO_LOADING);
      }}
      onNext={() => {
        changeQueryParams({ page: page + 1 }, NO_LOAD_MORE, NO_LOADING);
      }}
    />
  );
}

const Footer = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      tasksLibrary: {
        list,
        total,
        queryParam: { page, limit },
      },
    },
  } = state;

  return { total, page, limit, list };
};

const mapDispatch = dispatch => ({
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
});

export default connect(mapState, mapDispatch)(Footer);
