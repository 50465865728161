import { useState, useEffect, useRef } from 'react';
import { pluralize } from 'utils/commonFunction';

const calculateTimeRemaining = (targetDate, timerRef, isOverview) => {
  if (!targetDate) return null;

  const currentDate = new Date();
  const endDate = new Date(targetDate);

  const timeDifference = endDate - currentDate;

  if (timeDifference <= 0) {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    return null;
  }

  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (days >= 1) {
    return isOverview ? { time: days, label: pluralize('Day', days) } : `${pluralize('Day', days, true)}`;
  }
  if (hours >= 1) {
    return isOverview ? { time: hours, label: pluralize('Hour', hours) } : `${pluralize('Hour', hours, true)}`;
  }

  if (minutes === 0) return null;

  return isOverview ? { time: minutes, label: pluralize('Minute', minutes) } : `${pluralize('Minute', minutes, true)}`;
};

function useDaysRemaining(targetDate, isOverview = false) {
  const timerRef = useRef();

  const [timeRemaining, setTimeRemaining] = useState(() => calculateTimeRemaining(targetDate, timerRef, isOverview));

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeRemaining(() => calculateTimeRemaining(targetDate, timerRef, isOverview));
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [targetDate, isOverview, setTimeRemaining]);

  return timeRemaining;
}

export default useDaysRemaining;
