import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { copyAssignment, copyAssignmentToDate } from 'actions/workout/getWorkouts';
import { removeTask, pasteTask, copyTask, saveToLibrary } from 'redux/task/task.actionCreators';
import TaskCalendarCellComponent from './component';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = (state, ownProps) => {
  const { sideBarVisible, calendarType, isModalOpen, modal, popup, movingSet, rootReducer, workouts } = state;
  const { workingClientDetail } = rootReducer.client;
  const { permission } = rootReducer;

  let day_tasks = rootReducer.task.day_tasks;
  let currentTaskDay = day_tasks.find(day_tasks => {
    return day_tasks.day === ownProps.day.toFormat('MM-dd-yyyy');
  });
  let currentAssignment = workouts.get(ownProps.day.toFormat('MM-dd-yyyy'));
  let currentTasks = [];
  if (currentTaskDay) {
    currentTasks = currentTaskDay.tasks;
  }
  let copyingTask = rootReducer.task.copyingTask;

  currentTasks.sort((a, b) => {
    return a.day_index > b.day_index ? 1 : a.day_index < b.day_index ? -1 : 0;
  });

  const currentHabits = _.get(currentTaskDay, 'habits', []);

  return {
    sideBarVisible,
    currentTasks,
    currentHabits,
    currentTaskDay,
    currentAssignment,
    calendarType,
    isModalOpen,
    modal,
    copyingTask,
    bodymetricTypes: rootReducer.client.bodymetricTypes,
    repeatTaskPermission: rootReducer.permission.repeat_task,
    repeatTaskAutoFlowPermission: rootReducer.permission.repeat_task_autoflow,
    popup,
    movingSet,
    selectedClient: _.get(workingClientDetail, '_id', ''),
    timezone: _.get(workingClientDetail, 'timezone', ''),
    permission,
  };
};

const copyCurrentAssignment = (dispatch, assignment) => {
  dispatch(copyAssignment(assignment));
};

const pasteCopiedAssignment = (dispatch, params) => {
  dispatch(copyAssignmentToDate(params));
};

const removeCurrentTask = (dispatch, params) => {
  dispatch(removeTask(params));
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    copyCurrentAssignment: assignment => copyCurrentAssignment(dispatch, assignment),
    pasteCopiedAssignment: params => pasteCopiedAssignment(dispatch, params),
    removeCurrentTask: params => removeCurrentTask(dispatch, params),
    pasteCurrentTask: bindActionCreators(pasteTask, dispatch),
    copyTask: bindActionCreators(copyTask, dispatch),
    push: bindActionCreators(push, dispatch),
    saveToLibrary: bindActionCreators(saveToLibrary, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCalendarCellComponent);
