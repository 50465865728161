import styled from 'styled-components';
import { FormGroup as FG } from 'shared/FormControl';

export const FormGroup = styled(FG)`
  margin-bottom: 20px;

  input {
    padding: 13px 14px;
    background: #ffffff;
    border: 1px solid #dadfea;
    border-radius: 3px;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    height: auto;

    ::placeholder {
      color: #202353;
      opacity: 0.4;
    }
  }

  &.form-group--state {
    flex: 0 0 160px;

    &.error {
      .billing-select__control {
        background: rgba(234, 49, 74, 0.05);
        border-color: #ee2525;
      }
    }
  }

  &.form-group--postal-code {
    flex: 0 0 130px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 2}, 1fr);
  grid-gap: 10px;

  ${FormGroup} {
    margin-bottom: 0;
  }
`;

export const USFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${FormGroup} {
    margin-right: 10px;
    margin-bottom: 0;

    :last-child {
      margin-right: 0;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 0;
  background-color: transparent;
  width: 100%;

  .billing-select-container {
    .billing-select__control {
      background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
      border: 1px solid #dadfea;
      border-radius: 3px;
      padding: 0 6px;
      box-shadow: none !important;

      :hover,
      &.billing-select__control--menu-is-open,
      &.billing-select__control--is-focused {
        border-color: #5158cf !important;
      }

      .billing-select__indicator-separator {
        display: none;
      }

      .billing-select__input {
        input {
          height: auto;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #323c47;
        }
      }

      .billing-select__single-value {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #323c47;
      }

      .billing-select__placeholder {
        font-size: 13px;
        line-height: 18px;
        color: #202353;
        opacity: 0.4;
      }
    }

    .billing-select__menu {
      overflow: hidden;

      .billing-select__menu-list {
        .billing-select__option {
          background: #fff;
          font-size: 13px;
          line-height: 18px;
          color: #000000;
          cursor: pointer;

          &.billing-select__option--is-selected,
          &.billing-select__option--is-focused {
            background: #f4f4ff !important;
          }
        }
      }
    }
  }

  input[type='number'] {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .form-group--postal-code {
    input {
      text-transform: uppercase;

      ::placeholder {
        text-transform: none;
      }
    }
  }
`;
