import React from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import CheckWhite from 'assets/icons/checked_white.svg';

const Wrapper = styled.label`
  padding: 0;
  padding-left: 21px;
  cursor: pointer;
  user-select: none;
  font-family: Open Sans;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818da1;
  display: inline-block;
  position: relative;

  &.disabled {
    cursor: not-allowed;
  }

  .checkbox-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: ${props => (props.size || 12) + 'px'};
    width: ${props => (props.size || 12) + 'px'};
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px #cdcdcd;
    background-image: unset;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    left: 0;

    :checked ~ .checkbox-icon {
      background-color: #5158cf;
      border-color: #5158cf;
      background-image: url(${CheckWhite});
    }
  }

  &.thirdState {
    .checkbox-icon {
      border-color: #5c58c0;
      background-color: rgba(92, 88, 192, 0.2);
    }
  }

  ${props =>
    !!props.circle &&
    css`
      .checkbox-icon {
        border-radius: 50%;
      }
    `}
`;

const Checkbox = props => (
  <Wrapper
    className={classNames('evf-checkbox', props.className, {
      disabled: props.disabled,
      thirdState: props.thirdState,
    })}
    circle={props.circle}
    size={props.size}
    onClick={props.onClick}
  >
    {props.title || null}
    <input
      id={props.id}
      type="checkbox"
      onChange={props.onChange}
      checked={!!props.checked}
      disabled={props.disabled}
    />
    <span data-checkbox={props.dataAttribute} className="checkbox-icon" />
  </Wrapper>
);

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.any,
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  size: PropTypes.number,
  onChange: PropTypes.func,
  dataAttribute: PropTypes.string,
};

export default Checkbox;
