import React from 'react';
import { Modal, Image, Grid } from 'semantic-ui-react';
import _ from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import diff from 'deep-diff';
import classnames from 'classnames';
import Datetime from 'react-datetime';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { classToPlain } from 'class-transformer';
import Request from 'configs/request';
import TaskAddNew from 'components/TaskAddNew';
import ConfirmModal from 'shared/ConfirmModal';
import { DropdownIndicator } from 'shared/Icons';
import { Button, DateInput, ErrorMessage } from 'shared/FormControl';
import Urlify from 'shared/Urlify';
import TaskComment from './TaskComment';
import Reminder from './Reminder';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import GeneralTaskForm from './GeneralTaskForm';
import { MEDIA_TYPES, ATTACHMENT_TYPES } from './GeneralTaskForm/helper';
import { FormGroup } from 'shared/FormControl';
import WeeklyRepeat from 'components/TaskRepeat/WeeklyRepeat';
import MonthlyRepeat from 'components/TaskRepeat/MonthlyRepeat';
import RepeatTaskConfirm from 'components/TaskRepeat/ConfirmModal';
import TextEditable from 'shared/TextEditable';
import UpgradePath from 'shared/UpgradePath';
import { CONFIRM_TYPES } from 'components/TaskRepeat/ConfirmModal/constants';
import IntervalRepeat from 'components/TaskRepeat/IntervalRepeat';
import AdvancedSettings from './AdvancedSettings';
import FormQuestionnaire from './FormQuestionnaire';

import { Mixpanel } from 'utils/mixplanel';
import { autoAddHttps, validateLink } from 'utils/validations';
import {
  AUTOFLOW_TYPES,
  REPEAT_TASK_TYPES,
  STEP_UNIQUE_CODE,
  DEFAULT_TASK_REPEAT_MONTHLY,
  DEFAULT_TASK_ADVANCED_SETTINGS,
  TASK_TYPES,
  TEAM_SHARE_PRIVATE,
  TEAM_SHARE_NOOWNER,
  CDN_URL,
} from 'constants/commonData';
import OwnershipPopup from 'components/OwnershipPopup';
import { downloadFileFromURL, generateGroupWeeks, getAvailableMetrics, getExtension } from 'utils/commonFunction';
import { ReactComponent as AddIcon } from 'assets/icons/add_grey.svg';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left-black.svg';
import { ReactComponent as TaskForm } from 'assets/icons/task_option_form.svg';

import './styles.scss';
import * as S from './style';
import { checkLinkFormat } from '../../utils/validations';

const DEFAULT_REMINDER_TIME = '08:00 AM';
const REPEAT_TYPES = _.cloneDeep(REPEAT_TASK_TYPES);

export default class TaskCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      intervalRepeatErrors: [],
      frequency: 'weekly',
      monthlyType: 1,
      task: {
        attachments: {},
        repeat_task_setting_id: {},
      },
      originTask: {},
      bodymetricTypes: props.bodyMetricsFromLibrary
        ? getAvailableMetrics(_.cloneDeep(get(props, 'bodyMetricsFromLibrary', []).filter(item => item.selected)))
        : _.cloneDeep(props.bodymetricTypes),
      contentScrollAuto: true,
      canSubmit: true,
      uploadingFiles: [],
      repeatType: REPEAT_TYPES[0],
      isRepeat: false,
      isRepeatLoading: false,
      showUpdateRepeatSettingConfirModal: false,
      showUpdateTaskDetailConfirmModal: false,
      showUpdateNormalToRepeatConfirmModal: false,
      isConfirmRemoveSettingRepeat: false,
      isConfirmChangeSetDate: false,
      modeFromLibrary: props.modeFromLibrary,
    };
    this.uploadedAllComment = true;
    this.resizeCallbackDebounce = _.debounce(this.handleModalContentSizeChange, 150);
    this.observer = new ResizeObserver(this.resizeCallbackDebounce);
    this.observed = false;
    this.modalContent = React.createRef();
    this.shouldCloseAfterSave = false;
    this.noteInput = React.createRef();
  }

  getRepeatType = () => {
    const {
      task: { repeat_task_setting_id },
    } = this.props;
    if (repeat_task_setting_id) {
      return _.isEqual(repeat_task_setting_id.type, REPEAT_TYPES[0].value) ? REPEAT_TYPES[0] : REPEAT_TYPES[1];
    }
    return REPEAT_TYPES[0];
  };

  componentDidMount() {
    const { task, date, autoflowType } = this.props;
    const mode = task && task._id ? 'edit' : 'add';
    let oldWeekIndex;
    let oldDayIndex;

    if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
      oldWeekIndex = parseInt(date / 7);
      oldDayIndex = date % 7;
    }

    if (mode === 'edit') {
      // edit mode, task is already created
      this.setState(
        {
          oldWeekIndex,
          oldDayIndex,
          date,
          repeatType: this.getRepeatType(),
          task: task,
          isRepeat: task.repeat_task_setting_id ? true : false,
        },
        () => {
          this.initStateFromTask(task);
        },
      );
    } else {
      // add mode, creating new task
      const defaultTaskData = this.initTaskObject(task);
      this.setState({
        task: _.cloneDeep(defaultTaskData),
        originTask: _.cloneDeep(defaultTaskData),
        oldWeekIndex,
        oldDayIndex,
      });
    }

    setTimeout(() => {
      if (this.modalContent.current) {
        const { clientHeight } = this.modalContent.current;

        if (clientHeight > 330) {
          if (!this.state.contentScrollAuto) {
            this.setState({ contentScrollAuto: true });
          }
        } else {
          if (this.state.contentScrollAuto) {
            this.setState({ contentScrollAuto: false });
          }
        }
      }
    }, 0);
  }

  componentDidUpdate() {
    if (this.modalContent.current) {
      if (!this.observed) {
        this.observer.observe(this.modalContent.current);
        this.observed = true;
      }
    } else {
      this.observer.disconnect();
      this.observed = false;
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  toggleConfirmSettingRepeat = () => {
    this.setState(state => ({
      showUpdateRepeatSettingConfirModal: !state.showUpdateRepeatSettingConfirModal,
    }));
  };

  toggleUpdateTaskDetail = () => {
    this.setState(state => ({
      showUpdateTaskDetailConfirmModal: !state.showUpdateTaskDetailConfirmModal,
    }));
  };

  toggleUpdateNormalToRepeat = () => {
    this.setState(state => ({
      showUpdateNormalToRepeatConfirmModal: !state.showUpdateNormalToRepeatConfirmModal,
    }));
  };

  toggleUpdateSetDate = () => {
    this.setState(state => ({
      isConfirmChangeSetDate: !state.isConfirmChangeSetDate,
    }));
  };

  handleModalContentSizeChange = entries => {
    if (entries[0]) {
      const {
        contentRect: { height },
      } = entries[0];

      if (height > 330) {
        if (!this.state.contentScrollAuto) {
          this.setState({ contentScrollAuto: true });
        }
      } else {
        if (this.state.contentScrollAuto) {
          this.setState({ contentScrollAuto: false });
        }
      }
    }
  };

  initStateFromTask = task => {
    const { autoflowType, isAddTaskFromLibrary, user } = this.props;
    const { bodymetricTypes, date, modeFromLibrary } = this.state;
    const dateData =
      autoflowType === AUTOFLOW_TYPES.INTERVAL
        ? { key: date, label: `Day ${date + 1}`, value: date }
        : moment(task.day, 'MM-DD-YYYY').format('YYYY-MM-DDTHH:mm:ss.sssZ');

    if (task.metrics) {
      task.metrics = _.filter(task.metrics, metric => {
        return _.find(bodymetricTypes, metricType => metricType._id === metric);
      });
    }

    let repeat_task_setting_id;

    if (task.repeat_task_setting_id) {
      repeat_task_setting_id = { ...task.repeat_task_setting_id };
    }

    const isDuplicate = isAddTaskFromLibrary && modeFromLibrary === 'duplicate';

    this.setState({
      task: _.cloneDeep({
        ...task,
        title: isDuplicate ? '' : task.title,
        owner: isDuplicate ? get(user, '_id', '') : get(task, 'owner', ''),
        share: isDuplicate ? TEAM_SHARE_PRIVATE : get(task, 'share', ''),
        status: isDuplicate ? 0 : get(task, 'status', 0),
        date: dateData,
        schedule_reminder: task.schedule_reminder || false,
        reminder_time: task.reminder_time || DEFAULT_REMINDER_TIME,
        repeat_task_setting_id,
      }),
      originTask: _.cloneDeep({
        ...task,
        title: isDuplicate ? '' : task.title,
        owner: isDuplicate ? get(user, '_id', '') : get(task, 'owner', ''),
        share: isDuplicate ? TEAM_SHARE_PRIVATE : get(task, 'share', ''),
        status: isDuplicate ? 0 : get(task, 'status', 0),
        date: dateData,
        reminder_time: task.reminder_time || DEFAULT_REMINDER_TIME,
        schedule_reminder: task.schedule_reminder || false,
        repeat_task_setting_id,
      }),
    });
  };

  initTaskObject = task => {
    const { date, type, autoflowType, owner } = this.props;

    const taskType = type || task.type;
    const initSetting = task ? {} : DEFAULT_TASK_ADVANCED_SETTINGS[taskType.toUpperCase()];

    return {
      metrics: [],
      type: type,
      schedule_reminder: false,
      reminder_time: DEFAULT_REMINDER_TIME,
      attacchments: {},
      owner: owner,
      share: TEAM_SHARE_PRIVATE,
      ...task,
      ...initSetting,
      date:
        autoflowType !== AUTOFLOW_TYPES.INTERVAL
          ? date || moment().format('YYYY-MM-DDTHH:mm:ss.sssZ')
          : date
          ? { key: date, label: `Day ${date + 1}`, value: date }
          : { key: 1, label: 'Day 1', value: 0 },
    };
  };

  onSelectDay = data => {
    this.setState({ task: { ...this.state.task, date: data } });
  };

  renderDatetimeInput = props => {
    const { task } = this.state;

    return <DateInput {...props} text={(task.date ? moment(task.date) : moment()).format('MMM D, YYYY (ddd)')} />;
  };

  onSelectDate = momentData => {
    const { task } = this.state;

    if (task.status) {
      return true;
    }

    if (moment.isMoment(momentData)) {
      this.setState({
        task: { ...task, date: momentData.format('YYYY-MM-DDTHH:mm:ss.sssZ') },
      });
    }
  };

  onChangeReminderTime = data => {
    this.setState({ task: { ...this.state.task, reminder_time: data.label } });
  };

  onToggleScheduleReminder = event => {
    const { task } = this.state;
    const { checked } = event.target;
    this.setState({ task: { ...task, schedule_reminder: checked } });
  };

  handleNoteChange = value => {
    this.setState({ task: { ...this.state.task, note: value } });
  };

  onChangeAttachmentsGeneralTask = attachments => {
    const newStates = { task: { ...this.state.task, attachments } };

    if (!attachments) {
      newStates.uploadingFiles = [];
    }

    this.setState(newStates);
  };

  onChangeTaskName = event => {
    const { value } = event.target;
    const errors = { ...this.state.errors, title: false };
    if (!value || (value && !value.trim())) {
      errors.title = true;
    }

    this.setState({ task: { ...this.state.task, title: value }, errors: errors });
  };

  handleChangeRepeatType = item => {
    const { task, repeatType, originTask } = this.state;

    if (repeatType.value === item.value) {
      return;
    }

    const repeatSettings = {
      ...get(originTask, 'repeat_task_setting_id'),
      type: item.value,
    };

    if (item.value === REPEAT_TASK_TYPES[1].value) {
      repeatSettings.day_of_month = Number(moment(repeatSettings.start_date).format('D'));
      repeatSettings.for_the_next = get(originTask, 'repeat_task_setting_id.for_the_next', DEFAULT_TASK_REPEAT_MONTHLY);
    }

    this.setState({
      repeatType: item,
      task: { ...task, repeat_task_setting_id: repeatSettings },
    });
  };

  handleChangeTotalRepeatMonth = totalMonth => {
    this.setState(prev => ({
      task: { ...prev.task, repeat_task_setting_id: { ...prev.task.repeat_task_setting_id, for_the_next: totalMonth } },
    }));
  };

  updateUploadStatus = uploadList => {
    const newUploadingFiles = uploadList.reduce((list, currentItem) => {
      if (currentItem.status) {
        return list.filter(id => id !== currentItem.uploadId);
      }

      return [...list, currentItem.uploadId];
    }, this.state.uploadingFiles.slice());

    this.setState({ uploadingFiles: newUploadingFiles });
  };

  toggleRepeatIcon = () => {
    const { isRepeat } = this.state;

    if (isRepeat) {
      return false;
    }

    this.setState({ isRepeat: !this.state.isRepeat });
  };

  handleConfirmUpdateSetDate = isOnly => {
    const { addRepeatTask } = this.props;
    const { loading } = this.state;

    if (loading) return;

    this.setState({
      loading: true,
    });

    addRepeatTask(this.getRepeatSettingParams()).then(res => {
      this.setState({
        loading: false,
      });
      this.handleSubmitForm(get(res, 'data._id'), isOnly);
      this.toggleUpdateSetDate();
    });
  };

  //Repeat task change value
  handleOnChangeRepeat = item => {
    const { repeatType } = this.state;
    this.setState(prevState => ({
      task: {
        ...prevState.task,
        repeat_task_setting_id: {
          ...prevState.task.repeat_task_setting_id,
          ...item,
          type: repeatType.value,
        },
      },
    }));
  };

  toggleRemoveSettingRepeat = () => {
    const { task } = this.props;
    if (!task || !task.repeat_task_setting_id) {
      this.setState(state => ({
        isRepeat: false,
        task: {
          ...state.task,
          repeat_task_setting_id: undefined, // Reset repeat_task_setting_id
        },
      }));
    } else {
      this.setState(state => ({
        isConfirmRemoveSettingRepeat: !state.isConfirmRemoveSettingRepeat,
      }));
    }
  };

  handleRemoveSettingRepeat = () => {
    const { task, removeSettingRepeat, autoflowType } = this.props;
    const { oldDayIndex, oldWeekIndex } = this.state;

    let params = {
      taskId: task._id,
      is_only_this_task: false,
      repeatId: get(task, 'repeat_task_setting_id._id'),
    };

    if (autoflowType) {
      params = {
        ...params,
        autoflowId: task.autoflow,
      };
      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        params = {
          ...params,
          dayIndex: oldDayIndex,
          weekIndex: oldWeekIndex,
        };
      }
    } else {
      params = {
        ...params,
        taskDay: task.day,
      };
    }

    if (removeSettingRepeat) {
      removeSettingRepeat(params).then(() => {
        this.setState(state => ({
          task: {
            ...state.task,
            repeat_task_setting_id: '',
          },
        }));
        this.onCloseCurrentPopup();
      });
    }
  };

  handleToggleAddNote = () => {
    const { task, isAddingNote } = this.state;

    if (!task.status) {
      if (isAddingNote || task.note) {
        if (task.note) {
          this.setState({ task: { ...task, note: '' }, isAddingNote: false });
        } else {
          this.setState({ isAddingNote: false });
        }
      } else {
        this.setState({ isAddingNote: true }, () => {
          setTimeout(() => {
            if (this.noteInput.current) {
              this.noteInput.current.focus();
            }
          }, 0);
        });
      }
    }
  };

  handleRepeatInterval = item => {
    const limitTimes = get(item, 'autoflow_interval_repeat_type') === 'day' ? 20 : 26;
    let intervalErrors = [];
    if (!get(item, 'every')) {
      intervalErrors.push('every');
    } else {
      intervalErrors = intervalErrors.filter(item => item !== 'every');
    }
    if (!get(item, 'autoflow_interval_times') || get(item, 'autoflow_interval_times') > limitTimes) {
      intervalErrors.push('times');
    } else {
      intervalErrors = intervalErrors.filter(item => item !== 'times');
    }

    this.setState(prev => ({
      task: {
        ...prev.task,
        repeat_task_setting_id: {
          ...prev.task.repeat_task_setting_id,
          ...item,
          type: 'repeat_task_autoflow_interval',
        },
      },
      intervalRepeatErrors: intervalErrors,
    }));
  };

  handleToggleComment = () => {
    this.setState(prev => ({
      task: {
        ...prev.task,
        enable_comment: !prev.task.enable_comment,
      },
    }));
  };

  handleToggleReminder = () => {
    this.setState(prev => ({
      task: {
        ...prev.task,
        show_reminder_missed: !prev.task.show_reminder_missed,
      },
    }));
  };

  handleGetAttachment = (key, filename) => {
    const taskId = get(this.state, 'task._id');
    const taskLibraryId = get(this.state, 'task.taskLibraryId');
    const isAddTaskFromLibrary = get(this.props, 'isAddTaskFromLibrary', false);
    const isFromLibrary = (!taskId && taskLibraryId) || isAddTaskFromLibrary ? true : false;
    // NOTE: use different API and body depending on downloading document from saved task or from library
    if ((taskId || taskLibraryId) && key) {
      const { getAttachmentUrl, getAttachmentUrlInLibrary } = this.props;
      const downloadLink = !isFromLibrary ? getAttachmentUrl : getAttachmentUrlInLibrary;

      this.props.dispatch(
        Request.post(
          {
            url: downloadLink,
            data: {
              ...(!isFromLibrary
                ? { taskId: taskId }
                : { taskLibraryId: isAddTaskFromLibrary ? taskId : taskLibraryId }),
              key: key,
            },
          },
          true,
          res => {
            const { data } = res.data;
            downloadFileFromURL(data.Body, data.ContentType, filename, getExtension(key)).catch(() => {
              toast.error('Could not download file.');
            });
          },
        ),
      );
    }
  };

  renderGroup = props => <components.Group {...props} />;

  render() {
    const {
      isAddingNote,
      task,
      contentScrollAuto,
      uploadingFiles,
      isRepeat,
      repeatType,
      showUpdateRepeatSettingConfirModal,
      isConfirmRemoveSettingRepeat,
      showUpdateTaskDetailConfirmModal,
      showUpdateNormalToRepeatConfirmModal,
      isConfirmChangeSetDate,
      originTask,
      intervalRepeatErrors,
      errors,
    } = this.state;
    const { autoflowType, timezone, isAddTaskFromLibrary, user, modeFromLibrary } = this.props;

    let popupName;

    const isEdit = !!task._id;

    switch (task.type) {
      case TASK_TYPES.BODY_METRIC:
        popupName = 'Body Metrics';
        break;
      case TASK_TYPES.GENERAL:
        popupName = 'General';
        break;
      case TASK_TYPES.PROGRESS_PHOTO:
        popupName = 'Progress Photo';
        break;
      case TASK_TYPES.FORM:
        popupName = 'Forms';
        break;
      default:
        break;
    }

    const isHideSave =
      !task.originalAutoflow &&
      ((isEdit && diff(classToPlain(task.repeat_task_setting_id), classToPlain(originTask.repeat_task_setting_id))) ||
        (!isEdit && isRepeat) ||
        (diff(task.date, originTask.date) && originTask.repeat_task_setting_id));

    const today = moment.tz(moment(), timezone).format('M/D');
    const isAutoflowInterval = autoflowType === AUTOFLOW_TYPES.INTERVAL;

    const removeSettingRepeatMessage = isAutoflowInterval
      ? 'If you remove the repeat, this and all upcoming instances of the task will be removed. Tasks in the sequence prior to this one will not be affected. Would you like to proceed?'
      : `You have removed the repeat, so all future instances of the task (including today ${today} and onwards) will be removed. The original task will be updated accordingly. Would you like to proceed?`;
    const updateSettingRepeatMessage = isAutoflowInterval
      ? 'You are changing how the task repeats, so this and all upcoming instances of the task will be updated according to the changes. Would you like to proceed?'
      : `You are changing how the task repeats, so all future instances of the task (including today ${today} and onwards) will be updated according to your changes. Would you like to proceed?`;
    const changeSetDateMessage = isAutoflowInterval
      ? 'You are changing how the task repeats, so this and all upcoming instances of the task will be updated according to the changes. Would you like to proceed?'
      : `You are changing how the task repeats, so all future instances of the task (including today ${today} and onwards) will be updated according to your changes. Would you like to proceed?`;
    const pathName = autoflowType ? 'repeat_task_autoflow' : 'repeat_task';

    // Change from 52 weeks to 26 weeks
    const forTheRepeat = get(task, 'repeat_task_setting_id.for_the_next', 2);
    const limitRepeatWeek = forTheRepeat > 26 ? 26 : forTheRepeat;
    const limitRepeatMonth = forTheRepeat > 12 ? 2 : forTheRepeat;

    const currentIntervalType = isAutoflowInterval && get(task, 'repeat_task_setting_id.autoflow_interval_repeat_type');
    const limitTimes = currentIntervalType === 'day' ? 20 : 26;
    const overLimitTimes =
      isAutoflowInterval && get(task, 'repeat_task_setting_id.autoflow_interval_times') > limitTimes;

    const taskId = get(this.state, 'task._id');

    const shouldAllowDownload = true;
    const isShow =
      get(task, 'owner', '') !== get(user, '_id', '') && get(task, 'share', TEAM_SHARE_PRIVATE) === TEAM_SHARE_PRIVATE;

    const isDuplicate = isAddTaskFromLibrary && modeFromLibrary === 'duplicate';
    const ownerId = isDuplicate ? get(user, '_id', '') : get(task, 'owner', '');
    const shareType = isDuplicate ? TEAM_SHARE_PRIVATE : get(task, 'share', '');

    const params = new URLSearchParams(window.location.search);
    const openCommentTask = _.isEqual(params.get('comment'), 'show');

    return (
      <S.TaskModal
        size={'tiny'}
        open={this.props.isModalOpen}
        className={!isEdit ? 'create-task' : 'create-task edit-task'}
        closeOnDimmerClick={false}
        onClose={this.handleCloseAction}
      >
        <S.TaskModalContainer>
          <S.TaskForm className={classnames({ 'task--general': task.type === TASK_TYPES.GENERAL })}>
            <Modal.Header className="modal__header">
              {!isEdit && (
                <div className="button--back" onClick={this.handleBackClick}>
                  <BackIcon />
                  <div>Back</div>
                </div>
              )}
              <div className="title">
                {task.type === TASK_TYPES.FORM ? (
                  <TaskForm width={16} height={13} />
                ) : (
                  <Image src={`${CDN_URL}/images/task_${task.type}.svg`} width={16} height={13} />
                )}
                <p>{popupName}</p>
              </div>
              <button className="close-button" onClick={this.handleCloseAction}>
                <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
              </button>
            </Modal.Header>
            <Modal.Content
              className={classnames({
                'content-add-metric': task.type === TASK_TYPES.GENERAL,
                'scroll-auto': contentScrollAuto,
              })}
              ref={this.modalContent}
            >
              <S.FormContainer>
                {!isAddTaskFromLibrary && task._id && (
                  <S.ExtendActionsStyle className="workoutBuilder__extendActions">
                    <Dropdown className="actions">
                      <Option key="log-workout-save" onClick={this.saveToLibrary}>
                        <S.OptionIcon icon="floppy-disk" darkMode>
                          Save to Library
                        </S.OptionIcon>
                      </Option>
                      <Option key="log-workout-del" onClick={this.handleDeleteTask}>
                        <S.OptionIcon icon="delete" darkMode>
                          Delete
                        </S.OptionIcon>
                      </Option>
                    </Dropdown>
                  </S.ExtendActionsStyle>
                )}
                {isAddTaskFromLibrary && (
                  <S.ExtendActionsStyle className="button--share">
                    <OwnershipPopup
                      isEditAble={task.share === TEAM_SHARE_NOOWNER || get(task, 'owner', '') === get(user, '_id', '')}
                      owner={ownerId}
                      share={shareType}
                      handleChangeOwner={this.handleChangeOwner}
                      handleChangeShare={this.handleChangeShare}
                      hasYou
                    />
                  </S.ExtendActionsStyle>
                )}
                <FormGroup
                  largeMargin
                  className={classnames('input--task-name', {
                    error: get(errors, 'title', false),
                  })}
                >
                  <div className="title__wrapper">
                    <label>Task Name</label>
                    <span className="count">{get(task, 'title.length', 0)} / 90</span>
                  </div>
                  {task.status ? (
                    <div className="completed-task__read-only title__read-only">
                      <Urlify>{task.title}</Urlify>
                    </div>
                  ) : (
                    <>
                      <input
                        autoFocus={!task.title}
                        disabled={!!task.status}
                        value={task.title}
                        onChange={this.onChangeTaskName}
                        maxLength="90"
                        className={`${get(errors, 'title', false) ? 'border--errors' : ''}`}
                      />
                      {get(errors, 'title', false) ? (
                        <ErrorMessage className="task--error-message">Please add a task name</ErrorMessage>
                      ) : null}
                    </>
                  )}
                </FormGroup>
                {task.type === TASK_TYPES.BODY_METRIC ? (
                  this.renderListMetric()
                ) : task.type === TASK_TYPES.GENERAL ? (
                  <GeneralTaskForm
                    note={task.note}
                    attachments={task.attachments || {}}
                    onNoteChange={this.handleNoteChange}
                    onChangeAttachments={this.onChangeAttachmentsGeneralTask}
                    updateUploadStatus={this.updateUploadStatus}
                    onGetAttachment={this.handleGetAttachment}
                    taskId={taskId}
                    shouldAllowDownload={shouldAllowDownload}
                  />
                ) : null}
                {!task.status && task.type !== TASK_TYPES.GENERAL && (
                  <S.NoteContainer isAddingNote={isAddingNote}>
                    <Button onClick={this.handleToggleAddNote} textOnly className="button--addNote">
                      <AddIcon />
                      <span>{isAddingNote || task.note ? 'Remove note' : 'Add note'}</span>
                    </Button>
                    {(isAddingNote || task.note) && (
                      <TextEditable
                        value={task.note}
                        onChange={this.handleNoteChange}
                        placeholder="Add Note"
                        lineHeight={20}
                        breakLine={true}
                        ref={this.noteInput}
                      />
                    )}
                  </S.NoteContainer>
                )}
                {!!task.status && task.note && task.type !== TASK_TYPES.GENERAL ? (
                  <React.Fragment>
                    <div className="exercise-header-text">NOTE</div>
                    <div className="completed-task__read-only">
                      <Urlify>{task.note}</Urlify>
                    </div>
                  </React.Fragment>
                ) : null}
                {task.type === TASK_TYPES.FORM ? (
                  <div className="form-custom">
                    <FormQuestionnaire
                      form={task.form || {}}
                      task={task}
                      onSelectForm={this.handleSelectForm}
                      toggleSecondModal={this.props.toggleSecondModal}
                      getAllForms={this.props.getAllForms}
                      className="form-wrapper"
                    />
                    {get(errors, 'form', false) ? (
                      <ErrorMessage className="task--error-message task--noActions">
                        Please select at least one form
                      </ErrorMessage>
                    ) : null}
                  </div>
                ) : null}
                {!isAddTaskFromLibrary && <div className="set-date-label">SET DATE</div>}
                <S.SetTimeWrapper isAddTaskFromLibrary={isAddTaskFromLibrary}>
                  {!isAddTaskFromLibrary && !isAutoflowInterval && (
                    <Datetime
                      value={moment(task.date)}
                      disabled={!!task.status}
                      renderInput={this.renderDatetimeInput}
                      timeFormat={false}
                      onChange={this.onSelectDate}
                      closeOnSelect={true}
                      className="new-ui"
                    />
                  )}
                  {isAutoflowInterval && (
                    <Select
                      options={generateGroupWeeks(this.props.totalWeeks)}
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                        Group: this.renderGroup,
                      }}
                      onChange={this.onSelectDay}
                      classNamePrefix="evfSelectBox"
                      className="evfSelectBoxContainer autoflow-date-select"
                      value={task.date}
                      isSearchable={false}
                      styles={{
                        control: base => ({ ...base, minHeight: 30, height: 30, minWidth: 200 }),
                        minWidth: 200,
                      }}
                      menuPosition="fixed"
                    />
                  )}
                  <Reminder
                    isAddTaskFromLibrary={isAddTaskFromLibrary}
                    scheduleReminder={task.schedule_reminder}
                    reminderTime={task.reminder_time}
                    onChangeReminderTime={this.onChangeReminderTime}
                    onToggleScheduleReminder={this.onToggleScheduleReminder}
                  />
                </S.SetTimeWrapper>
                <UpgradePath pathName={pathName} fallback={<></>}>
                  {!isAddTaskFromLibrary && !task.originalAutoflow && (
                    <S.RepeatIconWrapper className={isRepeat && 'active'}>
                      <S.RepeatIconAction onClick={this.toggleRepeatIcon}>
                        <S.RepeatIcon className={`icon-repeat ${isRepeat && 'icon-active'}`} />
                        <span className="repeat-title">Repeat</span>
                      </S.RepeatIconAction>
                      {overLimitTimes && (
                        <S.RepeatError>
                          Set repeat for maximum {limitTimes} {currentIntervalType === 'day' ? 'times' : 'weeks'}
                        </S.RepeatError>
                      )}
                    </S.RepeatIconWrapper>
                  )}
                </UpgradePath>
              </S.FormContainer>
              {isRepeat && !task.originalAutoflow && (
                <UpgradePath pathName={pathName} fallback={<></>}>
                  {!isAutoflowInterval && (
                    <S.RepeatWrapper>
                      <S.Frequency>
                        <S.RepeatTitle>Frequency</S.RepeatTitle>
                        <Select
                          options={REPEAT_TYPES}
                          classNamePrefix="evfSelectBox"
                          className="evfSelectBoxContainer"
                          menuPosition="fixed"
                          defaultValue={repeatType}
                          onChange={this.handleChangeRepeatType}
                          components={{ DropdownIndicator, IndicatorSeparator: null }}
                          isSearchable={false}
                          styles={{
                            control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 110 }),
                          }}
                        />
                        <S.RemoveRepeat onClick={this.toggleRemoveSettingRepeat}>Remove repeat</S.RemoveRepeat>
                      </S.Frequency>
                      {_.isEqual(repeatType, REPEAT_TASK_TYPES[0]) ? (
                        <WeeklyRepeat
                          onChange={this.handleOnChangeRepeat}
                          everyRepeat={get(task, 'repeat_task_setting_id.every', 1)}
                          nextWeekRepeat={limitRepeatWeek}
                          // Selected repeat days, or dafault current day
                          repeatDays={get(task, 'repeat_task_setting_id.date_selected_in_weekly', [
                            moment(task.date).day() || 7,
                          ])}
                          defaultSelected={moment(task.date).day() || 7}
                        />
                      ) : (
                        <MonthlyRepeat
                          selectedDate={task.date}
                          totalMonth={limitRepeatMonth}
                          onChange={this.handleChangeTotalRepeatMonth}
                        />
                      )}
                    </S.RepeatWrapper>
                  )}
                  {isAutoflowInterval && (
                    <S.RepeatWrapper>
                      <S.IntervalRepeatTaskWrapper>
                        <S.RemoveRepeat onClick={this.toggleRemoveSettingRepeat}>Remove repeat</S.RemoveRepeat>
                        <IntervalRepeat
                          onChange={this.handleRepeatInterval}
                          selectedType={get(task, 'repeat_task_setting_id.autoflow_interval_repeat_type', 'day')}
                          selectedEvery={get(task, 'repeat_task_setting_id.every', 1)}
                          selectedTimes={get(task, 'repeat_task_setting_id.autoflow_interval_times', 5)}
                          errors={intervalRepeatErrors}
                        />
                      </S.IntervalRepeatTaskWrapper>
                    </S.RepeatWrapper>
                  )}
                </UpgradePath>
              )}
              <S.Breaker />
              <AdvancedSettings
                task={this.state.task}
                onToggleComment={this.handleToggleComment}
                onToggleReminder={this.handleToggleReminder}
              />
            </Modal.Content>
            <Modal.Actions className="modal__actions">
              {isAddTaskFromLibrary && isShow ? (
                <Button
                  purple
                  className="actions__save"
                  disabled={
                    this.state.loading ||
                    uploadingFiles.length ||
                    overLimitTimes ||
                    get(this.state.errors, 'title') ||
                    this.state.intervalRepeatErrors.length
                  }
                  onClick={_.debounce(this.handleDoneClick.bind(this, true), 300)}
                >
                  {'Save & Close'}
                </Button>
              ) : (
                <>
                  {!isHideSave && (
                    <Button
                      className="actions__saveClose"
                      disabled={
                        this.state.loading ||
                        uploadingFiles.length ||
                        get(this.state.errors, 'title') ||
                        this.state.intervalRepeatErrors.length
                      }
                      onClick={_.debounce(this.handleDoneClick.bind(this, false), 300)}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    purple
                    className={classnames('actions__save', {
                      disabled:
                        this.state.loading ||
                        uploadingFiles.length ||
                        overLimitTimes ||
                        get(this.state.errors, 'title') ||
                        this.state.intervalRepeatErrors.length,
                    })}
                    disabled={
                      this.state.loading ||
                      uploadingFiles.length ||
                      overLimitTimes ||
                      get(this.state.errors, 'title') ||
                      this.state.intervalRepeatErrors.length
                    }
                    onClick={_.debounce(this.handleDoneClick.bind(this, true), 300)}
                  >
                    {'Save & Close'}
                  </Button>
                </>
              )}
            </Modal.Actions>
          </S.TaskForm>
          {get(originTask, 'enable_comment', false) && (
            <TaskComment
              task={task}
              openCommentTask={openCommentTask}
              onChangeUploadStatus={noUploadedComments => {
                this.uploadedAllComment = !noUploadedComments.length;
              }}
            />
          )}
        </S.TaskModalContainer>
        {showUpdateRepeatSettingConfirModal && (
          <RepeatTaskConfirm
            title="New Repeat Pattern"
            type={isAutoflowInterval ? CONFIRM_TYPES.UPDATE_TASK_REPEAT_INTERVAL : null}
            message={updateSettingRepeatMessage}
            onClose={this.toggleConfirmSettingRepeat}
            onConfirm={this.handleConfirmUpdateSettingRepeat}
          />
        )}
        {showUpdateTaskDetailConfirmModal && (
          <RepeatTaskConfirm
            title="Edit a Repeating Task"
            isTask={true}
            type={CONFIRM_TYPES.TASK}
            message={`You are editing a repeating task. Do you want to change this and all upcoming occurrences of this task, or only the selected task? \n\n This change will detach the task(s) from the original sequence.`}
            onClose={this.toggleUpdateTaskDetail}
            onConfirm={this.handleConfirmUpdateTaskDetail}
          />
        )}
        {isConfirmRemoveSettingRepeat && (
          <RepeatTaskConfirm
            isRemove={true}
            title="Remove Repeated Tasks"
            type={isAutoflowInterval ? CONFIRM_TYPES.REMOVE_TASK_INTERVAL : null}
            message={removeSettingRepeatMessage}
            onClose={this.toggleRemoveSettingRepeat}
            onConfirm={this.handleRemoveSettingRepeat}
          />
        )}
        {showUpdateNormalToRepeatConfirmModal && (
          <RepeatTaskConfirm
            type={CONFIRM_TYPES.IN_PAST}
            title="Repeating a Task in the Past"
            message={`Only <strong>future instances</strong> of the task (including today ${today} and onwards) will be created. This will ensure streaks and completion rate calculations to be accurate. Would you like to proceed?`}
            onClose={this.toggleUpdateNormalToRepeat}
            onConfirm={this.handleConfirmUpdateNormalToRepeat}
          />
        )}
        {isConfirmChangeSetDate && (
          <RepeatTaskConfirm
            title="New Repeat Pattern"
            type={isAutoflowInterval ? CONFIRM_TYPES.CHANGE_SET_DATE_INTERVAL : null}
            message={changeSetDateMessage}
            onClose={this.toggleUpdateSetDate}
            onConfirm={this.handleConfirmUpdateSetDate}
          />
        )}
      </S.TaskModal>
    );
  }

  checkAttachmentLink = attachmentData => {
    const attachmentItem = get(attachmentData, 'attachments.0');

    if (
      attachmentItem &&
      attachmentData.type === ATTACHMENT_TYPES.MEDIA &&
      attachmentItem.type === MEDIA_TYPES.LINK &&
      attachmentItem.invalidLink
    ) {
      return false;
    }

    if (
      attachmentItem &&
      attachmentData.type === ATTACHMENT_TYPES.LINK &&
      !validateLink(attachmentItem.url) &&
      !checkLinkFormat(attachmentItem.url)
    ) {
      return false;
    }

    return true;
  };

  mixPanelTracking = type => {
    try {
      let trackingTitle = '';

      switch (type) {
        case ATTACHMENT_TYPES.MEDIA:
          trackingTitle = 'general_task_add_new_media_task';
          break;

        case ATTACHMENT_TYPES.DOCUMENT:
          trackingTitle = 'general_task_add_new_document_task';
          break;

        default:
          trackingTitle = 'general_task_add_new_link_task';
          break;
      }

      Mixpanel.track(trackingTitle);
    } catch (error) {
      console.error('MixPanel Tracking Error', error);
    }
  };

  isChangedSettingRepeat = () => {
    const { task, originTask } = this.state;

    if (originTask && originTask.repeat_task_setting_id) {
      const fieldsToCompare = [
        'every',
        'for_the_next',
        'type',
        'date_selected_in_weekly',
        'day_of_month',
        'autoflow_interval_times',
        'autoflow_interval_repeat_type',
      ];
      const originalSetting = _.pick(originTask.repeat_task_setting_id, fieldsToCompare);
      const newSettings = _.pick(task.repeat_task_setting_id, fieldsToCompare);
      return !_.isEqual(originalSetting, newSettings);
    }

    return false;
  };

  getRepeatSettingParams = () => {
    const { task } = this.state;
    const { autoflowType } = this.props;
    return {
      ...task.repeat_task_setting_id,
      ...(autoflowType !== AUTOFLOW_TYPES.INTERVAL && { start_date: task.date }),
      ...(autoflowType === AUTOFLOW_TYPES.INTERVAL && { autoflow_interval_day: task.date.value + 1 }),
    };
  };

  handleCreatingTask = () => {
    const { addRepeatTask } = this.props;
    addRepeatTask(this.getRepeatSettingParams()).then(res => {
      this.handleSubmitForm(get(res, 'data._id'), false);
    });
  };

  handleConfirmUpdateSettingRepeat = isOnly => {
    const { addRepeatTask } = this.props;
    const { loading } = this.state;
    if (loading) return;

    this.setState({
      loading: true,
    });

    addRepeatTask(this.getRepeatSettingParams()).then(res => {
      this.setState({
        loading: false,
      });
      this.handleSubmitForm(get(res, 'data._id'), isOnly);
      this.toggleConfirmSettingRepeat();
    });
  };

  getStartEndCalendar = () => {
    const { calendarStartDate, calendarType } = this.props;
    const startDatetCalendar = moment(calendarStartDate).subtract(1, 'd').format('YYYY-MM-DDTHH:mm:ss.sssZ');
    const endDateCalendar = moment(calendarStartDate)
      .add(calendarType * 7, 'd')
      .format('YYYY-MM-DDTHH:mm:ss.sssZ');
    return {
      start_date_calendar: startDatetCalendar,
      end_date_calendar: endDateCalendar,
    };
  };

  handleConfirmUpdateTaskDetail = isOnly => {
    const { task } = this.state;
    this.handleSubmitForm(task.repeat_task_setting_id._id, isOnly);
    this.toggleUpdateTaskDetail();
  };

  handleConfirmUpdateNormalToRepeat = () => {
    this.props.addRepeatTask(this.getRepeatSettingParams()).then(res => {
      this.handleSubmitForm(get(res, 'data._id'), false);
    });
  };

  handleDoneClick = isClose => {
    const { task, isRepeat, originTask, loading } = this.state;
    const { timezone, autoflowType, repeatTaskPermission, repeatTaskAutoFlowPermission } = this.props;
    const { title, date, type, metrics, form } = task;
    const isChanged = diff(classToPlain(task), classToPlain(originTask));

    let errors = {};

    if (loading) {
      return;
    }

    if (type === TASK_TYPES.BODY_METRIC) {
      if (metrics.length === 0) {
        errors.metric = true;
      }
    }

    if (type === TASK_TYPES.FORM && _.isEmpty(form)) {
      errors.form = true;
    }

    if (!title || (title && !title.trim())) {
      errors.title = true;
    }

    if (!date) {
      errors.date = true;
    }

    this.setState({ errors: errors });

    var sizeErrors = Object.keys(errors).length;

    if (!this.checkAttachmentLink(task.attachments) || sizeErrors) {
      return;
    }

    this.shouldCloseAfterSave = isClose;

    const isPermission = autoflowType ? repeatTaskAutoFlowPermission : repeatTaskPermission;

    if (!isRepeat || task.originalAutoflow || !isPermission) {
      this.handleSubmitForm();
    } else {
      if (!task._id) {
        // Creating
        this.handleCreatingTask();
      } else {
        if (!get(originTask, 'repeat_task_setting_id._id')) {
          const currentDate = moment.tz(moment(), timezone).format('YYYY-MM-DD');
          const currentTaskDate = moment(task.date).format('YYYY-MM-DD');
          if (moment(currentTaskDate).isBefore(currentDate) && originTask.repeat_task_setting_id) {
            this.toggleUpdateNormalToRepeat();
          } else {
            this.props.addRepeatTask(this.getRepeatSettingParams()).then(res => {
              this.handleSubmitForm(get(res, 'data._id'), false);
            });
          }
        } else {
          if (diff(classToPlain(task.date), classToPlain(originTask.date))) {
            this.toggleUpdateSetDate();
          } else if (this.isChangedSettingRepeat()) {
            this.toggleConfirmSettingRepeat();
          } else {
            isChanged ? this.toggleUpdateTaskDetail() : isClose && this.onCloseCurrentPopup();
          }
        }
      }
    }
  };

  handleSubmitForm = (repeatId, onlyUpdateCurrentTask) => {
    const { task, originTask, modeFromLibrary } = this.state;
    const { _id, title, note, date, type, metrics, enable_comment, show_reminder_missed, form } = task;
    const {
      addTask,
      updateTask,
      clientId,
      autoflowType,
      viewMode,
      isAddTaskFromLibrary,
      addFromTaskLibrary,
      updateFromTaskLibrary,
    } = this.props;
    const shouldRefresh = diff(
      classToPlain(task.repeat_task_setting_id),
      classToPlain(originTask.repeat_task_setting_id),
    );

    let mode = '';

    if (isAddTaskFromLibrary && modeFromLibrary === 'duplicate') {
      mode = 'add';
    } else {
      mode = _id ? 'edit' : 'add';
    }

    let formData = {};
    formData.client = clientId;
    formData.title = title.trim();
    formData.note = note || '';
    formData.owner = task.owner;
    formData.share = task.share;
    formData._id = task._id;
    formData.original_task_library = task._id;

    if (_.isBoolean(enable_comment)) {
      formData.enable_comment = enable_comment;
    }

    if (_.isBoolean(show_reminder_missed)) {
      formData.show_reminder_missed = show_reminder_missed;
    }

    if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
      if (mode === 'edit') {
        formData.newWeekIndex = parseInt(date.value / 7);
        formData.newDayIndex = date.value % 7;
        formData.oldWeekIndex = this.state.oldWeekIndex;
        formData.oldDayIndex = this.state.oldDayIndex;
      } else {
        formData.weekIndex = parseInt(date.value / 7);
        formData.dayIndex = date.value % 7;
      }
    } else {
      formData.date = date;
    }

    formData.type = type;

    if (mode === 'edit') {
      isAddTaskFromLibrary ? (formData.taskLibraryId = _id) : (formData.taskId = _id);
    }

    if (type === TASK_TYPES.BODY_METRIC) {
      formData.metrics = metrics;
    }

    if (type === TASK_TYPES.FORM && !_.isEmpty(form)) {
      formData.form = form._id;
    }

    // task reminder
    formData.schedule_reminder = task.schedule_reminder;
    formData.reminder_time = task.reminder_time;

    if (repeatId) {
      const { start_date_calendar, end_date_calendar } = this.getStartEndCalendar();
      formData.repeat_task_setting_id = repeatId;
      formData.start_date_calendar = start_date_calendar;
      formData.end_date_calendar = end_date_calendar;

      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        formData.start_day_index = formData.dayIndex;
        formData.start_week_index = formData.weekIndex;
        formData.end_week_index = viewMode;
        formData.end_day_index = viewMode * 7;
      }

      if (mode === 'edit') {
        formData.should_refresh = shouldRefresh;
        formData.is_only_this_task = onlyUpdateCurrentTask;
        formData.start_day_index = formData.newDayIndex;
        formData.start_week_index = formData.newWeekIndex;
      }
    }

    // attachments
    if (task.attachments && task.attachments.attachments.length) {
      formData.attachments = task.attachments;
      // logic auto add https on attachments type link video
      formData.attachments.attachments = formData.attachments.attachments.map(item => {
        if (item.type === MEDIA_TYPES.LINK) {
          return { ...item, url: autoAddHttps(item.url.trim()) };
        }
        return item;
      });
    }

    if (task.schedule_reminder) {
      Mixpanel.track('set_task_reminder', { time: task.reminder_time });
    }

    let action;

    if (isAddTaskFromLibrary) {
      action = mode === 'edit' ? updateFromTaskLibrary : addFromTaskLibrary;

      if (!formData.attachments) {
        formData.attachments = {
          attachments: [],
        };
      }

      formData =
        modeFromLibrary === 'duplicate'
          ? _.omit(formData, ['client', 'date', '_id'])
          : _.omit(formData, ['client', 'date', 'original_task_library']);
    } else {
      action = mode === 'edit' ? updateTask : addTask;
      formData = _.omit(formData, ['owner', 'share', 'original_task_library']);
    }

    this.setState({ loading: true });

    if (mode !== 'edit' && formData.attachments) {
      this.mixPanelTracking(formData.attachments.type);
    }

    return action(formData)
      .then(res => {
        let oldDayIndex;
        let oldWeekIndex;

        if (mode === 'edit') {
          oldDayIndex = formData.newDayIndex;
          oldWeekIndex = formData.newWeekIndex;
        } else {
          oldDayIndex = formData.dayIndex;
          oldWeekIndex = formData.weekIndex;
        }

        let newDate = date;
        if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
          newDate = date.value;
        }

        this.setState({ loading: false, oldDayIndex, oldWeekIndex, date: newDate });

        if (this.shouldCloseAfterSave) {
          this.onCloseCurrentPopup();
        } else {
          const { data } = res.data;

          if (isAddTaskFromLibrary && modeFromLibrary === 'duplicate') {
            this.setState({ modeFromLibrary: 'edit' });
          }

          this.handleSubmitTaskSuccess(data);
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleSubmitTaskSuccess = data => {
    const isEdit = !!this.state.task._id;
    const createdTask = get(data, 'createdTask');

    if (data && !isEdit && createdTask) {
      const { task, isRepeat } = this.state;
      if (isRepeat) {
        const repeating = _.cloneDeep(task.repeat_task_setting_id);
        createdTask.repeat_task_setting_id = repeating;
      }
      this.initStateFromTask(createdTask);
    } else if (isEdit) {
      // for autoflow interval
      if (_.isArray(data)) {
        const tasks = _.flatten(data.map(o => o.tasks));
        const task = tasks.find(t => t._id === this.state.task._id);
        if (task) {
          this.initStateFromTask(task);
        }
      } else {
        this.initStateFromTask(data);
      }
    } else if (this.props.isAddTaskFromLibrary) {
      this.initStateFromTask(data);
    }
  };

  handleBackClick = () => {
    const { onBack, toggleModal, isAddTaskFromLibrary, owner, bodyMetricsFromLibrary } = this.props;

    if (typeof onBack === 'function') {
      onBack();
    } else {
      toggleModal(
        true,
        <TaskAddNew
          owner={owner}
          bodyMetricsFromLibrary={bodyMetricsFromLibrary}
          isAddTaskFromLibrary={isAddTaskFromLibrary ? isAddTaskFromLibrary : false}
          date={this.props.date}
        />,
      );
    }
  };

  renderListMetric() {
    const { bodymetricTypes, task, errors } = this.state;
    const { metrics } = task;

    const filteredStep = bodymetricTypes.filter(item => item.unique_code !== STEP_UNIQUE_CODE);

    let result = [];
    for (let i = 0; i < filteredStep.length; i = i + 2) {
      const metricData = filteredStep[i];

      result.push(
        <Grid columns={16} key={metricData._id}>
          <Grid.Row columns={16}>
            <Grid.Column width="8" className="metric-type-column">
              <label className="metric-container">
                {metricData.name}
                <input
                  type="checkbox"
                  onChange={() => {
                    this.toggleSelectMetric(metricData._id);
                  }}
                  checked={metrics.indexOf(metricData._id) > -1}
                  disabled={!!task.status}
                />
                <span className="checkmark"></span>
              </label>
            </Grid.Column>
            <Grid.Column width="8" className="metric-type-column">
              {filteredStep[i + 1] && (
                <label className="metric-container">
                  {filteredStep[i + 1].name}
                  <input
                    type="checkbox"
                    onChange={() => {
                      this.toggleSelectMetric(filteredStep[i + 1]._id);
                    }}
                    checked={metrics.indexOf(filteredStep[i + 1]._id) > -1}
                    disabled={!!task.status}
                  />
                  <span className="checkmark"></span>
                </label>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>,
      );
    }
    return (
      <div className="bodymetric-container">
        <div className="bodymetric-wraper">{result}</div>
        {get(errors, 'metric', false) ? (
          <ErrorMessage className="task--error-message task--noActions">Please select at least one metric</ErrorMessage>
        ) : null}
      </div>
    );
  }

  toggleSelectMetric(id) {
    const errors = { ...this.state.errors, metric: false };
    let new_list_metrics = this.state.task.metrics.slice();
    let index = new_list_metrics.indexOf(id);

    if (index > -1) {
      new_list_metrics.splice(index, 1);
    } else {
      new_list_metrics.push(id);
    }

    this.setState({
      task: { ...this.state.task, metrics: new_list_metrics },
      errors,
    });
  }

  handleSelectForm = item => {
    const errors = { ...this.state.errors, form: false };
    this.setState({
      task: { ...this.state.task, form: item },
      errors,
    });
  };

  onCloseCurrentPopup = () => {
    if (typeof this.props.onCloseForm === 'function') {
      this.props.onCloseForm();
    } else {
      this.props.toggleModal(false);
    }
  };

  handleCloseAction = () => {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy) return null;
    if (!this.uploadedAllComment) {
      return this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Upload in progress"
          content="If you close the pop-up, all incomplete uploads will be discarded. Would you like to exit without finishing?"
          onConfirm={() => this.onCloseCurrentPopup()}
          newStyle={true}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    }

    let { originTask, task } = this.state;
    let origin = classToPlain(originTask);
    let current = classToPlain(task);

    // Exclude the thumbnail URLs from diff due to replaced by YouTube API
    origin = _.omit(_.get(origin, 'attachments.attachments[0]'), 'thumbnail_url') || origin;
    current = _.omit(_.get(current, 'attachments.attachments[0]'), 'thumbnail_url') || current;

    let changes = diff(
      _.set(_.cloneDeep(origin), 'attachments.attachments[0]', origin),
      _.set(_.cloneDeep(current), 'attachments.attachments[0]', current),
    );

    if (changes) {
      this.props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={() => this.onCloseCurrentPopup()}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    } else {
      this.onCloseCurrentPopup();
    }
  };

  saveToLibrary = () => {
    const taskId = get(this.state, 'task._id');
    const { autoflowType } = this.props;

    let taskSchema = 'client-calendar';
    if (autoflowType === AUTOFLOW_TYPES.INTERVAL) taskSchema = 'autoflow-interval-task';
    if (autoflowType === AUTOFLOW_TYPES.EXACT_DATE) taskSchema = 'autoflow-task';

    this.props.saveToLibrary({ taskId, taskSchema });
  };

  handleDeleteTask = () => {
    const { task } = this.state;
    const { repeatTaskPermission } = this.props;
    if (task.repeat_task_setting_id && repeatTaskPermission) {
      this.handleDeleteWithRepeat();
    } else {
      this.handleDeleteWithoutRepeat();
    }
  };

  handleDeleteWithRepeat = () => {
    const { toggleConfirmModal } = this.props;
    return toggleConfirmModal(
      true,
      <RepeatTaskConfirm
        title="Remove a Repeating Task"
        isRemove={true}
        isTask={true}
        type={CONFIRM_TYPES.TASK}
        message="You are removing a repeating task. Do you want to remove this and all upcoming occurences of this task, or only the selected task?"
        onClose={this.toggleDeleteConfirmModal}
        onConfirm={this.handleConfirmDeleteTaskWithRepeat}
      />,
    );
  };

  handleDeleteWithoutRepeat = () => {
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete task"
        content="Are you sure that you want to delete this task?"
        onConfirm={this.handleConfirmDeleteTaskWithoutRepeat}
      />,
    );
  };

  handleConfirmDeleteTaskWithRepeat = isOnly => {
    const { autoflowType } = this.props;
    if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
      this.handleDeleteTaskInterval(isOnly);
    } else if (autoflowType === AUTOFLOW_TYPES.EXACT_DATE) {
      this.handleDeleteTaskExactDate(isOnly);
    } else {
      this.handleDeleteClientTask(isOnly);
    }
    this.handleCloseAfterDeleted();
  };

  handleConfirmDeleteTaskWithoutRepeat = () => {
    const { autoflowType } = this.props;
    if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
      this.handleDeleteTaskInterval();
    } else if (autoflowType === AUTOFLOW_TYPES.EXACT_DATE) {
      this.handleDeleteTaskExactDate();
    } else {
      this.handleDeleteClientTask();
    }
    this.handleCloseAfterDeleted();
  };

  toggleDeleteConfirmModal = () => {
    const { toggleConfirmModal } = this.props;
    return toggleConfirmModal && toggleConfirmModal(false);
  };

  handleDeleteClientTask = isOnly => {
    const { task } = this.state;
    const { deleteClientTask } = this.props;
    const params = {
      taskId: task._id,
      client: task.client,
      is_only_this_task: isOnly,
    };
    deleteClientTask && deleteClientTask(params);
  };

  handleDeleteTaskInterval = isOnly => {
    const { oldDayIndex, oldWeekIndex, task } = this.state;
    const { autoflowId, deleteTask } = this.props;

    let params = {
      taskId: task._id,
      autoflowId: autoflowId,
      dayIndex: oldDayIndex,
      weekIndex: oldWeekIndex,
      is_only_this_task: isOnly,
    };
    if (task.repeat_task_setting_id) {
      params = {
        ...params,
        repeatId: task.repeat_task_setting_id._id,
      };
    }
    deleteTask && deleteTask(params);
  };

  handleDeleteTaskExactDate = isOnly => {
    const { task } = this.state;
    const { autoflowId, deleteTaskAutoExactDate } = this.props;
    const params = {
      id: task._id,
      autoflow: autoflowId,
      is_only_this_task: isOnly,
    };
    deleteTaskAutoExactDate && deleteTaskAutoExactDate(params);
  };

  handleCloseAfterDeleted = () => {
    this.toggleDeleteConfirmModal(); // Close confirm modal
    this.onCloseCurrentPopup(); // Close current popup task
  };

  handleChangeOwner = (event, data) => {
    let { task } = this.state;

    if (!task.owner) {
      task.owner = '';
    }

    task.owner = data.value;

    if (data.value === 'no_owner') {
      task.owner = null;
      task.share = TEAM_SHARE_NOOWNER;
    } else if (task.share === TEAM_SHARE_NOOWNER) {
      task.share = TEAM_SHARE_PRIVATE;
    }

    this.setState({ task });
  };

  handleChangeShare = (event, data) => {
    let { task } = this.state;

    task.share = data.value;
    this.setState(prevState => ({
      task,
    }));
  };
}
