import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  width: ${({ isHearRateMetric }) => (isHearRateMetric ? '280px' : '260px')};
  height: 236px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(38, 38, 38, 0.2);
  font-size: 13px;

  &.heart-rate-metric {
    width: 280px;
  }

  &.default {
    position: absolute;
    font-family: 'Open Sans';
    left: 84px;
    bottom: 6px;
    z-index: 100;
  }

  &.custom-height {
    height: unset;
  }

  .content {
    padding: 15px 15px 20px;
    border-bottom: 1px #e1e1ea solid;

    h3 {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      color: #202353;
      margin-bottom: 20px;
    }

    .datetime-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    .date-input {
      .form-control {
        height: 36px;
        padding: 10px !important;
        border-radius: 4px;
        border: 1px solid #e1e1ea;
        width: ${({ isHearRateMetric }) => (isHearRateMetric ? '120px' : '110px')};
        justify-content: flex-start !important;

        &:hover {
          color: #5158cf !important;
          border-color: #5158cf;
        }

        div {
          display: none;
        }
        span {
          margin-left: 0 !important;
        }
      }
      &.rdtOpen {
        .form-control {
          border-color: #5158cf;
        }
      }
      .rdtPicker {
        position: absolute;
        bottom: 50px;
        left: 177px;
        width: 360px;
        height: min-content;
        max-height: 320px;
        background-color: #fff;
        padding: 15px 24px;
        border-radius: 5px;
        box-shadow: 0px 2px 8px rgba(38, 38, 38, 0.2);
        border: none;

        table tbody {
          tr:first-child td .content {
            margin-top: 7px;
          }
        }

        table thead {
          color: #202353 !important;

          tr:first-child th {
            padding-bottom: 4px;
          }

          tr:last-child {
            height: 44px;
            border-bottom: 1px solid #e1e1ea;

            .dow {
              padding-top: 7px;
              font-size: 13px;
              line-height: 24px;
              font-weight: 600;
              color: #202353 !important;
            }
          }

          .rdtPrev {
            span {
              position: relative;
              left: -16px;
              background-image: url(${CDN_URL}/images/arrow-left-gray-icon.svg);
            }
            :hover {
              span {
                background-image: url(${CDN_URL}/images/left-arrow-bold.svg);
                background-size: 16px;
              }
            }
          }

          .rdtNext {
            span {
              position: relative;
              right: -16px;
              background-image: url(${CDN_URL}/images/arrow-left-gray-icon.svg);
              transform: rotate(180deg);
              background-position: center center;
            }
            :hover {
              span {
                background-image: url(${CDN_URL}/images/right-arrow-bold.svg);
                background-size: 16px;
                transform: rotate(0deg);
                background-position: center center;
              }
            }
          }
          .disabledBtnNext {
            pointer-events: none;
            :hover {
              span {
                position: relative;
                right: -16px;
                background-image: url(${CDN_URL}/images/arrow-left-gray-icon.svg);
                transform: rotate(180deg);
                background-position: center center;
              }
            }
          }

          .rdtSwitch {
          }
        }

        .rdtDay {
          padding: 0 !important;
          height: 36px;
          color: #202353;

          .content {
            border-bottom: 0;
            & > div {
              width: 34px;
              height: 34px;
              line-height: 34px;
              font-weight: 600;
            }
          }

          &.rdtOld,
          &.rdtNew {
            color: #7b7e91;
          }

          &.rdtToday .content > div {
            background-color: #fff;
            color: #5158cf;
          }

          &.disabled {
            color: #cfd0e5;
          }

          &.isSelected,
          &.rdtActive {
            .content > div {
              background-color: #5158cf;
              color: #fff;
            }
          }

          &:hover {
            .content > div {
              background-color: #f3f5f8;
              color: #202353;
            }

            &.disabled .content > div {
              color: unset;
            }

            &.rdtActive.rdtToday .content > div {
              color: #5158cf;
            }
          }
        }

        .rdtMonths,
        .rdtYears {
          tr:first-child th {
            padding-bottom: 23px;
          }

          tr:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .time-input {
      display: flex;
      align-items: center;
      height: 36px;
      position: relative;
      overflow: hidden;

      &.wrap-time-error input {
        border-color: #ea314a !important;
      }

      input {
        color: #202353;
        border: none;
        background-color: transparent;
        height: 36px;
        width: ${({ isHearRateMetric }) => (isHearRateMetric ? '120px' : '110px')};
        padding-left: 8px;
        border: 1px solid #e1e1ea;
        border-radius: 4px;

        &:focus {
          border-color: #5158cf;
        }

        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          font-size: 11px;
        }
      }

      span.time-ap {
        display: inline-block;
        padding: 0 4px;
        width: 27px;
        text-align: center;
        font-size: 10px;
        color: #a3a3b5;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        position: absolute;
        right: 1px;

        &.time-am {
          right: 28px;
        }

        &.time-pm {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &:first-child {
          border-left: 1px solid #e1e1ea;
          border-right: 1px solid #e1e1ea;
        }

        &:hover {
          background-color: #f0f0f0;
          color: #202353;
        }
        &.selected {
          background-color: #f0f0f2;
          color: #202353;
        }
      }
    }

    .time-error {
      position: absolute;
      bottom: -22px;
      font-size: 12px;
      left: 131px;
      color: #ea314a;
    }
  }

  .value-input {
    margin-top: 15px;

    .label-error {
      color: #ea314a;
    }

    .metric-value-input {
      height: 36px;
      padding: 10px !important;
      border-radius: 5px;
      border: 1px solid #e1e1ea;
      width: 100%;

      &:focus {
        outline: none;
        border-color: #5158cf;
      }
    }

    .input-error {
      border-color: #ea314a !important;
    }

    .select-unit-wrap {
      .options {
        left: 0;
        top: 40px;
        width: 199px;

        .checked-icon {
          margin-left: 8px;
          margin-right: 0;
        }
      }

      .dropdown-wrapper {
        min-height: 36px;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        gap: 10px;
        background-color: #fff;
        border: 1px solid #e1e1ea;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
        color: #202353;

        &.active-select,
        &:hover {
          svg path {
            stroke: #7b7e91;
          }
        }
        &.active-select {
          border: 1px solid #5158cf;
        }
        &:hover {
          color: #202353;
          background-color: #f8f8f8;
        }

        .arrow-down-icon {
          margin-right: 4px;
          margin-left: 5px;
          margin-top: 0px;
        }
      }
    }

    .flex {
      display: flex;
      gap: 10px;
    }

    .row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    p {
      text-transform: uppercase;
      color: #7b7e91;
      font-size: 10px;
      font-weight: 600;
      line-height: 15px;
      margin-bottom: 3px;
    }

    .ft-in-error {
      margin-top: 8px;
    }

    .value-error {
      font-size: 12px;
      padding-top: 0;
      line-height: 18px;
      margin-top: 8px;
      color: #ea314a;

      &.ft-error,
      &.in-error {
        margin-top: 0 !important;
      }
    }

    .metric-value-input {
      width: 100% !important;
      max-width: 161px !important;
      flex: 1;

      &.time-metric {
        max-width: unset !important;
      }
      &.heart-rate-metric {
        max-width: 209px !important;
        width: 209px !important;
      }
    }

    .unit-select-box {
      flex: 1;
      min-width: 80px;
    }

    .unit-select__single-value {
      font-weight: 400;
      padding-left: 10px;
      overflow: unset;
      margin: 0;
    }

    .unit-select__menu {
      min-width: 100px;
      right: 0;
    }

    .unit-select__menu-list {
      padding: 0;

      .unit-select__option {
        span {
          white-space: nowrap;
        }
      }
    }

    .unit-select__control {
      border-color: #e1e1ea;
      height: 36px;
      min-height: 36px;
      box-shadow: none;
      gap: 10px;

      &:hover {
        border-color: #5158cf;
      }

      &--menu-is-open {
        border-color: #5158cf;
      }
    }

    .unit-select__value-container {
      min-height: 36px;
      padding: 0;
      width: 100%;
    }

    .unit-select__indicators .unit-select__indicator {
      padding: 0 13.5px 0 0;

      img {
        width: 11px;
      }
    }
  }

  .no-result {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
  }

  .footer {
    padding: 10px 15px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button {
      height: 30px;
      padding: 10px 15px;
      border-radius: 5px;
      min-width: auto;
      width: 57px;

      &.btn-cancel {
        border: none;
        display: flex;
        height: 36px;
        width: 72px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      &.btn-save {
        display: flex;
        height: 36px;
        width: 60px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
  }
`;

export const CustomPopup = styled(Popup)`
  z-index: 2000 !important;
  &.ui.popup {
    border: unset !important;
    box-shadow: unset !important;
  }
`;

export const FitInchUnitWrapper = styled.div`
  .error-message {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #ee2525;
    margin-top: 5px;
  }
`;

export const CustomDropdownPopup = styled(Popup)`
  &.ui.popup {
    z-index: 2000;
    min-width: 199px;
    padding: 10px;
    border-radius: 10px;
    background: #ffffff;
    border: unset;
    box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  }
`;

export const TriggerDropdown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 36px;
  cursor: pointer;
  user-select: none;

  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background: #ffffff;

  .trigger-label {
    color: #202353;

    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    white-space: nowrap;
  }

  .arrow-down-icon {
    transform: rotate(180deg);
    width: 10px;
    height: 10px;
    margin-left: 6px;
    margin-top: 3px;
    min-width: 10px;

    path {
      stroke: #7b7e91;
    }
  }

  &.active,
  &:hover {
    background: #f0f1ff;
    border-color: #5158cf;

    .trigger-label {
      color: #5158cf;
    }

    .arrow-down-icon {
      path {
        stroke: #5158cf;
      }
    }
  }
`;

export const OptionDropdown = styled.div`
  cursor: pointer;
  user-select: none;

  height: 40px;
  padding: 10px;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .item-label {
    color: #202353;

    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  &.active {
    .item-label {
      font-weight: 600;
    }
  }

  &:hover {
    background: #f0f1ff;
  }

  &.active,
  &:hover {
    .item-label {
      color: #5158cf;
    }
  }

  .checked-icon path {
    fill: #5158cf;
  }
`;

export const TimeMetric = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7b7e91;
`;
