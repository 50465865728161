import React from 'react';
import TableHeader from './TableHeader';
import TableContent from './TableContent';
import * as S from './style';
import Pagination from 'shared/Pagination';

const Content = ({ list, push, loading, query, changeQueryParams, isFirst, isHideFormBanner }) => {
  const handleSorting = params => {
    changeQueryParams(params);
  };

  return (
    <S.ContentWrapper>
      <TableHeader list={list} onSort={handleSorting} query={query} />
      <TableContent
        list={list}
        push={push}
        loading={loading}
        query={query}
        isFirst={isFirst}
        isHideFormBanner={isHideFormBanner}
      />
      <Pagination
        page={query.page}
        perPage={query.per_page}
        totalPage={query.total}
        onPrev={() => {
          changeQueryParams({ page: query.page - 1 });
        }}
        onNext={() => {
          changeQueryParams({ page: query.page + 1 });
        }}
      />
    </S.ContentWrapper>
  );
};

export default Content;
