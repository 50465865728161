import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const DateContainer = styled.div`
  margin: 0 auto 8px;
  padding: 0 134px 50px;
  border-left: 1px dashed #C4C4C4;
  position: relative;
  
  .date-container {
    position: absolute;
    left: -7px;
    top: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;

    .today {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #979797;
      padding-left: 25px
    }

    .date {
      display: flex;
      justify-content: flex-start;

      .dot {
        width: 25px;
        height: 24px;
        background-color: #fff;
        position: relative;
        ::before {
          position: absolute;
          content: '';
          left: 0;
          top: 3px;
          width: 13px;
          height: 13px;
          background-color: #C4C4C4;
          border-radius: 50%;
        }

        &.active {
          ::before {
            background-color: #49CA8D;
          }
        }
      }
    }
  }

  .post {
    margin-bottom: 20px;
    width: 432px;
    
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const ScrollTop = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background: url(${CDN_URL}/images/arrow_up_white.svg) no-repeat;
  background-size: auto;
  background-position: center;
  background-color: #6456C4;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s, visibility .3s;
  position: fixed;
  right: 25px;
  bottom: 90px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.16) 0px 2px 32px 0px;
  z-index: 1000;

  &.show {
    opacity: 1;
    visibility: visible;
  }
`;

export const NoData = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  margin-top: 130px;

  img.ui {
    margin-bottom: 23px;
    height: 124px;
    display: inline-block !important;
  }
`;

export const FeatureTurnedOff = styled.div`
  background: #FFFAE6;
  border: 1px solid #EEA827;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #EEA827;
  margin: 0 auto 30px;

  img {
    margin-right: 6px;
  }
`;

export const Wrapper = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  scroll-behavior: smooth;

  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0) !important;
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,0.15) !important;
    }
  }

  ${props => props.turnedOff && css`
    padding-top: 25px;
  `}
`;