// Libs
import React, { useState, useEffect } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close_circle_grey.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';

// Utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

// Styles
import * as S from './style';

const DietaryInfo = props => {
  const {
    isEditMode,
    dietaryRecipe,
    handleChoose = () => {},
    value = [],
    cloudfrontList,
    loading = false,
    getListDietary = () => {},
  } = props;

  const [dietaryList, setDietaryList] = useState(value);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  useEffect(() => {
    setDietaryList(value);
  }, [value]);

  useEffect(() => {
    if (isEmpty(dietaryRecipe) && openDropdown) {
      getListDietary();
    }
  }, [openDropdown, dietaryRecipe]);

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
  };

  const handleClosePopUp = () => {
    setOpenDropdown(false);
  };

  const handleChooseDietary = data => {
    const checked = dietaryList.some(item => get(item, 'value', '') === get(data, 'value', ''));
    let updatedList = [];
    if (checked) {
      updatedList = [...dietaryList].filter(item => get(item, 'value', '') !== get(data, 'value', ''));
    } else {
      updatedList = [...dietaryList, data];
    }

    const convertUpdatedList = updatedList.map(item => item && item.value);
    updatedList = dietaryRecipe.filter(item => convertUpdatedList.includes(get(item, 'value', '')));

    setDietaryList(updatedList);
    handleChoose(updatedList);
  };

  const handleRemoveDietary = data => {
    setIsSelecting(true);
    const updatedList = [...dietaryList].filter(item => get(item, 'value', '') !== get(data, 'value', ''));
    setDietaryList(updatedList);
    handleChoose(updatedList);
    setTimeout(() => {
      setIsSelecting(false);
    }, 100);
  };

  return (
    <S.DietaryInfoWrapper>
      {isEditMode && <S.Title>Dietary Information</S.Title>}
      {/* <RootCloseWrapper onRootClose={handleClosePopUp}> */}
      <S.DietaryList isEdit={isEditMode}>
        {dietaryList.map(item => {
          const { value, name, icon } = item;
          const iconConvert = convertS3UrlToCloudFrontUrl(icon, cloudfrontList, true);
          return (
            <S.DietaryItem isEdit={isEditMode} key={value}>
              <S.IconItem>
                <img src={iconConvert} alt={name} />
              </S.IconItem>
              {name}
              {isEditMode && <CloseIcon onClick={() => handleRemoveDietary(item)} className="close-icon" />}
            </S.DietaryItem>
          );
        })}
        {isEditMode && get(dietaryList, 'length', 0) === 0 && (
          <S.AddDietary onClick={handleOpenDropdown} isEmpty>
            <PlusIcon />
            <span>e.g. Vegan, Nut free...</span>
          </S.AddDietary>
        )}
        {isEditMode &&
          get(dietaryList, 'length', 0) !== 0 &&
          get(dietaryList, 'length', 0) < get(dietaryRecipe, 'length', 0) && (
            <S.AddDietary onClick={handleOpenDropdown}>
              <PlusIcon />
            </S.AddDietary>
          )}
        {openDropdown && (
          <RootCloseWrapper onRootClose={handleClosePopUp} disabled={isSelecting}>
            <S.DropdownList>
              <S.DropdownTitle>Choose Dietary Info</S.DropdownTitle>
              {loading ? (
                <S.Loading />
              ) : (
                <S.DietarySelectList>
                  {dietaryRecipe.map(item => {
                    const { value = '', name = '', icon = '', icon_hover = '' } = item || {};
                    const active = dietaryList.some(item => get(item, 'value', '') === value);
                    const iconConvert = convertS3UrlToCloudFrontUrl(icon, cloudfrontList, true);
                    const iconHoverConvert = convertS3UrlToCloudFrontUrl(icon_hover, cloudfrontList, true);
                    return (
                      <S.DietarySelectItem active={active} key={value} onClick={() => handleChooseDietary(item)}>
                        <img className="icon-dietary" src={iconConvert} alt={name} />
                        <img className="icon-dietary-hover" src={iconHoverConvert} alt={name} />
                        <span>{name}</span>
                      </S.DietarySelectItem>
                    );
                  })}
                </S.DietarySelectList>
              )}
            </S.DropdownList>
          </RootCloseWrapper>
        )}
      </S.DietaryList>
      {/* </RootCloseWrapper> */}
    </S.DietaryInfoWrapper>
  );
};

export default DietaryInfo;
