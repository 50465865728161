import styled, { css } from 'styled-components';

export const EndDate = styled.div`
  background: #eeedff;
  border-radius: 3px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #5c5bbd;
  padding: 1px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: hidden;

  svg {
    margin-right: 4px;
  }

  div {
    overflow: hidden;
  }
`;

export const MessageHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #616265;
  padding: 10px 5px 10px 10px;

  .autoMessage__time {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;

    > * {
      margin-right: 5px;

      :last-child {
        margin-right: 0;
      }
    }

    .paused {
      background: rgba(255, 196, 43, 0.3);
      border-radius: 3px;
      font-weight: 600;
      font-size: 10px;
      line-height: 130%;
      color: #443604;
      padding: 2px 5px;
    }
  }

  .evf-dropdown {
    .evf-dropdown__option {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 150px;

      img {
        margin-right: 14px;
        width: 14px;
      }
    }
  }

  ${props =>
    props.announcement &&
    css`
      min-height: 35px;
    `}
`;

export const MessageContent = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #1f2023;
  padding: 0 10px 0;

  .autoMessage__title {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
  }
`;

export const MessageContainer = styled.div`
  padding-bottom: 10px;
`;

export const MessageWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
  user-select: none;
  cursor: pointer;

  &.autoMessage--announcement {
    .autoMessage--announcement__media {
      width: 100%;
      height: 86px;
      margin-bottom: 10px;
      overflow: hidden;
      position: relative;

      img,
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        outline: none !important;
        pointer-events: none;
        min-width: unset;
      }
    }
  }

  &.autoMessage--inApp {
    .autoMessage__title {
      font-weight: 600;
    }
  }
`;
