export const MAX_ONBOARDING_NAME_LENGTH = 90;

export const OnboardingTabs = [
  {
    menuItem: 'Onboarding Flows',
    name: 'onboarding_flows',
  },
  {
    menuItem: 'Your Onboarding Flows',
    name: 'your_onboarding_flows',
  },
  {
    menuItem: 'Archived',
    name: 'archive',
  },
  {
    menuItem: 'Default Onboarding Flow',
    name: 'default_onboarding_flow',
  },
];

export const TYPE_OF_MODAL = {
  UPDATE: 'Update',
  CREATE: 'Create',
};
