import styled, { css } from 'styled-components';

import { FallbackWrapper } from 'shared/UpgradePath/style';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

export const Section = styled.div`
  padding: 0;
  font-size: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  color: #000000;
  font-size: 26px;
  line-height: 39px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;
`;

export const Description = styled.div`
  display: block;
  width: 488px;
  color: #000000;
  margin: auto;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const ItemLink = styled.a`
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ItemButton = styled.button`
  display: block;
  padding: 11px 13px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 100%;
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const FallbackWrapperS = styled(FallbackWrapper)`
  padding-top: 20px;
`;

export const ComingSoon = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.18px;
  padding: 5px 5px 5px 24px;
  margin-bottom: 59px;
  background: linear-gradient(90.09deg, #f7f7f7 70.51%, rgba(247, 247, 247, 0) 95.24%);
  border-radius: 4px;
  align-items: center;
`;

export const ComingText = styled.span`
  padding-left: 3px;
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.18px;
`;

export const SUpgradePathButton = styled(UpgradePathButton)`
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 100%;
  display: flex;
  border: none;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }

  width: 226px;
  padding: 13.5px 13px;

  span {
    display: block;
    margin: auto;
  }
`;

export const UpgradePathImg = styled.img`
  width: 865px;
  margin-top: 49px;
`;

export const RequestCountryLink = styled.span`
  color: #5158cf;
`;

export const PackageFallBackGroup = styled.a`
  color: #5158cf;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  padding-left: 24px;
  cursor: pointer;
  &:hover {
    color: #5158cf;
  }
  svg {
    margin-bottom: -2px;
    margin-right: 8px;
    path {
      fill: #5158cf;
    }
  }
`;
