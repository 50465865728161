// Lib
import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import get from 'lodash/get';

// Shared
import DropdownOptions from 'shared/DropdownOptions';
import { DropdownIndicator } from 'shared/Icons';

// Constants
import { QUESTION_TYPES } from 'constants/commonData';
import { checkSyncConditions } from 'components/FormDetails/helpers';

// Styles
import * as S from './style';

const customOption = props => {
  const { data, label, value } = props || {};
  const { is_disabled } = data || {};

  return (
    <components.Option {...props}>
      <div className={classNames(`${value}`, { is_disabled: is_disabled })}>{label}</div>
    </components.Option>
  );
};

const MetricOption = ({ workingQuestion, onChange, disabled, bodyMetrics, questions }) => {
  const { _id = '', type = '', body_metric_unique_code = '', body_metric_name = '' } = workingQuestion || {};

  const isHasMetric = (bodyMetrics || []).some(({ unique_code = '' }) => unique_code === body_metric_unique_code);

  const handleChange = ({ value = '' }) => {
    const newQuestions = (questions || []).filter(({ type }) =>
      [QUESTION_TYPES.DATE_TIME, QUESTION_TYPES.METRIC].includes(type),
    );

    const metricCondition = ({ is_sync, _id, type, body_metric_unique_code }) => {
      return (
        type === QUESTION_TYPES.METRIC &&
        (typeof is_sync === 'undefined' || is_sync) &&
        _id !== get(workingQuestion, '_id') &&
        body_metric_unique_code === value
      );
    };

    const { hasSync: hasSyncMetric } = checkSyncConditions(newQuestions, metricCondition);

    typeof onChange === 'function' &&
      onChange({
        _id: _id,
        type: type,
        body_metric_unique_code: value,
        unit: (bodyMetrics.find(({ unique_code = '' }) => unique_code === value) || {}).unit,
        is_sync: !hasSyncMetric,
      });
  };

  const customStyles = {
    menuList: (baseStyle, state) => ({
      ...baseStyle,
      paddingRight: '3px',
      marginRight: '3px',
      paddingLeft: '10px',
      maxHeight: '185px',
      '::-webkit-scrollbar-thumb': {
        background: '#e1e1ea !important',
      },
      '::-webkit-scrollbar': {
        width: '4px !important',
      },
    }),
  };

  return (
    <S.MetricOptionWrapper disabled={disabled}>
      <S.MetricOptionLabel>Metric</S.MetricOptionLabel>
      <DropdownOptions
        key={_id}
        options={bodyMetrics.map(({ name = '', unique_code = '' }) => ({
          label: name,
          value: unique_code,
        }))}
        selectedValue={body_metric_unique_code}
        onSelect={handleChange}
        customIndicator={DropdownIndicator}
        className="custom-dropdown-options"
        readOnly={disabled}
        customOption={customOption}
        styles={customStyles}
        defaultValue={{
          label: body_metric_name,
          value: body_metric_unique_code,
        }}
        isDefaultValue={!isHasMetric}
      />
    </S.MetricOptionWrapper>
  );
};

export default MetricOption;
