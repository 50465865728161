import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import styled from 'styled-components';
import { ErrorMessage, FormGroup } from 'shared/FormControl';

const StarRequired = styled.span`
  color: #ea314a;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  .label__maxLength {
    opacity: 0.3;
  }
`;

const FormControl = styled(FormGroup)`
  margin-bottom: 22px;
  input {
    height: 42px;
    font-weight: normal;
  }

  textarea {
    background: #ffffff;
    border: 1px solid #dcdcde;
    box-sizing: border-box;
    border-radius: 4px;
    color: #323c47;
    font-size: 12px;
    line-height: 18px;
    padding: 5px 15px;
    outline: none !important;
    width: 100%;
    font-weight: normal;
    font-family: Open Sans;
    height: 134px;
    resize: none;

    &::placeholder {
      color: #969fb5;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.error {
    input:hover:not(:disabled),
    input:focus,
    textarea:not(:disabled),
    textarea:focus {
      border-color: #ea314a;
    }
  }
  &.custom-error.error {
    input {
      border-color: #ea314a;
    }
  }
`;

export default class FormItemInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getInvalidMsg = () => {
    const { rules = [], required, value, requiredErrorMessage } = this.props;
    let msg = '';
    if (required && _.isEmpty(value.trim())) {
      return requiredErrorMessage || 'This field is required.';
    }
    _.forEach(rules, rule => {
      if (rule.regex && !rule.regex.test(value)) {
        msg = rule.message;
        return false;
      }
      if ((required || rule.required) && _.isEmpty(value.trim())) {
        msg = rule.message || 'This field is required.';
        return false;
      }
      if (!rule.valid) {
        msg = rule.message;
        return false;
      }
    });
    return msg;
  };

  handleKeyDown = event => {
    const { onKeyDown } = this.props;
    onKeyDown && onKeyDown(event);
  };

  renderInput = (type, value, onChange, placeholder, inputProps, name) => {
    switch (type) {
      case 'textarea':
        return <textarea {...inputProps} value={value} onChange={onChange} placeholder={placeholder} name={name} />;
      case 'number':
        return (
          <input
            {...inputProps}
            type="number"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
          />
        );
      default:
        return (
          <input
            {...inputProps}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onKeyDown={this.handleKeyDown}
            name={name}
          />
        );
    }
  };

  render() {
    const {
      label,
      hideLabel = false,
      type,
      value,
      onChange,
      placeholder,
      isSubmitted,
      inputProps,
      maxLength,
      isHideErrorMessage,
      isRequiredStar = false,
      name,
      className,
    } = this.props;
    const errorMsg = this.getInvalidMsg();
    return (
      <FormControl className={classNames(`formControl ${className}`, { error: isSubmitted && !!errorMsg })}>
        {!hideLabel && (
          <Label>
            <span>
              {label}
              {isRequiredStar && (
                <>
                  &nbsp;<StarRequired className="required-star">*</StarRequired>
                </>
              )}
            </span>
            {maxLength ? (
              <span className="label__maxLength">
                {value ? value.length : 0} / {maxLength}
              </span>
            ) : null}
          </Label>
        )}
        {this.renderInput(type, value, onChange, placeholder, inputProps, name)}
        {!!isSubmitted && !isHideErrorMessage && errorMsg && (
          <div style={{ marginTop: '5px' }}>
            <ErrorMessage>{errorMsg}</ErrorMessage>
          </div>
        )}
      </FormControl>
    );
  }
}
