import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import { INVOICE_STATUS, PURCHASE_STATUS } from 'constants/commonData';

export const CounterWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  .payment-counter.__react_component_tooltip.show {
    padding: 10px 30px;
    border-radius: 5px;
  }
`;

export const CurrencySymbol = styled.div`
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  width: 15px;
  height: 18px;
  text-align: center;
  &.chf {
    margin-right: 10px;
  }
  &.nok {
    margin-right: 12px;
  }
`;

export const CounterNumber = styled.a`
  color: #5158cf;
  cursor: pointer;
  &:hover {
    color: #5158cf;
    text-decoration: underline;
  }
`;

export const CounterTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding-left: 8px;
  padding-right: 4px;
`;

export const PopupWrapper = styled(Modal)`
  &.ui.modal {
    width: 808px;
    border-radius: 8px;
    .header {
      border-radius: 8px;
      padding-top: 30px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 15px !important;
      color: #202353 !important;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }

    .content {
      padding: 15px !important;
      padding-top: 10px !important;
      max-height: 730px;
    }
    .actions {
      background-color: transparent;
      border-top: none;
      box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
      button {
        min-width: 110px;
      }
    }
  }
`;

export const TableWrapper = styled.div`
  max-height: 440px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
`;

export const Table = styled.table`
  border-spacing: 0 0;
  border-collapse: collapse;
  position: relative;
  width: 100%;
`;

export const TableHeader = styled.thead`
  border-radius: 5px;
  th {
    padding: 13px 15px;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #202353;
    text-transform: uppercase;
  }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  box-shadow: inset 0px -1px 0px #ebedf4;
  cursor: pointer;
  &:last-child {
    box-shadow: none;
  }
`;

export const TableCellHeader = styled.th`
  position: sticky;
  top: 0;
  background: #fafbfc;
  cursor: pointer;
`;

export const TableCell = styled.td`
  .padding {
    padding: 10px 15px;
  }

  .__react_component_tooltip.marketplace-icon {
    border-radius: 5px;
    padding: 10px 13px 10px 15px;
  }
`;

export const TableCellPackageName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .cover-image {
    min-width: 40px;
  }
`;

export const PackageNameDetail = styled.div`
  max-width: 157px;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  padding-left: 10px;
`;

export const PackageTypeDetail = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`;

export const TableCellPlatform = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableCellPurchase = styled.div``;

export const TableCellType = styled.div``;

export const TableCellStatus = styled.div``;

export const Price = styled.strong`
  font-weight: 600;
`;

export const Status = styled.div`
  border-radius: 3px;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  display: inline-block;

  text-transform: uppercase;
  padding: 4px 8px;

  ${props =>
    [PURCHASE_STATUS.ACTIVE, INVOICE_STATUS.PAID].includes(props.status) &&
    css`
      background: #eaf8ec;
      color: #2fb845;
    `}

  ${props =>
    [INVOICE_STATUS.OVERDUE].includes(props.status) &&
    css`
      background: rgba(234, 49, 74, 0.06);
      color: #ee5066;
    `}
    
  ${props =>
    [PURCHASE_STATUS.CANCEL, INVOICE_STATUS.SCHEDULED, PURCHASE_STATUS.PAUSED].includes(props.status) &&
    css`
      background: rgba(123, 126, 154, 0.1);
      color: #7b7e9a;
    `}

    ${props =>
    [PURCHASE_STATUS.TRIAL].includes(props.status) &&
    css`
      background: #ffb2241a;
      color: #ffb224; ;
    `}
`;
