import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Datetime from 'react-datetime';
import Select from 'react-select';
import TimeOptions from 'constants/time-options';
import { ReactComponent as ClockIcon } from 'assets/icons/time_indicator.svg';
import { DateInput } from 'shared/FormControl';
import { ErrorMessage } from 'shared/FormControl';
import * as S from './style';

export default ({ duration, onChange, highlightEndTime, shouldShowError, isEditing }) => {
  const { startTime, startDate, endTime, endDate } = duration;

  const onChangeEndTime = data => {
    if (endDate && endDate.isSame(startDate, 'day') && data.value <= startTime.value) {
      return;
    }

    onChange('endTime', data);
  };

  const onChangeStartTime = data => {
    onChange('startTime', data);
  };

  const onChangeStartDate = data => {
    onChange('startDate', data);
  };

  const onChangeEndDate = data => {
    onChange('endDate', data);
  };

  return (
    <S.Wrapper>
      <div className="title">Set Leaderboard Duration</div>
      <S.Container>
        <S.Group>
          <div className="label">Start Date</div>
          <div className="group">
            <Select
              options={TimeOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              components={{ DropdownIndicator: () => <ClockIcon />, IndicatorSeparator: null }}
              placeholder="-"
              onChange={onChangeStartTime}
              classNamePrefix="evfSelectBox"
              className="evfSelectBoxContainer"
              value={startTime}
              isSearchable={false}
              styles={{
                control: base => ({ ...base, minHeight: 42, height: 42, minWidth: 113 }),
                menu: base => ({ ...base, marginTop: 2, marginBottom: 5, minWidth: 113 }),
              }}
              menuPlacement="auto"
              isDisabled={isEditing}
            />
            <Datetime
              value={startDate}
              renderInput={props => <DateInput {...props} text={startDate.format('MMM D, YYYY')} />}
              inputProps={{ disabled: isEditing }}
              timeFormat={false}
              closeOnSelect={true}
              onChange={onChangeStartDate}
              className="new-ui"
              isValidDate={current => (endDate ? current.isBefore(endDate, 'day') : true)}
            />
          </div>
        </S.Group>
        <S.Arrow />
        <S.Group>
          <div className="label">End Date</div>
          <div className="group">
            <Select
              options={
                !endDate || endDate.isAfter(startDate, 'day')
                  ? TimeOptions
                  : TimeOptions.filter(item => item.value > startTime.value)
              }
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              components={{
                DropdownIndicator: () => <ClockIcon />,
                IndicatorSeparator: null,
              }}
              placeholder="--:-- --"
              onChange={onChangeEndTime}
              classNamePrefix="evfSelectBox"
              className={classnames('evfSelectBoxContainer', {
                highlight: highlightEndTime,
                error: shouldShowError && !endTime,
              })}
              value={endTime}
              isSearchable={false}
              styles={{
                control: base => ({ ...base, minHeight: 42, height: 42, minWidth: 120 }),
                menu: base => ({ ...base, marginTop: 2, marginBottom: 5, minWidth: 120 }),
              }}
              menuPlacement="auto"
            />
            <Datetime
              value={endDate}
              renderInput={props => (
                <DateInput {...props} text={endDate ? endDate.format('MMM D, YYYY') : 'Choose Date'} />
              )}
              timeFormat={false}
              closeOnSelect={true}
              onChange={onChangeEndDate}
              className={classnames('new-ui', { error: shouldShowError && !endDate })}
              isValidDate={current =>
                (!endTime || endTime.value > startTime.value
                  ? current.isSameOrAfter(startDate, 'day')
                  : current.isAfter(startDate, 'day')) && current.isSameOrAfter(moment(), 'day')
              }
            />
          </div>
          {shouldShowError && (!endDate || !endTime) ? (
            <ErrorMessage>{!endDate ? 'Please select end date' : 'Please select end time'}</ErrorMessage>
          ) : null}
        </S.Group>
      </S.Container>
    </S.Wrapper>
  );
};
