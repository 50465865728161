import React from 'react';
import get from 'lodash/get';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

const ForumTriggerOption = ({ data }) => {
  const { _id } = data;
  const no_of_members = get(data, 'no_of_members', 0);
  const banner = get(data, 'banner_thumbnail') || get(data, 'banner') || `${CDN_URL}/images/default_forum_banner.svg`;

  if (!_id) {
    return (
      <S.HorizontalWrapper>
        <S.Wrapper>
          <S.Title className="title">{get(data, 'label', '')}</S.Title>
        </S.Wrapper>
      </S.HorizontalWrapper>
    );
  }

  return (
    <S.HorizontalWrapper>
      <S.CoverImage>
        <img src={banner} alt={get(data, 'label', '')} />
      </S.CoverImage>
      <S.Wrapper>
        <S.Title className="title">{get(data, 'label', '')}</S.Title>
        <S.InfoText>
          {no_of_members} {pluralize('MEMBER', no_of_members)}
        </S.InfoText>
      </S.Wrapper>
    </S.HorizontalWrapper>
  );
};

export default ForumTriggerOption;
