import React, { useMemo, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';
import has from 'lodash/has';
import upperCase from 'lodash/upperCase';
import lowerCase from 'lodash/lowerCase';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import { ReactComponent as CreditIcon } from 'assets/icons/credit_icon.svg';
import { ReactComponent as CancelSubscriptionIcon } from 'assets/icons/cancel_invoice_sm.svg';
import { ReactComponent as UndoCancelIcon } from 'assets/icons/undo_cancel_icon.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/primary_resend.svg';
import { ReactComponent as Pause } from 'assets/icons/payment_pause.svg';
import { ReactComponent as Resume } from 'assets/icons/payment_resume.svg';
import {
  cancelSubscription,
  undoCancel,
  pauseSubscription,
  resumeSubscription,
  getInvoiceList,
} from 'redux/package-analytics/actions';
import { resendActivationEmail } from 'redux/package-detail/actions';
import CancelSubscriptionPopup from '../../CancelSubscriptionPopup';
import { toggleConfirmModal } from 'actions/modal';
import { CANCEL_REASON, INVOICE_STATUS, PACKAGE_PRICING_TYPES, PURCHASE_STATUS } from 'constants/commonData';
import CancelSubscriptionWithProduct from '../../CancelSubscriptionWithProduct';
import ArchiveClientPopup from '../../ArchiveClientPopup';
import PauseSubscriptionPopup from '../../PauseSubscriptionPopup';
import { formatDateSafari, getTrialLeftDays, pluralize } from 'utils/commonFunction';
import { StatusLabelOutline } from 'components/Package/components/StatusLabel';
import { isArchiveCoachManualCancel } from 'components/Package/helps';
import { SharedTooltip } from 'shared/SharedTooltip';

import * as S from './styles';

const openedPauseResume = process.env.REACT_APP_ENABLE_PAYMENT_PAUSE_RESUME;

function PurchaseInfo(props) {
  const [isUndo, setUndo] = useState(false);
  const [isResend, setResend] = useState(false);

  const {
    client,
    purchase,
    cancelSubscription,
    toggleConfirmModal,
    onClose,
    undoCancel,
    resendActivationEmail,
    callback,
    getInvoiceList,
    packageDetail,
    isMP,
    hasPaymentPermission,
  } = props;
  const cancelOptions = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const renderTrigger = ({ open }) => {
    const hasDropDownOption = document.querySelectorAll('.purchase-detail-dropdown .evf-dropdown__option') || [];
    if (hasDropDownOption.length === 0) return;

    return (
      <div data-for="purchase-detail-action-tooltip" data-tip>
        <S.TriggerCancelIcon active={!!open} />
        {!open && <SharedTooltip id="purchase-detail-action-tooltip" />}
      </div>
    );
  };

  const renderOneTimeTrigger = ({ open }) => {
    return (
      <div data-for="purchase-detail-action-tooltip" data-tip>
        <S.TriggerCancelIcon active={!!open} />
        {!open && <SharedTooltip id="purchase-detail-action-tooltip" />}
      </div>
    );
  };

  const handleCancel = () => {
    if (isLoading) {
      return;
    }

    return toggleConfirmModal(
      true,
      <CancelSubscriptionPopup onConfirm={handleNextStep} item={purchase} packageDetail={purchase._package} />,
    );
  };

  const handleNextStep = params => {
    cancelOptions.current = params;
    if (isLoading) {
      return;
    }
    if (purchase.is_product && lowerCase(purchase.status) !== PURCHASE_STATUS.TRIAL) {
      return toggleConfirmModal(true, <CancelSubscriptionWithProduct onConfirm={handleConfirmRemoveProduct} />);
    }
    handleConfirmCancel(params);
  };

  const handleConfirmRemoveProduct = status => {
    if (!status) {
      handleConfirmCancel({
        ...cancelOptions.current,
        is_unassign_asset: false,
      });
      return toggleConfirmModal(false);
    }
    if (isArchiveCoachManualCancel(packageDetail)) {
      handleConfirmCancel({
        ...cancelOptions.current,
        is_unassign_asset: true,
        is_cancel_with_archive_client: true,
      });
      return toggleConfirmModal(false);
    }
    handleConfirmArchiveClientPopup(true);
  };

  // Show popup confirm archive client when remove product
  const handleConfirmCancelAndArchiveClient = status => {
    handleConfirmCancel({
      ...cancelOptions.current,
      is_unassign_asset: true,
      is_cancel_with_archive_client: status,
    });
  };

  // Confirm archive client or no
  const handleConfirmArchiveClientPopup = status => {
    if (isLoading) {
      return;
    }
    return toggleConfirmModal(true, <ArchiveClientPopup onConfirm={handleConfirmCancelAndArchiveClient} />);
  };

  const handleConfirmCancel = params => {
    cancelSubscription(params, isMP);
    cancelOptions.current = null;
    onClose && onClose();
  };

  const handleUndo = () => {
    setUndo(true);
    undoCancel({ id: purchase.id }, isMP);
    setTimeout(() => {
      callback && callback();
    }, 1000);
  };

  const isCancelling = useMemo(() => {
    return moment().isBefore(purchase.canceled_at) && get(purchase, 'status') !== 'CANCELLED';
  }, [purchase]);

  const isCanceled = lowerCase(purchase.status) === PURCHASE_STATUS.CANCEL;

  const isOneTime = useMemo(() => {
    return get(purchase, 'price.type') === PACKAGE_PRICING_TYPES.one_time.value;
  }, [purchase]);

  // TODO - Handle Render Resend
  const handleRenderResend = purchaseID => {
    const _client = client ? client : false;

    if (!_client) return;

    const _id = has(client, '_id');

    if (!_id) {
      return (
        <S.PurchaseInfoResend>
          <ResendIcon />
          <S.PackageInfoResendLabel onClick={() => handleResendEmail(purchaseID)}>
            Resend activation email
          </S.PackageInfoResendLabel>
        </S.PurchaseInfoResend>
      );
    }
  };

  // TODO - Handle Resend Email
  const handleResendEmail = _id => {
    if (isResend) return;

    setResend(true);
    resendActivationEmail(_id).then(res => {
      if (res.data)
        // TODO - after API response delay 1s to resend again
        setTimeout(() => {
          setResend(false);
        }, 1000);
    });
  };

  const isPaused = useMemo(() => {
    return [PURCHASE_STATUS.PAUSED].includes(lowerCase(purchase.status)) && !purchase.canceled_at;
  }, [purchase]);

  const isTrial = useMemo(() => {
    return [PURCHASE_STATUS.TRIAL].includes(lowerCase(purchase.status));
  }, [purchase]);

  const isEndTrialAfterChargeFailed = useMemo(() => {
    return get(purchase, 'cancel_reason', '') === CANCEL_REASON.TRIAL_ENDED;
  }, [purchase]);

  // ========= PAUSE =========
  const handleConfirmPauseSubscription = item => () => {
    const { toggleConfirmModal } = props;
    if (isLoading) {
      return;
    }
    toggleConfirmModal && toggleConfirmModal(true, <PauseSubscriptionPopup handleConfirm={handlePause} item={item} />);
  };

  const handlePause = (item, date) => {
    const { pauseSubscription, callback } = props;
    pauseSubscription && pauseSubscription(item, date, callback, isMP);
  };
  // ========= END =========

  // ========= RESUME =========
  const handleConfirmResumeSubscription = item => () => {
    const { toggleConfirmModal } = props;
    if (isLoading) {
      return;
    }
    toggleConfirmModal &&
      toggleConfirmModal(true, <PauseSubscriptionPopup handleConfirm={handleResume} item={item} isResume />);
  };

  const handleResume = async (item, date, type) => {
    const { resumeSubscription } = props;
    setIsLoading(true);
    resumeSubscription && resumeSubscription(item, type, date, handleCallBack, isMP);
  };

  const handleCallBack = () => {
    setIsLoading(false);
    callback && callback();
    setTimeout(() => {
      getInvoiceList &&
        getInvoiceList(
          {
            package_id: get(purchase, '_package.id'),
            isInvoiceOfPackage: true,
            client_email: client.email,
            pageSize: 50,
            purchase_id: get(purchase, 'id'),
            client_id: get(client, '_id', ''),
          },
          isMP,
        );
    }, 1000);
  };

  // ========= END =========

  return (
    <S.Wrapper>
      <S.PurchaseInfoRow>
        <S.PurchaseId>Order Number: {purchase.hash_id || purchase.id}</S.PurchaseId>
        {handleRenderResend(purchase.hash_id || purchase.id)}
      </S.PurchaseInfoRow>

      <S.PackageInfoContent>
        <S.PackageInfo isTrialPackage={isTrial}>
          <S.PackageName>{purchase.package_name}</S.PackageName>
          {((!purchase.canceled_at && !!purchase.next_invoice) || isUndo || isPaused || isTrial) && (
            <DropDown triggerIcon={renderTrigger} className={`purchase-detail-dropdown ${isTrial && 'trial-purchase'}`}>
              {!purchase.canceled_at && !isTrial && (!!purchase.next_invoice || hasPaymentPermission) && (
                <Option key="active" onClick={handleCancel} className={classNames(isLoading && 'isLoading')}>
                  <CancelSubscriptionIcon />
                  <span>Cancel Subscription</span>
                </Option>
              )}
              {isTrial && (
                <Option key="trial" onClick={handleCancel}>
                  <CancelSubscriptionIcon />
                  <span>Cancel Trial</span>
                </Option>
              )}

              {openedPauseResume && !isPaused && !isTrial && (
                <Option
                  key="pause"
                  onClick={handleConfirmPauseSubscription(purchase)}
                  className={classNames(isLoading && 'isLoading')}
                >
                  <Pause />
                  <span>Pause Subscription</span>
                </Option>
              )}

              {openedPauseResume && isPaused && hasPaymentPermission && (
                <Option
                  key="resume"
                  onClick={handleConfirmResumeSubscription(purchase)}
                  className={classNames(isLoading && 'isLoading')}
                >
                  <Resume />
                  <span>Resume Subscription</span>
                </Option>
              )}
            </DropDown>
          )}
          {isOneTime && !purchase.canceled_at && !isTrial && (
            <DropDown triggerIcon={renderOneTimeTrigger}>
              <Option key="active" onClick={handleCancel} className={classNames(isLoading && 'isLoading')}>
                <CancelSubscriptionIcon />
                <span>Mark as Cancelled</span>
              </Option>
            </DropDown>
          )}
        </S.PackageInfo>
        <S.CreditInfo>
          <CreditIcon />
          <S.CreditInfoDetail className={classNames({ resendActive: !has(client, '_id') })}>
            {upperCase(get(purchase, 'card.brand'))} ending in &nbsp;<b>{get(purchase, 'card.last4')}</b>
          </S.CreditInfoDetail>
        </S.CreditInfo>
      </S.PackageInfoContent>
      {isTrial && (
        <S.CancelContent>
          <StatusLabelOutline status={lowerCase('trial')}>Trial</StatusLabelOutline>
          <S.PurchaseDescription>
            Trial started on <b>{moment(get(purchase, 'created_at')).format('MMM D, YYYY')}</b> and will end on{' '}
            <b>{moment(get(purchase, 'ended_trial_at')).format('MMM D, YYYY')}</b> (
            {pluralize('day', getTrialLeftDays(get(purchase, 'ended_trial_at', '')), true)} left).
          </S.PurchaseDescription>
        </S.CancelContent>
      )}
      {isPaused && (
        <S.CancelContent>
          <S.PausedButton>Paused</S.PausedButton>
          {purchase.resume_day ? (
            <S.CancelDescription>
              This purchase will be paused until &nbsp;
              <S.ResumeDate>{moment(formatDateSafari(purchase.resume_day)).format('MMM D, YYYY')}</S.ResumeDate>
            </S.CancelDescription>
          ) : (
            <S.CancelDescription>This purchase was paused</S.CancelDescription>
          )}
        </S.CancelContent>
      )}
      {!!purchase.canceled_at && !isUndo && (
        <S.CancelContent>
          {isCanceled ? (
            <StatusLabelOutline status={lowerCase(purchase.status)}>{lowerCase(purchase.status)}</StatusLabelOutline>
          ) : (
            <StatusLabelOutline status={lowerCase(INVOICE_STATUS.SCHEDULED)}>Cancel</StatusLabelOutline>
          )}
          <S.CancelDescription>
            {isEndTrialAfterChargeFailed && (
              <>
                The payment was failed on{' '}
                <b>
                  {moment(get(purchase, 'cancellation.created_at') || get(purchase, 'canceled_at')).format(
                    'MMM D, YYYY',
                  )}
                </b>{' '}
                after a {get(purchase, 'price.trial_period', 0)}-day trial.
              </>
            )}
            {!isEndTrialAfterChargeFailed && isCancelling && (
              <>
                Cancellation processed on{' '}
                <b>
                  {moment(
                    formatDateSafari(get(purchase, 'resume_day')) || get(purchase, 'cancellation.created_at'),
                  ).format('MMM D, YYYY')}
                </b>
                . This subscription will end on{' '}
                <b>{moment(get(purchase, 'cancellation.canceled_at')).format('MMM D, YYYY')}</b>.
              </>
            )}
            {!isEndTrialAfterChargeFailed && !isCancelling && (
              <>
                {!purchase.stripe_invoice_id
                  ? 'This purchase was marked as cancelled on '
                  : isOneTime
                  ? 'This purchase was marked as cancelled on '
                  : 'This subscription ended on '}
                <b>{moment(get(purchase, 'canceled_at')).format('MMM D, YYYY')}</b>.
              </>
            )}
          </S.CancelDescription>
          {isCancelling && (
            <S.UndoCancel>
              <UndoCancelIcon data-tip data-for="cancel-subscription-tooltip" onClick={handleUndo} />
              <ReactTooltip id="cancel-subscription-tooltip" effect="solid" place={'top'} delayShow={300}>
                Undo the cancellation
              </ReactTooltip>
            </S.UndoCancel>
          )}
        </S.CancelContent>
      )}
    </S.Wrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: { packageDetail, permission },
  } = state;
  return {
    packageDetail: packageDetail.get('workingPackage').toJS(),
    hasPaymentPermission: permission.payment || false,
  };
};

const actionCreators = {
  toggleConfirmModal,
  cancelSubscription,
  undoCancel,
  resendActivationEmail,
  pauseSubscription,
  resumeSubscription,
  getInvoiceList,
};

export default connect(mapState, actionCreators)(PurchaseInfo);
