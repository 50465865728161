import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowCircleUp } from 'assets/icons/arrow-circle-up.svg';

import * as S from '../style';

const BackToTop = ({ onScrollToTop }) => {
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsWaiting(true); // This will render the component
    }, 100);

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, []);

  return (
    <>
      {isWaiting && (
        <S.ToTopWrapper>
          <ArrowCircleUp onClick={onScrollToTop} />
          <S.ToTopContent>
            <S.ToTopTitle>That's all of your notifications</S.ToTopTitle>
            <S.ToTopButton onClick={onScrollToTop}>Back to top</S.ToTopButton>
          </S.ToTopContent>
        </S.ToTopWrapper>
      )}
    </>
  );
};

export default BackToTop;
