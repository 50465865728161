import React from 'react';
import { IN_APP_MESSAGE_STATUS } from 'constants/commonData';
import { Button } from 'shared/FormControl';
import { IntervalMessageActions } from '../style';

export default ({
  currentStatus,
  editable,
  onPause,
  onPauseAndEdit,
  onResume,
  onEdit,
  disableSubmitting,
  totalMessages,
  onScheduleAndResume,
}) => {
  const renderButtons = () => {
    switch (currentStatus) {
      case IN_APP_MESSAGE_STATUS.ACTIVE:
        return (
          <>
            <Button type="button" onClick={onPause}>
              Pause
            </Button>
            <Button type="button" onClick={onPauseAndEdit} borderPurple>
              Pause & Edit
            </Button>
          </>
        );
      case IN_APP_MESSAGE_STATUS.PAUSE:
        return editable ? (
          <>
            <div className="remaining">Messages remaining: {3 - totalMessages}/3</div>
            <Button type="submit" disabled={disableSubmitting} onClick={onScheduleAndResume} purple>
              Schedule & Resume
            </Button>
          </>
        ) : (
          <>
            <Button type="button" onClick={onResume}>
              Resume
            </Button>
            <Button type="button" onClick={onEdit} borderPurple>
              Edit
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <IntervalMessageActions
      className="interval-message__actions"
      editting={currentStatus === IN_APP_MESSAGE_STATUS.PAUSE && editable}
    >
      {renderButtons()}
    </IntervalMessageActions>
  );
};
