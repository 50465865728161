import React, { useState, useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';

import * as S from './style';

import ForumHeader from '../ForumHeader';
import StickyHeader from '../ForumHeader/StickyHeader';
import PopUpForm from '../ForumTimeline/components/CreatePostForm/components/PopUpForm';
import { POST_TYPES } from 'components/CommunityForum/constants';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_black.svg';
import { LeaderboardDetail } from 'components/Leaderboard';
import { convertUnit, roundUp } from 'utils/commonFunction';

export default function LeaderboardTab(props) {
  const scrollTopRef = useRef();
  const container = useRef();
  let lastScrollTop = 0;

  useEffect(() => {
    const { getGroupDetail } = props;
    getGroupDetail();
    getConnectedLeaderboard(data => {
      if (data.length === 0) {
        push(`/home/forums/${props.match.params.id || props.match.params.forumId}/discussion`);
      }
    });

    return () => autoflowLeaderboardUnmount();
  }, [props.match.params.id || props.match.params.forumId]);

  const handleScroll = event => {
    event.persist();
    const { scrollTop } = event.target;

    if (scrollTop > 396) setSticky(true);
    if (scrollTop < 396) setSticky(false);

    if (scrollTopRef.current) {
      if (scrollTop > 324 || (isSticky && scrollTop > 10)) {
        scrollTopRef.current.classList.add('show');
      } else {
        scrollTopRef.current.classList.remove('show');
      }
    }

    lastScrollTop = scrollTop;
  };

  const handleScrollTop = () => {
    container.current.scrollTop = 0;
  };

  const [isSticky, setSticky] = useState(false);
  const {
    push,
    connectedLeaderboard,
    rankingTypes,
    autoflowGetLeaderboardClients,
    getConnectedLeaderboard,
    toggleModal,
    match,
    autoflowLeaderboardUnmount,
    leaderboardSetting = [],
  } = props;

  const leaderboardData = _.cloneDeep(_.get(connectedLeaderboard, '[0]', null));

  const onLoadClients = requestData => {
    autoflowGetLeaderboardClients({ ...requestData, leaderboardId: leaderboardData._id });
  };

  const handleShareLeaderboard = () => {
    const leaderboardData = _.cloneDeep(_.get(connectedLeaderboard, '[0]', null));
    toggleModal(true, <PopUpForm match={match} postType={POST_TYPES.LEADERBOARD} leaderboard={leaderboardData} />);
  };

  const leaderboardClients = useMemo(() => {
    const { user, connectedLeaderboard: data } = props;
    const leaderboardData = _.cloneDeep(_.get(data, '[0]', {}));

    const defaultSetting = _.get(leaderboardData, 'unit_id');

    const metricSettingData = leaderboardSetting.find(it => it.unique_code === 'weight');
    const setting =
      leaderboardData.ranking_type === 2 && metricSettingData
        ? metricSettingData.unit
        : _.get(user, 'preferences')['weight'];

    const { members = [] } = leaderboardData;

    const convertData = members.map(it => {
      it.score = roundUp(
        defaultSetting && _.isObject(defaultSetting) && setting
          ? roundUp(convertUnit(it.score, defaultSetting, setting), 2)
          : it.score,
        2,
      );
      it.setting = setting;
      return it;
    });

    return convertData;
  }, [connectedLeaderboard]);

  return (
    <S.Wrapper onScroll={handleScroll} ref={container}>
      <ForumHeader {...props} />
      {isSticky && <StickyHeader {...props} isSticky={isSticky} />}
      <S.Container>
        <S.ListContainer>
          {leaderboardData && (
            <LeaderboardDetail
              leaderboard={leaderboardData}
              clients={{
                isLoaded: true,
                total: leaderboardData.members.length,
                list: leaderboardClients,
              }}
              rankingTypes={rankingTypes.toJS() || []}
              onLoadClients={onLoadClients}
              shareLeaderboardAction={handleShareLeaderboard}
              noActions
            />
          )}
        </S.ListContainer>

        <S.ScrollTop ref={scrollTopRef} onClick={handleScrollTop}>
          <ArrowUpIcon />
        </S.ScrollTop>
      </S.Container>
    </S.Wrapper>
  );
}
