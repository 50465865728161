import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import CalendarCell from 'components/CalendarCell';
import CalendarHistoryCell from 'components/CalendarHistoryCell';

const weekday = new Array(7);
weekday[0] = 'Mon';
weekday[1] = 'Tue';
weekday[2] = 'Wed';
weekday[3] = 'Thu';
weekday[4] = 'Fri';
weekday[5] = 'Sat';
weekday[6] = 'Sun';

function Column(props) {
  const { calendarType, calendarStartDate, calendarMode, isDragging, column, handleCurrentWeekHover } = props;
  const dayOfWeek = useMemo(() => weekday[column], [column]);
  function renderCalendarCell(_, index) {
    const day = DateTime.fromISO(calendarStartDate.toISODate());
    const dayCell = day.plus({ days: 7 * index + column });
    const dayCellOfWeek = dayCell.toFormat('EEE');
    if (calendarMode === 'history') {
      return <CalendarHistoryCell key={dayCell.toString()} day={dayCell} dayofWeek={dayCellOfWeek} column={column} />;
    }
    return (
      <CalendarCell
        key={dayCell.toString()}
        day={dayCell}
        dayofWeek={dayCellOfWeek}
        weekIndex={index + 1}
        column={column}
        isDragging={isDragging}
        onMouseEnter={() => handleCurrentWeekHover(index)}
      />
    );
  }
  return (
    <div
      className={classNames('calendar-column', `weekday-${dayOfWeek}`, {
        'first-calendar-column': column === 0,
        'second-calendar-column': column === 1,
      })}
    >
      <div className="calendar-column__header">{dayOfWeek.toUpperCase()}</div>
      <div className="calendar-column__body">{Array(calendarType).fill(null).map(renderCalendarCell)}</div>
    </div>
  );
}

export default Column;
