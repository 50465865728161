import Request from 'configs/request';
import _ from 'lodash';

import { push } from 'connected-react-router';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

export const Types = {
  STUDIO_RESOURCES_CHANGE_QUERY_PARAMS: 'STUDIO_RESOURCES_CHANGE_QUERY_PARAMS',
  STUDIO_RESOURCES_LOADING_DATA: 'STUDIO_RESOURCES_LOADING_DATA',
  STUDIO_RESOURCES_SUCCESS_GET_LIST: 'STUDIO_RESOURCES_SUCCESS_GET_LIST',
  STUDIO_RESOURCES_FAILED_GET_LIST: 'STUDIO_RESOURCES_FAILED_GET_LIST',
  STUDIO_RESOURCES_SUCCESS_LOAD_MORE_LIST: 'STUDIO_RESOURCES_SUCCESS_LOAD_MORE_LIST',
  STUDIO_RESOURCES_SUCCESS_HIDE_BANNER: 'STUDIO_RESOURCES_SUCCESS_HIDE_BANNER',
  STUDIO_RESOURCE_SUCCESS_HIDE_GUIDE: 'STUDIO_RESOURCE_SUCCESS_HIDE_GUIDE',
  STUDIO_RESOURCES_LIST_RESET_DATA: 'STUDIO_RESOURCES_LIST_RESET_DATA',
  STUDIO_RESOURCE_SUCCESS_GET_DETAIL: 'STUDIO_RESOURCE_SUCCESS_GET_DETAIL',
  STUDIO_RESOURCE_SUCCESS_UPDATE: 'STUDIO_RESOURCE_SUCCESS_UPDATE',
  STUDIO_RESOURCE_SUCCESS_DELETE_RESOURCE: 'STUDIO_RESOURCE_SUCCESS_DELETE_RESOURCE',
  STUDIO_RESOURCE_RESET_DETAIL_DATA: 'STUDIO_RESOURCE_RESET_DETAIL_DATA',
  STUDIO_RESOURCE_UPDATE_REDUX: 'STUDIO_RESOURCE_UPDATE_REDUX',
  STUDIO_RESOURCE_CHANGE_SHARE_STATUS: 'STUDIO_RESOURCE_CHANGE_SHARE_STATUS',
};

export const changeStudioResourceQueryParams = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioResource: { query },
      },
    } = getState();

    const newData = { ...query, ...data };

    dispatch({
      type: Types.STUDIO_RESOURCES_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });
    dispatch(getListStudioResource(newData));
  };
};

export const getListStudioResource = params => {
  return (dispatch, getState) => {
    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      const {
        rootReducer: {
          studioResource: { query },
        },
      } = getState();

      queryParams = query;
    }

    queryParams.page = 1;

    dispatch({ type: Types.STUDIO_RESOURCES_LOADING_DATA });

    return dispatch(
      Request.post(
        { url: '/api/studio-resource/fetch-by-trainer', data: queryParams },
        true,
        response => {
          const { data, total } = response.data;
          dispatch({ type: Types.STUDIO_RESOURCES_SUCCESS_GET_LIST, payload: { data, total } });
        },
        error => {
          dispatch({ type: Types.STUDIO_RESOURCES_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const loadMoreStudioResource = (isLoading = false) => {
  return (dispatch, getState) => {
    let queryParams;

    const {
      rootReducer: {
        studioResource: { query },
      },
    } = getState();

    queryParams = query;
    queryParams.page = queryParams.page + 1;

    if (isLoading) {
      dispatch({ type: Types.STUDIO_RESOURCES_LOADING_DATA });
    }

    return dispatch(
      Request.post(
        { url: '/api/studio-resource/fetch-by-trainer', data: queryParams },
        true,
        response => {
          const { data, total, page } = response.data;
          dispatch({ type: Types.STUDIO_RESOURCES_SUCCESS_LOAD_MORE_LIST, payload: { data, total, page } });
        },
        error => {
          dispatch({ type: Types.STUDIO_RESOURCES_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const hideStudioResourceBanner = () => {
  return Request.post({ url: '/api/studio-resource/hide-banner' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.STUDIO_RESOURCES_SUCCESS_HIDE_BANNER });
  });
};

export const resetListData = () => ({ type: Types.STUDIO_RESOURCES_LIST_RESET_DATA });

export const getStudioResourceDetail = studioResourceId => {
  return Request.post(
    { url: '/api/studio-resource/get-detail', data: { studioResourceId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_RESOURCE_SUCCESS_GET_DETAIL, payload: { data } });
    },
  );
};

export const getAvailableClients = studioResourceId => {
  return Request.post(
    { url: '/api/studio-resource/get-clients-available', data: { studioResourceId } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_RESOURCE_SUCCESS_GET_AVAILABLE_CLIENTS, payload: { data } });
    },
  );
};

export const addClientToRedux = clients => {
  return { type: Types.STUDIO_RESOURCE_ADD_CLIENT_TO_REDUX, payload: { data: clients } };
};

export const updateStudioResourceToRedux = data => {
  return { type: Types.STUDIO_RESOURCE_UPDATE_REDUX, payload: { data } };
};

export const removeClientFromRedux = client => {
  return { type: Types.STUDIO_RESOURCE_REMOVE_CLIENT_TO_REDUX, payload: { data: client } };
};

export const saveClientsAvailable = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        studioResource: { availableClients, workingStudio },
      },
    } = getState();

    const studioResourceId = _.get(workingStudio, '_id');
    const clients = _.map(availableClients, '_id');
    return dispatch(
      Request.post(
        {
          url: '/api/studio-resource/add-client',
          data: { studioResourceId, clients },
        },
        false,
        response => {
          dispatch({ type: Types.STUDIO_RESOURCE_SUCCESS_SAVE_CLIENTS });
        },
      ),
    );
  };
};

export const updateStudioResource = (id, params) => {
  return Request.post(
    { url: '/api/studio-resource/update', data: { id, ...params } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.STUDIO_RESOURCE_SUCCESS_GET_DETAIL, payload: { data } });
    },
  );
};

export const duplicateStudioResource = resource => {
  const body = {
    resourceId: resource._id,
    resource_name: `Copy of ${resource.resource_name}`,
  };
  if (body.resource_name.length > 30) {
    body.resource_name = _.truncate(body.resource_name, { length: 30 });
  }
  return Request.post({ url: '/api/studio-resource/clone', data: body }, false, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch(push(`/home/studio-resource/${data._id}`));
  });
};

export const removeStudioResource = resource => {
  const body = {
    resourceId: resource._id,
  };
  return Request.post({ url: '/api/studio-resource/remove-resource', data: body }, false, (response, { dispatch }) => {
    dispatch({ type: Types.STUDIO_RESOURCE_SUCCESS_DELETE_RESOURCE, payload: { data: body } });
  });
};

export const resetStudioResourceDetail = () => ({ type: Types.STUDIO_RESOURCE_RESET_DETAIL_DATA });

export const updateShareStatus = ({ params = {}, studioResourceId } = {}) => (dispatch, getState) => {
  if ((!params.owner && params.share !== TEAM_SHARE_NOOWNER) || !studioResourceId) return;
  const {
    user: { _id: userId, team_member = [] },
    rootReducer: {
      studioResource: { list },
    },
  } = getState();

  return dispatch(
    Request.patch(
      {
        url: `/api/studio-resource/${studioResourceId}/share`,
        data: params,
      },
      true,
      response => {
        if (((response.data || {}).data || {}).success) {
          let newList = [];
          if (params.owner === userId || params.share !== TEAM_SHARE_PRIVATE) {
            newList = [...list].map(it => {
              if (it._id === studioResourceId) {
                const author_info = team_member.find(it => it._id === params.owner);
                return { ...it, share: params.share, author: params.owner, author_info };
              }
              return it;
            });
          } else {
            newList = [...list].filter(it => it._id !== studioResourceId);
          }

          dispatch({
            type: Types.STUDIO_RESOURCE_CHANGE_SHARE_STATUS,
            payload: newList,
          });
        }
      },
    ),
  );
};
