import React from 'react';
import { ReactComponent as NutritionIcon } from 'assets/icons/guide_nutrition.svg';
import { ReactComponent as HelpCenterIcon } from 'assets/icons/guide_help_center.svg';
import { ReactComponent as GetStartIcon } from 'assets/icons/guide_get_start.svg';
import { ReactComponent as WorkoutIcon } from 'assets/icons/guide_workout.svg';
import { ReactComponent as HabitIcon } from 'assets/icons/guide_habit_coaching.svg';
import { ReactComponent as AutomationIcon } from 'assets/icons/guide_automation.svg';
import { ReactComponent as Ondemand } from 'assets/icons/hp_ondemand.svg';
import { ReactComponent as Payment } from 'assets/icons/hp_payment.svg';
import { ReactComponent as Team } from 'assets/icons/hp_team.svg';
import { ReactComponent as EverfitAcademy } from 'assets/icons/hp_everfit_academy.svg';

export const List = [
  {
    key: 'help_center',
    link: 'http://help.everfit.io/',
    text: 'Help Center',
    icon: <HelpCenterIcon />,
  },
  {
    key: 'everfit_academy',
    link: process.env.REACT_APP_EVERFIT_ACADEMY_LINK,
    text: 'Everfit Academy',
    icon: <EverfitAcademy />,
  },
  {
    key: 'getting_started',
    link: 'http://help.everfit.io/en/collections/1576912-getting-started',
    text: 'Getting Started',
    icon: <GetStartIcon />,
  },
  {
    key: 'workout_builder',
    link: 'http://help.everfit.io/en/collections/1789451-everfit-s-workout-builder',
    text: 'Workout Builder',
    icon: <WorkoutIcon />,
  },
  {
    key: 'nutrition_coaching',
    link: 'http://help.everfit.io/en/collections/2541271-nutrition-coaching',
    text: 'Nutrition Coaching',
    icon: <NutritionIcon />,
  },
  {
    key: 'habit_coaching',
    link: 'http://help.everfit.io/en/collections/2863885-tasks-habits',
    text: 'Habit Coaching',
    icon: <HabitIcon />,
  },
  {
    key: 'automation',
    link: 'http://help.everfit.io/en/collections/2124384-autoflow',
    text: 'Automation',
    icon: <AutomationIcon />,
  },
  {
    key: 'on_demand',
    link: 'https://help.everfit.io/en/collections/2571710-on-demand-training-features',
    text: 'On-demand Training',
    icon: <Ondemand />,
  },
  {
    key: 'payment',
    link: 'https://help.everfit.io/en/collections/3210719-payment-packages',
    text: 'Payment & Packages',
    icon: <Payment />,
  },
  {
    key: 'team',
    link: 'https://help.everfit.io/en/collections/1789337-team-features',
    text: 'For Teams',
    icon: <Team />,
  },
];
