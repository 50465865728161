export const STATUS_HABIT = {
  DOING: 'doing',
  DONE: 'done',
};

export const LABEL_STATUS_HABIT = {
  DOING: 'Doing',
  MISSED: 'Missed',
  NOT_STARTED: 'Not started',
};

export const TYPE_FREQUENCY = {
  PER_DAY: 'per_day',
  PER_WEEK: 'per_week',
};
