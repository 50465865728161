import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import _ from 'lodash';
import { DropdownIndicator } from 'shared/Icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeComplianceView } from 'redux/autoflowInterval/client/actions';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .evfSelectBoxContainer {
    width: 212px;
    z-index: 10;

    .evfSelectBox__control {
      min-height: 30px;
      background-color: #fff;

      .evfSelectBox__value-container {
        padding-left: 12px;
        padding-right: 5px;
      }
    }

    .evfSelectBox__dropdown-indicator {
      padding: 0 12px 0 0 !important;
    }
  }
`;

const SingleValueWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
`;

const SingleValue = ({ innerProps, data }) => {
  return (
    <SingleValueWrapper {...innerProps}>
      <span>Compliance Data:&nbsp;</span>
      <strong>{data.view}</strong>
    </SingleValueWrapper>
  );
};

const complianceOptions = [
  {
    key: '7d',
    value: '7d',
    view: 'Last 7d',
    label: 'Last 7 days',
  },
  {
    key: '30d',
    value: '30d',
    view: 'Last 30d',
    label: 'Last 30 days',
  },
  {
    key: 'all',
    value: 'all',
    view: 'All Time',
    label: 'All Time',
  },
];

const ComplianceFilter = props => {
  const { className, view } = props;
  const viewSelected = _.find(complianceOptions, v => v.value === view);

  const onChange = data => {
    props.changeComplianceView(data.value);
  };

  return (
    <Wrapper className={className}>
      <Select
        value={viewSelected}
        key="compliance_select"
        options={complianceOptions}
        onChange={onChange}
        components={{ DropdownIndicator, IndicatorSeparator: null, SingleValue }}
        classNamePrefix="evfSelectBox"
        className="evfSelectBoxContainer"
        isSearchable={false}
      />
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  const view = rootReducer.autoflowInterval.client.get('complianceView');
  return {
    view,
  };
};

const mapDispatchToProps = dispatch => ({
  changeComplianceView: bindActionCreators(changeComplianceView, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceFilter);
