import styled, { css } from 'styled-components';
import { Dropdown as DropdownElement } from 'semantic-ui-react';
import { ellipsis } from 'polished';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

const handleShowGalleryItem = num => {
  switch (num) {
    case 1:
      return `
        :nth-child(1) {
          width: 568px; 
          height: 568px;
        }
      `;
    case 2:
      return `
        :nth-child(1) {
          width: 282px; 
          height: 282px;
        }
        :nth-child(2) {
          width: 282px;
          height: 282px;
        }
      `;
    case 3:
      return `
        :nth-child(1) {
          width: 186.67px; 
          height: 186px;
        }
        :nth-child(2) {
          width: 186.67px; 
          height: 186px;
        }
        :nth-child(3) {
          width: 186.67px; 
          height: 186px;
        }
      `;
    case 4:
      return `
        :nth-child(1) {
          width: 282px; 
          height: 282px;
        }
        :nth-child(2) {
          width: 282px; 
          height: 282px;
        }
        :nth-child(3) {
          width: 282px; 
          height: 282px;
        }
        :nth-child(4) {
          width: 282px;
          height: 282px;
        }
      `;
    case 5:
      return `
        :nth-child(1) {
          width: 282px; 
          height: 282px;
        }
        :nth-child(2) {
          width: 282px; 
          height: 282px;
        }
        :nth-child(3) {
          width: 186.67px; 
          height: 186.67px;
        }
        :nth-child(4) {
          width: 186.67px; 
          height: 186.67px;
        }
        :nth-child(5) {
          width: 186.67px; 
          height: 186.67px;
        }
      `;
    default:
      return `
        :nth-child(1) {
          width: 568px; 
          height: 568px;
        }
      `;
  }
};

export const GalleryItem = styled.a`
  overflow: hidden;
  cursor: ${props => (props.unpermitted ? 'default' : 'pointer')};
  position: relative;
  width: 100%;
  height: 100%;

  .image-with-fallback {
    height: 100%;
  }

  .video-container {
    flex: 1 1 !important;
    width: fill-available;
    height: 100%;
    aspect-ratio: 16/9;
    max-height: 100%;
    min-width: unset;
    min-height: unset;
    position: relative;
    .s3-video {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: auto;
      background-size: cover;
      overflow: hidden;
      object-fit: cover;
    }
    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
      ::after {
        content: url(${CDN_URL}/images/play_icon_white.svg);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    min-width: unset;
    min-height: unset;
  }

  ${props =>
    props.more &&
    css`
      ::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }

      ::after {
        content: ${props => `'+ ${props.more}'`};
        background-color: transparent;
        color: #fff;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  .gallery-item-wrapper {
    ${({ num }) => handleShowGalleryItem(num)};
  }
`;

export const Caption = styled.div`
  word-break: break-word;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.029999999329447746px;
  text-align: left;
  white-space: pre-line;

  ${props =>
    props.hasPreview &&
    css`
      margin-bottom: 15px !important;
    `};

  .public-DraftStyleDefault-ltr {
    display: ${props => (props.showMore ? 'block' : 'unset')};
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 84px;
    ${props =>
      props.hasPreview &&
      css`
        margin-bottom: 15px;
      `};
  }
  .public-DraftEditor-content {
    ${props =>
      props.hasPreview &&
      css`
        margin-bottom: 15px;
      `};
  }
`;

export const CaptionLink = styled.a`
  color: #0068e1;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.03px;
  ${({ showMore }) => showMore && ellipsis(undefined, 4)}
`;

export const ShowMoreText = styled.a`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #828282;
  display: inline-block;
  margin-bottom: 15px;

  ${props =>
    props.hasPreview &&
    css`
      margin-bottom: 15px !important;
    `}

  &:hover {
    text-decoration: none !important;
    cursor: ${props => (props.disabled ? 'default !important' : 'pointer !important')};
    color: ${props => (props.disabled ? '#828282' : '#1e70bf')};
  }
`;

export const Like = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;

  .name-text {
    font-weight: bold;
  }

  .and-text {
    font-weight: normal;
  }
`;

export const Comment = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;

  .name {
    font-weight: bold;
  }
`;

export const Header = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding: 15px 15px 0 15px;
  display: flex;
  position: relative;
  margin-bottom: 15px;

  .forum-post-options-wrapper {
    width: 20px;
    height: 20px;
    background: transparent;
    position: absolute;
    top: 12px;
    right: 19px;
    z-index: 2;
    cursor: pointer;
  }

  .dropdown-wrapper {
    width: 100%;
    height: 20px;
    background: transparent;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

export const Body = styled.div`
  padding: 0 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #000000;

  > * {
    margin-bottom: ${props =>
      props.isScheduledPost ? (props.hasPreview ? '0px' : '20px') : props.hasPreview ? '0px' : '15px'};
  }
`;

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  border-radius: 8px;
  padding: 0px, 15px, 5px, 15px;

  .interactions {
    padding: 5px 15px;
  }

  .ui.dropdown .dropdown-menu.more-actions {
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
    border: none !important;
    overflow: auto;
  }

  .ui.dropdown .dropdown-menu.more-actions > .item:first-child::before {
    content: none;
  }
`;

export const AuthorNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: 13px;
  height: ${props => (props.isScheduledPost ? '' : '34px')};
  margin-left: 10px;
  align-self: center;
  color: #202353;

  .author-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: ${props => (props.isScheduledPost ? '4px' : '7px')};
    width: 250px;
    min-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .create-at {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    opacity: 0.5 !important;
    color: #202353;
  }
  .focus {
    cursor: pointer;
  }
`;

export const PostStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 20px;

  ${props =>
    props.hasPreview &&
    css`
      margin-top: 0px;
    `}

  .comment {
    span {
      margin-right: 0;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: block;
  position: relative;
  width: ${props => (props.twoAvatars ? '35px' : '')};
  margin-right: 5px;
  height: 20px;

  > * {
    &:nth-child(2) {
      img,
      .sb-avatar__text {
        position: absolute;
        left: 15px;
        top: 0;
        display: inline-block;
        box-shadow: 0 0 0 3px white;
      }
    }
  }
`;

export const Dropdown = styled(DropdownElement)`
  position: absolute !important;
  top: 15px !important;
  right: 18px !important;
  ${props =>
    props.defaultCursor &&
    css`
      cursor: default !important;
      visibility: hidden;
    `}
`;

export const DropItem = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202353;
  padding: 17px 20.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: auto;

  & > span {
    margin-left: 10px;
  }

  &:hover {
    color: #5c54c3;
    background-color: #ececfa;
    svg path {
      stroke: #5158cf;
    }
    svg.item-without-stroke {
      path {
        fill: #5158cf;
        stroke: none;
      }
    }
  }
`;

export const EmptyComments = styled.div`
  height: 46px;
  width: 598px;
  padding: 14px 16px;
  background: #f4f6f8;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #393a4f;
  border-radius: 0 0 8px 8px;
`;

export const SConfirmModal = styled(ConfirmModal)`
  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }

  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    background-color: #ec465c !important;
  }

  .ui.button.confirm-no-button,
  .ui.button.confirm-yes-button {
    line-height: 20px !important;
  }

  .confirm-content-header {
    padding: 30px 30px 0 !important;

    .confirm-header-label {
      color: #000000;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .confirm-content-body {
    padding: 15px 30px 30px !important;

    .confirm-content-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  .confirm-header-image {
    width: 23px;
    height: 23px;
  }
`;

export const RemoveScheduleConfirmModal = styled(ConfirmModal)`
  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }

  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    opacity: 0.9;
  }

  .ui.button.confirm-no-button,
  .ui.button.confirm-yes-button {
    height: 30px;
    border-radius: 5px;
  }

  .ui.button.confirm-yes-button {
    width: 111px;
    background-color: #ff4d4f !important;
  }

  .ui.button.confirm-no-button {
    width: 102px;
    min-width: 102px;
  }

  .confirm-content-header {
    padding: 30px 30px 0 !important;

    .confirm-header-label {
      color: #000000;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .confirm-content-body {
    padding: 15px 30px 30px !important;

    .confirm-content-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  .confirm-header-image {
    width: 25px;
    height: 25px;
  }
`;

export const Notification = styled.span`
  width: 10px;
  height: 10px;
  border: 1.5px solid #ffffff;
  background: #f9a014;
  border-radius: 50%;
  box-sizing: border-box;
  transform: translateY(-7px);
`;

export const ScheduledBox = styled.div`
  width: 160px;
  height: 24px;
  background-color: #e8e8e8;
  border-radius: 4px;
  padding: 2px 8px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #d9d9d9;
  }
  ${props =>
    props.unpermitted &&
    css`
      cursor: default !important;
      &:hover {
        background-color: #e8e8e8 !important;
      }
    `}
`;

export const ScheduledTime = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  white-space: nowrap;
  ${props =>
    props.unpermitted &&
    css`
      cursor: default !important;
    `}
`;

export const UnscheduledBox = styled.div`
  width: 96px;
  height: 24px;
  background-color: #ffebe9;
  border-radius: 4px;
  padding: 2px 8px;
  justify-content: center;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #fddad7;
  }

  ${props =>
    props.unpermitted &&
    css`
      cursor: default !important;
      &:hover {
        background-color: #ffebe9 !important;
      }
    `}

  ${props =>
    props.noHover &&
    css`
      &:hover {
        background-color: #ffebe9;
      }
    `}
`;

export const UnscheduledTime = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  white-space: nowrap;
  color: #ff4d4f;
  ${props =>
    props.unpermitted &&
    css`
      cursor: default !important;
    `}
`;
