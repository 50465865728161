import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import { DATE_FORMAT } from 'constants/commonData';
import { TableContainer } from './style';
import { pluralize } from 'utils/commonFunction';
import LoadingItem from './LoadingItem';

function WeeklyProgress({ chartData, loadingChartData }) {
  const list = useMemo(() => chartData.filter(item => !!item.value).reverse(), [chartData]);

  return (
    <TableContainer className="weekly">
      <div className="table-header">
        <div className="table-header__content">
          <div className="time">Date</div>
          <div className="value">Total Steps</div>
        </div>
      </div>
      <div className={classnames('table-content', { empty: !loadingChartData && !list.length })}>
        {loadingChartData ? (
          <div className="list">
            <div className="loading-container">
              <LoadingItem key="1" />
              <LoadingItem key="2" />
              <LoadingItem key="3" />
              <LoadingItem key="4" />
              <LoadingItem key="5" />
            </div>
          </div>
        ) : list.length ? (
          <div className="list">
            {list.map(item => {
              return (
                <div key={item._id} className="item">
                  <div className="time">{moment(item.day, DATE_FORMAT).format('MMM D')}</div>
                  <div className="value">{parseInt(item.value).toLocaleString()}&nbsp;{pluralize('step', parseInt(item.value))}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-data">No data available</div>
        )}
      </div>
    </TableContainer>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      clientStep: { chartData, loadingChartData },
    },
  } = state;

  return { chartData, loadingChartData };
};

export default connect(mapState)(WeeklyProgress);
