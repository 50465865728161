import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px
  color: #7B7E91;
`;

export const LabelSelect = styled.div`
  display: flex;
  align-items: center;
  .name {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #202353;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .label-custom {
    ${baseText}
    font-weight: 600;
    font-size: 11px;
    background: #f8f8f8;
    border-radius: 100px;
    width: 60px;
    height: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ isShowLabelCustom }) =>
    isShowLabelCustom &&
    css`
      .name {
        width: calc(100% - 70px);
      }
    `}
`;

export const Thumbnail = styled.div`
  width: 39px;
  height: 39px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  background-color: #fff;
  .image-with-fallback {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const IconSelectItem = styled.div`
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  background-color: #f8f8f8;
  margin-right: 10px;
  flex-shrink: 0;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Checkbox = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #dadbea;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background-color: #fff;
  svg {
    width: 8px;
    height: 8px;
  }
`;

export const DietaryInfoItemWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f0f1ff;
    ${LabelSelect} {
      .name {
        color: #5158cf;
      }
    }
    ${IconSelectItem} {
      svg path {
        fill: #5158cf;
      }
    }
  }
  ${props =>
    props.selected &&
    css`
      ${Checkbox} {
        background-color: #5158cf;
        border: 1px solid #5158cf;
      }
    `}
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 14px;
`;
