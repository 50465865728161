import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const textBase = `
    color: #202353;
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 150%;
`;

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.meal-plan-assign-noti {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 525px;
  }
  &.ui.modal.meal-plan-assign-noti > .content {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-radius: 8px;
  background-color: #fff;
  padding: 30px;
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  .assign-noti-header-icon {
    width: 24px;
    height: 24px;
    &.fill-green path {
      fill: rgb(101, 204, 156);
    }
    &.fill-yellow path {
      fill: rgb(255, 190, 73);
    }
  }
  span {
    ${textBase}
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const Body = styled.div``;

export const SuccessMessage = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  ${({ isShowError }) =>
    isShowError &&
    css`
      margin-bottom: 5px;
    `}
`;

export const ErrorMessage = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 30px;
  padding: 10px 30px;
  border-radius: 5px;
  background: rgb(101, 204, 156);
  ${textBase}
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: rgba(101, 204, 156, 0.9);
  }

  ${({ isFillYellow }) =>
    isFillYellow &&
    css`
      background: rgb(255, 190, 73);

      &:hover {
        background: rgba(255, 190, 73, 0.9);
      }
    `}
`;
