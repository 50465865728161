import { useEffect, useState } from 'react';

export function useCountdown() {
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (countdown > 0) {
      localStorage.setItem('has_cooldown', true);
      const interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
        localStorage.removeItem('has_cooldown', false);
      };
    }
  }, [countdown]);

  return { countdown, setCountdown };
}
