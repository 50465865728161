import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { convertFromWorkoutToAPIExercise } from 'helpers/workout';
import LogActivityModal from './component';
import { recordAssignment } from 'redux/assignment/assignment.actionCreators';
import { highFiveLogActivities } from 'actions/workout/getWorkouts';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => {
  const { user, isModalOpen, workouts, rootReducer, deletingExerciseSet } = state;
  const { workingClientDetail } = rootReducer.client;
  let trainer_unit = user.preferences;
  return {
    user,
    isModalOpen,
    workouts,
    selectedClient: rootReducer.client.selected,
    fields: rootReducer.exercise.fields,
    deletingExerciseSet,
    unit: workingClientDetail ? workingClientDetail.preferences : trainer_unit,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    recordAssignment: workout => {
      let params = {
        timezone: DateTime.local().zoneName,
        assignment: workout._id,
      };
      if (workout.start_time) {
        params.start_time = workout.start_time;
      }
      if (workout.start_time) {
        params.end_time = workout.end_time;
      }

      let exercises = convertFromWorkoutToAPIExercise(workout);
      params = Object.assign({}, params, { exercises });
      dispatch(recordAssignment(params));
    },
    highFiveLogActivities: bindActionCreators(highFiveLogActivities, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogActivityModal);
