import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon.svg';
import { ReactComponent as SubtractionIcon } from 'assets/icons/subtraction-icon.svg';
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import * as S from './style';

const WIDTH_INPUT = 66;
const InputRange = ({
  defaultValue = 0,
  handleZoomSetting,
  handleConfirm,
  handleZoomPhoto,
  onChangeRange,
  handleResetZoom,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [enabledReset, setEnabledReset] = useState(false);

  const tooltipRef = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
    handleShowPercentZoom(defaultValue);
  }, [defaultValue]);

  const onChangeInput = e => {
    setEnabledReset(true);
    const valueInput = Number(e.target.value);
    setValue(valueInput);
    onChangeRange(valueInput / 100 + 1);
    handleShowPercentZoom(valueInput);
  };

  const handleZoom = value => {
    setEnabledReset(true);
    setValue(prevState => {
      const result = prevState + Number(value);
      handleShowPercentZoom(result);
      handleZoomPhoto(result / 100 + 1);
      if (result >= 300) {
        return 300;
      } else if (result <= 0) {
        return 0;
      } else {
        return result;
      }
    });
  };

  const handleShowPercentZoom = data => {
    if (tooltipRef && tooltipRef.current) {
      const value = (data / 300) * WIDTH_INPUT - 16;
      tooltipRef.current.style.left = `${value}px`;
    }
  };
  const handleReset = () => {
    setEnabledReset(false);
    setValue(0);
    handleResetZoom();
  };

  return (
    <S.ZoomSetting onClick={handleZoomSetting}>
      <S.ResetButton disabled={!enabledReset && value <= 0} onClick={handleReset}>
        <ResetIcon />
      </S.ResetButton>
      <S.ZoomRange>
        <S.ZoomButton disabled={value <= 0} onClick={() => handleZoom(-25)}>
          <SubtractionIcon />
        </S.ZoomButton>
        <S.InputRangeWrapper>
          <S.InputRange onChange={onChangeInput} min={0} max={300} step={5} value={value} type="range" />
          <S.Tooltip ref={tooltipRef}>{100 + value}%</S.Tooltip>
        </S.InputRangeWrapper>
        <S.ZoomButton disabled={value >= 300} onClick={() => handleZoom(25)}>
          <PlusIcon />
        </S.ZoomButton>
      </S.ZoomRange>
      <S.ConfirmButton onClick={() => handleConfirm(value)}>Confirm</S.ConfirmButton>
    </S.ZoomSetting>
  );
};

export default InputRange;
