import React from 'react';
import { Modal, Input, Button, Form, Label, Image, Radio } from 'semantic-ui-react';
import _, { debounce, trim } from 'lodash';

import { toggleModal, toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import GeneralButton from 'shared/GeneralButton';
import { showSuccess } from 'redux/modal/modal.actionCreators';
import { validateEmail } from 'utils/validations';
import RemoveTeamMember from 'components/RemoveTeamMember';
import { ErrorMessage } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

import { RemoveMemberButton, ActionContainer, RevokeButton } from './style';
import './styles.scss';

export default class TeamInviteModal extends React.Component {
  state = {
    role: 2,
    firstNameError: '',
    lastNameError: '',
    emailError: '',
  };

  componentDidMount() {
    if (this.props.teammateData) {
      this.setState({
        inviteId: this.props.teammateData.inviteId,
        userId: this.props.teammateData.userId,
        email: this.props.teammateData.email,
        first_name: this.props.teammateData.first_name,
        last_name: this.props.teammateData.last_name,
        role: this.props.teammateData.role,
      });
    }
  }

  render() {
    return (
      <div>
        <Modal
          size={'tiny'}
          open={this.props.isModalOpen}
          onClose={() => this.props.dispatch(toggleModal(false))}
          className="client-invite-modals"
          onMount={this.startJoyride}
          closeOnDimmerClick={false}
        >
          <Modal.Header className="invite-header">
            {this.props.teammateData ? 'Edit team member' : 'Invite Member to Everfit'}
            <Button
              onClick={() => {
                this.props.dispatch(toggleModal(false));
              }}
              className="close-button"
            >
              <Image src={`${CDN_URL}/images/close_circle.svg`} />
            </Button>
          </Modal.Header>
          <Modal.Content>
            <Form>{this.renderInputs()}</Form>
          </Modal.Content>
          <Modal.Actions>{this.renderActionButtons()}</Modal.Actions>
        </Modal>
      </div>
    );
  }

  onChangeFormInviteTeammate = event => {
    const { name, value } = event.target;
    let nameError = '';
    switch (name) {
      case 'first_name':
        nameError = 'firstName';
        break;
      case 'last_name':
        nameError = 'lastName';
        break;
      case 'password':
        nameError = 'email';
        break;
      default:
        return;
    }
    let fieldName = name === 'password' ? 'email' : name;
    this.setState({
      [fieldName]: value,
      [`${nameError}Error`]: '',
    });
  };

  validateForm = () => {
    const { first_name, last_name, email } = this.state;
    let firstNameError = '';
    let lastNameError = '';
    let emailError = '';

    if (!first_name) {
      firstNameError = 'Please enter first name';
    }

    if (!last_name) {
      lastNameError = 'Please enter last name';
    }

    if (!email) {
      emailError = 'Please include an email we can send the invite to';
    } else if (!validateEmail(email)) {
      emailError = 'Please enter a valid email';
    }

    this.setState({
      firstNameError,
      lastNameError,
      emailError,
    });

    return !firstNameError && !lastNameError && !emailError;
  };

  submitForm = () => {
    if (!this.validateForm()) {
      return;
    }

    if (this.props.teammateData) {
      this.props
        .editTeamMember({
          inviteId: this.state.inviteId,
          userId: this.state.userId,
          role: this.state.role,
        })
        .then(result => {
          if (result && result.data && result.data.data) {
            this.props.viewListTeamate();
            this.props.dispatch(toggleModal(false));
          }
        });
    } else {
      this.props
        .inviteNewTeamate({
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          role: this.state.role,
          teamId: this.props.user.teams && this.props.user.teams.length > 0 ? this.props.user.teams[0].team._id : null,
        })
        .then(result => {
          if (result && result.data && result.data.data) {
            this.props.viewListTeamate();
            this.props.dispatch(toggleModal(false));
            this.props.dispatch(showSuccess('You have succesfully invited a new teammate!', null, null, 'OK'));
          }
        });
    }
  };

  onChooseRole = (e, { value }) => {
    this.setState(() => ({
      role: value,
    }));
  };

  onClickRemoveMember = () => {
    const { teammateData } = this.props;

    this.props.dispatch(
      toggleModal(
        true,
        <RemoveTeamMember
          removedMember={teammateData}
          onClose={() => this.props.dispatch(toggleModal(false))}
          onSuccess={this.props.viewListTeamate}
        />,
      ),
    );
  };

  onRevoke = () => {
    const { teammateData } = this.props;

    this.props.dispatch(
      toggleConfirmModal(
        true,
        <ConfirmModal
          title={'Revoke'}
          content={`Are you sure that you want to revoke this invitation?`}
          onConfirm={() =>
            this.props.resendInvokeInvitation(false, teammateData._id).then(data => {
              if (_.get(data, 'data.data')) {
                this.props.viewListTeamate();
                this.props.dispatch(toggleModal(false));
                this.props.dispatch(showSuccess('You have succesfully revoke this invitation!', null, null, 'OK'));
              }
            })
          }
        />,
      ),
    );
  };

  renderActionButtons() {
    const { user, teammateData } = this.props;
    const showRevokeButton = teammateData && !!teammateData.inviteId;
    const showRemoveButton =
      teammateData && !showRevokeButton && teammateData._id !== user._id && teammateData.role !== 0;

    return (
      <ActionContainer>
        {showRevokeButton ? (
          <RevokeButton onClick={this.onRevoke}>Revoke</RevokeButton>
        ) : showRemoveButton ? (
          <RemoveMemberButton onClick={this.onClickRemoveMember}>Remove team member</RemoveMemberButton>
        ) : (
          <div />
        )}
        <GeneralButton className="setting-save-button" onClick={this.submitForm}>
          {this.props.teammateData ? 'Save' : 'Invite'}
        </GeneralButton>
      </ActionContainer>
    );
  }

  renderInputs() {
    const { teammateData } = this.props;
    const { firstNameError, lastNameError, emailError } = this.state;
    const isOwner = teammateData && teammateData.role === 0;
    return (
      <div className="invite-form-container">
        <Label className="form-label">FIRST NAME</Label>
        <Input
          onChange={this.onChangeFormInviteTeammate}
          name="first_name"
          autoFocus
          value={this.state.first_name}
          disabled={this.props.teammateData}
          placeholder={'First name'}
          style={{ width: '100%' }}
          className={`${firstNameError && 'input-invite-errors'}`}
          maxLength={30}
        />
        {firstNameError ? (
          <ErrorMessage className="task--error-message inivte--noActions">{firstNameError}</ErrorMessage>
        ) : null}
        <Label className="form-label">LAST NAME</Label>
        <Input
          onChange={this.onChangeFormInviteTeammate}
          name="last_name"
          value={this.state.last_name}
          disabled={this.props.teammateData}
          placeholder={'Last name'}
          style={{ width: '100%' }}
          className={`${lastNameError && 'input-invite-errors'}`}
          maxLength={30}
        />
        {lastNameError ? (
          <ErrorMessage className="task--error-message inivte--noActions">{lastNameError}</ErrorMessage>
        ) : null}
        <Label className="form-label">EMAIL</Label>
        <Input
          onChange={this.onChangeFormInviteTeammate}
          name="password"
          value={this.state.email}
          disabled={this.props.teammateData}
          placeholder={'Email'}
          style={{ width: '100%' }}
          className={`${emailError && 'input-invite-errors'}`}
        />
        {emailError ? (
          <ErrorMessage className="task--error-message inivte--noActions">{emailError}</ErrorMessage>
        ) : null}
        <Label className="form-label">ROLE</Label>
        <div className="radios">
          <Radio
            label="Admin"
            name="role"
            value={2}
            checked={this.state.role === 2 || this.state.role === 0}
            onChange={this.onChooseRole}
            disabled={isOwner}
          />
          <Radio
            label="Trainer"
            name="role"
            value={1}
            style={{ paddingLeft: 30 }}
            checked={this.state.role === 1}
            onChange={this.onChooseRole}
            disabled={isOwner}
          />
        </div>
        <div
          className="see-permission"
          onClick={() => {
            this.setState({ isViewPermission: !this.state.isViewPermission });
          }}
        >
          See Permissions
        </div>
        <Label className="form-label"></Label>
        {this.state.isViewPermission && (
          <React.Fragment>
            <div className="invite-form-af-title">App Features</div>
            {this.state.role === 1 ? (
              <ul className="invite-form-af-detail">
                <li>Can view and manage own clients</li>
                <li>Can view/edit own library assets (exercises, workouts, programs)</li>
                <li>Can view shared library assets</li>
              </ul>
            ) : (
              <ul className="invite-form-af-detail">
                <li>Can view and manage clients of all team members</li>
                <li>Can view/edit own and shared library assets (exercises, workouts, programs)</li>
                <li>Can manage all settings, including team members and billing</li>
              </ul>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  toggleSendMail = () => {
    this.setState(() => ({
      send_email: !this.state.send_email,
    }));
  };

  onInputChange(field, event) {
    const partialState = {};
    partialState[field] = event.target.value;
    this.setState(partialState);
  }

  debounceTrimEmail = debounce(trimmedMail => this.setState({ email: trimmedMail }), 1000);

  componentDidUpdate() {
    if (this.state.email) {
      if (trim(this.state.email) !== this.state.email) {
        this.debounceTrimEmail(trim(this.state.email));
      }
    }
  }
}
