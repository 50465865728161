import React from 'react';

import * as S from '../style';

const DatetimeForm = ({ defaultDateFormat }) => {
  const arrDatetime = defaultDateFormat.split('/');

  return (
    <S.FormType>
      <S.TypeDatetime>
        {arrDatetime.map((content, index) => (
          <React.Fragment key={content}>
            <S.DatetimeWrapper>
              <S.DateContent>{content}</S.DateContent>
            </S.DatetimeWrapper>
            {index < 2 && (
              <S.DateSeparator>
                <S.DateContent>/</S.DateContent>
              </S.DateSeparator>
            )}
          </React.Fragment>
        ))}
      </S.TypeDatetime>
    </S.FormType>
  );
};

export default DatetimeForm;
