import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import SelectProgram from './component';
import { assignProgram } from 'redux/program_library/program_library.actionCreators';

const mapStateToProps = state => {
  const { rootReducer, cloudfrontList } = state;
  const { all_items } = rootReducer.program_library;
  return {
    all_items,
    cloudfrontList,
    previewItem: rootReducer.program_library.preview_selected_item,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    assignProgram: (date, programId, startingAt, endingAt) => {
      let currentDate = DateTime.local();
      const newDate = date.set({
        hour: currentDate.get('hour'),
        minute: currentDate.get('minute'),
        second: currentDate.get('second'),
      });

      const params = {
        date: newDate.toString(),
        pid: programId,
        starting_at: startingAt + 1,
        ending_at: endingAt + 1,
      };

      return dispatch(assignProgram(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProgram);
