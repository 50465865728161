// Libs
import get from 'lodash/get';

// Constants
import { ASSET_TYPES, ON_DEMAND_ASSET_KEY } from './constants';

export const getTitleResourcesAsset = type => {
  switch (type) {
    case ASSET_TYPES.RESOURCE_COLLECTIONS:
      return 'Resource Collections';
    case ASSET_TYPES.WORKOUT_COLLECTIONS:
      return 'Workout Collections';
    case ASSET_TYPES.STUDIO_PROGRAMS:
      return 'Studio Programs';

    default:
      return '';
  }
};

export const getTotalContentResourcesAsset = type => {
  switch (type) {
    case ASSET_TYPES.RESOURCE_COLLECTIONS:
      return 'All Resource Collections';
    case ASSET_TYPES.WORKOUT_COLLECTIONS:
      return 'All Workout Collections';
    case ASSET_TYPES.STUDIO_PROGRAMS:
      return 'All Programs';

    default:
      return '';
  }
};

export const getField = (obj, field) => get(obj, field, false);

export const isHasAtLeastAssetValid = (collection, type) => {
  const checkDeleteValid = (collection || []).some(
    item => !getField(item[`${ON_DEMAND_ASSET_KEY[type]}_data`], 'is_deleted'),
  );
  const checkStatusValid = (collection || []).some(item => {
    const data = item[`${ON_DEMAND_ASSET_KEY[type]}_data`];
    if ([ASSET_TYPES.WORKOUT_COLLECTIONS].includes(type)) {
      return typeof data.status === undefined ? getField(data, 'isPublished') : getField(data, 'status') === 'publish';
    }
    return getField(data, 'isPublished');
  });

  if ([ASSET_TYPES.WORKOUT_COLLECTIONS, ASSET_TYPES.STUDIO_PROGRAMS].includes(type)) {
    return checkDeleteValid && checkStatusValid;
  }

  return checkDeleteValid;
};
