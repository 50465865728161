import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import TextEditableEl from 'shared/TextEditable';
import ConfirmModalEl from 'shared/ConfirmModal';

export const SurveyDetailModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 760px;
    height: fit-content;
    max-height: 805px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    @media screen and (max-height: 756px) {
      max-height: calc(100% - 48px);
    }
  }

  .header {
    border-radius: 8px;
    padding: 22px 22px 20px 22px !important;
  }

  .content {
    padding: 16px 18px 23px 30px !important;
    max-width: 753px;
    height: 640px;
    margin-right: 8px;
    overflow: scroll;

    ::-webkit-scrollbar {
      width: 5px !important;
    }
  }

  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 20px 30px;
    box-shadow: 0px -4px 16px 0px #6b6fb71a;
    background-color: #fff;
    border: none;

    .actions__cancel {
      min-width: 81px;
      max-width: 92px;
      height: 40px;
      padding: 11px 25px;
    }

    .actions__save {
      min-width: 100px;
      max-width: 157px;
      height: 40px;
      margin-left: 8px;
      padding: 11px 35px;

      &:hover {
        background: rgba(81, 88, 207, 0.9);
      }

      &:disabled:hover {
        background-color: #c4c4c4;
      }
    }
  }
  .__react_component_tooltip.name-tooltip {
    max-width: 280px;
    background: #141414;
    border-radius: 8px;
    padding: 16px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
  }
`;

export const CountCharacter = styled.span`
  display: none;
  text-align: right;
  color: #979797;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  span {
    color: ${props => (props.isError ? '#ea314a' : '#979797')};
  }
`;

export const TextEditable = styled(TextEditableEl)`
  .text-editable__container {
    padding: 0;
    border: none;
    width: 100%;
    min-width: 559px;
    max-width: 736px;

    .text-editable__input {
      width: 100%;
      font-size: 22px;
      line-height: 30px;
      word-break: break-word;

      ::before {
        color: #aaacae;
        opacity: 1;
      }
    }
  }
`;

export const NameField = styled.div`
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${props =>
    props.isError &&
    css`
      border-color: #ea314a;
      background-color: #fef4f6;
    `}

  &:hover {
    cursor: text;
    background: ${props => (props.isError ? '#fef4f6' : '#f8f8f8')};
    .text-editable__container:not(.text-editable__container--active):not(.text-editable__container--invalid) {
      background: ${props => (props.isError ? '#fef4f6' : '#f8f8f8')};
    }
  }

  &:focus,
  &:focus-within {
    cursor: text;
    background: #fff;
    border-color: #5158cf;

    ${CountCharacter} {
      display: block;
    }
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const Counter = styled.div`
  min-width: 26px;
  height: 27px;
  font-size: 20px;
  line-height: 27px;
  color: #a1a1a1;
`;

export const GripIconWrapper = styled.div`
  min-width: 26px;
  height: 15px;
  cursor: grab;

  &:hover {
    svg rect {
      fill: #5158cf;
    }
  }
`;

export const TrashIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  height: 19px;
  cursor: pointer;

  &:hover {
    svg path {
      fill: #ea314a;
    }
  }
`;

export const ItemWrapper = styled.div`
  &:hover {
    .text-editable__container {
      border-color: #5158cf;
    }
  }
`;

export const ItemEditableWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const QuestionField = styled(NameField)`
  padding: 20px;
  background: #fff;
  width: 100%;
  border: 1px solid #e4e8f3;
  position: relative;
  border-radius: 8px;

  &:hover {
    background: #fff;
    border-color: ${props => (props.isError && !props.isActive ? '#ea314a' : '#5158cf')};
  }

  ${props =>
    props.isError &&
    css`
      border-color: #ea314a;
    `}

  &:hover .text-editable__container:not(.text-editable__container--active):not(.text-editable__container--invalid) {
    background: #fff;
  }
`;

export const QuestionEditable = styled(TextEditable)`
  .text-editable__container {
    padding: 0px;
    border-radius: 8px;
    .text-editable__input {
      font-size: 15px;
      line-height: 20px;
      width: calc(100% - 30px);

      ::before {
        padding-left: 4px;
        color: #9a9a9a;
        opacity: 1;
      }
    }
  }
`;

export const IntroField = styled.div`
  width: calc(100% + 8px);
  display: flex;
  flex-direction: column;

  border: 1px solid transparent;
  border-radius: 8px;
  padding: 12px;
  gap: 2px;
  margin-left: -4px;

  ${props =>
    props.isError &&
    css`
      border-color: #ea314a;
      background-color: #fef4f6;
    `}

  &:hover {
    cursor: text;
    background: ${props => (props.isError ? '#fef4f6' : '#f8f8f8')};
    .text-editable__container:not(.text-editable__container--active):not(.text-editable__container--invalid) {
      background: ${props => (props.isError ? '#fef4f6' : '#f8f8f8')};
    }
  }

  &:focus,
  &:focus-within {
    cursor: text;
    background: #fff;
    border-color: #5158cf;

    ${CountCharacter} {
      display: block;
    }
  }
`;

export const IntroductionEditable = styled(TextEditable)`
  .text-editable__container {
    padding: 0px;
    border-radius: 8px;
    .text-editable__input {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #202353;

      ::before {
        padding-left: 4px;
        color: #9a9a9a;
        opacity: 1;
      }
    }
  }
`;

export const QuestionMessage = styled.p`
  ${props => ({
    fontSize: '13px',
    fontWeight: 400,
    color: '#ea314a',
    marginTop: '8px',
    marginLeft: props.marginLeft || '34px',
  })}
`;

export const AddMoreQuestion = styled.button`
  width: 100%;
  height: 62px;
  border: 1px solid #e4e8f3;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #9a9a9a;
  padding: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  max-width: 666px;

  &:hover {
    border-color: #5158cf;
    color: #5158cf;

    svg path {
      fill: #5158cf;
    }
  }

  &:focus-visible {
    outline: none;
  }
`;

export const ConfirmModal = styled(ConfirmModalEl)`
  &.ui.modal.confirm-popup-container.new-style {
    width: 467px !important;

    .confirm-content-header {
      padding: 32px 32px 16px 32px !important;

      .confirm-header-label {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .confirm-content-body {
      padding: 0 32px !important;

      .confirm-content-label {
        line-height: 20px;
        color: #4a4d56;
      }
    }

    .confirm-actions {
      padding: 32px !important;

      .ui.button {
        max-height: 30px;
        padding: 5px 16px;
        border-radius: 5px;

        &.confirm-no-button {
          min-width: 74px;

          &:hover {
            background: #f5f7f9 !important;
          }
        }

        &.confirm-yes-button {
          width: fit-content;
          background-color: #f9b811;
          box-shadow: none;
          letter-spacing: 0.37px;

          &:hover {
            background: rgba(249, 184, 17, 0.9);
          }
        }
      }
    }

    &.error {
      .confirm-content-header .confirm-header-label {
        svg rect {
          fill: rgba(234, 49, 74, 1);
        }
      }
      .confirm-actions .ui.button {
        &.confirm-yes-button {
          background-color: rgba(234, 49, 74, 1);

          &:hover {
            background: rgba(234, 49, 74, 0.9);
          }
        }
      }
    }
  }
`;
