import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import pick from 'lodash/pick';
import classNames from 'classnames';

import { ReactComponent as SingleChoiceIcon } from 'assets/icons/single_choice.svg';

import DefaultDropdownIndicator from './DefaultDropdownIndicator';
import { handleTriggerScroll } from 'components/OnboardingFlowDetail/constants/helper';

import * as S from './style';

const SingleChoiceAutomationSelect = props => {
  const { options = [], usePortal = false, selectedQuestion, onChange } = props;
  const { is_deleted } = selectedQuestion || {};
  const isQuestionUnavailable = is_deleted;

  const questionOptions = options.map(it => pick(it, ['title', 'question_original_id', '_id', 'index']));

  const selectQuestionRef = useRef();
  const selectRef = useRef();
  const [menuQuestionPlacement, setMenuQuestionPlacement] = useState(props.menuPlacement || 'auto');

  const menuQuestionPortalTarget = useMemo(() => {
    if (usePortal) return document.body;
    return false;
  }, [usePortal]);

  useEffect(() => {
    handleTriggerScroll('.trigger-scroll-wrapper', handleWindowScroll);
  }, []);

  const checkPlacementQuestion = useCallback(() => {
    if (selectQuestionRef.current && typeof selectQuestionRef.current.getBoundingClientRect === 'function') {
      const rect = selectQuestionRef.current.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= 100) {
        setMenuQuestionPlacement('top');
      } else {
        const propsMenuPlace = props.menuPlacement || 'auto';
        menuQuestionPlacement !== propsMenuPlace && setMenuQuestionPlacement(propsMenuPlace);
      }
    }
  }, [menuQuestionPlacement, selectQuestionRef]);

  const handleWindowScroll = () => {
    if (selectRef.current) {
      selectRef.current.select.blur();
    }
  };

  return (
    <S.SingleChoiceAutomationSelectWrapper
      ref={selectQuestionRef}
      className={classNames({ 'is-question-unavailable': isQuestionUnavailable })}
    >
      <div className="label-question">Choose question from Onboarding Form</div>
      <Select
        ref={selectRef}
        blurInputOnSelect
        isSearchable={false}
        isMulti={false}
        name="single-choice-automation"
        classNamePrefix="single-choice-automation"
        className="select-single-choice-automation"
        defaultValue={selectedQuestion}
        options={questionOptions}
        getOptionLabel={option => option.title}
        getOptionValue={option => option._id}
        menuPlacement={menuQuestionPlacement}
        menuPortalTarget={menuQuestionPortalTarget}
        onMenuOpen={checkPlacementQuestion}
        onChange={onChange}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: DefaultDropdownIndicator,
          SingleValue: CustomSingleValue,
          Option: CustomAssetOption,
        }}
        isQuestionUnavailable={isQuestionUnavailable}
        styles={S.customSelectQuestionStyles}
      />
    </S.SingleChoiceAutomationSelectWrapper>
  );
};

export default SingleChoiceAutomationSelect;

const CustomSingleValue = props => {
  const { data, selectProps } = props;
  const { isQuestionUnavailable } = selectProps;

  const numberIndex = (data.index || 0) + 1;

  return (
    <components.SingleValue {...props}>
      <S.QuestionTypeWrapper>
        <SingleChoiceIcon />
        <div>{numberIndex}</div>
      </S.QuestionTypeWrapper>
      <div className="question-label">
        {!!isQuestionUnavailable && <div className="deleted-label">Deleted</div>}
        {props.children}
      </div>
    </components.SingleValue>
  );
};

const CustomAssetOption = props => {
  const { data } = props;
  const numberIndex = (data.index || 0) + 1;

  return (
    <components.Option {...props}>
      <S.QuestionTypeWrapper>
        <SingleChoiceIcon />
        <div>{numberIndex}</div>
      </S.QuestionTypeWrapper>
      <div className="question-label">{props.children}</div>
    </components.Option>
  );
};
