import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { timed, strength, distance, bodyweight } from 'constants/images';

const icons = {
  timed,
  strength,
  distance,
  bodyweight,
};

export default ({ innerProps, data, isFocused, isSelected }) => {
  return (
    <Wrapper
      {...innerProps}
      className={classnames('category_option', {
        'category_option--is-focused': isFocused,
        'category_option--is-selected': isSelected,
      })}
    >
      <Icon>{data.unique_code === 'distance_short' ? icons.distance : icons[data.unique_code]}</Icon>
      <div>
        <Title>{data.label}</Title>
        <Description>{data.description}</Description>
      </div>
    </Wrapper>
  );
};

const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  margin-bottom: 4px;
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #202353;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #dadfea;
  cursor: pointer;

  :hover,
  &.category_option--is-focused,
  &.category_option--is-selected {
    background-color: #f4f4ff;

    path {
      stroke: #7072dd;
    }
  }

  :last-child {
    box-shadow: none;
  }
`;
