import React from 'react';
import moment from 'moment';

// constants
import { BACKGROUND_DEFAULT, BACKGROUND_LIST } from '../../constants';

import DropdownCountdownItem from 'components/Note/Countdown/components/DropdownCountdownItem';

import ConfettiImg from 'assets/images/confett.png';
import { ReactComponent as PinnedIcon } from 'assets/icons/pinned.svg';
import { GoalCountdownItemStyled } from './styles.js';
import useDaysRemaining from 'hooks/useDaysRemaining.js';

const CountdownItem = ({ countdown, onNavigateDetails, onChangeFormCreate }) => {
  const timeRemaining = useDaysRemaining(countdown.date_utc);

  const handleSelectViewDetail = () => {
    onNavigateDetails({ ...countdown, timeRemaining });
  };

  return (
    <GoalCountdownItemStyled className="countdown-item">
      <div
        className="countdown-top"
        style={{
          background: BACKGROUND_LIST.find(({ background }) => (countdown || {}).color === background)
            ? (countdown || {}).color
            : BACKGROUND_DEFAULT.background,
        }}
        onClick={handleSelectViewDetail}
      >
        <div className="titles">
          <h3>{countdown.title}</h3>
          {!timeRemaining && <img className="confetti" src={ConfettiImg} alt="confetti" />}
          {countdown.isPinned && <PinnedIcon className="pinned-ic" />}
        </div>
        <div className="countdown-right-wrapper">
          <div className="countdown-right">
            <span className="completed" role="img" aria-label="completed">
              {timeRemaining ? countdown.icon : '🎉'}
            </span>
            {timeRemaining || 'Completed'}
          </div>
        </div>
      </div>
      <div className="countdown-bottom">
        <p>
          Countdown to <span>{moment(countdown.date_utc).format('MMM D, YYYY')}</span>
        </p>
        {timeRemaining && (
          <DropdownCountdownItem
            countdown={countdown}
            className="dropdown-actions"
            onChangeFormCreate={onChangeFormCreate}
          />
        )}
      </div>
    </GoalCountdownItemStyled>
  );
};

export default CountdownItem;
