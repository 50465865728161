import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { changeCalendarStartDate } from 'actions/calendar';
import ClientViewprograms from './component';
import { removeAssignedProgram } from 'redux/client/client.actionCreators';

const mapStateToProps = state => ({
  isModalOpen: state.isModalOpen,
  programs: state.rootReducer.client.assignedPrograms,
  date_format: state.user.date_format,
});

const mapDispatchToprops = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeAssignedProgram: bindActionCreators(removeAssignedProgram, dispatch),
  changeCalendarStartDate: bindActionCreators(changeCalendarStartDate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToprops)(ClientViewprograms);
