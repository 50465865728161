import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toggleSideNav } from 'actions/sidenav';
import { toggleSideBar } from 'actions/sidebar';
import {
  programLibraryChangeCalendarType,
  arrangeExerciseSetOnProgramLibraryWorkout,
  moveExerciseSetOnProgramLibraryWorkout,
  getClientsAssignedToProgram,
  programToggleLiveSync,
  fetchJobAssignProgram,
  getProgramLibraryDetail,
  addWeekAtIndex,
  removeWeekById,
  calendarUnmount,
  moveWorkoutProgram,
  goToWeek,
  arrangeProgramLibraryWorkout,
  resetCopyItem,
  getProgramLibraryDetailByWeeks,
  handleSelectedWeek,
  handlePasteWeek,
  handleResetSelectedWeek,
  handleResetSelectWorkout,
  handleCopyWorkouts,
  handleRemoveWorkoutWeek,
  handleRemoveWorkouts,
} from 'redux/program_library/program_library.actionCreators';
import ProgramListCalendar from './component';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = state => {
  const {
    user,
    sideNavVisible,
    isModalOpen,
    modal,
    isConfirmModalOpen,
    confirmModal,
    errorPopup,
    isShowingError,
    workouts,
    rootReducer,
    calendarStartDate,
  } = state;

  const { program_library, permission } = rootReducer;

  return {
    permission,
    calendarStartDate,
    user,
    sideNavVisible,
    isModalOpen,
    modal,
    isConfirmModalOpen,
    confirmModal,
    errorPopup,
    isShowingError,
    workouts,
    isShowingSuccess: rootReducer.modal.isShowingSuccess,
    successPopup: rootReducer.modal.successPopup,
    currentWeek: program_library.selected_current_week,
    totalWeek: program_library.selected_total_week,
    selectedProgram: program_library.selected,
    calendarType: program_library.calendar_type,
    assigned_clients: program_library.assigned_clients,
    copyingWorkout: program_library.copying_workout,
    selectedWeek: program_library.selectedWeek,
    selectedWorkout: program_library.selectedWorkout,
    isCopy: program_library.isCopy,
    workoutSets: get(program_library, 'selected.workout_sets'),
    isAddingWeek: program_library.isAddingWeek,
  };
};

const toggleSideNavigation = (dispatch, visible) => {
  dispatch(toggleSideNav(visible));
};

const handleDragEndWorkout = (dispatch, result, programId) => {
  const { source, destination } = result;
  const newIndex = destination.index;
  const fromIndex = source.index;
  const [oldWeekIndex, oldDayIndex] = source.droppableId.split('_').splice(1, 2);
  const [newWeekIndex, newDayIndex] = destination.droppableId.split('_').splice(1, 2);
  const workoutId = result.draggableId.split('_')[1];

  if (oldWeekIndex === newWeekIndex && oldDayIndex === newDayIndex && fromIndex === newIndex) {
    return;
  }

  if (source.droppableId === destination.droppableId) {
    const params = {
      fromIndex,
      newIndex,
      weekIndex: newWeekIndex,
      dayIndex: newDayIndex,
      workoutId,
      programId,
    };
    dispatch(arrangeProgramLibraryWorkout(params));
  } else {
    const params = {
      fromIndex,
      newIndex,
      oldWeekIndex,
      oldDayIndex,
      newWeekIndex,
      newDayIndex,
      workoutId,
      programId,
    };
    dispatch(moveWorkoutProgram(params));
  }
};

const onDragEnd = (dispatch, result, programId) => {
  if (!result.source || !result.destination) {
    return;
  }

  if (result.type === 'workout') {
    handleDragEndWorkout(dispatch, result, programId);
    return;
  }

  const source = result.source;
  const destination = result.destination;

  const [to, toAssignment, toWeek] = destination.droppableId.split(';');
  const [from, fromAssignment, fromWeek] = source.droppableId.split(';');
  const sectionId = result.draggableId.split(';')[0];
  //Dragged superset
  if (destination && source.droppableId !== destination.droppableId) {
    //Move from one workout to another
    if (!sectionId) {
      console.error('Something wrong! Should add error handling here');
      return;
    }

    const newIndex = parseInt(destination.index);
    const oldIndex = parseInt(source.index);
    dispatch(
      moveExerciseSetOnProgramLibraryWorkout({
        sectionId,
        newIndex,
        oldIndex,
        workoutId: fromAssignment,
        workoutDestinationId: toAssignment,
        dayIndexDestination: to,
        dayIndex: from,
        weekIndex: toWeek,
        programId,
        oldWeekIndex: fromWeek,
      }),
    );
  } else {
    //Move inside one workout
    if (!sectionId || !fromAssignment) {
      console.error('Something wrong for rearrange! Should add error handling here');
      return;
    }

    const newIndex = parseInt(destination.index);
    const oldIndex = parseInt(source.index);

    dispatch(
      arrangeExerciseSetOnProgramLibraryWorkout({
        oldIndex,
        sectionId,
        newIndex,
        workoutId: fromAssignment,
        workoutDestinationId: toAssignment,
        dayIndexDestination: to,
        dayIndex: from,
        weekIndex: toWeek,
        programId,
        oldWeekIndex: fromWeek,
      }),
    );
  }
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    changeCalendarType: type => {
      dispatch(programLibraryChangeCalendarType(type));
    },
    toggleSideNavigation: visible => toggleSideNavigation(dispatch, visible),
    onDragEnd: (result, client, workouts) => onDragEnd(dispatch, result, client, workouts),
    toggleSideBar: visible => dispatch(toggleSideBar(visible)),
    getClientsAssignedToProgram: bindActionCreators(getClientsAssignedToProgram, dispatch),
    programToggleLiveSync: bindActionCreators(programToggleLiveSync, dispatch),
    fetchJobAssignProgram: bindActionCreators(fetchJobAssignProgram, dispatch),
    getProgramLibraryDetail: bindActionCreators(getProgramLibraryDetail, dispatch),
    addWeekAtIndex: bindActionCreators(addWeekAtIndex, dispatch),
    removeWeekById: bindActionCreators(removeWeekById, dispatch),
    push: bindActionCreators(push, dispatch),
    calendarUnmount: bindActionCreators(calendarUnmount, dispatch),
    goToWeek: bindActionCreators(goToWeek, dispatch),
    resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
    getProgramLibraryDetailByWeeks: bindActionCreators(getProgramLibraryDetailByWeeks, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    handleSelectedWeek: bindActionCreators(handleSelectedWeek, dispatch),
    handlePasteWeek: bindActionCreators(handlePasteWeek, dispatch),
    handleResetSelectedWeek: bindActionCreators(handleResetSelectedWeek, dispatch),
    handleResetSelectWorkout: bindActionCreators(handleResetSelectWorkout, dispatch),
    handleCopyWorkouts: bindActionCreators(handleCopyWorkouts, dispatch),
    handleRemoveWorkoutWeek: bindActionCreators(handleRemoveWorkoutWeek, dispatch),
    handleRemoveWorkouts: bindActionCreators(handleRemoveWorkouts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramListCalendar);
