// Libs
import React from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import classNames from 'classnames';

// Parts
import { CONTENT_DUPLICATE_ACTION_TYPES } from 'shared/TriggerForms/constants/types';

// Styles
import * as S from './styles';

const RequestDuplicateFormModal = props => {
  const { type, closeModal, cancelModal, submitAction, owner = {} } = props;

  if (!Object.keys(CONTENT_DUPLICATE_ACTION_TYPES).includes(type)) return null;

  const {
    icon,
    title,
    description,
    keyReplaceCoachName,
    cancelLabelButton,
    submitLabelButton,
    submitButtonTheme,
  } = CONTENT_DUPLICATE_ACTION_TYPES[type];

  const fullName =
    ((owner || {}).full_name || '').trim() ||
    `${(owner || {}).first_name || ''} ${(owner || {}).last_name || ''}`.trim();

  const handleCloseModal = () => {
    typeof closeModal === 'function' && closeModal();
  };

  const handleCancelModal = () => {
    typeof cancelModal === 'function' && cancelModal();
  };

  const handleSubmitModal = () => {
    typeof submitAction === 'function' && submitAction();
  };

  return (
    <S.RequestDuplicateFormModalWrapper
      open={true}
      closeIcon={
        <Button className="close-button">
          <Image src="/images/close_circle.svg" />
        </Button>
      }
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {icon && <img src={icon} alt="" className="icon-header" />}
        <div className="title-modal">{title}</div>
      </Modal.Header>
      <Modal.Description>
        <div
          className="description-modal"
          dangerouslySetInnerHTML={{
            __html: description.replace(keyReplaceCoachName, fullName),
          }}
        />
      </Modal.Description>
      <Modal.Actions>
        <button type="reset" className="cancel-button" onClick={handleCancelModal}>
          {cancelLabelButton}
        </button>
        <button type="submit" className={classNames('submit-button', submitButtonTheme)} onClick={handleSubmitModal}>
          {submitLabelButton}
        </button>
      </Modal.Actions>
    </S.RequestDuplicateFormModalWrapper>
  );
};

export default RequestDuplicateFormModal;
