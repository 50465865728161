import { DateTime } from 'luxon';
import get from 'lodash/get';
import { convertUnit, roundNumber } from 'utils/commonFunction';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';

export const formatDate = initialDate => {
  if (!initialDate) return '';
  const initialFormat = 'MM-dd-yyyy';
  const dateObj = DateTime.fromFormat(initialDate, initialFormat);
  let formatDate = 'MMM dd, yyyy';
  if (dateObj && dateObj.year === DateTime.now().year) {
    formatDate = 'MMM dd';
  }
  return DateTime.fromFormat(initialDate, initialFormat).toFormat(formatDate);
};

export const handleConvertUnit = (value = '', unit = {}, bodyMetricTypes = [], unitTitle = 'weight') => {
  const shouldConvert = bodyMetricTypes.find(it => get(it, 'unique_code', '') === unitTitle);
  return value && shouldConvert ? roundNumber(convertUnit(value, unit, get(shouldConvert, 'unit', {}))) : value;
};

export const handleScrollToPhoto = (day, photo, time = 300) => {
  setTimeout(() => {
    const eleListPhoto = document.getElementById(`photo-by-date-${day}`);
    const elePhoto = document.getElementById(photo);
    if (eleListPhoto && elePhoto) {
      const xPhoto = elePhoto.offsetLeft;
      eleListPhoto.scrollTo({
        left: xPhoto - 40,
      });
    }
  }, time);
};

export const getThumbnailProgressPhoto = originalThumbnail => {
  return process.env.REACT_APP_HIDE_PROGRESS_PHOTO ? [MEDIA_PLACEHOLDER] : originalThumbnail;
};
