import styled, { css } from 'styled-components';
import { ActionPasteWeek } from './components/style';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export const NavigatorBarRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const NavigatorBarContainer = styled.div`
  padding: 7px 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dde0e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CalendarWeekWrapper = styled.div`
  display: flex;
`;

export const WeekIndicatorContainer = styled.div`
  position: absolute;
  left: 7px;
  top: 2px;
  z-index: 1;

  .week__title {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #000000;
    opacity: 0.2;
    text-transform: uppercase;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    cursor: default;
  }
`;

export const WrapperAddWeekIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  left: 50%;
  transform: translateX(-50%);
  top: -11px;
  background: #5c5bbd url(${CDN_URL}/images/plus_white.svg) no-repeat center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: 3px;
  z-index: 1;

  ${props =>
    props.loadingPaste &&
    css`
      :hover {
        cursor: not-allowed;
      }

      ${AddWeekIcon} {
        pointer-events: none;
      }
    `}
`;

export const AddWeekIcon = styled.div`
  width: 100%;
  height: 100%;
`;

export const AddWeekContainer = styled.div`
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 30px;
  right: 30px;
  top: 100%;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  transition: background-color 0.2s ease;
  z-index: 99;

  :hover {
    background-color: #5c5bbd;

    ${WrapperAddWeekIcon} {
      opacity: 1;
    }
  }

  &.lastLine {
    ${WrapperAddWeekIcon} {
      top: unset;
      bottom: 0;
    }
  }

  &.firstLine {
    top: -10px;

    ${WrapperAddWeekIcon} {
      top: 0;
    }
  }
`;

export const ActionsWeek = styled.div`
  position: absolute;
  height: 100%;
  left: 3px;
  width: 24px;
  align-items: center;
  justify-content: center;
  display: none;
  flex-direction: column;
  gap: 24px;
  cursor: pointer;
  border-right: 1px solid #202353;

  &:hover {
    background-color: #e7e8ff;
    border-radius: 2px;
    border-right: unset;
  }

  svg:hover {
    path {
      stroke: #5158cf;
    }
  }

  .deleting {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${props =>
    props.isEditMode &&
    css`
      display: none;
      border-right: unset;
      cursor: default;

      &:hover {
        background-color: unset;
      }
    `}

  .__react_component_tooltip.app-tooltip.studio-tooltip {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    padding: 6px 15px;
  }
`;

export const WrapperTrashIcon = styled.div`
  ${props =>
    props.loadingPaste &&
    css`
      :hover {
        cursor: not-allowed;
      }

      ${TrashIcon} {
        pointer-events: none;
      }
    `}
`;

export const TrashIcon = styled.span``;

export const GridRow = styled.div`
  padding: 0 30px;
  position: relative;
  display: flex;
  justify-content: space-between;

  &:hover {
    ${ActionsWeek} {
      display: flex;
    }

    ${ActionPasteWeek} {
      display: flex;
    }

    .week__title.hide-week__title {
      display: none;
    }
  }
`;

export const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${GridRow} {
    height: ${props => `calc((100% - ${(props.numRows - 1) * 10}px) / ${props.numRows})`};
  }

  ${props =>
    props.isCopingWeek &&
    css`
      .addItemButton:hover {
        cursor: initial !important;
        background-image: url(${CDN_URL}/images/plus_circle_fill_none.svg) !important;
      }

      .autoflowInterval__assignMenu__menu {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
      }
    `}
`;

export const CalendarContainer = styled.div`
  height: calc(100% - 55px);
  padding-bottom: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const CancelChangeButton = styled.button`
  outline: none;
  box-shadow: none;
  border: 1px solid #d6dae4;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #818da1;
  padding: 5px 29px 5px 44px;
  background: #fff url(${CDN_URL}/images/close_818DA1.svg) no-repeat;
  background-position: 29px center;
  background-size: 12px 12px;

  :hover {
    cursor: pointer;
  }
`;

export const EditButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
  padding-right: 22px;
  position: relative;

  :after {
    content: '';
    position: absolute;
    width: 2px;
    height: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #e8e8e8;
  }

  > * {
    margin-right: 5px;

    :last-child {
      margin: 0;
    }
  }

  button {
    &.button--turn-on-edit-mode {
      padding: 5px 29px 5px 46px;
      background-image: url(${CDN_URL}/images/edit_white.svg);
      background-repeat: no-repeat;
      background-position: 29px center;
      background-size: 12px 12px;
    }

    &.button--publish-disabled {
      background-color: #c4c4c4;

      :hover {
        background-color: #c4c4c4;
      }
    }
  }
`;

export const AlertBottomWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 22px;
  z-index: 1;

  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
  }

  .cancel-copy {
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const BottomCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
    margin-left: 15px;
  }
`;

export const EscText = styled.strong``;

export const TextCancel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CancelBold = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const BottomCopyWeekWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 22px 88px 22px 22px;
  z-index: 11;
  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
  }
  .cancel-copy {
    cursor: pointer;
    margin-left: 15px;
  }
`;

export const BottomSelectWorkoutWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px 30px 20px 29px;
  z-index: 11;
`;

export const BottomNumberSelect = styled.div`
  width: 156px;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 5px;
  color: #333333;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const BottomNumber = styled.span`
  height: 21px;
  width: 43px;
  background-color: #303030;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
`;

export const BottomActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .tooltip--remove,
  .tooltip--copy {
    width: 100px;
  }
`;

export const IconActions = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextActions = styled.span`
  color: #728096;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const BottomActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    ${IconActions} {
      svg {
        path {
          fill: #5e59ff;
        }
      }
    }
    ${TextActions} {
      color: #5e59ff;
    }
  }

  .remove-workouts.bottom-calendar__tooltip {
    width: 143px !important;
    height: 31px !important;

    background: #303030;
    border-radius: 5px !important;
    padding: 7px 16px !important;

    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 150% !important;
  }

  .copy-workouts.bottom-calendar__tooltip {
    width: 100px !important;
    height: 31px !important;
    padding: 7px 35px !important;

    background: #303030;
    border-radius: 5px !important;

    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 150% !important;
    text-align: center !important;
  }
`;

export const ConfirmModalCustom = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container.remove-week-popup {
    width: 489px !important;
    height: 180px !important;
  }

  .ui.button.confirm-yes-button:hover {
    opacity: 0.9 !important;
  }

  .ui.button.confirm-no-button:hover {
    background-color: #f5f7f9 !important;
  }
`;

export const RemoveMultipleWorkoutPopup = styled(ConfirmModal)`
  .ui.button.confirm-yes-button:hover {
    opacity: 0.9 !important;
  }

  .ui.button.confirm-no-button:hover {
    background-color: #f5f7f9 !important;
  }
`;

export const RemoveEntireWeekPopup = styled(ConfirmModal)`
  &.remove-entire-week-popup.confirm-popup-container .confirm-content-header .confirm-header-label {
    color: #000000 !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  &.remove-entire-week-popup.confirm-popup-container .confirm-content-header .confirm-content-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #323c47;
  }

  &.ui.modal.confirm-popup-container.remove-entire-week-popup {
    width: 497px !important;
    height: 180px !important;
  }

  &.remove-entire-week-popup.ui.modal.confirm-popup-container .confirm-actions {
    margin-top: 4px;
    border-top: none !important;
    background: #ffffff !important;
  }

  &.ui.modal.confirm-popup-container .confirm-actions {
    padding: 0px 30px !important;
  }

  &.confirm-popup-container .confirm-content-body {
    padding: 16px 30px 25px 30px;
  }

  .ui.button.confirm-yes-button:hover {
    opacity: 0.9 !important;
  }

  .ui.button.confirm-no-button:hover {
    background-color: #f5f7f9 !important;
  }
`;
