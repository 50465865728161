import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { cloneDeep, debounce, forEach, get, isEqual, omit, keys, unionBy, find } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import { Button } from 'shared/FormControl';
import TextEditable from 'shared/TextEditable';
import { Toggle } from 'shared/FormControl';
import StatusChip from 'shared/StatusChip';
import { convertS3UrlToCloudFrontUrl, isTeamAdmin, reorder } from 'utils/commonFunction';
import { CDN_URL, ON_DEMAND_CATEGORY_STATUS, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { NewSearchInput } from 'shared/SearchInput';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as DragDropIcon } from 'assets/icons/workout-drag-drop-icon.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left_grey.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as UpArrowIcon } from 'assets/icons/move-workout-up.svg';
import { ReactComponent as DownArrowIcon } from 'assets/icons/move-workout-down.svg';
import { ReactComponent as HoverBackground } from 'assets/icons/hamburger_icon.svg';
import LoadingIndicator from 'shared/LoadingIndicator';

import Banner from './Banner';
import AddNewForm from './AddNewForm';
import DialogModal from './AddNewForm/DialogModal';
import WorkoutList from './WorkoutList';

import { LIMIT_LOAD_ITEMS, VALIDATION_FIELD, VALIDATION_LIST } from './constants';
import * as S from './style';
import { OWNER_TYPE } from 'shared/AssetsShareSetting/components/FiltersPopup';

const LIMIT_LENGTH = 30;
const MAX_HEIGHT = 80;
const LIMIT_DESCRIPTION = 190;
const LIMIT_LAST_ROW = 60;
const MAX_REVIEW_ROW = 3;
const MAX_DESCRIPTION_TYPING_HEIGHT = 98;
const queryAttr = 'data-rbd-drag-handle-draggable-id';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: '100%',
  position: 'relative',
});

const getLevelLabel = item => {
  if (item) {
    const currentLabel = get(item, 'level.item.name', '');
    return currentLabel === 'All' ? 'All levels' : currentLabel;
  }
  return '';
};

const HeaderArea = props => {
  const [checkedIds, setCheckedIds] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loadingWorkouts, setLoadingWorkouts] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState('');
  const {
    onDemandWorkouts,
    onSubmit,
    assignedIds,
    customBrandingBackground,
    searchAllOnDemandWorkouts,
    changeQueryParams,
    categoryId,
    query,
    groupLabels,
    categoryLabels,
    disabled = false,
    cloudfrontList,
  } = props;

  const [queryValue, setQueryValue] = useState(query.textSearch);

  useEffect(() => {
    return () => {
      handleClearSearch(false);
    };
  }, []);

  useEffect(() => {
    if (query.textSearch === '') {
      setQueryValue('');
    }
  }, [query.textSearch]);

  const handleAddWorkoutsToCategory = () => {
    if (checkedIds.length) {
      handleClearSearch();
      onSubmit && onSubmit({ workouts: checkedIds });
      setCheckedIds([]);
      setFilters({});
    }
  };

  const handleSelectedWorkout = item => {
    !checkedIds.includes(item._id)
      ? setCheckedIds([...checkedIds, item._id])
      : setCheckedIds([...checkedIds.filter(it => it != item._id)]);
  };

  const handleSelectAll = status => {
    const ids = workouts.map(it => it._id);
    if (status) {
      searchAllOnDemandWorkouts &&
        searchAllOnDemandWorkouts({
          perPage: total,
          page: 1,
          level: filters['level'],
          duration: filters['duration'],
          labels: getOtherFilters(),
          textSearch: searchText,
          excludeIds: assignedIds,
          listType: 'only_index',
        })
          .then(response => {
            const { data } = get(response, 'data');
            setCheckedIds(data);
          })
          .catch(err => {
            throw err;
          });
    } else {
      setCheckedIds([]);
    }
  };

  const handleCloseSelected = () => {
    setCheckedIds([]);
    setWorkouts([]);
    setPage(1);
    setTotal(0);
    setFilters({});
  };

  const handleCreatNewWorkout = () => {
    window.open('/home/on-demand-workouts/create_new=true', '_blank');
  };

  const handleSearchWorkouts = params => {
    setLoadingWorkouts(true);
    searchAllOnDemandWorkouts &&
      searchAllOnDemandWorkouts({ ...params, excludeIds: assignedIds })
        .then(response => {
          const { data } = get(response, 'data');

          setTotal(get(data, 'total', 0));
          setPage(get(data, 'page', 1));

          const currentWorkouts = get(data, 'list', []).filter(it => !assignedIds.includes(it._id));

          if (data) {
            if (params.isLoadMore) {
              setWorkouts(unionBy([...workouts, ...currentWorkouts], '_id'));
            } else {
              setWorkouts(currentWorkouts);
            }
          }
        })
        .catch(err => {
          throw err;
        })
        .finally(() => {
          setLoadingWorkouts(false);
        });
  };

  const handleSearch = (event, { value }, refetch = true) => {
    changeQueryParams && changeQueryParams(categoryId, { textSearch: value }, refetch);
  };

  const handleClearSearch = (refetch = true) => {
    handleSearch(null, { value: '' }, refetch);
  };

  const handleChange = (event, { value }) => {
    debounceHandleSearch(event, { value });
    setQueryValue(value);
  };

  const debounceHandleSearch = useCallback(debounce(handleSearch, 300), []);

  const handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams(categoryId);
    }
  };

  const handleLoadMore = () => {
    if (page * LIMIT_LOAD_ITEMS <= total) {
      handleSearchWorkouts({
        perPage: LIMIT_LOAD_ITEMS,
        page: page + 1,
        level: filters['level'],
        duration: filters['duration'],
        labels: getOtherFilters(),
        textSearch: searchText,
        isLoadMore: true,
      });
    }
  };

  const handleUpdateFilters = (options, owner = OWNER_TYPE.ALL) => {
    setWorkouts([]);
    setFilters(options);
    setTotal(0);
    setPage(1);
    setCheckedIds([]);

    let others = [];
    Object.keys(options).forEach(key => {
      const item = {};
      const workingLabel = categoryLabels.find(it => it.unique_code === key);
      if (workingLabel && !['level', 'duration'].includes(workingLabel.unique_code)) {
        item.label = get(workingLabel, '_id', '');
        item.values = options[key];
        if (!!get(item, 'values', []).length) {
          others.push(item);
        }
      }
    });

    handleSearchWorkouts({
      perPage: LIMIT_LOAD_ITEMS,
      page: 1,
      level: options['level'],
      duration: options['duration'],
      labels: others,
      textSearch: searchText,
      only_my_workout_library: owner === OWNER_TYPE.YOU,
    });
  };

  const getOtherFilters = () => {
    let others = [];
    Object.keys(filters).forEach(key => {
      const item = {};
      const workingLabel = categoryLabels.find(it => it.unique_code === key);
      if (!['level', 'duration'].includes(workingLabel.unique_code)) {
        item.label = get(workingLabel, '_id', '');
        item.values = filters[key];
        if (!!get(item, 'values', []).length) {
          others.push(item);
        }
      }
    });
    return others;
  };

  const handleChangeSearchWorkouts = (value, owner = OWNER_TYPE.ALL) => {
    setWorkouts([]);
    setTotal(0);
    setPage(1);
    setCheckedIds([]);
    setSearchText(value);
    handleSearchWorkouts({
      perPage: LIMIT_LOAD_ITEMS,
      page: 1,
      level: filters['level'],
      duration: filters['duration'],
      labels: getOtherFilters(),
      textSearch: value,
      only_my_workout_library: owner === OWNER_TYPE.YOU,
    });
  };

  const handleClearSearchWorkouts = (owner = OWNER_TYPE.ALL) => {
    setWorkouts([]);
    setTotal(0);
    setPage(1);
    setCheckedIds([]);
    setSearchText('');
    handleSearchWorkouts({
      perPage: LIMIT_LOAD_ITEMS,
      page: 1,
      level: filters['level'],
      duration: filters['duration'],
      labels: getOtherFilters(),
      searchText: '',
      only_my_workout_library: owner === OWNER_TYPE.YOU,
    });
  };

  const availableWorkouts = useMemo(() => {
    const counter = total;
    return counter > 0 ? counter : 0;
  }, [total, assignedIds]);

  return (
    <S.HeaderArea>
      <NewSearchInput
        placeholder="Search for a workout"
        className="search-input"
        onChange={handleChange}
        onClearSearch={handleClearSearch}
        onKeyPress={handleKeyPress}
        value={queryValue}
      />
      <AddNewForm
        text="Add workout"
        heightButton={36}
        widthButton={143}
        icon={<PlusIcon />}
        buttonClassName="buttonClassName"
        titlePopup="Choose your On-demand Workouts"
        description="Add workouts to display under this Category"
        submitName="Add"
        popupModal={DialogModal}
        onSubmit={handleAddWorkoutsToCategory}
        showLabel={onDemandWorkouts.length > 0}
        shouldDisable={checkedIds.length <= 0}
        onClosePopup={handleCloseSelected}
        onCreateNewWorkout={handleCreatNewWorkout}
        onSearchWorkouts={handleSearchWorkouts}
        onChangeSearchWorkouts={handleChangeSearchWorkouts}
        onClearSearchWorkouts={handleClearSearchWorkouts}
        isDisabled={false}
        groupLabels={groupLabels}
        onUpdateFilters={handleUpdateFilters}
        currentFilters={filters}
        categoryLabels={categoryLabels}
        onSelectAll={handleSelectAll}
        disableSelectAll={checkedIds.length === total && total > 0}
        isDisabledOwner={disabled}
      >
        <WorkoutList
          customBrandingBackground={customBrandingBackground}
          data={workouts}
          cloudfrontList={cloudfrontList}
          onChangeItem={handleSelectedWorkout}
          selectedIds={checkedIds}
          addedIds={assignedIds}
          onCreateNewWorkout={handleCreatNewWorkout}
          onLoadMore={handleLoadMore}
          availableWorkouts={availableWorkouts}
          loading={loadingWorkouts}
        />
      </AddNewForm>
    </S.HeaderArea>
  );
};

const RenderDescription = ({ description, onBlur, onDescriptionChange, readOnly = false }) => {
  const titleRef = React.createRef();
  const [isFocus, setIsFocus] = useState(false);
  const [isView, setIsView] = useState(false);
  const refContainer = useRef(null);

  useEffect(() => {
    titleRef.current && isFocus && titleRef.current.focus();
  }, [isFocus]);

  useEffect(() => {
    description.length === 0 && setIsFocus(true);
  }, [description]);

  const handleSetIsFocus = value => setIsFocus(value);

  const handleBlur = () => {
    onBlur('description', description);
    handleSetIsFocus(false);
    // TODO - scroll view of description on top
    if (titleRef.current) {
      const child = get(titleRef.current, 'inputRef.current', false);
      if (child) child.scrollTop = 0;
    }
    // Focus when description is empty
    if (description.length === 0) setIsFocus(true);
  };

  const handleReadMore = () => {
    setIsView(true);
  };

  const handleCloseReadMore = () => {
    // TODO - close view of description
    setIsView(false);
  };

  const handleClickView = () => {
    setIsView(false);
    handleSetIsFocus(true);
  };

  // TODO - Handle Count Break Line Of String
  const handleCountBreakLine = val => {
    const value = val ? val.trim() : '';
    const breakLineTotal = value.split('\n').length;
    return breakLineTotal;
  };

  const handleCutStringLastRow = val => {
    const breakLineTotal = val ? val.split('\n').length : 0;

    if (breakLineTotal === 0) return;
    if (breakLineTotal === 1) return val;

    let value = val.split('\n');
    value[MAX_REVIEW_ROW - 1] = value[MAX_REVIEW_ROW - 1]
      ? value[MAX_REVIEW_ROW - 1].substring(0, LIMIT_LAST_ROW)
      : null;
    value = value.join('\n');
    return value;
  };

  const handleRenderValue = value => {
    return (
      <S.DescriptionView className={classnames({ active: isView })}>
        <span onClick={handleClickView} className="view">
          {value}
        </span>
        <div
          className="descriptionInput__cancelViewMode"
          data-tip
          data-for="cancel-view-mode"
          onClick={handleCloseReadMore}
        >
          <ReactTooltip id="cancel-view-mode" effect="solid" place={'top'}>
            Minimize description
          </ReactTooltip>
        </div>
      </S.DescriptionView>
    );
  };

  const handleDescriptionChange = value => {
    // show the new line after click Enter key
    if (titleRef.current) {
      const child = get(titleRef.current, 'inputRef.current', false);
      if (child && child.clientHeight >= MAX_DESCRIPTION_TYPING_HEIGHT) {
        child.scrollTop = child.scrollHeight;
      }
    }

    // onchange description
    onDescriptionChange && onDescriptionChange(value);
  };

  const hasReadMore = handleCountBreakLine(description) > 3 || description.length > LIMIT_DESCRIPTION;
  let checkRefReadMore = hasReadMore;
  if (refContainer.current) {
    checkRefReadMore = refContainer.current.clientHeight > MAX_HEIGHT;
  }

  const shortDesc = checkRefReadMore ? handleCutStringLastRow(description) : description;
  const showReadMore = !isFocus && checkRefReadMore && !isView;

  return (
    <S.DescriptionArea ref={refContainer} isView={isView} hasReadMore={showReadMore}>
      {isView && handleRenderValue(description || '')}

      {!isView && (
        <TextEditable
          ref={titleRef}
          readOnly={readOnly}
          className="collection-description"
          value={isFocus ? description : shortDesc}
          onBlur={handleBlur}
          onChange={handleDescriptionChange}
          onFocus={() => handleSetIsFocus(true)}
          placeholder="Add category description"
          lineHeight={19.5}
          breakLine={true}
        />
      )}
      {showReadMore && <S.ReadMore onClick={handleReadMore}>Read More</S.ReadMore>}
    </S.DescriptionArea>
  );
};

export default function WorkoutCollectionsCategoryDetail({
  getAllOnDemandWorkouts,
  searchAllOnDemandWorkouts,
  getWorkoutCollectionCategoryDetail,
  workoutCollectionCategory,
  push,
  cloudfrontList,
  onDemandWorkouts,
  addWorkouts,
  workouts: originalWorkouts,
  getPreSignedUrl,
  updateCategoryDetail,
  removeWorkout,
  toggleCategory,
  toggleConfirmModal,
  customBrandingBackground,
  moveOnDemandWorkout,
  moveOnDemandWorkoutToTop,
  moveOnDemandWorkoutToBottom,
  changeQueryParams,
  loadMoreWorkouts,
  query,
  groupLabels,
  categoryLabels,
  getAssignedWorkouts,
  user,
}) {
  const [workingCategory, setWorkingCategory] = useState(workoutCollectionCategory);
  const [description, setDescription] = useState(workingCategory.description || '');
  const [name, setName] = useState(workingCategory.name);
  // const [description, setDescription] = useState(workoutCollectionCategory.description);
  // const [status, setStatus] = useState(workoutCollectionCategory.status);
  const { status } = workingCategory;
  const [workouts, setWorkouts] = useState(originalWorkouts);
  const debounceUpdateData = useCallback(debounce(updateCategoryDetail, 1000), []);
  const [validationList, setValidationList] = useState(VALIDATION_LIST);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [uploading, setUploading] = useState(false);

  const { on_demand_workout_collection = {} } = workoutCollectionCategory;
  const authorId = on_demand_workout_collection.author;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = on_demand_workout_collection.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;

  const isDragDisabled = !isCreatorOrOwnerOrAdmin || query.textSearch !== '';

  const [assignedIds, setAssignedIds] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const currentWorkouts = get(workoutCollectionCategory, 'workouts.list')
      ? get(workoutCollectionCategory, 'workouts')
      : workouts;
    setWorkouts(currentWorkouts);
  }, [workoutCollectionCategory.workouts]);

  useEffect(() => {
    setWorkingCategory(workoutCollectionCategory);
  }, [workoutCollectionCategory]);

  useEffect(() => {
    if (!isEqual(omit(workingCategory, 'updated_at'), omit(workoutCollectionCategory, 'updated_at'))) {
      let updateFields = cloneDeep(workingCategory);
      // only keep updated fields
      forEach(workoutCollectionCategory, (value, key) => {
        if (isEqual(workoutCollectionCategory[key], updateFields[key])) {
          delete updateFields[key];
        }
      });
      // TODO - Handle IsValidation
      if (!handleIsValidation()) return;

      updateFields = omit(updateFields, ['team', 'created_at', 'workouts', 'query', 'updated_at', 'status']);

      let workoutCollectionCategoryVal = workoutCollectionCategory;
      keys(updateFields).map(item => (workoutCollectionCategoryVal[item] = updateFields[item]));

      if (Object.keys(updateFields).length > 0) {
        debounceUpdateData(updateFields, workingCategory._id);
        setWorkingCategory(workoutCollectionCategoryVal);
      }
    }
  }, [workingCategory]);

  useEffect(() => {
    const url = get(window, 'location.pathname', '');
    const currentId = url.substring(url.lastIndexOf('/') + 1);
    getAssignedWorkouts &&
      getAssignedWorkouts(currentId, {
        listType: 'only_workout_library_index',
        perPage: get(query, 'total', 20),
      })
        .then(res => {
          const list = get(res, 'data.data', []);
          setAssignedIds(list);
        })
        .catch(err => {
          throw err;
        });
  }, [get(workouts, 'list', []).length]);

  useEffect(() => {
    getAllOnDemandWorkouts && getAllOnDemandWorkouts();
  }, []);

  const handleGoBack = () => {
    setTimeout(() => {
      push(`/home/workout-collections/${on_demand_workout_collection._id}`);
    }, 1000);
  };

  const handleChangeCoverImage = url => {
    setWorkingCategory({ ...workingCategory, background: url });
  };

  const handleRemoveBanner = () => {
    handleChangeCoverImage(null);
  };

  const startUploadCoverImage = () => {
    setUploading(true);
  };

  const endUploadCoverImage = () => {
    setUploading(false);
  };

  const handleNameChange = value => {
    if (value.length <= LIMIT_LENGTH) {
      setName(value);
    } else {
      setName(value.slice(0, LIMIT_LENGTH));
    }

    // TODO - Check Validation
    handleCheckValidation(value, 'name');
  };

  const handleBlur = (name, value) => {
    setWorkingCategory({ ...workingCategory, [name]: value });
  };

  const handleDescriptionChange = value => {
    setDescription(value || '');
  };

  const handleCountWorkout = value => {
    const val = value != 1 ? ' workouts' : ' workout';
    return val;
  };

  const handleAddWorkouts = params => {
    addWorkouts && addWorkouts({ id: workoutCollectionCategory._id, ...params });
  };

  const handleRemoveWorkout = id => () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove"
        content="Would you like to remove this workout from the Category? This will not affect the workout in any other Category or Collection."
        onConfirm={() =>
          removeWorkout({
            categoryId: workingCategory._id,
            workoutId: id,
          })
        }
        onDeny={() => {}}
        newStyle
        largeSpacing
        hasHoverState
        confirmButtonTitle="Remove"
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
      />,
    );
  };

  // TODO - Check validation
  const handleCheckValidation = (val, name) => {
    let value = val || '';
    let validations = validationList;

    value = value.trim();

    if (value.length > 0) validations[name] = true;
    else validations[name] = false;

    setValidationList(validations);
  };

  // TODO - Is-Validation
  const handleIsValidation = () => {
    const validationKeys = VALIDATION_FIELD;
    let isValidation = true;

    forEach(validationKeys, item => {
      if (!validationList[item]) isValidation = false;
    });

    return isValidation;
  };

  // TODO - Handle get validation
  const handleGetValidation = (name, value) => {
    const val = value || '';
    if (val.length === 0) return !validationList[name];
  };

  const handleBeforeCapture = () => {
    setIsDragging(true);
  };

  const handleOnDragEnd = item => {
    const { destination, source, draggableId } = item;
    if (!destination || !source || destination.index === source.index) {
      return;
    }

    const clone = cloneDeep(workouts);

    const data = reorder(clone.list, source.index, destination.index);
    // Update current list first
    setWorkouts({
      ...workouts,
      list: data,
    });

    // Call api to update late
    setTimeout(() => {
      moveOnDemandWorkout(draggableId, destination.index, source.index, true, () => setIsDragging(false));
    }, 500);
  };

  const onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  // Move Up/Down
  const handleMoveWorkout = (workoutId, destinationIndex, originalIndex) => () => {
    moveOnDemandWorkout(workoutId, destinationIndex, originalIndex);
  };

  const handleMoveToTop = (workoutId, originalIndex) => () => {
    moveOnDemandWorkoutToTop(workoutId, originalIndex);
  };

  const handleMoveToBottom = (workoutId, originalIndex) => () => {
    moveOnDemandWorkoutToBottom(workoutId, originalIndex);
  };

  const handleLoadMore = event => {
    const { total, isEnd, isLoading, textSearch } = query;
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && workouts.list.length < total && !isLoading && !isEnd && textSearch === '' && !isDragging) {
      const lastWorkoutId = get(workouts.list, `[${workouts.list.length - 1}]._id`);
      if (lastWorkoutId) {
        loadMoreWorkouts(workoutCollectionCategory._id, lastWorkoutId);
      }
    }
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const statusOptions = [
    { value: ON_DEMAND_CATEGORY_STATUS.INACTIVE, label: 'Draft', color: '#818DA1' },
    { value: ON_DEMAND_CATEGORY_STATUS.ACTIVE, label: 'Published', color: '#6ACF47' },
  ];

  const handleChangeStatus = newValue => {
    if (status === 'active') {
      setWorkingCategory({ ...workingCategory, status: ON_DEMAND_CATEGORY_STATUS.INACTIVE });
      toggleCategory(workingCategory._id, workingCategory.status);
    } else {
      setWorkingCategory({ ...workingCategory, status: ON_DEMAND_CATEGORY_STATUS.ACTIVE });
      toggleCategory(workingCategory._id, workingCategory.status);
    }
  };

  const handleNavigateToOnDemandModal = workoutId => () => {
    window.open(`/home/on-demand-workouts/${workoutId}`, '_blank');
  };
  return (
    <S.Wrapper onScroll={event => handleScroll(event)}>
      <S.BreadCrumbWrapper>
        <Button className="go-back-btn" textOnly type="button">
          <LeftArrowIcon onClick={handleGoBack} />
          <span className="collection-name" onClick={handleGoBack}>
            {on_demand_workout_collection.name}
          </span>
          <span className="breadcrumb-separator">/</span>
          <span className="category-name"> {workingCategory.name}</span>
        </Button>
      </S.BreadCrumbWrapper>
      <S.ContentWrapper>
        <S.BaseInfoContainer>
          <div className="banner-container">
            <Banner
              isSubmitting={uploading}
              isEdit={isCreatorOrOwnerOrAdmin}
              coverImage={convertS3UrlToCloudFrontUrl(workingCategory.background, cloudfrontList, true)}
              getPreSignedUrl={getPreSignedUrl}
              onUploadImageChange={handleChangeCoverImage}
              startUploadCoverImage={startUploadCoverImage}
              endUploadCoverImage={endUploadCoverImage}
              onRemove={handleRemoveBanner}
            />
          </div>
          <div className="info-container">
            <S.TextEditableArea>
              <TextEditable
                readOnly={!isCreatorOrOwnerOrAdmin}
                className="workout-collection-title"
                value={name}
                onBlur={() => handleBlur('name', name)}
                onChange={handleNameChange}
                placeholder="Add category name"
                lineHeight={29}
                maxLength={LIMIT_LENGTH}
                invalid={handleGetValidation('name', name)}
              />
              <S.CountCharacter>{`${name.length}/${LIMIT_LENGTH}`}</S.CountCharacter>
            </S.TextEditableArea>

            <RenderDescription
              description={description || ''}
              onBlur={handleBlur}
              onDescriptionChange={handleDescriptionChange}
              readOnly={!isCreatorOrOwnerOrAdmin}
            />
          </div>
          <div className="status-container">
            <StatusChip status={status} customClass="status-text" />
            {isCreatorOrOwnerOrAdmin && (
              <Toggle className="unit-client-toggle" checked={status === 'active'} onChange={handleChangeStatus} />
            )}
          </div>
        </S.BaseInfoContainer>
        <S.CategoryListContainer>
          <HeaderArea
            onDemandWorkouts={onDemandWorkouts}
            onSubmit={handleAddWorkouts}
            assignedIds={assignedIds}
            customBrandingBackground={customBrandingBackground}
            push={push}
            searchAllOnDemandWorkouts={searchAllOnDemandWorkouts}
            changeQueryParams={changeQueryParams}
            categoryId={workoutCollectionCategory._id}
            query={query}
            cloudfrontList={cloudfrontList}
            groupLabels={groupLabels}
            categoryLabels={categoryLabels}
            disabled={!isCreatorOrOwnerOrAdmin}
          />
          <div>
            <b>{workoutCollectionCategory.number_of_workouts}</b>{' '}
            {handleCountWorkout(workoutCollectionCategory.number_of_workouts || 0)}
          </div>

          <DragDropContext
            onDragEnd={handleOnDragEnd}
            onDragUpdate={onDragUpdate}
            onBeforeCapture={handleBeforeCapture}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className="category-list"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {get(workouts, 'list', []).map((workout, idx) => {
                    const isFirst = idx === 0;
                    const isLast = idx === workouts.list.length - 1;
                    const { rearranging: isRearranging } = workoutCollectionCategory;
                    let thumbnail = '';
                    if (workout.background_thumbnail) {
                      thumbnail = workout.background_thumbnail;
                    } else {
                      thumbnail = workout.background
                        ? workout.background
                        : customBrandingBackground.src
                        ? customBrandingBackground.src
                        : `${CDN_URL}/images/drop_image.svg`;
                    }

                    return (
                      <Draggable
                        key={`item-workout-${workout._id || idx}`}
                        draggableId={workout._id}
                        index={idx}
                        isDragDisabled={isDragDisabled}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={classnames('category-item', { isDragging: snapshot.isDragging })}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            onClick={handleNavigateToOnDemandModal(workout.on_demand_workout_library_item)}
                          >
                            <DragDropIcon className="drag-drop-icon" />
                            <div className="index-number">{idx + 1}</div>
                            <div className="banner-container">
                              <S.CategoryImage
                                isDefault={!workout.background && !customBrandingBackground.src}
                                src={thumbnail}
                                alt={workout.title}
                              />
                              {workout.type === 'video' && <S.VideoIcon />}
                              <S.HoverCategoryImage>
                                <HoverBackground />
                              </S.HoverCategoryImage>
                            </div>
                            <div className="workout-name">{workout.title}</div>
                            <div className="default-labels-container">
                              <div className="duration-text label-text">{workout.duration.item.name}</div>
                              <div className="level-text label-text">{getLevelLabel(workout)}</div>
                            </div>
                            <div className="custom-labels-container">
                              {workout.labels.map((item, idx) => {
                                return (
                                  <span
                                    key={`item-custom-label${(item.item || {})._id || idx}`}
                                    className="custom-label label-text"
                                  >
                                    {get(item, 'item.name', '')}
                                  </span>
                                );
                              })}
                            </div>
                            <div style={{ flex: '1' }}>
                              {isCreatorOrOwnerOrAdmin && (
                                <DropDown
                                  disabled={isRearranging}
                                  className="category-actions-dropdown"
                                  triggerIcon={({ open }) => (
                                    <S.TriggerIcon
                                      className={classnames('trigger-icon', { disabled: isRearranging })}
                                      active={!!open}
                                    />
                                  )}
                                  direction="left"
                                  shouldCheckItem={true}
                                >
                                  <div className="dropdown-area">
                                    {!isFirst && !isDragDisabled && (
                                      <Option onClick={handleMoveToTop(workout._id, idx)}>
                                        <UpArrowIcon className="icon" />
                                        <span>Move to top</span>
                                      </Option>
                                    )}
                                    {!isLast && !isDragDisabled && (
                                      <Option onClick={handleMoveToBottom(workout._id, idx)}>
                                        <DownArrowIcon className="icon" />
                                        <span>Move to bottom</span>
                                      </Option>
                                    )}
                                    <Option onClick={handleRemoveWorkout(workout._id)}>
                                      <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
                                      <span>Remove</span>
                                    </Option>
                                    <div className="dropdown-arrow" />
                                  </div>
                                </DropDown>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {!query.isEnd && workouts.list.length < query.total && !isDragging && (
                    <LoadingIndicator className="loading" />
                  )}
                  {provided.placeholder}
                  {snapshot.isUsingPlaceholder && snapshot.isDraggingOver && (
                    <div
                      style={{
                        position: 'absolute',
                        top: placeholderProps.clientY,
                        left: placeholderProps.clientX,
                        height: placeholderProps.clientHeight,
                        width: placeholderProps.clientWidth,
                        background: 'rgb(250, 251, 252)',
                        border: '1px dashed rgb(81, 88, 207)',
                        borderRadius: '8px',
                        borderWidth: placeholderProps.clientWidth ? '1px' : '0px',
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {query.textSearch !== '' && workouts.list.length === 0 && <S.NotFound>No results found</S.NotFound>}
        </S.CategoryListContainer>
      </S.ContentWrapper>
    </S.Wrapper>
  );
}
