import React from 'react';
import Form from './Form';
import Layout from './component';
import { pick } from 'lodash';
import { axiosInstanceWithoutToken } from 'configs/request';

export const handleRegister = data => {
  const { invite_code } = data;
  const registerLink = `api/authentication/enroll/${invite_code}`;
  const params = pick(data, ['email', 'first_name', 'last_name', 'password']);

  return axiosInstanceWithoutToken.post(registerLink, params).then(response => response.data);
};

const SignUpForm = props => <Form {...props} onSubmit={handleRegister} autoFocus="first_name" />;

export default props => {
  const handleFetchData = queryParams => {
    return axiosInstanceWithoutToken.get(`/api/auth/fetch_referral_code/${queryParams.inviteCode}`);
  };

  return <Layout {...props} components={{ SignUpForm }} onFetch={handleFetchData} />;
};
