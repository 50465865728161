import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import { Wrapper, TableHeaderCell, TableCell, TableRow } from 'shared/LibraryLayout';
import AddButton from 'shared/AddButton';
import { ASSETS_SHARE_SETTING_TYPE } from './constants';
import { CDN_URL } from 'constants/commonData';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 35px;
  ${props =>
    props.isHideBanner &&
    css`
      margin-top: -10px;
    `}

  .program-header-title {
    color: #000;
    font-family: 'Open Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .program-explore-banner-header-wrapper {
    flex: 1;
  }

  .program-explore-banner-header {
    height: 48px;
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: fit-content;
    margin: 0 auto;

    border-radius: 8px;
    border: 1px solid rgba(164, 164, 164, 0.1);
    background: linear-gradient(269deg, #fffdf0 1.1%, #f8fef1 99.38%);
  }

  .program-header-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 50%;
    background: #ebffae;
  }

  .program-explore-title {
    max-width: 500px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #000;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
  }

  .program-explore-templates-button {
    width: 134px;
    height: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #fff;
    margin-left: 13px;

    span {
      color: #000;
      font-family: 'Open Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    :hover {
      background: #5158cf;

      span {
        color: #ffffff;
      }
    }
  }

  @media screen and (max-width: 1555px) {
    .program-explore-banner-header-wrapper {
      flex: unset;
    }
  }

  @media screen and (max-width: 1299px) {
    .program-explore-banner-header {
      width: 426px;
      right: 23px;
      position: relative;
    }

    .program-explore-title {
      min-width: 190px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: break-spaces;
      line-height: 120%;
    }
    .program-explore-templates-button {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1120px) {
    .program-explore-banner-header {
      width: 209px;
      right: 23px;
      position: relative;
      gap: 11px;
      padding: 8px 14px;
    }
    .program-explore-title {
      display: none;
    }
    .program-explore-templates-button {
      margin-left: 2px;
    }
  }
`;

export const CustomLayoutWrapper = styled(Wrapper)`
  ${TableHeaderCell} {
    padding: 20px 15px;

    &:nth-child(1) {
      width: auto !important;
    }

    &:nth-child(2) {
      width: 215px !important;
      padding-left: 0px;
      padding-right: 0px;
    }

    &:nth-child(3) {
      width: 80px !important;
      padding-left: 0px;
      padding-right: 0px;
    }

    &:nth-child(4),
    &:nth-child(5) {
      width: 120px !important;
    }

    &:nth-child(5) {
      width: 120px !important;
      padding-left: 0px;
      padding-right: 0px;
    }

    &:nth-child(6) {
      width: 115px !important;
    }

    > * {
      flex-shrink: 0;
      white-space: nowrap;
    }
  }

  ${TableCell} {
    &:nth-child(2),
    &:nth-child(3) {
      padding: 16px 0;
    }

    &:nth-child(6) {
      padding: unset;
    }
  }

  .program-toolbar-left,
  .program-toolbar-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .program-toolbar-left {
    .search-input input {
      padding-left: 40px;
    }
    .program-filter .ui.button {
      width: 104px;
      margin-left: 5px;
      span {
        padding: 0px 0px 0px 10px;
      }
    }
  }

  .program-menu-tags-wrapper {
    position: relative;

    .program-popup-menu-tags-wrapper {
      position: unset;

      .program-popup-menu-tags-container {
        top: calc(100% + 8px);
        right: -1px;
      }

      .search-input {
        input {
          padding-left: 40px;
        }
      }
    }
  }

  .program-checkbox-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 74px;
    margin-right: 15px;

    .evf-checkbox.program-checkbox-header {
      padding-left: unset;
    }

    .checkbox-icon {
      transform: translate(-50%, -50%);
    }

    input:checked ~ .checkbox-icon {
      background-color: #7072dd;
      border-color: #7072dd;
      background-size: 10px;
    }
  }
`;

export const TagsButton = styled(AddButton)`
  float: left !important;
  padding: 6px 20px;
  border-color: #d4d7d9 !important;
  border-radius: 5px !important;

  .shared-add-button-wrapper {
    margin: 0;
  }

  .shared-add-button-title {
    font-size: 12px;
    line-height: 12px;
    color: #777;
    font-weight: 600 !important;
    letter-spacing: 0;
    padding-left: 10px;
  }

  &:hover,
  &:active {
    background-color: #f8f8f8 !important;
    cursor: pointer;
  }

  ${props =>
    props.open &&
    css`
      background-color: #f8f8f8 !important;
    `}
`;

export const AddNewProgramWrapper = styled.div`
  position: relative;

  .add-new-program-button {
    cursor: pointer;
    padding: 0 24px;
    height: 30px;

    border-radius: 5px;
    background: #5158cf;
    border: none;

    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    img {
      width: 12px;
      height: 12px;
      margin-right: 10px;
    }

    &:hover {
      background: rgba(81, 88, 207, 0.9) !important;
    }
  }

  .add-first-program-button {
    width: 188px;
    height: 36px;
    margin-top: 8px;
  }

  .add-more-program-button {
    width: 164px;
    height: 36px;
    margin-top: 24px;
    margin-left: -1px;
    gap: 9px;
    padding-left: 15px;
    justify-content: flex-start;
  }
`;

export const BannerWrapper = styled.div`
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 16px;
  margin-right: 20px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f1f3ff 0%, #fff9f6 52.85%, #fff3e8 77%, #fff5dd 100%);
  overflow: hidden;

  .bg-program-banner-left {
    width: 270px;
    height: 165px;
  }

  .explore-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
    max-width: 393px;

    .explore-head-title {
      color: #1c1919;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      margin-top: 1px;
    }

    .explore-title {
      color: #1c1919;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-top: 1px;
      margin-bottom: 3px;
    }

    .explore-description {
      color: #1c1919;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      width: 307px;
    }
  }

  .hide-banner-wrapper {
    position: absolute;
    bottom: 10px;
    left: 49%;
    transform: translateX(-50%);

    .hide-banner-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;

      padding: 5px;
      border-radius: 5px;
      background: transparent;
      border: none;
      cursor: pointer;

      span {
        color: #7b7e91;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }

      :hover {
        background: #f0f1ff;

        span {
          color: #5158cf;
        }

        .icon-arrow-up {
          fill: #5158cf;
        }
      }
    }
  }

  .explore-learn-more {
    position: relative;
    color: #6456c4;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 8px;

    img {
      position: absolute;
      top: 1px;
    }

    span {
      margin-left: 18px;
    }
  }

  .explore-template-wrapper {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);

    .explore-template-button {
      width: 181px;
      height: 38px;
      border-radius: 5px;
      background: #070707;
      border: none;
      cursor: pointer;
      margin-top: -1px;

      span {
        color: #fff;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &:hover {
        background: #3b3b3b;
      }
    }
  }
`;

export const CustomRow = styled(TableRow)`
  position: relative;

  .program-information-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;

    &.align-items-center {
      align-items: center;
    }
  }

  .program-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .thumbnail-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 60px;
      border-radius: 4px;
      background: #f7f8fc;
      overflow: hidden;

      .img-background {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .checkbox-thumbnail {
      display: none;
    }

    &.checked,
    :hover {
      .thumbnail-container {
        display: none;
      }

      .checkbox-thumbnail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 60px;

        .evf-checkbox.program-checkbox {
          padding-left: unset;
        }

        .checkbox-icon {
          transform: translate(-50%, -50%);
        }

        input:checked ~ .checkbox-icon {
          background-color: #7072dd;
          border-color: #7072dd;
          background-size: 10px;
        }
      }
    }
  }

  .program-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }

  .program-name {
    color: #0d1419;
    font-family: 'Open Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    word-break: break-word;
    box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .program-description {
    color: #0d1419;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    word-break: break-word;
    box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .program-live-sync {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    color: #0c6af6;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    border-radius: 3px;
    border: 1px solid #a2beeb;

    width: 56px;
    height: 20px;
  }

  .program-total-clients {
    margin-top: 10px;

    strong {
      font-weight: 600;
    }
  }

  .program-tags-container {
    display: flex;
    flex-flow: row wrap;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .program-not-live-sync,
  .program-not-tags {
    color: #222;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .program-total-clients,
  .program-total-weeks,
  .program-last-interacted,
  .program-not-last-interacted {
    color: #222;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .program-actions-wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 25px 25px 25px;
    gap: 15px;
  }

  .program-actions-dropdown {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-start;

    &.open {
      .trigger-icon {
        background: #eaeef1;
      }
    }

    .dropdown-area {
      padding: 6px 0;
      background: #2d2e2d;
      border-radius: 5px;
    }

    .dropdown-arrow {
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      right: 36.5px;
      top: 2.5px;
      background: #2d2e2d;
      border-top: 1px solid #363636;
      border-left: 1px solid #363636;
      transform: rotateZ(45deg);
    }
  }

  .evf-dropdown--basic {
    .evf-dropdown__menu {
      min-width: 141px;
      background-color: transparent;
      transform: translateX(28px) translateY(-2px);

      &.last_item {
        .dropdown-arrow {
          top: unset;
          bottom: 2.5px;
        }
      }
    }
    .evf-dropdown__option {
      padding-left: 15px;
      padding-right: 15px;
      svg {
        width: 15px;
        margin-right: 10px;
        path,
        rect {
          stroke: white;
        }

        &.stroke-unset {
          path,
          rect {
            stroke: unset;
          }
        }
      }
    }
  }

  .tag-name {
    cursor: pointer;
    margin-right: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5c58c0;

    :hover {
      text-decoration: underline;
    }

    ::after {
      content: ',';
    }

    :last-child::after {
      content: '';
    }
  }
`;

export const IconStatusContainer = styled.div`
  display: inline-block;
  cursor: pointer;

  .__react_component_tooltip.app-tooltip {
    width: 85px;
    margin-left: -8px;

    &.place-top {
      margin-top: -10px;
    }

    ::after {
      right: -4px;
      border-top-color: #2d2e2d !important;
    }

    &.is-place {
      width: 59px;
      height: 36px;
      padding: 8px;
      padding-top: 9px;
      margin-left: 0px;
      border-radius: 5px;
      background-color: #2d2e2d;
    }

    .tooltip-content {
      color: #fff;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  > * {
    vertical-align: middle;
  }

  ${props =>
    props.share &&
    css`
      svg {
        circle {
          fill: #f0f1ff;
        }

        path {
          fill: #5158cf;
        }
      }
    `}
`;

export const ShareOwnerAvatarWrapper = styled.div`
  display: inline-block;
  line-height: 0px;
  .__react_component_tooltip.share-owner-avatar-tooltip {
    border-radius: 4px;
    background: #333;
    padding: 8px 16px;
    margin-top: -13px;
    max-width: 394px;

    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    word-break: break-word;
    white-space: pre-wrap;

    ::after {
      background-color: #333;
      width: 11px;
      height: 11px;
      border-radius: 2px;
      transform: rotate(45deg);
      border: none !important;
      bottom: -7px;
      margin-left: -6px;
    }
  }
`;

const getFormatColumnCell = type => {
  switch (type) {
    case ASSETS_SHARE_SETTING_TYPE.SHARE_SETTING_MODAL:
      return css`
        gap: 10px;

        ${FieldWrapper} {
          width: 100%;
        }

        ${FieldTextBox} {
          height: 36px;
          padding-left: 14px;
        }

        .owner__control,
        .share__control {
          min-height: 36px;
          max-height: 36px;
        }

        .share__single-value {
          margin-top: 0px;
        }

        .share__dropdown-indicator {
          padding-right: 8px;
        }
      `;
    case 'default':
    default:
      return css`
        gap: 20px;

        ${FieldWrapper} {
          width: 100%;
        }

        ${FieldTextBox} {
          height: 42px;
        }

        .owner__control,
        .share__control {
          min-height: 42px;
          max-height: 42px;
        }
      `;
  }
};

export const customSelectOwnerStyles = {
  container: baseStyle => ({
    ...baseStyle,
    background: '#FAFBFC',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '42px',
    maxHeight: '42px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FAFBFC',
  }),
  valueContainer: baseStyle => ({
    ...baseStyle,
    padding: '0px 12px',
  }),
  singleValue: baseStyle => ({
    ...baseStyle,
    color: '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '8px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    overflowX: 'hidden',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#edecfc' : '#ffffff',
    ':hover': {
      backgroundColor: '#edecfc',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isFocused || state.isSelected ? '#5158CF' : '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : '400',
    lineHeight: '18px',
    padding: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
};

export const customSelectStatusStyles = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#FAFBFC',
    borderRadius: '4px',
    pointerEvents: 'auto',
    opacity: state.isDisabled ? 0.4 : 1,
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '42px',
    maxHeight: '42px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FAFBFC',
  }),
  valueContainer: baseStyle => ({
    ...baseStyle,
    padding: '0px 12px',
  }),
  singleValue: baseStyle => ({
    ...baseStyle,
    color: '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '8px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#edecfc' : '#ffffff',
    ':hover': {
      backgroundColor: '#edecfc',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isFocused || state.isSelected ? '#5158CF' : '#202353',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: state.isSelected ? '600' : '400',
    lineHeight: '18px',
    padding: '12px',
  }),
};

export const ShareSettingModalWrapper = styled(Modal)`
  border-radius: 8px !important;
  border: 1px solid #d4d4d5 !important;
  background: #fff !important;
  width: 563px !important;
  max-height: 301px !important;
  padding: 23px !important;

  > .header,
  .content,
  .actions {
    padding: 0 !important;
    background: #fff !important;
  }

  > .content {
    margin-top: 21px !important;
    margin-bottom: 45px !important;
  }

  > .actions {
    border-top: unset !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-right: -1px !important;
  }

  .cancel-button {
    color: #323c47 !important;
    text-align: center !important;
    font-family: 'Open Sans' !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;

    display: flex !important;
    width: 104px !important;
    height: 44px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;

    border-radius: 5px !important;
    border: 1px solid #d6dae4 !important;
    background: #fff !important;

    :hover {
      background-color: #f5f7f9 !important;
    }
  }

  .save-button {
    color: #fff !important;
    text-align: center !important;
    font-family: 'Open Sans' !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;

    display: flex !important;
    width: 102px !important;
    height: 44px !important;
    align-items: center !important;
    gap: 10px !important;

    border-radius: 5px !important;
    border: 1px solid #5158cf !important;
    background: #5158cf !important;

    :hover {
      background: rgba(81, 88, 207, 0.9) !important;
    }
    :disabled {
      border: 1px solid #c4c4c4 !important;
      background: #c4c4c4 !important;
    }
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${props => props.type && getFormatColumnCell(props.type)};
`;

export const FieldWrapper = styled.div`
  position: relative;
  display: block;
`;

export const FieldLabel = styled.div`
  color: #6a778a;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const FieldTextBox = styled.div`
  height: 42px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  border-radius: 4px;
  border: 1px solid #dcdcde;
  background: #fafbfc;
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  b {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
  }
`;

export const ModalTitle = styled.div`
  color: #000;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ResetFilter = styled.button`
  display: inline-block;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  background: transparent url(${CDN_URL}/images/reset_new.svg) no-repeat 0 center;
  background-size: auto;
  border: none;
  cursor: pointer;

  :hover {
    background-image: url(${CDN_URL}/images/reset_new_purple.svg);
    color: #5158cf;
  }

  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const CounterFilters = styled.div`
  right: -11px;
  top: -11px;
  width: 22px;
  height: 22px;
  border: 3px solid #fff;
  position: absolute;
  background: #ffa31a;
  border-radius: 6px;
  font-weight: 700;
  font-size: 10px;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: 'Open Sans';
`;

export const PopupWrapper = styled.div`
  width: 440px;
  max-height: calc(100vh - 310px);
  height: 599px;
  z-index: 999999;
  border-radius: 8px;
  border: 1px solid #d4d7d9;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  display: none;
  position: absolute;
  left: -1px;
  top: calc(100% + 8px);
  ${({ isHideBanner }) =>
    isHideBanner &&
    css`
      max-height: calc(100vh - 129px);
    `}
`;

export const PopupHeader = styled.div`
  padding: 20px 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PopupHeaderTitle = styled.span`
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const PopupBody = styled.div`
  height: calc(100% - 106px);
  overflow-y: auto;

  margin-right: 4px;
  padding-right: 4px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    background: #eaecf0 !important;
  }
`;

export const ClearLabel = styled.span`
  color: #5c5bbd;
  text-align: right;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PopupGroup = styled.div`
  ${props =>
    !props.noBorderBottom &&
    css`
      border-bottom: 1px solid #d4d7d9;
    `}
`;

export const PopupGroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px 15px 20px;

  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;

  img {
    transform: rotate(-90deg);
    &.opened {
      transform: rotate(0);
      transition: rotate 0.2s ease 0s;
    }
  }
`;

export const PopupGroupContent = styled.div`
  transition: rotate 0.2s ease 0s;
  display: none;
  &.expanded {
    display: block;
    padding: 0px 12px 15px 20px;
  }

  &.modality.expanded,
  &.experience_level.expanded,
  &.duration.expanded,
  &.program_length.expanded {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    justify-items: start;
    justify-content: start;
    align-items: start;
    align-content: center;
  }

  &.workout_days_per_week.expanded {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .system-modality:first-child .checkbox-icon {
    top: 14px;
  }

  .evf-checkbox.system-modality,
  .evf-checkbox.system-duration,
  .evf-checkbox.system-program-length {
    color: #111;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 0px 5px 22px;

    input:checked ~ .checkbox-icon {
      background-color: #5e59ff;
      border-color: #5e59ff;
      background-size: 10px;
    }
  }

  .system-experience-level {
    margin: 3px 0px;

    label {
      color: #111 !important;
      font-family: 'Open Sans' !important;
      font-size: 13px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;

      ::before {
        border-width: 2px !important;
      }
    }
  }

  .system-workout-days-per-week {
    cursor: pointer;
    display: flex;
    width: 45px;
    padding: 10px 15px;
    margin: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid #ddd;
    background: #fff;

    color: #141414;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;

    :hover {
      background: #e1e6ef;
    }

    &.selected {
      background: #5158cf;
      border: 1px solid #5158cf;
      color: #fff;
    }
  }
`;

export const PopupFooter = styled.div`
  text-align: right;
  padding: 10px 20px;
  box-shadow: inset 0 1px 1px 0px #d4d7d9;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  button {
    border-radius: 5px;
    background: #5158cf;
    color: #fff;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    cursor: pointer;
    width: 80px;
    height: 32px;
    margin-right: unset !important;
    border: none;
    &:hover {
      background: #5e59ff;
      color: #fff;
    }
    &.disable {
      cursor: not-allowed;
      background: #c4c4c4;
    }
  }
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
  .filter-options {
    .ui.button {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
    }
  }

  &.open {
    ${PopupWrapper} {
      display: block;
    }
  }
`;

export const EmptyWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 34px;

  .empty-label {
    color: #9a9da6;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -1px;
  }
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  .ui.loader {
    z-index: unset !important;
  }
  .ui.loader:before {
    border-color: #fbfbff;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    color: #8c8c8c;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }
`;

export const ModalityWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    display: block;
    margin-left: 8px;
    cursor: pointer;

    path {
      opacity: 0.4;
    }

    &:hover path {
      fill: #000;
      opacity: 1;
    }
  }

  .__react_component_tooltip {
    padding: 15px;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 309px;
  }
`;
