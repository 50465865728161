import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const AddExerciseButton = styled(Button)`
  padding: 6px 20px 6px 35px;
  background-image: url(${CDN_URL}/images/plus_short_thin_purple.svg);
  background-repeat: no-repeat;
  background-position: 20px center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5158cf;
  border-color: #5158cf;

  :hover {
    background-color: #5158cf;
    color: #fff;
    background-image: url(${CDN_URL}/images/plus_short_thin_white.svg);
  }
`;

export const SectionFormat = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  color: #c0c4ca;
  vertical-align: bottom;
`;

export const InstructionContainer = styled.div`
  padding: 10px;
  background-color: #f7f8fc;

  .text-editable__container {
    padding-top: 4px;
    padding-bottom: 4px;

    .text-editable__input {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #202353;

      :empty::before {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #a3a8bc;
        opacity: 1;
      }
    }

    :not(.text-editable__container--active) {
      .text-editable__input {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        box-orient: vertical;
        overflow: hidden;
      }
    }

    :hover:not(.text-editable__container--active) {
      background-color: #e9ecf7;
    }

    &.text-editable__container--active {
      .text-editable__input {
        min-height: 40px;
      }
    }
  }

  &.white,
  &.minimizeOfFreestyle {
    width: calc(100% - 30px);
    margin: auto;
    padding: 0;
    background-color: white;
    box-shadow: 0px 4px 8px #f1f2f6;
    border-radius: 3px;

    :hover {
      .text-editable__container {
        border: 1px solid #5158cf;
        background-color: white;
      }
    }

    .text-editable__container {
      padding: 7px 15px;

      .text-editable__input {
        line-height: 150%;
      }

      :hover:not(.text-editable__container--active) {
        background-color: white;
      }
    }
    &.white.error .text-editable__container {
      border: 1px solid #ea314a;
      background-color: #fef4f6;
    }

    &.minimizeOfFreestyle {
      margin-bottom: 5px;
      border: 1px solid transparent;
      border-radius: 5px;
      background-color: transparent;
      box-shadow: none;

      .text-editable__container {
        padding: 4px;

        &:hover {
          padding: 4px 9px;
          background-color: #f1f1f3;
        }

        &.text-editable__container--active {
          padding: 7px 15px;

          &:hover {
            padding: 7px 15px;
            background-color: white;
          }
        }
      }
    }
  }
`;

export const NoExerciseErrorContianer = styled.div`
  background-color: #f7f8fc;
  padding: 0 15px;

  .workoutError--noExercise {
    margin: 0;
  }
`;

export const NoInstructionsErrorContianer = styled.div`
  background-color: #f7f8fc;
  padding: 0 15px;

  .workoutError--noInstruction {
    margin: 0;
    margin-top: 10px;
  }
`;

export const ExerciseList = styled.div`
  padding: 0 20px 20px;
  position: relative;
`;

export const Content = styled.div`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f7f8fc;
`;

export const Wrapper = styled.div`
  background-color: #ffffff;
  margin-bottom: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;

  .evf-dropdown.actions {
    margin-left: 10px;

    .evf-dropdown__option {
      padding: 0;
      width: 100%;
    }

    .evf-dropdown__menu {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border: 1px solid #dadfea;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .evf-dropdown__trigger {
      width: 24px;
      height: 24px;

      .dots {
        background-color: #758094;
      }

      :hover {
        background-color: #eaebff;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #eaebff;
      }
    }
  }

  &.sectionWrapper {
    &--hidden {
      background-color: #fff;

      ${ExerciseList} {
        padding: 0;
      }

      ${Content} {
        background-color: #ffffff;
      }
    }

    &--linkable {
      margin-bottom: 0;
    }

    &--dragmode {
      // margin-bottom: 0px;
    }

    &--minimize {
      ${ExerciseList} {
        display: none;
      }

      .section_instructionContainer {
        padding-bottom: 4px;
      }
    }

    :not(.sectionWrapper--hidden) {
      border-radius: 5px;
      background-color: #ffffff;
    }

    & .sectionWrapper--freestyle {
      border-radius: 5px;
      background-color: #f7f8fc;
    }
  }

  .section__actions {
    width: 100%;
    padding: 20px;
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .workoutError--invalidSectionValue {
    margin-top: 15px;
    padding: 0 20px;
  }

  .workoutError--sectionTitleIsEmpty {
    margin-top: 15px;
    padding: 0 20px;
  }

  .section__moreExercises {
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    color: #202353;

    .count {
      font-weight: normal;
    }
  }
`;

export const SemiBoldText = styled.span`
  font-weight: 600;
`;

export const ListExercise = styled.div`
  padding: 0 15px;
  margin-top: 25px;
`;

export const AddListExercistButton = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;

  ${SemiBoldText} {
    margin-left: 6px;
  }

  &:hover {
    color: #5158cf;

    svg {
      > path:first-child {
        fill: #5158cf;
      }
    }
  }
`;

export const Text = styled.div`
  color: #758094;
  font-size: 13px;
  line-height: 18px;

  &:hover {
    color: #5158cf;
  }

  &.black {
    color: #202353;

    &:hover {
      color: #202353;
    }
  }

  ${props =>
    props.cursor &&
    css`
      cursor: pointer;
    `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
