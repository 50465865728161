import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px;
  color: #000;

  img.ui {
    margin-right: 6px;
  }
`;

export const Content = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323C47;
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  text-align: right;

  button {
    margin-left: 10px;
  }
`;