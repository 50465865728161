import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal } from 'actions/modal';
import {
  changeQueryParams,
  getContactList,
  approveRequestContactList,
  rejectRequestContactList,
  selectedClientIdContactList,
  selectedClientIdsContactList,
  resetContactListState,
} from 'redux/contact-list/actions';
import ContactView from './component';

const mapState = state => {
  const {
    user,
    router: { location },
    rootReducer: {
      packageDetail,
      contactList: { list, loading, total, queryParam, clientIdsSelected, clientIdSelected },
    },
  } = state;
  return {
    user,
    location,
    list,
    loading,
    total,
    queryParam,
    clientIdSelected,
    clientIdsSelected,
    workingPackage: packageDetail.get('workingPackage').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resetContactListState: bindActionCreators(resetContactListState, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    getContactList: bindActionCreators(getContactList, dispatch),
    approveRequestContactList: bindActionCreators(approveRequestContactList, dispatch),
    rejectRequestContactList: bindActionCreators(rejectRequestContactList, dispatch),
    selectedClientIdContactList: bindActionCreators(selectedClientIdContactList, dispatch),
    selectedClientIdsContactList: bindActionCreators(selectedClientIdsContactList, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(ContactView);
