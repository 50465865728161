import React from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

import * as S from './style';

function RenderOwnerShip({
  ownerValue,
  shareValue,
  hasAvatar,
  hasSpacing,
  onSetShareValue,
  onSetOwnerValue,
  hasYou,
  isEditAble = false,
  isTrainer = false,
  disableOwner = false,
}) {
  const handleChangeShare = (event, data) => {
    let shareVal = shareValue;

    shareVal.share = data.value;

    onSetShareValue && onSetShareValue(shareVal);
  };

  const handleChangeOwner = (event, data) => {
    let ownerVal = cloneDeep(ownerValue);
    let shareVal = cloneDeep(shareValue);

    if (!ownerVal) {
      ownerVal = {};
    }
    ownerVal.owner = data.value;
    if (data.value === 'no_owner') {
      ownerVal.owner = null;
      shareVal.share = TEAM_SHARE_NOOWNER;
    } else if (shareVal.share === TEAM_SHARE_NOOWNER) {
      shareVal.share = TEAM_SHARE_PRIVATE;
    }

    onSetShareValue && onSetShareValue(shareVal);
    onSetOwnerValue && onSetOwnerValue(ownerVal);
  };

  return (
    <S.CustomOwnership
      isEditAble={isEditAble}
      owner={get(ownerValue, 'owner', null)}
      share={get(shareValue, 'share', null)}
      hasAvatar={hasAvatar}
      hasSpacing={hasSpacing}
      handleChangeShare={handleChangeShare}
      handleChangeOwner={handleChangeOwner}
      hasYou={hasYou}
      isTrainer={isTrainer}
      disableOwner={disableOwner}
    />
  );
}

export default RenderOwnerShip;
