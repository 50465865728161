import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Container = styled.div`
  height: 100%;
  width: auto;
  font-size: 13px;
  font-weight: 600;
  position: relative;

  .rdt.rdtOpen {
    width: 100%;
    z-index: 1;

    .form-control {
      display: none;
    }
  }

  .rdtPicker {
    position: relative;
    width: 100%;
    min-width: 236px;
    font-family: 'Open Sans';
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    border: none;
    margin-top: 0;
    height: 319px;

    table {
      border: unset;
      border-collapse: collapse;
      border-spacing: 0;
    }

    td.rdtActive:hover {
      color: #202353;
    }

    table {
      thead {
        tr th.dow {
          padding-left: 5px;
          padding-top: 5px;
        }

        tr:first-child {
          th {
            height: 28px;
            position: relative;
            font-size: 13px;
            font-weight: 600;
            color: #202353;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            border: none;

            :hover {
              background: transparent;
              color: #745ee6;
            }

            &:nth-child(2) {
              bottom: 4px;
              top: 0;
            }

            &.rdtPrev,
            &.rdtNext {
              span {
                text-indent: -9999px;
                display: flex;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: auto;
                position: absolute;
                left: 10px;
                top: 2.5px;
                width: 15px;
                height: 15px;
                opacity: 1;
                width: 20px;
                height: 20px;
                color: #202353;

                :hover {
                  background-color: #f0f1ff;
                  border-radius: 5px;
                  color: #745ee6;
                }
              }
            }
            &.rdtPrev {
              span {
                background-image: url(${CDN_URL}/images/cal_prev.svg);
              }
              &:hover span {
                background-image: url(${CDN_URL}/images/cal_prev_active.svg);
              }
            }

            &.rdtNext {
              span {
                left: unset;
                right: 8px;
                background-image: url(${CDN_URL}/images/cal_next.svg);
              }
              &:hover span {
                background-image: url(${CDN_URL}/images/cal_next_active.svg);
              }
            }
          }
        }
        tr:nth-child(2) {
          height: 43px;
          th {
            border: none;
          }
        }
        ${({ dayIndex }) =>
          dayIndex &&
          css`
            tr:last-child th:nth-child(${dayIndex}) {
              color: #5158cf;
            }
          `}
      }
      tbody {
        position: relative;
        top: 4px;
        opacity: 0;
      }
    }

    span.meal-name-wrapper {
      width: 252px;
      height: 16px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      z-index: 3;
      padding: 1px 10px;
      border-radius: 3px;
      user-select: none;
      pointer-events: none;
      display: flex;
      align-items: center;

      .meal-name {
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        text-align: left;
        color: #7b7e91;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    .rdtDay {
      color: #202353;
      width: 36px;
      height: 40px;
      padding: 0;
      background-color: transparent;
      border-radius: 5px;
      text-shadow: none;

      .__react_component_tooltip.app-tooltip {
        background-color: #222;

        &.place-top:after {
          border-top-width: 6px !important;
          bottom: -7px;
        }
      }

      &.hoveringMealName:hover {
        background-color: transparent;
      }

      &:hover:not(&.hoveringMealName) {
        background-color: #f0f1ff;

        .content {
          border: 1px solid #5158cf;
          z-index: 2;
        }
      }

      &.rdtActive:hover {
        background-color: unset;
        text-shadow: unset;
      }

      &.rdtToday {
        &::before {
          border-left: unset;
          border-bottom: unset;
        }
      }
      &.rdtTodayTz {
        .day {
          border-radius: 50%;
          color: #5158cf;
        }
      }

      &.daySelected {
        background-color: #f0f1ff;
        .content {
          border: 1px solid transparent;
          z-index: 2;
        }
        .day {
          background-color: transparent;
          border-radius: 50%;
        }
      }

      &.rdtOld,
      &.rdtNew {
        color: #a3a3b5;
      }

      .content {
        position: relative;
        width: 36px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 5px;

        .day {
          width: fit-content;
          height: 20px;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: 1px;
          font-size: 11px;

          .day-recipe {
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #65cc9c;
            right: calc(100% + 3px);
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .dow {
      font-size: 11px;
      font-weight: 600;
      line-height: 10px;
      text-transform: uppercase;
      color: #7b7e91;
    }

    .rdtDays {
      overflow: initial;
      border-radius: 10px;
    }

    .rdtDays,
    .rdtMonths,
    .rdtYears {
      position: relative;
    }

    .rdtSwitch {
      pointer-events: none;
    }
  }
`;

export const UpcomingEvent = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(179.97deg, rgba(251, 251, 251, 0) 0%, rgba(251, 251, 251, 0.8) 5.21%, #fbfbfb 123.95%);

  p {
    padding: 10px 15px;
    color: #a3a3b5;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin: auto;
    background-color: #fbfbfbcc;
  }
`;
