import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import { ReactComponent as SchedulePostImg } from 'assets/icons/forum-timeline-schedule-post.svg';

export const Top = styled.div`
  padding: 20px 22px;
  background: radial-gradient(85.05% 85.05% at 36.25% 14.95%, #fcfcfc 0%, #e7edfc 100%), #f7f7f7;
`;

export const BodyArea = styled.div`
  background: white;
  padding: 20px 22px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #202353;
  margin-bottom: 5px;
`;

export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-bottom: 20px;
`;

export const UpgradePath = styled.div`
  width: 303px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  overflow: hidden;
`;

export const UpgradePathImage = styled.img`
  width: 100%;
`;

export const ConnectToAutoflowBtn = styled(Button)`
  color: #5158cf;

  button {
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 38px;
    span {
      text-transform: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

// Schedule Picker part
export const SchedulePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 48px;
`;

export const ScheduleLabel = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  margin-bottom: 2px;
`;

export const ScheduleTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  margin-bottom: 18px;
  white-space: nowrap;
`;

export const ScheduleSubTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.7;
  margin-bottom: 2px;
`;

export const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .rdt.new-ui {
    min-width: 322px;

    thead tr th.dow {
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 100%;
      color: #202353;
      height: 33px;
      padding-bottom: 20px;
    }

    thead tr:first-child {
      th {
        padding-bottom: 30px;
      }
    }

    tbody {
      tr:last-child {
        .rdtNew {
          display: none;
        }
      }
    }

    .rdtPicker {
      width: 339px;
      padding: 27px;
      padding-bottom: 25px;
      border: 1px solid #eceef4;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      bottom: calc(100% + 8px);

      .rdtSwitch {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px !important;
        line-height: 19px !important;
        color: #202353 !important;

        :hover {
          color: #7470ef !important;
        }
      }

      .rdtPrev {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/schedule-calendar-change-month.svg);
          position: absolute;
          top: 30px;
          left: 30px;
        }
        &.disabledBtnPrev {
          pointer-events: none;
          opacity: 0.3;
        }
      }

      .rdtNext {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/schedule-calendar-change-month.svg);
          display: inline-block;
          transform: rotate(180deg);
          position: absolute;
          top: 30px;
          right: 30px;
        }
        &.disabledBtnNext {
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }

    .rdtDay {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 13px;
      line-height: 100%;
      color: #202353;
      padding: 10px 10px 14px;

      ::before {
        top: 0 !important;
        left: 50% !important;
        width: 33px !important;
        height: 33px !important;
      }

      &.rdtToday {
        font-weight: 700;
        color: #5158cf;
      }

      &.rdtOld {
        visibility: hidden !important;
      }
      &.rdtNew {
        visibility: hidden !important;
      }
      &.rdtActive {
        font-weight: 600;
        color: #fff;

        ::before {
          background: #5158cf !important;
        }
      }

      &.rdtDisabled {
        font-weight: 400 !important;
        color: #99a1b1 !important;
      }
    }

    .rdtPicker tr:first-child:has(.rdtOld:last-child) {
      display: none !important;
    }
  }

  .custom-date-input {
    background: #fafbfc;
    min-width: 339px;
    padding: 8px 9px;

    span {
      line-height: 18px;
    }
  }

  .error {
    .custom-date-input {
      border-color: #ea314a;
    }
  }

  @media screen and (min-width: 1441px) and (min-height: 790px) {
    .rdt {
      .rdtPicker {
        bottom: calc(100% + 4px);
      }
    }
  }
`;

export const HourPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  .rootOptions {
    .common-select-container__control {
      padding: 5px 0px;
    }
    .common-select-container__menu {
      padding-bottom: 0 !important;
      z-index: 9999;
    }
    .common-select-container__menu-list {
      max-height: 208px;
      max-width: 99px;
    }
  }

  .dropdown--hour-picker .common-select-container__control {
    min-height: 36px;
    width: 99px;

    :hover,
    :focus {
      border-color: #5158cf;
    }

    .common-select-container__indicators i {
      color: #a1a1a5;
      margin: 1px 8px 0 0;
    }
  }

  .dropdown--hour-picker .common-select-container__option--is-disabled {
    cursor: not-allowed;
  }

  .dropdown--hour-picker .common-select-container__option {
    font-size: 13px;
    line-height: 18px;
    padding: 8px 0px 8px 20px;
  }
`;

export const SetTimeWrapper = styled.div`
  .custom-reminder {
    margin-left: 0;
    height: 36px;

    ${props =>
      props.disabled &&
      css`
        .evfSelectBox__control {
          pointer-events: none;
        }
      `}

    .evfSelectBox__menu {
      margin-bottom: 4px;
    }
  }
`;

export const ScheduleModal = styled(Modal)`
  &.ui.tiny.modal {
    background: transparent;
    box-shadow: none;
    width: unset;
    margin: auto;
  }

  .close-button img {
    width: 20px;
    height: 20px;
  }
`;

export const ScheduleModalContainer = styled.div`
  display: flex;
`;

export const ScheduleMainWrapper = styled.div`
  position: relative;
  background: #fff;
  border-radius: 8px;
  width: 498px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content {
    padding: 24px 24px;
    display: block;
    width: 100%;
    font-size: 1em;
    line-height: 1.4;
    flex: 1 1;
    overflow: visible;
    .schedule-post-button {
      display: flex;
      justify-content: flex-end;
    }
  }

  .modal__header {
    flex: 0 0 auto;
  }
`;

export const SchedulePostWrapper = styled.div`
  width: 303px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  background: #ffffff;
  overflow: hidden;

  .schedule-post-content {
    padding: 25px 20px 25px 104px;
    position: relative;
  }

  .button--close-banner {
    width: 16px;
    height: 16px;
    float: right;
    margin-top: -9px;
    margin-right: -4px;
    cursor: pointer;
  }
`;

export const SchedulePostImage = styled(SchedulePostImg)`
  position: absolute;
  left: 0px;
  top: ${({ createPost }) => `${createPost ? '-2px' : '7px'}}`};
`;

export const SchedulePostContainer = styled.div`
  .schedulePostDetail {
    margin-top: 15px;
    width: 303px !important;
    border-radius: 5px !important;
    border-bottom-left-radius: 5px;
  }
`;

export const SaveButton = styled(Button)`
  height: 34px;
  width: 86px;
  padding: 7px 30px;
  margin-left: 10px;

  &:hover,
  &:active,
  &:focus {
    background: #5559ff;
  }
`;

export const CancelButton = styled(Button)`
  height: 34px;
  width: 102px;
  padding: 7px 30px;
`;

export const RemoveButton = styled(Button)`
  width: 111px;
  height: 34px;
  padding: 7px 30px;
  background: #ea314a;
  margin-right: auto;
  color: #fff;
  &:hover {
    opacity: 90%;
    background: #ea314a;
  }
`;
