import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import SearchClients from './SearchClients';
import SelectedClients from './SelectedClients';
import ReactTooltip from 'react-tooltip';

class AddingClient extends React.PureComponent {
  render() {
    if (!this.props.workingStudio) {
      return null;
    }

    return (
      <S.Wrapper>
        <div className="addingClient__heading">
          Available for
          {this.props.user.is_hide_guide_studio_program && (
            <S.InfoTooltipIcon data-tip data-for="available-for-tooltip">
              <ReactTooltip id="available-for-tooltip" effect="solid" trigger="click" place={'top'} delayShow={300}>
                Once the program is published, your clients can start and stop the program anytime. And if you need to
                update the program, edits only go live after you publish changes.
              </ReactTooltip>
            </S.InfoTooltipIcon>
          )}
        </div>
        <div className="addingClient__body">
          <div className="addingClient__search">
            <SearchClients />
          </div>
          <div className="addingClient__selectedClient">
            <SelectedClients />
          </div>
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateTopProps = state => {
  return {
    workingStudio: state.rootReducer.studioProgram.workingStudio,
    user: state.user,
  };
};

export default connect(mapStateTopProps)(AddingClient);
