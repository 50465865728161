// libs
import React, { useMemo } from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { components } from 'react-select';
import get from 'lodash/get';

// utils
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

const CustomOption = props => {
  const { data } = props;
  const { cloudfrontList } = get(props, 'selectProps', {});
  const { author, is_none_option, name, is_default_team, is_my_default } = data || {};
  const { _id, full_name, first_name, last_name } = author || {};

  const avatar = useMemo(() => get(author, 'avatar'), [props]);
  const color = useMemo(() => get(author, 'color'), [props]);

  const fullName = full_name || `${first_name || ''} ${last_name || ''}`.trim();

  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        onClick: e => {
          e.stopPropagation();
          e.preventDefault();
        },
        onMouseMove: e => {
          e.stopPropagation();
          e.preventDefault();
        },
        onMouseOver: e => {
          e.stopPropagation();
          e.preventDefault();
        },
      }}
    >
      <div className="custom-option-wrapper" onClick={() => props.selectOption(data)}>
        <div className="wrapper-title">
          <div className="name">{name}</div>
          {(is_default_team || is_my_default) && (
            <div className="name-tag">
              {is_default_team ? 'Workspace Default' : is_my_default ? 'Your Default' : ''}
            </div>
          )}
        </div>
        {!is_none_option && (
          <>
            <div data-tip data-for={`coach-${_id}`}>
              <Avatar
                name={getUserShortName(author)}
                className="avatar"
                size={20}
                color={color}
                src={convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true)}
              />
            </div>
            <ReactTooltip className="app-tooltip set-as-default-tooltip" id={`coach-${_id}`} effect="solid" place="top">
              <p>{fullName}</p>
            </ReactTooltip>
          </>
        )}
      </div>
    </components.Option>
  );
};

export default CustomOption;
