import React from 'react';

import { ReactComponent as PollIcon } from 'assets/icons/poll_forum_post_icon.svg';
import * as S from './style';
import ReactTooltip from 'react-tooltip';

function PollPostTrigger(props) {
  const { hasTooltip = false } = props;
  return (
    <S.PollPostTrigger {...props} data-for="poll-tooltip" data-tip hasTooltip={hasTooltip}>
      <PollIcon />
      {hasTooltip && (
        <ReactTooltip id="poll-tooltip" className="poll-tooltip" place="top" effect="solid">
          Poll
        </ReactTooltip>
      )}
    </S.PollPostTrigger>
  );
}

export default PollPostTrigger;
