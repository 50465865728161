import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import * as S from './style';
import { PanelHeader, PanelContent, PanelFooter, ActionButton } from '../style';
import FullBillingHistory from '../FullBIllingHistory';
import { ReactComponent as PayIcon } from 'assets/icons/billing_pay_now_icon.svg';
import ConfirmPayAnInvoiceModal from '../ConfirmPayAnInvoiceModal';

function CurrentPlan(props) {
  const {
    historyListData,
    automation_meter_addons_overdue_invoice,
    zapier_meter_addons_overdue_invoice,
    invoice_over_due,
    toggleSecondModal,
  } = props;

  const onViewHistoryClick = () => {
    props.toggleModal(
      true,
      <FullBillingHistory
        toggleSecondModal={toggleSecondModal}
        automation_meter_addons_overdue_invoice={automation_meter_addons_overdue_invoice}
        zapier_meter_addons_overdue_invoice={zapier_meter_addons_overdue_invoice}
        invoice_over_due={invoice_over_due}
        onClose={() => props.toggleModal(false)}
      />,
    );
  };

  const onPayAnInvoice = data => {
    props.toggleModal(true, <ConfirmPayAnInvoiceModal billingInfo={data} onClose={() => props.toggleModal(false)} />);
  };

  const unpaidInvoice = [
    automation_meter_addons_overdue_invoice,
    zapier_meter_addons_overdue_invoice,
    invoice_over_due,
  ];

  return (
    <S.CurrentPlanWrapper>
      <PanelHeader>
        <div>Bill History</div>
      </PanelHeader>
      <PanelContent className={!historyListData.length ? 'content--no-data' : ''}>
        {historyListData.length ? (
          <S.List>
            {_.map(historyListData, (data, index) => (
              <S.HistoryItem key={index}>
                <div className="time">{moment.unix(data.created).format('MMM D, YYYY')}</div>
                <S.RightColumns>
                  {data.status !== 'paid' && unpaidInvoice.includes(data.id) ? (
                    <S.PayButton onClick={() => onPayAnInvoice(data)}>
                      <PayIcon />
                      Pay Now
                    </S.PayButton>
                  ) : null}
                  {data.status !== 'paid' ? <S.PendingStatus>Unpaid</S.PendingStatus> : null}
                  <div className="amount">{(Number(data.amount_due) || 0).toUSDFormat()}</div>
                </S.RightColumns>
              </S.HistoryItem>
            ))}
          </S.List>
        ) : (
          <S.NoBillYet>
            <span>No bills yet</span>
          </S.NoBillYet>
        )}
      </PanelContent>
      <PanelFooter>
        <ActionButton data-btn="view-full-history" onClick={onViewHistoryClick}>
          View Full History
        </ActionButton>
      </PanelFooter>
    </S.CurrentPlanWrapper>
  );
}

export default CurrentPlan;
