import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const ContentPublishChange = styled.div`
  ${baseText}
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 25px 0;
  .content-publish {
    margin-top: 16px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f0f2;
    display: flex;
    align-items: center;
    .evf-checkbox {
      padding-left: 30px;
      .checkbox-icon {
        height: 20px;
        width: 20px;
        transform: translateY(calc(-50% + -5px));
        background-size: 10px;
        border: 1px solid #e1e1ea;
        border-radius: 4px;
        &:hover {
          background-color: #f0f1ff;
        }
      }
      input:checked ~ .checkbox-icon {
        border-color: #5158cf;
        &:hover {
          background-color: #5158cf;
        }
      }
    }
  }
`;

export const Title = styled.div`
  ${baseText}
  font-size: 21px;
  line-height: 32px;
`;

export const PublishChange = styled(Modal)`
  &.ui.modal {
    width: 467px;
    .close-button {
      right: -9px;
      top: -9px;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .header {
      padding: 15px;
    }
    > :first-child {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
    > :last-child {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      padding: 0px 30px 30px;
    }
    > .content {
      padding: 0 30px;
    }
    > .actions {
      border-top: none;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

export const BtnAction = styled.button`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  outline: none;
  border: 1px solid #e1e1ea;
  width: 89px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  &.btn-cancel {
    &:hover {
      background-color: #f5f7f9;
    }
  }
  &.btn-publish {
    border: none;
    margin-left: 8px;
    width: 93px;
    color: #fff;
    background-color: rgba(81, 88, 207, 1);
    &:hover {
      background-color: rgba(81, 88, 207, 0.9);
    }
  }
`;
