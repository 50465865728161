import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import animateScrollTo from 'animated-scroll-to';

import SectionItem from './SectionItem';

import { ReactComponent as NoResultFound } from 'assets/icons/workout_builder_no_result_arrangement.svg';

import * as S from './style';
import { handleDragExercise, handleDragSection, setDragItem } from 'redux/workout-builder/actions';

class WorkoutArrangement extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseUp = e => {
    const dataset = _.get(e, 'target.dataset', {});
    if (this.props.draggingItem && !dataset.arrangement) {
      this.props.setDragItem(null);
    }
  };

  onMouseUpBottomSection = e => {
    const { draggingItem, selectedWorkout } = this.props;
    const sections = selectedWorkout.get('sections').toJS();

    if (draggingItem) {
      e.stopPropagation();
      if (draggingItem.get('dragType') === 'exercise') {
        this.props.handleDragExercise({
          dropType: 'section',
          sectionId: _.last(sections),
          index: sections.length,
        });
      } else {
        this.props.handleDragSection({
          dropType: 'section',
          sectionId: _.last(sections),
          index: sections.length,
        });
      }
    }
  };

  onMouseUpTopSection = e => {
    const { draggingItem, selectedWorkout } = this.props;
    const sections = selectedWorkout.get('sections').toJS();

    if (draggingItem) {
      e.stopPropagation();
      if (draggingItem.get('dragType') === 'exercise') {
        this.props.handleDragExercise({
          dropType: 'section',
          sectionId: sections[0],
          index: 0,
        });
      } else {
        this.props.handleDragSection({
          dropType: 'section',
          sectionId: sections[0],
          index: 0,
        });
      }
    }
  };

  scrollToBottom = () => {
    if (this.ref.current) {
      animateScrollTo(this.ref.current.scrollHeight, {
        minDuration: 250,
        speed: 10000,
        elementToScroll: this.ref.current,
      }).then(() => {});
    }
  };

  scrollToTop = () => {
    if (this.ref.current) {
      animateScrollTo(0, {
        minDuration: 250,
        speed: 10000,
        elementToScroll: this.ref.current,
      }).then(() => {});
    }
  };

  cancelScroll = () => {
    if (this.ref.current) {
      animateScrollTo(this.ref.current.scrollTop, {
        minDuration: 250,
        speed: 10000,
        elementToScroll: this.ref.current,
      }).then(() => {});
    }
  };

  render() {
    const selectedWorkout = this.props.selectedWorkout.toJS();
    const sections = _.get(selectedWorkout, 'sections', []);
    const isDragMode = !!this.props.draggingItem;
    return (
      <S.Wrapper className="workoutBuilder__workoutArrangement">
        {!!this.props.draggingItem && (
          <S.ScrollBottomArea onMouseOver={this.scrollToTop} onMouseLeave={this.cancelScroll} top />
        )}
        <S.Heading>Workout Arrangement</S.Heading>
        <S.SectionList ref={this.ref}>
          <S.TopSection isDragMode={isDragMode} onMouseUp={this.onMouseUpTopSection} />
          {sections.map((sectionId, index) => (
            <SectionItem key={sectionId} sectionId={sectionId} index={index} />
          ))}
          {!sections.length && (
            <div className="no-section">
              <NoResultFound />
              <p>Drag and move exercises to rearrange</p>
            </div>
          )}
          <S.BottomSection
            isDragMode={isDragMode}
            className="arrangement__bottom-section"
            onMouseUp={this.onMouseUpBottomSection}
          />
        </S.SectionList>
        {isDragMode && <S.ScrollBottomArea onMouseOver={this.scrollToBottom} onMouseLeave={this.cancelScroll} />}
      </S.Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    draggingItem: state.rootReducer.workoutBuilder.get('draggingItem'),
    selectedWorkout: state.rootReducer.workoutBuilder.getIn(['selectedWorkout']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDragItem: bindActionCreators(setDragItem, dispatch),
    handleDragExercise: bindActionCreators(handleDragExercise, dispatch),
    handleDragSection: bindActionCreators(handleDragSection, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutArrangement);
