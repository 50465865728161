import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import classNames from 'classnames';

const Wrapper = styled.div`
  &.collapse {
    margin-bottom: 0;
  }
  &.collapse.collapse-init {
    width: 100%;
  }

  .nameInput__editor {
    padding: 4px 5px;
    width: 100%;
    max-width: 260px;
    border-radius: 5px;
    border-radius: 3px;
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #202353;
    outline: none;
    background: transparent;
    &.new_section {
      max-width: 100%;
      height: 35px;
      padding: 10px;
      background: #f1f1f3;
      font-weight: normal;
    }

    :hover {
      background: #f1f1f3;
    }

    :focus {
      border: 1px solid #5158cf;
      background: #ffffff;
    }

    ${props =>
      props.hasError &&
      css`
        background: #fef4f6 !important;
        border: 1px solid #ea314a !important;
      `}

    :disabled {
      cursor: not-allowed;
    }
    &.disabled {
      cursor: not-allowed;
      background: transparent !important;
    }
  }

  .nameInput__warning {
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #ec4960;
    margin-left: 10px;
  }

  div.nameInput__editor {
    width: fit-content;
    min-width: 250px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.5px;
    &:hover {
    }
    &.new_section_wrapper {
      height: 35px;
      padding: 8px 10px;
      width: 100%;
      background: #f1f1f3;
      border-radius: 5px;
    }
    span {
      font-weight: 400;
    }
  }
`;

const limitLength = 25;

function SectionNameInput(props) {
  const { disabled = false } = props;
  const [name, setName] = useState(props.name || '');
  const [isInit, setInit] = useState(props.isNewSection);
  const [showError, setShowError] = useState(false);
  const [focus, setFocus] = useState(props.isNewSection);

  const onChange = e => {
    if (isInit) {
      setInit(false);
    }
    const { value } = e.target;
    if (value.length <= limitLength) {
      setName(value);
      value !== name && !props.isNewSection && props.onConfirmChange(value);
    } else {
      setName(_.truncate(value, { length: limitLength, omission: '' }));
      flashError();
    }
  };

  const flashError = _.debounce(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 200);
  }, 300);

  const onBlur = () => {
    props.onConfirmChange(name);
    if (name) {
      setFocus(false);
    }
  };

  const onKeyPress = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  const handleOnMouseOut = event => {
    !props.isNewSection && props.onConfirmChange(name);
  };

  const handleStopBoilEvent = event => {
    event.stopPropagation();
  };

  return (
    <Wrapper
      hasError={(!name && !isInit) || showError}
      className={`${props.isCollapse && 'collapse'} ${props.isNewSection && 'collapse-init'}`}
      onClick={handleStopBoilEvent}
    >
      {focus ? (
        <>
          <input
            value={name}
            onChange={onChange}
            placeholder={isInit ? 'New Section Name' : 'Add Section Name'}
            onBlur={onBlur}
            className={classNames('nameInput__editor', {
              new_section: props.isNewSection,
              disabled,
            })}
            onKeyPress={onKeyPress}
            autoFocus
            onMouseOut={handleOnMouseOut}
            disabled={disabled}
          />
          {name.length > 20 && (
            <span className="nameInput__warning">
              {' '}
              {name.length}/{limitLength}
            </span>
          )}
        </>
      ) : (
        <div
          onClick={() => !disabled && setFocus(true)}
          className={classNames('nameInput__editor', {
            new_section_wrapper: props.isNewSection,
            disabled,
          })}
        >
          {name}
          <span> ({props.counter})</span>
        </div>
      )}
    </Wrapper>
  );
}

export default SectionNameInput;
