export const LIST_SECTION_FORMAT = [
  {
    key: 'regular',
    title: 'Regular',
    desc: 'Exercise by exercise, mostly used for strength workouts',
  },
  {
    key: 'interval',
    title: 'Interval',
    desc: 'Runs built-in timer for exercise and rest (HIIT, Tabata, Circuit)',
  },
  {
    key: 'amrap',
    title: 'AMRAP',
    desc: 'Track total rounds completed based on time assigned',
  },
  {
    key: 'timed',
    title: 'Timed',
    desc: 'Track total duration based on rounds assigned',
  },
  {
    key: 'freestyle',
    title: 'Freestyle',
    desc: 'Best for warmups, Crossfit, or any follow-along videos',
  },
];
