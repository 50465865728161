// Libs
import React from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import round from 'lodash/round';

// Utils
import { convertS3UrlToCloudFrontUrl, formatMoney, pluralize } from 'utils/commonFunction';
import { CDN_URL, CURRENCIES, TEXT_CURRENCIES } from 'constants/commonData';

// Assets
import { ReactComponent as Settings } from 'assets/icons/OnboardingFlow/settings.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_circle_grey_16.svg';
import { ReactComponent as MarketPlaceIcon } from 'assets/icons/OnboardingFlow/market_place_icon.svg';

// Styles
import * as S from './style';

const ItemPackage = ({
  item,
  cloudfrontList,
  onRedirectToSettings,
  onRedirectToOverview,
  onRemovePackage,
  isLoadingAction,
}) => {
  const { id, hash_id = '', cover_image = '', name = '', price, status, platforms, is_deleted } = item || {};
  const { amount, currency, type, every, recurring_interval_type, end_after } = price || {};

  const isDraft = status === 'draft';
  const isArchived = status === 'archive';

  const isOnetimeType = type === 'one_time';
  const symbol = TEXT_CURRENCIES.includes(currency)
    ? `${get(CURRENCIES, `${currency}.symbol`)} `
    : get(CURRENCIES, `${currency}.symbol`);
  const money = formatMoney(round(amount, 2), symbol);

  const infoStr = isOnetimeType ? (
    `${money} One time`
  ) : (
    <>
      {money} Every {pluralize(recurring_interval_type, every, true)}
      {!!end_after && <>&nbsp;for {pluralize('time', end_after, true)}</>}
    </>
  );

  return (
    <S.Wrapper isDisabled={isDraft || isArchived || is_deleted}>
      <S.BannerWrapper>
        <img
          src={
            cover_image
              ? convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true)
              : `${CDN_URL}/images/deleted_package_onboarding.png`
          }
          alt={name}
        />
        {(platforms || []).some(item => item === 'marketplace') && (
          <>
            <MarketPlaceIcon
              className="market-place-icon"
              data-tip
              data-for={`market-place-tooltip-${hash_id || id}`}
            />
            <ReactTooltip
              className="app-tooltip market-place-tooltip"
              id={`market-place-tooltip-${hash_id || id}`}
              effect="solid"
              place="top"
            >
              <p>Package from Marketplace</p>
            </ReactTooltip>
          </>
        )}
      </S.BannerWrapper>
      <S.PackageInfoWrapper>
        {(isDraft || isArchived || is_deleted) && (
          <S.PackageStatus>{is_deleted ? 'DELETED' : isDraft ? 'DRAFT' : 'ARCHIVED'}</S.PackageStatus>
        )}
        <S.PackageName onClick={() => onRedirectToOverview(hash_id || id, platforms)}>{name}</S.PackageName>
        <S.PackageInfo>{amount && infoStr}</S.PackageInfo>
      </S.PackageInfoWrapper>
      <S.Actions>
        {isDraft || isArchived || is_deleted ? (
          <>
            <S.SettingsButton
              isDisabled={isLoadingAction}
              onClick={() => {
                if (isLoadingAction) return;
                onRemovePackage(id);
              }}
            >
              <CloseIcon className="custom-settings-icon" />
            </S.SettingsButton>
          </>
        ) : (
          <>
            <S.SettingsButton
              data-tip
              data-for={`settings-${hash_id || id}`}
              onClick={() => onRedirectToSettings(hash_id || id, platforms)}
            >
              <Settings className="custom-settings-icon" />
            </S.SettingsButton>
            <ReactTooltip
              className="app-tooltip settings-tooltip"
              id={`settings-${hash_id || id}`}
              effect="solid"
              place="top"
            >
              <div className="settings-tooltip-wrapper">
                <p className="title">Manage package</p>
                <p className="desc">Manage or remove this package in its package settings page.</p>
              </div>
            </ReactTooltip>
          </>
        )}
      </S.Actions>
    </S.Wrapper>
  );
};

export default ItemPackage;
