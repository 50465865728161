import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { activateClients, successActivateClients } from 'redux/autoflow/client/actions';
import {
  activateClients as activateClientsInterval,
  successActivateClients as successActivateClientsInterval,
} from 'redux/autoflowInterval/client/actions';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { AUTOFLOW_CLIENT_STATUS, AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import { ReactComponent as WarningIcon } from 'assets/icons/warning_yellow.svg';
import { pluralize } from 'utils/commonFunction';

import * as S from './style';

const WarningMessage = ({ message }) => (
  <S.Warning>
    <WarningIcon />
    <span>{message}</span>
  </S.Warning>
);

function ActivateClients(props) {
  const { autoflow, clients, multiple, resumedOn } = props;
  const pausedClients = filter(clients, item => item.status === AUTOFLOW_CLIENT_STATUS.PAUSE);
  const waitToStartClients = filter(clients, item => item.status === AUTOFLOW_CLIENT_STATUS.WAITING_TO_START);
  const client = clients[0];
  const waitToStartClientsGroupBy = groupBy(waitToStartClients, 'started_on');

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/activate.svg`} />
            {props.type !== AUTOFLOW_TYPES.INTERVAL && (
              <span>Activate {multiple ? 'selected clients' : '1 client'}</span>
            )}
            {props.type === AUTOFLOW_TYPES.INTERVAL && (
              <span>{multiple ? 'Start or Resume selected clients' : `Resume Autoflow on Day ${resumedOn}`}</span>
            )}
          </S.Header>
          <S.Body>
            {props.type !== AUTOFLOW_TYPES.INTERVAL && (
              <div>
                Upon activation for{' '}
                <b>
                  {multiple
                    ? `${pausedClients.length} paused client${pausedClients.length !== 1 ? 's' : ''}`
                    : `${client.full_name}`}
                </b>
                , this Autoflow’s workouts, tasks, and auto-messages from today onwards will be added to the calendar of{' '}
                {multiple ? 'each' : 'the'} client. Would you like to continue?
              </div>
            )}
            {props.type === AUTOFLOW_TYPES.INTERVAL && !multiple && (
              <>
                <div>
                  This Autoflow’s workouts, tasks, and auto-messages will be added to the calendar of{' '}
                  <b>
                    {multiple
                      ? `${pausedClients.length} paused client${pausedClients.length !== 1 ? 's' : ''}`
                      : `${client.full_name}`}
                  </b>{' '}
                  to start today as <b>Day {resumedOn}</b> of Autoflow.
                </div>
                <WarningMessage message="As a precaution with timezones, In-app Messages scheduled for today will not be sent. They still start sending tomorrow." />
              </>
            )}
            {props.type === AUTOFLOW_TYPES.INTERVAL && multiple && (
              <div>
                <div>
                  This Autoflow’s workouts, tasks, and auto-messages will be scheduled for the clients starting today.
                </div>
                <div>
                  <ul>
                    {waitToStartClients.length > 0 && (
                      <>
                        <br />
                        <li>
                          {map(waitToStartClientsGroupBy, (listCli, start_on) => {
                            return (
                              <>
                                For the
                                <b>{` ${listCli.length} waiting to start ${pluralize('client', listCli.length)}`}</b>,
                                this Autoflow will start on the <b>Day {start_on}</b>.{' '}
                              </>
                            );
                          })}
                        </li>
                      </>
                    )}
                    {pausedClients.length > 0 && (
                      <>
                        <br />
                        <li>
                          For the
                          <b>{` ${pausedClients.length} paused client${pausedClients.length !== 1 ? 's' : ''}`}</b>,
                          this Autoflow will resume from the day they were paused or have been scheduled to start from.
                        </li>
                      </>
                    )}
                  </ul>
                  <WarningMessage message="As a precaution, In-app Messages scheduled for today will not be sent for the resumed clients" />
                </div>
              </div>
            )}
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              green
              onClick={() => {
                if (props.type === AUTOFLOW_TYPES.INTERVAL) {
                  props.activateClients({ autoflow, clients: map(clients, '_id'), resumed_on: resumedOn }, multiple);
                } else {
                  if (pausedClients.length) {
                    props.activateClients({ autoflow, clients: map(pausedClients, '_id') }, multiple);
                  } else {
                    if (multiple) {
                      props.successActivateClients({ multiple });
                    }
                  }
                }

                props.toggleModal(false);
              }}
            >
              {props.type !== AUTOFLOW_TYPES.INTERVAL && 'Activate'}
              {props.type === AUTOFLOW_TYPES.INTERVAL && !multiple && 'Resume'}
              {props.type === AUTOFLOW_TYPES.INTERVAL && multiple && 'Start / Resume'}
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    autoflow: workingAutoflow && workingAutoflow._id,
  };
};

const mapDispatch = (dispatch, props) => {
  if (props.type === AUTOFLOW_TYPES.INTERVAL)
    return {
      toggleModal: bindActionCreators(toggleModal, dispatch),
      activateClients: bindActionCreators(activateClientsInterval, dispatch),
      successActivateClients: bindActionCreators(successActivateClientsInterval, dispatch),
    };

  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    activateClients: bindActionCreators(activateClients, dispatch),
    successActivateClients: bindActionCreators(successActivateClients, dispatch),
  };
};

export default connect(mapState, mapDispatch)(ActivateClients);
