import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

const limitText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

const testBase = css`
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
`;

export const ContactUsModalWrapper = styled(Modal)`
  background: #ffffff;
  border: 1px solid #d4d4d5;
  box-sizing: border-box;
  border-radius: 8px;

  .contactUsModal__content {
    border-radius: 8px;
    padding: 30px !important;

    .contactUsModal__title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #202353;
    }

    .contactUsModal__form {
      margin-top: 30px;
    }

    .contactUsModal__footer {
      text-align: right;
      button {
        padding: 7px 35px;
      }
    }
  }

  .hub-spot-form {
    margin-top: 30px;

    .hs-form-field {
      margin-bottom: 15px;
      width: 100%;
      font-weight: unset;
      font-family: 'Open Sans';

      label {
        width: 100%;
        display: inline-flex;
        gap: 4px;

        span {
          width: fit-content;
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
          text-transform: uppercase;
          color: #6a778a;
          margin-bottom: 5px;
          ${limitText}
        }

        .hs-form-required {
          width: 3%;
        }
      }

      legend.hs-field-desc {
        font-weight: 600;
        font-size: 9px;
        line-height: 14px;
        color: #969fb5;
        text-align: justify;
        margin-bottom: 5px;
      }

      .input {
        .hs-input {
          ${testBase}
          background: #ffffff;
          width: 100%;
          height: 36px;
          color: #323c47;
          box-sizing: border-box;
          padding: 5px 15px;
          border: 1px solid #dcdcde;
          border-radius: 4px;
          outline: none !important;

          ::placeholder {
            color: #969fb5;
            font-weight: normal;
          }

          &.invalid.error {
            border-color: #ee2525;
          }
        }

        textarea {
          min-height: 60px;
          resize: none;

          :hover:not(:disabled),
          :focus {
            border-color: #5c5bbd;
          }
        }

        input {
          :hover:not(:disabled),
          :focus {
            border-color: #5c5bbd;
          }

          ::-webkit-outer-spin-button,
          ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    .hs_error_rollup,
    .hs-form-field {
      .no-list.hs-error-msgs.inputs-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        label.hs-error-msg,
        label.hs-main-font-element {
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: #ee2525;
          margin-top: 5px;
        }
      }
    }

    .hs_submit {
      text-align: right;

      .actions > input {
        ${testBase}
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #5158cf;
        text-align: center;
        color: #ffffff;
        min-width: 80px;
        padding: 7px 35px;
        border-radius: 5px;
        border: 1px solid transparent;
        white-space: nowrap;
        box-shadow: none !important;
        outline: none !important;
        cursor: pointer;
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  .loading-hubspot {
    padding-top: 30px;

    .ui.loader:before {
      border-color: #ededed !important;
    }

    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }

    span {
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      opacity: 0.6;
    }
  }
`;

export const ToastPaymentChoose = styled.span`
  white-space: pre-wrap;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;
