import get from 'lodash/get';
import React from 'react';
import { NavLink } from 'react-router-dom';
import map from 'lodash/map';

// Utils
import { checkCountryUS } from 'utils/commonFunction';
import { PURCHASE_VALUE } from '../Settings/constants';

// Assets
import { ReactComponent as WarningNote } from 'assets/icons/warning-note.svg';

// Styles
import * as S from './styles';
import connect from 'react-redux/lib/connect/connect';

const NAVS = [
  { label: 'Overview', route: '' },
  { label: 'Analytics', route: 'analytics', isEdit: true },
  { label: 'Waitlist', route: 'waitlist', hide: true, key: PURCHASE_VALUE.WAITLIST },
  { label: 'Intro Call', route: 'intro-call', hide: true, key: PURCHASE_VALUE.CONTACT },
  { label: 'Settings', route: 'settings' },
];

function PackageLayout(props) {
  const { workingPackage, location, rightContent, backgroundColor = null, isMP, payment, isNeedVerify = false } = props;
  const id = workingPackage.get('hash_id') || workingPackage.get('id');
  const path = isMP ? 'marketplace' : 'packages';
  const isUS = checkCountryUS(get(payment, 'account.account.country', ''));
  const isLoadingPaymentAccount = get(payment, 'account.payment_account_fetching', false);
  const data = workingPackage ? workingPackage.toJS() : null;
  const has_setting = get(data, 'package_purchase_option.has_setting', []) || [];
  // const [isResent, setIsResent] = useState(false);

  // const handleResendVerify = event => {
  //   event.preventDefault();
  //   // TODO
  //   !isResent && setIsResent(true);
  // };

  // const renderWarning = () => {
  //   if (isNeedVerify) {
  //     return (
  //       <S.HeaderNote>
  //         <WarningNote />
  //         <S.WarningContent>
  //           You need to verify your email address to publish a package. If you haven't received the email, please check
  //           your Spam folder or{' '}
  //           <S.ResendRequest onClick={handleResendVerify} isResent={isResent}>
  //             request a new one.
  //           </S.ResendRequest>
  //         </S.WarningContent>
  //       </S.HeaderNote>
  //     );
  //   }

  //   if (!isLoadingPaymentAccount && !isUS && isMP) {
  //     return (
  //       <S.HeaderNote>
  //         <WarningNote />
  //         <S.WarningContent>
  //           Currently, publishing marketplace packages is only available for US-based accounts. If your Stripe
  //           connection is not from the US, you won't be able to publish a marketplace package.
  //         </S.WarningContent>
  //       </S.HeaderNote>
  //     );
  //   }

  //   // Add a default return statement or handle other cases if necessary
  //   return null;
  // };

  return (
    <S.Wrapper background={backgroundColor} className="custom-layout-wrapper">
      {!isLoadingPaymentAccount && !isUS && isMP && (
        <S.HeaderNote>
          <WarningNote />
          <span>
            Currently only available for US-based accounts. If your Stripe connection is not from the US, you won't be
            able to publish a marketplace package
          </span>
        </S.HeaderNote>
      )}
      <S.Container className="custom-container">
        <div className="packageDetail__heading">
          <div className="packageDetail__nav-menu">
            {map(NAVS, (nav, index) => {
              if (nav.hide && (!isMP || !has_setting.includes(nav.key))) return null;
              if (!nav.isEdit || id) {
                return (
                  <NavLink
                    key={index}
                    className="packageDetail__nav-menu__item"
                    exact={nav.route === ''}
                    to={`/home/${path}/${id || 'create'}${nav.route ? '/' + nav.route : ''}`}
                    isActive={() => {
                      return location.pathname === `/home/${path}/${id || 'create'}${nav.route ? '/' + nav.route : ''}`;
                    }}
                  >
                    {nav.label}
                  </NavLink>
                );
              }
            })}
          </div>
          <div>{rightContent}</div>
        </div>
        {props.children}
      </S.Container>
    </S.Wrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: { packageDetail, setupPayment },
    router: { location },
  } = state;

  return { workingPackage: packageDetail.get('workingPackage'), location, payment: setupPayment.toJS() };
};

export default connect(mapState, {})(PackageLayout);
