import styled, { css } from 'styled-components';
import FormItem from 'assets/icons/form_doc.svg';
import { FormatLayout } from '../constants';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 60px;
`;

export const MobilePreviewContainer = styled.div`
  padding: 53px 22px 10px;
  position: relative;
  height: 538px;
  width: 273px;
  flex: 0 0 273px;
  overflow: hidden;
  background: #f7f8fc url(${CDN_URL}/images/iphone_layout_dark.svg) no-repeat;
  background-position: top left;
  background-size: 100%;

  .mobilePreview__heading {
    font-weight: bold;
    font-size: 19.6981px;
    line-height: 21px;
    letter-spacing: -0.351752px;
    color: #1e0a3c;
  }
  .mobilePreview__sections {
    overflow: auto;
    height: calc(100% - 70px);
    margin-top: 27px;
    padding-right: 10px;
    margin-right: -10px;
    overflow-x: hidden;
  }
`;

export const SectionMobilePreview = styled.div`
  margin-bottom: 20px;
`;

export const ResourceItemWrapper = styled.div`
  padding: 8px 10px;
  background: #ffffff;
  border-radius: 7.03504px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  box-shadow: 0px 4px 8px rgba(98, 111, 136, 0.1);

  .previewResource {
    &__avatar {
      width: 42px;
      height: 42px;
      border-radius: 7px;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__info {
      font-weight: bold;
      font-size: 11.2561px;
      line-height: 17px;
      color: #1e0a3c;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      overflow: hidden;
      width: calc(100% - 52px);
      white-space: pre-line;
      overflow-wrap: break-word;
    }
  }

  ${props =>
    props.coverImage
      ? css`
          .previewResource__avatar {
            background-image: url(${props.coverImage});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `
      : css`
          .previewResource__avatar {
            background-color: #f7f8fc;
            &.youtube {
              background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
            }

            &.vimeo {
              background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
            }

            &.instagram {
              background-image: url(${CDN_URL}/images/studio_resource_instagram_icon.svg);
            }

            &.facebook {
              background-image: url(${CDN_URL}/images/studio_resource_facebook_icon.svg);
            }

            &.twitter {
              background-image: url(${CDN_URL}/images/studio_resource_twitter_icon.svg);
            }

            &.spotify {
              background-image: url(${CDN_URL}/images/studio_resource_spotify_icon.svg);
            }

            &.link {
              background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
              background-size: 12px;
            }
            &.document {
              background-image: url(${process.env
                .REACT_APP_CDN_URL}/images/studio_resource_document_mobile_icon_light.svg);
            }
            &.form {
              background-image: url(${FormItem});
              background-size: 25px;
            }
          }
        `}
`;

export const PreviewTitle = styled.h4`
  font-weight: bold;
  font-size: 12.6631px;
  line-height: 17px;
  letter-spacing: -0.140701px;
  color: #1e0a3c;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

export const PreviewLargeCardWrapper = styled.div`
  display: flex;
  margin-bottom: 22px;
`;

export const Card = styled.div`
  min-width: 223px;
  max-width: 223px;
  border-radius: 7.03504px;
  height: 119px;
  margin-right: 7px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(98, 111, 136, 0.1);
`;

export const NarrowCard = styled(Card)`
  min-width: 111px;
  max-width: 111px;
  height: 133px;
  box-shadow: 0px 4px 8px rgba(98, 111, 136, 0.1);
`;

export const LargeCardName = styled.p`
  font-weight: bold;
  font-size: 10.5526px;
  line-height: 15px;
  letter-spacing: -0.140701px;
  color: #ffffff;
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: pre-line;
  word-break: break-all;
  max-height: 40px;
  padding: 0 15px 0 15px;
  margin-bottom: ${props => (props.type === 'form' ? '4px' : '12px')} !important;
  text-overflow: ellipsis;
  max-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.isForm {
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.3px;
    color: #fff;
  }
`;

export const LargeCardSubName = styled.p`
  font-weight: 700;
  font-size: 8px;
  line-height: 100%;
  letter-spacing: -0.2px;
  color: #fff;
  overflow-wrap: break-word;
  max-width: 100%;
  white-space: pre-line;
  word-break: break-all;
  max-height: 24px;
  padding: 0 15px 16px 15px;
  text-overflow: ellipsis;
  max-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CardPhoto = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  ${props =>
    props.coverImage
      ? css`
          background-image: url(${props.coverImage});
        `
      : css`
          background-color: #ffff;
          background-size: 28.12px;
          ${LargeCardName},
          ${LargeCardSubName} {
            color: #1e0a3c;

            &.isForm {
              color: #1e0a3c;
            }
          }
          &.youtube {
            background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
          }

          &.vimeo {
            background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
          }

          &.instagram {
            background-image: url(${CDN_URL}/images/studio_resource_instagram_icon.svg);
          }

          &.facebook {
            background-image: url(${CDN_URL}/images/studio_resource_facebook_icon.svg);
          }

          &.twitter {
            background-image: url(${CDN_URL}/images/studio_resource_twitter_icon.svg);
          }

          &.spotify {
            background-image: url(${CDN_URL}/images/studio_resource_spotify_icon.svg);
          }

          &.link {
            background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
          }

          &.document {
            background-image: url(${CDN_URL}/images/studio_resource_document_icon.svg);
          }

          &.form {
            background-image: url(${FormItem});
            background-size: 50px;
            background-position: ${props =>
              props.format === FormatLayout.NARROW
                ? '50% 33%'
                : props.format === FormatLayout.LAGER
                ? '52% 39%'
                : 'center'};
          }
        `}
`;

export const ResourceType = styled.div`
  padding-left: 15px;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  svg path {
    fill: #000;
  }
  ${props =>
    props.coverImage &&
    css`
      color: #fff;
      svg path {
        fill: #fff;
      }
    `}
`;

export const SquareCard = styled.div`
  min-width: 66.44px;
  max-width: 66.44px;
  border-radius: 7.03504px;
  margin-right: 7px;

  ${CardPhoto} {
    height: 66.44px;
    border-radius: 7.03504px;
    margin-bottom: 5px;
    box-shadow: 0px 4px 8px rgba(98, 111, 136, 0.1);
  }
`;

export const SquareName = styled.p`
  display: -webkit-box;
  font-weight: bold;
  font-size: 9.84906px;
  line-height: 14px;
  padding: 0 5px;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  color: #1e0a3c;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ViewMore = styled.span`
  color: #7470ef;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`;

export const ViewName = styled.span`
  white-space: nowrap;
  max-width: 70%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;
