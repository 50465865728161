import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Container, UploadInfo } from './style';
import PropTypes from 'prop-types';
import { CDN_URL, CONVERSION } from 'constants/commonData';
import { axiosInstance } from 'configs/request';
import { parseDurationVideo } from 'utils/commonFunction';
import { ReactComponent as DurationIcon } from 'assets/icons/freestyle-section-duration-icon.svg';
import CircleDownload from 'shared/CircleDownload';

// axios cancel upload
const CancelToken = axios.CancelToken;
let cancelUploadRequest;

const Component = props => {
  const { preview, allowDownload = false } = props;
  const [failed, setFailed] = useState(false);
  const progressBar = useRef();
  const { file, apiConfigs, viewOnly } = props;
  const { size, name, duration } = file;
  const fileSize = Number(size) || 0;
  const kbSize = Math.round(fileSize / CONVERSION.MB_TO_KB);
  const displaySize =
    kbSize < CONVERSION.MB_TO_KB ? `${kbSize} KB` : `${Math.round(fileSize / CONVERSION.MB_TO_BYTE)} MB`;

  const uploadFile = () => {
    axiosInstance({
      ...apiConfigs,
      onUploadProgress: progressData => {
        const { loaded, total } = progressData;
        const progress = Math.min(Math.floor((loaded / total) * 100), 99);

        if (progressBar.current) {
          progressBar.current.style.width = progress + '%';
        }
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelUploadRequest = c;
      }),
    })
      .then(response => {
        const { data } = response.data;
        typeof props.onSuccess === 'function' && props.onSuccess(data);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          error.message = 'Canceled';
          typeof props.onError === 'function' && props.onError(error);
        } else {
          error.message = 'Failed';
          setFailed(true);
        }
      });
  };

  useEffect(() => {
    !props.viewMode && uploadFile();
  }, [file]);

  const onCancel = () => {
    if (typeof cancelUploadRequest === 'function') {
      cancelUploadRequest();
    }

    typeof props.onCancel === 'function' && props.onCancel();
  };

  const handleDownload = () => {
    props.onDownload && props.onDownload();
  };

  return (
    <Container className={`media-upload-progress${failed ? ' failed' : ''}`}>
      {preview ? preview : <img src={props.fileIcon || `${CDN_URL}/images/media_upload.svg`} className="icon" alt="" />}
      <UploadInfo className="upload-info" noSize={!size}>
        <div className="upload__file">
          <div className="file__name">{name}</div>
          <div className="file__size">
            {failed ? (
              <div
                className="try-again"
                onClick={() => {
                  setFailed(false);
                  progressBar.current.style.width = '0px';
                  uploadFile();
                }}
              >
                <img src={`${CDN_URL}/images/reup.svg`} alt="" />
                <span>&nbsp;Try again</span>
              </div>
            ) : null}
            {!failed && !props.viewMode && <span>{displaySize}</span>}
            {viewOnly ? null : (
              <svg className="file__cancel" width="10" height="10" viewBox="0 0 10 10" fill="none" onClick={onCancel}>
                <path
                  d="M9.125 1.125L0.875 9.375"
                  stroke="#111111"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.875 1.125L9.125 9.375"
                  stroke="#111111"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
        <div className="failed_message">Failed to upload file</div>
        <div className="download-file-container">
          {!props.viewMode ? (
            <div className="upload__progress">
              <div className="upload__progress_bar" ref={progressBar} />
            </div>
          ) : size ? (
            <div className="file__size">
              {duration ? (
                <div className="duration-text">
                  <DurationIcon /> <p>{parseDurationVideo(duration)}</p>
                </div>
              ) : (
                displaySize
              )}
            </div>
          ) : null}
          {!failed && allowDownload && (
            <div onClick={handleDownload} className="download-file-btn">
              <CircleDownload />
            </div>
          )}
        </div>
      </UploadInfo>
    </Container>
  );
};

Component.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onCancel: PropTypes.func,
  viewMode: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  apiConfigs: PropTypes.shape({
    url: PropTypes.string,
    method: PropTypes.string,
    data: PropTypes.object,
  }),
};

export default Component;
