// Libs
import React, { useMemo, useRef, useState, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

// Utils
import { formatShortLink, generateVimeoAPIUrl } from 'utils/commonFunction';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import { autoAddHttps, validateLink, validateVimeoUrl, validateYouTubeUrl, getLinkType } from 'utils/validations';

// Components
import MediaModal from 'shared/MediaModal';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/close_bold_circle.svg';
import DefaultImage from 'assets/icons/MealPlans/thumbnail_default_link.svg';

// Styles
import * as S from './style';

export const ERROR_MESSAGE = {
  INVALID_VIDEO_LINK: 'Please enter a valid Youtube or Vimeo URL',
};

const VideoLink = props => {
  const {
    label = 'Video',
    placeholder = 'Vimeo or Youtube Link',
    link = '',
    thumbnail = '',
    name = '',
    attachment_type = '',
    isEditMode,
    onChangeLink = () => {},
    updateDataDetailRecipe = () => {},
    error = {},
    updateLoadingVideoInstruction = () => {},
    loadingVideoInstruction = false,
  } = props;

  const [linkVideoData, setLinkVideoData] = useState(link);
  const [linkVideoThumbnail, setLinkVideoThumbnail] = useState(thumbnail);
  const [videoName, setVideoName] = useState(name);
  const [errorMessage, setErrorMessage] = useState(() => {
    const { intro_instruction } = error;
    if (intro_instruction) {
      return ERROR_MESSAGE.INVALID_VIDEO_LINK;
    } else {
      return '';
    }
  });
  const [typeLink, setTypeLink] = useState(attachment_type);
  const linkRef = useRef('');

  useEffect(() => {
    setLinkVideoData(link);
    setLinkVideoThumbnail(thumbnail);
  }, [link, thumbnail]);

  const isPreviewUrl = useMemo(() => !isEmpty(linkVideoData) && !isEmpty(linkVideoThumbnail), [
    linkVideoData,
    linkVideoThumbnail,
  ]);

  const handleChangeVideoLink = event => {
    event.preventDefault();
    const value = get(event, 'target.value', '');
    if (isEmpty(value.trim())) {
      setLinkVideoData('');
      setLinkVideoThumbnail('');
      setErrorMessage('');
      return;
    }
    const trimmedValue = autoAddHttps(value.trim());

    if (trimmedValue) {
      const link_type = getLinkType(trimmedValue);
      setTypeLink(link_type);
      if (!validateLink(trimmedValue)) {
        setLinkVideoData(trimmedValue.replace('https://', ''));
        setLinkVideoThumbnail('');
        setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
        return;
      }
      if (validateVimeoUrl(trimmedValue)) {
        debounce(getPageMetaDataFromURLVimeo, 300)(trimmedValue);
        return;
      } else {
        const youtubeID = validateYouTubeUrl(trimmedValue);
        if (isEmpty(youtubeID)) {
          setLinkVideoData(trimmedValue.replace('https://', ''));
          setLinkVideoThumbnail('');
          setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
          return;
        }
        debounce(getPageMetaDataFromURLYoutube, 300)(trimmedValue);
      }
    }
  };

  const getPageMetaDataFromURLVimeo = url => {
    updateLoadingVideoInstruction(true);
    axios
      .get(generateVimeoAPIUrl(url))
      .then(response => {
        updateDataDetailRecipe({
          error: omit(error, ['intro_instruction']),
        });
        const {
          data: { thumbnail_url, title },
        } = response;
        setLinkVideoData(url);
        setLinkVideoThumbnail(thumbnail_url || DefaultImage);
        setVideoName(title);
        setErrorMessage('');
        linkRef.current.value = formatShortLink(url);
      })
      .catch(() => {
        setLinkVideoData(url);
        setLinkVideoThumbnail(DefaultImage);
        setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
      })
      .finally(() => {
        updateLoadingVideoInstruction(false);
      });
  };

  const getPageMetaDataFromURLYoutube = url => {
    updateLoadingVideoInstruction(true);
    getPageMetaDataFromURL(url)
      .then(response => {
        updateDataDetailRecipe({
          error: omit(error, ['intro_instruction']),
        });
        const {
          data: {
            data: { image, title },
          },
        } = response;
        setLinkVideoData(url);
        setLinkVideoThumbnail(image || DefaultImage);
        setVideoName(title);
        setErrorMessage('');
        linkRef.current.value = formatShortLink(url);
      })
      .catch(() => {
        setLinkVideoData(url);
        setLinkVideoThumbnail(DefaultImage);
        setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
      })
      .finally(() => {
        updateLoadingVideoInstruction(false);
      });
  };

  const onLinkThumbnailLoadError = event => {
    event.preventDefault();
    if (!isEmpty(linkVideoThumbnail)) {
      event.target.src = linkVideoThumbnail;
    }
  };

  const onLinkThumbnailLoadSuccess = event => {
    event.preventDefault();
    event.target.style.display = 'block';
    onChangeLink({ url: linkVideoData, thumbnail_url: linkVideoThumbnail, name: videoName, type: typeLink });
  };

  const handleRemoveLink = () => {
    setLinkVideoData('');
    setLinkVideoThumbnail('');
    setVideoName('');
    setTypeLink('');
    setErrorMessage('');
    onChangeLink({});
    updateDataDetailRecipe({
      error: omit(error, ['intro_instruction']),
    });
    linkRef.current.value = '';
  };

  const isThumbnailDefault = linkVideoThumbnail.includes('/static/media/thumbnail_default_link');

  const renderVideoThumbnail = () => {
    return (
      <S.VideoThumbnailWrapper
        isThumbnailDefault={isThumbnailDefault}
        hasThumbnail={linkVideoThumbnail}
        isEditMode={isEditMode}
      >
        <S.VideoThumbnail
          src={linkVideoThumbnail}
          alt={videoName}
          onLoad={onLinkThumbnailLoadSuccess}
          onError={onLinkThumbnailLoadError}
          style={{ display: 'none' }}
        />
        {isEditMode && <RemoveIcon className="icon-remove" onClick={handleRemoveLink} />}
      </S.VideoThumbnailWrapper>
    );
  };

  const handleBlur = () => {
    onChangeLink(
      !!errorMessage || !linkVideoData
        ? { url: linkVideoData }
        : { url: linkVideoData, thumbnail_url: linkVideoThumbnail, name: videoName, type: typeLink },
    );
    if (!loadingVideoInstruction) {
      updateDataDetailRecipe({
        error: !!errorMessage ? { ...error, intro_instruction: true } : omit(error, ['intro_instruction']),
      });
    }
  };

  return (
    <S.VideoLinkWrapper>
      {isEditMode && (
        <>
          {label && <S.Label>{label}</S.Label>}
          <S.VideoLink
            ref={linkRef}
            placeholder={placeholder}
            onChange={handleChangeVideoLink}
            defaultValue={linkVideoData}
            hasError={errorMessage}
            onBlur={handleBlur}
          />
          {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        </>
      )}
      {(isPreviewUrl || !isEditMode) && !errorMessage && (
        <MediaModal
          video={linkVideoData}
          validVideo={true}
          trigger={renderVideoThumbnail()}
          className="media-modal-meal-plan"
        />
      )}
    </S.VideoLinkWrapper>
  );
};

export default VideoLink;
