import React from 'react';
import { Icon, Popup, Loader, Label, Image } from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import { DateTime } from 'luxon';
import _ from 'lodash';
import cx from 'classnames';
import { toggleModal } from 'actions/modal';
import TaskAddNew from 'components/TaskAddNew';
import styles, { EXERCISE_CONTAINER_HEIGHT } from './styles';
import AutoflowInfo from 'shared/AutoflowInfo';
import ConfirmModal from 'shared/ConfirmModal';
import RepeatTaskConfirm from 'components/TaskRepeat/ConfirmModal';
import { CONFIRM_TYPES } from 'components/TaskRepeat/ConfirmModal/constants';
import { CDN_URL } from 'constants/commonData';
import HabitDraggable from './components/HabitDraggable';
import { getItemStyle } from './helps';
import { Trigger } from 'shared/Dropdown/Basic';

import './styles.scss';

export default class TaskCalendarCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarCellHeight: 0,
      hover: false,
      openingPopup: '',
      isConfirmRemove: false,
      currentTask: {},
    };

    this.calendarCell = undefined;
    this.directionPopup = this.props.dayofWeek === 'Sun' ? 'bottom right' : 'bottom left';
  }

  componentDidMount() {
    this.updateCalendarCellHeight();
    window.addEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.calendarCell && this.calendarCell.offsetHeight !== this.state.calendarCellHeight) {
      this.updateCalendarCellHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  toggleRemoveConfirmModal = () => {
    this.setState(state => ({
      isConfirmRemove: !state.isConfirmRemove,
    }));
  };

  render() {
    const { day, currentTaskDay, calendarType, timezone, selectedClient } = this.props;
    const { isConfirmRemove } = this.state;

    if (!selectedClient) {
      return null;
    }

    const { isViewMore } = this.state;
    const active = DateTime.local().setZone(timezone).toFormat('MM-dd-yyyy') === day.toFormat('MM-dd-yyyy');
    let droppableId = `${day.toFormat('MM-dd-yyyy')};`;

    if (currentTaskDay) {
      droppableId = `${day.toFormat('MM-dd-yyyy')};${currentTaskDay._id}`;
    }

    return (
      <div className="calendar-cell" style={{ height: `${100 / calendarType}%` }}>
        <div
          style={{
            background: isViewMore ? '#ffffff' : '#f3f5f8',
            border: isViewMore ? 'solid 1px #726ee4' : '1px solid transparent',
          }}
          className="calendar-cell__content"
        >
          {isViewMore ? (
            <div className="day-info-over">
              <div>{day.toFormat('LLL dd')}</div>
              <div className="add-workout-button">
                <div className="add-new" onClick={this.handleAddTask.bind(this)} />
              </div>
            </div>
          ) : (
            <div className="day-info-over view-more">
              <Label
                color={active ? 'purple' : undefined}
                circular={active}
                style={{ ...styles.day }}
                className={active ? 'day-label active' : 'day-label'}
              >
                {day.toFormat('dd')}
              </Label>
              {this.renderTrainingInfo()}
              <div className="add-workout-button">
                <div className="add-new" onClick={this.handleAddTask.bind(this)} />
              </div>
            </div>
          )}
          {isViewMore && (
            <Image
              className="btntask-close-over"
              src={`${CDN_URL}/images/close_circle.svg`}
              onClick={() => {
                this.handleViewMore(false);
              }}
              width={16}
              height={16}
            />
          )}

          <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
              <div
                id="task-dropable"
                ref={ref => this.onRefInnerDiv(ref, provided)}
                style={{
                  background: isViewMore ? '#ffffff' : '#f3f5f8',
                  marginBottom: isViewMore ? 0 : 4,
                  overflowY: isViewMore || snapshot.isDragging ? 'auto' : 'unset',
                  height: isViewMore || snapshot.isDragging ? this.state.calendarCellHeight : 'unset',
                }}
                className="calendar-cell__main-content"
                {...provided.droppableProps}
              >
                {this.renderTasks(snapshot, day.weekday === 7)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        {isConfirmRemove && (
          <RepeatTaskConfirm
            title="Remove a Repeating Task"
            isRemove={true}
            isTask={true}
            type={CONFIRM_TYPES.TASK}
            message="You are removing a repeating task. Do you want to remove this and all upcoming occurences of this task, or only the selected task?"
            onClose={this.toggleRemoveConfirmModal}
            onConfirm={this.handleConfirmRemoveTaskHasRepeat}
          />
        )}
      </div>
    );
  }

  renderTasks(snapshot) {
    const { currentTasks, currentHabits, dayofWeek } = this.props;
    const { isViewMore } = this.state;
    const dragOver = snapshot.isDraggingOver ? { background: '#f3f5f8' } : {};
    const views = [];
    const maxRendered = this.getMaximumRenderedTasks();
    const isMonday = dayofWeek === 'Mon';

    for (let i = 0; i < currentTasks.length && (isViewMore || i < maxRendered); i++) {
      let task = currentTasks[i];
      if (!task) {
        continue;
      }
      views.push(this.renderTask(task, i));
    }

    // logic render habits
    const habitViews = [];
    for (let idx = 0; idx < currentHabits.length && (isViewMore || idx < maxRendered - views.length); idx++) {
      if (_.isEmpty(currentHabits[idx])) {
        continue;
      }
      const dayFormat = this.props.day.toFormat('MM-dd-yyyy');
      habitViews.push(
        <HabitDraggable
          key={`habit-${currentHabits[idx]._id}-${idx + views.length}-${dayFormat}`}
          habit={currentHabits[idx]}
          directionPopup={this.directionPopup}
          day={dayFormat}
          openingPopup={this.state.openingPopup}
          onPopupToggle={this.onPopupToggle}
          isDragging={this.props.isDragging}
          isMonday={isMonday}
        />,
      );
    }

    let className = 'ui segment small task-cell';

    if (isViewMore) {
      className += ' view-more';
    }

    return (
      <div className={className} style={{ ...dragOver }}>
        {views}
        {habitViews}
        {this.renderViewMore()}
        {this.renderBottom(snapshot.isDraggingOver)}
      </div>
    );
  }

  renderBottom(isDraggingOver) {
    const { currentTasks, copyingTask, currentHabits } = this.props;
    const { isViewMore, calendarCellHeight } = this.state;

    if (isDraggingOver || isViewMore || _.isEmpty(copyingTask)) {
      return <></>;
    }

    return (
      <div
        className="calendar-cell_paste-button"
        style={{ marginTop: _.isEmpty(currentTasks) && _.isEmpty(currentHabits) ? calendarCellHeight / 2 - 22 : 0 }}
        onClick={this.handlePaste}
      >
        <div>Paste</div>
      </div>
    );
  }

  renderTrainingInfo() {
    const { currentAssignment } = this.props;
    if (currentAssignment && currentAssignment.length > 0) {
      let title = '';
      currentAssignment.forEach((element, index) => {
        if (index < currentAssignment.length - 1) {
          title += element.title + ', ';
        } else {
          title += element.title;
        }
      });
      return (
        <div className="training-icon-container">
          <div data-tip={title} className="icon" />
          <ReactTooltip style={{ zIndex: 100000 }} className="app-tooltip" effect="solid" />
        </div>
      );
    }
    return <></>;
  }

  updateCalendarCellHeight() {
    if (this.calendarCell) {
      this.setState({
        calendarCellHeight: this.calendarCell.offsetHeight,
        width: window.innerWidth - 198,
        height: window.innerHeight,
      });
    } else {
      this.setState({
        width: window.innerWidth - 198,
        height: window.innerHeight,
      });
    }
  }

  onRefInnerDiv(ref, provided) {
    provided.innerRef(ref);
    this.calendarCell = ref;
  }

  renderViewMore() {
    const { currentTasks, copyingTask, currentHabits } = this.props;
    const { isViewMore } = this.state;
    const maxRendered = this.getMaximumRenderedTasks();
    let remainTasks = currentTasks.length + currentHabits.length - maxRendered;

    if (remainTasks <= 0 || isViewMore) {
      return <></>;
    }

    let taskText = '+ ' + remainTasks;
    if (remainTasks === 1) {
      taskText += ' more task';
    } else {
      taskText += ' more tasks';
    }
    return (
      <div
        className={cx('view-more-task', {
          copying: !_.isEmpty(copyingTask),
        })}
        onClick={() => {
          this.handleViewMore(true);
        }}
      >
        {taskText}
      </div>
    );
  }

  handleViewMore(isViewMore) {
    this.setState({ isViewMore: isViewMore });
  }

  renderTask(task, index) {
    const { currentTaskDay, day, bodymetricTypes, dayofWeek } = this.props;
    const dropdownIcon = <Trigger onClick={e => e.stopPropagation()} />;

    let draggableId = `${task._id};${day.toFormat('MM-dd-yyyy')}`;

    if (currentTaskDay) {
      draggableId = `${task._id};${day.toFormat('MM-dd-yyyy')};${currentTaskDay._id}`;
    }

    // Add more information to detect draggable item
    if (task.repeat_task_setting_id && !task.originalAutoflow) {
      draggableId += `${draggableId};isRepeating`;
    }

    let metricsName = task.schedule_reminder ? task.reminder_time : '';

    if (task.type === 'body_metric') {
      let listMetricsData = task.metrics.reduce((listMetrics, metricId) => {
        const metricData = bodymetricTypes.find(item => item._id === metricId && item.selected);

        if (metricData) {
          listMetrics.push(metricData.name);
        }
        return listMetrics;
      }, []);

      let metricText = listMetricsData.join(', ');

      if (task.schedule_reminder && metricText) metricsName += ' | ';

      metricsName += metricText;
    }

    const classNameOnDragging = this.props.isDragging ? ' task-card-on-dragging' : '';
    const isMonday = dayofWeek === 'Mon';

    return (
      <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={task.status}>
        {(provided, snapshot) => (
          <div
            className={
              !snapshot.isDragging
                ? task.status === 1
                  ? 'ui segment small task-card completed' + classNameOnDragging
                  : 'ui segment small task-card' + classNameOnDragging
                : 'ui segment small task-card' + classNameOnDragging
            }
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => this.handleOpenTask(task)}
          >
            {this.props.movingSet ? <Loader /> : null}
            <div className={'task-icon'}>
              <Image src={`${CDN_URL}/images/task_icon_${task.type}.svg`} />
            </div>
            {task.originalAutoflow && (
              <AutoflowInfo elementId={task._id} autoflow={task.originalAutoflow} isMonday={isMonday} isTask={true} />
            )}
            <div className="task-title">
              <div className="title" data-tip data-for={`task-title-${task._id}`}>
                {task.title ? task.title : ''}
              </div>
              <div className="description">{metricsName}</div>
              <ReactTooltip
                className="app-tooltip"
                id={`task-title-${task._id}`}
                effect="solid"
                place={'top'}
                delayShow={1000}
              >
                <span>{task.title ? task.title : ''}</span>
              </ReactTooltip>
            </div>
            {task.status === 1 && <Image className="completed_task" src={`${CDN_URL}/images/completed_task.svg`} />}
            <Popup
              trigger={dropdownIcon}
              on={'click'}
              style={styles.popup}
              open={this.state.openingPopup === task._id}
              onOpen={() => this.onPopupToggle(task._id)}
              onClose={() => this.onPopupToggle(null)}
              position={this.directionPopup}
              basic
              className="action-popup"
            >
              {this.renderPopupContent(task)}
            </Popup>
          </div>
        )}
      </Draggable>
    );
  }

  handleOpenTask(task) {
    const { selectedClient } = this.props;
    this.props.push(`/home/client/${selectedClient}/task/${task._id}`);
  }

  onSaveToLibrary = taskId => () => {
    this.setState({ openingPopup: '' });
    this.props.saveToLibrary({ taskId, taskSchema: 'client-calendar' });
  };

  handleOpenFormSubmission = (e, task) => {
    const formId = _.get(task, 'form._id', '');
    const submittedId = _.get(task, 'form_answer', '');
    e.stopPropagation();
    this.setState({ openingPopup: '' });
    if (formId) {
      window.open(`/home/forms/${formId}/responses?client=${task.client}&submitted=${submittedId}`, '_blank');
    }
  };

  renderPopupContent(task) {
    return (
      <ul style={styles.dropdownList}>
        {task.status === 1 && task.form && this.props.permission.form_questionnaire && (
          <li style={styles.dropdownListItem} className={'list-item'}>
            <div style={styles.dropdownListItemContainer} onClick={e => this.handleOpenFormSubmission(e, task)}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/submission.svg`} />{' '}
              <div style={styles.dropdownListItemTitle}>View submission</div>
            </div>
          </li>
        )}
        <li style={styles.dropdownListItem} className={'list-item'}>
          <div style={styles.dropdownListItemContainer} onClick={this.onSaveToLibrary(task._id)}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/save.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Save to library</div>
          </div>
        </li>
        <li style={styles.dropdownListItem} className={'list-item'}>
          <div
            style={styles.dropdownListItemContainer}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.props.copyTask(task);
            }}
          >
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/duplicate.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Copy</div>
          </div>
        </li>
        <li style={styles.dropdownListItem} className={'list-item'}>
          <div
            style={styles.dropdownListItemContainer}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleDeleteAction(task);
            }}
          >
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/delete.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Delete</div>
          </div>
        </li>
      </ul>
    );
  }

  onPopupToggle = popup => {
    if (popup == null) {
      console.log('Closing');
    }
    //should open popup
    this.onPopupToggleAction(popup);
  };

  onPopupToggleAction(popup) {
    this.resetActions(() => {
      setTimeout(() => this.setState({ openingPopup: popup }), 0);
    });
  }

  resetActions = callback => {
    this.setState({
      openingPopup: null,
    });
    callback && callback();
  };

  handleDeleteAction = task => {
    let params = {
      taskId: task._id,
      client: this.props.selectedClient,
    };
    const { repeatTaskPermission } = this.props;
    if (task.repeat_task_setting_id && !task.originalAutoflow && repeatTaskPermission) {
      this.setState({
        currentTask: task,
      });
      this.toggleRemoveConfirmModal();
    } else {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Delete task"
          content="Are you sure that you want to delete this task?"
          onConfirm={() => {
            params.is_only_this_task = true;
            this.props.removeCurrentTask(params);
          }}
        />,
      );
    }
  };

  handleConfirmRemoveTaskHasRepeat = isOnly => {
    const { currentTask } = this.state;
    const params = {
      taskId: currentTask._id,
      client: currentTask.selectedClient,
      is_only_this_task: isOnly,
    };
    this.props.removeCurrentTask(params);
    this.toggleRemoveConfirmModal();
  };

  handleAddTask() {
    this.props.dispatch(toggleModal(true, <TaskAddNew date={this.props.day.toString()} />));
  }

  handlePaste = event => {
    const multiPaste = event.shiftKey;
    const { pasteCurrentTask, copyingTask, day, selectedClient } = this.props;
    let currentDate = DateTime.local();
    let date = day.set({
      hour: currentDate.get('hour'),
      minute: currentDate.get('minute'),
      second: currentDate.get('second'),
    });

    if (!_.isEmpty(_.get(copyingTask, '_id'))) {
      const params = {
        taskId: _.get(copyingTask, '_id'),
        date: date.toString(),
        client: selectedClient,
      };

      pasteCurrentTask(params, multiPaste);
    }
  };

  getMaximumRenderedTasks() {
    const { calendarCellHeight } = this.state;

    // 6: border and margin bottom, 22: footer height
    const outerHeight = 6 + 22;
    // 8: first card not margin top
    const containerHeight = calendarCellHeight - outerHeight + 8;

    let maxRender = parseInt(containerHeight / EXERCISE_CONTAINER_HEIGHT, 10);

    return maxRender;
  }
}
