import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { PriceNote, Wrapper } from './style';
import UpgradeButton from './UpgradeButton';
import { getPeriodName, arrayToString } from 'utils/commonFunction';
import TermPolicy from './TermPolicy';
import { PLANS, TRIAL_MODE, DEFAULT_CLIENT_FILTERS } from 'constants/commonData';
import { updatePaymentStatus } from 'redux/payment/actions';
import { confirmPayment, initPricingData } from 'redux/pricing/actions';
import { bindActionCreators } from 'redux';
import PopupPaymentSuccess from '../ConfirmPayment/PopupPaymentSuccess';
import ReactTooltip from 'react-tooltip';
import { toggleConfirmModal } from 'actions/modal';
import DowngradeConfirm from './DowngradeConfirm';
import { selectSegment } from 'redux/segment/actions';
import { initPermission } from 'redux/permission/actions';
import { push } from 'connected-react-router';
import DowngradeQuestionnaire from '../DowngradeQuestionaire';
import classNames from 'classnames';

function getPriceByPeriod(period, packagePrice) {
  return period === 'monthly' ? packagePrice.monthlyPricing : packagePrice.anuallyPricing;
}

function PlanSummary(props) {
  const [submitting, setSubmitting] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isQuestionnaire, setQuestionnaire] = useState(false);
  const { selectPackage, addOnsPricing, teamData } = props;
  const price = getPriceByPeriod(selectPackage.period, selectPackage.packagePrice);
  const noClientsWarning = props.teamData.noClients - _.get(props.selectPackage, 'packagePrice.packageNoClients', 0);
  const isShowWarningMessage =
    !_.isNull(selectPackage.prorationPlanCost) &&
    props.teamData.trialMode === TRIAL_MODE.Expired &&
    selectPackage.prorationPlanCost > 0 &&
    selectPackage.period !== 'annually' &&
    teamData.period !== 'annually';

  const handleUpgrade = e => {
    if (props.selectPackage.tier === PLANS.starter.key) {
      if (teamData.trialMode === TRIAL_MODE.Grace) {
        return doUpgrade();
      }
      props.toggleConfirmModal(true, <DowngradeConfirm onConfirm={doUpgrade} />);
    } else {
      props.onUpgrade(e);
    }
  };

  const shouldShowQuestionnaine = () => {
    const { selectPackage, teamData } = props;
    return (
      teamData.tier !== PLANS.starter.key &&
      selectPackage.tier === PLANS.starter.key &&
      teamData.trialMode === TRIAL_MODE.Expired
    );
  };

  const doUpgrade = () => {
    const { updatePaymentStatus, selectSegment, confirmPayment } = props;
    if (shouldShowQuestionnaine()) {
      setQuestionnaire(true);
    } else {
      setSubmitting(true);
      updatePaymentStatus(null);
      confirmPayment()
        .then(data => {
          goHome();
          selectSegment(null, Object.assign({}, DEFAULT_CLIENT_FILTERS, { ownerShip: props.user._id }));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const goHome = () => {
    props.push('/home/billing');
    setTimeout(() => {
      props.initPermission();
    }, 1000);
    props.initPricingData();
  };

  const isShowCreditToAccount = useMemo(() => {
    return (
      teamData.period !== 'annually' &&
      selectPackage.tier === PLANS.starter.key &&
      !_.isNull(selectPackage.prorationPlanCost) &&
      selectPackage.prorationPlanCost < 0
    );
  }, [selectPackage, teamData]);

  return (
    <>
      <Wrapper {...props}>
        <div className="planSummary__heading">
          <span>Plan Summary</span>
        </div>
        <div className="planSummary__body">
          <div className="planSummary__selectedPlan">
            <div>
              <span
                className={`planSummary__selectedPlan--name planSummary__selectedPlan--${selectPackage.tier}`}
              >{`${_.upperFirst(selectPackage.tier)} Plan`}</span>
              {price > 0 && (
                <span className="planSummary__selectedPlan--client">
                  {' '}
                  - {_.get(selectPackage, 'packagePrice.packageNoClients', 0)} clients
                </span>
              )}
            </div>
            <div className="planSummary__selectedPlan--priceWrapper">
              <span className="planSummary__selectedPlan--price">
                {price > 0 ? `${price.toUSDFormat(false)}` : 'Free'}
              </span>
              {price > 0 && (
                <span className="planSummary__selectedPlan--period"> /{getPeriodName(selectPackage.period)}</span>
              )}
            </div>
          </div>
          {addOnsPricing.map(addOn => {
            if (!addOn.planAvailable.includes(selectPackage.tier)) {
              return;
            }

            const getTags = arrayToString(_.get(addOn, 'tags', []));
            const updatedTags =
              getTags === 'ADD-ON'
                ? 'Add-on'
                : getTags === 'API PLUGIN'
                ? `API Plugin (${_.get(selectPackage, 'zapier_quantity', false).toLocaleString('en-US')} tasks)`
                : getTags;

            return (
              selectPackage.addOns[addOn.code] &&
              !teamData.oneTimeAddOns[addOn.code] &&
              price > 0 && (
                <div className="planSummary__selectedPlan">
                  <div>
                    <span className="planSummary__planName planSummary__planName--automation">{addOn.title}</span>
                    <span className="planSummary__selectedPlan--client"> - {updatedTags}</span>
                  </div>
                  <div className={classNames('planSummary__selectedPlan--right', { isOneTime: addOn.isOneTime })}>
                    <span className="planSummary__selectedPlan--price">
                      {getPriceByPeriod(selectPackage.period, addOn.basePrice).toUSDFormat(false)}
                    </span>
                    {addOn.isOneTime ? (
                      <div className="planSummary__selectedPlan--period">One time</div>
                    ) : (
                      <span className="planSummary__selectedPlan--period"> /{getPeriodName(selectPackage.period)}</span>
                    )}
                  </div>
                </div>
              )
            );
          })}
          {isShowCreditToAccount && (
            <div className="planSummary__creditToAccount">
              <div className="planSummary__creditToAccount--leftContent">
                <span className="planSummary__creditToAccount--name">Credit to account</span>
                <div
                  className="planSummary__creditToAccount--tootipIcon"
                  data-tip
                  data-for="planSummary__creditToAccount"
                >
                  i
                </div>
                <ReactTooltip
                  className="app-tooltip plan-summary-tootip"
                  id="planSummary__creditToAccount"
                  place="top"
                  effect="solid"
                >
                  The remaining value will be added to your Everfit credit balance.
                </ReactTooltip>
              </div>
              <div>
                <strong className="planSummary__creditToAccount--price">
                  +&nbsp;{Number(selectPackage.prorationPlanCost * -1).toUSDFormat()}
                </strong>
              </div>
            </div>
          )}
          {isShowWarningMessage && (
            <div className="planSummary__promoMsg">
              * Upgrade your current subscription for only{' '}
              <strong>
                {selectPackage.prorationPlanCost / 100 > 0 ? `$${selectPackage.prorationPlanCost / 100}` : 'Free'}
              </strong>
              !
            </div>
          )}
          {noClientsWarning > 0 && price === 0 && (
            <div className="planSummary__waringMsg">
              <strong>* You are training {props.teamData.noClients} clients right now. </strong>
              <span>Please archive {noClientsWarning} clients before switching to the Free plan. </span>
              <a href="http://help.everfit.io/en/articles/4166092-how-to-archive-a-client" target="_blank">
                How to archive clients
              </a>
            </div>
          )}
        </div>
        <div className="planSummary__footer">
          <UpgradeButton disabled={submitting || props.isLoading} onClick={handleUpgrade} />
          <PriceNote>All prices are in USD</PriceNote>
        </div>
        {paymentSuccess && <PopupPaymentSuccess />}
        {isQuestionnaire && <DowngradeQuestionnaire />}
      </Wrapper>
      <TermPolicy />
    </>
  );
}

const mapState = state => {
  const { rootReducer, user } = state;

  return {
    user,
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    confirmPayment: bindActionCreators(confirmPayment, dispatch),
    selectSegment: bindActionCreators(selectSegment, dispatch),
    initPermission: bindActionCreators(initPermission, dispatch),
    initPricingData: bindActionCreators(initPricingData, dispatch),
    updatePaymentStatus: bindActionCreators(updatePaymentStatus, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(PlanSummary);
