import styled, { css } from 'styled-components';

export const InfoTooltipIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
  ${props =>
    props.size &&
    css`
      width: ${props.size};
      height: ${props.size};
    `}
  ${props =>
    props.maxWidthContent &&
    css`
      .__react_component_tooltip {
        width: ${props.maxWidthContent};
      }
    `}
`;
