// libs
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PollTitlePost from './components/TitlePoll';
import PollOption from './components/PollOption';
import get from 'lodash/get';

import { capitalizeFirstChar } from 'utils/commonFunction';

// components
import { SConfirmModal } from '../Post/style';

// constants
import { KEY_CODE } from 'constants/commonData';
import { LIMIT_QUESTION_CHARACTER } from 'components/CommunityForum/components/FormPoll/constants';

// assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_thin.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/close_progress_photo_image.svg';
import WarningIcon from 'assets/icons/warning_red_light.svg';

import * as S from './style';

const LIMIT_OPTION = 7;
const LIMIT_ADDED_OPTION = 25;
const LIMIT_TITLE = 255;

const PollPost = props => {
  const {
    user,
    group,
    members,
    toggleModal,
    isAuthorPost,
    authorPostId,
    pollData = {},
    addPollOption,
    cloudfrontList,
    votePollOption,
    isScheduledPost,
    removePollOption,
    unvotePollOption,
    toggleConfirmModal,
    onChangePoll,
  } = props;
  const {
    _id,
    total_votes,
    options = [],
    question = '',
    allow_multiple_choices: isMultiple,
    allow_member_to_add_option: otherCanAdd,
  } = pollData;
  const [viewAll, setViewAll] = useState(false);
  const [openInput, setOpenInput] = useState(false);
  const [errorInput, setErrorInput] = useState('');
  const [newOptionInput, setNewOptionInput] = useState('');
  const [loadingVote, setLoadingVote] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  const inputRef = useRef();

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  }, [newOptionInput]);

  const hiddenNumber = options.length - LIMIT_OPTION;
  const isViewerHasVote = options.some(({ viewer_has_voted }) => !!viewer_has_voted);

  const findHighestValueAndCount = (arr, key) => {
    if (arr.length === 0) return { highest: null, count: 0 };

    let highest = -Infinity;
    let count = 0;

    arr.forEach(item => {
      if (item[key] > highest) {
        highest = item[key];
        count = 1;
      } else if (item[key] === highest) {
        count++;
      }
    });

    return { highest, count };
  };

  const { highest: highestVote, count: numberOfHighest } = useMemo(() => findHighestValueAndCount(options, 'votes'), [
    options,
  ]);

  const sliceOptions = useMemo(() => {
    const isViewAll = options.length < LIMIT_OPTION && !viewAll;
    return isViewAll || viewAll ? options : options.slice(0, LIMIT_OPTION);
  }, [options, viewAll]);

  const handleViewAll = () => {
    setViewAll(true);
  };

  const handleOpenInputAddMoreOption = () => {
    if (!isAuthorPost && !otherCanAdd) return;
    setOpenInput(true);
  };

  const handleAddMoreOption = () => {
    const trimmedValue = typeof newOptionInput === 'string' ? newOptionInput.trim() : newOptionInput;
    if (!trimmedValue) {
      setErrorInput('');
      setOpenInput(false);
      return;
    }

    const isExisted = options.some(({ value }) => value === trimmedValue);
    if (isExisted) {
      setErrorInput('Already have this option');
      return;
    }

    setLoadingVote(true);
    addPollOption({ poll_id: _id, option_value: trimmedValue, isMultiple, isAuthorPost })
      .then(() => {
        onChangePoll && onChangePoll();
      })
      .finally(() => {
        setLoadingVote(false);
      });

    setOpenInput(false);
    setNewOptionInput('');
  };

  const onChangeAddMoreOption = event => {
    const { value = '', selectionStart } = event.target;
    setErrorInput('');
    if (value.length > LIMIT_TITLE) return;

    const finalValue = capitalizeFirstChar(value);
    event.target.value = finalValue;
    setNewOptionInput(finalValue);
    setCursorPosition(selectionStart);
  };

  const handleClearInput = event => {
    event.stopPropagation();
    setErrorInput('');
    setNewOptionInput('');
    setOpenInput(false);
  };

  const onKeyPress = event => {
    if (event.keyCode === KEY_CODE.enter) {
      event.preventDefault();
      handleAddMoreOption();
    }
  };

  const handleSelectOption = option => async () => {
    if (loadingVote) return;
    if (isScheduledPost) return;
    const { viewer_has_voted: isSelected, _id } = option;
    const voteParams = { poll_id: pollData._id, option_id: _id };
    setLoadingVote(true);
    try {
      isSelected ? await unvotePollOption(voteParams, isMultiple) : await votePollOption(voteParams, isMultiple);
      onChangePoll && onChangePoll();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVote(false);
    }
  };

  const handleOpenDeletePopup = id => () => {
    toggleConfirmModal(
      true,
      <SConfirmModal
        noBorder
        headerIcon={WarningIcon}
        hasCloseIcon
        hasHoverState
        title="Delete option?"
        content="Are you sure? This option will be removed permanently."
        onConfirm={handleConfirmRemoveOption(id)}
        confirmButtonTitle="Delete"
      />,
    );
  };

  const handleConfirmRemoveOption = id => () => {
    const newList = options.filter(({ _id }) => _id !== id);
    const isViewAll = viewAll || newList.length < LIMIT_OPTION;
    setViewAll(isViewAll);
    removePollOption({ poll_id: _id, option_id: id });
    onChangePoll && onChangePoll();
  };

  const checkIsLastOption = ({ viewer_has_voted: hasVoted, owner }, itemList) => {
    const { _id: currentOwnerOptionId } = owner || {};
    const { _id: userIdViewing } = user || {};
    const isOwnerForum = (group || {}).author === userIdViewing;
    const isOwnerOptionAndSelected =
      currentOwnerOptionId === userIdViewing && hasVoted && !isOwnerForum && !isAuthorPost;

    if (itemList.length === 1 || isOwnerOptionAndSelected) {
      return true;
    }

    return false;
  };

  const renderOption = (item, index, arrayOrigin) => {
    const { viewer_has_voted: isSelected, votes = 0, _id = '', percentage } = item;
    const isOwnerForum = (group || {}).author === user._id;
    const highestPercent = highestVote === votes && numberOfHighest === 1;
    const isLeastOption = checkIsLastOption(item, arrayOrigin);

    return (
      <PollOption
        item={item}
        key={_id}
        userId={user._id}
        viewAll={viewAll}
        members={members}
        percent={percentage}
        pollId={pollData._id}
        selected={isSelected}
        totalVote={total_votes}
        toggleModal={toggleModal}
        isAuthorPost={isAuthorPost}
        isOwnerForum={isOwnerForum}
        authorPostId={authorPostId}
        isMultipleChoice={isMultiple}
        isLeastOption={isLeastOption}
        cloudfrontList={cloudfrontList}
        highestPercent={highestPercent}
        isScheduledPost={isScheduledPost}
        isViewerHasVote={isViewerHasVote}
        onSelectOption={handleSelectOption}
        onOpenDeletePopup={handleOpenDeletePopup}
      />
    );
  };

  const renderAddMoreOption = () => {
    if (options.length >= LIMIT_ADDED_OPTION) return;

    if (!openInput) {
      if (!isAuthorPost && !otherCanAdd) {
        return null;
      }

      return (
        <S.AddMoreOption className="btn-add-more" onClick={handleOpenInputAddMoreOption}>
          <PlusIcon className="plus-icon" /> <span>Add another option</span>
        </S.AddMoreOption>
      );
    }

    return (
      <S.InputWrapper>
        <S.InputContainer errorInput={!!errorInput}>
          <S.AddMoreOptionInput
            type="text"
            defaultValue={newOptionInput}
            placeholder="Add an option"
            autoFocus
            onChange={onChangeAddMoreOption}
            onBlur={handleAddMoreOption}
            maxLength={LIMIT_QUESTION_CHARACTER}
            onKeyDown={onKeyPress}
            ref={inputRef}
          />
          <S.RemoveWrapper onMouseDown={handleClearInput}>
            <RemoveIcon className="remove-icon" />
          </S.RemoveWrapper>
        </S.InputContainer>
        <S.ValidateWrapper>
          <S.ErrorMessage>{!!errorInput && errorInput}</S.ErrorMessage>
          <S.LimitTitle isFull={LIMIT_TITLE === newOptionInput.length}>
            {newOptionInput.length >= LIMIT_TITLE - 15 && (
              <span>
                {newOptionInput.length}/{LIMIT_TITLE}
              </span>
            )}
          </S.LimitTitle>
        </S.ValidateWrapper>
      </S.InputWrapper>
    );
  };

  if (isScheduledPost) {
    return (
      <S.PollWrapper>
        <PollTitlePost title={question} />
        <S.PollOptionsWrapper>{options.map(renderOption)}</S.PollOptionsWrapper>
      </S.PollWrapper>
    );
  }

  return (
    <S.PollWrapper>
      <PollTitlePost title={question} />
      <S.PollOptionsWrapper>{sliceOptions.map(renderOption)}</S.PollOptionsWrapper>
      {viewAll || hiddenNumber <= 0 ? (
        renderAddMoreOption()
      ) : (
        <S.AddMoreOption onClick={handleViewAll}>View all ({hiddenNumber})</S.AddMoreOption>
      )}
    </S.PollWrapper>
  );
};

export default PollPost;
