import React, { useState, useLayoutEffect, useEffect } from 'react';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import { isMobile } from 'react-device-detect';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

export default function (props) {
  const { options, value, autoOpen } = props;
  const [open, setOpen] = useState(false);
  const selected = value || {};

  useEffect(() => {
    setOpen(!!autoOpen);
  }, [value]);

  return (
    <RootCloseWrapper event="click" disabled={!open} onRootClose={() => setOpen(false)}>
      <S.Wrapper className={open ? 'open' : null} ariaExpanded={!!open} tabIndex="0">
        <S.Trigger onClick={() => setOpen(!open)}>
          <div className="text">{selected.selectedLabel || selected.label}</div>
          <img src={`${CDN_URL}/images/arrow_down_light_purple.svg`} alt="" />
        </S.Trigger>
        <S.DropdownWrapperContent isMobile={isMobile}>
          <S.Menu className="questionaire__select__menu" isMobile={isMobile}>
            {_.map(options, item => (
              <S.Option
                key={item.key}
                onClick={() => {
                  setOpen(false);

                  if (item.key !== selected.key) {
                    props.onSelect(item);
                  }
                }}
                className="questionaire__select__option"
              >
                {item.label}
              </S.Option>
            ))}
          </S.Menu>
        </S.DropdownWrapperContent>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}
