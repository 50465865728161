import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const SetTable = styled.table`
  border-collapse: initial;
  border-spacing: 0;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #202353;
  width: 100%;
  table-layout: fixed;

  th {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #6a778a;
    padding: 5px 10px;

    .__react_component_tooltip.app-tooltip {
      width: 200px;
    }
  }

  tbody {
    tr {
      :not(:last-child) {
        td {
          border-bottom: 1px solid #dadfea;
        }
      }

      :hover {
        background-color: #f7f8fc;
      }

      :first-child {
        td {
          :first-child {
            border-top-left-radius: 5px;
          }

          :last-child {
            border-top-right-radius: 5px;
          }
        }
      }

      :last-child {
        td {
          :first-child {
            border-bottom-left-radius: 5px;
          }

          :last-child {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    td {
      padding: 6px 10px 5px;
    }
  }

  &.table--body {
    border: 1px solid #dadfea;
    border-radius: 5px;
  }
`;

export const TrainingSetsContainer = styled.div`
  width: 100%;

  .setTypeSelection {
    display: inline-block;

    .setTypeSelection__trigger {
      width: 47px;
      height: 22px;
    }
  }

  .workoutError--emptyORM {
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const ExtendInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  .leftSide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .evf-checkbox {
    font-weight: normal;
    font-size: 11px;
    line-height: 100%;
    color: #202353;
    margin-right: 20px;
  }
`;

export const AddSetContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .addOneSet {
    background-color: #fff;
    border: 1px solid #dadfea;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right-color: transparent;
    padding: 6px 14px 7px;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #a2a8b0;

    :hover {
      border-color: #5158cf;
      border-right-color: #5158cf;
      background-color: #eaebff;
      cursor: pointer;
      color: #5158cf;

      ~ * {
        .addSetsWrapper__trigger {
          border-left-color: transparent;
        }
      }
    }
  }

  .addSetsWrapper {
    .addSetsWrapper__trigger {
      width: 30px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #dadfea;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: #838d9f;
      background-color: #f7f8fc;
      background-image: url(${CDN_URL}/images/plus_new_grey.svg);
      background-repeat: no-repeat;
      background-position: center;

      :hover {
        background-color: #eaebff;
        border-color: #5158cf;
        color: #5158cf;
        cursor: pointer;
      }
    }

    &.open {
      .addSetsWrapper__trigger {
        background-image: url(${CDN_URL}/images/plus_new_grey.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #eaebff;
        border-color: #5158cf;
        color: #5158cf;
      }
    }
  }
`;

export const AlternateExerciseItem = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 9px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #202353;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .index {
    color: #979797;
    margin-right: 3px;
  }
`;

export const AlternateExerciseContainer = styled.div`
  margin-bottom: 10px;

  label {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #202353;
    opacity: 0.4;
    margin-bottom: 3px;
    text-transform: uppercase;
  }

  .list {
    background: #f7f8fc;
    border-radius: 8px;
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
`;

export const Wrapper = styled.div`
  padding: 20px;

  .noteEditor {
    font-size: 11px;
    line-height: 17px;
    padding: 5px 9px 6px;

    :not(:focus) {
      background-color: #f6f7f9;
    }
  }

  .text-editable__container {
    padding-top: 4px;
    padding-bottom: 4px;

    .text-editable__input {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #202353;

      :empty::before {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: #202353;
        opacity: 0.4;
      }
    }

    :not(.text-editable__container--active) {
      background-color: #f6f7f9;

      .text-editable__input {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        box-orient: vertical;
        overflow: hidden;
      }
    }
  }
`;
