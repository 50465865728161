import React from 'react';
import startCase from 'lodash/startCase';
import classNames from 'classnames';

import { ReactComponent as GridViewBlackIcon } from 'assets/icons/form-grid-view-black.svg';
import { ReactComponent as GridViewWhiteIcon } from 'assets/icons/form-grid-view-white.svg';
import { ReactComponent as ListViewBlackIcon } from 'assets/icons/form-list-view-black.svg';
import { ReactComponent as ListViewWhiteIcon } from 'assets/icons/form-list-view-white.svg';
import { FORM_VIEW_TYPES } from 'constants/commonData';

import * as S from './style';

export const HEADER_TITLES = [
  {
    key: 'your_form',
    value: 'your forms',
  },
  {
    key: 'all_form',
    value: 'all forms',
  },
  {
    key: 'archive',
    value: 'archived',
  },
];

const Header = ({ activeHeader, onChangeHeader, activeLayout, onChangeLayout }) => {
  const handleClickHeader = header => () => {
    onChangeHeader(header);
  };
  const handleClickLayout = layout => () => {
    onChangeLayout(layout);
  };
  return (
    <S.HeaderWrapper>
      <S.LeftContainer>
        {HEADER_TITLES.map(item => (
          <S.HeaderTab
            key={item.key}
            isActive={activeHeader === item.key}
            onClick={handleClickHeader(item.key)}
            className="Tabs"
            name={startCase(item.value)}
          >
            {startCase(item.value)}
          </S.HeaderTab>
        ))}
      </S.LeftContainer>
      {/* <S.RightContainer>
        <S.LayoutButtonsContainer>
          <div
            className={classNames('layout-button grid', { active: activeLayout === FORM_VIEW_TYPES.GRID })}
            onClick={handleClickLayout(FORM_VIEW_TYPES.GRID)}
          >
            {activeLayout === FORM_VIEW_TYPES.GRID ? <GridViewWhiteIcon /> : <GridViewBlackIcon />} Grid
          </div>
          <div
            className={classNames('layout-button list', { active: activeLayout === FORM_VIEW_TYPES.LIST })}
            onClick={handleClickLayout(FORM_VIEW_TYPES.LIST)}
          >
            {activeLayout === FORM_VIEW_TYPES.LIST ? <ListViewWhiteIcon /> : <ListViewBlackIcon />} List
          </div>
        </S.LayoutButtonsContainer>
      </S.RightContainer> */}
    </S.HeaderWrapper>
  );
};

export default Header;
