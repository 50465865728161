import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
`;

export const UploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  background: #ffffff;

  .image-with-fallback {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: #f2f2f2;
      object-fit: cover;
      outline: none;
    }
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  height: 100%;
  border: 1px dashed #e1e1ea;
  border-radius: inherit;
  position: relative;
  outline: none;
  ${props =>
    props.isDragging &&
    css`
      border-color: #5158cf;
    `}
  ${props =>
    props.src &&
    css`
      background: url(${props => props.src}) no-repeat center;
      background-size: cover;
      border: unset;
    `}
  ${props =>
    props.noBorder &&
    css`
      border: none;
    `}
  .close-icon {
    display: none;
    position: absolute;
    top: -9px;
    right: -9px;
    z-index: 18;
    width: 18px;
    height: 18px;
  }
  &:hover {
    .close-icon {
      display: block;
    }
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 18;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
`;

export const Desc = styled.div`
  ${textBase}
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 150%;
`;

export const UploadContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${textBase}
  color: #7B7E91;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: relative;
  .custom-upload-content {
    color: #5158cf;
    font-size: 12px;
    font-weight: 600;
    :hover {
      text-decoration: underline;
    }
  }
  .thumbnail-info {
    ${textBase}
    font-size: 12px;
    line-height: 18px;
    padding: 8px 10px;
    width: 172px !important;
    background-color: #333333;
    color: #fff;
    margin-top: -12px !important;
    border-radius: 4px;
    z-index: 99;
    user-select: none;
    text-align: left;
    &.type-dark.place-top:after {
      border-top-color: #333;
    }
  }
  .text-dragging {
    ${textBase}
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    margin-top: 20px;
    color: #7b7e91;
  }
  .text-upload-image {
    padding-top: 7px;
  }
  ${props =>
    props.background &&
    css`
      background: url(${props.background}) no-repeat center;
      background-size: cover;
    `}
  ${props =>
    props.isShowOverlay &&
    css`
      &:hover {
        ${Overlay} {
          visibility: visible;
          opacity: 1;
        }
      }
    `}
`;

export const IconInfo = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  z-index: 19;
  visibility: visible;
  opacity: 1;

  svg {
    width: 17px;
    height: 17px;
    path {
      fill: #e1e1ea;
    }
  }

  &:hover {
    background: #f0f1ff !important;

    svg path {
      fill: #5158cf !important;
    }
  }
`;
