import { connect } from 'react-redux';

import DashboardNotifications from './component';
import {
  readNotifications,
  getNotificationSummaries,
  getNotificationWithFilter,
  openItemNotification,
  markAsReadItemNotification,
  markAsUnreadItemNotification,
  readCategoryNotifications,
} from 'redux/notification/actions';
import { toggleModal } from 'actions/modal';

const mapStateToProps = state => {
  const {
    user,
    user: { _id: userId = '', timezone = '' } = {},
    rootReducer: {
      permission: { habit: isHabitPermission = false } = {},
      group: { list: groups = [], loading: loadingGroups = false } = {},
      forum: { list: forums = [], loading: loadingForums = false } = {},
      notification: { summaries = {}, dashboard = {} } = {},
    },
  } = state;

  return {
    userId,
    isHabitPermission,
    user,
    groups,
    loadingGroups,
    forums,
    loadingForums,
    summaries,
    dashboard,
    timezone,
  };
};

const mapDispatchToProps = {
  readNotifications,
  getNotificationSummaries,
  getNotificationWithFilter,
  openItemNotification,
  markAsReadItemNotification,
  markAsUnreadItemNotification,
  toggleModal,
  readCategoryNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNotifications);
