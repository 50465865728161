import styled, { css } from 'styled-components';

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 5px;
`;

export const LinkAttachmentWrapper = styled.div`
  position: relative;
  background: #f4f4f5;
  mix-blend-mode: normal;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;

  .taskAttachment__uploadCover {
    width: 215px;
    height: 125px;
  }

  .form-group--link {
    margin-bottom: 0;

    &.hasUrl {
      margin-bottom: 20px;
    }
  }

  .upload__placeholder {
    padding: 0 20px;
  }
`;
