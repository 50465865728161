export const VALIDATION_LIST = {
  name: true,
};

export const VALIDATION_FIELD = ['name'];

export const LIMIT_LOAD_ITEMS = 20;

export const DEFAULT_FILTERS = {
  level: [],
  duration: [],
  others: [],
};
