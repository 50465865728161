import styled from 'styled-components';
import { Panel } from '../style';

export const PayButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #5C5BBD;
  margin-right: 15px;
  cursor: pointer;
  
  svg {
    margin-right: 5px;
  }
`;

export const PendingStatus = styled.div`
  background: #fef5f6;
  border-radius: 5px;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #ea314a;
  padding: 5px 8px;
`;

export const RightColumns = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .amount {
    font-weight: 600;
    font-size: 14px;
    min-width: 100px;
    text-align: right;
  }
`;

export const HistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
`;

export const List = styled.div`
  margin-top: 10px;
`;

export const NoBillYet = styled.div`
  height: 100%;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 20px;
`;

export const CurrentPlanWrapper = styled(Panel)`
  .content--no-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
