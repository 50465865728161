import moment from 'moment';

export const last7dayEmpty = () => {
  const today = moment();
  const labels = [];

  for (let i = 6; i >= 0; i--) {
    const day = today.clone().subtract(i, 'days');
    const dayLabel = day.format('ddd');
    labels.push({ label: dayLabel, value: 0 });
  }

  return labels;
};
