import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import WorkoutLibraryComponent from './component';
import { toggleModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';

const mapStateToProps = state => {
  const {
    rootReducer: {
      workoutLibrary: { listWorkout, loading },
    },
  } = state;

  return { length: listWorkout.length, loading, listWorkout };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSideBar: visible => dispatch(toggleSideBar(visible)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkoutLibraryComponent));
