import styled, { css } from 'styled-components';

export const Popup = styled.div`
  max-height: 442px;
  overflow-y: scroll;
`;

export const PopupWrapper = styled.div`
  width: 379px;
  height: 553px;
  z-index: 999999;
  background: #ffffff;
  border: 1px solid #d4d7d9;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible;
  position: absolute;
  left: 270px;
  top: 35px;
  padding-bottom: 53px;
`;

export const PopupHeader = styled.div`
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PopupHeaderTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000;
`;

export const ClearLabel = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5e59ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PopupGroup = styled.div`
  border-bottom: 1px solid #d4d7d9;
  padding: 17px 15px;
`;

export const PopupGroupTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  img {
    transform: rotate(-90deg);
    &.opened {
      transform: rotate(0);
      transition: rotate 0.2s ease 0s;
    }
  }
`;

export const PopupGroupContent = styled.div`
  transition: rotate 0.2s ease 0s;
  display: none;
  &.expanded {
    display: flex;
    margin-top: 15px;
    margin-bottom: -13px;
    justify-content: space-between;
    &.duration {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
    }
    &.other {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    &.owner {
      display: inline-flex;
      gap: 20px;
      .radio label {
        color: #111;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
        padding-left: 21px !important;
        &::before {
          border-width: 1px;
        }
        :hover::before {
          border-color: #5158cf;
        }
      }
    }
  }

  .evf-checkbox.system-labels {
    color: #111111;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 13px;
    input:checked ~ .checkbox-icon {
      background-color: #5e59ff;
      border-color: #5e59ff;
    }
  }
`;

export const LabelChip = styled.span`
  padding: 8px 15px;
  border-radius: 453px;
  color: #4a4d56;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 8px;
  margin-right: 8px;
  background: rgba(21, 22, 25, 0.04);
  border-radius: 44px;
  border: 1px solid transparent;
  cursor: pointer;
  &.selected {
    background: rgba(94, 89, 255, 0.08);
    color: #5e59ff;
    border-color: #5e59ff;
  }
`;

export const PopupFooter = styled.div`
  text-align: right;
  padding: 10px 15px;
  border-top: 1px solid #d4d7d9;
  box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.08);
  position: absolute;
  bottom: 0;
  width: 100%;
  button {
    background: #5e59ff;
    color: #fff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    &:hover {
      background: #5e59ff;
      color: #fff;
    }
  }
`;
