import React from 'react';
import Avatar from 'react-avatar';

import styled from 'styled-components';
import { convertS3UrlToCloudFrontUrl, pluralize } from 'utils/commonFunction';
import { getUserShortName } from 'utils/commonFunction';
import { FORUM_AUTHOR_ROLE } from 'constants/commonData';
import SearchGroupAva from 'assets/icons/search-group.svg';
import { connect } from 'react-redux';

const Container = styled.div`
  &.multi-select__option {
    cursor: pointer;

    .client-avatar {
      margin-right: 12px;
    }
  }

  .multi-select__option__label {
    .name {
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #111111;
      word-break: break-all;
    }
    .email,
    .totalMember {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8d8c92;
      word-break: break-all;
    }
    .roleLable {
      display: block;
      position: absolute;
      top: 50%;
      padding: 5px 15px;
      max-width: 70px;
      text-align: center;
      font-size: 10px;
      color: #323c47;
      margin-bottom: 30px;
      background: #eeedf2;
      border: 1px solid #d4d7d9;
      border-radius: 10000px;
      align-items: center;
      transform: translateY(-50%);
    }
    &.rightSide {
      display: flex;
      height: 100%;
      max-width: 90px;
      position: relative;
      background: yellow;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

const SelectClientOption = ({ innerProps, children, data, isFocused, cloudfrontList }) => {
  const { ADMIN, COACH } = FORUM_AUTHOR_ROLE;
  let className = 'multi-select__option';

  if (isFocused) {
    className += ' multi-select__option--is-focused';
  }

  const name = data.first_name && data.last_name ? `${data.first_name} ${data.last_name}` : data.name;
  let roleLabel = null;

  if (data.role) {
    if (ADMIN.key === data.role) roleLabel = ADMIN.label;
    if (COACH.key === data.role) roleLabel = COACH.label;
  }

  const handleCountClient = value => {
    const label = pluralize('client', value);
    const val = String(value).concat(' ', label);

    return val;
  };

  return (
    <Container {...innerProps} className={className}>
      <Avatar
        name={getUserShortName(data)}
        className="client-avatar"
        size="26"
        src={
          data.typeName === 'group' ? SearchGroupAva : convertS3UrlToCloudFrontUrl(data.avatar, cloudfrontList, true)
        }
        color={data.color}
      />

      {data.typeName === 'group' ? (
        <div className="multi-select__option__label">
          <div className="name">
            <b>Group:</b> {name}
          </div>
          <div className="totalMember">{handleCountClient(data.total || 0)}</div>
        </div>
      ) : (
        <div className="multi-select__option__label">
          <div className="name">{name}</div>
          {!data.email.includes('_client@everfit.io') && <div className="email">{data.email}</div>}
        </div>
      )}
      {roleLabel && (
        <div className="multi-select__option__label rightSide">
          <div className="roleLable">{roleLabel}</div>
        </div>
      )}
    </Container>
  );
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(SelectClientOption);
