import styled, { css } from 'styled-components';
import { MODE } from './index';

export const Wrapper = styled.div`
  margin-bottom: 20px;
  .movement-pattern-select__control {
    .movement-pattern-select__value-container--has-value {
      padding: 4px 12px;
    }
    &--is-focused {
      border-color: #dadfea;
    }
    &--menu-is-open {
      border-color: #5158cf;
    }
  }
`;

export const TagsMenuListTitle = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000000;
  margin-left: ${props => (props.mode === MODE.DEFAULT ? '15px' : '10px')};
  margin-top: 15px;
  margin-bottom: 10px;

  ${props =>
    props.mode === MODE.DEFAULT &&
    css`
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: #818da1;
      text-transform: unset;
    `}
`;

export const TagItem = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  padding: 9px 15px;
  background: ${props => (props.isSelected ? '#818DA1' : '#f3f5f8')};
  border: 1px solid #e0e5ed;
  border-radius: 453px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: ${props => (props.isSelected ? '#ffffff' : '#818da1')};
  margin: 4px;
  cursor: pointer;

  :hover {
    background-color: ${props => (props.isSelected ? '#636C7B' : '#e1e6ef')};
    border-color: ${props => (props.isSelected ? '#E0E5ED' : '#e1e6ef')};
  }

  &.selected {
    background-color: #818da1;
    color: #fff;
    font-weight: 600;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;

      :hover {
        background-color: #f3f5f8 !important;
        border-color: #e0e5ed !important;
      }
    `}

  .start-icon {
    width: 12px;
    height: 12px;
    display: block;
  }

  .__react_component_tooltip.app-tooltip {
    background-color: #333333;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;

    &.place-top:after {
      border-top-color: #333333 !important;
      border-top-width: 8px !important;
      bottom: -9px;
    }
  }
`;

export const CreateNewTagOptionButton = styled.div`
  background-color: #f4f4ff;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  color: #7072dd;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  :hover {
    background: #5158cf;
    color: #ffffff;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  overflow: hidden;
  position: relative;
  white-space: nowrap;
  padding-left: 2px;
`;

export const LabelModality = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  color: #6a778a;
  display: flex;
  white-space: break-spaces;
`;

export const MovementLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  width: 305px;
`;

export const LabelContent = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  color: #323c47;
  margin-left: 5px;
`;

export const selectStyle = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#FAFBFC',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5158CF' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5158CF',
    },
    backgroundColor: '#FAFBFC',
    minHeight: '36px',
    minWidth: '369px',
    cursor: 'pointer',
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    display: 'inline-flex',
    // maxHeight: '70px',
    flexWrap: 'wrap',
    overflow: 'auto',
    overflowY: 'auto',
    padding: '2px 12px',
    paddingRight: '0',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#E7E7FD',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 4px 0px 1px',
    gap: '4px',
    background: '#f0f1ff',
    borderRadius: '5px',
    minHeight: '22px',
  }),
  multiValueLabel: provided => ({
    ...provided,
    paddingRight: '0px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#202353',
  }),
  multiValueRemove: (baseStyle, state) => ({
    ...baseStyle,
    cursor: 'pointer',
    'svg path': {
      fill: '#5b5bbd',
    },
    ':hover': {
      backgroundColor: 'initial',
      color: '#5b5bbd',
    },
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 2001,
  }),
  menu: provided => ({
    ...provided,
    marginTop: '1px',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: '206px',
    padding: state.selectProps.currentMode === MODE.SEARCH ? '10px 4px 10px 10px' : '0px 4px 10px 10px',
    marginRight: '6px',
    '::-webkit-scrollbar-thumb': {
      background: '#CDCDCD !important',
    },
    '::-webkit-scrollbar': {
      width: '3px !important',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#000000',
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#323c47',
    opacity: '1',
  }),
  dropdownIndicator: (baseStyle, state) => ({
    ...baseStyle,
    paddingRight: '17px',
    paddingTop: '9px',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#f4f4ff' : '#ffffff',
    ':hover': {
      backgroundColor: '#f4f4ff',
    },
    '&.disabled': {
      cursor: 'not-allowed',
    },
    color: '#000000',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    padding: '10px',
    fontWeight: state.isSelected ? '600' : '400',
    fontSize: '13px',
    lineHeight: '18px',
    borderRadius: '5px',
  }),
};
