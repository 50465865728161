import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #A3A3B5;
`;

export const StepInstructionWrapper = styled.div`
  width: 100%;
`;

export const List = styled.div``;

export const ButtonAdd = styled.div`
  ${baseText}
  margin: 11px 0 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #5158cf;
  cursor: pointer;
  width: fit-content;
  svg {
    margin-right: 8px;
    width: 10px;
    height: 10px;
    path {
      stroke: #5158cf;
    }
  }
`;
