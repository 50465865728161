import { AUTOFLOW_TYPES } from 'constants/commonData';
import ConfirmModalElement from 'shared/ConfirmModal';
import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
`;

export const HabitWrapper = styled.div`
  cursor: pointer;
  opacity: 1;

  ${props =>
    props.isDragging &&
    css`
      opacity: 0.3 !important;
      z-index: 1 !important;
    `}

  ${props =>
    props.autoflow &&
    css`
      &.task-card {
        margin: 0 0 6px !important;
        width: 100%;
        float: none;

        ${props.autoflow === AUTOFLOW_TYPES.EXACT_DATE &&
        css`
          padding-right: 2px !important;
        `}

        i.grey.icon {
          visibility: visible;
          margin-right: 2px;
        }
      }
    `}

    ${props =>
    props.autoflow === AUTOFLOW_TYPES.EXACT_DATE &&
    css`
      .action-popup {
        ul {
          width: 150px;
        }
      }
    `}

  .task-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: content-box;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid #f6f6f6;

    img {
      filter: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
  .task-title {
    .title-cal {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
      max-width: 228px;
      padding: 15px;
      border-radius: 5px;
      overflow-wrap: break-word !important;
    }
  }
`;

export const HabitConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 447px !important;

    .confirm-content-header {
      padding: 30px 0 0 30px !important;
    }

    .confirm-content-body {
      padding: 15px 30px 40px 30px !important;
    }
    .confirm-actions {
      .confirm-yes-button {
        ${baseText};
      }

      .confirm-no-button {
        ${baseText};
      }
    }
  }
`;

export const HabitDraggable = {
  dropdownList: {
    width: '150px',
    position: 'relative',
    padding: '0px',
    paddingBottom: '15px',
  },
  dropdownListItemTitle: {
    float: 'left',
    paddingBottom: '1px',
    paddingLeft: '12px',
    fontSize: '14px',
    lineHeight: '19px',
  },
};
