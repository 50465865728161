export const convertSecondToNormalText = seconds => {
  const timeString = new Date(seconds * 1000).toISOString().substr(11, 8);
  const strArray = timeString.split(':');
  let text = '';
  if (parseInt(strArray[0])) {
    text += parseInt(strArray[0]) + 'h ';
  }

  text += parseInt(strArray[1] || 0) + 'm ';
  text += parseInt(strArray[2] || 0) + 's';

  return text;
};

export const convertSecondToMinuteString = sec => {
  sec = Math.round(sec);
  if (!sec) {
    return '00:00';
  }
  let min = Math.floor(sec / 60);
  let seconds = sec - min * 60;
  seconds = Math.round(seconds * 100) / 100;

  let result = min < 10 ? '0' + min : min;
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  return result;
};

export const convertSecondToTimeString = (sec, normalText) => {
  if (normalText) {
    return convertSecondToNormalText(sec);
  }

  if (!sec) {
    return '00:00:00';
  }
  let hrs = Math.floor(sec / 3600);
  let min = Math.floor((sec - hrs * 3600) / 60);
  let seconds = sec - hrs * 3600 - min * 60;
  seconds = Math.round(seconds * 100) / 100;

  let result = hrs < 10 ? '0' + hrs : hrs;
  result += ':' + (min < 10 ? '0' + min : min);
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  return result;
};

export const convertTimeStringToSecond = time => {
  if (!time) {
    return 0;
  }
  let a = time.split(':'); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  let second = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return second;
};

export const convertSecondToTimeStringForRest = sec => {
  if (!sec) {
    return '00:00';
  }
  let hrs = Math.floor(sec / 3600);
  let min = Math.floor((sec - hrs * 3600) / 60);
  let seconds = sec - hrs * 3600 - min * 60;
  seconds = Math.round(seconds * 100) / 100;

  let result = min < 10 ? '0' + min : min;
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);
  //We only take min and seconds to print
  return result;
};

export const convertTimeStringToSecondForRest = time => {
  //Assume only minutes and second: 05:30 = 5p 30s
  if (!time) {
    return 0;
  }
  let a = time.split(':'); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  let second = +a[0] * 60 + +a[1];
  return second;
};

export const convertHoursStringToSecondForRest = time => {
  //Assume only minutes and second: 05:30 = 5p 30s
  if (!time) {
    return 0;
  }
  const a = time.split(':'); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  let second = +a[0] * 60 + +a[1];

  if (a.length === 3) {
    second = +a[0] * 3600 + +a[1] * 60 + +a[2];
  }
  return second;
};

export const convertDurationToString = duration => {
  let string = '';
  let sec = parseInt(duration);
  let hrs = Math.floor(sec / 3600);
  let min = Math.floor((sec - hrs * 3600) / 60);
  let seconds = sec - hrs * 3600 - min * 60;

  if (sec < 60) {
    // string = '0:';
    string += seconds;
    string += ' s';
    return string;
  } else if (hrs <= 0) {
    string += min;
    if (seconds == 0) {
      string += ' min';
    } else {
      string += ':' + (seconds < 10 ? '0' + seconds : seconds);
      string += ' min';
    }
    return string;
  } else {
    string += hrs;
    if (min == 0 && seconds == 0) {
      string += ' h';
    } else {
      string += ':' + (min < 10 ? '0' + min : min);
      string += ' h';
    }
    return string;
  }
};

export const convertDurationToStringForExerciseHistory = duration => {
  let string = '';
  let sec = parseInt(duration || 0);
  let hrs = Math.floor(sec / 3600);
  let min = Math.floor((sec - hrs * 3600) / 60);
  let seconds = sec - hrs * 3600 - min * 60;
  if (sec < 60) {
    // string = '0:';
    string += seconds;
    string += ' s';
    return { value: string, unit: 'min' };
  } else if (hrs <= 0) {
    string += min;
    if (seconds === 0) {
      string += ' min';
    } else {
      string += ':' + (seconds < 10 ? '0' + seconds : seconds);
      string += ' min';
    }
    return { value: string, unit: 'min' };
  } else {
    string += hrs;
    if (min === 0 && seconds === 0) {
      string += ' h';
    } else {
      string += ':' + (min < 10 ? '0' + min : min);
      string += ' h';
    }
    return { value: string, unit: 'hour' };
  }
};

export const timeSince = date => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + 'y';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + 'm';
  }
  interval = Math.floor(seconds / 648000);
  if (interval >= 1) {
    return interval + 'w';
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + 'd';
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + 'h';
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + 'm';
  }
  if (seconds < 0) {
    seconds = 0;
  }
  return Math.floor(seconds) + 's';
};

export const formatDuration = durationInSeconds => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours} h `;
  }

  if (minutes > 0) {
    formattedDuration += `${minutes} min `;
  }

  if ((seconds > 0 || formattedDuration === '') && hours < 1) {
    formattedDuration += `${seconds} sec`;
  }

  return formattedDuration.trim();
};
