import React from 'react';
import { get } from 'lodash';
import { ReactComponent as UploadIcon } from 'assets/icons/upload_banner_icon.svg';
import * as S from './style';
import { Checkbox } from 'shared/FormControl';
import { connect } from 'react-redux';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const MAX_LENGTH = 117;
export function cutString(str) {
  return str.length > MAX_LENGTH ? str.substring(0, MAX_LENGTH) + '... ' : str;
}

const ProductItem = ({ onSelect, selected, item, disabled, cloudfrontList }) => {
  const handleChange = event => {
    event.stopPropagation();
    onSelect && onSelect(item, disabled ? false : !selected);
  };

  return (
    <S.ProductItemWrapper disabled={disabled} selected={selected} onClick={handleChange}>
      <S.Thumbnail thumbnail={convertS3UrlToCloudFrontUrl(item.cover_image, cloudfrontList, true)}>
        <UploadIcon width={25} />
      </S.Thumbnail>
      <S.Desc>
        <S.DescTitle>{cutString(item.name)}</S.DescTitle>
        <S.DescCounter>
          Assets: <S.TextBold>{get(item, 'assets_count', 0)}</S.TextBold>
          <S.Bull>&bull;</S.Bull>Owner: <S.TextBold>{get(item, 'owner.full_name', '')}</S.TextBold>
        </S.DescCounter>
        <Checkbox className="product-item" checked={selected} />
      </S.Desc>
    </S.ProductItemWrapper>
  );
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(ProductItem);
