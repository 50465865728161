// Libs
import React from 'react';
import ReactTooltip from 'react-tooltip';

// Assets
import { ReactComponent as TriggerFormIcon } from 'assets/icons/onboarding-trigger-form.svg';

// Styles
import * as S from './styles';

const TriggerIcon = props => {
  const { tooltipContent = 'This form has questions that allow for automation triggers' } = props;

  return (
    <S.IconWrapper className="trigger-icon-wrapper" data-for="trigger-icon-tooltip" data-tip>
      <TriggerFormIcon />
      <ReactTooltip id="trigger-icon-tooltip" className="trigger-icon-tooltip" place="top" effect="solid">
        {tooltipContent}
      </ReactTooltip>
    </S.IconWrapper>
  );
};

export default TriggerIcon;
