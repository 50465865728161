import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import * as S from './style';
import WorkoutOverviewDetail from './WorkoutOverviewDetail';
import WorkoutHistoryDetail from './WorkoutHistoryDetail';

import { ReactComponent as NoResultFound } from 'assets/icons/workout_builder_no_workout_schedule.svg';

const WorkoutListOverview = props => {
  const [hideWorkout, setHideWorkout] = useState({});
  const { selectedDay, isTracked } = props;

  if (!selectedDay) {
    return <S.Wrapper />;
  }

  const toggleHide = workoutId => {
    setHideWorkout({
      ...hideWorkout,
      [workoutId]: !hideWorkout[workoutId],
    });
  };

  const workouts = _.filter(selectedDay.workouts || [], wk => {
    if (isTracked) {
      return moment(wk.day, 'MM-DD-YYYY').isBefore(moment().startOf('day')) || wk.status === 2;
    }
    return true;
  });

  return (
    <S.Wrapper>
      {workouts.map(workout =>
        isTracked ? (
          <WorkoutHistoryDetail
            key={workout._id}
            workout={workout}
            hide={hideWorkout[workout._id]}
            toggleHide={toggleHide}
          />
        ) : (
          <WorkoutOverviewDetail
            workout={workout}
            key={workout._id}
            hide={hideWorkout[workout._id]}
            toggleHide={toggleHide}
          />
        ),
      )}
      {!workouts.length && (
        <div className="no-workouts">
          <NoResultFound />
          {isTracked ? 'No workouts tracked' : 'No workouts scheduled'}
        </div>
      )}
    </S.Wrapper>
  );
};

export default WorkoutListOverview;
