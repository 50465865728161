import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const ReactQuillWrapper = styled(ReactQuill)`
  border: 1px solid #dadfea;
  border-radius: 4px;
  overflow: hidden;
  min-height: 267px;
  max-height: 400px;

  .ql-toolbar.ql-snow {
    background: #fafbfc;
    box-shadow: inset 0px -1px 0px #dadfea;
    border-bottom: none;
    border: none;
    padding: 5px 10px;

    .ql-formats {
      border-right: 1px solid #dadfea;
      padding-right: 15px;

      button {
        height: 16px;
        padding: 0px 5px;
      }
    }
  }

  .ql-editor.ql-blank {
    &::before {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      opacity: 0.3;
      font-style: normal;
      padding-left: 5px;
    }
  }

  .ql-container.ql-snow {
    border: none;
    max-height: 345px;
    overflow-y: scroll;
    font-family: 'Open Sans';
    .ql-editor {
      padding: 56px 33.59px 56px 23px;
      h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        padding-bottom: 25px;
      }

      h2 {
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        padding-bottom: 10px;
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
      }

      ul {
        list-style: disc;
        padding-left: 10px;
      }

      ol {
        list-style: auto;
      }

      ol,
      ul {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;

        padding-top: 26px;
        li {
          padding-bottom: 15px;
        }

        li:not(.ql-direction-rtl) {
          padding-left: 0;
          letter-spacing: -0.3px;
          &::before {
            margin-right: 13px;
          }
        }
      }
    }
  }

  &.error {
    border-color: #ea314a;
  }
`;
