import get from 'lodash/get';
import merge from 'lodash/merge';
import { replace } from 'connected-react-router';

import Request from 'configs/request';
import { omitEmptyRequestParams } from 'utils/commonFunction';
import { showError } from 'actions/error';
import { backToForms } from 'redux/form-details/actions';

export const Types = {
  GET_CLIENTS_LIST_REQUEST: 'GET_CLIENTS_LIST_REQUEST',
  GET_CLIENTS_LIST_SUCCESS: 'GET_CLIENTS_LIST_SUCCESS',
  SELECT_CLIENT_REQUEST: 'SELECT_CLIENT_REQUEST',
  SELECT_CLIENT_SUCCESS: 'SELECT_CLIENT_SUCCESS',
  RESET_FORM_RESPONSES: 'RESET_FORM_RESPONSES',
  GET_CLIENT_ANSWER_DATES_REQUEST: 'GET_CLIENT_ANSWER_DATES_REQUEST',
  GET_CLIENT_ANSWER_DATES_SUCCESS: 'GET_CLIENT_ANSWER_DATES_SUCCESS',
  GET_CLIENT_ANSWER_DETAILS_REQUEST: 'GET_CLIENT_ANSWER_DETAILS_REQUEST',
  GET_CLIENT_ANSWER_DETAILS_SUCCESS: 'GET_CLIENT_ANSWER_DETAILS_SUCCESS',
  CHANGE_CLIENT_LIST_QUERY_PARAMS: 'CHANGE_CLIENT_LIST_QUERY_PARAMS',
  GET_FORM_SUMMARY_REQUEST: 'GET_FORM_SUMMARY_REQUEST',
  GET_FORM_SUMMARY_SUCCESS: 'GET_FORM_SUMMARY_SUCCESS',
  READ_ALL_FORM_ANSWER_COMMENT_SUCCESS: 'READ_ALL_FORM_ANSWER_COMMENT_SUCCESS',
  NEW_FORM_ANSWER_COMMENT: 'NEW_FORM_ANSWER_COMMENT',
  EXPORT_FORM_RESPONSES_REQUEST: 'EXPORT_FORM_RESPONSES_REQUEST',
  EXPORT_FORM_RESPONSES_SUCCESS: 'EXPORT_FORM_RESPONSES_SUCCESS',
  EXPORT_FORM_RESPONSES_FAILED: 'EXPORT_FORM_RESPONSES_FAILED',
};

export const getClientsList = (formId, params, selectFirstResult = false) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { formResponses },
    } = getState();
    const clientsQuery = formResponses.clientsQuery;
    const queryParams = merge(clientsQuery, { ...params });
    if (get(queryParams, 'clientId')) {
      delete queryParams.clientId;
    }
    if (get(queryParams, 'submittedId')) {
      delete queryParams.submittedId;
    }
    const page = get(queryParams, 'page');
    dispatch({ type: Types.GET_CLIENTS_LIST_REQUEST, payload: { page } });
    return dispatch(
      Request.get(
        { url: `api/forms/${formId}/individual/clients`, params: omitEmptyRequestParams(queryParams) },
        true,
        response => {
          const data = get(response, 'data.data');
          if (data) {
            const firstItem = get(data, 'data[0]');
            const selectedItem = get(data, 'data', []).find(item => item.client === params.clientId);

            // force get client info
            if (params.clientId && !selectedItem) {
              dispatch(forceGetClientsListById({ formId, params, selectFirstResult, data }));
              return;
            }

            dispatch({ type: Types.GET_CLIENTS_LIST_SUCCESS, payload: data });
            if (firstItem && selectFirstResult && !selectedItem) dispatch(selectClientResponse(firstItem));
            if (selectedItem) dispatch(selectClientResponse(selectedItem, params.submittedId));
          }
        },
      ),
    );
  };
};

export const forceGetClientsListById = ({ formId, params, selectFirstResult, data } = {}) => dispatch =>
  dispatch(
    Request.get(
      {
        url: `api/forms/${formId}/individual/clients`,
        params: {
          client_id: params.clientId,
        },
      },
      false,
      response => {
        const newData = get(response, 'data.data');
        const newResponseData = { data: [...newData.data, ...data.data], total: data.total };
        if (newData) {
          dispatch({
            type: Types.GET_CLIENTS_LIST_SUCCESS,
            payload: newResponseData,
          });
          const firstItem = get(newResponseData, 'data[0]');
          const selectedItem = get(newResponseData, 'data', []).find(item => item.client === params.clientId);
          if (firstItem && selectFirstResult && !selectedItem) dispatch(selectClientResponse(firstItem));
          if (selectedItem) dispatch(selectClientResponse(selectedItem, params.submittedId));
        }
      },
      () => {
        dispatch(replace());
        const firstItem = get(data, 'data[0]');
        dispatch({ type: Types.GET_CLIENTS_LIST_SUCCESS, payload: data });
        if (firstItem) dispatch(selectClientResponse(firstItem));
      },
      false,
    ),
  );

export const changeClientListQueryParams = (formId, params) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.CHANGE_CLIENT_LIST_QUERY_PARAMS, payload: { params } });
    dispatch(getClientsList(formId, params));
  };
};

export const selectClientResponse = (data, submittedId) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.SELECT_CLIENT_REQUEST, payload: { data } });
    //TODO: handle fetch client submit
    dispatch(getClientAnswerDates(data, submittedId));
  };
};

export const getClientAnswerDates = (data, submittedId) => {
  return dispatch => {
    const formId = data.form;
    const clientId = data.client;
    const params = {
      client: clientId,
    };
    dispatch({ type: Types.GET_CLIENT_ANSWER_DATES_REQUEST });
    //TODO: handle fetch client submit
    return dispatch(
      Request.get({ url: `api/forms/${formId}/individual/answer-dates`, params: params }, false, response => {
        const { data } = response.data;
        dispatch({ type: Types.GET_CLIENT_ANSWER_DATES_SUCCESS, payload: { data } });
        if (submittedId) {
          dispatch(getClientAnswerDetails(submittedId));
        } else {
          const firstAnswerDate = get(data, 'data.[0]');
          firstAnswerDate && dispatch(getClientAnswerDetails(firstAnswerDate._id));
        }
      }),
    );
  };
};

export const getClientAnswerDetails = answerDateId => {
  return dispatch => {
    // dispatch({ type: Types.GET_CLIENT_ANSWER_DATES_REQUEST });
    //TODO: handle fetch client submit
    dispatch({ type: Types.GET_CLIENT_ANSWER_DETAILS_REQUEST, payload: { data: answerDateId } });
    return dispatch(
      Request.get({ url: `api/forms/${answerDateId}/individual/answer-detail` }, false, response => {
        const { data } = response.data;
        dispatch({ type: Types.GET_CLIENT_ANSWER_DETAILS_SUCCESS, payload: { data } });
        // dispatch({ type: Types.GET_CLIENT_ANSWER_DATES_SUCCESS, payload: { data } });
      }),
    );
  };
};

export const resetFormResponses = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_FORM_RESPONSES });
  };
};

export const getFormSummary = formId => {
  return dispatch => {
    dispatch({ type: Types.GET_FORM_SUMMARY_REQUEST });
    return dispatch(
      Request.get({ url: `api/forms/${formId}/summary` }, false, response => {
        const { data } = response.data;
        dispatch({ type: Types.GET_FORM_SUMMARY_SUCCESS, payload: { data } });
      }),
    );
  };
};

export const readAllCommentFormAnswer = id => {
  return dispatch => {
    return dispatch(
      Request.post({ url: `api/form-answers/${id}/comment/mark-all-read` }, false, response => {
        const {
          data: { success },
        } = response.data;
        if (success) {
          dispatch({ type: Types.READ_ALL_FORM_ANSWER_COMMENT_SUCCESS, payload: { success } });
        }
      }),
    );
  };
};

export const newFormAnswerComment = data => {
  return (dispatch, getState) => {
    const { user, rootReducer } = getState();

    const authorId = get(data, 'author._id', '');
    const newSubmittedId = get(data, 'item', '');

    const submittedId = get(rootReducer, 'formResponses.workingClient.workingAnswerDate._id', '');
    const userId = get(user, '_id', '');

    const isUnreadComment = newSubmittedId === submittedId && userId !== authorId;

    dispatch({ type: Types.NEW_FORM_ANSWER_COMMENT, payload: isUnreadComment });
  };
};

export const exportResponsesPdf = formAnswerId => {
  return (dispatch, getState) => {
    dispatch({ type: Types.EXPORT_FORM_RESPONSES_REQUEST });

    return dispatch(
      Request.post(
        { url: `/api/form-answers/${formAnswerId}/export` },
        true,
        (response, { dispatch }) => {
          const url = get(response, 'data.data.url', '');

          if (url) {
            window.open(url, '_blank');
            dispatch({ type: Types.EXPORT_FORM_RESPONSES_SUCCESS });
          }
        },
        (error, { dispatch }) => {
          dispatch(showError(get(error, 'response.data.message'), null, null, null, null, () => backToForms(dispatch)));
          dispatch({ type: Types.EXPORT_FORM_RESPONSES_FAILED });
        },
      ),
    );
  };
};
