import { connect } from 'react-redux';
import WorkoutDetailComponent from 'components/WorkoutDetailModalAIDemo/component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  initWorkoutData,
  clearWorkoutData,
  validateWorkoutData,
  updateWorkoutId,
  updateOriginalWorkout,
} from 'redux/workout-builder/actions';
import { resetWorkingClientData } from 'redux/client/client.actionCreators';

import { getOnboardingWorkoutBuiler, markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { hideWorkout } from 'redux/calendar/calendar.actionCreators';
import { sendFeedback } from './actions';
import { updateFlagBetaAgreement } from 'redux/general-settings/actions';
import { getUserGeneralSettings } from 'redux/general-settings/actions';

const mapState = state => {
  const tempWorkoutAI = state.rootReducer.workoutBuilder.getIn(['tempWorkoutAI']);
  return {
    isHideLeftPanel: state.rootReducer.workoutBuilder.getIn(['isHideLeftPanel']),
    isChanged: state.rootReducer.workoutBuilder.getIn(['isChanged']),
    user: state.user,
    loadedOnboardingData: state.rootReducer.onboarding.workoutBuilderGuideSteps.loaded,
    isUploading: state.rootReducer.workoutBuilder.getIn(['isUploading']),
    selectedWorkout: state.rootReducer.workoutBuilder.getIn(['selectedWorkout']),
    workoutModel: state.rootReducer.model,
    tempWorkoutAI: tempWorkoutAI ? tempWorkoutAI.toJS() : null,
    forceRating: process.env.REACT_APP_AI_ENABLE_FORCE_RATING === 'true',
    isAgreementAIBeta: state.rootReducer.generalSettings.is_agreed_ai_wob_beta_agreement || false,
    isAIOnboardingTour: state.rootReducer.generalSettings.is_done_ai_wob_onboarding_tour,
  };
};

const actionCreators = {
  clearWorkoutData,
  toggleModal,
  initWorkoutData,
  validateWorkoutData,
  toggleConfirmModal,
  updateWorkoutId,
  updateOriginalWorkout,
  getOnboardingWorkoutBuiler,
  markWorkoutBuilderGuide,
  resetWorkingClientData,
  hideWorkout,
  sendFeedback,
  updateFlagBetaAgreement,
  getUserGeneralSettings,
};

export default connect(mapState, actionCreators)(WorkoutDetailComponent);
