import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #202353;

  .cancel__description {
    padding-bottom: 15px;
    &.isOneTime {
      padding-bottom: 0;
    }
  }

  .evf-checkbox-circle {
    padding-bottom: 10px;
  }
`;

export const Message = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  margin-bottom: 24px;
`;
