import React, { useEffect, useMemo, useRef, useState, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import NumberFormat from 'react-number-format';

import { PURCHASE_STATUS_TAB_ITEMS, INVOICE_STATUS_TAB_ITEMS } from 'components/Package/constants';
import { NewSearchInput } from 'shared/SearchInput';
import { toggleModal } from 'actions/modal';
import { changeQueryParams, countPackages, getPackageStatistics } from 'redux/package-analytics/actions';
import { PACKAGE_ANALYTICS_VIEW_TYPE, PACKAGE_PRICING_TYPES, PURCHASE_STATUS, SORT_ENUM } from 'constants/commonData';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-scroll-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-scroll-right.svg';
import * as S from './style';

const SORT_BY_TAB = {
  cancelled: 'end_date',
  all_invoices: 'payment_due',
  paid: 'payment_due',
  overdue: 'payment_due',
};

const TabBar = props => {
  const {
    counter = {},
    viewType,
    currentStatus,
    textSearch,
    countPackages,
    changeQueryParams,
    packageId,
    getPackageStatistics,
    workingPackage,
    isMP,
  } = props;

  const horizontalScrollRef = useRef();
  const [hiddenLeftPaddle, setHiddenLeftPaddle] = useState(true);
  const [hiddenRightPaddle, setHiddenRightPaddle] = useState(true);

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      if (hiddenLeftPaddle && hiddenRightPaddle) {
        const wrapperSize = get(entries, '[0].target.parentElement.offsetWidth', 0);
        const target = get(entries, '[0].target');
        let size = 0;
        for (let index = 0; index < target.children.length; index++) {
          size += target.children[index].offsetWidth;
        }
        if (size > wrapperSize) {
          setHiddenLeftPaddle(true);
          setHiddenRightPaddle(false);
        }
      }
    }),
  );

  const resizedContainerRef = useCallback(container => {
    if (container !== null) {
      resizeObserver.current.observe(container);
      horizontalScrollRef.current = container;
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
      horizontalScrollRef.current = null;
    }
  }, []);

  useEffect(() => {
    countPackages({ id: packageId }, isMP);
    changeQueryParams(
      {
        packageId,
        status: 'active',
        order: SORT_ENUM.DESCENDING,
        sort: 'id',
      },
      undefined,
      isMP,
    );
  }, []);

  const handleSelectTab = event => {
    const { status, type } = event.currentTarget.dataset;

    changeQueryParams(
      {
        status,
        page: 1,
        sort: SORT_BY_TAB[status] || 'id',
        order: SORT_ENUM.DESCENDING,
      },
      type,
      isMP,
    );

    // Actions below will re-fetch packages/statistics after changed tab
    // IT's not UNNECESSARY and EFFECTED to performance
    // countPackages({ id: packageId }, isMP);
    // getPackageStatistics({ id: packageId }, isMP);

    if (horizontalScrollRef.current) {
      if (!hiddenLeftPaddle || !hiddenRightPaddle) {
        horizontalScrollRef.current.scrollTo({
          left: event.currentTarget.offsetLeft - 5,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleSearch = (event, { value }) => {
    changeQueryParams({ q: value, page: 1 }, undefined, isMP);
  };

  const handleClearSearch = () => {
    handleSearch(null, { value: '' });
  };

  const handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 }, undefined, isMP);
    }
  };

  const isOneTime = useMemo(() => {
    return get(workingPackage.toJS(), 'price.type') === PACKAGE_PRICING_TYPES.one_time.value;
  }, [workingPackage]);

  const PACKAGE_INVOICE_MENUS = isOneTime ? [INVOICE_STATUS_TAB_ITEMS[0]] : INVOICE_STATUS_TAB_ITEMS;

  const onHorizontalScroll = event => {
    event.persist();
    if (horizontalScrollRef.current) {
      const scroll = horizontalScrollRef.current;
      const position = scroll.scrollLeft;
      const wrapperSize = scroll.parentElement.offsetWidth;
      const size = scroll.scrollWidth;
      const invisibleSize = size - wrapperSize;
      const paddleMargin = 5;
      const endOffset = invisibleSize - paddleMargin;
      if (position <= paddleMargin) {
        setHiddenLeftPaddle(true);
        setHiddenRightPaddle(false);
      } else if (position < endOffset) {
        setHiddenLeftPaddle(false);
        setHiddenRightPaddle(false);
      } else if (position >= endOffset) {
        setHiddenLeftPaddle(false);
        setHiddenRightPaddle(true);
      }
    }
  };

  const handleScrollToSideMargin = ({ left = false }) => {
    if (horizontalScrollRef.current) {
      horizontalScrollRef.current.scrollTo({
        left: left ? 0 : horizontalScrollRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <S.Wrapper>
      <S.LeftContent>
        <S.Anchor left hidden={hiddenLeftPaddle}>
          <ArrowLeftIcon onClick={() => handleScrollToSideMargin({ left: true })} />
        </S.Anchor>
        <S.Anchor right hidden={hiddenRightPaddle}>
          <ArrowRightIcon onClick={() => handleScrollToSideMargin({ left: false })} />
        </S.Anchor>
        <S.WrapperHorizontalScroll ref={resizedContainerRef} onScroll={onHorizontalScroll}>
          <S.PurchaseGroup>
            {PURCHASE_STATUS_TAB_ITEMS.map(item => {
              const itemCounter = get(counter, `[${PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE}][${item.key}]`, 0);
              if (item.key === PURCHASE_STATUS.TRIAL && itemCounter <= 0) return <Fragment key={item.key} />;
              return (
                <S.TabBarItem
                  key={item.key}
                  active={item.value === currentStatus && viewType === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE}
                  onClick={handleSelectTab}
                  data-status={item.value}
                  data-type={PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE}
                >
                  {item.label}&nbsp;
                  <NumberFormat
                    thousandSeparator={true}
                    value={itemCounter}
                    displayType={'text'}
                    renderText={it => `(${it})`}
                  />
                </S.TabBarItem>
              );
            })}
          </S.PurchaseGroup>
          <S.InvoiceGroup>
            {PACKAGE_INVOICE_MENUS.map(item => (
              <S.TabBarItem
                key={item.key}
                active={item.value === currentStatus && viewType === PACKAGE_ANALYTICS_VIEW_TYPE.INVOICE}
                onClick={handleSelectTab}
                data-status={item.value}
                data-type={PACKAGE_ANALYTICS_VIEW_TYPE.INVOICE}
              >
                {item.label}&nbsp;
                <NumberFormat
                  thousandSeparator={true}
                  value={get(counter, `[${PACKAGE_ANALYTICS_VIEW_TYPE.INVOICE}][${item.key}]`, 0)}
                  displayType={'text'}
                  renderText={it => `(${it})`}
                />
              </S.TabBarItem>
            ))}
          </S.InvoiceGroup>
        </S.WrapperHorizontalScroll>
      </S.LeftContent>
      <S.RightContent>
        <NewSearchInput
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
          placeholder="E-mail, Name,.."
          onKeyPress={handleKeyPress}
          defaultValue={textSearch}
        />
      </S.RightContent>
    </S.Wrapper>
  );
};

const mapDispatch = {
  toggleModal,
  changeQueryParams,
  countPackages,
  getPackageStatistics,
};

const mapState = state => {
  const {
    rootReducer: { packageAnalytics },
    user,
  } = state;

  const { counter, query, viewType } = packageAnalytics.toJS();

  return {
    currentStatus: query.status,
    viewType: viewType,
    textSearch: query.q,
    counter: counter,
    hideBanner: user.is_hide_banner_package,
  };
};

export default connect(mapState, mapDispatch)(TabBar);
