import { Types } from './actions';
const INITIAL_STATE = {
  list: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, data } = action;
  switch (type) {
    case Types.UPLOAD_MEDIA_BACKGROUND_REQUEST: {
      const ids = [...state.list, ...data];
      return Object.assign({}, state, { list: ids });
    }
    case Types.UPLOAD_MEDIA_BACKGROUND_SUCCESS: {
      const ids = state.list.filter(item => !data.includes(item)) || [];
      return Object.assign({}, state, { list: ids || [] });
    }
    default:
      return state;
  }
};
