import get from 'lodash/get';
import { connect } from 'react-redux';
import React, { useMemo } from 'react';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
import {
  SORT_ENUM,
  PACKAGE_ANALYTICS_SORT_BY,
  PACKAGE_ANALYTICS_VIEW_TYPE,
  PURCHASE_STATUS,
  PACKAGE_PRICING_TYPES,
} from 'constants/commonData';
import { changeQueryParams } from 'redux/package-analytics/actions';

import * as S from '../style';

const TableHeader = props => {
  const { sort = 'id', order, changeQueryParams, counter = {}, isMP, viewType, status, workingPackage } = props;

  const renderSortIcon = key => {
    if (key === sort) {
      return order === SORT_ENUM.ASCENDING ? <ArrowDown /> : <ArrowUp />;
    }

    return <ArrowDown />;
  };

  const handleSort = event => {
    event.persist();
    const { sortValue } = event.currentTarget.dataset;
    changeQueryParams(
      {
        sort: sortValue,
        order:
          sort !== sortValue
            ? SORT_ENUM.DESCENDING
            : order === SORT_ENUM.DESCENDING
            ? SORT_ENUM.ASCENDING
            : SORT_ENUM.DESCENDING,
      },
      undefined,
      isMP,
    );
  };

  const { isCancelledTab, isTrialTab } = useMemo(
    () => ({
      isCancelledTab: viewType === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE && status === PURCHASE_STATUS.CANCEL,
      isTrialTab: viewType === PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE && status === PURCHASE_STATUS.TRIAL,
    }),
    [viewType, status],
  );

  const isOneTime = useMemo(() => {
    return get(workingPackage.toJS(), 'price.type') === PACKAGE_PRICING_TYPES.one_time.value;
  }, [workingPackage]);

  const isShowNextInvoice = useMemo(() => {
    return get(counter, `${PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE}.trial`, 0) !== 0;
  }, [counter]);

  return (
    <S.TableHeader>
      <S.TableRow>
        <S.TableCellHeader className="name" isSorting={sort === PACKAGE_ANALYTICS_SORT_BY.CUSTOMER_NAME} sortable>
          <S.TableCellContent data-sort-value={PACKAGE_ANALYTICS_SORT_BY.CUSTOMER_NAME} onClick={handleSort}>
            <span>Customer</span>
            {renderSortIcon('customer_name')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader>
          <S.TableCellContent>
            <span>Amount</span>
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader isSorting={sort === PACKAGE_ANALYTICS_SORT_BY.ID} sortable>
          <S.TableCellContent data-sort-value={PACKAGE_ANALYTICS_SORT_BY.ID} onClick={handleSort}>
            <span>{isTrialTab ? 'Start Trial' : 'Purchased'}</span>
            {renderSortIcon('id')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader>
          <S.TableCellContent>
            <span>Status</span>
          </S.TableCellContent>
        </S.TableCellHeader>
        {isTrialTab && (
          <S.TableCellHeader>
            <S.TableCellContent>
              <span>Days Left</span>
            </S.TableCellContent>
          </S.TableCellHeader>
        )}
        <S.TableCellHeader>
          <S.TableCellContent>
            <span>{isTrialTab ? 'Trial End Date' : 'Last invoice'} </span>
          </S.TableCellContent>
        </S.TableCellHeader>
        {!isOneTime && !isTrialTab && (
          <S.TableCellHeader isSorting={isCancelledTab && sort === PACKAGE_ANALYTICS_SORT_BY.END_DATE} sortable>
            <S.TableCellContent
              data-sort-value={PACKAGE_ANALYTICS_SORT_BY.END_DATE}
              onClick={isCancelledTab ? handleSort : undefined}
            >
              <span>{isCancelledTab ? 'End Date' : 'Next Invoice'}</span>
              {isCancelledTab && renderSortIcon('end_date')}
            </S.TableCellContent>
          </S.TableCellHeader>
        )}
        {isShowNextInvoice && isOneTime && !isTrialTab && (
          <S.TableCellHeader>
            <S.TableCellContent>
              <span>Next Invoice</span>
            </S.TableCellContent>
          </S.TableCellHeader>
        )}
        <S.TableCellHeader className="actions" />
      </S.TableRow>
    </S.TableHeader>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageAnalytics, packageDetail },
  } = state;
  const { counter, query, viewType } = packageAnalytics.toJS();
  return {
    sort: query.sort,
    order: query.order,
    viewType: viewType,
    status: query.status,
    packageId: query.packageId,
    counter: counter,
    workingPackage: packageDetail.get('workingPackage'),
  };
};

export default connect(mapState, { changeQueryParams })(TableHeader);
