import React from 'react';
import classnames from 'classnames';
import { List } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';

import { enableCoachBio, handleEnableProduct } from 'utils/commonFunction';
import ImageGotoBilling from '../ImageGotoBilling';
import { USER_ROLE } from 'constants/commonData';

import { SidebarContent, ItemCountList, SubMenuContainer, NumberTotal, BetaLabel, LabelWrapper } from '../../styles';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/learn-about-package.svg';

function PaymentSidebar(props) {
  const {
    activeItem,
    permission,
    push,
    total = 0,
    totalProduct = 0,
    isOwner,
    is_hide_banner_package,
    user,
    totalMP,
  } = props;

  const handleItemClick = event => {
    const { route } = event.currentTarget.dataset;
    push(`/home/${route}`);
  };

  const handlePermissionPayment = () => {
    if (!permission.explore_payment) {
      if (permission.payment) return true;
      return false;
    }
    return true;
  };

  const handlePermissionProduct = () => {
    return permission.payment;
  };

  /* TODO -  REACT_APP_ENABLE_PRODUCT */
  const isPublic = handleEnableProduct(user);

  /* TODO -  REACT_APP_ENABLE_COACH_BIO */
  const isEnableCoachBio = enableCoachBio();

  const hasMP = permission.marketplace_payment;

  return (
    <SidebarContent className="sidebar-content" hideOnMobile>
      <div className="sidebar-divide" />
      <p>Payment</p>
      <List className="menu-list">
        <ItemCountList>
          <div
            className={classnames('menu-list-item non-spacing', { active: activeItem === 'package' })}
            data-route="packages"
            onClick={handleItemClick}
          >
            <span>Packages</span>
            {handlePermissionPayment() && <NumberTotal>{total > 0 && total}</NumberTotal>}
          </div>
        </ItemCountList>
        {isPublic && (
          <SubMenuContainer className={classnames({ active: activeItem === 'sequences' })}>
            <ItemCountList>
              <div className="menu-list-item non-spacing" data-route="sequences" onClick={handleItemClick}>
                <span>Sequences</span>
                {handlePermissionProduct() && totalProduct > 0 && <NumberTotal>{totalProduct}</NumberTotal>}
              </div>

              {!handlePermissionProduct() && (
                <ImageGotoBilling
                  maxWidthContent={234}
                  notSendPrev={false}
                  isNotUsePathName={false}
                  pathname={'/home/sequences'}
                ></ImageGotoBilling>
              )}
            </ItemCountList>
          </SubMenuContainer>
        )}
        {hasMP ? (
          <ItemCountList>
            <div
              className={classnames('menu-list-item non-spacing', { active: activeItem === 'marketplace' })}
              data-route="marketplace"
              onClick={handleItemClick}
            >
              <LabelWrapper>
                <span>Marketplace</span>
                <BetaLabel>beta</BetaLabel>
              </LabelWrapper>
              {handlePermissionPayment() && <NumberTotal>{totalMP > 0 && totalMP}</NumberTotal>}
            </div>
          </ItemCountList>
        ) : null}
        {handlePermissionPayment() && (
          <div
            className={classnames('menu-list-item', { active: activeItem === 'payment-activities' })}
            data-route="payment-activities"
            onClick={handleItemClick}
          >
            <span>Payment Activity</span>
          </div>
        )}
        {isOwner && handlePermissionPayment() && (
          <>
            <div
              className={classnames('menu-list-item', { active: activeItem === 'setup-payment' })}
              data-route="setup-payment"
              onClick={handleItemClick}
            >
              <span>Setup Payment</span>
            </div>
          </>
        )}
        {isEnableCoachBio && handlePermissionPayment() && (
          <>
            <div
              className={classnames('menu-list-item', { active: activeItem === 'coach-bios' })}
              data-route="coach-bios"
              onClick={handleItemClick}
            >
              <span>Coach Bios</span>
            </div>
          </>
        )}
      </List>
      <div className="spacer" />
      {is_hide_banner_package && (
        <a
          className="guide-links"
          href="http://help.everfit.io/en/collections/3210719-payment-packages"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LearnMoreIcon />
          <p>Learn about Packages</p>
        </a>
      )}
    </SidebarContent>
  );
}

const mapState = state => {
  const {
    user: { teams, is_hide_banner_package },
    rootReducer: { permission, packageList, product },
    user,
  } = state;

  const publishPackageTotal = packageList.get('counter').toJS().publish ? packageList.get('counter').toJS().publish : 0;
  const draftPackageTotal = packageList.get('counter').toJS().draft ? packageList.get('counter').toJS().draft : 0;
  const publishMPPackageTotal = packageList.get('counterMP').toJS().publish
    ? packageList.get('counterMP').toJS().publish
    : 0;
  const draftMPPackageTotal = packageList.get('counterMP').toJS().draft ? packageList.get('counterMP').toJS().draft : 0;
  const { publish, draft } = product.get('counter').toJS();
  const publishProductTotal = publish ? publish : 0;
  const draftProductTotal = draft ? draft : 0;

  return {
    isOwner: teams[0].role === USER_ROLE.OWNER,
    permission,
    is_hide_banner_package,
    total: publishPackageTotal + draftPackageTotal,
    totalMP: publishMPPackageTotal + draftMPPackageTotal,
    totalProduct: publishProductTotal + draftProductTotal,
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(PaymentSidebar);
