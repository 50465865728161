import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 200px;
  width: 100%;

  & > .client-avatar {
    display: inline-table !important;

    .sb-avatar__image {
      max-width: initial !important;
    }
  }

  .header-info > .name-container {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: #333333;
    max-width: 700px;
    white-space: nowrap;

    .name {
      margin-right: 20px;
      max-width: calc(100% - 100px);
      overflow: hidden;
      white-space: nowrap;
    }
  }

  button {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 14px;

    &.button--resend-invitation,
    &.button--invite-to-connect {
      min-width: 128px;
    }

    &.button--resend-invitation:hover {
      background: #e5e7fd;
      border-radius: 5px;
    }

    &.button--copy-invite-link {
      border: 1px solid #d4d7d9;
      color: #777;
      &:hover {
        background: #f6f7f9;
        border: 1px solid #d4d7d9;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
  }

  @media screen and (min-width: 1380px) {
    .header-info {
      .client-avatar {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 1379px) {
    & {
      position: relative;
      top: -6px;
      margin-left: -32px;
      width: calc(100% - 32px);
      min-width: 100%;
    }
    .client-avatar {
      display: none !important;
    }

    .header-info {
      .client-avatar {
        display: inline-block !important;
        margin-left: 0 !important;
        margin-right: 8px !important;
        margin-top: 0 !important;
      }

      .name-container {
        padding-left: 32px;
        .name {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .client-menu .client-menu-item {
      margin: 0 24px 0 0;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  @media screen and (min-width: 999px) {
    .button-prev,
    .button-next {
      top: 5px;
    }
  }
  @media screen and (min-width: 744px) and (max-width: 900px) {
    .button-prev,
    .button-next {
      top: 15px;
    }
  }

  @media screen and (max-width: 875px) {
    .name-container .name {
      display: none;
    }
  }

  @media screen and (max-width: 680px) {
    &.has-actions {
      height: 120px;
    }

    .button-next {
      right: -30px;
    }
  }

  @media screen and (min-width: 681px) {
    & {
      height: 68px !important;
    }
    .button-next {
      right: -20px;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 680px) {
    .client-menu-wrap {
      padding-top: 8px !important;
    }

    .button-next,
    .button-prev {
      top: 23px;
    }
  }

  @media screen and (max-width: 374px) {
    & {
      top: 0 !important;
      width: 100%;

      &.has-actions {
        height: auto;
      }
    }

    .header-info .name-container .client-avatar,
    .header-info .name-container .client-avatar img {
      width: 32px;
      height: 32px;
    }

    .client-menu-wrap {
      padding-top: 2px;
    }

    .client-menu-item {
      padding: 8px !important;
      font-size: 13px !important;
      border-radius: 4px;
      height: 34px;
      display: flex;
      position: relative;
      margin: 0 4px 8px 0 !important;

      &.active,
      &:hover {
        color: #494699;
        background: rgba(81, 88, 207, 0.1);
      }
      &.active > div {
        display: none;
      }

      &:last-child {
        padding-right: 20px;
      }
    }

    .button-prev,
    .button-next {
      top: 22px !important;
    }
    .button-next {
      right: -20px !important;
    }
  }
`;

export const ButtonArrow = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 30px 0px rgba(172, 172, 172, 0.2);
  background-color: #fff;
  position: absolute;
  top: 5px;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0.5;
  cursor: pointer;

  &.button-prev {
    left: -24px;
  }

  &.button-next {
    right: -30px;
  }

  &:hover {
    opacity: 1;
  }

  svg path {
    fill: #202353;
  }

  &.button-prev {
    svg {
      transform: rotate(-90deg);
    }
  }

  &.button-next {
    svg {
      transform: rotate(90deg);
    }
  }

  @media screen and (max-width: 900px) {
    & {
      display: flex;
    }
  }
`;
