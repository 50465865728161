import styled from 'styled-components';
import ActionTrigger from 'shared/Dropdown/Trigger';
import { MenuTrigger } from 'shared/Icons';

export const DropdownWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  .evf-dropdown__option {
    padding: 8px 50px 8px 20px;

    .icon {
      margin-right: 10px;
    }
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: #00000010;
  border-radius: 6px;
  width: 24px;
  height: 24px;

  .dot,
  .dot::before,
  .dot::after {
    background-color: #FFFFFF;
  }

  &:hover {
    background: #FFFFFF;

    .dot, .dot::before, .dot::after {
      background-color: #000000;
    }
  }
  
`;
