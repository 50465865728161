import styled, { css } from 'styled-components';
import { Button, FormGroup as FG } from 'shared/FormControl';
import { Form, Image } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

export const WelcomeVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start !important;
`;

export const WelcomeVideoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start !important;
`;

export const VideoSelectWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const DescriptionContainer = styled.div`
  display: block;
  margin-right: 25px;
  padding-top: 23px;
`;

export const DescriptionTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-top: 2px;
  margin-bottom: 19px;
`;

export const DescriptionText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  width: 210px;
`;

export const VideoSelectContainer = styled.div`
  display: block;
  margin-right: 81px;
  margin-top: 26px;
`;

export const SaveButton = styled(Button)`
  margin-left: 40px;
  margin-top: 15px;
  flex: 0 0 110px;
  height: 32px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  background: ${props => (props.disabled ? '#c4c4c4' : '#5158cf')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${props => (props.disabled ? '#c4c4c4' : '#5559ff')};
  }
`;

export const RadioFormField = styled(Form.Field)`
  margin-bottom: 16px !important;

  :last-child {
    margin-bottom: 0px !important;
  }

  label {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #000000 !important;

    :before {
      width: 18px !important;
      height: 18px !important;
      border: 1px solid #cbcfd3 !important;
    }

    :after {
      width: 18px !important;
      height: 18px !important;
    }
  }

  .checked > label {
    :before {
      border: 2px solid #5158cf !important;
    }

    :after {
      background-color: #5158cf !important;
    }
  }
`;

export const FormLinkContainer = styled.form`
  flex: 1;
  margin-top: 70px;
`;

export const FormGroup = styled(FG)`
  display: grid;
  grid-template-columns: 50px auto;
  grid-gap: 15px;

  &.error {
    input {
      border-color: #f5222d;
    }
  }
`;

export const ThumbnailWrapper = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fafbfc;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${props =>
    props.isLoadedPreview &&
    css`
      border: 1px solid #dadfea;
    `}
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  input {
    max-width: 371px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #dadfea;
    border-radius: 4px;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .btn-remove-link {
    position: relative;
    display: block;
    margin-left: 8px;
    height: 100%;
    width: 12px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      width: 12px;
      height: 12px;
    }
  }
`;

export const VideoThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  svg {
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: 12px;
    height: 12px;
  }
`;

export const VideoThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const VideoTitle = styled.div`
  max-width: 360px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #979797;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VideoError = styled.div`
  max-width: 360px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #f5222d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UploadFileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const UploadFileTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const UploadFileTitleWrapperText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #868c9a;
  line-height: 18px;
`;

export const IconInfoCircle = styled(Image)`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;

export const DropzoneWrapper = styled.div`
  box-sizing: border-box;
  max-width: 352px;
  min-height: 114px;
  background: #ffffff;
  border: 1px dashed #dadfea;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.isDragging &&
    css`
      border-color: #5158cf;
    `}
`;

export const DropzoneWrapperText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
  margin-left: 20px;
`;

export const WrapperItemTextHightLight = styled.span`
  color: #5158cf;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: #5559ff;
  }
`;

export const PopupWrapper = styled.div`
  padding: 10px 0px 9px 0px;
  font-weight: 600;
  font-size: 14px;
  color: #202353;
  width: 152px;
  .select-file__container {
    width: 100%;
  }
  .select-file__trigger {
    width: inherit;
  }
`;

export const PopupButton = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  padding: 0px 15px;
  color: #202353;
  :hover {
    background: #eaebff;
    color: #5158cf;
    p {
      font-weight: 600;
    }
  }
  path {
    stroke: #202353;
  }
  :hover path {
    stroke: #5158cf;
  }
  svg {
    margin-right: 8px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
  }
`;

export const AttachmentPreview = styled.div`
  width: 352px;
  height: 114px;
  flex: 0 0 auto;
  border-radius: 5px;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #dadfea;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${props =>
    props.isCompleted &&
    css`
      display: grid !important;
      grid-template-columns: 142px 1fr auto 16px;
      justify-items: center;
    `}

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload__progress {
    width: 179px;
    border-radius: unset;
  }

  .upload__progress_bar {
    border-radius: unset !important;
    background-color: #5158cf !important;
  }

  .file__size > span {
    display: none !important;
  }

  .media-player__trigger,
  .media-player__button {
    background: transparent;
  }

  .media-player__trigger {
    max-width: 142px;
  }

  .media-player__button {
    max-width: 142px;
    height: 114px;
  }

  .media-upload-progress {
    height: 114px;
  }

  .upload-info {
    margin-left: 15px;
    margin-right: 15px;
  }

  .image-with-fallback {
    width: 100%;
    height: 100%;
  }

  .video-name {
    display: inline-block !important;
    max-width: 150px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 15px;
    margin-left: 15px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #202353;
  }

  .btn-remove-upload-file {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
`;

export const MediaTrigger = styled.div`
  position: relative;
  width: 142px;
  height: 114px;
  cursor: pointer;
  border-right: 1px solid #dadfea;
  box-sizing: border-box;
  background: #f9fbff;

  :before {
    content: '';
    position: absolute;
    left: 12px;
    bottom: 12px;
    width: 16px;
    height: 16px;
    background: url(${CDN_URL}/images/video_play.svg) left bottom / contain no-repeat;
    z-index: 1;
    cursor: pointer;
  }

  :after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 98.77%);
  }
`;
