import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import styled, { css } from 'styled-components';

const WORKOUT_SPACE_LEFT_BORDER = 13;
const WORKOUT_MAX_OFFSET = 92;
const TASK_SPACE_TO_LEFT_BORDER = 45;
const TASK_MAX_OFFSET = 60;
const HABIT_SPACE_TO_LEFT_BORDER = 47;
const HABIT_MAX_OFFSET = 60;

export default props => {
  const { autoflow, elementId, isMonday, isWorkout, isTask, isHabit } = props;
  const [offset, setOffset] = useState();
  const arrowOffset = useMemo(() => {
    if (isMonday && isWorkout) return 11;
    if (isMonday && isTask) return 42;
    if (isMonday && isHabit) return 44;
    return;
  }, [isMonday, isWorkout, isTask, isHabit]);

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      if (isMonday) {
        const width = get(entries, '[0].contentRect.width');
        let offsetRight = width / 2;
        let spaceToLeftBorder = 0;
        let maxOffset = 0;
        if (isWorkout) {
          spaceToLeftBorder = WORKOUT_SPACE_LEFT_BORDER;
          maxOffset = WORKOUT_MAX_OFFSET;
        }
        if (isTask) {
          spaceToLeftBorder = TASK_SPACE_TO_LEFT_BORDER;
          maxOffset = TASK_MAX_OFFSET;
        }
        if (isHabit) {
          spaceToLeftBorder = HABIT_SPACE_TO_LEFT_BORDER;
          maxOffset = HABIT_MAX_OFFSET;
        }
        offsetRight -= spaceToLeftBorder;
        // check case first time render
        const classList = get(entries, '[0].target.classList');
        if (!includes(classList, 'app-tooltip-white')) {
          offsetRight -= 8; // reduce size when class tooltip not exist
        }
        setOffset({
          right: Math.min(offsetRight, maxOffset),
        });
      }
    }),
  );

  const resizedContainerRef = useCallback(container => {
    const tooltipRef = get(container, 'tooltipRef');
    if (!isEmpty(tooltipRef)) {
      resizeObserver.current.observe(tooltipRef);
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    }
  }, []);

  if (!autoflow) {
    return null;
  }

  const name = get(autoflow, 'name', '');

  return (
    <Container className="autoflow-info" data-tip data-for={`${elementId}-autoflow-info`} arrowOffset={arrowOffset}>
      <Trigger className="autoflow-info__tooltip-trigger" />
      <ReactTooltip
        className="app-tooltip-white"
        id={`${elementId}-autoflow-info`}
        show={true}
        effect="solid"
        place="top"
        offset={offset}
        ref={resizedContainerRef}
      >
        <div>
          <Name className="autoflow-info__name">
            <span>
              <b>Autoflow:</b> {name}
            </span>
          </Name>
        </div>
      </ReactTooltip>
    </Container>
  );
};

const Trigger = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2fd787;
  flex: 0 0 6px;
`;

const Name = styled.div`
  pointer-events: unset;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
  text-align: left;
  word-wrap: break-word;
`;

const Container = styled.div`
  width: 12px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  background-color: transparent;
  cursor: pointer;
  flex: 0 0 12px;

  .__react_component_tooltip.app-tooltip-white {
    text-align: right;
    max-width: 210px;
    min-width: 100px;
    padding: 12px;
    pointer-events: unset;
  }

  .__react_component_tooltip.app-tooltip-white.place-top:before {
    border-top: 6px solid #dfe4e5 !important;
    bottom: -5px;
    scale: 0.5;
  }

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #5c5bbd !important;

    svg {
      vertical-align: middle;
      margin-left: 7px;
    }
  }

  ${props =>
    props.arrowOffset &&
    css`
      .__react_component_tooltip.app-tooltip-white.place-top:before,
      .__react_component_tooltip.app-tooltip-white.place-top:after {
        left: ${props.arrowOffset}px;
      }
    `}
`;
