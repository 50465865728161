import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';

import { DropdownIndicator } from 'shared/Icons';
import * as S from './styles';
import { ReactComponent as CloseIcon } from 'assets/icons/close_circle_grey.svg';

export const MODE = {
  DEFAULT: 'default',
  SEARCH: 'search',
};

const Equipment = props => {
  const { selectedOption: originalSelectedOption, onChange, options = [] } = props;

  const [inputValue, setInputValue] = useState('');
  const [currentMode, setCurrentMode] = useState(MODE.DEFAULT);
  const [selectedOption, setSelectedOption] = useState(originalSelectedOption);

  const handleSelectEquipment = option => {
    setSelectedOption(option);
    typeof onChange === 'function' && onChange(option);
  };

  const handleInputValue = inputValue => {
    setInputValue(inputValue);

    setCurrentMode(inputValue ? MODE.SEARCH : MODE.DEFAULT);
  };

  const filterOption = (option = {}, inputValue = '') => {
    const { label = '' } = option;
    return label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <S.EquipmentWrapper>
      <Select
        isClearable={true}
        inputValue={inputValue}
        onInputChange={handleInputValue}
        filterOption={filterOption}
        options={options}
        getOptionLabel={option => option.title}
        getOptionValue={option => option._id}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
          MenuList: CustomMenuList,
          ClearIndicator: CustomClearIndicator,
        }}
        classNamePrefix="single-select"
        className="single-select-container__equipment"
        placeholder="Add an equipment"
        onChange={handleSelectEquipment}
        noOptionsMessage={() => 'No results found.'}
        value={selectedOption}
        // custom props
        currentMode={currentMode}
      />
    </S.EquipmentWrapper>
  );
};

export default Equipment;

const CustomMenuList = props => {
  const { selectProps } = props;
  const { inputValue, currentMode } = selectProps;
  const showLabel = currentMode === MODE.SEARCH || inputValue !== '';

  const resultsCount = props.children.length ? props.children.length : 0;

  return (
    <components.MenuList {...props}>
      {showLabel && <S.SearchEquipment mode={currentMode}>{`Results (${resultsCount})`}</S.SearchEquipment>}
      {props.children}
    </components.MenuList>
  );
};

const CustomClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon />
    </components.ClearIndicator>
  );
};
