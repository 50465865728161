import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const ResourceAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;

  ${props =>
    props.coverImage
      ? css`
          background-image: url(${props.coverImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          &.youtube {
            background-color: #ffe7e7;
            background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
          }

          &.vimeo {
            background-color: #e8fbff;
            background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
          }

          &.instagram {
            background-color: #ffe2ec;
            background-image: url(${CDN_URL}/images/studio_resource_instagram_icon.svg);
          }

          &.facebook {
            background-color: #e0f0ff;
            background-image: url(${CDN_URL}/images/studio_resource_facebook_icon.svg);
          }

          &.twitter {
            background-color: #e8f6ff;
            background-image: url(${CDN_URL}/images/studio_resource_twitter_icon.svg);
          }

          &.spotify {
            background-color: #e7ffef;
            background-image: url(${CDN_URL}/images/studio_resource_spotify_icon.svg);
          }

          &.link {
            background-color: #f7f8fc;
            background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
          }

          &.document {
            background-color: #e8f4ff;
            background-image: url(${CDN_URL}/images/studio_resource_document_icon.svg);
          }

          &.form {
            background-color: rgba(76, 189, 54, 0.1);
            background-image: url(${CDN_URL}/images/studio_resource_form_icon.svg);
          }
        `}
`;

export const ResourceInfo = styled.div`
  position: relative;
  overflow: hidden;

  .resourceInfo__name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .resourceInfo__description {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .resourceInfo__wrapper {
    display: flex;
    align-items: center;
  }

  .resourceInfo__archived {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 63px;
    height: 19px;
    margin-left: 3px;

    color: #758094;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px;

    border-radius: 2px;
    border: 1px solid #dadfea;
    background: #f4f4f5;
  }
`;

export const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #dadfea;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  min-width: 258px;

  display: grid;
  grid-template-columns: 50px 1fr 24px;
  justify-content: start;
  align-items: center;
  position: relative;
  gap: 10px;

  .resourceInfo__options:not(:has(.open)) {
    visibility: hidden;
  }

  :hover {
    border-color: #5c5bbd;

    .resourceInfo__options {
      visibility: visible;
    }
  }

  :has(.resourceInfo__archived) {
    ${ResourceInfo}, ${ResourceAvatar} {
      opacity: 0.6;
    }

    :hover {
      border-color: #5c5bbd99;
    }
  }
`;

export const ResourceActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
