import styled from 'styled-components';

export const DropdownPurchaseOptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Open Sans';
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6px;
`;

export const PurchasePreviewValue = styled.span`
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  font-weight: ${props => props.fontWeight || 400};
  color: ${props => props.color || '#4a4d74'};
`;
