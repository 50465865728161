import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: white;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 31px;
  width: 100%;
  font-family: 'Open Sans';
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const CardName = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  margin-bottom: 8px;
`;

export const LearnMore = styled.a`
  margin-left: 12px;
  color: #6456c4;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  :hover {
    color: #6456c4;
  }
`;

export const LearnMoreIcon = styled.img`
  margin-bottom: -2px;
  margin-right: 3px;
`;

export const CardDescription = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  white-space: pre-wrap;
  height: 40px;
`;

export const InputWrapper = styled.div`
  width: 435px;
  display: flex;
  flex-direction: column;

  .formControl {
    margin-bottom: 0 !important;
  }

  input {
    border: 1px solid #dadfea;
    border-radius: 4px;
    height: 46px;

    ${props =>
      props.disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .custom-status-chip {
    padding-left: 17px;
  }

  .custom-status-chip::before {
    top: 13px;
    left: 5px;
  }

  .status-dropdown__indicators {
    transform: translateX(-12px);
  }
`;

export const CardText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
`;

export const CardTextBold = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
  margin-right: 16px;
  margin-bottom: 0;
`;

export const CardSubText = styled.span`
  color: #6a778a;
`;

export const CardSubTextMP = styled.span`
  color: #8f91a9;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const NumberInput = styled(NumberFormat)`
  width: 100%;
  padding: 5px 15px;
  outline: none;
  font-size: 13px;
  line-height: 18px;

  ::placeholder {
    color: #969fb5;
  }

  :hover {
    border-color: #5c5bbd;
  }

  :focus {
    border-color: #5c5bbd;
  }

  ${props =>
    props.error &&
    css`
      border-color: #ea314a !important;
    `}
`;

export const ErrorMessageWrapper = styled.div`
  margin-top: 5px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  :last-child {
    margin-bottom: 0px;
  }
`;

export const StatusIcon = styled.div`
  width: 6px;
  height: 6px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: ${props => props.statusColor};
`;

export const RemoveOptionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 23px;
`;
