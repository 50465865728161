import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import Button from 'shared/FormControl/Button';
import SelectClients from '../CreateNewForum/components/SelectClients';

export const SModal = styled(Modal)`
  .content {
    padding: 30px !important;
    border-radius: 8px;

    section {
      margin-bottom: 0;
      min-height: 38px;

      button {
        margin-top: 0;
      }
    }

    .multi-select-container, .multi-select__control {
      min-height: 42px;
    }
  }
`;

export const Title = styled.div`
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 5px;
`;

export const Description = styled.p`
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  margin-bottom: 30px;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SubmitButton = styled(Button)`
  height: 32px;
  width: 136px;
  margin-top: 30px;

  ${props =>
    props.disable &&
    css`
      background: #c4c4c4;
      color: white;
      cursor: not-allowed;
      pointer-events: none;

      &:hover,
      &:active,
      &:focus {
        background: #c4c4c4;
        color: white;
      }
    `}
`;

export const SelectClientsEle = styled(SelectClients)`
`;
