import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MealPlanDetail from 'components/MealPlanDetail';
import MealPlanDayDetail from 'components/MealPlanDayDetail';

export default () => (
  <Switch>
    <Route exact path="/home/meal-plans/:mealPlanId/weeks/:weekId" render={props => <MealPlanDetail {...props} />} />
    <Route
      exact
      path="/home/meal-plans/:mealPlanId/weeks/:weekId/days/:dayIndex"
      render={props => <MealPlanDayDetail {...props} />}
    />
  </Switch>
);
