import styled, { css } from 'styled-components';

export const TabBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  ${TabBarItem} {
    :last-child {
      margin-right: 0;
    }
  }
`;

export const TabBarItem = styled.div`
  padding: 12px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: rgba(32, 35, 83, 0.6);
  margin-right: 30px;

  ${props =>
    props.active &&
    css`
      box-shadow: inset 0px -2px 0px #5158cf;
      font-weight: bold;
      color: #5158cf;
    `}

  :hover {
    color: #5158cf;
    cursor: pointer;
  }
`;
