import { GENERAL_STATUS_WAITLIST } from 'components/Package/components/common';

export const columns = [
  {
    key: 'id',
    name: 'Requested date',
    sortable: true,
  },
  {
    key: 'status',
    name: 'Status',
  },
];

export const MOST_RECENT_KEY = 'id';

export const NO_LOAD_MORE = false;
export const NO_LOADING = false;

export const WAITLIST_STATUS = {
  WAITING: GENERAL_STATUS_WAITLIST.WAITING,
  OFFERED: GENERAL_STATUS_WAITLIST.OFFERED,
  PURCHASED: GENERAL_STATUS_WAITLIST.PURCHASED,
  REJECTED_BY_CLIENT: GENERAL_STATUS_WAITLIST.REJECTED_BY_CLIENT,
  REJECTED_BY_COACH: GENERAL_STATUS_WAITLIST.REJECTED_BY_COACH,
  CANCELLED_BY_CLIENT: GENERAL_STATUS_WAITLIST.CANCELLED_BY_CLIENT,
  NO_RESPONSE: GENERAL_STATUS_WAITLIST.NO_RESPONSE,
};
