import React from 'react';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { SegmentFallbackWrapper } from '../style';

export default function SegmentFallBack(props) {
  return (
    <SegmentFallbackWrapper>
      <div className="segmentFallBack__transparent"/>
      <div className="segmentFallBack__gradient" />
      <div className="segmentFallBack__white">
        <div className="fallback__content">
          <div className="fallback__contentTitle">
          Manage clients with custom Segments
          </div>
          <div className="fallback__contentDescription">
            Create lists like "Basketball Training” or “Bikini Challenge” for easy sorting, organizing, and reporting.
          </div>
          <UpgradePathButton text="Upgrade to Pro" />
        </div>
      </div>
    </SegmentFallbackWrapper>
  );
}
