import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { MB_SIDE_BAR_HEIGHT } from 'constants/commonData';

export const Item = styled(Link)`
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  box-shadow: inset 0px 1px 0px #494699;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  svg {
    margin-right: 15px;
    color: #a8a7d2;
  }

  :hover,
  &.active {
    background: #5c5bbd;
    cursor: pointer;
    color: #fff;
    text-decoration: none !important;
  }

  ${props =>
    props.isSub &&
    css`
      padding: 8px 13px;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: #9493be;
      box-shadow: none;
      border-radius: 9999px;

      :hover,
      &.active {
        background: #3b397b;
        color: #fff;
      }
    `}

  .subItem {
    padding-left: 10px;
  }
`;

export const ItemRow = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  box-shadow: inset 0px 1px 0px #494699;
  border-radius: 8px;
  margin-bottom: 10px;
  padding-right: 13px;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  svg {
    margin-right: 15px;
    color: #a8a7d2;
  }

  :hover,
  &.active {
    background: #5c5bbd;
    cursor: pointer;
    color: #fff;
    text-decoration: none !important;
  }

  ${props =>
    props.isSub &&
    css`
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: #9493be;
      box-shadow: none;
      border-radius: 9999px;

      :hover,
      &.active {
        background: #3b397b;
        color: #fff;
      }
    `}

  .subItem {
    padding-left: 10px;
  }

  ${Item} {
    width: 100%;
    padding: 8px 13px;
    margin: 0;
  }
`;

export const ItemUpgradePath = styled.div`
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  box-shadow: inset 0px 1px 0px #494699;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  svg {
    margin-right: 15px;
    color: #a8a7d2;
  }

  :hover,
  &.active {
    background: #5c5bbd;
    cursor: pointer;
    color: #fff;
    text-decoration: none !important;
  }

  ${props =>
    props.isSub &&
    css`
      padding: 8px 13px;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: #9493be;
      box-shadow: none;
      border-radius: 9999px;

      :hover,
      &.active {
        background: #3b397b;
        color: #fff;
      }
    `}

  .subItem {
    padding-left: 10px;
  }
`;

export const SubMenuHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 22px;

  .back {
    margin-right: 15px;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  padding: 32px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #494699;
  height: 100%;
  width: 230px;
  display: none;

  ${props =>
    props.overlapAll &&
    css`
      + .sidebar-content {
        display: none;
      }
    `}

  ${props =>
    props.isSub &&
    css`
      padding-left: 20px;
      padding-right: 20px;
    `}

  @media only screen and (max-height: ${MB_SIDE_BAR_HEIGHT}) {
    display: block;

    + .sidebar-content:not(.settings) {
      display: none;
    }
  }
`;

export const ItemCountList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NumberTotal = styled.span`
  min-width: 23px;
  text-align: center;
`;

export const ParentItem = styled.div`
  width: 100%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
`;

export const BetaLabel = styled.div`
  padding: 4px 6px;
  border-radius: 4px;
  background: linear-gradient(112deg, #ffc90c 25.07%, #ff4d00 180.06%);
  color: #443604;
  text-align: center;
  font-size: 7px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 7px */
  text-transform: uppercase;
`;
