import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { get, isEmpty, isEqual } from 'lodash';
import cx from 'classnames';

import { CUSTOM_ONBOARDING_VIDEO_TYPE } from 'components/CustomBranding/constants';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import * as SettingStyles from 'components/CustomBranding/SettingMobileAppPremium/style';
import { ReactComponent as PlaySquare } from 'assets/icons/play_square.svg';
import { ReactComponent as LoginScreenSVG } from 'assets/icons/login-screen.svg';
import TodayScreen from 'components/CustomBranding/SettingMobileAppPremium/TodayScreen';

const WelcomeVideoScreen = props => {
  const {
    getCloudUrl,
    customOnboardingVideoType,
    customOnboardingYoutube,
    customOnboardingUpload,
    customOnboardingDraft,
    getS3presignedURL,
    // props to show today screen
    selectedWorkoutBackground: workoutBackground,
    originalTheme: theme,
    loading,
  } = props;

  // Option
  const [type, setType] = useState(null);

  // Youtube/Vimeo
  const [linkVideoThumbnail, setLinkVideoThumbnail] = useState('');
  const [isShowYoutubeThumbnail, setShowYoutubeThumbnail] = useState(false);

  // Upload
  const [attachmentThumbnail, setAttachmentThumbnail] = useState('');
  const [isShowUploadThumbnail, setShowUploadThumbnail] = useState(false);

  useEffect(() => {
    setType(customOnboardingVideoType);
  }, [customOnboardingVideoType]);

  useEffect(() => {
    let ignore = false;
    const thumbnail = get(customOnboardingUpload, 'thumbnail_link', null);
    if (isEmpty(thumbnail)) return;
    getPresignedURLs(thumbnail, ignore);
    return () => {
      ignore = true;
    };
  }, [customOnboardingUpload]);

  useEffect(() => {
    const thumbnail = get(customOnboardingYoutube, 'thumbnail_link', null);
    setLinkVideoThumbnail(thumbnail);
  }, [customOnboardingYoutube]);

  useEffect(() => {
    let ignore = false;
    if (isEmpty(customOnboardingDraft)) return;
    // Option
    const draftType = get(customOnboardingDraft, 'type', type);
    setType(draftType);
    // Youtube
    const draftYoutubeThumbnail = get(customOnboardingDraft, 'youtube_thumbnail_link', '');
    if (!isEqual(draftYoutubeThumbnail, linkVideoThumbnail)) {
      setShowYoutubeThumbnail(false);
    }
    setLinkVideoThumbnail(draftYoutubeThumbnail);
    // Upload
    const draftUploadThumbnail = get(customOnboardingDraft, 'upload_thumbnail_link', '');
    getPresignedURLs(draftUploadThumbnail, ignore);

    return () => {
      ignore = true;
    };
  }, [customOnboardingDraft]);

  const getPresignedURLs = async (thumbnail, ignore) => {
    try {
      if (!ignore) {
        let cloudUrl = getCloudUrl(thumbnail);
        if (!cloudUrl) {
          const response = await getS3presignedURL([thumbnail]);
          cloudUrl = response[0];
        }
        if (!isEqual(cloudUrl, attachmentThumbnail)) {
          setShowUploadThumbnail(false);
        }
        setAttachmentThumbnail(cloudUrl);
      }
    } catch {}
  };

  const onLinkThumbnailLoadError = event => {
    event.preventDefault();
    if (!isEmpty(linkVideoThumbnail)) {
      event.target.src = linkVideoThumbnail;
    }
  };

  const onLinkThumbnailLoadSuccess = event => {
    event.preventDefault();
    event.target.style.display = 'block';
    setShowYoutubeThumbnail(true);
  };

  const onUploadThumbnailLoadError = event => {
    event.preventDefault();
    if (!isEmpty(attachmentThumbnail)) {
      event.target.src = attachmentThumbnail;
    }
  };

  const onUploadThumbnailLoadSuccess = event => {
    event.preventDefault();
    event.target.style.display = 'block';
    setShowUploadThumbnail(true);
  };

  const isShowDefault = useMemo(() => type === CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT, [type]);
  const isShowUpload = useMemo(() => type === CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN, [type]);
  const isShowYoutube = useMemo(() => type === CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO, [type]);
  const isShowSkip = useMemo(() => type === CUSTOM_ONBOARDING_VIDEO_TYPE.SKIP, [type]);
  const selectedColor = theme.primaryColor;

  return (
    <S.Wrapper>
      <S.WrapperLogin selectedColor={selectedColor}>
        <LoginScreenSVG className="custom-branding-color" />
      </S.WrapperLogin>
      <S.WrapperArrow>
        <Image src={`${CDN_URL}/images/arrow-first-login.svg`} alt="" />
      </S.WrapperArrow>
      <S.WrapperVideo>
        <Image
          src={`${CDN_URL}/images/everfit-welcome-video.svg`}
          alt="everfit-welcome-video"
          className={cx({
            hidden: !isShowDefault,
          })}
        />
        <SettingStyles.PhoneContainer
          className={cx({
            hidden: !isShowUpload,
          })}
        >
          <S.ContainerPlaySquare
            className={cx({
              hidden: isShowUploadThumbnail,
            })}
          >
            <S.WrapperPlaySquare>
              <PlaySquare />
            </S.WrapperPlaySquare>
          </S.ContainerPlaySquare>

          <S.ContainerThumbnail
            className={cx({
              hidden: !isShowUploadThumbnail,
            })}
          >
            <S.WrapperThumbnail>
              <Image
                src={attachmentThumbnail}
                alt=""
                onLoad={onUploadThumbnailLoadSuccess}
                onError={onUploadThumbnailLoadError}
                style={{ display: 'none' }}
              />
            </S.WrapperThumbnail>
            <S.WrapperMediaPlayer>
              <Image src={`${CDN_URL}/images/everfit_media_player.svg`} alt="" />
            </S.WrapperMediaPlayer>
          </S.ContainerThumbnail>
        </SettingStyles.PhoneContainer>
        <SettingStyles.PhoneContainer
          className={cx({
            hidden: !isShowYoutube,
          })}
        >
          <S.ContainerPlaySquare
            className={cx({
              hidden: isShowYoutubeThumbnail,
            })}
          >
            <S.WrapperPlaySquare>
              <PlaySquare />
            </S.WrapperPlaySquare>
          </S.ContainerPlaySquare>

          <S.ContainerThumbnail
            className={cx({
              hidden: !isShowYoutubeThumbnail,
            })}
          >
            <S.WrapperThumbnail>
              <Image
                src={linkVideoThumbnail}
                alt=""
                onLoad={onLinkThumbnailLoadSuccess}
                onError={onLinkThumbnailLoadError}
                style={{ display: 'none' }}
              />
            </S.WrapperThumbnail>
            <S.WrapperMediaPlayer>
              <Image src={`${CDN_URL}/images/everfit_media_player.svg`} alt="" />
            </S.WrapperMediaPlayer>
          </S.ContainerThumbnail>
        </SettingStyles.PhoneContainer>
        <SettingStyles.PhoneContainer
          className={cx({
            hidden: !isShowSkip,
          })}
        >
          <TodayScreen
            useCustomTitle={workoutBackground._id !== '1' || !theme.default}
            customBackground={!workoutBackground.default}
            customTheme={!theme.default}
            secondaryColor={theme.secondaryColor}
            primaryColor={theme.primaryColor}
            workoutBackground={workoutBackground.src}
            loading={loading}
          />
        </SettingStyles.PhoneContainer>
      </S.WrapperVideo>
    </S.Wrapper>
  );
};

export default WelcomeVideoScreen;
