import styled from 'styled-components';
import { Wrapper, TableHeaderCell, TableRow, TableContainer } from 'shared/LibraryLayout';

export const CoachSearch = styled.div`
  width: 265px;
  height: 49px;
  padding-top: 17px;

  .search-input {
    width: 100%;
    height: 30px;
    input {
      background-color: #f8f8f8;

      ::placeholder {
        color: #777;
      }
    }
  }
`;

export const TableWrapper = styled(TableContainer)`
  margin-top: 20px !important;
  overflow-x: auto;
`;

export const CoachColumnHeader = styled(TableHeaderCell)`
  color: #1a1f36;
  font-weight: 600;
  font-size: 11px;
  padding-left: 2px;
  line-height: 15px;

  &:nth-child(1) {
    min-width: 250px;
    width: 25% !important;
  }

  &:nth-child(2) {
    width: 25% !important;
  }

  &:nth-child(3) {
    width: 40% !important;
  }

  &:nth-child(4) {
    width: 10% !important;
  }

  svg path {
    fill: #40464c;
  }

  .coach-icon-header {
    margin-right: 8px;
    margin-top: 4px;
    width: 16px;
    color: #40464c;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;

  .checkbox-icon {
    transform: translateY(-100%);
    width: 14px;
    height: 14px;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16),
      0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
`;

export const CoachBiosWrapper = styled(Wrapper)`
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 5px 0 30px;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

export const CoachBiosTableRow = styled(TableRow)`
  border-bottom: none;
`;

export const LoadingWrapper = styled.div`
  width: 100%;

  .loading-coach-bios {
    height: 80px;
    padding: 15px 0;
  }
`;

export const EndLoadmore = styled.div`
  margin-bottom: 50px;
`;
