// Libs
import React from 'react';

// Assets
import { ReactComponent as TriggerFormIcon } from 'assets/icons/onboarding-trigger-form.svg';

// Styles
import * as S from './styles';

const TriggerAppliedIcon = () => {
  return (
    <S.IconWrapper className="trigger-icon-wrapper">
      <TriggerFormIcon />
      <div className="trigger-icon-label">TRIGGER APPLIED</div>
    </S.IconWrapper>
  );
};

export default TriggerAppliedIcon;
