import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const LeftPanel = styled.div`
  padding: 32px 48px;
  min-width: 619px;
  height: 100%;
  overflow: auto;
  position: relative;
`;

export const RightPanel = styled.div`
  width: 531px;
  background: #f3f5f8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .on-demand-settings__focused {
    position: absolute;
    z-index: 2;
    bottom: 32px;
    right: 99px;
    width: 33.79px;
    height: 33.79px;
    background-size: contain;
  }
  .on-demand-settings__focused--title {
    width: 97px;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    font-weight: 700;
    font-size: 13.2729px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.241325px;
    bottom: 15px;
    right: 67px;
  }

  @media only screen and (max-height: 767px) {
    transform: scale(0.703);
  }
`;

export const MobileOnFocused = styled.div`
  ${props =>
    props.isFocused &&
    css`
      height: 664px;
      width: 322px;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 2;
      border-radius: 25px;

      :before {
        content: '';
        position: absolute;
        padding: 8px 16px;
        width: 321px;
        height: 41px;
        top: 50px;
        background: white;
        border-radius: 8px;
      }

      @media only screen and (max-height: 767px) {
        :before {
          top: 50px;
        }
      }
    `}
`;

export const MainTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  max-width: 508px;
`;

export const MainTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #151619;
`;

export const SubTitle = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #151619;
  margin-top: 24px;
`;

export const SaveButton = styled(Button)`
  padding: 10px 32px;
  width: 94px;
  height: 40px;
  background: #5158cf;
  margin-left: 2px;

  :hover {
    background-color: #6368cd !important;
  }
`;

export const SettingInputWrapper = styled.div`
  padding-top: 8px;

  .nameInput__editor {
    padding: 9px 16px;
    width: 100%;
    max-width: 508px;
    min-height: 48px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #dadfea;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #151619;
    outline: none;

    ::placeholder {
      color: #151619;
      opacity: 0.3;
    }

    :hover {
      border-color: #5e59ff;
      background: rgba(255, 255, 255, 0.6);
      cursor: text;
    }

    :focus {
      border-color: #5e59ff;
      background: rgba(255, 255, 255, 0.6);
    }
  }

  .nameInput__warning {
    padding: 8px 12px;
    text-align: end;

    &--text {
      color: ${props => (props.showError ? '#EA314A' : 'rgba(21, 22, 25, 0.5)')};
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const MobilePreviewContainer = styled.div`
  padding: 66px 30px;
  position: relative;
  height: 687px;
  width: 350px;
  background: url(${CDN_URL}/images/iphone_ondemand_layout.svg) no-repeat;
  background-position: top left;
  display: flex;
  justify-content: space-between;
`;

export const MobilePreviewHeader = styled.div`
  font-weight: 700;
  font-size: 24.5574px;
  line-height: 30px;
  letter-spacing: -0.438525px;
  color: #1e0a3c;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: unset;
  word-wrap: break-word;
  position: relative;
  z-index: 2;
  height: fit-content;
  overflow: hidden;
`;

export const OnDemandIcon = styled.div`
  background: url(${CDN_URL}/images/on-demand-settings.svg) no-repeat;
  width: 25px;
  height: 22px;
  background-size: cover;
`;

export const OnDemandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 68px;
  width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
`;

export const OnDemandTitle = styled.span`
  font-weight: 700;
  font-size: 9.625px;
  line-height: 13px;
  text-align: center;
  display: block;
  margin-top: 2px;
  color: #5c5bbd;
  cursor: default;
  width: 75px;
`;

export const OnDemandFocused = styled.div`
  width: 134px;
  height: 134px;
  background: white;
  position: absolute;
  z-index: 2;
  bottom: -19px;
  right: 49px;
  border-radius: 50%;
`;

export const CircleBackground = styled.div`
  width: 152px;
  height: 152px;
  background: transparent;
  position: absolute;
  bottom: -28px;
  border-radius: 50%;
  right: 40px;
  backdrop-filter: blur(7px);
  border: 1px solid white;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.25);
`;

export const CircleWrapper = styled.div`
  animation: zoom-in 0.2s ease-out;
  transform-origin: center;

  @keyframes zoom-in {
    0% {
      transform: scale(0.25, 0.25);
    }
    50% {
      transform: scale(0.75, 0.75);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  z-index: 2;
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 180px;
`;

export const DiscardModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px !important;

    .ui.button {
      font-family: 'Open Sans';
    }

    .ui.button.confirm-no-button:hover,
    .ui.button.confirm-no-button:active,
    .ui.button.confirm-no-button:focus {
      background-color: #f5f7f9 !important;
    }

    .ui.button.confirm-yes-button:hover,
    .ui.button.confirm-yes-button:active,
    .ui.button.confirm-yes-button:focus {
      background-color: #ec465c !important;
    }

    .ui.button.close-button img {
      width: 20px;
      height: 20px;
      top: -2px;
      left: 1px;
    }

    .ui.button.confirm-no-button {
      line-height: 20px !important;
      padding: 5px 30px;
      min-width: 102px;
      height: 30px;
      border-radius: 5px;
    }

    .ui.button.confirm-yes-button {
      line-height: 20px !important;
      padding: 5px 30px;
      min-width: 108px;
      height: 30px;
      border-radius: 5px;
      background-color: #f5222d !important;
      box-shadow: none !important;
    }

    .confirm-content-header {
      padding: 30px 30px 0 !important;

      .confirm-header-label {
        color: #000000;
        font-size: 18px;
        line-height: 25px;
      }
    }

    .confirm-content-body {
      padding: 15px 30px 30px !important;

      .confirm-content-label {
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
      }
    }

    .confirm-header-image {
      width: 25px;
      height: 25px;
    }
  }
`;
