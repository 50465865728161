// Libs
import React, { useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';

// Components
import { Button } from 'shared/FormControl';
import { Checkbox } from 'shared/FormControl';

// Constants
import { STEP_UNIQUE_CODE } from 'constants/commonData';

// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close_bold_circle.svg';

// Styles
import * as S from './style';

const EnableMetrics = ({ onClose, listToEnableMetrics = [], onConfirm }) => {
  const [selectedEnableList, setSelectedEnableList] = useState([]);

  const handleCheckedAllMetric = () => {
    setSelectedEnableList(
      selectedEnableList.length === listToEnableMetrics.length ? [] : listToEnableMetrics.map(({ _id = '' }) => _id),
    );
  };

  const handleCheckedMetric = id => {
    const newList = selectedEnableList.includes(id)
      ? selectedEnableList.filter(item => item !== id)
      : [...selectedEnableList, id];

    setSelectedEnableList(newList);
  };

  const handleEnableMetric = () => {
    onConfirm(selectedEnableList);
  };

  return (
    <S.CustomModal open onClose={onClose} closeOnDimmerClick={false} className="evf-enable-metric-modal">
      <Modal.Header className="modal-enable-metric-header">
        <S.EnableMetricHeaderWrapper>
          <S.HeaderWrapper>
            <S.HeaderTitle>Enable Metrics</S.HeaderTitle>
            <S.HeaderDesc>Select metrics from the Metric Groups to enable for this client: </S.HeaderDesc>
          </S.HeaderWrapper>
          <CloseButton className="close-button" onClick={onClose}>
            <CloseIcon className="close-icon" />
          </CloseButton>
        </S.EnableMetricHeaderWrapper>
      </Modal.Header>
      <S.ContentWrapper>
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  circle
                  size={16}
                  title={
                    <S.MetricName isBold isHeader>
                      Metric
                    </S.MetricName>
                  }
                  checked={selectedEnableList.length > 0 && selectedEnableList.length === listToEnableMetrics.length}
                  onChange={handleCheckedAllMetric}
                />
              </th>
              <th>Unit</th>
            </tr>
          </thead>
          <tbody>
            {listToEnableMetrics.map(metric => {
              const { _id = '', name = '', unique_code = '', unit } = metric || {};
              const { title } = unit || {};

              const isSelected = selectedEnableList.includes(_id);

              return (
                <tr key={_id}>
                  <td>
                    <Checkbox
                      circle
                      size={16}
                      title={
                        <S.MetricName isBold={isSelected}>
                          {name}
                          {unique_code === STEP_UNIQUE_CODE && <S.ExtendInfo>(Automatic)</S.ExtendInfo>}
                        </S.MetricName>
                      }
                      checked={isSelected}
                      onChange={() => handleCheckedMetric(_id)}
                    />
                  </td>
                  <td>{title || '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </S.ContentWrapper>
      <S.Action>
        <Button purple onClick={handleEnableMetric}>
          Confirm
        </Button>
      </S.Action>
    </S.CustomModal>
  );
};

export default EnableMetrics;
