import React from 'react';
import _ from 'lodash';
import * as S from './style';

export default function EarnedCredit(props) {

  return (
    <S.EarnedCreditWrapper {...props}>
      <div className="earnedCredit__title">Earned Credits</div>
      <div className="earnedCredit__amount">{Number(props.earnedCredit).toUSDFormat()}</div>
    </S.EarnedCreditWrapper>
  )
}