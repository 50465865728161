import { isSafari } from 'react-device-detect';
import RecorderJS from 'recorder-js';

// It's hacking code! Please don't flow it
const hackingRecordTime = newTime => {
  if (!newTime) {
    return 0;
  }
  if (isSafari) {
    return newTime + newTime * 0.08;
  } else if (newTime < 20) {
    return newTime;
  }
  return newTime;
};

export const recordAudio = () => {
  return new Promise(async resolve => {
    let recorder = null;
    let stream = null;
    let bufferBlob = null;
    let audio = null;
    let audioContext = null;
    let recording = 0;
    let intervalId = null;

    try {
      stream = await getAudioStream();
    } catch (error) {
      console.log(error);
    }

    const renderTimeCounter = () => {
      recording = 0;
      let channelCount = 2;

      intervalId = setInterval(() => {
        let outputLatency = audioContext.outputLatency || audioContext.getOutputTimestamp().contextTime;
        const currentRecording = Math.floor(
          audioContext.currentTime +
            outputLatency * channelCount * audioContext.currentTime +
            audioContext.currentTime * audioContext.baseLatency * channelCount,
        );

        recording = Math.floor(audioContext.currentTime);
      }, 100);
    };

    const start = async () => {
      audioContext = new (window.AudioContext || window.webkitAudioContext)({
        latencyHint: 'playback',
        sampleRate: 44100,
      });

      recorder = new RecorderJS(audioContext, {
        onAnalysed: data => {},
      });

      recording = 0;

      await recorder.init(stream);

      recorder && (await recorder.start());
      renderTimeCounter();
    };

    const stop = async () => {
      clearInterval(intervalId);
      const { buffer } = await recorder.stop();

      bufferBlob = exportBuffer(buffer[0]);
      stream.getTracks().forEach(track => track.stop());

      recording = 0;
      pause();
    };

    const getBlob = () => {
      return bufferBlob;
    };

    const getRecordTime = () => {
      return recording;
    };

    const resume = () => {
      if (audio) {
        const resume = () => audio.play();
        resume();
      }
    };

    const pause = () => {
      if (audio) {
        const pause = () => audio.pause();
        pause();
      }
    };

    const play = () => {
      new Promise(async resolve => {
        if (bufferBlob) {
          try {
            const audioUrl = await URL.createObjectURL(bufferBlob);
            audio = new Audio(audioUrl);
            const play = () => audio.play();
            play();
          } catch (error) {
            console.log(error);
          }
        }
      });
    };

    resolve({ start, stop, pause, resume, play, getBlob, getRecordTime });
  });
};

/**
 * Get access to the users microphone through the browser.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Using_the_new_API_in_older_browsers
 */
function getAudioStream() {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }

  const params = { audio: true, video: false };

  return navigator.mediaDevices.getUserMedia(params);
}

/**
 * Snippets taken from:
 * https://aws.amazon.com/blogs/machine-learning/capturing-voice-input-in-a-browser/
 */

const recordSampleRate = 44100;

/**
 * Samples the buffer at 16 kHz.
 */
function downsampleBuffer(buffer, exportSampleRate) {
  if (exportSampleRate === recordSampleRate) {
    return buffer;
  }

  const sampleRateRatio = recordSampleRate / exportSampleRate;
  const newLength = Math.round(buffer.length / sampleRateRatio);
  const result = new Float32Array(newLength);

  let offsetResult = 0;
  let offsetBuffer = 0;

  while (offsetResult < result.length) {
    const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    let accum = 0;
    let count = 0;

    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
}

function floatTo16BitPCM(output, offset, input) {
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
}

function writeString(view, offset, string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

/**
 * Encodes the buffer as a WAV file.
 */
function encodeWAV(samples) {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  writeString(view, 0, 'RIFF');
  view.setUint32(4, 32 + samples.length * 2, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, 1, true);
  view.setUint32(24, recordSampleRate, true);
  view.setUint32(28, recordSampleRate * 2, true);
  view.setUint16(32, 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, 'data');
  view.setUint32(40, samples.length * 2, true);
  floatTo16BitPCM(view, 44, samples);

  return view;
}

/**
 * Samples the buffer at 16 kHz.
 * Encodes the buffer as a WAV file.
 * Returns the encoded audio as a Blob.
 */
function exportBuffer(recBuffer) {
  // const downsampledBuffer = downsampleBuffer(recBuffer, 16000);
  const encodedWav = encodeWAV(recBuffer);
  const audioBlob = new Blob([encodedWav], {
    type: 'audio/mp3',
  });

  return audioBlob;
}
