import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Arrow = styled.button`
  background: #EAEEF1;
  border-radius: 3px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 5px auto;
  background-position: center;
  background-color: #EAEEF1;
  cursor: pointer;
  outline: none;
  border: none;
  width: 24px;
  height: 24px;

  &.previous {
    background-image: url(${CDN_URL}/images/left_arrow_grey.svg);
  }

  &.next {
    background-image: url(${CDN_URL}/images/right_arrow_grey.svg);
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Text = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #728096;
  margin: 0 15px;

  .highlight {
    font-weight: 600;
  }
`;

export const Container = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;

export const  Wrapper = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;

  &.evfPagination--onlyOnePage {
    padding: 10px 0;
  }
`;