import React from 'react';
import { connect } from 'react-redux';

import ConfirmModal from 'shared/ConfirmModal/component';

const ConfirmLeaveModal = ({ title, onConfirm, message, confirmTitle, dispatch, isConfirmModalOpen }) => {
  return (
    <ConfirmModal
      title={title}
      isConfirmModalOpen={isConfirmModalOpen}
      content={<div>{message}</div>}
      onConfirm={onConfirm}
      confirmButtonTitle={confirmTitle}
      cancelButtonTitle="Cancel"
      newStyle
      noIcon
      dispatch={dispatch}
      hasHoverState
      hasCloseIcon
    />
  );
};

const mapStateToProps = state => {
  const { isConfirmModalOpen, confirmModal } = state;
  return {
    isConfirmModalOpen,
    confirmModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmLeaveModal);
