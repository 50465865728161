import styled, { css } from 'styled-components';
import { ReactComponent as CustomPointer } from 'assets/images/custom-color-picker-cursor.svg';

export const InputStyles = {
  input: {
    width: '235px',
    height: '42px',
    paddingLeft: '12px',
    border: 'none',
    outline: 'none',
  },
  label: {
    fontSize: '12px',
    color: '#000000',
    lineHeight: '18px',
  },
  wrap: {
    border: '1px solid #E1E5EC',
    borderRadius: '3px',
  },
};

export const SaturationPointer = styled(CustomPointer)`
  transform: translate(-16px, -15px);
`;

export const HuePointer = styled(CustomPointer)`
  transform: translate(-15px, -10px);
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.01);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
`;

export const Header = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0px;
  color: #202353;
`;

export const Close = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export const ColorPickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const FloaterTargetContainer = styled.div`
  span {
    height: 34px;
  }
`;

export const ColorPickerContainer = styled.div`
  width: 255px;
  height: 378px;
  padding: 10px;
  background: #fff;
  border: 1px solid #dadaea;
  box-sizing: border-box;

  border-radius: 5px;
  user-select: none;
`;

export const SaturationContainer = styled.div`
  width: 235;
  height: 235px;
  position: relative;
  padding-bottom: 75%;
`;

export const HueContainer = styled.div`
  margin-top: 20px;
  width: 235px;
  height: 10px;
  position: relative;
  border-radius: 99999px;
  margin-bottom: 15px;
  div.hue-horizontal {
    border-radius: 99999px;
  }
`;

export const EditableInputContainer = styled.div`
  display: flex;
`;
export const color = styled.div`
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background: ${props => (props.color.includes('#') ? props.color : `#${props.color}`)};
  cursor: pointer;
`;
export const swatch = styled.div``;
export const popover = styled.div`
  position: absolute;
  z-index: 2;
`;
export const cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
