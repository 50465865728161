import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Header, Wrapper, Actions, Content } from 'shared/Styles/ModalLayout';

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #000;
  margin-top: 10px;
`;

export const Name = styled.div`
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #263d52;
`;

export const ItemContainer = styled.div`
  padding: 8px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .sb-avatar {
    flex: 0 0 29px;
    margin-right: 15px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const UpgradeButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  color: #fff;
  padding: 15px 50px;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ModalWrapper = styled(Modal)`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #263d52;

  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 646px;
  }

  > .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }

  ${Header} {
    padding: 30px 44px 18px 26px;
    display: block;
  }

  ${Wrapper} {
    max-height: calc(100vh - 110px);
  }

  ${Content} {
    padding: 0 18px;
    position: relative;
    min-height: 150px;
    max-height: 300px;
    overflow: hidden;
    padding-bottom: 30px;

    &.showOverlay {
      padding-bottom: 0px;
      :before {
        content: '';
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        height: 230px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 96.35%);
        position: absolute;
        z-index: 1;
      }
    }
  }

  ${Actions} {
    padding: 0 30px 30px;
    justify-content: center;

    ${UpgradeButton} {
      margin: 0;
      padding: 15px 50px;
    }
  }
`;
