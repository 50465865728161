import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal } from 'actions/modal';
import { runOnboarding, maximizeCheckList } from 'redux/onboarding/actions';
import Component from './component';

const mapDispatch = dispatch => {
  return {
    runOnboarding: bindActionCreators(runOnboarding, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    maximizeCheckList: bindActionCreators(maximizeCheckList, dispatch),
  };
};

export default connect(null, mapDispatch)(Component);
