import React from 'react';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import moment from 'moment';
import { MultiplePasteMessage } from 'shared/Notifications';
import _ from 'lodash';
import { Workout } from 'types/model';
import { toggleModal } from 'actions/modal';
import { changeCalendarStartDate } from '../../actions/calendar';
import { DateTime } from 'luxon';
import WorkoutHistoryDetail from 'components/WorkoutHistoryDetail';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import LogWorkoutModal from 'components/LogWorkoutModal';
import { onSubmitting, onSubmitted } from 'actions/submitStatus';
import Request, { axiosInstance } from 'configs/request';
import { convertWorkoutUnits, prepareDataToTrackWorkout } from 'helpers/workout';
import { saveAssignmentToLibrary } from 'actions/workout/save';
import { WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import LogActivityModal from 'components/LogActivityModal';
import { getIdRemoveSingleWorkout } from 'redux/calendar/calendar.actionCreators';

export const REQUEST_GET_ASSIGNMENTS = 'REQUEST_GET_ASSIGNMENTS';
export const SUCCESS_GET_ASSIGNMENTS = 'SUCCESS_GET_ASSIGNMENTS';
export const COPY_ASSIGNMENT = 'COPY_ASSIGNMENT';
export const COPIED_ASSIGNMENT = 'COPIED_ASSIGNMENT';
export const RESET_COPY_ASSIGNMENT = 'RESET_COPY_ASSIGNMENT';
export const ADD_ASSIGNMENT_TO_DATE = 'ADD_ASSIGNMENT_TO_DATE';
export const CLIENT_TRAINING_CLEAR_COPY_ASSIGMENT = 'CLIENT_TRAINING_CLEAR_COPY_ASSIGMENT';
export const SUCCESS_GET_LOGGED_ACTIVITIES = 'SUCCESS_GET_LOGGED_ACTIVITIES';

export const Types = {
  SUCCESS_GET_ASSIGNMENTS_AND_UPDATE: 'SUCCESS_GET_ASSIGNMENTS_AND_UPDATE',
  SUCCESS_GET_LOGGED_ACTIVITIES_AND_UPDATE: 'SUCCESS_GET_LOGGED_ACTIVITIES_AND_UPDATE',
};

const handleChangeWeekCurrentLogActivity = (id, calendarType, startActivityDate, dispatch) => {
  let startDate = DateTime.local().startOf('week');

  if (startActivityDate) {
    startDate = moment(startActivityDate).startOf('week')._d;
    startDate = DateTime.fromISO(moment(startDate).format('YYYY-MM-DD'));
  }

  const endDate = startDate.plus({ days: 7 * calendarType });

  return {
    client: id || '',
    start_date: startDate,
    end_date: endDate,
  };
};

export const updateAssignmentInfo = workout => {
  return {
    type: 'UPDATE_ASSIGNMENT_INFO',
    workout,
  };
};

export const removeAssignment = workout => {
  return {
    type: 'DELETE_ASSIGNMENT',
    workout,
  };
};

export const copyAssignment = item => {
  toast(<MultiplePasteMessage />);

  return { type: COPY_ASSIGNMENT, item };
};

export const copiedAssignment = multiPaste => {
  return {
    type: COPIED_ASSIGNMENT,
    multiPaste,
  };
};

export const resetCopyAssignment = multiPaste => {
  return {
    type: RESET_COPY_ASSIGNMENT,
    multiPaste,
  };
};

export const increaseWorkoutPage = () => {
  return {
    type: 'INCREASE_PAGE_WORKOUT',
  };
};

export const decreaseWorkoutPage = () => {
  return {
    type: 'DECREASE_PAGE_WORKOUT',
  };
};

export const addAssignmentToDate = (date, data) => {
  const workout = new Workout();
  const parseData = workout.parseFromAssignment(data);

  return {
    type: ADD_ASSIGNMENT_TO_DATE,
    date,
    data: parseData,
  };
};

export const copyAssignmentToDate = (params, multiPaste = false) => {
  return dispatch => {
    dispatch(copiedAssignment(multiPaste));

    return dispatch(
      Request.post(
        { url: `/api/workout/v2/assignment/${params.assignment}/copy`, data: params },
        true,
        result => {
          const data = result.data && result.data.data ? result.data.data : {};
          dispatch(addAssignmentToDate(data.day, data));
        },
        error => dispatch({ type: 'ERROR_COPY_ASSIGNMENT', error }),
      ),
    );
  };
};

export const removeAssignmentFromDate = params => {
  return Request.delete(
    { url: `/api/workout/v2/assignment/${params.assignment._id}/delete` },
    true,
    (result, { dispatch }) => {
      dispatch(removeAssignment(params.assignment));
      const { data } = result;
      if (data) {
        dispatch(getIdRemoveSingleWorkout(_.get(params, 'assignment._id', '')));
      }
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_DELETE_ASSIGNMENT', error }),
  );
};

export const updateAssignment = (params, onSuccess = null) => {
  return dispatch => {
    if (typeof params.formNamespace === 'string') {
      dispatch(onSubmitting(params.formNamespace));
    }

    return dispatch(
      Request.put(
        { url: `/api/workout/v2/assignment/${params.assignment}/update`, data: params },
        true,
        () => {
          dispatch(changeCalendarStartDate());

          if (typeof params.formNamespace === 'string') {
            dispatch(onSubmitted(params.formNamespace, true));
          }

          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        },
        () => {
          if (typeof params.formNamespace === 'string') {
            dispatch(onSubmitted(params.formNamespace, false));
          }
        },
      ),
    );
  };
};

export const WORKOUT_TYPE = {
  DETAIL: 1,
  HISTORY: 2,
  LOGWORKOUT: 3,
  LOGACTIVITY: 4,
};

export const getAssignmentDetail = (assId, mode, type) => {
  return (dispatch, getState) => {
    const state = getState();
    const client = state.rootReducer.client.workingClientDetail;
    const units = client.preferences;

    if (type === WORKOUT_TYPE.LOGACTIVITY) {
      return dispatch(
        Request.get({ url: `/api/log-activities/${assId}` }, true, res => {
          const { data } = res.data;

          dispatch(toggleModal(true, <LogActivityModal history mode={mode} activity={data} />));

          const logActivityDateTime = data.end_time || data.start_time;
          const calendarType = _.get(state, 'calendarType', 1);
          const requestChangeWeek = handleChangeWeekCurrentLogActivity(
            client._id,
            calendarType,
            logActivityDateTime,
            dispatch,
          );

          dispatch(
            changeCalendarStartDate(
              {
                client: requestChangeWeek.client,
                start_date: requestChangeWeek.start_date.toFormat('MM-dd-yyyy'),
                end_date: requestChangeWeek.end_date.toFormat('MM-dd-yyyy'),
              },
              requestChangeWeek.start_date,
            ),
          );
        }),
      );
    } else {
      return dispatch(
        Request.get({ url: `/api/workout/v2/assignment/detail/${assId}` }, true, (data, { dispatch, getState }) => {
          const woData = data.data.data;
          const workout = new Workout();
          const parseData = convertWorkoutUnits(workout.parseFromAssignment(woData), units);
          const originWorkout = _.cloneDeep(parseData);
          const workingWorkout = _.cloneDeep(parseData);

          switch (type) {
            case WORKOUT_TYPE.DETAIL: {
              dispatch(
                toggleModal(
                  true,
                  <WorkoutDetailModal
                    trackable
                    hasTrackedWorkout
                    type={WORKOUT_BUILDER_TYPES.ASSIGNMENT}
                    workingWorkout={new Workout(workingWorkout)}
                    fetchWorkoutsInRange={fetchWorkoutsInRange}
                    clientData={client}
                    pdfType="assignment"
                    onSave={async workout => {
                      try {
                        const data = {
                          assignment: workout._id,
                          title: workout.title,
                          description: workout.description,
                          author: workout.author ? workout.author._id : null,
                          share: workout.share,
                          sections_target: workout.sections,
                          client: client._id,
                          background: workout.background,
                        };
                        const response = await dispatch(updateAssignment(data));
                        return response.data.data;
                      } catch (error) {
                        return Promise.resolve(null);
                      }
                    }}
                    onClose={() => dispatch(push(`/home/client/${client._id}/calendar`))}
                    saveToLibrary={workout => dispatch(saveAssignmentToLibrary({ assignment: workout._id }))}
                    onDelete={workout => {
                      const params = { assignment: workout, client: client._id };
                      dispatch(removeAssignmentFromDate(params));
                    }}
                    logWorkout={workout => {
                      const {
                        rootReducer: {
                          exercise: { fields },
                        },
                      } = state;
                      const trackingWorkout = prepareDataToTrackWorkout(workout, fields);
                      dispatch(push(`/home/client/${client._id}/calendar/${trackingWorkout._id}/tracking`));
                      dispatch(
                        toggleModal(
                          true,
                          <LogWorkoutModal
                            tracking
                            date={DateTime.fromISO(woData.date)}
                            day={woData.day}
                            originWorkout={new Workout(trackingWorkout)}
                            workingWorkout={new Workout(trackingWorkout)}
                          />,
                        ),
                      );
                    }}
                  />,
                ),
              );
              break;
            }
            case WORKOUT_TYPE.HISTORY:
              dispatch(
                toggleModal(
                  true,
                  <WorkoutHistoryDetail
                    day={woData.day}
                    history
                    mode={mode}
                    originWorkout={new Workout(originWorkout)}
                    workingWorkout={new Workout(workingWorkout)}
                  />,
                ),
              );
              break;
            case WORKOUT_TYPE.LOGWORKOUT:
              const {
                rootReducer: {
                  exercise: { fields },
                },
              } = getState();
              const logWorkout = prepareDataToTrackWorkout(parseData, fields);
              dispatch(
                toggleModal(
                  true,
                  <LogWorkoutModal
                    day={woData.day}
                    date={DateTime.fromISO(woData.date)}
                    mode={mode}
                    tracking
                    originWorkout={new Workout(logWorkout)}
                    workingWorkout={new Workout(logWorkout)}
                  />,
                ),
              );
              break;
            case WORKOUT_TYPE.LOGACTIVITY:
              dispatch(
                toggleModal(
                  true,
                  <LogActivityModal
                    day={woData.day}
                    history
                    mode={mode}
                    originWorkout={new Workout(originWorkout)}
                    workingWorkout={new Workout(workingWorkout)}
                  />,
                ),
              );
              break;
            default:
              break;
          }
        }),
      );
    }
  };
};

export const fetchWorkoutsInRange = data => {
  return (dispatch, getState) => {
    const state = getState();
    const client = state.rootReducer.client.selected;
    const params = { ...data, client };

    return axiosInstance.get('/api/workout/v2/assignments', { params }).then(response => {
      const workoutData = {};
      response.data.day_data.forEach(itm => {
        const workouts = itm.assignments.map(itmAssi => {
          const workout = new Workout();
          return workout.parseFromAssignment(itmAssi);
        });
        const day = moment(itm.day, 'MM-DD-YYYY').format('YYYY-MM-DD');
        workoutData[day] = { day, workouts: workouts };
      });
      return workoutData;
    });
  };
};

export const highFiveLogActivities = (id, type) => {
  return dispatch => {
    return dispatch(
      Request.patch({ url: `/api/log-activities/${id}/high-five` }, true, result => {
        const success = result.data && result.data.data.success;
        if (success) {
          dispatch(getAssignmentDetail(id, 'update', type));
        }
      }),
    );
  };
};

export const highFiveWorkoutHistory = (id, type) => {
  return dispatch => {
    return dispatch(
      Request.patch({ url: `/api/assignment/${id}/high-five` }, true, result => {
        const success = result.data && result.data.data.success;
        if (success) {
          dispatch(getAssignmentDetail(id, 'update', type));
        }
      }),
    );
  };
};

export const getHeartRateAssignment = id => {
  return (dispatch, getState) => {
    const state = getState();
    const client = state.rootReducer.client.selected;
    const params = { assignment: id, client };
    return dispatch(Request.get({ url: `/api/v1/heart-rate-assignments`, params }, true));
  };
};
