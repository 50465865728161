import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import * as S from './styles';
import PricingModal from '../PricingModal';
import { CURRENCIES, PACKAGE_PRICING_TYPES } from 'constants/commonData';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { pluralize } from 'utils/commonFunction';
import { connect } from 'react-redux';

const PackagePricing = props => {
  const { data, onChange, status, isEditMode, onSave, isArchiveClientTrial, allowTrialOnce } = props;
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [pricingData, setPricingData] = useState(null);

  useEffect(() => {
    setPricingData(data);
  }, [data]);

  const handleAddPricing = () => {
    setShowPricingModal(true);
  };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  const handleUpdatePricing = data => {
    onChange(data);
  };

  const symbol = useMemo(() => {
    let obj = CURRENCIES[_.get(pricingData, 'currency')] || {};

    return obj.symbol || '';
  }, [pricingData]);

  return (
    <S.Wrapper>
      <S.Header>
        <span>Pricing</span>
        {!!pricingData && isEditMode && <EditIcon onClick={handleAddPricing} />}
      </S.Header>
      {!!pricingData && (
        <>
          <S.PricingDetail>
            <S.PricingTypeLabel>{PACKAGE_PRICING_TYPES[pricingData.type].shortLabel}</S.PricingTypeLabel>
            <S.PricingValueContainer>
              <S.CurrencySymbol>{symbol}</S.CurrencySymbol>
              <S.PricingValue>{pricingData.amount.toSeperateFormat()}</S.PricingValue>
            </S.PricingValueContainer>
          </S.PricingDetail>
          {pricingData.type === PACKAGE_PRICING_TYPES.recurring.value && (
            <S.Over>
              Every {pluralize(pricingData.recurring_interval_type, pricingData.every, true)}
              {!!pricingData.end_after && <span>&nbsp;for {pluralize('time', pricingData.end_after, true)}</span>}
            </S.Over>
          )}
          {_.get(pricingData, 'enable_trial', false) && (
            <S.TrialPeriod>{_.get(pricingData, 'trial_period')}-Day Trial</S.TrialPeriod>
          )}
        </>
      )}
      {!pricingData && !!isEditMode ? <S.AddButton onClick={handleAddPricing}>Add Pricing</S.AddButton> : null}
      {showPricingModal && (
        <PricingModal
          onClose={handleClosePricingModal}
          onUpdate={handleUpdatePricing}
          originData={{
            ...pricingData,
            is_archive_on_trial_cancellation: isArchiveClientTrial,
            allow_trial_once: allowTrialOnce,
          }}
          packageStatus={status}
          onSave={onSave}
        />
      )}
    </S.Wrapper>
  );
};
const mapState = state => {
  const {
    user,
    rootReducer: { setupPayment, packageDetail },
  } = state;

  const {
    workingPackage: { price },
  } = packageDetail.toJS();

  return {
    user,
    payment: setupPayment.toJS(),
    trialPeriod: _.get(price, 'trial_period', null),
  };
};

export default connect(mapState, null)(PackagePricing);
