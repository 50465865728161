import styled from 'styled-components';
import { FormGroup as FG } from 'shared/FormControl';

export const FormGroup = styled(FG)`
  margin-bottom: 20px;

  label {
    margin-bottom: 9px;
  }

  input {
    ::placeholder {
      color: #000;
      opacity: 0.4;
    }
  }
`;

export const Note = styled.div`
  background: #fff4d9;
  border-radius: 5px;
  padding: 9px 10px 10px;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #b56a26;
  margin: 0 30px 10px;
`;

export const NameLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GroupInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  ${FormGroup} {
    margin-bottom: 0;
    width: auto;
  }
`;

export const FormContainer = styled.div`
  padding: 10px 30px 30px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
`;

export const Wrapper = styled.div`
  button {
    &.button--remove-metric {
      border: none;
      box-shadow: none;
      color: #758094;
      padding: 0;
    }

    &.button--save-metric {
      padding: 6px 39px;
    }

    &.button--create-metric {
      padding: 6px 33px;
    }

    &.button--save-metric--disabled {
      padding: 6px 39px;
      background-color: #c4c4c4;
      cursor: not-allowed;
      color: #fff;
    }
  }

  &.adding {
    ${Actions} {
      box-shadow: none;
      padding-bottom: 30px;
      padding-top: 0;
    }
  }

  .__react_component_tooltip.app-tooltip {
    padding: 15px;
    max-width: 260px;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
`;
