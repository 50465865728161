import styled, {css} from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const CalendarIcon = styled.div`
  width: 16px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url(${CDN_URL}/images/calendar_grey.svg);
`

export const Arrow = styled.div`
  width: 32px;
  height: 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    opacity: .4;
  }

  :hover {
    background-color: #ECEDEE;
  }
`;

export const Container = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  background: #FFFFFF;
  border-radius: 3px;

  :hover {
    background-color: #F6F7F9;
  }

  > button {
    background: none !important;
  }

  .rdt div.form-control[type="text"] {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4F4F4F;
    cursor: pointer;
    margin: 0 7px;
    font-size: 12px;
    line-height: 12px;

    svg {
      margin-right: 8px;
    }

    :hover {
      ${CalendarIcon} {
        background-image: url(${CDN_URL}/images/calendar_purple.svg);
      }

    }

    span {
      margin-top: 2px;
    }
  }

  .rdtPicker {
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 8px);
    padding: 0;
    width: auto;
    min-width: 236px;
    font-family: 'Open Sans';
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid #EEEEEE;
    background-color: #fff;
    border-radius: 3px;

    table {
      border-collapse: collapse;
      border-spacing: 0px 9px;

      thead {
        tr:first-child {
          th {
            font-size: 13px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #273d52;
            border: none;
            height: 53px;
            padding-bottom: 15px;
            position: relative;

            :hover {
              background: transparent;
              color: #745ee6;
            }

            &.rdtPrev,
            &.rdtNext {
              width: 33px;
              span {
                text-indent: -9999px;
                display: block;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: auto;
                position: absolute;
                left: -5px;
                top: 0;
                width: 38px;
                height: 38px;
                opacity: .4;

                :hover {
                  background-color: #ECEDEE;
                }
              }
            }
            &.rdtPrev {
              span {
                background-image: url(${CDN_URL}/images/arrow_left.svg);
              }
            }
  
            &.rdtNext {
              span {
                left: unset;
                right: -5px;
                background-image: url(${CDN_URL}/images/arrow_right.svg);
              }
            }
          }
        }
      }
    }

    .dow {
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      text-transform: uppercase;
      color: #99A1B1;
      height: 24px;
    }
  
    .rdtDay {
      color: #5A636C;
      width: auto;
      height: auto;
      padding: 0;
      background-color: #fff;
      border: 9px solid #fff;
      border-left: 0;
      border-right: 0;
      text-shadow: none;

      &:hover {
        background-color: #fff;
        .content {
          > div {
            background-color: #eee;
          }
        }
      }

      &.rdtOld {
        color: #99A1B1;
      }
  
      .content {
        padding: 2px 7px;
        display: flex;
        justify-content: center;

        > div {
          font-size: 10px;
          font-weight: unset;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 50%;
        }
      }
  
      &.inRange {
        .content {
          background-color: rgba(114, 110, 228, 0.15);
        }
        :hover {
          .content {
            > div {
              background-color: #d8dadc;
            }
          }
        }

        :first-of-type,
        &.rangeStart {
          .content {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }
        }
  
        :last-of-type {
          .content {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }

      &.rdtToday {
        ::before {
          display: none;
        }
        .content {
          > div {
            background-color: #5C5BBD;
            color: #fff;
          }
        }
        :hover {
          .content {
            > div {
              color: #5a636c;
              background-color: #eee;
            }
          }
        }
        &.inRange {
          :hover {
            .content {
              > div {
                background-color: #d8dadc;
              }
            }
          }
        }
      }

      &.rangeEnd {
        .content {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }

    .rdtDays,
    .rdtMonths,
    .rdtYears {
      border-radius: 3px;
      padding: 0 5px;
    }

    .rdtDays,
    .rdtMonths,
    .rdtYears {
      position: relative;

      ::after {
        content: '';
        left: 0;
        right: 0;
        top: 38px;
        height: 18px;
        background-color: #fff;
        position: absolute;
        border-top: 1px solid #eee;
      }

      ::before {
        content: '';
        left: 0;
        right: 0;
        top: 38px;
        height: 18px;
        background-color: #fff;
        position: absolute;
        border-top: 1px solid #eee;
      }
    }
  }
`;