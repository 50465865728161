import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const StartWorkout = styled.div`
  display: inline-block;
  padding: 8px 13px;
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  text-align: center;
  color: #7470ef;
  background: #ffffff;
  border-radius: 13px;
`;

export const Header = styled.div`
  margin-bottom: 20px;
  padding: 0 10px;

  .today {
    font-weight: bold;
    font-size: 7px;
    line-height: 11px;
    text-transform: uppercase;
  }

  .hello {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #1e0a3c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const WorkoutContent = styled.div`
  z-index: 2;
  position: relative;
`;

export const Workout = styled.div`
  border-radius: 5.41063px;
  padding: 27px 25px 13px;
  margin-bottom: 13px;
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 124px;

  .workout__title {
    font-weight: bold;
    font-size: 7px;
    line-height: 11px;
    color: #4fdfac;
    margin-bottom: 4px;
  }

  .workout__name {
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 14px;
  }
`;

export const TaskName = styled.div`
  flex: 1 1;
  overflow: hidden;
  margin: 0 20px 0 8px;
`;

export const TaskItem = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 8.657px;
  line-height: 13px;
  color: #1e0a3c;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    flex: 0 0 13px;
  }

  img {
    opacity: 0.5;
    width: 5px;
  }
`;

export const TaskDivide = styled.div`
  margin: 9px 0 9px 20px;
  height: 1px;
  background-color: #767676;
  opacity: 0.1;
`;

export const Task = styled.div`
  background: #ffffff;
  box-shadow: 0px 5.41063px 12.4444px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 11px 9px;

  .task__header {
    font-weight: bold;
    font-size: 8.657px;
    line-height: 12px;
    color: #1e0a3c;
    margin-bottom: 11px;
  }
`;

export const Content = styled.div`
  padding: 0 10px;

  .let-do-this {
    font-weight: bold;
    font-size: 8.657px;
    line-height: 12px;
    color: #1e0a3c;
    margin-bottom: 7px;
  }
`;

export const NotificationIcon = styled.div`
  width: 11px;
  height: 13px;
  position: absolute;
  background: transparent url(${CDN_URL}/images/app_notification.svg) no-repeat center;
  background-size: contain;
  top: 28px;
  right: 14px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;

  ${StartWorkout} {
    color: ${props => props.primaryColor};
  }

  ${Header} {
    .today {
      color: ${props => props.primaryColor};
    }
  }

  ${Workout} {
    background: transparent url(${props => props.workoutBackground}) no-repeat center;
    background-size: cover;
  }

  ${Task} {
    ${TaskItem} {
      svg.active-checkbox {
        path {
          fill: ${props => props.primaryColor};
        }
      }
    }
  }

  ${props =>
    props.useCustomTitle &&
    css`
      ${Workout} {
        .workout__title {
          color: #ffffff;
          opacity: 0.6;
        }
      }
    `}

  ${props =>
    props.customBackground &&
    css`
      ${Workout} {
        :before {
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          background-color: #131313;
          opacity: 0.2;
          position: absolute;
        }
      }
    `}

  ${props =>
    props.customTheme &&
    css`
      ${Task} {
        box-shadow: 0px 5px 23px rgba(192, 192, 192, 0.1);
        ${TaskItem} {
          svg.active-checkbox {
            path {
              fill: ${props => props.secondaryColor};
            }
          }
        }
      }
    `}
`;

export const AppIconItemArea = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(4, 55px);
  grid-template-rows: repeat(4, 55px);
  column-gap: 14px;
  row-gap: 29px;
  width: 100%;
  height: 286px;
  top: 32px;
  left: 0;
  padding: 54px 14px 0 14px;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AppIconItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  margin: auto;
  border-radius: ${props => (props.isAndroid ? '50%' : '15.5px')};
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  box-sizing: border-box;

  &:nth-child(4n) {
    margin-right: 0;
  }

  .text {
    position: absolute;
    top: 100%;
    width: 100%;
    color: #000000;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    transform: translateY(9px);
  }

  &.active {
    box-shadow: 0px 0px 24.8696px rgb(13 25 54 / 15%);
    background-color: white;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
`;

export const ScreenName = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  .android-title-container {
    transform: translateY(19.5px);
    .title {
      margin-bottom: 4px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    opacity: 0.3;
  }
`;
