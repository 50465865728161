import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';
import ButtonIcon from 'shared/ButtonIcon';

export const DefaultLabel = styled.div`
  margin-top: 22px;
`;

export const LabelHeader = styled.div`
  display: flex;
  margin-bottom: 6px;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  color: #6a778a;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const MoreLabelButton = styled.p`
  color: #5158cf;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      color: #c4c4c4;
      cursor: not-allowed;
    `}
`;

export const ListArea = styled.div`
  padding: 20px 20px 33px 20px;
  background: #a7adb81a;
  border-radius: 4px;
  .workout-select__menu-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .workout-select__menu {
    border: 1px solid #dadfea;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    margin-bottom: 9px;
  }
  .workout-select__option--is-selected {
    color: #5158cf !important;
    font-weight: bold;
    background-color: #ffffff !important;
  }
  .workout-select__option--is-focused {
    background-color: #ecebfe !important;
    color: #5158cf !important;
    font-weight: bold;
  }
  .workout-select__control {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    height: 36px;
    padding: 0 6px;
    background: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
    border: 1px solid #dadfea;

    :hover {
      border-color: #5158cf;
    }

    &.workout-select__control--menu-is-open,
    &.workout-select__control--is-focused {
      border-color: #5158cf !important;
    }

    .workout-select__indicator-separator {
      display: none;
    }

    .workout-select__dropdown-indicator svg {
      width: 12px;
      height: 12px;
    }

    .workout-select__value-container--has-value {
    }

    .workout-select__input {
      transform: translate(0px, -1px);
      input {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #777777;
        opacity: 0.5;
      }
    }
    .workout-select__placeholder,
    .workout-select__single-value {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: black;
    }
    .workout-select__value-container {
      transform: translateY(1px);
    }
  }
`;

export const LabelName = styled.div`
  height: 100%;
  min-width: 103px;
  color: #202353;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  margin-right: 32px;
  padding: 7px 0;
  max-width: 103px;
  white-space: nowrap;
  overflow: hidden;

  ${props =>
    props.hasTooltip &&
    css`
      cursor: pointer;
    `}
`;

export const LabelItem = styled.div`
  max-width: 125px;
  color: #202353;
  background: white;
  opacity: 0.6;
  padding: 7px 21px;
  margin: 0 5px 0 0;
  border: 1px solid #a7adb81a;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;

  &:last-child,
  &:nth-child(4n + 4) {
    margin-right: 0;
  }

  &:nth-child(n + 5) {
    margin-top: 8px;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: #5158cf;
    opacity: 1;
    background: #eeefff;
    border: 1px solid #5158cf;
  }

  ${props =>
    props.widthItem &&
    css`
      width: ${props.widthItem}px;
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      &:hover:not(.active),
      &:focus:not(.active),
      &:active:not(.active) {
        color: #202353;
        background: white;
        opacity: 0.6;
        border: 1px solid #a7adb81a;
      }
    `}
`;

export const LabelItemArea = styled.div`
  display: inherit;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 17px;
  justify-content: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SPopup = styled(Popup)`
  &.action-popup {
    z-index: 2000 !important;
    border: 1px solid #dadfea !important;

    &.ui.popup {
      border-radius: 5px !important;
      margin-bottom: -1px !important;
      transform: translateX(1px) translateY(calc(47% - 34px)) !important;
      box-shadow: none;
      margin-left: 0 !important;

      &::before {
        top: 13% !important;
        background: white !important;
      }
    }
  }

  &::before {
    background: white !important;
    border: 1px solid #dadfea;
    box-shadow: none !important;
  }

  &::after {
    background: white !important;
    border: 1px solid #dadfea;
    box-shadow: none !important;
  }

  .content {
    position: relative;
    padding: 10px 0 0;
    font-size: 13px;
    line-height: 18px;
    background: white;
    border-radius: 5px;
    z-index: 2;
  }
`;

export const MoreLabelWrapper = styled.div`
  > div {
    display: flex;
  }
`;

export const MoreLabelHeader = styled.div`
  color: #202353;
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 5px;
  padding: 0 16px;
`;

export const MoreLabelBody = styled.div`
  padding: 0 16px;
`;

export const MoreLabelBodyDescription = styled.div`
  color: #202353;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  opacity: 0.8;
  margin-bottom: 15px;
`;

export const MoreLabelFooter = styled.div`
  display: flex;
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 7px 15px;
  box-shadow: 0px -4px 16px rgb(107 111 183 / 10%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: flex-end;
  align-items: center;

  .cancelButtonClassName {
    button {
      background-color: transparent !important;

      span {
        color: #202353 !important;
      }

      &:hover,
      &:active,
      &:focus {
        background: rgb(245 247 249) !important;

        span {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      background: #5559ff !important;
    }
  }
`;

export const MoreLabelList = styled.div`
  margin-bottom: 10px;
  height: 178px;
  overflow-y: auto;
`;

export const MoreLabelItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
  cursor: pointer;

  .evf-checkbox {
    padding-left: 24px;
  }

  .checkbox-icon {
    width: 16px;
    height: 16px;
    background-size: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MoreLabelText = styled.span`
  width: calc(100% - 33px);
  color: #202353;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
