// Libs
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const ConfirmTypingModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 467px;
    height: fit-content;
    max-height: 745px;
    padding: 30px;
    border-radius: 8px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .header {
      display: grid;
      grid-template-columns: 25px 1fr;
      align-items: center;
      justify-items: flex-start;
      gap: 10px;
      padding: 0px;

      img.icon-header {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        object-fit: contain;
      }
    }

    .description {
      margin: 15px 0px;
    }

    .content {
      padding: 0px;
      margin: 0px 0px 30px 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
    }

    .actions {
      border-top: none;
      background: none;
      text-align: unset;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: flex-end;
    }

    > :last-child {
      padding: 0px !important;
      border-radius: 0px !important;
    }

    .close-button {
      right: -8px;
      top: -8px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .title-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }

    .description-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #323c47;

      strong.coach-name {
        font-weight: 600;
      }
    }

    .guide-description-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #323c47;
    }

    .input-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      width: 100%;
      color: #323c47;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #dadfea;
      outline: none;
      border-radius: 5px;
      padding: 11px 14px;

      &:hover,
      &:focus {
        border-color: #5e59ff;
      }
      &::placeholder {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #969797;
      }
    }

    .cancel-button {
      cursor: pointer;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #d6dae4;
      border-radius: 5px;
      padding: 4px 29px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #818da1;

      &:hover {
        background: #f3f3f3;
      }
    }

    .submit-button {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 5px;
      border: unset;
      padding: 4px 29px;
      border: 1px solid transparent;

      &:disabled {
        background: #d3d3d3;
        border-color: #d3d3d3;
        color: #ffffff;
        cursor: not-allowed;
      }

      &.primary {
        background: #5158cf;
        border-color: #5158cf;
        color: #ffffff;

        &:hover {
          background: rgba(81, 88, 207, 0.9);
          border-color: rgba(81, 88, 207, 0.9);
        }
      }

      &.secondary {
        background: #ffc445;
        border-color: #ffc445;
        color: #ffffff;

        &:hover {
          background: rgba(255, 196, 69, 0.9);
          border-color: rgba(255, 196, 69, 0.9);
        }
      }

      &.danger {
        background: #ea314a;
        border-color: #ea314a;
        color: #ffffff;

        &:hover {
          background: rgba(234, 49, 74, 0.9);
          border-color: rgba(234, 49, 74, 0.9);
        }
      }
    }
  }
`;
