// Libs
import React, { memo, useEffect, useState } from 'react';
import RootCloseWrapper from 'react-overlays/RootCloseWrapper';

// Constants
import { QUESTION_TYPES } from 'constants/commonData';
import { mongoObjectId } from 'utils/commonFunction';
import { DATE_FORMAT } from 'components/FormDetails/constants';
import { checkSyncConditions } from 'components/FormDetails/helpers';

// Assets
import { ReactComponent as WelcomeIcon } from 'assets/icons/welcome_screen.svg';
import { ReactComponent as SingleChoiceIcon } from 'assets/icons/single_choice.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icons/multiple_choice.svg';
import { ReactComponent as LinearScaleIcon } from 'assets/icons/linear_scale.svg';
import { ReactComponent as ShortAnswerIcon } from 'assets/icons/short_answer.svg';
import { ReactComponent as LongAnswerIcon } from 'assets/icons/long_answer.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/signature.svg';
import { ReactComponent as ImageTextIcon } from 'assets/icons/image_text.svg';
import { ReactComponent as MetricIcon } from 'assets/icons/metric_answer.svg';
import { ReactComponent as DateIcon } from 'assets/icons/date_answer.svg';

// Styles
import * as S from './style';

const QuestionTypesPopup = ({
  questions,
  trigger,
  questionTypes = [],
  onAddQuestion,
  menuClassName,
  onClosePopup,
  selected,
  disabled,
  defaultColor,
  user,
  bodyMetrics = [],
}) => {
  const { date_format } = user || {};

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (questionTypes.length) {
      setOpen(!questions.length);
    }
  }, [questions, questionTypes]);

  const newQuestions = (questions || []).filter(({ type }) =>
    [QUESTION_TYPES.DATE_TIME, QUESTION_TYPES.METRIC].includes(type),
  );

  const metricCondition = ({ is_sync, type, body_metric_unique_code }) => {
    return (
      type === QUESTION_TYPES.METRIC &&
      (typeof is_sync === 'undefined' || is_sync) &&
      body_metric_unique_code === ((bodyMetrics || [])[0] || {}).unique_code
    );
  };

  const { hasSync: hasSyncMetric } = checkSyncConditions(newQuestions, metricCondition);

  const handleToggle = () => {
    if (disabled) {
      return;
    }
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    onClosePopup && onClosePopup();
  };

  const handleSelect = item => () => {
    let data = {
      _id: mongoObjectId(),
      type: item,
      title: '',
      description: '',
    };

    switch (item) {
      case QUESTION_TYPES.WELCOME_SCREEN:
        data = {
          ...data,
          button_background: defaultColor || '#5158CF',
          button_label: "Let's start",
          background_image: null,
          background_image_thumbnail: null,
        };
        break;
      case QUESTION_TYPES.SINGLE_CHOICE:
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        data = {
          ...data,
          is_require: false,
          has_other_option: true,
          options: [
            {
              _id: mongoObjectId(),
              label: '',
              is_other: false,
            },
          ],
        };
        break;
      case QUESTION_TYPES.LINEAR_SCALE:
        data = {
          ...data,
          is_require: false,
          linear_start: {
            label: '',
            value: '0',
          },
          linear_end: {
            label: '',
            value: '5',
          },
        };
        break;
      case QUESTION_TYPES.SHORT_ANSWER:
      case QUESTION_TYPES.LONG_ANSWER:
        data = {
          ...data,
          is_require: false,
        };
        break;
      case QUESTION_TYPES.SIGNATURE:
        data = {
          ...data,
          is_require: true,
        };
        break;
      case QUESTION_TYPES.IMAGE_TEXT:
        data = {
          ...data,
          background_image: null,
          background_image_thumbnail: null,
          image_width: null,
          image_height: null,
        };
        break;
      case QUESTION_TYPES.DATE_TIME:
        data = {
          ...data,
          is_sync: false,
          sync_field: 'dob',
          sync_collection: 'profile',
          date_format: date_format || DATE_FORMAT,
          is_require: false,
        };
        break;
      case QUESTION_TYPES.METRIC:
        data = {
          ...data,
          is_sync: !hasSyncMetric,
          body_metric_unique_code: bodyMetrics.length > 0 ? bodyMetrics[0].unique_code : null,
          unit: bodyMetrics.length > 0 ? bodyMetrics[0].unit : null,
          is_require: false,
        };
        break;

      default:
        break;
    }

    onAddQuestion && onAddQuestion(data);
    handleClose();
  };

  const renderItem = () => {
    return questionTypes.map(question => {
      const { type = '', name = '' } = question || {};

      let icon = null;
      let hasWelcome = false;

      switch (type) {
        case QUESTION_TYPES.WELCOME_SCREEN:
          icon = <WelcomeIcon />;
          hasWelcome = questions.some(item => item.type === QUESTION_TYPES.WELCOME_SCREEN);
          break;
        case QUESTION_TYPES.SINGLE_CHOICE:
          icon = <SingleChoiceIcon />;
          break;
        case QUESTION_TYPES.MULTIPLE_CHOICE:
          icon = <MultipleChoiceIcon />;
          break;
        case QUESTION_TYPES.LINEAR_SCALE:
          icon = <LinearScaleIcon />;
          break;
        case QUESTION_TYPES.SHORT_ANSWER:
          icon = <ShortAnswerIcon />;
          break;
        case QUESTION_TYPES.LONG_ANSWER:
          icon = <LongAnswerIcon />;
          break;
        case QUESTION_TYPES.SIGNATURE:
          icon = <SignatureIcon />;
          break;
        case QUESTION_TYPES.IMAGE_TEXT:
          icon = <ImageTextIcon />;
          break;
        case QUESTION_TYPES.METRIC:
          icon = <MetricIcon />;
          break;
        case QUESTION_TYPES.DATE_TIME:
          icon = <DateIcon />;
          break;

        default:
          return null;
      }

      return (
        <S.Item
          onClick={!hasWelcome && handleSelect(type)}
          disabled={hasWelcome}
          isSelected={selected === type}
          data-option={name}
        >
          {icon}
          {name}
        </S.Item>
      );
    });
  };

  return (
    <RootCloseWrapper event="click" onRootClose={handleClose}>
      <S.Wrapper open={open}>
        <S.Trigger onClick={handleToggle} disabled={disabled}>
          {trigger ? (
            trigger
          ) : (
            <S.PlusIconContainer open={open} data-btn="create-question">
              <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5 4.99902V0.499023H4.5V4.99902H0V6.99902H4.5V11.499H6.5V6.99902H11V4.99902H6.5Z"
                  fill="white"
                />
              </svg>
            </S.PlusIconContainer>
          )}
        </S.Trigger>
        <S.MenuContainer className={menuClassName || ''}>{renderItem()}</S.MenuContainer>
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

export default memo(QuestionTypesPopup);
