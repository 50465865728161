import React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactAvatar from 'react-avatar';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import { LIMIT_RESOURCE_COLLECTION } from 'constants/commonData';

import * as S from './style';

export default function SearchClientItem({
  client: item,
  onSelect,
  isAssigned,
  cloudfrontList,
  collections_per_client = LIMIT_RESOURCE_COLLECTION,
}) {
  const handleClick = () => {
    if (isAssigned) {
      return;
    }
    onSelect(item);
  };

  const avt = convertS3UrlToCloudFrontUrl(item.avatar, cloudfrontList, true);

  return (
    <>
      {isAssigned && <ReactTooltip id={item._id} effect="solid" place={'top'} delayShow={10} />}
      <S.SearchClientItemWrapper
        data-tip={`Already assigned to ${collections_per_client} resource collections`}
        data-for={item._id}
        onClick={handleClick}
        isAssigned={isAssigned}
        className={isAssigned && 'over'}
      >
        <div className="searchClientItem__avatar">
          {item.type === 'group' ? (
            <ReactAvatar
              name={getUserShortName(item)}
              className="client-detail-avatar"
              size="34"
              src={avt}
              color={item.color}
            />
          ) : (
            <ReactAvatar name={item.name} className="client-detail-avatar" size="34" src={avt} color={item.color} />
          )}
        </div>
        <div className="searchClientItem__info">
          <div className="searchClientItem__info--name">
            {item.type === 'group' && <span className="searchClientItem__info--prefixGroup">Group: </span>}
            {item.name}
          </div>
          <div className="searchClientItem__info--email">
            {item.type === 'group' ? `${item.clients} clients` : item.email}
          </div>
        </div>
      </S.SearchClientItemWrapper>
    </>
  );
}
