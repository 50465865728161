import { Popup } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const QuestionWrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 8px;
  min-height: 46px;
`;

export const FormPollContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  width: 520px;

  background: #ffffff;
  border: 1px solid #d6dae4;
  border-radius: 5px;

  padding: 19px 9px 19px 15px;
  margin-bottom: ${({ disableEditPoll }) => (disableEditPoll ? '34px' : '0px')};

  ${QuestionWrapper} {
    grid-template-columns: ${({ disableEditPoll }) => !disableEditPoll && '1fr 14px;'};
  }

  .__react_component_tooltip.disable-edit-poll {
    padding: 15px;
    border-radius: 5px;
    width: 228px;
    line-height: 19.5px;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  .label-warning {
    margin-top: 8px;
  }
`;

export const QuestionEditorWrapper = styled.div`
  padding: 11px 15px 10px;
  cursor: text;
  margin-bottom: -5px;
  background: #f7f8fa;
  border-radius: 5px;
  border: 1px solid #f7f8fa;

  &:hover,
  &.has-focus {
    ${({ disableEditPoll }) =>
      disableEditPoll
        ? css`
            cursor: not-allowed;
          `
        : css`
            border: 1px solid rgba(81, 88, 207, 1);
            background-color: #fff;
          `}
  }

  #placeholder-question-poll-post {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: rgba(32, 35, 83, 0.3);
  }

  .DraftEditor-editorContainer {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #202353;
    word-break: break-word;
    white-space: pre-wrap;
  }

  textarea.question-input {
    cursor: ${({ disableEditPoll }) => disableEditPoll && 'not-allowed'};
    max-height: 110px !important;
    resize: none;

    &::placeholder {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      color: rgba(32, 35, 83, 0.3);
    }

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #202353;

    background: unset;
    border: unset;
    outline: unset;
    padding: unset;
    width: 100%;
  }
`;

export const ItemOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 4px;
`;

export const ItemOptionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ disableEditPoll }) => !disableEditPoll && '1fr 14px'};
  align-items: start;
  gap: 10px;

  .remove-button {
    display: ${({ disableEditPoll }) => (disableEditPoll ? 'none' : 'block')};
    width: 14px;
    height: 15px;
    padding: 0px;
    margin: 19px 0px 0px 0px;
    border: none;
    background: none;
    cursor: pointer;

    svg {
      width: 14px;
      height: 15px;

      :hover {
        g path {
          fill: #2d374e;
        }
      }
    }
  }

  .label-warning {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`;

export const OptionEditorWrapper = styled.div`
  padding: 15px 15px 11px;
  cursor: ${({ disableEditPoll }) => (disableEditPoll ? 'not-allowed' : 'text')} !important;
  min-height: 53px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #d6dae4;
  &:hover,
  &.has-focus {
    border: ${({ disableEditPoll }) => !disableEditPoll && '1px solid rgba(81, 88, 207, 1)'};
  }

  &.error-duplicate,
  &.disable-input {
    border-color: #ea314a;
  }

  #placeholder-option-poll-post {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.03px;

    color: rgba(20, 22, 50, 0.5);
  }

  .DraftEditor-editorContainer {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.03px;

    color: #141632;
    word-break: break-word;
    white-space: pre-wrap;
  }

  .option-input {
    max-height: 84px !important;
    resize: none;

    cursor: ${({ disableEditPoll }) => disableEditPoll && 'not-allowed'};
    &::placeholder {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.03px;

      color: rgba(20, 22, 50, 0.5);
    }

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.03px;

    color: #141632;

    background: unset;
    border: unset;
    outline: unset;
    padding: unset;
    width: 100%;
  }
`;

export const WarningOptionLabel = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03px;

  &.warning-duplicate {
    padding-left: 1px;
    color: #ea314a;
  }

  &.count-character {
    text-align: right;
    padding-right: 1px;

    color: rgba(137, 138, 152, 1);

    &.is-disable {
      color: #ea314a;
    }
  }
`;

export const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 22px;
  align-items: start;
`;

export const ActionItemWrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

export const ButtonAddOption = styled.button`
  padding: 0 0 0 1px;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  color: #5158cf;
`;

export const AdvancedSettingsWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  user-select: none;

  width: 100%;

  .poll-accordion {
    max-width: revert !important;
    display: inline-flex;
    height: 18px;
  }

  .poll-advanced-settings-title {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;

    padding: revert !important;
    font-family: revert !important;
    font-size: revert !important;
    color: revert !important;

    .label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;

      color: #758094;
    }

    &:hover,
    &.highlight-title {
      .label {
        color: #5158cf !important;
      }

      path {
        fill: #5158cf;
      }
    }
  }

  .poll-settings-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .poll-accordion-icon {
    svg {
      transition: transform 0.2s ease, opacity 0.2s ease;
      transform: ${props => (props.active ? 'rotate(180deg)' : 'translateY(1px)')};
      transform-origin: 50% 50%;
      transform-box: fill-box;
    }

    width: 12px;
    height: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin-left: 1px;
  }
`;

export const AdvancedSettingPopup = styled(Popup)`
  &.ui.popup {
    border: 1px solid rgba(218, 223, 234, 1) !important;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1) !important;
    background: rgba(255, 255, 255, 1) !important;
    border-radius: 8px !important;
    padding: 20px !important;
    z-index: 3000 !important;
  }

  &.ui.bottom.popup {
    margin: 8px 0 0 !important;
  }

  .settings-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }

  .setting-item {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 12px;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    user-select: none;

    &:hover {
      .checkbox-icon {
        border-color: #5e59ff !important;
      }
    }

    .checkbox-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;

      .evf-checkbox.setting-checkbox {
        padding-left: unset;
      }

      .checkbox-icon {
        transform: translate(-50%, -50%);
        border-color: #c3c5cb;
        border-radius: 4px;
      }

      input:checked ~ .checkbox-icon {
        background-color: #5e59ff;
        border-color: #5e59ff;
        background-size: 10px;
      }
    }

    .label-item {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      letter-spacing: 0.03px;

      color: #202353;
    }
  }
`;
