// Lib
import React, { useEffect, useState } from 'react';
import { Modal, TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';

// Shared
import TextEditable from 'shared/TextEditable';
import { Checkbox, CheckboxCircle } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import S3Images from 'shared/S3Images';

// Constants
import { QUESTION_TYPES } from 'constants/commonData';
import { DATE_FORMAT, UNIQUE_CODE_FT, UNIQUE_CODE_IN } from 'components/FormDetails/constants';

// Assets
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as Close } from 'assets/icons/close_current_color.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow_left_new.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';

import { SignatureContainer } from '../MainPanel/style';
import * as S from './style';

const PREV_KEY = 37;
const NEXT_KEY = 39;

const PreviewModal = ({ questions, defaultNavigationColor, onClose, user, bodyMetrics }) => {
  const { date_format = '' } = user || {};

  const hasWelcome = questions.some(item => item.type === QUESTION_TYPES.WELCOME_SCREEN);
  const [active, setActive] = useState(hasWelcome ? 0 : 1);
  const initialNumberQuestion =
    get(questions[hasWelcome ? 1 : 0], 'type') === QUESTION_TYPES.IMAGE_TEXT ? 0 : hasWelcome ? 0 : 1;
  const [numberQuestion, setNumberQuestion] = useState(hasWelcome ? initialNumberQuestion : initialNumberQuestion);
  const totalActive = questions.filter(item => item.type !== QUESTION_TYPES.WELCOME_SCREEN).length;
  const questionLength = questions.filter(
    item => item.type !== QUESTION_TYPES.WELCOME_SCREEN && item.type !== QUESTION_TYPES.IMAGE_TEXT,
  ).length;

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [active]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, []);

  const escFunction = event => {
    if (event.key === 'Escape') {
      onClose && onClose();
    }
  };

  const handleKeyDown = event => {
    switch (event.keyCode) {
      case PREV_KEY:
        handleClickPrev();
        break;
      case NEXT_KEY:
        handleClickNext();
        break;
      default:
        break;
    }
  };

  const handleClickPrev = () => {
    if (hasWelcome ? active > 0 : active > 1) {
      setActive(prev => prev - 1);
      const prevQuestion = hasWelcome ? questions[active] : questions[active - 1];

      if (get(prevQuestion, 'type') !== QUESTION_TYPES.IMAGE_TEXT) {
        setNumberQuestion(prev => prev - 1);
      }
    }
  };

  const handleClickNext = () => {
    if (active < totalActive) {
      setActive(prev => prev + 1);
      const nextQuestion = hasWelcome ? questions[active + 1] : questions[active];

      if (get(nextQuestion, 'type') !== QUESTION_TYPES.IMAGE_TEXT) {
        setNumberQuestion(prev => prev + 1);
      }
    }
  };

  const renderSingleOption = (option, idx) => {
    return (
      <div className="single-option-container" key={option._id}>
        <CheckboxCircle
          label={
            <S.OptionContainer>
              <TextEditable
                className="option-title"
                value={option.label || `Option ${idx + 1}`}
                placeholder="Option name"
                lineHeight={28}
                readOnly
              />
            </S.OptionContainer>
          }
          className="option-radio"
        />
      </div>
    );
  };

  const renderMultipleOption = (option, idx) => {
    return (
      <div className="multiple-option-container" key={option._id}>
        <Checkbox size={20} className="option-radio" />
        <S.OptionContainer className="multiple-choice-option-name">
          <TextEditable
            className="option-title"
            value={option.label || `Option ${idx + 1}`}
            placeholder="Option name"
            lineHeight={28}
            readOnly
          />
        </S.OptionContainer>
      </div>
    );
  };

  const handleClickStart = () => {
    setActive(1);
    const nextQuestion = hasWelcome ? questions[active + 1] : questions[active];
    if (get(nextQuestion, 'type') !== QUESTION_TYPES.IMAGE_TEXT) {
      setNumberQuestion(1);
    }
  };

  return (
    <S.ModalWrapper open={true} closeOnDimmerClick={false} className="preview-popup">
      <Modal.Header>
        <S.HeaderContainer>
          <S.HeaderPreviewLeft>
            <S.HeaderMessage>Preview what your clients will see</S.HeaderMessage>
          </S.HeaderPreviewLeft>
          <S.HeaderPreviewCenter>
            <S.HeaderPreviewText>Preview</S.HeaderPreviewText>
          </S.HeaderPreviewCenter>
          <S.HeaderPreviewRight data-btn="close-preview">
            <Close onClick={onClose} />
          </S.HeaderPreviewRight>
        </S.HeaderContainer>
      </Modal.Header>
      <ModalLayout.Content>
        <S.NavigationWrapper>
          <span
            className={classNames('arrow', { disabled: hasWelcome ? active === 0 : active === 1 })}
            onClick={handleClickPrev}
            data-id="prev-screen"
          >
            <ArrowLeft width={11} height={18} />
          </span>
          <S.PreviewDetail>
            {questions.map((question, index) => {
              const { type = '', unit, body_metric_unique_code = '' } = question || {};
              const { unique_code = '', title: titleUnit = '' } = unit || {};

              const unitCoachSetting = (
                (bodyMetrics || []).find(({ unique_code = '' }) => unique_code === body_metric_unique_code) || {}
              ).unit;

              const isMetricFt = [UNIQUE_CODE_FT, UNIQUE_CODE_IN].includes(
                get(unitCoachSetting, 'unique_code') || unique_code,
              );

              const idx = hasWelcome ? index : index + 1;
              if (active !== idx) {
                return null;
              }
              let content = null;
              const isImageText = [QUESTION_TYPES.IMAGE_TEXT].includes(type);
              const attachmentUrl = get(question, 'background_image', '');
              const attachmentThumbnailUrl = get(question, 'background_image_thumbnail', '');

              switch (type) {
                case QUESTION_TYPES.WELCOME_SCREEN:
                  return (
                    <S.WelcomeScreenContainer background={question.background_image}>
                      <div className="welcome-screen-content">
                        {question.title && (
                          <TextEditable
                            className="welcome-screen-title"
                            value={question.title}
                            lineHeight={40}
                            readOnly
                          />
                        )}
                        {question.description && (
                          <TextEditable
                            className="welcome-screen-description"
                            value={question.description}
                            lineHeight={22}
                            readOnly
                          />
                        )}
                        <S.LabelButton
                          purple
                          className="welcome-screen-btn"
                          buttonBackground={question.button_background}
                          onClick={handleClickStart}
                        >
                          {question.button_label}
                        </S.LabelButton>
                      </div>
                    </S.WelcomeScreenContainer>
                  );
                case QUESTION_TYPES.SINGLE_CHOICE:
                  content = (
                    <S.SingleChoiceContainer>
                      <div>{question.options.map(renderSingleOption)}</div>
                    </S.SingleChoiceContainer>
                  );
                  break;
                case QUESTION_TYPES.MULTIPLE_CHOICE:
                  content = (
                    <S.MultipleChoiceContainer>
                      <div
                        style={{
                          paddingLeft: '5px',
                          paddingTop: '2px',
                        }}
                      >
                        {question.options.map(renderMultipleOption)}
                      </div>
                    </S.MultipleChoiceContainer>
                  );
                  break;
                case QUESTION_TYPES.SHORT_ANSWER:
                  content = <TextArea className="short-answer" placeholder="Type your answer" readOnly rows={1} />;
                  break;
                case QUESTION_TYPES.LONG_ANSWER:
                  content = <TextArea className="short-answer" placeholder="Type your answer" readOnly rows={5} />;
                  break;
                case QUESTION_TYPES.LINEAR_SCALE:
                  const start = get(question, 'linear_start.value', 0);
                  const end = get(question, 'linear_end.value', 5);
                  const startLabel = get(question, 'linear_start.label', '');
                  const endLabel = get(question, 'linear_end.label', '');
                  const range = end - start + 1;
                  let data = {
                    firstLine: range,
                    secondLine: 0,
                  };

                  if (range > 6) {
                    const result = range / 2;
                    data = {
                      firstLine: Math.ceil(result),
                      secondLine: Math.floor(result),
                    };
                  }

                  content = (
                    <>
                      <S.LinearScaleLabelWrapper>
                        {startLabel && (
                          <S.LinearScaleLabel>
                            <span>{start}: </span>
                            <span>{startLabel}</span>
                          </S.LinearScaleLabel>
                        )}
                        {startLabel && endLabel && <Arrow width={10} />}
                        {endLabel && (
                          <S.LinearScaleLabel>
                            <span>{end}: </span>
                            <span>{endLabel}</span>
                          </S.LinearScaleLabel>
                        )}
                      </S.LinearScaleLabelWrapper>
                      <S.LinearScaleContainer range={data.firstLine} firstLine={data.firstLine}>
                        {Array.from({ length: data.firstLine }, (v, i) => (
                          <div className="option">{`${start}` === '1' ? i + 1 : i}</div>
                        ))}
                      </S.LinearScaleContainer>
                      {data.secondLine ? (
                        <S.LinearScaleContainer range={data.secondLine} firstLine={data.firstLine}>
                          {Array.from({ length: data.secondLine }, (v, i) => (
                            <div className="option">
                              {`${start}` === '1' ? i + 1 + data.firstLine : i + data.firstLine}
                            </div>
                          ))}
                        </S.LinearScaleContainer>
                      ) : null}
                    </>
                  );
                  break;
                case QUESTION_TYPES.SIGNATURE:
                  content = (
                    <>
                      <SignatureContainer>Tap to Sign</SignatureContainer>
                      <TextArea className="short-answer signature" placeholder="Your Full Name" readOnly rows={1} />
                    </>
                  );
                  break;
                case QUESTION_TYPES.IMAGE_TEXT:
                  content = (
                    <>
                      {(attachmentUrl || attachmentThumbnailUrl) && (
                        <S.ImageTextWrapper isPreview={!get(question, 'description', '').description}>
                          <S3Images src={[attachmentUrl || attachmentThumbnailUrl]} />
                        </S.ImageTextWrapper>
                      )}
                    </>
                  );
                  break;
                case QUESTION_TYPES.DATE_TIME:
                  content = (
                    <S.ReadOnlyWrapper>
                      <S.AnswerWrapper readOnly>
                        <span>{date_format === DATE_FORMAT ? 'mm' : 'dd'}</span>
                      </S.AnswerWrapper>
                      <span className="hyphen">-</span>
                      <S.AnswerWrapper readOnly>
                        <span>{date_format === DATE_FORMAT ? 'dd' : 'mm'}</span>
                      </S.AnswerWrapper>
                      <span className="hyphen">-</span>
                      <S.AnswerWrapper>
                        <span>yyyy</span>
                      </S.AnswerWrapper>
                    </S.ReadOnlyWrapper>
                  );
                  break;
                case QUESTION_TYPES.METRIC:
                  content = (
                    <S.ReadOnlyWrapper>
                      {isMetricFt ? (
                        <S.MetricAnswerWrapper>
                          <S.AnswerWrapper readOnly>
                            <span className="dynamic-unit">ft</span>
                          </S.AnswerWrapper>
                          <S.AnswerWrapper readOnly>
                            <span className="dynamic-unit">in</span>
                          </S.AnswerWrapper>
                        </S.MetricAnswerWrapper>
                      ) : (
                        <S.AnswerWrapper readOnly>
                          <span className="dynamic-unit">{get(unitCoachSetting, 'title') || titleUnit}</span>
                        </S.AnswerWrapper>
                      )}
                    </S.ReadOnlyWrapper>
                  );
                  break;

                default:
                  return null;
              }

              return (
                <S.QuestionContainer>
                  {!isImageText && <S.QuestionCounter>{numberQuestion + '/' + questionLength}</S.QuestionCounter>}
                  <S.ContentContainer isImageText={isImageText} isRequire={question.is_require}>
                    <S.ItemWrapperContent>
                      {!isImageText && (
                        <S.IconWrapper>
                          <QuestionIcon />
                          <span>{numberQuestion}</span>
                        </S.IconWrapper>
                      )}
                      <TextEditable
                        className="title"
                        value={
                          question.title
                            ? question.title
                            : [QUESTION_TYPES.SIGNATURE].includes(question.type)
                            ? 'Your Signature'
                            : [QUESTION_TYPES.IMAGE_TEXT].includes(question.type)
                            ? 'This is the title'
                            : 'This is the question'
                        }
                        placeholder="Name your question"
                        lineHeight={28}
                        readOnly
                      />
                      {question.description && (
                        <TextEditable className="description" value={question.description} lineHeight={22} readOnly />
                      )}
                      {content}
                      {active === totalActive && (
                        <S.SubmitButton
                          buttonBackground={defaultNavigationColor}
                          isLinear={[QUESTION_TYPES.LINEAR_SCALE].includes(question.type)}
                          isTextArea={[QUESTION_TYPES.LONG_ANSWER, QUESTION_TYPES.SHORT_ANSWER].includes(question.type)}
                          isSignature={[QUESTION_TYPES.SIGNATURE].includes(question.type)}
                        >
                          Submit
                        </S.SubmitButton>
                      )}
                    </S.ItemWrapperContent>
                  </S.ContentContainer>
                  <S.PaginationWrapper color={defaultNavigationColor}>
                    {active > 1 && (
                      <span onClick={handleClickPrev} data-btn="prev-screen">
                        <ArrowLeft width={4} height={7} />
                        Prev
                      </span>
                    )}
                    {active < totalActive && (
                      <span onClick={handleClickNext} data-btn="next-screen">
                        Next
                        <ArrowRight width={4} height={7} />
                      </span>
                    )}
                  </S.PaginationWrapper>
                  <S.QuestionTotal total={totalActive} background={defaultNavigationColor}>
                    {Array.from({ length: totalActive }, (v, i) => (
                      <div className={classNames({ active: i < active })} />
                    ))}
                  </S.QuestionTotal>
                </S.QuestionContainer>
              );
            })}
          </S.PreviewDetail>
          <span
            className={classNames('arrow', { disabled: active === totalActive })}
            onClick={handleClickNext}
            data-id="next-screen"
          >
            <ArrowRight width={11} height={18} />
          </span>
        </S.NavigationWrapper>
      </ModalLayout.Content>
    </S.ModalWrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { formDetails, customBranding } = rootReducer;
  return {
    questions: formDetails.questions || [],
    defaultNavigationColor: get(customBranding, 'originalTheme.primaryColor', ''),
    user,
    bodyMetrics: formDetails.bodyMetrics || [],
  };
};

export default connect(mapStateToProps, null)(PreviewModal);
