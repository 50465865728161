import Request, { axiosInstanceWithoutToken } from 'configs/request';
import { removeToken, getAccessToken } from 'utils/commonFunction';
import { hideTheMobileReminder } from 'utils/event-api';
import { signOutFirebase, database } from 'database/firebase';

export const LOGIN = 'LOGIN';
export const SUCCESS_LOGIN_USER = 'SUCCESS_LOGIN_USER';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const FAILED_LOGIN_USER = 'FAILED_LOGIN_USER';
export const ERROR_LOGIN_USER = 'ERROR_LOGIN_USER';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const SUCCESS_RESEND_EMAIL = 'SUCCESS_RESEND_EMAIL';
export const FAILED_RESEND_EMAIL = 'FAILED_RESEND_EMAIL';
export const SUCCESS_REMOVE_TEAM_MEMBER = 'SUCCESS_REMOVE_TEAM_MEMBER';
export const FAILED_REMOVE_TEAM_MEMBER = 'FAILED_REMOVE_TEAM_MEMBER';
export const USER_MARK_INTRO_VIDEO_WATCHED = 'USER_MARK_INTRO_VIDEO_WATCHED';
export const USER_ID_VERIFY_SUCCESS = 'USER_ID_VERIFY_SUCCESS';

export const successLoginUser = user => {
  return {
    type: SUCCESS_LOGIN_USER,
    user,
  };
};

export const logoutSuccess = () => {
  return {
    type: 'LOGOUT_USER',
  };
};

export const requestResendEmail = () => ({
  type: RESEND_EMAIL,
});

export const resendSuccess = () => ({
  type: SUCCESS_RESEND_EMAIL,
});

export const resendFailure = () => ({
  type: FAILED_RESEND_EMAIL,
});

const removeTokenFromServer = () => {
  const token = getAccessToken();

  if (token) {
    axiosInstanceWithoutToken.post('/api/auth/logout', null, { headers: { 'x-access-token': token } });
  }

  removeToken();
};

export const getMonitorSettings = () => {
  return () => {
    database
      .ref('monitor_settings')
      .once('value')
      .then(snapshot => {
        const { enable = false, limit = 0 } = snapshot.val();
        window.monitorSettings = {
          enableMonitor: enable,
          limit: limit,
        };
      });
  };
};

export const closeMonitorSettings = () => {
  database.ref('monitor_settings').off();
  delete window.monitorSettings;
};

export const logout = oldPath => {
  try {
    window.Intercom('shutdown');
  } catch {}

  removeTokenFromServer();
  hideTheMobileReminder();
  signOutFirebase();
  localStorage.removeItem('firebase-token');
  localStorage.removeItem('last-library-route');
  localStorage.removeItem('last-automation-route');
  localStorage.removeItem('persist:main');

  return dispatch => {
    let uri = '/login';

    if (oldPath) {
      uri += `?prev=${encodeURIComponent(oldPath)}`;
    }

    window.location = uri;
  };
};

export const logoutWithoutRedirect = () => {
  removeTokenFromServer();
  signOutFirebase();
  localStorage.removeItem('firebase-token');
  localStorage.removeItem('persist:main');

  return dispatch => {
    dispatch(logoutSuccess());
  };
};

export const resendVerifyEmail = email => {
  return dispatch => {
    dispatch(requestResendEmail());

    return axiosInstanceWithoutToken
      .post('/api/auth/resend', { email })
      .then(result => {
        dispatch(resendSuccess());
        return result.data;
      })
      .catch(error => {
        dispatch(resendFailure());
        return {
          error: error.response.data,
          status: error.response.status,
        };
      });
  };
};

export const submitQuestionnaire = data => {
  return Request.post({ url: '/api/profile/v2/onboarding-questionnaire', data }, false);
};

export const getProfileTempOnboardingQuestionnaire = () => {
  return Request.get({ url: '/api/profile/info' }, false);
};

export const submitQuestionnaireTraffic = data => {
  return Request.post({ url: 'api/profile/v2/onboarding-questionnaire-direct-traffic-lead-source', data }, false);
};

export const removeTeamMember = data => {
  return Request.post(
    { url: '/api/team/remove-trainer', data },
    true,
    (resp, { dispatch }) => {
      dispatch({ type: SUCCESS_REMOVE_TEAM_MEMBER, payload: { data: { _id: data.removeId } } });
    },
    (err, { dispatch }) => {
      dispatch({ type: FAILED_REMOVE_TEAM_MEMBER });
    },
  );
};

export const markIntroVideoWatched = () => ({ type: USER_MARK_INTRO_VIDEO_WATCHED });

export const getUserIdVerifySuccess = trainerId => {
  return { type: USER_ID_VERIFY_SUCCESS, payload: { trainerId } };
};
