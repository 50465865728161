// Lib
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

// Shared
import AccordionItem from './AccordionItem';
import AccordionSkeleton from './AccordionSkeleton';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import { clearSelection } from 'utils/commonFunction';

// Constants
import { DEFAULT_ACTIVE_INDEX, reorderDragDrop } from 'components/MealPlanDayDetail/constants';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_up_bold.svg';
import { ReactComponent as Trailing } from 'assets/icons/MealPlans/trailing_icon.svg';

import * as S from './style';

const Accordion = props => {
  const {
    listMealGroup,
    selectMealPlanDayDetail,
    isAdd,
    setIsAdd,
    loadingByDay,
    updateMealPlanByDay,
    toggleConfirmModal,
  } = props;
  const queryAttr = 'data-rbd-drag-handle-draggable-id';

  const [placeholderProps, setPlaceholderProps] = useState({});
  const [activeIndex, setActiveIndex] = useState(DEFAULT_ACTIVE_INDEX);
  const [activeAccordion, setActiveAccordion] = useState(DEFAULT_ACTIVE_INDEX);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [latestActive, setLatestActive] = useState(null);

  useEffect(() => {
    if (!listMealGroup || listMealGroup.length === 0) {
      return;
    }
    const firstIndex = 0;
    const lastIndex = listMealGroup.length - 1;

    if (isFirstLoad) {
      selectMealPlanDayDetail && selectMealPlanDayDetail(get(listMealGroup[firstIndex], '_id', ''));
      setActiveIndex(firstIndex);
      setActiveAccordion(firstIndex);
      setIsFirstLoad(false);
      setIsAdd(false);
    } else if (isAdd) {
      selectMealPlanDayDetail && selectMealPlanDayDetail(get(listMealGroup[lastIndex], '_id', ''));
      setActiveIndex(lastIndex);
      setActiveAccordion(lastIndex);
      setIsAdd(false);
    } else {
      selectMealPlanDayDetail && selectMealPlanDayDetail(get(listMealGroup[activeIndex], '_id', ''));
      setIsAdd(false);
    }
  }, [listMealGroup]);

  const handleClick = ({ index, _id }) => {
    setActiveIndex(index);
    setActiveAccordion(index === activeAccordion ? DEFAULT_ACTIVE_INDEX : index);
    selectMealPlanDayDetail && selectMealPlanDayDetail(_id);
    clearSelection();
  };

  const handleDragStart = event => {
    const draggedDOM = getDraggedDom(event && event.draggableId);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event && event.source && event.source.index;

    const clientY = getClientY(draggedDOM, [...draggedDOM.parentNode.children], sourceIndex);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
    // Keep latest active group
    activeIndex > -1 && setLatestActive(listMealGroup[activeIndex] || null);
  };

  const onDragEnd = useCallback(
    result => {
      const { destination, source, type } = result;

      if (!destination || !source) return;

      const sourceIndex = source.index;
      const destIndex = destination.index;

      if (type === 'droppableMeal') {
        const newMealGroup = reorderDragDrop(listMealGroup, sourceIndex, destIndex);

        updateMealPlanByDay && updateMealPlanByDay({ meal_group: newMealGroup }, true);
        handleKeepFocus(newMealGroup);
      } else if (type === 'droppableRecipes') {
        const recipeMap = listMealGroup.reduce((acc, item) => {
          acc[get(item, '_id')] = get(item, 'recipes');
          return acc;
        }, {});

        const sourceParentId = source.droppableId;
        const destParentId = destination.droppableId;

        const sourceRecipes = recipeMap[sourceParentId];
        const destRecipes = recipeMap[destParentId];

        let newMealGroup = [...listMealGroup];

        if (sourceParentId === destParentId) {
          /** In this case recipes are re-ordered inside same parent */
          const reorderDragDropedRecipes = reorderDragDrop(sourceRecipes, sourceIndex, destIndex);

          newMealGroup = newMealGroup.map(meal => {
            if (get(meal, '_id') === sourceParentId) {
              meal.recipes = reorderDragDropedRecipes;
            }

            return meal;
          });

          updateMealPlanByDay && updateMealPlanByDay({ meal_group: newMealGroup }, true);
        } else {
          /** In this case recipes are re-ordered inside other parent */
          let newSourceRecipes = [...sourceRecipes];
          const [draggedItem] = newSourceRecipes.splice(sourceIndex, 1);

          let newDestRecipes = [...destRecipes];
          newDestRecipes.splice(destIndex, 0, draggedItem);

          newMealGroup = newMealGroup.map(meal => {
            if (get(meal, '_id') === sourceParentId) {
              meal.recipes = newSourceRecipes;
            } else if (get(meal, '_id') === destParentId) {
              meal.recipes = newDestRecipes;
            }

            return meal;
          });

          updateMealPlanByDay && updateMealPlanByDay({ meal_group: newMealGroup }, true);
        }
      }
    },
    [listMealGroup, latestActive],
  );

  const handleKeepFocus = useCallback(
    groups => {
      if (latestActive) {
        const index = findIndex(groups, it => it._id === latestActive._id);
        if (index > -1) {
          setActiveIndex(index);
          setActiveAccordion(index);
          selectMealPlanDayDetail && selectMealPlanDayDetail(latestActive._id);
        }
      }
    },
    [latestActive],
  );

  const handleDragUpdate = event => {
    if (!event.destination) return;

    const draggedDOM = getDraggedDom(event && event.draggableId);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event && event.destination && event.destination.index;
    const sourceIndex = event && event.source && event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY = getClientY(draggedDOM, updatedArray, destinationIndex);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const getDraggedDom = draggableId => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    return document.querySelector(domQuery);
  };

  const getClientY = (draggedDOM, array, index) => {
    const result =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      array.slice(0, index).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    return result;
  };

  const handleRemoveMeal = (event, id) => {
    event.stopPropagation();

    if (!id) return;
    // Active index before remove
    const currentIndex = findIndex(listMealGroup, it => get(it, '_id') === id);
    // Length of list before remove
    const preLength = listMealGroup.length;

    const result = listMealGroup.filter(meal => get(meal, '_id') !== id);

    updateMealPlanByDay && updateMealPlanByDay({ meal_group: result }, true);
    // Select previous item
    if (currentIndex === preLength - 1 && currentIndex > -1) {
      const nextIndex = result.length - 1;
      if (nextIndex > -1) {
        const nextAction = {
          index: nextIndex,
          _id: result[nextIndex]._id,
        };
        handleClick(nextAction);
      }
    }
  };

  const handleOpenPopupRemove = (event, meal) => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.RemoveMeal
          confirmButtonTitle="Remove"
          title="Remove Meal?"
          content={`Are you sure you want to remove meal “${get(meal, 'name', '')}”?`}
          onConfirm={() => {
            handleRemoveMeal(event, get(meal, '_id', ''));
            toggleConfirmModal(false);
          }}
          noBorder
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          shouldCloseAfterConfirm={false}
          className="custom-remove-meal"
        />,
      );
  };

  const renderActions = meal => {
    return (
      <DropDown
        className="custom-actions-meal"
        triggerIcon={({ open }) => <MenuTrigger className="drop-down-trigger" vertical active={!!open} />}
        direction="left"
      >
        <Option key="delete" onClick={event => handleOpenPopupRemove(event, meal)}>
          <div className="icon">
            <img src={`${CDN_URL}/images/delete.svg`} alt="" />
          </div>
          <span>Remove</span>
        </Option>
      </DropDown>
    );
  };

  const renderMealGroup = (meal, index) => {
    const isActive = index === activeAccordion;
    const isEmpty = get(meal, 'recipes', []).length === 0;
    const _id = get(meal, '_id', '');

    return (
      <Draggable key={_id} draggableId={_id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="droppable-context-meal-category"
          >
            <S.AccordionWrapper isDragging={snapshot.isDragging} isActive={isActive}>
              <S.AccordionHeader onClick={() => handleClick({ index, _id })} isActive={isActive}>
                <S.AccordionHeaderLeft>
                  <span className="arrow-down-wrapper">
                    <ArrowDown className="arrow-down" />
                  </span>
                  <S.HeaderTitle>{get(meal, 'name', '') || 'Untitled'}</S.HeaderTitle>
                  <S.HeaderTotal>({get(meal, 'recipes', []).length})</S.HeaderTotal>
                </S.AccordionHeaderLeft>
                <S.AccordionHeaderRight>
                  <S.AccordionTrailingWrapper>
                    <Trailing className="trailing-header" />
                  </S.AccordionTrailingWrapper>
                  {get(listMealGroup, 'length') > 1 && !get(meal, 'is_system', false) && renderActions(meal)}
                </S.AccordionHeaderRight>
              </S.AccordionHeader>
              {isActive && (
                <S.AccordionContent isEmpty={isEmpty}>
                  {loadingByDay && (
                    <>
                      {Array(3)
                        .fill(null)
                        .map((_, index) => {
                          return <AccordionSkeleton key={`skeleton-${index}`} />;
                        })}
                    </>
                  )}
                  {!loadingByDay && isEmpty ? (
                    <S.AccordionItemEmpty>No Recipe yet</S.AccordionItemEmpty>
                  ) : (
                    <AccordionItem recipes={get(meal, 'recipes', [])} id={_id} placeholderProps={placeholderProps} />
                  )}
                </S.AccordionContent>
              )}
            </S.AccordionWrapper>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
      <Droppable droppableId="droppable" type="droppableMeal">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="droppable-context-meal-plan-day-detail">
            {!loadingByDay && listMealGroup && listMealGroup.map(renderMealGroup)}
            {loadingByDay && <S.AccordionWrapperSkeleton />}
            {provided.placeholder}
            {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
              <S.Placeholder
                style={{
                  top: placeholderProps.clientY,
                  left: placeholderProps.clientX,
                  height: placeholderProps.clientHeight,
                  width: placeholderProps.clientWidth,
                }}
              />
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Accordion;
