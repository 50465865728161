import React from 'react';
import * as S from './style';

const FormatItem = props => {
  return (
    <S.FormatItemWrapper className={props.value} onClick={() => props.onSelect(props.value)}>
      <div className="format-item__name">{props.formatName}</div>
      <div className="format-item__description">{props.description}</div>
    </S.FormatItemWrapper>
  );
};

const ChooseSectionFormatForm = props => {
  return (
    <S.Wrapper>
      <S.Content>
        <FormatItem
          value="regular"
          formatName="Regular"
          description="Exercise by exercise, mostly used for strength workouts"
          onSelect={props.onSelect}
        />
        <FormatItem
          value="interval"
          formatName="Interval"
          description="Runs built-in timer for exercise and rest (HIIT, Tabata, Circuit)"
          onSelect={props.onSelect}
        />
        <FormatItem
          value="amrap"
          formatName="AMRAP"
          description="Track total rounds completed based on time assigned"
          onSelect={props.onSelect}
        />
        <FormatItem
          value="timed"
          formatName="Timed"
          description="Track total duration based on rounds assigned"
          onSelect={props.onSelect}
        />
        {props.allowUseFreeSection && (
          <FormatItem
            value="freestyle"
            formatName="Freestyle"
            description="Best for warmups, Crossfit, or any follow-along videos "
            onSelect={props.onSelect}
          />
        )}
      </S.Content>
      <S.Footer>
        <div onClick={props.onCancel} className="add-section-form__cancel">
          Cancel
        </div>
      </S.Footer>
    </S.Wrapper>
  );
};

export default ChooseSectionFormatForm;
