import _ from 'lodash';
import Request from 'configs/request';

export const Types = {
  ADD_MEMBER_SUCCESS: 'ADD_MEMBER_SUCCESS',
};

export const searchClients = (clients, inputValue, callback) => {
  const except = _.map(clients, '_id');
  let search = typeof inputValue === 'string' ? inputValue.trim() : '';
  const data = { q: search, selected_clients: except };

  return Request.post({ url: '/api/forum-member/search-user', data });
};

export const searchGroupClients = (clients, inputValue, callback) => {
  const except = _.map(clients, '_id');
  let search = typeof inputValue === 'string' ? inputValue.trim() : '';
  const data = { q: search, selected_clients: except };
  return Request.post({ url: `/api/client-management/client-group-list`, data });
};

export const searchGroupClientList = data => Request.post({ url: `/api/client-management/client-group-list`, data });
