import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Image, Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

import './styles.scss';
import { CustomToastMessage } from '../CustomToast.style';

const CustomPopup = ({
  title = '',
  icon = '',
  content = '',
  onClose,
  onSubmit,
  buttonClassName = 'button-warning',
  buttonText = 'OK',
  isShowCancelButton = true,
  radioOption,
  onToggle,
  toastStyle,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleCancel = () => {
    onToggle();
    onClose && onClose();
  };

  const callbackSuccess = message => {
    setSubmitting(false);
    return toast(<CustomToastMessage style={toastStyle}>{message}</CustomToastMessage>);
  };

  const handleSubmit = () => {
    if (!onSubmit) {
      onToggle();
    } else {
      setSubmitting(true);
      onSubmit(callbackSuccess);
    }
  };

  return (
    <Modal open={true} onClose={handleCancel} closeOnDimmerClick={false} className="custom-popup-wrapper">
      <Modal.Header>
        <Button className="close-button" onClick={handleCancel}>
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <div className="header-title">
          <>{icon}</>
          <span>{title}</span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <span dangerouslySetInnerHTML={{ __html: content }} />
        {radioOption && <div className="radio-option">{radioOption}</div>}
      </Modal.Content>
      <Modal.Actions>
        {!!isShowCancelButton && (
          <Button className="button-cancel" onClick={handleCancel}>
            Cancel
          </Button>
        )}
        <Button disabled={isSubmitting} onClick={handleSubmit} className={buttonClassName}>
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CustomPopup;
