// Libs
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';

// Actions
import { VERIFY_TYPES } from '../../redux/setup-payment/actions';

// Shared
import { Button } from 'shared/FormControl';

// Constants
import { VERIFY_SESSION_STATUS, TITLE } from './contants';

// Assets
import InfoIcon from 'assets/icons/info_icon_confirm_blue.png';
import { ReactComponent as VerifiedIcon } from 'assets/icons/Payment/verified.svg';

// Styles
import * as S from './style';

const VerifyButton = props => {
  const { verifyStatus, chargesEnabled, onIdentityVerification, onGetConnectedAccount } = props;
  const [clientSecret, setClientSecret] = useState(null);
  const [stripe, setStripe] = useState(null);
  const { VERIFIED } = VERIFY_SESSION_STATUS;
  const [nameAcc, setNameAcc] = useState(null);

  useEffect(() => {
    async function handleGetStripe() {
      await setStripe(props.stripePromise);
    }

    handleGetStripe();
  }, []);

  useEffect(() => {
    setClientSecret(props.clientSecret);
  }, [clientSecret]);

  useEffect(() => {
    if (verifyStatus === VERIFIED)
      onGetConnectedAccount().then(res => {
        const { data } = res.data;

        if (data) handleGetInfo(data);
      });
  }, [verifyStatus]);

  // TODO - handle get info of account
  const handleGetInfo = data => {
    const { verified_document } = data;
    const _verified_document = verified_document ? verified_document : false;

    if (!_verified_document) return;
    const name = _verified_document.first_name.concat(' ', _verified_document.last_name) || '';
    setNameAcc(name);
  };

  const handleClick = async type => {
    if (!props.stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    onIdentityVerification(type).then(response => {
      const { status, data } = response;
      if (status === 200 && get(data, 'data.client_secret')) {
        const _clientSecret = get(data, 'data.client_secret');
        if (_clientSecret) handleOpenModalStripe(_clientSecret);
      }
    });
  };

  const handleOpenModalStripe = async clientSecret => {
    const res = await props.stripe.verifyIdentity(clientSecret);
    // TODO - res after verifyIdentity

    if (!res.error) {
      onGetConnectedAccount();
    } else {
      // Toast show error
      toast(res.error || 'Verify Error');
    }
  };

  return (
    <ConfirmIdentityArea
      stripe={props.stripe}
      verifyStatus={verifyStatus}
      onClick={handleClick}
      chargesEnabled={chargesEnabled}
      nameAcc={nameAcc}
      isUS={props.isUS}
      verificationSessions={props.verificationSessions}
    />
  );
};

const checkVerifyByType = (sessionsData = [], type, status) => {
  if (sessionsData.length) {
    const verifySessions = sessionsData.map(entry => entry.verify_session) || [];
    return verifySessions.some(it => it.type === type && it.status === status);
  }
  return false;
};

const ConfirmIdentityArea = props => {
  const { stripe, onClick, chargesEnabled, nameAcc, isUS, verificationSessions = [], verifyStatus } = props;
  const { VERIFIED, PROCESSING } = VERIFY_SESSION_STATUS;

  if (!chargesEnabled) return false;

  const verifyingDocument = checkVerifyByType(verificationSessions, VERIFY_TYPES.DOCUMENT, PROCESSING);
  const verifiedDocument = checkVerifyByType(verificationSessions, VERIFY_TYPES.DOCUMENT, VERIFIED);

  const verifyingID = checkVerifyByType(verificationSessions, VERIFY_TYPES.ID, PROCESSING);
  const verifiedID = checkVerifyByType(verificationSessions, VERIFY_TYPES.ID, VERIFIED);

  const isVerified = isUS ? verifiedDocument && verifiedID : verifiedDocument;
  const isOldAccount = verifyStatus === VERIFIED && verificationSessions.length === 0;
  const title = isVerified || isOldAccount ? TITLE.VERIFIED : TITLE.DEFAULT;

  const handleProcessVerify = type => {
    onClick(type);
  };

  return (
    <>
      <S.Title>Identity Verification</S.Title>
      <S.SectionContainer spaceBetween={true}>
        <S.DescWrapper>
          <S.Desc>
            <S.InfoIcon background={InfoIcon} />
            {title}
            {(isVerified || isOldAccount) && <S.Name>{nameAcc}</S.Name>}
          </S.Desc>
        </S.DescWrapper>
        {isOldAccount
          ? null
          : !isVerified && (
              <S.VerificationWrapper>
                {isUS && (
                  <>
                    <Button
                      disabled={verifyingID || !stripe}
                      onClick={event => {
                        event.preventDefault();
                        !verifiedID && handleProcessVerify(VERIFY_TYPES.ID);
                      }}
                      className={verifiedID ? 'verified' : ''}
                      data-tip
                      data-for="verified-id-processing"
                    >
                      {verifiedID && <VerifiedIcon />}
                      {verifyingID ? 'Processing' : `ID Number ${verifiedID ? 'Checked' : 'Check'}`}
                    </Button>
                    {verifyingID && (
                      <ReactTooltip
                        className="app-tooltip verified-id-tooltip"
                        id="verified-id-processing"
                        effect="solid"
                        place="top"
                      >
                        This process might take few minutes to complete
                      </ReactTooltip>
                    )}
                  </>
                )}
                <Button
                  disabled={verifyingDocument || !stripe}
                  onClick={event => {
                    event.preventDefault();
                    !verifiedDocument && handleProcessVerify(VERIFY_TYPES.DOCUMENT);
                  }}
                  className={verifiedDocument ? 'verified' : ''}
                  data-tip
                  data-for="verified-document-processing"
                >
                  {verifiedDocument && <VerifiedIcon />}
                  {verifyingDocument ? 'Processing' : `Document ${verifiedDocument ? 'Checked' : 'Check'}`}
                </Button>
                {verifyingDocument && (
                  <ReactTooltip
                    className="app-tooltip verified-document-tooltip"
                    id="verified-document-processing"
                    effect="solid"
                    place="top"
                  >
                    This process might take few minutes to complete
                  </ReactTooltip>
                )}
              </S.VerificationWrapper>
            )}
      </S.SectionContainer>
    </>
  );
};

const ConfirmIdentityStripe = props => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => <VerifyButton {...props} elements={elements} stripe={stripe} />}
    </ElementsConsumer>
  );
};

export default ConfirmIdentityStripe;
