import React, { useEffect, useMemo, useRef, useState } from 'react';
// import { createPortal } from 'react-dom';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import map from 'lodash/map';
import pick from 'lodash/pick';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import diff from 'deep-diff';

import { toggleSideBar } from 'actions/sidebar';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  getProgramWithFilter,
  toggleFullscreenModal,
  changeQueryParamsProgram,
  resetQueryParamsProgram,
  removeProgramLibraryItem,
  updateProgramSharingStatus,
  addBulkAddTagPrograms,
  closeFullscreenModal,
} from 'redux/program-library-and-templates/actions';
import { hideProgramLibraryBanner } from 'redux/general-settings/actions';

import { DEFAULT_QUERY_PARAMS } from 'redux/program-library-and-templates/reducers';
import { createNewTag, getMostRecentTagsList } from 'redux/tags/actions';
import { saveLastLibraryRoute, checkAssetAssignedToProduct } from 'utils/commonFunction';
import { TEAM_SHARE_PRIVATE, TAGS_TYPE, PROGRAM_SORT_TYPE } from 'constants/commonData';
import { ASSET_TYPE } from 'components/Product/constants';

import TagsIcon from 'assets/icons/program_tags.svg';

import ProgramDetailModal from 'components/ProgramDetailModal';
import { MODE } from 'components/ProgramDetailModal/constants';
// import ProgramTemplatesFullscreenModal from 'components/ProgramTemplatesFullscreenModal';
import { NewSearchInput } from 'shared/SearchInput';
import Pagination from 'shared/Pagination';
import * as Layout from 'shared/LibraryLayout';
import * as Icons from 'shared/LibraryLayout/Icons';
import ConfirmModal from 'shared/ConfirmModal';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import MenuTags from 'shared/MenuTags';
import ManageTags from 'shared/ManageTags';
import { Checkbox } from 'shared/FormControl';
import { TypingModal, TYING_TYPES } from 'shared/TriggerForms';

import * as S from './styles';
import ProgramLibraryContext from './context/ProgramLibraryContext';
import { LIBRARY_COLUMNS, DEFAULT_FILTERS, getCountTagsSelected } from './constants';
import Banner from './components/Banner';
import AddNewProgramButton from './components/AddNewProgramButton';
import ItemRowProgram from './components/ItemRowProgram';
import CreateNewTags from './components/CreateNewTags';
import FiltersPopup from './components/FiltersPopup';
import ResultNotFound from './components/ResultNotFound';
import EmptyProgram from './components/EmptyProgram';
// import LoadingProgramIndicator from './components/LoadingProgramIndicator';
import LoadingProgramSkeleton from './components/LoadingProgramSkeleton';

const { CREATE_TEMPLATE, UPDATE_TEMPLATE, CLONE_TEMPLATE } = MODE;

const ProgramLibraryAndTemplates = props => {
  const {
    user,
    programLibraryAndTemplates,
    permission,
    toggleModal,
    toggleConfirmModal,
    toggleSideBar,
    push,
    getProgramWithFilter,
    updateProgramSharingStatus,
    programTags = {},
    createNewTag,
    isModalOpen,
    cloudfrontList,
    getMostRecentTagsList,
    toggleFullscreenModal,
    is_hide_program_library_banner,
    changeQueryParamsProgram,
    resetQueryParamsProgram,
    removeProgramLibraryItem,
    addBulkAddTagPrograms,
    hideProgramLibraryBanner,
    closeFullscreenModal,
  } = props;

  const {
    list = [],
    total,
    loading = false,
    query: { page, per_page, search, sort, sorter } = {},
  } = programLibraryAndTemplates;

  const {
    query: { page: tagPage, textSearch: tagTextSearch } = {},
    mostRecentList: tagMostRecentList = [],
  } = programTags;

  const tableScrollableRef = useRef();
  const [showBanner, setShowBanner] = useState(!is_hide_program_library_banner);
  const [showTags, setShowTags] = useState(false);
  const [openCreateNewTags, setOpenCreateNewTags] = useState(false);
  const [openManageTags, setOpenManageTags] = useState(false);
  const [programsSelected, setProgramsSelected] = useState([]);

  const [currentFilters, hideResetButton] = useMemo(() => {
    const queryFilters = pick(programLibraryAndTemplates.query, Object.keys(DEFAULT_FILTERS));
    const isDefault = isEqual(queryFilters, DEFAULT_FILTERS);

    return [queryFilters, isDefault];
  }, [programLibraryAndTemplates]);

  const programIds = useMemo(() => map(list, '_id'), [list]);
  const programsSelectedIds = useMemo(() => map(programsSelected, '_id'), [programsSelected]);

  const countTagsSelected = useMemo(
    () =>
      showTags
        ? getCountTagsSelected({
            programsSelected,
            allCurrentProgram: list,
          })
        : {},
    [showTags, list, programsSelected],
  );

  const isSelectedAllProgram = useMemo(() => {
    return programsSelectedIds.length > 0 && difference(programIds, programsSelectedIds).length === 0;
  }, [programIds, programsSelectedIds]);

  const isEmptyProgram = useMemo(() => {
    const { list, total, query } = programLibraryAndTemplates;
    return isEmpty(list) && total === 0 && !diff(query, DEFAULT_QUERY_PARAMS);
  }, [programLibraryAndTemplates]);

  const isEmptyResultProgram = useMemo(() => {
    const { list, total, query } = programLibraryAndTemplates;
    return isEmpty(list) && total === 0 && diff(query, DEFAULT_QUERY_PARAMS);
  }, [programLibraryAndTemplates]);

  useEffect(() => {
    toggleSideBar(true);
    saveLastLibraryRoute('/home/program');
    getProgramWithFilter();
    getMostRecentTagsList({
      page: 1,
      sort: -1,
      type: TAGS_TYPE.PROGRAM,
      text_search: '',
    });

    return () => {
      resetQueryParamsProgram();
    };
  }, []);

  useEffect(() => {
    if (!isModalOpen && !openCreateNewTags && showTags) {
      getMostRecentTagsList({
        page: tagPage,
        sort: -1,
        type: TAGS_TYPE.PROGRAM,
        text_search: tagTextSearch,
      });
    }
  }, [tagPage, tagTextSearch, isModalOpen, openCreateNewTags, showTags]);

  useEffect(() => {
    if (loading && tableScrollableRef.current) {
      tableScrollableRef.current.scrollTop = 0;
    }
  }, [loading]);

  useEffect(() => {
    setShowBanner(!is_hide_program_library_banner);
  }, [is_hide_program_library_banner]);

  useEffect(() => {
    return () => {
      closeFullscreenModal();
    };
  }, []);

  const handleHideBanner = () => {
    setShowBanner(false);
    hideProgramLibraryBanner();
  };

  const handleSearch = (__, { value }) => {
    const trimmed = value.toLowerCase().trim();
    if (trimmed !== search) {
      setProgramsSelected([]);
      changeQueryParamsProgram({ page: 1, search: trimmed });
    }
  };

  const searchDebounce = debounce(handleSearch, 300);

  const handleClearSearch = () => {
    setProgramsSelected([]);
    changeQueryParamsProgram({ page: 1, search: '' });
  };

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      setProgramsSelected([]);
      changeQueryParamsProgram({ page: 1 });
    }
  };

  const handleAddNewProgram = () => {
    toggleModal(true, <ProgramDetailModal mode={CREATE_TEMPLATE} supportTemplate={true} />);
  };

  const handleEditProgram = item => {
    const originItem = cloneDeep(item);
    const workingItem = cloneDeep(item);
    toggleModal(
      true,
      <ProgramDetailModal
        mode={UPDATE_TEMPLATE}
        originItem={originItem}
        workingItem={workingItem}
        supportTemplate={true}
      />,
    );
  };

  const handleCloneProgram = item => {
    const originItem = cloneDeep(item);
    originItem.author = {
      _id: user._id,
    };
    originItem.share = TEAM_SHARE_PRIVATE;
    const workingItem = cloneDeep(item);
    workingItem.author = {
      _id: user._id,
    };
    workingItem.share = TEAM_SHARE_PRIVATE;
    toggleModal(
      true,
      <ProgramDetailModal
        mode={CLONE_TEMPLATE}
        originItem={originItem}
        workingItem={workingItem}
        supportTemplate={true}
      />,
    );
  };

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  /**
   * Priority is as below:
   * 1. Warning pop up of package
   * 2. Warning pop up of trigger
   * Enable if User writes word “Confirm” (ignore sensitive case).
   * On-click this option should remove the program (no need any other pop up)
   * 3. Warning pop up of assignment for Onboarding Flow.
   */
  const handleDeleteProgram = async item => {
    const { payment, onboarding_flow } = permission;

    if (payment) {
      const isAssignedToProduct = await checkAssetAssignedToProduct(ASSET_TYPE.PROGRAM, item._id);
      if (isAssignedToProduct) {
        return toggleConfirmModal(
          true,
          <DeleteAssetConfirmModal assetType={ASSET_TYPE.PROGRAM} onConfirm={handleConfirmRemove(item)} />,
        );
      }
    }

    if (onboarding_flow) {
      const {
        assigned: isAssignedToOnboardingFlow,
        assigned_trigger_form: isTriggerApplied,
      } = await checkAssetAssignedToProduct(ASSET_TYPE.PROGRAM, item._id, true, true);

      if (isTriggerApplied && typeof toggleConfirmModal === 'function') {
        return toggleConfirmModal(
          true,
          <TypingModal
            isCloseAfterSubmit
            type={TYING_TYPES.REMOVE_PROGRAM}
            closeModal={closeConfirmModal}
            submitAction={handleRemoveProgramLibrary(item._id)}
          />,
        );
      }

      if (isAssignedToOnboardingFlow) {
        return toggleConfirmModal(
          true,
          <DeleteAssetConfirmModal
            isOnboardingFlow
            title="Program is assigned to an Onboarding Flow"
            content="This Program is in at least 1 onboarding flow. If the Program is removed, it will be removed from all onboarding flows as well."
            assetType={ASSET_TYPE.PROGRAM}
            onConfirm={handleConfirmRemove(item)}
          />,
        );
      }
    }

    handleConfirmRemove(item)();
  };

  const handleConfirmRemove = item => () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Program from Library"
        content="Are you sure that you want to delete this program?"
        onConfirm={handleRemoveProgramLibrary(item._id)}
      />,
    );
  };

  const handleManageAction = itemId => {
    push(`/home/program/${itemId}/calendar`);
  };

  const handleGoToPrevPage = () => {
    if (page === 1) return;
    changeQueryParamsProgram({ page: page - 1 });
  };

  const handleGoToNextPage = () => {
    if (page * per_page >= total) return;
    changeQueryParamsProgram({ page: page + 1 });
  };

  const handleOpenMenuTags = () => setShowTags(true);

  const handleCloseMenuTags = () => setShowTags(false);

  const handleOpenCreateTags = () => {
    toggleModal(true, <CreateNewTags open={true} onCloseModal={handleCloseModal} onSubmit={createNewTag} />);
    setOpenCreateNewTags(true);
  };

  const handleCloseModal = () => {
    toggleModal(false);
    setTimeout(() => {
      setOpenCreateNewTags(false);
    }, 100);
  };

  const handleOpenManageTags = () => {
    toggleModal(true, <ManageTags type={TAGS_TYPE.PROGRAM} onCloseModal={handleCloseModalManageTag} />);
    setOpenManageTags(true);
  };

  const handleCloseModalManageTag = () => {
    setTimeout(() => {
      setOpenManageTags(false);
    }, 100);
  };

  const handleUpdateGeneralApplyTags = data => {
    const defaultSelected = get(data, 'defaultSelected', []);
    const currentSelected = get(data, 'tagIds', []);
    const exerciseIds = get(data, 'elementsSelectedByTabs', []);
    let newDataTags = {
      program_ids: exerciseIds,
      insert_tag_ids: currentSelected,
      remove_tag_ids: [],
    };
    let newTagIds = [];
    let removedTagIds = [];
    // If default selected have tags selected
    if (defaultSelected.length > 0) {
      if (diff(defaultSelected, currentSelected)) {
        removedTagIds = filter(defaultSelected, item => !currentSelected.includes(item));
        newTagIds = filter(currentSelected, item => !defaultSelected.includes(item));
        newDataTags = {
          ...newDataTags,
          insert_tag_ids: diff(newTagIds, currentSelected) || diff(newTagIds, defaultSelected) ? newTagIds : [],
          remove_tag_ids: removedTagIds,
        };
      }
    }
    return newDataTags;
  };

  const handleApplyTags = data => {
    if (!data) return;
    const newDataTags = handleUpdateGeneralApplyTags(data);
    newDataTags && typeof addBulkAddTagPrograms === 'function' && addBulkAddTagPrograms(newDataTags);
    setProgramsSelected([]);
  };

  const handleUpdateFilters = (options = {}) => {
    if (typeof options !== 'object') return;
    setProgramsSelected([]);
    changeQueryParamsProgram({ ...options, page: 1 });
  };

  const handleResetFilter = () => {
    setProgramsSelected([]);
    resetQueryParamsProgram({ filterOnly: true });
    getProgramWithFilter();
  };

  const handleRemoveProgramLibrary = id => () => {
    removeProgramLibraryItem(id);
  };

  const handleSortProgramList = (id, hasSort) => () => {
    if (!hasSort) return;
    changeQueryParamsProgram({
      page: 1,
      sort: sort === PROGRAM_SORT_TYPE.ASCENDING ? PROGRAM_SORT_TYPE.DESCENDING : PROGRAM_SORT_TYPE.ASCENDING,
      sorter: id,
    });
  };

  const handleSelectedProgram = (item, event) => {
    if (!item || !event) return;
    const checked = (event.target || {}).checked || false;

    if (checked) {
      setProgramsSelected(prevSelect => [...prevSelect, item]);
    } else {
      setProgramsSelected(prevFilters => {
        const newList = prevFilters.slice();
        newList.splice(
          newList.findIndex(it => it._id === item._id),
          1,
        );
        return newList;
      });
    }
  };

  const handleSelectItemTag = tag => {
    if (typeof tag !== 'object') return;
    changeQueryParamsProgram({ tags: [tag], page: 1 });
  };

  const handleStopAction = event => event.stopPropagation();

  const handleAllSelectProgram = event => {
    event.stopPropagation();
    if (isSelectedAllProgram) {
      setProgramsSelected(prevSelect =>
        uniqBy(
          prevSelect.filter(it => !programIds.includes(it._id)),
          '_id',
        ),
      );
    } else {
      setProgramsSelected(it => uniqBy([...it, ...list], '_id'));
    }
  };

  const contextValue = {
    user,
    onItemClick: handleManageAction,
    onEditItem: handleEditProgram,
    onDuplicateItem: handleCloneProgram,
    onRemoveItem: handleDeleteProgram,
    onSelectedProgram: handleSelectedProgram,
    onSelectedItemTag: handleSelectItemTag,
    toggleModal,
    updateShareStatus: updateProgramSharingStatus,
    cloudfrontList,
    programsSelectedIds,
    currentFilters,
    tags: programTags,
    onUpdateFilters: handleUpdateFilters,
    handleAddNewProgram,
    onHideBanner: handleHideBanner,
    onOpenExploreTemplate: toggleFullscreenModal,
    handleResetFilter,
  };

  const renderHeaderColumn = it => {
    const { id, label = '', hasSort = false, alignCenter = false, icon, isEmpty = false } = it;
    if (isEmpty) return <Layout.TableHeaderCell key={id} />;
    return (
      <Layout.TableHeaderCell key={id} alignCenter={alignCenter}>
        <Layout.TableHeaderCellContent
          hasSort={hasSort}
          active={sorter === id}
          onClick={handleSortProgramList(id, hasSort)}
        >
          {id === 'title' && (
            <div className="program-checkbox-header-wrapper" onClick={handleStopAction}>
              <Checkbox
                checked={isSelectedAllProgram}
                className="program-checkbox-header"
                onChange={handleAllSelectProgram}
                size={16}
              />
            </div>
          )}
          {icon}
          <span>
            {label}
            {id === 'title' && ` (${total})`}
          </span>
          {hasSort && renderSortIcon(id)}
        </Layout.TableHeaderCellContent>
      </Layout.TableHeaderCell>
    );
  };

  const renderSortIcon = column_name => {
    if (sorter === column_name) {
      return sort === PROGRAM_SORT_TYPE.ASCENDING ? <Icons.ArrowUp active /> : <Icons.ArrowDown active />;
    }
    return <Icons.ArrowDown />;
  };

  const renderItemProgram = (it, idx) => <ItemRowProgram key={`item-program-${it._id || idx}`} item={it} />;

  return (
    <ProgramLibraryContext.Provider value={contextValue}>
      <S.CustomLayoutWrapper>
        {/* {createPortal(<ProgramTemplatesFullscreenModal />, document.body)} */}
        {showBanner && <Banner />}
        <Layout.ToolbarContainer>
          <div className="program-toolbar-left">
            <NewSearchInput
              onChange={searchDebounce}
              onClearSearch={handleClearSearch}
              placeholder="Search by keyword or name"
              onKeyPress={onKeyPress}
            />
            <FiltersPopup isHideBanner={is_hide_program_library_banner} />
            <S.ResetFilter onClick={handleResetFilter} hide={hideResetButton}>
              Reset
            </S.ResetFilter>
          </div>
          <div className="program-toolbar-right">
            <div className="program-menu-tags-wrapper">
              <S.TagsButton title="Tags" img={TagsIcon} open={showTags} onClick={handleOpenMenuTags} />
              <MenuTags
                open={showTags}
                onClose={handleCloseMenuTags}
                type={TAGS_TYPE.PROGRAM}
                tags={tagMostRecentList}
                onClickAddNew={handleOpenCreateTags}
                toggleModal={toggleModal}
                onClickManage={handleOpenManageTags}
                onSubmit={createNewTag}
                countTagsSelected={countTagsSelected}
                elementsSelected={programsSelected}
                disableCloseMenu={openCreateNewTags || openManageTags}
                onApplyTabs={handleApplyTags}
                wrapperClassName="program-popup-menu-tags-wrapper"
                containerClassName="program-popup-menu-tags-container"
              />
            </div>
            <AddNewProgramButton />
          </div>
        </Layout.ToolbarContainer>
        <Layout.TableContainer ref={tableScrollableRef}>
          <Layout.Table>
            <Layout.TableHeader>
              <Layout.TableRow>{LIBRARY_COLUMNS.map(renderHeaderColumn)}</Layout.TableRow>
            </Layout.TableHeader>
            <Layout.TableBody>
              {!isEmptyProgram && !loading && list.map(renderItemProgram)}
              {loading && <LoadingProgramSkeleton />}
            </Layout.TableBody>
          </Layout.Table>
          {isEmptyResultProgram && !loading && <ResultNotFound />}
          {isEmptyProgram && !loading && <EmptyProgram />}
          {/* {loading && <LoadingProgramIndicator />} */}
        </Layout.TableContainer>
        <Pagination
          page={page}
          perPage={per_page}
          totalPage={total}
          onPrev={handleGoToPrevPage}
          onNext={handleGoToNextPage}
        />
      </S.CustomLayoutWrapper>
    </ProgramLibraryContext.Provider>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  programLibraryAndTemplates: state.rootReducer.programLibraryAndTemplates,
  permission: state.rootReducer.permission,
  cloudfrontList: state.cloudfrontList,
  programTags: state.rootReducer.programTags,
  isModalOpen: state.isModalOpen,
  is_hide_program_library_banner: state.rootReducer.generalSettings.is_hide_program_library_banner,
});

const mapDispatchToProps = {
  toggleModal,
  toggleConfirmModal,
  toggleSideBar,
  push,
  getProgramWithFilter,
  updateProgramSharingStatus,
  createNewTag,
  getMostRecentTagsList,
  toggleFullscreenModal,
  changeQueryParamsProgram,
  resetQueryParamsProgram,
  removeProgramLibraryItem,
  addBulkAddTagPrograms,
  hideProgramLibraryBanner,
  closeFullscreenModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgramLibraryAndTemplates));
