import get from 'lodash/get';
import Request from 'configs/request';
import { logout } from './auth/login';

export const SUCCESS_CLOUDFRONT_LIST = 'SUCCESS_CLOUDFRONT_LIST';

export const successGetCloudfrontList = cloudfrontList => ({
  type: SUCCESS_CLOUDFRONT_LIST,
  cloudfrontList,
});

export const getCloudfrontList = (isExpiredLink = false) => {
  return (dispatch, getState) => {
    const { cloudfrontList } = getState();
    if ((cloudfrontList || []).length > 0 && !isExpiredLink) return;

    return dispatch(
      Request.get(
        { url: `/api/cloudfront/signed-url`, headers: { agent: 'react' } },
        false,
        result => {
          try {
            const data = get(result, 'data.data', []);
            dispatch(successGetCloudfrontList(data));
          } catch (error) {}
        },
        error => {
          const status = get(error, 'response.status');

          if (status !== 401) {
            dispatch(logout);
          }
        },
      ),
    );
  };
};
