import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { NewSearchInput } from 'shared/SearchInput';
import { Checkbox } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export const AddResourcesPopupModal = styled(Modal)`
  &.ui.modal {
    width: 650px;

    .header {
      border-radius: 8px;
      padding-top: 20px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 0px !important;
      color: #202353 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #202353;

        &--description {
          margin-top: 5px;
          font-weight: normal;
          font-size: 13px;
          line-height: 150%;
          color: #323c47;
        }
      }
    }

    .content {
      padding: 30px 30px 0px 30px !important;
    }

    .actions {
      background: #fff;
      box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
      border: none;
      padding-top: 10px;
      padding-bottom: 10px;

      .actions__cancelBtn {
        padding: 7px 25px;
      }

      .actions__createBtn {
        margin-left: 5px;
        padding: 7px 50px;

        :disabled {
          background-color: #c4c4c4;
          border-color: #c4c4c4;
        }
      }
    }
  }

  .resourceResult__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #202353;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    &--selectedNumber {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #848484;
    }
  }
`;

export const SearchResourceInput = styled(NewSearchInput)`
  min-width: 270px;

  input {
    padding-top: 11px;
    padding-bottom: 11px;
    background: url(${CDN_URL}/images/search_grey_purple.svg) no-repeat;
    background-size: auto;
    background-position: 15px center;
    background-color: #f6f7f9;
    border: none;

    ::placeholder {
      color: #a6acc6;
      font-size: 13px;
      line-height: 18px;
      color: #a6acc6;
    }
    &:hover,
    &:focus {
      background-color: #f6f7f9;
    }
  }
`;

export const ResourceResultContainer = styled.div`
  max-height: 450px;
  min-height: 450px;
  overflow: auto;
  padding-right: 21px;
  margin-right: -21px;

  .resourceResult__noResult {
    margin: auto;
    text-align: center;
    margin-top: 200px;
  }
`;

export const CheckBoxIconWrapper = styled.div`
  position: absolute;
  top: auto;
  right: 20px;
  margin-bottom: 7px;
`;

export const CheckboxResource = styled(Checkbox)`
  input {
    :checked ~ .checkbox-icon {
      background-color: #7072dd;
      border-color: #7072dd;
      background-image: url(${CDN_URL}/images/check_studio_active.svg);
    }
  }

  .checkbox-icon {
    border: 1px solid #dadfea;
    border-radius: 3px;
    height: 16px;
    width: 16px;

    :hover {
      border: 1px solid #7072dd;
    }
  }
`;
export const ResourceAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;

  ${props =>
    props.coverImage
      ? css`
          background-image: url(${props.coverImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          &.youtube {
            background-color: #ffe7e7;
            background-image: url(${CDN_URL}/images/studio_resource_youtube_icon.svg);
          }

          &.vimeo {
            background-color: #e8fbff;
            background-image: url(${CDN_URL}/images/studio_resource_vimeo_icon.svg);
          }

          &.instagram {
            background-color: #ffe2ec;
            background-image: url(${CDN_URL}/images/studio_resource_instagram_icon.svg);
          }

          &.facebook {
            background-color: #e0f0ff;
            background-image: url(${CDN_URL}/images/studio_resource_facebook_icon.svg);
          }

          &.twitter {
            background-color: #e8f6ff;
            background-image: url(${CDN_URL}/images/studio_resource_twitter_icon.svg);
          }

          &.spotify {
            background-color: #e7ffef;
            background-image: url(${CDN_URL}/images/studio_resource_spotify_icon.svg);
          }

          &.link {
            background-color: #f7f8fc;
            background-image: url(${CDN_URL}/images/studio_resource_link_icon.svg);
          }

          &.document {
            background-color: #e8f4ff;
            background-image: url(${CDN_URL}/images/studio_resource_document_icon.svg);
          }

          &.form {
            background-color: rgba(76, 189, 54, 0.1);
            background-image: url(${CDN_URL}/images/studio_resource_form_icon.svg);
          }
        `}
`;

export const ResourceInfo = styled.div`
  width: calc(100% - 100px);

  .resourceInfo__name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .resourceInfo__description {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ResourceItemWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #ebedf4;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  :hover {
    border: 1px solid #5158cf;
    box-shadow: 0px 4px 8px #f1f2f6;
  }

  ${props =>
    props.active &&
    css`
      border: 1px solid #5158cf;
      background: #f8f8ff;
    `}

  ${props =>
    props.isGrayOutList &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;
