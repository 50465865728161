import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  ${props =>
    props.isHideFormBanner &&
    css`
      display: flex;
      width: 100%
      height: 100%;
      justify-content: center;
      align-items: center;
    `}
`;
