import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { UnlimitedSchedulingFailBackWrapper } from './style';
import { connect } from 'react-redux';

function UnlimitedSchedulingFailBack(props) {
  const { noWeeks, startDate } = props;
  const limitWeeks = _.get(props.permission, 'assignment_scheduling', 4);
  const limitWeekFrom = moment().add(limitWeeks, 'weeks').startOf('isoWeek');
  const endDate = moment(startDate).add(noWeeks, 'weeks').startOf('isoWeek');
  const noWeeksDiff = endDate.diff(limitWeekFrom, 'week');
  const percentHeight = noWeeksDiff > 0 ? (noWeeksDiff > noWeeks ? noWeeks : noWeeksDiff) / noWeeks : 0;
  const handleMouseEnter = () => {
    const { onMouseEnter } = props;
    if (onMouseEnter && typeof onMouseEnter === 'function') {
      onMouseEnter();
    }
  };
  return (
    <UnlimitedSchedulingFailBackWrapper
      style={{ height: percentHeight > 0 ? `calc( (100% - 20px) * ${percentHeight} ) ` : 0 }}
      onMouseEnter={handleMouseEnter}
    >
      <div className="fallback__content">
        <div className="fallback__contentTitle">Plan and Schedule ahead</div>
        <div className="fallback__contentDescription">
          <UpgradePathButton className="fallback__upgradeBtn" text="Upgrade" /> to schedule more than 4 weeks ahead
        </div>
      </div>
    </UnlimitedSchedulingFailBackWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    permission: rootReducer.permission,
  };
};

const mapDispatchToProps = () => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(UnlimitedSchedulingFailBack);
