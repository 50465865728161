import _ from 'lodash';
import {
  SUCCESS_LOGIN_USER,
  UPDATE_USER_INFO,
  SUCCESS_REMOVE_TEAM_MEMBER,
  USER_MARK_INTRO_VIDEO_WATCHED,
  USER_ID_VERIFY_SUCCESS,
} from 'actions/auth/login';

import { Types as StudioProgramTypes } from 'redux/studio-program/actions';
import { Types as ProfileTypes } from 'actions/profile';
import { Types as resourceTypes } from 'redux/studio-resource/actions';
import { Types as collectionTypes } from 'redux/studio-collection/actions';
import { Types as TeammateTypes } from 'redux/teammate/actions';
import { Types as AutoflowTypes } from 'redux/autoflow/actions';
import { Types as WaitingClientSTypes } from 'redux/waiting-activation-clients/actions';
import { Types as PackageTypes } from 'redux/package-list/actions';
import { Types as Product } from 'redux/product/actions';

export const user = (state = null, action) => {
  const { payload } = action;
  switch (action.type) {
    case UPDATE_USER_INFO:
      return Object.assign({}, action.user);

    case SUCCESS_LOGIN_USER:
      return Object.assign({}, action.user);

    case USER_ID_VERIFY_SUCCESS:
      return Object.assign({}, state, { trainerId: payload.trainerId });

    case ProfileTypes.UPDATE_SETTING_INVITE_REDUX: {
      let teams = state.teams;
      teams[0].team.is_enroll_auto_waiting = payload;
      return Object.assign({}, state, { teams });
    }

    case ProfileTypes.UPDATE_EMAIL_BILLING_REDUX: {
      let teams = state.teams;
      teams[0].team.billingEmail = payload.email;
      return Object.assign({}, state, { teams });
    }

    case 'UPDATE_USER_TEAM':
      let teams = state.teams.map(item => {
        if (item.team._id === action.payload._id) {
          item.team = action.payload;
        }
        return item;
      });
      return Object.assign({}, state, { teams });

    case 'UPDATE_USER_TEAM_ROLE':
      let team_member = state.team_member.map(item => {
        if (item._id === action.payload.userId) {
          item.teams[0].role = action.payload.role;
        }
        return item;
      });
      return Object.assign({}, state, { team_member });

    case 'UPDATE_PENDING_EMAIL':
      let { pending_email } = action.payload;
      return Object.assign({}, state, { pending_email });

    case 'LOGOUT_USER':
      return null;

    case SUCCESS_REMOVE_TEAM_MEMBER: {
      let removedMember = state.team_member.find(item => item._id === payload.data._id);
      if (removedMember) {
        removedMember.old_teams = [removedMember.teams[0]];
      }
      return Object.assign({}, state, {
        team_member: state.team_member.filter(item => item._id !== payload.data._id),
        team_old_members: [...state.team_old_members, removedMember],
      });
    }

    case StudioProgramTypes.STUDIO_PROGRAM_SUCCESS_HIDE_GUIDE:
      return Object.assign({}, state, {
        is_hide_guide_studio_program: true,
      });

    case StudioProgramTypes.STUDIO_PROGRAMS_SUCCESS_HIDE_BANNER:
      return Object.assign({}, state, { is_hide_banner_studio_program: true });

    case ProfileTypes.USER_ANSWERED_QUESTIONAIRE:
      return Object.assign({}, state, { is_answers_questionnaire: true });

    case ProfileTypes.USER_ANSWERED_TRACKING_FROM:
      return Object.assign({}, state, { is_answers_questionnaire_direct_traffic_lead_source: true });

    case resourceTypes.STUDIO_RESOURCES_SUCCESS_HIDE_BANNER:
      return Object.assign({}, state, { is_hide_banner_studio_resource: true });

    case collectionTypes.STUDIO_COLLECTIONS_SUCCESS_HIDE_BANNER:
      return Object.assign({}, state, { is_hide_banner_studio_collection: true });

    case TeammateTypes.TEAMMATE_SUCCESS_HIDE_BANNER:
      return Object.assign({}, state, { is_hide_banner_teammate: true });

    case AutoflowTypes.AUTOFLOW_SUCCESS_HIDE_BANNER:
      return Object.assign({}, state, { is_hide_banner_autoflow: true });

    case Product.PRODUCT_HIDE_BANNER_SUCCESS:
      return Object.assign({}, state, { is_hide_banner_product: true });

    case USER_MARK_INTRO_VIDEO_WATCHED:
      return Object.assign({}, state, { is_watch_video: true });

    case WaitingClientSTypes.WAITING_CLIENT_UPDATE_SHOW_POPUP_FLAG: {
      return Object.assign({}, state, { has_waiting_client: payload });
    }

    case PackageTypes.PAYMENT_COACH_HIDE_BANNER: {
      return Object.assign({}, state, { is_hide_banner_package: true });
    }

    case ProfileTypes.UPDATE_WORKOUT_COLLECTION_ONBOARDING: {
      return Object.assign({}, state, { is_passed_on_demand_collection_onboarding: true });
    }

    default:
      return state;
  }
};
