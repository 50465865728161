import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { getWorkoutBuilderSetting, saveWorkoutSetting } from 'components/WorkoutDetailModal/helper';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import WorkoutArrangement from './WorkoutArrangement';
import WorkoutOverview from './WorkoutOverview';

const ActionIcon = props => {
  return (
    <S.ActionIcon
      onClick={() => props.onSelect(props.viewType)}
      src={props.src}
      active={props.active}
      hoverSrc={props.hoverSrc}
      data-tip
      data-for={props.viewType}
      className={props.className || ''}
    >
      <ReactTooltip
        className="app-tooltip left-content-tooltip"
        id={props.viewType}
        effect="solid"
        place={'top'}
        delayShow={200}
      >
        <p>{props.tooltip}</p>
      </ReactTooltip>
    </S.ActionIcon>
  );
};

const RightPanel = props => {
  const [view, setView] = useState(
    _.isNil(getWorkoutBuilderSetting(props.user._id).viewRightPanel)
      ? 'arrangement'
      : getWorkoutBuilderSetting(props.user._id).viewRightPanel,
  );

  const onChangeView = newView => {
    if (newView === view) {
      saveWorkoutSetting(props.user._id, { viewRightPanel: '' });
      setView('');
    } else {
      setView(newView);
      saveWorkoutSetting(props.user._id, { viewRightPanel: newView });
    }
  };

  const isHasOverview =
    typeof props.fetchWorkoutsInRange === 'function' || typeof props.fetchWorkoutsByWeek === 'function';
  const isWeekCalendar = typeof props.fetchWorkoutsByWeek === 'function';

  return (
    <S.Wrapper className="workoutBuilder__workoutArrangementContainer">
      <S.Heading>
        <ActionIcon
          active={view === 'arrangement'}
          onSelect={onChangeView}
          viewType="arrangement"
          src={`${CDN_URL}/images/workout_builder_arrangement_icon.svg`}
          hoverSrc={`${CDN_URL}/images/workout_builder_arrangement_icon_hover.svg`}
          tooltip={view === 'arrangement' ? 'Minimize' : 'Workout Arrangement'}
          className="arrangementAction"
        />
        {isHasOverview && (
          <ActionIcon
            active={view === 'overview'}
            onSelect={onChangeView}
            viewType="overview"
            src={`${CDN_URL}/images/workout_builder_overview_icon.svg`}
            hoverSrc={`${CDN_URL}/images/workout_builder_overview_icon_hover.svg`}
            tooltip={view === 'overview' ? 'Minimize' : 'Workout Schedule'}
          />
        )}
      </S.Heading>
      {view && (isHasOverview || view !== 'overview') ? (
        <S.Content>
          {view === 'arrangement' && <WorkoutArrangement />}
          {view === 'overview' && isHasOverview && (
            <WorkoutOverview
              fetchWorkoutsInRange={props.fetchWorkoutsInRange}
              fetchWorkoutsByWeek={props.fetchWorkoutsByWeek}
              maxWeeks={props.maxWeeks}
              hasTrackedWorkout={props.hasTrackedWorkout}
              isWeekCalendar={isWeekCalendar}
              day={props.day}
            />
          )}
        </S.Content>
      ) : null}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    view: state.rootReducer.workoutBuilder.getIn(['rightPanelView']),
    day: state.rootReducer.workoutBuilder.getIn(['selectedWorkout', 'day']),
  };
};

export default connect(mapStateToProps, null)(RightPanel);
