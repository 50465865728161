import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/icons/arrow_up_currentColor.svg';

const Container = styled.button.attrs({
  type: 'button',
})`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #6995a5;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 10px;
  background-color: transparent;
  border: none;

  span {
    margin-right: 10px;
  }

  :hover {
    color: #5158cf;
    cursor: pointer;
  }
`;

export default ({ title, ...props }) => (
  <Container {...props} className="button--hide-banner">
    <span>{title || 'Hide Banner'}</span>
    <Icon />
  </Container>
);
