import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7b7e91;
`;

export const Wrapper = styled(Modal)`
  &.ui.modal {
    width: 820px;
    border-radius: 10px !important;
    @media screen and (max-height: 820px) {
      max-height: 100%;
    }
  }
`;

export const Header = styled.div`
  padding: 25px 30px 14px 30px;
  border-bottom: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-header {
    .sub-title {
      ${baseText}
    }
    .title {
      ${baseText}
      font-size: 19px;
      line-height: 29px;
      color: #202353;
      margin-top: 0;
    }
  }
  .right-header {
    .btn-add-csv {
      ${baseText}
      font-size: 13px;
      line-height: 20px;
      color: #202353;
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: #fff;
      border: 1px solid #e1e1ea;
      border-radius: 5px;
      padding: 0 19px;
      height: 36px;
      outline: none;
      cursor: pointer;
      svg path {
        fill: #202353;
      }
      &:hover {
        background-color: #f0f1ff;
        color: #5158cf;
        svg path {
          fill: #5158cf;
        }
      }
    }
  }
`;

export const Content = styled.div`
  position: relative;
  @media screen and (max-height: 820px) {
    height: calc(100% - 147px);
  }
`;

export const Action = styled.div`
  height: 60px;
  padding: 0 25px 0 25px !important;
  border-top: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-action {
    .error-msg {
      ${baseText}
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #ea314a;
    }
  }
  .right-action {
    display: flex;
    gap: 5px;
    .action-btn {
      ${baseText}
      color: #202353;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 0px 14px;
      height: 30px;
      border: none;
      background: transparent;
      border: 1px solid #e1e1ea;
      cursor: pointer;
      &.add-btn {
        width: 93px;
        max-width: 93px;
        color: #fff;
        border: 1px solid transparent;
        background: rgba(81, 88, 207, 1);
      }
      &:hover {
        &.add-btn {
          color: #fff;
          border: 1px solid transparent;
          background: rgba(81, 88, 207, 0.9);
        }
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.9;
      }
    }
  }
`;

export const TableContainer = styled.div`
  max-height: 653px;
  overflow-y: scroll;
  margin-right: 5px;
  @media screen and (max-height: 820px) {
    height: 100%;
  }
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1ea !important;
  }
  ::-webkit-scrollbar-track {
    margin-bottom: 30px;
    margin-top: 42px;
  }
  .table-header {
    display: flex;
    align-items: center;
    padding: 20px 0px 7px 75px;
    height: 42px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    &.is-scroll {
      background-color: #fff;
    }
    .table-cell {
      ${baseText}
      font-size: 10px;
      line-height: 15px;
      color: #7b7e91;
      text-transform: uppercase;
      &:nth-child(1) {
        width: 37.5%;
      }
      &:nth-child(2) {
        width: 34.1%;
      }
      &:last-child {
        flex: 1;
      }
      @media screen and (max-width: 1080px) {
        &:nth-child(1) {
          width: 29.1%;
        }
        &:nth-child(2) {
          width: 24.5%;
        }
        &:nth-child(3) {
          width: 23.9%;
        }
      }
    }
  }
  .item {
    display: flex;
    align-items: center;
    padding-right: 18px;
    margin-bottom: 10px;
    .text-number {
      width: 58px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        ${baseText}
        font-size: 13px;
        line-height: 20px;
        color: #202353;
      }
      svg {
        display: none;
        width: 20px;
        height: 20px;
        cursor: pointer;
        path {
          fill: #7b7e91;
        }
      }
    }

    .content {
      height: 66px;
      padding: 0 14px 0 14px;
      display: flex;
      align-items: center;
      box-shadow: 0px 2px 30px 0px #acacac33;
      border: 1px solid #f0f0f2;
      border-radius: 7px;
      flex: 1;
      gap: 15px;
      .form-item {
        &:nth-child(1) {
          width: 37%;
        }
        &:nth-child(2) {
          width: 34%;
        }
        &:last-child {
          flex: 1;
        }
        @media screen and (max-width: 1080px) {
          &:nth-child(1) {
            width: 28%;
          }
          &:nth-child(2) {
            width: 23.2%;
          }
          &:nth-child(3) {
            width: 22.5%;
          }
        }
      }
      .nutrition-info {
        height: 36px;
        border-radius: 5px;
        border: 1px solid #e1e1ea;
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
        span {
          ${baseText}
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #202353;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        &:hover {
          border-color: #5158cf;
        }
        &.is-empty {
          justify-content: center;
          border: none;
          background: #f0f0f2;
          svg {
            width: 12px;
            height: 12px;
            path {
              stroke: #202353;
            }
          }
          &:hover {
            background: #f0f1ff;
            svg {
              path {
                stroke: #5158cf;
              }
            }
          }
        }
      }
    }
    &:hover {
      .text-number {
        &.allow-remove {
          span {
            display: none;
          }
          svg {
            display: block;
          }
        }
      }
    }
  }
  .item-add {
    padding: 10px 0 50px 0;
    display: flex;
    align-items: center;
    .text-number {
      ${baseText}
      font-size: 13px;
      line-height: 20px;
      color: #202353;
      width: 58px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .button-add-more {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f0f1ff;
      cursor: pointer;
      border: none;
      outline: none;
      svg path {
        fill: #5158cf;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.9;
      }
    }
  }
`;
