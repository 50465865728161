import React, { useState, useRef, useLayoutEffect, useMemo, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import * as S from './style';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export default props => {
  const { totalWeek, startDay, placement, offset, singleDay } = props;
  const checkInRange = !singleDay && !isNaN(parseInt(startDay));

  if (!totalWeek) {
    console.error('totalWeek is number and greater than or equal to 1');
    return null;
  }

  //state
  const [open, setOpen] = useState(false);
  const [startWeek, setStartWeek] = useState(0);
  const [styles, setMenuStyles] = useState({});
  const [day, setDay] = useState(props.value || '');
  const [dayOnMouse, setDayOnMouse] = useState(null);

  //ref
  const triggerRef = useRef();
  const popupRef = useRef();

  useEffect(() => {
    setDay(props.value);
  }, [props.value, open]);

  const checkValidDay = typeof props.isValidDay === 'function' ? props.isValidDay : () => true;

  const generatePopupStyles = () => {
    if (!triggerRef.current) {
      return console.error('Can not find the trigger');
    }

    if (!popupRef.current) {
      return console.error('Can not find the menu');
    }

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const popupRect = popupRef.current.getBoundingClientRect();
    const { width, height, x, y } = triggerRect;
    const windowHeight = window.innerHeight;

    switch (placement) {
      case 'bottom': {
        const styles = {};
        styles.left = x + Number(_.get(offset, 'left', 0));
        styles.top = y + height;
        setMenuStyles(styles);
        return;
      }

      case 'top': {
        const left = x + Number(_.get(offset, 'left', 0));
        const top = y - popupRect.height - Number(_.get(offset, 'top', 0));
        setMenuStyles({ left, top });
        return;
      }

      default: {
        let left = x + width + 15,
          bottom = windowHeight - y - height - 80;

        if (bottom < 0) {
          if (y + height > windowHeight) {
            left = x;
            bottom = windowHeight - y - 15;
          } else {
            bottom = 0;
          }
        } else {
          if (bottom + popupRect.height > windowHeight) {
            bottom = windowHeight - popupRect.height;
          }
        }

        setMenuStyles({ left, bottom });

        return;
      }
    }
  };

  useLayoutEffect(() => {
    if (open) {
      generatePopupStyles();
    }
  }, [open]);

  const endWeek = useMemo(() => {
    let index = startWeek + 4;

    if (index > totalWeek) {
      index = totalWeek;
    }

    return index;
  }, [startWeek, totalWeek]);

  const toggleOpen = () => {
    setOpen(prev => !prev);
  };

  const onRootClose = () => {
    setOpen(false);
  };

  const onPrev = () => {
    if (startWeek - 4 >= 0) {
      setStartWeek(startWeek - 4);
    }
  };

  const onNext = () => {
    if (startWeek + 4 < totalWeek) {
      setStartWeek(startWeek + 4 > totalWeek ? totalWeek : startWeek + 4);
    }
  };

  const onSelectDay = event => {
    const { dayIndex } = event.currentTarget.dataset;

    if (checkValidDay(dayIndex)) {
      setDay(Number(dayIndex));
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onUpdate = () => {
    if (typeof props.onChange === 'function') {
      props.onChange(day);
      setOpen(false);
    }
  };

  const onMouseEnterDay = event => {
    if (!checkInRange) {
      return;
    }

    const { dayIndex } = event.currentTarget.dataset;
    setDayOnMouse(Number(dayIndex));
  };

  const onMouseLeaveCalendar = () => {
    if (!checkInRange) {
      return;
    }

    setDayOnMouse(null);
  };

  return (
    <RootCloseWrapper event="click" disabled={!open} onRootClose={onRootClose}>
      <S.Wrapper className={classnames('selectDayCalendar', { open: open })}>
        <S.Trigger onClick={toggleOpen} className="selectDayCalendar__trigger" ref={triggerRef}>
          <span>Day {props.value + 1}</span>
          <img src={`${CDN_URL}/images/new_calendar.svg`} alt="" />
        </S.Trigger>
        <S.PopupContainer className="selectDayCalendar__popup" ref={popupRef} style={styles}>
          <S.Header className="selectDayCalendar__header">{props.title || 'Choose day to schedule'}</S.Header>
          <S.Content className="selectDayCalendar__content">
            <S.WeekInfo>
              <PrevIcon className="selectDayCalendar__prev" onClick={onPrev} />
              <div>
                Week {startWeek + 1}
                {endWeek === startWeek ? '' : ` - ${endWeek}`} of {totalWeek}
              </div>
              <NextIcon className="selectDayCalendar__next" onClick={onNext} />
            </S.WeekInfo>
            <S.DayGrid
              className="selectDayCalendar__grid"
              onMouseLeave={onMouseLeaveCalendar}
              checkInRange={checkInRange}
            >
              {_.range((endWeek - startWeek) * 7).map(index => {
                const dayIndex = startWeek * 7 + index;
                const dayInRange =
                  checkInRange && startDay <= dayIndex && dayIndex < (dayOnMouse !== null ? dayOnMouse : day);

                return (
                  <S.DayContainer
                    key={dayIndex}
                    className={classnames('day', {
                      'day--disabled': !checkValidDay(dayIndex),
                      'day--active': dayIndex === day || dayIndex === startDay,
                      'day--inRange': dayInRange,
                    })}
                  >
                    <S.Day data-day-index={dayIndex} onClick={onSelectDay} onMouseEnter={onMouseEnterDay}>
                      {`0${dayIndex + 1}`.slice(-2)}
                    </S.Day>
                  </S.DayContainer>
                );
              })}
            </S.DayGrid>
          </S.Content>
          <S.Footer className="selectDayCalendar__footer">
            <Button key="cancel" textOnly onClick={onCancel}>
              Cancel
            </Button>
            <Button key="update" purple className="save" onClick={onUpdate}>
              Update
            </Button>
          </S.Footer>
        </S.PopupContainer>
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

const PrevIcon = props => (
  <svg width="7px" height="12px" viewBox="0 0 7 12" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g transform="translate(-37.000000, -118.000000)" stroke="#728096" strokeWidth="1.5">
        <g transform="translate(13.000000, 97.000000)">
          <g transform="translate(18.000000, 11.000000)">
            <polyline points="12 21 7 16 12 11"></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const NextIcon = props => (
  <svg width="7px" height="12px" viewBox="0 0 7 12" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g transform="translate(-219.000000, -118.000000)" stroke="#728096" strokeWidth="1.5">
        <g transform="translate(13.000000, 97.000000)">
          <g transform="translate(18.000000, 11.000000)">
            <polyline
              transform="translate(191.500000, 16.000000) rotate(180.000000) translate(-191.500000, -16.000000) "
              points="194 21 189 16 194 11"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
