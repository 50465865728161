import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid #5158cf;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px;
  text-align: center;
  width: 200px;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;

  .everfit-calo-input {
    border: none;
    padding: 0;
    line-height: 1;
    max-width: 135px;
    font-weight: bold;
    color: #5158cf;

    &:focus {
      border: none;
      outline: none;
    }
  }
`;
