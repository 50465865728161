import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${props => props.width}px;

  .status-dropdown__single-value {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #818da1;
    margin-left: 0px;
    margin-top: 1px;
  }
  .status-dropdown__value-container {
    height: 100%;
    padding: 0px;
  }
  .status-dropdown__control {
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #d6dae4;
    min-height: unset;
    height: 36px;
    :hover {
      border: 1px solid #5158cf;
    }
    transition: none !important;
  }

  .status-dropdown__control--is-focused {
    box-shadow: none;
  }
  .status-dropdown__control--menu-is-open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid #d6dae4;
    :hover {
      border: 1px solid #d6dae4;
    }
  }
  .status-dropdown__menu {
    margin-top: -1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    border: 1px solid #d6dae4;
    overflow: hidden;
    ${'' /* border: 1px solid #5158cf;  purple one*/}
  }
  .status-dropdown__menu-list {
    padding: 0px;
    overflow: hidden;
  }
  .status-dropdown__option {
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    padding: 0px;
    color: #818da1;
    height: 36px;
    display: flex;
    align-items: center;
    :active {
      background: #eeefff;
    }
    :hover {
      background: #eeefff;
      & .custom-status-chip.option {
        background: #eeefff;
      }
    }
  }
  .status-dropdown__option--is-focused {
    background: #ffffff;
  }
  .status-dropdown__indicators {
    transform: translateX(-18px);
  }
  .custom-status-chip {
    position: static;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #818da1;
    background: #ffffff;
    transform: translateX(7px);
    ::before {
      top: 11px;
    }
  }
`;

export const CompactInformationItem = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 5px 10px;
  margin-right: 5px;
  width: auto;
`;

export const StatusWrapper = styled(CompactInformationItem)`
  top: 10px;
  left: 10px;
  position: absolute;
  padding-left: 20px;

  :before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${props => props.statusColor};
    z-index: 1;
  }
`;
