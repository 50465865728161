import React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';

import * as S from '../style';

import { useMyContext } from '../context/ProgramTemplatesContext';
import TagsModalContent from './TagsModalContent';

const TagsModal = () => {
  const { openFullscreenModal, openDetailModal, toggleTagsModal, openTagsModal } = useMyContext();
  const opened = openFullscreenModal && openDetailModal && openTagsModal;

  return (
    <S.FullscreenModalWrapper
      className={classNames('tags', {
        open: opened,
      })}
    >
      <RootCloseWrapper event="click" disabled={!openTagsModal} onRootClose={toggleTagsModal}>
        <S.FullscreenModalContainer
          className={classNames('tags', {
            open: opened,
          })}
        >
          {openFullscreenModal && openDetailModal && openTagsModal && <TagsModalContent />}
        </S.FullscreenModalContainer>
      </RootCloseWrapper>
    </S.FullscreenModalWrapper>
  );
};

export default TagsModal;
