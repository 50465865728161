export const FILE_VALIDATION = {
  ACCEPTED: '.avi, .flv, .mp4, .mov, .3gp, image/png, image/jpeg, image/jpg, image/gif',
  VALIDATE_EXTENTION: ['png', 'jpeg', 'jpg', 'avi', 'flv', 'mp4', 'mov', '3gp', 'gif'],
  MAX_SIZE_VIDEO: 300,
  MAX_SIZE_IMAGE: 25,
};

export const DEFAULT_STATE = {
  mediaData: {
    list: [],
    error: '',
  },
  uploadInformation: {
    file: null,
    bucketData: null,
    apiURL: '',
    isLocalFile: false,
  },
};

export const UPLOAD_CONFIGS = { url: '/api/forum/upload', method: 'post' };

export const UPLOAD_KEY = 'attachments';

export const ERROR_MESSAGE = {
  INVALID_VIDEO_LINK: 'Please enter a valid Youtube or Vimeo URL',
  MAX_PHOTO_NUMBER: 'You can only upload 5 images',
};
