import styled from 'styled-components';

export default styled.div`
  background: #ffffff;
  border: 1px solid #f4f5f9;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  padding: 30px;
  display: flex;
  z-index: 2;

  .chart-section {
    flex: 1;
    padding-right: 60px;
    z-index: 1;

    .chart-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #202353;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;

      &.protein-dot {
        background-color: #57b5ea;
      }
      &.carbs-dot {
        background-color: #65cc9c;
      }
      &.fat-dot {
        background-color: #ffbe49;
      }
    }
  }

  .detail-section {
    width: 270px;
    height: 410px;
    background: #f8f8f8;
    border-radius: 8px;
    padding: 25px 10px;
    position: relative;

    .detail-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #131350;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        .edit-btn {
          cursor: pointer;
          margin-left: 10px;
          padding: 0px 7px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 56px;
          height: 24px;
          border: 1px solid #d6dae4;
          border-radius: 4px;

          div {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #bdbdbd;
          }

          &:hover {
            border-color: #5158cf;

            div {
              color: #5158cf;
            }
            svg * {
              fill: #5158cf;
            }
          }
        }
      }
    }

    .detail-subtitle {
      padding: 0 15px;
      font-size: 11px;
      line-height: 15px;
      margin-top: 5px;
    }

    .detail-table {
      margin-top: 20px;

      .row {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 18px;
        padding: 10px 15px;

        &.row-header {
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
          text-align: right;
          text-transform: uppercase;
          color: #202353;

          .col-goal {
            font-weight: 600;
          }
        }

        .col {
          font-weight: 600;
        }
        .col-name {
          font-weight: 600;
          flex: 1;
        }
        .col-daily {
          text-align: right;
          width: 65px;
          margin-left: 10px;
          text-align: right;

          &__highlight {
            color: #dd1f39;
          }
        }
        .col-goal {
          text-align: right;
          width: 65px;
          margin-left: 10px;
          font-weight: normal;
        }
      }
      .row-calo {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin-top: 12px;
        padding: 8px 15px;
      }
    }
    .goal-banner {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .evfSelectBoxContainer .evfSelectBox__control {
    background-color: #ffffff;
    border: 1px solid #d6dae4;
  }
`;
