import Request from 'configs/request';

export const Types = {
  GET_COACH_BIOS_DETAIL_REQUEST: 'GET_COACH_BIOS_DETAIL_REQUEST',
  GET_COACH_BIOS_DETAIL_SUCCESS: 'GET_COACH_BIOS_DETAIL_SUCCESS',
  GET_COACH_BIOS_DETAIL_FAILED: 'GET_COACH_BIOS_DETAIL_FAILED',
  UPDATE_COACH_BIOS_DETAIL_REQUEST: 'UPDATE_COACH_BIOS_DETAIL_REQUEST',
  UPDATE_COACH_BIOS_DETAIL_SUCCESS: 'UPDATE_COACH_BIOS_DETAIL_SUCCESS',
  UPDATE_COACH_BIOS_DETAIL_FAILED: 'UPDATE_COACH_BIOS_DETAIL_FAILED',
  RESET_COACH_BIOS_DETAIL_DATA: 'RESET_COACH_BIOS_DETAIL_DATA',
};

export const getCoachBiosDetail = coachId => {
  return (dispatch, getState) => {
    const { user } = getState();

    dispatch({ type: Types.GET_COACH_BIOS_DETAIL_REQUEST });
    return dispatch(
      Request.get(
        { url: `/api/payment/coach-bios/${coachId}` },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          if (data) {
            dispatch({
              type: Types.GET_COACH_BIOS_DETAIL_SUCCESS,
              payload: { data, user },
            });
          }
        },
        (error, { dispatch }) => dispatch({ type: Types.GET_COACH_BIOS_DETAIL_FAILED, error }),
      ),
    );
  };
};

export const resetCoachDetailData = () => ({ type: Types.RESET_COACH_BIOS_DETAIL_DATA });

export const updateCoachBiosDetail = (coachId, formData) => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_COACH_BIOS_DETAIL_REQUEST });
    return dispatch(
      Request.post(
        { url: `/api/payment/coach-bios/${coachId}`, data: formData },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.UPDATE_COACH_BIOS_DETAIL_SUCCESS, payload: { data } });
        },
        (error, { dispatch }) => dispatch({ type: Types.UPDATE_COACH_BIOS_DETAIL_FAILED, error }),
      ),
    );
  };
};
