import styled from 'styled-components';

export const TransferTeamWrapper = styled.div`
  .evfSelectBoxContainer {
    padding-bottom: 10px;
    .evfSelectBox__control {
      max-width: 100%;
      .evfSelectBox__value-container {
        padding-left: 5px;
        display: block;
        height: 100%;
        .evfSelectBox__placeholder {
          padding-left: 10px;
        }
      }
      .select__option__label {
        display: flex;
        align-items: center;
        max-width: 90%;
        padding-top: 2px;
      }
      .evfSelectBox__value-container input {
        position: absolute;
        height: 0;
      }
    }
  }
`;

export const TransferMessageConfirm = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  margin-bottom: 25px;
`;

export const ProfileInfo = styled.div`
  display: flex;
`;

export const RoleName = styled.div`
  color: #202353;
  background: #eeeff4;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding: 6px 15px;
  margin-left: 15px;
  text-align: center;
  border-radius: 20px;
  white-space: nowrap;
`;

export const TransferLabel = styled.label`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  padding-bottom: 3px;
  padding-top: 5px;
  display: block;
`;

export const ContactInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .sb-avatar {
    margin-right: 9px;
  }
`;

export const SingleLabel = styled.div`
  .name {
    font-size: 13px;
    line-height: 150%;
    color: #323c47;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const SelectOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  border-bottom: 1px solid #dcdcde;
  cursor: pointer;

  .sb-avatar {
    flex: 0 0 26px;
  }

  &.select__option--is-focused {
    background-color: #f6f5ff;
  }

  :last-child {
    border-bottom: none;
  }

  .sb-avatar {
    margin-right: 12px;
  }

  .sb-avatar__image,
  .sb-avatar__text {
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const SelectOptionLabel = styled.div`
  flex: 1 1;
  max-width: 90%;

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #111111;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }

  .email {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #8d8c92;
  }
`;
