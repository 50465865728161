import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import ButtonIcon from 'shared/ButtonIcon';
import FormItemInput from 'shared/FormItemInput';

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }

    :disabled {
      background: #c4c4c4 !important;
      cursor: not-allowed !important;
    }
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
    `}
`;

export const CreateAddNewModal = styled(Modal)`
  &.ui.modal {
    width: 452px;
  }

  .header {
    color: #202353 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    border-radius: 8px;
  }

  .content {
    padding: 25px 30px 30px 30px !important;
  }

  .formControl {
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .submit {
    padding: 7px 29px !important;

    &:hover,
    &:focus,
    &:active {
      background: #5559ff !important;
    }
  }
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-top: 4px;
  margin-bottom: 0;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
`;

export const CreateAddNewDialog = styled(CreateAddNewModal)`
  &.ui.modal {
    width: 650px;
  }

  .submit {
    padding: 7px 54.6px !important;
  }

  .search-input {
    width: 265px;
    height: 40px;
    margin: 20px 0;

    input {
      background-color: #ffffff;
      border: 1px solid #f0f0f0;

      ::placeholder {
        color: #777777;
      }

      &:hover,
      &:active,
      &:focus {
        border: 1px solid #7072dd;
      }
    }
  }

  ${Description} {
    margin-top: 5px;
    color: #323c47;
  }

  ${Footer} {
    margin-top: 0;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 6px;

  .label__required {
    color: #ea314a;
  }
`;

export const Form = styled.form``;

export const Input = styled(FormItemInput)``;

export const SActions = styled(Modal.Actions)`
  padding: 10px 30px !important;
  background: #ffffff !important;
  border-top: none !important;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
`;
