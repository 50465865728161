import React from 'react';
import { Modal, Image } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import FormItem from './FormItem';
import { validateEmail, validatePhone } from 'utils/validations';
import { toast } from 'react-toastify';
import { sendRequestBusiness } from 'utils/hubspot';
import HubspotForm from 'react-hubspot-form';
import { MESSAGE_VALIDATE } from '../constants';
import { CDN_URL } from 'constants/commonData';
import LoadingIndicator from 'shared/LoadingIndicator';

import { ContactUsModalWrapper, LoadingWrapper } from './style';

const SuccessMessage = () => {
  return (
    <div style={{ display: 'flex', alignItem: 'center' }}>
      <img src={`${CDN_URL}/images/checkbox_whitegreen.svg`} alt="" />
      <span>&nbsp;&nbsp;&nbsp;Thank you, we will be in touch shortly!.</span>
    </div>
  );
};
export default class ContactUsModal extends React.Component {
  state = {};

  handleChangeField = (field, e) => {
    this.setState({ [field]: e.target.value });
  };

  validateForm = () => {
    const { name, email, phoneNumber } = this.state;

    if (!name || !email || !phoneNumber) {
      return false;
    }

    if (!validateEmail(email) || !validatePhone(phoneNumber)) {
      return false;
    }
    return true;
  };

  handleClose = () => {
    this.props.closeModal();
    this.setState({
      name: '',
      email: '',
      phoneNumber: '',
      businessName: '',
      workUrl: '',
      noClients: '',
      noTrainers: '',
      isSubmited: false,
    });
  };

  onSubmit = () => {
    this.setState({ isSubmited: true });
    if (this.validateForm()) {
      const { name, email, phoneNumber, businessName, workUrl, noClients, noTrainers } = this.state;
      const params = { name, email, phoneNumber, businessName, workUrl, noClients, noTrainers };
      this.setState({ loading: true });
      sendRequestBusiness(params);
      toast.success(<SuccessMessage />, { position: 'top-center' });
      this.handleClose();
      this.setState({ loading: false });
      // axiosInstance
      //   .post('/api/general/contact-us', params)
      //   .then(response => {
      //     toast.success(<SuccessMessage />, { position: 'top-center'});
      //     this.handleClose();
      //   })
      //   .finally(() => {
      //     this.setState({ loading: false });
      //   });
    }
  };

  render() {
    return (
      <div>
        <ContactUsModalWrapper
          size={'tiny'}
          open={this.props.isModalOpen}
          onClose={() => this.handleClose()}
          closeOnDimmerClick={false}
          closeIcon={
            <button className="close-button" data-btn="button-close">
              <Image src={`${CDN_URL}/images/close_circle.svg`} />
            </button>
          }
        >
          <Modal.Content className="contactUsModal__content">
            <div className="contactUsModal__title">Tell us about your business</div>
            {process.env.REACT_APP_ENABLE_HUBSPOT_FORM && process.env.REACT_APP_HUBSPOT_FORM_ENTERPRISE_FORMID ? (
              <HubspotForm
                portalId={process.env.REACT_APP_HUBSPOT_PORTALID}
                formId={process.env.REACT_APP_HUBSPOT_FORM_ENTERPRISE_FORMID}
                onFormSubmitted={() => this.handleClose()}
                loading={
                  <LoadingWrapper>
                    <LoadingIndicator title="Loading..." className="loading-hubspot" size="medium" />
                  </LoadingWrapper>
                }
                cssClass="hub-spot-form"
                translations={{
                  en: {
                    required: MESSAGE_VALIDATE.REQUIRED,
                    invalidEmailFormat: MESSAGE_VALIDATE.INVALID_EMAIL_FORMAT,
                    phoneInvalidCharacters: MESSAGE_VALIDATE.PHONE_INVALID_CHARACTERS,
                    invalidEmail: MESSAGE_VALIDATE.INVALID_EMAIL,
                    submissionErrors: {
                      MISSING_REQUIRED_FIELDS: MESSAGE_VALIDATE.MISSING_REQUIRED_FIELDS,
                    },
                  },
                }}
              />
            ) : (
              <>
                <form className="contactUsModal__form">
                  <FormItem
                    label="Your name"
                    placeholder="Eg: Jon Smith"
                    required
                    isSubmited={this.state.isSubmited}
                    value={this.state.name}
                    onChange={e => this.handleChangeField('name', e)}
                  />
                  <FormItem
                    label="Your Email"
                    placeholder="Eg: Sample@email.com"
                    required
                    isSubmited={this.state.isSubmited}
                    value={this.state.email}
                    onChange={e => this.handleChangeField('email', e)}
                    rules={[
                      {
                        valid: validateEmail(this.state.email),
                        message: 'Wrong format email',
                      },
                    ]}
                  />
                  <FormItem
                    label="Phone Number"
                    placeholder="Eg: 111 - 111 -111"
                    required
                    isSubmited={this.state.isSubmited}
                    value={this.state.phoneNumber}
                    onChange={e => this.handleChangeField('phoneNumber', e)}
                    rules={[
                      {
                        valid: validatePhone(this.state.phoneNumber),
                        message: 'Wrong format phone',
                      },
                    ]}
                  />
                  <FormItem
                    label="Business Name"
                    isSubmited={this.state.isSubmited}
                    value={this.state.businessName}
                    required
                    onChange={e => this.handleChangeField('businessName', e)}
                  />
                  <FormItem
                    label="Website or linkedin"
                    isSubmited={this.state.isSubmited}
                    value={this.state.workUrl}
                    required
                    onChange={e => this.handleChangeField('workUrl', e)}
                  />
                  <FormItem
                    label="Number of Trainers?"
                    isSubmited={this.state.isSubmited}
                    value={this.state.noTrainers}
                    onChange={e => this.handleChangeField('noTrainers', e)}
                  />
                  <FormItem
                    label="Number of clients?"
                    isSubmited={this.state.isSubmited}
                    value={this.state.noClients}
                    onChange={e => this.handleChangeField('noClients', e)}
                  />
                </form>
                <div className="contactUsModal__footer">
                  <Button purple onClick={this.onSubmit} disabled={this.state.loading}>
                    <span>Submit</span>
                  </Button>
                </div>
              </>
            )}
          </Modal.Content>
        </ContactUsModalWrapper>
      </div>
    );
  }
}
