// Lib
import React, { useRef } from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import animateScrollTo from 'animated-scroll-to';
import { isMobile } from 'react-device-detect';

// Action
import { toggleConfirmModal } from 'actions/modal';
import {
  duplicateQuestion,
  getIsUploading,
  removeQuestion,
  selectedQuestion,
  updatePartialQuestion,
  updateListQuestionConfirmed,
} from 'redux/form-details/actions';

// Shared
import ConfirmModal from 'shared/ConfirmModal';
import WelcomeScreen from './WelcomeScreen';
import SingleChoice from './SingleChoice';
import MultipleChoice from './MultipleChoice';
import LinearScale from './LinearScale';
import ShortAnswer from './ShortAnswer';
import LongAnswer from './LongAnswer';
import Signature from './Signature';
import ImageText from './ImageText';
import DateAnswer from './DateAnswer';
import MetricAnswer from './MetricAnswer';
import { TriggerAppliedIcon, TypingModal, TYING_TYPES } from 'shared/TriggerForms';

// Constants
import { isSafari } from 'utils/commonFunction';
import { FORM_STATUS, QUESTION_TYPES } from 'constants/commonData';

// Assets
import { ReactComponent as DuplicateIcon } from 'assets/icons/question_copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/question_delete.svg';

import * as S from './style';

const renderQuestion = ({
  contentRef,
  question,
  workingQuestion,
  idx,
  updatePartialQuestion,
  onRemoveQuestion,
  onDuplicateQuestion,
  allowEdit,
  onScrollTo,
  selectedQuestion,
  loading,
  isDragging,
  mouseLeaveRef,
  numberQuestion,
  getIsUploading,
  isPublished,
  cloudfrontList,
  user,
  bodyMetrics,
  toggleConfirmModal,
  updateListQuestionConfirmed = () => {},
  listQuestionConfirmed = [],
}) => {
  if (!question) {
    return null;
  }
  const isSelected = workingQuestion && question && workingQuestion._id === question._id;

  let content = null;

  const handleMouseLeave = () => {
    mouseLeaveRef.current && mouseLeaveRef.current();
  };

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  const confirmRemoveQuestion = () => {
    const { has_trigger_in_use } = question;

    if (has_trigger_in_use && typeof toggleConfirmModal === 'function') {
      return toggleConfirmModal(
        true,
        <TypingModal
          isCloseAfterSubmit
          type={TYING_TYPES.REMOVE_QUESTION}
          closeModal={closeConfirmModal}
          submitAction={onRemoveQuestion(question._id)}
        />,
      );
    }

    onRemoveQuestion(question._id)();
  };

  switch (question.type) {
    case QUESTION_TYPES.WELCOME_SCREEN:
      return (
        <WelcomeScreen
          contentRef={contentRef}
          cloudfrontList={cloudfrontList}
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          onRemoveQuestion={onRemoveQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
        />
      );
    case QUESTION_TYPES.SINGLE_CHOICE:
      content = (
        <SingleChoice
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
          toggleConfirmModal={toggleConfirmModal}
          updateListQuestionConfirmed={updateListQuestionConfirmed}
          listQuestionConfirmed={listQuestionConfirmed}
        />
      );
      break;
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      content = (
        <MultipleChoice
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
        />
      );
      break;
    case QUESTION_TYPES.LINEAR_SCALE:
      content = (
        <LinearScale
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
        />
      );
      break;
    case QUESTION_TYPES.SHORT_ANSWER:
      content = (
        <ShortAnswer
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
        />
      );
      break;
    case QUESTION_TYPES.LONG_ANSWER:
      content = (
        <LongAnswer
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
        />
      );
      break;
    case QUESTION_TYPES.SIGNATURE:
      content = (
        <Signature
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
        />
      );
      break;
    case QUESTION_TYPES.IMAGE_TEXT:
      content = (
        <ImageText
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          getIsUploading={getIsUploading}
          isPublished={isPublished}
        />
      );
      break;
    case QUESTION_TYPES.DATE_TIME:
      content = (
        <DateAnswer
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
          user={user}
        />
      );
      break;
    case QUESTION_TYPES.METRIC:
      content = (
        <MetricAnswer
          workingQuestion={question}
          idx={idx}
          isSelected={isSelected}
          updatePartialQuestion={updatePartialQuestion}
          allowEdit={allowEdit}
          onScrollTo={onScrollTo}
          selectedQuestion={selectedQuestion}
          loading={loading}
          isDragging={isDragging}
          mouseLeaveRef={mouseLeaveRef}
          numberQuestion={numberQuestion}
          bodyMetrics={bodyMetrics}
        />
      );
      break;

    default:
      break;
  }

  const renderTriggerIcon = () => {
    const { type, has_trigger_in_use } = question;

    const isSingleChoiceAutomation = type === QUESTION_TYPES.SINGLE_CHOICE;
    if (!isSingleChoiceAutomation) return null;

    if (!has_trigger_in_use) return null;

    return (
      <S.MainPanelTriggerIconWrapper>
        <TriggerAppliedIcon />
      </S.MainPanelTriggerIconWrapper>
    );
  };

  return (
    <S.QuestionWrapper ref={contentRef} onMouseLeave={handleMouseLeave} isSafari={isSafari() || isMobile}>
      {content}
      {allowEdit && (
        <S.ActionsWrapper>
          <DuplicateIcon data-tip data-for={`duplicate-question-${idx}`} onClick={onDuplicateQuestion(question)} />
          <ReactTooltip
            id={`duplicate-question-${idx}`}
            className="question-tooltip"
            effect="solid"
            place="top"
            delayShow={300}
          >
            Duplicate
          </ReactTooltip>
          <DeleteIcon data-tip data-for={`delete-question-${idx}`} onClick={confirmRemoveQuestion} />
          <ReactTooltip
            id={`delete-question-${idx}`}
            className="question-tooltip"
            effect="solid"
            place="top"
            delayShow={300}
          >
            Delete
          </ReactTooltip>
        </S.ActionsWrapper>
      )}
      {renderTriggerIcon()}
    </S.QuestionWrapper>
  );
};

const MainPanel = ({
  workingQuestion,
  workingForm,
  questions,
  updatePartialQuestion,
  removeQuestion,
  duplicateQuestion,
  selectedQuestion,
  toggleConfirmModal,
  loading,
  isDragging,
  getIsUploading,
  cloudfrontList,
  user,
  bodyMetrics,
  updateListQuestionConfirmed = () => {},
  listQuestionConfirmed = [],
}) => {
  const wrapperRef = useRef({});
  const contentRef = useRef();
  const mouseLeaveRef = useRef();
  const clicked = useRef(false);
  const hasWelcome = questions.some(item => item && item.type === QUESTION_TYPES.WELCOME_SCREEN);
  const isPublished = get(workingForm, 'status') === FORM_STATUS.PUBLISHED;
  const isEditMode = get(workingForm, 'is_edit_mode', false);
  const hasResponses = get(workingForm, 'number_of_responses', 0);
  const allowEdit = !isPublished || (isPublished && isEditMode);

  const handleRemoveQuestion = id => () => {
    const question = questions.find(question => question._id === id);
    if (hasResponses && question.type !== QUESTION_TYPES.WELCOME_SCREEN) {
      if (get(question, 'has_trigger_in_use', false)) {
        removeQuestion && removeQuestion(id);
      } else {
        toggleConfirmModal(
          true,
          <ConfirmModal
            title="Are you sure?"
            content="You already collected responses to this question. If you delete it, that data will be deleted too."
            confirmButtonTitle="Yes, delete it"
            noIcon
            noBorder
            hasHoverState
            onConfirm={() => removeQuestion(id)}
            className="remove-question-confirm-popup-container"
          />,
        );
      }
    } else {
      removeQuestion && removeQuestion(id);
    }
  };

  const handleDuplicateQuestion = data => () => {
    if (clicked.current) {
      return;
    }
    clicked.current = true;
    duplicateQuestion &&
      duplicateQuestion(omit(data, ['has_trigger_in_use', 'related_question_onboarding_flows']), () => {
        clicked.current = false;
      });
  };

  const handleScrollTo = index => {
    if (wrapperRef.current) {
      animateScrollTo(index * (wrapperRef.current.clientHeight - 32) + 17, {
        minDuration: 100,
        speed: 0,
        elementToScroll: wrapperRef.current,
      }).then(() => {});
    }
  };
  let numberIndex = 0;

  return (
    <S.MainPanelWrapper isPublished={isPublished} allowEdit={allowEdit} ref={wrapperRef}>
      {questions.map((question, idx) => {
        const { type = '' } = question || {};
        numberIndex = type !== QUESTION_TYPES.IMAGE_TEXT ? numberIndex + 1 : numberIndex;

        let numberQuestion =
          type !== QUESTION_TYPES.WELCOME_SCREEN && type !== QUESTION_TYPES.IMAGE_TEXT
            ? hasWelcome
              ? numberIndex - 1
              : numberIndex
            : '—';

        return renderQuestion({
          contentRef,
          question,
          workingQuestion,
          idx: hasWelcome ? idx : idx + 1,
          updatePartialQuestion,
          onRemoveQuestion: handleRemoveQuestion,
          onDuplicateQuestion: handleDuplicateQuestion,
          allowEdit: allowEdit,
          onScrollTo: () => handleScrollTo(idx),
          selectedQuestion,
          loading,
          isDragging,
          mouseLeaveRef,
          numberQuestion: numberQuestion,
          getIsUploading,
          isPublished,
          cloudfrontList,
          user,
          bodyMetrics,
          toggleConfirmModal,
          updateListQuestionConfirmed,
          listQuestionConfirmed,
        });
      })}
    </S.MainPanelWrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, cloudfrontList, user } = state;
  const { formDetails } = rootReducer;
  return {
    cloudfrontList,
    questions: formDetails.questions || [],
    workingQuestion: formDetails.workingQuestion,
    workingForm: formDetails.workingForm,
    loading: formDetails.loading,
    user,
    bodyMetrics: formDetails.bodyMetrics || [],
    listQuestionConfirmed: get(formDetails, 'workingForm.listQuestionConfirmed', []) || [],
  };
};

const mapDispatchToProps = dispatch => ({
  updatePartialQuestion: bindActionCreators(updatePartialQuestion, dispatch),
  removeQuestion: bindActionCreators(removeQuestion, dispatch),
  duplicateQuestion: bindActionCreators(duplicateQuestion, dispatch),
  selectedQuestion: bindActionCreators(selectedQuestion, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getIsUploading: bindActionCreators(getIsUploading, dispatch),
  updateListQuestionConfirmed: bindActionCreators(updateListQuestionConfirmed, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPanel);
