// Libs
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

// Actions
import { openSecondToggle } from 'redux/broadcast-messages/actions';
import { getInitTotalRecipe } from 'redux/recipes/actions';

// Shared
import { Button, Checkbox } from 'shared/FormControl';
import SliderRangeInput from 'shared/SliderRangeInput';
import { DietarySelectItem } from 'components/RecipeDetail/DietaryInfo/style';

// Constants
import { convertS3UrlToCloudFrontUrl, getFixedPopupStyles } from 'utils/commonFunction';
import { RECIPE_FROM, RECIPE_FILTER_STATUS, MIN_CALORIES, MAX_CALORIES } from 'components/Recipes/constants';

// Assets
import { ReactComponent as FilterIcon } from 'assets/icons/MealPlans/meal_plan_filter_icon.svg';

import * as S from './style';

function RecipeFilter(props) {
  const {
    appliedFilter,
    trigger,
    onChangeNumberFilter,
    onSelectedFilterApply,
    isShowRecipeStatus = false,
    changeQueryParams,
    maxCalories,
    classNameCustom = '',
    changedMaxCalories,
    getInitTotalRecipe,
    listDietary = [],
    cloudfrontList,
  } = props;

  const initMax =
    changedMaxCalories < maxCalories ? changedMaxCalories : maxCalories > MAX_CALORIES ? maxCalories : MAX_CALORIES;

  const initFilterState = {
    dietaries: [],
    caloriesRange: {
      from: MIN_CALORIES,
      to: initMax,
    },
    recipeFrom: [],
    recipeStatus: [],
  };

  const [open, setOpen] = useState(false);
  const [clear, setClear] = useState(false);
  const [hasWarning, setHasWaring] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({ ...initFilterState, ...appliedFilter });
  const { dietaries = [], recipeFrom = [], recipeStatus = [] } = selectedFilter || {};

  const [changedRangeNumber, setChangedRangeNumber] = useState(0);

  useEffect(() => {
    if (!open) {
      setSelectedFilter({ ...initFilterState, ...appliedFilter });
      setClear(!clear);
    }
  }, [appliedFilter, open]);

  const triggerRef = useRef();
  const menuRef = useRef();
  const menuWidth = 437;
  const menuHeight = 330;

  const getMenuStyles = () => {
    if (open && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const styles = getFixedPopupStyles(triggerRect, menuHeight, isShowRecipeStatus ? 8 : 17);
      let left = triggerRect.x + 10;

      if (left + menuWidth > window.innerWidth) {
        left = window.innerWidth - menuWidth - 32;
      }

      return {
        styles: { ...styles, left },
      };
    }

    return { styles: { display: 'none' } };
  };

  const onUpdate = () => {
    onSelectedFilterApply && onSelectedFilterApply(selectedFilter);
    setOpen(false);
  };

  const { styles, className } = getMenuStyles();

  const handleCloseFilterPopup = () => {
    setSelectedFilter({ ...initFilterState, ...appliedFilter });
    if (!isEmpty(appliedFilter)) {
      setChangedRangeNumber(
        get(appliedFilter, 'caloriesRange.from', 0) !== MIN_CALORIES ||
          get(appliedFilter, 'caloriesRange.to', 0) < initMax
          ? 1
          : 0,
      );
      setOpen(false);
    }
  };

  const handleResetSelectedFilter = () => {
    getInitTotalRecipe(true).then(res => {
      const { data = {} } = res.data;
      const { max_calories = MAX_CALORIES } = data;
      if (max_calories < maxCalories) {
        const initRange = {
          from: MIN_CALORIES,
          to: max_calories < MAX_CALORIES ? MAX_CALORIES : max_calories,
        };
        onSelectedFilterApply && onSelectedFilterApply({ ...initFilterState, caloriesRange: initRange });
      } else if (!isEqual(initFilterState, selectedFilter)) {
        onSelectedFilterApply && onSelectedFilterApply(initFilterState);
        changeQueryParams && changeQueryParams();
      }
    });

    setSelectedFilter(initFilterState);
    setChangedRangeNumber(0);
    setClear(!clear);
  };

  const handleOpen = () => {
    if (open) {
      setSelectedFilter({ ...initFilterState, ...appliedFilter });
      setChangedRangeNumber(
        get(appliedFilter, 'caloriesRange.from', 0) !== MIN_CALORIES ||
          get(appliedFilter, 'caloriesRange.to', 0) < initMax
          ? 1
          : 0,
      );
    }
    setOpen(prev => !prev);
  };

  const handleChooseDietary = data => {
    const checked = get(selectedFilter, 'dietaries', []).some(
      item => get(item, 'value', '') === get(data, 'value', ''),
    );
    let updatedList = [];
    if (checked) {
      updatedList = [...get(selectedFilter, 'dietaries', [])].filter(
        item => get(item, 'value', '') !== get(data, 'value', ''),
      );
    } else {
      updatedList = [...get(selectedFilter, 'dietaries', []), data];
    }
    const newFilter = { ...selectedFilter, dietaries: updatedList };
    setSelectedFilter(newFilter);
  };

  const handleFilterRecipeFrom = data => {
    const checked = get(selectedFilter, 'recipeFrom', []).some(item => item === data);
    let updatedList = [];
    if (checked) {
      updatedList = [...get(selectedFilter, 'recipeFrom', [])].filter(item => item !== data);
    } else {
      updatedList = [...get(selectedFilter, 'recipeFrom', []), data];
    }
    const newFilter = { ...selectedFilter, recipeFrom: updatedList };
    setSelectedFilter(newFilter);
  };

  const handleFilterRecipeStatus = data => {
    const checked = get(selectedFilter, 'recipeStatus', []).some(item => item === data);
    let updatedList = [];
    if (checked) {
      updatedList = [...get(selectedFilter, 'recipeStatus', [])].filter(item => item !== data);
    } else {
      updatedList = [...get(selectedFilter, 'recipeStatus', []), data];
    }
    const newFilter = { ...selectedFilter, recipeStatus: updatedList };
    setSelectedFilter(newFilter);
  };

  const onChangeCaloriesRange = (fromNumber, toNumber) => {
    const newFromNumber = fromNumber < toNumber ? fromNumber : toNumber;
    const newToNumber = fromNumber > toNumber ? fromNumber : toNumber;
    setChangedRangeNumber(newFromNumber !== MIN_CALORIES || newToNumber !== initMax ? 1 : 0);

    const newFilter = { ...selectedFilter, caloriesRange: { from: newFromNumber, to: newToNumber } };
    setSelectedFilter(newFilter);
  };

  const filterNumber = useMemo(() => {
    const dietaryNumber = dietaries.length;
    const recipeFromNumber = recipeFrom.length;
    const recipeStatusNumber = recipeStatus.length;
    const numberFilter = dietaryNumber + recipeFromNumber + recipeStatusNumber + changedRangeNumber;
    onChangeNumberFilter(numberFilter);
    return numberFilter;
  }, [dietaries, recipeFrom, recipeStatus, changedRangeNumber, clear, open]);

  const handleWaring = isWarning => {
    setHasWaring(isWarning);
  };

  const renderRecipeFrom = () => {
    return (
      <>
        <S.FilterTitle>Recipe from</S.FilterTitle>
        <S.RecipeFromWrapper>
          {RECIPE_FROM.map(item => {
            const checked = get(selectedFilter, 'recipeFrom', []).includes(get(item, 'key', ''));

            return (
              <S.RecipeFromItem
                key={get(item, 'key', '')}
                onClick={event => {
                  event.preventDefault();
                  handleFilterRecipeFrom(get(item, 'key', ''));
                }}
              >
                <Checkbox className="recipe-check-box" checked={checked} />
                <S.CheckboxLabel>{get(item, 'label', '')}</S.CheckboxLabel>
              </S.RecipeFromItem>
            );
          })}
        </S.RecipeFromWrapper>
      </>
    );
  };

  const renderRecipeStatus = () => {
    return (
      <>
        <S.FilterTitle>Status</S.FilterTitle>
        <S.RecipeFromWrapper>
          {RECIPE_FILTER_STATUS.map(item => {
            const checked = get(selectedFilter, 'recipeStatus', []).includes(get(item, 'key', ''));

            return (
              <S.RecipeFromItem
                key={get(item, 'key', '')}
                onClick={e => {
                  e.preventDefault();

                  handleFilterRecipeStatus(get(item, 'key', ''));
                }}
              >
                <Checkbox className="recipe-check-box" checked={checked} />
                <S.CheckboxLabel>{get(item, 'label', '')}</S.CheckboxLabel>
              </S.RecipeFromItem>
            );
          })}
        </S.RecipeFromWrapper>
      </>
    );
  };

  const renderRecipeDietary = item => {
    const { value = '', name = '', icon = '', icon_hover = '' } = item;
    const active = get(selectedFilter, 'dietaries', []).some(item => get(item, 'value', '') === value);

    const iconConvert = convertS3UrlToCloudFrontUrl(icon, cloudfrontList, true);
    const iconHoverConvert = convertS3UrlToCloudFrontUrl(icon_hover, cloudfrontList, true);

    return (
      <DietarySelectItem active={active} key={value} onClick={() => handleChooseDietary(item)}>
        <img className="icon-dietary" src={iconConvert} alt={name} />
        <img className="icon-dietary-hover" src={iconHoverConvert} alt={name} />
        <span>{name}</span>
      </DietarySelectItem>
    );
  };

  const applyFilterRange = useMemo(() => {
    const toCalories = appliedFilter
      ? get(appliedFilter, 'caloriesRange.to', MAX_CALORIES)
      : get(selectedFilter, 'caloriesRange.to', MAX_CALORIES);
    const hasChangeMax = changedMaxCalories < toCalories;

    return hasChangeMax
      ? { from: MIN_CALORIES, to: changedMaxCalories }
      : get(appliedFilter, 'caloriesRange', { from: MIN_CALORIES, to: initMax });
  }, [appliedFilter, changedMaxCalories]);

  const currentInputRange = useMemo(() => {
    const toCalories = get(selectedFilter, 'caloriesRange.to', MAX_CALORIES);
    const hasChangeMax = changedMaxCalories < toCalories;

    return hasChangeMax
      ? { from: MIN_CALORIES, to: changedMaxCalories }
      : get(selectedFilter, 'caloriesRange', { from: MIN_CALORIES, to: initMax });
  }, [selectedFilter, changedMaxCalories]);

  return (
    <RootCloseWrapper event="click" onRootClose={handleCloseFilterPopup}>
      <S.Wrapper className={classNames('clients-filter', classNameCustom, className, { open: open })}>
        <S.TriggerContainer ref={triggerRef} onClick={handleOpen}>
          {trigger ? (
            trigger(open)
          ) : (
            <S.FilterButton active={open}>
              <FilterIcon className="filter-icon" />
              <span className="filter-label">Filter</span>
              {filterNumber > 0 && <S.FilterNumber className="filter-number">{filterNumber}</S.FilterNumber>}
            </S.FilterButton>
          )}
        </S.TriggerContainer>
        <S.Popup ref={menuRef} style={styles} menuHeight={menuHeight} menuWidth={menuWidth} className="popup-content">
          <S.Header>
            <S.PopupTitleHeader>Filter by</S.PopupTitleHeader>
            <S.ClearAllFilter onClick={handleResetSelectedFilter}>Clear All</S.ClearAllFilter>
          </S.Header>
          <S.Body isShowRecipeStatus={isShowRecipeStatus}>
            {/* <S.FilterSectionWrapper>{renderRecipeFrom()}</S.FilterSectionWrapper> */}
            {/* {isShowRecipeStatus && <S.FilterSectionWrapper>{renderRecipeStatus()}</S.FilterSectionWrapper>} */}
            <S.FilterSectionWrapper>
              <S.FilterTitle>Calories</S.FilterTitle>
              <S.SelectRangeWrapper>
                <SliderRangeInput
                  min={MIN_CALORIES}
                  max={initMax}
                  onInputRangeChange={onChangeCaloriesRange}
                  currentInputRange={currentInputRange}
                  open={open}
                  clear={clear}
                  onWarning={handleWaring}
                  appliedFilter={applyFilterRange}
                />
              </S.SelectRangeWrapper>
            </S.FilterSectionWrapper>
            <S.FilterSectionWrapper>
              <S.FilterTitle>Dietary Information</S.FilterTitle>
              <S.SelectDietary>{listDietary.map(renderRecipeDietary)}</S.SelectDietary>
            </S.FilterSectionWrapper>
          </S.Body>
          <S.Footer className="footer">
            <Button purple onClick={onUpdate} className="update-button" disabled={hasWarning}>
              Update
            </Button>
          </S.Footer>
        </S.Popup>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { recipes },
    cloudfrontList,
  } = state;

  const listDietary = get(recipes, 'listDietary', []);

  return {
    changedMaxCalories: get(recipes, 'maxCalories', {}),
    listDietary,
    cloudfrontList,
  };
};

const mapDispatch = dispatch => ({
  openSecondToggle: bindActionCreators(openSecondToggle, dispatch),
  getInitTotalRecipe: bindActionCreators(getInitTotalRecipe, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(RecipeFilter);
