import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { push } from 'connected-react-router';
import { getGroupDetail } from 'redux/forum/actions';
import { toggleModal } from 'actions/modal';
import { toggleConfirmModal } from 'actions/modal';
import { autoflowGetLeaderboardClients, autoflowLeaderboardUnmount } from 'redux/autoflow/leaderboard/actions';
import { getConnectedLeaderboard, updateGroup } from 'redux/forum/actions';
import { withRouter } from 'react-router';

import LeaderboardTab from './component';

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    group: state.rootReducer.forum.group,
    posts: state.rootReducer.forum.posts,
    members: state.rootReducer.forum.members,
    connectedLeaderboard: state.rootReducer.forum.connectedLeaderboard,
    leaderboardSetting: state.rootReducer.forum.leaderboardSetting,
    rankingTypes: state.rootReducer.leaderboard.get('rankingTypes'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const forumId = ownProps.match.params.forumId || ownProps.match.params.id;
  return {
    push: url => dispatch(push(url)),
    getGroupDetail: () => dispatch(getGroupDetail(forumId)),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    autoflowGetLeaderboardClients: bindActionCreators(autoflowGetLeaderboardClients, dispatch),
    autoflowLeaderboardUnmount: bindActionCreators(autoflowLeaderboardUnmount, dispatch),
    getConnectedLeaderboard: callback => dispatch(getConnectedLeaderboard(forumId, callback)),
    updateGroup: data => dispatch(updateGroup({ ...data, groupId: forumId })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeaderboardTab));
