import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { toggleModal } from 'actions/modal';
import * as S from './style';
import FormItem from './FormItem';
import { Button } from 'shared/FormControl';
import { axiosInstance } from 'configs/request';
import { getListStudioCollection } from 'redux/studio-collection/actions';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import { CDN_URL, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { generateParams } from 'shared/AssetsShareSetting/constants';

class AddCollectionFormPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      link: '',
      link_type: props.source_type,
      ...props.defaultCollection,
      author: props.user._id,
      share: TEAM_SHARE_PRIVATE,
    };
  }

  handleChangeField = (field, e) => {
    this.setState({ [field]: e.target.value });
  };

  validateForm = () => {
    const { name } = this.state;

    if (!name) {
      return false;
    }

    return true;
  };

  handleClose = () => {
    this.props.toggleModal(false);
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmited: true });
    const { user } = this.props;
    const { name, author, share } = this.state;

    const paramsShare = generateParams({
      ownership: author,
      shareStatus: share,
    });

    const params = {
      name,
      owner: paramsShare.owner,
      share: paramsShare.share,
    };
    let endPoint = '/api/studio-collection/add';
    this.setState({ loading: true });
    axiosInstance
      .post(endPoint, params)
      .then(response => {
        const { data } = response.data;
        if (data.author === user._id || data.share !== TEAM_SHARE_PRIVATE) {
          this.props.push(`/home/studio-collection/${data._id}`);
        }
        this.handleClose();
      })
      .finally(() => {
        this.setState({ loading: false, isSubmited: false });
      });
  };

  componentDidMount() {
    window.addEventListener('popstate', this.handleBackButtonToggleModal);
  }

  handleBackButtonToggleModal = () => {
    if (this.props.isModalOpen) {
      this.handleClose();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButtonToggleModal, false);
  }

  handleChangeOwnershipStatus = ({ author, share }) => {
    this.setState({ author, share });
  };

  render() {
    const { user } = this.props;
    const { loading, share } = this.state;
    const isDisabledSubmit = loading || !this.validateForm();
    return (
      <S.CreateNewStudioCollectionModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <Modal.Header>
          <div className="header-title">Create new collection</div>
          <OwnershipPopupStudio
            size={28}
            user={user}
            owner={user}
            shareStatus={share}
            saveChangeOnClose={this.handleChangeOwnershipStatus}
          />
        </Modal.Header>
        <Modal.Content>
          <form onSubmit={this.onSubmit} className="addStudioCollection__form">
            <FormItem
              label="Collection Name"
              placeholder="Collection Name"
              required
              maxLength={20}
              inputProps={{ autoFocus: true, maxLength: 20 }}
              isSubmited={this.state.isSubmited}
              value={this.state.name}
              onChange={e => this.handleChangeField('name', e)}
            />
          </form>
          <div className="addStudioCollection__footer">
            <Button disabled={isDisabledSubmit} onClick={this.onSubmit} purple>
              Create
            </Button>
          </div>
        </Modal.Content>
      </S.CreateNewStudioCollectionModal>
    );
  }
}

const actionCreators = { toggleModal, push, getListStudioCollection };

const mapStateToProps = state => {
  return {
    user: state.user,
    isModalOpen: state.isModalOpen,
  };
};

export default connect(mapStateToProps, actionCreators)(AddCollectionFormPopup);
