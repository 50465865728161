export const CATEGORIES_STATUS = {
  inactive: false,
  active: true,
};

export const VALIDATION_LIST = {
  name: true,
  type: true,
};

export const VALIDATION_FIELD = ['name', 'type'];

export const FormatLayout = {
  LAGER: 'large_card',
  SQUARE: 'square_card',
  NARROW: 'narrow_card',
  LIST: 'list',
};

export const INIT_SECTION = {
  format: 'large_card',
  resources: [],
  section_name: '',
};

export const WORKOUT_TYPE = {
  REGULAR: 'regular',
  VIDEO: 'video',
};
