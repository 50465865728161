import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Image, Button, Icon } from 'semantic-ui-react';
import './styles.scss';
import SegmentForm from './SegmentForm';
import Manage from './Manage';
import { toggleModal } from 'actions/modal';
import { CDN_URL } from 'constants/commonData';

class SegmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showManageScreen: !!props.onlyManage,
      editingSegment: null,
    };
  }

  onClose = () => this.props.toggleModal(false);

  editSegment = item => this.setState({ editingSegment: item, showManageScreen: false });

  render() {
    const { isModalOpen, onlyManage, selectedSegment, user } = this.props;
    const { showManageScreen, editingSegment } = this.state;
    const className = showManageScreen ? 'segment-modal segment-modal--manage' : 'segment-modal segment-modal--form';
    let title = 'Save segment';

    if (showManageScreen) {
      title = 'Manage Segments';
    } else {
      if (editingSegment) {
        title = 'Edit segment';
      } else if (selectedSegment && selectedSegment.trainer._id !== user._id) {
        title = 'Save to my segments';
      }
    }

    return (
      <Modal open={isModalOpen} onClose={this.onClose} className={className} closeOnDimmerClick={false}>
        <Modal.Header>
          <div className="title">
            {showManageScreen && !onlyManage ? (
              <div className="back-button" onClick={() => this.setState({ showManageScreen: false })}>
                <Icon name="chevron left" />
                <span>Back</span>
              </div>
            ) : null}
            <span>{title}</span>
          </div>
          <Button onClick={this.onClose} className="close-button" type="button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
        </Modal.Header>
        <Manage show={showManageScreen} editSegment={this.editSegment} />
        <SegmentForm
          show={!showManageScreen}
          openManageGroup={() => this.setState({ showManageScreen: true })}
          onSuccess={() =>
            editingSegment ? this.setState({ showManageScreen: true, editingSegment: null }) : this.onClose()
          }
          editingSegment={editingSegment}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { rootReducer, user, isModalOpen } = state;
  const selectedSegment = rootReducer.segment.list.find(item => item._id === rootReducer.segment.selected);

  return {
    selectedSegment,
    user,
    isModalOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentModal);
