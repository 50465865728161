import styled from 'styled-components';
import ConfirmModalEl from 'shared/ConfirmModal';

export const Wrapper = styled.div`
  .packageActions__saveBtn {
    padding: 6px 30px;
    color: #818da1;

    svg {
      margin-right: 5px;

      path {
        fill: #818da1;
      }
    }
  }

  .packageActions__saveBtn:disabled {
    color: #fff;

    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 72px;
`;

export const OtherSettingsWrapper = styled.div`
  margin-bottom: 72px;
  display: flex;
  flex-direction: column;
  gap: 13px;

  .bold-text {
    font-weight: 700 !important;
  }
`;

export const OtherHeader = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
  margin-bottom: 0;
`;

export const ConfirmModal = styled(ConfirmModalEl)`
  &.ui.modal.confirm-popup-container.new-style {
    width: 467px !important;

    .confirm-content-header {
      padding: 32px 32px 16px 32px !important;

      .confirm-header-label {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .confirm-content-body {
      padding: 0 32px !important;

      .confirm-content-label {
        line-height: 20px;
        color: #4a4d56;
      }
    }

    .confirm-actions {
      padding: 32px !important;

      .ui.button {
        max-height: 30px;
        padding: 5px 16px;
        border-radius: 5px;
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        min-width: fit-content;
        width: fit-content;
      }
    }

    &.error {
      .confirm-content-header .confirm-header-label {
        svg rect {
          fill: rgba(234, 49, 74, 1);
        }
      }
      .confirm-actions .ui.button {
        &.confirm-yes-button {
          min-width: 51px;
          background-color: rgba(234, 49, 74, 1);

          &:hover {
            background: rgba(234, 49, 74, 0.9);
          }
        }
      }
    }
  }
`;
