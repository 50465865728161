/**
 * @flow
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  addWeekAtIndex,
  exitProgramDetailScreen,
  handlePasteWeek,
  resetCopyItem,
} from 'redux/program_library/program_library.actionCreators';
import { getMostRecentTagsList } from 'redux/tags/actions';

import ProgramCalendarComponent from './component';

const mapStateToProps = state => {
  const { rootReducer, calendarStartDate } = state;
  const {
    program_library: {
      selectedWeek,
      startDateCopy,
      weekIndex,
      selectedWorkout,
      selected_current_week,
      selected_total_week,
      selected,
      calendar_type,
      currentWorkouts,
      isLoadingWorkouts,
      isAddingWeek,
    },
  } = rootReducer;

  return {
    startDateCopy: startDateCopy,
    weekIndex: weekIndex,
    selectedWeek: selectedWeek,
    selectedWorkout: selectedWorkout,
    calendarStartDate,
    currentWeek: selected_current_week,
    totalWeek: selected_total_week,
    selectedProgram: selected,
    workoutSets: selected.workout_sets,
    calendarType: calendar_type,
    currentWorkouts: currentWorkouts,
    isLoadingWorkouts: isLoadingWorkouts,
    isAddingWeek: isAddingWeek,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addWeekAtIndex: bindActionCreators(addWeekAtIndex, dispatch),
    exitProgramDetailScreen: bindActionCreators(exitProgramDetailScreen, dispatch),
    resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
    handlePasteWeek: bindActionCreators(handlePasteWeek, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCalendarComponent);
