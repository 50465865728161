import ConfirmCancelChangesModal from './ConfirmCancelChanges';
import ConfirmPublishChangesModal from './ConfirmPublishChanges';
import ConfirmTurnOnEditModeModal from './ConfirmTurnOnEditMode';
import './style.scss';

export default {
  ConfirmCancelChangesModal,
  ConfirmTurnOnEditModeModal,
  ConfirmPublishChangesModal,
};
