import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { denormalize } from 'normalizr';
import { SuperSet } from 'database/schema';

import { ENTITY_NAME } from 'database/constants';

import * as S from './style';
import ReactTooltip from 'react-tooltip';

const ExerciseIcon = () => {
  return (
    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3.5" cy="3.5" r="3" stroke="#A4A4A4" />
    </svg>
  );
};

const SupersetItem = props => {
  const superset = props.superset.toJS();
  const { exercise, exercise_instance } = superset;
  const currentEx = exercise || exercise_instance;
  return (
    <S.Wrapper>
      <div className="supersetItem">
        <div className="supersetItem__icon">
          <ExerciseIcon />
        </div>
        <div
          className={classnames('supersetItem__title', { 'supersetItem__title--unkown': !currentEx.title })}
          data-tip
          data-for={superset._id}
        >
          {currentEx.title || 'Unknown Exercise'}
        </div>
        {!currentEx.title && (
          <ReactTooltip id={superset._id} effect="solid" place="top">
            <span>You did not choose this exercise</span>
          </ReactTooltip>
        )}
        <S.SupersetIcon />
      </div>
    </S.Wrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    rootReducer: { model },
  } = state;

  return {
    superset: denormalize(model.getIn([ENTITY_NAME.Superset, ownProps.supersetId]), SuperSet, model),
  };
};
export default connect(mapStateToProps, null)(SupersetItem);
