import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { loadMoreStudioCollection } from 'redux/studio-collection/actions';
import StudioCollectionHeading from '../Heading';
import CollectionItem from './CollectionItem';
import LoadingIndicator from 'shared/LoadingIndicator';
import * as S from './style';

class CollectionList extends React.Component {
  constructor(props) {
    super(props);
    this.handleScrollDebounce = _.debounce(this.handleScrollDebounce, 300);
    this.listRef = React.createRef();
    this.state = {};
  }

  handleScroll = event => {
    event.persist();
    this.handleScrollDebounce.call(null, event);
  };

  handleScrollDebounce = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const { list, total, loadMore, isSearching } = this.props;
    const bottom = scrollHeight - (scrollTop + clientHeight);
    if (list.length < total && bottom < 150 && !isSearching) {
      loadMore();
    }
  };

  goToDetail = collection => {
    this.props.push(`/home/studio-collection/${collection._id}`);
  };

  render() {
    const { list, isSearching, cloudfrontList, user } = this.props;

    return (
      <S.Wrapper onScroll={this.handleScroll}>
        <StudioCollectionHeading banner />
        <div className="collectionList__heading">
          <div>Collections</div>
        </div>
        {!isSearching && !list.length ? <S.NoResult>No collections</S.NoResult> : null}
        {isSearching ? (
          <LoadingIndicator size="medium" title="Loading resource collections" />
        ) : (
          <S.List>
            {list.map((item, index) => (
              <CollectionItem
                id={`collection-${index}`}
                key={item._id}
                collection={item}
                onGoDetail={this.goToDetail}
                cloudfrontList={cloudfrontList}
                user={user}
              />
            ))}
          </S.List>
        )}
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      studioCollection: { list, total, isSearching },
    },
    sideBarVisible,
    cloudfrontList,
    user,
  } = state;
  return { list, total, isSearching, sideBarVisible, cloudfrontList, user };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  loadMore: bindActionCreators(loadMoreStudioCollection, dispatch),
});

export default connect(mapState, mapDispatch)(CollectionList);
