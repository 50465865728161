import React, { useEffect } from 'react';
import WorkoutCollectionCategoryDetail from './component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { get, isEqual } from 'lodash';
import {
  getWorkoutCollectionCategoryDetail,
  getOnDemandWorkouts,
  resetCategory,
  addWorkouts,
  getPreSignedUrl,
  updateCategoryDetail,
  removeWorkout,
  toggleCategory,
  moveOnDemandWorkout,
  moveOnDemandWorkoutToTop,
  moveOnDemandWorkoutToBottom,
  changeQueryParams,
  loadMoreWorkouts,
  getAssignedWorkouts,
} from 'redux/workout-collection-category/actions';
import { toggleConfirmModal } from 'actions/modal';

import { INITIAL_WORKING_COLLECTION_CATEGORY } from 'constants/commonData';
import { getAllOnDemandWorkouts, searchAllOnDemandWorkouts } from 'redux/on-demand-workouts/actions';
import { getCustomBrandingData } from 'redux/custom-branding/actions';
import { getAllLabels } from 'redux/category-labels/actions';

const index = ({
  match,
  workoutCollectionCategory,
  getWorkoutCollectionCategoryDetail,
  push,
  loading,
  getOnDemandWorkouts,
  getAllOnDemandWorkouts,
  onDemandWorkouts,
  addWorkouts,
  resetCategory,
  workouts,
  getPreSignedUrl,
  updateCategoryDetail,
  toggleCategory,
  toggleConfirmModal,
  removeWorkout,
  customBrandingBackground,
  searchAllOnDemandWorkouts,
  getCustomBrandingData,
  moveOnDemandWorkout,
  moveOnDemandWorkoutToTop,
  moveOnDemandWorkoutToBottom,
  changeQueryParams,
  loadMoreWorkouts,
  query,
  getAllLabels,
  getAssignedWorkouts,
  groupLabels,
  categoryLabels,
  user,
  cloudfrontList,
}) => {
  const workoutCollectionCategoryId = match.params.categoryId;
  useEffect(() => {
    if (customBrandingBackground.src === '') {
      getCustomBrandingData && getCustomBrandingData();
    }
    if (workoutCollectionCategoryId) {
      getWorkoutCollectionCategoryDetail(workoutCollectionCategoryId);
      getOnDemandWorkouts(workoutCollectionCategoryId);
    }
    getAllLabels && getAllLabels();
    return () => {
      // resetCategory();
    };
  }, []);
  if (isEqual(workoutCollectionCategory, INITIAL_WORKING_COLLECTION_CATEGORY) || loading) {
    return null;
  }
  return (
    <WorkoutCollectionCategoryDetail
      customBrandingBackground={customBrandingBackground}
      workoutCollectionCategory={workoutCollectionCategory}
      push={push}
      cloudfrontList={cloudfrontList}
      getAllOnDemandWorkouts={getAllOnDemandWorkouts}
      onDemandWorkouts={onDemandWorkouts}
      addWorkouts={addWorkouts}
      workouts={workouts}
      getWorkoutCollectionCategoryDetail={getWorkoutCollectionCategoryDetail}
      getPreSignedUrl={getPreSignedUrl}
      updateCategoryDetail={updateCategoryDetail}
      toggleCategory={toggleCategory}
      toggleConfirmModal={toggleConfirmModal}
      removeWorkout={removeWorkout}
      loading={loading}
      searchAllOnDemandWorkouts={searchAllOnDemandWorkouts}
      moveOnDemandWorkout={moveOnDemandWorkout}
      moveOnDemandWorkoutToTop={moveOnDemandWorkoutToTop}
      moveOnDemandWorkoutToBottom={moveOnDemandWorkoutToBottom}
      changeQueryParams={changeQueryParams}
      loadMoreWorkouts={loadMoreWorkouts}
      query={query}
      groupLabels={groupLabels}
      getAssignedWorkouts={getAssignedWorkouts}
      categoryLabels={categoryLabels}
      user={user}
    />
  );
};

const mapStateToProps = state => {
  const {
    workoutCollectionCategory,
    onDemandWorkouts: { list },
    categoryLabels,
  } = state.rootReducer;

  let groupLabels = {
    level: {},
    duration: {},
    others: [],
  };

  get(categoryLabels, 'list', []).forEach(item => {
    if (item.unique_code === 'level' || item.unique_code === 'duration') {
      groupLabels[item.unique_code] = item;
    } else {
      let data = get(groupLabels, 'others', []);
      groupLabels.others = [...data, item];
    }
  });

  return {
    workoutCollectionCategory,
    onDemandWorkouts: list || [],
    cloudfrontList: state.cloudfrontList,
    loading: workoutCollectionCategory.loading,
    workouts: workoutCollectionCategory.workouts,
    customBrandingBackground: state.rootReducer.customBranding.selectedWorkoutBackground,
    groupLabels,
    query: workoutCollectionCategory.query,
    categoryLabels: get(categoryLabels, 'list', []),
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkoutCollectionCategoryDetail: bindActionCreators(getWorkoutCollectionCategoryDetail, dispatch),
    getOnDemandWorkouts: bindActionCreators(getOnDemandWorkouts, dispatch),
    getAllOnDemandWorkouts: bindActionCreators(getAllOnDemandWorkouts, dispatch),
    getPreSignedUrl: bindActionCreators(getPreSignedUrl, dispatch),
    updateCategoryDetail: bindActionCreators(updateCategoryDetail, dispatch),
    addWorkouts: bindActionCreators(addWorkouts, dispatch),
    toggleCategory: bindActionCreators(toggleCategory, dispatch),
    resetCategory: bindActionCreators(resetCategory, dispatch),
    push: bindActionCreators(push, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    removeWorkout: bindActionCreators(removeWorkout, dispatch),
    getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
    searchAllOnDemandWorkouts: bindActionCreators(searchAllOnDemandWorkouts, dispatch),
    moveOnDemandWorkout: bindActionCreators(moveOnDemandWorkout, dispatch),
    moveOnDemandWorkoutToTop: bindActionCreators(moveOnDemandWorkoutToTop, dispatch),
    moveOnDemandWorkoutToBottom: bindActionCreators(moveOnDemandWorkoutToBottom, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    loadMoreWorkouts: bindActionCreators(loadMoreWorkouts, dispatch),
    getAllLabels: bindActionCreators(getAllLabels, dispatch),
    getAssignedWorkouts: bindActionCreators(getAssignedWorkouts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
