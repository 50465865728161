import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 428px;
  background: #fff;
  font-family: 'Open Sans';
  border-radius: 8px;
  padding: 4px;

  .beta-img {
    width: 100%;
  }

  .beta-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px 24px;

    &.submit-beta-content {
      padding: 20px 24px 22px;

      ol {
        margin: 0;
        padding-inline-start: 20px;
        margin-bottom: 68px;

        li:first-child p {
          margin-bottom: 6px;
        }
      }

      label.check-box-container.beta-cb {
        font-size: 11px !important;
        line-height: 16px;
        padding-left: 36px !important;
        color: #4a4d56 !important;
        margin-bottom: 0px !important;

        a {
          font-weight: 600;
          color: #5158cf;
        }

        span.checkmark {
          border-radius: 4px;
          top: 5px;
          height: 21px;
          width: 21px;
          background-color: #fff;
        }

        input:checked ~ .checkmark {
          background-color: #5158cf !important;
        }

        &.error span.checkmark {
          border-color: #fe3b6a;
        }
      }
    }

    &.congrats-content {
      gap: 16px;
      text-align: center;
      padding: 20px 24px 22px;

      h2 {
        font-size: 23px;
        line-height: 31.32px;
      }

      .let-start-btn {
        position: relative;
        left: 0px;
        top: 0px;
        width: 136px;
        height: 38px;
        margin: 16px auto 0;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          position: absolute;
          width: 100%;
          height: 100%;
        }

        span {
          font-size: 13px;
          line-height: 17.7px;
          font-weight: 600;
          color: #fff;
          z-index: 2;
        }
      }
    }

    h2 {
      font-size: 18px;
      line-height: 24.51px;
      font-weight: 600;
      color: #000;
      margin-bottom: 0;
    }

    p {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      color: #4a4d56;

      &:first-child {
        margin-bottom: 8px;
      }
    }
  }

  .beta-footer {
    display: flex;
    justify-content: end;

    button.next-btn {
      min-width: 100px;
      position: relative;
      top: 0px;

      &:hover {
        background-color: rgba(81, 88, 207, 0.9);
      }

      &.submit-btn {
        min-width: 115px;
        top: -1px;
      }
    }
  }
`;
