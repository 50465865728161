import styled, { css } from 'styled-components';

import { CDN_URL } from 'constants/commonData';

import AddMediaIcon from 'assets/icons/media_2.svg';
import AddMediaDraggingIcon from 'assets/icons/media_line_purple.svg';
import Audio from 'assets/icons/freestyle-section-attachment-audio.svg';
import AudioHover from 'assets/icons/freestyle-section-attachment-audio.svg';

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #dededf;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(174, 174, 174, 0.25);
  display: flex;
  justify-content: center;
  padding: ${props => (!props.shouldRenderMediaUpload ? '18px 0px' : '10px 9px')};
  margin-bottom: ${props => (props.noBottomMargin ? '0px' : '15px')};
  min-height: 70px;
  ${props => props.shouldRenderMediaUpload && 'background-color: #ffffff;'}

  ${props =>
    props.isDragging &&
    css`
      border-color: #5158cf;
    `}

  ${props =>
    props.noMargin &&
    css`
      margin: 12px 0px 0px;
      width: 100%;
    `}
`;

export const WrapperItem = styled.div`
  .drag-drop-background {
    height: 29px;
    width: 70px;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 5px;
`;

export const CoverImageWrapper = styled.div`
  margin: 1px 15px 16px;
`;

export const PopupWrapper = styled.div`
  padding: 10px 0px 9px 0px;
  font-weight: 600;
  font-size: 14px;
  color: #202353;
  width: 152px;
  .select-file__container {
    width: 100%;
  }
  .select-file__trigger {
    width: inherit;
  }
`;

export const PopupButton = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  padding: 0px 15px;
  color: #202353;
  :hover {
    background: #eaebff;
    color: #5158cf;
    p {
      font-weight: 600;
    }
  }
  path {
    stroke: #202353;
  }
  :hover path {
    stroke: #5158cf;
  }
  svg {
    margin-right: 8px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
  }
`;

export const WrapperItemText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
  max-width: 193px;
  margin-left: 30px;
`;

export const WrapperItemTextHightLight = styled.span`
  color: #5158cf;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: #5559ff;
  }
`;

export const AttachmentPreview = styled.div`
  width: 89px;
  height: 50px;
  flex: 0 0 auto;
  margin-right: 15px;
  border-radius: ${props => (props.isDocument ? '0px' : '5px')};
  position: relative;
  background-color: #fafbfc;
  overflow: hidden;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${props =>
    props.isVideo &&
    css`
      :before {
        content: '';
        position: absolute;
        left: 4px;
        bottom: 4px;
        width: 15px;
        height: 15px;
        background: url(${CDN_URL}/images/video_play.svg) left bottom / contain no-repeat;
        z-index: 1;
      }
    `}

  .media-player__trigger,
  .media-player__button {
    background: transparent;
  }

  .image-with-fallback {
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.isAudio &&
    css`
      :before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #fff url(${Audio}) left bottom / contain no-repeat;
        z-index: 1;
      }

      :hover {
        :before {
          background: #fff url(${AudioHover}) left bottom / contain no-repeat;
        }
      }
    `}

  ${props =>
    props.isDocument &&
    css`
      width: 30px;
      height: 30px;
      margin-left: 11px;
      :before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #fff url(${props.docIcon}) left bottom / contain no-repeat;
        z-index: 1;
      }
    `}
`;
