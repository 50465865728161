import React, { createContext } from 'react';
import { io } from 'socket.io-client';
import { socketAuthenticate } from './helpers';
import { connect } from 'react-redux';
import eventHandler from './eventHandler';

const WebSocketContext = createContext(null);
const WS_BASE = process.env.REACT_APP_SOCKET_URL;

export { WebSocketContext };

function SocketProvider({ children, dispatch }) {
  let socket;
  let ws;
  let isAuthenticated = false;

  if (!socket) {
    socket = io(WS_BASE, { transports: ['websocket'] });

    socket.on('connect', () => {
      console.log('Socket connected...');
      socketAuthenticate(socket, err => {
        if (!err && !isAuthenticated) {
          isAuthenticated = true;
          eventHandler(socket, dispatch);
        } else if (err) {
          isAuthenticated = false;
        }
      });
    });

    socket.on('disconnect', reason => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });

    socket.on('reconnect', attemptNumber => {
      console.log('Socket reconnect attemptNumber', attemptNumber);
    });

    socket.on('error', error => {
      console.log('Socket error', error);
    });

    ws = {
      socket: socket,
    };
  }

  return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
}

const mapStateToProps = state => {
  const {} = state;

  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketProvider);
