import React from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import { Workout } from 'types/model';
import ChooseWorkoutModal from 'components/ChooseWorkoutModal';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import CreateWorkoutTemplate from './index';
import { axiosInstance } from 'configs/request';
import { WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { addWorkout, openWorkoutDetail } from 'redux/workout_library/workout.actionCreators';

const DEFAULT_PARAMS = { page: 1, per_page: 20 };

class SystemWorkoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: props.total,
      workouts: props.defaultOptions,
      selectedWorkout: null,
      q: '',
    };

    this.search = '';
    this.searchDebounce = _.debounce(this.handleSearch, 500);
  }

  componentDidMount() {
    // this.searchWorkout('');
  }

  searchWorkout = q => {
    const params = { ...DEFAULT_PARAMS, q };
    this.setState({ searching: true });
    axiosInstance
      .get('/api/workout/v2/list_system', { params })
      .then(response => {
        if (q === this.search) {
          const { data, total } = response.data;
          this.setState({
            workouts: data,
            total,
            page: params.page,
            searching: false,
          });
        }
      })
      .catch(() => {});
  };

  handleSearch = q => {
    this.search = q;

    if (!q) {
      this.setState({
        workouts: this.props.defaultOptions,
        total: this.props.total,
        page: DEFAULT_PARAMS.page,
        q: '',
      });
    } else {
      this.searchWorkout(q);
    }
  };

  loadMore = () => {
    const { total, page, workouts, q } = this.state;
    const params = { page: page + 1, per_page: DEFAULT_PARAMS.per_page, q };

    if (workouts.length < total) {
      axiosInstance
        .get('/api/workout/v2/list_system', { params })
        .then(response => {
          if (q === this.search) {
            const { data, total } = response.data;
            const newWorkouts = _.uniqBy([...this.state.workouts, ...data], '_id');
            this.setState({ workouts: newWorkouts, total, page: params.page });
          }
        })
        .catch(() => {});
    }
  };

  onSelectWorkout = workout => {
    this.setState({ selectedWorkout: workout });
  };

  createWorkout = async workout => {
    try {
      const response = await this.props.addWorkout(workout);
      return response.data.data;
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  onSavedWithoutClose = newWorkout => {
    this.props.openWorkoutDetail(newWorkout._id);
    this.props.push(`/home/workout/${newWorkout._id}/detail`);
  };

  render() {
    const { selectedWorkout, workouts, searching, total } = this.state;

    if (selectedWorkout) {
      const workoutInstance = new Workout();
      const parseData = workoutInstance.parseFromWorkout(selectedWorkout);
      const workingWorkout = _.cloneDeep(parseData);
      workingWorkout.title = '';
      delete workingWorkout._id;

      return (
        <WorkoutDetailModal
          key={`create-workout-from-template__${selectedWorkout._id}`}
          workingWorkout={workingWorkout}
          type={WORKOUT_BUILDER_TYPES.TEMPLATE}
          onSave={this.createWorkout}
          onSavedWithoutClose={this.onSavedWithoutClose}
          pdfType="workout"
        />
      );
    }

    return (
      <ChooseWorkoutModal
        onClose={() => this.props.toggleModal(false)}
        onBack={() => this.props.toggleModal(true, <CreateWorkoutTemplate />)}
        workouts={workouts}
        searching={searching}
        onSearch={this.searchDebounce}
        onSelect={this.onSelectWorkout}
        title="Choose Workout Template"
        listLabel="Most Popular"
        total={total}
        loadMore={this.loadMore}
        isLoading={total > workouts.length}
      />
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      systemWorkout: { defaultOptions, total },
      workoutTags,
    },
  } = state;
  return { defaultOptions, workoutTags, total };
};

export default connect(mapState, { toggleModal, addWorkout, openWorkoutDetail, push })(SystemWorkoutModal);
