import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { isWindows } from 'react-device-detect';
import EmojiPicker, { EmojiStyle, Categories, SuggestionMode } from 'emoji-picker-react';
import { Button } from 'shared/FormControl';
import { BACKGROUND_DEFAULT, BACKGROUND_LIST, convertEmojiToUnified, DEFAULT_COLOR } from '../../../constants';
import * as S from './style';

const CATEGORIES = [
  {
    name: 'Recent',
    category: Categories.SUGGESTED,
  },
  {
    name: 'Smileys & People',
    category: Categories.SMILEYS_PEOPLE,
  },
  {
    name: 'Animals & Nature',
    category: Categories.ANIMALS_NATURE,
  },
  {
    name: 'Food & Drink',
    category: Categories.FOOD_DRINK,
  },
  {
    name: 'Travel & Places',
    category: Categories.TRAVEL_PLACES,
  },
  {
    name: 'Activities',
    category: Categories.ACTIVITIES,
  },
  {
    name: 'Objects',
    category: Categories.OBJECTS,
  },
  {
    name: 'Symbols',
    category: Categories.SYMBOLS,
  },
  {
    name: 'Flags',
    category: Categories.FLAGS,
  },
];

const EmojiPickerPopup = ({ prevEmoji, onUpdate, modalStatus, onChangeSelect }) => {
  const [selectedBackground, setSelectedBackground] = useState(
    BACKGROUND_LIST.find(item => item.background === prevEmoji.color) || BACKGROUND_DEFAULT,
  );
  const [selectedEmoji, setSelectedEmoji] = useState(prevEmoji.icon);
  const [selectedUnified, setSelectedUnified] = useState(prevEmoji.icon_unified);
  const [empty, setEmpty] = useState(false);

  const isNotChanged = selectedEmoji === prevEmoji.icon && (selectedBackground || {}).background === prevEmoji.color;

  useEffect(() => {
    setSelectedBackground(BACKGROUND_LIST.find(item => item.background === prevEmoji.color) || BACKGROUND_DEFAULT);
    setSelectedEmoji(prevEmoji.icon);
    const iconUnified = prevEmoji.icon_unified || convertEmojiToUnified(prevEmoji.icon);
    setSelectedUnified(iconUnified);
  }, [modalStatus]);

  useEffect(() => {
    onChangeSelect({
      icon: selectedEmoji,
      icon_unified: selectedUnified,
      color: _.get(selectedBackground, 'background', DEFAULT_COLOR),
    });
  }, [selectedEmoji, selectedBackground]);

  useEffect(() => {
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    setTimeout(() => {
      const targetNode = document.getElementsByClassName('epr-emoji-list')[0];
      if (targetNode) {
        observer.observe(targetNode, config);
      }
    }, 500);

    return () => {
      observer.disconnect();
    };
  }, []);

  const callback = (mutationList, observer) => {
    const targetNode = document.getElementsByClassName('epr-emoji-list')[0];
    if (targetNode && targetNode.clientHeight > 0) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const onClickEmoji = data => {
    setSelectedEmoji(data.emoji);
    setSelectedUnified(data.unified);
  };

  const onClickBackground = item => {
    setSelectedBackground(item);
  };

  const handleUpdateEmoji = () => {
    onUpdate &&
      onUpdate({
        icon: selectedEmoji,
        icon_unified: selectedUnified,
        color: (selectedBackground || {}).background,
      });
  };

  return (
    <S.ChooseIconsPopupWrapper unified={selectedUnified}>
      <div className="icon-content">
        <S.OverviewWrapper>
          <S.BackgroundWrapper>
            <S.TitleWrapper>Background Color</S.TitleWrapper>
            <S.BackgroundList>
              {BACKGROUND_LIST.map(item => (
                <S.BackgroundSelected
                  key={item.background}
                  active={_.get(selectedBackground, 'background', DEFAULT_COLOR) === item.background}
                  onClick={() => onClickBackground(item)}
                >
                  <S.BackgroundItem background={item.background} />
                </S.BackgroundSelected>
              ))}
            </S.BackgroundList>
          </S.BackgroundWrapper>
          <S.IconSection>
            <S.TitleWrapper>Icon</S.TitleWrapper>
          </S.IconSection>
          <S.IconWrapper>
            <EmojiPicker
              searchPlaceHolder="Search emoji"
              onEmojiClick={onClickEmoji}
              autoFocusSearch={false}
              width={379}
              height={248}
              lazyLoadEmojis={true}
              previewConfig={{
                showPreview: false,
              }}
              suggestedEmojisMode={SuggestionMode.RECENT}
              skinTonesDisabled
              emojiStyle={isWindows ? EmojiStyle.GOOGLE : EmojiStyle.NATIVE}
              categories={CATEGORIES}
              className="custom-emoji-picker"
            />
            {empty && <S.EmptyMessage>No results found</S.EmptyMessage>}
          </S.IconWrapper>
        </S.OverviewWrapper>
      </div>
      <div className="modal__actions">
        <Button purple className="actions__update" onClick={handleUpdateEmoji} disabled={isNotChanged}>
          Update
        </Button>
      </div>
    </S.ChooseIconsPopupWrapper>
  );
};

export default EmojiPickerPopup;
