import styled, { css } from 'styled-components';

export const Note = styled.div`
  background: #e7e6fe;
  border-radius: 5px;
  padding: 15px 15px 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #5c5bbd;
  width: 100%;
  max-width: 650px;

  p {
    margin-bottom: 15px;
  }

  a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #6456c4 !important;

    img.ui {
      margin-right: 4px;
    }
  }
`;

export const PageHeader = styled.div`
  width: 55%;
  margin: auto;
`;

export const FeatureSettingHeader = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 40px;
`;

export const SettingContent = styled.div`
  padding: 30px;
  width: 100%;

  .settings__features {
    border-bottom: 1px solid #eeeeee;
  }
`;

export const SyncActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    margin-bottom: 7px;
  }

  h4 {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #888888;
    margin: 0;
  }

  button {
    border-color: #d6dae4;
    color: #818da1;
    padding: 7px 10px;

    :hover {
      color: #5158cf;
      border-color: #5158cf;
      background-color: #fff;
    }
  }
`;

export const SyncDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px;
`;

export const SyncSettingContent = styled.div`
  max-width: 650px;
`;

export const SyncSettingContainer = styled.div`
  padding: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 55% 1fr;
  background: #fafbfc;
  box-shadow: inset 0px -1px 0px #eeeeee;
`;

export const Wrapper = styled.div`
  overflow-y: auto;
  position: relative;
  width: 100%;

  ${props =>
    props.disable &&
    css`
      overflow: hidden;
    `}
`;

export const DisableContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, #ffffff 58.86%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DisableContent = styled.div`
  background: #f3f3f3;
  border-radius: 8px;
  padding: 30px;
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
  text-align: center;
  color: #393939;
  max-width: 447px;
  margin-bottom: 60px;

  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 20px;
  }
`;
