import styled from 'styled-components';
import { NewSearchInput } from 'shared/SearchInput';
import { CDN_URL } from 'constants/commonData';

export const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-top: 30px;

  > * {
    margin-right: 10px !important;
    margin-bottom: 10px;

    :last-child {
      margin-right: 0 !important;
    }
  }
`;

export const StatictisContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .item {
    font-family: Open Sans;
    font-style: normal;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    margin-left: 10px;
    border-bottom: 3px solid #f2f2f2;
    cursor: pointer;
    width: 120px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :hover,
    &.active {
      color: #726ee4;
      border-color: #726ee4;

      .value {
        font-weight: 600;
      }

      .label {
        color: #726ee4;
        font-weight: bold;
      }
    }

    .value {
      font-weight: normal;
      font-size: 30px;
      margin-bottom: 7px;
      line-height: 100%;
    }

    .label {
      font-weight: 600;
      font-size: 10px;
      color: #6a778a;
      line-height: 100%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  margin-bottom: 5px;

  .evf-search-box {
    width: 300px;
  }
`;

export const SearchInput = styled(NewSearchInput)`
  width: 318px;

  input {
    background: url(${CDN_URL}/images/search_grey_purple.svg) no-repeat;
    background-size: auto;
    background-position: 15px center;
    background-color: #f8f8f8;
    border-radius: 5px;
    border-color: transparent;

    ::placeholder {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #777777;
    }
  }
`;
