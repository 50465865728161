import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import DefaultBackground from 'assets/images/form-welcome-screen-default.png';
import { Button } from 'shared/FormControl';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 0;
    width: 100vw;
    min-height: 100vh;
    margin: -1rem !important;
    background: #fafafa;
    > .header {
      border-radius: 0 !important;
      z-index: 9;
      padding: 9px 30px;
      background: #1f2122;
      border-bottom: 1px solid #ededed;
    }
    > :last-child {
      padding: 0;
    }
  }
  > .content {
    padding: 0 !important;
  }
`;

export const HeaderContainer = styled.div`
  max-width: 1380px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    justify-self: end;
    cursor: pointer;
  }
`;

export const HeaderMessage = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #fff;
  margin: 0;
`;

export const HeaderPreviewText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #fff;
  user-select: none;
`;

export const HeaderPreviewLeft = styled.div`
  text-align: left;
`;

export const HeaderPreviewCenter = styled.div`
  text-align: center;
`;

export const HeaderPreviewRight = styled.div`
  text-align: right;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;

  .arrow {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0.5px solid #666;
    outline: #666;
    box-shadow: inset 0px 0px 0px 0.5px rgb(0 0 0 / 30%);
    user-select: none;

    svg {
      path {
        fill: #666;
      }
    }

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    :not(.disabled):hover {
      cursor: pointer;
    }
  }
`;

export const PreviewDetail = styled.div`
  width: 375px;
  height: calc(100vh - 143px);
  margin: 50px 0;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
`;

export const LabelButton = styled(Button)`
  background: ${props => props.buttonBackground} !important;
`;

export const SubmitButton = styled(Button)`
  border-radius: 28px;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  width: 136px;
  height: 48px;
  padding: 0;
  margin-top: 24px;
  cursor: default;
  user-select: none;
  border: none;
  background: ${props => props.buttonBackground} !important;
  ${props =>
    props.isTextArea &&
    css`
      margin-top: 8px;
    `}
  ${props =>
    props.isLinear &&
    css`
      margin-top: 16px;
    `}
  ${props =>
    props.isSignature &&
    css`
      margin-top: 20px;
    `}
`;

export const WelcomeScreenContainer = styled.div`
  width: 375px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${props => props.background || DefaultBackground});
  background-size: cover;
  background-position: center;
  .welcome-screen-content {
    width: 335px;
    max-height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .welcome-screen-title {
      width: 100%;
      cursor: text;
      .text-editable__container {
        padding: 0px;
        background-color: unset;
        .text-editable__input {
          font-weight: 700;
          font-size: 28px;
          text-align: center;
          letter-spacing: -0.5px;
          color: #f2f2f2;
        }
      }
    }
    .welcome-screen-description {
      width: 100%;
      overflow-y: scroll;
      margin-top: 8px;
      cursor: text;
      padding-left: 6px;
      .text-editable__container {
        padding: 0px;
        background-color: unset;
        .text-editable__input {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #f2f2f2;
        }
      }
    }
  }
  .welcome-screen-btn {
    padding: 18px 52.5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: #7470ef;
    border-radius: 100px;
    margin-top: 26px;
    color: #fff;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 26px;
`;

export const QuestionCounter = styled.p`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1e0a3c;
  user-select: none;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.color || '#605bff'};
  margin-top: 16px;

  span {
    cursor: pointer;
    user-select: none;

    :first-child {
      svg {
        margin-right: 7px;
        margin-bottom: 2.5px;

        path {
          fill: ${props => props.color || '#605bff'};
        }
      }
    }

    :last-child {
      svg {
        margin-left: 7px;
        margin-bottom: 2.5px;

        path {
          fill: ${props => props.color || '#605bff'};
        }
      }
    }
  }
`;

export const QuestionTotal = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(${props => props.total || 1}, auto);

  div {
    background: #ececf1;
    height: 8px;
    margin-right: 2px;
    cursor: default;
    user-select: none;

    :last-child {
      margin: 0;
    }

    &.active {
      background: ${props => props.background};
    }
  }
`;

export const ItemWrapperContent = styled.div`
  max-height: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 271px);
  overflow: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 5px !important;
  }

  .title {
    margin-top: 6px;
    transform: translateX(-4px);
    user-select: none;

    .text-editable__container {
      padding: 0;
      padding-left: 4px;
      border: none;
    }

    .text-editable__input {
      font-weight: 600;
      font-size: 20px;
      color: #202353;
      line-height: 32px;
      letter-spacing: -0.02em;

      ${props =>
        props.isRequire &&
        css`
          position: relative;

          :after {
            content: '*';
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #ee2525;
            margin-left: 4px;
            position: absolute;
          }
        `}
    }
  }

  .description {
    transform: translateX(-4px);
    margin-bottom: 8px;

    .text-editable__container {
      padding: 0;
      padding-left: 4px;
      border: none;
      border-bottom: 1px solid transparent;
      margin-top: 2px;
      transition: background-color 0.1s linear;

      .text-editable__input {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: rgba(32, 35, 83, 0.6);
      }
    }
  }

  .short-answer {
    background: #f7f8fc;
    border-radius: 8px;
    border: none;
    padding: 15px 24px 29px 15px;
    resize: none;
    outline: none !important;
    margin-top: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    width: 100%;

    ::placeholder {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: rgba(32, 35, 83, 0.3);
    }

    &.signature {
      padding: 15.5px 24px 20px 15px;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 150%;
    }
  }
  ${props =>
    props.isImageText &&
    css`
      margin-top: 40px;
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  user-select: none;

  svg {
    margin-right: 4px;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3683f7;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: unset !important;
  user-select: none;
  .text-editable__wrapper.option-title {
    min-width: 110px;
    max-width: 508px;
    width: auto;
    margin: 0px;
    word-break: break-word;
    .text-editable__container {
      padding: 2px 4px;
      transition: background-color 0.1s linear;
      .text-editable__input {
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
        color: #202353;
      }
    }
  }
`;

export const SingleChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
  }

  .single-option-container {
    margin-top: 11px;

    .option-radio {
      width: 100%;
      cursor: default;
      margin-top: 11px;
      display: flex;
      align-items: flex-start;

      > div:first-child {
        cursor: default;
        margin-top: 5px;
        :hover {
          border: 1px solid #dadfea;
        }
      }
    }
  }

  .option-radio {
    > div:first-child {
      flex: 0 0 20px;
      height: 20px;
      width: 20px;
    }
    > span {
      transform: translate(4px, -1px);
    }
  }
`;

export const MultipleChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    width: 100%;
  }

  .multiple-option-container {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;

    .checkbox-icon {
      top: -8px;
      left: -6px;
      border-radius: 4px;
      cursor: default;
    }

    .multiple-choice-option-name {
      margin-left: 5px;
    }
  }

  .option-radio {
    margin-top: 13px;

    > div:first-child {
      flex: 0 0 20px;
      height: 20px;
      width: 20px;
    }
    > span {
      transform: translate(4px, -1px);
    }
  }
`;

export const LinearScaleLabelWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: 8px;

  svg {
    transform: rotate(180deg);
    path {
      stroke: rgba(32, 35, 83, 0.6);
    }
  }
`;

export const LinearScaleLabel = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(32, 35, 83, 0.6);
`;

export const Bull = styled.span`
  font-size: 16px;
  color: rgba(32, 35, 83, 0.6);
`;

export const LinearScaleContainer = styled.div`
  margin: 8px 0;
  display: grid;
  grid-template-columns: ${props => `repeat(${props.range || 5}, ${335 / props.firstLine}px)`};
  justify-content: center;

  .option {
    background: #fff;
    border: 1px solid #dadfea;
    border-radius: 8px;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: #000;
    height: 48px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    user-select: none;

    :last-child {
      margin: 0;
    }
  }
`;

export const ImageTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .image-with-fallback,
  img {
    width: 335px;
    object-fit: contain;
    aspect-ratio: auto;
  }

  ${props =>
    props.isPreview &&
    css`
      margin-top: 8px;
    `}
`;

export const ReadOnlyWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: baseline;
  margin-top: 16px;
  .hyphen {
    color: rgba(32, 35, 83, 0.5);
  }
  .dynamic-unit {
    width: 94px;
    height: 22px;
    text-align: end;
  }
`;

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: rgba(32, 35, 83, 0.5);
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(32, 35, 83, 0.5);
`;

export const MetricAnswerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 16px;
`;
