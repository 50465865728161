import styled from 'styled-components';

export const CommentsContainer = styled.div`
  background-color: #fff;
  height: 100%;
  width: ${props => (props.onlyCaption ? '608px' : '426px')};
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: ${props => (props.onlyCaption ? '815px' : '711px')};
  max-height: calc(100vh - 100px);
`;
