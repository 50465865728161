import styled, { css } from 'styled-components';

const textBase = `
    font-family: 'Open Sans';
    font-weight: 400;
    line-height: 150%;
    color: #202353;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  padding: 32px 31px 32px;
  margin-bottom: 15px;
  border-radius: 12px;
  border: 1px solid #dadfea;

  ${props =>
    props.isEmpty &&
    css`
      align-items: center;
    `}

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      .left-side,
      .right-side {
        user-select: none;
        pointer-events: none;
      }
    `}
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 11px;
  width: 400px;
`;

export const Header = styled.div`
  ${textBase}
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
`;

export const Description = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(32, 35, 83, 0.7);
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
