import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

import { Panel } from '../style';

export const Credit = styled.div`
  padding: 7px 19px;
  background: #f9f9fb;
  border: 1px solid #dadfea;
  border-radius: 3px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #202353;
`;

export const Card = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;

  .card-name {
    text-transform: uppercase;
  }

  .expires {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.4;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-left: 80px;
  min-height: 46px;

  :before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: transparent url(${props => props.logo || `${CDN_URL}/images/card_placeholder.svg`}) no-repeat center;
    background-size: 100%;
    width: 64px;
    height: 46px;
  }

  svg {
    margin-right: 16px;
  }
`;

export const BillingPeriod = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  .title {
    font-size: 13px;
    color: #202353;
    opacity: 0.6;
    margin-bottom: 5px;
  }

  ${props =>
    props.undefined &&
    css`
      .billingPeriod__name {
        position: relative;

        :before {
          content: '';
          position: absolute;
          left: 0;
          top: 7px;
          background: #202353;
          width: 14px;
          height: 2px;
        }
      }
    `}
`;

export const BillingDetailsWrapper = styled(Panel)``;
