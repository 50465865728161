import React from 'react';
import classNames from 'classnames';
import { ConfirmModal } from './style';
import { ASSET_TYPE } from 'components/Product/constants';
import values from 'lodash/values';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import TrashIcon from 'assets/icons/red_trash_circle.svg';

export default function DeleteAssetConfirmModal({
  assetType,
  onConfirm,
  assetTitle,
  title,
  content,
  isOnboardingFlow = false,
  isExtraLarge = false,
  isCloseAfterConfirm = false,
}) {
  const assetTypeTitle = assetTitle || assetType.replaceAll('_', ' ');
  return (
    <ConfirmModal
      className={classNames({ 'large-width': isOnboardingFlow, 'extra-large-width': isExtraLarge })}
      headerIcon={TrashIcon}
      noBorder
      title={title || `${capitalize(assetTypeTitle)} is assigned to a Sequence `}
      content={
        content ||
        `This ${assetTypeTitle.toLowerCase()} is in at least 1 sequence. If the ${assetTypeTitle.toLowerCase()} is removed, it will be removed from all the sequences as well.`
      }
      onConfirm={onConfirm}
      confirmButtonTitle="I understand"
      cancelButtonTitle="Back"
      hasCloseIcon
      hasHoverState
      shouldCloseAfterConfirm={isCloseAfterConfirm}
    />
  );
}

DeleteAssetConfirmModal.propTypes = {
  assetType: PropTypes.oneOf(values(ASSET_TYPE)).isRequired,
  onConfirm: PropTypes.func.isRequired,
};
