import React from 'react';
import { get } from 'lodash';

import { ReactComponent as ProgramIcon } from 'assets/icons/program_asset.svg';
import { pluralize } from 'utils/commonFunction';
import UpgradePath from 'shared/UpgradePath';

import AssetCard from './AssetCard';

import { handleCheckWeek } from '../../../helper';

export default function ProgramAsset({ data, onDeleteAsset, isEdit }) {
  const no_of_weeks = get(data, 'asset_data.workout_sets', []).length;

  return (
    <UpgradePath
      pathName="program"
      fallback={
        <AssetCard
          icon={<ProgramIcon />}
          title={data.asset_data.title}
          asset_type={data.asset_type}
          onDeleteAsset={onDeleteAsset}
          isEdit={isEdit}
          disabled={true}
          disabledText="inactive"
        >
          <span className="badge">Program</span>
          <span className="info-text">{handleCheckWeek(no_of_weeks)}</span>
        </AssetCard>
      }
    >
      <AssetCard
        icon={<ProgramIcon />}
        title={data.asset_data.title}
        asset_type={data.asset_type}
        onDeleteAsset={onDeleteAsset}
        isEdit={isEdit}
        disabled={data.is_deleted}
        disabledText="deleted"
      >
        <span className="badge">Program</span>
        <span className="info-text">{handleCheckWeek(no_of_weeks)}</span>
      </AssetCard>
    </UpgradePath>
  );
}
