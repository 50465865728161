import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { CDN_URL } from 'constants/commonData';
import { ModalWrapper } from './style';

export default function ConfirmUpdatePricingModal({ onClose, onConfirm }) {
  return (
    <ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      className="modal--set-step-goal"
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalLayout.Wrapper>
          <ModalLayout.Header className="modal__header">Updating the Price</ModalLayout.Header>
          <ModalLayout.Content className="modal__content">
            <p>The updated price will show up when you publish the package. </p>
            <p>
              Note: The price change will not affect the recurring payments for packages that clients have already
              purchased.
            </p>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button purple onClick={onConfirm}>
              Okay
            </Button>
          </ModalLayout.Actions>
        </ModalLayout.Wrapper>
      </Modal.Content>
    </ModalWrapper>
  );
}
