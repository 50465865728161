import React from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { Map } from 'immutable';
import { Modal, Button, Image, Dropdown, Icon } from 'semantic-ui-react';
import { classToPlain } from 'class-transformer';
import diff from 'deep-diff';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER, CALENDAR_LIMIT_WEEKS, CDN_URL } from 'constants/commonData';
import { checkAssetAssignedToProduct } from 'utils/commonFunction';

import ConfirmModal from 'shared/ConfirmModal';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { ErrorMessage } from 'shared/FormControl';
import { ASSET_TYPE } from 'components/Product/constants';
import { DEFAULT_MODALITY_VALUES, DEFAULT_EXPERIENCE_LEVEL } from 'components/ProgramLibraryAndTemplates/constants';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';

import * as S from './styles';
import { MODE, formatCreateProgramParams, formatUpdateProgramParams, formatCloneProgramParams } from './constants';
import CustomSelect from './components/CustomSelect';
import UploadCoverImage from './components/UploadCoverImage';
import { TypingModal, TYING_TYPES } from 'shared/TriggerForms';

const { CREATE_TEMPLATE, UPDATE_TEMPLATE, CLONE_TEMPLATE } = MODE;

class ProgramDetailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      program: this.getDefaultItem(),
      originProgram: this.getOriginItem(),
      errors: Map({}),
      modalityValue: null,
      experienceLevelValue: null,
      thumbnail: '',
      uploadingThumbnail: false,
    };

    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.weekRef = React.createRef();

    this.debounceChangeName = debounce(this.handleChangeProgramName, 0);
  }

  componentDidMount() {
    const { submitStatus, resetSubmitStatus } = this.props;

    if (submitStatus.status !== -1) {
      resetSubmitStatus();
    }
  }

  isEditAble = () => {
    const { mode, user } = this.props;
    const { program } = this.state;

    return mode === UPDATE_TEMPLATE && (program.share === TEAM_SHARE_NOOWNER || program.author._id === user._id);
  };

  getDefaultItem = () => {
    const { user, workingItem } = this.props;

    if (workingItem && workingItem.workout_sets) {
      workingItem.week_count = workingItem.workout_sets.length;
    }
    if (workingItem && !workingItem.share) {
      workingItem.share = TEAM_SHARE_PRIVATE;
    }

    return (
      workingItem || {
        author: {
          _id: user._id,
        },
        share: TEAM_SHARE_PRIVATE,
      }
    );
  };

  getOriginItem = () => {
    const { user, originItem } = this.props;

    if (originItem && originItem.workout_sets) {
      originItem.week_count = originItem.workout_sets.length;
    }
    if (originItem && !originItem.share) {
      originItem.share = TEAM_SHARE_PRIVATE;
    }

    return (
      originItem || {
        author: {
          _id: user._id,
        },
        share: TEAM_SHARE_PRIVATE,
      }
    );
  };

  checkAssigned = async ({ isCheck = false, id, isOnboardingFlow = false, isOnboardingFlowTrigger = false }) => {
    if (!isCheck) return false;
    try {
      return await checkAssetAssignedToProduct(ASSET_TYPE.PROGRAM, id, isOnboardingFlow, isOnboardingFlowTrigger);
    } catch (error) {
      return false;
    }
  };

  closeConfirmModal = () => {
    const { toggleConfirmModal } = this.props;
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  /**
   * Priority is as below:
   * 1. Warning pop up of package
   * 2. Warning pop up of trigger
   * Enable if User writes word “Confirm” (ignore sensitive case).
   * On-click this option should remove the program (no need any other pop up)
   * 3. Warning pop up of assignment for Onboarding Flow.
   */
  handleDeleteProgram = async () => {
    const { workingItem, mode, payment, onboarding_flow, toggleConfirmModal } = this.props;

    if (mode !== CREATE_TEMPLATE) {
      // normal assigned program
      const isAssignedToProduct = await this.checkAssigned({
        isCheck: payment,
        id: workingItem._id,
        isOnboardingFlow: false,
      });
      if (isAssignedToProduct) {
        toggleConfirmModal(
          true,
          <DeleteAssetConfirmModal assetType={ASSET_TYPE.PROGRAM} onConfirm={this.handleDiscardChanges(workingItem)} />,
        );
      }

      // onboarding flow assigned program
      const {
        assigned: isAssignedToOnboardingFlow,
        assigned_trigger_form: isTriggerApplied,
      } = await this.checkAssigned({
        isCheck: onboarding_flow,
        id: workingItem._id,
        isOnboardingFlow: true,
        isOnboardingFlowTrigger: true,
      });

      if (isTriggerApplied && typeof toggleConfirmModal === 'function') {
        return toggleConfirmModal(
          true,
          <TypingModal
            isCloseAfterSubmit
            type={TYING_TYPES.REMOVE_PROGRAM}
            closeModal={this.closeConfirmModal}
            submitAction={this.handleRemoveProgram(workingItem._id)}
          />,
        );
      }

      if (isAssignedToOnboardingFlow) {
        return toggleConfirmModal(
          true,
          <DeleteAssetConfirmModal
            isOnboardingFlow
            title="Program is assigned to an Onboarding Flow"
            content="This Program is in at least 1 onboarding flow. If the Program is removed, it will be removed from all onboarding flows as well."
            assetType={ASSET_TYPE.PROGRAM}
            onConfirm={this.handleDiscardChanges(workingItem)}
          />,
        );
      }

      // edit mode
      this.handleDiscardChanges(workingItem)();
    }
  };

  handleDiscardChanges = workingItem => () => {
    const { toggleConfirmModal } = this.props;

    return toggleConfirmModal(
      true,
      <ConfirmModal
        title={'Remove Program from Library'}
        content={'Are you sure that you want to delete this program?'}
        onConfirm={this.handleRemoveProgram(workingItem._id)}
      />,
    );
  };

  handleRemoveProgram = programId => () => {
    const { removeProgramLibrary, push, supportTemplate, removeProgram } = this.props;

    // Logic for UI program library pagination and templates
    if (supportTemplate) {
      removeProgram(programId);
    } else {
      // Old logic using in program detail calendar
      removeProgramLibrary(programId).then(() => {
        push('/home/program');
      });
    }
  };

  checkAssetAssignedToOnboardingFlow = async () => {
    const { onboarding_flow, toggleConfirmModal } = this.props;
    const { program, originProgram } = this.state;
    if (get(originProgram, 'share') === TEAM_SHARE_PRIVATE || get(program, 'share') !== TEAM_SHARE_PRIVATE)
      return false;

    const isAssigned = onboarding_flow
      ? await checkAssetAssignedToProduct(ASSET_TYPE.PROGRAM, get(originProgram, '_id'), true)
      : false;
    if (isAssigned) {
      toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          isOnboardingFlow
          title="Program is assigned to an Onboarding Flow"
          content="This Program is in at least 1 onboarding flow. If you change its permission to “Private”, it will be inactivated in all onboarding flows as well."
          assetType={ASSET_TYPE.PROGRAM}
          onConfirm={this.handleConfirmDeleteAssigned}
        />,
      );
    }
    return isAssigned;
  };

  handleConfirmDeleteAssigned = () => {
    const { toggleConfirmModal } = this.props;

    toggleConfirmModal(false);
    this.handleSubmitAction()();
  };

  handleCloseAction = () => {
    const { toggleConfirmModal, mode, toggleModal } = this.props;
    const { originProgram, program, modalityValue, experienceLevelValue, thumbnail, uploadingThumbnail } = this.state;

    if (this.titleRef != null && this.titleRef.current != null) {
      program.title = this.titleRef.current.value;
    }

    if (this.weekRef != null && this.weekRef.current != null) {
      program.week_count = parseInt(this.weekRef.current.value);
    }

    if (!originProgram.title) {
      originProgram.title = '';
    }

    if (!program.title) {
      program.title = '';
    }

    if (!originProgram.description) {
      originProgram.description = '';
    }

    if (!program.description) {
      program.description = '';
    }

    // modality
    if (modalityValue) {
      program.modality = modalityValue.key;
    }
    if (!program.modality) {
      program.modality = null;
    }

    if (!originProgram.modality) {
      originProgram.modality = null;
    }

    // level
    if (experienceLevelValue) {
      program.level = experienceLevelValue.key;
    }

    if (!program.level) {
      program.level = null;
    }

    if (!originProgram.level) {
      originProgram.level = null;
    }

    // cover image
    if (thumbnail) {
      program.background = thumbnail;
      program.background_thumbnail = thumbnail;
    }

    if (!program.background) {
      program.background = '';
      program.background_thumbnail = '';
    }

    if (!originProgram.background) {
      originProgram.background = '';
      originProgram.background_thumbnail = '';
    }

    let origin = classToPlain(originProgram);
    let current = classToPlain(program);

    if (mode === CREATE_TEMPLATE) {
      origin.week_count = 1;
    }

    const changes = diff(origin, current);

    if (changes || uploadingThumbnail) {
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={() => this.onCloseCurrentPopup()}
          onDeny={() => this.onDenyClosingPopup()}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    } else {
      toggleModal(false);
    }
  };

  onCloseCurrentPopup = () => {
    this.props.toggleModal(false);
  };

  onDenyClosingPopup = () => {
    this.props.toggleModal(true);
  };

  handleSubmitAction = isSaveAsCopy => () => {
    const { modalityValue, experienceLevelValue, thumbnail, uploadingThumbnail } = this.state;

    const {
      submitStatus,
      user,
      mode,
      createProgramLibrary,
      cloneProgramLibrary,
      updateProgramLibrary,
      supportTemplate = false,
      toggleModal,
      addProgram,
      updateProgram,
      cloneProgram,
    } = this.props;
    const program = cloneDeep(this.state.program || {});

    if (!submitStatus.status || uploadingThumbnail) {
      return false;
    }

    if (this.titleRef.current) {
      program.title = this.titleRef.current.value.trim();
    }

    if (this.weekRef.current) {
      program.week_count = this.weekRef.current.value;
    }

    if (modalityValue) {
      program.modality = modalityValue.key;
    }

    if (experienceLevelValue) {
      program.level = experienceLevelValue.key;
    }

    if (thumbnail) {
      program.background = thumbnail;
    }

    if (!this.checkValidation(program)) {
      return;
    }

    // Logic for UI program library pagination and templates
    if (supportTemplate) {
      if (mode === CREATE_TEMPLATE) {
        toggleModal(false);
        addProgram(formatCreateProgramParams(program));
      }

      if (mode === UPDATE_TEMPLATE) {
        if (isSaveAsCopy) {
          const newProgram = Object.assign({}, program);
          newProgram.author = { _id: user._id };
          newProgram.share = TEAM_SHARE_PRIVATE;
          toggleModal(false);
          cloneProgram(formatCloneProgramParams(newProgram));
        } else {
          toggleModal(false);
          updateProgram(formatUpdateProgramParams(program));
        }
      }

      if (mode === CLONE_TEMPLATE) {
        toggleModal(false);
        cloneProgram(formatCloneProgramParams(program));
      }
    } else {
      // Old logic using in program detail calendar
      if (mode === CREATE_TEMPLATE) {
        createProgramLibrary(formatCreateProgramParams(program));
      }

      if (mode === UPDATE_TEMPLATE) {
        if (isSaveAsCopy) {
          const newProgram = Object.assign({}, program);
          newProgram.author = { _id: user._id };
          newProgram.share = TEAM_SHARE_PRIVATE;
          cloneProgramLibrary(formatCloneProgramParams(newProgram));
        } else {
          updateProgramLibrary(formatUpdateProgramParams(program));
        }
      }

      if (mode === CLONE_TEMPLATE) {
        cloneProgramLibrary(formatCloneProgramParams(program));
      }
    }
  };

  checkValidation(program) {
    let errors = Map({});
    if (!program.title.trim()) {
      errors = errors.set('title', 'Title cannot be empty');
    }
    const weekCount = parseInt(program.week_count);
    if (!(weekCount >= 1 && weekCount <= CALENDAR_LIMIT_WEEKS)) {
      errors = errors.set('week', `Enter a number from 1 to ${CALENDAR_LIMIT_WEEKS}`);
    }
    if (!program.modality) {
      errors = errors.set('modality', `Please select modality`);
    }
    if (!program.level) {
      errors = errors.set('level', `Please select experience level`);
    }
    this.setState({ errors });

    if (errors.size > 0) {
      return false;
    }
    return true;
  }

  handleUseTemplate = () => {
    const { toggleModal, toggleFullscreenModal } = this.props;

    toggleModal(false);
    toggleFullscreenModal();
  };

  handleChangeModality = options => {
    this.setState({ modalityValue: options });

    const { errors } = this.state;

    if (errors.has('modality')) {
      this.setState(prevState => ({ errors: prevState.errors.delete('modality') }));
    }
  };

  handleChangeLevels = options => {
    this.setState({ experienceLevelValue: options });

    const { errors } = this.state;

    if (errors.has('level')) {
      this.setState(prevState => ({ errors: prevState.errors.delete('level') }));
    }
  };

  handleRemoveCoverImage = () => {
    this.setState(prevState => ({
      thumbnail: '',
      program: {
        ...prevState.program,
        background: '',
        background_thumbnail: '',
      },
    }));
  };

  handleUploadCoverImageSuccess = res => {
    if (res.url) {
      this.setState({ thumbnail: res.url });
    }
    this.setState({ uploadingThumbnail: false });
  };

  handleStartUploadCoverImage = () => {
    this.setState({ uploadingThumbnail: true });
  };

  handleCancelUploadCoverImage = () => {
    this.setState({ uploadingThumbnail: false });
  };

  handleChangeProgramName = () => {
    const { errors } = this.state;

    if (errors.has('title') && this.titleRef.current) {
      const title = this.titleRef.current.value.trim();
      if (!isEmpty(title)) {
        this.setState(prevState => ({ errors: prevState.errors.delete('title') }));
      }
    }
  };

  handleChangeOwnershipStatus = ({ author, share }) => {
    this.setState(prevState => ({
      program: {
        ...prevState.program,
        author: { _id: author },
        share,
      },
    }));
  };

  getTitle = mode => {
    switch (mode) {
      case CREATE_TEMPLATE:
        return 'Create new program';
      case CLONE_TEMPLATE:
        return 'Duplicate Program';
      default:
      case UPDATE_TEMPLATE:
        return 'Edit Program';
    }
  };

  renderRequiredField = field => {
    const { errors } = this.state;

    if (errors.size > 0) {
      let exist = errors.get(field);
      if (exist) {
        return <span style={{ color: 'red' }}> (*)</span>;
      }
    }
    return;
  };

  renderErrors = () => {
    const { errors } = this.state;

    if (errors.size > 0) {
      const views = [];
      errors.forEach((v, k) => {
        views.push(<li key={k}>{v}</li>);
      });

      return <ul className="error">{views}</ul>;
    }

    return;
  };

  render() {
    const { isModalOpen, mode, submitStatus, user } = this.props;
    const { uploadingThumbnail, program, errors } = this.state;

    let text = submitStatus.status ? 'Create Program' : 'Creating';

    if (mode === UPDATE_TEMPLATE || mode === CLONE_TEMPLATE) {
      text = submitStatus.status ? 'Save' : 'Saving';
    }

    let disabled = '';
    if (mode !== CREATE_TEMPLATE) {
      disabled = 'disabled';
    }

    const title = this.getTitle(mode);

    return (
      <S.CustomModal
        closeOnDimmerClick={false}
        open={isModalOpen}
        className="new-program-template-popup"
        onClose={this.handleCloseAction}
      >
        <Modal.Header className="program-header">
          <Button onClick={this.handleCloseAction} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="program-title-text">{title}</div>
          <div className="program-more-actions-right">
            <OwnershipPopupStudio
              size={28}
              user={user}
              owner={program.author}
              shareStatus={program.share}
              saveChangeOnClose={this.handleChangeOwnershipStatus}
              updateImmediately={true}
            />
            {mode === UPDATE_TEMPLATE && (
              <Dropdown
                className="program-options"
                icon={<Icon name="ellipsis horizontal more-actions-icon" color="grey" size="small" />}
              >
                <Dropdown.Menu className="dropdown-menu">
                  <Dropdown.Item
                    onClick={this.handleSubmitAction(true)}
                    className="dropdown-item"
                    disabled={!submitStatus.status || uploadingThumbnail}
                  >
                    <Image src={`${CDN_URL}/images/save.svg`} />
                    <div>Save as Copy</div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.handleDeleteProgram} className="dropdown-item">
                    <Image src={`${CDN_URL}/images/delete.svg`} />
                    <div>Delete</div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className="field-row">
            <div className="field-title">PROGRAM NAME</div>
            <div className="ui fluid input">
              <S.ProgramNameInput
                placeholder="Name your program"
                defaultValue={program.title || ''}
                ref={this.titleRef}
                autoFocus
                className={classNames('txt-program-name', { 'error-input': !!errors.get('title') })}
                onChange={this.debounceChangeName}
              />
            </div>
            {errors.get('title') ? <ErrorMessage>Please add program name</ErrorMessage> : null}
          </div>
          <div className="field-row">
            <div className="field-title">DESCRIPTION</div>
            <div className="ui fluid input">
              <S.ProgramDescriptionInput
                className="program-description-input"
                placeholder={'Add program description'}
                value={program.description}
                onChange={(evt, newData) => {
                  program.description = newData.value;
                  this.setState({ program });
                }}
              />
            </div>
          </div>
          <div className="field-row">
            <div className="field-title">WEEK</div>
            <div className="ui fluid input week">
              <input
                placeholder={''}
                type="number"
                min="1"
                defaultValue={program.week_count || 1}
                ref={this.weekRef}
                className={errors.get('week') ? 'error-input' : ''}
                disabled={disabled}
              />
            </div>
            {errors.get('week') ? (
              <ErrorMessage>{`Enter a number from 1 to ${CALENDAR_LIMIT_WEEKS}`}</ErrorMessage>
            ) : null}
          </div>
          <div className="field-row">
            <div className="field-title field-select">Modality</div>
            <CustomSelect
              onChange={this.handleChangeModality}
              name="modality"
              defaultValue={program.modality}
              options={DEFAULT_MODALITY_VALUES}
              placeholder="Select Modality"
              disabled={false}
              classNamePrefix="modality"
              className={classNames('select-modality', { 'error-select': !!errors.get('modality') })}
              isModality={true}
            />
            {errors.get('modality') ? <ErrorMessage>Please select modality</ErrorMessage> : null}
          </div>
          <div className="field-row">
            <div className="field-title field-select">Experience Level</div>
            <CustomSelect
              onChange={this.handleChangeLevels}
              name="level"
              defaultValue={program.level}
              options={DEFAULT_EXPERIENCE_LEVEL}
              placeholder="Select Experience Level"
              disabled={false}
              classNamePrefix="experience-levels"
              className={classNames('select-experience-levels', { 'error-select': !!errors.get('level') })}
              isModality={false}
            />
            {errors.get('level') ? <ErrorMessage>Please select experience level</ErrorMessage> : null}
          </div>
          <div className="field-row">
            <div className="field-title">Cover Image (optional)</div>
            <UploadCoverImage
              key="cover_image"
              className="programAttachment__uploadCover"
              onRemove={this.handleRemoveCoverImage}
              onUploadSuccess={this.handleUploadCoverImageSuccess}
              onStartUpload={this.handleStartUploadCoverImage}
              onEndUpload={this.handleCancelUploadCoverImage}
              src={program.background_thumbnail}
              uploadKey="media"
              hasRemoveIcon
            />
          </div>
        </Modal.Content>
        <Modal.Actions className="program-actions-container">
          <div>
            {mode === CREATE_TEMPLATE && (
              <button className="use-template-button" onClick={this.handleUseTemplate}>
                Use template
              </button>
            )}
          </div>
          <div>
            <S.SubmitButtonWrapper>
              <button
                disabled={!submitStatus.status || uploadingThumbnail}
                onClick={this.handleSubmitAction(false)}
                className="submit-button"
              >
                <span>{text}</span>
              </button>
            </S.SubmitButtonWrapper>
          </div>
        </Modal.Actions>
      </S.CustomModal>
    );
  }
}

export default ProgramDetailModal;
