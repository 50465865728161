import styled, { css } from 'styled-components';

export const ErrorMessage = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #ee2525;
  margin-top: ${props => (props.marginTop ? props.marginTop : '5px')};
`;

export default styled.div`
  margin-bottom: 15px;
  width: 100%;
  font-weight: unset;
  font-family: Open Sans;

  ${ErrorMessage} {
    display: none;
  }

  .title__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .count {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
    display: block;
    opacity: 0.3;
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
    display: block;
  }

  input,
  textarea {
    background: #ffffff;
    border: 1px solid #dcdcde;
    box-sizing: border-box;
    border-radius: 4px;
    height: 36px;
    color: #323c47;
    font-size: 13px;
    line-height: 18px;
    padding: 5px 15px;
    outline: none !important;
    width: 100%;
    font-weight: 600;
    font-family: Open Sans;

    ::placeholder {
      color: #969fb5;
      font-weight: normal;
    }

    :disabled {
      background: #fafbfc;
    }

    :hover:not(:disabled),
    :focus {
      border-color: #5c5bbd;
    }
  }

  &.error {
    input {
      border-color: #ee2525;

      ::placeholder {
        font-style: normal;
        font-weight: normal;
      }
    }

    ${ErrorMessage} {
      display: block;
    }
  }

  ${props =>
    props.largeMargin &&
    css`
      margin-bottom: 20px;
    `}

  .promoCode__input {
    padding: 5px 65px 5px 15px;
  }
`;
