import { map, get, uniqBy } from 'lodash';
import { Types } from './actions';

const PAGE_SIZE = 20;

const INITIAL_STATE = {
  list: [],
  mostRecentList: [],
  mostUsedList: [],
  loading: false,
  query: {
    page: 1,
    perPage: PAGE_SIZE,
    sort: 1,
    textSearch: '',
    status: '',
    total: 0,
    isEnd: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.TAGS_EXERCISE_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, {
        query: {
          ...state.query,
          ...payload,
        },
      });
    }

    case Types.TAGS_EXERCISE_RESET_QUERY_PARAMS: {
      return Object.assign({}, state, { query: { ...INITIAL_STATE.query } });
    }

    case Types.TAGS_EXERCISE_GET_LIST_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.TAGS_EXERCISE_GET_LIST_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.TAGS_EXERCISE_GET_LIST_SUCCESS: {
      const { data, page, total } = payload.data;
      const newList = page === 1 ? data : [...state.list, ...data];

      return Object.assign({}, state, {
        loading: false,
        list: map(newList, tag => ({ ...tag, label: tag.name, value: tag._id })),
        query: {
          ...state.query,
          page,
          total,
          isEnd: newList.length >= total,
        },
      });
    }

    case Types.TAGS_EXERCISE_GET_MOST_RECENT_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.TAGS_EXERCISE_GET_MOST_RECENT_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.TAGS_EXERCISE_GET_MOST_RECENT_SUCCESS: {
      const { data, page, total } = payload.data;
      const newList = page === 1 ? data : [...state.mostRecentList, ...data];

      return Object.assign({}, state, {
        loading: false,
        mostRecentList: uniqBy(
          map(newList, tag => ({ ...tag, label: tag.name, value: tag._id })),
          '_id',
        ),
        query: {
          ...state.query,
          page,
          total,
          isEnd: newList.length >= total,
        },
      });
    }

    case Types.TAGS_EXERCISE_GET_MOST_USED_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.TAGS_EXERCISE_GET_MOST_RECENT_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.TAGS_EXERCISE_GET_MOST_USED_SUCCESS: {
      const { data, page, total } = payload.data;
      const newList = page === 1 ? data : [...state.mostUsedList, ...data];

      return Object.assign({}, state, {
        loading: false,
        mostUsedList: map(newList, tag => ({ ...tag, label: tag.name, value: tag._id })),
        query: {
          ...state.query,
          page,
          total,
          isEnd: newList.length >= total,
        },
      });
    }

    case Types.TAGS_EXERCISE_CREATE_NEW_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.TAGS_EXERCISE_CREATE_NEW_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.TAGS_EXERCISE_CREATE_NEW_SUCCESS: {
      const newTag = { ...payload, label: get(payload, 'name', ''), value: get(payload, '_id', '') };

      return Object.assign({}, state, {
        loading: false,
        list: [newTag, ...state.list],
        mostUsedList: [newTag, ...state.mostUsedList],
        mostRecentList: [newTag, ...state.mostRecentList],
      });
    }

    case Types.TAGS_EXERCISE_EDIT_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.TAGS_EXERCISE_EDIT_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.TAGS_EXERCISE_EDIT_SUCCESS: {
      const updatedTag = { ...payload, label: get(payload, 'name', ''), value: get(payload, '_id', '') };
      const newList = state.list.map(tag => (tag._id === updatedTag._id ? updatedTag : tag));
      const newMostUsedList = state.mostUsedList.map(tag => (tag._id === updatedTag._id ? updatedTag : tag));

      return Object.assign({}, state, {
        loading: false,
        list: newList,
        mostUsedList: newMostUsedList,
      });
    }

    case Types.TAGS_EXERCISE_DELETE_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.TAGS_EXERCISE_DELETE_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.TAGS_EXERCISE_DELETE_SUCCESS: {
      const { id } = action;
      const newList = state.list.filter(tag => tag._id !== id);
      const newMostUsedList = state.mostUsedList.filter(tag => tag._id !== id);

      return Object.assign({}, state, {
        loading: false,
        list: newList,
        mostUsedList: newMostUsedList,
      });
    }

    default:
      return state;
  }
};
