import { SUCCESS_CLOUDFRONT_LIST } from 'actions/cloudfrontList';

export const cloudfrontList = (state = [], action) => {
  switch (action.type) {
    case SUCCESS_CLOUDFRONT_LIST:
      return action.cloudfrontList;

    default:
      return state;
  }
};
