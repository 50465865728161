import styled from 'styled-components';

export default styled.div`
  display: flex;
  border-radius: 7px;
  border: 1px solid #5158cf;
  color: #767ce4;

  .tab {
    flex: 1;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    padding: 10px 15px;
    cursor: pointer;

    &.tab-selected {
      background: #5158cf;
      color: #fff;
      border-radius: 5px;
    }
  }
`;
