import { get } from 'lodash';
import React from 'react';
import { getLinkDownloadApp } from 'utils/commonFunction';

const osName = pathname => {
  return !!pathname && pathname.includes('ios') ? 'ios' : 'android';
};

const isWhiteLabel = match => {
  const number = get(match, 'params.id', null);
  return parseInt(number);
};

class RedirectPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { redirectTo, match } = this.props;

    const appNumber = isWhiteLabel(match);
    if (appNumber) {
      this.handleDownloadApp(appNumber);
    } else {
      window.location = redirectTo;
    }
  }

  handleDownloadApp = async appNumber => {
    const { location } = this.props;
    const url = await getLinkDownloadApp({
      number: appNumber,
      os: osName(location.pathname),
    });
    if (url) {
      window.location = url;
    }
  };

  render() {
    return null;
  }
}

export default RedirectPage;
