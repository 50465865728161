import React from 'react';
import { createPortal } from 'react-dom';

import ProgramTemplatesFullscreenModal from 'components/ProgramTemplatesFullscreenModal';

const ProgramTemplates = () => {
  return createPortal(<ProgramTemplatesFullscreenModal forceOpenFullscreenModal={true} />, document.body);
};

export default ProgramTemplates;
