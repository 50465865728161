import React from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { DownloadCoachAppWrapper } from './styles';
import DownloadCoachApp from 'components/DownloadCoachApp';
import { CDN_URL } from 'constants/commonData';

export default function DownloadCoachAppButton(props) {
  const onClick = () => {
    props.toggleModal(true, <DownloadCoachApp fromTopbar />);
  };

  const downloadAppStep = _.get(props, 'onboarding.checkList', []).find(o => o.type === 'use_coach_app');
  const isHideOnboarding = _.get(props, 'onboarding.hideFeature', false);
  if (!downloadAppStep || (downloadAppStep && downloadAppStep.state) || !isHideOnboarding) {
    return null;
  }

  return (
    <DownloadCoachAppWrapper data-tip="Download Coach App" data-for="downloadCoachApp" onClick={onClick}>
      <img src={`${CDN_URL}/images/download_coach_app_icon.svg`} alt="" />
      <ReactTooltip id="downloadCoachApp" place="bottom" effect="solid" />
    </DownloadCoachAppWrapper>
  );
}
