import styled, { css } from 'styled-components';
import { MODE } from './index';

export const Wrapper = styled.div``;

export const EquipmentWrapper = styled.div`
  .single-select-container__equipment {
    .single-select__control {
      background: #fff;
      z-index: unset !important;
      min-width: 369px;

      .single-select__placeholder {
        color: #969fb5;
        font-size: 13px;
        font-weight: 400;
      }
      .single-select__value-container {
        padding: 2px 6px !important;
      }
      .single-select__input input {
        font-weight: 400;
      }
      .single-select__single-value {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #202353;
      }
    }
    .single-select__menu {
      border-radius: 8px;
      .single-select__menu-list {
        max-height: 248px;
        min-height: 98px;
        .single-select__menu-notice--no-options {
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
        }

        > div:last-child {
          margin-bottom: 10px;
        }
      }
    }

    .single-select__menu-portal {
      z-index: 2001;
    }

    > div:last-child {
      z-index: 3;
    }

    .single-select__indicators {
      padding-right: 3px;
      padding-top: 1px;
    }

    .single-select__clear-indicator {
      svg {
        width: 12px;
        height: 12px;

        path {
          fill: #7b7e91;
        }
      }

      &:hover {
        svg path {
          fill: #5158cf;
        }
      }
    }
  }
`;

export const SearchEquipment = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  margin-left: 11px;
  margin-top: 17px;
  margin-bottom: 11px;
  ${props =>
    props.mode === 'default' &&
    css`
      display: none;
    `}
`;
