import axios from 'axios';
import { normalize, denormalize } from 'normalizr';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Mixpanel } from 'utils/mixplanel';
import Request from 'configs/request';
import { Workout, TrainingSet, SuperSet, Section, Exercise, ExerciseLibrary, OneRepMax } from 'database/schema';
import { addEntities, updateEntity, updateEntities, setEntities } from 'redux/model/actions';
import { ENTITY_NAME } from 'database/constants';
import { mongoObjectId, localSectionId } from 'utils/commonFunction';
import { validateLink } from 'utils/validations';
import { HIDDEN_SECTION, SECTION_FORMAT_KEY, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { initSetsData, initIntervalExerciseFields, handleFormatFreestyleData } from 'helpers/exercise';
import { parseSectionExercises } from 'helpers/workout';
import { markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { WORKOUT_BUILDER_GUIDE_STEPS } from 'constants/commonData';
import { saveSectionToLibrary as saveSection } from 'actions/sectionLibrary';
import {
  updateTrainingSetsAfterLinking,
  updateSetInsideSupersetAfterRemoving,
  updateSetInsideSupersetAfterAdding,
  generateNewSuperset,
  generateNewExercise,
  breakdownSupersetToMultipleExercises,
  toExercisesRequestData,
} from './helper';
import { showError as showErrorPopup } from 'actions/error';
import { logSearchExcercise } from 'libs/firebase-analytics';
import { formatTags } from 'redux/exercise/exercise.actionCreators';

const CancelToken = axios.CancelToken;
const cancelRequests = [];

export const Types = {
  WORKOUT_BUILDER_INIT_WORKOUT_DATA: 'WORKOUT_BUILDER_INIT_WORKOUT_DATA',
  WORKOUT_BUILDER_UPDATE_TITLE: 'WORKOUT_BUILDER_UPDATE_TITLE',
  WORKOUT_BUILDER_UPDATE_DESCRIPTION: 'WORKOUT_BUILDER_UPDATE_DESCRIPTION',
  WORKOUT_BUILDER_ADD_EXERCISES_FROM_AI_TO_RECENT: 'WORKOUT_BUILDER_ADD_EXERCISES_FROM_AI_TO_RECENT',
  WORKOUT_BUILDER_UPDATE_SECTIONS: 'WORKOUT_BUILDER_UPDATE_SECTIONS',
  WORKOUT_BUILDER_UPDATE_BACKGROUND: 'WORKOUT_BUILDER_UPDATE_BACKGROUND',
  WORKOUT_BUILDER_UPDATE_TAGS: 'WORKOUT_BUILDER_UPDATE_TAGS',
  WORKOUT_BUILDER_SET_DRAG_ITEM: 'WORKOUT_BUILDER_SET_DRAG_ITEM',
  WORKOUT_BUILDER_LINK_SET: 'WORKOUT_BUILDER_LINK_SET',
  WORKOUT_BUILDER_SELECT_EXERCISE: 'WORKOUT_BUILDER_SELECT_EXERCISE',
  WORKOUT_BUILDER_SET_DRAG_LEFT_PANEL_ITEM: 'WORKOUT_BUILDER_SET_DRAG_LEFT_PANEL_ITEM',
  WORKOUT_BUILDER_CLEAR_DATA: 'WORKOUT_BUILDER_CLEAR_DATA',
  WORKOUT_BUILDER_SHOW_ERROR: 'WORKOUT_BUILDER_SHOW_ERROR',
  ADD_NEW_EXERCISE_TO_ALL_LIST: 'ADD_NEW_EXERCISE_TO_ALL_LIST',
  UPDATE_EXERCISE_IN_FREESTYLE_SECTION: 'UPDATE_EXERCISE_IN_FREESTYLE_SECTION',
  REMOVE_EXERCISE_IN_FREESTYLE_SECTION: 'REMOVE_EXERCISE_IN_FREESTYLE_SECTION',
  WORKOUT_BUILDER_UPDATE_ID_AFTER_ADDING: 'WORKOUT_BUILDER_UPDATE_ID_AFTER_ADDING',
  WORKOUT_BUILDER_SET_JUST_DROPPED_EXERCISE: 'WORKOUT_BUILDER_SET_JUST_DROPPED_EXERCISE',
  WORKOUT_BUILDER_UPDATE_PARTIAL_DATA: 'WORKOUT_BUILDER_UPDATE_PARTIAL_DATA',
  WORKOUT_BUILDER_UPDATE_EXERCISE_TAB: 'WORKOUT_BUILDER_UPDATE_EXERCISE_TAB',
  WORKOUT_BUILDER_UPDATE_SECTION_TAB: 'WORKOUT_BUILDER_UPDATE_SECTION_TAB',
  WORKOUT_BUILDER_UPDATE_WORKOUT_SCHEDULE: 'WORKOUT_BUILDER_UPDATE_WORKOUT_SCHEDULE',
  WORKOUT_BUILDER_UPDATE_ORIGINAL_WORKOUT: 'WORKOUT_BUILDER_UPDATE_ORIGINAL_WORKOUT',
  WORKOUT_BUILDER_REPLACE_ENTITIES: 'WORKOUT_BUILDER_REPLACE_ENTITIES',
  WORKOUT_BUILDER_UPLOAD_ATTACHMENT_REQUEST: 'WORKOUT_BUILDER_UPLOAD_ATTACHMENT_REQUEST',
  WORKOUT_BUILDER_UPLOAD_ATTACHMENT_SUCCESS: 'WORKOUT_BUILDER_UPLOAD_ATTACHMENT_SUCCESS',
  WORKOUT_BUILDER_RESET_ERROR_REDUX: 'WORKOUT_BUILDER_RESET_ERROR_REDUX',
  RESET_DISCARD_CHANGE: 'RESET_DISCARD_CHANGE',
  WORKOUT_BUILDER_UPDATE_HIDE_WORKOUT: 'WORKOUT_BUILDER_UPDATE_HIDE_WORKOUT',
  WORKOUT_BUILDER_UPDATE_GENERATE_WORKOUT_AI_FROM_PAGE: 'WORKOUT_BUILDER_UPDATE_GENERATE_WORKOUT_AI_FROM_PAGE',
  WORKOUT_BUILDER_UPDATE_TAB: 'WORKOUT_BUILDER_UPDATE_TAB',
  WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_REQUEST: 'WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_REQUEST',
  WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_SUCCESS: 'WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_SUCCESS',
  WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_FAILURE: 'WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_FAILURE',
  WORKOUT_UPDATE_ALL_EXERCISES: 'WORKOUT_UPDATE_ALL_EXERCISES',
  UPDATE_TEMP_WORKOUT_AI: 'UPDATE_TEMP_WORKOUT_AI',
};

export const initWorkoutData = workoutData => {
  return (dispatch, getState) => {
    if (!workoutData) return;

    const data = normalize(workoutData, Workout);
    dispatch(addEntities(data.entities));

    const workoutNormalizr = data.entities[ENTITY_NAME.Workout][data.result];
    dispatch({
      type: Types.WORKOUT_BUILDER_INIT_WORKOUT_DATA,
      payload: { data: workoutNormalizr, originalData: _.toPlainObject(workoutData) },
    });
  };
};

const emptyInstructions = workout => {
  const { sections } = workout;
  let empty = false;
  sections.forEach(section => {
    if (section.format === SECTION_FORMAT_KEY.FREESTYLE && !section.note) {
      empty = true;
    }
  });
  return empty;
};

export const updateTitle = data => ({ type: Types.WORKOUT_BUILDER_UPDATE_TITLE, payload: { data } });

export const updateTempWorkoutAI = data => ({ type: Types.UPDATE_TEMP_WORKOUT_AI, payload: { data } });

export const updateHideWorkout = data => ({ type: Types.WORKOUT_BUILDER_UPDATE_HIDE_WORKOUT, payload: { data } });

export const updateGenerateWorkoutAIFromPage = page => ({
  type: Types.WORKOUT_BUILDER_UPDATE_GENERATE_WORKOUT_AI_FROM_PAGE,
  payload: page,
});

export const updateDescription = data => ({ type: Types.WORKOUT_BUILDER_UPDATE_DESCRIPTION, payload: { data } });

export const addExercisesFromAIToRecentOption = exercises => ({
  type: Types.WORKOUT_BUILDER_ADD_EXERCISES_FROM_AI_TO_RECENT,
  payload: { exercises },
});

export const updateBackground = data => ({ type: Types.WORKOUT_BUILDER_UPDATE_BACKGROUND, payload: { data } });

export const updateTags = data => ({ type: Types.WORKOUT_BUILDER_UPDATE_TAGS, payload: { data } });

export const updateSections = data => ({ type: Types.WORKOUT_BUILDER_UPDATE_SECTIONS, payload: { data } });

export const startUploadAttachment = () => ({ type: Types.WORKOUT_BUILDER_UPLOAD_ATTACHMENT_REQUEST });

export const endUploadAttachment = () => ({ type: Types.WORKOUT_BUILDER_UPLOAD_ATTACHMENT_SUCCESS });

export const updateWorkoutSchedule = data => ({
  type: Types.WORKOUT_BUILDER_UPDATE_WORKOUT_SCHEDULE,
  payload: { data },
});

export const clearWorkoutData = () => ({ type: Types.WORKOUT_BUILDER_CLEAR_DATA });

export const addNewExerciseToAllList = exercise => ({
  type: Types.ADD_NEW_EXERCISE_TO_ALL_LIST,
  payload: { exercise },
});

export const updateExerciseInFreestyleSection = (sectionId, oldExerciseId, exercise) => ({
  type: Types.UPDATE_EXERCISE_IN_FREESTYLE_SECTION,
  payload: { sectionId, exercise, oldExerciseId },
});

export const removeExerciseNotExisted = (sectionId, exerciseId) => ({
  type: Types.REMOVE_EXERCISE_IN_FREESTYLE_SECTION,
  payload: { sectionId, exerciseId },
});

export const updateWorkoutPartialData = data => ({
  type: Types.WORKOUT_BUILDER_UPDATE_PARTIAL_DATA,
  payload: { data },
});

export const updateAllExercises = data => ({
  type: Types.WORKOUT_UPDATE_ALL_EXERCISES,
  payload: { data },
});

export const resetWorkoutError = () => ({
  type: Types.WORKOUT_BUILDER_RESET_ERROR_REDUX,
});

export const updateWorkoutId = id => ({ type: Types.WORKOUT_BUILDER_UPDATE_ID_AFTER_ADDING, payload: { data: id } });

export const setJustDroppedExercise = exerciseId => {
  return (dispatch, getState) => {
    dispatch({ type: Types.WORKOUT_BUILDER_SET_JUST_DROPPED_EXERCISE, payload: { data: exerciseId } });
    dispatch(markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection));

    setTimeout(() => {
      const {
        rootReducer: { workoutBuilder },
      } = getState();

      if (workoutBuilder.get('droppedExercise') === exerciseId) {
        dispatch({ type: Types.WORKOUT_BUILDER_SET_JUST_DROPPED_EXERCISE, payload: { data: '' } });
      }
    }, 2500);
  };
};

export const showError = () => ({ type: Types.WORKOUT_BUILDER_SHOW_ERROR });

export const setDragItem = draggingItem => ({
  type: Types.WORKOUT_BUILDER_SET_DRAG_ITEM,
  payload: { data: draggingItem },
});

export const handleDragSection = params => {
  return (dispatch, getState) => {
    const currentState = getState();
    const draggingItem = currentState.rootReducer.workoutBuilder.get('draggingItem').toJS();
    const selectedWorkout = currentState.rootReducer.workoutBuilder.get('selectedWorkout').toJS();

    const fromIndex = _.findIndex(selectedWorkout.sections, s => s === draggingItem.sectionId);
    let toIndex = params.index;
    if (fromIndex < params.index) {
      toIndex--;
    }
    selectedWorkout.sections.move(fromIndex, toIndex);

    dispatch(updateSections(selectedWorkout.sections));
    dispatch(setDragItem(null));
  };
};

export const handleDragExercise = params => {
  return (dispatch, getState) => {
    const currentState = getState();
    const draggingItem = currentState.rootReducer.workoutBuilder.get('draggingItem').toJS();
    const selectedWorkout = currentState.rootReducer.workoutBuilder.get('selectedWorkout').toJS();
    const fromSection = currentState.rootReducer.model.getIn([ENTITY_NAME.Section, draggingItem.sectionId]).toJS();

    //check move section
    if (params.dropType === 'section' && fromSection.type === HIDDEN_SECTION) {
      return dispatch(handleDragSection(params));
    }

    if (draggingItem.sectionId === params.sectionId && params.dropType === 'exercise') {
      // rearrange exercise
      fromSection.exercises.splice(draggingItem.index, 1);
      const toIndex = params.index > draggingItem.index ? params.index - 1 : params.index;
      fromSection.exercises.splice(toIndex, 0, draggingItem.exerciseId);
      dispatch(updateEntity(ENTITY_NAME.Section, draggingItem.sectionId, fromSection));
    } else {
      // move exercise
      if (params.dropType === 'section') {
        fromSection.exercises.splice(draggingItem.index, 1);

        if (fromSection.type !== HIDDEN_SECTION) {
          dispatch(updateEntity(ENTITY_NAME.Section, draggingItem.sectionId, fromSection));
        }

        const newSectionId = mongoObjectId();
        const toSection = {
          type: 'hidden',
          format: 'regular',
          exercises: [draggingItem.exerciseId],
          _id: newSectionId,
        };
        const entityId = localSectionId(toSection);
        selectedWorkout.sections.splice(params.index, 0, entityId);
        dispatch(updateEntity(ENTITY_NAME.Section, entityId, toSection));
        dispatch(updateSections(selectedWorkout.sections));
      } else {
        const moveExerciseParams = {
          sectionId: draggingItem.sectionId,
          exerciseId: draggingItem.exerciseId,
          toSectionId: params.sectionId,
          index: params.index,
        };
        dispatch(moveExerciseIntoSection(moveExerciseParams));
      }
    }

    dispatch(setDragItem(null));
  };
};

export const linkSet = data => {
  return (dispatch, getState) => {
    const { linkIndex, exerciseId, sectionId } = data;
    const {
      rootReducer: { model, workoutBuilder },
    } = getState();

    if (sectionId) {
      const section = model.getIn([ENTITY_NAME.Section, sectionId]).toJS();
      const exercises = section.exercises.slice();

      if (exerciseId) {
        // link exercise inside section
        const nextExericseId = exercises[linkIndex + 1];

        if (nextExericseId) {
          const exercise = model.getIn([ENTITY_NAME.Exercise, exerciseId]);
          const nextExercise = model.getIn([ENTITY_NAME.Exercise, nextExericseId]);
          const supersetIds = exercise.get('supersets').concat(nextExercise.get('supersets')).toJS();
          const newExercises = exercises.slice();
          const deleted = newExercises.splice(linkIndex + 1, 1);
          const addedEntities = updateTrainingSetsAfterLinking(model, supersetIds);

          const newExercise = { _id: exerciseId, supersets: supersetIds };
          const newSection = { ...section, exercises: newExercises };

          const normalizeInput = { ...addedEntities, newExercise, newSection };
          const schema = {
            addedSets: [TrainingSet],
            newSupersets: [SuperSet],
            newExercise: Exercise,
            newSection: Section,
          };

          const normalized = normalize(normalizeInput, schema);
          const deletedItems = { [ENTITY_NAME.Exercise]: deleted };
          dispatch(updateEntities(normalized.entities, deletedItems));
        }
      } else {
        // link section
        const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
        const nextSection = model.getIn([ENTITY_NAME.Section, sections[linkIndex + 1]]);
        const nextSupersets = model
          .getIn([ENTITY_NAME.Exercise, nextSection.get('exercises').get(0)])
          .get('supersets')
          .toJS();
        const exercise = model.getIn([ENTITY_NAME.Exercise, exercises[0]]).toJS();
        const newSupersetIds = exercise.supersets.concat(nextSupersets);
        const addedEntities = updateTrainingSetsAfterLinking(model, newSupersetIds);

        const newExercise = { ...exercise, supersets: newSupersetIds };
        const normalizeInput = { ...addedEntities, newExercise };
        const schema = {
          addedSets: [TrainingSet],
          newSupersets: [SuperSet],
          newExercise: Exercise,
        };

        const normalized = normalize(normalizeInput, schema);
        const deleted = sections.splice(linkIndex + 1, 1);

        dispatch(updateSections(sections));
        dispatch(updateEntities(normalized.entities, { [ENTITY_NAME.Section]: deleted }));
      }
    }
  };
};

export const unlinkSet = data => {
  return (dispatch, getState) => {
    const { linkIndex, exerciseId, sectionId } = data;
    const {
      rootReducer: { model, workoutBuilder },
    } = getState();

    if (sectionId) {
      const section = model.getIn([ENTITY_NAME.Section, sectionId]).toJS();
      const exercises = section.exercises.slice();
      const exercise = model.getIn([ENTITY_NAME.Exercise, exerciseId]).toJS();
      const newSupersets = exercise.supersets.slice();
      const oldSupersets = newSupersets.splice(0, linkIndex + 1);
      const newExercise = { _id: mongoObjectId(), supersets: newSupersets };
      const oldExercise = { supersets: oldSupersets, _id: exerciseId };

      if (section.type === HIDDEN_SECTION) {
        const newSection = { ...section, exercises: [newExercise._id], _id: mongoObjectId() };

        const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS().slice();
        const sectionIndex = _.findIndex(sections, id => id === sectionId);
        sections.splice(sectionIndex + 1, 0, localSectionId(newSection));

        const normalizeInput = { exercises: [oldExercise, newExercise], section: newSection };
        const schema = { exercises: [Exercise], section: Section };
        const normalized = normalize(normalizeInput, schema);

        dispatch(updateEntities(normalized.entities));
        dispatch(updateSections(sections));
      } else {
        const exerciseIndex = _.findIndex(exercises, id => id === exerciseId);
        exercises.splice(exerciseIndex + 1, 0, newExercise._id);
        const newSection = { ...section, exercises };

        const normalizeInput = { exercises: [oldExercise, newExercise], section: newSection };
        const schema = { exercises: [Exercise], section: Section };
        const normalized = normalize(normalizeInput, schema);
        dispatch(updateEntities(normalized.entities));
      }
    }
  };
};

export const addSets = (exerciseId, supersetId, quantity) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { model },
    } = getState();

    const superset = model.getIn([ENTITY_NAME.Superset, supersetId]).toJS();
    let currentSets = superset.training_sets.slice();
    const lastSet = model.getIn([ENTITY_NAME.TrainingSet, currentSets[currentSets.length - 1]]).toJS();
    let addedSets = _.map(Array(quantity), () => ({ ...lastSet, _id: mongoObjectId() }));
    currentSets = currentSets.concat(_.map(addedSets, '_id'));
    const newSuperset = { ...superset, training_sets: currentSets };

    const exercise = model.getIn([ENTITY_NAME.Exercise, exerciseId]).toJS();
    const nomarlizeInput = { addedSets, updatedSupersets: [newSuperset] };

    if (exercise.supersets.length > 1) {
      const supersets = exercise.supersets.map(id => model.getIn([ENTITY_NAME.Superset, id]).toJS());
      const otherSupersets = _.filter(supersets, s => s._id !== supersetId);
      const updateData = updateSetInsideSupersetAfterAdding(otherSupersets, currentSets.length, model);
      nomarlizeInput.addedSets = nomarlizeInput.addedSets.concat(updateData.addedSets);
      nomarlizeInput.updatedSupersets = nomarlizeInput.updatedSupersets.concat(updateData.updatedSupersets);
    }

    const normalized = normalize(nomarlizeInput, { addedSets: [TrainingSet], updatedSupersets: [SuperSet] });

    dispatch(updateEntities(normalized.entities));
  };
};

export const removeSet = (exerciseId, supersetId, setId) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { model },
    } = getState();

    const exercise = model.getIn([ENTITY_NAME.Exercise, exerciseId]).toJS();
    const superset = model.getIn([ENTITY_NAME.Superset, supersetId]).toJS();
    let currentSets = superset.training_sets.slice();

    if (currentSets.length > 1) {
      _.remove(currentSets, id => id === setId);

      if (exercise.supersets.length > 1) {
        const supersets = exercise.supersets.map(id => model.getIn([ENTITY_NAME.Superset, id]).toJS());
        const otherSupersets = _.filter(supersets, s => s._id !== supersetId);
        const { removedSets, updatedSupersets } = updateSetInsideSupersetAfterRemoving(
          otherSupersets,
          currentSets.length,
        );
        const newSuperset = { ...superset, training_sets: currentSets };

        const normalized = normalize([newSuperset, ...updatedSupersets], [SuperSet]);
        dispatch(updateEntities(normalized.entities, { [ENTITY_NAME.TrainingSet]: [...removedSets, setId] }));
      } else {
        dispatch(
          updateEntities(
            { [ENTITY_NAME.Superset]: { [supersetId]: { training_sets: currentSets } } },
            { [ENTITY_NAME.TrainingSet]: [setId] },
          ),
        );
      }
    }
  };
};

export const deleteSuperset = (sectionId, exerciseId, supersetId) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { model, workoutBuilder },
    } = getState();

    const exercise = model.getIn([ENTITY_NAME.Exercise, exerciseId]).toJS();
    const supersets = exercise.supersets.slice();
    const deletedSupersets = _.remove(supersets, id => id === supersetId);

    const updateData = { [ENTITY_NAME.Exercise]: { [exerciseId]: { supersets } } };
    const deleteData = { [ENTITY_NAME.Superset]: deletedSupersets };

    if (!supersets.length) {
      const section = model.getIn([ENTITY_NAME.Section, sectionId]).toJS();
      const deletedExercises = _.remove(section.exercises, exId => exId === exerciseId);
      updateData[ENTITY_NAME.Section] = { [sectionId]: { ...section } };
      deleteData[ENTITY_NAME.Exercise] = deletedExercises;

      if (!section.exercises.length && section.type === HIDDEN_SECTION) {
        const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
        const deletedSections = _.remove(sections, sId => sId === sectionId);
        deleteData[ENTITY_NAME.Section] = deletedSections;
        dispatch(updateSections(sections));
      }
    }

    dispatch(updateEntities(updateData, deleteData));
  };
};

export const duplicateSuperset = (sectionId, supersetId) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { model, workoutBuilder },
    } = getState();

    const section = model.getIn([ENTITY_NAME.Section, sectionId]).toJS();
    const superset = model.getIn([ENTITY_NAME.Superset, supersetId]).toJS();
    const trainingSets = superset.training_sets.map(id => {
      const set = model.getIn([ENTITY_NAME.TrainingSet, id]).toJS();
      return { ...set, _id: mongoObjectId() };
    });
    const newSuperset = { ...superset, _id: mongoObjectId(), training_sets: _.map(trainingSets, '_id') };
    const newExercise = { _id: mongoObjectId(), supersets: [newSuperset._id] };

    let newSection, sections;

    if (section.type === HIDDEN_SECTION) {
      newSection = { ...section, exercises: [newExercise._id], _id: mongoObjectId() };
      sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
      sections.push(localSectionId(newSection));
    } else {
      newSection = { ...section, exercises: [...section.exercises, newExercise._id] };
    }

    const normalizeInput = { trainingSets, newSuperset, newExercise, newSection };
    const schema = { trainingSets: [TrainingSet], newSuperset: SuperSet, newExercise: Exercise, newSection: Section };
    const normalized = normalize(normalizeInput, schema);
    dispatch(updateEntities(normalized.entities));

    if (sections) {
      dispatch(updateSections(sections));
    }
  };
};

export const addHidenExercise = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const selectedWorkout = currentState.rootReducer.workoutBuilder.get('selectedWorkout').toJS();
    const newSectionId = mongoObjectId();
    const newTrainingSet = { _id: mongoObjectId() };
    const newSuperset = generateNewSuperset(newTrainingSet._id);
    const newExercise = generateNewExercise(newSuperset._id);

    const newSection = {
      type: 'hidden',
      format: 'regular',
      exercises: [newExercise._id],
      _id: newSectionId,
    };

    const entityId = localSectionId(newSection);

    const normalizeInput = { newTrainingSet, newSuperset, newExercise, newSection };
    const schema = {
      newTrainingSet: TrainingSet,
      newSuperset: SuperSet,
      newExercise: Exercise,
      newSection: Section,
    };

    selectedWorkout.sections.push(entityId);
    const normalized = normalize(normalizeInput, schema);

    dispatch(updateEntities(normalized.entities));
    dispatch(updateSections(selectedWorkout.sections));
  };
};

export const addSection = data => {
  const { FREESTYLE } = SECTION_FORMAT_KEY;

  return (dispatch, getState) => {
    const currentState = getState();
    const selectedWorkout = currentState.rootReducer.workoutBuilder.get('selectedWorkout').toJS();
    const newSectionId = mongoObjectId();
    const newSection =
      FREESTYLE === data.format
        ? {
            ...data,
            exercise_references: [],
            note: '',
            _id: newSectionId,
          }
        : {
            ...data,
            exercises: [],
            _id: newSectionId,
          };
    const entityId = localSectionId(newSection);

    selectedWorkout.sections.push(entityId);
    const normalized = normalize(newSection, Section);
    data.format === FREESTYLE && Mixpanel.track('workout_builder_create_with_free_style');

    dispatch(updateEntities(normalized.entities));
    dispatch(updateSections(selectedWorkout.sections));
  };
};

export const duplicateSection = sectionId => {
  return (dispatch, getState) => {
    const {
      rootReducer: { model, workoutBuilder },
    } = getState();
    let section = denormalize(model.getIn([ENTITY_NAME.Section, sectionId]), Section, model).toJS();
    const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
    section._id = mongoObjectId();

    section.exercises = _.map(section.exercises, exercise => {
      const supersets = _.map(exercise.supersets, superset => {
        const trainingSets = _.map(superset.training_sets, set => ({ ...set, _id: mongoObjectId() }));
        return { ...superset, training_sets: trainingSets, _id: mongoObjectId() };
      });
      return { supersets, _id: mongoObjectId() };
    });

    sections.push(localSectionId(section));
    section = handleFormatFreestyleData(section);

    const normalized = normalize(section, Section);
    dispatch(updateEntities(normalized.entities));
    dispatch(updateSections(sections));
  };
};

export const saveSectionToLibrary = sectionId => {
  return (dispatch, getState) => {
    const {
      user: { preferences },
      rootReducer: { model },
    } = getState();
    const { FREESTYLE } = SECTION_FORMAT_KEY;
    const section = denormalize(model.getIn([ENTITY_NAME.Section, sectionId]), Section, model).toJS();
    const exercises = parseSectionExercises(section, preferences);
    const sectionData = Object.assign({}, section, { exercises });
    delete sectionData._id;
    sectionData.exercises = _.reduce(
      sectionData.exercises,
      (listExercise, ex) => {
        ex.supersets = _.reduce(
          ex.supersets,
          (listSuperset, superset) => {
            if (superset.exercise) listSuperset.push(superset);
            return listSuperset;
          },
          [],
        );
        if (ex.supersets && ex.supersets.length > 0) listExercise.push(ex);
        return listExercise;
      },
      [],
    );

    if (sectionData.exercises.length === 0 && sectionData.format !== FREESTYLE) {
      return dispatch(
        showErrorPopup(
          'Please add at least one exercise before saving the section to your library.',
          'Section is empty',
          'Ok',
        ),
      );
    }

    const selectedSection = handleFormatFreestyleData(sectionData);
    if (selectedSection.format === FREESTYLE) {
      if (!handleCheckValuSaveLibrary(selectedSection)) {
        return dispatch(
          showErrorPopup(
            'Please add some instructions before saving the section to your library.',
            'Section is empty',
            'Ok',
          ),
        );
      }
      const link = _.get(selectedSection, 'attachments[0].link', false);
      if (link && !validateLink(link)) {
        return dispatch(
          showErrorPopup('Please add valid link before saving the section to your library.', 'Link is invalid', 'Ok'),
        );
      }
    }
    return dispatch(saveSection(selectedSection)).then(() => {
      dispatch(searchSection(''));
    });
  };
};

const handleCheckValuSaveLibrary = selectedSection => {
  if (selectedSection.note.trim().length > 0) {
    return true;
  }
  return false;
};

export const removeSection = sectionId => {
  return (dispatch, getState) => {
    const {
      rootReducer: { workoutBuilder },
    } = getState();
    const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
    _.remove(sections, id => id === sectionId);
    dispatch(updateSections(sections));
  };
};

export const customizeSection = (sectionId, data) => {
  return (dispatch, getState) => {
    const {
      user: { preferences },
      rootReducer: {
        model,
        workoutBuilder,
        exercise: { fields, categories },
        client: { workingClientDetail },
      },
    } = getState();
    const section = denormalize(model.getIn([ENTITY_NAME.Section, sectionId]), Section, model).toJS();
    const newSection = { ...section, ...data };

    if (newSection.format === SECTION_FORMAT_KEY.INTERVAL) {
      if (section.format !== SECTION_FORMAT_KEY.INTERVAL) {
        const systemData = {
          units: workingClientDetail ? workingClientDetail.preferences : preferences,
          systemFields: fields,
        };

        newSection.exercises = _.map(newSection.exercises, exercise => {
          const supersets = _.map(exercise.supersets, superset => {
            const newFields = initIntervalExerciseFields(superset.exercise_instance, categories, fields);

            const params = {
              ...systemData,
              oldSets: superset.training_sets,
              newFields: newFields,
            };

            return {
              ...superset,
              exercise_instance: { ...superset.exercise_instance, fields: newFields },
              training_sets: initSetsData(params, true),
            };
          });

          return { ...exercise, supersets };
        });
      }
    } else {
      if (
        (newSection.format === SECTION_FORMAT_KEY.AMRAP || newSection.format === SECTION_FORMAT_KEY.TIMED) &&
        section.format !== SECTION_FORMAT_KEY.AMRAP &&
        section.format !== SECTION_FORMAT_KEY.TIMED
      ) {
        newSection.exercises = breakdownSupersetToMultipleExercises(newSection.exercises);
      }
    }

    const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
    const deleted = sections.splice(
      _.findIndex(sections, id => id === sectionId),
      1,
      localSectionId(newSection),
    );
    const normalized = normalize(newSection, Section);
    dispatch(updateEntities(normalized.entities), { [ENTITY_NAME.Section]: deleted });
    dispatch(updateSections(sections));
  };
};

export const onDragLeftPanelStart = draggingItem => ({
  type: Types.WORKOUT_BUILDER_SET_DRAG_LEFT_PANEL_ITEM,
  payload: { data: draggingItem },
});

export const onDragSectionLeftPanelEnd = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        workoutBuilder,
        client: { workingClientDetail },
        exercise: { fields },
        model,
      },
      user: { preferences },
    } = getState();
    const draggingItem = workoutBuilder.get('draggingItemLeft').toJS();
    const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();

    dispatch(markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection));
    if (draggingItem.dragType === 'exercise') {
      const newTrainingSet = { _id: mongoObjectId() };
      const newSuperset = generateNewSuperset(newTrainingSet._id, draggingItem.exercise);

      // note
      newSuperset.note = draggingItem.exercise.note || '';

      // alternatives
      if (draggingItem.exercise.alternatives) {
        const isExistExercise = draggingItem.exercise.alternatives.every(id =>
          model.hasIn([ENTITY_NAME.ExerciseLibrary, id]),
        );

        if (isExistExercise) {
          newSuperset.alternatives = draggingItem.exercise.alternatives;
        } else {
          dispatch(
            getExercisesByIds({
              ids: draggingItem.exercise.alternatives,
              superset: { ...newSuperset },
              isUpdateEntities: true,
            }),
          );
        }
      }

      const systemData = {
        units: workingClientDetail ? workingClientDetail.preferences : preferences,
        systemFields: fields,
        newFields: newSuperset.exercise_instance.fields,
        oldSets: [newTrainingSet],
      };
      const newTrainingSets = initSetsData(systemData, false);
      newSuperset.training_sets = _.map(newTrainingSets, '_id');
      const newExercise = generateNewExercise(newSuperset._id);
      const newSectionId = mongoObjectId();
      const newSection = {
        type: 'hidden',
        format: 'regular',
        exercises: [newExercise._id],
        _id: newSectionId,
      };
      const entityId = localSectionId(newSection);
      const normalizeInput = { newTrainingSets, newSuperset, newExercise, newSection };
      const schema = {
        newTrainingSets: [TrainingSet],
        newSuperset: SuperSet,
        newExercise: Exercise,
        newSection: Section,
      };

      const normalized = normalize(normalizeInput, schema);
      sections.splice(params.sectionIndex, 0, entityId);

      dispatch(setJustDroppedExercise(newExercise._id));
      dispatch(updateEntities(normalized.entities));
      dispatch(updateSections(sections));
    } else {
      const sectionWithNewID = { ...draggingItem.section, _id: mongoObjectId() };

      // Reset _id of exercises, supersets, training_sets when onDragSection
      sectionWithNewID.exercises = _.map(sectionWithNewID.exercises, exercise => {
        const supersets = _.map(exercise.supersets, superset => {
          const trainingSets = _.map(superset.training_sets, set => ({ ...set, _id: mongoObjectId() }));
          return { ...superset, training_sets: trainingSets, _id: mongoObjectId() };
        });
        return { ...exercise, supersets, _id: mongoObjectId() };
      });

      const normalizedData = normalize(sectionWithNewID, Section);

      const entityId = localSectionId(sectionWithNewID);
      sections.splice(params.sectionIndex, 0, entityId);

      dispatch(updateEntities(normalizedData.entities));
      dispatch(updateSections(sections));
    }

    dispatch(onDragLeftPanelStart(null));
  };
};

export const onDragExerciseLeftPanelEnd = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        workoutBuilder,
        model,
        client: { workingClientDetail },
        exercise: { fields, categories },
      },
      user: { preferences },
    } = getState();
    const draggingItem = workoutBuilder.get('draggingItemLeft').toJS();

    if (draggingItem.dragType === 'exercise') {
      const toSection = model.getIn([ENTITY_NAME.Section, params.sectionId]).toJS();

      const newTrainingSet = { _id: mongoObjectId() };
      const newSuperset = generateNewSuperset(newTrainingSet._id, draggingItem.exercise);

      // note
      newSuperset.note = draggingItem.exercise.note || '';

      // alternatives
      if (draggingItem.exercise.alternatives) {
        const isExistExercise = draggingItem.exercise.alternatives.every(id =>
          model.hasIn([ENTITY_NAME.ExerciseLibrary, id]),
        );

        if (isExistExercise) {
          newSuperset.alternatives = draggingItem.exercise.alternatives;
        } else {
          dispatch(
            getExercisesByIds({
              ids: draggingItem.exercise.alternatives,
              superset: { ...newSuperset },
              isUpdateEntities: true,
            }),
          );
        }
      }

      const isIntervalSection = toSection.format === SECTION_FORMAT_KEY.INTERVAL;
      if (isIntervalSection) {
        newSuperset.exercise_instance.fields = initIntervalExerciseFields(
          newSuperset.exercise_instance,
          categories,
          fields,
        );
      }
      const systemData = {
        units: workingClientDetail ? workingClientDetail.preferences : preferences,
        systemFields: fields,
        newFields: newSuperset.exercise_instance.fields,
        oldSets: [newTrainingSet],
      };
      const newTrainingSets = initSetsData(systemData, isIntervalSection);
      newSuperset.training_sets = _.map(newTrainingSets, '_id');

      const newExercise = generateNewExercise(newSuperset._id);

      toSection.exercises.splice(params.exerciseIndex, 0, newExercise._id);

      const normalizeInput = { newTrainingSets, newSuperset, newExercise, toSection };
      const schema = {
        newTrainingSets: [TrainingSet],
        newSuperset: SuperSet,
        newExercise: Exercise,
        toSection: Section,
      };
      const normalized = normalize(normalizeInput, schema);

      dispatch(setJustDroppedExercise(newExercise._id));
      dispatch(updateEntities(normalized.entities));
    }

    dispatch(onDragLeftPanelStart(null));
  };
};

const checkValidation = params => {
  let { originalWorkout, workout, workingClientDetail, user, fields, dispatch } = params;
  const { FREESTYLE } = SECTION_FORMAT_KEY;

  _.forEach(workout.sections, section => {
    _.forEach(section.exercises, exercise => {
      exercise.supersets = _.filter(exercise.supersets, superset => !!_.get(superset, 'exercise_instance.fields'));
    });
    section.exercises = _.filter(section.exercises, exercise => !!exercise.supersets.length);
  });
  workout.sections = _.filter(workout.sections, s => !!s.exercises.length || s.format === FREESTYLE);

  if (!_.isEqual(workout, originalWorkout)) {
    const normailized = normalize(workout, Workout);
    // dispatch(updateSections(_.map(workout.sections, section => localSectionId(section)))); // TODO - DO NOT RESET DATA
    dispatch(updateEntities(normailized.entities));
  }

  let isValid = true;

  if (!workout.sections.length || !workout.title) {
    dispatch(showError());
    return null;
  }

  _.forEach(workout.sections, section => {
    if (section.format === FREESTYLE) {
      if (section.note.length === 0 || section.title.length === 0) isValid = false;
      return isValid;
    }

    if (!section.exercises.length && section.format !== FREESTYLE) {
      isValid = false;
      return isValid;
    }

    if (section.type !== HIDDEN_SECTION) {
      if (
        !section.title ||
        (section.format === SECTION_FORMAT_KEY.AMRAP && !parseFloat(section.time)) ||
        (section.format === SECTION_FORMAT_KEY.TIMED && !parseInt(section.round))
      ) {
        isValid = false;
        return false;
      }
    }
  });

  if (!isValid) {
    dispatch(showError());
    return null;
  }

  const normailized = normalize(workout, Workout);

  _.forEach(normailized.entities[ENTITY_NAME.Superset], superset => {
    if (!superset.exercise_instance || !superset.exercise_instance.fields) {
      isValid = false;
      return false;
    }
  });

  if (!isValid) {
    dispatch(showError());
    return null;
  }

  _.forEach(normailized.entities[ENTITY_NAME.TrainingSet], set => {
    if (set.hasOwnProperty('orm')) {
      if (!set.orm || isNaN(parseInt(set.orm.value))) {
        isValid = false;
        return false;
      }
    }
    if (_.get(set, 'reps.value') === false) {
      isValid = false;
      return false;
    }
    // if (_.get(set, 'hr.value') === false) {
    //   isValid = false;
    //   return false;
    // }
    // if (_.get(set, 'rir.value') === false) {
    //   isValid = false;
    //   return false;
    // }
  });

  if (!isValid) {
    dispatch(showError());
    return null;
  }

  const preferences = _.get(workingClientDetail, 'preferences', user.preferences);
  let workoutData = [];

  workout.sections = _.forEach(workout.sections, section => {
    section.exercises = toExercisesRequestData(section.exercises, preferences, fields);
    section = handleFormatFreestyleData(section);
    workoutData.push(section);
  });

  workout.sections = workoutData;
  return workout;
};

export const validateWorkoutData = () => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        workoutBuilder,
        model,
        client: { workingClientDetail },
        exercise: { fields },
      },
    } = getState();
    const originalWorkout = denormalize(workoutBuilder.get('selectedWorkout'), Workout, model).toJS();
    let workout = _.cloneDeep(originalWorkout);

    const params = {
      originalWorkout,
      workout,
      workingClientDetail,
      user,
      fields,
      dispatch,
    };

    const isSectionEmpty = _.get(originalWorkout, 'sections', []).filter(
      section =>
        section.format !== SECTION_FORMAT_KEY.FREESTYLE &&
        section.format !== SECTION_FORMAT_KEY.HIDDEN_SECTION &&
        _.get(section, 'exercises', []).length <= 0,
    );

    if (isSectionEmpty.length) {
      toast.error('Please add at lease 1 exercise');
    }

    workout = checkValidation(params);

    if (emptyInstructions(originalWorkout) && !isSectionEmpty.length) {
      toast.error('Please add instructions');
    }

    if (workout && !isSectionEmpty.length) {
      dispatch(showError(false));
      return {
        _id: workout._id,
        title: workout.title,
        description: workout.description,
        background: workout.background,
        author: _.get(workout, 'author'),
        share: workout.share || TEAM_SHARE_PRIVATE,
        sections: workout.sections,
        tags: _.get(workout, 'tags', []).map(tag => tag._id),
      };
    } else {
      dispatch(showError());
      return null;
    }
  };
};

export const addExerciseInsideSection = params => {
  return (dispatch, getState) => {
    const currentState = getState();
    const foundSection = currentState.rootReducer.model.getIn([ENTITY_NAME.Section, params.sectionId]).toJS();

    const newTrainingSet = { _id: mongoObjectId() };
    const newSuperset = generateNewSuperset(newTrainingSet._id);
    const newExercise = generateNewExercise(newSuperset._id);

    foundSection.exercises.push(newExercise._id);

    const normalizeInput = { newTrainingSet, newSuperset, newExercise, foundSection };
    const schema = {
      newTrainingSet: TrainingSet,
      newSuperset: SuperSet,
      newExercise: Exercise,
      foundSection: Section,
    };
    const normalized = normalize(normalizeInput, schema);

    dispatch(updateEntities(normalized.entities));
  };
};

export const toggleUseORM = (supersetId, isUseORM) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        model,
        exercise: { fields },
      },
    } = getState();
    const superset = denormalize(model.getIn([ENTITY_NAME.Superset, supersetId]), SuperSet, model).toJS();
    const weightField = _.find(fields, f => f.unique_code === 'weight');
    const oneRMField = _.find(fields, f => f.unique_code === 'orm');

    if (!weightField || !oneRMField) {
      return;
    }

    let currentFields = (superset.exercise_instance.fields || []).slice();

    if (isUseORM) {
      if (currentFields.length > 3) {
        dispatch(showErrorPopup('Reached maximum number of column. Cannot activate Use %'));
        return;
      }

      const weightFieldIndex = currentFields.indexOf(weightField._id);
      currentFields.splice(weightFieldIndex, 0, oneRMField._id);
      superset.training_sets = superset.training_sets.map(set => ({
        ...set,
        orm: { value: 0 },
      }));
    } else {
      const existedWeightFromCurrentFields = currentFields.find(item => item === weightField._id);

      if (existedWeightFromCurrentFields) {
        currentFields = currentFields.filter(fId => fId !== oneRMField._id);
      } else {
        const ormFieldIndex = currentFields.indexOf(oneRMField._id);
        currentFields.splice(ormFieldIndex, 1, weightField._id);
      }
      const isWeight = weightField ? { weight: { value: '' } } : {};
      superset.training_sets = superset.training_sets.map(set => {
        const newSet = { ...set, ...isWeight };
        delete newSet.orm;
        return newSet;
      });
    }

    superset.exercise_instance.fields = currentFields;
    const normalized = normalize(superset, SuperSet);
    dispatch(setEntities(normalized.entities));
  };
};

export const searchExercise = params => {
  return (dispatch, getState) => {
    const currentState = getState();
    const exerciseTab = currentState.rootReducer.workoutBuilder.get('exerciseTab').toJS();
    const filters = params.filters || exerciseTab.filters;
    const textSearch = params.textSearch;
    const searchParams = _.omit(formatTags({ ...filters, q: textSearch, per_page: 20, page: 1, ...params }), 'filters');
    logSearchExcercise(searchParams);
    return dispatch(
      Request.post({ url: `/api/exercise/search_filter`, data: searchParams }, false, response => {
        const { data, total } = response.data;
        const normalized = normalize(data, [ExerciseLibrary]);
        dispatch(addEntities(normalized.entities));

        dispatch({
          type: Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB,
          payload: {
            data: {
              textSearch,
              filters,
              list: _.map(data, '_id'),
              total,
              page: 1,
              ...params,
            },
          },
        });
      }),
    );
  };
};

export const searchExerciseWithLoading = params => (dispatch, getState) => {
  const currentState = getState();
  const exerciseTab = currentState.rootReducer.workoutBuilder.get('exerciseTab').toJS();
  const filters = params.filters || exerciseTab.filters;
  const textSearch = params.textSearch;
  const searchParams = _.omit(formatTags({ ...filters, q: textSearch, per_page: 20, page: 1, ...params }), 'filters');

  dispatch({
    type: Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_REQUEST,
    payload: { textSearch },
  });

  logSearchExcercise(searchParams);

  if (!!cancelRequests.length) {
    cancelRequests.forEach(
      cancelRequest => typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_SEARCH_EXERCISE'),
    );
  }

  return dispatch(
    Request.post(
      {
        url: `/api/exercise/search_filter`,
        data: searchParams,
        cancelToken: new CancelToken(cancelRequest => cancelRequests.push(cancelRequest)),
      },
      false,
      response => {
        const { data, total } = response.data;
        const normalized = normalize(data, [ExerciseLibrary]);
        dispatch(addEntities(normalized.entities));

        const state = getState();
        const exerciseTab = state.rootReducer.workoutBuilder.get('exerciseTab').toJS();

        const currentQueryText = exerciseTab.textSearch || '';
        const currentInputValue = textSearch || '';

        if (currentQueryText.toLowerCase().trim() === currentInputValue.toLowerCase().trim()) {
          cancelRequests.length = 0;

          dispatch({
            type: Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_SUCCESS,
            payload: {
              data: {
                textSearch,
                filters,
                list: _.map(data, '_id'),
                total,
                page: 1,
                ...params,
              },
            },
          });
        }
      },
      error => {
        if ((error || {}).message === 'CANCEL_REQUEST_SEARCH_EXERCISE') return;
        dispatch({
          type: Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB_FAILURE,
        });
      },
    ),
  );
};

export const loadMoreExercise = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { filters, list, page, textSearch, total } = currentState.rootReducer.workoutBuilder
      .get('exerciseTab')
      .toJS();
    const newTags = _.get(filters, 'tags', []).map(item => (_.isObject(item) ? item._id : item));
    const searchParams = { ...filters, q: textSearch, per_page: 20, page: page + 1, tags: newTags };

    if (total <= list.length) {
      return Promise.resolve({});
    }

    return dispatch(
      Request.post({ url: `/api/exercise/search_filter`, data: searchParams }, false, response => {
        const { data, total } = response.data;
        const normalized = normalize(data, [ExerciseLibrary]);
        dispatch(addEntities(normalized.entities));

        dispatch({
          type: Types.WORKOUT_BUILDER_UPDATE_EXERCISE_TAB,
          payload: {
            data: {
              list: [...list, ..._.map(data, '_id')],
              total,
              page: page + 1,
              filters,
              textSearch,
            },
          },
        });
      }),
    );
  };
};

export const searchSection = textSearch => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { filters } = currentState.rootReducer.workoutBuilder.get('sectionTab').toJS();
    const searchParams = { ...filters, q: textSearch, per_page: 20, page: 1 };
    logSearchExcercise(searchParams);
    return dispatch(
      Request.get({ url: `/api/training_section/list`, params: searchParams }, false, response => {
        const { data, total } = response.data;
        dispatch({
          type: Types.WORKOUT_BUILDER_UPDATE_SECTION_TAB,
          payload: {
            data: {
              textSearch,
              filters,
              list: data,
              total,
              page: 1,
            },
          },
        });
      }),
    );
  };
};

export const loadMoreSection = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { filters, list, page, textSearch, total } = currentState.rootReducer.workoutBuilder.get('sectionTab').toJS();
    const searchParams = { ...filters, q: textSearch, per_page: 20, page: page + 1 };

    if (total <= list.length) {
      return Promise.resolve({});
    }

    return dispatch(
      Request.get({ url: `/api/training_section/list`, params: searchParams }, false, response => {
        const { data, total } = response.data;
        dispatch({
          type: Types.WORKOUT_BUILDER_UPDATE_SECTION_TAB,
          payload: {
            data: {
              list: [...list, ...data],
              total,
              page: page + 1,
              filters,
              textSearch,
            },
          },
        });
      }),
    );
  };
};

export const moveExerciseIntoSection = params => {
  return (dispatch, getState) => {
    const {
      user: { preferences },
      rootReducer: {
        workoutBuilder,
        model,
        client: { workingClientDetail },
        exercise: { fields, categories },
      },
    } = getState();
    const { sectionId, exerciseId, toSectionId } = params;
    const fromSection = model.getIn([ENTITY_NAME.Section, sectionId]).toJS();
    const toSection = denormalize(model.getIn([ENTITY_NAME.Section, toSectionId]), Section, model).toJS();
    const exercise = denormalize(model.getIn([ENTITY_NAME.Exercise, exerciseId]), Exercise, model).toJS();
    let addedExercises = [exercise];

    if (toSection.format === SECTION_FORMAT_KEY.INTERVAL) {
      if (fromSection.type === HIDDEN_SECTION || fromSection.format !== SECTION_FORMAT_KEY.INTERVAL) {
        const systemData = {
          units: _.get(workingClientDetail, 'preferences', preferences),
          systemFields: fields,
        };

        exercise.supersets = _.map(exercise.supersets, superset => {
          const newFields = initIntervalExerciseFields(superset.exercise_instance, categories, fields);

          const params = {
            ...systemData,
            oldSets: superset.training_sets,
            newFields: newFields,
          };

          return {
            ...superset,
            exercise_instance: { ...superset.exercise_instance, fields: newFields },
            training_sets: initSetsData(params, true),
          };
        });
      }
    } else {
      if (
        (toSection.format === SECTION_FORMAT_KEY.AMRAP || toSection.format === SECTION_FORMAT_KEY.TIMED) &&
        fromSection.format !== SECTION_FORMAT_KEY.AMRAP &&
        fromSection.format !== SECTION_FORMAT_KEY.TIMED
      ) {
        addedExercises = breakdownSupersetToMultipleExercises([exercise]);
      }
    }

    toSection.exercises.splice(
      params.index !== 0 && !params.index ? toSection.exercises.length : params.index,
      0,
      ...addedExercises,
    );
    const normalized = normalize(toSection, Section);
    dispatch(updateEntities(normalized.entities));

    if (fromSection.type === HIDDEN_SECTION) {
      const selectedWorkout = workoutBuilder.get('selectedWorkout').toJS();
      selectedWorkout.sections = selectedWorkout.sections.filter(s => s !== sectionId);
      dispatch(updateSections(selectedWorkout.sections));
    } else {
      fromSection.exercises = _.filter(fromSection.exercises, exId => exId !== exerciseId);
      dispatch(updateEntity(ENTITY_NAME.Section, sectionId, fromSection));
    }
  };
};

export const updateOriginalWorkout = data => {
  const { sections = [] } = data;
  _.forEach(sections, section => {
    section.format === SECTION_FORMAT_KEY.FREESTYLE && Mixpanel.track('workout_builder_update_with_free_style');
  });

  return dispatch =>
    dispatch({
      type: Types.WORKOUT_BUILDER_UPDATE_ORIGINAL_WORKOUT,
      payload: { data },
    });
};

export const updateExerciseLibrary = data => {
  return (dispatch, getState) => {
    const {
      user: { preferences },
      rootReducer: {
        model,
        workoutBuilder,
        exercise: { fields, categories },
        client: { workingClientDetail },
      },
    } = getState();

    const { oldExerciseId, newExercise } = data;

    // update training sets of all supersets inside workout  if the fields was changed
    const workout = denormalize(workoutBuilder.get('selectedWorkout'), Workout, model).toJS();
    _.forEach(workout.sections, section => {
      const isIntervalSection = section.format === SECTION_FORMAT_KEY.INTERVAL;
      _.forEach(section.exercises, exercise => {
        _.forEach(exercise.supersets, (superset, index) => {
          const exerciseLibraryId = _.get(superset, 'exercise._id', _.get(superset, 'exercise_instance.exercise', ''));

          _.forEach(superset.alternatives, (altEx, i) => {
            if (altEx._id === oldExerciseId) {
              superset.alternatives[i] = { ...newExercise };
            }
          });

          if (exerciseLibraryId && exerciseLibraryId === oldExerciseId) {
            exercise.supersets[index].exercise_instance.fields = isIntervalSection
              ? initIntervalExerciseFields(newExercise, categories, fields)
              : newExercise.fields;
            const oldSets = superset.training_sets;
            const newFields = superset.exercise_instance.fields;
            const initSetParams = {
              oldSets,
              newFields,
              systemFields: fields,
              units: workingClientDetail ? workingClientDetail.preferences : preferences,
            };

            exercise.supersets[index].training_sets = initSetsData(initSetParams, isIntervalSection);
            exercise.supersets[index].exercise_instance.title = newExercise.title;
            exercise.supersets[index].exercise_instance.exercise = newExercise._id;
            exercise.supersets[index].exercise = { ...newExercise };
          }
        });
      });
    });

    const normailized = normalize(workout, Workout);
    dispatch(updateEntities(normailized.entities));
  };
};

export const changeOrderExercise = params => {
  return (dispatch, getState) => {
    const { sectionId, exerciseId, fromIndex, toIndex } = params;
    const currentState = getState();
    const fromSection = currentState.rootReducer.model.getIn([ENTITY_NAME.Section, sectionId]).toJS();

    fromSection.exercises.splice(fromIndex, 1);
    fromSection.exercises.splice(toIndex, 0, exerciseId);
    dispatch(updateEntity(ENTITY_NAME.Section, sectionId, fromSection));
  };
};

export const changeOrderExerciseInSection = params => {
  return (dispatch, getState) => {
    const { exerciseId, fromIndex, toIndex } = params;
    const {
      rootReducer: { workoutBuilder },
    } = getState();
    const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
    sections.splice(fromIndex, 1);
    sections.splice(toIndex, 0, exerciseId);
    dispatch(updateSections(sections));
  };
};

export const changeOrderSection = params => {
  return (dispatch, getState) => {
    const { sectionId, fromIndex, toIndex } = params;
    const currentState = getState();
    const selectedWorkout = currentState.rootReducer.workoutBuilder.get('selectedWorkout').toJS();

    selectedWorkout.sections.splice(fromIndex, 1);
    selectedWorkout.sections.splice(toIndex, 0, sectionId);
    dispatch(updateSections(selectedWorkout.sections));
  };
};

export const adddSectionFromLibrary = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: { workoutBuilder },
    } = getState();
    const sectionWithNewID = { ...data, _id: mongoObjectId() };
    const sections = workoutBuilder.getIn(['selectedWorkout', 'sections']).toJS();
    sections.push(localSectionId(sectionWithNewID));
    const normalized = normalize(sectionWithNewID, Section);
    dispatch(addEntities(normalized.entities));
    dispatch(updateSections(sections));
  };
};

export const getOneRepMax = data => {
  return (dispatch, getState) => {
    const {
      router: { location },
    } = getState();

    if (!location.pathname.startsWith('/home/client/')) {
      return;
    }
    const [, , , client] = location.pathname.split('/');

    return dispatch(
      Request.get(
        { url: `/api/exercise/get-one-rep-max`, params: { ...data, client, includeRecord: true } },
        true,
        (response, { dispatch }) => {
          if (response && response.data && response.data.data && response.data.data.value) {
            const oneRM = {
              ...response.data.data,
              id: [client, data.exercise].join('_'),
            };
            const normailized = normalize(oneRM, OneRepMax);
            dispatch(addEntities(normailized.entities));
          }
        },
      ),
    );
  };
};

export const getPdf = (workoutData, pdfType, target_user_unit) => {
  let bodyData = { [pdfType]: workoutData._id, target_user_unit };

  return (dispatch, getState, history) => {
    return dispatch(
      Request.post({ url: `/api/export-pdf/export-workout`, data: bodyData }, false, response => {
        const url = response.data.data.url;

        window.open(url, '_blank');

        dispatch({
          type: 'PDF_SUCCESS',
          payload: url,
        });
      }),
    );
  };
};

const checkValidationSection = params => {
  let { originalWorkout, workout, workingClientDetail, user, fields, dispatch } = params;
  const { FREESTYLE } = SECTION_FORMAT_KEY;

  _.forEach(workout.sections, section => {
    _.forEach(section.exercises, exercise => {
      exercise.supersets = _.filter(exercise.supersets, superset => !!_.get(superset, 'exercise_instance.fields'));
    });
    section.exercises = _.filter(section.exercises, exercise => !!exercise.supersets.length);
  });
  workout.sections = _.filter(workout.sections, s => !!s.exercises.length || s.format === FREESTYLE);

  if (!_.isEqual(workout, originalWorkout)) {
    const normailized = normalize(workout, Workout);
    // dispatch(updateSections(_.map(workout.sections, section => localSectionId(section)))); // TODO - DO NOT RESET DATA
    dispatch(updateEntities(normailized.entities));
    dispatch(resetDiscardChange());
  }

  let isValid = true;

  if (!workout.sections.length || !workout.title) {
    dispatch(showError());
    return null;
  }

  _.forEach(workout.sections, section => {
    if (section.format === FREESTYLE) {
      if (section.note.length === 0) isValid = false;
      return isValid;
    }

    if (!section.exercises.length && section.format !== FREESTYLE) {
      isValid = false;
      return isValid;
    }

    if (section.type !== HIDDEN_SECTION) {
      if (
        (section.format === SECTION_FORMAT_KEY.AMRAP && !parseFloat(section.time)) ||
        (section.format === SECTION_FORMAT_KEY.TIMED && !parseInt(section.round))
      ) {
        isValid = false;
        return false;
      }
    }
  });

  if (!isValid) {
    dispatch(showError());
    return null;
  }

  const normailized = normalize(workout, Workout);

  _.forEach(normailized.entities[ENTITY_NAME.Superset], superset => {
    if (!superset.exercise_instance || !superset.exercise_instance.fields) {
      isValid = false;
      return false;
    }
  });

  if (!isValid) {
    dispatch(showError());
    return null;
  }

  _.forEach(normailized.entities[ENTITY_NAME.TrainingSet], set => {
    if (set.hasOwnProperty('orm')) {
      if (!set.orm || isNaN(parseInt(set.orm.value))) {
        isValid = false;
        return false;
      }
    }
    if (_.get(set, 'reps.value') === false) {
      isValid = false;
      return false;
    }
  });

  if (!isValid) {
    dispatch(showError());
    return null;
  }

  const preferences = _.get(workingClientDetail, 'preferences', user.preferences);
  let workoutData = [];

  workout.sections = _.forEach(workout.sections, section => {
    section.exercises = toExercisesRequestData(section.exercises, preferences, fields);
    section = handleFormatFreestyleData(section);
    workoutData.push(section);
  });

  workout.sections = workoutData;
  return workout;
};

export const validateSectionData = () => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        workoutBuilder,
        model,
        client: { workingClientDetail },
        exercise: { fields },
      },
    } = getState();
    const originalWorkout = denormalize(workoutBuilder.get('selectedWorkout'), Workout, model).toJS();
    let workout = _.cloneDeep(originalWorkout);

    const params = {
      originalWorkout,
      workout,
      workingClientDetail,
      user,
      fields,
      dispatch,
    };

    const isSectionEmpty = _.get(originalWorkout, 'sections', []).filter(
      section =>
        section.format !== SECTION_FORMAT_KEY.FREESTYLE &&
        section.format !== SECTION_FORMAT_KEY.HIDDEN_SECTION &&
        _.get(section, 'exercises', []).length <= 0,
    );

    const isSectionEmptyDuration = _.get(originalWorkout, 'sections', []).filter(
      section => section.format === SECTION_FORMAT_KEY.AMRAP && _.get(section, 'time', []).length <= 0,
    );

    const isSectionEmptyRound = _.get(originalWorkout, 'sections', []).filter(
      section => section.format === SECTION_FORMAT_KEY.TIMED && _.get(section, 'round', []).length <= 0,
    );

    if (isSectionEmpty.length) {
      toast.error('Please add at lease 1 exercise');
    }

    if (isSectionEmptyDuration.length) {
      toast.error('Please set a duration for the AMRAP section');
    }

    if (isSectionEmptyRound.length) {
      toast.error('Please set number of rounds for the Timed section');
    }

    workout = checkValidationSection(params);

    if (emptyInstructions(originalWorkout) && !isSectionEmpty.length) {
      toast.error('Please add instructions');
    }

    if (workout && !isSectionEmpty.length) {
      dispatch(showError(false));
      return {
        _id: workout._id,
        title: workout.title,
        description: workout.description,
        background: workout.background,
        author: _.get(workout, 'author'),
        share: workout.share || TEAM_SHARE_PRIVATE,
        sections: workout.sections,
        tags: _.get(workout, 'tags', []).map(tag => tag._id),
      };
    } else {
      dispatch(showError());
      return null;
    }
  };
};

export const resetDiscardChange = () => ({
  type: Types.RESET_DISCARD_CHANGE,
});

export const updateTab = data => ({ type: Types.WORKOUT_BUILDER_UPDATE_TAB, payload: { data } });

export const getExercisesByIds = (params = {}) => (dispatch, getState) => {
  const { ids = [], superset = {}, isUpdateEntities = false } = params;

  return dispatch(
    Request.post({ url: '/api/exercise/exercises-by-ids', data: { ids } }, true, response => {
      const { data: { data = [] } = {} } = response;

      if (!_.isEmpty(data)) {
        const normalizedExerciseLibrary = normalize(data, [ExerciseLibrary]);
        dispatch(addEntities(normalizedExerciseLibrary.entities));

        if (isUpdateEntities && superset) {
          superset.alternatives = ids;

          const normalizedSuperSet = normalize(superset, SuperSet);
          dispatch(updateEntities(normalizedSuperSet.entities));
        }
      }
    }),
  );
};
