import _ from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = {
  list: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  let list = state.list.slice(),
    index;
  const { payload } = action;
  switch (action.type) {
    case Types.SUCCESS_GET_ALL_GROUPS:
      if (payload.list) {
        list = payload.list;
      }

      return Object.assign({}, state, { list, loading: false });

    case Types.SUCCESS_ADD_NEW_GROUP:
      if (payload.group) {
        list.push(payload.group);
        list = _.orderBy(_.uniqBy(list, '_id'), ['name'], ['desc']);
      }

      return Object.assign({}, state, { list });

    case Types.SUCCESS_UPDATE_GROUP:
      if (payload.group) {
        index = _.findIndex(list, item => item._id === payload.group._id);

        if (index !== -1) {
          list[index] = payload.group;
        }
      }

      return Object.assign({}, state, { list });

    case Types.SUCCESS_DELETE_GROUP:
      if (payload.groupId) {
        _.remove(list, item => item._id === payload.groupId);
      }

      return Object.assign({}, state, { list });

    case Types.SUCCESS_ASSIGN_CLIENTS:
      if (payload.groups && payload.groups.length) {
        _.forEach(payload.groups, item => {
          const index = _.findIndex(list, g => g._id === item._id);
          if (index !== -1) {
            list[index] = item;
          }
        });
      }

      return Object.assign({}, state, { list });

    case Types.GET_ALL_GROUPS:
      return Object.assign({}, state, { loading: true });
    case Types.FAILED_GET_ALL_GROUPS:
      return Object.assign({}, state, { loading: false });

    default:
      return state;
  }
};
