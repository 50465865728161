import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
  color: #202353;
`;

export const GetMealPlanAddOn = styled.button`
  ${baseText}
  line-height: 20px;
  color: #a36b06;
  font-weight: 600;
  font-size: 13px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ffbe49;
  display: flex;
  align-items: center;
  padding: 9px;
  gap: 7px;
  background: #fffaf0;
  position: relative;
  cursor: pointer;
  .icon-info {
    width: 16px;
    height: 16px;
    path {
      fill: #ffbe49;
    }
  }
  .features__tooltipUpgradePath.custom-upgrade-path-popup {
    display: none;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    padding-top: 17px;
    padding-bottom: 0;
    .tooltip_box {
      width: 281px;
      -webkit-filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      -webkit-filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      box-shadow: unset;
      border: unset;
    }
    .tooltip_box:before {
      content: '';
      cursor: default;
      background-color: inherit;
      width: 16px;
      height: 16px;
      border: unset;
      top: -4px;
      bottom: unset;
      left: 50%;
      -webkit-transform: translateX(-50%) rotate(45deg);
      -ms-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
    }
    .tooltip__description {
      font-family: 'Open Sans';
      margin-top: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      width: 100%;
    }
    .tooltip__upgradePathBtn {
      border-radius: 5px;
      padding: 6px 30px;
      span {
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  :hover {
    .features__tooltipUpgradePath.custom-upgrade-path-popup {
      display: block;
    }
  }
`;
