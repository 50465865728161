import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export const ModalWrapper = styled(Modal)`
  display: flex;
  flex-direction: row;
  &.ui.modal {
    border-radius: 8px;
    width: 967px;
    max-width: 967px;
    height: 650px;
    max-height: 650px;
    padding: 0;

    .confirm-footer {
      padding: 30px 0 0;

      button {
        padding: 7px 42px;
      }
    }

    @media (max-height: 700px) {
      max-height: calc(100vh - 48px);
    }
  }

  &.ui.modal > :last-child {
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const FilterStyles = styled.div`
  padding: 5px;
  background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon.svg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #a6acc6;
  cursor: pointer;
  width: 28px;
  height: 28px;

  :hover {
    background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon_hover.svg);
    background-color: #eaebff;
    border-radius: 3px;
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
