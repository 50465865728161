import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import {
  assignWorkout,
  fetchAutoflowWorkoutsInRange,
  addWorkout,
  viewWorkoutDetail,
  updateWorkout,
  saveWorkoutToLibrary,
  deleteWorkout,
} from 'redux/autoflow/training/actions';
import { toggleModal } from 'actions/modal';
import ChooseWorkoutModal from 'components/ChooseWorkoutModal';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import { TAGS_TYPE, WORKOUT_AI_BUILDER_PAGES, WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { axiosInstance } from 'configs/request';
import { omitEmptyRequestParams } from 'utils/commonFunction';
import { getMostRecentTagsList } from 'redux/tags/actions';
import CreateWorkoutTemplateAIDemo from 'components/CreateWorkoutTemplateAIDemo';

const DEFAULT_PARAMS = { page: 1, per_page: 10 };

class AssignWorkoutModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: 0,
      workouts: [],
      isCreateNew: false,
      isSubmitting: false,
    };
    this.search = '';
    this.tags = [];
    this.searchDebounce = _.debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.searchWorkout();
    this.props.getMostRecentTagsList({ type: TAGS_TYPE.WORKOUT });
  }

  searchWorkout = searchParams => {
    const q = _.get(searchParams, 'q');
    const tags = _.get(searchParams, 'tags', []);
    const params = omitEmptyRequestParams({
      ...DEFAULT_PARAMS,
      q,
      search_tags: _.join(
        tags.map(tag => tag._id),
        ',',
      ),
    });
    this.setState({ loading: true, searching: true });
    axiosInstance
      .get('/api/workout/v2/list', { params })
      .then(response => {
        const { data, total } = response.data;
        this.setState({
          workouts: data,
          total,
          page: params.page,
          hasMore: data.length < response.data.total,
          loading: false,
          searching: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false, searching: false });
      });
  };

  onSearch = params => {
    this.search = params.q;
    this.tags = params.tags;
    this.searchWorkout(params);
  };

  loadMore = () => {
    const { hasMore, page, isLoading } = this.state;
    const params = omitEmptyRequestParams({
      page: page + 1,
      per_page: DEFAULT_PARAMS.per_page,
      q: this.search,
      search_tags: _.join(
        this.tags.map(tag => tag._id),
        ',',
      ),
    });

    if (hasMore && !isLoading) {
      this.setState({ loading: true });
      axiosInstance
        .get('/api/workout/v2/list', { params })
        .then(response => {
          const { data } = response.data;
          const newWorkouts = _.unionBy(this.state.workouts, data, '_id');
          this.setState({
            workouts: newWorkouts,
            total: response.data.total,
            page: params.page,
            hasMore: newWorkouts.length < response.data.total,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onSelect = workout => {
    const { date } = this.props;
    const params = { day: date.toFormat('MM-dd-yyyy'), workout: workout._id };
    this.setState({ isSubmitting: true });
    this.props
      .assignWorkout(params)
      .then(() => {
        this.props.toggleModal(false);
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
  };

  onClose = () => {
    this.props.toggleModal(false);
  };

  onCreateNew = () => {
    this.setState({ isCreateNew: true });
  };

  createWorkout = async workout => {
    try {
      const response = await this.props.addWorkout(this.props.date, workout);
      return response.data.data;
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  onSavedWithoutClose = assignment => {
    const { autoflowId } = this.props;
    this.props.viewWorkoutDetail(assignment._id);
    this.props.push(`/home/autoflow/${autoflowId}/training/calendar/${assignment._id}/detail`);
  };

  handleUpdateAssignment = async workout => {
    const { updateWorkout } = this.props || {};
    try {
      const response = await updateWorkout(workout);
      return response.data.data;
    } catch (error) {
      return Promise.resolve(null);
    }
  };

  handleSaveToLibrary = workout => {
    const { autoflowId, saveWorkoutToLibrary } = this.props;
    const { _id } = workout || {};
    const params = { id: _id, autoflow: autoflowId };

    saveWorkoutToLibrary(params);
  };

  handleDeleteAssignment = (__, workout) => {
    const { deleteWorkout } = this.props || {};
    deleteWorkout(workout);
  };

  handleEverfitAIClick = () => {
    const { toggleModal } = this.props;
    if (toggleModal) {
      toggleModal(
        true,
        <CreateWorkoutTemplateAIDemo
          onAssignment={this.createWorkout}
          onUpdateAssignment={this.handleUpdateAssignment}
          onSaveToLibrary={this.handleSaveToLibrary}
          onAssignmentDelete={this.handleDeleteAssignment}
          pdfType="autoflow_workout"
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          onSavedWithoutClose={this.onSavedWithoutClose}
          workoutAIPage={WORKOUT_AI_BUILDER_PAGES.autoflow_exact_date}
        />,
      );
    }
  };

  render() {
    const { date, workoutTags, enableAIworkout } = this.props;
    const { isCreateNew, hasMore, loading, searching, total, isSubmitting } = this.state;

    if (isCreateNew) {
      return (
        <WorkoutDetailModal
          key="create-new-assignment-in-autoflow"
          onSave={this.createWorkout}
          onSavedWithoutClose={this.onSavedWithoutClose}
          type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
          fetchWorkoutsInRange={fetchAutoflowWorkoutsInRange}
          date={date.toFormat('MM-dd-yyyy')}
          pdfType="autoflow_workout"
        />
      );
    }

    return (
      <ChooseWorkoutModal
        workouts={this.state.workouts}
        workoutTags={workoutTags}
        onClose={this.onClose}
        onSearch={params => {
          this.search = params.q;
          this.tags = params.tags;
          this.searchDebounce(params);
        }}
        loadMore={this.loadMore}
        isLoading={loading}
        searching={searching}
        total={total}
        hasMore={hasMore}
        onSelect={this.onSelect}
        onCreateNew={this.onCreateNew}
        title="Find a Workout"
        displayOwner
        displayLastUsed
        disableSelectButton={isSubmitting}
        onEverfitAIClick={enableAIworkout ? this.handleEverfitAIClick : undefined}
      />
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      autoflow: { common },
      workoutTags,
      permission,
    },
  } = state;
  const enableAIAllWorkoutBuilder = process.env.REACT_APP_AI_ENABLE_ALL_WORKOUT_BUILDER || false;
  const enableAIworkout = enableAIAllWorkoutBuilder && _.get(permission, 'workout_builder_ai', false);
  return {
    autoflowId: _.get(common, 'workingAutoflow._id'),
    workoutTags,
    enableAIworkout,
  };
};

const mapDispatch = dispatch => {
  return {
    assignWorkout: bindActionCreators(assignWorkout, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    push: bindActionCreators(push, dispatch),
    addWorkout: bindActionCreators(addWorkout, dispatch),
    updateWorkout: bindActionCreators(updateWorkout, dispatch),
    saveWorkoutToLibrary: bindActionCreators(saveWorkoutToLibrary, dispatch),
    deleteWorkout: bindActionCreators(deleteWorkout, dispatch),
    viewWorkoutDetail: bindActionCreators(viewWorkoutDetail, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
  };
};

export default connect(mapState, mapDispatch)(AssignWorkoutModal);
