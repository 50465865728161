import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import { ReactComponent as DragIcon } from 'assets/icons/drag-icon.svg';
import { ThumbnailStyle } from '../styles';
import { ExerciseItemWrapper, CustomTag } from './style';
import { onDragLeftPanelStart } from 'redux/workout-builder/actions';
import { ENTITY_NAME } from 'database/constants';
import ExerciseOverview from './ExerciseOverview';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { requestLogInfo, getYoutubeThumbnail } from 'utils/commonFunction';

function ExerciseItem(props) {
  const [openOverview, setOpenOverview] = useState(false);
  const [checkedThumbnail, setThumbnail] = useState(null);

  const { model, displayType } = props;

  if (!model) {
    const { displayType, exerciseId } = props;
    requestLogInfo('Exercise error: ', { displayType, exerciseId });
    return null;
  }
  const exercise = model.toJS();

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        let checkThumbnail = await getYoutubeThumbnail(exercise);
        setThumbnail(checkThumbnail);
      } catch (error) {
        console.error(error);
      }
    };

    fetchThumbnail();
  }, [model]);

  const onDragStart = e => {
    props.onDragLeftPanelStart({
      dragType: 'exercise',
      exercise,
    });
  };

  const onDragEnd = e => {
    props.onDragLeftPanelStart(null);
  };

  const onOpenOverview = () => {
    setOpenOverview(true);
  };

  const onClosePopup = () => {
    setOpenOverview(false);
  };

  const thumbnail = checkedThumbnail || (exercise.picture || [])[0];

  return (
    <ExerciseItemWrapper
      draggable
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      className={classnames(`exercise-item ${displayType}`)}
      key={exercise._id}
      onClick={onOpenOverview}
    >
      <ThumbnailStyle
        className="exerciseThumbnail"
        thumbnail={thumbnail}
        isVideo={!!exercise.video || !!exercise.videoLink}
      >
        {thumbnail ? <S3ImageWithFallback src={[thumbnail]} cover fluid /> : null}
      </ThumbnailStyle>
      <div className="exercise-item__title">
        <div className={classnames('exercise-item__title--text', { custom: exercise.custom })}>{exercise.title}</div>
        {displayType === 'list' && exercise.custom && <CustomTag>Custom</CustomTag>}
      </div>
      {displayType !== 'list' && exercise.custom && <CustomTag>Custom</CustomTag>}
      {displayType === 'list' && <DragIcon className="drag-icon" />}
      {openOverview && <ExerciseOverview exercise={exercise} onClose={onClosePopup} />}
    </ExerciseItemWrapper>
  );
}

const mapState = (state, ownProps) => {
  const { rootReducer } = state;
  const model = rootReducer.model.getIn([ENTITY_NAME.ExerciseLibrary, ownProps.exerciseId]);

  return { model };
};

const mapDispatch = dispatch => ({
  onDragLeftPanelStart: bindActionCreators(onDragLeftPanelStart, dispatch),
});

export default connect(mapState, mapDispatch)(ExerciseItem);
