import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import * as Layout from 'shared/ModalLayout';

export const ModalWrapper = styled(Modal)`
  width: 1009px !important;
  height: 635px !important;
  border-radius: 8px !important;
  .content {
    padding: 0 !important;
  }
`;

export const ModalFooter = styled(Modal.Actions)`
  padding: 10px 30px;
  height: 56px;
  text-align: right;
  background: #ffffff;
  border-top: 1px solid #f0f0f0 !important;

  ${Button} {
    width: 92px;
    margin-top: -1px;
    height: 32px;
    max-height: 32px;
    margin-right: 12px;
    background: #5158cf;

    :hover {
      background-color: rgb(81, 88, 207, 0.9);
    }

    font-weight: 600;
    font-size: 11px;
    line-height: 16px;

    :disabled {
      cursor: not-allowed;
      opacity: 1;
      background-color: #c4c4c4 !important;
    }
  }
`;

export const Message = styled.p`
  margin: 0;
  max-width: 325px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;

export const HeaderIcon = styled(Layout.HeaderIcon)``;

export const BackButton = styled(Layout.Header)`
  padding: 32px 32px 24px 32px;
  align-items: center;
  margin-bottom: 0;
`;

export const Content = styled(Layout.Content)`
  height: 579px;
  display: flex;
  margin-bottom: 0;
`;

export const LeftContent = styled.div`
  width: 292px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FilterWrapper = styled.div`
  width: 292px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FilterTitle = styled.div`
  margin-left: 30px;
  font-size: 16px;
  font-weight: 700;
`;

export const MiddleContent = styled.div`
  height: 579px;
  width: 367px;
  display: flex;
  flex-direction: column;
  background: #f3f5f8;
  align-items: center;
  .search-task {
    width: 303px;
    height: 56px;
    border-radius: 8px;
    margin: 32px 0 24px 0;
    input {
      padding: 17px 35px 17px 52px;
      font-size: 15px;
      line-height: 20px;
      color: #111111;
      border-radius: 8px;
      background-color: #ffffff;
      border: 1px solid #ecf0f8;

      ::placeholder {
        font-size: 15px;
        line-height: 20px;
        color: #b0b2bd;
      }
    }
  }
`;

export const TasksWrapper = styled.div`
  height: 100%;
  display: flex;
  margin-right: 8px;
  padding: 0 20px 0 32px;
  flex-direction: column;
  background: #f3f5f8;
  align-items: center;
  overflow-y: scroll;

  .loading {
    padding-top: 0;
  }

  ::-webkit-scrollbar {
    width: 3px !important;
  }
`;

export const NotFound = styled.div`
  display: flex;
  color: #9a9da6;
  font-size: 13px;
  font-weight: 400;
  align-items: center;
  height: 500px;
`;

export const PreviewWrapper = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Actions = styled(Layout.Actions)`
  button {
    &.button--remove-team-member {
      :disabled {
        background-color: #c4c4c4;
        opacity: 1;
      }
    }
  }
`;

export const Wrapper = styled(Layout.Wrapper)`
  padding: 0;

  .back-icon {
    svg path {
      stroke: #000 !important;
    }
    margin-right: 8px;
    cursor: pointer;
  }

  .task-icon {
    margin-right: 12px;
    margin-left: 3px;
    width: 30px;
    height: 30px;
  }
`;

export const TaskInfor = styled.div`
  display: flex;
  align-items: center;
  width: 303px;
  height: 62px;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 4px 16px rgba(33, 81, 154, 0.06);
  border-radius: 8px;
  padding: 15px 12px 15px 12px;
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    border: 1px solid #7773ff;
    box-sizing: border-box;
  }

  &.active {
    border: 1px solid #7773ff;
    color: #3c39a3 !important;
    background: #f6f5ff;
    box-shadow: 0px 4px 8px #f1f2f6;
  }

  .owner-avatar {
    opacity: 1;
  }
`;

export const Infor = styled.div`
  padding-right: 35px;
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;

export const TaskTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  max-width: 270px;
  word-break: break-word;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const HeaderFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding: 0 30px 0 30px;
`;

export const ClearSelection = styled.button`
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5c5bbd;
  padding: 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
