import React from 'react';
import * as S from './style';

export default ({ page, perPage, totalPage, onNext, onPrev }) => {
  let lastItem = page * perPage;
  const lastPage = Math.ceil(totalPage / perPage);

  if (lastItem > totalPage) {
    lastItem = totalPage;
  }

  if (!totalPage || lastPage < 2) {
    return <S.Wrapper className="evfPagination evfPagination--onlyOnePage" />;
  }

  return (
    <S.Wrapper className="evfPagination">
      <S.Container>
        <S.Arrow
          className="previous"
          disabled={page === 1}
          onClick={() => {
            if (page > 1) {
              typeof onPrev === 'function' && onPrev(page - 1);
            }
          }}
        />
        <S.Text>
          <span className="highlight">
            {page * perPage + 1 - perPage} - {lastItem}
          </span>{' '}
          of {totalPage}
        </S.Text>
        <S.Arrow
          className="next"
          disabled={page === lastPage}
          onClick={() => {
            if (page < lastPage) {
              typeof onPrev === 'function' && onNext(page + 1);
            }
          }}
        />
      </S.Container>
    </S.Wrapper>
  );
};
