import React from 'react';

import * as S from './style';

const MainPanelSkeleton = () => {
  return (
    <S.MainPanelSkeletonWrapper>
      <div className="skeleton-thumbnail" />
      <div className="skeleton-info">
        <div className="skeleton-name" />
        <div className="skeleton-macro-nutrient" />
        <div className="skeleton-description" />
        <div className="skeleton-dietary-list">
          {Array(3)
            .fill(null)
            .map((_, index) => {
              return <div key={`skeleton-${index}`} className="skeleton-dietary" />;
            })}
        </div>
      </div>
    </S.MainPanelSkeletonWrapper>
  );
};

export default MainPanelSkeleton;
