import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';

import Request from 'configs/request';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

export const Types = {
  CHANGE_ONBOARDING_TAB: 'CHANGE_ONBOARDING_TAB',
  CHANGE_ONBOARDING_QUERY: 'CHANGE_ONBOARDING_QUERY',
  RESET_ONBOARDING_QUERY: 'RESET_ONBOARDING_QUERY',
  ONBOARDING_FLOW_LIBRARY_CREATE_REQUEST: 'ONBOARDING_FLOW_LIBRARY_CREATE_REQUEST',
  ONBOARDING_FLOW_LIBRARY_CREATE_SUCCESS: 'ONBOARDING_FLOW_LIBRARY_CREATE_SUCCESS',
  ONBOARDING_FLOW_LIBRARY_CREATE_FAILED: 'ONBOARDING_FLOW_LIBRARY_CREATE_FAILED',
  ONBOARDING_FLOW_LIBRARY_FETCH_REQUEST: 'ONBOARDING_FLOW_LIBRARY_FETCH_REQUEST',
  ONBOARDING_FLOW_LIBRARY_FETCH_SUCCESS: 'ONBOARDING_FLOW_LIBRARY_FETCH_SUCCESS',
  ONBOARDING_FLOW_LIBRARY_FETCH_FAILED: 'ONBOARDING_FLOW_LIBRARY_FETCH_FAILED',
  ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_REQUEST: 'ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_REQUEST',
  ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_SUCCESS: 'ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_SUCCESS',
  ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_FAILED: 'ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_FAILED',
  ONBOARDING_FLOW_LIBRARY_DELETE_REQUEST: 'ONBOARDING_FLOW_LIBRARY_DELETE_REQUEST',
  ONBOARDING_FLOW_LIBRARY_DELETE_SUCCESS: 'ONBOARDING_FLOW_LIBRARY_DELETE_SUCCESS',
  ONBOARDING_FLOW_LIBRARY_DELETE_FAILED: 'ONBOARDING_FLOW_LIBRARY_DELETE_FAILED',
  ONBOARDING_FLOW_LIBRARY_DUPLICATE_REQUEST: 'ONBOARDING_FLOW_LIBRARY_DUPLICATE_REQUEST',
  ONBOARDING_FLOW_LIBRARY_DUPLICATE_SUCCESS: 'ONBOARDING_FLOW_LIBRARY_DUPLICATE_SUCCESS',
  ONBOARDING_FLOW_LIBRARY_DUPLICATE_FAILED: 'ONBOARDING_FLOW_LIBRARY_DUPLICATE_FAILED',
  ONBOARDING_FLOW_LIBRARY_ARCHIVE_REQUEST: 'ONBOARDING_FLOW_LIBRARY_ARCHIVE_REQUEST',
  ONBOARDING_FLOW_LIBRARY_ARCHIVE_SUCCESS: 'ONBOARDING_FLOW_LIBRARY_ARCHIVE_SUCCESS',
  ONBOARDING_FLOW_LIBRARY_ARCHIVE_FAILED: 'ONBOARDING_FLOW_LIBRARY_ARCHIVE_FAILED',
  ONBOARDING_FLOW_LIBRARY_UNARCHIVE_REQUEST: 'ONBOARDING_FLOW_LIBRARY_UNARCHIVE_REQUEST',
  ONBOARDING_FLOW_LIBRARY_UNARCHIVE_SUCCESS: 'ONBOARDING_FLOW_LIBRARY_UNARCHIVE_SUCCESS',
  ONBOARDING_FLOW_LIBRARY_UNARCHIVE_FAILED: 'ONBOARDING_FLOW_LIBRARY_UNARCHIVE_FAILED',
};

export const changeOnboardingTab = tab => ({
  type: Types.CHANGE_ONBOARDING_TAB,
  payload: { tab },
});

export const changeOnboardingQuery = query => {
  return dispatch => {
    dispatch({
      type: Types.CHANGE_ONBOARDING_QUERY,
      payload: { query },
    });
  };
};

export const resetOnboardingFilter = () => {
  return dispatch => {
    dispatch({
      type: Types.RESET_ONBOARDING_QUERY,
    });
  };
};

export const createOnboardingFlow = (data, callback) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const userId = (user || {})._id;
    const ownerId = (data || {}).owner || (data || {}).author;
    const dataShare = (data || {}).share;

    const isRedirect =
      userId === ownerId ||
      dataShare === TEAM_SHARE_NOOWNER ||
      (userId !== ownerId && dataShare !== TEAM_SHARE_PRIVATE);

    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_CREATE_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flows/save-draft`,
          data,
        },
        false,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});

          if (!isEmpty(data)) {
            dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_CREATE_SUCCESS });
            if (isRedirect) {
              dispatch(push(`/home/onboarding-flow/${(data || {})._id}`));
            }
            callback && callback();
          }
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_CREATE_FAILED });
        },
      ),
    );
  };
};

export const fetchOnboardingLibrary = query => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_FETCH_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/onboarding-flows`,
          params: query,
        },
        true,
        (response, { dispatch }) => {
          const { data = [], total, default_onboarding_flows = [] } = get(response, 'data.data', {});

          dispatch({
            type: Types.ONBOARDING_FLOW_LIBRARY_FETCH_SUCCESS,
            payload: { data, total, default_onboarding_flows },
          });
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_FETCH_FAILED });
        },
      ),
    );
  };
};

export const updateOnboardingFlowSharingStatus = (id, data, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        onboardingLibrary: { filters },
      },
    } = getState();

    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flows/${id}/share`,
          params: data,
        },
        false,
        (response, { dispatch }) => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_SUCCESS });
          dispatch(fetchOnboardingLibrary(filters));
          typeof callback === 'function' && callback();
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_FAILED });
        },
      ),
    );
  };
};

export const deleteOnboardingFlow = (id, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        onboardingLibrary: { filters },
      },
    } = getState();

    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_DELETE_REQUEST });

    return dispatch(
      Request.delete(
        {
          url: `/api/onboarding-flows/${id}`,
        },
        false,
        (response, { dispatch }) => {
          const { success } = get(response, 'data.data', {});
          if (success) {
            dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_DELETE_SUCCESS });
            dispatch(fetchOnboardingLibrary(filters));
            typeof callback === 'function' && callback();
          }
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_DELETE_FAILED });
        },
      ),
    );
  };
};

export const duplicateOnboardingFlow = (id, data) => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_DUPLICATE_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flows/${id}/duplicate`,
          data,
        },
        false,
        (response, { dispatch }) => {
          const { _id } = get(response, 'data.data', {});
          if (_id) {
            dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_DUPLICATE_SUCCESS });
            dispatch(push(`/home/onboarding-flow/${_id}`));
          }
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_DUPLICATE_FAILED });
        },
      ),
    );
  };
};

export const archiveOnboardingFlow = (id, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        onboardingLibrary: { filters },
      },
    } = getState();

    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_ARCHIVE_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flows/${id}/archive`,
        },
        false,
        (response, { dispatch }) => {
          const { success } = get(response, 'data.data', {});
          if (success) {
            dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_ARCHIVE_SUCCESS });
            dispatch(fetchOnboardingLibrary(filters));
            typeof callback === 'function' && callback();
          }
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_ARCHIVE_FAILED });
        },
      ),
    );
  };
};

export const unarchiveOnboardingFlow = (id, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        onboardingLibrary: { filters },
      },
    } = getState();

    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_UNARCHIVE_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/onboarding-flows/${id}/unarchive`,
        },
        false,
        (response, { dispatch }) => {
          const { success } = get(response, 'data.data', {});
          if (success) {
            dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_UNARCHIVE_SUCCESS });
            dispatch(fetchOnboardingLibrary(filters));
            typeof callback === 'function' && callback();
          }
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_UNARCHIVE_FAILED });
        },
      ),
    );
  };
};

export const fetchDefaultOnboardingLibrary = query => {
  return dispatch => {
    dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_FETCH_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/onboarding-flows/get-teammates`,
          params: query,
        },
        false,
        (response, { dispatch }) => {
          const { data = [], total } = get(response, 'data', {});
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_FETCH_SUCCESS, payload: { data, total } });
        },
        error => {
          dispatch({ type: Types.ONBOARDING_FLOW_LIBRARY_FETCH_FAILED });
        },
        false,
      ),
    );
  };
};
