import React from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import ConfirmModal from 'shared/ConfirmModal';
import { Modal } from 'semantic-ui-react';
import { Button, SelectFile } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import './style.scss';
import {
  createObjectURL,
  revokeObjectURL,
  handleCutString,
  mediaLog,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import FileUpload from 'shared/FileUpload';
import { ReactComponent as AddImageIcon } from 'assets/icons/add_image_icon.svg';

class WorkoutBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: { ...props.data },
      uploading: false,
      uploadData: {},
    };
    this.deleteItems = [];
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { open, selected } = this.state;
    const { data } = this.props;

    if (open && !prevState.open) {
      if (selected._id !== data._id) {
        this.setState({ selected: { ...data } });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClose = () => {
    // TODO: closing modal with deleted workout background selected will revert to default purple background
    // const { workoutBackgrounds } = this.props;
    // if (!_.find(workoutBackgrounds, this.props.data)) {
    //   this.props.onChange({ ...workoutBackgrounds[0] });
    // }
    this.setState({ open: false });
  };

  onOpen = () => {
    this.setState({ open: true });
  };

  onItemClick(item) {
    const { selected } = this.state;

    if (item._id && item._id !== selected._id) {
      this.setState({ selected: { ...item } });
    }
  }

  onSelectFile = file => {
    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via Choose background',
    });
    const uploadData = { src: createObjectURL(file), file, name: file.name };
    this.setState({ uploadData, uploading: true });
  };

  onUploadSuccess = response => {
    const { uploadData } = this.state;
    const { data } = response.data;
    const { originalname, file_extension } = data;
    mediaLog({
      status: 2,
      name: originalname,
      fileType: file_extension,
      description: `Upload success file via Choose background`,
    });
    const backgroundData = { ...(data.workout_background || {}), src: uploadData.src };
    this.setState({ uploadData: {}, uploading: false, selected: { ...backgroundData } }, () => {
      this.props.addWorkoutBackground(backgroundData);
    });
  };

  onCancelUpload = () => {
    const { uploadData } = this.state;
    revokeObjectURL(uploadData.src);
    this.setState({ uploadData: {}, uploading: false });
  };

  onUploadError = () => {
    this.setState({ uploading: false });
  };

  onSave = () => {
    const { data } = this.props;
    const { selected, uploading } = this.state;

    if (uploading) {
      return false;
    }

    if (selected._id !== data._id) {
      this.props.onChange({ ...selected });
    }

    this.setState({ open: false });
  };

  onRemoveItem = (e, item) => {
    e.stopPropagation();
    if (this.deleteItems.includes(item._id)) {
      return false;
    }

    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete Image?"
        content="Are you sure that you want to remove this image?"
        onConfirm={() => {
          this.deleteItems.push(item._id);
          this.props
            .deleteWorkoutBackground(item._id)
            .then(() => {
              const { workoutBackgrounds } = this.props;
              const { selected } = this.state;

              if (selected._id === item._id) {
                this.setState({ selected: { ...(workoutBackgrounds[0] || {}) } });
              }

              if (item.src.startsWith('blob:')) {
                revokeObjectURL(item.src);
              }
            })
            .finally(() => {
              _.remove(this.deleteItems, id => id === item._id);
            });
        }}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  renderUpload = () => {
    const { uploadData } = this.state;
    const { workoutBackgrounds } = this.props;

    if (uploadData.file) {
      const formData = new FormData();
      formData.append('media', uploadData.file);
      formData.append('is_workout_background', true);

      return (
        <S.Item empty>
          <S.Background background={uploadData.src} />
          <FileUpload
            onSuccess={this.onUploadSuccess}
            onError={this.onUploadError}
            onCancel={this.onCancelUpload}
            configs={{ url: '/api/upload/custom_brand', method: 'post', data: formData }}
            progressRadius={22}
            progressBarWidth={3}
            smallCancelButton
          />
        </S.Item>
      );
    } else if (!_.find(workoutBackgrounds, item => !item.default)) {
      return (
        <S.Item empty data-tip disablehover data-for="tooltip--select-custom-background">
          <SelectFile
            trigger={
              <S.SelectFileTrigger>
                <AddImageIcon />
              </S.SelectFileTrigger>
            }
            onSelect={this.onSelectFile}
            accept="image/png, image/jpeg, image/jpg"
            validateExtentions={['png', 'jpg', 'jpeg']}
            maxSize={25}
          />
          <ReactTooltip className="app-tooltip" id="tooltip--select-custom-background">
            <span>Upload your background</span>
          </ReactTooltip>
        </S.Item>
      );
    }

    return null;
  };

  render() {
    const { open, selected, uploading } = this.state;
    const { data, workoutBackgrounds, cloudfrontList } = this.props;

    return (
      <Modal
        open={open}
        closeOnDimmerClick={false}
        className="modal--custom-workout-background"
        onClose={this.onClose}
        onOpen={this.onOpen}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
        trigger={
          <S.TriggerContainer>
            <S.TriggerImage background={convertS3UrlToCloudFrontUrl(data.src, cloudfrontList, true)} />
            <S.TriggerImageName>{data.name}</S.TriggerImageName>
          </S.TriggerContainer>
        }
      >
        <Modal.Content>
          <S.ContentWrapper>
            <ModalLayout.Header>Choose background</ModalLayout.Header>
            <ModalLayout.Content>
              <S.BackgroundList>
                {_.map(workoutBackgrounds, (item, index) => (
                  <S.Item
                    empty
                    data-tip
                    data-for={`tooltip--choose-background-${index}`}
                    key={index}
                    active={selected._id === item._id}
                    onClick={this.onItemClick.bind(this, item)}
                  >
                    <S.Background background={convertS3UrlToCloudFrontUrl(item.src, cloudfrontList, true)} />
                    {!item.default ? <S.RemoveIcon onClick={e => this.onRemoveItem(e, item)} /> : null}
                    {item.name && (
                      <S.Tooltip className="app-tooltip" id={`tooltip--choose-background-${index}`}>
                        <span>{handleCutString(item.name)}</span>
                      </S.Tooltip>
                    )}
                  </S.Item>
                ))}
                {this.renderUpload()}
              </S.BackgroundList>
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button purple onClick={this.onSave} disabled={uploading}>
                Select
              </Button>
            </ModalLayout.Actions>
          </S.ContentWrapper>
        </Modal.Content>
      </Modal>
    );
  }
}

export default WorkoutBackground;
