import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  background-color: transparent;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  video, img {
    max-height: 80vh;
    max-width: 70vw;
    display: block;
  }
`;