import styled, { css } from 'styled-components';

export const Trigger = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
`;

export const PlusIconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #5e59ff;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 4px 16px rgba(199, 199, 231, 0.85));

  ${props =>
    props.open &&
    css`
      background-color: #5e59ff;
      opacity: 0.9;
    `}

  :hover {
    cursor: pointer;
    background-color: #5e59ff;
    opacity: 0.9;
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  display: none;
  width: 196px;
  max-height: 354px;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 7px 0;
  overflow: auto;
  z-index: 99999;
  top: 81px;
  left: 265px;
  user-select: none;
`;

export const Item = styled.div`
  display: flex;
  padding: 7px 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #202353;
  align-items: center;

  :hover {
    cursor: pointer;
    background: #e5e4ff;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;

      :hover {
        cursor: not-allowed;
        background: transparent;
      }
    `}

  ${props =>
    props.isSelected &&
    !props.disabled &&
    css`
      background: #e5e4ff;
    `}

  svg {
    margin-right: 8px;
  }
`;

export const Wrapper = styled.div`
  ${props =>
    props.open &&
    css`
      ${MenuContainer} {
        display: block;
      }
    `}
`;
