import styled, { css } from 'styled-components';

const Format = styled.div`
  display: grid;
  grid-template-columns: 28% 14% 14% 14% 26%;
  gap: 3%;
  padding: 20px 15px;

  @media screen and (min-width: 1441px) {
    grid-template-columns: 28% 17% 7% 17% 31%;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 60px 0 30px;
  overflow: hidden;
`;

export const TableHeaderWrapper = styled(Format)`
  border-bottom: 1px solid #eee;
`;

export const HeaderItem = styled.div`
  font-size: 11px;
  font-family: 'Open Sans';
  line-height: 16px;
  color: ${props => (props.isActive ? '#5157cf' : '#6a778a')};
  ${props =>
    props.isActive &&
    `
  svg.form-icon path {
    fill: #5157cf;
  }
  `}
  font-weight: 700;
  display: flex;
  align-items: center;
  ${props =>
    props.isCenter &&
    css`
      justify-content: center;
    `}
`;

export const HeaderItemContent = styled.div`
  display: flex;
  align-items: center;
  span {
    padding: 0 5px;
  }

  ${props =>
    props.hasSort &&
    css`
      cursor: pointer;
    `}
`;

export const TableContentWrapper = styled.div`
  height: ${props =>
    props.isHideFormBanner
      ? props.onePage
        ? `calc(100vh - ${props.isVerifyEmail ? '303px' : '265px'})`
        : `calc(100vh - ${props.isVerifyEmail ? '362px' : '324px'})`
      : props.onePage
      ? `calc(100vh - ${props.isVerifyEmail ? '488px' : '450px'})`
      : `calc(100vh - ${props.isVerifyEmail ? '561px' : '523px'})`};
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const ItemWrapper = styled(Format)`
  padding-bottom: 19px;
  border-bottom: 1px solid #eee;

  :hover {
    background-color: rgb(248, 248, 248);
    cursor: pointer;
  }
`;

export const ItemName = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span {
    display: inline-block;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
export const ItemContent = styled.div`
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  line-height: 18px;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemActions = styled(ItemContent)`
  .sb-avatar {
    margin-left: 12px;
    margin-right: 12px;
  }
  .evf-dropdown__menu {
    width: 150px !important;
  }
  .evf-dropdown__option span {
    padding-left: 8px;
  }
  .evf-dropdown__option svg {
    max-width: 16px;
    width: 100%;
  }
  .owner-avatar {
    opacity: 1;
    margin: 0 12px;
    width: 25px;
    height: 25px;

    .sb-avatar--src,
    .sb-avatar--text {
      margin-left: 0;

      span {
        font-size: 11px;
        font-weight: 700;
      }
    }
  }
  .sharing-status {
    margin-right: 6px;
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: #f7cc47;
  margin-right: 15px;
  border-radius: 50%;
  flex-shrink: 0;
  ${props =>
    props.active &&
    css`
      background: #2fd787;
    `}

  ${props =>
    props.archived &&
    css`
      background: #c4c4c4;
    `}
`;

export const NotFound = styled.div`
  text-align: center;
  height: calc(100vh - 460px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${props =>
    props.isHideFormBanner &&
    css`
      height: calc(100vh - 270px);
    `}
  svg {
    width: 82.4px;
    height: 81px;
  }

  .text {
    margin-top: 22px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #9a9da6;
  }
`;

export const NotFoundText = styled.div`
  color: #202353;
  padding: 23px 15px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #9a9da6;
  display: flex;
  align-items: center;
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  padding-top: 120px;
  .ui.loader:before {
    border-color: #ededed;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
  }
`;
