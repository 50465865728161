export const ATTACHMENT_UPLOAD_URL = '/api/file/gen-presigned-urls-custom-brand';

export const VIDEO_VALIDATIONS = {
  maxSizeKB: 300000000,
  maxSizeMB: 300,
  extensions: ['flv', 'mov', 'mp4', 'avi', '3gb'],
  accepts: `.flv, .mov, .mp4, .avi, .3gb`,
  errorMessage: 'Video size limit is 300MB. Please compress your video first or attach a video link instead.',
};

export const ERROR_MESSAGE = {
  INVALID_VIDEO_LINK: 'Please add valid link',
};
