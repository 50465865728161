import React from 'react';
import { Wrapper } from './style';

const OnDemandHeader = props => {
  return (
    <Wrapper>
      <div className="workout-collection__name">
        <span>{props.title || ''}</span>
      </div>
    </Wrapper>
  );
};

export default OnDemandHeader;
