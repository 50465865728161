import React from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { isMobile } from 'react-device-detect';
import smartlookClient from 'smartlook-client';
import LoadingBar from 'react-redux-loading-bar';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ToastContainer, Slide } from 'react-toastify';
import { Route, Redirect, Switch } from 'react-router-dom';

import { hideError, showError } from 'actions/error';
import { getAccessToken, isOwnerWorkspace } from 'utils/commonFunction';
import {
  getAllBodyparts,
  getAllCategories,
  getAllExerciseFields,
  getMostRecentExercises,
  getAllExerciseEquipments,
  getAllExerciseModalities,
  getAllExerciseMuscleGroup,
  getAllExerciseMovementPattern,
} from 'redux/exercise/exercise.actionCreators';
import { countPackages } from 'redux/package-list/actions';
import { getNewestClients } from 'redux/client/client.actionCreators';
import { getUnitCategories } from 'redux/unit/unit.actionCreators';
import { getAllGroups } from 'redux/group/actions';
import { getAllSegments } from 'redux/segment/actions';
import { getDefaultSystemWorkouts } from 'redux/systemWorkout/actions';
import { initPermission } from 'redux/permission/actions';
import { fetchLeaderboardGlobalData, fetchLeaderboardRankingTypes } from 'redux/leaderboard/actions';
import Login from 'components/Login';
import Register from 'components/Register';
import { SignUpWithWorkspaceInviteLink, SignUpWithNormalInviteLink } from 'components/SignUp';
import UnsubscribeEmail from 'components/UnsubscribeEmail';
import ForgotPassword from 'components/ForgotPassword';
import DashboardClient from 'components/Dashboard';
import ClientPage from 'components/ClientPage';
import InboxPage from 'components/Inbox';
import InboxTransferPage from 'components/Inbox/TransferPage';
import WorkoutLibraryContainer from 'components/WorkoutLibrary/container';
import ExerciseLibraryContainer from 'components/ExerciseLibrary/container';
import AccountDetails from 'components/AccountDetails';
import ChangeEmail from 'components/ChangeEmail';
import ChangePassword from 'components/ChangePassword';
import PublicProfile from 'components/PublicProfile';
import Notifications from 'components/Notifications';
import GeneralSettings from 'components/GeneralSettings';
import Teammates from 'components/Teammates';
import BillingHistory from 'components/BillingHistory';
import HomeLayout from 'layouts/home';
import ProgramListCalendarContainer from 'components/ProgramListCalendar/container';
import PlayVideoPage from 'components/PlayVideoPage';
import Welcome from 'components/Welcome';
import Loading from 'components/ListCalendar/Loading';
import {
  ProgramCalendarHeader,
  AutoflowDetailHeader,
  ClientListHeader,
  ClientDetailHeader,
  DefaultSettingsHeader,
  BaseHeader,
  InboxHeader,
  AutoflowListHeader,
  StudioProgramDetailHeader,
  WaitingActivationHeader,
  OnboardingFlowHeader,
  CoachBiosDetailHeader,
} from 'layouts/PageHeader';
import AutoflowList from 'components/AutoflowList';
import AutoflowDetail from 'components/AutoflowDetail';
import DefaultClientSettings from 'components/DefaultSettings';
import RetrictMobileLogin from 'components/RetrictMobileLogin';
import CustomBranding from 'components/CustomBranding';
import BillingSetting from 'components/BillingSetting';
import IntegrationSetting from 'components/IntegrationSetting';
import StudioProgramList from 'components/StudioProgram/List';
import StudioProgramDetail from 'components/StudioProgram/Detail';

import './styles.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import NetworkErrorPopup from 'components/NetworkErrorPopup';
import { isOnwer } from 'utils/validations';
import { handleEnableProduct, enableOnDemand, enableCoachBio } from 'utils/commonFunction';
import PricingPlan from 'components/PricingPlan';
import { initPricingData } from 'redux/pricing/actions';
import ConfirmResetPassword from 'components/Views/ConfirmResetPassword';
import VerifyChangeEmail from 'components/Views/VerifyChangeEmail';
import WarningTrialExpiredPopup from 'shared/UpgradePath/components/WarningTrialExpiredPopup';
import StudioProgramListHeader from 'layouts/PageHeader/StudioProgramListHeader';
import WorkoutCollectionsDetailHeader from 'layouts/PageHeader/WorkoutCollectionDetailHeader';
import WorkoutCollectionCategoryHeader from 'layouts/PageHeader/WorkoutCollectionCategoryHeader';
import OnDemandHeader from 'layouts/PageHeader/OnDemandHeader';
import StudioHeader from 'layouts/PageHeader/StudioHeader';
import StudioCollectionDetailHeader from 'layouts/PageHeader/StudioCollectionDetailHeader';
import PackageDetailHeader from 'layouts/PageHeader/PackageDetailHeader';
import ForumHeader from 'layouts/PageHeader/ForumHeader';
import RedirectPage from 'components/RedirectPage';
import ReferralProgram from 'components/ReferralProgram';
import DashboardNotifications from 'components/DashboardNotifications';
import CompleteRegistration from 'components/CompleteRegistration';
import CommunityForum from 'components/CommunityForum';

import { signInFirebaseWithCustomToken, signOutFirebase } from 'database/firebase';
import StudioResourceList from 'components/StudioResourceList';
import StudioCollectionList from 'components/StudioCollectionList';
import StudioCollectionDetail from 'components/StudioCollectionDetail';
import StudioOnDemandSettings from 'components/StudioOnDemandSettings';
import UpgradePath from 'shared/UpgradePath';
import { bootIntercom } from './helper';
// import { bootDataDogSessionReplay } from './datadog-session-replay';
import WaitingActivationPage from 'components/WaitingActivationClients/WaitingPage';
import PackageList from 'components/Package/PackageList';
import Product from 'components/Product';
import PackageListNavTitle from 'components/Package/PackageList/components/PackageListNavTitle';
import ProductListNavTitle from 'components/Product/ProductListNavTitle';
import ProductNameStatus from 'components/Product/ProductNameStatus';
import SetupPayment from 'components/SetupPayment';
import Activity from 'components/Activity';
import PackageDetail from 'components/Package/PackageDetail';
import WorkoutCollection from 'components/WorkoutCollections';
import OnDemandWorkouts from 'components/OnDemandWorkouts';
import WorkoutCollectionsDetail from 'components/WorkoutCollectionsDetail';
import WorkoutCollectionsCategoryDetail from 'components/WorkoutCollectionsCategoryDetail';
import WorkoutLabels from 'components/WorkoutLabels';
import WorkoutLabelsDetail from 'components/WorkoutLabelsDetail';
import { getUserGeneralSettings } from 'redux/general-settings/actions';
import NewVersionWarningPopup from 'components/NewVersionWarningPopup';
import Forms from 'components/Forms';
import FormDetails from 'components/FormDetails';
import FormHeader from 'layouts/PageHeader/FormHeader';
import SectionLibraryContainer from 'components/SectionLibrary/container';
import TasksLibraryContainer from 'components/TasksLibrary/container';
import CoachBioList from 'components/CoachBios/CoachBioList';
import CoachBioDetail from 'components/CoachBios/CoachBioDetail';
import OnboardingFlowDetail from 'components/OnboardingFlowDetail';
import MealPlans from 'components/MealPlans';
import MealPlanDetailContainer from 'components/MealPlanDetail/container';
import Recipes from 'components/Recipes';
import RecipeDetail from 'components/RecipeDetail';
import RecipeHeader from 'layouts/PageHeader/RecipeHeader';
import MealPlanHeader from 'layouts/PageHeader/MealPlanHeader';
import { closeMonitorSettings, getMonitorSettings } from 'actions/auth/login';
import { getCloudfrontList } from 'actions/cloudfrontList';
import NotificationsHeader from 'components/DashboardNotifications/Header';
import MetricGroupLibrary from 'components/MetricGroupLibrary';
import ProgramLibraryAndTemplates from 'components/ProgramLibraryAndTemplates';
import ProgramLibraryHeader from 'components/ProgramLibraryAndTemplates/Header';
import ProgramTemplates from 'components/ProgramTemplates';
import IngredientLibrary from 'components/IngredientLibrary';
import { getListCategoryIngredient, getListDietary } from 'redux/recipes/actions';
import { getListUnitIngredient } from 'redux/ingredient-library/actions';
import MealPlanHeaderLibrary from 'layouts/PageHeader/MealPlanHeaderLibrary';
import { CDN_URL } from 'constants/commonData';
import VerifyAccount from 'components/VerifyAccount';
import OnboardingFlowLibrary from 'components/OnboardingFlowLibrary';
import OnboardingFlowHeaderDetail from 'layouts/PageHeader/OnboardingFlowHeaderDetail';
import DataDogRumTracking from './DataDogRumTracking';

const PagesWithoutLoader = [
  '/login',
  '/register',
  '/signup',
  '/i',
  '/forgot-password',
  '/verify-account',
  '/unsubscribe-email',
  '/video',
  '/restrict-login',
  '/welcome',
  '/confirm-reset-password',
  '/verify-change-email',
  '/views/verify_change_email',
  '/views/forgot_password',
  '/views/download_ios',
  '/views/download_android',
  '/views/download_ios_coach',
  '/views/download_android_coach',
  '/d/ios',
  '/d/android',
  '/d/coach/ios',
  '/d/coach/android',
  '/complete-registration',
];

const ContinueWithoutTokenPages = [
  '/register',
  '/restrict-login',
  '/verify-change-email',
  '/confirm-reset-password',
  '/views/verify_change_email',
  '/views/forgot_password',
  '/complete-registration',
];

const DownloadLinks = [
  '/views/download_ios',
  '/views/download_android',
  '/views/download_ios_coach',
  '/views/download_android_coach',
  '/d/ios',
  '/d/android',
  '/d/coach/ios',
  '/d/coach/android',
];

const PagesWithoutProfile = ['/video', '/unsubscribe-email', '/signup', '/i'];

const CloseToastButton = ({ closeToast }) => (
  <img
    src={`${CDN_URL}/images/close_white.svg`}
    onClick={closeToast}
    style={{ padding: '5px', width: '20px' }}
    className="Toastify__toast__close-button"
    alt=""
  />
);

export default class App extends React.Component {
  preloadedData = false;

  componentDidMount() {
    const { location, dispatch } = this.props;

    window.addEventListener('beforeunload', e => {
      const { isUploading } = this.props;
      if (isUploading) {
        const message =
          'Uploading media is in progress. If you close the tab, all the progress will be lost. Are you sure you want to proceed with closing the tab?';

        (e || window.event).returnValue = message;

        return message;
      }
      return;
    });

    if (process.env.NODE_ENV === 'production') {
      smartlookClient.init('5f4d02e5a39f7bdd2777282f8ba1b3e7c4657a1f');
    }

    const downloadLink = _.find(DownloadLinks, path => location.pathname.startsWith(path));

    if (downloadLink) {
      return;
    }

    const continueWithoutToken = _.find(ContinueWithoutTokenPages, path => location.pathname.startsWith(path));

    if (continueWithoutToken) {
      this.props.logoutWithoutRedirect();
    } else {
      if (!PagesWithoutProfile.some(url => location.pathname.startsWith(url))) {
        const accessToken = getAccessToken();

        if (accessToken) {
          if (location.pathname.startsWith('/forgot-password') || location.pathname.startsWith('/login')) {
            const params = new URLSearchParams(location.search);
            if (params.get('coupon')) {
              localStorage.setItem('hasCoupon', location.search);
            }
            window.location = '/home/client';
          } else {
            this.props.getProfile();
            this.props.getCustomBrandingData();
            dispatch(getCloudfrontList());
            this.props.dispatch(initPermission());
          }
        } else {
          if (
            !location.pathname.startsWith('/forgot-password') &&
            !location.pathname.startsWith('/login') &&
            !location.pathname.startsWith('/verify-account')
          ) {
            const uri = `/login?prev=${encodeURIComponent(`${location.pathname}${location.search}`)}`;
            signOutFirebase();
            window.location = uri;
          }
        }
      }
    }
    dispatch(getMonitorSettings());
  }

  componentDidUpdate(prevProps) {
    const { dispatch, permission, user, location, logout } = this.props;

    // Check if data needs to be preloaded
    if (this.shouldPreloadData(prevProps)) {
      this.preloadData(user, permission, dispatch, location, logout);
    }

    // Boot Intercom
    this.bootIntercomIfNeeded(prevProps);

    // Check if Intercom needs re-rendering
    this.checkRenderIntercom(prevProps);
  }

  shouldPreloadData = prevProps => {
    const { user, permission } = this.props;

    const hasUserChanged = !_.isEqual(_.omit(prevProps.user, ['favorites']), _.omit(user, ['favorites'])) && user;
    const hasPermissionInitedChanged =
      user && permission.isInited && permission.isInited !== prevProps.permission.isInited;

    return hasUserChanged || hasPermissionInitedChanged;
  };

  preloadData = (user, permission, dispatch, location, logout) => {
    if (!this.preloadedData && (isOnwer(user) || permission.team)) {
      this.preloadedData = true;
      this.identifyThirdPartySDK(user);

      this.dispatchInitialData(dispatch);

      if (permission.recipe) {
        this.dispatchRecipeData(dispatch);
      }

      if (permission.payment || permission.explore_payment) {
        this.loadPaymentData(permission, dispatch);
      }

      this.handleMarketplaceAccess(permission, location, logout, dispatch);

      this.signInToFirebase(user);
    }
  };

  dispatchInitialData = dispatch => {
    const actions = [
      getAllCategories,
      getAllExerciseFields,
      getAllBodyparts,
      getAllExerciseModalities,
      getAllExerciseMuscleGroup,
      getAllExerciseMovementPattern,
      getUnitCategories,
      getAllExerciseEquipments,
      getMostRecentExercises,
      getAllGroups,
      getAllSegments,
      getDefaultSystemWorkouts,
      getNewestClients,
      initPricingData,
      fetchLeaderboardRankingTypes,
      fetchLeaderboardGlobalData,
      getUserGeneralSettings,
      getCloudfrontList,
    ];

    actions.forEach(action => dispatch(action()));
  };

  dispatchRecipeData = dispatch => {
    dispatch(getListDietary());
    dispatch(getListUnitIngredient());
    dispatch(getListCategoryIngredient());
  };

  handleMarketplaceAccess = (permission, location, logout, dispatch) => {
    const accessToken = getAccessToken();
    const { marketplace_payment } = permission || {};

    if (
      accessToken &&
      typeof marketplace_payment === 'boolean' &&
      !marketplace_payment &&
      location.pathname.includes('/marketplace')
    ) {
      if (location.search) {
        logout && logout(location.pathname + location.search);
      } else {
        dispatch(push('/home/packages'));
        this.showMarketplacePermissionError(dispatch);
      }
    }
  };

  showMarketplacePermissionError = dispatch => {
    dispatch(
      showError(
        'Please check with your workspace admin for permission to use this feature',
        null,
        null,
        null,
        null,
        () => dispatch(hideError()),
      ),
    );
  };

  loadPaymentData = (permission, dispatch) => {
    dispatch(countPackages());
    if (permission.marketplace_payment) {
      dispatch(countPackages(true));
    }

    if (permission.payment) {
      this.props.countProducts();
    }
  };

  signInToFirebase = user => {
    const firebase_token = localStorage.getItem('firebase-token');

    if (firebase_token) {
      signInFirebaseWithCustomToken(firebase_token)
        .then(() => {
          this.props.getAllRooms();
          this.props.getTotalUnread(user._id);
        })
        .catch(error => {
          // Handle Firebase authentication issue
        });
    }
  };

  bootIntercomIfNeeded = prevProps => {
    const { user, location } = this.props;
    const pathName = _.get(location, 'pathname', null);
    const isMobileIntroduction = isMobile && !_.isEmpty(pathName) && _.isEqual(pathName, '/onboarding');

    if (
      process.env.REACT_APP_INTERCOM_APP_ID &&
      user &&
      (!prevProps.user || user._id !== prevProps.user._id) &&
      !isMobileIntroduction
    ) {
      bootIntercom(user);
    }
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    window.removeEventListener('beforeunload', e => {});
    dispatch(closeMonitorSettings());
  }

  // Check render intercom when change mode copy week/workout
  checkRenderIntercom = prevProps => {
    const className = 'hasCopyWeekOrWorkout';

    if (this.props.hasCopyWeekOrWorkout !== prevProps.hasCopyWeekOrWorkout) {
      const intercomDom = document.querySelector('.intercom-lightweight-app-launcher');
      const intercomDomContent = document.querySelector('.intercom-messenger-frame');
      const intercomDomOpen = intercomDomContent ? intercomDomContent.nextSibling : null;
      if (intercomDom) {
        if (this.props.hasCopyWeekOrWorkout) {
          intercomDom.classList.add(className);
        } else {
          intercomDom.classList.remove(className);
        }
      }
      if (intercomDomOpen && intercomDomContent) {
        if (this.props.hasCopyWeekOrWorkout) {
          intercomDomOpen.classList.add(className);
          intercomDomContent.classList.add(className);
        } else {
          intercomDomOpen.classList.remove(className);
          intercomDomContent.classList.remove(className);
        }
      }
    }
  };

  handlePermissionPayment = () => {
    const { permission } = this.props;

    if (!permission.explore_payment) {
      if (permission.payment) return true;
      return false;
    }
    return true;
  };

  isEverfitUser(user) {
    // let email = _.get(user, 'profile_email');
    if (_.includes('everfit.io')) {
      return true;
    }
    return false;
  }

  identifyThirdPartySDK(user) {
    if (process.env.NODE_ENV === 'production') {
      smartlookClient.identify(user._id, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        // other custom properties
      });
    }
  }

  render() {
    const {
      location,
      user,
      isGettingProfile,
      permission,
      productDetail,
      nameWorkoutCollectionCategory,
      coachBioDetails,
      onboardingFlowDetail,
      isEnableZapier,
      generalSettings,
      teamData,
    } = this.props;
    const noData = _.find(PagesWithoutLoader, page => location.pathname.startsWith(page));

    const isOwner = user && isOwnerWorkspace(user);

    if ((!user && !noData) || isGettingProfile) {
      return (
        <div>
          <Dimmer active className="dimmer--loading-ui">
            <Loader indeterminate>Getting your data ready</Loader>
          </Dimmer>
          <div className="everfit-preload-images">
            <img alt="" src={`${CDN_URL}/images/welcome.svg`} />
            <img alt="" src={`${CDN_URL}/images/logo_neon.png`} />
            <img alt="" src={`${CDN_URL}/images/client_purple.svg`} />
            <img alt="" src={`${CDN_URL}/images/workout_builder_purple.svg`} />
            <img alt="" src={`${CDN_URL}/images/workout_libary_purple.svg`} />
            <img alt="" src={`${CDN_URL}/images/download_coach_app_bg.jpg`} />
          </div>
        </div>
      );
    }

    if (
      user &&
      isOwner &&
      (!user.is_answers_questionnaire || !user.is_watch_video) &&
      location.pathname !== '/welcome' &&
      location.pathname !== '/' &&
      location.pathname !== '/verify-account'
    ) {
      if (!user.is_answers_questionnaire || !user.is_answers_questionnaire_direct_traffic_lead_source || !isMobile) {
        return <Redirect to={`/welcome${window.location.search}`} />;
      }
    }

    const checkPermission = permission.payment;
    const isHideBanner = user ? (user.is_hide_banner_product ? user.is_hide_banner_product : false) : false;

    /* TODO -  REACT_APP_ENABLE_PRODUCT */
    const isPublic = handleEnableProduct(user);

    // TODO - Is Enable mission
    const isEnableOnDemand = enableOnDemand();

    const isEnableCoachBio = enableCoachBio();

    const hasMP = permission.marketplace_payment;

    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

    return (
      <div className="app-wrapper">
        {this.props.loading && <Loading isOpen />}
        <DataDogRumTracking />
        <LoadingBar
          className="loading"
          style={{
            backgroundColor: '#49ca8d',
            height: '4px',
            zIndex: 2147483102,
            position: 'fixed',
          }}
        />
        <NetworkErrorPopup />
        <NewVersionWarningPopup />
        <WarningTrialExpiredPopup />
        <Switch>
          <Route exact path={'/'} render={this.renderRoot.bind(this)} />
          <HomeLayout
            exact
            path="/home/client"
            component={ClientPage}
            activeItem={'client'}
            title={<ClientListHeader />}
          />
          <HomeLayout
            exact
            path="/home/client/waiting-activation"
            component={WaitingActivationPage}
            activeItem="client"
            title={<WaitingActivationHeader />}
          />
          <HomeLayout
            exact
            path="/home/inbox"
            component={InboxTransferPage}
            activeItem={'inbox'}
            title={<InboxHeader />}
            showHeaderBorder={true}
            hideToggleSidebar={true}
            pageTitle="Inbox"
          />
          <HomeLayout
            exact
            path="/home/inbox/:profileId"
            component={InboxPage}
            activeItem={'inbox'}
            title={<InboxHeader />}
            showHeaderBorder={true}
            hideToggleSidebar={true}
            pageTitle="Inbox"
          />
          <HomeLayout
            path={'/home/client/:clientId'}
            activeItem={'client'}
            title={<ClientDetailHeader />}
            component={DashboardClient}
            showHeaderBorder={true}
            higherHeader
          />
          <HomeLayout
            path="/home/exercise"
            component={ExerciseLibraryContainer}
            activeItem={'exercise_library'}
            title={'Exercise Library'}
            pageTitle="Exercise Library"
          />
          <HomeLayout
            path="/home/workout"
            component={WorkoutLibraryContainer}
            activeItem={'workout_library'}
            title={'Workout Library'}
            pageTitle="Workout Library"
          />
          <HomeLayout
            exact
            path="/home/program/templates"
            component={ProgramTemplates}
            activeItem="program_templates"
            title={<ProgramLibraryHeader />}
            upgradePath="program"
            pageTitle="Program Templates"
          />
          <HomeLayout
            exact
            path="/home/program"
            component={ProgramLibraryAndTemplates}
            activeItem={'program_library'}
            title={<ProgramLibraryHeader />}
            upgradePath="program"
            pageTitle="Program Library"
          />
          {(process.env.REACT_APP_ENABLE_SECTION_LIBRARY || permission.training_section_library) && (
            <HomeLayout
              path="/home/sections"
              component={SectionLibraryContainer}
              activeItem={'section_library'}
              title={'Section Library'}
              pageTitle="Section Library"
            />
          )}
          {this.props.isFormPermission &&
            (process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES ||
              process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) && (
              <HomeLayout
                exact
                path="/home/forms"
                component={Forms}
                activeItem={'forms_library'}
                title={
                  <UpgradePath pathName="form_questionnaire" fallback={'Forms'}>
                    Forms & Questionnaires
                  </UpgradePath>
                }
                upgradePath="" // TODO
                pageTitle="Forms & Questionnaires"
                showHeaderBorder={true}
              />
            )}
          {(process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES ||
            process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) && (
            <HomeLayout
              exact
              path="/home/forms"
              component={Forms}
              activeItem={'forms_library'}
              title={
                <UpgradePath pathName="form_questionnaire" fallback={'Forms'}>
                  Forms & Questionnaires
                </UpgradePath>
              }
              upgradePath="" // TODO
              pageTitle="Forms & Questionnaires"
              showHeaderBorder={true}
            />
          )}

          {/* Meal Plan Templates */}
          <HomeLayout
            exact
            path="/home/meal-plans"
            component={MealPlans}
            activeItem="meal_plans_library"
            title={<MealPlanHeaderLibrary title="Meal Plan Templates" type="meal-plan" />}
            pageTitle="Meal Plan Templates"
            showHeaderBorder={true}
            upgradePath="meal_plan"
          />
          <HomeLayout
            path="/home/meal-plans/:mealPlanId/weeks/:weekId"
            component={MealPlanDetailContainer}
            activeItem="meal_plans_library"
            title={<MealPlanHeader />}
            pageTitle="Meal Plan Templates"
            showHeaderBorder={true}
            upgradePath="meal_plan"
          />
          <HomeLayout
            exact
            path="/home/recipes"
            component={Recipes}
            activeItem="recipes_library"
            title="Recipe Library"
            pageTitle="Recipe Library"
            upgradePath="recipe"
          />
          <HomeLayout
            exact
            path="/home/recipes/:recipeId"
            component={RecipeDetail}
            activeItem="recipes_library"
            title={<RecipeHeader />}
            pageTitle="Recipe Library"
            showHeaderBorder={true}
            upgradePath="recipe"
          />
          <HomeLayout
            exact
            path="/home/ingredients"
            component={IngredientLibrary}
            activeItem="ingredient_library"
            title="Your Ingredients"
            pageTitle="Your Ingredients"
            upgradePath="ingredient"
          />
          <HomeLayout
            exact
            path="/home/metric-group"
            component={MetricGroupLibrary}
            activeItem="metric_groups_library"
            title="Metric Group Library"
            pageTitle="Metric Group Library"
            showHeaderBorder={true}
          />

          {this.props.isTaskLibraryPermission && process.env.REACT_APP_ENABLE_TASKS_LIBRARY && (
            <HomeLayout
              path="/home/task-library"
              component={TasksLibraryContainer}
              activeItem={'task_library'}
              title={'Task Library'}
              pageTitle="Task Library"
            />
          )}
          <HomeLayout
            path="/home/forms/:id"
            component={FormDetails}
            activeItem={'forms_library'}
            title={
              <UpgradePath pathName="form_questionnaire" fallback={'Forms'}>
                <FormHeader />
              </UpgradePath>
            }
            type="edit_program"
            upgradePath=""
            showHeaderBorder={true}
          />

          <HomeLayout
            exact
            path="/home/accountdetails"
            component={AccountDetails}
            parrent={'your_account'}
            activeItem={'account_details_setting'}
            title={'Account Details'}
            pageTitle="Settings - Account Details"
          />
          <HomeLayout
            exact
            path="/home/default-client-settings"
            component={DefaultClientSettings}
            parrent={'your_account'}
            activeItem={'default-client-settings'}
            title={<DefaultSettingsHeader />}
            showHeaderBorder={true}
            higherHeader
            pageTitle="Settings - Default Client Settings"
          />
          <HomeLayout
            exact
            path="/home/changeemail"
            component={ChangeEmail}
            activeItem={'account_details_setting'}
            title={'Change Email'}
          />
          <HomeLayout
            exact
            path="/home/changepassword"
            component={ChangePassword}
            activeItem={'account_details_setting'}
            title={'Change Password'}
          />
          <HomeLayout
            exact
            path="/home/publicprofile"
            component={PublicProfile}
            parrent={'your_account'}
            activeItem={'public_profile_setting'}
            title={'Public Profile'}
          />
          <HomeLayout
            exact
            path="/home/notifications"
            component={Notifications}
            parrent={'your_account'}
            activeItem={'notifications_setting'}
            title={'Notifications'}
            pageTitle="Settings - Notifications"
          />
          <HomeLayout
            exact
            path="/home/generalsettings"
            component={GeneralSettings}
            parrent={'work_space'}
            activeItem={'general_settings'}
            title={'Team Settings'}
            pageTitle="Settings - Team Settings"
          />
          <HomeLayout
            exact
            path="/home/teammates"
            component={Teammates}
            parrent={'work_space'}
            activeItem={'teammates_settings'}
            title={'Teammates'}
            upgradePath="team"
            pageTitle="Settings - Teammates"
          />
          {isEnableZapier && (
            <HomeLayout
              exact
              path="/home/integration"
              component={IntegrationSetting}
              activeItem={'integration_setting'}
              title={'Integration'}
              pageTitle="Integration"
            />
          )}
          <HomeLayout
            exact
            path="/home/billing"
            component={BillingSetting}
            activeItem={'billing_setting'}
            title={'Billing'}
            pageTitle="Billing"
          />
          <HomeLayout
            exact
            path="/home/billinghistory"
            component={BillingHistory}
            activeItem={'billing_setting'}
            title={'BillingHistory'}
          />
          <HomeLayout
            path="/home/program/:programId/calendar"
            component={ProgramListCalendarContainer}
            activeItem={'program_library'}
            title={<ProgramCalendarHeader />}
            type="edit_program"
            upgradePath="program"
          />
          <HomeLayout
            exact
            path="/home/autoflow"
            component={AutoflowList}
            activeItem={'autoflow'}
            title={<AutoflowListHeader />}
            upgradePath="autoflow"
            pageTitle="Autoflow"
          />
          <HomeLayout
            path={'/home/autoflow/:autoflowId'}
            activeItem={'autoflow'}
            title={<AutoflowDetailHeader />}
            component={AutoflowDetail}
            upgradePath="autoflow"
            showHeaderBorder={true}
            higherHeader
          />
          <HomeLayout
            path={'/pricing-plan'}
            activeItem={'pricing-plan'}
            title="pricing-plan"
            component={PricingPlan}
            showHeaderBorder={true}
            hideToggleSidebar={true}
            higherHeader
          />
          <HomeLayout
            exact
            path="/home/studio-program"
            component={StudioProgramList}
            title={<StudioProgramListHeader />}
            activeItem="studio_program"
            upgradePath="studio_program"
            pageTitle={<StudioHeader title="Studio Programs" />}
          />
          <HomeLayout
            exact
            path="/home/studio-programs"
            component={StudioProgramList}
            title={<StudioProgramListHeader />}
            activeItem="studio_program"
            upgradePath="studio_program"
            pageTitle={<StudioHeader title="Studio Programs" />}
          />
          <HomeLayout
            exact
            path="/home/on-demand-settings"
            title={<OnDemandHeader title={'On-demand Settings'} />}
            activeItem="studio_on_demand_setup"
            component={StudioOnDemandSettings}
            pageTitle="On-demand Settings"
            showHeaderBorder={true}
          />
          <HomeLayout
            path={'/home/studio-program/:studioId'}
            activeItem="studio_program"
            upgradePath="studio_program"
            title={<StudioProgramDetailHeader />}
            component={StudioProgramDetail}
            showHeaderBorder={true}
            higherHeader
          />
          <HomeLayout
            path={'/home/studio-programs/:studioId'}
            activeItem="studio_program"
            upgradePath="studio_program"
            title={<StudioProgramDetailHeader />}
            component={StudioProgramDetail}
            showHeaderBorder={true}
            higherHeader
          />
          <HomeLayout
            exact
            path="/home/studio-resource"
            activeItem="studio_resource"
            upgradePath="studio_resource_collection"
            title={
              <BaseHeader
                title={
                  <UpgradePath pathName="studio_resource_collection" fallback="On-demand">
                    <StudioHeader title="Resources" />
                  </UpgradePath>
                }
              />
            }
            component={StudioResourceList}
            pageTitle={<StudioHeader title="Resources" />}
          />
          <HomeLayout
            exact
            path="/home/studio-collection"
            activeItem="studio_collection"
            upgradePath="studio_resource_collection"
            title={
              <BaseHeader
                title={
                  <UpgradePath pathName="studio_resource_collection" fallback="On-demand">
                    <StudioHeader title="Resource Collections" />
                  </UpgradePath>
                }
              />
            }
            component={StudioCollectionList}
            pageTitle={<StudioHeader title="Studio Collections" />}
          />
          <HomeLayout
            path={'/home/studio-collection/:collectionId'}
            activeItem="studio_collection"
            upgradePath="studio_resource_collection"
            title={<StudioCollectionDetailHeader />}
            component={StudioCollectionDetail}
            showHeaderBorder={true}
            higherHeader
          />

          {isEnableOnDemand && (
            <HomeLayout
              exact
              path="/home/workout-collections"
              activeItem="studio_workout_collection"
              upgradePath={'on_demand_workout'}
              title={<OnDemandHeader title={'Workout Collections'} />}
              component={WorkoutCollection}
              pageTitle="Workout Collections"
            />
          )}
          {isEnableOnDemand && (
            <HomeLayout
              path="/home/workout-collections/:collectionId"
              activeItem="studio_workout_collection"
              upgradePath={'on_demand_workout'}
              title={<WorkoutCollectionsDetailHeader />}
              component={WorkoutCollectionsDetail}
              showHeaderBorder={true}
              highestNavbar
            />
          )}
          {isEnableOnDemand && (
            <HomeLayout
              path="/home/workout-collection-category/:categoryId"
              activeItem="studio_workout_collection"
              upgradePath={'on_demand_workout'}
              title={<WorkoutCollectionCategoryHeader name={nameWorkoutCollectionCategory} />}
              component={WorkoutCollectionsCategoryDetail}
              showHeaderBorder={true}
              higherHeader
            />
          )}

          {isEnableOnDemand && (
            <HomeLayout
              path="/home/on-demand-workouts"
              activeItem="studio_on_demand_workout"
              upgradePath={'on_demand_workout'}
              title={<OnDemandHeader title="On-demand Workout Library" />}
              component={OnDemandWorkouts}
              pageTitle="On-demand Workout Library"
            />
          )}

          {isEnableOnDemand && (
            <HomeLayout
              exact
              path="/home/workout-labels"
              activeItem="studio_workout_labels"
              upgradePath={'on_demand_workout'}
              title={<OnDemandHeader title="Manage Workout Labels" />}
              component={WorkoutLabels}
              pageTitle="Manage Workout Labels"
            />
          )}

          {isEnableOnDemand && (
            <HomeLayout
              path="/home/workout-labels/:labelId"
              activeItem="studio_workout_labels"
              upgradePath={'on_demand_workout'}
              title={<OnDemandHeader title="Manage Workout Labels" />}
              component={WorkoutLabelsDetail}
              showHeaderBorder={true}
              higherHeader
            />
          )}

          <HomeLayout
            exact
            path="/home/custom-branding"
            activeItem="custom_branding_settings"
            parrent="work_space"
            title={<BaseHeader title="Custom Branding" />}
            component={CustomBranding}
            showHeaderBorder={false}
            pageTitle="Settings - Custom Branding"
          />
          <HomeLayout
            path="/home/advanced-custom-branding"
            activeItem="custom_branding_settings"
            parrent="work_space"
            title={<BaseHeader title="Custom Branding" />}
            component={CustomBranding}
            showHeaderBorder={false}
            pageTitle="Settings - Custom Branding"
          />
          <HomeLayout
            path={'/home/referral-program'}
            activeItem="referral-program"
            title="Referral Program"
            component={ReferralProgram}
            showHeaderBorder={false}
            hideToggleSidebar
            pageTitle="Referral Program"
          />
          {_.get(permission, 'notification_center') && (
            <HomeLayout
              path={'/home/notification-center'}
              activeItem="notifications"
              title={<NotificationsHeader />}
              component={DashboardNotifications}
              showHeaderBorder={true}
              hideToggleSidebar={true}
              pageTitle="Notification Center"
            />
          )}
          <HomeLayout
            path={'/home/forums'}
            activeItem="forums"
            title={<ForumHeader />}
            component={CommunityForum}
            showHeaderBorder={true}
            hideToggleSidebar={false}
            pageTitle="Forums"
          />
          {/* Wating: Finish Design FallBack */}
          <HomeLayout
            exact
            path="/home/packages"
            component={PackageList}
            title={
              !this.handlePermissionPayment() ? 'Payment & Packages' : <PackageListNavTitle permission={permission} />
            }
            activeItem="package"
            pageTitle="Packages List"
            upgradePath={!this.handlePermissionPayment() && 'explore_payment'}
          />
          {hasMP && (
            <HomeLayout
              exact
              isMP
              path="/home/marketplace"
              component={PackageList}
              title={
                !this.handlePermissionPayment() ? (
                  'Payment & Packages'
                ) : (
                  <PackageListNavTitle permission={permission} isMP />
                )
              }
              activeItem="marketplace"
              pageTitle="Marketplace Packages List"
              upgradePath={!this.handlePermissionPayment() && 'explore_payment'}
            />
          )}
          {isPublic && (
            <HomeLayout
              exact
              path="/home/sequences"
              component={Product}
              title={
                !this.handlePermissionPayment() ? (
                  'Sequences'
                ) : (
                  <ProductListNavTitle checkPermission={checkPermission} isHideBanner={isHideBanner} />
                )
              }
              activeItem="sequences"
              pageTitle="Sequences"
              higherHeader={checkPermission && isHideBanner}
            />
          )}
          {isPublic && (
            <HomeLayout
              exact
              path="/home/sequences/:productId"
              component={Product}
              title={<ProductNameStatus productDetail={productDetail} />}
              activeItem="sequences"
              pageTitle="Sequences"
            />
          )}
          <HomeLayout
            path="/home/packages/:packageId"
            activeItem="package"
            title={<PackageDetailHeader />}
            component={PackageDetail}
            showHeaderBorder={true}
          />
          {hasMP && (
            <HomeLayout
              isMP
              path="/home/marketplace/:packageId"
              activeItem="marketplace"
              title={<PackageDetailHeader />}
              component={PackageDetail}
              showHeaderBorder={true}
            />
          )}
          <HomeLayout
            exact
            path="/home/setup-payment"
            component={SetupPayment}
            title="Setup Payment"
            activeItem="setup-payment"
            pageTitle="Setup Payment"
          />
          <HomeLayout
            exact
            path="/home/payment-activities"
            component={Activity}
            title="Payment Activity"
            activeItem="payment-activities"
            pageTitle="Payment Activity"
          />
          {isEnableCoachBio && (
            <HomeLayout
              exact
              path="/home/coach-bios"
              component={CoachBioList}
              title="Coach Bios"
              activeItem="coach-bios"
              pageTitle="Coach Bios"
            />
          )}

          {isEnableCoachBio && (
            <HomeLayout
              exact
              path="/home/coach-bios/:coachId"
              component={CoachBioDetail}
              title={<CoachBiosDetailHeader coachBioDetails={coachBioDetails} />}
              activeItem="coach-bios"
              pageTitle="Coach Bios Detail"
            />
          )}

          {isEnableCoachBio && (
            <HomeLayout
              exact
              path="/home/teammates/coach-bios/:coachId"
              component={CoachBioDetail}
              title={<CoachBiosDetailHeader coachBioDetails={coachBioDetails} />}
              parrent={'work_space'}
              activeItem={'teammates_settings'}
              pageTitle="Coach Bios Detail"
            />
          )}
          <HomeLayout
            exact
            path="/home/onboarding-flow"
            component={hasPermissionMultiple ? OnboardingFlowLibrary : OnboardingFlowDetail}
            activeItem={'onboarding-flow'}
            title={
              <OnboardingFlowHeader
                status={_.get(onboardingFlowDetail, 'status', 'draft')}
                permission={permission}
                isPermission={_.get(permission, 'onboarding_flow', false)}
                isHideUpgradePath={_.get(generalSettings, 'is_show_upgrade_path_onboarding_flow')}
                teamData={teamData}
              />
            }
            showHeaderBorder
            pageTitle="Onboarding Flow"
            mediumNavbar={_.get(permission, 'onboarding_flow', false)}
            upgradePath="onboarding_flow"
          />
          <HomeLayout
            path={'/home/onboarding-flow/:onboardingFlowId'}
            activeItem={'onboarding-flow'}
            title={
              hasPermissionMultiple ? (
                <OnboardingFlowHeaderDetail
                  status={_.get(onboardingFlowDetail, 'status', 'draft')}
                  isPermission={_.get(permission, 'onboarding_flow', false)}
                />
              ) : (
                <OnboardingFlowHeader
                  status={_.get(onboardingFlowDetail, 'status', 'draft')}
                  permission={permission}
                  isPermission={_.get(permission, 'onboarding_flow', false)}
                  isHideUpgradePath={_.get(generalSettings, 'is_show_upgrade_path_onboarding_flow')}
                  teamData={teamData}
                />
              )
            }
            component={OnboardingFlowDetail}
            showHeaderBorder
            pageTitle="Onboarding Flow"
            mediumNavbar={!_.get(permission, 'multiple_onboarding_flow')}
            upgradePath="onboarding_flow"
          />

          <Route path={'/login'} component={Login} />
          <Route path={'/register'} component={Register} />
          <Route path={'/signup/:inviteCode'} component={SignUpWithNormalInviteLink} />
          <Route path={'/i/:inviteCode'} component={SignUpWithWorkspaceInviteLink} />
          <Route path={'/forgot-password'} component={ForgotPassword} />
          <Route path={'/video'} component={PlayVideoPage} />
          <Route path="/verify-account" component={VerifyAccount} />
          <Route path="/verify-email" component={Welcome} />
          <Route path="/onboarding" component={Welcome} />
          <Route path="/welcome" component={Welcome} exact />
          <Route path="/restrict-login" component={RetrictMobileLogin} />
          <Route path="/unsubscribe-email" component={UnsubscribeEmail} />
          <Route path="/confirm-reset-password" component={ConfirmResetPassword} />
          <Route path="/verify-change-email" component={VerifyChangeEmail} />
          <Route path="/views/verify_change_email" component={VerifyChangeEmail} />
          <Route path="/views/forgot_password" component={ConfirmResetPassword} />
          <Route path="/complete-registration" component={CompleteRegistration} exact />
          <Route
            path="/views/download_ios"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_ITUNES_URL} {...props} />}
          />
          <Route
            path="/views/download_android"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_GOOGLE_PLAY_URL} {...props} />}
          />
          <Route
            path="/views/download_ios_coach"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_TRAINER_ITUNES_URL} {...props} />}
          />
          <Route
            path="/views/download_android_coach"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_TRAINER_GOOGLE_PLAY_URL} {...props} />}
          />
          <Route
            path={'/d/ios/:id'}
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_ITUNES_URL} {...props} />}
          />
          <Route
            path="/d/ios"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_ITUNES_URL} {...props} />}
          />
          <Route
            path="/d/android/:id"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_GOOGLE_PLAY_URL} {...props} />}
          />
          <Route
            path="/d/android"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_GOOGLE_PLAY_URL} {...props} />}
          />
          <Route
            path="/d/coach/ios"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_TRAINER_ITUNES_URL} {...props} />}
          />
          <Route
            path="/d/coach/android"
            render={props => <RedirectPage redirectTo={process.env.REACT_APP_TRAINER_GOOGLE_PLAY_URL} {...props} />}
          />
        </Switch>
        <ToastContainer
          className="app-notify-container"
          hideProgressBar={true}
          closeButton={<CloseToastButton />}
          transition={Slide}
          autoClose={5000}
          pauseOnFocusLoss={false}
        />
      </div>
    );
  }

  renderRoot(props) {
    const { selectedClient, user } = this.props;
    const params = new URLSearchParams(window.location.search);
    if (params.get('coupon')) {
      localStorage.setItem('hasCoupon', window.location.search);
    }

    if (selectedClient) {
      return <Redirect to={`/home/client/${selectedClient}`} />;
    }

    if (user) {
      if (!user.is_answers_questionnaire) {
        return <Redirect to={`/welcome${window.location.search}`} />;
      }

      return <Redirect to={'/home/client'} />;
    } else if (this.props.failureToGetProfile || this.props.errorGetProfile) {
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }
    return null;
  }
}
