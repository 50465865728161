import styled, { css } from 'styled-components';
import bg from 'assets/images/register_success_bg.svg';
import bgMb from 'assets/images/register_success_bg_mb.svg';

export const Header = styled.div`
  text-align: center;
  background-color: ${props => (props.step === 1 ? '#F7F8FC' : '#FFFFFF')};

  ${props =>
    props.hasLogo
      ? css`
          padding: 0;
          background-color: #fff;

          img {
            max-height: 200px;
            width: 100%;
          }

          @media screen and (max-width: 430px) {
            img {
              max-height: 158px;
            }
          }
        `
      : css`
          padding: 40px 30px 0;
          height: 94px;
        `}

  img {
    margin: auto;
    display: block;
    object-fit: cover;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
  &.send_link {
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 10px 16px rgba(99, 100, 114, 0.1);
    border-radius: 15px;
    margin-top: 17px;
    @media screen and (max-width: 430px) {
      padding: 24px 16px;
    }
  }

  label {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #7b7e91;
    margin-bottom: 8px;
    text-transform: uppercase;
    display: block;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: #1e0a3c;
    padding: 15px 26px 15px 16px;
    background: #f7f8fc;
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #f7f8fc;
    width: 100%;

    :focus {
      background: #f7f5ff;
      border-color: #7c70ef;
    }

    ::placeholder {
      color: #7b7e91;
    }

    ::-webkit-credentials-auto-fill-button {
      visibility: hidden;
    }
  }

  .error {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #ff5f5f;
    margin-top: 6px;
  }

  .password {
    position: relative;
    .eye {
      position: absolute;
      top: 50%;
      right: 16px;
      cursor: pointer;
      transform: translateY(-50%);
      z-index: 1;
    }

    input[autocomplete='off']::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      height: 0;
      width: 0;
      margin: 0;
    }
  }
`;

export const FormTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  width: 100%;
  @media only screen and (max-width: 430px) {
    line-height: 24px;
    font-size: 16px;
  }
`;

export const SignUpContainer = styled.form`
  width: 320px;
  max-width: 100%;
  margin: auto;
  padding: 60px 0 40px;

  h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000;
    max-width: 293px;
    margin: auto;
    margin-bottom: 38px;
  }

  input:focus {
    border-color: ${props => props.mainColor};
  }

  .form__footer {
    width: 100%;
    text-align: center;
    padding: 15px 0;

    &--btn {
      background: ${props => props.mainColor};
      width: 100%;

      &:hover {
        background-color: ${props => props.mainColor};
      }
    }

    &--link {
      margin-top: 20px;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #1e0a3c;
    }
  }
`;

export const Body = styled.div`
  background-color: ${props => (props.step === 1 || 2 ? '#F7F8FC' : '#FFFFFF')};

  .body__content {
    min-height: calc(100vh - 232px);
    max-width: ${props => (props.step === 1 || 2 ? '800px' : '100vw')};
    margin: auto;
    background-color: #ffffff;

    &.success {
      min-height: unset !important;
      background-image: url(${props => (props.step === 2 ? bg : '')});
      background-position: bottom;
      background-repeat: no-repeat;
      background-position: 0 350px;
      @media only screen and (max-width: 430px) {
        background-image: url(${props => (props.step === 2 ? bgMb : '')});
      }
    }
  }

  button {
    border-radius: 28px;
    width: 196px;
    background: #6965d7;
    border-radius: 28px;
    text-align: center;
    padding: 17px 23px 19px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    outline: none !important;
    cursor: pointer;
    border: none;

    :hover,
    :focus,
    :active {
      background-color: #5b57d7;
    }
  }

  &.success {
    min-height: unset !important;
  }
`;

export const DownLoadContainer = styled.div`
  text-align: center;
  margin-bottom: 17px;
  display: flex;
  justify-content: center;

  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #1e0a3c;
    margin-bottom: 20px;
  }

  a {
    display: inline-flex;
    margin-bottom: 10px;

    img {
      width: 185px;
      height: 50px;
    }

    :last-child {
      margin-left: 5px;
      img {
        min-height: 57px;
      }
    }
  }
`;

export const Footer = styled.div`
  font-family: Lato;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #8f99a3;
  background-color: #f7f8fc;
  padding: 32px 30px;
  flex: 1 1 100%;

  .poweredBy {
    display: flex;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #bababa;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin-left: 10px;
    }
  }

  .social {
    margin-bottom: 16px;

    a {
      margin-right: 14px;

      :last-child {
        margin-right: 0;
      }
    }
  }

  .address {
    p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 150%;
      color: #818eaf;
    }
  }

  @media only screen and (max-width: 430px) {
    .download__links {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin-right: 0;
      }
      svg {
        max-width: 100%;
      }
    }
  }
`;

export const Wrapper = styled.div`
  font-family: Open Sans;
  font-style: normal;
  /* align-items: center; */
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f7f8fc;

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
    max-width: ${props => (props.step === 1 || 2 ? '800px' : '100vw')};
    width: 100%;
    margin: auto;
    ${props =>
      (props.step === 1 || 2) &&
      css`
        padding-top: 60px;
        padding-right: 20px;
        padding-left: 20px;
      `};
  }

  @media only screen and (max-width: 767px) {
    height: unset;

    ${SignUpContainer} {
      padding-top: 33px;
      padding-bottom: 35px;
    }

    ${DownLoadContainer} {
      margin-bottom: 22px;
    }

    .content {
      padding: 0px;
    }

    ${Body} {
      .body__content {
        min-height: calc(100vh - 190px);
        padding: 10px;
      }
    }
  }
`;
