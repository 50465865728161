import styled, { css } from 'styled-components';
import { CoachDetailInfo, CoachEmail, CoachName } from 'components/CoachBios/CoachBioDetail/style';
import { Actions, TableBody, TableCell, TableRow } from 'shared/LibraryLayout';
import { MenuTrigger } from 'shared/Icons';

const limitText = css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const CheckBoxContainer = styled.div`
  .checkbox-icon {
    transform: translateY(-100%);
    width: 14px;
    height: 14px;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16),
      0px 1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
`;

export const CoachBiosTableBody = styled(TableBody)`
  height: 80px;

  ${TableRow} {
    :hover {
      background-color: inherit;
      cursor: default;
    }
  }
`;

export const CoachBiosDetailTableRow = styled(TableRow)`
  border-bottom: 1px solid #e3e8ee;
`;

export const CoachBiosCell = styled(TableCell)`
  padding: 10px 10px 10px 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #222222;

  :first-child {
    padding: 10px 0 10px 2px;
    min-width: 250px;
  }

  &:nth-child(1) {
    min-width: 250px;
    width: 25% !important;
  }

  &:nth-child(2) {
    min-width: 100px;
    width: 25% !important;
  }

  &:nth-child(3) {
    min-width: 450px;
    width: 45% !important;
  }

  &:nth-child(4) {
    width: 5% !important;
  }

  ${props =>
    props.alignCenter &&
    css`
      text-align: center;
    `}

  ${props =>
    props.alignRight &&
    css`
      text-align: right;
    `}
`;

export const CoachBios = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const CoachBiosDetail = styled(CoachDetailInfo)`
  padding-left: 16px;
`;

export const CoachBiosName = styled(CoachName)`
  padding-right: 20px;
  font-size: 14px;
  line-height: 19px;
`;

export const CoachBiosEmail = styled(CoachEmail)``;

export const CoachBiosRole = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${limitText}
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  padding-right: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${limitText}
`;

export const CoachBiosActions = styled(Actions)`
  .dot {
    background-color: #4f566b;

    ::after,
    ::before {
      background-color: #4f566b;
    }
  }
`;

export const CoachBiosMenuTrigger = styled(MenuTrigger)`
  margin-right: 20px;
`;

export const Edit = styled.div`
  color: #5c5bc3;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  text-align: center !important;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
