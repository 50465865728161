import get from 'lodash/get';
import map from 'lodash/map';
import React, { useMemo } from 'react';
import { replaceImageURL } from 'utils/commonFunction';
import { isS3FileURL } from 'utils/validations';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';
import UploadIcon from 'assets/icons/workout-collection-upload-banner-icon.svg';

import * as S from './styles';

const MAX_LENGTH = 100;

export function cutString(str) {
  return str.length > MAX_LENGTH ? str.substring(0, MAX_LENGTH) + '... ' : str;
}
export const Item = ({ item, currentExerciseId, type, changeQueryParams }) => {
  const isSelected = item._id === currentExerciseId;

  const handleSelectTag = (e, tag) => {
    changeQueryParams({ tags: [tag] });
    e.stopPropagation();
  };

  const renderTagsColumn = exercise => {
    const tags = get(exercise, 'tags', []);
    if (!tags.length) return <span>--</span>;

    return map(tags, tag => {
      return tag ? (
        <React.Fragment key={tag._id}>
          <span className="tag-name" onClick={e => handleSelectTag(e, tag)}>
            {tag.name}
          </span>
        </React.Fragment>
      ) : null;
    });
  };

  const renderExerciseModality = (exercise = {}) => {
    const { modality } = exercise;
    const title = get(modality, 'title', '--');
    if (title) return <span>{title}</span>;
  };

  const renderExerciseMuscleGroups = (muscle_groups = []) => {
    const primaryMuscleGroup = muscle_groups.find(item => item.is_primary);
    const titleMuscle = get(primaryMuscleGroup, 'muscle_group.title', '--');

    return <span>{titleMuscle}</span>;
  };

  const renderExerciseMovementPatterns = (movement_patterns = []) => {
    const primaryMovementPattern = movement_patterns.find(item => item.is_primary);
    const titleMovement = get(primaryMovementPattern, 'movement_pattern.title', '--');

    return <span>{titleMovement}</span>;
  };

  return (
    <>
      <S.WorkoutInfo>
        <S.WorkoutInfoDetail>
          <ExerciseMediaPreview
            exercise={item || {}}
            size={{ width: 90, height: 56 }}
            defaultThumbnail={UploadIcon}
            type={type}
          />
          <S.Desc>{cutString(item.title)}</S.Desc>
        </S.WorkoutInfoDetail>
      </S.WorkoutInfo>
      <div className="tags">{renderTagsColumn(item)}</div>
      <div className="modality">{renderExerciseModality(item)}</div>
      <div className="muscle-groups">{renderExerciseMuscleGroups(item.muscle_groups)}</div>
      <div className="movement-pattern">{renderExerciseMovementPatterns(item.movement_patterns)}</div>
      <div className="category">{item.category_type_name}</div>
      <S.RadioContainer>
        <S.RadioButton checked={isSelected} />
      </S.RadioContainer>
    </>
  );
};

const TableContent = ({
  exercises = [],
  currentExerciseId,
  onSelectExercise,
  lastExerciseElRef,
  loading,
  type,
  changeQueryParams,
}) => {
  const handleSelect = exercise => () => {
    onSelectExercise(exercise);
  };

  const exercisesSorted = useMemo(() => {
    const current = exercises.find(it => it._id === currentExerciseId);
    return current ? [current, ...exercises.filter(it => it._id !== currentExerciseId)] : exercises;
  }, [exercises]);

  return (
    <S.TableBody>
      {!loading && exercisesSorted.length === 0 && <S.NoResultText>No results found</S.NoResultText>}
      {exercisesSorted.map((it, index) => {
        const isCustom = get(it, 'custom', false);
        if (exercisesSorted.length === index + 1) {
          return (
            <S.TableRow ref={lastExerciseElRef} key={it._id} withHoverStyle onClick={handleSelect(it)}>
              <Item item={it} currentExerciseId={currentExerciseId} type={type} changeQueryParams={changeQueryParams} />
              {isCustom && <S.CustomLabel>Custom</S.CustomLabel>}
            </S.TableRow>
          );
        }
        return (
          <S.TableRow key={it._id} withHoverStyle onClick={handleSelect(it)}>
            <Item item={it} currentExerciseId={currentExerciseId} type={type} changeQueryParams={changeQueryParams} />
            {isCustom && <S.CustomLabel>Custom</S.CustomLabel>}
          </S.TableRow>
        );
      })}
    </S.TableBody>
  );
};

export default TableContent;
