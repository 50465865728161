import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, get } from 'lodash';

import { Mixpanel } from 'utils/mixplanel';
import { Button } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import { changeAutoflowQueryParam, getAutoflowList, hideAutoflowBanner } from 'redux/autoflow/actions';
import { toggleModal } from 'actions/modal';
import AutoflowFormWithIntevalOption from '../CreateNewAutoflow';
import AutoflowFormWithoutIntevalOption from '../Form';
import { SharedTooltip } from 'shared/SharedTooltip';
import { CDN_URL } from 'constants/commonData';

import { ReactComponent as MiniSearchIcon } from 'assets/icons/mini-search-icon.svg';

import * as S from './style';

class AutoflowHeading extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSearchBox: false };
    this.searchDebounce = debounce(this.onSearch, 300);
  }

  onSearch = (event, { value }) => {
    const { search } = this.props;
    const trimmed = value.toLowerCase().trim();

    if (trimmed !== search) {
      Mixpanel.track('autoflow_home_screen_search_autoflow');
      this.props.changeAutoflowQueryParam({ page: 1, search: trimmed });
      this.props.getAutoflowList();
    }
  };

  onCreateNew = () => {
    Mixpanel.track('autoflow_home_screen_add_autoflow');
    this.props.toggleModal(
      true,
      get(this.props.permission, 'autoflow_interval') ? (
        <AutoflowFormWithIntevalOption />
      ) : (
        <AutoflowFormWithoutIntevalOption />
      ),
    );
  };

  onOpenSearchBox = () => {
    this.setState({ showSearchBox: true });
  };

  onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      Mixpanel.track('autoflow_home_screen_search_autoflow');
      this.props.changeAutoflowQueryParam({ page: 1 });
      this.props.getAutoflowList();
    }
  };

  render() {
    const { showBanner, header, banner } = this.props;
    const { showSearchBox } = this.state;
    const isMobile = window.innerWidth <= 1120;
    const isShowBanner = showBanner && !isMobile;
    if ((isShowBanner && header) || (!isShowBanner && banner)) {
      return null;
    }

    return (
      <S.Wrapper className="autoflow__header" showBanner={showBanner && !isMobile}>
        {showBanner && !isMobile ? (
          <S.BannerContainer>
            <S.BannerTextContent>
              <div className="autoflow__banner__label">Autoflow</div>
              <div className="autoflow__banner__title">Automated program delivery at Scale</div>
              <div className="autoflow__banner__description">
                <span>
                  Manage your group training programs with ease. Schedule workouts, tasks, metric and progress photo
                  check-ins, and announcement messages.
                </span>
                <div className="autoflow__banner__learnMoreLink">
                  <a href="http://help.everfit.io/en/articles/3661707-autoflow-overview" target="_blank">
                    <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="" /> How Autoflow works
                  </a>
                  <a href="http://help.everfit.io/en/articles/4854106-introducing-leaderboards" target="_blank">
                    <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="" /> How Leaderboard works
                  </a>
                </div>
              </div>
            </S.BannerTextContent>
            <div className="buttons__container">
              <Button purple onClick={this.onCreateNew} className="button--create-new">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 4.99902V0.499023H4.5V4.99902H0V6.99902H4.5V11.499H6.5V6.99902H11V4.99902H6.5Z"
                    fill="black"
                  />
                </svg>
                <span>Add New Autoflow</span>
              </Button>
              {showSearchBox ? (
                <NewSearchInput
                  onChange={this.searchDebounce}
                  onClearSearch={() => this.onSearch(null, { value: '' })}
                  placeholder="Search by keyword or name"
                  onKeyPress={this.onKeyPress}
                />
              ) : (
                <>
                  <Button
                    className="button--search-box-trigger"
                    onClick={this.onOpenSearchBox}
                    data-tip
                    data-for="autoflow-banner-search-tooltip"
                  >
                    <MiniSearchIcon />
                  </Button>
                  <SharedTooltip content="Search" id="autoflow-banner-search-tooltip" />
                </>
              )}
            </div>
            <div className="button-hidden-container">
              <Button className="button--hide-banner" onClick={this.props.hideAutoflowBanner}>
                <span>Hide Banner</span>
                <img src={`${CDN_URL}/images/arrow_up_7e7e7e.svg`} alt="" />
              </Button>
            </div>
          </S.BannerContainer>
        ) : (
          <S.DefaultUIContainer>
            <NewSearchInput
              onChange={this.searchDebounce}
              onClearSearch={() => this.onSearch(null, { value: '' })}
              placeholder="Search by keyword or name"
              onKeyPress={this.onKeyPress}
            />
            <Button purple onClick={this.onCreateNew}>
              <img src={`${CDN_URL}/images/plus_white.svg`} alt="" />
              <span>Add New Autoflow</span>
            </Button>
          </S.DefaultUIContainer>
        )}
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    user: { is_hide_banner_autoflow },
    rootReducer: {
      permission,
      autoflow: {
        common: {
          query: { search },
        },
      },
    },
  } = state;
  return { search, showBanner: !is_hide_banner_autoflow, permission };
};

const mapDispatch = dispatch => ({
  changeAutoflowQueryParam: bindActionCreators(changeAutoflowQueryParam, dispatch),
  hideAutoflowBanner: bindActionCreators(hideAutoflowBanner, dispatch),
  getAutoflowList: bindActionCreators(getAutoflowList, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatch)(AutoflowHeading);
