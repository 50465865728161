import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import axios from 'axios';
import * as S from './style';
import Dropzone from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SelectFile } from 'shared/FormControl';
import { validateVimeoUrl, validateYouTubeUrl, validateFiles } from 'utils/validations';
import {
  revokeObjectURL,
  revokeMultipleObjectURL,
  createObjectURL,
  reorder,
  isNumber,
  mediaLog,
  formatShortLink,
  getMultiplePresignedUploadUrlsByParams,
  getPresignedUploadUrl,
  convertS3UrlToCloudFrontUrl,
  checkYoutubeThumbnailUrl,
  getVideoDetailSize,
  generateVimeoAPIUrl,
} from 'utils/commonFunction';
import { removeUploadedFileFromServer } from 'utils/commonRequest';
import FileUpload from 'shared/FileUpload';
import { FILE_VALIDATION, DEFAULT_STATE, ERROR_MESSAGE, UPLOAD_KEY } from './constants';
import MediaModal from 'shared/MediaModal/component';
import DropZonePlaceholder from 'shared/DropZonePlaceholder';
import { CONVERSION, LIMIT_SIZE_IMAGE_UPLOAD_MB, MEDIA_PLACEHOLDER } from 'constants/commonData';
import * as UploadStyles from 'shared/FileUpload/style';
import ProgressRing from 'shared/ProgressRing';
import { axiosInstance } from 'configs/request';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { getFileExtension } from 'helpers/exercise';

const DROP_ACCEPTED_FILES = `${FILE_VALIDATION.ACCEPTED_VIDEO}, ${FILE_VALIDATION.ACCEPTED_IMAGE}`;
const maxFileSize = 120 * CONVERSION.MB_TO_BYTE;
const CancelToken = axios.CancelToken;

class MediaSide extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      videoData: { ...DEFAULT_STATE.videoData, ...DEFAULT_STATE.uploadInformation, validVideo: false },
      photoData: { ...DEFAULT_STATE.photoData },
      uploadPhotoProgress: {},
      thumbnail: null,
    };

    this.videoLinkInput = React.createRef();
    this.uploadTasks = {};
  }

  componentDidMount() {
    this._isMounted = true;
    const { video, videoLink, picture, thumbnail_url, preview_300, preview_50, validVideo } = this.props;
    let photos = [],
      videoData = {};

    if (picture && picture.length) {
      photos = _.map(picture, (src, index) => ({
        src,
        apiURL: src,
        _id: `original_${new Date().getTime()}_${index}`,
      }));
    }

    if (video) {
      videoData = { thumbnail: thumbnail_url, src: video, apiURL: video };
      this.setState({ validVideo: validVideo });
    } else if (videoLink) {
      getVideoDetailSize(thumbnail_url)
        .then(async thumbnail => {
          let checkThumbnail = thumbnail_url;
          if (thumbnail.width === 120 && thumbnail.height === 90) {
            const preview300Size = await getVideoDetailSize(preview_300);
            if (preview300Size.width === 120 && preview300Size.height === 90) {
              checkThumbnail = preview_50;
            } else {
              checkThumbnail = preview_300;
            }
          }

          videoData = { thumbnail: checkThumbnail, src: videoLink, usingLink: true };
          if (!thumbnail_url && !picture.length) {
            this.setState({
              photoData: { ...this.state.photoData, list: photos },
              videoData: { ...this.state.videoData, ...videoData },
            });
          } else {
            this.prepareVideoAndPhotoData({ photos, videoData });
          }
        })
        .catch(error => {
          console.error(error);
          videoData = { thumbnail: MEDIA_PLACEHOLDER, src: videoLink, apiURL: videoLink, usingLink: true };
          this.prepareVideoAndPhotoData({ photos, videoData });
        });
    }

    if (!thumbnail_url && !picture.length) {
      this.setState({
        photoData: { ...this.state.photoData, list: photos },
        videoData: { ...this.state.videoData, ...videoData },
      });
    } else {
      this.prepareVideoAndPhotoData({ photos, videoData });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { videoData, photoData } = this.state;
    let listObjectURL = [];

    if (videoData.isLocalFile) {
      listObjectURL.push(videoData.src);
    }

    const photos = _.filter(photoData.list, item => !!item && item.isLocalFile);
    const localPhotoUrl = _.map(photos, item => item.src);

    listObjectURL = listObjectURL.concat(localPhotoUrl);

    if (listObjectURL.length) {
      revokeMultipleObjectURL(listObjectURL);
    }

    _.forEach(this.uploadTasks, task => {
      if (typeof task === 'function') {
        task();
      }
    });
  }

  prepareVideoAndPhotoData = async items => {
    const { photos, videoData } = items;

    try {
      let listURL = _.map(photos, 'src');

      if (videoData.thumbnail) {
        listURL.push(videoData.thumbnail);
      }

      const listPresignedURLs = await this.props.getS3presignedURLFromLocalDatabase(listURL);

      const newPhotos = _.map(photos, (p, index) => ({ ...p, src: listPresignedURLs[index] }));

      this.setState({
        photoData: { ...this.state.photoData, list: newPhotos },
        videoData: {
          ...this.state.videoData,
          ...videoData,
          thumbnail: videoData.thumbnail ? listPresignedURLs[listPresignedURLs.length - 1] : '',
        },
      });
    } catch {
      this.setState({
        photoData: { ...this.state.photoData, list: photos },
        videoData: { ...this.state.videoData, ...videoData },
      });
    }
  };
  getAttachmentId = config => {
    const splittedKey = config.key && config.key.split('/');
    const attachmentId = splittedKey[1].split('.')[0];
    return attachmentId;
  };

  updateVideoData = async data => {
    const { photoData, videoData } = this.state;
    const { file } = data;

    if (file) {
      const { uploadUrl, configs } = await getPresignedUploadUrl('api/file/gen-presigned-urls-exercise-library', file);
      this.setState(prev => ({
        videoData: {
          ...prev.videoData,
          ...data,
          configs: {
            fileData: configs,
            attachment_id: this.getAttachmentId(configs),
            uploadConfigs: {
              method: 'PUT',
              url: uploadUrl,
              headers: { 'Content-Type': configs.mimetype },
              data: file,
            },
          },
        },
      }));
    } else {
      this.setState(prev => ({ videoData: { ...prev.videoData, ...data } }));
    }
    // this.setState(prev => ({ videoData: { ...prev.videoData, ...data } }));
    this.props.onUpdate({ videoData: { ...videoData, ...data }, photoData: { ...photoData } });
  };

  updatePhotoData = (data, addingPhotos = []) => {
    const { photoData, videoData } = this.state;

    this.setState(
      prev => ({ photoData: { ...prev.photoData, ...data } }),
      () => {
        if (addingPhotos.length) {
          this.uploadFilesToServer(addingPhotos);
        }
      },
    );
    this.props.onUpdate({ videoData: { ...videoData }, photoData: { ...photoData, ...data } });
  };

  uploadFilesToServer = async files => {
    if (files.length) {
      const uploadArr = await getMultiplePresignedUploadUrlsByParams(
        files.map(({ file }) => file),
        {
          method: 'POST',
          url: 'api/file/gen-presigned-urls-exercise-library',
          data: { files: files.map(({ file }) => ({ name: file.name, mimetype: file.type })) },
        },
      );
      uploadArr.forEach((file, index) => {
        const { uploadUrl, configs } = file;
        const { _id } = files[index];

        axiosInstance({
          method: 'PUT',
          url: uploadUrl,
          headers: { 'Content-Type': configs.mimetype },
          data: files[index].file,
          onUploadProgress: progressData => {
            const { loaded, total } = progressData;
            const { list } = this.state.photoData;
            const newProgress = Math.floor((loaded / total) * 100);
            const itemIndex = _.findIndex(list, obj => obj._id === _id);

            if (itemIndex !== -1) {
              this.setState(prevState => ({
                uploadPhotoProgress: { ...prevState.uploadPhotoProgress, [_id]: newProgress },
              }));
            }
          },
          cancelToken: new CancelToken(
            function executor(c) {
              this.uploadTasks[_id] = c;
            }.bind(this),
          ),
        })
          .then(res => {
            const { _id } = files[index];
            const { original_name, mimetype } = configs;
            mediaLog({
              status: 2,
              name: original_name,
              fileType: mimetype,
              description: 'Upload success file via Exercise',
            });
            this.onUploadPhotoSuccess({ _id, configs });
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              if (this._isMounted) {
                this.onRemoveSinglePhoto({ data: { ...error, message: 'Canceled' }, _id });
              }
            } else {
              delete this.uploadTasks[_id];
            }
          });
      });
    }
  };

  onCancelUploadVideo = () => {
    if (!this._isMounted) {
      return false;
    }

    const { videoData } = this.state;

    if (videoData.isLocalFile) {
      revokeObjectURL(videoData.src);
    }

    this.updateVideoData({ ...DEFAULT_STATE.videoData, ...DEFAULT_STATE.uploadInformation });
  };

  onUploadVideoSuccess = async response => {
    const { videoData } = this.state;
    const videoType = getFileExtension(_.get(videoData, 'file.name', ''));
    if (!this._isMounted) {
      return false;
    }
    const { original_name, url, mimetype, thumbnail_url } = videoData.configs.fileData;
    // const { data } = response.data;

    // const { originalname, file_extension } = data;

    mediaLog({
      status: 2,
      name: original_name,
      fileType: mimetype,
      description: 'Upload success file via Exercise',
    });

    this.updateVideoData({
      file: null,
      apiURL: url,
      bucketData: videoData.configs,
      videoType: videoType,
      thumbnail: thumbnail_url,
      preview_300: thumbnail_url,
    });

    this.setState({
      validVideo: _.toUpper(videoType) === 'MP4',
    });
    const presignedThumbnail = await this.props.getS3presignedURLFromLocalDatabase([thumbnail_url])[0];
    this.setState(prevState => ({
      videoData: { ...prevState.videoData, thumbnail: presignedThumbnail },
    }));
  };

  // onUploadVideoSuccess = response => {
  //   const { videoData } = this.state;
  //   const videoType = getFileExtension(_.get(videoData, 'file.name', ''));
  //   if (!this._isMounted) {
  //     return false;
  //   }

  //   const { data } = response.data;

  //   const { originalname, file_extension } = data;

  //   mediaLog({
  //     status: 2,
  //     name: originalname,
  //     fileType: file_extension,
  //     description: 'Upload success file via Exercise',
  //   });

  //   this.updateVideoData({
  //     file: null,
  //     apiURL: data.location,
  //     bucketData: data,
  //     videoType: videoType,
  //   });

  //   this.setState({
  //     validVideo: _.toUpper(videoType) === 'MP4',
  //   });
  // };

  selectVideos = files => {
    const { src } = this.state.videoData;

    if (src) {
      return this.updateVideoData({ error: ERROR_MESSAGE.MAX_VIDEO_NUMBER });
    }

    const file = files[0];
    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via Exercise',
    });

    const newVideo = {
      src: createObjectURL(file),
      isLocalFile: true,
      file: file,
      error: files.length > 1 ? ERROR_MESSAGE.MAX_VIDEO_NUMBER : '',
      videoType: getFileExtension(_.get(file, 'name', '')),
    };

    this.updateVideoData(newVideo);
  };

  onChangeVideoLink = async event => {
    let value = event.target.value || '';

    if (value.includes('player.vimeo.com')) {
      const div = document.createElement('div');
      div.innerHTML = value;
      const iframe = div.querySelector('iframe');

      if (iframe) {
        value = iframe.src || '';
      }
    }

    const trimmedValue = value.trim();

    if (trimmedValue) {
      if (validateVimeoUrl(trimmedValue)) {
        this.updateVideoData({
          ...DEFAULT_STATE.uploadInformation,
          usingLink: true,
          src: trimmedValue,
          thumbnail: '',
          invalidLink: false,
        });
        axios
          .get(generateVimeoAPIUrl(trimmedValue))
          .then(response => {
            const data = response.data || {};
            this.updateVideoData({ invalidLink: false, thumbnail: data.thumbnail_url || MEDIA_PLACEHOLDER, error: '' });
          })
          .catch(error => {
            const { videoData } = this.state;

            if (videoData.src === trimmedValue) {
              this.updateVideoData({ invalidLink: true, error: ERROR_MESSAGE.INVALID_VIDEO_LINK });
            }
          });
      } else {
        const youtubeId = validateYouTubeUrl(trimmedValue);
        const invalidLink = !youtubeId;
        const thumbnailUrl = await checkYoutubeThumbnailUrl(youtubeId);

        this.updateVideoData({
          ...DEFAULT_STATE.uploadInformation,
          invalidLink,
          usingLink: true,
          src: formatShortLink(trimmedValue) || '',
          thumbnail: thumbnailUrl,
          error: invalidLink ? ERROR_MESSAGE.INVALID_VIDEO_LINK : '',
        });
      }
    } else {
      this.updateVideoData(DEFAULT_STATE.videoData);
    }
  };

  onRemoveVideo = event => {
    event.preventDefault();
    const { videoData } = this.state;

    if (videoData.isLocalFile) {
      revokeObjectURL(videoData.src);

      if (videoData.bucketData) {
        removeUploadedFileFromServer(videoData.bucketData);
      }
    }
    this.updateVideoData({ ...DEFAULT_STATE.videoData, ...DEFAULT_STATE.uploadInformation });
  };

  selectMultiplePhotos = (originalFiles, isDropped) => {
    let files = originalFiles;

    if (isDropped) {
      const checkedFiles = _.map(originalFiles, file => {
        const error = validateFiles({
          files: [file],
          validateExtentions: FILE_VALIDATION.IMAGE_VALIDATE_EXTENTION,
          maxSize: LIMIT_SIZE_IMAGE_UPLOAD_MB * CONVERSION.MB_TO_BYTE,
        });

        return { file, error };
      });

      files = _.filter(checkedFiles, item => !item.error).map(item => item.file);
    }

    if (!files.length) {
      return;
    }

    const { list } = this.state.photoData;

    if (list.length < 4) {
      const parsedPhotos = _.map(files, (file, index) => {
        const { size, name, type } = file;

        mediaLog({
          status: 1,
          name,
          fileSize: size,
          fileType: type,
          description: 'Send a file via Exercise',
        });
        return {
          src: createObjectURL(file),
          isLocalFile: true,
          file: file,
          _id: `adding_multiple_${new Date().getTime()}_${index}`,
        };
      });
      const addingPhotos = parsedPhotos.slice(0, 4 - list.length);

      this.updatePhotoData(
        {
          list: [...list, ...addingPhotos],
          error: addingPhotos.length < parsedPhotos.length ? ERROR_MESSAGE.MAX_PHOTO_NUMBER : '',
        },
        addingPhotos,
      );
    } else {
      this.updatePhotoData({ error: ERROR_MESSAGE.MAX_PHOTO_NUMBER });
    }
  };

  onRemoveSinglePhoto = ({ _id }) => {
    const { photoData } = this.state;
    const list = photoData.list.slice();
    const index = _.findIndex(list, item => item._id === _id);

    if (index !== -1) {
      const data = list[index];

      if (data && data.isLocalFile) {
        revokeObjectURL(data.src);

        if (data.bucketData) {
          removeUploadedFileFromServer(data.bucketData);
        }
      }

      list.splice(index, 1);
      this.updatePhotoData({ list, error: '' });
    }
  };

  onUploadPhotoSuccess = async ({ configs: data, _id }) => {
    const { photoData } = this.state;

    if (_id) {
      const index = _.findIndex(photoData.list, item => item._id === _id);
      delete this.uploadTasks[_id];

      if (index !== -1) {
        const list = photoData.list.slice();
        list[index] = { ...list[index], file: null, apiURL: data.url, bucketData: data };
        this.updatePhotoData({ list });
      } else {
        removeUploadedFileFromServer(data);
      }
    }
  };

  onCancelUploadphoto = photoId => {
    if (this.uploadTasks[photoId] && typeof this.uploadTasks[photoId] === 'function') {
      this.uploadTasks[photoId]();
    } else {
      this.onRemoveSinglePhoto({ _id: photoId });
    }
  };

  onEndingRearrangePhotos = result => {
    const { photoData } = this.state;
    const oldIndex = _.get(result, 'source.index');
    const newIndex = _.get(result, 'destination.index');

    if (isNumber(oldIndex) && isNumber(newIndex) && photoData.list[newIndex]) {
      const { photoData } = this.state;
      const list = photoData.list.slice();
      const newList = reorder(list, oldIndex, newIndex);
      this.updatePhotoData({ list: newList });
    }
  };

  onDrop = acceptedFiles => {
    // Do something with the files
    const videos = _.filter(acceptedFiles, file => file.type.includes('video'));
    const images = _.filter(acceptedFiles, file => file.type.includes('image'));

    if (videos.length) {
      this.selectVideos(videos);
    }

    if (images.length) {
      this.selectMultiplePhotos(images, true);
    }
  };

  renderVideoPreview = () => {
    const { videoData, validVideo } = this.state;
    const { fileType } = this.props;
    const { src, thumbnail, usingLink, isLocalFile } = videoData;
    const uploadConfigs = _.get(videoData, 'configs.uploadConfigs');

    if (videoData.file) {
      const formData = new FormData();
      formData.append(UPLOAD_KEY, videoData.file);

      return (
        <S.Preview hidePlayButton>
          <video src={src} type="video/mp4">
            <source src={src} type="video/mp4" />
          </video>
          <FileUpload
            onSuccess={this.onUploadVideoSuccess}
            onError={() => {}}
            onCancel={this.onCancelUploadVideo}
            configs={uploadConfigs}
          />
        </S.Preview>
      );
    }

    if (usingLink) {
      if (thumbnail) {
        return (
          <S.Preview thumbnail={this.getCloudUrl(thumbnail)}>
            <MediaModal video={src} presigned validVideo={validVideo} fileType={videoData.videoType || fileType} />
            <S.RemoveIcon onClick={this.onRemoveVideo} />
          </S.Preview>
        );
      }
    } else {
      return (
        <S.Preview thumbnail={this.getCloudUrl(thumbnail)}>
          {!thumbnail && <video src={src} />}
          <MediaModal video={src} validVideo={validVideo} fileType={videoData.videoType || fileType} />
          <S.RemoveIcon onClick={this.onRemoveVideo} />
        </S.Preview>
      );
    }

    return null;
  };

  renderVideo = () => {
    const { videoData } = this.state;
    const { src, error, usingLink } = videoData;
    const { maxSizeVideo } = this.props;

    return (
      <S.Section key="video">
        <S.SectionTitle>Video</S.SectionTitle>
        <S.VideoLink
          placeholder="Vimeo or Youtube link"
          onChange={this.onChangeVideoLink}
          ref={this.videoLinkInput}
          value={usingLink ? src : ''}
          disabled={!!src && !usingLink}
        />
        <S.VideoContainer>
          {src ? (
            <S.PreviewContainer>{this.renderVideoPreview()}</S.PreviewContainer>
          ) : (
            <SelectFile
              trigger={
                <S.UploadVideoButton>
                  <div className="icon" />
                  <span>Upload Video</span>
                </S.UploadVideoButton>
              }
              onSelect={file => this.selectVideos([file])}
              accept={FILE_VALIDATION.ACCEPTED_VIDEO}
              validateExtentions={FILE_VALIDATION.VIDEO_VALIDATE_EXTENTION}
              className="select-video-input"
              maxSize={maxSizeVideo || 120}
            />
          )}
        </S.VideoContainer>
        {error && <S.Error>{error}</S.Error>}
      </S.Section>
    );
  };

  getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, this.props.cloudfrontList, true);

  renderRearrangeUI = () => {
    const { photoData, uploadPhotoProgress, validVideo, videoData } = this.state;
    const { fileType } = this.props;
    const { list } = photoData;

    return (
      <DragDropContext onDragEnd={this.onEndingRearrangePhotos}>
        <Droppable droppableId="exercise-photos__droppable" direction="horizontal">
          {provided => (
            <S.ListPhoto ref={provided.innerRef} {...provided.droppableProps}>
              {_.map(list.concat(Array(4)).slice(0, 4), (data, index) => {
                if (!data) {
                  return (
                    <S.PhotoItemContainer key={index}>
                      <S.PhotoItem>
                        <SelectFile
                          trigger={<S.PhotoUploadTrigger />}
                          onSelect={this.selectMultiplePhotos}
                          accept={FILE_VALIDATION.ACCEPTED_IMAGE}
                          validateExtentions={FILE_VALIDATION.IMAGE_VALIDATE_EXTENTION}
                          maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
                          multiple
                        />
                      </S.PhotoItem>
                    </S.PhotoItemContainer>
                  );
                }

                return (
                  <Draggable key={index} draggableId={`draggable;${index}`} index={index}>
                    {provided => (
                      <S.PhotoItemContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={index}
                      >
                        <S.PhotoItem thumbnail={this.getCloudUrl(data.src)}>
                          {data.file ? (
                            <UploadStyles.Wrapper>
                              <UploadStyles.Container>
                                <ProgressRing
                                  radius={22}
                                  progressBarWidth={3}
                                  progress={uploadPhotoProgress[data._id] || 0}
                                />
                                <UploadStyles.CancelButton
                                  onClick={() => this.onCancelUploadphoto(data._id)}
                                  className="file-upload__cancel-button"
                                />
                              </UploadStyles.Container>
                            </UploadStyles.Wrapper>
                          ) : (
                            <>
                              <MediaModal
                                image={data.src}
                                presigned
                                validVideo={validVideo}
                                fileType={videoData.videoType || fileType}
                              />
                              <S.RemoveIcon onClick={() => this.onRemoveSinglePhoto({ _id: data._id })} />
                            </>
                          )}
                        </S.PhotoItem>
                      </S.PhotoItemContainer>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </S.ListPhoto>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  renderPhotos = () => {
    const { photoData } = this.state;
    const { list, error } = photoData;
    const { msgMaxSizeImg } = this.props;

    return (
      <S.Section key="photos">
        <S.SectionTitle>Photos</S.SectionTitle>
        {list.length ? (
          this.renderRearrangeUI()
        ) : (
          <>
            <S.UploadPhotoArea>
              <div>
                Drag and drop up to 4 images here or&nbsp;
                <SelectFile
                  trigger={<span className="choose-file">Choose file</span>}
                  onSelect={this.selectMultiplePhotos}
                  accept={FILE_VALIDATION.ACCEPTED_IMAGE}
                  validateExtentions={FILE_VALIDATION.IMAGE_VALIDATE_EXTENTION}
                  multiple={true}
                  maxSize={LIMIT_SIZE_IMAGE_UPLOAD_MB}
                  fileSizeLimitMessage={msgMaxSizeImg}
                />
              </div>
            </S.UploadPhotoArea>
            <S.AcceptNote>Accepted: jpg, jpeg, png</S.AcceptNote>
          </>
        )}
        {error && <S.Error>{error}</S.Error>}
      </S.Section>
    );
  };

  render() {
    return (
      <S.Wrapper>
        <Dropzone onDrop={this.onDrop} multiple={true} accept={DROP_ACCEPTED_FILES} maxSize={maxFileSize}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Container {...getRootProps()}>
              <S.Header>
                <span>Media</span>
              </S.Header>
              <S.Content>
                {this.renderVideo()}
                {this.renderPhotos()}
              </S.Content>
              <input {...getInputProps({ onClick: event => event.preventDefault() })} />
              <DropZonePlaceholder show={isDragActive} />
            </S.Container>
          )}
        </Dropzone>
      </S.Wrapper>
    );
  }
}

MediaSide.propTypes = {};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState, { getS3presignedURLFromLocalDatabase })(MediaSide);
