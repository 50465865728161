import styled from 'styled-components';

export const CloseText = styled.div`
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  background: #3f3f3f;
  border-radius: 54px;
  padding: 10px 50px;
  font-size: 14px;
  line-height: 100%;
  color: #c9c9c9;
  z-index: 3;
`;

export const Guide = styled.div`
  position: absolute;
  z-index: 2;
  width: 260px;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;

  .index {
    font-family: Comic Sans MS;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
  }

  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    padding-top: 3px;
  }

  &.guide--checklist {
    top: 166px;
    right: 405px;

    .text {
      font-size: 18px;
      padding-top: 7px;
    }

    .index {
      font-size: 24px;
    }

    .arrow {
      position: absolute;
      left: 192px;
      bottom: calc(100% + 2px);
    }

    @media screen and (max-width: 1250px) {
      top: 145px;
    }
  }
`;

export const CheckListWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 360px;
  height: 100%;
  box-shadow: none;

  ${Guide} {
    left: -306px;
    top: 160px;

    .text {
      font-size: 18px;
      padding-top: 7px;
    }

    .index {
      font-size: 24px;
    }

    .arrow {
      position: absolute;
      left: 192px;
      bottom: calc(100% + 2px);
    }
  }

  @media screen and (max-width: 1250px) {
    ${Guide} {
      top: 145px;
    }
  }
`;

export const ClientGuideWrapper = styled.div`
  border: 4px solid transparent;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  left: 318px;
  top: 208px;
  max-width: 400px;
  overflow: visible;
  white-space: nowrap;
  background-color: rgba(116, 112, 239, 0.3);

  .guide__container {
    border: 2px solid #7470ef;
    border-radius: 5px;
    background-color: #fff;
    min-height: 110px;
    overflow: hidden;

    .guide__client {
      padding: 13px 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .sb-avatar {
        margin-right: 18px;
        flex: 0 0 29px;
      }

      .name {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #263d52;
        overflow: hidden;
      }
    }
  }

  ${Guide} {
    left: 15px;
    top: calc(100% + 98px);
    white-space: initial;

    .arrow {
      position: absolute;
      left: 53px;
      bottom: calc(100% + 5px);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2147483103;
`;
