import styled from 'styled-components';

import ButtonIcon from 'shared/ButtonIcon';
import { BannerImage } from 'shared/SingleBanner/style';

export const BannerWrapper = styled.div`
  width: 100%;
  padding: 30px;
  margin-bottom: 2px;

  .bannerContainer {
    width: 100%;
    margin-bottom: 0;

    .single__banner__learnMoreLink {
      margin-left: 10px;
    }

    ${BannerImage} {
      width: 389px;
      height: 239px;
    }

    .buttons__container {
      .ui.button {
        width: 157px;
        padding-left: 11px;
        background-color: #5e59ff !important;

        :hover {
          background-color: #5e59ff !important;
          opacity: 0.9;
        }
      }

      .button--search-box-trigger {
        border: 1px solid #4b90f1;

        svg {
          path {
            stroke: #4b90f1;
          }
        }
      }
    }
  }
`;

export const Button = styled(ButtonIcon)`
  button {
    color: #fff;
    width: 184px;
    height: 40px;
    padding: 10px 15px;
    font-family: 'Open Sans' !important;
    background-color: #5158cf !important;
    border: none !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #5559ff !important;
    }

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }
`;
