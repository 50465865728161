import { connect } from 'react-redux';
import Header from './component';
import {
  deleteSuperset,
  duplicateSuperset,
  moveExerciseIntoSection,
  updateExerciseLibrary,
} from 'redux/workout-builder/actions';
import { markWorkoutBuilderGuide, runOnboarding } from 'redux/onboarding/actions';
import { getExerciseDetail } from 'redux/exercise/exercise.actionCreators';
import { showError } from 'actions/error';

const mapState = state => {
  const {
    rootReducer: {
      exercise: { mostRecents },
      client,
      workoutBuilder,
      onboarding: {
        workoutBuilderGuideSteps: { loaded, create_superset, create_exercise_section, custom_options },
      },
    },
  } = state;

  const allSections = workoutBuilder.getIn(['selectedWorkout', 'sections']);

  return {
    mostRecents,
    selectedClient: client.workingClientDetail,
    allSections,
    loadedWorkoutOnboardingData: loaded,
    alreadyCreateSuperset: create_superset,
    alreadyDragAndDropExercise: create_exercise_section,
    alreadyCustomOptions: custom_options,
  };
};

export default connect(mapState, {
  deleteSuperset,
  getExerciseDetail,
  duplicateSuperset,
  moveExerciseIntoSection,
  updateExerciseLibrary,
  markWorkoutBuilderGuide,
  runOnboarding,
  showError,
})(Header);
