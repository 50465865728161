import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import className from 'classnames';
import _ from 'lodash';
import queryString from 'query-string';
import { PromoWrapper } from './style';
import { FormGroup, ErrorMessage } from 'shared/FormControl';
import { bindActionCreators } from 'redux';
import { checkPromoCode } from 'redux/pricing/actions';
import { TRIAL_MODE } from 'constants/commonData';
import { getAddOnsCoupon } from 'components/PricingPlan/helper';

function PrommoCode(props) {
  const [isAdd, setAdd] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const { selectPackage, teamData, addOnsPricing, hasPromoCode, checkSubmitPromoCodeRef } = props;
  const isInvalid = (selectPackage.promoCode && !selectPackage.promoCode.valid) || (hasPromoCode && !submitting);
  const params = queryString.parse(window.location.search);
  const addOns = getAddOnsCoupon(selectPackage, addOnsPricing);

  useEffect(() => {
    const code = _.get(params, 'coupon', '');
    if (code) {
      setPromoCode(code);
      setAdd(true);
      handleCheckPromoCode(code);
    }
  }, []);

  useEffect(() => {
    if (checkSubmitPromoCodeRef) checkSubmitPromoCodeRef.current = checkSubmitPromoCode;

    return () => {
      if (checkSubmitPromoCodeRef) checkSubmitPromoCodeRef.current = null;
    };
  }, [checkSubmitPromoCodeRef]);

  const onChangePromoCode = e => {
    setErrorMsg(null);
    setPromoCode(e.target.value);
    props.setHasPromoCode && props.setHasPromoCode(true);
  };

  const handleAddPromoCode = e => {
    setAdd(true);
    setErrorMsg(null);
    props.setHasPromoCode && props.setHasPromoCode(false);
  };

  const removePromoCode = e => {
    setAdd(false);
    setErrorMsg(null);
    setPromoCode('');
    props.setHasPromoCode && props.setHasPromoCode(false);
  };

  const checkSubmitPromoCode = () => {
    setErrorMsg('Code has not been added. Click submit or x to cancel.');
  };

  const handleCheckPromoCode = code => {
    setSubmitting(true);
    props.setHasPromoCode && props.setHasPromoCode(false);
    props
      .checkPromoCode({ code: code })
      .then(response => {
        if (_.get(response.data, 'data.valid', false)) {
          // for downgrade automation add ons
          const isDowngradeAddOns =
            teamData.trialMode !== TRIAL_MODE.Active &&
            _.get(addOns, 'automation', false) * 1 < _.get(teamData, 'addOns.automation', false) * 1;

          const isDowngradePaymentPackageAddOns =
            teamData.trialMode !== TRIAL_MODE.Active &&
            _.get(addOns, 'payment_package', false) * 1 < _.get(teamData, 'addOns.payment_package', false) * 1;

          const isDowngradeResourceAddOns =
            teamData.trialMode !== TRIAL_MODE.Active &&
            _.get(addOns, 'resource_collections', false) * 1 <
              _.get(teamData, 'addOns.resource_collections', false) * 1;

          const isNoChangeNoClient =
            _.get(selectPackage, 'packagePrice.packageNoClients', 0) === _.get(teamData, 'packageNoClients', 0);

          const promoCodeAddOns = _.get(response.data, 'data.metadata.add_ons');

          if (
            (teamData.tier === selectPackage.tier &&
              teamData.period === selectPackage.period &&
              isNoChangeNoClient &&
              (isDowngradeAddOns || isDowngradePaymentPackageAddOns || isDowngradeResourceAddOns) &&
              teamData.promoCode !== promoCode.trim()) ||
            (promoCodeAddOns && !addOns[promoCodeAddOns])
          ) {
            const errorMessage = _.get(response, 'data.data.message', 'Code is not valid');
            setErrorMsg(errorMessage);
          } else {
            setAdd(false);
            setPromoCode('');
          }
        } else {
          const errorMessage = _.get(response, 'data.data.message', 'Code is not valid');
          setErrorMsg(errorMessage);
        }
      })
      .catch(error => {
        const errorMessage = _.get(error, 'data.data.message', 'Code not found');
        setErrorMsg(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (selectPackage.promoCode && selectPackage.promoCode.valid) {
    return null;
  }

  return (
    <PromoWrapper>
      {isAdd ? (
        <>
          <FormGroup className={className('promoCode__adding', { error: isInvalid && errorMsg })}>
            <input
              autoFocus
              value={promoCode}
              disabled={submitting}
              onChange={onChangePromoCode}
              className="promoCode__input"
              placehoder="Promo Code"
            />
            <button
              disabled={!promoCode.trim() || submitting}
              onClick={() => handleCheckPromoCode(promoCode.trim())}
              className="promoCode__btnSubmit"
            >
              Submit
            </button>
            <div className="promoCode__removeIcon" onClick={removePromoCode} />
          </FormGroup>
          {isInvalid && errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        </>
      ) : (
        <span onClick={handleAddPromoCode} className="promoCode__btnAdd">
          Add Promo Code
        </span>
      )}
    </PromoWrapper>
  );
}

const mapState = state => {
  const { rootReducer } = state;

  return {
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkPromoCode: bindActionCreators(checkPromoCode, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(PrommoCode);
