import styled from 'styled-components';

export const Container = styled.div`
  .name {
    font-size: 20px;
    line-height: 150%;
    margin-bottom: -2px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .toggle-input {
      font-weight: bold;
      font-size: 11px;
      color: #bdbdbd;
      margin-left: 10px;
      display: block;

      input ~ label::before {
        background-color: #c4c4c4;
      }

      input:checked ~ label::before {
        background-color: #2fd787;
      }

      label {
        display: block;
      }

      &.checked {
        color: #333333;
      }
    }

    .__react_component_tooltip {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      min-width: unset;
    }
  }

  .nav-menu {
    margin-top: 0;

    .nav-menu__item {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      padding-bottom: 10px;
      color: #858585;
      position: relative;
      margin: 0 18px;
      text-decoration: none !important;

      :first-child {
        margin-left: 0;
      }

      ::after {
        content: '';
        position: absolute;
        display: none;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #494699;
        left: 0;
      }

      &.active {
        color: #494699;

        ::after {
          display: block;
        }
      }

      .extend {
        font-weight: 600;
      }
    }
  }
`;

export const AutoflowName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
