import React, { useState, useEffect } from 'react';

import { PACKAGE_STATUS } from 'constants/commonData';
import FeatureBanner from 'shared/FeatureBanner';
import DefaultProductBannerImage from 'assets/images/default_product_banner.png';

import Header from './Header';
import List from './List';

import { ReactComponent as LearMoreIcon } from 'assets/icons/learn_more_icon.svg';
import * as S from './style';

const bannerSliderMockup = [
  {
    title: 'Introducing Sequences',
    titleOfDescription: 'Automate Program Delivery with a Purchase',
    description:
      'Sequences allow you to instantly assign programs and onboarding flows when a client purchases your package.',
    imageSrc: DefaultProductBannerImage,
  },
];

const handleRenderLinkOnBanner = () => {
  return (
    <S.ButtonArea>
      <S.Link
        href="https://help.everfit.io/en/articles/6047264-introducing-products-an-automation-for-payment-packages"
        target="_blank"
      >
        <LearMoreIcon />
        <S.LinkText>Set up automated Sequences</S.LinkText>
      </S.Link>

      <S.Link href="https://help.everfit.io/en/articles/6062108-how-to-add-a-product-to-a-package" target="_blank">
        <LearMoreIcon />
        <S.LinkText>How to add a Sequence to a Package</S.LinkText>
      </S.Link>
    </S.ButtonArea>
  );
};

const ProductList = props => {
  const {
    isHideBanner,
    onHideProductBanner,
    counter,
    onAddNewProduct,
    product,
    loading,
    onGetListProduct,
    query,
    push,
    onGetProductEdit,
    getProductDetail,
    archiveProduct,
    deleteProduct,
    unArchiveProduct,
    duplicateProduct,
    duplicating,
    productTotal,
    toggleConfirmModal,
    updateQuery,
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(product);
  }, [props]);

  useEffect(() => {
    return () => {
      updateQuery &&
        updateQuery({
          page: 1,
          q: '',
          status: '',
          isEnd: false,
        });
      onGetListProduct && onGetListProduct();
    };
  }, []);

  const handleHideBanner = () => {
    onHideProductBanner();
  };

  useEffect(() => {
    let index = 0;
    switch (query.status) {
      case PACKAGE_STATUS.PUBLISH:
        index = 1;
        break;
      case PACKAGE_STATUS.DRAFT:
        index = 2;
        break;
      case PACKAGE_STATUS.ARCHIVE:
        index = 3;
        break;
      default:
        index = 0;
        break;
    }
    setActiveIndex(index);
  }, [query]);

  const handleTabChange = idx => {
    let status = '';
    switch (idx) {
      case 1:
        status = PACKAGE_STATUS.PUBLISH;
        break;
      case 2:
        status = PACKAGE_STATUS.DRAFT;
        break;
      case 3:
        status = PACKAGE_STATUS.ARCHIVE;
        break;
      default:
        status = '';
        break;
    }
    updateQuery({ status, page: 1, isEnd: false });
    onGetListProduct();
  };

  const handleSearchChange = value => {
    updateQuery({ page: 1, q: value, isEnd: false });
    onGetListProduct();
  };

  return (
    <S.Wrapper>
      <FeatureBanner
        data={bannerSliderMockup}
        autoplay={false}
        autoplaySpeed={5000}
        speed={1000}
        infinite={true}
        rightToLeft={false}
        fade={false}
        isSlide={isHideBanner}
        wrapperClass="featureBanner"
        onHideBanner={handleHideBanner}
      >
        {handleRenderLinkOnBanner()}
      </FeatureBanner>
      <Header
        counter={counter}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
        onAddNewProduct={onAddNewProduct}
        productTotal={productTotal}
        onSearchProduct={handleSearchChange}
      />
      <List
        products={products}
        productTotal={productTotal}
        push={push}
        getProductDetail={getProductDetail}
        onGetProductEdit={onGetProductEdit}
        archiveProduct={archiveProduct}
        deleteProduct={deleteProduct}
        unArchiveProduct={unArchiveProduct}
        duplicateProduct={duplicateProduct}
        duplicating={duplicating}
        isHideBanner={isHideBanner}
        onAddNewProduct={onAddNewProduct}
        toggleConfirmModal={toggleConfirmModal}
        loading={loading}
      />
    </S.Wrapper>
  );
};

export default ProductList;
