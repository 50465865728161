import styled from 'styled-components';

export const WarningWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    max-width: 275px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    color: #202353;
    overflow: hidden;
  }

  .__react_component_tooltip.ai-tooltip.ex-tooltip::after {
    bottom: -10px !important;
    border-top-color: rgb(33, 33, 33, 0.95);
  }
`;

export const ExerciseItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &.exercise-not-existed {
    border: 1px solid #DADFEA;
    background-color: #F7F8FC;
    border-radius: 5px;
    padding: 8px 5px 8px 14px;
    margin-bottom: 10px;
    justify-content: space-between;

    &:hover {
      border-color: #5158cf;
    }
  }

  .index {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #979797;
    margin-right: 4px;
    &.expand {
      min-width: 20px;
      text-align: right;
    }
  }

  .media-preview {
    flex: 0 0 30px;
  }

  .create-new-ex {
    background-color: transparent;
    color: #5158cf;
    height: 26px;
    display: flex;
    align-items: center;
    border: 1px solid #c9cbf4;
    border-radius: 4px;
    padding: 4px 6px;
    height: 26px;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.7px;

    &:hover {
      border-color: #dee0ff;
      background-color: #dee0ff;
      cursor: pointer;
    }
  }

  .info {
    background: #ffffff;
    border: 1px solid #dadfea;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    position: relative;
    flex: 1 1;
    overflow: hidden;
    cursor: pointer;

    .title {
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #444444;
      margin-left: 8px
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1;
    }

    .exercise-item__sort {
      margin: 0 10px;
      flex: 0 0 15px;

      :hover {
        cursor: grab;

        rect {
          fill: #5158cf;
        }
      }
    }

    :hover, &.clicked {
      border-color: #5158cf;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  .remove {
    display: none;
  }

  :hover {
    .remove {
      display: block;
    }
  }
`;

export const ExerciseMenu = styled.div`
  border-radius: 5px;
  overflow: auto;
  padding: 10px 15px 10px 15px;
  height: 148px;
`;

export const Body = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #444444;
  padding: 0;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: transparent;

  button {
    margin: 0;
    margin-left: 5px;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818da1;
    padding: 6px 34px;
    border: 1px solid #d6dae4;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: #ffffff;

    &.cancel {
      padding: 6px 24px;
      :hover {
        background-color: #f5f7f9;
      }
    }

    &.save {
      background-color: #5158cf;
      border-color: #5158cf;
      color: #fff;
      :hover {
        box-shadow: 0 5px 14px 0 rgba(92, 91, 189, 0.37);
      }
    }
  }
`;

export const Wrapper = styled.div`
  border: 1px solid #dadfea;
  padding: 15px 0 0 0;
  margin-top: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  &.warning {
    border: 1px solid #fd6666;
  }

  .remove-all {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5158cf;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  .search-exercise-container {
    margin-bottom: 0;
    padding: 0 15px;
  }
`;
