// Lib
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Store
import { toggleModal, toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import {
  getDetailMealPlan,
  getDetailMealPlanByWeek,
  updateMealPlanByWeek,
  resetDetailMealPlan,
  resetDetailMealPlanByWeek,
  changeModeEditByDay,
  addWeekInMealPlan,
  removeWeekInMealPlan,
  getAssignedList,
  updateDetailMealPlanByWeek,
  updateDataDaysByWeek,
} from 'redux/meal-plans/actions';

// Shared
import Component from './component';

const mapStateToProps = state => {
  const {
    rootReducer: { mealPlans },
    user,
  } = state;

  const detailMealPlan = get(mealPlans, 'detailMealPlan', {});
  const detailMealPlanByWeek = get(mealPlans, 'detailMealPlanByWeek', {});
  const rearrangeMode = get(mealPlans, 'rearrangeMode', {});

  return { detailMealPlan, detailMealPlanByWeek, rearrangeMode, currentUser: user };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getDetailMealPlan: bindActionCreators(getDetailMealPlan, dispatch),
  getDetailMealPlanByWeek: bindActionCreators(getDetailMealPlanByWeek, dispatch),
  updateMealPlanByWeek: bindActionCreators(updateMealPlanByWeek, dispatch),
  resetDetailMealPlan: bindActionCreators(resetDetailMealPlan, dispatch),
  resetDetailMealPlanByWeek: bindActionCreators(resetDetailMealPlanByWeek, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  changeModeEditByDay: bindActionCreators(changeModeEditByDay, dispatch),
  addWeekInMealPlan: bindActionCreators(addWeekInMealPlan, dispatch),
  removeWeekInMealPlan: bindActionCreators(removeWeekInMealPlan, dispatch),
  getAssignedList: bindActionCreators(getAssignedList, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  updateDetailMealPlanByWeek: bindActionCreators(updateDetailMealPlanByWeek, dispatch),
  updateDataDaysByWeek: bindActionCreators(updateDataDaysByWeek, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
