import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import ButtonIcon from 'shared/ButtonIcon';
import FormItemInput from 'shared/FormItemInput';

export const Button = styled(ButtonIcon)`
  ${props => props.disabled && `cursor: not-allowed;`}
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }

  &.buttonClassName {
    button {
      margin-right: 0;
      &:hover,
      &:focus,
      &:active {
        background: #5559ff !important;
      }
    }
  }
`;

export const CreateAddNewModal = styled(Modal)`
  &.ui.modal {
    width: 560px;
  }

  .header {
    color: #202353 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    padding: 24px 24px 0 24px !important;
    border-radius: 8px;
  }

  .content {
    padding: 20px 24px !important;
  }

  .formControl {
    margin-bottom: 13px;
    > div {
      color: #9a9da6;
      font-weight: 700;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.01em;
      margin-bottom: 4px;
    }
    .label__maxLength {
      color: #6a778a;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .cancel {
    color: #151619;
    padding: 10px 34px !important;
    border: 1px solid #c3c5cb;
    margin-right: 12px;
  }

  .submit {
    padding: 10px 27px !important;

    &:disabled {
      background: #c4c4c4;
    }
    &:hover,
    &:focus,
    &:active {
      background: rgb(85, 89, 255) !important;
    }
  }

  .load-more {
    padding-top: 26px;
    padding-bottom: 1rem;
    .ui.small.loader:before {
      border-color: rgb(142 142 142 / 15%);
    }
    .ui.small.loader:after {
      border-color: #5158cf transparent transparent;
    }
  }
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-top: 4px;
  margin-bottom: 0;
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 48px;
  justify-content: flex-end;
  ${props =>
    props.hasLabel &&
    css`
      justify-content: space-between;
    `}
`;

export const Form = styled.form``;

export const Input = styled(FormItemInput)``;
