import styled, { css } from 'styled-components';

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 19px 5px;
  width: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: #fff;

  .item {
    font-weight: bold;
    font-size: 5.95169px;
    line-height: 8px;
    color: #8f99a3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.use-fill {
      & svg path {
        fill: #8f99a3;
      }
    }

    &.active.use-fill {
      & svg path {
        fill: ${props => props.primaryColor};
        stroke: none;
      }
    }

    &.active {
      color: ${props => props.primaryColor};

      & svg path {
        fill: none;
        stroke: ${props => props.primaryColor};
      }
    }
  }
`;
