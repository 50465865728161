import styled, { css } from 'styled-components';

export const SettingWrapper = styled.div`
  line-height: 0;
  .settings-accordion .title.advanced-settings-title {
    margin: 0 10px 10px 0 !important;
    display: inline-flex;
    align-items: center;
    font-family: 'Open Sans' !important;
    padding: 0 !important;
  }
  .advanced-settings-title {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 13.62px !important;
    text-transform: capitalize;
    margin: 0px 5px;
    color: #758094 !important;
  }

  .settings-icon {
    margin-right: 5px !important;
  }

  .accordion-icon {
    margin-left: 9px;
    transition: transform 0.2s ease, opacity 0.2s ease;
    ${props => props.active && `transform: rotate(180deg) translateY(2px);`};
  }

  .settings-accordion .content.active {
    margin-top: 7px;
    margin-bottom: 0px;
    border-radius: 4px;

    > div:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  :hover {
    span {
      color: #5158cf !important;
    }
    svg path {
      fill: #5158cf;
    }
  }
`;
