import styled, { css } from 'styled-components';
import S3Images from 'shared/S3Images';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7B7E91;
`;

export const FilterCategoryWrapper = styled.div`
  position: relative;
`;

export const List = styled.div`
  overflow: auto;
  white-space: nowrap;
  text-overflow: unset;
  ::-webkit-scrollbar {
    height: 0 !important;
  }
`;

export const Item = styled.div`
  background: #f0f0f2;
  border-radius: 5px;
  margin-right: 10px;
  width: 155px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  padding: 5px 32px 5px 5px;
  gap: 7px;
  position: relative;
  span {
    ${baseText}
    font-size: 11px;
    line-height: 17px;
    color: #202353;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: break-spaces;
  }
  .close-btn {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    border-radius: 50%;
    cursor: pointer;
    svg {
      width: 10px;
      height: 10px;
      path {
        fill: #a3a3b5;
      }
    }
    &:hover {
      background: #e3e4fa;
      svg path {
        fill: #5158cf;
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Thumbnail = styled(S3Images)`
  display: flex;
  img {
    width: 42px;
    height: 28px;
    object-fit: cover;
    border-radius: 5px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 54px;
  pointer-events: none;
  &.left {
    left: -2px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) -16.72%, #ffffff 53.16%, #ffffff 100%);
  }
  &.right {
    right: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) -16.72%, #ffffff 53.16%, #ffffff 100%);
  }
`;

export const Button = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg path {
    fill: #a3a3b5;
  }
  &:hover {
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }
  &.left {
    left: 0;
    svg {
      transform: rotate(-90deg);
    }
  }
  &.right {
    right: 0;
    svg {
      transform: rotate(90deg);
    }
  }
`;
