import styled from 'styled-components';
import { FormGroup } from 'shared/FormControl';

export const Message = styled.div`
  margin-top: 10px;
  background: #F3F5F8;
  border-radius: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #4E5662;
`;

export const Container = styled.form`
  .buttons {
    padding-top: 30px;
    text-align: right;

    button { 
      padding-top: 6px;
      padding-bottom: 6px;
      width: 136px;
      line-height: 18px;
    }
  }

  .ui.dropdown.sharing-dropdown {
    width: 100%;
    border-radius: 5px !important;
    background: #FAFBFC;
    border: 1px solid #DCDCDE;
    padding: 8px 15px !important;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #323C47;

    .text {
      font-weight: 600 !important;
      color: #323C47 !important;
    }

    :hover {
      border: 1px solid #DCDCDE;
    }

    &.active.visible {
      border-color: #5C5BBD;
      border-radius: 5px !important;
    }

    > .dropdown-icon {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }

    .menu {
      top: calc(100% + 4px);
      border: solid 1px #dfe4e5 !important;
      box-shadow: 0 5px 12px 2px rgba(0,0,0,0.1);
      background-color: #fff;
      border-radius: 4px;

      .item {
        font-size: 13px;
        font-weight: normal;
        line-height: 13px;
        letter-spacing: normal;
        color: #333333;
        border: none;
        padding: 11px 15px !important;
        color: #323C47;

        :hover,
        &.active {
          background-color: #edecfc;
          color: #5c58c0;
        }
      }
    }
  }

  ${FormGroup} {
    &.owner {
      margin-bottom: 0;

      .owner__info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #FAFBFC;
        border: 1px solid #DCDCDE;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 15px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #263D52;

        .sb-avatar {
          margin-right: 10px;
          flex: 0 0 29px;
        }

        .you {
          font-weight: normal;
        }
      }
    }
  }
`;