import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #323c47 !important;
`;

export const Content = styled.div`
  border-radius: 5px;
  user-select: auto;
  min-height: 36px;
  cursor: text;

  // For capitalize first case by CSS
  p {
    ${baseText}
    position: absolute;
    top: 8px;
    left: 15px;
    pointer-events: none;
  }

  p::first-letter {
    text-transform: capitalize;
  }
`;

export const Item = styled.div`
  margin-bottom: 5px;
  width: 100%;
  outline: none;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .icon-remove {
    display: none;
    cursor: pointer;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 1;
  }

  &:hover {
    .icon-remove {
      display: block;
    }
    ${Content} {
      border-color: #5158cf;
    }
  }

  ${props =>
    props.open &&
    css`
      ${Content} {
        border-color: #5158cf;
      }

      .icon-remove {
        display: block;
      }
    `}
`;

export const TextEditorWrapper = styled.input`
  white-space: nowrap;
  overflow: auto;
  text-overflow: clip;
  caret-color: black;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
  color: transparent !important;

  ::selection {
    color: transparent;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  :empty::before {
    ${baseText}
    color: #969FB5;
    opacity: 1;
    position: relative;
  }
`;
