import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import ImageAddIcon from 'assets/icons/upload_progress_photo.svg';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
`;

export const AddProgressPhoto = styled(Modal)`
  &.new-add-progress-photo.ui.modal {
    width: 742px;
    .close-button {
      right: -12px;
      top: -12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  &.new-add-progress-photo.ui.modal > :first-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 24px 24px 0px;
  }
  &.new-add-progress-photo.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 18px 20px;
  }
  &.new-add-progress-photo.ui.modal > .content {
    padding: 0 0 24px;
  }
  &.new-add-progress-photo.ui.modal > .actions {
    text-align: unset;
  }
`;

export const HeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${baseText}
`;

export const HeaderTitle = styled.div`
  color: #202353;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
`;

export const DatePicker = styled.div`
  .rdt.new-ui {
    &.rdtOpen .progress-photo-calender {
      border-color: #5158cf !important;
    }
    .progress-photo-calender.custom-date-input {
      width: 156px;
      min-width: 156px !important;
      height: 40px;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #dadfea;
      background-color: #fff;
      color: #202353;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      ${baseText}
      column-gap: 8px;
      justify-content: flex-start;
      &:hover {
        border-color: #5158cf !important;
      }
    }
    table {
      border-collapse: collapse;
    }
    thead tr th.dow {
      ${baseText}
      font-size: 14px;
      line-height: 100%;
      color: #202353;
      padding: 15px 10px;
      border-bottom: 1px solid #f6f6f6;
    }
    thead tr:first-child th {
      padding-bottom: 22px;
    }
    .rdtPicker {
      width: 356px;
      padding: 24px;
      margin-top: 4px;
      border-radius: 8px;
      background-color: #fdfdfe;
      box-shadow: 0px 4px 16px 0px rgba(38, 38, 38, 0.2);
      right: 0;
      .rdtSwitch {
        ${baseText}
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #202353;
        position: relative;
        height: unset;
        top: -2px;
        right: 0;
        :hover {
          color: #5158cf;
        }
      }
      .rdtPrev {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/schedule-calendar-change-month.svg);
          position: absolute;
          top: 24px;
          left: 22.5px;
        }
      }
      .rdtNext {
        span {
          display: none;
        }
        &::after {
          content: url(${CDN_URL}/images/schedule-calendar-change-month.svg);
          display: inline-block;
          transform: rotate(180deg);
          position: absolute;
          top: 24px;
          right: 22.5px;
        }
      }
    }
    .rdtDay {
      width: 44px;
      height: 44px;
      ${baseText}
      color: #202353;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      &::before {
        background-color: #f3f5f8;
        width: 34px;
        height: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.rdtActive::before {
        background-color: #5158cf;
      }
      &.rdtToday {
        color: #5158cf;
        font-weight: 700;
      }
      &.rdtDisabled {
        ${baseText}
        color: #202353;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        opacity: 0.3;
      }
      &.rdtToday.rdtActive {
        color: #fff !important;
        font-weight: 600 !important;
      }
      &.rdtActive {
        color: #fff !important;
      }
    }
    .rdtDay:not(.rdtDisabled):not(.rdtActive):hover {
      color: #202353 !important;
      font-weight: 600 !important;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const MediasWrapper = styled.div`
  height: 383px;
  width: auto;
  padding-top: 20px;
  overflow-x: hidden;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    height: 8px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 24px !important;
    background: #d9d9d9 !important;
  }
  ::-webkit-scrollbar-track {
    margin: 0 24px;
  }
`;

export const MediaList = styled.div`
  height: 100%;
  width: max-content;
  display: flex;
  column-gap: 16px;
  flex-wrap: nowrap;
  position: relative;
`;

export const MediaItem = styled.div`
  width: 196px;
  height: 339px;
  border-radius: 8px;
  position: relative;
  ${props =>
    props.isCypress &&
    css`
      background: #f5f5f5;
    `}
  &:first-child {
    margin-left: 24px;
  }
  &:last-child {
    margin-right: 24px;
  }
  .progress-photo-medias {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .file-upload-cancel-button {
    width: 18px;
    height: 18px;
    background-size: 8px auto;
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: -12px;
  right: -12px;
  z-index: 2;
  cursor: pointer;
`;

export const ProgressPhotoZone = styled.div`
  height: 363px;
  width: auto;
  margin: 20px 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px dashed #dadfea;
  background: #fafbfc;
  position: relative;
  cursor: pointer;
  ${props =>
    props.isDragging &&
    css`
      border-color: #7470ef;
    `}
  :hover {
    border-color: #7470ef;
  }
  :focus {
    outline: none !important;
  }
`;

export const MediaUpload = styled(ProgressPhotoZone)`
  height: 339px;
  width: 196px;
  margin: unset;
  margin-right: 24px;
`;

export const UploadIcon = styled.div`
  margin-bottom: 15px;
  width: 38px;
  height: 40px;
  background: transparent url(${ImageAddIcon}) no-repeat center;
  background-size: contain;
`;

export const UploadContent = styled.div`
  color: #7a7a7a;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  ${baseText}
  .custom-upload-content {
    color: #5158cf;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const MetricsWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin: 0 24px;
`;

export const MetricItem = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  input {
    height: 36px;
    ${baseText}
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    border: none !important;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
    /* Works for Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Works for Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export const MetricName = styled.div`
  ${baseText}
  color: #6A778A;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
`;

export const MetricWeight = styled.div`
  display: flex;
  border: 1px solid #dadfea;
  border-radius: 4px;
  width: 200px;
  height: 36px;
  .progress-photo-weight-input {
    width: 162px !important;
    border-radius: 4px 0 0 4px;
    height: 34px;
    padding: 9px 10px;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Open Sans';
  }
  ${props =>
    props.isError &&
    css`
      border-color: #ea314a !important;
    `}
`;

export const MetricUnit = styled.span`
  width: 38px;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid #dadfea;
  background: #fafbfc;
  ${baseText}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MetricFat = styled.div`
  position: relative;
  border: 1px solid #dadfea;
  border-radius: 4px;
  width: 100%;
  height: 36px;
  .progress-photo-bodyfat-input {
    width: 162px !important;
    border-radius: 4px 0 0 4px;
    height: 34px;
    padding: 9px 10px;
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Open Sans';
  }
  ${props =>
    props.isError &&
    css`
      border-color: #ea314a !important;
    `}
`;

export const IconFat = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  svg {
    fill: #758094;
  }
`;

export const ActionWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  ${baseText}
`;

export const ActionsError = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ErrorContent = styled.div`
  color: #ff4d4f;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const ActionsButton = styled.button`
  width: 77px;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  background-color: #bfbfbf;
  user-select: none;
  cursor: not-allowed;
  ${props =>
    props.isSave &&
    css`
      cursor: pointer;
      background-color: #5158cf;
      :hover {
        opacity: 0.9;
      }
    `}
`;

export const ButtonTitle = styled.span`
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  ${baseText}
`;

export const TagWrapper = styled.div`
  width: 100px;
  height: auto;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container {
    width: 447px !important;
  }
  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }
  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    background-color: #ec465c !important;
  }
  &.progress-photo-confirm-modal {
    .confirm-content-header {
      padding: 30px 30px 0;
    }
    .confirm-header-label {
      color: #202353;
      line-height: 25px;
      ${baseText}
    }
    .confirm-content-body {
      padding: 15px 30px 40px;
    }
    .confirm-content-label {
      color: #202353;
      line-height: 18px;
      ${baseText}
    }
    .confirm-no-button,
    .confirm-yes-button {
      height: 30px;
      border-radius: 5px !important;
      line-height: 19.5px !important;
      padding: 5px 30px !important;
      ${baseText}
    }
    .confirm-no-button {
      min-width: 140px;
    }
    .confirm-yes-button {
      min-width: 130px !important;
      box-shadow: unset !important;
    }
  }
`;
