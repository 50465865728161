import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import * as S from './style';
import { ContentFooter, MoreItems, PasteButton } from 'components/AutoflowCalendar/Cell.style';
import FixedDropdown, { Option } from 'shared/Dropdown/Basic';
import { ANNOUNCEMENT_AUTO_MESSAGE, CDN_URL, IN_APP_AUTO_MESSAGE } from 'constants/commonData';

import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import Counter from './Counter';
import VideoAutoMessage from 'shared/VideoAutoMessage';

const BOTTOM_SPACING = 22;
const DATE_FORMAT = 'MM-DD-YYYY';
const TIME_FORMAT = 'hh:mm A';
const ACTIONS = [
  { key: 'edit', label: 'Edit', icon: 'edit-white', action: 'onEdit' },
  { key: 'copy', label: 'Copy', icon: 'duplicate', action: 'onCopy' },
  { key: 'delete', label: 'Delete', icon: 'delete', action: 'onDelete' },
];

class CellContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lastMessage: null };

    this.resizeCallbackDebounce = _.debounce(this.resizeCallback, 150);
    this.observer = new ResizeObserver(this.resizeCallbackDebounce);
    this.container = React.createRef();
    this.idPrefix = `auto-message-${props.date}`;
    this.contentHeight = 0;
  }

  componentDidMount() {
    this.observer.observe(this.container.current);
  }

  componentDidUpdate(prevProps) {
    if (_.differenceWith(this.props.messages, prevProps.messages, _.isEqual).length) {
      this.calculateLastRenderedMessage();
    }
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  resizeCallback = entries => {
    if (entries[0]) {
      const {
        contentRect: { height },
      } = entries[0];

      if (height !== this.state.contentHeight) {
        this.calculateLastRenderedMessage();
      }
    }
  };

  calculateLastRenderedMessage = () => {
    const { messages } = this.props;
    const { lastMessage } = this.state;

    const containerRect = this.container.current.getBoundingClientRect();
    const { x, y, height } = containerRect;
    const messagesContainerHeight = height - BOTTOM_SPACING;
    const fakeEl = this.container.current.querySelector('.fake-content');
    let index = 0;

    if (fakeEl && fakeEl.clientHeight > messagesContainerHeight) {
      let elements = document.elementsFromPoint(x + 15, y + messagesContainerHeight + 2);
      let messageItem = _.find(elements, el => (el.id ? el.id.startsWith(this.idPrefix) : false));

      if (!messageItem) {
        // each message in a date margin bottom 5px
        //Sometimes, the cordinates point to the margin of element
        elements = document.elementsFromPoint(x + 5, y + messagesContainerHeight);
        messageItem = _.find(elements, e => (e.id ? e.id.startsWith(this.idPrefix) : false));
      }

      if (messageItem) {
        const [, , , mIndex] = messageItem.id.split(';');
        index = Number(mIndex) - 1;
      }
    } else {
      index = messages.length - 1;
    }

    if (index !== lastMessage) {
      this.setState({ lastMessage: index });
    }
  };

  renderActions = message => {
    const { messages } = this.props;
    const mIndex = _.findIndex(messages, item => item._id === message._id);

    if (mIndex === -1) {
      return null;
    }

    const moveActions = [];

    if (message.type === ANNOUNCEMENT_AUTO_MESSAGE) {
      if (mIndex > 0) {
        moveActions.push(
          <Option onClick={() => this.props.onArrange(message, mIndex - 1)}>
            <img src={`${CDN_URL}/images/workout_up.svg`} width={14} style={{ marginRight: 12 }} alt="" />
            <span>Move Up</span>
          </Option>,
        );
      }

      if (mIndex < messages.length - 1) {
        moveActions.push(
          <Option onClick={() => this.props.onArrange(message, mIndex + 1)}>
            <img src={`${CDN_URL}/images/workout_down.svg`} width={14} style={{ marginRight: 12 }} alt="" />
            <span>Move Down</span>
          </Option>,
        );
      }
    }

    return (
      <FixedDropdown className="autoMessage__actions">
        {moveActions}
        {ACTIONS.map(item =>
          item.key === 'edit' && message.type === IN_APP_AUTO_MESSAGE && message.status === 1 ? null : (
            <Option key={item.key} onClick={() => this.props[item.action](message)}>
              <img src={`${CDN_URL}/images/${item.icon}.svg`} width={14} style={{ marginRight: 12 }} alt="" />
              <span>{item.label}</span>
            </Option>
          ),
        )}
      </FixedDropdown>
    );
  };

  renderMessageHeader = message => {
    const { type, schedule_on, end_date, status } = message;
    const isAnnouncement = type === ANNOUNCEMENT_AUTO_MESSAGE;
    const icon = isAnnouncement ? `${CDN_URL}/images/announcement.svg` : `${CDN_URL}/images/in-app-message.svg`;

    return (
      <S.MessageHeader className="autoMessage__header">
        <div className="autoMessage__time">
          <img src={icon} />
          {isAnnouncement ? (
            <S.EndDate className="end_date">
              <img src={`${CDN_URL}/images/announcement_clock.svg`} alt="" />
              <div>End: {moment.utc(schedule_on === 'multiple' ? end_date : message.date).format('MMM D')}</div>
            </S.EndDate>
          ) : (
            <>
              <span>{message.time ? moment(message.time, 'HH:mm').format(TIME_FORMAT) : ''}</span>
              {status === 1 ? <img src={`${CDN_URL}/images/checkmark_green.svg`} alt="" /> : null}
            </>
          )}
        </div>
        {this.renderActions(message)}
      </S.MessageHeader>
    );
  };

  renderInAppMessage = message => {
    const { compactView } = this.props;

    return (
      <S.MessageContainer className="autoMessage__container">
        {this.renderMessageHeader(message)}
        <S.MessageContent className="autoMessage__content">
          <div className="autoMessage__title">{message.subject}</div>
        </S.MessageContent>
        {!compactView ? <Counter message={message} /> : null}
      </S.MessageContainer>
    );
  };

  renderMinimize = () => {
    const { lastMessage } = this.state;
    const { minimize, copying, messages, date, dayId, compactView } = this.props;

    if (lastMessage === null) {
      return null;
    }

    const views = [];
    const lastRender = minimize ? lastMessage : messages.length - 1;

    for (let i = 0; i <= lastRender; i++) {
      const message = messages[i];

      if (message) {
        const draggableId = `${date};${message._id};${message.type}${dayId ? ';' + dayId : ''}`;

        if (message.type === ANNOUNCEMENT_AUTO_MESSAGE) {
          // ANNOUCEMENT
          views.push(
            <Draggable key={draggableId} draggableId={draggableId} index={i} key={`${date};${message._id}`}>
              {provided => (
                <S.MessageWrapper
                  className="autoMessage autoMessage--announcement"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  onClick={() => this.props.onEdit(message)}
                >
                  {this.renderAnnouncementContent(message, true)}
                </S.MessageWrapper>
              )}
            </Draggable>,
          );
        } else {
          //IN-APP MESSAGE
          views.push(
            <Draggable
              key={draggableId}
              draggableId={draggableId}
              index={i}
              key={`${date};${message._id}`}
              isDragDisabled={message.status === 1}
            >
              {provided => (
                <S.MessageWrapper
                  className="autoMessage autoMessage--inApp"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  onClick={() => this.props.onEdit(message)}
                >
                  {this.renderInAppMessage(message)}
                </S.MessageWrapper>
              )}
            </Draggable>,
          );
        }
      }
    }

    const remaining = messages.slice(lastRender + 1).length;
    const messageType = _.get(copying, 'type');

    const hidePaste =
      !copying ||
      (messageType === ANNOUNCEMENT_AUTO_MESSAGE &&
        moment.utc(date, DATE_FORMAT).isSame(moment.utc(copying.date), 'day')) ||
      (messageType === IN_APP_AUTO_MESSAGE && moment(date, DATE_FORMAT).isBefore(moment(), 'day'));

    return (
      <div className={classnames('minimize', { 'can-paste': !hidePaste })}>
        {views}
        {minimize && (
          <ContentFooter className="autoMessageCell__footer">
            {!!remaining && (
              <MoreItems className="more-items" onClick={this.props.onViewMore}>
                + {remaining} more
              </MoreItems>
            )}
            {hidePaste ? null : (
              <PasteButton onClick={this.props.onPaste} className="autoMessageCell__paste">
                Paste
              </PasteButton>
            )}
          </ContentFooter>
        )}
      </div>
    );
  };

  renderAnnouncementContent = (message, renderDuration) => {
    const { attachment, attachment_type, subject, attachment_thumbnail } = message;
    const { compactView } = this.props;
    let media = null;

    if (attachment) {
      if (attachment_type.includes('image')) {
        media = <S3ImageWithFallback src={[attachment_thumbnail || '', attachment || '']} noSizeLimit />;
      } else {
        media = (
          <>
            <VideoAutoMessage message={message} renderDuration={renderDuration} compactView={compactView} />
          </>
        );
      }
    }

    return (
      <S.MessageContainer className="autoMessage__container">
        {this.renderMessageHeader(message)}
        <S.MessageContent className="autoMessage__content">
          {media && <div className="autoMessage--announcement__media">{media}</div>}
          <div className="autoMessage__title">{subject}</div>
        </S.MessageContent>
      </S.MessageContainer>
    );
  };

  render() {
    const { messages, minimize, compactView } = this.props;

    return (
      <S.CellContent
        ref={this.container}
        showAll={!minimize}
        empty={!messages.length}
        className={classnames('autoMessageCell__content', { compactView: compactView })}
      >
        <div className="fake-content">
          {_.map(messages, (message, index) => {
            if (!message) {
              return null;
            }

            return (
              <S.MessageWrapper
                key={message._id}
                onClick={() => this.props.onEdit(message)}
                id={`${this.idPrefix};${message.type};${message._id};${index}`}
                className={classnames(
                  'autoMessage',
                  `autoMessage--${message.type === ANNOUNCEMENT_AUTO_MESSAGE ? 'announcement' : 'inApp'}`,
                )}
              >
                {message.type === ANNOUNCEMENT_AUTO_MESSAGE
                  ? this.renderAnnouncementContent(message)
                  : this.renderInAppMessage(message)}
              </S.MessageWrapper>
            );
          })}
        </div>
        {this.renderMinimize()}
      </S.CellContent>
    );
  }
}

export default CellContent;
