import React, { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { ClientGuideWrapper, Guide } from './style';
import { CDN_URL, TABLE_CELL_CLIENT_NAME_WIDTH } from 'constants/commonData';
import { getUserShortName } from 'utils/commonFunction';

function ClientGuide(props) {
  const { clients } = props;

  if (!clients || !clients.length) {
    return null;
  }

  return (
    <ClientGuideWrapper style={{ minWidth: TABLE_CELL_CLIENT_NAME_WIDTH }}>
      <div className="guide__container">
        {clients.map(item => (
          <div className="guide__client" key={item._id}>
            <Avatar name={getUserShortName(item)} size="29" src={item.avatar} />
            <div className="name">{item.name}</div>
          </div>
        ))}
      </div>
      <Guide>
        <div className="index">2.&nbsp;</div>
        <div className="text">Explore the client dashboard with our DEMO clients</div>
        <img src={`${CDN_URL}/images/guide_arrow_1.svg`} className="arrow" alt="" />
      </Guide>
    </ClientGuideWrapper>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      client: { list },
    },
  } = state;

  return { clients: list.slice(0, 2) };
};

export default connect(mapState, null)(ClientGuide);
