import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const baseText = `
    font-family: Open Sans;
    font-style: normal;
`;

const limitText = `
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const CustomModal = styled(Modal)`
  &.ui.modal.workout-completion-modal {
    width: 623px;
    max-height: 645px;

    @media (max-height: 700px) {
      max-height: calc(100vh - 48px);
    }

    border-radius: 12px;
    .workout-completion-modal__header {
      padding: 24px 24px 12px !important;
      margin: 0px !important;
      height: auto;
      .close-button {
        right: -10px;
        top: -10px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .workout-completion-modal__content {
      padding: 0;
    }
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  ${baseText}
  color: #262626;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
`;

export const TabBarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: 0px -1px 0px 0px #dde0e5 inset;
`;

export const TabBarContainer = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 2px 3px;
  align-items: center;
  border-radius: 2px;
  background: #f6f6f6;
`;

export const TabBarItem = styled.div`
  ${baseText}
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  color: #6f6f71;
  font-size: 13px;

  display: flex;
  width: 137px;
  padding: 6px 16px;

  justify-content: space-between;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  :hover {
    color: ${props => (props.active ? '#fff' : !props.isLoading && '#5158cf')};
  }

  ${props =>
    props.active &&
    css`
      border-radius: 2px;
      background: #5158cf;
      color: #fff;
      box-shadow: 2px 2px 8px 0px rgba(56, 56, 56, 0.04);
    `}

  cursor:  ${props => props.isLoading && 'not-allowed'};
`;

const BroadcastButtonActive = css`
  background: #f5f5f5;
`;

export const SendMessageTrigger = styled.div`
  display: none;
  width: 275px;
  padding: 10px 0px;
  border-radius: 6px;

  position: absolute;
  top: 30px;
  right: 0;

  background: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  :hover {
    display: flex;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #262626;
  border-radius: 6px;
`;

export const BroadcastContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  ${baseText}
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid #bfbfbf;
  background: #fff;
  padding: 7px 10px;

  .broadcast-icon {
    width: 20px;
    height: 20px;
    path {
      stroke: #262626;
    }
  }

  :hover {
    ${BroadcastButtonActive}
  }
`;

export const SendMessageItem = styled.div`
  padding: 14px 16px !important;
  color: #fff;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  width: 100%;

  :hover {
    background: #413f3f;
    color: #fff;
    border-radius: 6px;
  }
`;

export const BroadcastWrapper = styled.div`
  position: relative;
  z-index: 99;

  :hover {
    ${SendMessageTrigger} {
      display: flex !important;
      visibility: visible;
    }
  }
  .ui.dropdown > .left.menu {
    border-radius: 6px;
    top: 40px;

    background: #262626;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .ui.dropdown .menu > .item {
    padding: 14px 16px !important;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;

    :hover {
      background: #413f3f;
      color: #fff;
      border-radius: 6px;
      ${props =>
        !props.hasSelectedClients &&
        css`
          :first-child {
            border-radius: 6px 6px 0px 0px;
          }
          :last-child {
            border-radius: 0px 0px 6px 6px;
          }
        `};
    }
  }

  .ui.active.visible.dropdown {
    ${BroadcastContainer} {
      ${BroadcastButtonActive}
    }
  }
`;

// Css for TableHeader

export const TableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
  padding: 10px 24px 10px 32px;
  box-shadow: 0px -1px 0px 0px #dde0e5 inset;
`;

export const CheckboxClientContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  .workout-completed-avatar {
    display: flex;
    flex-shrink: 0;
  }
`;

export const TableHeader = styled.thead`
  position: relative;
  z-index: 1;
`;

export const TableBody = styled.tbody`
  width: 100%;
  .wc-loading-indicator {
    padding: 24px;
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const TableCellHeader = styled.th`
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  padding-right: 8px;
  &:last-child {
    padding-right: 0;
  }
  &:nth-child(1) {
    width: 298px;
  }
  &:nth-child(2) {
    min-width: 239px;
  }
  &:nth-child(3) {
    width: auto;
  }
`;

export const TableCellContent = styled.div`
  padding-top: ${props => (props.paddingTop || 0) + 'px'};
  padding-bottom: ${props => (props.paddingBottom || 0) + 'px'};
  padding-left: ${props => (props.paddingLeft || 0) + 'px'};
  ${props =>
    props.isIcon &&
    css`
      width: 20px;
      height: 20px;
      padding-left: 1px;
    `}
  ${props =>
    props.isWorkout &&
    css`
      :hover {
        cursor: pointer;
        > div {
          color: #5158cf;
        }
      }
    `}

  .workout-missed-name {
    cursor: pointer;
  }
`;

export const TableHeaderContent = styled.div`
  ${baseText}
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
`;

// Css for CheckBox and avatar

export const CheckBoxContainer = styled.div`
  min-width: 24px;
  height: 24px;
  padding-left: 1px;
  margin-top: -6px;
  position: relative;
  &.checked,
  :hover {
    .checkbox-header {
      display: block;
    }
    .avatar-container {
      display: none;
    }
  }
`;

export const CheckboxHeader = styled.div`
  &.checkbox-header {
    display: none;
  }
  span.checkbox-icon {
    height: 20px;
    width: 20px;
    transform: translateY(-50%);
    background-size: 60%;
    border-radius: 3px;
  }

  :hover {
    .checkbox-icon {
      border: 1px solid #5158cf;
    }
  }
`;

export const AvatarContainer = styled.div`
  position: absolute;
  left: 0;
  top: 55%;
  transform: translateY(-50%);
`;

// Css for TableBody

export const TableBodyWrapper = styled.div`
  padding: 0 24px;
  overflow-x: hidden;
  overflow-y: auto;
  height: ${props => (props.height || '450') + 'px'};

  @media (max-height: 700px) {
    max-height: calc(100vh - 242px);
  }

  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15) !important;
    border-radius: 20px !important;
  }

  position: relative;
  padding-bottom: ${props => props.hasSelected && '76px'};
`;

export const TableBodyContainer = styled.div`
  height: 55px;
  width: 575px;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  box-shadow: 0px -1px 0px 0px #dde0e5 inset;

  :hover {
    background-color: #fafafa;

    .avatar-container {
      display: none;
    }

    .checkbox-header {
      display: block;
    }
  }
`;

export const TableBodyCell = styled.td`
  vertical-align: middle;
  padding-right: 8px;
  &:last-child {
    padding-right: 0;
  }
  &:nth-child(1) {
    width: 298px;
  }
  &:nth-child(2) {
    min-width: 239px;
  }
  &:nth-child(3) {
    width: auto;
  }
  .__react_component_tooltip.app-tooltip {
    padding: 8px 16px 0;
    text-align: left;
    width: 199px;
    border-radius: 6px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 8px 0 11px;
        ${baseText}
        color:  #FFF;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        ${limitText}
      }
    }
    &::after {
      left: 13.5%;
    }
  }

  .__react_component_tooltip.inbox-tooltip {
    padding: 12px 16px;
    text-align: center;
    border-radius: 6px;
    margin-top: 8px;
    &::after {
      top: -5px;
    }
  }
`;

export const TableBodyContent = styled.div`
  ${baseText}
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  ${limitText}
`;

export const TableBodyIcon = styled.div`
  position: relative;
  ${baseText}
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  .inbox-icon {
    width: 20px;
    height: 20px;
  }

  :hover {
    .inbox-icon {
      path {
        stroke: #5c62d2;
        fill: #5c62d2;
        stroke-width: 0.3;
      }
    }
  }
`;

export const InboxUnread = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ff4d4f;
  border-radius: 50%;
  top: -3px;
  right: -3px;
`;

// Css calendar

export const DatePicker = styled.div`
  .rdt.new-ui.rdtOpen {
    .workout-completion-calender.custom-date-input {
      background-color: #f4f4fc;
      border-color: unset;
    }
  }
  .rdt.new-ui {
    .workout-completion-calender.custom-date-input {
      ${baseText}
      width: 146px;
      min-width: 146px;
      height: 36px;
      padding: 8px 12px;
      border-radius: 4px;
      background-color: #f5f5f5;
      border: unset;
      color: #262626;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      span {
        display: flex;
        justify-content: start;
        width: 100%;
        user-select: none;
      }
      &:hover {
        background-color: #f4f4fc;
        border-color: unset;
      }
    }
    .rdtPicker {
      width: 339px;
      padding: 26px 16px 16px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
      margin-top: 4px;
      table {
        border-collapse: collapse;
      }
      thead tr th.dow {
        ${baseText}
        padding: 15px 10px;
        color: #7b7e91;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        text-transform: uppercase;
      }
      .rdtDays,
      .rdtMonths,
      .rdtYears {
        .rdtSwitch {
          ${baseText}
          color: #262626;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          position: relative;
          height: unset;
          top: -6px;
          right: 0;
          :hover {
            color: #5158cf;
          }
        }
        .rdtPrev {
          width: 40px;
          span {
            display: none;
          }
          &::after {
            content: url(${CDN_URL}/images/schedule-calendar-change-month-purple.svg);
            position: absolute;
            top: 25px;
            left: 14px;
          }
        }
        .rdtNext {
          width: 40px;
          span {
            display: none;
          }
          &::after {
            content: url(${CDN_URL}/images/schedule-calendar-change-month-purple.svg);
            display: inline-block;
            transform: rotate(180deg);
            position: absolute;
            top: 25px;
            right: 14px;
          }
        }
        .rdtDay {
          ${baseText}
          width: 44px;
          height: 45px;
          color: #262626;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 100%;
          text-transform: uppercase;
          &.rdtActive {
            ::before {
              width: 31px;
              height: 32px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #5158cf;
              border: 1px solid #5158cf;
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
          :hover:not(.rdtActive) {
            ::before {
              width: 31px;
              height: 32px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #ffffff;
              border: 1px solid #5158cf;
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
          &.rdtDisabled {
            ${baseText}
            color: #BFBFBF;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 100%;
            text-transform: uppercase;
          }
          &.rdtToday.rdtActive {
            color: #fff !important;
            font-weight: 600 !important;
          }
          &.rdtActive {
            color: #fff !important;
          }
        }
        .rdtDay:not(.rdtDisabled):not(.rdtActive):hover {
          color: #262626 !important;
          font-weight: 600 !important;
        }
      }
    }
  }
`;

// Css filter owner

export const FilterOwner = styled.div`
  width: 103px;
`;

// Css back to top

export const ToTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
  margin: 24px 0;
  svg {
    cursor: pointer;
  }
`;

export const ToTopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ToTopTitle = styled.div`
  ${baseText}
  color:  #8C8C8C;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`;

export const ToTopButton = styled.div`
  ${baseText}
  color: #5158CF;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  user-select: none;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StudioUpgradeButton = styled(UpgradePathButton)``;
export const BroadcastUpgradeButton = styled.div`
  background: -webkit-linear-gradient(76.71deg, #fdc830 -18.89%, #f37335 99.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.28px;
  cursor: pointer;
  hover: {
    background: -webkit-linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const StudioUpgradeModal = styled(Modal)`
  &.ui.modal.workout-completion-modal {
    width: 415px;
    height: 220px;
    border-radius: 12px;
    .workout-completion-modal__header {
      padding: 24px 24px 12px !important;
      margin: 0px !important;
      height: auto;
      .close-button {
        right: -10px;
        top: -10px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .workout-completion-modal__content {
      padding: 0;
    }
  }
`;

export const EmptyContainer = styled.div`
  width: 575px;
  display: flex;
  margin-top: ${props => (props.isCompletedTab ? '111px' : '56px')};
  flex-direction: column;
  justify-items: center !important;
  align-items: center !important;
`;

export const EmptyText = styled.div`
  color: #8c8c8c;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
`;

export const EmptyBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center !important;
  align-items: center !important;
`;

export const BroadcastSelectedWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 76px;
  border-radius: 0px 0px 12px 12px;
`;

export const BroadcastSelectedButton = styled(Button)`
  width: 575px;
  max-height: 44px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 12px;
  border-radius: 4px;

  ${props =>
    props.upgradePath &&
    css`
      background: linear-gradient(87deg, #fdc830 -18.89%, #f37335 99.03%);
      border: none;
      color: #fff;
    `}

  :hover {
    opacity: 0.9;
  }
`;
