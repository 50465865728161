// libs
import React from 'react';

// components
import MealItem from '../MealItem';
import AddMealButton from '../AddMealButton';

// utils
import { MAX_ITEM_PER_ROW } from 'components/ClientMealPlan/constants';

// styles
import * as S from './style';

const NormalView = ({ meals, hasMeals, onAddMeals, dateMoment, cloudfrontList }) => {
  const mealsWithRecipes = meals.filter(({ recipes }) => recipes.length > 0);

  const renderMealEmpty = itemNumber => {
    return new Array(itemNumber).fill(null).map((_, index) => <S.MealEmpty key={index} />);
  };

  return (
    <S.DayCategoryWrapper>
      {hasMeals ? (
        <>
          {meals.map(meal => {
            const { _id } = meal || {};

            return <MealItem key={_id} meal={meal} cloudfrontList={cloudfrontList} />;
          })}
          {mealsWithRecipes.length < MAX_ITEM_PER_ROW && renderMealEmpty(MAX_ITEM_PER_ROW - mealsWithRecipes.length)}
        </>
      ) : (
        <>
          <AddMealButton disabled={false} onClick={() => onAddMeals(dateMoment)} />
          {renderMealEmpty(MAX_ITEM_PER_ROW - 1)}
        </>
      )}
    </S.DayCategoryWrapper>
  );
};

export default NormalView;
