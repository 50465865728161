import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { isTeamAdmin } from 'utils/commonFunction';
import { toggleModal } from 'actions/modal';
import UpgradeYourPlanModal from 'shared/UpgradePath/components/UpgradeYourPlanModal';

function Header({ totalWaiting, maximum, totalActive, isAdmin, loading, ...actions }) {
  const available = Math.max(maximum - totalActive, 0);

  const handleCloseModal = () => {
    actions.toggleModal(false);
  };

  const handleUpgrade = () => {
    if (isAdmin) {
      return actions.push('/pricing-plan');
    } else {
      actions.toggleModal(true, <UpgradeYourPlanModal onClose={handleCloseModal} />);
    }
  };
  return (
    <S.Wrapper>
      <S.Title>
        <div>Waiting Activation ({totalWaiting})</div>
        <S.AvailableSeat>
          Available Seats: <span className="value">{available}</span>
        </S.AvailableSeat>
        {totalWaiting > available && <S.UpgradeButton onClick={handleUpgrade}>Upgrade Subscription!</S.UpgradeButton>}
      </S.Title>
      <S.SubTitle>Clients that have signed up via your invite link, waiting to be activated.</S.SubTitle>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { waitingActivationClients, client, permission },
    user,
  } = state;

  return {
    totalWaiting: waitingActivationClients.get('total'),
    loading: waitingActivationClients.get('loading'),
    maximum: permission.no_clients || 0,
    totalActive: client.total_active_clients || 0,
    isAdmin: isTeamAdmin(user),
  };
};

export default connect(mapStateToProps, { push, toggleModal })(Header);
