import { Types } from './actions';

const INITIAL_STATE = {
  query: { page: 1, per_page: 20, q: '' },
  defaultOptions: [],
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SUCCESS_GET_DEFAULT_SYSTEM_WORKOUTS:
      return Object.assign({}, state, { defaultOptions: payload.data, total: payload.total });

    default:
      return state;
  }
};
