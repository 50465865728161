import React from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Wrapper } from './style';
import UpgradePath from 'shared/UpgradePath';
import { Helmet } from 'react-helmet';
import { INITIAL_WORKING_COLLECTION_CATEGORY } from 'constants/commonData';

const WorkoutCollectionCategoryDetailHeader = props => {
  const { workoutCollectionCategory, location, loading, name } = props;
  if (_.isEqual(workoutCollectionCategory, INITIAL_WORKING_COLLECTION_CATEGORY) && loading) {
    return null;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{name} - Everfit</title>
      </Helmet>
      <div className="workout-collection-detail__name">
        <span>
          <UpgradePath pathName="on_demand_workout" fallback={<></>}>
            {name}
          </UpgradePath>
        </span>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, router } = state;
  const { workoutCollectionCategory } = rootReducer;
  const { loading } = workoutCollectionCategory;

  return {
    workoutCollectionCategory,
    location: router.location,
    loading,
  };
};

export default connect(mapStateToProps, null)(WorkoutCollectionCategoryDetailHeader);
