import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: calc(100% - ${({ hasPagination }) => (hasPagination ? '186px' : '134px')});
  padding: 0 15px 0 30px;
`;

export const TableWrapper = styled.div`
  flex: 1 1;
  padding-right: 15px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    position: relative;
    width: 100%;

    thead {
      position: relative;
      z-index: 1;

      tr {
        position: sticky;
        z-index: 9;
        top: 0;
        background-color: #fff;

        th {
          .column-icon {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    tbody tr {
      cursor: pointer;

      &:not(.is-loading):hover {
        background-color: #f8f8f8;
      }

      &.default-onboarding-tab:hover {
        background-color: unset;
      }
    }

    thead tr th,
    tbody tr td {
      font-size: 11px;
      font-weight: 700;
      line-height: 15px;
      text-align: left;
      color: #6a778a;

      display: inline-flex;
      align-items: center;
      justify-content: start;
      gap: 5px;

      height: 60px;
      padding: 20px 15px;
      border-bottom: 1px solid #eeeeee;

      &.sortable {
        cursor: pointer;
      }

      &.active .column-title {
        color: #5157cf;
      }

      &:nth-child(1) {
        width: 42.0454545455%;
      }
      &:nth-child(2) {
        width: 18.9393939394%;
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 13.6742424242%;
      }
      &:nth-child(5) {
        width: calc(100% - 42.0454545455% - 18.9393939394% - 13.6742424242% - 13.6742424242%);
      }

      .column-title {
        white-space: nowrap;
      }
    }

    thead tr th {
      .column-icon {
        display: flex;
        align-items: center;
      }
    }

    tbody tr td {
      height: 96px;
      gap: 15px;

      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      color: #2a394c;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        flex-shrink: 0;

        &__publish {
          background-color: #2fd787;
        }
        &__draft {
          background-color: #c4c4c4;
        }
      }

      .name {
        font-weight: 700;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .actions {
        display: flex;
        gap: 8px;
        margin-right: 15px;

        .evf-dropdown.custom-dropdown-actions.open .evf-dropdown__menu {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4px;

          padding: 8px 0;
          border-radius: 8px;
          background-color: #2d2e2d;

          .evf-dropdown__option {
            display: flex;
            align-items: center;
            gap: 12px;

            padding: 6px 15px;

            span {
              font-size: 13px;
              font-weight: 600;
              line-height: 18px;
              text-align: left;
              color: #ffffff;

              user-select: none;
            }
          }
        }
      }

      &:last-child {
        justify-content: end;
      }
    }

    tbody tr:has(td.loading-empty),
    tbody tr.default-onboarding-tab {
      cursor: default;
    }

    tbody td.loading-empty {
      text-align: center;
      padding-top: 104px;
      padding-left: 60px;
      height: unset;
      border-bottom: unset;

      &:nth-child(1) {
        width: 100%;
      }

      &:last-child {
        justify-content: center;
      }
    }
  }
  &.default-onboarding-flow {
    table {
      thead tr th,
      tbody tr td {
        &:nth-child(1) {
          width: 28.3%;
        }
        &:nth-child(2) {
          width: 71.7%;
        }
      }
      tbody tr td {
        &:last-child {
          justify-content: unset;
        }
      }
      tbody td.loading-empty {
        &:nth-child(1) {
          width: 100%;
        }
        &:last-child {
          justify-content: center;
        }
      }
      tbody {
        .coach-name {
          .sb-avatar {
            flex-shrink: 0;
          }
          span {
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: #2a394c;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        .text-onboarding-flow {
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #2a394c;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          max-width: 338px;
        }
      }
    }
  }
`;

export const ListEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;

  &.achieved-wrap {
    span.label {
      font-size: 15px;
      line-height: 20.43px;
      font-weight: 600;
      text-align: center;
      color: #495168;
      margin-top: -4px;
    }
  }

  &.search-wrap {
    margin-top: 47px;
    margin-left: -60px;

    span.label {
      font-size: 13px;
      line-height: 17.7px;
      font-weight: 400;
      text-align: center;
      color: #9a9da6;
      margin-top: 8px;
    }
  }

  img.steps {
    margin-bottom: -15px;
  }

  span.label {
    font-size: 15px;
    line-height: 20.43px;
    font-weight: 600;
    text-align: center;
  }
`;

export const OwnerAvatarWrapper = styled.div`
  .owner-avatar {
    opacity: 1 !important;

    .app-tooltip {
      box-shadow: none !important;
    }
  }
`;

export const OptionIcon = styled.div`
  width: 16px;
  height: 16px;

  .onboarding-edit-icon,
  .onboarding-archive-icon {
    width: 16px;
    height: 16px;
  }
  .onboarding-duplicate-icon {
    width: 17px;
    height: 17px;
  }
  .onboarding-remove-icon {
    width: 15px;
    height: 16px;
  }
`;

export const Wrapper = styled.div`
  height: calc(100% - 130px);
  padding: 26px 15px 0 0;
`;

export const NewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isLoading }) => (isLoading ? 'center' : 'flex-start')};
  flex-direction: column;
  gap: 32px;
  height: 100%;

  padding: 0 15px 30px 30px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;

  .custom-loading {
    padding-top: unset !important;
  }

  .evfPagination {
    padding: 7px 0 0;
  }

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      padding-top: 104px;
    `}

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }

  &::-webkit-scrollbar-track {
    margin-bottom: 5px;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fafafa;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  height: 75px;
  padding: 0 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  .menu-icon {
    width: 24px;
    height: 24px;

    path {
      fill: #000;
    }
  }

  .section-label {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #000;
  }
`;

export const NewTableWrapper = styled.div`
  padding: 8px 16px 16px;

  &.section-general table thead tr th.active .column-title {
    color: #5157cf;
  }

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    position: relative;
    width: 100%;

    tbody:before {
      line-height: 8px;
      content: '.';
      color: white;
      display: block;
    }

    tbody tr {
      cursor: pointer;

      &:first-child {
        td:first-child {
          border-top-left-radius: 12px;
        }
        td:last-child {
          border-top-right-radius: 12px;
        }
      }

      &:last-child {
        td:first-child {
          border-bottom-left-radius: 12px;
        }
        td:last-child {
          border-bottom-right-radius: 12px;
        }
      }
    }

    thead tr th,
    tbody tr td {
      display: inline-flex;
      align-items: center;
      justify-content: start;
      gap: 5px;

      padding: 16px 24px;

      &:nth-child(1) {
        width: 43.7888198758%;
      }
      &:nth-child(2) {
        width: 19.409937888%;
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 12.072981366%;
      }
      &:nth-child(5) {
        width: calc(100% - 43.7888198758% - 19.409937888% - 12.072981366% - 12.072981366%);
      }

      .column-title {
        white-space: nowrap;
      }
    }

    thead tr th {
      height: 52px;

      font-size: 11px;
      font-weight: 700;
      line-height: 15px;
      text-align: left;
      color: #6a778a;

      &.sortable {
        cursor: pointer;
      }

      .column-icon {
        display: flex;
        align-items: center;
      }
    }

    tbody tr td {
      gap: 15px;
      height: 96px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      background-color: #fff;

      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      color: #2a394c;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        flex-shrink: 0;

        &__publish {
          background-color: #2fd787;
        }
        &__draft {
          background-color: #c4c4c4;
        }
      }

      .col-name {
        display: contents;
      }

      .name {
        font-weight: 700;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        &.name-default {
          word-break: break-all;
          -webkit-line-clamp: 1;
        }
      }

      .name-tag {
        flex-shrink: 0;
        display: inline-block;
        font-weight: 400;
        margin-left: 15px;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: #f5f5ff;
        color: #4846da;
      }

      .last_updated {
        margin-left: 16px;
      }

      .__react_component_tooltip.app-tooltip.name-tag-tooltip {
        padding: 15px;
        max-width: 228px;
        background-color: #2d2e2d;
        text-align: left;
        margin-top: -11px;
        p {
          color: #fff;
          font-family: 'Open Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        &.name-tag-tooltip.place-top:after {
          bottom: -7px;
        }
      }

      .actions {
        display: flex;
        gap: 8px;

        .evf-dropdown.custom-dropdown-actions.open .evf-dropdown__menu {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 4px;

          padding: 8px 0;
          border-radius: 8px;
          background-color: #2d2e2d;

          .evf-dropdown__option {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 6px 15px;

            span {
              font-size: 13px;
              font-weight: 600;
              line-height: 18px;
              text-align: left;
              color: #ffffff;
              user-select: none;
            }
          }
        }
      }

      &:last-child {
        justify-content: end;
        padding-right: 16px;
      }
    }

    tbody tr:has(td.loading-empty) {
      cursor: default;
    }

    tbody td.loading-empty {
      text-align: center;
      padding-top: 104px;
      padding-left: 60px;
      height: unset;
      border-bottom: unset;

      &:nth-child(1) {
        width: 100%;
      }

      &:last-child {
        justify-content: center;
      }
    }
  }
`;
