import React from 'react';

import WorkoutCompletionModal from 'components/WorkoutCompletionModal/components';
import { ReactComponent as AnalyticIcon } from 'assets/icons/analytic_icon.svg';

import * as S from './style';

const WorkoutCompletionButton = props => {
  const {
    title = 'Workout Analytics',
    toggleModal,
    beforeCloseModal,
    beforeOpenModal,
    isDashboardNotification,
  } = props;

  const handleClose = () => {
    beforeCloseModal && beforeCloseModal();
    toggleModal && toggleModal(false);
  };
  const onOpenWorkoutCompletion = () => {
    beforeOpenModal && beforeOpenModal();
    toggleModal &&
      toggleModal(true, <WorkoutCompletionModal onClose={handleClose} beforeCloseModal={beforeCloseModal} />);
  };

  return (
    <S.WorkoutCompletionButton
      {...props}
      onClick={onOpenWorkoutCompletion}
      isDashboardNotification={isDashboardNotification}
    >
      <AnalyticIcon className="analytic-icon" /> {title}
    </S.WorkoutCompletionButton>
  );
};

export default WorkoutCompletionButton;
