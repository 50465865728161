/**
 * @flow
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CalendarComponent from 'components/Calendar/component';
import {
  handlePasteWeek,
  handleRemoveWeek,
  handleSelectedWeek,
  handleResetSelectedWeek,
} from 'redux/calendar/calendar.actionCreators';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = state => {
  const { calendarType, calendarStartDate, exercises, rootReducer, workouts } = state;

  return {
    workouts,
    exercises,
    calendarType,
    calendarStartDate,
    permission: rootReducer.permission,
    calendarMode: rootReducer.calendar.view_mode,
    selectedWeek: rootReducer.calendar.selectedWeek,
    selectedWorkout: rootReducer.calendar.selectedWorkout,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    handlePasteWeek: bindActionCreators(handlePasteWeek, dispatch),
    handleRemoveWeek: bindActionCreators(handleRemoveWeek, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    handleSelectedWeek: bindActionCreators(handleSelectedWeek, dispatch),
    handleResetSelectedWeek: bindActionCreators(handleResetSelectedWeek, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
