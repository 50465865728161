import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as IconViewmore } from 'assets/icons/icon-viewmore.svg';
import CommentItem from '../CommentItem';
import * as S from './style';

export default function CommentReplyItem(props) {
  const {
    item = {},
    handleDeleteComment,
    uploadSuccess,
    cancelUpload,
    members,
    postData,
    handleReplyMessage,
    showAttachItem,
    replyList = [],
    replyMessage,
    handleViewMoreReply,
  } = props;

  const totalReply = get(item, 'total_replies', 0);
  const isViewMore = get(item, 'isViewMore', false);
  const latestReplyList = get(item, 'latestReplyList', []);
  const idComment = get(item, '_id', '');
  const latestReply = latestReplyList[0];

  const renderViewMore = () => {
    const numberViewMore = totalReply - replyList.length;
    const isViewMoreThanTen = numberViewMore >= 10;

    return (
      <S.ViewMoreWrapper
        onClick={() =>
          handleViewMoreReply({
            comment_id: item._id,
            reply_id: !get(latestReply, 'isLocalFile', false) ? get(latestReply, '_id', undefined) : undefined,
          })
        }
      >
        <IconViewmore />
        <span>{`View ${isViewMoreThanTen ? '' : numberViewMore} more ${
          numberViewMore !== 1 ? 'replies' : 'reply'
        }`}</span>
      </S.ViewMoreWrapper>
    );
  };

  return (
    <S.CommentItemWrapper id={idComment} onlyViewMore={!isViewMore && totalReply > 0 && replyList.length <= 0}>
      <CommentItem
        key={item._id}
        comment={item}
        onDelete={() => handleDeleteComment(item)}
        onUploadSuccess={responseData => uploadSuccess(item, responseData, replyMessage)}
        showAttachItem={showAttachItem}
        onUploadFail={data => {}}
        onCancelUpload={error => cancelUpload(item, error, replyMessage)}
        members={members}
        initEditor={postData}
        hasReplyComment
        onReplyComment={handleReplyMessage}
      />
      <S.ListReplyWrapper>
        {((!isViewMore && totalReply > 0 && totalReply !== replyList.length) ||
          (isViewMore && totalReply > replyList.length)) &&
          renderViewMore()}
        {replyList.map(itemReply => {
          if (isEmpty(itemReply)) return <></>;
          const { _id = '' } = itemReply;
          return (
            <CommentItem
              key={_id}
              comment={itemReply}
              onDelete={() => handleDeleteComment(itemReply)}
              onUploadSuccess={responseData => uploadSuccess(itemReply, responseData, replyMessage)}
              showAttachItem={showAttachItem}
              onUploadFail={data => {}}
              onCancelUpload={error => cancelUpload(itemReply, error, replyMessage)}
              members={members}
              initEditor={postData}
              onReplyComment={handleReplyMessage}
              headerMedia
              sizeAvatar={24}
            />
          );
        })}
      </S.ListReplyWrapper>
    </S.CommentItemWrapper>
  );
}
