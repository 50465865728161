import React from 'react';
import LoadingIndicator from 'shared/LoadingIndicator';
import { AILoadingWrapper } from 'components/WorkoutDetailModal/components/MainPanel/style';
import { ReactComponent as BlueBg } from 'assets/icons/blue-loading.svg';

const Loading = () => {
  return (
    <AILoadingWrapper className="ai-generating-loading">
      <div className="bg-blue">
        <BlueBg className="blue-svg" />
        <LoadingIndicator className="indicator" title="" />
      </div>
    </AILoadingWrapper>
  );
};

export default Loading;
