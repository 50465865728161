import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: 515px;
  height: 104px;
  height: 104px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1.5px dashed #7470ef;
  background: rgba(255, 255, 255, 0.95);

  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${props =>
    props.show &&
    css`
      display: flex;
    `}

  .icon {
    width: 37px;
    height: 40px;
  }

  .text {
    color: #3b4859;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
