import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

const limitText = css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: visible;
  width: 286px;
`;

export const MobileFrame = styled.div`
  width: 100%;
  background: url(${CDN_URL}/images/iphone_layout_dark.svg) no-repeat;
  background-size: 100%;
  position: absolute;
  left: 0;
  top: 32px;
  right: 0;
  bottom: 0;

  :after {
    content: '';
    width: 100%;
    height: 128px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
`;

export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;
  width: 145.8px;
  height: 38px;
  border-radius: 18.81px;
  background: #7470ef;
  color: white;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
`;

export const TaskAction = styled.div`
  display: flex;
  margin-top: 130px;
  justify-content: center;
  align-items: center;
`;

export const NoAttachAction = styled.div`
  margin-top: 60px;
`;

export const Content = styled.div`
  height: 440px;
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 58px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
`;

export const AddComment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => (props.attachment ? 24 : 16)}px;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: #7470ef;
`;

export const TitlePreview = styled.div`
  word-break: break-word;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

export const NoSlectedTask = styled.div`
  margin-left: 15px;
  word-break: break-word;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const NoSlectedTaskText = styled.div`
  text-align: center;
  color: #9a9da6;
  font-weight: 400;
  font-size: 13px;
`;

export const Title = styled.div`
  text-align: center;
`;

export const Name = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
`;

export const Note = styled.div`
  margin-top: 8px;
  color: #222222;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  padding: 0 13px;
  white-space: pre-wrap;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
`;

export const GeneralWraper = styled.div``;

export const Thumbnail = styled.div`
  position: relative;
  display: flex;
  margin: -3px 12px 0 12px;
  height: ${props => props.height || 164}px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;

  svg {
    width: 48px;
    height: 48px;

    path:nth-child(1) {
      fill: #7470ef;
    }
  }

  background: url(${props => props.image}) no-repeat;
  background-color: #f7f8fc;
  background-size: cover;
  background-position: center;
`;

export const VideoPreview = styled.div`
  margin-top: 20px;
`;

export const ImagePreview = styled.div`
  margin-top: -3px;
`;

export const AudioPreview = styled.div`
  position: relative;
  display: flex;
  margin: 0 13px;
  margin-top: 20px;
  height: 88px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;

  svg.play-icon {
    width: 33px;
    height: 33px;
    margin-right: 14px;
    path:nth-child(1) {
      fill: #7470ef;
    }
  }

  svg.wave-icon {
    width: 150px;
    height: 23px;
  }

  background-color: #f7f8fc;
  background-size: cover;
`;

export const LinkPreview = styled.div`
  margin: 0 13px;
  border-radius: 6px;
  height: 162px;
  padding-left: 8px;
  ${props =>
    props.image &&
    css`
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.image}) no-repeat;
    `}
  background-color: #f7f8fc;
  background-size: cover;
  background-position: center;
  position: relative;
  font-weight: 700;
  font-size: ${props => (props.image ? '11px' : '9px')};
  color: ${props => (props.image ? 'white' : '#1e0a3c')};

  svg {
    width: ${props => (props.image ? '14px' : '11.5px')};
    height: ${props => (props.image ? '11px' : '8.5px')};

    path {
      fill: ${props => (props.image ? 'white' : '#1e0a3c')};
    }
  }
`;

export const LinkTitle = styled.div`
  bottom: 14px;
  margin-left: 6px;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TitleMedia = styled.div`
  text-align: center;
  word-break: break-word;
  padding-top: 11px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
  font-size: 12px;
`;

export const DocumentPreview = styled.div`
  border-radius: 6px;
  height: 163px;
  padding-left: 15px;
  margin: 0 13px;

  ${LinkTitle} {
    bottom: 14px;
    margin-left: 0;
  }

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.image}) no-repeat;
  background-color: #f7f8fc;
  background-size: cover;
  background-position: center;
  position: relative;
  font-weight: 700;
  font-size: ${props => (props.image ? '11px' : '9px')};
  color: ${props => (props.image ? 'white' : '#1e0a3c')};

  svg {
    width: ${props => (props.image ? '11px' : '8px')};
    height: ${props => (props.image ? '11px' : '8px')};

    path {
      fill: ${props => (props.image ? 'white' : '#1e0a3c')};
    }
  }
`;

export const DocumentType = styled.div`
  font-size: 9px;
`;

export const DocumentTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
`;

export const DocumentIcon = styled.div`
  margin-top: 22.15px;
`;

export const DocumentNoThumbNail = styled.div`
  position: relative;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  height: 162.72px;
  max-height: 162.72px;
  width: 237px;
  margin: 0 13px;

  background-color: #f7f8fc;
  font-size: 12px;
`;

export const OpenDocument = styled.div`
  color: #7470ef;
  font-size: 11px;
  font-weight: 700;
`;

export const ProgressPhotoPreview = styled.div`
  width: 100%;
  height: 163px;
  margin-top: -37px;
  margin-bottom: -15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BodyMetricPreview = styled.div`
  width: 100%;
  height: 163px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -36px;
  margin-bottom: -25px;
`;

export const MetricTitle = styled.div`
  margin-bottom: 16px;
  margin-top: 9px;
`;

export const MetricType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MetricItem = styled.div`
  width: 245px;
  height: 40px;
  margin-bottom: 8px;
  padding-left: 12px;
  padding-right: 13px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fd;
  border-radius: 8px;

  svg {
    width: 5px;
  }
`;

export const MetricAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5px;

  ${AddComment} {
    margin-top: 12px;
  }
`;

export const CommentIcon = styled.div`
  position: absolute;
  bottom: 9px;
  left: 68px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 9px;
`;

export const NoAttachment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 13px 9px 20px 0;

  ${AddComment} {
    margin-top: 16px;
  }
`;

export const FormPreview = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  height: 163px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f7f8fc;
  font-size: 12px;
`;

export const FormTitle = styled.div`
  margin-top: 15px;
  text-align: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${limitText}
`;

export const FormAction = styled.div`
  margin-top: 7px;

  ${ActionButton} {
    font-size: 13px;
    width: 117px;
    position: absolute;
    bottom: 2px;
    left: 73px;
  }
`;

export const PhotoAction = styled.div`
  position: absolute;
  bottom: 0;
  left: 60px;
`;
