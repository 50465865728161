import React, { memo } from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

import { ReactComponent as NewTabIconGray } from 'assets/icons/notification_new_tab_gray.svg';
import { ReactComponent as NewTabIconPurple } from 'assets/icons/notification_new_tab_purple.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_thin.svg';

import * as S from '../style';
import { useMyContext } from '../context/NotificationContext';

const HeaderTitle = () => {
  const { permission, closePopup } = useMyContext();

  const isPermission = get(permission, 'notification_center', false);

  return (
    <S.HeaderTitleWrapper>
      <S.TitleIconWrapper>
        <S.TitlePopup>Notifications</S.TitlePopup>
        {isPermission && (
          <>
            <S.ButtonNewTab data-for="notification-new-tab" data-tip href="/home/notification-center">
              <NewTabIconGray className="icon" />
              <NewTabIconPurple className="icon hover" />
            </S.ButtonNewTab>

            <ReactTooltip
              className="app-tooltip"
              id="notification-new-tab"
              effect="solid"
              place="bottom"
              delayShow={100}
              eventOff="mouseleave scroll mousewheel blur"
            >
              <span>Open Notification Center</span>
            </ReactTooltip>
          </>
        )}
      </S.TitleIconWrapper>
      <S.CloseIconWrapper onClick={closePopup}>
        <CloseIcon className="close-icon" />
      </S.CloseIconWrapper>
    </S.HeaderTitleWrapper>
  );
};

export default memo(HeaderTitle);
