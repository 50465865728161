import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const WorkoutDotContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2px;
`;

export const WorkoutDot = styled.div`
  width: 4px;
  height: 4px;
  margin: 0px 1.5px;
  border-radius: 50%;
  background-color: #3eb37c;
`;

export const DayCalendar = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 3px;
  border: 1px solid transparent;
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  text-align: center;
  color: #202353;
  padding-top: 8px;
  cursor: pointer;
  position: relative;

  ${props =>
    props.active &&
    css`
      background: #5158cf;
      color: #ffffff;
    `}

  :hover {
    border-color: #5158cf;
  }
`;

export const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 7px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #a7a7b6;
  margin-bottom: 14px;
`;

export const ActionButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  margin-left: 5px;
  background-image: url(${CDN_URL}/images/arrow_prev_calendar.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;

  :disabled {
    background-color: #eeeeee;
    cursor: not-allowed;
  }

  ${props =>
    props.next &&
    css`
      background-image: url(${CDN_URL}/images/arrow_next_calendar.svg);
    `}

  :hover {
    background-color: #eeeeee;
  }
`;

export const Actions = styled.div`
  display: flex;
`;

export const WeekTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
`;

export const Wrapper = styled.div`
  padding: 0px 22px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
`;
