import styled from 'styled-components';

export const FieldInputContainer = styled.div`
  display: flex;
  justify-content: center;

  input {
    background: #ffffff;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    text-align: center;
    width: 67px;
    padding: 3px;
    font-size: inherit;
    line-height: inherit;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :hover,
    :focus {
      border-color: #5158cf;
      background-color: #fff;
    }

    &.fieldInput--time {
      width: 67px !important;
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.45;
      border: transparent;
      background: transparent;
    }
  }
`;

export const Wrapper = styled.tr`
  .setTypeColumn {
    position: relative;
  }

  .superset__deleteSet {
    display: none;
    position: absolute;
    left: -9px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }

  :hover {
    .superset__deleteSet {
      display: block;
    }
  }

  :hover {
    cursor: pointer;
  }
`;
