import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { likeIcon, unlikeIcon, commentIcon } from 'constants/images';
import { likePost, unlikePost } from 'redux/forum/actions';
import { getNotifications } from 'redux/notification/actions';
import { Container } from './style';

function Interactions({ onCommentClick, like, comments, likePost, postId, unlikePost, onLikeClick, getNotifications }) {
  const [liked, setLiked] = useState(like);

  useEffect(() => {
    setLiked(like);
  }, [like]);

  const handleLike = () => {
    setLiked(it => !it);
    if (!liked) {
      likePost(postId)
        .then(() => {
          if (typeof onLikeClick === 'function') {
            onLikeClick();
          }
        })
        .finally(() => {
          getNotifications();
        });
    } else {
      unlikePost(postId)
        .then(() => {
          if (typeof onLikeClick === 'function') {
            onLikeClick();
          }
        })
        .finally(() => {
          getNotifications();
        });
    }
  };

  return (
    <Container className="interactions">
      <button type="button" className={`like ${liked ? 'active' : ''}`} onClick={handleLike}>
        {liked ? likeIcon : unlikeIcon}
        <span>Like</span>
      </button>
      <button
        type="button"
        className="comment"
        onClick={() => (typeof onCommentClick === 'function' ? onCommentClick() : null)}
      >
        {commentIcon}
        <span>Comment</span>
      </button>
    </Container>
  );
}

Interactions.propTypes = {
  onCommentClick: PropTypes.func,
  like: PropTypes.bool,
  comments: PropTypes.number,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const groupId = ownProps.groupId || ownProps.forumId;
  const { userId } = ownProps;

  return {
    likePost: postId => dispatch(likePost(groupId, postId, userId)),
    unlikePost: postId => dispatch(unlikePost(groupId, postId, userId)),
    getNotifications: bindActionCreators(getNotifications, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Interactions);
