import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import ConfirmModal from 'shared/ConfirmModal';
import { pluralize } from 'utils/commonFunction';
import { ReactComponent as CloseIcon } from 'assets/icons/close-copy-week.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-copy-workouts.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-workouts.svg';
import * as S from '../style';
import { CDN_URL, CLASSNAME_TOAST, KEY_CODE } from 'constants/commonData';

function AlterWorkoutSelected(props) {
  const {
    selectedWorkouts,
    toggleConfirmModal,
    permission,
    handleStartCopyMultipleWorkout,
    handleDeleteMultipleWorkout,
    handleResetSelectedMultipleWorkout,
  } = props;
  useEffect(() => {
    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, []);
  const onEscPressed = event => {
    const { toggleConfirmModal } = props;
    if (
      event.keyCode === KEY_CODE.esc &&
      document.querySelector('.remove-week-popup, .remove-workout-popup, .calendar--max-select')
    ) {
      toggleConfirmModal && toggleConfirmModal(false);
    }
  };
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V2 || !get(permission, 'copy_week')) return <></>;
  if (isEmpty(selectedWorkouts)) return <></>;
  function onCopyWorkout() {
    handleStartCopyMultipleWorkout();
    toast(
      `${selectedWorkouts.length} ${pluralize(
        'Workout',
        selectedWorkouts.length,
      )} copied. Click on date to paste ${pluralize('workout', selectedWorkouts.length)}.`,
      { className: CLASSNAME_TOAST.TRAINING_CALENDAR },
    );
  }
  function onRemoveWorkout() {
    toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove ${selectedWorkouts.length} ${pluralize('workout', selectedWorkouts.length)}`}
        content={`The selected workouts will be permanently deleted. Would you like to continue?`}
        onConfirm={handleDeleteMultipleWorkout}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        noBorder={true}
        hasCloseIcon={true}
        className="remove-workout-popup"
      />,
    );
  }
  return (
    <S.BottomSelectWorkoutWrapper>
      <S.BottomNumberSelect>
        <S.BottomNumber>{selectedWorkouts.length}</S.BottomNumber>
        <S.BottomLabel>{`${pluralize('Workout', selectedWorkouts.length)} selected`}</S.BottomLabel>
      </S.BottomNumberSelect>
      <S.BottomActionsContainer>
        <S.BottomActions onClick={onRemoveWorkout}>
          <S.IconActions>
            <TrashIcon />
          </S.IconActions>
          <S.TextActions>Remove</S.TextActions>
        </S.BottomActions>
        <S.BottomActions onClick={onCopyWorkout}>
          <S.IconActions>
            <CopyIcon />
          </S.IconActions>
          <S.TextActions>Copy</S.TextActions>
        </S.BottomActions>
      </S.BottomActionsContainer>
      <S.BottomCancel>
        <S.TextCancel>
          <S.CancelBold>Esc</S.CancelBold>&nbsp;to cancel
        </S.TextCancel>
        <CloseIcon onClick={handleResetSelectedMultipleWorkout} />
      </S.BottomCancel>
    </S.BottomSelectWorkoutWrapper>
  );
}

export default AlterWorkoutSelected;
