import styled, { css } from 'styled-components';
import Next from 'assets/icons/next_step.svg';

export const PackageBackgroundContainer = styled.div`
  width: 40px;
  margin-right: 15px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const PackageName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #202353;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 70%;
  word-break: break-word;
`;

export const TableCellContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  .__react_component_tooltip.app-tooltip {
    padding: 10px 32px;
    border-radius: 5px;
  }

  .evf-dropdown__menu {
    padding: 10px 0 8px;

    .evf-dropdown__option {
      padding: 8px 15px 7px;
    }

    .line {
      height: 1px;
      background: #3f3f3f;
      margin: 8px 15px 7px;
    }
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PricingColumnCell = styled(TableCellContent)`
  display: block;
`;

export const TableCell = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  padding: 18px 15px;
  flex: 1;

  &.pricing {
    min-width: 22.6%;
    display: flex;

    & > div {
      margin: auto 0;
    }
  }

  &.total-sold {
    min-width: 15%;
    padding-top: 30px;
  }

  &.revenues {
    min-width: 12.7%;
    padding-top: 30px;
  }

  &.active-user {
    min-width: 16%;
    padding-top: 30px;
  }

  &.actions {
    width: 60px;
    padding-top: 26px;
    padding-right: 15px;

    ${TableCellContent} {
      justify-content: flex-end;
    }

    .evf-dropdown__option {
      font-weight: 600;

      svg {
        margin-right: 11px;
        min-width: 14px;
      }
    }
  }

  ${props =>
    props.noResult &&
    css`
      text-align: center;
    `}
`;

export const PackageNameCell = styled.div`
  padding-left: 0;
  position: relative;
  width: 28%;
  height: 76px;
  padding-top: 16px;

  ${TableCellContent} {
    padding-left: 25px;
  }
`;

export const StatusCircle = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #c4c4c4;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  ${props =>
    props.isPublished &&
    css`
      background-color: #2fd787;
    `}
`;

export const TableRow = styled.div`
  display: flex;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  height: 49px;

  &.body-item {
    min-height: 76px;
  }
`;

export const TableCellHeader = styled.div`
  box-shadow: inset 0px -2px 0px #f1f3f4;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  color: #6a778a;
  padding: 19px 15px 15px;

  span {
    margin-right: 8px;
  }

  &.name {
    width: 28%;
  }

  &.pricing {
    min-width: 22.6%;
  }

  &.total-sold {
    min-width: 15%;
  }

  &.active-user {
    min-width: 16%;
  }

  &.revenues {
    min-width: 12.7%;
  }

  &.actions {
    width: 60px;
  }

  ${props =>
    props.isSorting &&
    css`
      color: #5158cf;
    `}
  ${props =>
    props.sortable &&
    css`
      :hover {
        cursor: pointer;
        color: #5158cf;
      }
    `}
`;

export const TableHeader = styled.thead`
  position: relative;
  z-index: 1;
`;

export const TableBody = styled.div`
  overflow: auto;
  height: calc(100% - 49px);

  ${TableRow} {
    :hover {
      background-color: #fafbfc;
      cursor: pointer;
    }

    :last-child {
      box-shadow: none;
    }
  }
`;

export const Table = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
  overflow: hidden;
  margin-bottom: ${props => props.hasOverLap && '45px'};
`;

export const TabBarSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  button {
    width: 158px;
    padding: 8px 10px;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .search-input {
    font-size: 12px;
    line-height: 16px;
    width: 265px;

    input {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

export const FilterByDaysWrapper = styled.div`
  width: 147px;
  height: 36px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  border: 1px solid #d4d7d9;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #f5f7f9;
  }
  .evfSelectBoxContainer {
    .evfSelectBox__control {
      border: none;
      background: transparent;
      &:hover {
        border: none;
        background: transparent;
      }
      &.evfSelectBox__control--menu-is-open {
        background: transparent;
        border: none !important;
      }
      .evfSelectBox__single-value {
        font-weight: 600;
        font-size: 11px;
        line-height: 11px;
      }
      .evfSelectBox__value-container {
        min-width: 85px;
        padding: 0 3px;
      }
    }
    .evfSelectBox__control--is-focused {
      border: none;
    }
    .evfSelectBox__menu {
      width: 202px;
      box-shadow: 0px 2px 4px rgba(42, 42, 52, 0.15);
      border-radius: 3px;
      z-index: 9999;
      right: -18px;
    }
    .evfSelectBox__dropdown-indicator {
      padding: 0;
    }
  }
`;

export const DropdownLabel = styled.label`
  font-weight: 600;
  font-size: 11px;
  color: #777777;
`;

export const PriceValue = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const Over = styled(PriceValue)`
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
`;

export const ListWrapper = styled.div`
  height: calc(100vh - ${({ hasAlertHeader }) => (hasAlertHeader ? '299px' : '255px')});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props =>
    props.isEmpty &&
    css`
      height: calc(100vh - ${({ hasAlertHeader }) => (hasAlertHeader ? '344px' : '300px')});
    `}
`;

export const BlankContainer = styled.div`
  width: 791px;
  display: flex;
  margin: 107px auto auto;
`;

export const StepContainer = styled.div`
  width: 189px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const StepImage = styled.img``;

export const NextStep = styled.div`
  margin: 0 22px;
  background-position: center;
  background-repeat: no-repeat;
  width: 70px;
  height: 137px;
  background-image: url(${Next});
`;

export const StepTitle = styled.p`
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #202353;
  opacity: 0.4;
  text-transform: uppercase;
  padding-top: 2px;
  margin-bottom: 6px;
`;

export const StepDesc = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  padding-top: 120px;
  .ui.loader:before {
    border-color: #ededed;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
  }
`;
