import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListComments } from 'redux/forum/actions';
import { getNotifications } from 'redux/notification/actions';
import Component from './component';

const mapState = state => {
  const { user, rootReducer } = state;
  return { userId: user._id, notification: rootReducer.notification };
};

const mapDispatchToProps = dispatch => ({
  getListComments: bindActionCreators(getListComments, dispatch),
  getNotifications: bindActionCreators(getNotifications, dispatch),
});

export default connect(mapState, mapDispatchToProps)(Component);
