import React, { useState, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';
import { Modal } from 'semantic-ui-react';
import { diff } from 'deep-diff';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

import { getUserShortName, revokeObjectURL, revokeMultipleObjectURL, mediaLog } from 'utils/commonFunction';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import ConfirmModal from 'shared/ConfirmModal';
import * as S from './style';
import ChatBar from './components/ChatBar';
import MessageItem from './components/MessageItem';
import Schedule from './components/Schedule';
import { ASSET_TYPES } from 'components/OnboardingFlowDetail/constants';
import { CDN_URL } from 'constants/commonData';
import WarningIcon from 'assets/icons/warning_red_light.svg';

function AssignOnboardingMessage(props) {
  const { user, onboardingMessage, toggleConfirmModal, toggleModal, afterSchedule, isEditMessage } = props;
  const removeIds = [];
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [uploadingItems, setUploadingItems] = useState([]);
  const [hasTextInInput, setHasTextInInput] = useState(false);
  const [newOnboardingMessage, setNewOnboardingMessage] = useState(null);
  const bodyRef = useRef();
  const onCloseRef = useRef();

  const userInfo = useMemo(
    () => ({ src: get(user, 'avatar'), name: getUserShortName(user), color: get(user, 'color') }),
    [user],
  );
  const title = useMemo(() => get(newOnboardingMessage, 'title', ''), [newOnboardingMessage]);
  const messages = useMemo(() => get(newOnboardingMessage, 'messages', []), [newOnboardingMessage]);
  const messageLength = useMemo(() => size(messages), [messages]);
  const messageRemaining = useMemo(() => 3 - Math.min(messageLength, 3), [messageLength]);

  useEffect(() => {
    return () => {
      revokeMultipleObjectURL(map(uploadingItems, 'objectURL'));
    };
  }, []);

  useEffect(() => {
    if (onboardingMessage && isEmpty(newOnboardingMessage)) {
      setNewOnboardingMessage(onboardingMessage);
    }
  }, [onboardingMessage]);

  const onClose = () => {
    toggleModal(false);
  };

  const handleCloseModal = (showConfirmPopup = true) => {
    if (showConfirmPopup && diff(onboardingMessage, newOnboardingMessage)) {
      onCloseRef.current && onCloseRef.current();
      return toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content="Are you sure you want to go? Changes have not been saved yet."
          onConfirm={onClose}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={WarningIcon}
        />,
      );
    }
    onClose();
  };

  const onSubmit = () => {
    if (isSubmitting) {
      return false;
    }

    if (isEmpty(title.trim()) && messageLength > 0) {
      return setShowError(true);
    }

    const data = {
      title: isEmpty(messages) ? '' : title,
      type: get(newOnboardingMessage, 'type', 'during_period'),
      during_period_from: get(newOnboardingMessage, 'during_period_from', '09:00 AM'),
      during_period_to: get(newOnboardingMessage, 'during_period_to', '05:00 PM'),
      delay_after_enable: get(newOnboardingMessage, 'delay_after_enable', false),
      delay_after_value: get(newOnboardingMessage, 'delay_after_value', 5),
      delay_after_unit: get(newOnboardingMessage, 'delay_after_unit', 'minutes'),
      messages: map(messages, m => omit(m, ['type', 'objectURL'])),
    };

    const continueSubmit = () => {
      setIsSubmitting(true);
      // Promise.resolve()
      //   .then(() => onClose(false))
      //   .catch(() => setIsSubmitting(false));
      onClose();
      afterSchedule({ ...onboardingMessage, ...data }, ASSET_TYPES.ONBOARDING_MESSAGE);
      setIsSubmitting(false);
    };

    if (hasTextInInput) {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Are you done?"
          content="You have typed a message that has not been added to the list of scheduled messages yet. Would you like to add the message first, or continue to save and exit?"
          onConfirm={continueSubmit}
          confirmButtonTitle="Save & exit"
          cancelButtonTitle="Cancel"
          newStyle={true}
        />,
      );
    } else {
      continueSubmit();
    }
  };

  const onSendMessage = message => {
    if (messageLength < 3) {
      setNewOnboardingMessage(it => ({ ...it, messages: [...get(it, 'messages', []), message] }));

      if (message.content === '{{file}}') {
        setUploadingItems([...uploadingItems, message._id]);
      }
    }
    setTimeout(() => {
      if (bodyRef && bodyRef.current) {
        bodyRef.current.scroll({ top: bodyRef.current.scrollHeight, behavior: 'smooth' });
      }
    }, 300);
  };

  const onRemoveMessage = index => {
    const newMessages = messages.slice();
    const removeMessages = newMessages.splice(index, 1);
    removeIds.push(messages[index]._id);

    if (removeMessages[0].objectURL) {
      revokeObjectURL(removeMessages[0].objectURL);
      if (!isEmpty(uploadingItems) && uploadingItems.includes(removeMessages[0]._id)) {
        setUploadingItems(uploadingItems.filter(id => id !== removeMessages[0]._id));
      }
    }

    setNewOnboardingMessage(it => ({ ...it, messages: newMessages }));
  };

  const isRemovedFromLocal = mId => {
    return removeIds.includes(mId);
  };

  const onUploadSuccess = ({ mId, data }) => {
    const newMessages = messages.slice();

    if (!isRemovedFromLocal(mId)) {
      const index = findIndex(messages, m => m._id === mId);

      if (messages[index]) {
        const { attachment, attachment_id } = data;
        const { attachment: name, attachment_type: fileType } = messages[index];
        mediaLog({
          status: 2,
          name,
          fileType,
          description: 'Upload success file via Onboarding Assign Message',
        });
        newMessages.splice(index, 1, {
          ...omit(messages[index], 'uploadConfigs'),
          attachment,
          attachment_id,
        });
      }
      setNewOnboardingMessage(it => ({ ...it, messages: newMessages }));
    }

    const newUploadingItems = uploadingItems.slice();
    remove(newUploadingItems, id => id === mId);

    setUploadingItems(newUploadingItems);
  };

  const onCancelUpload = ({ mId }) => {
    const index = findIndex(messages, m => m._id === mId);

    if (index !== -1) {
      onRemoveMessage(index);
      const newUploadingItems = uploadingItems.slice();
      remove(newUploadingItems, id => id === mId);

      setUploadingItems(newUploadingItems);
      onRemoveMessage(index);
    }
  };

  return (
    <S.ModalWrapper
      open={true}
      onClose={handleCloseModal}
      className={classnames('modal--in-app-message')}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.InAppMessageWrapper className="inAppMessageForm">
          <ModalLayout.Header className="inAppMessageForm__header">
            <div className="inAppMessageForm__header__title">Schedule Onboarding Messages</div>
            <div className="inAppMessageForm__header__description">
              In-app onboarding messages will be delivered based on the Client's timezone.
            </div>
          </ModalLayout.Header>
          <ModalLayout.Content className="inAppMessageForm__content">
            <Schedule
              newOnboardingMessage={newOnboardingMessage}
              setNewOnboardingMessage={setNewOnboardingMessage}
              showError={showError}
              onCloseRef={onCloseRef}
            />
            <S.Messages className="inAppMessageForm__messages" ref={bodyRef}>
              {map(messages, (m, index) => (
                <MessageItem
                  key={`message-${m._id}`}
                  message={m}
                  avatarData={userInfo}
                  onRemoveMessage={onRemoveMessage}
                  onUploadSuccess={onUploadSuccess}
                  onCancelUpload={onCancelUpload}
                  index={index}
                  disabled={false}
                />
              ))}
              {!!messageLength && <S.Tip>Each message will be sent 5s apart from each other</S.Tip>}
            </S.Messages>
            <ChatBar
              onSubmit={onSendMessage}
              placeholder="Type a message here"
              disabled={messageLength > 2}
              setHasTextInInput={setHasTextInInput}
            />
          </ModalLayout.Content>
          <ModalLayout.Actions className="inAppMessageForm__actions">
            <div className="remaining">Messages remaining: {messageRemaining}/3</div>
            <S.SubmitButton
              disabled={(!isEditMessage && !messageLength) || uploadingItems.length}
              onClick={onSubmit}
              purple
              isRemove={isEditMessage && !messageLength}
            >
              {isEditMessage ? (messageLength ? 'Update' : 'Remove') : 'Schedule'}
            </S.SubmitButton>
          </ModalLayout.Actions>
        </S.InAppMessageWrapper>
      </Modal.Content>
    </S.ModalWrapper>
  );
}

export default AssignOnboardingMessage;
