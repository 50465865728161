import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { denormalize } from 'normalizr';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { SectionFormatInput } from './style';
import { SECTION_FORMAT_KEY } from 'constants/commonData';
import { Section } from 'database/schema';
import { ENTITY_NAME } from 'database/constants';
import { updateEntity } from 'redux/model/actions';
import { roundTwoDigit } from 'helpers/number';
import { pluralize } from 'utils/commonFunction';
import { onlyAllowNumber } from 'utils/event-api';

function FormatInput(props) {
  const { model, sectionId, showError } = props;
  const section = model.toJS();

  const onChange = event => {
    const { value } = event.target;
    const parsedValue = parseFloat(value, 10);
    const newData = {};

    if (section.format === SECTION_FORMAT_KEY.TIMED) {
      if (value > 99) {
        return;
      }

      newData.round = value !== 0 && isNaN(parseInt(parsedValue)) ? '' : parseInt(parsedValue);
    } else {
      if (value > 999) {
        return;
      }

      newData.time = isNaN(parsedValue) ? '' : value * 60;
    }

    props.updateEntity(ENTITY_NAME.Section, sectionId, newData);
  };

  switch (section.format) {
    case SECTION_FORMAT_KEY.AMRAP: {
      let value = parseFloat(section.time);
      value = isNaN(value) ? '' : value / 60;

      return (
        <SectionFormatInput className="section__formatInput">
          <span className="label">Duration</span>
          <div className={classnames('inputContainer', { invalid: showError && !value })}>
            <div className="input">
              <span>{value}</span>
              <input
                type="number"
                min="0"
                max="999"
                step="0.1"
                value={value}
                onChange={onChange}
                onFocus={event => event.target.select()}
                onKeyPress={onlyAllowNumber}
              />
            </div>
            <span>min</span>
          </div>
        </SectionFormatInput>
      );
    }

    case SECTION_FORMAT_KEY.TIMED: {
      return (
        <SectionFormatInput className="section__formatInput">
          <span className="label">Round</span>
          <div className={classnames('inputContainer', { invalid: showError && !section.round })}>
            <div className="input">
              <span>{section.round}</span>
              <input
                type="number"
                min={0}
                step={1}
                max={99}
                value={section.round}
                onChange={onChange}
                onFocus={event => event.target.select()}
                onKeyPress={onlyAllowNumber}
              />
            </div>
            <span>{pluralize('round', section.round)}</span>
          </div>
        </SectionFormatInput>
      );
    }

    case SECTION_FORMAT_KEY.INTERVAL: {
      const totalDuration = _.sumBy(section.exercises, exercise => {
        return _.sumBy(exercise.supersets, superset => {
          const eachEide = superset.each_side ? 2 : 1; // TODO Use to double rest times
          return _.sumBy(superset.training_sets, set => {
            const duration = parseInt(_.get(set, 'duration.value', 0)) || 0;
            const rest = parseInt(_.get(set, 'rest.value', 0)) || 0;
            return (duration + rest) * eachEide;
          });
        });
      });

      const min = Math.floor(totalDuration / 60);
      const seconds = totalDuration % 60;

      return (
        <SectionFormatInput className="section__formatInput">
          <span className="label">Duration</span>
          <div className="inputContainer readOnly" data-tip data-for={`tooltip--section${sectionId}__formatInput`}>
            <div className="input" style={{ minWidth: 'unset' }}>
              <span>
                {min}
                {seconds ? ':' + `0${seconds}`.slice(-2) : ''}
              </span>
            </div>
            <span>min</span>
          </div>
          <ReactTooltip id={`tooltip--section${sectionId}__formatInput`} className="app-tooltip" effect="solid">
            <span>The duration is calculated based on the workout and rest times for each of the exercises</span>
          </ReactTooltip>
        </SectionFormatInput>
      );
    }

    default: {
      return null;
    }
  }
}

const mapState = (state, ownProps) => {
  const {
    rootReducer: { model, workoutBuilder },
  } = state;

  return {
    model: denormalize(model.getIn([ENTITY_NAME.Section, ownProps.sectionId]), Section, model),
    showError: workoutBuilder.get('showError'),
  };
};

const actionCreators = { updateEntity };

export default connect(mapState, actionCreators)(FormatInput);
