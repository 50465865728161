import React from 'react';
import { toast } from 'react-toastify';
import { toggleModal } from 'actions/modal';
import {
  changeCalendarType,
  changeCalendarStartDate,
  changeCalendarStartDateSuccess,
  changeCalendarTypeSuccess,
} from 'actions/calendar';
import { Mixpanel } from 'utils/mixplanel';
import { MultiplePasteMessage } from 'shared/Notifications';
import Request from 'configs/request';
import { TOPICS } from '../../constants/commonData';

export const Types = {
  MOVE_TASK: 'MOVE_TASK',
  CLIENT_TASK_REMOVE_REPEAT_SETTING: 'CLIENT_TASK_REMOVE_REPEAT_SETTING',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  REMOVE_TASKS_REPEATING_SUCCESS: 'REMOVE_TASKS_REPEATING_SUCCESS',
  SOCKET_HABIT_UPDATED: 'SOCKET_HABIT_UPDATED',
};

export const changeTaskCalendarType = (params, calendarType) => {
  return dispatch => {
    dispatch(changeCalendarType(params, calendarType));

    return dispatch(
      Request.get({ url: '/api/task/v4/list', params }, true, result => {
        const tasks = result.data.data;
        dispatch(getTasksSuccess(tasks));
        dispatch(changeCalendarTypeSuccess(calendarType));
      }),
    );
  };
};

const getTasksSuccess = tasks => ({ type: 'SUCCESS_GET_TASKS', payload: { data: tasks } });

export const getRepeatTasksSuccess = tasks => ({ type: 'SUCCESS_GET_REPEAT_TASKS', payload: { data: tasks } });

export const removeTasksRepeatingSuccess = tasks => ({
  type: Types.REMOVE_TASKS_REPEATING_SUCCESS,
  payload: { data: tasks },
});

export const changeTaskCalendarMode = mode => ({
  type: 'CHANGE_TASK_CALENDAR_VIEW_TYPE',
  payload: { data: mode },
});

export const changeTaskCalendarStartDate = (params, calendarStartDate) => {
  return (dispatch, getState) => {
    dispatch(changeCalendarStartDate(params, calendarStartDate));
    let tParams = params;

    if (!tParams) {
      const currentState = getState();
      calendarStartDate = currentState.calendarStartDate;
      const newEndDate = calendarStartDate.plus({
        days: 7 * currentState.calendarType,
      });
      tParams = {
        client: currentState.rootReducer.client.selected,
        start_date: calendarStartDate.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      };
    }

    if (!tParams.client) {
      return false;
    }

    return dispatch(
      Request.get({ url: '/api/task/v4/list', params: tParams }, true, result => {
        const tasks = result.data.data;
        dispatch(getTasksSuccess(tasks));
        dispatch(changeCalendarStartDateSuccess(calendarStartDate));
      }),
    );
  };
};

export const changeTaskCalendarAutoflowIntervalStartDate = (params, calendarStartDate) => {
  return (dispatch, getState) => {
    dispatch(changeCalendarStartDate(params, calendarStartDate));
    let tParams = params;

    if (!tParams) {
      const currentState = getState();
      calendarStartDate = currentState.calendarStartDate;
      const newEndDate = calendarStartDate.plus({
        days: 7 * currentState.calendarType,
      });
      tParams = {
        client: currentState.rootReducer.client.selected,
        start_date: calendarStartDate.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      };
    }

    return dispatch(
      Request.get(
        { url: '/api/autoflow-interval-task/get-autoflow-calendar-by-weeks', params: tParams },
        true,
        result => {
          const tasks = result.data.data;
          dispatch(getTasksSuccess(tasks));
          dispatch(changeCalendarStartDateSuccess(calendarStartDate));
        },
      ),
    );
  };
};

export const moveTask = params => {
  return dispatch => {
    dispatch({ type: Types.MOVE_TASK, payload: { data: params } });
    return dispatch(
      Request.put({ url: '/api/task/move', data: params }, false, result => {
        const data = result.data.data;
        dispatch({ type: 'APPEND_TASK_DAYS_REDUX', payload: data });
      }),
    );
  };
};

export const arrangeTask = params => {
  return dispatch => {
    dispatch({ type: Types.MOVE_TASK, payload: { data: params } });
    return dispatch(
      Request.put({ url: '/api/task/arrange', data: params }, false, result => {
        const data = result.data.data;
        dispatch({ type: 'APPEND_TASK_DAY_REDUX', payload: data });
      }),
    );
  };
};

export const addTask = data => {
  return Request.post({ url: '/api/task/v3/add-task', data }, true, (result, { dispatch }) => {
    if (result && result.data && result.data.data) {
      !data.repeat_task_setting_id && dispatch(changeTaskCalendarStartDate());
      Mixpanel.track('task_create_new');
    }
  });
};

export const addRepeatTask = data => {
  const END_POINT = '/api/repeat-task-setting/create';
  return Request.post({ url: END_POINT, data }, true);
};

export const removeSettingRepeat = data => {
  const END_POINT = '/api/task/v3/remove-repeat-task/';
  return Request.delete(
    {
      url: `${END_POINT}${data.taskId}`,
      data: {
        is_only_this_task: data.is_only_this_task,
      },
    },
    true,
    (_, { dispatch }) => {
      if (data.repeatId) {
        dispatch({ type: Types.CLIENT_TASK_REMOVE_REPEAT_SETTING, payload: { ...data } });
        dispatch(changeTaskCalendarStartDate());
      }
    },
  );
};

export const addTaskAutoFlowInterval = data => {
  Mixpanel.track('autoflow_interval_task_calendar_screen_add_new_task');
  return Request.post({ url: 'api/autoflow-interval-task/add-new-task', data }, true, (result, { dispatch }) => {
    if (result && result.data && result.data.data) {
      dispatch(changeTaskCalendarStartDate());
      dispatch(toggleModal(false));
    }
  });
};

export const updateTask = data => {
  return Request.put({ url: '/api/task/v3/update-task', data }, true, (result, { dispatch }) => {
    if (result && result.data && result.data.data) {
      (!data.repeat_task_setting_id || !data.should_refresh) && dispatch(changeTaskCalendarStartDate());
      Mixpanel.track('task_update');
    }
  });
};

export const addTaskFromLibrary = data => {
  return Request.post({ url: '/api/task/v2/add-task-from-library', data }, true, (result, { dispatch }) => {
    if (result && result.data && result.data.data) {
      dispatch(changeTaskCalendarStartDate());
      dispatch(toggleModal(false));
    }
  });
};

export const pasteTask = (data, multiplePaste) => {
  return Request.post({ url: '/api/task/copy', data }, true, (result, { dispatch }) => {
    Mixpanel.track('task_copy');
    if (result && result.data && result.data.data) {
      dispatch(changeTaskCalendarStartDate());

      if (!multiplePaste) {
        dispatch({ type: 'COPIED_TASK' });
      }
    }
  });
};

export const removeTask = params => {
  const { is_only_this_task } = params;
  const data = {
    is_only_this_task,
  };

  return Request.delete(
    {
      url: `/api/task/remove/${params.taskId}`,
      data,
    },
    true,
    (result, { dispatch }) => {
      dispatch(changeTaskCalendarStartDate());
      Mixpanel.track('task_delete');
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_DELETE_TASK', error }),
  );
};

export const removeReduxTask = taskId => ({ type: 'REMOVE_TASK_REDUX', payload: { taskId } });

export const copyTask = data => {
  toast(<MultiplePasteMessage title="tasks" />);

  return { type: 'COPY_TASK', payload: { data } };
};

export const saveToLibrary = data => {
  return Request.post({ url: '/api/task/v2/save-to-library', data }, true, () => {
    toast('Task has been added to your Library');
    Mixpanel.track('task_save_to_library');
  });
};

export const getTaskLibrary = taskId => {
  return Request.get({ url: '/api/task/v2/get-task-library' }, true);
};

export const deleteTaskLibrary = taskId => {
  return Request.delete({ url: `/api/task/v2/task-library/${taskId}` }, true);
};

export const resetCopyItem = () => ({ type: 'CLIENT_TASK_CLEAR_COPY_ITEM' });

export const getComments = taskId => {
  return Request.get({ url: '/api/comment/list', params: { topic: 'task', item: taskId } }, true);
};

export const getCommentsHabit = habitId => {
  return Request.get({ url: '/api/comment/list', params: { topic: TOPICS.HABIT, item: habitId } }, true);
};

export const getCommentsTopic = (id, topic) => {
  return Request.get({ url: '/api/comment/list', params: { topic, item: id } }, true);
};

export const addCommentForTask = data => Request.post({ url: '/api/comment/add', data }, true);

export const getTaskDetail = taskId => {
  return Request.get({ url: `/api/task/v2/get-task-detail`, params: { taskId } }, true);
};

export const socketHabitUpdated = data => {
  return { type: Types.SOCKET_HABIT_UPDATED, payload: data };
};

export const getListReplies = params => {
  const { comment_id, reply_id } = params;

  if (!comment_id || !reply_id) return;

  return Request.get({ url: `api/comment/${comment_id}/replies?reply_id=${reply_id}` }, true);
};
