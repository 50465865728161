import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import { deleteClient } from 'redux/autoflow/client/actions';
import { selectClients, deleteClients, resetData } from 'redux/autoflowInterval/client/actions';
import { getClientList, changeAutoflowClientQueryParam, startToday } from 'redux/autoflowInterval/client/actions';
import { toggleModal } from 'actions/modal';

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  const query = rootReducer.autoflowInterval.client.get('query');
  const list = rootReducer.autoflowInterval.client.get('list');
  const firstLoad = rootReducer.autoflowInterval.client.get('firstLoad');
  const selectedClients = rootReducer.autoflowInterval.client.get('selectedClients');

  return {
    list: list.toJS(),
    selectedClients: selectedClients.toJS(),
    sort: query.get('sort'),
    sorter: query.get('sorter'),
    firstLoad,
    autoflowStatus: workingAutoflow && workingAutoflow.status,
    workingAutoflow,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  getClientList: bindActionCreators(getClientList, dispatch),
  changeAutoflowClientQueryParam: bindActionCreators(changeAutoflowClientQueryParam, dispatch),
  selectClients: bindActionCreators(selectClients, dispatch),
  deleteClient: bindActionCreators(deleteClient, dispatch),
  deleteClients: bindActionCreators(deleteClients, dispatch),
  startToday: bindActionCreators(startToday, dispatch),
  resetData: bindActionCreators(resetData, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
