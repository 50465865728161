import styled, { css } from 'styled-components';
import { TableCell, TableHeaderCell, TableHeaderCellContent } from 'shared/LibraryLayout';
import ButtonIcon from 'shared/ButtonIcon';
import { TableContainer } from 'shared/LibraryLayout';
import { Wrapper } from 'shared/LibraryLayout';
import { CDN_URL } from 'constants/commonData';

export const TableWrapper = styled(TableContainer)`
  overflow-x: scroll;
  padding-right: 40px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 51px;
  width: 51px;
  min-height: 40px;

  .checkbox__thumbnail {
    width: 40px;
    height: 40px;
    display: none;
  }

  .checkbox__header {
    display: flex;
    justify-content: center;
    padding-left: 27px;
  }

  &.checked,
  :hover {
    .thumbnail-container {
      display: none;
    }

    .checkbox__thumbnail {
      display: flex;
      justify-content: center;

      .evf-checkbox {
        width: 100%;
        border: none;
      }

      .checkbox-icon {
        width: 15px;
        height: 15px;
        left: calc(50%);
        background-size: calc(15px / 2);
      }

      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    }
  }

  .checkbox__thumbnail .checkbox-icon,
  .checkbox-icon {
    width: 15px;
    height: 15px;
    left: calc(50% - calc(13px / 2));
    transform: translateY(-50%) translateX(-50%);
    border: 1px solid #b6b6b6;
    background-image: url(${CDN_URL}/images/checked_white.svg);
    border-radius: 2px;
  }

  .checkbox__thumbnail {
    .checkbox-icon {
      left: calc(50%);
    }
  }

  .evf-checkbox {
    padding-left: 0;
  }

  ${props =>
    props.isHasSelectedPrev &&
    css`
      .checkbox-icon {
        width: 15px;
        height: 15px;
        left: calc(50% - calc(13px / 2));
        background-size: calc(15px / 2);
        background-image: none;
      }

      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    `}
`;

export const TasksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .text {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #202353;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    max-width: 100%;

    @media screen and (min-width: 1441px) {
      max-width: initial;
    }
  }
`;

export const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .thumb-general {
    width: 18px;
    height: 18px;
  }

  .thumb-progress-photo {
    width: 19px;
    height: 18px;
  }

  .thumb-body-metric {
    width: 25px;
    height: 25px;
  }

  .thumb-form {
    width: 15px;
    height: 16px;
  }
`;

export const ThumbnailContainer = styled.div`
  overflow: hidden;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  width: 40px;
  height: 40px;
`;

export const FilterTrigger = styled.div`
  padding: 5px 25px;
  padding-left: 50px;
  background: url(${CDN_URL}/images/new_filter.svg) no-repeat 25px 6px;
  background-size: auto;
  background-color: #ffffff;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  cursor: pointer;

  :hover {
    background-color: #f8f8f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  }
`;

export const ResetFilter = styled.div`
  display: inline-block;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  background: transparent url(${CDN_URL}/images/reset_new.svg) no-repeat 0 center;
  background-size: auto;

  :hover {
    cursor: pointer;
    background-image: url(${CDN_URL}/images/reset_new_purple.svg);
    color: #5158cf;
  }

  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const ToolbarLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: 5px;
  }

  .button--delete-tasks-lib {
    color: #777777;
    padding: 5px 10px;
    font-size: 12px;

    svg {
      width: 14px;
      height: 15px;
    }
  }

  .exercise-filter {
    margin-right: 5px;

    &.open {
      ${FilterTrigger} {
        background-color: #f8f8f8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      }
    }

    .exercise-filter__count {
      right: -10px;
      top: -11px;
      border-radius: 6px;
      width: 22px;
      height: 21px;
      line-height: 17px;
      border: 3px solid #ffffff;
    }

    .exercise-filter__trigger::after {
      content: none;
    }
  }

  .search--task-lib {
    input {
      font-size: 12px;
      line-height: 16px;
    }
  }

  button.button--custom-purple {
    &:hover,
    &:focus {
      background-color: rgba(81, 88, 207, 0.9) !important;
      border-color: rgba(81, 88, 207, 0.9) !important;
    }
  }
`;

export const ToolbarRight = styled.div`
  display: flex;
`;

export const Button = styled(ButtonIcon)`
  button {
    color: #fff;
    height: 30px !important;
    padding: 0px 24px !important;
    font-family: 'Open Sans' !important;
    background-color: #5158cf !important;
    border: none !important;
    margin: 0px !important;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(81, 88, 207, 0.9) !important;
    }

    svg {
      color: white;
      margin-right: 10px !important;
    }

    span {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 0px !important;
    }
  }
`;

export const ButtonAddFirstTask = styled(ButtonIcon)`
  margin-top: 38px;
  margin-left: 4px;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: unset !important;
    gap: 10px;
    width: 164px;
    height: 36px;
    background-color: #5158cf !important;
    border-radius: 3px !important;
    border-color: #5158cf !important;
    margin: unset !important;

    &:hover,
    &:focus {
      background-color: rgba(81, 88, 207, 0.9) !important;
      border-color: rgba(81, 88, 207, 0.9) !important;
    }

    svg {
      color: #ffffff;
      /* margin-right: 5px; */
    }

    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: unset !important;
    }
  }
`;

export const TasksHeader = styled(TableHeaderCell)`
  padding: 5px 15px;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
  &:nth-child(1) {
    width: 400px !important;
    min-width: 250px !important;
  }
  &:nth-child(2) {
    width: auto !important;
  }
  &:nth-child(3) {
    width: 200px !important;
  }

  &.title {
    width: unset;
    min-width: 400px;
  }

  &.center-align {
    display: flex;
    align-items: center;
    box-shadow: none;
  }

  &.actions {
    width: 95px;
  }

  .checkbox {
    width: 15px;
    margin-right: 15px;

    .evf-checkbox {
      height: 15px;
      width: 15px;
      padding-left: 0;
      padding-right: 0;
      margin-right: 15px;

      .checkbox-icon {
        height: 15px;
        width: 15px;
        background-size: calc(15px / 2);
      }

      input:checked ~ .checkbox-icon {
        border: none;
        background-color: #7072dd !important;
        background-image: url(${CDN_URL}/images/checked_white.svg);
      }
    }
  }
`;

export const TaskTableCell = styled(TableCell)`
  padding: 10.5px 16px 10.5px 8px;
`;

export const TasksTableHeader = styled(TableHeaderCellContent)`
  .column-symbol {
    margin-left: 5px;
  }

  svg.arrow {
    path {
      fill: none !important;
    }
  }
`;

export const TasksTableCell = styled(TasksTableHeader)``;

export const TasksOwnerAvatar = styled.div`
  .owner-avatar {
    opacity: 1 !important;

    .app-tooltip {
      box-shadow: none !important;
    }
  }
`;

export const OptionIcon = styled.div`
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const LayoutWrapper = styled(Wrapper)`
  padding-right: 20px;

  .evfPagination {
    padding-right: 40px;
  }
`;

export const TasksTitleContent = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  svg.column-symbol {
    margin-right: unset;
  }
`;

export const TaskBodyMostRecent = styled.div`
  width: 81px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
