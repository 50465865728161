import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
`;

export const SectionItemWrapper = styled.div`
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #ebedf2;
  border-radius: 5px;
  cursor: grab;
  position: relative;

  :hover {
    border: 1px solid #5158cf;
  }

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    margin-bottom: 5px;
    margin-right: 20px;
    word-break: break-all;
  }

  .sub-title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &__format {
      padding: 2px 10px;
      background: #deebff;
      border-radius: 2px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #5088e2;
      text-transform: capitalize;
    }

    &__dot {
      height: 3px;
      width: 3px;
      border-radius: 50%;
      background-color: #9091a9;
      margin: 0px 8px;
    }

    &__no-exercises {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;

      color: #202353;
    }
  }

  .exercises-desc {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    opacity: 0.5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
  }

  .section-item__author {
    position: absolute;
    top: 7px;
    right: 7px;
  }
`;
