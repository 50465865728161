// Libs
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import ReactTooltip from 'react-tooltip';

// Actions
import { openSecondToggle } from 'redux/broadcast-messages/actions';

// Shared
import { Button } from 'shared/FormControl';

// Assets
import { ReactComponent as FilterIcon } from 'assets/icons/MealPlans/meal_plan_filter_icon.svg';

import * as S from './style';

function FilterCategoriesPopup(props) {
  const {
    appliedFilter,
    trigger,
    onSelectedFilterApply,
    classNameCustom = '',
    allCategory = [],
    onChangeNumberFilter,
  } = props;

  const initFilterState = {
    categories: [],
  };

  const [open, setOpen] = useState(false);
  const [clear, setClear] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState({ ...initFilterState, ...appliedFilter });
  const { categories = [] } = selectedFilter || {};

  const [changedRangeNumber, setChangedRangeNumber] = useState(0);

  useEffect(() => {
    if (!open) {
      setSelectedFilter({ ...initFilterState, ...appliedFilter });
      setClear(!clear);
    }
  }, [appliedFilter, open]);

  const disableFilter = useMemo(() => {
    return isEqual((appliedFilter || {}).categories, categories);
  }, [appliedFilter, categories]);

  const triggerRef = useRef();
  const menuRef = useRef();

  const onUpdate = () => {
    onSelectedFilterApply && onSelectedFilterApply(selectedFilter);
    setOpen(false);
  };

  const handleCloseFilterPopup = () => {
    setSelectedFilter({ ...initFilterState, ...appliedFilter });
    setOpen(false);
  };

  const handleResetSelectedFilter = () => {
    setSelectedFilter(initFilterState);
    setChangedRangeNumber(0);
    setClear(!clear);
    onSelectedFilterApply(initFilterState);
  };

  const handleOpen = () => {
    if (open) {
      setSelectedFilter({ ...initFilterState, ...appliedFilter });
    }
    setOpen(prev => !prev);
  };

  const handleSelectCategories = data => {
    const checked = get(selectedFilter, 'categories', []).some(item => item === get(data, '_id', ''));
    let updatedList = [];
    if (checked) {
      updatedList = [...get(selectedFilter, 'categories', [])].filter(item => item !== get(data, '_id', ''));
    } else {
      updatedList = [...get(selectedFilter, 'categories', []), get(data, '_id', '')];
    }
    setSelectedFilter({ categories: updatedList });
  };

  const filterNumber = useMemo(() => {
    const numberFilter = categories.length;
    typeof onChangeNumberFilter === 'function' && onChangeNumberFilter(numberFilter);
    return numberFilter;
  }, [changedRangeNumber, clear, open, categories]);

  const renderRecipeDietary = item => {
    const { _id = '', name = '', icon = '', icon_hover = '' } = item;

    const active = get(selectedFilter, 'categories', []).some(item => item === _id);

    return (
      <S.DietarySelectItemWrapper active={active} key={_id} onClick={() => handleSelectCategories(item)}>
        <S.IconCategoryOriginal src={[icon]} />
        <S.IconCategoryHover src={[icon_hover]} />
        <span>{name}</span>
      </S.DietarySelectItemWrapper>
    );
  };

  return (
    <RootCloseWrapper event="click" onRootClose={handleCloseFilterPopup}>
      <S.Wrapper className={classNames(classNameCustom, { open: open })}>
        <S.TriggerContainer ref={triggerRef} onClick={handleOpen}>
          {trigger ? (
            trigger(open)
          ) : (
            <S.FilterButton active={open} data-tip data-for="button-filter-tooltip">
              <FilterIcon className="filter-icon" />
              {filterNumber > 0 && <S.FilterNumber className="filter-number">{filterNumber}</S.FilterNumber>}
              <ReactTooltip id="button-filter-tooltip" effect="solid" place="top">
                Filter
              </ReactTooltip>
            </S.FilterButton>
          )}
        </S.TriggerContainer>
        <S.Popup ref={menuRef} className="popup-content">
          <S.Header>
            <S.PopupTitleHeader>Filter by</S.PopupTitleHeader>
            <S.ClearAllFilter onClick={handleResetSelectedFilter}>Clear All</S.ClearAllFilter>
          </S.Header>
          <S.Body>
            <S.FilterSectionWrapper>
              <S.SelectDietary>{allCategory.map(renderRecipeDietary)}</S.SelectDietary>
            </S.FilterSectionWrapper>
          </S.Body>
          <S.Footer className="footer">
            <Button purple onClick={onUpdate} className="update-button">
              Update
            </Button>
          </S.Footer>
        </S.Popup>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}

const mapDispatch = dispatch => ({
  openSecondToggle: bindActionCreators(openSecondToggle, dispatch),
});

export default connect(null, mapDispatch)(FilterCategoriesPopup);
