/**
 * @flow
 */

import type { Action } from 'types/redux';
import { TOGGLE_MODAL } from 'actions/modal';
import { TOGGLE_POPUP } from 'actions/popup';

export const popup = (state: string = '', action: Action) => {
  switch (action.type) {
    case TOGGLE_POPUP:
      return action.popup;
    case TOGGLE_MODAL:
      return '';
    default:
      return state;
  }
};
