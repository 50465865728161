export const TYING_TYPES = {
  REMOVE_PROGRAM: 'remove_program',
  REMOVE_FORUM: 'remove_forum',
  ADD_OPTION: 'add_option',
  REMOVE_OPTION: 'remove_option',
  REMOVE_QUESTION: 'remove_question',
  TURN_OFF_QUESTION: 'turn_off_question',
  CHANGE_FORM: 'change_form',
  REMOVE_ONBOARDING_FORM: 'remove_onboarding_form',
};

export const CONTENT_TYPING_TYPES = {
  [TYING_TYPES.REMOVE_PROGRAM]: {
    _id: TYING_TYPES.REMOVE_PROGRAM,
    icon: '/images/remove_icon_bg_red.svg',
    title: 'Are you sure to delete program?',
    description:
      'This program is being used as assignment of a trigger for Onboarding Flow. Removing the program will result in the removal of the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Remove',
    submitButtonTheme: 'danger',
  },
  [TYING_TYPES.REMOVE_FORUM]: {
    _id: TYING_TYPES.REMOVE_FORUM,
    icon: '/images/remove_icon_bg_red.svg',
    title: 'Are you sure to delete forum?',
    description:
      'This forum is being used as assignment of a trigger for Onboarding Flow. Since you are the only coach, leaving now will also delete this forum and result in the removal of the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Leave and delete',
    submitButtonTheme: 'danger',
  },
  [TYING_TYPES.ADD_OPTION]: {
    _id: TYING_TYPES.ADD_OPTION,
    icon: '/images/warning_purple.svg',
    title: 'Are you sure to add this option?',
    description:
      'This question is being used as trigger for Onboarding Flow. Adding more options into the question may affect the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Add',
    submitButtonTheme: 'primary',
  },
  [TYING_TYPES.REMOVE_OPTION]: {
    _id: TYING_TYPES.REMOVE_OPTION,
    icon: '/images/remove_icon_bg_red.svg',
    title: 'Are you sure to delete this option?',
    description:
      'This question is being used as trigger for Onboarding Flow. Removing the option will result in the removal of the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Remove',
    submitButtonTheme: 'danger',
  },
  [TYING_TYPES.REMOVE_QUESTION]: {
    _id: TYING_TYPES.REMOVE_QUESTION,
    icon: '/images/remove_icon_bg_red.svg',
    title: 'Are you sure to delete this question?',
    description:
      'This question is being used as trigger for Onboarding Flow. Removing the form will result in the removal of the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Remove',
    submitButtonTheme: 'danger',
  },
  [TYING_TYPES.TURN_OFF_QUESTION]: {
    _id: TYING_TYPES.TURN_OFF_QUESTION,
    icon: '/images/red-warning-circle.svg',
    title: 'Are you sure to turn off the question?',
    description:
      'This question is being used as a trigger for Onboarding Flow. Turning off the question will result in the removal of the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Turn off',
    submitButtonTheme: 'danger',
  },
  [TYING_TYPES.CHANGE_FORM]: {
    _id: TYING_TYPES.CHANGE_FORM,
    icon: '/images/warning-change-question.svg',
    title: 'Are you sure to change the form?',
    description:
      'This form is being used as a trigger for Onboarding Flow. Changing the form will result in the removal of the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Change',
    submitButtonTheme: 'secondary',
  },
  [TYING_TYPES.REMOVE_ONBOARDING_FORM]: {
    _id: TYING_TYPES.REMOVE_ONBOARDING_FORM,
    icon: '/images/remove_icon_bg_red.svg',
    title: 'Are you sure to remove the form?',
    description:
      'This form is being used as a trigger for Onboarding Flow. Removing the form will result in the removal of the trigger.',
    guideDescription: 'Type "Confirm" to acknowledge the action.',
    placeholderInput: 'Type "Confirm"',
    confirmKey: 'Confirm',
    cancelLabelButton: 'Cancel',
    submitLabelButton: 'Remove',
    submitButtonTheme: 'danger',
  },
};

export const BLOCK_ACTION_TYPES = {
  CHANGE_TYPE_QUESTION: 'change_type_question',
  ARCHIVE_FORM: 'archive_form',
};

export const CONTENT_BLOCK_ACTION_TYPES = {
  [BLOCK_ACTION_TYPES.CHANGE_TYPE_QUESTION]: {
    _id: BLOCK_ACTION_TYPES.CHANGE_TYPE_QUESTION,
    icon: '/images/warning-change-question.svg',
    title: 'Cannot change type of question.',
    description:
      'This question is being used as trigger. You cannot change question type at the moment, please remove trigger from the following onboarding flows first:',
    submitLabelButton: 'I understand',
    submitButtonTheme: 'secondary',
  },
  [BLOCK_ACTION_TYPES.ARCHIVE_FORM]: {
    _id: BLOCK_ACTION_TYPES.ARCHIVE_FORM,
    icon: '/images/block-action-archive-form.svg',
    title: 'Cannot archive form',
    description:
      'This form is being used as trigger. You can not archive at the moment, please remove trigger from the following onboarding flows first:',
    submitLabelButton: 'I understand',
    submitButtonTheme: 'secondary',
  },
};

export const DUPLICATE_ACTION_TYPES = {
  REQUEST_DUPLICATE_FORM: 'request_duplicate_form',
};

export const CONTENT_DUPLICATE_ACTION_TYPES = {
  [DUPLICATE_ACTION_TYPES.REQUEST_DUPLICATE_FORM]: {
    _id: DUPLICATE_ACTION_TYPES.REQUEST_DUPLICATE_FORM,
    icon: '/images/warning_purple.svg',
    title: 'Do you want to create a duplicate?',
    description: `This form belongs to another coach. Please be aware any changes made by <strong class="coach-name">%COACH_NAME%</strong> will affect your onboarding flow. Do you want to create a duplicate?`,
    keyReplaceCoachName: '%COACH_NAME%',
    cancelLabelButton: 'Use existing form',
    submitLabelButton: 'Duplicate to my library',
    submitButtonTheme: 'primary',
  },
};

export const SWITCH_VIEW_TRIGGER_TYPES = {
  PROGRAM: 'program',
  FORUM: 'forum',
};
