import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { Accordion } from 'semantic-ui-react';
import { ReactComponent as SettingIcon } from 'assets/icons/task-advanced-setting-icon.svg';
import { ReactComponent as AccordionIcon } from 'assets/icons/task-accordion-icon.svg';
import { Toggle } from 'shared/FormControl';
import * as S from './styles';

export default function AdvancedSettings({
  task,
  onToggleComment,
  onToggleReminder,
  hideReminder = false,
  highlightTitle = false,
  disabled = false,
  expand = false,
  activeClassName = '',
}) {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  useEffect(() => {
    scrollToComponent();
  }, [active]);
  const enableComment = task.enable_comment;
  const showReminderMissed = task.show_reminder_missed;

  const handleClick = () => {
    setActive(!active);
  };
  const handleSwitchComment = () => {
    onToggleComment();
  };
  const handleSwitchReminder = () => {
    onToggleReminder();
  };
  const scrollToComponent = () => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };
  return (
    <S.Wrapper active={active} expand={expand} ref={ref}>
      <Accordion className="task-accordion">
        <Accordion.Title
          className={classnames('task-advanced-settings-title', { 'highlight-title': highlightTitle && active })}
          active={active}
          index={0}
          onClick={handleClick}
        >
          <SettingIcon className="task-settings-icon" />
          <span>ADVANCED SETTINGS</span>
          <AccordionIcon className="task-accordion-icon" />
        </Accordion.Title>
        <Accordion.Content active={active} className={activeClassName}>
          <S.ToggleRow className={classnames({ 'with-bottom-margin': !hideReminder })}>
            <Toggle width={36} height={20} disabled={disabled} checked={enableComment} onChange={handleSwitchComment} />
            <div className="task-toggle-option">Enable comment capability</div>
          </S.ToggleRow>
          {!hideReminder ? (
            <S.ToggleRow>
              <Toggle
                width={36}
                height={20}
                disabled={disabled}
                checked={showReminderMissed}
                onChange={handleSwitchReminder}
              />
              <div className="task-toggle-option">Show reminder if missed</div>
            </S.ToggleRow>
          ) : null}
        </Accordion.Content>
      </Accordion>
    </S.Wrapper>
  );
}
