import { Modal, TextArea } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import ConfirmModal from 'shared/ConfirmModal';

export const CustomModal = styled(Modal)`
  &.ui.modal {
    width: 540px;
    height: 100%;
    max-height: 702px;
    border-radius: 8px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden auto;
      border-radius: 8px;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      height: 100%;
      padding: 24px !important;
    }

    .actions {
      width: 100%;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      padding: 10px 24px;
    }
  }

  .error-input {
    border: 1px solid #ee2525 !important;
    background: #fff5f6 !important;
  }

  .error-select {
    .modality__control,
    .experience-levels__control {
      border: 1px solid #ee2525 !important;
      background: #fff5f6 !important;
    }
  }

  .field-row {
    margin-bottom: 10px;

    .input {
      width: 100%;

      &.week {
        width: 80px;
      }
    }

    &:last-child {
      margin-bottom: unset;
    }
  }

  .field-title {
    color: #6a778a;
    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    text-transform: uppercase;

    margin-bottom: 5px;

    &.field-select {
      margin-bottom: 8px;
    }
  }

  .program-header {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 24px 0 !important;
    margin: 0 !important;
  }

  .program-title-text {
    color: #202353 !important;
    font-family: 'Open Sans' !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 140% !important; /* 25.2px */
    text-transform: capitalize !important;
  }

  .program-more-actions-right {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .program-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .use-template-button {
    cursor: pointer;
    color: #848484;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    background: unset;
    border: none;
    padding: 0px;
  }

  .use-template-button:hover {
    color: #5c58c0;
  }

  .program-attachment-upload-cover {
    margin: 0px 10px 12px 0px;
  }

  .program-options {
    float: left;
  }

  .ui.dropdown .menu > .disabled.item {
    cursor: not-allowed;
    pointer-events: unset;
    opacity: 1;
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;

export const SubmitButtonWrapper = styled.div`
  position: relative;

  .submit-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 154px;
    height: 34px;

    border-radius: 5px;
    background: #5158cf;
    border: none;
    cursor: pointer;

    span {
      color: #fff;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &:disabled {
      background: #c4c4c4;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      background: rgba(81, 88, 207, 0.9);
    }
  }
`;

export const ProgramNameInput = styled.input`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 38px;
  border: 1px solid #d4d7d9 !important;

  &::placeholder {
    color: #969797 !important;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-color: #5c5bbd !important;
  }
`;

export const ProgramDescriptionInput = styled(TextArea)`
  border-radius: 4px !important;
  background-color: #fff !important;
  border: 1px solid #d4d7d9 !important;
  outline: 0;
  width: 100%;
  height: 100px !important;
  padding: 10px 15px;
  resize: none;

  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &::placeholder {
    color: #969797;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-color: #5c5bbd !important;
  }
`;
