import React from 'react';
import _ from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import * as S from './style';
import { assignClientToCollection } from 'redux/client-studio/actions';
import { Button } from 'shared/FormControl';
import StudioCollectionItem from './StudioCollectionItem';
import { axiosInstance } from 'configs/request';
import { CDN_URL, LIMIT_RESOURCE_COLLECTION } from 'constants/commonData';

const PER_PAGE = 100;
class AddStudioCollectionClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      textSearch: '',
      selectedCollection: [],
    };
    this.loadingRef = React.createRef();
    this.handleSearch = _.debounce(this.handleSearch, 300);
  }

  componentDidMount() {
    this.handleSearch();
    this.initIntersectionObserver();
  }

  componentDidUpdate() {
    if (!this.observer) {
      this.initIntersectionObserver();
    }
  }

  initIntersectionObserver = () => {
    if (this.loadingRef.current) {
      var options = {
        root: null, // Page as root
        rootMargin: '0px',
        threshold: 1.0,
      };
      // Create an observer
      this.observer = new IntersectionObserver(
        this.handleObserver, //callback
        options,
      );
      this.observer.observe(this.loadingRef.current);
    }
  };

  handleObserver = (entities, observer) => {
    const y = entities[0].boundingClientRect.y;
    const { isLoading, hasMore } = this.state;
    if (this.state.prevY > y && !isLoading && hasMore) {
      this.loadMore();
    }
    this.setState({ prevY: y });
  };

  loadMore = () => {
    const { textSearch, page } = this.state;
    this.handleSearch(textSearch, page + 1);
  };

  onChangeSearch = e => {
    this.setState({ textSearch: e.target.value, searchResults: [], selectedCollection: [], isSelectedAll: false });
    this.handleSearch(e.target.value);
  };

  handleSearch = (textSearch, nextPage = 1) => {
    const params = {
      search: textSearch || '',
      page: nextPage,
      per_page: PER_PAGE,
    };
    this.setState({ isLoading: true });
    axiosInstance
      .post('/api/studio-collection/v2/fetch-by-trainer', params)
      .then(response => {
        const { data, page } = response.data;
        const { searchResults } = this.state;
        this.setState({
          searchResults: _.uniqBy([...searchResults, ...data], '_id'),
          page,
          hasMore: PER_PAGE <= data.length,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onSelectCollection = collection => {
    const { selectedCollection } = this.state;
    const { selectedIds, collections_per_client } = this.props;
    if (_.includes(selectedCollection, collection._id)) {
      this.setState(state => ({
        selectedCollection: state.selectedCollection.filter(item => item !== collection._id),
      }));
    } else {
      const counter = selectedIds.length + selectedCollection.length;
      counter < collections_per_client &&
        this.setState(state => ({
          selectedCollection: [...state.selectedCollection, collection._id],
        }));
    }
  };

  onClearSearch = () => {
    this.setState({
      textSearch: '',
      searchResults: [],
    });
  };

  handleClose = () => {
    this.props.toggleModal(false);
  };

  onSubmit = () => {
    this.setState({ isSubmitting: true });
    const { selectedCollection } = this.state;
    const { selectedClient } = this.props;
    this.props
      .assignClientToCollection({ collectionIds: selectedCollection, clientId: selectedClient._id })
      .then(response => {
        this.handleClose();
        this.props.onAddSuccess();
      })
      .finally(() => {
        this.setState({ isSubmitting: true });
      });
  };

  render() {
    const { textSearch, searchResults, selectedCollection, isSubmitting, isLoading } = this.state;
    const { selectedIds, collections_per_client } = this.props;
    const searchItems = searchResults.filter(item => !_.includes(selectedIds, item._id));

    const isFully = [...selectedCollection, ...selectedIds].length > collections_per_client - 1;

    return (
      <S.AddStudioCollectionClientModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <Modal.Header>
          <div className="header__title">Choose a Collection</div>
          <S.SearchCollectionInput
            value={textSearch}
            onChange={this.onChangeSearch}
            onClearSearch={this.onClearSearch}
            placeholder="Search collection name"
          />
        </Modal.Header>
        <Modal.Content>
          <div className="collectionResult__heading">
            <div className="collectionResult__title">All Collections ({searchItems.length})</div>
          </div>
          <S.CollectionResultContainer>
            {!searchItems.length && (
              <div className="collectionResult__noResult">{isLoading ? 'Searching...' : 'No results found'}</div>
            )}
            {searchItems.map(item => (
              <StudioCollectionItem
                key={item._id}
                collection={item}
                onSelect={this.onSelectCollection}
                isFully={isFully}
                active={_.includes(selectedCollection, item._id)}
              />
            ))}
            <div ref={this.loadingRef} />
          </S.CollectionResultContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button className="actions__cancelBtn" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            className="actions__createBtn"
            disabled={!selectedCollection || isSubmitting}
            purple
            onClick={this.onSubmit}
          >
            Add
          </Button>
        </Modal.Actions>
      </S.AddStudioCollectionClientModal>
    );
  }
}

const mapStateToProps = state => {
  const {
    rootReducer: { client = {}, permission = {} },
  } = state;

  return {
    selectedClient: client.workingClientDetail,
    collections_per_client: permission.collections_per_client || LIMIT_RESOURCE_COLLECTION,
  };
};

const actionCreators = { toggleModal, push, assignClientToCollection };

export default connect(mapStateToProps, actionCreators)(AddStudioCollectionClient);
