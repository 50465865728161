import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getHabitDetail } from 'redux/habits/actions';
import { TYPE_FREQUENCY } from '../../constants';
import { formatDate, formatDateWithLuxon } from '../../helps';
import Calendar from './components/Calendar';
import CompleteWeek from './components/CompleteWeek';
import Progress from './components/Progress';
import Streak from './components/Streak';
import * as S from './style';

const typeFormatDate = 'MMM DD';

const handleInitData = (data, day) => {
  const today = day && formatDate(day.replace(/-/g, '/'));

  let currentDay = {};

  const listDayValue = data.day_values || [];

  const currentDayCheck = _.find(listDayValue, function (o) {
    return o.day === today;
  });

  if (currentDayCheck) {
    currentDay = currentDayCheck;
  } else {
    const nearCurrentDate = listDayValue[listDayValue.length - 1];
    currentDay = nearCurrentDate;
  }

  if (data.frequency === TYPE_FREQUENCY.PER_DAY) {
    return (
      {
        ...currentDay,
        dayCompare: formatDate(currentDay && currentDay.day),
        day: formatDate(currentDay && currentDay.day, typeFormatDate),
      } || {}
    );
  } else {
    let currentWeek = {};
    const listWeekValue = data.week_values || [];

    const getWeekOfYear = day && formatDateWithLuxon(day, 'MM-dd-yyyy');

    const currentWeekCheck = _.find(listWeekValue, function (o) {
      return o.week_of_year === getWeekOfYear;
    });

    if (currentWeekCheck) {
      currentWeek = currentWeekCheck;
    } else {
      const nearCurrentDate = listWeekValue[listWeekValue.length - 1];
      currentWeek = nearCurrentDate;
    }

    const dayStartOfWeek = moment(new Date(currentDay && currentDay.day))
      .startOf('week')
      .format(typeFormatDate);
    const dayEndOfWeek = moment(new Date(currentDay && currentDay.day))
      .endOf('week')
      .format(typeFormatDate);
    return (
      {
        ...currentWeek,
        startOfWeek: dayStartOfWeek,
        day: `${dayStartOfWeek} - ${dayEndOfWeek}`,
        dayCompare: formatDate(currentDay && currentDay.day),
      } || {}
    );
  }
};

const Insight = props => {
  const {
    match: {
      params: { clientId },
    },
    data,
    getHabitDetail,
    clientTimezone,
  } = props;

  const params = new URLSearchParams(window.location.search);

  const [dataCurrentDay, setDataCurrentDay] = useState(() => {
    return handleInitData(data, params.get('tracked'));
  });

  const [dataInsight, setDataInsight] = useState(data);
  const [monthCurrent, setMonthCurrent] = useState(formatDate(params.get('tracked'), 'MMM YYYY'));

  const handleChoiceDay = data => {
    if (data) {
      if (dataInsight.frequency === TYPE_FREQUENCY.PER_DAY) {
        setDataCurrentDay({
          ...data,
          dayCompare: formatDate(data.day),
          day: formatDate(data.day, typeFormatDate),
        });
      } else {
        const getWeekOfYear = data && data.day && formatDateWithLuxon(data.day);
        const currentWeek = _.find(dataInsight.week_values, function (o) {
          return o.week_of_year === getWeekOfYear;
        });
        const dayStartOfWeek = moment(new Date(data.day)).startOf('week').format(typeFormatDate);
        const dayEndOfWeek = moment(new Date(data.day)).endOf('week').format(typeFormatDate);
        setDataCurrentDay({
          ...currentWeek,
          startOfWeek: dayStartOfWeek,
          day: `${dayStartOfWeek} - ${dayEndOfWeek}`,
          dayCompare: formatDate(data.day),
        });
      }
    }
  };

  const handleGetHabitInsight = async value => {
    setMonthCurrent(formatDate(value, 'MMM YYYY'));
    getHabitDetail({
      habit_id: data._id,
      month: value,
      client: clientId,
    }).then(response => {
      const res = _.get(response, 'data.data');
      setDataInsight(res);
    });
  };

  return (
    <S.Insight>
      <S.Title>{dataInsight.title}</S.Title>
      <S.Layout gap="12px" mB="8px">
        <S.Item width="525px">
          <Streak
            type={dataInsight.frequency}
            currentStreak={dataInsight.current_streak}
            bestStreak={dataInsight.best_streak}
            averageHabit={dataInsight.average_habit}
            totalHabit={dataInsight.goal_value}
          />
        </S.Item>
        <S.Item>
          <CompleteWeek
            monthCurrent={monthCurrent}
            type={dataInsight.frequency}
            currentAverageHabitCompleted={dataInsight.current_average_habit_completed}
            currentTotalHabitCompleted={dataInsight.current_total_habit_completed}
            averageHabitCompleted={dataInsight.average_habit_completed}
            totalHabitCompleted={dataInsight.total_habit_completed}
            sinceDate={dataInsight.start_date}
          />
        </S.Item>
      </S.Layout>
      <S.Layout gap="12px">
        <S.Item width="525px">
          <Calendar
            dataCurrentDay={dataCurrentDay}
            type={dataInsight.frequency}
            unitName={dataInsight.unit.name}
            dataDaily={dataInsight.day_values}
            dataWeekly={dataInsight.week_values}
            goalValue={dataInsight.goal_value}
            handleChoiceDay={handleChoiceDay}
            handleGetHabitInsight={handleGetHabitInsight}
            clientTimezone={clientTimezone}
            startDate={dataInsight.start_date}
          />
        </S.Item>
        <S.Item>
          <Progress
            dataCurrentDay={dataCurrentDay}
            goalValue={dataInsight.goal_value}
            unitName={dataInsight.unit.name}
            unified={dataInsight.icon_unified}
            type={dataInsight.frequency}
            color={dataInsight.color}
            clientTimezone={clientTimezone}
          />
        </S.Item>
      </S.Layout>
    </S.Insight>
  );
};

const mapStateToProps = state => {
  return {
    clientTimezone: _.get(state.rootReducer.client.workingClientDetail, 'timezone'),
  };
};

export default withRouter(connect(mapStateToProps, { getHabitDetail })(Insight));
