import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Trigger = styled.div`
  height: 30px;
  border-radius: 4px;
  border: solid 1px #d3d7d9;
  background-color: #fff;
  display: flex;
  justify-content:center;
  align-items: center;
  color: #6f7076;
  padding: 0 12px;
  cursor: pointer;

  .icon.column {
    background-image: url(${CDN_URL}/images/columns.svg);
    background-repeat: no-repeat;
    width: 14px;
    height: 13.5px;
    background-size: 100% auto;
    background-position: center;
    margin-right: 10px;
  }

  :hover,
  &.open {
    color: #745ee6;
    .icon.column {
      background-image: url(${CDN_URL}/images/columns_purple.svg);
    }
  }

  i.icon {
    line-height: 10px;
    margin: 0;
    font-size: 10px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 20px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .columns__checkbox {
    width: 100%;
  }

  .column__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 14px;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.57px;
    color: #333333;
  
    .column__icon {
      width: 26px;
      > img {
        width: auto;
        max-width: 16px;
      }
    }
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: calc(100% + 9px);
  right: 0;
  display: block;
  padding: 6px 0 10px;
  border-radius: 4px;
  box-shadow: 0 5px 13px -4px rgba(0, 0, 0, 0.3);
  border: solid 1px #dedee2;
  background: #fff;
  z-index: 99999;
  max-height: calc(100vh - 210px);
  overflow: hidden auto;

  @media only screen and (max-width: 1000px) {
    max-height: calc(100vh - 250px);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: block;
`;
