import styled from 'styled-components';

export const DayCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
`;

export const ItemEmpty = styled.div`
  height: 218px;
  border: 1px dashed #e1e1ea;
  border-radius: 5px;
`;

export const MealEmpty = styled.div`
  min-height: 218px;
  max-height: 420px;
  border: 1px dashed #e1e1ea;
  border-radius: 5px;
  background-color: #fff;
`;
