import React from 'react';
import classNames from 'classnames';

import * as S from './style';

const TagWrapper = ({ icon, content, isDeleted = false }) => {
  return (
    <S.Wrapper className={classNames('tag-wrapper', { 'is-deleted': isDeleted })}>
      {!!icon && icon}
      <div className="tag-label">{content}</div>
    </S.Wrapper>
  );
};

export default TagWrapper;
