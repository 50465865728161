// Libs
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const RequestDuplicateFormModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 467px;
    height: fit-content;
    max-height: 745px;
    padding: 30px;
    border-radius: 8px;

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .header {
      display: grid;
      grid-template-columns: 25px 1fr;
      align-items: center;
      justify-items: flex-start;
      gap: 10px;
      padding: 0px;

      img.icon-header {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        object-fit: contain;
      }
    }

    .description {
      margin: 15px 0px 30px;
    }

    .content {
      padding: 0px;
      margin: 0px 0px 30px 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
    }

    .actions {
      border-top: none;
      background: none;
      text-align: unset;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: flex-end;
    }

    > :last-child {
      padding: 0px !important;
      border-radius: 0px !important;
    }

    .close-button {
      right: -8px;
      top: -8px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .title-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }

    .description-modal {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #323c47;

      strong.coach-name {
        font-weight: 600;
      }
    }

    .cancel-button {
      cursor: pointer;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #d6dae4;
      border-radius: 5px;
      padding: 4px 29px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #818da1;
      min-width: 170px;

      &:hover {
        background: #f3f3f3;
      }
    }

    .submit-button {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
      border-radius: 5px;
      border: unset;
      padding: 4px 29px;
      border: 1px solid transparent;
      min-width: 204px;

      &.primary {
        background: #5158cf;
        border-color: #5158cf;
        color: #ffffff;

        &:hover {
          background: rgba(81, 88, 207, 0.9);
          border-color: rgba(81, 88, 207, 0.9);
        }
      }
    }
  }
`;
