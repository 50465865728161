import _ from 'lodash';
import { Types } from './actions';
import { Types as ParentTypes } from '../actions';
import { getAvailableMetrics } from 'utils/commonFunction';

const INITIAL_STATE = {
  features: [],
  workoutVisibility: [],
  metrics: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.AUTOFLOW_SUCCESS_GET_FEATURE_PREFERENCES:
    case Types.AUTOFLOW_SUCCESS_UPDATE_FEATURE_PREFERENCES:
      return Object.assign({}, state, { features: payload.data });

    case Types.AUTOFLOW_SUCCESS_GET_WORKOUT_VISIBILITY_PREFERENCES:
    case Types.AUTOFLOW_SUCCESS_UPDATE_WORKOUT_VISIBILITY_PREFERENCES:
      return Object.assign({}, state, { workoutVisibility: payload.data });

    case Types.AUTOFLOW_SUCCESS_GET_METRICS:
    case Types.AUTOFLOW_SUCCESS_METRIC_UPDATE_FEATURING:
      return Object.assign({}, state, { metrics: getAvailableMetrics(payload.data) });

    case Types.AUTOFLOW_SUCCESS_UPDATE_METRIC:
    case Types.AUTOFLOW_SUCCESS_UPDATE_MULTIPLE_METRIC:
    case Types.AUTOFLOW_SUCCESS_DELETE_METRIC:
    case Types.AUTOFLOW_SUCCESS_ADD_METRIC:
      return Object.assign({}, state, { metrics: getAvailableMetrics(payload.data) });

    case Types.AUTOFLOW_SUCCESS_TURN_ON_FEATURING:
      return Object.assign({}, state, {
        features: _.map(state.features, item => (item.type === payload.type ? { ...item, state: true } : item)),
      });

    case ParentTypes.RESET_AUTOFLOW_DETAIL_DATA:
      return INITIAL_STATE;

    case ParentTypes.AUTOFLOW_TURN_OFF_SETTING_SUCCESSFULLY:
      return Object.assign({}, state, { features: payload.newSettings });

    default:
      return state;
  }
};
