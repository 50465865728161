import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  .descriptionInput__textArea {
    background: #ffffff;
    border-radius: 5px;
    width: 100%;
    max-height: 130px;
    padding: 5px 15px;

    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    resize: none;
    border: none;
    outline: none;
    overflow: auto;

    &::placeholder {
      color: #969fb5;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }

    :hover {
      &:not(:focus) {
        background: #f1f1f3;
      }
    }

    ${props =>
      props.isEdit &&
      css`
        border: 1px solid #5c5bbd;
      `}

    ${props =>
      props.isViewMode &&
      css`
        border: 1px solid #ebedf4;
        background: #ffffff !important;
      `}

    &--empty {
      background: #f1f1f3;
      opacity: 0.5;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }
  }

  .descriptionInput__cancelViewMode {
    width: 16px;
    height: 16px;
    background: url(${CDN_URL}/images/close_circle_new.svg) no-repeat;
    position: absolute;
    top: -8px;
    right: -8px;
    background-position: center;
    border-radius: 50%;
    cursor: pointer;

    :hover {
      background: url(${CDN_URL}/images/close_circle_new_hover.svg) no-repeat;
    }
  }

  .descriptionInput__viewMore {
    position: absolute;
    bottom: 5px;
    right: 30px;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #5c5bbd;
    cursor: pointer;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const EditActionWrapper = styled.div`
  margin-top: 10px;
  text-align: right;

  .editAction__cancel {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    opacity: 0.5;
    cursor: pointer;
  }

  .editAction__confirmChange {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    text-align: right;
    color: #5c5bbd;
    margin-left: 20px;
    cursor: pointer;
  }
`;
