import { getPlainText, removeScriptTags } from 'components/AnnouncementPopup/helper';
import { clearInlineStylesAndClasses } from 'utils/commonFunction';

export const LIST_SORTER = [
  {
    key: 'createdAt',
    label: 'Created Date',
  },
  {
    key: 'updatedAt',
    label: 'Edited Date',
  },
];

export const EDITOR_MODE = {
  WRITE: 'write',
  READ_ONLY: 'read_only',
};

export const MAX_CONTENT_LENGTH = 10000;

export const addIsShowDateTag = ({ initArray = [], key = 'createdAt' } = {}) => {
  return initArray.reduce((acc, curr, index, array) => {
    const currentDate = curr[key].slice(0, 10);
    curr.isShowDateTag = false;
    curr.isHiddenLineBottom = false;

    if (index === 0) {
      curr.isShowDateTag = true;
    }

    if (index > 0 && !curr.isShowDateTag) {
      const prevDate = array[index - 1][key].slice(0, 10);

      if (currentDate !== prevDate) {
        curr.isShowDateTag = true;
      }
    }

    if (index < array.length - 1) {
      const nextDate = array[index + 1][key].slice(0, 10);

      if (currentDate !== nextDate) {
        curr.isHiddenLineBottom = true;
      }
    }

    acc.push(curr);
    return acc;
  }, []);
};

export const getParamsContent = content => {
  let contentHTMLFormat = removeScriptTags(content);
  // contentHTMLFormat = addInlineStyleToTags(contentHTMLFormat);
  contentHTMLFormat = clearInlineStylesAndClasses(contentHTMLFormat);

  let contentPlainText = getPlainText(content);
  contentPlainText = (contentPlainText || '').trimAny('↵').trim();

  return {
    content: contentPlainText,
    content_composer: contentHTMLFormat,
  };
};

export const scrollItemIntoView = ({ id, block = 'nearest' } = {}) => {
  if (!id) return;

  setTimeout(() => {
    const noteSelectedElement = document.querySelector(`[data-id="${id}"]`);
    if (noteSelectedElement) {
      noteSelectedElement.scrollIntoView({ block });
    }
  }, 0);
};

export const checkIsChangeContentNote = (notes, noteSelected) => {
  if (!notes || !noteSelected) return false;

  const originalNote = notes.find(it => it._id === noteSelected._id);
  const isChangeNote = (originalNote || {}).content !== (noteSelected || {}).content;
  const isChangeNoteFormat = (originalNote || {}).content_composer !== (noteSelected || {}).content_composer;

  return isChangeNote || isChangeNoteFormat;
};

export const BUILDER_TYPES = {
  NOTE: 'note',
  LIMITATION: 'limitation',
};

export const LABELS_BY_TYPES = {
  [BUILDER_TYPES.NOTE]: {
    button_add: 'New Note',
    new_note_title: 'New Note',
    placeholder_search: 'Search for a note',
    placeholder_composer: 'Type your note',
    remove_modal_title: 'Remove Note',
    remove_modal_description: 'Are you sure that you want to remove this note?',
    empty_search_label: 'No results found',
    empty_items_title: 'Add your first note.',
    empty_items_description: `This will be private and not shared\nwith your client.`,
  },
  [BUILDER_TYPES.LIMITATION]: {
    button_add: 'New',
    new_note_title: 'New Limitation/Injury',
    placeholder_search: 'Search for a limitation/injury',
    placeholder_composer: 'Type your note',
    remove_modal_title: 'Remove Limitation/Injury',
    remove_modal_description: 'Are you sure that you want to remove this limitation/injury?',
    empty_search_label: 'No results found',
    empty_items_title: 'Add first limitation/injury.',
    empty_items_description: `This will be private and not shared\nwith your client.`,
  },
};

export const getListAndSelectNext = ({ currentList, newList, removeId } = {}) => {
  const newListNote = newList || currentList.filter(it => it._id !== removeId) || [];

  const removedIndex = currentList.findIndex(note => note._id === removeId);

  let newSelected;
  if (newListNote.length > 0) {
    if (removedIndex === -1) {
      newSelected = newListNote[0];
    } else {
      const newSelectedIndex = removedIndex >= newListNote.length ? newListNote.length - 1 : removedIndex;
      newSelected = newListNote[newSelectedIndex];
    }
  } else {
    newSelected = undefined;
  }

  return { newListNote, newSelected };
};
