import styled from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
`;

export const Logo = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  margin: 40px 0;
  .logo-icon {
    width: 140px;
    height: 24px;
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 44px;
`;

export const VerifiedConfirmInfo = styled.div`
  max-width: ${({ isSuccess }) => (isSuccess ? '535px' : '716px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
`;

export const VerifiedTitle = styled.div`
  ${textBase}
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #141414;
`;

export const VerifiedDesc = styled.span`
  width: 100%;

  ${textBase}
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #595959;

  .desc-link {
    font-weight: 700;
    color: #5158cf;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const VerifiedActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VerifiedButton = styled.button`
  ${textBase}
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
  border: none;
  border-radius: 8px;
  background: #5158cf;
  outline: none;
  padding: 16px 40px;
  margin: unset;
  letter-spacing: 0.02em;
  &:hover {
    cursor: pointer;
    background: rgba(81, 88, 207, 0.8);
  }
  &::selection {
    color: #fff;
  }
  &:disabled {
    cursor: not-allowed;
    background: #c4c4c4;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 20px;

  @media only screen and (max-width: 980px) {
    overflow-x: hidden;
    background-color: #fff;
    body::-webkit-scrollbar,
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    ${ContentWrapper} {
      row-gap: 24px;
    }
    ${VerifiedConfirmInfo} {
      row-gap: 16px;
    }
    ${VerifiedTitle} {
      font-size: 24px;
      line-height: 33px;
    }
    ${VerifiedDesc} {
      font-size: 14px;
      line-height: 24px;
    }
    ${VerifiedButton} {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;
