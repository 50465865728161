import styled, { css } from 'styled-components';
import { FormGroup as FG, ErrorMessage } from 'shared/FormControl';
import { SubmitButton } from 'layouts/Login/style';
import { CDN_URL } from 'constants/commonData';

export const AccountType = styled.div`
  background: #ffffff;
  border: 1px solid #e3e6ef;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding: 14px;
  color: #bfc6d0;

  ${props =>
    props.active &&
    css`
      background: #f7f7ff;
      border-color: #5158cf;
      position: relative;
      color: #5158cf;

      :before {
        content: '';
        position: absolute;
        right: 10px;
        top: 7px;
        z-index: 1;
        background: transparent url(${CDN_URL}/images/checkbox_purple.svg) no-repeat center;
        width: 12px;
        height: 12px;
        background-size: contain;
      }
    `}
`;

export const AccountTypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const Eye = styled.div`
  position: absolute;
  right: 0;
  bottom: 12px;
  z-index: 1;
  cursor: pointer;
  height: 14px;
  text-align: center;
  vertical-align: middle;

  :hover {
    svg {
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const VerifyPasswordTooltip = styled.div`
  position: absolute;
  right: ${({ isOverlap }) => (isOverlap ? '62px' : '-248px')};
  bottom: ${({ isOverlap }) => (isOverlap ? '48px' : '-88px')};
`;

export const Agree = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #8a929d;
  padding: 30px;

  .highlight {
    color: #5158cf;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fafbfc;

  ${SubmitButton} {
    margin-bottom: 15px;
  }

  .g-recaptcha {
    margin: 13px auto;
    transform: scale(0.8190789474);
    display: flex;
    justify-content: center;
    height: 78px;
  }

  .form-group--password {
    margin-bottom: 35px;

    input {
      padding-right: 15px;
    }
  }
`;

export const RegisterFormGroup = styled(FG)`
  margin-bottom: 14px;

  ${ErrorMessage} {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #d94f3d;
    margin-top: 10px;
  }

  input {
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #e3e6ef;
    box-sizing: border-box;
    padding: 20px 15px;
    font-size: 14px;
    line-height: 19px;
    color: #191753;
    font-weight: normal;

    ::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #91979e;
    }

    :focus {
      background-color: #ffffff;
      border: 1px solid #5158cf;
      box-sizing: border-box;
    }

    &[value='']:not(:focus) {
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
    }

    &.referral-code-input {
      padding: 20px 48px 20px 40px;
      background-image: url(${CDN_URL}/images/referral_input_icon.svg);
      background-position: 15px;
      background-repeat: no-repeat;

      :focus {
        background-image: url(${CDN_URL}/images/referral_input_active_icon.svg);
      }
    }
  }

  &.error input {
    background-color: rgba(234, 49, 74, 0.05) !important;
    border-color: #ea314a !important;
  }

  .referral-form-group {
    position: relative;

    .referral-reset-input {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 15px;
      top: 50%;
      cursor: pointer;
      transform: translateY(-50%);
      background-image: url(${CDN_URL}/images/close_circle_new_hover.svg);
      background-position: cover;
      background-repeat: no-repeat;
    }
  }
`;

export const RegisterInput = styled.input`
  background: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #e3e6ef;
  box-sizing: border-box;
  padding: 20px 15px;

  :focus {
    background: #ffffff;
    border: 1px solid #5158cf;
    box-sizing: border-box;
  }
`;

export const SuggestEmailInput = styled.div`
  padding: 8px;
  align-self: stretch;

  color: #191753;
  font-size: 14px;
  font-family: 'Open Sans';
  line-height: 120%;

  border-radius: 4px;
  background: rgba(55, 105, 233, 0.08);

  margin-top: 6px;

  .email-suggestion {
    color: #3769e9;
    font-weight: 400;
    cursor: pointer;

    overflow-wrap: break-word;
    word-break: break-all;
    text-align: left;
  }

  .email-suggestion:hover {
    color: #3769e9;
  }
`;

export const SpelledEmail = styled.a``;

export const BoldEmail = styled.span`
  font-weight: 700;
`;

export const SubtitleContent = styled.div`
  margin: 16px 0;
  text-align: center;
  font-family: 'Open Sans';
  color: #91979e;
  font-size: 11px;
  line-height: 150%;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  ::before,
  ::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #e3e8ef;
    margin: auto;
  }
  ::before {
    margin-right: 28px;
  }
  ::after {
    margin-left: 28px;
  }
`;

export const JoinTeamWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 2px;
`;

export const JoinTeamTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-family: 'Open Sans';
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
`;

export const JoinTeamName = styled.div`
  font-family: 'Open Sans';
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
`;
