import React, { useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { parsePhoneNumberFromString, parseIncompletePhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js';
import { toast } from 'react-toastify';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { axiosInstance } from 'configs/request';
import './style.scss';

export default function (props) {
  const [phone, setPhone] = useState('');
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const closePopup = () => {
    if (!props.fromTopbar) {
      props.runOnboarding('');
      props.maximizeCheckList();
    }
    props.toggleModal(false);
  };

  const onChange = event => {
    const { value } = event.target;
    let newValue = parseIncompletePhoneNumber(value);

    if (newValue === phone) {
      if (formatIncompletePhoneNumber(value, 'US').indexOf(value) === 0) {
        // Trim the last digit (or plus sign).
        newValue = newValue.slice(0, -1);
      }
    }

    setPhone(newValue);
  };

  const onSubmit = event => {
    event.preventDefault();

    if (submitting) {
      return false;
    }

    const parsed = parsePhoneNumberFromString(phone, 'US');

    if (!showError) {
      setShowError(true);
    }

    if (parsed && parsed.isValid()) {
      setSubmitting(true);
      axiosInstance
        .post('/api/auth/send_link_coach', { phone: parsed.number })
        .then(() => {
          toast('Success! Download links have been sent to your phone.', { className: 'wider' });
          closePopup();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  const parsedPhone = parsePhoneNumberFromString(phone, 'US');
  const invalidPhoneNumber = showError && !!phone.length && (!parsedPhone || !parsedPhone.isValid());

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
      onClose={closePopup}
      className="modal--download-coach-app"
    >
      <Modal.Content>
        <S.Wrapper>
          <S.Header />
          <S.Form onSubmit={onSubmit} error={invalidPhoneNumber}>
            <h1>The most powerful Coach app!</h1>
            <h3>Message clients, manage training, and even program workouts – all from the palm of your hand.</h3>
            <S.Controls>
              <S.InputContainer>
                <S.CountryCode>+ 1</S.CountryCode>
                <S.Input
                  placeholder="US Phone number"
                  value={formatIncompletePhoneNumber(phone, 'US')}
                  onChange={onChange}
                  autoFocus={true}
                />
              </S.InputContainer>
              <S.Button type="submit">Text me</S.Button>
            </S.Controls>
            <S.Error>Please use US numbers only</S.Error>
          </S.Form>
          <S.Footer>
            <a href="https://apps.apple.com/us/app/everfit-for-coach/id1485827117?ls=1" target="_blank">
              <img src={`${CDN_URL}/images/appstore_big.svg`} alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.everfit.coach" target="_blank">
              <img src={`${CDN_URL}/images/googleplay_big.svg`} alt="" />
            </a>
          </S.Footer>
        </S.Wrapper>
      </Modal.Content>
    </Modal>
  );
}
