import React from 'react';
import { PromoCostWrapper } from './style';

const getPromoValue = (promoCode, subTotal) => {
  if (promoCode.amount_off) {
    return promoCode.amount_off / 100;
  } else if (promoCode.amount_off === 0) {
    return 0;
  } else if (promoCode.percent_off) {
    return subTotal * (promoCode.percent_off / 100);
  }
};

function PromoAmount(props) {
  const { billingInfo } = props;
  const { discount } = billingInfo;

  if (!discount || !discount.coupon) {
    return null;
  }

  const promoAmount = getPromoValue(discount.coupon, billingInfo.subtotal);

  return (
    <PromoCostWrapper>
      <div className="promoCost__code">Promo - {discount.coupon.id}</div>
      <div className="promoCost__value">- {Number(promoAmount).toUSDFormat()}</div>
    </PromoCostWrapper>
  );
}

export default PromoAmount;
