export const FILE_VALIDATION = {
  VIDEO_VALIDATE_EXTENTION: ['avi', 'flv', 'mp4', 'mov', '3gp'],
  ACCEPTED_VIDEO: '.avi, .flv, .mp4, .mov, .3gp',
  ACCEPTED_IMAGE: 'image/png, image/jpeg, image/jpg',
  IMAGE_VALIDATE_EXTENTION: ['png', 'jpeg', 'jpg'],
};

export const DEFAULT_STATE = {
  videoData: {
    usingLink: false,
    invalidLink: false,
    thumbnail: '',
    src: '',
    error: '',
    apiURL: '',
    attachment_id: '',
  },
  photoData: {
    list: [],
    error: '',
  },
  uploadInformation: {
    file: null,
    bucketData: null,
    apiURL: '',
    isLocalFile: false,
  }
};

export const UPLOAD_CONFIGS = { url: '/api/upload/exercise', method: 'post' };

export const UPLOAD_KEY = 'media';

export const ERROR_MESSAGE = {
  INVALID_VIDEO_LINK: 'Please enter a valid Youtube or Vimeo URL',
  MAX_VIDEO_NUMBER: 'You can only upload 1 video',
  MAX_PHOTO_NUMBER: 'You can only upload 4 images',
};
