import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSelectPackage } from 'redux/pricing/actions';
import styled, { css } from 'styled-components';
import ContactUsModal from './ContactUsModal';
import { Helmet } from 'react-helmet';
import { C_KEY_TOKEN, PLANS, TRIAL_MODE } from 'constants/commonData';
import { toast } from 'react-toastify';
import { getCookie, genTokenFromMarketplace } from 'utils/commonFunction';
import { ToastPaymentChoose } from './style';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 60px;
  outline: none;

  .chooseAPlan__leftContent {
    &--title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 5px;

      color: #202353;
    }

    &--description {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      max-width: 394px;
      color: #202353;

      a {
        color: #202353;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .chooseAPlan__rightContent {
    display: flex;
    padding-right: 50px;
  }
`;

const TimeButton = styled.button`
  background: #e3e3e9;
  border-radius: 5px;
  padding: 10px 50px;
  border: none;
  outline: none;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #202353;
  position: relative;

  span {
    opacity: 0.6;
  }

  .planDiscount__circleWhite {
    position: absolute;
    z-index: 1;
    width: 11px;
    height: 11px;
    background-color: #fff;
    border-radius: 50%;
    right: 0;
    top: 48%;
    transform: translate(56%, -50%);
  }

  .chooseAPlan__discount {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(102%, -100%);
    z-index: 2;
    opacity: 1;

    .planDiscount__percent {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 100%;
      text-transform: uppercase;
      color: #28ae66;
      margin-bottom: 3px;
    }

    .planDiscount__circle {
      position: absolute;
      width: 7px;
      height: 7px;
      background-color: #28ae66;
      border-radius: 50%;
      left: 0;
      transform: translate(-50%, 50%);
      bottom: 0;
    }

    .planDiscount__dashedBorder {
      position: relative;
      width: 27px;
      height: 27px;
      border-bottom-right-radius: 80%;
      border: 1px dashed #28ae66;
      border-top: none;
      border-left: none;
    }
  }

  ${props =>
    props.active
      ? css`
          background: #202353;
          box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
          border-radius: 5px;
          color: #ffffff;
          span {
            opacity: 1;
          }
        `
      : ``}
`;

function ChooseAPlan({
  location,
  user,
  logout,
  replace,
  teamData,
  currentPackage,
  paymentAddOn,
  updateSelectPackage,
  period,
  ...props
}) {
  const [isShowModal, setIsShowModal] = useState(false);

  const search = get(location, 'search', '');
  const pathname = get(location, 'pathname', '');
  const isMP = search.includes('marketplace');

  const isPayment = paymentAddOn.toJS().find(it => it.code === 'payment_package') ? true : false;
  const isTrialOrStarter =
    teamData.get('tier') === PLANS.starter.key || teamData.get('trialMode') !== TRIAL_MODE.Expired;
  const defaultAddOns = {
    automation: false,
    payment_package: false,
    resource_collections: false,
    premiumCustomBrand: false,
    zapier: false,
  };
  const addOns = useMemo(() => {
    return {
      ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
      payment_package: isPayment,
    };
  }, [isTrialOrStarter, isPayment, currentPackage]);

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (isMP && token && search.includes('redirect-platform')) {
      genTokenFromMarketplace({
        token,
        user,
        pathname,
        search,
        replacePathname: '/pricing-plan?prev=/home/marketplace',
        dispatch: props.dispatch,
      });
    }
  }, [search, pathname]);

  // MP
  useEffect(() => {
    if (isMP) {
      const currentAddOns = {
        ...(isTrialOrStarter ? defaultAddOns : currentPackage.get('addOns').toJS()),
        payment_package: true,
      };

      setTimeout(() => {
        updateSelectPackage({ addOns: currentAddOns }, () => {
          toast(<ToastPaymentChoose>{`Payment & Packages add-on has\nbeen added to your plan`}</ToastPaymentChoose>);
        });
      }, 5000);
    }
  }, []);

  const openContactUsPopop = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const handlePeriodSelect = period => {
    const currentPlan = teamData.toJS();
    const zapierQuantity =
      get(currentPlan, 'addOns.zapier') && period === 'monthly' ? get(currentPlan, 'zapier_quantity') : null;

    updateSelectPackage({
      period,
      ...(zapierQuantity !== null && { zapier_quantity: zapierQuantity }),
    });
  };

  return (
    <Wrapper {...props}>
      <Helmet>
        <title>Choose Plan - Everfit</title>
      </Helmet>
      <div className="chooseAPlan__leftContent">
        <div className="chooseAPlan__leftContent--title">Choose a plan</div>
        <div className="chooseAPlan__leftContent--description">
          Let’s power up your business! Choose from the plans below, or <a onClick={openContactUsPopop}>contact us</a>{' '}
          if your team is training more than 500 clients.
        </div>
        <ContactUsModal isModalOpen={isShowModal} closeModal={handleCloseModal} dispatch={props.dispatch} />
      </div>
      <div className="chooseAPlan__rightContent">
        <TimeButton active={period === 'monthly'} onClick={() => handlePeriodSelect('monthly')}>
          <span>Monthly</span>
        </TimeButton>
        <TimeButton active={period === 'annually'} onClick={() => handlePeriodSelect('annually')}>
          <span>Annually</span>
          <div className="planDiscount__circleWhite" />
          <div className="chooseAPlan__discount">
            <div className="planDiscount__percent">Save 16%</div>
            <div className="planDiscount__dashedBorder">
              <div className="planDiscount__circle" />
            </div>
          </div>
        </TimeButton>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const period = rootReducer.pricing.getIn(['selectPackage', 'period']);
  return {
    period,
    user,
    paymentAddOn: rootReducer.pricing.getIn(['addOnsPricing']),
    currentPackage: rootReducer.pricing.getIn(['selectPackage']),
    teamData: rootReducer.pricing.getIn(['teamData']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAPlan);
