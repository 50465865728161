import styled, { css } from 'styled-components';

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(254px, 1fr) );
  grid-gap: 20px;
  padding-bottom: 10px;
  margin-bottom: 50px;
`;

export const NoResult = styled.div`
  margin-top: 65px;
  text-align: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  opacity: 0.4;
`;

export const Wrapper = styled.div`
  flex: 1 1;
  overflow: auto;
  padding: 0 30px 50px;

  .collectionList__heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    margin-bottom: 15px;
  }
`;
