import React from 'react';
import { LoaderWrapper } from './style';

const Loader = ({ size = 24, color = '#D9D9D9' }) => {
  return (
    <LoaderWrapper size={size} color={color}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoaderWrapper>
  );
};

export default Loader;
