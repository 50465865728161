import styled from 'styled-components';
import { TableHeaderCell } from 'shared/LibraryLayout';

export const WaitListHeader = styled(TableHeaderCell)`
  padding: 0;
  cursor: pointer;
  user-select: none;
  &:nth-child(1) {
    width: 400px !important;
  }
  &:nth-child(2) {
    width: 235px !important;
  }
  &:nth-child(3) {
    width: auto !important;
  }
`;
