import styled from 'styled-components';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #202353;
  line-height: 19px;
`;

export const CompleteWeek = styled.div`
  background: #fafcfd;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Label = styled.div`
  ${baseText}
`;

export const Content = styled.div``;

export const Item = styled.div`
  text-align: ${props => props.textAlign || 'center'};
  padding-right: ${props => props.paddingRight || '0px'};
  width: 80px;
  .ratio {
    display: none;
  }
  &:hover {
    .percent {
      display: none;
    }
    .ratio {
      display: block;
    }
  }
`;

export const Title = styled.div`
  ${baseText}
  font-size: 24px;
  line-height: 100%;
  cursor: pointer;
  margin-bottom: 4px;
`;

export const Desc = styled.div`
  ${baseText}
  font-size: 11px;
  line-height: 150%;
  text-transform: uppercase;
  color: #8b8da5;
`;
