import styled from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

const baseText = `
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 150%;
`;

export const MealPlanFallbackWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
`;

export const BannerImage = styled.img`
  width: 100%;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

export const BannerContent = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const ContentTitle = styled.div`
  ${baseText}
  color: #202353;
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
`;

export const ContentDesc = styled.div`
  ${baseText}
  color: #7B7E91;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  width: 85%;
`;

export const BannerActions = styled(UpgradePathButton)`
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 5px;
  display: flex;
  border: none;
  align-items: center;
  padding: 10px 50px;
  width: 207px;
  height: 36px;

  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }

  span {
    display: block;
    ${baseText}
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
  }
`;

export const BannerImageWrapper = styled.div`
  flex-shrink: 0;
  width: 450px;
  @media only screen and (max-width: 1280px) {
    width: 400px;
  }
`;
