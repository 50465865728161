import styled from 'styled-components';

export const Wrapper = styled.div`
  .onboarding-loading {
    color: #505373;
    padding: 8px 12px;
    text-align: center;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    user-select: none;
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .onboarding-flow--is-disabled,
  .onboarding-flow__control--is-disabled,
  .onboarding-flow__single-value--is-disabled {
    cursor: not-allowed;
  }

  .onboarding-flow__indicators {
    padding-right: 10px;

    &:has(.custom-chevron-down) {
      padding-right: 7px;
    }

    .onboarding-flow__indicator {
      align-items: center;
      padding: unset;

      .custom-close-icon {
        width: 24px;
        height: 24px;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #ffd8dd;
          svg path {
            fill: #ea314a;
          }
        }

        &.disabled {
          &:hover {
            cursor: not-allowed;
            background-color: transparent;
            svg path {
              fill: #a3a3b5;
            }
          }
        }
      }
    }
  }

  .onboarding__dropdown-indicator :hover {
    cursor: pointer;
  }

  .__react_component_tooltip.app-tooltip.onboarding-flow-select {
    width: 228px;
    padding: 15px;
    border-radius: 5px;
    background-color: #2d2e2d;

    div {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #fff;
    }

    &.place-bottom {
      margin-top: 13px;
    }

    &.type-dark.place-top:after {
      border-bottom-color: #2d2e2d !important;
    }
    &.app-tooltip.place-top:after {
      bottom: -7px;
    }
  }
`;

export const customStyles = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#ffffff',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderRadius: '4px',
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    minHeight: '46px !important',
    maxHeight: '46px !important',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FFFFFF',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
  }),
  dropdownIndicator: (baseStyle, state) => ({
    ...baseStyle,
    cursor: 'pointer',
  }),
  input: (baseStyle, state) => ({
    ...baseStyle,
    input: {
      height: '22px !important',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#202353',
    },
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: '#202353',
    userSelect: 'none',
    marginLeft: 'unset',
    marginRight: 'unset',
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingLeft: '13px',
    gap: '8px',
    cursor: 'text',
    '> div:last-child': {
      margin: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      display: state.isDisabled ? 'none' : 'inline-block',
    },
    overflow: 'unset',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    overflow: 'hidden',
    paddingRight: '4px',
    background: '#fff',
    borderRadius: '3px',
    boxShadow: '0px 4px 16px 0px rgba(38, 38, 38, 0.2)',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    '.custom-menu-list': {
      maxHeight: '260px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      overflow: 'auto',
      paddingRight: '4px',
      '::-webkit-scrollbar': {
        width: '4px !important',
      },
      '::-webkit-scrollbar-track': {
        marginTop: '48px',
      },
    },
    '.menu-title': {
      width: '100%',
      padding: '20px 16px 10px',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '18px',
      color: '#000',
      textAlign: 'left',
    },
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 'none',
    '.custom-option-wrapper': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      padding: '19px 16px 21px',
      cursor: 'pointer',
      boxShadow: '0px -1px 0px 0px #eeeff2 inset',
      backgroundColor: state.isSelected ? '#EEECFF' : '#ffffff',
      ':hover': {
        backgroundColor: '#EEECFF',
        '.name': {
          color: '#5158cf',
        },
      },
    },
    '.wrapper-title': {
      width: 'calc(100% - 36px)',
      display: 'flex',
      alignItems: 'center',
      gap: '7px',
    },
    '.name': {
      color: state.isSelected ? '#5158cf' : '#202353',
      position: 'relative',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    '.name-tag': {
      flexShrink: 0,
      display: 'inline-block',
      padding: '2px 8px',
      borderRadius: '4px',
      fontSize: '11px',
      lineHeight: '15px',
      fontWeight: 400,
      color: '#4846da',
      backgroundColor: '#E0DFFF',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    width: '100%',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  loadingMessage: (baseStyle, state) => ({
    ...baseStyle,
    width: '100%',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  singleValue: (baseStyle, state) => ({
    ...baseStyle,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '15px',
    maxWidth: 'calc(100% - 26px)',
    '.wrapper-title': {
      width: 'calc(100% - 36px)',
      display: 'flex',
      alignItems: 'center',
      gap: '7px',
    },
    '.name': {
      color: '#202353',
      position: 'relative',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    '.name-tag': {
      flexShrink: 0,
      display: 'inline-block',
      padding: '2px 8px',
      borderRadius: '4px',
      fontSize: '11px',
      lineHeight: '15px',
      fontWeight: 400,
      color: '#4846da',
      backgroundColor: '#E0DFFF',
    },
    '.__react_component_tooltip.place-top.type-dark.app-tooltip.set-as-default-tooltip': {
      whiteSpace: 'nowrap',
    },
    overflow: 'unset',
  }),
};
