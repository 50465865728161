import React from 'react';

import { Button } from 'shared/FormControl';

import { useMyContext } from '../context/ProgramLibraryContext';

import { CDN_URL } from 'constants/commonData';

const AddNewProgramButton = props => {
  const { label = 'Add New Program' } = props;
  const { handleAddNewProgram } = useMyContext();

  return (
    <Button purple className="button--add-new-library-instance" onClick={handleAddNewProgram}>
      <img src={`${CDN_URL}/images/plus_white.svg`} alt="" />
      <span>{label}</span>
    </Button>
  );
};

export default AddNewProgramButton;
