import React, { useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

import { CDN_URL, MAX_TAG_NAME_LENGTH } from 'constants/commonData';

import { DropdownIndicator } from 'shared/Icons';
import { ReactComponent as RemoveIcon } from 'assets/icons/tag-value-remove.svg';

import * as S from './styles';

export const MODE = {
  DEFAULT: 'default',
  SEARCH: 'search',
};

const MuscleGroup = props => {
  const {
    selectedOptions: originalSelectedOptions = [],
    onChange,
    options = [],
    showError = false,
    isHiddenMuscleGroupOption = false,
  } = props;

  const selectRef = useRef(null);

  const [selectedOptions, setSelectedOptions] = useState(originalSelectedOptions);
  const [currentMode, setCurrentMode] = useState(MODE.DEFAULT);
  const [inputValue, setInputValue] = useState('');

  const formatOptions = useMemo(() => {
    if (isHiddenMuscleGroupOption) {
      return options.filter(it => !it.is_hidden);
    }

    return options;
  }, [options, isHiddenMuscleGroupOption]);

  const handleChange = selectedOptions => {
    if (selectedOptions.length <= 3) {
      setSelectedOptions(selectedOptions);
      typeof onChange === 'function' && onChange(selectedOptions);
    }
  };

  const handleInputChange = inputValue => {
    if (!inputValue && inputValue !== '') {
      return;
    }
    const newInputValue =
      inputValue.length <= MAX_TAG_NAME_LENGTH ? inputValue : inputValue.substr(0, MAX_TAG_NAME_LENGTH);
    setInputValue(newInputValue);

    setCurrentMode(inputValue ? MODE.SEARCH : MODE.DEFAULT);
  };

  const filterOption = (option = {}, inputValue = '') => {
    const { label = '', value } = option;
    if (!!inputValue) {
      const isSelected = selectedOptions.find(it => value === it._id);
      if (isSelected) return false;
      return label.toLowerCase().includes(inputValue.toLowerCase());
    }
    return true;
  };

  return (
    <S.Wrapper showError={showError}>
      <Select
        isMulti={true}
        isSearchable={true}
        closeMenuOnSelect={false}
        isLoading={false}
        hideSelectedOptions={false}
        name="muscle_group"
        ref={selectRef}
        options={formatOptions}
        value={selectedOptions}
        getOptionLabel={option => option.title}
        getOptionValue={option => option._id}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        filterOption={filterOption}
        classNamePrefix="muscle-group-select"
        className="muscle-group"
        placeholder="Select muscle group"
        noOptionsMessage={() => 'No results.'}
        styles={S.selectStyle}
        components={{
          DropdownIndicator,
          IndicatorSeparator: null,
          LoadingIndicator: null,
          Option: currentMode === MODE.DEFAULT ? DefaultModeOption : SearchModeOption,
          Menu: CustomMenu,
          MultiValueRemove,
          ClearIndicator: null,
          ValueContainer: CustomValueContainer,
          MultiValueLabel: CustomMultiValueLabel,
        }}
        // custom props
        currentMode={currentMode}
      />
      {showError && <S.ErrorMessage>Please select muscle group</S.ErrorMessage>}
    </S.Wrapper>
  );
};

export default MuscleGroup;

const CustomMenu = props => {
  const { selectProps } = props;
  const { inputValue, currentMode } = selectProps;
  const showLabel = currentMode === MODE.DEFAULT || inputValue === '';

  return (
    <components.Menu {...props}>
      {showLabel && <S.TagsMenuListTitle mode={currentMode}>Select up to 3 options</S.TagsMenuListTitle>}
      {props.children}
    </components.Menu>
  );
};

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <RemoveIcon />
    </components.MultiValueRemove>
  );
};

const CustomValueContainer = props => {
  const { hasValue, selectProps: { menuIsOpen = false } = {}, getValue } = props;
  const data = getValue();

  return (
    <components.ValueContainer {...props}>
      {hasValue && !menuIsOpen ? (
        <S.WrapperContent>
          <S.LabelModality>
            <S.MuscleLabel>
              Muscle group: <S.LabelContent>{data.map(it => it.title).join(', ')}</S.LabelContent>
            </S.MuscleLabel>
          </S.LabelModality>
          {props.children[1]}
        </S.WrapperContent>
      ) : (
        props.children
      )}
    </components.ValueContainer>
  );
};

const CustomMultiValueLabel = props => {
  return <components.MultiValueLabel {...props}>{props.children}</components.MultiValueLabel>;
};

function moveItemToFront(array, itemId) {
  const index = array.findIndex(item => item._id === itemId);
  if (index !== -1 && index !== 0) {
    array.unshift(array.splice(index, 1)[0]);
  }
  return array.slice();
}

const DefaultModeOption = props => {
  const { innerProps, innerRef, data, isSelected, getValue, hasValue, setValue } = props;

  const selectedValue = getValue();

  const [isDisabledOption, isPrimary] = useMemo(() => {
    let isDisabledOption = false;
    let isPrimary = false;

    if (hasValue) {
      if (!isSelected && selectedValue.length === 3) {
        isDisabledOption = true;
      }

      if (isSelected && selectedValue[0]._id === data._id) {
        isPrimary = true;
      }
    }

    return [isDisabledOption, isPrimary];
  }, [hasValue, selectedValue, isSelected]);

  const handleMarkAsPrimary = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!isPrimary) {
      const newValue = moveItemToFront(selectedValue, data._id);
      setValue(newValue);
    }
  };

  return (
    <S.TagItem key={data._id} isSelected={isSelected} {...innerProps} ref={innerRef} disabled={isDisabledOption}>
      {data.title}
      {isSelected && (
        <div onClick={handleMarkAsPrimary}>
          <img
            src={isPrimary ? `${CDN_URL}/images/star.svg` : `${CDN_URL}/images/unstar.svg`}
            alt=""
            className="start-icon"
            data-tip
            data-for={data._id}
          />
          {!isPrimary && (
            <ReactTooltip
              id={data._id}
              delayShow={0}
              disable={isPrimary}
              effect="solid"
              place="top"
              eventOff="mouseleave scroll mousewheel blur"
              className="app-tooltip"
            >
              Mark as Primary
            </ReactTooltip>
          )}
        </div>
      )}
    </S.TagItem>
  );
};

const SearchModeOption = props => {
  const { data, isSelected, getValue, hasValue } = props;

  const selectedValue = getValue();

  const isDisabledOption = useMemo(() => {
    let isDisabledOption = false;

    if (hasValue && !isSelected && selectedValue.length === 3) {
      isDisabledOption = true;
    }

    return isDisabledOption;
  }, [hasValue, selectedValue, isSelected]);

  return (
    <components.Option {...props} className={classNames({ disabled: isDisabledOption })}>
      {data.title}
    </components.Option>
  );
};
