import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import GeneralButton from 'shared/GeneralButton';
import { validateEmail } from 'utils/validations';
import AsyncSelect from 'react-select/lib/Async';
import './styles.scss';
import TimezoneSelect from 'shared/TimezoneSelect';

export default class GeneralSettings extends React.Component {
  state = {
    timezone: this.props.team.timezone,
    name: this.props.team.name,
    billingEmail: this.props.team.billingEmail,
    teamId: this.props.team._id,
  };

  componentDidMount() {
    this.props.toggleSideBar(true);
  }

  render() {
    return (
      <div className="accountdetails-container">
        <div className="changeemail">
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                <Label className="setting-label">WORKSPACE NAME</Label>
                <input
                  className={'form-text'}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value });
                  }}
                  value={this.state.name}
                  placeholder={'Name'}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Label className="setting-label">TIMEZONE</Label>
                <TimezoneSelect
                  onChange={(key) => {
                    this.setState((p) => ({
                      timezone: key.value,
                    }));
                  }}
                  timezone={this.state.timezone}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Label className="setting-label">BILLING EMAIL</Label>
                <AsyncSelect
                  className="setting newexercise-dropdown"
                  classNamePrefix="exl"
                  value={{
                    key: this.state.billingEmail,
                    label: this.state.billingEmail,
                    value: this.state.billingEmail,
                  }}
                  defaultValue={this.getBillingEmailDefault()}
                  onChange={(key) => {
                    this.setState((p) => ({
                      billingEmail: key.value,
                    }));
                  }}
                  blurInputOnSelect={true}
                  cacheOptions
                  loadOptions={(inputValue, callback) => {
                    this.searchBillingEmail(inputValue, callback);
                  }}
                  defaultOptions
                  styles={{
                    option: (provided, state) => {
                      return {
                        ...provided,
                        color: state.isSelected ? '#6B6B6B' : '#A0A0A0',
                        backgroundColor: state.isSelected
                          ? '#F7F7F7'
                          : state.isFocused
                          ? '#F2F2F2'
                          : '#FFFFFF',
                        fontWeight: state.isSelected ? 700 : 400,
                      };
                    },
                  }}
                />
              </Form.Field>
            </Form.Group>
            {this.state.error && (
              <Form.Group>
                <Form.Field>
                  <Label className="error-lable">{this.state.error}</Label>
                </Form.Field>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Field>
                <GeneralButton className="setting-save-button" onClick={this.handleSaveTeam.bind(this)}>
                  Save
                </GeneralButton>
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }

  getBillingEmailDefault() {
    let defaultInfo = this.props.user.team_member.find((item) => {
      return item.email === this.state.billingEmail;
    });
    if (!defaultInfo) {
      defaultInfo = this.props.user;
    }
    return {
      key: defaultInfo.email,
      label: `${defaultInfo.first_name} ${defaultInfo.last_name} (${defaultInfo.email})`,
      value: defaultInfo.email,
    };
  }

  searchBillingEmail(q, callback) {
    callback(
      this.props.user.team_member
        .map((itm) => {
          return {
            teams: itm.teams,
            key: itm.email,
            label: `${itm.first_name} ${itm.last_name} (${itm.email})`,
            value: itm.email,
          };
        })
        .filter(
          (itm) =>
            itm.value.toLowerCase().includes(q ? q.toLowerCase() : '') &&
            (itm.teams.length === 0 || itm.teams[0].role !== 1)
        )
    );
  }

  handleSaveTeam() {
    if (!this.state.timezone) {
      this.setState({ error: 'Please enter timezone' });
      return;
    }
    if (!this.state.name) {
      this.setState({ error: "Please enter team's name" });
      return;
    }
    if (!this.state.billingEmail) {
      this.setState({ error: 'Please enter billing account' });
      return;
    }
    if (!validateEmail(this.state.billingEmail)) {
      this.setState({ error: 'Please enter a valid billing account' });
      return;
    }
    this.setState({ error: null });
    this.props.editTeam({
      timezone: this.state.timezone,
      name: this.state.name,
      billingEmail: this.state.billingEmail,
      teamId: this.state.teamId,
    });
  }
}
