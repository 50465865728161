import get from 'lodash/get';

const USER_ROLES = {
  OWNER: 0,
  TRAINER: 1,
  ADMIN: 2,
};

const fullPermissionRoles = [USER_ROLES.OWNER, USER_ROLES.ADMIN];

const useSurveyPermission = (authorId, user, permission) => {
  const userId = get(user, '_id', '');
  const currentTeam = get(user, 'current_team', '');
  const findTeam = get(user, 'teams', []).find(item => get(item, 'team._id', '') === currentTeam);
  const teamRole = get(findTeam, 'role', '');

  const isAuthor = userId === authorId;
  const isFullPermission = !!findTeam && fullPermissionRoles.includes(teamRole);

  const hasMP = get(permission, 'marketplace_payment', false);

  const hasPermission = hasMP && (isFullPermission || isAuthor);

  return { hasPermission };
};

export default useSurveyPermission;
