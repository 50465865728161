import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import diff from 'deep-diff';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import find from 'lodash/find';

import useStateCallback from 'hooks/useStateCallback';
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import { Toggle } from 'shared/FormControl';
import DateRangeNextWeek from './DateRangeNextWeek';
import { WORKOUT_VISIBILITY } from 'constants/commonData';
import { SIDEBAR_ID } from 'components/ClientSettings/constants';
import { Checkbox } from 'shared/FormControl';
import { ReactComponent as EyePurple } from 'assets/icons/eye_purple.svg';
import ExerciseListModal from './ExerciseListModal';
import SyscAllExercisesModal from './SyscAllExercisesModal';

import { SaveButton, Left, Right, Center, SettingName } from '../style';

import * as S from './style';

const DATE_RANGE = [
  { key: WORKOUT_VISIBILITY.LAST_WEEK, label: 'Previous Week' },
  { key: WORKOUT_VISIBILITY.CURRENT_WEEK, label: 'Current Week' },
  { key: WORKOUT_VISIBILITY.NEXT_WEEK, label: 'Next Week' },
];

const checkStateSyncConditions = ({ originState, currentState, types }) => {
  const isAnyFalseInOrigin = types.some(type => {
    const item = originState.find(obj => obj.type === type);
    return item && item.state === false;
  });

  const areAllTrueInCurrent = types.every(type => {
    const item = currentState.find(obj => obj.type === type);
    return item && item.state === true;
  });

  return isAnyFalseInOrigin && areAllTrueInCurrent;
};

function WorkoutVisibility(props) {
  const { originSettings, onClientWorkoutChange, isAllChangeSetting = [] } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [settings, setSettings] = useState(props.originSettings.slice());
  const [settingsWeek, setSettingsWeek] = useState(props.originSettings.slice());
  const [isChanged, setIsChanged] = useStateCallback(false);
  const shouldAllChangeSetting = isAllChangeSetting.includes(true);
  const isShowSaveButton = useMemo(() => {
    return !!settings.length && diff(originSettings, settings);
  }, [settings]);

  const stateSwap = useMemo(() => {
    return find(settings, item => item.type === WORKOUT_VISIBILITY.SWAP_OUT_EXERCISE) || {};
  }, [settings]);

  const stateSwapLibraryGroup = useMemo(() => {
    return find(settings, item => item.type === WORKOUT_VISIBILITY.LIBRARY_GROUP) || {};
  }, [settings]);

  const stateSwapDefault = useMemo(() => {
    return find(settings, item => item.type === WORKOUT_VISIBILITY.DEFAULT_EXERCISES) || {};
  }, [settings]);

  const stateSwapTracked = useMemo(() => {
    return find(settings, item => item.type === WORKOUT_VISIBILITY.TRACKED_EXERCISES) || {};
  }, [settings]);

  const stateSwapAllExercises = useMemo(() => {
    const currentValue = find(settings, item => item.type === WORKOUT_VISIBILITY.ALL_EXERCISES_LIBRARY) || {};
    return { ...currentValue, state: !stateSwapDefault.state };
  }, [settings, stateSwapDefault]);

  const isUseExerciseLibrary = useMemo(() => {
    const enableLibrary = stateSwapDefault.state || stateSwapAllExercises.state;

    if (stateSwapLibraryGroup) {
      return stateSwapLibraryGroup.state && enableLibrary;
    }

    return enableLibrary;
  }, [stateSwapLibraryGroup, stateSwapDefault, stateSwapAllExercises]);

  useEffect(() => {
    if (!!settings.length && diff(originSettings, settings)) {
      setIsChanged(true);
      onClientWorkoutChange && onClientWorkoutChange(true);
    } else {
      setIsChanged(false);
      onClientWorkoutChange && onClientWorkoutChange(false);
    }
  }, [settings]);

  useEffect(() => {
    if (diff(settings, settingsWeek)) {
      setSettings(settingsWeek);
    }
  }, [settingsWeek]);

  useEffect(() => {
    setSettings(props.originSettings.slice());
  }, [props.originSettings]);

  useEffect(() => {
    isShowSaveButton && setIsChanged(shouldAllChangeSetting);
  }, [shouldAllChangeSetting]);

  const onSave = () => {
    if (isSubmitting) {
      return false;
    }

    const body = { types: _.map(settings, item => ({ type: item.type, state: item.state })) };

    setIsSubmitting(true);
    props.onSave(body).finally(() => setIsSubmitting(false));
  };

  const confirmSave = () => {
    const isNeedConfirmSync = checkStateSyncConditions({
      originState: originSettings,
      currentState: settings,
      types: [
        WORKOUT_VISIBILITY.SWAP_OUT_EXERCISE,
        WORKOUT_VISIBILITY.LIBRARY_GROUP,
        WORKOUT_VISIBILITY.ALL_EXERCISES_LIBRARY,
      ],
    });

    if (isNeedConfirmSync) {
      return props.toggleConfirmModal(true, <SyscAllExercisesModal onConfirm={onSave} />);
    }

    onSave();
  };

  const onRangeClick = item => {
    const copyItem = Object.assign({}, item);
    let affectedItem;
    let changedItems = [];
    const { NEXT_TWO_WEEK, NEXT_THREE_WEEK, NEXT_FOUR_WEEK } = WORKOUT_VISIBILITY;

    if (!item.editable) {
      return false;
    }

    if (copyItem.state) {
      copyItem.state = false;

      if (
        copyItem.type === WORKOUT_VISIBILITY.NEXT_WEEK &&
        _.find(settings, item => [NEXT_TWO_WEEK, NEXT_THREE_WEEK, NEXT_FOUR_WEEK].includes(item.type))
      ) {
        changedItems = [
          ...changedItems,
          { type: NEXT_TWO_WEEK, state: false },
          { type: NEXT_THREE_WEEK, state: false },
          { type: NEXT_FOUR_WEEK, state: false },
        ];
      }
    } else {
      copyItem.state = true;

      if (
        [NEXT_TWO_WEEK, NEXT_THREE_WEEK, NEXT_FOUR_WEEK].includes(copyItem.type) &&
        _.find(settings, item => item.type === WORKOUT_VISIBILITY.NEXT_WEEK)
      ) {
        affectedItem = { type: WORKOUT_VISIBILITY.NEXT_WEEK, state: true };
      }
    }

    changedItems.push(copyItem);

    if (affectedItem) {
      changedItems.push(affectedItem);
    }

    setSettings(
      _.map(settings, obj => {
        const changed = _.find(changedItems, i => i.type === obj.type);

        return { ...obj, state: changed ? changed.state : obj.state };
      }),
    );

    setSettingsWeek(
      _.map(settings, obj => {
        const changed = _.find(changedItems, i => i.type === obj.type);

        return { ...obj, state: changed ? changed.state : obj.state };
      }),
    );
  };

  const state = _.find(settings, item => item.type === WORKOUT_VISIBILITY.STATE);

  if (!settings || !settings.length) {
    return null;
  }

  const handleDiscardChange = nextLocation => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          setIsChanged(false, () => props.push(nextLocation.pathname));
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
        hasHoverState
      />,
    );
    return false;
  };

  const handleCloseModal = () => typeof props.toggleModal === 'function' && props.toggleModal(false);

  const handleOpenExerciseList = e => {
    e.preventDefault();
    typeof props.toggleModal === 'function' &&
      props.toggleModal(
        true,
        <ExerciseListModal
          closeModal={handleCloseModal}
          toggleSecondModal={props.toggleSecondModal}
          toggleConfirmModal={props.toggleConfirmModal}
        />,
      );
  };

  const handleOverridePosition = ({ top }, _, currentTarget, node) => {
    const rect = currentTarget.getBoundingClientRect();
    const newLeft = 10 + rect.left - node.clientWidth / 2;
    return { top, left: newLeft };
  };

  const toggleSwapOutExercise = () => {
    const newEdit = settings.map(it => {
      if (it._id === stateSwap._id) {
        return { ...it, state: !it.state };
      }
      return it;
    });
    setSettings(newEdit);
  };

  const toggleSwapDefault = () => {
    const newEdit = settings.map(it => {
      if (it._id === stateSwapLibraryGroup._id) {
        return { ...it, state: !isUseExerciseLibrary };
      }

      // force use default value
      if (isUseExerciseLibrary === false && stateSwapDefault.state === false && stateSwapAllExercises.state === false) {
        if (it._id === stateSwapDefault._id) {
          return { ...it, state: true };
        }
        if (it._id === stateSwapAllExercises._id) {
          return { ...it, state: false };
        }
      }
      return it;
    });
    setSettings(newEdit);
  };

  const toggleSwapTracked = () => {
    const newEdit = settings.map(it => {
      if (it._id === stateSwapTracked._id) {
        return { ...it, state: !it.state };
      }
      return it;
    });
    setSettings(newEdit);
  };

  const toggleSwapDefaultAndAllExercise = () => {
    const newEdit = settings.map(it => {
      if (it._id === stateSwapDefault._id) {
        return { ...it, state: !stateSwapDefault.state };
      }
      if (it._id === stateSwapAllExercises._id) {
        return { ...it, state: stateSwapDefault.state };
      }
      return it;
    });
    setSettings(newEdit);
  };

  return (
    <S.Wrapper className="settings__wk-visibility" id={SIDEBAR_ID.workout}>
      <Prompt when={isChanged} message={handleDiscardChange} />
      <Left className="left">
        <SettingName>
          <span>Client workout settings</span>
        </SettingName>
      </Left>
      <Center className="center">
        <S.Content>
          <S.Module>
            <div className="info">
              <S.Title>Date range visible to client</S.Title>
              <S.Description marginBottom>
                Choose range you would like your clients to have access to on their training calendar
              </S.Description>
              <S.Ranges>
                {_.map(DATE_RANGE, item => {
                  const obj = _.find(settings, o => o.type === item.key);

                  if (!obj) {
                    return null;
                  }

                  return (
                    <div
                      onClick={() => onRangeClick(obj)}
                      className={`item${obj.state ? ' active' : ''}${!obj.editable ? ' disabled' : ''}`}
                      key={item.key}
                    >
                      <span>{item.label}</span>
                    </div>
                  );
                })}

                <DateRangeNextWeek settings={settings} onRangeClick={onRangeClick} onSetSettings={setSettingsWeek} />
              </S.Ranges>
            </div>
          </S.Module>
          <S.Module>
            <div className="info">
              <S.Title>Allow clients to rearrange workouts</S.Title>
              <S.Description>
                Grant your clients flexibility to rearrange workouts within their visible range
              </S.Description>
            </div>
            <Toggle
              checked={state.state}
              onChange={() => {
                const newEdit = settings.map(mItm => {
                  return mItm._id !== state._id ? mItm : { ...mItm, state: !mItm.state };
                });
                setSettings(newEdit);
              }}
            />
          </S.Module>
          {process.env.REACT_APP_SWAP_OUT_EXERCISE && (
            <S.Module disable={!stateSwap.state}>
              <div className="info">
                <S.Title>Allow client to replace exercise</S.Title>
                <S.Description>
                  Client can replace exercises assigned in workouts with exercises from a specified list
                </S.Description>
                {stateSwap.state && (
                  <div className="list-options">
                    <label
                      className={classNames('checkbox-container', {
                        disable: !stateSwapTracked.state,
                      })}
                    >
                      <div className="checkbox-wrapper" data-tip data-for="warning-tooltip-default">
                        <Checkbox
                          checked={isUseExerciseLibrary}
                          size={20}
                          onChange={toggleSwapDefault}
                          disabled={!stateSwapTracked.state}
                        />
                        <div className="checkbox-label">Exercises from library</div>
                      </div>
                    </label>
                    <ReactTooltip
                      id="warning-tooltip-default"
                      className=""
                      effect="solid"
                      place="top"
                      disable={stateSwapTracked.state}
                      delayShow={0}
                      eventOff="mouseleave scroll mousewheel blur"
                      overridePosition={handleOverridePosition}
                    >
                      <div className="tooltip">Choose at least 1 option</div>
                    </ReactTooltip>

                    {isUseExerciseLibrary && (
                      <>
                        <label className="checkbox-container child-radio-container">
                          <div className="checkbox-wrapper">
                            <S.CustomRadioSwap
                              checked={stateSwapDefault.state}
                              name="exercise-library"
                              className="radio-exercise"
                              value="your-exercises"
                              onClick={toggleSwapDefaultAndAllExercise}
                            />
                            <div className="checkbox-label">Your general exercise list</div>
                          </div>
                          <div className="btn-view-list" onClick={handleOpenExerciseList}>
                            <EyePurple />
                            View List
                          </div>
                        </label>

                        <label className="checkbox-container child-radio-container">
                          <div className="checkbox-wrapper">
                            <S.CustomRadioSwap
                              checked={stateSwapAllExercises.state}
                              name="exercise-library"
                              className="radio-exercise"
                              value="exercise-library"
                              onClick={toggleSwapDefaultAndAllExercise}
                            />
                            <div className="checkbox-label">All exercises from your library</div>
                          </div>
                        </label>
                      </>
                    )}

                    <label
                      className={classNames('checkbox-container', {
                        disable: !isUseExerciseLibrary,
                      })}
                    >
                      <div className="checkbox-wrapper" data-tip data-for="warning-tooltip-past">
                        <Checkbox
                          checked={stateSwapTracked.state}
                          size={20}
                          onChange={toggleSwapTracked}
                          disabled={!isUseExerciseLibrary}
                        />
                        <div className="checkbox-label">Exercises already tracked by client</div>
                      </div>
                    </label>
                    <ReactTooltip
                      id="warning-tooltip-past"
                      className=""
                      effect="solid"
                      place="top"
                      disable={isUseExerciseLibrary}
                      delayShow={0}
                      eventOff="mouseleave scroll mousewheel blur"
                      overridePosition={handleOverridePosition}
                    >
                      <div className="tooltip">Choose at least 1 option</div>
                    </ReactTooltip>
                  </div>
                )}
              </div>
              <Toggle checked={stateSwap.state} onChange={toggleSwapOutExercise} />
            </S.Module>
          )}
        </S.Content>
      </Center>
      <Right className="right">{isShowSaveButton && <SaveButton onClick={confirmSave}>Save</SaveButton>}</Right>
    </S.Wrapper>
  );
}

WorkoutVisibility.propTypes = {
  originSettings: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  push,
  toggleConfirmModal,
  toggleModal,
  toggleSecondModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutVisibility);
