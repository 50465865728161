import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import StatisticInfo from './components/StatisticInfo';
import OverviewHeading from './components/OverviewHeading';
import OverviewBaseInfo from './components/OverviewBaseInfo';
import GuideStudioWork from './components/GuideStudioWork';
import AddingClient from './components/AddingClient';
import { bindActionCreators } from 'redux';
import { getStudioProgramDetail } from 'redux/studio-program/actions';

class StudioProgramOverview extends React.PureComponent {
  componentDidMount() {
    const { match } = this.props;
    const studioProgramId = match.params.studioId;
    this.props.getStudioProgramDetail(studioProgramId);
  }
  render() {
    return (
      <S.Wrapper>
        <div className="studioOverview__container">
          <OverviewHeading />
          <OverviewBaseInfo />
          <StatisticInfo />
          <GuideStudioWork />
          <AddingClient />
        </div>
      </S.Wrapper>
    );
  }
}

const mapDispatch = dispatch => ({
  getStudioProgramDetail: bindActionCreators(getStudioProgramDetail, dispatch),
});

export default connect(null, mapDispatch)(StudioProgramOverview);
