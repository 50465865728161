import React from 'react';
import { Modal } from 'semantic-ui-react';

import { ReactComponent as TrashIcon } from 'assets/icons/trash_light.svg';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { ModalWrapper, ModalTitle, ModalDesc, ModalButton } from './style';
import { CONFIRM_TYPES } from './constants';
import { CDN_URL } from 'constants/commonData';

const RepeatTaskConfirm = ({ title, message, onClose, onConfirm, isRemove, isTask, type }) => {
  const handleConfirmOnly = () => {
    onConfirm(!!isTask);
  };

  const handleConfirmAll = () => {
    onConfirm(false);
  };

  const getButtonActionTitle = () => {
    switch (type) {
      case CONFIRM_TYPES.TASK:
        return 'Only This Task';
      case CONFIRM_TYPES.MOVE:
        return 'Move This Task';
      case CONFIRM_TYPES.IN_PAST:
        return 'Add from Today Onwards';
      case CONFIRM_TYPES.REMOVE_TASK_INTERVAL:
        return 'Remove All Upcoming Tasks';
      case CONFIRM_TYPES.UPDATE_TASK_REPEAT_INTERVAL:
      case CONFIRM_TYPES.CHANGE_SET_DATE_INTERVAL:
        return 'Update All Upcoming Tasks';
      default:
        return 'Update All Future Tasks';
    }
  };
  return (
    <ModalWrapper
      className="confirm-remove-task"
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalTitle>
          {isRemove && <TrashIcon />}
          {title}
        </ModalTitle>
        <ModalLayout.Content className="confirm-content">
          <ModalDesc dangerouslySetInnerHTML={{ __html: message }} />
        </ModalLayout.Content>
        <ModalLayout.Actions className="confirm-footer">
          <ModalButton
            className={(type === CONFIRM_TYPES.MOVE || type === CONFIRM_TYPES.TASK) && 'rest-btn'}
            onClick={onClose}
          >
            Cancel
          </ModalButton>
          {isTask && (
            <ModalButton borderPurple grey className="all-btn" onClick={handleConfirmAll}>
              This &amp; Upcoming Tasks
            </ModalButton>
          )}
          <ModalButton
            purple
            onClick={handleConfirmOnly}
            {...(isRemove ? { delete: true } : { delete: false })}
            className={!isRemove && 'update-btn'}
          >
            {getButtonActionTitle()}
          </ModalButton>
        </ModalLayout.Actions>
      </Modal.Content>
    </ModalWrapper>
  );
};

export default RepeatTaskConfirm;
