import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

const textBase = `
    font-family: 'Open Sans';
    color: #202353;
    font-style: normal;
    line-height: 150%;
`;

export const Wrapper = styled.div`
  flex-shrink: 0;
  height: 100%;
  width: 400px;
  background-color: #fff;
  padding-top: 25px;

  ${({ sideBarVisible }) =>
    !sideBarVisible
      ? css`
          @media screen and (max-width: 1365px) {
            width: 360px;
          }
          @media screen and (min-width: 1366px) and (max-width: 1749px) {
            width: 400px;
          }
          @media screen and (min-width: 1750px) {
            width: 576px;
          }
        `
      : css`
          @media screen and (max-width: 1365px) {
            width: 300px;
          }
          @media screen and (min-width: 1366px) and (max-width: 1749px) {
            width: 325px;
          }
          @media screen and (min-width: 1750px) {
            width: 576px;
          }
        `}
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 30px;
  margin-bottom: 13px;
`;

export const FilterSearch = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  gap: 5px;
  .search-input.search-input-recipes {
    height: 100%;
    width: 100%;
    input {
      border: unset;
      background: url(${props => props.background}) no-repeat;
      background-size: 20px;
      background-position: 15px center;
      background-color: unset;
      ${textBase}
      color: rgb(32, 35, 83, 0.8);
      font-weight: 400;
      padding-left: 45px;
      ::placeholder {
        color: rgb(163, 163, 181, 0.8);
      }
    }
    svg {
      right: 3px;
      path {
        fill: rgb(163, 163, 181);
      }
    }
  }
  .clients-filter {
    margin-right: 10px;
  }
`;

export const FilterButton = styled(Button)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  min-width: unset;
  background-color: transparent;
  padding: unset;
  svg {
    flex-shrink: 0;
  }
  :hover {
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }

  ${props =>
    props.active &&
    css`
      background-color: #f0f1ff;
      svg path {
        fill: #5158cf;
      }
    `}

  * {
    margin-right: 0;
  }

  .__react_component_tooltip.filter-action-tooltip {
    ${textBase}
    border-radius: 5px;
    background: #333;
    padding: 8px 10px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    &.type-dark.place-top:after {
      border-top-color: #333;
    }
    &.place-top {
      margin-top: -8px;
    }
  }
`;

export const FilterNumber = styled.div`
  width: 16px;
  height: 14px;

  position: absolute;
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  right: -9px;
  top: -9px;

  border-radius: 3px;
  box-shadow: 0 0 0 3px #fff;
  background: #ffbe49;

  color: #fff;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

export const FilterCategoryWrapper = styled.div`
  position: relative;
`;

export const FilterCategories = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 25px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const RecipeCategory = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  :hover {
    color: #202353;
    border-bottom: 2px solid #5158cf;
    .recipe-category-label {
      color: ${props => (props.active ? '#5158cf' : '#202353')};
    }
  }
  .recipe-category-label {
    ${textBase}
    color: #7b7e91;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
  }
  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid #5158cf;
      .category-icon path {
        fill: #5158cf;
      }
      .recipe-category-label {
        color: #5158cf;
      }
      &:hover {
        .recipe-category-label {
          color: #5158cf;
        }
      }
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
  margin-top: 3px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 62px;
  pointer-events: none;
  &.overlay-prev {
    left: -2px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) -16.72%, #ffffff 53.16%, #ffffff 100%);
  }
  &.overlay-next {
    right: -2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) -16.72%, #ffffff 53.16%, #ffffff 100%);
  }
`;

export const ButtonArrow = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg path {
    fill: #a3a3b5;
  }
  &:hover {
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }
  &.button-prev {
    left: 0;
    svg {
      transform: rotate(-90deg);
    }
  }
  &.button-next {
    right: 0;
    svg {
      transform: rotate(90deg);
    }
  }
`;
