export const DEFAULT_COLOR = '#F1F1F1';
export const DEFAULT_EMOJI = {
  icon: '😆',
  color: DEFAULT_COLOR,
};

export const BACKGROUND_DEFAULT = {
  border: '#E6E6E6',
  background: '#F1F1F1',
  secondBackground: '#E0E0E0',
};

export const BACKGROUND_LIST = [
  {
    border: '#FCBCBC',
    background: '#FCDEDE',
    secondBackground: '#F9C8C8',
  },
  {
    border: '#FFD8B0',
    background: '#FCEDDE',
    secondBackground: '#FFD9B299',
  },
  {
    border: '#FAE8AF',
    background: '#FCF5DE',
    secondBackground: '#FAEDC3',
  },
  {
    border: '#E9DCCF',
    background: '#F0E9E2',
    secondBackground: '#E7D7C7',
  },
  {
    border: '#C7C9F3',
    background: '#EAEBFF',
    secondBackground: '#DDDFFE',
  },
  {
    border: '#CFEFD6',
    background: '#E6FCEB',
    secondBackground: '#C6EACE',
  },
  {
    border: '#C5E5F8',
    background: '#E9F7FF',
    secondBackground: '#D4EDFB',
  },
  BACKGROUND_DEFAULT,
];

export const REMINDER_SETTING = [
  {
    key: 'remind_at_finish_time',
    title: 'When the countdown finishes',
    status: true,
  },
  {
    key: 'remind_one_day_before',
    title: '1 day before',
    status: false,
  },
  { key: 'remind_one_week_before', title: '1 week before', status: false },
];

export const CountdownViewModes = {
  ViewList: 'ViewList',
  ViewDetails: 'ViewDetails',
  ViewCreateNew: 'ViewCreateNew',
};

export const COUNTDOWN_STATUS = {
  ON_GOING: 1,
  FINISHED: 2,
};

export const DEFAULT_COUNTDOWN_TIME = { label: '09:00 AM', value: '09:00:00' };

export const GoalTypes = {
  general: 'general',
};

export const GoalTypeLabels = {
  [GoalTypes.general]: 'General goal',
};

export const convertEmojiToUnified = emoji => {
  const codePoints = Array.from(emoji).map(char => char.codePointAt(0));

  const unifiedCode = codePoints.map(cp => cp.toString(16)).join('-');

  return unifiedCode;
};
