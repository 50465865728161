import React from 'react';
import _ from 'lodash';
import { Image } from 'semantic-ui-react';
import { CDN_URL, SECTION_FORMAT, SECTION_FORMAT_KEY } from 'constants/commonData';
import ReactTooltip from 'react-tooltip';
import { pluralize } from 'utils/commonFunction';
import './style.scss';

class SectionOverview extends React.Component {
  constructor(props) {
    super(props);
  }

  renderSectionUnit = () => {
    const { section } = this.props;
    let text = '';

    switch (section.format) {
      case 'amrap':
        const time = parseInt(section.time || 0);
        const seconds = (time % 60) + '';
        const minutes = parseInt(time / 60) + '';
        text = `${minutes.length === 1 ? '0' + minutes : minutes}:${
          seconds.length < 2 ? '0' + seconds : seconds.slice(0, 2)
        }`;
        return (
          <div className="unit-container" data-tip data-for={`section-time-${section._id}`}>
            <Image src={`${CDN_URL}/images/clock_gray.svg`} height={12} />
            <span>{text}</span>
            <ReactTooltip
              className="app-tooltip"
              id={`section-time-${section._id}`}
              effect="solid"
              place={'top'}
              delayShow={100}
            >
              <span>{text}</span>
            </ReactTooltip>
          </div>
        );
      case 'timed':
        text = `${parseInt(section.round || 0)} rounds`;
        return (
          <div className="unit-container" data-tip data-for={`section-round-${section._id}`}>
            <Image src={`${CDN_URL}/images/refresh_gray.svg`} height={12} />
            <span>{parseInt(section.round || 0)}</span>
            <ReactTooltip
              className="app-tooltip"
              id={`section-round-${section._id}`}
              effect="solid"
              place={'top'}
              delayShow={100}
            >
              <span>{text}</span>
            </ReactTooltip>
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { section, onClick } = this.props;

    const totalExercises =
      section.format === SECTION_FORMAT_KEY.FREESTYLE
        ? section.exercise_references.length
        : _.sumBy(section.exercises, ex => {
            return ex.supersets ? ex.supersets.length : 0;
          });

    return (
      <div
        className="section-overview"
        onClick={() => {
          if (onClick && typeof onClick === 'function') {
            onClick();
          }
        }}
      >
        <div className="section-overview__header">
          <div className="title">{section.title}</div>
        </div>
        <div className="section-overview__body">
          <div className="section-format">{SECTION_FORMAT[section.format]}</div>
        </div>
        <div className="section-overview__footer">
          {this.renderSectionUnit()}
          {totalExercises > 0 && (
            <div className="unit-container" data-tip data-for={`section-exercise-${section._id}`}>
              <Image src={`${CDN_URL}/images/dumbbell.svg`} />
              <span>{totalExercises}</span>
              <ReactTooltip
                className="app-tooltip"
                id={`section-exercise-${section._id}`}
                effect="solid"
                place={'top'}
                delayShow={100}
              >
                <span>{`${totalExercises} ${pluralize('exercise', totalExercises)}`}</span>
              </ReactTooltip>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SectionOverview;
