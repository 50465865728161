import React, { useState } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

import { map, filter, difference, isEqual, find, union, has, get } from 'lodash';
import MoreLabel from './MoreLabel';

import * as S from './style';

const getSelectedItem = (selectedLabels, items) => {
  let selectedItem = null;
  for (let x = 0; x < selectedLabels.length; x++) {
    for (let y = 0; y < items.length; y++) {
      if (selectedLabels[x].item.value === items[y]._id) {
        selectedItem = selectedLabels[x];
        break;
      }
    }
  }
  return selectedItem;
};

const Label = ({ label, selectedLabels, onChangeLabel, onUnselectLabel, index, disabled = false }) => {
  const { items } = label;
  const selectedItem = getSelectedItem(selectedLabels, items);
  const options = items.map(item => ({
    label: item.name,
    value: item._id,
  }));
  const noneOption = {
    label: '-- Choose Option --',
    value: '',
  };
  const selectOptions = [noneOption, ...options];
  const [value, setValue] = useState(
    selectedItem && selectedItem.label._id === label._id
      ? { label: selectedItem.item.label, value: selectedItem.item.value }
      : noneOption,
  );

  const handleChange = option => {
    if (!isEqual(option, noneOption)) {
      if (isEqual(value, noneOption)) {
        setValue(option);
        onChangeLabel && onChangeLabel({ item: option, label: label });
      } else {
        setValue(option);
        onChangeLabel && onChangeLabel({ item: option, label: label }, true);
      }
    } else {
      setValue(option);
      onUnselectLabel && onUnselectLabel({ value: option, label: label });
    }
  };
  return (
    <div style={{ flex: 1 }}>
      <Select
        isSearchable={false}
        key={label._id}
        options={selectOptions}
        value={value}
        onChange={handleChange}
        blurInputOnSelect
        classNamePrefix="workout-select"
        menuPlacement="auto"
        menuPortalTarget={document.body}
        styles={{
          singleValue: (provided, state) => {
            const opacity = state.getValue()[0].value === '' ? 0.4 : 1;
            return { ...provided, opacity };
          },
          menuPortal: (baseStyle, state) => ({
            ...baseStyle,
            zIndex: 2001,
          }),
          control: (baseStyle, state) => ({
            ...baseStyle,
            cursor: disabled ? 'not-allow' : 'pointer',
          }),
        }}
        isDisabled={disabled}
      />
    </div>
  );
};

function Labels({
  categoryLabels,
  workout,
  level,
  duration,
  onChangeLevel,
  onChangeDuration,
  onChangeLabel,
  onUnselectLabel,
  selectedLabels,
  handleRemoveSavedLabel,
  userId,
  disabled = false,
  ...props
}) {
  const defaultLabels = filter(categoryLabels, item => ['level', 'duration'].includes(item.unique_code)) || [];
  const moreLabels = filter(categoryLabels, item => !['level', 'duration'].includes(item.unique_code)) || [];
  const labels = difference(categoryLabels, defaultLabels);
  const parsedSelectedLabels = map(selectedLabels, label => label.label._id);
  const getSavedLabels = () => {
    const currentLocalStorageSavedLabels = JSON.parse(localStorage.getItem('saved_labels')) || {};
    if (has(currentLocalStorageSavedLabels, userId)) {
      return currentLocalStorageSavedLabels[userId];
    } else {
      return [];
    }
  };
  const [savedLabels, setSavedLabels] = useState(union(getSavedLabels(), parsedSelectedLabels));
  const renderDefaultLabels = (items, unique_code = '') => {
    const handleSetWidth = val => {
      return unique_code.includes('duration') && val;
    };

    return (
      <S.LabelItemArea>
        {map(items, (item, key) => {
          const isLevel = unique_code === 'level';
          const isDuration = unique_code === 'duration';
          const isActive = isLevel ? item._id === level : item._id === duration;
          const handleChangeLabel = () => {
            if (disabled) return;
            if (isLevel) {
              onChangeLevel(item._id);
            }
            if (isDuration) {
              onChangeDuration(item._id);
            }
          };
          return (
            <S.LabelItem
              key={key}
              className={isActive && 'active'}
              widthItem={handleSetWidth(98)}
              onClick={handleChangeLabel}
              disabled={disabled}
            >
              {item.name === 'All' ? 'All levels' : item.name}
            </S.LabelItem>
          );
        })}
      </S.LabelItemArea>
    );
  };

  const handleChangeSavedLabels = newLabels => {
    setSavedLabels(newLabels);
  };

  return (
    <S.DefaultLabel>
      <S.LabelHeader>
        <S.Title>workout labels</S.Title>
        <MoreLabel
          labels={moreLabels}
          onChangeSavedLabels={handleChangeSavedLabels}
          savedLabels={savedLabels}
          handleRemoveSavedLabel={handleRemoveSavedLabel}
          userId={userId}
          disabled={disabled}
        />
      </S.LabelHeader>
      <S.ListArea>
        {map(defaultLabels, label => (
          <S.Row key={label._id}>
            <S.LabelName>{label.name}</S.LabelName>
            {renderDefaultLabels(label.items || [], label.unique_code)}
          </S.Row>
        ))}
        {map(labels, (label, index) => {
          const shouldRender = find(savedLabels, savedLabel => savedLabel === label._id);
          const hasTooltip = get(label, 'name.length', 0) > 13;
          if (!shouldRender || !get(label, 'items', []).length) {
            return null;
          }
          return (
            <S.Row key={label._id}>
              {hasTooltip ? (
                <>
                  <S.LabelName hasTooltip={hasTooltip} data-tip={label.name} data-for="label-name-tooltip">
                    {label.name}
                  </S.LabelName>
                  <ReactTooltip id="label-name-tooltip" effect="solid" place={'top'} delayShow={500} />
                </>
              ) : (
                <S.LabelName>{label.name}</S.LabelName>
              )}
              <Label
                index={index}
                label={label}
                selectedLabels={selectedLabels}
                onChangeLabel={onChangeLabel}
                onUnselectLabel={onUnselectLabel}
                disabled={disabled}
              />
            </S.Row>
          );
        })}
      </S.ListArea>
    </S.DefaultLabel>
  );
}

export default Labels;
