import React, { useEffect } from 'react';
import { get } from 'lodash';
import BaseCreatePopup from 'shared/BaseCreatePopup';
import AddResourceFormPopup from '../AddResourceFormPopup';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import { CDN_URL } from 'constants/commonData';

const Resources = [
  {
    id: 'link',
    component: <AddResourceFormPopup source_type="link" />,
    icon: `${CDN_URL}/images/create_resource_link_icon.svg`,
    iconHover: `${CDN_URL}/images/create_resource_link_icon_hover.svg`,
    title: 'Link',
  },
  {
    id: 'document',
    component: <AddResourceFormPopup source_type="document" />,
    icon: `${CDN_URL}/images/create_resource_document_icon.svg`,
    iconHover: `${CDN_URL}/images/create_resource_document_icon_hover.svg`,
    title: 'Document',
  },
];

const ResourcesExpand = [
  {
    id: 'form',
    component: <AddResourceFormPopup source_type="form" />,
    icon: `${CDN_URL}/images/create_resource_form_icon.svg`,
    iconHover: `${CDN_URL}/images/create_resource_form_icon_hover.svg`,
    title: 'Form',
  },
];

function AddResourcePopup(props) {
  useEffect(() => {
    window.addEventListener('popstate', handleBackButtonToggleModal);
    return () => window.removeEventListener('popstate', handleBackButtonToggleModal, false);
  }, []);
  const handleBackButtonToggleModal = () => {
    if (props.isModalOpen) {
      props.toggleModal(false);
    }
  };

  const DefaultResources = props.isFormPermission ? [...Resources, ...ResourcesExpand] : Resources;
  return (
    <BaseCreatePopup
      title="Add Resource"
      subTitle="Which type of resource do you want to add?"
      resources={DefaultResources}
    />
  );
}

const mapStateToProps = state => {
  return {
    isModalOpen: state.isModalOpen,
    isFormPermission: get(state, 'rootReducer.permission.form_questionnaire', false),
  };
};

const actionCreators = { toggleModal };

export default connect(mapStateToProps, actionCreators)(AddResourcePopup);
