import styled, { css } from 'styled-components';

export const FeatureName = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 2px;
`;

export const FeatureCheckboxText = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px;
  opacity: 1 !important;
  line-height: 20px;
  color: #222222;
  margin-left: 8px;
`;

export const FeatureDescription = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #888888;
`;

export const Feature = styled.div`
  display: ${props => (props.shouldHide === true ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 25px;
  ${props =>
    props.childFeature &&
    css`
      margin-top: 12px;
    `}

  > img.ui {
    width: 42px;
    height: 42px;
  }
  .checkbox-container {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
  }

  .sub-option-least-one {
    .evf-checkbox {
      cursor: not-allowed;
    }
  }

  .checkbox-icon {
    background-size: 10px;
    border-radius: 4px;
  }

  .info {
    flex: 1 1;
    padding: 0 20px;
  }

  ${props =>
    props.disabled &&
    css`
      ${ImageContainer},
      .info {
        span {
          opacity: 0.5;
        }
      }
    `}

  .metrics-setting {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #5c5bbd;
    display: inline-block;
    cursor: pointer;
    margin-top: 15px;

    :hover {
      text-decoration: underline;
    }
  }

  .features__toggle {
    position: relative;

    .features__tooltipUpgradePath {
      visibility: hidden;
      position: absolute;
      z-index: 4;
      opacity: 0;
      transition: opacity 0.35s linear;
    }

    &:hover {
      .features__tooltipUpgradePath {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.75s ease, left 0.25s linear;
      }
    }
  }
  .radio-label {
    ${({ checked }) =>
      !checked &&
      css`
        color: #99a1b1 !important;
      `}
  }

  .__react_component_tooltip.app-tooltip.meal-plan-setting-tooltip {
    font-weight: 400;
    padding: 8px 16px;
    line-height: 18px;
    background: #333333;
  }
  .__react_component_tooltip.place-top.meal-plan-setting-tooltip {
    margin-top: -8px;
  }
  .__react_component_tooltip.type-dark.place-top.meal-plan-setting-tooltip:after {
    border-top-color: #333;
  }
  .__react_component_tooltip.place-top.meal-plan-setting-tooltip::after {
    margin-bottom: -2px !important;
  }

  .features__tooltipUpgradePath.custom-upgrade-path {
    width: 257px;
    padding-bottom: 16px;

    .tooltip_box {
      box-shadow: unset;
      border: unset;
      -webkit-filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));

      ::before {
        height: 16px;
        width: 16px;
        border: unset;
        bottom: -5px;
      }

      .tooltip__heading {
        font-family: 'Open Sans';
        font-size: 15px;
        font-weight: 600;
        line-height: 23px;
      }

      .tooltip__description {
        width: 100%;
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }

      .tooltip__upgradePathBtn {
        height: 30px;
        margin: auto;
        padding: 12px 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 5px;
        background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
        :hover {
          background: linear-gradient(85.16deg, #fdc830 -18.89%, #f37335 182.76%);
        }

        span {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
        }
      }
    }
  }
`;

export const Features = styled.div`
  max-width: 650px;

  .meal-plan-child-setting {
    display: flex;
    flex-direction: column;
    padding-left: 62px;
    margin-top: 0;

    .meal-plan-child-setting__label {
      color: #888;
      font-family: 'Open Sans';
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 8px;
      margin-top: 11px;
    }

    .checkbox-container {
      padding: 0;
      margin-bottom: 6px;

      .radio-label {
        margin-left: 5px;
      }
    }
  }
  .meal-plan-checkbox__label {
    margin-left: 5px !important;
    padding-top: 8px !important;
  }
  .meal-plan-checkbox {
    margin-top: 0 !important;
    margin-bottom: 2px;
  }
  .meal-plan-checkbox.last-element {
    margin-bottom: 8px;
  }
`;

export const ImageContainer = styled.div`
  width: 42px;
  height: 42px;
  background-color: #eaeef1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 55% 1fr;
  padding: 30px 0 40px;
`;

export const FeatureLearnMore = styled.a`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  line-height: 16px;
  color: #6456c4;
  padding-left: 8px;
  padding-bottom: 2px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
  }

  &:hover {
    color: #6456c4;
  }
`;

export const FeatureLinkTitle = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  padding-left: 18px;
`;
