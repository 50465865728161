import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import {
  duplicateSectionLibrary,
  removeSectionLibrary,
  updateSectionSharingStatus,
  updateSelectedSection,
  openSectionWorkoutDetail,
} from 'redux/section-library/actions';
import UpdatedShare from './UpdatedShare';
import { MenuTrigger } from 'shared/Icons';
import OwnerAvatar from 'shared/OwnerAvatar';
import { Checkbox } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import SharingStatus from 'shared/SharingStatus';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { isTeamAdmin, timeSince, pluralize } from 'utils/commonFunction';
import { getFormatName } from 'components/WorkoutDetailModal/components/LeftPanel/SectionsTab/SectionItem';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE, TEAM_SHARE_SHARED } from 'constants/commonData';

import { ReactComponent as TrashIcon } from 'assets/icons/form_trash.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate_form.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';

import * as Layout from 'shared/LibraryLayout';
import * as S from './style';

const getExerciseText = (sections = []) => {
  let count = 0;

  const result = _.get(sections, 'exercises', []).map(exercise => {
    const resultSupperset = _.get(exercise, 'supersets', []).map(supper => {
      count++;
      return _.get(supper, 'exercise_instance.title', '');
    });

    return resultSupperset.join(', ');
  });
  return {
    list: result.join(' / ') || '',
    count,
  };
};

const getFormatDesc = section => {
  switch (section.format) {
    case 'interval':
      const totalDuration = _.sumBy(section.exercises, exercise => {
        return _.sumBy(exercise.supersets, superset => {
          const eachEide = superset.each_side ? 2 : 1;
          return _.sumBy(superset.training_sets, set => {
            const duration = parseInt(_.get(set, 'duration.value', 0)) || 0;
            const rest = parseInt(_.get(set, 'rest.value', 0)) || 0;
            return (duration + rest) * eachEide;
          });
        });
      });
      const min = Math.floor(totalDuration / 60);
      const seconds = totalDuration % 60;

      return `${min}${seconds ? ':' + `0${seconds}`.slice(-2) : ''} ${pluralize(
        'minute',
        totalDuration === 60 ? 1 : 2,
        '',
      )}`;
    case 'amrap':
      return pluralize('minute', section.time / 60, true);
    case 'timed':
      return pluralize('round', section.round, true);
    case 'regular':
      return pluralize('exercise', getExerciseText(section).count, true);
    default:
      return '';
  }
};

function TableBody(props) {
  const { sections, selectedSection, user, toggleModal, updateSectionSharingStatus, push } = props;

  const isAdmin = isTeamAdmin(user);
  const hasTeam = !!user.team_member && user.team_member.length > 1;

  const handleOpenEditSection = section => {
    const sectionId = _.get(section, '_id', '');
    openSectionWorkoutDetail(sectionId);
    push && push(`/home/sections/${sectionId}/detail`);
  };

  const handleDuplicate = section => {
    const sectionId = _.get(section, '_id', '');
    duplicateSectionLibrary(sectionId);
    push && push(`/home/sections/${sectionId}/duplicate`);
  };

  const handleOpenFormPopup = section => {
    toggleModal(
      true,
      <UpdatedShare user={user} section={section} toggleModal={toggleModal} onUpdate={updateSectionSharingStatus} />,
    );
  };

  const renderActions = section => {
    const isOwner = _.get(section, 'user._id', '') === user._id;
    const isPermission = isAdmin || isOwner || _.get(section, 'share', TEAM_SHARE_PRIVATE) === TEAM_SHARE_NOOWNER;

    return (
      <DropDown triggerIcon={({ open }) => <MenuTrigger vertical active={!!open} itemId={_.get(section, '_id', '')} />}>
        {isPermission && (
          <Option key="Edit" onClick={() => handleOpenEditSection(section)}>
            <div className="icon">
              <EditIcon />
            </div>
            <span>Edit</span>
          </Option>
        )}
        <Option key="duplicate" onClick={() => handleDuplicate(section)}>
          <div className="icon">
            <DuplicateIcon />
          </div>
          <span>Duplicate</span>
        </Option>
        <Option key="update-share" onClick={() => handleOpenFormPopup(section)}>
          <div className="icon">
            <ShareIcon />
          </div>
          <span>Sharing settings</span>
        </Option>
        {isPermission && (
          <Option
            key="delete"
            onClick={() => {
              props.toggleConfirmModal(
                true,
                <ConfirmModal
                  className="custom-discard-change"
                  title="Remove Section"
                  content="Are you sure that you want to delete this section?"
                  onConfirm={() =>
                    props.removeSectionLibrary(_.get(section, '_id', ''), () => {
                      toast.dismiss();
                      toggleModal(false);
                    })
                  }
                />,
              );
            }}
          >
            <div className="icon">
              <TrashIcon />
            </div>
            <span>Delete</span>
          </Option>
        )}
      </DropDown>
    );
  };

  const selectSection = (section, checked) => {
    if (checked) {
      props.updateSelectedSection([...selectedSection, _.get(section, '_id', '')]);
    } else {
      props.updateSelectedSection(_.filter(props.selectedSection, id => id !== section._id));
    }
  };

  const renderCheckbox = section => {
    const isChecked = selectedSection.includes(_.get(section, '_id', ''));
    return (
      <Checkbox
        checked={isChecked}
        onClick={e => e.stopPropagation()}
        onChange={event => {
          event.stopPropagation();
          selectSection(section, event.target.checked);
        }}
      />
    );
  };

  return (
    <Layout.TableBody>
      {_.map(sections, section => {
        const share = section.share === TEAM_SHARE_SHARED || section.share === TEAM_SHARE_NOOWNER;
        const isNoOwner = section.share === TEAM_SHARE_NOOWNER;
        const author =
          hasTeam && !isNoOwner ? _.find(user.team_member, item => item._id === _.get(section, 'user._id', '')) : null;

        const dataNoteSection = _.get(section, 'note', '');

        return (
          <Layout.TableRow
            key={_.get(section, '_id', '')}
            onClick={() => {
              handleOpenEditSection(section);
            }}
          >
            <Layout.TableCell key="section">
              <S.SectionWrapper>
                <S.CheckBoxContainer onClick={e => e.stopPropagation()}>{renderCheckbox(section)}</S.CheckBoxContainer>
                <S.Section>
                  <S.SectionName>{_.get(section, 'title', '')}</S.SectionName>
                  {dataNoteSection && <S.SectionDesc>{dataNoteSection}</S.SectionDesc>}
                  {getExerciseText(section).list && (
                    <S.SectionExercise>{getExerciseText(section).list}</S.SectionExercise>
                  )}
                </S.Section>
              </S.SectionWrapper>
            </Layout.TableCell>
            <Layout.TableCell key="section-type" alignCenter>
              <S.SectionTypeWrapper>
                <S.FormatType>{getFormatName(section.format)}</S.FormatType>
                <S.FormatDescType>{getFormatDesc(section)}</S.FormatDescType>
              </S.SectionTypeWrapper>
            </Layout.TableCell>
            <Layout.TableCell key="most-recent" alignCenter>
              {_.get(section, 'last_used', null) ? timeSince(new Date(_.get(section, 'last_used', null))) : '--'}
            </Layout.TableCell>
            <Layout.TableCell key="actions" alignCenter>
              <Layout.Actions>
                {hasTeam && (
                  <>
                    {author && (
                      <OwnerAvatar
                        data={author}
                        tooltipId={`tooltip--owner-${section._id}`}
                        size={25}
                        className="owner-avatar"
                      />
                    )}
                    <SharingStatus
                      share={share}
                      place="top"
                      itemId={_.get(section, '_id', null)}
                      onClick={event => {
                        event.stopPropagation();
                        handleOpenFormPopup(section);
                      }}
                    />
                  </>
                )}
                {renderActions(section)}
              </Layout.Actions>
            </Layout.TableCell>
          </Layout.TableRow>
        );
      })}
    </Layout.TableBody>
  );
}

const mapState = state => {
  const {
    user,
    rootReducer: {
      sectionLibrary: { selectedSection, loading },
    },
  } = state;

  return { user, selectedSection, loading };
};

const mapDispatch = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    duplicateSectionLibrary: bindActionCreators(duplicateSectionLibrary, dispatch),
    removeSectionLibrary: bindActionCreators(removeSectionLibrary, dispatch),
    updateSelectedSection: bindActionCreators(updateSelectedSection, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    updateSectionSharingStatus: bindActionCreators(updateSectionSharingStatus, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableBody);
