import React from 'react';

import AssignToItem from './AssignToItem';

import * as S from './style';

const AnswerAssignToAssetSelect = props => {
  const { options = [], type, onChange, handleChangeAsset, stateTrigger } = props;

  const renderAnswerItem = answer => {
    return (
      <AssignToItem
        key={answer._id}
        answer={answer}
        type={type}
        onChange={onChange}
        handleChangeAsset={handleChangeAsset}
        options={options}
        stateTrigger={stateTrigger}
      />
    );
  };

  return <S.AnswerContainerAssignToWrapper>{options.map(renderAnswerItem)}</S.AnswerContainerAssignToWrapper>;
};

export default AnswerAssignToAssetSelect;
