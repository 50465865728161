import React, { useEffect, useState } from 'react';
import TextEditable from 'shared/TextEditable';
import * as S from './style';

const RecipeName = props => {
  const { isEditMode, value, onChange, maxLength, isSubmitted, isSubmittedPublish } = props;

  const [isError, setIsError] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    const inputElement = document.querySelector('.name-recipe .text-editable__input');
    if (inputElement) {
      inputElement.textContent = inputElement.textContent.trim();
    }
  }, [isEditMode]);

  const errorMessage = isError && (isSubmitted || isSubmittedPublish) && (
    <S.ErrorMessage>Please add a recipe name</S.ErrorMessage>
  );

  const characterCount = value && (
    <S.MaxLength>
      {value.length}/{maxLength}
    </S.MaxLength>
  );

  const handleBlur = () => {
    setIsError(!value);
    setIsFocus(false);
  };

  const handleFocus = () => setIsFocus(true);

  return (
    <S.RecipeNameWrapper isEditMode={isEditMode}>
      <TextEditable
        onChange={onChange}
        value={value || ''}
        placeholder="Name your recipe"
        invalid={isError}
        disabled={true}
        maxLength={maxLength}
        className="name-recipe"
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {isEditMode && <>{isError ? errorMessage : isFocus ? characterCount : ''}</>}
    </S.RecipeNameWrapper>
  );
};

export default RecipeName;
