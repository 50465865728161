import React from 'react';
import { connect } from 'react-redux';
import type { State, Dispatch } from 'types/redux';
import { PaymentMethodWrapper, CardInfoWrapper, Card, PaymentMethodContainer } from './style';
import StripeBillingInformation, { StripeCardElementOptions, StripeElementsContainer } from 'components/StripeBillingInformation';
import { CardElement } from '@stripe/react-stripe-js';
import { ErrorMessage } from 'shared/FormControl';
import { CARD_BRAND, CDN_URL } from 'constants/commonData';
import { ErrorContainer } from '../style';

class PaymentMethod extends React.Component {
  state = {};

  onCardFocus = () => {
    this.setState({ isCardFocus: true });
  }

  onCarBlur = () => {
    this.setState({ isCardFocus: false });
  }

  render() {
    const { billing, paymentInfo, toggleChangeCard, isChangeCard, billingDetail, invalidCard, showError, emptyCard, error } = this.props;
    const cardBrand = CARD_BRAND[paymentInfo.brand] || {};
    const { isCardFocus } = this.state;

    if (!billing.isInited) {
      return (
        <div />
      );
    }

    return (
      <PaymentMethodWrapper className={this.props.className}>
        {error && <ErrorContainer>
          <span>{error}</span>
        </ErrorContainer>}
        <PaymentMethodContainer>
          <div class="paymentMethod__heading">
            {isChangeCard && (
              <img src={`${CDN_URL}/images/arrow_back_purple.svg`} alt="" onClick={() => toggleChangeCard(false)} />
            )}
            {paymentInfo.payment_method_id && !isChangeCard ? 'Payment Method' : 'Add Payment Method'}
          </div>
          {paymentInfo.payment_method_id && !isChangeCard ? (
            <div>
              <CardInfoWrapper logo={cardBrand.logo}>
                <Card>
                  <div>
                    <span className="card-name">{cardBrand.name}</span> ending in&nbsp;
                    <strong className="sBold">{paymentInfo.last4}</strong>
                  </div>
                  <div className="expires">
                    Expires: {`0${paymentInfo.exp_month}`.slice(-2)}/{paymentInfo.exp_year}
                  </div>
                </Card>
              </CardInfoWrapper>
              <button className="paymentMethod__changeMethodBtn" onClick={() => toggleChangeCard(true)}>
                <span>+ Change Payment Method</span>
              </button>
            </div>
          ) : (
              <div>
                <StripeElementsContainer showError={(emptyCard && !isCardFocus && showError) || error}>
                  <CardElement options={StripeCardElementOptions} onChange={this.props.onCardChange} onFocus={this.onCardFocus} onBlur={this.onCarBlur} />
                  {(emptyCard && !isCardFocus && showError) || invalidCard ? (
                    <ErrorMessage className="error-message">Your card is invalid</ErrorMessage>
                  ) : null}
                </StripeElementsContainer>
                <StripeBillingInformation
                  originalData={billingDetail}
                  onChange={this.props.onBillingInfoChange}
                  showError={showError}
                />
              </div>
            )}
        </PaymentMethodContainer>
      </PaymentMethodWrapper>
    );
  }
}

const mapStateToProps = (state: State) => {
  const { rootReducer } = state;
  return {
    selectedPackage: rootReducer.pricing.get('selectPackage').toJS(),
    teamData: rootReducer.pricing.getIn(['teamData']).toJS(),
    paymentInfo: rootReducer.billing.paymentInfo,
    billing: rootReducer.billing,
    is_payment_success: rootReducer.payment.is_payment_success
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethod);