import React from 'react';
import ReactTooltip from 'react-tooltip';
import * as S from './style';

export const SharedTooltip = props => {
  const {
    position = 'top',
    id = '',
    content = 'More options',
    width = null,
    noWrap = false,
    whiteTooltip = false,
    className = '',
    offset = {},
    clickable = false,
  } = props;

  return (
    <S.TooltipWrapper width={width} noWrap={noWrap} className={className}>
      <ReactTooltip
        id={id}
        className={`${whiteTooltip ? 'app-tooltip-white' : 'app-tooltip'} custom-tooltip`}
        place={position}
        effect="solid"
        offset={offset}
        clickable={clickable}
      >
        {content}
      </ReactTooltip>
    </S.TooltipWrapper>
  );
};
