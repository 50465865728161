import styled from 'styled-components';

export const BackButton = styled.div`
  text-align: center;
  margin-bottom: 25px;
  width: 444px;
  text-align: left;
  max-width: 100%;

  a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #191753 !important;
    text-decoration: none !important;

    svg {
      margin-right: 8px;
    }
  }
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #191753;
  margin-bottom: 40px;

  &.has-message {
    margin-bottom: 15px;
  }
`;

export const SuccessContainer = styled.div`
  margin-bottom: 40px;
  padding: 10px 50px;
  background: #EFFFEF;
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  text-align: center;
  color: #62BC60;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: auto;

  .form-group {
    margin-bottom: 55px;
  }

  .form__header--reset-password {
    margin-bottom: 15px;
  }

  button[type='submit'] {
    text-transform: none;
  }

  @media only screen and (max-width: 768px) {
    ${BackButton} {
      padding: 30px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`;
