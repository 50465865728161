import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'semantic-ui-react';
import _ from 'lodash';
import ContentEditable from 'shared/ContentEditable';
import { deleteSegment, updateSegment } from 'redux/segment/actions';
import GeneralButton from 'shared/GeneralButton';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as LearMoreIcon } from 'assets/icons/learn_more_icon.svg';

class ManageSegment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeSegmentName = (segment, value) => {
    const { segments } = this.props;
    const name = value.trim();
    const origin = _.find(segments, item => item._id === segment._id);

    if ((!name && !segment.trainer) || !origin || origin.name === name) {
      return false;
    }

    let data = Object.assign({}, segment);
    data.trainer = typeof data === 'object' ? data.trainer._id : data.trainer;
    data.name = name;
    const { updateSegment } = this.props;
    updateSegment(segment._id, data);
  };

  onDelete = segment => {
    const { toggleConfirmModal, deleteSegment } = this.props;
    toggleConfirmModal(
      true,
      <ConfirmModal
        title={'Delete Segment?'}
        content={`Are you sure you want to delete the segment ‘${segment.name}’?`}
        onConfirm={() => deleteSegment(segment._id)}
        onDeny={() => {}}
        confirmButtonTitle="Delete"
        cancelButtonTitle="Cancel"
      />,
    );
  };

  getDescription = segment => {
    const { groups, user } = this.props;
    let result = [{ label: 'Category', value: segment.client_type || 'All' }];

    if (segment.groups && segment.groups.length) {
      let text = '';
      _.forEach(segment.groups, (gId, index) => {
        const existObj = _.find(groups, g => g._id === gId);
        if (existObj) {
          if (index && text) {
            text += ', ';
          }

          text += `${existObj.name}`;
        }
      });
      result.push({ label: 'Group', value: text });
    }

    let owner_ship = { label: 'Owner', value: 'You' };

    if (segment.owner_ship === 2) {
      owner_ship.value = 'All';
    } else if (segment.owner_ship === 3 && segment.certain_users && segment.certain_users.length) {
      let text = '';
      _.forEach(segment.certain_users, (uId, index) => {
        const existObj = _.find(user.team_member, u => u._id === uId);

        if (existObj && index && text) {
          text += `, ${existObj.first_name} ${existObj.last_name}`;
        }
      });
      owner_ship.value = text;
    }

    result.push(owner_ship);
    return result;
  };

  render() {
    const { segments, toggleModal, show, editSegment } = this.props;

    if (!show) {
      return null;
    }

    return (
      <React.Fragment>
        <Modal.Content>
          <div className={`segments-table`}>
            <div className="segments-table__header">
              <table>
                <thead>
                  <tr>
                    <th>
                      <span>Segment Name</span>
                    </th>
                    <th />
                  </tr>
                </thead>
              </table>
            </div>
            <div className="segments-table__body">
              {segments.length === 0 && (
                <div className="segments-empty">
                  <div className="description">
                    No custom segments yet. Adjust client list filters to set up and create your first one
                  </div>
                  <div>
                    <LearMoreIcon />{' '}
                    <a
                      href="https://help.everfit.io/en/articles/5608080-client-list-how-to-use-segments"
                      className="learn-more-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              )}
              <table>
                <thead>
                  <tr>
                    <th />
                    <th />
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {_.map(segments, item => {
                    const descriptions = this.getDescription(item);

                    return (
                      <tr key={item._id}>
                        <td>
                          <div className="segment-title">
                            <ContentEditable
                              required={true}
                              value={item.name}
                              onChange={e => this.onChangeSegmentName(item, e.value)}
                              maxLength={40}
                            />
                          </div>
                          <div className="description">
                            {_.map(descriptions, (d, index) => (
                              <span key={index}>
                                {index ? ', ' : ''}
                                {d.label}: {d.value}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td>
                          <div className="sharing-status">{item.share ? 'Shared' : 'Private'}</div>
                        </td>
                        <td>
                          <div className="segment-actions">
                            <span onClick={() => this.onDelete(item)}>Delete</span>
                            <span onClick={() => editSegment(item)}>Edit</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions className="manage-segment-actions">
          <div />
          <GeneralButton onClick={() => toggleModal(false)}>Done</GeneralButton>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

const mapStateTopProps = state => {
  const { rootReducer, user } = state;
  const { segment } = rootReducer;
  const segments = _.filter(segment.list || [], item => item.trainer._id === user._id && !item.is_default);
  return {
    user,
    segments,
    groups: rootReducer.group.list,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteSegment: bindActionCreators(deleteSegment, dispatch),
  updateSegment: bindActionCreators(updateSegment, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateTopProps, mapDispatchToProps)(ManageSegment);
