import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';

import { addRoomDraftMessage } from 'redux/inbox-new/actions';
import AppleHealthIcon from 'assets/icons/apple_health.svg';
import GoogleFitIcon from 'assets/images/google_fit.svg';
import FitBitIcon from 'assets/icons/fitbit.svg';
import GarminIcon from 'assets/icons/garmin.svg';
import { encryptData } from 'utils/commonFunction';

import { getMessageRequest } from 'components/BodyMetricProgressNew/AskToConnectButton';
import { HeaderWrapper } from '../style';

function Header(props) {
  const { device, client, clientName, isDefaultStep, bodyMetric, isConnected3rdParty } = props;
  const message = useMemo(() => getMessageRequest('steps'), [bodyMetric.unique_code]);

  const handleAskToConnect = () => {
    props.addRoomDraftMessage(client, message);
    props.push(`/home/inbox?profileId=${client}&ufn=${encryptData(clientName)}`);
  };

  const handleRenderIcon = () => (
    <div className="icon-area">
      <img src={FitBitIcon} className="icon fitbit-icon" alt="" />
      <img src={GarminIcon} className="icon garmin-icon" alt="" />
      <img src={device === 'android' ? GoogleFitIcon : AppleHealthIcon} className="icon device-icon" alt="" />
    </div>
  );

  return (
    <HeaderWrapper>
      <h3 className="metric-name">{bodyMetric.name}</h3>
      {isDefaultStep && device && !isConnected3rdParty && (
        <div className="integration-container">
          {handleRenderIcon()}
          <div className="name">Third-party App integration</div>
          <div className="ask-to-connect" onClick={handleAskToConnect}>
            Ask to connect
          </div>
        </div>
      )}
    </HeaderWrapper>
  );
}

const mapState = state => {
  const {
    bodyMetric,
    rootReducer: {
      client: { workingClientDetail, isConnected3rdParty },
    },
  } = state;

  return {
    bodyMetric,
    device: get(workingClientDetail, 'last_used_device'),
    client: get(workingClientDetail, '_id'),
    clientName:
      get(workingClientDetail, 'full_name') ||
      `${get(workingClientDetail, 'first_name')} ${get(workingClientDetail, 'last_name')}`,
    isConnected3rdParty,
  };
};

export default connect(mapState, { push, addRoomDraftMessage })(Header);
