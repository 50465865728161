import { fromJS } from 'immutable';
import { orderBy } from 'lodash';
import { Types } from './actions';

const INITIAL_STATE = fromJS({
  list: [],
  selected: [],
  total: 0,
  sorter: 'createdAt',
  sort: 1,
  loading: false,
  hasNew: false,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.WAITING_CLIENTS_GET_LIST_SUCCESSFULLY:
      return state.merge(fromJS({ ...payload, loading: false }));

    case Types.WAITING_CLIENTS_GET_LIST_FAILED:
      return state.set('loading', false);

    case Types.WAITING_CLIENTS_GET_LIST:
      return state.set('loading', true);

    case Types.WAITING_CLIENTS_SORT: {
      const { sort, sorter } = payload;
      const oldList = state.get('list').toJS();
      const newList = orderBy(oldList, sorter, sort === 1 ? 'asc' : 'desc');
      return state.merge(fromJS(payload)).set('list', fromJS(newList));
    }

    case Types.WAITING_CLIENTS_RESET_DATA: {
      return state.merge(fromJS({ selected: [], sorter: 'createdAt', sort: 1, loading: false }));
    }

    case Types.WAITING_CLIENT_UPDATE_TOGGLE_HAS_NEW_FLAG: {
      return state.set('hasNew', payload);
    }

    default:
      return state;
  }
};
