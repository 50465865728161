import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  :hover {
    .chart-arrow-btn {
      display: block;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: ${({ isOverview }) => (isOverview ? '100%' : 'calc(100% - 50px)')};
  transform: translateX(${({ isOverview }) => (isOverview ? '0' : '-20')}px);
  margin-top: ${({ isModeCompare }) => (isModeCompare ? '22px' : '0')};

  ${({ isOverview }) =>
    !isOverview &&
    css`
      .chart-wrapper {
        min-height: 337px;
      }
    `};

  .loading-chart-indicator {
    position: absolute;
    width: 100%;
    padding-left: 30px;
    padding-top: 145px;
  }

  .chart-arrow-btn {
    position: absolute;
    z-index: 2;
    display: none;
    top: calc(50% - ${({ isModeCompare }) => (isModeCompare ? '30px' : '15px')});
    height: 30px;
    width: 30px;
    border: 1px solid #e1e1ea;
    border-radius: 50%;
    background-color: #ffffff;
    overflow: hidden;

    svg {
      width: 30px;
      height: 30px;
      position: absolute;
      left: -1px;
      top: -1px;

      circle {
        fill: transparent;
        stroke: transparent;
      }
      path {
        stroke: #8789a8;
      }
    }

    &:disabled {
      opacity: 0.7;
      cursor: no-drop;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: #f0f1ff;
      svg path {
        stroke: #5158cf;
      }
    }

    &.chart-prev-btn {
      transform: rotate(180deg);
    }
    &.chart-next-btn {
      right: -30px;
    }
    &.chart-prev-btn {
      left: -1px;
    }
    &.exercise-btn.chart-prev-btn {
      left: -1px;
    }
    &.exercise-btn.chart-next-btn {
      right: -15px;
    }
  }

  :hover {
    .chart-arrow-btn {
      display: block;
    }
  }

  ${({ isHideYAxis }) =>
    isHideYAxis &&
    css`
      .metric-chart .recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis .recharts-cartesian-axis-ticks {
        display: none;
      }
    `}

  ${({ isHideXAxis }) =>
    isHideXAxis &&
    css`
      .metric-chart .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
        display: none;
      }
    `}

  ${({ minHeight = 300 }) => css`
    .recharts-responsive-container {
      min-height: ${minHeight}px;
    }
  `}

  .recharts-responsive-container {
    /* &.chart-line .xAxis .recharts-cartesian-axis-ticks .recharts-layer.recharts-cartesian-axis-tick:last-child text {
      transform: translateX(-16px);
    } */

    line.recharts-cartesian-axis-tick-line,
    .recharts-layer.xAxis .recharts-cartesian-axis-line {
      stroke: #e1e1ea;
    }

    /* &.no-data .xAxis {
      display: none;
    } */

    &.no-data .recharts-tooltip-cursor {
      display: none;
    }

    .recharts-tooltip-cursor {
      pointer-events: none;
    }

    .recharts-layer .recharts-curve.recharts-area-area {
      cursor: pointer;
    }

    .recharts-surface {
      cursor: default !important;
      overflow: visible;
    }

    text {
      color: #7b7e91;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      text-transform: uppercase;
    }

    .yAxis text {
      text-transform: lowercase;
      font-size: 10px;
      line-height: 15px;

      &.recharts-label {
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
    &.time-type {
      .yAxis text {
        text-transform: uppercase;
      }
    }
  }

  .chart-no-data {
    position: absolute;
    height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '100%')};
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    color: #7b7e91;

    svg {
      width: 32px;
      height: 32px;
      g path {
        stroke: #7b7e91;
      }
    }

    .no-data-wrapper {
      width: 145px;
      height: 60px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      svg {
        width: 32px;
        height: 32px;

        path {
          stroke: #7b7e91;
        }
      }

      .range-chart {
        path {
          stroke-width: 1;
        }
      }

      > p {
        color: #7b7e91;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .recharts-active-dot {
    display: none;
  }

  .recharts-tooltip-wrapper {
    z-index: 2;
    @keyframes delayedShow {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
        visibility: visible;
      }
    }
    &.show-my-tooltip {
      animation: 0.2s linear 0.3s forwards delayedShow;
      transform: unset !important;
      transition: unset !important;
    }
  }

  .one-point {
    .recharts-xAxis.xAxis .recharts-layer.recharts-cartesian-axis-tick:not(:nth-child(4)) {
      display: none;
    }
  }

  ${({ isOverviewAndOverviewGroup = false }) =>
    isOverviewAndOverviewGroup &&
    css`
      padding-left: 8px;
    `}
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(38, 38, 38, 0.2);
  min-width: ${({ isExerciseMetric }) => (isExerciseMetric ? 'auto' : '106px')};
  max-height: ${({ isExerciseMetric }) => (isExerciseMetric ? '63px' : '93px')};
  font-family: 'Open Sans';
  color: #7b7e91;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  transition: ${({ isHoveringChartBar, isSpecialChart }) =>
    isSpecialChart ? (isHoveringChartBar ? 'opacity 0s 0.5s' : 'opacity 0s 0s') : 'none'};

  &.sleep-tooltip {
    max-height: unset !important;

    .tooltip-value {
      height: 15px;

      span.divider {
        display: inline-block;
        position: relative;
        top: 11px;
        margin: 0 4px;
        width: 4px;
        height: 2px;
        background-color: #262746;
      }
    }
  }

  ${({ isOutsideRight, positionVertical = 10, widthCurrentTarget = 0 }) => {
    if (isOutsideRight) {
      return css`
        transform: translateX(calc(-100% - ${positionVertical}px));
      `;
    } else {
      return css`
        transform: translateX(calc(${widthCurrentTarget}px + ${positionVertical}px));
      `;
    }
  }}

  &.sleep-tooltip {
    max-height: unset !important;
    &.normal-hourly {
      transform: translateX(-50%);
      ${({ isOutsideRightNormalHourly, widthCurrentTarget = 0 }) => {
        if (isOutsideRightNormalHourly) {
          return css`
            transform: translateX(calc(-100% + ${widthCurrentTarget / 2}px));
          `;
        }
      }}
    }
    &.special-hourly {
    }

    .tooltip-value {
      height: 15px;

      span.divider {
        display: inline-block;
        position: relative;
        top: 11px;
        margin: 0 4px;
        width: 4px;
        height: 2px;
        background-color: #262746;
      }
    }
  }

  &.has-change {
    min-width: ${({ isExerciseMetric }) => (isExerciseMetric ? 'auto' : '67px')};
    min-height: ${({ isExerciseMetric }) => !isExerciseMetric && '63px'};
    padding-bottom: ${({ isExerciseMetric }) => !isExerciseMetric && '5px'};
    width: max-content;
  }

  &.is-range-time {
    width: max-content;
    min-width: unset;
    padding: 10px;
    gap: 0;
    ${({ isOutsideRight, positionVertical = 0, widthCurrentTarget = 0 }) => {
      if (isOutsideRight) {
        return css`
          transform: translateX(calc(-100% - ${positionVertical}px));
        `;
      } else {
        return css`
          transform: translateX(calc(${widthCurrentTarget}px + ${positionVertical}px));
        `;
      }
    }}
    .label-tool-tip {
      margin-bottom: 6px;
    }
    .tooltip-value-wrapper {
      height: 23px;
      margin-bottom: 6px;
      display: flex;
      align-items: baseline;
      .tooltip-value {
        height: 100%;
      }
      .label-average {
        ${textBase}
        font-weight: 400;
        line-height: 15px;
        font-size: 10px;
        color: #a3a3b5;
        padding-left: 12px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(calc(-50% - 1px));
          left: 6px;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: #a3a3b5;
        }
      }
    }
    .list-stage {
      margin-top: 0;
      width: fit-content;
      .sleep-state {
        padding: 0;
        gap: 15px;
        justify-content: start;
      }
      .sleep-state-left {
        width: 60px;
      }
    }
    &.recharts-tooltip-wrapper-overview {
      transform: translate(-50%, -100%) !important;
    }
  }

  h6,
  p {
    margin: 0;
  }

  p {
    color: #202353;
  }

  .tooltip-value {
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;
  }

  .value-change {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;

    .from-time {
      font-size: 11px;
      line-height: 18px;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  ${({ isModeCompare }) =>
    isModeCompare &&
    css`
      gap: 10px;
      min-width: unset;
      max-height: unset;
      box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);

      .value-change {
        margin-bottom: 0;
      }
    `}
`;

export const AverageLabel = styled.span`
  display: flex;
  ${textBase}
  color: #a3a3b5;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  align-self: center;
  padding-top: 2px;
`;

export const RangeTimeLabel = styled.span`
  ${textBase}
  color: #7B7E91;
  font-size: 12px;
`;

export const TimeRange = styled.span`
  ${textBase}
  color: #A3A3B5;
  font-size: 11px;
`;

export const UnitValue = styled.span`
  ${textBase}
  color: #202353;
  font-size: 15px;
  padding-bottom: 2px;
`;

export const UnitStyle = styled.span`
  ${textBase}
  color: #202353;
  font-size: 12px;
  align-self: center;
`;

export const Tag = styled.div`
  padding: 4px;
  border-radius: 5px;
  font-size: 10px;
  letter-spacing: 2px;
  height: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;

  svg {
    margin-right: 4px;
  }

  &.no-change {
    background-color: #f0f0f2;
  }

  &.increase-change {
    background-color: #edfaf4;
    color: #35c759;
    svg path {
      fill: #35c759;
    }
  }

  &.decrease-change {
    background-color: #faedef;
    color: #fe2c55;
    svg {
      transform: rotate(180deg);
      path {
        fill: #fe2c55;
      }
    }
  }
`;

export const MetricChartCompareWrapper = styled.div`
  margin-top: 31px;
  position: relative;

  ${({ isNoDataButHasGoal }) =>
    isNoDataButHasGoal &&
    css`
      .metric-chart-compare
        .recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis
        .recharts-cartesian-axis-ticks {
        display: none;
      }
    `}

  .recharts-responsive-container {
    /* &.chart-line .xAxis .recharts-cartesian-axis-ticks .recharts-layer.recharts-cartesian-axis-tick:last-child text {
      transform: translateX(-16px);
    } */

    line.recharts-cartesian-axis-tick-line,
    .recharts-layer.xAxis .recharts-cartesian-axis-line {
      stroke: #e1e1ea;
    }

    /* &.no-data .xAxis {
      display: none;
    } */

    &.no-data .recharts-tooltip-cursor {
      display: none;
    }

    .recharts-tooltip-cursor {
      pointer-events: none;
    }

    .recharts-surface {
      cursor: default !important;
      overflow: visible;
    }

    text {
      color: #7b7e91;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      text-transform: uppercase;
    }

    .yAxis text {
      text-transform: lowercase;
      font-size: 10px;
      line-height: 15px;

      &.recharts-label {
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
  }

  .chart-compare-loading {
    position: absolute;
    width: 100%;
    padding-top: 0;
    margin-top: -150px;
    margin-left: 15px;
  }

  .chart-no-data-compare {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 30px;

    .no-data-compare-wrapper {
      width: 145px;
      height: 60px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      svg {
        width: 32px;
        height: 32px;

        path {
          stroke: #7b7e91;
        }
      }

      > p {
        color: #7b7e91;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
`;

export const TooltipCompareWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ChartTypes = styled.div`
  display: flex;
  align-items: center;
  height: 21px;
`;

export const ChartType = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 2px;

  ${({ isChartLine }) =>
    isChartLine &&
    css`
      height: 2px;
    `}
`;

export const ChartInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ChartName = styled.div`
  height: 21px;
  display: flex;
  align-items: center;
  color: #7b7e91;
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
`;

export const ChartNoData = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const AvgLabel = styled.svg`
  overflow: overlay !important;
  path {
    fill: ${({ color }) => color};
  }
`;
