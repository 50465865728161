import get from 'lodash/get';
import { toast } from 'react-toastify';
import moment from 'moment';

import Request from 'configs/request';
import { NoteTypes } from 'components/Note/component';

export const COUNTDOWN_TYPES = {
  FETCH_COUNTDOWN_LIST_REQUEST: 'FETCH_COUNTDOWN_LIST_REQUEST',
  FETCH_COUNTDOWN_LIST_SUCCESS: 'FETCH_COUNTDOWN_LIST_SUCCESS',
  FETCH_COUNTDOWN_LIST_FAILED: 'FETCH_COUNTDOWN_LIST_FAILED',
  FETCH_GOAL_LIST_REQUEST: 'FETCH_GOAL_LIST_REQUEST',
  FETCH_GOAL_LIST_SUCCESS: 'FETCH_GOAL_LIST_SUCCESS',
  FETCH_COUNTDOWN_TEMPLATE_LIST_REQUEST: 'FETCH_COUNTDOWN_TEMPLATE_LIST_REQUEST',
  FETCH_COUNTDOWN_TEMPLATE_LIST_SUCCESS: 'FETCH_COUNTDOWN_TEMPLATE_LIST_SUCCESS',
  ADD_COUNTDOWN_REQUEST: 'ADD_COUNTDOWN_REQUEST',
  ADD_COUNTDOWN_SUCCESS: 'ADD_COUNTDOWN_SUCCESS',
  UPDATE_COUNTDOWN_REQUEST: 'UPDATE_COUNTDOWN_REQUEST',
  UPDATE_COUNTDOWN_SUCCESS: 'UPDATE_COUNTDOWN_SUCCESS',
  REMOVE_COUNTDOWN_REQUEST: 'REMOVE_COUNTDOWN_REQUEST',
  REMOVE_COUNTDOWN_SUCCESS: 'REMOVE_COUNTDOWN_SUCCESS',
  ARCHIVE_COUNTDOWN_REQUEST: 'ARCHIVE_COUNTDOWN_REQUEST',
  ARCHIVE_COUNTDOWN_SUCCESS: 'ARCHIVE_COUNTDOWN_SUCCESS',
  RESET_CLIENT_GOAL_COUNTDOWN: 'RESET_CLIENT_GOAL_COUNTDOWN',
};

export const resetClientGoalCountdown = () => {
  return dispatch => {
    dispatch({
      type: COUNTDOWN_TYPES.RESET_CLIENT_GOAL_COUNTDOWN,
    });
  };
};

export const getClientCountdownList = (params, isRefresh = false) => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { countdownList } = rootReducer.clientCountdown || {};
    if (!isRefresh) {
      dispatch({
        type: COUNTDOWN_TYPES.FETCH_COUNTDOWN_LIST_REQUEST,
      });
    }

    return dispatch(
      Request.get(
        {
          url: `/api/goal-countdown`,
          params: {
            ...params,
            sorter: 'date_utc',
            sort: 1,
            per_page: 20,
          },
        },
        false,
        res => {
          const { total = 0, data = [] } = res.data;
          const newList = params.page === 1 ? data : [...countdownList, ...data];
          const finalList = newList.filter(({ date_utc }) => {
            const minutes = moment(date_utc).diff(moment(), 'minutes');
            return minutes >= 1;
          });

          dispatch({
            type: COUNTDOWN_TYPES.FETCH_COUNTDOWN_LIST_SUCCESS,
            payload: { data: finalList, total, page: params.page },
          });
        },
        err => {
          dispatch({
            type: COUNTDOWN_TYPES.FETCH_COUNTDOWN_LIST_FAILED,
          });
        },
        false,
      ),
    );
  };
};

export const getClientGoalList = clientID => {
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: '/api/trainer/client_info/detail',
          params: { type: 'goal', client: clientID },
        },
        false,
        res => {
          dispatch({
            type: COUNTDOWN_TYPES.FETCH_GOAL_LIST_SUCCESS,
            payload: res.data.data,
          });
        },
        err => {
          console.err(err);
        },
        false,
      ),
    );
  };
};

export const updateClientGoal = (client, content = '', isEdit) => {
  return dispatch => {
    const data = {
      client,
      content,
      type: NoteTypes.goal,
    };

    return dispatch(
      Request.post(
        { url: '/api/trainer/client_info/add', data },
        true,
        () => {
          if (content === '' && !isEdit) {
            toast('Goal has been removed.');
          } else if (!isEdit) {
            toast('Goal has been created.');
          } else {
            toast('Goal has been saved.');
          }
          dispatch({
            type: COUNTDOWN_TYPES.FETCH_GOAL_LIST_SUCCESS,
            payload: content,
          });
        },
        () => {},
        false,
      ),
    );
  };
};

export const addNewCountdown = (data, callback) => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: '/api/goal-countdown', data },
        true,
        res => {
          const { data } = res.data;
          if (data) {
            callback && callback();
            toast('Countdown has been created.');
          }
        },
        err => {},
        false,
      ),
    );
  };
};

export const removeCountdown = id => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const countdownList = get(rootReducer, 'clientCountdown.countdownList', []);
    return dispatch(
      Request.delete(
        { url: `/api/goal-countdown/${id}` },
        true,
        res => {
          const newCountdowns = countdownList.filter(({ _id }) => _id !== id);

          dispatch({
            type: COUNTDOWN_TYPES.REMOVE_COUNTDOWN_SUCCESS,
            payload: newCountdowns,
          });
          toast('Countdown has been removed.');
        },
        err => {},
        false,
      ),
    );
  };
};

export const updateCountdown = (data, callback) => {
  return dispatch => {
    return dispatch(
      Request.patch(
        { url: '/api/goal-countdown/' + data._id, data },
        true,
        res => {
          callback && callback();
          toast('Countdown has been saved.');
        },
        err => {},
        false,
      ),
    );
  };
};

export const archiveCountdown = id => {
  return dispatch => {
    return dispatch(
      Request.patch(
        { url: '/api/trainer/client_info/countdown/' + id },
        true,
        res => {
          console.log(res);
        },
        err => {
          console.log(err);
        },
        false,
      ),
    );
  };
};

export const saveCountdownAsTemplate = id => {
  return dispatch => {
    return dispatch(
      Request.post(
        { url: '/api/trainer/client_info/countdown-template/' + id },
        true,
        res => {
          console.log(res);
        },
        err => {
          console.log(err);
        },
        false,
      ),
    );
  };
};

export const getClientCountdownTemplateList = params => {
  return dispatch => {
    return dispatch(
      Request.get(
        { url: '/api/trainer/client_info/countdown-template', params },
        res => {
          console.log(res);
        },
        err => {
          console.log(err);
        },
      ),
    );
  };
};

export const getCountdownDetail = _id => {
  return dispatch => {
    return dispatch(Request.get({ url: `/api/goal-countdown/${_id}` }));
  };
};
