// import from lodash
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';

import React from 'react';
import ReactTooltip from 'react-tooltip';
import { DateTime } from 'luxon';
import ProgramCalendarCell from 'components/ProgramCalendarCell';
import { Container } from './styles';
import { CALENDAR_LIMIT_WEEKS, TAGS_TYPE } from 'constants/commonData';

import * as S from './styles';

const weekday = new Array(7);
weekday[0] = 'Mon';
weekday[1] = 'Tue';
weekday[2] = 'Wed';
weekday[3] = 'Thu';
weekday[4] = 'Fri';
weekday[5] = 'Sat';
weekday[6] = 'Sun';

export default class ProgramCalendar extends React.Component {
  componentDidMount() {
    this.props.getMostRecentTagsList({ type: TAGS_TYPE.EXERCISE, page: 1, perPage: 20, sort: -1 });
  }
  componentWillUnmount() {
    this.props.exitProgramDetailScreen();
    this.props.resetCopyItem();
  }

  renderLines = () => {
    const { selectedProgram, calendarType, currentWeek, totalWeek, addWeekAtIndex } = this.props;
    if (totalWeek >= CALENDAR_LIMIT_WEEKS) {
      return null;
    }
    const lines = [];
    const renderedWeeks = Math.min(calendarType, totalWeek - currentWeek);

    for (let i = currentWeek; i <= currentWeek + calendarType; i++) {
      const lineIndex = i - currentWeek + 1;
      const tooltipId = `program-add-week__line${lineIndex}`;

      lines.push(
        <div className={`program-add-week__line line${lineIndex}${lineIndex > renderedWeeks + 1 ? ' no-show' : ''}`}>
          <div
            className="program-add-week__icon"
            data-tip
            data-for={tooltipId}
            onClick={() => {
              if (!isEmpty(get(selectedProgram, '_id'))) {
                addWeekAtIndex({ weekIndex: i, programId: selectedProgram._id });
              }
            }}
          />
          <ReactTooltip
            className="app-tooltip"
            id={tooltipId}
            effect="solid"
            place={i === currentWeek ? 'bottom' : 'top'}
            delayShow={200}
          >
            <span>Add 1 week</span>
          </ReactTooltip>
        </div>,
      );
    }

    return lines;
  };

  onPasteWeek = (weekIndex, event) => {
    const { handlePasteWeek } = this.props;
    const multiPaste = event.shiftKey;
    const debouncePasteWeek = debounce(handlePasteWeek, 100);
    debouncePasteWeek(weekIndex, multiPaste);
  };

  renderPasteButton() {
    const { calendarType, selectedWeek, currentWeek, totalWeek } = this.props;
    const rows = [];

    for (let index = 0; index < calendarType; index++) {
      const weekToPaste = index + currentWeek;
      const isOverWeekRange = weekToPaste + 1 > totalWeek;
      rows.push(
        <>
          {!isNil(selectedWeek) && !isOverWeekRange ? (
            <>
              <S.PasteButtonContainer calendarType={calendarType}>
                <S.PasteButton onClick={event => this.onPasteWeek(weekToPaste, event)}>Paste Week</S.PasteButton>
              </S.PasteButtonContainer>
            </>
          ) : null}
        </>,
      );
    }

    return (
      <S.PasteButtonWrapper style={{ height: `calc((100% - 25px) / ${calendarType})` }}>{rows}</S.PasteButtonWrapper>
    );
  }

  render() {
    const {
      selectedProgram,
      calendarType,
      selectedWorkout,
      isLoadingWorkouts,
      isAddingWeek,
      selectedWeek,
    } = this.props;
    const isHideAddWeekLine = !isNil(selectedWeek) || !isEmpty(selectedWorkout);

    if (!selectedProgram) {
      return null;
    }

    return (
      <Container
        className="calendar-columns program-calendar__columns"
        numOfLines={calendarType + 1}
        isCopingWeek={!isNil(selectedWeek)}
      >
        {isEmpty(selectedWorkout) && !isLoadingWorkouts && this.renderPasteButton()}
        {map(Array(7), (item, index) => this.renderColumn(index))}
        {!isAddingWeek && !isHideAddWeekLine && this.renderLines()}
      </Container>
    );
  }

  renderColumn(column) {
    const { calendarType, totalWeek, currentWeek, isDragging, workoutSets } = this.props;
    const columns = [];
    let dayofWeek = weekday[column];

    for (let i = 0; i < calendarType; i++) {
      let day = DateTime.local();

      day = day.plus({ days: 7 * i + column });

      let weekIndex = i + currentWeek;
      let calendarDayIndex = 7 * weekIndex + column;
      let dayWorkouts = get(workoutSets[weekIndex], 'days_workout', null);
      let isEmptyWeek = dayWorkouts && isEmpty(dayWorkouts);
      let emptyCell = false;

      if (weekIndex >= totalWeek) {
        emptyCell = true;
      }

      columns.push(
        <ProgramCalendarCell
          isEmptyWeek={isEmptyWeek}
          emptyCell={emptyCell}
          dayIndex={column}
          calendarDayIndex={calendarDayIndex}
          weekIndex={weekIndex}
          weekIndexNumber={i + 1}
          dayofWeek={dayofWeek}
          column={column}
          isDragging={isDragging}
          onMouseEnter={this.props.onMouseEnter}
        />,
      );
    }

    const columnClass = column === 0 ? 'first-calendar-column' : column === 1 ? 'second-calendar-column' : '';

    return (
      <div key={column} className={'calendar-column ' + columnClass}>
        <div className="calendar-column__body">{columns}</div>
      </div>
    );
  }
}
