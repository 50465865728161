import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import { FormGroup, Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { toast } from 'react-toastify';
import { ModalWrapper } from './style';
import { validatePositiveIntegerNumberOnly } from 'utils/validations';
import { CDN_URL } from 'constants/commonData';

function SetTarget({ onClose, currentValue, ...props }) {
  const [value, setValue] = useState(currentValue);

  const onChangeValue = event => {
    const { value } = event.target;

    if (parseInt(value) > 999999) {
      return;
    }

    setValue(value);
  };

  const onSubmit = event => {
    event.preventDefault();

    if (!parseInt(value)) {
      return toast.error('Please enter a positive integer number');
    }

    props.onSave(parseInt(value));
  };

  return (
    <ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      className="modal--set-step-goal"
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      width={394}
    >
      <Modal.Content>
        <form onSubmit={onSubmit}>
          <ModalLayout.Header className="modal__header">Set Target</ModalLayout.Header>
          <ModalLayout.Content className="modal__content">
            <FormGroup className="form-group">
              <label>StepS Goal</label>
              <div className="stepGoal__inputValue">
                <input
                  onChange={onChangeValue}
                  type="number"
                  value={value}
                  onKeyPress={validatePositiveIntegerNumberOnly}
                  max="1000000"
                />
                <span className="stepGoal__inputValue--addon">Steps</span>
              </div>
            </FormGroup>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button purple type="submit">
              Update
            </Button>
          </ModalLayout.Actions>
        </form>
      </Modal.Content>
    </ModalWrapper>
  );
}

export default SetTarget;
