import React from 'react';

import { CDN_URL } from 'constants/commonData';

import * as S from '../styles';
// import AddNewProgramButton from './AddNewProgramButton';

const ResultNotFound = () => {
  return (
    <S.EmptyWrapper>
      <img src={`${CDN_URL}/images/program_library_not_found.svg`} alt="" />
      <div className="empty-label">No results found.</div>
      {/* <AddNewProgramButton className="add-more-program-button" /> */}
    </S.EmptyWrapper>
  );
};

export default ResultNotFound;
