import styled, { css } from 'styled-components';
import { MenuTrigger } from 'shared/Icons';
import SmallVideo from 'assets/icons/small_play_light.png';
import Default from 'assets/icons/on-demand-default-wk.png';

export const WorkoutList = styled.div`
  display: flex;
  height: calc(100% - 30px);
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  ${props =>
    props.isPagination &&
    css`
      height: calc(100% - 90px);
    `}
`;

export const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;
`;

export const TableHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 2px 0px #f1f3f4;
  z-index: 1;
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 117px 90px 100px 128px;
  align-items: center;
  ${props =>
    props.withHoverStyle &&
    `
      :hover {
        background-color: #f8f8f8;
      }
      cursor: pointer;
  `}
`;

export const TableBody = styled.div`
  padding-bottom: 10px;

  ${TableRow} {
    box-shadow: inset 0px -1px 0px #f0f0f0;
    &:last-child {
      box-shadow: none;
    }
  }
`;

export const HeaderItem = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: ${props => (props.active ? '#5158cf' : '#a1a1a5')};
  text-align: left;
  padding: 18px 0;
  &:first-child {
    padding-left: 15px;
  }
`;

export const HeaderItemContainer = styled.div`
  display: flex;

  .click-container {
    cursor: pointer;
  }
  .icon {
    margin-left: 5px;
    padding-top: 1px;
    color: ${props => (props.active ? '#5158cf' : '#a1a1a5')};
    font-size: 10px;
  }
`;

export const WorkoutInfo = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  align-items: center;
  padding: 6px 15px;
`;

export const WorkoutInfoDetail = styled.div`
  display: flex;
  align-items: center;
`;

export const MediaWrapper = styled.div`
  min-width: 68px;
  height: 48px;
  border-radius: 3px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-image: url(${Default});
  ${props =>
    props.thumbnail &&
    css`
      background-image: url(${props.thumbnail});
    `}
`;

export const Desc = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #202353;
  padding: 0 10px;
`;

export const ChipWrapper = styled.div`
  text-align: start;
  padding-right: 15px;
`;

export const ChipText = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #818da1;
  text-transform: uppercase;
  padding: 5px 8px;
  background: #f1f1f1;
  border-radius: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
  display: inline-block;
`;

export const LevelLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #222222;
`;

export const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 25px);
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  .category-actions-dropdown {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .evf-dropdown--basic {
    .evf-dropdown__menu {
      min-width: 141px;
    }
    .evf-dropdown__option {
      padding-left: 15px;
      padding-right: 15px;
      svg {
        width: 15px;
        margin-right: 10px;
        path,
        rect {
          stroke: white;
        }

        &.stroke-unset {
          path,
          rect {
            stroke: unset;
          }
        }
      }
    }
  }

  .category-actions-dropdown {
    &.open {
      .trigger-icon {
        background: #eaeef1;
      }
    }

    .evf-dropdown__menu {
      background-color: transparent;
      transform: translateX(28px) translateY(-2px);

      &.last_item {
        .dropdown-arrow {
          display: none;
        }
      }
    }

    .dropdown-area {
      padding: 6px 0;
      background: #2d2e2d;
      border-radius: 5px;
    }

    .dropdown-arrow {
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      right: 36.5px;
      top: 2.5px;
      background: #2d2e2d;
      border-top: 1px solid #363636;
      border-left: 1px solid #363636;
      transform: rotateZ(45deg);
    }
  }
`;

export const DurationLabel = styled(LevelLabel)``;
export const MostRecentLabel = styled(LevelLabel)``;

export const TriggerIcon = styled(MenuTrigger)`
  background: transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  .dot,
  .dot::before,
  .dot::after {
    background-color: #728096;
  }

  &:hover {
    background: #dadfea;

    .dot,
    .dot::before,
    .dot::after {
      background-color: #000000;
    }
  }
`;

export const VideoIcon = styled.div`
  background-image: url(${SmallVideo});
  width: 18px;
  height: 18px;
  position: absolute;
  left: 5px;
  bottom: 5px;
  background-size: contain;
`;

export const NotFound = styled.div`
  margin: 40px;
  text-align: center;
  height: calc(100% - 290px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    width: 82.4px;
    height: 81px;
  }

  .text {
    margin-top: 22px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #9a9da6;
  }
`;

export const LoadingWrapper = styled.div`
  height: calc(100% - 51px);
  display: flex;
  justify-content: center;
  align-items: center;

  .loading--on-demand-workout {
    padding-top: unset;
  }
`;
