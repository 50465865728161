import React, { useEffect, useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import find from 'lodash/find';
import { diff } from 'deep-diff';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import { RootCloseWrapper } from 'react-overlays';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import { DateInput } from 'shared/FormControl';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

import { CDN_URL } from 'constants/commonData';

import {
  handleUploadProgress,
  updateProgressPhotoFilters,
  firstLoad,
  selectedPhoto,
} from 'redux/progress-photo/actions';
import CalendarIcon from 'assets/icons/form-response-calendar-icon.svg';
import ProgressPhotoUploadMultiple from './ProgressPhotoUploadMultiple';

import * as S from './style';

const AddProgressPhoto = props => {
  const {
    toggleModal,
    weightMetric,
    idBodyfatUnit,
    idBodyfatMetric,
    clientId,
    handleUploadProgress,
    loadingUpload,
    toggleConfirmModal,
    handleUploadSuccess,
    updateProgressPhotoFilters,
    firstLoad,
    isFromEdit = false,
    selectedPhoto,
    timezone,
  } = props;

  const defaultData = {
    client: clientId,
    metric_code: 'progress_photo',
    date: moment().tz(timezone).toISOString(),
    value: '',
    unit: get(weightMetric, 'id', ''),
    tags: [],
    other_metrics: [
      {
        unit: idBodyfatUnit,
        body_metric: idBodyfatMetric,
        value: '',
      },
    ],
  };

  const [data, setData] = useState(defaultData);
  const [isChanged, setIsChanged] = useState(false);
  const [isUploadImage, setIsUploadImage] = useState(true);
  const [startDate, setStartDate] = useState(timezone ? moment().tz(timezone) : moment());
  const [error, setError] = useState({});
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const originalData = defaultData;

  useEffect(() => {
    if (diff(pick(originalData, 'tags'), pick(data, 'tags'))) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [data]);

  useEffect(() => {
    const handlePopstate = () => {
      toggleModal && toggleModal(false);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const renderDay = (props, currentDate) => {
    const currDate = currentDate.date();
    return (
      <td {...props}>
        <div>{currDate < 10 ? '0' + currDate : currDate}</div>
      </td>
    );
  };

  const isValidateDate = current => {
    const today = moment();
    return current.isBefore(today);
  };

  const handleChangeDate = newDate => {
    setStartDate(newDate);
    setData({ ...data, date: newDate.toISOString() });
  };

  const renderDateTimeInput = props => {
    return (
      <DateInput
        {...props}
        text={startDate.format('MMM DD, YYYY')}
        isIcon={CalendarIcon}
        isIconRtl
        className="progress-photo-calender"
      />
    );
  };

  const onErrorMessageMaxLength = errMessage => {
    setError({ ...error, max_length_photos: errMessage });
  };

  const handleChangeValueUnit = (value, name) => {
    const newValue = get(value, 'value');
    const floatValue = get(value, 'floatValue');

    if (name === 'weight') {
      setError({ ...error, weight_empty: false });
      if (floatValue >= 1000) {
        setData({ ...data, value: '999.99' });
      } else {
        setData({ ...data, value: newValue });
      }
    } else if (name === 'bodyFat') {
      setError({ ...error, body_fat_empty: false });
      if (floatValue >= 100) {
        setData({
          ...data,
          other_metrics: [{ ...data.other_metrics[0], value: '99.99' }],
        });
      } else {
        setData({
          ...data,
          other_metrics: [{ ...data.other_metrics[0], value: newValue }],
        });
      }
    }
  };

  const getListImages = list => {
    const newArray = map(list, obj => (obj.tag !== '' ? pick(obj, ['image', 'tag']) : pick(obj, ['image'])));
    setData({ ...data, tags: newArray });
  };

  const checkValidateData = newData => {
    const weightEmpty = isEmpty(newData.value);
    const bodyFatEmpty = isEmpty(newData.other_metrics[0].value);
    if (!weightEmpty || !bodyFatEmpty) {
      const weightValue = parseFloat(newData.value) < 1 ? true : false;
      const bodyFatValue = parseFloat(newData.other_metrics[0].value) < 0.1 ? true : false;
      setError({
        ...error,
        weight_empty: weightValue,
        body_fat_empty: bodyFatValue,
      });
      return !(weightValue || bodyFatValue);
    }
    return weightEmpty || bodyFatEmpty;
  };

  const isUploadingImages = value => {
    if (isArray(value)) {
      setIsUploadImage(value.length > 0);
    }
  };

  const handleBackWhenClose = () => {
    if (isFromEdit) {
      updateProgressPhotoFilters &&
        updateProgressPhotoFilters(false, {
          day: timezone
            ? DateTime.now().setZone(timezone).toFormat('MM-dd-yyyy')
            : DateTime.now().toFormat('MM-dd-yyyy'),
        });
      firstLoad && firstLoad();
      setTimeout(() => {
        handleUploadSuccess && handleUploadSuccess();
      }, 300);
    } else {
      toggleModal(false);
    }
  };

  const handleCloseModal = () => {
    if (isChanged) {
      toggleConfirmModal &&
        toggleConfirmModal(
          true,
          <S.CustomConfirmModal
            headerIcon={`${CDN_URL}/images/warning_purple.svg`}
            title={`Discard Changes?`}
            content={`Are you sure you want to go? Changes have not been saved yet.`}
            onConfirm={() => {
              handleBackWhenClose();
            }}
            onDeny={() => {}}
            confirmButtonTitle="OK"
            noBorder
            hasCloseIcon
            noIcon
            className="progress-photo-confirm-modal"
          />,
        );
    } else {
      handleBackWhenClose();
    }
  };

  const handleSubmit = () => {
    let newData = data || {};
    if (checkValidateData(newData)) {
      newData.value = isEmpty(newData.value) ? 0 : newData.value;
      newData.other_metrics[0].value = isEmpty(newData.other_metrics[0].value) ? 0 : newData.other_metrics[0].value;
      handleUploadProgress &&
        handleUploadProgress(newData, () => {
          updateProgressPhotoFilters &&
            updateProgressPhotoFilters(false, {
              day: timezone
                ? DateTime.now().setZone(timezone).toFormat('MM-dd-yyyy')
                : DateTime.now().toFormat('MM-dd-yyyy'),
            });
          firstLoad && firstLoad();
          selectedPhoto && selectedPhoto({ day: moment(get(newData, 'date', '')).tz(timezone).format('MM-DD-YYYY') });
          handleUploadSuccess && handleUploadSuccess();
        });
    }
  };

  return (
    <S.AddProgressPhoto
      open={true}
      closeOnDimmerClick={false}
      onClose={handleCloseModal}
      className="new-add-progress-photo"
      data-progress-photo="upload-photo"
    >
      <Modal.Header>
        <CloseButton className="close-button" onClick={handleCloseModal}>
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
        <S.HeaderWrapper>
          <S.HeaderTitle>Upload Progress Photo</S.HeaderTitle>
          <RootCloseWrapper
            event="click"
            onRootClose={() => {
              setIsOpenCalendar(false);
            }}
          >
            <S.DatePicker>
              <Datetime
                open={isOpenCalendar}
                onFocus={() => {
                  setIsOpenCalendar(true);
                }}
                onBlur={() => {
                  setIsOpenCalendar(false);
                }}
                value={startDate}
                timeFormat={false}
                className="new-ui"
                closeOnSelect={true}
                renderDay={renderDay}
                isValidDate={isValidateDate}
                onChange={newDate => handleChangeDate(newDate)}
                renderInput={props => renderDateTimeInput({ ...props })}
              />
            </S.DatePicker>
          </RootCloseWrapper>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <ProgressPhotoUploadMultiple
            onErrorMessage={onErrorMessageMaxLength}
            getListImages={getListImages}
            isUploadingImages={isUploadingImages}
          />
          <S.MetricsWrapper>
            <S.MetricItem>
              <S.MetricName>Weight</S.MetricName>
              <S.MetricWeight isError={error && error.weight_empty}>
                <NumberFormat
                  className="progress-photo-weight-input"
                  onValueChange={e => handleChangeValueUnit(e, 'weight')}
                  value={get(data, 'value') === 0 ? '' : get(data, 'value')}
                  allowNegative={false}
                  decimalSeparator="."
                  isNumericString={true}
                  decimalScale={get(data, 'value') > 1000 ? 0 : 2}
                  maxLength={get(data, 'value') > 1000 ? 4 : null}
                />
                <S.MetricUnit>{get(weightMetric, 'title', '')}</S.MetricUnit>
              </S.MetricWeight>
            </S.MetricItem>
            <S.MetricItem>
              <S.MetricName>Body Fat</S.MetricName>
              <S.MetricWeight isError={error && error.body_fat_empty}>
                <NumberFormat
                  className="progress-photo-weight-input"
                  onValueChange={e => handleChangeValueUnit(e, 'bodyFat')}
                  value={get(data, 'other_metrics[0].value') === 0 ? '' : get(data, 'other_metrics[0].value')}
                  allowNegative={false}
                  decimalSeparator="."
                  decimalScale={get(data, 'other_metrics[0].value') > 100 ? 0 : 2}
                  maxLength={get(data, 'other_metrics[0].value') > 100 ? 3 : null}
                  isNumericString={true}
                />
                <S.MetricUnit>%</S.MetricUnit>
              </S.MetricWeight>
            </S.MetricItem>
          </S.MetricsWrapper>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ActionWrapper>
          <S.ActionsError>
            {error && error.max_length_photos && <S.ErrorContent>{error.max_length_photos}</S.ErrorContent>}
          </S.ActionsError>
          <S.ActionsButton
            disabled={!isChanged || isUploadImage || loadingUpload}
            isSave={isChanged && !isUploadImage && !loadingUpload}
            onClick={handleSubmit}
          >
            <S.ButtonTitle>Upload</S.ButtonTitle>
          </S.ActionsButton>
        </S.ActionWrapper>
      </Modal.Actions>
    </S.AddProgressPhoto>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      client: {
        bodymetricTypes,
        selected,
        workingClientDetail: { timezone },
      },
      progressPhoto: { loadingUpload },
    },
  } = state;
  const weightMetric = get(
    find(bodymetricTypes, it => it.unique_code === 'weight'),
    'unit',
    [],
  );
  const bodyFatMetric = get(
    find(bodymetricTypes, it => it.unique_code === 'body_fat'),
    'category.units',
    [],
  );
  const idBodyfatMetric = get(
    find(bodymetricTypes, it => it.unique_code === 'body_fat'),
    '_id',
    '',
  );
  const idBodyfatUnit = get(bodyFatMetric, '[0]._id', '');

  return { weightMetric, idBodyfatUnit, idBodyfatMetric, clientId: selected, loadingUpload, timezone };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  handleUploadProgress: bindActionCreators(handleUploadProgress, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updateProgressPhotoFilters: bindActionCreators(updateProgressPhotoFilters, dispatch),
  firstLoad: bindActionCreators(firstLoad, dispatch),
  selectedPhoto: bindActionCreators(selectedPhoto, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProgressPhoto);
