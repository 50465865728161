import React, { useState, useRef, useEffect } from 'react';
import { Modal, Image } from 'semantic-ui-react';
import diff from 'deep-diff';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AsyncSelect from 'react-select/lib/Async';
import Avatar from 'react-avatar';
import { Helmet } from 'react-helmet';

import {
  getUserShortName,
  timeSince,
  getWorkoutSummary,
  pluralize,
  formatShortLink,
  isTeamAdmin,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';

import { ReactComponent as WorkoutSearchIcon } from 'assets/icons/on-demand-workout-input-search.svg';
import { getWorkoutLibrary, updateOnDemandWorkouts } from 'redux/on-demand-workouts/actions';
import { toggleConfirmModal } from 'actions/modal';
import {
  ON_DEMAND_WORKOUT_TYPE,
  ON_DEMAND_WORKOUT_MODAL_MODE,
  TEAM_SHARE_PRIVATE,
  TEAM_SHARE_NOOWNER,
  CDN_URL,
} from 'constants/commonData';
import { autoAddHttps, validateLink } from 'utils/validations';
import { getPreSignedUrl } from 'redux/workout-collection/actions';

import Header from './Header';
import SelectedWorkout from './SelectedWorkout';
import Labels from './Labels';
import WorkoutInfo from './WorkoutInfo';
import LinkVideo from './LinkVideo';

import * as S from './style';
import { NO_OWNER_SHARED_OPTION, generateParams } from 'shared/AssetsShareSetting/constants';

const { EDIT: EDIT_MODE, ADD: ADD_MODE, DUPLICATE: DUPLICATE_MODE } = ON_DEMAND_WORKOUT_MODAL_MODE;

const handleGetCurrentWorkout = (
  workout,
  name,
  type,
  description,
  background,
  level,
  duration,
  labels,
  selectedWorkout,
  link,
  authorId,
  share,
) => {
  // TODO - Handle get current workout
  let formData = {
    ...workout,
    title: name,
    type: type,
    description: description,
    background: background,
    level: level,
    duration: duration,
    authorId,
    share,
  };

  formData.labels = labels.map(label => label.item.value);

  if (type === ON_DEMAND_WORKOUT_TYPE.REGULAR) {
    formData.original_workout = selectedWorkout && selectedWorkout._id;
  } else {
    formData.video_link = link && link.link;
  }

  return formData;
};

const handleDuplicateWorkoutName = name => {
  let newName = name;
  if (newName.length > 30) {
    newName = newName.slice(0, 30);
  }
  return newName;
};

function OnDemandWorkoutModal({
  workout = {},
  isModalOpen,
  onClose,
  type,
  mode,
  getWorkoutLibrary,
  categoryLabels,
  addNewOnDemandWorkouts,
  customBrandingBackground,
  updateOnDemandWorkouts,
  toggleConfirmModal,
  userId,
  user = {},
  cloudfrontList,
}) {
  const selectRef = useRef(null);
  const [selectedWorkout, setSelectedWorkout] = useState(
    (mode === EDIT_MODE || mode === DUPLICATE_MODE) && type === ON_DEMAND_WORKOUT_TYPE.REGULAR
      ? {
          title: workout.original_title,
          _id: workout.original_workout,
          sections: workout.sections,
          ...(workout.original_background &&
            !get(workout, 'background') && { background: workout.original_background }),
        }
      : null,
  );
  const [name, setName] = useState(
    mode === DUPLICATE_MODE
      ? handleDuplicateWorkoutName(`Copy of ${get(workout, 'title', '')}`)
      : get(workout, 'title', ''),
  );
  const [description, setDescription] = useState(get(workout, 'description', ''));
  const [background, setBackground] = useState(get(workout, 'background', null));
  const [link, setLink] = useState(
    workout && workout.video_link
      ? { url: workout.video_link, link: workout.video_link, thumbnail_url: workout.background }
      : null,
  );
  const [level, setLevel] = useState(mode === EDIT_MODE || mode === DUPLICATE_MODE ? workout.level.item._id : null);
  const [duration, setDuration] = useState(
    mode === EDIT_MODE || mode === DUPLICATE_MODE ? workout.duration.item._id : null,
  );
  const [labels, setLabels] = useState(
    mode === EDIT_MODE || mode === DUPLICATE_MODE
      ? workout.labels.map(label => ({
          item: {
            label: label.item.name,
            value: label.item._id,
          },
          label: label.label,
        }))
      : [],
  );
  const [shouldShowBackground, setShouldShowBackground] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [editOriginalWorkout, setEditOriginalWorkout] = useState(false);
  const [lastSelectedWorkout, setLastSelectedWorkout] = useState(null);
  const [videoLinkError, setVideoLinkError] = useState(false);
  const [ownership, setOwnership] = useState(user._id);
  const [shareStatus, setShareStatus] = useState(workout.share || TEAM_SHARE_PRIVATE);

  const author = mode === EDIT_MODE ? (typeof workout.author === 'object' ? workout.author : undefined) : user;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = workout.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;

  useEffect(() => {
    if (mode === EDIT_MODE) {
      if (shareStatus === TEAM_SHARE_NOOWNER || author === null) {
        setOwnership(NO_OWNER_SHARED_OPTION.value);
      } else {
        setOwnership(authorId);
      }
    }

    if (mode === DUPLICATE_MODE) {
      setOwnership(user._id);
      setShareStatus(TEAM_SHARE_PRIVATE);
    }
  }, []);

  // Todo - Check discard change value
  useEffect(() => {
    const levelLabel = categoryLabels.find(label => label.unique_code === 'level');
    const defaultLevelValue = get(levelLabel, 'items[0]._id', '');
    const durationLabel = categoryLabels.find(label => label.unique_code === 'duration');
    const defaultDurationValue = get(durationLabel, 'items[0]._id', '');

    const originalNewWorkout = cloneDeep({
      background: null,
      description: '',
      duration: defaultDurationValue,
      labels: [],
      level: defaultLevelValue,
      title: '',
      type,
      authorId: user._id,
      share: TEAM_SHARE_PRIVATE,
    });
    const originalWorkout = !isEmpty(workout)
      ? cloneDeep({
          ...workout,
          level: workout.level ? get(workout.level, 'item._id', null) : null,
          duration: workout.duration ? get(workout.duration, 'item._id', null) : null,
          labels: workout.labels ? workout.labels.map(item => get(item, 'item._id', null)) : null,
          background: get(workout, 'background', null),
          authorId: (workout.author || {})._id,
        })
      : cloneDeep(
          type === ON_DEMAND_WORKOUT_TYPE.REGULAR
            ? { ...originalNewWorkout, original_workout: null }
            : { ...originalNewWorkout, video_link: null },
        );

    const currentWorkoutAuthorId = ownership !== NO_OWNER_SHARED_OPTION.value ? ownership : (workout.author || {})._id;
    const currentWorkout = handleGetCurrentWorkout(
      workout,
      name,
      type,
      description,
      background,
      level,
      duration,
      labels,
      selectedWorkout,
      link,
      currentWorkoutAuthorId,
      shareStatus,
    );

    const haveChange = diff(originalWorkout, currentWorkout) ? true : false;
    setIsChange(haveChange);
  }, [name, type, description, background, level, duration, labels, selectedWorkout, link, ownership, shareStatus]);

  useEffect(() => {
    // for setting last selected workout
    if (selectedWorkout) {
      setLastSelectedWorkout(selectedWorkout);
    }
  }, [selectedWorkout]);

  useEffect(() => {
    // for error handling
    let errorArray = [];
    if (name === '' || name.length > 30) {
      errorArray.push('name');
    }
    if (selectedWorkout === null && type === ON_DEMAND_WORKOUT_TYPE.REGULAR) {
      errorArray.push('workout');
    }
    if ((link === null || !validateLink(link.link) || videoLinkError) && type === ON_DEMAND_WORKOUT_TYPE.VIDEO) {
      errorArray.push('link');
    }
    setErrors(errorArray);
  }, [name, selectedWorkout, link]);

  useEffect(() => {
    if (categoryLabels.length > 0 && mode === ADD_MODE) {
      const levelLabel = categoryLabels.find(label => label.unique_code === 'level');
      const defaultLevelValue = levelLabel.items[0]._id;
      setLevel(defaultLevelValue);
      const durationLabel = categoryLabels.find(label => label.unique_code === 'duration');
      const defaultDurationValue = durationLabel.items[0]._id;
      setDuration(defaultDurationValue);
    }
  }, [categoryLabels]);

  useEffect(() => {
    if (selectedWorkout) {
      setShouldShowBackground(true);
      const selectedWorkoutBackground = get(selectedWorkout, 'background');
      if (selectedWorkoutBackground) {
        setBackground(selectedWorkoutBackground);
      }
      return;
    } else {
      setBackground(null);
    }
    if (link && validateLink(link.link)) {
      setShouldShowBackground(true);
      const linkThumbnail = get(link, 'thumbnail_url');
      if (linkThumbnail) {
        setBackground(linkThumbnail);
      }
      return;
    } else {
      setBackground(null);
    }
    setShouldShowBackground(false);
  }, [selectedWorkout, link]);

  const searchWorkouts = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    getWorkoutLibrary({ textSearch: search, page: 1, per_page: 20, sorter: 'last_interacted' })
      .then(response => {
        const { data } = response.data;

        // TODO: SHOULD investigate
        if (data.list) {
          callback(data.list);
        } else {
          callback([]);
        }
      })
      .catch(() => {
        callback([]);
      });
  };

  const debounceSearchWorkout = debounce(searchWorkouts, 300);

  const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    const { sections, exercises } = getWorkoutSummary(data);

    return (
      <S.CustomOption ref={innerRef} {...innerProps}>
        <div className="base-info-container">
          <div className="workout-title">{data.title}</div>
          <div className="workout-info">
            {exercises} {pluralize('Exercise', exercises)} • {sections} {pluralize('Section', sections)}
          </div>
        </div>
        <div className="extra-info-container">
          <Avatar
            name={getUserShortName(data.author)}
            className="author-avatar"
            size="24"
            src={convertS3UrlToCloudFrontUrl(data.author.avatar, cloudfrontList, true)}
            color={data.author.color}
          />
          {!!data.last_interacted && <div className="last-used">{timeSince(new Date(data.last_interacted))}</div>}
        </div>
      </S.CustomOption>
    );
  };
  const handleSelectWorkout = newWorkout => {
    if (!name) {
      handleUpdateName(newWorkout.title);
    }
    if (!description) {
      handleUpdateDescription(newWorkout.description);
    }
    if (mode === EDIT_MODE) {
      setEditOriginalWorkout(true);
    }

    setSelectedWorkout(newWorkout);
  };
  const handleUnselectWorkout = () => {
    setSelectedWorkout(null);
    setLastSelectedWorkout(null);
  };
  const handleChangeLevel = value => {
    setLevel(value);
  };
  const handleChangeDuration = value => {
    setDuration(value);
  };
  const handleEditWorkout = () => {
    setSelectedWorkout(null);
    setTimeout(() => {
      if (selectRef.current) selectRef.current.focus();
    }, 0);
  };
  const handleCloseLink = () => {
    setBackground(null);
    setLink(null);
  };
  const handleLinkDataUpdate = newLinkData => {
    if (newLinkData) {
      const { name, thumbnail_url, url } = newLinkData[0];
      const newLinkObj = {
        original_name: name,
        link: formatShortLink(url),
        thumbnail_url: thumbnail_url,
        type: 5,
      };
      if (!validateLink(url)) {
        // block save button when link validate fail
        // props.startUploadAttachment();
        startUploadAttachment();
      } else {
        endUploadAttachment();
        // props.endUploadAttachment();
      }
      setLink(newLinkObj);
      // props.onUpdateAttachments([newLinkObj]);

      // TODO - update new data
      // let _newData = newData;
      // _newData.video_link = url;
      // _newData.background = thumbnail_url;
      // setNewData(_newData);
    } else {
      setLink(null);
      // props.onUpdateAttachments([]);
      // props.endUploadAttachment();
      endUploadAttachment();
    }
  };
  const startUploadAttachment = () => {
    setIsUploading(true);
  };
  const endUploadAttachment = () => {
    setIsUploading(false);
  };

  const handleAddNewSubmit = () => {
    setShowErrors(true);
    if (errors.length > 0) {
      return;
    }
    const paramsShare = generateParams({ ownership, shareStatus });
    var formData = {
      title: name,
      type: type,
      description: description,
      background: background,
      level: level,
      duration: duration,
      ...(editOriginalWorkout && { edit_original_workout: true }),
      owner: paramsShare.owner,
      share: paramsShare.share,
    };
    formData.labels = labels.map(label => label.item.value);
    if (type === ON_DEMAND_WORKOUT_TYPE.REGULAR) {
      formData.original_workout = selectedWorkout._id;
    } else {
      formData.video_link = link.link;
      // logic auto add https on attachments type link video
      formData.video_link = autoAddHttps(formData.video_link);
    }
    setIsUploading(true);
    switch (mode) {
      case EDIT_MODE:
        updateOnDemandWorkouts(formData, workout._id).then(res => {
          if (res) onClose();
        });
        break;
      case ADD_MODE:
        addNewOnDemandWorkouts(formData).finally(() => {
          onClose();
        });
        break;
      case DUPLICATE_MODE:
        addNewOnDemandWorkouts(formData).finally(() => {
          onClose();
        });
        break;
      default:
        break;
    }
  };

  const handleUpdateDescription = description => {
    setDescription(description);
  };
  const handleUpdateName = value => {
    setName(value);
  };
  const handleUpdateBackground = url => {
    setBackground(url);
  };
  const handleFieldFocus = () => {
    setShowErrors(false);
  };
  const handleStartUploading = () => {
    setIsUploading(true);
  };
  const handleEndUploading = () => {
    setIsUploading(false);
  };
  const handleChangeLabel = (option, replace = false) => {
    if (!replace) {
      return setLabels([...labels, option]);
    } else {
      let clonedLabels = cloneDeep(labels);
      for (let i = 0; i < clonedLabels.length; i++) {
        if (clonedLabels[i].label._id === option.label._id) {
          clonedLabels[i] = option;
          return setLabels(clonedLabels);
        }
      }
    }
  };
  const handleUnselectLabel = option => {
    let clonedLabels = cloneDeep(labels);
    remove(clonedLabels, label => label.label._id === option.label._id);
    setLabels(clonedLabels);
  };

  const handleRemoveSavedLabel = labelId => {
    let clonedLabels = cloneDeep(labels);
    remove(clonedLabels, label => label.label._id === labelId);
    setLabels(clonedLabels);
  };

  // TODO: Handle close modal
  const handleCloseModal = () => {
    if (isChange) handleDiscardChange();
    else onClose && onClose();
  };

  const handleDiscardChange = () => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={() => {
            onClose && onClose();
          }}
          onDeny={() => {}}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    return false;
  };

  const handleBlurWorkoutSelect = () => {
    if (lastSelectedWorkout && !selectedWorkout) {
      handleSelectWorkout(lastSelectedWorkout);
    }
  };

  const handleChangeOwnershipStatus = ({ author, share }) => {
    setOwnership(author);
    setShareStatus(share);
  };

  return (
    <S.ModalWrapper
      open={isModalOpen}
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </button>
      }
    >
      <Helmet>
        <title>
          {mode === EDIT_MODE && workout.title
            ? `${workout.title} - On-demand Workout - Everfit `
            : 'On-demand Workout - Everfit'}
        </title>
      </Helmet>
      <div className="header">
        <Header
          name={name}
          onUpdateName={handleUpdateName}
          background={background}
          shouldShowBackground={shouldShowBackground}
          getPreSignedUrl={getPreSignedUrl}
          onUpdateBackground={handleUpdateBackground}
          customBrandingBackground={customBrandingBackground}
          errors={errors}
          cloudfrontList={cloudfrontList}
          showErrors={showErrors}
          onNameFocus={handleFieldFocus}
          onStartUploading={handleStartUploading}
          onEndUploading={handleEndUploading}
          user={user}
          author={author}
          shareStatus={shareStatus}
          handleChangeOwnershipStatus={handleChangeOwnershipStatus}
          isCreatorOrOwnerOrAdmin={isCreatorOrOwnerOrAdmin}
        />
      </div>
      <Modal.Content>
        <WorkoutInfo
          description={description}
          onUpdateDescription={handleUpdateDescription}
          disabled={!isCreatorOrOwnerOrAdmin}
        />
        {type === ON_DEMAND_WORKOUT_TYPE.REGULAR ? (
          <>
            <S.Title>Add Workout</S.Title>
            <S.AddWorkoutContainer>
              <div className="text">Copy a workout from your Workout library</div>
              {!selectedWorkout ? (
                <>
                  <AsyncSelect
                    ref={selectRef}
                    defaultOptions
                    cacheOptions
                    onBlur={handleBlurWorkoutSelect}
                    onFocus={handleFieldFocus}
                    openMenuOnFocus
                    loadOptions={(inputValue, callback) => debounceSearchWorkout.call(this, inputValue, callback)}
                    components={{
                      DropdownIndicator: null,
                      Option: CustomOption,
                      LoadingIndicator: null,
                    }}
                    placeholder={
                      <S.CustomPlaceholderContainer>
                        <WorkoutSearchIcon />
                        <span>Search by workout name</span>
                      </S.CustomPlaceholderContainer>
                    }
                    styles={{
                      control: () => {
                        if (errors.includes('workout') && showErrors) {
                          return {
                            borderColor: '#ea314a !important',
                            backgroundColor: '#fef4f6 !important',
                            '&:hover': {
                              borderColor: '#ea314a !important',
                            },
                          };
                        }
                        return {};
                      },
                    }}
                    classNamePrefix="workout-select"
                    onChange={handleSelectWorkout}
                    noOptionsMessage={() => 'No result'}
                    isDisabled={!isCreatorOrOwnerOrAdmin}
                  />
                </>
              ) : (
                <SelectedWorkout
                  selectedWorkout={selectedWorkout}
                  onEditWorkout={handleEditWorkout}
                  cloudfrontList={cloudfrontList}
                  onUnselectWorkout={handleUnselectWorkout}
                  background={background}
                  customBrandingBackground={customBrandingBackground}
                  disabled={!isCreatorOrOwnerOrAdmin}
                />
              )}
            </S.AddWorkoutContainer>
          </>
        ) : (
          <>
            <S.Title>Add Video</S.Title>
            <S.AddVideoContainer>
              <LinkVideo
                errors={errors}
                showErrors={showErrors}
                onFocus={handleFieldFocus}
                onClose={handleCloseLink}
                background={background}
                attachment={link}
                onUpdateAttachmentData={handleLinkDataUpdate}
                updateUploadStatus={() => console.log('updateUpload status ran')}
                onStartFetchingMeta={startUploadAttachment}
                onEndFetchingMeta={endUploadAttachment}
                setVideoLinkError={setVideoLinkError}
                disabled={!isCreatorOrOwnerOrAdmin}
              />
            </S.AddVideoContainer>
          </>
        )}

        <Labels
          categoryLabels={categoryLabels}
          workout={workout}
          level={level}
          duration={duration}
          onChangeLevel={handleChangeLevel}
          onChangeDuration={handleChangeDuration}
          onChangeLabel={handleChangeLabel}
          onUnselectLabel={handleUnselectLabel}
          selectedLabels={labels}
          handleRemoveSavedLabel={handleRemoveSavedLabel}
          userId={userId}
          disabled={!isCreatorOrOwnerOrAdmin}
        />
      </Modal.Content>
      <Modal.Actions>
        <S.CancelButton onClick={handleCloseModal}>Cancel</S.CancelButton>
        <S.SubmitButton
          purple
          disabled={!isCreatorOrOwnerOrAdmin || isUploading || videoLinkError}
          onClick={handleAddNewSubmit}
        >
          {mode === ADD_MODE ? 'Add Workout' : 'Save'}
        </S.SubmitButton>
      </Modal.Actions>
    </S.ModalWrapper>
  );
}

const mapState = state => ({
  user: state.user,
  isModalOpen: state.isModalOpen,
  cloudfrontList: state.cloudfrontList,
  customBrandingBackground: state.rootReducer.customBranding.selectedWorkoutBackground,
  categoryLabels: get(state.rootReducer.categoryLabels, 'list', []),
  userId: state.user._id,
});

const mapDispatch = dispatch => ({
  getWorkoutLibrary: bindActionCreators(getWorkoutLibrary, dispatch),
  getPreSignedUrl: bindActionCreators(getPreSignedUrl, dispatch),
  updateOnDemandWorkouts: bindActionCreators(updateOnDemandWorkouts, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapState, mapDispatch)(OnDemandWorkoutModal);
