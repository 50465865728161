import styled, { css } from 'styled-components';

export const Name = styled.div`
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  width: 100%;
  max-width: 129px;
`;

export const SegmentItem = styled.div`
  ${props =>
    props.showRedDot &&
    css`
      .left-content {
        position: relative;

        :after {
          content: '';
          position: absolute;
          right: -11px;
          bottom: 4px;
          background: #ff4545;
          border-radius: 50%;
          z-index: 1;
          width: 6px;
          height: 6px;
        }
      }
    `}
`;

export const Container = styled.div`
  height: calc(100% - 72px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 80px;

  .segment__show-more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    padding: 0 15px 0 13px;

    :hover {
      text-decoration: underline;
    }

    i.icon {
      font-size: 10px;
      margin-left: 6px;
    }
  }

  .segments {
    margin-bottom: 32px;

    .segments__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.29px;
      padding: 0 15px 0 13px;
      margin-bottom: 14px;

      img {
        cursor: pointer;
      }
    }
  }

  .segment-avatar {
    font-weight: 600;
  }

  .divide {
    margin: 0 15px 7px 13px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .right-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5px;

    .count {
      width: 24px;
      text-align: right;
    }

    .segment__active-icon {
      display: none;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      cursor: pointer;

      img.hover {
        display: none !important;
      }

      :hover {
        img {
          :not(.hover) {
            display: none !important;
          }
          &.hover {
            display: block !important;
          }
        }
      }
    }
  }

  .menu-list-item:hover {
    .segment__active-icon {
      display: flex;
    }

    .segment-avatar {
      &.show-eye-icon {
        display: none !important;
      }
    }
  }
`;
