// Libs
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment';

// Components
import TrialAddOn from 'components/ClientMealPlan/components/TrialAddOn';
import { checkPastDateForTrial } from 'components/PricingPlan/helper';

// Helper
import { getTimeRemain } from 'components/ClientMealPlan/helper';

// Constants
import { ADD_ONS_PRICING_CODE } from 'components/PricingPlan/constants';

// Style
import { Wrapper } from './style';

const MealPlanHeaderLibrary = ({ beta_date, title, type = '' }) => {
  const renderTrialAddOn = () => {
    if (!beta_date || checkPastDateForTrial(beta_date, moment.tz.guess())) return;
    return (
      <TrialAddOn
        className="trial-add-on__button"
        description={
          type === 'recipe'
            ? `You're on a trial plan. When it ends, system recipes will be deactivated. You can upgrade for the full access now.`
            : `Upgrade to continue using Meal Plan Templates to power your nutrition coaching`
        }
        btnContentText="GET MEAL PLANS ADD-ON"
        title={
          <div>
            {getTimeRemain(beta_date)} <span style={{ fontWeight: 400 }}>until trial ends</span>
          </div>
        }
      />
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{title ? `${title} - Everfit` : ''}</title>
      </Helmet>
      <div className="meal-plan-title">
        {title || ''}
        {renderTrialAddOn()}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { pricing },
  } = state;
  const addOnsPricing = pricing.get('addOnsPricing').toJS() || [];
  const mealPlanAddOn = addOnsPricing.find(it => it.code === ADD_ONS_PRICING_CODE.MEAL_PLAN);

  const { beta_date } = mealPlanAddOn || {};

  return {
    beta_date,
  };
};

export default connect(mapStateToProps, null)(MealPlanHeaderLibrary);
