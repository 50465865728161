import React from 'react';

import { Modal, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { CDN_URL } from 'constants/commonData';

import PackagePublishFallbackImg from 'assets/images/package-publish-fallback.png';
import { ModalWrapper, SUpgradePathButton, NoAccessTitle, NoAccessDescription, Content } from './style';

class PackagePublishFallbackPopup extends React.Component {
  state = {};

  onClose = () => {
    if (this.props.isSecondModalOpen) {
      this.props.toggleSecondModal(false);
    } else {
      this.props.toggleModal(false);
    }
  };

  handleClickUpgradeButton = () => {
    this.props.onSaveDraftUpgrade(null);
    this.onClose();
  };

  render() {
    const des =
      this.props.description ||
      'Your team has reached the max number of clients for your subscribed plan. To add more clients, please archive some clients first or increase your plan size.';
    return (
      <ModalWrapper
        src={PackagePublishFallbackImg}
        open={this.props.isSecondModalOpen || this.props.isModalOpen}
        onClose={this.onClose}
        closeOnDimmerClick={false}
        closeIcon={
          <button className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </button>
        }
      >
        <Modal.Content>
          <div className="upgradeModal__upgradeBtn">
            <Content>
              <NoAccessTitle>Payment & Packages</NoAccessTitle>
              <NoAccessDescription>
                Create one-time or recurring packages to collect payment effortlessly for your training business!
              </NoAccessDescription>
            </Content>
            <SUpgradePathButton text="Get Payment Add-on" onClick={this.handleClickUpgradeButton} />
          </div>
        </Modal.Content>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { isModalOpen, isSecondModalOpen } = state;
  return {
    isModalOpen,
    isSecondModalOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagePublishFallbackPopup);
