import Request from 'configs/request';
import { omitEmptyRequestParams } from 'utils/commonFunction';

export const Types = {
  SETUP_PAYMENT_GET_ON_BOARDING_ONSUCCESS: 'SETUP_PAYMENT_GET_ON_BOARDING_ONSUCCESS',
  SETUP_PAYMENT_CONNECT_ACCOUNT_FETCHING: 'SETUP_PAYMENT_CONNECT_ACCOUNT_FETCHING',
  SETUP_PAYMENT_CONNECT_ACCOUNT_SUCCESS: 'SETUP_PAYMENT_CONNECT_ACCOUNT_SUCCESS',
  SETUP_PAYMENT_CONNECT_ACCOUNT_FAILED: 'SETUP_PAYMENT_CONNECT_ACCOUNT_FAILED',
  SETUP_PAYMENT_UPDATE_CURRENCY: 'SETUP_PAYMENT_UPDATE_CURRENCY',
};

export const VERIFY_TYPES = {
  DOCUMENT: 'document',
  ID: 'id_number',
};

export const getOnBoarding = params => {
  return Request.post({ url: '/api/payment/payment/get-onboarding-link', data: omitEmptyRequestParams(params) });
};

export const getConnectedAccount = () => {
  return dispatch => {
    dispatch({ type: Types.SETUP_PAYMENT_CONNECT_ACCOUNT_FETCHING });
    return dispatch(
      Request.get({ url: '/api/payment/payment/get-connected-account' }, true, (response, { dispatch }) => {
        const { data } = response.data;
        dispatch(
          {
            type: Types.SETUP_PAYMENT_CONNECT_ACCOUNT_SUCCESS,
            payload: data,
          },
          () => {
            dispatch({ type: Types.SETUP_PAYMENT_CONNECT_ACCOUNT_FAILED });
          },
        );
      }),
    );
  };
};

export const grantAccess = data => {
  return Request.patch({ url: '/api/payment/connect-account', data }, true, (response, { dispatch }) => {
    dispatch(getConnectedAccount());
  });
};

export const updateCurrency = prams => ({
  type: Types.SETUP_PAYMENT_UPDATE_CURRENCY,
  payload: prams,
});

export const getDashboardLink = () => {
  return Request.get({ url: '/api/payment/connect-account/get-dashboard-link' });
};

export const getVerifySessionLink = (type = VERIFY_TYPES.DOCUMENT) => {
  return Request.get({
    url: '/api/payment/connect-account/get-verify-session-link',
    params: {
      type: type,
    },
  });
};
