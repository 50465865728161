import styled, { css } from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';
import { Modal } from 'semantic-ui-react';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
`;

export const ModalWrapper = styled(Modal)`
  width: 688px !important;
  height: 700px !important;
  border-radius: 8px !important;

  .content {
    padding: 0 !important;
  }
`;

export const Header = styled.div`
  padding: 32px 32px 0 32px;
  margin-bottom: 0;
  flex-wrap: wrap;

  .habit-header-wrapper {
    margin-bottom: 32px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .habit-modal-title {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-right: 12px;
      }
      a {
        color: #5158cf !important;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .button-back {
      font-weight: 600;
      font-size: 18px;
      color: #202353;
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: 10px;
        cursor: pointer;
        path {
          stroke: #000 !important;
        }
      }
    }
  }

  .search-input {
    width: 100% !important;
    margin-bottom: 24px;
    height: 56px !important;

    > input {
      background-color: #f7f8fc !important;
      background-size: 24px !important;
      padding-left: 52px !important;
      font-size: 15px;

      ::placeholder {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400px;
        color: #7b7e91;
        opacity: 0.6;
      }

      :focus {
        background-color: transparent !important;
        border-color: #5c5bbd;
      }
    }

    svg {
      width: 15px !important;
      height: 15px !important;
    }
  }
`;

export const Wrapper = styled.div`
  max-height: 700px;
  padding: 0;
`;

export const Content = styled.div`
  padding: 0 24px 19px 32px;
  overflow: auto;
  margin-bottom: 0;
  max-height: 518px;

  .habit-wrapper {
    display: grid;
    padding-bottom: 24px;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  ::-webkit-scrollbar {
    width: 8px !important;
  }

  ${props =>
    props.noResult &&
    css`
      display: flex;
      justify-content: center;
      height: 392px;
      align-items: center;
    `}
`;

export const SectionWrapper = styled.div``;

export const SectionContent = styled.div`
  ${props =>
    props.disabled &&
    css`
      display: none;
    `}
`;

export const Section = styled.div`
  font-weight: 600;
  color: #6a778a;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const Item = styled.div`
  ${baseText}
  padding: 12px;
  box-shadow: 0px 8px 32px rgb(33 81 154 / 8%);
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  max-width: 200px;
  max-height: 62px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border: 1px solid #a09dff;
  }

  .close-button-item {
    display: none;
  }

  &:hover .close-button-item {
    width: 16px;
    display: inline-block;
    position: absolute;
    top: -11px;
    right: -8px;
  }
`;

export const ItemBox = styled.div`
  display: block;
  color: #bfbfbf;
  line-height: 10px;
  font-weight: 600;
  margin-left: 12px;
  overflow: hidden;
  .title-lib {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    max-width: 228px;
    padding: 15px;
    margin-top: -20px !important;
    margin-left: -20px !important;
    border-radius: 5px;
    overflow-wrap: break-word !important;
  }
`;

export const Icon = styled.span`
  display: flex;
  width: 32px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  ${baseText}
  color: #272830;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #273d52;
  margin-bottom: 4px;
  white-space: nowrap;
`;

export const Desc = styled.p`
  font-size: 11px;
  font-weight: 600;
  display: inline-flex;
  text-overflow: ellipsis;
  font-size: 11px;
  line-height: 15px;
  color: #bfbfbf;
  white-space: nowrap;
`;

export const NotFoundText = styled.div`
  color: #9a9da6;
  margin-top: 19px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #9a9da6;
  justify-content: center;
  display: flex;
`;

export const HabitConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 447px !important;

    .confirm-content-header {
      padding: 30px 0 0 30px !important;
    }

    .confirm-content-body {
      padding: 15px 30px 40px 30px !important;
    }
    .confirm-actions {
      .confirm-yes-button {
        ${baseText};
      }

      .confirm-no-button {
        ${baseText};
      }
    }
  }
`;
