import styled, { css } from 'styled-components';

const LIGHT_DARK = '#7B7E91';
const DARK_COLOR = '#1E0A3C';

const TextBase = css`
  font-family: 'Open Sans';
  color: #202353;
  font-size: 13px;
  font-weight: 600;
  line-height: 150%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  ${TextBase}
  align-items: center;
`;

export const BodyWrapper = styled.div`
  display: flex;
  padding: 0 25px 0 28px;
  flex-direction: column;
  margin: ${({ isSmallHeight }) => (isSmallHeight ? 'auto' : '100px 0 0')};
  align-items: center;
`;

export const CountDownName = styled.div`
  display: flex;
  font-size: 32px;
  word-break: break-all;
  line-height: 150%;
  text-align: center;
  justify-content: center;
`;

export const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 167px;
  position: relative;
  border-radius: 50%;
  margin-top: 12px;

  .icon-outer {
    opacity: 1;
    g {
      path {
        :first-child {
          fill: ${({ color }) => color.background};
        }

        :nth-child(2) {
          fill: ${({ color }) => color.secondBackground};
        }

        :nth-child(3) {
          fill: ${({ color }) => color.border};
        }
      }
    }

    linearGradient {
      stop {
        stop-color: ${({ color }) => color.border};
      }
    }
  }
`;

export const Circle = styled.div`
  position: absolute;
  background-color: transparent;
  z-index: 3;
  font-size: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const TimeNumber = styled.div`
  display: flex;
  flex-direction: column;

  .time-number {
    font-size: 32px;
    font-weight: 700;
    color: ${DARK_COLOR};
    text-align: center;
  }

  .time-label {
    font-size: 11px;
    color: ${LIGHT_DARK};
    text-transform: uppercase;
    text-align: center;
    margin-top: 7px;
  }

  :last-child {
    margin-left: -6px;
  }
`;

export const Colons = styled.div`
  font-size: 32px;
  font-weight: 400;
  color: ${DARK_COLOR};
`;

export const TimeCountdownWrapper = styled.div`
  display: flex;
  margin-top: 43px;
  margin-left: 7px;
  align-items: start;
  gap: 19px;

  &.time-counting-overview {
    margin-top: 0;
    margin-left: 0;
    gap: 0;

    .colons {
      font-size: 14px;
      line-height: 19.07px;
      font-weight: 600;
      color: #141414;
    }

    .time-number-label {
      &:nth-child(1) {
        width: 33px;
      }
      &:nth-child(3) {
        padding-right: 4px;
      }
      &:nth-child(5) {
        margin-left: -4px;
        min-width: 32px;
      }

      .time-number {
        font-size: 14px;
        line-height: 19.07px;
        font-weight: 600;
        color: #141414;
      }
      .time-label {
        font-size: 8px;
        color: #8c8c8c;
        line-height: 10.89px;
        margin-top: 3px;
      }
    }
  }
`;

export const MinuteNumber = styled.div`
  display: flex;
`;
