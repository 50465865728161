import styled, { css } from 'styled-components';

export const CompactInformationItem = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 5px 10px;
  margin-right: 5px;
  width: auto;
`;

export const Wrapper = styled(CompactInformationItem)`
  top: 10px;
  left: 10px;
  position: absolute;
  padding-left: 20px;

  :before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${props => props.statusColor};
    z-index: 1;
  }
`;
