// Libs
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image } from 'semantic-ui-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import animateScrollTo from 'animated-scroll-to';

// Actions
import { toggleConfirmModal } from 'actions/modal';
import {
  addQuestion,
  duplicateQuestion,
  rearrangeQuestions,
  removeQuestion,
  selectedQuestion,
} from 'redux/form-details/actions';

// Shared
import QuestionTypesPopup from 'shared/QuestionTypesPopup';
import FixedDropdown, { Option } from 'shared/Dropdown/Basic';
import ConfirmModal from 'shared/ConfirmModal';
import { TypingModal, TYING_TYPES } from 'shared/TriggerForms';

// Constants
import { reorder } from 'utils/commonFunction';
import { CDN_URL, FORM_STATUS, QUESTION_TYPES } from 'constants/commonData';

// Assets
import { ReactComponent as WelcomeIcon } from 'assets/icons/welcome_screen.svg';
import { ReactComponent as SingleChoiceIcon } from 'assets/icons/single_choice.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icons/multiple_choice.svg';
import { ReactComponent as LinearScaleIcon } from 'assets/icons/linear_scale.svg';
import { ReactComponent as ShortAnswerIcon } from 'assets/icons/short_answer.svg';
import { ReactComponent as LongAnswerIcon } from 'assets/icons/long_answer.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/signature.svg';
import { ReactComponent as ImageTextIcon } from 'assets/icons/image_text.svg';
import { ReactComponent as MetricIcon } from 'assets/icons/metric_answer.svg';
import { ReactComponent as DateIcon } from 'assets/icons/date_answer.svg';

// Styles
import * as S from './style';
import './styles.scss';

const Sections = ({
  workingQuestion,
  questionTypes,
  questions,
  addQuestion,
  removeQuestion,
  selectedQuestion,
  duplicateQuestion,
  rearrangeQuestions,
  workingForm,
  toggleConfirmModal,
  defaultBackgroundColor,
  onDraggingSections,
  loading,
  bodyMetrics,
  user,
}) => {
  const [list, setList] = useState([]);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const contentRef = useRef();
  const isPublished = get(workingForm, 'status') === FORM_STATUS.PUBLISHED;
  const isEditMode = get(workingForm, 'is_edit_mode', false);
  const hasResponses = get(workingForm, 'number_of_responses', 0);
  const allowEdit = !isPublished || (isPublished && isEditMode);

  useEffect(() => {
    setList(questions);
  }, [questions]);

  const handleAddQuestion = data => {
    addQuestion && addQuestion(data);
    handleScrollTo(data);
  };

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  const handleRemoveQuestion = id => () => {
    const question = questions.find(question => question._id === id);
    const { type, has_trigger_in_use } = question;

    if (
      isPublished &&
      type === QUESTION_TYPES.SINGLE_CHOICE &&
      has_trigger_in_use &&
      typeof toggleConfirmModal === 'function'
    ) {
      return toggleConfirmModal(
        true,
        <TypingModal
          isCloseAfterSubmit
          type={TYING_TYPES.REMOVE_QUESTION}
          closeModal={closeConfirmModal}
          submitAction={() => removeQuestion(id)}
        />,
      );
    }

    if (hasResponses && question.type !== QUESTION_TYPES.WELCOME_SCREEN) {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Are you sure?"
          content="You already collected responses to this question. If you delete it, that data will be deleted too."
          confirmButtonTitle="Yes, delete it"
          noIcon
          noBorder
          hasHoverState
          onConfirm={() => removeQuestion(id)}
          className="remove-question-confirm-popup-container"
        />,
      );
    } else {
      removeQuestion && removeQuestion(id);
    }
  };

  const handleDuplicateQuestion = data => () => {
    duplicateQuestion && duplicateQuestion(omit(data, ['has_trigger_in_use', 'related_question_onboarding_flows']));
  };

  const handleSelectQuestion = data => () => {
    selectedQuestion && selectedQuestion(data);
  };

  const handleOnDragEnd = item => {
    const { draggableId, destination, source } = item;
    if (!destination || !source || destination.index === source.index) {
      return;
    }

    const data = {
      id: draggableId,
      source: source.index,
      destination: destination.index,
    };
    const isWelcome =
      get(questions[source.index], 'type') === QUESTION_TYPES.WELCOME_SCREEN ||
      get(questions[destination.index], 'type') === QUESTION_TYPES.WELCOME_SCREEN;

    if (!isWelcome) {
      let newList = cloneDeep(questions);
      newList = reorder(newList, source.index, destination.index);
      // Update current list first
      setList(newList);
      rearrangeQuestions && rearrangeQuestions(data);
      setTimeout(() => {
        onDraggingSections(false);
      }, 500);
    }
  };

  const handleDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;
    const queryAttr = 'data-rbd-drag-handle-draggable-id';
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);

        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
    marginBottom: '16px',
  });

  const getListStyle = () => ({
    width: '100%',
    position: 'relative',
  });

  const handleScrollTo = data => {
    if (contentRef.current) {
      if (data.type === QUESTION_TYPES.WELCOME_SCREEN) {
        animateScrollTo(0, {
          minDuration: 100,
          speed: 100,
          elementToScroll: contentRef.current,
        }).then(() => {});
      } else {
        setTimeout(() => {
          animateScrollTo(contentRef.current.scrollHeight, {
            minDuration: 100,
            speed: 100,
            elementToScroll: contentRef.current,
          }).then(() => {});
        }, 100);
      }
    }
  };

  const renderList = () => {
    const hasWelcome = list.some(item => item && item.type === QUESTION_TYPES.WELCOME_SCREEN);
    let numberIndex = 0;

    return list.map((question, index) => {
      if (!question) {
        return null;
      }
      let icon = null;
      numberIndex = question.type !== QUESTION_TYPES.IMAGE_TEXT ? numberIndex + 1 : numberIndex;
      const numberQuestion = numberIndex;

      switch (question.type) {
        case QUESTION_TYPES.WELCOME_SCREEN:
          icon = <WelcomeIcon />;
          break;
        case QUESTION_TYPES.SINGLE_CHOICE:
          icon = <SingleChoiceIcon />;
          break;
        case QUESTION_TYPES.MULTIPLE_CHOICE:
          icon = <MultipleChoiceIcon />;
          break;
        case QUESTION_TYPES.LINEAR_SCALE:
          icon = <LinearScaleIcon />;
          break;
        case QUESTION_TYPES.SHORT_ANSWER:
          icon = <ShortAnswerIcon />;
          break;
        case QUESTION_TYPES.LONG_ANSWER:
          icon = <LongAnswerIcon />;
          break;
        case QUESTION_TYPES.SIGNATURE:
          icon = <SignatureIcon />;
          break;
        case QUESTION_TYPES.IMAGE_TEXT:
          icon = <ImageTextIcon />;
          break;
        case QUESTION_TYPES.METRIC:
          icon = <MetricIcon />;
          break;
        case QUESTION_TYPES.DATE_TIME:
          icon = <DateIcon />;
          break;

        default:
          break;
      }
      if (question.type === QUESTION_TYPES.WELCOME_SCREEN) {
        return (
          <S.Item
            active={workingQuestion && question._id === workingQuestion._id}
            onClick={handleSelectQuestion(question)}
            isWelcome={true}
            data-id="question-0"
          >
            <S.Text bold>0</S.Text>
            {icon}
            <S.Text name>{isPublished && question.title === '' ? 'Welcome screen' : question.title}</S.Text>
            {allowEdit && (
              <FixedDropdown
                direction="right"
                triggerIcon={() => (
                  <S.Trigger>
                    <div className="dots" />
                  </S.Trigger>
                )}
                menuStyles={{
                  lineHeight: '16px',
                  borderRadius: '8px',
                  background: '#2D2E2D',
                  boxShadow: '0px 0px 16px rgb(0 0 0 / 10%)',
                  padding: '8px 0',
                }}
              >
                <Option onClick={handleRemoveQuestion(question._id)}>
                  <Image src={`${CDN_URL}/images/delete.svg`} width={14} style={{ marginRight: 9 }} />
                  <span>Delete</span>
                </Option>
              </FixedDropdown>
            )}
          </S.Item>
        );
      }

      return (
        <Draggable
          key={question._id}
          draggableId={question._id}
          index={index}
          isDragDisabled={[QUESTION_TYPES.WELCOME_SCREEN].includes(question.type) || !allowEdit}
        >
          {(provided, snapshot) => (
            <S.ItemWrapper
              className={classNames({ isDragging: snapshot.isDragging })}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              draggable
            >
              <S.Item
                key={index}
                active={workingQuestion && question._id === workingQuestion._id}
                onClick={handleSelectQuestion(question)}
                data-id={`question-${index}`}
              >
                <S.Text bold>
                  {![QUESTION_TYPES.WELCOME_SCREEN, QUESTION_TYPES.IMAGE_TEXT].includes(question.type)
                    ? hasWelcome
                      ? numberQuestion - 1
                      : numberQuestion
                    : '—'}
                </S.Text>
                {icon}
                <S.Text name>{question.title}</S.Text>
                {/* {false && <SyncIcon className="sync-icon" />} */}
                {allowEdit && (
                  <FixedDropdown
                    direction="right"
                    triggerIcon={() => (
                      <S.Trigger>
                        <div className="dots" />
                      </S.Trigger>
                    )}
                    menuStyles={{
                      lineHeight: '16px',
                      borderRadius: '8px',
                      background: '#2D2E2D',
                      boxShadow: '0px 0px 16px rgb(0 0 0 / 10%)',
                      padding: '8px 0',
                    }}
                  >
                    {![QUESTION_TYPES.WELCOME_SCREEN].includes(question.type) ? (
                      <Option onClick={handleDuplicateQuestion(question)} data-option="duplicate">
                        <Image src={`${CDN_URL}/images/clone.svg`} width={14} style={{ marginRight: 9 }} />
                        <span>Duplicate</span>
                      </Option>
                    ) : null}
                    <Option onClick={handleRemoveQuestion(question._id)} data-option="delete">
                      <Image src={`${CDN_URL}/images/delete.svg`} width={14} style={{ marginRight: 9 }} />
                      <span>Delete</span>
                    </Option>
                  </FixedDropdown>
                )}
              </S.Item>
            </S.ItemWrapper>
          )}
        </Draggable>
      );
    });
  };

  return (
    <S.Wrapper>
      <S.Header>
        <span>Content</span>
        {!loading && allowEdit && (
          <QuestionTypesPopup
            questions={list}
            questionTypes={questionTypes}
            onAddQuestion={handleAddQuestion}
            defaultColor={defaultBackgroundColor}
            bodyMetrics={bodyMetrics}
            user={user}
          />
        )}
      </S.Header>
      <S.Content ref={contentRef}>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragUpdate={handleDragUpdate}
          onDragStart={() => onDraggingSections(true)}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                {renderList()}
                {provided.placeholder}
                {snapshot.isUsingPlaceholder && snapshot.isDraggingOver && (
                  <div
                    style={{
                      position: 'absolute',
                      top: placeholderProps.clientY,
                      left: placeholderProps.clientX,
                      height: 36,
                      borderRadius: '5px',
                      width: placeholderProps.clientWidth,
                      background: 'rgb(250, 251, 252)',
                      border: '1px dashed rgb(81, 88, 207)',
                      marginBottom: 10,
                      borderWidth: placeholderProps.clientWidth ? '1px' : '0px',
                    }}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </S.Content>
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { formDetails, customBranding } = rootReducer;
  return {
    questionTypes: formDetails.questionTypes,
    questions: formDetails.questions || [],
    workingQuestion: formDetails.workingQuestion,
    workingForm: formDetails.workingForm,
    defaultBackgroundColor: get(customBranding, 'originalTheme.primaryColor', '').toUpperCase(),
    loading: formDetails.loading,
    bodyMetrics: formDetails.bodyMetrics || [],
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  addQuestion: bindActionCreators(addQuestion, dispatch),
  removeQuestion: bindActionCreators(removeQuestion, dispatch),
  selectedQuestion: bindActionCreators(selectedQuestion, dispatch),
  duplicateQuestion: bindActionCreators(duplicateQuestion, dispatch),
  rearrangeQuestions: bindActionCreators(rearrangeQuestions, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sections);
