import React from 'react';
import classNames from 'classnames';
import { PackageFeaturesWrapper, FeatureItemWrapper, GroupName } from './style';
import ReactTooltip from 'react-tooltip';
import { CDN_URL } from 'constants/commonData';

const FeatureItem = ({ feature, plan, index }) => {
  return (
    <FeatureItemWrapper>
      <div className={`featureItem__icon featureItem__icon--${plan}`} />
      <div
        data-tip
        data-for={`${plan}_${feature.title}`}
        className={classNames('featureItem__text', { 'featureItem__text--highlight': feature.isHighlight })}
      >
        {feature.title}
      </div>
      {feature.description ? (
        <ReactTooltip
          className="app-tooltip-white"
          id={`${plan}_${feature.title}`}
          place="top"
          effect="solid"
          delayShow={100}
        >
          {feature.description}
        </ReactTooltip>
      ) : null}
    </FeatureItemWrapper>
  );
};

export default function PackageFeatures({ features, plan, isCompare, ...props }) {
  const onToggleCompare = e => {
    e.stopPropagation();
    props.onToggleCompare();
  };
  const filteredFeatures = features.filter(item => !item.group);
  const groupFeatures = features.filter(item => item.group);
  const groupName = groupFeatures.length && groupFeatures[0].group;
  return (
    <PackageFeaturesWrapper active={props.active} {...props}>
      <div className={classNames('packageFeature__list', { 'packageFeature__list--compare': isCompare })}>
        {filteredFeatures.map((feature, index) => (
          <FeatureItem key={`${plan}_${feature.title}`} feature={feature} plan={plan} index={index} />
        ))}
      </div>
      {groupName ? (
        <div className={classNames('packageGroupFeature__list', { 'packageGroupFeature__list--compare': isCompare })}>
          <GroupName>{groupName}</GroupName>
          {groupFeatures.map((feature, index) => (
            <FeatureItem key={`${plan}_${feature.title}`} feature={feature} plan={plan} index={index} />
          ))}
        </div>
      ) : null}
      <div
        className={classNames('packageItem__compare', { 'packageItem__compare--compare': isCompare })}
        onClick={onToggleCompare}
      >
        <span>
          {isCompare ? 'Minimize' : 'Compare'}
          <img src={`${CDN_URL}/images/slide_arrow_down_purple.svg`} alt="" />
        </span>
      </div>
    </PackageFeaturesWrapper>
  );
}
