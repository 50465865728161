import React from 'react';
import { SearchItemWrapper } from './style';
import ClientTrainingIcon from 'shared/Icons/ClientTrainingIcon';
import ClientInboxIcon from 'shared/Icons/ClientInboxIcon';
import ReactAvatar from 'react-avatar';
import { getUserShortName } from 'utils/commonFunction';

export default function SearchItem (props) {
  const { client } = props;

  const handleClick = () => {
    props.onSelectClient(client);
  }
  return (
    <SearchItemWrapper onClick={handleClick}>
      <div className="searchItem__leftContent">
        <div className="searchItem__avatar">
          <ReactAvatar name={getUserShortName(client)} className="client-detail-avatar" size="34" src={client.avatar} color={client.color}/>
        </div>
        <div className="searchItem__info">
          <div className="searchItem__info--name">{client.first_name}&nbsp;{client.last_name}</div>
          <div className="searchItem__info--email">{client.email}</div>
        </div>
      </div>
      <div className="searchItem__rightContent">
        <ClientTrainingIcon onClick={props.onCloseSearch} user={client} />
        <ClientInboxIcon onClick={props.onCloseSearch} user={client} />
      </div>
    </SearchItemWrapper>
  )
}