import styled, { css } from 'styled-components';

export const CommentItemWrapper = styled.div`
  margin-bottom: 52px;
  ${props =>
    props.onlyViewMore &&
    css`
      margin-bottom: 24px;
    `}
  &:last-child {
    margin-bottom: 43px;
    ${props =>
      props.onlyViewMore &&
      css`
        margin-bottom: 15px;
      `}
  }
`;

export const ListReplyWrapper = styled.div`
  margin-left: 42px;
`;

export const ViewMoreWrapper = styled.div`
  width: fit-content;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  span {
    color: #8f91a9;
  }
  svg {
    margin-right: 6px;
  }
  &:hover {
    span {
      color: #5158cf;
    }
    svg {
      g {
        opacity: 1;
      }
      path {
        fill: #5158cf;
        opacity: 1;
      }
    }
  }
`;
