export const ENTITY_NAME = {
  Workout: 'Workout',
  Section: 'Section',
  Superset: 'Superset',
  Exercise: 'Exercise',
  TrainingSet: 'TrainingSet',
  ExerciseLibrary: 'ExerciseLibrary',
  S3PresignedURL: 'S3PresignedURL',
  OneRepMax: 'OneRepMax',
};
