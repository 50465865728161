import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: ${props => props.radius}px;
  height: ${props => props.radius}px;
  background: #e9effb;
  border-radius: 50%;

  .circle .mask,
  .circle .fill {
    width: ${props => props.radius}px;
    height: ${props => props.radius}px;
    position: absolute;
    border-radius: 50%;
  }

  .circle .mask {
    clip: rect(0px, ${props => props.radius}px, ${props => props.radius}px, ${props => props.radius / 2}px);
  }

  .circle .mask .fill {
    clip: rect(0px, ${props => props.radius / 2}px, ${props => props.radius}px, 0px);
    background-color: ${props => props.color};
  }

  .circle .mask.full,
  .circle .fill {
    animation: fill ease-in-out 0.3s;
    transform: ${props => `rotate(${props.progress}deg)`};
  }

  @keyframes fill {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: ${props => `rotate(${props.progress}deg)`};
    }
  }

  .inside-circle {
    width: ${props => props.stroke}px;
    height: ${props => props.stroke}px;
    border-radius: 50%;
    background: #fff;
    line-height: ${props => props.stroke}px;
    text-align: center;
    margin-top: ${props => (props.radius - props.stroke) / 2}px;
    margin-left: ${props => (props.radius - props.stroke) / 2}px;
    position: absolute;
    z-index: 0;
    font-weight: 700;
  }
`;

function CircleProgress(props) {
  const { radius, stroke, progress, color } = props;
  const progressTransform = 180 * (progress / 100);
  return (
    <Wrapper radius={radius || 26} stroke={stroke || 18} progress={progressTransform} color={color}>
      <div className="circle">
        <div className="mask full">
          <div className="fill"></div>
        </div>
        <div className="mask half">
          <div className="fill"></div>
        </div>
        <div className="inside-circle" />
      </div>
    </Wrapper>
  );
}

export default CircleProgress;
