import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import TextEditable from 'shared/TextEditable';
import { convertS3UrlToCloudFrontUrl, isSafari, setCaretToEnd } from 'utils/commonFunction';
import { isMobile } from 'react-device-detect';
import { WELCOME_QUESTION_NAME_LIMIT_LENGTH, WELCOME_DESCRIPTION_LIMIT_LENGTH } from 'constants/commonData';

import { ReactComponent as DeleteIcon } from 'assets/icons/question_delete.svg';

import * as S from './style';

export default function WelcomeScreen({
  contentRef,
  workingQuestion,
  idx,
  isSelected,
  updatePartialQuestion,
  onRemoveQuestion,
  allowEdit,
  onScrollTo,
  selectedQuestion,
  loading,
  cloudfrontList,
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const storedTitle = get(workingQuestion, 'title', '');
  const storedDescription = get(workingQuestion, 'description', '');
  const image = get(workingQuestion, 'background_image', null); // TODO: handle upload image
  const questionNameRef = useRef();
  const descriptionRef = useRef();
  const isFocus = useRef(`name-${idx}`);

  useEffect(() => {
    setTitle(storedTitle);
    setDescription(storedDescription);
  }, [storedTitle, storedDescription]);

  useEffect(() => {
    if (isSelected) {
      onScrollTo();
    }
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && questionNameRef.current && isFocus.current === `name-${idx}`) {
      questionNameRef.current.focus();
      setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
    }
  }, []);

  const handleChangeQuestionName = value => {
    const trimmedValue = value.trim();
    if (trimmedValue.length <= WELCOME_QUESTION_NAME_LIMIT_LENGTH) {
      setTitle(trimmedValue);
    } else {
      // handle copy paste case out of chars limit
      setTitle(trimmedValue.slice(0, WELCOME_QUESTION_NAME_LIMIT_LENGTH));
      if (trimmedValue.length === WELCOME_QUESTION_NAME_LIMIT_LENGTH + 1) {
        setTitle(title.trim().slice(0, WELCOME_QUESTION_NAME_LIMIT_LENGTH));
        questionNameRef.current.focus();
        setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
      } else {
        setTitle(trimmedValue.slice(0, WELCOME_QUESTION_NAME_LIMIT_LENGTH));
      }
    }
  };

  const handleBlurQuestionName = () => {
    if (workingQuestion.title !== title && updatePartialQuestion && !loading) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        title,
      });
    }
  };

  const handleBlurDescription = () => {
    if (workingQuestion.description !== description && updatePartialQuestion && !loading) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        description,
      });
    }
  };

  const handleChangeDescription = value => {
    const trimmedValue = value.trim();
    if (trimmedValue.length <= WELCOME_DESCRIPTION_LIMIT_LENGTH) {
      setDescription(trimmedValue);
    } else {
      if (trimmedValue.length === WELCOME_DESCRIPTION_LIMIT_LENGTH + 1) {
        setDescription(description.trim().slice(0, WELCOME_DESCRIPTION_LIMIT_LENGTH));
        descriptionRef.current.focus();
        setCaretToEnd(get(descriptionRef, 'current.inputRef.current'));
      } else {
        setDescription(trimmedValue.slice(0, WELCOME_DESCRIPTION_LIMIT_LENGTH));
      }
    }
  };

  const handleFocus = type => () => {
    isFocus.current = type;
  };

  const handleOnMouseOutParent = () => {
    if (questionNameRef.current) {
      const inputRef = get(questionNameRef, 'current.inputRef.current');
      questionNameRef.current.onBlur();
      inputRef && inputRef.blur();
    }
    if (descriptionRef.current) {
      const inputRef = get(descriptionRef, 'current.inputRef.current');
      descriptionRef.current.onBlur();
      inputRef && inputRef.blur();
    }
  };

  const handleOnClickQuestion = () => {
    allowEdit && selectedQuestion && selectedQuestion(workingQuestion);
  };

  return (
    <S.QuestionWrapper
      ref={contentRef}
      onClick={handleOnClickQuestion}
      onMouseLeave={handleOnMouseOutParent}
      isSafari={isSafari() || isMobile}
    >
      <S.WelcomeScreenContainer background={convertS3UrlToCloudFrontUrl(image, cloudfrontList, true)}>
        <div className="welcome-screen-content">
          <TextEditable
            ref={questionNameRef}
            className="welcome-screen-title"
            value={title}
            placeholder={allowEdit ? 'Title (optional)' : ''}
            lineHeight={40}
            onFocus={handleFocus(`name-${idx}`)}
            onBlur={handleBlurQuestionName}
            onChange={handleChangeQuestionName}
            maxLength={WELCOME_QUESTION_NAME_LIMIT_LENGTH}
            notRequired
            readOnly={!allowEdit}
          />
          <TextEditable
            ref={descriptionRef}
            className="welcome-screen-description"
            value={description}
            placeholder={allowEdit ? 'Description (optional)' : ''}
            lineHeight={22}
            onFocus={handleFocus('desc')}
            onBlur={handleBlurDescription}
            onChange={handleChangeDescription}
            maxLength={WELCOME_DESCRIPTION_LIMIT_LENGTH}
            notRequired
            breakLine
            readOnly={!allowEdit}
          />
          <S.LabelButton purple className="welcome-screen-btn" buttonBackground={workingQuestion.button_background}>
            {workingQuestion.button_label}
          </S.LabelButton>
        </div>
      </S.WelcomeScreenContainer>
      {allowEdit && (
        <S.ActionsWrapper>
          <DeleteIcon data-tip data-for={`delete-question-${idx}`} onClick={onRemoveQuestion(workingQuestion._id)} />
          <ReactTooltip
            id={`delete-question-${idx}`}
            className="question-tooltip"
            effect="solid"
            place="top"
            delayShow={300}
          >
            Delete
          </ReactTooltip>
        </S.ActionsWrapper>
      )}
    </S.QuestionWrapper>
  );
}
