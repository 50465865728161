// Libs
import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';

// Actions
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { updateDataDetailRecipe, updateUnitType, handleSubmitted } from 'redux/recipes/actions';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';

// Components
import AddIngredient from '../AddIngredient';
import DropdownUnit from './components/DropdownUnit';
import IngredientItem from './components/IngredientItem';
import AddAsText from './components/AddAsText';

// Constants
import { ListUnit, ENUM_UNIT } from '../constants';

// Helper
import { validAllValueStringIngredient } from '../helper';

// Shared
import TextEditable from 'shared/TextEditable';

// style
import * as S from './style';

const ENUM_TYPE_ADD_INGREDIENT = {
  CUSTOM: 'custom',
  TEXT: 'text',
};

const Ingredient = props => {
  const {
    toggleModal,
    isEditMode,
    listIngredient = [],
    updateDataDetailRecipe,
    errors,
    isSubmitted,
    isSubmittedPublish,
    updateUnitType,
    unitType,
    ingredientText,
    ingredientNote,
    toggleConfirmModal,
    listUnitIngredient = {},
    handleSubmitted = () => {},
    autoCalculated = false,
    changeAutoCalculated = () => {},
  } = props;

  const queryAttr = 'data-rbd-drag-handle-draggable-id';

  const [defaultUnit, setDefaultUnit] = useState(unitType);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [typeAddIngredient, setTypeAddIngredient] = useState(() => {
    if (ingredientText) return ENUM_TYPE_ADD_INGREDIENT.TEXT;
    return ENUM_TYPE_ADD_INGREDIENT.CUSTOM;
  });

  useEffect(() => {
    setTypeAddIngredient(ingredientText ? ENUM_TYPE_ADD_INGREDIENT.TEXT : ENUM_TYPE_ADD_INGREDIENT.CUSTOM);
  }, [isEditMode]);

  const openAddIngredient = () => {
    toggleModal(
      true,
      <AddIngredient defaultUnit={defaultUnit} callbackAddIngredientSuccess={callbackAddIngredientSuccess} />,
    );
  };

  const callbackAddIngredientSuccess = () => {
    setTypeAddIngredient(ENUM_TYPE_ADD_INGREDIENT.CUSTOM);
  };

  const handleDragEnd = useCallback(
    result => {
      setPlaceholderProps({});

      if (!result.destination) {
        return;
      }
      const reorderedItems = Array.from(listIngredient);
      const [removed] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, removed);
      updateDataDetailRecipe && updateDataDetailRecipe({ ingredients: reorderedItems });
    },
    [listIngredient],
  );

  const handleDragStart = event => {
    const draggedDOM = getDraggedDom(event && event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event && event.source && event.source.index;

    const clientY = getClientY(draggedDOM, [...draggedDOM.parentNode.children], sourceIndex);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleDragUpdate = event => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event && event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event && event.destination && event.destination.index;
    const sourceIndex = event && event.source && event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY = getClientY(draggedDOM, updatedArray, destinationIndex);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const getDraggedDom = draggableId => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    return document.querySelector(domQuery);
  };

  const handleRemoveIngredient = idIngredient => {
    const ingredients = [...listIngredient].filter(item => get(item, '_id', '') !== idIngredient);

    let updatedError = errors;

    const isExistIngredients = validAllValueStringIngredient(ingredients);

    if (isExistIngredients) {
      updatedError = omit(updatedError, ['ingredients']);
    }

    if (ingredients.length < 1) {
      updateDataDetailRecipe && updateDataDetailRecipe({ ingredient_note: '' });
    }

    updateDataDetailRecipe && updateDataDetailRecipe({ ingredients, error: updatedError });
  };

  const handleChangeUnit = (data, idIngredient) => {
    const ingredients = [...listIngredient].map(item => {
      if (get(item, '_id', '') === idIngredient) {
        return { ...item, unit: data };
      }
      return item;
    });
    updateDataDetailRecipe && updateDataDetailRecipe({ ingredients });
  };

  const handleChangeValueUnit = (value, idIngredient) => {
    const ingredients = [...listIngredient].map(item => {
      if (get(item, '_id', '') === idIngredient) {
        return omit({ ...item, value_as_text: value }, ['isAddNew']);
      }
      return item;
    });

    const check = validAllValueStringIngredient(ingredients);

    const updatedError = !check ? { ...errors, ingredients: true } : omit(errors, ['ingredients']);

    updateDataDetailRecipe && updateDataDetailRecipe({ ingredients, error: updatedError });
  };

  const handleChangeUnitType = data => {
    setDefaultUnit(data);

    if (get(data, 'key', '') !== get(unitType, 'key', '')) {
      updateUnitType &&
        updateUnitType({
          unit_type: get(data, 'key', ''),
        });
    }

    updateDataDetailRecipe && updateDataDetailRecipe({ unit: data }, isEditMode);
  };

  const getClientY = (draggedDOM, array, index) => {
    const result =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      array.slice(0, index).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    return result;
  };

  const renderIngredientList = () => {
    const result = listIngredient.map((item, index) => {
      const {
        _id = '',
        image_thumbnail = '',
        name = '',
        unit = {},
        isAddNew = false,
        listUnitAutoCalculated = {},
      } = item;
      const resultInfoUnit = getInfoUnit(item);

      return (
        <Draggable key={_id} draggableId={_id} index={index} isDragDisabled={!isEditMode}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <IngredientItem
                isEdit={isEditMode}
                id={_id}
                isDragging={snapshot.isDragging}
                src={image_thumbnail}
                name={name}
                handleRemove={handleRemoveIngredient}
                unit={unit}
                titleUnit={resultInfoUnit.titleUnit}
                handleChangeUnit={data => handleChangeUnit(data, _id)}
                value={resultInfoUnit.valueIngredient}
                handleChangeValueUnit={value => handleChangeValueUnit(value, _id)}
                errors={errors}
                isSubmitted={isSubmitted}
                isSubmittedPublish={isSubmittedPublish}
                listUnitIngredient={listUnitIngredient}
                isAddNew={isAddNew}
                handleSubmitted={handleSubmitted}
                listIngredient={listIngredient}
                listUnitAutoCalculated={listUnitAutoCalculated}
                autoCalculated={autoCalculated}
              />
            </div>
          )}
        </Draggable>
      );
    });
    return result;
  };

  const getInfoUnit = data => {
    const { unit, value_as_text = '', imperial_converted, metric_converted } = data;

    let unitConvert = {};
    let valueIngredient = '';
    let titleUnit = '';

    if (isEditMode) {
      unitConvert = {
        key: get(unit, 'unique_code', ''),
        acronym: get(unit, 'formula', ''),
      };
      valueIngredient = value_as_text;
      titleUnit = get(unit, 'display', '') || get(unit, 'formula', '');
    } else {
      if (defaultUnit.key === ENUM_UNIT.DEFAULT) {
        unitConvert = {
          key: get(unit, 'unique_code', ''),
          acronym: get(unit, 'formula', ''),
        };
        valueIngredient = value_as_text;
        titleUnit = get(unit, 'display', '') || get(unit, 'formula', '');
      }
      if (defaultUnit.key === ENUM_UNIT.US_IMPERIAL) {
        unitConvert = {
          key: get(imperial_converted, 'unique_code', ''),
          acronym: get(imperial_converted, 'formula', ''),
        };
        valueIngredient = get(imperial_converted, 'value_as_text');
        titleUnit = get(imperial_converted, 'display', '') || get(imperial_converted, 'formula', '');
      }
      if (defaultUnit.key === ENUM_UNIT.METRIC) {
        unitConvert = {
          key: get(imperial_converted, 'unique_code', ''),
          acronym: get(metric_converted, 'formula', ''),
        };
        valueIngredient = get(metric_converted, 'value_as_text');
        titleUnit = get(metric_converted, 'display', '') || get(metric_converted, 'formula', '');
      }
    }

    return {
      unitConvert,
      valueIngredient,
      titleUnit,
    };
  };

  const openAddAsText = () => {
    setTypeAddIngredient(ENUM_TYPE_ADD_INGREDIENT.TEXT);
  };

  const handleChangeIngredientText = value => {
    updateDataDetailRecipe && updateDataDetailRecipe({ ingredient_text: value });
    changeAutoCalculated && changeAutoCalculated(false);
  };

  const handleChangeNoteIngredient = value => {
    updateDataDetailRecipe && updateDataDetailRecipe({ ingredient_note: value });
  };

  const handleClearTextIngredient = () => {
    setTypeAddIngredient(ENUM_TYPE_ADD_INGREDIENT.CUSTOM);
  };

  return (
    <S.IngredientWrapper isEditMode={isEditMode}>
      {isEditMode ? (
        <>
          <S.IngredientHeaderWrapper>
            <S.AddIngredient onClick={openAddIngredient} data-tip data-for="btn-add-ingredient-tooltip">
              <PlusIcon />
              Add Ingredient
            </S.AddIngredient>
            {ingredientText && (
              <ReactTooltip id="btn-add-ingredient-tooltip" effect="solid" place={'top'}>
                We will remove all the text to add itemize
              </ReactTooltip>
            )}
            {listIngredient.length <= 0 && (
              <S.AddAsText onClick={openAddAsText} active={typeAddIngredient === ENUM_TYPE_ADD_INGREDIENT.TEXT}>
                <PlusIcon />
                Add as text
              </S.AddAsText>
            )}
          </S.IngredientHeaderWrapper>
        </>
      ) : (
        <>
          {!ingredientText && (
            <S.UnitWrapper>
              <S.UnitName>Unit:</S.UnitName>
              <DropdownUnit onchange={handleChangeUnitType} value={defaultUnit} options={ListUnit} />
            </S.UnitWrapper>
          )}
        </>
      )}
      {typeAddIngredient === ENUM_TYPE_ADD_INGREDIENT.CUSTOM && (
        <>
          <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragUpdate={handleDragUpdate}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="droppable-context-ingredient">
                  {renderIngredientList()}
                  {provided.placeholder}
                  {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                    <S.Placeholder
                      style={{
                        top: placeholderProps.clientY,
                        left: placeholderProps.clientX,
                        height: placeholderProps.clientHeight,
                        width: placeholderProps.clientWidth,
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {listIngredient.length > 0 && (isEditMode || ingredientNote) && (
            <S.NoteWrapper readOnly={!isEditMode}>
              <TextEditable
                placeholder="Add an additional note"
                readOnly={!isEditMode}
                breakLine
                onChange={debounce(handleChangeNoteIngredient, 300)}
                value={ingredientNote}
              />
            </S.NoteWrapper>
          )}
        </>
      )}
      {typeAddIngredient === ENUM_TYPE_ADD_INGREDIENT.TEXT && (
        <S.AddAsTextWrapper>
          <AddAsText
            handleChangeIngredientText={debounce(handleChangeIngredientText, 300)}
            isEditMode={isEditMode}
            value={ingredientText}
            handleClearTextIngredient={handleClearTextIngredient}
            toggleConfirmModal={toggleConfirmModal}
            hasError={isSubmittedPublish && !ingredientText}
          />
        </S.AddAsTextWrapper>
      )}
    </S.IngredientWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes, ingredientLibrary },
  } = state;

  const errors = get(recipes, 'detailRecipe.error', {});
  const listUnitIngredient = get(ingredientLibrary, 'listUnitIngredient', {});

  return { errors, listUnitIngredient };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  updateDataDetailRecipe: bindActionCreators(updateDataDetailRecipe, dispatch),
  updateUnitType: bindActionCreators(updateUnitType, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  handleSubmitted: bindActionCreators(handleSubmitted, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ingredient);
