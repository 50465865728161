// Libs
import React, { useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// components
import MealPlanItem from '../MealPlanItem';
import AssignMealPlan from '../AssignMealPlan';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

// helper
import { formatDate } from 'components/ClientMealPlan/helper';

// utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as GetMealPlanIcon } from 'assets/icons/MealPlans/get_meal_plan_icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';

// Constants
import { DAY_FORMAT } from 'constants/time-format';
import { ASSIGNED_STATUS } from 'components/ClientMealPlan/constants';
import { PLANS } from 'constants/commonData';

// Styles
import * as S from './style';

const MealPlanList = ({
  cloudfrontList,
  toggleModal,
  selectedDay,
  assignedMealPlanClients = [],
  onChangeDay,
  assignedMealPlanClientsByRange = [],
  clientTimezone,
  currentMonth,
  permissionAddOn,
}) => {
  const [showUpcoming, setShowUpcoming] = useState(true);

  const filterPlansByStatus = (plans, status) => plans.filter((item = {}) => item.status === status);

  const getDayAssign = (plans, status) => {
    const lastPlan = plans.length > 0 ? plans[plans.length - 1] : null;

    if (!lastPlan) {
      return selectedDay;
    }

    const { day = '', weeks = [] } = lastPlan;
    const dateAddWeek = moment.tz(day, clientTimezone).add(get(weeks, 'length', 0), 'week');
    const endDate = dateAddWeek.clone().subtract(1, 'day');
    const assignedDay = dateAddWeek.isBefore(moment.tz(dateAddWeek, clientTimezone))
      ? moment.tz(dateAddWeek, clientTimezone)
      : dateAddWeek;
    const selectedDayStr = selectedDay.clone().format(DAY_FORMAT.YYYY_MM_DD);

    const isBeforeEndDate = moment.tz(selectedDayStr, clientTimezone).isSameOrBefore(endDate);

    switch (status) {
      case ASSIGNED_STATUS.UPCOMING:
      case ASSIGNED_STATUS.ACTIVE:
      case ASSIGNED_STATUS.COMPLETED:
        return isBeforeEndDate ? assignedDay : selectedDay;
      default:
        return selectedDay;
    }
  };

  const handleAssignMealPlan = () => {
    let assignedDay = selectedDay;

    if (!isEmpty(upcomingPlans) && (!isEmpty(activePlans) || !isEmpty(completedPlans))) {
      assignedDay = getDayAssign(upcomingPlans, ASSIGNED_STATUS.UPCOMING);
    } else if (!isEmpty(activePlans)) {
      assignedDay = getDayAssign(activePlans, ASSIGNED_STATUS.ACTIVE);
    } else if (!isEmpty(completedPlans)) {
      assignedDay = getDayAssign(completedPlans, ASSIGNED_STATUS.COMPLETED);
    }

    toggleModal &&
      toggleModal(
        true,
        <AssignMealPlan
          selectedDay={selectedDay}
          assignedDay={assignedDay}
          clientTimezone={clientTimezone}
          currentMonth={currentMonth}
        />,
      );
  };

  const activePlans = filterPlansByStatus(assignedMealPlanClients, ASSIGNED_STATUS.ACTIVE);
  const upcomingPlans = filterPlansByStatus(assignedMealPlanClients, ASSIGNED_STATUS.UPCOMING);
  const completedPlans = filterPlansByStatus(assignedMealPlanClientsByRange, ASSIGNED_STATUS.COMPLETED);

  const renderEmpty = () => {
    return (
      <S.Empty>
        <span>No Meal Plans assigned.</span>
      </S.Empty>
    );
  };

  const renderUpgradePath = () => {
    return (
      <S.GetMealPlan>
        <S.GetMealPlanContent>
          <GetMealPlanIcon />
          <span>Plan ahead and choose from your custom meal plans and recipes</span>
        </S.GetMealPlanContent>
        <UpgradePathButton
          className="custom-upgrade-path"
          text="Get Custom Meal Plans"
          planWillSelect={{
            tier: PLANS.pro.key,
            addOns: {
              meal_plan: true,
            },
          }}
        />
      </S.GetMealPlan>
    );
  };

  const onClickItem = day => {
    onChangeDay(moment(day));
  };

  const handleViewDetail = (idMealPlan, idFirstWeek) => {
    if (idMealPlan && idFirstWeek) {
      window.open(`/home/meal-plans/${idMealPlan}/weeks/${idFirstWeek}`, '_blank');
    }
  };

  const renderPlans = plans => {
    return plans.map(item => {
      const {
        _id = '',
        name = '',
        cover_image = '',
        cover_image_thumbnail = '',
        day = '',
        end_day = '',
        weeks = [],
      } = item;
      const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
      const coverImageThumbnail = convertS3UrlToCloudFrontUrl(cover_image_thumbnail, cloudfrontList, true);
      const startDay = formatDate(day);
      const endDay = formatDate(end_day);

      // Check day between start date and end date
      const startDate = moment(day);
      const endDate = moment(end_day);
      const selectedDayStr = selectedDay.clone().format(DAY_FORMAT.YYYY_MM_DD);
      const active = moment(selectedDayStr).isBetween(startDate, endDate, null, '[]');

      return (
        <MealPlanItem
          key={_id}
          name={name}
          id={_id}
          coverImage={coverImage}
          coverImageThumbnail={coverImageThumbnail}
          startDay={startDay}
          endDay={endDay}
          active={active}
          handleGotoStartingDate={() => onClickItem(day)}
          handleViewDetail={() => handleViewDetail(_id, weeks[0])}
        />
      );
    });
  };

  const renderListUpcoming = () => {
    return (
      <>
        {!isEmpty(upcomingPlans) && (
          <S.StatusLabel
            className="label-upcoming"
            showUpcoming={showUpcoming}
            onClick={() => {
              setShowUpcoming(!showUpcoming);
            }}
          >
            Upcoming <ArrowIcon />{' '}
          </S.StatusLabel>
        )}
        {showUpcoming && renderPlans(upcomingPlans)}
      </>
    );
  };

  return (
    <S.Wrapper noPermissionAddOn={!permissionAddOn}>
      <S.Header>
        <span>Meal Plans</span>
        {permissionAddOn && (
          <button onClick={handleAssignMealPlan}>
            <PlusIcon className="custom-plus-icon" />
            Assign Meal Plan
          </button>
        )}
      </S.Header>
      {permissionAddOn ? (
        <S.List>
          {!isEmpty(activePlans) && <S.StatusLabel>Active</S.StatusLabel>}
          {renderPlans(activePlans)}
          {renderListUpcoming()}
          {isEmpty(activePlans) && isEmpty(upcomingPlans) ? renderEmpty() : null}
        </S.List>
      ) : (
        renderUpgradePath()
      )}
    </S.Wrapper>
  );
};

export default MealPlanList;
