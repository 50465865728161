import React, { memo } from 'react';
import { ReactComponent as ResendIcon } from 'assets/icons/resend-icon.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/reject-icon.svg';
import { ReactComponent as AllowIcon } from 'assets/icons/allow-icon.svg';
import { BulkActionButton, BulkActionWrapper } from './styles';
import { pluralize } from 'utils/commonFunction';

const BulkAction = ({ label, onClick, iconName, count }) => {
  const finalLabel = `${label} ${pluralize('client', count, true)}`;
  const finalIcon = {
    resend: <ResendIcon />,
    reject: <RejectIcon />,
    allow: <AllowIcon />,
  };

  if (!count) return null;
  return (
    <BulkActionButton onClick={onClick}>
      {finalIcon[iconName]}
      <span>{finalLabel}</span>
    </BulkActionButton>
  );
};

export { BulkActionWrapper };

export default memo(BulkAction);
