// libs
import React from 'react';
import _ from 'lodash';
import { Modal, Image, Button, Input } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';

// shared
import { Checkbox } from 'shared/FormControl';
import OnboardingFlowSelect from 'shared/OnboardingFlowSelect';
import Icons from 'shared/Icons';
import TeammateDropdown, { TYPE } from 'shared/TeammateDropdown';
import CategoryClientDropdown from 'shared/CategoryClientDropdown';

// components
import ImportClients from 'components/ImportClients';

// utils
import { isTeamAdmin } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

// assets
import { ReactComponent as HintIcon } from 'assets/icons/hint.svg';

// styles
import { ListContainer, AssignContent, InputWrapper, CategoryContent, LeftActions } from './style';
import './style.scss';

class ClientModel {
  client_info;
  send_mail;
  assign_to;

  constructor(assignTo) {
    this.client_info = {
      first_name: '',
      last_name: '',
      email: '',
      client_type: 'Online',
    };
    this.send_mail = false;
    this.assign_to = assignTo;
  }
}
class AddMultipleClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [new ClientModel(props.user)],
      isSubmit: false,
      error: false,
      assignOnboarding: null,
    };
    this.tableContainerRef = React.createRef();
  }

  onClose = () => {
    const { toggleModal } = this.props;

    return toggleModal(false);
  };

  onCheckboxChange = (index, checked) => {
    const { list } = this.state;
    let newList = list.slice();
    newList[index].send_mail = checked;
    this.setState({ list: newList });
  };

  onClientInfoChange = (index, key, value) => {
    let list = this.state.list.slice();
    list[index].client_info[key] = value;
    this.setState({ list });
  };

  onAssignToChange = (index, value) => {
    let list = this.state.list.slice();
    list[index].assign_to = value;
    this.setState({ list });
  };

  submitClients = () => {
    const { permission } = this.props;
    const { list, isSubmit, assignOnboarding } = this.state;

    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;
    const onboarding_flow_id = hasPermissionMultiple ? _.get(assignOnboarding, '_id') : null;
    const onboarding_flow_type =
      hasPermissionMultiple && onboarding_flow_id === 'not_using' ? onboarding_flow_id : undefined;

    if (isSubmit) {
      return false;
    }

    let error = false;
    _.forEach(list, item => {
      _.forEach(item.client_info, (info, key) => {
        if (!info) {
          error = key === 'email' ? !!item.send_mail : true;
        }

        if (error) {
          return false;
        }
      });

      if (error) {
        return false;
      }
    });

    if (error) {
      return this.setState({ error });
    }

    const { submitMultipleClients, getAllSegments, getClientsFullData } = this.props;
    this.setState({ isSubmit: true, error });
    submitMultipleClients({ data: list, onboarding_flow_id, onboarding_flow_type })
      .then(response => {
        const { success } = response.data.data;
        if (success) {
          getAllSegments && getAllSegments();
          getClientsFullData && getClientsFullData();
        }
      })
      .catch(() => {
        this.setState({ isSubmit: false });
      });
  };

  renderList = () => {
    const { list, error } = this.state;
    const { user } = this.props;
    const isAdminOrOwner = isTeamAdmin(user);

    return (
      <div className="clients__table-container" ref={this.tableContainerRef}>
        <table>
          <thead>
            <tr>
              <th />
              <th>
                <div className="table__header">First Name</div>
              </th>
              <th>
                <div className="table__header">Last Name</div>
              </th>
              <th>
                <div className="table__header">Email</div>
              </th>
              <th>
                <div className="table__header">Category</div>
              </th>
              {isAdminOrOwner && (
                <th>
                  <div className="table__header">Assign To</div>
                </th>
              )}
              <th>
                <div className="table__header send-email-column">Send Invite</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(list, (item, index) => (
              <tr key={index}>
                <td>
                  <span>{index + 1}</span>
                  <Image
                    src={`${CDN_URL}/images/remove_purple.svg`}
                    onClick={() => {
                      if (list.length > 1) {
                        let newList = list.slice();
                        newList.splice(index, 1);
                        this.setState({ list: newList });
                      }
                    }}
                  />
                </td>
                {_.map(item.client_info, (value, key) => {
                  let className = 'client_info';

                  if (error) {
                    className += !value.trim() && (key !== 'email' || item.send_mail) ? ' error' : '';
                  }

                  return (
                    <td key={key}>
                      {key === 'client_type' ? (
                        <CategoryContent>
                          <CategoryClientDropdown
                            value={value}
                            onChange={data => {
                              if (data.value !== value) {
                                this.onClientInfoChange(index, key, data.value);
                              }
                            }}
                          />
                        </CategoryContent>
                      ) : (
                        <InputWrapper column={key} isAdminOrOwner={isAdminOrOwner}>
                          <Input
                            onChange={(evt, data) => this.onClientInfoChange(index, key, data.value)}
                            className={className}
                            value={value}
                            maxLength={['first_name', 'last_name'].includes(key) ? 30 : undefined}
                          />
                        </InputWrapper>
                      )}
                    </td>
                  );
                })}
                {isAdminOrOwner && (
                  <td>
                    <AssignContent>
                      <TeammateDropdown
                        type={TYPE.MULTIPLE}
                        value={item.assign_to}
                        onChange={it => this.onAssignToChange(index, it)}
                      />
                    </AssignContent>
                  </td>
                )}
                <td>
                  <Checkbox
                    checked={item.send_mail}
                    onChange={event => {
                      this.onCheckboxChange(index, event.target.checked);
                    }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td>{list.length + 1}</td>
              <td colSpan={5}>
                <Icons
                  className="icon"
                  name="add"
                  onClick={() => {
                    let newList = list.slice();
                    newList.push(new ClientModel(this.props.user));
                    this.setState({ list: newList }, () => {
                      setTimeout(() => {
                        if (this.tableContainerRef.current) {
                          const { scrollHeight, offsetHeight } = this.tableContainerRef.current;
                          this.tableContainerRef.current.scrollTop = scrollHeight - offsetHeight;
                        }
                      }, 0);
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { isModalOpen, toggleModal, cloudfrontList, toggleConfirmModal, toggleSecondModal, permission } = this.props;
    const { error, assignOnboarding } = this.state;

    const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

    return (
      <Modal
        open={isModalOpen}
        onClose={this.onClose}
        className="multiple-client-modal multiple-client-modal--oneByone"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Button onClick={this.onClose} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div>
            <h3>Add Clients</h3>
            <h2>Add Multiple Clients</h2>
          </div>
          <Button onClick={() => toggleModal(true, <ImportClients />)} className="multiple-clients__import-button">
            <Image src={`${CDN_URL}/images/csv.svg`} />
            <span>Add clients by .CSV file</span>
          </Button>
        </Modal.Header>
        <Modal.Content>
          <ListContainer className="clients">{this.renderList()}</ListContainer>
        </Modal.Content>
        <Modal.Actions>
          {!hasPermissionMultiple && <div className="error">{error ? 'Please complete all required fields' : ''}</div>}
          {hasPermissionMultiple && (
            <LeftActions>
              <div className="onboarding-flow">
                <div className="onboarding-flow-label">
                  <div className="custom-form-label">
                    ONBOARDING FLOW (OPTIONAL)
                    <HintIcon className="custom-hint-icon" data-tip data-for="tooltip-onboarding-flow" />
                  </div>
                  <ReactTooltip
                    id="tooltip-onboarding-flow"
                    effect="solid"
                    place="top"
                    className="app-tooltip onboarding-flow-tooltip"
                  >
                    <span>
                      With the Automation add-on, you can select different Onboarding Flows to assign to this client.
                    </span>
                  </ReactTooltip>
                </div>
                <OnboardingFlowSelect
                  type={TYPE.MULTIPLE}
                  value={assignOnboarding}
                  onChange={it => this.setState({ assignOnboarding: it })}
                  cloudfrontList={cloudfrontList}
                  toggleConfirmModal={toggleConfirmModal}
                  toggleSecondModal={toggleSecondModal}
                />
              </div>
              <div className="error">{error ? 'Please complete all required fields' : ''}</div>
            </LeftActions>
          )}
          <Button onClick={this.submitClients} className={'purple'}>
            Add Clients
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AddMultipleClients;
