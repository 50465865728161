import React from 'react';

import Avatar from 'react-avatar';
import _ from 'lodash';
import { Header, Image, Icon, Form, Radio } from 'semantic-ui-react';
import TeamInviteModal from 'components/TeamInviteModal';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { showError } from 'actions/error';
import { showSuccess } from 'redux/modal/modal.actionCreators';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL, TEAM_ROLES } from 'constants/commonData';
import { convertS3UrlToCloudFrontUrl, enableCoachBio, getUserShortName } from 'utils/commonFunction';
import * as S from './styles';
import './styles.scss';
import TeammatesHeading from './TeammatesHeading';
import InviteIcon from 'assets/icons/invite.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link_invite_workspace.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/teamates-setting.svg';
import CoachBioList from 'components/CoachBios/CoachBioList';

const INVITE_LINK = process.env.REACT_APP_INVITE_LINK;

export default class Teammates extends React.Component {
  state = {
    searchQuery: '',
    sorter: 'name',
    isAscending: true,
    settings: false,
    settingsOld: false,
    enrollAwaitList: [],
    openSetting: false,
    teammateTab: true,
  };

  componentDidMount() {
    const { location, history } = this.props;
    const isFromCoachBios = _.get(location, 'state.fromCoachBios', false);
    this.viewListTeamate();
    if (isFromCoachBios) {
      this.setState({ teammateTab: false });
      history.replace();
    }
  }

  viewListTeamate = async () => {
    const { viewListTeamate, user } = this.props;

    let { enrollAwaitList, settings } = this.state;
    const team = user.teams[0].team;

    await viewListTeamate().then(result => {
      if (result && result.data && result.data.data) {
        result.data.data.map(
          item =>
            typeof item.is_enroll_auto_waiting !== 'undefined' &&
            enrollAwaitList.push({ _id: item._id, is_enroll_auto_waiting: item.is_enroll_auto_waiting }),
        );

        settings = team ? team.is_enroll_auto_waiting : settings;
        this.setState({ teamlist: result.data.data, enrollAwaitList, settings, settingsOld: settings });
      }
    });
  };

  handleAddNew = () => {
    const { dispatch, user } = this.props;
    if (user.teams && user.teams.length > 0 && user.teams[0].role === 1) {
      dispatch(
        showError(
          'To invite a new teammate, please ask one of the admins or account owner to give you account permission',
        ),
      );
    } else {
      dispatch(toggleModal(true, <TeamInviteModal viewListTeamate={this.viewListTeamate.bind(this)} />));
    }
  };

  handleShowTeammateTab = value => {
    this.setState({ teammateTab: value });
  };

  renderToolbar() {
    const { teammateTab } = this.state;

    return (
      <S.Toolbar>
        <S.TeammateTab active={teammateTab} onClick={() => this.handleShowTeammateTab(true)}>
          Team Members
        </S.TeammateTab>
        <S.TeammateTab active={!teammateTab} onClick={() => this.handleShowTeammateTab(false)}>
          Coach Bios
        </S.TeammateTab>
      </S.Toolbar>
    );
  }

  getInviteLink = teammate => {
    return `${INVITE_LINK}/${teammate.referral_code}`;
  };

  getInviteLinkLabel = teammate => {
    return `${INVITE_LINK}/${teammate.referral_code}`;
  };

  getUserAvatar = url => {
    const { cloudfrontList } = this.props;
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList) || url;
  };

  renderClientsList() {
    if (!this.state.teamlist) {
      return null;
    }

    let searchQuery = this.state.searchQuery;
    let list = this.state.teamlist
      .filter(item => {
        let name = `${item.first_name} ${item.last_name}`;
        return name.toLowerCase().includes(searchQuery.toLowerCase());
      })
      .map(user => {
        if (user.inviter) {
          user.status = 'Pending';
        } else {
          user.status = 'Active';
        }

        user.name = `${user.first_name} ${user.last_name}`;

        if (user.inviter) {
          user.role_name = TEAM_ROLES[user.role];
        } else {
          if (user.teams && user.teams.length > 0) {
            user.role_name = TEAM_ROLES[user.teams[0].role];
          } else {
            user.role_name = 'Owner';
          }
        }
        return user;
      });

    if (list.length === 0) {
      if (searchQuery === '') {
        return (
          <S.Wrapper>
            <Header style={{ textAlign: 'center' }} as={'h5'}>
              Add your first teamate.
            </Header>
          </S.Wrapper>
        );
      }
    }
    const { isAscending, sorter } = this.state;
    list = _.orderBy(
      list,
      e => {
        return e[sorter] ? e[sorter] : '';
      },
      isAscending ? 'asc' : 'desc',
    );

    let isAdmin = false;

    if (
      !this.props.user.teams ||
      this.props.user.teams.length === 0 ||
      this.props.user.teams[0].role === 0 ||
      this.props.user.teams[0].role === 2
    ) {
      isAdmin = true;
    }

    return (
      <S.TableContainer>
        <S.TableBodyContainer className="table-client-warper">
          <table border="0" cellSpacing="0" cellPadding="0" className="table-client-list table-team-list fade">
            <thead>
              <tr>
                <th
                  className="table-header-cell first-cell"
                  onClick={() => {
                    this.handleSortClick('name');
                  }}
                >
                  <div className={sorter === 'name' ? ' sort' : ''}>
                    <Image src={`${CDN_URL}/images/name.svg`} width="14" />
                    <span>Name</span>
                    {this.renderSorterIndicator('name')}
                  </div>
                </th>
                <th
                  className="table-header-cell second-cell"
                  onClick={() => {
                    this.handleSortClick('email');
                  }}
                >
                  <div className={sorter === 'email' ? ' sort' : ''}>
                    <Image src={`${CDN_URL}/images/email.svg`} width="14" />
                    <span>Email</span>
                    {this.renderSorterIndicator('email')}
                  </div>
                </th>
                <th
                  className="table-header-cell center-cell"
                  onClick={() => {
                    this.handleSortClick('status');
                  }}
                >
                  <div className={sorter === 'status' ? ' sort' : ''}>
                    <Image src={`${CDN_URL}/images/status.svg`} width="14" />
                    <span>Status</span>
                    {this.renderSorterIndicator('status')}
                  </div>
                </th>
                <th
                  className="table-header-cell center-cell"
                  onClick={() => {
                    this.handleSortClick('role_name');
                  }}
                >
                  <div className={sorter === 'role_name' ? ' sort' : ''}>
                    <Image src={`${CDN_URL}/images/role.svg`} width="14" />
                    <span>Role</span>
                    {this.renderSorterIndicator('role_name')}
                  </div>
                </th>
                <th className="table-header-cell invite-link-cell">
                  <div>
                    <LinkIcon />
                    <span>Invite link</span>
                    <S.SettingIconWrapper>
                      <S.SPopup
                        trigger={
                          <S.IconButton onClick={() => this.handleSettingClick()}>
                            <SettingIcon></SettingIcon>
                          </S.IconButton>
                        }
                        content="Invite Link Settings"
                        position="top center"
                        className="action-popup"
                      />
                    </S.SettingIconWrapper>
                  </div>
                </th>
                <th className="table-header-cell last-cell"></th>
              </tr>
            </thead>
            <tbody>
              {list.length === 0 ? (
                <div className="no-user-found">No users found</div>
              ) : (
                list.map((user, idx) => {
                  return (
                    <tr key={idx} className={idx % 2 === 0 ? 'even' : 'odd'}>
                      <td className="table-client-cell first-cell">
                        <div className="first-client-cell">
                          <div>
                            <Avatar
                              name={getUserShortName(user)}
                              className="client-avatar"
                              size="30"
                              src={this.getUserAvatar(user.avatar)}
                              color={user.color}
                            />
                          </div>
                          <span>{user.name}</span>
                        </div>
                      </td>
                      <td className="table-client-cell second-cell">{user.email}</td>
                      <td>{user.status}</td>
                      <td>{user.role_name}</td>
                      <td className="table-client-cell invite-link-cell">
                        {user.inviter ? (
                          ''
                        ) : (
                          <S.CopyInviteLink
                            noIcon
                            hoverLabel="Copy invite link"
                            copiedLabel="Invite link copied!"
                            copyLabel={this.getInviteLinkLabel(user)}
                            value={this.getInviteLink(user)}
                          />
                        )}
                      </td>
                      <td className="table-client-cell last-cell">
                        {isAdmin && (
                          <div className="teammate-options">
                            <div
                              onClick={() => {
                                if (user.inviter) {
                                  this.handleResendClick(user._id);
                                }
                              }}
                            >
                              {user.inviter ? 'Resend' : ''}
                            </div>
                            <div
                              onClick={() => {
                                if (user.inviter) {
                                  this.handleInvokeClick(user._id);
                                }
                              }}
                            >
                              {user.inviter ? 'Revoke' : ''}
                            </div>
                            <div
                              onClick={() => {
                                let teammateData = {
                                  inviteId: user.inviter ? user._id : null,
                                  userId: user.inviter ? null : user._id,
                                  role: user.inviter
                                    ? user.role
                                    : user.teams && user.teams.length > 0
                                    ? user.teams[0].role
                                    : 0,
                                  first_name: user.first_name,
                                  last_name: user.last_name,
                                  email: user.email,
                                  _id: user._id,
                                };
                                this.props.dispatch(
                                  toggleModal(
                                    true,
                                    <TeamInviteModal
                                      teammateData={teammateData}
                                      viewListTeamate={this.viewListTeamate.bind(this)}
                                    />,
                                  ),
                                );
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </S.TableBodyContainer>
      </S.TableContainer>
    );
  }

  handleResendClick(id) {
    this.props.resendInvokeInvitation(true, id).then(data => {
      if (data && data.data && data.data.data) {
        this.props.dispatch(showSuccess('We have sent a new invitation to your teammate!', null, null, 'OK'));
      }
    });
  }

  handleInvokeClick(id) {
    this.props.dispatch(
      toggleConfirmModal(
        true,
        <ConfirmModal
          title={'Revoke'}
          content={`Are you sure that you want to revoke this invitation?`}
          onConfirm={() =>
            this.props.resendInvokeInvitation(false, id).then(data => {
              if (data && data.data && data.data.data) {
                this.viewListTeamate();
                this.props.dispatch(showSuccess('You have succesfully revoke this invitation!', null, null, 'OK'));
              }
            })
          }
          onDeny={() => {}}
        />,
      ),
    );
  }

  handleSettingClick = () => {
    this.setState(
      {
        openSetting: true,
      },
      () => {
        this.props.dispatch(toggleConfirmModal(true));
      },
    );
  };

  handleSortClick = sorter => {
    this.setState(prevState => ({
      sorter: sorter,
      isAscending: !this.state.isAscending,
    }));
  };

  renderSorterIndicator(column_name) {
    const { sorter, isAscending } = this.state;

    if (sorter === column_name) {
      return <Icon name={'chevron ' + (isAscending ? 'down' : 'up')} />;
    } else {
      return <Image className="sorter-indicator" src={`${CDN_URL}/images/arrow-down.svg`} width="12" />;
    }
  }

  handleRenderSetting = () => {
    let { settings } = this.state;

    return (
      <Form.Group>
        <S.SettingLabel>
          Choose how you want new clients that sign up through an invite link to be added to the workspace:
        </S.SettingLabel>
        <S.SFormField
          value={false}
          control={Radio}
          checked={!settings}
          label={
            <S.SFormRadioLabel>
              <S.SFormRadioTextBold>Activate clients automatically,</S.SFormRadioTextBold> if your workspace has client
              seats available. But when seats run out, new sign ups will be added to the “Waiting Activation” list.
            </S.SFormRadioLabel>
          }
          onChange={(e, { value }) => this.handleSelectRadio(value)}
        />
        <S.SFormField
          value={true}
          control={Radio}
          checked={settings}
          label={
            <S.SFormRadioLabel>
              <S.SFormRadioTextBold>Always add clients to the “Waiting Activation” list.</S.SFormRadioTextBold> From
              there, you can activate each client individually.
            </S.SFormRadioLabel>
          }
          onChange={(e, { value }) => this.handleSelectRadio(value)}
        />
      </Form.Group>
    );
  };

  handleConfirmModal = async () => {
    const { changeAutoWaitingEnroll, updateSettingInvite } = this.props;
    const { settings } = this.state;

    changeAutoWaitingEnroll({
      is_enroll_auto_waiting: settings,
    });

    updateSettingInvite(settings);

    this.setState({ settingsOld: settings });
  };

  handleCancelModal = () => {
    const { settingsOld } = this.state;
    this.setState({ settings: settingsOld, openSetting: false });
  };

  handleSelectRadio = value => {
    this.setState({ settings: value });
  };

  render() {
    const { openSetting, teammateTab } = this.state;
    const { permission } = this.props;
    const isEnableCoachBio = (permission.explore_payment || permission.payment) && enableCoachBio();
    return (
      <S.Wrapper>
        {isEnableCoachBio && this.renderToolbar()}
        {teammateTab ? (
          <>
            <TeammatesHeading onCreateNew={this.handleAddNew} /> {this.renderClientsList()}
          </>
        ) : (
          <CoachBioList fromTeammate />
        )}

        {openSetting && (
          <S.SConfirmModal
            title="Invite Link Settings"
            content={this.handleRenderSetting()}
            onConfirm={() => {
              this.handleConfirmModal();
            }}
            confirmButtonTitle="Save"
            cancelButtonTitle="Cancel"
            confirmButtonClass="btn-purple"
            headerIcon={InviteIcon}
            onDeny={() => {
              this.handleCancelModal();
            }}
            onClose={this.handleCancelModal}
            newStyle
            hasCloseIcon
          />
        )}
      </S.Wrapper>
    );
  }
}
