import styled from 'styled-components';

export const PaymentFailWarningWrapper = styled.div`
  padding: 12px;
  background: #d4e5fa;
  text-align: center;

  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #000000;
  position: absolute;
  top: 0;
  width: 100%;

  .paymentFailWarning__link {
    color: #ff6666;
    font-weight: bold;
  }
`;

export const TextUppercase = styled.strong`
  text-transform: uppercase;
`;
