import React from 'react';
import Dropzone from 'react-dropzone';
import { SelectFile } from 'shared/FormControl';
import FileUpload from 'shared/FileUpload';
import * as S from './style';
import { DEFAULT_UPLOAD_SETTINGS } from './constants';
import classNames from 'classnames';

const UploadCoverImage = ({
  src,
  file,
  isFirstTime,
  onConfirm,
  onDropFile,
  onSelectFile,
  onRemove,
  onUploadSuccess,
  onCancelUpload,
  uploadConfigs,
  isLoading,
  disabled = false,
}) => {
  if (!isFirstTime) {
    return (
      <S.FirstTimeWrapper>
        <S.BgWrapper>
          <S.PlaceholderBg />
        </S.BgWrapper>
        <S.Aura />
        <S.PlaceholderMessage>
          <S.PlaceholderTitle>Add a Cover Image</S.PlaceholderTitle>
          <S.PlaceholderDesc>
            Upload a cover image for your resource collection, or else a default graphic will be displayed on the client
            app.
          </S.PlaceholderDesc>
          <S.PlaceholderPreview />
          <S.PlaceholderActions>
            <S.PlaceholderConfirm onClick={onConfirm}>Got it</S.PlaceholderConfirm>
          </S.PlaceholderActions>
        </S.PlaceholderMessage>
      </S.FirstTimeWrapper>
    );
  }

  return (
    <S.UploadWrapperContainer disabled={disabled}>
      <Dropzone onDrop={onDropFile} multiple={false} disabled={disabled}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <S.UploadWrapper {...getRootProps()} isDragging={isDragActive}>
            <>
              <SelectFile
                trigger={<S.DropWrapper src={src} isDefault={!src}></S.DropWrapper>}
                className={classNames('uploadCover', src && 'available')}
                onSelect={onSelectFile}
                accept="image/png, image/jpeg, image/jpg"
                validateExtentions={DEFAULT_UPLOAD_SETTINGS.validateExtensions}
                maxSize={DEFAULT_UPLOAD_SETTINGS.maxSize}
                fileSizeLimitMessage={DEFAULT_UPLOAD_SETTINGS.maxSizeMessage}
                disabled={disabled}
              />
              <input {...getInputProps({ onClick: event => event.preventDefault() })} className="dropzone__input" />
            </>
            {src && !isLoading && <S.RemoveIcon onClick={onRemove} />}
            {file && (
              <FileUpload
                onSuccess={onUploadSuccess}
                onError={() => {}}
                onCancel={onCancelUpload}
                configs={uploadConfigs}
              />
            )}
          </S.UploadWrapper>
        )}
      </Dropzone>
    </S.UploadWrapperContainer>
  );
};
export default UploadCoverImage;
