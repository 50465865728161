import Request, { axiosInstance } from 'configs/request';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { push } from 'connected-react-router';
import { showError, hideError } from 'actions/error';

// Utils
import { isTrainer } from 'utils/validations';

// Constants
import { ASSIGNED_STATUS, MEAL_PLAN_STATUS } from 'components/MealPlanDetail/constants';
import { ERROR_CODE_BAD_REQUEST, ERROR_CODE_FORBIDDEN } from 'constants/commonData';
import { MEAL_PLAN_HEADER_TABS } from 'components/MealPlans/constants';

export const Types = {
  MEAL_PLAN_ADD_REQUEST: 'MEAL_PLAN_ADD_REQUEST',
  MEAL_PLAN_ADD_SUCCESS: 'MEAL_PLAN_ADD_SUCCESS',
  MEAL_PLAN_ADD_FAILED: 'MEAL_PLAN_ADD_FAILED',
  MEAL_PLAN_GET_LIST_REQUEST: 'MEAL_PLAN_GET_LIST_REQUEST',
  MEAL_PLAN_GET_LIST_SUCCESS: 'MEAL_PLAN_GET_LIST_SUCCESS',
  MEAL_PLAN_GET_LIST_FAILED: 'MEAL_PLAN_GET_LIST_FAILED',
  MEAL_PLAN_GET_TOTAL_LIST: 'MEAL_PLAN_GET_TOTAL_LIST',
  MEAL_PLANS_CHANGE_QUERY_PARAMS: 'MEAL_PLANS_CHANGE_QUERY_PARAMS',
  MEAL_PLANS_REMOVE_MEAL_PLAN: 'MEAL_PLANS_REMOVE_MEAL_PLAN',
  MEAL_PLANS_SHARE_MEAL_PLAN: 'MEAL_PLANS_SHARE_MEAL_PLAN',
  MEAL_PLAN_SELECT_MEAL_DAY_DETAIL: 'MEAL_PLAN_SELECT_MEAL_DAY_DETAIL',
  MEAL_PLAN_ADD_MEAL_DAY_DETAIL: 'MEAL_PLAN_ADD_MEAL_DAY_DETAIL',
  MEAL_PLAN_UPDATE_DATA_DAY_DETAIL: 'MEAL_PLAN_UPDATE_DATA_DAY_DETAIL',
  MEAL_PLAN_GET_DETAIL_REQUEST: 'MEAL_PLAN_GET_DETAIL_REQUEST',
  MEAL_PLAN_GET_DETAIL_SUCCESS: 'MEAL_PLAN_GET_DETAIL_SUCCESS',
  MEAL_PLAN_GET_DETAIL_FAILED: 'MEAL_PLAN_GET_DETAIL_FAILED',
  MEAL_PLAN_GET_DETAIL_BY_WEEK_REQUEST: 'MEAL_PLAN_GET_DETAIL_BY_WEEK_REQUEST',
  MEAL_PLAN_GET_DETAIL_BY_WEEK_SUCCESS: 'MEAL_PLAN_GET_DETAIL_BY_WEEK_SUCCESS',
  MEAL_PLAN_GET_DETAIL_BY_WEEK_FAILED: 'MEAL_PLAN_GET_DETAIL_BY_WEEK_FAILED',
  MEAL_PLAN_UPDATE_BY_WEEK_REQUEST: 'MEAL_PLAN_UPDATE_BY_WEEK_REQUEST',
  MEAL_PLAN_UPDATE_BY_WEEK_SUCCESS: 'MEAL_PLAN_UPDATE_BY_WEEK_SUCCESS',
  MEAL_PLAN_UPDATE_BY_WEEK_FAILED: 'MEAL_PLAN_UPDATE_BY_WEEK_FAILED',
  MEAL_PLAN_RESET_DETAIL: 'MEAL_PLAN_RESET_DETAIL',
  MEAL_PLAN_RESET_DETAIL_BY_WEEK: 'MEAL_PLAN_RESET_DETAIL_BY_WEEK',
  MEAL_PLAN_GET_DAY_OF_WEEK_REQUEST: 'MEAL_PLAN_GET_DAY_OF_WEEK_REQUEST',
  MEAL_PLAN_GET_DAY_OF_WEEK_SUCCESS: 'MEAL_PLAN_GET_DAY_OF_WEEK_SUCCESS',
  MEAL_PLAN_GET_DAY_OF_WEEK_FAILED: 'MEAL_PLAN_GET_DAY_OF_WEEK_FAILED',
  MEAL_PLAN_UPDATE_DAY_OF_WEEK_REQUEST: 'MEAL_PLAN_UPDATE_DAY_OF_WEEK_REQUEST',
  MEAL_PLAN_UPDATE_DAY_OF_WEEK_SUCCESS: 'MEAL_PLAN_UPDATE_DAY_OF_WEEK_SUCCESS',
  MEAL_PLAN_UPDATE_DAY_OF_WEEK_FAILED: 'MEAL_PLAN_UPDATE_DAY_OF_WEEK_FAILED',
  MEAL_PLAN_RESET_DETAIL_BY_DAY: 'MEAL_PLAN_RESET_DETAIL_BY_DAY',
  MEAL_PLAN_CHANGE_MODE_EDIT_BY_DAY: 'MEAL_PLAN_CHANGE_MODE_EDIT_BY_DAY',
  MEAL_PLAN_ADD_WEEK_REQUEST: 'MEAL_PLAN_ADD_WEEK_REQUEST',
  MEAL_PLAN_ADD_WEEK_SUCCESS: 'MEAL_PLAN_ADD_WEEK_SUCCESS',
  MEAL_PLAN_ADD_WEEK_FAILED: 'MEAL_PLAN_ADD_WEEK_FAILED',
  MEAL_PLAN_REMOVE_WEEK_REQUEST: 'MEAL_PLAN_REMOVE_WEEK_REQUEST',
  MEAL_PLAN_REMOVE_WEEK_SUCCESS: 'MEAL_PLAN_REMOVE_WEEK_SUCCESS',
  MEAL_PLAN_REMOVE_WEEK_FAILED: 'MEAL_PLAN_REMOVE_WEEK_FAILED',
  MEAL_PLAN_UPDATE_REQUEST: 'MEAL_PLAN_UPDATE_REQUEST',
  MEAL_PLAN_UPDATE_SUCCESS: 'MEAL_PLAN_UPDATE_SUCCESS',
  MEAL_PLAN_UPDATE_FAILED: 'MEAL_PLAN_UPDATE_FAILED',
  MEAL_PLAN_DUPLICATE_REQUEST: ' MEAL_PLAN_DUPLICATE_REQUEST',
  MEAL_PLAN_DUPLICATE_SUCCESS: ' MEAL_PLAN_DUPLICATE_SUCCESS',
  MEAL_PLAN_DUPLICATE_FAILED: ' MEAL_PLAN_DUPLICATE_FAILED',
  MEAL_PLAN_ASSIGN_MEAL_PLAN_REQUEST: 'MEAL_PLAN_ASSIGN_MEAL_PLAN_REQUEST',
  MEAL_PLAN_ASSIGN_MEAL_PLAN_SUCCESS: 'MEAL_PLAN_ASSIGN_MEAL_PLAN_SUCCESS',
  MEAL_PLAN_ASSIGN_MEAL_PLAN_FAILED: 'MEAL_PLAN_ASSIGN_MEAL_PLAN_FAILED',
  MEAL_PLAN_GET_ASSIGNED_LIST_REQUEST: 'MEAL_PLAN_GET_ASSIGNED_LIST_REQUEST',
  MEAL_PLAN_GET_ASSIGNED_LIST_SUCCESS: 'MEAL_PLAN_GET_ASSIGNED_LIST_SUCCESS',
  MEAL_PLAN_GET_ASSIGNED_LIST_FAILED: 'MEAL_PLAN_GET_ASSIGNED_LIST_FAILED',
  MEAL_PLAN_UNASSIGN_CLIENT_REQUEST: 'MEAL_PLAN_UNASSIGN_CLIENT_REQUEST',
  MEAL_PLAN_UNASSIGN_CLIENT_SUCCESS: 'MEAL_PLAN_UNASSIGN_CLIENT_SUCCESS',
  MEAL_PLAN_UNASSIGN_CLIENT_FAILED: 'MEAL_PLAN_UNASSIGN_CLIENT_FAILED',
  MEAL_PLAN_RESET_FILTERS: 'MEAL_PLAN_RESET_FILTERS',
  MEAL_PLAN_CHANGE_MODE_REARRANGE: 'MEAL_PLAN_CHANGE_MODE_REARRANGE',
  MEAL_PLAN_START_SUBMIT: 'MEAL_PLAN_START_SUBMIT',
  MEAL_PLAN_END_SUBMIT: 'MEAL_PLAN_END_SUBMIT',
  MEAL_PLAN_UPDATE_DETAIL: 'MEAL_PLAN_UPDATE_DETAIL',
  MEAL_PLAN_UPDATE_DETAIL_BY_WEEK: 'MEAL_PLAN_UPDATE_DETAIL_BY_WEEK',
  MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_REQUEST: 'MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_REQUEST',
  MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_SUCCESS: 'MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_SUCCESS',
  MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_FAILED: 'MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_FAILED',
  MEAL_PLAN_SET_HEADER_TABS: 'MEAL_PLAN_SET_HEADER_TABS',
  MEAL_PLAN_SET_CURRENT_LIST: 'MEAL_PLAN_SET_CURRENT_LIST',
};

export const ERROR_CODE_GET_MEAL_PLAN = '27_4';
export const ERROR_CODE_UPDATE_MEAL_PLAN = '27_15';

const handleLostPermission = ({ dispatch, getState, message, errorCode }) => {
  if (
    errorCode === ERROR_CODE_GET_MEAL_PLAN ||
    errorCode === ERROR_CODE_UPDATE_MEAL_PLAN ||
    errorCode === ERROR_CODE_FORBIDDEN ||
    errorCode === ERROR_CODE_BAD_REQUEST
  ) {
    dispatch({
      type: Types.MEAL_PLAN_UPDATE_DETAIL,
      payload: { data: { is_edit_mode: false } },
    });
    dispatch(showError(message, null, null, null, null, () => backToMealPlans(dispatch, getState)));
  }
};

export const addMealPlan = (params, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_ADD_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/meal-plans`,
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});

          if (!isEmpty(data)) {
            const mealPlanId = get(data, '_id', '');
            const weekId = get(data, 'weeks.[0]', '');
            dispatch({ type: Types.MEAL_PLAN_ADD_SUCCESS, payload: data });
            dispatch(push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}`));
            callback && callback();
          }
        },
        error => {
          dispatch({ type: Types.MEAL_PLAN_ADD_FAILED });
        },
      ),
    );
  };
};

export const getListMealPlan = params => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_GET_LIST_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/meal-plans`,
          params: params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data.data', []);
          const total = get(response, 'data.data.total', []);

          if (data) {
            dispatch({
              type: Types.MEAL_PLAN_GET_LIST_SUCCESS,
              payload: { resList: data, total, page: get(params, 'page', 1) },
            });
            dispatch(getTotalMealPlan({ text_search: get(params, 'text_search', '') }));
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_GET_LIST_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const getTotalMealPlan = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: { mealPlans },
    } = getState();
    const { filters } = mealPlans || {};
    const dataFilters = { ...omit(filters, ['only_my_meal_plans']), status: [MEAL_PLAN_STATUS.PUBLISH] };
    dispatch({ type: Types.MEAL_PLAN_GET_LIST_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/meal-plans/total`,
          params: { ...dataFilters, ...params },
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            const isDraft = get(dataFilters, 'status').includes(MEAL_PLAN_STATUS.DRAFT);
            dispatch({ type: Types.MEAL_PLAN_GET_TOTAL_LIST, payload: { ...data, isDraft } });
          }
        },
      ),
    );
  };
};

export const changeQueryParams = (params = {}) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { mealPlans },
    } = getState();
    const filters = get(mealPlans, 'filters', {});

    const pickParams = pick(filters, ['page', 'per_page', 'text_search', 'sort', 'sorter', 'only_my_meal_plans']);
    const newQuery = {
      ...pickParams,
      ...params,
    };

    dispatch({ type: Types.MEAL_PLANS_CHANGE_QUERY_PARAMS, payload: newQuery });
  };
};

export const removeMealPlan = id => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { filters } = get(rootReducer, 'mealPlans', null);

    return dispatch(
      Request.delete(
        {
          url: `/api/meal-plans/${id}`,
          data: { id },
        },
        false,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.MEAL_PLANS_REMOVE_MEAL_PLAN, payload: id });
            dispatch(getTotalMealPlan({ text_search: get(filters, 'text_search', '') }));
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const sharedMealPlan = (mealPlanId, data, callBack) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: { mealPlans },
    } = getState();
    const filters = get(mealPlans, 'filters', {});

    return dispatch(
      Request.post(
        {
          url: `/api/meal-plans/${mealPlanId}/share`,
          data,
        },
        false,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.MEAL_PLANS_SHARE_MEAL_PLAN, payload: { data, mealPlanId, user } });
            dispatch(getListMealPlan(filters));
            callBack && callBack();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          callBack && callBack();

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const selectMealPlanDayDetail = id => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_SELECT_MEAL_DAY_DETAIL, payload: { id } });
  };
};

export const addMealPlanDayDetail = (data, status = false) => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_ADD_MEAL_DAY_DETAIL, payload: { data, status } });
  };
};

export const getDetailMealPlan = (mealPlanId, weekId, isRefetch = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const mealPlanIdStore = get(state, 'rootReducer.mealPlans.detailMealPlan._id', '');

    if (mealPlanIdStore !== mealPlanId && isRefetch) {
      return;
    }

    dispatch({ type: Types.MEAL_PLAN_GET_DETAIL_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/meal-plans/${mealPlanId}`,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.MEAL_PLAN_GET_DETAIL_SUCCESS, payload: { data } });
            dispatch(getDetailMealPlanByWeek(mealPlanId, weekId, isRefetch));
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_GET_DETAIL_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const getDetailMealPlanByWeek = (mealPlanId, weekId, isRefetch = false) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_GET_DETAIL_BY_WEEK_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/meal-plans/${mealPlanId}/weeks/${weekId}`,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});

          if (data) {
            dispatch({ type: Types.MEAL_PLAN_GET_DETAIL_BY_WEEK_SUCCESS, payload: { data } });
            if (isRefetch) {
              dispatch(push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}`));
            }
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_GET_DETAIL_BY_WEEK_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const updateMealPlanByWeek = (mealPlanId, weekId, data) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_UPDATE_BY_WEEK_REQUEST });
    return dispatch(
      Request.patch(
        {
          url: `/api/meal-plans/${mealPlanId}/weeks/${weekId}`,
          data: data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.MEAL_PLAN_UPDATE_BY_WEEK_SUCCESS, payload: { data } });
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_UPDATE_BY_WEEK_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const resetDetailMealPlan = () => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_RESET_DETAIL });
  };
};

export const resetDetailMealPlanByWeek = () => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_RESET_DETAIL_BY_WEEK });
  };
};

export const updateMealPlanByDay = (data, status = false) => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_UPDATE_DATA_DAY_DETAIL, payload: { data, status } });
  };
};

export const getMealPlanDayOfWeek = (mealPlanId, weekId, dayIndex) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_GET_DAY_OF_WEEK_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/meal-plans/${mealPlanId}/weeks/${weekId}/days/${dayIndex}`,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.MEAL_PLAN_GET_DAY_OF_WEEK_SUCCESS, payload: { data } });
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_GET_DAY_OF_WEEK_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const addWeekInMealPlan = (id, data, type, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_ADD_WEEK_REQUEST });
    return dispatch(
      Request.post(
        { url: `/api/meal-plans/${id}/weeks`, data },
        true,
        (response, { dispatch }) => {
          const result = get(response, 'data.data', {});
          if (!isEmpty(result)) {
            dispatch({
              type: Types.MEAL_PLAN_ADD_WEEK_SUCCESS,
              payload: { data: result, weekIndex: get(data, 'week_index'), type },
            });
            callback && callback();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_ADD_WEEK_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const updateMealPlanDayOfWeek = (mealPlanId, weekId, dayIndex, data, callBack) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_UPDATE_DAY_OF_WEEK_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/meal-plans/${mealPlanId}/weeks/${weekId}/days/${dayIndex}`,
          data: data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.MEAL_PLAN_UPDATE_DAY_OF_WEEK_SUCCESS, payload: { data } });
            callBack && callBack();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_UPDATE_DAY_OF_WEEK_FAILED, payload: { errorCode } });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const removeWeekInMealPlan = (mealPlanId, weekId, weekIndex, callBack) => {
  return (dispatch, getState) => {
    const state = getState();

    const detailMealPlan = get(state, 'rootReducer.mealPlans.detailMealPlan', {});
    const weeks = get(detailMealPlan, 'weeks', []);
    const numberOfWeeks = get(detailMealPlan, 'number_of_weeks', 0);
    weeks.splice(weekIndex, 1);
    const data = { ...detailMealPlan, number_of_weeks: numberOfWeeks - 1, weeks };

    dispatch({ type: Types.MEAL_PLAN_REMOVE_WEEK_REQUEST });
    return dispatch(
      Request.delete(
        { url: `/api/meal-plans/${mealPlanId}/weeks/${weekId}` },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.MEAL_PLAN_REMOVE_WEEK_SUCCESS, payload: { data } });
            callBack && callBack();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_REMOVE_WEEK_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const resetDetailMealPlanByDay = () => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_RESET_DETAIL_BY_DAY });
  };
};

export const changeModeEditByDay = status => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_CHANGE_MODE_EDIT_BY_DAY, payload: { status } });
  };
};

export const updateMealPlan = (id, data, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_UPDATE_REQUEST });
    return dispatch(
      Request.patch(
        {
          url: `/api/meal-plans/${id}`,
          data: data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.MEAL_PLAN_UPDATE_SUCCESS, payload: { data } });
            callback && callback();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_UPDATE_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const duplicateMealPlan = (id, data) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_DUPLICATE_REQUEST });
    return dispatch(
      Request.post(
        { url: `/api/meal-plans/${id}/duplicate`, data },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          const mealPlanId = get(data, '_id', '');
          const weekId = get(data, 'weeks.[0]', '');

          if (data) {
            dispatch({
              type: Types.MEAL_PLAN_DUPLICATE_SUCCESS,
            });
            dispatch(push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}`));
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_DUPLICATE_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const assignMealPlan = (params, callback, isCallback = false, isClientTab = false) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_ASSIGN_MEAL_PLAN_REQUEST });
    return dispatch(
      Request.post(
        {
          url: '/api/meal-plan-clients/assign-clients',
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});

          if (!isEmpty(data)) {
            dispatch({ type: Types.MEAL_PLAN_ASSIGN_MEAL_PLAN_SUCCESS, payload: data });

            if ((get(data, 'valid_clients.length') !== 0 && get(data, 'invalid_clients.length') === 0) || isCallback) {
              callback && callback();
            }
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_ASSIGN_MEAL_PLAN_FAILED });

          if (!isClientTab) {
            handleLostPermission({ dispatch, getState, message, errorCode });
          }
        },
      ),
    );
  };
};

export const getAssignedList = (params, allowDispatch = true) => {
  return (dispatch, getState) => {
    if (allowDispatch) {
      dispatch({ type: Types.MEAL_PLAN_GET_ASSIGNED_LIST_REQUEST });
    }
    return dispatch(
      Request.get(
        {
          url: '/api/meal-plan-clients',
          params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data');
          if (data && allowDispatch) {
            dispatch({ type: Types.MEAL_PLAN_GET_ASSIGNED_LIST_SUCCESS, payload: { data } });
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          if (allowDispatch) {
            dispatch({ type: Types.MEAL_PLAN_GET_ASSIGNED_LIST_FAILED });
          }

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const unassignedMealPlan = (data, status) => {
  return (dispatch, getState) => {
    return dispatch(
      Request.post(
        {
          url: '/api/meal-plan-clients/unassign-clients',
          data,
        },
        true,
        (_, { dispatch }) => {
          if (status === ASSIGNED_STATUS.ACTIVE) {
            const params = {
              meal_plan_id: data.meal_plan_id,
              status: ASSIGNED_STATUS.ACTIVE,
            };
            dispatch(getAssignedList(params));
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const maskAsComplete = (data, status) => {
  return (dispatch, getState) => {
    return dispatch(
      Request.post(
        {
          url: '/api/meal-plan-clients/mark-completed',
          data,
        },
        true,
        (_, { dispatch }) => {
          if (status === ASSIGNED_STATUS.ACTIVE) {
            const params = {
              meal_plan_id: data.meal_plan_id,
              status: ASSIGNED_STATUS.ACTIVE,
            };
            dispatch(getAssignedList(params));
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const searchAssignMealPlanList = data => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { mealPlanId, search, page = 1, startingDay, except: exclude_ids } = data || {};

    const params = {
      search,
      page,
      per_page: 2,
      exclude_ids,
      client_status: [0, 1],
      only_groups_have_clients: true,
    };

    if (isTrainer(user)) params.only_my_groups = true;

    return Promise.all([
      axiosInstance.get('/api/group/list', {
        params,
      }),
      axiosInstance.get('/api/meal-plan-clients/search-users', {
        params: {
          meal_plan_id: mealPlanId,
          text_search: search,
          page,
          per_page: 50,
          starting_day: startingDay,
          include_demo_clients: false,
        },
      }),
    ]);
  };
};

export const resetFilters = () => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_RESET_FILTERS });
  };
};

export const resetCurrentList = () => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_SET_CURRENT_LIST });
  };
};

export const changeModeRearrange = () => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_CHANGE_MODE_REARRANGE });
  };
};

export const publishMealPlan = (id, callBack) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_START_SUBMIT });
    return dispatch(
      Request.post(
        { url: `/api/meal-plans/${id}/publish` },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({
              type: Types.MEAL_PLAN_UPDATE_DETAIL,
              payload: { data: { status: MEAL_PLAN_STATUS.PUBLISH } },
            });
            typeof callBack === 'function' && callBack();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_END_SUBMIT });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const unpublishMealPlan = (id, callBack) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_START_SUBMIT });
    return dispatch(
      Request.post(
        { url: `/api/meal-plans/${id}/unpublish` },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({
              type: Types.MEAL_PLAN_UPDATE_DETAIL,
              payload: { data: { status: MEAL_PLAN_STATUS.DRAFT, is_syncing: true } },
            });
            typeof callBack === 'function' && callBack();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_END_SUBMIT });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const startEditMealPlan = id => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_START_SUBMIT });
    return dispatch(
      Request.patch(
        { url: `/api/meal-plans/${id}/start-edit-mode` },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({
              type: Types.MEAL_PLAN_UPDATE_DETAIL,
              payload: { data: { is_edit_mode: true } },
            });
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_END_SUBMIT });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const cancelEditMealPlan = (id, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_START_SUBMIT });
    return dispatch(
      Request.patch(
        { url: `/api/meal-plans/${id}/cancel-edit-mode` },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({
              type: Types.MEAL_PLAN_UPDATE_DETAIL,
              payload: { data: { is_edit_mode: false, is_syncing: true } },
            });
          }
          if (typeof callback === 'function') {
            callback();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_END_SUBMIT });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const publishChangeMealPlan = (id, data, callBack) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_START_SUBMIT });
    return dispatch(
      Request.post(
        { url: `/api/meal-plans/${id}/publish-change`, data },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({
              type: Types.MEAL_PLAN_UPDATE_DETAIL,
              payload: { data: { is_edit_mode: false, is_syncing: true } },
            });
            typeof callBack === 'function' && callBack();
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_END_SUBMIT });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
      ),
    );
  };
};

export const syncingMealPlanSuccess = data => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_UPDATE_DETAIL, payload: { data } });
  };
};

export const getTotalAssignedClientList = params => {
  return Request.get({ url: '/api/meal-plan-clients/total', params }, true);
};

export const updateDetailMealPlanByWeek = data => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_UPDATE_DETAIL_BY_WEEK, payload: { data } });
  };
};

export const updateDataDaysByWeek = (id, weekId, data) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/meal-plans/${id}/weeks/${weekId}/days`,
          data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          const { meal_plan_days: days = [] } = data;
          if (days) {
            dispatch({ type: Types.MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_SUCCESS, payload: { data: days } });
          }
        },
        error => {
          const { data } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};
          dispatch({ type: Types.MEAL_PLAN_UPDATE_DATA_DAYS_BY_WEEK_FAILED });

          handleLostPermission({ dispatch, getState, message, errorCode });
        },
        false,
      ),
    );
  };
};

export const setHeaderTabs = tab => {
  return dispatch => {
    dispatch({ type: Types.MEAL_PLAN_SET_HEADER_TABS, payload: { tab } });
  };
};

export const backToMealPlans = (dispatch, getState) => {
  const {
    rootReducer: { mealPlans },
  } = getState() || {};
  const { filters } = mealPlans || {};

  dispatch(hideError(false));
  dispatch(
    changeQueryParams({
      ...filters,
    }),
  );
  dispatch(push('/home/meal-plans'));
  dispatch(setHeaderTabs(MEAL_PLAN_HEADER_TABS.ALL_PLAN));
};
