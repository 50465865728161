import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';

import { customStyleSingle, customStyleMultiple, customStyleImport, TeammateDropdownWrapper } from './style';
import CustomOption from './CustomOption';
import { axiosInstance } from 'configs/request';

export const TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  IMPORT: 'import',
};

export const SORT = {
  ASC: 1,
  DESC: -1,
};

export const SORTER = {
  FULL_NAME: 'full_name',
  EMAIL: 'email',
};

export const PER_PAGE = 20;

export const URL_API_LIST_MEMBER = '/api/v2/team/list-member';

const TeammateDropdown = props => {
  const { type = TYPE.SINGLE, name, onChange, value, disabled, user } = props;
  const selectRef = useRef();
  const loadingRef = useRef(false);
  const isEndLoadOptions = useRef(false);
  const queryRef = useRef({
    page: 1,
    per_page: PER_PAGE,
    search: '',
    sort: SORT.ASC,
    sorter: SORTER.FULL_NAME,
  });
  const placeholder = useMemo(() => get(props, 'placeholder', 'Choose Coach'), [props]);
  const noOptionsMessage = useMemo(() => get(props, 'noOptionsMessage', 'No results found'), [props]);
  const styles = useMemo(() => {
    switch (type) {
      case TYPE.IMPORT:
        return customStyleImport;
      case TYPE.MULTIPLE:
        return customStyleMultiple;
      case TYPE.SINGLE:
      default:
        return customStyleSingle;
    }
  }, [type]);
  const menuPortalTarget = useMemo(() => {
    if (type === TYPE.MULTIPLE) return document.body;
    return false;
  }, [type]);
  const [menuPlacement, setMenuPlacement] = useState(() => get(props, 'menuPlacement', 'auto'), [props]);
  const [options, setOptions] = useState([]);
  const [valueTeammate, setValueTeammate] = useState(value || user);
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    let ignore = false;
    initOptions(ignore);

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (menuOpen && options.length > 0) {
      const menuListContainer = document.querySelector('.teammate__menu .teammate__menu-list');
      if (menuListContainer) {
        menuListContainer.addEventListener('scroll', event => {
          const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
          if (bottom) debounceScrollBottom();
        });
      }
    }
  }, [menuOpen, options]);

  const initOptions = useCallback(async ignore => {
    try {
      loadingRef.current = true;
      setIsLoading(true);
      const params = queryRef.current;
      const res = await axiosInstance.get(URL_API_LIST_MEMBER, { params });
      const data = get(res, 'data.data', []);
      if (ignore) return;
      loadingRef.current = false;
      isEndLoadOptions.current = data.length < PER_PAGE;
      setIsLoading(false);
      const filteredNewList = data.filter(item => get(item, '_id') !== get(user, '_id'));
      filteredNewList.unshift(user);
      setOptions(filteredNewList);
    } catch (error) {
      loadingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  const handleScrollBottom = useCallback(async () => {
    try {
      if (loadingRef.current || isEndLoadOptions.current) return;
      loadingRef.current = true;
      setIsLoading(true);
      queryRef.current = {
        ...queryRef.current,
        page: queryRef.current.page + 1,
      };
      const params = queryRef.current;
      // Show loading indicator
      const loadingBox = document.createElement('div');
      loadingBox.classList.add('teammate-loading');
      loadingBox.innerHTML = 'Loading...';
      const parentData = document.querySelector('.teammate__menu .teammate__menu-list');
      parentData && loadingBox && parentData.appendChild(loadingBox);
      // Fetch data
      const res = await axiosInstance.get(URL_API_LIST_MEMBER, { params });
      const data = get(res, 'data.data', []);
      loadingRef.current = false;
      isEndLoadOptions.current = data.length < PER_PAGE;
      setIsLoading(false);
      setOptions(it => {
        const newList = unionBy(it, data, '_id');
        const filteredNewList = newList.filter(item => get(item, '_id') !== get(user, '_id'));
        filteredNewList.unshift(user);
        return filteredNewList;
      });
      // Hide loading indicator
      parentData && loadingBox && parentData.removeChild(loadingBox);
    } catch (error) {
      loadingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  const debounceScrollBottom = debounce(handleScrollBottom, 500);

  const onInputChange = useCallback(async (inputValue, actionMeta) => {
    try {
      if (loadingRef.current || actionMeta.action !== 'input-change') return;
      loadingRef.current = true;
      setIsLoading(true);
      queryRef.current = {
        ...queryRef.current,
        page: 1,
        search: inputValue,
      };
      const params = queryRef.current;
      // Fetch data
      const res = await axiosInstance.get(URL_API_LIST_MEMBER, { params });
      const data = get(res, 'data.data', []);
      loadingRef.current = false;
      isEndLoadOptions.current = data.length < PER_PAGE;
      setIsLoading(false);
      setOptions(it => {
        const newList = unionBy(it, data, '_id');
        const filteredNewList = newList.filter(item => get(item, '_id') !== get(user, '_id'));
        filteredNewList.unshift(user);
        return filteredNewList;
      });
      const parentData = document.querySelector('.teammate__menu .teammate__menu-list');
      parentData && parentData.scroll({ top: 0, behavior: 'smooth' });
    } catch (error) {
      loadingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  const debounceInputChange = debounce(onInputChange, 500);

  const handleChange = useCallback(option => {
    setValueTeammate(option);
    onChange && onChange(option);
  }, []);

  const checkPlacement = useCallback(() => {
    if (type === TYPE.SINGLE || type === TYPE.MULTIPLE) {
      if (typeof selectRef.current.getBoundingClientRect === 'function') {
        const rect = selectRef.current.getBoundingClientRect();
        if (window.innerHeight - rect.bottom <= 283) {
          setMenuPlacement('top');
        } else {
          menuPlacement !== get(props, 'menuPlacement', 'auto') &&
            setMenuPlacement(get(props, 'menuPlacement', 'auto'));
        }
      }
    }
  }, [menuPlacement, selectRef, type]);

  const onMenuOpen = useCallback(() => {
    checkPlacement();
    setMenuOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <TeammateDropdownWrapper ref={selectRef}>
      <Select
        blurInputOnSelect
        isMulti={false}
        isLoading={isLoading}
        isSearchable
        onChange={handleChange}
        name={name}
        value={valueTeammate}
        isClearable={false}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        styles={styles}
        classNamePrefix="teammate"
        className="select-teammate"
        noOptionsMessage={() => noOptionsMessage}
        getOptionLabel={option =>
          get(option, 'full_name') || `${get(option, 'first_name')} ${get(option, 'last_name')}`
        }
        getOptionValue={option => get(option, '_id')}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onInputChange={debounceInputChange}
        components={{
          IndicatorSeparator: null,
          Option: CustomOption,
        }}
      />
    </TeammateDropdownWrapper>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeammateDropdown);
