import React from 'react';
import * as S from './style';
import { DropdownIndicator } from 'shared/Icons';
import { DATE_FORMATS } from 'constants/commonData';
import DropdownOptions from 'shared/DropdownOptions';

const DateFormatSelect = ({ fullWidth = true, dateFormat, onChange, onScrollDown }) => {
  return (
    <S.DateSettingsWrapper fullWidth={fullWidth}>
      <div>
        <S.TextBold>Date Settings</S.TextBold>
        <S.Text>Current date format</S.Text>
      </div>
      <DropdownOptions
        width="145px"
        height="40px"
        options={DATE_FORMATS}
        selectedValue={dateFormat.value}
        onSelect={onChange}
        customIndicator={DropdownIndicator}
        onScrollBottom={onScrollDown}
        isForceUpdate={true}
      />
    </S.DateSettingsWrapper>
  );
};

export default DateFormatSelect;
