import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { getAutoflowDetail, resetDetailData, getAutoflowClientStatistic } from 'redux/autoflow/actions';
import { getMetrics, getFeaturePreferences, getWorkoutVisibility } from 'redux/autoflow/settings/actions';
import { getHabitUnits } from 'redux/habits/actions';
import AutoflowIntervalTaskCalendarLayout from 'components/AutoflowInterval/Task/TaskCalendarContainer';
import AutoflowIntervalTaskCell from 'components/AutoflowInterval/Task/Cell';
import AutoflowTraining from 'components/AutoflowTraining';
import AutoflowTask from 'components/AutoflowTask';
import AutoMessage from 'components/AutoflowAutoMessage';
import TrainingCell from 'components/AutoflowTraining/Cell';
import TaskCell from 'components/AutoflowTask/Cell';
import MessageCell from 'components/AutoflowAutoMessage/Cell';
import AutoflowInAppMessage from 'components/AutoflowAutoMessage/InAppMessage';
import Announcement from 'components/AutoflowAutoMessage/Announcement';
import Clients from 'components/AutoflowClients';
import AutoflowIntervalClient from 'components/AutoflowInterval/Client';
import Settings from 'components/AutoflowSettings';
import { ANNOUNCEMENT_AUTO_MESSAGE, AUTOFLOW_TYPES, IN_APP_AUTO_MESSAGE, TAGS_TYPE } from 'constants/commonData';
import { checkPermissionAccessAutoflow } from '../../utils/commonFunction';
import OverdueAnnuallyPopup from '../../layouts/home/Home/components/OverdueAnnuallyPopup';
import CalendarWrapper from './CalendarWrapper';
import AutoflowIntervalTrainingLayout from 'components/AutoflowInterval/Training/Layout';
import AutoflowIntervalTrainingCell from 'components/AutoflowInterval/Training/Cell';
import AutoflowIntervalAutoMessageLayout from 'components/AutoflowInterval/AutoMessage/Layout';
import AutoflowIntervalAutoMessageCell from 'components/AutoflowInterval/AutoMessage/Cell';
import AutoflowLeaderboard from 'components/AutoflowLeaderboard';
import IntervalInAppMessage from 'components/AutoflowInterval/AutoMessage/InAppMessageContainer';
import IntervalAnnouncement from 'components/AutoflowInterval/AutoMessage/Announcement';
import UpgradePath from 'shared/UpgradePath';
import { getMostRecentTagsList } from 'redux/tags/actions';
import { Helmet } from 'react-helmet';

function AutoflowDetail(props) {
  const {
    teamData,
    autoflowType,
    autoflowName,
    match: {
      params: { autoflowId },
    },
    habitUnits,
    getHabitUnits,
    isHabitPermission,
  } = props;

  useEffect(() => {
    const canAccessAutoflow = checkPermissionAccessAutoflow(teamData);

    if (canAccessAutoflow) {
      props.getAutoflowDetail(autoflowId);
      props.getMetrics({ autoflowId });
      props.getFeaturePreferences({ autoflow: autoflowId });
      props.getWorkoutVisibility({ autoflow: autoflowId });
      props.getAutoflowClientStatistic({ autoflow: autoflowId });
    }

    props.getMostRecentTagsList({ type: TAGS_TYPE.EXERCISE, page: 1, perPage: 20, sort: -1 });

    return () => {
      props.resetDetailData();
    };
  }, []);

  const canAccessAutoflow = useMemo(() => {
    return checkPermissionAccessAutoflow(teamData);
  }, [teamData]);

  if (!canAccessAutoflow) {
    return <OverdueAnnuallyPopup />;
  }

  if (!autoflowType) {
    return (
      <div className="loading-client-data">
        <h4>Getting data...</h4>
      </div>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/home/autoflow/:autoflowId?/training/calendar"
        render={props => (
          <>
            <Helmet>
              <title>{autoflowName} - Training - Everfit</title>
            </Helmet>
            <CalendarWrapper
              {...props}
              type={autoflowType}
              intervalComponents={{ Layout: AutoflowIntervalTrainingLayout, Cell: AutoflowIntervalTrainingCell }}
              exactDateComponents={{ Layout: AutoflowTraining, Cell: TrainingCell }}
            />
          </>
        )}
      />
      <Route
        exact
        path="/home/autoflow/:autoflowId?/training/calendar/:workoutId?/detail"
        render={props => (
          <CalendarWrapper
            {...props}
            type={autoflowType}
            intervalComponents={{ Layout: AutoflowIntervalTrainingLayout, Cell: AutoflowIntervalTrainingCell }}
            exactDateComponents={{ Layout: AutoflowTraining, Cell: TrainingCell }}
          />
        )}
      />
      <Route
        exact
        path="/home/autoflow/:autoflowId?/task/calendar"
        render={props => (
          <>
            <Helmet>
              <title>{autoflowName} - Tasks - Everfit</title>
            </Helmet>
            <CalendarWrapper
              {...props}
              type={autoflowType}
              habitUnits={habitUnits}
              isHabitPermission={isHabitPermission}
              getHabitUnits={getHabitUnits}
              intervalComponents={{ Layout: AutoflowIntervalTaskCalendarLayout, Cell: AutoflowIntervalTaskCell }}
              exactDateComponents={{ Layout: AutoflowTask, Cell: TaskCell }}
            />
          </>
        )}
      />
      <Route
        exact
        path="/home/autoflow/:autoflowId?/task/calendar/:taskId?/detail"
        render={props => (
          <>
            <Helmet>
              <title>{autoflowName} - Tasks - Everfit</title>
            </Helmet>
            <CalendarWrapper
              {...props}
              type={autoflowType}
              habitUnits={habitUnits}
              isHabitPermission={isHabitPermission}
              getHabitUnits={getHabitUnits}
              intervalComponents={{ Layout: AutoflowIntervalTaskCalendarLayout, Cell: AutoflowIntervalTaskCell }}
              exactDateComponents={{ Layout: AutoflowTask, Cell: TaskCell }}
            />
          </>
        )}
      />
      <Route
        path="/home/autoflow/:autoflowId?/auto-message"
        render={props => (
          <CalendarWrapper
            {...props}
            type={autoflowType}
            intervalComponents={{ Layout: AutoflowIntervalAutoMessageLayout, Cell: AutoflowIntervalAutoMessageCell }}
            exactDateComponents={{ Layout: AutoMessage, Cell: MessageCell }}
          >
            <Helmet>
              <title>{autoflowName} - Auto Messages - Everfit</title>
            </Helmet>
            <Switch>
              <Route
                exact
                path={`${props.match.path}/:messageId`}
                render={p => {
                  const { location } = p;
                  const params = new URLSearchParams(location.search);
                  const type = params.get('type');

                  if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
                    if (type === ANNOUNCEMENT_AUTO_MESSAGE) {
                      return <IntervalAnnouncement {...p} />;
                    }

                    if (type === IN_APP_AUTO_MESSAGE) {
                      return <IntervalInAppMessage {...p} />;
                    }
                  }

                  if (type === ANNOUNCEMENT_AUTO_MESSAGE) {
                    return <Announcement {...p} />;
                  }

                  if (type === IN_APP_AUTO_MESSAGE) {
                    return <AutoflowInAppMessage {...p} />;
                  }

                  return null;
                }}
              />
            </Switch>
          </CalendarWrapper>
        )}
      />
      <Route
        exact
        path="/home/autoflow/:autoflowId?/clients"
        component={autoflowType === AUTOFLOW_TYPES.INTERVAL ? AutoflowIntervalClient : Clients}
      />
      <Route exact path="/home/autoflow/:autoflowId?/settings" component={Settings} />
      <UpgradePath pathName="leaderboard">
        <Route exact path="/home/autoflow/:autoflowId?/leaderboard" component={AutoflowLeaderboard} />
      </UpgradePath>
    </Switch>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { autoflow } = rootReducer;

  return {
    autoflowType: _.get(autoflow.common.workingAutoflow, 'type'),
    autoflowName: _.get(autoflow.common.workingAutoflow, 'name'),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    habitUnits: _.get(rootReducer, 'habits.habitUnits'),
    isHabitPermission: _.get(rootReducer, 'permission.autoflow_habit', false),
  };
};

const actionCreators = {
  getAutoflowDetail,
  resetDetailData,
  getMetrics,
  getFeaturePreferences,
  getWorkoutVisibility,
  getAutoflowClientStatistic,
  getMostRecentTagsList,
  getHabitUnits,
};

export default connect(mapStateToProps, actionCreators)(AutoflowDetail);
