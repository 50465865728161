import React from 'react';

// components
import { Checkbox } from 'shared/FormControl';
import VotedDetailModal from '../VotedDetailModal';

// assets
import { ReactComponent as RemoveIcon } from 'assets/icons/close_progress_photo_image.svg';

import * as S from './style';

const PollOption = props => {
  const {
    item,
    userId,
    percent,
    pollId,
    selected,
    toggleModal,
    isAuthorPost,
    authorPostId,
    isOwnerForum,
    onSelectOption,
    cloudfrontList,
    highestPercent,
    isScheduledPost,
    isViewerHasVote,
    isMultipleChoice,
    onOpenDeletePopup,
    isLeastOption = false,
  } = props;
  const { _id, value, owner: authorOption } = item;

  const isOwnerOption = (authorOption || {})._id === userId;
  const isOtherOption = authorPostId !== (authorOption || {})._id;
  const fullName = `${authorOption.first_name} ${authorOption.last_name}`;
  const isPermission = (isOwnerOption || isAuthorPost || isOwnerForum) && !isLeastOption;
  const isShowPercent =
    (isAuthorPost || isOwnerOption || (!isOwnerOption && isViewerHasVote)) && !isScheduledPost && percent > 0;

  const renderTitle = () => (
    <S.Title>
      {isOtherOption && <S.AddBy>Added by {isOwnerOption ? 'you' : <span>{fullName}</span>}</S.AddBy>}
      {value}
    </S.Title>
  );

  const handleOpenVoteDetail = event => {
    event.preventDefault();
    event.stopPropagation();

    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <VotedDetailModal
          closeModal={() => toggleModal(false)}
          voteDetail={{
            pollId,
            percent,
            optionId: _id,
            title: item.value,
            votes: item.votes,
          }}
          cloudfrontList={cloudfrontList}
        />,
      );
  };

  return (
    <S.Wrapper isOwner={isPermission} isScheduledPost={isScheduledPost}>
      <S.OptionWrapper
        onClick={onSelectOption(item)}
        isScheduledPost={isScheduledPost}
        percent={percent}
        className={`option-wrapper_${_id}`}
      >
        <S.TitleWrapper>
          <S.RadioWrapper>
            {isMultipleChoice ? (
              <Checkbox className="checkbox-item" checked={selected} size={24} />
            ) : (
              <S.RadioButton checked={selected} />
            )}
          </S.RadioWrapper>
          {renderTitle()}
        </S.TitleWrapper>
        {isShowPercent && <S.VotePercent percent={percent} highestPercent={highestPercent} />}
        {isShowPercent && <S.RightSection onClick={handleOpenVoteDetail}>{percent}%</S.RightSection>}
      </S.OptionWrapper>
      {!isScheduledPost && isPermission && (
        <S.RemoveWrapper onClick={onOpenDeletePopup(_id)}>
          <RemoveIcon className="remove-icon" />
        </S.RemoveWrapper>
      )}
    </S.Wrapper>
  );
};

export default PollOption;
