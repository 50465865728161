import styled from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';

const textBase = `
    color: #202353;
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 150%;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    .close-button {
      top: -9px;
      right: -8px;
    }
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-image {
        width: 25px;
        height: 25px;
      }
      .confirm-header-label {
        ${textBase}
        font-size: 21px;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .confirm-content-body {
      padding: 25px 30px;
      .confirm-content-label {
        ${textBase}
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .ui.button.confirm-no-button {
      border: 1px solid #e1e1ea;
      border-radius: 5px;
      padding: 5px 24px;
      min-width: 89px;
      ${textBase}
      color: #777 !important;
      font-size: 12px;
      font-weight: 600;
    }
    .ui.button.confirm-yes-button {
      box-shadow: unset;
      border-radius: 5px;
      ${textBase}
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      margin-left: 8px !important;
    }
    .no-border {
      padding: 0 30px 30px !important;
    }
  }
`;
