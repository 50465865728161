import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  line-height: 150%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TotalTask = styled.div`
  margin-top: 4px;
  margin-left: -1px;

  color: rgba(32, 35, 83, 0.7);
  font-size: 14px;
  font-weight: 600;
`;

export const BoldText = styled.b`
  color: #202353;
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
`;

export const ViewHistory = styled.a`
  color: #6456c4;
  text-align: right;
  font-size: 13px;
  font-weight: 600;
  :hover {
    cursor: pointer;
    color: #6456c4;
  }

  padding-top: 3px;

  .icon {
    width: 8px;
    height: 10px;
    margin-left: 3px;
    path {
      fill: #5158cf;
    }
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

export const UsedAPI = styled.div`
  color: ${props => (props.isOver ? '#F5222D' : '#202353')};
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
`;

export const ProgressBar = styled.div`
  height: 8px;
  width: 100%;
  display: flex;
  border-radius: 3px;
  background: #f5f5f5;

  margin-top: 14px;
`;

export const ProgressPercent = styled.div`
  width: ${props => props.percent}%;
  height: 8px;
  border-radius: 3px;
  background: ${props =>
    props.percent > 100 ? 'linear-gradient(270deg, #e82929 0%, #2933e8 4.65%, #686fdd 100%)' : '#2933E8'};
`;

export const UsageReset = styled.div`
  color: rgba(32, 35, 83, 0.7);
  font-size: 13px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 574px;
    height: 468px;
    padding: 25px 0px 20px 30px;

    border-radius: 5px;
    border: 1px solid #ebedf4;
  }
  &.ui.modal > :last-child {
    padding: 0;
  }
`;

export const ModalContainer = styled(Modal.Content)``;

export const ModalHeader = styled.div`
  color: #202353;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModalContent = styled.div`
  margin-top: 25px;
`;

export const TableContainer = styled.div`
  padding-right: 1px;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  margin-right: 28px;
`;

export const EmptyLabel = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const HeaderTable = styled.div`
  display: flex;
  align-items: center;
  margin-left: -1px;
  margin-right: 30px;
  height: 31px;
  color: #6a778a;
  font-size: 11px;
  font-weight: 700;

  background: #fff;
  border-bottom: 1px solid #e8e8e7;
`;

export const LeftHeader = styled.div`
  width: 263px;
`;

export const RightHeader = styled.div`
  width: 253px;
`;

export const ContentTable = styled.div`
  margin-top: 5px;
  height: 305px;
  overflow-y: scroll;
  padding-right: 30px;

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  .loading-indicator {
    padding-top: 17px;
    padding-bottom: 19px;
    .custom-indicator.ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;
export const FirstLoad = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .loading-indicator {
    padding-top: 17px;
    padding-bottom: 19px;
    .custom-indicator.ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const TableItem = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;

  padding-bottom: 7px;
  margin-top: 9px;

  color: #202353;
  font-size: 12px;
  font-weight: 400;
`;

export const LeftContent = styled.div`
  width: 262px;
`;
export const RightContent = styled.div``;
export const FooterTable = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #f3f3f3;
  width: 514px;
  height: 33px;
`;

export const TotalLabel = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.4;
  width: 243px;
  margin-left: 17px;
  margin-bottom: -4px;
`;

export const TotalTasks = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: -5px;
`;

export const UsageDetail = styled.div`
  margin-left: 8px;
  cursor: pointer;

  .usage-icon {
    display: flex;
    justify-content: center;
    svg path {
      fill: rgba(32, 35, 83, 0.5);
    }
    :hover {
      svg path {
        fill: rgba(100, 86, 196, 1);
      }
    }
  }

  .__react_component_tooltip.app-tooltip.custom-usage {
    padding: 8px 15px;
    min-width: 190px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-transform: none;
    min-width: 173px;
    white-space: normal;
    right: 40px;
    left: unset !important;
    height: 56px;
    bottom: 0 !important;
    background: #2d2e2d !important;
    border-radius: 5px !important;

    ::after {
      left: 87% !important;
      border-top-color: #2d2e2d !important;
    }
  }

  .custom-usage--tooltip {
    text-align: left;
  }
`;
