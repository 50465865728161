import styled from 'styled-components';
import { Button } from 'shared/FormControl';

export default styled.div`
  width: 296px;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  padding: 30px 25px;
  text-align: center;

  svg {
    margin: 0 0 20px;
  }

  .empty-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 5px;
  }

  .empty-description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    margin-bottom: 30px;
  }

  ${Button} {
    color: #5158cf;
    border: 1px solid #5158cf;
    padding: 7px 19px;

    svg {
      margin-right: 10px;
    }

    :hover {
      background-color: #5158cf;
      color: #ffffff;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;
