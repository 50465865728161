// Lib
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { toast } from 'react-toastify';

// Actions
import {
  addRecipeTrainer,
  getClientMealPlanByMonth,
  getListRecipe,
  swapRecipeTrainer,
} from 'redux/client-meal-plan/actions';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { getInitTotalRecipe } from 'redux/recipes/actions';
import { showError, hideError } from 'actions/error';

// Components
import CommonPopup from '../CommonPopup';
import ItemContent from '../ItemContent';
import ItemSkeleton from '../ItemSkeleton';
import ListSelected from './components/ListSelected';

// utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { mongoObjectId } from 'utils/commonFunction';

// Constants
import { MAX_CALORIES, MIN_CALORIES, RECIPE_STATUS } from 'components/Recipes/constants';
import { DAY_FORMAT } from 'constants/time-format';
import { ACTION_TYPE } from 'components/ClientMealPlan/constants';

// Assets
import { ReactComponent as EmptyIcon } from 'assets/icons/MealPlans/meal-plan-empty.svg';

// Style
import * as S from './style';

const RecipeListModal = ({
  cloudfrontList,
  getListRecipe,
  mode,
  selectedDay,
  workingClientDetail,
  dataMeal,
  toggleModal,
  getClientMealPlanByMonth,
  toggleSecondModal,
  mealClientRecipe,
  getInitTotalRecipe,
  addRecipeTrainer,
  swapRecipeTrainer,
  idMealClient,
  showError,
  hideError,
  loadingAction,
}) => {
  const { _id: idClient = '' } = workingClientDetail || {};

  const initialFilter = {
    page: 1,
    per_page: 20,
    sort: -1,
    sorter: 'updated_at',
    only_my_recipes: false,
    text_search: '',
    status: [RECIPE_STATUS.PUBLISH],
    category: '',
  };

  const [filter, setFilter] = useState(initialFilter);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const [initTotal, setInitTotal] = useState({
    min_calories: MIN_CALORIES,
    max_calories: MAX_CALORIES,
    hasGetToTal: false,
  });
  const [appliedFilter, setAppliedFilter] = useState([]);

  const listRef = useRef(null);

  useEffect(() => {
    handleGetListRecipe();
  }, [filter]);

  useEffect(() => {
    handleGetInitTotalRecipe();

    return () => {
      setFilter(initialFilter);
      setAppliedFilter([]);
    };
  }, []);

  useEffect(() => {
    if (initTotal.hasGetToTal) {
      const initAppliedFilter = {
        dietaries: [],
        caloriesRange: {
          from: MIN_CALORIES,
          to: initTotal.max_calories > MAX_CALORIES ? initTotal.max_calories : MAX_CALORIES,
        },
      };

      setAppliedFilter(initAppliedFilter);
    }
  }, [initTotal.max_calories, initTotal.hasGetToTal]);

  const handleGetListRecipe = async () => {
    try {
      setLoading(true);
      const res = await getListRecipe(filter);
      setData(prevState => {
        const newData =
          get(filter, 'page', 0) === 1
            ? get(res, 'data.data', [])
            : uniqBy([...prevState, ...get(res, 'data.data', [])], '_id');
        return newData;
      });
      setTotal(get(res, 'data.total'));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleGetInitTotalRecipe = async () => {
    try {
      const res = await getInitTotalRecipe();
      setInitTotal({
        ...omit(get(res, 'data.data', {}), ['all', 'existed_recipes', 'only_my_recipes']),
        hasGetToTal: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleAdd = item => {
    const data = [item];
    handleUpdateMealCategory(data);
  };

  const hasFilterOrSearchChanged = useMemo(() => {
    const isCaloriesChanged =
      (filter.from_calories && filter.from_calories !== initTotal.min_calories) ||
      (filter.to_calories && filter.to_calories !== initTotal.max_calories);

    const isDietariesChanged = filter.dietaries ? JSON.parse(filter.dietaries).length > 0 : false;

    return !!filter.text_search || isCaloriesChanged || isDietariesChanged;
  }, [filter, initTotal]);

  const renderContent = () => {
    const resultLabel = hasFilterOrSearchChanged ? 'RESULTS' : 'RECIPES';
    return (
      <S.Wrapper>
        <S.Title>
          {resultLabel} ({total})
        </S.Title>
        {filter && filter.page === 1 && loading ? (
          renderSkeleton()
        ) : (
          <>
            {get(data, 'length', 0) > 0 ? (
              <S.ListWrapper>
                <S.List ref={listRef} onScroll={debounce(onScroll, 300)}>
                  {data.map(item => {
                    const { _id, name, cover_image, cover_image_thumbnail } = item;
                    const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
                    const coverImageThumbnail = convertS3UrlToCloudFrontUrl(
                      cover_image_thumbnail,
                      cloudfrontList,
                      true,
                    );
                    const active = selected.some(item => get(item, '_id', '') === _id);

                    const disabled =
                      get(dataMeal, 'recipes', []).findIndex(item => get(item, 'recipe._id', '') === _id) !== -1;
                    const checkLimitSelected = selected.length >= 10 && !active;

                    return (
                      <S.Item
                        key={_id}
                        onClick={() => {
                          if (disabled || checkLimitSelected) return;
                          handleSelected(item);
                        }}
                        disabled={disabled || checkLimitSelected}
                      >
                        <ItemContent
                          id={_id}
                          name={name}
                          coverImage={coverImage}
                          coverImageThumbnail={coverImageThumbnail}
                          active={active}
                          hideWeek
                          isRecipe
                          showViewDetail
                          title={renderContentActionDetail()}
                          toggleSecondModal={toggleSecondModal}
                          onAdd={handleAdd}
                          item={item}
                          disabled={disabled || checkLimitSelected}
                          idMealClient={idMealClient}
                          showCheckbox={mode === ACTION_TYPE.ADD && !disabled}
                        />
                      </S.Item>
                    );
                  })}
                </S.List>
              </S.ListWrapper>
            ) : (
              renderEmpty()
            )}
          </>
        )}
      </S.Wrapper>
    );
  };

  const renderAction = () => {
    return (
      <S.ButtonNext
        type="button"
        disabled={isEmpty(selected) || loadingAction}
        onClick={() => handleUpdateMealCategory(selected)}
      >
        {renderContentAction()}
      </S.ButtonNext>
    );
  };

  const renderEmpty = () => {
    const { text_search } = filter;

    return (
      <S.Empty>
        {!text_search && (
          <div className="icon">
            <EmptyIcon />
          </div>
        )}
        <span>{!text_search ? 'No recipes.' : 'No results found.'}</span>
      </S.Empty>
    );
  };

  const renderSkeleton = () => {
    const list = Array(9).fill(mongoObjectId());
    return (
      <S.List loading>
        {list.map((_, index) => {
          return (
            <S.Item key={index}>
              <ItemSkeleton isRecipe />
            </S.Item>
          );
        })}
      </S.List>
    );
  };

  const handleSelected = data => {
    if (mode === ACTION_TYPE.ADD) {
      const checked = selected.some(item => get(item, '_id', '') === get(data, '_id', ''));
      let updatedList = [];
      if (checked) {
        updatedList = [...selected].filter(item => get(item, '_id', '') !== get(data, '_id', ''));
      } else {
        updatedList = [...selected, data];
      }
      setSelected(updatedList);
    } else {
      setSelected([data]);
    }
  };

  const handleSearch = value => {
    setData([]);
    setFilter(prevState => ({
      ...prevState,
      page: 1,
      text_search: value,
    }));
  };

  const handleFilterCategory = category => {
    setData([]);
    setFilter(prevState => ({
      ...prevState,
      page: 1,
      category,
    }));
  };

  const handleSelectedFilterApply = selectedFilter => {
    setData([]);
    setAppliedFilter(selectedFilter);

    const dietaryFilter = get(selectedFilter, 'dietaries', []).map(item => item.value);
    const dietaryFilterJson = JSON.stringify(dietaryFilter);

    const newFilter = {
      dietaries: dietaryFilterJson,
      from_calories: get(selectedFilter, 'caloriesRange.from', 0),
      to_calories: get(selectedFilter, 'caloriesRange.to', 0),
      page: 1,
    };

    setFilter(prevState => ({
      ...prevState,
      ...newFilter,
    }));
  };

  const handleClearSearch = () => {
    setData([]);
    setFilter(prevState => ({
      ...prevState,
      page: 1,
      text_search: '',
    }));
  };

  const onScroll = () => {
    const listPhoto = listRef && listRef.current;
    if (listPhoto && get(data, 'length', 0) < total) {
      const isAtBottom = listPhoto.scrollTop + listPhoto.clientHeight >= listPhoto.scrollHeight - 25;

      if (isAtBottom) {
        handleScrollList();
      }
    }
  };

  const handleScrollList = () => {
    setFilter(prevState => ({
      ...prevState,
      page: prevState && prevState.page + 1,
    }));
  };

  const renderTitle = () => {
    switch (mode) {
      case ACTION_TYPE.ADD:
        return 'Add Recipes';
      case ACTION_TYPE.REPLACE:
        return 'Replace Recipe';
      default:
        return '';
    }
  };

  const renderContentActionDetail = () => {
    switch (mode) {
      case ACTION_TYPE.ADD:
        return 'Add Recipe';
      case ACTION_TYPE.REPLACE:
        return 'Replace Recipe';
      default:
        return '';
    }
  };

  const renderContentAction = () => {
    switch (mode) {
      case ACTION_TYPE.ADD:
        return 'Add Recipes';
      case ACTION_TYPE.REPLACE:
        return 'Replace Recipe';
      default:
        return '';
    }
  };

  const renderToast = () => {
    const messages = {
      [ACTION_TYPE.ADD]: 'added',
      [ACTION_TYPE.REPLACE]: 'replaced',
    };

    return `Recipe has been ${messages[mode]}.`;
  };

  const renderToastAdd = numRecipes => {
    let message;
    if (numRecipes === 1) {
      message = '1 recipe has been added.';
    } else {
      message = `${numRecipes} recipes have been added.`;
    }
    return message;
  };

  const renderListSelected = () => {
    if (mode === ACTION_TYPE.ADD) {
      const cloneSelected = [...selected];

      return (
        <S.ListSelectedWrapper>
          <ListSelected list={cloneSelected.reverse()} cloudfrontList={cloudfrontList} handleRemove={handleSelected} />
        </S.ListSelectedWrapper>
      );
    }
  };

  const handleUpdateMealCategory = selected => {
    const { name, is_system, _id: idMealGroup = '' } = dataMeal;

    if (mode === ACTION_TYPE.ADD) {
      const recipes = selected.map(item => {
        const { _id } = item;
        return {
          meal_group: idMealGroup,
          meal_group_name: name,
          recipe: _id,
          is_system: is_system,
        };
      });

      const data = {
        client: idClient,
        day: selectedDay.format(DAY_FORMAT.YYYY_MM_DD),
        recipes,
      };

      typeof addRecipeTrainer === 'function' &&
        addRecipeTrainer(data, () => {
          const fromDay = selectedDay.clone().set('D', 1).subtract(7, 'd').format(DAY_FORMAT.YYYY_MM_DD);
          const toDay = selectedDay
            .clone()
            .set('D', 1)
            .add(1, 'month')
            .subtract(1, 'day')
            .add(7, 'd')
            .format(DAY_FORMAT.YYYY_MM_DD);

          toggleModal && toggleModal(false);
          toggleSecondModal && toggleSecondModal(false);
          getClientMealPlanByMonth({
            client: idClient,
            from_day: fromDay,
            to_day: toDay,
          });
        })
          .then(res => {
            toast(renderToastAdd(get(res, 'data.total_added_recipes', 0)));
          })
          .catch(err => {
            showError(err && err.message, null, null, null, null, () => handleCloseError());
          });
    }

    if (mode === ACTION_TYPE.REPLACE) {
      const { _id: idRecipe } = selected[0];

      const data = {
        meal_client_recipe: mealClientRecipe,
        new_recipe: idRecipe,
      };

      typeof swapRecipeTrainer === 'function' &&
        swapRecipeTrainer(idMealClient, data, () => {
          const fromDay = selectedDay.clone().set('D', 1).subtract(7, 'd').format(DAY_FORMAT.YYYY_MM_DD);
          const toDay = selectedDay
            .clone()
            .set('D', 1)
            .add(1, 'month')
            .subtract(1, 'day')
            .add(7, 'd')
            .format(DAY_FORMAT.YYYY_MM_DD);

          toast(renderToast());
          toggleModal && toggleModal(false);
          toggleSecondModal && toggleSecondModal(false);
          getClientMealPlanByMonth({
            client: idClient,
            from_day: fromDay,
            to_day: toDay,
          });
        }).catch(err => {
          showError(err && err.message, null, null, null, null, () => handleCloseError());
        });
    }
  };

  const handleCloseError = () => {
    hideError(false);
    setData([]);
    setTotal(0);
    handleGetListRecipe();
  };

  return (
    <CommonPopup
      title={renderTitle()}
      content={renderContent()}
      action={renderAction()}
      onSearch={handleSearch}
      onClearSearch={handleClearSearch}
      onFilterCategory={handleFilterCategory}
      onSelectedFilterApply={handleSelectedFilterApply}
      appliedFilter={appliedFilter}
      maxCalories={initTotal.max_calories}
      placeholder="Search recipe name"
      leftAction={renderListSelected()}
      width={886}
    />
  );
};

const mapState = state => {
  const { cloudfrontList, rootReducer } = state;

  const { workingClientDetail } = get(rootReducer, 'client', {});
  const { assignedMealPlanClientsByRange, loadingAction } = get(rootReducer, 'clientMealPlan', {});

  return { cloudfrontList, workingClientDetail, assignedMealPlanClientsByRange, loadingAction };
};

const mapDispatch = dispatch => ({
  getListRecipe: bindActionCreators(getListRecipe, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  getClientMealPlanByMonth: bindActionCreators(getClientMealPlanByMonth, dispatch),
  getInitTotalRecipe: bindActionCreators(getInitTotalRecipe, dispatch),
  addRecipeTrainer: bindActionCreators(addRecipeTrainer, dispatch),
  swapRecipeTrainer: bindActionCreators(swapRecipeTrainer, dispatch),
  showError: bindActionCreators(showError, dispatch),
  hideError: bindActionCreators(hideError, dispatch),
});

export default connect(mapState, mapDispatch)(RecipeListModal);
