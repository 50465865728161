import React from 'react';
import * as S from './style';
import { ReactComponent as BatteryIcon } from 'assets/icons/battery.svg';
import { ReactComponent as BatteryWhiteIcon } from 'assets/icons/battery-white.svg';
import { ReactComponent as ConnectionIcon } from 'assets/icons/connection.svg';
import { ReactComponent as ConnectionWhiteIcon } from 'assets/icons/connection-white.svg';
import { ReactComponent as WifiIcon } from 'assets/icons/wifi.svg';
import { ReactComponent as WifiWhiteIcon } from 'assets/icons/wifi-white.svg';

export default function CustomBrandingStatusBar(props) {
  const { light } = props;
  return (
    <S.StatusBar {...props}>
      <div className="status-bar__left-content">9:41</div>
      <div className="status-bar__right-content">
        {light ? (
          <>
            <ConnectionWhiteIcon />
            <WifiWhiteIcon />
            <BatteryWhiteIcon />
          </>
        ) : (
          <>
            <ConnectionIcon />
            <WifiIcon />
            <BatteryIcon />
          </>
        )}
      </div>
    </S.StatusBar>
  );
}
