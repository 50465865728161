import styled from 'styled-components';

export default {
  dropdownListItemImage: {
    float: 'left',
    paddingTop: '6px',
    width: 14,
  },

  popup: {
    borderRadius: '0',
    padding: '10px 30px 10px 10px',
    borderRadius: '8px',
    background: '#2D2E2D',
  },

  popupIcon: {
    float: 'right',
    marginTop: '6px',
    cursor: 'pointer',
    marginLeft: 'auto',
    order: 2,
  },

  dropdownList: {
    width: '112px',
    position: 'relative',
    padding: '0px',
    paddingBottom: '15px',
  },

  dropdownListItem: {
    cursor: 'pointer',
    listStyle: 'none',
    float: 'left',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
  },

  dropdownListItemContainer: {
    float: 'left',
    cursor: 'pointer',
    paddingLeft: '10px',
  },

  dropdownListItemImage: {
    float: 'left',
    paddingTop: '3px',
    width: 14,
  },

  dropdownListItemTitle: {
    float: 'left',
    paddingBottom: '2px',
    paddingLeft: '5px',
  },
};

export const NoteWrapper = styled.div`
  #advanced-note-footer::-webkit-scrollbar {
    width: 4px !important;
  }
  .advanced-note-item {
    border-top: 1px solid #dfe4e5;
    padding: 10px 16px 10px;

    cursor: pointer;
  }

  .advanced-note-item.read-only {
    pointer-events: none;
  }

  .advanced-note-item.read-only i.grey.icon {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .advanced-note-item:first-child {
    border-top: none;
  }
  .advanced-note-item > div > span:first-child {
    font-size: 16px;
    color: #726ee4;
  }
  .advanced-note-item > div > span {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    white-space: pre-line;
    color: #323c47;
  }
  .advanced-note-item > .advanced-note-date {
    font-size: 10px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f99a3;
    margin-left: 10px;
    padding-top: 2px;
  }
  .advanced-note-item > .advanced-note-content {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: #273d52;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .advanced-note-content .note-content {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    box-orient: vertical;
    overflow: hidden;
    margin-right: 2px;
    word-break: break-word;
  }

  .eve-panel.note-panel.advanced {
    overflow: hidden;
  }

  .eve-panel.note-panel.advanced > .panel-footer {
    padding: 0px;
    max-height: 172px;
    overflow-y: auto;
    border-top: none;
  }
  .eve-panel.note-panel.advanced > .panel-footer.editting {
    border-top: 1px solid #dfe4e5;
  }

  .eve-panel.note-panel.advanced > p > .no-data {
    overflow: hidden;
    border: 1px solid #e5e9e9;
    padding: 10px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f99a3;
    background: #fafbfc;
    margin: 16px 16px 22px;

    :hover {
      border-color: #7773ff;
    }
  }

  .eve-panel.note-panel.advanced > p > .no-data.show-inbox {
    color: #dfe4e5;
    border-radius: 100px;
    border: solid 1px #dfe4e5;
    background-color: #ffffff;
  }

  .note-content a {
    word-break: break-all;
  }

  .note-panel.eve-panel .panel-header .edit-icon.read-only {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .eve-panel.on-inbox {
    border-radius: unset !important;
  }

  .eve-panel .panel-header.empty {
    justify-content: flex-start;
  }

  .header-title {
    cursor: pointer;
  }
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
  .ui.loader {
    z-index: unset !important;
  }
  .ui.loader:before {
    border-color: #fbfbff;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    color: #8c8c8c;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }
`;
