// Libs
import React, { useEffect, useRef, useState } from 'react';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import axios from 'axios';

// Actions
import { axiosInstance } from 'configs/request';

// Shared
import { NewSearchInput } from 'shared/SearchInput';
import LoadingIndicator from 'shared/LoadingIndicator';

// Components
import OnDemandItem from './OnDemandItem';
import { getTotalContentResourcesAsset } from 'components/OnboardingFlowDetail/helper';

// Constants
import { ASSET_TYPES, ON_DEMAND_API_GET_LIST, ON_DEMAND_ASSET_KEY } from 'components/OnboardingFlowDetail/constants';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';
import { ReactComponent as EmptyIcon } from 'assets/icons/OnboardingFlow/on_demand_empty.svg';
import { ReactComponent as EmptySearchIcon } from 'assets/icons/OnboardingFlow/on_demand_search_empty.svg';

// Styles
import * as S from './style';

const CancelToken = axios.CancelToken;
const cancelRequests = [];

const OnDemandPopup = ({
  type,
  onClose,
  title,
  totalSelect,
  onSelect,
  onDemandSelectedList,
  cloudfrontList,
  user,
  newOnDemandSelectedList,
}) => {
  const { method, url, query } = ON_DEMAND_API_GET_LIST[type];

  const searchBoxAssetsRef = useRef();

  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const selectedText = `${selectedList.length}/${totalSelect}`;
  const isEmptyList = list.length <= 0;

  useEffect(() => {
    getListOnDemandAsset();
  }, []);

  useEffect(() => {
    setIsEnabled(selectedList.length);
  }, [selectedList]);

  const fetchData = ({ inputValue, isNextPage = false, searchText }) => {
    if (!url || !method) return;

    const onDemandSelectedIds = (newOnDemandSelectedList || []).map(item => item[`${ON_DEMAND_ASSET_KEY[type]}_id`]);
    const totalSelectedIds = onDemandSelectedIds && onDemandSelectedIds.length;

    const isFetching = isLoading || isLoadingMore;

    if (isFetching && !!cancelRequests.length) {
      cancelRequests.forEach(
        cancelRequest => typeof cancelRequest === 'function' && cancelRequest('CANCEL_REQUEST_SEARCH_ASSETS_POPUP'),
      );
    }

    !isNextPage && setIsLoading(true);
    isNextPage && setIsLoadingMore(true);

    let queryParams = {
      ...query,
      excludeIds:
        type === ASSET_TYPES.WORKOUT_COLLECTIONS && totalSelectedIds ? JSON.stringify(onDemandSelectedIds) : undefined,
      except:
        [ASSET_TYPES.RESOURCE_COLLECTIONS, ASSET_TYPES.STUDIO_PROGRAMS].includes(type) && totalSelectedIds
          ? onDemandSelectedIds
          : undefined,
    };
    const key = type === ASSET_TYPES.WORKOUT_COLLECTIONS ? 'params' : 'data';

    const textSearchValue = type === ASSET_TYPES.WORKOUT_COLLECTIONS ? searchText || inputValue : undefined;
    const searchValue = type !== ASSET_TYPES.WORKOUT_COLLECTIONS ? searchText || inputValue : undefined;

    const listIds = (list || []).map(({ _id = '' }) => _id);

    if (textSearchValue || searchValue) {
      queryParams = {
        ...queryParams,
        textSearch: textSearchValue,
        search: searchValue,
      };
    }

    if (isNextPage) {
      queryParams = {
        ...queryParams,
        page: nextPage,
      };
    } else {
      setNextPage(1);
    }

    axiosInstance({
      method,
      url,
      [key]: queryParams,
      cancelToken: new CancelToken(cancelRequest => cancelRequests.push(cancelRequest)),
    })
      .then(response => {
        const currentQueryText = queryParams.textSearch || queryParams.search || '';
        const currentInputValue = searchBoxAssetsRef.current || '';

        if (currentQueryText.toLowerCase().trim() !== currentInputValue.toLowerCase().trim()) return;
        cancelRequests.length = 0;

        const { data, page, total } = (response || {}).data;
        const isWorkoutCollectionType = type === ASSET_TYPES.WORKOUT_COLLECTIONS;

        const searchData = isWorkoutCollectionType
          ? ((data || {}).list || []).filter(o => !listIds.includes(o._id))
          : (data || []).filter(o => !listIds.includes(o._id));

        const newData = isNextPage ? [...list, ...searchData] : isWorkoutCollectionType ? (data || {}).list : data;
        const newTotal = isWorkoutCollectionType ? (data || {}).total : total;
        const newPage = isWorkoutCollectionType ? (data || {}).page : page;

        setList(newData);
        setTotal(newTotal);
        setNextPage(newPage + 1);

        !isNextPage && setIsLoading(false);
        isNextPage && setIsLoadingMore(false);
      })
      .catch(error => {
        if ((error || {}).message === 'CANCEL_REQUEST_SEARCH_ASSETS_POPUP') return;

        !isNextPage && setIsLoading(false);
        isNextPage && setIsLoadingMore(false);
      });
  };

  const fetchDataDebounce = debounce(fetchData, 300);

  const getListOnDemandAsset = (inputValue = '') => {
    fetchData({ inputValue });
  };

  const loadMoreList = () => {
    fetchData({ isNextPage: true, searchText: textSearch });
  };

  const handleSelect = (item, isSelected) => {
    const { _id = '' } = item || {};

    if (isSelected) {
      const newList = selectedList.filter(item => item[`${ON_DEMAND_ASSET_KEY[type]}_id`] !== _id);
      setSelectedList(uniq(newList));
      return;
    }

    const newList = [
      ...selectedList,
      {
        [`${ON_DEMAND_ASSET_KEY[type]}_id`]: _id,
        [`${ON_DEMAND_ASSET_KEY[type]}_data`]: item,
      },
    ];
    setSelectedList(uniq(newList));
  };

  const handleSearch = (__, { value }) => {
    searchBoxAssetsRef.current = value;

    const trimmedText = value.toLowerCase().trim();

    if (trimmedText !== textSearch) {
      setTextSearch(trimmedText);
      fetchDataDebounce({ inputValue: trimmedText });
    }
  };

  const searchDebounce = debounce(handleSearch, 300);

  const handleClearSearch = () => {
    searchBoxAssetsRef.current = '';

    setTextSearch('');
    fetchData({ inputValue: '' });
  };

  const handleSubmit = () => {
    if (!isEnabled || isLoading) return;

    const newSelectedList = onDemandSelectedList.length ? [...onDemandSelectedList, ...selectedList] : selectedList;

    onSelect(newSelectedList, type);
    onClose();
  };

  const renderEmptyList = () => {
    return (
      <S.ContentWrapperEmpty>
        <EmptyIcon className="empty-icon" />
        <span>No {title.toLowerCase()}.</span>
      </S.ContentWrapperEmpty>
    );
  };

  const renderList = () => {
    const isSearchLoading = textSearch && isLoading;
    const isSearchEmptyList = textSearch && isEmptyList;

    return (
      <S.ContentBody isSearchLoading={isSearchLoading} isSearchEmptyList={isSearchEmptyList}>
        {isSearchLoading
          ? renderLoadingIndicator()
          : isSearchEmptyList
          ? renderEmptySearch()
          : (list || []).map(renderItem)}
      </S.ContentBody>
    );
  };

  const renderItem = item => {
    const { _id } = item || {};
    const isSelected = selectedList.some(item => item[`${ON_DEMAND_ASSET_KEY[type]}_id`] === _id);
    const isDisabled =
      selectedList.length >= totalSelect && !selectedList.some(item => item[`${ON_DEMAND_ASSET_KEY[type]}_id`] === _id);

    return (
      <OnDemandItem
        key={_id}
        type={type}
        item={item}
        onSelect={handleSelect}
        isChecked={isSelected}
        isDisabled={isDisabled}
        cloudfrontList={cloudfrontList}
        user={user}
      />
    );
  };

  const renderEmptySearch = () => {
    return (
      <S.ContentBodyEmpty>
        <EmptySearchIcon className="empty-search-icon" />
        <span>No results found.</span>
      </S.ContentBodyEmpty>
    );
  };

  const renderLoadingIndicator = () => {
    return <LoadingIndicator title="Loading" size="small" className="loading--on-demand-asset" />;
  };

  const handleLoadMore = event => {
    const { scrollLeft, clientWidth, scrollWidth } = event.target;
    const bottom = scrollWidth - (scrollLeft + clientWidth);

    if (list.length < total && bottom < 200 && !isLoading && !isLoadingMore) {
      loadMoreList();
    }
  };

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleClose = () => {
    onClose();
  };

  return (
    <S.CustomModal open={true} onClose={handleClose} closeOnDimmerClick={false} className="evf-resources-asset-modal">
      <Modal.Header>
        <S.HeaderWrapper>
          <S.HeaderRightSide>
            <S.HeaderTitle>{title}</S.HeaderTitle>
          </S.HeaderRightSide>
          <S.HeaderLeftSide>
            <NewSearchInput
              autoFocus={false}
              focusAfterClear
              placeholder="Search by keyword or name"
              onChange={searchDebounce}
              onClearSearch={handleClearSearch}
            />
          </S.HeaderLeftSide>
          <CloseButton className="close-button" onClick={handleClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper isEmptyOrLoading={!textSearch && (isEmptyList || isLoading)}>
          {!(!textSearch && (isEmptyList || isLoading)) && (
            <S.ContentHeader>
              <S.TotalContent>{`${getTotalContentResourcesAsset(type)} (${total})`}</S.TotalContent>
              <S.SelectedWrapper>{selectedText} selected</S.SelectedWrapper>
            </S.ContentHeader>
          )}
          <S.ContentContainer onScroll={handleScroll} isEmptyOrLoading={!textSearch && (isEmptyList || isLoading)}>
            {!textSearch && isLoading
              ? renderLoadingIndicator()
              : !textSearch && isEmptyList
              ? renderEmptyList()
              : renderList()}
            {isLoadingMore ? (
              <LoadingIndicator title="Loading..." size="small" className="loading--on-demand-asset loading-more" />
            ) : null}
          </S.ContentContainer>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ActionsWrapper>
          <S.Button className="btn btn__cancel" onClick={handleClose}>
            Cancel
          </S.Button>
          <S.Button className="btn btn__save" onClick={handleSubmit} disabled={!isEnabled || isLoading}>
            Add
          </S.Button>
        </S.ActionsWrapper>
      </Modal.Actions>
    </S.CustomModal>
  );
};

export default OnDemandPopup;
