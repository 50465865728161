import React from 'react';
import _ from 'lodash';
import ConfirmModal from 'shared/ConfirmModal';
import { Modal } from 'semantic-ui-react';
import { Button, SelectFile } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';
import './style.scss';
import {
  createObjectURL,
  revokeObjectURL,
  handleCutString,
  mediaLog,
  getPresignedUploadUrlByParams,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import FileUpload from 'shared/FileUpload';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleConfirmModal } from 'actions/modal';
import { addWorkoutBackground, deleteWorkoutBackground } from 'redux/custom-branding/actions';
import { ReactComponent as AddImageIcon } from 'assets/icons/add_image_icon.svg';
import { axiosInstance } from 'configs/request';
import { CDN_URL } from 'constants/commonData';

class DefaultBackgroundImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: { ...props.data },
      uploading: false,
      uploadData: {},
    };
    this.deleteItems = [];
    this.uploadConfigs = null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const { open, selected } = this.state;
    const { data } = this.props;

    if (open && !prevState.open) {
      if (selected._id !== data._id) {
        this.setState({ selected: { ...data } });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClose = () => {
    const { workoutBackgrounds } = this.props;
    if (!_.find(workoutBackgrounds, this.props.data)) {
      this.props.onChange({ ...workoutBackgrounds[0] });
    }
    this.setState({ open: false });
  };

  onOpen = () => {
    this.setState({ open: true });
  };

  onItemClick(item) {
    const { selected } = this.state;

    if (item._id && item._id !== selected._id) {
      this.setState({ selected: { ...item } });
    }
  }

  onSelectFile = async file => {
    const { size, name, type } = file;
    const { uploadUrl, configs } = await getPresignedUploadUrlByParams(file, {
      method: 'POST',
      url: '/api/file/gen-presigned-urls-custom-brand',
      data: {
        fileNames: [name],
      },
    });
    if (!uploadUrl || !configs) {
      return;
    }

    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via Your Background',
    });

    this.uploadConfigs = {
      url: uploadUrl,
      method: 'PUT',
      data: file,
      headers: { 'Content-Type': type },
    };
    const uploadData = { src: createObjectURL(file), file, name, ...configs };
    this.setState({ uploadData, uploading: true });
  };

  onUploadSuccess = response => {
    const { uploadData } = this.state;
    const fileExtension = uploadData.mimetype.split('/')[1];
    const name = _.get(uploadData, 'original_name', '');

    mediaLog({
      status: 2,
      name,
      fileType: fileExtension,
      description: `Upload success file via Your Background`,
    });

    const data = {
      location: uploadData.url,
      originalname: name,
    };
    axiosInstance.patch('/api/team/workout-background-library', data).then(response => {
      const workoutBackground = response.data.data.workout_background;
      this.setState({ uploadData: {}, uploading: false, selected: workoutBackground }, () => {
        this.uploadConfigs = null;
        this.props.addWorkoutBackground(workoutBackground);
      });
    });
  };

  onCancelUpload = () => {
    const { uploadData } = this.state;
    revokeObjectURL(uploadData.src);
    this.uploadConfigs = null;
    this.setState({ uploadData: {}, uploading: false });
  };

  onUploadError = () => {
    this.setState({ uploading: false });
  };

  onSave = () => {
    const { data } = this.props;
    const { selected, uploading } = this.state;

    if (uploading) {
      return false;
    }

    if (selected._id !== data._id) {
      this.props.onChange({ ...selected });
    }

    this.setState({ open: false });
  };

  onRemoveItem = (e, item) => {
    e.stopPropagation();
    if (this.deleteItems.includes(item._id)) {
      return false;
    }

    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete Image?"
        content="Are you sure that you want to remove this image?"
        onConfirm={() => {
          this.deleteItems.push(item._id);
          this.props
            .deleteWorkoutBackground(item._id)
            .then(() => {
              const { workoutBackgrounds } = this.props;
              const { selected } = this.state;

              if (selected._id === item._id) {
                this.setState({ selected: { ...(workoutBackgrounds[0] || {}) } });
              }

              if (item.src.startsWith('blob:')) {
                revokeObjectURL(item.src);
              }
            })
            .finally(() => {
              _.remove(this.deleteItems, id => id === item._id);
            });
        }}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  renderUpload = () => {
    const { uploadData } = this.state;

    if (uploadData.file) {
      return (
        <S.Item empty>
          <S.Background background={uploadData.src} />
          <FileUpload
            onSuccess={this.onUploadSuccess}
            onError={this.onUploadError}
            onCancel={this.onCancelUpload}
            configs={this.uploadConfigs}
            progressRadius={22}
            progressBarWidth={3}
            smallCancelButton
          />
        </S.Item>
      );
    } else {
      return (
        <S.Item empty data-tip disablehover data-for="tooltip--select-custom-background">
          <SelectFile
            trigger={
              <S.SelectFileTrigger>
                <AddImageIcon />
              </S.SelectFileTrigger>
            }
            onSelect={this.onSelectFile}
            accept="image/png, image/jpeg, image/jpg"
            validateExtentions={['png', 'jpg', 'jpeg']}
            maxSize={25}
          />
          <S.Tooltip className="app-tooltip" id="tooltip--select-custom-background">
            <span>Upload your background</span>
          </S.Tooltip>
        </S.Item>
      );
    }
  };

  getCloudUrl = url => {
    const { cloudfrontList } = this.props;
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList) || url;
  };

  render() {
    const { open, selected, uploading } = this.state;
    const { workoutBackgrounds, numOfCustomBackground } = this.props;
    const customBackgrounds = workoutBackgrounds.filter(item => !item.default);

    return (
      <Modal
        open={open}
        closeOnDimmerClick={false}
        className="modal--custom-workout-background"
        onClose={this.onClose}
        onOpen={this.onOpen}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
        trigger={
          <S.BackgroundBorder customFileUpload>
            <S.TriggerContainer>
              {customBackgrounds.length > 0 ? (
                <S.TriggerCustomImage
                  background={this.getCloudUrl(customBackgrounds[customBackgrounds.length - 1].src)}
                >
                  <S.ManageTextContainer>
                    <img src={`${CDN_URL}/images/manage-backgrounds.svg`} alt="" />
                    <S.ManageText>Manage Backgrounds</S.ManageText>
                  </S.ManageTextContainer>
                </S.TriggerCustomImage>
              ) : (
                <S.AddNewTextContainer>
                  {' '}
                  <img src={`${CDN_URL}/images/background-upload-add-new.svg`} alt="" />
                  <S.AddNewText>Add New</S.AddNewText>
                </S.AddNewTextContainer>
              )}
            </S.TriggerContainer>
          </S.BackgroundBorder>
        }
      >
        <Modal.Content>
          <S.ContentWrapper>
            <ModalLayout.Header>Your Backgrounds</ModalLayout.Header>
            <ModalLayout.Content>
              <S.BackgroundList>
                {this.renderUpload()}
                {_.map(workoutBackgrounds, (item, index) => {
                  if (item.default) {
                    return null;
                  }
                  const selectedDefault = item._id === this.props.data._id;
                  return (
                    <S.Item
                      selectedDefault={selectedDefault}
                      empty
                      data-tip
                      data-for={`tooltip--upload-custom-background-${index}`}
                      key={index}
                      active={selected._id === item._id}
                      onClick={this.onItemClick.bind(this, item)}
                    >
                      <S.Background background={this.getCloudUrl(item.src)} />
                      {!item.default ? <S.RemoveIcon onClick={e => this.onRemoveItem(e, item)} /> : null}
                      {item.name && (
                        <S.Tooltip className="app-tooltip" id={`tooltip--upload-custom-background-${index}`}>
                          <span>{handleCutString(item.name)}</span>
                        </S.Tooltip>
                      )}
                    </S.Item>
                  );
                })}
              </S.BackgroundList>
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button purple onClick={this.onSave} disabled={uploading || numOfCustomBackground < 1}>
                Select
              </Button>
            </ModalLayout.Actions>
          </S.ContentWrapper>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: {
      customBranding: { workoutBackgrounds, selectedWorkoutBackground },
    },
  } = state;
  return { workoutBackgrounds, selectedWorkoutBackground, cloudfrontList };
};

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addWorkoutBackground: bindActionCreators(addWorkoutBackground, dispatch),
  deleteWorkoutBackground: bindActionCreators(deleteWorkoutBackground, dispatch),
});

export default connect(mapState, mapDispatch)(DefaultBackgroundImageUpload);
