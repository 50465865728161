// Lib
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

// Store
import { addMealPlan, updateMealPlan } from 'redux/meal-plans/actions';
import { toggleConfirmModal } from 'actions/modal';

// Components
import CustomOwnerShip from '../CustomOwnerShip';
import NumberOfWeek from '../NumberOfWeek';
import CoverImageContainer from 'components/Recipes/Parts/CoverImage';
import FormItemInput from 'shared/FormItemInput';
import S3Images from 'shared/S3Images';
import DiscardChangeModal from 'components/Recipes/Parts/DiscardChange';

// Constants
import { TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER, CALENDAR_LIMIT_WEEKS } from 'constants/commonData';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/MealPlans/upload_banner.svg';

import * as S from './style';

const MealPlanModal = props => {
  const {
    user,
    toggleModal,
    addMealPlan,
    loading,
    detailMealPlan,
    editMode = false,
    updateMealPlan,
    toggleConfirmModal,
  } = props;

  const { name, cover_image, number_of_weeks, description, share, author } = detailMealPlan || {};
  const { _id: ownerId } = author || {};

  const prepareData = {
    name,
    coverImage: cover_image,
    numberOfWeeks: number_of_weeks,
    description,
    owner: ownerId || author,
    share,
  };

  const initialData = {
    name: '',
    coverImage: '',
    numberOfWeeks: 1,
    description: '',
    owner: get(user, '_id') || '',
    share: TEAM_SHARE_PRIVATE,
  };

  const [formData, setFormData] = useState(initialData);
  const [originData, setOriginData] = useState(initialData);
  const [isShowDiscard, setIsShowDiscard] = useState(false);
  const [localUrl, setLocalUrl] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!isEmpty(detailMealPlan)) {
      setFormData(prepareData);
      setOriginData(prepareData);
    }
  }, [detailMealPlan]);

  useEffect(() => {
    updateFormDataAndShowDiscard({ onlyValidName: isEmpty(detailMealPlan), condition: !isEqual(originData, formData) });
  }, [formData]);

  const updateFormDataAndShowDiscard = ({ onlyValidName, condition }) => {
    if (onlyValidName) {
      setIsShowDiscard(condition && !!formData.name.trim());
    } else {
      setIsShowDiscard(condition);
    }
  };

  const onSetShareValue = shareValue => {
    setFormData(prevState => ({ ...prevState, share: shareValue.share }));
  };

  const onSetOwnerValue = ownerValue => {
    setFormData(prevState => ({ ...prevState, owner: ownerValue.owner }));
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (typeof value === 'string' || typeof value === 'number') {
      setFormData({ ...formData, [name]: value });
      setIsSubmitted(false);
    }
  };

  const handleUpdateWeek = value => {
    setFormData({ ...formData, numberOfWeeks: value });
    setIsSubmitted(false);
  };

  const handleClose = () => {
    toggleModal && toggleModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!formData.name.trim() || !formData.numberOfWeeks) {
      return;
    }

    if (parseInt(formData.numberOfWeeks) === 0 || parseInt(formData.numberOfWeeks) > CALENDAR_LIMIT_WEEKS) {
      setIsSubmitted(true);
      return;
    }

    let params = {
      name: formData.name.trim(),
      cover_image: formData.coverImage || '',
      number_of_weeks: formData.numberOfWeeks,
      description: '',
      owner: formData.share === TEAM_SHARE_NOOWNER ? null : formData.owner || get(user, '_id', ''),
      share: formData.share,
    };

    if (editMode) {
      const mealPlanId = get(detailMealPlan, '_id', '');
      const newParams = omit(params, ['number_of_weeks']);

      if (mealPlanId) {
        updateMealPlan &&
          updateMealPlan(mealPlanId, newParams, () => {
            toast('Successfully updated.');
            handleClose();
            setIsSubmitted(false);
          });
      }
    } else {
      addMealPlan &&
        addMealPlan(params, () => {
          handleClose();
          setIsSubmitted(false);
        });
    }
  };

  const handleUploadSuccess = ({ fileInfo }) => {
    if (fileInfo) {
      setFormData({ ...formData, coverImage: fileInfo });
    }
  };

  const handleDiscardChange = () => {
    if (isShowDiscard) {
      toggleConfirmModal && toggleConfirmModal(true, <DiscardChangeModal onConfirm={handleClose} />);
    } else {
      handleClose();
    }
  };

  const handleValidateWeek = () => {
    const { numberOfWeeks = 0 } = formData || {};
    const parsedNumberOfWeeks = parseInt(numberOfWeeks);

    const rules = [];

    if (parsedNumberOfWeeks === 0 || parsedNumberOfWeeks > CALENDAR_LIMIT_WEEKS) {
      rules.push({ required: true, valid: false, message: `Please enter a number from 1 to ${CALENDAR_LIMIT_WEEKS}` });
    }

    return rules;
  };

  const handleRemoveFile = () => {
    setFormData(prevState => ({ ...prevState, coverImage: '' }));
  };

  const isChangedData = useMemo(() => {
    return !isEqual(originData, formData);
  }, [formData, originData]);

  return (
    <S.CustomModal open={true} onClose={handleDiscardChange} closeOnDimmerClick={false} className="evf-meal-plan-modal">
      <Modal.Header className="modal-meal-plan-header">
        <S.HeaderWrapper>
          <S.HeaderTitle>{editMode ? 'Edit Meal Plan Info' : 'Create Meal Plan'}</S.HeaderTitle>
          <CloseButton className="close-button" onClick={handleDiscardChange}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <div>
            <S.Title>cover image</S.Title>
            <S.UploadWrapper>
              <CoverImageContainer
                contentUpload={
                  get(formData, 'coverImage') && !localUrl ? (
                    <S3Images src={[get(formData, 'coverImage', '')]} />
                  ) : (
                    <>
                      {!localUrl ? (
                        <S.UploadContent>
                          <UploadIcon />
                          <span>
                            Drag & Drop an image here or
                            <br />
                            <span className="custom-upload-content">Upload Image</span>
                          </span>
                        </S.UploadContent>
                      ) : null}
                    </>
                  )
                }
                noBorder={get(formData, 'coverImage', '')}
                localUrl={localUrl}
                setLocalUrl={setLocalUrl}
                isLoading={loadingUpload}
                setIsLoading={setLoadingUpload}
                uploadCoverSuccess={handleUploadSuccess}
                mealPlanSrc={!isEmpty(detailMealPlan) && get(formData, 'coverImage')}
                onRemoveFile={handleRemoveFile}
                checkExistImg={get(formData, 'coverImage', '')}
              />
            </S.UploadWrapper>
          </div>
          <S.Form onSubmit={handleSubmit}>
            <FormItemInput
              label="meal plan name"
              placeholder="Name your meal plan"
              name="name"
              isRequiredStar
              isHideErrorMessage
              value={get(formData, 'name', '')}
              onChange={handleChange}
              maxLength={90}
              inputProps={{ maxLength: 90 }}
            />
            <NumberOfWeek
              editMode={editMode}
              formData={formData}
              handleChange={handleChange}
              isSubmitted={isSubmitted}
              handleValidateWeek={handleValidateWeek}
              onUpdateWeek={handleUpdateWeek}
            />
            <CustomOwnerShip
              userId={get(user, '_id', '')}
              ownerValue={pick(formData, ['owner'])}
              shareValue={pick(formData, ['share'])}
              onSetShareValue={onSetShareValue}
              onSetOwnerValue={onSetOwnerValue}
              hasSpacing
              hasYou
              isEditAble={
                get(formData, 'share', null) === TEAM_SHARE_NOOWNER ||
                get(formData, 'owner', null) === get(user, '_id', '')
              }
            />
          </S.Form>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.Footer>
          <S.Button
            disabled={
              !get(formData, 'name', '').trim() ||
              parseInt(formData.numberOfWeeks) === 0 ||
              formData.numberOfWeeks === '' ||
              loading ||
              loadingUpload ||
              !isChangedData
            }
            onClick={handleSubmit}
          >
            {editMode ? 'Save' : 'Create'}
          </S.Button>
        </S.Footer>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { mealPlans },
    user,
  } = state;

  const detailMealPlan = get(mealPlans, 'detailMealPlan', {});

  return {
    user,
    detailMealPlan,
    loading: get(mealPlans, 'loadingCreate', false),
  };
};

const mapDispatchToProps = dispatch => ({
  addMealPlan: bindActionCreators(addMealPlan, dispatch),
  updateMealPlan: bindActionCreators(updateMealPlan, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanModal);
