import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';
import Request from 'configs/request';
import { pluralize } from 'utils/commonFunction';

export const Types = {
  INGREDIENT_LIBRARY_GET_LIST_REQUEST: 'INGREDIENT_LIBRARY_GET_LIST_REQUEST',
  INGREDIENT_LIBRARY_GET_LIST_SUCCESS: 'INGREDIENT_LIBRARY_GET_LIST_SUCCESS',
  INGREDIENT_LIBRARY_GET_LIST_FAILED: 'INGREDIENT_LIBRARY_GET_LIST_FAILED',
  INGREDIENT_LIBRARY_CHANGE_QUERY_PARAMS: 'INGREDIENT_LIBRARY_CHANGE_QUERY_PARAMS',
  INGREDIENT_LIBRARY_RESET_QUERY_PARAMS: 'INGREDIENT_LIBRARY_RESET_QUERY_PARAMS',
  INGREDIENT_LIBRARY_ADD_SINGLE_REQUEST: 'INGREDIENT_LIBRARY_ADD_SINGLE_REQUEST',
  INGREDIENT_LIBRARY_ADD_SINGLE_SUCCESS: 'INGREDIENT_LIBRARY_ADD_SINGLE_SUCCESS',
  INGREDIENT_LIBRARY_ADD_SINGLE_FAILED: 'INGREDIENT_LIBRARY_ADD_SINGLE_FAILED',
  INGREDIENT_LIBRARY_ADD_MULTI_REQUEST: 'INGREDIENT_LIBRARY_ADD_MULTI_REQUEST',
  INGREDIENT_LIBRARY_ADD_MULTI_SUCCESS: 'INGREDIENT_LIBRARY_ADD_MULTI_SUCCESS',
  INGREDIENT_LIBRARY_ADD_MULTI_FAILED: 'INGREDIENT_LIBRARY_ADD_MULTI_FAILED',
  INGREDIENT_LIBRARY_SELECTED_INGREDIENTS: 'INGREDIENT_LIBRARY_SELECTED_INGREDIENTS',
  INGREDIENT_LIBRARY_RESET_SELECTED_INGREDIENT: 'INGREDIENT_LIBRARY_RESET_SELECTED_INGREDIENT',
  INGREDIENT_LIBRARY_REMOVE_MULTI_REQUEST: 'INGREDIENT_LIBRARY_REMOVE_MULTI_REQUEST',
  INGREDIENT_LIBRARY_REMOVE_MULTI_SUCCESS: 'INGREDIENT_LIBRARY_REMOVE_MULTI_SUCCESS',
  INGREDIENT_LIBRARY_REMOVE_MULTI_FAILED: 'INGREDIENT_LIBRARY_REMOVE_MULTI_FAILED',
  INGREDIENT_LIBRARY_GET_LIST_UNIT_REQUEST: 'INGREDIENT_LIBRARY_GET_LIST_UNIT_REQUEST',
  INGREDIENT_LIBRARY_GET_LIST_UNIT_SUCCESS: 'INGREDIENT_LIBRARY_GET_LIST_UNIT_SUCCESS',
  INGREDIENT_LIBRARY_GET_LIST_UNIT_FAILED: 'INGREDIENT_LIBRARY_GET_LIST_UNIT_FAILED',
  INGREDIENT_LIBRARY_UPDATE_REQUEST: 'INGREDIENT_LIBRARY_UPDATE_REQUEST',
  INGREDIENT_LIBRARY_UPDATE_SUCCESS: 'INGREDIENT_LIBRARY_UPDATE_SUCCESS',
  INGREDIENT_LIBRARY_UPDATE_FAILED: 'INGREDIENT_LIBRARY_UPDATE_FAILED',
  INGREDIENT_LIBRARY_UPDATE_LOADING: 'INGREDIENT_LIBRARY_UPDATE_LOADING',
};

export const getIngredients = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        ingredientLibrary: { filters },
      },
    } = getState();
    const queryParams = params ? params : filters;

    dispatch({ type: Types.INGREDIENT_LIBRARY_GET_LIST_REQUEST });

    return dispatch(
      Request.get(
        {
          url: '/api/recipe-ingredients/library',
          params: queryParams,
        },
        false,
        (response, { dispatch }) => {
          const { data, total } = get(response, 'data.data');
          if (data) {
            dispatch({ type: Types.INGREDIENT_LIBRARY_GET_LIST_SUCCESS, payload: { data, total } });
          }
        },
        error => {
          dispatch({ type: Types.INGREDIENT_LIBRARY_GET_LIST_FAILED });
        },
      ),
    );
  };
};

export const changeQueryParams = queries => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        ingredientLibrary: { filters },
      },
    } = getState();

    const newQueries = { ...filters, ...queries };

    dispatch({
      type: Types.INGREDIENT_LIBRARY_CHANGE_QUERY_PARAMS,
      payload: { filters: newQueries },
    });
    dispatch(getIngredients(newQueries));
  };
};

export const resetQueryParams = () => ({ type: Types.INGREDIENT_LIBRARY_RESET_QUERY_PARAMS });

export const addSingleIngredient = (data, isDispatchData = true) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        ingredientLibrary: { filters },
      },
    } = getState();

    dispatch({ type: Types.INGREDIENT_LIBRARY_ADD_SINGLE_REQUEST });

    return dispatch(
      Request.post(
        {
          url: '/api/recipe-ingredients',
          data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});

          if (isDispatchData) {
            if (!isEmpty(data)) {
              dispatch({ type: Types.INGREDIENT_LIBRARY_ADD_SINGLE_SUCCESS, payload: { data, filters } });
            }
          } else {
            dispatch({ type: Types.INGREDIENT_LIBRARY_UPDATE_LOADING, payload: { loading: false } });
          }
        },
        () => {
          dispatch({ type: Types.INGREDIENT_LIBRARY_ADD_SINGLE_FAILED });
        },
      ),
    );
  };
};

export const editIngredient = (data, _id) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        ingredientLibrary: { filters },
      },
    } = getState();

    dispatch({ type: Types.INGREDIENT_LIBRARY_UPDATE_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/recipe-ingredients/${_id}`,
          data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (!isEmpty(data)) {
            dispatch({ type: Types.INGREDIENT_LIBRARY_UPDATE_SUCCESS, payload: { data, filters } });
          }
        },
        () => {
          dispatch({ type: Types.INGREDIENT_LIBRARY_UPDATE_FAILED });
        },
      ),
    );
  };
};

export const addMultiIngredient = (data, isDispatchData = true) => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        ingredientLibrary: { filters },
      },
    } = getState();
    const newData = data.map(item => omit(item, ['_id']));

    dispatch({ type: Types.INGREDIENT_LIBRARY_ADD_MULTI_REQUEST });

    return dispatch(
      Request.post(
        {
          url: '/api/recipe-ingredients/bulk',
          data: {
            data: newData,
          },
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (!isEmpty(data)) {
            if (isDispatchData) {
              dispatch({ type: Types.INGREDIENT_LIBRARY_ADD_MULTI_SUCCESS, payload: { data, filters } });
            } else {
              dispatch({ type: Types.INGREDIENT_LIBRARY_UPDATE_LOADING, payload: { loading: false } });
            }
            toast(
              `${pluralize('ingredient', newData.length, true)} ${newData.length === 1 ? 'has' : 'have'} been added.`,
            );
          }
        },
        () => {
          dispatch({ type: Types.INGREDIENT_LIBRARY_ADD_MULTI_FAILED });
        },
      ),
    );
  };
};

export const selectedIngredient = ingredientIds => {
  return { type: Types.INGREDIENT_LIBRARY_SELECTED_INGREDIENTS, payload: { ingredientIds } };
};

export const resetSelectedIngredient = () => {
  return { type: Types.INGREDIENT_LIBRARY_RESET_SELECTED_INGREDIENT };
};

export const removeMultiIngredient = listIds => {
  return dispatch => {
    dispatch({ type: Types.INGREDIENT_LIBRARY_REMOVE_MULTI_REQUEST });
    return dispatch(
      Request.post(
        {
          url: '/api/recipe-ingredients/bulk-remove',
          data: {
            ingredient_ids: listIds,
          },
        },
        true,
        (response, { dispatch }) => {
          const { success, deleted, validIds } = get(response, 'data.data');
          if (success) {
            dispatch({ type: Types.INGREDIENT_LIBRARY_REMOVE_MULTI_SUCCESS, payload: { data: validIds } });
            toast(`${pluralize('ingredient', deleted, true)} ${deleted === 1 ? 'has' : 'have'} been removed.`);
            dispatch(resetSelectedIngredient());
          }
        },
        () => {
          dispatch({ type: Types.INGREDIENT_LIBRARY_REMOVE_MULTI_FAILED });
        },
      ),
    );
  };
};

export const getListUnitIngredient = () => {
  return dispatch => {
    dispatch({ type: Types.INGREDIENT_LIBRARY_GET_LIST_UNIT_REQUEST });
    return dispatch(
      Request.get(
        {
          url: '/api/recipe-ingredients/units',
        },
        false,
        response => {
          const data = get(response, 'data.data', []);
          if (!isEmpty(data)) {
            dispatch({ type: Types.INGREDIENT_LIBRARY_GET_LIST_UNIT_SUCCESS, payload: { data } });
          }
        },
        () => {
          dispatch({ type: Types.INGREDIENT_LIBRARY_GET_LIST_UNIT_FAILED });
        },
      ),
    );
  };
};

export const getIngredientDetail = id => {
  return Request.get({ url: `/api/recipe-ingredients/${id}` });
};

export const addIngredientByCSV = data => {
  return Request.post({ url: '/api/recipe-ingredients/import', data });
};
