import React from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { Button } from 'shared/FormControl';
import LeftPanel from './components/LeftPanel';
import MainPanel from './components/MainPanel';
import ConfirmModal from 'shared/ConfirmModal';
import { WORKOUT_BUILDER_TYPES, WORKOUT_BUILDER_GUIDE_STEPS, CDN_URL } from 'constants/commonData';
import { Workout } from 'types/model';
import * as S from './style';

// import icons
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-circle.svg';
import { getWorkoutBuilderSetting, saveWorkoutSetting } from './helper';

const shouldDisableSaveAction = workoutData => {
  let disabled = false;

  const { sections = [] } = workoutData;

  sections.forEach(section => {
    const { exercises = [] } = section;

    if (exercises.length) {
      exercises.forEach(it => {
        const { supersets = [] } = it;

        if (supersets.length) {
          supersets.forEach(superset => {
            const { exercise, alternatives = [] } = superset;

            if (alternatives.includes(exercise)) {
              disabled = true;
            }
          });
        }
      });
    }
  });

  return disabled;
};

export default class WorkoutDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isDeleting: false,
      isHideLeftPanel: getWorkoutBuilderSetting(props.user._id).isHideLeftPanel || false,
      clickCloseButton: false,
      isChanging: false,
    };
    this.mainPanelRef = null;
  }

  componentDidMount() {
    if (!this.props.loadedOnboardingData) {
      this.props.getOnboardingWorkoutBuiler();
    }
    if (this.props.workingWorkout) {
      this.props.initWorkoutData(this.props.workingWorkout);
    } else {
      if (this.props.date) {
        this.props.initWorkoutData({ day: this.props.date, sections: [] });
      }
    }

    window.addEventListener('beforeunload', this.handleBeforeUnload);

    window.addEventListener('popstate', () => {
      this.onCloseCurrentPopup();
    });
  }

  componentWillUnmount() {
    this.props.clearWorkoutData();
    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    window.removeEventListener('popstate', () => {}, false);
  }

  handleBeforeUnload = event => {
    if (this.props.isChanged) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  generateMainPanelRef = ref => {
    if (ref && ref.getWrappedInstance) {
      this.mainPanelRef = ref.getWrappedInstance();
    }
  };

  onClickSaveButton = () => {
    if (this.mainPanelRef) {
      this.mainPanelRef.handleCancelAddSection();
    }

    if (this.state.isSaving || this.props.isUploading) {
      return;
    }

    const workoutData = this.props.validateSectionData();

    if (workoutData) {
      this.setState({ isSaving: true });
      this.props
        .onSave(workoutData)
        .then(newWorkout => {
          newWorkout && toast('Section has been saved');

          if (!newWorkout) {
            console.error('newWorkout undefined');
            return;
          }

          const { share, team, title, user, _id } = newWorkout;

          const dataWorkoutConvert = {
            sections: [
              {
                ..._.omit(newWorkout, ['share', 'share', 'author']),
              },
            ],
            share,
            team,
            author: user,
            title,
            _id,
          };

          let newWorkoutRes = dataWorkoutConvert;
          if (newWorkoutRes.sections_target) newWorkoutRes.sections = newWorkoutRes.sections_target;

          const { type } = this.props;
          const newOriginalWorkout =
            type === WORKOUT_BUILDER_TYPES.ASSIGNMENT
              ? _.toPlainObject(new Workout().parseFromAssignment(newWorkoutRes))
              : newWorkoutRes;
          this.props.updateOriginalWorkout(newOriginalWorkout);
          this.props.initWorkoutData(newWorkoutRes);

          if (!workoutData._id) {
            this.props.updateWorkoutId(newWorkoutRes._id);
          }

          if (typeof this.props.onSavedWithoutClose === 'function') {
            this.props.onSavedWithoutClose(newWorkoutRes);
          }
        })
        .finally(() => {
          this.setState({ isSaving: false });
        });
    }
  };

  onClickSaveAndCloseButton = (event, saveAsCopy) => {
    if (this.state.isSaving || this.props.isUploading) {
      return;
    }

    const workoutData = this.props.validateSectionData();

    if (workoutData) {
      if (shouldDisableSaveAction(workoutData)) {
        toast.error('Cannot add same alternative exercise with the original exercise');
      } else {
        this.setState({ isSaving: true });
        this.props.onSave(workoutData, saveAsCopy).then(response => {
          response && toast('Section has been saved');
          this.onCloseCurrentPopup();
        });
      }
    } else {
      if (this.mainPanelRef) {
        this.mainPanelRef.handleCancelAddSection();
      }
    }
  };

  onCloseCurrentPopup = () => {
    this.props.toggleModal(false);

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }

    this.props.markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection);
  };

  handleDenyClosePopup = () => {
    this.setState({ clickCloseButton: false });
  };

  handleCloseAction = () => {
    this.setState({ clickCloseButton: true }, () => {
      setTimeout(() => {
        if (this.state.isChanging) {
          this.props.toggleConfirmModal(
            true,
            <S.CustomConfirmModal
              noBorder
              title="Discard Changes?"
              content={`Are you sure you want to go? Changes have not been saved yet.`}
              onConfirm={this.onCloseCurrentPopup}
              onDeny={this.handleDenyClosePopup}
              confirmButtonTitle="Discard changes"
              hasCloseIcon
              headerIcon={`${CDN_URL}/images/alert_warning.svg`}
            />,
          );
        } else {
          this.onCloseCurrentPopup();
        }
      }, 0);
    });
  };

  onDeleteWorkout = workout => {
    const { type } = this.props;
    this.setState({
      isDeleting: true,
    });
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title={type === WORKOUT_BUILDER_TYPES.TEMPLATE ? 'Remove Workout from Library' : 'Delete workout'}
        content={'Are you sure that you want to delete this workout?'}
        onConfirm={() => {
          this.onCloseCurrentPopup();

          if (typeof this.props.onDelete === 'function') {
            this.props.onDelete(workout);
          }
        }}
        onDeny={() => {
          this.setState({
            isDeleting: false,
          });
        }}
      />,
    );
  };

  onSaveAsCopy = () => {
    this.onClickSaveAndCloseButton(null, true);
  };

  toggleHideLeftPanel = () => {
    const { isHideLeftPanel } = this.state;
    this.setState({ isHideLeftPanel: !isHideLeftPanel });
    saveWorkoutSetting(this.props.user._id, { isHideLeftPanel: !isHideLeftPanel });
  };

  onIsChanging = value => {
    this.setState({ isChanging: value });
  };

  onCheckField = checked => {
    const { workingWorkout } = this.props;
    let { isDeleting, isChanging } = this.state;

    if (!isDeleting && workingWorkout && workingWorkout._id && (checked || isChanging)) {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          content="Would you like to leave the current screen? You will lose any unsaved data."
          onConfirm={() => {
            this.onIsChanging(false);
            this.props.logWorkout(this.props.workingWorkout);
          }}
        />,
      );
    }
  };

  render() {
    const { type, trackable, readOnly, workingWorkout, isUploading } = this.props;
    const { isSaving, isHideLeftPanel, isChanging } = this.state;

    return (
      <S.ModalWrapper open={true} onClose={this.handleCloseAction} closeOnDimmerClick={false}>
        {!!workingWorkout && (
          <Helmet>
            <title>{workingWorkout.title} - Section - Everfit</title>
          </Helmet>
        )}
        <Modal.Content>
          <S.ContentStyles className="leftContent">
            <button className="close-button" onClick={this.handleCloseAction}>
              <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
            </button>
            <S.ContentContainer className={classnames('leftContent__contentContainer', { minimize: isHideLeftPanel })}>
              <div className={classnames('workout-left-panel', { hidden: isHideLeftPanel })}>
                <LeftPanel />
              </div>
              <S.MainPanelContainer className="workoutBuilder__mainPanelContainer">
                <div
                  className={classnames('arrow-icon', { 'hidden-icon': isHideLeftPanel })}
                  onClick={this.toggleHideLeftPanel}
                >
                  <ArrowIcon data-tip={`${isHideLeftPanel ? 'Open' : 'Close'} Quick Panel`} />
                </div>
                <ReactTooltip place="top" type="dark" effect="solid" className="app-tooltip" />
                <MainPanel
                  workoutType={type}
                  trackable={trackable}
                  onSave={this.onClickSaveButton}
                  saveToLibrary={this.props.saveToLibrary}
                  saveAsCopy={this.onSaveAsCopy}
                  logWorkout={this.props.logWorkout}
                  onDelete={this.onDeleteWorkout}
                  ref={this.generateMainPanelRef}
                  readOnly={readOnly}
                  isHideLeftPanel={isHideLeftPanel}
                  workoutData={this.props.workingWorkout}
                  clientData={this.props.clientData}
                  pdfType={this.props.pdfType}
                  isChanging={isChanging}
                  onIsChanging={this.onIsChanging}
                  onCheckField={this.onCheckField}
                  sectionFormat={this.props.sectionFormat}
                  removeSectionLibrary={this.props.removeSectionLibrary}
                />
              </S.MainPanelContainer>
            </S.ContentContainer>
            <S.Footer>
              <Button onClick={this.onClickSaveButton} disabled={isSaving || readOnly || isUploading} className="save">
                Save
              </Button>
              <Button
                purple
                onClick={this.onClickSaveAndCloseButton}
                disabled={isSaving || readOnly || isUploading}
                className="saveAndClose"
              >
                Save &amp; Close
              </Button>
            </S.Footer>
          </S.ContentStyles>
        </Modal.Content>
      </S.ModalWrapper>
    );
  }
}
