import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSections, selectSection, deleteSection, resetSection } from 'actions/sectionLibrary';
import { toggleConfirmModal } from 'actions/modal';
import SelectSection from './component';

const mapStateToProps = state => {
  const { sectionLibrary } = state;

  return {
    list: sectionLibrary.list || [],
  };
};

const mapDispatchToProps = dispatch => ({
  getSections: bindActionCreators(getSections, dispatch),
  selectSection: bindActionCreators(selectSection, dispatch),
  deleteSection: bindActionCreators(deleteSection, dispatch),
  resetSection: bindActionCreators(resetSection, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSection);
