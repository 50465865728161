import React from 'react';
import { Icon } from 'semantic-ui-react';
import * as S from './style';
import { ON_DEMAND_WORKOUTS_SORTER, ON_DEMAND_WORKOUTS_SORT } from 'redux/on-demand-workouts/reducer';

const TableHeader = ({ counter = 0, query, changeQueryParams }) => {
  const HeaderItems = [
    {
      name: 'workout',
      title: `Workouts (${counter})`,
      value: ON_DEMAND_WORKOUTS_SORTER.TITLE,
      showTotal: true,
      sort: true,
    },
    {
      name: 'level',
      title: 'Level',
      value: ON_DEMAND_WORKOUTS_SORTER.LEVEL,
      showTotal: false,
      sort: true,
    },
    {
      name: 'duration',
      title: 'Duration',
      value: ON_DEMAND_WORKOUTS_SORTER.DURATION,
      showTotal: false,
      sort: true,
    },
    {
      name: 'most_recent',
      title: 'Most Recent',
      value: ON_DEMAND_WORKOUTS_SORTER.MOST_RECENT,
      showTotal: false,
      sort: true,
    },
    {
      name: 'more_actions',
      title: '',
      value: '',
      showTotal: false,
      sort: false,
      blank: true,
    },
  ];
  const handleSort = value => () => {
    let newSort = ON_DEMAND_WORKOUTS_SORT.ASC;
    if (query.sorter === value) {
      if (query.sort === ON_DEMAND_WORKOUTS_SORT.ASC) {
        newSort = ON_DEMAND_WORKOUTS_SORT.DESC;
      }
    }
    changeQueryParams({ sorter: value, sort: newSort });
  };
  return (
    <S.TableHeader>
      <S.TableRow>
        {HeaderItems.map((item, index) => {
          if (item.blank) {
            return <div key={`header-${index}`} />;
          }
          const isActive = query.sorter === item.value;
          const isDesc =
            item.value === ON_DEMAND_WORKOUTS_SORTER.MOST_RECENT
              ? query.sort === ON_DEMAND_WORKOUTS_SORT.DESC
              : !(query.sort === ON_DEMAND_WORKOUTS_SORT.DESC);
          return (
            <S.HeaderItem key={`header-${index}`} active={isActive}>
              <S.HeaderItemContainer active={isActive}>
                <div className="click-container" onClick={handleSort(item.value)}>
                  {item.title}
                  {isActive ? (
                    <Icon className={`chevron ${isDesc ? 'down' : 'up'}`} />
                  ) : (
                    <Icon className={`chevron down`} />
                  )}
                </div>
              </S.HeaderItemContainer>
            </S.HeaderItem>
          );
        })}
      </S.TableRow>
    </S.TableHeader>
  );
};

export default TableHeader;
