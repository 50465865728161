import classNames from 'classnames';
import React from 'react';

import { ReactComponent as StarIcon } from 'assets/icons/star-3.svg';
import './styles.scss';

export const AI_TABS = {
  AI: 'AI Assistant',
  STANDARD: 'Standard Builder',
};

const TabBar = ({ currentTab, onChangeTab, isSending }) => {
  const renderTab = tab => (
    <div
      className={classNames('ai-tab-item', { active: currentTab === tab, disabled: isSending })}
      onClick={() => onChangeTab(tab)}
      id={tab.split(' ').join('_')}
    >
      {tab === AI_TABS.AI && <StarIcon />}
      <span>{tab}</span>
    </div>
  );
  return (
    <div className={classNames('ai-tab-wrapper', { disabled: isSending })}>
      {renderTab(AI_TABS.AI)}
      {renderTab(AI_TABS.STANDARD)}
    </div>
  );
};

export default TabBar;
