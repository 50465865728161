import React from 'react';
import { Loader } from 'semantic-ui-react';
import * as S from './style';

const Indicator = () => {
  return (
    <S.IndicatorWrapper>
      <Loader active inline="centered" className="custom-indicator" size={32}>
        <span>Loading forms</span>
      </Loader>
    </S.IndicatorWrapper>
  );
};

export default Indicator;
