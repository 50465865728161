import styled from 'styled-components';

export const customStyleSingle = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    background: '#ffffff',
    borderRadius: '4px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '38px',
    maxHeight: '38px',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FAFBFC',
  }),
  dropdownIndicator: (baseStyle, state) => ({
    ...baseStyle,
    cursor: 'pointer',
  }),
  input: (baseStyle, state) => ({
    ...baseStyle,
    input: {
      height: '22px !important',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#202353',
    },
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: '282px',
    marginTop: '4px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid #DCDCDE',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '6px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    maxHeight: 'unset',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    '::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused || state.isSelected ? '#edecfc' : '#ffffff',
    color: state.isFocused || state.isSelected ? '#5c58c0' : '#000000',
    cursor: 'pointer',
    maxHeight: 'unset',
    padding: '11px 16px',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: state.isSelected ? 700 : 400,
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: 'rgba(50, 60, 71, 0.3)',
    userSelect: 'none',
    marginLeft: 'unset',
    marginRight: 'unset',
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingLeft: '12px',
    gap: '8px',
    cursor: 'pointer',
    WebkitMaskImage: 'linear-gradient(90deg, #000 90%, transparent)',
    '> div:last-child': {
      margin: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      display: state.isDisabled ? 'none' : 'inline-block',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  loadingMessage: (baseStyle, state) => ({
    ...baseStyle,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505373',
    userSelect: 'none',
    minHeight: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  singleValue: (baseStyle, state) => ({
    ...baseStyle,
    color: '#000',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textOverflow: 'unset',
  }),
};

export const CategoryDropdownWrapper = styled.div`
  .category-loading {
    color: #505373;
    padding: 8px 12px;
    text-align: center;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    user-select: none;
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .category__dropdown-indicator :hover {
    cursor: pointer;
  }
`;
