import React from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { List } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import connect from 'react-redux/lib/connect/connect';

import { enableOnDemand } from 'utils/commonFunction';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/learn-about-package.svg';

import { SubMenuContainer, SidebarContent } from '../../styles';

function StudioSidebar(props) {
  const { activeItem, permission, user, generalSettings } = props;
  const isEnableOnDemand = enableOnDemand();
  const isEnabledOnDemandSettings = get(permission, 'studio_resource_collection', false);
  const isHideBannerStudioProgram = get(user, 'is_hide_banner_studio_program', false);
  const isHideBannerStudioCollection = get(generalSettings, 'is_passed_workout_collection_banner_onboarding', false);

  return (
    <SidebarContent className="sidebar-content" hideOnMobile>
      <div className="sidebar-divide" />
      <p>On-demand</p>
      <List className="menu-list">
        <div
          className={classnames('menu-list-item', { active: activeItem === 'studio_collection' })}
          onClick={() => {
            props.push('/home/studio-collection');
          }}
        >
          <span>Resource Collections</span>
        </div>
        <SubMenuContainer>
          <div
            className={classnames('menu-list-item', { active: activeItem === 'studio_resource' })}
            onClick={() => {
              props.push('/home/studio-resource');
            }}
          >
            <span>Resources</span>
          </div>
        </SubMenuContainer>
        {isEnableOnDemand && (
          <div
            className={classnames('menu-list-item', { active: activeItem === 'studio_workout_collection' })}
            onClick={() => {
              props.push('/home/workout-collections');
            }}
          >
            <span>Workout Collections</span>
          </div>
        )}
        {isEnableOnDemand && (
          <SubMenuContainer>
            <div
              className={classnames('menu-list-item', { active: activeItem === 'studio_on_demand_workout' })}
              onClick={() => {
                props.push('/home/on-demand-workouts');
              }}
            >
              <span>On-demand Workouts</span>
            </div>
          </SubMenuContainer>
        )}
        {isEnableOnDemand && (
          <SubMenuContainer>
            <div
              className={classnames('menu-list-item', { active: activeItem === 'studio_workout_labels' })}
              onClick={() => {
                props.push('/home/workout-labels');
              }}
            >
              <span>Workout Labels</span>
            </div>
          </SubMenuContainer>
        )}
        <div
          className={classnames('menu-list-item', { active: activeItem === 'studio_program' })}
          onClick={() => {
            props.push('/home/studio-programs');
          }}
        >
          <span>Studio Programs</span>
        </div>
        {isEnabledOnDemandSettings && (
          <div
            className={classnames('menu-list-item', { active: activeItem === 'studio_on_demand_setup' })}
            onClick={() => {
              props.push('/home/on-demand-settings');
            }}
          >
            <span>Settings</span>
          </div>
        )}
      </List>
      {activeItem === 'studio_workout_collection' && isHideBannerStudioCollection && (
        <>
          <div className="spacer" />
          <a
            className="guide-links"
            href="https://help.everfit.io/en/articles/6260935-workout-collections-launch-your-own-on-demand-netflix-style-training-content-portal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LearnMoreIcon className="guide-icon" />
            <p className="guide-title">Learn about Workout Collections</p>
          </a>
        </>
      )}
      {activeItem === 'studio_program' && isHideBannerStudioProgram && (
        <>
          <div className="spacer" />
          <a
            className="guide-links"
            href="https://help.everfit.io/en/articles/4671369-studio-programs-on-demand-training-programs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LearnMoreIcon />
            <p>Learn about Studio Programs</p>
          </a>
        </>
      )}
    </SidebarContent>
  );
}

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { generalSettings },
  } = state;

  return {
    user,
    generalSettings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudioSidebar);
