import React from 'react';
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import { ResetButtonWrapper } from './styles';

const ResetButton = ({ isShow, onReset }) => {
  if (!isShow) return null;
  return (
    <ResetButtonWrapper onClick={onReset}>
      <ResetIcon />
      <span>Reset</span>
    </ResetButtonWrapper>
  );
};

export default ResetButton;
