import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';

import TextEditable from 'shared/TextEditable';

import * as S from './style';
import Banner from './Banner';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const LIMIT_LENGTH = 90;

// Header of detail page
const BasicInfo = props => {
  let {
    data,
    onChangeDesc,
    onChangeTitle,
    getPreSignedUrl,
    onUploadImageChange,
    isEdit,
    isSubmitting,
    startUploadCoverImage,
    endUploadCoverImage,
    cloudfrontList,
  } = props;

  const [name, setName] = useState('Loading...');
  const [description, setDescription] = useState('Loading...');
  const [coverImage, setCoverImage] = useState(data.cover_image);

  useEffect(() => {
    if (!isEmpty(data.name)) {
      setName(data.name);
      setDescription(data.description);
      setCoverImage(data.cover_image);
    }
  }, [data]);

  const handleTitleChange = value => {
    if (value.length <= LIMIT_LENGTH) {
      setName(value);
      onChangeTitle && onChangeTitle(value);
    } else {
      // handle copy paste case out of chars limit
      setName(value.slice(0, LIMIT_LENGTH));
      onChangeTitle && onChangeTitle(value.slice(0, LIMIT_LENGTH));
    }
  };

  const handleDescriptionChange = value => {
    setDescription(value);
    onChangeDesc && onChangeDesc(value);
  };

  const handleChangeCoverImage = value => {
    setCoverImage(value);
    onUploadImageChange && onUploadImageChange(value);
  };

  return (
    <S.Wrapper isEdit={isEdit}>
      <Banner
        isSubmitting={isSubmitting}
        isEdit={isEdit}
        coverImage={convertS3UrlToCloudFrontUrl(coverImage, cloudfrontList, true)}
        getPreSignedUrl={getPreSignedUrl}
        onUploadImageChange={handleChangeCoverImage}
        startUploadCoverImage={startUploadCoverImage}
        endUploadCoverImage={endUploadCoverImage}
      />
      <div className="product-info-container">
        <TextEditable
          readOnly={!isEdit}
          className="product-title"
          value={name}
          onChange={handleTitleChange}
          placeholder="Add Sequence name"
          lineHeight={27}
          invalid={!name || name.length > LIMIT_LENGTH}
          maxLength={LIMIT_LENGTH}
        />
        <TextEditable
          readOnly={!isEdit}
          className="product-description"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Add Sequence description"
          lineHeight={19.5}
          breakLine
        />
      </div>
    </S.Wrapper>
  );
};

export default BasicInfo;
