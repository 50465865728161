import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px 0px;
  overflow: auto;
`;

export const Container = styled.div`
  width: 1085px;
  margin: 0 auto;
`;
