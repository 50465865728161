import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  .workout-collection-detail__name {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #202353;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 23px;
    transform: translateY(5px);

    .__react_component_tooltip.tooltip--latest-edit-by {
      width: 193px;
      padding: 15px;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .workout-collection-detail__nav-menu {
    margin-top: 0;
    transform: translateX(-33px);

    .workout-collection-detail__nav-menu__item {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      padding-bottom: 14px;
      color: #858585;
      position: relative;
      margin: 0 18px;
      text-decoration: none !important;

      :first-child {
        margin-left: 0;
      }

      ::after {
        content: '';
        position: absolute;
        display: none;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #5158cf;
        left: 0;
      }

      &.active {
        color: #5158cf;

        ::after {
          display: block;
        }
      }
    }
  }
`;
