import { cloneDeep, get, isObject } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { ReactComponent as LeftBanner } from 'assets/icons/forum-leaderboard-post-banner-left.svg';
import { ReactComponent as RightBanner } from 'assets/icons/forum-leaderboard-post-banner-right.svg';
import { ReactComponent as FirstPlaceStart } from 'assets/icons/forun-leaderboard-first-place-star.svg';
import { ReactComponent as SecondPlaceStart } from 'assets/icons/forun-leaderboard-second-place-star.svg';
import { ReactComponent as ThirdPlaceStart } from 'assets/icons/forun-leaderboard-third-place-star.svg';
import {
  convertUnit,
  formatLeaderboardRanking,
  getLeaderboardUnitLabel,
  getUserShortName,
  roundUp,
} from 'utils/commonFunction';
import * as S from './style';

function ShareLeaderboardPost({ leaderboard, rankingTypes, user, leaderboardSetting }) {
  const { name } = leaderboard;

  const leaderboardClients = useMemo(() => {
    if (leaderboard) {
      const leaderboardData = cloneDeep(leaderboard);
      const metricSettingData = leaderboardSetting.find(it => it.unique_code === 'weight');
      const defaultSetting = cloneDeep(get(leaderboardData, 'unit_id'));
      const setting =
        leaderboardData.ranking_type === 2 && metricSettingData
          ? metricSettingData.unit
          : get(user, 'preferences')['weight'];
      const members = leaderboardData.members ? leaderboardData.members : [];
      const convertData = members.map(it => {
        it.score = roundUp(
          defaultSetting && isObject(defaultSetting) && setting
            ? roundUp(convertUnit(it.score, defaultSetting, setting), 2)
            : it.score,
          2,
        );
        it.setting = setting;
        return it;
      });

      return convertData;
    }
    return [];
  }, [leaderboard, leaderboardSetting]);

  const renderStar = idx => {
    switch (idx) {
      case 0:
        return <FirstPlaceStart />;
      case 1:
        return <SecondPlaceStart />;
      case 2:
        return <ThirdPlaceStart />;
      default:
        return null;
    }
  };

  const rankingType = rankingTypes.toJS().find(item => item.value === leaderboard.ranking_type);

  const unit = useMemo(() => {
    if (rankingType) {
      return getLeaderboardUnitLabel(rankingType, leaderboardClients[0]);
    }
    return '';
  }, [leaderboardClients]);

  return (
    <S.Wrapper>
      <div className="header">
        <LeftBanner />
        <div className="leaderboard-name">{name}</div>
        <RightBanner />
      </div>
      <div className="body">
        {leaderboardClients.map((c, idx) => {
          if (idx < 3) {
            return (
              <div className="rank-row">
                <div className="client-info-container">
                  <Avatar name={getUserShortName(c.user)} size="42" src={c.user.avatar} color={c.user.color} />
                  <div className="client-name">
                    {c.user.first_name} {c.user.last_name}
                  </div>
                </div>
                <span className="client-score">
                  {Number(c.score).toSeperateFormat()} {formatLeaderboardRanking(unit, c.score)}
                  {renderStar(idx)}
                </span>
              </div>
            );
          } else return null;
        })}
      </div>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    rankingTypes: state.rootReducer.leaderboard.get('rankingTypes'),
    leaderboardSetting: state.rootReducer.forum.leaderboardSetting,
  };
};

export default connect(mapStateToProps)(ShareLeaderboardPost);
