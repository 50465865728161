import { padTo2Digits } from 'utils/commonFunction';

export const getMinMaxHeartRateValue = data => {
  let overallMin = data[0].min;
  let overallMax = data[0].max;

  data.forEach(item => {
    if (item.min < overallMin) {
      overallMin = item.min;
    }
    if (item.max > overallMax) {
      overallMax = item.max;
    }
  });

  return { min: overallMin, max: overallMax };
};

export const splitPoints = [80, 100, 120, 130, 150, 180];
export const colors = ['#02ABF4', '#4DD44D', '#FFC150', '#FF865A', '#FC4444'];

const findStepTemp = (gapStep, arrStep) => arrStep.findIndex(step => gapStep <= step);

export const getNewDataMinMax = (minValue, maxValue) => {
  let chartMinValue = Math.floor(minValue / 5) * 5;
  if (minValue - chartMinValue === 0) {
    chartMinValue -= 5;
  }
  chartMinValue = Math.max(0, chartMinValue);
  const gapValue = maxValue - chartMinValue;
  let gapStep = gapValue / 3;
  let step;

  if (gapStep <= 5) {
    const arrStepNonTimeUnit = [1, 2, 3, 4, 5];
    let index = findStepTemp(gapStep, arrStepNonTimeUnit);
    step = index !== -1 ? arrStepNonTimeUnit[index] : Math.ceil(gapStep / 5) * 5;
  } else {
    step = Math.ceil(gapStep / 5) * 5;
  }

  if (minValue - chartMinValue <= 4 && step > 15) {
    chartMinValue -= 5;
  }

  let chartMaxValue = chartMinValue + step * 3;

  return [chartMinValue, chartMaxValue, 4];
};

export const convertSeconds = seconds => {
  if (seconds < 0) {
    return '';
  }

  let hours = Math.floor(seconds / 3600);
  let remainingSeconds = seconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds = remainingSeconds % 60;

  if (hours > 0) {
    return minutes > 0 ? `${hours}h${minutes}m` : `${hours}h`;
  }
  if (minutes > 0) {
    return remainingSeconds > 0 ? `${minutes}m${remainingSeconds}s` : `${minutes}m`;
  }
  return remainingSeconds === 0 ? '0m' : `${remainingSeconds}s`;
};

export function convertSecondsToTime(secondsInput) {
  if (!secondsInput) return '';
  if (typeof secondsInput !== 'number') return '';

  let seconds = secondsInput;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  hours = hours % 24;

  return hours > 0
    ? `${hours}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    : `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

export const splitRangeByMaxHeartRate = zones => {
  const chartRageColors = zones.reduce((arr, curr, index) => {
    if (curr.duration !== 0) {
      arr.push({
        color: colors[index],
      });
    }
    return arr;
  }, []);

  const results = chartRageColors.map((range, index) => {
    return {
      ...range,
      percentage:
        chartRageColors.length === 1
          ? 100
          : (chartRageColors.length - (index + 1)) * (100 / (chartRageColors.length - 1)),
    };
  });

  return results.reverse();
};
