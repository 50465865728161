import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Droppable } from 'react-beautiful-dnd';
import * as Cell from 'components/AutoflowCalendar/Cell.style';
import CreatePopup from '../CreatePopup';
import { Wrapper } from './style';
import Content from './Content';
import ConfirmModal from 'shared/ConfirmModal';
import AutoflowInAppMessage from '../InAppMessage';
import { CDN_URL, IN_APP_AUTO_MESSAGE } from 'constants/commonData';

const DATE_FORMAT = 'MM-DD-YYYY';
const DATABASE_TIME_FORMAT = 'HH:mm';

export default function CellContent(props) {
  const { day, copying, messages, match, dayId, user, toggleSideBar } = props;

  useEffect(() => {
    toggleSideBar && toggleSideBar(false);
  }, []);

  const [showAll, setShowAll] = useState(false);
  const contentRef = useRef();

  const isToday = !showAll && day.isSame(moment(), 'day');

  const {
    params: { autoflowId },
    url,
  } = match;

  const onEdit = message => {
    props.push(`${url}/${message._id}?type=${message.type}`);
  };

  const onCopy = message => {
    props.copy(message);
  };

  const onPaste = event => {
    const multiPaste = event.shiftKey;

    if (copying) {
      const { time } = copying;
      const today = moment();
      let copyToDate;

      if (copying.type === IN_APP_AUTO_MESSAGE) {
        copyToDate = moment(`${day.format(DATE_FORMAT)} ${time}`, `${DATE_FORMAT} ${DATABASE_TIME_FORMAT}`);
      } else {
        const originDate = moment(copying.date);
        copyToDate = moment(day).set('hour', originDate.hour()).set('minute', originDate.minute());
      }

      if (copying.type === IN_APP_AUTO_MESSAGE && copyToDate.isBefore(today, 'minute')) {
        const newMessage = Object.assign({}, copying);
        newMessage.day = day.format(DATE_FORMAT);
        newMessage.status = 0;
        delete newMessage._id;

        return props.toggleModal(
          true,
          <AutoflowInAppMessage
            day={copyToDate.format(DATE_FORMAT)}
            originData={newMessage}
            extendData={{ multiPaste, action: 'paste' }}
          />,
        );
      }

      const params = {
        messageId: copying._id,
        autoflow: autoflowId,
        date: copyToDate.format(),
        day: day.format(DATE_FORMAT),
        timezone: user.timezone,
      };

      props.paste(params, multiPaste);
    }
  };

  const onDelete = message => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Message"
        content="Are you sure that you want to remove this message?"
        onConfirm={() => props.deleteMessage({ autoflow: autoflowId, id: message._id }, day.format(DATE_FORMAT))}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  const onArrange = (message, newIndex) => {
    const bodyData = {
      autoflow: autoflowId,
      messageId: message._id,
      dayMessageId: dayId,
      newIndex,
    };

    props.arrangeMessage(bodyData);
  };

  return (
    <Wrapper className="autoflow-message__calendar-cell">
      <Cell.Container copying={!!copying} showAll={showAll}>
        {showAll && (
          <Cell.CancelViewMore
            onClick={() => {
              if (contentRef.current) {
                contentRef.current.scrollTop = 0;
              }

              setShowAll(false);
            }}
          />
        )}
        <Cell.Header>
          <Cell.DateContainer today={isToday}>{day.format(showAll ? 'MMM DD' : 'DD')}</Cell.DateContainer>
          <Cell.AddNew onClick={() => props.toggleModal(true, <CreatePopup date={day} />)} />
        </Cell.Header>
        <Droppable droppableId={`${day.format(DATE_FORMAT)}${dayId ? ';' + dayId : ''}`}>
          {(provided, snapshot) => (
            <Cell.Content
              ref={ref => {
                provided.innerRef(ref);
              }}
              style={{
                overflowY: showAll || snapshot.isDragging ? 'auto' : 'hidden',
              }}
              {...provided.droppableProps}
            >
              <Content
                messages={messages}
                date={day.format(DATE_FORMAT)}
                minimize={!showAll}
                onViewMore={() => setShowAll(true)}
                onEdit={onEdit}
                onCopy={onCopy}
                onDelete={onDelete}
                onPaste={onPaste}
                dayId={dayId}
                copying={copying}
                ref={contentRef}
                onArrange={onArrange}
                compactView={props.compactView}
              />
              {provided.placeholder}
            </Cell.Content>
          )}
        </Droppable>
      </Cell.Container>
    </Wrapper>
  );
}
