import React from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { SortableHandle, SortableContainer, SortableElement } from 'react-sortable-hoc';
import ReactTooltip from 'react-tooltip';

import SearchExercise from 'shared/SearchExercise';
import { RemoveIcon } from 'shared/Icons';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';
import { MODES } from 'components/ExerciseForm/component';
import ExerciseForm from 'components/ExerciseForm';

import { ReactComponent as SortHamDrag } from 'assets/icons/sort_ham.svg';
import { ReactComponent as WarningExerciseIcon } from 'assets/icons/warning-exercise.svg';
import { Wrapper, Body, ExerciseItem, ExerciseMenu, WarningWrap } from './style';

const DragHandle = SortableHandle(() => (
  <div className="exercise-item__sort">
    <SortHamDrag />
  </div>
));

const SortableItem = SortableElement(
  ({ data, disabledEdit, currentID, clicked, itemIndex, onRemove, onUpdateExerciseLibrary, isExpand = false }) => {
    const onClickRemove = () => {
      onRemove(data._id);
    };

    const onSaveExercise = newData => {
      onUpdateExerciseLibrary({ oldExerciseId: data._id, newExercise: newData.exercise });
    };

    return (
      <ExerciseItem className="alt-exs__exercise-item" key={data._id}>
        <div className={classnames('index', isExpand && 'expand')}>{itemIndex + 1}.</div>
        <div className={classnames(`info`, { clicked: clicked && currentID === itemIndex })}>
          <ExerciseMediaPreview
            size={{ width: 30, height: 30 }}
            indicatorSize={{ width: 10, height: 10 }}
            exercise={data}
            onSaveExercise={onSaveExercise}
          />
          <div className="title">{data.title}</div>
          {!disabledEdit && <DragHandle />}
        </div>
        {!disabledEdit && <RemoveIcon onClick={onClickRemove} className="remove" size={15} />}
      </ExerciseItem>
    );
  },
);

const SortableList = SortableContainer(
  ({ items, disabledEdit, currentID, clicked, exercisesNOTExisted, onClickRemoveIcon, onUpdateExerciseLibrary }) => (
    <ExerciseMenu className="alt-exs__exercise-menu" onScroll={e => e.stopPropagation()}>
      {exercisesNOTExisted}
      {_.map(items, (item, index) => (
        <SortableItem
          key={`item-${item._id}`}
          itemIndex={index}
          index={index}
          currentID={currentID}
          data={item}
          clicked={clicked}
          onRemove={onClickRemoveIcon}
          onUpdateExerciseLibrary={onUpdateExerciseLibrary}
          disabledEdit={disabledEdit}
          isExpand={items.length > 9}
        />
      ))}
    </ExerciseMenu>
  ),
);

class ListExercises extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exercises: props.exerciseReferences || [],
      disabledEdit: props.disabledEdit || false,
      clicked: false,
      currentID: null,
      createNewExercise: null,
    };
  }

  onSelectExercise = data => {
    const { exercises } = this.state;
    const { originalExerciseReferences } = this.props;

    if (data._id === originalExerciseReferences) {
      return toast.error('Cannot add same alternative exercise with the original exercise');
    }

    if (_.find(exercises, item => item._id === data._id)) {
      return false;
    }

    let newExercises = exercises.slice();
    newExercises.push(data);

    this.setState({ exercises: newExercises });
  };

  removeExercise = exId => {
    const { exercises } = this.state;
    this.setState({ exercises: exercises.filter(item => item._id !== exId) });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let exercises = this.state.exercises.slice();
    const removed = exercises.splice(oldIndex, 1);

    exercises.splice(newIndex, 0, removed[0]);
    document.body.style.cursor = 'default';

    this.setState({ exercises });
    this.handleClicked(null);
  };

  onSortStart = () => {
    document.body.style.cursor = 'grabbing';
  };

  onUpdateExerciseLibrary = data => {
    const { exercises } = this.state;
    const { oldExerciseId, newExercise } = data;

    if (exercises.length) {
      const exercises = this.state.exercises.slice();

      _.forEach(exercises, (ex, index) => {
        if (ex._id === oldExerciseId) {
          exercises[index] = { ...newExercise };
        }
      });

      this.setState({ exercises });
    }

    if (typeof this.props.onUpdateExerciseLibrary === 'function') {
      this.props.onUpdateExerciseLibrary(data);
    }
  };

  componentDidUpdate() {
    const { exercises } = this.state;
    const {
      exerciseReferences,
      onSave,
      setRemoveExerciseState,
      removeAllExerciseState,
      onUpdatePrefrenceExercise,
    } = this.props;

    if (exercises !== exerciseReferences) {
      if (onSave) onSave(exercises);
      if (onUpdatePrefrenceExercise) onUpdatePrefrenceExercise(exercises);
    }

    if (removeAllExerciseState) {
      if (onSave) onSave([]);
      setRemoveExerciseState(false);
      this.setState({ exercises: [] });
      if (onUpdatePrefrenceExercise) onUpdatePrefrenceExercise([]);
    }
  }

  handleClicked = val => {
    let { clicked } = this.state;
    this.setState({ clicked: !clicked, currentID: val ? val['index'] : null });
  };

  handleHideSearch = () => {
    const { exercises } = this.state;
    const { mostRecents, placeholder, hideSearch } = this.props;

    if (hideSearch) return;
    return (
      <SearchExercise
        defaultOptions={this.handleRenderResultSearch(mostRecents)}
        defaultValue={null}
        onSelect={this.onSelectExercise}
        onSave={this.onSaveExercise}
        onlySelect={true}
        disableCreateNew={true}
        menuIsAutoOpen={!exercises.length}
        excludes={_.map(exercises, '_id')}
        optionHeader="Exercises"
        placeholder={placeholder}
      />
    );
  };

  handleRenderResultSearch = mostRecents => {
    const { exercises } = this.state;
    const selected = _.map(exercises, item => item._id);
    let mostRecentsResult = _.cloneDeep(mostRecents);

    mostRecentsResult =
      selected.length > 0 ? _.filter(mostRecentsResult, item => !selected.includes(item._id)) : mostRecentsResult;
    return mostRecentsResult;
  };

  createNewExerciseSuccess = ({ exercise, close }) => {
    const { createNewExercise } = this.state;
    this.setState(
      s => ({
        ...s,
        exercises: s.exercises.map(item => {
          if (item._id === createNewExercise._id) {
            return exercise;
          }
          return item;
        }),
        createNewExercise: close ? null : exercise,
      }),
      () => {
        const { addNewExerciseToAllList, sectionId, updateExerciseInFreestyleSection } = this.props;
        addNewExerciseToAllList(exercise);
        if (sectionId && updateExerciseInFreestyleSection) {
          updateExerciseInFreestyleSection(sectionId, createNewExercise._id, exercise);
        }
      },
    );
  };

  classifyExercises = items => {
    const exercisesExisted = [];
    const exercisesNOTExisted = [];
    items.forEach(item => {
      item.is_existing === false ? exercisesNOTExisted.push(item) : exercisesExisted.push(item);
    });
    return { exercisesExisted, exercisesNOTExisted };
  };

  onCreateNewExercise = exercise => () => {
    this.setState({ createNewExercise: exercise });
  };

  onClickRemoveNotExist = exerciseId => () => {
    this.setState(
      s => ({ ...s, exercises: s.exercises.filter(item => item._id !== exerciseId) }),
      () => {
        const { sectionId, removeExerciseNotExisted } = this.props;
        removeExerciseNotExisted(sectionId, exerciseId);
      },
    );
  };

  renderExerciseNotExisted = item => {
    const { _id, title } = item;
    const tooltipID = `ex-${_id}-wk-tooltip`;

    return (
      <ExerciseItem key={_id} className="exercise-not-existed">
        <WarningWrap>
          <ReactTooltip
            className="app-tooltip ai-tooltip ex-tooltip"
            id={tooltipID}
            effect="solid"
            place="top"
            delayShow={100}
          >
            This exercise is not on library
          </ReactTooltip>
          <WarningExerciseIcon className="waring-icon" data-for={tooltipID} data-tip />
          <p>{title}</p>
        </WarningWrap>
        <button className="create-new-ex" onClick={this.onCreateNewExercise(item)}>
          Create this exercise
        </button>
        {!this.state.disabledEdit && (
          <RemoveIcon onClick={this.onClickRemoveNotExist(item._id)} className="remove" size={15} />
        )}
      </ExerciseItem>
    );
  };

  onCloseExercisePopup = () => {
    this.setState({ createNewExercise: null });
  };

  render() {
    const { exercises, clicked, currentID, disabledEdit, createNewExercise } = this.state;
    const { exercisesExisted, exercisesNOTExisted } = this.classifyExercises(exercises);
    const isHasExerciseNotExisted = exercisesNOTExisted.length > 0;

    return (
      <Wrapper className={classnames('alt-exs', { warning: isHasExerciseNotExisted })}>
        {!!createNewExercise && (
          <ExerciseForm
            open={true}
            mode={MODES.CREATE}
            firstTitle={createNewExercise.title}
            closePopup={this.onCloseExercisePopup}
            onSubmitSuccess={this.createNewExerciseSuccess}
          />
        )}
        <Body className="alt-exs__body">
          {this.handleHideSearch()}
          <SortableList
            disabledEdit={disabledEdit}
            helperClass="sortableHelper"
            items={exercisesExisted}
            currentID={currentID}
            clicked={clicked}
            exercisesNOTExisted={exercisesNOTExisted.map(this.renderExerciseNotExisted)}
            onSortStart={this.onSortStart}
            onSortEnd={this.onSortEnd}
            updateBeforeSortStart={this.handleClicked}
            onClickRemoveIcon={this.removeExercise}
            onUpdateExerciseLibrary={this.onUpdateExerciseLibrary}
            useDragHandle
            lockAxis="y"
          />
        </Body>
      </Wrapper>
    );
  }
}

export default ListExercises;
