// Libs
import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Components
import VideoLink from './components/VideoLink';
import StepInstruction from './components/StepInstruction';
import StepInstructionDetail from './components/StepInstructionDetail';

// Styles
import * as S from './style';

const tabs = [
  {
    key: 'preparation',
    label: 'Preparation',
  },
  {
    key: 'cooking',
    label: 'Cooking',
  },
];

const Instruction = props => {
  const {
    isEditMode,
    IntroInstruction,
    onChangeLink = () => {},
    PrepareInstructions,
    CookingInstructions,
    handleUpdateStepInstruction,
    setUploadLoading,
    isLoading,
    isSubmitted,
    isSubmittedPublish,
    updateDataDetailRecipe = () => {},
    error = {},
    updateLoadingVideoInstruction = () => {},
    loadingVideoInstruction = false,
  } = props;

  const [tab, setTab] = useState(tabs[0]);

  const isEmptyStepPrepare = get(tab, 'key') === 'preparation' && isEmpty(PrepareInstructions);
  const isEmptyStepCooking = get(tab, 'key') === 'cooking' && isEmpty(CookingInstructions);

  useEffect(() => {
    if (!isEditMode && isEmpty(PrepareInstructions) && !isEmpty(CookingInstructions)) {
      setTab(tabs[1]);
    }
  }, []);

  const onTabClick = data => {
    setTab(data);
  };

  const renderEmptyStep = () => {
    return <S.TabContentEmpty>No available steps.</S.TabContentEmpty>;
  };

  return (
    <S.InstructionWrapper>
      <S.VideoLinkWrapper isEditMode={isEditMode} hasVideoIntro={get(IntroInstruction, 'thumbnail_url', '')}>
        <VideoLink
          isEditMode={isEditMode}
          link={get(IntroInstruction, 'url', '')}
          thumbnail={get(IntroInstruction, 'thumbnail_url', '')}
          name={get(IntroInstruction, 'name', '')}
          attachment_type={get(IntroInstruction, 'attachment_type', '')}
          onChangeLink={onChangeLink}
          updateDataDetailRecipe={updateDataDetailRecipe}
          error={error}
          updateLoadingVideoInstruction={updateLoadingVideoInstruction}
          loadingVideoInstruction={loadingVideoInstruction}
        />
      </S.VideoLinkWrapper>
      <S.TabHeader>
        {tabs.map(item => {
          const { key, label } = item;
          return (
            <S.TabHeaderItem onClick={() => onTabClick(item)} active={key === get(tab, 'key', '')} key={key}>
              {label}
            </S.TabHeaderItem>
          );
        })}
      </S.TabHeader>
      <S.TabContent isEditMode={isEditMode} isEmptyStep={(isEmptyStepPrepare || isEmptyStepCooking) && !isEditMode}>
        {isEditMode ? (
          <StepInstruction
            PrepareInstructions={PrepareInstructions}
            CookingInstructions={CookingInstructions}
            handleUpdateStepInstruction={data => handleUpdateStepInstruction(data, tab)}
            tab={tab}
            setUploadLoading={setUploadLoading}
            isLoading={isLoading}
            isSubmitted={isSubmitted}
            isSubmittedPublish={isSubmittedPublish}
          />
        ) : (
          <StepInstructionDetail
            PrepareInstructions={PrepareInstructions}
            CookingInstructions={CookingInstructions}
            tab={tab}
          />
        )}
        {(isEmptyStepPrepare || isEmptyStepCooking) && !isEditMode && renderEmptyStep()}
      </S.TabContent>
    </S.InstructionWrapper>
  );
};

export default Instruction;
