import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import round from 'lodash/round';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import {
  MEDIA_PLACEHOLDER,
  CURRENCIES,
  PACKAGE_STATUS,
  PACKAGE_PRICING_TYPES,
  TEXT_CURRENCIES,
  CDN_URL,
} from 'constants/commonData';
import { push } from 'connected-react-router';
import { formatMoney, pluralize } from 'utils/commonFunction';
import { toggleModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import AddPackagePopup from '../AddPackagePopup';

import { archivePackage, removePackage } from 'redux/package-list/actions';
import { enableEditMode } from 'redux/package-detail/actions';
import { toggleConfirmModal } from 'actions/modal';
import { SharedTooltip } from 'shared/SharedTooltip';

import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/action_archive.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash_icon.svg';
import { ReactComponent as MarketplaceIcon } from 'assets/icons/marketplace_icon.svg';

import * as S from './style';

const TableContent = props => {
  const { list, isMP, permission } = props;
  const packages = useMemo(() => list.toJS(), [list]);
  const path = isMP ? 'marketplace' : 'packages';
  const hasMP = permission.marketplace_payment;

  const renderTrigger = ({ open }) => (
    <div data-tip data-for="package-table-tooltip">
      <MenuTrigger vertical active={!!open} />
      <SharedTooltip id="package-table-tooltip" />
    </div>
  );

  const handleEdit = item => () => {
    const id = item.hash_id || item.id;
    props.push(`/home/${path}/${id}`);
  };

  const handleConfirmArchive = id => () => {
    props.archivePackage({ id, status: 'archive', isMP });
  };

  const handleUnArchive = item => () => {
    props.archivePackage({ id: item.id, status: 'draft', isMP });
  };

  const hasOverlapByOnboarding = document.querySelector('.onboarding-guide');
  const hasOverlapByIntercom = document.querySelector('.intercom-launcher');

  const handleSelectedPackage = item => () => {
    const messageConfirm = `Are you sure you want to archive ${item.name}?`;
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title={messageConfirm}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Yes, Archive Package"
        hasCloseIcon
        newStyle={true}
        content="Archiving the package will unpublish the listing, so new purchases can not be processed. Existing
        subscriptions, however, will continue to be billed as usual until cancelled."
        headerIcon={`${CDN_URL}/images/archive_light_package.svg`}
        confirmButtonClass="btn-purple"
        onConfirm={handleConfirmArchive(item.id)}
      />,
    );
    return;
  };

  const handleDuplicate = (item, toMP = false) => () => {
    props.toggleModal(
      true,
      <AddPackagePopup isMP={toMP || isMP} toMP={toMP} packageName={`${item.name} - Copy`} packageId={item.id} />,
    );
  };

  const doRemove = id => () => {
    props.removePackage(id, isMP);
  };

  const handleRemove = item => () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove package"
        hasCloseIcon
        newStyle={true}
        src={`${CDN_URL}/images/trash-circle.svg`}
        confirmButtonTitle="Delete"
        content={
          <span>
            Are you sure you want to remove <b>{item.name}</b>?
          </span>
        }
        onConfirm={doRemove(item.id)}
      />,
    );
    return;
  };

  const handleClickName = event => {
    const { item, status, sold } = event.currentTarget.dataset;

    if (status === PACKAGE_STATUS.PUBLISH || sold > 0) {
      if (event.metaKey || event.ctrlKey || event.shiftKey) {
        window.open(window.location.origin + `/home/${path}/${item}/analytics`);
      } else {
        props.push(`/home/${path}/${item}/analytics`);
      }
    } else {
      if (event.metaKey || event.ctrlKey || event.shiftKey) {
        window.open(window.location.origin + `/home/${path}/${item}`);
      } else {
        props.push(`/home/${path}/${item}`);
      }
    }
  };

  const handleErrorImg = ({ cover_image, cover_image_s3 }) => evt => {
    evt.target.src = evt.target.src === cover_image ? cover_image_s3 : MEDIA_PLACEHOLDER;
  };

  if (!packages.length) {
    return (
      <S.TableBody>
        <S.TableRow>
          <S.TableCell colSpan="6">No packages found</S.TableCell>
        </S.TableRow>
      </S.TableBody>
    );
  }

  return (
    <S.TableBody>
      {packages.map(item => {
        const currency = get(item.price, 'currency', 'usd');
        const symbol = TEXT_CURRENCIES.includes(currency)
          ? `${get(CURRENCIES, `${currency}.symbol`)} `
          : get(CURRENCIES, `${currency}.symbol`);

        return (
          <S.TableRow
            key={item.id}
            onClick={handleClickName}
            data-item={item.hash_id || item.id}
            data-status={item.status}
            data-sold={item.total_sold}
            className="body-item"
          >
            <S.PackageNameCell isPublished={item.status === PACKAGE_STATUS.PUBLISH}>
              <S.TableCellContent>
                <S.StatusCircle
                  isPublished={item.status === PACKAGE_STATUS.PUBLISH}
                  data-for={`${item._id}`}
                  data-tip={item.status === PACKAGE_STATUS.PUBLISH ? 'Live' : 'Draft'}
                />
                <ReactTooltip className="app-tooltip" id={`${item._id}`} effect="solid" place={'top'} />
                <S.PackageBackgroundContainer>
                  <img
                    src={item.cover_image || MEDIA_PLACEHOLDER}
                    loading="lazy"
                    alt={item.name}
                    onError={handleErrorImg(item)}
                  />
                </S.PackageBackgroundContainer>
                <S.PackageName>{item.name}</S.PackageName>
              </S.TableCellContent>
            </S.PackageNameCell>
            <S.TableCell className="pricing">
              <S.PricingColumnCell>
                <S.PriceValue>{formatMoney(round(get(item.price, 'amount'), 2), symbol) || '--'}</S.PriceValue>
                <S.Over>
                  {get(item.price, 'type') === PACKAGE_PRICING_TYPES.recurring.value ? (
                    <>
                      Every {pluralize(get(item.price, 'recurring_interval_type'), get(item.price, 'every'), true)}
                      {!!get(item.price, 'end_after') && (
                        <span>&nbsp;for {pluralize('time', get(item.price, 'end_after'), true)}</span>
                      )}
                    </>
                  ) : (
                    !!item.price && 'One time'
                  )}
                </S.Over>
              </S.PricingColumnCell>
            </S.TableCell>
            <S.TableCell className="total-sold">
              <S.TableCellContent>{item.total_sold || '--'}</S.TableCellContent>
            </S.TableCell>
            <S.TableCell className="active-user">
              <S.TableCellContent>{item.active_users || '--'}</S.TableCellContent>
            </S.TableCell>
            <S.TableCell className="revenues">
              <S.TableCellContent>
                <S.PriceValue>{!item.revenue ? '--' : formatMoney(round(item.revenue / 100, 2), symbol)}</S.PriceValue>
              </S.TableCellContent>
            </S.TableCell>
            <S.TableCell className="actions">
              <S.TableCellContent>
                <DropDown
                  triggerIcon={renderTrigger}
                  extraHeight={75}
                  overlapByOnlyIntercom={!!(!hasOverlapByOnboarding && hasOverlapByIntercom)}
                  hasLongOption={!isMP && hasMP}
                >
                  <Option key="active" onClick={handleEdit(item)} data-item={item.id}>
                    <EditIcon />
                    <span>Edit</span>
                  </Option>
                  <Option key="duplicate" onClick={handleDuplicate(item)} data-item={item.id}>
                    <DuplicateIcon />
                    <span>Duplicate</span>
                  </Option>
                  <Option
                    key="archive"
                    onClick={item.status !== 'archive' ? handleSelectedPackage(item) : handleUnArchive(item)}
                    data-item={item.id}
                  >
                    <ArchiveIcon />
                    <span>{item.status !== 'archive' ? 'Archive' : 'Unarchive'}</span>
                  </Option>
                  {item.total_sold < 1 && item.status === PACKAGE_STATUS.ARCHIVE && (
                    <Option key="duplicate" onClick={handleRemove(item)} data-item={item.id}>
                      <TrashIcon />
                      <span>Remove</span>
                    </Option>
                  )}
                  {!isMP && hasMP ? (
                    <S.OptionWrapper>
                      <div className="line" />
                      <Option key="duplicate-to-mp" onClick={handleDuplicate(item, true)} data-item={item.id}>
                        <MarketplaceIcon />
                        <span>Duplicate to Marketplace</span>
                      </Option>
                    </S.OptionWrapper>
                  ) : null}
                </DropDown>
              </S.TableCellContent>
            </S.TableCell>
          </S.TableRow>
        );
      })}
    </S.TableBody>
  );
};

const mapState = state => {
  const {
    // cloudfrontList,
    rootReducer: { packageList, permission },
  } = state;
  return {
    // cloudfrontList,
    list: packageList.get('list'),
    permission,
  };
};

const actionCreators = {
  archivePackage,
  removePackage,
  enableEditMode,
  toggleConfirmModal,
  toggleModal,
  push,
};

export default connect(mapState, actionCreators)(TableContent);
