import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as S from './style';
import { PanelHeader, PanelContent, PanelFooter, ActionButton } from '../style';
import PaymentInformation from './PaymentInformation';
import { BILLING_CYCLE, CARD_BRAND, PLANS } from 'constants/commonData';
import { DEFAULT_INFORMATION } from 'components/StripeBillingInformation';
import { togglePaymentInformation } from 'redux/billing/actions';

function BillingDetails(props) {
  const { paymentInfo, pricingPlan, showPaymentForm, billPaying } = props;
  const { payment_method_id, balance = 0 } = paymentInfo;
  const billingCycle =
    pricingPlan.tier === PLANS.starter.key
      ? null
      : BILLING_CYCLE[pricingPlan.billing_cycle] || BILLING_CYCLE[1];
  const cardBrand = CARD_BRAND[paymentInfo.brand] || {};

  const onAddCreditCard = () => {
    props.togglePaymentInformation(true);
  };

  const billingDetails = _.pick(paymentInfo, Object.keys(DEFAULT_INFORMATION));

  return (
    <S.BillingDetailsWrapper>
      <PanelHeader>
        <div>Billing Details</div>
        <S.Credit name="credit-main">
          <span>Credit:&nbsp;</span>
          <b name="credit-remain-summary">{(Number(balance) > 0 ? balance : 0).toUSDFormat()}</b>
        </S.Credit>
      </PanelHeader>
      <PanelContent>
        <S.BillingPeriod undefined={!billingCycle}>
          <div className="title">Billing Period</div>
          <div className="billingPeriod__name">{billingCycle && billingCycle.name}</div>
        </S.BillingPeriod>
      </PanelContent>
      <PanelFooter>
        <S.CardInfo logo={cardBrand.logo}>
          {!payment_method_id ? (
            <ActionButton data-btn="add-credit-card" onClick={onAddCreditCard}>
              Add Credit Card
            </ActionButton>
          ) : (
            <S.Card>
              <div>
                <span className="card-name">{cardBrand.name}</span> ending in{' '}
                <span className="sBold">{paymentInfo.last4}</span>
              </div>
              <div className="expires">
                Expires: {`0${paymentInfo.exp_month}`.slice(-2)}/{paymentInfo.exp_year}
              </div>
            </S.Card>
          )}
        </S.CardInfo>
        {payment_method_id ? (
          <ActionButton onClick={onAddCreditCard}>Update Billing Details</ActionButton>
        ) : null}
      </PanelFooter>
      {showPaymentForm ? (
        <PaymentInformation
          billingDetails={billingDetails}
          originalPaymentMethod={paymentInfo.payment_method_id || ''}
          billPaying={billPaying}
        />
      ) : null}
    </S.BillingDetailsWrapper>
  );
}

const mapState = (state) => {
  const {
    rootReducer: {
      billing: { paymentInfo, pricingPlan, showPaymentForm, billPaying },
    },
  } = state;

  return { paymentInfo, pricingPlan, showPaymentForm, billPaying };
};

const mapDispatch = (dispatch) => ({
  togglePaymentInformation: bindActionCreators(togglePaymentInformation, dispatch),
});

export default connect(mapState, mapDispatch)(BillingDetails);
