import styled, { css, keyframes } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const slide = keyframes`
  0%{
    right: -100%;
  }

  100% {
    right: 0;
  }
`;

export const TriggerIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: url(${CDN_URL}/images/onboarding.svg) no-repeat;
  background-position: center;
  background-size: auto;
  margin-right: 10px;
  background-color: #fff;
`;

export const TriggerTitle = styled.div`
  font-weight: bold;
`;

export const TriggerSubTitle = styled.div`
  font-weight: normal;
`;

export const TriggerContent = styled.div`
  min-width: 160px;
`;

export const Trigger = styled.div`
  padding: 7px 9px;
  border-radius: 220px;
  background: url(${CDN_URL}/images/onboarding_trigger_bg.svg) no-repeat;
  background-size: 100%;
  background-position: top left;
  background-color: #7672e8;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  right: 110px;
  bottom: 25px;
  color: #fff;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  box-shadow: 0px 8px 8px rgba(116, 112, 239, 0.2);
  z-index: 999;
  ${props =>
    props.hasCopyWeekOrWorkout &&
    css`
      bottom: 88px;
    `}
`;

export const ProgressPercent = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  width: 40px;
  text-align: left;
`;

export const ProgressBar = styled.div`
  background: linear-gradient(270deg, #55eda5 0%, #00a3ff 100%);
  border-radius: 20px;
  height: 9px;
`;

export const ProgressBarContainer = styled.div`
  padding: 3px;
  border-radius: 20px;
  flex: 1 1;
  overflow: hidden;
  background-color: #fff;
`;

export const Progress = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  color: #ffffff;
  font-style: normal;
  margin-bottom: 24px;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin: 0 25px 5px 0;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  h3 {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 0;
  }

  a {
    color: inherit !important;
    text-decoration: underline;
  }
`;

export const StepStatus = styled.div`
  background-color: #eceeef;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #333333;
  margin-right: 10px;
`;

export const StepName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  flex: 1 1;
`;

export const StepCollapseIcon = styled.img.attrs({
  src: `${CDN_URL}/images/arrow_down_black.svg`,
})`
  cursor: pointer;
  transform: rotate(-90deg);
  margin-left: 20px;
`;

export const StepHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  text-align: left;
  cursor: pointer;
`;

export const StepContent = styled.div`
  padding: 0 15px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  .text {
    margin-bottom: 20px;
  }

  .action {
    text-align: right;

    button {
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #7470ef;
      border: none;
      outline: none !important;
      cursor: pointer;
      background-color: transparent;
      padding: 0;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;

      img {
        margin-left: 10px;
        width: 12px;
      }
    }
  }
`;

export const StepContentWrapper = styled.div`
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
`;

export const Step = styled.div`
  border: 1px solid #ececec;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: #ffffff;
  color: #000000;

  ${props =>
    props.open &&
    css`
      ${StepContentWrapper} {
        max-height: 300px;
        transition: max-height 0.2s ease-in;
      }

      ${StepCollapseIcon} {
        transform: rotate(0);
      }
    `}

  ${props =>
    props.completed &&
    css`
      color: #b3b3b3;

      ${StepStatus} {
        background: url(${CDN_URL}/images/check_white_bold.svg) no-repeat;
        background-position: center;
        background-size: auto;
        background-color: #7470ef;
      }
    `}
`;

export const Steps = styled.div`
  flex: 1 1;
  overflow: auto;

  ${Step} {
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const MainContent = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const DismissText = styled.a`
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #7470ef !important;
  cursor: pointer;
`;

export const DismissPopup = styled.div`
  position: absolute;
  padding: 20px 37px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #323c47;
  bottom: 57px;
  left: 50%;
  transform: translateX(-50%);
  width: 295px;
  background-color: #ffffff;

  ::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -4px;
    transform: translateX(-50%) rotate(45deg);
    border: 1px solid #e7e7e7;
    border-left: none;
    border-top: none;
    z-index: 2;
    width: 7px;
    height: 7px;
    background-color: #fff;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 4px 30px;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      min-width: unset;

      &.cancel {
        background: #ffffff;
        border: 1px solid #d6dae4;
        color: #818da1;
      }

      &.confirm {
        color: #fff;
        background-color: #5c5bbd;
        border: 1px solid #5c5bbd;
      }
    }
  }
`;

export const Footer = styled.div`
  padding: 30px 0;
  text-align: center;
`;

export const Container = styled.div`
  background: url(${CDN_URL}/images/onboarding_list_bg.svg) no-repeat;
  background-position: top left;
  background-size: 100% auto;
  background-color: #fafbfc;
  padding: 25px;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.05);

  .close {
    position: absolute;
    top: 28px;
    right: 28px;
    cursor: pointer;
  }
`;

export const CheckListWrapper = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 2147483100;
  width: 360px;
  max-width: 100%;
  right: -400px;
  transition: right 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  ${props =>
    props.showMobileUI &&
    css`
      width: 100%;
      right: calc(-40px - 100%);
      height: 100%;
    `}

  &.visible {
    right: 0;

    &.with-user-guide {
      z-index: 2147483105;

      ${Container} {
        box-shadow: none;
        border: none;
      }
    }
  }
`;
