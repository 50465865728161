import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListStudioCollection, resetListData } from 'redux/studio-collection/actions';
import { toggleSideBar } from 'actions/sidebar';
import * as S from './style';
import StudioCollectionHeading from './components/Heading';
import CollectionList from './components/CollectionList';

const StudioCollectionList = function (props) {
  useEffect(() => {
    props.getListStudioCollection(...Array(1), true);
    props.toggleSideBar(true);
  }, []);

  return (
    <S.Wrapper>
      <StudioCollectionHeading header />
      <CollectionList />
    </S.Wrapper>
  );
};

const mapDispatch = dispatch => ({
  getListStudioCollection: bindActionCreators(getListStudioCollection, dispatch),
  resetListData: bindActionCreators(resetListData, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
});

export default connect(null, mapDispatch)(StudioCollectionList);
