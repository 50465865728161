/**
 * @flow
 */

import React from 'react';
import { Image, Button } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { DateTime } from 'luxon';
import { toggleModal } from 'actions/modal';
import ClientProfileModal from 'components/ClientProfileModal';
import FindTimeZone from 'components/FindTimeZone';
import UpgradePath from 'shared/UpgradePath';
import ReactTooltip from 'react-tooltip';
import { encryptData } from 'utils/commonFunction';

import ActivePrograms from './ActivePrograms';
import AssignedAutoflows from './AssignedAutoflows';
import { SharedTooltip } from 'shared/SharedTooltip';

import { CDN_URL } from 'constants/commonData';

import { ReactComponent as MailIcon } from 'assets/icons/email.svg';
import { ReactComponent as TimezoneIcon } from 'assets/icons/home.svg';
import { ReactComponent as OwnerIcon } from 'assets/icons/owner.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down_new.svg';
import { ReactComponent as ConsultationMessageIcon } from 'assets/icons/consultation-message.svg';
import ConsultationButton from './ConsultationButton';
import PaymentOverview from '../PaymentOverview';
import GroupOverview from './GroupOverview';

import * as S from './styles';
import './styles.scss';
export default class ClientProfileOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hoverTimezone: false, isViewMore: false, openViewMore: false };

    this.wrapperRef = React.createRef();
    this.resizeObserver = new ResizeObserver(this.handleResizeCallBack);
  }

  handleResizeCallBack = entries => {
    const { target } = entries[0];
    const children = target.children;
    let counter = 0;
    let hideSection = false;
    for (let item of children) {
      const classList = get(item, 'classList', {});
      if (classList.contains('profile-part')) {
        const { openViewMore } = this.state;
        counter++;

        if (openViewMore) {
          item.classList.remove('hide-section');
          item.classList.remove('one-line');
          item.classList.remove('two-line');
          item.classList.remove('three-line');
        } else {
          if (counter >= 3) {
            item.classList.add('hide-section');
            if (counter === 3) {
              item.classList.remove('hide-section');
              if (item.offsetHeight > 54) {
                this.setState({ isViewMore: true });
                hideSection = true;
                item.classList.add('three-line');
              }
            }
            if (counter === 4 && !hideSection) {
              item.classList.remove('hide-section');
              if (item.offsetHeight > 36) {
                this.setState({ isViewMore: true });
              }
              if (item.offsetHeight > 18) {
                hideSection = true;
                item.classList.add('two-line');
              }
            }
            if (counter === 5 && !hideSection) {
              item.classList.remove('hide-section');
              if (item.offsetHeight > 18) {
                item.classList.remove('two-line');
                item.classList.add('one-line');
                this.setState({ isViewMore: true });
              } else {
                this.setState({
                  isViewMore: item.offsetHeight > 0 && item.classList.contains('one-line'),
                });
              }
            }
          }

          const hasHideSection = document.querySelector('.profile-part.hide-section');
          if (counter > 5 || hasHideSection) {
            this.setState({ isViewMore: true });
          }
        }
      }
    }
  };

  starClient(isStar) {
    this.props.starClient({ user: this.props.selectedClient._id }, isStar);
  }

  componentDidMount() {
    if (this.wrapperRef.current) {
      this.resizeObserver.observe(this.wrapperRef.current);
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  handleClickEditTimezone = () => this.props.dispatch(toggleModal(true, <FindTimeZone date="" />));

  handleViewMore = () => {
    const profilePartNumber = document.getElementsByClassName('profile-part');
    this.setState(
      {
        openViewMore: !this.state.openViewMore,
      },
      () => {
        let hideSection = false;
        Array.from(profilePartNumber).forEach((element, index) => {
          if (this.state.openViewMore) {
            element.classList.remove('hide-section');
            element.classList.remove('one-line');
            element.classList.remove('two-line');
            element.classList.remove('three-line');
          } else {
            if (index >= 2) {
              element.classList.add('hide-section');
              if (index === 2) {
                element.classList.remove('hide-section');
                if (element.offsetHeight > 54) {
                  hideSection = true;
                  element.classList.add('three-line');
                }
              }
              if (index === 3 && !hideSection) {
                element.classList.remove('hide-section');
                if (element.offsetHeight > 18) {
                  hideSection = true;
                  element.classList.add('two-line');
                }
              }
              if (index === 4 && !hideSection) {
                element.classList.remove('hide-section');
                element.offsetHeight > 18 && element.classList.add('one-line');
              }
            }
          }
        });
      },
    );
  };

  render() {
    const { isViewMore, openViewMore } = this.state;
    const {
      selectedClient,
      user,
      uploadConsultationFile,
      deleteConsultationFile,
      toggleConfirmModal,
      dispatch,
    } = this.props;

    if (!selectedClient) {
      return null;
    }

    const isStar = selectedClient.favorited;
    let userTimeZone = DateTime.local().setZone(selectedClient.timezone);
    let isInboxFeaturing = selectedClient.feature_preferences.find(item => item.type === 'inbox').state;
    let trainer;

    if (selectedClient.trainers && selectedClient.trainers.length > 0 && user.team_member.length > 1) {
      trainer = user.team_member.find(item => item._id === selectedClient.trainers[0].trainer);
    }

    let phoneNumber;

    if (selectedClient.phone) {
      const parsePhone = parsePhoneNumberFromString(selectedClient.phone);

      if (parsePhone) {
        phoneNumber = parsePhone.formatInternational();
      } else {
        phoneNumber = selectedClient.phone;
      }
    }

    return (
      <div className="eve-panel client-profile-panel">
        <div className="panel-header">
          <div className="panel-title" onClick={this.handleClickProfile.bind(this)}>
            Profile
          </div>
          <Image
            src={isStar ? `${CDN_URL}/images/star.svg` : `${CDN_URL}/images/unstar.svg`}
            style={{ cursor: 'pointer' }}
            width={20}
            onClick={() => {
              this.starClient(!isStar);
            }}
            className="star-icon"
          />
          <Button className="open-button" onClick={this.handleClickProfile.bind(this)}>
            Open
          </Button>
        </div>
        <S.PanelBody className="panel-body" ref={this.wrapperRef}>
          <div className="info-container message">
            <div className="info-container">
              {selectedClient.client_connection === 1 && isInboxFeaturing ? (
                <>
                  <ConsultationMessageIcon
                    style={{ marginRight: '10px' }}
                    onClick={e => {
                      dispatch(
                        push(
                          `/home/inbox?profileId=${selectedClient._id}&ufn=${encryptData(
                            selectedClient.first_name,
                          )}&uln=${encryptData(selectedClient.last_name)}`,
                        ),
                      );
                    }}
                    data-tip
                    data-for="inbox-tooltip"
                  />
                  <SharedTooltip content="Inbox" position="top" id="inbox-tooltip" />
                </>
              ) : null}
              <ConsultationButton
                client={selectedClient}
                consultationDocument={get(this.props, 'consultationDoc[0]', null)}
                uploadConstultationFile={uploadConsultationFile}
                deleteConsultationFile={deleteConsultationFile}
                toggleConfirmModal={toggleConfirmModal}
              />
            </div>
          </div>
          {selectedClient.email ? (
            <S.ProfilePart className="profile-part">
              <S.IconContainer>
                <MailIcon />
              </S.IconContainer>
              <S.ContentContainer>
                <a href={`mailto:${selectedClient.email}`} className="no-style">
                  {selectedClient.email}
                </a>
              </S.ContentContainer>
            </S.ProfilePart>
          ) : null}
          {phoneNumber ? (
            <S.ProfilePart className="profile-part">
              <S.IconContainer>
                <PhoneIcon />
              </S.IconContainer>
              <S.ContentContainer>
                <a href={`callto:${phoneNumber}`} className="no-style">
                  {phoneNumber}
                </a>
              </S.ContentContainer>
            </S.ProfilePart>
          ) : null}
          <S.TimezoneInfo className="profile-part">
            <S.IconContainer>
              <TimezoneIcon />
            </S.IconContainer>
            <S.ContentContainer>
              <ReactTooltip id="user-timezone" className="app-tooltip" effect="solid" />
              <span data-for="user-timezone" data-tip={`GMT${userTimeZone.toFormat('ZZ')}`}>
                {userTimeZone.toFormat('t')} - {selectedClient.timezone}
              </span>
              <span className="edit-icon" onClick={this.handleClickEditTimezone} />
            </S.ContentContainer>
            {this.renderEditTimezone()}
          </S.TimezoneInfo>
          {trainer && (
            <S.ProfilePart className="profile-part">
              <S.IconContainer>
                <OwnerIcon />
              </S.IconContainer>
              <S.ContentContainer>
                {'Owner: '}
                {`${trainer.first_name} ${trainer.last_name}`}
              </S.ContentContainer>
            </S.ProfilePart>
          )}
          <GroupOverview />
          <UpgradePath pathName="payment" fallback={<></>}>
            <PaymentOverview />
          </UpgradePath>
          <UpgradePath pathName="program" fallback={<></>}>
            <ActivePrograms />
          </UpgradePath>
          <UpgradePath pathName="autoflow" fallback={<></>}>
            <AssignedAutoflows autoflows={selectedClient.active_autoflows} />
          </UpgradePath>
          {isViewMore && (
            <S.ViewMoreAction onClick={this.handleViewMore} openViewMore={openViewMore}>
              <div className="action-text">{openViewMore ? 'Show Less' : 'View More'}</div>
              <ArrowDownIcon className="arrow-icon" />
            </S.ViewMoreAction>
          )}
        </S.PanelBody>
      </div>
    );
  }

  handleClickProfile() {
    const { selectedClient, dispatch } = this.props;
    let originClient = cloneDeep(selectedClient);
    let workingClient = cloneDeep(selectedClient);
    dispatch(toggleModal(true, <ClientProfileModal originClient={originClient} workingClient={workingClient} />));
  }

  renderEditTimezone() {
    const { hoverTimezone } = this.state;
    const { dispatch } = this.props;

    if (hoverTimezone) {
      return <span className="edit-icon" onClick={() => dispatch(toggleModal(true, <FindTimeZone date="" />))} />;
    }
    return null;
  }

  handleHover(mouseIn) {
    this.setState({ hoverTimezone: mouseIn });
  }
}
