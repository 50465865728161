import React from 'react';
import classnames from 'classnames';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { Header, HeaderLeftContent, DateTabs } from './style';
import { pluralize } from 'utils/commonFunction';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export default ({ totalDays, totalWeeks, dates, onClearDate, focusedInput }) => (
  <Header className="saveExactDateCalendarAsProgram__header">
    <HeaderLeftContent>
      <div className="title">Choose range</div>
      <div className="subTitle">
        {totalDays
          ? `${pluralize('day', totalDays, true)} - ${pluralize('week', totalWeeks, true)}`
          : 'Select your start date'}
      </div>
    </HeaderLeftContent>
    <DateTabs>
      <div className={classnames('item', { active: focusedInput === START_DATE })} data-date-type={START_DATE}>
        <div>
          <div className="label">Start Date</div>
          <div className="placeholder">{dates.startDate ? dates.startDate.format('MMM D') : 'Choose the date'}</div>
        </div>
        {!!dates.startDate && <CloseIcon onClick={onClearDate} data-for-date={START_DATE} />}
      </div>
      <div className={classnames('item', { active: focusedInput === END_DATE })} data-date-type={END_DATE}>
        <div>
          <div className="label">End Date</div>
          <div className="placeholder">{dates.endDate ? dates.endDate.format('MMM D') : 'Choose the date'}</div>
        </div>
        {!!dates.endDate && <CloseIcon onClick={onClearDate} data-for-date={END_DATE} />}
      </div>
    </DateTabs>
  </Header>
);
