import get from 'lodash/get';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import classnames from 'classnames';
import { Icon, Image } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import { Button } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import ConfirmModal from 'shared/ConfirmModal';
import AddParticipantsModal from './AddParticipantsModal';
import { ReactComponent as ParticipantIcon } from 'assets/icons/participant-icon.svg';
import { ReactComponent as NoResultsIcon } from 'assets/icons/participants-search-no-results.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';

import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import './styles.scss';

const ParticipantsList = ({
  cloudfrontList,
  members = [],
  searchMembers = [],
  totalParticipants,
  user,
  selectedRoom,
  viewTeammate,
  toggleModal,
  toggleConfirmModal,
  removeGroupMember,
  leaveGroup,
  push,
  checkLastTrainer,
  getGroupMembers,
  searchGroupMembers,
  isGroupChatPermission,
}) => {
  const observer = useRef();
  const [isCollapse, setIsCollapse] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isOpenPopup, setOpenPopup] = useState(null);
  const [page, setPage] = useState(1);

  const handleOpenPopup = id => {
    setOpenPopup(isOpenPopup === id ? null : id);
  };

  const groupMembers = searchValue ? searchMembers : members;
  const isMe = useMemo(
    () =>
      groupMembers.find(item => {
        if (viewTeammate ? item._id === viewTeammate : item._id === user._id) {
          if (searchValue) {
            return (
              get(item, 'first_name', '').toLowerCase().includes(searchValue.toLowerCase()) ||
              get(item, 'last_name', '').toLowerCase().includes(searchValue.toLowerCase()) ||
              get(item, 'full_name', '').toLowerCase().includes(searchValue.toLowerCase())
            );
          }
          return true;
        }

        return false;
      }),
    [searchValue, groupMembers],
  );
  const otherMembers = groupMembers.filter(item => (viewTeammate ? item._id !== viewTeammate : item._id !== user._id));
  const membersList = isViewMore ? otherMembers : otherMembers.slice(0, 4);

  const handleViewMore = () => !viewTeammate && isGroupChatPermission && setIsViewMore(true);

  const handleToggleSection = () => {
    setIsCollapse(!isCollapse);
    setIsViewMore(false);
    if (isCollapse) {
      handleClearSearch();
    }
  };

  const handleReset = () => {
    setPage(1);
    setIsEnd(false);
  };

  const handleSearch = (event, { value }) => {
    const trimmedValue = value.trim();
    setSearchValue(trimmedValue);
    if (trimmedValue && searchGroupMembers) {
      setLoading(true);
      searchGroupMembers({ roomId: selectedRoom.id, page: 1, search: trimmedValue }, () => {
        setLoading(false);
        handleReset();
      });
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    handleReset();
    searchGroupMembers && searchGroupMembers({ roomId: selectedRoom.id, page: 1, search: '' });
  };

  const handleRemoveGroupMember = memberId => {
    removeGroupMember && removeGroupMember(selectedRoom.id, memberId);
  };

  const handleLeaveGroup = () => {
    leaveGroup &&
      leaveGroup(selectedRoom.id, roomId => {
        push && push(`/home/inbox/${roomId}`);
      });
  };

  const handleConfirmRemoveParticipant = memberId =>
    !viewTeammate &&
    isGroupChatPermission &&
    toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Remove participant"
        content="This participant will be removed from your group. Would you like to continue?"
        onConfirm={() => handleRemoveGroupMember(memberId)}
        confirmButtonTitle="Remove"
        hasHoverState
        src={`${CDN_URL}/images/trash-circle.svg`}
        className="group-chat-confirm-popup"
      />,
    );

  const handleConfirmLeaveGroup = () => {
    !viewTeammate &&
      isGroupChatPermission &&
      checkLastTrainer &&
      checkLastTrainer(selectedRoom.id).then(res => {
        const isLastTrainer = get(res, 'data.data.is_last_trainer', false);
        let content = 'Do you want to leave this group?';
        let confirmButtonTitle = 'Leave';

        if (isLastTrainer) {
          content =
            'You are the last trainer in the group. The group will be removed if you leave it. Would you like to continue?';
          confirmButtonTitle = 'Leave & Remove Group';
        }

        toggleConfirmModal(
          true,
          <ConfirmModal
            noBorder
            title="Leave group"
            content={content}
            onConfirm={handleLeaveGroup}
            confirmButtonTitle={confirmButtonTitle}
            hasHoverState
            headerIcon={`${CDN_URL}/images/red-warning-circle.svg`}
            className={classnames('group-chat-confirm-popup', { 'long-btn': isLastTrainer })}
          />,
        );
      });
  };

  const handleOpenAddParticipantsModal = () =>
    !viewTeammate &&
    isGroupChatPermission &&
    toggleModal(true, <AddParticipantsModal onClose={() => toggleModal(false)} resetStatus={handleReset} />);

  const lastParticipantElRef = useCallback(
    node => {
      if (loading || isEnd) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          // Last element is in view
          setLoading(true);
          setPage(page + 1);
          if (searchValue) {
            searchGroupMembers &&
              searchGroupMembers({ roomId: selectedRoom.id, page: page + 1, search: searchValue }, status => {
                setLoading(false);
                setIsEnd(status);
              });
          } else {
            getGroupMembers &&
              getGroupMembers({ roomId: selectedRoom.id, page: page + 1 }, status => {
                setLoading(false);
                setIsEnd(status);
              });
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, isEnd],
  );

  const convertS3Url = url => convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);

  return (
    <S.SectionWrapper
      data-name="participants-wrapper"
      isViewMore={isViewMore}
      isCollapse={isCollapse}
      hasScroll={isViewMore && groupMembers.length > 10}
    >
      <S.SectionTitleWrapper data-name="title-wrapper" isCollapse={isCollapse} onClick={handleToggleSection}>
        <S.SectionTitle data-name="title">Participants ({totalParticipants})</S.SectionTitle>
        <ArrowIcon data-name="arrow" />
      </S.SectionTitleWrapper>
      {isViewMore && !isCollapse && (
        <NewSearchInput
          placeholder="Search by name"
          className="search-participants"
          onChange={debounce(handleSearch, 300)}
          onClearSearch={handleClearSearch}
        />
      )}
      {!isCollapse ? (
        <S.ListWrapper data-name="list-wrapper" empty={!membersList.length && !isMe}>
          {!searchValue ? (
            <S.ParticipantItem data-name="invite-more-item" disabled={viewTeammate || !isGroupChatPermission}>
              <S.ItemWrapper data-name="item-wrapper">
                <S.ActionImage>
                  <ParticipantIcon />
                </S.ActionImage>
                <Button textOnly className="btn" onClick={handleOpenAddParticipantsModal}>
                  Invite more
                </Button>
              </S.ItemWrapper>
            </S.ParticipantItem>
          ) : null}
          {isMe ? (
            <S.ParticipantItem
              data-name="is-you-item"
              isMe
              searching={searchValue}
              disabled={viewTeammate || !isGroupChatPermission}
            >
              <S.ItemWrapper data-name="item-wrapper-name">
                <Avatar name={getUserShortName(isMe)} size="30" src={convertS3Url(isMe.avatar)} color={isMe.color} />
                <p>
                  {isMe.first_name} {isMe.last_name}
                </p>
              </S.ItemWrapper>
              <S.ItemWrapper
                isMe
                data-name="item-wrapper-role"
                disabled={viewTeammate || !isGroupChatPermission}
                onClick={() => handleOpenPopup(isMe._id)}
              >
                {isOpenPopup === isMe._id && (
                  <RootCloseWrapper event="click" onRootClose={() => setOpenPopup(null)}>
                    <div className={`menu-item-dropdown-content ${isOpenPopup === isMe._id ? 'open' : ''}`}>
                      <ul className="list-dropdown">
                        <li className="list-dropdown-item" onClick={handleConfirmLeaveGroup}>
                          <Image src={`${CDN_URL}/images/leave.svg`} />
                          <span>Leave group</span>
                        </li>
                      </ul>
                    </div>
                  </RootCloseWrapper>
                )}
                <Icon name={'ellipsis horizontal'} color={'grey'} />
                {!viewTeammate ? <S.ItemLabel>You</S.ItemLabel> : null}
              </S.ItemWrapper>
            </S.ParticipantItem>
          ) : null}
          {membersList.length
            ? membersList.map((item, index) => (
                <S.ParticipantItem
                  key={item._id}
                  data-name={`participant-item-${index}`}
                  ref={membersList.length === index + 1 && isViewMore ? lastParticipantElRef : undefined}
                  searching={searchValue}
                  disabled={viewTeammate || !isGroupChatPermission}
                >
                  <S.ItemWrapper data-name="item-wrapper-name">
                    <Avatar
                      name={getUserShortName(item)}
                      size="30"
                      src={convertS3Url(item.avatar)}
                      color={item.color}
                    />
                    <p>
                      {item.first_name} {item.last_name}
                    </p>
                  </S.ItemWrapper>
                  <S.ItemWrapper
                    data-name="item-wrapper-action"
                    disabled={viewTeammate || !isGroupChatPermission}
                    onClick={() => handleOpenPopup(item._id)}
                  >
                    {isOpenPopup === item._id && (
                      <RootCloseWrapper event="click" onRootClose={() => setOpenPopup(null)}>
                        <div className={`menu-item-dropdown-content ${isOpenPopup === item._id ? 'open' : ''}`}>
                          <ul className="list-dropdown">
                            <li className="list-dropdown-item" onClick={() => handleConfirmRemoveParticipant(item._id)}>
                              <Image src={`${CDN_URL}/images/delete.svg`} />
                              <span>Remove</span>
                            </li>
                          </ul>
                        </div>
                      </RootCloseWrapper>
                    )}
                    <Icon name={'ellipsis horizontal'} color={'grey'} />
                  </S.ItemWrapper>
                </S.ParticipantItem>
              ))
            : null}
          {!!searchValue && !loading && !membersList.length && !isMe && (
            <S.NoResultsWrapper data-name="no-results-wrapper">
              <NoResultsIcon />
            </S.NoResultsWrapper>
          )}
        </S.ListWrapper>
      ) : null}
      {otherMembers.length > 4 && !isCollapse && !isViewMore && (
        <S.ActionWrapper data-name="view-more-btn" disabled={viewTeammate || !isGroupChatPermission}>
          <Button textOnly className="btn" onClick={handleViewMore}>
            View more
          </Button>
        </S.ActionWrapper>
      )}
    </S.SectionWrapper>
  );
};

export default ParticipantsList;
