import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeOrderExercise } from 'redux/workout-builder/actions';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_grey.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down_grey.svg';

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 3px;
  position: absolute;
  top: 0;
  right: 20px;
  transform: translateY(-50%);
  display: flex;

  .order-item {
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;

    :first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      :not(:last-child) {
        border-right: 1px solid #dadfea;
      }
    }

    :last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    :hover {
      box-shadow: 0px 0px 0px 1px #5158cf;

      path {
        fill: #5158cf;
      }

      :first-child:not(:last-child) {
        border-color: transparent;
      }
    }
  }
`;

function ChangeOrderButton(props) {
  const { sectionId, exerciseId, exerciseIndex, exerciseSize } = props;

  const onMoveUp = () => {
    props.changeOrderExercise({
      sectionId,
      exerciseId,
      fromIndex: exerciseIndex,
      toIndex: exerciseIndex - 1,
    });
  };

  const onMoveDown = () => {
    props.changeOrderExercise({
      sectionId,
      exerciseId,
      fromIndex: exerciseIndex,
      toIndex: exerciseIndex + 1,
    });
  };

  return (
    <Wrapper className={props.className}>
      {exerciseIndex !== 0 && (
        <div className="order-item" onClick={onMoveUp}>
          <ArrowUpIcon />
        </div>
      )}
      {exerciseSize > exerciseIndex + 1 && (
        <div className="order-item" onClick={onMoveDown}>
          <ArrowDownIcon />
        </div>
      )}
    </Wrapper>
  );
}

const mapDispatch = dispatch => ({
  changeOrderExercise: bindActionCreators(changeOrderExercise, dispatch),
});

export default connect(null, mapDispatch)(ChangeOrderButton);
