import styled, { css } from 'styled-components';
import UploadImageIcon from 'assets/icons/workout-collection-upload-banner-icon.svg';
import { Modal as ModalEl } from 'semantic-ui-react';
import { SelectFile } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

export const QuestionTypeWrapper = styled.div`
  position: relative;
  .type {
    margin-bottom: 20px;
  }
  .change-question-menu {
    width: 100%;
    top: 68px;
    left: 1px;
    ${'' /* > div {
      font-size: 14px;
    } */}
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: #000000;
`;

export const QuestionTypeDropdownTrigger = styled.div`
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 1px solid ${props => (props.isOpen ? '#5e59ff' : '#DADFEA')};
  border-radius: 4px;
  position: relative;
  .value-container {
    user-select: none;
    height: 100%;
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #202353;
    align-items: center;
    padding: 0px 10px;
    svg {
      margin-right: 8px;
    }
    .dropdown-indicator {
    }
  }
  img.dropdown-icon {
    position: absolute;
    right: 12px;
    top: 13px;
    ${props =>
      props.isOpen &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

export const SettingContainer = styled.div`
  margin: 12px 0px;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
`;

export const RequiredWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form-builder-required-toggle {
    transform: translateY(-2px);
  }
  .form-builder-required-toggle label {
    ::before {
      background-color: #e0e1e4;
    }
  }
  .form-builder-required-toggle.checked label {
    ::before {
      background-color: #36ba7b !important;
    }
  }
`;

export const OtherOptionWrapper = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form-builder-required-toggle {
    transform: translateY(-2px);
    input {
      ${({ disabled }) => disabled && `cursor: not-allowed`}
    }
  }
  .form-builder-required-toggle label {
    ::before {
      background-color: #e0e1e4;
    }
  }
  .form-builder-required-toggle.checked label {
    ::before {
      background-color: #36ba7b !important;
    }
  }
`;
export const SettingsTypeWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #202353;
  }

  input {
    &:checked ~ label::before,
    &checked:focus ~ label::before {
      background-color: #36ba7b !important;
    }
  }

  label:before {
    background-color: #e0e1e4 !important;
  }
`;

export const DropdownInputWrapper = styled.div`
  background: #fff;
  border: 1px solid #dadfea;
  border-radius: 5px;
  display: flex;
  padding: 6px 8px 6px 12px;
  gap: ${props => (props.isColorPicker ? '9px' : '8px')};
  height: 32px;
  align-items: center;

  .custom-dropdown {
    .common-select-container__control {
      min-height: 20px;
      border: none;
      background: #fff;
      padding: 0;
    }

    .common-select-container__value-container.common-select-container__value-container--has-value {
      padding: 0;

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #202353;
      }
    }

    .common-select-container__menu {
      width: 57px;
      left: -12px;
      top: 27px;
    }
  }

  .custom-input {
    margin: 0;
    position: relative;

    input {
      border: none;
      padding: 0;
      padding-right: 35px;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #202353;
      :disabled {
        background: #ffffff;
      }
    }
  }
  .color-picker-trigger {
    transform: translate(-1px, 2px);
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 20px;
  background: #dee4ec;
`;

export const AddOnLabel = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: rgba(32, 35, 83, 0.6);
  position: absolute;
  right: 28px;
`;

export const Trigger = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 20px;
  height: 20px;
  background-color: ${props => props.buttonBackground};
  flex: 0 0 20px;
`;

export const SettingImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  .add-btn {
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    color: #3c39a3;
    cursor: pointer;
    user-select: none;
    :hover {
      text-decoration: underline;
    }
  }
  .change-remove-container {
    .change-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 120%;
      color: #4a4d56;
      margin-right: 10px;
      cursor: pointer;
      user-select: none;
      :hover {
        text-decoration: underline;
      }
    }
    .remove-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 120%;
      color: #ea314a;
      cursor: pointer;
      user-select: none;
      :hover {
        text-decoration: underline;
      }
    }
  }
  &.setting-image-text {
    padding: 0;
  }
`;

export const Modal = styled(ModalEl)`
  &.form-builder-image-library {
    width: 542px !important;
    height: 385px;
    .close-button svg {
      width: 20px;
      height: 20px;
    }
    .header {
      padding: 14px 16px !important;
      border-bottom: 1px solid #f0f0fc !important;
      border-top-left-radius: 8px;
      > div {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #262627;
      }
    }
    .tabs-container {
      border-bottom: 1px solid #f0f0fc;
      padding: 14px 16px;
      .tab {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #262627;
        width: fit-content;
        position: relative;
        ::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background-color: #7773ff;
          bottom: -15px;
          left: 0px;
        }
      }
    }
    .content {
      padding: 16px !important;
      .images-library {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;
        height: 241px;
        .image-container {
          cursor: pointer;
          position: relative;
        }
      }
    }
    .actions.library-action {
      padding: 0px 16px 16px 13px;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      background: #ffffff;
      border-top: unset;
      display: flex;
      .save-btn {
        width: 110px;
        height: 32px;
      }
      .left-info {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #868c9a;
        .info-text {
          text-align: left;
          margin-left: 4px;
          width: 278px;
        }
      }
    }
  }
`;

export const Image = styled.div`
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-image: url(${props => props.background});
  ${props => props.selected && `box-shadow: 0 0 0 2px #5e59ff;`}
  :hover {
    box-shadow: 0 0 0 2px #5e59ff;
  }
`;

export const UploadIcon = styled.div`
  width: 33px;
  height: 35px;
  background-image: url(${UploadImageIcon});
  background-repeat: no-repeat;
  z-index: 1;
  margin: auto;
  margin-bottom: 4px;
`;

export const UploadContainer = styled.div`
  ${'' /* position: relative; */}
  width: 100%;
  height: 100%;
  ${'' /* background: #fafbfc;
  border-radius: 4px;
  padding-top: 77px;
  border: 1px solid #dadfea; */}
  .upload-content {
    width: 93px;
    height: 48px;
    margin: auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #6a778a;
    span {
      color: #5158cf;
    }
  }
  :hover {
    span {
      text-decoration: underline;
    }
  }
`;

export const BannerWrapper = styled.div`
  border-radius: 4px;
  position: relative;
  background-color: #fafbfc;
  border-radius: 4px;
  padding-top: 77px;
  border: 1px solid #dadfea;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  ${'' /* ${props => (props.src ? 'border: none;' : 'border: 1px dashed #dadfea;')} */}
  ${props =>
    props.isDragging &&
    css`
      border: 1px dashed #5158cf;
    `}

  .select-file__container {
    width: 100%;
    height: 100%;
    .select-file__trigger {
      width: inherit;
    }
  }
`;

export const SelectBanner = styled(SelectFile)`
  ${'' /* position: absolute; */}
  ${'' /* z-index: 2; */}
  ${'' /* height: 100%; */}
`;

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background: transparent url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 2;
`;

export const SettingLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  padding-bottom: 5px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-left: 4px;
  svg {
    width: 12px;
    height: 12px;
    path {
      fill: #a6acc6;
    }
    :hover {
      path {
        fill: #5158cf;
      }
    }
  }
  .tooltip-image-text {
    width: 270px;
    height: 70px;
    padding: 15px !important;
    :after {
      bottom: -7px !important;
    }
  }
`;

export const TooltipContent = styled.div`
  color: #fff;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

export const SyncOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ActionsWrapper = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form-builder-required-toggle {
    transform: translateY(-2px);
  }
  .form-builder-required-toggle label {
    ::before {
      background-color: #e0e1e4;
    }
  }
  .form-builder-required-toggle.checked label {
    ::before {
      background-color: #36ba7b !important;
    }
  }
  .app-tooltip.option-sync-tooltip {
    padding: 15px;
    background-color: #151619;
    border-radius: 5px;
    text-align: left;
    &.metric {
      left: calc(100% - 348px) !important;
    }
    &.date {
      left: calc(100% - 388px) !important;
    }
    &.type-dark.place-top {
      margin-top: -4px;
      &:after {
        left: calc(100% - 12px);
        bottom: -7px;
        border-top-color: #151619;
      }
    }
    span {
      user-select: none;
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
    }
  }
`;

export const MetricOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .custom-dropdown-options {
    height: 32px;
    .rootOptions {
      .common-select-container__control {
        padding: 5px 8px 5px 12px;
        background: #fff;
        min-height: unset;
        .common-select-container__value-container--has-value {
          padding: unset;
          margin-right: 10px;
          span {
            color: #202353;
            font-family: 'Open Sans';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .common-select-container__indicators {
          height: 20px;
          .common-select-container__indicator.common-select-container__dropdown-indicator {
            padding: 5px 3px;
          }
        }
      }
      .common-select-container__menu {
        top: unset;
        margin-top: 4px;
        margin-bottom: 0;
        padding-bottom: 0;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
        background: #fff;
        .common-select-container__menu-list {
          box-shadow: none;
        }
        .common-select-container__option {
          border-radius: 5px;
          &.common-select-container__option--is-selected,
          &.common-select-container__option--is-focused {
            font-weight: 400;
            color: #5e59ff;
            background-color: #f6f5ff;
          }
        }
      }
    }
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5 !important;
        cursor: not-allowed !important;
      `}
    .is_disabled {
      opacity: 0.7;
    }
  }
`;

export const MetricOptionLabel = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.sync-dob-confirm-modal {
    width: 445px !important;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
      .confirm-header-label {
        font-family: 'Open Sans';
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0px;
        color: #000000;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
      .confirm-content-label {
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        color: #323c47;
      }
    }
    .confirm-actions {
      &.no-border {
        padding: 0 30px 28px 0 !important;
      }
      .button {
        border-radius: 5px;
        font-family: 'Open Sans';
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        padding: 4px 29px;
        font-weight: 600;
        margin-left: 5px !important;
        height: 30px;
      }
      .confirm-no-button {
        min-width: unset;
        width: 102px;
        border: 1px solid #d6dae4 !important;
        color: #818da1 !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        width: 144px;
        color: #fff;
        background-color: rgba(255, 178, 36, 1);
        :hover {
          background-color: rgba(255, 178, 36, 0.9);
        }
      }
    }
  }
`;

export const QuestionHasSyncWrapper = styled.div`
  position: relative;
  .custom-icon {
    position: absolute;
    width: 22px;
    height: 24px;
    top: -5px;
    left: 3px;
  }
`;

export const QuestionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  height: 36px;
  margin-left: 32px;
  padding: 7px 11px 7px 11px;
  border: 1px solid #dadae3;
  border-radius: 5px;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #202353;
  .question-number {
    font-weight: 600;
  }
  .custom-type-icon {
    flex-shrink: 0;
  }
  .question-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
  }
  &:hover {
    cursor: pointer;
    background: #f2f2f2;
  }
`;
