import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ExerciseForm from './component';
import { toggleSecondModal, toggleConfirmModal } from 'actions/modal';
import { addExercise, getAllCategories, updateExercise } from 'redux/exercise/exercise.actionCreators';
import { getJsonfile } from 'configs/request';
import { createNewTag } from 'redux/tags/actions';

const mapStateToProps = state => {
  const { user, rootReducer } = state;
  let isTeamAdmin = false;
  if (!user.teams || user.teams.length === 0 || user.teams[0].role === 0 || user.teams[0].role === 2) {
    isTeamAdmin = true;
  }
  return {
    user,
    isTeamAdmin,
    body_parts: rootReducer.exercise.body_parts,
    categories: rootReducer.exercise.categories,
    fields: rootReducer.exercise.fields || [],
    tags: rootReducer.exerciseTags,
    equipments: rootReducer.exercise.equipments,
    modalities: rootReducer.exercise.modalities,
    muscleGroup: rootReducer.exercise.muscleGroup,
    movementPattern: rootReducer.exercise.movementPattern,
    mostRecents: rootReducer.exercise.mostRecents,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createNewTag: bindActionCreators(createNewTag, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    addExercise: bindActionCreators(addExercise, dispatch),
    updateExercise: bindActionCreators(updateExercise, dispatch),
    getAllCategories: bindActionCreators(getAllCategories, dispatch),
    getJsonfile: url => {
      return dispatch(getJsonfile(url));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseForm);
