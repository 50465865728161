import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Checkbox } from 'shared/FormControl';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import TextEditable from 'shared/TextEditable';
import { mongoObjectId, setCaretToEnd } from 'utils/commonFunction';
import { diff } from 'deep-diff';
import get from 'lodash/get';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import usePrevious from 'hooks/usePrevious';
import { QUESTION_NAME_LIMIT_LENGTH, OPTION_NAME_LIMIT_LENGTH } from 'constants/commonData';

import * as S from './style';

const Option = ({
  option,
  optionsRef,
  idx,
  focusedOption,
  handleFocus,
  handleBlurOption,
  handleChangeOption,
  handleOptionEnterPress,
  allowEdit,
  allowRemove,
  handleRemoveOption,
}) => {
  const [alignTop, setAlignTop] = useState(false);
  useEffect(() => {
    const element = document.getElementById(`${option._id}-element`);
    if (element && element.offsetHeight) {
      setAlignTop(element.offsetHeight > 33);
    }
  }, [option.label]);
  return (
    <div
      className={classNames('multiple-option-container', { alignTop: alignTop })}
      key={option._id}
      id={`${option._id}-element`}
      data-id={`multiple-option-${idx}`}
    >
      <Checkbox size={20} className="option-radio" />
      <S.OptionContainer className="multiple-choice-option-name" isOptionFocused={focusedOption === option._id}>
        <TextEditable
          ref={el => (optionsRef.current[idx] = el)}
          className={classNames('option-title', { 'other-option': option.is_other })}
          value={option.label}
          placeholder="Option name"
          lineHeight={28}
          onFocus={handleFocus(option._id)}
          onBlur={handleBlurOption}
          onChange={handleChangeOption(option._id)}
          maxLength={OPTION_NAME_LIMIT_LENGTH}
          onKeyPress={handleOptionEnterPress}
          notRequired
          readOnly={option.is_other || !allowEdit}
          isNotBlurAfterEnter={true}
        />
        {allowEdit && allowRemove && (
          <div
            className={classNames('remove-option-container', {
              disabled: option.is_other ? false : !allowRemove,
            })}
          >
            <S.OptionRemove onClick={handleRemoveOption(option)} />
          </div>
        )}
      </S.OptionContainer>
    </div>
  );
};

export default function MultipleChoice({
  workingQuestion,
  idx,
  isSelected,
  updatePartialQuestion,
  allowEdit,
  onScrollTo,
  selectedQuestion,
  loading,
  isDragging = false,
  mouseLeaveRef,
  numberQuestion,
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [options, setOptions] = useState(get(workingQuestion, 'options', []));
  const storedTitle = get(workingQuestion, 'title', '');
  const storedDescription = get(workingQuestion, 'description', '');
  const allowRemove = options.filter(o => !o.is_other).length > 1;
  const isRequire = get(workingQuestion, 'is_require', false);
  const hasOther = options.some(item => item.is_other);
  const prevOptions = usePrevious({ options: options });
  const questionNameRef = useRef();
  const optionsRef = useRef([]);
  const descriptionRef = useRef();
  const isFocus = useRef(`name-${idx}`);
  const [focusedOption, setFocusedOption] = useState(null);

  useEffect(() => {
    if (isSelected) {
      onScrollTo();
      mouseLeaveRef.current = handleOnMouseOutParent;
    }
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && questionNameRef.current && isFocus.current === `name-${idx}`) {
      questionNameRef.current.focus();
      setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
    }
  }, []);

  useEffect(() => {
    setOptions(get(workingQuestion, 'options', []));
  }, [workingQuestion]);

  useEffect(() => {
    setTitle(storedTitle);
    setDescription(storedDescription);
  }, [storedTitle, storedDescription]);

  useEffect(() => {
    // focus on the newly created option
    if (prevOptions && prevOptions.options.length < options.length) {
      const existOther = prevOptions.options.some(item => item.is_other);

      if (hasOther && existOther) {
        focusToOption(options.length - 2);
      } else {
        focusToOption(options.length - 1);
      }
    }
  }, [options]);

  const handleAddOption = () => {
    let newOptions = [...options];

    if (hasOther) {
      newOptions.splice(newOptions.length - 1, 0, { _id: mongoObjectId(), label: '', is_other: false });
    } else {
      newOptions = [...newOptions, { _id: mongoObjectId(), label: '', is_other: false }];
    }

    setOptions(newOptions);
    if (!isDragging && updatePartialQuestion) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        options: newOptions,
      });
    }
  };

  const focusToOption = idx => {
    const optionRef = optionsRef.current[idx];
    if (optionRef) {
      optionRef.focus();
    }
  };
  const handleRemoveOption = option => () => {
    const optionId = option._id;
    if (!allowRemove && !option.is_other) return;

    const clonedOptions = filter(options, o => o._id !== optionId);

    setOptions(clonedOptions);
    !isDragging &&
      updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        options: clonedOptions,
      });
  };

  const handleChangeQuestionName = value => {
    if (!isDragging) {
      const trimmedValue = value.trim();
      if (trimmedValue.length <= QUESTION_NAME_LIMIT_LENGTH) {
        setTitle(trimmedValue);
      } else {
        // handle copy paste case out of chars limit
        if (trimmedValue.length === QUESTION_NAME_LIMIT_LENGTH + 1) {
          setTitle(title.trim().slice(0, QUESTION_NAME_LIMIT_LENGTH));
          questionNameRef.current.focus();
          setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
        } else {
          setTitle(trimmedValue.slice(0, QUESTION_NAME_LIMIT_LENGTH));
        }
      }
    }
  };

  const handleBlurQuestionName = () => {
    if (workingQuestion.title !== title && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        title,
      });
    }
  };

  const handleBlurDescription = () => {
    if (workingQuestion.description !== description && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        description,
      });
    }
  };

  const handleChangeDescription = value => {
    const trimmedValue = value.trim();
    setDescription(trimmedValue);
  };

  const handleChangeOption = optionId => value => {
    let newOptions = options.map(option => {
      if (option._id === optionId) {
        let newOption = { ...option };
        newOption.label = value;
        if (value.length <= OPTION_NAME_LIMIT_LENGTH) {
          newOption.label = value;
        } else {
          if (value.length === OPTION_NAME_LIMIT_LENGTH + 1) {
            newOption.label = option.label.slice(0, OPTION_NAME_LIMIT_LENGTH);
          } else {
            newOption.label = value.slice(0, OPTION_NAME_LIMIT_LENGTH);
          }
        }
        return newOption;
      } else {
        return option;
      }
    });

    setOptions(newOptions);
  };

  const handleBlurOption = () => {
    setFocusedOption(null);
    if (diff(options, workingQuestion.options) && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        options,
      });
    }
  };

  const handleOptionEnterPress = e => {
    if (e.key === 'Enter' && !loading) {
      handleAddOption();
    }
  };

  const handleFocus = optionId => () => {
    // selectedQuestion && selectedQuestion(workingQuestion); // TODO
    setFocusedOption(optionId);
    handleFocusText('option')();
  };

  const handleFocusText = type => () => {
    isFocus.current = type;
  };

  const handleOnMouseOutParent = () => {
    if (questionNameRef.current) {
      const inputRef = get(questionNameRef, 'current.inputRef.current');
      questionNameRef.current.onBlur();
      inputRef && inputRef.blur();
    }
    if (descriptionRef.current) {
      const inputRef = get(descriptionRef, 'current.inputRef.current');
      descriptionRef.current.onBlur();
      inputRef && inputRef.blur();
    }
    forEach(optionsRef.current, optionRef => {
      if (optionRef) {
        const inputRef = get(optionRef, 'inputRef.current');
        optionRef.onBlur();
        inputRef && inputRef.blur();
      }
    });
  };

  const handleOnClickQuestion = () => {
    allowEdit && selectedQuestion && selectedQuestion(workingQuestion);
  };

  const renderOption = (option, idx) => {
    return (
      <Option
        option={option}
        optionsRef={optionsRef}
        idx={idx}
        focusedOption={focusedOption}
        handleFocus={handleFocus}
        handleBlurOption={handleBlurOption}
        handleChangeOption={handleChangeOption}
        handleOptionEnterPress={handleOptionEnterPress}
        allowEdit={allowEdit}
        allowRemove={allowRemove}
        handleRemoveOption={handleRemoveOption}
      />
    );
  };

  return (
    <S.ContentContainer isRequire={isRequire} onClick={handleOnClickQuestion}>
      <S.IconWrapper>
        <QuestionIcon />
        <span>{numberQuestion}</span>
      </S.IconWrapper>
      <S.MultipleChoiceContainer>
        <div>
          <TextEditable
            ref={questionNameRef}
            className="title"
            value={title}
            placeholder="Name your question"
            lineHeight={28}
            onFocus={handleFocusText(`name-${idx}`)}
            onBlur={handleBlurQuestionName}
            onChange={handleChangeQuestionName}
            maxLength={QUESTION_NAME_LIMIT_LENGTH}
            notRequired
            readOnly={!allowEdit}
          />
          <TextEditable
            ref={descriptionRef}
            className="description"
            value={description}
            placeholder="Description (optional)"
            lineHeight={22}
            onFocus={handleFocusText('desc')}
            onBlur={handleBlurDescription}
            onChange={handleChangeDescription}
            notRequired
            breakLine
            readOnly={!allowEdit}
          />
          <div
            style={{
              paddingLeft: '5px',
              paddingTop: '2px',
            }}
          >
            {options.map((option, idx) => renderOption(option, idx))}
          </div>
          {allowEdit && (
            <div className="add-option-btn" onClick={handleAddOption}>
              Add option
            </div>
          )}
        </div>
      </S.MultipleChoiceContainer>
    </S.ContentContainer>
  );
}
