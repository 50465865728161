// Libs
import React from 'react';
import NumberFormat from 'react-number-format';

// Style
import * as S from './style';

const UnitInput = props => {
  const {
    label = '',
    min = 0,
    max = 9999.99,
    unit = '',
    className,
    defaultValue,
    onChange = () => {},
    onFocus = () => {},
    value,
    required = false,
    disabled = false,
    allowNegative = false,
    decimalScale = 2,
    decimalSeparator = '.',
  } = props;

  return (
    <S.UnitInputWrapper className={className} disabled={disabled}>
      {label && (
        <label>
          {label} {required && <span className="required">*</span>}
        </label>
      )}
      <S.UnitInput className="unit-input">
        <NumberFormat
          onValueChange={onChange}
          value={value}
          defaultValue={defaultValue}
          allowNegative={allowNegative}
          decimalSeparator={decimalSeparator}
          decimalScale={decimalScale}
          isAllowed={values => {
            const { floatValue = 0 } = values;
            return floatValue >= min && floatValue <= max;
          }}
          onFocus={onFocus}
        />

        {unit && <span>{unit}</span>}
      </S.UnitInput>
    </S.UnitInputWrapper>
  );
};

export default UnitInput;
