// libraries
import React, { useMemo } from 'react';
import capitalize from 'lodash/capitalize';

import { Checkbox } from 'shared/FormControl';

import * as S from './style';

function capitalizeEachWord(str = '') {
  return str.split(' ').map(capitalize).join(' ');
}

function LibraryMetricGroupItem({ item, checked, onChangeSelect }) {
  const { _id, title = '', metric_names = [] } = item || {};

  const metricNames = useMemo(() => {
    let strData = metric_names
      .map(item => capitalizeEachWord(item))
      .join('  •  ')
      .replace(/Bmi/g, 'BMI')
      .replace(/Water Intake/g, 'Water intake');
    return strData;
  }, [metric_names]);

  const handleSelect = event => {
    event.preventDefault();
    onChangeSelect(_id);
  };

  return (
    <S.MetricGroupItem checked={checked} onClick={handleSelect}>
      <div className="group-head">
        <h5>{title}</h5>
        <Checkbox checked={checked} onChange={handleSelect} size={20} className="checkbox-metric-icon" />
      </div>
      <p className="names">{metricNames}</p>
    </S.MetricGroupItem>
  );
}

export default LibraryMetricGroupItem;
