import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Modal, Input, Icon, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import { Checkbox } from 'shared/FormControl';
import ImageButton from 'shared/ImageButton';
import { createSegment, updateSegment } from 'redux/segment/actions';
import GeneralButton from 'shared/GeneralButton';
import { convertFiltersToSegment } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_PRIVATE, TEAM_SHARE_SHARED } from 'constants/commonData';

const shareOptions = [
  {
    text: 'Private to me',
    value: TEAM_SHARE_PRIVATE,
  },
  {
    text: 'Shared',
    value: TEAM_SHARE_SHARED,
  },
];

class SegmentForm extends React.Component {
  constructor(props) {
    super(props);
    const { selectedSegment, editingSegment } = props;
    this.state = {
      name: editingSegment ? editingSegment.name : selectedSegment ? selectedSegment.name : '',
      isReplace: false,
      trainer: props.user._id,
      share: TEAM_SHARE_PRIVATE,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { editingSegment } = this.props;

    if (editingSegment && (!prevProps.editingSegment || editingSegment._id !== prevProps.editingSegment._id)) {
      this.setState({ name: editingSegment.name, share: editingSegment.share || TEAM_SHARE_PRIVATE });
    }
  }

  saveSegment = () => {
    const { selectedSegment, filters, updateSegment, createSegment, onSuccess, editingSegment } = this.props;
    const { isReplace, name, trainer, share } = this.state;

    if (!name) {
      return this.setState({ error: true });
    }

    if (editingSegment) {
      const segment = _.omit(editingSegment, ['createdAt', 'updatedAt', '__v', 'trainer']);
      const data = Object.assign({}, segment, { trainer, share, name });
      return updateSegment(editingSegment._id, data).then(onSuccess);
    }

    let data = convertFiltersToSegment(filters);
    data.share = share;
    data.trainer = trainer;
    data.is_visible = true;

    if (selectedSegment && isReplace) {
      const segmentData = _.omit(selectedSegment, ['createdAt', 'updatedAt', '__v', 'trainer']);
      data = Object.assign({}, segmentData, data);
      return updateSegment(selectedSegment._id, data).then(onSuccess);
    }

    data.name = name;
    createSegment(data).then(onSuccess);
  };

  render() {
    const { name, isReplace, share, error } = this.state;
    const { openManageGroup, selectedSegment, show, user, editingSegment } = this.props;

    if (!show) {
      return null;
    }

    return (
      <React.Fragment>
        <Modal.Content>
          <div className="form-group">
            <label>Name</label>
            <Input
              value={name}
              onChange={(event, data) => this.setState({ name: data.value })}
              ref={this.inputRef}
              disabled={isReplace}
              error={error && !name}
            />
            {!!error && !name ? <div className="error">Name is required</div> : null}
          </div>
          <div className="form-group">
            <label>Owner</label>
            <div className="segment-owner-ship">
              <div className="text">
                {user.first_name} {user.last_name}
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Sharing</label>
            <Dropdown
              value={share}
              selection
              className="segment-owner-ship"
              options={shareOptions}
              onChange={(evt, data) => this.setState({ share: data.value })}
            />
          </div>
          {!editingSegment && selectedSegment && selectedSegment.trainer._id === user._id ? (
            <Checkbox
              className="replace-checkbox"
              checked={isReplace}
              title={
                <span className="title">
                  Replace the segment "<span>{selectedSegment.name}</span>"
                </span>
              }
              onChange={event => {
                const checked = event.target.checked;
                this.setState({ isReplace: checked, name: checked ? selectedSegment.name : name });
              }}
            />
          ) : null}
        </Modal.Content>
        <Modal.Actions className="manage-segment-actions">
          <ImageButton
            image={`${CDN_URL}/images/setting.svg`}
            title={'Manage segments'}
            className="bg-transparent"
            onClick={openManageGroup}
          />
          <GeneralButton onClick={this.saveSegment}>Done</GeneralButton>
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

SegmentForm.propTypes = {
  createSegment: PropTypes.func,
  updateSegment: PropTypes.func,
  openManageGroup: PropTypes.func,
  onSuccess: PropTypes.func,
  selectedSegment: PropTypes.object,
  editingSegment: PropTypes.object,
  show: PropTypes.bool,
};

const mapStateTopProps = state => {
  const { rootReducer, user } = state;
  const { segment } = rootReducer;
  const selectedSegment = _.find(segment.list, item => item._id === segment.selected);
  return {
    user: user,
    selectedSegment,
    filters: segment.filters,
  };
};

const mapDispatchToProps = dispatch => ({
  createSegment: bindActionCreators(createSegment, dispatch),
  updateSegment: bindActionCreators(updateSegment, dispatch),
});

export default connect(mapStateTopProps, mapDispatchToProps)(SegmentForm);
