import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

function ClientInAutoflowWarning(props) {
  const { client_name } = props;

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/alert_warning.svg`} />
            <span>Client already in an Autoflow</span>
          </S.Header>
          <S.Body>
            <div>
              <b>{client_name}</b> is already in another Autoflow. Client needs to be <b>marked as completed</b> or{' '}
              <b>removed</b> from their current Autoflows before adding to a new Autoflow.
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>OK</Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

export default connect(null, { toggleModal })(ClientInAutoflowWarning);
