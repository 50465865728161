import React from 'react';
import { diff } from 'deep-diff';
import _ from 'lodash';
import classnames from 'classnames';

import TextEditable from 'shared/TextEditable';
import { HIDDEN_SECTION, TEAM_SHARE_NOOWNER, WORKOUT_BUILDER_TYPES, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { workoutDiscardFilter } from 'utils/commonFunction';
import { ErrorMessage } from 'shared/FormControl';
import OwnershipPopup from 'components/OwnershipPopup';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import UpgradePath from 'shared/UpgradePath';
import { THEMES } from 'components/CustomBranding/constants';

import LinkSet, { LINK_TYPES } from 'components/WorkoutDetailModal/components/MainPanel/components/LinkSet';
import DragContainer from 'components/WorkoutDetailModal/components/MainPanel/components/DragContainer';
import AddActions from 'components/WorkoutDetailModal/components/MainPanel/components/AddActions';
import AddSectionForm from 'components/WorkoutDetailModal/components/MainPanel/components/AddSectionForm';
import WorkoutTags from 'components/WorkoutDetailModal/components/MainPanel/components/WorkoutTags';
import { OptionIcon } from 'components/WorkoutDetailModal/components/MainPanel/components/styles';
import { WorkoutContext } from 'components/WorkoutDetailModal/components/MainPanel/components/contexts';
import WorkoutBackground from 'components/WorkoutDetailModal/components/MainPanel/components/WorkoutBackground';
import Section from 'components/WorkoutDetailModal/components/MainPanel/components/Section';
import WorkoutSettingModal from 'components/WorkoutDetailModal/components/MainPanel/components/WorkoutSettingModal';

import * as S from 'components/WorkoutDetailModal/components/MainPanel/style';
import { checkDisabledFromAI } from 'components/WorkoutDetailModalAIDemo/convert-data';
import Loading from './Loading';

const SectionWrapper = props => {
  const { sectionId, nextSectionId, sectionIndex, sectionSize, isDragMode, onIsChanging } = props;

  const [, type] = sectionId.split('-');
  let linkable = false;

  if (sectionSize > 1 && sectionIndex < sectionSize - 1 && type === HIDDEN_SECTION) {
    const [, nextSectionType] = nextSectionId.split('-');
    linkable = nextSectionType === HIDDEN_SECTION;
  }

  return (
    <React.Fragment>
      <Section
        sectionId={sectionId}
        linkable={linkable}
        sectionIndex={sectionIndex}
        sectionSize={sectionSize}
        onIsChanging={onIsChanging}
      />
      {linkable && !isDragMode && (
        <LinkSet type={LINK_TYPES.LINK} sectionId={sectionId} linkIndex={sectionIndex} onIsChanging={onIsChanging} />
      )}
    </React.Fragment>
  );
};

class MainPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingSection: false,
      scrolling: false,
      workoutBackground: {
        src: '',
      },
      originalWorkout: props.workout.toJS(),
      discard: false,
    };
    this.headerRef = React.createRef();
    this.titleRef = React.createRef();
    this.actionFixedRef = React.createRef();
    this.contentRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.onScrollDebounce = _.debounce(this.handleScrollContent, 100);
  }

  componentDidMount() {
    const { workout, getMostRecentExercises } = this.props;
    if (!workout.get('title') && this.titleRef.current) {
      this.titleRef.current.focus();
    }
    getMostRecentExercises && getMostRecentExercises();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //Discard changes
    const { currentWorkoutData } = nextProps;
    const { originalWorkout, onIsChanging, isChanged } = this.props;
    const workoutsChanged = currentWorkoutData.toJS();
    const originWorkouts = originalWorkout ? originalWorkout.toJS() : { sections: [] };

    // Original workouts to compare
    let workouts = originWorkouts;
    let unsetArr = [
      'author',
      'author_name',
      'video_only',
      'updatedAt',
      'last_interacted',
      'tags',
      'label',
      'key',
      'value',
      'category.id',
      'one_rep_max',
      'is_custom',
      'id',
      'from_everfit',
      'share',
    ];
    workoutDiscardFilter(workouts, unsetArr);

    // New workouts to compare
    let newWorkout = workoutsChanged;
    workoutDiscardFilter(newWorkout, unsetArr);

    if (!_.isUndefined(workouts, newWorkout)) {
      workouts.workout_settings = newWorkout.workout_settings;
    }

    if (_.isUndefined(workouts.workout_settings)) {
      delete workouts.workout_settings;
    }

    if (_.isUndefined(newWorkout.workout_settings)) {
      delete newWorkout.workout_settings;
    }

    // only run when component is first mounted
    if (this.state.workoutBackground.src === '') {
      const workoutLevelBackground = _.get(nextProps, 'workoutData.background', false);
      // If opening an existing background, will get background from workout data. Otherwise, will get default workout background set in settings
      if (workoutLevelBackground) {
        this.setState({
          workoutBackground: {
            src: workoutLevelBackground,
          },
        });
      } else {
        this.setState({
          workoutBackground: _.get(nextProps, 'defaultWorkoutBackground', THEMES[0]), // If no default background selected, choose the purple background
        });
      }
    }
    // Check changing
    if (diff(workouts, newWorkout) && isChanged) {
      onIsChanging && onIsChanging(true);
      this.setState({ discard: true });
    } else {
      onIsChanging && onIsChanging(false);
      this.setState({ discard: false });
    }
  }

  descriptionObserverCallback = entities => {
    const element = entities[0];
    const { isIntersecting } = element;
    this.scrollCallbackDebounce(isIntersecting);
  };

  handleScrollCallback = descriptionIntersecting => {
    this.setState({ scrolling: !descriptionIntersecting });
  };

  handleToggleAction = isIntersecting => {
    if (this.actionFixedRef.current) {
      this.actionFixedRef.current.style.visibility = isIntersecting ? 'hidden' : 'visible';
    }
  };

  handleAddSection = () => {
    this.setState({ isAddingSection: true }, this.scrollToBottom);
    this.props.resetWorkoutError();
  };

  handleCancelAddSection = () => {
    this.setState({ isAddingSection: false });
  };

  handleAddSectionSuccess = () => {
    this.setState({ isAddingSection: false }, this.scrollToBottom);
  };

  handleChangeOwner = (event, data) => {
    const { workout } = this.props;
    const newWorkoutData = {
      author: workout.get('author') ? workout.get('author').toJS() : {},
      share: workout.get('share'),
    };

    newWorkoutData.author._id = data.value;

    if (data.value == 'no_owner') {
      newWorkoutData.author._id = null;
      newWorkoutData.share = TEAM_SHARE_NOOWNER;
    } else if (newWorkoutData.share === TEAM_SHARE_NOOWNER) {
      newWorkoutData.share = TEAM_SHARE_PRIVATE;
    }

    this.props.updateWorkoutPartialData(newWorkoutData);
  };

  handleChangeShare = (event, data) => {
    this.props.updateWorkoutPartialData({ share: data.value });
  };

  handleSaveToLibrary = () => {
    if (typeof this.props.saveToLibrary === 'function') {
      this.props.saveToLibrary(this.props.workout.toJS());
    }
  };

  handleSaveAsCopy = () => {
    if (typeof this.props.saveAsCopy === 'function') {
      this.props.saveAsCopy();
    }
  };

  handleDelete = () => {
    if (typeof this.props.onDelete === 'function') {
      this.props.onDelete(this.props.workout.toJS());
    }
  };

  handleLogWorkout = (isChanging, discard) => {
    const { onCheckField, logWorkout } = this.props;

    if (typeof this.props.logWorkout === 'function') {
      if (discard || isChanging) {
        onCheckField(true);
        return;
      }
      logWorkout(this.props.originalWorkout.toJS());
    }
  };

  handleExportPDF = async () => {
    const { workout, pdfType, currentUserId } = this.props;
    this.props.getPdfRequest();
    await this.props.getPdf(workout.toJS(), pdfType, currentUserId);
    this.props.getPdfFinish();
  };

  scrollToBottom = () => {
    if (this.contentRef.current) {
      setTimeout(() => {
        this.contentRef.current.scrollTo(0, this.contentRef.current.scrollHeight);
      }, 0);
    }
  };

  handleOnChangeBackground = data => {
    this.setState({ workoutBackground: { ...data } });
    this.props.updateBackground(data.src);
  };

  handleScrollContent = () => {
    if (this.contentRef.current && this.descriptionRef.current) {
      const { scrollTop } = this.contentRef.current;
      if (scrollTop > 0) {
        this.setState({
          scrolling: true,
        });
      } else {
        this.setState({
          scrolling: false,
        });
      }
    }
  };

  handleApplyTags = data => {
    const { updateTags } = this.props;
    updateTags(data.tags);
  };

  handleModalWorkoutSetting = workout => {
    this.props.toggleSecondModal(
      true,
      <WorkoutSettingModal
        isCreateNew={this.props.isCreateNew}
        assignmentId={_.get(workout.toJS(), '_id', '')}
        isHideWorkout={_.get(workout.toJS(), 'workout_settings.hide_workout', false)}
        noBorder
      />,
    );
  };

  render() {
    const {
      workout,
      draggingItemLeft,
      showError,
      workoutType,
      currentUserId,
      trackable,
      isHideLeftPanel,
      firstLinkSupersetId,
      readOnly,
      onIsChanging,
      isChanging,
      workoutTags,
      createNewTag,
      location,
      isTrainer,
      workoutModel,
    } = this.props;
    const { isAddingSection, scrolling, discard } = this.state;
    const sectionSize = workout.get('sections').size;
    const isDragMode = !!draggingItemLeft;
    const ableToAssignTags = location.pathname.startsWith('/home/workout');
    const isClientCalendar = location.pathname.startsWith('/home/client');
    const disableSaveWorkout = checkDisabledFromAI(workoutModel, workout);

    return (
      <WorkoutContext.Provider value={{ firstLinkSupersetId }}>
        <S.Wrapper className={classnames('workoutBuilder__mainPanel', { scrolling: scrolling })}>
          <Loading />
          <S.Header className="mainPanel__header">
            <TextEditable
              ref={this.titleRef}
              value={workout.get('title')}
              onChange={_.debounce(this.props.updateTitle, 100)}
              placeholder="Name your workout"
              lineHeight={27}
              invalid={!workout.get('title') && showError}
            />
            {!workout.get('title') && showError ? <ErrorMessage>Please add a workout name</ErrorMessage> : null}
            <S.ExtendActions className="workoutBuilder__extendActions">
              {ableToAssignTags && (
                <WorkoutTags
                  allowCreateNew
                  createNewTag={createNewTag}
                  tags={workoutTags}
                  currentFilters={{ tags: workout.get('tags') ? workout.get('tags').toJS() : [] }}
                  onApply={this.handleApplyTags}
                />
              )}

              {workoutType === WORKOUT_BUILDER_TYPES.TEMPLATE && (
                <OwnershipPopup
                  isEditAble={
                    workout.get('share') === TEAM_SHARE_NOOWNER || workout.getIn(['author', '_id']) === currentUserId
                  }
                  owner={workout.getIn(['author', '_id']) || currentUserId}
                  share={workout.get('share') || TEAM_SHARE_PRIVATE}
                  handleChangeOwner={this.handleChangeOwner}
                  handleChangeShare={this.handleChangeShare}
                  isTrainer={isTrainer}
                  hasYou
                />
              )}
              <Dropdown className="actions">
                {!!trackable && (
                  <Option key="log-workout" onClick={() => this.handleLogWorkout(isChanging, discard)}>
                    <OptionIcon icon="logworkout" darkMode>
                      Log workout
                    </OptionIcon>
                  </Option>
                )}
                {workout.get('_id') && workoutType === WORKOUT_BUILDER_TYPES.TEMPLATE ? (
                  <Option key="saveAsCopy" onClick={this.handleSaveAsCopy}>
                    <OptionIcon icon="floppy-disk" darkMode>
                      Save as copy
                    </OptionIcon>
                  </Option>
                ) : null}
                {workout.get('_id') && (
                  <Option onClick={this.handleExportPDF}>
                    <OptionIcon icon="print-pdf" darkMode>
                      Print workout
                    </OptionIcon>
                  </Option>
                )}
                {!readOnly && (
                  <Option
                    key="saveToLibrary"
                    onClick={() => {
                      !disableSaveWorkout && this.props.onSave();
                    }}
                    disabled={disableSaveWorkout}
                  >
                    <OptionIcon icon="floppy-disk" darkMode>
                      Save workout
                    </OptionIcon>
                  </Option>
                )}
                {workoutType !== WORKOUT_BUILDER_TYPES.TEMPLATE && workout.get('_id') ? (
                  <Option key="save" onClick={this.handleSaveToLibrary}>
                    <OptionIcon icon="floppy-disk" darkMode>
                      Save to library
                    </OptionIcon>
                  </Option>
                ) : null}
                {isClientCalendar && (
                  <Option key="workoutSetting" onClick={() => this.handleModalWorkoutSetting(workout)}>
                    <OptionIcon icon="setting-white" darkMode>
                      Workout settings
                    </OptionIcon>
                  </Option>
                )}
                {workout.get('_id') && !readOnly ? (
                  <Option key="delete" onClick={this.handleDelete}>
                    <OptionIcon icon="delete" darkMode>
                      Delete
                    </OptionIcon>
                  </Option>
                ) : null}
              </Dropdown>
            </S.ExtendActions>
          </S.Header>
          <S.Content className="mainPanel__content" ref={this.contentRef} onScroll={this.onScrollDebounce}>
            <S.DescriptionAndBackgroundContainer className="mainPanel__workoutDescription" ref={this.descriptionRef}>
              <S.DescriptionContainer>
                <label>DESCRIPTION</label>
                <TextEditable
                  value={workout.get('description')}
                  onChange={_.debounce(this.props.updateDescription, 100)}
                  placeholder="Add a description"
                  lineHeight={20}
                  breakLine={true}
                />
              </S.DescriptionContainer>
              {process.env.REACT_APP_ENABLE_HIGHER_CUSTOM_BRAND && (
                <UpgradePath pathName="premiumCustomBrand" fallback={null}>
                  <WorkoutBackground data={this.state.workoutBackground} onChange={this.handleOnChangeBackground} />
                </UpgradePath>
              )}
            </S.DescriptionAndBackgroundContainer>
            <S.SectionListContainer className="mainPanel__sections">
              {!!sectionSize &&
                workout
                  .get('sections')
                  .map((sectionId, index) => (
                    <SectionWrapper
                      key={sectionId}
                      sectionIndex={index}
                      sectionSize={sectionSize}
                      sectionId={sectionId}
                      nextSectionId={workout.get('sections').get(index + 1)}
                      isDragMode={isDragMode}
                      onIsChanging={onIsChanging}
                    />
                  ))}
              {!isAddingSection && !sectionSize && (
                <DragContainer noMargin={!sectionSize && showError} isHideLeftPanel={isHideLeftPanel} />
              )}
              {!sectionSize && showError ? (
                <ErrorMessage className="workoutError--noExercise">Please add at least 1 exercise</ErrorMessage>
              ) : null}
              {isAddingSection && (
                <AddSectionForm
                  onAddedSection={this.handleCancelAddSection}
                  onAddSectionSuccess={this.handleAddSectionSuccess}
                />
              )}
              <AddActions onShow={this.handleToggleAction} onAddSection={this.handleAddSection} />
            </S.SectionListContainer>
          </S.Content>
          <S.ActionFixedContainer ref={this.actionFixedRef}>
            <AddActions className="btn-actions" onAddSection={this.handleAddSection} />
          </S.ActionFixedContainer>
        </S.Wrapper>
      </WorkoutContext.Provider>
    );
  }
}

export default MainPanel;
