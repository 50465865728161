import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .overviewInfo__left {
    width: 34%;
  }

  .overviewInfo__right {
    width: 66%;
    padding-left: 15px;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;
