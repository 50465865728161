import React from 'react';
import styled from 'styled-components';

const Icon = styled.div`
  background: #ffffff;
  border: 1px solid #d4d7d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px #d4d7d9;
  border-radius: 5px;
  padding: 4px 7px;
  margin-left: 8px;

  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: #889299;
`;

export default function HotkeyIcon(props) {
  return <Icon>{props.key || 'J'}</Icon>;
}
