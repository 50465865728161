import React, { useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';

import * as S from './style';

const SystemFilter = props => {
  const { filterSystem, setFilterSystem } = props;
  const [open, setOpen] = useState(false);

  const title = filterSystem ? 'All' : 'No';

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowAll = () => {
    setFilterSystem(true);
    setOpen(false);
  };

  const handleShowNo = () => {
    setFilterSystem(false);
    setOpen(false);
  };

  return (
    <RootCloseWrapper onRootClose={handleClose} event="click" disabled={!open}>
      <S.Wrapper>
        <div className={`filter__dropdown ${open ? 'active' : ''}`} onClick={() => setOpen(!open)}>
          <div className="filter__dropdown__title">
            <span className="filter-name">Others</span>
            <span className="colons">:</span>
            <span className="selected">{title}</span>
          </div>
        </div>
        {open && (
          <S.MenuContainer>
            <div className={classNames('item', { active: filterSystem })} onClick={handleShowAll}>
              Show All
            </div>
            <div className={classNames('item', { active: !filterSystem })} onClick={handleShowNo}>
              Not Showing
            </div>
          </S.MenuContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

export default SystemFilter;
