import styled, { css } from 'styled-components';
import Dropdown from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

export const Video = styled.video`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: ${props => (props.isReplyComment ? '260px' : '295px')};
  max-width: ${props => (props.isReplyComment ? '260px' : '295px')};
`;

export const VideoHolder = styled.div`
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;

  ${props =>
    props.defaultThumb &&
    css`
      min-width: 100px;
    `}
`;

export const Author = styled.span`
  color: #202353;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: left;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-bottom: 5px;
`;

export const Message = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #141632;
`;

export const Content = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  white-space: pre-wrap;
  color: #141632;
  word-break: break-word;
  width: 100%;
`;

export const CommentDate = styled.span`
  cursor: pointer;
  /* display: inline-block; */
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
  color: #202353;
  opacity: 0.5;
  /* width: 100%; */

  :hover {
    text-decoration: underline;
  }
`;

export const CommentAttachItem = styled.span`
  display: inline-block;
`;

export const OtherInfo = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #979797;
  width: fit-content;
`;

export const MediaContainer = styled.div`
  max-height: 100%;
  max-width: 100%;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border-radius: 5px;

  .s3-video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: ${props => (props.isReplyComment ? '260px' : '295px')};
    max-width: ${props => (props.isReplyComment ? '260px' : '295px')};
  }

  img.thumbnail {
    max-height: ${props => (props.isReplyComment ? '260px' : '295px')};
    max-width: ${props => (props.isReplyComment ? '260px' : '295px')};
    width: 100%;
    height: 100%;
    display: block;
  }

  .media-player__trigger {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
`;

export const MediaBox = styled.div`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin: 11px 0 5px;

  ${props =>
    props.headerMedia &&
    css`
      margin: 0;
      width: fit-content;
      max-height: unset;
    `}

  .evf-file-upload {
    max-height: ${props => (props.isReplyComment ? '260px' : '295px')};
    max-width: ${props => (props.isReplyComment ? '260px' : '295px')};
  }
`;

export const Container = styled.div`
  border-radius: 0 8px 8px 8px;
  padding: 8px 15px 12px;
  position: relative;
  flex: 1;
  background-color: rgba(246, 246, 246, 1);

  .dropdown {
    position: absolute;
    top: 8px;
    right: 15px;
  }

  &.commentMedia {
    background: transparent;
    padding: 0;
    .dropdown {
      top: -2px;
    }
  }

  .evf-dropdown__trigger {
    height: 20px;
    :hover {
      background-color: #f3f2f2;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 36px;

  .comment__avatar {
    margin-right: 10px;
    .comment__avatar {
      border-radius: 50%;
      object-fit: cover;

      span {
        font-weight: 600;
      }
    }
  }
`;

export const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: center;
  padding: 5px;

  svg {
    margin-right: 10px;
  }
`;

export const ReplyWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 16px;
  bottom: -28px;
  left: 8px;
`;

export const ReplyButtonWrapper = styled.div`
  ${props =>
    props.loadingReply &&
    css`
      :hover {
        cursor: not-allowed;
      }

      ${ReplyButton} {
        pointer-events: none;
      }
    `}
`;

export const ReplyButton = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  opacity: 0.5;
  user-select: none;

  :hover {
    color: #5c5bbd;
    opacity: 1;
    cursor: pointer;
  }
`;

export const HeaderMedia = styled.div`
  background-color: red;
  min-width: 123px;
  min-width: 100%;
  padding: 10px 50px 10px 15px;
  background: #f6f6f6;
  border-radius: 0px 10px 10px 10px;
  position: relative;
  width: fit-content;
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #202353;
    margin-bottom: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .mention-name {
    font-size: 13px;
    line-height: 150%;
  }
  .dropdown {
    position: absolute;
    top: 8px !important;
    right: 15px;
  }
`;

export const DropdownWrapper = styled(Dropdown)`
  .evf-dropdown__menu {
    width: 175px;
  }

  .evf-dropdown__trigger--icon {
    cursor: pointer;
    align-items: center;
    color: rgb(222, 222, 222);
    height: 20px;
    width: 20px;
    border-radius: 3px;
    padding-top: 2px;

    .icon {
      margin: 0;
    }

    :hover {
      background-color: rgb(243, 242, 242);
      color: rgb(114, 110, 228);
    }
  }

  .evf-dropdown__trigger--icon.icon-checked {
    background-color: rgb(243, 242, 242);
    color: rgb(114, 110, 228);
  }
`;
