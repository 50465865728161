// Lib
import React from 'react';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';

// Styles
import * as S from './style';

const AddWeekButton = props => {
  const { disabled = false } = props;

  return (
    <S.AddWeekButtonWrapper>
      <S.AddWeekButton disabled={disabled}>
        <PlusIcon className="add-icon" />
        Add Week
      </S.AddWeekButton>
    </S.AddWeekButtonWrapper>
  );
};

export default AddWeekButton;
