import React from 'react';
import _ from 'lodash';
import ExerciseForm from 'components/ExerciseForm';
import { Helmet } from 'react-helmet';
import { LIMIT_SIZE_VIDEO_UPLOAD_MB, showMsgForSize } from 'constants/commonData';

export default props => {
  const { isModalOpen, originExercise, toggleModal, getExerciseLibrary, push, mode } = props;

  return (
    <>
      {!!originExercise && (
        <Helmet>
          <title>{originExercise.title} - Exercise - Everfit</title>
        </Helmet>
      )}
      <ExerciseForm
        maxSizeVideo={LIMIT_SIZE_VIDEO_UPLOAD_MB}
        msgMaxSizeImg={showMsgForSize()}
        open={isModalOpen}
        originExercise={originExercise}
        mode={mode}
        closePopup={() => {
          toggleModal(false);
          push('/home/exercise/');
        }}
        onSubmitSuccess={({ close }) => {
          getExerciseLibrary();

          if (close) {
            toggleModal(false);
            push('/home/exercise/');
          }
        }}
      />
    </>
  );
};
