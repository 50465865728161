import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import WorkoutTagIcon from 'assets/icons/workout-tag-gray.svg';
import WorkoutTagHoverIcon from 'assets/icons/workout-tag-purple.svg';

export const Trigger = styled.div`
  width: 28px;
  height: 28px;
  ${'' /* background-color: #e4e5e7; */}
  border-radius: 5px;
  background-image: url('${WorkoutTagIcon}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
  z-index: 999;

  :hover {
    ${'' /* background-color: #e7e6fe; */}
    background-image: url('${WorkoutTagHoverIcon}');
  }
`;

export const TriggerContainer = styled.div`
  ::after {
    content: '';
    width: 7px;
    height: 7px;
    background: #ffffff;
    border: 1px solid #d4d7d9;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    position: absolute;
    z-index: 1000000;
    display: none;
  }
`;

export const ClearSelection = styled.button`
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5c5bbd;
  padding: 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const PrimaryFocusItem = styled.div`
  display: inline-block;
  padding: 10px 15px;
  background: #f3f5f8;
  border-radius: 453px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #818da1;
  margin: 4px;
  cursor: pointer;

  :hover {
    background-color: #e1e6ef;
  }

  &.selected {
    background-color: #818da1;
    color: #fff;
    font-weight: 600;
  }
`;

export const SectionToggleIcon = styled.img.attrs({
  src: `${CDN_URL}/images/arrow_down_black.svg`,
})`
  transform: rotate(-90deg);
  cursor: pointer;
  transition: rotate 0.2s ease;
`;

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: #000000;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  :hover {
    font-weight: 600;
  }
`;

export const Section = styled.div`
  border-bottom: 1px solid #d4d7d9;
  padding: 15px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #111111;

  ${props =>
    props.expand &&
    css`
      ${SectionTitle} {
        font-weight: 600;
      }

      ${SectionToggleIcon} {
        transform: rotate(0);
      }
    `}

  &.from {
    .evf-checkbox {
      margin-right: 30px;
    }
  }

  .toggle-input {
    input {
      ~ label {
        ::before {
          background-color: #d4d7d9;
        }
        ::after {
          right: 16px;
          left: unset;
        }
      }

      :checked,
      :checked:focus {
        ~ label::before {
          background-color: #7072dd;
        }
        ~ label::after {
          right: 2px;
          left: unset;
        }
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding: 15px;
`;

export const Body = styled.div`
  flex: 1 1;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 15px;

  ${Section} {
    :last-child {
      border-bottom: none;
    }
  }
`;

export const Footer = styled.div`
  text-align: right;
  padding: 15px;
  button {
    width: 80px;
    height: 26px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
  }
`;

export const Popup = styled.div`
  background: #ffffff;
  border: 1px solid #d4d7d9;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible;
  display: none;
  position: fixed;
  width: ${props => `${props.menuWidth}px`};
  min-height: ${props => `${props.menuHeight}px`};
  z-index: 999999;
`;

export const Count = styled.div`
  border-radius: 3px;
  font-style: normal;
  font-size: 10px;
  line-height: 100%;
  font-weight: 700;
  color: #716ae7;
  position: absolute;
  width: 18px;
  height: 17px;
  top: -3px;
  right: -5px;
  z-index: 999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CountWithBackground = styled.div`
  border: 3px solid #ffffff;
  border-radius: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  color: #ffffff;
  position: absolute;
  ${'' /* width: 18px; */}
  ${'' /* height: 17px; */}
  top: -9px;
  right: -9px;
  z-index: 999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  div.background-container {
    background: #ffa31a;
    border-radius: 3px;
    width: 16px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin-right: 10px;
  user-select: none;

  &.bottom {
    ${TriggerContainer} {
      ::after {
        top: calc(100% + 2px);
        border-right: none;
        border-bottom: none;
      }
    }
  }

  &.top {
    ${TriggerContainer} {
      ::after {
        bottom: calc(100% + 2px);
        border-left: none;
        border-top: none;
      }
    }
  }

  .evf-checkbox {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #111111;
  }

  &.open {
    ${Trigger} {
      ${'' /* background-color: #e7e6fe; */}
      background-image: url('${WorkoutTagHoverIcon}');
    }

    ${TriggerContainer} {
      ::after {
        display: block;
      }
    }

    ${Popup} {
      display: flex;
    }

    .workout-builder__filter-icon {
      background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon_hover.svg);
      background-color: #eaebff;
      border-radius: 3px;
    }
  }
`;
