
export const Types = {
  UPDATE_PAYMENT_STATUS: 'UPDATE_PAYMENT_STATUS',
  RESET_REDUCER: 'RESET_REDUCER',
};

export const updatePaymentStatus = (data) => {
  return { type: Types.UPDATE_PAYMENT_STATUS, payload: { data } };
};

export const resetPaymentReducer = (data) => {
  return { type: Types.RESET_REDUCER, payload: { data } };
};