import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const LeaderboardModal = styled(Modal)`
  width: 430px !important;

  > .content {
    border-radius: 8px !important;
    padding: 0 !important;
  }

  .modalLayout__header {
    padding-bottom: 25px;
  }

  .modalLayout__content {
    padding: 0 30px 20px;
    overflow: visible;
  }

  .modalLayout__actions {
    padding: 11px 30px;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    button {
      margin-left: 10px;
    }
  }

  .evfSelectBoxContainer.rankingBy {
    .evfSelectBox__control {
      border-radius: 5px;

      .evfSelectBox__value-container {
        padding-left: 14px;
      }

      .evfSelectBox__single-value {
        color: #000;
        font-weight: normal;
      }
    }
  }

  .connect-forum-type {
    & > div {
      margin: 5px 0;

      label {
        font-weight: normal !important;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
`;

export const NoOptionsMessageStyle = styled.div`
  padding: 70px 44px 110px;
  text-align: center;

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    color: #202353;
    opacity: 0.9;
  }

  .description {
    margin-top: 7px;
    font-weight: normal;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    color: #202353;
    opacity: 0.6;
  }
`;
