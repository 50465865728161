import styled, { css } from 'styled-components';
import CalendarIcon from 'assets/icons/form-response-calendar-icon.svg';

export const Wrapper = styled.div`
  padding: 24px 160px;
  overflow-y: scroll;
  height: calc(100vh - ${({ isVerifyEmail }) => (isVerifyEmail ? '176px' : '132px')});

  ::-webkit-scrollbar {
    width: 8px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4 !important;
    border-radius: 25.47px !important;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  transform: translateX(-20px);
  .tab {
    user-select: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: rgba(32, 35, 83, 0.6);
    line-height: 19px;
    border-radius: 100px;
    padding: 6px 12px;

    &.active {
      color: #5158cf;
      background: rgba(81, 88, 207, 0.1);
      font-weight: 700;
    }
  }
  ${props =>
    props.isIndividual &&
    css`
      transform: translateX(-160px);
    `}
`;

export const IndividualContainer = styled.div`
  width: 100%;
  > div.header {
    margin-bottom: 26px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .client-avatar {
        flex: 0 0 36px;
        margin-right: 15px;
      }
      .client-name {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #202353;
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      align-items: center;
    }
    .answer-dates-dropdown {
      width: 100%;
      height: 40px;
      .common-select-container__control {
        background-color: #ffffff;
        border: 1px solid #dadfea;
        border-radius: 8px;
        .common-select-container__indicators svg {
          transition: transform ease 0.2s, stroke ease 0.2s;
        }
        &--menu-is-open {
          border: 1px solid #5e59ff;
          .common-select-container__indicators svg {
            transform: rotate(180deg);
            path {
              stroke: #5e59ff;
            }
          }
        }
        &--is-disabled {
          .common-select-container__indicators {
            opacity: 0;
          }
        }
        .common-select-container__value-container {
          position: relative;
          overflow: visible;
          ::before {
            position: absolute;
            left: -22px;
            top: 3px;
            content: url(${CalendarIcon});
            position: absolute;
          }
          transform: translateX(28px);
          padding: 2px 0px;

          .common-select-container__indicators {
            transform: translateX(-2px);
          }
        }
      }
      .common-select-container__menu-list {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        max-height: 128px;
        overflow-y: scroll;
        .common-select-container__option {
          text-align: center;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 120%;
          padding: 8px 12px 8px 14px;
          color: #202353;

          &--is-focused {
            background: transparent;
          }
          &--is-selected {
            color: #5e59ff;
          }

          :hover {
            color: #202353;
            background: rgba(94, 89, 255, 0.15);
          }
        }
      }
    }
  }
`;

export const FormRespondContainer = styled.div``;

export const AnswerContainer = styled.div`
  padding: 20px 50px;
  min-height: 108px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  word-break: break-word;
  border-radius: 5px;
  position: relative;
  :not(:last-child) {
    margin-bottom: 24px;
  }
  .header {
    &.empty {
      .title {
        color: rgba(32, 35, 83, 0.6);
      }
    }
    .title {
      color: #202353;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
    }
    .responses-count {
      transform: translate(-8px, 7px);
      flex: 0 0 85px;
      float: right;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      text-align: right;
      color: rgba(32, 35, 83, 0.7);
    }
  }
  &.summary {
    padding: 40px 50px;
    max-height: 500px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .text-editable__container {
    padding: 0;

    .text-editable__input {
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: rgba(32, 35, 83, 0.6);
      padding-top: 8px;
    }
  }

  .app-tooltip.responses-metric-tooltip {
    padding: 15px;
    background-color: #2d2e2d;
    border-radius: 5px;
    text-align: left;
    &.type-dark.place-top {
      margin-top: -4px;
      &:after {
        border-top-color: #2d2e2d;
      }
    }
    span {
      user-select: none;
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
    }
  }
`;

export const MetricAnalyticIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding: 4px;
  background-color: #fff;
  .metric-detail-icon {
    flex-shrink: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(94, 89, 255, 0.1);
    .metric-detail-icon path {
      fill: rgba(94, 89, 255, 1);
    }
  }
`;

export const SingleChoiceContainer = styled.div`
  margin-top: 22px;
  margin-bottom: 6px;
  .single-option-container {
    :not(:last-child) {
      margin-bottom: 7px;
    }
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #202353;
    .option-radio {
      align-items: center;
      width: 100%;
      cursor: unset;
      > div:first-child {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        border-width: 1px;
        margin-right: 12px;
        :hover {
          border: 1px solid #dadfea;
        }
        > div {
          height: 12px;
          width: 12px;
        }
      }
      &.checked {
        > div:first-child {
          :hover {
            border: 1px solid #6261ff;
          }
        }
      }
      &.alignTop {
        align-items: flex-start;
        > div:first-child {
          transform: translateY(2px);
        }
      }
    }
  }
  .other-answer {
    .text-editable__container {
      padding: 0px;
      .text-editable__input {
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #202353;
      }
    }
  }
`;

export const MultipleChoiceContainer = styled.div`
  margin-top: 22px;
  margin-bottom: 6px;
  .option-container {
    display: flex;
    align-items: center;
    height: unset !important;
  }
  span.checkmark {
    border-radius: 4px;
    background-color: #ffffff;
  }
  .other-answer {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #202353;
  }
  .multiple-option-container {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    &.alignTop {
      align-items: flex-start;
      .checkbox-icon {
        transform: translateY(2px);
      }
    }
  }
  .option-radio {
    margin-right: 11px;
    .checkbox-icon {
      cursor: default;
      border-radius: 4px;
    }
    input:checked ~ .checkbox-icon {
      background-color: #5e59ff;
      background-size: 8px 8px;
    }
  }
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #202353;
  .other-answer {
    .text-editable__container {
      padding: 0px;
      .text-editable__input {
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #202353;
      }
    }
  }
`;

export const ShortAnswerContainer = styled.div`
  margin-top: 16px;
  .content {
    .text-editable__container.text-editable__container--active {
      padding: 0px;
      .text-editable__input {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #202353;
      }
    }
  }
`;

export const LinearScaleContainer = styled.div`
  margin-top: 16px;
  .labels-container {
    user-select: none;
    .label-item {
      display: inline-block;
      -webkit-font-smoothing: auto;
      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: rgba(32, 35, 83, 0.6);
      }
    }
    .breaker {
      display: inline;
      margin: 0px 24px;
      color: rgba(32, 35, 83, 0.6);
    }
  }

  .option-container {
    margin: 8px 0px 0px;
    display: grid;
    grid-template-columns: repeat(${props => props.range || 5}, auto);
    column-gap: 4px;
  }
  .option {
    background: #fff;
    border: 1px solid #dadfea;
    border-radius: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    user-select: none;

    :last-child {
      margin: 0;
    }
    &.selected {
      color: #ffffff;
      background: #7470ef;
    }
  }
`;

export const SingleChoiceSummary = styled.div`
  overflow-y: scroll;
  margin: 0px -10px;
  padding: 0px 10px;
  margin-top: 16px;
  max-height: calc(420px - ${({ titleHeight }) => titleHeight + 16}px);
  .option {
    .info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .option-summary {
        display: flex;
        transform: translateY(3px);
        flex: 0 0 180px;
        justify-content: flex-end;
      }
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #202353;
        word-break: break-word;
        &.percentage {
          transform: translateY(-3px);
        }
      }
    }
    .no-of-resp {
      flex: 0 0 85px;
      margin-left: 8px;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      text-align: right;
      color: rgba(32, 35, 83, 0.7);
    }
    :not(:last-child) {
      margin-bottom: 30px;
    }
  }

  ::-webkit-scrollbar {
    width: 8px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4 !important;
    border-radius: 25.47px !important;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: #ebebeb;
  border-radius: 100px;
  .filled-bar {
    background: #5158cf;
    border-radius: 100px;
    height: 100%;
    width: ${props => props.progress || 0}%;
  }
`;

export const ShortAnswerSummary = styled.div`
  margin: 0px -10px;
  padding: 0px 10px;
  margin-top: 16px;
  max-height: calc(420px - ${({ titleHeight }) => titleHeight + 16}px);
  overflow-y: scroll;
  .text-editable__wrapper.answer {
    :not(:last-child) {
      border-bottom: 1px solid #eeeeee;
    }
  }
  .text-editable__container.text-editable__container--active {
    padding: 16px 0px;
    .text-editable__input {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: #202353;
    }
  }
  .loading {
    padding-top: 16px;
  }

  ::-webkit-scrollbar {
    width: 8px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4 !important;
    border-radius: 25.47px !important;
  }
`;

export const NotAnswered = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: rgba(32, 35, 83, 0.6);
`;

export const AnswerContentWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #202353;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

export const SignatureResponse = styled.div`
  border-radius: 5px;
  padding-top: 16px;
  display: flex;
  align-items: center;

  img {
    width: 335px;
    height: 160px;
    border-radius: 8px;
  }

  ${props =>
    props.summary &&
    css`
      border-radius: 2.5px;
      padding-top: 0;
      /* border: 0.5px solid #dedede; */

      &:not(:first-child) {
        padding-top: 16px;
      }

      :not(:last-child) {
        border-bottom: 1px solid #eee;
        padding-bottom: 16px;
      }

      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: #202353;
        margin-bottom: 8px;
        user-select: none;
      }
    `}
`;

export const DescriptionText = styled.p`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: rgba(32, 35, 83, 0.6);
  padding-top: 8px;
`;

export const FullNameText = styled.p`
  width: 335px;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000;
  padding-top: 16px;
  text-align: center;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;
`;

export const WrapperComment = styled.div`
  margin-right: 8px;
  height: 40px;
  padding: 0 16px;
  border: 1px solid #dadfea;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #202353;
  &:hover {
    border: 1px solid #5e59ff;
  }
  svg {
    margin-right: 12px;
  }
`;

export const DropdownOptions = styled.div`
  width: 200px;
`;

export const NotificationComment = styled.div`
  position: absolute;
  top: -4px;
  right: -3px;
  background: #ed786a;
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;

export const ButtonPrintPdf = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 54px;
  background: #fff;
  border: 1px solid #dadfea;
  border-radius: 8px;
  margin-left: 12px;
  opacity: unset;
  cursor: pointer;

  &:not(:hover) {
    svg {
      path {
        stroke: #202353;
      }
    }
  }

  &:hover {
    border: 1px solid #5158cf;
  }

  ${props =>
    props.isLoading &&
    css`
      cursor: not-allowed;
      &:hover {
        border: 1px solid #dadfea;
      }
    `}
`;

export const LoadingWrapper = styled.div`
  display: block;

  .custom-loading-print.ui.loader:before {
    border: 2px solid rgba(33, 33, 33, 0.4);
  }
  .custom-loading-print.ui.loader:after {
    border-color: #212121 transparent transparent;
    border-style: solid;
    border-width: 2px;
  }
`;

export const DateAnswer = styled.div`
  margin-top: 16px;
  font-family: 'Open Sans';
  color: #202353;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  font-style: normal;
`;

export const MetricAnswer = styled.div`
  margin-top: 16px;
  font-family: 'Open Sans';
  color: #202353;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  font-style: normal;
`;

export const DateAnswerSummary = styled.div`
  margin: 0px -10px;
  padding: 0px 10px;
  margin-top: 16px;
  max-height: calc(420px - ${({ titleHeight }) => titleHeight + 16}px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4 !important;
    border-radius: 25.47px !important;
  }
`;

export const DateAnswerItem = styled.div`
  padding: 24px 0px 16px;
  font-family: 'Open Sans';
  color: #202353;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  &:not(:last-child) {
    border-bottom: 1px solid rgb(238, 238, 238);
  }
`;
