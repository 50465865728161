// Lib
import React, { useMemo, useRef } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import get from 'lodash/get';

// constants
import { NUTRIENT_TYPE } from 'components/MealPlanDayDetail/constants';
import { listMacronutrients } from 'components/RecipeDetail/constants';

import { calculateNutritionChartValues, conditionalRoundNutrition } from 'components/RecipeDetail/helper';

// Assets
import NutritionChartIcon from 'assets/icons/MealPlans/nutrition_chart_icon.png';
import { ReactComponent as MacroIcon } from 'assets/icons/MealPlans/macro_icon.svg';

// Style
import * as S from './style';

const SIZES = {
  PADDLING_TOP: 10,
  PADDLING_LEFT: 30,
  MIN_PADDING: 8,
};

const data = [
  { label: 'protein', unit: 'g', color: '#57b5ea', type: NUTRIENT_TYPE.PROTEIN },
  { label: 'carbs', unit: 'g', color: '#65cc9c', type: NUTRIENT_TYPE.CARBS },
  { label: 'fat', unit: 'g', color: '#ffbe49', type: NUTRIENT_TYPE.FAT },
];

const MacroInfo = ({ viewMode, macroNutrients }) => {
  const isWeekMode = useMemo(() => (viewMode || {}).key === 'week', [viewMode]);

  const totalMacroRef = useRef(null);
  const macroInfoRef = useRef(null);

  const calories = macroNutrients.find(item => item.type === NUTRIENT_TYPE.CALORIES);
  const { value: caloriesValue, type: caloriesType } = calories;
  const caloriesInfo = listMacronutrients.find(item => item.type === caloriesType);
  const macroList = macroNutrients.filter(item => item.type !== NUTRIENT_TYPE.CALORIES);

  const dataConvert = useMemo(() => {
    return data.map(item => {
      const { type = '' } = item || {};
      const macro = macroList.find(macroItem => macroItem.type === type);

      const percentList = calculateNutritionChartValues(macroNutrients);
      const percent = get(percentList, `[${type}].percent`, 0);

      return { ...item, ...macro, percent };
    });
  }, [macroNutrients]);

  const onMouseEnter = () => {
    const elementTotalMacro = totalMacroRef.current;
    const elementMacroInfo = macroInfoRef.current;

    if (elementTotalMacro) {
      const totalRect = elementTotalMacro.getBoundingClientRect();
      const sidebar = document.querySelector('.sidebar-content');
      const { x, y, width, height: totalHeight } = totalRect;

      if (elementMacroInfo) {
        const macroRect = elementMacroInfo.getBoundingClientRect();
        const sideBarBound = sidebar ? sidebar.getBoundingClientRect() : {};
        const { height, width: widthMacro } = macroRect;
        let finalTop = y - height - SIZES.PADDLING_TOP;
        let finalLeft = x - widthMacro / 2;
        let isOverLeft = false;

        if (y - height < SIZES.PADDLING_TOP) {
          finalTop = y + totalHeight + 2;
        }

        const boundRight = sideBarBound.right + SIZES.PADDLING_LEFT;
        if (x < sideBarBound.right) {
          isOverLeft = true;
          finalLeft = sideBarBound.right + SIZES.MIN_PADDING;
        } else if (finalLeft < boundRight) {
          isOverLeft = true;
          finalLeft = boundRight;
        }

        const isOverRight = x + widthMacro - window.innerWidth >= 20;
        elementMacroInfo.style.position = 'fixed';
        elementMacroInfo.style.top = `${finalTop}px`;
        elementMacroInfo.style.right = isOverRight ? '8px' : 'unset';
        elementMacroInfo.style.left = isOverRight ? 'unset' : `${isOverLeft ? finalLeft : x}px`;
        elementMacroInfo.style.transform =
          isOverRight || isOverLeft ? 'unset' : `translateX(calc(-50% + ${width / 2}px))`;
      }
    }
  };

  return (
    <S.TotalMacro isWeekMode={isWeekMode} ref={totalMacroRef} onMouseEnter={onMouseEnter}>
      <MacroIcon />
      <div className="total-value">
        <span>{conditionalRoundNutrition(caloriesValue)}</span> {caloriesInfo.unit}
      </div>
      <div className="macro-info">
        <S.MacroInfo ref={macroInfoRef}>
          <div className="left-panel">
            <div className="chart-wrapper">
              <PieChart width={88} height={88}>
                <Pie
                  data={dataConvert}
                  dataKey="percent"
                  innerRadius={37}
                  outerRadius={44}
                  paddingAngle={4}
                  cornerRadius={2}
                  startAngle={90}
                  endAngle={-360}
                  stroke={undefined}
                  isAnimationActive={false}
                >
                  {dataConvert.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
              <img className="nutrition-chart-icon" src={NutritionChartIcon} alt="" />
            </div>
            <div className="calories-value">
              {conditionalRoundNutrition(caloriesValue)} <span className="calories-unit">{caloriesInfo.unit}</span>
            </div>
            <div className="calories-label">{caloriesInfo.label}</div>
          </div>
          <div className="list-macro">
            {dataConvert.map(item => {
              const { label = '', color = '', unit = '', value, percent } = item || {};
              return (
                <div className="item-macro" key={label}>
                  <div className="tick-label" style={{ backgroundColor: color }}></div>
                  <div className="label">
                    {label}{' '}
                    <span className="percent">
                      ({percent} <span className="percentage">%</span>)
                    </span>
                  </div>
                  <div className="value">
                    {conditionalRoundNutrition(value)} <span className="unit">{unit}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </S.MacroInfo>
      </div>
    </S.TotalMacro>
  );
};

export default MacroInfo;
