import React from 'react';
import { connect } from 'react-redux';
import * as S from './style';
import TableHeader from './TableHeader';
import TableContent from './TableContent';
import Indicator from './Indicator';

function Body({ loading, isMP }) {
  const hasOverlap = document.querySelector('.onboarding-guide') || document.querySelector('.intercom-launcher');

  return (
    <S.Table hasOverLap={hasOverlap}>
      <TableHeader isMP={isMP} />
      {loading ? <Indicator /> : <TableContent isMP={isMP} />}
    </S.Table>
  );
}

const mapState = state => {
  const {
    rootReducer: { packageList },
  } = state;
  const { loadingPackages } = packageList.toJS ? packageList.toJS() : {};
  return {
    loading: loadingPackages,
  };
};

export default connect(mapState, null)(Body);
