import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import classnames from 'classnames';

import * as S from './style';

const daysOfWeek = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

class MinimizeCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      week: 0,
      days: _.range(0, (props.maxWeeks > 4 ? 4 : props.maxWeeks) * 7),
      selectedDay: 0,
      workoutData: {},
    };
  }

  getNoDots = day => {
    const { workoutData } = this.props;
    const workouts = _.get(workoutData, [day.format('YYYY-MM-DD'), 'workouts'], []);
    return workouts.length > 3 ? 3 : workouts.length;
  };

  render() {
    const { currentDay, currentMonth, onNavigateBack, onNavigateForward } = this.props;
    const startDate = moment(currentDay).subtract(1, 'week').startOf('week');
    const days = _.range(0, 42).map(d => {
      return moment(startDate).add(d, 'days');
    });
    return (
      <S.Wrapper className={this.props.className}>
        <S.Heading>
          <S.WeekTitle>{currentDay.format('MMMM YYYY')}</S.WeekTitle>
          <S.Actions>
            <S.ActionButton onClick={onNavigateBack} />
            <S.ActionButton next onClick={onNavigateForward} />
          </S.Actions>
        </S.Heading>
        <S.Title>
          {daysOfWeek.map(dayWeek => (
            <S.DayWeek key={dayWeek}>{dayWeek}</S.DayWeek>
          ))}
        </S.Title>
        <S.Calendar>
          {days.map(day => (
            <S.DayCalendar
              active={currentDay.isSame(day, 'day')}
              onClick={() => this.props.onChange(day)}
              key={day}
              className={classnames({
                'other-month': day.month() !== currentMonth,
                today: day.format('L') === moment().format('L'),
              })}
            >
              {day.format('D')}
              <S.WorkoutDotContainer>
                {_.range(0, this.getNoDots(day)).map(i => (
                  <S.WorkoutDot key={i} />
                ))}
              </S.WorkoutDotContainer>
            </S.DayCalendar>
          ))}
        </S.Calendar>
      </S.Wrapper>
    );
  }
}

export default MinimizeCalendar;
