import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import _ from 'lodash';
import diff from 'deep-diff';
import { RootCloseWrapper } from 'react-overlays';
import { Image } from 'semantic-ui-react';
import { Checkbox } from 'shared/FormControl';
import GeneralButton from 'shared/GeneralButton';
import { NewSearchInput } from 'shared/SearchInput';
import { countTagSelected, getSelectedTags, selectTags } from './commonFunction';
import { CDN_URL, MAX_TAG_NAME_LENGTH, TAGS_TYPE } from 'constants/commonData';
import { ReactComponent as Plus } from 'assets/icons/new_plus_grey_sm.svg';
import { ReactComponent as Setting } from 'assets/icons/new_setting_grey.svg';
import classNames from 'classnames';

import * as S from './style';

function MenuTag({
  open,
  onClose,
  type,
  tags = [],
  exerciseTags,
  workoutTags,
  programTags,
  isModalOpen,
  disableCloseMenu,
  onClickAddNew,
  onClickManage,
  onSubmit,
  changeQueryParams,
  resetQueryParams,
  countTagsSelected = [],
  elementsSelected = [],
  onApplyTabs,
  wrapperClassName = '',
  containerClassName = '',
}) {
  const observer = useRef();
  const [selectedTags, setSelectedTags] = useState([]);
  const [countTagsSelectedOrigin, setCountTagsSelectedOrigin] = useState([]);
  const requestResetText = useRef(null);
  const [defaultSelected, defaultSelectedTags] = useState([]);
  const { loading, query } = useMemo(() => {
    switch (type) {
      case TAGS_TYPE.EXERCISE:
        return { loading: exerciseTags.loading, query: exerciseTags.query };

      case TAGS_TYPE.WORKOUT:
        return { loading: workoutTags.loading, query: workoutTags.query };

      case TAGS_TYPE.PROGRAM:
        return { loading: programTags.loading, query: programTags.query };

      default:
        return { loading: false, query: { isEnd: true } };
    }
  }, [exerciseTags, workoutTags, programTags]);

  // Check Element is have checked any tags
  const isElementHaveChecked = useMemo(() => {
    return elementsSelected ? elementsSelected.length > 0 : false;
  }, [elementsSelected, countTagsSelected]);

  const isChanged = useMemo(() => {
    if (diff(selectedTags, getSelectedTags(elementsSelected))) {
      return true;
    }

    const keys = Object.keys(countTagsSelected);
    let isDiff = false;

    keys.forEach(o => {
      if (diff(countTagsSelected[o], countTagsSelectedOrigin[o])) {
        isDiff = true;
      }
    });
    return isDiff;
  }, [selectedTags, countTagsSelectedOrigin]);

  // Default selected tags on first time
  useEffect(() => {
    if (open) {
      const defaultSelect = getSelectedTags(elementsSelected) || [];
      setSelectedTags(defaultSelect);
      setCountTagsSelectedOrigin(countTagsSelected);
      defaultSelectedTags(_.cloneDeep(defaultSelect));

      // Reset selected tags
      if (countTagsSelected.length === 0 && elementsSelected.length === 0) {
        setSelectedTags([]);
      }
    }
  }, [open]);

  useEffect(() => {
    return () => {
      resetQueryParams(type);
    };
  }, []);

  const lastExerciseElRef = useCallback(
    node => {
      if (loading || query.isEnd) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !query.isEnd) {
          // Last element is in view
          changeQueryParams({
            type,
            page: query.page + 1,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, query.isEnd],
  );

  // Check tags be selected
  const isTagHaveSelected = tags => {
    return tags ? tags.length > 0 : false;
  };

  // Handle Click Add New Popup
  const handleClickAddNew = () => {
    onClickAddNew && onClickAddNew();
  };

  const handleClickManage = () => {
    onClickManage && onClickManage();
    resetQueryParams(type);
  };

  const handleAddNew = () => {
    const data = {
      type,
      name: query.textSearch,
    };

    onSubmit &&
      onSubmit(data, () => {
        resetQueryParams(type);
      });

    requestResetText && requestResetText.current();
  };

  const handleSearch = (event, { value }) => {
    changeQueryParams({ type, page: 1, textSearch: value.trim() });
  };

  /**
   * handleSelectTag: Handle Select Tag
   * @param {*} item
   * @param {*} event
   * countObj => countObj={ total, originState, state }
   * countObj: total => element total is selected
   * item state: 0: unchecked, 1: half-checked, 2: full-checked
   */
  const handleSelectTag = (item, event) => {
    const checked = _.get(event, 'target.checked', false);
    const elementsSelectedList = getSelectedTags(elementsSelected);
    let newCount = Object.assign({}, countTagsSelectedOrigin);
    let countObj = countTagsSelectedOrigin[_.get(item, '_id', '')]
      ? Object.assign({}, countTagsSelected[_.get(item, '_id', '')])
      : null;

    // Update new select tag, new count tag after select
    const updateSelectTags = selectTags(item, elementsSelectedList, checked, countObj, selectedTags, newCount);
    const newSelectedTags = _.get(updateSelectTags, 'newSelected', []);
    const newCountTag = Object.assign([], _.get(updateSelectTags, 'newCount', []));

    if (newSelectedTags) setSelectedTags(newSelectedTags);
    if (newCountTag) setCountTagsSelectedOrigin(newCountTag);
  };

  const handleClose = () => {
    onClose && onClose();
    resetQueryParams(type);
  };

  // Handle submit add tags
  const handleApplyTabs = () => {
    const elementsSelectedByTabs = elementsSelected ? _.map(elementsSelected, item => item._id) : [];
    const tagIds = selectedTags || [];

    // Apply tabs
    onApplyTabs &&
      onApplyTabs({
        elementsSelectedByTabs: elementsSelectedByTabs,
        tagIds: tagIds,
        defaultSelected: defaultSelected,
      });

    // Clear Selected Tab
    setSelectedTags([]);
    setCountTagsSelectedOrigin([]);

    // Close menu
    handleClose();
  };

  // Get extend data of tag item: total (element be added to tags)
  const handleGetExtendDataOfTagItem = countItem => {
    const data =
      elementsSelected.length < 2 || !countItem || !countItem.state
        ? ''
        : countItem.state === 2
        ? 'all'
        : countItem.total;

    return data;
  };

  // Check Tag Item have selected
  const handleCheckTagItemSelected = (item, extendData) => {
    return selectedTags.includes(_.get(item, '_id', '')) || extendData === 'all';
  };

  const renderTagItem = (item, index) => {
    const countItem = countTagSelected(_.get(item, '_id', ''), countTagsSelectedOrigin);
    const extendData = handleGetExtendDataOfTagItem(countItem);
    const isSelected = handleCheckTagItemSelected(item, extendData);

    if (tags.length === index + 1) {
      return (
        <li key={item._id} ref={lastExerciseElRef}>
          <Checkbox
            thirdState={countItem && countItem.state === 1}
            disabled={!isElementHaveChecked}
            checked={isSelected}
            className="tag-checkbox"
            onChange={e => handleSelectTag(item, e)}
            title={
              <div className="tag-checkbox__title">
                <div>
                  <span>{item.name}</span>
                </div>
                {extendData ? <div className="exercises">{`(${extendData})`}</div> : null}
              </div>
            }
          />
        </li>
      );
    }

    return (
      <li key={item._id}>
        <Checkbox
          thirdState={countItem && countItem.state === 1}
          checked={isSelected}
          disabled={!isElementHaveChecked}
          className="tag-checkbox"
          onChange={e => handleSelectTag(item, e)}
          title={
            <div className="tag-checkbox__title">
              <div>
                <span>{item.name}</span>
              </div>
              {extendData ? <div className="exercises">{`(${extendData})`}</div> : null}
            </div>
          }
        />
      </li>
    );
  };

  const renderBody = () => {
    const hasTag = tags.some(tag => _.lowerCase(tag.name) === _.lowerCase(query.textSearch.trim()));
    return (
      <ul>
        {query.textSearch.trim() && !isModalOpen && (!tags.length || !hasTag) ? (
          <li className="create-new-tag-item">
            <div className="create-new-tag-option" onClick={handleAddNew}>
              <Image src={`${CDN_URL}/images/new_tag_purple.svg`} width={12} />
              <span>Add "{query.textSearch.trim()}" Tag</span>
            </div>
          </li>
        ) : null}
        {_.map(tags, (item, index) => renderTagItem(item, index))}
      </ul>
    );
  };

  const noTagBefore = Object.keys(countTagsSelectedOrigin).length === 0;
  return (
    <RootCloseWrapper event="click" disabled={!open || disableCloseMenu} onRootClose={handleClose}>
      <S.Wrapper
        className={classNames(wrapperClassName, {
          open,
        })}
      >
        {open ? (
          <S.MenuContainer className={containerClassName}>
            <S.MenuHeader>
              <NewSearchInput
                onChange={_.debounce(handleSearch, 300)}
                onClearSearch={() => resetQueryParams(type)}
                placeholder="Search"
                maxLength={MAX_TAG_NAME_LENGTH}
                requestResetText={requestResetText}
              />
            </S.MenuHeader>
            {tags.length ? (
              <S.TextBold>
                {query.textSearch.trim() && !isModalOpen ? `results (${tags.length})` : 'most recent'}
              </S.TextBold>
            ) : null}
            <S.MenuBody>{renderBody()}</S.MenuBody>
            <S.MenuFooter>
              <div className="footer_left">
                <GeneralButton onClick={handleClickAddNew} className="create-button" withImage>
                  <Plus />
                  <span>Create</span>
                </GeneralButton>
                <GeneralButton onClick={handleClickManage} className="manage-button" withImage>
                  <Setting />
                  <span>Manage</span>
                </GeneralButton>
              </div>
              <GeneralButton
                className="apply-button"
                disabled={(noTagBefore && (!isElementHaveChecked || !isTagHaveSelected(selectedTags))) || !isChanged}
                onClick={handleApplyTabs}
              >
                Apply
              </GeneralButton>
            </S.MenuFooter>
          </S.MenuContainer>
        ) : null}
      </S.Wrapper>
    </RootCloseWrapper>
  );
}

export default MenuTag;
