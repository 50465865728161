import React, { useState } from 'react';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import { Checkbox } from 'shared/FormControl';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const ContentPublishChangeRender = ({ toggleModal, onPublish }) => {
  const [check, setCheck] = useState(false);

  const handleCheck = event => {
    const checked = event.target.checked;
    setCheck(checked);
  };

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const handlePublish = () => {
    onPublish(check);
  };

  return (
    <S.PublishChange open={true} closeOnDimmerClick={false} onClose={handleCloseModal}>
      <Modal.Header>
        <CloseButton className="close-button" onClick={handleCloseModal}>
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      </Modal.Header>
      <Modal.Content>
        <S.Title>Publish Changes?</S.Title>
        <S.ContentPublishChange>
          <span>
            All the updates will be synced to the clients and override all the changes that clients have made including
            swapping, deleting, or adding recipes.
          </span>
          <div className="content-publish">
            <Checkbox checked={check} onChange={handleCheck} />
            <span>
              Do not override the dates that the client has modified including swapping, deleting, or adding recipes.
            </span>
          </div>
        </S.ContentPublishChange>
      </Modal.Content>
      <Modal.Actions>
        <S.BtnAction className="btn-cancel" onClick={handleCloseModal}>
          Cancel
        </S.BtnAction>
        <S.BtnAction className="btn-publish" onClick={handlePublish}>
          Publish
        </S.BtnAction>
      </Modal.Actions>
    </S.PublishChange>
  );
};

export default ContentPublishChangeRender;
