import React from 'react';
import moment from 'moment';
import get from 'lodash/get';

import * as S from '../style';

const DateTimeResponse = ({ answer, date_format }) => {
  return <S.QuestionAnswer>{moment(get(answer, 'date_answer', '')).utc().format(date_format)}</S.QuestionAnswer>;
};

export default DateTimeResponse;
