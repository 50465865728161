import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

const GoalLine = props => {
  const {
    viewSize,
    data = [],
    tooltipText,
    lastValue,
    note,
    chart: { limit, height, width, xPositions },
    isSame,
    isClose,
  } = props;

  const points = useMemo(() => {
    let pts = [];
    xPositions.forEach((it, index) => {
      let x = 0;
      x = xPositions[index] - 10;
      const yTmp = (data[index] / limit) * height;
      const y = height - (yTmp + 10) > height - 28 ? height - 28 : height - (yTmp + 10);

      pts.push(`${x},${y}`);
    });

    return pts;
  }, [viewSize, limit, height, width, data]);

  const toolTipPosition = useMemo(() => {
    let lastElement = points[points.length - 1];

    if (lastElement) {
      const [left = 0, top = 0] = lastElement.split(',');
      if (isSame) {
        return {
          left: `${Number(left) + 15}px`,
          top: `${Number(top)}px`,
          marginTop: '7px',
        };
      }

      if (isClose) {
        return {
          left: `${Number(left) + 15}px`,
          top: `${Number(top) - 15}px`,
        };
      }

      return {
        left: `${Number(left) + 15}px`,
        top: `${Number(top) - 8}px`,
      };
    }

    return {
      top: 0,
      right: 0,
    };
  }, [points]);

  return (
    <>
      <div class="slds-p-top--medium">
        <svg version="1.2" class="quiz-graph">
          <polyline fill="none" stroke="#C2C3D0" stroke-width="1" points={points.join(' ')} stroke-dasharray="5, 3" />
        </svg>
      </div>

      {lastValue > 0 && (
        <div
          className={`weekly-report__goal-tooltip weekly-report__goal-tooltip--${note}`}
          style={{ ...toolTipPosition }}
        >
          <ReactTooltip
            className="app-tooltip"
            id={`${lastValue}_training_goal`}
            effect="solid"
            place={'top'}
            delayShow={200}
          >
            <p>{tooltipText}</p>
          </ReactTooltip>
          <div className="weekly-report__goal-tooltip--value" data-tip data-for={`${lastValue}_training_goal`}>
            {data[data.length - 1]}
          </div>
        </div>
      )}
    </>
  );
};

export default GoalLine;
