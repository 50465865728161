import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Loader } from 'semantic-ui-react';

import DropdownOptions from 'shared/DropdownOptions';
import { ReactComponent as IconComment } from 'assets/icons/icon-comment.svg';
import { ReactComponent as CaretDown } from 'assets/icons/form-responses-caret-down.svg';
import { ReactComponent as IconPrint } from 'assets/icons/print_pdf.svg';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

import FormResponse from './FormResponse';
import CommentModal from './CommentModal';
import * as S from './styles';

const CustomIndicator = () => <CaretDown />;

export default function Individual({
  workingClient,
  getClientAnswerDetails,
  timezone,
  dateFormat,
  submittedId,
  toggleModal,
  match,
  push,
  getDetails,
  isAuthorizedClient,
  onPrint,
  loadingPrint,
  showError,
  closeError,
  cloudfrontList,
}) {
  const params = new URLSearchParams(get(window, 'location.search', ''));
  const hasComment = params && params.get('hasComment');
  const idSubmitted = params && params.get('submitted');
  const color = get(workingClient, 'client_color');
  const answerDates = get(workingClient, 'answerDates.data', []);
  const id = get(workingClient, 'workingAnswerDate._id');
  const workingAnswerDate = get(workingClient, 'workingAnswerDate');
  const [workingAnswerDateId, setWorkingAnswerDateId] = useState(submittedId);

  useEffect(() => {
    if (id && !isEmpty(answerDates)) {
      if (submittedId && !answerDates.map(item => item._id).includes(submittedId)) {
        setWorkingAnswerDateId(get(answerDates, '[0]._id'));
        showError(
          'Oops, this submission was removed. All related data could not be visible at the moment.',
          null,
          null,
          null,
          null,
          closeError,
        );
        return;
      }
      if (answerDates.some(item => item._id === id)) {
        setWorkingAnswerDateId(id);
      } else {
        setWorkingAnswerDateId(get(answerDates, '[0]._id'));
        showError(
          'Oops, this submission was removed. All related data could not be visible at the moment.',
          null,
          null,
          null,
          null,
          closeError,
        );
        return;
      }
    }
  }, [id]);

  useEffect(() => {
    if (hasComment && match) {
      toggleModal(true, <CommentModal match={match} id={idSubmitted} />);
      getDetails(get(match, 'params.id'));
    }
    return () => {
      toggleModal(false);
    };
  }, [hasComment]);

  const handleSelect = data => {
    getClientAnswerDetails(data.value);
  };

  const handleClientProfile = () => {
    window.open(`/home/client/${workingClient.client}`, '_blank');
  };

  const onOpenComment = () => {
    const clientId = workingClient && workingClient.client;
    push && push(`${match.url}?client=${clientId}&submitted=${id}&hasComment=true`);
  };

  const renderLoadingPrint = () => {
    return (
      <S.LoadingWrapper>
        <Loader active inline="centered" className="custom-loading-print" size="tiny" />
      </S.LoadingWrapper>
    );
  };

  const handlePrintPdf = () => {
    onPrint && onPrint();
  };

  const getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);

  return (
    <S.IndividualContainer key={workingClient._id}>
      <div className="header">
        <div className="left" onClick={handleClientProfile}>
          <Avatar
            name={workingClient.client_full_name}
            className="client-avatar"
            size="36"
            src={getCloudUrl(workingClient.client_avatar)}
            color={color}
          />
          <div className="client-name">{workingClient.client_full_name}</div>
        </div>
        {answerDates.length > 0 && workingAnswerDateId && (
          <div className="right">
            {isAuthorizedClient && (
              <S.WrapperComment onClick={onOpenComment} data-btn="comments">
                <IconComment data-notification="notification-comments" />
                Comments
                {get(workingAnswerDate, 'is_unread_comment', false) && <S.NotificationComment />}
              </S.WrapperComment>
            )}
            <S.DropdownOptions>
              <DropdownOptions
                readOnly={answerDates.length < 2}
                width="49px"
                height="20px"
                options={answerDates.map(obj => ({
                  label: moment(obj.submitted_at).tz(timezone).format(`${dateFormat} -  h:mm A`),
                  value: obj._id,
                }))}
                selectedValue={workingAnswerDateId}
                onSelect={handleSelect}
                className="answer-dates-dropdown"
                customIndicator={CustomIndicator}
                isForceUpdate
              />
            </S.DropdownOptions>
            <S.ButtonPrintPdf onClick={handlePrintPdf} disabled={loadingPrint} isLoading={loadingPrint}>
              {loadingPrint ? renderLoadingPrint() : <IconPrint />}
            </S.ButtonPrintPdf>
          </div>
        )}
      </div>
      {workingAnswerDate && (
        <FormResponse
          workingAnswerDate={workingAnswerDate}
          getCloudUrl={getCloudUrl}
          dateFormat={dateFormat}
          clientId={workingClient && workingClient.client}
          push={push}
        />
      )}
    </S.IndividualContainer>
  );
}
