import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Item = styled.div`
  font-size: 13px;
  line-height: 15px;
  color: #202353;
  font-weight: normal;
  padding: 8px 15px;

  :hover {
    font-weight: 600;
    background-color: #eaebff;
    cursor: pointer;
  }
`;

export const Trigger = styled.div`
  width: 28px;
  height: 26px;
  background-color: #fff;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid #d4d8df;
  text-align: center;
  line-height: 26px;
  background-color: #f7f8fc;
  background-image: url(${CDN_URL}/images/plus_new_grey.svg);
  background-repeat: no-repeat;
  background-position: center;

  &.disabled {
    cursor: not-allowed;
  }
`;

export const Menu = styled.div`
  position: fixed;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(107, 111, 183, 0.15);
  border-radius: 3px;
  z-index: 1000;
  width: 80px;
`;

export const Wrapper = styled.div``;
