import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import { ReactComponent as ShareIcon } from 'assets/icons/studio_share.svg';

import * as S from '../styles';

const OwnershipIconStatus = props => {
  const { share = false, itemId, place = 'top', onItemClick } = props;

  const handleItemClick = event => {
    typeof onItemClick === 'function' && onItemClick(event);
  };

  return (
    <S.IconStatusContainer
      share={share}
      className="ownership-icon-status"
      data-tip
      data-for={`ownership-icon-status__tooltip-${itemId}`}
      onClick={handleItemClick}
    >
      <ShareIcon />
      <ReactTooltip
        id={`ownership-icon-status__tooltip-${itemId}`}
        effect="solid"
        place={place}
        className={classNames('app-tooltip', { 'is-place': place })}
      >
        <span className="tooltip-content">{share ? 'Shared' : 'Private'}</span>
      </ReactTooltip>
    </S.IconStatusContainer>
  );
};

export default OwnershipIconStatus;
