import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 467px;

    > .content {
      padding: 0 !important;
      border-radius: 8px !important;
    }

    .modal__header {
      padding-bottom: 20px;
    }

    .modal__content {
      p {
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: #323c47;

        :first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
`;
