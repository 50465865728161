import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
  margin-top: 20px;

  .post {
    margin-bottom: 20px;
    width: 598px;
    border-radius: 8px;
    padding: 0px !important;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const Section = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #202353;
  opacity: 0.7;
  width: 100%;
  padding: 10px 20px;
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  padding-top: 20px;
  height: 84px;
  .ui.loader:before {
    border-color: #ededed;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
  }
`;

export const IndicatorPlaceHolder = styled.div`
  height: 84px;
  display: block;
`;
