import React, { useEffect, useMemo, useState } from 'react';
import pick from 'lodash/pick';
import { diff } from 'deep-diff';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import CountdownItem from 'components/Note/Countdown/components/CountdownItem';
// import CountdownTemplates from 'components/Note/Countdown/components/CountdownTemplates';
import CreateNewCountdown from 'components/Note/Countdown/components/CreateNewCountdown';
import CountdownDetails from 'components/Note/Countdown/components/CountdownDetails';
import { CountdownViewModes, DEFAULT_EMOJI } from 'components/Note/Countdown/constants';
import EmptyList from '../EmptyList/index.js';
import DropdownCountdownItem from '../DropdownCountdownItem/index.js';

import { getClientCountdownList } from 'redux/client-countdown/action.js';
import LoadingIndicator from 'shared/LoadingIndicator/index.js';
import useTimeRemaining from 'hooks/useTimeRemaining.js';

// assets
import { ReactComponent as BackIcon } from 'assets/icons/back_icon.svg';
// import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add_black.svg';
// import { ReactComponent as PinIcon } from 'assets/icons/pin_countdown.svg';

// styles
import { CustomConfirmModal } from '../ConfirmModal/style';
import * as S from './styles.js';
import { CDN_URL } from 'constants/commonData.js';

const FORM_DATA_ORIGIN = {
  title: '',
  is_all_day: true,
  date: null,
  time: null,
  remind_at_finish_time: true,
  remind_one_day_before: false,
  remind_one_week_before: false,
  is_pinned_on_today_screen: false,
  is_share_with_trainer: true,
  ...DEFAULT_EMOJI,
};

const PER_PAGE = 20;

const CountdownList = props => {
  const {
    countdownList = [],
    viewMode: viewModeProp,
    addNewCountdown,
    countdownDetail,
    getCountdownDetail,
    getClientCountdownList,
    loading,
    page,
    totalCountdown,
    selectedClientId,
    toggleConfirmModal,
    onChangeFormCreate,
    onChangeViewMode,
  } = props;
  const [viewMode, setViewMode] = useState(viewModeProp);
  const [countdownDetails, setCountdownDetails] = useState(countdownDetail || null);
  const [showTemplates, setShowTemplate] = useState(viewMode === CountdownViewModes.ViewCreateNew);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formDataCreate, setFormDataCreate] = useState(FORM_DATA_ORIGIN);
  const timeRemaining = useTimeRemaining(countdownDetail ? countdownDetail.date_utc : null, false);

  const pickArr = [
    'date',
    'title',
    'icon',
    'color',
    'is_all_day',
    'remind_at_finish_time',
    'remind_one_day_before',
    'remind_one_week_before',
  ];

  const isChanged = useMemo(() => {
    if (!formDataCreate.is_all_day) {
      pickArr.push('time');
    }
    const oldData = pick(FORM_DATA_ORIGIN, pickArr);
    const newData = pick(formDataCreate, pickArr);

    return diff(oldData, newData);
  }, [formDataCreate]);

  const isCreateView = viewMode === CountdownViewModes.ViewCreateNew;
  const isListView = viewMode === CountdownViewModes.ViewList;
  const isViewDetail = viewMode === CountdownViewModes.ViewDetails;
  const isDisableCreateButton = !(formDataCreate || {}).title || !(formDataCreate || {}).date;

  useEffect(() => {
    if (isCreateView) {
      onChangeFormCreate(isChanged);
    } else {
      onChangeFormCreate(false);
    }
  }, [isChanged, viewMode]);

  const handleSelectedTemplate = template => {
    setSelectedTemplate(template);
    setShowTemplate(false);
  };

  const handleGetCountdown = () => {
    if ((countdownDetails || {})._id) {
      getCountdownDetail(countdownDetails._id).then(res => {
        const { data = {} } = res.data;
        setCountdownDetails(data);
      });
    }
  };

  useEffect(() => {
    if (isViewDetail) {
      handleGetCountdown();
    }
  }, [countdownList]);

  const renderCountdownItem = item => {
    return (
      <CountdownItem
        key={item._id}
        countdown={item}
        onNavigateDetails={handleNavigateDetails}
        onChangeFormCreate={handleChangeFormCreate}
      />
    );
  };

  const resetState = () => {
    setShowTemplate(false);
    setViewMode(CountdownViewModes.ViewList);
    setCountdownDetails(null);
    onChangeViewMode(CountdownViewModes.ViewList);
  };

  const onClickBackFromDetails = () => {
    if (isCreateView) {
      if (!isChanged) {
        resetState();
        return;
      }

      toggleConfirmModal(
        true,
        <CustomConfirmModal
          onConfirm={resetState}
          onDeny={() => toggleConfirmModal(false)}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          confirmButtonTitle="Discard Changes"
          title="Discard Changes?"
          noBorder
          hasCloseIcon
          isPressEsc
          isCloseOnDimmer={false}
        />,
      );

      return;
    }

    resetState();
  };

  const onClickBackFromCreate = () => {
    setShowTemplate(true);
    setViewMode(CountdownViewModes.ViewCreateNew);
  };

  const handleNavigateDetails = data => {
    setViewMode(CountdownViewModes.ViewDetails);
    setCountdownDetails(data);
  };

  const onClickAddNew = () => {
    setShowTemplate(true);
    setViewMode(CountdownViewModes.ViewCreateNew);
  };

  const handleChangeFormCreate = formData => {
    setFormDataCreate({ ...formDataCreate, ...formData });
  };

  const handleCreateCountdown = () => {
    addNewCountdown(formDataCreate, () => {
      getClientCountdownList({ page: 1, client: selectedClientId });
      setViewMode(CountdownViewModes.ViewList);
    });

    setFormDataCreate(FORM_DATA_ORIGIN);
  };

  const getHeading = () => {
    if (isListView) {
      return (
        <>
          <h2>Countdown</h2>
          {countdownList.length > 0 && (
            <button className="add-btn" onClick={onClickAddNew}>
              <AddIcon /> Add new
            </button>
          )}
        </>
      );
    }

    return (
      <>
        <button className="back-btn" onClick={onClickBackFromDetails}>
          <BackIcon /> Back
        </button>

        {isViewDetail && (
          <div className="header__right-side">
            {/* <div>
              <PinIcon
                className={classNames('pin-icon', { pinned: (countdownDetails || {}).is_pinned_on_today_screen })}
              />
            </div> */}
            {!!timeRemaining && (
              <DropdownCountdownItem
                countdown={countdownDetails}
                className="dropdown-actions"
                isDetail={true}
                onBackToList={onClickBackFromDetails}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const getContent = () => {
    if (isListView) {
      if (countdownList.length === 0) {
        return <EmptyList onClickAddNew={onClickAddNew} />;
      }
      return (
        <div className="countdown-list-wrapper">
          {countdownList.map(renderCountdownItem)}
          {loading && <LoadingIndicator className="loading-countdowns" />}
        </div>
      );
    }

    if (isCreateView) {
      return <CreateNewCountdown onChangeFormCreate={handleChangeFormCreate} selectedTemplate={selectedTemplate} />;
    }

    if (isViewDetail) {
      return <CountdownDetails countdown={countdownDetails} />;
    }

    return <CountdownDetails />;
  };

  const handleLoadMoreCountDown = event => {
    const { clientHeight = 0, scrollHeight = 0, scrollTop = 0 } = event.target;
    if (!isListView) return;
    const bottom = scrollHeight - scrollTop <= clientHeight + 10;
    const isEnd = Math.ceil(totalCountdown / PER_PAGE) === page;
    if (bottom && !loading && !isEnd) {
      const currentPage = page + 1;
      const params = {
        page: currentPage,
        client: selectedClientId,
      };
      getClientCountdownList(params);
    }
  };

  const onScrollDebounce = debounce(handleLoadMoreCountDown, 300);

  return (
    <S.CountdownListStyled className="countdown-list" isGrayBackground={isListView}>
      <div className="countdown-heading">{getHeading()}</div>
      <div
        className="countdown-list-items"
        onScroll={event => {
          event.persist();
          onScrollDebounce.call(null, event);
        }}
      >
        {getContent()}
      </div>
      {isCreateView && (
        <div className="countdown-footer">
          <div />
          {/* <button className="archived-btn" onClick={onClickBackFromDetails}>
            <HistoryIcon /> Archived
          </button> */}

          <S.CreateButton onClick={handleCreateCountdown} purple disabled={isDisableCreateButton}>
            Create countdown
          </S.CreateButton>
        </div>
      )}
    </S.CountdownListStyled>
  );
};

const mapState = ({ rootReducer }) => {
  const { client, clientCountdown } = rootReducer;
  const { selected: selectedClientId } = client || {};
  const { loadingCountdowns = false, pageCountdowns, totalCountdown } = clientCountdown || {};
  return {
    loading: loadingCountdowns,
    page: pageCountdowns,
    totalCountdown,
    selectedClientId,
  };
};

const mapDispatch = { getClientCountdownList };

export default connect(mapState, mapDispatch)(CountdownList);
