import React from 'react';
import styled, { keyframes } from 'styled-components';

const bouncing = keyframes`
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }

  100% {
    transform: translate3d(0, -10px, 0) scale(0.9, 1.1);
  }
`;

const Container = styled.div`
  width: ${props => `${props.width || 50}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .LoaderBalls__item {
    width: ${props => `${props.size || 12}px`};
    height: ${props => `${props.size || 12}px`};
    border-radius: 50%;
    background: ${props => props.color || '#745ee6'}; ;

    :nth-child(1) {
      animation: ${bouncing} 0.4s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95);
    }

    :nth-child(2) {
      animation: ${bouncing} 0.4s 0.1s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
    }

    :nth-child(3) {
      animation: ${bouncing} 0.4s 0.2s alternate infinite cubic-bezier(0.6, 0.05, 0.15, 0.95) backwards;
    }
  }
`

export default ({width, size, color}) => (
  <Container className="LoaderBalls" width={width} size={size} color={color}>
    <div className="LoaderBalls__item" />
    <div className="LoaderBalls__item" />
    <div className="LoaderBalls__item" />
  </Container>
)