import styled, { css } from 'styled-components';
import CategorySelect from 'components/IngredientLibrary/Parts/CategorySelect';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
`;

export const Wrapper = styled.div`
  margin-top: 17px;
  .toggle-nutrition-info {
    ${baseText}
    color: #5158CF;
    height: 30px;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px 0 10px;
    cursor: pointer;
    .toggle-nutrition-info-icon {
      transform: rotate(180deg);
      margin-left: 9px;
      width: 8px;
      path {
        fill: #5158cf;
      }
    }
    &:hover {
      background: #f0f1ff;
    }
    &.is-show {
      .toggle-nutrition-info-icon {
        transform: rotate(0deg);
      }
    }
  }
  .nutrition-info {
    margin-top: 15px;
    padding-left: 10px;
  }
`;

export const NutritionInfo = styled.div`
  margin-top: 18px;
  padding-left: 10px;
  .tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    .tab-header-left {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .tab-header-item {
      ${baseText}
      font-size: 13px;
      line-height: 20px;
      color: #7b7e91;
      height: 30px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.active {
        color: #5158cf;
        border-color: #5158cf;
      }
    }
    .tab-header-right {
      display: flex;
      align-items: center;
      gap: 7px;
      &.is-view-mode {
        gap: 5px;
      }
      .text-amount-per {
        ${baseText}
        font-size: 11px;
        line-height: 17px;
        color: #7b7e91;
        margin-right: 3px;
        &.is-view-mode {
          margin-right: 0;
        }
      }
      .value-amount-per {
        ${baseText}
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }
      .input-amount-per {
        width: 60px;
      }
      .list-unit {
        width: 106px;
      }
    }
  }
  .tab-content {
    .macro-nutrient {
      padding-top: 25px;
      display: flex;
      gap: 14.5px;
      .item-macro-nutrient {
        width: 100%;
        flex: 1;
      }
    }
    .other-nutrient {
      padding-top: 25px;
      .item-other-nutrient {
        width: calc(25% - 10px);
      }
      .nutrient-list {
        display: flex;
        flex-wrap: wrap;
        gap: 14px 13px;
      }
      .toggle-other-nutrient {
        ${baseText}
        font-size: 12px;
        line-height: 18px;
        color: #7b7e91;
        height: 30px;
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        width: fit-content;
        cursor: pointer;
        svg {
          margin-bottom: 1px;
          margin-left: 9px;
          path {
            fill: #7b7e91;
          }
        }

        :hover {
          color: #5158cf;
          svg {
            path {
              fill: #5158cf;
            }
          }
        }
        &.is-hide {
          margin-bottom: 0;
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;

export const ListAmountPer = styled(CategorySelect)`
  .trigger-wrapper {
    width: 129px;
    height: 30px;
    line-height: 30px;
    padding: 0px 25px 0 9px;
    .text-placeholder {
      line-height: 30px;
    }
    svg {
      right: 14px;
    }
  }
`;

export const ListAmountPerWrapper = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      .__react_component_tooltip.place-top {
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        width: 184px;
        border-radius: 4px;
        padding: 8px 16px;
        margin-top: -13px;
        &::after {
          bottom: -9px;
        }
      }
      .__react_component_tooltip.type-dark.place-top:after {
        border-top-width: 8px;
      }
      ${ListAmountPer} {
        pointer-events: none;
      }
    `}
`;
