import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { bindActionCreators } from 'redux';
import { capitalize, replace, isEqual, find, debounce, map } from 'lodash';
import { push } from 'connected-react-router';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/lib/Async';

import { FormGroup } from 'shared/FormControl';
import { DropdownIndicator } from 'shared/Icons';
import { Button } from 'shared/FormControl';
import { getAutoflowList, searchAutoflow } from 'redux/autoflow/actions';
import { pluralize } from 'utils/commonFunction';
import { autoflowDisconnectForum, autoflowConnectForum, autoflowInvite } from 'redux/autoflow/forum/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { getConnectedLeaderboard } from 'redux/forum/actions';
import { CONNECT_FORUM_TYPES } from 'constants/commonData';
import { ReactComponent as ThreeDots } from 'assets/icons/forum-advanced-settings-three-dots.svg';
import { ReactComponent as DisconnectAutoflow } from 'assets/icons/forum-advanced-settings-disconnect-autoflow.svg';
import { ReactComponent as GoToAutoflow } from 'assets/icons/forum-advanced-settings-go-to-autoflow.svg';
import { ReactComponent as TitleIcon } from 'assets/icons/forum-advanced-settings-title-icon.svg';
import { ReactComponent as AutoflowSearch } from 'assets/icons/autoflow-search.svg';
import ConfirmModal from 'shared/ConfirmModal';

import * as S from './styles';
import InviteModal from './InviteModal';
import LeaderBoard from './LeaderBoard';
import { SharedTooltip } from 'shared/SharedTooltip';

function ConnectAutoflow({
  getAutoflowList,
  autoflowList,
  push,
  groupId,
  autoflowDisconnectForum,
  autoflowConnectForum,
  autoflowInvite,
  toggleModal,
  leaderboardTemplates,
  toggleConfirmModal,
  connectedLeaderboard,
  originalAutoflow,
  getGroupDetail,
  forumList,
  useStateCallback,
  rankingTypes,
  dispatchSearchAutoflow,
  getConnectedLeaderboard,
}) {
  const [selectedAutoflow, setSelectedAutoflow] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [connectedAutoflow, setConnectedAutoflow] = useState(null); // original connected autoflow
  const [isChanged, setIsChanged] = useStateCallback(false);
  const [isFocused, setIsFocused] = useState(false);
  const inviteMode = CONNECT_FORUM_TYPES.NONE;
  const searchAutoflow = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    dispatchSearchAutoflow({ search, page: 1, per_page: 20, sorter: 'last_used' })
      .then(response => {
        const { data } = response.data;

        // TODO: SHOULD investigate
        if (data) {
          callback(data);
        } else {
          callback([]);
        }
      })
      .catch(() => {
        callback([]);
      });
  };
  const searchAutoflowDebounce = debounce(searchAutoflow, 200);
  useEffect(() => {
    getAutoflowList();
  }, []);
  useEffect(() => {
    if (originalAutoflow) {
      setSelectedAutoflow(originalAutoflow);
      setConnectedAutoflow(originalAutoflow);
    } else {
      setConnectedAutoflow(null);
    }
  }, [originalAutoflow]);
  useEffect(() => {
    if (!isEqual(selectedAutoflow, connectedAutoflow)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [selectedAutoflow, connectedAutoflow]);
  const handleSelectAutoflow = item => {
    setSelectedAutoflow(item);
  };
  const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return (
      <S.CustomOption ref={innerRef} {...innerProps}>
        <div className="autoflow-name">{data.name}</div>
        <div className="autoflow-details">
          <span className="clients-count">{data.clients_count.total} </span>
          {pluralize('Client', data.clients_count.total)}
          <span> - {capitalize(replace(data.type, '_', ' '))}</span>
        </div>
      </S.CustomOption>
    );
  };

  const handleInviteClients = () => {
    toggleModal(
      true,
      <InviteModal
        toggleModal={toggleModal}
        selectedAutoflow={selectedAutoflow}
        autoflowInvite={autoflowInvite}
        groupId={groupId}
      />,
    );
  };

  const handleToggleFocus = () => {
    setIsFocused(!isFocused);
  };

  const handleGoToAutoflow = autoflowId => {
    window.open(`/home/autoflow/${autoflowId}/leaderboard`, '_blank');
  };

  const handleSave = async () => {
    if (!selectedAutoflow) {
      await autoflowDisconnectForum(connectedAutoflow._id);
      getAutoflowList();
      getGroupDetail(groupId);
      getConnectedLeaderboard(groupId);
      toast('Settings has been saved');
    } else if (!isEqual(selectedAutoflow, connectedAutoflow)) {
      if (selectedAutoflow.forum) {
        setSelectedAutoflow(null);
        const alreadyConnectedForum = find(forumList, el => el._id === selectedAutoflow.forum);
        toast.error(
          `This Autoflow is already connected to ${
            alreadyConnectedForum ? `the Forum '${alreadyConnectedForum.name}'` : 'another forum'
          }`,
        );
        return;
      }
      await autoflowConnectForum(selectedAutoflow._id, groupId, inviteMode);
      getAutoflowList();
      getGroupDetail(groupId);
      getConnectedLeaderboard(groupId);
      toast('Settings has been saved');
    } else {
      // autoflowInvite(selectedAutoflow._id, groupId, inviteMode);
    }
  };

  const handleDisconnectAutoflow = () => {
    setIsFocused(false);
    if (!connectedAutoflow) {
      //not connected to any autoflow yet
      setSelectedAutoflow(null);
    } else {
      toggleConfirmModal(
        true,
        <ConfirmModal
          noBorder
          title="Disconnect Autoflow from Forum?"
          content={`If the Autoflow has a Leaderboard, it will also be disconnected from the Forum. Would you like to continue?`}
          onConfirm={async () => {
            setSelectedAutoflow(null);
            setConnectedAutoflow(null);
            await autoflowDisconnectForum(connectedAutoflow._id);
            getAutoflowList();
            getGroupDetail(groupId);
            getConnectedLeaderboard(groupId);
            toast('Settings has been saved');
          }}
          confirmButtonTitle="I understand"
          hasCloseIcon
        />,
      );
    }
  };

  const handleDiscardChange = nextLocation => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          setIsChanged(false, () => push(nextLocation.pathname));
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
      />,
    );
    return false;
  };

  const SelectedAutoflow = ({ data: option }) => {
    return (
      <S.CustomValue>
        <div className="autoflow-name">{option.name}</div>
        <S.Dropdown
          className="dropdown-container"
          trigger={
            <>
              <ThreeDots
                className={`three-dots-btn ${dropdownOpen && 'active'}`}
                data-for="more-option-connect-autoflow-tooltip"
                data-tip
              />
              {!dropdownOpen && <SharedTooltip id="more-option-connect-autoflow-tooltip" />}
            </>
          }
          icon={null}
          open={dropdownOpen}
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        >
          <S.Dropdown.Menu className="dropdown-menu more-actions">
            <S.Dropdown.Item onClick={() => handleGoToAutoflow(option._id)}>
              <div className="popup-button">
                <GoToAutoflow />
                <p className="go-autoflow">Go to Autoflow</p>
              </div>
            </S.Dropdown.Item>
            <S.Breaker />
            <S.Dropdown.Item onClick={handleDisconnectAutoflow}>
              <div className="popup-button">
                <DisconnectAutoflow />
                <p className="disconnect-autoflow">Disconnect Autoflow</p>
              </div>
            </S.Dropdown.Item>
          </S.Dropdown.Menu>
        </S.Dropdown>
        <div className="autoflow-details">
          <span className="clients-count">{option.clients_count.total} </span>
          {pluralize('Client', option.clients_count.total)} -{' '}
          <span className="invite-clients-btn" onClick={handleInviteClients}>
            Invite Clients to Forum
          </span>
        </div>
      </S.CustomValue>
    );
  };
  return (
    <S.SettingContainer>
      <Prompt when={isChanged} message={handleDiscardChange} />
      <div className="title-container">
        <TitleIcon />
        {originalAutoflow ? 'Connect' : 'Connect Leaderboard'}
        {isChanged && (
          <div className="save-btn-container">
            <Button borderPurple onClick={handleSave}>
              Save
            </Button>
          </div>
        )}
      </div>
      <FormGroup className="forum-form-group no-bottom-margin">
        <label>
          <span>{!selectedAutoflow ? 'CHOOSE AUTOFLOW TO INTEGRATE' : 'AUTOFLOW CONNECTED'}</span>
        </label>
        {!selectedAutoflow ? (
          <AsyncSelect
            defaultOptions
            cacheOptions
            loadOptions={(inputValue, callback) => searchAutoflowDebounce.call(this, inputValue, callback)}
            className="choose-autoflow-select"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={
              isFocused ? (
                <S.CustomPlaceholder>
                  <AutoflowSearch />
                  <span>Search</span>
                </S.CustomPlaceholder>
              ) : (
                'Choose Autoflow'
              )
            }
            components={{ DropdownIndicator, IndicatorSeparator: null, LoadingIndicator: null, Option: CustomOption }}
            classNamePrefix="single-select"
            onChange={handleSelectAutoflow}
            value={selectedAutoflow}
          />
        ) : (
          <>
            <SelectedAutoflow data={selectedAutoflow} />
            {selectedAutoflow === connectedAutoflow && (
              <LeaderBoard
                leaderBoardList={connectedLeaderboard}
                rankingTypes={rankingTypes}
                push={push}
                autoflowId={selectedAutoflow._id}
                leaderboardTemplates={leaderboardTemplates}
              />
            )}
          </>
        )}
      </FormGroup>
    </S.SettingContainer>
  );
}

const mapStateToProps = state => {
  return {
    forumList: state.rootReducer.forum.list,
    autoflowList: state.rootReducer.autoflow.common.list,
    leaderboardTemplates: state.rootReducer.leaderboard.getIn(['templates']).toJS(),
    rankingTypes: state.rootReducer.leaderboard.getIn(['rankingTypes']).toJS(),
    connectedLeaderboard: state.rootReducer.forum.connectedLeaderboard,
    originalAutoflow: state.rootReducer.forum.group.autoflow,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAutoflowList: bindActionCreators(getAutoflowList, dispatch),
    dispatchSearchAutoflow: bindActionCreators(searchAutoflow, dispatch),
    push: bindActionCreators(push, dispatch),
    autoflowDisconnectForum: bindActionCreators(autoflowDisconnectForum, dispatch),
    autoflowConnectForum: bindActionCreators(autoflowConnectForum, dispatch),
    autoflowInvite: bindActionCreators(autoflowInvite, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    getConnectedLeaderboard: bindActionCreators(getConnectedLeaderboard, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectAutoflow);
