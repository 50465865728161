// Libs
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Shared
import LoadingIndicator from 'shared/LoadingIndicator';

// Parts
import NutritionView from './NutritionView';
import MarcoView from './MarcoView';

// Utils
import { DATE_FORMAT } from 'constants/commonData';

// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/MealPlans/action_arrow_right.svg';

// Styles
import * as S from './style';

const MarcoGoal = ({
  macroNutrients,
  getReportMacrosDaily,
  getReportMacrosWeekly,
  selectedClient,
  selectedDay,
  push,
  viewMode,
}) => {
  const [marcoData, setMarcoData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isMarco, setIsMarco] = useState(false);

  useEffect(() => {
    fetchMacrosDaily();
  }, [selectedDay, viewMode]);

  const fetchMacrosDaily = () => {
    const params = {
      client: get(selectedClient, '_id'),
      day: selectedDay.format('MM-DD-YYYY'),
    };

    setIsLoading(true);

    typeof getReportMacrosDaily === 'function' &&
      getReportMacrosDaily(params)
        .then(response => {
          const { value = {} } = get(response, 'data.data.goal', {});

          setMarcoData(value);
          setIsMarco(!isEmpty(value));
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const fetchMacrosWeekly = () => {
    const startOfWeek = selectedDay.clone().startOf('isoWeek');
    const endOfWeek = selectedDay.clone().endOf('isoWeek');

    const params = {
      client: get(selectedClient, '_id'),
      startDay: startOfWeek.format(DATE_FORMAT),
      endDay: endOfWeek.format(DATE_FORMAT),
    };

    setIsLoading(true);

    typeof getReportMacrosWeekly === 'function' &&
      getReportMacrosWeekly(params)
        .then(response => {
          const { value } = get(response, 'data.data.goals[0]', {});

          setMarcoData(value);
          setIsMarco(!isEmpty(value));
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const handleNavigateToMarco = () => {
    if (isLoading) return;

    push(`/home/client/${get(selectedClient, '_id')}/macros`, {
      isUpdate: true,
    });
  };

  return (
    <S.MarcoGoalWrapper isMarco={isMarco}>
      <S.MacroHeader>
        <S.MacroTitle>{viewMode.key === 'day' ? 'Daily Nutrition Total' : 'Weekly Nutrition Averages'}</S.MacroTitle>
        <>
          {isLoading ? null : (
            <S.ButtonAction onClick={handleNavigateToMarco}>
              <span>{isMarco ? 'Update Macro Goals' : 'Set Macro Goals'}</span>
              <ArrowIcon className="arrow-icon" />
            </S.ButtonAction>
          )}
        </>
      </S.MacroHeader>
      <S.SeparateLine isMarco={isMarco} />
      {isLoading ? (
        <S.MarcoGoalContainer>
          <LoadingIndicator title=" " className="loading-marco-nutrient" />
        </S.MarcoGoalContainer>
      ) : (
        <S.MarcoGoalContainer>
          {isMarco ? (
            <MarcoView marcoData={marcoData} macroNutrients={macroNutrients} isMarco={isMarco} />
          ) : (
            <NutritionView macroNutrients={macroNutrients} />
          )}
        </S.MarcoGoalContainer>
      )}
    </S.MarcoGoalWrapper>
  );
};

export default MarcoGoal;
