import React from 'react';
import moment from 'moment';

import * as S from '../styles';

export default function DateAnswerResponse({ answer, dateFormat }) {
  const { answer: answerData } = answer || {};
  const { date_answer = '' } = answerData || {};

  return <S.DateAnswer>{moment(date_answer).utc().format(dateFormat)}</S.DateAnswer>;
}
