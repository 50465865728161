import ConfirmModal from 'shared/ConfirmModal';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const Container = styled.div`
  width: 100%;
  height: ${({ isVerifyEmail }) => (isVerifyEmail ? 'calc(100% - 44px)' : '100%')};
  display: flex;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.save-before-exit-modal {
    width: 467px !important;
    .close-button {
      right: -7px;
    }
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
      .confirm-header-label {
        ${baseText}
        font-size: 21px;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .confirm-content-body {
      padding: 25px 30px 25px;
      .confirm-content-label {
        ${baseText}
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .confirm-actions {
      .button {
        border-radius: 5px;
        ${baseText}
        padding: 5px 24px;
        font-weight: 600;
        margin-left: 8px !important;
      }
    }
  }

  &.ui.modal.confirm-popup-container.save-before-exit-modal {
    .confirm-actions {
      .confirm-no-button {
        min-width: unset;
        width: 160px;
        border: 1px solid #e1e1ea !important;
        color: #777 !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        width: 77px;
        color: #fff;
        background-color: #5158cf !important;
        &:hover {
          background-color: rgba(81, 88, 207, 0.9) !important;
        }
      }
    }
  }
`;
