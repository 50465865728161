import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const RemoveMemberButton = styled.button.attrs({
  type: 'button',
})`
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #758094;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  box-shadow: none;
  background-color: transparent;
  position: relative;
  padding-left: 27px;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: url(${CDN_URL}/images/remove_bg_grey.svg) no-repeat center;
    z-index: 1;
  }
`;

export const RevokeButton = styled(RemoveMemberButton)`
  ::before {
    background: url(${CDN_URL}/images/revoke_bg_grey.svg) no-repeat center;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
