import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { axiosInstanceWithoutRefreshToken } from 'configs/request';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .verifyEmail__heading {
    padding: 20px;
    margin-top: 50px;

    img {
      height: 36px;
    }
  }

  .verifyEmail__body {
    border-top: solid 1px #DEE3E6;
    height: 1px;
    margin-bottom: 10px;
    text-align: center;
    padding: 20px;

    .verifyEmail__title {
      text-align: center;
      font-weight: bold;
      font-size: 26px;
      line-height: 44px;
      color: rgb(25, 23, 83);

      img {
        height: 25px;
        margin-left: 10px;
      }
    }

    .verifyEmail__destination {
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: rgb(25, 23, 83);
      margin-bottom: 40px;
    }
  }
`;

export default function VerifyChangeEmail(props) {
  const [state, setState] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    axiosInstanceWithoutRefreshToken
      .post('/api/auth/verify-change-email', { token: params.get('token') })
      .then(response => {
        if (response.data) {
          setState(response.data);
        }
      })
      .catch((error) => {
        setState(error.response.data);
      });
  }, []);

  return !_.isNil(state) && (
    <Wrapper>
      <div className="verifyEmail__heading">
        <img src="https://everfit-web-asset.s3.us-west-1.amazonaws.com/images/logo.png" />
      </div>
      {!state.error ? (
        <div className="verifyEmail__body">
          <div className="verifyEmail__title">
            <div>Email Address
              <img src={`${CDN_URL}/images/check_icon.png`} alt=""/>
            </div>
            <div>
              Verified on Everfit
            </div>
          </div>
          <div className="verifyEmail__destination">
            <div>You have successfully verified new email address</div>
            <div>You will be redirected in a few seconds, or else open the Everfit app and sign in to start training.</div>
          </div>
        </div>
      ) : (
        <div className="verifyEmail__body">
          <div className="verifyEmail__title">
            <div>Confirm changing email address on Everfit</div>
          </div>
          <div className="verifyEmail__destination">
            {state.message}
          </div>
        </div>
      )}
    </Wrapper>
  )
}