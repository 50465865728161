import React from 'react';

import * as S from './style';
import { CDN_URL } from 'constants/commonData';

const RemoveTriggerConfirmModal = props => {
  const { onConfirm } = props;

  const handleConfirm = () => {
    typeof onConfirm === 'function' && onConfirm();
  };

  return (
    <S.CustomModal
      isPressEsc
      noBorder
      hasCloseIcon
      hasHoverState
      headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
      title="Remove Trigger"
      confirmButtonTitle="Remove"
      content={
        <>
          Assignment based on trigger will be removed. Would you like to continue?
          <br />
          <br />
          Please note this will only affect upcoming clients that go through the Onboarding Flow.
        </>
      }
      className="remove-trigger-modal"
      onConfirm={handleConfirm}
    />
  );
};

export default RemoveTriggerConfirmModal;
