import React from 'react';
import { components } from 'react-select';
import get from 'lodash/get';

import { pluralize } from 'utils/commonFunction';
import { SurveyInformationOptionWrapper } from '../styles';

const CustomPlaceholder = props => {
  const { children, ...restProps } = props;
  const mask = get(children, 'mask');
  let placeholder = 'Select your survey';
  if (!!get(mask, 'surveyName') && !get(props, 'isFocused', false)) {
    const surveyName = get(children, 'mask.surveyName', '');
    const authorName = get(children, 'mask.authorName', '');
    const totalQuestions = get(children, 'mask.questions.length', 0);
    placeholder = (
      <div className="survey__info">
        <h6 className="survey__info-name">{surveyName}</h6>
        <SurveyInformationOptionWrapper>
          <span className="survey__info-questions">
            <b>{totalQuestions}</b> {pluralize('Question', totalQuestions)}
          </span>
          <div className="dot" />
          <span className="author-name">{authorName}</span>
        </SurveyInformationOptionWrapper>
      </div>
    );
  }

  return <components.Placeholder {...restProps}>{placeholder}</components.Placeholder>;
};

export default CustomPlaceholder;
