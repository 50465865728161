import { fromJS } from 'immutable';
import { Types } from './actions';

const INITIAL_STATE = fromJS({
  registration: null,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.AUTH_ADD_REGISTRATION_NEED_TO_COMPETE:
      return state.set('registration', fromJS(payload.data));

    default:
      return state;
  }
};
