import uniqBy from 'lodash/uniqBy';
import { PER_PAGE } from 'components/IngredientLibrary/constants';
import { Types } from './actions';

export const INITIAL_STATE = {
  list: [],
  loading: false,
  total: 0,
  filters: {
    page: 1,
    per_page: PER_PAGE,
    text_search: '',
    sort: -1,
    sorter: 'last_interacted',
    categories: [],
  },
  isLoading: false,
  selectedIngredients: [],
  listUnitIngredient: {},
  loadingListUnitIngredient: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.INGREDIENT_LIBRARY_GET_LIST_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case Types.INGREDIENT_LIBRARY_GET_LIST_FAILED:
      return Object.assign({}, state, {
        loading: false,
      });

    case Types.INGREDIENT_LIBRARY_GET_LIST_SUCCESS:
      const { data, total } = payload;
      return Object.assign({}, state, {
        loadingCreate: false,
        loading: false,
        list: data,
        total,
      });

    case Types.INGREDIENT_LIBRARY_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { filters: { ...payload.filters } });

    case Types.INGREDIENT_LIBRARY_RESET_QUERY_PARAMS:
      return Object.assign({}, state, { filters: INITIAL_STATE.filters });

    case Types.INGREDIENT_LIBRARY_ADD_SINGLE_REQUEST:
    case Types.INGREDIENT_LIBRARY_ADD_MULTI_REQUEST:
      return Object.assign({}, state, { isLoading: true });

    case Types.INGREDIENT_LIBRARY_ADD_SINGLE_SUCCESS: {
      const { data, filters } = payload;
      const { page, sort, sorter = '' } = filters || {};

      const reverseMostRecent = sort === 1 && sorter === 'last_interacted';

      return Object.assign({}, state, {
        isLoading: false,
        total: state.total + 1,
        list: reverseMostRecent || page !== 1 ? [...state.list] : [data, ...state.list],
      });
    }

    case Types.INGREDIENT_LIBRARY_ADD_MULTI_SUCCESS: {
      const { data, filters } = payload;
      const { page, sort, sorter = '' } = filters || {};

      const reverseMostRecent = sort === 1 && sorter === 'last_interacted';

      return Object.assign({}, state, {
        isLoading: false,
        total: state.total + (data || []).length,
        list: reverseMostRecent || page !== 1 ? [...state.list] : [...data, ...state.list],
      });
    }

    case Types.INGREDIENT_LIBRARY_ADD_SINGLE_FAILED:
    case Types.INGREDIENT_LIBRARY_ADD_MULTI_FAILED:
      return Object.assign({}, state, { isLoading: false });

    case Types.INGREDIENT_LIBRARY_SELECTED_INGREDIENTS: {
      const { ingredientIds } = payload;
      return Object.assign({}, state, { selectedIngredients: ingredientIds });
    }

    case Types.INGREDIENT_LIBRARY_RESET_SELECTED_INGREDIENT:
      return Object.assign({}, state, { selectedIngredients: [] });

    case Types.INGREDIENT_LIBRARY_REMOVE_MULTI_REQUEST: {
      return Object.assign({}, state, { isLoading: true });
    }

    case Types.INGREDIENT_LIBRARY_REMOVE_MULTI_SUCCESS: {
      const { data } = payload;

      return Object.assign({}, state, {
        isLoading: false,
        total: state.total - (data || []).length,
        list: [...state.list].filter(({ _id }) => !data.includes(_id)),
      });
    }

    case Types.INGREDIENT_LIBRARY_REMOVE_MULTI_FAILED: {
      return Object.assign({}, state, { isLoading: false });
    }

    case Types.INGREDIENT_LIBRARY_GET_LIST_UNIT_REQUEST: {
      return Object.assign({}, state, {
        loadingListUnitIngredient: true,
      });
    }

    case Types.INGREDIENT_LIBRARY_GET_LIST_UNIT_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, {
        listUnitIngredient: data,
        loadingListUnitIngredient: false,
      });
    }

    case Types.INGREDIENT_LIBRARY_GET_LIST_UNIT_FAILED: {
      return Object.assign({}, state, {
        loadingListUnitIngredient: false,
      });
    }

    case Types.INGREDIENT_LIBRARY_UPDATE_REQUEST: {
      return Object.assign({}, state, { isLoading: true });
    }

    case Types.INGREDIENT_LIBRARY_UPDATE_SUCCESS: {
      const { data, filters } = payload;
      const { page } = filters || {};

      let arrTmp = [...state.list];

      if (page === 1) {
        arrTmp = uniqBy([data, ...arrTmp], '_id');
      } else {
        arrTmp = arrTmp.filter(({ _id }) => _id !== data._id);
      }

      return Object.assign({}, state, {
        isLoading: false,
        list: arrTmp,
      });
    }

    case Types.INGREDIENT_LIBRARY_UPDATE_FAILED: {
      return Object.assign({}, state, { isLoading: false });
    }

    case Types.INGREDIENT_LIBRARY_UPDATE_LOADING: {
      const { loading = false } = payload || {};

      return Object.assign({}, state, {
        isLoading: loading,
      });
    }

    default:
      return state;
  }
};
