import React from 'react';
import { remove } from 'lodash';
import styled from 'styled-components';
import { MESSAGE_TYPES } from 'components/InAppMessage/constants';

export default (props) => {
  const { message } = props;
  const { messages } = message;

  if (!messages.length) {
    return null;
  }

  const images = messages.slice();
  const texts = remove(images, (m) => m.type === MESSAGE_TYPES.TEXT);
  const videos = remove(images, (m) => m.attachment_type && m.attachment_type.startsWith('video'));

  return (
    <Counter className="inAppMessage_counter">
      {texts.length ? (
        <div className="item">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 0C2.691 0 0 2.362 0 5.267C0 8.172 2.691 10.533 6 10.533C6.34684 10.5331 6.69319 10.5067 7.036 10.454L9.761 11.939C9.8344 11.9788 9.91652 11.9997 10 12C10.1326 12 10.2598 11.9473 10.3536 11.8536C10.4473 11.7598 10.5 11.6326 10.5 11.5V8.711C10.9646 8.26406 11.3363 7.72972 11.5937 7.13868C11.8511 6.54765 11.9892 5.91157 12 5.267C12 2.362 9.309 0 6 0Z"
              fill="#DEDEDE"
            />
          </svg>
          <span>{texts.length}</span>
        </div>
      ) : null}
      {images.length ? (
        <div className="item">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.125 0H0.875C0.35 0 0 0.35 0 0.875V13.125C0 13.65 0.35 14 0.875 14H13.125C13.65 14 14 13.65 14 13.125V0.875C14 0.35 13.65 0 13.125 0ZM5.25 3.5C5.775 3.5 6.125 3.85 6.125 4.375C6.125 4.9 5.775 5.25 5.25 5.25C4.725 5.25 4.375 4.9 4.375 4.375C4.375 3.85 4.725 3.5 5.25 3.5ZM2.625 10.5L4.375 7L6.125 8.75L8.75 5.25L11.375 10.5H2.625Z"
              fill="#DEDEDE"
            />
          </svg>
          <span>{images.length}</span>
        </div>
      ) : null}
      {videos.length ? (
        <div className="item">
          <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.763 1.57452C13.6156 1.48338 13.4315 1.47506 13.2765 1.55252L9.99997 3.191V2C9.99997 1.17157 9.3284 0.5 8.49997 0.5H1.5C0.671571 0.5 0 1.17157 0 2V7.99999C0 8.82841 0.671571 9.49998 1.5 9.49998H8.49997C9.3284 9.49998 9.99997 8.82841 9.99997 7.99999V6.80898L13.2765 8.44998C13.5235 8.57341 13.8238 8.47325 13.9472 8.22621C13.9823 8.15599 14.0004 8.07847 14 7.99999V2C14.0001 1.82668 13.9104 1.66569 13.763 1.57452ZM5 7C3.89542 7 2.99999 6.10457 2.99999 4.99999C2.99999 3.89541 3.89542 2.99999 5 2.99999C6.10458 2.99999 7.00001 3.89541 7.00001 4.99999C7.00001 6.10457 6.10455 7 5 7Z"
              fill="#DEDEDE"
            />
          </svg>
          <span>{videos.length}</span>
        </div>
      ) : null}
    </Counter>
  );
};

const Counter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  padding: 0 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #1f2023;

  .item {
    margin-right: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 5px;
    }

    :last-child {
      margin-right: 0;
    }
  }
`;
