import React, { useState, useMemo, useEffect, useRef, useLayoutEffect } from 'react';
import _ from 'lodash';
import { fromJS } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';
import { RootCloseWrapper } from 'react-overlays';
import * as S from './style';
import { Button } from 'shared/FormControl';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { ReactComponent as NextIcon } from 'assets/icons/arrow_right.svg';
import { ReactComponent as PrevIcon } from 'assets/icons/arrow_left.svg';

import SaveAsProgram from 'shared/SaveAsProgram';
import Header from './Header';

export default function (props) {
  const anchor = useRef('mounted');
  //state
  const [open, setOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [showForm, setShowForm] = useState(false);
  const [workoutInRange, setWorkoutInRange] = useState(fromJS({}));
  const [startMonth, setStartMonth] = useState(moment().startOf('month'));
  const [popupStyles, setPopupStyles] = useState({});

  //ref
  const popupRef = useRef();
  const triggerRef = useRef();

  useEffect(() => {
    return () => {
      anchor.current = null;
    };
  }, []);

  useLayoutEffect(() => {
    if (open) {
      const rect = triggerRef.current.getBoundingClientRect();

      const { x, y, height, width } = rect;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const popupWidth = popupRef.current.clientWidth;
      const minHeight = 515;
      const styles = { right: windowWidth - x - width };
      let className = 'bottom';
      const bottomSpacing = windowHeight - y - height - 10;

      if (bottomSpacing > minHeight) {
        styles.top = y + height + 10;
      } else {
        if (windowHeight < minHeight) {
          styles.top = 0;
        } else {
          styles.top = Math.max(windowHeight - minHeight - 5, 0);
        }
      }

      if (styles.right + popupWidth > windowWidth) {
        styles.right = Math.max(windowWidth - popupWidth + 5, 0);
      }

      setPopupStyles({ styles, className });
    }
  }, [open]);

  useEffect(() => {
    if (!open && !showForm) {
      setFocusedInput(START_DATE);
      setDates({ startDate: null, endDate: null });
    } else if (open && !showForm) {
      if (typeof props.fetchWorkoutsInRange === 'function') {
        try {
          const start_date = startMonth.format('MM-DD-YYYY');
          const end_date = moment(startMonth).add(1, 'month').endOf('month').format('MM-DD-YYYY');

          props.fetchWorkoutsInRange({ start_date, end_date }).then(data => {
            if (anchor && anchor.current) {
              setWorkoutInRange(workoutInRange.merge(data));
            }
          });
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [open, startMonth, showForm]);

  const [totalDays, totalWeeks] = useMemo(() => {
    if (!dates.startDate && !dates.endDate) {
      return [0, 0];
    }

    if (dates.startDate && !dates.endDate) {
      return [1, 1];
    }

    return [dates.endDate.diff(dates.startDate, 'days') + 1, dates.endDate.diff(dates.startDate, 'week') + 1];
  }, [dates]);

  const toggleOpen = () => {
    setOpen(prev => !prev);
  };

  const onRootClose = () => {
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
    setStartMonth(moment().startOf('month'));
  };

  const goToNextStep = () => {
    setShowForm(true);
    setOpen(false);
  };

  const onFocusChange = focusedInput => {
    if (dates.startDate) {
      setFocusedInput(END_DATE);
    } else if (dates.startDate && dates.endDate) {
      return;
    } else {
      setFocusedInput(focusedInput || START_DATE);
    }
  };

  const onDatesChange = data => {
    setDates(data);
  };

  const renderDayContents = (date, data) => {
    const showTootip = dates.startDate || dates.endDate;
    const day = showTootip
      ? date.isSame(dates.startDate, 'day')
        ? 1
        : date.isSame(dates.endDate, 'day')
        ? totalDays
        : null
      : null;
    const workouts = workoutInRange.getIn([date.format('YYYY-MM-DD'), 'workouts']);
    const totalDots = workouts ? Math.min(3, workouts.size) : 0;
    const isEndDateHovering =
      focusedInput === END_DATE && !dates.endDate && data && data.has('hovered') && !data.has('selected-start');

    const isStartDateHovering = focusedInput === START_DATE && data && data.has('hovered');

    return (
      <div
        className={classnames('CalendarDay__content', {
          isToday: date.isSame(moment(), 'day'),
          isEndDateHovering: isEndDateHovering,
          isStartDateHovering: isStartDateHovering,
        })}
      >
        <div className="CalendarDay__content__dayValue" data-tip data-for={day ? `tooltip-date-index-${day}` : ''}>
          {date.format('DD')}
        </div>
        {totalDots ? (
          <S.WorkoutDotContainer>
            {_.range(totalDots).map(index => (
              <S.Dot key={index} />
            ))}
          </S.WorkoutDotContainer>
        ) : null}
        {day ? (
          <ReactTooltip className="app-tooltip" id={`tooltip-date-index-${day}`} effect="solid">
            <span>Day {day}</span>
          </ReactTooltip>
        ) : null}
      </div>
    );
  };

  const isDayBlocked = date => {
    if (!dates.startDate && !dates.endDate) {
      return false;
    } else if (dates.startDate && date.isBefore(dates.startDate, 'day')) {
      return true;
    } else if (dates.endDate && date.isAfter(dates.endDate, 'day')) {
      return true;
    } else if (dates.startDate && dates.endDate && !date.isBetween(dates.startDate, dates.endDate, 'days', '[]')) {
      return true;
    }

    return false;
  };

  const onClearDate = event => {
    const { forDate } = event.currentTarget.dataset;

    if (forDate === START_DATE) {
      setFocusedInput(START_DATE);
      setDates(prev => ({ ...prev, startDate: null }));
    } else {
      setFocusedInput(!dates.startDate ? START_DATE : END_DATE);
      setDates(prev => ({ ...prev, endDate: null }));
    }
  };

  const onSave = data => {
    return props.onSave({ ...data, ...dates });
  };

  const onCloseForm = () => {
    setShowForm(false);
    setStartMonth(moment().startOf('month'));
  };

  const onNextMonthClick = date => {
    setStartMonth(date.startOf('month'));
  };

  const onPrevMonthClick = date => {
    setStartMonth(date.startOf('month'));
  };

  return (
    <RootCloseWrapper event="click" disabled={true} onRootClose={onRootClose}>
      <S.Wrapper className={('saveExactDateCalendarAsProgram', classnames(popupStyles.className, { open: open }))}>
        <S.Trigger onClick={toggleOpen} className="saveExactDateCalendarAsProgram__trigger" ref={triggerRef}>
          <SaveIcon />
          <span>Save as Program</span>
        </S.Trigger>
        {open && (
          <S.PopupContainer className="saveExactDateCalendarAsProgram__popup" ref={popupRef} style={popupStyles.styles}>
            <Header
              totalDays={totalDays}
              totalWeeks={totalWeeks}
              dates={dates}
              onClearDate={onClearDate}
              focusedInput={focusedInput}
            />
            <S.Content className="saveExactDateCalendarAsProgram__content">
              <DayPickerRangeController
                startDate={dates.startDate}
                endDate={dates.endDate}
                onDatesChange={onDatesChange}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                hideKeyboardShortcutsPanel={true}
                numberOfMonths={2}
                isDayBlocked={isDayBlocked}
                noBorder={true}
                renderDayContents={renderDayContents}
                transitionDuration={0}
                navPrev={<PrevIcon />}
                navNext={<NextIcon />}
                onPrevMonthClick={onPrevMonthClick}
                onNextMonthClick={onNextMonthClick}
              />
            </S.Content>
            <S.Footer className="saveExactDateCalendarAsProgram__footer">
              <Button key="cancel" textOnly onClick={onCancel}>
                Cancel
              </Button>
              <Button
                key="update"
                purple
                className="save"
                onClick={goToNextStep}
                disabled={!dates.startDate || !dates.endDate}
              >
                Save
              </Button>
            </S.Footer>
          </S.PopupContainer>
        )}
        {showForm && (
          <SaveAsProgram
            onSave={onSave}
            controlled
            onClose={onCloseForm}
            title={props.defaultTitle}
            header="Save Program to library"
          />
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
}
