import React from 'react';
import _, { concat, filter, find, get, includes, sortBy } from 'lodash';
import { Modal, Image } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import { LOG_ACTIVITY_FIELD, CLIENT_RATINGS, KEY_CODE, CDN_URL } from 'constants/commonData';
import { Workout } from 'types/model';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { ReactComponent as DistanceIcon } from 'assets/icons/log-activity-distance-icon.svg';
import { ReactComponent as HeartRateIcon } from 'assets/icons/log-activity-heart-rate-icon.svg';
import { ReactComponent as CaloriesIcon } from 'assets/icons/log-activity-calories-icon.svg';
import { ReactComponent as RepsIcon } from 'assets/icons/log-activity-reps-icon.svg';
import { ReactComponent as AvgPowerIcon } from 'assets/icons/log-activity-avg-power-icon.svg';
import { ReactComponent as ElevationIcon } from 'assets/icons/log-activity-elevation-gain-icon.svg';
import { ReactComponent as AvgCadenceIcon } from 'assets/icons/log-activity-avg-cadence-icon.svg';
import { ReactComponent as PaceIcon } from 'assets/icons/log-activity-pace-icon.svg';

import Comments from './Comments';
import './styles.scss';
import * as S from './styles.js';

import {
  ResultContainer,
  Wrapper,
  MainInfoContainer,
  BaseInfoContainer,
  Details,
  HeaderText,
  ContainerWithHeaderBackground,
  NotesContainer,
} from './styles';
import ConfirmModal from 'shared/ConfirmModal';

import HistoryExercisePopup from 'components/HistoryExercisePopup';
import NoteModal from 'components/NoteModal';
import { ReactComponent as HistoryIcon } from 'assets/icons/history_bold.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/note_purple.svg';
import ActivityEmoji from './ActivityEmoji';
import TextEditable from 'shared/TextEditable';

export const EDIT_MODE = 'EDIT_MODE';
export const VIEW_MODE = 'VIEW_MODE';

const TIME_FORMAT = 'h:mm A';
const { CALORIES, AVG_HEART_RATE, REPS, DISTANCE, AVG_CADENCE, POWER, ELEVATION_GAIN, PACE } = LOG_ACTIVITY_FIELD;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatNum(num) {
  return Math.abs(num) > 999 ? numberWithCommas(num) : Math.sign(num) * Math.abs(num);
}

const LOG_VIDEO_TYPE = 'ondemand_video';

export default class LogActivityModal extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.uploadedAllComment = true;
    this.viewNote = null;
    this.isOnDemandVideo = get(props, 'activity.log_type', '') === LOG_VIDEO_TYPE;
  }

  state = {
    workout: this.getDefaultWorkout(),
    isAddingNote: false,
    shouldFocusNote: false,
    viewNote: null,
    viewDetail: false,
    viewMore: null,
    viewFilter: null,
    isHighFive: _.get(this.props.activity, 'is_high_five', false),
  };

  handleViewNote = ex => {
    this.setState({ viewNote: ex });
  };

  handleCloseViewNote = () => {
    this.setState({
      viewNote: null,
    });
  };

  getDefaultWorkout() {
    const { workingWorkout } = this.props;
    return workingWorkout || new Workout();
  }

  performCloseHistory = () => {
    this.props.dispatch(toggleModal(false));
    const { pathname } = window.location;

    if (pathname.includes('activity')) {
      const { selectedClient } = this.props;

      if (selectedClient) {
        this.props.dispatch(push(`/home/client/${selectedClient}/calendar`));
      } else {
        this.props.dispatch(push(`/home/client`));
      }
    }
  };

  handleCloseAction = () => {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy) return null;
    if (!this.uploadedAllComment) {
      this.props.dispatch(
        toggleConfirmModal(
          true,
          <ConfirmModal
            title="Upload in progress"
            content="If you close the pop-up, all incomplete uploads will be discarded. Would you like to exit without finishing?"
            onConfirm={() => this.performCloseHistory()}
            newStyle={true}
            headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          />,
        ),
      );
    } else {
      this.performCloseHistory();
    }
  };

  renderHistoryPopup = exerciseSet => {
    const exerciseId = _.get(exerciseSet, 'exercise._id', '') || _.get(exerciseSet, 'exercise_instance.exercise', '');
    const tooltipId = `tooltip--history-${exerciseSet.exercise._id}`;
    return (
      <HistoryExercisePopup
        exerciseId={exerciseId}
        deletedExercise={!_.get(exerciseSet, 'exercise._id', '')}
        trigger={
          <>
            <HistoryIcon data-tip="View History" data-for={tooltipId} />
            <ReactTooltip id={tooltipId} effect="solid" className="app-tooltip" />
          </>
        }
      />
    );
  };

  renderNotePopup = exerciseSet => {
    return (
      <>
        <NoteIcon
          data-for={`note-${exerciseSet.exercise._id}`}
          data-tip="View Note"
          onClick={() => this.handleViewNote(exerciseSet)}
          className="view-note"
        />
        <ReactTooltip id={`note-${exerciseSet.exercise._id}`} place="top" effect="solid" className="app-tooltip" />
      </>
    );
  };

  getIcon = unique_code => {
    switch (unique_code) {
      case CALORIES.unique_code:
        return <CaloriesIcon />;
      case AVG_HEART_RATE.unique_code:
        return <HeartRateIcon />;
      case REPS.unique_code:
        return <RepsIcon />;
      case DISTANCE.unique_code:
        return <DistanceIcon />;
      case AVG_CADENCE.unique_code:
        return <AvgCadenceIcon />;
      case POWER.unique_code:
        return <AvgPowerIcon />;
      case ELEVATION_GAIN.unique_code:
        return <ElevationIcon />;
      case PACE.unique_code:
        return <PaceIcon />;
      default:
        return null;
    }
  };

  renderBaseInfo = caloriesData => {
    const { activity } = this.props;
    const caloriesValue = get(caloriesData, 'value');
    const { duration, timeText } = this.duration();
    const videoThumbnail = get(activity, 'on_demand_workout.background');
    const clientRating = get(activity, 'rating', null);

    return (
      <BaseInfoContainer className="workout-base-info">
        {!this.isOnDemandVideo ? (
          <ActivityEmoji emoji={activity.icon} backgroundColor={activity.background_icon_color} />
        ) : (
          <S.VideoThumbnailContainer thumbnail={videoThumbnail} />
        )}
        <div className={`workout__name ${videoThumbnail ? 'with__video__margin' : ''}`}>{activity.name || ''}</div>
        {timeText && <div className="duration">{timeText}</div>}
        <div className="workout__summarize">
          <div className="completion">
            <div className={`item ${!caloriesValue && 'center'}`}>
              <Image src={`${CDN_URL}/images/log-activity-calories.svg`} width={32} />
              <div className="value">
                <div className="unit">Duration</div>
                <span>
                  {Math.ceil(duration.asMinutes())} <span className="unit-display">min</span>
                </span>
              </div>
            </div>
            {caloriesValue && (
              <div className="item">
                <Image src={`${CDN_URL}/images/log-activity-calories.svg`} width={32} />
                <div className="value">
                  <div className="unit">Calories</div>
                  <span>
                    {formatNum(Math.round((caloriesValue + Number.EPSILON) * 100) / 100)}{' '}
                    <span className="unit-display">{caloriesData.unit.title}</span>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.isOnDemandVideo && clientRating !== null && clientRating >= 0 && (
          <div className="workout__summarize video__workout">
            <div className="client__rating__header">Client Rating</div>
            <div className="client__rating__detail">{CLIENT_RATINGS[clientRating]}</div>
          </div>
        )}
      </BaseInfoContainer>
    );
  };

  renderNoteExercise = () => {
    const exTitle = _.get(this.state.viewNote, 'exercise_instance.title', '');
    return (
      <NoteModal
        onClose={this.handleCloseViewNote}
        value={this.state.viewNote.note}
        onSubmit={this.handleCloseViewNote}
        title={exTitle}
      />
    );
  };

  duration = () => {
    const { activity, user } = this.props;
    const { timezone: coachTimezone } = user;
    const clientTimezone = get(activity, 'author.timezone');
    const formatTimezone = clientTimezone ? clientTimezone : coachTimezone; // if client's timezone is not available, use Coach's timezone

    let start = moment(activity.start_time),
      end = moment(activity.end_time);

    let timediffms = end - start; // difference in ms
    let duration = moment.duration(timediffms);
    const endDateFormat = moment(activity.end_time).tz(formatTimezone).format('MMM D, YYYY');
    let timeText = endDateFormat;

    if (activity.start_time && activity.end_time) {
      const startDateMoment = moment(activity.start_time).tz(formatTimezone);
      const endDateMoment = moment(activity.end_time).tz(formatTimezone);
      const DATE_FORMAT = startDateMoment.isSame(endDateMoment, 'year') ? 'MMM D' : 'MMM D, YYYY';

      if (endDateMoment.isSame(startDateMoment, 'day')) {
        timeText = `${endDateFormat} • ${startDateMoment.format(TIME_FORMAT)} - ${endDateMoment.format(TIME_FORMAT)}`;
      } else {
        timeText = `${startDateMoment.format(`${DATE_FORMAT} - ${TIME_FORMAT}`)} • ${endDateMoment.format(
          `${DATE_FORMAT} - ${TIME_FORMAT}`,
        )}`;
      }
    }
    return {
      timeText,
      duration,
    };
  };

  generatePaceField = (fields, distanceData, durationData) => {
    const hundredFormatUnits = ['m', 'yd', 'ft'];
    const shouldFormatHundred = includes(hundredFormatUnits, distanceData.unit.unique_code);
    const { duration } = durationData;
    const noOfMinutes = duration.asMinutes();
    const noOfSeconds = duration.asSeconds();
    let paceSeconds = noOfSeconds / distanceData.value;
    let paceMinutes = noOfMinutes / distanceData.value;
    if (shouldFormatHundred) {
      paceSeconds = paceSeconds * 100;
      paceMinutes = paceMinutes * 100;
    }
    let totalTime = '00:00';
    let paceDurationTitle = 'min';
    if (paceMinutes < 10) {
      const minutesText = Math.floor(paceSeconds / 60);
      let secondsText = Math.floor(paceSeconds % 60);
      if (secondsText < 10) {
        secondsText = '0' + secondsText;
      }
      totalTime = `${minutesText}:${secondsText}`;
    } else {
      paceDurationTitle = 'hr';
      const hoursText = Math.floor(paceSeconds / 3600);
      let minutesText = Math.floor(paceMinutes % 60);
      if (minutesText < 10) {
        minutesText = '0' + minutesText;
      }
      totalTime = `${hoursText}:${minutesText}`;
    }

    return {
      _id: 'pace-field',
      log_activity_field: {
        _id: 'pace_unit',
        data_type: 'string',
        display: 'Pace',
        title: 'Pace',
        unique_code: 'pace',
        unit_category: 'pace_unit',
      },
      unit: {
        _id: 'pace_unit',
        name: 'pace',
        title: `${paceDurationTitle} / ${shouldFormatHundred ? '100' : ''}${distanceData.unit.title}`,
        unique_code: `${paceDurationTitle} / ${distanceData.unit.title}`,
      },
      value: totalTime,
    };
  };

  handleClickHighFive = id => {
    this.props.highFiveLogActivities(id, 4).then(() => {
      this.setState({
        isHighFive: true,
      });
    });
  };

  render() {
    const { viewNote, isHighFive } = this.state;
    const { activity } = this.props;

    var log_activity_fields = get(activity, 'tracking_fields', []);
    const distanceData = find(log_activity_fields, obj => obj.log_activity_field.unique_code === DISTANCE.unique_code);
    if (distanceData) {
      // Distance is needed to calculate Pace
      const paceField = this.generatePaceField(log_activity_fields, distanceData, this.duration());
      log_activity_fields = concat(log_activity_fields, paceField);
    }
    const trackingFieldFilterOutList = [CALORIES.unique_code];
    const caloriesData = find(log_activity_fields, obj => obj.log_activity_field.unique_code === CALORIES.unique_code);
    const trackingFields = sortBy(
      filter(log_activity_fields, obj => !includes(trackingFieldFilterOutList, obj.log_activity_field.unique_code)),
      it => LOG_ACTIVITY_FIELD[it.log_activity_field.unique_code.toUpperCase()].order,
    );

    return (
      <Modal
        closeOnDimmerClick={false}
        size={'tiny'}
        open={this.props.isModalOpen}
        className="workout-history-modal"
        onClose={this.handleCloseAction}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
      >
        <Modal.Header></Modal.Header>
        <Modal.Content className="workout-history-content">
          <Wrapper>
            <ResultContainer>
              <MainInfoContainer>
                <HeaderText>{this.isOnDemandVideo ? activity.name : 'Activity'}</HeaderText>
                <ContainerWithHeaderBackground>
                  {this.renderBaseInfo(caloriesData)}
                  {trackingFields.length > 0 && (
                    <Details className="history__details">
                      {trackingFields.map(trackingField => {
                        const isPaceField = trackingField.log_activity_field.unique_code === PACE.unique_code;
                        const roundedValue = isPaceField
                          ? trackingField.value
                          : formatNum(Math.round((trackingField.value + Number.EPSILON) * 100) / 100);

                        const trackingText = `${trackingField.log_activity_field.display}: ${roundedValue} ${trackingField.unit.title}`;
                        return (
                          <div className="value">
                            {this.getIcon(trackingField.log_activity_field.unique_code)}
                            {trackingText}
                          </div>
                        );
                      })}
                    </Details>
                  )}
                  {activity.note && (
                    <NotesContainer>
                      <div className="note-header">Note</div>
                      <TextEditable value={activity.note} readOnly className="note-description" />
                    </NotesContainer>
                  )}
                </ContainerWithHeaderBackground>
              </MainInfoContainer>
            </ResultContainer>
            {/* TODO: should implement comments logic */}
            <Comments
              assignmentId={activity._id}
              highFives={_.get(activity, 'high_fives', [])}
              handleClickHighFive={this.handleClickHighFive}
              isHighFive={isHighFive}
            />
          </Wrapper>
        </Modal.Content>
        {viewNote && this.renderNoteExercise()}
      </Modal>
    );
  }
}
