import React from 'react';
import { get } from 'lodash';

import { autoflowTypeParse } from 'utils/commonFunction';
import { ReactComponent as AutoflowIcon } from 'assets/icons/route_autoflow.svg';
import { ReactComponent as AutoflowSetting } from 'assets/icons/asset-autoflow-setting-icon.svg';
import { AUTOFLOW_STATUS } from 'constants/commonData';
import UpgradePath from 'shared/UpgradePath';

import AssetCard from './AssetCard';

export default function AutoflowAsset({ data, onDeleteAsset, isEdit }) {
  const isSettingTurnedOff = get(data, 'asset_data.isSettingTurnedOff', false);
  const disabled = {
    isDisabled: data.is_deleted || data.asset_data.status !== AUTOFLOW_STATUS.ACTIVATED,
    disabledText: data.is_deleted ? 'deleted' : 'inactive',
  };
  return (
    <UpgradePath
      pathName="autoflow"
      fallback={
        <AssetCard
          icon={<AutoflowIcon />}
          title={data.asset_data.name}
          asset_type={data.asset_type}
          onDeleteAsset={onDeleteAsset}
          isEdit={isEdit}
          disabled={true}
          disabledText={'inactive'}
        >
          <span className="badge">autoflow</span>
          <span className="badge">{autoflowTypeParse(data.asset_data.type)}</span>
          {!isSettingTurnedOff && <AutoflowSetting className="autoflow-setting" />}
        </AssetCard>
      }
    >
      <AssetCard
        icon={<AutoflowIcon />}
        title={data.asset_data.name}
        asset_type={data.asset_type}
        onDeleteAsset={onDeleteAsset}
        isEdit={isEdit}
        disabled={disabled.isDisabled}
        disabledText={disabled.disabledText}
      >
        <span className="badge">autoflow</span>
        <span className="badge">{autoflowTypeParse(data.asset_data.type)}</span>
        {!isSettingTurnedOff && <AutoflowSetting className="autoflow-setting" />}
      </AssetCard>
    </UpgradePath>
  );
}
