import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isUndefined, toNumber } from 'lodash';
import RepRangeInputStyles from './styles';

const reg = /^\d{0,3}-?\d{0,3}$/;
const numberReg = /^\d{0,3}$/;

const RepRangeInput = ({ className, onChange, defaultValue = '', isNumber, error: errorDefault, ...props }) => {
  const [value, setValue] = useState(`${defaultValue || ''}`);
  const [error, setError] = useState(errorDefault || false);

  useEffect(() => {
    if (defaultValue === false) {
      setValue(0);
      setError(true);
    }
  }, []);

  const handleOnKeyPress = e => {
    const text = e.target.value;
    if (text) setError(false);
    if (isNumber) {
      if (numberReg.test(text)) {
        if (text.indexOf('-') === -1 && text.length > 3) {
          return;
        }
        setValue(text);
      }
    } else {
      if (reg.test(text)) {
        if (text.indexOf('-') === -1 && text.length > 3) {
          return;
        }
        setValue(text);
      }
    }
  };

  const handleOnChange = () => {
    if (error) return onChange(false);

    if (value === '') {
      if (isNumber) setError(true);

      return onChange('');
    }

    if (value == 0) {
      setError(true);
      return onChange(false);
    }

    if (!value) return;

    const [fromValue, toValue] = value.toString().split('-');
    if (fromValue === '' && toValue === '') {
      setValue('');
      return onChange('');
    }

    let newValue;
    if (fromValue === '' || toValue === '' || isUndefined(toValue)) {
      newValue = fromValue === '' ? toNumber(toValue) : toNumber(fromValue);
      setValue(newValue);
      if (newValue === 0) {
        setError(true);
        return onChange(false);
      }

      return onChange(newValue);
    }

    const fromValueNumber = toNumber(fromValue);
    const toValueNumber = toNumber(toValue);
    if (fromValueNumber === toValueNumber) newValue = fromValueNumber;
    else newValue = `${fromValueNumber}-${toValueNumber}`;

    setValue(newValue);
    if (fromValueNumber === 0 || toValueNumber === 0) {
      setError(true);
      return onChange(false);
    }
    return onChange(newValue);
  };

  return (
    <RepRangeInputStyles>
      <input
        {...props}
        className={classnames(className, { error })}
        value={value}
        onChange={handleOnKeyPress}
        onBlur={handleOnChange}
      />
    </RepRangeInputStyles>
  );
};

RepRangeInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default RepRangeInput;
