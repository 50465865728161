import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const EditButton = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 8px 15px;

  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #818da1;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 10px;
    path {
      fill: #818da1;
    }
  }

  :hover {
    color: #5158cf;
    svg {
      margin-right: 10px;
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;

  .select-file__trigger {
    color: #5158cf;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ${props =>
    props.isDragging
      ? css`
          opacity: 1;
        `
      : css`
          :hover {
            background-color: #00000030;
            opacity: 1;

            .select-file__trigger {
              color: #ffffff;
            }
          }
        `}
`;

export const Wrapper = styled.div`
  background: #eaecf2;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 189px;
  width: 100%;
  outline: none;
  position: relative;

  &.error {
    box-shadow: 0px 0px 1px 1px #ea314a;
  }

  .bannerImage__editBtn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 2;
  }

  ${props =>
    props.isDragging &&
    css`
      border: 1px dashed #5158cf;

      ${Placeholder} {
        opacity: 1;
      }
    `}
  ${props =>
    props.src &&
    !props.isDragging &&
    css`
      background-image: url(${props.src});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      cursor: pointer;

      ${Placeholder} {
        opacity: 0;
      }
    `}
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const RemoveIcon = styled.div`
  width: 16px;
  height: 16px;
  background: transparent url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
`;
