import Request from 'configs/request';

export const Types = {
  MY_FITNESS_PAL_STARTED_SYNCING: 'MY_FITNESS_PAL_STARTED_SYNCING',
  MY_FITNESS_PAL_SYNCED: 'MY_FITNESS_PAL_SYNCED',
};

export const syncMyFitnessPal = data => {
  return dispatch => {
    dispatch({ type: Types.MY_FITNESS_PAL_STARTED_SYNCING, payload: { data } });
  };
};

export const finishSyncMyFitnessPal = data => {
  return dispatch => {
    dispatch({ type: Types.MY_FITNESS_PAL_SYNCED, payload: { data } });
  };
};
export const getMyfitnesspalStatus = clientId => {
  return Request.get(
    { url: `/api/integration-source/get-status`, params: { source: 'MY_FITNESS_PAL', clientId } },
    true,
  );
};

export const saveMacroNote = data => {
  return Request.post({ url: `/api/macro/save-macro-note`, data }, true);
};

export const getMacroNote = client => {
  return Request.get({ url: `/api/macro/get-macro-note`, params: { client } }, true);
};
