import { CDN_URL } from 'constants/commonData';

export const WEB_FEATURES = [
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-personalize.png`,
    title: 'Personalize for each Client',
    description:
      'Customize the coaching features used for each client, and add workouts and tasks easily from a calendar view.',
  },
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-training.png`,
    title: 'Build your Training Libraries',
    description:
      'Create Exercises, Workouts, Programs, Tasks, and Forms to reuse as templates in your personal library.',
  },
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-custom-branding.png`,
    title: 'Custom Branding',
    description:
      'Set up your personal branding to elevate the client app experience with your logo, theme colors, and more.',
  },
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-autoflow.png`,
    title: 'Group Automation with Autoflow',
    description:
      'Automate delivery of messages, workouts, or tasks to a group the same time it takes to program for one.',
  },
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-coaching.png`,
    title: 'On-demand Coaching',
    description:
      'Launch an on-demand content hub with workouts, docs, and links to provide self-guided training options.',
  },
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-sell-packages.png`,
    title: 'Sell Packages',
    description: 'Set up one-time or recurring payments and automate program delivery when clients make a purchase.',
  },
];

export const APP_FEATURES = [
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-messaging.png`,
    title: 'Messaging & Notifications',
    description: 'Respond to Inbox messages, client comments, and Community Forums wherever you are.',
  },
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-individual.png`,
    title: 'Individual Client Profiles',
    description:
      'Review workouts, food journals, metric charts, or task completion, and add assignments straight from the app.',
  },
  {
    screenshot: `${CDN_URL}/images/mobile-onboarding-screenshot-libraries.png`,
    title: 'Exercise & Workout Libraries',
    description:
      'Create and edit Exercises from videos shot on your phone, and design Workouts for clients on the fly.',
  },
];
