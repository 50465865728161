import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, Image, Button } from 'semantic-ui-react';
import AsyncSelect from 'react-select/lib/Async';
import Datetime from 'react-datetime';
import moment from 'moment';

import { pluralize } from 'utils/commonFunction';

import { CDN_URL, CLIENT_STATUS } from 'constants/commonData';
import './style.scss';
import {
  FormGroup,
  CustomOptionProgramWrapper,
  ProgramOptionNameContainer,
  ProgramOptionOwnerContainer,
  DayRangeContainer,
  InvalidEndDate,
} from './style';
import SelectClientOption from 'shared/SelectClientOption';
import SelectProgramDay from 'shared/SelectProgramDay';
import { DropdownIndicator } from 'shared/Icons';
import { timeSince } from 'utils/commonFunction';
import { Mixpanel } from 'utils/mixplanel';
import { searchClients, searchGroupClients } from 'redux/autoflow/client/actions';
import { selectStyles } from 'shared/SearchGroupName';

const CustomOptionProgram = ({ innerProps, data, isFocused }) => {
  let className = 'evfSelectBox__option';

  if (isFocused) {
    className += ' evfSelectBox__option--is-focused';
  }

  return (
    <CustomOptionProgramWrapper {...innerProps} className={className}>
      <ProgramOptionNameContainer>
        <div>{data.title}</div>
        {data.active_sync && <span className="live-sync">Live Sync</span>}
      </ProgramOptionNameContainer>
      <ProgramOptionOwnerContainer>
        {!!data.last_interacted && <div className="last-used">{timeSince(new Date(data.last_interacted))}</div>}
      </ProgramOptionOwnerContainer>
    </CustomOptionProgramWrapper>
  );
};

class AssignProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignTo: props.originClients || [],
      assignToGroup: [],
      startingOn: moment(),
      startingAt: 0,
      endingOn: props.program ? props.program.workout_sets.length * 7 - 1 : 0,
      submitting: false,
      selectedProgram: null,
    };

    this.searchClientDebounce = _.debounce(this.searchClient, 200);
    this.onSearchProgramDebounce = _.debounce(this.onSearchProgram, 200);
  }

  onSubmit = () => {
    const { startingAt, startingOn, selectedProgram, submitting, endingOn } = this.state;
    const { program, programAssignMultiple, onAssignSuccess, from } = this.props;
    let { assignToGroup, assignTo } = this.state;

    if (endingOn < startingAt) {
      return;
    }

    if (submitting) {
      return false;
    }

    if (!assignTo.length) {
      return false;
    }

    if (assignToGroup.length > 0) {
      _.map(assignTo, item => {
        if (item.typeName !== 'group') assignToGroup.push(item);
      });
    }

    const bodyData = {
      pid: program ? program._id : selectedProgram._id,
      clients: assignToGroup.length > 0 ? [...new Set(_.map(assignToGroup, '_id'))] : _.map(assignTo, '_id'),
      date: moment(startingOn).format(),
      starting_at: startingAt + 1,
      ending_at: endingOn + 1,
    };

    const totalWeek = _.get(program || selectedProgram, 'workout_sets.length');

    this.setState({ submitting: true });

    programAssignMultiple(bodyData)
      .then(() => {
        if (bodyData.ending_at < Number(totalWeek) * 7) {
          Mixpanel.track(`set_end_date_program_on_${from}`);
        }
        if (typeof onAssignSuccess === 'function') {
          onAssignSuccess();
          this.handleClearAddList();
        }
      })
      .catch(() => {
        this.setState({ submitting: false });
      });
  };

  handleClearAddList = () => {
    this.setState({
      assignTo: this.props.originClients || [],
      assignToGroup: [],
    });
  };

  searchClient = (inputValue, callback) => {
    const { assignTo } = this.state;
    const except = _.map(assignTo, '_id');
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    this.props
      .searchGroupClients({ q: search, selected_clients: except })
      .then(response => {
        const { data } = response.data;

        if (data) {
          if (data.length > 0) {
            callback(
              _.map(data, item => ({
                ...item,
                key: item._id,
                value: item._id,
                label: `${item.first_name} ${item.last_name}`,
                typeName: 'client',
              })),
            );
          } else {
            this.searchGroupClient(inputValue, callback);
          }
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  searchGroupClient = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    const searchAll = search.toLowerCase() === 'group' ? true : false;

    this.props
      .searchGroupClients({ txt_search: searchAll ? '' : search })
      .then(response => {
        const { data } = response.data;

        if (data) {
          const { data } = response.data;
          const clients = data.clients.map(o => ({
            ...o,
            typeName: 'client',
            name: `${o.first_name} ${o.last_name}`,
          }));
          const groups =
            data &&
            data.groups &&
            data.groups
              .filter(item => item && item.total_clients > 0)
              .map(g => ({
                ...g,
                typeName: 'group',
              }));

          const results = _.orderBy([...clients, ...groups], ['typeName'], ['asc']).filter(
            item =>
              item.typeName === 'group' ||
              ((item.client_connection === CLIENT_STATUS.connected ||
                item.client_connection === CLIENT_STATUS.pending) &&
                !item.is_archived),
          );
          callback(
            _.map(searchAll ? groups : results, item => ({
              ...item,
              key: item._id,
              value: item._id,
              label:
                item.typeName === 'group'
                  ? `${item.name} (${item.total_clients} ${pluralize('client', item.total_clients || 0)})`
                  : item.name,
              total: item.total_clients,
              typeName: item.typeName,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  renderAssignTo = () => {
    return (
      <FormGroup className="assign-to">
        <div className="label">Assign to</div>
        <AsyncSelect
          isMulti
          defaultOptions
          cacheOptions
          components={{ Option: SelectClientOption }}
          loadOptions={(inputValue, callback) => this.searchClientDebounce.call(this, inputValue, callback)}
          onChange={list => this.handleAssignToMember(list)}
          className="multi-select-container"
          classNamePrefix="multi-select"
          placeholder="Enter user..."
          noOptionsMessage={() => 'No results'}
          defaultValue={_.map(this.state.assignTo, item => ({
            ...item,
            key: item._id,
            value: item._id,
            label: item.full_name ? item.full_name : `${item.first_name} ${item.last_name}`,
          }))}
          styles={selectStyles}
        />
      </FormGroup>
    );
  };

  handleAssignToMember = list => {
    let assignToGroup = [];

    list
      .filter(item => item.typeName === 'group')
      .map(item => {
        this.props.searchGroupClients({ group: item._id }).then(response => {
          _.map(response.data.data.clients, item => {
            assignToGroup.push(item);
          });
        });
      });

    this.setState({ assignTo: list, assignToGroup });
  };

  onSelectProgram = data => {
    this.setState({ selectedProgram: data, endingOn: data.workout_sets.length * 7 - 1, startingAt: 0 });
  };

  onSearchProgram = (inputValue, callback) => {
    const { programs } = this.props;
    if (!programs.length) {
      this.props.getProgramLibrary().then(response => {
        callback(response.data.data);
      });
    } else {
      const textSearch = inputValue;
      const regex = new RegExp(textSearch, 'i');
      const searchResults = programs.filter(program => {
        return regex.test(program.title);
      });
      callback(_.orderBy(searchResults, ['last_interacted'], ['desc']));
    }
  };

  renderProgram = () => {
    const { program } = this.props;

    return (
      <FormGroup className="program">
        <div className="label">Program</div>
        {program ? (
          <div className="form-control">{program.title}</div>
        ) : (
          <div className="select-program">
            <AsyncSelect
              defaultOptions
              cacheOptions
              getOptionLabel={option => option.title}
              loadOptions={(inputValue, callback) => this.onSearchProgramDebounce.call(this, inputValue, callback)}
              components={{
                DropdownIndicator,
                Option: CustomOptionProgram,
                IndicatorSeparator: null,
              }}
              classNamePrefix="single-select"
              placeholder="Find your Program"
              onChange={data => this.onSelectProgram(data)}
              noOptionsMessage={() => 'No result'}
              inputId="assign-to-program__select-program-input"
              classNamePrefix="evfSelectBox"
              className="evfSelectBoxContainer"
            />
          </div>
        )}
      </FormGroup>
    );
  };

  renderDatetimeInput = props => {
    const { startingOn } = this.state;
    let inputClass = 'form-control';

    if (props.className) {
      inputClass += ' ' + props.className;
    }

    return (
      <div {...props} className={inputClass}>
        <Image src={`${CDN_URL}/images/new_calendar.svg`} className="calendar-icon" />
        {startingOn.format('MMM DD YYYY')}
      </div>
    );
  };

  renderStartingOn = () => {
    const { startingOn } = this.state;

    return (
      <FormGroup className="starting-on">
        <div className="label">Starting On</div>
        <Datetime
          value={startingOn}
          renderInput={this.renderDatetimeInput}
          timeFormat={false}
          closeOnSelect={true}
          onChange={date => this.setState({ startingOn: date })}
          isValidDate={current => current.isSameOrAfter(moment(), 'day')}
          renderDay={(props, currentDate) => (
            <td {...props}>
              <div className="rdt__custom-day">
                <div>{currentDate.date()}</div>
              </div>
            </td>
          )}
        />
      </FormGroup>
    );
  };

  handleSelectStartingDay = value => this.setState({ startingAt: value });

  handleSelectEndingOn = value => this.setState({ endingOn: value });

  renderAssignRange = () => {
    const { startingAt, selectedProgram, endingOn } = this.state;
    const { program } = this.props;
    const totalWeek = program ? program.workout_sets.length : selectedProgram ? selectedProgram.workout_sets.length : 0;
    const invalidDay = endingOn < startingAt;

    return (
      <DayRangeContainer invalid={invalidDay}>
        <FormGroup className="dayRangeOption">
          <div className="label">Starting At</div>
          <SelectProgramDay value={startingAt} totalWeek={totalWeek} onSelect={this.handleSelectStartingDay} />
        </FormGroup>
        {!!totalWeek && (
          <FormGroup className="dayRangeOption dayRangeOption--endDay">
            <div className="label">Ending on</div>
            <SelectProgramDay value={endingOn} totalWeek={totalWeek} onSelect={this.handleSelectEndingOn} />
          </FormGroup>
        )}
        {invalidDay && <InvalidEndDate>End date should be after the Start Date</InvalidEndDate>}
      </DayRangeContainer>
    );
  };

  render() {
    const { isModalOpen, toggleModal, program } = this.props;
    const { assignTo, submitting, selectedProgram } = this.state;

    return (
      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        className="assign-program-modal"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Button onClick={() => toggleModal(false)} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div>Assign Program</div>
        </Modal.Header>
        <Modal.Content>
          {this.renderAssignTo()}
          {this.renderProgram()}
          {this.renderStartingOn()}
          {this.renderAssignRange()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={'purple'}
            disabled={!assignTo.length || (!selectedProgram && !program)}
            onClick={this.onSubmit}
          >
            {submitting ? 'Assigning' : 'Assign'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(null, { searchClients, searchGroupClients })(AssignProgram);
