import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArchiveClientModal from './component';
import { archiveClients, getClientsFullData } from 'redux/client/client.actionCreators';
import { hideError, showError } from 'actions/error';
import { getAllSegments } from 'redux/segment/actions';

const mapDispatchToProps = dispatch => ({
  archiveClients: bindActionCreators(archiveClients, dispatch),
  showError: bindActionCreators(showError, dispatch),
  getAllSegments: bindActionCreators(getAllSegments, dispatch),
  getClientsFullData: bindActionCreators(getClientsFullData, dispatch),
  hideError: bindActionCreators(hideError, dispatch),
});

export default connect(null, mapDispatchToProps)(ArchiveClientModal);
