import React from 'react';
import styled, { css } from 'styled-components';
import UpgradePathButton from './UpgradePathButton';

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding-bottom: 10px;

  .tooltip_box {
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    white-space: normal;

    &:before {
      content: '';
      position: absolute;
      bottom: -4px;
      right: 48%;
      border: solid 1px #c4c4c461;
      width: 8px;
      height: 8px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: #fff;
      border-left-color: #fff;
      border-top-color: #fff;
    }
  }

  .tooltip__heading {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    cursor: text;

    color: #323c47;
  }

  .tooltip__description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    cursor: text;

    width: ${props => props.contentWidth || '237px'};

    color: #000000;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  button.tooltip__upgradePathBtn {
    ${props =>
      props.btnFullWidth &&
      css`
        width: 100%;
        padding: 9px 30px;
      `}

    span {
      justify-content: center;
    }
  }
`;

export default function UpgradePathTooltipContent({
  id,
  className,
  heading,
  description,
  contentWidth,
  btnFullWidth,
  onClickUpgrade,
  btnContentText = '',
  planWillSelect = {},
  isShowHeading = true,
}) {
  const handleClick = () => {
    if (typeof onClickUpgrade === 'function') {
      onClickUpgrade();
    }
  };

  return (
    <Wrapper className={className} contentWidth={contentWidth} btnFullWidth={btnFullWidth}>
      <div className="tooltip_box">
        {isShowHeading && <div className="tooltip__heading">{heading || 'Easy nutrition coaching'}</div>}
        <div className="tooltip__description">
          {description || 'Use a one-on-one food timeline to stay in sync with your client’s eating habits.'}
        </div>
        <UpgradePathButton
          className="tooltip__upgradePathBtn"
          onClick={handleClick}
          text={btnContentText}
          planWillSelect={planWillSelect}
        />
      </div>
    </Wrapper>
  );
}
