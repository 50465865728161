import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManageTags from './component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { updateTagInExercises, deleteTagInExercises } from 'redux/exercise/exercise.actionCreators';
import { changeQueryParams, createNewTag, deleteTag, editTag, getTagsList, resetQueryParams } from 'redux/tags/actions';
import { deleteTagInWorkouts, updateTagInWorkouts } from 'redux/workout_library/workout.actionCreators';
import { deleteTagInPrograms, updateTagInPrograms } from 'redux/program-library-and-templates/actions';

const mapStateToProps = state => {
  const {
    user,
    isModalOpen,
    rootReducer: { exerciseTags, workoutTags, programTags },
  } = state;
  return {
    user,
    isModalOpen,
    exerciseTags,
    workoutTags,
    programTags,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    getTagsList: bindActionCreators(getTagsList, dispatch),
    createNewTag: bindActionCreators(createNewTag, dispatch),
    editTag: bindActionCreators(editTag, dispatch),
    deleteTag: bindActionCreators(deleteTag, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
    updateTagInExercises: bindActionCreators(updateTagInExercises, dispatch),
    deleteTagInExercises: bindActionCreators(deleteTagInExercises, dispatch),
    updateTagInWorkouts: bindActionCreators(updateTagInWorkouts, dispatch),
    deleteTagInWorkouts: bindActionCreators(deleteTagInWorkouts, dispatch),
    updateTagInPrograms: bindActionCreators(updateTagInPrograms, dispatch),
    deleteTagInPrograms: bindActionCreators(deleteTagInPrograms, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageTags);
