import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ClientFilters from './component';
import { toggleModal } from 'actions/modal';
import { changeClientFilter, selectSegment } from 'redux/segment/actions';

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { segment, group } = rootReducer;
  const selectedSegment = segment.list.find(item => item._id === segment.selected);

  return {
    filters: segment.filters,
    groups: group.list,
    selectedSegment,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  changeClientFilter: bindActionCreators(changeClientFilter, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  selectSegment: bindActionCreators(selectSegment, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientFilters);
