import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import moment from 'moment';

import { Divide, Wrapper } from './style';
import { getPeriodName, arrayToString } from 'utils/commonFunction';
import TermPolicy from 'components/PricingPlan/PlanSummary/TermPolicy';
import PromoCode from './PromoCode';
import PromomotionCost from './PromomotionCost';
import Subtotal from './SubTotal';
import ProrationCost from './ProrationCost';
import { calculatePaymentObject, getAddOnsCoupon, getPriceByPeriod } from 'components/PricingPlan/helper';

function PaymentSummary(props) {
  const checkSubmitPromoCodeRef = useRef();
  const [hasPromoCode, setHasPromoCode] = useState(false);
  const { selectPackage, addOnsPricing, paymentInfo, teamData, downgrade, permission } = props;
  const price = getPriceByPeriod(selectPackage.period, selectPackage.packagePrice);
  const isCurrentZapierAmount = _.get(teamData, 'zapier_quantity', 0);
  const isSelectedZapierAmount = _.get(selectPackage, 'zapier_quantity', 0);
  const isAnnually = _.get(selectPackage, 'period', '') === 'annually' && _.get(teamData, 'period', '') === 'annually';
  const isMonthly = _.get(selectPackage, 'period', '') === 'monthly' && _.get(teamData, 'period', '') === 'monthly';
  const isDowngradeZapier = isSelectedZapierAmount < isCurrentZapierAmount;
  const isZapierMeter = _.get(permission, 'zapier_on_demand_charge');

  // Count if add-ons added
  let countAddedAddOns = 0;
  const addOnsBefore = _.get(teamData, 'addOns', []);
  const addOnsSelected = _.get(selectPackage, 'addOns', []);
  for (const key in _.get(teamData, 'addOns', [])) {
    if (addOnsBefore.hasOwnProperty(key) && addOnsSelected.hasOwnProperty(key)) {
      if (addOnsBefore[key] === false && addOnsSelected[key] === true) {
        countAddedAddOns++;
      }
    }
  }

  // Keep current base plan and upgrade only zapier plan
  const isDowngradeZapierKeepBasePlan =
    isSelectedZapierAmount !== isCurrentZapierAmount &&
    isMonthly &&
    !isAnnually &&
    _.isEqual(_.get(selectPackage, 'addOns', []), _.get(teamData, 'addOns', [])) &&
    _.get(selectPackage, 'packagePrice.packageNoClients', false) === _.get(teamData, 'packageNoClients', false);

  // Downgrade base plan and downgrade Zapier annually
  const isBothZapAndBase =
    _.get(selectPackage, 'will_schedule') === true && _.get(selectPackage, 'will_zapier_schedule') === true;
  const isDowngradeAnnually = () => {
    if (isBothZapAndBase) {
      return false; // Return false if isBothZapAndBase is true.
    } else {
      const isDowngrade =
        (_.get(selectPackage, 'will_schedule', false) ||
          (isDowngradeZapier &&
            countAddedAddOns === 0 &&
            _.get(selectPackage, 'packagePrice.packageNoClients', false) ===
              _.get(teamData, 'packageNoClients', false))) &&
        isAnnually &&
        !isMonthly;

      return isDowngrade;
    }
  };

  // Downgrade base plan but upgrade Zapier for case: Total due of new plan > current plan
  const newTotalDueIsGreater =
    _.get(selectPackage, 'will_schedule') === false &&
    _.get(selectPackage, 'will_zapier_schedule') === false &&
    isSelectedZapierAmount > isCurrentZapierAmount &&
    _.get(selectPackage, 'packagePrice.packageNoClients', false) <= _.get(teamData, 'packageNoClients', false) &&
    countAddedAddOns === 0 &&
    isAnnually;

  // Remove zapier when on Annually
  const removeZapierOnAnnually =
    _.get(teamData, 'addOns.zapier', false) === true &&
    _.get(selectPackage, 'addOns.zapier', false) === false &&
    isAnnually &&
    countAddedAddOns === 0;

  const isZapierChangedOnAnnualToMonthly =
    isSelectedZapierAmount !== isCurrentZapierAmount &&
    _.get(selectPackage, 'period', '') === 'monthly' &&
    _.get(teamData, 'period', '') === 'annually';

  let expiredDate;
  const isScheduled =
    ((downgrade && teamData.period === 'annually') ||
      isDowngradeZapierKeepBasePlan ||
      isDowngradeAnnually() ||
      removeZapierOnAnnually ||
      isZapierChangedOnAnnualToMonthly) &&
    !newTotalDueIsGreater;

  if (isScheduled) {
    expiredDate = moment(teamData.expiredDate).format('MMM D, YYYY');
  }

  const paymentObject = calculatePaymentObject(
    selectPackage,
    addOnsPricing,
    paymentInfo,
    teamData,
    downgrade,
    isScheduled,
  );

  const oneTimeAddOnsPricing = addOnsPricing.filter(o => o.isOneTime);
  const recurringAddOnsPricing = addOnsPricing.filter(o => !o.isOneTime);
  const addOns = getAddOnsCoupon(selectPackage, recurringAddOnsPricing);

  const handleCheckPromoCode = () => {
    if (checkSubmitPromoCodeRef.current) checkSubmitPromoCodeRef.current();
  };

  function renderTags(tags) {
    const updatedTags = arrayToString(tags);
    return updatedTags === 'ADD-ON'
      ? 'Add-on'
      : updatedTags === 'API PLUGIN'
      ? `${_.get(selectPackage, 'zapier_quantity', false).toLocaleString('en-US')} tasks`
      : updatedTags;
  }

  return (
    <div className={props.className}>
      <Wrapper>
        <div className="paymentSummary__heading">
          <span>Payment Summary</span>
        </div>
        <div className="paymentSummary__body">
          <div className="paymentSummary__selectedPlan">
            <div>
              <span className="paymentSummary__selectedPlan--name">{`${_.upperFirst(selectPackage.tier)} Plan`}</span>
              {price > 0 && (
                <span className="paymentSummary__selectedPlan--client">
                  {' '}
                  - {_.get(selectPackage, 'packagePrice.packageNoClients', 0)} clients
                </span>
              )}
            </div>
            <div>
              <div className="paymentSummary__selectedPlan--price">
                {price > 0 ? `${price.toUSDFormat(false)}` : 'Free'}
              </div>
              {price > 0 && (
                <div className="paymentSummary__selectedPlan--period">/{getPeriodName(selectPackage.period)}</div>
              )}
            </div>
          </div>
          {recurringAddOnsPricing.map(
            addOn =>
              addOns[addOn.code] &&
              price > 0 && (
                <div className="paymentSummary__selectedPlan">
                  <div className={classNames({ paymentSummary__oneTimeDes: addOn.isOneTime })}>
                    <span className="paymentSummary__planName paymentSummary__planName--automation">{addOn.title}</span>
                    <span className="paymentSummary__selectedPlan--client">
                      {''} - {renderTags(_.get(addOn, 'tags', []))}
                    </span>
                    {addOn.isMeter && (
                      <>
                        {addOn.code === 'zapier' && !isZapierMeter ? null : (
                          <div className="paymentSummary__selectedPlan--addOnsDes">
                            Additional monthly cost based on usage
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className={classNames({ paymentSummary__oneTimePrice: addOn.isOneTime })}>
                    <div className="paymentSummary__selectedPlan--price">
                      {getPriceByPeriod(selectPackage.period, addOn.basePrice).toUSDFormat(false)}
                    </div>
                    {addOn.isOneTime ? (
                      <div className="paymentSummary__selectedPlan--period">One time</div>
                    ) : (
                      <div className="paymentSummary__selectedPlan--period">/{getPeriodName(selectPackage.period)}</div>
                    )}
                  </div>
                </div>
              ),
          )}
        </div>
        <Subtotal subTotal={paymentObject.subTotal - paymentObject.oneTimeCost} period={selectPackage.period} />
        <div className="paymentSummary__totalSumary">
          {paymentObject.proratedSubtotal !== paymentObject.unusedCost && (
            <ProrationCost
              cost={paymentObject.proratedSubtotal}
              label="Prorated subtotal"
              description="Until end of billing cycle"
            />
          )}
          <PromomotionCost promoAmount={paymentObject.promoAmount} />
          {paymentObject.proratedSubtotal !== paymentObject.unusedCost && (
            <ProrationCost
              cost={paymentObject.unusedCost}
              label="Credit for current plan"
              description="Until end of billing cycle"
              minus={true}
            />
          )}
          {!!paymentObject.oneTimeCost && (!!paymentObject.proratedSubtotal || !!paymentObject.unusedCost) && (
            <Divide />
          )}
          {!!paymentObject.oneTimeCost && (
            <div className="paymentSummary__oneTimeAddOns">
              {oneTimeAddOnsPricing.map(
                addOn =>
                  selectPackage.addOns[addOn.code] &&
                  !teamData.oneTimeAddOns[addOn.code] &&
                  price > 0 && (
                    <div className="paymentSummary__selectedPlan">
                      <div className={classNames({ paymentSummary__oneTimeDes: addOn.isOneTime })}>
                        <span className="paymentSummary__planName paymentSummary__planName--automation">
                          {addOn.title}
                        </span>
                        <span className="paymentSummary__selectedPlan--client"> - Add-on</span>
                      </div>
                      <div className={classNames({ paymentSummary__oneTimePrice: addOn.isOneTime })}>
                        <div className="paymentSummary__selectedPlan--price">
                          ${getPriceByPeriod(selectPackage.period, addOn.basePrice).toFixed(2)}
                        </div>
                        <div className="paymentSummary__selectedPlan--period">One time</div>
                      </div>
                    </div>
                  ),
              )}
            </div>
          )}
          {paymentObject.payFromBalance > 0 && (
            <div className="paymentSummary__subTotal">
              <div className="paymentSummary__subTotal--left-content">
                <span className="paymentSummary__subTotal--name">Credit balance</span>
                <div
                  className="paymentSummary__creditToAccount--tootipIcon"
                  data-tip
                  data-for="paymentSummary__payFromBalance"
                >
                  i
                </div>
                <ReactTooltip
                  className="app-tooltip payment-summary-tootip"
                  id="paymentSummary__payFromBalance"
                  place="top"
                  effect="solid"
                >
                  The amount will be deducted from your Everfit credit balance.
                </ReactTooltip>
              </div>
              <div>
                <div className="paymentSummary__subTotal--price paymentSummary__subTotal--credit">
                  -&nbsp;{Number(paymentObject.payFromBalance).toUSDFormat(false)}
                </div>
              </div>
            </div>
          )}
          {paymentObject.creditToAccount > 0 && (
            <div className="paymentSummary__creditToAccount">
              <div className="paymentSummary__creditToAccount--leftContent">
                <span className="paymentSummary__creditToAccount--name">Credit to account</span>
                <div
                  className="paymentSummary__creditToAccount--tootipIcon"
                  data-tip
                  data-for="paymentSummary__creditToAccount"
                >
                  i
                </div>
                <ReactTooltip
                  className="app-tooltip payment-summary-tootip"
                  id="paymentSummary__creditToAccount"
                  place="top"
                  effect="solid"
                >
                  The remaining value will be added to your Everfit credit balance.
                </ReactTooltip>
              </div>
              <div>
                <strong className="paymentSummary__creditToAccount--price">
                  +&nbsp;{Number(paymentObject.creditToAccount).toUSDFormat(false)}
                </strong>
              </div>
            </div>
          )}
        </div>
        <PromoCode
          hasPromoCode={hasPromoCode}
          setHasPromoCode={setHasPromoCode}
          checkSubmitPromoCodeRef={checkSubmitPromoCodeRef}
        />
        <div className="paymentSummary__totalDue">
          <div className="paymentSummary__totalDue--name">
            {expiredDate ? (
              <>
                <div className="paymentSummary__totalDue--text">Total due</div>
                <div className="paymentSummary__totalDue--day">on {expiredDate}</div>
              </>
            ) : (
              <div className="paymentSummary__totalDue--today">Total due today</div>
            )}
          </div>
          <div className="paymentSummary__totalDue--price">{Number(paymentObject.totalDue).toUSDFormat(false)}</div>
        </div>
        <div className="paymentSummary__footer">
          <button
            onClick={hasPromoCode ? handleCheckPromoCode : props.onConfirm}
            disabled={props.invalidCard}
            className="paymentSummary__confirmBtn"
          >
            Confirm Payment
          </button>
          <h5>Subscription renews automatically</h5>
        </div>
      </Wrapper>
      <TermPolicy />
    </div>
  );
}

const mapState = state => {
  const { rootReducer } = state;

  return {
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    paymentInfo: rootReducer.billing.paymentInfo,
    permission: rootReducer.permission,
  };
};

const actionCreators = {
  push,
};

export default connect(mapState, actionCreators)(PaymentSummary);
