import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import round from 'lodash/round';

export const convertDataCSVFile = data => {
  const dataConvert = data.map(row => {
    const { Ingredients: name = '' } = row;
    const default_unit = row['Default Unit'] || '';
    const amount_per = row['Amount Per'] || '';
    const category = row['Category'] || '';

    const protein = row['Protein (g)'] || '';
    const carbs = row['Carbs (g)'] || '';
    const fat = row['Fat (g)'] || '';

    const fiber = row['Fiber (g)'] || '';
    const sugar = row['Sugar (g)'] || '';
    const cholesterol = row['Cholesterol (mg)'] || '';
    const saturated_fat = row['Saturated Fat (g)'] || '';
    const calcium = row['Calcium (mg)'] || '';
    const iron = row['Iron (mg)'] || '';
    const sodium = row['Sodium (mg)'] || '';
    const magnesium = row['Magnesium (mg)'] || '';
    const added_sugars = row['Added Sugars (g)'] || '';
    const chloride = row['Chloride (mg)'] || '';
    const chromium = row['Chromium (mcg)'] || '';
    const copper = row['Copper (mg)'] || '';
    const iodine = row['Iodine (mcg)'] || '';
    const manganese = row['Manganese (mg)'] || '';
    const molybdenum = row['Molybdenum (mcg)'] || '';
    const phosphorus = row['Phosphorus (mg)'] || '';
    const potassium = row['Potassium (mg)'] || '';
    const selenium = row['Selenium (mcg)'] || '';
    const trans_fat = row['Trans Fat (g)'] || '';
    const vitamin_a = row['Vitamin A (mcg)'] || '';
    const thiamine = row['Vitamin B1 (Thiamine) (mg)'] || '';
    const riboflavin = row['Vitamin B2 (Riboflavin) (mg)'] || '';
    const niacin = row['Vitamin B3 (Niacin) (mg)'] || '';
    const pantothenic_acid = row['Vitamin B5 (mg)'] || '';
    const pyridoxine = row['Vitamin B6 (Pyridoxine) (mg)'] || '';
    const biotin = row['Vitamin B7 (Biotin) (mcg)'] || '';
    const folate = row['Vitamin B9 (Folate) (mcg)'] || '';
    const cyanocobalamin = row['Vitamin B12 (mcg)'] || '';
    const vitamin_c = row['Vitamin C (mg)'] || '';
    const vitamin_d = row['Vitamin D (mcg)'] || '';
    const vitamin_e = row['Vitamin E (mg)'] || '';
    const vitamin_k = row['Vitamin K (mcg)'] || '';
    const zinc = row['Zinc (mg)'] || '';

    const valueCalories =
      (convertValueNutrient(protein) * 4 || 0) +
      (convertValueNutrient(carbs) * 4 || 0) +
      (convertValueNutrient(fat) * 9 || 0);

    const macro_nutrients = [
      {
        type: 'calories',
        value: round(valueCalories, 2),
      },
      {
        type: 'protein',
        value: convertValueNutrient(protein),
      },
      {
        type: 'carbs',
        value: convertValueNutrient(carbs),
      },
      {
        type: 'fat',
        value: convertValueNutrient(fat),
      },
    ];

    const other_nutrients = [
      {
        type: 'fiber',
        value: convertValueNutrient(fiber),
      },
      {
        type: 'sugar',
        value: convertValueNutrient(sugar),
      },
      {
        type: 'cholesterol',
        value: convertValueNutrient(cholesterol),
      },
      {
        type: 'saturated_fat',
        value: convertValueNutrient(saturated_fat),
      },
      {
        type: 'calcium',
        value: convertValueNutrient(calcium),
      },
      {
        type: 'iron',
        value: convertValueNutrient(iron),
      },
      {
        type: 'sodium',
        value: convertValueNutrient(sodium),
      },
      {
        type: 'magnesium',
        value: convertValueNutrient(magnesium),
      },
      {
        type: 'added_sugars',
        value: convertValueNutrient(added_sugars),
      },
      {
        type: 'chloride',
        value: convertValueNutrient(chloride),
      },
      {
        type: 'chromium',
        value: convertValueNutrient(chromium),
      },
      {
        type: 'copper',
        value: convertValueNutrient(copper),
      },
      {
        type: 'iodine',
        value: convertValueNutrient(iodine),
      },
      {
        type: 'manganese',
        value: convertValueNutrient(manganese),
      },
      {
        type: 'molybdenum',
        value: convertValueNutrient(molybdenum),
      },
      {
        type: 'phosphorus',
        value: convertValueNutrient(phosphorus),
      },
      {
        type: 'potassium',
        value: convertValueNutrient(potassium),
      },
      {
        type: 'selenium',
        value: convertValueNutrient(selenium),
      },
      {
        type: 'trans_fat',
        value: convertValueNutrient(trans_fat),
      },
      {
        type: 'vitamin_a',
        value: convertValueNutrient(vitamin_a),
      },
      {
        type: 'thiamine',
        value: convertValueNutrient(thiamine),
      },
      {
        type: 'riboflavin',
        value: convertValueNutrient(riboflavin),
      },
      {
        type: 'niacin',
        value: convertValueNutrient(niacin),
      },
      {
        type: 'pantothenic_acid',
        value: convertValueNutrient(pantothenic_acid),
      },
      {
        type: 'pyridoxine',
        value: convertValueNutrient(pyridoxine),
      },
      {
        type: 'biotin',
        value: convertValueNutrient(biotin),
      },
      {
        type: 'folate',
        value: convertValueNutrient(folate),
      },
      {
        type: 'cyanocobalamin',
        value: convertValueNutrient(cyanocobalamin),
      },
      {
        type: 'vitamin_c',
        value: convertValueNutrient(vitamin_c),
      },
      {
        type: 'vitamin_d',
        value: convertValueNutrient(vitamin_d),
      },
      {
        type: 'vitamin_e',
        value: convertValueNutrient(vitamin_e),
      },
      {
        type: 'vitamin_k',
        value: convertValueNutrient(vitamin_k),
      },
      {
        type: 'zinc',
        value: convertValueNutrient(zinc),
      },
    ];

    return {
      name: name.trim(),
      category: category.trim(),
      default_unit: default_unit.trim(),
      amount_per: amount_per.trim(),
      macro_nutrients,
      other_nutrients,
    };
  });

  return dataConvert.filter(
    ({ name, category, default_unit, amount_per }) => !(!name && isEmpty(category) && !default_unit && !amount_per),
  );
};

export const findAllowUnit = (categories = [], category = '') => {
  const dataCategory =
    categories.find(item => ((item || {}).name || '').toLowerCase() === (category || '').toLowerCase()) || {};
  const servingSizes = get(dataCategory, 'serving_sizes', []) || [];
  return servingSizes.map(item => `${(item || {}).value} ${(item || {}).unit}`);
};

export const checkNutrientData = data => {
  return data.some(item => (item || {}).value > 0);
};

export const HEADER_CSV_FILE = [
  'Ingredients',
  'Category',
  'Default Unit',
  'Amount Per',
  'Protein (g)',
  'Carbs (g)',
  'Fat (g)',
  'Fiber (g)',
  'Sugar (g)',
  'Cholesterol (mg)',
  'Saturated Fat (g)',
  'Calcium (mg)',
  'Iron (mg)',
  'Sodium (mg)',
  'Magnesium (mg)',
  'Added Sugars (g)',
  'Chloride (mg)',
  'Chromium (mcg)',
  'Copper (mg)',
  'Iodine (mcg)',
  'Manganese (mg)',
  'Molybdenum (mcg)',
  'Phosphorus (mg)',
  'Potassium (mg)',
  'Selenium (mcg)',
  'Trans Fat (g)',
  'Vitamin A (mcg)',
  'Vitamin B1 (Thiamine) (mg)',
  'Vitamin B2 (Riboflavin) (mg)',
  'Vitamin B3 (Niacin) (mg)',
  'Vitamin B5 (mg)',
  'Vitamin B6 (Pyridoxine) (mg)',
  'Vitamin B7 (Biotin) (mcg)',
  'Vitamin B9 (Folate) (mcg)',
  'Vitamin B12 (mcg)',
  'Vitamin C (mg)',
  'Vitamin D (mcg)',
  'Vitamin E (mg)',
  'Vitamin K (mcg)',
  'Zinc (mg)',
];

export const listIngredientValid = list => {
  return list.every(({ name, category, default_unit }) => name && category && default_unit);
};

export const convertValueNutrient = number => {
  if (!number.trim()) return '';
  const numberConvert = round(Number(number.trim()), 2);
  return numberConvert <= 9999.99 ? numberConvert : NaN;
};

export const checkInvalidNutrient = data => {
  return data.some(item => isNaN((item || {}).value));
};

export const isAmountPerValid = (amountPer = '', categoryIngredient, category) => {
  const normalizedAmountPer = (amountPer || '').toLowerCase();
  return findAllowUnit(categoryIngredient, category).includes(normalizedAmountPer);
};

export const splitFirstSpace = str => {
  const index = str.indexOf(' ');
  if (index === -1) {
    return [str];
  }
  return [str.slice(0, index), str.slice(index + 1)];
};
