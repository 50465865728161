import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import {
  getGroupDetail,
  getListPost,
  getAllListPost,
  getListUser,
  getConnectedLeaderboard,
  getListSchedulePost,
  loadMoreScheduledPost,
  resetDisableLoadMoreSchedulePosts,
} from 'redux/forum/actions';
import { toggleModal } from 'actions/modal';
import { toggleConfirmModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';

import UpcomingTab from './component';

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    group: state.rootReducer.forum.group,
    schedulePosts: state.rootReducer.forum.schedulePosts,
    totalScheduledPost: state.rootReducer.forum.totalScheduledPost,
    loading: state.rootReducer.forum.loading,
    members: state.rootReducer.forum.members,
    permission: state.rootReducer.permission,
    isEnd: state.rootReducer.forum.query.isEnd,
    tab: state.rootReducer.forum.query.tab,
    page: state.rootReducer.forum.query.page,
    firstLoadSchedulePosts: state.rootReducer.forum.firstLoadSchedulePosts,
    enabledAddons: state.rootReducer.pricing.getIn(['teamData']).toJS(),
    schedulePostsLoading: state.rootReducer.forum.schedulePostsLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const forumId = get(ownProps, 'match.params.forumId') || get(ownProps, 'match.params.id');
  return {
    push: url => dispatch(push(url)),
    getGroupDetail: () => dispatch(getGroupDetail(forumId)),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getListPost: () => dispatch(getListPost(forumId)),
    getListSchedulePost: params => dispatch(getListSchedulePost(forumId, params)),
    loadMoreScheduledPost: params => dispatch(loadMoreScheduledPost(forumId, params)),
    getAllListPost: () => dispatch(getAllListPost(forumId)),
    getListUser: () => dispatch(getListUser(forumId)),
    getConnectedLeaderboard: () => dispatch(getConnectedLeaderboard(forumId)),
    toggleSideBar: visible => dispatch(toggleSideBar(visible)),
    resetDisableLoadMoreSchedulePosts: bindActionCreators(resetDisableLoadMoreSchedulePosts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpcomingTab));
