import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button } from 'shared/FormControl';
import SelectSection from 'components/SelectSection';

import { CDN_URL } from 'constants/commonData';

import ChooseSectionFormatForm from './ChooseSectionFormatForm';
import SectionForm from './SectionForm';

import { addSection, adddSectionFromLibrary } from 'redux/workout-builder/actions';

import * as S from './style';

function getFormatName(format) {
  switch (format) {
    case 'amrap':
      return 'AMRAP';
    default:
      return _.upperFirst(format);
  }
}

const AddSectionForm = props => {
  const [step, setStep] = useState(1);
  const [format, setFormat] = useState();
  const [selectFromLib, setSelectFromLib] = useState(false);

  const handleChangeFormat = value => {
    setFormat(value);
    setStep(2);
  };

  const handleSubmit = params => {
    props.addSection(params);
    props.onAddSectionSuccess();
  };

  const onSelectFromLib = () => {
    setSelectFromLib(true);
  };

  const onCloseSelectSectionPopup = () => {
    setSelectFromLib(false);
  };

  const onSelectSection = data => {
    props.adddSectionFromLibrary(data);
    props.onAddSectionSuccess();
  };

  return (
    <S.Wrapper className="section__addSectionWrapper">
      <S.Heading>
        <div>
          {step === 2 && (
            <img
              className="add-section-form__back-icon"
              src={`${CDN_URL}/images/arrow_back_purple_new.svg`}
              alt="Back"
              onClick={() => setStep(1)}
            />
          )}
          {step === 1 ? 'Choose your section format' : `Create ${getFormatName(format)} Section`}
        </div>
        {step === 1 && (
          <Button className="button--chooseFromLib" onClick={onSelectFromLib}>
            Choose from library
          </Button>
        )}
      </S.Heading>
      <S.Body>
        {step === 1 ? (
          <ChooseSectionFormatForm
            onSelect={handleChangeFormat}
            onCancel={props.onAddedSection}
            allowUseFreeSection={props.allowUseFreeSection}
          />
        ) : (
          <SectionForm format={format} onSubmit={handleSubmit} setStep={setStep} />
        )}
      </S.Body>
      {selectFromLib && <SelectSection onSelect={onSelectSection} open={true} onClose={onCloseSelectSectionPopup} />}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  const isWhiteLabelAccount = _.find(user.teams, item => item.team.white_label);

  const allowUseFreeSection = user.is_using_freestyle_section || process.env.REACT_APP_ENABLE_FREESTYLE_SECTION;
  return {
    allowUseFreeSection,
  };
};

const actionCreators = {
  addSection,
  adddSectionFromLibrary,
};

export default connect(mapStateToProps, actionCreators)(AddSectionForm);
