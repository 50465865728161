import React, { useEffect, useMemo, useState } from 'react';
import mapKeys from 'lodash/mapKeys';
import sumBy from 'lodash/sumBy';
import { connect } from 'react-redux';
import moment from 'moment';

import { ReactComponent as DragIcon } from 'assets/icons/drag-icon-2.svg';

import StepChartOverview from './StepChart';
import { roundNumberBodyMetric } from 'utils/commonFunction';
import { DATE_FORMAT } from 'constants/commonData';
import { axiosInstance } from 'configs/request';

import { Wrapper } from './style';

const StepChartOverviewNew = props => {
  const { name, isDraggable = true, isDefaultStep, client, timezone, uniqueCode, isGroupOverview, metricId } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentDate = moment();
    const fromDate = moment(currentDate).subtract(1, 'week').add(1, 'day');
    const endpoint = isDefaultStep
      ? '/api/body-metric-entry/steps-metric-data'
      : '/api/v2/body-metric-entry/chart-data-group-by-day';

    axiosInstance
      .get(endpoint, {
        params: {
          fromDate: fromDate.format(DATE_FORMAT),
          toDate: currentDate.format(DATE_FORMAT),
          client,
          timezone,
          metric_code: uniqueCode,
        },
      })
      .then(response => {
        const { data } = response.data;
        let day = fromDate;
        const chartDataObj = mapKeys(data, 'day');
        const chartData = [];

        while (day.isSameOrBefore(currentDate, 'day')) {
          const obj = chartDataObj[day.format(DATE_FORMAT)] || { value: 0 };
          chartData.push({ ...obj, label: day.format('ddd') });
          day.add(1, 'day');
        }
        setData(chartData);
        setLoading(false);
      });
  }, [client]);

  const average = useMemo(() => {
    const value = !data.length ? 0 : parseInt(sumBy(data, 'value') / data.length);
    return roundNumberBodyMetric(value, 1);
  }, [data]);

  return (
    <Wrapper className="step-chart-overview">
      <div className="header">
        <div className="title">{name || 'Steps'}</div>
        {isDraggable && <DragIcon className="move-icon" />}
        <div className="header-value">
          <div className="value">{average || <span className="dash">—</span>}</div>
          {average !== 0 && <div className="description">Average last {data.length} days</div>}
        </div>
      </div>
      <div className="chart-container">
        <StepChartOverview
          height={isGroupOverview ? 200 : 120}
          minHeight={isGroupOverview ? 200 : 120}
          width={400}
          isLoading={loading}
          chartData={data}
          chartId={metricId}
        />
      </div>
    </Wrapper>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: {
      client: { workingClientDetail },
    },
  } = state;

  return {
    client: workingClientDetail._id,
    timezone: user.timezone,
  };
};

export default connect(mapState)(StepChartOverviewNew);
