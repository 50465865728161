import { Types, markReadForumIndicator } from './actions';
import { MEDIA_FILE_REJECTED_CONTENT } from 'constants/commonData';
import _ from 'lodash';

const PER_PAGE = 5;
const INITITAL_STATE = {
  list: [],
  group: {},
  groupSettings: {},
  connectedLeaderboard: [],
  posts: [],
  members: [],
  query: {
    lastPostId: '',
    perPage: PER_PAGE,
    page: 1,
    isEnd: false,
    tab: 'all',
  },
  leaderboardSetting: [],
  formDataSchedule: [],
  schedulePosts: [],
  totalScheduledPost: 0,
  loading: false,
  firstLoadPosts: false,
  firstLoadSchedulePosts: false,
  postsLoading: false,
  schedulePostsLoading: false,
};

const likePost = (oldState, payload) => {
  const { members } = oldState;
  const { postId, userId } = payload;

  const { posts } = oldState;
  const newPosts = posts.slice();
  const index = _.findIndex(newPosts, item => item._id === postId);
  const likerInfoIndex = _.findIndex(newPosts[index].liker_info, item => item._id === userId);
  const user = _.find(members, item => item._id === userId);

  if (index !== -1) {
    newPosts[index].liker.push(userId);
    const likerInfo = newPosts[index].liker.slice(0, 2).map(liker => _.find(members, ['_id', liker])) || [];

    if (user) {
      newPosts[index].liker_info
        ? likerInfoIndex !== -1
          ? newPosts[index].liker_info.splice(likerInfoIndex, 1, user)
          : newPosts[index].liker_info.push(user)
        : (newPosts[index].liker_info = likerInfo);
    }
  }

  return Object.assign({}, oldState, { posts: newPosts });
};

const unlikePost = (oldState, payload) => {
  const { postId, userId } = payload;

  const { posts } = oldState;
  const newPosts = posts.slice();
  const index = _.findIndex(newPosts, item => item._id === postId);
  const likerIndex = _.findIndex(newPosts[index].liker, item => item === userId);
  const likerInfoIndex = _.findIndex(newPosts[index].liker_info, item => item._id === userId);

  if (index !== -1) {
    newPosts[index].liker.splice(likerIndex, 1);
    likerInfoIndex !== -1 && newPosts[index].liker_info.splice(likerInfoIndex, 1);
  }

  return Object.assign({}, oldState, { posts: newPosts });
};

const updatePostsAfterViewDetail = (oldState, payload) => {
  const { posts } = oldState;

  if (!posts.length) {
    return oldState;
  }

  const { postId, data } = payload;
  const newPosts = posts.slice();
  const index = _.findIndex(newPosts, item => item._id === postId);

  if (index !== -1) {
    newPosts[index] = { ...newPosts[index], ...data };
  }

  return Object.assign({}, oldState, { posts: newPosts });
};

const forum = (state = INITITAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case Types.FORUM_GET_LIST_SUCCESS:
      return { ...state, list: payload.data };
    case Types.SELECT_GROUP:
      return { ...state, group: _.merge(state.group, payload.data) };
    case Types.ADD_NEW_GROUP_SUCCESS:
      return { ...state, group: payload.data, list: [...state.list, payload.data], loading: false };
    case Types.GET_GROUP_DETAIL_SUCCESS:
      return { ...state, group: payload.data, loading: false };
    case Types.UPDATE_GROUP_SUCCESS:
      const groupId = _.get(action, 'payload.groupId', '');
      const banner = _.get(action, 'payload.banner', '');
      const updatedList = state.list.map(group => {
        if (group._id === groupId && banner) {
          return {
            ...group,
            banner: banner,
            banner_thumbnail: banner,
          };
        }

        return group;
      });

      return { ...state, group: _.merge(state.group, action.payload), list: updatedList };
    case Types.GET_LIST_GROUP_SUCCESS: {
      let currentGroupID = _.get(window, 'location.pathname', '');
      let currentList = payload.data;

      currentGroupID = currentGroupID.replace('/home/forums/', '').replace('/discussion', '');

      _.forEach(currentList, (item, index) => {
        if (currentGroupID === item._id) {
          currentList[index].isUnread = false;
        }
      });
      return { ...state, list: currentList, loading: false };
    }
    case Types.REMOVE_GROUP_SUCCESS: {
      return {
        ...state,
        posts: [],
        schedulePosts: [],
        disableLoadMorePosts: false,
        list: state.list.filter(group => group._id !== payload.data),
        query: INITITAL_STATE.query,
      };
    }
    case Types.GET_LIST_POST_SUCCESS: {
      const { data } = payload;
      const lastId = data.length > 0 ? data[data.length - 1]._id : '';
      if (data.length > 0) {
        return {
          ...state,
          posts: _.uniqBy([...state.posts, ...data], '_id'),
          query: {
            ...state.query,
            lastPostId: lastId,
          },
        };
      }
      return { ...state, firstLoadPosts: false };
    }
    case Types.GET_LIST_POST_REQUEST: {
      return {
        ...state,
        firstLoadPosts: true,
      };
    }
    case Types.RESET_LOAD_POSTS_QUERY_REDUX: {
      return {
        ...state,
        posts: [],
        schedulePosts: [],
        members: [],
        query: {
          ...state.query,
          lastPostId: '',
          page: 1,
          tab: 'all',
        },
        formDataSchedule: [],
        disableLoadMorePosts: false,
        disableLoadMoreSchedulePosts: false,
      };
    }
    case Types.RESET_FORUM_DATA_REDUX: {
      return {
        ...INITITAL_STATE,
        list: state.list,
      };
    }
    case Types.ADD_MEMBER_SUCCESS:
      return { ...state, members: [...state.members, payload.data] };
    case Types.GET_LIST_USER_SUCCESS:
      return { ...state, members: payload.data };
    case Types.LIKE_POST_SUCCESS:
      return likePost(state, payload);
    case Types.UNLIKE_POST_SUCCESS:
      return unlikePost(state, payload);
    case Types.DELETE_POST_SUCCESS: {
      const posts = _.filter(state.posts, item => item._id !== payload.postId);
      const isNormalPost = _.find(state.posts, item => item._id === payload.postId && item.status !== 'IN_SCHEDULE');

      const schedulePosts = _.filter(state.schedulePosts, item => item._id !== payload.postId);
      let totalScheduledPost = state.totalScheduledPost;
      if (payload.tab === 'all') {
        totalScheduledPost = schedulePosts.length;
      } else {
        totalScheduledPost = isNormalPost ? state.totalScheduledPost : state.totalScheduledPost - 1;
      }

      let query = {
        ...state.query,
        lastPostId: '',
      };
      if (posts.length > 0) {
        const lastId = posts[posts.length - 1]._id;
        query.lastPostId = lastId;
      }
      return Object.assign({}, state, { posts, query, schedulePosts, totalScheduledPost: totalScheduledPost });
    }
    case Types.UPLOAD_ATTACHMENT_SUCCESS:
      return state;
    case Types.ADD_POST_SUCCESS:
      const lastPinnedPostIndex = _.findLastIndex(state.posts, post => post.isPin);
      let newPosts = _.cloneDeep(state.posts);

      newPosts.splice(lastPinnedPostIndex + 1, 0, payload.data);
      return {
        ...state,
        posts: newPosts,
        loading: false,
      };

    case Types.ADD_POST_FAIL:
    case Types.EDIT_POST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case Types.ADD_SCHEDULED_POST_SUCCESS: {
      const lastScheduledPostIndex = _.findLastIndex(state.schedulePosts, post => post.isPin);
      let newScheduledPosts = _.cloneDeep(state.schedulePosts);
      let newTotalScheduledPost = payload.totalScheduledPost + 1;

      newScheduledPosts.splice(lastScheduledPostIndex + 1, 0, payload.data);

      return {
        ...state,
        schedulePosts: newScheduledPosts,
        totalScheduledPost: newTotalScheduledPost,
        loading: false,
        group: { ...state.group, is_hide_schedule_post_banner: true },
      };
    }
    case Types.GET_POST_DETAIL_SUCCESS: {
      const isUnread = _.get(state, 'group.isUnread', false);
      const groupID = _.get(state, 'group._id', '');

      if (isUnread) markReadForumIndicator(groupID);
      return {
        ...state,
        posts: _.uniqBy([...state.posts, payload], '_id'),
      };
    }
    case Types.EDIT_POST_SUCCESS: {
      const data = _.get(payload, 'data', null);
      const currentTab = _.get(payload, 'tab', null);
      const dataSchedule = _.get(payload, 'data.status', null);
      let newSchedulePosts = _.cloneDeep(state.schedulePosts);
      const postId = data._id;

      // Edit scheduled post
      if (dataSchedule !== 'PUBLISHED') {
        newSchedulePosts = newSchedulePosts
          .map(it => (it._id === postId ? { ...it, ..._.omit(data, 'leaderboard_snapshot') } : it))
          .filter(it => it.status !== 'PUBLISHED');

        return {
          ...state,
          schedulePosts: newSchedulePosts,
          totalScheduledPost: state.totalScheduledPost,
          query: { ...state.query, tab: currentTab },
          loading: false,
        };
      }

      // Edit normal post
      let newPosts = _.cloneDeep(state.posts);
      const isNormalPost = _.find(newPosts, it => _.get(it, '_id', '') === postId);
      const isReschedulePost = _.get(data, 'schedule_time', '');

      if (isReschedulePost) {
        newPosts.unshift(_.omit(data, 'leaderboard_snapshot'));
        newPosts = _.uniqBy(newPosts, '_id');
      } else {
        newPosts = newPosts.map(it => (it._id === postId ? { ...it, ..._.omit(data, 'leaderboard_snapshot') } : it));
      }

      newSchedulePosts = newSchedulePosts
        .map(it => (it._id === postId ? { ...it, ..._.omit(data, 'leaderboard_snapshot') } : it))
        .filter(it => it.status !== 'PUBLISHED');

      return {
        ...state,
        posts: newPosts,
        schedulePosts: newSchedulePosts,
        totalScheduledPost: isNormalPost ? state.totalScheduledPost : state.totalScheduledPost - 1,
        query: { ...state.query, tab: currentTab },
        loading: false,
      };
    }
    case Types.PIN_POST_SUCCESS:
    case Types.UNPIN_POST_SUCCESS: {
      let newPosts = _.cloneDeep(state.posts);
      newPosts = newPosts.map(it => {
        if (it._id === payload.postId) {
          it.isPin = !it.isPin;
        }
        return it;
      });
      return { ...state, posts: newPosts };
    }
    case Types.FORUM_UPDATE_POSTS_AFTER_VIEW_DETAIL:
      return updatePostsAfterViewDetail(state, payload);
    case Types.REMOVE_MEMBER_SUCCESS:
      return { ...state, members: _.filter(state.members, item => item._id !== payload) };
    case Types.GET_GROUP_SETTINGS_SUCCESS:
      return Object.assign({}, state, { groupSettings: payload });
    case Types.UPDATE_GROUP_SETTINGS_SUCCESS:
      const newGroupSetting = Object.assign({}, state.groupSettings, payload);
      return { ...state, groupSettings: newGroupSetting };
    case Types.GET_CONNECTED_LEADERBOARD_SUCCESS:
      return Object.assign({}, state, { connectedLeaderboard: payload });
    case Types.LEAVE_GROUP_SUCCESS:
      return Object.assign({}, state, {
        posts: [],
        schedulePosts: [],
        disableLoadMorePosts: false,
        list: state.list.filter(it => it._id !== payload.groupId),
        query: INITITAL_STATE.query,
      });

    case Types.TRANSFER_OWNERSHIP_SUCCESS:
      const { ownership, currentUser } = payload;
      let { group, members } = state;
      group.author = ownership;
      let userWillChange = state.members.find(it => it._id === currentUser._id);
      const userRole = currentUser.teams[0].role === 1 ? 1 : 2;
      userWillChange.role = userRole;
      const newMembers = members.map(it => {
        if (it._id === userWillChange._id) {
          return userWillChange;
        } else {
          if (it._id === ownership) {
            it.role = 3;
          }
          return it;
        }
      });
      return Object.assign({}, state, { group: group, members: newMembers });
    case Types.GET_SETTING_LEADERBOARD_SUCCESS: {
      return Object.assign({}, state, { leaderboardSetting: payload });
    }
    case Types.SHOW_FORUM_INDICATOR_SUCCESS: {
      let groupList = _.get(state, 'list', []);
      const groupID = _.get(action, 'payload.groupId', '');
      const currentGroupID = _.get(state, 'group._id', '');

      if (currentGroupID === groupID) return state;
      if (groupList.length === 0) return { ...state };
      else {
        groupList = _.forEach(groupList, (item, index) => {
          if (item._id === groupID) groupList[index].isUnread = true;
        });
        return { ...state, list: _.merge(state.list, groupList) };
      }
    }

    case Types.UPDATE_SHOW_POST_INDICATOR_SUCCESS: {
      let postList = _.get(state, 'posts', []);
      const postID = _.get(action, 'payload.request.postId', '');

      _.forEach(postList, (item, index) => {
        if (item._id === postID) {
          postList[index].isUnread = false;
        }
      });

      return { ...state, posts: _.merge(state.posts, postList) };
    }

    case Types.UPDATE_NEW_COMMENT_SHOW_INDICATOR_SUCCESS: {
      const groupList = _.get(state, 'list', '');
      const groupID = _.get(action, 'payload.groupId', '');
      const currentGroupID = _.get(state, 'group._id', '');

      _.forEach(groupList, (item, index) => {
        if (item._id === currentGroupID) {
          groupList[index].isUnread = false;
        } else if (item._id === groupID) groupList[index].isUnread = true;
      });

      return { ...state, list: _.merge(state.list, groupList) };
    }

    case Types.SAVE_SCHEDULE_POST_TEMP: {
      return Object.assign({}, state, { formDataSchedule: payload });
    }
    case Types.GET_LIST_SCHEDULE_REQUEST: {
      return Object.assign({}, state, { loading: true, firstLoadSchedulePosts: true });
    }
    case Types.GET_LIST_SCHEDULE_SUCCESS:
      const { data, total, tabType } = payload;
      const isEnd = data.length === total;

      if (data && tabType === 'all') {
        return {
          ...state,
          schedulePosts: _.uniqBy([...data], '_id'),
          totalScheduledPost: total,
          query: {
            ...state.query,
            page: 1,
            isEnd: isEnd,
            tab: tabType,
          },
          loading: false,
          firstLoadSchedulePosts: false,
        };
      } else {
        return {
          ...state,
          schedulePosts: _.uniqBy([...data], '_id'),
          query: {
            ...state.query,
            page: 1,
            isEnd: isEnd,
            tab: tabType,
          },
          loading: false,
          firstLoadSchedulePosts: false,
        };
      }
    case Types.SCHEDULED_LIST_GET_MORE_REQUEST: {
      return Object.assign({}, state, { loading: true, firstLoadSchedulePosts: true });
    }

    case Types.GET_SCHEDULED_LIST_ALL_SUCCESS: {
      const { data, total, page, tab } = payload;
      const totalPost = _.uniqBy([...state.schedulePosts, ...data], '_id');
      const isEnd = totalPost.length === total;

      return Object.assign({}, state, {
        schedulePosts: totalPost,
        query: {
          ...state.query,
          page: page,
          isEnd: isEnd,
          tab: tab,
        },
        loading: false,
        firstLoadSchedulePosts: false,
      });
    }

    case Types.GET_SCHEDULED_LIST_MY_POST_SUCCESS: {
      const { data, total, page, tab } = payload;
      const totalPost = _.uniqBy([...state.schedulePosts, ...data], '_id');
      const isEnd = totalPost.length === total;

      return Object.assign({}, state, {
        schedulePosts: totalPost,
        query: {
          ...state.query,
          page: page,
          isEnd: isEnd,
          tab: tab,
        },
        loading: false,
        firstLoadSchedulePosts: false,
      });
    }
    case Types.RESET_CREATE_SCHEDULE_POST: {
      return {
        ...state,
        formDataSchedule: [],
        firstLoadSchedulePosts: false,
      };
    }
    case Types.RESET_CHANGE_TAB_REDUX: {
      return {
        ...state,
        query: { ...state.query, page: 1, tab: 'all' },
      };
    }
    case Types.HIDE_SCHEDULE_POST_BANNER: {
      return {
        ...state,
        group: { ...state.group, is_hide_schedule_post_banner: true },
        loading: false,
      };
    }
    case Types.RESET_CHANGE_FORUM_REDUX: {
      return {
        ...state,
        totalScheduledPost: 0,
      };
    }
    case Types.GET_LIST_COMMENT_FORUM_SUCCESS: {
      const data = _.get(action, 'payload.data', []);
      const totalComments = _.get(action, 'payload.total_comments', 0);
      const dataConvert =
        data &&
        data.map(item => {
          const latestReply = _.get(item, 'latest_reply');
          return { ...item, latestReplyList: latestReply ? [latestReply] : [], isViewMore: false };
        });
      const postId = _.get(action, 'payload.postId', '');
      const postList = _.get(state, 'posts', []);

      const postListConvert = postList.map(item => {
        if (item && item._id === postId) {
          return {
            ...item,
            listComment: dataConvert,
            total_comment: totalComments,
            indexStartList: dataConvert.length > 0 ? dataConvert.length - 1 : 0,
          };
        }
        return item;
      });

      return { ...state, posts: postListConvert };
    }

    case Types.ADD_COMMENT_FORUM_SUCCESS: {
      const data = _.get(action, 'payload.data', {});
      const index = _.get(action, 'payload.index');
      const postId = _.get(action, 'payload.postId', '');
      const totalComment = _.get(action, 'payload.total_comment', 0);
      const postList = _.get(state, 'posts', []);
      const commentLocalId = _.get(action, 'payload.commentLocalId', '');
      const postListConvert = postList.map(item => {
        if (item && item._id === postId) {
          const isReply = data.topic === 'comment';
          const listComment = _.get(item, 'listComment', []);
          const isMedia = data.content === MEDIA_FILE_REJECTED_CONTENT && data.attachment_source !== 'giphy';

          if (isReply) {
            const listCommentConvert = listComment.map(itemComment => {
              if (_.get(itemComment, '_id', '') === _.get(data, 'item', '')) {
                let latestReplyList = _.get(itemComment, 'latestReplyList', []);
                if (isMedia) {
                  latestReplyList = latestReplyList.map(item => {
                    const { _id = '' } = item;
                    return _id === commentLocalId ? { ...data } : item;
                  });
                } else {
                  latestReplyList.push(data);
                }
                const totalReplies = _.get(itemComment, 'total_replies', 0);
                return {
                  ...itemComment,
                  total_replies: isMedia ? totalReplies : totalReplies + 1,
                  latestReplyList: _.isArray(latestReplyList) ? latestReplyList : [data],
                };
              }
              return itemComment;
            });
            return { ...item, listComment: listCommentConvert, total_comment: totalComment };
          } else {
            if (isMedia) {
              const listCommentConvert = listComment.map(itemComment => {
                const { _id = '' } = itemComment;
                return _id === commentLocalId ? data : itemComment;
              });
              return { ...item, listComment: listCommentConvert, total_comment: totalComment, indexStartList: index };
            } else {
              listComment.push(data);
              return {
                ...item,
                listComment,
                total_comment: totalComment,
                indexStartList: index,
              };
            }
          }
        }
        return item;
      });

      return { ...state, posts: postListConvert };
    }

    case Types.ADD_COMMENT_WITH_MEDIA_FORUM_SUCCESS: {
      const { data = [], index, postId = '' } = action.payload;
      const postList = _.get(state, 'posts', []);

      const postListConvert = postList.map(item => {
        if (item && item._id === postId) {
          return { ...item, listComment: data, indexStartList: index };
        }
        return item;
      });

      return { ...state, posts: postListConvert };
    }

    case Types.DELETE_COMMENT_FORUM_SUCCESS: {
      const postList = _.get(state, 'posts', []);
      const postId = _.get(action, 'payload.postId', '');
      const postListConvert = postList.map(itemPost => {
        const { _id = '', total_comment = 0, listComment = [], indexStartList } = itemPost;

        if (_id === postId) {
          const totalReplies = _.get(action, 'payload.data.total_replies', 0);
          const commentId = _.get(action, 'payload.data._id', '');
          const totalComment = total_comment;
          const newTotal = totalComment - totalReplies - 1;
          let newList = [];

          if (_.get(action, 'payload.data.topic', '') === 'comment') {
            newList = listComment.map(itemComment => {
              const { _id = '', total_replies = 0, latestReplyList = [] } = itemComment;

              if (_id === _.get(action, 'payload.data.item', '')) {
                return {
                  ...itemComment,
                  total_replies: total_replies - 1,
                  latestReplyList: latestReplyList.filter(itemReply => _.get(itemReply, '_id', '') !== commentId),
                };
              }
              return itemComment;
            });
          } else {
            newList = listComment.filter(itemComment => _.get(itemComment, '_id', '') !== commentId);
          }
          const { length } = newList;
          return {
            ...itemPost,
            total_comment: newTotal,
            listComment: newList,
            indexStartList: indexStartList === length ? (length > 0 ? length - 1 : 0) : indexStartList,
          };
        }
        return itemPost;
      });

      return { ...state, posts: postListConvert };
    }

    case Types.RESET_LIST_COMMENT_OF_POST: {
      const postId = _.get(action, 'payload', '');
      const postList = _.get(state, 'posts', []);

      const postListConvert = postList.map(item => {
        const { listComment = [], _id = '' } = item;
        const { length } = listComment;
        if (_id === postId) {
          return {
            ...item,
            listComment: [],
            indexStartList: length - 1,
          };
        }
        return item;
      });

      return { ...state, posts: postListConvert };
    }

    case Types.GET_LIST_REPLY: {
      const data = _.get(action, 'payload.data', []).reverse();

      const postId = _.get(action, 'payload.postId', '');
      const commentId = _.get(action, 'payload.commentId', '');
      const postList = _.get(state, 'posts', []);

      const postListConvert = postList.map(item => {
        const listComment = _.get(item, 'listComment', []);
        if (item && item._id === postId) {
          const listCommentConvert = listComment.map(itemComment => {
            if (itemComment && itemComment._id === commentId) {
              const latestReplyList = _.get(itemComment, 'latestReplyList', []);
              const latestReplyListConvert = [...data, ...latestReplyList];
              return {
                ...itemComment,
                isViewMore: true,
                latestReplyList: latestReplyListConvert,
              };
            }
            return itemComment;
          });
          return { ...item, listComment: listCommentConvert };
        }
        return item;
      });

      return { ...state, posts: postListConvert };
    }

    case Types.SHOW_POST_PUBLISHED:
      return Object.assign({}, state, {
        posts: payload.posts,
        schedulePosts: payload.schedulePosts,
        totalScheduledPost: Math.max(state.totalScheduledPost - 1, 0),
      });

    case Types.FETCH_POSTS_START:
      return Object.assign({}, state, { postsLoading: true });
    case Types.FETCH_POSTS_DONE:
      return Object.assign({}, state, { postsLoading: false });
    case Types.FETCH_SCHEDULE_POSTS_START:
      return Object.assign({}, state, { schedulePostsLoading: true });
    case Types.FETCH_SCHEDULE_POSTS_DONE:
      return Object.assign({}, state, { schedulePostsLoading: false });

    case Types.DISABLE_LOAD_MORE_POSTS:
      return Object.assign({}, state, { disableLoadMorePosts: true });
    case Types.DISABLE_LOAD_MORE_SCHEDULE_POSTS:
      return Object.assign({}, state, { disableLoadMoreSchedulePosts: true });
    case Types.RESET_DISABLE_LOAD_MORE_SCHEDULE_POSTS:
      return Object.assign({}, state, { disableLoadMoreSchedulePosts: false });

    case Types.GET_LIST_GROUP_REQUEST:
      return { ...state, loading: true };
    case Types.GET_LIST_GROUP_FAILURE:
    case Types.GET_GROUP_DETAIL_FAILURE:
      return { ...state, loading: false };

    case Types.ADD_POLL_OPTION_SUCCESS:
    case Types.REMOVE_POLL_OPTION_SUCCESS:
    case Types.VOTE_POLL_OPTION_SUCCESS:
    case Types.UNVOTE_POLL_OPTION_SUCCESS:
    case Types.ACTION_POLL_OPTION_FAILED:
      return Object.assign({}, state, {
        posts: payload.newPosts,
      });

    default:
      return state;
  }
};

export default forum;
