import { connect } from 'react-redux';
import Leaderboard from './component';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { autoflowGetLeaderboardClients } from 'redux/autoflow/leaderboard/actions';
import { toggleModal } from 'actions/modal';
import { withRouter } from 'react-router';

const mapStateToProps = state => {
  return {
    user: state.user,
    group: state.rootReducer.forum.group,
    rankingTypes: state.rootReducer.leaderboard.get('rankingTypes'),
    connected_autoflow: state.rootReducer.forum.group.autoflow,
    connectedLeaderboard: state.rootReducer.forum.connectedLeaderboard,
    leaderboardSetting: state.rootReducer.forum.leaderboardSetting,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    autoflowGetLeaderboardClients: bindActionCreators(autoflowGetLeaderboardClients, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Leaderboard));
