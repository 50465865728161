import React from 'react';
import SectionDetailModal from 'components/SectionDetailModal';
import { WORKOUT_BUILDER_TYPES, LIST_SECTION_TYPE, CDN_URL } from 'constants/commonData';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import { LIST_SECTION_FORMAT } from './constants';
import * as S from './style';

function CreateSection(props) {
  const onSave = async section => {
    try {
      const response = await props.addSectionLibrary(section);
      return response.data.data;
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  const onSavedWithoutClose = newSection => {
    props.openSectionWorkoutDetail(newSection._id);
    props.push(`/home/sections/${newSection._id}/detail`);
  };

  return (
    <Modal
      open={true}
      className="modal--create-workout-template"
      closeOnDimmerClick={true}
      onClose={() => props.toggleModal(false)}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <S.Title>Create Section</S.Title>
        <S.SubTitle>Please select your section format</S.SubTitle>
      </Modal.Header>
      <Modal.Content>
        <S.OptionContainer>
          {LIST_SECTION_FORMAT.map(item => {
            return (
              <S.Option
                key={item.key}
                className={item.key}
                onClick={() => {
                  props.toggleModal(
                    true,
                    <SectionDetailModal
                      onSave={onSave}
                      onSavedWithoutClose={onSavedWithoutClose}
                      type={WORKOUT_BUILDER_TYPES.TEMPLATE}
                      key="create-workout-template"
                      pdfType="workout"
                      sectionFormat={item.key}
                    />,
                  );
                  props.addSection({
                    format: item.key,
                    type: LIST_SECTION_TYPE[0].key,
                    exercises: [],
                  });
                  props.resetDiscardChange && props.resetDiscardChange();
                }}
              >
                <S.OptionLabel>{item.title}</S.OptionLabel>
                <S.OptionDesc>{item.desc}</S.OptionDesc>
              </S.Option>
            );
          })}
        </S.OptionContainer>
      </Modal.Content>
    </Modal>
  );
}

export default CreateSection;
