import Request from 'configs/request';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { toast } from 'react-toastify';
import {
  convertS3UrlToCloudFrontUrl,
  omitEmptyRequestParams,
  replaceImageToThumbnailConvertedURL,
} from 'utils/commonFunction';
import { PAGE_SIZE_PACKAGE } from 'redux/package-list/reducer';
import { push } from 'connected-react-router';

export const Types = {
  PAYMENT_COACH_CHANGE_QUERY_PARAMS: 'PAYMENT_COACH_CHANGE_QUERY_PARAMS',
  PAYMENT_COACH_CHANGE_QUERY_PARAMS_MARKETPLACE: 'PAYMENT_COACH_CHANGE_QUERY_PARAMS_MARKETPLACE',
  PAYMENT_COACH_GET_PACKAGE_LIST: 'PAYMENT_COACH_GET_PACKAGE_LIST',
  PAYMENT_COACH_GET_PACKAGE_LIST_SUCCESSFULLY: 'PAYMENT_COACH_GET_PACKAGE_LIST_SUCCESSFULLY',
  PAYMENT_COACH_GET_PACKAGE_LIST_ERROR: 'PAYMENT_COACH_GET_PACKAGE_LIST_ERROR',
  PAYMENT_COACH_HIDE_BANNER: 'PAYMENT_COACH_HIDE_BANNER',
  PAYMENT_RESET_DATA: 'PAYMENT_RESET_DATA',
  PAYMENT_COUNT_PACKAGES: 'PAYMENT_COUNT_PACKAGES',
  PAYMENT_COUNT_MARKETPLACE_PACKAGES: 'PAYMENT_COUNT_MARKETPLACE_PACKAGES',
  PAYMENT_COUNT_PACKAGES_REQUEST: 'PAYMENT_COUNT_PACKAGES_REQUEST',
  PAYMENT_COACH_ARCHIVE_PACKAGE: 'PAYMENT_COACH_ARCHIVE_PACKAGE',
  PAYMENT_COACH_UPDATE_COUNTER_REDUX: 'PAYMENT_COACH_UPDATE_COUNTER_REDUX',
};

export const getPackageList = (params, isMP) => {
  const platforms = isMP ? ['marketplace'] : ['everfit'];
  return Request.get(
    { url: '/api/payment/packages', params: omitEmptyRequestParams({ ...params, platforms }) },
    true,
    (response, { dispatch, getState }) => {
      const { data } = response.data;
      const { cloudfrontList = [] } = getState() || {};
      const list = (data.data || []).map(item => {
        const imgS3 = convertS3UrlToCloudFrontUrl(item.cover_image, cloudfrontList, true);
        return {
          ...item,
          cover_image_s3: imgS3,
          cover_image: replaceImageToThumbnailConvertedURL(imgS3),
        };
      });

      dispatch({
        type: Types.PAYMENT_COACH_GET_PACKAGE_LIST_SUCCESSFULLY,
        payload: { list, total: data.total },
      });
    },
    (error, { dispatch }) => dispatch({ type: Types.PAYMENT_COACH_GET_PACKAGE_LIST_ERROR, error }),
  );
};

export const copyPackage = (data, isMP) => {
  return dispatch => {
    const platforms = isMP ? ['marketplace'] : ['everfit'];
    return dispatch(
      Request.post(
        { url: `/api/payment/packages/${data.id}/duplicate`, data: { ...data, platforms } },
        true,
        response => {
          const packageId = get(response, 'data.data.hash_id');
          const path = isMP ? 'marketplace' : 'packages';

          dispatch(countPackages(isMP));
          packageId && dispatch(push(`/home/${path}/${packageId}`));
        },
      ),
    );
  };
};

export const changeQueryParams = (params, isMP) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { packageList },
    } = getState();

    const query = omitEmptyRequestParams({ ...packageList.get(isMP ? 'queryMP' : 'query').toJS(), ...params });
    if (isMP) {
      dispatch({ type: Types.PAYMENT_COACH_CHANGE_QUERY_PARAMS_MARKETPLACE, payload: query });
    } else {
      dispatch({ type: Types.PAYMENT_COACH_CHANGE_QUERY_PARAMS, payload: query });
    }
    dispatch(getPackageList(query, isMP));
  };
};

export const hideBanner = isMP => {
  return Request.post({ url: 'api/packages/hide-banner' }, true, (response, { dispatch }) => {
    dispatch({ type: Types.PAYMENT_COACH_HIDE_BANNER });
  });
};

export const resetData = () => ({ type: Types.PAYMENT_RESET_DATA });

export const updateCounterPackages = () => ({ type: Types.PAYMENT_COACH_UPDATE_COUNTER_REDUX });

export const countPackages = isMP => {
  return (dispatch, getState) => {
    const platforms = isMP ? ['marketplace'] : ['everfit'];
    dispatch({ type: Types.PAYMENT_COUNT_PACKAGES_REQUEST });
    return dispatch(
      Request.get({ url: '/api/payment/countPackages', params: { platforms } }, true, (response, { dispatch }) => {
        const { data } = response.data;
        if (isMP) {
          dispatch({ type: Types.PAYMENT_COUNT_MARKETPLACE_PACKAGES, payload: data });
        } else {
          dispatch({ type: Types.PAYMENT_COUNT_PACKAGES, payload: data });
        }
      }),
    );
  };
};

export const archivePackage = params => {
  const NUMBER_NOT_RESET_PAGE = 1;
  const NUMBER_CHECK_RESET_PAGE = 1;

  return (dispatch, getState) => {
    const {
      rootReducer: { packageList },
    } = getState();

    const query = params.isMP ? packageList.get('queryMP').toJS() : packageList.get('query').toJS();

    if (query.page > NUMBER_NOT_RESET_PAGE) {
      if (
        (query.page - NUMBER_NOT_RESET_PAGE) * PAGE_SIZE_PACKAGE + NUMBER_CHECK_RESET_PAGE ===
        packageList.get('total')
      )
        query.page = query.page - NUMBER_CHECK_RESET_PAGE;
    }

    return dispatch(
      Request.patch(
        { url: `/api/payment/packages/${params.id}/status`, data: { status: params.status } },
        true,
        response => {
          const { data } = response.data;
          dispatch({
            type: Types.PAYMENT_COACH_ARCHIVE_PACKAGE,
            payload: pickBy(data, identity),
          });
          dispatch({ type: Types.PAYMENT_COACH_CHANGE_QUERY_PARAMS, payload: query });
          dispatch(getPackageList(query, params.isMP));
          dispatch(countPackages(params.isMP));
        },
      ),
    );
  };
};

export const removePackage = (packageId, isMP) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { packageList },
    } = getState();

    const query = isMP ? packageList.get('queryMP').toJS() : packageList.get('query').toJS();

    return dispatch(
      Request.delete({ url: `/api/payment/packages/${packageId}` }, true, () => {
        dispatch(getPackageList(query, isMP));
        dispatch(countPackages(isMP));
        toast(`Package has been removed`);
      }),
    );
  };
};
