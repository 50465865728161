import styled, { css } from 'styled-components';

export const Name = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 5px;
`;

export const Email = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  opacity: 0.5;
`;

export const CustomerInfoContainer = styled.div`
  flex: 1 1;
`;

export const CheckboxContainer = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 15px;
  flex: 0 0 36px;
`;

export const TableCellContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TableCell = styled.td`
  padding: 10px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;

  &.actions {
    ${TableCellContent} {
      justify-content: flex-end;
    }

    .evf-dropdown__option {
      font-weight: 600;

      svg {
        margin-right: 11px;
      }
    }
  }

  &.customter {
    width: 33%;
  }

  ${props =>
    props.noResult &&
    css`
      text-align: center;
    `}
`;

export const TableRow = styled.tr`
  box-shadow: inset 0px -1px 0px #f0f0f0;
`;

export const TableCellHeader = styled.th`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #727a8b;
  padding: 17px 15px 14px;
  box-shadow: inset 0px -2px 0px #f6f5f8;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;

  span {
    margin: 0px 8px 0px 5px;
  }

  &.customer {
    width: 33%;
  }

  &.actions {
    width: 60px;
  }

  ${props =>
    props.isSorting &&
    css`
      color: #5158cf;
    `}
  ${props =>
    props.sortable &&
    css`
      :hover {
        cursor: pointer;
        color: #5158cf;
      }
    `}
`;

export const TableHeader = styled.thead``;

export const TableBody = styled.tbody`
  ${TableRow} {
    :hover {
      background-color: #fafbfc;
      cursor: pointer;
    }

    :last-child {
      box-shadow: none;
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0 0;
  border-collapse: collapse;
  position: relative;
  width: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  flex: 1 1;
  height: 100%;
  overflow: auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 60px 55px 30px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
