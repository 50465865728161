import app from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/analytics';
import { axiosInstance } from 'configs/request';

app.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const database = app.database();
const storage = app.storage();
const analytics = app.analytics();
const auth = app.auth();

function getFirebaseToken() {
  return axiosInstance.get('/api/profile/get-firebase-token').then(response => {
    const { firebase_token } = response.data;
    return firebase_token;
  });
}

export async function signInFirebaseWithCustomToken(firebaseToken, retry = 0) {
  if (retry > 0) {
    firebaseToken = await getFirebaseToken();
  }
  return auth
    .signInWithCustomToken(firebaseToken)
    .then(data => {
      return data;
    })
    .catch(error => {
      console.log('Firebase authen issue', retry, error);
      if (retry < 5) {
        retry++;
        return signInFirebaseWithCustomToken(firebaseToken, retry);
      }
      throw error;
    });
}

export const signOutFirebase = () => {
  auth.signOut();
};

export { database, storage, analytics, auth };
