import styled, { keyframes, css } from 'styled-components';

export const OptionWrapper = styled.div`
  // position: sticky;
  width: inherit;
  // bottom: 0;
  // z-index: 3;

  .evf-settings {
    width: inherit;

    .ui.attached {
      &.tabular.menu {
        padding-left: 0px;
        background: #f9f9f9;
        border-bottom: 1px solid #e1e5ec;

        .item {
          color: #262445;
          padding: 16px 20px;
          margin-right: 0;
          font-size: 13px;
          font-family: 'Open Sans' !important;
          line-height: 18px;
          border-radius: 0 !important;

          svg {
            margin-right: 10px;
            color: #8f99a3;

            path {
              stroke: #8f99a3;
            }
          }

          &.active {
            svg {
              color: #5158cf !important;

              path {
                stroke: #5158cf;
              }
            }
          }
        }
      }
    }
  }
`;

export const OptionWrapperContent = styled.div`
  display: flex;
  background: #ffffff;
  padding: 30px 30px 45px 30px;
  min-height: 190px;
  &.onboard-welcome {
    padding: 15px 30px 10px 30px;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const MenuLabel = styled.label`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #262445;
  padding: 0 20px;
`;
