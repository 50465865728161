import React, { useState, useRef, useCallback } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import isEmpty from 'lodash/isEmpty';

import SearchBar from 'shared/Giphy/components/SearchBar';
import GifGrid from 'shared/Giphy/components/GifGrid';
import { GIPHY_API_KEY, options, emptyGifsResult } from 'shared/Giphy/constants';

import * as S from 'shared/Giphy/styles';

function GiphyContent(props) {
  if (isEmpty(GIPHY_API_KEY)) return <></>;

  const gf = new GiphyFetch(GIPHY_API_KEY);
  const [gridWidth, setWidth] = useState(0);
  const [searchKey, setSearch] = useState('');

  const fetchGifs = useCallback(
    async offset => {
      let result = emptyGifsResult;
      if (!isEmpty(searchKey)) {
        result = await gf.search(searchKey, {
          ...options,
          offset,
        });
      } else {
        result = await gf.trending({ ...options, offset });
      }
      return result;
    },
    [gf, searchKey],
  );

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;
      setWidth(width);
    }),
  );

  const resizedContainerRef = useCallback(container => {
    if (container !== null) {
      resizeObserver.current.observe(container);
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    }
  }, []);

  const onGifClick = (gif, e) => {
    e.preventDefault();
    props.onGifClick && props.onGifClick(gif);
    props.onClose && props.onClose();
  };

  return (
    <S.GiphyContainer>
      <SearchBar searchKey={searchKey} setSearch={setSearch} />
      <S.ScrollGrid>
        <S.ContentGrid ref={resizedContainerRef}>
          <GifGrid searchKey={searchKey} gridWidth={gridWidth} fetchGifs={fetchGifs} onGifClick={onGifClick} />
        </S.ContentGrid>
      </S.ScrollGrid>
    </S.GiphyContainer>
  );
}

export default GiphyContent;
