import React, { useMemo } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';

import CalendarActionItem from './CalendarActionItem';
import * as S from '../style';

function CalendarActions(props) {
  const { calendarType, selectedWeek, selectedWorkout, calendarMode, calendarStartDate, workouts, permission } = props;
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V1 || !_.get(permission, 'copy_week')) return <></>;
  if (!_.isEmpty(selectedWeek) || !_.isEmpty(selectedWorkout) || _.isEqual(calendarMode, 'history')) return <></>;
  const listAvailableCopyWeek = useMemo(() => {
    const available = Array(calendarType).fill(false);
    const day = DateTime.fromISO(calendarStartDate);
    for (let index = 0; index < calendarType; index++) {
      for (let dayIdx = 0; dayIdx < 7; dayIdx++) {
        const date = day.plus({ days: 7 * index + dayIdx });
        const workout = workouts.get(date.toFormat('MM-dd-yyyy'));
        if (!_.isEmpty(workout)) {
          available[index] = true;
          break;
        }
      }
    }
    return available;
  }, [calendarType, calendarStartDate, workouts]);
  return (
    <>
      <S.CalendarActionsWrapper right={false}>
        {listAvailableCopyWeek.map((availableCopy, index) => (
          <CalendarActionItem key={index} index={index} availableCopy={availableCopy} {...props} />
        ))}
      </S.CalendarActionsWrapper>
      <S.CalendarActionsWrapper right={true}>
        {Array(calendarType)
          .fill(null)
          .map((__, index) => (
            <CalendarActionItem key={index} index={index} availableCopy={false} {...props} />
          ))}
      </S.CalendarActionsWrapper>
    </>
  );
}

export default CalendarActions;
