import React from 'react';
import { get } from 'lodash';

import classNames from 'classnames';
import { getLabelAndDuration } from './commonFunction';
import CardThumbnail from './CardThumbnail';

import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';
import { MAX_ITEM } from './constants';

import * as S from './style';

function PreviewLargeCard(props) {
  const { section } = props;
  const workoutTotal = get(section, 'workouts', []).length;

  return (
    <S.SectionMobilePreview>
      <S.PreviewTitle>
        <S.ViewName>{section.name}</S.ViewName>
        <S.ViewMore>See more</S.ViewMore>
      </S.PreviewTitle>
      <S.PreviewLargeCardWrapper>
        {get(section, 'workouts', [])
          .filter((item, index) => index < MAX_ITEM)
          .map(resource => (
            <S.Card key={resource._id} className={classNames({ 'full-width': workoutTotal === 1 })}>
              <CardThumbnail resource={resource} showType={true}>
                <S.LargeCardName coverImage={resource.background}>{resource.title}</S.LargeCardName>
                <S.LabelAndDuration>{getLabelAndDuration(resource)}</S.LabelAndDuration>
              </CardThumbnail>

              {resource.type.includes('video') && (
                <div className="previewResource__playIcon">
                  <PlayIcon />
                </div>
              )}
            </S.Card>
          ))}
      </S.PreviewLargeCardWrapper>
    </S.SectionMobilePreview>
  );
}

export default PreviewLargeCard;
