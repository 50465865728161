import React from 'react';
import { components } from 'react-select';
import { get } from 'lodash';
import ReactTooltip from 'react-tooltip';
import CustomOptionStyles, { GlobalTooltipStyle } from './styles';

const CustomOption = ({ children, ...props }) => {
  const unique_code = get(props, 'data.unique_code');
  if (unique_code === 'min')
    return (
      <components.Option {...props}>
        <CustomOptionStyles>
          <div>{children}</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            data-tip
            data-for={`tooltip--metric--min`}
            className="metric-tooltip"
          >
            <path
              d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 10.5C6.475 10.5 6.125 10.15 6.125 9.625C6.125 9.1 6.475 8.75 7 8.75C7.525 8.75 7.875 9.1 7.875 9.625C7.875 10.15 7.525 10.5 7 10.5ZM7.875 7.875H6.125V3.5H7.875V7.875Z"
              fill="#A6ACC6"
            />
          </svg>
        </CustomOptionStyles>
        <ReactTooltip id={`tooltip--metric--min`} effect="solid" place="top">
          <span>
            Your clients will be able to input time as HH:MM (hours and minutes), but data is stored in our database as
            minutes.
          </span>
        </ReactTooltip>
        <GlobalTooltipStyle />
      </components.Option>
    );

  return <components.Option {...props}>{children}</components.Option>;
};

export default CustomOption;
