import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import SectionLibrary from './component';
import { toggleModal } from 'actions/modal';
import {
  changeQueryParams,
  getSections,
  updateSelectedSection,
  openSectionWorkoutDetail,
  duplicateSectionLibrary,
  resetQueryParams,
} from 'redux/section-library/actions';
import { toggleSideBar } from 'actions/sidebar';

const mapStateToProps = state => {
  const {
    rootReducer: {
      sectionLibrary: { list, loading, total, query },
    },
  } = state;

  return {
    sections: list,
    loading,
    total,
    query,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    getSections: bindActionCreators(getSections, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    updateSelectedSection: bindActionCreators(updateSelectedSection, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    openSectionWorkoutDetail: bindActionCreators(openSectionWorkoutDetail, dispatch),
    duplicateSectionLibrary: bindActionCreators(duplicateSectionLibrary, dispatch),
    resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionLibrary);
