import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  SettingContent,
  Note,
  FeatureSettingHeader,
  PageHeader,
  SyncSettingContainer,
  Wrapper,
  SyncSettingContent,
  SyncActionContainer,
  SyncDescription,
  DisableContainer,
  DisableContent,
} from './style';
import { WorkoutSettings, FeatureSettings } from 'shared/Settings';
import { SettingName } from 'shared/Settings/style';
import { updateFeaturePreferences, updateWorkoutVisibility } from 'redux/autoflow/settings/actions';
import { toggleModal } from 'actions/modal';
import AutoflowMetrics from 'components/AutoflowMetrics';
import { Button } from 'shared/FormControl';
import GuideLink from 'shared/Styles/GuideLink';
import ConfirmTurnOffSetting from './ConfirmTurnOffSetting';
import { Helmet } from 'react-helmet';
import SidebarSetting from 'components/SidebarSetting';
import { SIDEBAR_ID } from 'components/ClientSettings/constants';
import { CDN_URL } from 'constants/commonData';
import * as Sidebar from 'components/ClientSettings/style';

const SIDEBAR_SETTING = [
  {
    title: 'Sync Settings',
    id: SIDEBAR_ID.sync_setting,
  },
  {
    title: 'Features',
    id: SIDEBAR_ID.features,
  },
  {
    title: 'Workout Settings',
    id: SIDEBAR_ID.workout,
  },
];

function Settings(props) {
  const { autoflow, originFeatures, originWorkoutSettings, permission, isDisable, autoflowName } = props;
  const [isFuturesChanged, setIsFuturesChanged] = useState(false);
  const [isClientWorkoutChange, setIsClientWorkoutChange] = useState(false);
  const isAllChangeSetting = useMemo(() => {
    return [isFuturesChanged, isClientWorkoutChange];
  }, [isFuturesChanged, isClientWorkoutChange]);

  const handleCloseTurnOffSettingModal = () => {
    props.toggleModal(false);
  };

  const handleTurnOffSetting = () => {
    props.toggleModal(true, <ConfirmTurnOffSetting onClose={handleCloseTurnOffSettingModal} />);
  };

  const onFuturesChanged = value => setIsFuturesChanged(value);
  const onClientWorkoutChange = value => setIsClientWorkoutChange(value);
  const handleScrollTag = idTag => {
    const element = document.getElementById(idTag);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Sidebar.Wrapper>
      <SidebarSetting sidebarSetting={SIDEBAR_SETTING} onScrollTag={handleScrollTag} />
      <Sidebar.Content>
        <Wrapper disable={isDisable}>
          <Helmet>
            <title>{autoflowName} - Settings - Everfit</title>
          </Helmet>
          <SyncSettingContainer id={SIDEBAR_ID.sync_setting}>
            <div>
              <SettingName>Sync Settings</SettingName>
            </div>
            <div>
              <SyncSettingContent>
                <SyncDescription>
                  When a client is activated for the Autoflow, the client will adopt the selection of settings below.
                  Each client can only be in 1 Autoflow that syncs settings.
                </SyncDescription>
                <SyncActionContainer>
                  <div>
                    <h3>Use Autoflow Settings</h3>
                    <h4>Leave on to sync settings</h4>
                  </div>
                  <Button onClick={handleTurnOffSetting}>Turn-Off Settings</Button>
                </SyncActionContainer>
              </SyncSettingContent>
            </div>
          </SyncSettingContainer>
          <SettingContent className="autoflow-settings">
            <PageHeader>
              <Note>
                <p>
                  Clients newly added to an Autoflow will adopt the default selection of settings. You can still
                  customize the experience for each client individually from each client’s settings page.
                </p>
                <a
                  className="guide"
                  href="http://help.everfit.io/en/articles/2836312-manage-client-features-and-unit-settings"
                  target="_blank"
                >
                  <Image src={`${CDN_URL}/images/feature_setting_guide.svg`} />
                  <span>How to disable/enable features</span>
                </a>
              </Note>
            </PageHeader>
            <FeatureSettings
              originSettings={originFeatures}
              onSave={body => props.updateFeaturePreferences({ ...body, autoflow })}
              showMetricSetting={true}
              onClickManageMetric={() => props.toggleModal(true, <AutoflowMetrics />)}
              permission={permission}
              header={
                <FeatureSettingHeader>
                  Create a custom experience for your clients by disabling or enabling certain features. When a feature
                  is disabled, it will not appear on your client'Sidebar mobile app.
                </FeatureSettingHeader>
              }
              isAllChangeSetting={isAllChangeSetting}
              onFuturesChanged={onFuturesChanged}
            />
            <WorkoutSettings
              originSettings={originWorkoutSettings}
              onSave={body => props.updateWorkoutVisibility({ ...body, autoflow })}
              isAllChangeSetting={isAllChangeSetting}
              onClientWorkoutChange={onClientWorkoutChange}
            />
          </SettingContent>
          {isDisable && (
            <DisableContainer>
              <DisableContent>
                <h3>Settings-Free Autoflow</h3>
                <p>
                  This Autoflow has been permanently converted to not sync settings. Now you can assign this to any
                  client, even ones already in another Autoflow.
                </p>
                <p>
                  <u>Please Note:</u> for all assignments (eg. Workouts) in the Autoflow, make sure all clients have
                  those features turned on, or else certain clients may not receive the assignments.
                </p>
                <GuideLink
                  to="http://help.everfit.io/en/articles/5210131-how-to-add-multiple-autoflows-for-a-client-using-settings-free-autoflows"
                  title="What is a Settings-Free Autoflow?"
                />
              </DisableContent>
            </DisableContainer>
          )}
        </Wrapper>
      </Sidebar.Content>
    </Sidebar.Wrapper>
  );
}

const mapState = state => {
  const { autoflow, permission } = state.rootReducer;
  const { settings, common } = autoflow;
  return {
    autoflow: _.get(common, 'workingAutoflow._id'),
    autoflowName: _.get(common, 'workingAutoflow.name'),
    originFeatures: settings.features,
    originWorkoutSettings: settings.workoutVisibility,
    isDisable: _.get(common, 'workingAutoflow.isSettingTurnedOff'),
    permission,
  };
};

const actionCreators = {
  updateFeaturePreferences,
  updateWorkoutVisibility,
  toggleModal,
};

export default connect(mapState, actionCreators)(Settings);
