/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { State, Dispatch } from 'types/redux';
import {
  getNotificationPreferences,
  updateNotificationPreferences,
  getEmailPreferences,
  updateEmailPreferences,
} from 'actions/client';
import Notifications from './component';

const mapStateToProps = state => {
  const { user } = state;
  return {
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getNotificationPreferences: () => {
      return dispatch(getNotificationPreferences());
    },
    updateNotificationPreferences: data => {
      return dispatch(updateNotificationPreferences(data));
    },
    getEmailPreferences: () => {
      return dispatch(getEmailPreferences());
    },
    updateEmailPreferences: data => {
      return dispatch(updateEmailPreferences(data));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
