import React, { useMemo } from 'react';
import get from 'lodash/get';
import some from 'lodash/some';
import moment from 'moment';

import { ReactComponent as FormIcon } from 'assets/icons/form_doc.svg';
import { ReactComponent as FormOnboardingIcon } from 'assets/icons/form_doc_onboarding.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/onboarding-flow-signature.svg';

import * as S from './style';

const Item = ({ item, timezone, clientId, onOpen }) => {
  const isOnboardingForm = get(item, 'is_complete_onboarding_flow_form');
  const hasTypeSignature = some(get(item, 'questions'), it => get(it, 'type') === 'signature');

  const submittedDate = useMemo(() => {
    return moment(isOnboardingForm ? item.onboarding_form_submitted_at : item.submitted_at)
      .tz(timezone)
      .format('MMM DD, YYYY');
  }, [item]);

  const handleGoDetail = () => {
    if (isOnboardingForm) {
      onOpen && onOpen(item);
    } else {
      window.open(`/home/forms/${item.form}/responses?client=${clientId}&submitted=${item._id}`, '_blank');
    }
  };

  if (!item) {
    return null;
  }

  return (
    <S.ItemWrapper onClick={handleGoDetail}>
      <S.ItemName>
        {isOnboardingForm ? <FormOnboardingIcon /> : <FormIcon />}
        <S.FormNameDetail>
          {get(item, 'current_form_name', '') || get(item, 'title', '')}
          {hasTypeSignature && <SignatureIcon className="signature-icon" />}
        </S.FormNameDetail>
      </S.ItemName>
      <S.ItemSubmittedDate>{submittedDate}</S.ItemSubmittedDate>
    </S.ItemWrapper>
  );
};

export default Item;
