import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import Mailcheck from 'mailcheck';

import Layout from 'layouts/Login';
import Captcha from 'shared/Captcha';
import WarningPopup from './ClientSignUpWarning';
import { register } from 'actions/auth/register';
import { ErrorMessage } from 'shared/FormControl';
import { validateEmail, checkExistEmail, checkPasswordValidation } from 'utils/validations';
import { axiosInstanceWithoutToken, axiosInstance } from 'configs/request';
import { getCookie, refreshCookie, setCookie, splitEmailString } from 'utils/commonFunction';
import {
  COOKIE_EXPRIRED_DAYS,
  EMAIL_SPELL_CHECK,
  REFERAL_CODE_KEY,
  AFFREF_CODE_KEY,
  SUPPORTED_DOMAIN_EMAIL,
  CDN_URL,
} from 'constants/commonData';
import ButtonGoogleAuth from 'shared/ButtonGoogleAuth';
import ClientLoginWarning from 'components/ClientLoginWarning';
import VerifyPasswordTooltip from './VerifyPasswordTooltip';
import { ReactComponent as IconHorn } from 'assets/icons/icon_horns.svg';

import * as FormStyles from 'layouts/Login/style';
import * as S from './style';

const EMAIL_ERRORS = { INVALID: 'INVALID', EXISTED: 'EXISTED' };
const topLevelDomains = ['com', 'net', 'org'];

class Register extends React.Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    this.state = {
      showPassword: false,
      emailValidation: { isValidating: false, error: '' },
      email: params.get('email') || '',
      first_name: '',
      last_name: '',
      password: '',
      is_trainer: true,
      isHuman: false,
      isResetCaptcha: false,
      inviter_name: '',
      team_invite_id: '',
      team_name: '',
      plan: 'studio',
      billing_cycle: 1,
      clients: 100,
      serverError: '',
      showError: false,
      is_default_trial: true,
      referral_code: params.get('code') || '',
      isMisspelledEmail: false,
      emailTextSuggestion: '',
      isSignupByGoogle: false,
      isPasswordInputFocused: false,
      isOverlapVerifyTooltip: false,
      showVerifyPasswordError: false,
    };
    this.checkEmailExistDebounce = debounce(this.checkExistEmail, 300);
    this.isSubmitting = false;
  }

  componentDidUpdate() {
    try {
      const rightContent = document.querySelector('.right-content');
      if (rightContent) {
        const width = rightContent.offsetWidth;
        const widthOfOuter = 200;
        const widthOfSignUpElm = 444;
        const isOverlap = width < widthOfOuter * 2 + widthOfSignUpElm;
        this.setState({ isOverlapVerifyTooltip: isOverlap });
      }
    } catch (error) {}
  }

  componentDidMount() {
    let teamId = '',
      source = '',
      code = '',
      affCode = '',
      params;
    const paramsString = get(this.props, 'location.search', '');
    const { workspace } = this.props;

    if (paramsString) {
      params = new URLSearchParams(paramsString);
      teamId = params.get('team_invite_id');
      source = params.get('source');
      code = params.get('code');
      affCode = params.get('affref');
    }

    // Save Affiliate code
    code && this.saveReferralCode(code);
    affCode && this.fetchReferralCodeFromAff(affCode);

    if (teamId) {
      if (isEmpty(workspace)) {
        this.props.handleVerifyTeam(teamId).then(response => {
          const data = get(response, 'data.data');
          if (data) {
            const systemData = pick(data, ['inviter_name', 'team_name', 'first_name', 'last_name', 'email']);
            this.setState({ ...systemData, team_invite_id: teamId });
          }
        });
      } else {
        this.setState({ ...workspace, team_invite_id: teamId });
      }
    } else if (source) {
      const billing_cycle = Number(params.get('billing_cycle'));
      const clients = Number(params.get('clients'));
      const plan = params.get('plan') || this.state.plan;
      const is_default_trial = !billing_cycle || !clients || !plan ? true : false;

      const externalData = {
        first_name: params.get('first_name') || '',
        last_name: params.get('last_name') || '',
        email: params.get('email') || '',
        plan,
        billing_cycle,
        clients,
        is_default_trial,
      };

      const emailValidation = { isValidating: !!externalData.email, error: '' };

      this.setState({ ...externalData, emailValidation }, () => {
        if (externalData.email) {
          this.checkExistEmail(externalData.email);
        }
      });

      axiosInstanceWithoutToken.post('/api/campaign/subscribe', {
        ...externalData,
        source,
        phone: params.get('phone') || '',
      });
    }
  }

  fetchReferralCodeFromAff = async affCode => {
    axiosInstance
      .get('/api/affiliate/get-referral-code-by-aff-code', { params: { affiliate_code: affCode } })
      .then(response => {
        const code = get(response, 'data.data.referral_code', '');
        if (code) {
          this.setState({ referral_code: code });
          this.saveReferralCode(code, affCode);
        }
      });
  };

  checkExistEmail = value => {
    const emailValidation = { isValidating: false, error: '' };

    checkExistEmail(value)
      .then(response => {
        const { email } = this.state;

        if (value === email) {
          const { data } = response.data;

          if (data.exist) {
            emailValidation.error = EMAIL_ERRORS.EXISTED;
          }

          this.setState({ emailValidation });
        }
      })
      .catch(() => {
        const { email } = this.state;

        if (email === value) {
          this.setState({ emailValidation });
        }
      });
  };

  onEmailChange = event => {
    const { value } = event.target;
    const emailValidation = { isValidating: false, error: '' };

    if (value) {
      if (validateEmail(value)) {
        emailValidation.isValidating = true;
      } else {
        emailValidation.error = EMAIL_ERRORS.INVALID;
      }
    }

    this.setState({
      email: value,
      emailValidation,
      showError: false,
      isMisspelledEmail: false,
    });

    if (value) {
      Mailcheck.run({
        email: value,
        domains: SUPPORTED_DOMAIN_EMAIL,
        topLevelDomains: topLevelDomains,
        suggested: suggestion => {
          const { domain, full } = suggestion;
          if (SUPPORTED_DOMAIN_EMAIL.includes(domain)) {
            this.setState({ isMisspelledEmail: true, emailTextSuggestion: full });
          }
        },
        empty: () => {
          this.setState({ emailTextSuggestion: '', isMisspelledEmail: false });
        },
      });
      const addressEmail = splitEmailString(value, 0);
      const splitDomainEmail = splitEmailString(value, 1);
      EMAIL_SPELL_CHECK.forEach(item => {
        if (splitDomainEmail === item.domain) {
          this.setState({ isMisspelledEmail: true, emailTextSuggestion: `${addressEmail}@${item.suggestion}` });
        }
      });
    }

    if (emailValidation.isValidating) {
      this.checkEmailExistDebounce(value);
    }
  };

  onPasswordChange = event => {
    const { value } = event.target;
    this.setState({ password: value.trim(), showError: false });
  };

  onSelectAccountType = value => {
    if (value !== this.state.is_trainer) {
      this.setState({ is_trainer: value }, () => {
        const { is_trainer } = this.state;

        if (!is_trainer) {
          this.props.toggleModal(
            true,
            <WarningPopup
              onConfirm={() => {
                this.props.toggleModal(false);
                this.onSelectAccountType(true);
              }}
            />,
          );
        }
      });
    }
  };

  getSubmitData = () => {
    const data = pick(this.state, [
      'first_name',
      'last_name',
      'email',
      'password',
      'is_trainer',
      'team_invite_id',
      'clients',
      'billing_cycle',
      'plan',
      'is_default_trial',
      'referral_code',
    ]);

    return { ...data, reward_referral_code: get(window, 'Rewardful.referral', '') };
  };

  onSubmit = async event => {
    event.preventDefault();
    const { emailValidation, password, team_invite_id, email, isHuman, showError } = this.state;

    if (this.isSubmitting || (!isHuman && !window.Cypress)) {
      return;
    }

    if (!email || emailValidation.isValidating || emailValidation.error || !password || password.length < 8) {
      if (!showError) {
        this.setState({ showError: true });
      }

      return;
    }

    const submitData = this.getSubmitData();
    submitData.sign_up_info = window.location.search;

    if (team_invite_id) {
      this.isSubmitting = true;
      this.setState({ serverError: '' });
      submitData.referredBy = 'teammate';
      register(submitData).catch(error => {
        if (this) {
          const serverError = get(
            error,
            'response.data.message',
            'Undefined error. Please try again later or contact your administrator.',
          );
          this.isSubmitting = false;
          this.setState({ serverError });
        }
      });
    } else {
      this.isSubmitting = true;
      this.setState({ serverError: '' });
      this.props.createTrainerProfile(submitData).catch(error => {
        if (this) {
          const serverError = get(
            error,
            'response.data.message',
            'Undefined error. Please try again later or contact your administrator.',
          );
          this.isSubmitting = false;
          this.setState({ serverError });
        }
      });
    }
  };

  captchaCallback = () => {
    this.setState({ isHuman: true, isResetCaptcha: false });
  };

  captchaExpiredCallback = () => {
    if (window.Cypress) {
      this.setState({ isHuman: true, isResetCaptcha: false });
    }
    this.setState({ isHuman: false, isResetCaptcha: false });
  };

  toggleShowPassword = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  // Save the Affiliate code into cookie
  saveReferralCode = (referral_code, affref_code = '') => {
    return new Promise(resolve => {
      axiosInstance
        .post('/api/authentication/verify-referral-code', { referral_code })
        .then(response => {
          const referredBy = get(response, 'data.referredBy');
          const currentCode = getCookie(REFERAL_CODE_KEY);
          if (referredBy === 'affiliate') {
            if (!currentCode) {
              // Add new Affiliate code into cookie
              setCookie(REFERAL_CODE_KEY, referral_code, COOKIE_EXPRIRED_DAYS);
              setCookie(AFFREF_CODE_KEY, affref_code, COOKIE_EXPRIRED_DAYS);
            } else if (currentCode === referral_code) {
              // Refresh Affiliate code in cookie
              refreshCookie(REFERAL_CODE_KEY);
              refreshCookie(AFFREF_CODE_KEY);
            }
          }
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };

  handleReplacedEmail = () => {
    this.setState({ email: this.state.emailTextSuggestion, emailTextSuggestion: '' });
  };

  handleShowEmailSuggestion = email => {
    const address = email.split('@')[0];
    const domain = email.split('@')[1];
    return (
      <S.SpelledEmail className="email-suggestion" onClick={this.handleReplacedEmail}>
        {address}
        <S.BoldEmail>@{domain}</S.BoldEmail>
      </S.SpelledEmail>
    );
  };

  onHandleSuccess = tokenResponse => {
    this.setState({ isSignupByGoogle: true });
    const { team_invite_id } = this.state;
    const submitData = pick(this.getSubmitData(), ['team_invite_id', 'referral_code', 'reward_referral_code']);
    submitData.sign_up_info = window.location.search;
    if (tokenResponse) {
      this.isSubmitting = true;
      this.setState({ serverError: '' });
      this.props
        .signUpByGoogle(submitData, team_invite_id, tokenResponse)
        .catch(error => {
          if (get(error, 'response.status') === 403) {
            this.setState({ email: '', password: '', emailValidation: {} });
            this.props.toggleModal(true, <ClientLoginWarning onClose={() => this.props.toggleModal(false)} />);
          } else if (this) {
            const serverError = get(
              error,
              'response.data.message',
              'Undefined error. Please try again later or contact your administrator.',
            );
            this.setState({ serverError });
          }
        })
        .finally(() => {
          this.isSubmitting = false;
          this.setState({ isSignupByGoogle: false });
        });
    }
  };

  checkNewPassword = password => {
    const validationResult = checkPasswordValidation(password || '');
    return Object.values(validationResult).every(item => !!item);
  };

  handleCheckPasswordInputStatus = status => () => {
    const { isPasswordInputFocused, password } = this.state;
    if (isPasswordInputFocused && !status && !!password) {
      this.setState({
        showVerifyPasswordError: !this.checkNewPassword(password),
      });
    } else {
      this.setState({
        showVerifyPasswordError: false,
      });
    }

    this.setState({
      isPasswordInputFocused: status,
    });
  };

  render() {
    const {
      email,
      password,
      isHuman,
      emailValidation,
      team_invite_id,
      is_trainer,
      isResetCaptcha,
      showPassword,
      team_name,
      serverError,
      showError,
      isMisspelledEmail,
      emailTextSuggestion,
      isSignupByGoogle,
      isPasswordInputFocused,
      isOverlapVerifyTooltip,
      showVerifyPasswordError,
    } = this.state;

    const isInvalidNewPassword = !this.checkNewPassword(password);

    return (
      <S.Wrapper>
        <Layout>
          <FormStyles.FormContainer onSubmit={this.onSubmit}>
            {team_invite_id ? (
              <FormStyles.FormHeader>
                <S.JoinTeamWrapper>
                  <S.JoinTeamTitle>
                    <IconHorn />
                    Join your team at
                  </S.JoinTeamTitle>
                  <S.JoinTeamName>{team_name}</S.JoinTeamName>
                </S.JoinTeamWrapper>
              </FormStyles.FormHeader>
            ) : (
              <FormStyles.FormHeader>Sign up for Everfit</FormStyles.FormHeader>
            )}
            <ButtonGoogleAuth
              title="Sign up"
              type="button"
              disabled={isSignupByGoogle}
              onHandleSuccess={this.onHandleSuccess}
            />
            {serverError && <FormStyles.ServerErrorMessage isGoogleAuth>{serverError}</FormStyles.ServerErrorMessage>}
            <S.SubtitleContent>Or Sign up with e-mail</S.SubtitleContent>
            <FormStyles.FormGroup className={classnames({ error: showError && !!emailValidation.error })}>
              <FormStyles.Controls>
                <label className={email ? '' : 'hide'}>Your Email Address</label>
                <input
                  defaultValue={email}
                  id="email"
                  type="email"
                  placeholder="Your Email Address"
                  onChange={this.onEmailChange}
                  value={email}
                  name="password"
                />
              </FormStyles.Controls>
              {isMisspelledEmail && emailTextSuggestion && (
                <S.SuggestEmailInput>
                  Did you mean&nbsp;
                  {this.handleShowEmailSuggestion(emailTextSuggestion)}?
                </S.SuggestEmailInput>
              )}
              <ErrorMessage>
                {emailValidation.error === EMAIL_ERRORS.EXISTED
                  ? 'This email address is already in use'
                  : 'Invalid email'}
              </ErrorMessage>
            </FormStyles.FormGroup>
            <FormStyles.FormGroup
              className={classnames('form-group--password', {
                error: (showError && password && password.length < 8) || showVerifyPasswordError,
              })}
            >
              <FormStyles.Controls>
                <label className={password ? '' : 'hide'}>Create Password</label>
                <input
                  ref={this.passwordInput}
                  defaultValue={password}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Create Password"
                  onChange={this.onPasswordChange}
                  value={password}
                  name="password"
                  onBlur={this.handleCheckPasswordInputStatus(false)}
                  onFocus={this.handleCheckPasswordInputStatus(true)}
                />
                <S.Eye onClick={this.toggleShowPassword}>{showPassword ? closeEye : openEye}</S.Eye>
                {isPasswordInputFocused && (
                  <S.VerifyPasswordTooltip isOverlap={isOverlapVerifyTooltip}>
                    <VerifyPasswordTooltip passwordValue={password} isOverlap={isOverlapVerifyTooltip} />
                  </S.VerifyPasswordTooltip>
                )}
              </FormStyles.Controls>
              {!showVerifyPasswordError && <ErrorMessage>Password must have at least 8 characters</ErrorMessage>}
            </FormStyles.FormGroup>
            <S.AccountTypeContainer>
              <S.AccountType
                data-select-role="I’m a Coach"
                active={is_trainer}
                onClick={() => this.onSelectAccountType(true)}
              >
                I’m a Coach
              </S.AccountType>
              <S.AccountType
                data-select-role="I’m a Client"
                active={!is_trainer}
                onClick={() => this.onSelectAccountType(false)}
              >
                I’m a Client
              </S.AccountType>
            </S.AccountTypeContainer>
            {!window.Cypress ? (
              <Captcha
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                lang="en"
                theme="light"
                type="image"
                isResetCaptcha={isResetCaptcha}
                callback={this.captchaCallback}
                expiredCallback={this.captchaExpiredCallback}
              />
            ) : (
              <div style={{ paddingBottom: '40px' }}></div>
            )}
            <FormStyles.SubmitButton
              disabled={
                (!isHuman || !email || !password || isSignupByGoogle || isInvalidNewPassword) && !window.Cypress
              }
            >
              <span>Sign up for free</span>
              <img src={`${CDN_URL}/images/arrow.svg`} alt="" />
            </FormStyles.SubmitButton>
            <FormStyles.FormFooter>
              <span>Already have an account?&nbsp;</span>
              <Link to={`/login${window.location.search}`} className="highlight">
                Log In
              </Link>
            </FormStyles.FormFooter>
          </FormStyles.FormContainer>
          <S.Agree>
            By continuing you agree with Everfit’s{' '}
            <a href="https://everfit.io/tos/" target="_blank" className="highlight">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://everfit.io/privacy/" target="_blank" className="highlight">
              Privacy Policy
            </a>
          </S.Agree>
        </Layout>
        {this.props.isModalOpen ? this.props.modal : null}
      </S.Wrapper>
    );
  }
}

export default Register;

const openEye = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.97813 12.5C10.1281 12.5 12.5781 9.7875 13.6281 8.2125C14.0656 7.6 14.0656 6.8125 13.6281 6.2C12.5781 4.7125 10.1281 2 6.97813 2C3.82813 2 1.37813 4.7125 0.328125 6.2875C-0.109375 6.9 -0.109375 7.6875 0.328125 8.2125C1.37813 9.7875 3.82813 12.5 6.97813 12.5ZM6.97813 4.625C8.46563 4.625 9.60313 5.7625 9.60313 7.25C9.60313 8.7375 8.46563 9.875 6.97813 9.875C5.49063 9.875 4.35313 8.7375 4.35313 7.25C4.35313 5.7625 5.49063 4.625 6.97813 4.625Z"
      fill="#BFC6D0"
    />
  </svg>
);

const closeEye = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7751 4.89999L5.6001 12.075C6.0376 12.1625 6.5626 12.25 7.0001 12.25C10.1501 12.25 12.6001 9.53749 13.6501 7.96249C14.0876 7.34999 14.0876 6.56249 13.6501 5.94999C13.4751 5.68749 13.1251 5.33749 12.7751 4.89999Z"
      fill="#BFC6D0"
    />
    <path
      d="M12.5125 0.2625L10.15 2.625C9.1875 2.1 8.1375 1.75 7 1.75C3.85 1.75 1.4 4.4625 0.35 6.0375C-0.0875 6.65 -0.0875 7.4375 0.35 7.9625C0.7875 8.6625 1.575 9.5375 2.45 10.325L0.2625 12.5125C-0.0875 12.8625 -0.0875 13.3875 0.2625 13.7375C0.4375 13.9125 0.6125 14 0.875 14C1.1375 14 1.3125 13.9125 1.4875 13.7375L13.7375 1.4875C14.0875 1.1375 14.0875 0.6125 13.7375 0.2625C13.3875 -0.0875 12.8625 -0.0875 12.5125 0.2625ZM4.6375 8.1375C4.4625 7.7875 4.375 7.4375 4.375 7C4.375 5.5125 5.5125 4.375 7 4.375C7.4375 4.375 7.7875 4.4625 8.1375 4.6375L4.6375 8.1375Z"
      fill="#BFC6D0"
    />
  </svg>
);
