import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bulkSendInvitation } from 'redux/client/client.actionCreators';
import BulkSendInvitation from './component';

const mapDispatch = dispatch => ({
  bulkSendInvitation: bindActionCreators(bulkSendInvitation, dispatch),
})

export default connect(null, mapDispatch)(BulkSendInvitation);
