import React from 'react';
import { components } from 'react-select';
import { ReactComponent as SurveyIcon } from 'assets/icons/survey_item_icon.svg';

function CustomControl(props) {
  const { children, ...restProps } = props;
  return (
    <components.Control {...restProps}>
      <div className="control__wrapper">
        <div className="control__wrapper-icon">
          <SurveyIcon />
        </div>
        <div className="control__wrapper-value">{children}</div>
      </div>
    </components.Control>
  );
}

export default CustomControl;
