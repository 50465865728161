import styled, { css } from 'styled-components';

export const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  margin-bottom: 25px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  padding: 25px 20px;
  margin-bottom: 17px;
  border-radius: 5px;

  ${props =>
    props.isEditMode &&
    css`
      margin-bottom: 280px;
    `}

  .evfSelectBoxContainer {
    .evfSelectBox__control {
      background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
      border: 1px solid #dadfea;
      border-radius: 5px;
    }

    .evfSelectBox__dropdown-indicator {
      padding-right: 13px;
    }

    .select__option {
      padding: 10px 15px;
      cursor: pointer;

      :hover {
        background: #f7f7ff;
        border-radius: 5px;
      }
    }
  }
`;

export const SingleLabel = styled.div`
  position: relative;
  overflow: hidden;

  .name {
    display: block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;

    .is-me {
      font-weight: 400;
    }
  }

  .email {
    margin-top: 3px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.4;
  }
`;

export const ContactInformation = styled.div`
  flex: 1 1;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: flex-start;
  align-items: center;

  .sb-avatar {
    margin-right: 14px;
  }
`;
