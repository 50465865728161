import moment from 'moment';
import round from 'lodash/round';
import { convertMinToFromHourMin } from 'utils/commonFunction';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
const TIME_FORMAT = 'h:mm A';

export const renderDuration = value => {
  const { hour, min } = convertMinToFromHourMin(round(value || 0));
  let label = min ? `${round(min)} m` : '';
  if (hour) {
    label = `${hour} h ${label}`;
  }
  return label;
};

export const renderTime = item => {
  const { start_time, end_time, start, end } = item;
  if (!start_time || !end_time) return '--';

  const startMM = moment(`${start} ${start_time}`, DATE_TIME_FORMAT);
  const endMM = moment(`${end} ${end_time}`, DATE_TIME_FORMAT);
  return `${startMM.format(TIME_FORMAT)} - ${endMM.format(TIME_FORMAT)}`;
};
