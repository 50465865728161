// Lib
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

// Shared
import Ownership from 'components/Ownership';

// Constants
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

const ShareSetting = ({
  ownerValue,
  shareValue,
  hasAvatar,
  hasSpacing,
  setOwnerValue,
  setShareValue,
  userId,
  hasYou,
}) => {
  const owner = get(ownerValue, 'owner', null);
  const shared = get(shareValue, 'share', null);

  const handleChangeShare = (e, data) => {
    const shareVal = shareValue;

    shareVal.share = data.value;

    setShareValue(shareVal);
  };

  const handleChangeOwner = (e, data) => {
    const ownerVal = cloneDeep(ownerValue || {});
    const shareVal = cloneDeep(shareValue || {});

    ownerVal.owner = data.value;
    if (data.value === 'no_owner') {
      ownerVal.owner = null;
      shareVal.share = TEAM_SHARE_NOOWNER;
    } else if (shareVal.share === TEAM_SHARE_NOOWNER) {
      shareVal.share = TEAM_SHARE_PRIVATE;
    }

    setShareValue(shareVal);
    setOwnerValue(ownerVal);
  };

  return (
    <Ownership
      isEditAble={shared === TEAM_SHARE_NOOWNER || owner === userId}
      owner={owner}
      share={shared}
      hasAvatar={hasAvatar}
      hasSpacing={hasSpacing}
      handleChangeShare={handleChangeShare}
      handleChangeOwner={handleChangeOwner}
      hasYou={hasYou}
    />
  );
};

export default ShareSetting;
