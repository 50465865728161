import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const CancelButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  background: #fff url(${CDN_URL}/images/close_purple.svg) no-repeat center;
  background-size: auto;
  cursor: pointer;
  border-radius: 500%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.small && css`
    width: 18px;
    height: 18px;
    background-size: 8px;
  `}

  ${props => props.middle && css`
    background-size: 20px 20px!important;
  `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`;
