import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffff;
`;

const statusVariants = {
  protein: {
    backgroundColor: '#57B5EA',
  },
  carbs: {
    backgroundColor: '#65CC9C',
  },
  fat: {
    backgroundColor: '#FFBE49',
  },
};

export const Item = styled.div`
  width: calc(25% - 10px);
`;

export const Protein = styled.div`
  cursor: not-allowed;
  width: calc(25% - 10px);
  &:hover {
    .unit-input {
      border: 1px solid #5158cf;
    }
  }
  .__react_component_tooltip {
    width: 208px;
  }
  .__react_component_tooltip.place-top {
    margin-top: -2px;
  }
  .__react_component_tooltip {
    ${baseText}
    padding: 8px 16px;
  }
`;

export const MacroSection = styled.div`
  width: 100%;
  background-color: #fbfbfb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 35px;
`;

export const MacroList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 9px 10px;
  ${({ isEmptyValue }) =>
    isEmptyValue &&
    css`
      gap: 20px;
    `}
`;

export const MacroItem = styled.div`
  ${baseText}
  position: relative;
  color: #202353;
  letter-spacing: -0.6px;
  min-width: 96px;
  padding-left: 22px;
  span.percent {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.6px;
    padding-left: 3px;
    span.percentage {
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.5px;
    }
  }
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => statusVariants[props.status].backgroundColor || 'transparent'};
    position: absolute;
    top: 7px;
    left: 2px;
  }

  ${props =>
    props.isBlankData &&
    css`
      &::before {
        background-color: #a3a3b5;
      }
    `}
`;

export const ValueMacroItem = styled.div`
  ${baseText}
  color: #202353;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: -0.95px;
  .value-empty {
    font-weight: 400;
    color: #7b7e91;
  }
`;

export const ValueMacroUnit = styled.div`
  display: inline-block;
  ${baseText}
  color: #202353;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.75px;
`;

export const MacroMain = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

export const MacroChart = styled.div`
  position: relative;
  .macro-chart-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.blank-data {
      path {
        fill: #a3a3b5;
      }
    }
  }
`;

export const CaloriesContent = styled.div`
  text-align: center;
  ${({ isEmptyValue }) =>
    isEmptyValue &&
    css`
      min-width: 88px;
    `}
`;

export const CaloriesValue = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #202353;
  letter-spacing: -1.6px;
  .value-empty {
    font-weight: 400;
    color: #7b7e91;
  }
`;

export const CaloriesUnit = styled.span`
  ${baseText}
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #202353;
  letter-spacing: -1.2px;
  ${({ isEmptyValue }) =>
    isEmptyValue &&
    css`
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -1.6px;
    `}
`;

export const CaloriesName = styled.div`
  ${baseText}
  font-size: 13px;
  line-height: 20px;
  color: #202353;
  letter-spacing: -0.65px;
`;
