import React, { memo, useState } from 'react';

import * as S from 'shared/Giphy/styles';
import GiphyWatermarkIcon from 'assets/icons/giphy_watermark.png';
import PlaceholderLoader from './PlaceholderLoader';

const Watermark = props => {
  const {
    children,
    maxSize = true,
    bgLoaderTransparent,
    showLoader = true,
    showWatermark = true,
    repliedSize = false,
    isReplyComment = false,
    loaderMaxSize = false,
    ratioComment = false,
    ratioCommentForum = false,
    isInbox = false,
  } = props;
  const [loaded, setLoaded] = useState(false);

  const handleLoadSuccess = event => {
    event.preventDefault();
    setLoaded(true);
  };

  if (!children) return <></>;

  if (!showLoader) {
    return (
      <S.Watermark maxSize={maxSize} bgLoaderTransparent={bgLoaderTransparent}>
        {children && React.cloneElement(children)}
        {showWatermark && <img src={GiphyWatermarkIcon} className="giphy-watermark" alt="" />}
      </S.Watermark>
    );
  }

  return (
    <S.Watermark maxSize={maxSize} bgLoaderTransparent={bgLoaderTransparent}>
      {!loaded && (
        <PlaceholderLoader
          isReplyComment={isReplyComment}
          loaderMaxSize={loaderMaxSize}
          repliedSize={repliedSize}
          ratioComment={ratioComment}
          ratioCommentForum={ratioCommentForum}
          isInbox={isInbox}
        />
      )}
      <div style={{ display: loaded ? 'block' : 'none', height: 'inherit' }}>
        {children && React.cloneElement(children, { onLoad: handleLoadSuccess })}
      </div>
      {loaded && showWatermark && <img src={GiphyWatermarkIcon} className="giphy-watermark" alt="" />}
    </S.Watermark>
  );
};

export default memo(Watermark);
