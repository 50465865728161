import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #202353; 
`;

export const Wrapper = styled.div`
  padding: 0 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 20px;
`;

export const AddMeal = styled.div`
  ${baseText}
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 252px;
  height: 238px;
  background-color: #f0f0f2;
  border-radius: 5px;
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
    margin-right: 9px;
    path {
      stroke: #202353;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    svg path {
      stroke: #5158cf;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: #f0f0f2;
        color: #202353;
        svg path {
          stroke: #202353;
        }
      }
    `}
`;

export const MealEmpty = styled.div`
  border-radius: 5px;
  width: 252px;
  height: 238px;
  border: 1px dashed #e1e1ea;
  flex-shrink: 0;
`;
