import styled from 'styled-components';

export const SurveyItemWrapper = styled.div`
  padding: 24px;
  border: 1px solid #ebedf4;
  border-radius: 8px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &.survey__active:hover {
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  & .survey__actions {
    &.evf-dropdown.evf-dropdown--basic.open {
      > .evf-dropdown__menu {
        display: flex;
        flex-direction: column;
        min-width: 159px;
        border-radius: 8px;
        gap: 4px;
        padding: 8px 0;
      }
    }

    .evf-dropdown__option {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 12px;
      /* margin-top: 4px; */
      user-select: none;
      height: 30px;
      padding: 0 15px;
      letter-spacing: -0.4px;

      &.isLoading {
        cursor: not-allowed;
      }

      svg {
        width: 16px;
        height: 16px;

        &.white-color {
          g {
            stroke: #fff;
          }
        }
      }
    }
  }
`;

export const SurveyInfo = styled.div`
  flex: 1;
  width: calc(100% - 52px);

  .survey__info-common {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 11px;
    color: #aaacae;
    font-weight: 600;
    p {
      white-space: nowrap;

      &.survey__info-author {
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  > h6 {
    color: #202353;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 8px;
    width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    max-width: 476px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SurveyInformationWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  width: calc(100% - 25px);
`;

export const SurveyIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SurveyMenuButton = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  &:hover {
    cursor: pointer;
    background: #e7ebee;
  }

  .evf-dropdown__menu {
    min-width: 159px;
    border-radius: 8px;
  }

  .evf-dropdown__option {
    font-weight: 600;
    &.isLoading {
      cursor: not-allowed;
    }

    svg {
      margin-right: 11px;
      width: 16px;

      &.white-color {
        path {
          fill: white;
        }
      }
    }
  }
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #c4c4c4;
  flex-shrink: 0;
`;

export const SelectedSurveyRadio = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #5158cf;
  border-radius: 100%;
  position: relative;
  flex-shrink: 1;
  border-color: #ebedf4;
`;
