import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import TaskReminder from './component';
import {
  getTask,
  fetch,
  updateTask,
  addTask,
  removeTask,
  markAsCompleted,
  fetchCompletedTask,
  fetchFollowingTask,
  arrangeTask,
  searchTrainers,
  unmarkAsCompleted,
} from 'redux/personal-task-reminder/action';

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const taskReminder = rootReducer.taskReminder.toJS();
  const notification = { ...rootReducer.notification };
  return { taskReminder, user, notification };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  getTask: bindActionCreators(getTask, dispatch),
  fetch: bindActionCreators(fetch, dispatch),
  arrangeTask: bindActionCreators(arrangeTask, dispatch),
  fetchCompletedTask: bindActionCreators(fetchCompletedTask, dispatch),
  updateTask: bindActionCreators(updateTask, dispatch),
  addTask: bindActionCreators(addTask, dispatch),
  removeTask: bindActionCreators(removeTask, dispatch),
  markAsCompleted: bindActionCreators(markAsCompleted, dispatch),
  unmarkAsCompleted: bindActionCreators(unmarkAsCompleted, dispatch),
  fetchFollowingTask: bindActionCreators(fetchFollowingTask, dispatch),
  searchTrainers: bindActionCreators(searchTrainers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskReminder);
