import { connect } from 'react-redux';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { toggleModal, toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import TaskCreate from 'components/TaskCreate/component';
import { submitTask, removeSettingRepeat } from 'redux/autoflowInterval/task/actions';
import { saveToLibrary, addRepeatTask } from 'redux/task/task.actionCreators';

const mapStateToProps = state => {
  const { isModalOpen, rootReducer } = state;
  const {
    settings: { metrics, features },
    common: { workingAutoflow },
  } = rootReducer.autoflow;

  const { common } = rootReducer.autoflowInterval;

  return {
    bodymetricTypes: metrics.filter(item => item.selected),
    isModalOpen,
    features,
    viewMode: common.get('viewMode'),
    startWeek: common.get('startWeek'),
    totalWeeks: workingAutoflow.interval_weeks.length,
    repeatTaskPermission: get(rootReducer, 'permission.repeat_task'),
    repeatTaskAutoFlowPermission: get(rootReducer, 'permission.repeat_task_autoflow'),
    getAttachmentUrl: '/api/autoflow-interval-task/v2/get-attachment',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    addRepeatTask: bindActionCreators(addRepeatTask, dispatch),
    removeSettingRepeat: bindActionCreators(removeSettingRepeat, dispatch),
    addTask: bindActionCreators(submitTask, dispatch),
    updateTask: bindActionCreators(submitTask, dispatch),
    saveToLibrary: bindActionCreators(saveToLibrary, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCreate);
