import { Map, List } from 'immutable';
import { Workout } from 'types/model';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { updateExerciseLibraryInWorkout } from 'helpers/workout';

import { MOVE_WORKOUT, ADD_WORKOUT, MOVE_WORKOUT_SUCCESS, UPDATE_WORKOUTS, MOVE_EXERCICE } from 'actions/workout';

import {
  REQUEST_GET_ASSIGNMENTS,
  SUCCESS_GET_ASSIGNMENTS,
  COPY_ASSIGNMENT,
  COPIED_ASSIGNMENT,
  ADD_ASSIGNMENT_TO_DATE,
  RESET_COPY_ASSIGNMENT,
  SUCCESS_GET_LOGGED_ACTIVITIES,
  Types,
} from 'actions/workout/getWorkouts';

import { Types as ClientCalendarTypes } from 'redux/calendar/calendar.actionCreators';

export const movingSet = (state = '', action) => {
  switch (action.type) {
    case 'MOVE_EXERCISE_SET':
      return action.data ? action.data.set : '';
    default:
      return '';
  }
};

export const loggedActivities = (state = new Map(), action) => {
  switch (action.type) {
    case SUCCESS_GET_LOGGED_ACTIVITIES:
      if (action.payload.length > 0) {
        let activities = new Map();
        action.payload.forEach(itm => {
          activities = activities.set(itm.day, itm.activities);
        });
        return activities;
      } else {
        return new Map();
      }

    case Types.SUCCESS_GET_LOGGED_ACTIVITIES_AND_UPDATE: {
      const { data } = action.payload;
      if (data.length > 0) {
        let newActivities = new Map();

        data.forEach(itm => {
          newActivities = newActivities.set(itm.day, itm.activities);
        });
        return mergeMaps(state, newActivities);
      } else {
        return new Map();
      }
    }

    default:
      return state;
  }
};

export const workouts = (state = new Map(), action) => {
  switch (action.type) {
    case 'SELECT_CLIENT':
      return new Map();
    case REQUEST_GET_ASSIGNMENTS:
      return new Map();
    case SUCCESS_GET_ASSIGNMENTS:
      if (action.workouts.length > 0) {
        let workouts = new Map();

        action.workouts.forEach(itm => {
          workouts = workouts.set(itm.day, itm.workouts);
        });
        return workouts;
      } else {
        return new Map();
      }

    case 'UPDATE_ASSIGNMENT_INFO':
      let itm = action.workout;
      state = state.set(itm.day, itm);
      return state;

    case 'DELETE_ASSIGNMENT':
      let deleteItem = action.workout;
      return state.update(deleteItem.day, workouts => {
        let workoutsCopy = workouts.slice();
        _.remove(workoutsCopy, item => item._id === deleteItem._id);
        return workoutsCopy;
      });

    case ADD_WORKOUT:
      return state.set(action.to, action.workout);
    case MOVE_WORKOUT: {
      const { payload } = action;
      const dragWorkout = state.getIn([payload.oldDay])[payload.fromIndex];
      dragWorkout.day = payload.newDay;
      return state
        .update(payload.oldDay, list => {
          list.splice(payload.fromIndex, 1);
          return list;
        })
        .update(payload.newDay, list => {
          return List(list).splice(payload.newIndex, 0, dragWorkout).toJS();
        })
        .set('dragingAssignmentId', payload.assignmentId || null);
    }
    case MOVE_WORKOUT_SUCCESS:
      return state.set('dragingAssignmentId', null);

    case MOVE_EXERCICE: {
      const { payload } = action;
      const fromWorkouts = state.get(payload.from);
      const fromWorkoutIndex = _.findIndex(fromWorkouts, o => o._id === payload.fromAssignment);
      const movingSection = fromWorkouts[fromWorkoutIndex].sections.find(o => o._id === payload.sectionId);
      return state
        .update(payload.from, list => {
          list[fromWorkoutIndex].sections = list[fromWorkoutIndex].sections.filter(o => o._id !== payload.sectionId);
          if (!list[fromWorkoutIndex].sections.length) {
            list.splice(fromWorkoutIndex, 1);
          }
          return list;
        })
        .update(payload.to, list => {
          const toWorkoutIndex = _.findIndex(list, o => o._id === payload.toAssignment);
          if (toWorkoutIndex !== -1) {
            list[toWorkoutIndex].sections.splice(payload.newIndex, 0, movingSection);
          }
          return list;
        })
        .set('dragingAssignmentId', payload.fromAssignment || null);
    }
    case UPDATE_WORKOUTS:
      let updateState = new Map();
      _.forEach(action.workouts, workout => {
        const list = state.get(workout.day) || [];
        const index = _.findIndex(list, o => o._id === workout._id);
        if (index === -1) {
          list.push(workout);
        } else {
          list[index] = workout;
        }
        updateState = updateState.set(workout.day, [...list]);
      });
      return state.merge(updateState).set('dragingAssignmentId', null);

    case 'MOVE_EXERCISE_SET':
      const { data, error } = action;
      if (!data || error) {
        return state;
      }
      let fromDT = DateTime.fromJSDate(new Date(data.from));
      let toDT = DateTime.fromJSDate(new Date(data.to));
      let fromDate = data.from;
      let toDate = data.to;
      if (fromDT.isValid) {
        fromDate = fromDT.toFormat('MM-dd-yyyy');
      }
      if (toDT.isValid) {
        toDate = toDT.toFormat('MM-dd-yyyy');
      }
      let from = state.get(fromDate, null);
      let to = state.get(toDate, null);

      if (!from) {
        return state;
      }
      const idx = _.findIndex(from.sets, s => s._id === data.set);
      const set = from.sets[idx];
      from.training_sets.splice(idx, 1);

      if (fromDate === toDate) {
        from.training_sets.splice(data.index, 0, set);
        return state.set(fromDate, from);
      }

      if (!to) {
        to = new Workout();
        to.title = 'New workout';
        to.date = action.to;
        to.day = toDate;
        to.training_sets.push(set);
      } else {
        to.training_sets.splice(data.index, 0, set);
      }
      state = state.set(fromDate, from.training_sets.length === 0 ? null : from).set(toDate, to);
      return state;

    case ADD_ASSIGNMENT_TO_DATE:
      return state.get(action.date)
        ? state.update(action.date, list => list.concat([action.data]))
        : state.update(action.date, () => [action.data]);

    case 'UPDATE_EXERCISE_LIB_FROM_CALENDAR':
      const { day, assignmentId, oldExerciseId, newExerciseLibrary, categories, fields, unit } = action.payload.data;

      if (state.get(day)) {
        return state.update(day, list => {
          const workouts = list.slice();

          _.forEach(workouts, workout => {
            if (workout._id === assignmentId) {
              workout.sections = updateExerciseLibraryInWorkout(workout.sections, oldExerciseId, newExerciseLibrary, {
                categories,
                fields,
                unit,
              });

              return false;
            }
          });

          return workouts;
        });
      }

      return state;
    case ClientCalendarTypes.CLIENT_CALENDAR_TRAINING_REMOVE_WEEK_SUCCESS:
      return state.map(it => {
        if (!_.isArray(it)) return it;
        return it.filter(workout => !_.includes(action.payload, _.get(workout, '_id')));
      });
    case ClientCalendarTypes.CLIENT_CALENDAR_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS:
      return state.map(it => {
        if (!_.isArray(it)) return it;
        return it.filter(workout => !_.includes(action.payload, _.get(workout, '_id')));
      });
    case ClientCalendarTypes.CLIENT_CALENDAR_TRAINING_NEW_ASSIGNMENT:
      if (_.get(action, 'payload.day')) {
        const copyWorkout = new Workout();
        copyWorkout.parseFromAssignment(_.get(action, 'payload'));
        return state.get(copyWorkout.day)
          ? state.update(copyWorkout.day, list => _.unionBy(list.concat(copyWorkout), '_id'))
          : state.update(copyWorkout.day, () => [copyWorkout]);
      }
      return state;

    case Types.SUCCESS_GET_ASSIGNMENTS_AND_UPDATE: {
      const { data } = action.payload;
      if (data.length > 0) {
        let newWorkouts = new Map();

        data.forEach(itm => {
          newWorkouts = newWorkouts.set(itm.day, itm.workouts);
        });
        return mergeMaps(state, newWorkouts);
      } else {
        return new Map();
      }
    }
    default:
      return state;
  }
};

export const copyingAssignment = (state = null, action) => {
  switch (action.type) {
    case COPY_ASSIGNMENT:
      return action.item;
    case COPIED_ASSIGNMENT:
      return action.multiPaste ? state : null;
    case 'CLIENT_TRAINING_CLEAR_COPY_ASSIGMENT':
      return null;
    case RESET_COPY_ASSIGNMENT:
      return null;
    default:
      return state;
  }
};

const mergeMaps = (map1, map2) => {
  let resultMap = Map();

  const mergeArrays = (arr1, arr2) => {
    const combined = [...arr1, ...arr2];
    const uniqueById = combined.reduce((acc, obj) => {
      acc[obj._id] = obj;
      return acc;
    }, {});
    return Object.values(uniqueById);
  };

  map1.forEach((value, key) => {
    resultMap = resultMap.set(key, value);
  });

  map2.forEach((value, key) => {
    if (resultMap.has(key)) {
      const mergedArray = mergeArrays(resultMap.get(key), value);
      resultMap = resultMap.set(key, mergedArray);
    } else {
      resultMap = resultMap.set(key, value);
    }
  });

  return resultMap;
};
