import React from 'react';
import { addResponsiveMetaTag } from 'utils/event-api';

export default function withResponsive(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      if (window.innerWidth < 982) {
        addResponsiveMetaTag();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
