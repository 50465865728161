import React, { useEffect, useMemo } from 'react';
import { get } from 'lodash';
import TableHeader from 'components/SectionLibrary/Parts/TableHeader';
import TableBody from 'components/SectionLibrary/Parts/TableBody';
import Footer from './Parts/Footer';
import Toolbar from './Parts/Toolbar';
import CreateSectionTemplate from 'components/CreateSectionTemplate';
import LoadingIndicator from 'shared/LoadingIndicator';
import * as Layout from 'shared/LibraryLayout';
import { saveLastLibraryRoute } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

import * as S from './style.js';

import { ReactComponent as SectionsNotFound } from 'assets/icons/sections-no-results.svg';
import { ReactComponent as SectionsEmpty } from 'assets/icons/sections-empty.svg';

function SectionLibrary(props) {
  const {
    sections,
    match,
    total,
    loading,
    query,
    toggleModal,
    getSections,
    openSectionWorkoutDetail,
    mode,
    duplicateSectionLibrary,
    resetQueryParams,
    toggleSideBar,
    updateSelectedSection,
  } = props;
  const {
    params: { sectionId },
  } = match;

  const onNew = () => {
    toggleModal(true, <CreateSectionTemplate />);
  };

  useEffect(() => {
    if (sectionId) {
      if (mode === 'edit') {
        openSectionWorkoutDetail && openSectionWorkoutDetail(sectionId);
      }
      if (mode === 'duplicate') {
        duplicateSectionLibrary && duplicateSectionLibrary(sectionId);
      }
    }
  }, [sectionId, mode]);

  useEffect(() => {
    getSections && getSections();
    toggleSideBar && toggleSideBar(true);
    saveLastLibraryRoute('/home/sections');

    return () => {
      resetQueryParams && resetQueryParams();
      updateSelectedSection && updateSelectedSection([]);
    };
  }, []);

  const isEmptyWithoutSearch = useMemo(() => {
    return (
      !loading &&
      total <= 0 &&
      !sections.length &&
      !get(query, 'query', '').length > 0 &&
      !get(query, 'formats', []).length > 0 &&
      !get(query, 'owners', []).length > 0
    );
  }, [loading, query, total, sections]);

  const isSearchEmpty = useMemo(() => {
    return (
      !loading &&
      total <= 0 &&
      !sections.length &&
      (get(query, 'query', '').length > 0 || get(query, 'formats', []).length || get(query, 'owners', []).length > 0)
    );
  }, [loading, query, total, sections]);

  return (
    <S.LayoutWrapper>
      <Toolbar />
      <S.TableContainerWrapper>
        <Layout.Table>
          <TableHeader />
          <TableBody sections={sections} />
        </Layout.Table>
        {loading && !query.query && !sections.length && <LoadingIndicator />}
        {isSearchEmpty ? (
          <Layout.NoResult>
            <S.NotFoundWrapper>
              <SectionsNotFound />
            </S.NotFoundWrapper>
            <S.NoResultText>No results found</S.NoResultText>
          </Layout.NoResult>
        ) : (
          isEmptyWithoutSearch && (
            <Layout.NoResult>
              <S.EmptyWrapper>
                <SectionsEmpty />
              </S.EmptyWrapper>
              <S.FirstSectionButton purple onClick={onNew}>
                <img className="plus-icon" src={`${CDN_URL}/images/plus_white.svg`} alt="" />
                <span>Add Your First Section</span>
              </S.FirstSectionButton>
            </Layout.NoResult>
          )
        )}
      </S.TableContainerWrapper>
      <Footer />
    </S.LayoutWrapper>
  );
}

export default SectionLibrary;
