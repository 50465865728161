import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateCustomBranding,
  updateCustomBrandingPremium,
  publishAppIconCustomBrandingPremium,
  cancelAppIconCustomBrandingPremium,
} from 'redux/custom-branding/actions';
import SettingMobileAppPremium from './component';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: {
      customBranding: {
        loading,
        selectedWorkoutBackground,
        selectedSplashLogo,
        selectedSplashBackground,
        selectedAppIconKey,
        selectedAppIconReview,
        selectedAppIconUrl,
        selectedTodayScreenLogo,
        publisedAppIcon,
        originalTheme,
        customOnboardingVideoType,
        customOnboardingYoutube,
        customOnboardingUpload,
        customOnboardingDraft,
        appIconDefault,
        appIconKeySystemUrl,
        appIconKeySystem,
        appIconFlowType,
      },
      permission: { premiumCustomBrand },
    },
  } = state;
  return {
    loading,
    cloudfrontList,
    selectedWorkoutBackground,
    selectedSplashLogo,
    selectedSplashBackground,
    selectedAppIconKey,
    selectedAppIconReview,
    selectedAppIconUrl,
    publisedAppIcon,
    originalTheme,
    premiumCustomBrand,
    selectedTodayScreenLogo,
    customOnboardingVideoType,
    customOnboardingYoutube,
    customOnboardingUpload,
    customOnboardingDraft,
    currentAppIconDefault: appIconDefault || {},
    appIconKeySystemUrl,
    appIconKeySystem,
    appIconFlowType,
  };
};

const mapDispatch = dispatch => ({
  updateCustomBranding: bindActionCreators(updateCustomBranding, dispatch),
  updateCustomBrandingPremium: bindActionCreators(updateCustomBrandingPremium, dispatch),
  publishAppIcon: bindActionCreators(publishAppIconCustomBrandingPremium, dispatch),
  cancelAppIcon: bindActionCreators(cancelAppIconCustomBrandingPremium, dispatch),
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapState, mapDispatch)(SettingMobileAppPremium);
