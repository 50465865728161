import React from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { MAX_FILE_SIZE, CONVERSION, CDN_URL } from 'constants/commonData';
import * as S from './style';
import { SelectFile } from 'shared/FormControl';
import { createObjectURL, mediaLog, revokeObjectURL } from 'utils/commonFunction';
import MediaUploadProgress from 'shared/MediaUploadProgress';
import { VALIDATIONS } from 'components/TaskCreate/GeneralTaskForm/helper';

// const ACCEPT_FILES =
//   '.csv, .doc,.docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf';

const DEFAULT_STATE = {
  src: '',
  isLocalUrl: false,
  file: null,
};

function detectFileIcon(file, document_type) {
  let type = document_type;
  if (file) {
    type = _.last(file.name.split('.'));
  }
  switch (type) {
    case 'docx':
    case 'doc':
      return `${CDN_URL}/images/studio_resource_doc_icon.svg`;
    case 'pdf':
      return `${CDN_URL}/images/studio_resource_pdf_icon.svg`;
    case 'xlsx':
    case 'xls':
    case 'csv':
      return `${CDN_URL}/images/studio_resource_xlsx_icon.svg`;
    default:
      return '';
  }
}

class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.maxSize = MAX_FILE_SIZE * CONVERSION.MB_TO_BYTE;
    this.uploadConfigs = null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }
  }

  onSelectFile = file => {
    if (file.size > 25 * CONVERSION.MB_TO_BYTE) {
      return this.limitSizeError();
    }

    const formData = new FormData();
    formData.append('media', file);
    this.uploadConfigs = {
      url: '/api/upload/custom_brand',
      method: 'post',
      data: formData,
    };
    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via Resource',
    });

    const fileIcon = detectFileIcon(file);
    this.setState({ formData, src: createObjectURL(file), isLocalUrl: true, file, fileIcon }, () => {
      this.props.onStartUpload();
    });
  };

  onDropFile = files => {
    if (this.props.disabled) return;

    if (files.length) {
      const file = files[0];
      if (file.size > 25 * CONVERSION.MB_TO_BYTE) {
        return this.limitSizeError();
      }
      this.onSelectFile(file);
    } else {
      toast.error('File type not supported');
    }
  };

  limitSizeError = () => {
    return toast.error('File size limit is 25MB');
  };

  onUploadSuccess = data => {
    if (this._isMounted) {
      this.uploadConfigs = null;
      const { size } = this.state.file;
      const { originalname, file_extension } = data;

      mediaLog({
        status: 2,
        name: originalname,
        fileType: file_extension,
        description: `Upload success file via Resource`,
      });

      this.setState({ file: null }, () => {
        this.props.onUploadSuccess({ bucketData: data, src: this.state.src, fileSize: size });
      });
    }
  };

  onCancelUpload = () => {
    if (this._isMounted) {
      revokeObjectURL(this.state.src);
      this.uploadConfigs = null;
      this.setState({ ...DEFAULT_STATE }, () => {
        this.props.onEndUpload();
        this.props.onRemove();
      });
    }
  };

  onRemoveImage = () => {
    if (this.props.disabled) return;
    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }

    this.setState({ ...DEFAULT_STATE }, () => {
      this.props.onRemove();
    });
  };

  onErrorUpload = () => {};

  render() {
    const { disabled = false } = this.props;
    const { src, file } = this.state;
    const fileIcon = detectFileIcon(file, this.props.data.document_type);

    if (!file && !this.props.data.document_url) {
      return (
        <Dropzone
          onDrop={this.onDropFile}
          multiple={false}
          accept={VALIDATIONS.DOCUMENT.accepts}
          maxSize={this.maxSize}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Wrapper {...getRootProps()} isDragging={isDragActive}>
              <S.Placeholder isDragging>
                <S.Icon />
                <S.Text>
                  Drag and drop <b>.xls .doc .pdf</b> document here or&nbsp;
                  <SelectFile
                    trigger={<span>Choose file</span>}
                    onSelect={this.onSelectFile}
                    accept={VALIDATIONS.DOCUMENT.accepts}
                    validateExtentions={VALIDATIONS.DOCUMENT.extensions}
                    disabled={disabled}
                  />
                </S.Text>
              </S.Placeholder>
              <input {...getInputProps({ onClick: event => event.preventDefault() })} className="dropzone__input" />
            </S.Wrapper>
          )}
        </Dropzone>
      );
    }

    return (
      <Dropzone
        onDrop={this.onDropFile}
        multiple={false}
        accept={VALIDATIONS.DOCUMENT.accepts}
        maxSize={this.maxSize}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <S.Wrapper {...getRootProps()} isDragging={isDragActive} src={src || this.props.data.document_url}>
            {file ? (
              <MediaUploadProgress
                file={file}
                fileIcon={fileIcon}
                apiConfigs={this.uploadConfigs}
                onCancel={this.onRemoveImage}
                onError={this.onErrorUpload}
                onSuccess={this.onUploadSuccess}
              />
            ) : isDragActive ? (
              <S.Placeholder isDragging>
                <S.Icon />
                <S.Text>
                  Drag and drop <b>.xls .doc .pdf</b> document here or&nbsp;
                  <SelectFile
                    trigger={<span>Choose file</span>}
                    onSelect={this.onSelectFile}
                    accept={VALIDATIONS.DOCUMENT.accepts}
                    validateExtentions={VALIDATIONS.DOCUMENT.extensions}
                    disabled={disabled}
                  />
                </S.Text>
              </S.Placeholder>
            ) : (
              <MediaUploadProgress
                file={{ name: this.props.data.document_name, size: this.props.data.document_size }}
                fileIcon={fileIcon}
                onCancel={this.onRemoveImage}
                apiConfigs={this.uploadConfigs}
                viewMode
                viewOnly={disabled}
              />
            )}
          </S.Wrapper>
        )}
      </Dropzone>
    );
  }
}

export default DocumentUpload;
