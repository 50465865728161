import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Image } from 'semantic-ui-react';
import moment from 'moment';
import { debounce, get, isEmpty } from 'lodash';
import { deleteTaskLibrary } from 'redux/task/task.actionCreators';
import {
  changeQueryParams,
  getTasksLibrary,
  resetTaskLibraryStore,
  resetQueryParams,
} from 'redux/tasks-library/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { NewSearchInput } from 'shared/SearchInput';
import TaskCreate from 'components/TaskCreate/component';
import TaskCreateClient from 'components/TaskCreate';
import TaskAddNew from 'components/TaskAddNew';
import LoadingIndicator from 'shared/LoadingIndicator';
import TaskFilter from './TaskFilter';
import PreviewTask from './PreviewTask';
import classNames from 'classnames';
import OwnerAvatar from 'shared/OwnerAvatar';
import { Button } from 'shared/FormControl';
import { limitText } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

// import icons
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left-black.svg';

import * as S from './styles';

const TaskLibrary = props => {
  const {
    onBack,
    date,
    from,
    loading,
    page,
    query,
    isEnd,
    listTaskLibrary,
    getTasksLibrary,
    changeQueryParams,
    resetQueryParams,
    resetStore,
    isSearching,
  } = props;

  const [isLoadMore, setIsLoadMore] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskLibrary, setSelectedTaskLibrary] = useState(null);

  useEffect(() => {
    getTasksLibrary && getTasksLibrary();

    return () => {
      resetQueryParams();
      resetStore();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(listTaskLibrary) && !selectedTask) {
      setSelectedTask(listTaskLibrary[0]);
    }
  }, [listTaskLibrary]);

  const onClose = () => {
    props.toggleModal(false);
  };

  const onBackToList = () => {
    if (typeof onBack === 'function') {
      onBack();
    } else props.toggleModal(true, <TaskAddNew date={date} />);
  };

  const chooseTaskFromLibrary = taskData => () => {
    setSelectedTask(taskData);
  };

  const handleScrollEnd = event => {
    setIsLoadMore(false);
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && !loading && !isEnd) {
      setIsLoadMore(true);
      changeQueryParams({ page: page + 1 }, true);
    }
  };

  const searchTask = (event, { value }) => {
    setSelectedTask(null);
    const search = value.toLowerCase().trim();

    if (search !== query) {
      changeQueryParams({ page: 1, query: search });
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);
  const searchDebounce = debounce(searchTask, 300);

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      changeQueryParams({ page: 1 });
    }
  };

  const handleSelectTask = selectedTask => {
    setSelectedTaskLibrary(selectedTask);
  };

  if (selectedTaskLibrary) {
    const { _id, ...data } = selectedTask;

    return from === 'client' ? (
      <TaskCreateClient
        date={date}
        {...props}
        task={{
          ...data,
          autoflow: props.autoflowId,
          taskLibraryId: _id,
          date,
          day: moment(date).format('MM-DD-YYYY'),
        }}
        mode={'edit'}
        onBack={onBackToList}
      />
    ) : (
      <TaskCreate
        date={date}
        {...props}
        task={{
          ...data,
          autoflow: props.autoflowId,
          taskLibraryId: _id,
          date,
          day: moment(date).format('MM-DD-YYYY'),
        }}
        mode={'edit'}
        onBack={onBackToList}
      />
    );
  }

  const trackingIsFilter = isFilter => {
    setSelectedTask(null);
  };

  return (
    <S.ModalWrapper
      open={true}
      onClose={onClose}
      className="modal--task-library"
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.Wrapper>
          <S.Content>
            <S.LeftContent>
              <S.BackButton>
                <BackIcon className="back-icon" onClick={onBackToList} />
                <span>Task Library</span>
              </S.BackButton>
              <S.FilterWrapper>
                <TaskFilter onTrackingFilter={trackingIsFilter} />
              </S.FilterWrapper>
            </S.LeftContent>
            <S.MiddleContent>
              <NewSearchInput
                onChange={searchDebounce}
                onClearSearch={() => searchTask(null, { value: '' })}
                onKeyPress={onKeyPress}
                placeholder="Search for a task"
                className="search-task"
                searchIconPath={`${CDN_URL}/images/search-task.svg`}
              />
              <S.TasksWrapper
                onScroll={event => {
                  event.persist();
                  onScrollDebounce.call(null, event);
                }}
              >
                {isEmpty(listTaskLibrary) && !loading ? (
                  <S.NotFound>{isSearching ? 'No tasks found' : 'Start by adding your first task'}</S.NotFound>
                ) : (
                  listTaskLibrary.map(task => (
                    <S.TaskInfor
                      key={task._id}
                      className={classNames({ active: get(selectedTask, '_id', '') === get(task, '_id', 'no_id') })}
                      onClick={chooseTaskFromLibrary(task)}
                    >
                      <S.Infor>
                        <Image className="task-icon" src={`${CDN_URL}/images/task_icon_${task.type}_no_circle.svg`} />
                        <S.TaskTitle>{limitText(get(task, 'title'), 35)}</S.TaskTitle>
                      </S.Infor>
                      <OwnerAvatar
                        data={get(task, 'owner', '')}
                        tooltipId={`tooltip--owner-${get(task, '_id', '')}`}
                        size={24}
                        className="owner-avatar"
                      />
                    </S.TaskInfor>
                  ))
                )}
                {loading && isLoadMore && <LoadingIndicator className="loading" />}
              </S.TasksWrapper>
            </S.MiddleContent>
            <S.PreviewWrapper>
              <PreviewTask task={selectedTask} />
            </S.PreviewWrapper>
          </S.Content>
        </S.Wrapper>
      </Modal.Content>

      <S.ModalFooter>
        <Button purple onClick={() => handleSelectTask(selectedTask)} disabled={isEmpty(selectedTask)}>
          Select
        </Button>
      </S.ModalFooter>
    </S.ModalWrapper>
  );
};

const mapStateToProps = state => {
  const { list, loading, isEnd, queryParam } = state.rootReducer.tasksLibrary;
  const query = get(queryParam, 'query', '');
  const types = get(queryParam, 'types', []);
  const owners = get(queryParam, 'owners', []);
  const page = get(queryParam, 'page', 1);
  const isSearching = query.length || !isEmpty(types) || !isEmpty(owners);

  return {
    listTaskLibrary: list,
    loading: loading,
    query: query,
    page: page,
    isEnd: isEnd,
    isSearching,
    getAttachmentUrlInLibrary: 'api/task/v3/get-attachment-library',
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  deleteTaskLibrary: bindActionCreators(deleteTaskLibrary, dispatch),
  getTasksLibrary: bindActionCreators(getTasksLibrary, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
  resetStore: bindActionCreators(resetTaskLibraryStore, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskLibrary);
