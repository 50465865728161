import React from 'react';
import { DateTime } from 'luxon';
import Datetime from 'react-datetime';
import { push } from 'connected-react-router';
import moment from 'moment';
import _ from 'lodash';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { Workout } from 'types/model';
import { Modal, Button, Image, TextArea } from 'semantic-ui-react';
import './styles.scss';
import { classToPlain } from 'class-transformer';
import diff from 'deep-diff';
import TimeField from 'react-simple-timefield';
import SectionDetail from 'components/SectionDetail';
import { toast } from 'react-toastify';
import { convertFromWorkoutSetsAPIExercise, checkValidationTrainingSet } from 'helpers/workout';

import { DateInput } from 'shared/FormControl';
import { AUTO_FILL_FIELDS, CDN_URL, KEY_CODE, LOGGING_WORKOUT_MODES, SECTION_FORMAT_KEY } from 'constants/commonData';
import Comments from 'components/WorkoutHistoryDetail/Comments';

import { ReactComponent as MarkAllIcon } from 'assets/icons/check_all_grey.svg';

import * as S from './style';
import { autoFillInput } from 'components/ExerciseDetail/helpers';

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'MMM D, YYYY';
const FULL_DATE_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export default class LogWorkoutModal extends React.Component {
  constructor(props) {
    super(props);

    const {
      workingWorkout: { start_date, end_date },
    } = props;

    this.state = {
      workout: this.getDefaultWorkout(),
      originWorkout: this.getOriginWorkout(),
      isAdding: false,
      isChanged: false,
      isAddingNote: false,
      shouldFocusNote: false,
      startDate: start_date ? moment(start_date) : moment(),
      endDate: end_date ? moment(end_date) : moment(),
      showing: false,
      isErrorTrainingSet: false,
      isErrorSets: [],
      isMarkAllSection: false,
    };

    this.noteRef = React.createRef();
    this.saveWorkouts = _.debounce(this.saveWorkouts, 200);
    this.uploadedAllComment = true;
  }

  handleContentChange = event => {
    event.preventDefault();
  };

  getDefaultWorkout() {
    let workingWorkout = _.cloneDeep(this.props.workingWorkout);

    const today = moment();

    if (!workingWorkout.start_date) {
      workingWorkout.start_time = today.format(TIME_FORMAT);
    } else {
      workingWorkout.start_time = moment(workingWorkout.start_date).format(TIME_FORMAT);
    }

    if (!workingWorkout.end_date) {
      workingWorkout.end_time = today.add(1, 'hour').format(TIME_FORMAT);
    } else {
      workingWorkout.end_time = moment(workingWorkout.end_date).format(TIME_FORMAT);
    }

    return workingWorkout || new Workout();
  }

  getOriginWorkout() {
    let originWorkout = _.cloneDeep(this.props.originWorkout);
    const today = moment();

    if (!originWorkout.start_date) {
      originWorkout.start_time = today.format(TIME_FORMAT);
    } else {
      originWorkout.start_time = moment(originWorkout.start_date).format(TIME_FORMAT);
    }

    if (!originWorkout.end_date) {
      originWorkout.end_time = today.add(1, 'hour').format(TIME_FORMAT);
    } else {
      originWorkout.end_time = moment(originWorkout.end_date).format(TIME_FORMAT);
    }

    return originWorkout || new Workout();
  }

  onCloseCurrentPopup() {
    this.props.dispatch(toggleModal(false));

    const { pathname } = window.location;

    const { selectedClient } = this.props;

    if (selectedClient) {
      this.props.dispatch(push(`/home/client/${selectedClient}/calendar`));
    } else {
      this.props.dispatch(push('/home/client'));
    }
  }

  onDenyClosingPopup() {
    this.props.dispatch(toggleModal(true));
  }

  handleCloseAction() {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy) return null;
    let origin = classToPlain(this.state.originWorkout);
    let current = classToPlain(this.state.workout);

    if (this.noteRef && this.noteRef.current) {
      current.completed_note = this.noteRef.current.value || this.noteRef.current.ref.value;
    }

    let changes = diff(origin, current);

    const renderContent = () => {
      return (
        <S.CancelContentPopup>
          <p className="text">Any data logged and comments will be discarded.</p>
          <p className="text">Would you like to continue?</p>
        </S.CancelContentPopup>
      );
    };

    if (changes) {
      let state = this.state;
      let workout = state.workout;
      workout.completed_note = current.completed_note;
      this.setState(state, () => {
        this.props.dispatch(
          toggleConfirmModal(
            true,
            <S.DiscardChangeModal
              hasCloseIcon
              headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
              newStyle
              title="Cancel log workout"
              content={renderContent()}
              onConfirm={() => this.onCloseCurrentPopup()}
              onDeny={() => this.onDenyClosingPopup()}
            />,
          ),
        );
      });
    } else {
      this.onCloseCurrentPopup();
    }
  }

  onStartDateChange = startDate => {
    const { endDate, workout } = this.state;
    const fullStartDate = moment(`${startDate.format(DATE_FORMAT)} ${workout.start_time}`, FULL_DATE_FORMAT);
    const fullEndDate = moment(`${endDate.format(DATE_FORMAT)} ${workout.end_time}`, FULL_DATE_FORMAT);

    if (fullStartDate.isAfter(fullEndDate)) {
      this.setState({
        startDate,
        endDate: moment(fullStartDate).add(1, 'hour'),
        workout: {
          ...workout,
          end_time: moment(fullStartDate).add(1, 'hour').format(TIME_FORMAT),
        },
      });
    } else {
      this.setState({ startDate });
    }
  };

  onEndDateChange = endDate => {
    const { startDate, workout } = this.state;
    const fullStartDate = moment(`${startDate.format(DATE_FORMAT)} ${workout.start_time}`, FULL_DATE_FORMAT);
    const fullEndDate = moment(`${endDate.format(DATE_FORMAT)} ${workout.end_time}`, FULL_DATE_FORMAT);

    if (fullStartDate.isAfter(fullEndDate)) {
      this.setState({
        startDate: moment(fullEndDate).subtract(1, 'hour'),
        endDate,
        workout: {
          ...workout,
          start_time: moment(fullEndDate).subtract(1, 'hour').format(TIME_FORMAT),
        },
      });
    } else {
      this.setState({ endDate });
    }
  };

  onStartTimeChange = startTime => {
    const { workout, startDate, endDate } = this.state;

    const fullStartDate = moment(`${startDate.format(DATE_FORMAT)} ${startTime}`, FULL_DATE_FORMAT);
    const fullEndDate = moment(`${endDate.format(DATE_FORMAT)} ${workout.end_time}`, FULL_DATE_FORMAT);

    if (fullStartDate.isAfter(fullEndDate)) {
      this.setState({
        endDate: moment(fullStartDate).add(1, 'hour'),
        workout: {
          ...workout,
          start_time: startTime,
          end_time: moment(fullStartDate).add(1, 'hour').format(TIME_FORMAT),
        },
      });
    } else {
      this.setState({ workout: { ...workout, start_time: startTime } });
    }
  };

  onEndTimeChange = endTime => {
    const { workout, startDate, endDate } = this.state;

    const fullStartDate = moment(`${startDate.format(DATE_FORMAT)} ${workout.start_time}`, FULL_DATE_FORMAT);
    const fullEndDate = moment(`${endDate.format(DATE_FORMAT)} ${endTime}`, FULL_DATE_FORMAT);

    if (fullStartDate.isAfter(fullEndDate)) {
      this.setState({
        startDate: moment(fullEndDate).subtract(1, 'hour'),
        workout: {
          ...workout,
          end_time: endTime,
          start_time: moment(fullEndDate).subtract(1, 'hour').format(TIME_FORMAT),
        },
      });
    } else {
      this.setState({ workout: { ...workout, end_time: endTime } });
    }
  };

  toggleShowing = () => this.setState({ showing: !this.state.showing });

  renderDateTimePicker = () => {
    const { workout, startDate, endDate } = this.state;
    const { tracking } = this.props;
    const workoutDescription = _.get(workout, 'description', '');

    return (
      <S.DateTimePickerContainer
        className="dateTimePicker__container"
        style={{
          marginBottom: tracking && !workoutDescription ? '-4px' : null,
        }}
        tracking={tracking}
      >
        <S.DateTimeGroup>
          <S.FormGroup className="datePicker">
            <label>Start Date</label>
            <Datetime
              value={startDate}
              renderInput={props => <DateInput {...props} text={startDate.format(DATE_FORMAT)} />}
              timeFormat={false}
              onChange={this.onStartDateChange}
              closeOnSelect={true}
              isValidDate={currentDate => currentDate.isSameOrBefore(moment(), 'day')}
              className="new-ui"
            />
          </S.FormGroup>
          <S.FormGroup className="timePicker">
            <label>Start Time</label>
            <TimeField
              value={workout.start_time}
              onChange={this.onStartTimeChange}
              colon=":"
              style={{ width: '100%' }}
            />
          </S.FormGroup>
        </S.DateTimeGroup>
        <S.Arrow />
        <S.DateTimeGroup>
          <S.FormGroup className="datePicker">
            <label>End Date</label>
            <Datetime
              value={endDate}
              renderInput={props => <DateInput {...props} text={endDate.format(DATE_FORMAT)} />}
              timeFormat={false}
              onChange={this.onEndDateChange}
              closeOnSelect={true}
              isValidDate={currentDate => currentDate.isSameOrBefore(moment(), 'day')}
              className="new-ui"
            />
          </S.FormGroup>
          <S.FormGroup className="timePicker">
            <label>End Time</label>
            <TimeField value={workout.end_time} onChange={this.onEndTimeChange} colon=":" style={{ width: '100%' }} />
          </S.FormGroup>
        </S.DateTimeGroup>
      </S.DateTimePickerContainer>
    );
  };

  renderWorkoutDescription = description => {
    if (!description) return null;

    return (
      <S.WorkoutDescription>
        <h5>description</h5>
        <p contentEditable={true} onKeyDown={this.handleContentChange} spellCheck={false}>
          {description}
        </p>
      </S.WorkoutDescription>
    );
  };

  render() {
    const { showing, workout } = this.state;
    const { tracking } = this.props;
    const classes = `tracking-workout ${tracking ? 'tracking-workout-popup-container' : 'workout-popup-container'}`;
    return (
      <Modal
        closeOnDimmerClick={!tracking}
        size={'tiny'}
        open={this.props.isModalOpen}
        className={classes}
        style={{ margin: '4rem auto!important' }}
        onClose={() => {
          this.handleCloseAction();
        }}
      >
        {tracking ? (
          <S.WorkoutContainer>
            <S.WorkoutContent>
              {this.renderHeader()}
              <Modal.Content
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  paddingTop: 19,
                  height: showing && 'calc(100% - 99px)',
                  background: showing && '#fff',
                }}
              >
                {this.renderDateTimePicker()}
                {this.renderWorkoutDescription(_.get(workout, 'description', ''))}
                {this.renderNote()}
                {this.renderSections()}
              </Modal.Content>
              <Modal.Actions className="tracking-actions">{this.renderActionButtons()}</Modal.Actions>
            </S.WorkoutContent>
            <S.CommentContainer>
              <S.CommentToggle showing={showing} onClick={this.toggleShowing} />
              {showing ? (
                <S.CommentsWrapper>
                  <Comments assignmentId={workout._id} />
                </S.CommentsWrapper>
              ) : null}
            </S.CommentContainer>
          </S.WorkoutContainer>
        ) : (
          <>
            {this.renderHeader()}
            <Modal.Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 19 }}>
              {this.renderDateTimePicker()}
              {this.renderNoteButton()}
              {this.renderNote()}
              {this.renderSections()}
            </Modal.Content>
            <Modal.Actions className="tracking-actions">{this.renderActionButtons()}</Modal.Actions>
          </>
        )}
      </Modal>
    );
  }

  renderHeader() {
    return (
      <Modal.Header className="workout-header tracking-workout-header">
        <div className="workout-header-inner ">
          <Button
            onClick={() => {
              this.handleCloseAction();
            }}
            className="close-button"
          >
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="history-workout-title">
            <div className="history-workout-title-text">{this.state.workout.title || ''}</div>
          </div>
        </div>
      </Modal.Header>
    );
  }

  renderSections = () => {
    const { workout, isErrorTrainingSet, isErrorSets, isMarkAllSection } = this.state;
    const { sections } = workout;
    if (!sections) {
      return null;
    }
    let sectionViews = [];

    _.forEach(sections, (item, index) => {
      let totalPreviousHiddenSection = null;

      if (item.type === 'hidden') {
        if (index === 0) {
          totalPreviousHiddenSection = 0;
        } else {
          let previousSections = sections.slice(0, index);
          totalPreviousHiddenSection = _.filter(previousSections, s => s.type === 'hidden').length;
        }
      }

      sectionViews.push(
        <SectionDetail
          key={item._id}
          sectionIndex={index}
          section={item}
          totalPreviousHiddenSection={totalPreviousHiddenSection}
          totalSection={sections.length}
          tracking={this.props.tracking}
          onChangeExerciseData={this.onChangeExerciseData}
          onUpdateSection={this.handleUpdateSection}
          onUpdateRoundOnSection={this.handleUpdateRoundOnSection}
          onUpdateOriginWorkout={this.handleUpdateOriginWorkout}
          isErrorTrainingSet={isErrorTrainingSet}
          isErrorSets={isErrorSets}
          isMarkAllSection={isMarkAllSection}
          onSetIsMarkAllSection={this.handleSetIsMarkAllSection}
          onSetValidationTrainingSet={this.handleSetValidationTrainingSet}
        />,
      );
    });

    return sectionViews;
  };

  onChangeExerciseData = data => {
    this.setState(({ workout }) => {
      const { sectionIndex, setIndex, exIndex, exerciseSet } = data;
      const newWorkout = _.cloneDeep(workout);
      const section = _.cloneDeep(newWorkout.sections[sectionIndex] || {});

      if (section) {
        const set = _.cloneDeep(section.exercises[setIndex] || {});

        if (set) {
          // const ex = _.cloneDeep(set.supersets[exIndex] || {});
          // set.supersets[exIndex] = _.merge(ex, exerciseSet);
          set.supersets[exIndex] = exerciseSet;
          section.exercises[setIndex] = set;
        }

        newWorkout.sections[sectionIndex] = section;
      }
      return { workout: newWorkout };
    });
  };

  renderActionButtons() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {this.renderCheckAllButton()}
        <S.ButtonArea>
          <S.GeneralButton onClick={() => this.handleCloseAction()}>Cancel</S.GeneralButton>

          <S.GeneralButton disabled={this.state.isTracking} onClick={this.saveWorkouts} purple>
            Save
          </S.GeneralButton>
        </S.ButtonArea>
      </div>
    );
  }

  // TODO: Ben
  // checkInvalidSet = (fields, trainingSet) => {
  //   const checkFields = fields.filter(
  //     field =>
  //       ![
  //         'is_completed',
  //         'id',
  //         '_id',
  //         'rest',
  //         'duration',
  //         'set_type',
  //         'weight',
  //         'targetTrainingSet',
  //         'each_side_mode',
  //       ].includes(field),
  //   );

  //   const isValid = checkFields.map(field => {
  //     const value = _.get(trainingSet, `[${field}].value`);
  //     if (['rir', 'hr'].includes(field) && value === 0) {
  //       return true;
  //     }
  //     return !!value;
  //   });

  //   return isValid.length ? isValid.some(item => !item) : [false];
  // };

  //  canMarkAllWorkout = () => {
  //   const { workout } = this.state;
  //   const sections = _.get(workout, 'sections', []);
  //   const isValidWorkout = sections.map(section => {
  //     return section.exercises.map(set => {
  //       return (set.supersets || []).map(ex => {
  //         return (ex.training_sets || []).map(exSet => this.checkInvalidSet(Object.keys(exSet), exSet));
  //       });
  //     });
  //   });
  //   return _.flattenDeep(isValidWorkout).some(item => item);
  // };

  renderCheckAllButton() {
    return (
      <S.MarkAll onClick={() => this.checkAllSet()}>
        <MarkAllIcon />
        <S.MarkAllLabel>Mark All</S.MarkAllLabel>
      </S.MarkAll>
    );
  }

  checkAllSet() {
    let { workout } = this.state;

    if (workout) {
      let sections = _.cloneDeep(workout.sections);

      _.forEach(sections, section => {
        if (section.format === 'freestyle') {
          section.section_completed = true;
        }
        if (section.exercises) {
          _.forEach(section.exercises, set => {
            if (set && set.supersets) {
              _.forEach(set.supersets, ex => {
                _.forEach(ex.training_sets, exSet => {
                  exSet.is_completed = true;
                  // Set default value fields
                  autoFillInput(exSet, AUTO_FILL_FIELDS);
                });
              });
            }
          });
        }
      });

      workout.sections = sections;
      this.checkValidation(); // Show error when click Mark All
      this.setState({ workout, isMarkAllSection: true });
    }
  }

  handleSetIsMarkAllSection = value => {
    this.setState({ isMarkAllSection: value });
  };

  handleDeleteNote() {
    let workout = this.state.workout;
    workout.completed_note = '';
    this.setState(prevState => ({
      workout,
      isAddingNote: false,
      shouldFocusNote: false,
    }));
  }

  handleAddNote() {
    this.setState(prevState => ({
      isAddingNote: true,
      shouldFocusNote: true,
    }));
  }

  renderNoteButton() {
    if (!this.state.workout.completed_note && !this.state.isAddingNote) {
      return (
        <div className="note-button add-note-button workout-note-buttons" onClick={() => this.handleAddNote()}>
          <Image src={`${CDN_URL}/images/add_note.svg`} />
          <div>Add Workout Note</div>
        </div>
      );
    }

    return (
      <div className="note-button delete-note-button" onClick={() => this.handleDeleteNote()}>
        <Image src={`${CDN_URL}/images/delete_note.svg`} />
        <div>Delete Workout Note</div>
      </div>
    );
  }

  renderNote() {
    if (!this.state.workout.completed_note && !this.state.isAddingNote) {
      return null;
    }

    if (this.state.shouldFocusNote) {
      this.setState({ shouldFocusNote: false });
    }

    return (
      <div className="note-input-container workout-note">
        <TextArea
          className="note-input"
          defaultValue={this.state.workout.completed_note}
          placeholder={'Add note'}
          ref={this.noteRef}
        />
      </div>
    );
  }

  checkValidation = () => {
    const { workout } = this.state;
    const { fields, tracking } = this.props;
    const fieldCodes = _.map(fields, item => item.unique_code);
    const { sections } = workout;
    let isValueEmpty = false,
      hasCompletedSet = false;
    const oneRepMaxField = fields.find(item => item.unique_code == 'orm');
    let isValidationTrainingSet = true;

    if (sections && sections.length) {
      outer_loop: for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
        const exercises = _.get(sections[sectionIndex], 'exercises', []);
        if (sections[sectionIndex].section_completed) {
          hasCompletedSet = true;
          this.handleSetValidationTrainingSet(false);
        }

        for (let setIndex = 0; setIndex < exercises.length; setIndex++) {
          const supersets = _.get(exercises[setIndex], 'supersets', []);

          for (let exIndex = 0; exIndex < supersets.length; exIndex++) {
            const exercise = supersets[exIndex];
            const training_sets = _.get(exercise, 'training_sets', []);
            const exerciseFields = _.get(exercise, 'exercise_instance.fields', []);

            for (let i = 0; i < training_sets.length; i++) {
              let valueError = false;

              if (training_sets[i].is_completed) {
                hasCompletedSet = true;
                if (sections[sectionIndex].format === SECTION_FORMAT_KEY.TIMED) {
                  let isValid = this.state.isErrorSets;

                  isValidationTrainingSet = checkValidationTrainingSet(sections[sectionIndex]);

                  if (!isValidationTrainingSet) {
                    isValid.push(sections[sectionIndex]._id);
                  } else {
                    isValid = _.filter(isValid, item => item !== sections[sectionIndex]._id);
                  }

                  this.setState({ isErrorSets: [...new Set(isValid)] });
                }

                this.handleSetValidationTrainingSet(false);

                _.forEach(training_sets[i], (data, key) => {
                  if (fieldCodes.includes(key)) {
                    const fieldObj = _.find(fields, item => item.unique_code === key);

                    if (
                      exerciseFields.includes(fieldObj._id) &&
                      fieldObj.data_type !== 'time' &&
                      !data.value &&
                      isNaN(parseInt(data.value))
                    ) {
                      valueError = true;
                      return false;
                    }

                    if (
                      fieldObj.unique_code === 'reps' &&
                      (!data.value || !/^\d+$/.test(data.value) || data.value == 0)
                    ) {
                      valueError = true;
                      return false;
                    }

                    if (
                      fieldObj.unique_code === 'hr' &&
                      (!data.value || !/^\d+$/.test(data.value)) &&
                      data.value !== 0
                    ) {
                      valueError = true;
                      return false;
                    }

                    if (
                      fieldObj.unique_code === 'rir' &&
                      (!data.value || !/^\d+$/.test(data.value)) &&
                      data.value !== 0
                    ) {
                      valueError = true;
                      return false;
                    }
                  }
                });
              } else {
                // When not have completed
                // let isValid = this.state.isErrorSets;
                // isValidationTrainingSet = checkValidationTrainingSet(sections[sectionIndex]);
                // isValid = _.filter(isValid, item => item !== sections[sectionIndex]._id);
                // this.setState({ isErrorSets: [...new Set(isValid)] });
              }

              if (valueError) {
                isValueEmpty = true;
                break outer_loop;
              }
            }
          }
        }
      }
    }

    if (!hasCompletedSet) {
      toast.error(`Please check off at least 1 set to save the workout`);
    }

    if (isValueEmpty) {
      const errorMsg = tracking
        ? 'Please fill out all required fields before checking the sets off.'
        : 'Workout could not be saved. Please check missing fields before saving.';
      toast.error(errorMsg);
    }

    if (hasCompletedSet && !isValueEmpty) {
      if (!isValidationTrainingSet || this.state.isErrorSets.length > 0) {
        this.handleSetValidationTrainingSet(true);
        toast.error(`Please input the training time before saving the workout.`);
        return;
      }
    }

    return hasCompletedSet && !isValueEmpty;
  };

  handleSetValidationTrainingSet = value => this.setState({ isErrorTrainingSet: value });

  handleUpdateSection = (newSection, sectionIndex) => {
    let newSectionsArr = _.cloneDeep(this.state.workout.sections);
    newSectionsArr[sectionIndex] = newSection;
    this.setState({ workout: { ...this.state.workout, sections: newSectionsArr } });
  };

  handleUpdateRoundOnSection = (newSection, sectionIndex) => {
    let newSectionsArr = this.state.workout.sections;
    newSectionsArr[sectionIndex] = newSection;
    this.setState({ workout: { ...this.state.workout, sections: newSectionsArr } });
  };

  handleUpdateOriginWorkout = (newSection, sectionIndex) => {
    let newSectionsArr = _.cloneDeep(this.state.workout.sections);
    newSectionsArr[sectionIndex] = newSection;
    this.setState({ originWorkout: { ...this.state.originWorkout, sections: newSectionsArr } });
  };

  saveWorkouts = () => {
    const { day, selectedClientData } = this.props;
    let workout = this.state.workout;
    const isValid = this.checkValidation();

    if (!isValid) {
      return false;
    }

    if (this.noteRef && this.noteRef.current) {
      workout.completed_note = this.noteRef.current.value || this.noteRef.current.ref.value;
    }

    this.doTracking(workout, selectedClientData);
  };

  counterRoundCompleted = section => {
    let counter = 0;
    let checkedRound = [];
    if ([SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED].includes(section.format)) {
      const exercises = _.get(section, 'exercises', []);
      exercises.forEach(ex => {
        const supersets = _.get(ex, 'supersets', []);
        supersets.forEach(sup => {
          const trainingSets = _.get(sup, 'training_sets');
          trainingSets.forEach((it, index) => {
            const keys = _.filter(
              _.keys(it) || [],
              item =>
                ![
                  'is_completed',
                  'id',
                  '_id',
                  'rest',
                  'duration',
                  'set_type',
                  'targetTrainingSet',
                  'each_side_mode',
                  ...AUTO_FILL_FIELDS,
                ].includes(item),
            );
            if (!keys.length && it.is_completed && !checkedRound.includes(index)) {
              counter++;
              checkedRound.push(index);
            } else {
              keys.forEach(k => {
                const value = /^\d+$/.test(it[k].value);
                if (it.is_completed && value && !checkedRound.includes(index)) {
                  counter++;
                  checkedRound.push(index);
                }
              });
            }
          });
        });
      });
    }
    return counter;
  };

  doTracking = (workout, client) => {
    const { startDate, endDate } = this.state;
    const { mode } = this.props;

    const fullStartDateMoment = moment(`${startDate.format(DATE_FORMAT)} ${workout.start_time}`, FULL_DATE_FORMAT);
    const fullEndDateMoment = moment(`${endDate.format(DATE_FORMAT)} ${workout.end_time}`, FULL_DATE_FORMAT);

    const sections = workout.sections.map(section => {
      const copy = _.omit(section, ['user', '__v', 'createdAt', 'updatedAt']);
      let exercises = convertFromWorkoutSetsAPIExercise(section.exercises, client.preferences, true);
      copy.round_complete = this.counterRoundCompleted(section);
      return { ...copy, exercises };
    });

    const params = {
      timezone: DateTime.local().zoneName,
      assignment: workout._id,
      completed_note: workout.completed_note,
      client: client._id,
      date: workout.date,
      start_time: workout.start_time,
      end_time: workout.end_time,
      start_date: fullStartDateMoment.format(),
      end_date: fullEndDateMoment.format(),
      duration: Math.abs(fullEndDateMoment.diff(fullStartDateMoment, 'seconds')),
      sections,
    };

    this.setState({ isTracking: true });

    let action = this.props.recordAssignment;

    if (mode === LOGGING_WORKOUT_MODES.UPDATING) {
      action = this.props.updateTrackingData;
      params.assignmentId = workout._id;
      delete params.assignment;
    }

    action(params)
      .then(() => {
        const { selectedClient } = this.props;
        if (selectedClient) {
          this.props.dispatch(push(`/home/client/${selectedClient}/calendar`));
        } else {
          this.props.dispatch(push('/home/client'));
        }
      })
      .finally(() => {
        this.setState({ isTracking: false });
      });
  };
}
