import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { Helmet } from 'react-helmet';
import SearchInput from 'shared/SearchInput';
import * as Layout from 'shared/LibraryLayout';
import { DEFAULT_STATE } from 'redux/waitlist/reducers';
import { toast } from 'react-toastify';

import TableHeader from './parts/TableHeader';
import TableBody from './parts/TableBody';
import TablePagination from './parts/TablePagination';
import LoadingIndicator from 'shared/LoadingIndicator';
import * as S from '../../components/CustomTable.style';
import PackageLayout from '../PackageLayout';
import BulkActionButton, { BulkActionWrapper } from '../../components/BulkActionButton';
import DeclineRequestModal from '../../components/DeclineRequestModal';
import { DECLINE_REQUEST_TYPE } from '../../components/DeclineRequestModal/helper';
import { WAITLIST_STATUS } from './constants';
import CustomPopup from 'components/Package/components/CustomPopup';
import StatusFilter from 'components/Package/components/StatusFilter';
import ResetButton from 'components/Package/components/ResetButton';
import {
  getClientWord,
  getParamsFromUrl,
  getPronounsWord,
  getWaitlistStatus,
} from 'components/Package/components/common';
import { APPROVED_OR_REJECTED_PACKAGE_UNPUBLISH_ERROR, C_KEY_TOKEN } from 'constants/commonData';
import { getCookie, genTokenFromMarketplace } from 'utils/commonFunction';

import { ReactComponent as SuccessIcon } from 'assets/icons/popup-success-icon.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/popup-warning-icon.svg';

const View = props => {
  const {
    total,
    match,
    loading,
    queryParam,
    toggleModal,
    getWaitList,
    workingPackage,
    changeQueryParams,
    resetWaitListState,
    clientIdsSelected = [],
    approveRequestWaitList,
    selectedClientIdWaitList,
    selectedClientIdsWaitList,
    location,
    user,
    dispatch,
  } = props;
  const { q = '', status, pageSize } = queryParam;
  const isMP = get(match, 'url', '').includes('marketplace');
  const packageName = get(workingPackage, 'name', '');
  const packageId = get(workingPackage, 'id', '');
  const packageHashId = get(workingPackage, 'hash_id', '');
  const isShowPagination = total > pageSize;
  const search = get(location, 'search', '');
  const pathname = get(location, 'pathname', '');
  const params = getParamsFromUrl(search);
  const clientEmail = params.length === 2 ? params[1] : params[0];

  const [textSearch, setTextSearch] = useState('');

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (isMP && token && search.includes('redirect-platform')) {
      const replacePathname = search.includes('&') && pathname + search.replace('redirect-platform&', '');
      genTokenFromMarketplace({ token, user, pathname, search, dispatch, replacePathname });
    }
  }, [pathname, search]);

  useEffect(() => {
    if (packageId) {
      if (clientEmail) {
        setTextSearch(clientEmail);
        packageHashId && window.history.replaceState({}, '', `/home/marketplace/${packageHashId}/waitlist`);
        changeQueryParams({ ...DEFAULT_STATE.queryParam, q: clientEmail }, true, true);
      } else {
        getWaitList && getWaitList(packageId);
      }
    }

    return () => {
      resetWaitListState();
    };
  }, [packageId, clientEmail]);

  const handleDebounceSearch = useCallback(
    debounce((value = '') => {
      const search = value.toLowerCase().trim();
      if (search !== q) {
        const isLoading = true;
        const isCancelRequest = true;
        selectedClientIdWaitList([]);
        selectedClientIdsWaitList([]);
        setTimeout(
          () => changeQueryParams({ ...DEFAULT_STATE.queryParam, q: search }, isLoading, isCancelRequest),
          100,
        );
      }
    }, 300),
    [q],
  );

  const handleSearch = value => {
    setTextSearch(value);
    handleDebounceSearch(value);
  };

  const handleChangeStatusFilter = status => {
    changeQueryParams({ page: 1, status });
  };

  const handleResetFilter = () => {
    changeQueryParams(omit(DEFAULT_STATE.queryParam, 'q'));
  };

  const handleDeclineError = error => {
    const { message = '', errorCode = '' } = error || {};

    if (errorCode === APPROVED_OR_REJECTED_PACKAGE_UNPUBLISH_ERROR) {
      handleOpenWarningPackageUnpublishedPopup();
    } else {
      toast.error(message);
    }
  };

  const handleOpenRejectPopup = () => {
    toggleModal(
      true,
      <DeclineRequestModal
        type={DECLINE_REQUEST_TYPE.WAITLIST}
        onDeclineError={handleDeclineError}
        onClose={() => {
          toggleModal(false);
          handleCloseRequestPopup();
        }}
      />,
    );
  };

  const handleOpenApprovePopup = countClientSelected => {
    const clientWord = getClientWord(countClientSelected);
    toggleModal(
      true,
      <CustomPopup
        title="Offer package to client"
        icon={<SuccessIcon />}
        buttonClassName="button-approve"
        buttonText="Offer"
        onToggle={() => toggleModal(false)}
        onClose={handleCloseRequestPopup}
        onSubmit={handleApproveRequest}
        content={`Are you sure you want to offer this package to the selected ${clientWord}?`}
      />,
    );
  };

  const handleApproveRequest = toastMessage => {
    approveRequestWaitList(packageId, {
      callbackSuccess: message => {
        toggleModal(false);
        toastMessage(message);
      },
      callbackFailure: errorCode => {
        toggleModal(false);
        if (errorCode === APPROVED_OR_REJECTED_PACKAGE_UNPUBLISH_ERROR) {
          handleOpenWarningPackageUnpublishedPopup();
        } else {
          toggleModal(
            true,
            <CustomPopup
              isShowCancelButton={false}
              title="Warning"
              icon={<WarningIcon />}
              onToggle={() => toggleModal(false)}
              content={`You have reached the purchase limit for ${packageName}. If you want to continue to send the offer, please increase the purchase limit.`}
            />,
          );
        }
      },
    });
  };

  const handleOpenWarningPackageUnpublishedPopup = () => {
    toggleModal(
      true,
      <CustomPopup
        isShowCancelButton={false}
        title="Warning"
        icon={<WarningIcon />}
        onToggle={() => toggleModal(false)}
        content={`Your package is unpublished. Please publish your package to process the requests.`}
      />,
    );
  };

  const handleCloseRequestPopup = () => {
    selectedClientIdWaitList([]);
  };

  const renderLoadingTask = () => {
    return (
      <Layout.TableBody>
        <Layout.TableRow>
          <Layout.TableCell colSpan={7} className="custom-loading">
            <LoadingIndicator title="Loading..." size="medium" />
          </Layout.TableCell>
        </Layout.TableRow>
      </Layout.TableBody>
    );
  };

  const renderBulkActions = () => {
    const countClientSelected = get(clientIdsSelected, 'length', 0);
    if (!countClientSelected) return;
    return (
      <BulkActionWrapper>
        <BulkActionButton
          label="Decline"
          iconName="reject"
          onClick={handleOpenRejectPopup}
          count={countClientSelected}
        />
        <BulkActionButton
          label="Offer"
          iconName="allow"
          onClick={() => handleOpenApprovePopup(countClientSelected)}
          count={countClientSelected}
        />
      </BulkActionWrapper>
    );
  };

  const statusOptions = map(Object.values(WAITLIST_STATUS), item => {
    const { statusLabel } = getWaitlistStatus(item);
    return {
      name: statusLabel,
      value: item,
    };
  });

  if (!isMP) return null;
  return (
    <S.Container>
      <PackageLayout isMP={isMP} rightContent={renderBulkActions()}>
        <S.LayoutWrapper>
          <Helmet>
            <title>{`Waitlist - Everfit`}</title>
          </Helmet>
          <S.TableSearchWrapper>
            <div className="action-filter-wrapper">
              <StatusFilter
                type="waitlist"
                options={statusOptions}
                value={status}
                onChange={handleChangeStatusFilter}
              />
              <ResetButton isShow={!isEmpty(status)} onReset={handleResetFilter} />
            </div>

            <SearchInput
              value={textSearch}
              placeholder="Search"
              onAddOnIconClick={() => handleSearch('')}
              onChange={(evt, data) => handleSearch(data.value)}
            />
          </S.TableSearchWrapper>
          <S.TableWrapper isShowPagination={isShowPagination}>
            <Layout.Table>
              <TableHeader />
              {loading ? (
                renderLoadingTask()
              ) : (
                <TableBody onOpenRejectPopup={handleOpenRejectPopup} onOpenApprovePopup={handleOpenApprovePopup} />
              )}
            </Layout.Table>
          </S.TableWrapper>
          <TablePagination />
        </S.LayoutWrapper>
      </PackageLayout>
    </S.Container>
  );
};

export default React.memo(View);
