import styled from 'styled-components';

export const FooterBodyMetric = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
