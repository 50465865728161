import styled, { css } from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { CDN_URL } from 'constants/commonData';

export const FallbackWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #e1e5ec;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 35px;
  overflow: auto;

  .fallback__image {
    img {
      width: 1017.36px;
    }

    &--program {
      img {
        width: 629px;
      }
    }

    &--studio {
      img {
        width: 1214;
        max-width: 100%;
      }
    }
  }

  .fallback__content {
    margin-bottom: 50px;
    margin-top: 20px;

    &--program {
      margin-bottom: 30px;
    }

    .fallback__contentTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 10px;
      /* identical to box height, or 30px */

      text-align: center;

      color: #000000;

      &--program {
        margin-bottom: 5px;
      }
    }

    .fallback__contentDescription {
      width: 458px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      /* or 21px */

      text-align: center;

      color: #000000;

      opacity: 0.6;
      &--program {
        font-size: 16px;
        opacity: unset;
        width: 473px;
      }
    }
  }
`;

export const AutoflowFallbackWrapper = styled(FallbackWrapper)`
  overflow: hidden;
  padding-top: 0px;
`;

export const OnboardingFlowFallbackWrapper = styled(FallbackWrapper)`
  overflow: hidden;
  padding-top: 0px;
`;

export const FoodJournalsFallBackWrapper = styled(FallbackWrapper)`
  border-top: none;
  flex-direction: row;
  justify-content: center;

  .fallback__image {
    margin-left: 50px;
    img {
      max-height: 700px;
      height: calc(100vh - 150px);
      width: auto;
    }
  }

  .fallback__content {
    width: 388px;

    .fallback__contentTitle {
      text-align: left;
      margin-bottom: 5px;
    }
    .fallback__contentDescription {
      width: 100%;
      text-align: left;
      margin-bottom: 30px;
    }
  }
`;

export const SegmentFallbackWrapper = styled(FallbackWrapper)`
  position: absolute;
  border-top: none;
  z-index: 2;
  .segmentFallBack__transparent {
    width: 100%;
    height: 200px;
  }

  .segmentFallBack__gradient {
    height: 126px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 82.04%);
  }

  .segmentFallBack__white {
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 396px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;

    .fallback__contentTitle {
      margin-bottom: 5px;
    }

    .fallback__contentDescription {
      font-size: 16px;
      color: #000000;
      margin-bottom: 30px;
      opacity: 1;
    }
  }
`;

export const TeammatesFallBackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-top: 1px solid #e1e5ec;

  ${FoodJournalsFallBackWrapper} {
    padding-top: 120px;
    max-width: 1380px;
  }

  .fallback__image {
    margin-left: 50px;
    width: calc(100% - 600px);

    img {
      width: 100%;
      height: auto;
    }
  }

  .fallback__content {
    width: 388px;

    .fallback__contentTitle {
      text-align: left;
      margin-bottom: 5px;
    }
    .fallback__contentDescription {
      width: 100%;
      text-align: left;
      margin-bottom: 30px;
    }
  }

  .teammateFallBack__transferClient {
    background: #c4c4c410;
    border-radius: 5px;
    padding: 10px 15px;

    margin-bottom: 100px;

    display: flex;
    justify-content: center;

    &--text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      width: 291px;
      color: #000000;

      opacity: 0.4;
      margin-right: 80px;
    }

    &--button {
      border: 1px solid #5158cf;
      color: #5158cf;
      font-weight: normal;

      &:disabled {
        background-color: #fff;
      }

      &:hover {
        background-color: #5158cf;
        color: #fff;
      }

      .loading {
        padding-right: 20px;
        &:after {
          content: '.';
          animation: dots 1s steps(5, end) infinite;
        }

        @keyframes dots {
          0%,
          20% {
            color: rgba(0, 0, 0, 0);
            text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
          }
          40% {
            color: #5158cf;
            text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
          }
          60% {
            text-shadow: 0.25em 0 0 #5158cf, 0.5em 0 0 rgba(0, 0, 0, 0);
          }
          80%,
          100% {
            text-shadow: 0.25em 0 0 #5158cf, 0.5em 0 0 #5158cf;
          }
        }
      }
    }
  }
`;

export const BrandingFallBackWrapper = styled.div`
  background-image: linear-gradient(#fdc830, #f37335);
  border-radius: 8px;
  padding: 2px;
  margin-top: 50px;
  margin-top: 50px;

  .brandingFallBack__wrapper {
    position: relative;
    border-radius: 6px;
    padding: 43px 15px 30px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: #ffffff;
  }

  .brandingFallBack__topIcon {
    position: absolute;
    border-radius: 50%;
    border: 2px solid #fec931;
    width: 40px;
    height: 40px;
    top: 0;
    transform: translateY(-50%);
    background-color: #fff;
    background-image: url(${CDN_URL}/images/upgrade_path_branding_icon.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  .brandingFallBack__content {
    width: 388px;
    text-align: center;

    .brandingFallBack__contentTitle {
      text-align: left;
      margin-bottom: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      /* identical to box height, or 30px */

      text-align: center;

      color: #202353;
    }
    .brandingFallBack__contentDescription {
      width: 100%;
      text-align: left;
      margin-bottom: 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */

      text-align: center;

      color: #202353;
    }
  }
`;

export const Item = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ItemPackage = styled(Item)`
  flex-direction: column;
`;

export const ItemImage = styled.img`
  width: 100%;
`;

export const ItemContent = styled.div`
  padding-left: 30px;
`;

export const ItemTitle = styled.div`
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 12px;
`;

export const ItemText = styled.div`
  max-width: 385px;
  font-size: 1.15rem;
  line-height: 150%;
  margin-bottom: 40px;
`;

export const ItemLink = styled.a`
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ItemButton = styled.button`
  display: block;
  padding: 11px 10px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 100%;
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ItemImageArea = styled.div`
  display: inherit;
  width: 33.3vw;
`;

export const ItemImageVerticalArea = styled.div`
  display: inherit;
  width: 24.25vw;
`;

export const Menu = styled.div`
  display: flex;
`;

export const MenuItem = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-right: 48px;
  margin-bottom: 42px;
  cursor: pointer;

  &.last-child {
    margin-right: 0;
  }
`;

export const MenuItemActive = styled(MenuItem)`
  padding-bottom: 8px;
  color: #5158cf;
  font-weight: 700;
  border-bottom: 2px solid #5158cf;
`;

export const Section = styled.div`
  padding: 60px 39px;
  font-size: 14px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
`;

export const Footers = styled.div`
  ${ItemButton} {
    margin: auto;
    margin-top: 30px;
    min-width: 200px;
  }
`;

export const Title = styled.div`
  color: #000000;
  font-size: 26px;
  line-height: 39px;
  font-weight: 700;
  text-align: center;
  margin-top: 2px;
`;

export const TitlePackage = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-size: 1.15rem;
  line-height: 150%;
  text-align: center;
`;

export const DescriptionPackage = styled.div`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  max-width: 458px;
  margin: auto;
`;

export const ItemSubText = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
  opacity: 0.4;
`;

export const SliderArea = styled.div`
  display: block;

  .slick-dots {
    bottom: 18px;

    .slick-active button {
      background: #5c5bbd;
      border: none;
    }

    li {
      width: initial;
      height: initial;
      margin: 0 4px;

      button {
        width: 10px;
        height: 10px;
        padding: 0;
        border: 1px solid #e8ebed;
        border-radius: 50%;

        &:before {
          content: none;
        }
      }
    }

    &:before {
      content: 'Create package';
      position: absolute;
      bottom: 40px;
      left: 45.5%;
      font-weight: 600;
      font-size: 15px;
      line-height: 120%;
    }
  }

  .slick-slider {
    width: 77vw;
    height: 65vh;
    margin: auto;
  }
`;

export const Body = styled.div`
  display: block;
`;

export const Container = styled.div`
  width: 100%;
`;

export const NewCountryWrapper = styled.div`
  border-top: none;
  flex-direction: row;
  justify-content: center;
`;

export const PackageFallBackGroup = styled.a`
  color: #5158cf;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  padding-left: 3px;
  cursor: pointer;
  &:hover {
    color: #5158cf;
  }
`;

export const RequestButton = styled.button`
  width: 178px;
  height: 34px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  background: linear-gradient(92.61deg, #8b54ff 5.98%, #5058ea 110.75%), #f6f6f6;
  border-radius: 4px;
  margin-left: 122px;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(92.61deg, #b18cff 5.98%, #5058ea 110.75%);
  }

  ${props =>
    props.isDisabled &&
    css`
      background: linear-gradient(92.61deg, #e6e6e6 5.98%, #a7a7a7 110.75%), #f6f6f6;
      color: #6c6c6c;

      &:hover {
        background: linear-gradient(92.61deg, #e6e6e6 5.98%, #a7a7a7 110.75%), #f6f6f6;
        cursor: not-allowed;
      }
    `}

  ${props =>
    props.isHidden &&
    css`
      visibility: hidden;
    `}
`;

export const SectionAutoflow = styled.div`
  padding: 0;
  font-size: 14px;
`;

export const SectionOnboardingFlow = styled.div`
  padding: 0;
  font-size: 14px;
`;

export const Dots = styled.div`
  width: 12px;
  height: 12px;
  padding: 0;
  border: 1px solid #e8ebed;
  border-radius: 50%;

  &:before {
    content: none;
  }
`;

export const Slider = styled.div`
  display: block;

  .slick-dots {
    .slick-active {
      ${Dots} {
        background: #5c5bbd;
        border: none;
      }
    }

    li {
      width: initial;
      height: initial;
      margin: 0px 0px 0px 12px;
    }
  }

  .slick-list {
    ${'' /* top: 72px; */}
    z-index: 1;
    overflow: visible;
  }
  .slick-slider {
    height: calc(100vh - 140px);
  }
`;

export const OnboardingFlowUpgradeContainer = styled.div`
  display: block;

  .slick-dots {
    .slick-active {
      ${Dots} {
        background: #5c5bbd;
        border: none;
      }
    }

    li {
      width: initial;
      height: initial;
      margin: 0px 0px 0px 12px;
    }
  }

  .slick-list {
    z-index: 1;
    overflow: visible;
  }
  .slick-slider {
    height: calc(100vh - 140px);
  }
`;

export const UpgradePathBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &.first {
    margin: 45px 44px 33px 0px;
    img {
      display: block;
      max-width: 1071px;
      object-fit: contain;
    }
  }

  &.second {
    margin: 66px 40px 1px 0px;
    img {
      display: block;
      max-width: 724px;
      object-fit: contain;
    }
  }

  img {
    @media only screen and (max-height: 830px) {
      width: ${props => (props.imageOrder === 'first' ? '90%' : '80%')};
    }

    @media only screen and (max-height: 730px) {
      width: ${props => (props.imageOrder === 'first' ? '80%' : '70%')};
    }

    @media only screen and (max-height: 700px) {
      width: ${props => (props.imageOrder === 'first' ? '75%' : '65%')};
    }

    @media only screen and (max-height: 688px) {
      width: ${props => (props.imageOrder === 'first' ? '70%' : '60%')};
    }

    @media only screen and (max-height: 650px) {
      width: ${props => (props.imageOrder === 'first' ? '65%' : '55%')};
    }
  }
`;

export const OnboardingFlowUpgradePathBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &.onboarding-flow-bg {
    margin: ${props => (props.isHasAlertHeader ? '15px 0 25px 0' : '35px 0 45px 0')};
    img {
      display: block;
      max-width: 1380px;
      object-fit: contain;
      width: 100%;

      @media only screen and (max-height: 830px) {
        width: 90%;
      }

      @media only screen and (max-height: 730px) {
        width: 80%;
      }

      @media only screen and (max-height: 700px) {
        width: 75%;
      }

      @media only screen and (max-height: 688px) {
        width: 70%;
      }

      @media only screen and (max-height: 650px) {
        width: 65%;
      }

      @media only screen and (max-height: 600px) {
        width: 60%;
      }
    }
  }
`;

export const UpgradePathAutoflowButton = styled(UpgradePathButton)`
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 100%;
  display: flex;
  border: none;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }

  width: 200px;
  padding: ${props => (!props.isOnboardingFlow ? '10px 35px' : '10px 0px')};

  span {
    display: block;
    margin: auto;
  }
`;

export const TitleAutoflow = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
`;

export const TitleOnboardingFlow = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;

  width: 369px;
`;

export const DescriptionAutoflow = styled.div`
  width: 458px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 30px;
`;

export const DescriptionOnboardingFlow = styled.div`
  width: 458px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 30px;
`;

export const LearnMore = styled.a`
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  color: #5c5bbd;
  margin-bottom: 30px;
  margin-top: -20px;
  display: flex;
  align-items: center;

  img {
    display: inline-block !important;
    margin-right: 4px;
  }

  :hover {
    color: #5c5bbd;
  }

  ${props =>
    props.isDisabled &&
    css`
      background: linear-gradient(92.61deg, #e6e6e6 5.98%, #a7a7a7 110.75%), #f6f6f6;
      color: #6c6c6c;

      &:hover {
        background: linear-gradient(92.61deg, #e6e6e6 5.98%, #a7a7a7 110.75%), #f6f6f6;
        cursor: not-allowed;
      }
    `}
`;

export const BodyAutoflow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
