import { toast } from 'react-toastify';

export const SOCKET_EVENTS = {
  TeamPlanUpdate: 'team_plan_update',
  PaymentAuthenUpdate: 'payment_authen_update',
  MyFitnessPalStartedSyncing: 'macro_mfp_start_syncing',
  MyFitnessPalSynced: 'macro_mfp_synced',
  CronometerStartedSyncing: 'cronometer_sync_macro_value_start',
  CronometerSynced: 'cronometer_sync_macro_value',
  AutoflowIntervalAssignProgramToDay: 'assign_program_to_autoflow_interval',
  CreatedRepeatTasksSuccess: 'created_repeat_task_success',
  CreatedRepeatTasksAutoflowExactDateSuccess: 'created_repeat_task_autoflow_exact_date_success',
  CreatedRepeatTasksAutoflowExactDateDone: 'created_repeat_task_autoflow_exact_date_done',
  RemovedRepeatTasksAutoflowExactDateDone: 'remove_repeat_task_autoflow_exact_date_success',
  CreatedRepeatTasksAutoflowIntervalSuccess: 'created_repeat_task_autoflow_interval_success',
  CreatedRepeatTasksAutoflowIntervalDone: 'created_repeat_task_autoflow_interval_done',
  RemovedRepeatTasksAutoflowIntervalSuccess: 'removed_repeat_task_autoflow_interval_success',
  CreatedRepeatTaskDone: 'created_repeat_task_done',
  RemovedRepeatTaskSuccess: 'removed_repeat_task_success',
  PersonalTaskNotification: 'personal_task_due_notification',
  NewWaitingActivateClient: 'new_waiting_activate_client',
  DownloadPdfCustomInvoice: 'download_pdf_custom_invoice',
  NotifyForumIndicator: 'notify_forum_indicator',
  NewNotification: 'new_notification',
  UpdateLastEditStudioChange: 'event_update_last_edit_by_studio_program',
  AssignedProgramToStudioProgram: 'assign_program_to_studio_program',
  NewWebVersionReleased: 'new-web-version-released',
  HabitUpdated: 'habit_updated',
  NewAssignment: 'new_assignment',
  AutoflowTrainingSocketWorkoutAdded: 'autoflow-exact-date-workout-added',
  AutoflowIntervalSocketWorkoutAdded: 'autoflow-interval-workout-added',
  ProgramWorkoutSocketWorkoutAdded: 'program-workout-added',
  StudioProgramSocketWorkoutAdded: 'studio-program-workout-added',
  StudioProgramSocketWorkoutDeleted: 'studio-program-workout-deleted',
  NewComment: 'new_comment',
  WorkoutSummaryInitialed: 'workout-summary-initialed',
  MealPlanIobFinished: 'meal_plan_job_finished',
  MealClientUpdated: 'meal_client_updated',
  TrainerEmailVerified: 'trainer-email-verified',
  RecipeIngredientImported: 'recipe-ingredient-imported',
};

export const TASK_REMINDER_NOTIFICATION_TOAST_OPTIONS = {
  autoClose: false,
  hideProgressBar: true,
  progress: 0.2,
  className: 'notification_due',
  bodyClassName: 'body_notification_due',
  closeButton: false,
  position: toast.POSITION.BOTTOM_RIGHT,
  draggable: false,
  closeOnClick: false,
  // and so on ...
};
