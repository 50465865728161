import React from 'react';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CDN_URL } from 'constants/commonData';

const Container = styled.div`
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  padding: 10px;
  background-color: #f3f3f3;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 170px;
  cursor: pointer;

  :hover {
    border-color: #5c5bbd;
  }

  &.invalid {
    background: #fff7f7;
    border: 1px solid #ff9791;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const DateInput = props => {
  const onClick = event => {
    if (props.disabled) return;

    props.onClick(event);
  };

  return (
    <Container
      {...props}
      onClick={onClick}
      className={classNames('custom-date-input', props.className, {
        invalid: props.isInvalidDate,
        disabled: props.disabled,
      })}
    >
      {props.customIcon ? (
        <>
          <span>{props.text}</span>
          {props.customIcon}
        </>
      ) : props.isIconRtl ? (
        <>
          <Image src={props.isIcon || `${CDN_URL}/images/new_calendar.svg`} />
          <span>{props.text}</span>
        </>
      ) : (
        <>
          <span>{props.text}</span>
          <Image src={props.isIcon || `${CDN_URL}/images/new_calendar.svg`} />
        </>
      )}
    </Container>
  );
};

DateInput.propTypes = {
  isInvalidDate: PropTypes.bool,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  isIcon: PropTypes.string,
  isIconRtl: PropTypes.bool,
};

export default DateInput;
