import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import UpcomingBill from '../UpcomingBill';
import { PanelHeader, PanelContent, PanelFooter, ActionButton, PanelItem } from '../style';
import { pluralize } from 'utils/commonFunction';
import { useStripe } from '@stripe/react-stripe-js';
import { PLANS, BILLING_CYCLE } from 'constants/commonData';
import { Button, Checkbox } from 'shared/FormControl';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import * as S from './style';

function CurrentPlan(props) {
  const {
    pricingPlan,
    upcommingInvoice,
    billingCycle,
    scheduledSubscription,
    // invoiceOverDue,
    clientSecret,
    addOnsPricing,
    zapierBasePricings,
    paymentOverdue,
    zapierOverdue,
    addOnsOverdue,
    permission,
  } = props;

  if (clientSecret) {
    try {
      useStripe()
        .confirmCardPayment(clientSecret)
        .then(result => {
          // Display error.message in your UI.
          if (result.error) {
            console.log('error');
          } else {
            console.log('success');
          }
        })
        .catch(error => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  }
  const plan = PLANS[pricingPlan.tier] || PLANS.pro;
  const { noClients, number_of_clients, permission_number_of_clients, is_enterprise } = pricingPlan;
  function filterPricingByPlan(pricings, plan) {
    return pricings
      .map((pricing, index) => ({ ...pricing, index }))
      .filter(pricing => _.get(pricing, 'packageNoVolumes', 0) === plan);
  }
  const filteredPricing = filterPricingByPlan(zapierBasePricings, _.get(scheduledSubscription, 'zapier_quantity', 0));
  const currentNoClients = number_of_clients;
  const getScheduledContent = (zapier = false) => {
    const switchDate = pricingPlan.expiredDate ? moment(pricingPlan.expiredDate).format('MMM D, YYYY') : '';
    const isZapierScheduled = _.get(scheduledSubscription, 'addOns.zapier', false);

    const zapierTier = (_.get(filteredPricing, '[0].index', 0) + 1).toLocaleString('en-US');

    if (scheduledSubscription.tier === 'starter') {
      return `You are scheduled to switch to the "Starter plan" on ${switchDate}.`;
    }

    const { billing_cycle, number_of_clients, tier } = scheduledSubscription;
    const cycleObject = BILLING_CYCLE[billing_cycle] || {};
    const useAddOns = _.get(scheduledSubscription, 'addOns.automation', false);

    let addOnsText = '';
    let nextAddOns = [];
    if (addOnsPricing) {
      nextAddOns = addOnsPricing.toJS().filter(addOn => {
        return _.get(scheduledSubscription, ['addOns', addOn.code]);
      });
      if (nextAddOns.length > 0) {
        // Filter out 'zapier' add-on
        const filteredAddOns = nextAddOns.filter(addOn => addOn.code !== 'zapier');

        if (filteredAddOns.length > 0) {
          const addOnsCount = filteredAddOns.length;
          addOnsText = filteredAddOns
            .map((o, index) => {
              if (addOnsCount === 1 || index === addOnsCount - 1) {
                return o.title;
              } else {
                return o.title + ', ';
              }
            })
            .join('');
        } else {
          addOnsText = nextAddOns[0].title; // Only one value, no need for comma
        }
      }
    }

    const newPlan = PLANS[tier] || PLANS.pro;
    const isOnlyScheduledZapier = addOnsText === 'Zapier' && currentNoClients === number_of_clients;
    const filteredAddOns = _.filter(nextAddOns, { code: 'zapier' });

    return (
      <>
        {isOnlyScheduledZapier ? (
          <span>
            You are scheduled to switch to the&nbsp;
            <span className="sBold">
              "Tier {zapierTier} - {_.get(scheduledSubscription, 'zapier_quantity', 0).toLocaleString('en-US')} tasks"
            </span>
            &nbsp;of the Zapier plugin paid <span style={{ textTransform: 'lowercase' }}>{cycleObject.name}</span> on{' '}
            {switchDate}
            .&nbsp;
          </span>
        ) : (
          <span>
            You are scheduled to switch to the&nbsp;
            <span className="sBold">
              "{newPlan.name} plan - {pluralize('Client', number_of_clients, true)}"
            </span>
            &nbsp;paid <span style={{ textTransform: 'lowercase' }}>{cycleObject.name}</span> on {switchDate}
            .&nbsp;
            {addOnsText ? (
              <span>
                You have included the <span className="sBold">{addOnsText.replace('Zapier', '')}</span>{' '}
                {pluralize('add-on', addOnsText.split(',').length)}
                {isZapierScheduled ? (
                  <>
                    , and the{' '}
                    <S.BoldText>
                      "Tier {zapierTier} - {_.get(scheduledSubscription, 'zapier_quantity', 0).toLocaleString('en-US')}{' '}
                      tasks"{' '}
                    </S.BoldText>
                    of the Zapier plugin.
                  </>
                ) : (
                  '.'
                )}
              </span>
            ) : (
              'No add-ons have been included.'
            )}
          </span>
        )}
      </>
    );
  };

  const onOpenPaymentForm = () => {
    props.togglePaymentInformation(true);
  };

  const onViewBill = () => {
    props.toggleModal(
      true,
      <UpcomingBill
        onClose={() => props.toggleModal(false)}
        data={upcommingInvoice}
        plan={plan}
        billingCycle={billingCycle}
        useAddOns={!!_.get(pricingPlan, 'addOns.automation')}
        pricingPlan={pricingPlan}
        scheduledSubscription={scheduledSubscription}
        permission={permission}
      />,
    );
  };

  const nextChargeTime = _.get(upcommingInvoice, 'next_payment_attempt', '');
  const isInvoiceOverdue = paymentOverdue || zapierOverdue || addOnsOverdue;

  return (
    <S.CurrentPlanWrapper>
      <PanelHeader>
        <S.HeaderContainer>
          <div className="current-plan">
            Current Plan <b className="highlight">- {is_enterprise ? 'Custom' : plan.name}</b>
          </div>
          {scheduledSubscription ? (
            <>
              <Button className="button--scheduled-plan" purple data-tip data-for="current-plan-scheduled">
                {scheduledIcon}
                <span>Scheduled</span>
              </Button>
              <ReactTooltip id="current-plan-scheduled" effect="solid" place="bottom" className="app-tooltip-white">
                <S.ScheduledInformation>
                  <div className="title">Scheduled for Upcoming Bill</div>
                  <div className="description">{getScheduledContent()}</div>
                </S.ScheduledInformation>
              </ReactTooltip>
            </>
          ) : null}
        </S.HeaderContainer>
        {isInvoiceOverdue ? (
          <S.OpenPaymentFormButton data-btn-change-plan="change-plan" onClick={onOpenPaymentForm}>
            Change Plan
          </S.OpenPaymentFormButton>
        ) : (
          <UpgradePathButton data-btn-change-plan="change-plan" text="Change Plan" className="button--upgrade-path" />
        )}
      </PanelHeader>
      <PanelContent>
        <PanelItem>
          {plan.key === PLANS.starter.key ? (
            <S.Free>Free</S.Free>
          ) : (
            <>
              <S.Fee>
                <span className="fee">{(Number(upcommingInvoice.total) || 0).toUSDFormat()}</span>&nbsp;
                <span className="tax-inclusive">(Tax inclusive)</span>
              </S.Fee>
              {is_enterprise ? (
                <></>
              ) : (
                <S.NextCharge>
                  Next Charge:{' '}
                  {nextChargeTime ? moment.unix(upcommingInvoice.next_payment_attempt).format('MMM D, YYYY') : '--'}
                </S.NextCharge>
              )}
            </>
          )}
        </PanelItem>
      </PanelContent>
      <PanelFooter>
        {is_enterprise ? (
          <></>
        ) : (
          <ActionButton data-btn="view-upcoming-bill" onClick={onViewBill}>
            View Upcoming Bill{' '}
          </ActionButton>
        )}
        <S.Clients>
          Clients:{' '}
          <b>
            {noClients}/{permission_number_of_clients}
          </b>
        </S.Clients>
      </PanelFooter>
    </S.CurrentPlanWrapper>
  );
}

export default CurrentPlan;

const scheduledIcon = (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.88461 11.2309C8.56106 11.2309 10.7308 9.06118 10.7308 6.38473C10.7308 3.70827 8.56106 1.53857 5.88461 1.53857C3.20815 1.53857 1.03845 3.70827 1.03845 6.38473C1.03845 9.06118 3.20815 11.2309 5.88461 11.2309Z"
      fill="white"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.88458 3.61548V6.38471H8.65381"
      stroke="#5158CF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
