import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { isTeamAdmin, pluralize, timeSince, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

import { ReactComponent as DefaultCover } from 'assets/icons/program_workout_plan_default.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_thin.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';

import * as Layout from 'shared/LibraryLayout';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import MenuTrigger from 'shared/Icons/MenuTrigger';
import { Checkbox } from 'shared/FormControl';

import * as S from '../styles';
import { useMyContext } from '../context/ProgramLibraryContext';
import { NO_OWNER_SHARED_OPTION } from '../constants';
import ShareOwnerAvatar from './ShareOwnerAvatar';
import OwnershipIconStatus from './OwnershipIconStatus';
import ShareSettingModal from './ShareSettingModal';

const ItemRowProgram = props => {
  const { item = {} } = props;

  const {
    user,
    onItemClick,
    onEditItem,
    onDuplicateItem,
    onRemoveItem,
    onSelectedProgram,
    onSelectedItemTag,
    toggleModal,
    updateShareStatus,
    cloudfrontList,
    programsSelectedIds = [],
  } = useMyContext();

  const {
    _id,
    title = '',
    description = '',
    active_sync = false,
    total_clients = 0,
    workout_sets = [],
    last_interacted,
    author,
    share,
    tags = [],
    background_thumbnail,
  } = item;

  const [availableBackground, setAvailableBackground] = useState(!!background_thumbnail);

  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isCreatorOrOwnerOrAdmin = isCreator || isTeamAdmin(user);
  const isOwner = share !== TEAM_SHARE_NOOWNER;
  const isChecked = programsSelectedIds.includes(_id);

  const thumbnail = useMemo(() => {
    if (!availableBackground) return undefined;
    return background_thumbnail ? convertS3UrlToCloudFrontUrl(background_thumbnail, cloudfrontList, true) : undefined;
  }, [availableBackground]);

  const isValidTotalClients = useMemo(() => active_sync && typeof total_clients === 'number' && total_clients > 0, [
    active_sync,
    total_clients,
  ]);

  const onLoadBackgroundError = () => {
    setAvailableBackground(false);
  };

  const handleSaveShare = ({ ownership = null, shareStatus = TEAM_SHARE_PRIVATE } = {}) => {
    if (!_id) return;
    const isNoOwner = ownership === NO_OWNER_SHARED_OPTION.value || shareStatus === TEAM_SHARE_NOOWNER;
    const params = {
      _id,
      author: isNoOwner ? null : ownership,
      share: isNoOwner ? TEAM_SHARE_NOOWNER : shareStatus,
    };
    typeof updateShareStatus === 'function' && updateShareStatus(params);
    typeof toggleModal === 'function' && toggleModal(false);
  };

  const handleShare = () => {
    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <ShareSettingModal
          user={user}
          owner={item.author}
          shareStatus={item.share}
          closeModal={handleCloseModal}
          onSave={handleSaveShare}
        />,
      );
  };

  const handleCloseModal = () => {
    typeof toggleModal === 'function' && toggleModal(false);
  };

  const handleSelectTag = tag => event => {
    event.preventDefault();
    event.stopPropagation();
    typeof onSelectedItemTag === 'function' && onSelectedItemTag(tag);
  };

  const handleItemClick = () => {
    onItemClick(_id);
  };

  const handleEditItem = () => {
    onEditItem(item);
  };

  const handleDuplicateItem = () => {
    onDuplicateItem(item);
  };

  const handleRemoveItem = () => {
    onRemoveItem(item);
  };

  const handleCheckboxProgram = event => {
    event.stopPropagation();
    onSelectedProgram(item, event);
  };

  const handleStopAction = event => event.stopPropagation();

  const handleOpenShareSetting = event => {
    event.preventDefault();
    event.stopPropagation();
    if (isCreatorOrOwnerOrAdmin) {
      handleShare();
    }
  };

  const renderTriggerIcon = ({ open }) => (
    <MenuTrigger itemId={_id} vertical active={!!open} className="trigger-icon" />
  );

  const renderTag = it => (
    <span key={`item-tag-${it._id}`} className="tag-name" onClick={handleSelectTag(it)}>
      {it.name}
    </span>
  );

  const renderThumbnail = () => {
    if (thumbnail) {
      return <img src={thumbnail} alt="" className="img-background" onError={onLoadBackgroundError} />;
    }

    return <DefaultCover />;
  };

  return (
    <S.CustomRow onClick={handleItemClick}>
      <Layout.TableCell verticalAlignTextTop>
        <div
          className={classNames('program-information-wrapper', {
            'align-items-center': !description,
          })}
        >
          <div className={classNames('program-thumbnail', { checked: isChecked })} onClick={handleStopAction}>
            <div className="checkbox-thumbnail">
              <Checkbox checked={isChecked} className="program-checkbox" onChange={handleCheckboxProgram} size={16} />
            </div>
            <div className="thumbnail-container">{renderThumbnail()}</div>
          </div>
          <div className="program-information">
            <div className="program-name">{title}</div>
            {description && <div className="program-description">{description}</div>}
          </div>
        </div>
      </Layout.TableCell>
      <Layout.TableCell>
        {tags.length ? (
          <div className="program-tags-container">{tags.map(renderTag)}</div>
        ) : (
          <div className="program-not-tags">--</div>
        )}
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        {active_sync && <div className="program-live-sync">Live Sync</div>}
        {isValidTotalClients && (
          <div className="program-total-clients">
            <strong>{total_clients}</strong>&nbsp;{pluralize('client', total_clients)}
          </div>
        )}
        {!active_sync && <div className="program-not-live-sync">--</div>}
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        <div className="program-total-weeks">{workout_sets.length || 0}w</div>
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        {last_interacted ? (
          <div className="program-last-interacted">{timeSince(new Date(last_interacted))}</div>
        ) : (
          <div className="program-not-last-interacted">-</div>
        )}
      </Layout.TableCell>
      <Layout.TableCell>
        <div className="program-actions-wrapper">
          {isOwner ? (
            <ShareOwnerAvatar
              user={author}
              tooltipId={`avatar-tooltip-${_id}`}
              size={25}
              cloudfrontList={cloudfrontList}
            />
          ) : (
            <div className="program-no-owner" />
          )}
          <OwnershipIconStatus place="top" itemId={_id} share={share} onItemClick={handleOpenShareSetting} />
          <DropDown
            className="program-actions-dropdown"
            triggerIcon={renderTriggerIcon}
            direction="left"
            shouldCheckItem
          >
            <div className="dropdown-area">
              {isCreatorOrOwnerOrAdmin && (
                <Option onClick={handleEditItem}>
                  <EditIcon />
                  <span>Edit</span>
                </Option>
              )}
              <Option onClick={handleDuplicateItem}>
                <DuplicateIcon />
                <span>Duplicate</span>
              </Option>
              {isCreatorOrOwnerOrAdmin && (
                <Option onClick={handleShare}>
                  <ShareIcon className="stroke-unset" />
                  <span>Sharing settings</span>
                </Option>
              )}
              {isCreatorOrOwnerOrAdmin && (
                <Option onClick={handleRemoveItem}>
                  <DeleteIcon />
                  <span>Remove</span>
                </Option>
              )}
              <div className="dropdown-arrow" />
            </div>
          </DropDown>
        </div>
      </Layout.TableCell>
    </S.CustomRow>
  );
};

export default ItemRowProgram;
