import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Modal, Radio } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { initDefaultValue } from './helpers';
import OneTimePayment from './components/OneTimePayment';
import RecurringPayment from './components/RecurringPayment';
import { CDN_URL, PACKAGE_PRICING_TYPES, PACKAGE_STATUS } from 'constants/commonData';
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
import CountryModal from 'shared/CountryModal';
import ConfirmUpdatePricing from './components/ConfirmUpdatePricing';
import { getOnBoarding } from 'redux/setup-payment/actions';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { toggleConfirmModal } from 'actions/modal';
import { isOnwer } from 'utils/validations';
import * as S from './styles';

const PricingModal = ({
  user,
  originData,
  onUpdate,
  onClose,
  packageStatus,
  payment,
  getOnBoarding,
  toggleConfirmModal,
  onSave,
}) => {
  const [pricingData, setPricingData] = useState(initDefaultValue(originData));
  const [type, setType] = useState(_.get(originData, 'type', PACKAGE_PRICING_TYPES.one_time.value));
  const [showError, setShowError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState(null);
  const [isAmountError, setAmountError] = useState(false);
  const [isEveryError, setEveryError] = useState(false);

  const isPublished = useMemo(() => {
    return packageStatus === PACKAGE_STATUS.PUBLISH;
  }, [packageStatus]);

  const isNOK = useMemo(() => {
    return _.upperCase(_.get(payment, 'account.currency', '')) === 'NOK';
  }, [payment]);

  const handleDataChange = newValue => {
    if (newValue.hasOwnProperty('amount')) {
      if (!newValue.amount || newValue.amount < isNOK ? 3 : 1 || newValue.amount > 5000) {
        handleAmountError();

        setShowError(true);
      }
    }

    setPricingData(prev => ({ ...prev, ...newValue }));
  };

  const handleChangeDropdown = newValue => {
    setPricingData(prev => ({ ...prev, ...newValue }));
  };

  const handleOnChangeEvery = newValue => {
    if (!newValue.every || newValue.every < 1 || newValue.every > 12) {
      handleEveryError();
      setShowError(true);
    }
    setPricingData(prev => ({ ...prev, ...newValue }));
  };

  const handleChangeType = (e, { value }) => {
    if (value === type || isPublished) {
      return;
    }

    setType(value);
  };

  const handleUpdatePricing = () => {
    let errors = [];

    if (!pricingData.amount || pricingData.amount < 1 || pricingData.amount > 5000) {
      errors.push('amount');
      setAmountError(true);
    }

    if (type === PACKAGE_PRICING_TYPES.recurring.value && (!pricingData.every || pricingData.every > 12)) {
      errors.push('every');
      setEveryError(true);
    }

    if (errors.length > 0) {
      return setShowError(true);
    }

    if (type === PACKAGE_PRICING_TYPES.recurring.value && isPublished && !_.isEqual(pricingData, originData)) {
      setShowConfirmModal(true);
    } else {
      performUpdate();
    }
  };

  const handleAmountError = () => {
    setAmountError(true);
  };

  const handleEveryError = () => {
    setEveryError(true);
  };

  const performUpdate = () => {
    let data = _.pick(pricingData, [
      'currency',
      'amount',
      'recurring_interval_type',
      'end_after',
      'every',
      'enable_trial',
      'trial_period',
      'is_archive_on_trial_cancellation',
      'allow_trial_once',
    ]);

    if (!data.end_after) {
      delete data.end_after;
    }

    onUpdate({ ...data, type, amount: _.round(data.amount, 2) });
    onClose();
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmUpdate = () => {
    handleCloseConfirmModal();
    performUpdate();
  };

  const handleConnect = async () => {
    if (!isOnwer(user)) {
      warningModal();
      return;
    }
    setIsOpen(true);
  };

  const onCloseCountry = () => {
    setIsOpen(false);
    setLoading(false);
  };

  const handleSelectCountry = item => {
    setCountry(item);
  };

  const handleNextStep = async () => {
    if (!isLoading) {
      setLoading(true);
      onSave();
      const responsive = await getOnBoarding({
        country: country.value,
      });
      if (responsive) {
        window.location = _.get(responsive, 'data.data.url');
      }
    }
  };

  const isConnected = _.get(payment, 'account.charges_enabled');
  const defaultCurrency = _.get(payment, 'account.currency');

  const warningModal = () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Workspace missing payment account"
        content="Please contact the owner of your workspace to set up payments with a Stripe account."
        onConfirm={() => {}}
        confirmButtonTitle="Okay"
        confirmButtonClass="btn-purple"
        headerIcon={`${CDN_URL}/images/warning_purple_Icon.svg`}
        hideCancelButton
        newStyle
        hasCloseIcon
      />,
    );
    return false;
  };

  return (
    <>
      <S.ModalWrapper
        open={true}
        closeOnDimmerClick={false}
        onClose={onClose}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
      >
        <Modal.Content>
          <S.LeftContainer>
            <S.PricingTitle>Package Pricing</S.PricingTitle>
            {_.map(PACKAGE_PRICING_TYPES, item => (
              <S.PricingTypeItem
                active={item.value === type}
                key={item.value}
                disable={packageStatus === PACKAGE_STATUS.PUBLISH && item.value !== type}
              >
                <Radio
                  label={item.label}
                  name="pricingType"
                  value={item.value}
                  checked={item.value === type}
                  onChange={handleChangeType}
                />
              </S.PricingTypeItem>
            ))}
          </S.LeftContainer>
          <S.RightContainer>
            {!isConnected && (
              <S.WarningContainer>
                <S.WarningMessage>
                  <Warning />
                  To enable payments, <span onClick={handleConnect}>please connect your Stripe account.</span>
                </S.WarningMessage>
                <S.WarningType>
                  {type === PACKAGE_PRICING_TYPES.one_time.value ? 'One-time Payment' : 'Recurring Subscription'}
                </S.WarningType>
                <S.WarningDetail>
                  {type === PACKAGE_PRICING_TYPES.one_time.value
                    ? 'This package will charge the user one time. To register for this package, your client will need to enter their email and credit card information.'
                    : 'This package will set up a recurring charge that repeats for a set number of times or renews forever. Registration requires email and credit card details.'}
                </S.WarningDetail>
              </S.WarningContainer>
            )}
            {isConnected && (
              <>
                {type === PACKAGE_PRICING_TYPES.one_time.value && (
                  <OneTimePayment
                    data={
                      pricingData.id
                        ? pricingData
                        : {
                            ...pricingData,
                            currency: defaultCurrency,
                          }
                    }
                    onChange={handleDataChange}
                    showError={showError}
                    amountErrorField={isAmountError}
                  />
                )}
                {type === PACKAGE_PRICING_TYPES.recurring.value && (
                  <RecurringPayment
                    data={
                      pricingData.id
                        ? pricingData
                        : {
                            ...pricingData,
                            currency: defaultCurrency,
                          }
                    }
                    onChange={handleDataChange}
                    onChangeType={handleChangeDropdown}
                    onChangeEvery={handleOnChangeEvery}
                    showError={showError}
                    amountErrorField={isAmountError}
                    everyErrorField={isEveryError}
                  />
                )}
              </>
            )}
            <S.Actions>
              <Button purple onClick={handleUpdatePricing} disabled={!isConnected} className="update-pricing">
                Update Pricing
              </Button>
            </S.Actions>
          </S.RightContainer>
        </Modal.Content>
        {showConfirmModal && <ConfirmUpdatePricing onClose={handleCloseConfirmModal} onConfirm={handleConfirmUpdate} />}
      </S.ModalWrapper>
      {isOpen && <CountryModal onNextStep={handleNextStep} onChange={handleSelectCountry} onClose={onCloseCountry} />}
    </>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: { setupPayment },
  } = state;

  return {
    user,
    payment: setupPayment.toJS(),
  };
};

const actionCreators = {
  getOnBoarding,
  toggleConfirmModal,
};

export default connect(mapState, actionCreators)(PricingModal);
