import styled from 'styled-components';

const textBase = `
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 150%;
    color: #202353;
`;

export const HeaderWrapper = styled.div`
  height: 60px;
  width: 100%;
  padding: 0 30px 0 15px;
  background: #fff;
  border-bottom: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 79px;
  border-radius: 5px;
  ${textBase}
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin-right: 5px;
  svg {
    padding-right: 5px;
    path {
      fill: #202353;
    }
  }
  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }
`;

export const Separate = styled.div`
  width: 1px;
  height: 16px;
  background: #e1e1ea;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  .toggle-input.toggle-dietary-info {
    label {
      padding-left: 38px;
      span.label-dietary-info {
        user-select: none;
        ${textBase}
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      }
    }
    input ~ label::before {
      background-color: #e0e1e4;
    }
    input:checked ~ label::before,
    input:checked:focus ~ label::before {
      background-color: #36ba7b;
    }
  }
  .save-button-day-detail {
    ${textBase}
    font-size: 12px;
    font-weight: 600;
    padding: 10px 20px;
    height: 30px;
    color: #fff;
    background: rgb(81, 88, 207, 1);
    border: none;
    display: flex;
    align-items: center;
    min-width: unset;
    &:hover {
      background: rgba(81, 88, 207, 0.9);
    }
    &:disabled {
      color: #7b7e91;
      background: #eaeef1;
    }
  }
`;
