import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { DATE_FORMAT, STEP_UNIQUE_CODE } from 'constants/commonData';
import { TableContainer } from './style';
import { pluralize } from 'utils/commonFunction';
import LoadingItem from './LoadingItem';
import { getStepProgressDataMonthly } from 'redux/client-step/actions';

function MonthlyProgress({ getStepProgressDataMonthly, client, fromDate, manualStepSelected }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [queryRange, setQueryRange] = useState({
    fromDate: moment(fromDate, DATE_FORMAT).subtract(1, 'year').startOf('year'),
    toDate: moment(fromDate, DATE_FORMAT).endOf('year'),
  });

  // useEffect(() => {
  //   if (!moment(fromDate, DATE_FORMAT).isSame(moment(queryRange.fromDate, DATE_FORMAT), 'year')) {
  //     setQueryRange({
  //       fromDate: moment(fromDate, DATE_FORMAT).startOf('year'),
  //       toDate: moment(fromDate, DATE_FORMAT).endOf('year'),
  //     });
  //   }
  // }, [fromDate]);

  useEffect(() => {
    setLoading(true);
    getStepProgressDataMonthly({
      fromDate: queryRange.fromDate.format(DATE_FORMAT),
      toDate: queryRange.toDate.format(DATE_FORMAT),
      client: client,
      metric_code: manualStepSelected ? manualStepSelected.unique_code : STEP_UNIQUE_CODE,
    }).then(response => {
      const { data } = response.data;
      setList(
        data.filter(item => !!item.avg).sort((a, b) => (moment(a, 'MM-YYYY').isAfter(moment(b, 'MM-YYYY')) ? 1 : -1)),
      );
      setLoading(false);
    });
  }, [queryRange, manualStepSelected]);

  return (
    <TableContainer className="weekly">
      <div className="table-header">
        <div className="table-header__content">
          <div className="time">Date</div>
          <div className="value">Total Steps</div>
        </div>
      </div>
      <div className={classnames('table-content', { empty: !loading && !list.length })}>
        {loading ? (
          <div className="list">
            <div className="loading-container">
              <LoadingItem key="1" />
              <LoadingItem key="2" />
              <LoadingItem key="3" />
              <LoadingItem key="4" />
              <LoadingItem key="5" />
            </div>
          </div>
        ) : list.length ? (
          <div className="list">
            {list.map(item => {
              return (
                <div key={item.month} className="item">
                  <div className="time">{moment(item.month, 'MM-YYYY').format('MMM YYYY')}</div>
                  <div className="value">
                    {parseInt(item.avg).toLocaleString()}&nbsp;{pluralize('step', parseInt(item.avg))}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-data">No data available</div>
        )}
      </div>
    </TableContainer>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      client: { workingClientDetail },
      clientStep: { fromDate, manualStepSelected },
    },
  } = state;

  return { client: workingClientDetail._id, fromDate, manualStepSelected };
};

export default connect(mapState, { getStepProgressDataMonthly })(MonthlyProgress);
