// Libs
import React from 'react';

// Parts
import { getTriggerAssignLabel } from 'shared/TriggerForms/units/helper';

// Assets
import { ReactComponent as ArrowConnectTopIcon } from 'assets/icons/arrow_connect_top.svg';

const OptionItem = props => {
  const { option, type } = props;

  if (typeof option !== 'object') return null;

  const { label } = option;

  return (
    <div className="option-wrapper">
      <div className="option-name">{label}</div>
      <div className="assign-to-wrapper">
        <ArrowConnectTopIcon />
        <div className="assign-to-label">Assign to</div>
        <div className="assign-to-program-forum-label">{getTriggerAssignLabel(option, type)}</div>
      </div>
    </div>
  );
};

export default OptionItem;
