import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

import * as S from './style';
import { MODES } from '../constant';
import { CDN_URL } from 'constants/commonData';
import { Button, ErrorMessage } from 'shared/FormControl';
import { DropdownIndicator } from 'shared/Icons';
import ConfirmDelete from '../ConfirmDelete';
import CustomOption from '../CustomOption';

const selectBoxStyles = {
  control: base => ({ ...base, minHeight: 36, height: 36 }),
  menu: base => ({ ...base, margin: 0 }),
};

const LIMIT_METRIC_NAME = 30;

class MetricDetail extends React.Component {
  constructor(props) {
    super(props);
    const { originalData, mode } = props;
    this.state = {
      name: originalData.name || '',
      category: originalData.category || null,
      unit: originalData.unit || null,
      showError: false,
      editable: mode === MODES.EDIT ? !!originalData && !!originalData.editable : true,
      showConfirmDeletePopup: false,
      focusedNameInput: false,
    };
    this.isSubmitting = false;
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.__isMounted = true;
  }

  compoentWillUnmount() {
    this.__isMounted = false;
  }

  onSelectType = data => {
    const newState = { category: data };

    if (data.default_unit) {
      newState.unit = (data.units || []).find(item => item._id === data.default_unit);
    } else {
      newState.unit = null;
    }

    this.setState({ ...newState });
  };

  onSelectUnit = data => {
    this.setState({ unit: data });
  };

  onChangeName = event => {
    const text = event.target.value;
    if (text.length > LIMIT_METRIC_NAME) return;
    this.setState({ name: text });
  };

  onSave = () => {
    if (this.isSubmitting) {
      return;
    }

    const { name, unit, category } = this.state;
    const { mode, originalData } = this.props;
    const trimmedName = name.trim();

    if (
      mode === MODES.EDIT &&
      trimmedName === originalData.name &&
      _.get(unit, '_id') === _.get(originalData, 'unit._id')
    ) {
      return this.props.onBack();
    }

    if (!name.trim() || !category || !unit) {
      return this.setState({ showError: true });
    }

    const bodyData = { name: trimmedName, category: category._id, unit: unit ? unit._id : '' };
    this.isSubmitting = true;

    if (mode === MODES.EDIT) {
      bodyData.unique_code = originalData.unique_code;

      return this.props
        .onUpdate(bodyData)
        .then(() => {
          toast.success('Changes to default client metrics saved!');

          if (this.__isMounted) {
            this.props.onBack();
          }
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }

    this.props
      .onAdd(bodyData)
      .then(() => {
        if (this.__isMounted) {
          this.props.onBack();
        }
      })
      .catch(() => {
        this.isSubmitting = false;
      });
  };

  onClickRemove = () => {
    this.setState({ showConfirmDeletePopup: true });
  };

  onConfirmDelete = () => {
    const { originalData } = this.props;
    this.setState({ showConfirmDeletePopup: false });
    this.isSubmitting = true;
    this.props
      .onDelete(originalData)
      .then(() => {
        if (this.__isMounted) {
          this.props.onBack();
        }
      })
      .catch(() => {
        this.isSubmitting = false;
      });
  };

  enableInputName = () => {
    setTimeout(() => {
      if (this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
        this.setState({ focusedNameInput: true });
      }
    }, 100);
  };

  handleBlur = () => {
    this.setState({ focusedNameInput: false });
  };

  render() {
    const { name, category, unit, showError, editable, showConfirmDeletePopup, focusedNameInput } = this.state;
    const { mode, unitCategories, originalData } = this.props;

    return (
      <S.Wrapper className={classnames('metricDetail', { adding: mode === MODES.ADD })}>
        {mode === MODES.EDIT && editable ? (
          <S.Note>
            Metrics are shared within a workspace and may be used by another trainer. It is a good practice to share
            updates with your teammates.
          </S.Note>
        ) : null}
        <S.FormContainer className="metricDetail__form">
          <S.FormGroup className={classnames({ error: showError && !name.trim() })}>
            <S.NameLabelWrapper>
              <label>METRIC NAME </label>
              <label>
                {focusedNameInput && name.length >= LIMIT_METRIC_NAME - 5 && `${name.length}/${LIMIT_METRIC_NAME}`}
              </label>
            </S.NameLabelWrapper>
            <input
              ref={this.inputRef}
              placeholder="Enter name..."
              value={name}
              onChange={this.onChangeName}
              disabled={mode === MODES.EDIT && (!editable || !originalData.custom)}
              onClick={this.enableInputName}
              onBlur={this.handleBlur}
              maxLength={LIMIT_METRIC_NAME}
            />
            <ErrorMessage>Title cannot be empty</ErrorMessage>
          </S.FormGroup>
          <S.GroupInput>
            <S.FormGroup className={classnames({ error: showError && !category })}>
              <label>Type</label>
              <Select
                key="select--category"
                options={unitCategories}
                getOptionLabel={option => option.name}
                getOptionValue={option => option._id}
                components={{ DropdownIndicator, IndicatorSeparator: null }}
                placeholder="Choose metric type"
                menuPosition="fixed"
                onChange={this.onSelectType}
                classNamePrefix="evfSelectBox"
                className="evfSelectBoxContainer"
                value={category}
                isSearchable={false}
                isDisabled={mode === MODES.EDIT}
                styles={selectBoxStyles}
              />
              <ErrorMessage>Please select Type</ErrorMessage>
            </S.FormGroup>
            <S.FormGroup className={classnames({ error: showError && !unit })}>
              <label>Unit</label>
              <Select
                key="select--unit"
                options={category ? category.units : []}
                getOptionLabel={option => option.title || '-'}
                getOptionValue={option => option._id}
                components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomOption }}
                placeholder="-"
                menuPosition="fixed"
                onChange={this.onSelectUnit}
                classNamePrefix="evfSelectBox"
                className="evfSelectBoxContainer"
                value={unit}
                isSearchable={false}
                styles={selectBoxStyles}
                isDisabled={!editable}
              />
              <ErrorMessage>Please select Unit</ErrorMessage>
            </S.FormGroup>
          </S.GroupInput>
        </S.FormContainer>
        <S.Actions>
          {mode === MODES.EDIT ? (
            <>
              {editable ? (
                <Button className="button--remove-metric" onClick={this.onClickRemove}>
                  <img src={`${CDN_URL}/images/remove_bg_grey.svg`} alt="remove" />
                  <span>Remove Metric</span>
                </Button>
              ) : (
                <div />
              )}
              {!editable ? (
                <>
                  <Button
                    className="button--save-metric--disabled"
                    data-tip
                    data-for="tooltip--metricDetail__disable-edit"
                  >
                    Save
                  </Button>
                  <ReactTooltip
                    id="tooltip--metricDetail__disable-edit"
                    effect="solid"
                    place="top"
                    className="app-tooltip"
                  >
                    <div>
                      You do not have permission to edit this metric. Please contact your admin, or create a new metric.
                    </div>
                  </ReactTooltip>
                </>
              ) : (
                <Button purple className="button--save-metric" onClick={this.onSave}>
                  Save
                </Button>
              )}
            </>
          ) : (
            <>
              <div />
              <Button purple className="button--create-metric" onClick={this.onSave}>
                Create
              </Button>
            </>
          )}
        </S.Actions>
        {showConfirmDeletePopup ? (
          <ConfirmDelete
            data={originalData}
            onClose={() => this.setState({ showConfirmDeletePopup: false })}
            onConfirm={this.onConfirmDelete}
          />
        ) : null}
      </S.Wrapper>
    );
  }
}

export default MetricDetail;
