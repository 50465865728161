import styled from 'styled-components';

export const Tabs = styled.div`
  border-bottom: 1px solid #dcdcde;
  padding: 0 20px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .tab {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    cursor: pointer;
    position: relative;

    .name {
      position: relative;
      margin-right: 3px;
    }

    &.active,
    &:hover {
      color: #494699;
      .name::after {
        display: block;
      }
    }

    &.active {
      font-weight: bold;
      ::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #494699;
      }
    }
  }
`;

export const List = styled.div`
  background-color: #fff;
  height: 300px;
  overflow-x: hidden;
  overflow-y: overlay;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #263d52;

  .no-data {
    padding: 20px;
    text-align: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid #dcdcde;

      :nth-child(even) {
        background-color: #fafbfc;
      }

      td {
        text-align: left;
        padding: 15px 20px;

        .name {
          margin-bottom: 2px;
          a {
            color: inherit;
            :hover {
              color: #726ee4;
            }
          }
        }

        .date {
          font-size: 11px;
          font-weight: normal;
          color: #5a636c;
        }

        &.action {
          text-align: center;
          width: 60px;

          .icon {
            cursor: pointer;
            margin: auto;
          }
        }
      }
    }
  }
`;
