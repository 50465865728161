import React from 'react';
import { pluralize } from 'utils/commonFunction';
import Avatar from 'react-avatar';
import get from 'lodash/get';

import GroupAvatar from 'assets/icons/group_option.svg';

import * as S from './style';

export default ({ innerProps, children, data, isFocused, innerRef }) => {
  let className = 'multi-select__option';
  if (isFocused) {
    className += ' multi-select__option--is-focused';
  }
  const name = get(data, 'name', '');

  const handleCountClient = value => {
    const label = pluralize('Client', value);
    const val = String(value).concat(' ', label);
    return val;
  };

  return (
    <S.SelectGroupContainer ref={innerRef} {...innerProps} className={className}>
      <div className="multi-select__option__label">
        <div className="avatar">
          <Avatar className="group-avatar" size="26" src={GroupAvatar} />
        </div>
        <div>
          <div className="name">
            <b>Group:</b> {name}
          </div>
          <div className="totalMember">{handleCountClient(get(data, 'total_clients', 0))}</div>
        </div>
      </div>
    </S.SelectGroupContainer>
  );
};
