import { CDN_URL } from 'constants/commonData';

export const DEFAULT_FORUM_BANNER = `${CDN_URL}/images/default_forum_banner.svg`;

export const CREATE_NEW_FORUM_BANNER = `${CDN_URL}/images/create_new_forum_banner.svg`;

export const FILE_VALIDATION = {
  VIDEO_VALIDATE_EXTENTION: ['avi', 'flv', 'mp4', 'mov', '3gp'],
  ACCEPTED_VIDEO: '.avi, .flv, .mp4, .mov, .3gp',
  ACCEPTED_IMAGE: 'image/png, image/jpeg, image/jpg',
  IMAGE_VALIDATE_EXTENTION: ['png', 'jpeg', 'jpg'],
  ACCEPTED: 'image/png, image/jpg, image/jpeg, .avi, .flv, .mp4, .mov, .3gp',
  EXTENTIONS: ['png', 'jpg', 'jpeg', 'avi', 'flv', 'mp4', 'mov', '3gp'],
  MAX_SIZE_VIDEO: 150,
  MAX_SIZE_VIDEO_FORUM: 300,
  MAX_SIZE_IMAGE_FORUM: 25,
};

export const FILE_LIMIT_MESSAGE = {
  IMAGE_FILE: 'Image size limit is 25MB.',
};

export const NAVS = [
  { label: 'Discussion', route: '/discussion' },
  { label: 'Scheduled Posts', route: '/upcoming' },
  { label: 'Members', route: '/members' },
  { label: 'Leaderboard', route: '/leaderboard' },
];

export const TYPES = { TEXT: 'text', FILE: 'file' };

export const POST_TYPES = {
  POST: 1,
  LEADERBOARD: 2,
};

export const FORUM_MOMENT_SETTING = {
  future: 'in %s',
  past: diff => (diff == 'just now' ? diff : `${diff} ago`),
  s: 'just now',
  ss: '%d seconds',
  m: '1m',
  mm: '%dm',
  h: '1h',
  hh: '%dh',
  d: '1d',
  dd: number => {
    if (number < 7) {
      return number + 'd';
    } else {
      let weeks = Math.round(number / 7);
      return weeks + 'w';
    }
  },
  M: '4w',
  y: '1y',
  yy: '%dy',
};

export const TYPE_ERROR = {
  FORUM_POST: 'forum_post',
  COMMENT: 'comment',
};
