import React, { useEffect, useRef } from 'react';
import _ from 'lodash';

import * as S from './style';
import { Bar, Chart } from 'react-chartjs-2';
import { extendChartBorderRadius } from '../DailyCalo/helper';

Chart.Tooltip.positioners.center = function (elements) {
  const { x, y, base } = elements[0]._model;
  const height = base - y;
  return { x, y: y + height / 2 };
};

const caloriesTypes = [
  { value: 'protein', color: '#57B5EA', percentKey: 'protein_percent' },
  { value: 'carbs', color: '#65CC9C', percentKey: 'carbs_percent' },
  { value: 'fat', color: '#FFBE49', percentKey: 'fat_percent' },
];

const initChartData = (reportData, weekCalendar, types) => {
  const labels = ['Protein', 'Carbs', 'Fat'];
  const isNodata = reportData.meals && !reportData.meals.length;
  const datasets = [
    {
      type: 'bar',
      label: 'Actual',
      backgroundColor: _.map(caloriesTypes, 'color'),
      hoverBorderWidth: 0,
      borderWidth: 0,
      data: isNodata
        ? []
        : _.map(caloriesTypes, type => _.round(_.get(reportData.averageMeal, ['value', type.percentKey]), 0)),
    },
    {
      type: 'bar',
      label: 'Goal',
      backgroundColor: '#EEF0F7',
      hoverBorderWidth: 0,
      borderWidth: 0,
      data: isNodata
        ? []
        : _.map(caloriesTypes, type => _.round(_.get(reportData.goals, [0, 'value', type.percentKey]), 0)),
    },
  ];

  return {
    labels,
    datasets,
  };
};

const DistributionChart = ({ reportData }) => {
  const chartRef = useRef(null);
  const data = initChartData(reportData);

  useEffect(() => {
    Chart.helpers.extend(Chart.elements.Rectangle.prototype, extendChartBorderRadius());
  }, []);

  return (
    <S.ChartWrapper>
      {reportData.meals && !reportData.meals.length && <S.NoData>No data in range</S.NoData>}
      <Bar
        ref={chartRef}
        data={data}
        height={140}
        options={{
          cornerRadius: 3,
          legend: {
            display: false,
          },
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');
              var tooltipCaretEl = document.getElementById('chartjs-tooltip-caret');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<div id ="evf-tooltip"></div>';
                document.body.appendChild(tooltipEl);
              }
              if (!tooltipCaretEl) {
                tooltipCaretEl = document.createElement('div');
                tooltipCaretEl.id = 'chartjs-tooltip-caret';
                document.body.appendChild(tooltipCaretEl);
              }
              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                tooltipCaretEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<div>';

                titleLines.forEach(function (title) {
                  innerHtml += title;
                });
                innerHtml += '</div><div>';

                bodyLines.forEach(function (body, i) {
                  const content = body[0].split(': ');
                  innerHtml += '<span  style="font-weight: normal;">' + content[0] + '</span> - ' + content[1] + '%';
                });
                innerHtml += '</div>';

                var tableRoot = tooltipEl.querySelector('#evf-tooltip');
                if (tableRoot) {
                  tableRoot.innerHTML = innerHtml;
                }
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              /* FUNCTION TO GET BAR PROPS */
              const getBARSegment = chart => {
                const dataPoints = tooltipModel.dataPoints,
                  bar = chart.active[dataPoints[0].datasetIndex]._model,
                  canvasPosition = chart.canvas.getBoundingClientRect(),
                  paddingLeft = parseFloat(getComputedStyle(chart.canvas).paddingLeft),
                  paddingTop = parseFloat(getComputedStyle(chart.canvas).paddingTop),
                  scrollLeft = document.body.scrollLeft,
                  scrollTop = document.body.scrollTop;

                return {
                  top: bar.y + canvasPosition.top + paddingTop + scrollTop,
                  left: bar.x - bar.width / 2 + canvasPosition.left + paddingLeft + scrollLeft,
                  width: bar.width,
                  height: bar.base - bar.y,
                };
              };

              /* GET BAR PROPS (width, height, top, left) */
              const barHeight = getBARSegment(this._chart).height,
                barTop = getBARSegment(this._chart).top;
              const centerY = barTop + barHeight / 2;

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX - 10 - tooltipModel.width / 2 + 'px';
              tooltipEl.style.top = centerY - tooltipModel.height * 1.5 + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = 10 + 'px';
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.background = '#2D2E2D';
              tooltipEl.style.textAlign = 'left';
              tooltipEl.style.fontSize = '13px';
              tooltipEl.style.fontWeight = '700';
              tooltipEl.style.lineHeight = '19px';
              // Display, position, and set styles for caret
              tooltipCaretEl.style.opacity = 1;
              tooltipCaretEl.style.position = 'absolute';
              tooltipCaretEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + tooltipModel.xPadding / 2 - 10 + 'px';
              // tooltipCaretEl.style.width = 0;
              // tooltipCaretEl.style.height = 0;
              tooltipCaretEl.style.top = centerY - tooltipModel.height * 0.5 + 14 + 'px';
              tooltipCaretEl.style.pointerEvents = 'none';
              tooltipCaretEl.style.borderStyle = 'solid';
              tooltipCaretEl.style.borderWidth = '9px 8px 0';
              tooltipCaretEl.style.borderColor = '#2D2E2D transparent transparent transparent';
            },
          },
          drawOnChartArea: false,
          responsive: true,
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                barPercentage: 0.7,
                categoryPercentage: 0.5,
                ticks: {
                  beginAtZero: true,
                  fontColor: '#20235350',
                  fontSize: 11,
                  fontStyle: 'bold',
                  padding: 9,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawOnChartArea: false,
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: '#20235350',
                  fontStyle: 'bold',
                  fontSize: 11,
                  suggestedMax: 50,
                  stepSize: 15,
                  callback: function (value, index, values) {
                    return value + '%';
                  },
                },
              },
            ],
          },
        }}
      />
    </S.ChartWrapper>
  );
};

export default DistributionChart;
