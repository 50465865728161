import React from 'react';
import isEmpty from 'lodash/isEmpty';

// Constants
import { COLOR_HEART_RATE_CHART } from 'components/BodyMetricChartNew/constants';

import * as S from './style';

const HeartRateKeysMetric = props => {
  const {
    bodyMetric,
    hearRateMetric: { value_range },
  } = props;
  const { min = 0, max = 0 } = value_range;
  const { unit: unitDefaultTitle = '', isGettingChartData, isGettingTarget } = bodyMetric;
  if (bodyMetric.isGettingChartData) return null;

  return (
    <S.Wrapper isShowRangeTitle={true}>
      {!isGettingChartData && !isGettingTarget && (
        <S.CurrentWrapper>
          <S.HeartRateKeyLabel color={COLOR_HEART_RATE_CHART}>
            {isEmpty(value_range) || min === max ? '' : 'Range'}
          </S.HeartRateKeyLabel>
          <>
            <S.Number current>
              <>
                {!isEmpty(value_range) ? min === max ? min : `${min} - ${max}` : <span className="empty-line">—</span>}{' '}
                <S.Unit> {unitDefaultTitle}</S.Unit>
              </>
            </S.Number>
          </>
        </S.CurrentWrapper>
      )}
    </S.Wrapper>
  );
};

export default HeartRateKeysMetric;
