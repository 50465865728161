import React from 'react';
import _ from 'lodash';
import { successGetClientStatistic } from '../actions';
import { AUTOFLOW_ASSIGN_CLIENT_ERRORS } from 'constants/commonData';
import { toggleModal } from 'actions/modal';
import { ClientInAutoflowWarningModal, AssignClientSuccessModal } from 'components/AutoflowConfirmModals';
import Request from 'configs/request';

export const Types = {
  SUCCESS_GET_AUTOFLOW_CLIENT_LIST: 'SUCCESS_GET_AUTOFLOW_CLIENT_LIST',
  FAILED_GET_AUTOFLOW_CLIENT_LIST: 'FAILED_GET_AUTOFLOW_CLIENT_LIST',
  RESET_AUTOFLOW_CLIENT_DATA: 'RESET_AUTOFLOW_CLIENT_DATA',
  AUTOFLOW_CLIENT_CHANGE_QUERY_PARAM: 'AUTOFLOW_CLIENT_CHANGE_QUERY_PARAM',
  AUTOFLOW_CLIENT_SELECT_CLIENTS: 'AUTOFLOW_CLIENT_SELECT_CLIENTS',
  AUTOFLOW_CLIENT_SUCCESS_REMOVE_CLIENTS: 'AUTOFLOW_CLIENT_SUCCESS_REMOVE_CLIENTS',
  AUTOFLOW_CLIENT_SUCCESS_REMOVE_ONE_CLIENT: 'AUTOFLOW_CLIENT_SUCCESS_REMOVE_ONE_CLIENT',
  AUTOFLOW_ACTIVATE_CLIENTS: 'AUTOFLOW_ACTIVATE_CLIENTS',
  AUTOFLOW_SUCCESS_ACTIVATE_CLIENTS: 'AUTOFLOW_SUCCESS_ACTIVATE_CLIENTS',
  AUTOFLOW_FAILED_ACTIVATE_CLIENTS: 'AUTOFLOW_FAILED_ACTIVATE_CLIENTS',
  AUTOFLOW_PAUSE_CLIENTS: 'AUTOFLOW_PAUSE_CLIENTS',
  AUTOFLOW_SUCCESS_PAUSE_CLIENTS: 'AUTOFLOW_SUCCESS_PAUSE_CLIENTS',
  AUTOFLOW_FAILED_PAUSE_CLIENTS: 'AUTOFLOW_FAILED_PAUSE_CLIENTS',
  AUTOFLOW_SUCCESS_ADD_CLIENTS: 'AUTOFLOW_SUCCESS_ADD_CLIENTS',
  AUTOFLOW_SUCCESS_MARK_CLIENTS_AS_COMPLETE: 'AUTOFLOW_SUCCESS_MARK_CLIENTS_AS_COMPLETE',
  AUTOFLOW_FAILED_MARK_CLIENTS_AS_COMPLETE: 'AUTOFLOW_FAILED_MARK_CLIENTS_AS_COMPLETE',
};

export const resetData = () => ({ type: Types.RESET_AUTOFLOW_CLIENT_DATA });

export const selectClients = data => ({
  type: Types.AUTOFLOW_CLIENT_SELECT_CLIENTS,
  payload: { data },
});

const compareRequest = (request, currentState) => {
  if (request.page !== currentState.page) {
    return false;
  }

  if (request.sort !== currentState.sort) {
    return false;
  }

  if (request.sorter !== request.sorter) {
    return false;
  }

  if (request.search.trim() !== request.search.trim()) {
    return false;
  }

  return true;
};

export const changeAutoflowClientQueryParam = newQuery => ({
  type: Types.AUTOFLOW_CLIENT_CHANGE_QUERY_PARAM,
  payload: { newQuery },
});

export const getClientList = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { client, common } = autoflow;
    const { workingAutoflow } = common;

    return dispatch(
      Request.get(
        {
          url: `/api/autoflow/client/list`,
          params: {
            ...client.query,
            search: client.query.search.trim(),
            autoflow: _.get(workingAutoflow, '_id'),
          },
        },
        true,
        response => {
          const { data } = response;
          const state = getState();
          const { query } = state.rootReducer.autoflow.client;
          const list = _.map(data.data, item => {
            const { client } = item;
            const trainer = _.get(client, 'trainers[0].trainer');

            return {
              ...item,
              client: { ...client, trainer },
            };
          });

          if (compareRequest(client.query, query)) {
            dispatch({
              type: Types.SUCCESS_GET_AUTOFLOW_CLIENT_LIST,
              payload: { list, total: data.total },
            });
          }

          return response.data;
        },
        error => {
          dispatch({
            type: Types.FAILED_GET_AUTOFLOW_CLIENT_LIST,
            error: error,
          });
        },
      ),
    );
  };
};

export const refreshData = autoflow => {
  return dispatch => {
    dispatch(changeAutoflowClientQueryParam({ page: 1 }));
    dispatch(getClientList());
  };
};

const updateClientStatistic = (response, autoflow) => {
  return dispatch => {
    const { data } = response.data;
    dispatch(successGetClientStatistic({ data, autoflow }));
  };
};

export const addClients = data => {
  return Request.post({ url: `/api/autoflow/client/bulk_add`, data }, true, (response, { dispatch }) => {
    dispatch(getClientList());
    dispatch(updateClientStatistic(response, data.autoflow));
    dispatch({ type: Types.AUTOFLOW_SUCCESS_ADD_CLIENTS });
    dispatch(assignClientsSuccess(response, data.first_client_name));
  });
};

export const deleteClients = data => {
  return Request.post({ url: `/api/autoflow/client/bulk_delete`, data }, true, (response, { dispatch, getState }) => {
    dispatch({ type: Types.AUTOFLOW_CLIENT_SUCCESS_REMOVE_CLIENTS });
    dispatch(updateClientStatistic(response, data.autoflow));
    const statistic = response.data.data;

    const {
      rootReducer: {
        autoflow: {
          client: {
            query: { is_completed },
          },
        },
      },
    } = getState();

    if (is_completed && !statistic.completed) {
      dispatch(changeAutoflowClientQueryParam({ status: undefined, is_completed: undefined }));
    }

    dispatch(refreshData(data.autoflow));
  });
};

export const deleteClient = data => {
  return Request.post({ url: `/api/autoflow/client/delete`, data }, true, (response, { dispatch }) => {
    dispatch({
      type: Types.AUTOFLOW_CLIENT_SUCCESS_REMOVE_ONE_CLIENT,
      payload: { client: data.client },
    });
    dispatch(refreshData(data.autoflow));
    dispatch(updateClientStatistic(response, data.autoflow));
  });
};

export const successActivateClients = data => ({
  type: Types.AUTOFLOW_SUCCESS_ACTIVATE_CLIENTS,
  payload: data,
});

export const successPauseClients = data => ({
  type: Types.AUTOFLOW_SUCCESS_PAUSE_CLIENTS,
  payload: data,
});

export const activateClients = (data, multiple) => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_ACTIVATE_CLIENTS });

    return dispatch(
      Request.put(
        { url: `/api/autoflow/client/resume`, data },
        true,
        response => {
          dispatch(successActivateClients({ data, multiple }));
          dispatch(refreshData(data.autoflow));
          dispatch(updateClientStatistic(response, data.autoflow));
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_ACTIVATE_CLIENTS }),
      ),
    );
  };
};

export const pauseClients = (data, multiple) => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_PAUSE_CLIENTS });

    return dispatch(
      Request.put(
        { url: `/api/autoflow/client/pause`, data },
        true,
        response => {
          dispatch(successPauseClients({ data, multiple }));
          dispatch(refreshData(data.autoflow));
          dispatch(updateClientStatistic(response, data.autoflow));
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_PAUSE_CLIENTS }),
      ),
    );
  };
};

export const searchClients = data => Request.post({ url: `/api/autoflow/client/search`, data });

export const searchGroupClients = data => Request.post({ url: `/api/client-management/client-group-list`, data });

export const assignClientsFromMainList = data => {
  return Request.post({ url: '/api/autoflow/client/bulk_add', data }, true);
};

export const successMaskClientsAsComplete = data => ({
  type: Types.AUTOFLOW_SUCCESS_MARK_CLIENTS_AS_COMPLETE,
  payload: data,
});

export const markClientsAsComplete = (data, multiple) => {
  return Request.post(
    { url: '/api/autoflow/client/bulk_mark_complete/', data },
    true,
    (response, { dispatch }) => {
      dispatch(successMaskClientsAsComplete({ data, multiple }));
      dispatch(updateClientStatistic(response, data.autoflow));
      dispatch(refreshData());
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.AUTOFLOW_FAILED_MARK_CLIENTS_AS_COMPLETE });
    },
  );
};

export const assignClientsSuccess = (response, first_client_name) => {
  const { list_success, list_error } = response.data;

  const existOnOther = _.sumBy(list_error, item =>
    item.code === AUTOFLOW_ASSIGN_CLIENT_ERRORS.EXIST_ON_OTHER ? 1 : 0,
  );

  if (!list_success.length && list_error.length === 1 && existOnOther) {
    return dispatch => {
      dispatch(toggleModal(true, <ClientInAutoflowWarningModal client_name={first_client_name} />));
    };
  }

  const successMessage = `${
    list_success.length === 1 ? '1 client was' : `${list_success.length} clients were`
  } added to the Autoflow.`;

  let errorMessage = '';
  const errors = [];

  if (list_error.length) {
    errorMessage += `${list_error.length === 1 ? '1 client was' : `${list_error.length} clients were`} not added:`;

    const existOnThis = _.sumBy(list_error, item =>
      item.code === AUTOFLOW_ASSIGN_CLIENT_ERRORS.EXIST_ON_THIS ? 1 : 0,
    );

    const pending = _.sumBy(list_error, item => (item.code === AUTOFLOW_ASSIGN_CLIENT_ERRORS.CLIENT_PENDING ? 1 : 0));

    const offline = _.sumBy(list_error, item => (item.code === AUTOFLOW_ASSIGN_CLIENT_ERRORS.CLIENT_OFFLINE ? 1 : 0));

    const belongToOther = _.sumBy(list_error, item =>
      item.code === AUTOFLOW_ASSIGN_CLIENT_ERRORS.CLIENT_BELONG_TO_OTHER_TRAINER ? 1 : 0,
    );

    if (existOnThis) {
      errors.push(`${existOnThis === 1 ? '1 client is' : `${existOnThis} clients are`} already in Autoflow.`);
    }

    if (pending) {
      errors.push(`${pending} ${pending === 1 ? 'is' : 'are'} pending client${pending !== 1 ? 's' : ''}.`);
    }

    if (offline) {
      errors.push(`${offline} ${offline === 1 ? 'is' : 'are'} offline client${offline !== 1 ? 's' : ''}.`);
    }

    if (existOnOther) {
      errors.push(
        <p>
          {`${existOnOther} client${existOnOther !== 1 ? 's' : ''} need${
            existOnOther !== 1 ? '' : 's'
          } to be marked as `}
          <b>completed</b> or <b>removed</b> from their current Autoflows before adding to a new Autoflow.
        </p>,
      );
    }

    if (belongToOther) {
      errors.push(
        `${belongToOther} client${belongToOther !== 1 ? 's' : ''} belong${
          belongToOther === 1 ? 's' : ''
        } to other trainer`,
      );
    }
  }

  return dispatch => {
    dispatch(
      toggleModal(
        true,
        <AssignClientSuccessModal successMessage={successMessage} errorMessage={errorMessage} errors={errors} />,
      ),
    );
  };
};
