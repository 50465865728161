import React from 'react';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash-update.svg';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clientRemoveSubCoach } from 'redux/client/client.actionCreators';
import { Button } from 'shared/FormControl';
import { getUserShortName } from 'utils/commonFunction';
import { Center, Left } from '../style';
import AddCoach from './AddCoach';
import { SIDEBAR_ID } from 'components/ClientSettings/constants';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const Permission = ({ toggleConfirmModal, toggleModal, listSubCoach, clientRemoveSubCoach, clientId }) => {
  const handleConfirm = (idTrainer, nameTrainer) => {
    clientRemoveSubCoach(clientId, idTrainer, nameTrainer);
  };

  const handleRemove = (id, name) => {
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title="Remove teammate?"
        content="Once you remove the teammate, they will not have access to this client anymore. Would you like to continue?"
        onConfirm={() => handleConfirm(id, name)}
        confirmButtonTitle="Remove"
        noBorder
        className="custom-popup"
      />,
    );
  };

  const handleAdd = () => {
    toggleModal(true, <AddCoach />);
  };

  return (
    <S.WrapperMain>
      <S.Wrapper id={SIDEBAR_ID.permission}>
        <Left>
          <S.SettingName>
            <span>Team Permission</span>
          </S.SettingName>
        </Left>
        <Center>
          <S.Content>
            <S.Module>
              <S.Description>Add teammates to manage this client</S.Description>
            </S.Module>
          </S.Content>
        </Center>
      </S.Wrapper>
      <S.WrapperTable>
        <Center>
          <S.ContentTable>
            <S.WrapperGrantAccess>
              <S.Header>
                <S.TitleMain>Grant Access</S.TitleMain>
                <Button type="button" btnBackgroundViolet onClick={handleAdd}>
                  Add
                </Button>
              </S.Header>
              {listSubCoach && listSubCoach.length > 0 && (
                <S.TableWrap>
                  <S.TableHeaderWrap>
                    <S.HeaderItem>Teammate</S.HeaderItem>
                    <S.HeaderItem textCenter>Permission</S.HeaderItem>
                    <S.HeaderItem textCenter>Remove</S.HeaderItem>
                  </S.TableHeaderWrap>
                  <S.TableContentWrap>
                    {listSubCoach &&
                      listSubCoach.map(item => {
                        return (
                          <S.TableItemWrap key={item._id}>
                            <S.TableItemContent>
                              <S.UserInfo>
                                <S.Avatar>
                                  <Avatar
                                    name={getUserShortName(item.trainer)}
                                    size="36"
                                    src={item.trainer.avatar}
                                    color={item.trainer.color}
                                  />
                                </S.Avatar>
                                <S.ContentUserInfo>
                                  <S.Title>{item.trainer.full_name}</S.Title>
                                  <S.Desc>{item.trainer.email}</S.Desc>
                                </S.ContentUserInfo>
                              </S.UserInfo>
                            </S.TableItemContent>
                            <S.TableItemContent textCenter>
                              <S.Text>Full permission</S.Text>
                            </S.TableItemContent>
                            <S.TableItemContent textCenter>
                              <IconTrash onClick={() => handleRemove(item.trainer._id, item.trainer.full_name)} />
                            </S.TableItemContent>
                          </S.TableItemWrap>
                        );
                      })}
                  </S.TableContentWrap>
                </S.TableWrap>
              )}
            </S.WrapperGrantAccess>
          </S.ContentTable>
        </Center>
      </S.WrapperTable>
    </S.WrapperMain>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      client: {
        subCoach: { data },
        selected,
      },
    },
  } = state;

  return {
    clientId: selected,
    listSubCoach: data,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  clientRemoveSubCoach: bindActionCreators(clientRemoveSubCoach, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
