import styled, { css } from 'styled-components';
import ConnectedForumBG from 'assets/icons/add_leaderboard_connected_forum_bg.svg';
import { Button } from 'shared/FormControl';
import { ReactComponent as LeaderboardCupImg } from 'assets/icons/forum-timeline-leaderboard-cup.svg';

export const AddNewLeaderboardWrapper = styled.div`
  width: 303px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  background: #ffffff;
  overflow: hidden;

  .add-new-leaderboard-content {
    padding: 25px 20px 25px 103px;
    position: relative;
  }

  ${props =>
    props.connected &&
    css`
      padding: 45px 25px 45px 165px;
      width: 420px;
      text-align: left;
      background: #fff url(${ConnectedForumBG}) no-repeat left top;
      background-size: auto 100%;

      .subtitle {
        margin-bottom: 20px;
      }

      ${Button} {
        color: #fff;
        background-color: #5158cf;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    `}
`;

export const AddNewLeaderboardHeader = styled.div`
  display: flex;
  .header-text {
    heigth: 52px;
    background: linear-gradient(90deg, #ffe9e0 0%, rgba(254, 243, 214, 0.6) 76.36%, rgba(254, 255, 214, 0) 100%);
    font-weight: 600;
    color: #202353;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
  }
`;

export const AutoflowConnectedHeader = styled.div`
  display: flex;
  .header-text {
    flex: 1;
    background: linear-gradient(90deg, #e0ffe3 0%, rgba(254, 255, 214, 0) 100%);
    font-weight: 600;
    color: #202353;
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
    svg {
      margin-right: 7px;
      transform: translateY(3px);
      path {
        stroke: #369e4d;
      }
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #202353;
  margin-bottom: 5px;
`;

export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-bottom: 20px;
`;

export const LeaderboardCup = styled(LeaderboardCupImg)`
    position: absolute
    left: 0px;
    top: 0px;
`;
// confirmed skip for this release
export const CloseButton = styled(Button)`
  display: none;
  min-width: 13px !important;
  margin-right: 17.5px;
  &:hover {
    svg > path {
      fill: #ea314a !important;
    }
  }
`;

export const ConnectToAutoflowBtn = styled(Button)`
  color: #5158cf;
  :hover {
    text-decoration: underline;
  }
`;

export const LeaderboardContainer = styled.div`
  .leaderboard-text {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #202353;
    display: inline-block;
  }
  .leaderboardDetail {
    margin-top: 15px;
    width: 303px !important;
    border-radius: 5px !important;
    border-bottom-left-radius: 5px;
  }
  .share-leaderboard {
    display: none;
    float: right;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #5158cf;
    cursor: pointer;
    margin-top: 3px;
    :hover {
      text-decoration: underline;
    }
  }
  :hover {
    .share-leaderboard {
      display: block;
    }
  }
`;

export const Top = styled.div`
  padding: 20px 22px;
  background: radial-gradient(85.05% 85.05% at 36.25% 14.95%, #fcfcfc 0%, #e7edfc 100%), #f7f7f7;
`;

export const BodyArea = styled.div`
  background: white;
  padding: 20px 22px;
`;

export const UpgradePath = styled.div`
  width: 303px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  overflow: hidden;
`;

export const UpgradePathImage = styled.img`
  width: 100%;
`;
