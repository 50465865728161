import React, { useEffect, useState } from 'react';
import { Radio } from 'semantic-ui-react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import ReactTooltip from 'react-tooltip';

import * as S from '../style';
import { useMyContext } from '../context/ProgramTemplatesContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close_program_filter.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_gray_out.svg';

import { Button, Checkbox } from 'shared/FormControl';

import {
  DEFAULT_FILTERS_POPUP,
  DEFAULT_LEVELS,
  // DEFAULT_DURATIONS,
  DEFAULT_PROGRAM_LENGTH,
  DEFAULT_MODALITIES_VALUES,
  FILTER_TYPE_KEYS,
  FILTER_TYPES,
  DEFAULT_WORKOUT_DAYS_PER_WEEK,
} from 'components/ProgramTemplatesFullscreenModal/constants.js';

const FilterModalContent = () => {
  const {
    toggleFilterModal,
    changeQueryParams,
    currentFilters = DEFAULT_FILTERS_POPUP,
    hideResetButton,
    resetQueryPopupFilter,
  } = useMyContext();

  const [filters, setFilters] = useState(DEFAULT_FILTERS_POPUP);
  const [isDisable, setIsDisable] = useState(isEqual(filters, currentFilters));

  useEffect(() => {
    if (!isEqual(currentFilters, filters)) {
      setFilters(currentFilters);
    }
  }, [currentFilters]);

  const handleClearAll = () => {
    if (isDisable) return;
    setFilters(DEFAULT_FILTERS_POPUP);
    setIsDisable(true);
    if (!hideResetButton) {
      resetQueryPopupFilter();
      changeQueryParams();
    }
  };

  const handleUpdateFilterOptions = () => {
    if (isDisable) return;
    const { durations, levels, modalities, number_of_weeks, workout_days_per_weeks } = filters;
    changeQueryParams({
      durations,
      levels,
      modalities,
      number_of_weeks,
      workout_days_per_weeks,
    });
    toggleFilterModal();
  };

  const handleSelectCheckbox = (item = {}, filter_key) => (event = {}) => {
    if (!filter_key) return;
    let checked = (event.target || {}).checked || false;
    if (filter_key === FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK) {
      checked = !filters[filter_key].includes(item.key);
    }

    if (checked) {
      setFilters(prevFilters => ({
        ...prevFilters,
        [filter_key]: [...prevFilters[filter_key], item.key],
      }));
    } else {
      setFilters(prevFilters => {
        const newList = prevFilters[filter_key].slice();
        newList.splice(
          newList.findIndex(i => i === item.key),
          1,
        );
        return { ...prevFilters, [filter_key]: newList };
      });
    }

    if (isDisable) setIsDisable(false);
  };

  const handleSelectRadio = (item = {}, filter_key) => () => {
    if (!filter_key) return;
    setFilters(prevFilters => ({ ...prevFilters, [filter_key]: item.key }));

    if (isDisable) setIsDisable(false);
  };

  const renderFilterTypeContent = key => {
    switch (key) {
      case FILTER_TYPE_KEYS.MODALITIES:
        return DEFAULT_MODALITIES_VALUES.map(it => {
          const { key: keyModality = '', label = '', desc = '' } = it || {};
          return (
            <S.ModalityWrapper>
              <Checkbox
                key={keyModality}
                title={label}
                className="system-modalities"
                checked={filters.modalities.includes(keyModality)}
                onChange={handleSelectCheckbox(it, key)}
                size={24}
              />
              {keyModality !== 'strength_hypertrophy' && (
                <>
                  <InfoIcon data-tip data-for={`modality-tooltip-${keyModality}`} />
                  <ReactTooltip id={`modality-tooltip-${keyModality}`} effect="solid" place={'top'} delayShow={300}>
                    {desc}
                  </ReactTooltip>
                </>
              )}
            </S.ModalityWrapper>
          );
        });
      case FILTER_TYPE_KEYS.LEVELS:
        return DEFAULT_LEVELS.map(it => (
          <Radio
            key={it.key}
            label={it.label}
            className="system-experience-level"
            checked={filters.levels === it.key}
            onClick={handleSelectRadio(it, key)}
          />
        ));
      case FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK:
        return DEFAULT_WORKOUT_DAYS_PER_WEEK.map(it => (
          <button
            key={it.key}
            className={classNames('system-workout-days-per-weeks', {
              selected: filters[FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK].includes(it.key),
            })}
            onClick={handleSelectCheckbox(it, key)}
          >
            {it.label}
          </button>
        ));
      // case FILTER_TYPE_KEYS.DURATIONS:
      //   return DEFAULT_DURATIONS.map(it => (
      //     <Checkbox
      //       key={it.key}
      //       title={it.label}
      //       className="system-durations"
      //       checked={filters.durations.includes(it.key)}
      //       onChange={handleSelectCheckbox(it, key)}
      //       size={24}
      //     />
      //   ));
      case FILTER_TYPE_KEYS.PROGRAM_LENGTH:
        return DEFAULT_PROGRAM_LENGTH.map(it => (
          <Checkbox
            key={it.key}
            title={it.label}
            className="system-program-length"
            checked={filters.number_of_weeks.includes(it.key)}
            onChange={handleSelectCheckbox(it, key)}
            size={24}
          />
        ));
      default:
        return null;
    }
  };

  const renderFilterType = (it, idx) => {
    const { key, label } = it;
    const isLast = idx === FILTER_TYPES.length - 1;

    return (
      <S.PopupGroup key={`item-filter-${key}`}>
        <S.PopupGroupTitle>{label}</S.PopupGroupTitle>
        <S.PopupGroupContent className={classNames(key, { expanded: true })}>
          {renderFilterTypeContent(key)}
        </S.PopupGroupContent>
        <S.CustomBorder noBorderBottom={isLast} />
      </S.PopupGroup>
    );
  };

  return (
    <S.FullscreenModalContent className="filter">
      <S.FilterModalHeader>
        <S.FilterHeaderTitle>Filters</S.FilterHeaderTitle>
        <S.CloseFilterButton onClick={toggleFilterModal}>
          <CloseIcon />
        </S.CloseFilterButton>
      </S.FilterModalHeader>
      <S.FilterModalBody>{FILTER_TYPES.map(renderFilterType)}</S.FilterModalBody>
      <S.FilterModalFooter>
        <S.ClearLabel disabled={isDisable} onClick={handleClearAll}>
          Clear All
        </S.ClearLabel>
        <Button className={classNames({ disable: isDisable })} onClick={handleUpdateFilterOptions}>
          Update Filters
        </Button>
      </S.FilterModalFooter>
    </S.FullscreenModalContent>
  );
};

export default FilterModalContent;
