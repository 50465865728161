import styled from 'styled-components';
import { Wrapper, Header, Content } from 'shared/Styles/ModalLayout';
import { Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

export const PaymentMethodContainer = styled.div`
  flex: 1 1;

  .payment-method__heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    margin-bottom: 20px;
  }
`;

export const PaymentInfoContainer = styled.div`
  min-width: 340px;
  margin-left: 30px;
  margin-top: 8px;
`;

export const ErrorContainer = styled.div`
  background: #fef5f6;
  padding: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  text-align: center;

  color: #ea314a;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 25px;
  min-height: 300px;

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    position: relative;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #222222;
    text-align: left;

    tr {
      td {
        padding: 16px 15px;
        box-shadow: inset 0px -1px 0px #f0f0f0;

        .status {
          font-weight: 600;
          font-size: 11px;
          line-height: 15px;
          color: #ea314a;

          &.paid {
            color: #50c94d;
          }
        }

        &.amount {
          font-weight: 600;
        }
      }
    }

    thead {
      th {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #a1a1a5;
        padding: 17px 15px;
        box-shadow: 0px 2px 0px #f1f3f4;
        position: sticky;
        top: 0;
        z-index: 999;
        cursor: pointer;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DownloadIconBilling = styled.div`
  width: 25px;
  height: 25px;
  background: url(${CDN_URL}/images/download_billing_icon.svg) center no-repeat;
  cursor: pointer;

  :hover {
    background: url(${CDN_URL}/images/download_billing_icon_active.svg) center no-repeat;
  }
`;

export const ModalWrapper = styled(Modal)`
  width: 925px;
  border-radius: 8px;
  max-width: 100%;

  .content {
    padding: 0 !important;
    border-radius: 8px !important;
  }

  ${Header} {
    padding-bottom: 15px;
  }

  ${Content} {
    padding: 30px;
  }
`;
