import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Wrapper } from './style';
import UpgradePath from 'shared/UpgradePath';
import { requestLogInfo } from 'utils/commonFunction';

const NAVS = [
  { label: 'Resources', route: '' },
  { label: 'Clients', route: 'clients' },
];

const StudioCollectionDetailHeader = props => {
  const { originCollection, location, loading } = props;

  if (!originCollection) {
    return null;
  }

  const handleClick = isClient => () => {
    if (isClient) {
      requestLogInfo('Client tab - resource collection', { collection_id: originCollection._id });
    }
  };

  return (
    <Wrapper>
      <div className="studio-collection-detail__name">
        <span>
          <UpgradePath pathName="studio_resource_collection" fallback="On-demand">
            {originCollection.name}
          </UpgradePath>
        </span>
      </div>
      <div className="studio-collection-detail__nav-menu">
        {_.map(NAVS, (nav, index) => (
          <NavLink
            onClick={handleClick(nav.route === 'clients')}
            key={nav.label}
            className={`studio-collection-detail__nav-menu__item ${loading && 'disabled'}`}
            exact={nav.route === ''}
            to={`/home/studio-collection/${originCollection._id}${nav.route ? '/' + nav.route : ''}`}
            isActive={() => {
              return (
                location.pathname ===
                `/home/studio-collection/${originCollection._id}${nav.route ? '/' + nav.route : ''}`
              );
            }}
          >
            {nav.label}
          </NavLink>
        ))}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, router } = state;
  const { originCollection, loading } = rootReducer.studioCollection;

  return {
    originCollection,
    location: router.location,
    loading,
  };
};

export default connect(mapStateToProps, null)(StudioCollectionDetailHeader);
