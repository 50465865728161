// Libs
import React, { useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';

// Style
import * as S from './style';

const FilterCategory = props => {
  const { handleChoose = () => {}, scrollWidthFilter = 250, categoryIngredient, selectedCategories } = props;
  const listCategoryRef = useRef(null);
  const [statusScroll, setStatusScroll] = useState('left');

  const categoryList = useMemo(() => {
    return [{ name: 'All Categories' }, ...categoryIngredient];
  }, [categoryIngredient]);

  useEffect(() => {
    const listCategory = listCategoryRef.current;

    const handleScroll = () => {
      const tolerancePercentage = 5;
      if (listCategory) {
        if (listCategory.scrollLeft === 0) {
          setStatusScroll('left');
        } else if (
          listCategory.scrollLeft + listCategory.clientWidth + tolerancePercentage >=
          listCategory.scrollWidth
        ) {
          setStatusScroll('right');
        } else {
          setStatusScroll('center');
        }
      }
    };

    if (listCategory) {
      listCategory.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listCategory) {
        listCategory.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const onSelected = data => {
    handleChoose(get(data, '_id', null));
  };

  const handleArrowButton = value => {
    if (listCategoryRef && listCategoryRef.current)
      listCategoryRef.current.scrollTo({
        left: listCategoryRef.current.scrollLeft + value,
        behavior: 'smooth',
      });
  };

  const handleScroll = e => {
    const parent = listCategoryRef.current;
    const child = e.currentTarget;
    const rect = child.getBoundingClientRect();
    const x = rect.left - parent.getBoundingClientRect().left;
    const tolerancePercentage = 5;

    const scrollThreshold = 60;

    const scrollToLeft = () => {
      listCategoryRef.current.scrollTo({
        left: parent.scrollLeft - tolerancePercentage - rect.width,
        behavior: 'smooth',
      });
    };

    const scrollToRight = () => {
      listCategoryRef.current.scrollTo({
        left: parent.scrollLeft + tolerancePercentage + rect.width,
        behavior: 'smooth',
      });
    };

    if (x < scrollThreshold) {
      scrollToLeft();
    }

    if (x > parent.offsetWidth - rect.width - scrollThreshold) {
      scrollToRight();
    }
  };

  return (
    <S.FilterCategoryWrapper>
      <S.List ref={listCategoryRef}>
        {categoryList.map(category => {
          const { _id, name } = category;
          const active = selectedCategories.includes(_id) || (isEmpty(selectedCategories) && !_id);

          return (
            <S.Item
              onClick={e => {
                handleScroll(e);

                onSelected(category);
              }}
              active={active}
              key={_id}
            >
              {name}
            </S.Item>
          );
        })}
      </S.List>
      {(statusScroll === 'right' || statusScroll === 'center') && (
        <>
          <S.Overlay className="left" />
          <S.Button className="left" onClick={() => handleArrowButton(-scrollWidthFilter)}>
            <ArrowIcon />
          </S.Button>
        </>
      )}
      {(statusScroll === 'left' || statusScroll === 'center') && (
        <>
          <S.Overlay className="right" />
          <S.Button className="right" onClick={() => handleArrowButton(scrollWidthFilter)}>
            <ArrowIcon />
          </S.Button>
        </>
      )}
    </S.FilterCategoryWrapper>
  );
};

export default FilterCategory;
