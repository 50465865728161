import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { toggleModal } from 'actions/modal';
import FormItemInput from 'shared/FormItemInput';
import { Button } from 'shared/FormControl';
import { addNewPackage } from 'redux/package-detail/actions';
import { copyPackage } from 'redux/package-list/actions';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

class AddPackagePopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastKeyCode: null,
    };
  }

  componentDidMount() {
    if (this.props.packageId) {
      this.setState({
        name: this.props.packageName,
      });
    }
  }

  handleChangeName = e => {
    this.setState({ name: e.target.value });
  };

  handleClose = () => {
    this.props.toggleModal(false);
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const { name } = this.state;
    const { copyPackage, addNewPackage, packageId, isMP } = this.props;

    if (!name.trim()) {
      return;
    }

    let params = {
      name,
    };
    if (packageId) {
      params = {
        ...params,
        id: packageId,
      };
      copyPackage(params, isMP);
      this.handleClose();
    } else {
      addNewPackage(params, isMP);
    }
  };

  handleOnKeyDown = event => {
    const { lastKeyCode } = this.state;
    const inputElement = event.target;
    const newKeyCode = event.keyCode;

    if (
      inputElement &&
      inputElement.value.length === inputElement.selectionEnd &&
      newKeyCode === 32 &&
      (lastKeyCode === 32 || event.repeat)
    ) {
      event.preventDefault();
    }
    this.setState({ lastKeyCode: newKeyCode });
  };

  render() {
    const { loading } = this.state;
    const isDisabledSubmit = loading;
    const { packageId, isMP = false, toMP = false } = this.props;
    return (
      <S.CreateNewPackageModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="close" />
          </CloseButton>
        }
      >
        <Modal.Header>
          {packageId ? (isMP && toMP ? 'Duplicate Package to Marketplace' : 'Duplicate Package') : 'Create New Package'}
          <div className="addPackage__description">
            {packageId
              ? isMP && toMP
                ? 'Create a copy of your existing package to Marketplace. The new package will be added as a draft, even if your existing package is published.'
                : 'Create a copy of your existing package. The new package will be added as a draft, even if your existing package is published.'
              : 'Set up a new package to sell or promote your services. This can include memberships, sessions, training programs, sequences, and more.'}
          </div>
        </Modal.Header>
        <Modal.Content>
          <form onSubmit={this.onSubmit} className={`addPackage__form ${packageId && 'copyPackage_form'}`}>
            <FormItemInput
              label="Package Name"
              placeholder="Name your package"
              required
              isHideErrorMessage
              maxLength={90}
              inputProps={{ autoFocus: true, maxLength: 90 }}
              isSubmitted={this.state.isSubmitted}
              value={this.state.name}
              onChange={this.handleChangeName}
              onKeyDown={this.handleOnKeyDown}
            />
          </form>
          <div className="addPackage__footer">
            <Button disabled={isDisabledSubmit} onClick={this.onSubmit} purple>
              {packageId ? 'Duplicate' : 'Create New'}
            </Button>
          </div>
        </Modal.Content>
      </S.CreateNewPackageModal>
    );
  }
}

const actionCreators = { toggleModal, push, addNewPackage, copyPackage };

export default connect(null, actionCreators)(AddPackagePopup);
