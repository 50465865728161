import styled from 'styled-components';
import TextEditableEl from 'shared/TextEditable';

export const TextEditable = styled(TextEditableEl)`
  .text-editable__container {
    padding: 2px 4px;
    width: fit-content;
    min-width: 500px;
  }
`;
