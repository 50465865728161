import React from "react";
import { Route, Switch } from "react-router-dom";
import ProgramListCalendar from "components/ProgramListCalendar";

export default class ProgramListCalendarContainer extends React.Component {
  state = {
    openingPopup: ""
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/home/program/:programId/calendar"
          render={props => <ProgramListCalendar {...props} />}
        />
        <Route
          exact
          path="/home/program/:programId/calendar/:workoutId?/detail"
          render={props => <ProgramListCalendar {...props} />}
        />
        <Route
          exact
          path="/home/program/:programId/calendar/:workoutId?/clone"
          render={props => <ProgramListCalendar {...props} />}
        />
      </Switch>
    );
  }
}
