import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #7B7E91;
`;

export const Wrapper = styled.div`
  .content {
    display: flex;
    align-items: center;
  }
  .label {
    ${baseText}
    width: 96px;
  }
  .list {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .item {
    ${baseText}
    font-weight: 400;
    color: #202353;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 36px;
    border: 1px solid #e1e1ea;
    border-radius: 20px;
    padding: 0 14px;
    cursor: pointer;
    &.active {
      background: #f0f1ff;
      border: 1px solid #5158cf;
    }
    &:hover {
      background: #f0f1ff;
    }
  }
  .error {
    ${baseText}
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ea314a;
    margin-top: 3px;
    margin-left: 100px;
  }
`;
