import React, { useEffect, useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import get from 'lodash/get';

import { getFormProperties, handleTriggerScroll } from 'components/OnboardingFlowDetail/constants/helper';

import * as S from './style';
import TagFormInfo from 'components/OnboardingFlowDetail/components/TagFormInfo';
import TriggerPopup from './TriggerPopup';

const OnboardingFormSelect = props => {
  const { options = [], selectedForm, onChange, selectedFormId, isUnavailable } = props;

  const [showPopup, setShowPopup] = useState(false);

  const isEmptyOptions = options.length === 0;

  useEffect(() => {
    handleTriggerScroll('.trigger-scroll-wrapper', handleClosePopup);
  }, []);

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSelectForm = it => () => {
    const isSameCustomForm = !!it.custom_form && get(it, 'custom_form._id') === get(selectedForm, 'custom_form._id');
    const isSameNormalForm = !!it.form && get(it, 'form._id') === get(selectedForm, 'form._id');

    // no action when re-choice same form
    if (!!selectedForm && (isSameCustomForm || isSameNormalForm)) {
      setShowPopup(false);
      return;
    }

    typeof onChange === 'function' && onChange(it);
    setShowPopup(false);
  };

  const renderFormItem = it => {
    const { formTitle, _id } = getFormProperties(it);

    return (
      <S.FormItemWrapper
        key={_id}
        className={classNames('form-item-container', { selected: _id === selectedFormId })}
        onClick={handleSelectForm(it)}
      >
        <div className="form-title">{formTitle}</div>
        <TagFormInfo form={it} />
      </S.FormItemWrapper>
    );
  };

  return (
    <S.OnboardingFormsPopup
      trigger={<TriggerPopup selectedForm={selectedForm} showPopup={showPopup} isUnavailable={isUnavailable} />}
      basic={true}
      flowing={true}
      hoverable={true}
      on="click"
      position="bottom left"
      open={showPopup}
      onOpen={handleOpenPopup}
      onClose={() => {}}
    >
      <RootCloseWrapper event="click" onRootClose={handleClosePopup} disabled={!showPopup}>
        <div className="container-popup">
          {!isEmptyOptions && options.map(renderFormItem)}
          {isEmptyOptions && (
            <div className="empty-container">
              <div className="empty-text">No forms available.</div>
            </div>
          )}
        </div>
      </RootCloseWrapper>
    </S.OnboardingFormsPopup>
  );
};

export default OnboardingFormSelect;
