import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  &.ui.modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: ${({ width }) => width || '467px'};
    height: ${({ height }) => height || 'auto'};

    border-radius: 10px;
  }
  &.ui.modal > .header,
  &.ui.modal > .content,
  &.ui.modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24.5px;
  border-radius: 8px;
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 30px 30px 0;

    .header-icon {
      flex-shrink: 0;
    }

    .header-title {
      font-size: 21px;
      font-weight: 600;
      line-height: 32px;
      color: #202353;
    }
  }

  .content {
    padding: 0 30px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #202353;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 0 30px 30px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      border-radius: 5px;
      padding: 6px 25px;

      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      cursor: pointer;

      &.cancel {
        border: 1px solid #e1e1ea;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #777;
        background-color: #fff;

        &:hover {
          background-color: #f5f7f9;
        }
      }

      &.create {
        color: #fff;
        background-color: #5158cf;

        &:hover {
          background-color: rgba(81, 88, 207, 0.9);
        }
      }
    }
  }
`;
