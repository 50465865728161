import styled from 'styled-components';

import { FallbackWrapper } from '../../style';

export const Section = styled.div`
  padding: 0;
  font-size: 14px;
  .upgrade-path-button {
    display: block;
    padding: 11px 13px;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 100%;
    border-radius: 3px;
  }
`;

export const Header = styled.div``;

export const Title = styled.div`
  font-size: 1.858rem;
  line-height: 150%;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;
`;

export const Description = styled.div`
  display: block;
  width: 444px;
  margin: auto;
  font-size: 1.15rem;
  line-height: 150%;
  text-align: center;
`;

export const Body = styled.div`
  display: block;
`;

export const Dots = styled.div`
  width: 10px;
  height: 10px;
  padding: 0;
  border: 1px solid #e8ebed;
  border-radius: 50%;

  &:before {
    content: none;
  }
`;

export const Slider = styled.div`
  display: block;

  .slick-dots {
    top: 47px;

    .slick-active {
      ${Dots} {
        background: #5c5bbd;
        border: none;
      }
    }

    li {
      width: initial;
      height: initial;
      margin: 0 4px;
    }
  }

  .slick-list {
    top: 72px;
    z-index: 1;
  }
`;

export const Item = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: 0;
`;

export const ItemImage = styled.img`
  width: 100%;
  max-width: 900px;
`;
export const ItemVideo = styled.div`
  min-width: 100%;
  max-width: 900px;
  overflow: hidden;
`;

export const ItemContent = styled.div`
  padding-left: 42px;
  transform: translateX(-25%);

  .upgrade-path-button {
    background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
    border-radius: 3px;
    color: #ffffff;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 100%;
    display: flex;
    border: none;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    :hover {
      background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
    }

    width: 226px;
    padding: 13.5px 13px;

    span {
      display: block;
      margin: auto;
    }
  }
`;

export const ItemTitle = styled.div`
  font-size: 1.15rem;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 12px;
`;

export const ItemText = styled.div`
  color: #080a24;
  max-width: 385px;
  font-size: 1.15rem;
  line-height: 150%;
  font-family: 'Public Sans';
  letter-spacing: -0.2px;
  margin-bottom: 40px;
`;

export const ItemLink = styled.a`
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ItemButton = styled.button`
  display: block;
  padding: 11px 13px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 100%;
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ItemImageArea = styled.div`
  display: inherit;
`;

export const ArrowSliderLeft = styled.div`
  left: 58px !important;
  z-index: 2;

  &:before {
    content: none !important;
  }
  &:hover,
  &:active,
  &:focus {
    svg {
      g {
        opacity: 1;
      }
    }
  }
`;

export const ArrowSliderRight = styled.div`
  right: 70px !important;
  z-index: 2;

  svg {
    transform: rotateZ(180deg);
  }

  &:before {
    content: none !important;
  }
  &:hover,
  &:active,
  &:focus {
    svg {
      g {
        opacity: 1;
      }
    }
  }
`;

export const Number = styled.div`
  font-size: 50px;
  font-weight: 700;
  line-height: 100%;
  opacity: 0.1;
  text-align: left;
  margin-bottom: 5px;
`;

export const FallbackWrapperS = styled(FallbackWrapper)`
  padding-top: 20px;
`;

export const ComingSoon = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.18px;
  padding: 5px 5px 5px 24px;
  margin-bottom: 20px;
  background: linear-gradient(90.09deg, #f7f7f7 70.51%, rgba(247, 247, 247, 0) 95.24%);
  border-radius: 4px;
  align-items: center;
`;

export const ComingText = styled.span`
  padding-left: 3px;
  font-family: 'Open Sans';
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.18px;
`;

export const PackageFallBackGroup = styled.a`
  color: #5158cf;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  padding-left: 24px;
  cursor: pointer;
  &:hover {
    color: #5158cf;
  }
  svg {
    margin-bottom: -2px;
    margin-right: 8px;
    path {
      fill: #5158cf;
    }
  }
`;
