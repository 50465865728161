import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientProfileModalComponent from './component';
import {
  removeClient,
  updateProfileThenUploadAvatar,
  resendInvitationClientAndUpdateProfileThenUploadAvatar,
  archiveOneClient,
} from 'redux/client/client.actionCreators';

const mapStateToProps = state => {
  const {
    user,
    isModalOpen,
    isSecondModalOpen,
    cloudfrontList,
    rootReducer: {
      client: {
        workingClientDetail: { sub_trainers },
        isUploading,
      },
    },
  } = state;
  let isTeamAdmin = !user.teams || user.teams.length === 0 || user.teams[0].role !== 1;
  return { user, isTeamAdmin, isModalOpen, isSecondModalOpen, subTrainers: sub_trainers, cloudfrontList, isUploading };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    removeClient: bindActionCreators(removeClient, dispatch),
    saveClientInfo: bindActionCreators(updateProfileThenUploadAvatar, dispatch),
    saveClientInfoAndSendInvitetoConnect: bindActionCreators(
      resendInvitationClientAndUpdateProfileThenUploadAvatar,
      dispatch,
    ),
    archiveOneClient: bindActionCreators(archiveOneClient, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileModalComponent);
