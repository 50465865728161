import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
// import repeat from '/images/task_repeat_active.svg';

export const TaskModalContainer = styled.div`
  display: flex;
`;

export const TaskForm = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  width: 570px;
  /* max-height: 773px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 580px;

  .header {
    font-size: 1.42857143rem;
    line-height: 1.28571429em;
    font-weight: 700;
    min-height: 24px;
  }

  .content {
    padding: 0 25px;
    margin-bottom: 20px;
    display: block;
    width: 100%;
    font-size: 1em;
    line-height: 1.4;
    flex: 1 1;
    overflow: visible;
    max-height: calc(100vh - 214px);

    &.scroll-auto {
      overflow: auto;
    }
  }

  .modal__actions {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 10px 30px;
    text-align: right;
    background: #ffffff;
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);

    .actions__saveClose {
      margin-right: 5px;
      min-width: 90px;
      padding: 7px 10px;
      border: 1px solid #d6dae4;
      line-height: 150%;
      height: 34px;

      :disabled {
        background: #fff;
        opacity: 0.3;
      }
    }

    .actions__save {
      min-width: 140px;
      max-width: 140px;
      padding: 7px 30px;
      :hover:not(&.disabled) {
        background: rgba(81, 88, 207, 0.9);
      }

      &.disabled {
        color: #fff;
        background: #bfbfbf;
        opacity: 0.5;
      }
    }
  }

  .custom-date-input {
    padding: 9px 9px;
    width: 190px;
    background: #fafbfc;
  }

  .modal__header {
    flex: 0 0 auto;
    padding: 20px 25px;

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #323c47;

      img,
      svg {
        margin-right: 10px;
      }
    }

    .button--back {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      cursor: pointer;
      opacity: 0.5;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;

        path {
          stroke: #202353;
        }
      }
    }
  }

  &.task--general {
    .content {
      padding: 0 30px;
    }

    .modal__header {
      padding: 15px 30px 20px 30px;

      .title {
        margin-top: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
      }

      .button--back {
        margin-bottom: 0;
      }
    }

    .input--task-name {
      font-weight: normal;
    }
  }

  .set-date-label {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    padding: 0;
    margin-bottom: 4px;
  }
`;

export const TaskModal = styled(Modal)`
  &.ui.tiny.modal {
    background: transparent;
    box-shadow: none;
    width: unset;
  }
`;

export const ReminderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 54px;

  ${props =>
    props.isAddTaskFromLibrary &&
    css`
      margin-left: 0;
    `}

  .toggle-input {
    margin-right: 15px;
    font-size: 12px;

    input {
      ~ label {
        ::before {
          background-color: #5158cf;
        }
      }
    }

    :not(.checked) {
      color: #728096;

      input {
        ~ label {
          ::before {
            background-color: #e4e5e7;
          }
        }
      }
    }

    .text-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        margin-left: 6px;

        :not(:hover) {
          path {
            fill: #a6acc6;
          }
        }

        :hover {
          path {
            fill: #5158cf;
          }
        }
      }
    }
  }

  .__react_component_tooltip.app-tooltip {
    padding: 15px;
    font-size: 13px;
    max-width: 230px;
    text-align: left;
  }
`;

export const SetTimeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${props =>
    props.isAddTaskFromLibrary &&
    css`
      height: 36px;
    `}

  .ui {
    &.settime {
      input {
        background: #fafbfc;
      }
    }
  }

  .rdt {
    .rdtPicker {
      bottom: calc(100% + 2px);
    }
  }

  .autoflow-date-select {
    z-index: 3;

    .evfSelectBox__menu-list {
      padding: 7px 0;
    }

    .evfSelectBox__group {
      padding: 0;
      padding-top: 2px;

      &:first-child {
        padding-top: 3px;
      }

      .evfSelectBox__group-heading {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #000;
        opacity: 0.4;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 8px;
        margin: 0;
        text-transform: none;
      }
    }
  }
`;

export const ExtendActionsStyle = styled.div`
  position: absolute;
  right: 35px;
  top: 24px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ownershipWrapper {
    .action-menu__toggle-button {
      margin-right: 0;
      width: auto;

      .teammate-icon {
        width: 28px;
        height: 28px;
        border-radius: 5px;
      }
    }

    .ownership {
      right: -12px;
    }
  }

  .evf-dropdown {
    margin-left: 10px;

    .evf-dropdown__option {
      padding: 0;
      width: 100%;
    }

    .evf-dropdown__menu {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .evf-dropdown__trigger {
      width: 28px;
      height: 28px;
      border-radius: 5px;

      .dots {
        background-color: #838d9f;
        width: 4px;
        height: 4px;

        :before {
          width: 4px;
          height: 4px;
          left: -6px;
        }

        :after {
          width: 4px;
          height: 4px;
          left: 6px;
        }
      }

      :hover {
        background-color: #ebedf3;
      }
    }

    &.open {
      .evf-dropdown__trigger {
        background-color: #ebedf3;

        .dots {
          background-color: #838d9f;
        }
      }
    }
  }
`;

export const OptionIcon = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  padding: 7px 15px 7px 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;

  ::before {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(${CDN_URL}/images/${props => props.icon}.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  :hover {
    color: #5158cf;
    background-color: #eaebff;
    cursor: pointer;

    ${props =>
      props.hoverIcon &&
      css`
        ::before {
          background-image: url(${CDN_URL}/images/${props => props.hoverIcon}.svg);
        }
      `}
  }

  ${props =>
    props.darkMode &&
    css`
      color: #fff;

      :hover {
        color: #fff;
        background-color: #696969;
      }
    `}
`;

export const FormContainer = styled.div`
  .button--share {
    right: 30px !important;
  }
`;

export const RepeatIconWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #728096;
  cursor: pointer;
  justify-content: space-between;
  &.active {
    color: #5158cf;
  }
  .repeat-title {
    padding-left: 6px;
  }
  &:hover {
    .repeat-title {
      color: #5158cf;
    }
    .icon-repeat {
      background-image: url(${CDN_URL}/images/task_repeat_active.svg);
    }
  }
`;

export const RepeatIconAction = styled.div`
  display: flex;
  align-items: center;
`;

export const RepeatError = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #ea314a;
`;

export const RepeatIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${CDN_URL}/images/task_repeat.svg);
  &.icon-active {
    background-image: url(${CDN_URL}/images/task_repeat_active.svg);
  }
`;

export const RepeatWrapper = styled.div`
  background: #f3f5f8;
  border-radius: 6px;
  padding: 20px;
  margin-top: 10px;

  .evfSelectBoxContainer {
    .evfSelectBox__control {
      .evfSelectBox__single-value {
        color: #000;
        font-weight: normal;
      }
    }
  }
`;

export const IntervalRepeatTaskWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Frequency = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RepeatTitle = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #6a778a;
  font-weight: 600;
  margin-right: 10px;
  &.nested {
    margin-left: 10px;
  }

  ${props =>
    props.habit &&
    css`
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      margin-right: 8px;
      text-transform: uppercase;
    `}
`;

export const RemoveRepeat = styled.span`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #c4c4c4;
  position: absolute;
  top: -3px;
  right: 0;
  cursor: pointer;
  &:hover {
    color: #ea314a;
  }
`;

export const NoteContainer = styled.div`
  margin-bottom: 20px;

  .text-editable__wrapper {
    margin: 0;
  }

  .text-editable__input {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgb(0, 0, 0);
  }

  .button--addNote {
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
    color: #818da1;
    margin-bottom: 5px;
    font-weight: 600;
    min-width: unset;

    :hover {
      color: #5158cf;
      background: transparent;
    }
  }

  ${props =>
    props.isAddingNote &&
    css`
      .button--addNote {
        margin-bottom: 5px;
      }
    `}
`;

export const Breaker = styled.div`
  background: #e2e3e8;
  height: 1px;
  width: 100%;
  opacity: 0.7;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
