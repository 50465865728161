import { toast } from 'react-toastify';
import { upperFirst, keys } from 'lodash';
import { TASK_TYPES } from 'constants/commonData';

export const MODE = {
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};

export const MESSAGE = {
  DELETE: 'Task has been deleted',
  MODAL_TITLE: 'Remove Task',
  MODAL_CONTENT: 'Are you sure that you want to delete this task?',
  DELETE_SINGLE: 'task has been deleted',
  DELETE_MULTIPLE: 'tasks have been deleted',
};

export const TOAST_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const MOST_RECENT_KEY = 'last_activity_at';

export const columns = [
  {
    key: 'last_activity_at',
    name: 'Most Recent',
    alignCenter: true,
  },
];

const TYPE_ORDER_SHOW = {
  general: 'general',
  form: 'form',
  body_metric: 'body_metric',
  progress_photo: 'progress_photo',
};

const getFormatName = format => {
  switch (format) {
    case TASK_TYPES.BODY_METRIC:
      return 'Metrics';
    case TASK_TYPES.GENERAL:
      return 'General';
    case TASK_TYPES.PROGRESS_PHOTO:
      return 'Progress Photo';
    case TASK_TYPES.FORM:
      return 'Form';
    default:
      return upperFirst(format);
  }
};

export const taskType = keys(TYPE_ORDER_SHOW).map(value => ({
  _id: value,
  title: getFormatName(value),
}));

export const NO_LOAD_MORE = false;
export const NO_LOADING = false;

export const toastMessage = (text, type) => {
  switch (type) {
    case 'success':
      return toast.success(text);
    case 'error':
      return toast.error(text);

    default:
      return toast(text);
  }
};
