import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import * as S from './style';
import './DeclineRequestModal.css';
import { Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import { rejectRequestWaitList as rejectRequestWaitListAction } from 'redux/waitlist/actions';
import { rejectRequestContactList as rejectRequestContactListAction } from 'redux/contact-list/actions';

import PreviewEmailSection from './PreviewEmailSection';
import DeclineIntroductionSection from './DeclineIntroductionSection';
import { CDN_URL } from 'constants/commonData';
import { getContentByType, defaultFormData, defaultErrors, DECLINE_REQUEST_TYPE, DECLINE_STEP } from './helper';

const DeclineRequestModal = props => {
  const {
    type,
    workingPackage: workingPackageProp,
    waitListRequests,
    waitListIdSelected,
    waitListIdsSelected,
    contactListRequests,
    contactListIdSelected,
    contactListIdsSelected,
    rejectRequestWaitList,
    rejectRequestContactList,
    onClose,
    onDeclineError,
  } = props;
  const workingPackage = workingPackageProp.toJS();

  const [step, setStep] = useState(DECLINE_STEP.STEP_ONE);
  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState(defaultErrors);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(false);
  const selectedClientRequests = useMemo(() => {
    const requests = type === DECLINE_REQUEST_TYPE.WAITLIST ? waitListRequests : contactListRequests;
    const clientIdSelected = type === DECLINE_REQUEST_TYPE.WAITLIST ? waitListIdSelected : contactListIdSelected;
    const clientIdsSelected = type === DECLINE_REQUEST_TYPE.WAITLIST ? waitListIdsSelected : contactListIdsSelected;

    if (clientIdSelected.length) return requests.filter(item => clientIdSelected.includes(item.id));
    return requests.filter(item => clientIdsSelected.includes(item.id));
  }, [
    type,
    waitListRequests,
    waitListIdSelected,
    waitListIdsSelected,
    contactListRequests,
    contactListIdSelected,
    contactListIdsSelected,
  ]);

  const hasErrors = !!errors.declineReason;
  const disabledDeclineRequest = isLoading || !formData.declineReason.trim() || hasErrors;
  const isMultipleSelectedRequests = selectedClientRequests.length > 1;
  const clientRequest = isMultipleSelectedRequests ? null : selectedClientRequests[0]; // Only get the first client request when the user only selects request

  const modalContent = useMemo(
    () =>
      getContentByType(type, {
        selectedRequestsCount: selectedClientRequests.length || 0,
        selectedDeclineOption: formData.selectedDeclineOption,
      }) || {},
    [type, selectedClientRequests, formData.selectedDeclineOption, step],
  );

  useEffect(() => {
    if (!isMounted.current) {
      const { defaultDeclineReason } = modalContent || {};
      setFormData(preState => ({ ...preState, declineReason: defaultDeclineReason }));
      isMounted.current = true;
    }
  }, [formData.selectedDeclineOption]);

  const handleChangeFormData = useCallback(data => {
    setFormData(preState => ({ ...preState, ...data }));
  }, []);

  const handleDeclineRequest = () => {
    if (isLoading) return;

    const params = { package_id: workingPackage.id, decline_reason: formData.declineReason };

    if (type === DECLINE_REQUEST_TYPE.WAITLIST) {
      setIsLoading(true);
      rejectRequestWaitList(params, {
        callbackSuccess: message => {
          onClose();
          toast(message);
          setIsLoading(false);
        },
        callbackFailure: error => {
          onClose();
          setIsLoading(false);
          onDeclineError(error);
        },
      });
    }

    if (type === DECLINE_REQUEST_TYPE.INTROCALL) {
      setIsLoading(true);
      rejectRequestContactList(
        { ...params, rejectedOption: formData.selectedDeclineOption },
        {
          callbackSuccess: message => {
            onClose();
            setIsLoading(false);
            toast(message);
          },
          callbackFailure: error => {
            onClose();
            setIsLoading(false);
            onDeclineError(error);
          },
        },
      );
    }

    return null;
  };

  const handleChangeDeclineOption = useCallback(
    option => {
      const { defaultDeclineReason: newDeclineReason } =
        getContentByType(type, {
          selectedRequestsCount: selectedClientRequests.length || 0,
          selectedDeclineOption: option,
        }) || {};

      handleChangeFormData({ selectedDeclineOption: option, declineReason: newDeclineReason });
      setErrors(defaultErrors);
    },
    [type, selectedClientRequests],
  );

  const handleCancelEditDeclineReason = () => {
    setErrors(defaultErrors);
  };

  const handleCancel = () => {
    setErrors(defaultErrors);
    setFormData({ ...formData, declineReason: modalContent.defaultDeclineReason });
    if (step === DECLINE_STEP.STEP_TWO) {
      setStep(DECLINE_STEP.STEP_ONE);
    } else {
      handleCancelEditDeclineReason();
      onClose();
    }
  };

  const handleSubmit = () => {
    if (step === DECLINE_STEP.STEP_ONE) {
      setStep(DECLINE_STEP.STEP_TWO);
    } else {
      handleDeclineRequest();
    }
  };

  return (
    <S.ModalWrapper
      open
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close_circle" />
        </CloseButton>
      }
      onClose={onClose}
      step={step}
    >
      <S.ModalContent type={type}>
        {step === DECLINE_STEP.STEP_ONE && (
          <DeclineIntroductionSection
            type={type}
            modalContent={modalContent}
            formData={formData}
            onChangeDeclineOption={handleChangeDeclineOption}
          />
        )}
        {step === DECLINE_STEP.STEP_TWO && (
          <PreviewEmailSection
            modalContent={modalContent}
            clientRequest={clientRequest}
            workingPackage={workingPackage}
            isMultipleSelectedRequests={isMultipleSelectedRequests}
            formData={formData}
            isLoading={isLoading}
            errors={errors}
            setErrors={setErrors}
            onChangeForm={handleChangeFormData}
          />
        )}
        <S.ActionsWrapper>
          <S.ActionItem>
            <S.ProgressStep width={20} active={step === DECLINE_STEP.STEP_ONE} />
            <S.ProgressStep width={40} active={step === DECLINE_STEP.STEP_TWO} />
          </S.ActionItem>
          <S.ActionItem>
            <S.CancelButton disabled={isLoading} onClick={handleCancel}>
              {step === DECLINE_STEP.STEP_ONE ? 'Cancel' : 'Back'}
            </S.CancelButton>
            <S.SubmitButton step={step} onClick={handleSubmit} disabled={disabledDeclineRequest}>
              {step === DECLINE_STEP.STEP_ONE ? 'Continue' : 'Decline'}
            </S.SubmitButton>
          </S.ActionItem>
        </S.ActionsWrapper>
      </S.ModalContent>
    </S.ModalWrapper>
  );
};

const mapState = state => {
  const {
    rootReducer: {
      waitList: {
        list: waitListRequests,
        clientIdSelected: waitListIdSelected,
        clientIdsSelected: waitListIdsSelected,
      },
      contactList: {
        list: contactListRequests,
        clientIdSelected: contactListIdSelected,
        clientIdsSelected: contactListIdsSelected,
      },
      packageDetail,
    },
  } = state;

  return {
    waitListRequests,
    waitListIdSelected,
    waitListIdsSelected,
    contactListRequests,
    contactListIdSelected,
    contactListIdsSelected,
    workingPackage: packageDetail.get('workingPackage'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    rejectRequestWaitList: bindActionCreators(rejectRequestWaitListAction, dispatch),
    rejectRequestContactList: bindActionCreators(rejectRequestContactListAction, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(DeclineRequestModal);
