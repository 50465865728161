import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import { Button } from 'shared/FormControl';
import SurveyModal from '../SurveyModal';

import { CDN_URL, CLASSNAME_TOAST } from 'constants/commonData';
import { moveElement, pluralize, setCaretToEnd } from 'utils/commonFunction';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { createNewSurvey, getSurveyQuestionLibraries, updateSurvey } from 'redux/survey-contact/actions';
import { updatePackageSurveyFlag } from 'redux/package-detail/actions';

import { ReactComponent as PlusIcon } from 'assets/icons/clarity_plus-line.svg';
import { ReactComponent as GripIcon } from 'assets/icons/grip_vertical.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash_grey_fill.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/popup-warning-icon.svg';

import * as S from './styles';
import { logout } from 'actions/auth/login';
import ReactTooltip from 'react-tooltip';

const FORM_KEYS = {
  SURVEY_NAME: 'surveyName',
  INTRODUCTION: 'intro',
  QUESTIONS: 'questions',
};
const FORM_LIMIT_LENGTH = {
  [FORM_KEYS.SURVEY_NAME]: 90,
  [FORM_KEYS.INTRODUCTION]: 210,
  [FORM_KEYS.QUESTIONS]: 255,
};
const ERROR_MESSAGE = {
  EMPTY: 'This field can not be empty.',
  DUPLICATE_QUESTIONS: 'This is a duplicated question.',
  DUPLICATE_SURVEY_NAME: 'Survey name already exists. Please choose a different name.',
};
const TOOLTIP_MESSAGE = {
  SURVEY_NAME: 'Clients will not see the survey name.',
  SURVEY_INTRO: 'Clients will see this survey introduction. You can edit or remove it.',
};
const DEFAULT_INTRODUCTION =
  'Thanks for your interest in {{PACKAGE_NAME}}. Please fill out the short survey below to help us get the most out of our call.';

const SurveyDetailModal = ({
  isCreateNew = false,
  isShowBack = true,
  isDuplicate = false,
  choosingSurvey,
  onClose,
  workingPackage,
  surveyQuestionLibraries,
  toggleModal,
  getSurveyQuestionLibraries,
  createNewSurvey,
  updateSurvey,
  updatePackageSurveyFlag,
  toggleConfirmModal,
  logout,
  onChangeSurvey,
}) => {
  const [surveyName, setSurveyName] = useState('');
  const [questions, setQuestions] = useState([{ question: '' }]);
  const [introduction, setIntroduction] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusQuestion, setFocusQuestion] = useState();
  const [hoverQuestion, setHoverQuestion] = useState();
  const [errorsState, setErrorState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [textEditorFocusing, setTextEditorFocusing] = useState('');
  const packageData = workingPackage ? workingPackage.toJS() : null;
  const packageName = get(packageData, 'name', null);
  const packagePurchaseOption = get(packageData, 'package_purchase_option', null);
  const surveyId = get(choosingSurvey, 'id');

  const surveyNameRef = useRef();
  const introRef = useRef();
  const questionRefs = useRef([]);

  useEffect(() => {
    if (!surveyQuestionLibraries.length) getSurveyQuestionLibraries();
  }, [surveyQuestionLibraries]);

  useEffect(() => {
    setTimeout(() => {
      surveyNameRef.current && surveyNameRef.current.focus();
      const inputRef = get(surveyNameRef, 'current.inputRef.current');
      setCaretToEnd(inputRef);
    }, 0);
  }, []);

  useEffect(() => {
    if (isCreateNew && packageName) {
      const newIntroduction = DEFAULT_INTRODUCTION.replace('{{PACKAGE_NAME}}', packageName);
      setIntroduction(newIntroduction);
    }
  }, [packageName, isCreateNew, isDuplicate]);

  useEffect(() => {
    if (isCreateNew && surveyQuestionLibraries && isEmpty(choosingSurvey)) {
      setQuestions(map(surveyQuestionLibraries, item => pick(item, 'question')));
    } else {
      const { survey_name, questions, intro } = choosingSurvey || {};
      setSurveyName((survey_name || '').slice(0, FORM_LIMIT_LENGTH[FORM_KEYS.SURVEY_NAME]));
      setQuestions(map(questions, item => pick(item, 'question')));
      if (typeof intro === 'string') {
        setIntroduction(intro);
      }
    }
  }, [choosingSurvey, surveyQuestionLibraries]);

  const handleRemoveErrorMessage = key => {
    const hasErrorMessage = get(errorsState, key, '');
    if (!!hasErrorMessage) {
      const newErrorMessage = omit(errorsState, [key]);
      setErrorState(newErrorMessage);
    }
  };

  const validationData = (data, isSkipSurveyName) => {
    const surveyNameMsg = get(errorsState, 'surveyName', '');
    const currentData = data || questions;
    const mappedData = [];
    let errors = {
      surveyName: surveyNameMsg,
    };

    if (!isSkipSurveyName && !surveyName.trim()) {
      errors = { surveyName: ERROR_MESSAGE.EMPTY };
    }

    currentData.forEach((item, index) => {
      const isEmpty = !item.question.trim();
      const isDuplicated = mappedData.includes(item.question.toLowerCase());

      if (isEmpty) {
        errors = {
          ...errors,
          [index]: ERROR_MESSAGE.EMPTY,
        };
      } else {
        mappedData.push(item.question.toLowerCase());

        if (isDuplicated) {
          errors = {
            ...errors,
            [index]: ERROR_MESSAGE.DUPLICATE_QUESTIONS,
          };
        }
      }
    });
    return pickBy(errors, identity);
  };

  const handleClose = () => {
    if (hasChanges && surveyId) return handleDiscardChanges();
    if (isShowBack) return toggleModal(true, <SurveyModal onClose={onClose} onChangeSurvey={onChangeSurvey} />);
    toggleModal(false);
  };

  const handleDiscardChanges = () => {
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="error"
        noIcon
        newStyle
        hasCloseIcon
        isPressEsc
        confirmButtonTitle="Discard changes"
        title={
          <>
            <WarningIcon />
            <span>Discard Changes?</span>
          </>
        }
        content={<p>Are you sure you want to go? Changes have not been saved yet.</p>}
        onConfirm={() => {
          setHasChanges(false);
          if (isShowBack) return toggleModal(true, <SurveyModal onClose={onClose} onChangeSurvey={onChangeSurvey} />);
          toggleModal(false);
        }}
      />,
    );
  };

  const handleApplyAllPackages = () => {
    const params = {
      survey_name: surveyName,
      questions,
      intro: introduction,
    };
    const totalPackageApplied = get(choosingSurvey, 'total_package_applied', 0);
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        noIcon
        newStyle
        hasCloseIcon
        isPressEsc
        confirmButtonTitle="Apply for all packages"
        title={
          <>
            <WarningIcon />
            <span>Confirmation</span>
          </>
        }
        content={
          <p>{`Are you sure you want to apply changes for ${pluralize(
            'package',
            totalPackageApplied,
            true,
          )} using this survey?`}</p>
        }
        onConfirm={() => {
          setIsSubmitting(true);
          updateSurvey(surveyId, params)
            .then(res => {
              const data = get(res, 'data.data', {});
              const package_purchase_option = {
                ...packagePurchaseOption,
                survey_id: data.id,
                survey: {
                  ...packagePurchaseOption.survey,
                  survey_name: data.survey_name,
                  questions: get(data, 'questions', []),
                  intro: introduction,
                  author_name: data.author_name || '',
                },
              };
              updatePackageSurveyFlag({ has_survey_list: true, package_purchase_option });
              toggleModal(false);
              toast('Successfully updated.', { className: CLASSNAME_TOAST.SURVEY_MANAGEMENT });
            })
            .catch(error => {
              const statusCode = get(error, 'response.data.statusCode', 0);
              if (statusCode === 400) {
                setErrorState({ surveyName: ERROR_MESSAGE.DUPLICATE_SURVEY_NAME });
              }
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }}
      />,
    );
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    if (isSubmitting) return;
    const errors = validationData(questions);
    setErrorState(errors);
    if (Object.keys(errors).length) return;

    const isEditing = surveyId && !isCreateNew;
    const totalPackageApplied = get(choosingSurvey, 'total_package_applied', 0);
    if (isEditing && totalPackageApplied > 1) return handleApplyAllPackages();

    setIsSubmitting(true);
    const params = {
      survey_name: surveyName,
      questions,
      intro: introduction,
    };
    const action = isEditing ? updateSurvey(surveyId, params) : createNewSurvey(params);

    action
      .then(res => {
        const data = get(res, 'data.data', {});

        const package_purchase_option = {
          ...packagePurchaseOption,
          survey_id: data.id,
          survey: {
            ...packagePurchaseOption.survey,
            survey_name: data.survey_name,
            questions: get(data, 'questions', []),
            intro: introduction,
            author_name: data.author_name || '',
          },
        };
        updatePackageSurveyFlag({ has_survey_list: true, package_purchase_option });
        toggleModal(false);
        onChangeSurvey && onChangeSurvey(true);
        let successMessage = 'Your survey has been created successfully.';
        if (isDuplicate) {
          successMessage = 'The survey is duplicated and applied for your package.';
        }
        if (isEditing) {
          successMessage = 'Successfully updated.';
        }
        toast(successMessage, {
          className: CLASSNAME_TOAST.SURVEY_MANAGEMENT,
        });
      })
      .catch(error => {
        const statusCode = get(error, 'response.data.statusCode', 0);
        if (statusCode === 400) {
          setErrorState({ surveyName: ERROR_MESSAGE.DUPLICATE_SURVEY_NAME });
        }
        if (statusCode === 403) {
          logout();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onDragEnd = async result => {
    const { destination, source } = result;
    if (!destination || !source) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    const newQuestions = moveElement(questions, source.index, destination.index);
    if (isSubmitted) {
      const errors = validationData(newQuestions);
      setErrorState(errors);
    }
    setQuestions(newQuestions);
    setHasChanges(true);
  };

  const handleChangeTextEditor = (newValue, ref, key) => {
    const inputRef = get(ref, 'current.inputRef.current');
    const currentValue = key === FORM_KEYS.SURVEY_NAME ? surveyName : introduction;
    let resolveNewValue = newValue;
    const limitLength = get(FORM_LIMIT_LENGTH, key, 0);
    if (newValue.length <= limitLength) {
      resolveNewValue = newValue;
    } else {
      if (newValue.length === limitLength + 1) {
        resolveNewValue = currentValue.slice(0, limitLength);
      } else {
        resolveNewValue = newValue.slice(0, limitLength);
      }
      setTimeout(() => {
        ref.current.focus();
        setCaretToEnd(inputRef);
      }, 0);
    }

    switch (key) {
      case FORM_KEYS.SURVEY_NAME: {
        setSurveyName(resolveNewValue);
        break;
      }
      case FORM_KEYS.INTRODUCTION: {
        setIntroduction(resolveNewValue);
        break;
      }
      default:
        break;
    }

    handleRemoveErrorMessage(key);
    setHasChanges(true);
  };

  const handleBlurSurveyName = () => {
    setTextEditorFocusing('');
    setSurveyName(surveyName.trim());
    surveyNameRef.current.inputRef.current.innerText = surveyName.trim();
  };

  const handleChangeQuestionName = (newValue, idx) => {
    if (newValue.length <= FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS]) {
      setQuestions(map(questions, (item, index) => (index === idx ? { ...item, question: newValue } : item)));
    } else {
      if (newValue.length === FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS] + 1) {
        setQuestions(
          map(questions, (item, index) => {
            const questionName = get(questions[index], 'question', '');
            if (index === idx) {
              return {
                ...item,
                question:
                  questionName.length === FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS]
                    ? get(questions[index], 'question', '')
                    : newValue.slice(0, FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS]),
              };
            }

            return item;
          }),
        );
      } else {
        setQuestions(
          map(questions, (item, index) =>
            index === idx ? { ...item, question: newValue.slice(0, FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS]) } : item,
          ),
        );
      }

      const inputRef = get(questionRefs.current[idx], 'inputRef.current');
      setTimeout(() => {
        setCaretToEnd(inputRef);
      }, 0);
    }
    handleRemoveErrorMessage(idx);
    setHasChanges(true);
  };

  const focusToQuestion = idx => {
    const questionRef = questionRefs.current[idx];
    questionRef && questionRef.focus();
  };

  const handleAddMoreQuestion = () => {
    if (questions.length >= 10) return;

    setQuestions([...questions, { question: '' }]);
    setHasChanges(true);
    setTimeout(() => {
      focusToQuestion(questions.length);
    }, 0);
  };

  const handleRemoveQuestion = idx => {
    if (questions.length <= 1) return;
    const newQuestions = questions.filter((item, index) => index !== idx);
    setQuestions(newQuestions);
    setHasChanges(true);

    const newErrors = validationData(newQuestions, true);
    setErrorState(newErrors);
  };

  const handleQuestionBlur = (item, idx) => {
    setFocusQuestion();
    if (questions.length <= 1) return;

    const questionName = get(item, 'question', '');
    if (!questionName) {
      handleRemoveQuestion(idx);
    }
  };

  const renderQuestions = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <S.ListWrapper ref={provided.innerRef} {...provided.droppableProps}>
              {map(questions, (item, i) => {
                const questionName = get(item, 'question', '');
                const errorMsg = get(errorsState, i);
                const isShowIcons = i !== hoverQuestion;
                return (
                  <Draggable draggableId={`question-${i}`} index={i} key={`question-${i}`}>
                    {(draggableProvide, snapshot) => (
                      <S.ItemWrapper
                        isDragging={snapshot.isDragging}
                        {...draggableProvide.draggableProps}
                        ref={draggableProvide.innerRef}
                        onMouseEnter={() => i !== focusQuestion && questions.length > 1 && setHoverQuestion(i)}
                        onMouseLeave={() => setHoverQuestion()}
                      >
                        <S.ItemEditableWrapper>
                          {isShowIcons ? (
                            <S.Counter>{i + 1}</S.Counter>
                          ) : (
                            <S.GripIconWrapper {...draggableProvide.dragHandleProps}>
                              <GripIcon className="grip-icon" />
                            </S.GripIconWrapper>
                          )}
                          <S.QuestionField isError={!!errorMsg} isActive={i === focusQuestion}>
                            <S.QuestionEditable
                              ref={el => (questionRefs.current[i] = el)}
                              value={questionName}
                              onChange={value => handleChangeQuestionName(value, i)}
                              onFocus={() => {
                                setHoverQuestion();
                                setFocusQuestion(i);
                              }}
                              onBlur={() => handleQuestionBlur(item, i)}
                              placeholder="Your question goes here"
                              invalid={false}
                              maxLength={FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS] - 1}
                            />
                            {isShowIcons ? (
                              questionName.length >= FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS] - 10 ? (
                                <S.CountCharacter
                                  isError={questionName.length >= FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS] - 5}
                                >
                                  <span>{questionName.length}</span>
                                  {`/${FORM_LIMIT_LENGTH[FORM_KEYS.QUESTIONS]}`}
                                </S.CountCharacter>
                              ) : null
                            ) : (
                              <S.TrashIconWrapper
                                onClick={event => {
                                  event.stopPropagation();
                                  handleRemoveQuestion(i);
                                }}
                              >
                                <TrashIcon />
                              </S.TrashIconWrapper>
                            )}
                          </S.QuestionField>
                        </S.ItemEditableWrapper>

                        {!!errorMsg && <S.QuestionMessage>{errorMsg}</S.QuestionMessage>}
                      </S.ItemWrapper>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}

              {questions.length < 10 ? (
                <S.ItemEditableWrapper>
                  <S.Counter />
                  <S.AddMoreQuestion onClick={handleAddMoreQuestion}>
                    <PlusIcon />
                    <span>Add New Question</span>
                  </S.AddMoreQuestion>
                </S.ItemEditableWrapper>
              ) : null}
            </S.ListWrapper>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <>
      <S.SurveyDetailModalWrapper
        open={true}
        closeOnDimmerClick={false}
        onClose={handleClose}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="close_circle" />
          </CloseButton>
        }
      >
        <Modal.Header>
          <S.NameField isError={!!get(errorsState, FORM_KEYS.SURVEY_NAME, '')} data-tip data-for="survey-name">
            <S.TextEditable
              ref={surveyNameRef}
              value={surveyName}
              onChange={value => handleChangeTextEditor(value, surveyNameRef, FORM_KEYS.SURVEY_NAME)}
              placeholder="Enter survey name"
              invalid={false}
              maxLength={FORM_LIMIT_LENGTH[FORM_KEYS.SURVEY_NAME] - 1}
              onBlur={handleBlurSurveyName}
              onFocus={() => setTextEditorFocusing(FORM_KEYS.SURVEY_NAME)}
            />
            {surveyName.length >= FORM_LIMIT_LENGTH[FORM_KEYS.SURVEY_NAME] - 10 ? (
              <S.CountCharacter isError={surveyName.length >= FORM_LIMIT_LENGTH[FORM_KEYS.SURVEY_NAME] - 5}>
                <span>{surveyName.length}</span>
                {`/${FORM_LIMIT_LENGTH[FORM_KEYS.SURVEY_NAME]}`}
              </S.CountCharacter>
            ) : null}
          </S.NameField>
          {!!get(errorsState, FORM_KEYS.SURVEY_NAME, '') && (
            <S.QuestionMessage marginLeft="0px">{get(errorsState, FORM_KEYS.SURVEY_NAME, '')}</S.QuestionMessage>
          )}
          <S.IntroField data-tip data-for="survey-intro">
            <S.IntroductionEditable
              ref={introRef}
              placeholder="Write a brief introduction to your survey here (optional)."
              value={introduction}
              onChange={value => handleChangeTextEditor(value, introRef, FORM_KEYS.INTRODUCTION)}
              onFocus={() => setTextEditorFocusing(FORM_KEYS.INTRODUCTION)}
              onBlur={() => setTextEditorFocusing('')}
              invalid={false}
              maxLength={FORM_LIMIT_LENGTH[FORM_KEYS.INTRODUCTION] - 1}
            />
            {introduction.length >= FORM_LIMIT_LENGTH[FORM_KEYS.INTRODUCTION] - 10 ? (
              <S.CountCharacter isError={introduction.length >= FORM_LIMIT_LENGTH[FORM_KEYS.INTRODUCTION] - 5}>
                <span>{introduction.length}</span>
                {`/${FORM_LIMIT_LENGTH[FORM_KEYS.INTRODUCTION]}`}
              </S.CountCharacter>
            ) : null}
          </S.IntroField>
          {textEditorFocusing !== FORM_KEYS.SURVEY_NAME && (
            <ReactTooltip id="survey-name" className="name-tooltip" effect="solid" place="top">
              <span>{TOOLTIP_MESSAGE.SURVEY_NAME}</span>
            </ReactTooltip>
          )}
          {textEditorFocusing !== FORM_KEYS.INTRODUCTION && (
            <ReactTooltip id="survey-intro" className="name-tooltip" effect="solid" place="bottom">
              <span>{TOOLTIP_MESSAGE.SURVEY_INTRO}</span>
            </ReactTooltip>
          )}
        </Modal.Header>
        <Modal.Content>{renderQuestions()}</Modal.Content>
        <Modal.Actions className="modal__actions">
          <Button className="actions__cancel" onClick={handleClose}>
            {isShowBack ? 'Back' : 'Cancel'}
          </Button>
          <Button purple className="actions__save" disabled={isSubmitting} onClick={handleSubmit}>
            {surveyId && !isCreateNew ? 'Save' : 'Create survey'}
          </Button>
        </Modal.Actions>
      </S.SurveyDetailModalWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  surveyQuestionLibraries: get(state, 'rootReducer.surveyContact.surveyQuestionLibraries', []),
  workingPackage: get(state, 'rootReducer.packageDetail', {}).get('workingPackage'),
});
const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getSurveyQuestionLibraries: bindActionCreators(getSurveyQuestionLibraries, dispatch),
  createNewSurvey: bindActionCreators(createNewSurvey, dispatch),
  updatePackageSurveyFlag: bindActionCreators(updatePackageSurveyFlag, dispatch),
  updateSurvey: bindActionCreators(updateSurvey, dispatch),
  logout: bindActionCreators(logout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetailModal);
