import styled, { css } from 'styled-components';
import ChatInput from 'shared/ChatInput';

export const Header = styled.div`
  padding: 24px 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202353;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 3px 25px;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const Footer = styled.div`
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  outline: none !important;

  ${props =>
    props.empty &&
    css`
      ${Body} {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    `}
`;

export const Wrapper = styled.div`
  flex: 1;
  background: #ffffff;
  box-shadow: -2px 0px 16px rgba(108, 108, 108, 0.1);
  border-radius: 5px;
  height: 640px;

  ${props =>
    !props.showing &&
    css`
      display: none;
    `}
`;

export const WrapperInput = styled(ChatInput)`
  border-top: none;
  padding: 10px;
  box-shadow: -2px 0px 16px rgba(108, 108, 108, 0.1), inset 0px 1px 0px #dde2ed;
  .post {
    width: 26px;
    height: 26px;
  }
`;

export const LeaveAComment = styled.div`
  margin-bottom: 72px;
  text-align: center;
  h4 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #202353;
    margin-bottom: 11px;
    margin-top: 19px;
  }
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    text-align: center;
    color: #202353;
  }
`;

export const LoaderWrapper = styled.div`
  padding-bottom: 70px;
`;

export const ListCommentWrapper = styled.div`
  margin-bottom: auto;
`;
