// Libs
import React, { useState, useMemo } from 'react';
import { find, get } from 'lodash';
import { Image } from 'semantic-ui-react';

// Shared
import QuestionTypesPopup from 'shared/QuestionTypesPopup';
import ConfirmModal from 'shared/ConfirmModal';
import { BLOCK_ACTION_TYPES, BlockActionModal } from 'shared/TriggerForms';

// Constants
import {
  CDN_URL,
  QUESTION_TYPES,
  WELCOME_DESCRIPTION_LIMIT_LENGTH,
  WELCOME_QUESTION_NAME_LIMIT_LENGTH,
} from 'constants/commonData';
import { DATE_FORMAT } from 'components/FormDetails/constants';
import { checkSyncConditions } from 'components/FormDetails/helpers';

// Assets
import { ReactComponent as WelcomeIcon } from 'assets/icons/welcome_screen.svg';
import { ReactComponent as SingleChoiceIcon } from 'assets/icons/single_choice.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icons/multiple_choice.svg';
import { ReactComponent as LinearScaleIcon } from 'assets/icons/linear_scale.svg';
import { ReactComponent as ShortAnswerIcon } from 'assets/icons/short_answer.svg';
import { ReactComponent as LongAnswerIcon } from 'assets/icons/long_answer.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/signature.svg';
import { ReactComponent as ImageTextIcon } from 'assets/icons/image_text.svg';
import { ReactComponent as MetricIcon } from 'assets/icons/metric_answer.svg';
import { ReactComponent as DateIcon } from 'assets/icons/date_answer.svg';

// Styles
import { QuestionTypeWrapper, Title, QuestionTypeDropdownTrigger } from './style';
import './styles.scss';

const renderIcon = type => {
  let icon;
  switch (type) {
    case QUESTION_TYPES.WELCOME_SCREEN:
      icon = <WelcomeIcon />;
      break;
    case QUESTION_TYPES.SINGLE_CHOICE:
      icon = <SingleChoiceIcon />;
      break;
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      icon = <MultipleChoiceIcon />;
      break;
    case QUESTION_TYPES.LINEAR_SCALE:
      icon = <LinearScaleIcon />;
      break;
    case QUESTION_TYPES.SHORT_ANSWER:
      icon = <ShortAnswerIcon />;
      break;
    case QUESTION_TYPES.LONG_ANSWER:
      icon = <LongAnswerIcon />;
      break;
    case QUESTION_TYPES.SIGNATURE:
      icon = <SignatureIcon />;
      break;
    case QUESTION_TYPES.IMAGE_TEXT:
      icon = <ImageTextIcon />;
      break;
    case QUESTION_TYPES.METRIC:
      icon = <MetricIcon />;
      break;
    case QUESTION_TYPES.DATE_TIME:
      icon = <DateIcon />;
      break;

    default:
      break;
  }
  return icon;
};

export default function QuestionType({
  workingQuestion,
  questionTypes,
  questions,
  toggleConfirmModal,
  updatePartialQuestion,
  allowEdit,
  defaultBackgroundColor,
  user,
  bodyMetrics,
  isPublished,
  workingForm,
}) {
  const {
    _id = '',
    type = '',
    title = '',
    description = '',
    options = '',
    has_trigger_in_use,
    related_question_onboarding_flows,
  } = workingQuestion || {};
  const { date_format } = user || {};

  const [open, setOpen] = useState(false);

  const questionTypeName = useMemo(() => {
    return get(find(questionTypes, ['type', type]), 'name', '');
  }, [workingQuestion, questionTypes]);

  const newQuestions = (questions || []).filter(({ type }) =>
    [QUESTION_TYPES.DATE_TIME, QUESTION_TYPES.METRIC].includes(type),
  );

  const metricCondition = ({ is_sync, _id, type, body_metric_unique_code }) => {
    return (
      type === QUESTION_TYPES.METRIC &&
      (typeof is_sync === 'undefined' || is_sync) &&
      _id !== get(workingQuestion, '_id') &&
      body_metric_unique_code === ((bodyMetrics || [])[0] || {}).unique_code
    );
  };

  const { hasSync: hasSyncMetric } = checkSyncConditions(newQuestions, metricCondition);

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  const handleAddQuestion = data => {
    const isChangeToChoice = [QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.MULTIPLE_CHOICE].includes(data.type);
    const isChangeChoice =
      isChangeToChoice && [QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.MULTIPLE_CHOICE].includes(type);
    const isChangeAnswer =
      [QUESTION_TYPES.SHORT_ANSWER, QUESTION_TYPES.LONG_ANSWER, QUESTION_TYPES.SIGNATURE].includes(data.type) &&
      [QUESTION_TYPES.SHORT_ANSWER, QUESTION_TYPES.LONG_ANSWER, QUESTION_TYPES.SIGNATURE].includes(type);
    const isWelcome = [QUESTION_TYPES.WELCOME_SCREEN].includes(type);

    if (type !== data.type) {
      let newData = {
        _id: _id,
        type: data.type,
        title: title,
        description: description,
        options: [],
        has_other_option: false,
        linear_start: {
          label: '',
          value: '0',
        },
        linear_end: {
          label: '',
          value: '5',
        },
      };

      if ([data.type, workingQuestion.type].includes(QUESTION_TYPES.WELCOME_SCREEN)) {
        newData = {
          ...newData,
          button_background: defaultBackgroundColor,
          button_label: "Let's start",
          background_image: null,
          background_image_thumbnail: null,
        };
      }

      if ([data.type, workingQuestion.type].includes(QUESTION_TYPES.IMAGE_TEXT)) {
        newData = {
          ...newData,
          background_image: null,
          background_image_thumbnail: null,
          image_width: null,
          image_height: null,
        };
      }

      if ([data.type, workingQuestion.type].includes(QUESTION_TYPES.DATE_TIME)) {
        newData = {
          ...newData,
          is_sync: false,
          sync_field: 'dob',
          sync_collection: 'profile',
          date_format: date_format || DATE_FORMAT,
          is_require: false,
        };
      }

      if ([data.type, workingQuestion.type].includes(QUESTION_TYPES.METRIC)) {
        newData = {
          ...newData,
          is_sync: !hasSyncMetric,
          body_metric_unique_code: bodyMetrics.length > 0 ? bodyMetrics[0].unique_code : null,
          unit: bodyMetrics.length > 0 ? bodyMetrics[0].unit : null,
          is_require: false,
        };
      }

      if (isChangeToChoice) {
        newData = {
          ...newData,
          has_other_option: true,
          options: data.options,
        };
      }

      if (isChangeChoice) {
        newData = {
          ...newData,
          has_other_option: true,
          options: options,
        };
      }

      if (data.type === QUESTION_TYPES.WELCOME_SCREEN) {
        newData = {
          ...newData,
          title: title.substring(0, WELCOME_QUESTION_NAME_LIMIT_LENGTH),
          description: description.substring(0, WELCOME_DESCRIPTION_LIMIT_LENGTH),
        };
      }

      if (
        isPublished &&
        type === QUESTION_TYPES.SINGLE_CHOICE &&
        has_trigger_in_use &&
        typeof toggleConfirmModal === 'function' &&
        Array.isArray(related_question_onboarding_flows)
      ) {
        return toggleConfirmModal(
          true,
          <BlockActionModal
            type={BLOCK_ACTION_TYPES.CHANGE_TYPE_QUESTION}
            closeModal={closeConfirmModal}
            relatedQuestionOnboardingFlows={related_question_onboarding_flows}
            user={user}
          />,
        );
      }

      if (!isChangeChoice && !isChangeAnswer && !isWelcome) {
        newData = {
          ...newData,
          is_require: false,
        };

        toggleConfirmModal(
          true,
          <ConfirmModal
            noIcon
            noBorder
            title="Change question type?"
            content="All settings and options will be reset, except for the title and description of the question. Would you like to continue?"
            onConfirm={() => updatePartialQuestion(newData)}
            confirmButtonTitle="Yes, Continue"
            hasHoverState
            shouldCloseAfterConfirm
            className="change-question-confirm-popup-container"
          />,
        );
      } else {
        updatePartialQuestion(newData);
      }
    }
  };
  const CustomTrigger = () => {
    return (
      <QuestionTypeDropdownTrigger
        isOpen={allowEdit ? open : false}
        onClick={() => {
          setOpen(prev => !prev);
        }}
      >
        <div className="value-container">
          {renderIcon(type)}
          {questionTypeName}
        </div>
        <Image src={`${CDN_URL}/images/arrow_down_black.svg`} className="dropdown-icon" />
      </QuestionTypeDropdownTrigger>
    );
  };
  const handleClosePopup = () => {
    if (open) {
      setOpen(false);
    }
  };
  return (
    <QuestionTypeWrapper>
      <Title className="type">Type</Title>
      <QuestionTypesPopup
        selected={type}
        questions={questions}
        questionTypes={questionTypes}
        onClosePopup={handleClosePopup}
        onAddQuestion={handleAddQuestion}
        trigger={<CustomTrigger />}
        menuClassName="change-question-menu"
        disabled={!allowEdit}
        defaultColor={defaultBackgroundColor}
        user={user}
        bodyMetrics={bodyMetrics}
        workingQuestion={workingQuestion}
      />
    </QuestionTypeWrapper>
  );
}
