import React from 'react';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button } from 'shared/FormControl';
import { explorePayment } from 'redux/permission/actions';
import { initPermission } from 'redux/permission/actions';
import { getAccessToken, getCookie, genTokenFromMarketplace } from 'utils/commonFunction';
import NewCountryFallBack from '../NewCountryFallBack';

import * as S from './style';
import { Container } from '../../style';
import UpgradePathCreatePackage from 'assets/images/upgrade-path-packages_video.mp4';
import UpgradePathSetPricing from 'assets/images/upgrade-path-set-pricing.png';
import UpgradePathCollect from 'assets/images/upgrade-path-collect.png';
import { ReactComponent as ArrowSlider } from 'assets/icons/arrow-slider.svg';
import { ReactComponent as UsdSymbol } from 'assets/icons/usd-symbol.svg';
import { C_KEY_TOKEN } from 'constants/commonData';

const isPublic = process.env.REACT_APP_SUPPORT_PAYMENT_MULTI_COUNTRIES;

const data = [
  {
    imageSrc: UpgradePathCreatePackage,
    title: 'Create Packages',
    description: 'Setup professional package pages to help you sell coaching services online or in-person.',
    video: true,
  },
  {
    imageSrc: UpgradePathSetPricing,
    title: 'Set Pricing',
    description: 'Choose one-time or recurring payments that work with any training business model.',
  },
  {
    imageSrc: UpgradePathCollect,
    title: 'Collect Payments',
    description: 'Receive automatic payouts and gain insights you need to grow with a comprehensive dashboard.',
  },
];

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <S.ArrowSliderRight className={className} onClick={onClick}>
      <ArrowSlider />
    </S.ArrowSliderRight>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <S.ArrowSliderLeft className={className} onClick={onClick}>
      <ArrowSlider />
    </S.ArrowSliderLeft>
  );
}

class PackageFallBack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'slick-dots',
        customPaging: i => <S.Dots className={'dots-item'} />,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      },
    };
  }

  // MP
  componentDidMount() {
    const { user, dispatch } = this.props;
    const search = window.location.search;
    const pathname = window.location.pathname;

    const token = getCookie(C_KEY_TOKEN);

    if (token && search.includes('redirect-platform')) {
      genTokenFromMarketplace({ token, user, pathname, search, replacePathname: '/home/marketplace', dispatch });
    }
  }

  handleExplorePayment = () => {
    this.props.explorePayment().finally(() => {
      const accessToken = getAccessToken();
      if (accessToken) this.props.initPermission();
    });
  };

  render() {
    const { settings } = this.state;

    return (
      <S.FallbackWrapperS>
        <S.ComingSoon>
          <UsdSymbol />
          <S.ComingText>
            {!isPublic ? (
              'Currently only available for US-based bank accounts. Coming soon for other countries!'
            ) : (
              <NewCountryFallBack />
            )}
          </S.ComingText>
        </S.ComingSoon>

        <Container>
          <S.Section>
            <S.Header>
              <S.Title>Payment & Packages</S.Title>
              <S.Description>
                Create one-time or recurring packages to collect payment effortlessly for your training business!
              </S.Description>
            </S.Header>

            <S.Body>
              <S.Slider>
                <Slider {...settings}>
                  {data.map((item, key) => (
                    <S.Item key={key}>
                      <S.ItemImageArea>
                        {!item.video && <S.ItemImage src={item.imageSrc}></S.ItemImage>}
                        {item.video && (
                          <S.ItemVideo>
                            <ReactPlayer
                              progressInterval={500}
                              muted={true}
                              url={item.imageSrc}
                              playing={true}
                              loop={true}
                              width="102%"
                              height="100%"
                              controls={false}
                            />
                          </S.ItemVideo>
                        )}
                      </S.ItemImageArea>

                      <S.ItemContent>
                        <S.Number>{key + 1}</S.Number>
                        <S.ItemTitle>{item.title}</S.ItemTitle>
                        <S.ItemText>{item.description}</S.ItemText>
                        {/* <UpgradePathButton className="upgrade-path-button" text="Get Payment lmao" /> */}
                        <Button className="upgrade-path-button" onClick={this.handleExplorePayment}>
                          Explore Payment Now
                        </Button>
                      </S.ItemContent>
                    </S.Item>
                  ))}
                </Slider>
              </S.Slider>
            </S.Body>
          </S.Section>
        </Container>
      </S.FallbackWrapperS>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    explorePayment: bindActionCreators(explorePayment, dispatch),
    initPermission: bindActionCreators(initPermission, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(PackageFallBack);
