// Lib
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

// Store
import {
  selectedCoverBackground,
  getRecipeBackgroundLibraries,
  resetBackgroundLibrary,
  updateDataDetailRecipe,
  getUnitType,
} from 'redux/recipes/actions';
import { toggleConfirmModal } from 'actions/modal';

// Components
import CustomOwnerShip from 'components/MealPlans/parts/CustomOwnerShip';
import Thumbnail from 'components/RecipeDetail/Thumbnail';
import DiscardChangeModal from '../DiscardChange';

// Constants
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { ENUM_DISH_TYPE, ListUnit, MACRO_NUTRIENTS } from 'components/RecipeDetail/constants';

// Helper
import { getRandomInt } from 'components/RecipeDetail/helper';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';

import * as S from './style';

const AddNewRecipe = props => {
  const {
    user,
    loadingAdd,
    toggleModal,
    listCoverImage = [],
    selectedCoverBackground,
    getRecipeBackgroundLibraries,
    resetBackgroundLibrary,
    toggleConfirmModal,
    updateDataDetailRecipe,
    push,
    isChangeRecipeDefaultImage,
    getUnitType,
  } = props;

  const originFormData = {
    name: '',
    owner: get(user, '_id', null),
    share: TEAM_SHARE_PRIVATE,
    coverImage: get(listCoverImage, '[0].image', ''),
  };
  const [formData, setFormData] = useState(originFormData);
  const [localUrl, setLocalUrl] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingCoverImg, setLoadingCoverImg] = useState(false);
  const [isShowDiscard, setIsShowDiscard] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const nameTrim = get(formData, 'name', '').trim();

  useEffect(() => {
    getRecipeBackground();
    return () => {
      resetBackgroundLibrary && resetBackgroundLibrary();
    };
  }, []);

  useEffect(() => {
    setIsShowDiscard(!!nameTrim && !isEqual(originFormData, formData));
  }, [formData]);

  const getRecipeBackground = async () => {
    try {
      setLoadingCoverImg(true);
      const res = await getRecipeBackgroundLibraries({ page: 1, per_page: 100, type: 'system' }, false);
      const listBackground = get(res, 'data.data.data', []);
      const indexRandom = getRandomInt(listBackground.length);
      const result = listBackground[indexRandom];
      setFormData({ ...formData, coverImage: result && result.image });
      selectedCoverBackground && selectedCoverBackground(result);
      setLoadingCoverImg(false);
    } catch (err) {
      setLoadingCoverImg(false);
      console.error(err);
    }
  };

  const handleShareValue = shareValue => {
    setFormData(prevState => ({ ...prevState, share: shareValue.share }));
  };

  const handleOwnerValue = ownerValue => {
    setFormData(prevState => ({ ...prevState, owner: ownerValue.owner }));
  };

  const handleClose = () => {
    toggleModal && toggleModal(false);
  };

  const handleDiscardChange = () => {
    if (isShowDiscard) {
      toggleConfirmModal && toggleConfirmModal(true, <DiscardChangeModal onConfirm={handleClose} />);
    } else {
      handleClose();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!nameTrim || loadingUpload) {
      return;
    }

    let params = {
      name: nameTrim,
      owner: formData.share === TEAM_SHARE_NOOWNER ? null : formData.owner || get(user, '_id', ''),
      share: formData.share,
      cover_image: formData.coverImage || get(listCoverImage, '[0].image', ''),
      prepare_instructions: [],
      cooking_instructions: [],
      dish_type: ENUM_DISH_TYPE.MAIN_DISH,
    };

    handleCreateRecipe(params);
  };

  const handleCreateRecipe = async data => {
    setIsLoadingCreate(true);
    const resUnitType = await getUnitType();
    const unitTypeKey = get(resUnitType, 'data.data.unit_type');
    const unitType = ListUnit.find(item => item && item.key === unitTypeKey);

    const dataDefault = {
      number_of_serving: 1,
      macro_nutrients: [
        { type: MACRO_NUTRIENTS.PROTEIN, value: '' },
        { type: MACRO_NUTRIENTS.CARBS, value: '' },
        { type: MACRO_NUTRIENTS.FAT, value: '' },
        { type: MACRO_NUTRIENTS.CALORIES, value: '' },
      ],
    };

    const dataRecipe = {
      ...data,
      ...dataDefault,
      unit: unitType,
      owner: { _id: data.owner || '' },
      is_auto_calculated: true,
    };

    updateDataDetailRecipe && updateDataDetailRecipe(dataRecipe);
    setIsLoadingCreate(false);
    handleClose();
    push('/home/recipes/create');
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (typeof value === 'string' || typeof value === 'number') {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelect = item => {
    setFormData({ ...formData, coverImage: get(item, 'image') });
  };

  if (loadingCoverImg) return <></>;

  return (
    <S.CustomModal open onClose={handleDiscardChange} closeOnDimmerClick={false} className="evf-add-recipe-modal">
      <Modal.Header className="modal-recipe-header">
        <S.HeaderWrapper>
          <S.HeaderTitle>Create Recipe</S.HeaderTitle>
          <CloseButton className="close-button" onClick={handleDiscardChange}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <S.ContentLeft>
            <S.Title>cover image</S.Title>
            <S.UploadWrapper>
              <Thumbnail
                localUrl={localUrl}
                setLocalUrl={setLocalUrl}
                isLoading={loadingUpload}
                setIsLoading={setLoadingUpload}
                src={formData.coverImage}
                onSelect={handleSelect}
              />
            </S.UploadWrapper>
            {!isChangeRecipeDefaultImage && <S.Hint>Default image in use. Click to change image</S.Hint>}
          </S.ContentLeft>
          <S.Form onSubmit={handleSubmit}>
            <S.InputName
              label="Recipe Name"
              placeholder="Name your recipe"
              name="name"
              required
              isRequiredStar
              isHideErrorMessage
              value={formData.name}
              onChange={handleChange}
              maxLength={90}
              inputProps={{ maxLength: 90 }}
            />
            <CustomOwnerShip
              userId={get(user, '_id', '')}
              ownerValue={pick(formData, ['owner'])}
              shareValue={pick(formData, ['share'])}
              onSetShareValue={handleShareValue}
              onSetOwnerValue={handleOwnerValue}
              hasSpacing
              hasYou
              isEditAble={
                get(formData, 'share', null) === TEAM_SHARE_NOOWNER ||
                get(formData, 'owner', null) === get(user, '_id', '')
              }
            />
          </S.Form>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.Footer>
          <S.Button disabled={!nameTrim || loadingUpload || loadingAdd || isLoadingCreate} onClick={handleSubmit}>
            Create
          </S.Button>
        </S.Footer>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes, generalSettings },
  } = state;

  return {
    listCoverImage: get(recipes, 'coverBackground.list', []),
    loadingAdd: get(recipes, 'loading', false),
    isChangeRecipeDefaultImage: get(generalSettings, 'is_change_recipe_default_image', ''),
  };
};

const mapDispatchToProps = dispatch => ({
  selectedCoverBackground: bindActionCreators(selectedCoverBackground, dispatch),
  getRecipeBackgroundLibraries: bindActionCreators(getRecipeBackgroundLibraries, dispatch),
  resetBackgroundLibrary: bindActionCreators(resetBackgroundLibrary, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updateDataDetailRecipe: bindActionCreators(updateDataDetailRecipe, dispatch),
  getUnitType: bindActionCreators(getUnitType, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewRecipe);
