import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BillHistory from './component';
import { toggleModal } from 'actions/modal';

const mapState = (state) => {
  const {
    rootReducer: {
      billing: {
        paymentInfo: { billing_contact },
      },
    },
  } = state;

  return { data: billing_contact };
};

const mapDispatch = (dispatch) => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatch)(BillHistory);
