import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Modal, Button, Image } from 'semantic-ui-react';
import { classToPlain } from 'class-transformer';
import diff from 'deep-diff';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

// components
import Datetime from 'shared/DatePicker';
import GeneralButton from 'shared/GeneralButton';
import { ErrorMessage } from 'shared/FormControl';
import FitInchUnit from 'components/BodyMetricProgressNew/AddResultPopup/FitInchUnit';
import { CustomConfirmModal } from 'components/BodyMetricChartNew/components/ChartSettings/style';

// assets
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down_thin.svg';
import { ReactComponent as ArrowDownInputIcon } from 'assets/icons/arrow-down-input.svg';
import { ReactComponent as ArrowUpInputIcon } from 'assets/icons/arrow-up-input.svg';
import { ReactComponent as EmptyMetricIcon } from 'assets/icons/empty_add_metric_group.svg';

// constants and utils
import { SPECIAL_METRICS_CODE } from 'components/BodyMetricChartNew/constants';
import { CDN_URL, METRIC_UNIT_ERRORS } from 'constants/commonData';
import {
  convertFtAndInchToFt,
  processMetricResultValue,
  getTimeFromString,
  formatInputTime,
} from 'utils/commonFunction';

import './styles.scss';
import DateTimeInput, { DATETIME_ERROR_KEYS } from './DateTimeInput';

const format = 'MMM D, YYYY';

export const END_TIME_ERRORS = {
  [DATETIME_ERROR_KEYS.START_TIME_UNFILLED]: 'Start time should be filled',
  [DATETIME_ERROR_KEYS.END_TIME_UNFILLED]: 'End time should be filled',
  [DATETIME_ERROR_KEYS.START_TIME_GREATER_END_TIME]: 'End time must be greater than start time',
  [DATETIME_ERROR_KEYS.OVER_24_HOURS]: 'Sleep time should be less than 24 hours',
  [DATETIME_ERROR_KEYS.OVER_CURRENT_TIME]: 'Time should be less than current time',
};

export default class BodyMetricUpdateAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bodymetricTypes: this.getMetricFromProps(),
      originBodyMetrics: this.getMetricFromProps(),
      date: moment(),
      showPicker: false,
      errors: {},
      focusField: {},
    };

    this.isSubmitting = false;

    this.timeMetricInputRefs = {};
    this.deviceTz = moment.tz.guess();
  }

  onToggleCalendar = () => {
    this.setState(s => ({ showPicker: !s.showPicker }));
  };

  getMetricFromProps() {
    const { bodymetricTypes, metricsOfGroup, bodymetricAllSettings, isConnected3rdParty } = this.props;
    const list = metricsOfGroup || bodymetricTypes || [];

    return list
      .filter(item => {
        // if (isConnected3rdParty && item.unique_code === SPECIAL_METRICS_CODE.SLEEP) {
        //   return false;
        // }
        return !!item.can_update_manual;
      })
      .map(item => {
        const { unit: currentUnit, category, name, _id, unique_code } = item;
        const metricSetting = bodymetricAllSettings.find(it => it.metric_code === unique_code);
        const finalUnit = _.get(metricSetting, 'unit') || currentUnit || _.get(category, 'default_unit') || {};
        const isSleep = unique_code === SPECIAL_METRICS_CODE.SLEEP;

        return {
          name,
          value: isSleep
            ? {
                start: null,
                end: null,
              }
            : '',
          ft: '',
          inch: '',
          _id,
          unique_code,
          unit: finalUnit,
        };
      });
  }

  handleInputChange = (value, _id) => {
    const { bodymetricTypes, errors } = this.state;

    if (isNaN(value)) return;

    const metric = bodymetricTypes.find(item => item._id === _id);

    const processedValue = processMetricResultValue(value, metric.unique_code);

    if ((+processedValue > 0 || processedValue === '') && errors[_id]) {
      delete errors[_id];
    }

    const newMetrics = bodymetricTypes.map(item => {
      if (item._id === _id) {
        return {
          ...item,
          value: processedValue,
        };
      }
      return item;
    });

    this.setState({ errors, bodymetricTypes: newMetrics });
  };

  handleBlurFtInInput = mId => () => {
    const { errors, bodymetricTypes } = this.state;
    const errorIdFtInch = `${mId}-ft-inch`;
    const ftMetric = bodymetricTypes.find(item => item._id === mId);

    if (!(+ftMetric.ft > 0 || +ftMetric.inch > 0) && (ftMetric.ft !== '' || ftMetric.inch !== '')) {
      errors[errorIdFtInch] = METRIC_UNIT_ERRORS.VALUE;
    }

    this.setState(s => ({ ...s, errors: { ...s.errors, ...errors } }));
  };

  handleChangeFtInValue = mId => (key, value) => {
    const { errors, bodymetricTypes } = this.state;
    const newMetrics = bodymetricTypes.map(item => {
      if (item._id === mId) {
        return { ...item, [key]: value };
      }
      return item;
    });

    const ftMetric = bodymetricTypes.find(item => item._id === mId);

    const errorId = `${mId}-ft-inch`;

    const shouldDeleteError = secondary =>
      ftMetric[secondary] > 0 || value > 0 || (ftMetric[secondary] === '' && value === '');

    if (shouldDeleteError('inch') || shouldDeleteError('ft')) {
      delete errors[errorId];
    }

    this.setState({ bodymetricTypes: newMetrics, errors: { ...errors } });
  };

  handleBlurValue = mId => event => {
    const { value } = event.target;
    if (isNaN(value) || value === '') return;
    const { errors, bodymetricTypes } = this.state;
    const metric = bodymetricTypes.find(item => {
      return item._id === mId;
    });
    const validatedValue = processMetricResultValue(value, metric.unique_code);

    if (+validatedValue === 0) {
      errors[mId] = METRIC_UNIT_ERRORS.VALUE;
    }
    const newMetrics = bodymetricTypes.map(item => {
      if (item._id === mId) {
        return { ...item, value: validatedValue };
      }
      return item;
    });

    this.setState({ bodymetricTypes: newMetrics, errors: { ...errors } });
  };

  handleArrowInput = (mid, isIncrease) => {
    this.setState(s => ({
      ...s,
      bodymetricTypes: s.bodymetricTypes.map(item => {
        if (item._id === mid) {
          if (item.value === '' && !isIncrease) {
            return item;
          }
          return {
            ...item,
            value: Number(item.value) + (isIncrease ? 1 : -1) || '',
          };
        }
        return item;
      }),
    }));
  };

  renderArrowInput = (mId, title) => {
    const titleW = title === '%' ? 10 : title.length * 7;
    return (
      <div className="arrow-inputs" style={{ right: titleW + 16 }}>
        <span onClick={() => this.handleArrowInput(mId, true)} className="arrow-input up">
          <ArrowUpInputIcon />
        </span>
        <span onClick={() => this.handleArrowInput(mId, false)} className="arrow-input down">
          <ArrowDownInputIcon />
        </span>
      </div>
    );
  };

  handleChangeLabelTime = ({ inputTime, id, unitTitle }) => {
    const value = `${inputTime}`;
    const labelUnit = document.getElementById(`time-metric-unit-${id}`);
    if (unitTitle === 'sec') {
      if (value.length >= 3) {
        labelUnit.innerText = 'min';
      } else if (value.length < 3 && labelUnit.innerText === 'min') {
        labelUnit.innerText = 'sec';
      }
    }

    if (unitTitle === 'min') {
      if (value.length >= 3) {
        labelUnit.innerText = 'h';
      } else if (value.length < 3 && labelUnit.innerText === 'h') {
        labelUnit.innerText = 'min';
      }
    }
  };

  handleChangeTimeMetric = metric => () => {
    const {
      _id,
      unit: { title: unitTitle },
    } = metric;

    const inputRef = this.timeMetricInputRefs[_id];
    if (!inputRef) return;

    const value = inputRef.value.replace(/\D/g, '');
    if (_.isEmpty(value)) {
      inputRef.value = '';
      this.handleInputChange('', _id);
      return;
    }

    const validValue = processMetricResultValue(Number(value), unitTitle);
    const format = formatInputTime(validValue);
    this.handleChangeLabelTime({ inputTime: validValue, id: _id, unitTitle });

    const timeFormat = format || value;
    const timeValue = getTimeFromString(timeFormat);

    inputRef.value = timeFormat;
    this.handleInputChange(timeValue, _id);
  };

  renderTimeMetricInput = (metric = {}) => {
    const { errors } = this.state;
    const {
      _id,
      unit: { title: unitTitle },
    } = metric;

    return (
      <div className="input-wrap">
        <div className="input-content-wrap">
          <input
            ref={ref => (this.timeMetricInputRefs[_id] = ref)}
            type="text"
            onChange={this.handleChangeTimeMetric(metric)}
            onBlur={this.handleBlurValue(_id)}
            className={classNames('metric-value-input time-metric', { 'input-error': !!errors[_id] })}
          />
          <div className={classNames('unit-wrapper', { 'empty-title': !unitTitle })}>
            <span className="unit" id={`time-metric-unit-${_id}`}>
              {unitTitle}
            </span>
          </div>
        </div>
        {!!errors[_id] && <ErrorMessage className="value-error">{errors[_id]}</ErrorMessage>}
      </div>
    );
  };

  handleBlurDateTime = (key, item) => {
    const { errors } = this.state;
    const {
      value: { start, end },
      _id,
    } = item || {};

    let endError = null;
    // validate time value
    if (start && end) {
      if (end.diff(start, 'm') > 24 * 60) {
        endError = DATETIME_ERROR_KEYS.OVER_24_HOURS;
      } else if (start.unix() >= end.unix()) {
        endError = DATETIME_ERROR_KEYS.START_TIME_GREATER_END_TIME;
      }
    }

    const endId = `end_${_id}`;
    if (endError) {
      errors[endId] = endError;
    } else if (!endError && start && end) {
      delete errors[endId];
    }
    this.setState(s => ({ ...s, errors: { ...s.errors, ...errors } }));
  };

  handleChangeDateTime = (key, value) => {
    const [field, mId] = key.split('_');
    let itemChanged = null;
    const newMetrics = this.state.bodymetricTypes.map(item => {
      if (item._id === mId) {
        itemChanged = {
          ...item,
          value: { ...item.value, [field]: value },
        };
        return itemChanged;
      }
      return item;
    });

    const { errors } = this.state;
    if (value) {
      if (value.unix() > moment().unix()) {
        errors[key] = DATETIME_ERROR_KEYS.OVER_CURRENT_TIME;
      } else {
        delete errors[key];
      }
    }

    this.setState(
      s => ({
        ...s,
        bodymetricTypes: newMetrics,
        errors: { ...s.errors, ...errors },
      }),
      () => this.handleBlurDateTime(key, itemChanged),
    );
  };

  handleDateTimeError = (key, msg) => {
    // key: format: start|end_metricID
    this.setState(s => ({ ...s, errors: { ...s.errors, [key]: msg } }));
  };

  renderSleepMetric = item => {
    const { errors } = this.state;
    const { _id, name } = item;
    const startKey = `start_${_id}`;
    const endKey = `end_${_id}`;

    // error message start input
    let startInputErrorMessage = '';
    if (errors[startKey]) {
      switch (errors[startKey]) {
        case DATETIME_ERROR_KEYS.OVER_CURRENT_TIME: {
          startInputErrorMessage = END_TIME_ERRORS[DATETIME_ERROR_KEYS.OVER_CURRENT_TIME];
          break;
        }
        case DATETIME_ERROR_KEYS.INVALID_TIME_FORMAT: {
          startInputErrorMessage = METRIC_UNIT_ERRORS.TIME;
          break;
        }
        default: {
          startInputErrorMessage = END_TIME_ERRORS[DATETIME_ERROR_KEYS.START_TIME_UNFILLED];
          break;
        }
      }
    }

    // error message end input
    let endInputErrorMessage = '';
    if (errors[endKey]) {
      switch (errors[endKey]) {
        case DATETIME_ERROR_KEYS.END_TIME_UNFILLED: {
          endInputErrorMessage = END_TIME_ERRORS[DATETIME_ERROR_KEYS.END_TIME_UNFILLED];
          break;
        }
        case DATETIME_ERROR_KEYS.INVALID_TIME_FORMAT: {
          endInputErrorMessage = METRIC_UNIT_ERRORS.TIME;
          break;
        }
        default: {
          endInputErrorMessage = errors[endKey] ? END_TIME_ERRORS[errors[endKey]] : null;
          break;
        }
      }
    }

    return (
      <div className="input-container" key={_id}>
        <div className="type">{name}</div>
        <div className="input-wrap sleep-inputs">
          <div className="sleep-time-item">
            <span className={classNames('sleep-time-label', { 'error-label': !!errors[startKey] })}>Start</span>
            <DateTimeInput
              isAM={false}
              date={moment().subtract(1, 'd')}
              inputId={startKey}
              onError={this.handleDateTimeError}
              onBlur={key => this.handleBlurDateTime(key, item)}
              onChangeValue={this.handleChangeDateTime}
              errorMsg={startInputErrorMessage}
            />
          </div>
          <div className="sleep-time-item">
            <span className={classNames('sleep-time-label', { 'error-label': !!errors[endKey] })}>End</span>
            <DateTimeInput
              isAM={true}
              date={moment()}
              inputId={endKey}
              onError={this.handleDateTimeError}
              onBlur={key => this.handleBlurDateTime(key, item)}
              onChangeValue={this.handleChangeDateTime}
              errorMsg={endInputErrorMessage}
            />
          </div>
        </div>
      </div>
    );
  };

  renderMetricItem = item => {
    const { errors } = this.state;
    const {
      _id,
      name,
      value,
      ft,
      inch,
      unit: { title: unitTitle },
      unique_code,
    } = item;

    if (unique_code === SPECIAL_METRICS_CODE.SLEEP) {
      return this.renderSleepMetric(item);
    }

    const isTimeMetric = unitTitle === 'min' || unitTitle === 'sec';
    const ftID = `${_id}-ft-inch`;

    return (
      <div className="input-container" key={_id}>
        <div className="type">{name}</div>
        {unitTitle === 'ft' ? (
          <div className="ft-in-wrap">
            <FitInchUnit
              inchValue={inch}
              fitValue={ft}
              showArrow
              onChange={this.handleChangeFtInValue(_id)}
              hasError={!!errors[ftID]}
              onBlur={this.handleBlurFtInInput(_id)}
              autoFocus={false}
              className="ft-in-input"
              idMetricType={_id}
              isMulti
            />
            {!!errors[ftID] && (
              <div className="ft-in-error">
                {!!errors[ftID] && <ErrorMessage className="value-error in-error">{errors[ftID]}</ErrorMessage>}
              </div>
            )}
          </div>
        ) : isTimeMetric ? (
          this.renderTimeMetricInput(item)
        ) : (
          <div className="input-wrap">
            <div className="input-content-wrap">
              <NumberFormat
                onValueChange={({ value: newVal }) => this.handleInputChange(newVal, _id)}
                value={value}
                allowNegative={false}
                decimalSeparator="."
                decimalScale={2}
                onBlur={this.handleBlurValue(_id)}
                className={classNames('metric-value-input', { 'input-error': !!errors[_id] })}
              />
              <div className={classNames('unit-wrapper', { 'empty-title': !unitTitle })}>
                {this.renderArrowInput(_id, unitTitle || '')}
                <span className="unit">{unitTitle}</span>
              </div>
            </div>
            {!!errors[_id] && <ErrorMessage className="value-error">{errors[_id]}</ErrorMessage>}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { bodymetricTypes, errors } = this.state;
    const { isModalOpen, metricsOfGroup, selectedGroup } = this.props;
    const isGroupMetric = metricsOfGroup !== undefined;
    const isDefaultGroup = selectedGroup.is_default;
    const isListEmpty = bodymetricTypes.length <= 0;
    return (
      <Modal
        size="tiny"
        open={isModalOpen}
        onClose={this.handleCloseAction}
        closeOnDimmerClick={false}
        className="body-metric-update-all-modal"
      >
        <Modal.Header>
          <Button onClick={this.handleCloseAction} className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="form-header">
            <div className="title">{isDefaultGroup || !isGroupMetric ? 'Update All Metrics' : 'Update Results'}</div>
            {!isListEmpty && this.renderDateSelect()}
          </div>
        </Modal.Header>
        <Modal.Content>
          {!isListEmpty ? (
            <div className="list-input">{_.map(bodymetricTypes, this.renderMetricItem)}</div>
          ) : (
            <div className="list-empty">
              <EmptyMetricIcon />
              <span>No Metrics Available for Manual Update.</span>
            </div>
          )}
        </Modal.Content>
        {!isListEmpty && (
          <Modal.Actions>
            <GeneralButton
              className="metric-save-button"
              onClick={this.handleSaveBodyMetric}
              disabled={Object.keys(errors).length > 0}
            >
              Save
            </GeneralButton>
          </Modal.Actions>
        )}
      </Modal>
    );
  }

  renderDateSelect = () => {
    const { date, showPicker } = this.state;

    let dateText = date.format(date.isSame(moment(), 'year') ? 'MMM DD' : format);

    if (date.isSame(moment(), 'day')) {
      dateText = 'Today';
    }

    return (
      <div className={classNames('date-input-container', { 'open-picker': showPicker })}>
        <Datetime
          value={date}
          isShowArrowBtn={false}
          renderCustomInput={
            <div className="date-input-field">
              <CalendarIcon width={11} />
              {dateText}
              <ArrowDownIcon />
            </div>
          }
          maxDate={new Date()}
          timeFormat={false}
          onChange={momentData => {
            this.setState({ date: momentData, showPicker: false });
          }}
          clientTimezone={this.deviceTz}
          hideNextMonth
        />
      </div>
    );
  };

  handleCloseAction = () => {
    const { originBodyMetrics, bodymetricTypes } = this.state;
    const { toggleConfirmModal, toggleModal } = this.props;
    const origin = classToPlain(originBodyMetrics);
    const current = classToPlain(bodymetricTypes);
    const changes = diff(origin, current);
    if (changes) {
      toggleConfirmModal(
        true,
        <CustomConfirmModal
          onConfirm={this.onCloseCurrentPopup}
          onDeny={this.onDenyClosingPopup}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          confirmButtonTitle="Discard Changes"
          title="Discard Changes?"
          noBorder
          hasCloseIcon
          className="discard-all-results"
          isPressEsc
          isCloseOnDimmer={false}
        />,
      );
    } else {
      toggleModal(false);
    }
  };

  onCloseCurrentPopup = () => {
    this.props.toggleModal(false);
  };

  onDenyClosingPopup = () => {
    this.props.toggleModal(true);
  };

  validatedTimeSleep = sleepEntry => {
    const { errors } = this.state;
    const { start: startMM, end: endMM } = sleepEntry.value;
    const today = moment().unix();
    const startId = `start_${sleepEntry._id}`;
    const endId = `end_${sleepEntry._id}`;
    const newErrors = { ...errors };
    let isValid = true;

    if (!startMM) {
      newErrors[startId] = DATETIME_ERROR_KEYS.START_TIME_UNFILLED;
      isValid = false;
    }

    if (!endMM) {
      newErrors[endId] = DATETIME_ERROR_KEYS.END_TIME_UNFILLED;
      isValid = false;
    }

    if (!newErrors[startId] && startMM && startMM.unix() > today) {
      isValid = false;
      newErrors[startId] = DATETIME_ERROR_KEYS.OVER_CURRENT_TIME;
    }

    if (!newErrors[endId] && endMM && endMM.unix() > today) {
      isValid = false;
      newErrors[endId] = DATETIME_ERROR_KEYS.OVER_CURRENT_TIME;
    }

    if (!isValid) {
      this.setState(s => ({ ...s, errors: newErrors }));
    }
    return isValid;
  };

  handleSaveBodyMetric = async e => {
    e.preventDefault();
    const {
      clientId,
      metricsOfGroup,
      updateSuccessCallback,
      addBodyMetricEntries,
      addHeartRateEntry,
      addSleepEntry,
    } = this.props;
    const { date } = this.state;

    if (!clientId || this.isSubmitting) {
      return;
    }

    const updatedTypes = _.filter(this.state.bodymetricTypes, item => {
      const { value, unique_code, ft, inch } = item;
      if (unique_code === SPECIAL_METRICS_CODE.SLEEP) {
        return !!value.start || !!value.end;
      }
      return value || ft || inch;
    });

    if (!updatedTypes.length) {
      return this.props.toggleModal(false);
    }

    const dateString = date.format();

    const updatedTypesNoSpecial = updatedTypes.filter(
      it => ![SPECIAL_METRICS_CODE.SLEEP, SPECIAL_METRICS_CODE.HEART_RATE].includes((it || {}).unique_code),
    );

    const otherMetrics = _.map(updatedTypesNoSpecial, item => {
      const { _id, unit, value, ft, inch } = item;
      const isFtUnit = unit.title === 'ft';
      const finalValue = isFtUnit ? convertFtAndInchToFt(ft, inch).toFixed(2) : value;
      return {
        type: _id,
        value: finalValue,
        unit: unit._id,
        date: dateString,
      };
    });

    const updatedHeartRate = updatedTypes.find(it => (it || {}).unique_code === SPECIAL_METRICS_CODE.HEART_RATE);
    const sleepEntry = updatedTypes.find(it => (it || {}).unique_code === SPECIAL_METRICS_CODE.SLEEP);

    try {
      const allPromise = [];
      if (sleepEntry) {
        const isValid = this.validatedTimeSleep(sleepEntry);
        if (!isValid) {
          return;
        }

        const { value, unique_code } = sleepEntry;
        const param = {
          client: clientId,
          unique_code,
          start_time: value.start.format('YYYY-MM-DD HH:mm'),
          end_time: value.end.format('YYYY-MM-DD HH:mm'),
        };
        allPromise.push(addSleepEntry(param));
      }
      if (updatedHeartRate) {
        const { value, unique_code = '', unit } = updatedHeartRate;
        const heartRateParams = {
          date: dateString,
          unique_code,
          value: +value,
          unit,
          client: clientId,
          timezone: moment.tz.guess(),
        };
        allPromise.push(addHeartRateEntry(heartRateParams));
      }

      if (otherMetrics.length > 0) {
        allPromise.push(addBodyMetricEntries({ client: clientId, items: otherMetrics }));
      }

      this.isSubmitting = true;

      if (allPromise.length > 0) {
        await Promise.all(allPromise);

        if (metricsOfGroup) {
          toast('Successfully updated.');
        }
        if (typeof updateSuccessCallback === 'function') {
          updateSuccessCallback();
        }
      }
      this.props.toggleModal(false);
    } catch (error) {
      console.error(error);
    }

    this.isSubmitting = false;
  };
}
