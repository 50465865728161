import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import * as S from './style';
import ForumHeader from '../ForumHeader';
import CreatePostForm from './components/CreatePostForm';
import DiscussionFeed from './components/DiscussionFeed';
import StickyHeader from '../ForumHeader/StickyHeader';

import PostDetail from '../ForumTimeline/components/PostDetail';
import { ReactComponent as EmptyPostsSvg } from 'assets/images/empty_posts.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_black.svg';
import Leaderboard from './components/Leaderboard';
import SchedulePost from './components/SchedulePost';

export default function ForumTimeline(props) {
  const {
    match: {
      params: { postId },
    },
    permission,
    firstLoadPosts,
    posts,
    postsLoading,
  } = props;

  const scrollTopRef = useRef();
  const container = useRef();
  const lastScrollTop = useRef(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResizeScreen);
  }, []);

  useEffect(() => {
    return () => {
      props.toggleModal(false);
    };
  }, []);

  useEffect(() => {
    const { getGroupDetail, getListPost, getListUser, getConnectedLeaderboard, getListSchedulePost, posts } = props;
    getGroupDetail();
    !posts.length && getListPost();
    getListUser();
    getConnectedLeaderboard();
    getListSchedulePost && getListSchedulePost();
  }, [props.match.params.id || props.match.params.forumId]);

  useEffect(() => {
    let groupID = get(props, 'location.pathname', '');
    const isUnread = get(props, 'group.isUnread', false);

    groupID = groupID.replace('/home/forums/', '').split('/').shift();
    if (isUnread) {
      props.markReadForumIndicator({ groupId: groupID });
    }
  }, []);

  useEffect(() => {
    if (postId) {
      props.toggleModal(true, <PostDetail {...props} />);
    }
  }, [postId]);

  const handleResizeScreen = () => {
    props.toggleSideBar(true);
  };

  const handleScroll = event => {
    event.persist();
    const { scrollTop, id } = event.target;
    handleScrollStartAndEnd();

    // TODO - skip scroll for page: when scroll mentions list
    if (id.includes('mentions-list')) return;

    if (scrollTop > 396) setSticky(true);
    if (scrollTop < 396) setSticky(false);

    if (scrollTopRef.current) {
      if (scrollTop > 324 || (isSticky && scrollTop > 10)) {
        scrollTopRef.current.classList.add('show');
      } else {
        scrollTopRef.current.classList.remove('show');
      }
    }

    lastScrollTop.current = scrollTop;
    handleScrollEnd(event);
  };

  const handleScrollTop = () => {
    container.current.scrollTop = 0;
  };

  const handleScrollStartAndEnd = () => {
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false);
    }, 500);
  };

  const handleScrollFromTop = (offset, callback) => {
    if (container.current) {
      container.current.scrollTop = offset;
    }
    if (callback) callback();
  };

  const getListPostDebounce = debounce(props.getListPost, 250);
  const handleScrollDebounce = debounce(handleScroll, 250);

  const handleScrollEnd = event => {
    const targetId = get(event, 'target.id');
    const scrollHeight = get(event, 'target.scrollHeight');
    const scrollTop = get(event, 'target.scrollTop');
    const clientHeight = get(event, 'target.clientHeight');
    const currentHeight = Math.floor(scrollHeight - scrollTop);
    const isAtBottom = currentHeight <= clientHeight;
    const shouldLoadMore = posts.length && targetId === 'timeLine' && isAtBottom && !postsLoading;

    if (shouldLoadMore) {
      getListPostDebounce();
    }
  };

  return (
    <S.Wrapper
      onScroll={event => {
        event.persist();
        handleScrollDebounce.call(null, event);
      }}
      ref={container}
      id="timeLine"
    >
      <ForumHeader {...props} />
      {isSticky && <StickyHeader isSticky={isSticky} {...props} />}

      <S.DiscussionTimeline>
        <S.PostFeed>
          <CreatePostForm {...props} />

          {/* <FeedFilters onSetFeedFilter={setFeedFilter} /> */}
          {!props.posts.length && !firstLoadPosts && (
            <S.EmptyFeed>
              <EmptyPostsSvg />
              <div className="title">No posts yet</div>
              <div className="subtitle">Be the first to post! Welcome new members or share about your day.</div>
            </S.EmptyFeed>
          )}
          {props.posts.length > 0 && (
            <DiscussionFeed {...props} onScrollFromTop={handleScrollFromTop} isScrolling={isScrolling} />
          )}
        </S.PostFeed>
        <S.SideBar>
          <Leaderboard permission={permission} />
          <SchedulePost permission={permission} />
          {/* TODO */}
          {/* <CommunityActivity /> */}
        </S.SideBar>
      </S.DiscussionTimeline>
      <S.ScrollTop ref={scrollTopRef} onClick={handleScrollTop}>
        <ArrowUpIcon />
      </S.ScrollTop>
    </S.Wrapper>
  );
}
