import styled, { css } from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';
import PlusIcon from 'assets/icons/add-asset-plus-icon.svg';
import PlusHoveredIcon from 'assets/icons/add-asset-plus-hover-icon.svg';

export const Wrapper = styled.div``;

export const ConfirmModal = styled(ConfirmModalElement)`
  .confirm-content-header {
    padding-top: 30px !important;
  }
`;

export const AssetContainer = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 20px 15px;
  margin-bottom: 25px;
  min-height: 104px;
`;

export const AssetHeader = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #202353;
  margin-bottom: 20px;
`;

export const StartWeek = styled.h4`
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #202353;
  opacity: 0.4;
  margin-bottom: 23px;
`;

export const FirstAddAsset = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  margin-top: -13px;
  ${props =>
    props.isFirstTime &&
    css`
      padding: 15px;
      background: #fafbfc;
      border: 1px dashed #c8cfe0;
    `}
`;

export const TimeLineWrapper = styled.div``;

export const AssetDetailContainer = styled.div`
  border-left: 1px dashed rgb(196, 196, 196);
  flex: 1;
  padding-left: 13px;
  margin-left: 13px;
  position: relative;
  padding-bottom: 17px;
  &:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    background: #e2e5ec;
    top: -4px;
    left: -5px;
    border-radius: 50%;
    z-index: 1;
  }
`;

export const TimeLine = styled.div`
  display: flex;
  &:last-child {
    ${AssetDetailContainer} {
      border-left: 1px solid transparent;
    }
  }
  ${props =>
    props.isFirstTime &&
    css`
      &:last-child {
        ${AssetDetailContainer} {
          padding-bottom: 0;
          &:after {
            content: '';
            position: absolute;
            height: 89px;
            top: 0;
            left: -2px;
            width: 2px;
            border-left: 2px solid transparent;
            border-image: url(${CDN_URL}/images/border_grayout.svg) 1 stretch;
          }
        }
      }
    `}
`;

export const DateTime = styled.div`
  width: 75px;
  height: 23px;
  background: #f3f5f8;
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #202353;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -13px;
`;

export const FirstAddAssetTitle = styled.h6`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  margin: 0;
`;

export const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin-top: 10px;
`;

export const OptionsWrapperShortItem = styled.div`
  border: 1px solid #dadfea;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
`;

export const ToggleAddAsset = styled.div`
  width: 35px;
  height: 35px;
  background-color: #e8e9fb;
  background-image: url(${PlusIcon});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #5559ff;
    background-image: url(${PlusHoveredIcon});
  }
  transform: translateX(71px);
`;

export const NoAssetsContainer = styled.div`
  margin: auto;
  width: fit-content;
  p {
    margin-top: 9px;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #202353;
    opacity: 0.3;
  }
`;
