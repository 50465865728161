import React from 'react';
import { BACKGROUND_LIST } from 'constants/habitData';
import { Emoji } from 'emoji-picker-react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import { TYPE_FREQUENCY } from '../../../../constants';
import { addPrefix, formatDate } from '../../../../helps';
import * as S from './style';

const Progress = ({
  strokeWidth = 6,
  circleRatio = 0.75,
  dataCurrentDay,
  goalValue,
  unitName,
  unified,
  type,
  color,
  clientTimezone,
}) => {
  const today = formatDate(new Date().toLocaleString('en-US', { timeZone: clientTimezone }));
  const compareDay = moment(today).isSame(dataCurrentDay.dayCompare);
  const getWeekOfYear = DateTime.now().toFormat('kkkk-WW');
  const getLastWeekOfYear = `${moment().subtract(7, 'd').year()}-${addPrefix(moment().subtract(7, 'd').week())}`;
  const compareWeek = getWeekOfYear === dataCurrentDay.week_of_year;
  const compareLastWeek = getLastWeekOfYear === dataCurrentDay.week_of_year;

  const borderColor = _.find(BACKGROUND_LIST, function (o) {
    return o.border === color;
  });

  let unitFormat = unitName;
  if (goalValue == 1) {
    unitFormat = unitName.replace(/s$/, '');
  }
  unitFormat = `${goalValue} ${unitFormat}`;

  return (
    <S.Progress>
      {type === TYPE_FREQUENCY.PER_DAY ? (
        <S.Today hide={!compareDay}>Today</S.Today>
      ) : (
        <S.Today>
          {!dataCurrentDay.week_of_year || compareWeek
            ? 'This week'
            : compareLastWeek
            ? 'Last week'
            : `Week of ${dataCurrentDay.startOfWeek}`}
        </S.Today>
      )}
      {goalValue === 1 && type === TYPE_FREQUENCY.PER_DAY ? (
        <>
          <S.ProgressBarGoal
            borderColor={
              dataCurrentDay.value > 0 ? `${_.get(borderColor, 'border', 'transparent')}` : 'rgba(244, 244, 244, 1)'
            }
          >
            <S.Icon bgColor={`${_.get(borderColor, 'background', 'transparent')}`}>
              <Emoji unified={unified} size="42" />
            </S.Icon>
          </S.ProgressBarGoal>
          <S.TimeGoal>{dataCurrentDay.day}</S.TimeGoal>
        </>
      ) : (
        <S.ProgressBar>
          <CircularProgressbar
            value={dataCurrentDay.percent_completed || 0}
            strokeWidth={strokeWidth}
            circleRatio={circleRatio}
          />
          <S.Info>
            <p className="bold">{dataCurrentDay.value || 0}</p>
            <p>of {unitFormat}</p>
          </S.Info>
          <S.Time>{dataCurrentDay.day}</S.Time>
        </S.ProgressBar>
      )}
    </S.Progress>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
