import React from 'react';
import classNames from 'classnames';
import { CDN_URL } from 'constants/commonData';
import { Container } from './style';
import FileUpload from 'assets/icons/file_upload.svg';

export default ({ img, text, className, children, unknowFileType, ...otherProps }) => (
  <Container {...otherProps} className={classNames('drop-zone__input-container', className)}>
    {unknowFileType ? (
      <>
        <img src={FileUpload} className="icon" alt="" />
        <div className="text">Drag and drop your files here </div>
      </>
    ) : (
      <>
        <img src={img || `${CDN_URL}/images/drop_image.svg`} className="icon" alt="" />
        <div className="text">{text || 'Drop image or video here'}</div>
      </>
    )}

    {children}
  </Container>
);
