import Request from 'configs/request';
import { INITIAL_WORKING_COLLECTION_CATEGORY, ON_DEMAND_CATEGORY_STATUS } from 'constants/commonData';
import { cloneDeep, omit } from 'lodash';
import { toast } from 'react-toastify';
import { updateFormatCategoryRedux } from 'redux/workout-collection/actions';
import { omitEmptyRequestParams } from 'utils/commonFunction';

export const Types = {
  CATEGORY_GET_DETAIL_REQUEST: 'CATEGORY_GET_DETAIL_REQUEST',
  CATEGORY_GET_DETAIL_SUCCESS: 'CATEGORY_GET_DETAIL_SUCCESS',
  CATEGORY_ADD_WORKOUTS_REQUEST: 'CATEGORY_ADD_WORKOUTS_REQUEST',
  CATEGORY_REMOVE_WORKOUT_REQUEST: 'CATEGORY_REMOVE_WORKOUT_REQUEST',
  CATEGORY_REMOVE_WORKOUT_SUCCESS: 'CATEGORY_REMOVE_WORKOUT_SUCCESS',
  CATEGORY_ADD_WORKOUTS_SUCCESS: 'CATEGORY_ADD_WORKOUTS_SUCCESS',
  CATEGORY_ADD_WORKOUTS_FAIL: 'CATEGORY_ADD_WORKOUTS_FAIL',
  CATEGORY_GET_WORKOUTS_REQUEST: 'CATEGORY_GET_WORKOUTS_REQUEST',
  CATEGORY_GET_WORKOUTS_SUCCESS: 'CATEGORY_GET_WORKOUTS_SUCCESS',
  CATEGORY_RESET_STATE: 'CATEGORY_RESET_STATE',
  CATEGORY_DETAIL_SUBMIT_REQUEST: 'CATEGORY_DETAIL_SUBMIT_REQUEST',
  CATEGORY_DETAIL_SUBMIT_SUCCESS: 'CATEGORY_DETAIL_SUBMIT_SUCCESS',
  CATEGORY_DETAIL_UPDATE_REQUEST: 'CATEGORY_DETAIL_UPDATE_REQUEST',
  CATEGORY_DETAIL_UPDATE_SUCCESS: 'CATEGORY_DETAIL_UPDATE_SUCCESS',
  CATEGORY_DETAIL_ACTIVE_REQUEST: 'CATEGORY_DETAIL_ACTIVE_REQUEST',
  CATEGORY_DETAIL_DEACTIVE_REQUEST: 'CATEGORY_DETAIL_DEACTIVE_REQUEST',
  CATEGORY_DETAIL_ACTIVE_SUCCESS: 'CATEGORY_DETAIL_ACTIVE_SUCCESS',
  CATEGORY_DETAIL_DEACTIVE_SUCCESS: 'CATEGORY_DETAIL_DEACTIVE_SUCCESS',
  MOVE_ON_DEMAND_WORKOUT_REQUEST: 'MOVE_ON_DEMAND_WORKOUT_REQUEST',
  MOVE_ON_DEMAND_WORKOUT_SUCCESS: 'MOVE_ON_DEMAND_WORKOUT_SUCCESS',
  MOVE_ON_DEMAND_WORKOUT_TO_TOP_REQUEST: 'MOVE_ON_DEMAND_WORKOUT_TO_TOP_REQUEST',
  MOVE_ON_DEMAND_WORKOUT_TO_TOP_SUCCESS: 'MOVE_ON_DEMAND_WORKOUT_TO_TOP_SUCCESS',
  MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_REQUEST: 'MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_REQUEST',
  MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_SUCCESS: 'MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_SUCCESS',
  CATEGORY_CHANGE_QUERY_PARAMS: 'CATEGORY_CHANGE_QUERY_PARAMS',
  CATEGORY_WORKOUT_LOAD_MORE_REQUEST: 'CATEGORY_WORKOUT_LOAD_MORE_REQUEST',
  CATEGORY_WORKOUT_LOAD_MORE_SUCCESS: 'CATEGORY_WORKOUT_LOAD_MORE_SUCCESS',
  CATEGORY_WORKOUT_UPDATE_FORMAT_REQUEST: 'CATEGORY_WORKOUT_UPDATE_FORMAT_REQUEST',
  CATEGORY_WORKOUT_UPDATE_FORMAT_SUCCESS: 'CATEGORY_WORKOUT_UPDATE_FORMAT_SUCCESS',
};

export const getWorkoutCollectionCategoryDetail = id => {
  return (dispatch, getState) => {
    dispatch({ type: Types.CATEGORY_RESET_STATE });
    dispatch({ type: Types.CATEGORY_GET_DETAIL_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/on-demand-workout-categories/${id}`,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.CATEGORY_GET_DETAIL_SUCCESS, payload: { data } });
        },
      ),
    );
  };
};

export const addWorkouts = params => {
  return dispatch => {
    dispatch({ type: Types.CATEGORY_ADD_WORKOUTS_REQUEST });

    return dispatch(
      Request.post(
        { url: `/api/on-demand-workout-categories/${params.id}/workouts`, data: { workouts: params.workouts } },
        true,
        res => {
          // TODO
          setTimeout(() => {
            dispatch(getOnDemandWorkouts(params.id));
          }, 1000);
        },
        error => {
          dispatch({ type: Types.CATEGORY_ADD_WORKOUTS_FAIL, error });
        },
      ),
    );
  };
};

export const removeWorkout = params => {
  const { workoutId } = params;
  return dispatch => {
    dispatch({ type: Types.CATEGORY_REMOVE_WORKOUT_REQUEST });
    return dispatch(
      Request.delete(
        { url: `/api/on-demand-workout-categories/workouts/${workoutId}` },
        true,
        res => {
          dispatch({ type: Types.CATEGORY_REMOVE_WORKOUT_SUCCESS, payload: { data: workoutId } });
        },
        error => {},
      ),
    );
  };
};

export const getOnDemandWorkouts = (id, params) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { workoutCollectionCategory },
    } = getState();
    dispatch({ type: Types.CATEGORY_GET_WORKOUTS_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/on-demand-workout-categories/${id}/workouts`,
          params: {
            perPage: workoutCollectionCategory.query.perPage,
            ...omitEmptyRequestParams(params),
          },
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.CATEGORY_GET_WORKOUTS_SUCCESS, payload: { data } });
        },
      ),
    );
  };
};

export const getAssignedWorkouts = (id, params) => {
  return Request.get({
    url: `/api/on-demand-workout-categories/${id}/workouts`,
    params: {
      ...omitEmptyRequestParams(params),
    },
  });
};

export const resetCategory = () => {
  return dispatch => {
    dispatch({ type: Types.CATEGORY_RESET_STATE });
  };
};

export const getPreSignedUrl = data => {
  return dispatch => {
    dispatch({ type: Types.CATEGORY_DETAIL_SUBMIT_REQUEST });
    return dispatch(
      //TODO: replace presigned url when available

      Request.post({ url: '/api/file/gen-presigned-urls-on-demand-workout', data }, true, (response, { dispatch }) => {
        dispatch({ type: Types.CATEGORY_DETAIL_SUBMIT_SUCCESS });
      }),
    );
  };
};

export const updateCategoryDetail = (updateFields, categoryId) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.CATEGORY_DETAIL_UPDATE_REQUEST });
    return dispatch(
      Request.patch(
        {
          url: `/api/on-demand-workout-categories/${categoryId}`,
          data: updateFields,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({
            type: Types.CATEGORY_DETAIL_UPDATE_SUCCESS,
            payload: data ? { data: data } : { data: INITIAL_WORKING_COLLECTION_CATEGORY },
          });
          toast('This category has been updated successfully.', { autoClose: 3000 });
        },
      ),
    );
  };
};

export const toggleCategory = (categoryId, status) => {
  return dispatch => {
    const action = status === ON_DEMAND_CATEGORY_STATUS.ACTIVE ? 'deactive' : 'active';
    const isActive = status === ON_DEMAND_CATEGORY_STATUS.ACTIVE;
    if (isActive) {
      dispatch({ type: Types.CATEGORY_DETAIL_DEACTIVE_REQUEST });
    } else {
      dispatch({ type: Types.CATEGORY_DETAIL_ACTIVE_REQUEST });
    }
    return dispatch(
      Request.put(
        { url: `/api/on-demand-workout-categories/${categoryId}/${action}` },
        true,
        res => {
          const { data } = res.data;
          if (isActive) {
            dispatch({ type: Types.CATEGORY_DETAIL_DEACTIVE_SUCCESS, payload: { data } });
            toast('The Category has been deactivated.');
          } else {
            dispatch({ type: Types.CATEGORY_DETAIL_ACTIVE_SUCCESS, payload: { data } });
            toast('The Category has been activated');
          }
        },
        error => {
          // dispatch({ type: Types.WORKOUT_COLLECTION_GET_CLIENTS_FAIL, error });
        },
      ),
    );
  };
};

export const updateSettingFormat = (format, categoryId) => {
  return dispatch => {
    return dispatch(
      Request.patch(
        { url: `/api/on-demand-workout-categories/${categoryId}`, data: { format } },
        true,
        res => {
          dispatch({
            type: Types.CATEGORY_WORKOUT_UPDATE_FORMAT_SUCCESS,
            payload: { format, categoryId },
          });
          dispatch(updateFormatCategoryRedux(format, categoryId));
          toast('This category has been updated successfully.', { autoClose: 3000 });
        },
        error => {
          //
        },
      ),
    );
  };
};

export const moveOnDemandWorkout = (workoutId, destinationIndex, originalIndex, isDragging = false, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MOVE_ON_DEMAND_WORKOUT_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/on-demand-workout-categories/workouts/${workoutId}/rearrange`,
          data: {
            newIndex: destinationIndex,
          },
        },
        true,
        response => {
          dispatch({
            type: Types.MOVE_ON_DEMAND_WORKOUT_SUCCESS,
            payload: {
              workoutId,
              destinationIndex,
              originalIndex,
              isDragging,
            },
          });
          toast('This category has been updated successfully.', { autoClose: 3000 });
          callback && callback();
        },
      ),
    );
  };
};

export const moveOnDemandWorkoutToTop = (workoutId, originalIndex) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MOVE_ON_DEMAND_WORKOUT_TO_TOP_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/on-demand-workout-categories/workouts/${workoutId}/top`,
        },
        true,
        response => {
          dispatch({
            type: Types.MOVE_ON_DEMAND_WORKOUT_TO_TOP_SUCCESS,
            payload: {
              workoutId,
              originalIndex,
            },
          });
          toast('This category has been updated successfully.', { autoClose: 3000 });
        },
      ),
    );
  };
};

export const moveOnDemandWorkoutToBottom = (workoutId, originalIndex) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_REQUEST });
    return dispatch(
      Request.put(
        {
          url: `/api/on-demand-workout-categories/workouts/${workoutId}/bottom`,
        },
        true,
        response => {
          dispatch({
            type: Types.MOVE_ON_DEMAND_WORKOUT_TO_BOTTOM_SUCCESS,
            payload: {
              workoutId,
              originalIndex,
            },
          });
          toast('This category has been updated successfully.', { autoClose: 3000 });
        },
      ),
    );
  };
};

export const changeQueryParams = (id, params, refetch) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { workoutCollectionCategory },
    } = getState();

    const query = omit({ ...workoutCollectionCategory.query, ...params }, ['total', 'isEnd']);
    dispatch({ type: Types.CATEGORY_CHANGE_QUERY_PARAMS, payload: query });
    if (refetch) {
      dispatch(getOnDemandWorkouts(id, query));
    }
  };
};

export const loadMoreWorkouts = (id, lastWorkoutId) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.CATEGORY_WORKOUT_LOAD_MORE_REQUEST });
    const {
      rootReducer: {
        workoutCollectionCategory: { query },
      },
    } = getState();
    let queryParams = omit(cloneDeep(query), ['isEnd', 'total', 'isLoading']);
    queryParams.lastWorkoutId = lastWorkoutId;
    return dispatch(
      Request.get(
        { url: `/api/on-demand-workout-categories/${id}/workouts`, params: queryParams },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.CATEGORY_WORKOUT_LOAD_MORE_SUCCESS, payload: { data } });
        },
        error => {},
      ),
    );
  };
};
