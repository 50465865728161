import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import MetricSetting from 'shared/MetricSetting';
import { updateMetric, addMetric, deleteMetric, updateMultipleMetric } from 'redux/autoflow/settings/actions';

function Component(props) {
  const { metrics, unitCategories, autoflow } = props;

  return (
    <MetricSetting
      subTitle="Select the metrics you want all clients to use and track when participating in this Autoflow."
      originMetrics={metrics}
      unitCategories={unitCategories}
      onSave={items => props.updateMultipleMetric({ items, autoflowId: autoflow })}
      onClose={data => {
        if (typeof props.onClose === 'function') {
          props.onClose(data);
        } else {
          props.toggleModal(false);
        }
      }}
      onUpdate={metricData => props.updateMetric({ ...metricData, autoflowId: autoflow })}
      onAdd={metricData => props.addMetric({ ...metricData, autoflowId: autoflow })}
      onDelete={metricData => props.deleteMetric({ unique_code: metricData.unique_code, autoflowId: autoflow })}
    />
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { autoflow, unit } = rootReducer;
  const { common, settings } = autoflow;
  const { metrics } = settings;

  return {
    autoflow: _.get(common, 'workingAutoflow._id'),
    metrics: metrics || [],
    unitCategories: unit.unit_categories || [],
  };
};

const actionCreators = {
  toggleModal,
  toggleConfirmModal,
  updateMultipleMetric,
  updateMetric,
  addMetric,
  deleteMetric,
};

export default connect(mapState, actionCreators)(Component);
