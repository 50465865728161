export const replaceInnerHTMLBreakLine = elementHTML => {
  if (!elementHTML || !elementHTML.innerHTML) {
    return '';
  }

  const breakLineReg = new RegExp('<div><br></div>', 'g');
  const divBreakLineReg = new RegExp('<br></div><div>', 'g');
  const divDownLineReg = new RegExp('</div><div>', 'g');
  const divOpenReg = new RegExp('<div>', 'g');
  const divCloseReg = new RegExp('</div>', 'g');
  const firstBreakReg = new RegExp('^<br>', 'i');

  const innerHTML = elementHTML.innerHTML;
  const newHTML = innerHTML
    .replace(breakLineReg, '<br>')
    .replace(divBreakLineReg, '<br>')
    .replace(divDownLineReg, '<br>')
    .replace(divOpenReg, '<br>')
    .replace(divCloseReg, '')
    .replace(firstBreakReg, '');

  return newHTML;
};

export const getPlainText = htmlString => {
  try {
    let div = document.createElement('div');
    div.innerHTML = htmlString;
    div.innerHTML = replaceInnerHTMLBreakLine(div);
    document.body.appendChild(div);
    const text = div.innerText;
    document.body.removeChild(div);
    return text;
  } catch {
    return htmlString;
  }
};
