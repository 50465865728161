import styled from 'styled-components';
import AddButton from 'shared/AddButton';

export const MenuHeader = styled.div`
  width: 100%;
  padding: 7px 6px 10px 6px;
  .ui.input.search-input {
    > input {
      border-radius: 4px !important;
      width: 100%;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.21px;
      height: 30px;
      font-family: 'Open Sans';
    }
  }

  .ui.input.search-input {
    height: 30px;
  }
`;

export const MenuBody = styled.div`
  overflow-x: hidden;
  overflow-y: overlay;
  margin: 0 0 10px;
  height: 250px;

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
    li {
      padding: 6px 20px;
      .group-checkbox {
        width: 100%;
        display: block;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #111111;
      }

      .create-new-group-option {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5c58c0;
        cursor: pointer;
        padding: 0 10px;
        
        > img {
          margin-right: 12px;
        }
      }

      :hover {
        background-color: #edecfc;
      }

      .group-checkbox__title {
        display: flex;
        justify-content: flex-start;
        > div:first-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .clients {
          color: #99A1B1;
          margin-left: 10px;
        }
      }
    }
  }
`;

export const MenuFooter = styled.div`
  padding: 10px 15px 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eceff1;

  .footer_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ui.button.general-button {
    padding: 6px 25px 9px;
    border-radius: 4px;
    margin: 0;
    background-color: #5158cf;
    border: none;
    box-shadow: none !important;
    font-size: 11px;
    line-height: 11px;

    :hover,
    :focus {
      background-color: #5559ff !important;
    }
  }

  .ui.button.general-button.create-button,
  .ui.button.general-button.manage-button {
    background-color: transparent;
    color: #7b82a4 !important;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    padding-right: 20px;

    > * {
      margin-right: 5px;
    }

    :hover,
    :focus {
      color: #5c58c0 !important;
      background-color: transparent !important;

      svg {
        circle {
          fill: #5c58c0;
        }
      }
    }
  }

  .ui.button.general-button.manage-button {
    :hover,
    :focus {
      svg {
        path {
          fill: #5c58c0;
        }
      }
    }
  }

  .apply-button { 
    height: 30px;
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: calc(100% + 9px);
  left: 0;
  width: 320px;
  display: block;
  border-radius: 4px;
  box-shadow: 0 5px 13px -4px rgba(0, 0, 0, 0.3);
  border: solid 1px #dedee2;
  background: #fff;
  z-index: 999;
  &.searching {
    ${MenuBody} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: block;
  font-family: 'Open Sans';

  .add-button-container:hover,
  &.open .add-button-container {
    background-color: #FAFBFC;
  }

  ${MenuHeader} {
    .search-input {
      width: 100% !important;
    }
  }
`;

export const GroupButton = styled(AddButton)`
  .shared-add-button-title {
    font-weight: 600 !important;
    font-size: 11px;
    color: #777777;
  }
`