import styled, { css } from 'styled-components';
import { Wrapper, Header, Content, Actions } from 'shared/Styles/ModalLayout';
import ReactTooltip from 'react-tooltip';
import { CDN_URL } from 'constants/commonData';

export const RemoveIcon = styled.div`
  width: 15px;
  height: 15px;
  background: transparent url(${CDN_URL}/images/delete_bg_white.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  display: none;
`;

export const SubHeader = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 8px;
  &.everfit-backgrounds-title {
    margin-top: 5px !important;
    margin-bottom: 13px !important;
  }
`;

export const CustomBackgroundsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px 0px 20px;
`;

export const TriggerImage = styled.div`
  width: 77px;
  height: 49px;
  flex: 0 0 77px;
  background: transparent url(${props => props.background}) no-repeat center;
  background-size: cover;
  border-radius: 6px;
`;

export const TriggerImageName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #202353;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TriggerContainer = styled.div`
  // background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  max-height: 58px;

  :hover {
    background: linear-gradient(180deg, #ffffff 0%, #edeff4 100%);
    cursor: pointer;
  }
`;

export const SelectFileTrigger = styled.div`
  background: #f7f8fc;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
    border-color: #5158cf;

    svg {
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: transparent url(${props => props.background}) no-repeat center;
  background-size: cover;
`;

export const Item = styled.div`
  background: #ffffff;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 14px;
  padding: 4px;
  height: 122px;
  position: relative;
  cursor: pointer;

  .select-file__container,
  .select-file__trigger {
    width: 100%;
    height: 100%;
  }

  :hover {
    ${RemoveIcon} {
      display: block;
    }
  }

  :hover {
    border-color: #5158cf;
  }

  ${props =>
    props.disablehover
      ? css`
          :hover {
            border-color: transparent;
          }
        `
      : css`
          :hover {
            border-color: #5158cf;
          }
        `}
  ${props =>
    props.active &&
    css`
      border-color: #5158cf;
    `}

  .evf-file-upload {
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    border-radius: 10px;
    width: unset;
    height: unset;
  }
  ${props =>
    props.selectedDefault &&
    `
  ::before {
    content: 'Default';
    position: absolute;
    top: 12px;
    right: 12px;
    width: 65px;
    height: 19px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
  } 
  `}
`;

export const BackgroundList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ContentWrapper = styled(Wrapper)`
  max-height: calc(100vh - 100px);

  ${Header} {
    padding-bottom: 20px;
  }

  ${Content} {
    padding: 0 25px;
  }

  ${Actions} {
    padding-top: 30px;

    button {
      padding: 6px 54px;
    }
  }
`;

export const Tooltip = styled(ReactTooltip)`
  max-width: 320px !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden;
`;
