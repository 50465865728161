import styled from 'styled-components';

export const ClientStatus = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #c4c4c4;
  margin-right: 10px;
  flex: 0 0 6px;

  &.active {
    background-color: #1eae6a;
  }

  &.pause {
    background-color: #ffa31d;
  }

  &.completed {
    background-color: #726ee4;
  }
`;

export const Container = styled.div`
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;

  .table-container {
    flex: 1 1;
    overflow: auto;

    ::-webkit-scrollbar-track {
      margin-top: 50px;
    }

    .autoflow-client__profile__name {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #263d52;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;
      overflow: hidden;

      .autoflow-client__profile__name__value {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      :hover {
        color: #494699;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    .completed-marker {
      flex: 0 0 16px;
    }
  }

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    font-size: 13px;
    font-family: Open Sans;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    min-width: 100%;
    width: auto;
    position: relative;

    tr {
      th {
        font-size: 10px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6a778a;
        height: 0;
        position: sticky;
        text-align: left;
        top: 0;
        background-color: #fff;
        z-index: 1;

        &.name {
          left: 0;
          z-index: 2;
        }

        .table__header__content {
          padding: 18px 10px;
          width: 100%;
          border-bottom: 2px solid #f6f5f8;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 15px;
          color: #6a778a;
          white-space: nowrap;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          i.icon.active {
            color: #716ae7;
          }

          &.hidden {
            color: transparent;
            pointer-events: none;
            user-select: none;
          }

          img.ui.column-icon {
            margin-right: 5px;
          }

          i.icon {
            margin: 0;
            margin-left: 7px;
            line-height: 11px;
          }

          .select-all-container {
            width: 30px;
            margin-right: 16px;
            margin-left: 2px;
            display: flex;
            justify-content: center;
          }

          .header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
          }
        }
      }

      td {
        text-align: left;
        padding: 14px 10px;
        border-bottom: 1px solid #e8e8e7;
        white-space: nowrap;
        min-width: 150px;

        &.name {
          background: #fff;
          max-width: 200px;
          padding-right: 20px;
        }

        &.action {
          text-align: right;
          min-width: 50px;
          max-width: 50px;

          .evf-dropdown {
            display: inline-block;

            &.open {
              .actions-trigger {
                background-color: #dae0e4;
              }
            }

            .evf-dropdown__menu {
              background-color: #2d2e2d;
              border-radius: 5px;
              padding: 8px 0;
              width: 190px;

              .evf-dropdown__option {
                color: #fff;
                font-weight: 600;
                font-family: Open Sans;
                font-size: 13px;
                line-height: 18px;
                padding: 8px 30px 8px 18px;

                .item__content {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  color: #fff;

                  img {
                    margin-right: 12px !important;
                  }
                }
              }
            }
          }
        }
      }

      .select-checkbox {
        .checkbox-icon {
          width: 18px;
          height: 18px;
          border-radius: 4px;
          background-size: 10px auto;
        }
      }

      .autoflow-client__profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .autoflow-client__profile__avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          flex: 0 0 30px;
          margin-right: 15px;
        }

        .select-checkbox {
          display: none;
          margin-left: 6px;
        }
      }

      :not(.disable-select):hover {
        .autoflow-client__profile {
          .select-checkbox {
            display: block;
          }

          .sb-avatar {
            display: none !important;
          }
        }
      }

      &.inactive {
        td {
          opacity: 0.4;

          &.name,
          &.action {
            opacity: 1;
          }
        }

        .autoflow-client__profile__avatar,
        .autoflow-client__profile__name span {
          opacity: 0.4;
        }

        .autoflow-exactdate-tooltip {
          min-width: 105px;
        }
      }

      .autoflow-exactdate-tooltip {
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 120%;
        padding: 8px 12px;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    tbody {
      tr:hover {
        background-color: #fafbfc;
        td {
          background-color: #fafbfc;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;

  &.selecting {
    ${Container} {
      tr:not(.disable-select) {
        .autoflow-client__profile {
          .select-checkbox {
            display: block;
          }

          .sb-avatar {
            display: none !important;
          }
        }
      }
    }
  }
`;
