import styled from 'styled-components';

export const GoalCountdownContentOverviewStyled = styled.div`
  &.goal-countdown-content {
    display: flex !important;
    flex-direction: column;
    max-height: 212px;
    gap: 10px;
    padding: 12px;
    overflow-y: auto !important;

    .loading-countdowns {
      padding-top: 15px !important;

      .ui.loader:after {
        border-color: #5158cf transparent transparent !important;
      }
    }

    &.empty {
      flex-direction: row;
      overflow: unset;
      padding: 14px 13px 9px;
    }

    .add-btn {
      height: 30px;
      border-radius: 15px;
      padding: 10px 15px 10px 12px;
      font-size: 12px;
      line-height: 18px;
      display: inline-flex;
      gap: 9px;
      color: #5e5e5e;
      font-weight: 600;
      border: none;
      align-items: center;
      background-color: #f0f0f2;

      svg {
        position: relative;
        display: inline-block;
        top: -1px;
        left: 0px;
        width: 9.33px;
        height: 9.33px;
        path {
          fill: #5e5e5e;
        }
      }

      &:hover {
        background-color: #f0f1ff;
        color: #5158cf;
        cursor: pointer;

        svg path {
          fill: #5158cf;
        }
      }
    }
  }
`;
