// Libs
import React, { useEffect, useRef } from 'react';
import Avatar from 'react-avatar';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ReactTooltip from 'react-tooltip';

// Redux
import {
  changeQueryParams,
  getListMealPlan,
  removeMealPlan,
  sharedMealPlan,
  duplicateMealPlan,
  setHeaderTabs,
} from 'redux/meal-plans/actions';

// Components
import { CDN_URL, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import SkeletonLoading from 'components/Recipes/Parts/SkeletonLoading';
import MealPlanModal from '../MealPlanModal';
import DeleteModalMealPlan from '../DeleteModalMealPlan';
import ShareModal from '../ShareModal';

// Constants
import { getUserShortName, isTeamAdmin, pluralize, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { ARRAY_LENGTH_LOADING } from 'components/Recipes/constants';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { MEAL_PLAN_STATUS, PER_PAGE } from 'components/MealPlanDetail/constants';
import { MEAL_PLAN_HEADER_TABS } from 'components/MealPlans/constants';

// Assets
import { ReactComponent as EmptyBanner } from 'assets/icons/MealPlans/meal-plan-empty.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/MealPlans/action_duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/icons/MealPlans/action_edit.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/MealPlans/action_share.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';
import DefaultImage from 'assets/icons/MealPlans/meal_plan_default.png';

// Styles
import * as S from './style';

const MealPlanContentList = props => {
  const {
    push,
    user,
    total,
    loading,
    filters,
    list = [],
    toggleModal,
    removeMealPlan,
    sharedMealPlan,
    getListMealPlan,
    changeQueryParams,
    toggleConfirmModal,
    duplicateMealPlan,
    isMealPlanBanner,
    tab,
    setHeaderTabs,
    isShowDraft,
    cloudfrontList,
  } = props;

  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;

    if (get(filters, 'page') === 1) {
      container && container.scrollTo({ top: 0 });
    }
  }, [filters]);

  useEffect(() => {
    if (MEAL_PLAN_HEADER_TABS.ALL_PLAN !== tab && !isShowDraft) {
      getListMealPlan({ ...filters, only_my_meal_plans: MEAL_PLAN_HEADER_TABS.ALL_PLAN !== tab });
    } else {
      getListMealPlan(filters);
    }
  }, [filters]);

  const handleRedirectToDetail = (mealPlanId, weekId, isEdit = false) => {
    const queryParams = isEdit ? '?isEdit=true' : '';
    push(`/home/meal-plans/${mealPlanId}/weeks/${weekId}${queryParams}`);
  };

  const handleOpenAddMealPlan = () => {
    toggleModal && toggleModal(true, <MealPlanModal user={user} toggleModal={toggleModal} />);
  };

  const handleOpenDeleteModal = (mealPlanId, status) => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <DeleteModalMealPlan
          onConfirm={() => removeMealPlan(mealPlanId)}
          toggleConfirmModal={toggleConfirmModal}
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
          hasCloseIcon
          content={status === MEAL_PLAN_STATUS.DRAFT ? 'Are you sure you want to remove the Meal Plan?' : ''}
        />,
      );
  };

  const handleUpdateShareOwner = ({ id, params }) => {
    sharedMealPlan &&
      sharedMealPlan(id, params, () => {
        toggleModal && toggleModal(false);
      });
  };

  const handleOpenSharedModal = mealPlan => {
    toggleModal &&
      toggleModal(
        true,
        <ShareModal
          user={user}
          toggleModal={toggleModal}
          mealPlan={mealPlan}
          isShowCancelButton={true}
          onSubmit={handleUpdateShareOwner}
        />,
      );
  };

  const handleDuplicate = data => {
    const { _id, name } = data;
    let params = {
      name: `Copied - ${name.trim()}`,
    };

    if (_id) {
      duplicateMealPlan && duplicateMealPlan(_id, params);
    }
  };

  const renderActions = mealPlan => {
    const canAction =
      isTeamAdmin(user) ||
      get(mealPlan, 'author._id', '') === get(user, '_id', '') ||
      get(mealPlan, 'share', TEAM_SHARE_PRIVATE) === TEAM_SHARE_NOOWNER;
    const mealPlanId = get(mealPlan, '_id', '');
    const status = get(mealPlan, 'status', '');

    return (
      <DropDown
        className="custom-dropdown-actions"
        triggerIcon={({ open }) => (
          <MenuTrigger className="dropdown-trigger" vertical active={!!open} itemId={mealPlanId} />
        )}
        direction="left"
        isNewSpacing
        isMealPlanAndRecipe
      >
        <Option key="duplicate" onClick={() => handleDuplicate(mealPlan)}>
          <S.OptionIcon className="icon">
            <DuplicateIcon className="meal-plan-duplicate-icon" />
          </S.OptionIcon>
          <span>Duplicate</span>
        </Option>
        {canAction && (
          <Option
            key="edit"
            onClick={() => handleRedirectToDetail(get(mealPlan, '_id', ''), get(mealPlan, 'weeks.[0]', ''), true)}
          >
            <S.OptionIcon>
              <EditIcon className="meal-plan-edit-icon" />
            </S.OptionIcon>
            <span>Edit</span>
          </Option>
        )}
        {canAction && (
          <Option key="share-setting" onClick={() => handleOpenSharedModal(mealPlan)}>
            <S.OptionIcon className="icon">
              <ShareIcon className="share-icon" />
            </S.OptionIcon>
            <span>Sharing settings</span>
          </Option>
        )}
        {canAction && (
          <Option key="delete" onClick={() => handleOpenDeleteModal(mealPlanId, status)}>
            <S.OptionIcon>
              <RemoveIcon className="meal-plan-remove-icon" />
            </S.OptionIcon>
            <span>Remove</span>
          </Option>
        )}
      </DropDown>
    );
  };

  const handleLoadMore = event => {
    const scrollHeight = get(event, 'target.scrollHeight', 0);
    const scrollTop = get(event, 'target.scrollTop', 0);
    const clientHeight = get(event, 'target.clientHeight', 0);

    const bottom = scrollHeight - scrollTop <= clientHeight + 10;

    const isEnd = get(filters, 'page') >= Math.ceil(total / PER_PAGE);
    if (bottom && !loading && !isEnd) {
      const nextPage = get(filters, 'page') + 1;
      changeQueryParams && changeQueryParams({ page: nextPage });
    }
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleScroll = event => {
    event.persist();
    onScrollDebounce.call(null, event);
  };

  const renderMealPlanItem = item => {
    const {
      _id: itemId = '',
      author,
      share = 0,
      cover_image_thumbnail = '',
      cover_image = '',
      weeks = [],
      name = '',
      number_of_clients = 0,
      status = '',
    } = item || {};

    const { _id: authorId = '', avatar = '', color = '', first_name = '', last_name = '' } = author || {};
    const { _id: userId = '' } = user || {};

    const isAnotherOwner = share !== TEAM_SHARE_NOOWNER && authorId !== userId;

    const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
    const coverImageThumbnail = convertS3UrlToCloudFrontUrl(cover_image_thumbnail, cloudfrontList, true);

    return (
      <S.MealPlanItemWrapper key={itemId} onClick={() => handleRedirectToDetail(itemId, get(weeks, '[0]', ''))}>
        {coverImageThumbnail || coverImage ? (
          <S.MealPlanThumbnail src={[coverImageThumbnail, coverImage]} defaultImage={DefaultImage} />
        ) : (
          <S.MealPlanThumbnail src={[DefaultImage]} defaultImage={DefaultImage} />
        )}
        <S.MealPlanInfo>
          <S.TagWrapper>
            <S.WeekLabel>{pluralize('Week', weeks.length, true)}</S.WeekLabel>
            {status && (
              <S.Status status={status}>{status === MEAL_PLAN_STATUS.PUBLISH ? 'Published' : 'Draft'}</S.Status>
            )}
          </S.TagWrapper>
          <S.MealPlanTitle>{name}</S.MealPlanTitle>
          <S.MealInfoBottom>
            <S.AssignToNumber>
              Assigned to<b className="bold">{pluralize('client', number_of_clients, true)}</b>
            </S.AssignToNumber>
            {isAnotherOwner && (
              <>
                <div data-for={`meal-plan-avatar-${authorId}-tooltip`} data-tip className="meal-plan-owner-avatar">
                  <Avatar
                    name={getUserShortName(author)}
                    size="20"
                    src={avatar}
                    color={color}
                    className="meal-plan-avatar"
                  />
                </div>
                <ReactTooltip
                  className="meal-plan-avatar-tooltip"
                  id={`meal-plan-avatar-${authorId}-tooltip`}
                  effect="solid"
                  place="top"
                >
                  {first_name} {last_name}
                </ReactTooltip>
              </>
            )}
          </S.MealInfoBottom>
        </S.MealPlanInfo>
        {renderActions(item)}
      </S.MealPlanItemWrapper>
    );
  };

  return (
    <S.MealPlanContainer
      ref={containerRef}
      onScroll={handleScroll}
      isHasBanner={!isMealPlanBanner}
      isEmptyList={isEmpty(list) && !loading}
    >
      {!loading && isEmpty(list) && (
        <S.EmptyListWrapper hasFilter={get(filters, 'text_search')} isEmptyList={isEmpty(list) && !loading}>
          {get(filters, 'text_search') ? (
            <S.NotFoundWrapper>
              <S.NoResultFound>No results found.</S.NoResultFound>
            </S.NotFoundWrapper>
          ) : (
            <>
              <EmptyBanner className="empty-banner" />
              <S.NoResultFound>No meal plans</S.NoResultFound>
              <S.NewMealPlanButton onClick={handleOpenAddMealPlan} purple>
                <PlusIcon className="plus-icon" />
                <span className="label">Create your first meal plan</span>
              </S.NewMealPlanButton>
            </>
          )}
        </S.EmptyListWrapper>
      )}
      <S.ContentList>
        {loading &&
          get(filters, 'page') === 1 &&
          Array(ARRAY_LENGTH_LOADING)
            .fill(null)
            .map((_, index) => <SkeletonLoading key={index} />)}
        {!isEmpty(list) && !(loading && get(filters, 'page') === 1) && list.map(renderMealPlanItem)}
        {loading &&
          get(filters, 'page', 1) > 1 &&
          Array(ARRAY_LENGTH_LOADING)
            .fill(null)
            .map((_, index) => <SkeletonLoading key={index} />)}
      </S.ContentList>
    </S.MealPlanContainer>
  );
};
const mapStateToProps = state => {
  const { user, rootReducer, cloudfrontList } = state;

  return {
    user,
    list: get(rootReducer, 'mealPlans.list', []),
    total: get(rootReducer, 'mealPlans.total', 0),
    filters: get(rootReducer, 'mealPlans.filters', null),
    loading: get(rootReducer, 'mealPlans.loading', false),
    isMealPlanBanner: get(rootReducer, 'generalSettings.is_hide_meal_plan_banner'),
    tab: get(rootReducer, 'mealPlans.tab'),
    cloudfrontList,
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  removeMealPlan: bindActionCreators(removeMealPlan, dispatch),
  sharedMealPlan: bindActionCreators(sharedMealPlan, dispatch),
  getListMealPlan: bindActionCreators(getListMealPlan, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  duplicateMealPlan: bindActionCreators(duplicateMealPlan, dispatch),
  setHeaderTabs: bindActionCreators(setHeaderTabs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanContentList);
