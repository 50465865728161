import React, { useEffect, useState } from 'react';
import ChartComponent from './ChartComponent';
import { colors, convertSecondsToTime } from './constants';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import * as S from './style';

const INIT_STATE = {
  zones: [],
  heart_rate_average: 0,
  min: 0,
  max: 0,
  entries: [],
};

const HeartRateResultChart = ({ assignmentId, getHeartRateAssignment, workout }) => {
  const [heartRateData, setHeartRateData] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);

  const zones = heartRateData.zones;
  const totalDuration = zones.reduce((arr, curr) => {
    return arr + curr.duration;
  }, 0);

  const min = heartRateData.min;
  const max = heartRateData.max;
  const gatheredZones = !isEmpty(zones) ? [zones[0], zones[3], zones[1], zones[4], zones[2]] : [];

  const handleGetHeartRate = () => {
    setLoading(true);
    getHeartRateAssignment(assignmentId)
      .then(res => {
        setHeartRateData(get(res, 'data.data'));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetHeartRate();
  }, [assignmentId]);

  const renderLegend = (item, index) => {
    const { duration, zone, min, max } = item;
    const color = colors[zone - 1];
    const range = zone === 1 ? `<${max}` : zone === 5 ? `${min}+` : `${min}-${max}`;
    const maxWidth = 48;
    const percent = duration / totalDuration;
    const timeOfZone = convertSecondsToTime(duration) || '00:00';
    const width = percent * maxWidth.toFixed(0) + 4;

    return (
      <S.LegendSection key={color}>
        <S.LegendSectionLeft>
          <S.LegendZone>Zone {zone}</S.LegendZone>
          <S.LegendColor color={color} width={width} />
          <S.LegendTime>{timeOfZone}</S.LegendTime>
        </S.LegendSectionLeft>
        <S.LegendRange>{range} BPM</S.LegendRange>
      </S.LegendSection>
    );
  };

  const renderSection = () => {
    return (
      <S.SectionContainer>
        <S.SectionHeader>
          <S.LeftHeader>
            <S.AvgNumber>{heartRateData.heart_rate_average.toFixed(0)}</S.AvgNumber>
            <S.AvgUnit>AVG BPM</S.AvgUnit>
          </S.LeftHeader>
          <S.RightHeader>
            <S.RangeNumber>
              {heartRateData.min} <S.LongDash /> {heartRateData.max}
            </S.RangeNumber>
            <S.RangeUnit>BPM</S.RangeUnit>
          </S.RightHeader>
        </S.SectionHeader>

        <S.SectionBody>
          <ChartComponent heartRateData={heartRateData} />
        </S.SectionBody>
        <S.SectionFooter>{gatheredZones.map(renderLegend)}</S.SectionFooter>
      </S.SectionContainer>
    );
  };
  if (loading || (min === 0 && max === 0)) return null;
  return (
    <>
      <div className="title">
        <div>Heart Rate</div>
      </div>
      <div className="history__sections">{renderSection()}</div>
    </>
  );
};

export default HeartRateResultChart;
