import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SectionDetailComponent from 'components/SectionDetailModal/component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  initWorkoutData,
  clearWorkoutData,
  validateWorkoutData,
  updateWorkoutId,
  updateOriginalWorkout,
  validateSectionData,
} from 'redux/workout-builder/actions';
import { removeSectionLibrary } from 'redux/section-library/actions';

import { getOnboardingWorkoutBuiler, markWorkoutBuilderGuide } from 'redux/onboarding/actions';

const mapState = state => {
  return {
    isHideLeftPanel: state.rootReducer.workoutBuilder.getIn(['isHideLeftPanel']),
    isChanged: state.rootReducer.workoutBuilder.getIn(['isChanged']),
    user: state.user,
    loadedOnboardingData: state.rootReducer.onboarding.workoutBuilderGuideSteps.loaded,
    isUploading: state.rootReducer.workoutBuilder.getIn(['isUploading']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearWorkoutData: bindActionCreators(clearWorkoutData, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    initWorkoutData: bindActionCreators(initWorkoutData, dispatch),
    validateWorkoutData: bindActionCreators(validateWorkoutData, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    updateWorkoutId: bindActionCreators(updateWorkoutId, dispatch),
    updateOriginalWorkout: bindActionCreators(updateOriginalWorkout, dispatch),
    getOnboardingWorkoutBuiler: bindActionCreators(getOnboardingWorkoutBuiler, dispatch),
    markWorkoutBuilderGuide: bindActionCreators(markWorkoutBuilderGuide, dispatch),
    validateSectionData: bindActionCreators(validateSectionData, dispatch),
    removeSectionLibrary: bindActionCreators(removeSectionLibrary, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(SectionDetailComponent);
