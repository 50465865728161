import React, { useState, useEffect } from 'react';
import { RootCloseWrapper } from 'react-overlays';

import GiphyContent from 'shared/Giphy/components/GiphyContent';
import InboxTrigger from 'shared/Giphy/components/InboxTrigger';
import * as S from 'shared/Giphy/styles';

function GiphyPopup(props) {
  if (process.env.REACT_APP_ENABLE_GIPHY !== 'true') return <></>;
  const {
    trigger = InboxTrigger,
    position = 'top center',
    size,
    onGifClick,
    disabled = false,
    isScrolling = false,
  } = props;
  const CustomTrigger = trigger;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isScrolling && open) {
      setOpen(false);
    }
  }, [isScrolling]);

  return (
    <S.GiphyPopup
      size={size}
      trigger={<CustomTrigger open={open} disabled={disabled} />}
      flowing={true}
      hoverable={true}
      on="click"
      position={position}
      open={open}
      onOpen={() => !disabled && setOpen(true)}
      onClose={() => {}}
      className="opened-giphy-popup"
    >
      <RootCloseWrapper event="click" onRootClose={() => setOpen(false)} disabled={!open}>
        <GiphyContent onGifClick={onGifClick} onClose={() => setOpen(false)} />
      </RootCloseWrapper>
    </S.GiphyPopup>
  );
}

export default GiphyPopup;
