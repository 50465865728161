import styled, { css } from 'styled-components';
import { Dropdown as DropdownElement } from 'semantic-ui-react';
import { ellipsis } from 'polished';
import ConfirmModal from 'shared/ConfirmModal';

export const PostAuthor = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  right: 0;
  z-index: 2;
  padding: 30px 15px 15px;

  .author__avatar {
    flex: 0 0 46px;
    margin-right: 15px;
    .author__avatar {
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .author__name {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    flex: 1;
    max-width: 340px;
    padding-right: 22px;

    .group-name {
      white-space: nowrap;
      overflow: hidden;
      max-width: 170px;
    }

    .date {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      color: #000000;
      cursor: pointer;
      display: inline-block;

      svg {
        margin-right: 8px;
      }

      > * {
        vertical-align: middle;
      }
    }
  }
`;

export const AuthorName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  > div:first-of-type {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  & > * {
    margin-right: 5px;
  }
`;

export const PostCaption = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.029999999329447746px;
  text-align: left;
  margin-bottom: 15px;

  .public-DraftStyleDefault-ltr {
    display: ${props => (props.showMore ? 'block' : 'unset')};
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 84px;
  }
`;

export const ShowMoreText = styled.a`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #828282;

  &:hover {
    text-decoration: none !important;
    cursor: pointer;
  }
`;

export const PostInfo = styled.div`
  position: relative;
  margin-bottom: 30px;
  padding: 0 15px;

  ${props =>
    props.hasPreview &&
    css`
      margin-bottom: 0px;
    `}

  .poll-detail-wrapper {
    margin-left: 5px;
  }
`;

export const PostStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 15px;

  ${props =>
    props.hasPreview &&
    css`
      height: 18px;
    `}

  /* .comment {
    position: absolute;
    right: 0;
    bottom: 0;
  } */

  .stats__right-side {
    display: flex;
    gap: 19px;
    position: absolute;
    right: 15px;
    bottom: 0;
    button {
      background-color: transparent;
    }
  }
`;

export const Liked = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;

  .name-text {
    font-weight: bold;
  }

  .and-text {
    font-weight: normal;
  }
`;

export const Comments = styled.div`
  height: calc(100% - ${props => (props.initHeightCommentList ? `${props.initHeightCommentList}px` : '0')});
  min-height: 200px;
  overflow-y: scroll;
  padding-top: 5px;
  padding-right: 22px;
  padding-left: 15px;
`;

export const Content = styled.div`
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: overlay;

  .interactions {
    padding: 5px 0;
    border-top: 1px solid #dde2ed;
    border-bottom: 1px solid #dde2ed;
    margin-bottom: 15px;
    padding: 0 15px;
  }

  .no-scroll {
    /* position: sticky;
    top: 0;
    z-index: 999 !important; */
    background: #fff;
    padding-top: 30px;
  }

  .link-preview-detail {
    padding: 0 15px;
  }

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  overflow: visible;
  font-family: 'Open Sans';
  color: #000;
  width: 100%;
`;

export const Dropdown = styled(DropdownElement)`
  position: absolute !important;
  top: 30px !important;
  right: 0 !important;
`;

export const DropItem = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202353;
  padding: 17px 20.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: auto;

  & > span {
    margin-left: 10px;
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
    svg path {
      stroke: #5158cf;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: block;
  position: relative;
  width: ${props => (props.twoAvatars ? '35px' : '')};
  margin-right: 5px;
  height: 20px;

  > * {
    &:nth-child(2) {
      img,
      .sb-avatar__text {
        position: absolute;
        left: 15px;
        top: 0;
        display: inline-block;
        box-shadow: 0 0 0 3px white;
      }
    }
  }
`;

export const Body = styled.div`
  padding: 5px 20px;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  outline: none !important;

  .no-scroll {
    padding: 0;
    position: relative;
    /* top: ${props => (props.isSeeMore ? `-${props.isSeeMore}px` : '0')}; */
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;

    .interactions {
      padding: 5px 0;
      border-top: 1px solid #dde2ed;
      border-bottom: 1px solid #dde2ed;
      margin-bottom: 15px;
    }
  }

  ${props =>
    props.empty &&
    css`
      ${Body} {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    `}
`;

export const SConfirmModal = styled(ConfirmModal)`
  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }

  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    background-color: #ec465c !important;
  }

  .ui.button.confirm-no-button,
  .ui.button.confirm-yes-button {
    line-height: 20px !important;
  }

  .confirm-content-header {
    padding: 30px 30px 0 !important;

    .confirm-header-label {
      color: #000000;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .confirm-content-body {
    padding: 15px 30px 30px !important;

    .confirm-content-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  .confirm-header-image {
    width: 23px;
    height: 23px;
  }
`;

export const OptionWrapper = styled.div`
  position: sticky;
  top: 0;
  right: 15px;
  z-index: 3;
  margin-right: 15px;
`;
