import Request from 'configs/request';
import { TAGS_TYPE } from 'constants/commonData';
import { Mixpanel } from 'utils/mixplanel';
import { Types as ExerciseTypes } from './exercise-tags/actions';
import { Types as WorkoutTypes } from './workout-tags/action-types';
import { Types as ProgramTypes } from './program-tags/actions';

export const changeQueryParams = params => {
  return (dispatch, getState) => {
    let newData;
    switch (params.type) {
      case TAGS_TYPE.EXERCISE:
        const {
          rootReducer: {
            exerciseTags: { queryExercise },
          },
        } = getState();

        newData = { ...queryExercise, ...params };
        dispatch({
          type: ExerciseTypes.TAGS_EXERCISE_CHANGE_QUERY_PARAMS,
          payload: newData,
        });
        break;
      case TAGS_TYPE.WORKOUT:
        const {
          rootReducer: {
            workoutTags: { queryWorkout },
          },
        } = getState();

        newData = { ...queryWorkout, ...params };
        dispatch({
          type: WorkoutTypes.TAGS_WORKOUT_CHANGE_QUERY_PARAMS,
          payload: newData,
        });
        break;
      case TAGS_TYPE.PROGRAM: {
        const {
          rootReducer: {
            programTags: { query },
          },
        } = getState();

        newData = { ...query, ...params };
        dispatch({
          type: ProgramTypes.TAGS_PROGRAM_CHANGE_QUERY_PARAMS,
          payload: newData,
        });
        break;
      }

      default:
        break;
    }
  };
};

export const resetQueryParams = (type = TAGS_TYPE.EXERCISE) => {
  return dispatch => {
    switch (type) {
      case TAGS_TYPE.EXERCISE:
        dispatch({ type: ExerciseTypes.TAGS_EXERCISE_RESET_QUERY_PARAMS });
        break;
      case TAGS_TYPE.WORKOUT:
        dispatch({ type: WorkoutTypes.TAGS_WORKOUT_RESET_QUERY_PARAMS });
        break;
      case TAGS_TYPE.PROGRAM: {
        dispatch({ type: ProgramTypes.TAGS_PROGRAM_RESET_QUERY_PARAMS });
        break;
      }

      default:
        break;
    }
  };
};

export const getTagsList = params => {
  return dispatch => {
    switch (params.type) {
      case TAGS_TYPE.EXERCISE:
        dispatch({ type: ExerciseTypes.TAGS_EXERCISE_GET_LIST_REQUEST });
        break;
      case TAGS_TYPE.WORKOUT:
        dispatch({ type: WorkoutTypes.TAGS_WORKOUT_GET_LIST_REQUEST });
        break;
      case TAGS_TYPE.PROGRAM: {
        dispatch({ type: ProgramTypes.TAGS_PROGRAM_GET_LIST_REQUEST });
        break;
      }

      default:
        break;
    }
    return dispatch(
      Request.get(
        {
          url: `/api/tag/get-list-tag-by-team/`,
          params: {
            sorter: 'name',
            per_page: 20,
            ...params,
          },
        },
        true,
        response => {
          const { data } = response;
          switch (params.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({
                type: ExerciseTypes.TAGS_EXERCISE_GET_LIST_SUCCESS,
                payload: data,
              });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({
                type: WorkoutTypes.TAGS_WORKOUT_GET_LIST_SUCCESS,
                payload: data,
              });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({
                type: ProgramTypes.TAGS_PROGRAM_GET_LIST_SUCCESS,
                payload: data,
              });
              break;
            }

            default:
              break;
          }
        },
        () => {
          switch (params.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_GET_LIST_FAILED });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_GET_LIST_FAILED });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_GET_LIST_FAILED });
              break;
            }

            default:
              break;
          }
        },
      ),
    );
  };
};

export const getMostRecentTagsList = params => {
  return dispatch => {
    switch (params.type) {
      case TAGS_TYPE.EXERCISE:
        dispatch({ type: ExerciseTypes.TAGS_EXERCISE_GET_MOST_RECENT_REQUEST });
        break;
      case TAGS_TYPE.WORKOUT:
        dispatch({ type: WorkoutTypes.TAGS_WORKOUT_GET_MOST_RECENT_REQUEST });
        break;
      case TAGS_TYPE.PROGRAM: {
        dispatch({ type: ProgramTypes.TAGS_PROGRAM_GET_MOST_RECENT_REQUEST });
        break;
      }

      default:
        break;
    }
    return dispatch(
      Request.get(
        {
          url: `/api/tag/get-list-tag-by-team/`,
          params: {
            sorter: 'most_recent',
            per_page: 20,
            ...params,
          },
        },
        false,
        response => {
          const { data } = response;
          switch (params.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({
                type: ExerciseTypes.TAGS_EXERCISE_GET_MOST_RECENT_SUCCESS,
                payload: data,
              });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({
                type: WorkoutTypes.TAGS_WORKOUT_GET_MOST_RECENT_SUCCESS,
                payload: data,
              });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({
                type: ProgramTypes.TAGS_PROGRAM_GET_MOST_RECENT_SUCCESS,
                payload: data,
              });
              break;
            }

            default:
              break;
          }
        },
        () => {
          switch (params.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_GET_MOST_RECENT_FAILED });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_GET_MOST_RECENT_FAILED });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_GET_MOST_RECENT_FAILED });
              break;
            }

            default:
              break;
          }
        },
      ),
    );
  };
};

export const getMostUsedTagList = params => {
  return dispatch => {
    switch (params.type) {
      case TAGS_TYPE.EXERCISE:
        dispatch({ type: ExerciseTypes.TAGS_EXERCISE_GET_MOST_USED_REQUEST });
        break;
      case TAGS_TYPE.WORKOUT:
        dispatch({ type: WorkoutTypes.TAGS_WORKOUT_GET_MOST_USED_REQUEST });
        break;
      case TAGS_TYPE.PROGRAM: {
        dispatch({ type: ProgramTypes.TAGS_PROGRAM_GET_MOST_USED_REQUEST });
        break;
      }

      default:
        break;
    }
    return dispatch(
      Request.get(
        {
          url: `/api/tag/get-list-tag-by-team/`,
          params: { sorter: 'most_used_exercise', per_page: 20, ...params },
        },
        true,
        response => {
          const { data } = response;
          switch (params.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({
                type: ExerciseTypes.TAGS_EXERCISE_GET_MOST_USED_SUCCESS,
                payload: data,
              });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({
                type: WorkoutTypes.TAGS_WORKOUT_GET_MOST_USED_SUCCESS,
                payload: data,
              });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({
                type: ProgramTypes.TAGS_PROGRAM_GET_MOST_USED_SUCCESS,
                payload: data,
              });
              break;
            }

            default:
              break;
          }
        },
        () => {
          switch (params.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_GET_MOST_USED_FAILED });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_GET_MOST_USED_FAILED });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_GET_MOST_USED_FAILED });
              break;
            }

            default:
              break;
          }
        },
      ),
    );
  };
};

export const createNewTag = (data, callback) => {
  return dispatch => {
    switch (data.type) {
      case TAGS_TYPE.EXERCISE:
        dispatch({ type: ExerciseTypes.TAGS_EXERCISE_CREATE_NEW_REQUEST });
        Mixpanel.track('create_tag_system_exercises');
        break;
      case TAGS_TYPE.WORKOUT:
        dispatch({ type: WorkoutTypes.TAGS_WORKOUT_CREATE_NEW_REQUEST });
        Mixpanel.track('create_tag_system_workouts');
        break;
      case TAGS_TYPE.PROGRAM: {
        dispatch({ type: ProgramTypes.TAGS_PROGRAM_CREATE_NEW_REQUEST });
        Mixpanel.track('create_tag_system_programs');
        break;
      }

      default:
        break;
    }

    return dispatch(
      Request.post(
        {
          url: '/api/tag/',
          data: data,
        },
        true,
        response => {
          const { data } = response.data;
          switch (data.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_CREATE_NEW_SUCCESS, payload: data });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_CREATE_NEW_SUCCESS, payload: data });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_CREATE_NEW_SUCCESS, payload: data });
              break;
            }

            default:
              break;
          }
          callback && callback(data);
        },
        () => {
          switch (data.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_CREATE_NEW_FAILED });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_CREATE_NEW_FAILED });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_CREATE_NEW_FAILED });
              break;
            }

            default:
              break;
          }
        },
      ),
    );
  };
};

export const editTag = (id, data, callback) => {
  return dispatch => {
    switch (data.type) {
      case TAGS_TYPE.EXERCISE:
        dispatch({ type: ExerciseTypes.TAGS_EXERCISE_EDIT_REQUEST });
        break;
      case TAGS_TYPE.WORKOUT:
        dispatch({ type: WorkoutTypes.TAGS_WORKOUT_EDIT_REQUEST });
        break;
      case TAGS_TYPE.PROGRAM: {
        dispatch({ type: ProgramTypes.TAGS_PROGRAM_EDIT_REQUEST });
        break;
      }

      default:
        break;
    }

    return dispatch(
      Request.put(
        {
          url: `/api/tag/${id}`,
          data: {
            name: data.name,
          },
        },
        true,
        response => {
          const { data } = response.data;
          switch (data.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_EDIT_SUCCESS, payload: data });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_EDIT_SUCCESS, payload: data });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_EDIT_SUCCESS, payload: data });
              break;
            }

            default:
              break;
          }
        },
        () => {
          callback && callback();
          switch (data.type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_EDIT_FAILED });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_EDIT_FAILED });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_EDIT_FAILED });
              break;
            }

            default:
              break;
          }
        },
      ),
    );
  };
};

export const deleteTag = (id, type = TAGS_TYPE.EXERCISE) => {
  return dispatch => {
    switch (type) {
      case TAGS_TYPE.EXERCISE:
        dispatch({ type: ExerciseTypes.TAGS_EXERCISE_DELETE_REQUEST });
        Mixpanel.track('delete_tag_system_exercises');
        break;
      case TAGS_TYPE.WORKOUT:
        dispatch({ type: WorkoutTypes.TAGS_WORKOUT_DELETE_REQUEST });
        Mixpanel.track('delete_tag_system_workouts');
        break;
      case TAGS_TYPE.PROGRAM: {
        dispatch({ type: ProgramTypes.TAGS_PROGRAM_DELETE_REQUEST });
        Mixpanel.track('delete_tag_system_workouts');
        break;
      }

      default:
        break;
    }

    return dispatch(
      Request.delete(
        {
          url: `/api/tag/${id}`,
        },
        true,
        response => {
          switch (type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_DELETE_SUCCESS, id });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_DELETE_SUCCESS, id });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_DELETE_SUCCESS, id });
              break;
            }

            default:
              break;
          }
        },
        () => {
          switch (type) {
            case TAGS_TYPE.EXERCISE:
              dispatch({ type: ExerciseTypes.TAGS_EXERCISE_DELETE_FAILED });
              break;
            case TAGS_TYPE.WORKOUT:
              dispatch({ type: WorkoutTypes.TAGS_WORKOUT_DELETE_FAILED });
              break;
            case TAGS_TYPE.PROGRAM: {
              dispatch({ type: ProgramTypes.TAGS_PROGRAM_DELETE_FAILED });
              break;
            }

            default:
              break;
          }
        },
      ),
    );
  };
};
