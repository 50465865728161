import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeamInviteModalComponent from './component';
import { inviteNewTeamate, editTeamMember, resendInvokeInvitation } from 'actions/client';

const mapStateToProps = (state) => {
  const { isModalOpen, user } = state;

  return { isModalOpen, user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    inviteNewTeamate: bindActionCreators(inviteNewTeamate, dispatch),
    editTeamMember: bindActionCreators(editTeamMember, dispatch),
    resendInvokeInvitation: bindActionCreators(resendInvokeInvitation, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamInviteModalComponent);
