import { findIndex, get, isObject, map, merge } from 'lodash';
import { Types } from './actions';
import { DEFAULT_CUSTOM_FILTERS } from 'shared/ExerciseFilterPopup';
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';

export const checkIsOwner = (user, checkId) => {
  let isOwner = false;
  if (isObject(user)) {
    isOwner = get(user, '_id', '') === checkId ? true : false;
  } else {
    isOwner = user === checkId ? true : false;
  }
  return isOwner;
};

export const DEFAULT_STATE = {
  list: [],
  selectedTasks: [],
  loading: false,
  total: 0,
  has_task_library: false,
  isEnd: false,
  deleting: false,
  queryParam: {
    limit: 20,
    page: 1,
    query: '',
    sorter: 'last_activity_at',
    sort: -1,
    ...DEFAULT_CUSTOM_FILTERS,
  },
};

const TasksReducer = (state = DEFAULT_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case Types.TASKS_LIBRARY_GET_LIST_REQUEST:
      return Object.assign({}, state, { loading: true });

    case Types.TASKS_LIBRARY_GET_LIST_SUCCESS: {
      const {
        data: { total, page, list, has_task_library },
        isEnd,
        isLoadMore,
        prevList,
      } = payload;
      let newList = list;
      if (isLoadMore && page > 1) {
        newList = prevList.concat(list);
      }
      return Object.assign({}, state, { list: newList, page, total, loading: false, isEnd, has_task_library });
    }

    case Types.TASKS_LIBRARY_GET_LIST_FAILED:
      return Object.assign({}, state, { loading: false });

    case Types.TASKS_LIBRARY_CHANGE_QUERY_PARAMS:
      return Object.assign({}, state, { queryParam: { ...payload.data } });

    case Types.TASKS_LIBRARY_RESET_QUERY_PARAMS:
      return Object.assign({}, state, { queryParam: { ...DEFAULT_STATE.queryParam } });

    case Types.TASKS_LIBRARY_DUPLICATE_SUCCESS:
      const { duplicateData } = payload;
      const newListDup = [duplicateData, ...state.list];
      return Object.assign({}, state, { list: newListDup, total: state.total + 1 });

    case Types.TASKS_LIBRARY_DELETE_SINGLE_REQUEST:
    case Types.TASKS_LIBRARY_BULK_DELETE_TASKS_REQUEST:
      return Object.assign({}, state, { deleting: true });

    case Types.TASKS_LIBRARY_DELETE_SINGLE_SUCCESS: {
      const { taskId } = payload;
      const { selectedTasks } = state;
      let currentSelectedTasks = selectedTasks;
      if (taskId && selectedTasks.length > 0) {
        currentSelectedTasks = selectedTasks.filter(it => it !== taskId);
      }
      return Object.assign({}, state, { deleting: false, selectedTasks: currentSelectedTasks });
    }

    case Types.TASKS_LIBRARY_DELETE_SINGLE_FAILED:
    case Types.TASKS_LIBRARY_BULK_DELETE_TASKS_FAILED:
      return Object.assign({}, state, { deleting: false });

    case Types.TASKS_LIBRARY_UPDATE_SELECTED_TASK:
      const { data } = payload;
      return Object.assign({}, state, { selectedTasks: data });

    case Types.TASKS_LIBRARY_RESET_SELECTED_TASK:
      return Object.assign({}, state, { selectedTasks: [] });

    case Types.TASKS_LIBRARY_BULK_DELETE_TASKS_SUCCESS:
      const { newList, newTotal } = payload;
      return Object.assign({}, state, {
        deleting: false,
        selectedTasks: [],
        list: newList,
        total: newTotal,
      });

    case Types.TASKS_LIBRARY_UPDATE_SHARING_STATUS_SUCCESS:
      const { updateShare, user } = payload;
      const newListShare = map(state.list, item =>
        get(item, '_id', '') === get(updateShare, '_id', '')
          ? { ...item, owner: get(updateShare, 'owner', ''), share: get(updateShare, 'share', TEAM_SHARE_PRIVATE) }
          : item,
      );

      if (get(state, 'queryParam.sorter', '') === 'last_activity_at') {
        const currentIndex = findIndex(newListShare, it => {
          return it._id === get(updateShare, '_id', '');
        });
        newListShare.splice(currentIndex, 1);
        newListShare.unshift(updateShare);
      }

      let filterDataShare = [];
      newListShare.forEach(it => {
        if (it.share !== 0 || (it.share === 0 && checkIsOwner(it.owner, get(user, '_id', '')))) {
          filterDataShare.push(it);
        }
      });

      const isChangeTotalList = state.list.length !== filterDataShare.length;

      return Object.assign({}, state, {
        list: filterDataShare,
        total: isChangeTotalList ? state.total - 1 : state.total,
      });

    case Types.ADD_FROM_TASK_LIBRARY_SUCCESS:
      const { addTask, userAdd } = payload;
      let newListAdd = [];
      let isChangeTotalAdd = false;
      const result =
        get(userAdd, '_id', '') !== get(addTask, 'owner', '') &&
        get(addTask, 'share', TEAM_SHARE_PRIVATE) === TEAM_SHARE_PRIVATE;
      if (result) {
        newListAdd = state.list;
        isChangeTotalAdd = state.list.length !== newListAdd.length;
      } else {
        newListAdd = [addTask, ...state.list];
        isChangeTotalAdd = state.list.length !== newListAdd.length;
      }

      if (newListAdd.length === 21) {
        newListAdd.pop();
      }

      return Object.assign({}, state, {
        list: newListAdd,
        total: isChangeTotalAdd ? state.total + 1 : state.total,
      });

    case Types.UPDATE_FROM_TASK_LIBRARY_SUCCESS:
      const { updateTask, loggedId } = payload;
      const newListUpdate = map(state.list, item =>
        get(item, '_id', '') === get(updateTask, '_id', '') ? merge(item, updateTask) : item,
      );

      if (get(state, 'queryParam.sorter', '') === 'last_activity_at') {
        const currentIndex = findIndex(newListUpdate, it => it._id === get(updateTask, '_id', ''));
        newListUpdate.splice(currentIndex, 1);
        newListUpdate.unshift(updateTask);
      }

      let filterData = [];
      newListUpdate.forEach(it => {
        if (it.share !== 0 || (it.share === 0 && checkIsOwner(it.owner, loggedId))) {
          filterData.push(it);
        }
      });

      return Object.assign({}, state, { list: filterData });

    case Types.RESET_TASK_LIBRARY_STORE:
      return DEFAULT_STATE;

    default:
      return state;
  }
};

export default TasksReducer;
