import { filter, set } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isOnwer } from 'utils/validations';
import { push } from 'connected-react-router';

import { toggleConfirmModal } from 'actions/modal';
import { leaveGroup, leaveAndDeleteGroup } from 'redux/forum/actions';
import { ASSET_TYPE } from 'components/Product/constants';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';

import ConfirmLeaveModal from './ConfirmLeaveModal';
import TransferMember from './TransferMember';

const LeaveGroup = ({
  user,
  toggleConfirmModal,
  leaveGroup,
  group,
  members,
  leaveAndDeleteGroup,
  list,
  push,
  assigned,
  assignedOnboardingFlow,
}) => {
  const [shouldRenderDeleteAssetModal, setShouldRenderDeleteAssetModal] = useState(assigned || assignedOnboardingFlow);
  const handelConfirmLeave = async () => {
    const teammates = filter(members, member => user._id !== member._id && member.role !== 0);
    const isAuthor = group.author === user._id;
    if (teammates.length > 0 && isAuthor) {
      toggleConfirmModal(true, <TransferMember onConfirm={handleConfirmTransfer} teammates={teammates} />);
    } else {
      if (isAuthor) {
        leaveAndDeleteGroup && (await leaveAndDeleteGroup(group));
      } else {
        await leaveGroup({
          group,
        });
      }
      handleSelectForum();
    }
  };

  const handleSelectForum = () => {
    const activeList = list.filter(it => it._id !== group._id);
    activeList.length > 0 && push ? push(`/home/forums/${activeList[0]._id}/discussion`) : push(`/home/forums`);
  };

  const handleConfirmTransfer = async teammate => {
    if (leaveGroup) {
      await leaveGroup({
        group,
        teammate,
      });
      handleSelectForum();
    }
  };

  const modalContent = useMemo(() => {
    const isAuthor = group.author === user._id;
    const teammates = filter(members, member => user._id !== member._id && member.role !== 0);

    if (!isAuthor) {
      return {
        title: 'Leave this forum?',
        message: 'Do you want to leave the forum? Or you may want to simply turn off notifications instead.',
        confirmButton: 'Leave',
      };
    }
    if (teammates.length > 0) {
      return {
        title: 'Who’s in charge?',
        message: 'Transfer the forum to another teammate before you leave.',
        confirmButton: 'Transfer ownership',
      };
    } else {
      return {
        title: 'Leave and Delete forum?',
        message: `Do you want to leave ‘${group.name}’? Since you are the only coach, leaving now will also delete this forum`,
        confirmButton: 'Leave and delete',
      };
    }
  }, [members]);

  if (
    assigned &&
    shouldRenderDeleteAssetModal &&
    filter(members, member => user._id !== member._id && member.role !== 0).length === 0
  ) {
    return (
      <DeleteAssetConfirmModal assetType={ASSET_TYPE.FORUM} onConfirm={() => setShouldRenderDeleteAssetModal(false)} />
    );
  }

  if (
    assignedOnboardingFlow &&
    shouldRenderDeleteAssetModal &&
    filter(members, member => user._id !== member._id && member.role !== 0).length === 0
  ) {
    return (
      <DeleteAssetConfirmModal
        isOnboardingFlow
        title="Forum is assigned to an Onboarding Flow"
        content="This Forum is in at least 1 onboarding flow. If the Forum is removed, it will be removed from all onboarding flows as well."
        assetType={ASSET_TYPE.FORUM}
        onConfirm={() => setShouldRenderDeleteAssetModal(false)}
      />
    );
  }

  return (
    <ConfirmLeaveModal
      title={modalContent.title}
      message={modalContent.message}
      onConfirm={handelConfirmLeave}
      confirmTitle={modalContent.confirmButton}
    />
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      forum: { group, members, list },
    },
  } = state;
  return {
    group,
    members,
    list,
    user,
  };
};

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  leaveGroup: bindActionCreators(leaveGroup, dispatch),
  leaveAndDeleteGroup: bindActionCreators(leaveAndDeleteGroup, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(LeaveGroup);
