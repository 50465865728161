import get from 'lodash/get';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import filter from 'lodash/filter';
import moment from 'moment';
import { SORT_ENUM } from 'constants/commonData';
import { Types } from './actions';
import { MOST_RECENT_KEY, WAITLIST_STATUS } from 'components/Package/PackageDetail/WaitList/constants';

export const DEFAULT_STATE = {
  list: [],
  clientIdSelected: [],
  clientIdsSelected: [],
  loading: true,
  total: 0,
  isSubmitting: false,
  queryParam: {
    pageSize: 20,
    page: 1,
    q: '',
    sort: MOST_RECENT_KEY,
    order: SORT_ENUM.DESCENDING,
    status: [],
  },
};

const WaitListReducer = (state = DEFAULT_STATE, action) => {
  const { payload } = action;
  let newList = [...state.list];

  switch (action.type) {
    case Types.WAIT_LIST_RESET_STATE: {
      return DEFAULT_STATE;
    }

    case Types.WAIT_LIST_GET_LIST_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.WAIT_LIST_GET_LIST_SUCCESS: {
      const { data, page } = payload;
      return Object.assign({}, state, {
        page,
        loading: false,
        total: get(data, 'total', 0),
        list: get(data, 'data', []),
      });
    }

    case Types.WAIT_LIST_GET_LIST_FAILED: {
      return Object.assign({}, state, { loading: false, list: [] });
    }

    case Types.WAIT_LIST_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, { queryParam: { ...payload.data } });
    }

    case Types.WAIT_LIST_SELECT_CLIENT_IDS: {
      const { clientIds } = payload;
      return Object.assign({}, state, { clientIdsSelected: clientIds, clientIdSelected: [] });
    }

    case Types.WAIT_LIST_SELECT_CLIENT_ID: {
      const { clientId } = payload;
      return Object.assign({}, state, { clientIdSelected: clientId });
    }

    case Types.WAIT_LIST_APPROVE_REQUEST: {
      return Object.assign({}, state, { isSubmitting: true });
    }

    case Types.WAIT_LIST_APPROVE_SUCCESS: {
      forEach(payload.ids, id => {
        const index = findIndex(newList, item => item.id === id);
        if (index !== -1) {
          set(newList[index], 'status', WAITLIST_STATUS.OFFERED);
          set(newList[index], 'trainer_info', payload.trainer_info);
          set(newList[index], 'approved_at', moment().toISOString());
        }
      });

      return Object.assign({}, state, {
        list: newList,
        isSubmitting: false,
        clientIdSelected: [],
        clientIdsSelected: filter(state.clientIdsSelected, clientId => !payload.ids.includes(clientId)),
      });
    }

    case Types.WAIT_LIST_APPROVE_FAILED: {
      return Object.assign({}, state, { isSubmitting: false });
    }

    case Types.WAIT_LIST_REJECT_REQUEST: {
      return Object.assign({}, state, { isSubmitting: true });
    }

    case Types.WAIT_LIST_REJECT_SUCCESS: {
      forEach(payload.ids, id => {
        const index = findIndex(newList, item => item.id === id);
        if (index !== -1) {
          set(newList[index], 'status', WAITLIST_STATUS.REJECTED_BY_COACH);
          set(newList[index], 'trainer_info', payload.trainer_info);
          set(newList[index], 'rejected_at', moment().toISOString());
        }
      });

      return Object.assign({}, state, {
        list: newList,
        isSubmitting: false,
        clientIdSelected: [],
        clientIdsSelected: filter(state.clientIdsSelected, clientId => !payload.ids.includes(clientId)),
      });
    }

    case Types.WAIT_LIST_REJECT_FAILED: {
      return Object.assign({}, state, { isSubmitting: false });
    }

    case Types.WAIT_LIST_UNDO_REQUEST: {
      return Object.assign({}, state, { isSubmitting: true });
    }

    case Types.WAIT_LIST_UNDO_SUCCESS: {
      const index = findIndex(newList, item => item.id === payload.data.id);
      if (index !== -1) {
        set(newList[index], 'status', get(payload, 'data.status'));
        set(newList[index], 'is_marked_as_undo', get(payload, 'data.is_marked_as_undo', false));
      }
      return Object.assign({}, state, {
        list: newList,
        isSubmitting: false,
      });
    }

    case Types.WAIT_LIST_UNDO_FAILED: {
      return Object.assign({}, state, { isSubmitting: false });
    }

    case Types.WAIT_LIST_RESEND_REQUEST: {
      return Object.assign({}, state, { isSubmitting: true });
    }

    case Types.WAIT_LIST_RESEND_SUCCESS: {
      const index = findIndex(newList, item => item.id === payload.id);
      if (index !== -1) {
        set(newList[index], 'status', WAITLIST_STATUS.OFFERED);
        set(newList[index], 'trainer_info', payload.trainer_info);
      }
      return Object.assign({}, state, {
        list: newList,
        isSubmitting: false,
      });
    }

    case Types.WAIT_LIST_RESEND_FAILED: {
      return Object.assign({}, state, { isSubmitting: false });
    }

    default:
      return state;
  }
};

export default WaitListReducer;
