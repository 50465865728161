import styled from 'styled-components';

export const Wrapper = styled.div`
  .collectionArea__content {
    background: #f5f5f5;
    border-radius: 8px;
    display: inline-flex;
  }

  .collectionArea__image {
    position: relative;
    width: 240px;

    img {
      position: absolute;
      bottom: 0px;
      left: 15px;
      width: 230px;
    }
  }

  .collectionArea__info {
    padding: 32px 30px;

    &--title {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #202353;
      margin-bottom: 5px;
      max-width: 206px;
    }

    &--description {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #202353;
      opacity: 0.8;
      margin-bottom: 20px;
      max-width: 206px;
    }

    &--button {
      background: #419aea;
      border-radius: 5px;
      width: 100%;
      color: #fff;
      border: none;
    }
  }
`;
