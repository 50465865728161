import React from 'react';
import { connect } from 'react-redux';

import * as S from './style';
import InviteInput from './InviteInput';
import CopyButton from './CopyButton';
import EarnedCredit from './EarnedCredit';
import { referralAmount } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

class ReferralForm extends React.Component {
  constructor(props) {
    super(props);
    this.getReferralLink = this.getReferralLink.bind(this);
  }

  getReferralLink = referralCode => {
    return `${window.location.origin}/register?code=${referralCode}`;
  };

  render() {
    const { user } = this.props;
    return (
      <S.Wrapper>
        <div className="referralForm__content">
          <div className="referralForm__leftContent">
            <div className="referralForm__title">
              Give ${referralAmount()}, Get ${referralAmount()} for each referral!!
            </div>
            <div className="referralForm__description">
              Lovin’ it? Share Everfit with your friends and each of you will earn ${referralAmount()} in credits to use
              towards subscriptions.
            </div>
            <div className="referralForm__inviteFriend">
              <InviteInput />
              <div className="referralForm__inviteFriend--des">Use commas “ , “ to separate several people</div>
            </div>
            <div className="referralForm__actions">
              <CopyButton copyType="link" copyValue={this.getReferralLink(user.referral_code)} />
              <CopyButton copyType="code" copyValue={user.referral_code} />
            </div>
          </div>
          <div className="referralForm__rightContent">
            <img src={`${CDN_URL}/images/referral_program_right_icon.png`} alt="" />
          </div>
          {user.referral_earned_credit > 0 && (
            <EarnedCredit earnedCredit={user.referral_earned_credit} className="referralForm__earnedCredit" />
          )}
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateToProps = (state: State) => {
  const { user } = state;
  return {
    user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralForm);
