// Libs
import React from 'react';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_thin.svg';

// Styles
import * as S from './style';

const ButtonCreate = ({ title, disabled, onClick }) => {
  return (
    <S.Button disabled={disabled} onClick={onClick}>
      <PlusIcon className="custom-icon" />
      {title}
    </S.Button>
  );
};

export default ButtonCreate;
