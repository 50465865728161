import React from 'react';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';
import { showError } from 'actions/error';
import { toggleModal } from 'actions/modal';
import { showSuccess, hideSuccess } from 'redux/modal/modal.actionCreators';
import { getQueryParamsFromObject } from 'utils/commonFunction';
import _ from 'lodash';
import Request from 'configs/request';
import { Workout } from 'types/model';
import { onSubmitting, onSubmitted } from 'actions/submitStatus';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import { MEDIA_PLACEHOLDER, WORKOUT_BUILDER_TYPES } from 'constants/commonData';

export const Types = {
  LOAD_MOST_RECENT_WORKOUT_SUCCESS: 'LOAD_MOST_RECENT_WORKOUT_SUCCESS',
  WORKOUT_LIBRARY_UPDATE_SHARING_STATUS: 'WORKOUT_LIBRARY_UPDATE_SHARING_STATUS',
  WORKOUT_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS: 'WORKOUT_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS',
  WORKOUT_LIBRARY_FAILED_UPDATE_SHARING_STATUS: 'WORKOUT_LIBRARY_FAILED_UPDATE_SHARING_STATUS',
  WORKOUT_LIBRARY_SUCCESS_GET_LIST: 'WORKOUT_LIBRARY_SUCCESS_GET_LIST',
  WORKOUT_LIBRARY_FAILED_GET_LIST: 'WORKOUT_LIBRARY_FAILED_GET_LIST',
  WORKOUT_LIBRARY_CHANGE_QUERY_PARAMS: 'WORKOUT_LIBRARY_CHANGE_QUERY_PARAMS',
  WORKOUT_LIBRARY_RESET_QUERY_PARAMS: 'WORKOUT_LIBRARY_RESET_QUERY_PARAMS',
  WORKOUT_LIBRARY_LOADING_DATA: 'WORKOUT_LIBRARY_LOADING_DATA',
  UPDATE_TAG_IN_WORKOUTS: 'UPDATE_TAG_IN_WORKOUTS',
  DELETE_TAG_IN_WORKOUTS: 'DELETE_TAG_IN_WORKOUTS',
  WORKOUT_LIBRARY_UPDATE_SELECTED_WORKOUT: 'WORKOUT_LIBRARY_UPDATE_SELECTED_WORKOUT',
  WORKOUT_LIBRARY_BULK_ADD_WORKOUT_REQUEST: 'WORKOUT_LIBRARY_BULK_ADD_WORKOUT_REQUEST',
  WORKOUT_LIBRARY_BULK_ADD_WORKOUT_SUCCESS: 'WORKOUT_LIBRARY_BULK_ADD_WORKOUT_SUCCESS',
  WORKOUT_LIBRARY_BULK_ADD_WORKOUT_FAIL: 'WORKOUT_LIBRARY_BULK_ADD_WORKOUT_FAIL',
  WORKOUT_LIBRARY_RESET_SELECTED_WORKOUTS_SUCCESS: 'WORKOUT_LIBRARY_RESET_SELECTED_WORKOUTS_SUCCESS',
  FETCH_ALL_EXERCISES_SUCCESS: 'FETCH_ALL_EXERCISES_SUCCESS',
};

export const updateSelectedWorkout = workoutIds => {
  return { type: Types.WORKOUT_LIBRARY_UPDATE_SELECTED_WORKOUT, payload: { data: workoutIds } };
};

export const removeWorkout = workoutId => {
  return Request.delete(
    { url: `/api/workout/v2/delete/${workoutId}` },
    false,
    (result, { dispatch, getState }) => {
      const {
        rootReducer: { workoutLibrary },
      } = getState();
      const { listWorkout, query } = workoutLibrary;
      if (query.page > 1 && listWorkout.length === 1) {
        dispatch(getWorkoutLibrary({ page: query.page - 1 }));
      } else {
        dispatch(getWorkoutLibrary());
      }
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_REMOVE_WORKOUT', error });
    },
  );
};

export const selectedWorkout = (workoutId, onSuccess, from) => {
  return dispatch => {
    return dispatch(
      Request.get(
        { url: `/api/workout/v2/detail/${workoutId}?from=${from}` },
        true,
        result => {
          if (typeof onSuccess === 'function') {
            const workout = result.data.data;
            onSuccess(workout);
          }
        },
        error => {},
      ),
    );
  };
};

export const getListWorkouts = params => {
  return (dispatch, getState) => {
    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      const currentState = getState();
      queryParams = _.pick(currentState.rootReducer.workoutLibrary, ['page', 'per_page', 'search', 'sorter', 'sort']);
    }

    return dispatch(
      Request.get(
        { url: `/api/workout/list_light?${getQueryParamsFromObject(queryParams)}` },
        true,
        result => {
          dispatch(toggleModal(false));
          dispatch({ type: 'SUCCESS_GET_WORKOUT_LIST', payload: result.data });
        },
        error => {
          dispatch({ type: 'ERROR_GET_LIST_WORKOUT', error: error });
          dispatch(
            showError(
              'Please check your internet connection and try again.',
              'Offline',
              'Ok',
              null,
              'error-network-button',
            ),
          );
        },
      ),
    );
  };
};

export const addWorkout = (workoutData, saveAsCopy = false) => {
  return (dispatch, getState) => {
    const { user } = getState();

    let params = {
      timezone: DateTime.local().zoneName,
      title: workoutData.title,
      description: workoutData.description || '',
      background: workoutData.background,
      author: _.get(workoutData, 'author._id', user._id),
      share: workoutData.share,
      sections: removeMedialDefault(workoutData.sections),
      tags: workoutData.tags,
      is_generated_by_ai: workoutData.is_generated_by_ai || false,
      conversion_id: workoutData.is_generated_by_ai ? workoutData.conversion_id || null : null,
      is_edited: workoutData.is_generated_by_ai ? workoutData.is_edited || false : undefined,
    };

    if (saveAsCopy) {
      params.author = user._id;
      params.share = 0;
    }

    return dispatch(
      Request.post({ url: '/api/workout/v2/add', data: params }, true, (response, { dispatch }) => {
        dispatch(getWorkoutLibrary());
      }),
    );
  };
};

export const cloneWorkoutTemplate = (workoutId, isDuplicate = false) => {
  return Request.get({ url: `/api/workout/v2/detail/${workoutId}` }, true, (response, { dispatch }) => {
    const { data } = response.data;
    const workingWorkout = new Workout().parseFromWorkout(data);
    delete workingWorkout._id;
    workingWorkout.title = '';
    if (isDuplicate) {
      workingWorkout.author = null;
    }

    dispatch(
      toggleModal(
        true,
        <WorkoutDetailModal
          key={`clone-workout__${data._id}`}
          workingWorkout={workingWorkout}
          type={WORKOUT_BUILDER_TYPES.TEMPLATE}
          pdfType="workout"
          onSave={async workout => {
            try {
              const response = await dispatch(addWorkout(workout));
              return response.data.data;
            } catch (err) {
              return Promise.resolve(null);
            }
          }}
          onSavedWithoutClose={newWorkout => {
            dispatch(openWorkoutDetail(newWorkout._id));
            dispatch(push(`/home/workout/${newWorkout._id}/detail`));
          }}
        />,
      ),
    );
  });
};

export const removeMedialDefault = sections => {
  return (sections || []).map(item => {
    const attachments = item.attachments || [];
    return {
      ...item,
      attachments: attachments.map(attachItem => ({
        ...attachItem,
        thumbnail_url: attachItem && attachItem.thumbnail_url === MEDIA_PLACEHOLDER ? '' : attachItem.thumbnail_url,
      })),
    };
  });
};

export const updateWorkout = workoutData => {
  const data = {
    workoutId: workoutData._id,
    title: workoutData.title,
    description: workoutData.description,
    author: _.get(workoutData, 'author._id', null),
    background: workoutData.background,
    share: workoutData.share,
    sections: removeMedialDefault(workoutData.sections),
    tags: workoutData.tags,
  };

  return Request.put({ url: `/api/workout/v2/update/${workoutData._id}`, data }, false, (response, { dispatch }) => {
    dispatch(getWorkoutLibrary());
  });
};

export const openWorkoutDetail = workoutId => {
  return dispatch => {
    return dispatch(
      Request.get({ url: `/api/workout/v2/detail/${workoutId}` }, false, result => {
        const woData = result.data.data;
        const workout = new Workout();
        const parseData = workout.parseFromWorkout(woData);
        const workingWorkout = _.cloneDeep(parseData);

        dispatch(
          toggleModal(
            true,
            <WorkoutDetailModal
              key={`workout-detail__${workingWorkout._id}`}
              workingWorkout={workingWorkout}
              pdfType="workout"
              onSave={async (workoutData, saveAsCopy) => {
                if (saveAsCopy) {
                  return dispatch(addWorkout(workoutData, true));
                }

                try {
                  const response = await dispatch(updateWorkout(workoutData));
                  return response.data.data;
                } catch (err) {
                  return Promise.resolve(null);
                }
              }}
              type={WORKOUT_BUILDER_TYPES.TEMPLATE}
              onClose={() => dispatch(push('/home/workout'))}
              onDelete={workout => dispatch(removeWorkout(workout._id))}
            />,
          ),
        );
      }),
    );
  };
};

export const updateWorkoutSharingStatus = (data, isOwner) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.WORKOUT_LIBRARY_UPDATE_SHARING_STATUS });

    return dispatch(
      Request.put(
        { url: `/api/workout/v2/update/${data.workoutId}`, data },
        true,
        () => {
          if (isOwner) {
            dispatch({ type: Types.WORKOUT_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS, payload: { data } });
          } else {
            dispatch(getWorkoutLibrary());
          }
        },
        () => dispatch({ type: Types.WORKOUT_LIBRARY_FAILED_UPDATE_SHARING_STATUS }),
      ),
    );
  };
};

export const changeQueryParams = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        workoutLibrary: { query },
      },
    } = getState();

    const newData = { ...query, ...data };

    dispatch({
      type: Types.WORKOUT_LIBRARY_CHANGE_QUERY_PARAMS,
      payload: { data: newData },
    });
    dispatch(getWorkoutLibrary(newData));
  };
};

export const resetQueryParams = (shouldRefetch = false) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.WORKOUT_LIBRARY_RESET_QUERY_PARAMS });
    if (shouldRefetch) {
      dispatch(getWorkoutLibrary());
    }
  };
};

export const getWorkoutLibrary = params => {
  return (dispatch, getState) => {
    let queryParams;

    if (params) {
      queryParams = params;
    } else {
      const {
        rootReducer: {
          workoutLibrary: { query },
        },
      } = getState();

      queryParams = _.cloneDeep(query);
    }

    if (queryParams.search_tags && queryParams.search_tags.length > 0) {
      queryParams.search_tags = queryParams.search_tags.map(tag => tag._id);
    }

    dispatch({ type: Types.WORKOUT_LIBRARY_LOADING_DATA });

    return dispatch(
      Request.get(
        { url: `/api/workout/v2/list?${getQueryParamsFromObject(queryParams)}` },
        true,
        result => {
          dispatch({ type: Types.WORKOUT_LIBRARY_SUCCESS_GET_LIST, payload: { data: result.data } });
        },
        error => {
          dispatch({ type: Types.WORKOUT_LIBRARY_FAILED_GET_LIST, error: error });
        },
      ),
    );
  };
};

export const getWorkoutLibraryDetail = workoutId => {
  return Request.get({ url: `/api/workout/v2/detail/${workoutId}` }, true);
};

export const updateTagInWorkouts = (id, name) => ({
  type: Types.UPDATE_TAG_IN_WORKOUTS,
  payload: {
    id,
    name,
  },
});

export const deleteTagInWorkouts = id => ({
  type: Types.DELETE_TAG_IN_WORKOUTS,
  payload: {
    id,
  },
});

export const addBulkAddTagWorkouts = data => {
  return (dispatch, getState) => {
    dispatch({ type: Types.WORKOUT_LIBRARY_BULK_ADD_WORKOUT_REQUEST });
    return dispatch(
      Request.post(
        { url: '/api/workout/bulk-add-tag', data },
        true,
        (result, { dispatch }) => {
          const { data } = result.data;
          if (data) {
            dispatch(getWorkoutLibrary());
            dispatch(updateSelectedWorkout([]));
            if (data.listInvalidWorkoutTitle.length) {
              const message = `You are not authorized to edit these workouts: ${data.listInvalidWorkoutTitle.join(
                ', ',
              )}`;
              dispatch(showError(message.length > 165 ? `${message.substring(0, 165)}...` : message));
            }
          }
          dispatch({ type: Types.WORKOUT_LIBRARY_BULK_ADD_WORKOUT_SUCCESS });
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.WORKOUT_LIBRARY_BULK_ADD_WORKOUT_FAIL, error });
        },
      ),
    );
  };
};

export const fetchAllExercises = () => {
  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: '/api/exercise/search_filter',
          data: {
            body_part: [],
            category_type: [],
            from: [false, false],
            video_only: false,
            tags: [],
            q: '',
            page: 1,
            per_page: 4000,
            sort: -1,
            sorter: 'last_interacted',
            excludes: [],
          },
        },
        false,
        (result, { dispatch }) => {
          const { data } = result.data;
          dispatch({ type: Types.FETCH_ALL_EXERCISES_SUCCESS, payload: data || [] });
        },
      ),
    );
  };
};

export const resetSelectedWorkout = () => {
  return { type: Types.WORKOUT_LIBRARY_RESET_SELECTED_WORKOUTS_SUCCESS };
};
