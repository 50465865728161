import styled, { css } from 'styled-components';
import { PRODUCT_STATUS } from '../constants';

export const ProductListNavArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin-right: 10px;
  overflow: hidden;
`;

export const Status = styled.div`
  display: flex;
  height: 21px;
  color: #202353;
  padding: 0 10px;
  margin-top: 2px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-transform: capitalize;
  background: #ebedf4;
  border: 1px solid #d8dceb;
  box-sizing: border-box;
  border-radius: 3px;
  align-items: center;
  justify-content: center;

  ${props =>
    props.status === PRODUCT_STATUS.PUBLISH &&
    css`
      color: #36b24b;
      background: #dff5e1;
      border: 1px solid #36b24b;
    `}
}`;
