import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import TimeField from 'react-simple-timefield';
import {
  convertSecondToTimeString,
  convertTimeStringToSecond,
  convertSecondToTimeStringForRest,
  convertTimeStringToSecondForRest,
} from 'helpers/time';
import RepRangeInput from 'shared/RepRangeInput';
import { validatePositiveIntegerNumberOnly, validateNumberOnly } from 'utils/validations';
import { convertUnit, setupWheelEventListener } from 'utils/commonFunction';
import { roundTwoDigit } from 'helpers/number';

export default function FieldInput(props) {
  const { setData, field, disabled, useORM, supersetORMData, missingORMBValue } = props;
  let time, displayValue;

  const fieldData = useMemo(() => {
    const result = { ...field };
    result.inputValue = _.get(setData, field.unique_code, { value: '' });

    return result;
  }, [setData, field]);

  const isRest = fieldData.unique_code === 'rest';

  const handleInputFieldFocus = event => {
    const inputField = event.target;
    setupWheelEventListener(inputField);
    inputField.select();
  };

  const onNormalInputChange = useCallback(
    event => {
      const { value } = event.target;
      let newValue = value;

      if (isNaN(parseInt(newValue)) || newValue < 0) {
        newValue = '';
      } else if (fieldData.data_type === 'int') {
        newValue = parseInt(newValue);
      }

      updateSetData(newValue);
    },
    [fieldData],
  );

  const onInputWithMaxChange = max =>
    useCallback(
      event => {
        const { value } = event.target;
        let newValue = value;

        if (isNaN(parseInt(newValue)) || newValue < 0) {
          newValue = '';
        } else if (newValue > max) {
          return;
        } else if (fieldData.data_type === 'int') {
          newValue = parseInt(newValue);
        }

        updateSetData(newValue);
      },
      [fieldData],
    );

  const onRepRangeInputChange = value => {
    updateSetData(value);
  };

  const onTimeInputChange = value => {
    const newValue = isRest ? convertTimeStringToSecondForRest(value) : convertTimeStringToSecond(value);
    updateSetData(newValue);
  };

  const updateSetData = (value, isChanged) => {
    const data = { [field.unique_code]: { value } };
    props.onChange(data, isChanged);
  };
  switch (fieldData.data_type) {
    case 'int':
      if (fieldData.unique_code === 'reps')
        return (
          <RepRangeInput
            className="fieldInput"
            defaultValue={fieldData.inputValue.value}
            placeholder="-"
            onChange={onRepRangeInputChange}
            disabled={disabled}
            onFocus={event => event.target.select()}
          />
        );

      displayValue = isNaN(parseInt(fieldData.inputValue.value)) ? '' : parseInt(fieldData.inputValue.value);

      if (fieldData.unique_code === 'hr') {
        return (
          <input
            className="fieldInput"
            step="1"
            min="0"
            max="200"
            value={displayValue}
            placeholder="-"
            type="number"
            onKeyPress={validatePositiveIntegerNumberOnly}
            onChange={onInputWithMaxChange(200)}
            disabled={disabled}
            onFocus={handleInputFieldFocus}
          />
        );
      }

      if (fieldData.unique_code === 'rir') {
        return (
          <input
            className="fieldInput"
            step="1"
            min="0"
            max="500"
            value={displayValue}
            placeholder="-"
            type="number"
            onKeyPress={validatePositiveIntegerNumberOnly}
            onChange={onInputWithMaxChange(500)}
            disabled={disabled}
            onFocus={handleInputFieldFocus}
          />
        );
      }

      return (
        <input
          className="fieldInput"
          step="1"
          min="0"
          value={displayValue}
          placeholder="-"
          type="number"
          onKeyPress={validatePositiveIntegerNumberOnly}
          onChange={onNormalInputChange}
          disabled={disabled}
          onFocus={handleInputFieldFocus}
        />
      );

    case 'time':
      time = isRest
        ? convertSecondToTimeStringForRest(fieldData.inputValue.value)
        : convertSecondToTimeString(fieldData.inputValue.value);

      return (
        <TimeField
          className="fieldInput--time"
          value={time}
          colon=":"
          showSeconds={!isRest}
          onChange={onTimeInputChange}
        />
      );

    default: {
      let shouldDisable = disabled;
      let displayValue, invalid;

      if (field.unique_code === 'weight' && useORM) {
        shouldDisable = true;
        const ormValue = Number(_.get(setData, 'orm.value') || 0);
        displayValue = ((Number(_.get(supersetORMData, 'value')) || 0) * ormValue) / 100;

        // TODO - Converting Unit by ORM
        if (supersetORMData) {
          displayValue = roundTwoDigit(
            convertUnit(displayValue, supersetORMData.set_unit, props.units[field.unique_code]),
          );
        }
      } else {
        displayValue = _.ceil(parseFloat(fieldData.inputValue.value), 2);
        displayValue = isNaN(displayValue) ? '' : displayValue;
      }

      if (_.get(setData, `${field.unique_code}.set_unit`)) {
        const oldUnits = setData[field.unique_code].set_unit;
        const newUnits = props.units[field.unique_code] ? props.units[field.unique_code] : oldUnits;
        if (oldUnits._id !== newUnits._id) {
          displayValue = roundTwoDigit(convertUnit(displayValue, oldUnits, newUnits));
          updateSetData(displayValue, false);
        }
      }

      return (
        <input
          className={classnames('fieldInput', {
            invalid: field.unique_code === 'orm' && missingORMBValue && displayValue === '',
          })}
          step="0.1"
          min="0"
          value={displayValue}
          placeholder="-"
          type="number"
          onKeyPress={validateNumberOnly}
          onChange={onNormalInputChange}
          disabled={shouldDisable}
          onFocus={handleInputFieldFocus}
        />
      );
    }
  }
}
