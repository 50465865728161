import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 576px;
    height: 661px;
    padding: 36px 32px;
    border-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    padding: 0;
  }
`;

export const ModalContainer = styled(Modal.Content)``;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const LeftHeader = styled.div`
  max-width: 322px;
`;

export const HeaderLabel = styled.div`
  color: #202353;
  font-size: 24px;
  font-weight: 600;
`;

export const HeaderDescription = styled.div`
  color: #505373;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 4px;
`;
export const RightHeader = styled.div``;

export const ChoosePlanGroupButton = styled.div`
  display: flex;
  width: 177px;
  padding: 7px 0px;
  align-items: center;
  position: relative;
  top: 14px;
  right: 13px;

  .monthly {
    position: absolute;
    left: 18px;
    padding: 10px 10px 8px 10px !important;
    border-right: 0px;
  }

  .annually {
    position: absolute;
    right: -5px;
    padding-left: 18px;
  }
`;

export const TimeButton = styled(Button)`
  display: flex;
  border: none;

  width: ${props => (props.active ? '83px' : '85px')};
  background: ${props => (props.active ? ' #202353 ' : '#E3E3E9')};
  z-index: ${props => (props.active ? 2 : 1)};
  border-radius: 4px;
  height: 28px;
  padding: 10px 14px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-size: 12px;
  font-weight: 600;
  color: ${props => (props.active ? '#fff' : 'rgba(32, 35, 83, 0.60)')};

  :hover {
    color: ${props => (props.active ? '#fff' : 'rgba(32, 35, 83, 0.60)')};
    background: ${props => (props.active ? ' #202353 ' : '#E3E3E9')};
  }
`;

export const Discount = styled.div`
  position: absolute;
  z-index: 3;
  right: 36px;
  top: 32px;
`;

export const DiscountCircle = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: #28ae66;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  left: 8px;
  bottom: 21px;
  border: 3px solid #fafafd;
`;

export const DiscountBorder = styled.div`
  width: 8px;
  height: 14px;
  border-bottom-right-radius: 80%;
  border: 1px dashed #28ae66;
  border-top: none;
  border-left: none;
`;

export const DiscountPercent = styled.div`
  color: #28ae66;
  font-size: 11px;
  font-weight: 700;
  line-height: 100%; /* 11px */
  text-transform: uppercase;
  position: absolute;
  right: 19px;
  top: 48px;
  z-index: 3;
`;

export const ModalContent = styled.div``;

export const PlanWrapper = styled.div`
  margin-bottom: 48px;
`;

export const PlanItemContainer = styled.div`
  display: flex;
  padding: 16px 16px 16.5px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  position: relative;

  border-radius: 5px;

  ${props =>
    props.checked
      ? css`
          border: 1px solid #5158cf;
          box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
        `
      : css`
          border: 1px solid #e8e8e8;
        `}

  margin-bottom: 12px;

  :hover {
    cursor: pointer;
    border: 1px solid #5158cf;
    box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  }
`;

export const CurrentPlan = styled.div`
  display: flex;
  height: 20px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #e7e6fe;
  position: absolute;
  left: -2px;
  top: -8px;

  color: #202353;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const CurrentInMorePlan = styled.div`
  display: flex;
  height: 20px;
  padding: 10px 8px;
  margin-top: 10px;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #e7e6fe;

  color: #202353;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const ScheduledPlan = styled.div`
  display: flex;
  height: 20px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #fef0e6;
  position: absolute;
  left: -2px;
  top: -8px;

  color: #f37335;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const ScheduledInMorePlan = styled.div`
  display: flex;
  height: 20px;
  padding: 10px 8px;
  margin-top: 10px;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #fef0e6;

  color: #f37335;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const TaskPerMonth = styled.div`
  display: flex;
  align-items: center;

  .radio {
    &.ui.radio.checkbox {
      margin-left: 2px;
      margin-top: 4px;
    }
  }
`;

export const SpanText = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

export const TaskPerMonthText = styled.div`
  color: #202353;
  font-size: 18px;
  font-weight: 600;

  margin-top: 7px;
  margin-left: 17px;

  ${SpanText} {
    margin-left: -2px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 164px;
`;

export const PerMonth = styled.div`
  color: #505373;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  text-align: end;
  margin-bottom: -3px;
`;

export const Price = styled.div`
  display: flex;
  color: #202353;
  font-size: 16px;
  font-weight: 600;
  margin-right: -5px;
  width: 164px;
  justify-content: flex-end;
`;

export const OldPrice = styled.div`
  color: #505373;
  font-size: 12px;
  font-weight: 400;
  text-decoration: line-through;
  margin-top: 4px;
  margin-right: 8px;
  text-align: start;
`;

export const Currency = styled.div`
  color: #202353;
  font-size: 14px;
  font-weight: 400;

  margin-left: 5px;
  margin-top: 2px;
`;

export const GetMore = styled.div`
  color: #505373;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 16px;
  span {
    font-weight: 600;
  }
`;

export const ContactLink = styled.a`
  color: #5158cf;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;

  :hover {
    cursor: pointer;
    color: #5158cf;
  }
`;

export const ModalFooter = styled.div``;

export const ConfirmButton = styled(Button)`
  display: flex;
  width: 512px;
  height: 52px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 5px;
  background: #0085ff;

  color: #fff;
  font-size: 14px;
  font-weight: 700;

  :hover {
    background: #0085ff;
    color: #ffff;
    opacity: 0.9;
    ${props =>
      props.isDowngrade &&
      css`
        border: 1px solid #5158cf;
        background: #fff;
        color: #5158cf;
      `}
  }

  :disabled {
    background: #dadfea;
    color: #91939a;
    :hover {
      opacity: 1;
    }
  }

  ${props =>
    props.isDowngrade &&
    css`
      border: 1px solid #d0d4ed;
      background: #fff;
      color: #afb4cf;
    `}
`;

export const GetMorePlan = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  .arrow-up-icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
    margin-top: 5px;
    path {
      stroke: #202353;
    }
  }

  .arrow-up-icon {
    transform: ${props => props.hasMorePlan && 'rotate(180deg)'};
  }
`;

export const MorePlan = styled.div`
  display: flex;
  max-height: 302px;
  width: 528px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  z-index: 4;

  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background: #fff;

  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);

  position: absolute;
  bottom: 252px;
  right: 24px;

  &::-webkit-scrollbar {
    width: 6px !important;
  }

  border-right-width: 4px;
  border-color: transparent;
`;

export const MorePlanItemContainer = styled.div`
  display: flex;
  padding: 11px 24px 12px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  position: relative;

  box-shadow: 0px -1px 0px 0px #f2f2f2 inset;

  :hover {
    background: #f4f4ff;
    cursor: pointer;
    ${TaskPerMonthText}, ${PerMonth}, ${Price}, ${Currency}, ${OldPrice} {
      color: #5158cf;
    }
  }

  ${props =>
    props.checked &&
    css`
      background: #f4f4ff;
      ${TaskPerMonthText}, ${PerMonth}, ${Price}, ${Currency}, ${OldPrice} {
        color: #5158cf;
      }
    `}

  ${RightContent} {
    margin-right: -7px;
  }

  ${PerMonth} {
    margin-right: 0;
  }
`;

export const DowngradeWrapper = styled.div``;

export const DowngradeConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    border-radius: 8px !important;
    width: 612px !important;
    .ui.button.confirm-no-button {
      color: #202353 !important;
      border: 1px solid #d9d9d9 !important;
    }
  }
  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }

  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    opacity: 0.9;
  }

  .close-button.ui.button {
    img {
      width: 20px;
      height: 20px;
    }
  }

  .close-button img {
  }

  .ui.button.confirm-yes-button {
    font-family: Open Sans !important;
    background-color: #fba703 !important;
    box-shadow: none !important;
    max-width: 196px !important;
    border: 0;
    padding: 7px 24px;
    height: 36px;
    border-radius: 3px;
  }

  .ui.button.confirm-no-button {
    font-family: Open Sans !important;
    color: #202353 !important;
    border: 1px solid #d9d9d9 !important;
    min-width: 90px !important;
    padding: 7px 24px;
    height: 36px;
    border-radius: 3px;
  }

  .confirm-popup-container .ui.button.confirm-no-button,
  .ui.button.confirm-yes-button {
    line-height: 20px !important;
  }

  .confirm-content-header {
    justify-content: center !important;
    padding: 36px 24px 1px !important;

    .confirm-header-label {
      color: #202353;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .confirm-content-body {
    padding: 32px 24px 24px 24px !important;
    .confirm-content-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
    }
  }

  .confirm-actions {
    padding: 24px 24px 36px 24px !important;
    .button {
      margin-left: 10px !important;
    }
  }
`;

export const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  background: #fff6f5;
  height: 82px;

  p {
    color: #e90e10;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.75 !important;

    span {
      font-weight: 600;
    }
  }
`;

export const DowngradeContent = styled.div`
  margin-top: 16px;
  color: #202353;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.75;

  span {
    font-weight: 600;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-left: 4px;
    position: relative;
    top: 3px;

    g path {
      fill: #36ba7b;
    }
  }
`;

export const FirstOption = styled.div`
  margin-top: 12px;
  margin-bottom: 5px;
`;

export const SecondOption = styled.div`
  margin-bottom: 12px;
`;

export const LastSentence = styled.div`
  color: #202353;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5 !important;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-items: center;
`;

export const BackButton = styled.button`
  background: none;
  border: 0;
  padding-left: 6px;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
    transform: rotate(270deg);
  }
`;

export const HeaderTitle = styled.div`
  width: 527px;
  padding-left: 4px;
`;
