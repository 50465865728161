import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  checkLastTrainer,
  getGroupMembers,
  leaveGroup,
  removeGroupMember,
  searchGroupMembers,
} from 'redux/inbox-new/actions';
import { push } from 'connected-react-router';

const mapState = state => ({
  cloudfrontList: state.cloudfrontList,
  totalParticipants: get(state, 'rootReducer.inboxNew.totalParticipants'),
  searchMembers: get(state, 'rootReducer.inboxNew.searchMembers'),
  viewTeammate: get(state, 'rootReducer.inboxNew.viewTeammate'),
  isGroupChatPermission: get(state, 'rootReducer.permission.group_chat', false),
});

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeGroupMember: bindActionCreators(removeGroupMember, dispatch),
  leaveGroup: bindActionCreators(leaveGroup, dispatch),
  push: bindActionCreators(push, dispatch),
  checkLastTrainer: bindActionCreators(checkLastTrainer, dispatch),
  getGroupMembers: bindActionCreators(getGroupMembers, dispatch),
  searchGroupMembers: bindActionCreators(searchGroupMembers, dispatch),
});

export default connect(mapState, mapDispatch)(Component);
