import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import * as S from './style';
import CommentsList from './CommentsList';

export default function (props) {
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    if (props.openCommentTask) {
      setShowing(true);
    }
  }, []);

  if (!props.task || !props.task._id) {
    return null;
  }

  // TODO: should return here to check
  // if (props.task.type !== 'general') {
  //     return null;
  // }

  // only show in client calendar - task tab
  if (!props.location.pathname.startsWith('/home/client')) {
    return null;
  }

  const toggleShowing = () => {
    setShowing(!showing);
  };

  return (
    <S.Wrapper>
      <S.CommentToggle showing={showing} onClick={toggleShowing} />
      {showing && (
        <CommentsList
          showing={showing}
          taskId={get(props, 'task._id', '')}
          onChangeUploadStatus={props.onChangeUploadStatus}
        />
      )}
    </S.Wrapper>
  );
}
