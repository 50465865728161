import get from 'lodash/get';

export const convertUnitName = (data = {}) => {
  const newData = { ...data };

  Object.keys(newData).forEach(key => {
    if (key !== 'others') {
      newData[key] = newData[key].map(group => ({
        ...group,
        units: group.units.map(unit => {
          const { name, formula } = unit || {};
          return {
            ...unit,
            name: `${name} (${formula})`,
          };
        }),
      }));
    }
  });

  return newData;
};

export const convertUnitForAutoCalculated = (data = {}, allowUnit = []) => {
  const newData = { ...data };
  Object.keys(newData).forEach(key => {
    if (key !== 'others') {
      newData[key] = newData[key].map(group => ({
        ...group,
        units: get(group, 'units', [])
          .map(unit => {
            const { name, formula } = unit || {};
            return {
              ...unit,
              name: `${name} (${formula})`,
            };
          })
          .filter(unit => allowUnit.includes((unit || {})._id)),
      }));
    } else {
      newData[key] = newData[key].filter(unit => (allowUnit || []).includes((unit || {})._id));
    }
  });

  return newData;
};

export const convertDataAmountPer = (data = {}) => {
  const { list = [], value = [] } = data;
  const selectedItem = list.find(item => value.includes(item._id)) || {};

  if (!selectedItem) {
    return { unit_id: undefined, value: undefined };
  }

  return {
    unit_id: selectedItem.unit_id,
    value: selectedItem.value,
  };
};
