import React, { useState } from 'react';
import classnames from 'classnames';
import { Accordion } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';

import { ReactComponent as SettingIcon } from 'assets/icons/task-advanced-setting-icon.svg';
import { ReactComponent as AccordionIcon } from 'assets/icons/task-accordion-icon.svg';

import * as S from './style';

import { Checkbox } from 'shared/FormControl';
import { KEY_ADVANCED_SETTINGS } from './constants';

const AdvancedSettings = props => {
  const { settings, onChangeSettings } = props;

  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(it => !it);
  };

  const handleOpenPopup = () => {
    setActive(true);
  };

  const handleClosePopup = () => {
    setActive(false);
  };

  const handleToggleSetting = key => e => {
    e.preventDefault();
    onChangeSettings(key, !settings[key]);
  };

  const renderOption = (key, label) => (
    <div className="setting-item" onClick={handleToggleSetting(key)}>
      <div className="checkbox-item">
        <Checkbox onClick={handleToggleSetting(key)} checked={settings[key]} className="setting-checkbox" size={20} />
      </div>
      <div className="label-item">{label}</div>
    </div>
  );

  return (
    <S.AdvancedSettingPopup
      trigger={
        <S.AdvancedSettingsWrapper active={active}>
          <Accordion className="poll-accordion">
            <Accordion.Title
              className={classnames('poll-advanced-settings-title', { 'highlight-title': active })}
              active={active}
              index={0}
              onClick={handleClick}
            >
              <div className="poll-settings-icon">
                <SettingIcon />
              </div>
              <div className="label">ADVANCED SETTING</div>
              <div className="poll-accordion-icon">
                <AccordionIcon />
              </div>
            </Accordion.Title>
          </Accordion>
        </S.AdvancedSettingsWrapper>
      }
      basic={true}
      flowing={true}
      hoverable={true}
      on="click"
      position="bottom right"
      open={active}
      onOpen={handleOpenPopup}
      onClose={() => {}}
    >
      <RootCloseWrapper event="click" onRootClose={handleClosePopup} disabled={false}>
        <div className="settings-wrapper">
          {renderOption(KEY_ADVANCED_SETTINGS.ALLOW_MULTIPLE_CHOICES, 'Allow multiple choices')}
          {renderOption(KEY_ADVANCED_SETTINGS.ALLOW_MEMBER_TO_ADD_OPTION, 'Allow member to add option')}
        </div>
      </RootCloseWrapper>
    </S.AdvancedSettingPopup>
  );
};

export default AdvancedSettings;
