import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { Button } from 'shared/FormControl';
import { TEAM_SHARE_NOOWNER } from 'constants/commonData';
import RenderOwnShip from 'components/Forms/components/AddNewForm/RenderOwnShip';

import * as S from './style';

export default function UpdatedShare(props) {
  const {
    section: { user, share, _id },
    toggleModal,
    onUpdate,
    user: currentUser,
  } = props;

  const [ownerValue, setOwnerValue] = useState({});
  const [shareValue, setShareValue] = useState({});

  useEffect(() => {
    setShareValue({
      share: share,
    });

    share !== TEAM_SHARE_NOOWNER &&
      setOwnerValue({
        owner: get(user, '_id', ''),
      });
  }, [user, share]);

  const onSetShareValue = value => {
    setShareValue(value);
  };

  const onSetOwnerValue = value => {
    setOwnerValue(value);
  };

  const onClose = () => {
    toggleModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const shareStatus = {
      sectionLibraryId: _id,
      share: get(shareValue, 'share', null),
    };

    if (get(shareValue, 'share', null) !== TEAM_SHARE_NOOWNER) {
      shareStatus.authorId = get(ownerValue, 'owner', '');
    } else {
      shareStatus.author_name = null;
    }

    onUpdate(shareStatus, true, () => {});

    toggleModal(false);
  };

  return (
    <S.ModalShare open={true} className="tasks-share-owner">
      <S.ModalShare.Header>Sharing settings</S.ModalShare.Header>
      <S.ModalShare.Content>
        <S.Form>
          <RenderOwnShip
            userId={currentUser && currentUser._id}
            ownerValue={ownerValue}
            shareValue={shareValue}
            onSetShareValue={onSetShareValue}
            onSetOwnerValue={onSetOwnerValue}
            type="update"
            hasAvatar
            hasSpacing
            hasYou
          />
        </S.Form>
        <S.Footer>
          <Button className="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button className={classNames('submit')} onClick={e => handleSubmit(e)} purple>
            Save
          </Button>
        </S.Footer>
      </S.ModalShare.Content>
    </S.ModalShare>
  );
}
