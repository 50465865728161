import React from 'react';

import Column from './components/Column';
import CalendarActions from './components/CalendarActions';
import ButtonPasteWeek from './components/ButtonPasteWeek';
import UpgradePath from 'shared/UpgradePath';
import UnlimitedSchedulingFailBack from 'shared/UpgradePath/components/UnlimitedSchedulingFailBack';
import { resetCopyAssignment } from 'actions/workout/getWorkouts';

import './styles.scss';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentWeekIndexHover: -1,
    };
  }

  componentDidMount() {
    const { onMount } = this.props;

    if (onMount && typeof onMount === 'function') {
      onMount();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetCopyAssignment());
  }

  handleCurrentWeekHover = id => {
    this.setState({
      currentWeekIndexHover: id,
    });
  };

  handleResetCurrentWeek = () => {
    this.setState({
      currentWeekIndexHover: -1,
    });
  };

  render() {
    const { calendarType, calendarStartDate } = this.props;
    const { currentWeekIndexHover } = this.state;

    return (
      <div className="calendar-columns" onMouseLeave={this.handleResetCurrentWeek}>
        <CalendarActions
          currentWeekIndexHover={currentWeekIndexHover}
          handleCurrentWeekHover={this.handleCurrentWeekHover}
          {...this.props}
        />
        <ButtonPasteWeek currentWeekIndexHover={currentWeekIndexHover} {...this.props} />
        {Array(7)
          .fill(null)
          .map((_, index) => (
            <Column column={index} key={index} {...this.props} handleCurrentWeekHover={this.handleCurrentWeekHover} />
          ))}
        <UpgradePath
          pathName="unlimitedScheduling"
          fallback={
            <UnlimitedSchedulingFailBack
              noWeeks={calendarType}
              startDate={calendarStartDate.toISODate()}
              onMouseEnter={this.handleResetCurrentWeek}
            />
          }
        />
      </div>
    );
  }
}

export default Calendar;
