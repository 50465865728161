import { connect } from 'react-redux';
import _, { sortBy } from 'lodash';
import CalendarHistoryCellComponent from './component';
import { getAssignmentDetail } from 'actions/workout/getWorkouts';
import { DateTime } from 'luxon';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

const mapStateToProps = (state, ownProps) => {
  const {
    allExercises,
    workouts,
    loggedActivities,
    calendarType,
    isModalOpen,
    modal,
    popup,
    rootReducer,
    movingSet,
  } = state;

  const { workingClientDetail } = rootReducer.client;
  const timezone = _.get(workingClientDetail, 'timezone', '');
  const currentDay = DateTime.local().setZone(timezone).startOf('day');
  const isCurrentDay = currentDay.toFormat('MM-dd-yyyy') === ownProps.day.startOf('day').toFormat('MM-dd-yyyy');
  const diff = ownProps.day.startOf('day').diff(currentDay, 'days');
  const workoutItems = workouts.get(ownProps.day.toFormat('MM-dd-yyyy'));
  const completedWorkoutItems = _.filter(workoutItems, workout => workout.status === 2);
  const activityItem = loggedActivities.get(ownProps.day.toFormat('MM-dd-yyyy'));
  // if calendar cell's date is in the past, show all workouts
  // if calendar date is current day, only show completed workout
  // if calendar date is in the future, not show anything
  let currentWorkouts =
    diff.values.days < 0 && !isCurrentDay ? workoutItems : isCurrentDay ? completedWorkoutItems : null;
  let currentActivities = activityItem ? sortBy(activityItem, activity => activity.end_time) : [];
  const onDemandVideoWorkouts = _.filter(currentActivities, activity => activity.log_type === 'ondemand_video');
  if (onDemandVideoWorkouts.length > 0) {
    // if (!currentWorkouts) console.log('==>', ownProps.day.toFormat('MM-dd-yyyy')); // TODO: investigate
    if (!currentWorkouts) currentWorkouts = [];
    currentWorkouts = _.sortBy(_.concat(currentWorkouts, onDemandVideoWorkouts), el => {
      const logType = _.get(el, 'log_type');
      if (logType && logType === 'ondemand_video') {
        return el.end_time;
      } else {
        return el.end_date;
      }
    });
    currentActivities = _.filter(currentActivities, activity => {
      const logType = _.get(activity, 'log_type', 'normal');
      return logType === 'normal';
    });
  }

  return {
    allExercises,
    currentWorkouts,
    currentActivities,
    calendarType,
    isModalOpen,
    modal,
    popup,
    movingSet,
    selectedClient: _.get(workingClientDetail, '_id', ''),
    timezone: _.get(workingClientDetail, 'timezone', ''),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getAssignmentDetail: bindActionCreators(getAssignmentDetail, dispatch),
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarHistoryCellComponent);
