import styled, { css } from 'styled-components';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-alt.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-edit.svg';

export const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: 32px 0;
  margin: auto;
  font-family: 'Open Sans';
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 189px);
  overflow-y: scroll;
  padding: 5px 10px 20px 5px;

  ${props =>
    props.labelType &&
    css`
      height: calc(100vh - 177px);
    `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 754px;
  padding: 24px 32px 25px 24px;
  margin: 0 auto;
  margin-bottom: 8px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.04);

  &:last-child {
    margin-bottom: 0;
  }

  .labelInfo__icon {
    visibility: hidden;
  }

  ${props =>
    props.highlight &&
    css`
      border: 1px solid white;

      :hover {
        border: 1px solid #5e59ff;
        cursor: pointer;

        .labelInfo__icon {
          visibility: visible;
        }
      }
    `}
`;

export const ItemName = styled.h3`
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  color: #151619;
  margin: 0;
  display: flex;
  align-items: center;
`;

export const ItemInfo = styled.span`
  width: 16px;
  color: #9a9da6;
  font-weight: 400;
  margin-right: 24px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomDeleteIcon = styled(DeleteIcon)`
  width: 24px;
  height: 25px;

  path {
    fill: #9a9da6;
  }

  :hover {
    path {
      fill: #5e59ff;
    }
  }
`;

export const CustomEditIcon = styled(EditIcon)`
  width: 24px;
  height: 25px;
  margin-right: 21px;

  path {
    fill: #9a9da6;
  }

  :hover {
    path {
      fill: #5e59ff;
    }
  }
`;

export const EmptyOptionArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 190px);
`;

export const EmptyText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 30px;
  color: #9a9da6;
`;
