import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { startToday } from 'redux/autoflowInterval/client/actions';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

function StartTodayClients(props) {
  const { autoflow, multiple, clients } = props;

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/activate.svg`} />
            <span>Start Autoflow for 1 client</span>
          </S.Header>
          <S.Body>
            <div>
              This Autoflow’s workouts, tasks, and auto-messages will be added to the calendar of{' '}
              <b>{clients[0].full_name}</b> to start today. Would you like to continue?
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              green
              onClick={() => {
                props.startToday({ autoflowId: autoflow, clients: _.map(clients, '_id') });
                props.toggleModal(false);
              }}
            >
              Start
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    autoflow: workingAutoflow && workingAutoflow._id,
  };
};

const mapDispatch = (dispatch, props) => {
  return {
    startToday: bindActionCreators(startToday, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapState, mapDispatch)(StartTodayClients);
