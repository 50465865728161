import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';

import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

import * as S from '../styles';
import { ASSETS_SHARE_SETTING_TYPE, NO_OWNER_SHARED_OPTION, generateParams, generateShareStatus } from '../constants';
import ColumnOwnership from './ColumnOwnership';
import { Button } from 'shared/FormControl';

const ShareSettingModal = props => {
  const { disabled = false, shareStatus, owner } = props;

  const [ownership, setOwnership] = useState();
  const [share, setShareStatus] = useState(shareStatus);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (shareStatus === TEAM_SHARE_NOOWNER || owner === null) {
      setOwnership(NO_OWNER_SHARED_OPTION.value);
    } else {
      setOwnership(owner._id);
    }
  }, []);

  const handleChangeOwnership = (option = {}) => {
    const ownershipValue = option.value || owner._id;
    setOwnership(ownershipValue);
    setShareStatus(it => generateShareStatus({ ownership: ownershipValue, shareStatus: it }));
  };

  const handleChangeShareStatus = (option = {}) => {
    setShareStatus(option.value || TEAM_SHARE_PRIVATE);
  };

  const handleCancel = e => {
    e.preventDefault();
    typeof props.closeModal === 'function' && props.closeModal();
  };

  const handleSave = e => {
    e.preventDefault();
    setLoading(true);
    const params = generateParams({ ownership, shareStatus: share });
    typeof props.onSave === 'function' && props.onSave({ params });
  };

  return (
    <S.ShareSettingModalWrapper open className="share-setting-modal">
      <Modal.Header>
        <S.ModalTitle>Sharing settings</S.ModalTitle>
      </Modal.Header>
      <Modal.Content>
        <ColumnOwnership
          {...props}
          type={ASSETS_SHARE_SETTING_TYPE.SHARE_SETTING_MODAL}
          ownership={ownership}
          shareStatus={share}
          onChangeOwnership={handleChangeOwnership}
          onChangeShareStatus={handleChangeShareStatus}
          disabled={disabled || loading}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button className="cancel-button" onClick={handleCancel}>
          Cancel
        </Button>
        <Button className="save-button" purple onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </Modal.Actions>
    </S.ShareSettingModalWrapper>
  );
};

export default ShareSettingModal;
