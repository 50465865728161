import styled from 'styled-components';
import { CheckBoxContainer } from '../CustomTable.style';

export const StatusFilterWrapper = styled.div`
  position: relative;
  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .status-button {
      user-select: none;
      height: 36px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      font-size: 14px;
      line-height: 14px;
      color: #262626;
      background-color: #f5f5f5;
      padding: 0 12px;
      cursor: pointer;

      .bold {
        font-weight: 600;
      }
      &:hover {
        background-color: #f4f4fc;
        transition: all 0.3s;
      }
    }
  }

  .status-dropdown {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: ${props => (props.type === 'waitlist' ? '195px' : '259px')};
    position: absolute;
    margin-top: 8px;
    border: 1px solid #e8ebeb;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 12px;
    z-index: 1000;
    box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
    .status-item {
      user-select: none !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      min-width: max-content;
      height: 35px;
      padding: 0 1px 0 8px;
      cursor: pointer;
      label {
        font-size: 14px;
        line-height: 20px;
        color: #262626;
      }
      &:hover {
        background-color: #f4f4fc;
      }
    }
  }
`;

export const CustomCheckBoxContainer = styled(CheckBoxContainer)`
  padding-left: 0 !important;

  .checkbox__thumbnail .checkbox-icon,
  .checkbox-icon {
    width: 16px;
    height: 16px;
    border: 1px solid #b6b6b6;
    border-radius: 2px;
    background-size: 8px;
  }
`;
