import { filter, isEqual, reduce } from 'lodash';
import React from 'react';
import Select, { components } from 'react-select';
import { Wrapper } from './style';
import PropTypes from 'prop-types';
import { ReactComponent as DropdownIcon } from 'assets/icons/status-dropdown-indicator.svg';
import StatusChip from 'shared/StatusChip';

function StatusDropdown({ options: originalOptions, width = 137, value, onChange, readOnly = false }) {
  const handleOnChange = value => {
    onChange && onChange(value);
  };
  const selectedStatusValue = filter(originalOptions, option => {
    return isEqual(option.value, value);
  });
  const options = filter(originalOptions, option => !isEqual(option.value, value));
  const DropdownIndicator = () => {
    return <DropdownIcon />;
  };

  const statusColors = {};

  originalOptions.forEach(option => {
    statusColors[option.value] = {
      label: option.label,
      color: option.color,
    };
  });

  const SingleValue = props => {
    const selected = props.data;
    return (
      <components.SingleValue {...props}>
        <StatusChip status={selected.value} customStatuses={statusColors} customClass="custom-status-chip" />
      </components.SingleValue>
    );
  };

  const Option = props => {
    const selected = props.data;
    return (
      <components.Option {...props}>
        <StatusChip status={selected.value} customStatuses={statusColors} customClass="custom-status-chip option" />
      </components.Option>
    );
  };
  return (
    <Wrapper width={width}>
      <Select
        isDisabled={readOnly}
        classNamePrefix="status-dropdown"
        value={selectedStatusValue}
        options={options}
        components={{ IndicatorSeparator: null, DropdownIndicator, SingleValue, Option }}
        onChange={handleOnChange}
        isSearchable={false}
      />
    </Wrapper>
  );
}

StatusDropdown.propTypes = {
  options: PropTypes.array,
  width: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default StatusDropdown;
