// Libs
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import diff from 'deep-diff';
import _ from 'lodash';
import { Prompt } from 'react-router';
import { push } from 'connected-react-router';

// Actions
import {
  savePackageDraft,
  savePackageDraftGoToUpgrade,
  getPackage,
  updatePackage,
  updatePackageGoToUpgrade,
  createPackage,
  publishPackage,
  enableEditMode,
  cancelEditMode,
} from 'redux/package-detail/actions';
import { toggleConfirmModal } from 'actions/modal';

// Components
import PackageLayout from '../PackageLayout';
import PackageForm from './PackageForm';
import PackagePricing from './PackagePricing';
import PackageViewMode from './PackageViewMode';
import ConfirmModal from 'shared/ConfirmModal';
import PricingModal from './PricingModal';
import PackageActions from './PackageActions';
import AssignedTrainer from './AssignedTrainer';
import PreviewModal from './PreviewModal';
import AssignProduct from './AssignProduct';

// Utils
import { axiosInstance } from 'configs/request';
import { handleEnableProduct } from 'utils/commonFunction';
import { getCookie, genTokenFromMarketplace } from 'utils/commonFunction';
import { C_KEY_TOKEN, PACKAGE_STATUS, CDN_URL } from 'constants/commonData';

// Styles
import * as S from './styles';

const Overview = props => {
  const {
    workingPackage,
    match: { params, url },
    isEditMode,
    user,
    list,
    cloudfrontList,
    location,
    dispatch,
  } = props;
  const isMP = url.includes('marketplace');

  const [packageData, setPackageData] = useState();
  const [originPackage, setOriginPackage] = useState(workingPackage);
  const [isConfirmLeavePage, setIsConfirmLeavePage] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [trainer, setTrainer] = useState();
  const [isPreview, setPreview] = useState(false);
  const [isAddPrice, setAddPrice] = useState(false);

  useEffect(() => {
    setPackageData({
      ...workingPackage.toJS(),
      products: workingPackage.toJS().product
        ? _.uniq([workingPackage.toJS().product, ..._.get(workingPackage.toJS(), 'products', [])])
        : workingPackage.toJS().products || [],
    });
    setOriginPackage(workingPackage);
    setTrainer(workingPackage.getIn(['trainer', '_id']) || workingPackage.get('trainer') || user._id);
  }, [workingPackage]);

  useEffect(() => {
    setIsConfirmLeavePage(false);
  }, [isEditMode]);

  useEffect(() => {
    return () => {
      props.toggleConfirmModal(false);
    };
  }, []);

  const search = _.get(location, 'search', '');
  const pathname = _.get(location, 'pathname', '');

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (isMP && token && search.includes('redirect-platform')) {
      genTokenFromMarketplace({ token, user, pathname, search, dispatch });
    }
  }, [search, pathname]);

  const handleChangeForm = data => {
    setIsSubmitted(false);
    setPackageData({
      ...packageData,
      ...data,
    });
  };

  const isEditing = useMemo(() => {
    return params.packageId && params.packageId !== 'create';
  }, [params.packageId]);

  const isChangeForm = useMemo(() => {
    if (isConfirmLeavePage) {
      return false;
    }

    let originData = {};
    if (isEditing) {
      originData = {
        ...workingPackage.toJS(),
        products: workingPackage.toJS().product
          ? _.uniq([workingPackage.toJS().product, ..._.get(workingPackage.toJS(), 'products', [])])
          : workingPackage.toJS().products || [],
      };
    }

    const diffData = diff(originData, packageData);
    return !!diffData;
  }, [workingPackage, packageData, isConfirmLeavePage]);

  const validateForm = isDraft => {
    if (
      !packageData.name ||
      packageData.name.length > 90 ||
      !packageData.cover_image ||
      !packageData.description ||
      packageData.description === '<p><br></p>'
    ) {
      return false;
    }

    if (_.isNil(packageData.price && packageData.price.amount) && !isDraft) {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Set Pricing for Package"
          content="Before publishing the package, please set up pricing."
          onConfirm={handlePricingModal}
          confirmButtonTitle="Update Pricing"
          confirmButtonClass="confirm-unpublish-button"
          headerIcon={`${CDN_URL}/images/warning_yellow_icon.svg`}
          hideCancelButton
          newStyle
          hasCloseIcon
        />,
      );
      return false;
    }
    return true;
  };

  const warningModal = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Complete basic package details"
        content="Before publishing the package, please make sure you have included the package name, banner image, pricing and a short package description. Providing more details will help your customers that want to make a purchase."
        onConfirm={onEdit}
        confirmButtonTitle="Edit Package"
        confirmButtonClass="confirm-unpublish-button"
        headerIcon={`${CDN_URL}/images/warning_yellow_icon.svg`}
        hideCancelButton
        newStyle
        hasCloseIcon
      />,
    );
    return false;
  };

  const onEdit = () => {
    props.enableEditMode(workingPackage.get('id'), workingPackage.get('status') === PACKAGE_STATUS.PUBLISH, isMP);
  };

  const handlePublish = async () => {
    setIsSubmitted(true);
    setIsSave(false);
    const isValidForm = validateForm();
    if (isValidForm) {
      const body = {
        ...packageData,
        trainer,
        product: packageData.product ? _.get(packageData, 'product.id', packageData.product) : null,
        products: _.map(packageData.products, item => item.id),
      };

      setIsConfirmLeavePage(true);
      if (isEditing) {
        await props.publishPackage(packageData.id, body, isMP);
      } else {
        await props.createPackage(body, isMP);
      }
      return;
    }

    if (workingPackage.get('status') === PACKAGE_STATUS.DRAFT && !isEditMode) {
      setIsSave(true);
      warningModal();
    }
  };

  const handleSave = async () => {
    setIsSave(true);
    if (!packageData.name || packageData.name.length > 90) {
      setIsSubmitted(true);
      return false;
    }

    const body = _.omitBy(packageData, v => !v);
    body.trainer = trainer;
    body.product = packageData.product ? _.get(packageData, 'product.id', packageData.product) : null;
    body.products = _.map(packageData.products, item => item.id);

    if (_.has(packageData, 'allow_trial_once')) {
      body.allow_trial_once = packageData.allow_trial_once;
    }

    setIsConfirmLeavePage(true);

    if (isEditing) {
      await props.updatePackage(packageData.id, body, isMP);
    } else {
      props.savePackageDraft(body, isMP);
    }
    setIsSave(false);
    setIsSubmitted(false);
    return true;
  };

  const handleSaveDraftLinkProduct = async pathname => {
    setIsSave(true);
    if (!packageData.name || packageData.name.length > 90) {
      setIsSubmitted(true);
      return false;
    }

    const body = _.omitBy(packageData, v => !v);
    body.trainer = trainer;
    body.product = packageData.product ? _.get(packageData, 'product.id', packageData.product) : null;
    body.products = _.map(packageData.products, item => item.id);
    setIsConfirmLeavePage(true);
    if (isEditing) {
      await props.updatePackageGoToUpgrade(packageData.id, body, pathname, isMP);
    } else {
      props.savePackageDraftGoToUpgrade(body, pathname, isMP);
    }
    setIsSave(false);
    setIsSubmitted(false);
    return true;
  };

  const handleSaveDraftUpgrade = async pathname => {
    setIsSave(true);
    if (!packageData.name || packageData.name.length > 90) {
      setIsSubmitted(true);
      props.push(props.location.pathname);
      return false;
    }

    const body = _.omitBy(packageData, v => !v);
    body.trainer = trainer;
    setIsConfirmLeavePage(true);

    // Check name package on current list
    const nameList = _.map(list.toJS(), item => item.name);
    const packageExist = nameList.includes(packageData.name.trim());

    if (packageExist) {
      setIsSave(false);
      setIsSubmitted(false);
      return true;
    }

    if (isEditing) {
      await props.updatePackageGoToUpgrade(packageData.id, body, pathname, isMP);
    } else {
      props.savePackageDraftGoToUpgrade(body, pathname, isMP);
    }

    setIsSave(false);
    setIsSubmitted(false);
    return true;
  };

  const handleConfirmDiscardChange = async pathname => {
    await setIsConfirmLeavePage(true);
    const saveResult = await handleSave();

    if (saveResult) {
      props.push(pathname);
    }
  };

  const handleCloseAnyway = async pathname => {
    await setIsConfirmLeavePage(true);
    props.push(pathname);
  };

  const onCancelEditMode = async pathname => {
    await setIsConfirmLeavePage(true);
    props.cancelEditMode(workingPackage.get('id'), workingPackage.get('status') === PACKAGE_STATUS.PUBLISH);
    props.push(pathname);
  };

  const handleBlockedNavigation = nextLocation => {
    if (isEditMode && workingPackage.get('status') === PACKAGE_STATUS.PUBLISH) {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Cancel Edits Made to Package?"
          content="Would you like to cancel editing the package? Any changes you have made will not be saved."
          onConfirm={() => onCancelEditMode(nextLocation)}
          confirmButtonTitle="Cancel Edits"
          headerIcon={`${CDN_URL}/images/cancel_subscription_confirm_icon.svg`}
          cancelButtonTitle="Continue Editing"
          newStyle
          hasCloseIcon
        />,
      );
      return false;
    }
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Save before exit?"
        content="You have made changes that have not been saved yet. Would you like save changes before leaving?"
        onConfirm={() => handleConfirmDiscardChange(nextLocation.pathname)}
        confirmButtonTitle="Save as Draft"
        cancelButtonTitle="Close anyway"
        confirmButtonClass="btn-purple"
        headerIcon={`${CDN_URL}/images/warning_purple_Icon.svg`}
        onDeny={() => handleCloseAnyway(nextLocation.pathname)}
        newStyle
        hasCloseIcon
      />,
    );
    return false;
  };

  const handlePriceChange = price => {
    const newPrice = _.omit(price, 'is_archive_on_trial_cancellation', 'allow_trial_once');
    setPackageData({
      ...packageData,
      is_archive_on_trial_cancellation: _.get(price, 'is_archive_on_trial_cancellation', false),
      allow_trial_once: _.get(price, 'allow_trial_once', false),
      price: newPrice,
    });
  };

  const handleSelectTrainer = newValue => {
    setTrainer(newValue);
  };

  const handleOnPreview = () => {
    setPreview(true);
  };

  const handleOnClosePreview = () => {
    setPreview(false);
  };

  const handlePricingModal = () => {
    setAddPrice(!isAddPrice);
  };

  const handleUpdatePricing = data => {
    handlePriceChange(data);
  };

  const handleSelectedProduct = (item, checked) => {
    let newProduct = [];

    if (checked) {
      newProduct = _.uniqBy([...packageData.products, ...item], 'id');
    } else {
      newProduct = _.filter(packageData.products, it => it.id !== item.id);
    }

    setPackageData({
      ...packageData,
      products: newProduct,
    });
  };

  /* TODO -  REACT_APP_ENABLE_PRODUCT */
  const isPublic = handleEnableProduct(user);

  if (!packageData || (isEditing && !packageData.id)) {
    return null;
  }

  const packageStatus = _.get(workingPackage.toJS(), 'status');

  return (
    <>
      <PackageLayout
        isMP={isMP}
        rightContent={
          <PackageActions
            isMP={isMP}
            status={workingPackage.get('status')}
            onSave={handleSave}
            onPublish={handlePublish}
            isEditMode={isEditMode}
            onPreview={handleOnPreview}
            onSetIsConfirmLeavePage={setIsConfirmLeavePage}
            onSaveDraftUpgrade={handleSaveDraftUpgrade}
          />
        }
      >
        <Prompt when={isChangeForm} message={handleBlockedNavigation} />
        <S.Wrapper>
          {!isEditMode ? (
            <PackageViewMode packageData={packageData} cloudfrontList={cloudfrontList} />
          ) : (
            <PackageForm
              isSubmitted={isSubmitted}
              originPackage={originPackage}
              onChangeForm={handleChangeForm}
              isSave={isSave}
              cloudfrontList={cloudfrontList}
            />
          )}
          <div>
            <PackagePricing
              isEditMode={isEditMode}
              data={packageData.price}
              isArchiveClientTrial={_.get(packageData, 'is_archive_on_trial_cancellation', false)}
              allowTrialOnce={_.get(packageData, 'allow_trial_once', false)}
              onChange={handlePriceChange}
              status={packageData && packageData.status}
              onSave={handleSave}
            />
            {isPublic &&
              ((_.get(packageData, 'products', []).length && packageStatus === PACKAGE_STATUS.PUBLISH) ||
                isEditMode ||
                packageStatus !== PACKAGE_STATUS.PUBLISH) && (
                <AssignProduct
                  isEdit={isEditMode}
                  onSelectedProduct={handleSelectedProduct}
                  product={packageData.products}
                  price={packageData.price}
                  onSaveDraft={handleSaveDraftLinkProduct}
                />
              )}
            <AssignedTrainer trainer={trainer} onChange={handleSelectTrainer} isEditMode={isEditMode} />
          </div>
        </S.Wrapper>
      </PackageLayout>
      {isPreview && (
        <PreviewModal
          packageData={packageData}
          trainer={trainer}
          onClose={handleOnClosePreview}
          cloudfrontList={cloudfrontList}
          isMP={isMP}
        />
      )}
      {isAddPrice && (
        <PricingModal
          onClose={handlePricingModal}
          onUpdate={handleUpdatePricing}
          originData={{
            ...packageData.price,
            is_archive_on_trial_cancellation: _.get(packageData, 'is_archive_on_trial_cancellation', false),
            allow_trial_once: _.get(packageData, 'allow_trial_once', false),
          }}
          packageStatus={packageData && packageData.status}
          onSave={handleSave}
        />
      )}
    </>
  );
};

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: { packageDetail, packageList },
    router: { location },
    user,
  } = state;

  return {
    cloudfrontList,
    workingPackage: packageDetail.get('workingPackage'),
    isEditMode: packageDetail.get('isEditMode'),
    location,
    user,
    list: packageList.get('list'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    push: bindActionCreators(push, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    getPackage: bindActionCreators(getPackage, dispatch),
    createPackage: bindActionCreators(createPackage, dispatch),
    updatePackage: bindActionCreators(updatePackage, dispatch),
    publishPackage: bindActionCreators(publishPackage, dispatch),
    enableEditMode: bindActionCreators(enableEditMode, dispatch),
    cancelEditMode: bindActionCreators(cancelEditMode, dispatch),
    savePackageDraft: bindActionCreators(savePackageDraft, dispatch),
    updatePackageGoToUpgrade: bindActionCreators(updatePackageGoToUpgrade, dispatch),
    savePackageDraftGoToUpgrade: bindActionCreators(savePackageDraftGoToUpgrade, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(Overview);
