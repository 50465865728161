import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreatePostForm from './component';
import { toggleSideNav } from 'actions/sidenav';
import { toggleSideBar } from 'actions/sidebar';
import { toggleModal } from 'actions/modal';

const mapStateToProps = state => {
  const { isModalOpen, sideNavVisible, sideBarVisible, modal, errorPopup, isShowingError, rootReducer, user } = state;
  const enabledAddons = state.rootReducer.pricing.getIn(['teamData']).toJS();
  const permissions = rootReducer.permission;

  return {
    isModalOpen,
    sideNavVisible,
    sideBarVisible,
    modal,
    errorPopup,
    isShowingError,
    enabledAddons,
    user,
    permissions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleSideNavigation: bindActionCreators(toggleSideNav, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostForm);
