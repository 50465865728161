import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowIcon } from 'assets/icons/grey_arrow.svg';
import ButtonIcon from 'shared/ButtonIcon';
import * as S from './style';

const FeatureBanner = ({
  isSlide = false,
  autoplay = true,
  autoplaySpeed = 5000,
  speed = 1000,
  infinite = true,
  rightToLeft = false,
  fade = false,
  data = [],
  onHideBanner,
  wrapperClass = '',
  children,
  otherProps,
}) => {
  const sliderRef = useRef();
  const [isSlideHide, setIsSlideHide] = useState(isSlide);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
    dots: true,
    infinite: infinite,
    speed: speed,
    fade: fade,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots',
    rtl: rightToLeft,
    className: 'sliderMain',
    customPaging: i => <S.Dots className={'dots-item'} />,
    afterChange: indexOfCurrentSlide => {
      setCurrentSlide(indexOfCurrentSlide);
    },
  };

  const handleNextSlide = () => {
    // TODO
    sliderRef.current.slickNext();
  };

  const handleHideBanner = () => {
    onHideBanner && onHideBanner();
    setIsSlideHide(true);
  };

  return (
    !isSlideHide &&
    Array.isArray(data) &&
    data.length > 0 && (
      <S.BannerWrapper className={wrapperClass}>
        <S.BannerSliderContainer>
          {/* TODO - VIDEO - waiting confirm */}
          {/* {data[currentSlide].video ? (
            <S.Media video>
              <ReactPlayer
                progressInterval={500}
                muted={true}
                url={data[currentSlide].imageSrc}
                playing={true}
                loop={true}
                width="102%"
                height="100%"
                controls={false}
              />
            </S.Media>
          ) : (
            <S.Media url={data[currentSlide].imageSrc && data[currentSlide].imageSrc}></S.Media>
          )} */}
          <S.Media url={data[currentSlide].imageSrc && data[currentSlide].imageSrc}></S.Media>
          <S.Slider>
            <Slider ref={sliderRef} {...settings}>
              {data.map((item, key) => (
                <S.Item key={key}>
                  <S.Content>
                    <S.Title>{item.title}</S.Title>
                    <S.TitleOfDescription>
                      {item.titleLink ? (
                        <S.TitleLink href={item.titleLink} target="_blank">
                          {item.titleOfDescription}
                        </S.TitleLink>
                      ) : (
                        item.titleOfDescription
                      )}
                    </S.TitleOfDescription>
                    <S.Description>{item.description}</S.Description>
                    <>{children}</>
                  </S.Content>
                  {data.length > 1 && (
                    <ButtonIcon
                      text="Next"
                      height={38}
                      width={105}
                      icon={<ArrowIcon />}
                      position="right"
                      className="nextButton"
                      onClick={handleNextSlide}
                    />
                  )}
                </S.Item>
              ))}
            </Slider>
            <S.HideBannerButton onClick={handleHideBanner}>
              Hide Banner <ArrowIcon />
            </S.HideBannerButton>
          </S.Slider>
        </S.BannerSliderContainer>
      </S.BannerWrapper>
    )
  );
};

FeatureBanner.prototype = {
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  speed: PropTypes.number,
  infinite: PropTypes.bool,
  rightToLeft: PropTypes.bool,
  fade: PropTypes.bool,
  isSlide: PropTypes.bool,
  wrapperClass: PropTypes.string,
  data: PropTypes.array,
};

export default FeatureBanner;
