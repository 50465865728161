import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import SaveAsProgram from 'shared/SaveAsProgram';
import { saveAutoflowIntervalToProgram } from 'redux/autoflowInterval/actions';
import { CDN_URL } from 'constants/commonData';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FeatureTurnOff = styled.div`
  display: flex;
  background: #fffae6;
  padding: 3px 10px;
  border-radius: 6px;
  border: 1px solid #f3ce8a;
  color: #eea620;
  align-items: center;
  font-size: 12px;

  img {
    margin-right: 5px;
  }
`;

function NavBar({ userId, workingAutoflow, saveAutoflowIntervalToProgram, featureTurnOff }) {
  const onSave = data => {
    const bodyData = { ...data, author: userId, autoflowId: workingAutoflow._id };

    return saveAutoflowIntervalToProgram(bodyData);
  };

  return (
    <Container>
      <div />
      {featureTurnOff && (
        <FeatureTurnOff>
          <img src={`${CDN_URL}/images/feature_turned_off.svg`} alt="" />
          <span>Training has been turned off</span>
        </FeatureTurnOff>
      )}
      <SaveAsProgram title={`${workingAutoflow.name} Copy`} onSave={onSave} />
    </Container>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      autoflow: {
        settings: { features },
      },
    },
  } = state;

  const trainingSetting = features.find(item => item.type === 'training');
  const featureTurnOff = trainingSetting ? !trainingSetting.state : false;
  return {
    featureTurnOff,
    userId: state.user._id,
    workingAutoflow: state.rootReducer.autoflow.common.workingAutoflow,
  };
};

const actions = {
  saveAutoflowIntervalToProgram,
};

export default connect(mapState, actions)(NavBar);
