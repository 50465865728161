import styled from 'styled-components';

export const Content = styled.div`
  width: 405px;
  height: 341px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;

  .text {
    width: 245px;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > * {
      margin: 5px 0;
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
  }

  button {
    &.button--create-new {
      padding: 8px 20px;

      svg {
        margin-right: 10px;
      }
    }
    :hover {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

export const Container = styled.div`
  max-width: 780px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 182px;
  background: #fafbfc;
  overflow: auto;
`;
