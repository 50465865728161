import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_right.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-alt.svg';
import { ReactComponent as EditIcon } from 'assets/icons/pencil-edit.svg';

export const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: 32px 0;
  margin: auto;
  font-family: 'Open Sans';
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ isVerifyEmail }) => (isVerifyEmail ? '250px' : '206px')});
  overflow-y: scroll;
  padding: 5px 10px 20px 5px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 754px;
  font-family: 'Open Sans';
  padding: 15px 32px 15px 24px;
  margin: 0 auto;
  margin-bottom: 8px;
  border: 1px solid white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.04);

  &:last-child {
    margin-bottom: 0;
  }

  .labelInfo__icon {
    visibility: hidden;
  }

  :hover {
    border: 1px solid #5e59ff;
    cursor: pointer;

    .labelInfo__icon {
      visibility: visible;
    }

    .labelInfo__arrow {
      path {
        fill: #4a4d56;
      }
    }
  }
`;

export const ItemName = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #151619;
  margin: 0;
`;

export const ItemInfo = styled.p`
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #9a9da6;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomDeleteIcon = styled(DeleteIcon)`
  width: 24px;
  height: 25px;
  margin-right: 35px;

  path {
    fill: #9a9da6;
  }

  :hover {
    path {
      fill: #5e59ff;
    }
  }
`;

export const CustomArrowIcon = styled(ArrowIcon)`
  width: 7px;
  height: 12px;

  path {
    fill: #9a9da6;
  }
`;

export const CustomEditIcon = styled(EditIcon)`
  width: 24px;
  height: 25px;
  margin-right: 21px;

  path {
    fill: #9a9da6;
  }

  :hover {
    path {
      fill: #5e59ff;
    }
  }
`;
