import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 30px;
  margin-top: -2px;

  .tabs .ui.text.menu {
    margin: 0px 0 6px 0;

    .item {
      margin-right: 30px;
      padding: 0px;
      text-decoration: none !important;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #202353;
      opacity: 0.6;
      padding: 12px 0px;

      &.active {
        color: #5158cf;
        opacity: 1;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 1px;
          background-color: #5158cf;
        }
      }
    }
  }
`;
