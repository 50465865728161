import React from 'react';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

const SharingContainer = styled.div`
  display: inline-block;

  > * {
    vertical-align: middle;
  }

  ${props =>
    props.shared &&
    css`
      svg {
        rect {
          fill: #ecebff;
        }

        path {
          fill: #5c5bbd;
        }
      }
    `}

  .app-tooltip-white {
    min-width: 80px;
    text-align: center;
  }
`;

export default props => (
  <SharingContainer {...props} shared={props.shared} className="sharing-status">
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-tip
      data-for={props.tooltipId}
    >
      <path
        d="M9.75 4.875C11.0939 4.875 12.1875 3.78137 12.1875 2.4375C12.1875 1.09362 11.0939 0 9.75 0C8.40613 0 7.3125 1.09362 7.3125 2.4375C7.3125 2.61869 7.33606 2.79419 7.37344 2.96481L4.75719 4.59956C4.34119 4.26887 3.822 4.0625 3.25 4.0625C1.90612 4.0625 0.8125 5.15613 0.8125 6.5C0.8125 7.84387 1.90612 8.9375 3.25 8.9375C3.822 8.9375 4.34119 8.73112 4.75719 8.40044L7.37344 10.0352C7.33606 10.2058 7.3125 10.3813 7.3125 10.5625C7.3125 11.9064 8.40613 13 9.75 13C11.0939 13 12.1875 11.9064 12.1875 10.5625C12.1875 9.21863 11.0939 8.125 9.75 8.125C9.178 8.125 8.65881 8.33138 8.24281 8.66206L5.62656 7.02731C5.66394 6.85669 5.6875 6.68119 5.6875 6.5C5.6875 6.31881 5.66394 6.14331 5.62656 5.97269L8.24281 4.33794C8.65881 4.66863 9.178 4.875 9.75 4.875Z"
        fill="#C4C4C4"
      />
    </svg>
    <ReactTooltip
      className={props.className ? props.className : 'app-tooltip-white'}
      id={props.tooltipId}
      effect="solid"
      place={'top'}
    >
      <span>{props.shared ? 'Shared' : 'Private'}</span>
    </ReactTooltip>
  </SharingContainer>
);
