export const THEMES = [
  { primaryColor: '#7470ef', secondaryColor: '#4fdfac', _id: 'default', default: true, preset: true },
  { primaryColor: '#485363', secondaryColor: '#4f95fe', _id: 'preset_1', preset: true },
  { primaryColor: '#63b2fb', secondaryColor: '#1c66e3', _id: 'preset_2', preset: true },
  { primaryColor: '#4fc79c', secondaryColor: '#1f996d', _id: 'preset_3', preset: true },
  { primaryColor: '#4a4a48', secondaryColor: '#fcd81c', _id: 'preset_4', preset: true },
  { primaryColor: '#f98e2d', secondaryColor: '#373130', _id: 'preset_5', preset: true },
  { primaryColor: '#485263', secondaryColor: '#d84954', _id: 'preset_6', preset: true },
  { primaryColor: '#485263', secondaryColor: '#eb497a', _id: 'preset_7', preset: true },
  { primaryColor: '#3B4494', secondaryColor: '#E4AB93', _id: 'preset_8', preset: true },
  { primaryColor: '#b7cab1', secondaryColor: '#2b7b95', _id: 'preset_9', preset: true },
];
