// Libs
import React from 'react';
import { Button, Image, Modal } from 'semantic-ui-react';

import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as PrimeIcon } from 'assets/icons/MealPlans/prime_icon.svg';

// Styles
import * as S from './style';

const DescriptionModal = ({ description, onClose }) => {
  return (
    <S.CustomModalDesc open={true} onClose={onClose} closeOnDimmerClick={false} className="evf-desc-modal">
      <Modal.Header>
        <Button onClick={onClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} alt="close icon" />
        </Button>
        <S.HeaderWrapper>
          <PrimeIcon className="desc-prime" />
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentDesc>{description}</S.ContentDesc>
      </Modal.Content>
    </S.CustomModalDesc>
  );
};

export default DescriptionModal;
