import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { DropdownIndicator } from 'shared/Icons';
import { Toggle } from 'shared/FormControl';
import TIME_OPTIONS from 'constants/time-options';

import * as S from './style';

const DATE_FORMAT = 'YYYY-MM-DD';

export default ({
  className,
  selectedDate,
  reminderTime,
  scheduleReminder,
  disabled = false,
  reminderTimeValue,
  onChangeReminderTime,
  onToggleScheduleReminder,
}) => {
  const today = moment(new Date());
  const currentTime = today.format('HH:mm');
  const currentDate = today.format(DATE_FORMAT);
  const Options = TIME_OPTIONS.filter(item => (currentDate === selectedDate ? item.value > currentTime : true));
  const customTime = { ...reminderTimeValue, value: reminderTimeValue.value.slice(0, 5) };
  const [options, setOptions] = useState(Options);

  useEffect(() => {
    let index = 0;
    for (let i = 0; i < Options.length - 1; i++) {
      if (
        moment(customTime.value, 'hh:mm').isBetween(
          moment(Options[i].value, 'hh:mm'),
          moment(Options[i + 1].value, 'hh:mm'),
          'minutes',
        )
      ) {
        index = i + 1;
      } else if (moment(customTime.value, 'hh:mm').isAfter(moment(Options[i].value, 'hh:mm'))) {
        index = i + 2;
      }
    }
    const isNotCustomTime = Options.some(item => item.label === reminderTime);

    const newOptions = isNotCustomTime ? Options : [...Options.slice(0, index), customTime, ...Options.slice(index)];
    setOptions(newOptions);
  }, [reminderTimeValue]);

  const value = useMemo(() => {
    const foundOption = options.find(item => item.label === reminderTime);
    return foundOption || customTime;
  }, [options]);

  return (
    <S.SelectTimeContainer className={className}>
      <S.SelectTimeSection>
        <S.ToggleLabel>All-day</S.ToggleLabel>
        <Toggle
          disabled={disabled}
          checked={scheduleReminder}
          onChange={onToggleScheduleReminder}
          width={44}
          height={24}
        />
      </S.SelectTimeSection>

      {!scheduleReminder && (
        <S.SelectTimeSection>
          <S.DropdownLabel>PICK THE TIME</S.DropdownLabel>
          <Select
            options={options}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            placeholder="-"
            onChange={onChangeReminderTime}
            classNamePrefix="evfSelectBox"
            className="evfSelectBoxContainer"
            value={value}
            isSearchable={false}
            styles={{
              control: base => ({ ...base, minHeight: 40, height: 40, minWidth: 158 }),
              menu: base => ({ ...base, marginTop: 2, marginBottom: 5, minWidth: 158 }),
            }}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.label}
            menuPlacement="bottom"
            maxMenuHeight="250"
            menuPosition="fixed"
          />
        </S.SelectTimeSection>
      )}
    </S.SelectTimeContainer>
  );
};
