import styled, { css } from 'styled-components';

import { Button } from 'shared/FormControl';
import S3Images from 'shared/S3Images';

import { MEAL_PLAN_STATUS } from 'components/MealPlanDetail/constants';

const textBase = `
  font-family: 'Open Sans';
  font-style: normal;
  color: #202353;
  line-height: 150%;
`;

const limitText = `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const MealPlanContainer = styled.div`
  width: 100%;
  height: ${({ isHasBanner }) => (isHasBanner ? 'calc(100% - 335px)' : 'calc(100% - 135px)')};
  padding: 0 12px 0 30px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }
  ${({ isEmptyList }) =>
    isEmptyList &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const OrderBy = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const SortLabel = styled.div`
  ${textBase}
  color: #7b7e91;
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
`;

const activePopup = css`
  background: #f0f1ff;
  color: #5158cf;
  border-radius: 5px;
  .arrow-down-icon {
    path {
      stroke: #5158cf;
    }
  }
`;

export const SortTrigger = styled.div`
  max-height: 30px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #202353;
  :hover {
    ${activePopup}
  }
  ${props => props.active && activePopup}
  .arrow-down-icon {
    transform: rotate(180deg);
    width: 12px;
    height: 12px;
    margin-left: 6px;
    margin-top: 3px;
  }
`;

export const SortPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  padding-right: 0;
  width: 129px;
  position: absolute;
  top: 34px;
  right: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  font-size: 13px;
  font-weight: 400;
`;

export const SortItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${props => (props.active ? '#5158cf' : '#7b7e91')};
  font-weight: ${props => (props.active ? '600' : '400')};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  :hover {
    color: #5158cf;
    font-weight: 600;
  }
  .checked-icon {
    margin-top: 4px;
    margin-right: 4px;
    path {
      fill: #5158cf;
    }
  }
  .sort-item-label {
    white-space: nowrap;
  }
`;

export const ContentList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 25px;
  margin-bottom: 30px;

  @media only screen and (min-width: 1880px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1641px) and (max-width: 1879px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1280px) and (max-width: 1640px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1040px) and (max-width: 1279px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (max-width: 1039px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const MealPlanItemWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  border: 1px solid #f0f0f2;
  overflow: hidden;
  cursor: pointer;
  :hover {
    border: 1px solid #5158cf;
    .evf-dropdown.custom-dropdown-actions {
      visibility: visible;
      opacity: 1;
    }
  }
  .evf-dropdown.custom-dropdown-actions.open {
    visibility: visible;
    opacity: 1;
    .dropdown-trigger {
      background-color: #f0f1ff !important;
    }
  }
  .evf-dropdown.custom-dropdown-actions {
    position: absolute;
    right: 15px;
    top: 10px;
    visibility: hidden;
    opacity: 0;
    .evf-dropdown__trigger-container {
      .dropdown-trigger {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: #fff;
        .dot {
          transform: unset;
        }
        .dot,
        .dot::after,
        .dot::before {
          background-color: #202353;
        }
        :hover {
          background-color: #f0f1ff;
          .dot,
          .dot::after,
          .dot::before {
            background-color: #5158cf;
          }
        }
        .__react_component_tooltip.app-tooltip.show {
          background: #333;
          padding: 8px 10px;
          border-radius: 5px;
          ${textBase}
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          width: 95px;
          height: 34px;
        }
        .__react_component_tooltip.type-dark.place-top:after {
          border-top-color: #333;
          margin-bottom: -2px !important;
        }
        .__react_component_tooltip.place-top {
          margin-top: -11px;
        }
      }
    }
    .evf-dropdown__menu {
      padding: 4px 0;
      .evf-dropdown__option {
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        user-select: none;
        .icon {
          display: flex;
          justify-content: center;
        }
        span {
          ${textBase}
          color: #f7f8f9;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
`;

export const OptionIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const MealPlanThumbnail = styled(S3Images)`
  height: 169px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MealPlanInfo = styled.div`
  padding: 20px 15px;
  position: relative;
  .meal-plan-owner-avatar {
    flex-shrink: 0;
    .meal-plan-avatar.sb-avatar__text {
      pointer-events: none;
    }
    img {
      pointer-events: none;
    }
  }
`;

export const MealPlanTitle = styled.div`
  height: 40px;
  ${textBase}
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
  ${limitText}
  -webkit-line-clamp: 2;
  line-clamp: 2;
`;

export const WeekLabel = styled.div`
  display: flex;
  height: 24px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  ${textBase}
  color: #fff;
  font-size: 10px;
  font-weight: 600;
`;

export const TagWrapper = styled.div`
  position: absolute;
  top: -34px;
  display: flex;
  gap: 5px;
`;

export const MealInfoBottom = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  .__react_component_tooltip.meal-plan-avatar-tooltip {
    border-radius: 5px;
    padding: 8px 16px;
    ${textBase}
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    &.show {
      background-color: #333;
    }
    &.type-dark.place-top:after {
      border-top-color: #333;
      border-top-style: solid;
      border-top-width: 6px;
    }
    &.place-top {
      margin-top: -6px;
    }
  }
`;

export const AssignToNumber = styled.div`
  ${textBase}
  font-size: 12px;
  font-weight: 400;
  .bold {
    font-weight: 600;
    margin-left: 5px;
  }
  ${limitText}
  -webkit-line-clamp: 1;
  line-clamp: 1;
`;

export const EmptyListWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => (props.hasFilter ? '150px' : '200px')};
  .empty-banner {
    margin-bottom: 20px;
  }
  ${({ isEmptyList }) =>
    isEmptyList &&
    css`
      padding-top: 0;
    `}
`;

export const NotFoundWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoResultFound = styled.span`
  ${textBase}
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 23px;
`;

export const Notice = styled.span`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const NewMealPlanButton = styled(Button)`
  padding: 10px 17px 10px 15px;
  min-width: 208px;
  max-height: 36px;
  .plus-icon {
    width: 13px;
    height: 13px;
    fill: #fff;
    margin-top: 0;
    margin-left: 2px;
    margin-right: 7px;
  }
  .label {
    font-size: 13px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    margin-top: 0;
    margin-left: 2px;
  }
  :hover {
    opacity: 0.9;
  }
`;

export const Status = styled.div`
  ${textBase}
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  line-height: 15px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 20px;
  background-color: rgba(0, 0, 0, 0.7);
  width: fit-content;
  position: relative;
  &::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${({ status }) => (status === MEAL_PLAN_STATUS.PUBLISH ? '#6ACF47' : '#fff')};
    position: absolute;
    left: 10px;
  }
`;
