// Libs
import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { get, orderBy } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { getAllLabels, removeLabelFromList, addNewAllLabels, updateLabelName } from 'redux/workout-labels/actions';
import { toggleConfirmModal } from 'actions/modal';

// Components
import { SharedTooltip } from 'shared/SharedTooltip';
import ConfirmModal from 'shared/ConfirmModal';
import LoadingIndicator from 'shared/LoadingIndicator';
import WorkoutLabelsHeader from './WorkoutLabelsHeader';
import AddNewForm from './AddNewForm';

import { CDN_URL } from 'constants/commonData';

// Utils
import { isTeamAdmin } from 'utils/commonFunction';

// Styles
import * as S from './style';

function WorkoutLabels({
  getAllLabels,
  workoutLabels,
  toggleConfirmModal,
  loading,
  removeLabelFromList,
  addNewAllLabels,
  push,
  updateLabelName,
  user,
  paymentOverdue,
  zapierOverdue,
  addOnsOverdue,
  paymentWhiteLabelOverdue,
}) {
  const [open, setOpen] = useState(false);
  const [labelEditing, setLabelEditing] = useState();
  const reverseWorkoutLabels = orderBy(workoutLabels, obj => obj.order, 'desc');

  const { is_verified_email, force_send_verification_email_at } = user || {};

  const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin(user);

  const isWhiteLabelOverdue = paymentWhiteLabelOverdue;
  const isShowOverdue = !isWhiteLabelOverdue && isOverdue;
  const isVerifyEmail =
    !isWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

  useEffect(() => {
    getAllLabels && getAllLabels();
  }, []);

  const handleRemoveLabel = (e, id) => {
    e.stopPropagation();

    toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        newStyle
        largeSpacing
        hasHoverState
        confirmButtonTitle="Remove"
        title="Remove Label"
        content="Are you sure you want to remove this label? It will be removed from all the workouts in the on-demand workout library."
        onConfirm={() => removeLabelFromList(id)}
      />,
    );
  };

  const handleEditLabel = (e, item) => {
    e.stopPropagation();
    setOpen(true);
    setLabelEditing(item);
  };

  const handleUpdateLabelName = (params, callback) => {
    labelEditing && updateLabelName && updateLabelName({ ...params, labelId: get(labelEditing, '_id') });
    callback && callback();
  };

  const handleGotoDetailLabel = id => {
    push(`workout-labels/${id}`);
  };

  const renderLabelItem = item => {
    const propertiesLength = get(item, 'items', []).length;

    return (
      <S.ItemWrapper key={item._id} onClick={() => handleGotoDetailLabel(get(item, '_id', ''))}>
        <div>
          <S.ItemName>{get(item, 'name', '')}</S.ItemName>
          <S.ItemInfo>
            {propertiesLength} {propertiesLength === 1 ? 'option' : 'options'}
          </S.ItemInfo>
        </div>
        <S.IconsWrapper>
          {!['default'].includes(get(item, 'type', '')) && (
            <>
              <>
                <S.CustomEditIcon
                  className="labelInfo__icon"
                  onClick={e => handleEditLabel(e, item)}
                  data-tip
                  data-for="edit-workout-label-tooltip"
                />
                <SharedTooltip id="edit-workout-label-tooltip" content="Edit" />
              </>
              <>
                <S.CustomDeleteIcon
                  className="labelInfo__icon"
                  onClick={e => handleRemoveLabel(e, item._id)}
                  data-tip
                  data-for="remove-workout-label-tooltip"
                />
                <SharedTooltip id="remove-workout-label-tooltip" content="Remove" />
              </>
            </>
          )}
          <S.CustomArrowIcon className="labelInfo__arrow" />
        </S.IconsWrapper>
      </S.ItemWrapper>
    );
  };

  return (
    <S.Container>
      <WorkoutLabelsHeader addNewAllLabels={addNewAllLabels} />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <S.ListWrapper isVerifyEmail={isWhiteLabelOverdue || isShowOverdue || isVerifyEmail}>
          {reverseWorkoutLabels.map(item => renderLabelItem(item))}
        </S.ListWrapper>
      )}
      <AddNewForm
        open={open}
        itemEditing={labelEditing}
        titlePopup="Edit Label"
        submitName="Save"
        titleInput="Label name"
        placeholderInput="Enter Label Name"
        maxLength={30}
        onSetOpen={setOpen}
        onSubmit={handleUpdateLabelName}
      />
    </S.Container>
  );
}

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { workoutLabels, pricing },
  } = state;

  return {
    user,
    loading: workoutLabels.loading,
    workoutLabels: get(workoutLabels, 'list', []),
    paymentOverdue: pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    paymentWhiteLabelOverdue: pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  getAllLabels: bindActionCreators(getAllLabels, dispatch),
  addNewAllLabels: bindActionCreators(addNewAllLabels, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeLabelFromList: bindActionCreators(removeLabelFromList, dispatch),
  updateLabelName: bindActionCreators(updateLabelName, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutLabels);
