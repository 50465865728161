import React from 'react';

import * as S from './style';

const ContentSkeleton = ({ displayType }) => {
  return (
    <S.ContentSkeletonWrapper className={displayType}>
      <div className="skeleton-thumbnail" />
      <div className="skeleton-info">
        <div className="skeleton-name" style={{ width: '80%' }} />
        <div className="skeleton-name" style={{ width: '60%' }} />
        <div className="skeleton-name" style={{ width: '70%' }} />
      </div>
    </S.ContentSkeletonWrapper>
  );
};

export default ContentSkeleton;
