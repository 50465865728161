import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

function AutoflowPausedWarning(props) {
  const { multiple, title, description } = props;

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/alert_warning.svg`} />
            <span>{title || 'Autoflow is paused'}</span>
          </S.Header>
          <S.Body>
            <div>
              {description ||
                `Autoflow has been paused. Please activate Autoflow first before activating particular client${
                  multiple ? 's' : ''
                }.`}
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>OK</Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

export default connect(null, { toggleModal })(AutoflowPausedWarning);
