import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const ActionsContainer = styled.div`
  width: 150px;
  padding: 15px 0;

  .actions__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 5px 0 5px 15px;

    img {
      width: 14px;
      margin-right: 12px !important;
    }

    :hover {
      background-color: #696969;
    }
  }
`;

export const TaskContainer = styled.div`
  padding: 8px;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  box-shadow: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;

  .task__icon {
    img {
      width: 22px;
    }
    flex: 0 0 22px;
  }

  .task__title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    overflow: hidden;
    padding-left: 8px;

    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 150%;
    }

    .description {
      font-size: 10px;
      font-weight: 400;
      color: #a0a8b1;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  i.icon.more-actions-icon {
    margin: 0 0 0 auto !important;
  }
`;

export const CellHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  padding: 0 10px;

  .day {
    font-size: 11px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    color: #728096;
    text-align: center;
    font-weight: 600;
    font-family: Open Sans;

    &.today {
      background-color: #726ee4;
      color: #fff;
    }
  }

  .add-task-button {
    visibility: hidden;
    cursor: pointer;

    .plus-icon {
      width: 16px;
      height: 16px;
      display: block;
      background: url(${CDN_URL}/images/plus_circle_white.svg) no-repeat;
      background-size: contain;
      background-position: center;
      position: relative;
      z-index: 10000;
    }
  }
`;

export const Content = styled.div`
  border-radius: 6px;
  display: block;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  padding: 0 5px;

  &.no-tasks {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${TaskContainer} {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CellFooter = styled.div`
  padding: 0;
  width: 100%;
  min-height: 22px;

  .paste {
    height: 22px;
    line-height: 19.5px;
    border-radius: 5px;
    background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
    font-size: 13px;
    font-weight: unset;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    display: none;
    user-select: none;
    align-items: center;
    justify-content: center;
  }

  .more-tasks {
    display: block;
    font-size: 11px;
    font-weight: 600;
    color: #5a636c;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Container = styled.div`
  border-radius: 6px;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  background: #f3f5f8;
  position: relative;
  flex-basis: 0;
  margin: 0;
  height: 100%;
`;

export const Wrapper = styled.div`
  &.view-more {
    ${Container} {
      background: rgb(255, 255, 255);
      border: 1px solid rgb(114, 110, 228);

      ${CellHeader} {
        border-bottom: 1px solid #e5eced;
        .day {
          width: auto;
          color: #2d2e2d;
        }
      }
    }
  }

  :hover {
    ${CellHeader} {
      .add-task-button {
        visibility: visible;
      }
    }

    ${Container} {
      ${Content}.copying {
        ${CellFooter} {
          .more-tasks {
            display: none;
          }
          .paste {
            display: flex;
          }
        }
      }
    }
  }
`;
