import { TextBase } from 'components/MetricGroupLibrary/style';
import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

export const CustomModal = styled(Modal)`
  padding: unset;
  font-family: 'Open Sans';

  &.ui.modal.evf-add-group-modal {
    margin: unset;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: unset;
    width: 649px;

    & > .header,
    & > .content,
    & > .actions {
      border: unset;
      border-radius: unset;
      padding: unset;
      background: transparent !important;
    }

    & > .modal-add-group-header {
      position: relative;

      .close-button {
        position: absolute;
        display: flex;
        top: -8px;
        right: -10px;
        .close-icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
`;

export const HeaderWrapper = styled.div`
  padding: 20px 30px 0 20px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  margin-bottom: 40px;
`;

export const AddMetricHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .metric-search {
    height: 30px;
    width: 224px;

    input {
      padding-right: 10px;
    }
  }
`;

export const AddMetricHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  ${TextBase}
  font-size: 19px;
  font-weight: 600;
  line-height: 30px;
  color: #202353;
  gap: 18px;
  margin-left: 10px;

  .back-icon {
    cursor: pointer;
    path {
      fill: #202353;
    }
  }
`;

export const HeaderTitle = styled.div`
  ${TextBase}
  font-size: 19px;
  font-weight: 600;
  line-height: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 480px;
  padding-right: 8px;
  padding-bottom: 20px;

  .metric-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 49px 0 50px;

    svg {
      margin-bottom: 15px;
    }

    .checkbox-metric-icon {
      position: relative;
      top: -10px;
      right: -9px;
    }

    p {
      color: #202353;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 22.5px */
    }
  }

  .checkbox-metric-icon {
    position: relative;
    top: -2px;
    display: flex;
    gap: 10px;
    color: #202353;
    text-align: right;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    padding-left: 0;
    line-height: 20px;

    .checkbox-icon {
      border-radius: 4px;
      background-size: 69%;
      top: 0;
      position: relative;
      transform: translateY(0);
    }
  }

  .metric-group-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    padding: 0 15px 0 30px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px !important;
      position: relative;
      right: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px !important;
      background-color: #e1e1ea !important;
      height: 131px !important;
    }
    &::-webkit-scrollbar-track {
      width: 17px !important;
    }

    .loading {
      flex-shrink: 0;
    }

    > div:last-child {
      margin-bottom: 5px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
`;

export const Action = styled.div`
  display: flex;
  padding: 15px 30px 15px 15px !important;
  justify-content: flex-end;
  border-top: 1px solid #f0f0f2;
  width: 100%;
  height: 60px;
  z-index: 2;
  gap: 10px;

  button {
    width: 103px;

    &:disabled {
      border-radius: 5px;
      background: #eaeef1;
      color: #7b7e91;
    }

    &.btn-cancel {
      width: 99px;
    }
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 30px;
  justify-content: center;
  margin-top: -70px;

  p {
    color: #202353;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const EmptyIcon = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  background: #f0f0f2;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
`;

export const MetricGroupItem = styled.div`
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background: #fff;
  font-family: 'Open Sans';
  padding: 20px;

  &:hover {
    border-color: #5158cf;
    cursor: pointer;
  }

  ${({ checked }) =>
    checked &&
    css`
      border-color: #5158cf;
    `}

  .group-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;

    h5 {
      color: #202353;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 19.5px */
      margin-bottom: 0;
    }
  }

  .names {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #7b7e91;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;
