// Libs
import React from 'react';
import get from 'lodash/get';

// Shared
import FormItemInput from 'shared/FormItemInput';

// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down.svg';

// Constants
import { CALENDAR_LIMIT_WEEKS } from 'constants/commonData';

// Style
import * as S from './style';

const invalidChars = ['-', '+', 'e'];

const NumberOfWeek = props => {
  const { editMode, formData = {}, handleChange, isSubmitted, handleValidateWeek, onUpdateWeek } = props;
  const numberOfWeek = formData.numberOfWeeks;

  const showError = handleValidateWeek();

  const handleUpdateWeek = type => {
    const numberWeek = +numberOfWeek;
    const isIncrease = type === 'increase';
    const isDecrease = type === 'decrease';

    if ((isIncrease && numberWeek < CALENDAR_LIMIT_WEEKS) || (isDecrease && numberWeek > 1)) {
      const delta = isIncrease ? 1 : -1;
      const result = (numberWeek || 0) + delta;
      onUpdateWeek(result.toString());
    }
  };

  const onKeyDown = e => {
    // Fix: Safari input type number allows letters
    const keyCode = e.keyCode || e.which;

    const isAllowedKey =
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      keyCode === 8 ||
      keyCode === 46 ||
      keyCode === 37 ||
      keyCode === 39 ||
      keyCode === 9;

    if (invalidChars.includes(e.key) || !isAllowedKey) {
      e.preventDefault();
    }
  };

  return (
    <S.Wrapper>
      <FormItemInput
        label="Number Of Weeks"
        placeholder=""
        type="number"
        name="numberOfWeeks"
        inputProps={{ min: 1, disabled: editMode, onKeyDown }}
        value={String(numberOfWeek)}
        onChange={handleChange}
        isSubmitted={(parseInt(numberOfWeek) === 0 || parseInt(numberOfWeek) > CALENDAR_LIMIT_WEEKS) && isSubmitted}
        rules={showError}
        className="custom-error"
      />
      {!editMode && (
        <>
          <S.ButtonUpdateWeek
            className="increase-week"
            onClick={() => handleUpdateWeek('increase')}
            disabled={+numberOfWeek >= CALENDAR_LIMIT_WEEKS}
          >
            <ArrowIcon />
          </S.ButtonUpdateWeek>
          <S.ButtonUpdateWeek
            className="decrease-week"
            hasError={get(showError, '[0].required', false) && isSubmitted}
            onClick={() => handleUpdateWeek('decrease')}
            disabled={+numberOfWeek <= 1}
          >
            <ArrowIcon />
          </S.ButtonUpdateWeek>
        </>
      )}
    </S.Wrapper>
  );
};

export default NumberOfWeek;
