// Libs
import React, { useState, useCallback, useRef, useMemo } from 'react';
import Select from 'react-select';
import find from 'lodash/find';

// Utils
import { TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { ASSETS_SHARE_SETTING_TYPE, NO_OWNER_SHARED_OPTION, getOwnerOptions } from '../constants';
import { isTeamAdmin } from 'utils/commonFunction';

// Parts
import DefaultDropdownIndicator from './DefaultDropdownIndicator';
import CustomDropdownIndicator from './CustomDropdownIndicator';

// Styles
import * as S from '../styles';

const FieldOwner = props => {
  const {
    user = {},
    owner = {},
    disabled = false,
    usePortal = false,
    type = ASSETS_SHARE_SETTING_TYPE.DEFAULT,
    onChangeOwnership,
    shareStatus,
    ownership,
  } = props;

  const selectOwnerRef = useRef();
  const [menuOwnerPlacement, setMenuOwnerPlacement] = useState(props.menuPlacement || 'auto');

  const [defaultOwnerValue, useCustomIcon, ownershipOptions] = useMemo(() => {
    let ownerValue;
    if (shareStatus === TEAM_SHARE_NOOWNER || owner === null || ownership === NO_OWNER_SHARED_OPTION.value) {
      ownerValue = NO_OWNER_SHARED_OPTION;
    } else {
      const ownershipData = find(user.team_member, item => item && item._id === ownership);
      if (ownershipData) {
        let fullName =
          ownershipData.full_name || `${ownershipData.first_name || ''} ${ownershipData.last_name || ''}`.trim();
        if (ownershipData._id === user._id) {
          fullName += ' (You)';
        }
        ownerValue = {
          text: fullName,
          value: ownershipData._id,
        };
      }
    }

    const showCustomIcon = type === ASSETS_SHARE_SETTING_TYPE.OWNERSHIP_POPUP;

    const isOwnerOrAdmin = isTeamAdmin(user);
    const members = isOwnerOrAdmin ? user.team_member || [] : [user];
    const options = getOwnerOptions({
      members,
      hasYou: true,
      userId: user._id,
    });

    return [ownerValue, showCustomIcon, options];
  }, [user, type, owner, shareStatus, ownership]);

  const menuOwnerPortalTarget = useMemo(() => {
    if (usePortal) return document.body;
    return false;
  }, [usePortal]);

  const checkPlacementOwner = useCallback(() => {
    if (selectOwnerRef.current && typeof selectOwnerRef.current.getBoundingClientRect === 'function') {
      const rect = selectOwnerRef.current.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= 100) {
        setMenuOwnerPlacement('top');
      } else {
        const propsMenuPlace = props.menuPlacement || 'auto';
        menuOwnerPlacement !== propsMenuPlace && setMenuOwnerPlacement(propsMenuPlace);
      }
    }
  }, [menuOwnerPlacement, selectOwnerRef]);

  return (
    <S.Wrapper ref={selectOwnerRef}>
      <S.FieldLabel>Owner</S.FieldLabel>
      <Select
        blurInputOnSelect
        isSearchable={false}
        isMulti={false}
        name="owner"
        classNamePrefix="owner"
        className="select-owner"
        defaultValue={defaultOwnerValue}
        options={ownershipOptions}
        isDisabled={disabled}
        getOptionLabel={option => option.text}
        getOptionValue={option => option.value}
        menuPlacement={menuOwnerPlacement}
        menuPortalTarget={menuOwnerPortalTarget}
        onChange={onChangeOwnership}
        onMenuOpen={checkPlacementOwner}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: useCustomIcon ? CustomDropdownIndicator : DefaultDropdownIndicator,
        }}
        styles={S.customSelectOwnerStyles}
      />
    </S.Wrapper>
  );
};

export default FieldOwner;
