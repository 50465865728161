import React, { useEffect } from 'react';
import get from 'lodash/get';

import WorkoutDetailModalAIDemo from 'components/WorkoutDetailModalAIDemo';
import { WORKOUT_AI_BUILDER_PAGES, WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import './style.scss';

function CreateWorkoutAIDemo(props) {
  const {
    onAssignment,
    onUpdateAssignment,
    addWorkout,
    updateWorkout,
    type,
    push,
    openWorkoutDetail,
    onSavedWithoutClose,
    onAssignmentDelete,
    removeWorkout,
    onSaveToLibrary,
    onLogWorkout,
    toggleModal,
    isTrackable,
    pdfType,
    workoutAIPage,
  } = props || {};

  const onSave = async (workout, isUpdated) => {
    try {
      const handleAssignment = async () => {
        return isUpdated ? await onUpdateAssignment(workout) : await onAssignment(workout);
      };

      const handleRegularWorkout = async () => {
        return isUpdated ? await updateWorkout(workout) : await addWorkout(workout);
      };

      const isAssignmentType = [WORKOUT_BUILDER_TYPES.ASSIGNMENT, WORKOUT_BUILDER_TYPES.IN_LIBRARY].includes(type);

      const response = isAssignmentType ? await handleAssignment() : await handleRegularWorkout();
      return get(response, 'data.data', response);
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  const savedWithoutClose = newWorkout => {
    const { _id } = newWorkout || {};
    if (typeof onSavedWithoutClose === 'function') {
      onSavedWithoutClose(newWorkout);
    } else {
      openWorkoutDetail(_id);
      push(`/home/workout/${_id}/detail`);
    }
  };

  const onDelete = workout => {
    const { _id } = workout || {};
    if (typeof onAssignmentDelete === 'function') {
      onAssignmentDelete(_id, workout);
    } else {
      removeWorkout(_id);
    }
  };

  const saveToLibrary = workout => {
    if (typeof onSaveToLibrary === 'function') {
      onSaveToLibrary(workout);
    }
  };

  const logWorkout = workout => {
    if (typeof onLogWorkout === 'function') {
      onLogWorkout(workout);
    }
  };

  useEffect(() => {
    toggleModal(
      true,
      <WorkoutDetailModalAIDemo
        onSave={onSave}
        onSavedWithoutClose={savedWithoutClose}
        saveToLibrary={saveToLibrary}
        logWorkout={logWorkout}
        trackable={isTrackable}
        type={type || WORKOUT_BUILDER_TYPES.TEMPLATE}
        key="create-workout-template"
        pdfType={pdfType || 'workout'}
        workoutAIPage={workoutAIPage || WORKOUT_AI_BUILDER_PAGES.workout_library}
        onDelete={onDelete}
      />,
    );
  }, []);

  return null;
}

export default CreateWorkoutAIDemo;
