import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { cloneDeep, debounce, omit } from 'lodash';
import { toast } from 'react-toastify';

import { NewSearchInput } from 'shared/SearchInput';
import { Button } from 'shared/FormControl';

import TableHeader from './TableHeader';
import TableContent from './TableContent';
import * as S from './styles';
import { axiosInstance } from 'configs/request';
import Filter, { DEFAULT_FILTERS } from 'shared/ExerciseFilterPopup';
import { CDN_URL, TAGS_TYPE } from 'constants/commonData';

const SearchIcon = `${CDN_URL}/images/search_bold.svg`;

export const SORTER = {
  EXERCISE: 'title',
  // PRIMARY_FOCUS: 'body_part',
  TAGS: 'tags',
  MODALITY: 'modality',
  MUSCLE_GROUP: 'muscle_group',
  MOVEMENT_PATTERN: 'movement_pattern',
  CATEGORY: 'category_type_name',
};

export const SORT = {
  ASC: 1,
  DESC: -1,
};

const PAGE_SIZE = 20;

const DEFAULT_QUERY = {
  page: 1,
  perPage: PAGE_SIZE,
  q: '',
  ...DEFAULT_FILTERS,
};

export default function ChangeExerciseModal({
  open,
  onClose,
  currentExercise,
  onSelectExercise,
  bodyParts,
  categories,
  exerciseTags,
  getMostRecentTagsList,
  equipments,
  modalities,
  muscleGroup,
  movementPattern,
}) {
  const [query, setQuery] = useState(DEFAULT_QUERY);
  const [exercises, setExercises] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState(currentExercise);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const latestQuerySortRef = useRef({});

  const lastExerciseElRef = useCallback(
    node => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          // Last element is in view
          setQuery({ ...query, page: query.page + 1 });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    open && getExercises();
  }, [query, open]);

  useEffect(() => {
    resetQuery();
    setSelectedExercise(currentExercise);
    open && getMostRecentTagsList({ type: TAGS_TYPE.EXERCISE });
  }, [open]);

  const resetQuery = () => {
    setQuery(DEFAULT_QUERY);
  };
  const handleSubmit = () => {
    onSelectExercise({ ...selectedExercise, value: selectedExercise._id });
    onClose();
  };

  const getParamsSort = search => {
    const { sort, sorter } = query;

    if (search === '') {
      if (sort !== undefined && sorter !== undefined) {
        return {};
      }

      return {
        sorter: latestQuerySortRef.current.sorter,
        sort: latestQuerySortRef.current.sort,
      };
    }

    if (sort !== undefined && sorter !== undefined) {
      latestQuerySortRef.current = { sort, sorter };
    }

    return { sorter: undefined, sort: undefined };
  };

  const handleSearch = (event, { value }) => {
    const paramsSort = getParamsSort(value);

    setQuery({ ...query, q: value, page: 1, ...paramsSort });
  };
  const changeQueryParams = newData => {
    setQuery({ ...query, ...newData });
  };
  const handleClearSearch = () => {
    const paramsSort = getParamsSort('');

    setQuery({ ...query, q: '', page: 1, ...paramsSort });
  };
  const handleChangeExercise = exercise => {
    setSelectedExercise(exercise);
  };
  const handleKeyPress = () => {};
  const textSearch = () => {};
  const getExercises = () => {
    if (query.page === 1) {
      setExercises([]);
    }
    let queryParams = cloneDeep(query);
    if (queryParams.tags && queryParams.tags.length > 0) {
      queryParams.tags = queryParams.tags.map(tag => tag._id);
    }
    setLoading(true);
    axiosInstance
      .post('/api/exercise/search_filter', queryParams)
      .then(res => {
        const { data } = res.data;
        const newData = queryParams.page === 1 ? data : [...exercises, ...data];
        if (newData.length === res.data.total) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setExercises(newData);
        setLoading(false);
      })
      .catch(err => {
        toast.error('Could not connect to server, please try again later.');
        setLoading(false);
      });
  };
  const handleApplyFilter = value => {
    setQuery({ ...query, page: 1, ...value });
  };
  return (
    <S.ChangeExerciseModalContainer
      open={open}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close_circle" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <div className="container">
          <div>Change Exercise</div>
          <NewSearchInput
            className="change-exercise-search-input"
            placeholder="Search by exercise name"
            onChange={debounce(handleSearch, 300)}
            onClearSearch={handleClearSearch}
            onKeyPress={handleKeyPress}
            defaultValue={textSearch}
            searchIconPath={SearchIcon}
          />

          <S.FilterTriggerContainer>
            <Filter
              className="change-exercise-filter"
              tags={exerciseTags}
              bodyParts={bodyParts}
              categories={categories}
              currentFilters={omit(query, ['page', 'perPage', 'q', 'sort', 'sorter'])}
              onApply={handleApplyFilter}
              menuHeight={585}
              trigger={<S.FilterButton />}
              equipments={equipments}
              modalities={modalities}
              muscleGroup={muscleGroup}
              movementPattern={movementPattern}
            />
          </S.FilterTriggerContainer>
        </div>
      </Modal.Header>

      <Modal.Content>
        <S.TableContainer id="content-header">
          <TableHeader query={query} counter={exercises.length} changeQueryParams={changeQueryParams} />
          <TableContent
            lastExerciseElRef={lastExerciseElRef}
            onSelectExercise={handleChangeExercise}
            exercises={exercises}
            currentExerciseId={selectedExercise._id}
            loading={loading}
            type="change-exercise"
            changeQueryParams={changeQueryParams}
          />
          {loading && <S.LoadingIndicator isLoadingMore={query.page > 1} />}
        </S.TableContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button className="cancel-btn" onClick={onClose}>
          Cancel
        </Button>
        <Button
          purple
          className="refund-btn"
          onClick={handleSubmit}
          disabled={selectedExercise._id === currentExercise._id}
        >
          Change
        </Button>
      </Modal.Actions>
    </S.ChangeExerciseModalContainer>
  );
}
