import React from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import * as S from './style';
import { SelectFile } from 'shared/FormControl';
import { createObjectURL, revokeObjectURL, mediaLog } from 'utils/commonFunction';
import { validateFiles } from 'utils/validations';
import ReactTooltip from 'react-tooltip';
import FileUpload from 'shared/FileUpload';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { getPresignedUploadUrl, removeAllParamsFromURL } from 'utils/commonFunction';

const DEFAULT_STATE = {
  src: '',
  isLocalUrl: false,
  file: null,
};

class UploadCoverImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.maxSize = 25;
    this.validateExtentions = ['png', 'jpg', 'jpeg'];
    this.uploadConfigs = null;
    this.updateConfigs = null;
  }

  getPresignedURLs = async list => {
    try {
      const response = await this.props.getS3presignedURL(list);
      this.setState(state => ({
        src: response[0],
      }));
    } catch {}
  };

  componentDidMount() {
    this._isMounted = true;
    this.getPresignedURLs([this.props.src]);
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }
  }

  onSelectFile = async file => {
    const { configs, uploadUrl } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-program-library', file);
    this.updateConfigs = {
      ...configs,
      url: removeAllParamsFromURL(uploadUrl),
    };
    this.uploadConfigs = {
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    };
    this.setState({ src: createObjectURL(file), isLocalUrl: true, file }, () => {
      const { size, name, type } = file;
      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file to S3',
      });
      this.props.onStartUpload(file);
    });
  };

  onDropFile = files => {
    if (files.length > 1) {
      return toast.error('Please upload one file at a time');
    }

    if (files.length) {
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: this.validateExtentions,
        maxSize: this.maxSize * CONVERSION.MB_TO_BYTE,
      });

      if (error) {
        let message = '';

        if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = 'File type not supported.';
        } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = 'Image size limit is 25MB.';
        }

        toast.error(message);
      } else {
        this.onSelectFile(file);
      }
    }
  };

  onUploadSuccess = response => {
    if (this._isMounted) {
      const { data } = response.data;
      const { size, name, type } = this.state.file;
      mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload success a file to S3',
      });
      this.uploadConfigs = null;
      this.setState({ file: null }, () => {
        this.props.onUploadSuccess(this.updateConfigs);
      });
    }
  };

  onCancelUpload = () => {
    if (this._isMounted) {
      revokeObjectURL(this.state.src);
      this.uploadConfigs = null;
      this.setState({ ...DEFAULT_STATE }, () => {
        this.props.onEndUpload();
      });
    }
  };

  onRemoveImage = () => {
    if (this.state.isLocalUrl) {
      revokeObjectURL(this.state.src);
    }

    this.setState({ ...DEFAULT_STATE }, () => {
      this.props.onRemove();
    });
  };

  render() {
    const { src, file } = this.state;

    if (!src && !this.props.src) {
      return (
        <Dropzone onDrop={this.onDropFile} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Wrapper {...getRootProps()} isDragging={isDragActive} className={this.props.className}>
              <S.TooltipIcon data-tip data-for="upload-warning" />
              <ReactTooltip id="upload-warning" effect="solid" place={'top'}>
                Use an image size of at least 640 x 360 pixels for the best resolution and display.
              </ReactTooltip>
              <S.Placeholder isDragging className="upload__placeholder">
                <S.Icon />
                <S.Text>
                  Drag & Drop your
                  <br />
                  images here
                  <br />
                  or&nbsp;
                  <SelectFile
                    trigger={<span>Choose Files</span>}
                    onSelect={this.onSelectFile}
                    accept="image/png, image/jpeg, image/jpg"
                    validateExtentions={this.validateExtentions}
                    maxSize={this.maxSize}
                    fileSizeLimitMessage="Image size limit is 25MB."
                  />
                </S.Text>
              </S.Placeholder>
              <input {...getInputProps({ onClick: event => event.preventDefault() })} className="dropzone__input" />
            </S.Wrapper>
          )}
        </Dropzone>
      );
    }

    return (
      <Dropzone onDrop={this.onDropFile} multiple={false}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <S.Wrapper
            {...getRootProps()}
            isDragging={isDragActive}
            src={src || this.props.src}
            className={this.props.className}
          >
            {!file && this.props.hasRemoveIcon && (
              <S.RemoveIcon
                onClick={e => {
                  e.stopPropagation();
                  this.onRemoveImage();
                }}
              />
            )}
            {file ? (
              <FileUpload
                onSuccess={this.onUploadSuccess}
                onError={() => {}}
                onCancel={this.onCancelUpload}
                configs={this.uploadConfigs}
              />
            ) : (
              <S.Placeholder isDragging={isDragActive} className="upload__placeholder">
                <S.TooltipIcon data-tip data-for="upload-warning" />
                <ReactTooltip id="upload-warning" effect="solid" place={'top'}>
                  Use an image size of at least 640 x 360 pixels for the best resolution and display.
                </ReactTooltip>
                <S.Icon />
                <S.Text>
                  Drag & Drop your
                  <br />
                  images here
                  <br />
                  or&nbsp;
                  <SelectFile
                    disabled
                    trigger={<span>Choose Files</span>}
                    onSelect={this.onSelectFile}
                    accept="image/png, image/jpeg, image/jpg"
                    validateExtentions={this.validateExtentions}
                    maxSize={this.maxSize}
                    fileSizeLimitMessage="Image size limit is 25MB."
                  />
                </S.Text>
              </S.Placeholder>
            )}
            {!this.props.hideFileInput && !file && (
              <input {...getInputProps()} accept="image/png, image/jpeg, image/jpg" />
            )}
            {!file && this.props.children}
          </S.Wrapper>
        )}
      </Dropzone>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(null, mapDispatchToProps)(UploadCoverImage);
