// Lib
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

// Actions
import { getListMealPlan } from 'redux/client-meal-plan/actions';
import { toggleSecondModal } from 'actions/modal';

// Component
import CommonPopup from '../CommonPopup';
import ItemContent from '../ItemContent';
import ItemSkeleton from '../ItemSkeleton';
import AssignMealPlanModal from '../AssignMealPlanModal';

// utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { mongoObjectId } from 'utils/commonFunction';

// Assets
import { ReactComponent as EmptyIcon } from 'assets/icons/MealPlans/meal-plan-empty.svg';

// Style
import * as S from './style';

const AssignMealPlan = ({
  cloudfrontList,
  getListMealPlan,
  toggleSecondModal,
  workingClientDetail,
  assignedDay,
  clientTimezone,
  currentMonth,
  selectedDay,
}) => {
  const [filter, setFilter] = useState({
    page: 1,
    per_page: 15,
    sort: -1,
    sorter: 'updated_at',
    only_my_meal_plans: false,
    text_search: '',
    status: ['publish'],
  });
  const listRef = useRef(null);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    handleGetListMealPlan();
  }, [filter]);

  const handleGetListMealPlan = async () => {
    try {
      setLoading(true);
      const res = await getListMealPlan(filter);
      setData(prevState => [...prevState, ...get(res, 'data.data.data')]);
      setTotal(get(res, 'data.data.total'));
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <S.Wrapper>
        <S.Title>MEAL PLANS ({total})</S.Title>
        {filter && filter.page === 1 && loading ? (
          renderSkeleton()
        ) : (
          <>
            {get(data, 'length', 0) > 0 ? (
              <S.ListWrapper>
                <S.List ref={listRef} onScroll={debounce(onScroll, 300)}>
                  {data.map(item => {
                    const { _id, name, cover_image, cover_image_thumbnail, number_of_weeks } = item;
                    const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
                    const coverImageThumbnail = convertS3UrlToCloudFrontUrl(
                      cover_image_thumbnail,
                      cloudfrontList,
                      true,
                    );
                    const active = _id === (selected && selected._id);

                    return (
                      <S.Item key={_id} onClick={() => handleSelected(item)}>
                        <ItemContent
                          name={name}
                          coverImage={coverImage}
                          coverImageThumbnail={coverImageThumbnail}
                          totalWeek={number_of_weeks}
                          active={active}
                        />
                      </S.Item>
                    );
                  })}
                </S.List>
              </S.ListWrapper>
            ) : (
              renderEmpty()
            )}
          </>
        )}
      </S.Wrapper>
    );
  };

  const renderAction = () => {
    return (
      <S.ButtonNext type="button" disabled={isEmpty(selected)} onClick={handleOpenAssign}>
        Next
      </S.ButtonNext>
    );
  };

  const renderEmpty = () => {
    const { text_search } = filter;

    return (
      <S.Empty>
        {!text_search && (
          <div className="icon">
            <EmptyIcon />
          </div>
        )}
        <span>{!text_search ? 'No Meal Plans available.' : 'No results found.'}</span>
      </S.Empty>
    );
  };

  const renderSkeleton = () => {
    const list = Array(9).fill(mongoObjectId());
    return (
      <S.List loading>
        {list.map((_, index) => {
          return (
            <S.Item key={index}>
              <ItemSkeleton />
            </S.Item>
          );
        })}
      </S.List>
    );
  };

  const handleSelected = item => {
    setSelected(item);
  };

  const handleSearch = value => {
    setData([]);
    setFilter(prevState => ({
      ...prevState,
      page: 1,
      text_search: value,
    }));
  };

  const handleClearSearch = () => {
    setData([]);
    setFilter(prevState => ({
      ...prevState,
      page: 1,
      text_search: '',
    }));
  };

  const onScroll = () => {
    const listPhoto = listRef && listRef.current;
    if (listPhoto && get(data, 'length', 0) < total) {
      const isAtBottom = listPhoto.scrollTop + listPhoto.clientHeight >= listPhoto.scrollHeight - 25;

      if (isAtBottom) {
        handleScrollList();
      }
    }
  };

  const handleScrollList = () => {
    setFilter(prevState => ({
      ...prevState,
      page: prevState && prevState.page + 1,
    }));
  };

  const handleOpenAssign = () => {
    toggleSecondModal &&
      toggleSecondModal(
        true,
        <AssignMealPlanModal
          detailMealPlan={selected}
          readOnlyAssignTO
          workingClientDetail={workingClientDetail}
          assignedDay={assignedDay}
          clientTimezone={clientTimezone}
          currentMonth={currentMonth}
          onCloseError={handleCloseError}
          selectedDay={selectedDay}
        />,
      );
  };

  const handleCloseError = () => {
    setData([]);
    setTotal(0);
    handleGetListMealPlan();
  };

  return (
    <CommonPopup
      title="Assign Meal Plan"
      content={renderContent()}
      action={renderAction()}
      hideFilter
      hideFilterCategory
      onSearch={handleSearch}
      onClearSearch={handleClearSearch}
    />
  );
};

const mapState = state => {
  const { cloudfrontList, rootReducer } = state;
  const workingClientDetail = get(rootReducer, 'client.workingClientDetail');

  return { cloudfrontList, workingClientDetail };
};

const mapDispatch = dispatch => ({
  getListMealPlan: bindActionCreators(getListMealPlan, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
});

export default connect(mapState, mapDispatch)(AssignMealPlan);
