import styled from 'styled-components';

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const EmptyMessage = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  opacity: 0.2;
`;

export const ClientName = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #273d52;
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
