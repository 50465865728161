import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'shared/FormControl';
import styled from 'styled-components';

import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  margin-top: 15px;
`;

export const AddButton = styled(Button)`
  padding: 11px;
  background: #eaebff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5158cf;
  border-color: transparent;
  width: 100%;

  .add-button__icon {
    width: 20px;
    height: 20px;
    background-image: url(${CDN_URL}/images/plus_purple_new.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
  }

  :hover {
    background: #5158cf;
    color: #ffffff;

    .add-button__icon {
      background-image: url(${CDN_URL}/images/plus_white_new.svg);
    }
  }
`;

const AddActions = props => {
  const [element, setElement] = useState(null);
  const observer = useRef(
    new IntersectionObserver(
      entries => {
        const firstEntry = entries[0];
        if (typeof props.onShow === 'function') {
          props.onShow(firstEntry.isIntersecting);
        }
      },
      { threshold: 0.9 },
    ),
  );

  useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  return (
    <Wrapper ref={setElement} className={props.className}>
      <AddButton onClick={props.onAddExercise}>
        <div className="add-button__icon" />
        Add Exercise
      </AddButton>
    </Wrapper>
  );
};

export default AddActions;
