// Libs
import React from 'react';

// Styles
import * as S from './style';

const ConfirmModal = ({
  title,
  width,
  content,
  onClose,
  onConfirm,
  className,
  headerIcon,
  hasCloseIcon = true,
  cancelBtnTitle,
  confirmBtnTitle,
}) => {
  return (
    <S.CustomConfirmModal
      title={title}
      content={content}
      onClose={onClose}
      onConfirm={onConfirm}
      headerIcon={headerIcon}
      cancelButtonTitle={cancelBtnTitle}
      confirmButtonTitle={confirmBtnTitle}
      className={`custom-confirm-modal ${className}`}
      hasCloseIcon={hasCloseIcon}
      noBorder
      isPressEsc
      isCloseOnDimmer={false}
      width={width}
    />
  );
};

export default ConfirmModal;
