import styled, { css } from 'styled-components';

export const EmptyWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 460px);
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.isHideFormBanner &&
    css`
      height: calc(100vh - 270px);
    `}
`;
