import React from 'react';
import debounce from 'lodash/debounce';

import * as S from '../style';
import { useMyContext } from '../context/NotificationContext';

const MarkAllReadButton = () => {
  const { hasUnread, readNotifications } = useMyContext();

  if (!hasUnread) return <></>;

  const onButtonClick = debounce(readNotifications, 300);

  return (
    <S.MarkAllReadButton textOnly onClick={onButtonClick}>
      Mark all as read
    </S.MarkAllReadButton>
  );
};

export default MarkAllReadButton;
