import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 5px;
  height: 49px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 15px;

  span {
    color: #202353;
    font-family: 'Open Sans';
    font-size: ${props => (props.isPermission ? '20px' : '22px')};
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.002px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
`;

export const Description = styled.div`
  color: rgba(32, 35, 83, 0.6);
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  width: calc(100% - 18%);
  white-space: break-spaces;
  text-overflow: ellipsis;
  display: flex;
  position: relative;
`;

export const Action = styled.div`
  position: absolute;
  right: calc(100% - 72% - 5px);
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  ${props =>
    props.isHideUpgradePath &&
    css`
      right: calc(100% - 90% - 4px);
    `}
`;

export const LearnMore = styled.a`
  display: flex;
  margin-bottom: 2px;

  :hover {
    color: #6456c4;
  }
`;

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  margin-top: 2px;
  border-radius: 50%;
  background: #d9d9d9;
`;

export const LearnMoreIcon = styled.img`
  margin-right: 4px;
`;

export const LearnMoreText = styled.div`
  color: #6456c4;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  :hover {
    color: #6456c4;
  }
`;
