import React from 'react';
import { components } from 'react-select';
import get from 'lodash/get';

import { pluralize } from 'utils/commonFunction';
import { SurveyInformationOptionWrapper } from '../styles';

const CustomSingleValue = props => {
  const { children, ...restProps } = props;

  const surveyName = get(props, 'selectProps.placeholder.mask.surveyName', '');
  const authorName = get(props, 'data.author_name', '');
  const totalQuestions = get(props, 'selectProps.placeholder.mask.questions.length', 0);

  return (
    <components.SingleValue {...restProps}>
      <p className="survey__placeholder">Select your survey</p>
      <div className="survey__info">
        <h6 className="survey__info-name">{surveyName}</h6>
        <SurveyInformationOptionWrapper>
          <span className="survey__info-questions">
            <b>{totalQuestions}</b> {pluralize('Question', totalQuestions)}
          </span>
          <div className="dot" />
          <span className="author-name">{authorName}</span>
        </SurveyInformationOptionWrapper>
      </div>
    </components.SingleValue>
  );
};

export default CustomSingleValue;
