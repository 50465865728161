import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import _ from 'lodash';

// Customs
import { EVERY, NEXT_WEEKS, LIST_DAYS } from './../constants';
import { pluralize } from 'utils/commonFunction';
import * as S from './../../TaskCreate/style';
import * as T from './style';
import { DropdownIndicator } from 'shared/Icons';

const SelectStyles = {
  components: { DropdownIndicator, IndicatorSeparator: null },
  styles: {
    control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 74 }),
  },
  classNamePrefix: 'evfSelectBox',
  className: 'evfSelectBoxContainer',
  menuPosition: 'fixed',
  isSearchable: false,
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const WeeklyRepeat = ({ repeatDays, everyRepeat, nextWeekRepeat, onChange, defaultSelected }) => {
  const [every, setEvery] = useState(_.cloneDeep(EVERY)[everyRepeat - 1]);
  const [nextWeek, setNextWeek] = useState(_.cloneDeep(NEXT_WEEKS)[nextWeekRepeat - 1]);
  const [selectedDays, setSelectedDays] = useState(repeatDays);
  const prevProps = usePrevious({ defaultSelected });
  useEffect(() => {
    let cloneSelectedDays = [...selectedDays];
    if (prevProps && prevProps.defaultSelected !== defaultSelected) {
      cloneSelectedDays.shift();
      cloneSelectedDays.unshift(defaultSelected);
      setSelectedDays(_.uniq(cloneSelectedDays));
    } else {
      cloneSelectedDays = _.uniq([...selectedDays, defaultSelected]);
    }
    onChange({
      for_the_next: nextWeek.value,
      every: every.value,
      date_selected_in_weekly: cloneSelectedDays,
    });
  }, [every, nextWeek, selectedDays, defaultSelected]);

  const handleOnChangeEvery = item => {
    setEvery(item);
    if (item.value > nextWeek.value) {
      setNextWeek(item);
    }
  };

  const handleRepeatDays = item => () => {
    let clone = _.cloneDeep(selectedDays);
    if (_.includes(clone, item)) {
      clone = _.filter(clone, it => it != item);
    } else {
      clone.push(item);
    }
    setSelectedDays(clone);
  };

  const handleRepeatNextWeek = item => {
    if (item.value >= every.value) {
      setNextWeek(item);
    }
  };

  const isChecked = value => {
    return _.includes(selectedDays, value) || _.isEqual(value, defaultSelected) ? true : false;
  };

  return (
    <>
      <S.Frequency>
        <S.RepeatTitle>Every</S.RepeatTitle>
        <Select options={EVERY} value={every} onChange={handleOnChangeEvery} {...SelectStyles} />
        <S.RepeatTitle className="nested">{pluralize('week', every.value)} on</S.RepeatTitle>
        <T.DayOfWeeksWrapper>
          <div className="list-rest-day">
            {_.cloneDeep(LIST_DAYS).map((day, index) => (
              <div key={`${day.value}-${index}`}>
                <input
                  type="checkbox"
                  name={day.value}
                  value={day.value}
                  disabled={_.isEqual(day.value, defaultSelected)}
                  checked={isChecked(day.value)}
                  id={`repeat-day-${day.value}`}
                  onChange={handleRepeatDays(day.value)}
                />
                <label className="rest-day-spec" htmlFor={`repeat-day-${day.value}`}>
                  {day.label}
                </label>
              </div>
            ))}
          </div>
        </T.DayOfWeeksWrapper>
      </S.Frequency>
      <S.Frequency>
        <S.RepeatTitle>For the next</S.RepeatTitle>
        <Select options={NEXT_WEEKS} value={nextWeek} onChange={handleRepeatNextWeek} {...SelectStyles} />
        <S.RepeatTitle className="nested">{pluralize('week', nextWeek.value)}</S.RepeatTitle>
      </S.Frequency>
    </>
  );
};

export default WeeklyRepeat;
