import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Layout from 'layouts/Login';
import * as FormStyles from 'layouts/Login/style';
import * as S from './style';
import { ErrorMessage } from 'shared/FormControl';
import { validateEmail } from 'utils/validations';
import { axiosInstanceWithoutToken } from 'configs/request';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      serverError: '',
      success: false,
    };
    this.isSubmitting = false;
  }

  componentDidMount() {
    const paramsString = _.get(this.props, 'location.search', '');

    if (paramsString) {
      const params = paramsString.split('email=')[1];
      this.setState({ email: params || '' });
    }
  }

  onEmailChange = event => {
    const { value } = event.target;
    this.setState({ email: value, serverError: '' });
  };

  onSubmit = event => {
    event.preventDefault();
    const email = this.state.email.trim();

    if (this.isSubmitting || !email || !validateEmail(email)) {
      return false;
    }

    this.isSubmitting = true;
    this.setState({ serverError: '', success: false });
    axiosInstanceWithoutToken
      .post('/api/auth/forgot', { email })
      .then(() => {
        this.isSubmitting = false;
        this.setState({ success: true });
      })
      .catch(error => {
        const serverError = _.get(
          error,
          'response.data.message',
          'Undefined error. Please try again later or contact your administrator.',
        );
        this.isSubmitting = false;
        this.setState({ serverError });
      });
  };

  render() {
    const { email, serverError, success } = this.state;

    return (
      <S.Wrapper>
        <Layout>
          <S.BackButton>
            <Link to="/login">
              {backIcon}
              <span>Back</span>
            </Link>
          </S.BackButton>
          <FormStyles.FormContainer onSubmit={this.onSubmit}>
            <FormStyles.FormHeader className="form__header--reset-password">Reset password</FormStyles.FormHeader>
            <S.Description className={classnames({ 'has-message': serverError || success })}>
              Submit your email address and you will receive instructions to reset password
            </S.Description>
            {serverError && <FormStyles.ServerErrorMessage>{serverError}</FormStyles.ServerErrorMessage>}
            {success ? (
              <S.SuccessContainer>
                A confirmation has been sent to your email. Please check and follow the instruction.
              </S.SuccessContainer>
            ) : null}
            <FormStyles.FormGroup
              className={classnames('form-group', { error: !!email.trim() && !validateEmail(email.trim()) })}
            >
              <FormStyles.Controls>
                <label className={email ? '' : 'hide'}>Your Email Address</label>
                <input
                  defaultValue={email}
                  type="email"
                  placeholder="Your Email Address"
                  onChange={this.onEmailChange}
                  name="password"
                />
              </FormStyles.Controls>
              <ErrorMessage>Your email address is invalid</ErrorMessage>
            </FormStyles.FormGroup>
            <FormStyles.SubmitButton disabled={!email.trim() || !validateEmail(email.trim())}>
              Submit
            </FormStyles.SubmitButton>
          </FormStyles.FormContainer>
        </Layout>
      </S.Wrapper>
    );
  }
}

export default ForgotPassword;

const backIcon = (
  <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 7H2" stroke="#191753" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 1L1 7L7 13" stroke="#191753" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
