import styled from 'styled-components';

export const FeatureHeader = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #000;
  margin-bottom: 40px;

  p {
    margin-bottom: 10px;
  }

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #6456c4 !important;

    img.ui {
      margin-right: 4px;
    }
  }
`;

export const Note = styled.div`
  background: #e7e6fe;
  border-radius: 5px;
  padding: 15px 15px 13px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #5c5bbd;
  width: 100%;
  max-width: 650px;
`;

export const Header = styled.div`
  width: 55%;
  margin: auto;
`;

export const Wrapper = styled.div`
  padding: 30px;
  overflow-y: auto;
  width: 100%;

  .settings__features {
    border-bottom: 1px solid #eeeeee;
  }
`;
