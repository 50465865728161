import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import Input from 'shared/CommentInput';
import CommentItem from 'shared/CommentItem';
import { likeSummary, getUserShortName, pluralize, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import {
  Wrapper,
  Content,
  PostInfo,
  PostAuthor,
  PostCaption,
  Liked,
  Comments,
  InputContainer,
  HeaderWrapper,
  HighFiveWrapper,
  HighFiveMessage,
  AvatarContainer,
  ShowMoreText,
  ContentWrapper,
} from './style';
import Interactions from 'shared/Post/Interations';
import HighFive from 'assets/icons/icon-2-hand.svg';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
      loading: false,
      isShowMore: false,
      initTopHeader: 0,
      initHeightCommentList: 0,
    };

    this.inputRef = React.createRef();
    this.bodyRef = React.createRef();
    this.captionRef = React.createRef();
    this.noScrollRef = React.createRef();

    this.toggleReadMore = () =>
      this.setState({ isShowMore: !this.state.isShowMore }, () => {
        if (this.noScrollRef && this.noScrollRef.current) {
          this.setState({ initTopHeader: this.noScrollRef.current.scrollHeight });
        }
      });
  }

  componentDidMount() {
    const { getComments, post } = this.props;
    getComments(post._id).then(response => {
      const { data, total } = response.data;
      this.setState({ list: data, total }, () => {
        if (this.noScrollRef && this.noScrollRef.current) {
          this.handleInitHeightCommentList();
        }
      });
    });
    this.checkCaptionHeight();
  }

  componentDidUpdate(prevProps, prevState) {
    const { caption, liker } = this.props.post;
    const { initTopHeader } = this.state;
    if (prevProps.post.caption !== caption) {
      this.checkCaptionHeight();
    }
    if (prevProps.post.liker !== liker) {
      if (this.noScrollRef && this.noScrollRef.current && initTopHeader === 0) {
        this.handleInitHeightCommentList();
      }
    }
  }

  handleInitHeightCommentList = () => {
    const { post, userId } = this.props;
    const liker = post.liker || [];
    const likeSum = likeSummary(userId, liker);
    const initHeightCommentList = this.noScrollRef.current.scrollHeight + 15 + (likeSum ? 5 : 0);
    this.setState({
      initHeightCommentList,
    });
  };

  checkCaptionHeight = () => {
    const {
      post: { caption },
    } = this.props;
    const textLength = caption.length;
    const currentHeight = this.captionRef.current ? this.captionRef.current.clientHeight : 0;
    const isShowMore = textLength > 333 || currentHeight > 84;
    this.setState({ isShowMore });
  };

  componentWillUnmount() {
    const { post, updatePostsAfterViewDetail } = this.props;
    const { total } = this.state;
    updatePostsAfterViewDetail(post._id, { total_comment: total });
  }

  getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, this.props.cloudfrontList, true);

  addComment = (value, callback) => {
    const { list, loading } = this.state;
    let comment = value ? value.trimAny('↵').trim() : '';
    comment = comment.trim();

    if (comment && !loading) {
      this.setState({ loading: true });

      const { addCommentToPost, post } = this.props;

      const body = {
        item: post._id,
        topic: 'food_journal',
        content: comment,
      };

      addCommentToPost(body)
        .then(response => {
          const newList = list.slice();
          const { data, total } = response.data;
          newList.push(data);
          this.setState({ list: newList, total }, () => {
            this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
          });
          callback && callback();
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  renderPostInfo = () => {
    const { post } = this.props;
    const { author, date, caption } = post;
    const name = `${author.first_name} ${author.last_name}`;
    const { isShowMore } = this.state;

    return (
      <PostInfo className="post__info">
        <PostAuthor className="post__author">
          <Avatar
            name={getUserShortName(author)}
            className="author__avatar"
            size="46"
            src={this.getCloudUrl(author.avatar)}
          />
          <div className="author__name">
            <div>{name}</div>
            <div className="date" data-tip data-for={post._id}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.375 11.625C9.2745 11.625 11.625 9.2745 11.625 6.375C11.625 3.47551 9.2745 1.125 6.375 1.125C3.47551 1.125 1.125 3.47551 1.125 6.375C1.125 9.2745 3.47551 11.625 6.375 11.625Z"
                  stroke="#A2A2A2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.375 3.375V6.375H9.375"
                  stroke="#A2A2A2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{moment.utc(date).format('h:mm A')}</span>
            </div>
            <ReactTooltip className="app-tooltip" id={post._id} effect="solid" place={'top'}>
              <span>{moment.utc(date).format('dddd, MMMM DD, YYYY [at] h:mm A')}</span>
            </ReactTooltip>
          </div>
        </PostAuthor>

        {!!caption && (
          <PostCaption className="post__caption" ref={this.captionRef} showMore={isShowMore}>
            {caption}
          </PostCaption>
        )}
        {!!caption && isShowMore && <ShowMoreText onClick={this.toggleReadMore}>{'... See more'}</ShowMoreText>}
      </PostInfo>
    );
  };

  renderAvatars = users => {
    return (
      <AvatarContainer twoAvatars={users.length >= 2}>
        {users.slice(0, 2).map(user => (
          <Avatar
            name={getUserShortName(user)}
            className="avatar"
            size="20"
            src={user ? this.getCloudUrl(user.avatar) : ''}
          />
        ))}
      </AvatarContainer>
    );
  };

  renderLikeSummary = () => {
    const { post, userId } = this.props;
    const liker = post.liker || [];
    const likeSum = likeSummary(userId, liker);

    if (likeSum) {
      return (
        <Liked className="post__liked">
          {this.renderAvatars(liker)}
          Liked by&nbsp;<span className="name">{likeSum}</span>
        </Liked>
      );
    }

    return null;
  };

  renderHighFivesName = list => {
    const { userId } = this.props;
    const personName = list.map(item => (item._id === userId ? 'You' : item.first_name));
    if (personName.length === 2) {
      const [first, last] = personName;
      return (
        <>
          <span>{first}</span> and <span>{last}</span>
        </>
      );
    }
    if (personName.length > 2) {
      const firstTwo = personName.slice(0, 2);
      if (personName.length === 3) {
        const [last] = personName.slice(-1);
        return (
          <>
            <span>{firstTwo.join(', ')}</span> and <span>{last}</span>
          </>
        );
      }
      const others = list.map(item => (item._id === userId ? 'You' : item.full_name)).slice(2);
      const othersCount = others.length;
      return (
        <>
          <span>{firstTwo.join(', ')}</span> and{' '}
          <span data-tip data-for="high-five-tooltip" className="others-name">
            {othersCount} others
          </span>
          <ReactTooltip
            id="high-five-tooltip"
            effect="solid"
            place={'bottom'}
            delayShow={500}
            className="high-five-tooltip"
            show
          >
            {others.map(item => (
              <p key={item}>{item}</p>
            ))}
          </ReactTooltip>
        </>
      );
    }
    return <span>{personName.join(', ')}</span>;
  };

  handleHighFive = assignmentId => {
    const { highFiveFoodJournal } = this.props;
    highFiveFoodJournal && highFiveFoodJournal(assignmentId);
  };

  render() {
    const { list, total, initTopHeader, initHeightCommentList } = this.state;
    const { post, userId, likePost, isHighFive, isLoadingHighFive, highFives } = this.props;
    const liked = _.find(post.liker || [], item => item._id === userId);

    return (
      <Wrapper>
        <ContentWrapper>
          <HeaderWrapper initTopHeader={initTopHeader} ref={this.noScrollRef}>
            {this.renderPostInfo()}
            {this.renderLikeSummary()}
            <Interactions
              like={!!liked}
              onLikeClick={() => likePost(post._id, 'detail')}
              onCommentClick={() => {
                this.inputRef.current.focus();
              }}
              comments={total}
            />
            {highFives.length ? (
              <HighFiveMessage>
                {this.renderHighFivesName(highFives)}
                {highFives.length === 1 ? ' gave a high' : ' gave high'} {pluralize('five', highFives.length)} 🙌
              </HighFiveMessage>
            ) : null}
          </HeaderWrapper>
          <Content ref={this.bodyRef} initHeightCommentList={initHeightCommentList}>
            <Comments>
              {_.map(list, item => (
                <CommentItem key={item._id} comment={item} />
              ))}
            </Comments>
          </Content>
        </ContentWrapper>
        <InputContainer className="post__comment__input-container">
          {!isHighFive && (
            <HighFiveWrapper
              data-for="high-five"
              data-tip="Send a high five"
              isDisabled={isLoadingHighFive}
              onClick={() => this.handleHighFive(_.get(post, '_id', ''))}
            >
              <img src={HighFive} width={18} alt="" />
              <ReactTooltip id="high-five" place="top" effect="solid" className="app-tooltip" />
            </HighFiveWrapper>
          )}
          <Input onPost={this.addComment} ref={this.inputRef} />
        </InputContainer>
      </Wrapper>
    );
  }
}

export default Component;
