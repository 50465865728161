import _ from 'lodash';
import { Types } from './actions';

const INITITAL_STATE = {
  messages: [],
  copying: null,
};

export default (state = INITITAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.AUTOFLOW_SUCCESS_GET_AUTO_MESSAGES:
      return Object.assign({}, state, { messages: payload.data });

    case Types.AUTOFLOW_SUCCESS_ADD_AUTO_MESSAGE:
    case Types.AUTOFLOW_SUCCESS_PASTE_AUTO_MESSAGE:
    case Types.AUTOFLOW_SUCCESS_EDIT_THEN_PASTE_AUTO_MESSAGE:
    case Types.AUTOFLOW_SUCCESS_ARRANGE_AUTO_MESSAGE:
      return Object.assign({}, state, { messages: _.unionBy(payload.data, state.messages, 'day') });
    case Types.AUTOFLOW_SUCCESS_UPDATE_AUTO_MESSAGE: {
      return Object.assign({}, state, { messages: _.unionBy(payload.data, state.messages, 'day') });
    }

    case Types.AUTOFLOW_COPY_AUTO_MESSAGE:
      return Object.assign({}, state, { copying: payload.data });

    case Types.AUTOFLOW_AUTO_MESSAGE_CLEAR_COPY_ITEM:
      return Object.assign({}, state, { copying: null });

    case Types.AUTOFLOW_SUCCESS_DELETE_AUTO_MESSAGE:
      return Object.assign({}, state, {
        messages: _.map(state.messages, item => {
          if (item.day === payload.day) {
            return { ...item, messages: _.filter(item.messages, m => m._id !== payload.id) };
          } else {
            return item;
          }
        }),
        copying: state.copying && state.copying._id === payload.id ? null : state.copying,
      });

    case Types.AUTOFLOW_SUCCESS_MOVE_AUTO_MESSAGE:
      return Object.assign({}, state, { messages: _.unionBy(payload.data, state.messages, 'day') });

    case Types.AUTOFLOW_AUTO_MESSAGE_RESET_DATA:
      return INITITAL_STATE;

    default:
      return state;
  }
};
