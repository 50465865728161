import React from 'react';
import ContentLoader from 'react-content-loader';
import { LoadingItemStyles } from './style';

const LoadingSize = new Array(8).fill(null);

const LoadingIndicator = props => {
  return (
    <>
      {LoadingSize.map((item, index) => (
        <LoadingItemStyles>
          <ContentLoader viewBox="0 0 323 40" {...props} key={`loading-item-${index}`}>
            <rect x="50" y="7" width="125" height="6" rx="3"></rect>
            <rect x="50" y="26" width="70" height="6" rx="3"></rect>
            <rect x="289" y="8" width="20" height="20" rx="4.4"></rect>
            <circle cx="24" cy="20" r="18"></circle>
          </ContentLoader>
        </LoadingItemStyles>
      ))}
    </>
  );
};

export default LoadingIndicator;
