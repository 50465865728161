'use strict'

import type React, { Element } from 'react';
type State = {
  successPopup: ?Element<any>,
  isShowingSuccess: boolean
};

type Action = {
  type: string,
  payload: any,
};

export const DEFAULT_STATE = {
  successPopup: null,
  isShowingSuccess: false
};

const ModalReducer = (state: State = DEFAULT_STATE, action: Action) => {
  switch (action.type) {
    case 'SHOW_SUCCESS':
      return Object.assign({}, state, {
        successPopup: action.payload.modal,
        isShowingSuccess: true
      });
    case 'HIDE_SUCCESS':
      return Object.assign({}, state, {
        successPopup: null,
        isShowingSuccess: false
      });
    default:
      return state;
  }
};

export default ModalReducer;
