import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;

  &.view-by-wrap {
    .options {
      width: 156px;

      > div {
        padding: 0px 10px;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        line-height: normal;
      }
    }

    .checked-icon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
      margin-top: 0px;
    }

    .dropdown-wrapper svg {
      margin-top: 0px !important;
    }
  }
`;

export const SortLabel = styled.div`
  color: #7b7e91;
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
`;

const activePopup = css`
  background: #f0f1ff !important;
  color: #5158cf !important;

  .arrow-down-icon {
    path {
      stroke: #5158cf !important;
    }
  }
`;

export const Trigger = styled.div`
  max-height: 30px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #202353;
  white-space: nowrap;
  &.disabled {
    cursor: not-allowed !important;
  }

  :hover {
    ${activePopup}
  }

  ${props => props.active && activePopup}

  .arrow-down-icon {
    transform: rotate(180deg);
    width: 10px;
    height: 10px;
    margin-left: 7px;
    margin-right: -1px;
    min-width: 10px;
  }
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 165px;
  position: absolute;
  top: 38px;
  right: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  font-size: 13px;
  font-weight: 400;
`;

export const Option = styled.div`
  padding: 11px;
  cursor: pointer;
  color: ${props => (props.active ? '#5158cf' : '#202353')};
  font-weight: ${props => (props.active ? '600' : '400')};
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  :hover {
    color: #5158cf;
    font-weight: ${props => (props.active ? '600' : '400')};
    border-radius: 5px;
    background: #f0f1ff;
  }

  .checked-icon {
    margin-top: 4px;
    margin-right: 4px;
    path {
      fill: #5158cf;
    }
  }

  .sort-item-label {
    white-space: nowrap;
  }
`;
