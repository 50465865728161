import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
`;

export const WrapperCommentModal = styled(Modal)`
  &.ui.modal {
    width: 550px;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding: 0;
  }
  .close-button {
    right: -10px;
    top: -10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const CommentConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 447px !important;

    .confirm-content-header {
      padding: 30px 0 0 30px !important;
    }

    .confirm-content-body {
      padding: 15px 30px 40px 30px !important;
    }
    .confirm-actions {
      .confirm-yes-button {
        ${baseText};
      }

      .confirm-no-button {
        ${baseText};
      }
    }
  }
`;
