import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import * as S from './style';
import { push } from 'connected-react-router';
import { getProgramLibrary } from 'redux/program_library/program_library.actionCreators';
import { addFromProgramLibrary } from 'redux/studio-program/actions';
import ProgramItem from './ProgramItem';
import { Button } from 'shared/FormControl';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import { CDN_URL, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { generateParams, generateShareStatus } from 'shared/AssetsShareSetting/constants';

class CopyFromProgramLibrary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      textSearch: '',
      author: props.user._id,
      share: TEAM_SHARE_PRIVATE,
    };
  }

  componentDidMount() {
    this.props.getProgramLibrary();
    window.addEventListener('popstate', this.handleBackButtonToggleModal);
  }

  handleBackButtonToggleModal = () => {
    if (this.props.isModalOpen) {
      this.handleClose();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButtonToggleModal, false);
  }

  handleSearch = e => {
    const { programs } = this.props;
    const textSearch = e.target.value;
    const regex = new RegExp(textSearch, 'i');
    const searchResults = programs.filter(program => {
      return regex.test(program.title);
    });
    this.setState({ textSearch, searchResults });
  };

  onSelectProgram = program => {
    this.setState({ selectedProgram: program._id });
  };

  onClearSearch = () => {
    this.setState({
      textSearch: '',
      searchResults: [],
    });
  };

  handleClose = () => {
    this.props.toggleModal(false);
  };

  onSubmit = () => {
    const { user } = this.props;
    const { author, share, selectedProgram } = this.state;
    this.setState({ isSubmitting: true });

    const paramsShare = generateParams({
      ownership: author,
      shareStatus: share,
    });

    const params = {
      programId: selectedProgram,
      owner: paramsShare.owner,
      share: paramsShare.share,
    };
    this.props
      .addFromProgramLibrary(params)
      .then(response => {
        this.handleClose();
        const { data } = response.data;
        const hasPermission = user._id === data.author || data.share !== TEAM_SHARE_PRIVATE;
        if (hasPermission) {
          this.props.push(`/home/studio-programs/${data._id}`);
        }
      })
      .finally(() => {
        this.setState({ isSubmitting: true });
      });
  };

  handleChangeOwnership = (option = {}) => {
    const ownershipValue = option.value || this.authorId;
    const shareValue = generateShareStatus({
      ownership: ownershipValue,
      shareStatus: this.state.share,
    });

    this.setState({
      ownership: ownershipValue,
      share: shareValue,
    });
  };

  handleChangeShareStatus = (option = {}) => {
    this.setState({
      share: option.value || TEAM_SHARE_PRIVATE,
    });
  };

  handleChangeOwnershipStatus = ({ author, share }) => {
    this.setState({ author, share });
  };

  render() {
    const { programs, user } = this.props;
    const { textSearch, searchResults, selectedProgram, isSubmitting, share } = this.state;
    const items = textSearch ? searchResults : programs;
    return (
      <S.CopyFromProgramLibraryModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <Modal.Header>
          <div className="header__title">Copy from Program Library</div>
          <S.SearchProgramInput
            value={textSearch}
            onChange={this.handleSearch}
            onClearSearch={this.onClearSearch}
            placeholder="Search program name"
          />
          <OwnershipPopupStudio
            size={28}
            user={user}
            owner={user}
            shareStatus={share}
            saveChangeOnClose={this.handleChangeOwnershipStatus}
            updateImmediately={true}
          />
        </Modal.Header>
        <Modal.Content>
          <div className="programResult__title">Programs ({items.length})</div>
          <S.ProgramResultContainer>
            {!items.length && <div className="programResult__noResult">No results</div>}
            {items.map(item => (
              <ProgramItem
                key={item._id}
                program={item}
                onSelect={this.onSelectProgram}
                active={selectedProgram === item._id}
              />
            ))}
          </S.ProgramResultContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button className="actions__cancelBtn" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            className="actions__createBtn"
            disabled={!selectedProgram || isSubmitting}
            purple
            onClick={this.onSubmit}
          >
            Create Studio Program
          </Button>
        </Modal.Actions>
      </S.CopyFromProgramLibraryModal>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    programs: state.rootReducer.program_library.all_items || [],
    isModalOpen: state.isModalOpen,
  };
};

const actionCreators = { toggleModal, push, getProgramLibrary, addFromProgramLibrary };

export default connect(mapStateToProps, actionCreators)(CopyFromProgramLibrary);
