import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .dot {
    width: 6px;
    height: 6px;
    background-color: #5158cf;
    border-radius: 50%;
  }

  &.waiting_to_start {
    .dot {
      background-color: #b4b8cb;
    }
  }
  &.paused {
    .dot {
      background-color: #ffc42b;
    }
  }
  &.active {
    .dot {
      background-color: #38c774;
    }
  }
  &.completed {
    .dot {
      background-color: #5158cf;
    }
  }

  .status-name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    margin-left: 5px;
  }
`;

const statusObject = {
  0: { key: 'waiting_to_start', label: 'Waiting to start' },
  1: { key: 'paused', label: 'Paused' },
  2: { key: 'active', label: 'Active' },
  3: { key: 'completed', label: 'Completed' },
  [-1]: { key: 'deactive', label: 'Deactive' },
};

const StatusColumn = props => {
  const { status } = props;
  return (
    <Wrapper className={statusObject[status].key}>
      <div className="dot" />
      <div className="status-name">{statusObject[status].label}</div>
    </Wrapper>
  );
};

export default StatusColumn;
