import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';

import { ONBOARDING_STEPS } from 'constants/commonData';
import { MODES } from 'shared/MetricSetting/constant';

import { ReactComponent as SearchIcon } from 'assets/icons/search_icon_grey.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus_thin.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down_thin.svg';

import { NewSearchInput } from 'shared/SearchInput';
import ClientMetricSetting from 'components/ClientSettings/ClientMetricSetting';
import AddSavedGroupModal from '../AddSavedGroupModal';
import UpgradePathTooltipContent from 'shared/UpgradePath/components/UpgradePathTooltipContent';
import { SharedTooltip } from 'shared/SharedTooltip';

import * as S from './style';

const ToolbarBodyMetrics = props => {
  const {
    addNewMetricGroup,
    showGuide,
    runOnboarding,
    toggleModal,
    handleRefreshBodyMetric,
    push,
    match,
    permission,
    searchGroupMetricsByName,
  } = props;
  const hasPermission = (permission || {}).custom_metrics;

  const [openSearch, setOpenSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (!openSearch) searchGroupMetricsByName('');
  }, [openSearch]);

  const handleOpenSearch = () => {
    setOpenSearch(it => !it);
  };

  const handleSearch = (__, { value }) => {
    const trimmed = value.toLowerCase().trim();
    searchGroupMetricsByName(trimmed);
    setTextSearch(trimmed);
  };

  const searchDebounce = debounce(handleSearch, 300);

  const handleClearSearch = () => {
    // TODO: clear search
    setOpenSearch(true);
    setTextSearch('');
    searchGroupMetricsByName('');
  };

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      // TODO: action search
      searchGroupMetricsByName(textSearch);
    }
  };

  const handleTogglePopup = () => {
    setOpenPopup(it => !it);
  };

  const handleSearchBlur = (event = {}) => {
    const value = (event.target || {}).value || '';
    if (!value.trim()) {
      setOpenSearch(false);
      setTextSearch('');
      searchGroupMetricsByName('');
    }
  };

  const handleCreateNewGroup = () => {
    typeof addNewMetricGroup === 'function' && addNewMetricGroup(match.params.clientId);
    push(`/home/client/${match.params.clientId}/metrics`);
    setOpenPopup(false);
    setTimeout(() => {
      const element = document.getElementById('group-body-metric-wrapper');
      element && element.scrollTo({ top: element.scrollHeight });
    }, 0);
  };

  const handleAddNewMetric = () => {
    if (showGuide) {
      runOnboarding(ONBOARDING_STEPS.CUSTOMIZE_METRIC_STEP_2);
    }

    toggleModal(
      true,
      <ClientMetricSetting
        handleRefreshBodyMetric={handleRefreshBodyMetric}
        modeProp={MODES.ADD}
        isFromToolbar={true}
      />,
    );
  };

  const handleAddSavedGroup = () => {
    toggleModal(true, <AddSavedGroupModal toggleModal={toggleModal} />);
  };

  return (
    <S.BodyMetricsWrapper>
      <div className="left-side">
        {openSearch ? (
          <NewSearchInput
            autoFocus
            focusAfterClear
            placeholder="Search metric or group"
            onChange={searchDebounce}
            onClearSearch={handleClearSearch}
            onKeyPress={onKeyPress}
            onBlur={handleSearchBlur}
          />
        ) : (
          <div className="label">Body Metrics</div>
        )}
      </div>
      <div className="right-side">
        {!openSearch && (
          <S.SearchIconButton onClick={handleOpenSearch} data-tip data-for="metrics-search-tooltip">
            <SearchIcon className="icon" />
            <SharedTooltip content="Search" position="bottom" id="metrics-search-tooltip" />
          </S.SearchIconButton>
        )}
        <S.AddNewMetricPopup
          trigger={
            <S.AddNewMetricTrigger className={classNames({ active: openPopup })}>
              <PlusIcon className="icon-plus" />
              <span className="label">Add New</span>
              <ArrowDownIcon className="icon-down" />
            </S.AddNewMetricTrigger>
          }
          flowing={true}
          hoverable={true}
          on="click"
          position="bottom right"
          open={openPopup}
          onOpen={handleTogglePopup}
          onClose={handleTogglePopup}
          className="add-new-action-popup"
          keepInViewPort={true}
          basic={true}
        >
          <RootCloseWrapper event="click" disabled={!openPopup} onClick={handleTogglePopup}>
            <S.PopupAddNewContent hasPermission={hasPermission}>
              <S.PopupAddNewItem
                onClick={hasPermission ? handleAddNewMetric : undefined}
                className="add-new-metric-label"
              >
                <S.PopupAddNewLabel>
                  Add New Metric
                  {hasPermission ? null : (
                    <UpgradePathTooltipContent
                      className="features__tooltipUpgradePath"
                      id="feat_custom-metrics"
                      heading="Add your own metrics"
                      description="Create custom metrics to track anything you want for your clients"
                      btnFullWidth
                      onClickUpgrade={() => {
                        toggleModal(false);
                      }}
                    />
                  )}
                </S.PopupAddNewLabel>
              </S.PopupAddNewItem>
              <S.PopupAddNewItem onClick={handleCreateNewGroup}>
                <S.PopupAddNewLabel>Add New Group</S.PopupAddNewLabel>
              </S.PopupAddNewItem>
              <S.PopupAddNewItem onClick={handleAddSavedGroup}>
                <S.PopupAddNewLabel>Add Saved Group</S.PopupAddNewLabel>
              </S.PopupAddNewItem>
            </S.PopupAddNewContent>
          </RootCloseWrapper>
        </S.AddNewMetricPopup>
      </div>
    </S.BodyMetricsWrapper>
  );
};

export default ToolbarBodyMetrics;
