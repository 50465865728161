import React, { useState, useEffect } from 'react';
import ListExercises from 'shared/ListExercises';
import { ReactComponent as AddMoreListExercise } from 'assets/icons/add_more.svg';
import classnames from 'classnames';
import _ from 'lodash';
import * as S from '../style';

function ExerciseList(props) {
  const {
    sectionId,
    exerciseReferences,
    originalExerciseReferences,
    onSave,
    onCancel,
    removeAllExerciseState,
    setRemoveExerciseState,
    onUpdatePrefrenceExercise,
  } = props;
  const [openList, setOpenList] = useState(exerciseReferences.length > 0 ? true : false);

  function handleRemoveAll() {
    onCancel();
    setTimeout(() => {
      setOpenList(!openList);
    }, 200);
  }

  return (
    <S.ListExercise>
      <S.Header>
        <S.AddListExercistButton onClick={() => !openList && setOpenList(!openList)}>
          {!openList && <AddMoreListExercise />}

          <S.Text className={classnames({ black: openList })}>
            <S.SemiBoldText>List {exerciseReferences.length > 0 ? 'of' : ''} Exercises </S.SemiBoldText>
            {exerciseReferences.length > 0 ? `(for reference)` : `(optional)`}
          </S.Text>
        </S.AddListExercistButton>

        {openList && (
          <S.Text cursor>
            <S.SemiBoldText onClick={handleRemoveAll}>Remove All</S.SemiBoldText>
          </S.Text>
        )}
      </S.Header>

      {openList && (
        <ListExercises
          sectionId={sectionId}
          placeholder={'Choose Exercise to add'}
          exerciseReferences={exerciseReferences}
          originalExerciseReferences={originalExerciseReferences}
          onSave={onSave}
          removeAllExerciseState={removeAllExerciseState}
          setRemoveExerciseState={setRemoveExerciseState}
          onUpdatePrefrenceExercise={onUpdatePrefrenceExercise}
        />
      )}
    </S.ListExercise>
  );
}

export default ExerciseList;
