import React from 'react';

import { CDN_URL } from 'constants/commonData';

import * as S from '../styles';
import AddNewProgramButton from './AddNewProgramButton';

const ResultNotFound = () => {
  return (
    <S.EmptyWrapper>
      <img src={`${CDN_URL}/images/program_library_empty.svg`} alt="" />
      <AddNewProgramButton label="Add Your First Program" className="add-first-program-button" />
    </S.EmptyWrapper>
  );
};

export default ResultNotFound;
