import { fromJS } from 'immutable';
import { get, orderBy, map } from 'lodash';
import { Types } from './actions';
import {
  PACKAGE_ANALYTICS_VIEW_TYPE,
  PACKAGE_ANALYTICS_SORT_BY,
  PURCHASE_STATUS,
  SORT_ENUM,
} from 'constants/commonData';

const INITIAL_STATE = fromJS({
  purchases: [],
  invoices: [],
  total: 0,
  viewType: PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE,
  loading: true,
  counter: {},
  query: {
    page: 1,
    pageSize: 50,
    sort: PACKAGE_ANALYTICS_SORT_BY.CUSTOMER_NAME,
    order: SORT_ENUM.ASCENDING,
    q: '',
    status: PURCHASE_STATUS.ACTIVE,
  },
  statistics: {},
  clientPurchases: {
    purchases: [],
    invoicesOfPackage: [],
    totalPaid: 0,
  },
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.PACKAGE_ANALYTICS_CHANGE_QUERY_PARAMS:
      return state.set('query', fromJS(payload.query)).set('viewType', payload.viewType).set('loading', true);

    case Types.PACKAGE_ANALYTICS_GET_PURCHASE_LIST_SUCCESSFULLY:
      return state.set('purchases', fromJS(payload.list)).set('total', payload.total).set('loading', false);

    case Types.PACKAGE_ANALYTICS_GET_INVOICE_LIST_SUCCESSFULLY:
      return state.set('invoices', fromJS(payload.list)).set('total', payload.total).set('loading', false);

    case Types.PACKAGE_ANALYTICS_COUNT_PURCHASES:
      return state.set('counter', fromJS(payload));

    case Types.PACKAGE_ANALYTICS_RESET_DATA:
      return INITIAL_STATE;

    case Types.PACKAGE_ANALYTICS_GET_STATISTICS_SUCCESSFULLY:
      return state.set('statistics', fromJS(payload));

    case Types.PACKAGE_ANALYTICS_GET_PURCHASES_OF_CLIENT_SUCCESSFULLY: {
      const purchases = get(payload, 'data', []);
      return state.setIn(['clientPurchases', 'purchases'], fromJS(orderBy(purchases, ['created_at'], ['desc'])));
    }

    case Types.PACKAGE_ANALYTICS_GET_INVOICES_PACKAGE_CLIENT_SUCCESSFULLY: {
      const invoices = get(payload, 'list', []);
      const totalPaid = get(payload, 'total_paid', 0);
      return state.mergeIn(['clientPurchases'], {
        invoicesOfPackage: fromJS(invoices),
        totalPaid: fromJS(totalPaid),
      });
    }

    case Types.PACKAGE_ANALYTICS_REFUND_INVOICE_SUCCESSFULLY: {
      let invoicesOfPackage = get(state.toJS(), 'clientPurchases.invoicesOfPackage', []);
      let invoiceList = get(state.toJS(), 'invoices', []);

      // Update On List
      map(invoiceList, (item, index) => {
        if (item.id === get(payload, 'invoice_id', '')) {
          invoiceList[index] = {
            ...invoiceList[index],
            status: get(payload, 'status', ''),
          };
        }
      });

      // Update On Detail
      map(
        invoicesOfPackage,
        (item, index) =>
          item.id === payload.invoice_id &&
          (invoicesOfPackage[index] = {
            ...invoicesOfPackage[index],
            status: get(payload, 'status', ''),
            total_refund: get(payload, 'stripe_refund_data.amount', 0),
          }),
      );

      return state
        .mergeIn(['clientPurchases'], {
          invoicesOfPackage: fromJS(invoicesOfPackage),
        })
        .mergeIn(['invoices'], invoiceList);
    }

    case Types.PACKAGE_ANALYTICS_GET_STATISTICS_REQUEST: {
      return state.set('statistics', fromJS({}));
    }

    case Types.PACKAGE_ANALYTICS_PAUSE_RECURRING_SUCCESSFULLY:
    case Types.PACKAGE_ANALYTICS_RESUME_RECURRING_SUCCESSFULLY: {
      const currentPurchases = state.get('purchases').toJS();
      const currentClientPurchases = state.get('clientPurchases').toJS();
      const { data } = payload;

      if (data) {
        currentPurchases.forEach((purchase, index) => {
          if (purchase.id === data.id) {
            currentPurchases[index] = {
              ...purchase,
              ...data,
            };
          }
        });
        const { purchases } = currentClientPurchases;

        purchases.forEach((purchase, index) => {
          if (purchase.id === data.id) {
            purchases[index] = {
              ...purchase,
              ...data,
            };
          }
        });
        currentClientPurchases.purchases = purchases;
      }

      return state
        .mergeIn(['purchases'], fromJS(currentPurchases))
        .mergeIn(['clientPurchases'], fromJS(currentClientPurchases));
    }

    default:
      return state;
  }
};
