import styled from 'styled-components';
import RegularImage from 'assets/icons/section_format_regular.svg';
import RegularImageHover from 'assets/icons/section_format_regular_hover.svg';
import IntervalImage from 'assets/icons/section_format_interval.svg';
import IntervalImageHover from 'assets/icons/section_format_interval_hover.svg';
import AmrapImage from 'assets/icons/section_format_amrap.svg';
import AmrapImageHover from 'assets/icons/section_format_amrap_hover.svg';
import TimedImage from 'assets/icons/section_format_timed.svg';
import TimedImageHover from 'assets/icons/section_format_timed_hover.svg';
import FreeStyleImage from 'assets/icons/section_format_freestyle.svg';
import FreeStyleImageHover from 'assets/icons/section_format_freestyle_hover.svg';

export const Wrapper = styled.div``;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .add-section-form__cancel {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    cursor: pointer;
  }
`;

export const FormatItemWrapper = styled.div`
  padding: 11px 4px 15px 91px;
  background-repeat: no-repeat;
  background-position: 9px 14px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    border: 1px solid #5158cf;
  }

  &.regular {
    background-image: url(${RegularImage});

    :hover {
      background-image: url(${RegularImageHover});
    }
  }

  &.interval {
    background-image: url(${IntervalImage});

    :hover {
      background-image: url(${IntervalImageHover});
    }
  }

  &.amrap {
    background-image: url(${AmrapImage});

    :hover {
      background-image: url(${AmrapImageHover});
    }
  }

  &.timed {
    background-image: url(${TimedImage});

    :hover {
      background-image: url(${TimedImageHover});
    }
  }

  &.freestyle {
    position: relative;
    background-image: url(${FreeStyleImage});

    // In case New Section type is applied
    // ::before {
    //   content: 'NEW';
    //   width: 46px;
    //   height: 17px;
    //   border-top-right-radius: 5.21113px;
    //   border-bottom-left-radius: 5.21113px;
    //   text-align: center;
    //   background: #fff5d0;
    //   font-weight: bold;
    //   font-size: 9px;
    //   line-height: 100%;
    //   color: #ffc445;
    //   position: absolute;
    //   right: 0px;
    //   top: 0px;
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   justify-content: center;
    // }

    :hover {
      background-image: url(${FreeStyleImageHover});
    }
  }

  .format-item__name {
    margin-top: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    color: #202353;
  }

  .format-item__description {
    margin-top: 5px;
    font-weight: normal;
    font-size: 11px;
    line-height: 150%;
    /* or 16px */
    letter-spacing: -0.01em;

    color: #202353;
  }
`;
