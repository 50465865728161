import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/lib/connect/connect';

import * as S from './style';
import WeekCalendar from './WeekCalendar';
import BaseCalendar from './BaseCalendar';
import { updateWorkoutSchedule } from 'redux/workout-builder/actions';

class CalendarOverview extends React.Component {
  state = {
    isHide: true,
    currentMonth: moment().month(),
    currentDay: moment(),
    currentWeek: 0,
    workoutData: {},
  };

  toggleExpand = () => {
    this.setState({ isHide: !this.state.isHide });
  };

  handleChangeWeek = week => {
    this.setState({ currentWeek: week });

    const { maxWeeks, onExpand } = this.props;
    if (maxWeeks - week < 4) {
      onExpand(false);
    } else if (!this.state.isHide) {
      onExpand(true);
    }
  };

  render() {
    const { isHide, currentWeek } = this.state;
    const { maxWeeks, isWeekCalendar, defaultDay, workoutSchedule } = this.props;
    const isHideExpand = isWeekCalendar && maxWeeks - currentWeek < 4;
    return (
      <S.Wrapper className={classnames({ hide: isHide })}>
        {isWeekCalendar ? (
          <WeekCalendar
            {...this.props}
            isHide={isHide}
            defaultDay={defaultDay}
            onChangeWeek={this.handleChangeWeek}
            workoutData={workoutSchedule.toJS()}
          />
        ) : (
          <BaseCalendar {...this.props} isHide={isHide} defaultDay={defaultDay} workoutData={workoutSchedule.toJS()} />
        )}
        <S.HideContainer>{isHideExpand ? null : <S.HideIcon onClick={this.toggleExpand} />}</S.HideContainer>
      </S.Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchWorkoutsInRange: ownProps.fetchWorkoutsInRange
      ? bindActionCreators(ownProps.fetchWorkoutsInRange, dispatch)
      : null,
    fetchWorkoutsByWeek: ownProps.fetchWorkoutsByWeek
      ? bindActionCreators(ownProps.fetchWorkoutsByWeek, dispatch)
      : null,
    updateWorkoutSchedule: bindActionCreators(updateWorkoutSchedule, dispatch),
  };
};

const mapStateToProps = state => {
  return {
    workoutSchedule: state.rootReducer.workoutBuilder.getIn(['workoutSchedule']),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarOverview);
