// Libs
import _, { cloneDeep, find, get, toLower } from 'lodash';
import { useEffect } from 'react';
import moment from 'moment';
import { isSafari as isSafariLib } from 'react-device-detect';
import { datadogLogs } from '@datadog/browser-logs';
import { DateTime } from 'luxon';
import heic2any from 'heic2any';
import { replace } from 'connected-react-router';

import { Mixpanel } from './mixplanel';
import { axiosInstance } from 'configs/request';
import { isVideoFile, isMp3File, isDocumentFile, validateYouTubeUrl, isS3FileURL } from './validations';
import {
  CLIENT_OWNER_SHIP,
  NOTIFICATION_AVATAR,
  CURRENCIES,
  TEXT_CURRENCIES,
  NOTIFICATION_SUB_ACTIONS,
  TOPICS,
  NOTIFICATION_ACTIONS,
  PAYMENT_NOTIFICATIONS_TYPES,
  NOTIFICATION_TYPES,
  METRIC_VALUE_LIMIT,
  HEIC_IMAGE_TYPES,
  CDN_URL,
} from 'constants/commonData';

import {
  DEFAULT_CLIENT_FILTERS,
  HIDE_BODY_METRICS,
  USER_ROLE,
  HIDDEN_SECTION,
  CONVERSION,
  COOKIE_EXPRIRED_DAYS,
  SECTION_FORMAT_KEY,
  AUTOFLOW_TYPES,
  GERMANY_SWITZERLAND,
  DOCUMENT_MINETYPE,
  TRAFFIC_TRACKING_CASES,
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_PAYMENT_STATES,
} from 'constants/commonData';
import { logout } from 'actions/auth/login';
import { axiosInstanceWithoutToken } from 'configs/request';
import { AUTOFILL_ITEMS } from 'components/InAppMessage/constants';
import { AUTOFILL_ONBOARDINGFLOW_ITEMS } from 'shared/AssignOnboardingMessage/components/constants';

export const referralAmount = () => {
  const now = moment();
  const date = moment('2022-04-01');
  if (now.isBefore(date)) {
    return 30;
  }
  return 20;
};

export function getQueryParamsFromObject(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}

export function CleanEmptyStringFromObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === '' || obj[propName] === undefined || obj[propName] === null) {
      delete obj[propName];
    }
  }
  return obj;
}

export function timeSince(date, min = 'second') {
  const seconds = Math.abs(Math.floor((new Date() - date) / 1000));

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + 'y';
  }
  interval = Math.floor(seconds / 604800);
  if (interval >= 1) {
    return interval + 'w';
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + 'd';
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + 'h';
  }

  interval = Math.floor(seconds / 60);

  if (interval >= 1) {
    return interval + 'm';
  } else if (min === 'minute') {
    return '1m';
  }

  return Math.floor(seconds) + 's';
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, match[2], ' ', match[3], ' ', match[4]].join('');
  }

  return phoneNumberString;
}

export function getAccessToken() {
  if (localStorage.getItem('access-token')) {
    return localStorage.getItem('access-token');
  }
  return sessionStorage.getItem('access-token');
}

export function getRefreshToken() {
  if (localStorage.getItem('refresh_token')) {
    return localStorage.getItem('refresh_token');
  }

  return sessionStorage.getItem('refresh_token');
}

export const setCookie = (cname, cvalue, exdays) => {
  let time = exdays * 24 * 60 * 60 * 1000;
  if (process.env.REACT_APP_LIMIT_REFERRAL_COOKIE_ENABLED) {
    time = 10 * 60 * 1000;
  }
  const d = new Date();
  d.setTime(d.getTime() + time);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const refreshCookie = cname => {
  const cookie = getCookie(cname);
  if (cookie) {
    setCookie(cname, cookie, COOKIE_EXPRIRED_DAYS);
  }
};

export const saveToken = (data, remember) => {
  const { token, refresh_token } = data;

  if (remember) {
    localStorage.setItem('access-token', token);
    localStorage.setItem('refresh_token', refresh_token);
  } else {
    sessionStorage.setItem('access-token', token);
    sessionStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('last_user_token', JSON.stringify(sessionStorage));
  }
};

export const removeToken = () => {
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh_token');
  sessionStorage.removeItem('access-token');
  sessionStorage.removeItem('refresh_token');
  localStorage.removeItem('last_user_token');
};

export const roundNumberOver1000 = (value, precision = 1) => {
  return value > 1000 ? _.round(value) : roundUp(value, precision);
};

export const roundUp = (num, precision) => {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};

export const roundNumber = (num = 0, precision = 2) => {
  return Number(Number(num).toFixed(precision));
};

export const roundNumberFtnInch = (num = 0, precision = 2) => {
  return roundNumberBodyMetric(num, precision);
};
export const roundNumberBodyMetric = (num = 0, precision = 2) => {
  const multiplier = Math.pow(10, precision);
  return Math.round(num * multiplier) / multiplier;
};

export const convertMinToFromHourMin = value => {
  if (value < 60) return { hour: 0, min: value };

  const hour = Math.floor(value / 60);
  const min = Math.floor(value - hour * 60);
  return { hour, min: Math.round(min) };
};

export const convertMinToFromMinSec = value => {
  let min = Math.floor(value);
  let sec = Math.round((value % 1) * 60);
  if (sec === 60) {
    min++;
    sec = 0;
  }
  return { min, sec: Math.round(sec) };
};

export const formatMinValueToHM = (value, isNewFormat = false, isConvertValue = false) => {
  const valueRounded = roundNumberBodyMetric(value, 1);
  const strMin = isNewFormat ? `${valueRounded} m` : `${valueRounded} min`;

  if (valueRounded < 60 || isConvertValue) return strMin;

  const { hour, min } = convertMinToFromHourMin(valueRounded);
  const strMinAndHour = isNewFormat
    ? `${hour}${min > 0 ? 'h' : ' h'}${min > 0 ? ` ${min}m` : ''}`
    : `${hour} h${min > 0 ? ` ${min} min` : ''}`;

  return strMinAndHour;
};

export const getExtension = url =>
  url
    .split('.')
    .pop()
    .split(/\-|\#|\?/)[0];

const VALID_IMAGE_EXTENTIONS = ['jpg', 'gif', 'bmp', 'png', 'jpeg'];
export const isImage = filename => {
  const ext = getExtension(filename);
  return VALID_IMAGE_EXTENTIONS.includes(ext.toLowerCase());
};

const VALID_VIDEO_EXTENTIONS = ['m4v', 'avi', 'mpg', 'mp4', 'mov'];
export const isVideo = filename => {
  const ext = getExtension(filename);
  return VALID_VIDEO_EXTENTIONS.includes(ext.toLowerCase());
};

export const setTableColumns = (user, tableName, columns) => {
  let allTables = localStorage.getItem('evf_table_columns');

  if (!allTables) {
    allTables = {};
  } else {
    try {
      allTables = JSON.parse(allTables);
    } catch (err) {
      allTables = {};
    }
  }

  allTables[user] = allTables[user] ? allTables[user] : {};
  allTables[user][tableName] = columns;
  localStorage.setItem('evf_table_columns', JSON.stringify(allTables));
};

export const getTableColumns = (user, tableName) => {
  let allTables = localStorage.getItem('evf_table_columns');

  if (!allTables) {
    allTables = {};
  } else {
    try {
      allTables = JSON.parse(allTables);
    } catch (err) {
      allTables = {};
    }
  }

  return _.get(allTables, `${user}.${tableName}`, []);
};

export const getDemoClient = clientList => {
  if (!clientList) {
    return null;
  }

  let demoClient = _.find(clientList, c => c.email.includes('ben@demo'));

  if (demoClient) {
    return demoClient;
  }

  const list = _.orderBy(clientList.slice(), ['first_name'], ['asc']);

  return list[0];
};

export const convertSegmentToFilters = (segment, user) => {
  const {
    client_type,
    client_connection,
    owner_ship,
    groups,
    certain_users,
    last_activity,
    is_archived,
    last_activity_type,
    last_assigned_workout,
    last_assigned_workout_type,
  } = segment;
  let result = {
    client_type,
    is_archived,
    client_connection,
    groups,
    ownerShip: null,
    last_activity,
    last_activity_type,
    last_assigned_workout,
    last_assigned_workout_type,
  };

  if (isNaN(parseInt(result.last_activity))) {
    result.last_activity = DEFAULT_CLIENT_FILTERS.last_activity;
  }

  if (!result.last_activity_type) {
    result.last_activity_type = DEFAULT_CLIENT_FILTERS.last_activity_type;
  }

  if (isNaN(parseInt(result.last_assigned_workout))) {
    result.last_assigned_workout = DEFAULT_CLIENT_FILTERS.last_assigned_workout;
  }

  if (!result.last_assigned_workout_type) {
    result.last_assigned_workout_type = DEFAULT_CLIENT_FILTERS.last_assigned_workout_type;
  }

  if (owner_ship === 1) {
    if (segment.trainer && segment.trainer._id !== user._id) {
      result.ownerShip = [segment.trainer._id];
    } else {
      result.ownerShip = user._id;
    }
  }

  if (owner_ship === 3) {
    result.ownerShip = certain_users;
  }

  return result;
};

/*
OWNER_SHIP_YOU: 1,
OWNER_SHIP_ALL: 2,
OWNER_SHIP_CERTAIN_USERS: 3, 
*/
export const convertFiltersToSegment = filters => {
  let result = Object.assign({}, filters);
  result.owner_ship = _.isArray(filters.ownerShip) ? 3 : filters.ownerShip === null ? 2 : 1;
  result.certain_users = result.owner_ship === 3 ? filters.ownerShip : [];
  return result;
};

export const convertFiltersToClientParams = filters => {
  let result = Object.assign({}, filters);
  result.owner_ship = _.isArray(filters.ownerShip) ? 3 : filters.ownerShip === null ? 2 : 1;
  result.certain_users = result.owner_ship === 3 ? filters.ownerShip : [];
  delete result.ownerShip;

  if (result.client_connection === undefined || result.client_connection === null) {
    delete result.client_connection;
  }

  if (isNaN(parseInt(result.last_activity))) {
    delete result.last_activity;
    delete result.last_activity_type;
  }

  if (isNaN(parseInt(result.last_assigned_workout))) {
    delete result.last_assigned_workout;
    delete result.last_assigned_workout_type;
  }

  if (!result.client_type) {
    delete result.client_type;
  }

  if (!result.is_archived) {
    delete result.is_archived;
  }

  return result;
};

export const isTeamAdmin = user => !user.teams || user.teams.length === 0 || user.teams[0].role !== USER_ROLE.TRAINER;

export const isOwnerWorkspace = user => {
  return get(user, 'teams[0].role') === USER_ROLE.OWNER;
};

export const isOwnerOrAdminOrSubTrainer = user => {
  const { is_sub_trainer } = user;
  let isAdminOrTrainer = false;

  const hasTeammates = get(user, 'team_member', []).filter(item => item._id !== user._id);

  if ((isTeamAdmin(user) || user.teams[0].role === 0) && hasTeammates.length) {
    isAdminOrTrainer = true;
  }

  return is_sub_trainer || isAdminOrTrainer;
};

export const removeFristLastSlash = theString => theString.replace(/^\/|\/$/g, '');

export const formatBytes = (a, b) => {
  if (a == 0) {
    return '0 Bytes';
  }

  let c = 1024,
    d = b || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
};

export const calculateYAxisRange = (lowest, highest) => {
  const min = lowest - (highest - lowest);
  const max = highest + (highest - lowest) / 2;
  return {
    suggestedMin: min < 0 || min === max ? 0 : min,
    suggestedMax: max === 0 ? 1 : max,
  };
};

export const isDefaultFilters = filters => {
  const {
    client_type,
    client_connection,
    groups,
    ownerShip,
    is_archived,
    last_activity,
    last_assigned_workout,
  } = filters;

  return (
    !client_type &&
    !client_connection &&
    !groups.length &&
    !is_archived &&
    isNaN(parseInt(last_activity)) &&
    isNaN(parseInt(last_assigned_workout)) &&
    typeof ownerShip === 'string'
  );
};

export const formatNumber = num => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const likeSummary = (userId, all) => {
  if (!all.length) {
    return '';
  }

  const others = all.slice();
  let text = '';
  const currentUser = _.remove(others, item => item._id === userId);

  if (!others.length) {
    return 'you';
  }
  if (!currentUser.length) {
    text += `${others[0].first_name} ${others[0].last_name}`;

    if (others.length > 2) {
      text += ` and ${others.length - 1} others`;
    }

    if (others.length === 2) {
      text += ` and ${others[1].first_name} ${others[1].last_name}`;
    }
  } else {
    if (others.length === 1) {
      text += `you and ${others[0].first_name} ${others[0].last_name}`;
    } else {
      text = `you and ${others.length} others`;
    }
  }

  return text;
};

const sortCodes = ['weight', 'body_fat'];

export const getAvailableMetrics = list => {
  const filtered = _.filter(list, item => item.active && !HIDE_BODY_METRICS.includes(item.unique_code));

  filtered.sort((it1, it2) => {
    if (sortCodes.includes(it1.unique_code)) {
      return -1;
    }

    if (sortCodes.includes(it2.unique_code)) {
      return 1;
    }

    return 0;
  });

  return filtered;
};

export const getUTCTime = (date, format = 'HH:mm') => {
  return moment(date).utc().format(format);
};

export function isNumber(value) {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
}

export const getDuration = duration => {
  let time = '00:00';

  if (duration) {
    const roundedDuration = Math.round(Number(duration));
    const arr = [];
    let part = roundedDuration;

    while (part > 0) {
      const remain = part % 60;
      arr.push(remain < 10 ? '0' + remain : remain);
      part = parseInt(part / 60);
    }

    if (arr.length === 1) {
      arr.push('00');
    }

    arr.reverse();
    time = arr.join(':');
  }

  return time;
};

export const getVideoType = type => {
  switch (type) {
    case 'video/quicktime':
      return 'video/mp4';
    default:
      return type;
  }
};

export const parseDataURItoFile = (dataURI, filename) => {
  let byteString;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = decodeURI(dataURI.split(',')[1]);
  }

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const arrayBuffer = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString.charCodeAt(i);
  }

  return new File([arrayBuffer], filename, { type: mimeString });
};

export const inWorkspace = user => _.get(user, 'team_member', []).length > 1;

export const initFirebaseReference = (userId, topic, file) => {
  return `${userId}/${topic}/web_${new Date().getTime()}_${file.name}`;
};

const breakLineReg = new RegExp('<div><br></div>', 'g');
const divOpenReg = new RegExp('<div>', 'g');
const divCloseReg = new RegExp('</div>', 'g');

export const replaceInnerHTMLEditableElement = element => {
  if (!element || !element.innerHTML) {
    return '';
  }

  const innerHTML = element.innerHTML;
  const newHTML = innerHTML.replace(breakLineReg, '<br>').replace(divOpenReg, '<br>').replace(divCloseReg, '');

  return newHTML;
};

export const getUserShortName = userInfo => {
  if (!userInfo) {
    return '';
  }
  const { first_name, last_name, full_name, name } = userInfo;
  if (first_name || last_name) {
    const firstNameFirstLetter = (first_name || '').trim().replace(/\s+/g, ' ').split(' ')[0];
    const lastNameFirstLetter = (last_name || '').trim().replace(/\s+/g, ' ').split(' ')[0];

    return `${firstNameFirstLetter} ${lastNameFirstLetter}`;
  }

  if (full_name || name) {
    const names = (full_name || name || '').trim().replace(/\s+/g, ' ').split(' ');
    return names.length > 1 ? `${names[0]} ${names.pop()}` : names[0];
  }
};

const VIDEO_REGEX = /.(mp4|3gp|ogg|wmv|webm|flv|mov|avi)$/i;
const AUDIO_REGEX = /.(mp3)$/i;

export const getMediaType = file => {
  if (!file) {
    return '';
  }

  if (file.type) {
    return file.type;
  }

  if (file.name.match(VIDEO_REGEX)) {
    return 'video';
  }

  if (file.name.match(AUDIO_REGEX)) {
    return 'audio';
  }

  return 'image';
};

export const generateApiUrl = route => {
  return `${process.env.REACT_APP_API_URL}${route}`;
};

export const getFixedPopupStyles = (rect, popupHeight, margin = 0) => {
  const { height, x, y } = rect;
  const styles = { left: x, top: y + height + margin };
  const windowHeight = window.innerHeight;
  const bottomSpace = windowHeight - (y + height + margin + 10);
  const topSpace = y - margin - 10;

  if (bottomSpace < popupHeight) {
    if (topSpace > bottomSpace) {
      const newMenuHeight = Math.min(topSpace, popupHeight);
      styles.top = y - (newMenuHeight + margin);
      styles.maxHeight = newMenuHeight;
    } else {
      styles.maxHeight = bottomSpace;
    }
  }

  return styles;
};

export const getWorkoutSummary = workout => {
  const sections = _.sumBy(workout.sections, s => (s.type !== HIDDEN_SECTION ? 1 : 0));
  const exercises = _.sumBy(workout.sections, s => {
    return s.format === SECTION_FORMAT_KEY.FREESTYLE
      ? s.exercise_references.length
      : _.sumBy(s.exercises, set => set.supersets.length);
  });

  return { sections, exercises };
};

export const pluralize = (word, count, inclusive) => {
  if (!word) {
    return '';
  }

  const newWord = count === 1 ? word : `${word}s`;

  if (inclusive) {
    return `${count} ${newWord}`;
  }

  return newWord;
};

export const generateVimeoAPIUrl = videoLink => `https://vimeo.com/api/oembed.json?url=${videoLink}&width=1920`;

export const revokeObjectURL = url => {
  const URL = window.URL || window.webkitURL;
  URL.revokeObjectURL(url);
};

export const revokeMultipleObjectURL = list => {
  const URL = window.URL || window.webkitURL;
  _.forEach(list, url => {
    URL.revokeObjectURL(url);
  });
};

export const createObjectURL = file => {
  const URL = window.URL || window.webkitURL;

  return URL.createObjectURL(file);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const escapeRegExp = string => {
  return _.isString(string) ? string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') : '';
};

export const displayFileSize = sizeAsByte => {
  const sizeAsKB = Math.round(sizeAsByte / CONVERSION.MB_TO_KB);
  return sizeAsKB < CONVERSION.MB_TO_KB ? `${sizeAsKB} KB` : `${Math.round(sizeAsByte / CONVERSION.MB_TO_BYTE)} MB`;
};

export const getPeriodName = period => {
  return period === 'monthly' ? 'month' : 'year';
};

export const checkPermissionAccessAutoflow = teamData => {
  const isFailAddOn = teamData.payment_addons_over_due;
  const isOverAfter5Day =
    teamData.time_addons_over_due && moment().isAfter(moment(teamData.time_addons_over_due * 1000).add(5, 'day'));
  if (isFailAddOn && isOverAfter5Day) {
    return false;
  }
  return true;
};

export const checkPermissionAccessZapier = teamData => {
  if (
    teamData.zapier_meter_addons_overdue &&
    teamData.time_zapier_meter_addons_overdue &&
    moment().isAfter(moment(teamData.time_zapier_meter_addons_overdue * 1000).add(5, 'day'))
  ) {
    return false;
  } else {
    return true;
  }
};

export const generateAWSImageFallback = list => {
  const results = [];

  _.forEach(list, original => {
    if (original) {
      results.push(original);
      const fallback = original.replace('/thumbnails-converted/', '/images/');

      if (fallback !== original) {
        results.push(fallback);
      }
    }
  });

  return results;
};

export const mongoObjectId = function () {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const localSectionId = section => {
  return `${section._id}-${section.type}-${section.format}`;
};

export const generateS3DataToPresigned = (s3URL, expireSeconds = 300) => {
  const urlObj = new URL(s3URL);
  return {
    expireSeconds,
    bucket: urlObj.hostname.split('.')[0],
    fileKey: decodeURIComponent(urlObj.pathname.slice(1)),
    operation: 'getObject',
  };
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const saveLastLibraryRoute = route => {
  localStorage.setItem('last-library-route', route);
};

export const getLastLibraryRoute = route => {
  return localStorage.getItem('last-library-route');
};

export const saveLastAutomationRoute = route => {
  localStorage.setItem('last-automation-route', route);
};

export const getLastAutomationRoute = () => {
  return localStorage.getItem('last-automation-route');
};

export const omitEmptyRequestParams = params => {
  return _.omitBy(params, value => _.isNil(value) || value === '');
};

export const readAsArrayBufferAsync = inputFile => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsArrayBuffer(inputFile);
  });
};

export const getGradient = (ctx, startColor) => {
  let gradient = ctx.createLinearGradient(0, 0, 0, 150);
  gradient.addColorStop(0, startColor);
  gradient.addColorStop(0.3, 'rgba(255, 255, 255, 0)');
  gradient.addColorStop(1, 'white');
  return gradient;
};

const key = 'everfit_hash';

const cipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
  return text => text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

const decipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded =>
    encoded
      .match(/.{1,2}/g)
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
};

export const encryptData = data => {
  return cipher(key)(data);
};

export const decryptData = encrypted => {
  return decipher(key)(encrypted);
};

export const formatSameYear = date => {
  const currentYear = moment().year();
  return moment(date).year() === currentYear ? moment(date).format('MMM D') : moment(date).format('MMM D, YYYY');
};

export const formatDateSafari = date => {
  return typeof date === 'string' ? date.replace(/-/g, '/') : date;
};

export const getResourceType = resource => {
  const type = resource.link_type || resource.document_type;
  switch (type) {
    case 'youtube':
    case 'vimeo':
    case 'instagram':
    case 'twitter':
    case 'spotify':
    case 'facebook':
    case 'document':
      return type;
    case 'pdf':
      return 'document';
    case 'form':
      return 'form';
    default:
      return 'link';
  }
};

export const handleMixpanelTrackingPrintPDF = (pathname, currentComponent) => {
  const modulesArr = [
    {
      key: 'client_calendar',
      path: 'client',
    },
    {
      key: 'autoflow',
      path: 'autoflow',
    },
    {
      key: 'program',
      path: 'program',
    },
    {
      key: 'workout_library',
      path: 'workout',
    },
    {
      key: 'program_studio',
      path: 'studio-program',
    },
    {
      key: 'forms_library',
      path: 'forms',
    },
  ];
  const currentModule = _.find(modulesArr, el => pathname.includes(el.path));
  const mixPanelKey = `${currentModule.key}_${currentComponent}_print_workout`;
  Mixpanel.track(mixPanelKey);
};

const cloneDays = (firstDay, diff, format) => {
  return Array.from({ length: diff }, (_, index) => ({
    day: firstDay.clone().add(index, 'days').format(format),
    value: 0,
  }));
};

const reduceDataByDate = (data, format) => {
  return data.reduce((acc, { day, value }) => {
    acc[moment(day).format(format)] = value;
    return acc;
  }, {});
};

const finalResult = (initData, dataMap) => {
  return initData.map(item => ({
    ...item,
    value: dataMap[item.day] || 0,
  }));
};

export const formatChartData30Days = (data = []) => {
  if (data.length === 0) return [];

  const format = 'MM/DD/YYYY';
  const firstDay = moment().subtract(29, 'days');

  // Initialize data for the last 30 days
  const initData = cloneDays(firstDay, 30, format);

  // Create a lookup map for the input data
  const dataMap = reduceDataByDate(data, format);

  // Map through initial data and assign values from the lookup map
  const results = finalResult(initData, dataMap);

  return results;
};

export const formatChartDataUnlimitedLength = (data = []) => {
  if (data.length === 0) return [];

  const format = 'MM/DD/YYYY';
  const firstDay = moment().subtract(29, 'days');

  // Initialize data for the last 30 days
  const initData = cloneDays(firstDay, 30, format);

  // Create a lookup map from the input data
  const dataMap = reduceDataByDate(data, format);

  // Map through initial data and assign values from the lookup map
  const results = finalResult(initData, dataMap);

  return results;
};

export const formatChartDataByWeek = (data = []) => {
  if (!data.length) return [];

  const format = 'MM/DD/YYYY';
  const startDate = moment(data[0].day);
  const currentDate = moment();
  const diff = currentDate.diff(startDate, 'days');

  // Initialize data for all days between the start and current date
  const initData = cloneDays(startDate, diff, format);

  // Create a lookup map for quick value assignment
  const dataMap = reduceDataByDate(data, format);

  // Populate initial data with values from the data map
  const results = finalResult(initData, dataMap);

  return results;
};

// User In Workout Background
const MAX_STRING_LENG = 40;

export function handleCutString(str) {
  const fileType = str.substring(str.lastIndexOf('.'));

  return str.length > MAX_STRING_LENG ? str.substring(0, MAX_STRING_LENG) + '... ' + fileType : str;
}
export const getMetadataMediaFile = async file => {
  const localUrl = URL.createObjectURL(file);
  let width = null;
  let height = null;
  let metadata = {};
  const isFLV = _.lowerCase(getExtension(file.name)) === 'flv';
  if (isVideoFile(file.type) || isImage(file.name) || isFLV) {
    if (isImage(file.name)) {
      const { width: originalWidth = null, height: originalHeight = null } = await getImageDimensionFromUrl(localUrl);
      width = originalWidth;
      height = originalHeight;
    } else {
      const { width: originalWidth = null, height: originalHeight = null } = await getVideoDimensions(localUrl);
      width = originalWidth;
      height = originalHeight;
    }
    if (width) {
      metadata.width = `${width}`;
    }
    if (height) {
      metadata.height = `${height}`;
    }
  }
  return metadata;
};

// TODO: THIS IS IMPLEMENTED WITH FREESTYLE SECTION IN MIND,
// SHOULD CHECK FOR VIDEO TYPE'S THUMBNAIL AND KEYS IF YOU WANT TO REUSE THIS

export async function getPresignedUploadUrl(genPresignUrl, file, isNewLocation = false) {
  let uploadConfigs = {
    method: 'POST',
    url: genPresignUrl,
    data: { files: [{ name: file.name, mimetype: file.type }] },
  };

  const isFLV = _.lowerCase(getExtension(file.name)) === 'flv';
  if (isVideoFile(file.type) || isImage(file.name) || isFLV) {
    uploadConfigs = {
      ...uploadConfigs,
      data: {
        files: [{ ...uploadConfigs.data.files[0] }],
        // files: [{ ...uploadConfigs.data.files[0], metadata: await getMetadataMediaFile(file) }],
      },
    };
  }

  const response = await axiosInstance(uploadConfigs);
  const uploadUrl = response.data.data[0];
  const key = extractKey(uploadUrl, isVideoFile(file.type));
  const url = extractUrl(uploadUrl, file.type ? isVideoFile(file.type) : isFLV);
  const bucket = extractBucket(uploadUrl);
  const thumbnail_key = extractThumbnailKey(uploadUrl, isVideoFile(file.type), isNewLocation);
  const thumbnail_url = extractThumbnailUrl(uploadUrl, isVideoFile(file.type), isNewLocation);
  const mimetype = file.type;
  const original_name = file.name;
  const size = file.size;
  if (isMp3File(file.type) || isDocumentFile(file.type)) {
    // should not have thumbnail for audio and document file (document file's thumbnail is updated in separate flow)
    return { uploadUrl, configs: { key, url, bucket, mimetype, original_name, size } };
  }
  const attachment = {
    uploadUrl,
    configs: { key, url, bucket, mimetype, original_name, thumbnail_key, thumbnail_url, size },
  };
  return attachment;
}

export async function getPresignedUploadUrlMultiple(genPresignedUrl = '', data = []) {
  let uploadConfigs = {
    method: 'POST',
    url: genPresignedUrl,
    data: {
      files: await Promise.all(
        data.map(item => {
          const name = get(item, 'file.name');
          const type = get(item, 'file.type');
          const isFLV = _.lowerCase(getExtension(name)) === 'flv';
          if (isVideoFile(type) || isImage(name) || isFLV) {
            return {
              name,
              mimetype: type,
              // metadata: await getMetadataMediaFile(item), // TODO - Will apply later
            };
          }
          return { name, mimetype: type };
        }),
      ),
    },
  };

  const response = await axiosInstance(uploadConfigs);
  const uploadUrls = response.data.data;
  let attachments = [];

  data.forEach((item, index) => {
    const { file, objectURL, dimensions } = item;
    const uploadUrl = uploadUrls[index];
    const key = extractKey(uploadUrl, isVideoFile(file.type));
    const url = extractUrl(uploadUrl, isVideoFile(file.type));
    const bucket = extractBucket(uploadUrl);
    const thumbnail_key = extractThumbnailKey(uploadUrl, isVideoFile(file.type));
    const thumbnail_url = extractThumbnailUrl(uploadUrl, isVideoFile(file.type));
    const mimetype = file.type;
    const original_name = file.name;
    const size = file.size;
    if (isMp3File(file.type) || isDocumentFile(file.type)) {
      // should not have thumbnail for audio and document file (document file's thumbnail is updated in separate flow)
      return { uploadUrl, configs: { key, url, bucket, mimetype, original_name, size } };
    }
    attachments.push({
      file,
      objectURL,
      dimensions,
      uploadUrl,
      configs: { key, url, bucket, mimetype, original_name, thumbnail_key, thumbnail_url, size },
    });
  });

  return attachments;
}

export async function getPresignedUploadUrlByParams(file, params) {
  let uploadConfigs = params;
  // - TODO -  Will apply later
  // let metaDatas = [];
  // const isFLV = _.lowerCase(getExtension(file.name)) === 'flv';
  // if (isVideoFile(file.type) || isImage(file.name) || isFLV) {
  //   metaDatas.push(await getMetadataMediaFile(file));
  // }

  // uploadConfigs = {
  //   ...uploadConfigs,
  //   data: {
  //     ...uploadConfigs.data,
  //     metaDatas: metaDatas,
  //   },
  // };

  const response = await axiosInstance(uploadConfigs);
  const uploadUrl = response.data.data[0];
  const key = extractKey(uploadUrl, isVideoFile(file.type));
  const url = extractUrl(uploadUrl, isVideoFile(file.type));
  const bucket = extractBucket(uploadUrl);
  const thumbnail_key = extractThumbnailKey(uploadUrl, isVideoFile(file.type));
  const thumbnail_url = extractThumbnailUrl(uploadUrl, isVideoFile(file.type));
  const mimetype = file.type;
  const original_name = file.name;
  const size = file.size;
  if (isMp3File(file.type) || isDocumentFile(file.type)) {
    // should not have thumbnail for audio and document file (document file's thumbnail is updated in separate flow)
    return { uploadUrl, configs: { key, url, bucket, mimetype, original_name, size } };
  }
  const attachment = {
    uploadUrl,
    configs: { key, url, bucket, mimetype, original_name, thumbnail_key, thumbnail_url, size },
  };
  return attachment;
}

export async function getMultiplePresignedUploadUrlsByParams(files, params) {
  let uploadConfigs = params;

  const response = await axiosInstance(uploadConfigs);
  const uploadArr = response.data.data.map((uploadUrl, index) => {
    const key = extractKey(uploadUrl, isVideoFile(files[index].type));
    const url = extractUrl(uploadUrl, isVideoFile(files[index].type));
    const bucket = extractBucket(uploadUrl);
    const thumbnail_key = extractThumbnailKey(uploadUrl, isVideoFile(files[index].type));
    const thumbnail_url = extractThumbnailUrl(uploadUrl, isVideoFile(files[index].type));
    const mimetype = files[index].type;
    const original_name = files[index].name;
    const size = files[index].size;
    if (isMp3File(files[index].type) || isDocumentFile(files[index].type)) {
      // should not have thumbnail for audio and document file (document file's thumbnail is updated in separate flow)
      return { uploadUrl, configs: { key, url, bucket, mimetype, original_name, size } };
    }
    const attachment = {
      uploadUrl,
      configs: { key, url, bucket, mimetype, original_name, thumbnail_key, thumbnail_url, size },
    };
    return attachment;
  });
  return uploadArr;
}

export function getFormatImageUrlWithSML(url, size = 'l') {
  if (typeof url !== 'string' || !url) return url;
  const sizes = ['s', 'm', 'l'];
  const imageSize = sizes.includes(size) ? size : 'l';
  return url.replace('/original/', `/${imageSize}/`);
}

const extractKey = (uploadUrl, isVideo) => {
  const splitted_url = uploadUrl.split('/');
  const field_key_location = splitted_url[splitted_url.length - 1];
  const folder_name = isVideo ? 'videos-converted' : splitted_url[splitted_url.length - 2];
  const extracted_field_key =
    folder_name + '/' + field_key_location.substring(0, field_key_location.indexOf('?X-Amz-Algorithm')); // extract field key from presigned upload url
  if (isVideo) {
    let splitted_extracted_field_key = extracted_field_key.split('.');
    splitted_extracted_field_key[splitted_extracted_field_key.length - 1] = 'mp4'; // replace any file extension to mp4
    return splitted_extracted_field_key.join('.');
  }
  return extracted_field_key;
};

export const extractUrl = (uploadUrl, isVideo) => {
  let splitted_url = uploadUrl.split('/').splice(0, 3);
  const key = extractKey(uploadUrl, isVideo);
  const url_arr = splitted_url.concat(key);
  let url = '';
  if (url_arr && !!url_arr[1]) {
    url = url_arr.join('/');
  } else {
    const { pathname, origin } = new URL(uploadUrl);
    url = origin + pathname;
  }
  return url;
};

const extractBucket = uploadUrl => {
  const splitted_url = uploadUrl.split('/');
  const bucket = splitted_url[2].split('.')[0];
  return bucket;
};

const extractThumbnailKey = (uploadUrl, isVideo, isNewLocation = false) => {
  const key = extractKey(uploadUrl, isVideo);
  let thumbnail_key_splitted_arr = key.split('/');
  const { pathname } = new URL(uploadUrl);
  const spittedPathName = pathname.split('/');
  thumbnail_key_splitted_arr[0] = isNewLocation
    ? `thumbnails-converted/${spittedPathName[2]}/${spittedPathName[3]}/original`
    : 'thumbnails-converted';
  const thumbnail_key = thumbnail_key_splitted_arr.join('/');
  if (isVideo) {
    let splitted_video_key = thumbnail_key.split('.');
    const video_file_name = splitted_video_key[0] + 'thumbnail.0000000.jpg';
    return video_file_name;
  }
  return thumbnail_key;
};

const extractThumbnailUrl = (uploadUrl, isVideo, isNewLocation = false) => {
  let splitted_url = uploadUrl.split('/').splice(0, 3);
  const thumbnail_key = extractThumbnailKey(uploadUrl, isVideo, isNewLocation);
  const url_arr = splitted_url.concat(thumbnail_key);
  const thumbnail_url = url_arr.join('/');
  return thumbnail_url;
};

export const getDurationVideo = (file, callback) => {
  let video = document.createElement('video');
  video.preload = 'metadata';
  video.onloadedmetadata = function () {
    window.URL.revokeObjectURL(video.src);

    if (video.duration < 1) {
      return;
    }
    const minutes = Math.floor(video.duration / 60);
    const seconds = Math.floor(video.duration - minutes * 60);
    const parsedDuration = `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
    callback(video.duration);
    return;
  };

  video.src = URL.createObjectURL(file);
};

export const parseDurationVideo = (duration, isText) => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration - minutes * 60);
  let parsedDuration;
  if (isText) {
    parsedDuration = `${minutes !== 0 ? `${minutes}m` : ''}${seconds !== 0 ? ` ${seconds}s` : ''}`;
  } else {
    parsedDuration = `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
  }
  return parsedDuration;
};

const getRenderedSections = (currentWorkout, maxRendered) => {
  const sections = currentWorkout.sections || [];
  let total = 0;
  let renderedSections = 0;
  _.forEach(sections, (section, index) => {
    if (total >= maxRendered) {
      return renderedSections;
    } else {
      if (section.type === 'hidden') {
        const exercise = section.exercises ? section.exercises[0] : {};
        _.forEach(exercise.supersets, ex => {
          total++;
        });
      } else {
        total++;
      }
      renderedSections++;
    }
  });
  return renderedSections;
};

const calculatorExercises = (currentWorkout, maxRendered) => {
  const sections = currentWorkout.sections || [];
  const renderedSections = sections.slice(0, getRenderedSections(currentWorkout, maxRendered));
  let total = 0;
  let empty = 0;

  let rendered = 0;
  const notOnlyFree = renderedSections.find(it => it.format !== SECTION_FORMAT_KEY.FREESTYLE);

  // Counter rendered item
  _.forEach(renderedSections, section => {
    if (section.type === 'hidden') {
      const exercise = section.exercises ? section.exercises[0] : {};
      _.forEach(exercise.supersets, ex => {
        if (rendered < maxRendered) {
          rendered++;
        }
      });
    } else {
      if (rendered < maxRendered) {
        if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
          if (!section.exercise_references.length && notOnlyFree) {
            empty++;
            rendered++;
          } else {
            rendered += section.exercise_references.length;
          }
        } else {
          rendered += _.sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
        }
      }
    }
  });

  // Counter all exercises
  _.forEach(sections, section => {
    if (section.type === 'hide') {
      const exercise = section.exercises ? section.exercises[0] : {};
      _.forEach(exercise.supersets, ex => {
        total++;
      });
    } else {
      if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
        total += section.exercise_references.length;
      } else {
        total += _.sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
      }
    }
  });

  return maxRendered !== 100 ? total - rendered + empty : 0;
};

export const counterBehindExercises = (currentWorkout, maxRendered) => {
  return calculatorExercises(currentWorkout, maxRendered);
};

export const checkingValidUrl = (src, isSynced = true) => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open('GET', src, isSynced); // false for synchronous request
  xmlHttp.send(null);
  return xmlHttp.status === 200;
};

export const getStatusCodeUrl = async src => {
  const statusCode = await new Promise(res => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', src, true); // false for synchronous request
    xmlHttp.onreadystatechange = function () {
      res(xmlHttp.status);
    };
    xmlHttp.send(null);
  });
  return statusCode;
};

export const ownerOfForum = (user, forum) => {
  const owner = forum.author;
  return owner === user._id;
};

export const autoflowTypeParse = type => {
  switch (type) {
    case AUTOFLOW_TYPES.EXACT_DATE:
      return 'By Exact date';
    case AUTOFLOW_TYPES.INTERVAL:
      return 'By day sequence';
    default:
      return '';
  }
};

export const mediaLog = async params => {
  let uploadConfigs = {
    method: 'POST',
    url: '/api/general/log-upload-data',
    data: {
      ...window.activeUser,
      ...params,
    },
  };
  await axiosInstance(uploadConfigs);
};

export const checkAssetAssignedToProduct = async (
  assetType,
  assetId,
  isOnboardingFlow = false,
  isOnboardingFlowTrigger = false,
) => {
  let url = '/api/payment/assets/check-assigned-asset';
  let method = 'GET';
  if (isOnboardingFlow) {
    url = '/api/onboarding-flows/check-assigned-asset';
    method = 'POST';
  }
  let configs = {
    method,
    url,
    params: {
      asset_type: assetType,
      asset_id: assetId,
    },
  };
  const res = await axiosInstance(configs);
  const isAssigned = res.data.data.assigned;
  if (isOnboardingFlow && isOnboardingFlowTrigger) {
    return res.data.data;
  }
  return isAssigned;
};

export const hasBeenPurchased = async id => {
  let configs = {
    method: 'GET',
    url: `/api/payment/products/${id}/detail`,
  };
  const res = await axiosInstance(configs);
  const isPurchased = get(res, 'data.data.is_purchased', false);
  return isPurchased;
};

// TODO - Handle enable product
export const handleEnableProduct = user => {
  let isUsingPaymentProduct = false;

  if (user) isUsingPaymentProduct = user.is_using_payment_product;
  const isPublic = process.env.REACT_APP_ENABLE_PRODUCT || isUsingPaymentProduct;
  return isPublic || process.env.REACT_APP_NODE_ENV === 'development';
};

export const enableOnDemand = () => {
  // return process.env.REACT_APP_ENABLE_ON_DEMAND;
  return true;
};

export const enableCoachBio = () => {
  const isPublic = process.env.REACT_APP_ENABLE_COACH_BIO;
  return isPublic;
};

export const getLinkDownloadApp = async params => {
  const configs = {
    method: 'GET',
    url: `/api/general/get-link-redirect-app-white-label`,
    params: {
      numberPrettyLink: params.number,
      type: params.os,
    },
  };
  const res = await axiosInstance(configs);
  return _.get(res, 'data', '');
};

export const processMetricResultValue = (value, metric_code) => {
  const numericValue = roundNumberBodyMetric(value, 2);

  switch (metric_code) {
    case 'body_fat':
      if (numericValue >= 100) {
        return '99.99';
      }
      if (numericValue < 10) {
        return value.substring(0, 4);
      }
      return value.substring(0, 5);
    case 'heart_rate':
      if (numericValue >= 220) {
        return 220;
      }
      return value;

    default:
      return numericValue > METRIC_VALUE_LIMIT ? METRIC_VALUE_LIMIT : value;
  }
};

export const convertUnit = (value, oldUnit, newUnit) => {
  if (!value || !oldUnit || !newUnit) {
    return value;
  }
  if (oldUnit._id !== newUnit._id) {
    if (oldUnit.formula && newUnit.formula) {
      return convertTemperature(value, oldUnit, newUnit);
    }
    return (value * oldUnit.ratio) / newUnit.ratio;
  }
  return value;
};

export const convertTemperature = (value, oldUnit, newUnit) => {
  if (oldUnit.unique_code === 'c_degree' && newUnit.unique_code === 'f_degree') {
    return (value * 9) / 5 + 32;
  } else if (oldUnit.unique_code === 'f_degree' && newUnit.unique_code === 'c_degree') {
    return ((value - 32) * 5) / 9;
  }
  return value;
};

export const getImageDimensionFromUrl = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
    img.onerror = reject;
    img.src = url;
  });
};

export const getVideoDimensions = url => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadedmetadata = () => resolve({ width: video.videoWidth, height: video.videoHeight });
    video.onerror = reject;
    video.src = url;
  });
};

export const formatShortLink = url => {
  if (url && url.includes('shorts/')) {
    return url.replace('shorts/', 'watch?v=');
  }
  return url;
};

export const formatLeaderboardRanking = (type, score) => {
  if (!!type && ['task', 'workout', 'rep', 'step'].includes(type)) {
    return pluralize(type, score);
  }
  return type;
};

const CONVERTED_FOLDER = 'thumbnails-converted';
export const replaceImageURL = (url, isSynced = true) => {
  try {
    if (url && !url.includes(CONVERTED_FOLDER)) {
      let newUrl = url.replace('/images', '');
      const fileName = url.split('/').pop();
      if (fileName) {
        newUrl = newUrl.replace(fileName, `${CONVERTED_FOLDER}/${fileName}`);
      }
      if (checkingValidUrl(newUrl, isSynced)) {
        return newUrl;
      }
      return url;
    }
    return url;
  } catch (error) {
    return url;
  }
};

export const replaceImageToThumbnailConvertedURL = url => {
  if (url && !url.includes(CONVERTED_FOLDER)) {
    const newUrl = url.replace('/images', '');
    const fileName = url.split('/').pop();
    if (fileName) {
      return newUrl.replace(fileName, `${CONVERTED_FOLDER}/${fileName}`);
    }
  }
  return url;
};

export const getLeaderboardUnitLabel = (type, setting) => {
  if (type) {
    switch (type.value) {
      case 2:
        return _.get(setting, 'setting.title', '');
      case 7:
      case 8:
        return '';
      default:
        return _.get(type, 'unitInfo.unique_code', _.get(type, 'unit.value', ''));
    }
  }
  return '';
};

export const swapElement = (array, indexA, indexB) => {
  var tmp = array[indexA];
  array[indexA] = array[indexB];
  array[indexB] = tmp;
};

// Click out area hook
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const downloadFileFromURL = async (buffer, contentType, name, ext) => {
  return new Promise(async (resolve, reject) => {
    try {
      let csvBlob = new Blob([new Uint8Array(buffer.data)], {
        type: contentType,
      });
      const imageURL = URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = imageURL;
      link.setAttribute('download', `${name}.${ext}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return resolve();
    } catch (err) {
      return reject();
    }
  });
};

export const downloadFileFromBlobURI = (blobURI, name = 'file.pdf') => {
  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobURI;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const requestLogInfo = (key, data) => {
  if (
    process.env.REACT_APP_ENABLE_DATADOG_RUM &&
    process.env.REACT_APP_DATADOG_RUM_APP_ID &&
    process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN &&
    window.isEnableLogRequest
  ) {
    const userInfo = {
      name: get(window, 'activeUser.fullName', ''),
      email: get(window, 'activeUser.email', ''),
      team_id: get(window, 'activeUser.teamId', ''),
      id: get(window, 'activeUser.userId', ''),
      is_trainer: true,
    };
    datadogLogs.logger.info(key, { ...data, user: { ...userInfo } });
  }
};

export const getPaymentCountries = () => {
  const isFullSupport = process.env.REACT_APP_ENABLE_PAYMENT_GERMANY_SWITZERLAND;
  const expand = isFullSupport ? GERMANY_SWITZERLAND : [];
  const labels = expand.map(it => it.label);
  const message = labels.join(', ');
  const replace = labels.length < 1 ? '' : message.replace(/([^,]*)$/, ` and $1`);
  return `Currently only available for US, UK, Australia, Canada, New Zealand, Germany, Switzerland,`;
};

// Move cursor position in contenteditable
export const setCaretToEnd = target => {
  if (target) {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(target);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
    range.detach();
    // set scroll to the end if multiline
    target.scrollTop = target.scrollHeight;
  }
};

export const isSafari = () => {
  return (
    navigator.userAgent.toLowerCase().indexOf('safari') !== -1 &&
    navigator.userAgent.toLowerCase().indexOf('chrome') === -1 &&
    typeof window.ontouchstart === 'undefined'
  );
};

export const detectDocumentType = fileName => {
  const docType = find(
    DOCUMENT_MINETYPE,
    type => {
      return fileName.includes(type) && type;
    },
    null,
  );

  return docType.slice(1) || 'document';
};

// run test: npm test src/test/limitText.test.js
export const limitText = (text = '', limit) => {
  if (limit <= 0 || !text) {
    return text;
  }
  return text.length > limit ? `${text.slice(0, limit)}...` : text;
};

export const convertLabelForDropdown = (list, field = 'name') => {
  if (!list || (list && !list.length)) return [];

  return list.map(item => ({
    ...item,
    label: _.capitalize(item[field]),
    value: _.lowerCase(item[field]),
  }));
};

/**
 * getHabitDescription
 * @param {*} habit object, require properties goal_value, unit, frequency
 * @returns {string} description
 */
export const getHabitDescription = ({ goal_value, unit: { name: unitName }, frequency }) => {
  let frequencyFormat = '';
  switch (frequency) {
    case 'per_day':
      frequencyFormat = 'a day';
      break;
    case 'per_week':
      frequencyFormat = 'a week';
      break;
    default:
      break;
  }
  let unitFormat = unitName;
  if (goal_value <= 1 && unitName) {
    unitFormat = unitName.replace(/s$/, '');
  }
  return `${goal_value} ${unitFormat} ${frequencyFormat}`.toLowerCase();
};

export const convertReminderTime = ({ value, beforeFormat, afterFormat }) =>
  moment(value, beforeFormat).format(afterFormat);

export const disableFutureDateTime = current => {
  return current.isBefore(moment());
};

export const copyToClipboard = text => {
  let dummy = document.createElement('textarea');
  let range, selection;
  document.body.appendChild(dummy);
  dummy.value = text;

  if (isSafariLib) {
    range = document.createRange();
    range.selectNodeContents(dummy);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    dummy.setSelectionRange(0, 999999);
    document.execCommand('copy');
  } else {
    dummy.select();
    navigator.clipboard.writeText(dummy.value);
  }
  document.body.removeChild(dummy);
};

const removeParam = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
};

export const makeFriendlyYoutubeURL = src => {
  let result = src;
  if (validateYouTubeUrl(result)) {
    _.forEach(['list', 'index'], item => {
      result = removeParam(item, result);
    });
  }
  return result;
};

export function workoutDiscardFilter(data, arr) {
  let result = data;
  if (data.description || data.title) {
    result.description = result.description ? result.description.trim() : '';
    result.title = result.title ? data.title.trim() : '';
    _.forEach(data.sections, sections => {
      _.forEach(sections.exercises, exercises => {
        _.forEach(exercises.supersets, supersets => {
          let unsetFields = supersets.exercise;
          _.forEach(arr, arr => {
            result = _.unset(unsetFields, arr);
          });
          if (supersets.alternatives.length) {
            _.forEach(supersets.alternatives, alternatives => {
              _.forEach(arr, arr => {
                result = _.unset(alternatives, arr);
              });
            });
          }
          if (supersets.training_sets.length) {
            _.forEach(supersets.training_sets, training_sets => {
              _.forEach(arr, arr => {
                result = _.unset(training_sets, arr);
              });
            });
          }
        });
      });
    });
  } else return;

  return result;
}

// run test: npm test src/test/isShowTrafficPage.test.js or yarn test src/test/isShowTrafficPage.test.js
export const isShowTrafficPage = query => {
  if (!query) return true;
  const decodeQuery = decodeURIComponent(query);

  // From facebook
  if (decodeQuery.includes('fbclid')) return false;

  const urlSearchParams = new URLSearchParams(decodeQuery);
  const params = Object.fromEntries(urlSearchParams.entries());
  const paramKeys = Object.keys(params);

  return paramKeys.every(key => {
    return TRAFFIC_TRACKING_CASES.includes(key);
  });
};

export const checkSameMonth = (datetime1, datetime2) => {
  const monthOfDatetime1 = datetime1.month;
  const monthOfDatetime2 = datetime2.month;

  return monthOfDatetime1 === monthOfDatetime2 ? true : false;
};

export const zeroPadDigit = number => {
  return (number < 10 ? '0' : '') + number;
};

// run test: npm test src/test/isHasworkoutInWeek.test.js or yarn test src/test/isHasworkoutInWeek.test.js
export const hasWorkoutInWeek = (workoutsByDay, weekIndex) => {
  let hasWorkoutInWeek = false;
  for (const [key, value] of Object.entries(workoutsByDay)) {
    const weekWorkout = parseInt(key.split('_')[0]);
    if (weekWorkout === weekIndex && !_.isEmpty(value)) {
      hasWorkoutInWeek = true;
    }
  }
  return hasWorkoutInWeek;
};

export const LIMIT_SELECTED_WORKOUTS = 20;
// run test: npm test src/test/isTrialLeftDays.test.js or yarn test src/test/isTrialLeftDays.test.js
export const getTrialLeftDays = (ended_trial_at, today, unit = 'days') => {
  const diffFromNow = (d, today, unit) => {
    const dt = DateTime.fromJSDate(d);
    const td = today ? DateTime.fromJSDate(new Date(today)) : DateTime.now();
    return dt.diff(td, unit);
  };

  let res = Math.ceil(diffFromNow(new Date(ended_trial_at), today, unit)[unit]);
  if (res <= 0) res = 1;

  return res;
};

export const convertAutoFillFields = (string = '', characterWantReplace = '_', characterReplace = ' ') => {
  if (!string) return '';
  const newReg = new RegExp(`/#|${characterWantReplace}`, 'g');
  return string.replace(newReg, characterReplace).trim();
};

export const generateGroupWeeks = weeks => {
  if (weeks < 1) return [];
  const groupsOption = [];
  for (let index = 0; index < weeks; index++) {
    const weekOptions = _.fill(Array(7), null).map((v, i) => {
      const value = i + index * 7;
      return {
        key: value + 1,
        label: `Day ${value + 1}`,
        value: value,
      };
    });
    const group = {
      label: `— Week ${index + 1} —`,
      options: weekOptions,
    };
    groupsOption.push(group);
  }
  return groupsOption;
};

export const isESCPress = key => {
  return ['Esc', 'Escape'].includes(key);
};

export const generateRoomName = (name, totalParticipants) => {
  if (!name || !totalParticipants) return;

  const firstNameOfMembers = name.split(',');
  const topThreeMembers = firstNameOfMembers.slice(0, 3).join(', ');
  const otherMembers = totalParticipants > 3 ? `, and ${pluralize('other', totalParticipants - 3, true)}` : '';

  return `${topThreeMembers}${otherMembers}`;
};

// run test: npm test src/test/splitEmailString.test.js
export const splitEmailString = (email, index = 0) => {
  return toLower(email.split('@')[index]);
};

export const convertDataMention = (content = '', tagged_people = []) => {
  const taggedPeopleConvert = tagged_people.map(item => item && item.name);
  taggedPeopleConvert.push('everyone');
  let result = content;
  taggedPeopleConvert.map(item => {
    result = result.replace(`@${item}`, item);
  });
  return result;
};

export function arrayToString(array) {
  return array.join(', ');
}

export const convertHeicToJpeg = async (files, onSuccess, onError) => {
  const listImages = [];
  for (const file of files) {
    await heic2any({
      blob: file,
      toType: 'image/jpeg',
    })
      .then(result => {
        const newFile = new File([result], `image_${new Date().getTime()}.jpeg`, {
          type: result.type,
        });
        listImages.push(newFile);
        onSuccess && onSuccess('Successfully');
      })
      .catch(err => {
        onError && onError(err);
      });
  }
  return listImages;
};

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

// run test: npm test src/test/convertMsToTime.test.js
export function convertMsToTime(milliseconds) {
  if (!milliseconds) return '';
  if (typeof milliseconds !== 'number') return '';

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

export function convertMsToRawTime(milliseconds) {
  if (!milliseconds) return '';
  if (typeof milliseconds !== 'number') return '';

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let months = Math.floor(days / 30);
  let years = Math.floor(months / 12);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  days = days % 30;
  months = months % 12;

  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
}

export const formatMoney = (amount, symbol = '$', decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      symbol +
      negativeSign +
      (j ? i.substring(0, j) + thousands : '') +
      i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

export function setupWheelEventListener(event) {
  event.addEventListener(
    'wheel',
    function (e) {
      e.preventDefault();
    },
    { passive: false },
  );
}

export const getDayTitle = (day, timezone) => {
  const today = moment();
  const selectedDayMoment = moment(day);
  const isToday = moment().tz(timezone).format('MM-DD-YYYY') === moment(day).format('MM-DD-YYYY');
  const isSameYear = today.isSame(day, 'year');

  if (isToday) return `Today, ${selectedDayMoment.format('MMM D')}`;
  return selectedDayMoment.format(isSameYear ? 'MMM D' : 'MMM D, YYYY');
};

/**
 * Generates the avatar source for a given notification.
 *
 * @param {Object} notification - The notification object.
 * @return {string} The avatar source URL.
 */
export function getAvatarNotification(notification = {}, cloudfrontList = []) {
  const { type, action_type, additional_info, relate_user } = notification;
  const { app_icon_url, remote_icon } = additional_info || {};
  const { avatar = '' } = relate_user || {};

  let avatarSrc = NOTIFICATION_AVATAR[notification.action_type];

  if (type) {
    if (action_type === 'app_icon') {
      avatarSrc = app_icon_url || remote_icon || '';
    } else if (action_type === 'recap') {
      avatarSrc = `${CDN_URL}/images/notification_logo.svg`;
    } else {
      avatarSrc = NOTIFICATION_AVATAR[notification.type];
    }
  }

  if (action_type === 'package_trial') {
    avatarSrc = NOTIFICATION_AVATAR[notification.action_sub_type];
  }

  if (isNotificationPaymentSummary(notification)) {
    avatarSrc = NOTIFICATION_AVATAR[notification.action_target];
  }

  return avatarSrc || convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true);
}

/**
 * Formats a given date string according to the specified timezone and returns a formatted string.
 *
 * @param {string} dateString - The date string to be formatted.
 * @param {string} timezone - The timezone to be used for formatting. If not provided, the default timezone will be used.
 * @return {string} The formatted date string.
 */
export function formatDateLastUpdate(dateString, timezone) {
  if (!dateString) return '';
  const date = DateTime.fromISO(dateString, { zone: timezone, locale: 'en-US' });
  const now = DateTime.local().setZone(timezone);
  if (isSameCurrentDate(dateString, timezone)) {
    return 'Today';
  }
  const month = date.toLocaleString({ month: 'short' });
  const day = date.toLocaleString({ day: '2-digit' }).replace(/^0/, '');
  const year = date.toLocaleString({ year: 'numeric' });
  if (date.year !== now.year) {
    return `${month} ${day}, ${year}`;
  }
  return `${month} ${day}`;
}

/**
 * Formats a given date string according to the specified timezone and returns a formatted string.
 *
 * @param {string} dateString - The date string to be formatted.
 * @param {string} timezone - The timezone to be used for formatting. If not provided, the default timezone will be used.
 * @return {string} The formatted date string.
 */
export function formatDateNotification(dateString, timezone) {
  if (!dateString) return '';
  const date = DateTime.fromISO(dateString, { zone: timezone, locale: 'en-US' });
  const now = DateTime.local().setZone(timezone);
  if (isSameCurrentDate(dateString, timezone)) {
    const seconds = Math.abs(Math.floor((now.toJSDate() - date.toJSDate()) / 1000));
    if (seconds < 60) return 'Just now';
    return date.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: true });
  }
  const month = date.toLocaleString({ month: 'short' });
  const day = date.toLocaleString({ day: '2-digit' }).replace(/^0/, '');
  const year = date.toLocaleString({ year: 'numeric' });
  const time = date.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: true });
  if (date.year !== now.year) {
    return `${month} ${day}, ${year} • ${time}`;
  }
  return `${month} ${day} • ${time}`;
}

/**
 * Formats a given date string according to the specified timezone and returns a formatted string.
 *
 * @param {string} dateString - The date string to be formatted.
 * @param {string} timezone - The timezone to be used for formatting. If not provided, the default timezone will be used.
 * @return {string} The formatted date string.
 */
export function formatDateCreatedNote({ dateString, timezone, isShowTime = false, isShowToday = false } = {}) {
  if (!dateString) return '';
  const date = DateTime.fromISO(dateString, { zone: timezone, locale: 'en-US' });
  const now = DateTime.local().setZone(timezone);
  const month = date.toLocaleString({ month: 'short' });
  const day = date.toLocaleString({ day: 'numeric' });
  const year = date.toLocaleString({ year: 'numeric' });
  const time = date.toLocaleString({ hour: 'numeric', minute: '2-digit', hour12: true });
  if (isShowToday && isSameCurrentDate(dateString, timezone)) {
    return isShowTime ? `Today ${time}` : 'Today';
  }
  if (date.year !== now.year) {
    return isShowTime ? `${month} ${day}, ${year} - ${time}` : `${month} ${day}, ${year}`;
  }
  return isShowTime ? `${month} ${day} - ${time}` : `${month} ${day}`;
}

/**
 * Formats a given date string according to the specified timezone and returns a formatted string.
 *
 * @param {string} dateString - The date string to be formatted.
 * @param {string} timezone - The timezone to be used for formatting. If not provided, the default timezone will be used.
 * @return {string} The formatted date string.
 */
export function formatDatePaymentSummary(dateString, timezone) {
  if (!dateString) return '';
  const date = DateTime.fromISO(dateString, { zone: timezone, locale: 'en-US' });
  const now = DateTime.local().setZone(timezone);
  const month = date.toLocaleString({ month: 'short' });
  const day = date.toLocaleString({ day: 'numeric' });
  const year = date.toLocaleString({ year: 'numeric' });
  if (date.year !== now.year) {
    return `${month} ${day}, ${year}`;
  }
  return `${month} ${day}`;
}

/**
 * Returns the formatted creation date string.
 *
 * @param {Object} data - The data object.
 * @returns {string} The formatted creation date string.
 */
export const getCreatedAtString = data => {
  const createdAt = _.get(data, 'createdAt');
  const timezone = _.get(data, 'user.timezone');
  if (_.isEmpty(data) || _.isEmpty(createdAt) || _.isEmpty(timezone)) return '';
  const date = new Date(new Date(createdAt).toLocaleString('en-US', { timeZone: timezone }));
  // format MM-dd-yyyy
  return `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`;
};

/**
 * Returns the formatted creation date string.
 *
 * @param {Object} data - The data object.
 * @returns {string} The formatted creation date string.
 */
export const getAdditionalInfoDayString = data => {
  const additionalInfoDay = _.get(data, 'additional_info.day');
  const timezone = _.get(data, 'user.timezone');
  if (_.isEmpty(data) || _.isEmpty(additionalInfoDay) || _.isEmpty(timezone)) return '';
  const date = new Date(new Date(additionalInfoDay).toLocaleString('en-US', { timeZone: timezone }));
  // format MM-dd-yyyy
  return `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`;
};

/**
 * Checks if the given date is the same as the current date in the specified time zone.
 *
 * @param {string} dateString - The date to compare in string format.
 * @param {string} timeZone - The time zone to use for the comparison.
 * @return {boolean} Returns true if the given date is the same as the current date in the specified time zone, otherwise returns false.
 */
export const isSameCurrentDate = (dateString, timeZone) => {
  if (!dateString) return false;
  const currentDate = DateTime.local().setZone(timeZone).toISODate();
  const givenDate = DateTime.fromISO(dateString, {
    zone: timeZone,
  }).toISODate();
  return currentDate === givenDate;
};

/**
 * Converts filters to notification params.
 *
 * @param {object} params - The filters to convert.
 * @param {string} params.clientId - The ID of the client.
 * @param {string[]} params.groupIds - The IDs of the groups.
 * @param {string} params.ownerShip - The ownership value.
 * @param {string} params.forumId - The ID of the forum.
 * @param {string} params.category - The category value.
 * @param {string[]} params.paymentState - The payment states.
 * @param {boolean} params.showUnread - Whether to show only unread notifications.
 * @param {boolean} params.includeCoaches - Whether to show only included coaches.
 * @param {boolean} params.includeInternal - Whether to show only included internal.
 * @return {object} The converted notification params.
 */
export const convertFiltersToNotificationParams = (params = {}) => {
  const result = {};

  // Client
  if (!_.isEmpty(params.clientId) && _.isEmpty(params.groupIds)) {
    result.client = params.clientId;
  }

  // Owner
  if (!_.isUndefined(params.ownerShip)) {
    result.owner_ship = _.isArray(params.ownerShip)
      ? CLIENT_OWNER_SHIP.CERTAIN_USERS
      : params.ownerShip === null
      ? CLIENT_OWNER_SHIP.ALL
      : CLIENT_OWNER_SHIP.ME;

    if (result.owner_ship === CLIENT_OWNER_SHIP.CERTAIN_USERS) {
      result.certain_users = params.ownerShip;
    }
  }

  // Groups
  if (!_.isEmpty(params.groupIds) && _.isEmpty(params.clientId)) {
    result.group_ids = params.groupIds;
  }

  // Forum
  if (!_.isEmpty(params.forumId) && params.category === NOTIFICATION_CATEGORIES.FORUM) {
    result.forum = params.forumId;
  }

  // Payment state
  if (!_.isNil(params.paymentState) && params.category === NOTIFICATION_CATEGORIES.PAYMENT) {
    if (_.isArray(params.paymentState)) {
      result.states = [];
      _.forEach(params.paymentState, item => {
        if (Object.values(NOTIFICATION_PAYMENT_STATES).includes(item)) {
          result.states.push(item);
        }
      });
    }
  }

  // Category
  if (!_.isEmpty(params.category)) {
    if (Object.values(NOTIFICATION_CATEGORIES).includes(params.category)) {
      result.category = params.category;
    }
  }

  // Show only unread
  if (!_.isNil(params.showUnread)) {
    result.only_show_unread = params.showUnread;
  }

  // Coaches
  if (params.includeCoaches === false) {
    result.include_coaches = false;
  }

  // Internal
  if (params.includeInternal === false) {
    result.include_internal = false;
  }

  return result;
};

/**
 * Check if the item is a notification for payment summary.
 * @param {Object} item - The notification item to check.
 * @returns {boolean} - True if the item is a notification for payment summary, false otherwise.
 */
export const isNotificationPaymentSummary = item =>
  _.get(item, 'action') === 'report' && _.get(item, 'action_target') === 'payment_summary';

/**
 * Check if the item is a notification for recap end of year.
 * @param {Object} item - The notification item to check.
 * @returns {boolean} - True if the item is a notification for payment summary, false otherwise.
 */
export const isNotificationRecap = (item = {}) => item.action_type === 'recap' && item.category === 'admin';

/**
 * Format the item's price for display in a sales context.
 * @param {Object} item - The item to format.
 * @returns {string} - The formatted price.
 */
export const formatInSale = item => {
  const inSales = get(item, 'additional_info.in_sales', 0);
  const keyCurrency = get(item, 'additional_info.currency', 'usd');
  const symbol = get(CURRENCIES, `${keyCurrency}.symbol`);
  const formatSymbol = TEXT_CURRENCIES.includes(keyCurrency) ? `${symbol} ` : symbol;
  return formatMoney(_.round(inSales, 2), formatSymbol);
};

/**
 * Convert a s3 url and returns a cloudfront url.
 *
 * @param {string} s3Url - S3 url asset (image url).
 * @param {Array} cloudfrontList - List of cloudfront distribution.
 * @param {Boolean} shouldReturnOriginIfNotFound - Used to return s3Url if not found cloudfront.
 * @return {string} The cloudfront url if found, else null.
 */
export const convertS3UrlToCloudFrontUrl = (s3Url, cloudfrontList = [], shouldReturnOriginIfNotFound = false) => {
  if (!s3Url || !isS3FileURL(s3Url)) {
    if (shouldReturnOriginIfNotFound) {
      return s3Url;
    }
    return null;
  }

  const { protocol, hostname, pathname } = new URL(s3Url);
  const bucketName = hostname.split('.').shift();
  const bucket = cloudfrontList.find(item => item.s3_bucket === bucketName);

  if (bucket) {
    return `${protocol}//${bucket.cloudfront_distribution}${pathname}?Policy=${bucket.policy}&Signature=${bucket.signature}&Key-Pair-Id=${bucket.key_pair_id}`;
  }

  if (shouldReturnOriginIfNotFound) {
    return s3Url;
  }

  return null;
};

export const getItemNotificationUrl = (notification = {}, isHabitPermission = false) => {
  if (!notification && typeof notification !== 'object') return '';
  const {
    type = '',
    action_type = '',
    action_sub_type = '',
    action_target = '',
    additional_info = {},
    item = '',
    sub_item = '',
    secondary_item = '',
    item_topic = '',
    sub_item_topic = '',
    category = '',
  } = notification;
  const { _id: relate_user_id = '' } = notification.relate_user || {};
  const {
    _id: additional_info_id = '',
    author: additional_info_author = '',
    metric_code: additional_info_metric_code = '',
    purchase_id: additional_info_purchase_id = '',
    form: additional_info_form = '',
    formAnswer: additional_info_form_answer = '',
  } = additional_info || {};
  const { _id: additional_info_client_id = '' } = additional_info.client || {};
  const { id: additional_info_package_id = '', hash_id: additional_info_package_hash_id = '' } =
    additional_info.package || {};
  const { id: additional_info_purchase__id = '' } = additional_info.purchase || {};
  const isPaymentNotification =
    Object.values(PAYMENT_NOTIFICATIONS_TYPES).includes(type) ||
    Object.values(PAYMENT_NOTIFICATIONS_TYPES).includes(action_sub_type);
  const isRecap = action_type === NOTIFICATION_ACTIONS.RECAP && category === 'admin';
  if (isPaymentNotification) {
    const packageId = additional_info_package_hash_id || additional_info_package_id;
    const purchaseId = additional_info_purchase__id || additional_info_purchase_id;
    if (packageId && purchaseId) {
      return `/home/packages/${packageId}/analytics?preview=${purchaseId}`;
    }
  }
  if (action_target === PAYMENT_NOTIFICATIONS_TYPES.PAYMENT_SUMMARY) {
    return `/home/packages`;
  }
  if (
    type === NOTIFICATION_TYPES.FORM_QUESTIONNAIRE &&
    additional_info_form &&
    relate_user_id &&
    additional_info_form_answer
  ) {
    return `/home/forms/${additional_info_form}/responses?client=${relate_user_id}&submitted=${additional_info_form_answer}`;
  }
  if (isRecap) {
    const token = getAccessToken();
    const domain = process.env.REACT_APP_RECAP_DOMAIN;
    if (domain) {
      const url = domain + `?token=${token}`;
      return url;
    }
  }
  if (action_type === NOTIFICATION_ACTIONS.ONBOARDING_FLOW && item) {
    return `/home/onboarding-flow/${item}`;
  }
  let url;
  if (
    action_type === NOTIFICATION_ACTIONS.MEAL_PLAN_CLIENT &&
    action_sub_type === NOTIFICATION_SUB_ACTIONS.CLIENT_SWAPPED_RECIPE &&
    action_target === NOTIFICATION_ACTIONS.MEAL_PLAN_CLIENT
  ) {
    const clientId = relate_user_id;
    const trackedDate = getAdditionalInfoDayString(notification);
    if (clientId) url = `/home/client/${clientId}/meal-plan?tracked=${trackedDate}`;
  } else if (
    action_type === NOTIFICATION_ACTIONS.MACRO &&
    action_sub_type === NOTIFICATION_SUB_ACTIONS.CLIENT_LOGGED_MEAL &&
    action_target === NOTIFICATION_ACTIONS.MACRO
  ) {
    const clientId = relate_user_id;
    const trackedDate = getAdditionalInfoDayString(notification);
    if (clientId) url = `/home/client/${clientId}/macros?tracked=${trackedDate}`;
  } else if (
    action_type === NOTIFICATION_ACTIONS.PROGRESS_PHOTO &&
    action_target === NOTIFICATION_ACTIONS.PROGRESS_PHOTO
  ) {
    const clientId = relate_user_id;
    url = `/home/client/${clientId}?show=progress_photo`;
  } else if (
    action_type === NOTIFICATION_ACTIONS.GOAL &&
    action_sub_type === NOTIFICATION_SUB_ACTIONS.UPDATE &&
    !action_target
  ) {
    url = `/home/client/${relate_user_id}/macros`;
  } else if (
    action_type === NOTIFICATION_ACTIONS.BODY_METRIC &&
    action_sub_type === NOTIFICATION_SUB_ACTIONS.UPDATE &&
    action_target === NOTIFICATION_ACTIONS.BODY_METRIC
  ) {
    url = `/home/client/${relate_user_id}/metrics`;
  } else if (action_type === NOTIFICATION_ACTIONS.FORM_QUESTIONNAIRES) {
    url = `/home/forms/${additional_info_id}/responses?client=${
      additional_info_client_id || relate_user_id
    }&submitted=${item}&hasComment=true`;
  } else if (action_sub_type === NOTIFICATION_SUB_ACTIONS.NEW_WAITING_CLIENT) {
    url = '/home/client/waiting-activation';
  } else if (action_type === NOTIFICATION_ACTIONS.LEADER_BOARD && secondary_item) {
    url = `/home/autoflow/${secondary_item}/leaderboard`;
  } else if (action_type === NOTIFICATION_ACTIONS.REFERRAL) {
    url = '/home/referral-program';
  } else if (action_type === NOTIFICATION_ACTIONS.AUTOFLOW) {
    url = '/home/autoflow';
    if (item) {
      url += `/${item}/${item_topic}`;
    }
  } else if (action_type === NOTIFICATION_ACTIONS.FORUM) {
    if (sub_item_topic === 'forum_post') {
      url = `/home/forums/${item}/post-item/${sub_item}`;
    } else {
      url = `/home/forums/${item}/discussion`;
    }
  } else if (action_type === NOTIFICATION_ACTIONS.LOG_ACTIVITY) {
    if (additional_info_author || relate_user_id) {
      url = `/home/client/${additional_info_author || relate_user_id}/calendar/${item}/activity`;
    }
  } else if (action_type === NOTIFICATION_ACTIONS.CLIENT_ASSIGNED) {
    url = `/home/client/${additional_info_client_id}`;
  } else if (notification.relate_user) {
    if (action_type !== TOPICS.PERSONAL_TASK) {
      url = `/home/client/${additional_info_client_id || relate_user_id}`;
    }
    if (item) {
      switch (item_topic) {
        case TOPICS.FOOD_JOURNAL:
          url += `/food-journal/detail/${item}`;
          break;
        case TOPICS.ASSIGNMENT:
          url += `/calendar/${item}/history`;
          break;
        case TOPICS.TASK:
          url += `/task/${item}?`;
          if (action_sub_type === NOTIFICATION_SUB_ACTIONS.COMMENT) {
            url += `comment=show&`;
          }
          url = url.replace(/\?$|&$/, '');
          break;
        case TOPICS.TEAM_BODY_METRIC:
          url += additional_info_metric_code
            ? `/metrics?unique_code=${encodeURIComponent(additional_info_metric_code)}`
            : `/metrics`;
          break;
        case TOPICS.PERSONAL_TASK:
          break;
        case TOPICS.HABIT:
          if (isHabitPermission) {
            url += `/habit/${item}?`;
            if (
              action_sub_type === NOTIFICATION_SUB_ACTIONS.ON_STREAK ||
              action_sub_type === NOTIFICATION_SUB_ACTIONS.HABIT_COMPLETE_TRAINER
            ) {
              const trackedDate = getCreatedAtString(notification);
              url += `tracked=${trackedDate}&`;
            }
            if (action_sub_type === NOTIFICATION_SUB_ACTIONS.COMMENT) {
              url += `comment=show&`;
            }
            url = url.replace(/\?$|&$/, '');
          } else {
            return;
          }
          break;
        default:
          break;
      }
    }
  }
  return url;
};

export const formatLiveLink = url => {
  if (url && url.includes('live/')) {
    return url.replace('live/', 'watch?v=');
  }
  return url;
};

export const hexToRgbaWithOpacity = (hexColor = '', opacity = 1) => {
  // Remove the leading # if present
  hexColor = hexColor.replace(/^#/, '');
  // Check if the hex color has enough characters
  if (hexColor.length !== 6) {
    return hexColor;
  }
  // Convert the hex color to decimal values
  const [r, g, b] = [0, 2, 4].map(start => parseInt(parseInt(hexColor.substring(start, start + 2), 16)));

  // Return the RGBA color array
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const convertFtToFtAndInch = (ftValue, decimalIn = 1) => {
  const inch = Number((ftValue - Math.floor(ftValue)) * 12);

  return {
    ft: Math.floor(ftValue) || '',
    inch: roundNumber(inch, decimalIn) || '',
  };
};

export const convertFtAndInch = (ftValue, decimalIn = 1) => {
  const inch = Number((ftValue - Math.floor(ftValue)) * 12);

  return {
    ft: Math.floor(ftValue) || '',
    inch: roundNumberFtnInch(inch, decimalIn) || '',
  };
};

export const convertFtAndInchToFt = (ft, inch) => {
  const inchVal = roundNumberBodyMetric(Number((inch || 0) / 12), 3);
  return Number(ft) + Number(inchVal);
};

export const checkCountryUS = (countryCode = '') => {
  return countryCode.toUpperCase() === 'US';
};

// MP
export const genTokenFromMarketplace = ({ token, user, pathname, search, replacePathname, dispatch }) => {
  axiosInstanceWithoutToken
    .post('/api/authentication/trainer/gen-marketplace-token', {
      token,
      agent: 'web',
    })
    .then(result => {
      if (get(result, 'data.data.user._id') !== get(user, '_id')) {
        dispatch(logout(pathname + search));
      } else {
        dispatch(replace(replacePathname || pathname));
      }
    })
    .catch(() => {
      dispatch(logout(pathname + search));
    });
};

export const removeAllParamsFromURL = url => (url ? url.split('?')[0] : '');

export const trimEmptyLines = text => {
  return (text || '').replace(/\n\s*\n/g, '\n\n');
};

export const parseTextAutoMessages = (text = '', isWorkspace = false) => {
  let arr = [];
  let plainText = '';

  for (let i = 0; i < text.length; ) {
    const match = (isWorkspace ? AUTOFILL_ONBOARDINGFLOW_ITEMS : AUTOFILL_ITEMS).find(item =>
      text.startsWith(item.value, i),
    );

    if (match) {
      const { title, value } = match;
      if (plainText) {
        arr.push({ content: plainText, highlight: false });
        plainText = '';
      }

      arr.push({ content: title, highlight: true });
      i += value.length;
    } else {
      plainText += text[i++];
    }
  }

  if (plainText) {
    arr.push({ content: plainText, highlight: false });
  }

  return arr;
};

/**
 * Returns the moved element array.
 *
 * @param {Array} array - The array object.
 * @param {number} fromIndex - Source index
 * @param {number} toIndex - Destination index
 * @returns {Array} The moved element array.
 *
 * E.g:
 * moveElement([1,2,3,4,5],0,2);
 * -> [2,3,1,4,5]
 */
export const moveElement = (array, fromIndex, toIndex) => {
  const newArray = cloneDeep(array);

  newArray.splice(toIndex, 0, newArray.splice(fromIndex, 1)[0]);

  return newArray;
};

export const getTimeFromString = time => {
  if (_.isEmpty(time)) return undefined;

  if (time.includes(':')) {
    const [hours = 0, minutes = 0] = time.split(':');

    return Number(hours * 60) + Number(minutes);
  }

  return Number(time);
};

export const formatInputTime = inputTime => {
  let input = '';
  if (typeof inputTime === 'number' && inputTime !== NaN) {
    input = `${inputTime}`;
  } else if (typeof inputTime === 'string' && !_.isEmpty(inputTime) && Number(inputTime) !== NaN) {
    input = Number(inputTime).toString();
  }
  if (_.isEmpty(input)) return '';

  const length = input.length;
  if (length === 1) {
    return `${input}`;
  } else if (length === 2) {
    return `${input}`;
  } else if (length >= 3) {
    const hours = input.slice(0, -2);
    const minutes = input.slice(-2);
    return `${hours}:${minutes || '00'}`;
  }
};

export const getMetaSize = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = err => reject(err);
    img.src = url;
  });
};

export const MAX_RES_URL = 'maxresdefault.jpg';
export const HQ_URL = 'hqdefault.jpg';
export const DEFAULT_URL = 'default.jpg';

export const checkYoutubeThumbnailUrl = async videoId => {
  const maxResUrl = `https://img.youtube.com/vi/${videoId}/${MAX_RES_URL}`;
  const hqUrl = `https://img.youtube.com/vi/${videoId}/${HQ_URL}`;
  const defaultUrl = `https://img.youtube.com/vi/${videoId}/${DEFAULT_URL}`;

  try {
    const maxResThumbnail = await getMetaSize(maxResUrl);
    if (maxResThumbnail.width === 120 && maxResThumbnail.height === 90) {
      const hqThumbnail = await getMetaSize(hqUrl);
      if (hqThumbnail.width === 120 && hqThumbnail.height === 90) {
        // default resolution
        return defaultUrl;
      } else {
        // hq resolution
        return hqUrl;
      }
    } else {
      // max resolution
      return maxResUrl;
    }
  } catch (error) {
    console.error('Error loading thumbnails:', error);
  }
};

export const getVideoDetailSize = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = err => reject(err);
    img.src = url;
  });
};

export const getYoutubeThumbnail = async exercise => {
  try {
    let originalThumbnail = exercise.thumbnail_url;
    if (!_.isEmpty(exercise.videoLink)) {
      const metaSize = await getVideoDetailSize(exercise.thumbnail_url);
      if (metaSize.width === 120 && metaSize.height === 90) {
        const preview300Size = await getVideoDetailSize(exercise.preview_300);
        if (preview300Size.width === 120 && preview300Size.height === 90) {
          originalThumbnail = exercise.preview_50;
        } else {
          originalThumbnail = exercise.preview_300;
        }
      }
    }
    return originalThumbnail;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const splitOwner = ({ user, clients }) => {
  const yourClients = [];
  const otherClients = [];

  clients.forEach(assignedClient => {
    const trainers = assignedClient.trainers || [];
    const isTrainer = trainers.findIndex(it => it.trainer === user._id);
    if (isTrainer !== -1) {
      yourClients.push(assignedClient);
    } else {
      const subTrainers = assignedClient.sub_trainers || [];
      const isSubTrainer = subTrainers.findIndex(it => it.trainer === user._id);
      otherClients.push({ ...assignedClient, canRemove: isSubTrainer !== -1 });
    }
  });

  return [yourClients, otherClients];
};

export function formatNumberEven(num, showFromNumber = 1000) {
  if (num < showFromNumber || num < 1000) return num;
  if (num < 100000) {
    const newNum = (num / 1000).toFixed(3);
    const val = newNum.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0];
    return (val.includes('.0') ? Number(val) : val) + 'K';
  }
  if (num < 1000000) {
    return Math.floor(num / 1000) + 'K';
  }

  return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
}

export const upperFirstCharacter = text => {
  if (typeof text !== 'string') return text;
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Converts a HEIC image from a given URL to a JPEG image using XMLHttpRequest
 * and the heic2any library.
 *
 * @param {string} imageUrl - The URL of the HEIC image to be converted.
 * @param {function} callback - The callback function to be called when the
 * conversion is complete. The callback function takes two parameters: an
 * error (or null if there was no error), and the URL of the converted JPEG image.
 */
export const convertHEICToJPEGfromUrl = (imageUrl, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', imageUrl, true);
  xhr.responseType = 'blob';

  xhr.onload = function () {
    if (xhr.status === 200) {
      heic2any({
        blob: xhr.response,
        toType: 'image/jpeg',
      })
        .then(convertedBlob => {
          const convertedImageUrl = URL.createObjectURL(convertedBlob);
          callback(null, convertedImageUrl);
        })
        .catch(error => {
          callback(error);
        });
    } else {
      callback(new Error('Failed to fetch image'));
    }
  };

  xhr.onerror = function () {
    callback(new Error('Network error occurred'));
  };

  xhr.send();
};

/**
 * Convert a HEIC image URL to a JPEG URL using XMLHttpRequest and the heic2any library.
 *
 * @param {string} image - The URL of the HEIC image to be converted.
 * @return {Promise} A promise that resolves to the URL of the converted JPEG image,
 * or rejects with an error.
 */
export const convertHEICImagePromise = image =>
  new Promise((resolve, reject) =>
    convertHEICToJPEGfromUrl(image, (error, convertedImageUrl) => {
      if (error) {
        resolve(image);
      } else {
        resolve(convertedImageUrl);
      }
    }),
  );

/**
 * Converts HEIC image URLs to JPEG URLs asynchronously.
 *
 * @param {Array} urls - An array of image URLs.
 * @returns {Promise} A promise that resolves to an array of converted image URLs.
 */
export const convertHEICUrlToJPEGUrl = async (urls = []) => {
  const resultUrls = [...urls];

  const heicImages = _.uniq(urls.filter(item => HEIC_IMAGE_TYPES.some(type => item.includes(type))));
  const convertedImages = heicImages.length > 0 ? await Promise.all(heicImages.map(convertHEICImagePromise)) : [];

  return resultUrls.map(item => {
    if (heicImages.includes(item)) {
      const resultIndex = heicImages.findIndex(item2 => item2 === item);
      return convertedImages[resultIndex];
    }
    return item;
  });
};

export const formatSameYearWithTime = date => {
  const currentYear = moment().year();
  return moment(date).year() === currentYear
    ? moment(date).format('hh:mm A MMM D')
    : moment(date).format('hh:mm A MMM D, YYYY');
};

export const clearInlineStylesAndClasses = htmlString => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  const elementsWithStyles = tempDiv.querySelectorAll('[style]');
  elementsWithStyles.forEach(function (element) {
    element.removeAttribute('style');
  });

  const elementsWithClasses = tempDiv.querySelectorAll('[class]');
  elementsWithClasses.forEach(function (element) {
    element.removeAttribute('class');
  });

  const elementsWithIds = tempDiv.querySelectorAll('[id]');
  elementsWithIds.forEach(function (element) {
    element.removeAttribute('id');
  });

  return tempDiv.innerHTML;
};

export const clearSelection = () => {
  const sel = window.getSelection ? window.getSelection() : document.selection;

  if (sel) {
    if (sel.removeAllRanges) {
      sel.removeAllRanges();
    } else if (sel.empty) {
      sel.empty();
    }
  }
};

export const capitalizeFirstChar = str => {
  const newStr = str || '';
  if (!newStr.trim()) return '';

  return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};
