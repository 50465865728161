import React, { useState } from 'react';
import _ from 'lodash';
import * as S from './styles';
import TextEditable from 'shared/TextEditable';

const LIMIT_LENGTH = 90;

function NameFormItem(props) {
  const [name, setName] = useState(props.name || '');
  const [showError, setShowError] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const onChange = value => {
    if (value.length <= LIMIT_LENGTH) {
      setName(value);
      props.onChange(value);
    } else {
      flashError();
    }
  };

  const flashError = _.debounce(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 200);
  }, 300);

  const onBlur = () => {
    setFocused(false);
  };

  const onFocus = () => {
    setFocused(true);
  };

  return (
    <S.Wrapper hasError={!name || name.length > LIMIT_LENGTH || showError}>
      <TextEditable
        value={name}
        onChange={onChange}
        className=""
        placeholder="Enter package name"
        type="text"
        maxLength={LIMIT_LENGTH}
        onBlur={onBlur}
        onFocus={onFocus}
        invalid={!name || name.length > LIMIT_LENGTH || showError}
      />
      {isFocused && name.length > LIMIT_LENGTH - 10 && (
        <div className="nameInput__warning">
          <span className="nameInput__warning--label">Characters:</span>
          <span className="nameInput__warning--text">
            {' '}
            {name.length}/{LIMIT_LENGTH}
          </span>
        </div>
      )}
    </S.Wrapper>
  );
}

export default NameFormItem;
