import React from 'react';
import get from 'lodash/get';
import { components } from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleModal } from 'actions/modal';
import SurveyDetailModal from '../../SurveyDetailModal';
import { ReactComponent as PlusIcon } from 'assets/icons/add-asset-plus-hover-icon.svg';

const CustomMenu = props => {
  const inputValue = get(props, 'selectProps.inputValue', '');

  const handleCreate = () => {
    if (!get(props, 'toggleModal')) return;
    props.toggleModal(
      true,
      <SurveyDetailModal
        isCreateNew
        isShowBack={false}
        onClose={() => {}}
        onChangeSurvey={get(props, 'selectProps.onChangeSurvey')}
      />,
    );
  };

  return (
    <components.Menu {...props}>
      {!inputValue.trim() && (
        <button className="create__action" onClick={handleCreate}>
          <span className="create__action-icon">
            <PlusIcon />
          </span>
          <span>Create new survey</span>
        </button>
      )}
      {props.children}
    </components.Menu>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { surveyContact },
  } = state;

  return {
    surveyList: get(surveyContact, 'surveyList', { total: 0, data: [] }),
    loading: get(surveyContact, 'loading', false),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomMenu);
