import React, { useCallback, useRef, useState, useMemo } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import { NewSearchInput } from 'shared/SearchInput';
import { Radio } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import classNames from 'classnames';

import * as S from './style';
import { ReactComponent as ClientIcon } from 'assets/icons/client-icon.svg';
import LoadingIndicator from 'shared/LoadingIndicator';
import { getUserShortName } from 'utils/commonFunction';
import { getNotificationClientsByParams } from 'redux/notification/actions';

const ClientFilter = props => {
  const {
    filterClient,
    onFilterClient,
    clients,
    getNotificationClientsByParams,
    loading,
    total,
    disable,
    afterSelect,
  } = props;
  const lastScrollTopRef = useRef(0);
  const queryParamsRef = useRef({
    page: 1,
    text_search: '',
  });
  const [open, setOpen] = useState(false);

  const hiddenScrollBar = useMemo(
    () => (isEmpty(queryParamsRef.current.text_search) ? clients.length <= 4 : clients.length <= 3),
    [clients, queryParamsRef.current.text_search],
  );

  const handleSelectClient = (client = null) => {
    if (get(client, '_id') !== get(filterClient, '_id')) {
      onFilterClient(client);
      afterSelect && afterSelect();
    }
    handleClose();
  };

  const handleSearchClient = (event, { value }) => {
    queryParamsRef.current = {
      ...queryParamsRef.current,
      text_search: value,
      page: 1,
    };
    getNotificationClientsByParams(queryParamsRef.current);
  };

  const searchDebounce = debounce(handleSearchClient, 300);

  const handleScrollEnd = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    const isEnd = size(clients) === total;

    if (!isEnd && !loading && scrollTop > lastScrollTopRef.current) {
      const bottom = scrollHeight - (scrollTop + clientHeight);

      if (bottom < 150) {
        queryParamsRef.current = {
          ...queryParamsRef.current,
          page: queryParamsRef.current.page + 1,
        };
        getNotificationClientsByParams && getNotificationClientsByParams(queryParamsRef.current);
      }
    }

    lastScrollTopRef.current = scrollTop;
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const handleToggle = useCallback(() => {
    if (disable) return;
    if (!open) {
      queryParamsRef.current = {
        page: 1,
        text_search: '',
      };
      getNotificationClientsByParams();
    }
    setOpen(it => !it);
  }, [open, queryParamsRef.current, disable]);

  const handleClose = () => {
    setOpen(false);
    lastScrollTopRef.current = 0;
  };

  return (
    <RootCloseWrapper onRootClose={handleClose} event="click" disabled={!open}>
      <S.Wrapper>
        <div className={classNames('filter__dropdown', { active: open, disable: disable })} onClick={handleToggle}>
          {filterClient ? (
            <S.SelectedClient>
              <Avatar
                name={getUserShortName(filterClient)}
                className="client-detail-avatar"
                size="24"
                src={get(filterClient, 'avatar')}
                color={get(filterClient, 'color')}
              />
              <S.ClientName className="client-detail-name">{get(filterClient, 'full_name')}</S.ClientName>
            </S.SelectedClient>
          ) : (
            <div className="filter__dropdown__title">
              <span className="filter-name">Client</span>
              <span className="colons">:</span>
              <span className="selected">All</span>
            </div>
          )}
        </div>
        {open && (
          <S.MenuContainer>
            <S.SearchBar>
              <NewSearchInput
                placeholder="Search by name"
                onChange={searchDebounce}
                onClearSearch={() => handleSearchClient(null, { value: '' })}
                autoFocus
              />
            </S.SearchBar>
            <S.ClientsWrapper
              onScroll={event => {
                event.persist();
                onScrollDebounce.call(null, event);
              }}
              hiddenScrollBar={hiddenScrollBar}
            >
              {!isEmpty(clients) && (
                <>
                  {isEmpty(queryParamsRef.current.text_search) && (
                    <S.SelectClientOption onClick={() => handleSelectClient()} isSelected={!filterClient}>
                      <S.AvatarClient>
                        <ClientIcon className="client-icon" />
                        <S.AllClientLabel>All clients</S.AllClientLabel>
                      </S.AvatarClient>
                      <S.RadioButton>
                        <Radio checked={!filterClient} />
                      </S.RadioButton>
                    </S.SelectClientOption>
                  )}
                  {clients.map(client => (
                    <S.SelectClientOption
                      key={get(client, '_id')}
                      onClick={() => handleSelectClient(client)}
                      isSelected={get(filterClient, '_id') === get(client, '_id')}
                    >
                      <S.AvatarClient>
                        <Avatar
                          name={getUserShortName(client)}
                          className="client-detail-avatar"
                          size="32"
                          src={get(client, 'avatar')}
                          color={get(client, 'color')}
                        />
                        <S.ClientName>{get(client, 'full_name')}</S.ClientName>
                      </S.AvatarClient>
                      <S.RadioButton>
                        <Radio checked={get(filterClient, '_id') === get(client, '_id')} />
                      </S.RadioButton>
                    </S.SelectClientOption>
                  ))}
                </>
              )}
              {isEmpty(clients) && !loading && (
                <S.EmptySearch>
                  {queryParamsRef.current.text_search ? 'No results found.' : 'No clients found.'}
                </S.EmptySearch>
              )}
              {loading && (
                <S.LoadingBox>
                  <LoadingIndicator className="client-loading" />
                </S.LoadingBox>
              )}
            </S.ClientsWrapper>
          </S.MenuContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

const mapStateToProps = state => {
  return {
    clients: get(state, 'rootReducer.notification.clients.list', []),
    loading: get(state, 'rootReducer.notification.clients.loading', false),
    per_page: get(state, 'rootReducer.notification.clients.per_page', 20),
    total: get(state, 'rootReducer.notification.clients.total', 0),
    page: get(state, 'rootReducer.notification.clients.page', 1),
  };
};

const mapDispatchToProps = dispatch => ({
  getNotificationClientsByParams: bindActionCreators(getNotificationClientsByParams, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientFilter);
