import styled from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';

const baseText = `
  font-family: Open Sans;
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  color: #202353;
`;

export const InfoModal = styled(ConfirmModal)`
  &.ui.modal {
    &.confirm-popup-container {
      border-radius: 8px;
      width: 467px !important;
      .confirm-content-header {
        padding: 30px 30px 0;
        .confirm-header-label {
          ${baseText}
        }
        .confirm-header-image {
          width: 25px;
          height: 25px;
        }
      }
      .confirm-content-body {
        padding: 25px 30px;
        .confirm-content-label {
          ${baseText}
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
    .ui.button {
      &.confirm-no-button {
        ${baseText}
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: #777777;
        min-width: 89px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e1e1ea;
        height: 30px;
        &:hover {
          background-color: #f5f7f9 !important;
        }
      }
      &.confirm-yes-button {
        ${baseText}
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        min-width: 93px;
        color: #fff;
        background: rgba(81, 88, 207, 1);
        box-shadow: none;
        margin-left: 8px !important;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        &:hover {
          background: rgba(81, 88, 207, 0.9);
        }
      }
    }
    &.unpublish-meal-plan {
      .ui.button {
        &.confirm-yes-button {
          min-width: 110px;
          background: rgba(255, 190, 73, 1);
          &:hover {
            background: rgba(255, 190, 73, 0.9);
          }
        }
      }
      .confirm-content-header {
        p {
          padding-left: 35px;
        }
        .confirm-header-image {
          width: 35px;
          height: 35px;
          position: absolute;
          left: 24px;
        }
      }
    }
    &.edit-meal-plan {
      .ui.button {
        &.confirm-yes-button {
          min-width: 80px;
          background: rgba(81, 88, 207, 1);
          &:hover {
            background: rgba(81, 88, 207, 0.9);
          }
        }
      }
    }
    &.cancel-meal-plan {
      .ui.button {
        &.confirm-yes-button {
          min-width: 146px;
          background: rgba(234, 49, 74, 1);
          &:hover {
            background: rgba(234, 49, 74, 0.9);
          }
        }
      }
    }
  }
`;
