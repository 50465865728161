import styled from 'styled-components';

export const Filter = styled.div`
  padding: 24px 24px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  button {
    width: 100%;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #636a73;
    text-align: center;
    cursor: pointer;
    height: 30px;
    border-radius: 4px;
    &.selected {
      background-color: rgba(94, 89, 255, 0.1);
      color: #5e59ff;
    }
  }
`;
