import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
`;

export const Wrapper = styled.div`
  .text-editable__input {
    ${baseText}
    line-height: 23px;
  }
  .text-editable__container {
    border: 1px solid #e1e1ea;
    pointer-events: unset;
    min-height: 158px;
    overflow-y: scroll;
    padding: 9px;
  }
  .text-editable__container--active {
    border: 1px solid #5158cf;
  }
  .text-editable__input:empty::before {
    ${baseText}
    line-height: 23px;
    color: #a3a3b5;
    opacity: 1;
  }
  .text-editable__container:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
    background-color: transparent;
  }
  .text-editable-wrapper {
    position: relative;
    .close-icon {
      position: absolute;
      top: -9px;
      right: -9px;
      width: 18px;
      height: 18px;
      display: none;
      cursor: pointer;
      g path:last-child {
        fill: #a3a3b5;
      }
    }
  }
  .action-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .desc-text {
      ${baseText}
      font-size: 12px;
      line-height: 21px;
      color: #7b7e91;
    }
  }
  ${({ hasError }) =>
    hasError &&
    css`
      .text-editable__container {
        border: 1px solid #ea314a;
      }
    `}

  ${({ isViewMode }) =>
    isViewMode &&
    css`
      .text-editable__container--active {
        border: 1px solid #e1e1ea;
      }
    `}

  ${({ allowRemoveText }) =>
    allowRemoveText &&
    css`
      .text-editable-wrapper {
        &:hover {
          .text-editable__container {
            border: 1px solid #5158cf;
          }
          .close-icon {
            display: block;
          }
        }
      }
    `}
`;
