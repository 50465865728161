import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  gap: 4px;
  background: #f1f1f1;
  border-radius: 4px;
  .tag-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
  }
  &.is-deleted {
    background: #dce2f0;
  }
`;
