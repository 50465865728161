import truncate from 'lodash/truncate';
import React, { useEffect, useState } from 'react';
import * as S from '../style';

const limitLength = 20;

function OnDemandSettingInput(props) {
  const [name, setName] = useState(props.name || '');
  const [showError, setShowError] = useState(false);
  const [focused, setFocused] = useState(false);
  const renderName = name || [];

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  const onChange = e => {
    const { value } = e.target;
    if (value.length <= limitLength) {
      setName(value);
      value !== name && props.onConfirmChange(value, 'typing');
      setShowError(value.length === limitLength);
    }
  };

  const onBlur = () => {
    props.onConfirmChange(name);
    setFocused(false);
    props.checkFocus(false);
    setShowError(false);
  };

  const onFocus = e => {
    const { value } = e.target;
    setFocused(true);
    props.checkFocus(true);
    setShowError(value.length === limitLength);
  };

  const onKeyPress = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  const handleOnMouseOut = e => {
    props.onConfirmChange(name);
  };

  const handlePaste = e => {
    if (!e.clipboardData || !e.clipboardData.getData) {
      return;
    }

    const pastedText = e.clipboardData.getData('text');
    const newValue = truncate(pastedText, { length: limitLength, omission: '' });

    if (newValue !== name) {
      setName(newValue);
      props.onConfirmChange(newValue, 'typing');
      setShowError(newValue.length === limitLength);
    }
  };

  return (
    <S.SettingInputWrapper focused={focused} showError={showError}>
      <input
        value={name}
        onChange={onChange}
        placeholder="On-demand"
        onBlur={onBlur}
        onFocus={onFocus}
        className="nameInput__editor"
        onKeyPress={onKeyPress}
        onMouseOut={handleOnMouseOut}
        onPaste={handlePaste}
      />
      {renderName.length >= 15 && focused && (
        <div className="nameInput__warning">
          <span className="nameInput__warning--text">
            {renderName.length}/{limitLength}
          </span>
        </div>
      )}
    </S.SettingInputWrapper>
  );
}

export default OnDemandSettingInput;
