import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import get from 'lodash/get';

import { hexToRgbaWithOpacity } from 'utils/commonFunction';
import { DAY_FORMAT } from 'constants/time-format';

import { Container, UpcomingEvent } from './style';

const YMD_FORMAT = 'YYYY-MM-DD';

export default function ClientMealPlanCalendar(props) {
  const {
    selectedDay,
    selectedClient,
    onChangeDay,
    getClientMealPlanByMonth,
    clientTimezone,
    coachTimezone,
    assignedMealPlanClientsByRange = [],
    manualMealClients = [],
    setCurrentMonth,
    mealPlanScheduling,
    isOpen,
  } = props;
  const momentLocal = moment.locale();
  moment.locale();
  const [currentDate, setCurrentDay] = useState(moment(selectedDay).tz(clientTimezone));
  const [mealPlanPerWeek, setMealPlanPerWeek] = useState([]);
  const [isHoveringMealName, setIsHoveringMealName] = useState(false);
  const [shouldUpdateUpcomingEvent, setShouldUpdateUpcomingEvent] = useState(false);

  const weekBlockedMM = useMemo(() => {
    return mealPlanScheduling !== -1
      ? moment(moment().add(mealPlanScheduling, 'week').format(YMD_FORMAT), YMD_FORMAT).startOf('week')
      : null;
  }, [mealPlanScheduling]);

  useEffect(() => {
    splitMealRangePerWeek();
  }, [assignedMealPlanClientsByRange]);

  useEffect(() => {
    if (selectedDay.unix() !== currentDate.unix()) {
      fetchMealPlanMonth(selectedDay);
    }
  }, [selectedDay]);

  useEffect(() => {
    return () => {
      moment.locale(momentLocal);
    };
  }, []);

  const sliceRangeToPerWeek = arr => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      const from = moment(arr[i].day);
      const to = moment(arr[i].end_day);
      const range = to.diff(from, 'days') + 1;
      const rowNumber = range / 7;

      if (rowNumber > 1) {
        for (let rangeIndex = 0; rangeIndex < rowNumber; rangeIndex++) {
          const startRange = from.clone().add(rangeIndex, 'week');
          const endRange = from
            .clone()
            .add(rangeIndex + 1, 'week')
            .subtract(1, 'day');
          const mealWeek = {
            ...arr[i],
            day: startRange.format(YMD_FORMAT),
            end_day: endRange.format(YMD_FORMAT),
          };
          res.push(mealWeek);
        }
      } else {
        res.push(arr[i]);
      }
    }
    return res;
  };

  const splitMealRangePerWeek = () => {
    const range = sliceRangeToPerWeek(assignedMealPlanClientsByRange);
    setMealPlanPerWeek(range);
  };

  const onSelectDate = date => {
    const newDate = moment(date.format(YMD_FORMAT), YMD_FORMAT);

    // prevent click when user remove upcoming event dom
    if (weekBlockedMM && newDate.unix() >= weekBlockedMM.unix()) {
      return;
    }

    const startDateMoment = moment(selectedDay);

    if (!date.isSame(startDateMoment, 'day')) {
      onChangeDay(date);
    }
  };

  const handleMouseEnterMealName = () => {
    setIsHoveringMealName(true);
  };

  const handleMouseLeaveMealName = () => {
    setIsHoveringMealName(false);
  };

  const fetchMealPlanMonth = async startDay => {
    try {
      setCurrentDay(startDay);
      const dayCloned = startDay.clone().set('D', 1);
      await getClientMealPlanByMonth({
        client: selectedClient._id,
        from_day: dayCloned.clone().subtract(7, 'd').format(DAY_FORMAT.YYYY_MM_DD),
        to_day: dayCloned.clone().add(1, 'month').subtract(1, 'day').add(7, 'd').format(DAY_FORMAT.YYYY_MM_DD),
      });
    } catch (error) {}
  };

  const handleNavigateBack = () => {
    setCurrentMonth(moment(currentDate).subtract(1, 'month'));
    fetchMealPlanMonth(moment(currentDate).subtract(1, 'month'));
    setShouldUpdateUpcomingEvent(s => !s);
  };

  const handleNavigateForward = () => {
    setCurrentMonth(moment(currentDate).add(1, 'month'));
    fetchMealPlanMonth(moment(currentDate).add(1, 'month'));
    setShouldUpdateUpcomingEvent(s => !s);
  };

  const handleClickLabel = e => {
    e.stopPropagation();
  };

  const renderDay = (props, currentDate) => {
    let className = props.className || '';
    // reset time of current date to 0:0:0
    const newCurrentDate = moment(moment(currentDate).format(YMD_FORMAT), YMD_FORMAT);

    const startDayInRange = mealPlanPerWeek.find(item => {
      const sDayItem = moment(item.day);
      return newCurrentDate.format(YMD_FORMAT) === sDayItem.format(YMD_FORMAT);
    });

    if (weekBlockedMM && newCurrentDate.unix() >= weekBlockedMM.unix()) {
      className += ' week-blocked';
    }

    if (currentDate.isSame(moment(selectedDay), 'day')) {
      className += ' daySelected';
    }

    if (isHoveringMealName) {
      className += ' hoveringMealName';
    }

    const day = currentDate.format('D');
    let backgroundColor = '#5158CF';

    if (startDayInRange) {
      className += ' rangeStart';
      backgroundColor = startDayInRange.color || backgroundColor;
    }
    backgroundColor = hexToRgbaWithOpacity(backgroundColor, 0.4);

    const mealManual = manualMealClients.findIndex(
      item => item && item.day === moment(currentDate).tz(coachTimezone).format(DAY_FORMAT.YYYY_MM_DD),
    );

    const itemWithCurrentDate = (mealPlanPerWeek || []).find(item => {
      const { day, end_day } = item || {};
      const startDate = moment(day);
      const endDate = moment(end_day);
      return currentDate.isBetween(startDate, endDate, null, '[]');
    });

    // Handle removing the dot manually when the add-on meal plan is lost
    const mealManualByDate = manualMealClients.find(
      item => item && item.day === moment(currentDate).tz(coachTimezone).format(DAY_FORMAT.YYYY_MM_DD),
    );
    const hasAtLeastOneEnabledRecipe = get(mealManualByDate, 'meal_group', []).some(item =>
      get(item, 'recipes', []).some(recipe => !recipe.is_disable),
    );

    const isToday = moment().tz(clientTimezone).format('MM-DD-YYYY') === moment(currentDate).format('MM-DD-YYYY');
    if (isToday) {
      className += ' rdtTodayTz';
    }

    return (
      <td {...props} className={className}>
        <div className="content">
          <span className="day">
            {!!(mealManual !== -1) && !itemWithCurrentDate && hasAtLeastOneEnabledRecipe && (
              <span className="day-recipe"></span>
            )}
            {day}
          </span>
          {!!startDayInRange && (
            <>
              <span
                className="meal-name-wrapper"
                style={{ backgroundColor }}
                onClick={handleClickLabel}
                onMouseEnter={handleMouseEnterMealName}
                onMouseLeave={handleMouseLeaveMealName}
              >
                <span className="meal-name">{startDayInRange.name}</span>
              </span>
            </>
          )}
        </div>
      </td>
    );
  };

  useEffect(() => {
    fetchMealPlanMonth(currentDate);
  }, []);

  useEffect(() => {
    const daysTable = document.querySelector('.rdtPicker .rdtDays tbody') || { style: {} };
    if (mealPlanScheduling === -1) {
      daysTable.style.opacity = 1;
      return;
    }

    const weekBlockedElm = document.querySelector('.rdtDay.week-blocked');
    const upcomingEventElm = document.getElementById('upcoming-event');

    if (!weekBlockedElm || !upcomingEventElm) {
      if (upcomingEventElm) {
        upcomingEventElm.style.visibility = 'hidden';
      }
      daysTable.style.opacity = 1;
      return;
    }

    const { bottom: upcomingEventBottom } = upcomingEventElm.getBoundingClientRect();
    const { top: topWeek } = weekBlockedElm.getBoundingClientRect();

    const height = upcomingEventBottom - topWeek;
    const finalHeight = height < 0 ? 0 : height >= 244 ? 256 : height;

    upcomingEventElm.style.height = finalHeight + 'px';
    upcomingEventElm.style.visibility = finalHeight === 0 ? 'hidden' : 'visible';
    daysTable.style.opacity = 1;
  }, [mealPlanScheduling, shouldUpdateUpcomingEvent, currentDate, isOpen]);

  const getDayIndex = selectedDay => {
    const abbreviatedDay = (selectedDay || '').format('ddd');
    const dayIndex = moment.weekdaysShort().indexOf(abbreviatedDay);
    return dayIndex === 0 ? 7 : dayIndex;
  };

  return (
    <Container dayIndex={getDayIndex(selectedDay)}>
      <Datetime
        open
        value={currentDate}
        timeFormat={false}
        onChange={onSelectDate}
        renderDay={renderDay}
        onNavigateBack={handleNavigateBack}
        onNavigateForward={handleNavigateForward}
      />
      {mealPlanScheduling >= 0 && (
        <UpcomingEvent id="upcoming-event">
          <p>Upgrade to view upcoming weeks</p>
        </UpcomingEvent>
      )}
    </Container>
  );
}
