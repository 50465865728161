// Libs
import React, { useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { RootCloseWrapper } from 'react-overlays';
import get from 'lodash/get';
import omit from 'lodash/omit';

// Components
import UnitSelect from 'components/IngredientLibrary/Parts/UnitSelect';

// Helpers
import { convertUnitName } from 'components/IngredientLibrary/helpers';
import { validValueStringIngredient } from 'components/RecipeDetail/helper';

// utils
import { pluralize, roundNumberBodyMetric } from 'utils/commonFunction';

// Assets
import { ReactComponent as DeleteIcon } from 'assets/icons/close_icon_cicre.svg';
import defaultImg from 'assets/icons/Ingredients/default_ingredient_image.png';

// Style
import * as S from './style';

const IngredientItem = props => {
  const {
    isDragging = false,
    id = '',
    isEdit = true,
    src,
    name,
    handleRemove = () => {},
    unit = {},
    handleChangeUnit = () => {},
    value = '',
    handleChangeValueUnit = () => {},
    titleUnit = '',
    listUnitIngredient = {},
    isAddNew,
    handleSubmitted = () => {},
    listIngredient = [],
    listUnitAutoCalculated = {},
    autoCalculated = false,
  } = props;

  const [isError, setIsError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const hasError = isError || (!(value && validValueStringIngredient(value)) && !isAddNew);

  const convertNameListUnit = autoCalculated ? listUnitAutoCalculated : convertUnitName(listUnitIngredient);

  const unitType = useMemo(() => {
    let result = '';
    const unitGroup = get(unit, 'unit_group', '');
    if (unitGroup === 'other') {
      result = unitGroup;
    } else {
      result = get(unit, 'unit_type', '');
    }
    return pluralize(result);
  }, [unit]);

  const valueDetail = useMemo(() => {
    const numericValue = Number(value);
    return !isNaN(numericValue) ? roundNumberBodyMetric(numericValue) : value;
  }, [value]);

  useEffect(() => {
    if (value && isError) {
      setIsError(false);
    }
  }, [value]);

  const handleBlurInput = () => {
    const check = value && validValueStringIngredient(value);
    handleSubmitted({
      ingredients: listIngredient.map(item => {
        if (item._id === id) {
          return omit(item, ['isAddNew']);
        }
        return item;
      }),
    });
    return setIsError(!check && isFocused);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  return (
    <S.IngredientItemWrapper className="ingredient-item-wrapper" isEdit={isEdit} isDragging={isDragging}>
      {isEdit && <S.MoveIconWrapper />}
      <S.Thumbnail src={[src, defaultImg]} />
      <S.Content className="content">
        <S.Name>{name}</S.Name>
        {isEdit ? (
          <S.FormWrapper>
            <S.Form>
              <RootCloseWrapper>
                <S.UnitInputWrapper
                  error={hasError}
                  value={value}
                  onChange={handleChangeValueUnit}
                  onFocus={handleOnFocus}
                  onBlur={handleBlurInput}
                />
              </RootCloseWrapper>
              <S.UnitWrapper>
                <UnitSelect
                  label=""
                  data={convertNameListUnit}
                  heightSelect="30px"
                  menuPositionHorizontal="right"
                  value={get(unit, '_id', '')}
                  acronymSelect
                  isObjectSubmit
                  onChangeUnit={handleChangeUnit}
                  unitType={unitType}
                  oldName={get(unit, 'formula', '')}
                />
              </S.UnitWrapper>
            </S.Form>
          </S.FormWrapper>
        ) : (
          <S.Value>
            {valueDetail} {titleUnit}
          </S.Value>
        )}
      </S.Content>
      {isEdit && (
        <S.Remove
          data-tip
          data-iscapture="true"
          data-for={`tooltip-remove-ingredient-${id}`}
          onClick={() => handleRemove(id)}
        >
          <DeleteIcon />
          <ReactTooltip
            className="tooltip-remove-ingredient"
            id={`tooltip-remove-ingredient-${id}`}
            effect="solid"
            place="top"
            delayShow={100}
          >
            Remove Ingredient
          </ReactTooltip>
        </S.Remove>
      )}
    </S.IngredientItemWrapper>
  );
};

export default IngredientItem;
