import S3Images from 'shared/S3Images';
import styled, { css } from 'styled-components';

const limitText = `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  word-break: break-word;
`;

const textBase = `
    font-family: 'Open Sans';
    color: #202353;
    font-style: normal;
    line-height: 150%;
`;

export const MainPanelHeaderWrapper = styled.div`
  width: 100%;
  padding: 20px 30px 0;
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const HeaderInput = styled.input`
  outline: unset;
  padding: 0 10px;
  line-height: 40px !important;
  ${textBase}
  font-size: 21px;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 5px;
  background: transparent;
  min-width: 265px;
  &:focus,
  &:hover {
    border: 1px solid #5158cf;
    background: #fff;
  }
`;

export const HeaderButton = styled.div`
  height: 42px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0 10px;
  min-width: 265px;
  width: fit-content;
  .truncate {
    ${textBase}
    font-size: 21px;
    font-weight: 600;
    line-height: 40px;
    ${limitText}
  }
  &:hover {
    ${({ isSystem }) =>
      !isSystem &&
      css`
        cursor: text;
        border-color: #5158cf;
        background: #fff;
      `}
  }

  ${props =>
    props.isSystem &&
    css`
      min-width: unset;
      cursor: not-allowed;
      user-select: none;
    `}
`;

export const TotalNutrition = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalNutritionItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${textBase}
  font-size: 12px;
  font-weight: 600;
  padding: 10px 8px;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #a3a3b5;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`;

export const NutritionLabelItem = styled.div`
  margin-right: 5px;
`;

export const NutritionValueItem = styled.div`
  margin-right: 3px;
  ${props =>
    props.isEmpty &&
    css`
      color: #a3a3b5;
    `}
`;

export const MainPanelContentWrapper = styled.div`
  height: 100%;
`;

export const ContentContainer = styled.div`
  height: calc(100% - 193px);
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
  padding: 20px 26px 20px 40px;
  margin-right: 10px;
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e1e1ea !important;
  }
`;

export const ContentEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 99px 0;

  .blank-icon {
    width: 40px;
    height: 40px;
  }
`;

export const ContentEmptyTitle = styled.div`
  ${textBase}
  color: #7b7e91;
  font-size: 15px;
  font-weight: 400;
`;

export const MainPanelSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.06);
  animation: custom-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes custom-pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  .skeleton-thumbnail {
    flex-shrink: 0;
    width: 150px;
    height: 100px;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
  .skeleton-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .skeleton-name {
    width: 70%;
    height: 20px;
    background: #f8f8f8;
    border-radius: 5px;
  }
  .skeleton-macro-nutrient {
    width: 60%;
    height: 18px;
    background: #f8f8f8;
    border-radius: 5px;
  }
  .skeleton-description {
    width: 90%;
    height: 16px;
    background: #f8f8f8;
    border-radius: 5px;
  }
  .skeleton-dietary-list {
    display: flex;
    gap: 5px 7px;
  }
  .skeleton-dietary {
    width: 80px;
    height: 26px;
    background: #f8f8f8;
    border-radius: 100px;
  }
`;

export const HeaderTitleSkeleton = styled.div`
  width: 243px;
  height: 40px;
  background: #f0f0f2;
  border-radius: 5px;
  animation: custom-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes custom-pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
`;
