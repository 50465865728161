import React from 'react';

import * as S from './style';
import ReferralForm from './ReferralForm';
import HowItWorkReferral from './HowItWorkReferral';

export default class ReferralProgram extends React.Component {
  render() {
    return (
      <S.Wrapper>
        <ReferralForm />
        <HowItWorkReferral />
      </S.Wrapper>
    );
  }
}
