import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: #48C98C;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 81px;
    height: 81px;
    position: relative;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: #48C98C;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #48C98C;
    border: none;
    background-color: #fff;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 4px #48C98C;
    }
  }

`;

export default function(props) {
  return (
    <Wrapper>
      <svg class="checkmark" width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle class="checkmark__circle"  cx="40.5" cy="40.5" r="38.5" stroke="#48C98C" stroke-linecap="round"/>
        <path class="checkmark__check" d="M27 41.0683L35.6337 50L54 31" stroke="#48C98C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </Wrapper>
  );
}