import React, { useMemo } from 'react';
import { cloneDeep, get, isObject, uniqBy } from 'lodash';

import { POST_TYPES } from 'components/CommunityForum/constants';

import { LeaderboardContainer } from './style';
import AddLeaderboard from './AddLeaderboard';
import LeaderboardDetail from './LeaderboardDetail';
import UpgradePathAutoflow from './UpgradePathAutoflow';
import PopUpForm from '../CreatePostForm/components/PopUpForm';
import { convertUnit, roundUp } from 'utils/commonFunction';

export default function component({
  push,
  group,
  autoflowGetLeaderboardClients,
  rankingTypes,
  connected_autoflow,
  connectedLeaderboard,
  toggleModal,
  match,
  user,
  permission,
  leaderboardSetting,
}) {
  const leaderboardData = cloneDeep(get(connectedLeaderboard, '[0]', null));

  const open = url => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const handleConnectAutoflow = () => {
    if (!group._id) return;
    push(`/home/forums/${group._id}/settings`);
  };

  const handleCreateLeaderboard = () => {
    if (!get(connected_autoflow, '_id')) return;
    open(`/home/autoflow/${connected_autoflow._id}/leaderboard`);
  };

  const handleShareLeaderboard = () => {
    const leaderboardData = cloneDeep(get(connectedLeaderboard, '[0]', null));
    toggleModal(true, <PopUpForm match={match} postType={POST_TYPES.LEADERBOARD} leaderboard={leaderboardData} />);
  };

  const onLoadClients = requestData => {
    autoflowGetLeaderboardClients({ ...requestData, leaderboardId: leaderboardData._id });
  };

  const handleGoToLeaderboardTab = () => {
    if (!group._id) return;
    push(`/home/forums/${group._id}/leaderboard`);
  };

  const handleGoToLearnMoreAutoflow = () => {
    window.open('https://help.everfit.io/en/articles/3661707-autoflow-overview-how-to-automate-your-training-business');
  };

  const leaderboardClients = useMemo(() => {
    const leaderboardData = cloneDeep(get(connectedLeaderboard, '[0]', null));

    if (leaderboardData) {
      const metricSettingData = leaderboardSetting.find(it => it.unique_code === 'weight');

      const defaultSetting = get(leaderboardData, 'unit_id');
      const setting =
        leaderboardData.ranking_type === 2 && metricSettingData
          ? metricSettingData.unit
          : get(user, 'preferences')['weight'];

      const members = leaderboardData.members ? uniqBy(leaderboardData.members, 'user._id') : [];
      const convertData = members.map(it => {
        it.score = roundUp(
          defaultSetting && isObject(defaultSetting) && setting
            ? roundUp(convertUnit(it.score, defaultSetting, setting), 2)
            : it.score,
          2,
        );
        it.setting = setting;
        return it;
      });

      return convertData;
    }
    return [];
  }, [connectedLeaderboard]);

  return (
    <div>
      {permission.autoflow ? (
        !leaderboardData ? (
          <AddLeaderboard
            onConnectAutoflow={handleConnectAutoflow}
            connected_autoflow={connected_autoflow}
            onCreateLeaderboard={handleCreateLeaderboard}
          />
        ) : (
          <LeaderboardContainer>
            <div className="leaderboard-text">Leaderboard</div>
            {leaderboardData && leaderboardData.members.length > 0 && (
              <div className="share-leaderboard" onClick={handleShareLeaderboard}>
                Share in a post
              </div>
            )}
            <LeaderboardDetail
              leaderboard={leaderboardData}
              clients={{ list: leaderboardClients, isLoaded: true }}
              rankingTypes={rankingTypes.toJS() || []}
              onLoadClients={onLoadClients}
              noActions
              unit={leaderboardData.unit}
              onGoToleaderboardtab={handleGoToLeaderboardTab}
            />
          </LeaderboardContainer>
        )
      ) : (
        <UpgradePathAutoflow onGoToLearnMoreAutoflow={handleGoToLearnMoreAutoflow} />
      )}
    </div>
  );
}
