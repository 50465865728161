import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import {
  createProgramLibrary,
  updateProgramLibrary,
  cloneProgramLibrary,
  removeProgramLibrary,
} from 'redux/program_library/program_library.actionCreators';
import {
  addProgram,
  updateProgram,
  cloneProgram,
  removeProgram,
  toggleFullscreenModal,
} from 'redux/program-library-and-templates/actions';
import { onResetStatus } from 'actions/submitStatus';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { DEFAULT_SUBMIT_STATUS } from 'constants/commonData';

import Component from './component';

const mapStateToProps = state => {
  const { user, isModalOpen, rootReducer, submitStatus } = state;

  return {
    user,
    isModalOpen,
    rootReducer,
    submitStatus: submitStatus.PROGRAM_DETAIL || DEFAULT_SUBMIT_STATUS,
    payment: rootReducer.permission.payment,
    onboarding_flow: rootReducer.permission.onboarding_flow,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetSubmitStatus: () => dispatch(onResetStatus('PROGRAM_DETAIL')),
    createProgramLibrary: bindActionCreators(createProgramLibrary, dispatch),
    updateProgramLibrary: bindActionCreators(updateProgramLibrary, dispatch),
    cloneProgramLibrary: bindActionCreators(cloneProgramLibrary, dispatch),
    removeProgramLibrary: bindActionCreators(removeProgramLibrary, dispatch),
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleFullscreenModal: bindActionCreators(toggleFullscreenModal, dispatch),
    addProgram: bindActionCreators(addProgram, dispatch),
    updateProgram: bindActionCreators(updateProgram, dispatch),
    cloneProgram: bindActionCreators(cloneProgram, dispatch),
    removeProgram: bindActionCreators(removeProgram, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
