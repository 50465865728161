import styled, { css } from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';

const baseText = `
  font-style: normal;
  font-weight: 600;
  color: #000;
`;

export const MainPanel = styled.div`
  position: relative;
  width: 612px;
  ${props =>
    props.compareMode &&
    css`
      width: 876px;
    `}
`;

export const MainPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const MainPanelBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4px;
  @media (max-height: 762px) {
    height: calc(100% - 80px);
    padding: 4px 0 104px;
  }
`;

export const HeaderTitle = styled.div`
  ${baseText}
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.4px;
  margin-top: 4px;
`;

export const ShareButton = styled.button`
  display: flex;
  width: 91px;
  height: 36px;
  padding: 9px 16px;
  align-items: flex-start;
  gap: 8px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #dadfea;
`;

export const PhotoWrapper = styled.div`
  width: 100%;
  height: 100%;
  &:hover .slick-prev,
  &:hover .slick-next {
    opacity: 1;
  }
`;

export const SliderWrapper = styled.div`
  @media (max-height: 762px) {
    height: 100%;
    .slick-slider {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slick-list {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .slick-prev {
    left: 32px;
    opacity: 0;
    ${props =>
      props.isCypress &&
      css`
        opacity: 1;
      `}
  }

  .slick-next {
    right: 32px;
    opacity: 0;
    ${props =>
      props.isCypress &&
      css`
        opacity: 1;
      `}
  }

  .slider-next-arrow,
  .slider-prev-arrow {
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 12px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 99px;
    z-index: 99;

    :before {
      content: none;
    }

    :hover {
      background: rgba(255, 255, 255, 1);
      svg path {
        fill: #5e59ff;
      }
    }

    &.slick-disabled {
      display: none !important;
      ${props =>
        props.isCypress &&
        css`
          display: block !important;
        `}
    }
  }

  .slick-slide {
    opacity: 0;
  }

  .slick-center {
    opacity: 1;
  }

  .slick-cloned {
    opacity: 0.6;
  }
`;

export const DeleteModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 447px !important;
    background-color: #ffffff !important;
    .confirm-actions {
      margin: 8px 0px 0px 0px !important;
    }
  }

  .ui.button.confirm-no-button:hover,
  .ui.button.confirm-no-button:active,
  .ui.button.confirm-no-button:focus {
    background-color: #f5f7f9 !important;
  }

  .ui.button.confirm-yes-button:hover,
  .ui.button.confirm-yes-button:active,
  .ui.button.confirm-yes-button:focus {
    background-color: #ec465c !important;
  }

  .ui.button.confirm-no-button {
    width: 140px !important;
    border-radius: 5px !important;
    padding: 5px 30px;
    font-family: 'Open Sans';
  }
  .ui.button.confirm-yes-button {
    width: 130px !important;
    border-radius: 5px !important;
    padding: 5px 30px;
    box-shadow: none;
    font-family: 'Open Sans';
  }

  .confirm-content-header {
    padding: 30px 30px 0 !important;

    .confirm-header-label {
      color: #000000;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .confirm-content-body {
    padding: 15px 30px 30px !important;

    .confirm-content-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 19.5px;
    }
  }
`;

export const InformationUnitWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
`;
