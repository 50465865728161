import React from 'react';
import _ from 'lodash';
import styles from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ShoeIcon } from 'assets/icons/shoe_orange.svg';
import moment from 'moment';
import { pluralize } from 'utils/commonFunction';

const TooltipWrapper = styles(ReactTooltip)`
  &.__react_component_tooltip {

    &.average-tooltip {
      background-color: #FFFFFF;
      border-radius: 3px;
      padding: 15px;
      color: #202353;
      min-width: unset;
      width: unset;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
    }

    .average-tooltip {
      &__time {
        display: flex;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 11px;
        line-height: 100%;
        text-transform: uppercase;
        white-space: nowrap;

        strong {
          color: #FF4C14;
          margin-left: 5px;
        }

        span {
          margin-left: 5px;
          color: #8F91A9;
        }
      }

      &__value {
        strong {
          font-size: 16px;
          line-height: 100%;
          font-weight: 600;
          color: #202353;
        }

        span {
          font-weight: 600;
          font-size: 13px;
          line-height: 100%;
          color: #8F91A9;
          margin-left: 5px;
        }

      }
    }
  }
`

function AverageStepTooltip({steps, fromDate, toDate, ...props}) {
  let from = moment(fromDate, 'MM-DD-YYYY');
  let to = moment(toDate, 'MM-DD-YYYY');
  if (from.month() === to.month()) {
    from = from.format("MMM DD");
    to = to.format("DD");
  } else {
    from = from.format("MMM DD");
    to = to.format("MMM DD");
  }
  return (
    <TooltipWrapper className="average-tooltip app-tooltip-white" {...props}>
      <div className="average-tooltip__time">
        <ShoeIcon />
        <strong>Average</strong>
        <span>{from} - {to}</span>
      </div>
      <div className="average-tooltip__value">
        <strong>{Number(_.floor(steps, 0) || 0).toSeperateFormat()}</strong>
        <span>{pluralize('step', steps)}</span>
      </div>
    </TooltipWrapper>
  );
}

export default AverageStepTooltip;
