import styled, { css } from 'styled-components';

export const DayCategoryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
`;

export const DayCategoryWrapperEmpty = styled.div`
  height: 60px;
  width: 100%;
  border: 1px dashed #e1e1ea;
  border-radius: 5px;
  opacity: 0.5;

  ${({ isOverDay }) =>
    isOverDay &&
    css`
      border: 1px dashed #ea314a;
    `}
`;

export const MealItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 100%;
  min-height: 122px;
  max-height: 122px;
  padding-top: 10px;
  border: 1px dashed #e1e1ea;
  border-radius: 5px;
  background-color: #f8f8f8;

  .meal-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 10px;
  }

  .meal-name {
    font-size: 11px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    color: #202353;
  }

  .meal-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;

    margin-right: 2.25px;
    margin-bottom: 10px;
    padding: 0 3.75px 0 10px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px !important;
      background: #e1e1ea !important;
    }
  }

  &:has(.meal-container .is-over) {
    border: 1px dashed #5158cf;
  }

  ${({ isOver }) =>
    isOver &&
    css`
      border: 1px dashed #5158cf;
    `}
`;

export const RecipeItem = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  border-radius: 5px;
  background-color: #fff;

  .recipe-background {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
  }

  .drag-container {
    position: absolute;
    top: 5px;
    right: 5px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.7);
    .recipe-drag-icon {
      width: 7.2px;
      height: 7.2px;
    }
  }

  .app-tooltip.recipe-item-tooltip {
    background-color: #333333;
    border-radius: 4px;
    padding: 8px 16px;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;

    &.place-top {
      margin-top: -12px;
    }

    &::after {
      border-top-color: #333333 !important;
      bottom: -8px !important;
    }
  }

  &:hover {
    .drag-container {
      display: flex;
    }
  }

  ${({ isLogged }) =>
    isLogged &&
    css`
      cursor: not-allowed;
    `}

  ${({ isDragging }) =>
    isDragging &&
    css`
      box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
      .drag-container {
        display: flex;
      }
    `}

  ${({ isSortableDragging }) =>
    isSortableDragging &&
    css`
      border: 1px dashed #5158cf;
      .recipe-background,
      .drag-container {
        opacity: 0;
        visibility: hidden;
      }
    `}
`;
