import React from 'react';
import _ from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

import CloseIcon from 'assets/icons/close_circle.svg';
import * as S from './style';
class PopupModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      isDisabledSubmit: false,
      isSubmitted: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { open, itemEditing } = this.props;

    if (open && itemEditing && prevState.name === this.state.name) {
      this.setState({
        name: _.get(this.props, 'itemEditing.name', ''),
        isSubmitted: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = nextProps;

    if (!open) {
      this.setState({ name: '', isSubmitted: false });
    }
  }

  handleChangeName = (e, _name) => {
    const value = e.target.value.replace('  ', ' ');
    const _maxLength = this.props.maxLength || 90;

    if (_maxLength === 0 || value.length <= _maxLength) {
      this.setState({ [_name]: value, shouldShowError: false });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const { name } = this.state;
    const { onSubmit } = this.props;

    let isValidation = true;
    let params = {
      name,
    };

    _.forEach(params, item => {
      if (item.trim().length === 0) isValidation = false;
    });

    if (!isValidation) return;
    if (onSubmit) {
      this.setState({ isDisabledSubmit: true });
      onSubmit(params, this.onCloseForm);
    }
  };

  onCloseForm = () => {
    const { onSetOpen } = this.props;

    this.setState({ isDisabledSubmit: false });
    onSetOpen && onSetOpen(false);
  };

  handleRenderFields = item => {
    const { isSubmitted } = this.state;

    return (
      <S.Input
        label={item.label || 'Title'}
        placeholder={item.placeholder || 'Input title'}
        required={item.required}
        isHideErrorMessage={item.isHideErrorMessage}
        maxLength={item.maxLength}
        inputProps={{ autoFocus: item.autoFocus || false, maxLength: item.maxLength }}
        isSubmitted={isSubmitted}
        value={this.state[item.name]}
        onChange={e => this.handleChangeName(e, item.name)}
      />
    );
  };

  render() {
    const {
      open,
      onSetOpen,
      titlePopup,
      description,
      titleInput,
      placeholderInput,
      submitName,
      maxLength,
    } = this.props;
    const { isDisabledSubmit, name, isSubmitted: isSubmitted } = this.state;
    const _maxLength = maxLength || 0;

    return (
      <S.CreateAddNewModal
        open={open}
        closeIcon={
          <CloseButton className="close-button" onClick={() => onSetOpen(false)}>
            <img src={CloseIcon} />
          </CloseButton>
        }
      >
        <Modal.Header>
          {titlePopup ? titlePopup : 'Create New'}
          {description && <S.Description>{description}</S.Description>}
        </Modal.Header>
        <Modal.Content>
          <S.Form onSubmit={this.handleSubmit}>
            <S.Input
              label={titleInput ? titleInput : 'Title'}
              placeholder={placeholderInput ? placeholderInput : 'Input title'}
              required
              isHideErrorMessage
              maxLength={_maxLength > 0 && _maxLength}
              inputProps={{ autoFocus: true, maxLength: _maxLength > 0 && _maxLength }}
              isSubmitted={isSubmitted}
              value={name}
              onChange={e => this.handleChangeName(e, 'name')}
            />
          </S.Form>
          <S.Footer>
            <Button className="cancel" onClick={() => onSetOpen(false)}>
              Cancel
            </Button>
            <Button className="submit" disabled={isDisabledSubmit} onClick={this.handleSubmit} purple>
              {submitName ? submitName : 'Create New'}
            </Button>
          </S.Footer>
        </Modal.Content>
      </S.CreateAddNewModal>
    );
  }
}

export default PopupModal;
