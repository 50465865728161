import moment from 'moment';

import { calculateMacroNutrients, getMacroNutrients } from 'components/MealPlanDayDetail/constants';

export const formatDate = date => {
  const currentDate = moment();
  const formattedDate = moment(date).format('MMM DD');
  const isInCurrentYear = moment(date).isSame(currentDate, 'year');
  return isInCurrentYear ? formattedDate : moment(date).format('MMM DD, YYYY');
};

export const getMarcoValue = (list, marcoType) => {
  if (list[marcoType] !== undefined) {
    return list[marcoType];
  } else {
    return 0;
  }
};

export const checkShowPaddingAngle = (percentProtein, percentCarbs, percentFat) => {
  const countNonZeros = [percentProtein, percentCarbs, percentFat].filter(value => value !== 0).length;

  return countNonZeros === 2 || countNonZeros === 3;
};

export const getTimeRemain = expiredDate => {
  const expired = moment(expiredDate);
  const current = moment();
  const dayRemain = expired.diff(current, 'days');
  return dayRemain > 1 ? dayRemain + ' days left' : '1 day left';
};

export const getWeekTitle = (day, timezone) => {
  const today = moment().tz(timezone);
  const dayOfWeek = day.clone().tz(timezone);

  const startOfWeek = dayOfWeek.clone().startOf('week');
  const endOfWeek = dayOfWeek.clone().endOf('week');

  const isSameYear = dayCheck => {
    return today.isSame(dayCheck, 'year');
  };

  const startOfWeekString = startOfWeek.format(isSameYear(startOfWeek) ? 'MMM D' : 'MMM D, YYYY');
  const endOfWeekString = endOfWeek.format(isSameYear(endOfWeek) ? 'MMM D' : 'MMM D, YYYY');

  return `${startOfWeekString} - ${endOfWeekString}`;
};

export const getTotalMacroNutrients = (mealGroup = []) => {
  if (!mealGroup.length) return [];

  const list = mealGroup.map(item => ({
    ...item,
    recipes: (item.recipes || []).filter(({ is_disable, is_logged }) => !(is_disable && !is_logged)),
  }));

  if (list.length === 0) {
    return [];
  }
  const macroNutrients = calculateMacroNutrients(list);
  const listNutrients = getMacroNutrients(macroNutrients);

  return listNutrients.map(({ type = '', value = '' }) => ({ type, value }));
};

export const getTotalAveragesMacroNutrients = (data = []) => {
  if (!data.length) return [];

  return data.reduce((acc, { type, value }) => {
    if (!acc[type]) {
      acc[type] = { total: 0 };
    }
    if (value) {
      acc[type].total += value;
    }
    return acc;
  }, {});
};

export const getDaysOfWeek = selectedDate => {
  const startOfWeek = selectedDate.clone().startOf('isoWeek');
  const daysOfWeek = [];

  for (let i = 0; i < 7; i++) {
    const day = startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD');
    daysOfWeek.push(day);
  }

  return daysOfWeek;
};
