import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const SidebarHeightStyle = {
  0: '760px',
  1: '620px',
  2: '450px',
};

const ScrollStyle = css`
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-track {
    background: #fafbfc !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #e6e8eb !important;
    border-radius: 10px !important;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #e6e8eb !important;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 32px 0 12px 95px;
  overflow: auto;
  background: #fafbfc;
`;

export const Container = styled.div`
  width: 1045px;
  margin: auto;
  height: 100%;
  overflow: hidden;
`;

export const HeaderWrapper = styled.div`
  .search-input {
    width: 631px;
    height: 50px;
    margin-right: 200px;
    input {
      background-color: #ffffff;
      border: 1px solid #ecf0f8;
      border-radius: 5px;
      font-size: 12px;
      line-height: 16px;
      width: 631px;
      background-image: url(${CDN_URL}/images/activity_search.svg);
      ::placeholder {
        color: #a6acc6;
        opacity: 0.8;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
  ${ScrollStyle}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 640px;
  position: sticky;
  top: 0;
`;

export const FilterWrapper = styled.div`
  min-width: 242px;
  ${props =>
    css`
      min-height: ${SidebarHeightStyle[props.collapsedNumber]};
    `}
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  border: 1px solid #ecf0f8;
  border-radius: 5px;
  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    content: ' ';
    background-image: url(${CDN_URL}/images/checked_white.svg);
    background-size: 8px 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #5158cf;
    border-radius: 4px;
    width: 15px;
    height: 15px;
  }
  .ui.checkbox input.hidden + label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    padding-left: 27px;
  }
  .ui.checkbox,
  .field .ui.checkbox {
    margin-bottom: 14px;
  }
  .ui.checkbox:last-child {
    margin-bottom: unset;
  }
  .ui.checkbox .box:before,
  .ui.checkbox label:before {
    width: 15px;
    height: 15px;
    top: 1px;
  }
  .ui.checkbox input:focus ~ .box:before,
  .ui.checkbox input:focus ~ label:before {
    border-color: rgba(34, 36, 38, 0.35);
  }
  .ui.checkbox input:checked ~ label:after {
    top: 1px;
  }
`;

export const ListWrapper = styled.div`
  max-height: calc(100% - 140px);
  overflow-y: scroll;
  padding-bottom: 24px;
  padding-right: 5px;
  ${ScrollStyle}
`;

export const Title = styled.h4`
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  color: #202353;
  margin-bottom: 16px;
`;

export const Empty = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #202353;
  opacity: 0.5;
  padding: 85px 0;
  text-align: center;
`;

export const IndicatorWrapper = styled.div`
  display: block;
  position: relative;
  padding-top: 20px;
  .ui.loader:before {
    border-color: #ededed;
  }
  .ui.loader:after {
    border-color: #5158cf transparent transparent;
  }
  span {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    opacity: 0.6;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  gap: 33px;
  margin-bottom: 24px;
  margin-left: 2px;
  position: sticky;
  top: 0;
`;

export const TabItem = styled.div`
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  background: #fafbfc;
  cursor: pointer;
  color: #76778a;
  padding: 4px 0px 6px 0px;

  ${props =>
    props.active &&
    css`
      opacity: 1;
      color: #5158cf;
      font-weight: 700;
      box-shadow: 0px -2px 0px 0px #5158cf inset;
    `}
`;

export const VerticalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  margin-bottom: 12px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  cursor: pointer;

  &.last-item {
    margin-bottom: 0;
  }

  &.hidden-checkbox {
    display: none;
  }

  .ui.checkbox.sub-checkbox input.hidden + label {
    padding-left: 22px;
  }

  .ui.checkbox,
  .field .ui.checkbox {
    margin-bottom: 0;
    width: 100%;

    &.sub-checkbox {
      padding-left: 32px;
    }
  }

  svg {
    margin-top: 5px;
    width: 17px;
    height: 7px;

    ${props =>
      !props.isCollapsed &&
      css`
        transform: rotate(180deg);
      `}

    path {
      stroke: #1c1b1f;
    }
  }
`;
