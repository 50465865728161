import React from 'react';
import _ from 'lodash';

import * as S from '../style';
import * as SettingStyles from './style';
import Upload from '../Upload';
import WorkoutBackground from '../WorkoutBackground';
import { THEMES } from '../constants';
import { SettingLoader, LogoLoader } from '../Loader';
import TodayScreen from './TodayScreen';
import UpgradePath from 'shared/UpgradePath';
import AdvancedCustomizationTooltip from './AdvancedCustomizationTooltip';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const DEFAULT_STATE = {
  splashLogo: { name: '', src: '' },
  workoutBackground: {},
  theme: { ...THEMES[0] },
};

class SettingMobileApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.isSaving = false;
    this.defaultTheme = THEMES[0];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCancelStandard(nextProps);
  }

  handleCancelStandard = data => {
    const { discard, getCustomBrandingData, onChangeDiscard } = data;

    if (discard) {
      getCustomBrandingData().finally(() => {
        this.state = { ...DEFAULT_STATE };
        this.isSaving = false;
        this.defaultTheme = THEMES[0];

        this.props.onCheckSaving(false);
        onChangeDiscard(false);
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedWorkoutBackground, selectedSplashLogo, loading, originalTheme } = this.props;
    const { workoutBackground } = this.state;
    const isDefaultTheme =
      originalTheme.primaryColor.toLowerCase() === '#7470ef' &&
      originalTheme.secondaryColor.toLowerCase() === '#4fdfac';

    if (!loading && prevProps.loading) {
      this.setState({
        splashLogo: { ...selectedSplashLogo },
        workoutBackground: { ...selectedWorkoutBackground },
        theme: { ...originalTheme, default: isDefaultTheme },
      });
    } else if (
      selectedWorkoutBackground._id !== prevProps.selectedWorkoutBackground._id &&
      selectedWorkoutBackground._id !== workoutBackground._id
    ) {
      // Workout background has been deleted
      this.setState({ workoutBackground: { ...selectedWorkoutBackground } });
    }
  }

  onRemoveSplashLogo = () => {
    // const { bucketData } = this.state.splashLogo;
    this.setState({ splashLogo: { ...DEFAULT_STATE.splashLogo } }, () => {
      // if (bucketData) {
      //   removeUploadedFileFromServer(bucketData);
      // }
    });
  };

  onUploadLogoSuccess = ({ src, name, bucketData }) => {
    this.setState({ splashLogo: { ...this.state.splashLogo, name, src, bucketData } });
  };

  onChangeBackground = data => {
    this.setState({ workoutBackground: { ...data } });
  };

  onSelectTheme = event => {
    const { themeId } = event.currentTarget.dataset;
    const selectedTheme = THEMES.find(item => item._id === themeId);

    if (selectedTheme) {
      const { theme } = this.state;

      if (
        selectedTheme.primaryColor.toLowerCase() !== theme.primaryColor.toLowerCase() ||
        selectedTheme.secondaryColor.toLowerCase() !== theme.secondaryColor.toLowerCase()
      ) {
        this.setState({ theme: { ...selectedTheme } });
      }
    }
  };

  onSave = () => {
    if (this.isSaving) {
      return false;
    }

    const { workoutBackground, splashLogo, theme } = this.state;
    const data = {
      splash_logo: !_.isEmpty(splashLogo.bucketData) ? splashLogo.bucketData.url : splashLogo.src,
      splash_logo_name: splashLogo.name,
      selected_workout_background: workoutBackground._id,
      primary_color: theme.primaryColor,
      secondary_color: theme.secondaryColor,
    };
    const localData = {
      selectedSplashLogo: splashLogo,
      selectedWorkoutBackground: workoutBackground,
      originalTheme: { ...theme },
    };
    this.isSaving = true;
    this.props.updateCustomBranding(data, localData).finally(() => {
      this.isSaving = false;
    });
  };

  renderSaveButton = () => {
    const { selectedWorkoutBackground, selectedSplashLogo, loading, originalTheme, onCheckSaving } = this.props;
    const { splashLogo, workoutBackground, theme } = this.state;
    const originalSplashLogo = selectedSplashLogo.src || '';
    const currentLogo = splashLogo.src || '';

    if (
      !loading &&
      (theme.primaryColor.toLowerCase() !== originalTheme.primaryColor.toLowerCase() ||
        theme.secondaryColor.toLowerCase() !== originalTheme.secondaryColor.toLowerCase() ||
        selectedWorkoutBackground._id !== workoutBackground._id ||
        originalSplashLogo !== currentLogo)
    ) {
      // Check Saving
      onCheckSaving(true);

      return (
        <S.SaveButton className="save" onClick={this.onSave}>
          Save
        </S.SaveButton>
      );
    } else {
      // Check Saving
      onCheckSaving(false);
      return <div />;
    }
  };

  handleRedirectToTab = () => {
    this.props.onRedirectToTab(1);
  };

  render() {
    const { splashLogo, workoutBackground, theme } = this.state;
    const { loading, discard, defaultBackground, cloudfrontList } = this.props;
    const tier = _.get(this.props, 'tier', 'starter');

    return (
      <S.Section premiumCustomBrandPermission={this.props.premiumCustomBrandPermission}>
        <S.SectionHeader>
          <S.SectionHeaderTitle>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" className="icon">
              <path
                d="M10 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H10C11.103 16 12 15.103 12 14V2C12 0.897 11.103 0 10 0ZM6 14C5.448 14 5 13.552 5 13C5 12.448 5.448 12 6 12C6.552 12 7 12.448 7 13C7 13.552 6.552 14 6 14ZM10 10H2V2H10V10Z"
                fill="#90949C"
              />
            </svg>
            <span>Client Mobile App</span>
          </S.SectionHeaderTitle>
          {discard || this.renderSaveButton()}
        </S.SectionHeader>
        <UpgradePath pathName="premiumCustomBrand" fallback={null}>
          <SettingStyles.NoticeBanner>
            <SettingStyles.BannerContent>
              <p>
                Find your advanced custom branding settings on the{' '}
                <span onClick={this.handleRedirectToTab}>Advanced tab</span>
              </p>
            </SettingStyles.BannerContent>
          </SettingStyles.NoticeBanner>
        </UpgradePath>
        {!this.props.premiumCustomBrandPermission && (
          <S.SectionContent>
            <S.SettingArea>
              {loading ? (
                <SettingLoader />
              ) : (
                <S.SettingAreaContent>
                  <S.SectionDescription>
                    Customize the splash screen clients first see when opening the app, and choose a personalized
                    background for your workouts.
                  </S.SectionDescription>
                  <UpgradePath pathName="branding">
                    <S.FormGroup>
                      <label>Splash Screen Logo</label>
                      <Upload
                        key={splashLogo.src || 'splashLogo'}
                        source="Splash Screen Logo"
                        cloudfrontList={cloudfrontList}
                        onRemove={this.onRemoveSplashLogo}
                        onUploadSuccess={this.onUploadLogoSuccess}
                        data={splashLogo}
                        verifyResolution={{
                          width: 100,
                          height: 100,
                          message: 'Resolution should be at least 100px by 100px.',
                        }}
                        maxSize={25}
                        overSizeMessage="Please upload an image smaller than 25 MB"
                      />
                      <S.Tip>
                        Png or jpg files. Resolution should be at least{' '}
                        <span className="hightlight">100px by 100px.</span>
                      </S.Tip>
                    </S.FormGroup>
                    <S.FormGroup>
                      <label>Workout background</label>
                      <WorkoutBackground data={workoutBackground} onChange={this.onChangeBackground} />
                    </S.FormGroup>
                    <S.FormGroup className="clientMobileApp">
                      <label>App Themes</label>
                      <SettingStyles.ThemesContainer>
                        {_.map(THEMES, (item, index) => (
                          <SettingStyles.ThemeItem
                            active={
                              item.primaryColor.toLowerCase() === theme.primaryColor.toLowerCase() &&
                              item.secondaryColor.toLowerCase() === theme.secondaryColor.toLowerCase()
                            }
                            onClick={this.onSelectTheme}
                            key={item._id}
                            data-theme-id={item._id}
                          >
                            <SettingStyles.ThemeContent {...item} />
                          </SettingStyles.ThemeItem>
                        ))}
                        <SettingStyles.ThemeItem
                          key="new-theme"
                          addNew={true}
                          data-tip
                          data-for="toolip--add-new-theme"
                        >
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 9V0H9L9 9H0V10H9V18H10V10H18V9H10Z"
                              fill="#99A1B4"
                            />
                          </svg>
                          {addNewTheme}
                          <AdvancedCustomizationTooltip
                            id="toolip--add-new-theme"
                            effect="solid"
                            place="top"
                            className="app-tooltip-white"
                            event="click"
                            globalEventOff="click"
                            clickable
                            isCapture
                            onRedirectToTab={this.props.onRedirectToTab}
                          />
                        </SettingStyles.ThemeItem>
                      </SettingStyles.ThemesContainer>
                    </S.FormGroup>
                  </UpgradePath>
                </S.SettingAreaContent>
              )}
            </S.SettingArea>
            <SettingStyles.PreviewWrapper>
              <SettingStyles.PreviewContent>
                <SettingStyles.PhoneContainer>
                  <SettingStyles.PhoneContent>
                    <SettingStyles.SplashLogoPlaceholderContainer logoSize={!loading && !!splashLogo.src}>
                      {loading ? (
                        <LogoLoader width="92px" height="92px" radius="5px" />
                      ) : splashLogo.src && tier !== 'starter' ? (
                        <SettingStyles.Logo src={convertS3UrlToCloudFrontUrl(splashLogo.src, cloudfrontList, true)} />
                      ) : (
                        <SettingStyles.SplashLogoPlaceholder>
                          <SettingStyles.PlaceholderText>Splash Screen Logo</SettingStyles.PlaceholderText>
                        </SettingStyles.SplashLogoPlaceholder>
                      )}
                    </SettingStyles.SplashLogoPlaceholderContainer>
                  </SettingStyles.PhoneContent>
                </SettingStyles.PhoneContainer>

                <SettingStyles.PhoneContainer>
                  <TodayScreen
                    useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                    customBackground={!workoutBackground.default}
                    customTheme={!theme.default}
                    secondaryColor={tier === 'starter' ? this.defaultTheme.secondaryColor : theme.secondaryColor}
                    primaryColor={tier === 'starter' ? this.defaultTheme.primaryColor : theme.primaryColor}
                    workoutBackground={
                      tier === 'starter'
                        ? defaultBackground.src
                        : convertS3UrlToCloudFrontUrl(workoutBackground.src, cloudfrontList, true)
                    }
                    loading={loading}
                    tier={tier}
                  />
                </SettingStyles.PhoneContainer>
              </SettingStyles.PreviewContent>
            </SettingStyles.PreviewWrapper>
          </S.SectionContent>
        )}
      </S.Section>
    );
  }
}

export default SettingMobileApp;

const addNewTheme = (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="add-new-theme-hover"
  >
    <rect x="0.5" y="0.5" width="73" height="73" rx="36.5" fill="#FFF7EE" stroke="url(#paint0_linear)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M38 37V28H37L37 37H28V38H37V46H38V38H46V37H38Z" fill="#F47436" />
    <defs>
      <linearGradient id="paint0_linear" x1="37" y1="1" x2="37" y2="73" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDC830" />
        <stop offset="1" stopColor="#F37335" />
      </linearGradient>
    </defs>
  </svg>
);
