import styled, { css } from 'styled-components';
import CommentItem from 'shared/CommentItem';

export const CommentItemWrapper = styled.div``;

export const ListReplyWrapper = styled.div`
  margin-left: 30px;
  margin-bottom: 18px;
  margin-top: -9px;
`;

export const CommentItemReply = styled(CommentItem)`
  margin-bottom: 8px;
  position: relative;
  .comment__username {
    font-size: 13px;
  }
`;

export const CommentItemReplyWrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: -20px;
    width: 2px;
    height: calc(100% + 10px);
    background-color: #f0f1f6;
  }
  &:last-child:before {
    content: none;
  }
`;

export const TreeItem = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid #f0f1f6;
  border-bottom-left-radius: 11px;
  position: absolute;
  top: ${props => (props.isViewMore ? '-7px' : '-5px')};
  left: -20px;
  :before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    background: #fff;
    bottom: 0px;
    left: 0;
    border-bottom-left-radius: 9px;
  }
`;

export const CommentItemChild = styled.div`
  position: relative;
  ${props =>
    props.hasReply &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 24px;
        left: 10px;
        width: 2px;
        height: calc(100% - 18px);
        background-color: #f0f1f6;
      }
    `}
`;

export const ViewMoreWrapper = styled.div`
  width: fit-content;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  user-select: none;
  cursor: pointer;
  span {
    color: #202353;
    opacity: 0.5;
  }
  svg {
    margin-right: 3px;
  }
  &:hover {
    span {
      color: #5158cf;
      opacity: 1;
    }
    svg {
      g {
        opacity: 1;
      }
      path {
        fill: #5158cf;
        opacity: 1;
      }
    }
  }
`;
