import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { resumeAutoflow } from 'redux/autoflow/actions';
import { resumeAutoflow as resumeAutoflowInterval } from 'redux/autoflowInterval/actions';
import { AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import * as S from './style';
import { bindActionCreators } from 'redux';

function ActiveAutoflow(props) {
  const { autoflowId, clientStatistic } = props;
  const body =
    'Upon activation, this Autoflow’s workouts, tasks, and auto-messages from today onwards will be synced to any clients added to the active Autoflow.';
  const end =
    !clientStatistic.waiting_to_start && !clientStatistic.paused ? (
      <span>
        Currently, <b>no clients</b> have been added. Would you like to continue?
      </span>
    ) : (
      <span>Would you like to continue?</span>
    );
  let openning = 'Autoflow will be activated',
    activatedClients = null;

  if (clientStatistic.paused || clientStatistic.waiting_to_start) {
    activatedClients = (
      <span>
        &nbsp;for all
        {clientStatistic.paused ? (
          <>
            &nbsp;<b>{clientStatistic.paused} Paused</b> client{clientStatistic.paused === 1 ? '' : 's'}
          </>
        ) : null}
        {clientStatistic.waiting_to_start ? (
          <>
            &nbsp;{clientStatistic.paused ? 'and ' : ''}
            <b>{clientStatistic.waiting_to_start} Waiting to start</b> client
            {clientStatistic.waiting_to_start === 1 ? '' : 's'}
          </>
        ) : null}
      </span>
    );
  }

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal active-autoflow"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/activate.svg`} />
            <span>Activate Autoflow</span>
          </S.Header>
          <S.Body>
            <div>
              {openning}
              {activatedClients}.&nbsp;{body}&nbsp;{end}
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              green
              onClick={() => {
                props.resumeAutoflow(autoflowId);
                props.toggleModal(false);
              }}
            >
              Activate
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    autoflowId: workingAutoflow && workingAutoflow._id,
  };
};

const mapDispatch = (dispatch, props) => {
  if (props.type === AUTOFLOW_TYPES.INTERVAL) {
    return {
      toggleModal: bindActionCreators(toggleModal, dispatch),
      resumeAutoflow: bindActionCreators(resumeAutoflowInterval, dispatch),
    };
  }
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resumeAutoflow: bindActionCreators(resumeAutoflow, dispatch),
  };
};

export default connect(mapState, mapDispatch)(ActiveAutoflow);
