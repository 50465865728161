import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import VideoDuration from 'shared/VideoDuration';
import { getDuration } from 'utils/commonFunction';
import { FILE_VALIDATIONS } from '../constants';
import { CDN_URL } from 'constants/commonData';
import { CameraIcon } from './icons';

export const SelectedFiles = props => {
  const { index, selectedFiles } = props;

  return (
    <MediaContainer className="selected-files">
      {selectedFiles.map(item => {
        const { attachment_type, objectURL } = item;

        return (
          <MediaItem className="item" key={objectURL}>
            {attachment_type.includes('video') ? (
              <>
                <video
                  className="media"
                  onLoadedMetadata={event => {
                    if (event.target && event.target.duration) {
                      const { duration, nextElementSibling } = event.target;

                      if (nextElementSibling && nextElementSibling.className.includes('video-duration')) {
                        const time = getDuration(duration);
                        const timeEl = nextElementSibling.querySelector('.time');

                        if (timeEl) {
                          timeEl.innerText = time;
                        }
                      }
                    }

                    event.persist();
                  }}
                >
                  <source src={objectURL} type={attachment_type} />
                  <source src={objectURL} type="video/mp4" />
                </video>
                <VideoDuration />
              </>
            ) : (
              <img className="media" src={objectURL} alt="" />
            )}
            <img
              src={`${CDN_URL}/images/close_circle.svg`}
              onClick={() => props.onDelete(index)}
              className="remove-icon"
              alt=""
            />
          </MediaItem>
        );
      })}
    </MediaContainer>
  );
};

export const SelectFileInput = props => {
  const { disabled, hasText } = props;
  const disableSelectFile = disabled || hasText;

  return (
    <FileInputContainer
      className={classnames('select-files-input', { disabled: disableSelectFile })}
      data-tip
      data-for="chat-input-upload-media-tooltip"
    >
      {CameraIcon}
      {hasText ? (
        <ReactTooltip
          className="app-tooltip autoflow-exactdate-tooltip"
          id="chat-input-upload-media-tooltip"
          effect="solid"
          place="top"
        >
          <p>Please send message first before adding attachment</p>
        </ReactTooltip>
      ) : (
        <ReactTooltip className="app-tooltip" id="chat-input-upload-media-tooltip" effect="solid" place="top">
          <span>Upload Media</span>
        </ReactTooltip>
      )}
      <input
        type="file"
        accept={FILE_VALIDATIONS.ACCEPTED}
        disabled={disableSelectFile}
        multiple={false}
        onChange={props.onChange}
      />
    </FileInputContainer>
  );
};

const MediaItem = styled.div`
  width: 72px;
  height: 72px;
  margin-right: 10px;
  position: relative;

  img,
  video {
    &.media {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .remove-icon {
    position: absolute;
    right: -6px;
    top: -6px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    display: block;
  }

  :last-child {
    margin-right: 0;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const FileInputContainer = styled.div`
  position: relative;
  height: 32px;

  :not(.disabled):hover {
    svg {
      rect {
        fill: #d9dfe3;
      }
    }
  }

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 1;
    text-indent: -9999px;
    cursor: pointer;
  }

  &.disabled {
    input {
      cursor: not-allowed;
    }
  }

  .__react_component_tooltip.app-tooltip-white {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    padding: 12px 15px 10px;
    width: 180px;
  }
`;
