import React from 'react';
import ContentLoader from 'react-content-loader';

import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 6px rgba(7, 13, 36, 0.17);
  border-radius: 5px;
  height: 208px;
`;

export const TemplateLoading = () => {
  return (
    <Container>
      <ContentLoader height="208" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)">
        <rect x="0" y="0" rx="0" ry="5" width="100%" height="130" />
        <rect x="10" y="145" rx="2" ry="2" width="88%" height="14" />
        <rect x="10" y="165" rx="2" ry="2" width="50%" height="14" />
      </ContentLoader>
    </Container>
  );
};
