import { Types } from './actions';

const INITIAL_STATE = {
  loaded: false,
  loading: false,
  is_passed_customize_log_activity_onboarding: false,
  is_passed_log_activity_onboarding: false,
  is_passed_workout_collection_banner_onboarding: false,
  is_hide_form_banner: true,
  is_hide_meal_plan_banner: true,
  is_hide_program_library_banner: true,
  is_show_upgrade_path_onboarding_flow: true,
  is_hide_onboarding_flow_trigger_form_note: true,
  enable_datadog_rum: false,
  enable_logs_request: false,
  is_agreed_ai_wob_beta_agreement: false,
  is_done_ai_wob_onboarding_tour: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.USER_GET_GENERAL_SETTINGS_SUCCESS: {
      return Object.assign({}, state, { ...payload.data, loaded: true });
    }
    case Types.USER_HIDE_WORKOUT_COLLECTION_SUCCESS: {
      return Object.assign({}, state, { is_passed_workout_collection_banner_onboarding: true });
    }
    case Types.USER_HIDE_FORM_BANNER_SUCCESS: {
      return Object.assign({}, state, { is_hide_form_banner: true });
    }
    case Types.USER_HIDE_TEXT_DEFAULT_IMAGE: {
      return Object.assign({}, state, { is_change_recipe_default_image: true });
    }
    case Types.USER_HIDE_BANNER_STARTING: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.USER_HIDE_BANNER_ENDING: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.USER_HIDE_MEAL_PLAN_BANNER_SUCCESS: {
      return Object.assign({}, state, { is_hide_meal_plan_banner: true, loading: false });
    }
    case Types.USER_HIDE_UPGRADE_PATH_ONBOARDING_SUCCESS: {
      return Object.assign({}, state, { is_show_upgrade_path_onboarding_flow: true, loading: false });
    }
    case Types.USER_HIDE_PROGRAM_LIBRARY_BANNER_SUCCESS: {
      return Object.assign({}, state, { is_hide_program_library_banner: true, loading: false });
    }
    case Types.USER_HIDE_ONBOARDING_FLOW_TRIGGER_FORM_NOTE_SUCCESS: {
      return Object.assign({}, state, { is_hide_onboarding_flow_trigger_form_note: true, loading: false });
    }

    case Types.USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_FAILED: {
      return Object.assign({}, state, { loading: false });
    }
    case Types.USER_UPDATE_STATUS_MEAL_PLAN_FORMAT_SUCCESS: {
      const { data } = payload;
      return Object.assign({}, state, { ...data, loading: false });
    }
    case Types.USER_UPDATE_FLAG_BETA_AGREEMENT_SUCCESS: {
      const { dataBetaAgreement } = payload;
      return Object.assign({}, state, { ...dataBetaAgreement, loading: false });
    }
    case Types.USER_DONE_AI_WOB_ONBOARDING_TOUR_SUCCESS:
      const { isDoneAIOnboardingTour } = payload;
      return Object.assign({}, state, {
        loading: false,
        is_done_ai_wob_onboarding_tour: isDoneAIOnboardingTour,
      });

    default:
      return state;
  }
};
