import React from 'react';
import SuccessPopup from 'shared/SuccessPopup';

export const showSuccess = (message, title, closeAction, acttionButtonTitle) => {
  return {
    type: 'SHOW_SUCCESS',
    payload: {
      modal: (
        <SuccessPopup
          content={message}
          title={title}
          closeAction={closeAction}
          acttionButtonTitle={acttionButtonTitle}
        />
      )
    }
  };
};

export const hideSuccess = () => {
  return {
    type: 'HIDE_SUCCESS'
  };
};
