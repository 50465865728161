import moment from 'moment';
import pick from 'lodash/pick';
import get from 'lodash/get';

import { FILE_ERRORS, CONVERSION, AUTOFLOW_TYPES, DATE_FORMAT } from 'constants/commonData';

export const SUPPORTED_FILES = ['png', 'jpg', 'jpeg', 'avi', 'flv', 'mp4', 'mov', '3gp'];
export const MAX_SIZE = 200 * CONVERSION.MB_TO_BYTE;
export const MAX_SIZE_VIDEO = 150 * CONVERSION.MB_TO_BYTE;
export const MAX_SIZE_IMAGE = 25 * CONVERSION.MB_TO_BYTE;
export const ATTACHMENT_TYPE_LINK = 'link';
export const TITLE_LINE_HEIGHT = 16;

export function getFileInformation(file, onSuccess, onError = () => {}) {
  if (!file) {
    return onError({ error: 'File is required' });
  }

  const fileExtension = file.name.split('.').pop();

  if (!SUPPORTED_FILES.includes(fileExtension.toLowerCase())) {
    return onError({ error: FILE_ERRORS.FILE_TYPE_INVALID });
  }

  if (file.size > MAX_SIZE) {
    return onError({ error: FILE_ERRORS.FILE_SIZE_LIMIT });
  }

  const reader = new FileReader();
  const URL = window.URL || window.webkitURL;

  reader.onload = e => {
    const { result } = e.target;

    const data = { file, data: result };

    if (file.type.startsWith('video')) {
      const url = URL.createObjectURL(file);
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        URL.revokeObjectURL(url);
        onSuccess({ ...data, duration: video.duration });
      };
      video.src = url;
    } else {
      onSuccess(data);
    }
  };

  reader.readAsDataURL(file);
}

export const generateAnnouncementDates = (originalData, date, type) => {
  let originStartDate, originEndDate;

  if (type === AUTOFLOW_TYPES.INTERVAL) {
    const tmpDay = get(originalData, 'dayIndex', date),
      tmpWeek = get(originalData, 'weekIndex', 0);

    originStartDate = Number(tmpWeek) * 7 + Number(tmpDay);
    originEndDate = originStartDate + Number(get(originalData, 'dayDuration', 1)) - 1;
  } else {
    const propDate = get(originalData, 'date', date);
    const dateFormated = moment.isMoment(propDate)
      ? propDate.format(DATE_FORMAT)
      : moment.utc(propDate).format(DATE_FORMAT);
    originStartDate = moment(dateFormated, DATE_FORMAT);
    originEndDate = get(originalData, 'end_date')
      ? moment.utc(originalData.end_date)
      : moment(originStartDate).add(1, 'day');
  }

  return { start: originStartDate, end: originEndDate };
};

export function removeScriptTags(inputString) {
  return inputString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
}

export function getAttachmentLinkData(data) {
  if (data.attachment && data.attachment_type === ATTACHMENT_TYPE_LINK) {
    return pick(data, ['attachment', 'attachment_thumbnail', 'attachment_type', 'attachment_name', 'attachment_id']);
  }
  return undefined;
}

export const replaceInnerHTMLQuillElement = elementHTML => {
  if (!elementHTML || !elementHTML.innerHTML) {
    return '';
  }

  const breakLineReg = new RegExp('<p><br></p>', 'g');
  const divOpenReg = new RegExp('<p>', 'g');
  const divCloseReg = new RegExp('</p>', 'g');
  const firstBreakReg = new RegExp('^<br>', 'i');

  const innerHTML = elementHTML.innerHTML;
  const newHTML = innerHTML
    .replace(breakLineReg, '<br>')
    .replace(divOpenReg, '<br>')
    .replace(divCloseReg, '')
    .replace(firstBreakReg, '');

  return newHTML;
};

export function getPlainText(htmlString) {
  try {
    let div = document.createElement('div');
    div.innerHTML = htmlString;
    div.innerHTML = replaceInnerHTMLQuillElement(div);
    document.body.appendChild(div);
    const text = div.innerText;
    document.body.removeChild(div);
    return text;
  } catch {
    return htmlString;
  }
}

export function getHTMLText(plainText = '') {
  return plainText.replace(/\n/g, '<br>');
}

export function addInlineStyleToTags(htmlString) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const qlTextCenterTags = tempElement.querySelectorAll('.ql-align-center');
  qlTextCenterTags.forEach(tag => {
    tag.style.textAlign = 'center';
  });

  const listTags = tempElement.querySelectorAll('ul, ol');
  if (listTags.length > 0) {
    listTags.forEach(listTag => {
      listTag.style.paddingInlineStart = '21px';

      const liTextCenterTags = listTag.querySelectorAll('.ql-align-center');
      if (liTextCenterTags.length > 0) {
        listTag.style.display = 'flex';
        listTag.style.flexDirection = 'column';
        listTag.style.justifyContent = 'center';
        listTag.style.alignItems = 'center';

        const liNotTextCenterTags = listTag.querySelectorAll(':not(.ql-align-center)');

        if (liNotTextCenterTags.length > 0) {
          liNotTextCenterTags.forEach(tag => {
            tag.style.width = '100%';
          });
        }
      }
    });
  }

  const headerTags = tempElement.querySelectorAll('h1, h2');
  headerTags.forEach(headerTag => {
    const children = headerTag.childNodes;

    children.forEach(child => {
      if (child.nodeType === 3 && child.parentNode.tagName !== 'STRONG') {
        const strongElement = document.createElement('strong');
        child.parentNode.insertBefore(strongElement, child);
        strongElement.appendChild(child);
      }
    });
  });

  return tempElement.innerHTML;
}
