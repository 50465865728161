import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { push } from 'connected-react-router';
import {
  openWorkoutDetail,
  removeWorkout,
  updateWorkoutSharingStatus,
  updateSelectedWorkout,
  changeQueryParams,
  resetSelectedWorkout,
} from 'redux/workout_library/workout.actionCreators';
import { toggleConfirmModal } from 'actions/modal';

import ConfirmModal from 'shared/ConfirmModal';
import * as Layout from 'shared/LibraryLayout';
import SharingStatus from 'shared/SharingStatus';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import { Checkbox } from 'shared/FormControl';

import {
  TEAM_SHARE_SHARED,
  TEAM_SHARE_NOOWNER,
  TEAM_SHARE_PRIVATE,
  WORKOUT_BUILDER_TYPES,
  CDN_URL,
} from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';
import columns from './columns';
import { WorkoutCell } from '../style';
import { cloneWorkoutTemplate } from 'redux/workout_library/workout.actionCreators';

function Component(props) {
  const { items, user, selectedWorkout } = props;
  const hasTeam = !!user.team_member && user.team_member.length > 1;

  useEffect(() => {
    // Reset Selected Workouts before move another page
    return () => {
      props.resetSelectedWorkout();
    };
  }, []);

  if (!items.length) {
    return null;
  }

  const editWorkout = item => {
    props.openWorkoutDetail(item._id);
    props.push(`/home/workout/${item._id}/detail`);
  };

  const handleCloneWorkout = item => {
    props.cloneWorkoutTemplate(item._id, true);
  };

  const toggleSharingStatus = workout => {
    const { author, share = 0 } = workout;

    if (workout.share === TEAM_SHARE_NOOWNER) {
      return editWorkout(workout);
    }

    if (!author) {
      return false;
    }

    if (author._id === user._id || isTeamAdmin(user)) {
      const newSharingStatus = share === TEAM_SHARE_PRIVATE ? TEAM_SHARE_SHARED : TEAM_SHARE_PRIVATE;
      props.updateWorkoutSharingStatus({ workoutId: workout._id, share: newSharingStatus });
    }
  };

  const handleSelectWorkout = (e, value) => {
    const workoutID = _.get(value, '_id', '');
    const checked = _.get(e, 'target.checked', false);
    let selectedList = [];

    if (checked) {
      props.updateSelectedWorkout && props.updateSelectedWorkout([...selectedWorkout, workoutID]);
      selectedList = [...selectedWorkout, workoutID];
    } else {
      props.updateSelectedWorkout && props.updateSelectedWorkout(selectedWorkout.filter(id => id !== workoutID));
      selectedList = selectedWorkout.filter(id => id !== workoutID);
    }

    props.onCountTagBySelected(_.filter(items, item => selectedList.includes(item._id)));
  };

  const renderActions = workout => {
    const isCanDelete = workout.author._id === user._id || isTeamAdmin(user);
    return (
      <DropDown triggerIcon={({ open }) => <MenuTrigger vertical active={!!open} itemId={_.get(workout, '_id', '')} />}>
        <Option key="duplicate" onClick={() => handleCloneWorkout(workout)}>
          <div className="icon">
            <img src={`${CDN_URL}/images/clone.svg`} alt="" />
          </div>
          <span>Duplicate</span>
        </Option>
        {isCanDelete ? (
          <Option
            key="delete"
            onClick={() => {
              props.toggleConfirmModal(
                true,
                <ConfirmModal
                  title="Remove Workout"
                  content="Are you sure that you want to delete this workout?"
                  onConfirm={() => props.removeWorkoutLibrary(workout._id)}
                />,
              );
            }}
          >
            <div className="icon">
              <img src={`${CDN_URL}/images/delete.svg`} alt="" />
            </div>
            <span>Delete</span>
          </Option>
        ) : null}
      </DropDown>
    );
  };

  const renderCheckbox = workout => {
    const isChecked = selectedWorkout.includes(_.get(workout, '_id', ''));
    return (
      <Checkbox
        checked={isChecked}
        onClick={e => e.stopPropagation()}
        onChange={e => handleSelectWorkout(e, workout)}
      />
    );
  };

  const handleSelectTag = (e, tag) => {
    e.stopPropagation();
    props.changeQueryParams({ search_tags: [tag] });
  };

  return (
    <Layout.TableBody>
      {_.map(items, workout => {
        const share = workout.share === TEAM_SHARE_SHARED || workout.share === TEAM_SHARE_NOOWNER;

        return (
          <Layout.TableRow key={workout._id} onClick={() => editWorkout(workout)}>
            {columns.map(col => {
              if (col.customCell) {
                return col.customCell(_.get(workout, col.key), workout, renderCheckbox(workout), handleSelectTag);
              }
              return (
                <WorkoutCell key={col.key} alignCenter={col.alignCenter}>
                  {_.get(workout, col.key)}
                </WorkoutCell>
              );
            })}
            <WorkoutCell key="actions">
              <Layout.Actions>
                {hasTeam ? (
                  <>
                    <SharingStatus
                      place="top"
                      share={share}
                      itemId={workout._id}
                      onClick={event => {
                        event.stopPropagation();
                        toggleSharingStatus(workout);
                      }}
                    />
                  </>
                ) : null}
                {renderActions(workout)}
              </Layout.Actions>
            </WorkoutCell>
          </Layout.TableRow>
        );
      })}
    </Layout.TableBody>
  );
}

const TableBody = React.memo(Component);

const mapState = state => {
  const {
    user,
    rootReducer: {
      workoutLibrary: { listWorkout, selectedWorkout },
    },
  } = state;

  return { items: listWorkout, user, selectedWorkout };
};

const mapDispatch = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    openWorkoutDetail: bindActionCreators(openWorkoutDetail, dispatch),
    removeWorkoutLibrary: bindActionCreators(removeWorkout, dispatch),
    updateWorkoutSharingStatus: bindActionCreators(updateWorkoutSharingStatus, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    cloneWorkoutTemplate: bindActionCreators(cloneWorkoutTemplate, dispatch),
    updateSelectedWorkout: bindActionCreators(updateSelectedWorkout, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetSelectedWorkout: bindActionCreators(resetSelectedWorkout, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableBody);
