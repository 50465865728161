import moment from 'moment';

export const CHART_COLORS = [
  '#F2617D',
  '#FF7557',
  '#FEA498',
  '#F8BC3B',
  '#5BB7AF',
  '#3BA974',
  '#0D7EA0',
  '#4CD9CD',
  '#72BEF4',
  '#CA80DC',
  '#7856FF',
];

export const TIME_RANGE = [
  { label: '1M', value: 'last_month', months: 1 },
  { label: '2M', value: 'last_2_month', months: 2 },
  { label: '6M', value: 'last_6_month', months: 6 },
  { label: '1Y', value: 'last_year', months: 12 },
];

export const LAST_TIME_OPTIONS = [
  { label: 'Today', value: 'today' },
  { label: 'Last 7 days', value: 'last_7_day' },
  { label: 'Last 4 weeks', value: 'last_4_week' },
  { label: 'Last 2 months', value: 'last_2_month' },
  { label: 'Last 3 months', value: 'last_3_month' },
  { label: 'Last 6 months', value: 'last_6_month' },
  { label: 'Last 12 months', value: 'last_12_month' },
  { label: 'Custom', value: 'custom' },
];

export const PERIOD_GROUP = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

export const VIEW_TYPE_OPTIONS = [
  { label: 'Hourly', value: PERIOD_GROUP.HOURLY },
  { label: 'Daily', value: PERIOD_GROUP.DAILY },
  { label: 'Weekly', value: PERIOD_GROUP.WEEKLY },
  { label: 'Monthly', value: PERIOD_GROUP.MONTHLY },
];

export const NORMALIZING_TIME_OPTIONS = {
  today: { label: 'Today', value: 'today', viewTypes: [PERIOD_GROUP.HOURLY] },
  last_7_day: { label: 'Last 7 days', value: 'last_7_day', viewTypes: [PERIOD_GROUP.DAILY] },
  last_4_week: { label: 'Last 4 weeks', value: 'last_4_week', viewTypes: [PERIOD_GROUP.DAILY, PERIOD_GROUP.WEEKLY] },
  last_2_month: { label: 'Last 2 months', value: 'last_2_month', viewTypes: [PERIOD_GROUP.DAILY, PERIOD_GROUP.WEEKLY] },
  last_3_month: { label: 'Last 3 months', value: 'last_3_month', viewTypes: [PERIOD_GROUP.DAILY, PERIOD_GROUP.WEEKLY] },
  last_6_month: {
    label: 'Last 6 months',
    value: 'last_6_month',
    viewTypes: [PERIOD_GROUP.WEEKLY, PERIOD_GROUP.MONTHLY],
  },
  last_12_month: {
    label: 'Last 12 months',
    value: 'last_12_month',
    viewTypes: [PERIOD_GROUP.WEEKLY, PERIOD_GROUP.MONTHLY],
  },
  custom: { label: 'Custom', value: 'custom', viewTypes: [] },
};

export const TIME_OPTIONS_METRIC_OVERVIEW = {
  last_7_day: { label: 'Last 7 days', value: 'last_7_day', viewTypes: [PERIOD_GROUP.DAILY] },
  last_2_week: { label: 'Last 2 weeks', value: 'last_2_week', viewTypes: [PERIOD_GROUP.DAILY, PERIOD_GROUP.WEEKLY] },
  last_4_week: { label: 'Last 4 weeks', value: 'last_4_week', viewTypes: [PERIOD_GROUP.DAILY, PERIOD_GROUP.WEEKLY] },
  last_2_month: { label: 'Last 2 months', value: 'last_2_month', viewTypes: [PERIOD_GROUP.DAILY, PERIOD_GROUP.WEEKLY] },
  last_6_month: {
    label: 'Last 6 months',
    value: 'last_6_month',
    viewTypes: [PERIOD_GROUP.WEEKLY, PERIOD_GROUP.MONTHLY],
  },
};

export const randomColor = CHART_COLORS[Math.round(Math.random() * CHART_COLORS.length)];

export const DEFAULT_COLOR = '#7856FF';

export const COLOR_OPTIONS = [
  {
    label: 'Red',
    color: '#F2617D',
  },
  {
    label: 'Orange 1',
    color: '#FF7557',
  },
  {
    label: 'Orange 2',
    color: '#FEA498',
  },
  {
    label: 'Yellow',
    color: '#F8BC3B',
  },
  {
    label: 'Green 1',
    color: '#5BB7AF',
  },
  {
    label: 'Green 2',
    color: '#3BA974',
  },
  {
    label: 'Green 3',
    color: '#0D7EA0',
  },
  {
    label: 'Blue 1',
    color: '#4CD9CD',
  },
  {
    label: 'Blue 2',
    color: '#72BEF4',
  },
  {
    label: 'Purple 1',
    color: '#CA80DC',
  },
  {
    label: 'Purple 2',
    color: '#7856FF',
  },
];

export const KEYS_METRIC = [
  {
    title: 'Current',
    key: 'current',
  },
  {
    title: 'Average',
    key: 'average',
  },
  {
    title: 'Goal',
    key: 'goal',
  },
  {
    title: 'Starting',
    key: 'starting',
  },
];

export const METRIC_CHANGED_STATUS = {
  NO: 'no',
  INCREASE: 'increase',
  DECREASE: 'decrease',
};

export const isValidTimeRange = time => {
  const times = Object.values(NORMALIZING_TIME_OPTIONS);
  return !!times.find(item => item.value !== 'custom' && item.value === time);
};

export const getDateFromRange = timeRange => {
  const today = moment(new Date());
  const formatString = 'yyyy-MM-DD';

  switch (timeRange) {
    case NORMALIZING_TIME_OPTIONS.today.value: {
      return { fromDate: today.clone().format(formatString), toDate: today.format(formatString) };
    }

    case NORMALIZING_TIME_OPTIONS.last_7_day.value: {
      return { fromDate: today.clone().subtract(6, 'days').format(formatString), toDate: today.format(formatString) };
    }

    // for metrics overview
    case 'last_2_week': {
      return {
        fromDate: today.clone().subtract(2, 'weeks').add(1, 'd').format(formatString),
        toDate: today.format(formatString),
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_4_week.value: {
      return {
        fromDate: today.clone().subtract(4, 'weeks').add(1, 'd').format(formatString),
        toDate: today.format(formatString),
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_2_month.value: {
      return {
        fromDate: today.clone().subtract(2, 'months').add(1, 'd').format(formatString),
        toDate: today.format(formatString),
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_3_month.value: {
      return {
        fromDate: today.clone().subtract(3, 'months').add(1, 'd').format(formatString),
        toDate: today.format(formatString),
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_6_month.value: {
      return {
        fromDate: today.clone().subtract(6, 'months').add(1, 'd').format(formatString),
        toDate: today.format(formatString),
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_12_month.value: {
      return {
        fromDate: today.clone().subtract(12, 'months').add(1, 'd').format(formatString),
        toDate: today.format(formatString),
      };
    }

    case NORMALIZING_TIME_OPTIONS.custom.value: {
      return {
        fromDate: '',
        toDate: '',
      };
    }

    default:
      return {
        fromDate: today.clone().subtract(1, 'month').format(formatString),
        toDate: today.format(formatString),
      };
  }
};

export const getTimeRangeUnitFromRange = (timeRange, dayNumber = 1) => {
  switch (timeRange) {
    case NORMALIZING_TIME_OPTIONS.last_2_month.value: {
      return {
        value: 2,
        timeTitle: 'M',
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_3_month.value: {
      return {
        value: 3,
        timeTitle: 'M',
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_6_month.value: {
      return {
        value: 6,
        timeTitle: 'M',
      };
    }

    case NORMALIZING_TIME_OPTIONS.last_12_month.value: {
      return {
        value: 12,
        timeTitle: 'M',
      };
    }

    default:
      return {
        value: dayNumber,
        timeTitle: 'd',
      };
  }
};

export const getViewTypeFromRange = (timeRange = {}) => {
  if (!timeRange.endDate) return PERIOD_GROUP.HOURLY;
  const rangeDate = Math.abs(timeRange.startDate.diff(timeRange.endDate, 'day'));
  if (rangeDate === 0) return PERIOD_GROUP.HOURLY;
  if (rangeDate < 90) return PERIOD_GROUP.DAILY;
  return PERIOD_GROUP.WEEKLY;
};

const RANGE_DATE = {
  oneDay: 1,
  oneWeek: 7,
  fourWeek: 28,
  twoMonth: 2,
  threeMonth: 3,
  sixMonth: 6,
  oneYear: 12,
};

export const getOptionFromRange = (timeRange = {}) => {
  const {
    today,
    custom,
    last_7_day,
    last_4_week,
    last_2_month,
    last_3_month,
    last_6_month,
    last_12_month,
  } = NORMALIZING_TIME_OPTIONS;

  if (!timeRange.endDate) return today.value;

  const rangeDate = Math.abs(timeRange.startDate.diff(timeRange.endDate, 'day')) + 1;
  if (rangeDate === RANGE_DATE.oneDay) return today.value;
  if (rangeDate === RANGE_DATE.oneWeek) return last_7_day.value;
  if (rangeDate === RANGE_DATE.fourWeek) return last_4_week.value;

  const rangeMonth = Math.abs(timeRange.startDate.clone().subtract(1, 'day').diff(timeRange.endDate, 'month', true));
  if (rangeMonth === RANGE_DATE.twoMonth) return last_2_month.value;
  if (rangeMonth === RANGE_DATE.threeMonth) return last_3_month.value;
  if (rangeMonth === RANGE_DATE.sixMonth) return last_6_month.value;
  if (rangeMonth === RANGE_DATE.oneYear) return last_12_month.value;

  return custom.value;
};

export const getViewTypeOptionsFromRange = (timeRange = {}) => {
  if (!timeRange || !timeRange.endDate) return [];
  const rangeDate = Math.abs(timeRange.startDate.diff(timeRange.endDate, 'day'));

  if (rangeDate === 0) return [PERIOD_GROUP.HOURLY];
  if (rangeDate < 14) return [PERIOD_GROUP.DAILY];
  if (rangeDate < 90) return [PERIOD_GROUP.DAILY, PERIOD_GROUP.WEEKLY];
  return [PERIOD_GROUP.WEEKLY, PERIOD_GROUP.MONTHLY];
};

export const formatDate = (date = {}, isSameYear = false, formatStringInYear = '', formatStringOtherYear = '') => {
  if (!date) return '--';
  if (formatStringInYear) return date.format(isSameYear ? formatStringInYear : formatStringOtherYear);
  return date.format(isSameYear ? 'MMM D' : 'MMM D, YYYY');
};

export const HOUR_LABELS = ['12AM', '4AM', '8AM', '12PM', '4PM', '8PM'];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const CHART_LABEL_POSITION = {
  POSITION: 'insideTopLeft',
  OFFSET: 35,
  DY: -58,
};

export const CHART_TOOLTIP_POSITION = {
  OFFSET: 20,
};

export const BAR_CHART_METRIC_TYPES = ['calories_burned', 'calories_consumed', 'sleep', 'water_intake'];

export const BOUNDARY_VALUES = [Infinity, -Infinity];

export const TIME_UNITS = ['sec', 'min'];

export const DATA_POINT_KEYS = {
  latest_value: 'latest_value',
  average_value: 'average_value',
  all_values: 'all_values',
};

export const DATA_POINT_OPTIONS = [
  {
    label: 'Last value for each period',
    value: DATA_POINT_KEYS.latest_value,
  },
  {
    label: 'Average value',
    value: DATA_POINT_KEYS.average_value,
  },
  {
    label: 'Total of all entries',
    value: DATA_POINT_KEYS.all_values,
  },
];

export const UNDEFINED_OR_NULL_VALUE = [undefined, null];

export const SPECIAL_METRICS_CODE = {
  SLEEP: 'sleep',
  HEART_RATE: 'heart_rate',
  BMI: 'bmi',
  STEPS: 'steps',
};
export const UNIT_SPECIAL_CHART_NAMES = ['bpm'];

export const COLOR_HEART_RATE_CHART = '#F2617D';

export const LINE_CHART = 'line';

export const BAR_CHART = 'bar';

export const KEYS_METRIC_ARRAY = ['current', 'goal', 'starting'];

export const initChartDataEmpty = (time = '') => {
  const momentTime = moment(time, 'MMM d');
  return [
    {
      group_name: time,
      start: time,
      end: time,
      time: momentTime,
      value: undefined,
    },
  ];
};
