import React, { useMemo, useState, useContext, useEffect, useRef } from 'react';
import _ from 'lodash';
import { isSafari } from 'react-device-detect';

import SearchExercise from 'shared/SearchExercise';
import * as S from './style';
import Dropdown, { Option, Trigger } from 'shared/Dropdown/Basic';
import ExerciseForm from 'components/ExerciseForm';
import { MODES } from 'components/ExerciseForm/component';
import { localSectionId } from 'utils/commonFunction';
import AlternativeExercises from 'components/AlternativeExercises';
import { OptionIcon } from '../../../styles';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';
import HistoryExercisePopup from 'components/HistoryExercisePopup';
import { WorkoutContext, SectionContext } from '../../../contexts';
import MoveToSection from './MoveToSection';
import { HIDDEN_SECTION } from 'constants/commonData';
import OnboardingTooltip, { Content, Title } from 'components/Onboarding/Tooltip';
import { SECTION_FORMAT_KEY, WORKOUT_BUILDER_GUIDE_STEPS } from 'constants/commonData';
import { toast } from 'react-toastify';
import { AI_TABS } from 'components/WorkoutDetailModalAIDemo/components/LeftPanel/TabBar';
import { SharedTooltip } from 'shared/SharedTooltip';

function Header(props) {
  const {
    originalExercise,
    exerciseInstance,
    mostRecents,
    exerciseId,
    supersetId,
    sectionData,
    alternatives,
    selectedClient,
    allSections,
    loadedWorkoutOnboardingData,
    alreadyDragAndDropExercise,
    alreadyCreateSuperset,
    alreadyCustomOptions,
    markWorkoutBuilderGuide,
    exerciseIndex,
    linkIndex,
    runOnboarding,
    showError,
    aiTab = '',
  } = props;
  const [libraryData, setLibraryData] = useState(null);
  const [createNewExercise, setCreateNewExercise] = useState(null);
  const [showAltExercises, setShowAltExercises] = useState(false);
  const isAddingExercise = _.isEmpty(originalExercise) && _.isEmpty(exerciseInstance);
  const sectionId = localSectionId(sectionData);
  const sectionsCanMoveIn = allSections.toJS().filter(s => s !== sectionId && s.split('-')[1] !== HIDDEN_SECTION);
  const workoutCommonData = useContext(WorkoutContext);
  const sectionCommonData = useContext(SectionContext);
  let shouldShowOnboarding = false;
  const tempAltExercises = useRef([]); // Use the ref to avoid re-render

  useEffect(() => {
    if (shouldShowOnboarding) runOnboarding(WORKOUT_BUILDER_GUIDE_STEPS.CustomOptions);
  }, [shouldShowOnboarding]);

  // check show history icon
  const exerciseLibraryId = _.get(originalExercise, '_id') || _.get(exerciseInstance, 'exercise');
  const isShowHistoryIcon = exerciseLibraryId && selectedClient;

  const defaultValue = useMemo(() => {
    const id = _.get(originalExercise, '_id', _.get(exerciseInstance, 'exercise', ''));
    if (!id) return null;
    return {
      key: id,
      value: id,
      is_existing: _.get(originalExercise || exerciseInstance, 'is_existing') !== false,
      label: _.get(originalExercise || exerciseInstance, 'title'),
    };
  }, [originalExercise, exerciseInstance]);

  shouldShowOnboarding =
    sectionCommonData.sectionIndex === 0 &&
    linkIndex === 0 &&
    exerciseIndex === 0 &&
    loadedWorkoutOnboardingData &&
    alreadyDragAndDropExercise &&
    !alreadyCustomOptions &&
    (alreadyCreateSuperset || (!alreadyCreateSuperset && !workoutCommonData.firstLinkSupersetId));

  const onDelete = () => {
    props.deleteSuperset(sectionId, exerciseId, supersetId);
  };

  const onViewExercise = () => {
    if (!originalExercise) {
      return;
    }

    props.getExerciseDetail(originalExercise._id).then(response => {
      const { data } = response.data;
      setLibraryData(data);
    });
  };

  const onCloseExercisePopup = () => {
    setLibraryData(null);
    setCreateNewExercise(null);
  };

  const onSaveLibraryData = data => {
    if (createNewExercise) {
      props.onSelectExercise(data.exercise);
    } else {
      props.updateExerciseLibrary({ oldExerciseId: originalExercise._id, newExercise: data.exercise });
    }

    if (data.close) {
      setLibraryData(null);
      setCreateNewExercise(null);
    } else {
      setLibraryData(data.exercise);
      setCreateNewExercise(null);
    }
  };

  const handleSelectTempAltExercises = data => {
    tempAltExercises.current = data;
  };

  const onSelectExercise = data => {
    if (tempAltExercises.current && tempAltExercises.current.length) {
      const isExistAlternate = _.find(tempAltExercises.current, ex => ex._id === data.value);
      if (isExistAlternate) {
        return toast.error('Cannot add same alternative exercise with the original exercise');
      }
    }

    if (data && !data.value) {
      setCreateNewExercise(data.textSearch);
      return;
    }

    if (alternatives && alternatives.length) {
      const isExistAlternate = _.find(alternatives, ex => ex._id === data.value);
      if (isExistAlternate) {
        return toast.error('Cannot add same alternative exercise with the original exercise');
      }
    }

    props.onSelectExercise(data);
  };

  const onDuplicate = () => {
    props.duplicateSuperset(sectionId, supersetId);
  };

  const openAltExercisesPopup = () => {
    setShowAltExercises(true);
  };

  const closeAltExercisesPopup = () => {
    setShowAltExercises(false);
    tempAltExercises.current = [];
  };

  const onSaveAltExercises = exercises => {
    setShowAltExercises(false);
    tempAltExercises.current = [];
    props.onUpdateAlternativeExercises(exercises);
  };

  const onMoveToSection = toSectionId => {
    props.moveExerciseIntoSection({ sectionId, exerciseId, toSectionId });
  };

  const onUpdateExerciseLibraryFromAlternative = data => {
    props.updateExerciseLibrary(data);
  };

  const onGuideDone = () => {
    markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CustomOptions);
  };

  const renderTrigger = ({ open }) => (
    <>
      {!open && <SharedTooltip id="exercise-option-tooltip" />}
      <Trigger data-tip data-for="exercise-option-tooltip" />
    </>
  );

  return (
    <S.Wrapper className="superset__header" newUIPopup={isSafari}>
      {!isAddingExercise ? (
        <ExerciseMediaPreview
          showError={showError}
          exercise={originalExercise || {}}
          onSaveExercise={onSaveLibraryData}
        />
      ) : null}
      <SearchExercise
        defaultOptions={mostRecents}
        defaultValue={defaultValue}
        onSelect={onSelectExercise}
        onToggleUsePercent={props.onToggleUsePercent}
        should_show_1rm={props.showORMOption}
        is_use_1rm={props.useORM}
        disabled={false}
        menuIsAutoOpen={true}
        placeholder="Choose Exercise"
        autoSearch={aiTab === AI_TABS.AI}
      />
      {isShowHistoryIcon && (
        <HistoryExercisePopup exerciseId={exerciseLibraryId} deletedExercise={!_.get(originalExercise, '_id', '')} />
      )}
      {isAddingExercise ? (
        <S.CancelButton onClick={onDelete}>Cancel</S.CancelButton>
      ) : (
        <div className="section__header__actions__group">
          <Dropdown type="light" disabled={showAltExercises} className="actions" triggerIcon={renderTrigger}>
            {!!defaultValue && defaultValue.is_existing && (
              <>
                <Option key="view">
                  <OptionIcon icon="eye_777C8F" hoverIcon="eye_5158CF" onClick={onViewExercise}>
                    View exercise
                  </OptionIcon>
                </Option>
                <Option key="customize" onClick={props.onCustomizeFields}>
                  <OptionIcon icon="pen_777C8F" hoverIcon="pen_5158CF">
                    Customize fields
                  </OptionIcon>
                </Option>
                <Option key="alternate" onClick={openAltExercisesPopup}>
                  <OptionIcon icon="alt_777C8F" hoverIcon="alt_5158CF">
                    {alternatives.length ? 'Edit' : 'Add'} Alternate Exercises
                  </OptionIcon>
                </Option>
                <Option key="duplicate" onClick={onDuplicate}>
                  <OptionIcon icon="duplicate_777C8F" hoverIcon="duplicate_5158CF">
                    Duplicate
                  </OptionIcon>
                </Option>
              </>
            )}
            <Option key="delete" onClick={onDelete}>
              <OptionIcon icon="new_delete" hoverIcon="new_delete_purple">
                Delete
              </OptionIcon>
            </Option>
            {!!defaultValue && defaultValue.is_existing && (
              <>
                {_.map(sectionsCanMoveIn, sId => (
                  <Option key={`move_${supersetId}_to_${sId}`} onClick={() => onMoveToSection(sId)}>
                    <MoveToSection sectionId={sId} />
                  </Option>
                ))}
              </>
            )}
          </Dropdown>
          {shouldShowOnboarding && (
            <div className="section__header__onboarding">
              <OnboardingTooltip onClose={onGuideDone} place="bottom" align="end" showInModal>
                <Content style={{ width: 315 }}>
                  <Title>More customization options</Title>
                  Explore options to add <b>alternate exercises,</b> or change the <b>tracking fields</b> (RPE, Resting
                  Heart Rate, and more)
                </Content>
              </OnboardingTooltip>
            </div>
          )}
        </div>
      )}
      {libraryData ? (
        <ExerciseForm
          open={true}
          originExercise={libraryData}
          mode={MODES.UPDATE}
          closePopup={onCloseExercisePopup}
          onSubmitSuccess={onSaveLibraryData}
        />
      ) : null}
      {createNewExercise ? (
        <ExerciseForm
          open={true}
          mode={MODES.CREATE}
          firstTitle={createNewExercise}
          closePopup={onCloseExercisePopup}
          onSubmitSuccess={onSaveLibraryData}
        />
      ) : null}
      {showAltExercises && (
        <AlternativeExercises
          exercises={alternatives}
          onCancel={closeAltExercisesPopup}
          onSave={onSaveAltExercises}
          originalExercise={_.get(originalExercise, '_id')}
          onUpdateExerciseLibrary={onUpdateExerciseLibraryFromAlternative}
          onSelectTempAltExercises={handleSelectTempAltExercises}
          newUIPopup={isSafari}
        />
      )}
    </S.Wrapper>
  );
}

export default Header;
