import ConfirmModal from 'shared/ConfirmModal';
import styled, { css } from 'styled-components';

// Constants
import { bgDishType } from './constants';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  background: #fff;
`;

export const RecipeDetailWrapper = styled.div`
  width: 100%;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .recipe-loading-indicator {
    padding-top: 0;
  }
`;

export const ContentWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - 60px);
`;

export const Content = styled.div`
  width: 680px;
  margin: 50px auto 75px;
`;

export const Overview = styled.div`
  display: flex;
  gap: 20px;
  ${props =>
    props.isEditMode &&
    css`
      gap: 25px;
    `}
`;

export const Thumbnail = styled.div`
  width: 260px;
  height: 173px;
`;

export const Information = styled.div`
  flex: 1;
`;

export const DietaryInfo = styled.div`
  margin-top: 35px;
  ${({ isEdit }) =>
    isEdit &&
    css`
      margin-top: 20px;
    `}
`;

export const MealDetail = styled.div`
  user-select: none;
  margin-top: 15px;
  ${props =>
    props.isEdit &&
    css`
      margin-top: 40px;
    `}
`;

export const NutritionOverview = styled.div`
  margin-top: 20px;
`;

export const ListCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  padding-left: 10px;
  flex-wrap: wrap;
`;

export const CategoryItem = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  background-color: ${({ dishType }) => bgDishType[dishType] || '#f0f1ff'};
  padding: 4px 10px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 5px;
  }
`;

export const SelectCategoryWrapper = styled.div`
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
`;

export const Hint = styled.div`
  ${baseText}
  color: #7B7E91;
  font-size: 11px;
  line-height: 17px;
  font-weight: 400;
  margin-top: 7px;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.cancel-edit-modal,
  &.ui.modal.confirm-popup-container.save-before-exit-modal {
    width: 467px !important;
    .close-button {
      right: -7px;
    }
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
      .confirm-header-label {
        ${baseText}
        font-size: 21px;
        font-weight: 600;
        line-height: 32px;
      }
    }
    .confirm-content-body {
      padding: 25px 30px 25px;
      .confirm-content-label {
        ${baseText}
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .confirm-actions {
      .button {
        border-radius: 5px;
        ${baseText}
        padding: 5px 24px;
        font-weight: 600;
        margin-left: 8px !important;
      }
    }
  }

  &.ui.modal.confirm-popup-container.cancel-edit-modal {
    .confirm-actions {
      .confirm-no-button {
        min-width: unset;
        width: 146px;
        border: 1px solid #e1e1ea !important;
        color: #777 !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        width: 120px;
        padding: 5px;
        color: #fff;
        background-color: #ea314a !important;
        &:hover {
          background-color: rgba(234, 49, 74, 0.9) !important;
        }
      }
    }
  }

  &.ui.modal.confirm-popup-container.save-before-exit-modal {
    .confirm-actions {
      .confirm-no-button {
        min-width: unset;
        width: 160px;
        border: 1px solid #e1e1ea !important;
        color: #777 !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        width: 77px;
        color: #fff;
        background-color: #5158cf !important;
        &:hover {
          background-color: rgba(81, 88, 207, 0.9) !important;
        }
      }
    }
  }
`;

export const DishType = styled.div`
  margin-top: 40px;
`;

export const SelectCategory = styled.div`
  margin-top: 20px;
`;
