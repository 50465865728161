import styled from 'styled-components';
import { FallbackWrapper } from 'shared/UpgradePath/style';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 32px;
  height: 100%;
  width: 100%;
  overflow: auto;

  .clientStudio__heading {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #202353;
    &--collection {
      margin-top: 50px;
    }
    &--item {
      margin-bottom: 20px;
    }
    .sub-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #202353;
      opacity: 0.8;
      margin-left: 10px;
    }
  }
  .clientStudio__programList {
    display: -webkit-box;
    overflow: auto;
    width: 100%;

    ::-webkit-scrollbar {
      height: 5px !important;
    }
    &--program {
      margin-bottom: 32px;
      padding-bottom: 10px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${FallbackWrapper} {
    margin-bottom: 32px;
  }
`;

export const AddStudioProgramClientButton = styled.div`
  background-color: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 5px;
  width: 194px;
  min-height: 304px;
  background-image: url(${CDN_URL}/images/plus_icon_new.svg);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
  cursor: pointer;
  :hover {
    background-color: #fafafd;
  }
  &.add-workout {
    min-height: 284px;
  }
`;

export const AddCollectionButton = styled(AddStudioProgramClientButton)`
  min-height: 126px;
`;
