import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import { Phone, fadeIn, Preview } from '../style';

export const PlaceholderText = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #a8a8a8;
`;

export const Logo = styled.img`
  max-height: 70px;
  max-width: 180px;
  object-fit: scale-down;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const SplashLogoPlaceholder = styled.div`
  background: #fafafa;
  border: 1px dashed #dedede;
  box-sizing: border-box;
  border-radius: 5px;
  width: 92px;
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const SplashLogoPlaceholderContainer = styled.div`
  width: 92px;
  height: 92px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.logoSize &&
    css`
      width: auto;
      height: auto;
      max-height: 70px;
      max-width: 180px;
    `}
`;

export const ThemeContent = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  :before,
  :after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }

  :before {
    top: 0;
    background-color: ${props => (props.default ? props.primaryColor : props.secondaryColor)};
  }

  :after {
    bottom: 0;
    background-color: ${props => (props.default ? props.secondaryColor : props.primaryColor)};
  }
`;

export const ThemeItem = styled.div`
  background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
  border: 1px solid #dadfea;
  box-sizing: border-box;
  /* margin-right: 12px;
  margin-bottom: 12px; */
  padding: 10px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  :hover {
    border-color: #7470ef;
    cursor: pointer;
  }

  ${props =>
    props.active &&
    css`
      border-color: #7470ef;
    `}

  ${props =>
    props.addNew &&
    css`
      width: 72px;
      height: 72px;

      .add-new-theme-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        display: none;
      }

      :hover {
        border-color: transparent;

        .add-new-theme-hover {
          display: block;
        }
      }
    `}

  .__react_component_tooltip.app-tooltip-white {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #323c47 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-color: #e8e8e8;
    position: absolute !important;
    top: unset !important;
    left: 50% !important;
    bottom: 60px !important;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;

export const ThemesContainer = styled.div`
  /* display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(72px, 72px));
  grid-gap: 12px;
`;

export const PhoneContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhoneContainer = styled.div`
  padding: 8px 10px 9px;
  position: relative;
  height: 480px;
  width: 244px;
  flex: 0 0 244px;
  overflow: hidden;

  :before {
    content: '';
    background: transparent url(${CDN_URL}/images/iphone_layout_dark.svg) no-repeat;
    background-position: top left;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
`;

export const PreviewContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${PhoneContainer} {
    :first-child {
      margin-right: 14px;
    }
  }
`;

export const PreviewWrapper = styled(Preview)`
  background-color: #fff;
  padding: 0 20px;
`;

export const NoticeBanner = styled.div`
  margin-top: 30px;
  width: 100%;
  position: relative;
  border: none;
  ::before {
    content: '';
    background: linear-gradient(269.04deg, rgba(253, 200, 48, 0.63) 1.1%, #f37335 99.38%);
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 0;
    width: calc(100% + 2px);
    height: 72px;
    border-radius: 6px;
  }
  p {
    color: #000000;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    -webkit-font-smoothing: initial;
    span {
      color: #fcc037;
      font-weight: bold;
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export const BannerContent = styled.div`
  position: inherit;
  background: linear-gradient(90.34deg, #fffaee 24.25%, #fffcf4 95.68%);
  height: 70px;
  vertical-align: middle;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
