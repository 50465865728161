import React, { useState } from 'react';
import AddProgressPhoto from 'components/AddProgressPhoto';
import EditProgressPhoto from 'components/EditProgressPhoto';

const ProgressPhotoWrapper = ({ isView = false, onClose }) => {
  const [isViewState, setIsViewState] = useState(isView);
  const [isFromEdit, setIsFromEdit] = useState(isView);
  return (
    <>
      {isViewState ? (
        <EditProgressPhoto
          handleAddPhoto={(isFromEdit = true) => {
            setIsFromEdit(!isFromEdit);
            setIsViewState(false);
          }}
          onClose={onClose}
        />
      ) : (
        <AddProgressPhoto
          handleUploadSuccess={() => {
            setIsViewState(true);
            onClose && onClose();
          }}
          isFromEdit={isFromEdit}
        />
      )}
    </>
  );
};

export default ProgressPhotoWrapper;
