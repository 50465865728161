// Libs
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';

// Components
import LoadingIndicator from 'shared/LoadingIndicator';
import HourlyChart from './HourlyChart';
import RangeChart from './RangeChart';
import SleepSwitch from './SleepSwitch';

// Actions
import { filterChartViewMode } from 'actions/bodyMetric';

// Constants
import { DATE_FORMAT, PERIOD_GROUP, getTimeRangeUnitFromRange } from 'components/BodyMetricChartNew/constants';
import { SLEEP_VIEW } from './constants';

// Assets
import { ReactComponent as RangeChartIcon } from 'assets/icons/range-chart.svg';
import { ReactComponent as ChartBarIcon } from 'assets/icons/bar-chart-2.svg';
import { ReactComponent as HourlyChartIcon } from 'assets/icons/sleep-chart.svg';
import { ReactComponent as ArrowNextIcon } from 'assets/icons/arrow-circle.svg';

import { checkDataHasStage, checkIsAllInBed, checkIsOnlyAsleepInBed } from './hourly-helper';

// Styles
import HourlyTimeConnectedStagesChart from './HourlyTimeConnectedStagesChart';
import * as S from 'components/BodyMetricChartNew/components/styles';
import 'components/BodyMetricChartNew/components/style.scss';
import './style.scss';

class SleepChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTime: true,
    };
  }

  renderChart = () => {
    const { filterTime, sleepSettings } = this.props;
    const props = { ...this.props };

    let FinalChart = RangeChart;

    if (filterTime.period_group === PERIOD_GROUP.HOURLY) {
      FinalChart = HourlyChart;

      if (
        sleepSettings.viewBy === SLEEP_VIEW.TIME &&
        (sleepSettings.connectedApp || sleepSettings.hasAsleepStageInRange) &&
        sleepSettings.isShowStages &&
        props.chartData.length > 0
      ) {
        if (
          checkDataHasStage(props.chartData) &&
          !checkIsAllInBed(props.chartData) &&
          !checkIsOnlyAsleepInBed(props.chartData)
        ) {
          FinalChart = HourlyTimeConnectedStagesChart;
        }
      }
    }

    return <FinalChart {...props} />;
  };

  handleChangeRange = isNext => () => {
    const {
      filterChartViewMode,
      filterTime: { from_date, to_date, period_group, timeRangeString },
    } = this.props;
    const fromDateMoment = moment(from_date, DATE_FORMAT);
    const toDateMoment = moment(to_date, DATE_FORMAT);
    const diff = toDateMoment.diff(fromDateMoment, 'd') + 1;
    const timeUnit = getTimeRangeUnitFromRange(timeRangeString, diff);
    const newFromDate = isNext
      ? fromDateMoment.add(timeUnit.value, timeUnit.timeTitle)
      : fromDateMoment.subtract(timeUnit.value, timeUnit.timeTitle);
    const newToDate = isNext
      ? toDateMoment.add(timeUnit.value, timeUnit.timeTitle)
      : toDateMoment.subtract(timeUnit.value, timeUnit.timeTitle);

    filterChartViewMode(newFromDate.format(DATE_FORMAT), newToDate.format(DATE_FORMAT), period_group, timeRangeString);
  };

  render() {
    const {
      isLoading,
      chartData,
      minHeight = 337,
      filterTime,
      noDataLabel = 'No data available',
      bodyMetric,
      isGroupOverview,
      isOverview,
      sleepSettings,
    } = this.props;
    const { isFirstTime } = this.state;
    const { isGettingSetting } = bodyMetric || {};
    const isHourlyChart = filterTime.period_group === PERIOD_GROUP.HOURLY;
    const disabledNext = moment().diff(moment(filterTime.to_date, this.DATE_FORMAT), 'd') <= 0;
    const isSleepTime = sleepSettings.viewBy === SLEEP_VIEW.TIME;
    const EmptyDataIcon = isHourlyChart ? HourlyChartIcon : isSleepTime ? RangeChartIcon : ChartBarIcon;

    return (
      <S.ChartContainer>
        <S.Wrapper
          minHeight={minHeight}
          isHideAxis={!chartData.length}
          className={classNames('chart-wrapper sleep-chart-wrapper', {
            'range-chart': !isHourlyChart,
            'hourly-chart': isHourlyChart,
            'is-overview': isOverview,
            'is-no-data': !chartData.length && !isLoading && !isGettingSetting,
          })}
          isOverview={isOverview}
          isOverviewAndOverviewGroup={isGroupOverview || isOverview}
          isHideYAxis={!chartData.length}
        >
          {(isLoading || (isFirstTime && isGettingSetting)) && (
            <LoadingIndicator title="Loading chart..." className="loading-chart-indicator" />
          )}
          {!isOverview && (
            <>
              <button className="chart-arrow-btn chart-prev-btn" onClick={this.handleChangeRange(false)}>
                <ArrowNextIcon />
              </button>
              <button
                className="chart-arrow-btn chart-next-btn"
                onClick={this.handleChangeRange(true)}
                disabled={disabledNext}
              >
                <ArrowNextIcon />
              </button>
            </>
          )}
          {this.renderChart()}
          {!isOverview && (
            <SleepSwitch
              isLoading={isLoading}
              chartData={chartData}
              isHourlyChart={isHourlyChart}
              rangeTime={this.props.rangeTime}
            />
          )}
          {!chartData.length && !isLoading && !isGettingSetting && (
            <div className="chart-no-data">
              <div className="no-data-wrapper">
                <EmptyDataIcon
                  width={32}
                  className={classNames({
                    'sleep-time-empty-chart': isSleepTime,
                  })}
                />
                <p>{noDataLabel}</p>
              </div>
            </div>
          )}
        </S.Wrapper>
      </S.ChartContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  filterChartViewMode: bindActionCreators(filterChartViewMode, dispatch),
});

const mapState = ({ bodyMetric }) => ({
  filterTime: bodyMetric.filterTime,
  sleepSettings: bodyMetric.sleepSettings || {},
});

export default connect(mapState, mapDispatchToProps)(SleepChart);
