'use strict';

import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Types } from './task.actionCreators';
import { DATE_FORMAT } from 'constants/commonData';

export const DEFAULT_STATE = {
  tasks: [],
  day_tasks: [],
  taskCalendarStartDate: DateTime.local().startOf('week'),
};

const TaskReducer = (state = DEFAULT_STATE, action) => {
  let { tasks, day_tasks } = state;
  switch (action.type) {
    case 'REMOVE_TASK_REDUX':
      _.remove(tasks, item => {
        return item._id === action.payload.taskId;
      });
      return Object.assign({}, state, {
        tasks,
      });
    case 'APPEND_TASK_DAY_REDUX':
      // let day_task = action.payload.day_task;
      // _.remove(day_tasks, item => {
      //   return item._id === day_task._id;
      // });
      // day_tasks.push(day_task);

      // logic arrange tasks, just replace tasks in day_tasks by id
      const dayTaskIndex = _.findIndex(day_tasks, ['_id', _.get(action.payload, '_id', undefined)]);
      if (dayTaskIndex !== -1) {
        if (!_.isEmpty(_.get(day_tasks, dayTaskIndex, undefined))) {
          day_tasks[dayTaskIndex].tasks = _.get(action.payload, 'tasks', []);
        }
      }

      return Object.assign({}, state, { day_tasks });
    case 'APPEND_TASK_DAYS_REDUX':
      // let new_day_tasks = action.payload.day_tasks;
      // _.forEach(new_day_tasks, day_task => {
      //   _.remove(day_tasks, item => {
      //     return item.day === day_task.day;
      //   });
      //   day_tasks.push(day_task);
      // });

      // logic move tasks between 2 days, just replace _id, tasks in day_tasks by day
      _.forEach(action.payload, it => {
        const _id = _.get(it, '_id', undefined);
        const day = _.get(it, 'day', undefined);
        const tasks = _.get(it, 'tasks', []);
        const dayTaskIndex = _.findIndex(day_tasks, ['day', day]);
        if (dayTaskIndex !== -1) {
          if (!_.isEmpty(_.get(day_tasks, dayTaskIndex, undefined))) {
            // need replace id day_task
            day_tasks[dayTaskIndex]._id = _id;
            day_tasks[dayTaskIndex].tasks = tasks;
          }
        }
      });

      return Object.assign({}, state, { day_tasks });
    case 'SUCCESS_GET_TASKS':
      return Object.assign({}, state, {
        day_tasks: action.payload.data,
      });
    case 'SUCCESS_GET_REPEAT_TASKS': {
      const { data } = action.payload;
      const day_tasks = _.cloneDeep(state.day_tasks);
      const currentDay = _.find(day_tasks, it => it.day === data.day && it.client === data.client);
      if (currentDay) {
        currentDay.tasks = _.uniqBy([...currentDay.tasks, data], '_id');
      }
      const tasks = _.map(day_tasks, day => {
        return currentDay && day.day === currentDay.day ? currentDay : day;
      });
      return Object.assign({}, state, {
        day_tasks: tasks,
      });
    }
    case 'COPY_TASK':
      return Object.assign({}, state, {
        copyingTask: action.payload.data,
      });
    case 'COPIED_TASK':
      return Object.assign({}, state, {
        copyingTask: null,
      });
    case 'CLIENT_TASK_CLEAR_COPY_ITEM':
      return Object.assign({}, state, { copyingTask: null });

    case Types.MOVE_TASK:
      return handleMoveTask(action.payload.data, state);

    case Types.UPDATE_TASK_SUCCESS: {
      const tasks = _.map(state.day_tasks, day => {
        return {
          ...day,
          tasks: _.filter(day.tasks, task => task._id !== action.payload.data._id),
        };
      });
      return Object.assign({}, state, {
        day_tasks: tasks,
      });
    }

    case Types.REMOVE_TASKS_REPEATING_SUCCESS: {
      const day_tasks = _.cloneDeep(state.day_tasks);
      const ids = _.map(action.payload.data, item => item._id);
      const tasks = _.map(day_tasks, day => {
        return {
          ...day,
          tasks: _.filter(day.tasks, task => !_.includes(ids, task._id)),
        };
      });
      return Object.assign({}, state, { day_tasks: tasks });
    }

    case Types.CLIENT_TASK_REMOVE_REPEAT_SETTING: {
      const { taskDay, repeatId } = action.payload;
      const fromDay = moment(taskDay, DATE_FORMAT);

      const newDayTask = _.map(state.day_tasks, item => {
        if (moment(item.day, DATE_FORMAT).isSameOrAfter(fromDay, 'date')) {
          return {
            ...item,
            tasks: _.filter(item.tasks, task => _.get(task, 'repeat_task_setting_id._id') !== repeatId),
          };
        }

        return item;
      });

      return Object.assign({}, state, { day_tasks: newDayTask });
    }

    case Types.SOCKET_HABIT_UPDATED:
      _.forEach(day_tasks, it => {
        if (!_.isEmpty(it.habits)) {
          const dayHabitIndex = _.findIndex(it.habits, ['_id', _.get(action.payload, '_id')]);
          if (dayHabitIndex !== -1) {
            if (!_.isEmpty(_.get(it.habits, dayHabitIndex))) {
              it.habits[dayHabitIndex].has_tracked = _.get(action.payload, 'has_tracked');
              it.habits[dayHabitIndex].current_streak = _.get(action.payload, 'current_streak');
              it.habits[dayHabitIndex].best_streak = _.get(action.payload, 'best_streak');
            }
          }
        }
      });
      return Object.assign({}, { day_tasks });

    default:
      return state;
  }
};

const handleMoveTask = (params, oldState) => {
  const tasks = oldState.day_tasks;
  const { newIndex, autoflow, newDate, taskId, dayTaskId } = params;
  const newDay = moment(newDate).format('MM-DD-YYYY');
  const fromDayTaskIndex = _.findIndex(oldState.day_tasks, o => o._id === dayTaskId);
  const toDayTaskIndex = _.findIndex(oldState.day_tasks, o => o.day === newDay);
  const fromIndex = tasks[fromDayTaskIndex].tasks.findIndex(t => t._id === taskId);
  const taskMoving = tasks[fromDayTaskIndex].tasks.find(t => t._id === taskId);
  taskMoving.day = newDay;
  tasks[fromDayTaskIndex].tasks.splice(fromIndex, 1);

  if (toDayTaskIndex !== -1) {
    tasks[toDayTaskIndex].tasks.splice(newIndex, 0, taskMoving);
  } else {
    tasks.push({
      autoflow: autoflow,
      day: newDay,
      tasks: [taskMoving],
      _id: taskMoving._id,
    });
  }
  return Object.assign({}, oldState, { day_tasks: tasks });
};

export default TaskReducer;
