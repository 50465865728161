import React from 'react';
import get from 'lodash/get';

import * as S from '../style';

const SingleChoiceResponse = ({ answer }) => {
  return <S.QuestionAnswer>{get(answer, 'single_choice_answer.label', '')}</S.QuestionAnswer>;
};

export default SingleChoiceResponse;
