import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import { getPublishedProducts } from 'redux/product/actions';
import { axiosInstance } from 'configs/request';
import ProductList from './ProductList';
import { PRODUCT_STATUS } from 'components/Product/constants';
import { CDN_URL } from 'constants/commonData';
import WarningPopup from './WarningPopup';
import WarningTrialPopup from './WarningTrialPopup';

import { ReactComponent as UploadIcon } from 'assets/icons/upload_banner_icon.svg';
import { ReactComponent as Launch } from 'assets/icons/launch_link.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/white_plus.svg';

import * as S from './style';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

const MAX_LENGTH = 32;
export function cutString(str) {
  return !!str && str.length > MAX_LENGTH ? str.substring(0, MAX_LENGTH) + '...' : str;
}

const AssignProduct = ({
  isEdit = false,
  toggleConfirmModal,
  permission,
  getPublishedProducts,
  counter,
  onSelectedProduct,
  product = [],
  onSaveDraft,
  toggleSecondModal,
  price,
  cloudfrontList,
}) => {
  const [currentProduct, setCurrentProduct] = useState([]);
  const { is_allow_multiple_products } = permission;

  useEffect(() => {
    setCurrentProduct(product);
  }, [product]);

  const handleAddProduct = () => {
    toggleConfirmModal(
      true,
      <ProductList
        onClose={handleOnClose}
        onClickCreateNew={handleCreatePackage}
        getPublishedProducts={getPublishedProducts}
        counter={counter}
        onSave={handleSave}
        product={currentProduct}
        is_allow_multiple_products={is_allow_multiple_products}
      />,
    );
  };

  const handleSave = product => {
    const handleShowAutoflowConflictPopup = () => {
      if (product.length === 2) {
        const params = {
          productIds: product.map(item => item.id),
        };
        axiosInstance.post('/api/payment/products/check-conflict-assets', params).then(res => {
          if (get(res, 'data.data.isConflict')) {
            toggleSecondModal(true, <WarningPopup secondProduct={product[1]} />);
          }
        });
      }
    };

    const handleAcceptTrialWarning = () => {
      setCurrentProduct(product);
      onSelectedProduct(product, true);
      toggleSecondModal(false);
      handleShowAutoflowConflictPopup();
      handleOnClose();
    };

    if (get(price, 'enable_trial', false)) {
      toggleSecondModal(true, <WarningTrialPopup isSecondModalOpen={true} onAccept={handleAcceptTrialWarning} />);
    } else {
      setCurrentProduct(product);
      onSelectedProduct(product, true);
      handleShowAutoflowConflictPopup();
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    toggleConfirmModal(false);
  };

  const handleCreatePackage = () => {
    handleOnClose();
    onSaveDraft && onSaveDraft('/home/sequences');
  };

  const handleDetailProduct = hash_id => {
    window.open(`/home/sequences/${hash_id}`);
  };

  const handleRemoveProduct = id => {
    setCurrentProduct(currentProduct.filter(item => item.id !== id));
    onSelectedProduct({ id }, false);
  };

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title>Sequence</S.Title>
        {is_allow_multiple_products && isEdit && currentProduct.length === 1 ? (
          <S.PlusIconContainer onClick={handleAddProduct}>
            <PlusIcon />
          </S.PlusIconContainer>
        ) : (
          <div />
        )}
      </S.TitleWrapper>
      {!currentProduct.length ? (
        <S.NewSection isEdit={isEdit} onClick={handleAddProduct}>
          <S.IconWrapper />
          <S.AddProductTitle>Add a sequence</S.AddProductTitle>
        </S.NewSection>
      ) : (
        currentProduct.map(item => (
          <S.OriginalProduct isEdit={isEdit} key={item.id}>
            <S.Thumbnail thumbnail={convertS3UrlToCloudFrontUrl(item.cover_image, cloudfrontList, true) || ''}>
              <UploadIcon width={25} />
            </S.Thumbnail>
            <S.Desc>
              <S.DescTitle>{item.name}</S.DescTitle>
              <S.DescCounter>
                Assets: <S.TextBold>{get(item, 'assets_count', 0)}</S.TextBold>
              </S.DescCounter>
              <S.Link onClick={() => handleDetailProduct(item.hash_id)}>
                Go to sequence <Launch />
              </S.Link>
            </S.Desc>
            <S.RemoveIcon
              src={`${CDN_URL}/images/close_circle.svg`}
              onClick={() => handleRemoveProduct(item.id)}
              alt=""
            />
            {item.status === PRODUCT_STATUS.ARCHIVE && <S.ArchivedStatus>Archived</S.ArchivedStatus>}
          </S.OriginalProduct>
        ))
      )}
    </S.Wrapper>
  );
};

const mapState = state => {
  const {
    cloudfrontList,
    rootReducer: { product, permission },
  } = state;

  return { counter: get(product.get('counter').toJS(), 'publish', 0), permission, cloudfrontList };
};

const actionCreators = {
  toggleConfirmModal,
  push,
  getPublishedProducts,
  toggleSecondModal,
};

export default connect(mapState, actionCreators)(AssignProduct);
