import { Types } from './actions';

const PAGE_SIZE = 20;

export const ON_DEMAND_WORKOUTS_SORTER = {
  TITLE: 'title',
  LEVEL: 'level',
  DURATION: 'duration',
  MOST_RECENT: 'most_recent',
};

export const ON_DEMAND_WORKOUTS_SORT = {
  ASC: '1',
  DESC: '-1',
};

const INITIAL_STATE = {
  list: [],
  loading: false,
  total: 0,
  query: {
    page: 1,
    perPage: PAGE_SIZE,
    sort: ON_DEMAND_WORKOUTS_SORT.DESC,
    sorter: ON_DEMAND_WORKOUTS_SORTER.MOST_RECENT,
    textSearch: '',
  },
  workingWorkout: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.ON_DEMAND_WORKOUTS_GET_LIST_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.ON_DEMAND_WORKOUTS_GET_LIST_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        list: payload.list,
        total: payload.total,
      });
    }
    case Types.ON_DEMAND_WORKOUTS_REMOVE_WORKOUT_SUCCESS: {
      // const { list = [] } = state;
      // const newList = list.filter(it => it._id !== payload.data);
      // const total = newList.length;
      // return Object.assign({}, state, {
      //   list: newList,
      //   total,
      // });
      return state;
    }
    case Types.ON_DEMAND_WORKOUTS_ADD_NEW_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case Types.ON_DEMAND_WORKOUTS_ADD_NEW_SUCCESS: {
      // const currentList = state.list;
      // return Object.assign({}, state, { loading: false, list: concat(payload, currentList) });
      return state;
    }

    case Types.ON_DEMAND_WORKOUTS_UPDATE_SUCCESS: {
      return state;
      // let clonedList = cloneDeep(state.list);
      // const updateIndex = findIndex(clonedList, { _id: payload.data._id });
      // clonedList.splice(updateIndex, 1);
      // clonedList.unshift(payload.data);
      // return Object.assign({}, state, { list: clonedList });
    }

    case Types.ON_DEMAND_WORKOUTS_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, { query: payload });
    }
    case Types.ON_DEMAND_WORKOUTS_CHANGE_SHARE_STATUS: {
      return Object.assign({}, state, {
        list: payload || state.list,
      });
    }

    default:
      return state;
  }
};
