import React from 'react';
import _, { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import MetricSetting from 'shared/MetricSetting';
import {
  updateClientMetric,
  updateMultipleClientMetric,
  addClientMetric,
  deleteClientMetric,
} from 'redux/client/client.actionCreators';
import { ONBOARDING_STEPS } from 'constants/commonData';
import { runOnboarding } from 'redux/onboarding/actions';

function ClientMetricSetting(props) {
  const {
    metrics,
    unitCategories,
    clientId,
    showGuide,
    handleRefreshBodyMetric,
    modeProp = '',
    isFromToolbar = false,
  } = props;

  const onClose = data => {
    props.toggleModal(false);
    if (!isEmpty(data)) {
      handleRefreshBodyMetric && handleRefreshBodyMetric();
    }
  };

  const onSaveSetting = items => props.updateMultipleClientMetric({ items, clientId });

  const onUpdate = metricData => props.updateClientMetric({ ...metricData, clientId });

  const onAdd = metricData => props.addClientMetric({ ...metricData, clientId });

  const onDelete = metricData => props.deleteClientMetric({ unique_code: metricData.unique_code, clientId });

  const onCloseOnboardingTooltip = () => props.runOnboarding();

  return (
    <MetricSetting
      subTitle="Select the metrics you want your client to use for progress tracking and accountability."
      originMetrics={metrics}
      unitCategories={unitCategories}
      onSave={onSaveSetting}
      onClose={onClose}
      onUpdate={onUpdate}
      onAdd={onAdd}
      onDelete={onDelete}
      onCloseOnboardingTooltip={onCloseOnboardingTooltip}
      showGuide={showGuide}
      modeProp={modeProp}
      isFromToolbar={isFromToolbar}
      clientId={clientId}
    />
  );
}

const mapState = state => {
  const {
    rootReducer: {
      unit: { unit_categories },
      client: { workingClientDetail, bodymetricTypes },
      onboarding: { running, showTooltip },
    },
  } = state;

  return {
    clientId: _.get(workingClientDetail, '_id'),
    metrics: bodymetricTypes || [],
    unitCategories: unit_categories || [],
    showGuide: running === ONBOARDING_STEPS.CUSTOMIZE_METRIC_STEP_2 && showTooltip,
  };
};

const actionCreators = {
  toggleModal,
  updateClientMetric,
  updateMultipleClientMetric,
  addClientMetric,
  deleteClientMetric,
  runOnboarding,
};

export default connect(mapState, actionCreators)(ClientMetricSetting);
