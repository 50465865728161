import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import CacheBuster from 'react-cache-buster';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
import { install } from 'resize-observer';
import preval from 'preval.macro';
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from 'components/App';
import store, { history } from 'configureStore';
import { unregister, registerLocalStorageChange } from 'registerServiceWorker';
import WebSocketProvider from './libs/socket';
import packageJson from '../package.json';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './extensions';
import './semantic/dist/semantic.min.css';
import './index.scss';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

const root = document.getElementById('root');

const getFeatureName = () => {
  const name = process.env.REACT_APP_FEATURE_NAME || '';
  return name ? `: ${name.replaceAll('-', ' ')}` : '';
};

if (process.env.REACT_APP_GA_APP) {
  ReactGA.initialize(process.env.REACT_APP_GA_APP);
}

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

if (typeof window !== 'undefined') {
  install();
}

// remove console logs excludes info, error log
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

let persistor = persistStore(store);
console.info('======== Build information ======== ');
console.info('Environment:', process.env.REACT_APP_NODE_ENV);
console.info('Build version:', packageJson.version);
console.info('Build Timestamp:', preval`module.exports = new Date();`);
console.info('=================================== ');

if (root) {
  ReactDOM.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <CacheBuster currentVersion={packageJson.version} isEnabled={true} isVerboseMode={false}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <WebSocketProvider>
              <Router history={history}>
                <Elements stripe={stripePromise}>
                  <MixpanelProvider mixpanel={mixpanel}>
                    <App />
                    {process.env.REACT_APP_IS_QA_MODE && (
                      <div className="debug-mode-settings">QA Mode{getFeatureName()}</div>
                    )}
                  </MixpanelProvider>
                </Elements>
              </Router>
            </WebSocketProvider>
          </PersistGate>
        </Provider>
      </CacheBuster>
    </GoogleOAuthProvider>,
    root,
  );
  registerLocalStorageChange();
  unregister();
}
