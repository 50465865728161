import { connect } from 'react-redux';
import _ from 'lodash';
import AlternativeExercises from './component';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { exercise } = rootReducer;

  return {
    mostRecents: _.map(exercise.mostRecents || [], item =>
      Object.assign({}, item, {
        key: item._id,
        label: item.title,
        value: item._id
      })
    )
  };
};

export default connect(mapStateToProps, null)(AlternativeExercises);
