import styled, { css } from 'styled-components';

import ViewLarge from 'assets/icons/view_lager.svg';
import ViewLargeSelect from 'assets/icons/view_lager_select.svg';
import ViewLargeLight from 'assets/icons/view_lager_light.svg';
import ViewSquares from 'assets/icons/view_squares.svg';
import ViewSquaresSelect from 'assets/icons/view_squares_select.svg';
import ViewSquaresLight from 'assets/icons/view_squares_light.svg';
import ViewNarrow from 'assets/icons/view_narrow.svg';
import ViewNarrowSelect from 'assets/icons/view_narrow_select.svg';
import ViewNarrowLight from 'assets/icons/view_narrow_light.svg';
import ViewList from 'assets/icons/view_list.svg';
import ViewListSelect from 'assets/icons/view_list_select.svg';
import ViewListLight from 'assets/icons/view_list_light.svg';

export const Wrapper = styled.div``;
export const DropdownWrapper = styled.div`
  .evf-dropdown__menu {
    padding: 0;
    background-color: #fff;
    overflow: visible;
  }
`;

export const Arrow = styled.div`
  width: 9px;
  height: 9px;
  border: 1px solid #d4d7d9;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  position: absolute;
  right: 15px;
  top: 1px;
  background: #fff;
`;
export const LayoutOptions = styled.div`
  display: flex;
  width: 106px;
  height: 32px;
  margin-left: 24px;
  margin-right: 10px;
  padding-right: 7px;
  border: 1px solid #ebedf4;
  border-radius: 3px;
  align-items: center;
  flex-direction: row;

  i.mini.icon {
    color: #a1a1a5;
    font-size: 10px;
    padding: 2px 8px;
  }
`;

export const LayoutName = styled.div`
  width: 32px;
`;
export const LayoutTitle = styled.div`
  font-size: 11px;
  line-height: 100%;
  color: #777777;
  padding-left: 8px;
  letter-spacing: 0.5px;
`;
export const ViewType = styled.div`
  height: 100%;
  width: 32px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.large_card {
    background-image: url(${ViewLargeSelect});
  }
  &.square_card {
    background-image: url(${ViewSquaresSelect});
  }
  &.narrow_card {
    background-image: url(${ViewNarrowSelect});
  }
  &.list {
    background-image: url(${ViewListSelect});
  }
`;

export const OptionsWrapper = styled.div`
  width: 520px;
  height: 294px;
  border-radius: 5px;
  border: 1px solid #d4d7d9;
  background-color: #fff;
  padding: 30px;
  margin-top: 5px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #202353;
`;

export const TypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 23px;
  padding-bottom: 30px;
`;

export const Type = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${ViewLarge});
  background-size: 100%;

  &.large_card {
    background-image: url(${ViewLarge});
    &:hover {
      background-image: url(${ViewLargeLight});
    }
    ${props =>
      props.isActive &&
      css`
        background-image: url(${ViewLargeLight});
      `}
  }
  &.square_card {
    background-image: url(${ViewSquares});
    &:hover {
      background-image: url(${ViewSquaresLight});
    }
    ${props =>
      props.isActive &&
      css`
        background-image: url(${ViewSquaresLight});
      `}
  }
  &.narrow_card {
    background-image: url(${ViewNarrow});
    &:hover {
      background-image: url(${ViewNarrowLight});
    }
    ${props =>
      props.isActive &&
      css`
        background-image: url(${ViewNarrowLight});
      `}
  }
  &.list {
    background-image: url(${ViewList});
    &:hover {
      background-image: url(${ViewListLight});
    }
    ${props =>
      props.isActive &&
      css`
        background-image: url(${ViewListLight});
      `}
  }
`;

export const TypeName = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  text-align: center;
  margin-top: 16px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: end;
  button {
    padding: 6px 22px;
    &:first-child {
      background-color: transparent;
      border: none;
    }
  }
`;
