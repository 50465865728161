// Libs
import styled from 'styled-components';

export const AssignedTriggerWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 11px;
  gap: 8px;
  background: linear-gradient(3.79deg, #ffeae1 0%, #fffcf2 68.87%), #ffffff;
  border: 1px solid #ff6433;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  .title-banner {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }

  .description-banner {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }

  .used-in-label {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
  }

  .onboarding-flow-detail-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 7px 0px 0px 0px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    width: 100%;
  }

  .onboarding-flow-name-wrapper {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    margin: 0px 7px 7px 7px;
    background: #fafbfc;
    border: 1px solid #d4d7d9;
    border-radius: 5px;

    &.active,
    &:hover {
      border: 1px solid #5e59ff;
    }

    &:hover {
      .hide-go-to-icon .go-to-icon {
        visibility: visible;
        display: inline-flex;
      }
    }
  }

  .onboarding-flow-name {
    position: relative;
    text-overflow: ellipse;
    white-space: nowrap;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    overflow: hidden;
    color: #000000;
    min-height: 18px;
  }

  .options-wrapper {
    padding: 0px 4px 0px 8px;
    width: 100%;
  }

  .scrollable-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    padding: 0px 4px 8px 0px;
    gap: 12px;
    width: 100%;
    height: 100%;
    max-height: 227px;

    &::-webkit-scrollbar-track {
      margin-top: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e6e6e6 !important;
    }
    &::-webkit-scrollbar {
      width: 4px !important;
    }
  }

  .option-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3px;
    width: 100%;
  }

  .option-name {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    width: 100%;
    white-space: nowrap;
    word-break: nowrap;
    text-overflow: ellipse;
    overflow: hidden;
  }

  .assign-to-wrapper {
    display: grid;
    grid-template-columns: 20px auto 1fr;
    align-items: center;
    gap: 4px;
    width: 100%;
  }

  .assign-to-label {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #323c47;
    margin-left: 4px;
  }

  .assign-to-program-forum-label {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    white-space: nowrap;
    word-break: nowrap;
    text-overflow: ellipse;
    overflow: hidden;
  }

  .tabs-wrapper {
    padding: 0px 8px;
    margin-bottom: 8px;
  }

  .tabs-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    align-items: stretch;
    padding: 2px;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .tab-item {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 2px 4px;
    border: none;
    border-radius: 4px;
    background: transparent;
    cursor: pointer;

    &:hover {
      background: transparent;
    }

    &.active {
      background: #ffffff;
    }
  }

  .tab-item-label {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    color: #323c47;
  }

  .action-icons {
    display: inline-flex;
    gap: 12px;
  }

  .arrow-down-icon {
    margin: 2px 3px 0px 0px;
  }

  .hide-go-to-icon .go-to-icon {
    visibility: hidden;
    display: none;
  }

  .hide-dropdown-icon .arrow-down-icon {
    visibility: hidden;
    display: none;
  }
`;
