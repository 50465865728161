import styled, { css } from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 179px;
  border: 1px solid #f0f0f2;
  border-radius: 5px;
`;

export const ItemHeader = styled.div`
  display: flex;
  height: 54px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #f0f0f2;
`;

export const ItemContent = styled.div`
  display: flex;
  padding: 20px 15px 0;
  flex-direction: column;
  background: #fff;
`;

export const Metrics = styled.div`
  display: flex;
  height: 25px;
  width: 100%;
  border-radius: 3px;
  background: #f0f0f2;
  flex: 1 0 0;
  align-self: stretch;
`;

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 25px;
  margin-bottom: 22px;
`;

export const MetricNumber = styled.div`
  height: 13px;
  width: 65px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 3px;
  background: #f0f0f2;
`;

export const BottomGroup = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const AvatarWrapper = styled.div`
  width: 20px;
  height: 20px;
  background: #f0f0f2;
  border-radius: 50%;
`;
