import React from 'react';
import { GlobalSearchResultsWrapper } from './style';
import SearchItem from './SearchItem';
import HotkeyIcon from './HotkeyIcon';
import ReactTooltip from 'react-tooltip';

export default class GlobalSearchResults extends React.PureComponent {

  handleSelectClient = (client) => {
    this.props.onSelectClient(client);
  }

  render () {
    const { clients, txtSearch, isSearching } = this.props;
    return (
      <GlobalSearchResultsWrapper>
        <div className="globalSearchResults__heading">
          <div className="globalSearchResults__mostRecents">
            {txtSearch ? 'Results' : 'Most recent'} ({clients.length})
          </div>
          <div className="globalSearchResults__hotkey">
            <span className="globalSearchResults__hotkey--text" data-tip data-for="search_hotkey" >Hotkey</span>
            <HotkeyIcon />
            <ReactTooltip
              delayShow={100}
              className="app-tooltip"
              id="search_hotkey"
              effect="solid"
              place={'bottom'}
            >
              <span>Press “J” to open the search</span>
            </ReactTooltip>
          </div>
        </div>
        <div className="globalSearchResults__body">
          {isSearching && <div className="globalSearchResults__body--noResults">Loading...</div>}
          {!isSearching && !clients.length && <div className="globalSearchResults__body--noResults">No results</div>}
          {!isSearching && clients.map(client => <SearchItem key={client._id} client={client} onSelectClient={this.handleSelectClient} onCloseSearch={this.props.onCloseSearch} />)}
        </div>
      </GlobalSearchResultsWrapper>
    )
  }
}
