import { CDN_URL } from 'constants/commonData';

export default {
  all_clients_total: 10,
  data: [
    {
      groups: [],
      is_archived: false,
      _id: '5eccb7dac2602f4561b6580b',
      client_connection: 1,
      client_type: 'Online',
      email: 'ben@demo',
      avatar: `${CDN_URL}/images/fake_client_avatar_1.png`,
      full_name: 'Michael Phillips',
      full_name_sort: 'ben andrew - demo',
      color: '#79AAF2',
      last_engaged: '2020-05-16T06:32:57.128Z',
      last_activity: '2020-05-25T00:00:00.000Z',
      trainer_full_name: 'Cristian Ronaldo',
      last_assigned_workout: '2020-06-14T00:00:00.000Z',
      completed_task_30d: 1,
      completed_task_7d: 0,
      percent_task_completed_30d: 0.68,
      percent_task_completed_7d: 0,
      total_task_30d: 3,
      total_task_7d: 0,
      completed_assignment_30d: 10,
      completed_assignment_7d: 2,
      percent_completed_30d: 0.68,
      percent_completed_7d: 0.2,
      total_assignment_30d: 23,
      total_assignment_7d: 10,
      inbox_preferences: {},
    },
    {
      groups: [],
      is_archived: false,
      _id: '5eccb7dac2602f4561b6580c',
      client_connection: 1,
      client_type: 'Hybrid',
      email: 'BessieHenry@demo',
      avatar: `${CDN_URL}/images/fake_client_avatar_2.png`,
      full_name: 'Bessie Henry',
      full_name_sort: 'Bessie Henry',
      color: '#79AAF2',
      last_engaged: '2020-05-16T06:32:57.128Z',
      last_activity: '2020-05-25T00:00:00.000Z',
      trainer_full_name: 'Cristian Ronaldo',
      last_assigned_workout: '2020-06-14T00:00:00.000Z',
      completed_task_30d: 1,
      completed_task_7d: 0,
      percent_task_completed_30d: 0.68,
      percent_task_completed_7d: 0,
      total_task_30d: 3,
      total_task_7d: 0,
      completed_assignment_30d: 68,
      completed_assignment_7d: 68,
      percent_completed_30d: 0.68,
      percent_completed_7d: 0.668,
      total_assignment_30d: 100,
      total_assignment_7d: 100,
      inbox_preferences: {},
    },
    {
      groups: [],
      is_archived: false,
      _id: '5eccb7dac2602f4561b6580d',
      client_connection: 1,
      client_type: 'Hybrid',
      email: 'GuyMccoy@demo',
      avatar: `${CDN_URL}/images/fake_client_avatar_3.png`,
      full_name: 'Guy Mccoy',
      full_name_sort: 'Guy Mccoy',
      color: '#79AAF2',
      last_engaged: '2020-05-16T06:32:57.128Z',
      last_activity: '2020-05-25T00:00:00.000Z',
      trainer_full_name: 'Cristian Ronaldo',
      last_assigned_workout: '2020-06-14T00:00:00.000Z',
      completed_task_30d: 1,
      completed_task_7d: 0,
      percent_task_completed_30d: 0.68,
      percent_task_completed_7d: 0,
      total_task_30d: 3,
      total_task_7d: 0,
      completed_assignment_30d: 10,
      completed_assignment_7d: 2,
      percent_completed_30d: 0.68,
      percent_completed_7d: 0.2,
      total_assignment_30d: 23,
      total_assignment_7d: 10,
      inbox_preferences: {},
    },
  ],
  page: 1,
  per_page: 20,
  search: '',
  sort: '1',
  sorter: '1',
  total: 1,
  total_except_search: 10,
};
