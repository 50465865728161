import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px 20px 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 80px;
`;

export const Section = styled.section`
  .hi-coach-label {
    font-size: 30px !important;
    margin-bottom: 0 !important;
  }

  .sub-title {
    margin-top: 15px !important;
    font-weight: 600 !important;
  }

  &.illustration {
    text-align: center;
    max-width: 400px;

    .img-container {
      width: 100%;
      min-height: 225px;
      margin-bottom: 30px;

      img {
        width: 300px;
      }
    }

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #000000;
      margin: 0;
      margin-bottom: 5px;
    }

    h3 {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #000000;
      opacity: 0.6;
      max-width: 340px;
      margin: 0 auto;
    }
  }

  &.questions {
    padding-top: 70px;
    text-align: left;
    width: 580px;

    h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
      color: #000000;
      margin-bottom: 35px;
    }

    .actions {
      text-align: right;

      button {
        cursor: pointer;
        font-weight: bold;
        font-size: 13px;
        line-height: 150%;
        color: #ffffff;
        padding: 8px 60px;
        outline: none;
        border: none;
        border-radius: 5px;
        background: #7470ef;

        :disabled {
          background: #cdcdcd;
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const QuestionContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 8px;
`;

export const Questions = styled.div`
  min-height: 150px;
  margin-bottom: 40px;
`;

export const Container = styled.div`
  padding-top: 180px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 100px;
  flex-flow: row wrap;

  ${Section} {
    :first-child {
      margin-right: 100px;
    }

    :last-child {
      margin-left: 50px;
    }
  }

  @media screen and (max-height: 767px) {
    padding-top: 100px;
  }
`;

export const NotACoachSection = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  color: #7570ee;
  font-weight: 700;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
  width: 100%;

  :hover {
    opacity: 0.85;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  overflow: auto;

  .questionaire__select__menu .questionaire__select__option {
    line-height: 100%;
  }

  ${props =>
    props.mobile &&
    css`
      ${Container} {
        padding: 24px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .desctiprion {
          display: none;
        }

        section {
          margin: 0;
          margin-right: 0;
          width: 100%;

          &.illustration {
            margin: 0;
            margin-bottom: 20px;

            .img-container {
              min-height: unset;
              margin-bottom: 0;

              img {
                height: 133px;
                width: auto;
              }
            }
          }

          &.questions {
            padding-top: 0;
            margin: 0;

            h1 {
              text-align: center;
              margin-bottom: 24px;
              font-weight: bold;
              font-size: 28px;
              line-height: 130%;
              color: #000000;
              padding: 0 10px;
            }
            .hi-coach-label {
              font-size: 28px !important;
              font-weight: 700;
            }
            .sub-title {
              font-size: 16px !important;
              font-weight: 700 !important;
              margin-top: 10px !important;
              margin-right: 5px;
            }
          }
        }

        .questionaire__select__menu {
          padding: 15px;
          width: 215px;

          .questionaire__select__option {
            border: 1px solid #f0f0f0;
            border-radius: 5px;
            margin-bottom: 10px;
            line-height: 100%;
            padding: 15px;

            :last-child {
              margin-bottom: 0;
            }
          }
        }

        .questionaire {
          display: flex;
          max-width: 500px;
          margin: 0 auto 20px;
        }

        .actions {
          display: flex;
          text-align: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          button {
            width: 100%;
            max-width: 100%;
            height: 48px;
            font-size: 16px;
          }

          ${NotACoachSection} {
            position: unset;
            margin-top: 22px;
            color: #000000;
            opacity: 0.6;
          }
        }

        ${Questions} {
          min-height: 350px;
          margin: 0;
        }
      }

      ${Header} {
        height: unset;
        padding: 18px;

        img {
          width: unset;
          height: 28px;
        }
      }
    `}
`;
