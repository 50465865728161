import React from 'react';
import classNames from 'classnames';
import SectionItem from './SectionItem';

import * as S from './style';

const WorkoutOverviewDetail = props => {
  const { workout, hide } = props;
  return (
    <S.Wrapper className={classNames({ 'workout-overview__hide': hide })}>
      <S.Heading>
        <div>{workout.title}</div>
        <S.ExpandIcon className="workout-overview__expand-icon" onClick={() => props.toggleHide(workout._id)} />
      </S.Heading>
      <S.Content className="workout-overview__content">
        {workout.sections.map(section => (
          <SectionItem section={section} key={section._id} />
        ))}
      </S.Content>
    </S.Wrapper>
  );
};

export default WorkoutOverviewDetail;
