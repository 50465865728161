import { push } from 'connected-react-router';
import _ from 'lodash';
import { showSuccess } from 'redux/modal/modal.actionCreators';
import { getQueryParamsFromObject } from 'utils/commonFunction';
import Request from 'configs/request';

export const SUCCESS_GET_VERIFY_TEAM = 'SUCCESS_GET_VERIFY_TEAM';

export const getClientInfo = params => Request.get({ url: '/api/trainer/client_info/detail', params });

export const addClientInfo = data => Request.post({ url: '/api/trainer/client_info/add', data });

export const getDashboardInfo = params => {
  return Request.get({ url: '/api/workout/v2/assignment/dashboard', params: params }, true);
};

export const getBodyMetricChartData = params => Request.get({ url: '/api/body-metric-entry/get-entry-list', params });

export const addProgressPhoto = data => Request.post({ url: '/api/measurement/v2/add', data }, true);

export const deleteProgressPhoto = mid => Request.delete({ url: `/api/measurement/delete/${mid}` }, true);

export const updateProgressPhoto = (mid, data) =>
  Request.post({ url: `/api/measurement/v2/update/${mid}`, data }, true);

export const getProgressPhotoHistory = params => {
  return Request.get({ url: '/api/measurement/history', params }, false, (data, { dispatch }) => {});
};

export const getMetricCategories = () => {
  return Request.get({ url: '/api/unit/category/list', params: { page: 0, per_page: 100 } }, false);
};

export const selectMetricType = (data, clientId) => {
  return Request.put({ url: '/api/measurement_type/unfeaturing', data }, true);
};

export const getNotifications = () => Request.get({ url: '/api/notification/list' }, true);

export const readNotifications = data => Request.post({ url: '/api/notification/read', data });

export const getClientActivity = (clientId, params) => {
  return (dispatch, getState) => {
    const { user } = getState();
    if (user._id !== clientId) {
      return dispatch(Request.get({ url: `/api/trainer/v2/client/activity/${clientId}`, params }, false));
    }
  };
};

export const getClientNote = (type, client) => {
  return Request.get({ url: `/api/${type}/list/${client}` }, false);
};

export const addClientNote = (type, data) => Request.post({ url: `/api/${type}/add`, data });

export const updateClientNote = (type, data) => Request.post({ url: `/api/${type}/update`, data });

export const deleteClientNote = (type, data) => Request.delete({ url: `/api/${type}/delete`, data });

export const getUserPreferences = () => Request.get({ url: '/api/profile/preferences' }, true);

export const changeEmail = data => {
  return Request.put({ url: '/api/profile/change_email', data }, true, (response, { dispatch }) => {
    dispatch({
      type: 'UPDATE_PENDING_EMAIL',
      payload: { pending_email: data.email },
    });
    dispatch(showSuccess('Success! Please check your email and click on the verification button to complete change.'));
  });
};

export const changePassword = data => {
  return Request.put({ url: '/api/profile/change_password', data }, true, (response, { dispatch }) => {
    dispatch(push('/home/accountdetails'));
  });
};

export const getNotificationPreferences = () => Request.get({ url: '/api/profile/notification_preferences' }, true);

export const updateNotificationPreferences = data =>
  Request.put({ url: '/api/profile/notification_preferences/updateMany', data }, true);

export const getEmailPreferences = () => Request.get({ url: '/api/profile/email_preferences', method: 'get' }, true);

export const updateEmailPreferences = data =>
  Request.put({ url: '/api/profile/email_preferences/updateMany', data }, true);

export const getFeaturePreferences = data =>
  Request.get({ url: `/api/trainer/client_info/feature_preferences?client=${data}` }, true);

export const updateFeaturePreferences = data => {
  return Request.put(
    { url: '/api/trainer/client_info/feature_preferences/updateMany', data },
    true,
    (result, { dispatch }) => {
      if (result.data && result.data.data) {
        dispatch({
          type: 'SETTING_CLIENT_ALL_FEATURE',
          payload: { client: data.client, feature_preferences: result.data.data },
        });
      }
    },
  );
};

export const getUnitPreferences = data =>
  Request.get({ url: `/api/trainer/client_info/unit_preferences?client=${data}` }, true);

export const updateUnitPreferences = data =>
  Request.put({ url: '/api/trainer/client_info/unit_preferences/update', data }, true);

export const updateClientInfo = (client, data) => {
  return Request.put({ url: `/api/trainer/client/${client}`, data }, true, (result, { dispatch }) => {
    const { data } = result.data;
    dispatch({ type: 'UPDATE_CLIENT_INFO', payload: { data } });
  });
};

export const updateOneFeaturePreferences = params => {
  return Request.put(
    { url: '/api/trainer/client_info/feature_preferences/update', data: params },
    true,
    (result, { dispatch }) => {
      dispatch({ type: 'SETTING_CLIENT_FEATURE', payload: params });
    },
  );
};

export const editTeam = data => {
  return Request.put({ url: '/api/team/edit', data }, true, (result, { dispatch }) => {
    dispatch({ type: 'UPDATE_USER_TEAM', payload: result.data.data });
  });
};

export const verifyTeam = team_invite_id => {
  return Request.get({ url: `/api/team_invitation/verify/${team_invite_id}` }, true, (resp, { dispatch }) => {
    const { data = {} } = resp.data;
    dispatch({ type: SUCCESS_GET_VERIFY_TEAM, payload: data });
  });
};

export const verifyInviteInfo = code => Request.get({ url: `/api/auth/verify_invite_info/${code}` }, true);

export const verifyUnsubscribeEmailInfo = (token, type) =>
  Request.get({ url: `/api/auth/verify_unsubscribe_email_info/${token}/${type}` }, true);

export const inviteNewTeamate = data => Request.post({ url: '/api/team/invite', data }, true);

export const viewListTeamate = () => Request.get({ url: '/api/team/list_member' }, true);

export const editTeamMember = data => {
  return Request.put({ url: '/api/team/edit_member', data }, true, (result, { dispatch }) => {
    dispatch({ type: 'UPDATE_USER_TEAM_ROLE', payload: data });
  });
};

export const resendInvokeInvitation = (isResend, id) =>
  Request.post({ url: `/api/team/${isResend ? 'resend' : 'invoke'}`, data: { inviteId: id } }, true);

export const getExerciseHistoryList = params =>
  Request.get({ url: `/api/exercise_history/list?${getQueryParamsFromObject(params)}` }, true);

export const getExerciseHistoryEntries = params => {
  return Request.get({ url: `/api/exercise_history/exercise_entries?${getQueryParamsFromObject(params)}` }, true);
};

export const getExerciseHistoryEntriesByTime = params => {
  return Request.get(
    { url: `/api/exercise_history/exercise_entries_by_time?${getQueryParamsFromObject(params)}` },
    true,
  );
};

export const searchClients = q => Request.get({ url: `/api/trainer/clients_search?q=${q}&client_connection=1` });

export const getWorkoutVisibilityPreferences = clientId => {
  return Request.get({ url: `/api/trainer/client_info/workout_visibility_preferences?client=${clientId}` }, true);
};

export const updateWorkoutVisibilityPreferences = data => {
  return Request.put({ url: `/api/trainer/client_info/workout_visibility_preferences/updateMany`, data }, true);
};
