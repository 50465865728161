import React from 'react';
import classnames from 'classnames';
import VideoDuration from 'shared/VideoDuration';
import { getDuration } from 'utils/commonFunction';
import { FILE_VALIDATIONS } from 'components/InAppMessage/constants';
import { CDN_URL } from 'constants/commonData';
import { CameraIcon } from 'components/InAppMessage/ChatBar/icons';
import ReactTooltip from 'react-tooltip';

import * as S from './style';

export const SelectedFiles = props => {
  const { index, selectedFiles } = props;

  return (
    <S.MediaContainer className="selected-files">
      {selectedFiles.map(item => {
        const { attachment_type, objectURL } = item;

        return (
          <S.MediaItem className="item" key={objectURL}>
            {attachment_type.includes('video') ? (
              <>
                <video
                  className="media"
                  onLoadedMetadata={event => {
                    if (event.target && event.target.duration) {
                      const { duration, nextElementSibling } = event.target;

                      if (nextElementSibling && nextElementSibling.className.includes('video-duration')) {
                        const time = getDuration(duration);
                        const timeEl = nextElementSibling.querySelector('.time');

                        if (timeEl) {
                          timeEl.innerText = time;
                        }
                      }
                    }

                    event.persist();
                  }}
                >
                  <source src={objectURL} type={attachment_type} />
                  <source src={objectURL} type="video/mp4" />
                </video>
                <VideoDuration />
              </>
            ) : (
              <img className="media" src={objectURL} alt="media" />
            )}
            <img
              src={`${CDN_URL}/images/close_circle.svg`}
              onClick={() => props.onDelete(index)}
              className="remove-icon"
              alt="remove-icon"
            />
          </S.MediaItem>
        );
      })}
    </S.MediaContainer>
  );
};

export const SelectFileInput = props => {
  const { disabled, hasText } = props;
  const disableSelectFile = disabled || hasText;

  return (
    <S.FileInputContainer
      className={classnames('select-files-input', { disabled: disableSelectFile })}
      data-tip
      data-for="tooltip--broadcastMessageUploadMedia"
    >
      {CameraIcon}
      <input
        type="file"
        accept={FILE_VALIDATIONS.ACCEPTED}
        disabled={disableSelectFile}
        multiple={false}
        onChange={props.onChange}
      />

      <ReactTooltip
        id="tooltip--broadcastMessageUploadMedia"
        className="app-tooltip"
        place="top"
        effect="solid"
        globalEventOff="click"
      >
        <span>Upload Media</span>
      </ReactTooltip>
    </S.FileInputContainer>
  );
};
