/**
 * @flow
 */

import React from 'react';
import type { Element } from 'react';
import ErrorPopup from 'shared/ErrorPopup';

import type { Action } from 'types/redux';

export const showError = (
  error: String,
  title: String = null,
  close: String = null,
  headerIcon: String = null,
  buttonClass: String = null,
  callback: any,
): Action => {
  return {
    type: 'SHOW_ERROR',
    modal: (
      <ErrorPopup
        content={error}
        title={title}
        close_button={close}
        headerIcon={headerIcon}
        buttonClass={buttonClass}
        callback={callback}
      />
    ),
  };
};

export const hideError = (): Action => {
  return {
    type: 'HIDE_ERROR',
  };
};
