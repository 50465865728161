import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import {
  maximizeCheckList,
  minimizeCheckList,
  addYourselfAsClient,
  hideFeature,
  runOnboarding,
  hideOnboardingUserGuide,
} from 'redux/onboarding/actions';
import { toggleModal } from 'actions/modal';
import Onboarding from './component';

const mapState = state => {
  const {
    user,
    rootReducer: {
      onboarding,
      client: { newestClients },
      calendar,
      autoflow,
      autoflowInterval,
      program_library,
      studioProgram,
    },
    router,
  } = state;
  const autoflowIntervalTraining = get(autoflowInterval, 'training').toJS();

  const clientCalendarHasCopyWeekOrWorkout =
    !isEmpty(get(calendar, 'selectedWeek')) || !isEmpty(get(calendar, 'selectedWorkout'));
  const autoflowExactDateHasCopyWeekOrWorkout =
    !isEmpty(get(autoflow, 'training.selectedWeek')) || !isEmpty(get(autoflow, 'training.selectedWorkouts'));

  const autoflowIntervalHasCopyWeekOrWorkout =
    !isNil(get(autoflowIntervalTraining, 'selectedWeek')) ||
    !isEmpty(get(autoflowIntervalTraining, 'selectedWorkouts'));

  const programLibraryHasCopyWeekOrWorkout =
    !isNil(get(program_library, 'selectedWeek')) || !isEmpty(get(program_library, 'selectedWorkout'));

  const studioProgramHasCopyWeekOrWorkout =
    !isNil(get(studioProgram, 'selectedWeek')) || !isEmpty(get(studioProgram, 'selectedWorkout'));

  return {
    user,
    onboarding,
    clientList: newestClients,
    location: router.location,
    hasCopyWeekOrWorkout:
      clientCalendarHasCopyWeekOrWorkout ||
      autoflowExactDateHasCopyWeekOrWorkout ||
      autoflowIntervalHasCopyWeekOrWorkout ||
      programLibraryHasCopyWeekOrWorkout ||
      studioProgramHasCopyWeekOrWorkout,
  };
};

const mapDispatch = dispatch => {
  return {
    maximizeCheckList: bindActionCreators(maximizeCheckList, dispatch),
    minimizeCheckList: bindActionCreators(minimizeCheckList, dispatch),
    addYourselfAsClient: bindActionCreators(addYourselfAsClient, dispatch),
    hideFeature: bindActionCreators(hideFeature, dispatch),
    runOnboarding: bindActionCreators(runOnboarding, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    push: bindActionCreators(push, dispatch),
    hideOnboardingUserGuide: bindActionCreators(hideOnboardingUserGuide, dispatch),
  };
};

export default connect(mapState, mapDispatch)(Onboarding);
