import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Button } from './style';
import { toggleModal } from 'actions/modal';
import AssignProgram from 'components/AssignProgram';

function AssignButton(props) {
  const { clients, program } = props;

  return (
    <Button
      className={classnames('button--assignProgram', { assigned: clients.length })}
      onClick={() => props.toggleModal(true, <AssignProgram program={program} from="program_calendar" />)}
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.625 10.8623L13.4872 7.00001L9.625 3.13776L8.38775 4.37501L10.1378 6.12501H4.375V7.87501H10.1378L8.38775 9.62501L9.625 10.8623Z"
          fill="white"
        />
        <path
          d="M10.5 12.25H2.625V1.75H10.5V0H1.75C1.267 0 0.875 0.392 0.875 0.875V13.125C0.875 13.608 1.267 14 1.75 14H10.5V12.25Z"
          fill="white"
        />
      </svg>
      <span>{clients.length ? 'Assign' : 'Assign Program'}</span>
    </Button>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      program_library: { assigned_clients, selected },
    },
  } = state;
  return { clients: assigned_clients, program: selected };
};

const actionCreators = {
  toggleModal,
};

export default connect(mapState, actionCreators)(AssignButton);
