import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

export const ChooseOnboardingFormButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 15px 0px 14px;
  width: 100%;

  cursor: pointer;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 16px 48px -12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  &.focus,
  &:hover {
    border-color: #5559ff;
  }

  .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #5559ff;
  }
`;

export const OnboardingFormsPopup = styled(Popup)`
  &.ui.popup {
    padding: 4px 0px !important;
    border-radius: 8px !important;
    border: unset !important;
    background: #ffffff !important;
    box-shadow: 0px 4px 16px rgba(38, 38, 38, 0.2) !important;
    z-index: 3000 !important;
    overflow: hidden;

    &.bottom {
      margin: 4px 0 0 !important;
    }
  }

  .container-popup {
    width: 436px;
    max-height: 158px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  .empty-container {
    padding: 16px;
  }

  .empty-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;

    color: rgba(32, 35, 83, 0.6);
  }
`;

export const FormItemWrapper = styled.button`
  &.form-item-container {
    position: relative;
    cursor: pointer;
    padding: 16px;
    text-align: left;
    background: unset;
    border: unset;

    &.focus {
      background: #e6e6ff;
    }

    &.selected {
      background: #f6f5ff;
    }

    &:hover {
      background: #f6f5ff;
      .form-title {
        color: #5e59ff;
      }
    }
  }

  &.trigger-popup {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: stretch;
    gap: 8px;

    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-shadow: 0px 16px 48px -12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 100%;

    &.focus,
    &:hover {
      border-color: #5559ff;
      background: unset;

      .form-title {
        color: #202353;
      }
    }

    .main-content {
      overflow: hidden;
    }

    .left-content {
    }
  }

  &.is-unavailable {
    background: #eaedf4;
    &.focus,
    &:hover {
      background: #eaedf4;
    }
  }

  .form-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    margin-bottom: 6px;

    display: flex;
    align-items: baseline;

    .name-form {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .text-unavailable {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      color: #7f88a5;
      margin-left: 9px;
      text-transform: uppercase;
    }
  }

  .change-button {
    text-align: left;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

    color: #5559ff;
  }
`;

export const TriggerPopupWrapper = styled.div``;
