import React, { useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { CONVERSION, MAX_FILE_SIZE, FILE_ERRORS } from 'constants/commonData';
import { validateFiles } from 'utils/validations';

function SelectFile(props) {
  const {
    disabled,
    accept,
    trigger,
    maxSize,
    validateExtentions,
    multiple,
    className,
    fileTypeSupportMessage,
    fileSizeLimitMessage,
  } = props;
  const inputRef = useRef();

  const onSelect = event => {
    const { files } = event.target;

    if (files.length) {
      const error = validateFiles({
        files,
        validateExtentions,
        maxSize: (_.isNumber(maxSize) ? parseInt(maxSize) : MAX_FILE_SIZE) * CONVERSION.MB_TO_BYTE,
      });

      if (error) {
        let message = '';

        if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = fileTypeSupportMessage || `File type not supported.`;
        } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = fileSizeLimitMessage || `File too large. Please upload a smaller file`;
        }

        toast.error(message);
        typeof props.onError === 'function' && props.onError(error);
      } else {
        props.onSelect(multiple ? files : files[0]);
      }

      event.target.value = '';
    }
  };

  return (
    <Wrapper className={classNames('select-file__container', className)}>
      <div className="select-file__trigger">
        {React.cloneElement(trigger, {
          onClick: event => {
            inputRef.current.click();
          },
        })}
      </div>
      <input
        type="file"
        accept={accept}
        onChange={onSelect}
        className="select-file__input"
        disabled={disabled}
        multiple={multiple}
        title=""
        ref={inputRef}
      />
    </Wrapper>
  );
}

SelectFile.propTypes = {
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  validateExtentions: PropTypes.arrayOf(PropTypes.string),
  onError: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
};

export default SelectFile;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none !important;
    box-shadow: none !important;
    text-indent: -9999px;
    opacity: 0;
    border: none !important;
    cursor: pointer;
    visibility: hidden;
    z-index: -1;
  }
`;
