import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

const Wrapper = styled.div`
  width: 100vw;
  height: ${props => (props.offline ? '100vh' : '0px')};
  background-color: transparent;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: ${props => (props.offline ? 2147483100 : -1)};

  .errorPopup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 30px;
    opacity: ${props => (props.offline ? 1 : 0)};
    transition: opacity 0.5s ease-in-out;

    background: #333333;
    border-radius: 5px;
    z-index: ${props => (props.offline ? 2147483100 : 0)};
    margin-left: ${props => (props.sideBarVisible ? '230px' : 0)};
    margin-top: 55px;
    height: 48px;
  }

  .errorPopup__text {
    align-self: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin-right: 90px;

    text-align: center;

    color: #ffffff;
  }

  .errorPopup__loader {
    border: 2px solid #666b70;
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* custom style on mobile page introduction */
  ${props =>
    props.isMobileIntroduction &&
    css`
      align-items: flex-start;
      justify-content: center;

      .errorPopup {
        margin-left: 10px;
        margin-right: 10px;
        height: unset;
        align-items: center;
      }

      .errorPopup__text {
        margin-right: unset;
      }

      .errorPopup__loader {
        flex-shrink: 0;
      }
    `}
`;

function NetworkErrorPopup({ sideBarVisible, location }) {
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    window.addEventListener('online', () => setOffline(false));
    window.addEventListener('offline', () => setOffline(true));
  }, []);

  const isMobileIntroduction = useMemo(() => {
    const pathName = _.get(location, 'pathname', null);
    return isMobile && !_.isEmpty(pathName) && _.isEqual(pathName, '/onboarding');
  }, [isMobile, location]);

  return (
    <Wrapper offline={offline} sideBarVisible={sideBarVisible} isMobileIntroduction={isMobileIntroduction}>
      <div className="errorPopup">
        <div className="errorPopup__text">Connection is offline. System will try to reconnect...</div>
        <div className="errorPopup__loader" />
      </div>
    </Wrapper>
  );
}

const mapStateToProps = state => {
  const { sideBarVisible } = state;

  return {
    sideBarVisible,
  };
};

export default withRouter(connect(mapStateToProps)(NetworkErrorPopup));
