import React, { useRef, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { Radio } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import isEqual from 'lodash/isEqual';
import ReactTooltip from 'react-tooltip';

import { CDN_URL, TAGS_TYPE } from 'constants/commonData';

import { ReactComponent as FilterIcon } from 'assets/icons/new_filter.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_gray_out.svg';
import { Button, Checkbox } from 'shared/FormControl';
import TagsFilter from 'shared/TagsFilter';
import ButtonIcon from 'shared/ButtonIcon';

import * as S from '../styles';

import {
  DEFAULT_FILTERS,
  DEFAULT_GROUP_OPTIONS,
  DEFAULT_EXPERIENCE_LEVEL,
  // DEFAULT_DURATIONS,
  DEFAULT_PROGRAM_LENGTH,
  DEFAULT_MODALITY_VALUES,
  FILTER_TYPE_KEYS,
  FILTER_TYPES,
  DEFAULT_WORKOUT_DAYS_PER_WEEK,
} from '../constants';
import { useMyContext } from '../context/ProgramLibraryContext';

const FiltersPopup = props => {
  const { className = '', isHideBanner } = props;
  const { currentFilters = DEFAULT_FILTERS, tags, onUpdateFilters, onCloseFilters, handleResetFilter } = useMyContext();
  const ref = useRef();
  const bodyRef = useRef();

  const [isTagsMenuOpen, setIsTagsMenuOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [groupOptions, setGroupOptions] = useState(DEFAULT_GROUP_OPTIONS);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const counterFilters = useMemo(() => {
    if (typeof currentFilters !== 'object') return 0;
    let counter = 0;
    for (const [key, value] of Object.entries(currentFilters)) {
      switch (key) {
        case FILTER_TYPE_KEYS.TAGS:
        case FILTER_TYPE_KEYS.MODALITY:
        case FILTER_TYPE_KEYS.PROGRAM_LENGTH:
        case FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK:
          counter += value.length;
          break;
        case FILTER_TYPE_KEYS.EXPERIENCE_LEVEL:
          if (value !== null) counter += 1;
          break;
        // case FILTER_TYPE_KEYS.DURATION:
        default:
          break;
      }
    }
    return counter;
  }, [currentFilters]);

  useEffect(() => {
    if (!showFilters && !isEqual(currentFilters, filters)) {
      setFilters(currentFilters);
    }
  }, [showFilters, currentFilters]);

  useEffect(() => {
    if (showFilters && bodyRef.current) {
      bodyRef.current.scrollTo({ top: 0 });
    }
  }, [showFilters]);

  const toggleShowFilters = () => setShowFilters(it => !it);

  const handleClearAll = () => {
    setFilters(DEFAULT_FILTERS);
    setIsDisable(true);
    typeof handleResetFilter === 'function' && handleResetFilter();
  };

  const handleUpdateFilterOptions = () => {
    typeof onUpdateFilters === 'function' && onUpdateFilters(filters);
    typeof onCloseFilters === 'function' && onCloseFilters();
    setShowFilters(false);
  };

  const handleExpand = key => () => {
    setGroupOptions({
      ...groupOptions,
      [key]: !groupOptions[key],
    });
  };

  const handleTagMenuOpen = () => {
    setIsTagsMenuOpen(true);
  };

  const handleTagMenuClose = () => {
    setTimeout(() => {
      setIsTagsMenuOpen(false);
    }, 300);
  };

  const handleSelectTags = values => {
    setFilters({ ...filters, tags: values });

    if (isDisable) setIsDisable(false);
  };

  const handleSelectCheckbox = (item = {}, filter_key) => (event = {}) => {
    if (!filter_key) return;
    let checked = (event.target || {}).checked || false;
    if (filter_key === FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK) {
      checked = !filters[filter_key].includes(item.key);
    }

    if (checked) {
      setFilters(prevFilters => ({
        ...prevFilters,
        [filter_key]: [...prevFilters[filter_key], item.key],
      }));
    } else {
      setFilters(prevFilters => {
        const newList = prevFilters[filter_key].slice();
        newList.splice(
          newList.findIndex(i => i === item.key),
          1,
        );
        return { ...prevFilters, [filter_key]: newList };
      });
    }

    if (isDisable) setIsDisable(false);
  };

  const handleSelectRadio = (item = {}, filter_key) => () => {
    if (!filter_key) return;
    setFilters(prevFilters => ({ ...prevFilters, [filter_key]: item.key }));

    if (isDisable) setIsDisable(false);
  };

  const renderFilterTypeContent = key => {
    switch (key) {
      case FILTER_TYPE_KEYS.TAGS:
        return (
          <TagsFilter
            onMenuOpen={handleTagMenuOpen}
            onMenuClose={handleTagMenuClose}
            onChange={handleSelectTags}
            tags={tags}
            selectedTags={filters[FILTER_TYPE_KEYS.TAGS]}
            type={TAGS_TYPE.PROGRAM}
            sorter="most_recent"
            menuStyles={{
              marginTop: '4px !important',
            }}
          />
        );
      case FILTER_TYPE_KEYS.MODALITY:
        return DEFAULT_MODALITY_VALUES.map(it => {
          const { key: keyModality = '', label = '', tooltip = '' } = it || {};
          return (
            <S.ModalityWrapper key={it.key}>
              <Checkbox
                title={label}
                className="system-modality"
                checked={filters[FILTER_TYPE_KEYS.MODALITY].includes(it.key)}
                onChange={handleSelectCheckbox(it, key)}
                size={16}
              />
              {keyModality !== 'strength_hypertrophy' && (
                <>
                  <InfoIcon data-tip data-for={`modality-tooltip-${keyModality}`} />
                  <ReactTooltip id={`modality-tooltip-${keyModality}`} effect="solid" place={'top'} delayShow={300}>
                    {tooltip}
                  </ReactTooltip>
                </>
              )}
            </S.ModalityWrapper>
          );
        });
      case FILTER_TYPE_KEYS.EXPERIENCE_LEVEL:
        return DEFAULT_EXPERIENCE_LEVEL.map(it => (
          <Radio
            key={it.key}
            label={it.label}
            className="system-experience-level"
            checked={filters[FILTER_TYPE_KEYS.EXPERIENCE_LEVEL] === it.key}
            onClick={handleSelectRadio(it, key)}
          />
        ));
      case FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK:
        return DEFAULT_WORKOUT_DAYS_PER_WEEK.map(it => (
          <button
            key={it.key}
            className={classNames('system-workout-days-per-week', {
              selected: filters[FILTER_TYPE_KEYS.WORKOUT_DAYS_PER_WEEK].includes(it.key),
            })}
            onClick={handleSelectCheckbox(it, key)}
          >
            {it.label}
          </button>
        ));
      // case FILTER_TYPE_KEYS.DURATION:
      //   return DEFAULT_DURATIONS.map(it => (
      //     <Checkbox
      //       key={it.key}
      //       title={it.label}
      //       className="system-duration"
      //       checked={filters[FILTER_TYPE_KEYS.DURATION].includes(it.key)}
      //       onChange={handleSelectCheckbox(it, key)}
      //       size={16}
      //     />
      //   ));
      case FILTER_TYPE_KEYS.PROGRAM_LENGTH:
        return DEFAULT_PROGRAM_LENGTH.map(it => (
          <Checkbox
            key={it.key}
            title={it.label}
            className="system-program-length"
            checked={filters[FILTER_TYPE_KEYS.PROGRAM_LENGTH].includes(it.key)}
            onChange={handleSelectCheckbox(it, key)}
            size={16}
          />
        ));
      default:
        return null;
    }
  };

  const renderFilterType = (it, idx) => {
    const { key, label, className } = it;
    const isLast = idx === FILTER_TYPES.length - 1;

    return (
      <S.PopupGroup key={`item-filter-${key}`} noBorderBottom={isLast}>
        <S.PopupGroupTitle onClick={handleExpand(key)}>
          {label}
          <img
            src={`${CDN_URL}/images/arrow_down_black.svg`}
            className={classNames({ opened: groupOptions[key] })}
            alt=""
          />
        </S.PopupGroupTitle>
        <S.PopupGroupContent className={classNames(className, { expanded: groupOptions[key] })}>
          {renderFilterTypeContent(key)}
        </S.PopupGroupContent>
      </S.PopupGroup>
    );
  };

  return (
    <RootCloseWrapper event="click" onRootClose={toggleShowFilters} disabled={!showFilters || isTagsMenuOpen}>
      <S.FilterButtonWrapper className={classNames('program-filter', { open: showFilters })}>
        <ButtonIcon
          text="Filter"
          width={106}
          height={30}
          className="filter-options"
          icon={<FilterIcon />}
          color="rgb(119, 119, 119)"
          textHoverColor="rgb(119, 119, 119)"
          bgHoverColor="rgb(248, 248, 248)"
          onClick={toggleShowFilters}
        />
        {counterFilters > 0 && (
          <S.CounterFilters>
            <span>{counterFilters}</span>
          </S.CounterFilters>
        )}
        <S.PopupWrapper ref={ref} className={className} isHideBanner={isHideBanner}>
          <S.PopupHeader>
            <S.PopupHeaderTitle>Filter by:</S.PopupHeaderTitle>
            <S.ClearLabel onClick={handleClearAll}>Clear All</S.ClearLabel>
          </S.PopupHeader>
          <S.PopupBody ref={bodyRef}>
            {FILTER_TYPES.map(renderFilterType)}
            <S.PopupFooter>
              <Button className={classNames({ disable: isDisable })} onClick={handleUpdateFilterOptions}>
                Update
              </Button>
            </S.PopupFooter>
          </S.PopupBody>
        </S.PopupWrapper>
      </S.FilterButtonWrapper>
    </RootCloseWrapper>
  );
};

export default FiltersPopup;
