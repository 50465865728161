import styled from 'styled-components';
import Button from 'shared/FormControl/Button';

export const CreatePostForm = styled.div`
  width: 598px;
  height: 142px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  background-color: #fff;
  padding: 20px;
`;

export const WriteSomethingField = styled(Button)`
  width: 500px;
  height: 44px;
  border-radius: 12px;
  background-color: #f4f6f8;
  :hover {
    background-color: #e9ecf2;
  }
  border: none;
  padding: 10px 20px;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #7b7e91;
  margin-left: 11px;
  justify-content: initial;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AssetButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const AddAssetText = styled.span`
  color: #696974;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  margin-left: 10.56px !important;
`;

export const AddAssetButton = styled(Button)`
  display: flex;
  height: 38px;
  left: 0px;
  top: 0px;
  padding: 10px 18px;
  margin: 20px 0 0;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 0px #0000001a !important;
  border-radius: 10000px;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0;
  }

  :hover {
    ${AddAssetText} {
      color: #5158cf;
    }

    background-color: #f6f5ff;
    border: 1px solid #5158cf;
    box-shadow: 0px 2px 2px #0000001a !important;
  }
`;
