import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const Container = styled.div`
  width: 100%;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  color: #222222;
  background: transparent;
  
  > div {
    padding: 10px 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .icon {
    width: 26px;
    height: 15px;
    background-image: url(${CDN_URL}/images/customize.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center left;

    &.edit {
      background-image: url(${CDN_URL}/images/customize.svg);
    }
    &.customize {
      background-image: url(${CDN_URL}/images/customize.svg);
    }
    &.new-section {
      background-image: url(${CDN_URL}/images/plus_circle_black.svg);
    }
    &.duplicate {
      background-image: url(${CDN_URL}/images/duplicate_black.svg);
    }
    &.delete {
      background-image: url(${CDN_URL}/images/delete_black.svg);
    }
    &.move {
      background-image: url(${CDN_URL}/images/arrow_right_bold_black.svg);
    }
    &.move-down {
      background-image: url(${CDN_URL}/images/down_none_border.svg);
    }
    &.move-up {
      background-image: url(${CDN_URL}/images/up_none_border.svg);
    }
    &.save {
      background-image: url(${CDN_URL}/images/save_black.svg);
    }
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
    .icon {
      &.edit {
        background-image: url(${CDN_URL}/images/customize_purple.svg);
      }
      &.customize {
        background-image: url(${CDN_URL}/images/customize_purple.svg);
      }
      &.new-section {
        background-image: url(${CDN_URL}/images/plus_circle_purple.svg);
      }
      &.duplicate {
        background-image: url(${CDN_URL}/images/duplicate_purple.svg);
      }
      &.delete {
        background-image: url(${CDN_URL}/images/delete_purple.svg);
      }
      &.move {
        background-image: url(${CDN_URL}/images/arrow_right_bold_purple.svg);
      }
      &.move-down {
        background-image: url(${CDN_URL}/images/down_none_border_purple.svg);
      }
      &.move-up {
        background-image: url(${CDN_URL}/images/up_none_border_purple.svg);
      }
      &.save {
        background-image: url(${CDN_URL}/images/save_purple.svg);
      }
    }
  }
`;

class MenuItem extends React.PureComponent {
  render() {
    const  { children, icon, disabled, onClick } = this.props;
    return (
      <Container
        className={`action-menu-item${disabled ? ' disabled' : ''}`}
        onClick={onClick}
      >
        <div>
          <div className={`icon ${icon}`}/>
          <div className="content">{children}</div>
        </div>
      </Container>
    )
  }
}

MenuItem.propTypes = {
  icon: PropTypes.string.isRequired
}

export default MenuItem;