import styled, { css } from 'styled-components';
import { SelectFile } from 'shared/FormControl';
import { MenuTrigger } from 'shared/Icons';
import SmallVideo from 'assets/icons/small_play.png';
import InfoTooltipIconElement from 'shared/InfoTooltipIcon';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  width: 100%;
  padding: 15px 25px 0 25px;
  overflow: auto;
`;
export const BreadCrumbWrapper = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;

  .go-back-btn {
    background: transparent;
    justify-content: flex-start;
    path {
      stroke: #333;
    }
  }

  .breadcrumb-separator {
    margin-left: 4px;
    margin-right: 4px;
    color: #333;
  }
  .collection-name {
    color: #333;
    font-weight: 400;
    margin-right: 0px;
    line-height: 19.5px;
    &:hover {
      text-decoration-line: underline;
      color: #5158cf;
    }
  }
  .category-name {
    font-weight: 600;
    color: #5158cf;
    line-height: 19.5px;
    &:hover {
      cursor: default;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 10px;
  padding: 0 90px;
  margin: 0 auto;
  @media (max-width: 1440px) {
    padding: 0 10px;
  }
`;

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background: transparent url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 2;
`;

export const BaseInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  .info-container {
    flex: 1;
  }
  .workout-collection-type {
    .text-editable__input {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      text-transform: uppercase;
      color: rgba(32, 35, 83, 0.6);
    }
    .text-editable__container {
      padding: 4px;
    }
  }
  .workout-collection-title {
    .text-editable__input {
      font-weight: 600;
      font-size: 23px;
    }
    .text-editable__container {
      padding: 4px;
    }
  }
  .collection-description {
    width: 550px;
    span {
      color: #8a8ba4 !important;
    }

    .text-editable__input {
      display: -webkit-box;
      height: auto;
      max-height: 62.5px;
      overflow: hidden;
      font-size: 13px;
      font-weight: normal;
      line-height: 19.5px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .text-editable__container--empty {
      .text-editable__input {
        height: unset;
      }
    }

    .text-editable__container--active {
      width: 550px;
      .text-editable__input {
        display: block;
        max-height: 97.5px;
        height: auto;
        overflow: auto !important;
        text-overflow: initial !important;
        -webkit-line-clamp: initial;
        -webkit-box-orient: inherit;
        ::before {
          padding-left: 0px !important;
          background: unset;
        }
      }
    }
  }
  .banner-container {
    width: 194px;
    height: 106px;
    margin-right: 10px;
  }

  .status-container {
    /* flex: 0 0 137px; */
    /* margin-left: 30px; */
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    input {
      width: 36px;
    }
    label {
      min-width: 36px;
      min-height: 20px;
    }
    input ~ label::before {
      background-color: #c4c4c4;
    }
    input:checked ~ label::before,
    input:checked:focus ~ label::before {
      background-color: #36b24b;
    }

    .status-text {
      background-color: transparent;
      position: relative;
      color: black;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-right: 10px;
      top: auto;
      bottom: auto;
      &:before {
        width: 6px;
        height: 6px;
        top: 12px;
        left: 6px;
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${props => (props.src ? 'border: none;' : 'border: 1px dashed #dadfea;')}
  display: flex;
  border-radius: 4px;
  height: 100%;
  ${props =>
    props.isDragging &&
    css`
      border: 1px dashed #5158cf;
    `}
  align-items: center;
  justify-content: center;
  position: relative;
  .hidden {
    visibility: hidden;
  }
  .show-on-hover {
    display: none;
    background-color: #00000030;
  }
  :hover {
    .show-on-hover {
      display: flex;
    }

    ${props =>
      props.src &&
      css`
        .thumb-category-tooltip {
          display: block;
        }
      `}
  }

  ${props =>
    props.src &&
    css`
      .thumb-category-tooltip {
        display: none;
      }
    `}
`;

export const SelectBanner = styled(SelectFile)`
  position: absolute;
  z-index: 2;
  height: 100%;
`;

export const Icon = styled.div`
  width: 33px;
  height: 35px;
  ${props =>
    props.outline
      ? 'background: url(${CDN_URL}/images/drop_image_transparent.svg) no-repeat center;'
      : 'background: transparent url(${CDN_URL}/images/drop_image.svg) no-repeat center;'}

  background-size: contain;
  z-index: 1;
`;

export const EditButton = styled.div`
  height: 100%;
  width: 194px;

  border-radius: 5px;
  padding: 8px 15px;
  font-family: Open Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #818da1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    .banner-link-btn {
      text-decoration: underline;
    }
  }
  .banner-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
  .banner-link-btn {
    color: #685cbf;
  }
`;

export const VideoIcon = styled.div`
  background-image: url(${SmallVideo});
  width: 25px;
  height: 25px;
  position: absolute;
  left: 8px;
  bottom: 8px;
  background-size: contain;
`;

export const CategoryImage = styled.img`
  ${props =>
    props.isDefault &&
    css`
      width: 28px !important;
      height: 28px !important;
    `}
`;

export const HoverCategoryImage = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  background-color: #f7f8f9;
  border-radius: 4px;
`;

export const CategoryListContainer = styled.div`
  margin-top: 33px;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 20px 20px 0;
  width: 100%;
  max-height: calc(100vh - 292px);
  overflow: hidden;

  .loading {
    padding-top: 26px;
  }
  .category-list {
    display: block;
    max-height: calc(100vh - 397px);
    margin-top: 9px;
    overflow: auto;
  }
  .category-item {
    align-items: center;
    height: 103px;
    display: flex;
    padding: 8px 20px 8px 6px;
    background-color: #fff;
    box-shadow: inset 0px -1px 0px #ecf1fc;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #fff;

    :hover {
      border: 1px solid #7773ff;
    }

    &.isDragging {
      border: 1px solid #7773ff;

      ${CategoryImage},
      ${VideoIcon} {
        display: none;
      }

      ${HoverCategoryImage} {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &:last-child {
      box-shadow: none;
    }
    .drag-drop-icon {
      flex: 0 0 22px;
    }
    .index-number {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      opacity: 0.4;
      margin-right: 20px;
      width: 20px;
      flex: 0 0 20px;
      overflow: visible;
    }
    .banner-container {
      flex: 0 0 139px;
      width: 139px;
      height: 100%;
      margin-right: 16px;
      background: #f3f6f9;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 4px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    .workout-name {
      flex: 0 0 220px;
      font-weight: 600;
      font-size: 13px;
      color: #202353;
      width: 260px;
      margin-right: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 20px;
    }
    .drag-drop-icon {
      margin-right: 22px;
    }
    .label-text {
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
    }
    .default-labels-container {
      display: flex;
      justify-content: space-around;
      width: 150px;
      margin-right: 30px;
      .duration-text {
        width: 60px;
        text-align: left;
        color: #5158cf;
      }
      .level-text {
        width: 90px;
        text-align: left;
        color: #5158cf;
      }
    }
    .custom-labels-container {
      min-width: 193px;
      .custom-label {
        margin-right: 2px;
        padding: 5px 8px;
        background: #f1f1f1;
        border-radius: 3px;
        color: #818da1;
        margin: 1px 2px 1px 0px;
        display: inline-block;
      }
    }
    .category-actions-dropdown {
      width: fit-content;
      float: right;

      &.open {
        .trigger-icon {
          background: #eaeef1;
        }
      }

      .evf-dropdown__menu {
        width: 153px;
        background: transparent;
        transform: translateY(-2px) translateX(28px);

        &.last_item {
          .dropdown-arrow {
            display: none;
          }
        }
      }

      .trigger-icon.disabled {
        cursor: not-allowed;
      }

      .dropdown-area {
        padding: 6px 0;
        background: #2d2e2d;
        border-radius: 5px;
      }

      .dropdown-arrow {
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        right: 36.5px;
        top: 2.5px;
        background: #2d2e2d;
        border-top: 1px solid #363636;
        border-left: 1px solid #363636;
        transform: rotateZ(45deg);
      }

      .evf-dropdown__option {
        padding: 9px 16px;
        .icon {
          margin-right: 10px;
          &.edit {
            width: 16px;
          }
        }
      }
    }
  }
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transform: rotate(90deg);

  .dot,
  .dot::before,
  .dot::after {
    background-color: #728096;
  }

  &:hover {
    background: #dadfea;

    .dot,
    .dot::before,
    .dot::after {
      background-color: #000000;
    }
  }
`;

export const HeaderArea = styled.div`
  display: flex;
  height: 40px;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;

  .search-input {
    width: 339px;
    height: 40px;
    margin: 20px 0;

    input {
      background-color: #f6f7f9;
      border: 1px solid #f0f0f0;

      ::placeholder {
        color: #777777;
      }

      &:hover,
      &:active,
      &:focus {
        border: 1px solid #7072dd;
      }
    }
  }
`;

export const WorkoutListHeader = styled.div``;

export const TotalWorkout = styled.div`
  color: #202353;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  margin-bottom: 11px;
  display: flex;
  justify-content: space-between;
`;

export const SelectedCounterLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #848484;
  padding-right: 15px;
`;

export const WorkoutListArea = styled.div`
  height: 410px;
  overflow-y: auto;
  padding-right: 15px;
`;

export const WorkoutEmptyListArea = styled.div`
  display: flex;
  height: 410px;
  justify-content: center;
  align-items: center;
`;

export const WorkoutButton = styled.div`
  display: flex;
  color: #7b82a4;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 5px;

    path:first-child {
      fill: #7b82a4;
    }
  }
`;

export const WorkoutWrapper = styled.div`
  border: 1px solid #ebedf4;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  ${props =>
    props.selected &&
    css`
      border: 1px solid #5158cf;
      background: #f8f8ff;
    `}
  :hover {
    cursor: pointer;
    border: 1px solid #5158cf;
    background: #f8f8ff;
  }
`;

export const Thumbnail = styled.div`
  width: 133px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaecf2;
  border-radius: 5px;
  position: relative;
  background-size: 28px;
  background-repeat: no-repeat;
  background-image: url(${CDN_URL}/images/drop_image.svg);
  background-position: center;
  ${props =>
    props.thumbnail &&
    css`
      background-image: url(${props.thumbnail});
      background-size: cover;
      svg {
        display: none;
      }
    `}
`;

export const DescWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Desc = styled.div`
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  display: flex;
`;

export const ListLabel = styled.div`
  padding: 0 10px;
  position: relative;
  overflow: hidden;
`;

export const DescTitle = styled.p`
  line-height: 18px;
  font-weight: 600;
  font-size: 13px;
  color: #202353;
  max-height: 36px;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
`;

export const LevelLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #202353;
  opacity: 0.5;
`;

export const Label = styled.span`
  padding: 5px;
  text-transform: uppercase;
  color: #818da1;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  margin-right: 2px;
  background: #f1f1f1;
  border-radius: 3px;
  margin-bottom: 2px;
  display: inline-block;
`;

export const MostRecentLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #000;
  line-height: 16px;
  padding-right: 20px;
`;

export const HeaderWrapper = styled.div`
  width: 80%;
  margin-bottom: 10px;
`;

export const CheckboxWrapper = styled.div`
  width: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  input:checked ~ .checkbox-icon {
    background-image: url(${CDN_URL}/images/check_icon.svg);
    background-size: 75%;
  }

  .checkbox-icon {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    top: 8px;
  }
`;

export const NoMoreLabel = styled.div`
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  opacity: 0.3;
`;

export const CountCharacter = styled.span`
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  color: #0000004d;
  font-size: 13px;
  transform: translateY(-50%);
`;

export const TextEditableArea = styled.div`
  position: relative;
  width: 450px;
  height: 37px;

  .text-editable__input {
    width: calc(100% - 51px);
    overflow-wrap: normal;
    overflow-y: hidden;
    overflow-x: visible;
    white-space: nowrap;
    text-overflow: initial;

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &:focus-within,
  &:focus,
  &:hover {
    background: #f1f1f3;
    border-radius: 5px;

    ${CountCharacter} {
      display: initial;
    }
  }
`;

export const ReadMore = styled.span`
  position: absolute;
  left: 10px;
  bottom: 8px;
  text-align: right;
  padding: 0 1px;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #5c5bbd !important;
  cursor: pointer;
`;

export const DescriptionArea = styled.div`
  position: relative;
  width: 550px;
  padding-bottom: ${props => (props.hasReadMore ? '19.5px' : '0')};

  &:hover {
    background-color: #f1f1f3;
  }

  ${props =>
    props.isView &&
    css`
      &:hover {
        background-color: transparent;
      }
    `}
`;

export const DescriptionView = styled.div`
  position: relative;
  width: 550px;
  margin-left: 6px;

  &:hover {
    .view {
      background: #f1f1f3;
      border-radius: 5px;
    }
  }

  &.active {
    display: block;
    padding: 5px;

    .view {
      display: block !important;
      width: auto;
      height: 110px;
      border: 1px solid #ebedf4;
      padding: 5px 15px;
      text-overflow: initial !important;
      overflow: auto;
      border-radius: 5px;
      -webkit-line-clamp: initial !important;
      -webkit-box-orient: vertical !important;
    }
  }

  .view {
    display: block;
    width: 550px;
    white-space: pre-wrap;
    overflow: hidden;
    padding: 5px 5px 4px;
    transform: translateX(-5px);

    &.haveBreakLine {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .descriptionInput__cancelViewMode {
    width: 16px;
    height: 16px;
    background: url(${CDN_URL}/images/close_circle_new.svg) no-repeat;
    position: absolute;
    top: 0;
    right: 4px;
    background-position: center;
    border-radius: 50%;
    cursor: pointer;

    :hover {
      background: url(${CDN_URL}/images/close_circle_new_hover.svg) no-repeat;
    }
  }
`;

export const InfoTooltipIcon = styled(InfoTooltipIconElement)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const NotFound = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  opacity: 0.3;
  margin: 40px;
  text-align: center;
  height: calc(100% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
