import { Button } from 'shared/FormControl';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const DayTypeIcon = styled.div`
  width: 9px;
  height: 9px;
  background-image: url(${props =>
    props.type === 'rest_day' ? `${CDN_URL}/images/macros_rest_day_icon.svg` : `${CDN_URL}/images/macros_training_day_icon.svg`});
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 5px;
`;

export const MealItemRow = styled.tr`
  td {
    :first-child {
      padding-left: 15px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    :last-child {
      padding-right: 15px;
    }

    &.highlight {
      color: #dd1f39;
    }
  }
`;

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background: #50537715;
    border-radius: 6px;
    height: 32px;

    th {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-align: right;
      text-transform: uppercase;
      color: #6a778a;
      padding: 11px 0px;

      :first-child {
        text-align: left;
        padding-left: 15px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      :last-child {
        text-align: right;
        padding-right: 15px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  tr {
    height: 44px;

    :not(:last-child) {
      border-bottom: 1px solid #e7eaf870;
    }

    td {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: right;
      color: #202353;
      padding: 11px 0px;

      :first-child {
        text-align: left;
      }
    }

    &.no-data {
      td {
        text-align: center;
        vertical-align: middle;
        font-size: 13px;
        line-height: 150%;
        color: #000000;
        opacity: 0.3;
      }
    }

    &.tr__average {
      background-color: #50537715;
      height: 32px;

      td {
        color: #6a778a;
        padding: 7px 0px;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        color: #202353;

        :first-child {
          text-align: left;
          padding-left: 15px;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        :last-child {
          text-align: right;
          padding-right: 15px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
`;

export const ViewButton = styled(Button)`
  background: #f6f6f8;
  mix-blend-mode: normal;
  border-radius: 3px;
  border-color: transparent;
  padding: 5px 12px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #202353;

  :first-child {
    margin-right: 5px;
  }

  &.active,
  :hover {
    background: #202353;
    color: #ffffff;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .total-by-day__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #202353;
  }

  .total-by-day__view-btn {
    padding: 4px;
    background: #f6f6f8;
    mix-blend-mode: normal;
    border-radius: 3px;
  }
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f4f5f9;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  padding: 30px;
`;
