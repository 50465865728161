// @flow

import _ from 'lodash';

type State = {
    unit_categories: Array<any>,
    isGettingUnit: boolean
};

type Action = {
    type: string,
    payload: any,
};

export const DEFAULT_STATE = {
    unit_categories: [],
    isGettingUnit: false
};

const UnitReducer = (state: State = DEFAULT_STATE, action: Action) => {
    switch (action.type) {
        case 'IS_GETTING_UNIT_CATEGORIES':
            return Object.assign({}, state, {
                isGettingUnit: true
            });
        case 'SUCCESS_GET_UNIT_CATEGORIES':
            return Object.assign({}, state, {
                unit_categories: action.payload.unit_categories,
                isGettingUnit: false
            });
        default:
            return state;
    }
};

export default UnitReducer;
