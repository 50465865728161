import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import thunk from 'redux-thunk';
import reducer from 'reducers';
import { googleAnalytics } from './reactGAMiddlewares';
import { Mixpanel } from 'utils/mixplanel';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import immutableTransform from 'redux-persist-transform-immutable';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createFilter } from 'redux-persist-transform-filter';

// Only store formDetails to localStorage
const storeSubset = createFilter('rootReducer', ['formDetails', 'broadcastMessages']);

const persistConfig = {
  transforms: [storeSubset, immutableTransform()],
  key: 'main',
  storage,
  whitelist: 'rootReducer',
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const history = createBrowserHistory();
history.listen(location => {
  Mixpanel.visit(location.pathname);
});

const bindMiddleware = () => {
  const middlewares = [routerMiddleware(history), loadingBarMiddleware(), googleAnalytics, thunk];

  if (process.env.NODE_ENV !== 'production') {
    const composeEnhancers =
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

    return composeEnhancers(applyMiddleware(...middlewares));
  }

  return applyMiddleware(...middlewares);
};

export default createStore(connectRouter(history)(persistedReducer), undefined, bindMiddleware());
