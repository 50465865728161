import React, { useMemo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { pluralize, getUserShortName, isTeamAdmin } from 'utils/commonFunction';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';
import { CDN_URL, CLIENT_STATUS } from 'constants/commonData';
import UpgradeYourPlanModal from 'shared/UpgradePath/components/UpgradeYourPlanModal';

export default props => {
  const { user, segments, maximum, totalActive } = props;
  const [shown, setShown] = useState(false);
  const loaded = useRef(false);

  useEffect(() => {
    const waitingActivationSegment = _.find(segments, item => item.client_connection === CLIENT_STATUS.waiting);

    if (!loaded.current && waitingActivationSegment) {
      loaded.current = true;
      props.getWaitingClients({ segmentId: waitingActivationSegment._id });
    }
  }, [segments]);

  const clients = useMemo(() => _.orderBy(props.clients.toJS(), 'full_name', 'asc'), [props.clients]);
  const show = useMemo(() => {
    return !!clients.length && !!user.has_waiting_client;
  }, [clients, user]);

  const handleClosePopup = () => {
    setShown(true);
    props.markSeenWaitingList();
  };

  const handleCloseUpgradeModal = () => {
    props.toggleModal(false);
  };

  const handleClickUpgradeButton = () => {
    handleClosePopup();

    if (isTeamAdmin(user)) {
      return props.push('/pricing-plan');
    } else {
      props.toggleModal(true, <UpgradeYourPlanModal onClose={handleCloseUpgradeModal} />);
    }
  };

  const available = Math.max(maximum - totalActive, 0);

  if (!show || shown) {
    return null;
  }
  return (
    <S.ModalWrapper
      open={available === 0 ? true : false}
      closeOnDimmerClick={false}
      onClose={handleClosePopup}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalLayout.Wrapper>
          <ModalLayout.Header>
            <div>Your clients are waiting to be activated</div>
            <S.SubTitle>
              There are {pluralize('client', clients.length, true)} that have newly registered for an account, but your
              workspace is out of client seats, so they are on a waiting list to be activated.
            </S.SubTitle>
          </ModalLayout.Header>
          <ModalLayout.Content className={clients.length > 15 ? 'showOverlay' : ''}>
            <S.Grid>
              {clients.map(client => (
                <S.ItemContainer key={client._id}>
                  <Avatar name={getUserShortName(client)} size="29" src={client.avatar} color={client.color} />
                  <S.Name>{client.full_name}</S.Name>
                </S.ItemContainer>
              ))}
            </S.Grid>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <S.UpgradeButton onClick={handleClickUpgradeButton}>Upgrade Plan</S.UpgradeButton>
          </ModalLayout.Actions>
        </ModalLayout.Wrapper>
      </Modal.Content>
    </S.ModalWrapper>
  );
};
