import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const ReturnLink = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 104%;
  text-align: center;
  color: #5158cf;
  margin-top: 50px;
  padding-bottom: 50px;

  span {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px 10px;
`;

export const Dot = styled.div`
  background: #dadfea;
  opacity: ${({ opacity }) => opacity || 1};
  transform: rotate(90deg);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: 5px;
`;

export const ClientProgress = styled.progress`
  width: 100%;
`;

export const ClientAmount = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #202353;
`;

export const ClientContainer = styled.div`
  progress[value] {
    -webkit-appearance: none;
    appearance: none;
    height: 6px;
  }

  progress[value]::-webkit-progress-bar {
    background-color: #f3f5f8;
    border-radius: 2px;
  }

  progress[value]::-webkit-progress-value {
    background-color: ${({ addOn }) => (addOn ? '#323143' : '#726dff')};
    border-radius: 2px;
  }
`;

export const PricePrefix = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: #202353;
  opacity: 0.5;
  margin-right: 20px;
`;

export const PriceCurrency = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 100%;
  color: #202353;
  margin-right: 5px;
`;

export const PriceAmount = styled.div`
  font-weight: bold;
  font-size: 34px;
  line-height: 100%;
  color: #202353;
  margin-right: 5px;
`;

export const PriceUnit = styled.div`
  font-size: 13px;
  line-height: 100%;
  color: #202353;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: top;
  width: 100%;
`;

export const RightContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 50px;
`;

export const PlanNameContainer = styled.div`
  display: flex;
`;

export const PlanName = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  color: #202353;
`;

export const PlanLabel = styled.div`
  background: #202353;
  border-radius: 3px;
  padding: 5px 6px;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 5px;

  ${({ addOn }) =>
    addOn &&
    css`
      background: #f1f2f6;
      color: #202353;
    `}
`;

export const EmailContact = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #202353;
  font-weight: 600;
  text-decoration: underline;
`;

export const PlanDescription = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #202353;
  max-width: 265px;
  margin-top: 15px;
`;

export const PriceBox = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 35px 30px;
  display: flex;
  width: 100%;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #202353;
  max-width: 530px;
  margin-top: 5px;
  margin: 0 auto;
  margin-bottom: 60px;
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #202353;
`;

export const PricingContent = styled.div`
  width: 776px;
  margin: 0 auto;
`;

export const Container = styled.div`
  overflow: auto;
  width: 100vw;
  height: calc(100vh - 53px);
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: #fff;
  background-image: url(${CDN_URL}/images/custom_enterprice_price_bg.png);
  background-position: top right;
  background-repeat: no-repeat;
  padding-top: 120px;
  overflow: scroll;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 50px;
  background-color: #fff;
  align-items: center;
  box-shadow: inset 0px -1px 0px #eceef1;

  img {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  background: #e5e5e5;
  background: linear-gradient(180deg, #f7f8fc 0%, rgba(247, 248, 252, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
`;
