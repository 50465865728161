import styled, { css } from 'styled-components';

export const EditButton = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 8px 15px;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #818da1;
  display: flex;
  align-items: center;
  justify-content: center
  cursor: pointer;

  svg {
    margin-right: 10px;
    path {
      fill: #818da1;
    }
  }

  :hover {
    color: #5158cf;
    svg {
      margin-right: 10px;
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const Wrapper = styled.div`
  background: #eaecf2;
  box-sizing: border-box;
  border-radius: 5px;
  height: 189px;
  width: 100%;
  outline: none;
  position: relative;
  margin-top: 15px;
  &.error {
    box-shadow: 0px 0px 1px 1px #ea314a;
  }

  .bannerImage__editBtn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 2;
  }

  ${props =>
    props.src &&
    css`
      background-image: url(${props.src});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      cursor: pointer;
    `}
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
