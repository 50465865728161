import { changeAutoflowClientQueryParam, getClientList } from './client/actions';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import Request from 'configs/request';
import {
  updateAutoflowIntervalWeekView,
  successGetClientStatistic as successGetClientStatisticInterval,
} from 'redux/autoflowInterval/actions';
import { Types as ForumTypes } from './forum/actions';
import { AUTOFLOW_TYPES } from 'constants/commonData';

export const Types = {
  SUCCESS_GET_AUTOFLOW_LIST: 'SUCCESS_GET_AUTOFLOW_LIST',
  FAILED_GET_AUTOFLOW_LIST: 'FAILED_GET_AUTOFLOW_LIST',
  SUCCESS_GET_AUTOFLOW_DETAIL: 'SUCCESS_GET_AUTOFLOW_DETAIL',
  AUTOFLOW_CHANGE_QUERY_PARAM: 'AUTOFLOW_CHANGE_QUERY_PARAM',
  SUCCESS_ADD_AUTOFLOW: 'SUCCESS_ADD_AUTOFLOW',
  SUCCESS_CLONE_AUTOFLOW: 'SUCCESS_CLONE_AUTOFLOW',
  FAILED_ADD_AUTOFLOW: 'FAILED_ADD_AUTOFLOW',
  FAILED_CLONE_AUTOFLOW: 'FAILED_CLONE_AUTOFLOW',
  SUCCESS_EDIT_AUTOFLOW: 'SUCCESS_EDIT_AUTOFLOW',
  FAILED_EDIT_AUTOFLOW: 'FAILED_EDIT_AUTOFLOW',
  SUCCESS_DELETE_AUTOFLOW: 'SUCCESS_DELETE_AUTOFLOW',
  FAILED_DELETE_AUTOFLOW: 'FAILED_DELETE_AUTOFLOW',
  RESET_AUTOFLOW_DETAIL_DATA: 'RESET_AUTOFLOW_DETAIL_DATA',
  CHANGE_WORKING_AUTOFLOW_DETAIL: 'CHANGE_WORKING_AUTOFLOW_DETAIL',
  AUTOFLOW_RESUME: 'AUTOFLOW_RESUME',
  AUTOFLOW_SUCCESS_RESUME: 'AUTOFLOW_SUCCESS_RESUME',
  AUTOFLOW_FAILED_RESUME: 'AUTOFLOW_FAILED_RESUME',
  AUTOFLOW_PAUSE: 'AUTOFLOW_PAUSE',
  AUTOFLOW_SUCCESS_PAUSE: 'AUTOFLOW_SUCCESS_PAUSE',
  AUTOFLOW_FAILED_PAUSE: 'AUTOFLOW_FAILED_PAUSE',
  AUTOFLOW_COUNT_CLIENTS: 'AUTOFLOW_COUNT_CLIENTS',
  AUTOFLOW_SUCCESS_COUNT_CLIENTS: 'AUTOFLOW_SUCCESS_COUNT_CLIENTS',
  AUTOFLOW_FAILED_COUNT_CLIENTS: 'AUTOFLOW_FAILED_COUNT_CLIENTS',
  AUTOGLOW_CHANGE_VIEW_MODE: 'AUTOGLOW_CHANGE_VIEW_MODE',
  AUTOGLOW_CHANGE_START_DATE: 'AUTOGLOW_CHANGE_START_DATE',
  AUTOGLOW_SUCCESS_COUNT_CLIENT_TO_ACTIVE: 'AUTOGLOW_SUCCESS_COUNT_CLIENT_TO_ACTIVE',
  AUTOGLOW_FAILED_COUNT_CLIENT_TO_ACTIVE: 'AUTOGLOW_FAILED_COUNT_CLIENT_TO_ACTIVE',
  AUTOFLOW_SUCCESS_HIDE_BANNER: 'AUTOFLOW_SUCCESS_HIDE_BANNER',
  AUTOFLOW_RESET_QUERY_DATA: 'AUTOFLOW_RESET_QUERY_DATA',
  AUTOFLOW_TURN_OFF_SETTING_SUCCESSFULLY: 'AUTOFLOW_TURN_OFF_SETTING_SUCCESSFULLY',
};

export const resetDetailData = () => ({ type: Types.RESET_AUTOFLOW_DETAIL_DATA });

const compareRequest = (request, currentState) => {
  if (request.page !== currentState.page) {
    return false;
  }

  if (request.sort !== currentState.sort) {
    return false;
  }

  if (request.sorter !== request.sorter) {
    return false;
  }

  if (request.search.trim() !== request.search.trim()) {
    return false;
  }

  return true;
};

export const changeAutoflowQueryParam = newQuery => ({
  type: Types.AUTOFLOW_CHANGE_QUERY_PARAM,
  payload: { newQuery },
});

export const changeWorkingAutoflow = data => ({
  type: Types.CHANGE_WORKING_AUTOFLOW_DETAIL,
  payload: { data },
});

export const getAutoflowList = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { common } = autoflow;

    return dispatch(
      Request.get(
        { url: `/api/autoflow/v2/list`, params: { ...common.query, search: common.query.search.trim() } },
        true,
        result => {
          const { data } = result;
          const state = getState();
          const { query } = state.rootReducer.autoflow.common;

          if (compareRequest(common.query, query)) {
            dispatch({
              type: Types.SUCCESS_GET_AUTOFLOW_LIST,
              payload: { list: data.data, total: data.total },
            });
          }

          return result.data;
        },
        error => {
          dispatch({
            type: Types.FAILED_GET_AUTOFLOW_LIST,
            error: error,
          });
        },
      ),
    );
  };
};

export const addAutoflow = data => {
  return Request.post(
    { url: '/api/autoflow/add', data },
    true,
    (r, { dispatch }) => {
      dispatch({ type: Types.SUCCESS_ADD_AUTOFLOW });
      dispatch(getAutoflowList());
    },
    (error, { dispatch }) => dispatch({ type: Types.FAILED_ADD_AUTOFLOW }),
  );
};

export const updateAutoflow = (id, data) => {
  return Request.put(
    { url: `/api/autoflow/update/${id}`, data },
    true,
    (r, { dispatch }) => {
      dispatch({ type: Types.SUCCESS_EDIT_AUTOFLOW });
      dispatch(getAutoflowList());
    },
    (error, { dispatch }) => dispatch({ type: Types.FAILED_EDIT_AUTOFLOW }),
  );
};

export const deleteAutoflow = id => {
  return Request.delete(
    { url: `/api/autoflow/delete/${id}` },
    true,
    (r, { dispatch }) => {
      dispatch({ type: Types.SUCCESS_DELETE_AUTOFLOW });
      dispatch(getAutoflowList());
    },
    (error, { dispatch }) => dispatch({ type: Types.FAILED_DELETE_AUTOFLOW }),
  );
};

export const getAutoflowDetail = id => {
  return Request.get({ url: `/api/autoflow/detail/${id}` }, true, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch({
      type: Types.SUCCESS_GET_AUTOFLOW_DETAIL,
      payload: { data },
    });

    dispatch({
      type: ForumTypes.AUTOFLOW_FORUM_CONNECT_SUCCESS,
      payload: get(data, 'forum') || null,
    });

    if (data.type === AUTOFLOW_TYPES.INTERVAL) {
      dispatch(updateAutoflowIntervalWeekView(data));
    }
  });
};

export const duplicateAutoflow = (autoflowId, data, isSettingTurnedOff) => {
  return Request.post(
    { url: `/api/autoflow/copy/${autoflowId}`, data },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      if (isSettingTurnedOff) {
        toast(
          `${data.name} has been created. Settings are on by default. Please switch it off if you would like to use the Autoflow without settings`,
        );
      } else {
        toast('This may take a few minutes');
      }
      dispatch({ type: Types.SUCCESS_CLONE_AUTOFLOW });
      dispatch(getAutoflowList());
    },
    (error, { dispatch }) => dispatch({ type: Types.FAILED_CLONE_AUTOFLOW }),
  );
};

export const resumeAutoflow = autoflow => {
  return dispatch => {
    toast.success(
      `Autoflow has been activated successfully. Changes to each client’s calendar may take a few minutes to complete.`,
      { className: 'margin-right--small' },
    );
    dispatch({ type: Types.AUTOFLOW_RESUME });

    return dispatch(
      Request.put(
        { url: `/api/autoflow/resume/${autoflow}` },
        true,
        response => {
          const { data } = response.data;
          dispatch(successGetClientStatistic({ autoflow, data }));
          dispatch({ type: Types.AUTOFLOW_SUCCESS_RESUME, payload: { data: autoflow } });
          dispatch(changeAutoflowClientQueryParam({ page: 1 }));
          dispatch(getClientList());
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_RESUME }),
      ),
    );
  };
};

export const pauseAutoflow = autoflow => {
  return dispatch => {
    toast.success(
      `Autoflow has been paused successfully. Changes to each client’s calendar may take a few minutes to complete.`,
      { className: 'margin-right--small' },
    );
    dispatch({ type: Types.AUTOFLOW_PAUSE });

    return dispatch(
      Request.put(
        { url: `/api/autoflow/pause/${autoflow}` },
        true,
        response => {
          const { data } = response.data;
          dispatch(successGetClientStatistic({ autoflow, data }));
          dispatch({ type: Types.AUTOFLOW_SUCCESS_PAUSE, payload: { data: autoflow } });
          dispatch(changeAutoflowClientQueryParam({ page: 1 }));
          dispatch(getClientList());
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_PAUSE }),
      ),
    );
  };
};

export const successGetClientStatistic = payload => ({ type: Types.AUTOFLOW_SUCCESS_COUNT_CLIENTS, payload });

export const getAutoflowClientStatistic = params => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_COUNT_CLIENTS });

    return dispatch(
      Request.get(
        { url: `/api/autoflow/client/count`, params },
        true,
        response => {
          const { data } = response.data;
          dispatch(successGetClientStatistic({ data, autoflow: params.autoflow }));
          dispatch(successGetClientStatisticInterval({ data, autoflow: params.autoflow }));
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_COUNT_CLIENTS }),
      ),
    );
  };
};

export const searchAutoflow = params => Request.get({ url: `/api/autoflow/v2/list`, params });

export const countToActive = params => {
  return Request.get(
    { url: `/api/autoflow/client/count-to-active`, params },
    true,
    (response, { dispatch }) => {
      dispatch({ type: Types.AUTOGLOW_SUCCESS_COUNT_CLIENT_TO_ACTIVE });
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.AUTOGLOW_FAILED_COUNT_CLIENT_TO_ACTIVE });
    },
  );
};

export const hideAutoflowBanner = () => {
  return Request.post({ url: '/api/autoflow/hide-banner' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_SUCCESS_HIDE_BANNER });
  });
};

export const resetAutoflowListQueryData = () => ({ type: Types.AUTOFLOW_RESET_QUERY_DATA });

export const turnOffAutoflowSetting = autoflow => {
  return Request.post({ url: `/api/autoflow/${autoflow}/turn-off-setting` }, false, (response, { dispatch }) => {
    const { data } = response.data;
    dispatch({ type: Types.AUTOFLOW_TURN_OFF_SETTING_SUCCESSFULLY, payload: { autoflow, newSettings: data } });
  });
};
