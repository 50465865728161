import React from 'react';

import * as S from './style';
import { ReactComponent as RepliedIcon } from 'assets/icons/replied-icon.svg';
import { getTitleReplied } from 'components/Inbox/helps';

const TittleReplied = props => {
  const title = getTitleReplied(props);
  return (
    <S.TittleRepliedWrapper>
      <RepliedIcon />
      <S.TittleReplied>{title}</S.TittleReplied>
    </S.TittleRepliedWrapper>
  );
};

export default TittleReplied;
