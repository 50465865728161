import React from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { Button } from 'shared/FormControl';
import { showError } from 'actions/error';
import SelectFieldDropdown from 'shared/SelectFieldDropdown';
import * as S from './style';

const SortableItem = SortableElement(props => {
  const onDelete = () => {
    props.onDelete(props.value._id);
  };

  return (
    <S.Item>
      <span className="index">{props.itemIndex + 1}.</span>
      <span>{props.value.title}</span>
      {props.showDeleteIcon ? <S.RemoveIcon onClick={onDelete} /> : null}
    </S.Item>
  );
});

const SortableList = SortableContainer(({ items, intervalSection, durationField, onDelete }) => {
  return (
    <S.ListFields className="listFields">
      {items.map((value, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            itemIndex={index}
            showDeleteIcon={items.length > 1 && (!intervalSection || value._id !== durationField._id)}
            onDelete={onDelete}
          />
        );
      })}
    </S.ListFields>
  );
});

const hiddenFields = ['orm'];

export default class ExerciseFieldsSelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldIds: this.getDefaultFields(),
    };
  }

  componentDidUpdate() {
    const { onChange } = this.props;
    const { fieldIds } = this.state;

    if (typeof onChange == 'function') {
      const fullFields = this.getDefaultFields();
      if (!_.isEqual(fieldIds, fullFields)) {
        this.setState({ fieldIds: fullFields });
      }
    }
  }

  getDefaultFields = () => {
    const { systemFields, default_fields } = this.props;
    let restField = _.find(systemFields, f => f.unique_code === 'rest');
    let result = default_fields.slice();

    if (restField && !result.includes(restField._id)) {
      result.push(restField._id);
    }

    return result;
  };

  onSave = () => {
    const { fieldIds } = this.state;

    if (typeof this.props.onSave === 'function') {
      this.props.onSave(fieldIds);
    }
  };

  render() {
    const { systemFields, dataFor } = this.props;
    const { fieldIds } = this.state;

    if (!systemFields || !systemFields.length) return null;

    const tooltipId = `tooltip--customize-guide-for${dataFor}`;

    return (
      <S.Wrapper className="exerciseCustomizeForm">
        <S.Header className="exerciseCustomizeForm__header">
          <S.Label>
            <label>Select tracking fields</label>
            <S.QuestionPlaceHolder data-tip data-for={tooltipId} />
            <ReactTooltip id={tooltipId} place="top" className="app-tooltip" effect="solid">
              <span>
                Choose up to 3 fields used for assigning the exercise. Drag and drop to rearrange the order of the
                fields.
              </span>
            </ReactTooltip>
          </S.Label>
          <div className="buttons">
            <Button className="cancel" onClick={this.props.handleClose}>
              Cancel
            </Button>
            <Button className="save" onClick={this.onSave}>
              Save
            </Button>
          </div>
        </S.Header>
        <S.Content className="exerciseCustomizeForm__content">
          {this.renderTypes()}
          {fieldIds.length < 4 ? (
            <SelectFieldDropdown
              selectedFields={fieldIds}
              onSelect={this.handleChooseField}
              hiddenFields={hiddenFields}
            />
          ) : null}
        </S.Content>
      </S.Wrapper>
    );
  }

  handleChooseField = field => {
    const { fieldIds } = this.state;
    const { onChange } = this.props;

    if (!fieldIds.includes(field._id) && fieldIds.length < 4) {
      let newFields = Object.assign([], fieldIds);
      newFields.unshift(field._id);
      this.setState({ fieldIds: newFields }, () => {
        if (typeof onChange === 'function') {
          onChange(newFields);
        }
      });
    }
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onChange } = this.props;
    let fieldIds = _.cloneDeep(this.state.fieldIds);
    fieldIds = arrayMove(fieldIds, oldIndex, newIndex);
    this.setState({ fieldIds }, () => {
      if (typeof onChange === 'function') {
        onChange(fieldIds);
      }
    });
  };

  renderTypes = () => {
    const { intervalSection, systemFields } = this.props;
    const { fieldIds } = this.state;
    let listObjectFields = fieldIds.map(item => {
      return systemFields.find(tFields => tFields._id === item);
    });

    const restField = systemFields.find(f => f.unique_code === 'rest');
    const durationField = systemFields.find(f => f.unique_code === 'duration');
    listObjectFields = listObjectFields.filter(item => item._id !== restField._id);

    return (
      <SortableList
        distance={1}
        helperClass="sortableHelper"
        axis="x"
        items={listObjectFields}
        onSortEnd={this.onSortEnd}
        intervalSection={intervalSection}
        durationField={durationField}
        onDelete={this.handleDeleteField}
      />
    );
  };

  handleDeleteField = fieldId => {
    const { fieldIds } = this.state;
    const { onChange, is_use_1rm } = this.props;

    if (fieldIds.length < 3) {
      return true;
    }
    if (is_use_1rm) {
      const { systemFields } = this.props;
      let oneRepMaxField = _.find(systemFields, f => f.unique_code === 'orm');
      let weightField = _.find(systemFields, f => f.unique_code === 'weight');
      if (fieldId == oneRepMaxField._id) {
        this.props.dispatch(showError('Cannot remove 1rm field when activating Use %'));
        return;
      } else if (fieldId == weightField._id) {
        this.props.dispatch(showError('Cannot remove weight field when activating Use %'));
        return;
      }
    }
    let newFields = fieldIds.slice();
    newFields.splice(fieldIds.indexOf(fieldId), 1);
    this.setState({ fieldIds: newFields }, () => {
      if (typeof onChange === 'function') {
        onChange(newFields);
      }
    });
  };
}
