/**
 * @flow
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import TaskCreate from './component';
import {
  addTask,
  updateTask,
  saveToLibrary,
  addRepeatTask,
  removeSettingRepeat,
  removeTask,
} from 'redux/task/task.actionCreators';
import { addFromTaskLibrary, updateFromTaskLibrary } from 'redux/tasks-library/actions';
import { toggleModal, toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import { getAllForms } from 'redux/forms/actions';

const mapStateToProps = state => {
  const { isModalOpen, rootReducer, calendarStartDate, calendarType, user } = state;
  return {
    bodymetricTypes: rootReducer.client.bodymetricTypes.filter(item => item.featuring),
    clientId: get(rootReducer, 'client.selected'),
    isModalOpen,
    calendarStartDate,
    calendarType,
    repeatTaskPermission: get(rootReducer, 'permission.repeat_task'),
    timezone: get(rootReducer, 'client.workingClientDetail.timezone', ''),
    getAttachmentUrl: '/api/task/v3/get-attachment',
    getAttachmentUrlInLibrary: 'api/task/v3/get-attachment-library',
    user: user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    updateTask: bindActionCreators(updateTask, dispatch),
    addTask: bindActionCreators(addTask, dispatch),
    addRepeatTask: bindActionCreators(addRepeatTask, dispatch),
    removeSettingRepeat: bindActionCreators(removeSettingRepeat, dispatch),
    saveToLibrary: bindActionCreators(saveToLibrary, dispatch),
    deleteClientTask: bindActionCreators(removeTask, dispatch),
    dispatch,
    getAllForms: bindActionCreators(getAllForms, dispatch),
    addFromTaskLibrary: bindActionCreators(addFromTaskLibrary, dispatch),
    updateFromTaskLibrary: bindActionCreators(updateFromTaskLibrary, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCreate);
