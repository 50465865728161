import React from 'react';
import ContentLoader from 'react-content-loader';
import _ from 'lodash';

import styled from 'styled-components';

const Wrapper = styled.div``;

const LoaderItemWrapper = styled(ContentLoader)`
  background: #fafbfc;

  :nth-child(odd) {
    background: #ffffff;
  }

  :nth-child(even) {
    box-shadow: inset -1px 0px 0px 1px #f0f0f0;
  }
`;

function LoaderItem({ tableMetadata }) {
  const { columns, scrollWidth } = tableMetadata;

  const getWidth = index => {
    return 13 + _.sumBy(_.slice(columns, 0, index));
  };

  return (
    <LoaderItemWrapper viewBox={`0 0 ${scrollWidth} 56`} width={scrollWidth} height={56} speed={2}>
      <g filter="url(#filter0_i)">
        <rect width={scrollWidth} height="56" fill="#FAFBFC" />
      </g>
      <circle cx="27" cy="28" r="15" fill="#F2F2F2" />
      <rect x="54" y="23" width="120" height="9" rx="2" fill="#F2F2F2" />
      <rect x="294" y="20" width="16" height="16" rx="2" fill="#F2F2F2" />
      <rect x="346" y="20" width="16" height="16" rx="2" fill="#F2F2F2" />
      {_.range(2, columns.length).map(index => (
        <rect x={getWidth(index)} y="23" width="31" height="9" rx="2" fill="#F2F2F2" key={index} />
      ))}
      <defs>
        <filter
          id="filter0_i"
          x="0"
          y="0"
          width={scrollWidth}
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </LoaderItemWrapper>
  );
}

export default function ClientListLoader(props) {
  return (
    <Wrapper>
      <LoaderItem {...props} />
      <LoaderItem {...props} />
      <LoaderItem {...props} />
      <LoaderItem {...props} />
    </Wrapper>
  );
}
