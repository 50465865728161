import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactAvatar from 'react-avatar';
import { getUserShortName } from 'utils/commonFunction';
import { Button } from 'shared/FormControl';
import { bindActionCreators } from 'redux';
import { CDN_URL } from 'constants/commonData';

import { logout } from 'actions/auth/login';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483100;

  .fallBackPopup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: #fff;
    border-radius: 5px;
    z-index: 2147483100;
    padding: 30px;

    &__heading {
      margin-bottom: 30px;
    }

    &__content {
      margin-bottom: 20px;
      width: 360px;

      &--title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        /* identical to box height, or 30px */

        text-align: center;

        color: #000000;

        margin-bottom: 5px;
      }

      &--description {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        text-align: center;

        color: #666666;
      }
    }

    &__owner {
      background: #F3F5F8;
      mix-blend-mode: normal;
      opacity: 0.8;
      border-radius: 6px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0px;
      margin-bottom: 30px;

      &--name {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;

        color: #000000;
        margin-top: 5px;
      }
    }
  }
`;

function TeammatesFallBackWrapper({user, location, ...props}) {
  const owner = user.team_member[0];
  return (
    <Wrapper >
      <div className="fallBackPopup">
        <div className="fallBackPopup__heading">
          <img src={`${CDN_URL}/images/upgrade_path_teammate_icon_popup.svg`} alt="" />
        </div>
        <div className="fallBackPopup__content">
          <div className="fallBackPopup__content--title">
            Your subscription plan has expired
          </div>
          <div className="fallBackPopup__content--description">
            <strong>Please contact the workspace owner</strong>
            <span> to reactivate your subscription.</span>
          </div>
        </div>
        <div className="fallBackPopup__owner">
          <ReactAvatar name={getUserShortName(owner)} className="client-detail-avatar" size="36" src={owner.avatar} color={owner.color}/>
          <div className="fallBackPopup__owner--name">
            {owner.full_name}
          </div>
        </div>
        <div className="fallBackPopup__logout">
          <Button purple onClick={() => props.logout(location.pathname)} >Logout</Button>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state: State) => {
  const {
    user
  } = state;

  return {
    user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: bindActionCreators(logout, dispatch),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeammatesFallBackWrapper)
);