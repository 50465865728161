// Libs
import React, { useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { toast } from 'react-toastify';

// Actions
import { toggleModal } from 'actions/modal';
import { getCurrentUsage, updateSelectPackage, updateZapierPrice } from 'redux/pricing/actions';
import { startTrialMealPlan } from 'redux/permission/actions';
import { initPricingData } from 'redux/pricing/actions';

// Shared
import { CheckboxCircle } from 'shared/FormControl';
import { Checkbox } from 'shared/FormControl';
import HowToCalculateModal from './HowToCalculateModal';
import OneTimeAddOnPurchased from './OneTimeAddOnPurchased';
import StartTrialModal from 'shared/UpgradePath/components/CustomMealPlanFallBack/StartTrialModal';

// Utils
import { arrayToString } from 'utils/commonFunction';
import { isTrainer } from 'utils/validations';
import { checkPastDateForTrial, getBetaLabel, getTimeRemain } from '../helper';

// Constants
import { ADD_ONS_PRICING_CODE } from '../constants';
import { CDN_URL, PLANS, TRIAL_MODE } from 'constants/commonData';

// Styles
import * as S from './style';

const FeatureItem = ({ feature, code, hideTooltip, index }) => {
  return (
    <>
      <li
        data-tip
        data-for={`${code}_${feature.title}`}
        className={classNames('featureItem__text', { 'featureItem__text--highlight': feature.isHighlight })}
      >
        {feature.title}
      </li>
      {feature.description && !hideTooltip ? (
        <ReactTooltip className="app-tooltip-white" id={`${code}_${feature.title}`} place="top" effect="solid">
          {feature.description}
        </ReactTooltip>
      ) : null}
    </>
  );
};

function getPriceByPeriod(period, packagePrice) {
  return period === 'monthly' ? get(packagePrice, 'monthlyPricing', 0) : get(packagePrice, 'anuallyPricing', 0);
}

export const getPeriodName = period => {
  return period === 'monthly' ? 'mo' : 'yr';
};

function AutomationAddOn(props) {
  const [isShowModal, setIsShowModal] = useState(false);
  const [focus, setFocus] = useState(false);
  const {
    addOn,
    addOns,
    period,
    packagePrice,
    tier,
    oneTimeAddOns,
    addOnsPricing,
    isUpdatingPromo,
    currentZapierQuantity,
    selectPackage,
    zapierScheduledSubscription,
    currentZapierUsage,
    getCurrentUsage,
    toggleModal,
    permission,
    startTrialMealPlan,
    initPricingData,
    trialMode,
    teamDataTier,
    isTrainer,
  } = props;

  const { code = '', beta_date = '', headerInfoText } = addOn || {};

  const monthlyPricing = packagePrice.get('monthlyPricing');
  const packageNoClients = packagePrice.get('packageNoClients') || 0;
  const isZapier = get(addOn, 'code', '') === 'zapier' && tier === 'studio';
  const isDisabled = monthlyPricing === 0;
  const updatedTags = arrayToString(get(addOn, 'tags', []));
  const hasZapierSelected = get(selectPackage, 'zapier_quantity', 0);

  // Show label beta
  const timezone = moment.tz.guess();
  const isPastDate = checkPastDateForTrial(beta_date, timezone);
  const isShowBetaLabel = beta_date && !isPastDate;
  const betaLabel = getBetaLabel(code);
  const betaDate = isShowBetaLabel && moment.tz(beta_date, timezone).format('MMM DD, YYYY');

  const zapierOptions = get(addOn, 'basePricings', []).map((item, index) => ({
    value: get(item, '_id'),
    index,
    annually: get(item, 'anuallyPricing'),
    annuallyDiscount: get(item, 'monthOfAnuallyPricing'),
    monthly: get(item, 'monthlyPricing'),
    volumes: get(item, 'packageNoVolumes'),
  }));
  const isZapierModal = hasZapierSelected !== currentZapierQuantity;

  const filterCurrentPlan =
    isZapierModal && hasZapierSelected > 0
      ? zapierOptions.filter(item => item.volumes === hasZapierSelected)
      : zapierOptions.filter(item => item.volumes === currentZapierQuantity);

  // Check currentZapierQuantity is from Zapier Modal to Billing Page,
  // otherwise hasZapierSelected check current selected Zapier value
  const checkZapierCurrentTier =
    currentZapierQuantity || hasZapierSelected > 0 ? filterCurrentPlan[0] : zapierOptions[0];

  const [zapierTier, setZapierTier] = useState(checkZapierCurrentTier);

  useEffect(() => {
    if (!isUpdatingPromo && isZapier && zapierTier !== undefined) {
      getCurrentUsage && getCurrentUsage();
      const selectedAddOns = addOnsPricing.map(item => {
        if (get(item, 'code', '') === 'zapier') {
          if (addOns.zapier === true) {
            return {
              ...item,
              basePrice: {
                monthlyPricing: get(zapierTier, 'monthly', 0),
                anuallyPricing: get(zapierTier, 'annually', 0),
              },
            };
          } else {
            return {
              ...item,
              basePrice: {
                monthlyPricing: 0,
                anuallyPricing: 0,
              },
            };
          }
        }
        return item;
      });
      props.updateZapierPrice({ addOnsPricing: selectedAddOns });
      if (isZapier && addOns.zapier) {
        props.updateSelectPackage({
          zapier_quantity: get(zapierTier, 'volumes', 0),
        });
      }
    }
  }, [addOns.zapier, zapierTier, isUpdatingPromo, getCurrentUsage]);

  const shouldHideAddOn = useMemo(() => {
    if (!addOn.planAvailable || !addOn.planAvailable.length) {
      return false;
    }
    return addOn.planAvailable.includes(tier);
  }, [addOn, tier]);

  const handleCheck = e => {
    if (props.isLoading) {
      return;
    }

    let selectedAddOns = {
      addOns: {
        ...addOns,
        [addOn.code]: e.target.checked,
      },
    };

    if (isZapier && addOns.zapier) {
      selectedAddOns.zapier_quantity = get(zapierTier, 'volumes', 0);
    }

    props.updateSelectPackage(selectedAddOns);
  };

  const showHowToCalculateModal = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  if (!shouldHideAddOn) {
    return null;
  }

  if (oneTimeAddOns && oneTimeAddOns.get(addOn.code)) {
    return <OneTimeAddOnPurchased addOn={addOn} />;
  }

  const handleSelectChange = option => {
    setZapierTier(option);
  };

  const handleScrollToOption = () => {
    setTimeout(() => {
      const selectedOption = document.querySelector('.selected-zapier');
      if (selectedOption) {
        selectedOption.scrollIntoView();
      }
    }, 0);
  };

  const OptionWithRadio = props => {
    let className = 'zapier_options';
    let selectedOption = '';
    const isSelected = props.isSelected || zapierTier === props.data;

    if (isSelected) {
      className += ' zapier_options--is-focused';
      selectedOption += 'selected-zapier';
    }
    const tierIndex = get(props, 'data.index', 0);
    const tierTitle = `Tier ${tierIndex + 1}`;

    const handleChangeOption = () => {
      props.selectOption(props.data);
    };

    const checkCurrentPeriod = get(currentZapierUsage, 'billing_cycle', null) === 'month' ? 'monthly' : 'annually';

    const isCurrentTag =
      checkCurrentPeriod === period &&
      get(currentZapierUsage, 'zapier_quantity', null) === get(props, 'data.volumes', 0);

    const checkScheduledPeriod = get(zapierScheduledSubscription, 'billing_cycle', null) === 1 ? 'monthly' : 'annually';

    const isScheduledTag =
      get(zapierScheduledSubscription, 'zapier_quantity', null) === get(props, 'data.volumes', 0) &&
      checkScheduledPeriod === period;

    return (
      <S.OptionsWrapper className={selectedOption}>
        <CheckboxCircle
          label={
            <S.CustomZapierOptionsWrapper>
              <S.ZapierTask>
                <p>{tierTitle}</p>
                <span>
                  {get(props, 'data.volumes', 0).toLocaleString('en-US')} tasks{' '}
                  {isScheduledTag && <S.ScheduledPlan>Scheduled Plan</S.ScheduledPlan>}
                  {isCurrentTag && <S.CurrentPlan>Current Plan</S.CurrentPlan>}
                </span>
              </S.ZapierTask>
              {period === 'monthly' ? (
                <S.ZapierPrice>
                  <S.ZapierPriceUnit>
                    <p>${get(props, 'data.monthly', 0).toLocaleString('en-US')}</p>&nbsp;USD
                  </S.ZapierPriceUnit>
                  <S.ZapierPeriodLabel>per month</S.ZapierPeriodLabel>
                </S.ZapierPrice>
              ) : (
                <S.ZapierPrice>
                  <S.ZapierPriceUnit>
                    <S.ZapierOriginalPrice>
                      ${get(props, 'data.monthly', 0).toLocaleString('en-US')}
                    </S.ZapierOriginalPrice>
                    <p>${get(props, 'data.annuallyDiscount', 0).toLocaleString('en-US')}</p>&nbsp;USD
                  </S.ZapierPriceUnit>
                  <S.ZapierPeriodLabel>per month</S.ZapierPeriodLabel>
                </S.ZapierPrice>
              )}
            </S.CustomZapierOptionsWrapper>
          }
          value="next_payment"
          checked={props.isSelected}
          onChange={handleChangeOption}
          className={className}
          customCheckbox="20px"
        />
      </S.OptionsWrapper>
    );
  };

  const SingleValue = ({ data }) => {
    const tierTitle = `Tier ${data.index + 1}`;

    return (
      <S.ZapierSingleWrapper>
        <S.ZapierSingleTitle>{tierTitle}</S.ZapierSingleTitle>
        {period === 'monthly' ? (
          <>
            <S.SelectedZapier>
              {get(data, 'volumes', 0).toLocaleString('en-US')} tasks - $
              {get(data, 'monthly', 0).toLocaleString('en-US')}
            </S.SelectedZapier>
            <S.ZapierPeriod>/mo</S.ZapierPeriod>
          </>
        ) : (
          <>
            <S.SelectedZapier>
              {get(data, 'volumes', 0).toLocaleString('en-US')} tasks - $
              {get(data, 'annuallyDiscount', 0).toLocaleString('en-US')}
            </S.SelectedZapier>
            <S.ZapierPeriod> /mo</S.ZapierPeriod>
          </>
        )}
      </S.ZapierSingleWrapper>
    );
  };

  const openStartTrial = () => {
    switch (code) {
      case ADD_ONS_PRICING_CODE.MEAL_PLAN:
        toggleModal(true, <StartTrialModal onClose={closeStartTrial} onConfirmed={handleStartTrial} />);
        break;

      default:
        break;
    }
  };

  const handleStartTrial = () => {
    startTrialMealPlan().then(() => {
      initPricingData();
      toggleModal(false);
      toast('7-day trial of Advanced Meal Plans has started.');
    });
  };

  const closeStartTrial = () => {
    toggleModal(false);
  };

  return (
    <S.AutomationWrapper
      disabled={isDisabled}
      active={isDisabled ? false : addOns[addOn.code]}
      data-tip
      data-for="automation_tooltip"
      data-billing-automation={`automation${addOns[addOn.code] ? ' checked' : ''}`}
      name={addOn.title}
      isShowBetaLabel={isShowBetaLabel}
    >
      <div className="automation__left">
        <div className="automation__icon">
          <img src={addOn.icon} />
        </div>
        <div className="automationContent">
          <div className="automationContent__name">
            <a target="_blank" href={get(addOn, 'articleLink', '')}>
              {addOn.title}
            </a>
            <span className="automationContent__name--addOn">{updatedTags}</span>
          </div>
          <div className="automationContent__featureTitle">{addOn.description}</div>
          <ul className="automationContent__featureItem">
            {props.addOn.features.map(feat => (
              <FeatureItem hideTooltip={isDisabled || isZapier} feature={feat} code={addOn.code} />
            ))}
          </ul>
        </div>
      </div>
      <div className="automation__right">
        <div className="automation__checkbox">
          <span className="automation__checkbox--text">Add to my plan</span>
          <Checkbox
            checked={isDisabled ? false : addOns[addOn.code]}
            onChange={handleCheck}
            className="select-checkbox"
            disabled={isDisabled}
            dataAttribute={`${addOn.title}`}
          />
        </div>
        {addOn.isMeter ? (
          <>
            {isZapier && (period !== 'monthly' || currentZapierQuantity === 0) ? (
              <div className="automation__dropdown">
                <Select
                  menuPlacement="top"
                  options={zapierOptions}
                  selectedValue={zapierTier}
                  isSearchable={false}
                  className="dropdown--zapier-picker"
                  classNamePrefix="common-select-container"
                  blurInputOnSelect={true}
                  onMenuOpen={handleScrollToOption}
                  components={{
                    Option: OptionWithRadio,
                    SingleValue,
                    IndicatorSeparator: null,
                  }}
                  defaultValue={checkZapierCurrentTier}
                  onChange={handleSelectChange}
                  styles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
                    }),
                  }}
                />
              </div>
            ) : isZapier ? (
              <div className="automation__pricing">
                <div className="automation__pricing--cost">
                  <span className="automation__pricing--symbol">$</span>
                  <span className="automation__pricing--value">
                    {get(zapierTier, 'monthly', 0).toLocaleString('en-US')}
                  </span>
                  <span className="automation__pricing--unit">/mo</span>
                </div>
                <div className="automation__pricing--zapier">
                  <span className="automation__pricing--tier">TIER {get(checkZapierCurrentTier, 'index', 0) + 1}</span>
                  &nbsp;{get(checkZapierCurrentTier, 'volumes', 0).toLocaleString('en-US')} Tasks
                </div>
              </div>
            ) : (
              <div className="automation__pricing">
                <div className="automation__pricing--cost">
                  <span className="automation__pricing--from">From</span>
                  <span className="automation__pricing--symbol">$</span>
                  <span className="automation__pricing--value">{addOn.startingPrice}</span>
                  <span className="automation__pricing--unit">/mo</span>
                </div>
                <div className="automation__pricing--clients">
                  {packageNoClients < 30 ? 'Train clients in Autoflow' : 'Train up to 30 clients in Autoflow'}
                </div>
              </div>
            )}
          </>
        ) : isObject(get(addOn, 'basePriceAfterDiscount')) ? (
          <div className="automation__pricing automation__pricing--discount">
            <div className="automation__pricing--cost">
              <span className="automation__pricing--symbol">$</span>
              <span className="automation__pricing--value">
                {getPriceByPeriod(period, addOn.basePriceAfterDiscount)}
              </span>
              {addOn.isOneTime ? (
                <span className="automation__pricing--unit">One time</span>
              ) : (
                <span className="automation__pricing--unit">/{getPeriodName(period)}</span>
              )}
            </div>
            <div className="automation__pricing--cost-discount">
              <span className="automation__pricing--value-discount">${getPriceByPeriod(period, addOn.basePrice)} </span>
              {addOn.isOneTime ? (
                <span className="automation__pricing--unit-discount">One time</span>
              ) : (
                <span className="automation__pricing--unit-discount">/{getPeriodName(period)}</span>
              )}
              <span className="automation__pricing--desc-discount">{get(addOn, 'basePricingDiscountNote', '')}</span>
            </div>
            {isShowBetaLabel ||
            get(permission, 'is_used_trial_meal_plan', false) ||
            trialMode === TRIAL_MODE.Active ||
            trialMode === TRIAL_MODE.Grace ||
            teamDataTier === PLANS.starter.key ||
            isTrainer ? (
              <></>
            ) : (
              <button className="automation__pricing--btn-trial" onClick={openStartTrial}>
                Start 7-day trial
              </button>
            )}
          </div>
        ) : (
          <div className="automation__pricing">
            <div className="automation__pricing--cost">
              <span className="automation__pricing--symbol">$</span>
              <span className="automation__pricing--value">{getPriceByPeriod(period, addOn.basePrice)}</span>
              {addOn.isOneTime ? (
                <span className="automation__pricing--unit">One time</span>
              ) : (
                <span className="automation__pricing--unit">/{getPeriodName(period)}</span>
              )}
            </div>
          </div>
        )}
        {addOn.moreInfoLink ? (
          <a className="automation__howCalculate" href={addOn.moreInfoLink} target="_blank" rel="noopener noreferrer">
            {addOn.moreInfoText}
          </a>
        ) : (
          <div
            className="automation__howCalculate"
            onClick={showHowToCalculateModal}
            onMouseOver={() => setFocus(true)}
            onMouseOut={() => {
              setFocus(false);
              ReactTooltip.rebuild();
            }}
          >
            {addOn.moreInfoText}
          </div>
        )}
        <HowToCalculateModal isModalOpen={isShowModal} addOn={addOn} period={period} closeModal={handleCloseModal} />
      </div>

      {isDisabled && (
        <ReactTooltip
          className="app-tooltip-white"
          id="automation_tooltip"
          place="top"
          getContent={() => {
            return null;
          }}
        >
          {!focus && (
            <>
              Only available for <strong>Pro Plan</strong> and <strong>Studio Plan</strong>
            </>
          )}
        </ReactTooltip>
      )}
      {isShowBetaLabel && (
        <div className="automation__label">
          <img src={`${CDN_URL}/images/checkmark-yellow.svg`} alt="" />
          <div className="automation__label--content">
            {getTimeRemain(beta_date)} {headerInfoText}
          </div>
        </div>
      )}
    </S.AutomationWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const currentZapierUsage = rootReducer.pricing.get('zapierCurrentUsage').toJS();

  return {
    addOns: rootReducer.pricing.getIn(['selectPackage', 'addOns']).toJS(),
    period: rootReducer.pricing.getIn(['selectPackage', 'period']),
    packagePrice: rootReducer.pricing.getIn(['selectPackage', 'packagePrice']),
    tier: rootReducer.pricing.getIn(['selectPackage', 'tier']),
    oneTimeAddOns: rootReducer.pricing.getIn(['teamData', 'oneTimeAddOns']),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    isUpdatingPromo: rootReducer.pricing.get('isUpdatingPromo'),
    currentZapierQuantity: rootReducer.pricing.getIn(['teamData', 'zapier_quantity']),
    zapierScheduledSubscription: get(currentZapierUsage, 'scheduledSubscription', 0),
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    currentZapierUsage,
    permission: rootReducer.permission,
    trialMode: rootReducer.pricing.getIn(['teamData', 'trialMode']),
    teamDataTier: rootReducer.pricing.getIn(['teamData', 'tier']),
    isTrainer: isTrainer(user),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
  updateZapierPrice: bindActionCreators(updateZapierPrice, dispatch),
  getCurrentUsage: bindActionCreators(getCurrentUsage, dispatch),
  startTrialMealPlan: bindActionCreators(startTrialMealPlan, dispatch),
  initPricingData: bindActionCreators(initPricingData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomationAddOn);
