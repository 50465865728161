import React, { memo } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import * as S from './style';
import Urlify from 'shared/Urlify';
import GiphyWatermark from 'shared/Giphy/components/Watermark';
import S3Images from 'shared/S3Images';
import S3Video from 'shared/S3Video';
import { ReactComponent as RepliedAttachmentIcon } from 'assets/icons/replied-attachment-icon.svg';
import { MESSAGE_TYPE, getMessageType, getMessageThumbnail } from 'components/Inbox/helps';

const RepliedMessage = ({ replyMessage, isOwner, onClick, showRecord, disabled }) => {
  const messageType = getMessageType(replyMessage);
  const thumbnail = getMessageThumbnail(replyMessage);
  switch (messageType) {
    case MESSAGE_TYPE.DELETED:
      return (
        <S.MessageRepliedText isOwner={isOwner} isDeleted showRecord={showRecord} onClick={onClick} disabled={disabled}>
          <div>Message removed</div>
        </S.MessageRepliedText>
      );
    case MESSAGE_TYPE.TEXT:
      return (
        <S.MessageRepliedText isOwner={isOwner} onClick={onClick} showRecord={showRecord}>
          <div>
            <Urlify>{replyMessage.content}</Urlify>
          </div>
        </S.MessageRepliedText>
      );
    case MESSAGE_TYPE.IMAGE:
    case MESSAGE_TYPE.GIF:
    case MESSAGE_TYPE.GIPHY:
      return (
        <S.MessageRepliedMedia isOwner={isOwner} onClick={onClick} showRecord={showRecord}>
          <GiphyWatermark showWatermark={isEqual(messageType, MESSAGE_TYPE.GIPHY)} repliedSize={true}>
            <S3Images src={[thumbnail]} />
          </GiphyWatermark>
        </S.MessageRepliedMedia>
      );
    case MESSAGE_TYPE.AUDIO:
      return (
        <S.MessageRepliedText isOwner={isOwner} onClick={onClick} showRecord={showRecord}>
          <div>Attachment</div>&nbsp;
          <RepliedAttachmentIcon />
        </S.MessageRepliedText>
      );
    case MESSAGE_TYPE.VIDEO:
      return (
        <S.MessageRepliedMedia isOwner={isOwner} onClick={onClick} showRecord={showRecord}>
          {thumbnail ? (
            <GiphyWatermark showWatermark={false} repliedSize={true}>
              <S3Images src={[thumbnail]} />
            </GiphyWatermark>
          ) : (
            <S3Video
              videoLink={get(replyMessage, 'attachment')}
              videoType={get(replyMessage, 'attachment_type')}
              thumbnail={thumbnail}
            />
          )}
          <S.VideoPlayButton />
        </S.MessageRepliedMedia>
      );
    case MESSAGE_TYPE.MULTIPLE_MEDIA:
      const firstAttachment = get(replyMessage, 'attachments[0]');
      const firstAttachmentThumbnail = getMessageThumbnail(firstAttachment);
      const isFirstAttachmentTypeVideo = get(firstAttachment, 'attachment_type').startsWith('video');
      return (
        <S.MessageRepliedMedia isOwner={isOwner} onClick={onClick} showRecord={showRecord}>
          <GiphyWatermark showWatermark={false} repliedSize={true}>
            <S3Images src={[firstAttachmentThumbnail]} />
          </GiphyWatermark>
          {isFirstAttachmentTypeVideo && <S.VideoPlayButton />}
        </S.MessageRepliedMedia>
      );
    default:
      return <></>;
  }
};

export default memo(RepliedMessage);
