import styled from 'styled-components';

export const CustomerAvatar = styled.div``;

export const CustomerInfo = styled.div`
  margin-left: 24px;

  .customerInfo__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #202353;
    display: flex;
    margin-bottom: 10px;
    button {
      height: 27px;
    }
    button:hover {
      background-color: transparent;
    }
  }

  .customerInfo__item {
    display: grid;
    grid-template-columns: 80px 1fr;
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 150%;
    color: #202353;

    div:first-child {
      font-weight: 600;
    }
  }

  .customerInfo__coach {
    display: flex;
    align-items: center;

    .sb-avatar {
      margin-right: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px 25px 13px;
  display: flex;
  margin-bottom: 5px;
`;

export const OverviewLink = styled.a`
  color: inherit;
  font-size: inherit;
  &:hover {
    color: inherit;
    text-decoration: none !important;
  }
`;
