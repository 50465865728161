import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { DECLINE_STEP } from './helper';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: ${({ step }) => (step === DECLINE_STEP.STEP_ONE ? '550px' : '934px')};
    border-radius: 8px;
  }
  & .ui.close-button {
    top: -8px;
    right: -8px;
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 27px;
  color: #151619;
  font-weight: 600;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px !important;
`;

export const ModalAction = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 24px !important;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 20px 24px;
  box-shadow: 0px -4px 16px 0px #6b6fb71a;
  height: 80px;
`;

export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BaseButton = styled.button`
  font-size: 13px;
  line-height: 17.7px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: none;

  :disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const CancelButton = styled(BaseButton)`
  color: #818da1;
  padding: 11px 25px;
  border: 1px solid #d6dae4;
  background-color: #ffffff;
`;

export const SubmitButton = styled(BaseButton)`
  color: #ffffff;
  padding: 11px 35px;
  background-color: ${({ step }) => (step === DECLINE_STEP.STEP_ONE ? '#5158CF' : '#ea314a')};
  border: none;

  :not([disabled]):hover {
    background-color: ${({ step }) => (step === DECLINE_STEP.STEP_ONE ? '#5559FF' : '#ea314ae6')};
  }
`;

export const ProgressStep = styled.div`
  width: ${({ width }) => `${width}px`};
  background: ${({ active }) => (active ? 'rgba(32, 35, 83, 1)' : 'rgba(230, 230, 230, 1)')};
  height: 6px;
  border-radius: 10px;
`;
