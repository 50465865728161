import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7B7E91;
`;

export const UnitInputWrapper = styled.div`
  border: 1px solid #dadbea;
  border-radius: 5px;
  padding: 4px 9px;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #202353;
    border: none;
    padding: 0;
    outline: none;
    width: 100%;
    background-color: transparent;
  }
  ${({ error }) =>
    error &&
    css`
      border: 1px solid #ea314a;
    `}
`;
