import styled, { css } from 'styled-components';

export const ContentEditable = styled.div`
  outline: none;
  height: auto;
  overflow: hidden;
  overflow-wrap: break-word;
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: ${props => `${props.lineHeight || 27}px`};
  color: #202353;
  padding: 0;
  white-space: pre-wrap;

  :empty::before {
    content: ${props => `'${props.placeholder}'`};
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    line-height: inherit;
    color: #202353;
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  padding: 7px 9px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  ${props =>
    props.readOnly &&
    css`
      &.text-editable__container--active {
        border: none;
      }
    `};
`;

export const Wrapper = styled.div`
  width: auto;
  background-color: transparent;

  .text-editable__container {
    &--active {
      background-color: #ffffff;
      border-color: #5158cf;
    }

    :hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
      background-color: #f1f1f3;
      cursor: text;
    }

    &--invalid {
      border-color: #ea314a;
      background-color: #fef4f6;
    }
  }
`;
