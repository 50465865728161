import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import SelectOwnerOption from 'shared/SelectOwnerOption';

import * as S from '../ExerciseFilterPopup/style';

const SelectOwner = props => {
  const {
    user,
    owners,
    selectedOwners: originalSelectedOwners = [],
    onSelectowners,
    onShowDropdown,
    placeholder,
    menuWidth,
  } = props;
  const [selectedOwners, setSelectedOwners] = useState(originalSelectedOwners);
  const [openedSection, setOpenSection] = useState(true);

  useEffect(() => {
    if (originalSelectedOwners.length && !_.isEqual(originalSelectedOwners, selectedOwners)) {
      setSelectedOwners(originalSelectedOwners);
    }
  }, [originalSelectedOwners]);

  const handleChangeOwner = value => {
    setSelectedOwners(value);
    onSelectowners && onSelectowners(value);
  };

  const teamMembers = user.team_member || [];
  const options = _.map(teamMembers, item => {
    return Object.assign({}, item, {
      key: item._id,
      value: `${item.first_name} ${item.last_name}`,
      label: `${item.first_name} ${item.last_name}`,
    });
  });
  const handleMenuOpen = () => {
    onShowDropdown && onShowDropdown(true);
    setTimeout(() => {
      const selectedEl = document.querySelector('.multi-select__value-container--has-value');
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    }, 15);
  };

  return (
    <S.Section expand={openedSection} className="owner">
      <S.SectionHeader onClick={() => setOpenSection(!openedSection)}>
        <S.SectionTitle>Owner</S.SectionTitle>
        <S.SectionToggleIcon />
      </S.SectionHeader>
      <S.SectionBody expand={!openedSection}>
        <S.SelectOwner>
          <Select
            isMulti
            isSearchable
            options={options}
            components={{ Option: SelectOwnerOption }}
            classNamePrefix="multi-select"
            value={
              _.isEmpty(owners)
                ? ''
                : options.find(function (option) {
                    return option.value === selectedOwners;
                  })
            }
            placeholder={placeholder || 'Enter the users'}
            onChange={handleChangeOwner}
            className="multi-select-container"
            noOptionsMessage={() => 'No results found'}
            menuPosition="fixed"
            styles={{
              control: provided => ({
                ...provided,
                cursor: 'pointer',
              }),
              menuPortal: provided => ({
                ...provided,
                zIndex: 9999,
              }),
              menu: styles => ({ ...styles, width: menuWidth ? `${menuWidth}px` : '100%' }),
            }}
            onMenuOpen={handleMenuOpen}
            onMenuClose={() => onShowDropdown && onShowDropdown(false)}
          />
        </S.SelectOwner>
      </S.SectionBody>
    </S.Section>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, null)(SelectOwner);
