import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const CreateNew = styled.div`
  width: 21px;
  height: 20px;

  cursor: pointer;
  background: transparent url(${CDN_URL}/images/new_inbox.svg) no-repeat center;
  background-size: contain;

  margin-bottom: 5px;
  margin-left: 14px;

  ${props =>
    props.readOnly &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`;

export const CreateNewWrapper = styled.div`
  display: flex;
  width: 37px;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  :hover {
    background: #f6f7ff;
  }
`;

export const CreateBroadcast = styled.div`
  margin-left: 4px;

  :hover .broadcast-icon {
    border-radius: 8px;
    background: rgba(94, 89, 255, 0.1);
  }

  .__react_component_tooltip {
    border-radius: 5px;
    background: #2d2e2d;
  }

  .__react_component_tooltip.place-bottom:after {
    top: -5px;
  }
`;
export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  color: #000000;
`;

export const Container = styled.div`
  width: 333px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

export const BroadcastButton = styled(Button)`
  display: flex;
  max-height: 36px;
  width: 115px;
  padding: 8px 12px 8px 4px;
  border-radius: 5px;
  background: #f6f7ff;
  align-items: center;
  border: none;

  .broadcast-icon {
    margin-right: 6px;
  }

  :hover {
    background: #5158cf;
    .broadcast-icon {
      path {
        stroke: #fff;
      }
    }
    span {
      color: #fff;
    }
  }

  span {
    color: #5158cf;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    margin-top: 2px !important;
  }
`;

export const CreateNewInbox = styled(Button)`
  display: flex;
  border-radius: 5px;
  background-color: #f6f7ff;
  align-items: center;
  border: none;
  min-width: 0;
  width: 37px;
  height: 36px;
  padding: 8px;
  margin-left: 4px;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: #5158cf;
    .new-inbox {
      path {
        stroke: #fff;
      }
    }
  }

  ${props =>
    props.readOnly &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
      :hover {
        background-color: #f6f7ff;
        .new-inbox {
          path {
            stroke: #5158cf;
          }
        }
      }
    `}
`;
