import styled, { css } from 'styled-components';

export const SettingWrapper = styled.div`
  line-height: 0;
  .settings-accordion .title.advanced-settings-title {
    margin: 0 0 4px 0 !important;
    display: inline-flex;
    align-items: center;
    font-family: 'Open Sans' !important;
    padding: 0 !important;
  }
  .advanced-settings-title {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-transform: capitalize;
    margin: 0px 5px;
    color: #5158cf !important;
    path {
      stroke: #5158cf;
    }
  }

  .task-settings-icon {
    margin-right: 5px !important;
  }

  .accordion-icon {
    margin-left: 8px;
    transition: transform 0.2s ease, opacity 0.2s ease;
    ${props => props.active && `transform: rotate(180deg) translateY(2px);`};
  }

  .settings-accordion .content.active {
    margin-top: 7px;
    margin-bottom: 0px;
    border-radius: 4px;

    > div:last-child {
      margin-bottom: 0;
    }
  }
`;
