import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import { get, last, lowerCase, omit } from 'lodash';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

import { SelectFile } from 'shared/FormControl';
import { getExtension, getPresignedUploadUrl } from 'utils/commonFunction';
import UploadCoverImage from './UploadDocCoverImage';
import MediaUploadProgress from 'shared/MediaUploadProgress';
import MediaPlayer from 'shared/MediaPlayer';
import { createObjectURL, revokeObjectURL } from 'utils/commonFunction';
import { isMp3File, isVideoFile, isDocumentFile, isImageFile, validateLink } from 'utils/validations';
import { onDragSectionLeftPanelEnd, startUploadAttachment, endUploadAttachment } from 'redux/workout-builder/actions';

import { ReactComponent as VideoIcon } from 'assets/icons/freestyle-attatchment-video.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/freestyle-attatchment-audio.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/freestyle-attatchment-image.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/freestyle-attatchment-document.svg';
import PDFIcon from 'assets/icons/freestyle-section-pdf-icon.svg';
import DocIcon from 'assets/icons/freestyle-section-doc-icon.svg';
import XlsxIcon from 'assets/icons/freestyle-section-xlsx-icon.svg';
import DefaultImage from 'assets/icons/freestyle_upload.svg';
import DragImage from 'assets/icons/freestyle_upload_drag.svg';
import LinkAttachment from './LinkAttachment';
import { VALIDATIONS } from './LinkAttachment/helper';
import * as S from './style';

const supportedExtensions = [
  ...VALIDATIONS.VIDEO.extensions,
  ...VALIDATIONS.IMAGE.extensions,
  ...VALIDATIONS.DOCUMENT.extensions,
  ...VALIDATIONS.AUDIO.extensions,
];

const FREESTYLE_ATTACHMENT_UPLOAD_URL = '/api/file/gen-presigned-urls-training-section';

const getAttachmentType = mimetype => {
  if (isVideoFile(mimetype)) return 1;
  if (isMp3File(mimetype)) return 2;
  if (isImageFile(mimetype)) return 3;
  if (isDocumentFile(mimetype)) return 4;
};

function detectFileIcon(file, document_type) {
  let type = document_type;

  if (file) {
    const { name, original_name } = file;
    type = last((original_name || name || '').split('.'));
  }

  switch (type) {
    case 'docx':
    case 'doc':
      return DocIcon;
    case 'pdf':
      return PDFIcon;
    case 'xlsx':
    case 'xls':
    case 'csv':
      return XlsxIcon;
    default:
      return '';
  }
}

const UploadAndDragSection = props => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [localUrl, setLocalUrl] = useState(null);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  const [isUploading, setUploading] = useState(false);
  const [isUploadingCoverImage, setUploadingCoverImage] = useState(false);
  const [attachments, setAttachments] = useState(get(props, 'originalAttachments', null));
  const [addLink, setAddLink] = useState(get(props, 'originalAttachments[0].type', 0) === 5); // default is false
  const [updateConfigs, setUpdateConfigs] = useState(null);
  useEffect(() => {
    const uploadFile = async () => {
      const { configs, uploadUrl } = await getPresignedUploadUrl(FREESTYLE_ATTACHMENT_UPLOAD_URL, file);
      setUpdateConfigs(configs);
      setUploading(true);
      setUploadConfigs({
        method: 'PUT',
        url: uploadUrl,
        headers: { 'Content-Type': file.type },
        data: file,
      });
    };
    if (file !== null) {
      uploadFile();
    }
    if (file === null) {
      clearLocalUrl(); // clear localUrl when remove file
    }
  }, [file]);
  useEffect(() => {
    return () => {
      if (localUrl) {
        revokeObjectURL(localUrl); // cleanup localUrl when component unmount
      }
    };
  }, []);

  useEffect(() => {
    if (isUploading || isUploadingCoverImage) {
      props.startUploadAttachment();
    } else {
      props.endUploadAttachment();
    }
  }, [isUploading, isUploadingCoverImage]);

  const handleFileSelect = file => {
    setFile(file);
    setLocalUrl(createObjectURL(file));
    setOpen(false);
    // handleTogglePopup();
  };

  const handleRemoveUploadedAttachment = () => {
    setUploading(false);
    setFile(null);
    setUploadConfigs(null);
    clearAttachments();
    props.onUpdateAttachments([]);
  };

  const clearLocalUrl = () => {
    if (localUrl) {
      revokeObjectURL(localUrl);
    }
    setLocalUrl(null);
  };

  const PopupContent = () => {
    const menuItems = [
      {
        name: 'Video',
        icon: <VideoIcon />,
        accept: '.avi, .flv, .mp4, .mov, .3gp, .m4v, video/*',
        validateExtentions: ['avi', 'flv', 'mp4', 'mov', '3gp', 'm4v'],
        maxSize: 150,
        limitMessage: 'Video size limit is 150MB. Please compress your video first or attach a video link instead.',
      },
      {
        name: 'Audio',
        icon: <AudioIcon />,
        accept: '.m4a, .mp3, audio/*',
        validateExtentions: ['m4a', 'mp3'],
        maxSize: 50,
        limitMessage: 'Audio size limit is 50MB.',
      },
      {
        name: 'Image',
        icon: <ImageIcon />,
        accept: '.png, .jpeg, .jpg, image/*',
        validateExtentions: ['png', 'jpeg', 'jpg'],
        maxSize: 25,
        limitMessage: 'Image size limit is 25MB.',
      },
      {
        name: 'Document',
        icon: <DocumentIcon />,
        accept: '.doc, .docx, .xls, .xlsx, .pdf, application/pdf',
        validateExtentions: ['doc', 'docx', 'xls', 'xlsx', 'pdf'],
        maxSize: 25,
        limitMessage: 'Document size limit is 25MB.',
      },
    ];
    return (
      <S.PopupWrapper>
        {menuItems.map(item => (
          <SelectFile
            key={item.name}
            trigger={
              <S.PopupButton>
                {item.icon}
                <p>{item.name}</p>
              </S.PopupButton>
            }
            onSelect={handleFileSelect}
            accept={item.accept}
            validateExtentions={item.validateExtentions}
            maxSize={item.maxSize}
            fileSizeLimitMessage={item.limitMessage}
          />
        ))}
      </S.PopupWrapper>
    );
  };

  const handleTogglePopup = () => {
    setOpen(!open);
  };

  const handleUploadSuccess = () => {
    const newAttachmentData = { ...updateConfigs, type: getAttachmentType(updateConfigs.mimetype) };
    setAttachments([newAttachmentData]);
    props.onUpdateAttachments([newAttachmentData]);
    setUploading(false);
  };

  const handleCancelUploadingFile = () => {
    setUploading(false);
    setFile(null);
    setUploadConfigs(null);
  };

  const shouldRenderMediaUpload = () => {
    return (file !== null && uploadConfigs !== null) || addLink || attachment;
  };

  const toggleAddLink = () => {
    setAddLink(!addLink);
  };

  const handleCloseLink = () => {
    toggleAddLink();
    clearAttachments();
    props.onUpdateAttachments([]);
    props.endUploadAttachment(); // always allow save when close link attachment
  };

  const clearAttachments = () => {
    setAttachments(null);
  };

  const handleDrop = files => {
    if (files.length > 1) {
      return toast.error('Please upload one file at a time');
    }
    handleDroppedFile(files[0]);
  };

  const handleDroppedFile = file => {
    const { name, size } = file;
    const fileExtension = name.split('.').pop().toLowerCase();

    if (!supportedExtensions.includes(fileExtension)) {
      return toast.error('File type not supported.');
    } else {
      if (isVideoFile(file.type) && size > VALIDATIONS.VIDEO.maxSize) {
        return toast.error(VALIDATIONS.VIDEO.errorMessage);
      } else if (isImageFile(file.type) && size > VALIDATIONS.IMAGE.maxSize) {
        return toast.error(VALIDATIONS.IMAGE.errorMessage);
      } else if (isDocumentFile(file.type) && size > VALIDATIONS.DOCUMENT.maxSize) {
        return toast.error(VALIDATIONS.DOCUMENT.errorMessage);
      } else if (size > VALIDATIONS.AUDIO.maxSize) {
        return toast.error(VALIDATIONS.AUDIO.errorMessage);
      }
    }

    handleFileSelect(file);
  };

  const attachment = get(attachments, '[0]', null);

  const renderLinkAttach = () => {
    const link_obj = attachment
      ? {
          url: attachment.link,
          thumbnail_url: attachment.thumbnail_url,
          name: attachment.original_name,
        }
      : null;
    return (
      <LinkAttachment
        onClose={handleCloseLink}
        attachment={link_obj}
        onUpdateAttachmentData={handleLinkDataUpdate}
        updateUploadStatus={() => console.log('updateUpload status ran')}
        onStartFetchingMeta={props.startUploadAttachment}
        onEndFetchingMeta={props.endUploadAttachment}
      />
    );
  };

  const renderUploadFile = () => {
    return (
      <MediaUploadProgress
        withPresignedUrl
        file={file}
        apiConfigs={uploadConfigs}
        onCancel={handleCancelUploadingFile}
        onSuccess={handleUploadSuccess}
        preview={
          <S.AttachmentPreview
            isVideo={isVideoFile(file.type)}
            isAudio={isMp3File(file.type)}
            isDocument={isDocumentFile(file.type)}
            docIcon={detectFileIcon(file)}
          >
            <MediaPlayer
              url={localUrl}
              type={file.type}
              thumbnail={file.type.startsWith('image') ? localUrl : null}
              generatePreview
            />
          </S.AttachmentPreview>
        }
      />
    );
  };

  const renderUploadCompleted = () => {
    const isFLV = lowerCase(getExtension(attachment.original_name)) === 'flv';
    if (isFLV) attachment.mimetype = 'video/x-flv';
    const type = get(attachment, 'mimetype', '') || get(file, 'type', '');
    const isAudioFileAttach = isMp3File(type);
    const isVideoFileAttach = isVideoFile(type);
    const isDocFileAttach = isDocumentFile(type);

    const attachmentPreview = () => {
      switch (true) {
        case isAudioFileAttach:
          return (
            <S.AttachmentPreview isAudio>
              <MediaPlayer
                url={localUrl || attachment.url}
                type={type}
                thumbnail={attachment.thumbnail_url}
                generatePreview
              />
            </S.AttachmentPreview>
          );
        case isVideoFileAttach:
          return (
            <S.AttachmentPreview isVideo>
              <MediaPlayer
                url={localUrl || attachment.url}
                type={type}
                thumbnail={localUrl ? null : attachment.thumbnail_url}
                generatePreview
              />
            </S.AttachmentPreview>
          );
        case isDocFileAttach:
          return <S.AttachmentPreview isDocument docIcon={detectFileIcon(attachment)}></S.AttachmentPreview>;
        default:
          // TODO: should change attachment.type to file.type
          return (
            <S.AttachmentPreview>
              <MediaPlayer
                url={localUrl || attachment.url}
                type={type}
                thumbnail={localUrl || attachment.thumbnail_url || attachment.url}
                generatePreview
              />
            </S.AttachmentPreview>
          );
      }
    };

    return (
      <MediaUploadProgress
        file={{ ...attachment, name: attachment.original_name }}
        onCancel={handleRemoveUploadedAttachment}
        viewMode
        preview={attachmentPreview()}
        viewOnly={get(props, 'viewOnly', false)}
      />
    );
  };

  const renderUploading = () => {
    return addLink ? renderLinkAttach() : isUploading ? renderUploadFile() : renderUploadCompleted();
  };

  const handleCancelUploadCoverImage = () => {
    setUploadingCoverImage(false);
  };

  const handleUploadCoverImageSuccess = res => {
    const newAttachmentData = { ...attachment, thumbnail_url: res.url, thumbnail_key: res.key };
    setAttachments([newAttachmentData]);
    props.onUpdateAttachments([newAttachmentData]);
    setUploadingCoverImage(false);
  };

  const handleStartUploadCoverImage = () => {
    setUploadingCoverImage(true);
  };

  const handleRemoveCoverImage = () => {
    const newAttachmentData = omit(attachment, ['thumbnail_url', 'thumbnail_key']);
    setAttachments([newAttachmentData]);
    props.onUpdateAttachments([newAttachmentData]);
  };

  const handleLinkDataUpdate = newLinkData => {
    if (newLinkData) {
      const { name, thumbnail_url, url } = newLinkData[0];
      const newLinkObj = {
        original_name: name,
        link: url,
        thumbnail_url: thumbnail_url,
        type: 5,
      };
      if (!validateLink(url)) {
        // block save button when link validate fail
        props.startUploadAttachment();
      } else {
        props.endUploadAttachment();
      }
      setAttachments([newLinkObj]);
      props.onUpdateAttachments([newLinkObj]);
    } else {
      setAttachments(null);
      props.onUpdateAttachments([]);
    }
  };

  return (
    <Dropzone onDrop={handleDrop}>
      {({ getRootProps, isDragActive }) => {
        return (
          <div>
            <S.Wrapper
              {...getRootProps()}
              isDragging={isDragActive}
              isDragMode={!!props.draggingItemLeft}
              noMargin={props.noMargin}
              noBottomMargin={get(props, 'noBottomMargin', false)}
              file={file}
              shouldRenderMediaUpload={shouldRenderMediaUpload()}
            >
              {(!file || !uploadConfigs) && addLink === false && !attachment ? (
                <>
                  <S.WrapperItem>
                    <img
                      src={isDragActive ? DragImage : DefaultImage}
                      className="drag-drop-background"
                      alt="Drag Exercise"
                    />
                  </S.WrapperItem>

                  <S.WrapperItem>
                    <S.WrapperItemText>
                      Add a video, audio file, <br />
                      <Popup
                        trigger={<S.WrapperItemTextHightLight>Choose a file</S.WrapperItemTextHightLight>}
                        on="click"
                        position="bottom center"
                        open={open}
                        onOpen={handleTogglePopup}
                        onClose={handleTogglePopup}
                        style={{ zIndex: 2001, transform: 'translateY(-5px)' }}
                      >
                        <PopupContent />
                      </Popup>
                      , or{' '}
                      <S.WrapperItemTextHightLight onClick={toggleAddLink}>Add video link</S.WrapperItemTextHightLight>
                    </S.WrapperItemText>
                  </S.WrapperItem>
                </>
              ) : (
                renderUploading()
              )}
            </S.Wrapper>
          </div>
        );
      }}
    </Dropzone>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    draggingItemLeft: state.rootReducer.workoutBuilder.getIn(['draggingItemLeft']),
  };
};

const mapDispatch = dispatch => ({
  onDragSectionLeftPanelEnd: bindActionCreators(onDragSectionLeftPanelEnd, dispatch),
  startUploadAttachment: bindActionCreators(startUploadAttachment, dispatch),
  endUploadAttachment: bindActionCreators(endUploadAttachment, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(UploadAndDragSection);
