import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { Loader } from 'semantic-ui-react';

import Post from '../Post';
import { Wrapper, Section, IndicatorWrapper, IndicatorPlaceHolder } from './style';
import { toggleModal } from 'actions/modal';
import PostDetail from '../PostDetail';

function DiscussionFeed(props) {
  const { posts, match, push, toggleModal, postsLoading } = props;
  const forumId = match.params.id || match.params.forumId || '';
  const [openingDropdown, setOpeningDropdown] = useState(null);

  const pinPosts = useMemo(() => {
    const filteredPosts = posts.filter(it => it.isPin);
    const combinedPosts = filteredPosts.map(post => ({
      published_at: post.published_at || 0,
      created_at: post.created_at || 0,
      ...post,
    }));
    // If none of the filtered posts have a published_at, filter by createAt instead
    return filteredPosts.every(post => post.published_at == null)
      ? filteredPosts.filter(it => it.createdAt != null)
      : orderBy(combinedPosts, ['published_at', 'createdAt'], ['desc', 'desc']);
  }, [posts]);

  const defaultPosts = useMemo(() => {
    const filteredPosts = posts.filter(it => !it.isPin);
    const combinedPosts = filteredPosts.map(post => ({
      published_at: post.published_at || 0,
      created_at: post.created_at || 0,
      ...post,
    }));
    // If none of the filtered posts have a published_at, filter by createAt instead
    return filteredPosts.every(post => post.published_at === null)
      ? filteredPosts.filter(it => it.createdAt !== null)
      : orderBy(combinedPosts, ['published_at', 'createdAt'], ['desc', 'desc']);
  }, [posts]);

  return (
    <Wrapper className="list-comment-forum">
      {pinPosts.length > 0 && (
        <>
          <Section>Pinned Posts</Section>
          {pinPosts.map(item => {
            return (
              <Post
                {...props}
                key={item._id}
                id={item._id}
                data={item}
                root={`/home/forums/${forumId}/post-detail/${item._id}`}
                onMediaClick={({ mediaIndex }, mediaType, multiple) => {
                  if (mediaType !== 'video' || multiple) {
                    push(`/home/forums/${forumId}/post-detail/${item._id}?startAt=${mediaIndex}`);
                    toggleModal(true, <PostDetail mediaIndex={mediaIndex} {...props} postId={item._id} />);
                  }
                }}
                onCommentClick={() => {
                  push(`/home/forums/${forumId}/post-detail/${item._id}`);
                  toggleModal(true, <PostDetail {...props} postId={item._id} />);
                }}
                openingDropdown={openingDropdown}
                onToggleDropdown={setOpeningDropdown}
              />
            );
          })}
        </>
      )}
      {defaultPosts.length > 0 && (
        <>
          <Section>New Posts</Section>
          {defaultPosts.map(item => {
            return (
              <Post
                {...props}
                key={item._id}
                id={item._id}
                data={item}
                root={`/home/forums/${forumId}/post-detail/${item._id}`}
                onMediaClick={({ mediaIndex }, mediaType, multiple) => {
                  if (mediaType !== 'video' || multiple) {
                    push(`/home/forums/${forumId}/post-detail/${item._id}?startAt=${mediaIndex}`);
                    toggleModal(true, <PostDetail mediaIndex={mediaIndex} {...props} postId={item._id} />);
                  }
                }}
                onCommentClick={() => {
                  push(`/home/forums/${forumId}/post-detail/${item._id}`);
                  toggleModal(true, <PostDetail {...props} postId={item._id} />);
                }}
                openingDropdown={openingDropdown}
                onToggleDropdown={setOpeningDropdown}
              />
            );
          })}
        </>
      )}
      {postsLoading ? (
        <IndicatorWrapper>
          <Loader active inline="centered" className="custom-indicator">
            <span>Loading posts</span>
          </Loader>
        </IndicatorWrapper>
      ) : (
        <IndicatorPlaceHolder />
      )}
    </Wrapper>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(null, mapDispatchToProps)(DiscussionFeed);
