/**
 * @flow
 */

module.exports = {
  root: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  input: {
    marginTop: 4,
    martinBottom: 4,
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },

  button: {
    display: 'flex',
    width: '50%',
  },

  checkbox: {
    marginTop: 8,
    marginBottom: 8,
  },
};
