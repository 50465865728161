import React from 'react';

import * as S from '../style';

const SignatureForm = () => {
  return (
    <S.FormType isSignature>
      <S.SignatureBox>
        <S.SignaturePlaceholder>Your Client's Signature</S.SignaturePlaceholder>
      </S.SignatureBox>
      <S.SignatureSign>Your Client's Full Name</S.SignatureSign>
    </S.FormType>
  );
};

export default SignatureForm;
