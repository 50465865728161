import styled, { css } from 'styled-components';
import { Modal, Radio } from 'semantic-ui-react';
import SmallVideo from 'assets/icons/small_play_light.png';
import LoadingIndicatorEl from 'shared/LoadingIndicator';
import { CDN_URL } from 'constants/commonData';

const baseText = `   
  color: #222222;
  font-size: 13px;
  line-height: 18px;
`;

export const ChangeExerciseModalContainer = styled(Modal)`
  &.ui.modal {
    width: 1087px;
    height: 494px;
    transform: translateY(-14%) !important;
  }

  .header {
    border-radius: 8px;
    padding-top: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 15px !important;
    font-weight: 600 !important;
    position: relative;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      .change-exercise-search-input {
        height: 40px;
        width: 474px;

        input {
          background-position: 13px center;
          padding-right: 69px;
        }
      }

      :hover {
        input {
          background-color: #fff;
          border-color: #7072dd;
        }
      }

      svg {
        right: 47px;
      }
    }
    .change-exercise-filter-trigger {
      position: absolute;
    }
  }

  .content {
    padding: 0px !important;
    /* margin-top: 10px !important; */
    overflow: hidden;
    max-height: 586px;
    height: 100%;

    .custom-indicator.ui.loader::before {
      border-color: rgba(0, 0, 0, 0.1) !important;
    }
    .custom-indicator.ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }

  &.ui.modal > .header:not(.ui) {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.125px;
  }
  .actions {
    background: #ffffff !important;
    box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.08) !important;
    border-top: 0px !important;
    padding: 10px 30px !important;
    .cancel-btn {
      height: 32px;
      width: 92px;
      border-radius: 5px;
    }
    .refund-btn {
      height: 32px;
      width: 118px;
      margin-left: 5px;
      border-radius: 5px;
      :hover {
        background: #5e59ff;
      }
      :disabled:hover {
        background-color: #c4c4c4 !important;
      }
    }
  }
`;

export const WorkoutListContainer = styled.div``;

export const ExerciseOption = styled.div`
  padding: 7px;
  display: flex;

  .title {
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
    color: #202353;
  }
  .field-value {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #222222;
  }
`;

export const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 0px 25px 0px 30px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const TableHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: transparent;
  z-index: 2;
`;

export const CustomLabel = styled.div`
  position: absolute;
  background: #ebedf2;
  border-radius: 0px 5px 0px 0px;
  padding: 4px 12px;
  color: rgb(32, 35, 83, 0.5);
  top: 0px;
  right: 0px;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
`;

export const TableRow = styled.div`
  position: relative;
  ${props =>
    props.isHeader &&
    `
  background-color: #202353;
  border-radius: 3px;
  `}
  display: grid;

  grid-template-columns: 330px 140px 120px 133px 144px 160px 0px;
  align-items: center;
  top: -1px;

  .modality {
    ${baseText}
    padding-right: 5px;
    max-width: 118px;
  }
  .category {
    ${baseText}
  }
  .tags {
    ${baseText}
    max-width: 100px;
    .tag-name {
      cursor: pointer;
      margin-right: 5px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #5c58c0;

      :hover {
        text-decoration: underline;
      }

      ::after {
        content: ',';
      }

      :last-child::after {
        content: '';
      }
    }
  }
  .muscle-groups {
    ${baseText}
    padding-right: 40px;
  }
  .movement-pattern {
    ${baseText}
    padding-right: 25px;
  }
`;

export const TableBody = styled.div`
  padding-top: 8px;
  ${TableRow} {
    border: 1px solid #ebedf2;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px;
    :last-child {
      margin-bottom: 18px;
    }
    transition: border ease 50ms;
    :hover {
      border: 1px solid #5158cf;
    }
  }
`;

export const HeaderItem = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  ${'' /* transition: font-weight 100ms, font-size 100ms; */}
  color: #ffffff;
  ${props =>
    props.active &&
    css`
      ${'' /* font-size: 12px; */}
      font-weight: bold;
    `}
  ${'' /* color: ${props => (props.active ? '#FFFFFF' : '#FFFFFF')}; */}
  text-align: left;
  padding: 11px 0;
  &:first-child {
    padding-left: 18px;
  }
  .icon.chevron.down {
    color: #ffffff;
  }
`;

export const HeaderItemContainer = styled.div`
  display: flex;

  .click-container.hover {
    cursor: pointer;
  }
  .icon {
    margin-left: 5px;
    padding-top: 1px;
    color: #ffffff;
    ${'' /* color: ${props => (props.active ? '#a1a1a5' : '#a1a1a5')}; */}
    ${'' /* color: ${props => (props.active ? '#5158cf' : '#a1a1a5')}; */}
    font-size: 10px;
  }
`;

export const WorkoutInfo = styled.div`
  display: grid;
  grid-template-columns: 85% 15%;
  align-items: center;
  padding: 7px;
  border-radius: 5px;
`;

export const WorkoutInfoDetail = styled.div`
  display: flex;
  flex: 0 0 90px;
  align-items: center;
  .media-preview__trigger {
    background-color: #fafbfd;
  }

  .media-preview__popup {
    left: -70px !important;
  }
`;

// export const MediaWrapper = styled.div`
//   min-width: 68px;
//   height: 48px;
//   border-radius: 3px;
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   position: relative;
//   background-image: url(${Default});
//   ${props =>
//     props.thumbnail &&
//     css`
//       background-image: url(${props.thumbnail});
//     `}
// `;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  .evf-dropdown--basic {
    .evf-dropdown__menu {
      min-width: 141px;
    }
    .evf-dropdown__option {
      padding-left: 15px;
      padding-right: 15px;
      svg {
        width: 15px;
        margin-right: 10px;
        path,
        rect {
          stroke: white;
        }
      }
    }
  }

  .category-actions-dropdown {
    &.open {
      .trigger-icon {
        background: #eaeef1;
      }
    }

    .evf-dropdown__menu {
      background-color: transparent;
      transform: translateX(28px) translateY(-2px);

      &.last_item {
        .dropdown-arrow {
          display: none;
        }
      }
    }

    .dropdown-area {
      padding: 6px 0;
      background: #2d2e2d;
      border-radius: 5px;
    }

    .dropdown-arrow {
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      right: 36.5px;
      top: 2.5px;
      background: #2d2e2d;
      border-top: 1px solid #363636;
      border-left: 1px solid #363636;
      transform: rotateZ(45deg);
    }
  }
`;

export const ChipWrapper = styled.div`
  text-align: start;
  padding-right: 15px;
`;

export const ChipText = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #818da1;
  text-transform: uppercase;
  padding: 5px 8px;
  background: #f1f1f1;
  border-radius: 3px;
  margin-left: 2px;
  margin-bottom: 2px;
  display: inline-block;
`;

export const Desc = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: #202353;
  padding: 0 10px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const MediaWrapper = styled.div`
  display: flex;
  flex: 0 0 90px;
  justify-content: center;
  align-items: center;
  background-color: #fafbfd;
  width: 90px;
  height: 56px;
  border-radius: 3px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  ${props =>
    props.thumbnail &&
    css`
      background-image: url(${props.thumbnail});
    `}
`;
export const VideoIcon = styled.div`
  background-image: url(${SmallVideo});
  width: 14px;
  height: 14px;
  position: absolute;
  left: 5px;
  bottom: 5px;
  background-size: contain;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const RadioButton = styled(Radio)`
  margin-right: 16px;
  label {
    ::before {
      border-width: ${props => (props.checked ? '2px' : '1px')} !important;
      width: 16px !important;
      height: 16px !important;
      transition: none !important;
    }
    ::after {
      width: 8px !important;
      height: 8px !important;
      transform: none !important;
      top: 5px !important;
      left: 4px !important;
      transition: none !important;
    }
  }
`;

export const FilterButton = styled.div`
  padding: 5px;
  background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon.svg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #a6acc6;
  cursor: pointer;
  width: 28px;
  height: 28px;

  :hover {
    background-image: url(${CDN_URL}/images/workout_builder_filter_exercise_icon_hover.svg);
    background-color: #eaebff;
    border-radius: 3px;
  }
`;

export const FilterTriggerContainer = styled.div`
  position: absolute;
  right: 30px;
  .exercise-filter__menu {
    top: 59px !important;
    left: 630px !important;
  }
`;

export const LoadingIndicator = styled(LoadingIndicatorEl)`
  ${props =>
    props.isLoadingMore
      ? css`
          padding-top: 20px;
          margin-bottom: 60px;
        `
      : css`
          padding-top: 25%;
          margin-bottom: 25%;
        `}
`;

export const NoResultText = styled.div`
  display: flex;
  height: 268px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 22px;
  font-weight: 400;
  line-height: 18px;
  color: rgb(154, 157, 166);
`;
