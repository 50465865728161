import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Button as CloseButton, Image } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';
// import actions
import { toggleModal, toggleConfirmModal } from 'actions/modal';

import { CDN_URL } from 'constants/commonData';

// import assets
import discardImage from 'assets/images/discard-icon.png';
import { AddNoteModalStyles, HeaderModalStyles, ContentModalStyles, ActionsModalStyles } from './styles';

const AddNoteModal = ({ onConfirm, dispatch }) => {
  const onClose = () => {
    dispatch(toggleConfirmModal(false));
  };

  const onOk = () => {
    dispatch(toggleConfirmModal(false));
    if (onConfirm) onConfirm();
  };

  return (
    <AddNoteModalStyles
      size={'tiny'}
      open={true}
      onClose={onClose}
      className="client-macro-note-discard-change"
      closeOnDimmerClick
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
    >
      <HeaderModalStyles>
        <img src={discardImage} className="title-image" alt="" />
        <div className="title">Discard Changes?</div>
      </HeaderModalStyles>
      <ContentModalStyles>
        <div className="description">Are you sure you want to go? Changes have not been saved yet.</div>
      </ContentModalStyles>
      <ActionsModalStyles>
        <Button onClick={onClose}>Cancel</Button>
        <Button className="confirm-yes-button" onClick={onOk}>
          Ok
        </Button>
      </ActionsModalStyles>
    </AddNoteModalStyles>
  );
};

export default connect()(AddNoteModal);
