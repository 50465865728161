'use strict';

import Request from 'configs/request';

export const getUnitCategories = () => {
  return dispatch => {
    dispatch({ type: 'IS_GETTING_UNIT_CATEGORIES' });

    return dispatch(
      Request.get(
        { url: '/api/unit/category/list' },
        false,
        result => {
          const list = result.data.data;
          dispatch({ type: 'SUCCESS_GET_UNIT_CATEGORIES', payload: { unit_categories: list } });
        },
        error => dispatch({ type: 'ERROR_GET_UNIT_CATEGORIES', error })
      )
    );
  };
};
