import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
  color: #202353;
`;

export const MarcoGoalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  padding: 10px 0 5px;
  border-radius: 10px;
  box-shadow: 0px 2px 30px 0px rgba(172, 172, 172, 0.2);

  ${({ isMarco }) =>
    isMarco &&
    css`
      padding: 7px 0 15px;
    `}
`;

export const MacroHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 0 15px;
`;

export const MacroTitle = styled.div`
  flex: 1;

  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #7b7e91;
  text-transform: uppercase;
`;

export const ButtonAction = styled.button`
  display: flex;
  height: 30px;
  padding: 0px 5px 0 10px;
  justify-content: center;
  align-items: center;
  gap: 3.5px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: transparent;
  background-color: #fff;

  span {
    ${baseText}
    color: #7b7e91;
    font-size: 11px;
    line-height: 17px;
    font-weight: 600;
  }

  .arrow-icon path {
    fill: #7b7e91;
  }

  &:hover {
    cursor: pointer;
    background-color: #f0f1ff;
    span {
      color: #5158cf;
    }
    .arrow-icon path {
      fill: #5158cf;
    }
  }
`;

export const SeparateLine = styled.div`
  height: 1px;
  margin: 4px 15px;
  background-color: #f0f0f2;

  ${({ isMarco }) =>
    isMarco &&
    css`
      margin: 4px 15px 15px;
    `}
`;

export const MarcoGoalContainer = styled.div`
  .loading-marco-nutrient {
    padding: 35px 0;
  }
`;

// Nutrition View

export const NutritionWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 11px 0;
  background-color: #fff;
`;

export const NutritionItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3px;
  flex: 1;
  width: 25%;
`;

export const NutritionValues = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 3px;

  span {
    ${baseText}
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span.is-empty {
    font-weight: 400;
  }
`;

export const NutritionUnit = styled.div`
  flex: 1;
  ${baseText}
  font-size: 12px;
  font-weight: 600;
`;

export const NutritionMetric = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
`;

// Marco View

export const MacroWrapper = styled.div`
  display: flex;
  padding: 0 25px 0 30px;
  align-items: center;
  gap: 35px;
  background: #fff;
`;

export const ProgressWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

export const ProgressItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const ProgressValues = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
`;

export const NutritionValue = styled.div`
  ${baseText}
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;

  ${({ isExceeding }) =>
    isExceeding &&
    css`
      color: #ea314a;
    `}

  ${({ isEmptyValue }) =>
    isEmptyValue &&
    css`
      font-weight: 400;
    `}
`;

export const MarcoValue = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 600;
`;

export const ProgressUnit = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 600;
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 6px;
  background-color: #f0f0f2;
  border-radius: 3px;
`;

export const ProgressBar = styled.div`
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ bgColor }) => `${bgColor}`};
  border-radius: inherit;
  text-align: right;
  /* transition: width 1s ease-in-out; */
`;

export const ProgressMetric = styled.div`
  ${baseText}
  color: #202353;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
`;

export const MarcoChartWrapper = styled.div`
  position: relative;
`;

export const MarcoChartValues = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MarcoChartValue = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
`;

export const MarcoChartMetric = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
`;
