import React, { Fragment } from 'react';

import useTimeRemaining from 'hooks/useTimeRemaining';
import { pluralize } from 'utils/commonFunction';
import * as S from './style';

const TimeCounting = ({ datetime, className = '' }) => {
  const timeRemaining = useTimeRemaining(datetime, true);
  const { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0 } = timeRemaining || {};

  const renderCountItem = (num, label, showColons = false, colonBefore = false) => (
    <Fragment key={label}>
      <S.TimeNumber className="time-number-label">
        <div className="time-number">{num}</div>
        <div className="time-label">{pluralize(label, num)}</div>
      </S.TimeNumber>
    </Fragment>
  );

  const renderCountColon = key => (
    <S.Colons className="colons" key={key}>
      :
    </S.Colons>
  );

  const renderTimeCountDown = () => {
    const isShowSeconds = years === 0 && months === 0 && days === 0;
    const isShowMinutes = years === 0 && months === 0;
    const isShowHour = !years > 0;
    const isShowDay = (years === 0 && months === 0 && days > 0) || years > 0 || months > 0;
    const isShowMonth = (years === 0 && months > 0) || years > 0;
    const items = [];

    if (years > 0) {
      items.push(renderCountItem(years, 'year'));
      items.push(
        <S.Colons className="colons" key="colon-1">
          :
        </S.Colons>,
      );
    }
    if (isShowMonth) {
      items.push(renderCountItem(months, 'month'));
      items.push(
        <S.Colons className="colons" key="colon-2">
          :
        </S.Colons>,
      );
    }

    if (isShowDay) {
      items.push(renderCountItem(days, 'day'));
      if (items.length < 5) {
        items.push(
          <S.Colons className="colons" key="colon-3">
            :
          </S.Colons>,
        );
      }
    }

    if (isShowHour) {
      items.push(renderCountItem(hours, 'hour'));
      if (items.length < 5) {
        items.push(
          <S.Colons className="colons" key="colon-4">
            :
          </S.Colons>,
        );
      }
    }

    if (isShowMinutes) {
      items.push(renderCountItem(minutes, 'minute'));
      if (items.length < 5) {
        items.push(
          <S.Colons className="colons" key="colon-5">
            :
          </S.Colons>,
        );
      }
    }

    if (isShowSeconds) {
      items.push(renderCountItem(seconds, 'second'));
    }

    return items;
  };

  const renderNewTimeCountDown = () => {
    const isShowMonth = years > 0;
    const items = [];

    if (years > 0) {
      items.push(renderCountItem(years, 'year'));
      items.push(renderCountColon(1));
    }
    if (isShowMonth) {
      items.push(renderCountItem(months, 'month'));
      items.push(renderCountColon(2));
    }

    items.push(renderCountItem(!isShowMonth ? days + months * 30 : days, 'day'));
    if (items.length < 5) {
      items.push(renderCountColon(3));
    }

    if (years === 0) {
      items.push(renderCountItem(hours, 'hour'));
      if (items.length < 5) {
        items.push(renderCountColon(4));
      }
    }

    if (years === 0) {
      items.push(renderCountItem(minutes, 'minute'));
    }

    return items;
  };

  return <S.TimeCountdownWrapper className={className}>{renderNewTimeCountDown()}</S.TimeCountdownWrapper>;
};

export default TimeCounting;
