// Libs
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

// Actions
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { duplicateStudioProgram, removeStudioProgram, updateShareStatus } from 'redux/studio-program/actions';

// Shared
import DropDown, { Option } from 'shared/Dropdown/Basic';
import ConfirmModal from 'shared/ConfirmModal';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { ShareSettingModal } from 'shared/AssetsShareSetting';

// Components
import StudioConfirmModals from 'components/StudioProgram/Detail/Calendar/ConfirmModal';
import CopyToProgramLibraryModal from '../CopyToProgramLibraryModal';

// Utils
import { checkAssetAssignedToProduct } from 'utils/commonFunction';
import { ASSET_TYPE } from 'components/Product/constants';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';

// Styles
import * as S from './style';
import { SharedTooltip } from 'shared/SharedTooltip';

function DropdownOptions(props) {
  const {
    studioProgram,
    payment,
    isCreatorOrOwnerOrAdmin = false,
    toggleModal,
    user,
    updateShareStatus,
    onboarding_flow,
  } = props;
  const studioProgramId = studioProgram._id;
  const author = typeof studioProgram.author_info === 'object' ? studioProgram.author_info : undefined;

  const onRemoveClick = async e => {
    const isAssignedPayment = payment ? await checkAssetAssignedToProduct(ASSET_TYPE.STUDIO, studioProgram._id) : false;
    const isAssignedOnboarding = onboarding_flow
      ? await checkAssetAssignedToProduct(ASSET_TYPE.STUDIO_PROGRAM, studioProgram._id, true)
      : false;

    if (payment && isAssignedPayment) {
      props.toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          assetType={ASSET_TYPE.STUDIO}
          onConfirm={() => {
            props.toggleConfirmModal(
              true,
              <ConfirmModal
                headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
                newStyle
                confirmButtonTitle="Remove Program"
                title="Remove Program"
                content="Removing this Studio program will remove any workouts from this program on your client’s training calendar, and your client will lose access to the program."
                onConfirm={() => props.removeStudioProgram(studioProgram)}
              />,
            );
          }}
        />,
      );
    } else if (onboarding_flow && isAssignedOnboarding) {
      props.toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          isExtraLarge
          isCloseAfterConfirm
          title="Studio Program is assigned to an Onboarding Flow"
          content="This studio program is in at least 1 onboarding flow. If the studio program is removed, it will be removed from all onboarding flows as well."
          assetType={ASSET_TYPE.STUDIO}
          onConfirm={() => props.removeStudioProgram(studioProgram)}
        />,
      );
    } else {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          newStyle
          confirmButtonTitle="Remove Program"
          title="Remove Program"
          content="Removing this Studio program will remove any workouts from this program on your client’s training calendar, and your client will lose access to the program."
          onConfirm={() => props.removeStudioProgram(studioProgram)}
        />,
      );
    }
  };

  const onDuplicate = () => {
    props.duplicateStudioProgram(studioProgram);
  };

  const onEdit = () => {
    if (studioProgram.isPublished && !studioProgram.isEditMode) {
      props.toggleModal(
        true,
        <StudioConfirmModals.ConfirmTurnOnEditModeModal
          programId={studioProgram._id}
          onClose={() => props.toggleModal(false)}
        />,
      );
    }
    props.push(`/home/studio-programs/${studioProgram._id}/calendar`);
  };

  const onCopyToLibrary = () => {
    props.toggleModal(true, <CopyToProgramLibraryModal studioProgram={props.studioProgram} />);
  };

  const handleSaveShare = ({ params } = {}) => {
    typeof toggleModal === 'function' && toggleModal(false);
    if (!params || !studioProgramId) return;
    typeof updateShareStatus === 'function' && updateShareStatus({ params, studioProgramId });
  };

  const onShare = () => {
    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <ShareSettingModal
          user={user}
          owner={author}
          shareStatus={studioProgram.share}
          closeModal={onCloseModal}
          onSave={handleSaveShare}
        />,
      );
  };

  const onCloseModal = () => {
    typeof toggleModal === 'function' && toggleModal(false);
  };

  return (
    <S.DropdownWrapper className={props.className}>
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="studio-program-item-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="studio-program-item-options-tooltip" />
          </div>
        )}
      >
        <Option key="copy" onClick={onCopyToLibrary}>
          <img className="icon" src={`${CDN_URL}/images/copy_to_library_icon.svg`} alt="" />
          <span>Save as Program</span>
        </Option>
        <Option key="clone" onClick={onDuplicate}>
          <img className="icon" src={`${CDN_URL}/images/clone.svg`} alt="" />
          <span>Duplicate</span>
        </Option>
        {isCreatorOrOwnerOrAdmin && (
          <Option key="edit" onClick={onEdit}>
            <img className="icon edit" src={`${CDN_URL}/images/edit-white.svg`} alt="" />
            <span>Edit</span>
          </Option>
        )}
        {isCreatorOrOwnerOrAdmin && (
          <Option key="share" onClick={onShare}>
            <ShareIcon className="icon share" />
            <span className="text">Sharing settings</span>
          </Option>
        )}
        {isCreatorOrOwnerOrAdmin && (
          <Option key="delete" onClick={onRemoveClick}>
            <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
            <span>Remove</span>
          </Option>
        )}
      </DropDown>
    </S.DropdownWrapper>
  );
}

const mapStateToProps = state => {
  const { user, rootReducer } = state;
  const { permission } = rootReducer || {};
  const { payment, onboarding_flow } = permission || {};

  return { user, payment, onboarding_flow };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  duplicateStudioProgram: bindActionCreators(duplicateStudioProgram, dispatch),
  removeStudioProgram: bindActionCreators(removeStudioProgram, dispatch),
  updateShareStatus: bindActionCreators(updateShareStatus, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownOptions);
