import React from 'react';
import { DateTime } from 'luxon';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { checkSameMonth } from 'utils/commonFunction';
import * as S from '../style';

function AlterWeekSelected(props) {
  const { selectedWeek, permission, handleResetSelectedWeek } = props;
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V2 || !get(permission, 'copy_week')) return <></>;
  if (isEmpty(selectedWeek)) return <></>;
  const isSameMonth = checkSameMonth(
    DateTime.fromISO(get(selectedWeek, 'from')),
    DateTime.fromISO(get(selectedWeek, 'to')),
  );
  const fromDate = DateTime.fromISO(get(selectedWeek, 'from')).toFormat('LLL d');
  const toDate = isSameMonth
    ? DateTime.fromISO(get(selectedWeek, 'to')).toFormat('d')
    : DateTime.fromISO(get(selectedWeek, 'to')).toFormat('LLL d');
  return (
    <S.BottomCopyWeekWrapper>
      <div>
        Week of&nbsp;
        <strong>
          {fromDate} - {toDate}
        </strong>
        &nbsp;copied
      </div>
      <div>
        <strong>Esc</strong>&nbsp;to cancel
        <CloseIcon onClick={handleResetSelectedWeek} className="cancel-copy" />
      </div>
    </S.BottomCopyWeekWrapper>
  );
}

export default AlterWeekSelected;
