import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const CustomModal = styled(Modal)`
  &.ui.modal {
    width: 500px;
    height: 100%;
    min-height: 391px;
    padding: unset;
    border-radius: 8px;
    transition: all 0.5s ease;

    &.state-1 {
      max-height: 391px;

      .trigger-scroll-wrapper {
        padding-right: 28px;
      }
    }

    &.state-2 {
      max-height: 712px;
    }

    &.state-empty {
      max-height: 350px;
      min-height: 350px;
    }

    &.state-unavailable {
      max-height: 240px;
      min-height: 240px;
    }

    &.visible.transition {
      display: flex !important;
      flex-direction: column;
    }

    .close-button {
      top: -10px;
      right: -10px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .header {
      padding: 24px 32px 12px !important;
      background: unset !important;
    }

    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex: 1;
      padding: unset !important;
      background: unset !important;
    }

    .actions {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      background: unset !important;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 32px;
      gap: 8px;

      .right-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        gap: 8px;
      }
    }

    > :last-child {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }

  .header-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .header-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #202353;
  }

  .trigger-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .trigger-scroll-wrapper {
    overflow-y: auto;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
    margin-right: 4px;
    padding: 8px 24px 24px 32px;

    ::-webkit-scrollbar {
      width: 4px !important;
    }
    ::-webkit-scrollbar-thumb {
      background: #d9d9d9 !important;
    }
  }
`;

export const ButtonCancel = styled.button`
  margin: unset;
  cursor: pointer;

  min-width: 112px;
  box-sizing: border-box;
  padding: 9px 34px;
  background: #ffffff;
  border: 1px solid #c3c5cb;
  border-radius: 4px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #151619;

  &:hover {
    background-color: #f3f3f3;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonSubmit = styled.button`
  border: unset;
  margin: unset;
  cursor: pointer;

  padding: 9px 31px;
  box-sizing: border-box;
  min-width: 112px;
  background: #5158cf;
  border-radius: 4px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #ffffff;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    color: #ffffff;
    background: #d3d3d3;
    cursor: not-allowed;
  }
`;

export const ButtonRemoveTrigger = styled.button`
  border: unset;
  background: unset;
  padding: unset;
  margin: unset;
  cursor: pointer;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  color: #d51e1e;

  &:hover {
    color: #e95b5b;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const EmptyWrapper = styled.div`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #323c47;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  padding-top: 20px;
  padding-right: 4px;
`;
