import styled from 'styled-components';

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  margin: 0;
  margin-bottom: 3px;
`;

export const Trigger = styled.button`
  outline: none !important;
  background-color: #7072dd;
  border-radius: 5px;
  padding: 11px 24px;
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
`;

export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
`;

export const OptionIcon = styled.div`
  margin: 0 auto 30px;
  width: 60px;
  height: 60px;
  border: 1px solid #A5A7AE;
  box-sizing: border-box;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-size: center;
  background-position: center;
  background-repeat: no-repeat;

  &.hover {
    display: none;
  }
`;

export const OptionLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #202353;
  margin-bottom: 10px;
`;

export const Option = styled.div`
  display: inline-block;
  width: 200px;
  height: 180px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 38px 25px 30px;
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #979797;
  margin: 0 13px 30px;
  cursor: pointer;

  :hover {
    background: #fbfbff;
    border-color: #5158cf;

    ${OptionIcon} {
      display: none;

      &.hover {
        display: inline-block;
        background-color: #ECF0FF;
        border: 1px solid #5C5BBD;
      }
    }
  }
`;

export const OptionContainer = styled.div`
  padding: 0 17px;
`;
