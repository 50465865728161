// Lib
import React from 'react';

// Style
import * as S from './style';

const ItemSkeleton = ({ isRecipe }) => {
  return (
    <S.Wrapper isRecipe={isRecipe}>
      <div className="thumbnail"></div>
      <div className="content">
        <div className="long-name"></div>
        <div className="short-name"></div>
      </div>
    </S.Wrapper>
  );
};

export default ItemSkeleton;
