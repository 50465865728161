import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  copyAssignment,
  copyAssignmentToDate,
  removeAssignmentFromDate,
  resetCopyAssignment,
} from 'actions/workout/getWorkouts';
import { arrangeAssignment } from 'redux/assignment/assignment.actionCreators';
import CalendarCellComponent from 'components/CalendarCell/component';
import { saveAssignmentToLibrary, updateExerciseLibFromCalendar } from 'actions/workout/save';
import { ONBOARDING_STEPS } from 'constants/commonData';
import { hideTooltip } from 'redux/onboarding/actions';
import { getPdf } from 'redux/workout-builder/actions';
import {
  getPdfRequest,
  getPdfFinish,
  handleSelectWorkout,
  handlePasteMultipleWorkout,
  handleResetSelectedWeek,
  handleResetSelectWorkout,
  hideWorkout,
} from 'redux/calendar/calendar.actionCreators';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = (state, ownProps) => {
  const {
    allExercises,
    workouts,
    calendarType,
    isModalOpen,
    modal,
    copyingAssignment,
    popup,
    movingSet,
    rootReducer,
    router,
    user,
  } = state;

  const {
    onboarding: { running, showTooltip },
    calendar: { selectedWorkout, selectedWeek, isCopySelectedWorkout, excludeWorkout },
    permission,
  } = rootReducer;
  const { workingClientDetail } = rootReducer.client;
  const currentWorkouts = workouts.get(ownProps.day.toFormat('MM-dd-yyyy'));
  const dragingAssignmentId = workouts.get('dragingAssignmentId');

  return {
    allExercises,
    currentWorkouts,
    calendarType,
    isModalOpen,
    modal,
    copyingAssignment,
    selectedClient: _.get(workingClientDetail, '_id', ''),
    popup,
    movingSet,
    timezone: _.get(workingClientDetail, 'timezone', ''),
    showAssignWorkoutGuide: running === ONBOARDING_STEPS.ASSIGN_WORKOUT && showTooltip,
    showAssignProgramGuide: running === ONBOARDING_STEPS.ASSIGN_PROGRAM && showTooltip,
    dragingAssignmentId,
    workingClientDetail,
    location: router.location,
    selectedWorkout,
    selectedWeek,
    excludeWorkout,
    isCopySelectedWorkout,
    permission,
    hasSelectedWorkout: !_.isEmpty(selectedWorkout),
    trainerTimeZone: _.get(user, 'timezone', ''),
  };
};

const removeCurrentAssignment = (dispatch, params) => {
  dispatch(removeAssignmentFromDate(params));
};

const arrangeAssignments = (dispatch, params) => {
  dispatch(arrangeAssignment(params));
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    arrangeAssignments: params => arrangeAssignments(dispatch, params),
    copyCurrentAssignment: bindActionCreators(copyAssignment, dispatch),
    pasteCopiedAssignment: bindActionCreators(copyAssignmentToDate, dispatch),
    removeCurrentAssignment: params => removeCurrentAssignment(dispatch, params),
    saveCurrentAssignmentToLibrary: workout => {
      let params = {
        assignment: workout._id,
      };
      dispatch(saveAssignmentToLibrary(params));
    },
    updateExerciseLibFromCalendar: bindActionCreators(updateExerciseLibFromCalendar, dispatch),
    hideTooltip: bindActionCreators(hideTooltip, dispatch),
    getPdf: bindActionCreators(getPdf, dispatch),
    getPdfRequest: bindActionCreators(getPdfRequest, dispatch),
    getPdfFinish: bindActionCreators(getPdfFinish, dispatch),
    handleSelectWorkout: bindActionCreators(handleSelectWorkout, dispatch),
    handlePasteMultipleWorkout: bindActionCreators(handlePasteMultipleWorkout, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    handleResetSelectedWeek: bindActionCreators(handleResetSelectedWeek, dispatch),
    handleResetSelectWorkout: bindActionCreators(handleResetSelectWorkout, dispatch),
    resetCopyAssignment: bindActionCreators(resetCopyAssignment, dispatch),
    hideWorkout: bindActionCreators(hideWorkout, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCellComponent);
