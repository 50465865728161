import { PAGE_SIZE } from 'components/CoachBios/CoachBioList/constants';
import { Types } from './actions';

const INIT_STATE = {
  list: [],
  total: 0,
  loading: false,
  query: {
    page: 1,
    q: '',
    pageSize: PAGE_SIZE,
  },
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_COACH_BIO_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.GET_COACH_BIO_SUCCESS: {
      return Object.assign({}, state, {
        list: payload.list,
        total: payload.total,
        loading: false,
        query: {
          ...payload.queryParams,
        },
      });
    }

    case Types.COACH_BIO_CHANGE_QUERY_PARAMS: {
      return Object.assign({}, state, {
        loading: false,
        query: {
          ...payload.data,
        },
      });
    }

    case Types.GET_COACH_BIO_FAIL: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    default:
      return state;
  }
};
