import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import countBy from 'lodash/countBy';

import * as Layout from 'shared/LibraryLayout';
import ExerciseDetailModal from 'components/ExerciseDetailModal';
import TableHeader from './Parts/TableHeader';
import TableBody from './Parts/TableBody';
import Toolbar from './Parts/Toolbar';
import Footer from './Parts/Footer';
import { Button } from 'shared/FormControl';
import { MODES } from 'components/ExerciseForm/component';
import { saveLastLibraryRoute } from 'utils/commonFunction';

import * as S from './style';

function View(props) {
  const [countTagsSelected, setCountTagsSelected] = useState([]);
  const [exercisesSelected, setExercisesSelected] = useState([]);
  const { match, mode, loading, listExercise, selectedExercise } = props;
  const {
    params: { exerciseId },
  } = match;

  useEffect(() => {
    // Reset Selected Exercise before move another page
    return () => {
      props.resetSelectedExercise();
    };
  }, []);

  useEffect(() => {
    props.toggleSideBar(true);
    saveLastLibraryRoute('/home/exercise');
  }, []);

  useEffect(() => {
    if (exerciseId) {
      props.getExerciseDetail(exerciseId).then(response => {
        const { data } = response.data;
        let popupMode = MODES.UPDATE;

        if (mode) {
          popupMode = mode;
        }

        props.toggleModal(true, <ExerciseDetailModal mode={popupMode} originExercise={data} />);
      });
    }
  }, [exerciseId, mode]);

  const onCreateNew = () => {
    props.toggleModal(true, <ExerciseDetailModal mode={MODES.CREATE} />);
  };

  const handleCountTagBySelected = exercises => {
    let allCurrentExercise = get(props, 'listExercise', []);
    let allCurrentTags = [];
    let allTagsExerciseList = [];

    forEach(exercises, item => {
      allCurrentTags = allCurrentTags.concat(item.tags || []);
    });

    forEach(allCurrentExercise, item => {
      allTagsExerciseList = allTagsExerciseList.concat(item.tags || []);
    });

    const countCurrentExercise = countBy(allCurrentTags, '_id');
    const countAllExerciseList = countBy(allTagsExerciseList, '_id');
    let result = [];

    forEach(countCurrentExercise, (total, tagsId) => {
      result[tagsId] = {
        total,
        state: countAllExerciseList[tagsId] === allTagsExerciseList[tagsId] || total === exercises.length ? 2 : 1,
      };
      result[tagsId].originState = result[tagsId].state;
    });

    //  item state:
    // 0: unchecked
    // 1: half-checked
    // 2: full-checked
    setCountTagsSelected(result);
    setExercisesSelected(exercises);
  };

  // Handle clear exercise selected have tags
  const handleClearSelectedByTags = () => {
    setCountTagsSelected([]);
    setExercisesSelected([]);
  };

  useEffect(() => {
    const exercises = listExercise.filter(it => selectedExercise.includes(it._id));
    handleCountTagBySelected(exercises);
  }, [selectedExercise, listExercise]);

  return (
    <S.TableWrapper>
      <Toolbar
        countTagsSelected={countTagsSelected}
        tagsSelected={exercisesSelected}
        onClearSelectedByTags={handleClearSelectedByTags}
      />
      <Layout.TableContainer>
        <Layout.Table>
          <TableHeader onCountTagBySelected={handleCountTagBySelected} />
          <TableBody onCountTagBySelected={handleCountTagBySelected} />
        </Layout.Table>
        {!loading && !props.length ? (
          <Layout.NoResult>
            <Layout.NoResultText>No results found</Layout.NoResultText>
            <Button className="button--add-new-item" onClick={onCreateNew}>
              + Add new exercise
            </Button>
          </Layout.NoResult>
        ) : null}
      </Layout.TableContainer>
      <Footer />
    </S.TableWrapper>
  );
}

export default React.memo(View);
