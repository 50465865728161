import React, { useCallback, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Image, Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import { diff } from 'deep-diff';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import { handleSelectedForm } from 'redux/onboarding-flow/actions';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { getFormProperties, getTriggerQuestions } from 'components/OnboardingFlowDetail/constants/helper';
import {
  ASSET_TYPES,
  INIT_STATE_TRIGGER_OPTIONS,
  INIT_STATE_TRIGGER_FORMS,
} from 'components/OnboardingFlowDetail/constants';
import { clearCancelTokens, saveCancelTokens, cancelLastRequest } from './helper';
import get from 'lodash/get';

import { axiosInstance } from 'configs/request';

import OnboardingFormSelect from '../OnboardingFormSelect';
import SelectSingleAutomation from '../SingleChoiceAutomationSelect';
import AnswerAssignToAssetSelect from '../AnswerAssignToAssetSelect';
import RemoveTriggerConfirmModal from '../RemoveTriggerConfirmModal';
import DiscardChangeTriggerModal from '../DiscardChangeTriggerModal';

const AutomationTriggerModal = props => {
  const {
    toggleModal,
    toggleConfirmModal,
    onboarding_form,
    type,
    isUpdate = false,
    handleChangeAsset,
    selectedAsset,
    handleSelectedForm,
  } = props;

  const initTriggerForms = selectedAsset && type ? selectedAsset[type] : [];
  const initState = cloneDeep({ ...INIT_STATE_TRIGGER_FORMS, ...(initTriggerForms[0] || {}) });
  const [state, setState] = useState(initState);
  const currentFormIdRef = useRef(initState.form || initState.custom_form);

  const hasTriggerOptions = get(state, 'trigger_options.length', 0) > 0;
  const diffChange = hasTriggerOptions && diff(initState, state);
  const isStateChange = !!diffChange;
  const isStateEmpty = state.empty;
  const isStateAssignedTrigger = state.assignedTrigger;
  const isUnavailable = state.is_form_deleted === true;

  const formOptions = onboarding_form;

  const onChangeAsset = data => {
    const triggerOptions = (data || {}).trigger_options || [];
    handleChangeAsset(data);
    setState(it => ({
      ...it,
      ...data,
      assignedTrigger: triggerOptions.some(it => !!it.forum || !!it.program_library),
    }));
  };

  const titleModal = useMemo(() => {
    if (type === ASSET_TYPES.PROGRAM_TRIGGER_FORMS) {
      return 'Set up Program Trigger';
    } else if (type === ASSET_TYPES.FORUM_TRIGGER_FORMS) {
      return 'Set up Forum Trigger';
    }

    return '';
  }, [type]);

  const isDisableSubmitButton = useMemo(() => {
    if (isStateEmpty) return true;
    if (!isStateAssignedTrigger) return true;

    return !isStateChange;
  }, [isStateChange, isStateEmpty, isStateAssignedTrigger]);

  const handleCloseModal = () => {
    if (isStateChange) {
      handleConfirmDiscardChange();
    } else {
      typeof toggleModal === 'function' && toggleModal(false);
    }
  };

  const handleCreateTrigger = () => {
    if (!isStateChange) return;

    if (typeof handleChangeAsset === 'function') {
      handleChangeAsset(state, type, true);
      toast(isUpdate ? 'Trigger has been updated.' : 'Trigger has been added.');
    }

    typeof toggleModal === 'function' && toggleModal(false);
  };

  const handleRemoveTrigger = () => {
    if (typeof handleChangeAsset === 'function') {
      handleChangeAsset(undefined, type);
      toast('Trigger has been removed.');
    }

    typeof toggleModal === 'function' && toggleModal(false);
  };

  const handleConfirmRemoveTrigger = () => {
    if (!isUpdate) return;

    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(true, <RemoveTriggerConfirmModal onConfirm={handleRemoveTrigger} />);
  };

  const handleDiscardChange = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
    typeof toggleModal === 'function' && toggleModal(false);
  };

  const handleConfirmDiscardChange = () => {
    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(true, <DiscardChangeTriggerModal onConfirm={handleDiscardChange} />);
  };

  const handleChangeOnboardingForm = async form => {
    const { isCustomForm, _id: formId, triggerQuestions, selected_questions } = getFormProperties(form, {
      isFilterTriggerQuestions: true,
    });

    currentFormIdRef.current = formId;
    const newState = { ...INIT_STATE_TRIGGER_FORMS };

    if (isCustomForm) {
      newState.custom_form = formId;
      newState.custom_form_data = form;
    } else {
      newState.form = formId;
      newState.form_data = form;
    }

    let questions = triggerQuestions;

    // try fetch form detail
    if (Array.isArray(triggerQuestions) && triggerQuestions.length === 0) {
      setState(newState);

      // fetch form detail
      const formDetail = await fetchFormDetail(formId, isCustomForm);

      // filter questions
      if (typeof formDetail === 'object' && formDetail.questions) {
        questions = getTriggerQuestions(formDetail.questions, isCustomForm, selected_questions) || [];

        // update state form detail
        updateStoreOnboardingForm(formId, formDetail);
      } else {
        return;
      }
    }

    const initQuestion = questions[0] || null;

    let initOptions = [];
    let questionId = null;
    if (initQuestion) {
      questionId = initQuestion.question_original_id || initQuestion._id;
      initOptions = initQuestion.options || initQuestion.option_answers || [];
      initOptions = initOptions.map(it => ({
        ...it,
        ...INIT_STATE_TRIGGER_OPTIONS,
        question_option: it._id,
      }));
    }

    newState.question = questionId;
    newState.question_data = initQuestion;
    newState.questions = questions;
    newState.trigger_options = initOptions;

    if (questions.length === 0) {
      newState.empty = true;
    }

    if (initOptions.length > 0) {
      newState.assignedTrigger = initOptions.some(it => !!it.forum || !!it.program_library);
    }

    setState(newState);
  };

  const handleChangeQuestion = data => {
    const questionId = data.question_original_id || data._id || null;
    if (!questionId) return;

    const newQuestion = state.questions.find(q => questionId === q.question_original_id || questionId === q._id);

    let newOptions = [];
    if (newQuestion) {
      newOptions = newQuestion.options || newQuestion.option_answers || [];
      newOptions = newOptions.map(it => ({
        ...it,
        ...INIT_STATE_TRIGGER_OPTIONS,
        question_option: it._id,
      }));
    }

    let newStateAssignedTrigger = false;
    if (newOptions.length > 0) {
      newStateAssignedTrigger = newOptions.some(it => !!it.forum || !!it.program_library);
    }

    setState(it => ({
      ...it,
      question: questionId,
      question_data: newQuestion,
      trigger_options: newOptions,
      assignedTrigger: newStateAssignedTrigger,
      is_form_deleted: false,
      is_deleted: false,
      is_resolved: true,
    }));
  };

  const handleChangeAssign = answerId => value => {
    let newOptions = state.trigger_options;
    if (value) {
      newOptions = state.trigger_options.map(it => {
        if (it.question_option === answerId) {
          if (type === ASSET_TYPES.PROGRAM_TRIGGER_FORMS) {
            it.program_library = value._id || null;
            it.program_library_data = value || null;
          }

          if (type === ASSET_TYPES.FORUM_TRIGGER_FORMS) {
            it.forum = value._id || null;
            it.forum_data = value || null;
          }
        }
        return it;
      });
    } else {
      newOptions = state.trigger_options.map(it => {
        if (it.question_option === answerId) {
          it.program_library = null;
          it.program_library_data = null;
          it.forum = null;
          it.forum_data = null;
        }
        return it;
      });
    }

    let newStateAssignedTrigger = false;
    if (newOptions.length > 0) {
      newStateAssignedTrigger = newOptions.some(it => !!it.forum || !!it.program_library);
    }

    setState(it => ({
      ...it,
      trigger_options: newOptions,
      assignedTrigger: newStateAssignedTrigger,
    }));
  };

  const fetchFormDetail = useCallback(async formId => {
    try {
      // cancel last request
      if (state.loading) {
        cancelLastRequest();
      }

      // start loading
      setState(it => ({
        ...it,
        question: null,
        question_data: null,
        questions: [],
        trigger_options: [],
        loading: true,
      }));

      // fetch api
      const response = await axiosInstance.get(`/api/forms/${formId}/trainer`, {
        cancelToken: saveCancelTokens,
      });

      if (typeof response === 'object' && currentFormIdRef.current === formId) {
        clearCancelTokens();

        const { data: { data = {} } = {} } = response;
        return data;
      }

      return {};
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      // end loading
      setState(it => ({ ...it, loading: false }));
    }
  }, []);

  const updateStoreOnboardingForm = useCallback((formId, formDetail) => {
    const newOnboardingForm = onboarding_form.map(item => {
      const { _id: itemId, isCustomForm: isItemCustom } = getFormProperties(item);
      if (itemId === formId) {
        if (isItemCustom) {
          return {
            ...item,
            custom_form: {
              ...item.custom_form,
              ...formDetail,
            },
          };
        } else {
          return {
            ...item,
            form: {
              ...item.form,
              ...formDetail,
            },
          };
        }
      }

      return item;
    });

    typeof handleSelectedForm === 'function' && handleSelectedForm(newOnboardingForm);
  }, []);

  return (
    <S.CustomModal
      open={true}
      closeOnDimmerClick={false}
      className={classNames({
        'state-1': !(state.form || state.custom_form),
        'state-2': state.form || state.custom_form,
        'state-empty': state.empty || state.loading,
        'state-unavailable': isUnavailable,
      })}
      onClose={handleCloseModal}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
    >
      <Modal.Header>
        <div className="header-title-container">
          <div className="header-title">{titleModal}</div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="trigger-container">
          <div className="trigger-scroll-wrapper">
            <OnboardingFormSelect
              options={formOptions}
              selectedForm={state.form_data || state.custom_form_data}
              selectedFormId={state.form || state.custom_form}
              onChange={handleChangeOnboardingForm}
              isUnavailable={isUnavailable}
            />
            {!isUnavailable && state.question && (
              <SelectSingleAutomation
                key={state.form || state.custom_form}
                usePortal
                options={state.questions || []}
                selectedQuestion={state.question_data}
                selectedQuestionId={state.question}
                onChange={handleChangeQuestion}
              />
            )}
            {!isUnavailable && (state.form || state.custom_form) && state.question && (
              <AnswerAssignToAssetSelect
                key={`${state.form || state.custom_form}_${state.question}`}
                type={type}
                options={state.trigger_options}
                stateTrigger={state}
                assignedAnswers={state.trigger_options}
                onChange={handleChangeAssign}
                handleChangeAsset={onChangeAsset}
              />
            )}
            {isStateEmpty && <S.EmptyWrapper>You don’t have any trigger question.</S.EmptyWrapper>}
            {state.loading && <S.EmptyWrapper>Loading...</S.EmptyWrapper>}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="left-buttons">
          {isUpdate && (
            <S.ButtonRemoveTrigger onClick={handleConfirmRemoveTrigger}>Remove Trigger</S.ButtonRemoveTrigger>
          )}
        </div>

        <div className="right-buttons">
          <S.ButtonCancel onClick={handleCloseModal}>Cancel</S.ButtonCancel>

          <S.ButtonSubmit disabled={isDisableSubmitButton} onClick={handleCreateTrigger}>
            {isUpdate ? 'Update' : 'Create'}
          </S.ButtonSubmit>
        </div>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      onboardingFlow: { onboarding_form },
    },
    user,
  } = state;

  return {
    user,
    onboarding_form,
  };
};

const mapDispatchToProps = {
  toggleModal,
  toggleSecondModal,
  toggleConfirmModal,
  handleSelectedForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomationTriggerModal);
