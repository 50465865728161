import { connect } from 'react-redux';
import StudioProgramList from './component';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { loadMoreStudioProgram } from 'redux/studio-program/actions';

const mapState = state => {
  const {
    rootReducer: {
      studioProgram: { list, total, isSearching },
    },
  } = state;
  return { list, total, isSearching };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  loadMore: bindActionCreators(loadMoreStudioProgram, dispatch),
});

export default connect(mapState, mapDispatch)(StudioProgramList);
