import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  overflow: auto;
  scroll-behavior: smooth;
  padding: 0;
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px 220px;
  background: #f0f2f5;
  overflow: none;
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 1 1 !important;
  margin: 0;
`;

export const SideBar = styled.div`
  height: 100%;
  background: #f0f2f5;
  & > * {
    margin-bottom: 20px;
  }
  position: sticky;
  top: 80px;
`;

export const ScrollTop = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  position: fixed;
  right: 88px;
  bottom: 30px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  z-index: 1000;
  background: #ffffff;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: #6456c4 !important;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;
