// Libs
import React, { useEffect, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Prompt } from 'react-router';
import { diff } from 'deep-diff';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import cloneDeep from 'lodash/cloneDeep';

// Shared
import { Button } from 'shared/FormControl';
import AssignOnboardingMessage from 'shared/AssignOnboardingMessage';
import LoadingIndicator from 'shared/LoadingIndicator';
import PaymentLimitAccess from 'shared/PaymentLimitAccess';
import { AllWarningIssueIcon } from 'shared/TriggerForms';

// Components
import AssetCard from './components/AssetCard';
import Settings from './components/OnboardingFlowSettings';
import WelcomeForm from './components/WelcomeForm';
import WelcomeFormQuestions from './components/WelcomeForm/WelcomeFormQuestions';
import OnDemandPopup from './components/OndemandPopup';
import UpgradePathFloat from './components/UpgradePathFloat';
import AutomationTriggerModal from './components/AutomationTriggerModal';

// Constants
import { AUTOFLOW_STATUS, CDN_URL } from 'constants/commonData';
import {
  getFormProperties,
  formatParamsTrigger,
  formatExtendDataTriggerForms,
  checkIsTriggerChange,
  formatParamsResolveIssues,
} from 'components/OnboardingFlowDetail/constants/helper';
import { saveLastAutomationRoute } from 'utils/commonFunction';
import {
  ASSET_TYPES,
  HEADER_ONBOARDING_FLOW_DETAIL_TABS,
  LIMIT_COLLECTION_NUMBER,
  ONBOARDING_FLOW_STATUS,
  ON_DEMAND_ASSET_KEY,
} from './constants';
import { getField, getTitleResourcesAsset, isHasAtLeastAssetValid } from './helper';
import { ASSET_TYPE } from 'components/Product/constants';

// Hooks
import useEscKeyPress from 'hooks/useEscKeyPress';

// Assets
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as LineIcon } from 'assets/icons/line_arrow_right.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen_icon.svg';
import { ReactComponent as PublishIcon } from 'assets/icons/publish.svg';
import { ReactComponent as UnpublishIcon } from 'assets/icons/unpublish_icon.svg';
import { ReactComponent as CloseGrayIcon } from 'assets/icons/close_gray.svg';
import { ReactComponent as SaveLightIcon } from 'assets/icons/save_light.svg';
import { ReactComponent as FormIcon } from 'assets/icons/form_asset_purple.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message_asset_orange.svg';
import { ReactComponent as AssignmentsIcon } from 'assets/icons/assignments_asset_green.svg';
import { ReactComponent as AutoflowIcon } from 'assets/icons/route_autoflow.svg';
import WarningViolet from 'assets/icons/warning_violet.svg';
import OnboardingBackground from 'assets/images/onboarding-limit-access.png';

import * as S from './style';

const OnboardingFlowDetail = props => {
  const {
    toggleModal,
    onboardingForm,
    onboardingMessage,
    getFormTemplates,
    isEditMode,
    isSaveDraft,
    isSubmitting,
    permission,
    workingData,
    saveOnboardingFlowDraft,
    publishOnboardingFlow,
    unpublishOnboardingFlow,
    editOnboardingFlow,
    cancelChanges,
    saveChanges,
    user,
    getOnboardingFlowDetail,
    toggleConfirmModal,
    handleSelectedForm,
    toggleSecondModal,
    getFormTemplatesDetail,
    formTemplates,
    questionsTemplates,
    push,
    removeForm,
    getDefaultMetrics,
    activeAssets,
    toggleStatus,
    sideBarVisible,
    toggleSideBar,
    isLoading,
    isConfirmModalOpen,
    resetFormTemplates,
    cloudfrontList,
    hideUpgradePathOnboarding,
    isHideUpgradePath,
    resetWorkingData,
    resetWorkingSetting,
    teamData,
    hasPermissionUseTrigger,
    location,
    history,
  } = props;

  const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

  const [selectedAsset, setSelectedAsset] = useState({
    form: null,
    message: null,
    program: null,
    forum: null,
    autoflow: null,
    on_demand: {
      demand_asset: {
        resource_collections: [],
        workout_collections: [],
        studio_programs: [],
      },
    },
    program_trigger_forms: [],
    forum_trigger_forms: [],
  });
  const [isLoadingWelcomeForm, setIsLoadingWelcomeForm] = useState(false);
  const [hasMessages, setHasMessages] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState(HEADER_ONBOARDING_FLOW_DETAIL_TABS[0].key);

  const status = useMemo(() => get(workingData, 'status', 'draft'), [workingData]);
  const isChanged = useMemo(() => {
    if (isLoading) return false;

    const isEqualFunction = (path, comparisonPath) => {
      const data = get(workingData, path);
      const comparisonData = get(selectedAsset, comparisonPath);
      return isEqual(data, comparisonData);
    };

    const onboardingFormChanged =
      !isEqual(get(workingData, 'onboarding_form.enable', true), get(activeAssets, 'form')) ||
      !isEqual(get(workingData, 'onboarding_message.enable', true), get(activeAssets, 'message')) ||
      !isEqual(get(workingData, 'onboarding_assignment.enable', true), get(activeAssets, 'assignments')) ||
      !isEqual(get(workingData, 'onboarding_autoflow.enable', true), get(activeAssets, 'autoflow')) ||
      !isEqual(get(workingData, 'onboarding_on_demand.enable', true), get(activeAssets, 'on_demand'));

    const messageFields = [
      'title',
      'type',
      'during_period_from',
      'during_period_to',
      'delay_after_enable',
      'delay_after_unit',
      'delay_after_value',
      'messages',
    ];

    const messageChanged = diff(
      pick(get(workingData, 'onboarding_message'), messageFields),
      pick(get(selectedAsset, 'message'), messageFields),
    );

    const assignmentChanged =
      !isEqualFunction('onboarding_assignment.program_library_data._id', 'program._id') ||
      !isEqualFunction('onboarding_assignment.forum_data._id', 'forum._id');

    // check trigger change
    const isProgramTriggerChange = checkIsTriggerChange({
      keySource: 'onboarding_assignment.program_trigger_forms',
      source: cloneDeep(workingData),
      keyState: ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
      state: cloneDeep(selectedAsset),
    });
    const isForumTriggerChange = checkIsTriggerChange({
      keySource: 'onboarding_assignment.forum_trigger_forms',
      source: cloneDeep(workingData),
      keyState: ASSET_TYPES.FORUM_TRIGGER_FORMS,
      state: cloneDeep(selectedAsset),
    });
    const assignmentTriggerChanged = isProgramTriggerChange || isForumTriggerChange;

    const autoflowChanged = !isEqualFunction('onboarding_autoflow.autoflow_data._id', 'autoflow._id');

    const onDemandChanged =
      diff(
        get(workingData, `onboarding_on_demand.demand_asset[${ASSET_TYPES.RESOURCE_COLLECTIONS}]`),
        get(selectedAsset, `[${ASSET_TYPES.ON_DEMAND}].demand_asset[${ASSET_TYPES.RESOURCE_COLLECTIONS}]`),
      ) ||
      diff(
        get(workingData, `onboarding_on_demand.demand_asset[${ASSET_TYPES.WORKOUT_COLLECTIONS}]`),
        get(selectedAsset, `[${ASSET_TYPES.ON_DEMAND}].demand_asset[${ASSET_TYPES.WORKOUT_COLLECTIONS}]`),
      ) ||
      diff(
        get(workingData, `onboarding_on_demand.demand_asset[${ASSET_TYPES.STUDIO_PROGRAMS}]`),
        get(selectedAsset, `[${ASSET_TYPES.ON_DEMAND}].demand_asset[${ASSET_TYPES.STUDIO_PROGRAMS}]`),
      );

    return (
      onboardingFormChanged ||
      messageChanged ||
      assignmentChanged ||
      autoflowChanged ||
      onDemandChanged ||
      assignmentTriggerChanged
    );
  }, [workingData, selectedAsset, activeAssets]);

  const onboardingFlowId = get(props, 'match.params.onboardingFlowId', '');

  if (!permission.onboarding_flow) {
    return (
      <PaymentLimitAccess message="You are not authorized to access this feature." background={OnboardingBackground} />
    );
  }

  useEscKeyPress(() => {
    if (isConfirmModalOpen && toggleConfirmModal) {
      return toggleConfirmModal(false);
    }
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('view_mode') === HEADER_ONBOARDING_FLOW_DETAIL_TABS[1].key) {
      setTabActive(HEADER_ONBOARDING_FLOW_DETAIL_TABS[1].key);

      params.delete('view_mode');
      const newUrl = params.toString()
        ? `${window.location.pathname}?${params.toString()}`
        : `${window.location.pathname}`;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location, history]);

  useEffect(() => {
    getDefaultMetrics && getDefaultMetrics();
    toggleSideBar && toggleSideBar(true);
    hasPermissionMultiple &&
      typeof saveLastAutomationRoute === 'function' &&
      saveLastAutomationRoute('/home/onboarding-flow');
  }, []);

  useEffect(() => {
    setSelectedAsset(it => ({
      ...it,
      form: !isEmpty(onboardingForm) ? onboardingForm : [],
    }));
  }, [onboardingForm]);

  useEffect(() => {
    if (!isEmpty(onboardingMessage)) {
      setSelectedAsset(it => ({ ...it, message: onboardingMessage }));
    }
  }, [onboardingMessage]);

  useEffect(() => {
    typeof getOnboardingFlowDetail === 'function' &&
      getOnboardingFlowDetail(onboardingFlowId)
        .then(res => {
          const data = get(res, 'data.data');
          if (isNil(data)) {
            handleResetData({});
            return;
          }

          handleResetData(data);
          if (!isEmpty(get(data, 'onboarding_message.messages', []))) {
            setHasMessages(true);
          }
        })
        .finally(() => {
          setWaiting(false);
        });
  }, []);

  useEffect(() => {
    return () => {
      typeof resetFormTemplates === 'function' && resetFormTemplates();
      typeof resetWorkingData === 'function' && resetWorkingData();
      typeof resetWorkingSetting === 'function' && resetWorkingSetting();
    };
  }, []);

  const handleToggleStatus = type => {
    let assetName = '';
    switch (type) {
      case ASSET_TYPES.WELCOME_FORM:
        assetName = 'Onboarding Forms';
        break;
      case ASSET_TYPES.ONBOARDING_MESSAGE:
        assetName = 'Onboarding Messages';
        break;
      case ASSET_TYPES.ASSIGNMENTS:
        assetName = 'Assignments';
        break;
      case ASSET_TYPES.AUTOFLOW:
        assetName = 'Autoflow';
        break;
      case ASSET_TYPES.ON_DEMAND:
        assetName = 'On-Demand';
        break;

      default:
        break;
    }
    toast(`${assetName} is ${activeAssets[type] ? 'excluded from' : 'included in'} Onboarding Flow`, {
      className: 'custom--onboarding-flow',
    });
    toggleStatus({ ...activeAssets, [type]: !activeAssets[type] });
  };

  const checkPermission = type => {
    switch (type) {
      case ASSET_TYPES.AUTOFLOW:
        return permission[type];
      case ASSET_TYPES.ON_DEMAND:
        return (
          permission['on_demand_workout'] || permission['studio_resource_collection'] || permission[ASSET_TYPE.STUDIO]
        );

      default:
        return true;
    }
  };

  const handleChangeAsset = (assetData = null, type, isConvertTriggerOption = false) => {
    const assetConvert = {
      ...assetData,
      trigger_options: (get(assetData, 'trigger_options', []) || []).filter(it => {
        return !(!it.forum && !it.program_library && it.is_deleted);
      }),
    };

    const asset = isConvertTriggerOption ? assetConvert : assetData;

    switch (type) {
      case ASSET_TYPES.WELCOME_FORM:
        setSelectedAsset(it => ({ ...it, [type]: [asset] }));
        break;
      case ASSET_TYPES.ONBOARDING_MESSAGE:
        setSelectedAsset(it => ({ ...it, [type]: asset }));
        setHasMessages(!isEmpty(asset.messages));
        break;
      case ASSET_TYPES.RESOURCE_COLLECTIONS:
      case ASSET_TYPES.WORKOUT_COLLECTIONS:
      case ASSET_TYPES.STUDIO_PROGRAMS:
        setSelectedAsset(it => {
          const updatedOnDemand = {
            ...it.on_demand,
            demand_asset: { ...(it.on_demand || {}).demand_asset },
          };
          updatedOnDemand.demand_asset[type] = asset;
          return {
            ...it,
            on_demand: updatedOnDemand,
          };
        });
        break;
      case ASSET_TYPES.PROGRAM_TRIGGER_FORMS:
      case ASSET_TYPES.FORUM_TRIGGER_FORMS:
        setSelectedAsset(it => ({ ...it, [type]: asset ? [asset] : [] }));
        break;
      default:
        setSelectedAsset(it => ({ ...it, [type]: asset }));
        break;
    }
  };

  const handleOpenTriggerModal = ({ type, ...rest } = {}) => {
    if (type === ASSET_TYPES.PROGRAM_TRIGGER_FORMS || type === ASSET_TYPES.FORUM_TRIGGER_FORMS) {
      typeof toggleModal === 'function' &&
        toggleModal(
          true,
          <AutomationTriggerModal
            handleChangeAsset={handleChangeAsset}
            type={type}
            selectedAsset={selectedAsset}
            {...rest}
          />,
        );
    }
  };

  const handleOpenMessageModal = () => {
    toggleModal(
      true,
      <AssignOnboardingMessage
        onboardingMessage={
          !isEmpty(get(selectedAsset, 'message.messages', [])) ? selectedAsset.message : onboardingMessage
        }
        afterSchedule={handleChangeAsset}
        isEditMessage={!isEmpty(get(selectedAsset, 'message.messages', []))}
      />,
    );
  };

  const handleOpenWelcomeForm = firstFormId => {
    if (!isEmpty(formTemplates)) {
      onOpenWelcomeForm({ formData: formTemplates, firstFormId });
    } else {
      setIsLoadingWelcomeForm(true);
      getFormTemplates &&
        getFormTemplates()
          .then(response => {
            const formData = get(response, 'data.data', []).reverse();
            if (formData.length) {
              onOpenWelcomeForm({ formData, firstFormId });
            }
          })
          .finally(() => {
            setIsLoadingWelcomeForm(false);
          });
    }
  };

  const onOpenWelcomeForm = ({ formData, firstFormId }) => {
    toggleModal(
      true,
      <WelcomeForm
        onClose={() => toggleModal(false)}
        formData={formData}
        onboardingForm={get(onboardingForm, '[0]', {})}
        getFormTemplates={getFormTemplates}
        firstFormId={
          get(selectedAsset, 'form[0].custom_form') ? get(selectedAsset, 'form[0].custom_form._id') : firstFormId
        }
        isEditForm={!firstFormId && get(selectedAsset, 'form', []).some(item => get(item, 'form._id'))}
        forms={get(selectedAsset, 'form', [])}
        selectedAsset={selectedAsset}
        handleChangeAsset={handleChangeAsset}
      />,
    );
  };

  const handleEditForm = id => {
    const questionsDetail = questionsTemplates.find(item => get(item, '_id', '') === id);
    if (!isEmpty(questionsTemplates) && questionsDetail) {
      onOpenWelcomeFormQuestions({ formDetailData: questionsDetail });
    } else {
      setIsLoadingWelcomeForm(true);
      getFormTemplatesDetail &&
        getFormTemplatesDetail(id)
          .then(response => {
            const formDetailData = get(response, 'data.data');
            if (formDetailData) {
              onOpenWelcomeFormQuestions({ formDetailData });
            }
          })
          .finally(() => {
            setIsLoadingWelcomeForm(false);
          });
    }
  };

  const onOpenWelcomeFormQuestions = ({ formDetailData }) => {
    const anotherForm = get(selectedAsset, 'form', []).find(
      item => ![get(item, 'form._id'), get(item, 'custom_form._id')].includes(get(formDetailData, '_id')),
    );

    toggleSecondModal(
      true,
      <WelcomeFormQuestions
        onCloseSecondModal={() => toggleSecondModal(false)}
        handleSelectedForm={handleSelectedForm}
        formDetailData={formDetailData}
        onboardingForm={onboardingForm.find(form => isEqual(get(form, 'form._id'), get(formDetailData, '_id'))) || {}}
        isEditForm={get(selectedAsset, 'form', []).some(form =>
          isEqual(get(form, 'form._id'), get(formDetailData, '_id')),
        )}
        toggleModal={toggleModal}
        formTemplates={formTemplates}
        getFormTemplates={getFormTemplates}
        isRemoveForm={get(selectedAsset, 'form', []).filter(item => get(item, 'form._id')).length === 2}
        forms={get(selectedAsset, 'form', [])}
        firstFormId={get(anotherForm, 'form._id') || get(anotherForm, 'custom_form._id')}
        selectedAsset={selectedAsset}
        handleChangeAsset={handleChangeAsset}
      />,
    );
  };

  const generateBodyData = () => {
    let bodyData = {
      onboarding_form: {
        enable: activeAssets.form,
      },
      onboarding_message: {
        enable: activeAssets.message,
        type: 'during_period',
      },
      onboarding_assignment: {
        enable: activeAssets.assignments,
      },
      onboarding_autoflow: {
        enable: activeAssets.autoflow,
      },
      onboarding_on_demand: {
        enable: activeAssets.on_demand,
      },
    };

    if (selectedAsset.form) {
      bodyData.onboarding_form = {
        enable: activeAssets.form,
        forms: selectedAsset.form.map(item => {
          if (get(item, 'custom_form')) {
            return {
              custom_form: get(item, 'custom_form._id'),
              selected_questions: undefined,
            };
          } else {
            return {
              form: get(item, 'form._id'),
              selected_questions: !isEmpty(onboardingForm)
                ? get(item, 'selected_questions', [])
                : map(get(item, 'form.questions', []), item => item._id),
            };
          }
        }),
      };
    }
    if (selectedAsset.message) {
      bodyData.onboarding_message = { ...selectedAsset.message, enable: activeAssets.message };
      if (isEmpty(get(bodyData, 'onboarding_message.title', ''))) {
        bodyData.onboarding_message = omit(bodyData.onboarding_message, 'title');
      }
    }
    if (
      selectedAsset.program ||
      selectedAsset.forum ||
      get(selectedAsset, `[${ASSET_TYPES.PROGRAM_TRIGGER_FORMS}].length`, []) > 0 ||
      get(selectedAsset, `[${ASSET_TYPES.FORUM_TRIGGER_FORMS}].length`, []) > 0
    ) {
      bodyData.onboarding_assignment = {
        enable: activeAssets.assignments,
        program_library: get(selectedAsset, 'program._id'),
        program_library_data: get(selectedAsset, 'program') || {},
        forum: get(selectedAsset, 'forum._id'),
        forum_data: get(selectedAsset, 'forum') || {},
        program_trigger_forms: formatParamsTrigger(
          selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS],
          ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
        ),
        forum_trigger_forms: formatParamsTrigger(
          selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS],
          ASSET_TYPES.FORUM_TRIGGER_FORMS,
        ),
        program_trigger_resolve_issues: formatParamsResolveIssues(
          selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS],
          ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
        ),
        forum_trigger_resolve_issues: formatParamsResolveIssues(
          selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS],
          ASSET_TYPES.FORUM_TRIGGER_FORMS,
        ),
      };
    }
    if (selectedAsset.autoflow) {
      bodyData.onboarding_autoflow = {
        enable: activeAssets.autoflow,
        autoflow: get(selectedAsset, 'autoflow._id'),
        autoflow_data: get(selectedAsset, 'autoflow') || {},
      };
    }
    if (selectedAsset.on_demand) {
      bodyData.onboarding_on_demand = {
        enable: activeAssets.on_demand,
        demand_asset: get(selectedAsset, 'on_demand.demand_asset', {}),
      };
    }

    return bodyData;
  };

  const handleResetData = data => {
    const {
      onboarding_form,
      onboarding_message,
      onboarding_assignment,
      onboarding_autoflow,
      onboarding_on_demand,
      is_show_save_draft,
    } = data || {};

    const newOnboardingForm = get(onboarding_form, 'forms', []).map(item => {
      const { hasTrigger } = getFormProperties(item, { checkHasTrigger: true });

      if (get(item, 'custom_form')) {
        const signature = get(item, 'custom_form.questions', []).filter(question =>
          ['signature'].includes(get(question, 'type', '')),
        );

        return {
          custom_form: {
            ...get(item, 'custom_form'),
            hasSignature: !!signature.length,
            hasTrigger,
          },
        };
      } else {
        const signature = get(item, 'form.questions', []).filter(question =>
          ['signature'].includes(get(question, 'type', '')),
        );

        return {
          form: {
            ...get(item, 'form'),
            hasSignature: !!signature.length,
            hasTrigger,
          },
          selected_questions: get(item, 'selected_questions', []),
        };
      }
    });
    handleSelectedForm && handleSelectedForm(newOnboardingForm);

    const getToggleStatus = (isAutoflowAddon, isShowDraft, asset) => {
      return isAutoflowAddon ? isShowDraft || get(asset, 'enable', true) : get(asset, 'enable', true);
    };
    const forms = [
      { key: 'form', value: onboarding_form },
      { key: 'message', value: onboarding_message },
      { key: 'assignments', value: onboarding_assignment },
      { key: 'autoflow', value: onboarding_autoflow },
      { key: 'on_demand', value: onboarding_on_demand },
    ];
    const status = forms.reduce((acc, { key, value }) => {
      acc[key] = getToggleStatus(hasPermissionMultiple, is_show_save_draft, value);
      return acc;
    }, {});
    toggleStatus(status);
    setHasMessages(!isEmpty(get(onboarding_message, 'messages', [])));
    setSelectedAsset({
      form: newOnboardingForm || null,
      message: onboarding_message || null,
      program: get(onboarding_assignment, 'program_library_data', null),
      forum: get(onboarding_assignment, 'forum_data')
        ? {
            ...get(onboarding_assignment, 'forum_data'),
            label: get(onboarding_assignment, 'forum_data.name'),
          }
        : null,
      autoflow: get(onboarding_autoflow, 'autoflow_data')
        ? {
            ...get(onboarding_autoflow, 'autoflow_data'),
            label: get(onboarding_autoflow, 'autoflow_data.name'),
            is_deleted: get(onboarding_autoflow, 'autoflow_data.is_deleted', false),
          }
        : null,
      on_demand: !isEmpty(get(onboarding_on_demand, 'demand_asset', {}))
        ? { demand_asset: get(onboarding_on_demand, 'demand_asset') }
        : null,
      program_trigger_forms: formatExtendDataTriggerForms({
        triggerForms: get(onboarding_assignment, 'program_trigger_forms', []),
        forms: newOnboardingForm,
      }),
      forum_trigger_forms: formatExtendDataTriggerForms({
        triggerForms: get(onboarding_assignment, 'forum_trigger_forms', []),
        forms: newOnboardingForm,
      }),
    });
  };

  const handleSaveDraft = nextLocation => {
    if (isSubmitting) return;
    setLoading(true);
    (hasPermissionMultiple
      ? saveChanges(get(workingData, '_id'), generateBodyData(), isSaveDraft)
      : saveOnboardingFlowDraft(generateBodyData(), hasPermissionMultiple)
    )
      .then(res => {
        const { data } = res.data;
        handleResetData(data);
        hasPermissionMultiple && getOnboardingFlowDetail(onboardingFlowId);
      })
      .catch(() => {
        handleResetData(workingData || {});
      })
      .finally(() => {
        nextLocation && push(nextLocation.pathname);
        setLoading(false);
      });
    autoScrollToLeft();
  };

  const isHaveLeastOneAssetValid = () => {
    const isValidForm = activeAssets.form && isExistedFormValid();
    const isValidMessage = activeAssets.message && hasMessages;
    const isValidAssignmentProgram =
      activeAssets.assignments && !!selectedAsset.program && !getField(selectedAsset, 'program.is_deleted');
    const isValidAssignmentForum =
      activeAssets.assignments && !!selectedAsset.forum && !getField(selectedAsset, 'forum.is_deleted');
    const isValidAutoflow =
      activeAssets.autoflow &&
      !!selectedAsset.autoflow &&
      getField(selectedAsset, 'autoflow.status') === AUTOFLOW_STATUS.ACTIVATED;
    const isValidOnDemand = onValidOnDemand(selectedAsset, activeAssets);

    return (
      isValidForm ||
      isValidMessage ||
      isValidAssignmentProgram ||
      isValidAssignmentForum ||
      isValidAutoflow ||
      isValidOnDemand
    );
  };

  const isNoneAssetTurnOn = () => {
    return (
      !get(activeAssets, 'form') &&
      !get(activeAssets, 'message') &&
      !get(activeAssets, 'assignments') &&
      !get(activeAssets, 'autoflow') &&
      !get(activeAssets, 'on_demand')
    );
  };

  const handlePublish = () => {
    if (isSubmitting) return;

    if (isNoneAssetTurnOn() || !isHaveLeastOneAssetValid()) {
      renderCannotPublishPopup();
      return;
    }

    // able to publish
    publishOnboardingFlow(get(workingData, '_id'));
    autoScrollToLeft();
  };

  const handleUnpublish = () => {
    if (isSubmitting) return;
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.UnpublishConfirmModal
          title="Unpublish Onboarding Flow"
          headerIcon={`${CDN_URL}/images/studio_program_unpublish_yellow.svg`}
          newStyle
          largeSpacing
          hasCloseIcon
          hasHoverState
          content={
            <>
              <p>
                This will not assign the onboarding flow and its assets to any new clients who sign up. Any assets
                already assigned to clients or scheduled to be assigned will be unaffected.
              </p>
            </>
          }
          confirmButtonTitle="I understand"
          confirmButtonClass="confirm-unpublish-button"
          onConfirm={() => {
            unpublishOnboardingFlow(get(workingData, '_id'));
            autoScrollToLeft();
          }}
        />,
      );
  };

  const handleEdit = () => {
    editOnboardingFlow();
    if (get(selectedAsset, 'message.messages.length')) {
      setHasMessages(true);
    }
  };

  const handleCancelChanges = nextLocation => {
    cancelChanges();
    handleResetData(workingData || {});
    autoScrollToLeft();
    if (nextLocation) {
      setTimeout(() => {
        nextLocation && push(nextLocation.pathname);
      }, 0);
    }
  };

  const isExistedFormValid = () => {
    return (selectedAsset.form || []).some(item => {
      if (item.custom_form) {
        return !item.custom_form.is_archived && !item.custom_form.is_deleted;
      }
      // form default
      if (item.form) return true;
      return false;
    });
  };

  const onValidOnDemand = (selectedAsset, activeAssets) => {
    const onDemandAsset = selectedAsset[ASSET_TYPES.ON_DEMAND] && selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset;

    if (!activeAssets.on_demand || !onDemandAsset) {
      return false;
    }

    const resourceCollections = onDemandAsset[ASSET_TYPES.RESOURCE_COLLECTIONS];
    const workoutCollections = onDemandAsset[ASSET_TYPES.WORKOUT_COLLECTIONS];
    const studioPrograms = onDemandAsset[ASSET_TYPES.STUDIO_PROGRAMS];

    // Check one asset has data
    const isHasOneListHasData =
      !isEmpty(resourceCollections) || !isEmpty(workoutCollections) || !isEmpty(studioPrograms);

    // Check all asset have data
    const isHasAllListHasData =
      !isEmpty(resourceCollections) && !isEmpty(workoutCollections) && !isEmpty(studioPrograms);

    // Check at least asset valid
    const resourceAssetValid = isHasAtLeastAssetValid(resourceCollections, ASSET_TYPES.RESOURCE_COLLECTIONS);
    const workoutAssetValid = isHasAtLeastAssetValid(workoutCollections, ASSET_TYPES.WORKOUT_COLLECTIONS);
    const studioAssetValid = isHasAtLeastAssetValid(studioPrograms, ASSET_TYPES.STUDIO_PROGRAMS);

    const isValidAllAsset = resourceAssetValid || workoutAssetValid || studioAssetValid;

    const isValid = isHasAllListHasData ? isValidAllAsset : isHasOneListHasData && isValidAllAsset;

    return isValid;
  };

  const handleSaveChanges = nextLocation => {
    if (isSubmitting) return;
    const isPublished = status === ONBOARDING_FLOW_STATUS.PUBLISH;

    if (isPublished && (isNoneAssetTurnOn() || !isHaveLeastOneAssetValid())) {
      renderCannotPublishPopup(
        'The Onboarding Flow is empty',
        'Please add at least 1 asset before publishing the onboarding flow',
      );
      return;
    }

    // save normally
    setLoading(true);
    saveChanges(get(workingData, '_id'), generateBodyData())
      .then(res => {
        const { data } = res.data;
        handleResetData(data);
      })
      .catch(() => {
        handleResetData(workingData || {});
      })
      .finally(() => {
        nextLocation && push(nextLocation.pathname);
        setLoading(false);
      });
    autoScrollToLeft();
  };

  const shouldHideElement = type => {
    if (!type) return true;
    if (!activeAssets[type] && !isSaveDraft && !isEditMode) return true;
    if (type === ASSET_TYPES.WELCOME_FORM) {
      return isEmpty(get(selectedAsset, 'form', {})) && !isSaveDraft && !isEditMode;
    }
    if (type === ASSET_TYPES.ONBOARDING_MESSAGE) {
      return (!get(selectedAsset, 'message.messages', []).length || !hasMessages) && !isSaveDraft && !isEditMode;
    }
    if (type === ASSET_TYPES.ASSIGNMENTS) {
      return (
        !get(selectedAsset[ASSET_TYPES.PROGRAM], '_id') &&
        !get(selectedAsset[ASSET_TYPES.FORUM], '_id') &&
        !selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0] &&
        !selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0] &&
        !isSaveDraft &&
        !isEditMode
      );
    }
    if (type === ASSET_TYPES.AUTOFLOW) {
      return (
        !checkPermission(type) || (!isSaveDraft && !isEditMode && !get(selectedAsset[ASSET_TYPES.AUTOFLOW], '_id'))
      );
    }
    if (type === ASSET_TYPES.ON_DEMAND) {
      return (
        !checkPermission(type) ||
        (!isSaveDraft &&
          !isEditMode &&
          isEmpty(((selectedAsset.on_demand || {}).demand_asset || {})[ASSET_TYPES.RESOURCE_COLLECTIONS]) &&
          isEmpty(((selectedAsset.on_demand || {}).demand_asset || {})[ASSET_TYPES.WORKOUT_COLLECTIONS]) &&
          isEmpty(((selectedAsset.on_demand || {}).demand_asset || {})[ASSET_TYPES.STUDIO_PROGRAMS]))
      );
    }
    return !get(selectedAsset[type], '_id') && !isSaveDraft && !isEditMode;
  };

  const handleDiscardChange = nextLocation => {
    if (isChanged) {
      toggleConfirmModal(
        true,
        <S.ExitConfirm
          className="exit-confirm custom"
          noBorder
          headerIcon={WarningViolet}
          hasCloseIcon
          hasHoverState
          title="Save before exiting?"
          content="You have made changes that have not been saved yet. Would you like to save changes before leaving?"
          onConfirm={() => {
            get(workingData, '_id') ? handleSaveChanges(nextLocation) : handleSaveDraft(nextLocation);
          }}
          onDeny={() => handleCancelChanges(nextLocation)}
          cancelButtonTitle="Exit without saving"
          confirmButtonTitle="Save"
        />,
      );

      return false;
    }

    return true;
  };

  const renderCannotPublishPopup = (title = '', message = '') => {
    toggleConfirmModal(
      true,
      <S.ExitConfirm
        className="exit-confirm custom publish-confirm"
        noBorder
        headerIcon={WarningViolet}
        hasCloseIcon
        hasHoverState
        title={title || 'Cannot publish'}
        content={message || 'Please add at least 1 asset before publishing the onboarding flow.'}
        onConfirm={() => {
          toggleConfirmModal(false);
          autoScrollToLeft();
        }}
        hideCancelButton
        confirmButtonTitle="Okay"
      />,
    );
  };

  const autoScrollToLeft = () => {
    const container = document.querySelector('.onboarding-flow-container');
    if (container) {
      container.scrollLeft = {
        left: 0,
        behavior: 'smooth',
      };
    }
  };

  const handleOpenOnDemandPopup = type => {
    const isHasOnDemand =
      !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND]) &&
      !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset) &&
      !isEmpty(selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[type]);

    const onDemandSelectedList = isHasOnDemand ? selectedAsset[ASSET_TYPES.ON_DEMAND].demand_asset[type] : [];
    const newOnDemandSelectedList = (onDemandSelectedList || []).filter(item => {
      const { is_deleted, isPublished, status: assetStatus } = item[`${ON_DEMAND_ASSET_KEY[type]}_data`] || {};

      if ([ASSET_TYPES.RESOURCE_COLLECTIONS].includes(type)) {
        return !is_deleted;
      } else if ([ASSET_TYPES.WORKOUT_COLLECTIONS].includes(type)) {
        return !is_deleted && (assetStatus === 'publish' || isPublished);
      } else if ([ASSET_TYPES.STUDIO_PROGRAMS].includes(type)) {
        return !is_deleted && isPublished;
      }
      return false;
    });

    const newTotalSelect = LIMIT_COLLECTION_NUMBER - newOnDemandSelectedList.length;

    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <OnDemandPopup
          type={type}
          totalSelect={newTotalSelect}
          title={getTitleResourcesAsset(type)}
          onClose={() => toggleModal(false)}
          onSelect={handleChangeAsset}
          onDemandSelectedList={onDemandSelectedList}
          newOnDemandSelectedList={newOnDemandSelectedList}
          cloudfrontList={cloudfrontList}
          user={user}
        />,
      );
  };

  const handleChangeTab = key => {
    if (key === tabActive) return;
    setTabActive(key);
  };

  return (
    <S.MainPage className="mainOnboardingFlow" isOverviewTab={tabActive === HEADER_ONBOARDING_FLOW_DETAIL_TABS[0].key}>
      <S.ToolbarWrapper>
        <S.Toolbar sideBarVisible={sideBarVisible}>
          <S.TabWrapper>
            {HEADER_ONBOARDING_FLOW_DETAIL_TABS.map(tab => {
              const { key, value } = tab || {};
              const isDisabledSettingsTab =
                status !== ONBOARDING_FLOW_STATUS.PUBLISH && key === 'settings' && hasPermissionMultiple;

              return (
                <S.Tab
                  key={key}
                  isActive={key === tabActive}
                  isDisabled={isDisabledSettingsTab}
                  onClick={() => {
                    if (isDisabledSettingsTab) return;
                    handleChangeTab(key);
                  }}
                  data-tip
                  data-for={`tool-tip__${key}`}
                >
                  <span>{value}</span>
                  {isDisabledSettingsTab && (
                    <ReactTooltip
                      className="app-tooltip tab-tooltip"
                      id={`tool-tip__${key}`}
                      effect="solid"
                      place="top"
                    >
                      <p>Publish onboarding flow to access settings</p>
                    </ReactTooltip>
                  )}
                </S.Tab>
              );
            })}
            {hasPermissionUseTrigger && (
              <AllWarningIssueIcon
                forumTriggerForms={selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS]}
                programTriggerForms={selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS]}
              />
            )}
          </S.TabWrapper>
          {(!hasPermissionMultiple || tabActive === HEADER_ONBOARDING_FLOW_DETAIL_TABS[0].key) && (
            <S.ActionsWrapper>
              {!isSaveDraft && !isEditMode && (
                <Button className="onboarding-flow-btn onboarding-flow-edit" disabled={false} onClick={handleEdit}>
                  <PenIcon />
                  Edit
                </Button>
              )}
              {status !== ONBOARDING_FLOW_STATUS.PUBLISH && isSaveDraft && (
                <Button
                  className="onboarding-flow-btn onboarding-flow-save-draft"
                  disabled={loading}
                  onClick={handleSaveDraft}
                >
                  <SaveIcon />
                  Save Draft
                </Button>
              )}
              {!isSaveDraft && isEditMode && (
                <Button
                  className="onboarding-flow-btn onboarding-flow-cancel-changes"
                  disabled={false}
                  onClick={isChanged ? handleDiscardChange : handleCancelChanges}
                >
                  <CloseGrayIcon />
                  Cancel Changes
                </Button>
              )}
              {!isSaveDraft && isEditMode && (
                <Button
                  className="onboarding-flow-btn onboarding-flow-save-changes"
                  disabled={loading}
                  onClick={handleSaveChanges}
                >
                  <SaveLightIcon />
                  Save Changes
                </Button>
              )}
              {status === ONBOARDING_FLOW_STATUS.PUBLISH && !isEditMode && !isSaveDraft && (
                <Button
                  className="onboarding-flow-btn onboarding-flow-unpublish"
                  disabled={false}
                  onClick={handleUnpublish}
                >
                  <UnpublishIcon />
                  Unpublish
                </Button>
              )}
              {status !== ONBOARDING_FLOW_STATUS.PUBLISH && !isEditMode && !isSaveDraft && (
                <Button
                  className="onboarding-flow-btn onboarding-flow-publish"
                  disabled={false}
                  onClick={handlePublish}
                >
                  <PublishIcon />
                  Publish
                </Button>
              )}
            </S.ActionsWrapper>
          )}
        </S.Toolbar>
      </S.ToolbarWrapper>
      {tabActive === HEADER_ONBOARDING_FLOW_DETAIL_TABS[0].key && (
        <S.Container className="onboarding-flow-container">
          <Prompt when={isChanged} message={handleDiscardChange} />
          <S.Wrapper>
            {shouldHideElement(ASSET_TYPES.WELCOME_FORM) &&
            shouldHideElement(ASSET_TYPES.ONBOARDING_MESSAGE) &&
            shouldHideElement(ASSET_TYPES.ASSIGNMENTS) &&
            shouldHideElement(ASSET_TYPES.AUTOFLOW) &&
            shouldHideElement(ASSET_TYPES.ON_DEMAND) ? (
              <S.EmptyWrapper>
                <S.EmptyIconsWrapper>
                  <S.IconWrapper>
                    <FormIcon />
                  </S.IconWrapper>
                  <S.IconWrapper>
                    <MessageIcon />
                  </S.IconWrapper>
                  <S.IconWrapper>
                    <AssignmentsIcon />
                  </S.IconWrapper>
                  <S.IconWrapper thin>
                    <AutoflowIcon />
                  </S.IconWrapper>
                </S.EmptyIconsWrapper>
                <S.EmptyMessage>No Assets</S.EmptyMessage>
              </S.EmptyWrapper>
            ) : (
              <S.AssetsWrapper disabled={!isSaveDraft && !isEditMode && !waiting}>
                {waiting ? (
                  <LoadingIndicator className="onboarding-flow-loading" />
                ) : (
                  <>
                    {shouldHideElement(ASSET_TYPES.WELCOME_FORM) ? null : (
                      <AssetCard
                        asset={{
                          type: ASSET_TYPES.WELCOME_FORM,
                          is_active: activeAssets.form,
                        }}
                        selectedAsset={selectedAsset}
                        onToggleStatus={() => handleToggleStatus(ASSET_TYPES.WELCOME_FORM)}
                        onOpen={(editingId, firstFormId) =>
                          editingId ? handleEditForm(editingId) : handleOpenWelcomeForm(firstFormId)
                        }
                        isEditForm={!isEmpty(get(selectedAsset, 'form', []))}
                        buttonIcon={
                          (get(selectedAsset, 'form') || []).some(item => get(item, 'form._id')) && (
                            <EditIcon className="onboarding-flow-edit-icon" />
                          )
                        }
                        onboardingForm={onboardingForm || []}
                        isLoading={isLoadingWelcomeForm}
                        disabled={(!isSaveDraft && !isEditMode) || isSubmitting}
                        isShowTooltip={isSaveDraft || isEditMode}
                        key={ASSET_TYPES.WELCOME_FORM}
                        removeForm={removeForm}
                        isEditMode={isEditMode}
                        toggleConfirmModal={toggleConfirmModal}
                        handleChangeAsset={handleChangeAsset}
                      />
                    )}
                    {shouldHideElement(ASSET_TYPES.ONBOARDING_MESSAGE) ? null : (
                      <>
                        {shouldHideElement(ASSET_TYPES.WELCOME_FORM) ? null : <LineIcon className="center" />}
                        <AssetCard
                          asset={{
                            type: ASSET_TYPES.ONBOARDING_MESSAGE,
                            is_active: activeAssets.message,
                          }}
                          selectedAsset={selectedAsset}
                          onToggleStatus={() => handleToggleStatus(ASSET_TYPES.ONBOARDING_MESSAGE)}
                          onOpen={handleOpenMessageModal}
                          isEditMessage={hasMessages}
                          buttonIcon={hasMessages && <EditIcon className="onboarding-flow-edit-icon" />}
                          user={user}
                          disabled={(!isSaveDraft && !isEditMode) || isSubmitting}
                          isShowTooltip={isSaveDraft || isEditMode}
                          key={ASSET_TYPES.ONBOARDING_MESSAGE}
                        />
                      </>
                    )}
                    {shouldHideElement(ASSET_TYPES.ASSIGNMENTS) ? null : (
                      <>
                        {shouldHideElement(ASSET_TYPES.WELCOME_FORM) &&
                        shouldHideElement(ASSET_TYPES.ONBOARDING_MESSAGE) ? null : (
                          <LineIcon className="center" />
                        )}
                        <AssetCard
                          asset={{
                            type: ASSET_TYPES.ASSIGNMENTS,
                            is_active: activeAssets.assignments,
                          }}
                          selectedAsset={selectedAsset}
                          onToggleStatus={() => handleToggleStatus(ASSET_TYPES.ASSIGNMENTS)}
                          onSelect={handleChangeAsset}
                          disabled={(!isSaveDraft && !isEditMode) || isSubmitting}
                          isShowTooltip={isSaveDraft || isEditMode}
                          key={ASSET_TYPES.ASSIGNMENTS}
                          onOpen={handleOpenTriggerModal}
                          handleChangeAsset={handleChangeAsset}
                          hasPermissionUseTrigger={hasPermissionUseTrigger}
                        />
                      </>
                    )}
                    {shouldHideElement(ASSET_TYPES.ON_DEMAND) ? null : (
                      <>
                        {shouldHideElement(ASSET_TYPES.WELCOME_FORM) &&
                        shouldHideElement(ASSET_TYPES.ONBOARDING_MESSAGE) &&
                        shouldHideElement(ASSET_TYPES.ASSIGNMENTS) ? null : (
                          <LineIcon className="center" />
                        )}
                        <AssetCard
                          permission={permission}
                          asset={{
                            type: ASSET_TYPES.ON_DEMAND,
                            is_active: activeAssets.on_demand,
                          }}
                          selectedAsset={selectedAsset}
                          onToggleStatus={() => handleToggleStatus(ASSET_TYPES.ON_DEMAND)}
                          disabled={(!isSaveDraft && !isEditMode) || isSubmitting}
                          isShowTooltip={isSaveDraft || isEditMode}
                          onOpen={handleOpenOnDemandPopup}
                          onSelect={handleChangeAsset}
                          cloudfrontList={cloudfrontList}
                        />
                      </>
                    )}
                    {shouldHideElement(ASSET_TYPES.AUTOFLOW) ? null : (
                      <>
                        {shouldHideElement(ASSET_TYPES.WELCOME_FORM) &&
                        shouldHideElement(ASSET_TYPES.ONBOARDING_MESSAGE) &&
                        shouldHideElement(ASSET_TYPES.ASSIGNMENTS) &&
                        shouldHideElement(ASSET_TYPES.ON_DEMAND) ? null : (
                          <LineIcon className="center" />
                        )}
                        <AssetCard
                          permission={permission}
                          asset={{
                            type: ASSET_TYPES.AUTOFLOW,
                            is_active: activeAssets.autoflow,
                          }}
                          selectedAsset={selectedAsset}
                          onToggleStatus={() => handleToggleStatus(ASSET_TYPES.AUTOFLOW)}
                          onSelect={handleChangeAsset}
                          disabled={(!isSaveDraft && !isEditMode) || isSubmitting}
                          isShowTooltip={isSaveDraft || isEditMode}
                        />
                      </>
                    )}
                  </>
                )}
                {/* Trick: Margin right must be applied on the last div */}
                {(!isSaveDraft || isEditMode || waiting) && <div className="cheat-space-right"></div>}
              </S.AssetsWrapper>
            )}
          </S.Wrapper>
        </S.Container>
      )}
      {tabActive === HEADER_ONBOARDING_FLOW_DETAIL_TABS[1].key && (
        <Settings onboardingFlowId={onboardingFlowId} disabled={!isSaveDraft && !isEditMode} />
      )}
      {!isHideUpgradePath && (
        <UpgradePathFloat onClose={() => hideUpgradePathOnboarding()} permission={permission} teamData={teamData} />
      )}
    </S.MainPage>
  );
};

export default OnboardingFlowDetail;
