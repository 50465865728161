// Libs
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

// Constants
import { pluralize } from 'utils/commonFunction';

// Parts
import { countTotalWarningIssue } from 'shared/TriggerForms/units/helper';

// Styles
import * as S from './styles';

const AllWarningIssueIcon = props => {
  const { tooltipId = 'all-warning-icon-program-tooltip', forumTriggerForms, programTriggerForms } = props;

  const issueTriggerNumber = useMemo(() => countTotalWarningIssue({ forumTriggerForms, programTriggerForms }), [
    forumTriggerForms,
    programTriggerForms,
  ]);

  if (issueTriggerNumber < 1) return null;

  const toBe = issueTriggerNumber === 1 ? 'is' : 'are';
  const noun = pluralize('issue', issueTriggerNumber, true);
  const tooltipContent = `There ${toBe} ${noun} with trigger.`;

  return (
    <S.IconWrapper data-for={tooltipId} data-tip>
      <img src="/images/warning-delete-icon.svg" alt="" />
      <ReactTooltip id={tooltipId} className="all-warning-icon-program-tooltip" place="top" effect="solid">
        {tooltipContent}
      </ReactTooltip>
    </S.IconWrapper>
  );
};

export default AllWarningIssueIcon;
