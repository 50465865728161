import styled from 'styled-components';
import { Modal, Dropdown } from 'semantic-ui-react';
import { FormGroup } from 'shared/FormControl';

export const CreateNewStudioProgramModal = styled(Modal)`
  &.ui.modal {
    width: 449px;
  }

  .header {
    border-radius: 8px;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    color: #202353 !important;
  }

  .content {
    padding: 30px !important;
  }

  .addStudioProgram {
    &__footer {
      text-align: right;
      text-align: right;
      padding-top: 10px;
      button {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
  }

  .addStudioProgram__form {
    .ui.selection.dropdown {
      .text {
        width: calc(100% - 15px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;

  .label__required {
    color: #ea314a;
  }
`;

export const FormControl = styled(FormGroup)`
  margin-bottom: 22px;
  input {
    height: 42px;
    font-weight: normal;
  }

  textarea {
    background: #ffffff;
    border: 1px solid #dcdcde;
    box-sizing: border-box;
    border-radius: 4px;
    color: #323c47;
    font-size: 12px;
    line-height: 18px;
    padding: 5px 15px;
    outline: none !important;
    width: 100%;
    font-weight: normal;
    font-family: Open Sans;
    height: 134px;
    resize: none;

    &::placeholder {
      color: #969fb5;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.error {
    input:hover:not(:disabled),
    input:focus {
      border-color: #ea314a;
    }
  }
`;

export const StudioDropdown = styled(Dropdown)`
  background: #fafbfc42 !important;
  border: 1px solid #dcdcde !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  width: 100%;
  padding: 12px 15px !important;

  .ui.selection.visible.dropdown > .text:not(.default) {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
  }

  .selected,
  .text {
    font-size: 13px;
    line-height: 18px;
    color: #202353;
  }

  .item:not(.selected) {
    .text {
      font-weight: normal;
    }
  }
`;
