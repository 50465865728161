import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import { user } from 'reducers/login';
import home from 'reducers/home';
import { isGettingProfile, failureToGetProfile, errorGetProfile } from 'reducers/profile';
import { sideNavVisible } from 'reducers/sidenav';
import { sideBarVisible } from 'reducers/sidebar';
import { calendarType, calendarStartDate } from 'reducers/calendar';
import { isModalOpen, modal, isConfirmModalOpen, isSecondModalOpen, secondModal, confirmModal } from 'reducers/modal';
import { isShowingError, errorPopup } from 'reducers/error';
import { popup } from 'reducers/popup';
import { workouts, copyingAssignment, movingSet, loggedActivities } from 'reducers/workout';
import bodyMetric from 'reducers/bodyMetric';
import { cloudfrontList } from './cloudfrontList';
import rootReducer from 'redux/index.reducers';
import submitStatus from './submitStatus';
import sectionLibrary from './sectionLibrary';
import groupMetric from './groupMetric';
import { workspace } from './workspace';

const rootBaseReducer = combineReducers({
  user,
  workspace,
  loadingBar: loadingBarReducer,
  home,
  cloudfrontList,
  isGettingProfile,
  failureToGetProfile,
  errorGetProfile,
  calendarType,
  calendarStartDate,
  sideNavVisible,
  sideBarVisible,
  isModalOpen,
  modal,
  isConfirmModalOpen,
  isSecondModalOpen,
  confirmModal,
  secondModal,
  popup,
  isShowingError,
  errorPopup,
  workouts,
  loggedActivities,
  copyingAssignment,
  movingSet,
  rootReducer,
  bodyMetric,
  submitStatus,
  sectionLibrary,
  groupMetric,
});

const appReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return rootBaseReducer(state, action);
};

export default appReducer;
