import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';
import * as S from './styles';
import CustomerInfo from './CustomerInfo';
import PurchaseInfo from './PurchaseInfo';
import InvoiceList from './InvoiceList';
import RefundPaymentPopup from '../RefundPaymentPopup';
import { refundInvoice, getPurchaseDetail } from 'redux/package-analytics/actions';
import { showError } from 'actions/error';
import { CDN_URL } from 'constants/commonData';

function PurchaseDetailPopup(props) {
  const [purchase, setPurchase] = useState(null);
  const [client, setClient] = useState(null);
  const [trainer, setTrainer] = useState(null);

  useEffect(() => {
    fetchDetail();
  }, []);

  const fetchDetail = async () => {
    const { id, getPurchaseDetail, isMP } = props;
    const response = await getPurchaseDetail({ id }, isMP);

    if (response) {
      setPurchase(get(response, 'data.data'));
      setClient(get(response, 'data.data.client'));
      setTrainer(get(response, 'data.data._package.trainer'));
    }
  };

  if (!purchase) {
    return null;
  }

  const handleClose = () => {
    props.toggleModal(false);
    const path = props.isMP ? 'marketplace' : 'packages';
    const packageId = get(purchase, '_package.hash_id') || get(purchase, '_package.id');
    packageId && props.push(`/home/${path}/${packageId}/analytics`);
  };

  const handleChangePurchase = item => {
    setPurchase(item);
  };

  const handleToggleRefundModal = invoice => {
    if (invoice) {
      props.toggleSecondModal(
        true,
        <RefundPaymentPopup
          toggleModal={props.toggleModal}
          toggleSecondModal={props.toggleSecondModal}
          currency={props.account.currency}
          refundInvoice={props.refundInvoice}
          invoice={invoice}
          toggleConfirmModal={props.toggleConfirmModal}
          showError={props.showError}
        />,
      );
    }
  };

  const getDetailCallback = () => {
    fetchDetail();
  };

  return (
    <S.PurchaseDetailPopupWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={handleClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close_circle" />
        </CloseButton>
      }
    >
      <Modal.Header>Customer Information</Modal.Header>
      <Modal.Content>
        {client && <CustomerInfo client={client} coach={trainer} push={props.push} />}
        {purchase.id && (
          <PurchaseInfo
            isMP={props.isMP}
            client={client}
            purchase={purchase}
            onClose={handleClose}
            callback={fetchDetail}
          />
        )}
        {client && (
          <InvoiceList
            isMP={props.isMP}
            client={client}
            defaultPurchase={purchase}
            onChangePackage={handleChangePurchase}
            onToggleRefundModal={handleToggleRefundModal}
            getDetailCallback={getDetailCallback}
          />
        )}
      </Modal.Content>
    </S.PurchaseDetailPopupWrapper>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { setupPayment },
  } = state;
  return {
    account: setupPayment.toJS().account,
  };
};

const actionCreators = {
  toggleModal,
  toggleSecondModal,
  push,
  getPurchaseDetail,
  refundInvoice,
  toggleConfirmModal,
  showError,
};

export default connect(mapStateToProps, actionCreators)(PurchaseDetailPopup);
