import React from 'react';
import ContentLoader, { List } from 'react-content-loader';

export const ListLoader = () => <List />;

export const SettingLoader = (props) => (
  <ContentLoader height="300" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)" {...props}>
    <rect x="0" y="0" rx="1" ry="1" width="100%" height="13" />
    <rect x="0" y="20" rx="1" ry="1" width="100%" height="13" />
    <rect x="0" y="40" rx="1" ry="1" width="100" height="13" />
    <rect x="0" y="83" rx="1" ry="1" width="120" height="13" />
    <rect x="0" y="100" rx="1" ry="1" width="100%" height="80" />
    <rect x="0" y="200" rx="1" ry="1" width="97%" height="13" />
    <rect x="0" y="220" rx="1" ry="1" width="90%" height="13" />
    <rect x="0" y="240" rx="1" ry="1" width="50%" height="13" />
  </ContentLoader>
);

export const WorkooutBackgroudLoader = (props) => (
  <ContentLoader height="135px" width="100%" speed={2} backgroundColor="rgba(237, 237, 241, 0.5)" {...props}>
    <rect x="0" y="0" rx="9" ry="9" width="100%" height="100%" />
  </ContentLoader>
);

export const LogoLoader = (props) => (
  <ContentLoader speed={2} backgroundColor="rgba(237, 237, 241, 0.5)" {...props}>
    <rect x="0" y="0" rx={props.radius} ry={props.radius} width="100%" height="100%" />
  </ContentLoader>
);
