import moment from 'moment';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import classNames from 'classnames';
import { connect } from 'react-redux';
import lowerCase from 'lodash/lowerCase';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { push } from 'connected-react-router';

import {
  CANCEL_REASON,
  CURRENCIES,
  PACKAGE_ANALYTICS_VIEW_TYPE,
  PACKAGE_PRICING_TYPES,
  PURCHASE_STATUS,
  TEXT_CURRENCIES,
} from 'constants/commonData';
import { ReactComponent as Inbox } from 'assets/icons/inbox_sm.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel_invoice_sm.svg';
import { ReactComponent as Pause } from 'assets/icons/payment_pause.svg';
import { ReactComponent as Resume } from 'assets/icons/payment_resume.svg';
import { ReactComponent as PreviewIcon } from 'assets/icons/view_sm.svg';
import StatusLabel from 'components/Package/components/StatusLabel';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import PurchaseDetailPopup from '../../PurchaseDetailPopup';
import CancelSubscriptionPopup from '../../CancelSubscriptionPopup';
import { cancelSubscription, pauseSubscription, resumeSubscription } from 'redux/package-analytics/actions';
import {
  formatDateSafari,
  formatMoney,
  formatSameYear,
  getTrialLeftDays,
  pluralize,
  encryptData,
} from 'utils/commonFunction';
import CancelSubscriptionWithProduct from '../../CancelSubscriptionWithProduct';
import ArchiveClientPopup from '../../ArchiveClientPopup';
import PauseSubscriptionPopup from '../../PauseSubscriptionPopup';
import { isArchiveCoachManualCancel } from 'components/Package/helps';
import { SharedTooltip } from 'shared/SharedTooltip';

import * as S from '../style';

// Open Pause/Resume
const openedPauseResume = process.env.REACT_APP_ENABLE_PAYMENT_PAUSE_RESUME;

const TableContent = props => {
  const { list, account, currentTab, counter = {}, push, isMP, hasPaymentPermission } = props;
  const purchases = useMemo(() => list.toJS(), [list]);
  const selectedItem = useRef(null);
  const cancelOptions = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowPreview = () => {
    const params = new URL(document.location).searchParams;
    const purchase = params.get('preview');
    purchase && props.toggleModal(true, <PurchaseDetailPopup id={purchase} isMP={isMP} />);
  };

  useEffect(() => {
    handleShowPreview();
  }, []);

  const renderTrigger = ({ open }) => (
    <div data-tip data-for="package-detail-tooltip">
      <MenuTrigger vertical active={!!open} />
      <SharedTooltip id="package-detail-tooltip" />
    </div>
  );

  const handleViewDetail = item => () => {
    if (isLoading) {
      return;
    }
    props.toggleModal(true, <PurchaseDetailPopup id={item} isMP={isMP} />);
  };

  const handleInboxClient = item => () => {
    const clientId = item.customer._id;
    const clientName = get(item, 'customer.full_name', '');
    if (isLoading) {
      return;
    }
    if (clientId) {
      push(`/home/inbox?profileId=${clientId}&ufn=${encryptData(clientName)}`);
    }
  };

  const handleConfirmRemoveProduct = status => {
    const { toggleConfirmModal, packageDetail } = props;
    if (!status) {
      handleConfirmCancel({
        ...cancelOptions.current,
        is_unassign_asset: false,
      });
      return toggleConfirmModal(false);
    }
    if (isArchiveCoachManualCancel(packageDetail)) {
      handleConfirmCancel({
        ...cancelOptions.current,
        is_unassign_asset: true,
        is_cancel_with_archive_client: true,
      });
      return toggleConfirmModal(false);
    }
    handleConfirmArchiveClientPopup();
  };

  // Confirm archive client or no
  const handleConfirmCancelAndArchiveClient = status => {
    handleConfirmCancel({
      ...cancelOptions.current,
      is_unassign_asset: true,
      is_cancel_with_archive_client: status,
    });
  };

  // Show popup confirm archive client when remove product
  const handleConfirmArchiveClientPopup = () => {
    const { toggleConfirmModal } = props;
    if (isLoading) {
      return;
    }
    return toggleConfirmModal(true, <ArchiveClientPopup onConfirm={handleConfirmCancelAndArchiveClient} />);
  };

  const handleNextStep = params => {
    const { toggleConfirmModal } = props;
    cancelOptions.current = params;
    if (isLoading) {
      return;
    }
    if (selectedItem.current.is_product && lowerCase(selectedItem.current.status) !== PURCHASE_STATUS.TRIAL) {
      return toggleConfirmModal(true, <CancelSubscriptionWithProduct onConfirm={handleConfirmRemoveProduct} />);
    }
    handleConfirmCancel(params);
  };

  const handleCancel = item => () => {
    const { packageDetail, toggleConfirmModal } = props;
    selectedItem.current = item;
    if (isLoading) {
      return;
    }
    toggleConfirmModal(
      true,
      <CancelSubscriptionPopup onConfirm={handleNextStep} item={item} packageDetail={packageDetail} />,
    );
  };

  const handleConfirmCancel = params => {
    props.cancelSubscription(params, isMP);
    selectedItem.current = null;
  };

  // ========= PAUSE =========
  const handleConfirmPauseSubscription = item => () => {
    const { toggleConfirmModal } = props;
    if (isLoading) {
      return;
    }
    toggleConfirmModal && toggleConfirmModal(true, <PauseSubscriptionPopup handleConfirm={handlePause} item={item} />);
  };

  const handlePause = (item, date) => {
    const { pauseSubscription } = props;
    setIsLoading(true);
    pauseSubscription &&
      pauseSubscription(
        item,
        date,
        () => {
          setIsLoading(false);
        },
        isMP,
      );
  };

  // ========= RESUME =========
  const handleConfirmResumeSubscription = item => () => {
    const { toggleConfirmModal } = props;
    if (isLoading) {
      return;
    }
    toggleConfirmModal &&
      toggleConfirmModal(true, <PauseSubscriptionPopup handleConfirm={handleResume} item={item} isResume />);
  };

  const handleResume = (item, date, type) => {
    const { resumeSubscription } = props;
    setIsLoading(true);
    resumeSubscription &&
      resumeSubscription(
        item,
        type,
        date,
        () => {
          setIsLoading(false);
        },
        isMP,
      );
  };

  if (!purchases.length) {
    return (
      <S.TableBody>
        <S.TableRow className="disabled">
          <S.TableCell colSpan="7">No purchases found</S.TableCell>
        </S.TableRow>
      </S.TableBody>
    );
  }

  const isOneTimePurchase = get(props, 'packageDetail.price.type') === PACKAGE_PRICING_TYPES.one_time.value;
  const isActiveTab = currentTab === PURCHASE_STATUS.ACTIVE;
  const isTrialTab = currentTab === PURCHASE_STATUS.TRIAL;

  const isShowNextInvoice = get(counter, `${PACKAGE_ANALYTICS_VIEW_TYPE.PURCHASE}.trial`, 0) !== 0;

  return (
    <S.TableBody>
      {purchases.map(item => {
        const isTrialStatus = lowerCase(item.status) === PURCHASE_STATUS.TRIAL;
        const trialLeftDays = getTrialLeftDays(get(item, 'ended_trial_at', ''));
        const isTrialEnd = get(item, 'cancel_reason') === CANCEL_REASON.TRIAL_ENDED;

        return (
          <S.TableRow key={item.id} onClick={handleViewDetail(item.id)} data-item={item.id}>
            <S.TableCell>
              <S.CustomerName>{item.customer.full_name}</S.CustomerName>
              <S.CustomerEmail>{item.customer.email}</S.CustomerEmail>
            </S.TableCell>
            <S.TableCell>
              <S.PriceValue>
                {isNil(item.amount)
                  ? '--'
                  : formatMoney(
                      round(item.amount, 2),
                      TEXT_CURRENCIES.includes(account.currency || 'usd')
                        ? `${get(CURRENCIES, `${account.currency || 'usd'}.symbol`)} `
                        : get(CURRENCIES, `${account.currency || 'usd'}.symbol`),
                    )}
              </S.PriceValue>
            </S.TableCell>
            <S.TableCell>
              <S.TableCellContent>{formatSameYear(item.created_at)}</S.TableCellContent>
            </S.TableCell>
            <S.TableCell>
              <S.TableCellContent>
                <StatusLabel status={lowerCase(item.status)}>{item.status}</StatusLabel>
              </S.TableCellContent>
            </S.TableCell>
            {isTrialStatus && (
              <>
                {isActiveTab ? (
                  <>
                    {isOneTimePurchase && isShowNextInvoice ? (
                      <>
                        <S.TableCell>
                          <div>-</div>
                        </S.TableCell>
                        <S.TableCell>
                          <div>{formatSameYear(item.ended_trial_at)}</div>
                          <S.CanceledAt>
                            <div>{pluralize('day', trialLeftDays, true)} left on trial</div>
                          </S.CanceledAt>
                        </S.TableCell>
                      </>
                    ) : (
                      <>
                        <S.TableCell>
                          <div>-</div>
                        </S.TableCell>
                        <S.TableCell>
                          <div>{formatSameYear(item.ended_trial_at)}</div>
                          <S.CanceledAt>
                            <div>{pluralize('day', trialLeftDays, true)} left on trial</div>
                          </S.CanceledAt>
                        </S.TableCell>
                      </>
                    )}
                  </>
                ) : isTrialTab ? (
                  <>
                    <S.TableCell>
                      <S.TableCellContent>{pluralize('day', trialLeftDays, true)}</S.TableCellContent>
                    </S.TableCell>
                    <S.TableCell>
                      <S.TableCellContent>
                        {item.ended_trial_at ? formatSameYear(item.ended_trial_at) : '-'}
                      </S.TableCellContent>
                    </S.TableCell>
                  </>
                ) : null}
              </>
            )}
            {!isTrialStatus && (
              <>
                <S.TableCell>
                  <S.TableCellContent>{item.last_invoice ? formatSameYear(item.last_invoice) : '-'}</S.TableCellContent>
                </S.TableCell>
                {isShowNextInvoice && isOneTimePurchase && (
                  <S.TableCell>
                    <div>-</div>
                  </S.TableCell>
                )}
              </>
            )}
            {!isOneTimePurchase && !isTrialStatus && (
              <S.TableCell>
                {lowerCase(item.status) !== PURCHASE_STATUS.CANCEL && (
                  <>
                    {item.resume_day ? (
                      <div>{item.resume_day ? formatSameYear(formatDateSafari(item.resume_day)) : '-'}</div>
                    ) : (
                      <div>{item.next_invoice ? formatSameYear(item.next_invoice) : '-'}</div>
                    )}
                  </>
                )}
                {lowerCase(item.status) !== PURCHASE_STATUS.CANCEL && (
                  <S.CanceledAt>
                    <div>{item.canceled_at ? `Cancelling on ${formatSameYear(item.canceled_at)}` : null}</div>
                    <div>
                      {item.is_end
                        ? `Ending on ${formatSameYear(formatDateSafari(item.end_day || item.last_invoice))}`
                        : null}
                    </div>
                    <div>
                      {item.resume_day ? `Paused until ${formatSameYear(formatDateSafari(item.resume_day))}` : null}
                    </div>
                  </S.CanceledAt>
                )}
                {lowerCase(item.status) === PURCHASE_STATUS.CANCEL && (
                  <div>
                    {item.is_end && item.current_period_end
                      ? formatSameYear(moment.unix(item.current_period_end))
                      : item.last_invoice && !isTrialEnd
                      ? formatSameYear(item.canceled_at || item.last_invoice)
                      : '-'}
                  </div>
                )}
              </S.TableCell>
            )}
            <S.TableCell className="actions">
              <S.TableCellContent>
                <DropDown triggerIcon={renderTrigger}>
                  <Option
                    key="active-view-detail"
                    onClick={handleViewDetail(item.id)}
                    data-item={item.id}
                    className={classNames(isLoading && 'isLoading')}
                  >
                    <PreviewIcon />
                    <span>Purchase Detail</span>
                  </Option>
                  {[
                    PURCHASE_STATUS.ACTIVE,
                    PURCHASE_STATUS.OVERDUE,
                    PURCHASE_STATUS.TRIAL,
                    PURCHASE_STATUS.PAUSED,
                  ].includes(lowerCase(item.status)) &&
                    !!item.customer._id && (
                      <Option
                        key="active-inbox"
                        onClick={handleInboxClient(item)}
                        data-item={item.id}
                        className={classNames(isLoading && 'isLoading')}
                      >
                        <Inbox />
                        <span>Message Client</span>
                      </Option>
                    )}

                  {openedPauseResume &&
                    !isOneTimePurchase &&
                    [PURCHASE_STATUS.ACTIVE, PURCHASE_STATUS.OVERDUE].includes(lowerCase(item.status)) &&
                    !item.canceled_at &&
                    !!item.next_invoice && (
                      <Option key="active-pause" onClick={handleConfirmPauseSubscription(item)} data-item={item.id}>
                        <Pause />
                        <span>Pause Subscription</span>
                      </Option>
                    )}

                  {openedPauseResume &&
                    !isOneTimePurchase &&
                    [PURCHASE_STATUS.PAUSED].includes(lowerCase(item.status)) &&
                    hasPaymentPermission && (
                      <Option
                        key="active-resume"
                        onClick={handleConfirmResumeSubscription(item)}
                        data-item={item.id}
                        className={classNames(isLoading && 'isLoading')}
                      >
                        <Resume />
                        <span>Resume Subscription</span>
                      </Option>
                    )}
                  {[PURCHASE_STATUS.OVERDUE, PURCHASE_STATUS.ACTIVE, PURCHASE_STATUS.PAUSED].includes(
                    lowerCase(item.status),
                  ) &&
                    !item.canceled_at &&
                    (hasPaymentPermission || !!item.next_invoice) &&
                    !isOneTimePurchase && (
                      <Option
                        key="active-cancel"
                        onClick={handleCancel(item)}
                        data-item={item.id}
                        className={classNames(isLoading && 'isLoading')}
                      >
                        <Cancel />
                        <span>Cancel Subscription</span>
                      </Option>
                    )}
                  {PURCHASE_STATUS.TRIAL.includes(lowerCase(item.status)) && (
                    <Option
                      key="active-cancel"
                      onClick={handleCancel(item)}
                      data-item={item.id}
                      className={classNames(isLoading && 'isLoading')}
                    >
                      <Cancel />
                      <span>Cancel Trial</span>
                    </Option>
                  )}
                  {!isTrialStatus && isOneTimePurchase && !item.canceled_at && (
                    <Option
                      key="active-mask-as-cancel"
                      onClick={handleCancel(item)}
                      data-item={item.id}
                      className={classNames(isLoading && 'isLoading')}
                    >
                      <Cancel />
                      <span>Mark as Cancelled</span>
                    </Option>
                  )}
                </DropDown>
              </S.TableCellContent>
            </S.TableCell>
          </S.TableRow>
        );
      })}
    </S.TableBody>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageAnalytics, packageDetail, setupPayment },
  } = state;
  const currentQuery = packageAnalytics.get('query').toJS();
  const { counter } = packageAnalytics.toJS();
  return {
    list: packageAnalytics.get('purchases'),
    packageDetail: packageDetail.get('workingPackage').toJS(),
    account: setupPayment.toJS().account,
    currentTab: currentQuery.status,
    counter: counter,
  };
};

export default connect(mapState, {
  toggleModal: toggleModal,
  toggleConfirmModal,
  cancelSubscription,
  pauseSubscription,
  resumeSubscription,
  push,
})(TableContent);
