import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Modal } from 'semantic-ui-react';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';
import { Button } from 'shared/FormControl';
import Section from './components/Section';
import { CDN_URL } from 'constants/commonData';

function handleRenderList(workout, viewFilter) {
  let workoutData = workout.sections;

  if (viewFilter) {
    workoutData = [viewFilter];
  }

  return (
    <S.SectionList>
      {workoutData.map((section, sectionIndex) => (
        <Section
          key={section._id}
          section={section}
          nextSectionType={_.get(workout.sections[sectionIndex + 1], 'type')}
        />
      ))}
    </S.SectionList>
  );
}
export default ({ workout, viewFilter, onClose }) => {
  const [element, setElement] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const observer = useRef(
    new IntersectionObserver(
      entries => {
        const firstEntry = entries[0];

        handleScrolling(firstEntry.isIntersecting);
      },
      { threshold: 1 },
    ),
  );

  useEffect(() => {
    const currentElement = element;

    if (currentElement) {
      observer.current.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.current.disconnect();
      }
    };
  }, [element]);

  const handleScrolling = isIntersecting => {
    setScrolling(!isIntersecting);
  };

  return (
    <S.ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalLayout.Wrapper>
          <ModalLayout.Header className={classnames({ scrolling: scrolling })}>{workout.title}</ModalLayout.Header>
          <ModalLayout.Content>
            <div className="checkScrollItem" ref={setElement} />
            {workout.description && workout.description.length && !viewFilter ? (
              <S.WorkoutDesctiptionContainer>
                <label>Description</label>
                <S.Description>{workout.description}</S.Description>
              </S.WorkoutDesctiptionContainer>
            ) : null}
            {handleRenderList(workout, viewFilter)}
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button purple onClick={onClose}>
              Close
            </Button>
          </ModalLayout.Actions>
        </ModalLayout.Wrapper>
      </Modal.Content>
    </S.ModalWrapper>
  );
};
