import React from 'react';
import * as S from './style';
import Header from './Header';
import Content from './Content';

class StudioProgramList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { getListStudioProgram, toggleSideBar } = this.props;
    getListStudioProgram();
    toggleSideBar(true);
  }

  componentWillUnmount() {
    this.props.resetListData();
  }

  render() {
    return (
      <S.Wrapper className="studio-programs-wrapper">
        <Header header />
        <Content />
      </S.Wrapper>
    );
  }
}

export default StudioProgramList;
