// Libs
import React, { useMemo, useState } from 'react';
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, ReferenceLine } from 'recharts';
import classNames from 'classnames';
import moment from 'moment';

// Components
import HourlyChartBarShape from './HourlyChartBarShape';
import HourlyChartTooltip from './HourlyChartTooltip';
import { SLEEP_CORE_STAGES, SLEEP_STAGE_KEYS, SLEEP_VIEW } from './constants';
import { mongoObjectId } from 'utils/commonFunction';
import { handleHourlyTickFormat } from 'components/BodyMetricChartNew/chartHelper';
import CustomGoalDot from '../../CustomGoal';
import { getRangeOfSleep, getStartEndSleeps } from './helpers';
import {
  getDomainsHourlyWithGoal,
  convertGroupSleepToDate,
  convertMinToHour,
  getHourlyGoalValue,
  getFinalDomainsHourly,
  checkDataHasStage,
  checkIsAllInBed,
  checkIsOnlyAsleepInBed,
} from './hourly-helper';

const HourlyChart = props => {
  const { chartData, width = 500, height = 400, bodyMetric, isLoading } = props;
  const isEmpty = chartData.length === 0;
  const { sleepSettings, metricSettings, keyMetricData, target, filterTime } = bodyMetric;
  const { viewBy, isShowStages, connectedApp } = sleepSettings || {};
  const [tooltipPosition, setTooltipPosition] = useState(undefined);
  const isDurationView = viewBy === SLEEP_VIEW.DURATION;

  const finalData = useMemo(() => {
    const hasStage = checkDataHasStage(chartData);
    const isAllBed = checkIsAllInBed(chartData);
    const isOnlyAsleepInBed = checkIsOnlyAsleepInBed(chartData);

    if (!isDurationView) {
      return convertGroupSleepToDate(chartData, filterTime.to_date, hasStage);
    }

    return chartData.map(item => {
      const { startMM, endMM } = getStartEndSleeps(item.grouped_sleep_in_day);
      const result = item.stage_details.reduce(
        (obj, sItem) => {
          obj.totalDuration += sItem.duration;
          if (isShowStages && !isOnlyAsleepInBed) {
            if (sItem.sleep_stage === SLEEP_STAGE_KEYS.in_bed) {
              obj.totalDuration -= sItem.duration;
            }
            obj[sItem.sleep_stage] = (obj[sItem.sleep_stage] || 0) + sItem.duration;
          } else {
            obj.in_bed += sItem.duration;
            if (sItem.sleep_stage !== SLEEP_STAGE_KEYS.in_bed && sItem.sleep_stage !== SLEEP_STAGE_KEYS.awake) {
              obj.asleep += sItem.duration;
            }
          }
          return obj;
        },
        { in_bed: 0, asleep: 0, totalDuration: 0 },
      );
      const dataType = [];

      if (isShowStages && !isOnlyAsleepInBed) {
        if (isAllBed && result.in_bed) {
          dataType.push({
            type: SLEEP_STAGE_KEYS.in_bed,
            value: convertMinToHour(result.totalDuration),
            time: result.totalDuration,
          });
        }
        SLEEP_CORE_STAGES.forEach(stage => {
          if (result[stage]) {
            dataType.push({
              type: stage,
              value: convertMinToHour(result[stage]),
              time: result[stage],
            });
          }
        });
      } else {
        if (hasStage) {
          dataType.push({
            type: SLEEP_STAGE_KEYS.in_bed,
            value: convertMinToHour(result.in_bed - result.asleep),
            time: result.in_bed,
          });
        }

        if (result.asleep) {
          dataType.push({
            type: SLEEP_STAGE_KEYS.asleep,
            value: convertMinToHour(result.asleep),
            time: result.asleep,
          });
        }
      }
      const _id = mongoObjectId();
      const date = moment(item.end, 'YYYY-MM-DD').toISOString();

      return {
        ...item,
        _id,
        type: _id,
        date,
        group_name: date,
        rangeValues: isDurationView
          ? [0, convertMinToHour(isShowStages ? result.totalDuration : result.in_bed)]
          : getRangeOfSleep(startMM, endMM, filterTime.to_date),
        dataType: dataType,
      };
    });
  }, [chartData, isDurationView, isShowStages, connectedApp, filterTime.to_date]);

  const goalValue = useMemo(() => {
    return getHourlyGoalValue(metricSettings, isDurationView, target);
  }, [keyMetricData, metricSettings, target, isDurationView]);

  const getDomainFromFinalData = (data = []) => {
    const allValues = data.flatMap(item => item.rangeValues);
    const minValue = Math.min(...allValues);
    const maxValue = Math.max(...allValues);
    return [minValue, maxValue];
  };

  const [data, domains] = useMemo(() => {
    let domains = getDomainFromFinalData(finalData) || [0, 0];

    if (finalData.length === 0) {
      if (!goalValue) {
        return [[], isDurationView ? [0, 8] : [11, 19]];
      }

      if (goalValue !== null) {
        const ranges = isDurationView ? [0, goalValue] : [Math.floor(goalValue), Math.floor(goalValue) + 8];
        domains = getDomainsHourlyWithGoal(ranges[0], ranges[1], goalValue, !isDurationView);
      }
      domains = getFinalDomainsHourly(domains, isDurationView);
      return [[], domains];
    }

    if (goalValue !== null) {
      const [min, max] = domains;
      domains = getDomainsHourlyWithGoal(min, max, goalValue, !isDurationView);
    }
    domains = getFinalDomainsHourly(domains, isDurationView);

    return [finalData, domains];
  }, [finalData, isShowStages, tooltipPosition, goalValue, connectedApp, isDurationView]);

  const tickValues = useMemo(() => {
    if (!domains) return undefined;
    const gap = domains[1] - domains[0];
    const step = gap === 24 ? 6 : Number(Math.floor(gap / 5) + 1);
    return Array(5)
      .fill(0)
      .map((_, index) => domains[0] + index * step);
  }, [domains]);

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className={classNames('metric-chart sleep-hourly-chart', { 'no-data': isEmpty })}
    >
      {!isLoading && (
        <BarChart
          width={width}
          height={height}
          data={data}
          className="recharts-surface-default"
          layout="vertical"
          barSize={20}
          padding={{ left: 30, right: 30 }}
        >
          <XAxis
            dy={5}
            padding={{ left: 5, right: 5 }}
            type="number"
            domain={domains}
            tickFormatter={v => handleHourlyTickFormat(v, !isDurationView)}
            interval={0}
            tickCount={5}
            ticks={tickValues}
          />
          <YAxis type="category" hide dataKey="date" />
          <Tooltip
            isAnimationActive={false}
            cursor={false}
            active={!!tooltipPosition}
            content={<HourlyChartTooltip sleepSettings={sleepSettings} payloadTooltip={tooltipPosition} />}
            coordinate={tooltipPosition}
            dataPayload={(tooltipPosition || {}).payload}
            wrapperStyle={{ ...(tooltipPosition || {}), position: 'fixed', transform: 'none', zIndex: 1000 }}
          />
          <Bar
            dataKey="rangeValues"
            shape={<HourlyChartBarShape setTooltipPosition={setTooltipPosition} sleepSettings={sleepSettings} />}
          />
          {goalValue !== null && (
            <ReferenceLine
              x={goalValue}
              stroke="#A3A3B5"
              label={<CustomGoalDot isSleep className="sleep-goal-line" />}
            />
          )}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default HourlyChart;
