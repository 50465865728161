import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleConfirmModal } from 'actions/modal';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import { SharedTooltip } from 'shared/SharedTooltip';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

function DropdownWorkoutItem(props) {
  const handleRenderOption = () => {
    const id = _.get(props, 'workoutCollection._id', '');
    const content = <p>Would you like to remove access of this workout collection for this client?</p>;

    return (
      <Option
        key="delete"
        onClick={e => {
          props.toggleConfirmModal(
            true,
            <S.ConfirmModal
              headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
              newStyle
              confirmButtonTitle="Remove"
              title="Remove"
              content={content}
              onConfirm={() => props.onRemove(id)}
            />,
          );
        }}
      >
        <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
        <span>Remove</span>
      </Option>
    );
  };

  return (
    <S.DropdownWrapper>
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="client-workout-collection-item-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="client-workout-collection-item-options-tooltip" />
          </div>
        )}
      >
        {handleRenderOption()}
      </DropDown>
    </S.DropdownWrapper>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToprops = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToprops)(DropdownWorkoutItem);
