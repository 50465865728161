import React, { Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import moment from 'moment';

import LoadingIndicator from 'shared/LoadingIndicator';
import { AllEntriesSleepByDay } from '../AllEntriesByDayModal/AllEntriesSleep';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-select-down-middle.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-grey.svg';
import { renderDuration, renderTime } from './sleep-helper';
import '../styles.scss';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export function formatDateChild(dateString, timezone) {
  if (!dateString) return '';
  const date = DateTime.fromISO(dateString, { zone: timezone, locale: 'en-US' });
  const now = DateTime.local().setZone(timezone);

  const month = date.toLocaleString({ month: 'short' });
  const day = date.toLocaleString({ day: '2-digit' }).replace(/^0/, '');
  const year = date.toLocaleString({ year: 'numeric' });
  if (date.year !== now.year) {
    return `${month} ${day}, ${year}`;
  }
  return `${month} ${day}`;
}

const isSameCurrentYear = time => {
  if (!time) return;
  const currentDate = DateTime.now();
  return currentDate.year === time.year;
};

const SleepMetricProgress = props => {
  const {
    selectedMetric,
    timezone,
    metricTypes,
    toggleModal,
    toggleConfirmModal,
    dateFormat,
    clientId,
    getBodyMetricTarget,
    updateBodyMetricEntry,
    isViewByWeek,
    isLoading,
    viewingDetailGroupId,
    progressList,
    previous_day,
    confirmRemoveEntry,
    updateSleepEntry,
    isShowAddResultPopup,
  } = props;

  const renderEntryItem = (item, index) => {
    const date = DateTime.fromISO(moment(item.end, 'YYYY-MM-DD').toISOString()).toLocal();
    const isSameYear = isSameCurrentYear(date);
    let dateText = date.toFormat(isSameYear ? 'MMM d' : 'MMM d, yyyy');

    if (date.toFormat('MM-dd-yyyy') === DateTime.local().setZone(timezone).toFormat('MM-dd-yyyy')) {
      dateText = 'Today';
    }

    return (
      <Table.Row
        key={[item.start, item.end, index].join('-')}
        className="row-item sleep-metric-item"
        onClick={openEntriesByDayModal(item, true)}
      >
        <Table.Cell className="date">
          <div>{dateText}</div>
        </Table.Cell>

        <Table.Cell className="value">
          <div>{renderDuration(item.duration)}</div>
        </Table.Cell>
        <Table.Cell className="value">
          <div>{renderTime(item)}</div>
        </Table.Cell>

        <Table.Cell>
          <div className="actions">
            <button className="btn-icon btn-right-arrow">
              <ArrowRightIcon width={13} />
            </button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const openEntriesByDayModal = item => () => {
    if (isShowAddResultPopup) return;

    toggleModal(
      true,
      <AllEntriesSleepByDay
        selectedEntry={item}
        toggleModal={toggleModal}
        toggleConfirmModal={toggleConfirmModal}
        timezone={timezone}
        selectedMetric={selectedMetric}
        metricTypes={metricTypes}
        dateFormat={dateFormat}
        clientId={clientId}
        getBodyMetricTarget={getBodyMetricTarget}
        updateBodyMetricEntry={updateBodyMetricEntry}
        isViewByWeek={isViewByWeek}
        confirmRemoveEntry={confirmRemoveEntry}
        updateSleepEntry={updateSleepEntry}
      />,
    );
  };

  const renderChildItem = (item, weekId) => {
    const isOpening = viewingDetailGroupId === weekId;
    const date = formatDateChild(moment(`${item.end} ${item.end_time}`, DATE_TIME_FORMAT).toISOString(), timezone);

    return (
      <Table.Row
        key={classNames(item.start, item.end)}
        className={classNames('row-item child-item sleep-sub-item', {
          'child-opening': isOpening,
        })}
        onClick={openEntriesByDayModal(item)}
      >
        <Table.Cell className="date">
          <div>{date}</div>
        </Table.Cell>
        <Table.Cell className="value">
          <div>{renderDuration(item.duration)}</div>
        </Table.Cell>
        <Table.Cell className="value">
          <div>{renderTime(item)}</div>
        </Table.Cell>

        <Table.Cell>
          <div className="actions progress-child-item">
            <button className="btn-icon btn-right-arrow">
              <ArrowRightIcon width={13} />
            </button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderEntryGroup = (item, index) => {
    const { viewingDetailGroupId, updateDetailGroupId } = props;
    const isOpening = viewingDetailGroupId === item.start;
    const start = DateTime.fromISO(item.start).toLocal();
    const end = DateTime.fromISO(item.end).toLocal();
    const startIsSameCurrentYear = isSameCurrentYear(start);
    const endIsSameCurrentYear = isSameCurrentYear(end);
    const startAndEndIsSameYear = start.year === end.year;
    const dateDetails = [...(item.date_details || [])].reverse();

    return (
      <Fragment key={`${item.start}-${item.end}-${index}`}>
        <Table.Row
          className={classNames('row-item collapse-item sleep-metric-item', { opening: isOpening })}
          onClick={updateDetailGroupId(item.start)}
        >
          <Table.Cell className="date">
            <div>
              {start.toFormat(startIsSameCurrentYear ? 'MMM d' : startAndEndIsSameYear ? 'MMM d' : 'MMM d, yyyy')} -{' '}
              {end.toFormat(endIsSameCurrentYear ? 'MMM d' : 'MMM d, yyyy')}
            </div>
          </Table.Cell>
          <Table.Cell className="value">
            <div>{renderDuration(item.duration)}</div>
          </Table.Cell>
          <Table.Cell className="value">
            <div>{renderTime(item)}</div>
          </Table.Cell>
          <Table.Cell>
            <div className="actions progress-item">
              <button className="btn-icon btn-arrow">
                <ArrowDownIcon width={13} />
              </button>
            </div>
          </Table.Cell>
        </Table.Row>
        {dateDetails.map(child => renderChildItem(child, item.start))}
      </Fragment>
    );
  };

  const renderEntry = (item, index) => {
    if (isViewByWeek) {
      return renderEntryGroup(item, index);
    }
    return renderEntryItem(item);
  };

  const showLoading = progressList.length > 20 && !!previous_day && isLoading;

  return (
    <>
      {progressList.length > 0 ? (
        <div className="progress__body">
          <Table singleLine className={classNames('progress-table-head normal-metric-head sleep-metric')}>
            <Table.Header className="progress-table__head">
              <Table.Row>
                <Table.HeaderCell className="w-112 date">DATE</Table.HeaderCell>
                <Table.HeaderCell className="head-time">
                  {isViewByWeek ? 'Weekly avg. duration' : 'Total Duration'}
                </Table.HeaderCell>
                <Table.HeaderCell className="result">
                  {isViewByWeek ? 'Weekly avg. sleep time' : 'Sleep time'}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          <div className="result-list">
            <Table singleLine className="progress-table__content normal-metric-content">
              <Table.Body>
                {progressList.map(renderEntry)}
                {showLoading && (
                  <Table.Row className="row-item">
                    <Table.Cell colSpan={isViewByWeek ? 3 : 4} textAlign="center" verticalAlign="middle">
                      <LoadingIndicator title="" className="progress-load-more" />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      ) : (
        <>{!isLoading && <p className="no-result">No entries yet</p>}</>
      )}
    </>
  );
};

export default SleepMetricProgress;
