import React, { useEffect, useMemo, useState, useRef } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Modal, Button, Image, Icon } from 'semantic-ui-react';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { toggleModal } from 'actions/modal';

const ResumeOptions = ({ toggleModal, data, onConfirmResume }) => {
  const [optionSelected, setOptionSelected] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [isChooseDay, setChooseDay] = useState(false);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [resumeOptions, setResumeOptions] = useState([]);
  const [startWeek, setStartWeek] = useState(1);
  const [listDays, setListDays] = useState([]);
  const [isError, setError] = useState(false);

  const ref = useRef();

  // Handle click out choose day
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpenCalendar && ref.current && !ref.current.contains(e.target)) {
        setOpenCalendar(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpenCalendar]);

  useEffect(() => {
    const { paused_on, weeks } = data;
    let viewOptions = [{ value: paused_on, label: `Day the client was paused (Day ${paused_on})` }];

    if (paused_on < weeks * 7) {
      viewOptions.push({ value: paused_on + 1, label: 'Day after the client was paused' });
    }

    if (paused_on > 1) {
      viewOptions.push({ value: paused_on - 1, label: 'Day before the client was paused' });
    }

    viewOptions.push({ value: 'custom', label: 'Custom' });

    setResumeOptions(_.unionBy(viewOptions, 'value'));
  }, []);

  useEffect(() => {
    setOptionSelected(resumeOptions[0]);
  }, [resumeOptions]);

  useEffect(() => {
    if (optionSelected && optionSelected.value === 'custom') {
      setChooseDay(true);
    } else {
      setChooseDay(false);
      setOpenCalendar(false);
    }
  }, [optionSelected]);

  useEffect(() => {
    const { weeks } = data;
    const days = Array.from(new Array(weeks > 4 ? 28 : weeks * 7)).map((_, index) => {
      let startIndex = startWeek;
      if (startWeek > 1) {
        if (startWeek > 2) {
          startIndex = (startIndex - 1) * 7 + index;
        } else {
          startIndex = 7 + index;
        }
      } else {
        startIndex = index;
      }
      const value = startIndex + 1 > 9 ? startIndex + 1 : `0${startIndex + 1}`;
      return {
        value: value,
        label: value,
      };
    });
    setListDays(days);
  }, [startWeek]);

  const handleSelected = item => {
    setError(false);
    setOptionSelected(item);
  };

  const handleSelectDay = day => event => {
    event.stopPropagation();
    setSelectedDay(day);
    setOpenCalendar(false);
  };

  const handleOpenCalendar = () => {
    setError(false);
    setOpenCalendar(!isOpenCalendar);
  };

  const handleOnClose = () => {
    toggleModal(false);
  };

  const handleResume = () => {
    const { paused_on } = data;
    let resume = paused_on;
    if (optionSelected.value === 'custom') {
      if (!selectedDay) {
        setError(true);
        return;
      }
      resume = selectedDay;
    } else {
      resume = optionSelected.value;
    }
    onConfirmResume && onConfirmResume(data, parseInt(resume));
  };

  const titleOfWeek = useMemo(() => {
    const { weeks } = data;
    // Total weeks < 4: Week 1 - total_week of total_week
    // Total weeks > 4: Week start_week - start_week + 3 of total_week
    return weeks > 4 ? `Week ${startWeek} - ${startWeek + 3} of ${weeks}` : `Week 1 - ${weeks} of ${weeks}`;
  }, [startWeek, data]);

  const handleNextWeek = () => {
    !disabledNextWeek && setStartWeek(startWeek + 1);
  };

  const handlePrevWeek = () => {
    !disabledPrevWeek && setStartWeek(startWeek - 1);
  };

  const disabledPrevWeek = useMemo(() => {
    return startWeek <= 1;
  }, [startWeek]);

  const disabledNextWeek = useMemo(() => {
    const { weeks } = data;
    return startWeek + 3 >= weeks;
  }, [startWeek]);

  return (
    <S.ModalWrapper
      open={true}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onClose={handleOnClose}
      className="autoflow-action-resume-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Wrapper>
          <S.Title>Resume Client in Autoflow</S.Title>
          <S.Desc>
            Choose the day you would like to resume your client on for this Autoflow. It can be the day you paused the
            client or any other day.
          </S.Desc>
          <S.Label>resume on</S.Label>
          <S.DropdownWrapper>
            <Select
              options={resumeOptions}
              components={{ IndicatorSeparator: null }}
              onChange={handleSelected}
              value={optionSelected}
              classNamePrefix="evfSelectBox"
              className="evfSelectBoxContainer"
              isSearchable={false}
              menuPosition="fixed"
              styles={{
                control: base => ({ ...base, minHeight: 42, height: 42, width: 298 }),
              }}
            />
            {isChooseDay && (
              <S.DayPicker className={`${isOpenCalendar && 'opened'} ${isError && 'warning'}`} ref={ref}>
                <S.SelectedDay onClick={handleOpenCalendar}>
                  <span>{selectedDay ? `Day ${selectedDay}` : 'Choose Day'}</span>
                  <Icon className="calendar outline" />
                </S.SelectedDay>
                {isOpenCalendar && (
                  <S.Calendar>
                    <S.CalendarTitle>Choose Day to Resume on</S.CalendarTitle>
                    <S.CalendarActions>
                      <Icon className="chevron left" onClick={handlePrevWeek} disabled={disabledPrevWeek} />
                      <S.Weeks>{titleOfWeek}</S.Weeks>
                      <Icon className="chevron right" onClick={handleNextWeek} disabled={disabledNextWeek} />
                    </S.CalendarActions>
                    <S.DaysOfWeeks>
                      {listDays.map((day, index) => (
                        <S.Day
                          key={`${index + '_day'}`}
                          onClick={handleSelectDay(day.value)}
                          className={`${day.value === selectedDay && 'selected'}`}
                        >
                          {day.value}
                        </S.Day>
                      ))}
                    </S.DaysOfWeeks>
                  </S.Calendar>
                )}
              </S.DayPicker>
            )}
          </S.DropdownWrapper>
          <S.Footer>
            <S.ResumeAction onClick={handleResume}>Resume</S.ResumeAction>
          </S.Footer>
        </S.Wrapper>
      </Modal.Content>
    </S.ModalWrapper>
  );
};

export default connect(null, { toggleModal })(ResumeOptions);
