import React, { useMemo, useState } from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';
import { diff } from 'deep-diff';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

import { pluralize } from 'utils/commonFunction';

import { Checkbox } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import { CustomConfirmModal } from 'components/BodyMetricChartNew/components/ChartSettings/style';

import { CDN_URL } from 'constants/commonData';

import { getUnitObject } from '../constant';
import * as S from '../style';

const AddMetricsModal = props => {
  const { group = {}, metricsNormalized = {}, onClose, addMetricsToGroup, toggleConfirmModal, clientId } = props;
  const { metrics = [] } = group;

  const [metricsSelected, setMetricsSelected] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  const hasChanged = useMemo(() => {
    return !isEmpty(metricsSelected);
  }, [metricsSelected]);

  const availableMetrics = useMemo(
    () => Object.values(metricsNormalized).filter(metric => !metrics.includes(metric._id)),
    [metricsNormalized, metrics],
  );

  const availableAndSearchedMetrics = useMemo(
    () => availableMetrics.filter(metric => metric.name.toLowerCase().trim().includes(textSearch)),
    [availableMetrics, textSearch],
  );

  const isSelectedAllMetric = useMemo(() => {
    if (isEmpty(metricsSelected)) return false;

    if (textSearch) {
      return availableAndSearchedMetrics.every(item => metricsSelected.includes((item || {})._id));
    }

    return availableMetrics.every(item => metricsSelected.includes((item || {})._id));
  }, [metricsSelected, availableMetrics, availableAndSearchedMetrics, textSearch]);

  const isEmptyMetrics = isEmpty(availableAndSearchedMetrics);
  const availableOriginMetric = useMemo(() => {
    return metrics.filter(it => Object.keys(metricsNormalized).includes(it._id));
  }, [metrics, metricsNormalized]);

  const isDisableAdd = useMemo(() => isEmptyMetrics || !diff(availableOriginMetric, metricsSelected), [
    isEmptyMetrics,
    metricsSelected,
    availableOriginMetric,
  ]);

  const handleSearch = (__, { value }) => {
    const trimmed = value.toLowerCase().trim();
    if (trimmed !== textSearch) {
      setTextSearch(trimmed);
    }
  };

  const searchDebounce = debounce(handleSearch, 300);

  const handleClearSearch = () => {
    setTextSearch('');
  };

  const handleAdd = () => {
    const params = {
      client: clientId,
      groupId: group._id,
      data: [{ group: group._id, metrics: metricsSelected }],
    };
    addMetricsToGroup(params);
    onClose();
  };

  const handleAllSelectMetric = () => {
    if (textSearch) {
      const metricIds = map(availableAndSearchedMetrics, '_id');

      if (isSelectedAllMetric) {
        setMetricsSelected(prevSelect => uniq(prevSelect.filter(id => !metricIds.includes(id))));
      } else {
        setMetricsSelected(prevSelect => uniq([...prevSelect, ...metricIds]));
      }

      return;
    }

    if (isSelectedAllMetric) {
      setMetricsSelected([]);
    } else {
      setMetricsSelected(map(availableMetrics, '_id'));
    }
  };

  const toggleSelectMetric = (id, checked) => () => {
    if (checked) {
      setMetricsSelected(prevSelect => [...prevSelect, id]);
    } else {
      setMetricsSelected(prevSelect => prevSelect.filter(it => it !== id));
    }
  };

  const handleSelectMetric = id => event => {
    event.stopPropagation();

    if (!id || !event) return;
    const checked = (event.target || {}).checked || false;

    toggleSelectMetric(id, checked)();
  };

  const renderHeaderTable = () => {
    return (
      <thead>
        <tr>
          <th className="metrics">
            <div className="col-header">
              <div className="metrics-column-wrapper checkbox-wrapper">
                <div
                  className="metrics-checkbox-header-wrapper"
                  onClick={event => {
                    event.preventDefault();
                    handleAllSelectMetric();
                  }}
                >
                  <Checkbox
                    checked={isSelectedAllMetric}
                    className="metrics-checkbox-header"
                    onChange={event => {
                      event.preventDefault();
                      handleAllSelectMetric();
                    }}
                    size={20}
                  />
                </div>
                <div className="col-title">metrics</div>
              </div>
            </div>
          </th>
          <th className="type">
            <div className="col-header ">
              <div className="col-title">type</div>
            </div>
          </th>
          <th className="unit">
            <div className="col-header ">
              <div className="col-title">unit</div>
            </div>
          </th>
        </tr>
      </thead>
    );
  };

  const renderItemMetric = (it = {}) => {
    const { _id, name = '', category: { name: categoryName = '' } = {} } = it;
    const unit = getUnitObject(it);
    const isSelectedMetric = metricsSelected.includes(_id);

    return (
      <tr key={_id} onClick={toggleSelectMetric(_id, !isSelectedMetric)}>
        <td>
          <div
            className="metrics-column-wrapper checkbox-wrapper"
            onClick={event => {
              event.preventDefault();
              handleSelectMetric(_id);
            }}
          >
            <div className="metrics-checkbox-header-wrapper">
              <Checkbox
                checked={isSelectedMetric}
                className="metrics-checkbox-header"
                onChange={handleSelectMetric(_id)}
                size={20}
              />
            </div>
            <div className="col-value name">{name}</div>
          </div>
        </td>
        <td>
          <div className="col-value type">{categoryName}</div>
        </td>
        <td>
          <div className="col-value unit">{unit.title || '--'}</div>
        </td>
      </tr>
    );
  };
  const handleCloseModal = () => {
    if (!hasChanged) {
      onClose();
      return;
    }
    toggleConfirmModal(
      true,
      <CustomConfirmModal
        onConfirm={onClose}
        onDeny={() => toggleConfirmModal(false)}
        headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        confirmButtonTitle="Discard Changes"
        title="Discard Changes?"
        noBorder
        hasCloseIcon
      />,
    );
  };

  return (
    <S.CustomModalAddMetric
      open
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <S.ModalAddMetricHeaderTitle>Add Metrics</S.ModalAddMetricHeaderTitle>
        <NewSearchInput
          autoFocus={false}
          focusAfterClear
          placeholder="Search by keyword or name"
          onChange={searchDebounce}
          onClearSearch={handleClearSearch}
        />
      </Modal.Header>
      <Modal.Content>
        {isEmptyMetrics && !textSearch && (
          <S.ModalAddMetricEmpty className="no-available">
            <div className="empty-label">No metrics available.</div>
          </S.ModalAddMetricEmpty>
        )}
        {isEmptyMetrics && textSearch && (
          <>
            {textSearch && (
              <S.ModalAddMetricWrapper className="not-found">
                <table>{renderHeaderTable()}</table>
              </S.ModalAddMetricWrapper>
            )}
            <S.ModalAddMetricEmpty>
              <div className="empty-label">{isEmpty(textSearch) ? 'No metrics available.' : 'No results found.'}</div>
            </S.ModalAddMetricEmpty>
          </>
        )}
        {!isEmptyMetrics && (
          <S.ModalAddMetricWrapper>
            <table>
              {renderHeaderTable()}
              <tbody>{availableAndSearchedMetrics.map(renderItemMetric)}</tbody>
            </table>
          </S.ModalAddMetricWrapper>
        )}
      </Modal.Content>
      <Modal.Actions>
        <S.ModalAddMetricFooter>
          {metricsSelected.length > 0 && (
            <S.MetricSelected>
              <span className="count">{metricsSelected.length}</span>
              <span className="label">{`${pluralize('Metric', metricsSelected.length)} selected`}</span>
            </S.MetricSelected>
          )}
          <S.ButtonAdd disabled={isDisableAdd} onClick={handleAdd}>
            Add
          </S.ButtonAdd>
        </S.ModalAddMetricFooter>
      </Modal.Actions>
    </S.CustomModalAddMetric>
  );
};

export default AddMetricsModal;
