import React, { useEffect, useRef } from 'react';
import { Bar, XAxis, YAxis, BarChart, ReferenceLine } from 'recharts';
import { convertSeconds, getNewDataMinMax } from './constants';
import HeartRateBarShape from './HeartRateBarShape';
import { getTicks } from 'components/BodyMetricChartNew/chartHelper';
import classNames from 'classnames';

import * as S from './style';

const ChartComponent = props => {
  const { heartRateData } = props;
  const chartRef = useRef();
  const { min = 0, max = 0, entries = [], zones = [] } = heartRateData;
  const gapOxStep = entries[entries.length - 1].time - entries[entries.length - 2].time;
  const minZone5 = zones[4].min;
  const maxZone1 = zones[0].max;

  useEffect(() => {
    if (chartRef.current) {
      try {
        const selectors = document.querySelectorAll(
          '.recharts-yAxis .recharts-cartesian-axis-ticks .recharts-text.recharts-cartesian-axis-tick-value tspan',
        );
        const referenceLine = document.querySelector('.recharts-layer.recharts-reference-line.last-item line');
        const lastItem = selectors[selectors.length - 1];
        if (lastItem) lastItem.setAttribute('dy', 0);
        if (referenceLine) {
          referenceLine.setAttribute('x1', 485);
          referenceLine.setAttribute('x2', 485);
        }
      } catch (error) {}
    }
  }, [chartRef.current]);

  const renderStopTags = () => {
    if (max < zones[0].max) {
      return <stop offset={`100%`} stopColor="#02ABF4" stopOpacity="1" />;
    }
    if (min > zones[4].min) {
      return <stop offset={`100%`} stopColor="#FC4444" stopOpacity="1" />;
    }
    return (
      <>
        <stop offset={`0%`} stopColor="#FC4444" stopOpacity="1" />
        <stop offset={`25%`} stopColor="#FF865A" stopOpacity="1" />
        <stop offset={`50%`} stopColor="#FFC150" stopOpacity="1" />
        <stop offset={`75%`} stopColor="#4DD44D" stopOpacity="1" />
        <stop offset={`100%`} stopColor="#02ABF4" stopOpacity="1" />
      </>
    );
  };

  const entriesMap = entries.map(entry => ({
    ...entry,
    label: convertSeconds(entry.time),
    _id: entry.time,
    range: [entry.min, entry.max],
  }));

  const [newDataMin, newDataMax, tickCount] = getNewDataMinMax(min, max);

  const domain = [newDataMin, newDataMax];

  const ticks = getTicks(newDataMin, newDataMax, tickCount);

  const CustomizedAxisTick = chartProps => {
    const { x, y, payload, index, visibleTicksCount } = chartProps;

    if (index === 0) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={20} fill="#1E0A3C" textAnchor="middle">
            0m
          </text>
        </g>
      );
    }

    if (index % 10 === 0) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={20} fill="#1E0A3C" textAnchor="middle">
            {convertSeconds(payload.value)}
          </text>
        </g>
      );
    }

    if (index === visibleTicksCount - 1) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={7} y={0} dy={20} fill="#1E0A3C" textAnchor="middle">
            {convertSeconds(gapOxStep * 40)}
          </text>
        </g>
      );
    }

    return null;
  };

  return (
    <S.ChartWrapper>
      <BarChart
        width={510}
        height={255}
        data={entriesMap}
        margin={{ top: 0, bottom: 0, right: 30, left: 0 }}
        ref={chartRef}
      >
        <defs>
          <linearGradient
            id="colorUv"
            x1="0"
            y1={`${((newDataMax - minZone5) / (newDataMax - newDataMin)) * 255}`}
            x2="0"
            y2={`${255 - ((maxZone1 - newDataMin) / (newDataMax - newDataMin)) * 255}`}
            gradientUnits="userSpaceOnUse"
          >
            {renderStopTags()}
          </linearGradient>
        </defs>
        <XAxis
          dataKey="time"
          axisLine={false}
          tickLine={false}
          tickFormatter={(tick, index) => (index % 40 === 0 ? tick : '')}
          tick={CustomizedAxisTick}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          domain={domain}
          ticks={ticks}
          tickCount={tickCount}
          allowDataOverflow
          tick={{ dx: -12, dy: 0 }}
        />
        {entriesMap.map((entry, index) => {
          if (entry.time === 0 || index % 10 === 0 || index === entriesMap.length - 1)
            return (
              <ReferenceLine
                key={entry._id}
                x={entry.time}
                stroke="#000000"
                opacity={0.1}
                strokeWidth={1}
                className={classNames({ 'last-item': index === entriesMap.length - 1 })}
              />
            );
          return null;
        })}
        <Bar dataKey="range" shape={<HeartRateBarShape />} fill="url(#colorUv)" />
      </BarChart>
    </S.ChartWrapper>
  );
};

export default ChartComponent;
