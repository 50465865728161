import { Types } from './actions';

export const INITIAL_CATEGORY_LABELS = {
  list: [],
};

export default (state = INITIAL_CATEGORY_LABELS, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.CATEGORY_GET_LABELS_SUCCESS: {
      return Object.assign({}, state, { list: payload.data });
    }
    default:
      return state;
  }
};
