import React, { useState } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { toggleConfirmModal } from 'actions/modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  margin-bottom: 5px;
  .nameInput__editor {
    padding: 10px 15px;
    width: 100%;
    /* box-sizing: border-box; */
    border-radius: 3px;
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 23px;
    line-height: 130%;
    color: #202353;
    outline: none;

    :hover {
      background: #f1f1f3;
    }

    :focus {
      border: 1px solid #5158cf;
      background: #ffffff;
    }

    ${props =>
      props.hasError &&
      css`
        background: #fef4f6 !important;
        border: 1px solid #ea314a !important;
      `}
  }

  .nameInput__warning {
    padding: 5px 15px;

    &--label {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
    }

    &--text {
      color: #ea314a;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const limitLength = 30;

function ProgramNameEditable(props) {
  const { disabled = false } = props;
  const [name, setName] = useState(props.name || '');
  const [showError, setShowError] = useState(false);

  const onChange = e => {
    if (disabled) return;
    const value = e.target.value;
    if (value.length <= 30) {
      setName(value);
    } else {
      setName(_.truncate(value, { length: 30, omission: '' }));
      flashError();
    }
  };

  const flashError = _.debounce(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 200);
  }, 300);

  const onBlur = () => {
    if (name && name.length <= 30) {
      props.onConfirmChange({ program_name: name }, 'title');
    }
  };

  const onKeyPress = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  return (
    <Wrapper hasError={!name || name.length > 30 || showError} disabled={disabled}>
      <input
        value={name}
        onChange={onChange}
        placeholder="Add program name"
        onBlur={onBlur}
        className="nameInput__editor"
        onKeyPress={onKeyPress}
        disabled={disabled}
      />
      {name.length > 25 && (
        <div className="nameInput__warning">
          <span className="nameInput__warning--label">Characters:</span>
          <span className="nameInput__warning--text">
            {' '}
            {name.length}/{limitLength}
          </span>
        </div>
      )}
    </Wrapper>
  );
}

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(null, mapDispatch)(ProgramNameEditable);
