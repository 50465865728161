// Lib
import React from 'react';
import get from 'lodash/get';

// Share
import MainPanelSkeleton from './MainPanelSkeleton';
import RecipeItems from 'components/Recipes/Parts/RecipeItems';

// Assets
import { ReactComponent as BlankIcon } from 'assets/icons/MealPlans/RecipeCategories/lunch.svg';

import * as S from './style';

const MainPanelContent = props => {
  const { meal, loadingByDay, listMealGroup, updateMealPlanByDay, selected } = props;
  const hasListRecipes = get(meal, 'recipes', []) && get(meal, 'recipes.length', 0) > 0;

  const handleRemoveRecipe = (event, recipe) => {
    event.stopPropagation();

    const result = listMealGroup.map(meal => {
      if (get(meal, '_id') === selected) {
        const newRecipes = get(meal, 'recipes', []).filter(item => {
          return get(item, 'recipe._id') !== get(recipe, '_id');
        });

        return { ...meal, recipes: newRecipes };
      }

      return meal;
    });

    updateMealPlanByDay && updateMealPlanByDay({ meal_group: result }, true);
  };

  return (
    <S.MainPanelContentWrapper>
      {loadingByDay ? (
        <S.ContentContainer>
          {Array(5)
            .fill(null)
            .map((_, index) => {
              return <MainPanelSkeleton key={`skeleton-${index}`} />;
            })}
        </S.ContentContainer>
      ) : hasListRecipes ? (
        <S.ContentContainer>
          {get(meal, 'recipes', []).map(recipe => {
            return (
              <RecipeItems
                key={get(recipe, 'recipe._id', '')}
                recipe={get(recipe, 'recipe', {})}
                isShowRemoveIcon={true}
                onRemoveRecipe={handleRemoveRecipe}
                showDesc={false}
                {...props}
              />
            );
          })}
        </S.ContentContainer>
      ) : (
        <S.ContentEmpty>
          <BlankIcon className="blank-icon" />
          <S.ContentEmptyTitle>Add recipes from the right</S.ContentEmptyTitle>
        </S.ContentEmpty>
      )}
    </S.MainPanelContentWrapper>
  );
};

export default MainPanelContent;
