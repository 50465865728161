import React, { useState, useEffect } from 'react';
import * as S from './style';

import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { convertS3UrlToCloudFrontUrl, getStatusCodeUrl } from 'utils/commonFunction';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import { SOCIAL_STRING } from 'constants/commonData';

const CheckBoxIcon = ({ active, onChange }) => {
  return (
    <S.CheckBoxIconWrapper>
      <S.CheckboxResource checked={active} onChange={onChange} />
    </S.CheckBoxIconWrapper>
  );
};

function getAvatarClass(resource) {
  const type = resource.link_type || resource.document_type;
  switch (type) {
    case 'youtube':
    case 'vimeo':
    case 'instagram':
    case 'twitter':
    case 'spotify':
    case 'facebook':
    case 'document':
      return type;
    case 'pdf':
      return 'document';
    case 'form':
      return 'form';
    default:
      return 'link';
  }
}
const ResourceItem = props => {
  const [coverImage, setCover] = useState('');
  const { resource, onSelect, active, isGrayOutList } = props;

  const getPresignedURLs = async list => {
    try {
      const newSrc = convertS3UrlToCloudFrontUrl(list[0], props.cloudfrontList);
      if (newSrc) {
        setCover(newSrc);
      } else {
        const statusCodeExistedThumbnail = await getStatusCodeUrl(list[0]);
        if (
          resource.link &&
          statusCodeExistedThumbnail !== 200 &&
          (getAvatarClass(resource) === SOCIAL_STRING.instagram || resource.link.includes(SOCIAL_STRING.instagram))
        ) {
          const metaDataFromURL = await getPageMetaDataFromURL(resource.link);
          const { data: { data: { image = '' } = {} } = {} } = metaDataFromURL;

          setCover(image);
        } else {
          const response = await props.getS3presignedURL(list);
          setCover(response[0]);
        }
      }
    } catch {}
  };

  useEffect(() => {
    resource.cover_image && getPresignedURLs([resource.cover_image]);
  }, []);

  const onClick = () => {
    onSelect(resource);
  };

  return (
    <S.ResourceItemWrapper active={active} onClick={onClick} isGrayOutList={isGrayOutList}>
      <S.ResourceAvatar coverImage={coverImage} className={getAvatarClass(resource)} />
      <S.ResourceInfo>
        <div className="resourceInfo__name">{resource.name}</div>
        <div className="resourceInfo__description">
          {resource.document_name || resource.link || get(resource, 'form.name', '')}
        </div>
      </S.ResourceInfo>
      <CheckBoxIcon active={active} onChange={onClick} />
    </S.ResourceItemWrapper>
  );
};

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceItem);
