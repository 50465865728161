import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import TableHeader from './parts/TableHeader';
import TableBody from './parts/TableBody';
import Toolbar from './parts/Toolbar';
import Footer from './parts/Footer';
import LoadingIndicator from 'shared/LoadingIndicator';
import TaskCreate from 'components/TaskCreate';
import { saveLastLibraryRoute } from 'utils/commonFunction';
import { MODE } from './constants';

import * as Layout from 'shared/LibraryLayout';
import * as S from './styles';
import { ReactComponent as TaskLibraryEmpty } from 'assets/images/task_library_empty.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import TaskAddNew from 'components/TaskAddNew';

const View = props => {
  const {
    loading,
    resetSelectedTask,
    mode,
    match,
    replace,
    getMetrics,
    getTasksLibraryDetails,
    toggleModal,
    toggleSideBar,
    total,
    has_task_library,
    user,
    query,
    types,
    owners,
  } = props;
  const {
    params: { taskId },
  } = match;
  const [bodyMetricsUser, setBodyMetricsUser] = useState([]);
  const [taskDetail, setTaskDetail] = useState({});

  const isFilterSearch = useMemo(() => !_.isEmpty(query) || !_.isEmpty(types) || !_.isEmpty(owners), [
    query,
    types,
    owners,
  ]);

  useEffect(() => {
    return () => {
      resetSelectedTask && resetSelectedTask();
    };
  }, []);

  useEffect(() => {
    toggleSideBar && toggleSideBar(true);
    saveLastLibraryRoute('/home/task-library');
  }, []);

  useEffect(() => {
    if (taskId) {
      getTasksLibraryDetails &&
        getTasksLibraryDetails(taskId).then(response => {
          const {
            data: { data },
          } = response;
          setTaskDetail(data);

          let popupMode = MODE.EDIT;

          if (mode) {
            popupMode = mode;
          }

          toggleModal &&
            toggleModal(
              true,
              <TaskCreate
                bodyMetricsFromLibrary={bodyMetricsUser}
                task={data}
                modeFromLibrary={popupMode}
                isAddTaskFromLibrary={true}
                onCloseForm={onCloseForm}
              />,
            );
        });
    }

    return () => {
      setTaskDetail(null);
    };
  }, [taskId, mode]);

  useEffect(() => {
    getMetrics &&
      getMetrics().then(response => {
        const {
          data: { data },
        } = response;
        setBodyMetricsUser(data);
      });
  }, []);

  const onCloseForm = () => {
    toggleModal && toggleModal(false);
    replace && replace(`/home/task-library`);
  };

  const handleAddNewTask = () => {
    toggleModal &&
      toggleModal(
        true,
        <TaskAddNew
          owner={get(user, '_id', '')}
          isAddTaskFromLibrary={true}
          bodyMetricsFromLibrary={bodyMetricsUser}
        />,
      );
  };

  const renderLoadingTask = () => {
    return (
      <Layout.TableBody>
        <Layout.TableRow style={{ border: 'unset' }}>
          <Layout.TableCell colSpan={7}>
            <LoadingIndicator title="Loading tasks" size="medium" />
          </Layout.TableCell>
        </Layout.TableRow>
      </Layout.TableBody>
    );
  };

  return (
    <S.LayoutWrapper>
      {taskId && !!taskDetail && (
        <Helmet>
          <title>{`${get(taskDetail, 'title', '')} - Task Library - Everfit`}</title>
        </Helmet>
      )}
      <Toolbar bodyMetricsFromLibrary={bodyMetricsUser} />
      <S.TableWrapper>
        <Layout.Table>
          <TableHeader />
          {loading ? renderLoadingTask() : <TableBody />}
        </Layout.Table>
        {!loading && total <= 0 && (
          <Layout.NoResult>
            {isFilterSearch && <Layout.NoResultText>No results found</Layout.NoResultText>}
            {!has_task_library && !isFilterSearch && (
              <>
                <TaskLibraryEmpty />
                <S.ButtonAddFirstTask text="Add Your First Task" icon={<PlusIcon />} onClick={handleAddNewTask} />
              </>
            )}
          </Layout.NoResult>
        )}
      </S.TableWrapper>
      <Footer />
    </S.LayoutWrapper>
  );
};

export default React.memo(View);
