import { get } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

function ForumHeader({ forum }) {
  const forumName = get(forum, 'group.name');
  return (
    <>
      <Helmet>
        <title>{forumName ? `${forumName} - ` : ''}Forums - Everfit</title>
      </Helmet>
      <div>Community Forums</div>
    </>
  );
}

const mapStateToProps = state => {
  return { forum: state.rootReducer.forum };
};

export default connect(mapStateToProps)(ForumHeader);
