import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideOnboardingUserGuide, maximizeCheckList } from 'redux/onboarding/actions';
import ClientGuide from './ClientGuide';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

function Guide(props) {
  const { show } = props;
  const wrapper = useRef();

  if (!show) {
    return null;
  }

  const onClose = () => {
    props.maximizeCheckList();
    props.hideOnboardingUserGuide();
  };

  return ReactDOM.createPortal(
    <S.Wrapper ref={wrapper} onClick={onClose}>
      <S.Guide className="guide--checklist">
        <div className="index">1.&nbsp;</div>
        <div className="text">Learn how to start training in less than 5 minutes!</div>
        <img src={`${CDN_URL}/images/guide_arrow_2.svg`} className="arrow" alt="" />
      </S.Guide>
      <ClientGuide />
      <S.CloseText>Click anywhere to close</S.CloseText>
    </S.Wrapper>,
    document.body,
  );
}

const mapState = state => {
  const {
    rootReducer: {
      onboarding: { showOnboardingGuide },
    },
  } = state;

  return { show: showOnboardingGuide };
};

const mapDispatch = dispatch => {
  return {
    hideOnboardingUserGuide: bindActionCreators(hideOnboardingUserGuide, dispatch),
    maximizeCheckList: bindActionCreators(maximizeCheckList, dispatch),
  };
};

export default connect(mapState, mapDispatch)(Guide);
