// Lib
import React, { useEffect, useState } from 'react';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import get from 'lodash/get';

// Components
import { Button } from 'shared/FormControl';
import RenderOwnerShip from 'components/MealPlans/parts/ShareModal/RenderOwnerShip';

// constants
import { TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';

import * as S from './style';

const ShareModal = props => {
  const { isHideCloseButton = false, isShowCancelButton = false, toggleModal, onSubmit, user, recipe } = props;

  const [ownerValue, setOwnerValue] = useState({
    owner: get(recipe, 'author._id'),
  });
  const [shareValue, setShareValue] = useState({ share: TEAM_SHARE_PRIVATE });

  useEffect(() => {
    if (recipe) {
      setOwnerValue({
        owner: get(recipe, 'author._id'),
      });
      setShareValue({
        share: get(recipe, 'share') || TEAM_SHARE_PRIVATE,
      });
    }
  }, [recipe]);

  const onSetShareValue = value => {
    setShareValue(value);
  };

  const onSetOwnerValue = value => {
    setOwnerValue(value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    const id = get(recipe, '_id', '');

    let params = {
      owner: get(shareValue, 'share') === TEAM_SHARE_NOOWNER ? null : get(ownerValue, 'owner'),
      share: get(shareValue, 'share'),
    };

    if (onSubmit) {
      onSubmit && onSubmit({ id, params });
    }
  };

  const onClose = () => {
    toggleModal && toggleModal(false);
  };

  return (
    <S.ModalShare
      open={true}
      closeIcon={
        !isHideCloseButton && (
          <CloseButton className="close-button" onClick={onClose}>
            <img src={CloseIcon} alt="button-close" />
          </CloseButton>
        )
      }
      className="recipe-share-owner"
    >
      <Modal.Header>Sharing settings</Modal.Header>
      <Modal.Content>
        <S.Form onSubmit={onSubmit}>
          <RenderOwnerShip
            ownerValue={ownerValue}
            shareValue={shareValue}
            onSetShareValue={onSetShareValue}
            onSetOwnerValue={onSetOwnerValue}
            userId={get(user, '_id', '')}
            hasAvatar
            hasSpacing
            hasYou
            isEditAble={
              get(shareValue, 'share', null) === TEAM_SHARE_NOOWNER ||
              get(ownerValue, 'owner', null) === get(user, '_id', '')
            }
          />
        </S.Form>
        <S.Footer>
          {isShowCancelButton && (
            <Button className="cancel" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button className="submit" onClick={event => handleSubmit(event)} purple>
            Save
          </Button>
        </S.Footer>
      </Modal.Content>
    </S.ModalShare>
  );
};

export default ShareModal;
