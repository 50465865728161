import React, { useMemo } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { Icon, Popup, Image } from 'semantic-ui-react';
import { Emoji } from 'emoji-picker-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import TitleTooltip from 'shared/TitleTooltip';
import { removeHabit, saveHabitToLibrary, endHabit, getHabitUnits } from 'redux/habits/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import {
  removeAutoflowIntervalHabit,
  getDetailAutoflowIntervalHabit,
  saveAutoflowIntervalHabitToLibrary,
} from 'redux/autoflowInterval/habit/actions';
import { saveAutoflowHabitToLibrary, getDetailAutoflowHabit, removeAutoflowHabit } from 'redux/autoflow/habit/actions';
import { convertReminderTime } from 'utils/commonFunction';
import AutoflowInfo from 'shared/AutoflowInfo';
import { Trigger } from 'shared/Dropdown/Basic';
import styles from 'components/TaskCalendarCell/styles';
import { AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import * as S from './styles';
import './styles.scss';

const HabitDraggable = props => {
  const {
    habit = undefined,
    day,
    directionPopup,
    openingPopup,
    onPopupToggle,
    isDragging,
    selectedClient,
    push,
    toggleConfirmModal,
    removeCurrentHabit,
    saveHabitToLibrary,
    endCurrentHabit,
    autoflow,
    removeAutoflowIntervalHabit,
    saveAutoflowIntervalHabitToLibrary,
    saveAutoflowHabitToLibrary,
    removeAutoflowHabit,
    isMonday = false,
    workingAutoflowId,
  } = props;

  if (isEmpty(habit)) return <></>;
  const { title, _id, icon_unified, schedule_reminder, reminder_time, original_autoflow } = habit;

  const reminderTime = useMemo(() => {
    if (schedule_reminder && reminder_time) {
      return convertReminderTime({
        value: reminder_time,
        beforeFormat: 'HH:mm:ss',
        afterFormat: 'hh:mm A',
      });
    }
    return '';
  }, [schedule_reminder, reminder_time]);

  const togglePopup = id => {
    toggleActionPopup(id);
  };

  const toggleActionPopup = id => {
    resetActions(() => {
      setTimeout(() => onPopupToggle(id), 0);
    });
  };

  const resetActions = callback => {
    onPopupToggle(null);
    callback && callback();
  };

  const handleOpenHabit = event => {
    event.preventDefault();
    if (autoflow) {
      push(`/home/autoflow/${workingAutoflowId}/task/calendar/${_id}/detail`);
      return;
    }
    if (!isEmpty(selectedClient) && !isEmpty(_id)) {
      push(`/home/client/${selectedClient}/habit/${_id}${`?tracked=${day}`}`);
    }
  };

  const handleSaveToLibraryHabit = event => {
    event.preventDefault();
    onPopupToggle(null);

    if (autoflow) {
      if (autoflow === AUTOFLOW_TYPES.INTERVAL) {
        saveAutoflowIntervalHabitToLibrary(_id);
      } else {
        saveAutoflowHabitToLibrary(_id);
      }
    } else {
      saveHabitToLibrary(_id);
    }
  };

  const handleEnd = () => {
    endCurrentHabit && endCurrentHabit(_id, () => {});
  };

  const handleConfirmEndHabit = event => {
    event.preventDefault();
    onPopupToggle(null);
    toggleConfirmModal(
      true,
      <S.HabitConfirmModal
        noIcon
        title="End Habit"
        content="If you end this habit, all upcoming occurrences today onwards will be removed. Would you like to continue?"
        confirmButtonTitle="End habit"
        onConfirm={handleEnd}
        noBorder
        hasHoverState
      />,
    );
  };

  const handleConfirmDeleteHabit = event => {
    event.preventDefault();
    onPopupToggle(null);
    let content =
      'If you delete this habit, all occurrences of this habit (in the past and future) and insights will be permanently lost. Would you like to continue?';
    let confirmButtonTitle = 'Delete habit entirely';
    let onConfirm = () => null;

    if (autoflow) {
      content =
        'If you delete this habit, all occurrences of this habit inside the Autoflow will be permanently lost, and all habits that have been assigned to your clients will be immediately ended. Would you like to continue?';
      confirmButtonTitle = 'Yes, delete';

      if (autoflow === AUTOFLOW_TYPES.INTERVAL) {
        onConfirm = () => removeAutoflowIntervalHabit(_id);
      } else {
        onConfirm = () => removeAutoflowHabit(_id);
      }
    } else {
      onConfirm = () => removeCurrentHabit(_id);
    }

    toggleConfirmModal(
      true,
      <S.HabitConfirmModal
        noIcon
        title="Delete Habit"
        content={content}
        confirmButtonTitle={confirmButtonTitle}
        onConfirm={onConfirm}
        noBorder
        hasHoverState
      />,
    );
  };

  const isAutoflowExactDate = autoflow === AUTOFLOW_TYPES.EXACT_DATE;
  return (
    <S.HabitWrapper
      className="ui segment small task-card"
      onClick={handleOpenHabit}
      isDragging={isDragging}
      autoflow={autoflow}
    >
      <div className="task-icon">
        <Emoji unified={icon_unified} size="15" />
      </div>
      {!autoflow && original_autoflow && (
        <AutoflowInfo
          elementId={`habit-${_id}-${day}`}
          autoflow={original_autoflow}
          isMonday={isMonday}
          isHabit={true}
        />
      )}
      <div className="task-title">
        <TitleTooltip
          className="title"
          tooltipClassName="title-cal"
          tooltipId={`habit-title-${_id}-${day}`}
          content={title}
          delayShow={1000}
        />
        <div className="description">{reminderTime}</div>
      </div>
      <Popup
        trigger={
          autoflow === AUTOFLOW_TYPES.EXACT_DATE ? (
            <Icon
              color={'grey'}
              name={'ellipsis horizontal'}
              size={'small'}
              className="more-actions-icon"
              onClick={e => e.stopPropagation()}
            />
          ) : (
            <Trigger onClick={e => e.stopPropagation()} />
          )
        }
        on="click"
        style={styles.popup}
        open={isEqual(openingPopup, _id)}
        onOpen={() => togglePopup(_id)}
        onClose={() => togglePopup(null)}
        position={directionPopup}
        basic
        className="action-popup"
      >
        <ul style={isAutoflowExactDate ? S.HabitDraggable.dropdownList : styles.dropdownList}>
          <li
            style={styles.dropdownListItem}
            className={classnames('list-item', { 'custom-list-item': isAutoflowExactDate })}
          >
            <div style={styles.dropdownListItemContainer} onClick={handleSaveToLibraryHabit}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/save.svg`} />
              <div style={isAutoflowExactDate ? S.HabitDraggable.dropdownListItemTitle : styles.dropdownListItemTitle}>
                Save to Library
              </div>
            </div>
          </li>
          {!habit.has_ended && !autoflow && (
            <li style={styles.dropdownListItem} className={'list-item'}>
              <div style={styles.dropdownListItemContainer} onClick={handleConfirmEndHabit}>
                <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/flag_end.svg`} />
                <div style={styles.dropdownListItemTitle}>End Habit</div>
              </div>
            </li>
          )}
          <li
            style={styles.dropdownListItem}
            className={classnames('list-item', { 'custom-list-item': isAutoflowExactDate })}
          >
            <div style={styles.dropdownListItemContainer} onClick={handleConfirmDeleteHabit}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/delete.svg`} />
              <div style={isAutoflowExactDate ? S.HabitDraggable.dropdownListItemTitle : styles.dropdownListItemTitle}>
                Delete Habit
              </div>
            </div>
          </li>
        </ul>
      </Popup>
    </S.HabitWrapper>
  );
};

const mapStateToProps = state => ({
  selectedClient: get(state.rootReducer.client.workingClientDetail, '_id'),
  habitUnits: get(state, 'rootReducer.habits.habitUnits'),
  isHabitPermission: get(state, 'rootReducer.permission.autoflow_habit', false),
  workingAutoflowId: get(state, 'rootReducer.autoflow.common.workingAutoflow._id'),
});

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeCurrentHabit: params => dispatch(removeHabit(params)),
  saveHabitToLibrary: params => dispatch(saveHabitToLibrary(params)),
  endCurrentHabit: bindActionCreators(endHabit, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  removeAutoflowIntervalHabit: bindActionCreators(removeAutoflowIntervalHabit, dispatch),
  getDetailAutoflowIntervalHabit: bindActionCreators(getDetailAutoflowIntervalHabit, dispatch),
  saveAutoflowIntervalHabitToLibrary: bindActionCreators(saveAutoflowIntervalHabitToLibrary, dispatch),
  saveAutoflowHabitToLibrary: bindActionCreators(saveAutoflowHabitToLibrary, dispatch),
  getDetailAutoflowHabit: bindActionCreators(getDetailAutoflowHabit, dispatch),
  removeAutoflowHabit: bindActionCreators(removeAutoflowHabit, dispatch),
  getHabitUnits: bindActionCreators(getHabitUnits, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HabitDraggable);
