import React, { useMemo } from 'react';
import * as S from './style';
import { checkPasswordValidation } from 'utils/validations';
import { ReactComponent as CheckmarkCircleIcon } from 'assets/icons/checkmark-circle.svg';
import { ReactComponent as UntickIcon } from 'assets/icons/checkmark-circle-untick.svg';

const passwordSuggestions = [
  {
    key: 'least_one_number',
    label: 'At least one number',
  },
  {
    key: 'least_one_alphabet',
    label: 'At least one alphabet character',
  },
  {
    key: 'least_one_special',
    label: 'At least one special character',
  },
  {
    key: 'least_8_number',
    label: 'At least 8 characters',
  },
];

const VerifyPasswordTooltip = props => {
  const { passwordValue, width = 230, height = 208, isOverlap = false } = props;

  const passwordValidates = useMemo(() => checkPasswordValidation(passwordValue), [passwordValue]);

  return (
    <S.TooltipWrapper width={width} height={height} isOverlap={isOverlap}>
      <div className="label-verify-password-tooltip">Password must contain:</div>
      <div className="suggestion-wrapper">
        {passwordSuggestions.map(suggestion => (
          <div key={suggestion.key} className="suggestion-row">
            {passwordValidates[suggestion.key] ? (
              <CheckmarkCircleIcon className="checked-icon checkmark-icon" />
            ) : (
              <UntickIcon className="un-checked-icon checkmark-icon" />
            )}
            <div className="suggestion-label">{suggestion.label}</div>
          </div>
        ))}
      </div>
    </S.TooltipWrapper>
  );
};

export default VerifyPasswordTooltip;
