import { PLANS, WORKOUT_COLLECTION_STATUS } from 'constants/commonData';
import { ReactComponent as TextIcon } from 'assets/icons/onboarding-flow-text.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/onboarding-flow-signature.svg';
import { ReactComponent as TriggerFormIcon } from 'assets/icons/onboarding-trigger-form.svg';

const ASSET_TYPES = {
  WELCOME_FORM: 'form',
  ONBOARDING_MESSAGE: 'message',
  ASSIGNMENTS: 'assignments',
  AUTOFLOW: 'autoflow',
  PROGRAM: 'program',
  FORUM: 'forum',
  FORM: 'form',
  QUESTION: 'question',
  ON_DEMAND: 'on_demand',
  RESOURCE_COLLECTIONS: 'resource_collections',
  WORKOUT_COLLECTIONS: 'workout_collections',
  STUDIO_PROGRAMS: 'studio_programs',
  PROGRAM_TRIGGER_FORMS: 'program_trigger_forms',
  FORUM_TRIGGER_FORMS: 'forum_trigger_forms',
};

const FORM_QUESTIONS_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  INPUT: 'input',
};

const ONBOARDING_FLOW_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
};

const TABS = [
  {
    key: 'your_form',
    value: 'your forms',
  },
  {
    key: 'all_form',
    value: 'all forms',
  },
];

const DEFAULT_QUERY = {
  page: 1,
  per_page: 20,
  text_search: '',
  fetch_type: 'your_form',
  sort: -1,
  sorter: 'last_updated_at',
  status: 'published',
  total: 0,
  your_form: 0,
  all_form: 0,
};

const LIMIT_COLLECTION_NUMBER = 5;

const HEADER_ONBOARDING_FLOW_DETAIL_TABS = [
  {
    key: 'overview',
    value: 'overview',
  },
  {
    key: 'settings',
    value: 'settings',
  },
];

const ON_DEMAND_API_GET_LIST = {
  [ASSET_TYPES.RESOURCE_COLLECTIONS]: {
    method: 'POST',
    url: '/api/studio-collection/v2/fetch-by-trainer',
    query: {
      page: 1,
      per_page: 20,
      search: '',
      only_my_studio_collection: false,
      except: [],
    },
  },
  [ASSET_TYPES.WORKOUT_COLLECTIONS]: {
    method: 'GET',
    url: '/api/on-demand-workout-collections',
    query: {
      page: 1,
      perPage: 20,
      textSearch: '',
      status: WORKOUT_COLLECTION_STATUS.PUBLISH,
      only_my_workout_collection: false,
      excludeIds: [],
    },
  },
  [ASSET_TYPES.STUDIO_PROGRAMS]: {
    method: 'POST',
    url: '/api/studio-program/fetch-by-trainer',
    query: {
      page: 1,
      per_page: 20,
      search: '',
      isPublished: true,
      only_my_studio_program: false,
      except: [],
    },
  },
};

const ON_DEMAND_ASSET_KEY = {
  [ASSET_TYPES.RESOURCE_COLLECTIONS]: 'resource_collection',
  [ASSET_TYPES.WORKOUT_COLLECTIONS]: 'workout_collection',
  [ASSET_TYPES.STUDIO_PROGRAMS]: 'studio_program',
};

const MAXIMUM_SELECTED_ASSETS = 5;

const ON_DEMAND_PLANS = {
  studio: {
    tier: PLANS.studio.key,
  },
  onDemand: {
    tier: PLANS.pro.key,
    addOns: {
      resource_collections: true,
    },
  },
  automation: {
    addOns: {
      automation: true,
    },
  },
  proAndAutomation: {
    tier: PLANS.pro.key,
    addOns: {
      automation: true,
    },
  },
  studioAndAutomation: {
    tier: PLANS.studio.key,
    addOns: {
      automation: true,
    },
  },
};

const MENU_HEIGHT = 330;

export {
  ASSET_TYPES,
  FORM_QUESTIONS_TYPE,
  ONBOARDING_FLOW_STATUS,
  TABS,
  DEFAULT_QUERY,
  LIMIT_COLLECTION_NUMBER,
  HEADER_ONBOARDING_FLOW_DETAIL_TABS,
  ON_DEMAND_API_GET_LIST,
  ON_DEMAND_ASSET_KEY,
  MAXIMUM_SELECTED_ASSETS,
  ON_DEMAND_PLANS,
  MENU_HEIGHT,
};

export const TAG_TYPES = {
  NUMBER_OF_QUESTIONS: 'number_of_questions',
  SIGNATURE: 'signature',
  TRIGGER: 'trigger',
};

export const INFO_TAGS = {
  [TAG_TYPES.NUMBER_OF_QUESTIONS]: {
    icon: TextIcon,
    label: 'QUESTION',
  },
  [TAG_TYPES.SIGNATURE]: {
    icon: SignatureIcon,
    label: 'SIGNATURE',
  },
  [TAG_TYPES.TRIGGER]: {
    icon: TriggerFormIcon,
    label: 'TRIGGER',
  },
};

export const INIT_STATE_TRIGGER_OPTIONS = {
  question_option: null,
  program_library: null,
  program_library_data: null,
  forum: null,
  forum_data: null,
  is_deleted: false,
  is_resolved: true,
  last_updated_date: null,
  last_updated_by: null,
  is_rename_resolved: null,
};

export const INIT_STATE_TRIGGER_FORMS = {
  form: null,
  form_data: null,
  custom_form: null,
  custom_form_data: null,
  question: null,
  question_data: null,
  questions: [],
  trigger_options: [],
  empty: false,
  assignedTrigger: false,
  is_form_deleted: false,
  is_deleted: false,
  is_resolved: true,
};

export const TRIGGER_FORMS_PICK_PROTOTYPE = ['form', 'custom_form', 'question', 'trigger_options'];
export const PROGRAM_TRIGGER_OPTIONS_PICK_PROTOTYPE = [
  'question_option',
  'program_library',
  'program_library_data',
  'label',
];
export const PROGRAM_PICK_PROTOTYPE = ['_id', 'title', 'active_sync', 'share'];
export const FORUM_TRIGGER_OPTIONS_PICK_PROTOTYPE = ['question_option', 'forum', 'forum_data', 'label'];
export const FORUM_PICK_PROTOTYPE = ['_id', 'name', 'banner'];

export const EMPTY_OPTION_TRIGGER = {
  _id: null,
  key: '-',
  label: '-',
  title: '-',
};
