import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddMultipleClients from './component';
import { toggleModal, toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import { getClientsFullData, submitMultipleClients } from 'redux/client/client.actionCreators';
import { getAllSegments } from 'redux/segment/actions';

const mapStateToProps = state => {
  const {
    isModalOpen,
    user,
    cloudfrontList,
    rootReducer: { permission },
  } = state;
  return {
    isModalOpen,
    user,
    cloudfrontList,
    permission,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  submitMultipleClients: bindActionCreators(submitMultipleClients, dispatch),
  getAllSegments: bindActionCreators(getAllSegments, dispatch),
  getClientsFullData: bindActionCreators(getClientsFullData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMultipleClients);
