import React from 'react';

import * as S from '../style';
import { useMyContext } from '../context/ProgramTemplatesContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close_program_filter.svg';

const DescModalContent = () => {
  const { toggleDescriptionModal, detailModalItem } = useMyContext();
  const { description } = detailModalItem;

  return (
    <S.FullscreenModalContent className="description">
      <S.FilterModalHeader>
        <S.FilterHeaderTitle>About Program</S.FilterHeaderTitle>
        <S.CloseFilterButton onClick={toggleDescriptionModal}>
          <CloseIcon />
        </S.CloseFilterButton>
      </S.FilterModalHeader>
      <S.TagPopupContent>
        <S.DescriptionContent
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></S.DescriptionContent>
      </S.TagPopupContent>
    </S.FullscreenModalContent>
  );
};

export default DescModalContent;
