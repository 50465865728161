// Libs
import get from 'lodash/get';

// Constants
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { SWITCH_VIEW_TRIGGER_TYPES } from '../constants/types';
import { ASSET_TYPES } from 'components/OnboardingFlowDetail/constants';

export const getTriggerOptions = ({ onboardingFlow, questionOptions }) => {
  let programTriggerOptions = [];
  let forumTriggerOptions = [];

  if (typeof onboardingFlow !== 'object' || !Array.isArray(questionOptions)) {
    return {
      programTriggerOptions,
      forumTriggerOptions,
    };
  }

  const programOptions = get(onboardingFlow, 'program_trigger_forms[0].trigger_options');
  const forumOptions = get(onboardingFlow, 'forum_trigger_forms[0].trigger_options');

  if (Array.isArray(programOptions)) {
    programTriggerOptions = filterOptionByCurrentList(questionOptions, programOptions);
  }

  if (Array.isArray(forumOptions)) {
    forumTriggerOptions = filterOptionByCurrentList(questionOptions, forumOptions);
  }

  return {
    programTriggerOptions,
    forumTriggerOptions,
  };
};

export const getTriggerAssignLabel = (triggerOption, type) => {
  const { program_library_data, forum_data } = { ...triggerOption };

  if (typeof program_library_data === 'object' && type === SWITCH_VIEW_TRIGGER_TYPES.PROGRAM) {
    return program_library_data.title || '';
  }

  if (typeof forum_data === 'object' && type === SWITCH_VIEW_TRIGGER_TYPES.FORUM) {
    return forum_data.title || forum_data.name || '';
  }

  return '';
};

export const isPrivateFlow = (user, author, share) => author._id !== user._id && share === TEAM_SHARE_PRIVATE;

export const getTooltipContent = author => {
  const { first_name, last_name } = author;
  const fullName = `${first_name || ''} ${last_name || ''}`.trim();
  return `This Onboarding Flow is private by owner <strong>${fullName}.</strong>`;
};

export const filterOptionByCurrentList = (currentOptions, sourceOptions) =>
  currentOptions.reduce((accumulator, currentValue) => {
    const findItem = sourceOptions.find(it => it.question_option === currentValue._id);

    if (findItem) {
      return [
        ...accumulator,
        {
          ...findItem,
          label: currentValue.label || findItem.label,
        },
      ];
    }

    return accumulator;
  }, []);

const countIssues = (triggerForms, assignedKey) => {
  let total = 0;

  triggerForms.forEach(it => {
    if (it.is_deleted === true || it.is_resolved === false) {
      return total++;
    }

    if (Array.isArray(it.trigger_options) && it.trigger_options.length > 0) {
      it.trigger_options.forEach(option => {
        if (!option || !option[assignedKey]) return;

        if (typeof option[assignedKey] === 'object' && option[assignedKey] && option[assignedKey].is_deleted === true) {
          return total++;
        }

        if (option.is_deleted === true || (option.is_resolved === false && option.is_rename_resolved === null)) {
          return total++;
        }
      });
    }
  });

  return total;
};

export const countTotalWarningIssue = ({ forumTriggerForms, programTriggerForms }) => {
  let results = 0;

  if (Array.isArray(programTriggerForms) && programTriggerForms.length > 0) {
    results += countIssues(programTriggerForms, 'program_library_data');
  }

  if (Array.isArray(forumTriggerForms) && forumTriggerForms.length > 0) {
    results += countIssues(forumTriggerForms, 'forum_data');
  }

  return results;
};

export const findAssetNamesDeleted = (triggerForm, type) => {
  const results = [];

  if (typeof triggerForm === 'object') {
    let assignedKey = '';
    if (type === ASSET_TYPES.PROGRAM_TRIGGER_FORMS) {
      assignedKey = 'program_library_data';
    }
    if (type === ASSET_TYPES.FORUM_TRIGGER_FORMS) {
      assignedKey = 'forum_data';
    }

    if (Array.isArray(triggerForm.trigger_options) && triggerForm.trigger_options.length > 0) {
      triggerForm.trigger_options.forEach(option => {
        if (!option || !option[assignedKey]) return;

        if (typeof option[assignedKey] === 'object' && option[assignedKey].is_deleted === true) {
          results.push(option[assignedKey].title || option[assignedKey].name);
        }
      });
    }
  }

  return results;
};
