import React from 'react';
import Avatar from 'react-avatar';
import moment from 'moment';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import Dropzone from 'react-dropzone';
import { database } from 'database/firebase';
import { DateTime } from 'luxon';
import { diff } from 'deep-diff';

import * as S from './style';

import {
  getUserShortName,
  getMediaType,
  revokeMultipleObjectURL,
  revokeObjectURL,
  mediaLog,
  getMultiplePresignedUploadUrlsByParams,
  generateRoomName,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import Urlify from 'shared/Urlify';
import ChatInput, { TYPES as MESSAGE_TYPES } from 'shared/ChatInput';
import { Button } from 'shared/FormControl';
import { DELETED_CLIENT_NAME, MEDIA_FILE_REJECTED_CONTENT, LIMIT_SIZE_VIDEO_UPLOAD_MB } from 'constants/commonData';
import MediaPlayer from 'shared/MediaPlayer';
import FileUpload from 'shared/FileUpload';
import DropZonePlaceholder from 'shared/DropZonePlaceholder';
import S3Images from 'shared/S3Images';
import S3Video from 'shared/S3Video';
import RoomAction from '../RoomAction';
import LoadingIndicator from 'shared/LoadingIndicator';
import AudioPlayer from './AudioPlayer';
import { isSafari } from 'react-device-detect';
import GiphyWatermark from 'shared/Giphy/components/Watermark';
import SearchParticipant from 'components/Inbox/SearchParticipant';
import { ReactComponent as NoContactsIcon } from 'assets/icons/no-contacts-icon.svg';
import SystemMessage from 'components/Inbox/SystemMessage';
import { ReactComponent as ReplyIcon } from 'assets/icons/reply-icon.svg';
import { MESSAGE_TYPE, getMessageType } from 'components/Inbox/helps';
import RepliedMessage from 'components/Inbox/Reply/RepliedMessage';
import TittleReplied from 'components/Inbox/Reply/TittleReplied';
import MultipleMediaMessage from 'components/Inbox/MultipleMediaMessage';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-purple.svg';
import MessageAction from 'components/Inbox/MessageAction';
import { axiosInstance } from 'configs/request';
import { SharedTooltip } from 'shared/SharedTooltip';

const THRESHOLD = 10;

class InboxContent extends React.Component {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
    this.inputRef = React.createRef();
    this.searchClientDebounce = _.debounce(this.searchClients, 300);
    this.scrollTopDebounce = _.debounce(this.handleScrollToTop, 300);
    this.scrollBottomDebounce = _.debounce(this.handleScrollToBottom, 300);
    this.showScrollDownButtonDebounce = _.debounce(this.handleShowDownButtonEvent, 100);
    this.checkEndOfBottomDebounce = _.debounce(this.handleCheckEndOfBottomEvent, 100);
    this.isTurningOnFeature = false;
    this.scrollingEventDebounce = _.debounce(this.handleScrollEvent, 300);
    this.scrolling = 0;
    this.enableScrollingBottom = false; // Enable scroll bottom
    this.isScrollingToOriginal = false; // Is scrolling to original
    this.isGoToLatest = false;
    this.uploading = false;
    this.loadingCreateGroup = false;
    this.atLatestMessage = false;
    this.autoOpenMenu = true;
    this.state = {
      selectedClient: props.transferInformation,
      uploadConfigs: [],
      progressVoiceChat: 0,
      playingVoiceChat: '',
      sendingVoiceMessage: false,
      startRecord: false,
      showRecord: false,
      replyMessage: {},
      scrolling: false,
    };
  }

  componentDidMount() {
    this.__isMounted = true;
    this.scrollToBottom();
    this.setState({
      enableScrolling: true,
    });
    setTimeout(() => {
      this.enableScrollingBottom = true;
      if (this.contentRef.current) {
        this.contentRef.current.addEventListener('scroll', this.showScrollDownButtonDebounce);
        this.contentRef.current.addEventListener('scroll', this.checkEndOfBottomDebounce);
      }
    }, 2000);
    const { currentRoomId, room, selectedRoom, resetMuteStatus } = this.props;
    const mute_until = _.get(room, 'mute_until', null) || _.get(selectedRoom, 'mute_until', null);
    if (mute_until > 0) {
      const time = Math.ceil((mute_until - Date.now()) / 1000) * 1000;
      this.muteTimeout = setTimeout(() => {
        resetMuteStatus && resetMuteStatus(currentRoomId);
        this.muteTimeout && clearTimeout(this.muteTimeout);
      }, time);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      messages,
      archivedMessages,
      loading,
      currentRoomId,
      room,
      selectedRoom,
      resetMuteStatus,
      isWaiting,
      stopWaitingSystemMessage,
      bottomItem,
      isGroupChatPermission,
    } = this.props;
    if (diff(room, prevProps.room) || diff(selectedRoom, prevProps.selectedRoom)) {
      const mute_until = _.get(room, 'mute_until', null) || _.get(selectedRoom, 'mute_until', null);
      if (mute_until > 0) {
        const time = Math.ceil((mute_until - Date.now()) / 1000) * 1000;
        this.muteTimeout = setTimeout(() => {
          resetMuteStatus && resetMuteStatus(currentRoomId);
          this.muteTimeout && clearTimeout(this.muteTimeout);
        }, time);
      }
    }

    if (
      (prevProps.messages.length !== messages.length ||
        prevProps.archivedMessages.length !== archivedMessages.length) &&
      prevProps.loading !== loading &&
      loading === false
    ) {
      this.scrollToBottom();
    }

    if (
      prevProps.messages.length !== messages.length &&
      isWaiting === true &&
      loading === false &&
      _.get(messages, `[${messages.length - 1}].system_message_type`) !== undefined
    ) {
      this.handleGoToBottom();
      stopWaitingSystemMessage();
    }

    if (
      this.contentRef.current &&
      this.enableScrollingBottom &&
      this.atLatestMessage &&
      prevProps.messages.length < messages.length &&
      _.isEmpty(bottomItem)
    ) {
      this.handleGoToBottom();
    }

    if (isGroupChatPermission !== prevProps.isGroupChatPermission) {
      this.setState({
        selectedClient: [],
        showRecord: false,
        startRecord: false,
        sendingVoiceMessage: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.contentRef.current) {
      this.contentRef.current.removeEventListener('scroll', this.showScrollDownButtonDebounce);
      this.contentRef.current.removeEventListener('scroll', this.checkEndOfBottomDebounce);
    }
    this.__isMounted = false;

    if (this.props.currentRoomId) {
      database.ref('messages').child(this.props.currentRoomId).off();
    }

    const localFiles = _.filter(this.props.messages, item => item.attachment && item.isLocalFile);

    if (localFiles.length) {
      revokeMultipleObjectURL(_.map(localFiles, 'attachment'));
    }

    this.muteTimeout && clearTimeout(this.muteTimeout);
  }

  scrollToBottom = (timeout = 300) => {
    if (this.isScrollingToOriginal) return;
    setTimeout(() => {
      const { firstLoadList } = this.props;
      if (this.contentRef.current) {
        if (this.isGoToLatest) {
          const { scrollHeight, clientHeight } = this.contentRef.current;
          this.scrolling = scrollHeight - clientHeight;
          this.contentRef.current.scrollTop = scrollHeight - clientHeight;
          this.isGoToLatest = false;
          this.atLatestMessage = true;
          return;
        }
        if (!firstLoadList) {
          this.contentRef.current.scrollTo({ top: 100, behavior: 'smooth' });
          this.atLatestMessage = false;
        } else {
          this.contentRef.current.scrollTop = this.contentRef.current.scrollHeight;
          this.atLatestMessage = true;
        }
      }
    }, timeout);
  };

  handleGoToBottom = () => {
    if (this.isScrollingToOriginal) return;
    this.isGoToLatest = true;
    const userId = _.get(this.props, 'user._id');
    const profileId = _.get(this.props, 'profileId');
    const messages = _.get(this.props, 'messages');
    const archivedMessages = _.get(this.props, 'archivedMessages');
    const bottomItem = _.get(this.props, 'bottomItem');
    if (bottomItem) {
      if (messages.length) {
        this.props.getLatestMessages();
      } else if (archivedMessages.length) {
        this.props.getLatestMessagesArchivedRoom(`${userId}_${profileId}`);
      }
      this.timerScrollToBottom && clearTimeout(this.timerScrollToBottom);
      this.timerScrollToBottom = setTimeout(() => {
        if (this.contentRef.current) {
          const { scrollHeight, clientHeight } = this.contentRef.current;
          this.scrolling = scrollHeight - clientHeight;
          this.contentRef.current.scrollTop = scrollHeight - clientHeight;
          this.setState({ scrolling: false });
        }
      }, 1000);
    } else {
      if (this.contentRef.current) {
        const { scrollHeight, clientHeight } = this.contentRef.current;
        this.scrolling = scrollHeight - clientHeight;
        this.contentRef.current.scrollTop = scrollHeight - clientHeight;
        this.setState({ scrolling: false });
      }
    }
    // End of scroll
    setTimeout(() => {
      this.isGoToLatest = false;
    }, 2000);
  };

  handleScrollToTop = () => {
    if (this.isScrollingToOriginal) return;
    const {
      currentRoomId,
      endOfList,
      getMessages,
      archivedMessages,
      getArchivedRoomMessages,
      adminGetMessages,
      user,
      profileId,
      viewTeammate,
      currentTeam,
      loading,
    } = this.props;

    if (this.contentRef.current && this.contentRef.current.scrollTop <= 10 && !endOfList && !loading) {
      if (archivedMessages.length > 0) {
        getArchivedRoomMessages(`${user._id}_${profileId}`);
      } else {
        if (viewTeammate) {
          adminGetMessages && adminGetMessages(currentTeam, currentRoomId);
        } else {
          !this.isScrollingToOriginal && getMessages && getMessages(currentRoomId);
        }
      }
    }
  };

  initLocalMessageData = (message, index, reply_message_id, replyMessage) => {
    const { user, profileId } = this.props;
    const { uploadConfigs } = this.state;
    const { file, objectURL, dimensions } = message;
    const timestamp = new Date().getTime();
    const data = new FormData();
    data.append('attachment', file);

    return {
      uploadConfigs: uploadConfigs[index] || null,
      attachment: objectURL,
      attachment_type: getMediaType(file),
      attachment_width: _.get(dimensions, 'width'),
      attachment_height: _.get(dimensions, 'height'),
      key: `new_media_message__${timestamp}__${index}`,
      localKey: `new_media_message__${timestamp}__${index}`,
      timestamp,
      sender: user._id,
      isLocalFile: true,
      content: MEDIA_FILE_REJECTED_CONTENT,
      attachment_duration: file.duration,
      reply_message_id,
      reply_message: replyMessage,
      chat_id: profileId,
    };
  };

  sendMessageMultipleMedia = ({ messages, reply_message_id, reply_message }) => {
    const { user, profileId, selectedRoom, addNewLocalMessages, createNewGroup, push } = this.props;
    const { uploadConfigs, selectedClient } = this.state;
    const timestamp = new Date().getTime();

    let localMessage = {
      uploadConfigs: uploadConfigs,
      attachments: _.map(messages, ({ file, objectURL, dimensions }, idx) => {
        return {
          attachment_id: `new_multiple_media_message__${timestamp}__${idx}`,
          attachment: objectURL,
          attachment_type: getMediaType(file),
          attachment_width: _.get(dimensions, 'width'),
          attachment_height: _.get(dimensions, 'height'),
          attachment_duration: _.get(file, 'duration'),
        };
      }),
      timestamp,
      sender: user._id,
      content: MEDIA_FILE_REJECTED_CONTENT,
      reply_message_id,
      reply_message,
      chat_id: profileId,
      key: `new_multiple_media_message__${timestamp}`,
      localKey: `new_multiple_media_message__${timestamp}`,
      isLocalFile: true,
    };

    if (!profileId) {
      if ((selectedClient.length === 1 && selectedClient[0].typeName === 'group') || selectedClient.length > 1) {
        createNewGroup &&
          createNewGroup(selectedClient, roomId => {
            localMessage = {
              ...localMessage,
              room_id: roomId,
            };
            setTimeout(() => {
              addNewLocalMessages && addNewLocalMessages([localMessage]);
            }, 2000);
          });
      } else {
        push && push(`/home/inbox/${selectedClient[0]._id}`);
        localMessage = {
          ...localMessage,
          chat_id: selectedClient[0]._id,
        };
        addNewLocalMessages && addNewLocalMessages([localMessage]);
      }
    } else {
      if (_.get(selectedRoom, 'type') === 'group') {
        localMessage = {
          ...localMessage,
          room_id: selectedRoom.id,
        };
      }
      addNewLocalMessages && addNewLocalMessages([localMessage]);
    }
  };

  onCancelUpload = localData => {
    if (this.__isMounted) {
      this.props.removeLocalMessage(this.props.currentRoomId, localData.key);
      revokeObjectURL(localData.attachment);
      this.uploading = false;
    }
  };

  onUploadSuccess = (localData, response) => {
    if (!this.__isMounted) {
      return;
    }

    const { messages, profileId, currentRoomId, user, selectedRoom } = this.props;

    const existed = _.find(messages, item => item.localKey === localData.localKey);

    if (existed) {
      this.uploading = false;
      const { config } = response;
      const { url } = config;
      const attachment = url.substring(0, url.indexOf('?X-Amz-Algorithm='));
      const currentParams = _.get(this.props, 'match.params', {});
      const currentId = currentParams && currentParams.profileId;
      let attachmentId = attachment.split('/') || [];

      attachmentId = attachmentId[attachmentId.length - 1];
      attachmentId = attachmentId.substring(0, attachmentId.indexOf('.'));

      let bodyData = {
        ..._.pick(existed, [
          'attachment_type',
          'attachment_width',
          'attachment_height',
          'attachment_duration',
          'reply_message_id',
          'attachments',
        ]),
        attachment: attachment,
        attachment_id: attachmentId,
        message: existed.content,
        chat_id: profileId || currentId,
      };

      if (_.get(selectedRoom, 'type') === 'group') {
        bodyData = {
          ...bodyData,
          room_id: currentRoomId,
        };
      }

      mediaLog({
        status: 2,
        name: attachment,
        fileType: localData.attachment_type,
        description: 'Upload success file via Inbox',
      });

      this.setState({
        sendingVoiceMessage: true,
      });

      this.props.sendMessage(bodyData).then(() => {
        if (!_.get(localData, 'attachment_type', '').includes('audio')) {
          this.props.removeLocalMessage(currentRoomId, localData.key); // Handled replace local message in inbox-new reducer
        }
        revokeObjectURL(localData.attachment);
        this.setState({ uploadConfigs: [], sendingVoiceMessage: false });

        const recordTimes = parseInt(localStorage.getItem(`${user._id}`));

        if (_.get(localData, 'attachment_type', '').includes('audio') && recordTimes < 3) {
          localStorage.setItem(`${user._id}`, recordTimes + 1);
        }
      });
    }
  };

  onSubmit = message => {
    const {
      profileId,
      featureOff,
      selectedRoom,
      createNewGroup,
      push,
      removeRoomDraftMessage,
      getLatestMessages,
      sendMessage,
      addNewLocalMessages,
      resetFirstLoadList,
    } = this.props;
    const { selectedClient } = this.state;

    if (featureOff) {
      return;
    }

    const { type, data } = message;
    const reply_message = _.get(this.state, 'replyMessage.value');
    const reply_message_id = _.get(this.state, 'replyMessage.key');
    let room_id = profileId;

    switch (type) {
      case MESSAGE_TYPES.TEXT:
        if (profileId) {
          if (_.get(selectedRoom, 'type') === 'group') {
            if (this.contentRef.current) {
              this.contentRef.current.scrollTop = this.contentRef.current.scrollHeight;
              sendMessage &&
                sendMessage({ room_id: profileId, message: data, reply_message_id }).then(() => {
                  const { scrollHeight, clientHeight } = this.contentRef.current;
                  this.scrolling = scrollHeight - clientHeight;
                  this.contentRef.current.scrollTop = scrollHeight - clientHeight;
                  this.setState({ isScrolling: false });
                });
            }
          } else {
            if (this.contentRef.current) {
              this.contentRef.current.scrollTop = this.contentRef.current.scrollHeight;
              sendMessage &&
                sendMessage({ chat_id: profileId, message: data, reply_message_id }).then(() => {
                  const { scrollHeight, clientHeight } = this.contentRef.current;
                  this.scrolling = scrollHeight - clientHeight;
                  this.contentRef.current.scrollTop = scrollHeight - clientHeight;
                  this.setState({ isScrolling: false });
                });
            }
          }
        } else {
          if ((selectedClient.length === 1 && selectedClient[0].typeName === 'group') || selectedClient.length > 1) {
            createNewGroup &&
              createNewGroup(selectedClient, roomId => {
                room_id = roomId;
                setTimeout(() => {
                  sendMessage && sendMessage({ room_id: roomId, message: data, reply_message_id });
                }, 500);
              });
          } else {
            sendMessage &&
              sendMessage({ chat_id: selectedClient[0]._id, message: data, reply_message_id }).then(() => {
                room_id = selectedClient[0]._id;
                push && push(`/home/inbox/${selectedClient[0]._id}`);
              });
          }
        }

        break;

      case MESSAGE_TYPES.FILE:
        setTimeout(() => {
          // if (_.size(data) > 1 || (_.size(data) === 1 && !_.get(data, '[0].file.type', '').includes('audio'))) {
          resetFirstLoadList && resetFirstLoadList();
          this.sendMessageMultipleMedia({ messages: data, reply_message_id, reply_message });
          setTimeout(() => {
            if (this.contentRef.current) {
              const { scrollHeight, clientHeight } = this.contentRef.current;
              this.scrolling = scrollHeight - clientHeight;
              this.contentRef.current.scrollTop = scrollHeight - clientHeight;
              this.setState({ isScrolling: false });
            }
          }, 500);
          // } else {
          //   const localMessages = _.map(data, (item, index) =>
          //     this.initLocalMessageData(item, index, reply_message_id, reply_message),
          //   );
          //   if (localMessages.length) {
          //     if (!room_id) {
          //       if (
          //         (selectedClient.length === 1 && selectedClient[0].typeName === 'group') ||
          //         selectedClient.length > 1
          //       ) {
          //         createNewGroup &&
          //           createNewGroup(selectedClient, roomId => {
          //             room_id = roomId;
          //             setTimeout(() => {
          //               addNewLocalMessages && addNewLocalMessages(localMessages);
          //               this.uploading = true;
          //             }, 2000);
          //           });
          //       } else {
          //         room_id = selectedClient[0]._id;
          //         push && push(`/home/inbox/${selectedClient[0]._id}`);
          //         addNewLocalMessages && addNewLocalMessages(localMessages);
          //         this.uploading = true;
          //       }
          //     } else {
          //       addNewLocalMessages && addNewLocalMessages(localMessages);
          //       this.uploading = true;
          //     }
          //     setTimeout(
          //       () => {
          //         if (this.contentRef.current) {
          //           const { scrollHeight, clientHeight } = this.contentRef.current;
          //           this.scrolling = scrollHeight - clientHeight;
          //           this.contentRef.current.scrollTop = scrollHeight - clientHeight;
          //           this.setState({ isScrolling: false });
          //         }
          //       },
          //       isSafari ? 800 : 500,
          //     );
          //   }
          // }
        }, 300);

        break;

      default:
        break;
    }

    removeRoomDraftMessage && removeRoomDraftMessage(room_id);
    getLatestMessages && getLatestMessages();
    !_.isEmpty(this.state.replyMessage) && this.handleCancelReply();
  };

  onSelectClient = data => {
    this.autoOpenMenu = false;
    this.setState({ selectedClient: data }, () => {
      if (this.props.isGroupChatPermission || !data) return;
      this.props.selectClientFromSearchBar(data._id);

      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
    this.props.newConversation && this.props.enableChatInput(!(data || []).length);
  };

  onTurnOnFeature = () => {
    if (this.isTurningOnFeature) {
      return false;
    }

    this.isTurningOnFeature = true;
    this.props.turnOnFeature(this.props.profileId).finally(() => {
      this.isTurningOnFeature = false;
    });
  };

  searchClients = (inputValue, callback) => {
    const { selectedClient } = this.state;
    const { isGroupChatPermission, getContactsList } = this.props;
    const except = _.map(
      _.filter(selectedClient, item => _.get(item, 'typeName') === 'group'),
      '_id',
    );
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    if (isGroupChatPermission) {
      getContactsList({ search, except })
        .then(response => {
          if (response.length) {
            const data = response.map(item => item.data.data);

            const clients = data[1].data.map(client => ({
              ...client,
              typeName: 'client',
              name: `${client.first_name} ${client.last_name}`,
            }));
            const groups = data[0].map(group => ({
              ...group,
              typeName: 'group',
            }));
            const results = [...groups, ...clients];

            callback(
              _.map(results, item => ({
                ...item,
                key: item._id,
                value: item._id,
                label: item.name,
                total: item.total_clients,
                typeName: item.typeName,
              })),
            );
          } else {
            callback([]);
          }
        })
        .catch(error => {
          callback([]);
        });
    } else {
      axiosInstance
        .get(`/api/inbox/searchClient/v2?q=${search}`)
        .then(response => {
          const { data } = response.data;

          if (data) {
            callback(
              _.map(data, item => ({
                ...item,
                key: item._id,
                value: item._id,
                typeName: 'client',
                label: `${item.first_name} ${item.last_name}`,
              })),
            );
          } else {
            callback([]);
          }
        })
        .catch(error => {
          callback([]);
        });
    }
  };

  onDrop = files => {
    if (files.length && this.inputRef.current) {
      typeof this.inputRef.current.onDropFiles === 'function' && this.inputRef.current.onDropFiles(files);
    }
  };

  handleRenderVideo = item => {
    return (
      <S3Video videoLink={item.attachment} videoType={item.attachment_type} thumbnail={item.attachment_thumbnail} />
    );
  };

  handleRenderThumbnail = (thumbnail, backup, isAttachmentGiphy, isImage) => {
    if (isAttachmentGiphy) {
      return (
        <GiphyWatermark isInbox={true}>
          <S3Images src={[thumbnail]} />
        </GiphyWatermark>
      );
    }

    if (isImage) {
      return (
        <GiphyWatermark isInbox={true} showWatermark={false}>
          <S3Images src={[thumbnail]} />
        </GiphyWatermark>
      );
    }

    if (thumbnail.includes('thumbnail')) {
      return (
        <GiphyWatermark isInbox={true} showWatermark={false}>
          <S3Images src={[thumbnail]} />
        </GiphyWatermark>
      );
    }
    return (
      <GiphyWatermark isInbox={true} showWatermark={false}>
        <S3Images src={[backup]} />
      </GiphyWatermark>
    );
  };

  handleProgressVoiceChat = progress => {
    this.setState({
      progressVoiceChat: progress,
    });
  };

  handlePlayingVoiceChat = playing => {
    this.setState({
      playingVoiceChat: playing,
    });
  };

  handlePauseVoiceChat = () => {
    this.setState({
      playingVoiceChat: '',
    });
    document.querySelectorAll('audio').forEach(el => {
      el.pause();
      el.currentTime = 0;
    });
  };

  handleReplyMessage = (isOwner, first_name, item) => () => {
    if (this.state.showRecord) return;
    let type = 'text';
    let content = item.content;
    let owner = 'yourself';

    if (!isOwner) {
      owner = first_name;
    }

    const messageType = getMessageType(item);
    if (!_.isEqual(messageType, MESSAGE_TYPE.TEXT)) {
      content = '';
      if (_.isEqual(messageType, MESSAGE_TYPE.MULTIPLE_MEDIA)) {
        type = 'Attached files';
      } else {
        const attachmentType = _.get(item, 'attachment_type', '');
        if (attachmentType.startsWith('image')) {
          type = 'Image';
          if (attachmentType.includes('gif')) type = 'GIF file';
        }
        if (attachmentType.startsWith('video')) type = 'Video';
        if (attachmentType.startsWith('audio')) type = 'Attached file';
      }
    }

    this.setState(
      {
        replyMessage: {
          type,
          content,
          owner,
          key: item.key,
          value: item,
        },
      },
      () => {
        if (this.inputRef.current) {
          this.inputRef.current.focus();
        }
      },
    );
  };

  handleCancelReply = () => {
    this.setState({
      replyMessage: {},
    });
  };

  scrollToOriginalMessage = messageId => {
    const {
      viewTeammate,
      getMessagesAtId,
      getMessagesAdminRoomAtId,
      currentRoomId,
      currentTeam,
      archivedMessages,
      getMessagesArchivedRoomAtId,
      user,
      profileId,
    } = this.props;
    if (this.state.showRecord) return;
    this.isScrollingToOriginal = true;
    const messageElement = document.getElementById(`content-${messageId}`);
    if (!messageElement) {
      const scrollUpAfterGetMessages = () => {
        const messageElement = document.getElementById(`content-${messageId}`);
        if (messageElement) {
          messageElement.classList.remove('highlight');
          messageElement.scrollIntoView({ block: 'center' });
          messageElement.classList.add('highlight');
          if (this.contentRef.current) {
            const { scrollTop } = this.contentRef.current;
            if (scrollTop > this.scrolling) this.scrolling = scrollTop;
            this.setState({ scrolling: true }, () => {
              this.isScrollingToOriginal = false;
            });
          }
        }
      };

      // Load archived messages
      if (archivedMessages.length) {
        getMessagesArchivedRoomAtId &&
          getMessagesArchivedRoomAtId(`${user._id}_${profileId}`, messageId).then(() => {
            scrollUpAfterGetMessages();
          });
      } else {
        // Load admin convo messages
        if (viewTeammate && user && profileId) {
          getMessagesAdminRoomAtId &&
            getMessagesAdminRoomAtId(currentTeam, currentRoomId, messageId).then(() => {
              scrollUpAfterGetMessages();
            });
        } else {
          // Load normal messages
          getMessagesAtId &&
            getMessagesAtId(currentRoomId, messageId).then(() => {
              scrollUpAfterGetMessages();
            });
        }
      }
    } else {
      messageElement.classList.remove('highlight');
      messageElement.scrollIntoView({ block: 'center' });
      messageElement.classList.add('highlight');
      if (this.contentRef.current) {
        const { scrollTop } = this.contentRef.current;
        if (scrollTop > this.scrolling) this.scrolling = scrollTop;
        this.setState({ scrolling: this.scrolling - scrollTop > 500 }, () => {
          this.isScrollingToOriginal = false;
        });
      }
    }
  };

  renderMessage = (item, index) => {
    if (_.isEmpty(item)) return;
    const { progressVoiceChat = 0, playingVoiceChat, showRecord } = this.state;
    const {
      messages,
      user,
      room,
      viewTeammate,
      archivedMessages,
      roomInformation,
      currentRoomId,
      usersInfo,
      isGroupChatPermission,
      cloudfrontList,
    } = this.props;
    const roomData = _.get(room, 'parnerData');
    const isGroup = _.get(room, 'type') === 'group';
    const currentList = !roomData && archivedMessages.length > 0 ? archivedMessages : messages;

    const messageTime = moment(item.timestamp);
    const isOwner = viewTeammate ? item.sender === viewTeammate : item.sender === user._id;
    const currentOwner = !viewTeammate
      ? user
      : [...user.team_member, ...user.team_old_members].find(it => it._id === viewTeammate);
    const showMoreInfo =
      !currentList[index + 1] ||
      currentList[index + 1].sender !== item.sender ||
      currentList[index + 1].system_message_type !== undefined;
    const showName =
      (!currentList[index - 1] ||
        currentList[index - 1].sender !== item.sender ||
        currentList[index - 1].system_message_type !== undefined) &&
      _.isEmpty(_.get(currentList[index], 'reply_message_id'));
    const showGroupDate =
      !currentList[index - 1] || !moment(currentList[index - 1].timestamp).isSame(messageTime, 'day');
    const nextOwner = currentList[index + 1] ? currentList[index + 1].sender : null;
    const ownerInfo = isOwner
      ? currentOwner
      : archivedMessages.length > 0
      ? roomInformation
      : isGroup || viewTeammate
      ? usersInfo.find(it => it._id === item.sender) || _.get(item, 'sender_info', {})
      : _.get(room, 'parnerData', {});

    const showSeenIndicator =
      isOwner && showMoreInfo && index < currentList.length - Number(_.get(room, 'parnerData.unread', 0)) && !isGroup;

    const isMedia = item.content === MEDIA_FILE_REJECTED_CONTENT && item.attachment;
    const isVideo = item.attachment_type && item.attachment_type.startsWith('video');
    const isAudio =
      (item.attachment_type && item.attachment_type.startsWith('audio')) ||
      _.get(item, 'attachments[0].attachment_type', '').startsWith('audio');
    const isImage = item.attachment_type && item.attachment_type.startsWith('image');
    const thumbnail = isMedia ? item.attachment_thumbnail || (!isVideo ? item.attachment : null) : null;
    let removeMessageText = 'This message was unsent';
    const isDeleted = item.was_deleted || item.was_system_deleted;
    if (item.was_system_deleted) {
      removeMessageText = 'Message removed';
    }
    const messageKey = item.localKey || item.key;
    // const isAttachmentGiphy = _.isEqual(_.get(item, 'attachment_source'), 'giphy');
    const replyMessageId = _.get(item, 'reply_message_id');
    const replyMessage = _.get(item, 'reply_message');
    const isReplied = !_.isEmpty(replyMessageId) && !isDeleted;
    const isRepliedSelf = _.isEqual(_.get(item, 'reply_message.sender'), _.get(item, 'sender'));
    const isRepliedMe = _.isEqual(_.get(item, 'reply_message.sender'), _.get(user, '_id'));
    const messageType = getMessageType(item);
    const isMultipleMedia = _.isEqual(messageType, MESSAGE_TYPE.MULTIPLE_MEDIA);
    const senderInfo = usersInfo.find(it => it._id === _.get(item, 'sender'));
    const senderReplyInfo = usersInfo.find(it => it._id === _.get(item, 'reply_message.sender'));

    const system_message_type = _.get(item, 'system_message_type');
    if (!_.isNil(system_message_type)) {
      return (
        <React.Fragment key={messageKey}>
          {showGroupDate && !messageTime.isSame(new Date(), 'day') && (
            <S.GroupDate>{messageTime.format('dddd, DD MMM YYYY')}</S.GroupDate>
          )}
          {showGroupDate && messageTime.isSame(new Date(), 'day') && <S.GroupDate>Today</S.GroupDate>}
          <SystemMessage
            key={messageKey}
            message={item}
            isOwner={item.sender === user._id}
            userId={user._id}
            usersInfo={usersInfo}
            viewTeammate={viewTeammate}
          />
        </React.Fragment>
      );
    }
    const isRepliedWasSystemDeleted = !!replyMessage && _.get(replyMessage, 'was_system_deleted', false);
    const hasGroupChatPermission = isGroup && !isGroupChatPermission;

    return (
      <React.Fragment key={messageKey}>
        {showGroupDate && !messageTime.isSame(new Date(), 'day') && (
          <S.GroupDate>{messageTime.format('dddd, DD MMM YYYY')}</S.GroupDate>
        )}
        {showGroupDate && messageTime.isSame(new Date(), 'day') && <S.GroupDate>Today</S.GroupDate>}
        <S.MessageContainer
          isOwner={isOwner}
          bottomSpace={index !== currentList.length - 1 && nextOwner !== item.sender}
          showMoreInfo={showMoreInfo}
          isMedia={isMedia}
          isMultipleMedia={isMultipleMedia}
          isDeleted={isDeleted}
          id={messageKey}
          progress={progressVoiceChat}
          playing={playingVoiceChat.includes(item.key)}
          pause={playingVoiceChat === `${item.key}-pause`}
          duration={playingVoiceChat.includes(item.key) ? Math.round(item.attachment_duration / 1000) : 0}
        >
          {showName && isGroup && (viewTeammate || item.sender !== user._id) ? (
            <S.Name>
              {ownerInfo.is_removed
                ? DELETED_CLIENT_NAME
                : ownerInfo.full_name || `${ownerInfo.first_name} ${ownerInfo.last_name}`}
            </S.Name>
          ) : null}
          {isReplied && (
            <TittleReplied
              isOwner={isOwner}
              isRepliedSelf={isRepliedSelf}
              isRepliedMe={isRepliedMe}
              firstName={_.get(senderInfo, 'is_removed') ? DELETED_CLIENT_NAME : _.get(senderInfo, 'first_name', '')}
              firstNameReplied={
                _.get(senderReplyInfo, 'is_removed') ? DELETED_CLIENT_NAME : _.get(senderReplyInfo, 'first_name', '')
              }
              viewTeammate={viewTeammate}
              room={room}
            />
          )}
          {isReplied && (
            <RepliedMessage
              replyMessage={replyMessage}
              isOwner={isOwner}
              showRecord={showRecord}
              onClick={() => !isRepliedWasSystemDeleted && this.scrollToOriginalMessage(replyMessageId)}
              disabled={isRepliedWasSystemDeleted}
            />
          )}
          <S.ReplyMessageWrapper isOwner={isOwner} isReplied={isReplied}>
            {!viewTeammate && !item.isLocalFile && !isDeleted && (
              <S.MoreOptionWrapper id={`action-${messageKey}`}>
                {isOwner && !hasGroupChatPermission ? (
                  <MessageAction
                    messageKey={messageKey}
                    currentRoomId={currentRoomId}
                    disabled={showRecord}
                    toggleConfirmModal={this.props.toggleConfirmModal}
                    deleteMessage={this.props.deleteMessage}
                  />
                ) : null}
                {!hasGroupChatPermission ? (
                  <>
                    <S.ReplyIcon
                      onClick={this.handleReplyMessage(isOwner, ownerInfo.first_name, item)}
                      showRecord={showRecord}
                      data-for="reply-tooltip-icon"
                      data-tip
                    >
                      <ReplyIcon />
                    </S.ReplyIcon>
                    <SharedTooltip id="reply-tooltip-icon" content="Reply" />
                  </>
                ) : null}
              </S.MoreOptionWrapper>
            )}
            {isAudio && !isDeleted ? (
              <S.AudioMessageWrapper id={`content-${messageKey}`}>
                <S.AudioMessage
                  progress={progressVoiceChat}
                  playing={playingVoiceChat.includes(messageKey)}
                  pause={playingVoiceChat === `${messageKey}-pause`}
                  showRecord={showRecord}
                  duration={playingVoiceChat.includes(item.key) ? Math.round(item.attachment_duration / 1000) : 0}
                  key={`au-${item.key}`}
                >
                  {item.isLocalFile ? (
                    <>
                      <MultipleMediaMessage
                        message={item}
                        showRecord={showRecord}
                        cloudfrontList={cloudfrontList}
                        removeLocalMessage={this.props.removeLocalMessage}
                        currentRoomId={this.props.currentRoomId}
                        sendMessage={this.props.sendMessage}
                        scrollToBottom={this.scrollToBottom}
                        getS3presignedURLFromLocalDatabase={this.props.getS3presignedURLFromLocalDatabase}
                        cancelUploadRequest={this.props.cancelUploadRequest}
                        voiceMessage
                      />
                      <AudioPlayer duration={item.attachment_duration / 1000} uploading={true} />

                      {/**
                       <FileUpload
                        onSuccess={responseData => this.onUploadSuccess(item, responseData)}
                        onError={() => {}}
                        onCancel={error => this.onCancelUpload(item, error)}
                        configs={item.uploadConfigs}
                      >
                        <AudioPlayer duration={item.attachment_duration / 1000} uploading={true} />
                      </FileUpload>
                       */}
                    </>
                  ) : (
                    <AudioPlayer
                      url={item.attachment}
                      duration={Math.floor(item.attachment_duration / 1000)}
                      audioKey={messageKey}
                      activeKey={playingVoiceChat}
                      showRecord={showRecord}
                      onPlay={this.handlePlayingVoiceChat}
                      onProgress={this.handleProgressVoiceChat}
                    />
                  )}
                </S.AudioMessage>
              </S.AudioMessageWrapper>
            ) : (
              <S.Message id={`content-${messageKey}`} isDeleted={isDeleted}>
                {isDeleted ? (
                  <S.ContentWrapper>
                    <Urlify>{removeMessageText}</Urlify>
                  </S.ContentWrapper>
                ) : isMultipleMedia ? (
                  <MultipleMediaMessage
                    message={item}
                    showRecord={showRecord}
                    cloudfrontList={this.props.cloudfrontList}
                    removeLocalMessage={this.props.removeLocalMessage}
                    currentRoomId={this.props.currentRoomId}
                    sendMessage={this.props.sendMessage}
                    scrollToBottom={this.scrollToBottom}
                    getS3presignedURLFromLocalDatabase={this.props.getS3presignedURLFromLocalDatabase}
                    cancelUploadRequest={this.props.cancelUploadRequest}
                  />
                ) : isMedia ? (
                  <>
                    <MultipleMediaMessage
                      message={item}
                      showRecord={showRecord}
                      cloudfrontList={this.props.cloudfrontList}
                      removeLocalMessage={this.props.removeLocalMessage}
                      currentRoomId={this.props.currentRoomId}
                      sendMessage={this.props.sendMessage}
                      scrollToBottom={this.scrollToBottom}
                      getS3presignedURLFromLocalDatabase={this.props.getS3presignedURLFromLocalDatabase}
                      cancelUploadRequest={this.props.cancelUploadRequest}
                    />
                    {/* <S.MediaBox showRecord={showRecord}>
                      {thumbnail
                        ? this.handleRenderThumbnail(thumbnail, item.attachment, isAttachmentGiphy, isImage)
                        : isVideo
                        ? this.handleRenderVideo(item)
                        : null}
                      {item.isLocalFile ? (
                        <FileUpload
                          onSuccess={responseData => this.onUploadSuccess(item, responseData)}
                          onError={() => {}}
                          onCancel={error => this.onCancelUpload(item, error)}
                          configs={item.uploadConfigs}
                        />
                      ) : (
                        <MediaPlayer
                          url={item.attachment}
                          type={item.attachment_type}
                          showRecord={showRecord}
                          onPause={this.handlePauseVoiceChat}
                        />
                      )}
                    </S.MediaBox> */}
                  </>
                ) : (
                  <S.ContentWrapper>
                    <Urlify>{item.content}</Urlify>
                  </S.ContentWrapper>
                )}
              </S.Message>
            )}
            {showMoreInfo && ownerInfo && (
              <Avatar
                name={ownerInfo.is_removed ? DELETED_CLIENT_NAME : getUserShortName(ownerInfo)}
                size="35"
                src={convertS3UrlToCloudFrontUrl(_.get(ownerInfo, 'avatar', ''), cloudfrontList, true)}
                color={_.get(ownerInfo, 'color', '')}
              />
            )}
          </S.ReplyMessageWrapper>
          {showMoreInfo ? (
            <>
              <S.Time data-tip data-for={`message-time${index}`}>
                {messageTime.format('hh:mm A')}
                {showSeenIndicator ? ' ∙ Seen' : ''}
              </S.Time>
              <ReactTooltip className="app-tooltip" id={`message-time${index}`} effect="solid" place="bottom">
                <span>{messageTime.format('MMM DD [at] hh:mm A')}</span>
              </ReactTooltip>
            </>
          ) : null}
        </S.MessageContainer>
      </React.Fragment>
    );
  };

  handleSelectMedia = async (files, data, callback) => {
    let config = [];
    let media = _.map(files, ele => ele.file);

    const attachments = await getMultiplePresignedUploadUrlsByParams(media, {
      method: 'POST',
      url: 'api/file/gen-presigned-urls-inbox',
      data: { files: media.map(el => ({ name: el.name, mimetype: el.type })) },
    });

    _.map(attachments, (item, idx) => {
      config.push({
        url: item.uploadUrl,
        method: 'put',
        data: media[idx],
        headers: { 'Content-Type': media[idx].type },
      });
    });

    this.setState({ uploadConfigs: config }, () => {
      data && this.onSubmit(data);
      callback && callback();
    });
  };

  handleRemoveMedia = index => {
    const newConfigs = this.state.uploadConfigs.slice();
    newConfigs.splice(index, 1);
    this.setState({ uploadConfigs: newConfigs });
  };

  onGifClick = gif => {
    const { selectedClient } = this.state;
    const { selectedRoom, sendMessage, createNewGroup, getLatestMessages, resetFirstLoadList, push } = this.props;
    const profileId = _.get(this.props, 'profileId');
    const url = _.get(gif, 'images.original.url');
    const id = _.get(gif, 'id');
    const reply_message_id = _.get(this.state, 'replyMessage.key');
    if (!_.isEmpty(url) && !_.isEmpty(id)) {
      let data = {
        chat_id: profileId,
        message: MEDIA_FILE_REJECTED_CONTENT,
        attachment: url,
        attachment_id: id,
        attachment_type: 'image/gif',
        attachment_source: 'giphy',
        reply_message_id,
      };

      if (profileId) {
        if (_.get(selectedRoom, 'type') === 'group') {
          data = { room_id: profileId, ...data };
        }
        if (this.contentRef.current) {
          this.contentRef.current.scrollTop = this.contentRef.current.scrollHeight;
          sendMessage &&
            sendMessage(data).then(() => {
              getLatestMessages();
              resetFirstLoadList();
              const { scrollHeight, clientHeight } = this.contentRef.current;
              this.scrolling = scrollHeight - clientHeight;
              this.contentRef.current.scrollTop = scrollHeight - clientHeight;
              this.setState({ isScrolling: false });
            });
        }
      } else {
        if ((selectedClient.length === 1 && selectedClient[0].typeName === 'group') || selectedClient.length > 1) {
          createNewGroup &&
            createNewGroup(selectedClient, roomId => {
              setTimeout(() => {
                sendMessage &&
                  sendMessage({ room_id: roomId, ...data }).then(() => {
                    if (this.contentRef.current) {
                      const { scrollHeight, clientHeight } = this.contentRef.current;
                      this.scrolling = scrollHeight - clientHeight;
                      this.contentRef.current.scrollTop = scrollHeight - clientHeight;
                      this.setState({ isScrolling: false });
                    }
                  });
              }, 500);
            });
        } else {
          sendMessage({ ...data, chat_id: selectedClient[0]._id }).then(() => {
            push && push(`/home/inbox/${selectedClient[0]._id}`);
            // if (this.contentRef.current) {
            //   const { scrollHeight, clientHeight } = this.contentRef.current;
            //   this.scrolling = scrollHeight - clientHeight;
            //   this.contentRef.current.scrollTop = scrollHeight - clientHeight;
            //   this.setState({ isScrolling: false });
            // }
          });
        }
      }
    }
    !_.isEmpty(this.state.replyMessage) && this.handleCancelReply && this.handleCancelReply();
  };

  handleCreateGroup = () => {
    if (this.loadingCreateGroup) return;
    const { selectedClient } = this.state;
    const { createNewGroup } = this.props;
    this.autoOpenMenu = false;
    this.loadingCreateGroup = true;
    selectedClient &&
      createNewGroup &&
      createNewGroup(selectedClient, roomId => {
        this.setState({
          selectedClient: [],
        });
      }).finally(() => {
        setTimeout(() => {
          this.loadingCreateGroup = false;
        }, 500);
      });
  };

  handleScrollToBottom = () => {
    if (this.isScrollingToOriginal) return;
    const {
      currentRoomId,
      getMessagesBottomAtId,
      viewTeammate,
      getMessagesAdminRoomBottomAtId,
      currentTeam,
      getMessagesArchivedRoomBottomAtId,
      archivedMessages,
      user,
      profileId,
    } = this.props;

    if (archivedMessages.length && user && profileId) {
      getMessagesArchivedRoomBottomAtId && getMessagesArchivedRoomBottomAtId(`${user._id}_${profileId}`);
    } else {
      if (viewTeammate) {
        getMessagesAdminRoomBottomAtId(currentTeam, currentRoomId);
      } else {
        getMessagesBottomAtId(currentRoomId);
      }
    }
  };

  handleScrollEvent = () => {
    if (this.isScrollingToOriginal) return;
    const { loading, firstLoadList } = this.props;
    if (this.contentRef.current && !loading) {
      const { scrollHeight, clientHeight, scrollTop } = this.contentRef.current;
      const isBottom = scrollTop >= scrollHeight - clientHeight - THRESHOLD;
      const isTop = scrollTop < THRESHOLD;
      if (
        isBottom &&
        this.enableScrollingBottom &&
        !this.isScrollingToOriginal &&
        !this.isGoToLatest &&
        !firstLoadList
      ) {
        this.scrollBottomDebounce();
      } else {
        if (isTop) {
          this.scrollTopDebounce();
        }
      }
    }
  };

  handleShowDownButtonEvent = () => {
    if (this.isScrollingToOriginal || this.isGoToLatest || !this.enableScrollingBottom || !this.contentRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = this.contentRef.current;
    const isScrollingAtBottom =
      scrollTop > this.scrolling ||
      scrollHeight - clientHeight >= this.scrolling ||
      this.scrolling >= scrollHeight - clientHeight;

    if (isScrollingAtBottom) {
      this.scrolling = scrollHeight - clientHeight;
    }

    const { bottomItem } = this.props;

    if (!_.isEmpty(bottomItem)) {
      if (!this.state.scrolling) {
        this.setState({ scrolling: true });
      }
      return;
    }

    const newScrolling = this.scrolling - scrollTop > 500;

    if (newScrolling !== this.state.scrolling) {
      this.setState({ scrolling: newScrolling });
    }
  };

  handleCheckEndOfBottomEvent = () => {
    const { bottomItem } = this.props;
    const { scrollHeight, clientHeight, scrollTop } = this.contentRef.current;

    if (!_.isEmpty(bottomItem)) {
      this.atLatestMessage = false;
      return;
    }

    const isAtLatestMessage = Math.round(scrollTop) >= scrollHeight - clientHeight - 31;
    this.atLatestMessage = isAtLatestMessage;
  };

  render() {
    const {
      room,
      messages,
      newConversation,
      featureOff,
      draftMessage,
      viewTeammate,
      roomInformation,
      user,
      archivedMessages,
      loading,
      isDisabled,
      unmuteConversation,
      selectedRoom,
      invalidRoom,
      isGroupChatPermission,
    } = this.props;
    if (invalidRoom) return <S.Wrapper />;
    const { startRecord, showRecord, sendingVoiceMessage, selectedClient, scrolling } = this.state;
    let roomData = _.get(room, 'parnerData');
    const mute_until = _.get(room, 'mute_until', null) || _.get(selectedRoom, 'mute_until', null);
    let roomName = roomData
      ? `${roomData.first_name} ${roomData.last_name}`
      : roomInformation
      ? `${roomInformation.first_name} ${roomInformation.last_name}`
      : '';
    const isGroup = _.get(room, 'type') === 'group';

    if (isGroup) {
      roomData = room;
      roomName =
        _.get(room, 'room_name') || generateRoomName(_.get(room, 'room_sub_name'), _.get(room, 'total_members', 0));
    }
    if (_.get(room, 'type') === 'direct' && viewTeammate) {
      roomData = room;
      roomName = _.get(room, 'room_name');
    }
    const teamIds = user.team_old_members.map(it => it._id) || [];
    const currentParams = _.get(this.props, 'match.params', {});
    const currentId = currentParams && currentParams.profileId;
    const shouldCreateGroup =
      selectedClient &&
      (_.get(selectedClient[0], 'total_clients', 0) > 1 || selectedClient.length > 1) &&
      isGroupChatPermission;
    const shouldHideChatBox =
      viewTeammate ||
      (selectedClient && selectedClient.length === 1 && _.get(selectedClient[0], 'is_trainer')) ||
      (isGroup && !isGroupChatPermission);
    const isMute = mute_until !== null && (mute_until > Date.now() || mute_until < 0);
    const isShowMessages = this.props.isGroupChatPermission ? roomData || currentId : roomData && currentId;

    return (
      <S.Wrapper>
        <Dropzone onDrop={this.onDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Container {...getRootProps()}>
              <S.Header disabled={this.loadingCreateGroup}>
                {newConversation && !roomInformation ? (
                  <S.SelectClientContainer>
                    <div className="title">To:</div>
                    <SearchParticipant
                      onChange={this.onSelectClient}
                      name="search-participant"
                      defaultOptions={true}
                      loadOptions={(inputValue, callback) => this.searchClientDebounce.call(this, inputValue, callback)}
                      autoFocus={roomInformation ? false : true}
                      autoOpen={this.autoOpenMenu}
                      value={selectedClient}
                      placeholder={isGroupChatPermission ? 'Type the name of person or group' : 'Select...'}
                      emptyIcon={<NoContactsIcon />}
                      classNamePrefix="isp"
                      isMulti={isGroupChatPermission}
                      className="search-participant-list"
                    />
                    {shouldCreateGroup ? (
                      <S.CreateButton onClick={this.handleCreateGroup} disabled={this.loadingCreateGroup}>
                        Create Group
                      </S.CreateButton>
                    ) : null}
                  </S.SelectClientContainer>
                ) : (
                  <>
                    <S.ClientName>{roomName || <span />}</S.ClientName>
                    {room ? (
                      <RoomAction
                        type="light"
                        roomId={room.id}
                        readOnly={viewTeammate || (isGroup && !isGroupChatPermission)}
                        isGroup={isGroup}
                        isMute={isMute}
                      />
                    ) : null}
                  </>
                )}
              </S.Header>
              {isMute ? (
                <S.MuteConversationWrapper>
                  <S.MutedText>
                    {mute_until < 0
                      ? 'Muted forever.'
                      : `Muted until ${DateTime.fromISO(new Date(mute_until).toISOString()).toFormat(
                          'hh:mm a MMM dd, yyyy',
                        )}.`}
                  </S.MutedText>
                  <S.UnmuteButton
                    disabled={!isGroupChatPermission}
                    onClick={() => isGroupChatPermission && unmuteConversation(_.get(room, 'id'))}
                  >
                    Unmute
                  </S.UnmuteButton>
                </S.MuteConversationWrapper>
              ) : null}
              <S.Content
                id={`detail-content-${currentId}`}
                ref={this.contentRef}
                onScroll={this.scrollingEventDebounce}
              >
                {loading && (
                  <S.LoadingWrapper>
                    <LoadingIndicator title="Loading..." />
                  </S.LoadingWrapper>
                )}
                {!roomData && archivedMessages.length > 0 && (
                  <S.List showRecord={showRecord}>{_.map(roomName ? archivedMessages : [], this.renderMessage)}</S.List>
                )}
                {isShowMessages ? <S.List showRecord={showRecord}>{_.map(messages, this.renderMessage)}</S.List> : null}
              </S.Content>
              {!shouldHideChatBox ? (
                <S.Footer hide={shouldHideChatBox}>
                  <ChatInput
                    placeholder="Type message here"
                    onPost={this.onSubmit}
                    ref={this.inputRef}
                    user={user}
                    autoFocus={false}
                    disabled={featureOff || isDisabled || this.uploading}
                    defaultValue={draftMessage}
                    startRecord={startRecord}
                    showRecord={showRecord}
                    sendingVoiceMessage={sendingVoiceMessage}
                    onSelect={this.handleSelectMedia}
                    onRemove={this.handleRemoveMedia}
                    onStartRecord={() => this.setState({ startRecord: true })}
                    onStopRecord={() => this.setState({ startRecord: false, sendingVoiceMessage: false })}
                    onToggleRecord={() => this.setState(it => ({ showRecord: !it.showRecord }))}
                    onSendRecord={() => this.setState({ sendingVoiceMessage: true })}
                    onPause={this.handlePauseVoiceChat}
                    isInbox={true}
                    giphyEnable={true}
                    onGifClick={this.onGifClick}
                    replyMessage={this.state.replyMessage}
                    onCancelReply={this.handleCancelReply}
                    scrolling={scrolling}
                    onGoToBottom={this.handleGoToBottom}
                    notCacheMessage
                    isReplyInbox={true}
                    maxSizeVideo={LIMIT_SIZE_VIDEO_UPLOAD_MB}
                  />
                </S.Footer>
              ) : (
                <S.FloatingButtonWrapper>
                  {scrolling && (
                    <S.FloatingButton onClick={this.handleGoToBottom}>
                      <ArrowDown />
                    </S.FloatingButton>
                  )}
                </S.FloatingButtonWrapper>
              )}
              {viewTeammate && teamIds.includes(viewTeammate) && (
                <S.Footer>
                  <S.ConversationEnded>Conversation has ended</S.ConversationEnded>
                </S.Footer>
              )}
              {(featureOff && room) || (newConversation && roomInformation && featureOff) ? (
                <S.FeatureOffContainer>
                  <S.TurnOnFeatureMessage>
                    <div className="title">Turn on Message feature?</div>
                    <div className="description">
                      <p>Messages has been turned off for client.</p>
                      <p>Would you like to turn on the feature?</p>
                    </div>
                    <Button purple onClick={this.onTurnOnFeature}>
                      Yes
                    </Button>
                  </S.TurnOnFeatureMessage>
                </S.FeatureOffContainer>
              ) : null}
              <input {...getInputProps({ onClick: event => event.preventDefault() })} />
              <S.DropZonePlaceholderContainer show={isDragActive}>
                <DropZonePlaceholder show={true} />
              </S.DropZonePlaceholderContainer>
            </S.Container>
          )}
        </Dropzone>
      </S.Wrapper>
    );
  }
}

export default InboxContent;
