import React from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import ConfirmModal from 'shared/ConfirmModal';
import { Modal } from 'semantic-ui-react';
import { Button, SelectFile } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';
import './style.scss';
import {
  createObjectURL,
  revokeObjectURL,
  handleCutString,
  getPresignedUploadUrlByParams,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import { ReactComponent as AddImageIcon } from 'assets/icons/add_image_icon.svg';
import FileUpload from 'shared/FileUpload';
import { axiosInstance } from 'configs/request';
import { CDN_URL } from 'constants/commonData';

class WorkoutBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: { ...props.data },
      uploading: false,
      uploadData: {},
    };
    this.deleteItems = [];
    this.uploadConfigs = null;
    this.selectedBackground = React.createRef();
  }

  componentDidMount() {
    this.props.getCustomBrandingData();
    this._isMounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!(this.props.workoutBackgrounds.length + 1 === nextProps.workoutBackgrounds.length)) {
      // prevent running when uploading new image
      this.setState({ selected: { ...nextProps.data } });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { open, selected } = this.state;
    const { data } = this.props;

    if (open && !prevState.open) {
      if (selected._id !== data._id) {
        this.setState({ selected: { ...data } });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true }, () => {
      setTimeout(() => {
        if (this.selectedBackground.current !== null) {
          const isCustomBackground = this.selectedBackground.current.getAttribute('data-isCustom');
          // always run scrollIntoView for default backgrounds
          if (isCustomBackground) {
            if (!this.shouldScrollIntoView()) return; // break function
          }
          this.selectedBackground.current.scrollIntoView(true);
        }
      }, 0);
    });
  };

  shouldScrollIntoView = () => {
    const numOfEverfitBackgrounds = this.props.workoutBackgrounds.filter(item => item.default).length;
    const selectedBackgroundIndex =
      this.selectedBackground.current.getAttribute('data-index') - numOfEverfitBackgrounds;
    if (selectedBackgroundIndex <= 10) {
      return false; // prevent scrollIntoView when selectedBackgroundIndex <= 10
    }
    return true;
  };

  handleItemClick = item => () => {
    const { selected } = this.state;

    if (item._id && item._id !== selected._id) {
      this.setState({ selected: { ...item } });
    }
  };

  handleSelectFile = async file => {
    const { name, type } = file;
    const { uploadUrl, configs } = await getPresignedUploadUrlByParams(file, {
      method: 'POST',
      url: '/api/file/gen-presigned-urls-custom-brand',
      data: {
        fileNames: [name],
      },
    });
    if (!uploadUrl || !configs) {
      return;
    }
    this.uploadConfigs = {
      url: uploadUrl,
      method: 'PUT',
      data: file,
      headers: { 'Content-Type': type },
    };
    const uploadData = { src: createObjectURL(file), file, name, ...configs };
    this.setState({ uploadData, uploading: true });
  };

  handleUploadSuccess = response => {
    const { uploadData } = this.state;
    const name = _.get(uploadData, 'original_name', '');

    const data = {
      location: uploadData.url,
      originalname: name,
    };
    axiosInstance.patch('/api/team/workout-background-library', data).then(response => {
      const workoutBackground = response.data.data.workout_background;
      this.setState({ uploadData: {}, uploading: false, selected: { ...workoutBackground } }, () => {
        this.uploadConfigs = null;
        this.props.addWorkoutBackground(workoutBackground);
        setTimeout(() => {
          if (this.selectedBackground.current !== null) {
            if (this.shouldScrollIntoView()) {
              this.selectedBackground.current.scrollIntoView(true);
            }
          }
        }, 0);
      });
    });
  };

  handleCancelUpload = () => {
    const { uploadData } = this.state;
    revokeObjectURL(uploadData.src);
    this.uploadConfigs = null;
    this.setState({ uploadData: {}, uploading: false });
  };

  handleUploadError = () => {
    this.setState({ uploading: false });
  };

  handleSave = () => {
    const { data } = this.props;
    const { selected, uploading } = this.state;

    if (uploading) {
      return false;
    }

    if (selected._id !== data._id) {
      this.props.onChange({ ...selected });
    }

    this.setState({ open: false });
  };

  handleRemoveItem = (e, item) => {
    e.stopPropagation();
    if (this.deleteItems.includes(item._id)) {
      return false;
    }

    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete Image?"
        content="Are you sure that you want to remove this image?"
        onConfirm={() => {
          this.deleteItems.push(item._id);
          this.props
            .deleteWorkoutBackground(item._id)
            .then(() => {
              const { workoutBackgrounds } = this.props;
              const { selected } = this.state;

              if (selected._id === item._id) {
                this.setState({ selected: { ...(workoutBackgrounds[0] || {}) } });
              }

              if (item.src.startsWith('blob:')) {
                revokeObjectURL(item.src);
              }
            })
            .finally(() => {
              _.remove(this.deleteItems, id => id === item._id);
            });
        }}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  getCloudUrl = originUrl => {
    const { cloudfrontList } = this.props;
    return convertS3UrlToCloudFrontUrl(originUrl, cloudfrontList, true);
  };

  renderUpload = () => {
    const { uploadData } = this.state;
    const { workoutBackgrounds, premiumCustomBrand } = this.props;
    const numOfCustomBackgrounds = workoutBackgrounds.filter(el => el.default !== true).length;

    if (uploadData.file) {
      return (
        <S.Item empty>
          <S.Background background={uploadData.src} />
          <FileUpload
            onSuccess={this.handleUploadSuccess}
            onError={this.handleUploadError}
            onCancel={this.handleCancelUpload}
            configs={this.uploadConfigs}
            progressRadius={22}
            progressBarWidth={3}
            smallCancelButton
          />
        </S.Item>
      );
    } else if (premiumCustomBrand || numOfCustomBackgrounds < 1) {
      // allow upload multiple image if premiumCustomBrand == true
      return (
        <S.Item empty data-tip disablehover data-for="tooltip--select-custom-background">
          <SelectFile
            trigger={
              <S.SelectFileTrigger>
                <AddImageIcon />
              </S.SelectFileTrigger>
            }
            onSelect={this.handleSelectFile}
            accept="image/png, image/jpeg, image/jpg"
            validateExtentions={['png', 'jpg', 'jpeg']}
            maxSize={25}
          />
          <ReactTooltip className="app-tooltip" id="tooltip--select-custom-background">
            <span>Upload your background</span>
          </ReactTooltip>
        </S.Item>
      );
    }

    return null;
  };

  render() {
    const { open, selected, uploading } = this.state;
    const { data, workoutBackgrounds } = this.props;

    return (
      <Modal
        open={open}
        closeOnDimmerClick={false}
        className="modal--custom-workout-background"
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        closeIcon={
          <button className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </button>
        }
        trigger={
          <S.TriggerContainer>
            <S.TriggerImage data-tip data-for="tooltip" background={data ? this.getCloudUrl(data.src) : null} />
            <ReactTooltip place="top" id="tooltip" effect="solid">
              <span style={{ opacity: 1 }}>Update background</span>
            </ReactTooltip>
          </S.TriggerContainer>
        }
      >
        <Modal.Content>
          <S.ContentWrapper>
            <ModalLayout.Header>Choose background</ModalLayout.Header>
            <ModalLayout.Content>
              <S.SubHeader>Your backgrounds</S.SubHeader>
              <S.CustomBackgroundsContainer>
                {this.renderUpload()}
                {_.map(workoutBackgrounds, (item, index) => {
                  // renders custom backgrounds
                  const selectedDefault = item._id === this.props.selectedWorkoutBackground._id;
                  const isCustomBackground = !_.get(item, 'default', false);
                  const activeImage = selected._id === item._id || selected.src === item.src;
                  if (activeImage && isCustomBackground) {
                    return (
                      <S.Item
                        data-index={index}
                        data-isCustom={true}
                        selectedDefault={selectedDefault}
                        empty
                        data-tip
                        data-for={`tooltip--detail-background-${index}`}
                        ref={this.selectedBackground}
                        key={index}
                        active={selected._id === item._id}
                        onClick={this.handleItemClick(item)}
                      >
                        <S.Background background={this.getCloudUrl(item.src)} />
                        {!item.default ? <S.RemoveIcon onClick={e => this.handleRemoveItem(e, item)} /> : null}
                        {item.name && (
                          <S.Tooltip className="app-tooltip" id={`tooltip--detail-background-${index}`}>
                            <span>{item.name}</span>
                          </S.Tooltip>
                        )}
                      </S.Item>
                    );
                  } else if (isCustomBackground) {
                    return (
                      <S.Item
                        selectedDefault={selectedDefault}
                        empty
                        data-tip
                        data-for={`tooltip--detail-background-${index}`}
                        key={index}
                        active={false}
                        onClick={this.handleItemClick(item)}
                      >
                        <S.Background background={this.getCloudUrl(item.src)} />
                        {!item.default ? <S.RemoveIcon onClick={e => this.handleRemoveItem(e, item)} /> : null}
                        {item.name && (
                          <S.Tooltip className="app-tooltip" id={`tooltip--detail-background-${index}`}>
                            <span>{handleCutString(item.name)}</span>
                          </S.Tooltip>
                        )}
                      </S.Item>
                    );
                  } else return null;
                })}
              </S.CustomBackgroundsContainer>
              <S.SubHeader className="everfit-backgrounds-title">Everfit backgrounds</S.SubHeader>
              <S.BackgroundList>
                {_.map(workoutBackgrounds, (item, index) => {
                  const activeImage = selected._id === item._id || selected.src === item.src;
                  const selectedDefault = item._id === this.props.selectedWorkoutBackground._id;
                  const isCustomBackground = !_.get(item, 'default', false);
                  if (activeImage && !isCustomBackground) {
                    return (
                      <S.Item
                        selectedDefault={selectedDefault}
                        empty
                        data-tip
                        data-for={`tooltip--detail-background-${index}`}
                        ref={this.selectedBackground}
                        key={index}
                        active={true}
                        onClick={this.handleItemClick(item)}
                      >
                        <S.Background background={this.getCloudUrl(item.src)} />
                        {!item.default ? <S.RemoveIcon onClick={e => this.handleRemoveItem(e, item)} /> : null}
                        {item.name && (
                          <S.Tooltip className="app-tooltip" id={`tooltip--detail-background-${index}`}>
                            <span>{item.name}</span>
                          </S.Tooltip>
                        )}
                      </S.Item>
                    );
                  } else if (!isCustomBackground) {
                    return (
                      <S.Item
                        selectedDefault={selectedDefault}
                        empty
                        data-tip
                        data-for={`tooltip--detail-background-${index}`}
                        key={index}
                        active={false}
                        onClick={this.handleItemClick(item)}
                      >
                        <S.Background background={this.getCloudUrl(item.src)} />
                        {!item.default ? <S.RemoveIcon onClick={e => this.handleRemoveItem(e, item)} /> : null}
                        {item.name && (
                          <S.Tooltip className="app-tooltip" id={`tooltip--detail-background-${index}`}>
                            <span>{handleCutString(item.name)}</span>
                          </S.Tooltip>
                        )}
                      </S.Item>
                    );
                  } else return null; // run when isCustomBackground == true, this section should not render custom background
                })}
              </S.BackgroundList>
            </ModalLayout.Content>
            <ModalLayout.Actions>
              <Button
                purple
                onClick={this.handleSave}
                // if still in draft (isPublished == false) always allow change background, if published, only allow change backround when isEditMode == true
                disabled={uploading || (this.props.isPublished ? !this.props.isEditMode : false)}
              >
                Select
              </Button>
            </ModalLayout.Actions>
          </S.ContentWrapper>
        </Modal.Content>
      </Modal>
    );
  }
}

export default WorkoutBackground;
