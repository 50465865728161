import styled from 'styled-components';

import ConfirmModal from 'shared/ConfirmModal';

const baseText = `
    font-family: 'Open Sans';
    color: #202353;
    font-style: normal;
    line-height: 150%;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.delete-recipe-modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal.delete-recipe-modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.delete-recipe-modal {
    width: 467px !important;
    border-radius: 8px;
    background: #ffffff !important;
    box-shadow: unset !important;

    .close-button {
      right: -7px;
    }

    .confirm-content-header {
      padding: 30px 30px 0;

      .confirm-header-image {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .confirm-header-label {
        ${baseText}
        color: #000;
        font-size: 21px;
        font-weight: 600;
        line-height: 32px;
      }
    }

    .confirm-content-body {
      padding: 25px 30px;

      .confirm-content-label {
        ${baseText}
        color: #323C47;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .confirm-actions {
      background: #fff;
      border-top: none;
      padding: 2px 19px 30px;

      .confirm-no-button,
      .confirm-yes-button {
        ${baseText}
        font-size: 12px;
        font-weight: 600;
        border-radius: 5px;
        height: 30px;
      }

      .confirm-no-button {
        background: #ffffff;
        border: 1px solid #d4d7d9;
        min-width: 89px;
        padding: 5px 24px;
        margin-right: 3px !important;
        color: #777 !important;

        :hover {
          background: #f5f7f9 !important;
        }
      }

      .confirm-yes-button {
        color: #ffffff;
        background: #ea314a;
        border: unset;
        box-shadow: unset;
        padding: 5px 30px;
        width: 107px;

        :hover {
          background: rgb(234, 49, 74, 0.9);
        }
      }
    }
  }
`;
