import React from 'react';
import _ from 'lodash';
import diff from 'deep-diff';
import './styles.scss';
import { openWorkoutDetail } from 'redux/workout_library/workout.actionCreators';
import CreateWorkoutTemplate from 'components/CreateWorkoutTemplate';
import { Button } from 'shared/FormControl';
import * as Layout from 'shared/LibraryLayout';
import TableHeader from './Parts/TableHeader';
import TableBody from './Parts/TableBody';
import Toolbar from './Parts/Toolbar';
import Footer from './Parts/Footer';
import { WorkoutLibraryWrapper } from './style';
import { saveLastLibraryRoute } from 'utils/commonFunction';

export default class WorkoutLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countTagsSelected: [],
      workoutsSelected: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { listWorkout } = nextProps;
    const workoutsSelectedOrigin = _.cloneDeep(this.state.workoutsSelected);
    let { workoutsSelected } = this.state;

    _.forEach(listWorkout, item => {
      _.forEach(workoutsSelected, (ele, idx) => {
        if (item._id === ele._id) {
          workoutsSelected[idx].tags = item.tags;
        }
      });
    });

    // Update bulk action after add tag on workout builder
    if (diff(workoutsSelected, workoutsSelectedOrigin)) {
      this.handleCountTagBySelected(workoutsSelected, listWorkout);
    }
  }

  componentDidMount() {
    this.props.toggleSideBar(true);
    saveLastLibraryRoute('/home/workout');
    const { match, mode } = this.props;

    const workoutId = match.params.workoutId;
    if (workoutId) {
      let popupMode = 'update_workout';
      if (mode) {
        popupMode = mode;
      }
      this.props.dispatch(openWorkoutDetail(workoutId));
    }
  }

  onCreateNew = () => {
    this.props.toggleModal(true, <CreateWorkoutTemplate />);
  };

  handleCountTagBySelected = (workouts, listWorkout = _.get(this, 'props.listWorkout', [])) => {
    let allCurrentWorkout = listWorkout;
    let allCurrentTags = [];
    let allTagsWorkoutList = [];

    _.forEach(workouts, item => {
      allCurrentTags = allCurrentTags.concat(item.tags || []);
    });

    _.forEach(allCurrentWorkout, item => {
      allTagsWorkoutList = allTagsWorkoutList.concat(item.tags || []);
    });

    const countCurrentWorkout = _.countBy(allCurrentTags, '_id');
    const countAllWorkoutList = _.countBy(allTagsWorkoutList, '_id');
    let result = [];

    _.forEach(countCurrentWorkout, (total, tagsId) => {
      result[tagsId] = {
        total,
        state: countAllWorkoutList[tagsId] === allTagsWorkoutList[tagsId] || total === workouts.length ? 2 : 1,
      };
      result[tagsId].originState = result[tagsId].state;
    });

    //  item state:
    // 0: unchecked
    // 1: half-checked
    // 2: full-checked
    this.setState({
      countTagsSelected: result,
      workoutsSelected: workouts,
    });
  };

  // Handle clear exercise selected have tags
  handleClearSelectedByTags = () => {
    this.setState({
      countTagsSelected: [],
      workoutsSelected: [],
    });
  };

  render() {
    let { length } = this.props;
    const { countTagsSelected, workoutsSelected } = this.state;

    return (
      <WorkoutLibraryWrapper>
        <Toolbar
          countTagsSelected={countTagsSelected}
          tagsSelected={workoutsSelected}
          onClearSelectedByTags={this.handleClearSelectedByTags}
        />
        <Layout.TableContainer>
          <Layout.Table>
            <TableHeader onCountTagBySelected={this.handleCountTagBySelected} />
            <TableBody onCountTagBySelected={this.handleCountTagBySelected} />
          </Layout.Table>
          {!length ? (
            <Layout.NoResult>
              <Layout.NoResultText>No results found</Layout.NoResultText>
              <Button className="button--add-new-item" onClick={this.onCreateNew}>
                + Add New Workout
              </Button>
            </Layout.NoResult>
          ) : null}
        </Layout.TableContainer>
        <Footer />
      </WorkoutLibraryWrapper>
    );
  }
}
