import React, { useState } from 'react';
import WorkoutViewOnly from 'shared/WorkoutViewOnly';

export default ({ workout }) => {
  const [viewDetail, setViewDetail] = useState(false);

  const handleViewWorkout = () => {
    setViewDetail(true);
  };

  const handleClose = () => {
    setViewDetail(false);
  };

  return (
    <div className="title">
      <div>Workout Details</div>
      <div className="view-workout" onClick={handleViewWorkout} textOnly>
        View Workout
      </div>
      {viewDetail && <WorkoutViewOnly workout={workout} onClose={handleClose} />}
    </div>
  );
};
