import React from 'react';

import * as S from './style';

const Header = ({ total }) => {
  return (
    <S.HeaderWrapper>
      <S.HeaderItem>Documents ({total})</S.HeaderItem>
      <S.HeaderItem>Submitted Date</S.HeaderItem>
    </S.HeaderWrapper>
  );
};

export default Header;
