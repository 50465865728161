import React from 'react';

// Constants
import { ASSETS_SHARE_SETTING_TYPE } from '../constants';

// Parts
import FieldOwner from './FieldOwner';
import FieldShare from './FieldShare';

// Styles
import * as S from '../styles';

const ColumnOwnership = props => {
  const { type = ASSETS_SHARE_SETTING_TYPE.DEFAULT } = props;

  return (
    <S.ColumnWrapper type={type}>
      <FieldOwner {...props} />
      <FieldShare {...props} />
    </S.ColumnWrapper>
  );
};

export default ColumnOwnership;
