import IconSearch from 'assets/icons/search_icon_grey.svg';
import { Modal } from 'semantic-ui-react';
import LoadingIndicator from 'shared/LoadingIndicator';
import { NewSearchInput } from 'shared/SearchInput';
import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7b7e91;
`;

export const AddIngredientWrapper = styled(Modal)`
  &.ui.modal {
    width: 562px;
    height: 660px;
    border-radius: 10px !important;
  }
  .close-button {
    right: -9px;
    top: -9px;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

export const Header = styled.div`
  padding: 25px 25px 20px;
  box-shadow: 0px 3px 12px 0px #0000000d;
`;

export const Title = styled.h3`
  ${baseText}
  font-size: 19px;
  line-height: 29px;
  margin-bottom: 20px;
  color: #202353;
`;

export const Content = styled.div`
  height: calc(100% - 235px);
  display: flex;
  position: relative;
`;

export const Action = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-items: end;
  padding: 0 20px;
  border-top: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSave = styled.button`
  ${baseText}
  width: 123px;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: rgba(234, 238, 241, 1);
  cursor: not-allowed;
  margin-left: auto;
  &:hover {
    background: rgba(234, 238, 241, 0.9);
  }
  ${props =>
    props.active &&
    css`
      cursor: pointer;
      background: rgba(81, 88, 207, 1);
      color: #fff;
      &:hover {
        background: rgba(81, 88, 207, 0.9);
      }
    `}
`;

export const SearchWrapper = styled.div`
  height: 36px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #dadbea;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  background-color: #f8f8f8;

  :focus-within {
    border-color: #5158cf;
    background-color: #fff;
  }

  :hover {
    border-color: #5158cf;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputSearch = styled(NewSearchInput)`
  height: 34px;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  input {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #202353;
    border: 1px solid transparent;
    padding: 7px 25px 7px 43px;
    background: url(${IconSearch}) no-repeat;
    background-size: 17px 16px;
    background-position: 14px center;
    background-color: #f8f8f8;
    ::placeholder {
      ${baseText}
      font-weight: 400;
      font-size: 13px;
      line-height: 19.5px;
      color: #a3a3b5;
      opacity: 0.8;
    }

    :focus {
      border-color: transparent;
      background-color: #fff;
    }
    :hover {
      border-color: transparent;
    }
  }

  svg {
    right: 7px;
    path {
      fill: #a3a3b5;
    }
  }
`;

export const ListIngredient = styled.div`
  height: 100%;
  flex: 1;
`;

export const ListIngredientSelected = styled.div`
  height: 100%;
  width: 212px;
  padding: 15px 10px 15px 20px;
  background: #fbfbfb;
  box-shadow: -1px 4px 6px 0px #00000005 inset;
`;

export const LoadingIndicatorWrapper = styled(LoadingIndicator)`
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: fit-content;
  padding-top: 0;
  span {
    ${baseText}
    font-weight: 400;
    color: #7b7e91;
  }
  .ui.loader:after {
    border-color: #5158cf #e1e1ea #e1e1ea !important;
    border-width: 3px;
    width: 19px !important;
    height: 19px !important;
  }
  .ui.loader:before {
    width: 19px !important;
    height: 19px !important;
    border-width: 3px;
  }
  .ui.small.text.loader {
    padding-top: 25px;
  }
`;

export const NewIngredientBtn = styled.button`
  ${baseText}
  color: #202353;
  width: 141px;
  height: 30px;
  border: 1px solid #e1e1ea;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  svg {
    margin-right: 4px;
  }
  &:hover {
    background: #f0f1ff;
    color: #5158cf;
    svg path {
      fill: #5158cf;
    }
  }
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  align-items: end;
  height: 39px;
  position: relative;
  padding: 0 20px;
  &::before {
    content: '';
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: #f0f0f2;
  }
`;

export const TabItem = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      color: #5158cf;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: #5158cf;
      }
    `}
`;
