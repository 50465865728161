import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  position: relative;
  .filter__dropdown {
    background-color: #f5f5f5;
    cursor: pointer;
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    color: #262626;
    &.active,
    :hover {
      background-color: #f4f4fc !important;
    }
    .filter__dropdown__title {
      .filter-name {
        width: 34px;
        margin-left: 1px;
      }

      .colons {
        margin-left: 1px;
        margin-right: 4px;
        width: 4px;
      }
      .selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }

      .text {
        max-width: 190px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
`;

export const SearchBar = styled.div`
  .search-input {
    height: 34px;
    width: 306px;
    input {
      background: url(${CDN_URL}/images/search_white.svg) no-repeat scroll 11px center !important;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

export const PaymentOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 6px 0px 6px 10px;

  :hover {
    cursor: pointer;
    background-color: #f4f4fc;
    .payment-checkbox__icon .checkbox-icon {
      border-color: #5158cf;
    }
  }

  .payment-checkbox__icon .checkbox-icon {
    border-radius: 2px;
  }
`;

export const PaymentOptionTitle = styled.div`
  color: var(--neutral-800, #262626);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-right: -1px;

  .payment-checkbox__icon {
    font-size: 13px;
    margin-left: 5px;
    .checkbox-icon {
      border-radius: 2px;
      background-position: 2px 2px;
      background-size: 69%;
    }
  }
`;

export const MenuContainer = styled.div`
  width: 255px;
  max-height: 332px;
  border-radius: 4px;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border: solid 1px #e0e4e5;
  background-color: #fff;
  padding: 11px 12px;
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 999;
`;
