export const MESSAGE_TYPES = { TEXT: 'text', FILE: 'file' };

export const AUTOFILL_ONBOARDINGFLOW_ITEMS = [
  { value: '{FIRST_NAME}', title: '{First_Name}', description: 'Client’s first name' },
  { value: '{LAST_NAME}', title: '{Last_Name}', description: 'Client’s last name' },
  { value: '{FULL_NAME}', title: '{Full_Name}', description: 'Client’s full name' },
  { value: '{WORKSPACE_NAME}', title: '{Workspace_Name}', description: 'Client’s workspace name' },
];

export const FILE_VALIDATIONS = {
  ACCEPTED: 'image/png, image/jpg, image/jpeg, .mp4, .mov',
  EXTENTIONS: ['png', 'jpg', 'jpeg', 'mp4', 'mov'],
  MAX_SIZE: 50,
  IMAGE_TYPES: ['png', 'jpg', 'jpeg'],
  VIDEO_TYPES: ['mp4', 'mov', 'avi', 'flv', '3gp'],
  MAX_IMAGE_SIZE: 25,
  MAX_VIDEO_SIZE: 150,
};

function timeOptions() {
  let h = 0;
  const list = [];

  while (h < 24) {
    let m = 0;
    const a = h < 12 ? 'AM' : 'PM';
    const hh = `0${h % 12 || 12}`.slice(-2);

    while (m < 46) {
      const mm = `0${m}`.slice(-2);
      list.push(`${hh}:${mm} ${a}`);
      m += 15;
    }

    h += 1;
  }

  return list;
}

export const TimeOptions = [
  { label: '12:00 AM', value: '00:00', minutes: 0 },
  { label: '12:15 AM', value: '00:15', minutes: 15 },
  { label: '12:30 AM', value: '00:30', minutes: 30 },
  { label: '12:45 AM', value: '00:45', minutes: 45 },
  { label: '01:00 AM', value: '01:00', minutes: 60 },
  { label: '01:15 AM', value: '01:15', minutes: 75 },
  { label: '01:30 AM', value: '01:30', minutes: 90 },
  { label: '01:45 AM', value: '01:45', minutes: 105 },
  { label: '02:00 AM', value: '02:00', minutes: 120 },
  { label: '02:15 AM', value: '02:15', minutes: 135 },
  { label: '02:30 AM', value: '02:30', minutes: 150 },
  { label: '02:45 AM', value: '02:45', minutes: 165 },
  { label: '03:00 AM', value: '03:00', minutes: 180 },
  { label: '03:15 AM', value: '03:15', minutes: 195 },
  { label: '03:30 AM', value: '03:30', minutes: 210 },
  { label: '03:45 AM', value: '03:45', minutes: 225 },
  { label: '04:00 AM', value: '04:00', minutes: 240 },
  { label: '04:15 AM', value: '04:15', minutes: 255 },
  { label: '04:30 AM', value: '04:30', minutes: 270 },
  { label: '04:45 AM', value: '04:45', minutes: 285 },
  { label: '05:00 AM', value: '05:00', minutes: 300 },
  { label: '05:15 AM', value: '05:15', minutes: 315 },
  { label: '05:30 AM', value: '05:30', minutes: 330 },
  { label: '05:45 AM', value: '05:45', minutes: 345 },
  { label: '06:00 AM', value: '06:00', minutes: 360 },
  { label: '06:15 AM', value: '06:15', minutes: 375 },
  { label: '06:30 AM', value: '06:30', minutes: 390 },
  { label: '06:45 AM', value: '06:45', minutes: 405 },
  { label: '07:00 AM', value: '07:00', minutes: 420 },
  { label: '07:15 AM', value: '07:15', minutes: 435 },
  { label: '07:30 AM', value: '07:30', minutes: 450 },
  { label: '07:45 AM', value: '07:45', minutes: 465 },
  { label: '08:00 AM', value: '08:00', minutes: 480 },
  { label: '08:15 AM', value: '08:15', minutes: 495 },
  { label: '08:30 AM', value: '08:30', minutes: 510 },
  { label: '08:45 AM', value: '08:45', minutes: 525 },
  { label: '09:00 AM', value: '09:00', minutes: 540 },
  { label: '09:15 AM', value: '09:15', minutes: 555 },
  { label: '09:30 AM', value: '09:30', minutes: 570 },
  { label: '09:45 AM', value: '09:45', minutes: 585 },
  { label: '10:00 AM', value: '10:00', minutes: 600 },
  { label: '10:15 AM', value: '10:15', minutes: 615 },
  { label: '10:30 AM', value: '10:30', minutes: 630 },
  { label: '10:45 AM', value: '10:45', minutes: 645 },
  { label: '11:00 AM', value: '11:00', minutes: 660 },
  { label: '11:15 AM', value: '11:15', minutes: 675 },
  { label: '11:30 AM', value: '11:30', minutes: 690 },
  { label: '11:45 AM', value: '11:45', minutes: 705 },
  { label: '12:00 PM', value: '12:00', minutes: 720 },
  { label: '12:15 PM', value: '12:15', minutes: 735 },
  { label: '12:30 PM', value: '12:30', minutes: 750 },
  { label: '12:45 PM', value: '12:45', minutes: 765 },
  { label: '01:00 PM', value: '13:00', minutes: 780 },
  { label: '01:15 PM', value: '13:15', minutes: 795 },
  { label: '01:30 PM', value: '13:30', minutes: 810 },
  { label: '01:45 PM', value: '13:45', minutes: 825 },
  { label: '02:00 PM', value: '14:00', minutes: 840 },
  { label: '02:15 PM', value: '14:15', minutes: 855 },
  { label: '02:30 PM', value: '14:30', minutes: 870 },
  { label: '02:45 PM', value: '14:45', minutes: 885 },
  { label: '03:00 PM', value: '15:00', minutes: 900 },
  { label: '03:15 PM', value: '15:15', minutes: 915 },
  { label: '03:30 PM', value: '15:30', minutes: 930 },
  { label: '03:45 PM', value: '15:45', minutes: 945 },
  { label: '04:00 PM', value: '16:00', minutes: 960 },
  { label: '04:15 PM', value: '16:15', minutes: 975 },
  { label: '04:30 PM', value: '16:30', minutes: 990 },
  { label: '04:45 PM', value: '16:45', minutes: 1005 },
  { label: '05:00 PM', value: '17:00', minutes: 1020 },
  { label: '05:15 PM', value: '17:15', minutes: 1035 },
  { label: '05:30 PM', value: '17:30', minutes: 1050 },
  { label: '05:45 PM', value: '17:45', minutes: 1065 },
  { label: '06:00 PM', value: '18:00', minutes: 1080 },
  { label: '06:15 PM', value: '18:15', minutes: 1095 },
  { label: '06:30 PM', value: '18:30', minutes: 1110 },
  { label: '06:45 PM', value: '18:45', minutes: 1125 },
  { label: '07:00 PM', value: '19:00', minutes: 1140 },
  { label: '07:15 PM', value: '19:15', minutes: 1155 },
  { label: '07:30 PM', value: '19:30', minutes: 1170 },
  { label: '07:45 PM', value: '19:45', minutes: 1185 },
  { label: '08:00 PM', value: '20:00', minutes: 1200 },
  { label: '08:15 PM', value: '20:15', minutes: 1215 },
  { label: '08:30 PM', value: '20:30', minutes: 1230 },
  { label: '08:45 PM', value: '20:45', minutes: 1245 },
  { label: '09:00 PM', value: '21:00', minutes: 1260 },
  { label: '09:15 PM', value: '21:15', minutes: 1275 },
  { label: '09:30 PM', value: '21:30', minutes: 1290 },
  { label: '09:45 PM', value: '21:45', minutes: 1305 },
  { label: '10:00 PM', value: '22:00', minutes: 1320 },
  { label: '10:15 PM', value: '22:15', minutes: 1335 },
  { label: '10:30 PM', value: '22:30', minutes: 1350 },
  { label: '10:45 PM', value: '22:45', minutes: 1365 },
  { label: '11:00 PM', value: '23:00', minutes: 1380 },
  { label: '11:15 PM', value: '23:15', minutes: 1395 },
  { label: '11:30 PM', value: '23:30', minutes: 1410 },
  { label: '11:45 PM', value: '23:45', minutes: 1425 },
];

export const TypeOptions = [
  {
    label: 'During Period',
    value: 'during_period',
  },
  {
    label: 'Immediately',
    value: 'immediately',
  },
];

export const TimeDelayOptions = [
  { label: '5 minutes', value: 5 },
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 },
  { label: '20 minutes', value: 20 },
  { label: '25 minutes', value: 25 },
  { label: '30 minutes', value: 30 },
  { label: '35 minutes', value: 35 },
  { label: '40 minutes', value: 40 },
  { label: '45 minutes', value: 45 },
  { label: '50 minutes', value: 50 },
  { label: '55 minutes', value: 55 },
];
