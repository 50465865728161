import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';

export const MoreOption = styled.div`
  ${props =>
    props.disabled &&
    css`
      display: none;
    `};
`;

export const Label = styled.div`
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-left: 8px;
`;

export const MessageActionPopup = styled(Popup)`
  &.ui.popup {
    background-color: transparent;
    border: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
  }

  &.ui.top.popup {
    margin: 0 0 10.5px !important;
  }

  &.ui.bottom.popup {
    margin: 10.5px 0 0 !important;
  }

  &::before {
    content: '';
    width: 10px !important;
    height: 10px !important;
    background-color: #2d2e2d !important;
    box-shadow: unset !important;
    bottom: -10px !important;
    transform: rotate(45deg) translateX(-70%) !important;
    margin-left: unset !important;
  }
`;

export const MessageActionContent = styled.div`
  width: 123px;
  border-radius: 8px;
  background-color: #2d2e2d;
  box-shadow: unset;
  padding: 8px 0px;
`;

export const MessageActionItem = styled.div`
  padding: 8px 0px 8px 16px;
  background-color: #2d2e2d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    background-color: #696969;
  }
`;
