import React from 'react';
import { connect } from 'react-redux';

import { SORT_ENUM, PACKAGE_ANALYTICS_INVOICE_SORT_BY } from 'constants/commonData';
import { changeQueryParams } from 'redux/package-analytics/actions';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
import * as S from '../style';

const TableHeader = props => {
  const { sort = 'payment_due', order, changeQueryParams, isMP } = props;

  const renderSortIcon = key => {
    if (key === sort) {
      return order === SORT_ENUM.ASCENDING ? <ArrowDown /> : <ArrowUp />;
    }

    return <ArrowDown />;
  };

  const handleSort = event => {
    event.persist();
    const { sortValue } = event.currentTarget.dataset;
    changeQueryParams(
      {
        sort: sortValue,
        order:
          sort !== sortValue
            ? SORT_ENUM.DESCENDING
            : order === SORT_ENUM.DESCENDING
            ? SORT_ENUM.ASCENDING
            : SORT_ENUM.DESCENDING,
      },
      undefined,
      isMP,
    );
  };

  return (
    <S.TableHeader>
      <S.TableRow>
        <S.TableCellHeader className="name" isSorting={sort === PACKAGE_ANALYTICS_INVOICE_SORT_BY.CUSTOMER} sortable>
          <S.TableCellContent data-sort-value={PACKAGE_ANALYTICS_INVOICE_SORT_BY.CUSTOMER} onClick={handleSort}>
            <span>Customer</span>
            {renderSortIcon('customer')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader isSorting={sort === PACKAGE_ANALYTICS_INVOICE_SORT_BY.AMOUNT} sortable>
          <S.TableCellContent data-sort-value={PACKAGE_ANALYTICS_INVOICE_SORT_BY.AMOUNT} onClick={handleSort}>
            <span>Amount</span>
            {renderSortIcon('amount')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader>
          <S.TableCellContent>
            <span>Order ID</span>
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader>
          <S.TableCellContent>
            <span>Invoice #</span>
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader>
          <S.TableCellContent>
            <span>Status</span>
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader isSorting={sort === PACKAGE_ANALYTICS_INVOICE_SORT_BY.PAYMENT_DUE} sortable>
          <S.TableCellContent data-sort-value={PACKAGE_ANALYTICS_INVOICE_SORT_BY.PAYMENT_DUE} onClick={handleSort}>
            <span>Payment Due</span>
            {renderSortIcon('payment_due')}
          </S.TableCellContent>
        </S.TableCellHeader>
        <S.TableCellHeader className="actions"></S.TableCellHeader>
      </S.TableRow>
    </S.TableHeader>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageAnalytics },
  } = state;

  const { counter, query, viewType } = packageAnalytics.toJS();
  return {
    sort: query.sort,
    order: query.order,
    viewType: viewType,
    status: query.status,
    counter,
  };
};

export default connect(mapState, { changeQueryParams })(TableHeader);
