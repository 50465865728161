import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const CreateNewStudioCollectionModal = styled(Modal)`
  &.ui.modal {
    width: 440px;
  }

  .header {
    border-radius: 8px;
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;

    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 8px !important;
  }

  .header-title {
    color: #202353 !important;
    font-family: 'Open Sans' !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 25px !important;
  }

  .content {
    padding: 30px !important;
  }

  .addStudioCollection {
    &__form {
      margin-bottom: 30px;
    }

    &__coverImageInput {
      max-width: 215px;
      margin-bottom: 30px;
    }

    &__footer {
      display: flex;
      flex-direction: row-reverse;

      button {
        width: 136px;
        height: 32px;
        border-radius: 5px;
      }
    }
  }

  .close-button {
    right: -8px;
    top: -10px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;

  .label__required {
    color: #ea314a;
  }
`;
