import React from 'react';
import { PLANS } from 'constants/commonData';
import { ReactComponent as AutoflowIcon } from 'assets/icons/autoflow_asset.svg';
import { ReactComponent as ForumIcon } from 'assets/icons/forum_asset.svg';
import { ReactComponent as ProgramIcon } from 'assets/icons/program_asset.svg';
import { ReactComponent as ResourceCollectionIcon } from 'assets/icons/resource_collection_asset.svg';
import { ReactComponent as StudioProgramIcon } from 'assets/icons/studio_program_asset.svg';
import { ReactComponent as WorkoutCollectionIcon } from 'assets/icons/workout-collection-asset.svg';
import { ReactComponent as WorkoutCollectionSecondaryIcon } from 'assets/icons/workout-collection-secondary-asset-icon.svg';

export const PRODUCT_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
  ARCHIVE: 'archive',
  NEW: 'new',
  DUPLICATE: 'duplicate',
  ISPURCHASED: 'ispurchased',
};

export const PRODUCT_TAB_ITEMS = [
  { key: 'all', value: '', label: 'All Sequences', showTotal: false },
  { key: 'publish', value: PRODUCT_STATUS.PUBLISH, label: 'Published', showTotal: true },
  { key: 'draft', value: PRODUCT_STATUS.DRAFT, label: 'Draft', showTotal: true },
  { key: 'archive', value: PRODUCT_STATUS.ARCHIVE, label: 'Archived', showTotal: false },
];

export const ACTIVATE_TYPES = [
  { key: 'immediately', value: 1, label: 'Purchase Date (immediately)', tooltip: '', overviewLabel: 'purchase date' },
  { key: 'monday', value: 2, label: 'Following Monday', tooltip: '', overviewLabel: 'following Monday' },
];

export const LIMIT_ACCESS = [
  { type: 'forever', key: 'forever', value: 'forever', label: 'Forever (will not remove)' },
  { type: 'w', key: '1w', value: '1w', label: '1 week' },
  { type: 'w', key: '4w', value: '4w', label: '4 weeks' },
  { type: 'w', key: '8w', value: '8w', label: '8 weeks' },
  { type: 'w', key: '12w', value: '12w', label: '12 weeks' },
  { type: 'm', key: '1m', value: '1m', label: '1 month' },
  { type: 'm', key: '2m', value: '2m', label: '2 months' },
  { type: 'm', key: '3m', value: '3m', label: '3 months' },
  { type: 'm', key: '6m', value: '6m', label: '6 months' },
  { type: 'm', key: '12m', value: '12m', label: '12 months' },
];

export const PRODUCT_ACCESS_DURATION_UNIT = [
  { key: 'forever', value: 1 },
  { key: 'w', value: 2 },
  { key: 'm', value: 3 },
  { key: 'until_end_of_package', value: 4 },
];

export const AUTOFLOW_STATUS = {
  AUTOFLOW_STATUS_WAITING_TO_START: 0,
  AUTOFLOW_STATUS_PAUSED: 1,
  AUTOFLOW_STATUS_ACTIVATED: 2,
};

export const ASSET_TYPE = {
  AUTOFLOW: 'autoflow',
  RESOURCE: 'resource_collection',
  STUDIO: 'studio_program',
  FORUM: 'forum',
  PROGRAM: 'program',
  WORKOUT_COLLECTION: 'on_demand_workout_collection',
  STUDIO_PROGRAM: 'on_demand_studio_program',
  RESOURCE_COLLECTION: 'on_demand_resource_collection',
};

export const ASSET_TYPES = [
  {
    key: 'autoflow',
    value: 'autoflow',
    label: 'Autoflow',
    pathName: 'autoflow',
    desc: 'Automated schedule of workouts, tasks & messages',
    icon: <AutoflowIcon />,
    color: '#1A5FF6',
    tooltip: 'Automated schedule of workouts, tasks & messages',
    upgradePathTooltip: 'Upgrade your subscription to use Autoflows',
    isEnable: false,
    planWillSelect: {
      addOns: {
        automation: true,
      },
    },
  },
  {
    key: 'resource_collection',
    value: 'resource_collection',
    label: 'Resource Collection',
    pathName: 'studio_resource_collection',
    icon: <ResourceCollectionIcon />,
    color: '#58C573',
    desc: 'On-demand portal for documents, files & links',
    tooltip: 'On-demand portal for documents, files & links',
    upgradePathTooltip: 'Upgrade your subscription to use Resource Collections',
    isEnable: false,
    planWillSelect: {
      tier: PLANS.pro.key,
      addOns: {
        resource_collections: true,
      },
    },
  },
  {
    key: 'studio_program',
    value: 'studio_program',
    label: 'Studio Program',
    pathName: 'studio_program',
    icon: <StudioProgramIcon />,
    color: '#EC3452',
    desc: 'On-demand client-managed training program',
    tooltip: 'On-demand client-managed training program',
    upgradePathTooltip: 'Upgrade your subscription to use Studio Programs',
    isEnable: false,
    planWillSelect: {
      tier: PLANS.studio.key,
    },
  },
  {
    key: 'forum',
    value: 'forum',
    label: 'Forum',
    pathName: 'forum',
    desc: 'Private group for content sharing and resources',
    icon: <ForumIcon />,
    color: '#F6BA62',
    tooltip: 'Private group for content sharing and resources',
    upgradePathTooltip: 'Upgrade your subscription to use Forums',
    isEnable: false,
    planWillSelect: {
      tier: PLANS.pro.key,
    },
  },
  {
    key: 'program',
    value: 'program',
    label: 'Program',
    pathName: 'program',
    icon: <ProgramIcon />,
    color: '#EE592A',
    desc: 'Multi-week workout program template',
    tooltip: 'Multi-week workout program template',
    upgradePathTooltip: 'Upgrade your subscription to use Programs',
    isEnable: false,
    planWillSelect: {
      tier: PLANS.pro.key,
    },
  },
  {
    key: 'on_demand_workout_collection',
    value: 'on_demand_workout_collection',
    label: 'Workout Collection',
    pathName: 'on_demand_workout',
    icon: <WorkoutCollectionIcon />,
    secondaryIcon: <WorkoutCollectionSecondaryIcon />,
    color: '#5E59FF',
    desc: 'On-demand library of workouts and videos',
    tooltip: 'On-demand library of workouts and videos',
    upgradePathTooltip: 'Upgrade your subscription to use Workout Collections',
    isEnable: false,
    planWillSelect: {
      tier: PLANS.pro.key,
      addOns: {
        resource_collections: true,
      },
    },
  },
];

export const LIMIT_PER_PAGE = 20;
