import React, { useMemo } from 'react';

import { pluralize } from 'utils/commonFunction';
import { ReactComponent as GroupTagIcon } from 'assets/icons/filter_group_tag.svg';
import * as S from './style';

const ItemGroup = props => {
  const totalClient = useMemo(() => {
    const client = props.total_clients || props.clients || 0;
    return pluralize(`${client} client`, client);
  }, [props]);

  return (
    <S.ItemGroupWrapper onClick={props.onClick}>
      <div className="group-icon">
        <GroupTagIcon />
      </div>
      <div className="group-content">
        <span className="group-name">
          <strong>Group:&nbsp;</strong>
          {props.name}
        </span>
        <div className="total-clients">{totalClient}</div>
      </div>
    </S.ItemGroupWrapper>
  );
};

export default ItemGroup;
