import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { getDuration, makeFriendlyYoutubeURL } from 'utils/commonFunction';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { ReactComponent as PlayIcon } from 'assets/icons/play_audio.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/pause_audio.svg';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading_audio.svg';
import * as S from './style';

const AudioPlayer = ({
  url,
  duration,
  uploading,
  audioKey,
  activeKey,
  showRecord,
  onPlay,
  onProgress,
  getS3presignedURLFromLocalDatabase,
}) => {
  const [src, setSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [played, setPlayed] = useState(0);
  let timeout = null;
  const audioRef = useRef();

  useEffect(() => {
    if (activeKey && activeKey.includes(audioKey)) {
      onProgress && onProgress(played * 100);
    }
  }, [activeKey, audioKey, duration, played]);

  const getPresignedURLs = async () => {
    try {
      setLoading(true);
      await getS3presignedURLFromLocalDatabase([url]).then(res => {
        setSrc(res[0]);
      });
    } catch {
      // good luck
    }
  };

  const handlePlay = async () => {
    if (showRecord) {
      return;
    }
    if (!src) {
      await getPresignedURLs();
    }
    timeout && clearTimeout(timeout);
    if (audioRef.current) {
      if (activeKey && !activeKey.includes(audioKey)) {
        document.querySelectorAll('audio').forEach(el => {
          el.pause();
          el.currentTime = 0;
        });
        onProgress && onProgress(0);
      }

      timeout = setTimeout(() => {
        onPlay && onPlay(`${audioKey}-play`);
        audioRef.current.player.player.play();
      }, 400);
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      onPlay && onPlay(`${audioKey}-pause`);
      audioRef.current.player.player.pause();
      timeout && clearTimeout(timeout);
    }
  };

  const currentDuration = useMemo(() => {
    if (activeKey && activeKey.includes(audioKey)) {
      return getDuration(playedSeconds > duration ? duration : playedSeconds);
    }
    return getDuration(duration);
  }, [activeKey, audioKey, duration, playedSeconds]);

  return (
    <div>
      <ReactPlayer
        url={makeFriendlyYoutubeURL(src)}
        ref={audioRef}
        width="160px"
        height="0px"
        onReady={() => setLoading(false)}
        onProgress={({ playedSeconds, played }) => {
          Math.floor(playedSeconds) <= duration && setPlayedSeconds(Math.floor(playedSeconds));
          setPlayed(played);
        }}
        onEnded={() => {
          timeout && clearTimeout(timeout);
          onPlay && onPlay('');
          onProgress && onProgress(0);
          setPlayedSeconds(0);
        }}
      />
      <S.AudioContainer>
        {uploading || loading ? (
          <S.AudioControl>
            <LoadingIcon className="loader" />
          </S.AudioControl>
        ) : activeKey === `${audioKey}-play` ? (
          <S.AudioControl onClick={handlePause}>
            <PauseIcon />
          </S.AudioControl>
        ) : (
          <S.AudioControl showRecord={showRecord} onClick={handlePlay}>
            <PlayIcon />
          </S.AudioControl>
        )}
        <S.AudioDuration>{currentDuration}</S.AudioDuration>
      </S.AudioContainer>
    </div>
  );
};

export default connect(null, { getS3presignedURLFromLocalDatabase })(AudioPlayer);
