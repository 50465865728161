import styled, { css } from 'styled-components';
import FormItemInput from 'shared/FormItemInput';
import FirstCreateNewIconSrc from 'assets/icons/first_create_new_workout_collections.svg';
import WorkoutCollectionBannerSrc from 'assets/images/workout_collections_banner.svg';
import Card from 'shared/Card';
import InfoTooltipIconElement from 'shared/InfoTooltipIcon';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  padding: 30px 0 10px;
  width: 100%;
  /* max-width: 1055px; */
  margin: 0 auto;
`;

export const CollectionsList = styled.div`
  height: calc(100% - 40px);
  padding: 0 40px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  .loading {
    padding-top: 20px;
  }
  ${props =>
    !props.isHideBanner &&
    css`
      height: calc(100% - 237px);
    `}
`;

export const CollectionEmptyList = styled.div`
  display: flex;
  padding: 0 40px;
  height: calc(100% - 200px);
  justify-content: center;
  align-items: center;

  ${props =>
    !props.isHideBanner &&
    css`
      height: calc(100% - 450px);
    `}
`;

export const EmptyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyAreaBanner = styled.div`
  width: 127px;
  height: 139px;
  background-image: url(${FirstCreateNewIconSrc});
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
`;

export const EmptyAreaText = styled.div`
  display: flex;
  max-width: 238px;
  color: rgba(154, 157, 166, 1);
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 17.7px;
  align-items: flex-end;
`;

export const CardListWrapper = styled.div`
  display: grid;
  padding-right: 3px;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  column-gap: 22px;
  row-gap: 15px;
`;

export const CollectionCard = styled(Card)`
  width: 100%;
  .title {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .description {
    padding-left: 15px !important;
    padding-right: 15px !important;
    min-height: 40px;
  }
  .info-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    .info-content span:first-child {
      margin-right: 9px;
    }
    .info-content span {
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 12px;
      line-height: 150%;
      color: #202353;
    }

    &.share-owner-avatar {
      display: grid;
      grid-template-columns: 1fr 20px;
      gap: 20px;
      justify-content: start;
      align-items: end;
    }
  }
  .status {
    top: 148px;
  }
  .banner-container {
    height: 180px;
  }
  .dropdown-wrapper {
    svg {
      margin-right: 10px;
      &.stroke-unset {
        path {
          stroke: unset !important;
        }
      }
    }

    .dropdown-arrow {
      top: 2.8px;
    }
  }

  .dropdown-area {
    padding: 4px 0;
  }

  .evf-dropdown__menu {
    width: 144px !important;
  }

  .evf-dropdown__option {
    padding: 8px 8px !important;

    span {
      color: #f7f8f9;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
`;

export const CollectionsHeader = styled.div`
  position: relative;
  display: flex;
  padding: 0 40px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;

  .search-input {
    width: 265px;
    height: 36px;

    input {
      background-color: #f8f8f8;
      border: 1px solid #f0f0f0;

      ::placeholder {
        color: #777777;
      }

      &:hover,
      &:active,
      &:focus {
        border: 1px solid #7072dd;
      }
    }
  }

  .search-filter-container {
    display: flex;
    flex-direction: row;
  }

  .buttonClassName {
    button {
      margin-right: 0;

      &:hover,
      &:focus,
      &:active {
        background: #5559ff !important;
      }
    }
  }
`;

export const Input = styled(FormItemInput)``;

export const Label = styled.div`
  display: flex;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  align-items: center;
  justify-content: space-between;
`;

export const LabelText = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(calc(54% + 8px), -38%);
  }
`;

export const InfoIconArea = styled.span`
  ${'' /* position: absolute; */}
  svg {
    width: 10px;
    height: 10px;
    transform: translateX(3px) translateY(1px);
    margin-left: 1px;
    cursor: pointer;
  }

  #activate-thumb-collection {
    padding: 15px;
    text-transform: none;
  }
`;

export const NotFound = styled.div`
  margin: 40px;
  text-align: center;
  height: calc(100% - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    width: 82.4px;
    height: 81px;
  }

  .text {
    margin-top: 22px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #9a9da6;
  }
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  .filter-options {
    .ui.button {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      border: none !important;
      :hover {
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.04),
          0px 0px 4px 0px rgba(0, 0, 0, 0.06);
        background-color: rgb(245, 247, 249) !important;
      }

      :focus {
        background-color: #fff !important;
      }
      margin-right: unset;
    }
  }
  .filter-options-header {
    .ui.button {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      border: 1px solid #d4d7d9 !important;
      margin-right: unset;

      :hover {
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.04),
          0px 0px 4px 0px rgba(0, 0, 0, 0.06);
        background-color: rgb(245, 247, 249) !important;
      }

      :focus {
        background-color: #fff !important;
      }
    }
  }
`;

export const CounterFilters = styled.div`
  right: -11px;
  top: -11px;
  width: 22px;
  height: 22px;
  border: 3px solid #fff;
  position: absolute;
  background: #ffbe49;
  border-radius: 6px;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
`;

export const ResetFilter = styled.div`
  display: inline-flex;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  background: transparent url(${CDN_URL}/images/reset_new.svg) no-repeat 0 center;
  background-size: auto;
  align-items: center;

  :hover {
    cursor: pointer;
    background-image: url(${CDN_URL}/images/reset_new_purple.svg);
    color: #5158cf;
  }

  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;
