// libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import isObject from 'lodash/isObject';

// Components
import NormalView from './components/NormalView';
import RearrangeView from './components/RearrangeView';
import MacroInfo from '../MealCategory/components/MacroInfo';
import DragItemRecipe from './components/RearrangeView/DragItemRecipe';

// utils
import { getDayTitle, mongoObjectId } from 'utils/commonFunction';
import { getDaysOfWeek, getTotalMacroNutrients } from 'components/ClientMealPlan/helper';

// styles
import * as S from './style';

const orderMealNames = [
  { _id: mongoObjectId(), name: 'Breakfast', recipes: [], is_recipes_empty: true },
  { _id: mongoObjectId(), name: 'Lunch', recipes: [], is_recipes_empty: true },
  { _id: mongoObjectId(), name: 'Dinner', recipes: [], is_recipes_empty: true },
  { _id: mongoObjectId(), name: 'Snack', recipes: [], is_recipes_empty: true },
];

const WeeklyCategory = ({
  selectedDay,
  viewMode,
  isRearrangeMode,
  isLoadingWeek,
  weekMealClients,
  clientTimezone,
  onAddMeals,
  cloudfrontList,
  reArrangeMealClientRecipe,
  workingClientDetail,
}) => {
  const { _id: clientId } = workingClientDetail || {};

  const daysOfWeek = useMemo(() => getDaysOfWeek(selectedDay), [selectedDay]);

  const generateNewWeekMealClients = useCallback(() => {
    return daysOfWeek.map(dayItem => {
      const existingMeal = uniqBy(weekMealClients || [], 'day').find(({ day }) => day === dayItem);

      const updateRecipes = meal => {
        const { _id, recipes } = meal || {};
        const hasRecipes = (recipes || []).length > 0;

        return {
          ...meal,
          is_recipes_empty: !hasRecipes,
          recipes: (recipes || []).map(recipeItem => {
            const { recipe } = recipeItem || {};
            return {
              ...recipeItem,
              recipe: {
                ...recipe,
                _id: `${_id}-${(recipe || {})._id}`,
              },
            };
          }),
        };
      };

      if (existingMeal) {
        const { meal_group, is_manual } = existingMeal || {};
        const hasAtLeastOneCustom = (meal_group || []).some(({ is_system }) => !is_system);
        const shouldUseOrderMealNames = (is_manual && !hasAtLeastOneCustom) || (meal_group || []).length <= 0;

        let updatedMealGroup = [];

        if (shouldUseOrderMealNames) {
          updatedMealGroup = (orderMealNames || []).map(itemMeal => {
            const { name } = itemMeal || {};

            const meal = meal_group.find(item => item && item.name === name);
            return isObject(meal) ? updateRecipes(meal) : itemMeal;
          });
        } else {
          updatedMealGroup = (meal_group || []).map(meal => updateRecipes(meal));
        }

        return {
          ...existingMeal,
          meal_group: updatedMealGroup,
        };
      }

      return {
        _id: mongoObjectId(),
        day: dayItem,
        meal_group: [],
      };
    });
  }, [weekMealClients, daysOfWeek]);

  const [weekData, setWeekData] = useState(() => generateNewWeekMealClients());
  const [weekDataCloned, setWeekDataCloned] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);
  const [overMealId, setOverMealId] = useState(null);
  const [isRearranging, setIsRearranging] = useState(false);
  const [isError, setIsError] = useState(false);
  const [overDayId, setOverDayId] = useState(null);

  useEffect(() => {
    setWeekData(generateNewWeekMealClients());
  }, [generateNewWeekMealClients]);

  useEffect(() => {
    if (isRearrangeMode) {
      setIsRearranging(isRearrangeMode);
    } else {
      setIsError(false);
      setOverDayId(null);
    }

    if (isRearranging && isRearranging !== isRearrangeMode) {
      setWeekData(prevWeekData =>
        prevWeekData.map(dayItem => {
          const { meal_group } = dayItem || {};

          const updatedMealGroup = meal_group.map(meal => {
            const { recipes } = meal || {};
            const hasRecipes = recipes.length > 0;

            return {
              ...meal,
              is_recipes_empty: !hasRecipes,
            };
          });

          return {
            ...dayItem,
            meal_group: updatedMealGroup,
          };
        }),
      );
    }
  }, [isRearrangeMode]);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const getRecipeById = (data, { mealId, recipeId }) => {
    const flatRecipes = (data || []).flatMap(({ meal_group = [] }) =>
      meal_group.flatMap(({ recipes = [] }) => recipes),
    );
    const recipe = (flatRecipes || []).find(({ recipe }) => {
      const { _id } = recipe || {};
      const [mId, rId] = (_id || '').split('-');
      return mId === mealId && rId === recipeId;
    });
    return recipe;
  };

  const moveToDifferentMealSameDay = ({
    recipeId,
    targetIndex,
    targetDayId,
    currentDayId,
    targetMealId,
    currentMealId,
  }) => {
    if (currentDayId === targetDayId) {
      setWeekData(prevWeekData => {
        return prevWeekData.map(day => {
          const { meal_group } = day || {};
          const sourceMeal = meal_group.find(({ _id }) => _id === currentMealId);
          const targetMeal = meal_group.find(({ _id }) => _id === targetMealId);

          if (isEmpty(targetMeal) || isEmpty(sourceMeal)) return day;

          const { recipes: sourceMealRecipes } = sourceMeal || {};
          const { recipes: targetMealRecipes } = targetMeal || {};

          const recipeToMove = sourceMealRecipes.find(({ recipe }) => (recipe || {})._id === recipeId);
          const updatedSourceMealRecipes = sourceMealRecipes.filter(({ recipe }) => (recipe || {})._id !== recipeId);
          const finalTargetMealRecipes = [
            ...targetMealRecipes.slice(0, targetIndex),
            recipeToMove,
            ...targetMealRecipes.slice(targetIndex),
          ];

          return {
            ...day,
            meal_group: meal_group.map(meal => {
              const { _id } = meal || {};

              if (_id === currentMealId) {
                return { ...meal, recipes: updatedSourceMealRecipes };
              }
              if (_id === targetMealId) {
                return { ...meal, recipes: finalTargetMealRecipes };
              }
              return meal;
            }),
          };
        });
      });
    }
  };

  const moveToAnotherDayMeal = ({ recipeId, targetIndex, targetDayId, currentDayId, targetMealId, currentMealId }) => {
    if (currentDayId !== targetDayId) {
      setWeekData(prevWeekData => {
        return prevWeekData.map(day => {
          const { _id, meal_group } = day || {};
          if (_id === currentDayId) {
            const sourceMeal = meal_group.find(({ _id }) => _id === currentMealId);
            if (isEmpty(sourceMeal)) return day;

            const { recipes: sourceMealRecipes } = sourceMeal;
            const updatedSourceMealRecipes = sourceMealRecipes.filter(({ recipe }) => (recipe || {})._id !== recipeId);

            return {
              ...day,
              meal_group: meal_group.map(meal => {
                if ((meal || {})._id === currentMealId) {
                  return { ...meal, recipes: updatedSourceMealRecipes };
                }
                return meal;
              }),
            };
          } else if (_id === targetDayId) {
            const [mId, rId] = (recipeId || '').split('-');
            const recipeToMove = getRecipeById(weekDataCloned, { mealId: mId, recipeId: rId });
            const targetMeal = meal_group.find(({ _id }) => _id === targetMealId);
            if (isEmpty(targetMeal)) return day;

            const { recipes: targetMealRecipes } = targetMeal || {};
            const finalTargetMealRecipes = [
              ...targetMealRecipes.slice(0, targetIndex),
              recipeToMove,
              ...targetMealRecipes.slice(targetIndex),
            ];

            return {
              ...day,
              meal_group: meal_group.map(meal => {
                if ((meal || {})._id === targetMealId) {
                  return { ...meal, recipes: finalTargetMealRecipes };
                }
                return meal;
              }),
            };
          }
          return day;
        });
      });
    }
  };

  const handleDragStart = ({ active }) => {
    const { id } = active || {};
    const [mealId, recipeId] = (id || '').split('-');
    setActiveItemId({ mealId, recipeId });
    setWeekDataCloned(weekData);
    setIsError(false);
    setOverDayId(null);
  };

  const handleDragMove = ({ over }) => {
    if (isEmpty(over)) {
      setWeekData(weekDataCloned);
    }
  };

  const handleDragOver = ({ active, over }) => {
    if (!over || !active) return;
    const { id: activeId } = active || {};
    const { id: overId } = over || {};

    const activeDay = weekData.find(({ meal_group }) =>
      meal_group.some(({ recipes }) => recipes.some(({ recipe }) => (recipe || {})._id === activeId)),
    );
    if (isEmpty(activeDay)) return;

    const { _id: activeDayId, meal_group: activeDayMeals } = activeDay || {};
    const activeMeal = activeDayMeals.find(({ recipes }) =>
      recipes.some(({ recipe }) => (recipe || {})._id === activeId),
    );
    if (isEmpty(activeMeal)) return;

    const { _id: activeMealId, recipes: activeMealRecipes } = activeMeal || {};
    const { recipe: activeRecipe } = activeMealRecipes.find(({ recipe }) => (recipe || {})._id === activeId);
    if (isEmpty(activeRecipe)) return;

    let overDay = weekData.find(({ meal_group }) =>
      meal_group.some(
        ({ _id, recipes }) => recipes.some(({ recipe }) => (recipe || {})._id === overId) || _id === overId,
      ),
    );
    if (isEmpty(overDay)) return;

    const { _id: overDayId, meal_group: overDayMeals } = overDay || {};
    let overMeal = overDayMeals.find(
      ({ _id, recipes }) => recipes.some(({ recipe }) => (recipe || {})._id === overId) || _id === overId,
    );
    if (isEmpty(overMeal)) return;

    const { _id: overMealId, recipes: overMealRecipes } = overMeal || {};
    const overIndex = overMealRecipes.findIndex(({ recipe }) => (recipe || {})._id === overId);

    const isBelowOverItem =
      active.rect.current.translated && active.rect.current.translated.top > over.rect.top + over.rect.height;
    const modifier = isBelowOverItem ? 1 : 0;
    const newIndex = overIndex >= 0 ? overIndex + modifier : overMealRecipes.length;

    setOverMealId(overMealId);

    if (activeDayId === overDayId && activeMealId !== overMealId) {
      // Move the recipe to the same day but a different meal.
      moveToDifferentMealSameDay({
        currentDayId: activeDayId,
        targetDayId: activeDayId,
        recipeId: activeRecipe._id,
        currentMealId: activeMealId,
        targetMealId: overMealId,
        targetIndex: newIndex,
      });
    } else if (activeDayId !== overDayId) {
      // Move the recipe to a different meal and a different day.
      moveToAnotherDayMeal({
        currentDayId: activeDayId,
        targetDayId: overDayId,
        recipeId: activeRecipe._id,
        currentMealId: activeMealId,
        targetMealId: overMealId,
        targetIndex: newIndex,
      });
    }
  };

  // Move the recipe to the same meal and same day.
  const handleDragEnd = ({ active, over }) => {
    if (!over || !active) return;

    const { id: activeId } = active || {};
    const { id: overId } = over || {};

    const activeDay = weekData.find(({ meal_group }) =>
      meal_group.some(({ recipes }) => recipes.some(({ recipe }) => (recipe || {})._id === activeId)),
    );
    if (isEmpty(activeDay)) return;

    const { _id: activeDayId, meal_group } = activeDay || {};
    const activeMeal = meal_group.find(({ recipes }) => recipes.some(({ recipe }) => (recipe || {})._id === activeId));
    if (isEmpty(activeMeal)) return;

    const overMeal = activeMeal;

    const { _id: activeMealId, recipes = [] } = activeMeal || {};
    const { _id: overMealId } = overMeal || {};
    if (activeMealId === overMealId) {
      const newOverId = overId === activeMealId ? activeId : overId;

      const activeIndex = recipes.findIndex(({ recipe }) => (recipe || {})._id === activeId);
      const overIndex = recipes.findIndex(({ recipe }) => (recipe || {})._id === newOverId);

      const newRecipes = arrayMove(recipes, activeIndex, overIndex);

      const recipeTracker = {};
      const filteredRecipes = newRecipes.filter(recipeItem => {
        const { recipe } = recipeItem || {};
        const { _id } = recipe || {};
        const [mealId, recipeId] = (_id || '').split('-');

        if (!recipeTracker[recipeId]) {
          recipeTracker[recipeId] = mealId;
          return true;
        }

        return recipeTracker[recipeId] === mealId;
      });

      const newWeekData = weekData.map(day => {
        const { _id } = day || {};
        if (_id === activeDayId) {
          return {
            ...day,
            meal_group: meal_group.map(meal => {
              const { _id } = meal || {};
              if (_id === activeMealId) {
                return { ...meal, recipes: filteredRecipes };
              }
              return meal;
            }),
          };
        }
        return day;
      });

      handleReArrange(activeId, overId, overIndex);
      setWeekData(newWeekData);

      // If droppable on day no meal categories should show error message after 2 seconds
      const overElement = document.getElementById(overId);

      if (overElement) {
        setOverDayId(overId);
        const childElement = overElement.querySelector('.non-droppable');
        const hasNonDroppableClass = childElement && childElement.classList.contains('non-droppable');
        const hasIsOverDayClass = childElement && childElement.classList.contains('is-over-day');

        setTimeout(() => {
          if (overIndex === -1 && hasNonDroppableClass && hasIsOverDayClass) {
            setIsError(true);
          }
        }, 2000);
      }
    }
  };

  const handleDragCancel = () => {
    setActiveItemId(null);
    setWeekDataCloned(null);
  };

  const handleReArrange = (activeId, overId, overIndex) => {
    const [mealId] = (overId || '').split('-');
    if (!mealId || !overMealId) return;

    let overDayItem, overMealItem, mealGroupId;

    if (mealId === overMealId) {
      mealGroupId = mealId;
      overDayItem = weekDataCloned.find(({ meal_group }) => meal_group.some(({ _id }) => _id === mealId));
      overMealItem = weekDataCloned.flatMap(({ meal_group }) => meal_group).find(({ _id }) => _id === mealId);
    } else {
      mealGroupId = overMealId;
      overDayItem = weekDataCloned.find(({ meal_group }) => meal_group.some(({ _id }) => _id === overMealId));
      overMealItem = weekDataCloned.flatMap(({ meal_group }) => meal_group).find(({ _id }) => _id === overMealId);
    }

    const overRecipeItem = weekDataCloned
      .flatMap(({ meal_group }) => meal_group.flatMap(({ recipes }) => recipes))
      .find(({ recipe }) => (recipe || {})._id === activeId);

    const { day } = overDayItem || {};
    const { name, is_system } = overMealItem;
    const { _id } = overRecipeItem || {};

    const data = {
      client: clientId,
      meal_group_name: name,
      new_index: overIndex,
      day: day,
      is_system: is_system,
      meal_group_id: mealGroupId,
    };

    if (overIndex === -1) return;

    reArrangeMealClientRecipe(data, _id);
  };

  return (
    <S.Wrapper isRearrangeMode={isRearrangeMode}>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        autoScroll={{ layoutShiftCompensation: false }}
      >
        <SortableContext items={weekData.map(({ _id }) => _id)}>
          {weekData.map(dayData => {
            const { _id, day, meal_group = [] } = dayData || {};

            const dateMoment = moment(day);
            const dayTitle = getDayTitle(dateMoment, clientTimezone);
            const hasMeals = meal_group.length > 0 && (meal_group || []).some(({ recipes }) => recipes.length > 0);
            const macroNutrients = !isRearrangeMode && getTotalMacroNutrients(meal_group);

            return (
              <S.DayWrapper key={_id} id={_id} isRearrangeMode={isRearrangeMode}>
                <S.DayHeader>
                  <S.TitleOfWeek>{dayTitle}</S.TitleOfWeek>
                  {hasMeals && !isRearrangeMode && (
                    <>
                      <S.EditButton disabled={false} onClick={() => onAddMeals(dateMoment)}>
                        Edit
                      </S.EditButton>
                      <MacroInfo viewMode={viewMode} macroNutrients={macroNutrients} />
                    </>
                  )}
                  {isError && overDayId === _id && (
                    <div className="error-message">Can't drop recipes, no meal categories</div>
                  )}
                </S.DayHeader>
                {isRearrangeMode ? (
                  <RearrangeView
                    id={_id}
                    meals={meal_group}
                    cloudfrontList={cloudfrontList}
                    isLoadingWeek={isLoadingWeek}
                  />
                ) : (
                  <NormalView
                    meals={meal_group}
                    dateMoment={dateMoment}
                    hasMeals={hasMeals}
                    onAddMeals={onAddMeals}
                    cloudfrontList={cloudfrontList}
                  />
                )}
              </S.DayWrapper>
            );
          })}
        </SortableContext>
        <DragOverlay>
          {activeItemId ? (
            <DragItemRecipe
              recipe={(getRecipeById(weekData, activeItemId) || {}).recipe}
              isDragging
              cloudfrontList={cloudfrontList}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </S.Wrapper>
  );
};

export default WeeklyCategory;
