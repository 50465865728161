import Request from 'configs/request';
import _ from 'lodash';
import { STEP_UNIQUE_CODE } from 'constants/commonData';
import { get } from 'lodash';

export const Types = {
  CHANGE_STEP_METRIC_SELECTED: 'CHANGE_STEP_METRIC_SELECTED',
  MANUAL_STEP_FETCH_CHART_DATA_REQUEST: 'MANUAL_STEP_FETCH_CHART_DATA_REQUEST',
  MANUAL_STEP_FETCH_CHART_DATA_SUCCESS: 'MANUAL_STEP_FETCH_CHART_DATA_SUCCESS',
  MANUAL_STEP_FETCH_CHART_DATA_ERROR: 'MANUAL_STEP_FETCH_CHART_DATA_ERROR',
  MANUAL_STEP_FETCH_PROGRESSING_REQUEST: 'MANUAL_STEP_FETCH_PROGRESSING_REQUEST',
  MANUAL_STEP_FETCH_PROGRESSING_SUCCESS: 'MANUAL_STEP_FETCH_PROGRESSING_SUCCESS',
  MANUAL_STEP_FETCH_PROGRESSING_ERROR: 'MANUAL_STEP_FETCH_PROGRESSING_ERROR',
  STEP_FETCHING_CHART_DATA: 'STEP_FETCHING_CHART_DATA',
  STEP_FETCH_CHART_DATA_SUCCESS: 'STEP_FETCH_CHART_DATA_SUCCESS',
  STEP_FETCH_CHART_DATA_ERROR: 'STEP_FETCH_CHART_DATA_ERROR',
  STEP_GET_GOAL_SUCCESS: 'STEP_GET_GOAL_SUCCESS',
  STEP_SET_GOAL_SUCCESS: 'STEP_SET_GOAL_SUCCESS',
  STEP_UPDATE_TIME_RANGE: 'STEP_UPDATE_TIME_RANGE',
};

export const changeStepMetricSelected = stepSelected => ({
  type: Types.CHANGE_STEP_METRIC_SELECTED,
  payload: { stepSelected },
});

export const getManualStepChartData = query => (dispatch, getState) => {
  const {
    rootReducer: {
      client: { workingClientDetail },
      clientStep: { manualStepSelected },
    },
  } = getState();
  const params = {
    ...query,
    client: workingClientDetail._id,
    metric_code: manualStepSelected.unique_code,
  };
  dispatch({ type: Types.MANUAL_STEP_FETCH_CHART_DATA_REQUEST });
  return dispatch(
    Request.get(
      { url: '/api/v2/body-metric-entry/chart-data-group-by-day', params },
      true,
      response => {
        const data = get(response, 'data.data', []);
        const filtered = data.filter(item => !!item.value);
        const dailyAverage = parseInt(_.sumBy(filtered, 'value') / filtered.length || 0);
        dispatch({
          type: Types.MANUAL_STEP_FETCH_CHART_DATA_SUCCESS,
          payload: { chartData: filtered, dailyAverage },
        });
      },
      () => {
        dispatch({ type: Types.MANUAL_STEP_FETCH_CHART_DATA_ERROR });
      },
    ),
  );
};

export const refreshManualStepChart = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        clientStep: { fromDate, toDate, manualStepSelected },
      },
    } = getState();
    if (manualStepSelected) {
      dispatch(getManualStepChartData({ fromDate, toDate }));
    }
  };
};

export const getManualStepProgressData = query => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        client: { workingClientDetail },
        clientStep: { manualStepSelected },
      },
    } = getState();
    return dispatch(
      Request.get(
        {
          url: 'api/v2/body-metric-entry/chart-data',
          params: {
            timezone: user.timezone,
            client: workingClientDetail._id,
            metric_code: manualStepSelected.unique_code,
            ...query,
          },
        },
        true,
        response => {
          const data = get(response, 'data.data', []);
          dispatch({ type: Types.MANUAL_STEP_FETCH_PROGRESSING_SUCCESS, payload: data });
        },
        () => {
          dispatch({ type: Types.MANUAL_STEP_FETCH_PROGRESSING_ERROR });
        },
      ),
    );
  };
};

export const getChartData = query => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        client: { workingClientDetail },
      },
    } = getState();
    dispatch({ type: Types.STEP_FETCHING_CHART_DATA, payload: { ...query } });

    return dispatch(
      Request.get(
        {
          url: '/api/body-metric-entry/steps-metric-data',
          params: {
            ...query,
            client: _.get(workingClientDetail, '_id'),
            metric_code: STEP_UNIQUE_CODE,
          },
        },
        true,
        response => {
          const data = get(response, 'data.data', []);
          const filtered = data.filter(item => !!item.value);
          const dailyAverage = parseInt(_.sumBy(filtered, 'value') / filtered.length || 0);
          dispatch({ type: Types.STEP_FETCH_CHART_DATA_SUCCESS, payload: { chartData: filtered, dailyAverage } });
        },
        () => {
          dispatch({ type: Types.STEP_FETCH_CHART_DATA_ERROR });
        },
      ),
    );
  };
};

export const changeTimeRange = data => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        clientStep: { manualStepSelected },
      },
    } = getState();
    dispatch({ type: Types.STEP_UPDATE_TIME_RANGE, payload: data });

    if (manualStepSelected) {
      return dispatch(getManualStepChartData(data));
    }
    return dispatch(getChartData({ fromDate: data.fromDate, toDate: data.toDate }));
  };
};

export const getStepGoal = () => (dispatch, getState) => {
  const {
    rootReducer: {
      client: { workingClientDetail },
      clientStep: { manualStepSelected },
    },
  } = getState();

  return dispatch(
    Request.get(
      {
        url: '/api/body-metric/get-client-target',
        params: {
          unique_code: manualStepSelected ? manualStepSelected.unique_code : STEP_UNIQUE_CODE,
          client: _.get(workingClientDetail, '_id'),
        },
      },
      true,
      response => {
        const { data } = response.data;
        dispatch({ type: Types.STEP_GET_GOAL_SUCCESS, payload: _.get(data, 'data.value', null) });
      },
    ),
  );
};

export const setStepGoal = value => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        client: { bodymetricTypes, workingClientDetail },
        clientStep: { manualStepSelected },
      },
    } = getState();

    const stepObj = manualStepSelected || _.find(bodymetricTypes, item => item.unique_code === STEP_UNIQUE_CODE);

    if (stepObj) {
      const bodyData = {
        value,
        unique_code: stepObj.unique_code,
        client: workingClientDetail._id,
        unit: _.get(stepObj, 'unit._id'),
      };

      dispatch({ type: Types.STEP_SET_GOAL_SUCCESS, payload: value });

      return dispatch(Request.post({ url: '/api/body-metric/add-client-target', data: bodyData }, true));
    }
  };
};

export const getStepProgressDataWeekly = params =>
  Request.get({ url: '/api/body-metric-entry/metric-data-progress', params }, true);

export const getStepProgressDataMonthly = params =>
  Request.get({ url: '/api/body-metric-entry/metric-data-monthly-average', params }, true);
