import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import PlayIcon from 'assets/icons/freestyle-section-play-icon.svg';

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 5px;
`;

export const LinkAttachmentWrapper = styled.div`
  width: 100%;
  position: relative;
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 5px;
  .close-icon {
    cursor: pointer;
    margin-left: 15px;
  }

  .link-icon-placeholder {
    flex: 0 0 auto;
  }

  .link-input {
    margin-left: 15px;
    flex: 1;
  }

  .container {
    display: flex;
    align-items: center;
    padding: 0px;
  }

  .video-preview {
  }

  .taskAttachment__uploadCover {
    width: 215px;
    height: 125px;
  }

  .form-group--link {
    margin-bottom: 0;
  }

  .upload__placeholder {
    padding: 0 20px;
  }
`;

export const LinkPreviewWrapper = styled.div`
  background: #fafbfc;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  width: 112px;
  height: 70px;
  outline: none;
  position: relative;

  ${props =>
    props.src &&
    css`
      background-image: url(${props.src});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      cursor: pointer;
    `}
`;

export const MediaLinkPreview = styled.div`
  width: 112px;
  height: 70px;
  flex: 0 0 112px;
  border-radius: 5px;
  position: relative;
  background-color: #fafbfc;
  background-image: url(${CDN_URL}/images/create_resource_link_icon.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  overflow: hidden;

  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
      background-size: cover;
    `}

  ${props =>
    props.shouldRenderPlayIcon &&
    `
:before {
    content: '';
    position: absolute;
    left: 4px;
    bottom: 4px;
    z-index: 1;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center;
    background-image: url(${PlayIcon});
    display: block;
  }
    `}
  

  &.vimeo {
    :before {
      display: block;
      background-image: url(${CDN_URL}/images/logo_vimeo.png);
    }
  }

  &.youtube {
    :before {
      display: block;
      background-image: url(${CDN_URL}/images/logo_youtube.png);
    }
  }

  .media-player__trigger,
  .media-player__button {
    background: transparent;
  }
`;

export const CloseIcon = styled.div`
  z-index: 1;
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: 14px;
  border-radius: 50%;
`;
