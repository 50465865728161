// Libs
import React, { useEffect, useMemo, useState } from 'react';

// Constants
import { PLANS } from 'constants/commonData';

// Assets
import MealPlanUpgradePathBanner from 'assets/images/meal_plan_upgrade_path_banner.jpg';

// Styles
import * as S from './style';

const MealPlanFallBack = props => {
  const { isClientTab, match } = props || {};

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const { clientId } = (match || {}).params || {};

    setTimeout(() => {
      setLoaded(true);
    }, 50);
    // reset index state
    window.history.replaceState({}, '', isClientTab ? `/home/client/${clientId}/meal-plan` : '/home/meal-plans');
  }, []);

  const { imageSrc, title, description, targetPlan, contentButton } = useMemo(() => {
    if (isClientTab) {
      return {
        imageSrc: MealPlanUpgradePathBanner,
        title: 'Meal Plan',
        description:
          'Provide weekly meal guidance to your clients. Choose from over 500 recipes approved by registered dieticians.',
        contentButton: 'Get Meal Plans',
        targetPlan: { tier: PLANS.pro.key },
      };
    } else {
      return {
        imageSrc: MealPlanUpgradePathBanner,
        title: 'Meal Plans',
        description:
          'Create easy to roll out meal plan templates for different diets and training phases. Provide sophisticated nutrition guidance in a snap.',
        contentButton: 'Get Meal Plans',
        targetPlan: {
          tier: PLANS.pro.key,
          addOns: {
            meal_plan: true,
          },
        },
      };
    }
  }, []);

  if (!loaded) {
    return <></>;
  }

  return (
    <S.MealPlanFallbackWrapper>
      <S.BannerImageWrapper>
        <S.BannerImage src={imageSrc} alt="" />
      </S.BannerImageWrapper>
      <S.LeftSide>
        <S.BannerContent>
          <S.ContentTitle>{title}</S.ContentTitle>
          <S.ContentDesc>{description}</S.ContentDesc>
        </S.BannerContent>
        <S.BannerActions className="meal-plan-upgrade" text={contentButton} planWillSelect={targetPlan} />
      </S.LeftSide>
    </S.MealPlanFallbackWrapper>
  );
};

export default MealPlanFallBack;
