import styled, { keyframes } from 'styled-components';

import backgroundPreview from 'assets/images/ondemand-preview.png';

const pulse = keyframes`
  0% {
    -webkit-transform:scale(1); 
  }

  20% {
    -webkit-transform:scale(3.6);
    opacity: 0.1;
  }

  40% { 
    -webkit-transform:scale(6);
  }

  60% { 
    -webkit-transform:scale(8.7);
    opacity: 0.05;
  }

  85% { 
    -webkit-transform:scale(10);
    opacity: 0
  }

  100% {
    opacity: 0;
  }
`;

export const FirstTimeWrapper = styled.div`
  position: relative;
  display: inline-block;
  background-color: #ffff;
  border-radius: 4px;
  z-index: 10002;
`;

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 2px;
  border: 1px solid #dadfea;
  border-radius: 3px;
`;

export const PlaceholderMessage = styled.div`
  width: 320px;
  padding: 20px 0;
  height: 428px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  right: -347px;
  top: -26px;
  position: absolute;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-width: 1px 0px 0px 1px;
    border-top-style: solid;
    border-left-style: solid;
    border-image: initial;
    border-right-style: initial;
    border-bottom-style: initial;
    border-color: #dcdcde;
    transform: rotate(-45deg);
    position: absolute;
    right: 315px;
    top: 15px;
    background: #ffffff;
  }
`;

export const PlaceholderTitle = styled.h4`
  color: #151619;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
  padding: 0 20px;
  text-transform: initial;
`;

export const PlaceholderDesc = styled.p`
  color: #4a4d56;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
  padding: 0 20px;
  text-transform: initial;
`;

export const PlaceholderPreview = styled.div`
  background-image: url(${backgroundPreview});
  background-color: #ffff;
  background-repeat: no-repeat;
  background-position: center 15px;
  height: 244px;
  width: 100%;
  background-size: contain;
`;

export const PlaceholderConfirm = styled.div`
  color: #5e59ff;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  padding: 0 20px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-transform: initial;
`;

export const PlaceholderActions = styled.div`
  text-align: right;
  padding-top: 18px;
`;

export const Aura = styled.div`
  position: absolute;
  right: -14px;
  top: -10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f47435;
  animation: ${pulse} 1.25s infinite cubic-bezier(0.4, 0, 1, 1);
`;
