/**
 * @flow
 */

import { connect } from 'react-redux';
import Teammates from './component';
import { viewListTeamate, resendInvokeInvitation } from 'actions/client';
import { changeAutoWaitingEnroll } from 'redux/teammate/actions';
import { updateSettingInvite } from 'actions/profile';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => {
  const { isModalOpen, modal, cloudfrontList, sideBarVisible, user, rootReducer } = state;
  const permission = rootReducer.permission || {};

  return {
    user,
    cloudfrontList,
    sideBarVisible,
    isModalOpen,
    modal,
    permission,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    viewListTeamate: () => dispatch(viewListTeamate()),
    resendInvokeInvitation: (isResend, id) => dispatch(resendInvokeInvitation(isResend, id)),
    changeAutoWaitingEnroll: bindActionCreators(changeAutoWaitingEnroll, dispatch),
    updateSettingInvite: bindActionCreators(updateSettingInvite, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teammates);
