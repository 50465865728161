import { SUCCESS_GET_VERIFY_TEAM } from 'actions/client';

export const workspace = (state = [], action) => {
  switch (action.type) {
    case SUCCESS_GET_VERIFY_TEAM:
      return action.payload;

    default:
      return state;
  }
};
