import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { Checkbox } from 'shared/FormControl';
import * as Layout from 'shared/LibraryLayout';
import {
  selectedClientIdsContactList,
  selectedClientIdContactList,
  updateMarkedReadMessage,
  undoRequestContactList,
  getListSurveySubmission,
  resendRequestContactList,
} from 'redux/contact-list/actions';
import { formatSameYear, getUserShortName, copyToClipboard, formatSameYearWithTime } from 'utils/commonFunction';
import ViewMessagePopup from 'components/Package/components/CustomPopup/ViewMessagePopup';
import ResponseSurveyPopup from 'components/Package/components/CustomPopup/ResponseSurveyPopup';
import { CustomToastMessage } from 'components/Package/components/CustomToast.style';
import TableEmpty from 'components/Package/components/TableEmpty';
import CustomPopup from 'components/Package/components/CustomPopup';
import { getRejectedReasonContent } from 'components/Package/components/RejectReason';
import { toggleModal } from 'actions/modal';

import { CONTACT_STATUS } from '../constants';
import * as S from '../../../components/CustomTable.style';
import {
  checkUndoDurationTime,
  getTimeActionTooltip,
  getTrainerNameApprovedOrRejected,
  getWaitlistStatus,
} from 'components/Package/components/common';
import { renderActionErrorPopup } from 'components/Package/components/CustomPopup/GroupActionPopup';

import { ReactComponent as Message } from 'assets/icons/has-message.svg';
import { ReactComponent as MessageUnread } from 'assets/icons/message-unread.svg';
import { ReactComponent as ContactedPhone } from 'assets/icons/contacted-phone.svg';
import { ReactComponent as ContactedPhoneCopy } from 'assets/icons/contacted-phone-copy.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/popup-warning-icon.svg';
import { ReactComponent as SurveyIcon } from 'assets/icons/survey-icon.svg';
import { PURCHASE_VALUE } from '../../Settings/constants';

function View({
  list,
  loading,
  toggleModal,
  workingPackage,
  onOpenApprovePopup,
  clientIdsSelected,
  onOpenRejectPopup,
  undoRequestContactList,
  updateMarkedReadMessage,
  getListSurveySubmission,
  resendRequestContactList,
  selectedClientIdContactList,
  selectedClientIdsContactList,
}) {
  const hasSelected = !!get(clientIdsSelected, 'length', 0);
  const selectedIds = hasSelected ? clientIdsSelected : [];
  const hasSurveyResponse = !!get(workingPackage, 'package_purchase_option.survey_id', false);
  const isContact = get(workingPackage, 'package_purchase_option.type') === PURCHASE_VALUE.CONTACT;

  const handleSelectClient = (value, event) => {
    event.stopPropagation();

    if (event.target && event.target.checked) {
      selectedClientIdsContactList([...clientIdsSelected, value.id]);
    } else {
      selectedClientIdsContactList(filter(clientIdsSelected, clientId => clientId !== value.id));
    }
  };

  const renderMessageIcon = (hasMessage, isMarkedRead) => {
    if (!hasMessage) return;
    return isMarkedRead ? <Message /> : <MessageUnread />;
  };

  const handleCopy = phone => {
    copyToClipboard(phone);
    toast(<CustomToastMessage marginRight="-40px">{`Phone number has been copied to\nclipboard.`}</CustomToastMessage>);
  };

  const handleRejectRequest = item => {
    const clientId = [item.id];
    onOpenRejectPopup();
    selectedClientIdContactList(clientId);
  };

  const handleApproveRequest = item => {
    const clientId = [item.id];
    onOpenApprovePopup(clientId.length);
    selectedClientIdContactList(clientId);
  };

  const handleOpenMessagePopup = item => {
    if (!item.message) return;
    const newClientInfo = {
      ...item.client_info,
      message: item.message,
      email: item.client_email,
    };
    toggleModal(
      true,
      <ViewMessagePopup title="Client message" clientInfo={newClientInfo} onClose={() => toggleModal(false)} />,
    );

    if (!item.is_marked_as_read) {
      updateMarkedReadMessage(item.id);
    }
  };

  const handleOpenReasonPopup = item => {
    toggleModal(true, <ViewMessagePopup title="Decline reason" clientInfo={item} onClose={() => toggleModal(false)} />);
  };

  const handleOpenSurveyResponsePopup = item => {
    const data = {
      ...get(item, 'client_info'),
      email: get(item, 'client_email'),
    };

    if (get(item, 'survey_submission_id')) {
      getListSurveySubmission(item.survey_submission_id);
    }

    toggleModal(true, <ResponseSurveyPopup data={data} onClose={() => toggleModal(false)} />);
  };

  const handleOpenUndoRequest = (disabledAction, item) => {
    if (disabledAction) return;
    toggleModal(
      true,
      <CustomPopup
        title="Are you sure you want to undo?"
        icon={<WarningIcon />}
        buttonText="Confirm"
        buttonClassName="button-confirm"
        onToggle={() => toggleModal(false)}
        onSubmit={toast => handleUndoRequest(toast, item.id)}
        content={`The request status will revert to waiting, and we’ll email the client to let them know.`}
        toastStyle={{
          marginRight: '-40px',
        }}
      />,
    );
  };

  const handleUndoRequest = (toastMessage, id) => {
    if (!!id) {
      undoRequestContactList(id, {
        callbackSuccess: message => {
          toggleModal(false);
          toastMessage(message);
        },
        callbackFailure: errorCode => {
          renderActionErrorPopup({ errorCode, toggleModal });
        },
      });
    }
  };

  const handleOpenResendRequest = item => {
    toggleModal(
      true,
      <CustomPopup
        title="Resend the offer?"
        icon={<WarningIcon />}
        buttonText="Confirm"
        buttonClassName="button-confirm"
        onToggle={() => toggleModal(false)}
        onSubmit={toast => handleResendRequest(toast, item.id)}
        content={`Are you sure you want to resend the offer? We will send the email & reminders to the client.`}
        toastStyle={{
          marginRight: '-40px',
        }}
      />,
    );
  };

  const handleResendRequest = (toastMessage, id) => {
    if (!!id) {
      resendRequestContactList(id, {
        callbackSuccess: message => {
          toggleModal(false);
          toastMessage(message);
        },
        callbackFailure: errorCode => {
          renderActionErrorPopup({ errorCode, toggleModal });
        },
      });
    }
  };

  if (!loading && list && !list.length) return <TableEmpty />;
  return (
    <Layout.TableBody>
      {map(list, (item, index) => {
        const {
          id,
          client_name,
          client_email,
          status,
          created_at,
          client_info,
          phone_number,
          message,
          trainer_info,
          reject_reason,
          is_marked_as_read = false,
          reject_reason_note = '',
          rejected_at,
          is_marked_as_undo = false,
          survey_submission_id,
          phone_code,
        } = item || {};
        const uuid = get(item, 'id', index);
        const requestDate = formatSameYear(created_at);
        const requestDateTooltip = formatSameYearWithTime(created_at);

        const isChecked = selectedIds.includes(id);
        const disabledSelect = status !== CONTACT_STATUS.WAITING;
        const isShowImpactPerson = [
          CONTACT_STATUS.OFFERED,
          CONTACT_STATUS.REJECTED_BY_COACH_AFTER_CONSULTATION,
          CONTACT_STATUS.REJECTED_BY_COACH_WITHOUT_CONSULTATION,
        ].includes(status);
        const isShowReason = status === CONTACT_STATUS.REJECTED_BY_CLIENT;
        const isWaiting = status === CONTACT_STATUS.WAITING;
        const isRejected = [
          CONTACT_STATUS.REJECTED_BY_COACH_AFTER_CONSULTATION,
          CONTACT_STATUS.REJECTED_BY_COACH_WITHOUT_CONSULTATION,
        ].includes(status);
        const isNoResponse = status === CONTACT_STATUS.NO_RESPONSE;
        const trainerFullName = `${get(trainer_info, 'first_name', '')} ${get(trainer_info, 'last_name', '')}`;
        const phone = `${phone_code ? `${phone_code} ` : ''} ${phone_number}`;

        const disabledUndoAction = is_marked_as_undo || checkUndoDurationTime(rejected_at);
        const isShowUndoAction = isRejected && isContact && !disabledUndoAction;
        const isShowResendAction = isNoResponse && isContact;

        const { statusParse, statusLabel } = getWaitlistStatus(status);
        const timeActionTooltip = getTimeActionTooltip(item);

        return (
          <S.TableRowWrapper key={uuid}>
            <S.TableCellWrapper key="client">
              <S.CustomTableCellWrapper
                disabledSelect={disabledSelect}
                onClick={e => e.stopPropagation()}
                className={classNames(isChecked ? 'checked' : null)}
              >
                <S.CheckBoxContainer>
                  <div className="checkbox__thumbnail" data-tip data-for={`checkbox-${uuid}`}>
                    <Checkbox
                      checked={isChecked}
                      disabled={disabledSelect}
                      onChange={event => handleSelectClient(item, event)}
                    />
                    {disabledSelect && (
                      <ReactTooltip
                        className="checkbox-tooltip app-tooltip"
                        id={`checkbox-${uuid}`}
                        effect="solid"
                        eventOff="mouseleave scroll mousewheel blur"
                        delayShow={100}
                      >
                        <span>No available actions for this client.</span>
                      </ReactTooltip>
                    )}
                  </div>
                  <S.Thumbnail className="thumbnail">
                    <Avatar
                      title
                      name={getUserShortName(client_info)}
                      size="24"
                      src={get(client_info, 'avatar.s', '')}
                      color={get(client_info, 'color')}
                    />
                  </S.Thumbnail>
                </S.CheckBoxContainer>
                <div className="name-wrapper">
                  <span className="text">{client_name}</span>
                  <span className="email">{client_email}</span>
                </div>
              </S.CustomTableCellWrapper>
            </S.TableCellWrapper>
            <S.TableCellWrapper key="phone-message">
              <div onClick={() => handleCopy(phone)} className="phone-icon" data-tip data-for={`phone-icon-${uuid}`}>
                <ContactedPhone className="contacted-phone" />
                <ContactedPhoneCopy className="contacted-phone-copy" />
                <ReactTooltip
                  className="phone-icon-tooltip app-tooltip"
                  id={`phone-icon-${uuid}`}
                  effect="solid"
                  eventOff="mouseleave scroll mousewheel blur"
                  delayShow={100}
                >
                  <span>{`Copy ${phone}`}</span>
                </ReactTooltip>
              </div>
              <div
                className={classNames('message-icon', !message ? 'not-pointer' : '')}
                onClick={() => handleOpenMessagePopup(item)}
              >
                {renderMessageIcon(message, is_marked_as_read)}
              </div>
            </S.TableCellWrapper>
            {hasSurveyResponse && (
              <S.TableCellWrapper key="survey">
                {!!survey_submission_id && (
                  <div className="survey-response" onClick={() => handleOpenSurveyResponsePopup(item)}>
                    <div className="survey-response__icon">
                      <SurveyIcon width={15} height={15} />
                    </div>
                    <span className="survey-response__action">View</span>
                  </div>
                )}
              </S.TableCellWrapper>
            )}
            <S.TableCellWrapper key="request_date">
              <span data-tip data-for={`request-date-${uuid}`} className="text pointer">
                {requestDate}
              </span>
              <ReactTooltip
                className="request-date-tooltip app-tooltip"
                id={`request-date-${uuid}`}
                effect="solid"
                eventOff="mouseleave scroll mousewheel blur"
                delayShow={100}
              >
                <span>{requestDateTooltip}</span>
              </ReactTooltip>
            </S.TableCellWrapper>
            <S.TableCellWrapper key="status">
              <div className="action-wrapper">
                <div className="status-wrapper">
                  <div className={classNames('status', statusParse)}>
                    <span
                      data-tip
                      data-for={`time-action-${uuid}`}
                      className={classNames('text', timeActionTooltip ? 'pointer' : '')}
                    >
                      {statusLabel}
                    </span>
                    {!!timeActionTooltip && (
                      <ReactTooltip
                        className="time-action-tooltip app-tooltip"
                        id={`time-action-${uuid}`}
                        effect="solid"
                        eventOff="mouseleave scroll mousewheel blur"
                        delayShow={100}
                        offset={{ top: 8 }}
                      >
                        <span>{timeActionTooltip}</span>
                      </ReactTooltip>
                    )}
                  </div>
                  {isShowImpactPerson && (
                    <div className="avatar-wrapper" data-tip data-for={`impact-person-${uuid}`}>
                      <Avatar
                        title
                        name={getUserShortName(trainer_info)}
                        size="24"
                        src={get(trainer_info, 'avatar', '')}
                        color={get(trainer_info, 'color')}
                      />
                      <ReactTooltip
                        className="impact-person-tooltip app-tooltip"
                        id={`impact-person-${uuid}`}
                        effect="solid"
                        eventOff="mouseleave scroll mousewheel blur"
                        delayShow={100}
                        offset={{ top: 1 }}
                      >
                        <span>{getTrainerNameApprovedOrRejected(statusParse, trainerFullName)}</span>
                      </ReactTooltip>
                    </div>
                  )}
                  {isShowUndoAction && (
                    <button
                      disabled={disabledUndoAction}
                      className="undo-action"
                      onClick={() => handleOpenUndoRequest(disabledUndoAction, item)}
                    >
                      <span>Undo</span>
                    </button>
                  )}
                  {isShowResendAction && (
                    <button className="resend-action" onClick={() => handleOpenResendRequest(item)}>
                      <span>Resend</span>
                    </button>
                  )}
                </div>
                {isWaiting && (
                  <S.ActionsWrapper>
                    <button onClick={() => handleRejectRequest(item)}>
                      <span>Decline</span>
                    </button>
                    <button className="border" onClick={() => handleApproveRequest(item)}>
                      <span>Offer</span>
                    </button>
                  </S.ActionsWrapper>
                )}
                {isShowReason && (
                  <S.ActionsWrapper>
                    <button
                      onClick={() =>
                        handleOpenReasonPopup({
                          ...client_info,
                          email: client_email,
                          message: reject_reason_note || getRejectedReasonContent(reject_reason),
                        })
                      }
                    >
                      <span>View reason</span>
                    </button>
                  </S.ActionsWrapper>
                )}
              </div>
            </S.TableCellWrapper>
          </S.TableRowWrapper>
        );
      })}
    </Layout.TableBody>
  );
}

const TableBody = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      packageDetail,
      contactList: { list, loading, total, pageSize, clientIdsSelected },
    },
  } = state;
  return {
    list,
    loading,
    total,
    pageSize,
    clientIdsSelected,
    workingPackage: packageDetail.get('workingPackage').toJS(),
  };
};

const mapDispatch = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    selectedClientIdsContactList: bindActionCreators(selectedClientIdsContactList, dispatch),
    selectedClientIdContactList: bindActionCreators(selectedClientIdContactList, dispatch),
    updateMarkedReadMessage: bindActionCreators(updateMarkedReadMessage, dispatch),
    undoRequestContactList: bindActionCreators(undoRequestContactList, dispatch),
    getListSurveySubmission: bindActionCreators(getListSurveySubmission, dispatch),
    resendRequestContactList: bindActionCreators(resendRequestContactList, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableBody);
