import styled, { css } from 'styled-components';

export const MultipleMediaBox = styled.div`
  position: relative;
  display: grid;
  gap: 8px;
  width: 388px;

  ${props =>
    props.showRecord &&
    css`
      * {
        cursor: not-allowed !important;
      }
    `}

  ${props =>
    props.length &&
    props.length === 1 &&
    css`
      display: block;
      max-width: 310px;
      max-height: 310px;
      width: unset;
      height: unset;

      img,
      video {
        max-width: 310px;
        max-height: 310px;
      }
    `}

  ${props =>
    props.length &&
    props.length === 2 &&
    css`
      grid-template-columns: repeat(2, 190px);
      grid-template-rows: 190px;
      height: 190px;
    `}

 ${props =>
    props.length &&
    props.length === 3 &&
    css`
      grid-template-columns: repeat(3, 124px);
      grid-template-rows: 124px;
      height: 124px;
    `}

   ${props =>
    props.length &&
    props.length === 4 &&
    css`
      grid-template-columns: repeat(2, 190px);
      grid-template-rows: repeat(2, 190px);
      height: 388px;
    `}

   ${props =>
    props.length &&
    props.length === 5 &&
    css`
      > :nth-child(1) {
        display: flex;
        grid-column-start: 1;
        grid-column-end: 4;
      }

      > :nth-child(2) {
        display: flex;
        grid-column-start: 4;
        grid-column-end: 7;
      }

      > :nth-child(3) {
        display: flex;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      > :nth-child(4) {
        display: flex;
        grid-column-start: 3;
        grid-column-end: 5;
      }

      > :nth-child(5) {
        display: flex;
        grid-column-start: 5;
        grid-column-end: 7;
      }

      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 190px 124px;
      height: 322px;
    `}
`;
