import React from 'react';
import DropdownOptions from 'shared/DropdownOptions';
import * as S from './style';
import { ACTIVATE_TYPES } from './../../constants';

const Activate = ({ onSelect, defaultValue = 1 }) => {
  return (
    <S.Wrapper>
      <DropdownOptions
        width="292px"
        height="42px"
        options={ACTIVATE_TYPES}
        selectedValue={defaultValue}
        onSelect={onSelect}
      />
    </S.Wrapper>
  );
};

export default Activate;
