import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const StartWorkout = styled.div`
  display: inline-block;
  padding: 8px 13px;
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  text-align: center;
  color: #7470ef;
  background: #ffffff;
  border-radius: 13px;
`;

export const Header = styled.div`
  margin-bottom: 20px;
  padding: 0 10px;

  .today {
    font-weight: bold;
    font-size: 7px;
    line-height: 11px;
    text-transform: uppercase;
  }

  .hello {
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #1e0a3c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const MobileNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-top: -2px;
`;

export const HeaderTitle = styled.div`
  p {
    font-size: 6px;
    color: #fff;
    margin-bottom: 0px;
    transform: translate(0px, 8px);
    span {
      font-weight: bold;
    }
  }
  img:first-child {
    display: block;
    transform: translate(0px, 6px);
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  h4 {
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;
    margin-bottom: 5px;
  }
  p {
    font-weight: normal;
    font-size: 7px;
    line-height: 12px;
  }
  .icons-container {
    width: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const WorkoutContent = styled.div`
  z-index: 2;
  position: relative;
`;

export const Workout = styled.div`
  border-radius: 5.41063px;
  padding: 27px 25px 13px;
  margin-bottom: 13px;
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 124px;

  .workout__title {
    font-weight: bold;
    font-size: 7px;
    line-height: 11px;
    color: #4fdfac;
    margin-bottom: 4px;
  }

  .workout__name {
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 14px;
  }
`;

export const TaskName = styled.div`
  flex: 1 1;
  overflow: hidden;
  margin: 0 20px 0 8px;
`;

export const TaskItem = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 8.657px;
  line-height: 13px;
  color: #1e0a3c;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    flex: 0 0 13px;
  }

  img {
    opacity: 0.5;
    width: 5px;
  }
`;

export const TaskDivide = styled.div`
  margin: 9px 0 9px 20px;
  height: 1px;
  background-color: #767676;
  opacity: 0.1;
`;

export const Task = styled.div`
  background: #ffffff;
  box-shadow: 0px 5.41063px 12.4444px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 11px 9px;

  .task__header {
    font-weight: bold;
    font-size: 8.657px;
    line-height: 12px;
    color: #1e0a3c;
    margin-bottom: 11px;
  }
`;

export const Content = styled.div`
  padding: 0 10px;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  .let-do-this {
    font-weight: bold;
    font-size: 8.657px;
    line-height: 12px;
    color: #1e0a3c;
    margin-bottom: 7px;
  }
  // img {
  //   margin-top: 14px;
  //   width: 100%;
  // }
  .note {
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    color: #5f5f5f;
  }
`;

export const SetTable = styled.table`
  background: #f7f8fc;
  border-radius: 4px;
  border-collapse: collapse;
  width: 100%;
  padding: 0px 8px;
  table-layout: fixed;
  tr {
    height: 22px;
  }
  color: #7b7e91;
  .table-header {
    font-weight: bold;
    font-size: 6px;
    line-height: 10px;
    letter-spacing: 0.1px;
    color: #7b7e91;
    th:first-child {
      padding-left: 8px;
      text-align: left;
    }
    tr {
      display: table-cell;
      width: 100%;
    }
  }
  .table-content {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 7px;
    line-height: 11px;

    td {
      text-align: center;
      position: relative;
    }
    td:first-child {
      padding-left: 8px;
      text-align: left;
    }
    td:last-child {
      text-align: right;
      padding-right: 8px;
    }
    td:first-child::after {
      content: '';
      background: #efefef;
      position: absolute;
      top: -2px;
      left: 18%;
      height: 1px;
      width: 364%;
    }
  }
  .done {
    color: #1e0a3c;
  }
  .active {
    color: ${props => props.primaryColor};
    td:first-child {
      color: #1e0a3c;
    }
    td:first-child::before {
      content: '';
      background: ${props =>
        props.primaryColor.toLowerCase() === '#7470ef' && props.secondaryColor.toLowerCase() === '#4fdfac'
          ? props.primaryColor
          : props.secondaryColor};
      position: absolute;
      top: initial;
      height: 22px;
      transform: translate(-9px, -7px);
      width: 1px;
    }
  }
`;

export const MarkAllText = styled.p`
  font-size: 7px;
  line-height: 10px;
  text-align: center;
  color: #7b7e91;
  margin: auto;
  margin-top: 9px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 19px 5px;
  width: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: #fff;

  .item {
    font-weight: bold;
    font-size: 5.95169px;
    line-height: 8px;
    color: #8f99a3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.active {
      color: #485263;
    }
  }
`;

export const NotificationIcon = styled.div`
  width: 11px;
  height: 13px;
  position: absolute;
  background: transparent url(${CDN_URL}/images/app_notification.svg) no-repeat center;
  background-size: contain;
  top: 28px;
  right: 14px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;

  .background {
    z-index: -1;
    background: ${props => props.primaryColor};
    position: absolute;
    width: 100%;
  }

  ${StartWorkout} {
    color: ${props => props.primaryColor};
  }

  ${Header} {
    position: absolute;
    top: 0px;
    z-index: -1;
    background-size: cover;
    height: 55px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .today {
      color: ${props => props.primaryColor};
    }
  }

  ${Workout} {
    background: transparent url(${props => props.workoutBackground}) no-repeat center;
    background-size: cover;
  }

  ${Task} {
    ${TaskItem} {
      svg.active-checkbox {
        path {
          fill: ${props => props.primaryColor};
        }
      }
    }
  }

  ${props =>
    props.useCustomTitle &&
    css`
      ${Workout} {
        .workout__title {
          color: #ffffff;
          opacity: 0.6;
        }
      }
    `}

  ${props =>
    props.customBackground &&
    css`
      ${Workout} {
        :before {
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          background-color: #131313;
          opacity: 0.2;
          position: absolute;
        }
      }
    `}

  ${props =>
    props.customTheme &&
    css`
      background-color: #f9f9f9;

      ${Task} {
        box-shadow: 0px 5px 23px rgba(192, 192, 192, 0.1);
        ${TaskItem} {
          svg.active-checkbox {
            path {
              fill: ${props => props.secondaryColor};
            }
          }
        }
      }
    `}

  ${Footer} {
    .item {
      &.active {
        color: ${props => props.primaryColor};

        path {
          fill: ${props => props.primaryColor};
        }
      }
    }
  }
`;

export const WrapperVideoPlayer = styled.div`
  position: relative;
  display: block;
  padding-top: 14px;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
