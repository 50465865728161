export const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY || '';

export const options = {
  lang: 'en',
  type: 'gifs',
  sort: 'relevant',
  limit: 9,
  rating: 'g',
};

export const emptyGifsResult = {
  data: [],
  pagination: { total_count: 0, count: 0, offset: 0 },
  meta: { status: 200, msg: 'OK', response_id: '' },
};
