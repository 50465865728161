import styled from 'styled-components';
import Select from 'react-select';
import { Popup } from 'semantic-ui-react';
import StatusLabel from 'components/Package/components/StatusLabel';

export const PackageSelectWrapper = styled(Select)`
  &.evfSelectBoxContainer {
    .evfSelectBox__menu {
      margin-top: 15px;
      z-index: 2;
    }
    .evfSelectBox__control.evfSelectBox__control--is-focused,
    .evfSelectBox__control.evfSelectBox__control----menu-is-open,
    .evfSelectBox__control {
      border: none !important;
      background: transparent;
      border-bottom: 1px solid #000 !important;
      border-radius: 0px;
      padding-left: 0px;
      margin-left: 10px;
      min-width: auto;
      max-width: 405px;
      height: 32px;
      .evfSelectBox__value-container {
        padding-left: 0px;
        padding-right: 0px;
        display: block;
        padding-top: 4px;
        input {
          position: absolute;
        }
      }
      .evfSelectBox__single-value {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #202353;
        display: contents;
      }
      .evfSelectBox__dropdown-indicator {
        padding-right: 0px;
      }
      .evfSelectBox__indicators {
        display: block;
        padding-top: 6px;
      }
    }
    .evfSelectBox__control.evfSelectBox__control--is-focused,
    .evfSelectBox__control.evfSelectBox__control----menu-is-open {
      .evfSelectBox__single-value {
        color: #5158cf;
      }
      .packageOption__name {
        color: #5158cf;
      }
    }
    .evfSelectBox__option.evfSelectBox__option--is-focused {
      .packageOption__name {
        color: #5158cf;
      }
    }
    .evfSelectBox__menu-list {
      padding: 12px 15px;
      .evfSelectBox__option {
        padding: 15px 0px;
        cursor: pointer;
        background-color: transparent;
        &:hover {
          background-color: transparent;
          .packageOption__name {
            color: #5158cf;
          }
        }
      }
    }
  }
`;

export const TotalPaid = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  padding-top: 12px;
`;

export const PackageSelectOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: inset 0px -1px 0px #ebedf4;
  .packageOption__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #202353;
    max-width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
  }
  .packageOption__purchasedAt {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: right;
    color: #202353;
    opacity: 0.4;
  }
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PackageSelectContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
`;

export const Content = styled.div`
  padding-top: 15px;
`;

export const StatusContent = styled(StatusLabel)`
  padding: 5px 8px;
  text-align: center;
  width: fit-content;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  button:hover {
    background-color: transparent;
  }
  button {
    margin-left: 8px;
    height: 35px;
  }
`;

export const InvoiceTableWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 3px rgba(76, 98, 135, 0.25);
  border-radius: 8px;
  padding: 0 15px 15px;
  max-height: 300px;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
  ::-webkit-scrollbar {
    width: 5px !important;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(128, 135, 139, 0.8) !important;
    }
  }
  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    position: relative;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    tr {
      th {
        background: #fff;
        padding: 15px 0 0 !important;
        div {
          background: #fafbfc;
          padding: 13px 15px;
        }
      }
      td {
        padding: 16px 15px;
        box-shadow: inset 0px -1px 0px #f0f0f0;
        &.limit {
          max-width: 182px;
        }
        .status {
          font-weight: 600;
          font-size: 11px;
          line-height: 15px;
          color: #ea314a;
          &.paid {
            color: #50c94d;
          }
        }
        &.amount {
          text-align: right;
          white-space: nowrap;

          .cost {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 7px;
              width: 100%;
              height: 0.75px;
              background: #202353;
            }
          }
          .cost-by-refunded {
            margin-left: 4px;
          }
        }
        &.dropdown {
          width: 25px;
          padding-right: 0;
          .evf-dropdown__trigger-container {
            > div {
              &:hover {
                background: rgba(21, 22, 25, 0.04);
              }
            }
            .dot {
              background-color: #4a4d56;
              &::before,
              &::after {
                background-color: #4a4d56;
              }
            }
          }
          .evf-dropdown__menu {
            padding: 0;
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 0px 16px rgba(0, 0, 0, 0.08),
              0px 24px 24px -8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            transform: translateX(4px) translateY(7px);
          }
          .evf-dropdown__option {
            padding: 0;
            min-width: 140px;
            min-height: 44px;
            .items {
              display: flex;
              width: 100%;
              color: #5e59ff;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              padding: 0 12px;
              svg {
                margin-right: 13px;
              }
            }
            &:hover {
              background: transparent;
              .items {
                padding: 8px 12px;
                background: rgba(94, 89, 255, 0.08);
              }
            }
          }
        }
      }
    }
    thead {
      background: #fafbfc;
      border-radius: 5px;
      th {
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: #202353;
        padding: 13px 15px;
        box-shadow: 0px 2px 0px #f1f3f4;
        position: sticky;
        top: 0;
        cursor: pointer;
        z-index: 1;

        &.amount {
          text-align: right;
        }
        &.minHeight {
          div {
            min-height: 40px;
          }
        }
      }
    }
    tbody {
      .cursor {
        cursor: pointer;
      }
    }
  }
`;

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const Tooltip = styled(Popup)`
  &.ui.inverted.popup {
    background: #2d2e2d;
    border-radius: 5px;
    z-index: 2000;
    &:after,
    &:before {
      background: #2d2e2d !important;
    }
    &:before {
      bottom: -5px !important;
    }
  }
`;

export const RefundCost = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 19.5px;
`;

export const RefundReason = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
`;
export const RefundDetails = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const TooltipContent = styled.div`
  width: 228px;
  padding: 15px;
  border-radius: 5px;
  ${RefundCost} {
    margin-bottom: 17px;
  }
  .highlight {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
`;
