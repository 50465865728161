import styled from 'styled-components';

export const StatusFilterWrapper = styled.div`
  position: relative;
  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .status-button {
      user-select: none;
      height: 36px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      font-size: 14px;
      line-height: 14px;
      color: #262626;
      background-color: #f5f5f5;
      padding: 0 12px;
      cursor: pointer;

      .bold {
        font-weight: 600;
      }
      &:hover {
        background-color: #f4f4fc;
        transition: all 0.3s;
      }
    }
  }

  .status-dropdown {
    position: absolute;
    margin-top: 8px;
    border: 1px solid #d9d9d9;
    background: #ffffff;
    border-radius: 4px;
    z-index: 1000;
    box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
    .status-item {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 150px;
      height: 35px;
      padding: 0 8px;
      cursor: pointer;
      label {
        font-size: 14px;
        line-height: 20px;
        color: #595959;
      }
      &:hover,
      &.active {
        background-color: #f4f4fc;
        color: #5158cf;
      }

      &.active {
        font-weight: 600;
      }
    }
  }
`;
