import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const Wrapper = styled.div`
  .end_date {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 10px;
  }
`;

const FORMAT_DATE = 'MMM D, YYYY';

const EndDateColumn = ({ item }) => {
  let { end_date, started_date, weeks, started_on } = item;
  if (!end_date) {
    end_date = moment(started_date, 'MM-DD-YYYY').add(weeks, 'weeks').subtract(started_on, 'days').format(FORMAT_DATE);
  }
  return (
    <Wrapper>
      <div className="end_date">{moment(end_date, 'MM-DD-YYYY').format(FORMAT_DATE)}</div>
    </Wrapper>
  );
};

export default EndDateColumn;
