import { get, forEach, countBy } from 'lodash';
import { UNCHECK_TAGS, HALF_CHECKED_TAGS, FULL_CHECKED_TAGS } from './constants';

/**
 * handleCountTagSelected: Handle count tag by selected
 * @param {*} id
 * @returns countItem
 */
export const countTagSelected = (id, countTagsSelected) => {
  const count = countTagsSelected;
  const countItem = get(count, `[${id}]`, false);

  return countItem;
};

/**
 * getSelectedTags: Handle get selected tags
 * @param {*} tags
 * @returns selectedTags
 */
export const getSelectedTags = tags => {
  let selectedTags = [];
  let allTags = [];

  forEach(tags, item => {
    allTags = allTags.concat(item.tags || []);
  });

  const countTags = countBy(allTags, '_id');

  forEach(countTags, (total, groupId) => {
    if (total === tags.length) {
      selectedTags.push(groupId);
    }
  });

  return selectedTags;
};

/**
 * selectTags : Handle Select Tags
 * @param {*} item: current item
 * @param {*} list: tag list
 * @param {*} checked: checked status
 * @param {*} countObj: current obj
 * @param {*} newSelected: new select tags
 * @param {*} newCount: count selected - tag total
 * @returns //{ newSelected: newSelected, newCount: newCount }
 */
export const selectTags = (item, list, checked, countObj, newSelected, newCount) => {
  if (countObj) {
    switch (countObj.state) {
      case FULL_CHECKED_TAGS:
        countObj.state = UNCHECK_TAGS;
        break;

      case HALF_CHECKED_TAGS:
        countObj.state = FULL_CHECKED_TAGS;
        break;

      default:
        if (countObj.total === list.length) {
          countObj.state = FULL_CHECKED_TAGS;
        } else {
          countObj.state = HALF_CHECKED_TAGS;
        }

        break;
    }

    newCount[item._id] = countObj;

    if (checked) {
      newSelected.push(item._id);
      if (countObj.state === UNCHECK_TAGS) countObj.state = countObj.originState;
      if (countObj.total === list.length) countObj.state = FULL_CHECKED_TAGS;
    } else {
      if (countObj.state === FULL_CHECKED_TAGS) countObj.state = countObj.originState;
      newSelected.splice(newSelected.indexOf(item._id), 1);
    }
  } else {
    // Tags not yet selected for any elements
    if (checked) {
      newSelected.push(item._id);
    } else {
      newSelected.splice(newSelected.indexOf(item._id), 1);
    }
  }

  return { newSelected: newSelected, newCount: newCount };
};
