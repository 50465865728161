import get from 'lodash/get';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { FORM_STATUS, QUESTION_TYPES } from 'constants/commonData';
import { Types } from './actions';
import { mongoObjectId, reorder } from 'utils/commonFunction';

export const INITIAL_STATE_FORM = {
  loading: false,
  workingForm: {
    name: 'Loading...',
  },
  questionTypes: [],
  questions: [],
  workingQuestion: null,
  isEditMode: false,
  isSubmitting: false,
  imageLibrary: {
    loading: false,
    list: [],
  },
  originalWorkingForm: null,
  draftForms: [],
  isUploading: false,
  bodyMetrics: [],
};

export default (state = INITIAL_STATE_FORM, action) => {
  const { type, payload } = action;
  let newQuestions = [];
  let clonedQuestions = [];
  switch (type) {
    case Types.GET_FORM_DETAIL_REQUEST:
    case Types.UPDATE_PARTIAL_QUESTION_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.GET_FORM_DETAIL_SUCCESS: {
      const {
        data: { questions },
        isKeepCurrentState,
      } = payload;
      let clonedWorkingForm = cloneDeep(state.workingForm);
      const workingQuestion = cloneDeep(state.workingQuestion);
      const currentQuestions = cloneDeep(state.questions);
      const indexOfQuestionInList = currentQuestions.findIndex(x => x._id === get(workingQuestion, '_id'));
      const existingForm = (state.draftForms || []).some(item => get(item, '_id', '') === payload.data._id);
      let updatedDraftForms = cloneDeep(state.draftForms || []);

      if (existingForm) {
        updatedDraftForms = updatedDraftForms.map(item =>
          get(item, '_id', '') === payload.data._id ? { ...item, ...payload.data } : item,
        );
      } else {
        if (payload.data.status === FORM_STATUS.DRAFT || payload.data.is_edit_mode) {
          updatedDraftForms.push(payload.data);
        }
      }
      return Object.assign({}, state, {
        loading: false,
        workingForm: merge(clonedWorkingForm, payload.data),
        originalWorkingForm: merge(clonedWorkingForm, payload.data),
        questions: questions,
        workingQuestion: isKeepCurrentState ? questions[indexOfQuestionInList] : questions[0] || null,
        draftForms: updatedDraftForms,
      });
    }

    case Types.UPDATE_WORKING_FORM_SUCCESS: {
      const { data, formId } = payload;
      let clonedWorkingForm = cloneDeep(state.workingForm);
      const draftForms = cloneDeep(state.draftForms);
      let newDraftForms = [];
      if (draftForms) {
        newDraftForms = draftForms.map(it => {
          if (it && it._id === formId) {
            it.name = get(data, 'name', '');
          }
          return it;
        });
      }
      return Object.assign({}, state, {
        workingForm: merge(clonedWorkingForm, payload.data),
        loading: false,
        draftForms: newDraftForms,
      });
    }

    case Types.UPDATE_WORKING_FORM_FAILED: {
      return Object.assign({}, state, { loading: false });
    }

    case Types.ADD_QUESTION_SUCCESS: {
      if (payload.type === QUESTION_TYPES.WELCOME_SCREEN) {
        newQuestions = [payload, ...state.questions];
      } else {
        newQuestions = [...state.questions];
        newQuestions.push(payload);
      }

      let updatedDraftForms = cloneDeep(state.draftForms);
      updatedDraftForms = updatedDraftForms.map(item =>
        get(item, '_id', '') === state.workingForm._id ? { ...item, questions: newQuestions } : item,
      );

      return Object.assign({}, state, {
        questions: newQuestions,
        workingQuestion: payload,
        draftForms: updatedDraftForms,
      });
    }

    case Types.REMOVE_QUESTION_SUCCESS: {
      clonedQuestions = [...state.questions];
      const deletedQuestion = clonedQuestions.find(item => get(item, '_id', '') === payload);
      const indexOfDeleted = clonedQuestions.indexOf(deletedQuestion);
      let updateWorkingQuestion = { ...state.workingQuestion };
      newQuestions = clonedQuestions.filter(item => get(item, '_id', '') !== payload);

      if (payload === updateWorkingQuestion._id) {
        if (indexOfDeleted === 0) {
          updateWorkingQuestion = newQuestions[0];
        } else {
          updateWorkingQuestion = newQuestions[indexOfDeleted - 1];
        }
      }

      let updatedDraftForms = cloneDeep(state.draftForms);
      updatedDraftForms = updatedDraftForms.map(item =>
        get(item, '_id', '') === state.workingForm._id ? { ...item, questions: newQuestions } : item,
      );

      return Object.assign({}, state, {
        questions: newQuestions,
        workingQuestion: newQuestions.length ? updateWorkingQuestion : null,
        draftForms: updatedDraftForms,
      });
    }

    case Types.SELECTED_QUESTION_SUCCESS:
      return Object.assign({}, state, { workingQuestion: payload });

    case Types.DUPLICATE_QUESTION_SUCCESS: {
      clonedQuestions = [...state.questions];
      const copyQuestion = clonedQuestions.find(item => item._id === payload._id);
      const indexOfCopy = clonedQuestions.indexOf(copyQuestion);

      const newOptions = get(payload, 'options', []).map(item => ({
        ...item,
        _id: mongoObjectId(),
      }));

      const newQuestion = {
        ...payload,
        _id: mongoObjectId(),
        options: get(payload, 'options') && newOptions,
        is_sync: (typeof payload.is_sync === 'undefined' || payload.is_sync) && false,
      };

      clonedQuestions.splice(indexOfCopy + 1, 0, newQuestion);
      let updatedDraftForms = cloneDeep(state.draftForms);
      updatedDraftForms = updatedDraftForms.map(item =>
        get(item, '_id', '') === state.workingForm._id ? { ...item, questions: clonedQuestions } : item,
      );

      return Object.assign({}, state, {
        questions: clonedQuestions,
        workingQuestion: newQuestion,
        draftForms: updatedDraftForms,
      });
    }

    case Types.REARRANGE_QUESTIONS_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.REARRANGE_QUESTIONS_SUCCESS:
      clonedQuestions = [...state.questions];
      clonedQuestions = reorder(clonedQuestions, payload.source, payload.destination);
      return Object.assign({}, state, {
        questions: clonedQuestions,
        loading: false,
        workingQuestion: clonedQuestions[payload.destination],
      });

    case Types.UPDATE_PARTIAL_QUESTION_SUCCESS: {
      clonedQuestions = [...state.questions];
      if (payload.type === QUESTION_TYPES.WELCOME_SCREEN) {
        const editedQuestion = clonedQuestions.find(item => get(item, '_id', '') === payload._id);
        newQuestions = clonedQuestions.filter(item => get(item, '_id', '') !== payload._id);
        newQuestions = [{ ...editedQuestion, ...payload }, ...newQuestions];
      } else {
        newQuestions = clonedQuestions.map(item =>
          get(item, '_id', '') === payload._id ? { ...item, ...payload } : item,
        );
      }
      switch (payload.type) {
        case QUESTION_TYPES.WELCOME_SCREEN:
          {
            const editedQuestion = clonedQuestions.find(item => get(item, '_id', '') === payload._id);
            newQuestions = clonedQuestions.filter(item => get(item, '_id', '') !== payload._id);
            newQuestions = [{ ...editedQuestion, ...payload }, ...newQuestions];
          }
          break;

        default:
          newQuestions = clonedQuestions.map(item =>
            get(item, '_id', '') === payload._id ? { ...item, ...payload } : item,
          );
          break;
      }

      let updatedDraftForms = cloneDeep(state.draftForms);
      updatedDraftForms = updatedDraftForms.map(item =>
        get(item, '_id', '') === state.workingForm._id ? { ...item, questions: newQuestions } : item,
      );

      return Object.assign({}, state, {
        questions: newQuestions,
        workingQuestion: { ...state.workingQuestion, ...payload },
        loading: false,
        draftForms: updatedDraftForms,
      });
    }

    case Types.GET_QUESTION_TYPES_SUCCESS:
      return Object.assign({}, state, {
        questionTypes: payload,
      });

    case Types.PUBLISH_FORM_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.PUBLISH_FORM_SUCCESS: {
      let clonedWorkingForm = cloneDeep(state.workingForm);

      return Object.assign({}, state, {
        originalWorkingForm: merge(clonedWorkingForm, payload.data),
        loading: false,
      });
    }

    case Types.PUBLISH_FORM_FAILED: {
      return Object.assign({}, state, { loading: false });
    }

    case Types.ENABLE_EDIT_FORM_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case Types.ENABLE_EDIT_FORM_SUCCESS: {
      let updatedDraftForms = cloneDeep(state.draftForms);
      updatedDraftForms = updatedDraftForms.filter(item => get(item, '_id', '') !== payload);

      return Object.assign({}, state, { draftForms: updatedDraftForms, loading: false });
    }

    case Types.ENABLE_EDIT_FORM_FAILED: {
      return Object.assign({}, state, { loading: false });
    }

    case Types.RESET_FORM_DETAIL:
      return Object.assign({}, state, { ...INITIAL_STATE_FORM, draftForms: state.draftForms });

    case Types.GET_BACKGROUND_LIBRARY_REQUEST:
      return Object.assign({}, state, { imageLibrary: { ...state.imageLibrary, loading: true } });

    case Types.GET_BACKGROUND_LIBRARY_SUCCESS:
      return Object.assign({}, state, { imageLibrary: { loading: false, list: payload.data } });

    case Types.SAVE_BACKGROUND_LIBRARY_SUCCESS:
      return Object.assign({}, state, {
        imageLibrary: { loading: false, list: [...state.imageLibrary.list, payload.data] },
      });

    case Types.REMOVE_IMAGE_REQUEST:
      return Object.assign({}, state, {
        imageLibrary: { ...state.imageLibrary, loading: true },
      });

    case Types.REMOVE_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        imageLibrary: { ...state.imageLibrary, loading: false },
      });

    case Types.DUPLICATE_FORM_QUESTIONS: {
      const updatedDraftForms = cloneDeep(state.draftForms);
      updatedDraftForms.push(payload);
      return Object.assign({}, state, { draftForms: updatedDraftForms });
    }

    case Types.UPDATE_NUMBER_OF_RESPONSIVE:
      const draftForms = state.draftForms.map(item => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            number_of_responses: action.payload.data,
          };
        }
        return item;
      });
      const workingForm = { ...state.workingForm, number_of_responses: action.payload.data };
      return Object.assign({}, state, { workingForm, draftForms });

    case Types.IS_UPLOADING_IMAGE: {
      return Object.assign({}, state, { isUploading: payload });
    }
    case Types.GET_DEFAULT_METRICS:
      const { data } = payload;
      return Object.assign({}, state, {
        bodyMetrics: data,
      });

    case Types.FROM_DETAIL_UPDATE_LIST_QUESTION_CONFIRMED: {
      const clonedWorkingForm = cloneDeep(state.workingForm);
      let listQuestionConfirmed = clonedWorkingForm.listQuestionConfirmed || [];

      if (!listQuestionConfirmed.includes(payload)) {
        listQuestionConfirmed.push(payload);
      }

      return Object.assign({}, state, {
        workingForm: merge(clonedWorkingForm, { listQuestionConfirmed }),
      });
    }

    default:
      return state;
  }
};
