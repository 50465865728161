/**
 * @flow
 */

export const EXERCISE_CONTAINER_HEIGHT = 60;
export const EXERCISE_CONTAINER_MARGIN = 2;

export const ASSIGNMENT_CONTAINER_HEIGHT = 57;
export const ASSIGNMENT_CONTAINER_MARGIN = 4;

export const ASSIGNMENT_COMPLETED_CONTAINER_HEIGHT = 74;
export const ASSIGNMENT_COMPLETED_CONTAINER_MARGIN = 4;

export default {
  calendarCell: {
    marginBottom: 4,
    borderRadius: 6,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    border: '1px solid transparent',
    background: '#f3f5f8',
    position: 'relative',
  },

  cellActive: {
    borderStyle: 'solid',
    borderColor: '#726EE4',
    borderWidth: '1px',
  },

  exercise: {
    display: 'flex',
    margin: EXERCISE_CONTAINER_MARGIN,
    backgroundColor: 'white',
    maxHeight: EXERCISE_CONTAINER_HEIGHT * 2,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    flexDirection: 'column',
    marginBottom: 6,
  },

  exerciseListContainer: {
    margin: '0px 4px 4px',
    padding: EXERCISE_CONTAINER_MARGIN,
    background: 'rgba(255, 255, 255, 0.8)',
  },

  activitiesListContainer: {
    margin: '0px 4px 4px',
    padding: '10px 5px 5px',
    background: 'rgba(255, 255, 255, 0.8)',
  },

  activitiesListHeader: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '11px',
    lineHeight: '15px',
    color: '#728096',
    marginLeft: '7px',
    marginBottom: '8px',
  },

  middleHover: {
    display: 'flex',
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },

  exercisesListHeader: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 4,
  },

  exercisesListFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '8px 2px 6px 2px',
  },
};
