import React from 'react';
import { remove } from 'lodash';
import styled from 'styled-components';
import { MESSAGE_TYPES } from 'components/InAppMessage/constants';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as PictureIcon } from 'assets/icons/picture.svg';
import { ReactComponent as MediaIcon } from 'assets/icons/media.svg';

export default props => {
  const { message } = props;
  const { messages } = message;

  if (!messages.length) {
    return null;
  }

  const images = messages.slice();
  const texts = remove(images, m => m.type === MESSAGE_TYPES.TEXT);
  const videos = remove(images, m => m.attachment_type && m.attachment_type.startsWith('video'));

  return (
    <Counter className="inAppMessage_counter">
      {texts.length ? (
        <div className="item">
          <MessageIcon />
          <span>{texts.length}</span>
        </div>
      ) : null}
      {images.length ? (
        <div className="item">
          <PictureIcon />
          <span>{images.length}</span>
        </div>
      ) : null}
      {videos.length ? (
        <div className="item">
          <MediaIcon />
          <span>{videos.length}</span>
        </div>
      ) : null}
    </Counter>
  );
};

const Counter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  padding: 0 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: #1f2023;

  .item {
    margin-right: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 5px;
    }

    :last-child {
      margin-right: 0;
    }
  }
`;
