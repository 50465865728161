import React, { useState } from 'react';

import * as S from './style';

const AddNewForm = ({
  onCreateNew,
  icon,
  position,
  text,
  heightButton,
  widthButton,
  buttonClassName,
  titlePopup,
  description,
  titleInput,
  placeholderInput,
  submitName,
  maxLength,
  onSubmit,
  fields,
  popupModal = null,
  children,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const handleCreateNew = () => {
    /* TODO */
    onCreateNew && onCreateNew();
    setOpen(true);
  };

  const handleClosePopup = val => {
    setOpen(val);
  };

  // TODO - TYPE MODE
  const PopupModal = popupModal;

  return (
    <>
      <S.Button
        text={text}
        icon={icon}
        position={position}
        height={heightButton}
        width={widthButton}
        className={buttonClassName}
        onClick={handleCreateNew}
        disabled={disabled}
      />

      <PopupModal
        open={open}
        titlePopup={titlePopup}
        description={description}
        titleInput={titleInput}
        placeholderInput={placeholderInput}
        submitName={submitName}
        maxLength={maxLength}
        onSetOpen={handleClosePopup}
        onSubmit={onSubmit}
        fields={fields}
        children={children}
      />
    </>
  );
};

export default AddNewForm;
