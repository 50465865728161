import React from 'react';
import styled from 'styled-components';

import ConfirmModal from 'shared/ConfirmModal';
import { CDN_URL } from 'constants/commonData';

const RemoveConfirmModal = props => {
  const { title, description, onConfirm } = props;

  const handleConfirm = () => {
    typeof onConfirm === 'function' && onConfirm();
  };

  return (
    <CustomModal
      isPressEsc
      noBorder
      hasCloseIcon
      hasHoverState
      headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
      title={title || 'Remove Note'}
      confirmButtonTitle="Remove"
      content={description || 'Are you sure that you want to remove this note?'}
      className="remove-note-modal"
      onConfirm={handleConfirm}
    />
  );
};

export default RemoveConfirmModal;

export const CustomModal = styled(ConfirmModal)`
  &.ui.modal.remove-note-modal {
    width: 467px !important;
    height: 180px !important;

    > :first-child {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    > :last-child {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    .close-button img {
      width: 20px !important;
      height: 20px !important;
    }

    .confirm-content-header {
      padding: 30px 30px 0 30px;
    }

    .confirm-header-image {
      width: 25px !important;
      height: 25px !important;
    }

    .confirm-header-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }

    .confirm-content-body {
      padding: 15px 30px 30px 30px !important;
    }

    .confirm-content-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: #323c47;
    }

    .confirm-no-button {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #818da1;

      min-width: unset !important;
      width: 102px !important;
      height: 30px !important;
    }

    .confirm-yes-button {
      box-shadow: unset !important;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;

      width: 111px !important;
      height: 30px !important;
      margin-left: 5px !important;
      border: unset !important;
      border-radius: 5px !important;
    }
  }
`;
