import React from 'react';
import { Modal } from 'semantic-ui-react';
import * as S from './style';
import { ANNOUNCEMENT_AUTO_MESSAGE, CDN_URL, IN_APP_AUTO_MESSAGE } from 'constants/commonData';

function CreateAutoflowMessage(props) {
  const onSelect = event => {
    const { type } = event.currentTarget.dataset;
    props.onSelect(type);
  };

  return (
    <S.ModalWrapper
      open={true}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      onClose={props.onCancel}
    >
      <Modal.Header>
        <h1>Schedule New Auto Message</h1>
        <h3>Please select your channel</h3>
      </Modal.Header>
      <Modal.Content>
        <S.Grid>
          <S.Item key={ANNOUNCEMENT_AUTO_MESSAGE} onClick={onSelect} data-type={ANNOUNCEMENT_AUTO_MESSAGE}>
            <div className="img-container">
              <img src={`${CDN_URL}/images/annoucement_logo.svg`} alt="" />
            </div>
            <div className="text">
              <div className="name">Announcement</div>
              <div>Broadcast a full-day announcement to clients</div>
            </div>
          </S.Item>
          <S.Item key={IN_APP_AUTO_MESSAGE} onClick={onSelect} data-type={IN_APP_AUTO_MESSAGE}>
            <div className="img-container">
              <img src={`${CDN_URL}/images/in-app_logo.svg`} alt="" />
            </div>
            <div className="text">
              <div className="name">In-app</div>
              <div>Engage your client through our in-app messaging</div>
            </div>
          </S.Item>
        </S.Grid>
      </Modal.Content>
    </S.ModalWrapper>
  );
}

export default CreateAutoflowMessage;
