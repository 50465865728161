import React from 'react';

import * as S from '../style';
import { useMyContext } from '../context/ProgramTemplatesContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close_program_filter.svg';

const InstructionModalContent = () => {
  const { toggleInstructionModal } = useMyContext();

  return (
    <S.FullscreenModalContent className="instruction">
      <S.FilterModalHeader>
        <S.FilterHeaderTitle>Instruction</S.FilterHeaderTitle>
        <S.CloseFilterButton onClick={toggleInstructionModal}>
          <CloseIcon />
        </S.CloseFilterButton>
      </S.FilterModalHeader>
      <S.InstructionWrapper>
        {/* TODO: Update when have freestyle popup for premade program */}
        <S.InstructionContent>{}</S.InstructionContent>
      </S.InstructionWrapper>
    </S.FullscreenModalContent>
  );
};

export default InstructionModalContent;
