import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import CreateWorkoutAIDemo from './component';
import {
  addWorkout,
  openWorkoutDetail,
  removeWorkout,
  updateWorkout,
} from 'redux/workout_library/workout.actionCreators';

const actionCreators = { toggleModal, addWorkout, updateWorkout, push, openWorkoutDetail, removeWorkout };

export default connect(null, actionCreators)(CreateWorkoutAIDemo);
