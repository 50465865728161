import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HomeComponent from './component';
import { toggleSideNav } from 'actions/sidenav';
import { toggleSideBar } from 'actions/sidebar';
import { toggleModal } from 'actions/modal';
import { getOnboardingCheckList } from 'redux/onboarding/actions';
import { checkDueTask } from 'redux/personal-task-reminder/action';
import { markSeenWaitingList } from 'redux/waiting-activation-clients/actions';
import { selectSegment, activeWaitingSegment } from 'redux/segment/actions';

import {
  getListGroup,
  selectGroup,
  resetLoadPostsQuery,
  markReadForumIndicator,
  resetChangeForum,
} from 'redux/forum/actions';
import { getCloudfrontList } from 'actions/cloudfrontList';

import { isTeamAdmin } from 'utils/commonFunction';

const mapStateToProps = state => {
  const {
    isModalOpen,
    sideNavVisible,
    sideBarVisible,
    modal,
    isSecondModalOpen,
    secondModal,
    errorPopup,
    isShowingError,
    isConfirmModalOpen,
    confirmModal,
    rootReducer,
    loadingBar,
    user: { is_hide_banner_product },
    rootReducer: { packageList, product, generalSettings, segment },
    user,
    router,
    cloudfrontList,
  } = state;

  const isPaymentAddOn = rootReducer.pricing
    .getIn(['addOnsPricing'])
    .toJS()
    .find(it => it.code === 'payment_package');
  const isEnableZapier = rootReducer.pricing
    .getIn(['addOnsPricing'])
    .toJS()
    .find(it => it.code === 'zapier');
  const { notification } = rootReducer;
  const publishPackageTotal = packageList.get('counter').toJS().publish ? packageList.get('counter').toJS().publish : 0;
  const draftPackageTotal = packageList.get('counter').toJS().draft ? packageList.get('counter').toJS().draft : 0;
  const publishMPPackageTotal = packageList.get('counterMP').toJS().publish
    ? packageList.get('counterMP').toJS().publish
    : 0;
  const draftMPPackageTotal = packageList.get('counterMP').toJS().draft ? packageList.get('counterMP').toJS().draft : 0;
  const { publish, draft } = product.get('counter').toJS();
  const publishProductTotal = publish ? publish : 0;
  const draftProductTotal = draft ? draft : 0;

  return {
    ...notification,
    cloudfrontList,
    isTeamAdmin: isTeamAdmin(user),
    isModalOpen,
    sideNavVisible,
    sideBarVisible,
    modal,
    isSecondModalOpen,
    secondModal,
    errorPopup,
    isShowingError,
    isConfirmModalOpen,
    confirmModal,
    selectedClient: rootReducer.client.workingClientDetail,
    isShowingSuccess: rootReducer.modal.isShowingSuccess,
    successPopup: rootReducer.modal.successPopup,
    userList: rootReducer.cms.user.items,
    totalUnread: rootReducer.inboxNew.totalUnread,
    filters: rootReducer.segment.filters,
    onboarding: rootReducer.onboarding,
    permission: rootReducer.permission,
    isPaymentAddOn,
    paymentOverdue: rootReducer.pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: rootReducer.pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: rootReducer.pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    forumList: rootReducer.forum.list,
    posts: rootReducer.forum.posts,
    currentSelectedForum: rootReducer.forum.group,
    isLoading: loadingBar.default,
    is_hide_banner_product,
    total: publishPackageTotal + draftPackageTotal,
    totalMP: publishMPPackageTotal + draftMPPackageTotal,
    totalProduct: publishProductTotal + draftProductTotal,
    user,
    isFormPermission: get(rootReducer, 'permission.form_questionnaire', false),
    isTaskLibraryPermission: get(rootReducer, 'permission.task_library', false),
    isHideReferralCoach: get(rootReducer, 'permission.is_hide_referral_coach', false),
    isHideFormBanner: get(generalSettings, 'is_hide_form_banner', false),
    isHideProgramLibraryBanner: get(generalSettings, 'is_hide_program_library_banner', false),
    isEnableZapier,
    segment,
    router,
    generalSettings,
    paymentWhiteLabelOverdue: rootReducer.pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getCloudfrontList: bindActionCreators(getCloudfrontList, dispatch),
    selectGroup: bindActionCreators(selectGroup, dispatch),
    checkDueTask: bindActionCreators(checkDueTask, dispatch),
    toggleSideNavigation: bindActionCreators(toggleSideNav, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getOnboardingCheckList: bindActionCreators(getOnboardingCheckList, dispatch),
    getListGroup: bindActionCreators(getListGroup, dispatch),
    resetLoadPostsQuery: bindActionCreators(resetLoadPostsQuery, dispatch),
    resetChangeForum: bindActionCreators(resetChangeForum, dispatch),
    markReadForumIndicator: bindActionCreators(markReadForumIndicator, dispatch),
    selectSegment: bindActionCreators(selectSegment, dispatch),
    markSeenWaitingList: bindActionCreators(markSeenWaitingList, dispatch),
    activeWaitingSegment: bindActionCreators(activeWaitingSegment, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
