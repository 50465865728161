import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { RootCloseWrapper } from 'react-overlays';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import NumberFormat from 'react-number-format';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_currentColor.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/check-mark-freestyle-section.svg';

// import DropdownOption from 'components/BodyMetricChartNew/components/DropdownOption';
import { Button, ErrorMessage } from 'shared/FormControl';
import DatePicker from 'shared/DatePicker';
import FitInchUnit from './FitInchUnit';

import {
  convertFtAndInchToFt,
  convertFtToFtAndInch,
  roundNumberFtnInch,
  convertFtAndInch,
  convertUnit,
  mongoObjectId,
  processMetricResultValue,
  roundNumber,
  roundNumberBodyMetric,
  convertMinToFromHourMin,
  getTimeFromString,
  formatInputTime,
} from 'utils/commonFunction';
import { SPECIAL_METRICS_CODE } from 'components/BodyMetricChartNew/constants';
import { CustomConfirmModal } from 'components/BodyMetricChartNew/components/ChartSettings/style';
import { CDN_URL, METRIC_UNIT_ERRORS } from 'constants/commonData';
import { toggleConfirmModal } from 'actions/modal';

import * as S from './style';

const TIME_FORMAT = 'hh : mm';

const getFormData = data => {
  const { value, unit, date } = data;
  const itemMoment = moment(date);
  const isFtUnit = unit.unique_code === 'ft';
  const { ft, inch } = isFtUnit ? convertFtAndInch(value, 2) : { ft: '', inch: '' };

  return {
    value: roundNumber(value, 2),
    isAM: itemMoment.format('A') === 'AM',
    time: itemMoment.format(TIME_FORMAT),
    date: itemMoment,
    unit,
    ft,
    inch: roundNumberFtnInch(inch, 2) || '',
  };
};

function AddResultPopup(props) {
  const {
    isOpen = false,
    onClose,
    metric,
    onSave,
    metricTypes,
    editData = {},
    style = {},
    toggleConfirmModal,
    isManualStep = false,
    newUI = false,
    trigger = undefined,
    onOpen = () => {},
    positionEdit = 'top right',
  } = props;
  const timeMetricInputRef = useRef();

  const [errors, setErrors] = useState({});
  const [isOpenDisCard, setIsOpenDisCard] = useState(false);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const deviceTz = moment.tz.guess();

  const isEditMode = useMemo(() => !isEmpty(editData), [editData]);
  const initAddData = useMemo(() => {
    const nowMoment = moment().tz(deviceTz);
    return {
      date: nowMoment,
      time: nowMoment.format(TIME_FORMAT),
      isAM: nowMoment.format('A') === 'AM',
      value: '',
      ft: '',
      inch: '',
      unit: metric.metricSettings.unit || { _id: metric.unitId, title: metric.unit },
    };
  }, []);

  const initFormData = useMemo(() => (isEditMode ? getFormData(editData) : initAddData), [
    editData,
    initAddData,
    isEditMode,
  ]);

  const [form, setForm] = useState(initFormData);

  const isNotChange = useMemo(
    () =>
      isEqual(
        {
          ...initFormData,
          value: initFormData.value !== '' ? Number(initFormData.value) : initFormData.value,
          unit: (initFormData.unit || {})._id,
        },
        {
          ...form,
          value: form.value !== '' ? Number(form.value) : form.value,
          unit: (form.unit || {})._id,
        },
      ),
    [initFormData, form],
  );

  const isFtUnit = form.unit.unique_code === 'ft';

  const unitOptions = useMemo(() => {
    const metricType = metricTypes.find(item => item.unique_code === metric.unique_code) || {};
    const { category: { units = [] } = {}, _id = 1 } = metricType;

    return units.map(item => {
      const title = (item || {}).unique_code === 'none' ? '-' : item.title;
      return {
        ...item,
        key: item._id,
        value: item._id,
        label: `${item.name} (${title})`,
        metricId: _id,
        shortLabel: title,
      };
    });
  }, [metric]);

  const isDisabledSubmit = useMemo(() => {
    if (errors.time || form.time === '') return true;
    if (isFtUnit) {
      if (errors.ft_inch || (form.ft !== '' && form.inch !== '' && +form.ft === 0 && +form.inch === 0)) return true;
      return isNotChange || (!form.ft && !form.inch) || false;
    }
    return !!errors.value || form.value === '' || form.value === 0 || isNotChange || false;
  }, [form, errors, isFtUnit, isNotChange]);

  const isTimeMetric = useMemo(() => {
    const { unit } = metric;
    return unit === 'min' || unit === 'sec';
  }, [metric]);

  const isHearRateMetric = useMemo(() => {
    const { unique_code } = metric;
    return unique_code === SPECIAL_METRICS_CODE.HEART_RATE;
  }, [metric]);

  useEffect(() => {
    if (isOpen && isTimeMetric && isEditMode) {
      const { hour = 0, min = 0 } = convertMinToFromHourMin(initFormData.value);
      setTimeout(() => {
        const defaultTimeValue = hour > 0 ? `${hour}:${min || '00'}` : `${min}`;
        if (timeMetricInputRef.current) {
          timeMetricInputRef.current.value = defaultTimeValue;
          handleChangeLabelTime(defaultTimeValue.replace(/\D/g, ''));
        }
      }, 0);
    }
  }, [isOpen, isEditMode, isTimeMetric]);

  const handleChangeDate = date => {
    const newForm = { ...form, date };
    setForm(newForm);
    validDateTime(newForm);
    setIsShowCalendar(false);
  };

  const handleChangeTime = e => {
    const newVal = e.target.value;
    let [hour, minute = 0] = newVal.split(' : ');
    if (Number(minute) === 0) {
      minute = '00';
    } else if (minute && minute.trim().length === 1) {
      minute = '0' + minute;
    }

    const timeStr = `${hour} : ${minute}`;
    const newTime = DateTime.fromFormat(timeStr, TIME_FORMAT);

    if (!newTime.invalid && errors.time) {
      setErrors({ ...errors, time: undefined });
    }

    setForm({ ...form, time: newVal });
  };

  const getDateRequest = data => {
    const { date, time, isAM } = data || form;
    const newTime = DateTime.fromFormat(time, TIME_FORMAT);

    return DateTime.local(
      date.year(),
      date.month() + 1,
      date.date(),
      newTime.hour === 12 ? 12 : newTime.hour + (isAM ? 0 : 12),
      newTime.minute,
    );
  };

  const checkDatetimeValid = () => {
    const dateRq = getDateRequest();

    if (moment(dateRq.ts).unix() > moment().unix()) {
      return false;
    }
    return true;
  };

  const handleBlurFtInInput = () => {
    const error = {
      ...errors,
      ft_inch: undefined,
    };

    if (!(+form.ft > 0 || +form.inch > 0) && (form.ft !== '' || form.inch !== '')) {
      error.ft_inch = METRIC_UNIT_ERRORS.VALUE;
    }

    setErrors(error);
  };

  const handleBlurTime = e => {
    let [hour, minute = 0] = e.target.value.split(' : ');

    if (Number(minute) === 0) {
      minute = '00';
    } else if (minute && minute.trim().length === 1) {
      minute = '0' + minute;
    }

    const timeStr = `${hour} : ${minute}`;
    const newTime = DateTime.fromFormat(timeStr, TIME_FORMAT);

    if (newTime.invalid) {
      setErrors({ ...errors, time: METRIC_UNIT_ERRORS.TIME });
      return;
    }

    let newForm = undefined;
    if (newTime.hour >= 13) {
      const hour = newTime.hour - 12;
      const newTimeStr = `${hour > 9 ? '' : '0'}${hour} : ${newTime.minute > 9 ? '' : '0'}${newTime.minute}`;
      newForm = {
        ...form,
        time: newTimeStr,
        isAM: false,
      };
      setForm(newForm);
    } else {
      newForm = {
        ...form,
        time: timeStr,
      };
      setForm(s => ({ ...s, time: timeStr, isAM: Number(hour) === 0 || s.isAM }));
    }
    validDateTime(newForm);
  };

  const validDateTime = newForm => {
    const dateRq = getDateRequest(newForm);
    const invalidTime = moment(dateRq.ts).unix() > moment().unix();
    setErrors({ ...errors, time: dateRq.invalid || invalidTime ? METRIC_UNIT_ERRORS.TIME : undefined });
  };

  const handleSelectAM = isAM => () => {
    setIsShowCalendar(false);
    const newForm = { ...form, isAM };
    setForm(newForm);
    validDateTime(newForm);
  };

  const handleBlurValue = event => {
    const { value } = event.target;

    if (value === '' && errors.value) {
      setErrors({ ...errors, value: undefined });
    }
    if (isNaN(value) || value === '') return;

    const validatedValue = processMetricResultValue(value, metric.unique_code);

    if (value !== '' && Number(validatedValue) <= 0) {
      setErrors({ ...errors, value: METRIC_UNIT_ERRORS.VALUE });
    }

    setForm({
      ...form,
      value: validatedValue,
    });
  };

  const handleChangeValue = ({ value }) => {
    if (isNaN(value)) return;
    const processedValue = processMetricResultValue(value, metric.unique_code);

    if (+processedValue > 0 && errors.value) {
      setErrors({ ...errors, value: undefined });
    }

    setForm({ ...form, value: processedValue });
  };

  const handleSelectUnit = unitId => () => {
    setOpenDropdown(false);

    if (form.unit._id !== unitId) {
      setErrors({ time: errors.time || undefined });
      const newUnit = unitOptions.find(item => item._id === unitId) || {};
      if (form.value === '') {
        setForm(prevState => ({ ...prevState, unit: newUnit, value: '', ft: '', inch: '' }));
        return;
      }
      let newValue = roundNumberBodyMetric(convertUnit(form.value, form.unit, newUnit), 2);
      if (form.unit.unique_code === 'ft') {
        newValue = convertFtAndInchToFt(form.ft, form.inch);
        newValue = roundNumberBodyMetric(convertUnit(newValue, form.unit, newUnit), 2);
      }

      if (newUnit.unique_code === 'ft') {
        const newFt = roundNumberBodyMetric(convertUnit(form.value, form.unit, newUnit), 2);
        const { ft, inch } = convertFtToFtAndInch(newFt);

        setForm(prevState => ({ ...prevState, unit: newUnit, value: newValue, ft, inch }));
      } else {
        setForm(prevState => ({ ...prevState, unit: newUnit, value: newValue, ft: '', inch: '' }));
      }
    }
  };

  const handleSave = async () => {
    setErrors({});
    onClose();
    const { time, value, ft, inch } = form;
    const newTime = DateTime.fromFormat(time, TIME_FORMAT);

    if (newTime.invalid || !checkDatetimeValid()) {
      setErrors({ ...errors, time: METRIC_UNIT_ERRORS.TIME });
      return;
    }

    let ftValue = 0;
    if (isFtUnit) {
      ftValue = convertFtAndInchToFt(ft, inch);
    } else if (value !== '' && Number(value) <= 0) {
      setErrors({ ...errors, value: METRIC_UNIT_ERRORS.VALUE });
      return;
    }

    const dateRq = getDateRequest();

    onSave({
      date: dateRq.toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      unique_code: metric.unique_code,
      value: isFtUnit ? ftValue : form.value,
      unit: form.unit,
    });
  };

  const handleClose = () => {
    if (isOpenDisCard) return;

    if (isShowCalendar) {
      setIsShowCalendar(false);
      return;
    }

    handleCheckValidate();

    if (!isNotChange) {
      return handleDiscardChange();
    }

    onClose();
  };

  const handleCheckValidate = () => {
    let checkErrors = {};
    const { time, value } = form;
    const newTime = DateTime.fromFormat(time, TIME_FORMAT);

    if (newTime.invalid || !checkDatetimeValid()) {
      checkErrors = { ...checkErrors, time: METRIC_UNIT_ERRORS.TIME };
    }

    if (value !== '' && Number(value) <= 0) {
      checkErrors = { ...checkErrors, value: METRIC_UNIT_ERRORS.VALUE };
    }

    if (!isEmpty(checkErrors)) {
      setErrors(checkErrors);
      return true;
    }
    return false;
  };

  const handleDiscardChange = () => {
    setIsOpenDisCard(true);
    toggleConfirmModal(
      true,
      <CustomConfirmModal
        noBorder
        title="Discard Changes?"
        content={`Are you sure you want to go? Changes have not been saved yet.`}
        onConfirm={handleConfirmDiscardChange}
        onDeny={handleDenyDiscardChange}
        onClose={handleDenyDiscardChange}
        confirmButtonTitle="Discard changes"
        hasCloseIcon
        headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        className="add-result-discard-change"
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  const handleConfirmDiscardChange = () => {
    onClose();
    setTimeout(() => {
      setIsOpenDisCard(false);
    }, 300);
  };

  const handleDenyDiscardChange = () => {
    setTimeout(() => {
      setIsOpenDisCard(false);
    }, 300);
  };

  const handleToggleCalendar = () => {
    if (!isShowCalendar) {
      const contentId = isEditMode ? `edit_result_wrapper_${editData._id}` : `add_result_wrapper`;
      const popupContentElement = document.getElementById(contentId);

      if (popupContentElement) {
        const dateInputElement = popupContentElement.querySelector('.date-input');
        const rdtPicker = popupContentElement.querySelector('.rdtPicker');

        if (dateInputElement && rdtPicker) {
          const dataInputRect = dateInputElement.getBoundingClientRect();

          if (dataInputRect.top < 330) {
            rdtPicker.style.top = '40px';
          }

          if (window.innerWidth - dataInputRect.right < 370 && isEditMode) {
            rdtPicker.style.transform = 'unset';
            rdtPicker.style.left = 'unset';
            rdtPicker.style.right = '0px';
          }
        }
      }
    }

    setIsShowCalendar(show => !show);
    setOpenDropdown(false);
  };

  const closeCalendar = () => {
    setIsShowCalendar(false);
  };

  const handleChangeFtInValue = (key, value) => {
    const error = {};

    const shouldDeleteError = secondary => form[secondary] > 0 || value > 0 || (form[secondary] === '' && value === '');

    if (shouldDeleteError('inch') || shouldDeleteError('ft')) {
      error.ft_inch = undefined;
    }

    setErrors(s => ({ ...s, ...error }));
    setForm(s => ({ ...s, [key]: value }));
  };

  const handleOpenDropdown = () => {
    closeCalendar();
    setOpenDropdown(true);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(false);
  };

  const handleChangeLabelTime = inputTime => {
    const { metricSettings: { unit = {} } = {} } = metric;
    const { title = '' } = unit;

    const value = `${inputTime}`;
    const labelUnit = document.getElementById('time-metric-unit');
    if (title === 'sec') {
      if (value.length >= 3) {
        labelUnit.innerText = 'min';
      } else if (value.length < 3 && labelUnit.innerText === 'min') {
        labelUnit.innerText = 'sec';
      }
    }

    if (title === 'min') {
      if (value.length >= 3) {
        labelUnit.innerText = 'h';
      } else if (value.length < 3 && labelUnit.innerText === 'h') {
        labelUnit.innerText = 'min';
      }
    }
  };

  const handleChangeTimeMetric = () => {
    if (!timeMetricInputRef.current) return;

    const value = timeMetricInputRef.current.value.replace(/\D/g, '');
    if (isEmpty(value)) {
      timeMetricInputRef.current.value = '';
      handleChangeValue({ value: '' });
      return;
    }

    const validValue = processMetricResultValue(Number(value), metric.unique_code);
    const format = formatInputTime(validValue);
    handleChangeLabelTime(validValue);

    const timeFormat = format || value;
    const timeValue = getTimeFromString(timeFormat);

    timeMetricInputRef.current.value = timeFormat;
    handleChangeValue({ value: timeValue });
  };

  const renderTimeMetricInput = () => {
    return (
      <input
        ref={timeMetricInputRef}
        type="text"
        autoFocus
        onChange={handleChangeTimeMetric}
        onBlur={handleBlurValue}
        onFocus={closeCalendar}
        className={classNames('metric-value-input time-metric', {
          'input-error': !!errors.value,
        })}
      />
    );
  };

  const renderNoOptionalMetricUnit = () => {
    const { metricSettings: { unit = {} } = {} } = metric;
    const { title = '' } = unit || {};

    return <S.TimeMetric id="time-metric-unit">{title}</S.TimeMetric>;
  };

  const renderContent = ({ newUI = false } = {}) => {
    let contentId = 'add_result_wrapper';
    if (isEditMode) {
      contentId = `edit_result_wrapper_${editData._id}`;
    }

    const activeUnit = unitOptions.find(item => item.value === form.unit._id);
    const activeUnitLabel = activeUnit ? activeUnit.shortLabel || activeUnit.label : '';

    return (
      <RootCloseWrapper event="click" disabled={isOpenDisCard || isOpenDropdown} onRootClose={handleClose}>
        <S.Wrapper
          className={classNames({
            'add-result-popup': !isEditMode,
            'edit-result-popup': isEditMode,
            'custom-height': !isEmpty(errors),
            default: !newUI,
          })}
          style={style}
          id={contentId}
          isHearRateMetric={isHearRateMetric}
        >
          <div className="content">
            <h3>{!isEmpty(editData) ? 'Edit' : 'Add'} Result</h3>
            <div className="datetime-wrapper">
              <DatePicker
                clientTimezone={deviceTz}
                value={form.date}
                format="MMM D, YYYY"
                onChange={handleChangeDate}
                className="date-input"
                isShowArrowBtn={false}
                closeOnClickOutside={true}
                isOpen={isShowCalendar}
                onToggleCalendar={handleToggleCalendar}
                maxDate={moment()}
                hideNextMonth={true}
              />
              <div className={classNames('time-input', { 'wrap-time-error': !!errors.time })}>
                <Cleave
                  placeholder="HH : MM"
                  value={form.time}
                  options={{ time: true, timePattern: ['h', 'm'], delimiter: ' : ' }}
                  onChange={handleChangeTime}
                  onBlur={handleBlurTime}
                  onFocus={closeCalendar}
                  className="time-cleave"
                />
                <span className={classNames('time-ap time-am', { selected: form.isAM })} onClick={handleSelectAM(true)}>
                  AM
                </span>
                <span
                  className={classNames('time-ap time-pm', { selected: !form.isAM })}
                  onClick={handleSelectAM(false)}
                >
                  PM
                </span>
              </div>
            </div>
            <div className="value-input">
              <p className={classNames({ 'label-error': !!errors.value || !!errors.ft_inch })}>{metric.name}</p>
              <div className="flex row">
                {isFtUnit ? (
                  <FitInchUnit
                    inchValue={form.inch}
                    fitValue={form.ft}
                    onFocus={closeCalendar}
                    onChange={handleChangeFtInValue}
                    onBlur={handleBlurFtInInput}
                    hasError={!!errors.ft_inch}
                    idMetricType={mongoObjectId()}
                  />
                ) : isTimeMetric ? (
                  renderTimeMetricInput()
                ) : (
                  <NumberFormat
                    onValueChange={handleChangeValue}
                    value={form.value}
                    autoFocus
                    onFocus={closeCalendar}
                    allowNegative={false}
                    decimalSeparator="."
                    // placeholder={`Add ${form.unit.title}`}
                    onBlur={handleBlurValue}
                    decimalScale={isManualStep || isHearRateMetric ? 0 : 2}
                    className={classNames('metric-value-input', {
                      'input-error': !!errors.value,
                      'time-metric': isTimeMetric,
                      'heart-rate-metric': isHearRateMetric,
                    })}
                  />
                )}
                {/* <DropdownOption
                  options={unitOptions}
                  onOpen={closeCalendar}
                  selected={form.unit._id}
                  className="select-unit-wrap"
                  onSelectFilter={handleSelectUnit}
                /> */}
                {isTimeMetric || isHearRateMetric ? (
                  renderNoOptionalMetricUnit()
                ) : (
                  <S.CustomDropdownPopup
                    trigger={
                      <S.TriggerDropdown
                        className={classNames({
                          active: isOpenDropdown,
                        })}
                      >
                        <span className="trigger-label">{activeUnitLabel}</span>
                        <ArrowDownIcon className="arrow-down-icon" />
                      </S.TriggerDropdown>
                    }
                    flowing={true}
                    hoverable={true}
                    on="click"
                    position="bottom left"
                    open={isOpenDropdown}
                    onOpen={handleOpenDropdown}
                    // onClose={handleCloseDropdown}
                    className="unit-select-popup"
                    keepInViewPort={true}
                    basic={true}
                  >
                    <RootCloseWrapper event="click" disabled={!isOpenDropdown} onRootClose={handleCloseDropdown}>
                      <>
                        {unitOptions.map(it => (
                          <S.OptionDropdown
                            key={it.value}
                            className={classNames({
                              active: form.unit._id === it.value,
                            })}
                            onClick={handleSelectUnit(it.value)}
                          >
                            <div className="item-label">{it.label}</div>
                            {form.unit._id === it.value && <CheckedIcon className="checked-icon" />}
                          </S.OptionDropdown>
                        ))}
                      </>
                    </RootCloseWrapper>
                  </S.CustomDropdownPopup>
                )}
              </div>
              {isFtUnit ? (
                <>
                  {!!errors.ft_inch && (
                    <div className="ft-in-error">
                      {!!errors.ft_inch && (
                        <ErrorMessage className="value-error in-error">{errors.ft_inch}</ErrorMessage>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>{!!errors.value && <ErrorMessage className="value-error">{errors.value}</ErrorMessage>}</>
              )}
            </div>
          </div>
          <div className="footer">
            <Button className="btn-cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button purple className="btn-save" onClick={handleSave} disabled={isDisabledSubmit}>
              Save
            </Button>
          </div>
        </S.Wrapper>
      </RootCloseWrapper>
    );
  };

  if (newUI && isEditMode && trigger) {
    return (
      <S.CustomPopup
        trigger={trigger}
        flowing={true}
        hoverable={true}
        on="click"
        position={positionEdit}
        open={isOpen}
        onOpen={onOpen}
        onClose={() => {}}
        className="edit-result-action-popup"
        keepInViewPort={true}
        basic={true}
      >
        {renderContent({ newUI: true })}
      </S.CustomPopup>
    );
  }

  if (newUI && !isEditMode && trigger) {
    return (
      <S.CustomPopup
        trigger={trigger}
        flowing={true}
        hoverable={true}
        on="click"
        position="top left"
        open={isOpen}
        onOpen={onOpen}
        onClose={() => {}}
        className="add-result-action-popup"
        keepInViewPort={true}
        basic={true}
      >
        {renderContent({ newUI: true })}
      </S.CustomPopup>
    );
  }

  return renderContent();
}

const mapStateToProps = state => {
  const { isConfirmModalOpen = false } = state;

  return {
    isConfirmModalOpen,
  };
};

const actionCreators = {
  toggleConfirmModal,
};

export default connect(mapStateToProps, actionCreators)(AddResultPopup);
