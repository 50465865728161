import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { toggleModal } from 'actions/modal';
import ClientProfileModal from 'components/ClientProfileModal';
import { CDN_URL } from 'constants/commonData';

import './style.scss';

function EmailMissingPopup(props) {
  const { client } = props;

  const onClose = () => {
    props.toggleModal(false);
  };

  const goToProfile = () => {
    const originClient = _.cloneDeep(client);
    const workingClient = _.cloneDeep(client);
    props.toggleModal(true, <ClientProfileModal originClient={originClient} workingClient={workingClient} />);
  };

  return (
    <Modal
      open={true}
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
      onClose={onClose}
      className="modal--email-missing"
    >
      <Modal.Content>
        <Wrapper>
          <ModalLayout.Header>
            <span>Email is missing</span>
          </ModalLayout.Header>
          <ModalLayout.Content>
            <div>Please have a valid email entered for your clients</div>
          </ModalLayout.Content>
          <ModalLayout.Actions>
            <Button onClick={goToProfile} className="button--go-to-profile">
              Go to Profile
            </Button>
            <Button purple onClick={onClose}>
              OK
            </Button>
          </ModalLayout.Actions>
        </Wrapper>
      </Modal.Content>
    </Modal>
  );
}

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(null, mapDispatch)(EmailMissingPopup);

const Wrapper = styled.div`
  ${ModalLayout.Header} {
    padding: 30px 30px 20px;
  }

  .button--go-to-profile {
    padding: 6px 29px;
  }
`;
