import React from 'react';

import { ReactComponent as RemoveWeek } from 'assets/icons/remove_week.svg';
import { ReactComponent as RemoveWorkout } from 'assets/icons/remove-workout.svg';
import { ReactComponent as RemoveEntire } from 'assets/icons/entire-remove.svg';

// styles
import * as S from './styles';

const RemoveWeekAction = props => {
  const { onRemoveAllWorkoutInWeek, onDeleteWeek, disabled = false } = props;

  return (
    <S.SidebarItemContainer disabled={disabled}>
      <RemoveWeek className="action-icon" />
      <div className="menu-item-dropdown-content">
        <ul className="list-dropdown">
          <li className="list-dropdown-item" onClick={onRemoveAllWorkoutInWeek}>
            <div>
              <RemoveWorkout className="remove-icon week-workout" />
            </div>
            <div className="remove-text">Remove workouts of the week</div>
          </li>
          <li className="list-dropdown-item entire" onClick={onDeleteWeek}>
            <div>
              <RemoveEntire className="remove-icon entire" />
            </div>
            <span className="remove-text">Remove entire week </span>
          </li>
        </ul>
      </div>
    </S.SidebarItemContainer>
  );
};
export default RemoveWeekAction;
