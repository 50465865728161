import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import { getGradient } from 'utils/commonFunction';

import * as S from './style';

const LineChart = memo(({ data, color, currency }) => {
  const [tooltip, setTooltip] = useState({
    top: 0,
    left: 0,
    date: '',
    value: 0,
  });

  const [isShow, setIsShow] = useState(false);
  const _containerRef = useRef();
  const _chartRef = useRef();
  const _toolTipRef = useRef();

  const [labels, revenues] = useMemo(() => {
    const labels = [],
      revenues = [];
    data.forEach(({ day, value }) => {
      labels.push(day);
      revenues.push(value);
    });
    return [labels, revenues];
  }, [data]);

  const values = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: '',
          data: revenues,
          borderColor: color,
          borderWidth: 2,
          backgroundColor: context => {
            const { ctx, chartArea } = context.chart;

            if (!chartArea) {
              return null;
            }
            return getGradient(ctx, color);
          },
          interaction: {
            intersect: false,
          },
          radius: 0,
        },
      ],
    }),
    [labels, revenues, color],
  );

  const handlePositionAndData = useCallback(
    (top, left, date, value) => {
      setIsShow(true);
      setTooltip({ top, left, date, value });
    },
    [setIsShow, setTooltip],
  );

  const tooltipCallBack = useCallback(
    tooltipModel => {
      if (tooltipModel) {
        let chart = _chartRef.current;
        if (!chart) {
          return;
        }

        if (!tooltipModel.opacity) {
          setIsShow(false);
          return;
        }

        const position = chart.chartInstance.canvas.getBoundingClientRect();
        const left = position.left + tooltipModel.caretX;
        const top = position.top + tooltipModel.caretY;

        // set values for display of data in the tooltip
        const { xLabel: date, yLabel: value } = tooltipModel.dataPoints[0];
        handlePositionAndData(top, left, date, value);
      }
    },
    [setIsShow, handlePositionAndData, _chartRef.current],
  );

  const lineOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      legend: { display: false },
      tooltips: {
        enabled: false,
        intersect: false,
        custom: tooltipCallBack,
      },
      scales: {
        yAxes: [{ display: false }],
        xAxes: [{ display: false }],
      },
    }),
    [tooltipCallBack],
  );

  useEffect(() => {
    if (_containerRef.current) {
      const observe = new ResizeObserver(() => {
        if (_chartRef.current) {
          _chartRef.current.chartInstance.resize();
        }
      });

      observe.observe(_containerRef.current);

      return () => {
        observe.disconnect();
      };
    }
  }, []);

  const tooltipWidth = _toolTipRef.current ? _toolTipRef.current.clientWidth : 1;

  const handleMoveOutChart = () => {
    setIsShow(false);
  };

  return (
    <div onMouseOut={handleMoveOutChart} ref={_containerRef}>
      <Line data={values} options={lineOptions} width={153} height={83} ref={_chartRef} />
      {isShow && (
        <>
          <S.TooltipWrapper style={{ top: tooltip.top - 63, left: tooltip.left - tooltipWidth / 2 }} ref={_toolTipRef}>
            <S.TooltipDate>{moment(tooltip.date).format('MMM DD, YYYY')} </S.TooltipDate> - {currency || `$`}
            <NumberFormat value={tooltip.value / 100} displayType={'text'} thousandSeparator={true} />
          </S.TooltipWrapper>
          <S.Arrow style={{ top: tooltip.top - 18, left: tooltip.left - 4 }} />
        </>
      )}
    </div>
  );
});

LineChart.displayName = 'LineChartMemo';

const LineChartWrap = props => {
  if (!props.data.length) {
    return (
      <S.LineEmpty>
        <span>No data available</span>
      </S.LineEmpty>
    );
  }

  return <LineChart {...props} />;
};

export default LineChartWrap;
