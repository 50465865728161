import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import { toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import { SharedTooltip } from 'shared/SharedTooltip';
import EditCountdownPopup from '../EditCountdownPopup';
import { RemoveConfirmModal } from '../ConfirmModal/style';
import { removeCountdown } from 'redux/client-countdown/action';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as PinnedIcon } from 'assets/icons/pin.svg';
// import { ReactComponent as SaveIcon } from 'assets/icons/save-template.svg';
// import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';

import * as S from './style';

const DropdownCountdownItem = props => {
  const {
    removeCountdown,
    toggleConfirmModal,
    countdown,
    toggleSecondModal,
    className = '',
    isDetail = false,
    onBackToList,
  } = props;

  const handlePinned = () => {};
  const handleSaveAsTemplate = () => {};
  const handleArchive = () => {};

  const handleEdit = () => {
    toggleSecondModal(true, <EditCountdownPopup countdownId={countdown._id} isEdit={true} />);
  };

  const handleRemoveCountdown = () => {
    isDetail && onBackToList && onBackToList();
    removeCountdown(countdown._id);
  };

  const handleRemove = () => {
    toggleConfirmModal(
      true,
      <RemoveConfirmModal
        onConfirm={handleRemoveCountdown}
        onDeny={() => toggleConfirmModal(false)}
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        confirmButtonTitle="Remove"
        title="Remove countdown"
        content="Are you sure you want to remove this countdown?"
        noBorder
        hasCloseIcon
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  return (
    <S.DropdownWrapper className={className} isDetail={isDetail}>
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="countdown-options-tooltip">
            <S.TriggerIcon className={classNames('trigger-icon', { active: !!open })} />
            {!open && <SharedTooltip id="countdown-options-tooltip" />}
          </div>
        )}
      >
        {/* {!isDetail && (
          <Option key="pin" onClick={handlePinned}>
            <PinnedIcon className="icon" />
            <span>Pin for Client</span>
          </Option>
        )} */}
        {/* <Option key="save" onClick={handleSaveAsTemplate}>
          <SaveIcon className="icon" />
          <span>Save as template</span>
        </Option> */}
        <Option key="edit" onClick={handleEdit}>
          <img className="icon edit" src={`${CDN_URL}/images/edit-white.svg`} alt="" />
          <span>Edit</span>
        </Option>
        {/* <Option key="archive" onClick={handleArchive}>
          <HistoryIcon className="icon" />
          <span className="text">Archive</span>
        </Option> */}
        <Option key="delete" onClick={handleRemove}>
          <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
          <span>Remove</span>
        </Option>
      </DropDown>
    </S.DropdownWrapper>
  );
};

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  removeCountdown: bindActionCreators(removeCountdown, dispatch),
});

export default connect(null, mapDispatch)(DropdownCountdownItem);
