import React from 'react';
import _ from 'lodash';
import DownloadCoachApp from 'components/DownloadCoachApp';
import { CDN_URL, DEMO_EMAIL, ONBOARDING_STEPS } from 'constants/commonData';

export const Steps = [
  {
    type: ONBOARDING_STEPS.ADD_CLIENT,
    name: 'Add your first client',
    description: 'To demonstrate how easy it is to begin, let’s add yourself as your first client.',
    actionText: 'Add yourself',
    action: function (props) {
      return (
        <button
          onClick={() => {
            props.toggleModal(false);
            props.addYourselfAsClient();
          }}
        >
          <span>Add yourself</span>
          <img src={`${CDN_URL}/images/arrow_right_purple.svg`} alt="" />
        </button>
      );
    },
  },
  {
    type: ONBOARDING_STEPS.ASSIGN_WORKOUT,
    name: 'Assign a workout',
    description:
      'Train clients with workouts built from our powerful workout builder. Create one brand new or choose a demo workout.',
    actionText: 'Assign workout',
    action: function (props) {
      return (
        <button
          onClick={() => {
            const { clientList } = props;

            if (!clientList.length) {
              return null;
            }

            const myClients = clientList.filter(c => !c.email.endsWith(DEMO_EMAIL));
            let client = clientList[0];

            if (myClients.length) {
              client = myClients[0];
            }

            props.toggleModal(false);
            props.runOnboarding(ONBOARDING_STEPS.ASSIGN_WORKOUT);
            props.minimizeCheckList();
            props.push(`/home/client/${client._id}/calendar`);
          }}
        >
          <span>Assign workout</span>
          <img src={`${CDN_URL}/images/arrow_right_purple.svg`} alt="" />
        </button>
      );
    },
  },
  {
    type: ONBOARDING_STEPS.ASSIGN_PROGRAM,
    name: 'Assign a program',
    description:
      'Programs help you save time. Create multi-day workout regimens and reuse them with one or many clients.',
    actionText: 'Assign program',
    action: function (props) {
      return (
        <button
          onClick={() => {
            const { clientList } = props;

            if (!clientList.length) {
              return null;
            }

            const myClients = clientList.filter(c => !c.email.endsWith(DEMO_EMAIL));
            let client = clientList[0];

            if (myClients.length) {
              client = myClients[0];
            }

            props.toggleModal(false);
            props.runOnboarding(ONBOARDING_STEPS.ASSIGN_PROGRAM);
            props.minimizeCheckList();
            props.push(`/home/client/${client._id}/calendar`);
          }}
        >
          <span>Assign program</span>
          <img src={`${CDN_URL}/images/arrow_right_purple.svg`} alt="" />
        </button>
      );
    },
  },
  {
    type: ONBOARDING_STEPS.CUSTOMIZE_METRIC,
    name: 'Customize metrics',
    description:
      'Metrics are key to measuring progress and defining success. Choose which metrics you want your client to track.',
    actionText: 'Select a new metric',
    action: function (props) {
      return (
        <button
          onClick={() => {
            const { clientList } = props;

            if (!clientList.length) {
              return null;
            }

            const myClients = clientList.filter(c => !c.email.endsWith(DEMO_EMAIL));
            let client = clientList[0];

            if (myClients.length) {
              client = myClients[0];
            }

            props.toggleModal(false);
            props.runOnboarding(ONBOARDING_STEPS.CUSTOMIZE_METRIC);
            props.minimizeCheckList();
            props.push(`/home/client/${client._id}/metrics`);
          }}
        >
          <span>Select a new metric</span>
          <img src={`${CDN_URL}/images/arrow_right_purple.svg`} alt="" />
        </button>
      );
    },
  },
  {
    type: ONBOARDING_STEPS.USE_COACH_APP,
    name: 'Download Coach app',
    description:
      'Train your clients, even from the beach. Message clients, assign and track workouts, receive updates, and more!',
    actionText: 'Download Coach app',
    action: function (props) {
      return (
        <button
          onClick={() => {
            props.runOnboarding(ONBOARDING_STEPS.USE_COACH_APP);
            props.minimizeCheckList();
            props.toggleModal(true, <DownloadCoachApp />);
          }}
        >
          <span>Download Coach app</span>
          <img src={`${CDN_URL}/images/arrow_right_purple.svg`} alt="" />
        </button>
      );
    },
  },
  {
    type: ONBOARDING_STEPS.ADD_CUSTOM_BRANDING,
    name: 'Add Custom Branding',
    description: 'Add your logo, color theme, workout background, and more!',
    actionText: 'Add your branding',
    action: function (props) {
      return (
        <button
          onClick={() => {
            props.toggleModal(false);
            props.runOnboarding(ONBOARDING_STEPS.ADD_CUSTOM_BRANDING);
            props.push(`/home/custom-branding`);
          }}
        >
          <span>Add your branding</span>
          <img src={`${CDN_URL}/images/arrow_right_purple.svg`} alt="" />
        </button>
      );
    },
  },
];
