// Lib
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';

// Shared
import TextEditable from 'shared/TextEditable';

// Constants
import { QUESTION_NAME_LIMIT_LENGTH } from 'constants/commonData';
import { UNIQUE_CODE_FT, UNIQUE_CODE_IN } from 'components/FormDetails/constants';
import { setCaretToEnd } from 'utils/commonFunction';

// Assets
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';

import * as S from './style';

export default function MetricAnswer({
  idx,
  loading,
  allowEdit,
  isSelected,
  onScrollTo,
  mouseLeaveRef,
  numberQuestion,
  workingQuestion,
  selectedQuestion,
  isDragging = false,
  updatePartialQuestion,
  bodyMetrics,
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const storedTitle = get(workingQuestion, 'title', '');
  const storedDescription = get(workingQuestion, 'description', '');
  const isRequire = get(workingQuestion, 'is_require', false);
  const questionNameRef = useRef();
  const descriptionRef = useRef();
  const isFocus = useRef(`name-${idx}`);

  const unitCoachSetting = (
    (bodyMetrics || []).find(
      ({ unique_code = '' }) => unique_code === get(workingQuestion, 'body_metric_unique_code'),
    ) || {}
  ).unit;

  const isMetricFt = [UNIQUE_CODE_FT, UNIQUE_CODE_IN].includes(
    get(unitCoachSetting, 'unique_code') || get(workingQuestion, 'unit.unique_code', ''),
  );

  useEffect(() => {
    setTitle(storedTitle);
    setDescription(storedDescription);
  }, [storedTitle, storedDescription]);

  useEffect(() => {
    if (isSelected) {
      onScrollTo();
      mouseLeaveRef.current = handleOnMouseOutParent;
    }
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && questionNameRef.current && isFocus.current === `name-${idx}`) {
      questionNameRef.current.focus();
      setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
    }
  }, []);

  const handleChangeQuestionName = value => {
    if (!isDragging) {
      const trimmedValue = value.trim();
      if (trimmedValue.length <= QUESTION_NAME_LIMIT_LENGTH) {
        setTitle(trimmedValue);
      } else {
        // handle copy paste case out of chars limit
        if (trimmedValue.length === QUESTION_NAME_LIMIT_LENGTH + 1) {
          setTitle(title.trim().slice(0, QUESTION_NAME_LIMIT_LENGTH));
          questionNameRef.current.focus();
          setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
        } else {
          setTitle(trimmedValue.slice(0, QUESTION_NAME_LIMIT_LENGTH));
        }
      }
    }
  };

  const handleChangeDescription = value => {
    const trimmedValue = value.trim();
    setDescription(trimmedValue);
  };

  const handleBlurQuestionName = () => {
    if (workingQuestion.title !== title && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        title,
      });
    }
  };

  const handleBlurDescription = () => {
    if (workingQuestion.description !== description && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        description,
      });
    }
  };

  const handleFocus = type => () => {
    isFocus.current = type;
  };

  const handleOnMouseOutParent = () => {
    if (questionNameRef.current) {
      const inputRef = get(questionNameRef, 'current.inputRef.current');
      questionNameRef.current.onBlur();
      inputRef && inputRef.blur();
    }
    if (descriptionRef.current) {
      const inputRef = get(descriptionRef, 'current.inputRef.current');
      descriptionRef.current.onBlur();
      inputRef && inputRef.blur();
    }
  };

  const handleOnClickQuestion = () => {
    allowEdit && selectedQuestion && selectedQuestion(workingQuestion);
  };

  return (
    <S.ContentContainer isRequire={isRequire} readOnly onClick={handleOnClickQuestion}>
      <S.IconWrapper>
        <QuestionIcon />
        <span>{numberQuestion}</span>
      </S.IconWrapper>
      <TextEditable
        ref={questionNameRef}
        className="title"
        value={title}
        placeholder="Name your question"
        lineHeight={28}
        onFocus={handleFocus(`name-${idx}`)}
        onBlur={handleBlurQuestionName}
        onChange={handleChangeQuestionName}
        maxLength={QUESTION_NAME_LIMIT_LENGTH}
        notRequired
        readOnly={!allowEdit}
      />
      <TextEditable
        ref={descriptionRef}
        className="description"
        value={description}
        placeholder="Description (optional)"
        lineHeight={22}
        onFocus={handleFocus('desc')}
        onBlur={handleBlurDescription}
        onChange={handleChangeDescription}
        notRequired
        breakLine
        readOnly={!allowEdit}
      />
      <S.ReadOnlyWrapper>
        {isMetricFt ? (
          <S.MetricAnswerWrapper>
            <S.AnswerWrapper readOnly>
              <span className="dynamic-unit">ft</span>
            </S.AnswerWrapper>
            <S.AnswerWrapper readOnly>
              <span className="dynamic-unit">in</span>
            </S.AnswerWrapper>
          </S.MetricAnswerWrapper>
        ) : (
          <S.AnswerWrapper readOnly>
            <span className="dynamic-unit">{get(unitCoachSetting, 'title') || get(workingQuestion, 'unit.title')}</span>
          </S.AnswerWrapper>
        )}
      </S.ReadOnlyWrapper>
    </S.ContentContainer>
  );
}
