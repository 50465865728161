import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { get } from 'lodash';

import TrashIcon from 'assets/icons/red_trash_circle.svg';
import LoadingIndicator from 'shared/LoadingIndicator';
import { ReactComponent as NoAssetsIcons } from 'assets/icons/no-asset-icons.svg';

import * as S from './style';
import AssetSelect from '../AssetSelect';
import SelectedAsset from './SelectedAssets/index.js';

const Asset = ({
  isEdit = true,
  toggleConfirmModal,
  onChangeAsset,
  data,
  isSubmitting = true,
  user,
  cloudfrontList,
}) => {
  const [assets, setAssets] = useState(get(data, 'assets', []));
  const isFirstTime = assets.length === 0;
  const [isAdding, setIsAdding] = useState(isFirstTime);

  useEffect(() => {
    setAssets(data.assets);
    setIsAdding(data.assets.length === 0);
  }, [data]);

  const handleAddAsset = newAsset => {
    const currentAssets = [...assets, newAsset];
    setAssets(currentAssets);
    setIsAdding(false);
    onChangeAsset && onChangeAsset(currentAssets);
  };

  const handleDeleteAsset = asset => {
    const deleteAsset = () => {
      const newAssets = assets.filter(el => el !== asset);
      setAssets(newAssets);
      onChangeAsset && onChangeAsset(newAssets);
    };
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="delete-asset-modal"
        noBorder
        headerIcon={TrashIcon}
        hasCloseIcon
        hasHoverState
        title={'Remove Asset'}
        content={'Would you like to remove the asset from this sequence?'}
        onConfirm={() => deleteAsset()}
        onDeny={() => {}}
        confirmButtonTitle="Remove"
      />,
    );
  };

  const handleToggleAdding = () => {
    setIsAdding(!isAdding);
  };

  if (isSubmitting) {
    return (
      <S.Wrapper>
        <LoadingIndicator />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <S.AssetHeader>Add Asset</S.AssetHeader>
      {isEdit ? (
        <S.AssetContainer>
          <S.StartWeek>Week 1</S.StartWeek>
          <S.TimeLineWrapper>
            {assets.map(asset => (
              <S.TimeLine isFirstTime={isFirstTime} key={asset.asset_id}>
                <S.DateTime>Day 1</S.DateTime>
                <S.AssetDetailContainer>
                  <SelectedAsset
                    data={asset}
                    onDeleteAsset={handleDeleteAsset}
                    isEdit={isEdit}
                    cloudfrontList={cloudfrontList}
                  />
                </S.AssetDetailContainer>
              </S.TimeLine>
            ))}
            {!isAdding && !isFirstTime ? (
              <S.TimeLine>
                <ReactTooltip id="add-more-tooltip" effect="solid" place={'left'} delayShow={500} />
                <S.ToggleAddAsset
                  onClick={handleToggleAdding}
                  data-tip="Add New Asset"
                  data-for="add-more-tooltip"
                ></S.ToggleAddAsset>
              </S.TimeLine>
            ) : (
              <S.TimeLine isFirstTime={isFirstTime}>
                <S.DateTime>Day 1</S.DateTime>
                <S.AssetDetailContainer>
                  <AssetSelect
                    user={user}
                    addedAssets={assets}
                    isAdding={isAdding}
                    onToggleAdding={handleToggleAdding}
                    onAddAsset={handleAddAsset}
                    isFirstTime={isFirstTime}
                  />
                </S.AssetDetailContainer>
              </S.TimeLine>
            )}
          </S.TimeLineWrapper>
        </S.AssetContainer>
      ) : (
        <S.AssetContainer>
          {assets.length === 0 && (
            <S.NoAssetsContainer>
              {/* <img src={NoAssetsIcons} alt="No assets selected" /> */}
              <NoAssetsIcons />
              <p>No Assets</p>
            </S.NoAssetsContainer>
          )}
          {assets.length > 0 && <S.StartWeek>Week 1</S.StartWeek>}
          <S.TimeLineWrapper>
            {assets.map(asset => (
              <S.TimeLine isFirstTime={isFirstTime} key={asset.asset_id}>
                <S.DateTime>Day 1</S.DateTime>
                <S.AssetDetailContainer>
                  <SelectedAsset data={asset} isEdit={isEdit} cloudfrontList={cloudfrontList} />
                </S.AssetDetailContainer>
              </S.TimeLine>
            ))}
          </S.TimeLineWrapper>
        </S.AssetContainer>
      )}
    </S.Wrapper>
  );
};

export default Asset;
