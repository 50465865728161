import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import { togglePaymentInformation } from 'redux/billing/actions';
import { updateSelectPackage } from 'redux/pricing/actions';
import { toggleModal } from 'actions/modal';

import { goToBilling } from 'helpers/upgradePath';
import { isTeamAdmin } from 'utils/commonFunction';
import { PLANS, TRIAL_MODE } from 'constants/commonData';
import { getLowestPackagePrice } from 'components/PricingPlan/helper';

import UpgradePathIcon from 'shared/UpgradePathIcon';

function ImageGotoBilling(props) {
  const {
    currentPackage,
    teamData,
    planWillSelect,
    planPricing,
    paymentAddOn,
    invoiceOverDue,
    togglePaymentInformation,
    user,
    push,
    updateSelectPackage,
    toggleModal,
    pathname = null,
    isNotUsePathName = false,
    notSendPrev,
    tooltip,
    imageSrc,
    imageTitle,
    imageBackgroundColor,
    isImageBackground,
    tooltipBackground,
    tooltipTextColor,
    tooltipId,
    maxWidthContent,
    buttonProps,
  } = props;

  const handleCloseUpgradeModal = () => {
    toggleModal(false);
  };

  const onImageEvent = () => {
    let pathName = pathname;
    const isAdmin = isTeamAdmin(user);
    const isTrialOrStarter =
      teamData.get('tier') === PLANS.starter.key || teamData.get('trialMode') !== TRIAL_MODE.Expired;
    const defaultAddOns = {
      automation: false,
      payment_package: false,
      resource_collections: false,
      premiumCustomBrand: false,
      zapier: false,
    };

    pathName = pathname ? pathname : window.location.pathname;

    goToBilling(
      isAdmin,
      pathName,
      isTrialOrStarter,
      defaultAddOns,
      invoiceOverDue,
      handleCloseUpgradeModal,
      togglePaymentInformation,
      notSendPrev,
      push,
      updateSelectPackage,
      currentPackage,
      toggleModal,
      paymentAddOn,
      planWillSelect,
      teamData,
      getLowestPackagePrice,
      planPricing,
      isNotUsePathName,
      props,
    );
  };

  return (
    <UpgradePathIcon
      tooltip={tooltip}
      imageSrc={imageSrc}
      imageTitle={imageTitle}
      imageBackgroundColor={imageBackgroundColor}
      isImageBackground={isImageBackground}
      onImageEvent={onImageEvent}
      tooltipBackground={tooltipBackground}
      tooltipTextColor={tooltipTextColor}
      tooltipId={tooltipId}
      maxWidthContent={maxWidthContent}
      buttonProps={buttonProps}
    />
  );
}

const mapStateToProps = state => {
  const { user, rootReducer } = state;

  return {
    user,
    currentPackage: rootReducer.pricing.getIn(['selectPackage']),
    planPricing: rootReducer.pricing.getIn(['planPricing']),
    teamData: rootReducer.pricing.getIn(['teamData']),
    paymentAddOn: rootReducer.pricing.getIn(['addOnsPricing']),
    invoiceOverDue: rootReducer.billing.invoiceOverDue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    push: bindActionCreators(push, dispatch),
    updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    togglePaymentInformation: bindActionCreators(togglePaymentInformation, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageGotoBilling);
