import React from 'react';
import { Mixpanel } from 'utils/mixplanel';
import _, { map } from 'lodash';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { connect } from 'react-redux';

import UpgradePath from 'shared/UpgradePath';
import { ReactComponent as UploadTooltipIcon } from 'assets/icons/splash-screen-upload-tooltip.svg';
import Upload from '../../Upload';
import * as S from './style';
import { THEMES } from '../../constants';
import './style.scss';

const DEFAULT_STATE = {
  splashLogo: { name: '', src: '' },
  splashBackground: { name: '', src: '' },
  theme: { ...THEMES[0] },
};

const WELCOME_TEXT_COLORS = [
  {
    name: 'Black',
    value: '#252020',
  },
  {
    name: 'White',
    value: '#ffffff',
  },
  {
    name: 'Hide Text',
    value: 'none',
  },
];

class CustomSplash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_STATE,
      isSaving: false,
      selectedWelcomeTextColor: props.originalWelcomeTextColor,
    };
  }

  componentDidMount() {
    this.setState({
      splashLogo: this.props.splashLogo,
      splashBackground: this.props.splashBackground,
      selectedWelcomeTextColor: this.props.welcomeTextColor,
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      const {
        discard,
        onCheckSaving,
        onChangeDiscard,
        setCurrentIndex,
        setScreenActive,
        splashLogo,
        splashBackground,
        activeIndex,
        welcomeTextColor,
        setSplashLogo,
        setSplashBackground,
        setWelcomeTextColor,
      } = this.props;

      if (!_.isEqual(splashLogo, this.state.splashLogo)) {
        this.setState({
          splashLogo: splashLogo,
        });
      }

      if (!_.isEqual(splashBackground, this.state.splashBackground)) {
        this.setState({
          splashBackground: splashBackground,
        });
      }

      if (!_.isEqual(welcomeTextColor, this.state.selectedWelcomeTextColor)) {
        this.setState({
          selectedWelcomeTextColor: welcomeTextColor,
        });
      }

      if (discard) {
        this.setState({
          splashLogo: this.props.originSelectedSplashLogo,
          splashBackground: this.props.originSelectedSplashBackground,
          selectedWelcomeTextColor: this.props.originalWelcomeTextColor,
        });
        setSplashLogo(this.props.originSelectedSplashLogo);
        setSplashBackground(this.props.originSelectedSplashBackground);
        setWelcomeTextColor(this.props.originalWelcomeTextColor);
        onCheckSaving(false);
        onChangeDiscard(false);
        setCurrentIndex(activeIndex);
        setScreenActive(activeIndex);
      }
    }
  }

  onRemoveSplashLogo = () => {
    this.setState({ splashLogo: { ...DEFAULT_STATE.splashLogo } }, () => {
      this.props.setSplashLogo(DEFAULT_STATE.splashLogo);
      this.props.onCheckSaving(true);
    });
  };

  onRemoveSplashBackground = () => {
    const { setSplashBackground, setWelcomeTextColor } = this.props;

    this.setState(
      {
        splashBackground: {
          ...DEFAULT_STATE.splashBackground,
          selectedWelcomeTextColor: this.props.originalWelcomeTextColor,
        },
      },
      () => {
        setSplashBackground(DEFAULT_STATE.splashBackground);
        setWelcomeTextColor(this.props.originalWelcomeTextColor);
        this.props.onCheckSaving(true);
      },
    );
  };

  onUploadLogoSuccess = ({ src, name, bucketData }) => {
    const { setSplashLogo } = this.props;
    this.setState({ splashLogo: { ...this.state.splashLogo, name, src, bucketData } }, () => {
      setSplashLogo({ ...this.state.splashLogo, name, src, bucketData });
      this.props.onCheckSaving(true);
    });
  };

  onUploadBackgroundSuccess = ({ src, name, bucketData }) => {
    const { setSplashBackground, setWelcomeTextColor } = this.props;
    setWelcomeTextColor(WELCOME_TEXT_COLORS[0].value);
    this.setState({ selectedWelcomeTextColor: WELCOME_TEXT_COLORS[0].value });
    this.setState({ splashBackground: { ...this.state.splashBackground, name, src, bucketData } }, () => {
      setSplashBackground({ ...this.state.splashBackground, name, src, bucketData });
      this.props.onCheckSaving(true);
    });
  };

  onSave = () => {
    const { theme, appIcon, appIconReview, appIconUrl, selectedTodayScreenLogo } = this.props;
    const { splashLogo, splashBackground, selectedWelcomeTextColor } = this.state;
    let data = {
      primary_color: theme.primaryColor,
      secondary_color: theme.secondaryColor,
      splash_logo: splashLogo.bucketData ? splashLogo.bucketData.url : splashLogo.src,
      splash_logo_name: splashLogo.name,
      splash_background: splashBackground.bucketData ? splashBackground.bucketData.url : splashBackground.src,
      splash_background_name: splashBackground.name,
      splash_screen_welcome_text_color: selectedWelcomeTextColor,
      app_icon_key: appIcon,
      today_screen_logo: selectedTodayScreenLogo.src || '',
      today_screen_file_name: selectedTodayScreenLogo.name || '',
    };
    if (appIconUrl) {
      data = { ...data, app_icon_url: appIconUrl };
    }
    if (appIconReview) {
      data = { ...data, app_icon_review: appIconReview };
    }
    const localData = {
      selectedSplashLogo: splashLogo,
      selectedSplashBackground: splashBackground,
      splash_screen_welcome_text_color: selectedWelcomeTextColor,
    };
    this.props.updateCustomBrandingPremium(data, localData).finally(() => {
      Mixpanel.track('advance_custom_brand_set_splash_screen');
      this.props.onCheckSaving(false);
    });
  };

  renderSaveButton = () => {
    return (
      <S.SaveButton
        className={classNames({
          save: true,
          disable: !this.props.saving,
        })}
        onClick={this.onSave}
      >
        Save
      </S.SaveButton>
    );
  };

  handleSelectWelcomeColor = value => {
    this.setState({
      selectedWelcomeTextColor: value,
    });
    this.props.setWelcomeTextColor(value);
    if (!_.isEqual(value, this.props.originalWelcomeTextColor)) {
      this.props.onCheckSaving(true);
    } else {
      if (
        _.isEqual(this.state.splashLogo, this.props.originSelectedSplashLogo) &&
        _.isEqual(this.state.splashBackground, this.props.originSelectedSplashBackground)
      ) {
        this.props.onCheckSaving(false);
      }
    }
  };

  render() {
    const { splashLogo, splashBackground, selectedWelcomeTextColor } = this.state;
    const { originalWelcomeTextColor, cloudfrontList } = this.props;

    const ThemeBall = ({ item, index, onClick }) => {
      const { selectedWelcomeTextColor } = this.state;
      return (
        <>
          <S.ThemeItem
            index={index}
            active={selectedWelcomeTextColor === item.value}
            onClick={() => onClick(item.value)}
            data-tip={item.name}
            data-for={`color-ball-${item.name}`}
          >
            <S.ThemeContent {...item} />
          </S.ThemeItem>
          <ReactTooltip
            effect="solid"
            id={`color-ball-${item.name}`}
            className="color-name-tooltip"
            arrowColor="#2d2e2d"
          />
        </>
      );
    };
    return (
      <div>
        <UpgradePath pathName="branding">
          <S.FormGroup>
            <div style={{ display: 'flex' }}>
              <S.DragboxItem>
                <label>
                  Splash Screen Logo{' '}
                  <UploadTooltipIcon
                    data-for="upload-info"
                    data-tip="Png or jpg files. Resolution should be at least 100px by 100 px."
                  />
                </label>
                <ReactTooltip effect="solid" id="upload-info" className="upload-tooltip-info" arrowColor="#2d2e2d" />
                <S.DragboxElement>
                  <Upload
                    key={splashLogo.src || 'splashLogo'}
                    onCancelUpload={this.onRemoveSplashLogo}
                    onUploadSuccess={this.onUploadLogoSuccess}
                    data={splashLogo}
                    cloudfrontList={cloudfrontList}
                    verifyResolution={{
                      width: 100,
                      height: 100,
                      message: 'Resolution should be at least 100px by 100px.',
                      messageSize: 'File too large. Please upload a smaller file.',
                    }}
                    maxSize={25}
                    source="Splash Screen Logo"
                  />

                  {/* <S.Note>
                  <S.Tip>
                    Png or jpg files. Resolution should be at least <span className="hightlight">100px by 100px.</span>
                  </S.Tip>
                </S.Note> */}
                </S.DragboxElement>
              </S.DragboxItem>
              {splashBackground.src && (
                <S.WelcomeColorPickerContainer noMarginTop withLeftMargin hiddenWhenLargeScreen>
                  <label>Welcome text color</label>
                  {map(WELCOME_TEXT_COLORS, (item, index) => (
                    <ThemeBall
                      index={index}
                      key={item.value}
                      item={item}
                      selectedWelcomeTextColor={originalWelcomeTextColor || selectedWelcomeTextColor}
                      onClick={this.handleSelectWelcomeColor}
                    />
                  ))}
                </S.WelcomeColorPickerContainer>
              )}
            </div>
            <S.DragboxItem withLeftMargin withMarginTopSmallScreen>
              <label>Splash Screen Background</label>

              <S.DragboxElement>
                <Upload
                  key={splashBackground.src || 'splashBackground'}
                  onCancelUpload={this.onRemoveSplashBackground}
                  onUploadSuccess={this.onUploadBackgroundSuccess}
                  cloudfrontList={cloudfrontList}
                  data={splashBackground}
                  verifyResolution={{
                    messageSize: 'File too large. Please upload a smaller file.',
                  }}
                  maxSize={25}
                  source="Splash Screen Background"
                />
              </S.DragboxElement>
            </S.DragboxItem>
            {splashBackground.src && (
              <S.WelcomeColorPickerContainer hiddenWhenSmallScreen>
                <label>Welcome text color</label>
                {map(WELCOME_TEXT_COLORS, (item, index) => (
                  <ThemeBall
                    index={index}
                    key={item.value}
                    item={item}
                    selectedWelcomeTextColor={selectedWelcomeTextColor}
                    onClick={this.handleSelectWelcomeColor}
                  />
                ))}
              </S.WelcomeColorPickerContainer>
            )}
          </S.FormGroup>

          {this.renderSaveButton()}
        </UpgradePath>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    cloudfrontList,
    rootReducer: {
      customBranding: { splash_screen_welcome_text_color, selectedSplashLogo, selectedSplashBackground },
    },
  } = state;

  return {
    cloudfrontList,
    originalWelcomeTextColor: splash_screen_welcome_text_color,
    originSelectedSplashLogo: selectedSplashLogo,
    originSelectedSplashBackground: selectedSplashBackground,
  };
};

export default connect(mapStateToProps)(CustomSplash);
