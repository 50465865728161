import React from 'react';
import { Loader } from 'semantic-ui-react';

import * as S from 'shared/PreviewLink/styles';

function PreviewLoader(props) {
  const {} = props;

  return (
    <S.LoadingMetaWrapper>
      <S.IndicatorWrapper>
        <Loader active size="small" inline="centered" className="custom-indicator" />
      </S.IndicatorWrapper>
    </S.LoadingMetaWrapper>
  );
}

export default PreviewLoader;
