import get from 'lodash/get';
import Request from 'configs/request';
import { INITIAL_STATE_METRIC_GROUP } from './reducers';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';

import { TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { getAvailableMetrics } from 'utils/commonFunction';
import { getGroupMetrics } from 'actions/groupMetric';

export const Types = {
  GET_TRAINER_METRICS_SUCCESS: 'GET_TRAINER_METRICS_SUCCESS',
  GET_TRAINER_METRICS_REQUEST: 'GET_TRAINER_METRICS_REQUEST',
  GET_TRAINER_METRICS_FAILED: 'GET_TRAINER_METRICS_FAILED',
  REMOVE_METRIC_GROUP: 'REMOVE_METRIC_GROUP',
  GET_METRIC_GROUP_LIBRARY_REQUEST: 'GET_METRIC_GROUP_LIBRARY_REQUEST',
  GET_METRIC_GROUP_LIBRARY_SUCCESS: 'GET_METRIC_GROUP_LIBRARY_SUCCESS',
  GET_METRIC_GROUP_LIBRARY_FAILED: 'GET_METRIC_GROUP_LIBRARY_FAILED',
  ADD_METRIC_GROUP_FROM_LIBRARY_REQUEST: 'ADD_METRIC_GROUP_FROM_LIBRARY_REQUEST',
  ADD_METRIC_GROUP_FROM_LIBRARY_SUCCESS: 'ADD_METRIC_GROUP_FROM_LIBRARY_SUCCESS',
  ADD_METRIC_GROUP_FROM_LIBRARY_FAILED: 'ADD_METRIC_GROUP_FROM_LIBRARY_FAILED',
  ADD_METRIC_GROUP_LIBRARY: 'ADD_METRIC_GROUP_LIBRARY',
  ADD_METRIC_GROUP_LIBRARY_REQUEST: 'ADD_METRIC_GROUP_FROM_LIBRARY_REQUEST',
  ADD_METRIC_GROUP_LIBRARY_FAILED: 'ADD_METRIC_GROUP_LIBRARY_FAILED',
  UPDATE_METRIC_GROUP_LIBRARY: 'UPDATE_METRIC_GROUP_LIBRARY',
  CHANGE_QUERY_PARAMS_METRIC_GROUP_LIBRARY: 'CHANGE_QUERY_PARAMS_METRIC_GROUP_LIBRARY',
  RESET_SEARCH_METRIC_GROUP_LIBRARY: 'RESET_SEARCH_METRIC_GROUP_LIBRARY',
};

export const getTrainerMetrics = () => {
  return dispatch => {
    dispatch({ type: Types.GET_TRAINER_METRICS_REQUEST });

    return dispatch(
      Request.get(
        {
          url: `/api/body-metric/get-list-trainer-default-body-metric`,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', []);

          if (data) {
            dispatch({
              type: Types.GET_TRAINER_METRICS_SUCCESS,
              payload: getAvailableMetrics(data),
            });
          }
        },
        error => {
          dispatch({ type: Types.GET_TRAINER_METRICS_FAILED });
        },
      ),
    );
  };
};

export const getGroupMetricsLibrary = (queries, fetchAll) => {
  const initFilters = INITIAL_STATE_METRIC_GROUP.filters;

  const params = !queries ? initFilters : queries;
  return dispatch => {
    if (!fetchAll) {
      dispatch({ type: Types.GET_METRIC_GROUP_LIBRARY_REQUEST });
    }
    return dispatch(
      Request.get(
        {
          url: `/api/group-metric-libraries${fetchAll ? '/all' : ''}`,
          params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data.data', []);
          const total = get(response, 'data.data.total', []);

          if (data) {
            dispatch({ type: Types.GET_METRIC_GROUP_LIBRARY_SUCCESS, payload: { data, params, total } });
          }
        },
        error => {
          dispatch({ type: Types.GET_METRIC_GROUP_LIBRARY_FAILED });
        },
      ),
    );
  };
};

export const addGroupMetricsFromLibrary = data => {
  return dispatch => {
    dispatch({ type: Types.ADD_METRIC_GROUP_FROM_LIBRARY_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/group-metrics/add-from-libraries`,
          data,
        },
        true,
        (response, { dispatch }) => {
          toast(`${data.libraries.length} metric ${data.libraries.length > 1 ? 'groups' : 'group'} have been added`);

          dispatch(getGroupMetrics(data.client));
          dispatch({ type: Types.ADD_METRIC_GROUP_FROM_LIBRARY_SUCCESS });
        },
        error => {
          dispatch({ type: Types.ADD_METRIC_GROUP_FROM_LIBRARY_FAILED });
        },
      ),
    );
  };
};

export const addGroupMetricLibrary = (group, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { metricGroupLibrary },
      user,
    } = getState();
    const isNoOwner = group.owner === 'no_owner';
    const params = isNoOwner ? omit(group, ['owner']) : group;
    const isPrivateUpdate = (user || {})._id !== (group || {}).owner && group.share === TEAM_SHARE_PRIVATE;
    dispatch({ type: Types.ADD_METRIC_GROUP_LIBRARY_REQUEST });
    return dispatch(
      Request.post(
        {
          url: `/api/group-metric-libraries`,
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', null);

          if (data) {
            dispatch({ type: Types.ADD_METRIC_GROUP_LIBRARY, payload: { data, isPrivateUpdate } });
            callback && callback();
          }
        },
        error => {
          dispatch({ type: Types.ADD_METRIC_GROUP_LIBRARY_FAILED });
          dispatch(getGroupMetricsLibrary((metricGroupLibrary || {}).filters));
        },
      ),
    );
  };
};

export const editGroupMetricLibrary = (group, callback, failedCallback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { metricGroupLibrary },
      user,
    } = getState();
    const isNoOwner = group.owner === 'no_owner';
    const params = isNoOwner ? omit(group, ['owner']) : group;
    const isPrivateUpdate = (user || {})._id !== (group || {}).owner && group.share === TEAM_SHARE_PRIVATE;
    dispatch({ type: Types.ADD_METRIC_GROUP_LIBRARY_REQUEST });
    return dispatch(
      Request.patch(
        {
          url: `/api/group-metric-libraries/${group._id}`,
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', null);

          if (data) {
            dispatch({ type: Types.UPDATE_METRIC_GROUP_LIBRARY, payload: { data, isPrivateUpdate } });
            callback && callback();
          }
        },
        err => {
          dispatch({ type: Types.ADD_METRIC_GROUP_LIBRARY_FAILED });
          dispatch(getGroupMetricsLibrary((metricGroupLibrary || {}).filters));
          failedCallback && failedCallback();
        },
      ),
    );
  };
};

export const removeGroupMetricLibrary = (_id, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { metricGroupLibrary },
    } = getState();

    return dispatch(
      Request.delete(
        {
          url: `/api/group-metric-libraries/${_id}`,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data.data', []);

          if (data) {
            dispatch({ type: Types.REMOVE_METRIC_GROUP, payload: { _id } });
            callback && callback();
          }
        },
        err => {
          dispatch(getGroupMetricsLibrary((metricGroupLibrary || {}).filters));
        },
      ),
    );
  };
};

export const changeQueryParams = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: { metricGroupLibrary },
    } = getState();
    const currentFilters = (metricGroupLibrary || {}).filters;
    const newFilters = { ...currentFilters, ...params };
    dispatch({ type: Types.CHANGE_QUERY_PARAMS_METRIC_GROUP_LIBRARY, payload: newFilters });
    dispatch(getGroupMetricsLibrary(newFilters));
  };
};

export const resetSearchGroups = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_SEARCH_METRIC_GROUP_LIBRARY });
  };
};

export const getMetricGroupDetail = (id, callback) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { metricGroupLibrary },
    } = getState();

    return dispatch(
      Request.get(
        { url: `/api/group-metric-libraries/${id}` },
        true,
        () => {},
        () => {
          dispatch({ type: Types.ADD_METRIC_GROUP_LIBRARY_FAILED });
          dispatch(getGroupMetricsLibrary((metricGroupLibrary || {}).filters));
          callback && callback();
        },
      ),
    );
  };
};
