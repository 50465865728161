export const WORKOUT_COMPLETION_TAB_ITEMS = [
  { key: 'missed', value: 0, label: 'Missed', showTotal: true },
  { key: 'completed', value: 1, label: 'Completed', showTotal: true },
];

export const TAB_MISSED = 0;

export const HEIGHT_OF_TAB_COMPLETED = 505;

export const PER_PAGE = 20;

export const SELECTED_CLIENT_TYPE = {
  allMissed: 'all-missed',
  withoutUnread: 'without-unread',
};

export const CLIENT_IN_VIEW = 8;
