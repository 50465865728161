// libraries
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// store
import { addGroupMetricLibrary, editGroupMetricLibrary } from 'redux/metric-group-library/action';
import { toggleConfirmModal } from 'actions/modal';

// components
import AddMetricToGroup from './AddMetricToGroup';
import SelectedMetrics from './SelectedMetrics';
import { NewSearchInput } from 'shared/SearchInput';
import OwnershipPopup from 'components/OwnershipPopup';
import ConfirmModalGroup from '../ConfirmModal';

// constants
import { LIMIT_TITLE, MODE } from 'components/MetricGroupLibrary/constants';
import { CDN_URL, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';

// assets
import { ReactComponent as AddEmptyMetricIcon } from 'assets/icons/empty_add_metric_group.svg';
import { ReactComponent as PlusEmptyIcon } from 'assets/icons/plus_thin.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/add_circle_solid_small.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back_icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_bold_circle.svg';

import * as S from './style';

function NewGroupModal(props) {
  const {
    toggleModal,
    mode = MODE.CREATE,
    oldData,
    addGroupMetric,
    editGroupMetric,
    user,
    toggleConfirmModal,
    isAdding,
  } = props;
  const defaultAuthorId = ((oldData || {}).owner || {})._id || (user || {})._id;
  const noOwner = (oldData || {}).share === 2;
  const hasPermissionAction =
    isTeamAdmin(user) || defaultAuthorId === (user || {})._id || mode === MODE.DUPLICATE || noOwner;
  const isEdit = mode === MODE.EDIT;

  const inputRef = useRef(null);
  const [title, setTitle] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [emptyTitle, setEmptyTitle] = useState(false);
  const [isAddMetric, setIsAddMetric] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [focusedInput, setFocusedInput] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(defaultAuthorId);
  const [share, setShare] = useState(TEAM_SHARE_PRIVATE);
  const [afterSaved, setAfterSave] = useState({});
  const [hasChecked, setHasChecked] = useState(false);

  useEffect(() => {
    !isEdit && enableInput();
  }, [inputRef]);

  const compareSelectedMetric = () =>
    selectedMetrics.every((item, index) => (item || {})._id === (oldData.metrics[index] || {})._id);

  const isHideSaveButton = useMemo(() => {
    return (user || {})._id !== selectedOwner && share === TEAM_SHARE_PRIVATE;
  }, [selectedOwner, share]);

  const hasChanged = useMemo(() => {
    if (hasChecked) return hasChecked;

    if (!isEmpty(afterSaved)) {
      const currentData = {
        title: title,
        metrics: selectedMetrics.map(item => (item || {})._id),
        share: share,
        owner: selectedOwner,
      };

      return !isEqual(afterSaved, currentData);
    }

    if (oldData) {
      const isEqualMetrics = selectedMetrics.length === oldData.metrics.length && compareSelectedMetric();
      const compareTitle = isEdit ? title !== oldData.title : !!title;
      const compareOwner = !noOwner && selectedOwner !== defaultAuthorId;
      const compareShare = isEdit ? share !== oldData.share : share !== TEAM_SHARE_PRIVATE;
      return (
        compareTitle ||
        compareOwner ||
        compareShare ||
        selectedMetrics.length !== oldData.metrics.length ||
        !isEqualMetrics
      );
    }

    return share !== TEAM_SHARE_PRIVATE || selectedOwner !== defaultAuthorId || selectedMetrics.length > 0 || !!title;
  }, [share, selectedOwner, selectedMetrics, title, oldData, afterSaved, hasChecked]);

  useEffect(() => {
    setSelectedMetrics((oldData || {}).metrics || []);
    const hasOldData = mode === MODE.EDIT || mode === MODE.DUPLICATE;

    if (hasOldData) {
      setTitle(mode === MODE.EDIT ? (oldData || {}).title : '');
      setSelectedOwner(mode === MODE.DUPLICATE ? (user || {})._id : noOwner ? 'no_owner' : defaultAuthorId);
      setShare(isEdit ? (oldData || {}).share : TEAM_SHARE_PRIVATE);
    }
  }, []);

  const enableInput = () => {
    setTitle(title);
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
        setFocusedInput(true);
      }
    }, 100);
  };

  const handleBlur = () => {
    setFocusedInput(false);
  };

  const handleChange = event => {
    if (event && event.target) {
      const text = event.target.value;
      if (text.length > LIMIT_TITLE) return;
      setEmptyTitle(false);
      setTitle(text);
    }
  };

  const handleCloseModal = () => {
    if (!hasChanged) {
      toggleModal(false);
      return;
    }

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModalGroup
          onConfirm={() => toggleModal(false)}
          toggleConfirmModal={toggleConfirmModal}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          hasCloseIcon
          content="Are you sure you want to go? Changes have not been saved yet."
          title="Discard Changes?"
          confirmButtonTitle="Discard Changes"
          isDiscardChange={true}
        />,
      );
  };

  const handleCreateGroup = () => {
    const group = {
      title: title,
      metrics: selectedMetrics.map(item => (item || {})._id),
      share: share,
      owner: selectedOwner,
    };

    addGroupMetric(group, () => {
      toast(`Metric group has been saved.`);
    });
  };

  const handleEditGroup = () => {
    const group = {
      _id: (oldData || {})._id,
      title: title,
      metrics: selectedMetrics.map(item => (item || {})._id),
      share: share,
      owner: selectedOwner,
    };

    editGroupMetric(
      group,
      () => {
        toast(`Metric group has been saved.`);
      },
      () => toggleModal(false),
    );
  };

  const handleSaveGroup = (isClose = false) => () => {
    if (!hasPermissionAction) {
      toggleModal(false);
      return;
    }

    const trimmedTitle = title.trim();
    if (trimmedTitle.length === 0) {
      setEmptyTitle(true);
      setTitle('');
      return;
    }

    if ((isAdding && !isClose) || (!isClose && !hasChanged)) return;

    if (!hasChanged) {
      toggleModal(false);
      return;
    }

    mode === MODE.EDIT ? handleEditGroup() : handleCreateGroup();

    setEmptyTitle(false);
    setAfterSave({
      title: trimmedTitle,
      metrics: selectedMetrics.map(item => (item || {})._id),
      share: share,
      owner: selectedOwner,
    });
    isClose && toggleModal(false);
  };

  const handleNavigateAddMetric = () => {
    setIsAddMetric(true);
  };

  const handleSelectMetric = metrics => {
    const newSelected = selectedMetrics.concat(metrics);
    setSelectedMetrics(newSelected);
    setIsAddMetric(false);
    handleBack();
  };

  const handleOrderMetrics = metrics => {
    setSelectedMetrics(metrics);
  };

  const handleRemoveSelectMetric = metric => {
    setSelectedMetrics(selectedMetrics.filter(item => (item || {})._id !== (metric || {})._id));
  };

  const handleSearch = (event, { value }) => {
    setTextSearch(value);
  };

  const handleClearSearch = () => {
    setTextSearch('');
  };

  const handleChangeOwner = (event, data) => {
    const noOwner = data.value === 'no_owner';
    setSelectedOwner(data.value);
    noOwner ? setShare(TEAM_SHARE_NOOWNER) : share === TEAM_SHARE_NOOWNER && setShare(TEAM_SHARE_PRIVATE);
  };

  const handleChangeShare = (event, data) => {
    setShare(data.value);
  };

  const handleBack = () => {
    setIsAddMetric(false);
    setHasChecked(false);
  };

  const renderEmptyMetric = () => (
    <S.EmptyWrapper>
      <S.EmptyIcon>
        <AddEmptyMetricIcon />
      </S.EmptyIcon>
      {hasPermissionAction ? (
        <S.AddMetricButton onClick={handleNavigateAddMetric}>
          <PlusEmptyIcon className="plus-icon" />
          <span>Add Metrics to Group</span>
        </S.AddMetricButton>
      ) : (
        <S.EmptyTitle>No metrics yet.</S.EmptyTitle>
      )}
    </S.EmptyWrapper>
  );

  return (
    <S.CustomModal
      open={true}
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
      isAddMetric={isAddMetric}
      className="evf-add-group-modal"
    >
      <Modal.Header className="modal-add-group-header">
        <S.HeaderWrapper>
          {isAddMetric ? (
            <S.AddMetricHeaderWrapper>
              <S.AddMetricHeaderTitle>
                <BackIcon className="back-icon" onClick={handleBack} />
                <label>Add to group</label>
              </S.AddMetricHeaderTitle>
              <NewSearchInput
                onChange={debounce(handleSearch, 300)}
                onClearSearch={handleClearSearch}
                placeholder="Search by keyword or name"
                className="metric-search"
              />
            </S.AddMetricHeaderWrapper>
          ) : (
            <S.TitleWrapper>
              <S.HeaderInputWrapper>
                <S.HeaderTitle>
                  <S.HeaderInput
                    ref={inputRef}
                    onClick={enableInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={title}
                    placeholder="Name your metric group"
                    isEmpty={emptyTitle}
                    disabled={!hasPermissionAction}
                    maxLength={LIMIT_TITLE}
                  />
                </S.HeaderTitle>
                <S.ValidateArea>
                  <S.LimitTitle isFull={LIMIT_TITLE === title.length && focusedInput}>
                    {title.length >= LIMIT_TITLE - 5 && focusedInput && (
                      <span>
                        {title.length}/{LIMIT_TITLE}
                      </span>
                    )}
                  </S.LimitTitle>
                  {emptyTitle && <S.ValidateMessage>Please add a metric group name</S.ValidateMessage>}
                </S.ValidateArea>
              </S.HeaderInputWrapper>
              {hasPermissionAction && (
                <S.ShareWrapper>
                  <OwnershipPopup
                    isEditAble={true}
                    owner={selectedOwner}
                    handleChangeOwner={handleChangeOwner}
                    share={share}
                    handleChangeShare={handleChangeShare}
                    title="Ownership & Sharing"
                    darkTooltip={true}
                    hasYou={true}
                    className="group-metric"
                  />
                </S.ShareWrapper>
              )}
            </S.TitleWrapper>
          )}

          <CloseButton className="close-button" onClick={handleCloseModal}>
            <CloseIcon className="close-icon" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      {!isAddMetric ? (
        <S.Wrapper>
          {isEmpty(selectedMetrics) ? (
            renderEmptyMetric()
          ) : (
            <SelectedMetrics
              selectedMetrics={selectedMetrics}
              onRemoveGroup={handleRemoveSelectMetric}
              onOrderMetrics={handleOrderMetrics}
              isDisable={!hasPermissionAction}
            />
          )}
        </S.Wrapper>
      ) : (
        <AddMetricToGroup
          selectedMetrics={selectedMetrics}
          onSetSelectedMetrics={handleSelectMetric}
          textSearch={textSearch}
          onClickedCheckbox={setHasChecked}
        />
      )}

      {!isAddMetric && (
        <S.Action>
          <S.AddMetric onClick={handleNavigateAddMetric}>
            {hasPermissionAction && (
              <>
                <PlusIcon className="plus-icon" />
                <span>Add Metrics to Group</span>
              </>
            )}
          </S.AddMetric>

          <S.RightAction>
            {!isHideSaveButton && hasPermissionAction && (
              <S.SaveButton notAllowed={isAdding} onClick={handleSaveGroup()}>
                Save
              </S.SaveButton>
            )}
            <S.SaveButton purple onClick={handleSaveGroup(true)}>
              {hasPermissionAction ? 'Save & Close' : 'Close'}
            </S.SaveButton>
          </S.RightAction>
        </S.Action>
      )}
    </S.CustomModal>
  );
}

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { metricGroupLibrary },
  } = state;
  const { isAdding = false } = metricGroupLibrary;

  return {
    user,
    isAdding,
  };
};

const mapDispatchToProps = dispatch => ({
  addGroupMetric: bindActionCreators(addGroupMetricLibrary, dispatch),
  editGroupMetric: bindActionCreators(editGroupMetricLibrary, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupModal);
