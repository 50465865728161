import styled, { css } from 'styled-components';
import { Dropdown as DropdownElement } from 'semantic-ui-react';
import Button from 'shared/FormControl/Button';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fafbfc;
  overflow: auto;
  scroll-behavior: smooth;
  padding: 0;
`;

export const Container = styled.div`
  width: 100%;
  padding: 20px 220px;
  background: #f0f2f5;
  overflow: none;
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 1 1 !important;
  margin: 0;
`;

export const ScrollTop = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  position: fixed;
  right: 88px;
  bottom: 30px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  z-index: 1000;
  background: #ffffff;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: #6456c4 !important;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const ListContainer = styled.div`
  width: 770px;
  box-shadow: 0px 2px 4px 0px #2a2a3426;
  border-radius: 8px;
  background: #ffffff;
`;

export const Header = styled.div`
  box-shadow: 0px 1px 0px 0px #e9ecf2;
  padding: 33px 30px 19px 30px;
  display: flex;
  justify-content: space-between;

  .search-input {
    height: 40px !important;
    width: 320px !important;
    left: 0px;
    top: 0px;
    border-radius: 5px;
    &.ui.input > input {
      background: url(${CDN_URL}/images/search_bold.svg) no-repeat 12px center !important;
      background-color: #f6f7f9 !important;
      ::placeholder {
        font-size: 12px;
        line-height: 16px;
        color: #a6acc6;
        opacity: 0.8;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 30px;
  min-height: 653px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
`;

export const HeaderSubtile = styled.p`
  padding-top: 7px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;

  span {
    opacity: 0.5;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MemberNameContainer = styled.div`
  font-size: 13px;
  margin-left: 20px;
  align-self: center;
  color: #202353;
  display: block
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .member-name {
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
  .create-at {
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    text-align: left;
    opacity: 50%;
  }
`;

export const MemberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  flex-direction: row;
`;

export const MemberContainerContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

export const Dropdown = styled(DropdownElement)`
  background: #f4f6f8 !important;
  border-radius: 5px !important;
  height: 38px;
  width: 59px;
  padding: 5px 10px !important;

  .icon {
    position: absolute;
    top: 13px;
    right: 14px !important;
  }

  .coach-options-tooltip {
    position: absolute;
    top: 0;
    right: 0 !important;
    background-color: transparent;
    width: 59px;
    height: 38px;
    z-index: 2;
  }

  .dropdown-menu.more-actions {
    transform: translate(100px, 10px);
  }

  .dropdown-menu.more-actions > .item:first-child::before {
    right: 120px !important;
  }
`;

export const DropItem = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202353;
  padding: 17px 20.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: auto;
  min-width: 193px;

  & > span {
    margin-left: 10px;
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
    svg > path {
      stroke: #5158cf;
    }
  }

  ${props =>
    props.remove &&
    css`
      color: #ea314a !important;
      svg > path {
        stroke: #ea314a !important;
      }
    `}
  .item {
    min-width: 193px;
  }
`;

export const DropdownDivider = styled.div`
  background-color: #dadfea;
  height: 1px;
  width: 173px;
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  Button {
    min-width: 59px !important;
    height: 38px;
    background: #f4f6f8 !important;
  }

  & > * {
    margin-left: 8px !important;
  }

  .tooltip-space {
    margin-left: 0 !important;
  }
`;

export const Divider = styled.div`
  height: 1px;
  flex: 0.9;
  border-radius: 0px;
  opacity: 0.6;
  background: #dadfea;
  margin: 30px 0;
`;

export const NoData = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;

  & > * {
    margin-bottom: 12px;
  }

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
  }

  .subtitle {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const ResultsText = styled.div`
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: #202353;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

export const RoleLabel = styled.span`
  font-weight: 400;
`;

export const MemberRole = styled.div`
  min-width: 75px;
  color: #202353;
  background: #eeeff4;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding: 2px 10px;
  margin-left: 15px;
  text-align: center;
  border-radius: 4px;
  white-space: nowrap;
`;

export const SButton = styled(Button)`
  &:hover,
  &:focus,
  &:active {
    background: #eff2f5 !important;
  }
`;
