import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _, { round, forEach, get, isNaN, isEqual } from 'lodash';
import { Button as CloseButton, Image } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
// import containers
import AddIntroModal from '../AddIntroModal';
import GramForm from './GramForm';
import PercentForm from './PercentForm';
import AddRestDaySection from '../AddRestDaySection';
// import actions
import { toggleModal } from 'actions/modal';
import { updateGoal } from 'actions/marcos';

import { CDN_URL } from 'constants/commonData';

// import icons
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as RestDayIcon } from 'assets/icons/add-rest-day.svg';
import ManualGoalModalStyles, { HeaderStyles, ContentStyles, ActionsStyles } from './styles';

const listDay = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const ManualGoalModal = ({
  isModalOpen,
  clientId,
  data = {},
  restDayData = {},
  fetchReport,
  typeDefault = 'gram',
  isEnabledBtn = false,
  dispatch,
  isUpdate = false,
  history,
}) => {
  const [isDisabledBtn, setIsDisabledBtn] = useState(!isEnabledBtn);
  const [isSaving, setIsSaving] = useState(false);
  const [typeSelected, setTypeSelected] = useState(typeDefault);
  const [restDayType, setRestDayType] = useState(get(restDayData, 'type'));
  const [listRestDay, setListRestDay] = useState(get(restDayData, 'rest_days') ? restDayData.rest_days.split('_') : []);
  const [totalCalo, setTotalCalo] = useState(data.totalCalo || NaN);
  const [totalRestCalo, setTotalRestCalo] = useState(get(restDayData, 'totalCalo') || NaN);
  const [nutri, setNutri] = useState({
    protein: data.protein || NaN,
    carbs: data.carbs || NaN,
    fat: data.fat || NaN,
  });
  const [restNutri, setRestNutri] = useState({
    protein: get(restDayData, 'protein') || NaN,
    carbs: get(restDayData, 'carbs') || NaN,
    fat: get(restDayData, 'fat') || NaN,
  });
  const [errors, setErrors] = useState({
    totalCalo: false,
    protein: false,
    carbs: false,
    fat: false,
  });
  const [restDayErrors, setRestDayErrors] = useState({
    totalCalo: false,
    protein: false,
    carbs: false,
    fat: false,
  });

  useEffect(() => {
    setErrors({
      totalCalo: false,
      protein: false,
      carbs: false,
      fat: false,
    });
    setRestDayErrors({
      totalCalo: false,
      protein: false,
      carbs: false,
      fat: false,
    });
    if (typeSelected === 'gram' && !isNaN(totalCalo)) {
      if (!isNaN(nutri.protein) && !isNaN(nutri.carbs) && !isNaN(nutri.fat)) {
        setTotalCalo(round((totalCalo * (nutri.protein + nutri.carbs + nutri.fat)) / 100, 2));
        setNutri({
          protein: round((nutri.protein * totalCalo) / (4 * 100), 2),
          carbs: round((nutri.carbs * totalCalo) / (4 * 100), 2),
          fat: round((nutri.fat * totalCalo) / (9 * 100), 2),
        });
      } else {
        setNutri({
          protein: NaN,
          carbs: NaN,
          fat: NaN,
        });
        setTotalCalo(NaN);
      }
    }
    if (typeSelected === 'percent' && !isNaN(totalCalo)) {
      if (!isNaN(nutri.protein) && !isNaN(nutri.carbs) && !isNaN(nutri.fat)) {
        setNutri({
          protein: round((4 * nutri.protein * 100) / totalCalo, 2),
          carbs: round((4 * nutri.carbs * 100) / totalCalo, 2),
          fat: round(
            100 - round((4 * nutri.protein * 100) / totalCalo, 2) - round((4 * nutri.carbs * 100) / totalCalo, 2),
            2,
          ),
        });
      } else {
        setNutri({
          protein: NaN,
          carbs: NaN,
          fat: NaN,
        });
      }
    }
    if (typeSelected === 'gram' && !isNaN(totalRestCalo)) {
      if (!isNaN(restNutri.protein) && !isNaN(restNutri.carbs) && !isNaN(restNutri.fat)) {
        setTotalRestCalo(round((totalRestCalo * (restNutri.protein + restNutri.carbs + restNutri.fat)) / 100, 2));
        setRestNutri({
          protein: round((restNutri.protein * totalRestCalo) / (4 * 100), 2),
          carbs: round((restNutri.carbs * totalRestCalo) / (4 * 100), 2),
          fat: round((restNutri.fat * totalRestCalo) / (9 * 100), 2),
        });
      } else {
        setRestNutri({
          protein: NaN,
          carbs: NaN,
          fat: NaN,
        });
        setTotalRestCalo(NaN);
      }
    }
    if (typeSelected === 'percent' && !isNaN(totalRestCalo)) {
      if (!isNaN(restNutri.protein) && !isNaN(restNutri.carbs) && !isNaN(restNutri.fat)) {
        setRestNutri({
          protein: round((4 * restNutri.protein * 100) / totalRestCalo, 2),
          carbs: round((4 * restNutri.carbs * 100) / totalRestCalo, 2),
          fat: round(
            100 -
              round((4 * restNutri.protein * 100) / totalRestCalo, 2) -
              round((4 * restNutri.carbs * 100) / totalRestCalo, 2),
            2,
          ),
        });
      } else {
        setRestNutri({
          protein: NaN,
          carbs: NaN,
          fat: NaN,
        });
      }
    }
  }, [typeSelected]);
  useEffect(() => {
    if (data) {
      const { protein, carbs, fat, totalCalo } = data;
      setTotalCalo(round(totalCalo, 2));
      setNutri({
        protein: round(protein, 2),
        carbs: round(carbs, 2),
        fat: round(fat, 2),
      });
    }
  }, [data]);
  useEffect(() => {
    if (get(restDayData, 'totalCalo')) {
      const { protein, carbs, fat, totalCalo } = restDayData;
      setTotalRestCalo(round(totalCalo, 2));
      setRestNutri({
        protein: round(protein, 2),
        carbs: round(carbs, 2),
        fat: round(fat, 2),
      });
    }
    if (get(restDayData, 'rest_days')) {
      setListRestDay(restDayData.rest_days.split('_'));
    }
    if (get(restDayData, 'type')) {
      setRestDayType(restDayData.type);
    }
  }, [restDayData]);
  useEffect(() => {
    if (!isEnabledBtn) {
      if (!totalCalo || _.values(nutri).every(_.isNaN)) {
        setIsDisabledBtn(true);
      } else {
        const initData = {
          totalCalo: data.totalCalo || NaN,
          totalRestCalo: get(restDayData, 'totalCalo') || NaN,
          nutri: {
            protein: data.protein || NaN,
            carbs: data.carbs || NaN,
            fat: data.fat || NaN,
          },
          restNutri: {
            protein: get(restDayData, 'protein') || NaN,
            carbs: get(restDayData, 'carbs') || NaN,
            fat: get(restDayData, 'fat') || NaN,
          },
          typeSelected: typeDefault,
          restDayType: get(restDayData, 'type'),
          listRestDay: get(restDayData, 'rest_days') ? restDayData.rest_days.split('_') : [],
        };
        const newData = {
          totalCalo,
          totalRestCalo,
          nutri,
          restNutri,
          typeSelected,
          restDayType,
          listRestDay,
        };
        if (isEqual(initData, newData)) setIsDisabledBtn(true);
        else setIsDisabledBtn(false);
      }
    }
  }, [totalCalo, totalRestCalo, nutri, restNutri, typeSelected, restDayType, listRestDay]);

  const onSave = () => {
    // Validate data
    if (isSaving) return;
    setIsSaving(true);
    const { protein, carbs, fat } = nutri;
    if (typeSelected === 'gram') {
      if ((!protein && !carbs && !fat) || !totalCalo) {
        setIsSaving(false);
        return setErrors({
          protein: true,
          carbs: true,
          fat: true,
        });
      }
      if (restDayType && ((!restNutri.protein && !restNutri.carbs && !restNutri.fat) || !totalRestCalo)) {
        setIsSaving(false);
        return setRestDayErrors({
          protein: true,
          carbs: true,
          fat: true,
        });
      }
    }
    if (typeSelected === 'percent') {
      const checkingErrors = [];
      forEach(errors, (v, name) => {
        if (name !== 'totalCalo') {
          if (isNaN(nutri[name])) checkingErrors.push(name);
        }
      });
      if (checkingErrors.length > 0) {
        setIsSaving(false);
        return setErrors({
          ...errors,
          ...checkingErrors.reduce((obj, err) => {
            obj[err] = true;
            return obj;
          }, {}),
        });
      }

      if (restDayType) {
        const checkingErrors = [];
        forEach(restDayErrors, (v, name) => {
          if (name !== 'totalCalo') {
            if (isNaN(restNutri[name])) checkingErrors.push(name);
          }
        });
        if (checkingErrors.length > 0) {
          setIsSaving(false);
          return setRestDayErrors({
            ...restDayErrors,
            ...checkingErrors.reduce((obj, err) => {
              obj[err] = true;
              return obj;
            }, {}),
          });
        }
      }
    }

    const listParams = [
      {
        client: clientId,
        type: 'training_day',
        macro: {
          type: typeSelected,
          protein: protein || 0,
          carbs: carbs || 0,
          fat: fat || 0,
          calories: totalCalo,
        },
      },
    ];
    if (restDayType) {
      listParams.push({
        client: clientId,
        type: 'rest_day',
        rest_days: restDayType === 'no_workout' ? 'no_workout' : listRestDay.join('_'),
        macro: {
          type: typeSelected,
          protein: restNutri.protein || 0,
          carbs: restNutri.carbs || 0,
          fat: restNutri.fat || 0,
          calories: totalRestCalo,
        },
      });
    } else {
      if (restDayData && restDayData.totalCalo) {
        listParams.push({
          client: clientId,
          type: 'rest_day',
          rest_days: 'no_workout',
          macro: {
            type: typeSelected,
            protein: 0,
            carbs: 0,
            fat: 0,
            calories: 0,
          },
        });
      }
    }
    dispatch(updateGoal(listParams)).then(() => {
      if (fetchReport) fetchReport();
      if (isUpdate) history.replace();
      setIsSaving(false);
    });
  };
  return (
    <ManualGoalModalStyles
      size={'tiny'}
      open={isModalOpen}
      onClose={() => dispatch(toggleModal(false))}
      className="client-invite-modals"
      closeOnDimmerClick={false}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
    >
      <HeaderStyles>
        <div
          className="back-icon"
          onClick={() => dispatch(toggleModal(true, <AddIntroModal clientId={clientId} fetchReport={fetchReport} />))}
        >
          <BackIcon />
        </div>
        <div className="title">Set Macro Goals</div>
      </HeaderStyles>
      <ContentStyles>
        <div className="goal-types-select">
          <div
            className={classNames('goal-type', { selected: typeSelected === 'percent' })}
            onClick={() => setTypeSelected('percent')}
          >
            Percent (%)
          </div>
          <div
            className={classNames('goal-type', { selected: typeSelected === 'gram' })}
            onClick={() => setTypeSelected('gram')}
          >
            Gram (g)
          </div>
        </div>
        {typeSelected === 'gram' && (
          <>
            <GramForm
              totalCalo={totalCalo}
              setTotalCalo={setTotalCalo}
              nutri={nutri}
              setNutri={setNutri}
              errors={errors}
              setErrors={setErrors}
            />
            <AddRestDaySection
              restDayType={restDayType}
              setRestDayType={setRestDayType}
              listRestDay={listRestDay}
              setListRestDay={setListRestDay}
            >
              <GramForm
                totalCalo={totalRestCalo}
                setTotalCalo={setTotalRestCalo}
                nutri={restNutri}
                setNutri={setRestNutri}
                errors={restDayErrors}
                setErrors={setRestDayErrors}
              />
            </AddRestDaySection>
          </>
        )}
        {typeSelected === 'percent' && (
          <>
            <PercentForm
              totalCalo={totalCalo}
              setTotalCalo={setTotalCalo}
              nutri={nutri}
              setNutri={setNutri}
              errors={errors}
              setErrors={setErrors}
            />
            <AddRestDaySection
              restDayType={restDayType}
              setRestDayType={setRestDayType}
              listRestDay={listRestDay}
              setListRestDay={setListRestDay}
            >
              <PercentForm
                totalCalo={totalRestCalo}
                setTotalCalo={setTotalRestCalo}
                nutri={restNutri}
                setNutri={setRestNutri}
                errors={restDayErrors}
                setErrors={setRestDayErrors}
              />
            </AddRestDaySection>
          </>
        )}
      </ContentStyles>
      <ActionsStyles>
        <Button purple type="submit" onClick={onSave} disabled={isDisabledBtn || isSaving}>
          Save
        </Button>
      </ActionsStyles>
    </ManualGoalModalStyles>
  );
};

ManualGoalModal.defaultProps = {
  data: {},
  restDayData: {},
};

export default connect(({ isModalOpen }) => ({
  isModalOpen,
}))(ManualGoalModal);
