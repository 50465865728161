import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import styled from 'styled-components';

export const SetOneRepMaxButton = styled(Button)`
  height: 30px;
  min-width: 77px;
  border: 1px solid #5158cf;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 14px;
  color: #5158cf;
  margin-right: 10px;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const SetOneRepMaxModalWrapper = styled(Modal)`
  background: #ffffff;
  border: 1px solid #d4d4d5;
  box-sizing: border-box;
  border-radius: 8px;

  &.ui.modal {
    width: 405px !important;

    .header {
      padding: 30px;
      padding-bottom: 0px;
      border-top-left-radius: 8px;
    }
  }

  .orm__inputValue {
    position: relative;

    &--addon {
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      opacity: 0.4;
      position: absolute;
      top: 9px;
      right: 20px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 20px 30px 30px;
  }

  .orm__footer {
    padding-top: 15px;
    text-align: right;
  }
`;
