import React, { useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

export default props => {
  const { checked, className, disabled, label, onChange, children, ...otherProps } = props;
  const inputRef = useRef();

  const onLabelClick = () => {
    !disabled && inputRef.current.click();
  };

  return (
    <Container
      {...otherProps}
      className={classNames('toggle-input', { checked: checked }, className)}
      onChange={null}
      hasLabel={!!label}
      disabled={disabled}
    >
      <input type="checkbox" checked={!!checked} disabled={disabled} ref={inputRef} onChange={onChange} />
      <label onClick={onLabelClick}>{label || ''}</label>
      {children}
    </Container>
  );
};

const Container = styled.div`
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 600;
  line-height: ${props => `${props.height || 20}px`};
  position: relative;
  display: inline-block;
  min-width: ${props => `${props.width || 40}px`};
  min-height: ${props => `${props.height || 20}px`};
  color: #000;
  cursor: pointer;

  label {
    position: relative;
    min-width: ${props => `${props.width || 40}px`};
    min-height: ${props => `${props.height || 20}px`};
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;

    :hover {
      cursor: pointer;
    }

    ::before,
    ::after {
      cursor: pointer;
      display: block;
      position: absolute;
      content: '';
      z-index: 1;
      transform: none;
      border: none;
      top: 0;
      background: #fff;
      box-shadow: none;
      border-radius: 9999px;
      transition: background 0.3s ease, left 0.3s ease;
    }

    :before {
      left: 0;
    }

    :after {
      left: 2px;
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    outline: 0 !important;
    width: ${props => `${props.width || 40}px`};
    height: ${props => `${props.height || 20}px`};
    z-index: 2;
    cursor: pointer;
    line-height: ${props => `${props.height || 20}px`};

    ~ label {
      ::before {
        background-color: #728096;
        width: ${props => `${props.width || 40}px`};
        height: ${props => `${props.height || 20}px`};
      }

      ::after {
        width: ${props => `${(props.height || 20) - 4}px`};
        height: ${props => `${(props.height || 20) - 4}px`};
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        box-shadow: none !important;
        border-radius: 50%;
      }
    }

    :checked,
    :checked:focus {
      ~ label {
        ::before {
          background-color: #6456c4;
        }

        ::after {
          left: ${props => `calc(${props.width || 40}px - ${(props.height || 20) - 2}px)`};
        }
      }
    }
  }

  ${props =>
    props.hasLabel &&
    css`
      label {
        padding-left: ${props => `${(props.width || 40) + 6}px`};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      input {
        cursor: not-allowed;
      }

      label {
        :hover {
          cursor: not-allowed;
        }
      }

      label {
        ::before,
        ::after {
          cursor: not-allowed;
        }
      }
    `}

  ${props =>
    props.reverse &&
    css`
      label {
        padding-left: 0;

        :before {
          left: unset;
          right: 0;
        }

        :after {
          left: unset;
          right: 2px;
        }
      }

      input {
        :checked,
        :checked:focus {
          ~ label {
            ::after {
              right: ${props => `calc(${props.width || 40}px - ${(props.height || 20) - 2}px)`};
              left: unset;
            }
          }
        }
      }
    `}

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;

      label {
        width: 100%;
      }
    `}
`;
