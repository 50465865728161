import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

const Wrapper = styled.div`
  margin-bottom: 5px;
  width: calc(100% - 180px);
  margin-left: 17px;

  .nameInput__editor {
    padding: 10px 10px;
    width: 100%;
    max-width: 450px;
    border-radius: 5px;
    border-radius: 3px;
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 23px;
    line-height: 130%;
    color: #202353;
    outline: none;
    margin-left: -10px;

    :hover {
      background: #f1f1f3;
    }

    :focus {
      border: 1px solid #5158cf;
      background: #ffffff;
    }

    ${props =>
      props.hasError &&
      css`
        background: #fef4f6 !important;
        border: 1px solid #ea314a !important;
      `}

    :disabled {
      cursor: not-allowed;
      background: transparent !important;
    }
  }

  .nameInput__warning {
    padding: 5px 15px;

    &--label {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
    }

    &--text {
      color: #ea314a;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const limitLength = 20;

function CollectionNameInput(props) {
  const { disabled = false } = props;
  const [name, setName] = useState(props.name || '');
  const [showError, setShowError] = useState(false);
  const [focused, setFocused] = useState(false);

  const onChange = e => {
    const value = e.target.value;
    if (value.length <= limitLength) {
      setName(value);
      value !== name && props.onConfirmChange(value);
    } else {
      setName(_.truncate(value, { length: limitLength, omission: '' }));
      flashError();
    }
  };

  const flashError = _.debounce(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 200);
  }, 300);

  const onBlur = () => {
    props.onConfirmChange(name);
    setFocused(false);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onKeyPress = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  const handleOnMouseOut = e => {
    props.onConfirmChange(name);
  };

  return (
    <Wrapper hasError={!name || name.length > limitLength || showError}>
      <input
        value={name}
        onChange={onChange}
        placeholder="Add collection name"
        onBlur={onBlur}
        onFocus={onFocus}
        className="nameInput__editor"
        onKeyPress={onKeyPress}
        onMouseOut={handleOnMouseOut}
        disabled={disabled}
      />
      {name.length > 17 && focused && (
        <div className="nameInput__warning">
          <span className="nameInput__warning--label">Characters:</span>
          <span className="nameInput__warning--text">
            {' '}
            {name.length}/{limitLength}
          </span>
        </div>
      )}
    </Wrapper>
  );
}

export default CollectionNameInput;
