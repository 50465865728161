// Libs
import React from 'react';
import { Button as CloseButton } from 'semantic-ui-react';

// Shared
import ImageBlurHashComponent from 'shared/ImageBlurHashComponent';

import { CDN_URL } from 'constants/commonData';

// Assets
import DescStartTrial from 'assets/icons/MealPlans/desc-start-trial.png';

// Style
import * as S from './style';

const StartTrialModal = ({ onClose, onConfirmed }) => {
  return (
    <S.Wrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close" />
        </CloseButton>
      }
    >
      <div className="banner-start-trial">
        <ImageBlurHashComponent src={DescStartTrial} alt="Start Trial" hash="LaPGvy-;E0oz~qn~%Mn*V?D%s;R*" />
      </div>
      <div className="content">
        <div className="title">Elevate Nutrition Coaching with Advanced Meal Plans</div>
        <div className="desc">
          Begin a 7-day trial of Advanced Meal Plans. Empower your clients to eat healthier with tailored nutrition
          plans and deliver delicious recipes in a breeze.
        </div>
      </div>
      <div className="actions">
        <button onClick={onClose}>I'll try it later</button>
        <button onClick={onConfirmed} className="confirm">
          Try it now
        </button>
      </div>
    </S.Wrapper>
  );
};

export default StartTrialModal;
