// Libs
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

// actions
import { toggleModal } from 'actions/modal';
import { createOnboardingFlow } from 'redux/onboarding-flow-library/actions';

// components
import CreateButton from 'components/OnboardingFlowLibrary/components/CreateButton';
import CreateModal from 'components/OnboardingFlowLibrary/components/CreateModal';

// assets
import OnboardingSteps from 'assets/images/OnboardingFlow/onboarding_steps.png';
import SearchEmpty from 'assets/images/OnboardingFlow/search_empty.png';

// styles
import * as S from './style';

const ListEmpty = props => {
  const { toggleModal, isSearch = false, isAchieved = false, createOnboardingFlow } = props;

  const handleSubmit = data => {
    typeof createOnboardingFlow === 'function' &&
      createOnboardingFlow(data, () => {
        toggleModal(false);
      });
  };

  const handleOpenCreatePopup = () => {
    toggleModal(true, <CreateModal onClose={() => toggleModal(false)} onSubmit={handleSubmit} />);
  };

  return (
    <S.ListEmptyWrapper className={classNames({ 'search-wrap': isSearch, 'achieved-wrap': isAchieved })}>
      <img src={isSearch ? SearchEmpty : OnboardingSteps} alt="" className="steps" />
      <span className="label">
        {isSearch
          ? 'No results found.'
          : isAchieved
          ? 'No archived Onboarding Flows'
          : 'Create your first Onboarding Flow'}
      </span>
      {!isSearch && !isAchieved && (
        <CreateButton contentButton="Create New Onboarding Flow" onClick={handleOpenCreatePopup} />
      )}
    </S.ListEmptyWrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: {
      onboardingLibrary: {},
    },
  } = state;

  return { user };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  createOnboardingFlow: bindActionCreators(createOnboardingFlow, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListEmpty);
