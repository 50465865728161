// Libs
import React from 'react';

// Shared
import UpgradePathIconButton from '../UpgradePathIconButton';

// Styles
import * as S from './styles';

const NoneTriggerBanner = props => {
  const { isHasPermissionUseTrigger, questionId } = props;

  return (
    <S.NoneTriggerWrapper>
      {!isHasPermissionUseTrigger && (
        <UpgradePathIconButton
          wrapperClassNames="upgrade-icon-wrapper"
          tooltipOffsetRight
          tooltipId={`upgrade-path-autoflow-${questionId}`}
        />
      )}
      <div className="title-banner">Automation trigger</div>
      <div className="description-banner">
        Single choice question now can add automation trigger&nbsp;
        <a
          className="link-read-more-banner"
          href="https://help.everfit.io/en/articles/9595362-form-triggers"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more
        </a>
      </div>
    </S.NoneTriggerWrapper>
  );
};

export default NoneTriggerBanner;
