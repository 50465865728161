import { toggleModal } from 'actions/modal';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { addSectionLibrary, openSectionWorkoutDetail, duplicateSectionLibrary } from 'redux/section-library/actions';
import { addSection, resetDiscardChange } from 'redux/workout-builder/actions';
import Component from './component';

const actionCreators = {
  toggleModal,
  addSectionLibrary,
  push,
  addSection,
  openSectionWorkoutDetail,
  duplicateSectionLibrary,
  resetDiscardChange,
};

export default connect(null, actionCreators)(Component);
