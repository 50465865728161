import styled from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

export const Wrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .custom-arrow-icon {
    flex-shrink: 0;
  }
  span {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #ed6e52;
    text-transform: capitalize;
    user-select: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  top: calc(100% + 11px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 308px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
`;

export const Header = styled.div`
  position: relative;
  height: 160px;
  width: 100%;
`;

export const Banner = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Title = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px 15px 24px 16px;
  background: #ffffff;
`;

export const Description = styled.div`
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #202353;
  letter-spacing: -0.02px;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const ButtonUpgradePath = styled(UpgradePathButton)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #5e59ff;
  border-radius: 5px;
  background: #fff;
  span {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: #5e59ff;
    text-transform: unset;
  }
  &:hover {
    border: 1px solid #2b26c1;
    background: unset;
    span {
      color: #2b26c1;
    }
  }
`;
