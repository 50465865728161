import React, { useState, memo, useMemo, useEffect } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import Datetime from 'react-datetime';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

// Constants
import { NORMALIZING_TIME_OPTIONS, formatDate } from 'components/BodyMetricChartNew/constants';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/close_current_color.svg';

import * as S from './style';

const FOCUS = {
  start: 'start',
  end: 'end',
};

const FORMAT_STRING = 'yyyy-MM-DD';

const DatePicker = props => {
  const {
    onApplyCustomFilter,
    startDate,
    endDate,
    rangeFilter,
    hideFilterToday = false,
    isLoadingMetric = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState(FOCUS.start);
  const [selectedDates, setSelectedDates] = useState({ startDate, endDate });
  const [currentApplyDates, setCurrentApplyDates] = useState({ startDate, endDate });
  const enableApply =
    rangeFilter === NORMALIZING_TIME_OPTIONS.custom.value || !isEqual(selectedDates, currentApplyDates);

  useEffect(() => {
    setCurrentApplyDates({ startDate, endDate });
  }, [startDate, endDate, open]);

  useEffect(() => {
    setSelectedDates(currentApplyDates);
    open && setFocusedInput(FOCUS.start);
    return () => {
      setSelectedDates(currentApplyDates);
    };
  }, [open]);

  const current = moment(new Date());
  const isEndSameCurrentYear = endDate.isSame(current, 'year');
  const isStartSameCurrentYear = startDate.isSame(current, 'year');

  const handleOpenDropdown = val => () => {
    if (isLoadingMetric) return;
    if (!val) {
      setSelectedDates(currentApplyDates);
      setFocusedInput(FOCUS.start);
    }
    setOpen(val);
  };

  const renderDay = (props, currentDate) => {
    let className = props.className || '';
    const start = !isEqual(selectedDates, currentApplyDates) ? selectedDates.startDate : currentApplyDates.startDate;
    const end = !isEqual(selectedDates, currentApplyDates) ? selectedDates.endDate : currentApplyDates.endDate;

    const isStartDate = currentDate.isSame(start, 'day');
    const isEndDate = currentDate.isSame(end, 'day');

    if (isStartDate || currentDate.isBetween(start, end)) {
      className += ' inRange';
    }

    if (isStartDate) {
      className += ' rangeStart';
    }

    if (isEndDate) {
      className += ' rangeEnd';
    }

    const isInvalidDate =
      (focusedInput === FOCUS.start && selectedDates.endDate && currentDate > selectedDates.endDate) ||
      (focusedInput === FOCUS.end && selectedDates.startDate && currentDate < selectedDates.startDate);
    const enCompareYear =
      focusedInput === FOCUS.start && selectedDates.endDate && currentDate.diff(selectedDates.endDate, 'years');
    const stCompareYear =
      focusedInput === FOCUS.end && selectedDates.startDate && currentDate.diff(selectedDates.startDate, 'years');

    if (isInvalidDate || !!enCompareYear || !!stCompareYear) {
      className += ' rdtDisabled';
    }

    return (
      <td {...props} className={className}>
        <div className="content">
          <div>{currentDate.format('DD')}</div>
        </div>
      </td>
    );
  };

  const onSelectDate = date => {
    if (!focusedInput) return;

    const formattedEndDate = moment(selectedDates.endDate).format('DD-MM-YYYY');
    const formattedStartDate = moment(selectedDates.startDate).format('DD-MM-YYYY');
    const formattedCurrentDate = moment(date).format('DD-MM-YYYY');

    if (hideFilterToday) {
      const isInvalidDateToday =
        (focusedInput === FOCUS.start && formattedEndDate === formattedCurrentDate) ||
        (focusedInput === FOCUS.end && formattedStartDate === formattedCurrentDate);

      if (isInvalidDateToday) return;
    }

    const isInvalidDate =
      (focusedInput === FOCUS.start && selectedDates.endDate && date > selectedDates.endDate) ||
      (focusedInput === FOCUS.end && selectedDates.startDate && date < selectedDates.startDate);
    if (isInvalidDate) return;

    const enCompareYear =
      focusedInput === FOCUS.start && selectedDates.endDate && date.diff(selectedDates.endDate, 'years');
    const stCompareYear =
      focusedInput === FOCUS.end && selectedDates.startDate && date.diff(selectedDates.startDate, 'years');
    if (!!enCompareYear || !!stCompareYear) return;

    const newSelectDates =
      focusedInput === FOCUS.start ? { ...selectedDates, startDate: date } : { ...selectedDates, endDate: date };

    setSelectedDates(newSelectDates);
  };

  const handleApplySelected = () => {
    if (!enableApply) return;

    const newSelectDates = selectedDates.endDate
      ? selectedDates
      : { ...selectedDates, endDate: selectedDates.startDate };
    onApplyCustomFilter(newSelectDates);
    setOpen(false);
  };

  const handleFocusInput = selected => () => {
    setFocusedInput(selected);
  };

  const handleRemoveDate = () => {
    setSelectedDates({ ...selectedDates, endDate: '' });
  };

  const isSelectOne = useMemo(() => {
    const { startDate, endDate } = selectedDates;

    return (
      !startDate ||
      !endDate ||
      (startDate && endDate && startDate.format(FORMAT_STRING) === endDate.format(FORMAT_STRING))
    );
  }, [selectedDates]);

  const isAppliedOne = useMemo(() => {
    const { startDate, endDate } = currentApplyDates;
    return (
      !startDate ||
      !endDate ||
      (startDate && endDate && startDate.format(FORMAT_STRING) === endDate.format(FORMAT_STRING))
    );
  }, [currentApplyDates]);

  return (
    <S.Wrapper>
      <S.Trigger onClick={handleOpenDropdown(!open)} active={open} disabled={isLoadingMetric}>
        {isAppliedOne ? (
          formatDate(currentApplyDates.startDate, isStartSameCurrentYear)
        ) : (
          <>
            {formatDate(currentApplyDates.startDate, isStartSameCurrentYear)} -{' '}
            {formatDate(currentApplyDates.endDate, isEndSameCurrentYear)}
          </>
        )}
      </S.Trigger>
      {open && (
        <RootCloseWrapper event="click" onRootClose={handleOpenDropdown(false)}>
          <S.Popup>
            <S.HeaderWrapper>
              <S.ShowStartDate onClick={handleFocusInput(FOCUS.start)} isFocus={focusedInput === FOCUS.start}>
                <S.Section>
                  <S.HeaderLabel>Start Date</S.HeaderLabel>
                  <S.SelectedDate>
                    <span>
                      {selectedDates.startDate ? (
                        formatDate(selectedDates.startDate)
                      ) : (
                        <span className="empty-text">Add Start Date</span>
                      )}
                    </span>
                  </S.SelectedDate>
                </S.Section>
              </S.ShowStartDate>
              <S.MiddleLine />
              <S.ShowEndDate onClick={handleFocusInput(FOCUS.end)} isFocus={focusedInput === FOCUS.end}>
                <S.Section>
                  <S.HeaderLabel>End Date</S.HeaderLabel>
                  <S.SelectedDate>
                    <span>
                      {selectedDates.endDate ? (
                        formatDate(selectedDates.endDate)
                      ) : (
                        <span className="empty-text">Add End Date</span>
                      )}
                    </span>
                  </S.SelectedDate>
                </S.Section>
                {selectedDates.endDate && (
                  <S.Section>
                    <RemoveIcon className="remove-icon" onClick={handleRemoveDate} />
                  </S.Section>
                )}
              </S.ShowEndDate>
            </S.HeaderWrapper>
            <S.BodyWrapper isOne={isSelectOne}>
              <Datetime
                open
                value={
                  focusedInput === FOCUS.start || !selectedDates.endDate
                    ? selectedDates.startDate
                    : selectedDates.endDate
                }
                timeFormat={false}
                onChange={onSelectDate}
                renderDay={renderDay}
              />
            </S.BodyWrapper>
            <S.FooterWrapper>
              <S.CancelButton onClick={handleOpenDropdown(false)}>Cancel</S.CancelButton>
              <S.ApplyButton
                onClick={handleApplySelected}
                isCustom={enableApply}
                disabled={hideFilterToday && !selectedDates.endDate}
              >
                Apply
              </S.ApplyButton>
            </S.FooterWrapper>
          </S.Popup>
        </RootCloseWrapper>
      )}
    </S.Wrapper>
  );
};

export default memo(DatePicker);
