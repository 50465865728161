import React from 'react';
import _ from 'lodash';
import SelectSetType from 'shared/SelectSetType';
import FieldInput from './FieldInput';
import { ENTITY_NAME } from 'database/constants';
import * as S from './style';

export default props => {
  const {
    fieldsObject,
    model,
    setId,
    setIndex,
    supersetId,
    disabled,
    exerciseId,
    oneRepMaxData,
    useORM,
    supersetORMData,
    missingORMBValue,
  } = props;
  const setData = model && model.toJS();

  let oneRepMax = supersetORMData;
  if (oneRepMaxData && (!oneRepMax || !oneRepMax.value)) {
    oneRepMax = oneRepMaxData.toJS();
  }

  const onChangeSetType = newSetType => {
    props.updateEntity(ENTITY_NAME.TrainingSet, setId, { set_type: newSetType });
  };

  const onChangeSetValue = (data, isChanged) => {
    props.updateEntity(ENTITY_NAME.TrainingSet, setId, data, isChanged);
  };

  const onDelete = () => {
    props.removeSet(exerciseId, supersetId, setId);
  };

  return (
    <S.Wrapper>
      <td key={`setType_${setId}`} className="setTypeColumn">
        <SelectSetType setIndex={setIndex} value={setData && setData.set_type} onSelect={onChangeSetType} />
        {!disabled && (
          <svg
            className="superset__deleteSet"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onDelete}
          >
            <path
              d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
              fill="white"
            />
            <path
              d="M9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35786 13.6421 2 9.5 2C5.35786 2 2 5.35786 2 9.5C2 13.6421 5.35786 17 9.5 17Z"
              fill="#EAEBFF"
              stroke="#5158CF"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M12 7L7 12" stroke="#5158CF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 7L12 12" stroke="#5158CF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        )}
      </td>
      {_.map(fieldsObject, field => {
        return (
          <td key={`setField_${setId}_${field._id}`}>
            <S.FieldInputContainer>
              <FieldInput
                setData={setData}
                field={field}
                onChange={onChangeSetValue}
                useORM={useORM}
                supersetORMData={oneRepMax}
                missingORMBValue={missingORMBValue}
                units={props.units}
              />
            </S.FieldInputContainer>
          </td>
        );
      })}
    </S.Wrapper>
  );
};
