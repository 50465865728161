import get from 'lodash/get';
import { Types } from './actions';

const INITIAL_STATE = {
  list: [],
  totalMissed: 0,
  totalCompleted: 0,
  isProcessingData: false,
  allMissedWorkouts: [],
  currentParams: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_WORKOUT_SUMMARY_REQUEST: {
      return Object.assign({}, state, { loading: true, currentParams: get(payload, 'newParams', null) });
    }

    case Types.GET_WORKOUT_SUMMARY_SUCCESS: {
      const { data, total_missed, total_completed, is_processing_data } = payload;
      if (is_processing_data) {
        return Object.assign({}, state, {
          isProcessingData: is_processing_data,
          loading: true,
        });
      }

      return Object.assign({}, state, {
        loading: false,
        list: data,
        totalMissed: total_missed,
        totalCompleted: total_completed,
        isProcessingData: false,
      });
    }

    case Types.GET_WORKOUT_SUMMARY_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }
    case Types.RESET_WORKOUT_SUMMARY_STATE: {
      return Object.assign({}, state, {
        ...INITIAL_STATE,
      });
    }

    case Types.GET_ALL_MISSED_WORKOUT_SUMMARY_SUCCESS: {
      const { data, total_missed, total_completed, is_processing_data } = payload;
      if (is_processing_data) {
        return Object.assign({}, state, {
          isProcessingData: is_processing_data,
          loading: true,
        });
      }

      return Object.assign({}, state, {
        allMissedWorkouts: data,
        totalMissed: total_missed,
        totalCompleted: total_completed,
        isProcessingData: false,
      });
    }

    default:
      return state;
  }
};
