import { Types } from './actions';
import _ from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';
import trainingReducer from './training/reducer';
import taskReducer from './task/reducer';
import clientReducer from './client/reducer';
import settings from './settings/reducer';
import autoMessage from './autoMessage/reducer';
import leaderboard from './leaderboard/reducer';
import forum from './forum/reducer';
import { AUTOFLOW_STATUS, CALENDAR_TYPES } from 'constants/commonData';
import { Types as IntervalTypes } from 'redux/autoflowInterval/actions';

const DEFAULT_CALENDAR_VIEW = CALENDAR_TYPES[1];

const INITITAL_STATE = {
  list: [],
  total: 0,
  query: {
    search: '',
    page: 1,
    per_page: 50,
    sort: -1,
    sorter: 'last_used',
  },
  workingAutoflow: null,
  clientStatistic: {},
  viewMode: DEFAULT_CALENDAR_VIEW.value,
  startDate: moment().startOf('isoWeek').format('MM-DD-YYYY'),
};

const common = (state = INITITAL_STATE, action) => {
  const { payload, type } = action;
  const { list, query } = state;

  switch (type) {
    case Types.AUTOFLOW_CHANGE_QUERY_PARAM:
      return Object.assign({}, state, { query: { ...query, ...payload.newQuery } });

    case Types.SUCCESS_GET_AUTOFLOW_LIST:
      return Object.assign({}, state, {
        list: payload.list,
        total: isNaN(parseInt(payload.total)) ? 0 : payload.total,
      });

    case Types.RESET_AUTOFLOW_DETAIL_DATA:
      return INITITAL_STATE;

    case Types.CHANGE_WORKING_AUTOFLOW_DETAIL:
    case Types.SUCCESS_GET_AUTOFLOW_DETAIL:
      return Object.assign({}, state, { workingAutoflow: payload.data });

    case Types.AUTOFLOW_SUCCESS_RESUME:
      if (state.workingAutoflow && state.workingAutoflow._id === payload.data) {
        return Object.assign({}, state, {
          workingAutoflow: { ...state.workingAutoflow, status: AUTOFLOW_STATUS.ACTIVATED },
        });
      } else {
        return state;
      }

    case Types.AUTOFLOW_SUCCESS_PAUSE:
      if (state.workingAutoflow && state.workingAutoflow._id === payload.data) {
        return Object.assign({}, state, {
          workingAutoflow: { ...state.workingAutoflow, status: AUTOFLOW_STATUS.PAUSED },
        });
      } else {
        return state;
      }

    case Types.AUTOFLOW_SUCCESS_COUNT_CLIENTS:
      return Object.assign({}, state, { clientStatistic: payload.data });

    case Types.AUTOGLOW_CHANGE_VIEW_MODE:
      return Object.assign({}, state, { viewMode: payload.mode });

    case Types.AUTOGLOW_CHANGE_START_DATE:
      return Object.assign({}, state, { startDate: payload.startDate });

    case Types.AUTOFLOW_RESET_QUERY_DATA:
      return Object.assign({}, state, { query: INITITAL_STATE.query });

    case Types.AUTOFLOW_TURN_OFF_SETTING_SUCCESSFULLY:
      if (_.get(state, 'workingAutoflow._id') === payload.autoflow) {
        return Object.assign({}, state, { workingAutoflow: { ...state.workingAutoflow, isSettingTurnedOff: true } });
      }

      return state;

    case IntervalTypes.AUTOFLOW_INTERVAL_REMOVE_WEEK:
    case IntervalTypes.AUTOFLOW_INTERVAL_ADD_WEEK:
      if (state.workingAutoflow && state.workingAutoflow._id === payload.data._id) {
        return Object.assign({}, state, { workingAutoflow: { ...state.workingAutoflow, ...payload.data } });
      }

      return state;

    default:
      return state;
  }
};

const autoflow = combineReducers({
  common,
  settings,
  autoMessage,
  training: trainingReducer,
  task: taskReducer,
  client: clientReducer,
  leaderboard,
  forum,
});

export default autoflow;
