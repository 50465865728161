// libs
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Modal, Button as CloseButton } from 'semantic-ui-react';

// constants
import { ONBOARDING_FLOW_SELECT_MODE } from 'shared/OnboardingFlowSelect';

// assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';

// styles
import * as S from './style';

const options = [
  { _id: 'my_default', label: "Use the Coach's default Onboarding Flow" },
  { _id: 'workspace_default', label: 'Use the workspace default Onboarding Flow' },
  { _id: 'not_using', label: 'Do not use an Onboarding Flow' },
];

const OnboardingFlowRemoveModal = ({ onClose, isHasMyDefault, isHasWorkspaceDefault, onSubmit, mode }) => {
  const newOptions = useMemo(
    () =>
      options.filter(({ _id }) => {
        if (isHasMyDefault && isHasWorkspaceDefault) return true;
        if (isHasMyDefault && !isHasWorkspaceDefault) return _id !== 'workspace_default';
        if (!isHasMyDefault && isHasWorkspaceDefault) return _id !== 'my_default';
        return _id !== 'my_default' && _id !== 'workspace_default';
      }),
    [isHasMyDefault, isHasWorkspaceDefault],
  );

  const [selected, setSelected] = useState({});

  useEffect(() => {
    const option = newOptions.filter(({ _id }) => {
      if (isHasMyDefault) return _id === 'my_default';
      if (isHasWorkspaceDefault) return _id === 'workspace_default';
      return _id === 'not_using';
    });
    setSelected(...option);
  }, [newOptions]);

  return (
    <S.CustomModal open={true} onClose={onClose} closeOnDimmerClick={false}>
      <Modal.Header>
        <S.HeaderWrapper>
          <img src="/images/trash-circle.svg" alt="trash-icon" className="trash-icon" />
          <S.HeaderTitle>Remove Onboarding Flow?</S.HeaderTitle>
          <CloseButton className="close-button" onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <S.Content>
            Please choose one of the following options if you want to remove the current onboarding flow from this{' '}
            {mode === ONBOARDING_FLOW_SELECT_MODE.INVITE_CLIENT ? 'client' : 'package'}:
          </S.Content>
          <S.CheckboxWrapper>
            {newOptions.map(item => {
              const { _id, label } = item || {};
              const isSelected = (selected || {})._id === _id;

              return (
                <S.Item key={_id}>
                  <div className="radio-wrapper">
                    <span
                      className={classNames('radio-button', { checked: isSelected })}
                      onClick={() => setSelected(item)}
                    />
                  </div>
                  <span className="radio-label" onClick={() => setSelected(item)}>
                    {label}
                  </span>
                </S.Item>
              );
            })}
          </S.CheckboxWrapper>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ActionsWrapper>
          <S.Button className="cancel" onClick={onClose}>
            Cancel
          </S.Button>
          <S.Button
            className="confirm"
            onClick={() => {
              onSubmit(selected);
              onClose();
            }}
          >
            Confirm & Remove
          </S.Button>
        </S.ActionsWrapper>
      </Modal.Actions>
    </S.CustomModal>
  );
};

export default OnboardingFlowRemoveModal;
