// Libs
import React, { useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { getListUnitIngredient } from 'redux/ingredient-library/actions';

// Assets
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_bold.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/MealPlans/check_blue.svg';

// Style
import * as S from './style';

/**
 * @typedef {Object} UnitSelectProps
 * @property {Object} data
 * data = {
 *  imperials: [
 *    {
 *      group_name: 'volumne',
 *      units: [
 *         _id: '653b2510bb147b001efdb6b9',
          name: 'Teaspoon',
          ...
 *      ],
 *    }
 *  ],
 *  metrics: [
 *    {
      group_name: 'volume',
      units: [
        {
          _id: '653b2510bb147b001efdb6b7',
          name: 'Milliliter',
          ...
        },
      ],
    },
 *  ],
 *  others: 
 *    {
      _id: '65a805ad4348211a82f26cb4',
      name: 'Almond',
      ...
    }
 *  ],
 * }
 * @property {string} value - value = '653b2510bb147b001efdb6b9';
 * @property {function} onChangeUnit 
 * @property {string} label 
 * @property {string} placeholder 
 * @property {string} placeholderInputSearch
 * @property {string} textNoResult
 * @property {boolean} hasError
 * @property {boolean} isMulti
 */

/**
 * Component select unit.
 * @param {UnitSelectProps} props - Props của component.
 * @returns {JSX.Element} - Return the JSX interface of the component.
 */

const enumTab = {
  IMPERIALS: 'imperials',
  METRICS: 'metrics',
  OTHERS: 'others',
};

const listTab = [
  {
    label: 'Imperial',
    key: enumTab.IMPERIALS,
  },
  {
    label: 'Metric',
    key: enumTab.METRICS,
  },
  {
    label: 'Other',
    key: enumTab.OTHERS,
  },
];

const UnitSelect = props => {
  const {
    data = {},
    value = '',
    onChangeUnit = () => {},
    label = 'default unit',
    placeholder = 'e.g. Cup, Gram...',
    placeholderInputSearch = 'Search unit',
    textNoResult = 'No results found.',
    hasError = false,
    isMulti = false,
    heightSelect = '36px',
    menuPositionHorizontal = 'left',
    acronymSelect = false,
    isObjectSubmit = false,
    unitType = '',
    oldName = '',
    loading = false,
    listUnitIngredient = {},
    getListUnitIngredient,
  } = props;
  const selectGroupRef = useRef();
  const listUnitRef = useRef();

  const [listUnit, setListUnit] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selected, setSelected] = useState(value);
  const [textSearch, setTextSearch] = useState('');
  const [tabActive, setTabActive] = useState(() => {
    const unitTypeItem = listTab.find(({ key }) => key === unitType);
    return !isEmpty(unitTypeItem) ? unitTypeItem : listTab[0];
  });

  const updateSelectGroupStyles = () => {
    const selectGroup = selectGroupRef.current;
    if (selectGroup) {
      const rect = selectGroup.getBoundingClientRect();
      const isDropdownVisible = window.innerHeight < rect.bottom;

      selectGroup.style.visibility = 'visible';

      if (isDropdownVisible) {
        selectGroup.style.top = 'unset';
        selectGroup.style.bottom = 'calc(100% + 8px)';
        selectGroup.style.paddingBottom = '0';
      }
    }
  };

  useEffect(() => {
    if (isEmpty(listUnitIngredient) && openDropdown) {
      typeof getListUnitIngredient === 'function' && getListUnitIngredient();
    }
  }, [openDropdown, listUnitIngredient]);

  useEffect(() => {
    updateSelectGroupStyles();
    if (!openDropdown && textSearch) {
      handleClearSearch();
    }
  }, [openDropdown]);

  useEffect(() => {
    const resultListUnit = tabActive.key === enumTab.OTHERS ? [{ units: data[tabActive.key] }] : data[tabActive.key];
    setListUnit(resultListUnit || []);
  }, [tabActive, data, openDropdown]);

  useEffect(() => {
    const itemTop = document.getElementById(`item-unit-${value}`);
    if (itemTop && listUnitRef.current) {
      const listItemRect = itemTop.getBoundingClientRect();
      const listUnitRect = listUnitRef.current.getBoundingClientRect();
      const scrollPosition = listItemRect.top - listUnitRect.top + listUnitRef.current.scrollTop;
      listUnitRef.current.scrollTop = scrollPosition;
    } else {
      if (listUnitRef.current) {
        listUnitRef.current.scrollTop = 0;
      }
    }
  }, [openDropdown, tabActive]);

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
    if (isMulti) {
      setTimeout(() => {
        const element = selectGroupRef.current;
        if (element) {
          const rect = element.getBoundingClientRect();
          const { x, y } = rect;
          element.style.left = `${x}px`;
          element.style.top = `${y}px`;
          element.style.width = `197px`;
          element.style.position = 'fixed';
        }
      }, 0);
    }
  };

  const handleClosePopUp = () => {
    setOpenDropdown(false);
  };

  const handleOnClick = unit => {
    const { _id = '' } = unit || {};
    setSelected(_id);
    setOpenDropdown(false);
    onChangeUnit(isObjectSubmit ? unit : _id);
  };

  const handleSearchText = (_, { value = '' }) => {
    setTextSearch(value);
    const searchResult = !isEmpty(findUnitsByName(data, value)) ? [{ units: findUnitsByName(data, value) }] : [];
    setListUnit(searchResult);
  };

  const handleClearSearch = () => {
    setTextSearch('');
    const result = tabActive.key === enumTab.OTHERS ? [{ units: data[tabActive.key] }] : data[tabActive.key];
    setListUnit(result);
  };

  const handleChangeTab = item => {
    setTabActive(item);

    if (textSearch) {
      const searchResult = !isEmpty(findUnitsByName(data, textSearch))
        ? [{ units: findUnitsByName(data, textSearch) }]
        : [];
      setListUnit(searchResult);
    } else {
      const resultListUnit = item.key === enumTab.OTHERS ? [{ units: data[item.key] }] : data[item.key];
      setListUnit(resultListUnit || []);
    }
  };

  const findSelectedUnitById = (data, id) => {
    if (isEmpty(data)) return null;

    const searchInGroups = groups => {
      for (const group of groups) {
        for (const unit of group.units) {
          if (unit._id === id) {
            return unit;
          }
        }
      }
      return null;
    };

    for (const key of [enumTab.IMPERIALS, enumTab.METRICS]) {
      const foundUnit = searchInGroups(data[key]);
      if (foundUnit) return foundUnit;
    }

    return data[enumTab.OTHERS].find(item => item._id === id) || null;
  };

  const findUnitsByName = (data, name) => {
    if (isEmpty(data)) {
      return null;
    }

    const searchString = name.trim().toLowerCase();

    const searchInGroups = groups => {
      return groups.reduce((foundUnits, group) => {
        return foundUnits.concat(group.units.filter(unit => unit.name.toLowerCase().includes(searchString)));
      }, []);
    };

    const unitsInImperialsAndMetrics = searchInGroups(data.imperials).concat(searchInGroups(data.metrics));
    const unitsInOthers = data.others.filter(item => item.name.toLowerCase().includes(searchString));

    const foundUnits = unitsInImperialsAndMetrics.concat(unitsInOthers);

    return foundUnits.length > 0 ? foundUnits : null;
  };

  const renderInputSearch = () => {
    return (
      <S.InputSearch
        placeholder={placeholderInputSearch}
        onChange={debounce(handleSearchText, 300)}
        onClearSearch={handleClearSearch}
      />
    );
  };

  const renderTab = () => {
    return (
      <S.Tabs>
        {listTab.map(item => {
          const { label, key } = item || {};
          const active = tabActive.key === key;
          return (
            <div key={key} className={classNames('tab-item', { active })} onClick={() => handleChangeTab(item)}>
              {label}
            </div>
          );
        })}
      </S.Tabs>
    );
  };

  const selectedUnit = findSelectedUnitById(data, selected);
  const triggerLabel = !isEmpty(selectedUnit) ? (acronymSelect ? selectedUnit.formula : selectedUnit.name) : undefined;

  const renderList = () => {
    return (
      <S.List hasSearch={textSearch} ref={listUnitRef}>
        {loading ? (
          <S.Loading />
        ) : textSearch && get(listUnit, 'length', 0) <= 0 ? (
          <span className="no-result">{textNoResult}</span>
        ) : (
          <>
            {listUnit.map((item, index) => {
              const { group_name = '', units } = item;
              if (get(units, 'length', 0) > 0)
                return (
                  <S.Group key={`${group_name}-${index}`}>
                    <div className={classNames('name-group', { 'custom-padding': textSearch })}>{group_name}</div>
                    {units.map(itemChild => {
                      const { _id = '', name = '' } = itemChild || {};
                      const active = value === _id;
                      return (
                        <S.Item
                          active={active}
                          onClick={() => handleOnClick(itemChild)}
                          key={_id}
                          id={`item-unit-${_id}`}
                        >
                          {name}
                          {active && <CheckIcon />}
                        </S.Item>
                      );
                    })}
                  </S.Group>
                );
              return null;
            })}
          </>
        )}
      </S.List>
    );
  };

  return (
    <S.Wrapper>
      {label && <S.Label>{label}</S.Label>}
      <S.TriggerWrapper
        heightSelect={heightSelect}
        onClick={handleOpenDropdown}
        openDropdown={openDropdown}
        hasError={hasError}
        className="unit-select-trigger-wrapper"
      >
        {triggerLabel || (oldName ? oldName : <span className="text-placeholder">{placeholder}</span>)}
        <ArrowDownIcon />
      </S.TriggerWrapper>
      {openDropdown && (
        <RootCloseWrapper onRootClose={handleClosePopUp}>
          <S.ListWrapper ref={selectGroupRef} menuPositionHorizontal={menuPositionHorizontal}>
            <S.ListContent>
              {renderInputSearch()}
              {!textSearch && renderTab()}
              {renderList()}
            </S.ListContent>
          </S.ListWrapper>
        </RootCloseWrapper>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { ingredientLibrary },
  } = state;

  const { listUnitIngredient = {} } = ingredientLibrary || {};

  return { listUnitIngredient };
};

const mapDispatchToProps = dispatch => ({
  getListUnitIngredient: bindActionCreators(getListUnitIngredient, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitSelect);
