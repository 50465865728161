import React, { Component } from 'react';
import { clone } from 'lodash';
import { convertFromRaw, convertToRaw, EditorState, ContentState, Modifier } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import Avatar from 'react-avatar';
import {
  getRole,
  formatMentions,
  initData,
  suggestionsFilter,
  getTaggedPeople,
  isTaggedEvery,
} from 'components/CommunityForum/helper';
import * as S from './style';
import 'draft-js-mention-plugin/lib/plugin.css';

const EntryComponent = props => {
  const { mention } = props;
  return (
    <div {...props} className="mention-item">
      <div className="item">
        <Avatar
          className="client-detail-avatar"
          size="24"
          textSizeRatio={1.5}
          name={mention.name}
          src={mention.avatar}
          color={mention.color}
        />
        <div className="name">{mention.name}</div>
        {mention.role > 0 && <div className="role">{getRole(mention)}</div>}
      </div>
    </div>
  );
};

const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });

class PostEditor extends Component {
  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true,
    });
  }

  state = {
    editorState: this.props.initEditor
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(initData(this.props.initEditor))))
      : EditorState.createEmpty(),
    suggestions: formatMentions(this.props.members),
  };

  componentDidMount() {
    const { callBackFunction, autoFocus } = this.props;

    callBackFunction && callBackFunction(this.resetFunction);
    if (autoFocus) {
      setTimeout(this.editor.focus, 0);
    }
  }

  onChange = editorState => {
    const { onInputChange } = this.props;

    if (typeof onInputChange === 'function') {
      const valueEditor = String(editorState.getCurrentContent().getPlainText());

      const entityMap = convertToRaw(editorState.getCurrentContent()).entityMap;
      const taggedFromEntityMap = Object.values(entityMap);
      const taggedPeople = taggedFromEntityMap.map(it => it.data.mention);

      onInputChange(valueEditor, taggedPeople);
    }
    this.setState({
      editorState: editorState,
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      const { onSubmit } = this.props;
      onSubmit && onSubmit();
    }
  };

  onSearchChange = ({ value, trigger }) => {
    const ableAddMembers = this.ableAddMembers();
    this.setState({
      suggestions: suggestionsFilter(
        value,
        formatMentions(ableAddMembers, this.hasEveryone()),
        ableAddMembers.length + 1,
      ),
    });
  };

  ableAddMembers = () => {
    const { members } = this.props;
    const editor = clone(this.state.editorState);
    const taggedIds = getTaggedPeople(editor);
    const results = members.filter(it => !taggedIds.includes(it._id));
    return results;
  };

  hasEveryone = () => {
    const editor = clone(this.state.editorState);
    const taggedIds = getTaggedPeople(editor);
    return isTaggedEvery(taggedIds);
  };

  resetFunction = () => {
    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState });
    setTimeout(() => {
      this.editor && this.focus();
    }, 500);
  };

  onAddMention = data => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  handleBlurEditContainer = () => {
    if (this.editor) {
      this.editor.blur();
    }
    return;
  };

  handleBeforeInput = (chars, editorState) => {
    if (chars === '. ') {
      const currentSelection = editorState.getSelection();
      this.setState({
        editorState: EditorState.set(editorState, {
          currentContent: Modifier.replaceText(editorState.getCurrentContent(), currentSelection, ' '),
        }),
      });
      return 'handled';
    }
    return 'not-handled';
  };

  handlePastedText = (text, __, editorState) => {
    const { onInputChange } = this.props;

    try {
      const formatText = text.trim().trimAny('↵');
      const contentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        ContentState.createFromText(formatText).getBlockMap(),
      );
      const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
      this.setState({ editorState: newEditorState });

      if (typeof onInputChange === 'function') {
        const valueEditor = String(newEditorState.getCurrentContent().getPlainText());

        const entityMap = convertToRaw(newEditorState.getCurrentContent()).entityMap;
        const taggedFromEntityMap = Object.values(entityMap);
        const taggedPeople = taggedFromEntityMap.map(it => it.data.mention);

        onInputChange(valueEditor, taggedPeople);
      }

      return 'handled';
    } catch (error) {}

    return 'not-handled';
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, linkifyPlugin];
    const { placeholder, customClassName = '', isLeaderboard, isSchedulePost, enableForumPoll } = this.props;
    return (
      <S.MentionInput
        onClick={this.focus}
        className={customClassName}
        isLeaderboard={isLeaderboard}
        isSchedulePost={isSchedulePost}
        enableForumPoll={enableForumPoll}
      >
        <S.MentionEditorWrapper>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            editorKey="mention-post"
            plugins={plugins}
            ref={element => {
              this.editor = element;
            }}
            spellCheck={true}
            stripPastedStyles={true}
            placeholder={placeholder}
            keyBindingFn={this.handleKeyPress}
            onBlur={this.handleBlurEditContainer}
            handleBeforeInput={this.handleBeforeInput}
            handlePastedText={this.handlePastedText}
          />
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
            onAddMention={this.onAddMention}
            entryComponent={EntryComponent}
          />
        </S.MentionEditorWrapper>
      </S.MentionInput>
    );
  }
}

export default PostEditor;
