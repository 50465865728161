import React, { useState, useEffect, useRef } from 'react';
import diff from 'deep-diff';
import _ from 'lodash';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import { ReactComponent as DropdownIcon } from 'assets/icons/arrow-select-down-middle.svg';
import { WORKOUT_VISIBILITY } from 'constants/commonData';
import { useOnClickOutside } from 'utils/commonFunction';

import * as S from './style';

const DATE_RANGE_NEXT_WEEK = [
  { key: WORKOUT_VISIBILITY.NEXT_TWO_WEEK, label: '+2 Weeks' },
  { key: WORKOUT_VISIBILITY.NEXT_THREE_WEEK, label: '+3 Weeks' },
  { key: WORKOUT_VISIBILITY.NEXT_FOUR_WEEK, label: '+4 Weeks' },
];

function DateRangeNextWeek({ settings, onRangeClick, onSetSettings }) {
  const [nextWeekList, setNextWeekList] = useState([]);
  const [activeNextWeekItem, setActiveNextWeekItem] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const type = _.map(DATE_RANGE_NEXT_WEEK, item => item.key);
    let list = [];

    _.forEach(settings, (item, idx) => {
      if (type.includes(item.type)) {
        list.push({ ...settings[idx], label: handleGetLabel(settings[idx].type), value: settings[idx].type });
      }
    });

    // Active Selected
    const activeSelected = _.find(list, o => o.state);

    if (activeSelected) {
      setActiveNextWeekItem(activeSelected);
    } else {
      let element = {};

      if (list.length > 0) {
        const currentSelect = activeSelected ? activeSelected : list[0];
        element = { ...currentSelect, state: false };
      }
      setActiveNextWeekItem(element);
    }

    setNextWeekList(list);
  }, [settings]);

  const handleGetLabel = type => {
    let label = '';

    _.forEach(DATE_RANGE_NEXT_WEEK, item => {
      if (item.key === type) {
        label = item.label;
      }
    });

    return label;
  };

  const DropdownIndicator = () => {
    return (
      <S.DropdownArrowArea onClick={() => setIsOpen(!isOpen)}>
        <DropdownIcon />
      </S.DropdownArrowArea>
    );
  };

  const handleClick = selected => {
    handleChange(selected);
    setIsOpen(false);
  };

  const SingleValue = props => {
    const selected = props.data;
    return (
      <components.SingleValue
        {...props}
        innerProps={{
          ...props.innerProps,
          onClick: e => {
            handleClick(selected);
          },
        }}
      >
        <S.SelectValue>{selected.label}</S.SelectValue>
      </components.SingleValue>
    );
  };

  const Option = props => {
    const selected = props.data;
    return (
      <components.Option {...props}>
        <S.Wrapper>{selected.label}</S.Wrapper>
      </components.Option>
    );
  };

  const handleChange = val => {
    let data = _.find(nextWeekList, o => o.type === val.value);
    let newNextWeekList = nextWeekList;
    let newSettings = settings;
    const type = _.map(DATE_RANGE_NEXT_WEEK, item => item.key);

    data = { ...data, state: !data.state };

    _.forEach(newNextWeekList, (item, idx) => {
      if (item.type === data.type) {
        newNextWeekList[idx] = { ...newNextWeekList[idx], state: data.state };
      } else {
        newNextWeekList[idx] = { ...newNextWeekList[idx], state: false };
      }
    });

    _.forEach(newSettings, (item, idx) => {
      if (type.includes(item.type)) {
        _.forEach(newNextWeekList, ele => {
          if (ele.type === item.type) {
            newSettings[idx] = { ...newSettings[idx], state: ele.state };
          }
        });
      }
    });

    onSetSettings(newSettings);
    setActiveNextWeekItem(data);

    data = { ...data, state: !data.state };
    onRangeClick(data);
  };

  const handleChangeWeek = val => {
    // Close dropdown
    setIsOpen(false);

    // Update selected
    if (!diff(val, activeNextWeekItem)) {
      if (_.get(activeNextWeekItem, 'state', false)) return;
    }
    handleChange(val);
  };

  useOnClickOutside({ current: _.get(ref, 'current.select.inputRef', false) }, e => {
    if (e.target.closest('.next-week-dropdown__option')) return;
    setIsOpen(false);
  });

  return (
    <Select
      ref={ref}
      menuIsOpen={isOpen}
      className={classNames('item dropdown', {
        active: activeNextWeekItem.state,
        disabled: !activeNextWeekItem.editable,
        isOpen: isOpen,
      })}
      placeholder={'custom placeholder component'}
      classNamePrefix="next-week-dropdown"
      value={activeNextWeekItem}
      options={nextWeekList}
      isSearchable={false}
      components={{ IndicatorSeparator: null, DropdownIndicator, SingleValue, Option }}
      onChange={handleChangeWeek}
    />
  );
}

export default DateRangeNextWeek;
