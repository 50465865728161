import styled from 'styled-components';

const baseText = `
  font-style: normal;
  font-weight: 600;
  color: #202353;
`;

export const Insight = styled.div``;

export const Title = styled.h3`
  ${baseText}
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #202353;
  margin-bottom: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => props.mB || '0'};
  gap: ${props => props.gap || '10px'};
`;

export const Item = styled.div`
  flex: ${props => (props.width ? 'none' : 'auto')};
  width: ${props => props.width || 'min-content'};
`;
