import React from 'react';
import { RootCloseWrapper } from 'react-overlays';
import get from 'lodash/get';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_currentColor.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/check-mark-freestyle-section.svg';

import * as S from './style';

const SORT_BY = [
  { label: 'Most recent', value: 'updated_at' },
  { label: 'Alphabetical', value: 'name_lowercase' },
];

const ListSorter = props => {
  const { setShowSortPopup, handleSelectSort, sortBy, showSortPopup } = props;
  const activeLabel = get(
    SORT_BY.find(item => item.value === sortBy),
    'label',
    false,
  );

  return (
    <S.OrderBy>
      <S.SortLabel>Sort: </S.SortLabel>
      <S.SortTrigger onClick={() => setShowSortPopup(!showSortPopup)} active={showSortPopup}>
        {activeLabel && activeLabel}
        <ArrowDownIcon className="arrow-down-icon" />
      </S.SortTrigger>
      {showSortPopup && (
        <RootCloseWrapper event="click" onRootClose={() => setShowSortPopup(false)}>
          <S.SortPopup>
            {SORT_BY.map(item => {
              return (
                <S.SortItem
                  onClick={() => handleSelectSort(item.value)}
                  active={sortBy === item.value}
                  key={item.value}
                >
                  <div className="sort-item-label">{item.label}</div>
                  {sortBy === item.value && <CheckedIcon className="checked-icon" />}
                </S.SortItem>
              );
            })}
          </S.SortPopup>
        </RootCloseWrapper>
      )}
    </S.OrderBy>
  );
};

export default ListSorter;
