import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import AsyncSelect from 'react-select/lib/Async';
import SelectClientOption from 'shared/SelectClientOption';
import { searchClients, searchGroupClients, searchGroupClientList } from 'redux/forum/member/actions';
import { CLIENT_STATUS } from 'constants/commonData';
import * as S from './style';
import { connect } from 'react-redux';
import { selectStyles } from 'shared/SearchGroupName';

function SelectClients(props) {
  const { placeholder, currentEmail, members, onSetAssignToGroup, user } = props;
  const [clients, setClients] = useState([]);
  const [assignToGroup, setAssignToGroup] = useState([]);

  const searchClient = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    props
      .searchClients(assignToGroup, search)
      .then(response => {
        const { data } = response.data;

        if (data) {
          if (data.length > 0) {
            let member = data;

            if (members) {
              const currentMember = _.map(members, item => item._id);
              member = _.filter(member, item => !currentMember.includes(item._id));
            } else if (currentEmail) member = _.filter(data, item => item._id !== user._id);

            callback(
              _.map(member, item => ({
                ...item,
                key: item._id,
                value: item._id,
                label: `${item.first_name} ${item.last_name}`,
                typeName: 'client',
              })),
            );
          } else {
            searchGroupClient(inputValue, callback);
          }
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const searchGroupClient = (inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    const searchAll = inputValue.toLowerCase() === 'group' ? true : false;

    props
      .searchGroupClientList({ txt_search: searchAll ? '' : search })
      .then(response => {
        const { data } = response.data;

        if (data) {
          const { data } = response.data;
          const clients = data.clients.map(
            o =>
              o.email !== currentEmail && {
                ...o,
                typeName: 'client',
                name: `${o.first_name} ${o.last_name}`,
              },
          );
          let groups = data.groups.map(g => ({
            ...g,
            typeName: 'group',
          }));
          const results = _.orderBy([...clients, ...groups], ['typeName'], ['asc']).filter(
            item =>
              item.typeName === 'group' ||
              ((item.client_connection === CLIENT_STATUS.connected ||
                item.client_connection === CLIENT_STATUS.pending) &&
                !item.is_archived),
          );

          callback(
            _.map(searchAll ? groups : results, item => ({
              ...item,
              key: item._id,
              value: item._id,
              label: item.typeName === 'group' ? `${item.name} (${item.total_clients} clients)` : item.name,
              total: item.total_clients,
              typeName: item.typeName,
            })),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  /**
   * handleOnChange: On Change when select
   * @param {*} list: list result
   */
  const handleOnChange = list => {
    // Get member on list (assignToGroup)
    list
      .filter(item => item.typeName === 'group')
      .map(item => {
        props.searchGroupClientList({ group: item._id }).then(response => {
          _.map(response.data.data.clients, item => {
            assignToGroup.push(item);
          });
        });
      });

    // Set clients list
    setClients(list);

    // Set get member on list
    setAssignToGroup(assignToGroup);
    if (onSetAssignToGroup) onSetAssignToGroup(assignToGroup);

    // Select client list
    props.onSelectClient(list);
  };

  const searchClientDebounce = _.debounce(searchClient, 200);

  return (
    <S.Wrapper>
      <S.Content>
        <AsyncSelect
          isMulti
          defaultOptions
          cacheOptions
          components={{ Option: SelectClientOption }}
          loadOptions={(inputValue, callback) => searchClientDebounce.call(this, inputValue, callback)}
          onChange={list => handleOnChange(list)}
          className={`multi-select-container select-client--select-input`}
          classNamePrefix="multi-select"
          placeholder={placeholder || ''}
          noOptionsMessage={() => 'No clients found.'}
          styles={selectStyles}
        />
      </S.Content>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  return {
    autoflow: workingAutoflow ? workingAutoflow._id : '',
    user,
  };
};

export default connect(mapStateToProps, { searchClients, searchGroupClients, searchGroupClientList })(SelectClients);
