import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { KEY_CODE } from 'constants/commonData';
import { setCaretToEnd, trimEmptyLines } from 'utils/commonFunction';
import {
  AI_TEXT_EXAMPLE,
  AI_JSON_EXAMPLE,
  AI_TEXT_EXAMPLE_TRIMMED,
  ONBOARDING_AI_TEXT_EXAMPLE,
  ONBOARDING_AI_TEXT_EXAMPLE_TRIMMED,
} from 'components/WorkoutDetailModalAIDemo/constants';
import ConfirmModalGroup from 'components/MetricGroupLibrary/Parts/ConfirmModal';

import { Mixpanel } from 'utils/mixplanel';

// assets
import { ReactComponent as StarsAIIcon } from 'assets/icons/star-3.svg';
import { ReactComponent as StarMagicIcon } from 'assets/icons/star_magic.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send-icon.svg';
import StarRotate from 'assets/gifs/ai_star_rotate.gif';

import { TextEditable } from '../style';

const ChartInput = ({
  onSendMessage,
  isSending,
  isFirstMessage,
  showExample = false,
  scrollToEnd,
  disabled,
  workoutAIPage,
  isOnboardingTour,
  dispatch,
  toggleConfirmModal,
}) => {
  const inputRef = useRef(null);
  const [hasText, setHasText] = useState(isOnboardingTour);
  const [text, setText] = useState('');
  const [isUseExample, setIsUseExample] = useState(false);
  const [isOnboardingTourState] = useState(isOnboardingTour);

  const onSubmit = async () => {
    if (!isSending || inputRef.current || !disabled) {
      const value = String(inputRef.current.innerText).trim();
      const trimValue = trimEmptyLines(value.trimAny('↵'));
      const isUseOnboardingExample = trimValue === ONBOARDING_AI_TEXT_EXAMPLE_TRIMMED;

      if (trimValue) {
        Mixpanel.track('generate_ai_workout_builder', { component: workoutAIPage });
        onSendMessage(
          (isUseExample || trimValue === AI_TEXT_EXAMPLE_TRIMMED) && !isUseOnboardingExample
            ? JSON.parse(JSON.stringify(AI_JSON_EXAMPLE))
            : trimValue,
        );
        if (!isFirstMessage) {
          inputRef.current.innerText = '';
        }
        inputRef.current.blur();
        setHasText(false);
      }
    }
  };

  const handleCopyExample = () => {
    if (inputRef.current) {
      inputRef.current.innerText = AI_TEXT_EXAMPLE;
      setHasText(true);
      setText(AI_TEXT_EXAMPLE);
      scrollDownAfterPasteInput();
      setIsUseExample(true);
      Mixpanel.track('use_example_ai_workout_builder', { component: workoutAIPage });
      setTimeout(() => {
        inputRef.current.focus();
        setCaretToEnd(inputRef.current);
      }, 100);
    }
  };

  const handleOpenModalTryExample = () => {
    if (!hasText) {
      handleCopyExample();
    } else {
      dispatch(
        toggleConfirmModal(
          true,
          <ConfirmModalGroup
            modalId="try-example-prompt-modal"
            className="try-example-prompt"
            title="Use example workout prompt"
            content="It will erase all the text you have inputted. Are you sure you want to proceed?"
            onConfirm={handleCopyExample}
            hasCloseIcon
            noIcon
            confirmButtonTitle="Yes, try example prompt"
            cancelButtonTitle="No"
          />,
        ),
      );
    }
  };

  const handleClickPlaceholder = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode > 36 && keyCode < 41) {
      // press arrow key
      event.stopPropagation();
      return;
    }

    if (event.keyCode === KEY_CODE.enter && event.shiftKey) {
      event.preventDefault();
      onSubmit();
    }
  };

  const scrollDownAfterPasteInput = input => {
    setTimeout(() => {
      if (input) {
        const { scrollHeight } = input;
        const currentIndicatorPosition = getCaretTopPosition();
        const { top: inputTop, height: inputHeight } = input.getBoundingClientRect();
        const distance = inputTop + inputHeight - currentIndicatorPosition;

        if (distance < 0) {
          input.scroll({ top: scrollHeight + distance, behavior: 'smooth' });
        }
      }
    }, 100);
  };

  const getCaretTopPosition = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    if (range) {
      return range.getBoundingClientRect().top;
    }
    return 0;
  };

  const onPaste = event => {
    event.preventDefault();
    let text = '';

    if (event.clipboardData && event.clipboardData.getData) {
      text = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData && window.clipboardData.getData) {
      text = window.clipboardData.getData('Text');
    }

    document.execCommand('insertText', false, text + ' ');
    event.persist();
    setText(text);
    setIsUseExample(false);
    scrollToEnd();
    scrollDownAfterPasteInput(inputRef.current);
  };

  const onInput = event => {
    const text = event.target.innerText;
    setHasText(!!text.trim());
    setText(text.trim());
    setIsUseExample(false);
  };

  useEffect(() => {
    const inputWrapper = document.getElementById('inputWrapper');
    const loadingWrap = document.getElementById('loadingWrap');
    if (inputWrapper && loadingWrap) {
      const { height } = inputWrapper.getBoundingClientRect();
      loadingWrap.style.paddingTop = height >= 320 ? 'unset' : '48px';
    }
  }, [text]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <div
      id="inputWrapper"
      className={classNames('input-wrapper', { 'is-first': isFirstMessage, 'no-show-example': !showExample })}
    >
      <TextEditable
        contentEditable
        onKeyDown={onKeyDown}
        ref={inputRef}
        placeholder=""
        onPaste={onPaste}
        onInput={onInput}
        className="input"
        {...(isOnboardingTourState ? { dangerouslySetInnerHTML: { __html: ONBOARDING_AI_TEXT_EXAMPLE } } : {})}
      />
      {!hasText && (
        <span className="input-placeholder" onClick={handleClickPlaceholder}>
          Write your text workout with a title here
        </span>
      )}
      {isFirstMessage ? (
        <div className={classNames('input-actions', { sending: isSending })}>
          {isSending ? (
            <img src={StarRotate} className="loading-rotate" alt="" />
          ) : (
            <>
              {showExample && (
                <>
                  <ReactTooltip
                    className="app-tooltip ai-tooltip"
                    id="btn-example-wk-tooltip"
                    effect="solid"
                    place="top"
                    delayShow={100}
                  >
                    Click here to learn how to write a workout prompt
                  </ReactTooltip>
                  <button
                    className="ai-btn copy-btn"
                    onClick={handleOpenModalTryExample}
                    data-for="btn-example-wk-tooltip"
                    data-tip
                  >
                    <StarMagicIcon />
                    Try an example here
                  </button>
                </>
              )}
              <ReactTooltip
                className="app-tooltip ai-tooltip"
                id="btn-generate-wk-tooltip"
                effect="solid"
                place="top"
                delayShow={100}
              >
                Click Shift + Enter to submit
              </ReactTooltip>
              <button
                className={classNames('ai-btn generate-btn')}
                data-for="btn-generate-wk-tooltip"
                data-tip
                disabled={disabled}
                onClick={onSubmit}
              >
                <StarsAIIcon />
                Generate
              </button>
            </>
          )}
        </div>
      ) : (
        <>
          {hasText && (
            <span className="icon-wrap" onClick={onSubmit}>
              <SendIcon />
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default ChartInput;
