import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import { bindActionCreators } from 'redux';

import { toggleModal } from 'actions/modal';
import SetOneRepMaxModal from './SetOneRepMaxModal';

import { SetOneRepMaxButton } from './style';

const SetOneRepMax = props => {
  const onOpenModal = () => {
    props.toggleModal(true, <SetOneRepMaxModal {...props} />);
  };
  return <SetOneRepMaxButton onClick={onOpenModal}>Set 1RM</SetOneRepMaxButton>;
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(SetOneRepMax);
