import { orderBy } from 'lodash';
import Request from 'configs/request';
import { CLIENT_STATUS, CLIENT_OWNER_SHIP } from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';
import { setTotalActiveClients } from 'redux/client/client.actionCreators';
import { getAllSegments } from 'redux/segment/actions';

export const Types = {
  WAITING_CLIENTS_SORT: 'WAITING_CLIENTS_SORT',
  WAITING_CLIENTS_GET_LIST_SUCCESSFULLY: 'WAITING_CLIENTS_GET_LIST_SUCCESSFULLY',
  WAITING_CLIENTS_GET_LIST: 'WAITING_CLIENTS_GET_LIST',
  WAITING_CLIENTS_GET_LIST_FAILED: 'WAITING_CLIENTS_GET_LIST_FAILED',
  WAITING_CLIENTS_RESET_DATA: 'WAITING_CLIENTS_RESET_DATA',
  WAITING_CLIENT_UPDATE_SHOW_POPUP_FLAG: 'WAITING_CLIENT_UPDATE_SHOW_POPUP_FLAG',
  WAITING_CLIENT_UPDATE_TOGGLE_HAS_NEW_FLAG: 'WAITING_CLIENT_UPDATE_TOGGLE_HAS_NEW_FLAG',
};

export const sortClients = (list, { sorter, sort }) => {
  return orderBy(list, sorter, sort === 1 ? 'asc' : 'desc');
};

export const getWaitingClients = queryParams => {
  return (dispatch, getState) => {
    const currentState = getState();
    const {
      user,
      rootReducer: { waitingActivationClients, segment },
    } = currentState;

    if (waitingActivationClients.get('loading')) {
      return false;
    }

    dispatch({ type: Types.WAITING_CLIENTS_GET_LIST });

    const params = {
      page: 1,
      per_page: 300,
      client_connection: CLIENT_STATUS.waiting,
      owner_ship: isTeamAdmin(user) ? CLIENT_OWNER_SHIP.ALL : CLIENT_OWNER_SHIP.ME,
      ...queryParams,
    };

    if (!params.segmentId && segment.list && segment.list.length) {
      const waitingSegment = segment.list.find(item => item.client_connection === CLIENT_STATUS.waiting);

      if (waitingSegment) {
        params.segmentId = waitingSegment._id;
      }
    }

    return dispatch(
      Request.get(
        { url: '/api/client-management/client-list', params },
        true,
        (response, { dispatch, getState }) => {
          const { data, total, total_active_clients } = response.data;
          const currentState = getState();
          const {
            rootReducer: { waitingActivationClients },
          } = currentState;
          const sorter = waitingActivationClients.get('sorter');
          const sort = waitingActivationClients.get('sort');
          const list = sortClients(data, { sorter, sort });
          dispatch({ type: Types.WAITING_CLIENTS_GET_LIST_SUCCESSFULLY, payload: { list, total } });
          dispatch(setTotalActiveClients(total_active_clients));
        },
        () => {
          dispatch({ type: Types.WAITING_CLIENTS_GET_LIST_FAILED });
        },
      ),
    );
  };
};

export const activeClients = clientIds => {
  return Request.post(
    { url: '/api/profile/waiting-activate-clients/active', data: { clientIds } },
    true,
    (response, { dispatch }) => {
      dispatch(getWaitingClients());
      dispatch(getAllSegments());
    },
  );
};

export const deleteClients = clientIds => {
  return Request.post(
    { url: '/api/profile/waiting-activate-clients/delete', data: { clientIds } },
    true,
    (response, { dispatch }) => {
      dispatch(getWaitingClients());
      dispatch(getAllSegments());
    },
  );
};

export const sortWaitingClients = sortData => ({ type: Types.WAITING_CLIENTS_SORT, payload: sortData });

export const resetWaitingClientsData = () => ({ type: Types.WAITING_CLIENTS_RESET_DATA });

export const updateWaitingClientFlag = value => ({ type: Types.WAITING_CLIENT_UPDATE_SHOW_POPUP_FLAG, payload: value });

export const markSeenWaitingList = () => {
  return dispatch => {
    dispatch(updateWaitingClientFlag(false));
    return dispatch(Request.post({ url: '/api/profile/mark-has-waiting-client', data: { has_waiting_client: false } }));
  };
};

export const toggleNewWaitingClientFlag = value => ({
  type: Types.WAITING_CLIENT_UPDATE_TOGGLE_HAS_NEW_FLAG,
  payload: value,
});
