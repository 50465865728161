import React from 'react';
import { ActivityEmojiContainer, ActivityEmoji as ActivityEmojiEl } from './styles';

export default function ActivityEmoji(props) {
  const { emoji, backgroundColor: originalBackgroundColor } = props;
  const backgroundColor = originalBackgroundColor ? originalBackgroundColor : '#EBE0FF';
  return (
    <ActivityEmojiContainer>
      <ActivityEmojiEl backgroundColor={backgroundColor}>
        <div>{emoji}</div>
      </ActivityEmojiEl>
    </ActivityEmojiContainer>
  );
}
