import queryString from 'query-string';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import _ from 'lodash';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { logRequestError } from './commonRequest';

const REFERRAL_UTM = {
  referral: {
    leadSource: 'Referral',
    leadSourceDetail: 'Referral Program',
    referredByKey: 'referralObj._id',
  },
  affiliate: {
    leadSource: 'Referral',
    leadSourceDetail: 'Affiliate',
    referredByKey: 'referral_code',
  },
  teammate: {
    leadSource: 'Referral',
    leadSourceDetail: 'Teammate',
    referredByKey: 'team_invite_id',
  },
};

export const sendSignupData = signupData => {
  if (
    !process.env.REACT_APP_HUBSPOT_PORTALID ||
    !process.env.REACT_APP_HUBSPOT_FORMGUID ||
    !signupData ||
    !signupData.email
  )
    return;

  const queryParams = queryString.parse(decodeURIComponent(window.location.search));
  const consolidateQueryParams = queryParams => {
    if (Array.isArray(queryParams)) {
      return _.head(queryParams);
    }
    return queryParams;
  };
  const fields = [
    {
      name: 'email',
      value: signupData.email,
    },
    {
      name: 'firstname',
      value: signupData.first_name,
    },
    {
      name: 'lastname',
      value: signupData.last_name,
    },
    {
      name: 'logged_on_desktop',
      value: isMobile ? false : true,
    },
  ];

  if (process.env.REACT_APP_HUBSPOT_UTM_ENABLED) {
    if (queryParams.utm_source) {
      fields.push({
        name: 'utm_source',
        value: consolidateQueryParams(queryParams.utm_source),
      });
    }
    if (queryParams.utm_medium) {
      fields.push({
        name: 'utm_medium',
        value: consolidateQueryParams(queryParams.utm_medium),
      });
    }
    if (queryParams.utm_campaign) {
      fields.push({
        name: 'utm_campaign',
        value: consolidateQueryParams(queryParams.utm_campaign),
      });
    }
    if (queryParams.utm_content) {
      fields.push({
        name: 'utm_content',
        value: consolidateQueryParams(queryParams.utm_content),
      });
    }
    if (queryParams.utm_term) {
      fields.push({
        name: 'utm_term',
        value: consolidateQueryParams(queryParams.utm_term),
      });
    }
    if (queryParams.utm_channel) {
      fields.push({
        name: 'utm_channel',
        value: consolidateQueryParams(queryParams.utm_channel),
      });
    }
    if (queryParams.utm_page) {
      fields.push({
        name: 'utm_page',
        value: consolidateQueryParams(queryParams.utm_page),
      });
    }
    if (signupData.referredBy && signupData.referredBy !== 'teammate') {
      fields.push({
        name: 'lead_source',
        value: REFERRAL_UTM[signupData.referredBy].leadSource,
      });
      fields.push({
        name: 'lead_source_detail',
        value: REFERRAL_UTM[signupData.referredBy].leadSourceDetail,
      });
      fields.push({
        name: 'referred_by',
        value: _.get(signupData, REFERRAL_UTM[signupData.referredBy].referredByKey, ''),
      });

      if (signupData.referredBy === 'affiliate') {
        fields.push({
          name: 'referred_by_affiliate',
          value: signupData.referral_code,
        });
      }
    }
  }

  if (process.env.REACT_APP_FACEBOOK_PIXEL_REGISTER_TRACKING_ENABLED) {
    if (queryParams.utm_source && consolidateQueryParams(queryParams.utm_source) === 'facebook') {
      //Track facebook pixel complete sign up
      ReactGA.event({
        category: 'CompleteRegistration',
        action: 'Signed up from facebook',
        label: 'Facebook tracking',
      });
    }
  }

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTALID}/${process.env.REACT_APP_HUBSPOT_FORMGUID}`;
  const data = {
    submittedAt: new Date().getTime(),
    fields,
    context: {
      hutk: Cookies.get('hubspotutk'),
      pageUri: encodeURI(window.location.href),
      pageName: 'Signup page',
    },
  };

  return axios.post(url, data).catch(error => {
    logRequestError('Hubspot.sendSignupDataIssue', error.response, signupData.email);
  });
};

export const sendAdvancedCustomizationRequest = email => {
  if (!process.env.REACT_APP_HUBSPOT_PORTALID || !process.env.REACT_APP_HUBSPOT_CUSTOM_BRANDING_FORMID || !email)
    return;

  const fields = [{ name: 'email', value: email }];

  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTALID}/${process.env.REACT_APP_HUBSPOT_CUSTOM_BRANDING_FORMID}`;
  const data = {
    submittedAt: new Date().getTime(),
    fields,
    context: {
      hutk: Cookies.get('hubspotutk'),
      pageUri: encodeURI(window.location.href),
      pageName: 'Custom branding',
    },
  };

  return axios.post(url, data).catch(error => {
    logRequestError('Hubspot.customBranding.joinWailistIssue', error.response, email);
  });
};

export const sendFirstWebLoginData = userData => {
  try {
    if (!process.env.REACT_APP_HUBSPOT_PORTALID || !process.env.REACT_APP_HUBSPOT_FORMGUID) return;

    const fields = [
      {
        name: 'email',
        value: userData.email,
      },
      {
        name: 'logged_on_desktop',
        value: true,
      },
    ];

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTALID}/${process.env.REACT_APP_HUBSPOT_FORMGUID}`;

    const data = {
      submittedAt: new Date().getTime(),
      fields,
      context: {
        hutk: Cookies.get('hubspotutk'),
        pageUri: encodeURI(window.location.href),
        pageName: 'Login page',
      },
    };

    return axios.post(url, data).catch(error => {
      logRequestError('Hubspot.signInSendFirstWebLoginDataIssue', error.response, userData.email);
    });
  } catch {
    //Nothing
  }
};

export const sendRequestBusiness = requestData => {
  try {
    if (!process.env.REACT_APP_HUBSPOT_PORTALID || !process.env.REACT_APP_HUBSPOT_REQUEST_BUSINESS_FORMID) return;
    const fields = [
      {
        name: 'email',
        value: requestData.email,
      },
      {
        name: 'name',
        value: requestData.name,
      },
      {
        name: 'phone',
        value: requestData.phoneNumber,
      },
      {
        name: 'business_name',
        value: requestData.businessName,
      },
      {
        name: 'website',
        value: requestData.workUrl,
      },
      {
        name: 'number_of_trainers',
        value: requestData.noTrainers,
      },
      {
        name: 'number_of_clients',
        value: requestData.noClients,
      },
    ];
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTALID}/${process.env.REACT_APP_HUBSPOT_REQUEST_BUSINESS_FORMID}`;
    const data = {
      submittedAt: new Date().getTime(),
      fields,
      context: {
        hutk: Cookies.get('hubspotutk'),
        pageUri: encodeURI(window.location.href),
        pageName: 'Enterprise Contact form',
      },
    };

    return axios.post(url, data).catch(error => {
      logRequestError('Hubspot.enterpriseContactSendEnterpriseContactDataIssue', error.response, requestData.email);
    });
  } catch {
    // Nothing
  }
};

export const sendFeedbackAIWorkoutRequest = requestData => {
  try {
    if (
      !process.env.REACT_APP_HUBSPOT_FEEDBACK_WORKOUT_AI_PORTALID ||
      !process.env.REACT_APP_HUBSPOT_FEEDBACK_WORKOUT_AI_FORMID
    ) {
      return new Promise((res, rej) => {
        rej('Missing keys of hubspot');
      });
    }

    const fields = [
      {
        name: 'email',
        value: requestData.email,
      },
      {
        name: 'name',
        value: requestData.name,
      },
      {
        name: 'message',
        value: requestData.message,
      },
    ];

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_FEEDBACK_WORKOUT_AI_PORTALID}/${process.env.REACT_APP_HUBSPOT_FEEDBACK_WORKOUT_AI_FORMID}`;

    const data = {
      submittedAt: new Date().getTime(),
      fields,
      context: {
        hutk: Cookies.get('hubspotutk'),
        pageUri: encodeURI(window.location.href),
        pageName: 'Workout AI Feedback Form',
      },
    };

    return axios.post(url, data);
  } catch {
    // Nothing
  }
};
