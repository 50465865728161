import React from 'react';
import get from 'lodash/get';
import Linkify from 'react-linkify';

import * as S from '../style';

const LongAnswerResponse = ({ answer }) => {
  return (
    <S.QuestionAnswer isLongAnswer>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        {get(answer, 'text_answer', '')}
      </Linkify>
    </S.QuestionAnswer>
  );
};

export default LongAnswerResponse;
