import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'shared/FormControl';
import ContactUsModal from '../ChooseAPlan/ContactUsModal';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 120px 30px 50px;
  margin-top: 20px;
  background: url(${CDN_URL}/images/choose_plan_enterprise_bg.png) right no-repeat;
  background-color: #F8FBFF;
  border: 1px solid #DADFEA;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;
  background-size: cover;
  margin-bottom: 40px;

  .enterprisePlan__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 8px;
  }

  .enterprisePlan__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    color: #202353;
    max-width: 483px;
  }

  .enterprisePlan__button {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #5C6881;
    padding: 8px 31px;
  }
`;


export default function EnterprisePlan(props) {
  const [isShowModal, setIsShowModal] = useState(false);

  const openContactUsPopop = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };
  return (
    <Wrapper {...props}>
      <div>
        <div className="enterprisePlan__title">Enterprise</div>
        <div className="enterprisePlan__description">For multi-location businesses and corporate wellness programs managing over 500 clients. Deliver personal training and wellness coaching for your members.</div>
      </div>
      <div>
        <Button onClick={openContactUsPopop} className="enterprisePlan__button">Let’s Talk</Button>
      </div>
      <ContactUsModal isModalOpen={isShowModal} closeModal={handleCloseModal} dispatch={props.dispatch} />
    </Wrapper>
  )
};
