import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Dropdown as DropdownElement } from 'semantic-ui-react';

export const NavMenu = styled.div`
  border-top: 1px solid rgb(218, 223, 234, 0.6);
  width: 920px;
  display: flex;
  margin-top: 20px;
  position: relative;
  min-height: 45px !important;

  .client-menu-item {
    padding: 16px 20px !important;
    margin: 0 !important;
  }

  .client-menu a.active {
    border-bottom: 2px solid #5c5bbd;
  }
`;

export const NavMenuItem = styled(NavLink)`
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
  padding: 16px 20px;
  color: #202353;
  position: relative;
  text-decoration: none !important;

  &:first-child {
    margin-left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #494699;
    left: 0;
  }

  .active {
    color: #494699;
    font-weight: 700;

    ::after {
      display: block;
    }
  }

  &:focus {
    color: #494699;
    font-weight: 700;

    ::after {
      display: block;
    }
  }

  &:hover {
    color: #494699;
    text-decoration: none !important;
  }
`;

export const Dropdown = styled(DropdownElement)`
  position: absolute !important;
  right: -28px !important;
  top: 15px !important;
  background: #e9ecf2;
  border-radius: 5px !important;
  height: 28px;
  width: 39px;
  right: 0 !important;
  padding: 5px 10px !important;

  &::after {
    display: block;
    margin: 10px 0 !important;
  }

  .icon {
    position: absolute;
    top: 7.5px;
    right: 5px !important;
  }

  .header-options-tooltip {
    position: absolute;
    top: 0;
    right: 0 !important;
    background-color: transparent;
    width: 39px;
    height: 28px;
    z-index: 2;
  }
  .dropdown-menu.more-actions {
    transform: translate(-9px, 10px);
  }
`;

export const Breaker = styled.div`
  margin: auto;
  height: 1px;
  width: 178px;
  background: #dadfea;
`;

export const DropItem = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202353;
  padding: 17px 20.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: auto;

  ${props =>
    props.leaveGroup &&
    css`
      color: #ea314a !important;
      svg > path {
        stroke: #ea314a !important;
      }
    `}

  & > span {
    margin-left: 10px;
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
    svg > path {
      stroke: #5158cf;
    }
  }
`;
