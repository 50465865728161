import styled from 'styled-components';

export const SingleChoiceAutomationSelectWrapper = styled.div`
  margin: 24px 0px 8px;
  z-index: 1;

  .label-question {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;

    color: #323c47;

    margin-bottom: 8px;
  }

  &.is-question-unavailable {
    .single-choice-automation__control {
      background: #eaedf4;
      box-shadow: 0px 4px 8px 0px #f1f2f6;
      border: 1px solid #dadfea;
    }
  }
`;

export const customSelectQuestionStyles = {
  container: baseStyle => ({
    ...baseStyle,
    background: '#FFFFFF',
    borderRadius: '8px',
    pointerEvents: 'auto',
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? 'rgba(94, 89, 255, 1)' : 'rgba(218, 223, 234, 1)',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? 'rgba(218, 223, 234, 1)' : 'rgba(94, 89, 255, 1)',
    },
    minHeight: '42px',
    maxHeight: '42px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    backgroundColor: state.isDisabled ? '#FFFFFF' : '#FFFFFF',
    borderRadius: '8px',
  }),
  valueContainer: baseStyle => ({
    ...baseStyle,
    padding: '0px 12px',
    flexWrap: 'no-wrap',
    overflow: 'unset',
  }),
  singleValue: baseStyle => ({
    ...baseStyle,
    marginLeft: 'unset',
    marginRight: 'unset',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',

    '.question-label': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '18px',
      color: '#323C47',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '.deleted-label': {
        fontFamily: 'Open Sans',
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '15px',
        textTransform: 'uppercase',
        color: '#000 !important',
        opacity: '0.5',
      },
    },
  }),
  menuPortal: (baseStyle, state) => ({
    ...baseStyle,
    zIndex: 2001,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: '4px',
    marginBottom: '4px',
    boxShadow: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.07))',
    borderRadius: '4px',
    overflow: 'hidden',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: '8px',
    paddingBottom: '8px',
    overflowX: 'hidden',
    maxHeight: '211px',
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor:
      state.isFocused && !state.isSelected
        ? 'rgba(245, 245, 255, 1)'
        : state.isSelected
        ? 'rgba(230, 230, 255, 1)'
        : '#ffffff',
    ':hover': {
      backgroundColor: 'rgba(245, 245, 255, 1)',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    padding: '11px 15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'start',
    gap: '8px',

    '.question-label': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '18px',
      color: '#323C47',
      marginTop: '2px',
    },
  }),
};

export const QuestionTypeWrapper = styled.div`
  min-width: 39px;
  padding: 2px 4px;
  background: #ee586c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  gap: 4px;
  border-radius: 2px;
  flex-shrink: 0;

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;

    text-align: right;

    color: #ffffff;
  }
`;
