import get from 'lodash/get';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Banner, List, Charts } from './components';
import { Wrapper, Container } from './style';
import { resetData, countPackages } from 'redux/package-list/actions';
import { toggleSideBar } from 'actions/sidebar';
import PaymentLimitAccess from 'shared/PaymentLimitAccess';
import UpgradePath from 'shared/UpgradePath';
import { getCookie, genTokenFromMarketplace, isTeamAdmin } from 'utils/commonFunction';
import { C_KEY_TOKEN } from 'constants/commonData';

const PackageList = ({
  permission,
  user,
  counter,
  toggleSideBar,
  total,
  isMP,
  location,
  counterMP,
  dispatch,
  paymentOverdue,
  zapierOverdue,
  addOnsOverdue,
  paymentWhiteLabelOverdue,
}) => {
  const { is_verified_email, force_send_verification_email_at, is_hide_banner_package: shouldHide } = user || {};

  const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin(user);

  const isWhiteLabelOverdue = paymentWhiteLabelOverdue;
  const isShowOverdue = !isWhiteLabelOverdue && isOverdue;
  const isVerifyEmail =
    !isWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

  const search = get(location, 'search', '');
  const pathname = get(location, 'pathname', '');

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (isMP && token && search.includes('redirect-platform')) {
      genTokenFromMarketplace({ token, user, pathname, search, replacePathname: '/home/marketplace', dispatch });
    }
  }, [search, pathname]);

  useEffect(() => {
    toggleSideBar(true);
  }, []);

  const handlePermissionPayment = () => {
    if (!permission.explore_payment) {
      if (permission.payment) return true;
      return false;
    }
    return true;
  };

  if (!permission.explore_payment && !permission.payment) {
    return <PaymentLimitAccess />;
  }

  return (
    <UpgradePath
      pathName={!handlePermissionPayment() && 'explore_payment'}
      fallback="Payment & Packages"
      location={location}
    >
      <Wrapper>
        <Container>
          {(shouldHide || isMP) && <Charts isMP={isMP} search={search} />}
          {!isMP ? <Banner /> : null}
          <List
            counter={isMP ? counterMP : counter}
            shouldHide={shouldHide || isMP}
            total={total}
            isMP={isMP}
            hasAlertHeader={isWhiteLabelOverdue || isShowOverdue || isVerifyEmail}
          />
        </Container>
      </Wrapper>
    </UpgradePath>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageList, permission, pricing },
    user,
  } = state;
  const { counter, counterMP, loading, total } = packageList.toJS ? packageList.toJS() : {};

  return {
    permission,
    counter: counter,
    counterMP: counterMP,
    loading: loading,
    total: total,
    user: user,
    paymentOverdue: pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    paymentWhiteLabelOverdue: pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    resetData: bindActionCreators(resetData, dispatch),
    countPackages: bindActionCreators(countPackages, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(PackageList);
