import { PLANS } from 'constants/commonData';
import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close_circle.svg';

import * as S from './style';

const UpgradeStudioPlanModal = props => {
  const { onClose, onCloseAll } = props;
  return (
    <S.StudioUpgradeModal
      open={true}
      closeOnDimmerClick={false}
      onClose={() => onClose()}
      className="upgrade-path-modal"
    >
      <button className="close-button" onClick={() => onClose()}>
        <CloseIcon className="close-icon" />
      </button>
      <S.ContentWrapper>
        <S.Title>Get Broadcast Messages</S.Title>
        <S.Content>
          Upgrade to the Studio Plan to Broadcast messages and send direct inbox messages to multiple clients at the
          same time.
        </S.Content>
        <S.StudioUpgradePathButton
          text="Upgrade to Studio"
          planWillSelect={{
            tier: PLANS.studio.key,
            addOns: {
              resource_collections: false,
            },
          }}
          onClick={() => onCloseAll && onCloseAll()}
        />
      </S.ContentWrapper>
    </S.StudioUpgradeModal>
  );
};

export default UpgradeStudioPlanModal;
