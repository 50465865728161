// Lib
import React, { useState } from 'react';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Actions
import { toggleModal } from 'actions/modal';

// Components
import FilterSearch from './components/FilterSearch';

// Assets
import CloseIcon from 'assets/icons/close_bold_circle.svg';

// Constants
import { CATEGORIES } from 'components/Recipes/constants';

// Style
import * as S from './style';

const CommonPopup = ({
  toggleModal,
  content = null,
  action = null,
  title = '',
  hideFilter = false,
  hideFilterCategory = false,
  onSearch,
  onClearSearch,
  onFilterCategory,
  onSelectedFilterApply,
  appliedFilter,
  maxCalories,
  placeholder,
  leftAction = null,
  height,
  width,
}) => {
  const [activeCategory, setActiveCategory] = useState(CATEGORIES[0].value);

  const handleClose = () => {
    toggleModal(false);
  };

  const handleFilterCategory = category => {
    setActiveCategory(category);
    onFilterCategory(category);
  };

  return (
    <S.CustomModal open onClose={handleClose} closeOnDimmerClick={false} customHeight={height} customWidth={width}>
      <Modal.Header>
        <CloseButton className="close-button" onClick={handleClose}>
          <img src={CloseIcon} alt="Close" />
        </CloseButton>
        <div className="title">{title}</div>
        <FilterSearch
          hideFilter={hideFilter}
          onSearch={onSearch}
          onClearSearch={onClearSearch}
          onSelectedFilterApply={onSelectedFilterApply}
          appliedFilter={appliedFilter}
          maxCalories={maxCalories}
          placeholder={placeholder}
        />
        {!hideFilterCategory && (
          <S.FilterCategory>
            {CATEGORIES.map(({ icon, label, value }) => {
              return (
                <S.CategoryItem
                  key={label}
                  active={activeCategory === value}
                  onClick={() => handleFilterCategory(value)}
                  className={`${label.replace('/', '-').toLowerCase()}`}
                >
                  {icon}
                  <span>{label}</span>
                </S.CategoryItem>
              );
            })}
          </S.FilterCategory>
        )}
      </Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions className={classNames({ 'left-action': leftAction })}>
        {leftAction}
        {action}
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(null, mapDispatch)(CommonPopup);
