import Request from 'configs/request';

export const Types = {};

export const fetchStudioProgramByClient = client => {
  return Request.post({ url: '/api/studio-program/fetch-by-client', data: { client } });
};

export const removeStudioProgramByClient = data => {
  return Request.post({ url: '/api/studio-program/remove-client', data });
};

export const addStudioProgramsToClient = data => {
  return Request.post({ url: '/api/studio-program/add-to-client', data });
};

export const addWorkoutCollectionsToClient = data => {
  return Request.post({ url: `/api/on-demand-workout-collections/add-client-into-collections`, data });
};

export const removeWorkoutCollectionsByClient = data => {
  return Request.delete({ url: `/api/on-demand-workout-collections/${data.collectionId}/clients/${data.clientId}` });
};

export const fetchStudioCollectionByClient = client => {
  return Request.post({ url: '/api/studio-collection/v2/fetch-by-client', data: { client } });
};

export const removeStudioCollectionByClient = data => {
  return Request.post({ url: '/api/studio-collection/v2/remove-client', data });
};

export const assignClientToCollection = data => {
  return Request.post({ url: '/api/studio-collection/v2/assign-collections', data });
};

export const fetchOnDemandWorkoutCollectionsByClient = param => {
  return Request.get({ url: `/api/on-demand-workout-collections/clients/${param}` });
};
