import _ from 'lodash';
import React, { useRef, useEffect, useCallback, useState } from 'react';
import Avatar from 'react-avatar';
import { convertS3UrlToCloudFrontUrl, formatLeaderboardRanking, getUserShortName } from 'utils/commonFunction';
import { ClientContainer, ClientItem, SkeletonClient } from './style';
import { connect } from 'react-redux';

const Clients = ({ onLoad, unit, clientsData, onlyTop3, loadMoreOffset, cloudfrontList }) => {
  const { list, page, per_page, total, isLoaded } = clientsData;
  const [element, setElement] = useState(null);
  const [loading, setLoading] = useState(false);

  const currentClientsData = useRef({ ...clientsData, loading });

  useEffect(() => {
    onLoad({ page: 1 });
  }, []);

  useEffect(() => {
    currentClientsData.current = { ...clientsData, loading: loading };
  }, [clientsData, loading]);

  const observerCallback = useCallback(
    entries => {
      const firstEntry = entries[0];

      if (
        firstEntry.isIntersecting &&
        !currentClientsData.current.loading &&
        Math.ceil(currentClientsData.current.total / currentClientsData.current.per_page) >
          currentClientsData.current.page
      ) {
        handleLoadMore();
      }
    },
    [loading, total, per_page, page, clientsData],
  );

  const observer = useRef(new IntersectionObserver(observerCallback, { threshold: 0.5 }));

  const handleLoadMore = async () => {
    setLoading(false);
    try {
      setLoading(true);
      await onLoad({ page: currentClientsData.current.page + 1 });
      setLoading(false);
    } catch (err) {
      console.error('🚀 ~ file: Clients.js ~ line 47 ~ handleLoadMore ~ err', err);
    }
  };

  useEffect(() => {
    if (element) {
      observer.current.observe(element);
    }

    return () => {
      if (element) {
        observer.current.disconnect();
      }
    };
  }, [element]);

  if (!onlyTop3) {
    return (
      <ClientContainer className={!list.length && isLoaded ? 'clientList noList' : 'clientList'}>
        {_.uniqBy(list, 'user._id').map((c, index) =>
          index < 3 ? null : (
            <ClientItem className="clientList__item" key={c.user._id}>
              <span className="index">{index + 1}</span>
              <Avatar
                name={getUserShortName(c.user)}
                size="42"
                color={c.user.color}
                src={convertS3UrlToCloudFrontUrl(c.user.avatar, cloudfrontList, true)}
              />
              <div className="clientName">
                {c.user.first_name} {c.user.last_name}
              </div>
              <span>
                {Number(c.score).toSeperateFormat()} {formatLeaderboardRanking(unit, c.score)}
              </span>
              {index === (page - 1) * per_page + (per_page - (loadMoreOffset || 10)) && (
                <div ref={setElement} className="bottomSentinal"></div>
              )}
            </ClientItem>
          ),
        )}
        {list.length > 0 && !(list.length === total) && (
          <SkeletonClient>
            <div className="skeleton-element index" />
            <div className="skeleton-element skeleton-avatar" />
            <div className="name-container">
              <div className="skeleton-element first-name" />
              <div className="skeleton-element last-name" />
            </div>
            <div className="skeleton-element score" />
          </SkeletonClient>
        )}
        {!list.length && isLoaded ? <div className="no-data">No data</div> : null}
      </ClientContainer>
    );
  } else {
    return null;
  }
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(Clients);
