import styled from 'styled-components';

export const WorkoutWrapper = styled.div`
  &:has(.workout-checked) {
    border: 1px solid #5a57cb !important;
    .workout-cell--checkbox-workout {
      visibility: visible;
    }
  }

  &:hover {
    border: 1px solid #5a57cb;
    .workout-cell--checkbox-workout {
      visibility: visible;
    }
  }
`;

export const ExerciseListHeader = styled.div`
  height: 14px;
  width: calc(100% - 8px);
  .workout-cell--checkbox-workout {
    height: 14px;
    width: 14px;
    padding-left: 18px;
    margin-left: 4px;
    display: block;
    visibility: ${props => (props.isSelectedWorkout ? 'visible' : 'hidden')};
    & > span {
      width: 14px;
      height: 14px;
    }
    input:checked ~ .checkbox-icon {
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      background-position: 2px 2px;
      background-size: 70%;
      top: 7px;
    }
    &:has(input:checked) {
      visibility: visible;
    }
    &:hover > span.checkbox-icon {
      border: 1px solid #5158cf;
    }
  }
`;
