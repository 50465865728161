import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: -2px;
  margin-left: -9px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6.5px;

  span {
    color: #202353;
    font-family: 'Open Sans';
    font-size: ${props => (props.isPermission ? '20px' : '22px')};
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.002px;
  }
`;

export const StatusLabel = styled.p`
  padding: 4px 10px;
  margin: unset;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background-color: #f0f0f2;

  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: #202353;
  text-align: center;

  ${props =>
    props.isPublished &&
    css`
      border: 1px solid #36b24b;
      background: #dff5e1;
      color: #36b24b;
      font-weight: 700;
    `}

  ${({ isHasMultiple }) =>
    isHasMultiple &&
    css`
      margin-top: -6px;
    `}
`;

export const EditButton = styled.button`
  padding: 5px 10px;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  cursor: pointer;

  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: #202353;

  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
  }
`;

export const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 36px;
  width: fit-content;
  max-width: 40ch;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 8px;

  .custom-onboarding-tooltip-name {
    letter-spacing: 0;
  }

  .__react_component_tooltip {
    &.place-bottom {
      margin-top: 19px;
    }

    &.type-dark.place-bottom:after {
      border-top-color: #2d2e2d;
    }

    &.custom-onboarding-tooltip-name-tooltip {
      width: 228px;
      border-radius: 5px;
      padding: 15px;
    }

    span {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      letter-spacing: unset;
      white-space: normal;
      color: #fff;
    }
  }
`;

export const Title = styled.div`
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: unset;
  text-align: left;
  color: #202353;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LoadingSkeleton = styled.div`
  width: 246px;
  height: 36px;
  border-radius: 8px;
  background-color: #f8f8f8;
`;
