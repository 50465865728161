import _ from 'lodash';
import { toast } from 'react-toastify';

import Request from 'configs/request';
import { Mixpanel } from 'utils/mixplanel';
import { changeTaskCalendarStartDate } from 'redux/task/task.actionCreators';

export const Types = {
  GET_HABITS_MOST_POPULAR_REQUEST: 'GET_HABITS_MOST_POPULAR_REQUEST',
  GET_HABITS_MOST_POPULAR_SUCCESS: 'GET_HABITS_MOST_POPULAR_SUCCESS',
  GET_HABITS_MOST_POPULAR_FAILED: 'GET_HABITS_MOST_POPULAR_FAILED',
  GET_HABITS_MOST_RECENT_REQUEST: 'GET_HABITS_MOST_RECENT_REQUEST',
  GET_HABITS_MOST_RECENT_SUCCESS: 'GET_HABITS_MOST_RECENT_SUCCESS',
  GET_HABITS_MOST_RECENT_FAILED: 'GET_HABITS_MOST_RECENT_FAILED',
  GET_HABITS_CUSTOM_REQUEST: 'GET_HABITS_CUSTOM_REQUEST',
  GET_HABITS_CUSTOM_SUCCESS: 'GET_HABITS_CUSTOM_SUCCESS',
  GET_HABITS_CUSTOM_FAILED: 'GET_HABITS_CUSTOM_FAILED',
  GET_HABITS_YOUR_TEAM_SUCCESS: 'GET_HABITS_YOUR_TEAM_SUCCESS',
  CHANGE_HABITS_LIST_QUERY_PARAMS: 'CHANGE_HABITS_LIST_QUERY_PARAMS',
  DELETE_HABIT_LIBRARY_REQUEST: 'DELETE_HABIT_LIBRARY_REQUEST',
  GET_HABIT_UNITS_REQUEST: 'GET_HABIT_UNITS_REQUEST',
  GET_HABIT_UNITS_SUCCESS: 'GET_HABIT_UNITS_SUCCESS',
  GET_HABIT_UNITS_FAILED: 'GET_HABIT_UNITS_FAILED',
  GET_HABIT_OVERVIEW_REQUEST: 'GET_HABIT_OVERVIEW_REQUEST',
  GET_HABIT_OVERVIEW_SUCCESS: 'GET_HABIT_OVERVIEW_SUCCESS',
  GET_HABIT_OVERVIEW_FAIL: 'GET_HABIT_OVERVIEW_FAIL',
  GET_HABITS_SYSTEM_REQUEST: 'GET_HABITS_SYSTEM_REQUEST',
  GET_HABITS_SYSTEM_SUCCESS: 'GET_HABITS_SYSTEM_SUCCESS',
  GET_HABITS_SYSTEM_FAILED: 'GET_HABITS_SYSTEM_FAILED',
};

export const getListMostPopular = () => {
  return (dispatch, getState) => {
    dispatch({ type: Types.GET_HABITS_MOST_POPULAR_REQUEST });

    return dispatch(
      Request.get(
        { url: '/api/habit-libraries/list-most-popular?per_page=6' },
        true,
        (result, { dispatch }) => {
          const data = _.get(result, 'data.data');
          if (data) {
            dispatch({
              type: Types.GET_HABITS_MOST_POPULAR_SUCCESS,
              payload: { data },
            });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.GET_HABITS_MOST_POPULAR_FAILED });
        },
      ),
    );
  };
};

export const getListMostRecent = () => {
  return dispatch => {
    dispatch({ type: Types.GET_HABITS_MOST_RECENT_REQUEST });

    return dispatch(
      Request.get(
        { url: '/api/habit-libraries/list-most-recent?per_page=6' },
        true,
        (result, { dispatch }) => {
          const data = _.get(result, 'data.data');
          if (data) {
            dispatch({
              type: Types.GET_HABITS_MOST_RECENT_SUCCESS,
              payload: { data },
            });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.GET_HABITS_MOST_RECENT_FAILED });
        },
      ),
    );
  };
};

export const getListCustom = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        habits: {
          data: prevData,
          total: prevTotal,
          query: { per_page, sort, sorter },
        },
      },
    } = getState();
    const { isSearch, reset, type } = params;
    dispatch({ type: Types.GET_HABITS_CUSTOM_REQUEST });
    if (!reset && !isSearch && prevData.length === prevTotal) return;
    return dispatch(
      Request.get(
        { url: '/api/habit-libraries', params: _.omit({ ...params, per_page, sort, sorter }, ['isSearch', 'reset']) },
        true,
        (result, { dispatch }) => {
          const { total, data: dataRes } = _.get(result, 'data');
          if (dataRes) {
            if (type === 'in_team') {
              dispatch({
                type: Types.GET_HABITS_YOUR_TEAM_SUCCESS,
                payload: {
                  data: dataRes,
                  total,
                },
              });
            } else {
              dispatch({
                type: Types.GET_HABITS_CUSTOM_SUCCESS,
                payload: {
                  data: isSearch || reset ? dataRes : [...prevData, ...dataRes],
                  total,
                },
              });
            }
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.GET_HABITS_CUSTOM_FAILED });
        },
      ),
    );
  };
};

export const getListSystem = () => {
  return dispatch => {
    dispatch({ type: Types.GET_HABITS_SYSTEM_REQUEST });
    return dispatch(
      Request.get(
        { url: '/api/habit-libraries/system' },
        true,
        (result, { dispatch }) => {
          const data = _.get(result, 'data.data');
          if (data) {
            dispatch({
              type: Types.GET_HABITS_SYSTEM_SUCCESS,
              payload: data,
            });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.GET_HABITS_SYSTEM_FAILED });
        },
      ),
    );
  };
};

export const changeQueryParams = params => {
  return dispatch => {
    dispatch({ type: Types.CHANGE_HABITS_LIST_QUERY_PARAMS, payload: params });
    dispatch(getListCustom(params));
  };
};

export const saveHabitToLibrary = params => {
  if (!params) return;

  return Request.post({ url: `/api/habits/${params}/save-to-library` }, true, (result, { dispatch }) => {
    toast('Habit has been added to your Library');
    if (_.get(result, 'data.data')) {
      Mixpanel.track('task_habit_save_to_library');
    }
  });
};

export const removeHabitFromLibrary = id => {
  if (_.isEmpty(id)) return;

  return Request.delete({ url: `/api/habit-libraries/${id}` }, true, (result, { dispatch }) => {
    if (_.get(result, 'data.data.success', false)) {
      dispatch({ type: Types.DELETE_HABIT_LIBRARY_REQUEST, payload: id });
    }
  });
};

export const removeHabit = habitId => {
  if (_.isEmpty(habitId)) return;

  return Request.delete({ url: `/api/habits/${habitId}` }, true, (result, { dispatch }) => {
    toast('The habit has been deleted.');
    if (_.get(result, 'data.data.success')) {
      Mixpanel.track('delete_habit');
      dispatch(changeTaskCalendarStartDate());
    }
  });
};

export const addHabit = data => {
  return Request.post({ url: '/api/habits', data }, true, (result, { dispatch }) => {
    const data = _.get(result, 'data.data');
    if (data) {
      Mixpanel.track('create_new_habit');
      dispatch(changeTaskCalendarStartDate());
    }
  });
};

export const updateHabit = (id, data) => {
  return Request.patch({ url: `/api/habits/${id}`, data }, true, (result, { dispatch }) => {
    const data = _.get(result, 'data.data');
    if (data) {
      dispatch(changeTaskCalendarStartDate());
    }
  });
};

export const getHabitUnits = () => {
  return dispatch => {
    dispatch({ type: Types.GET_HABIT_UNITS_REQUEST });

    return dispatch(
      Request.get(
        { url: '/api/habit-units' },
        true,
        (result, { dispatch }) => {
          const data = _.get(result, 'data.data');
          if (data) {
            dispatch({ type: Types.GET_HABIT_UNITS_SUCCESS, payload: { data } });
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.GET_HABIT_UNITS_FAILED });
        },
      ),
    );
  };
};

export const getHabitDetail = data => {
  if (!_.get(data, 'habit_id')) return;

  return Request.get({ url: `/api/habits/${data.habit_id}/insight`, params: _.omit(data, 'habit_id') });
};

export const getListHabitOverview = (params, callback) => {
  return (dispatch, _) => {
    dispatch({ type: Types.GET_HABIT_OVERVIEW_REQUEST });
    return dispatch(
      Request.get(
        {
          url: '/api/habits/active/fetch-by-trainer',
          params,
        },
        true,
        response => {
          const { data = [] } = response.data;
          if (data) {
            dispatch({ type: Types.GET_HABIT_OVERVIEW_SUCCESS, payload: data });
          }
          callback && callback();
        },
        () => {
          dispatch({ type: Types.GET_HABIT_OVERVIEW_FAIL });
        },
      ),
    );
  };
};

export const endHabit = (habitId, callback) => {
  if (_.isEmpty(habitId)) return;

  return Request.post({ url: `/api/habits/${habitId}/end` }, true, (result, { dispatch }) => {
    toast('The habit has been ended.');
    if (_.get(result, 'data.data.success')) {
      Mixpanel.track('end_habit');
      dispatch(changeTaskCalendarStartDate());
      callback && callback();
    }
  });
};
