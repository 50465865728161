import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'shared/FormControl';
import { DismissText, DismissPopup } from './style';
import { dismiss, hideFeature } from 'redux/onboarding/actions';
import { shouldUseVirtualViewport } from 'utils/event-api';

function Dismiss(props) {
  const { checkList } = props;
  const unfinished = _.find(checkList, item => !item.state);
  const [open, setOpen] = useState(false);

  const onConfirm = () => {
    if (window.screen.width < 576) {
      shouldUseVirtualViewport();
    }
    props.hideFeature();
    props.dismiss();
  };

  const onClick = () => {
    if (unfinished) {
      setOpen(true);
    } else {
      onConfirm();
    }
  };

  return (
    <>
      <DismissText onClick={onClick}>{unfinished ? 'Hide checklist' : 'Done'}</DismissText>
      {open && (
        <DismissPopup>
          <div style={{ marginBottom: 25 }}>Would you like to dismiss this checklist forever?</div>
          <div className="buttons">
            <Button className="cancel" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button className="confirm" onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        </DismissPopup>
      )}
    </>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      onboarding: { checkList },
    },
  } = state;

  return { checkList };
};

const mapDispatch = dispatch => {
  return {
    hideFeature: bindActionCreators(hideFeature, dispatch),
    dismiss: bindActionCreators(dismiss, dispatch),
  };
};

export default connect(mapState, mapDispatch)(Dismiss);
