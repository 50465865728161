import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Image, Button } from 'semantic-ui-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import debounce from 'lodash/debounce';

import { Button as ActionButton, FormGroup } from 'shared/FormControl';
import {
  FILE_ERRORS,
  DATE_FORMAT,
  MEDIA_PLACEHOLDER,
  AUTOFLOW_TYPES,
  ANNOUNCEMENT_SCHEDULE_TYPES,
  CDN_URL,
} from 'constants/commonData';
import FixedDropdown, { Option } from 'shared/Dropdown/Basic';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import {
  formatShortLink,
  formatLiveLink,
  getMediaType,
  getPresignedUploadUrl,
  makeFriendlyYoutubeURL,
  mediaLog,
  generateVimeoAPIUrl,
} from 'utils/commonFunction';
import { autoAddHttps, validateLink, validateVimeoUrl, validateYouTubeUrl } from 'utils/validations';

import * as S from './style';
import './style.scss';
import Preview from './Preview';
import DropZonePlaceholder from './DropZonePlaceholder';
import { generateAnnouncementDates } from './helper';
import ExactDateSchedule from './Schedule/ExactDate';
import IntervalSchedule from './Schedule/Interval';
import Attachment from './Attachment';
import Composer from '../../shared/Composer';
import {
  SUPPORTED_FILES,
  MAX_SIZE_VIDEO,
  MAX_SIZE_IMAGE,
  ATTACHMENT_TYPE_LINK,
  removeScriptTags,
  getAttachmentLinkData,
  getPlainText,
  getHTMLText,
  addInlineStyleToTags,
} from './helper';

function Announcement(props) {
  const { originData, date, announcementType = AUTOFLOW_TYPES.EXACT_DATE } = props;
  const originAttachment =
    originData.attachment && originData.attachment_type && originData.attachment_type !== ATTACHMENT_TYPE_LINK
      ? {
          type: originData.attachment_type,
          url: originData.attachment,
          thumbnail_url: originData.attachment_thumbnail,
        }
      : null;

  const originAttachmentLink = getAttachmentLinkData(originData);

  const parsedOriginalData = {
    type: originData.heading || 'Announcement',
    title: originData.subject || '',
    schedule: originData.schedule_on || ANNOUNCEMENT_SCHEDULE_TYPES.SINGLE,
    message: originData.html_content || getHTMLText(originData.content) || '',
    ...generateAnnouncementDates(originData, date, announcementType),
  };

  const [type, setType] = useState(parsedOriginalData.type);
  const [title, setTitle] = useState(parsedOriginalData.title);
  const [schedule, setSchedule] = useState(parsedOriginalData.schedule);
  const [message, setMessage] = useState(removeScriptTags(parsedOriginalData.message));
  const [attachment, setAttachment] = useState(originAttachment);
  const [start, setStart] = useState(parsedOriginalData.start);
  const [end, setEnd] = useState(parsedOriginalData.end);
  const [attachmentLink, setAttachmentLink] = useState(originAttachmentLink);
  const [loadingMetaData, setLoadingMetaData] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);

  const [isSaving, setSaving] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  // const messageInput = useRef();

  const isInterval = useMemo(() => announcementType === AUTOFLOW_TYPES.INTERVAL, [announcementType]);

  useEffect(() => {
    return () => {
      if (attachment && attachment.localFile) {
        window.URL.revokeObjectURL(attachment.localFile);
      }
    };
  }, []);

  const onModalMount = () => {
    setTimeout(() => {
      // if (messageInput.current) {
      //   messageInput.current.innerText = message;
      // }
    }, 0);
  };

  const cancelUploadFile = () => {
    setAttachment(null);
    setUploading(false);
  };

  const onClose = (showConfirmPopup = true) => {
    if (typeof props.onClose === 'function') {
      let data;

      if (showConfirmPopup) {
        const oldValue = {
          ...parsedOriginalData,
          attachment: originAttachment,
          attachmentLink: originAttachmentLink,
          start: isInterval ? parsedOriginalData.start : parsedOriginalData.start.format(DATE_FORMAT),
          end: isInterval ? parsedOriginalData.end : parsedOriginalData.end.format(DATE_FORMAT),
          message: getPlainText(parsedOriginalData.message).replace(/\s/g, ''),
        };
        const newValue = {
          type,
          title,
          schedule,
          message: getPlainText(message).replace(/\s/g, ''),
          attachment,
          attachmentLink,
          start: isInterval ? start : start.format(DATE_FORMAT),
          end: isInterval ? end : end.format(DATE_FORMAT),
        };
        data = { oldValue, newValue };
      }

      props.onClose(data);
    }
  };

  // const onPaste = event => {
  //   event.preventDefault();
  //   let text = '';

  //   if (event.clipboardData && event.clipboardData.getData) {
  //     text = event.clipboardData.getData('text/plain');
  //   } else if (window.clipboardData && window.clipboardData.getData) {
  //     text = window.clipboardData.getData('Text');
  //   }

  //   document.execCommand('insertText', false, text);
  //   event.persist();
  // };

  const onSelectFileError = ({ error }) => {
    let message;

    switch (error) {
      case FILE_ERRORS.FILE_TYPE_INVALID:
        message = 'Please use supported file types (png, jpg, jpeg, avi, flv, mp4, mov, 3gp)';
        break;

      case FILE_ERRORS.FILE_SIZE_LIMIT_IMAGE:
        message = 'Please upload an image smaller than 25 MB';
        break;

      case FILE_ERRORS.FILE_SIZE_LIMIT_VIDEO:
        message = 'Please resize and upload a video smaller than 300 MB';
        break;

      default:
        message = error;
        break;
    }

    toast.error(message);
  };

  const onDrop = files => {
    if (isUploading) {
      return false;
    }

    const file = files[0];

    if (!file) {
      return false;
    }

    const fileExtension = file.name.split('.').pop();

    if (!SUPPORTED_FILES.includes(fileExtension.toLowerCase())) {
      return onSelectFileError({ error: FILE_ERRORS.FILE_TYPE_INVALID });
    }

    const fileType = file.type.split('/').shift();
    const maxSize = fileType === 'video' ? MAX_SIZE_VIDEO : MAX_SIZE_IMAGE;

    if (file.size > maxSize) {
      return onSelectFileError({
        error: fileType === 'video' ? FILE_ERRORS.FILE_SIZE_LIMIT_VIDEO : FILE_ERRORS.FILE_SIZE_LIMIT_IMAGE,
      });
    }

    onSelectFile({ file });
  };

  const onSelectFile = async fileInfo => {
    setError(false);
    const { file } = fileInfo;
    const { size, name, type } = file;

    const { uploadUrl, configs } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-announcement', file);
    if (!uploadUrl || !configs) {
      return;
    }

    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via Announcement',
    });

    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    });

    setAttachment({ ...fileInfo, type: getMediaType(file), ...configs });
    setUploading(true);
  };

  const onSubmit = () => {
    if (isSaving) {
      return false;
    }

    // const replacedHTML = replaceInnerHTMLEditableElement(messageInput.current);
    // messageInput.current.innerHTML = replacedHTML;

    // let content = messageInput.current.innerText;
    // content = content ? content.trimAny('↵').trim() : '';

    let htmlContent = removeScriptTags(message);
    htmlContent = addInlineStyleToTags(htmlContent);

    let content = getPlainText(message);
    content = (content || '').trimAny('↵').trim();

    if (!type || !title || !content || isUploading) {
      // messageInput.current.innerText = content;
      return setError(true);
    }

    if (
      !isInterval &&
      schedule === ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE &&
      (end.isSameOrBefore(start, 'day') || end.isBefore(moment(), 'day'))
    ) {
      return setError(true);
    }

    let newData = {
      heading: type,
      subject: title,
      html_content: htmlContent,
      content,
      schedule_on: schedule,
      date: isInterval ? start : start.format(),
      attachment: '',
      attachment_type: '',
    };

    if (attachment) {
      newData.attachment = attachment.url;
      newData.attachment_type = attachment.type;
      newData.attachment_id = attachment.attachment_id;
      newData.localFile = attachment.localFile;
      newData.apiUrl = attachment.url;
    }

    if (attachmentLink && !invalidLink && !loadingMetaData) {
      newData = { ...newData, ...attachmentLink };
    }

    if (schedule === ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE) {
      newData.end_date = isInterval ? end : end.format();
    }

    if (props.onSubmit) {
      setSaving(true);
      props
        .onSubmit(newData)
        .then(() => onClose(false))
        .catch(() => setSaving(false));
    }
  };

  const onUpdateSchedule = (event, { value }) => {
    if (value !== schedule) {
      setSchedule(value);

      if (value === ANNOUNCEMENT_SCHEDULE_TYPES.MULTIPLE) {
        if (isInterval) {
          setEnd(start + 1);
        } else {
          setEnd(moment(start).add(1, 'day'));
        }
      }
    }
  };

  const onUpdateStartDate = newDate => {
    setStart(newDate);

    if (newDate.isSameOrAfter(end, 'day')) {
      setSchedule(ANNOUNCEMENT_SCHEDULE_TYPES.SINGLE);
    }
  };

  const onUpdateEndDate = newDate => setEnd(newDate);

  const onUpdateStartDay = day => {
    setStart(day);

    if (day >= end) {
      setSchedule(ANNOUNCEMENT_SCHEDULE_TYPES.SINGLE);
    }
  };

  const onUpdateEndDay = day => setEnd(day);

  const uploadAttachmentSuccess = responseData => {
    const localFile = window.URL.createObjectURL(attachment.file);
    const { size, name, type } = attachment.file;
    const splittedKey = attachment.key && attachment.key.split('/');
    const attachment_id = splittedKey[1].split('.')[0];
    mediaLog({
      status: 2,
      name,
      fileSize: size,
      fileType: type,
      description: 'Upload success file via Announcement',
    });

    setUploading(false);
    setAttachment(o => ({
      ...o,
      attachment_id,
      thumbnail_url: null,
      localFile,
    }));
    setUploadConfigs(null);
  };

  const onRemoveAttachment = () => {
    setAttachment(null);
    setUploadConfigs(null);
  };

  const handleInputMessage = text => {
    if (text) {
      setError(false);
    }

    // let newMess = replaceInnerHTMLEditableElement(event.target);

    // try {
    //   const div = document.createElement('div');
    //   div.innerHTML = newMess;
    //   document.body.appendChild(div);
    //   const text = div.innerText;
    //   document.body.removeChild(div);
    setMessage(text);
    // } catch {
    //   setMessage(newMess);
    // }
  };

  const handleSelectAttachmentLink = () => {
    setAttachmentLink({
      attachment: null,
      attachment_thumbnail: null,
      attachment_type: ATTACHMENT_TYPE_LINK,
      attachment_name: null,
      attachment_id: null,
    });
    setInvalidLink(false);
    setLoadingMetaData(false);
  };

  const handleRemoveAttachmentLink = () => {
    setAttachmentLink();
    setInvalidLink(false);
    setLoadingMetaData(false);
  };

  const getPageMetaDataFromURLVimeo = url => {
    setLoadingMetaData(true);
    axios
      .get(generateVimeoAPIUrl(url))
      .then(response => {
        const { data: { video_id = '', thumbnail_url = '', title = '' } = {} } = response;
        setAttachmentLink({
          attachment: url,
          attachment_thumbnail: thumbnail_url || MEDIA_PLACEHOLDER,
          attachment_type: ATTACHMENT_TYPE_LINK,
          attachment_name: title,
          attachment_id: video_id.toString(),
        });
        setInvalidLink(false);
      })
      .catch(() => {
        setInvalidLink(true);
      })
      .finally(() => {
        setLoadingMetaData(false);
      });
  };

  const getPageMetaDataFromURLYoutube = (url, videoId) => {
    setLoadingMetaData(true);
    getPageMetaDataFromURL(url)
      .then(response => {
        const { data: { data: { image = '', title = '' } = {} } = {} } = response;
        setAttachmentLink({
          attachment: url,
          attachment_thumbnail: image,
          attachment_type: ATTACHMENT_TYPE_LINK,
          attachment_name: title,
          attachment_id: videoId,
        });
        setInvalidLink(false);
      })
      .catch(() => {
        setInvalidLink(true);
      })
      .finally(() => {
        setLoadingMetaData(false);
      });
  };

  const handleChangeAttachmentLink = event => {
    event.preventDefault();
    const value = event.target.value || '';
    const httpsLink = autoAddHttps(value.trim());
    if (!validateLink(httpsLink)) {
      setAttachmentLink({
        attachment: value.trim(),
        attachment_thumbnail: null,
        attachment_type: ATTACHMENT_TYPE_LINK,
        attachment_name: null,
        attachment_id: null,
      });
      setInvalidLink(true);
      return;
    }

    const videoYoutubeId = validateYouTubeUrl(httpsLink);
    if (!!videoYoutubeId) {
      let url = makeFriendlyYoutubeURL(httpsLink);
      url = formatShortLink(url);
      url = formatLiveLink(url);
      debounce(getPageMetaDataFromURLYoutube, 300)(url, videoYoutubeId);
    } else if (validateVimeoUrl(httpsLink)) {
      debounce(getPageMetaDataFromURLVimeo, 300)(httpsLink);
    } else {
      setInvalidLink(true);
    }
  };

  return (
    <Modal
      open={true}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onClose={onClose}
      className="announcement-modal"
      closeOnDimmerClick={false}
      onMount={onModalMount}
    >
      <Modal.Content>
        <S.Wrapper>
          <S.Content>
            <S.Form hasError={error}>
              <S.FormHeader>
                <span>{originData._id ? 'Edit' : 'Create'} Announcement</span>
                {!!originData._id && (
                  <FixedDropdown>
                    <Option onClick={props.onCopy}>
                      <Image src={`${CDN_URL}/images/edit-white.svg`} width={14} style={{ marginRight: 12 }} />
                      <span>Copy</span>
                    </Option>
                    <Option onClick={props.onDelete}>
                      <Image src={`${CDN_URL}/images/delete.svg`} width={14} style={{ marginRight: 12 }} />
                      <span>Delete</span>
                    </Option>
                  </FixedDropdown>
                )}
              </S.FormHeader>

              <S.FormContent>
                <FormGroup className={error && !type ? 'error' : ''}>
                  <label>
                    <span>Type</span>
                    <span className="count">{type.length}/20</span>
                  </label>
                  <input
                    value={type}
                    placeholder={error && !type ? 'This field cannot be blank' : 'Announcement Type'}
                    onChange={event => setType(event.target.value)}
                    maxLength={20}
                    autoFocus
                  />
                </FormGroup>
                <FormGroup className={error && !title ? 'error' : ''}>
                  <label>
                    <span>Title</span>
                    <span className="count">{title.length}/40</span>
                  </label>
                  <input
                    value={title}
                    placeholder={error && !title ? 'This field cannot be blank' : 'Announcement Title'}
                    onChange={event => setTitle(event.target.value)}
                    maxLength={40}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Description</label>
                  <Composer initContent={message} onChange={handleInputMessage} />
                </FormGroup>
                <FormGroup>
                  <label>Add banner</label>
                  <Dropzone onDrop={onDrop} multiple={false}>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <S.MediaContainer {...getRootProps()}>
                          <S.Media>
                            <Attachment
                              isUploading={isUploading}
                              attachment={attachment}
                              cancelUploadFile={cancelUploadFile}
                              onUploadSuccess={uploadAttachmentSuccess}
                              onSelectFile={onSelectFile}
                              onSelectFileError={onSelectFileError}
                              onRemove={onRemoveAttachment}
                              uploadConfigs={uploadConfigs}
                              onSelectAttachmentLink={handleSelectAttachmentLink}
                              onRemoveAttachmentLink={handleRemoveAttachmentLink}
                              onChangeAttachmentLink={handleChangeAttachmentLink}
                              attachmentLink={attachmentLink}
                              loadingMetaData={loadingMetaData}
                              invalidLink={invalidLink}
                            />
                          </S.Media>
                          {error && isUploading ? <div className="file-in-process">Upload still in process</div> : null}
                          {!isUploading && <input {...getInputProps({ onClick: event => event.preventDefault() })} />}
                          <DropZonePlaceholder show={isDragActive && !isUploading} />
                        </S.MediaContainer>
                      );
                    }}
                  </Dropzone>
                </FormGroup>
              </S.FormContent>
            </S.Form>
            <Preview
              type={type}
              title={title}
              message={message}
              attachment={attachment}
              attachmentLink={attachmentLink}
              date={start}
              isInterval={isInterval}
            />
          </S.Content>
          <S.Footer>
            <div className="label">
              <span>SCHEDULE ON</span>
              <Image src={`${CDN_URL}/images/new_info.svg`} data-tip data-for="schedule-on-tooltip" />
              <ReactTooltip
                className="app-tooltip announcement-tooltip"
                id="schedule-on-tooltip"
                effect="solid"
                place={'top'}
                delayShow={500}
              >
                <p>Announcements will be delivered based on the Client’s timezone</p>
              </ReactTooltip>
            </div>
            <div className="actions">
              {isInterval ? (
                <IntervalSchedule
                  schedule={schedule}
                  startDay={start}
                  endDay={end}
                  error={error}
                  onUpdateStartDay={onUpdateStartDay}
                  onUpdateEndDay={onUpdateEndDay}
                  onUpdateSchedule={onUpdateSchedule}
                  totalWeek={props.totalWeek}
                />
              ) : (
                <ExactDateSchedule
                  schedule={schedule}
                  start={start}
                  end={end}
                  error={error}
                  onUpdateStartDate={onUpdateStartDate}
                  onUpdateEndDate={onUpdateEndDate}
                  onUpdateSchedule={onUpdateSchedule}
                />
              )}
              <ActionButton purple onClick={onSubmit}>
                Schedule
              </ActionButton>
            </div>
          </S.Footer>
        </S.Wrapper>
      </Modal.Content>
    </Modal>
  );
}

export default Announcement;
