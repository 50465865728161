import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Radio, Image } from 'semantic-ui-react';
import { isEmpty, debounce, get, isEqual, toLower } from 'lodash';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import * as S from './style';
import axios from 'axios';
import { ATTACHMENT_UPLOAD_URL, VIDEO_VALIDATIONS, ERROR_MESSAGE } from './helps';
import { CUSTOM_ONBOARDING_VIDEO_TYPE } from 'components/CustomBranding/constants';
import { CDN_URL } from 'constants/commonData';
import { ReactComponent as LinkTypeIcon } from 'assets/icons/freestyle_link_icon.svg';
import { ReactComponent as PlaySquare } from 'assets/icons/play_square.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play_icon_white.svg';
import { validateVimeoUrl, validateYouTubeUrl, validateLink, autoAddHttps, isVideoFile } from 'utils/validations';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import {
  formatShortLink,
  createObjectURL,
  revokeObjectURL,
  getPresignedUploadUrlByParams,
  mediaLog,
  getExtension,
  convertS3UrlToCloudFrontUrl,
  generateVimeoAPIUrl,
} from 'utils/commonFunction';
import { SelectFile } from 'shared/FormControl';
import MediaUploadProgress from 'shared/MediaUploadProgress';
import MediaModal from 'shared/MediaModal';
import { updateCustomBrandingPremium, showPreviewDraft } from 'redux/custom-branding/actions';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';

const WelcomeVideo = props => {
  const {
    customOnboardingVideoType,
    customOnboardingYoutube,
    customOnboardingUpload,
    updateCustomBrandingPremium,
    showPreviewDraft,
    getS3presignedURL,
    discard,
    onChangeDiscard,
    onCheckSaving,
    activeIndex,
    setCurrentIndex,
    setScreenActive,
    cloudfrontList,
  } = props;

  // Option
  const [type, setType] = useState(null);

  // Youtube/Vimeo
  const [linkVideoData, setLinkVideoData] = useState('');
  const [linkVideoThumbnail, setLinkVideoThumbnail] = useState('');
  const [videoName, setVideoName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const linkRef = useRef('');
  const [onLinkFetching, setLinkFetching] = useState(false);

  // Upload
  const [fileUpload, setFileUpload] = useState(null);
  const [localUrl, setLocalUrl] = useState(null);
  const [onUploading, setUploading] = useState(false);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  const [updateConfigs, setUpdateConfigs] = useState(null);
  const [attachmentLink, setAttachmentLink] = useState('');
  const [attachmentThumbnail, setAttachmentThumbnail] = useState('');
  const [attachmentName, setAttachmentName] = useState('');
  const [attachmentThumbnailPresigned, setAttachmentThumbnailPresigned] = useState('');
  const allowUpdateRef = useRef(true);

  // Saving
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setType(customOnboardingVideoType);
  }, [customOnboardingVideoType]);

  useEffect(() => {
    const link = get(customOnboardingYoutube, 'link', null);
    const thumbnail = get(customOnboardingYoutube, 'thumbnail_link', null);
    const name = get(customOnboardingYoutube, 'name', null);

    setLinkVideoData(link);
    setVideoName(name);
    setLinkVideoThumbnail(thumbnail);
  }, [customOnboardingYoutube]);

  useEffect(() => {
    const link = get(customOnboardingUpload, 'link', null);
    const thumbnail = get(customOnboardingUpload, 'thumbnail_link', null);
    const name = get(customOnboardingUpload, 'name', null);

    setAttachmentLink(link);
    setAttachmentThumbnail(thumbnail);
    setAttachmentName(name);
  }, [customOnboardingUpload]);

  useEffect(() => {
    allowUpdateRef.current = true;
    return () => {
      allowUpdateRef.current = false;
      if (localUrl) {
        revokeObjectURL(localUrl); // cleanup localUrl when component unmount
      }
    };
  }, []);

  useEffect(() => {
    if (discard) {
      // Option
      setType(customOnboardingVideoType);
      // Reset temp state
      clearLocalUrl();
      setErrorMessage('');
      setFileUpload(null);
      setUploading(false);
      setUploadConfigs(null);
      setUpdateConfigs(null);
      setAttachmentThumbnailPresigned('');
      setLinkFetching(false);
      allowUpdateRef.current = false;
      // Youtube/Vimeo
      setLinkVideoData(get(customOnboardingYoutube, 'link', null));
      setVideoName(get(customOnboardingYoutube, 'thumbnail_link', null));
      setLinkVideoThumbnail(get(customOnboardingYoutube, 'name', null));
      // Upload
      setAttachmentLink(get(customOnboardingUpload, 'link', null));
      setAttachmentThumbnail(get(customOnboardingUpload, 'thumbnail_link', null));
      setAttachmentName(get(customOnboardingUpload, 'name', null));
      // active show preview draft data
      showPreviewDraft({
        type: customOnboardingVideoType,
        youtube_thumbnail_link: get(customOnboardingYoutube, 'thumbnail_link', null),
        upload_thumbnail_link: get(customOnboardingUpload, 'thumbnail_link', null),
      });
      // change parent state
      onChangeDiscard(false);
      onCheckSaving(false);
      // change active tab
      setCurrentIndex(activeIndex);
      setScreenActive(activeIndex);
    }
  }, [discard]);

  useEffect(() => {
    // auto focus when choose type youtube/vimeo
    if (isEqual(type, CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO) && isEmpty(linkVideoData)) {
      linkRef.current.focus();
    }
  }, [type, linkVideoData]);

  useEffect(() => {
    let ignore = false;
    if (isEmpty(attachmentThumbnail)) return;
    getPresignedURLs(attachmentThumbnail, ignore);
    return () => {
      ignore = true;
    };
  }, [attachmentThumbnail]);

  const getPresignedURLs = async (thumbnail, ignore) => {
    try {
      if (!ignore) {
        let cloudUrl = convertS3UrlToCloudFrontUrl(thumbnail, cloudfrontList);
        if (!cloudUrl) {
          const response = await getS3presignedURL([thumbnail]);
          cloudUrl = response[0];
        }
        setAttachmentThumbnailPresigned(cloudUrl);
      }
    } catch {}
  };

  const getPageMetaDataFromURLVimeo = url => {
    axios
      .get(generateVimeoAPIUrl(url))
      .then(response => {
        const {
          data: { thumbnail_url = MEDIA_PLACEHOLDER, title },
        } = response;
        setLinkVideoData(url);
        setLinkVideoThumbnail(thumbnail_url);
        setVideoName(title);
        setErrorMessage('');
        linkRef.current.value = formatShortLink(url);
        // active show preview draft data
        showPreviewDraft({
          type,
          youtube_thumbnail_link: thumbnail_url,
          upload_thumbnail_link: attachmentThumbnail,
        });
      })
      .catch(() => {
        setLinkVideoData(url);
        setLinkVideoThumbnail('');
        setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
        // active show preview draft data
        showPreviewDraft({
          type,
          youtube_thumbnail_link: '',
          upload_thumbnail_link: attachmentThumbnail,
        });
      })
      .finally(() => {
        if (isEqual(type, customOnboardingVideoType) && isEqual(url, get(customOnboardingYoutube, 'link', null))) {
          onCheckSaving(false);
        }
        setLinkFetching(false);
      });
  };

  const getPageMetaDataFromURLYoutube = url => {
    getPageMetaDataFromURL(url)
      .then(response => {
        const {
          data: {
            data: { image, title },
          },
        } = response;
        setLinkVideoData(url);
        setLinkVideoThumbnail(image);
        setVideoName(title);
        setErrorMessage('');
        linkRef.current.value = formatShortLink(url);
        // active show preview draft data
        showPreviewDraft({
          type,
          youtube_thumbnail_link: image,
          upload_thumbnail_link: attachmentThumbnail,
        });
      })
      .catch(() => {
        setLinkVideoData(url);
        setLinkVideoThumbnail('');
        setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
        // active show preview draft data
        showPreviewDraft({
          type,
          youtube_thumbnail_link: '',
          upload_thumbnail_link: attachmentThumbnail,
        });
      })
      .finally(() => {
        if (isEqual(type, customOnboardingVideoType) && isEqual(url, get(customOnboardingYoutube, 'link', null))) {
          onCheckSaving(false);
        }
        setLinkFetching(false);
      });
  };

  const clearLocalUrl = () => {
    if (localUrl) {
      revokeObjectURL(localUrl);
    }
    setLocalUrl(null);
  };

  const onChangeVideoType = (_, { value }) => {
    if (onUploading || onLinkFetching) {
      return;
    }
    setType(value);
    // change value saving for show modal discard
    checkSavingForNewType(value);
    // active show preview draft data
    showPreviewDraft({
      type: value,
      youtube_thumbnail_link: linkVideoThumbnail,
      upload_thumbnail_link: attachmentThumbnail,
    });
  };

  const checkSavingForNewType = newType => {
    let isSave = true;
    if (isEqual(newType, customOnboardingVideoType)) {
      switch (newType) {
        case CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT:
        case CUSTOM_ONBOARDING_VIDEO_TYPE.SKIP:
          isSave = false;
          break;
        case CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN:
          if (isEqual(attachmentLink, get(customOnboardingUpload, 'link', null))) {
            isSave = false;
          }
          break;
        case CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO:
          if (isEqual(linkVideoData, get(customOnboardingYoutube, 'link', null))) {
            isSave = false;
          }
          break;
        default:
          break;
      }
    }
    // update parent state to discard it if the tab is changed
    onCheckSaving(isSave);
  };

  const onChangeVideoLink = event => {
    event.preventDefault();
    const value = event.target.value || '';
    onCheckSaving(true);
    setLinkFetching(true);
    if (isEmpty(value.trim())) {
      setLinkVideoData('');
      setLinkVideoThumbnail('');
      setErrorMessage('');
      // active show preview draft data
      showPreviewDraft({
        type,
        youtube_thumbnail_link: '',
        upload_thumbnail_link: attachmentThumbnail,
      });
      setLinkFetching(false);
      return;
    }
    const trimmedValue = autoAddHttps(value.trim());
    if (trimmedValue) {
      if (!validateLink(trimmedValue)) {
        setLinkVideoData(trimmedValue);
        setLinkVideoThumbnail('');
        setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
        // active show preview draft data
        showPreviewDraft({
          type,
          youtube_thumbnail_link: '',
          upload_thumbnail_link: attachmentThumbnail,
        });
        setLinkFetching(false);
        return;
      }
      if (validateVimeoUrl(trimmedValue)) {
        debounce(getPageMetaDataFromURLVimeo, 300)(trimmedValue);
        return;
      } else {
        const youtubeID = validateYouTubeUrl(trimmedValue);
        if (isEmpty(youtubeID)) {
          setLinkVideoData(trimmedValue);
          setLinkVideoThumbnail('');
          setErrorMessage(ERROR_MESSAGE.INVALID_VIDEO_LINK);
          // active show preview draft data
          showPreviewDraft({
            type,
            youtube_thumbnail_link: '',
            upload_thumbnail_link: attachmentThumbnail,
          });
          setLinkFetching(false);
          return;
        }
        debounce(getPageMetaDataFromURLYoutube, 300)(trimmedValue);
      }
    }
  };

  const handleRemoveLink = () => {
    setLinkVideoData('');
    setLinkVideoThumbnail('');
    setVideoName('');
    setErrorMessage('');
    onCheckSaving(true);
    linkRef.current.value = '';
    // active show preview draft data
    showPreviewDraft({
      type,
      youtube_thumbnail_link: '',
      upload_thumbnail_link: attachmentThumbnail,
    });
  };

  const handleRemoveUploadFile = () => {
    // Reset temp state upload file
    clearLocalUrl();
    setFileUpload(null);
    setUploading(false);
    setUploadConfigs(null);
    setUpdateConfigs(null);
    setAttachmentThumbnailPresigned('');
    setAttachmentLink('');
    setAttachmentThumbnail('');
    setAttachmentName('');
    // update parent state to discard it if the tab is changed
    onCheckSaving(true);
    // active show preview draft data
    showPreviewDraft({
      type,
      youtube_thumbnail_link: linkVideoThumbnail,
      upload_thumbnail_link: '',
    });
  };

  const handleUploadSuccess = () => {
    if (!allowUpdateRef.current) return;
    const { url, original_name, thumbnail_url } = updateConfigs;
    setAttachmentLink(url);
    setAttachmentThumbnail(thumbnail_url);
    setAttachmentThumbnailPresigned('');
    setAttachmentName(original_name);
    setUploading(false);
    onCheckSaving(true);
    // media log upload success
    mediaLog({
      status: 2,
      name: fileUpload.name,
      fileSize: fileUpload.size,
      fileType: fileUpload.type,
      description: 'Upload success onboarding welcome video',
    });
    // active show preview draft data
    showPreviewDraft({
      type,
      youtube_thumbnail_link: linkVideoThumbnail,
      upload_thumbnail_link: thumbnail_url,
    });
  };

  const handlePresignedUploadUrl = async tempFile => {
    const { uploadUrl, configs } = await getPresignedUploadUrlByParams(tempFile, {
      method: 'POST',
      url: ATTACHMENT_UPLOAD_URL,
      data: {
        fileNames: [tempFile.name],
      },
    });
    if (!uploadUrl || !configs) {
      return;
    }

    setUpdateConfigs(configs);
    setUploading(true);
    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': tempFile.type },
      data: tempFile,
    });
  };

  const onSelectFile = async tempFile => {
    if (!VIDEO_VALIDATIONS.extensions.includes(getExtension(toLower(tempFile.name)))) {
      return toast.error('File type not supported.');
    }
    const file = convertToValidFileTypeVideo(tempFile);
    if (isVideoFile(file.type) && file.size > VIDEO_VALIDATIONS.maxSizeKB) {
      return toast.error(VIDEO_VALIDATIONS.errorMessage);
    }
    const { size, name, type } = file;
    // media log file send
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a file via welcome video',
    });

    await handlePresignedUploadUrl(file);
    setLocalUrl(createObjectURL(file));
    setFileUpload(file);
  };

  const onDropFile = files => {
    if (files.length > 1) {
      return toast.error('Please upload one file at a time');
    }
    if (!files[0]) {
      return false;
    }

    onSelectFile(files[0]);
  };

  const convertToValidFileTypeVideo = tempFile => {
    const fileType =
      isEmpty(tempFile.type) || getExtension(toLower(tempFile.name)) === 'flv' ? 'video/mp4' : tempFile.type;
    // clone to new File object with type custom
    return new File([tempFile], [tempFile.name], { type: fileType });
  };

  const handleSave = () => {
    setUpdating(true);
    const data = {
      custom_onboarding_video_type: type,
    };
    const localData = {
      customOnboardingVideoType: type,
      customOnboardingDraft: null,
    };
    switch (type) {
      case CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT:
      case CUSTOM_ONBOARDING_VIDEO_TYPE.SKIP:
        break;
      case CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN:
        data.custom_onboarding_upload = {
          link: attachmentLink,
          thumbnail_link: attachmentThumbnail,
          name: attachmentName,
        };
        localData.customOnboardingUpload = data.custom_onboarding_upload;
        break;
      case CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO:
        data.custom_onboarding_youtube = {
          link: linkVideoData,
          thumbnail_link: linkVideoThumbnail,
          name: videoName,
        };
        localData.customOnboardingYoutube = data.custom_onboarding_youtube;
        break;
      default:
        break;
    }

    updateCustomBrandingPremium(data, localData).finally(() => {
      setUpdating(false);
      onChangeDiscard(false);
      onCheckSaving(false);
    });
  };

  const onLinkThumbnailLoadError = event => {
    event.preventDefault();
    if (!isEmpty(linkVideoThumbnail)) {
      event.target.src = linkVideoThumbnail;
    }
  };

  const onLinkThumbnailLoadSuccess = event => {
    event.preventDefault();
    event.target.style.display = 'block';
  };

  const onUploadThumbnailLoadError = event => {
    event.preventDefault();
    if (!isEmpty(attachmentThumbnailPresigned)) {
      event.target.src = attachmentThumbnailPresigned;
    }
  };

  const onUploadThumbnailLoadSuccess = event => {
    event.preventDefault();
    event.target.style.display = 'block';
  };

  const isPreviewUrl = useMemo(() => !isEmpty(linkVideoData) && !isEmpty(linkVideoThumbnail), [
    linkVideoData,
    linkVideoThumbnail,
  ]);
  const isLinkError = useMemo(() => isEmpty(linkVideoData), [linkVideoData]);

  const isDisable = useMemo(() => {
    if (updating || onUploading) {
      return true;
    }

    if (isEqual(type, customOnboardingVideoType)) {
      switch (type) {
        case CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT:
        case CUSTOM_ONBOARDING_VIDEO_TYPE.SKIP:
          return true;
        case CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN:
          if (isEqual(attachmentLink, get(customOnboardingUpload, 'link', null)) || isEmpty(attachmentLink)) {
            return true;
          }
          break;
        case CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO:
          if (
            isEqual(linkVideoData, get(customOnboardingYoutube, 'link', null)) ||
            isEmpty(linkVideoData) ||
            !isEmpty(errorMessage)
          ) {
            return true;
          }
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN:
          if (isEmpty(attachmentLink)) {
            return true;
          }
          break;
        case CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO:
          if (isEmpty(linkVideoData) || !isEmpty(errorMessage)) {
            return true;
          }
          break;
        default:
          break;
      }
    }

    return false;
  }, [
    type,
    attachmentLink,
    linkVideoData,
    customOnboardingVideoType,
    customOnboardingUpload,
    customOnboardingYoutube,
    errorMessage,
    updating,
    onUploading,
  ]);

  const renderUploadFile = () => {
    return (
      <S.AttachmentPreview>
        <MediaUploadProgress
          withPresignedUrl
          file={fileUpload}
          apiConfigs={uploadConfigs}
          onCancel={handleRemoveUploadFile}
          onSuccess={handleUploadSuccess}
          preview={<S.MediaTrigger />}
        />
      </S.AttachmentPreview>
    );
  };

  const renderUploadCompleted = () => {
    return (
      <S.AttachmentPreview isCompleted>
        <MediaModal
          video={attachmentLink}
          validVideo={true}
          trigger={
            <S.MediaTrigger>
              <Image
                src={attachmentThumbnailPresigned}
                alt=""
                onLoad={onUploadThumbnailLoadSuccess}
                onError={onUploadThumbnailLoadError}
                style={{ display: 'none' }}
              />
            </S.MediaTrigger>
          }
        />
        <div className="video-name">{attachmentName || ''}</div>
        <CloseIcon className="btn-remove-upload-file" onClick={handleRemoveUploadFile} />
      </S.AttachmentPreview>
    );
  };

  const renderUploading = () => {
    return onUploading ? renderUploadFile() : renderUploadCompleted();
  };

  return (
    <S.WelcomeVideoWrapper>
      <S.WelcomeVideoContainer>
        <S.VideoSelectWrapper>
          <S.DescriptionContainer>
            <S.DescriptionTitle>Welcome Video</S.DescriptionTitle>
            <S.DescriptionText>
              The Welcome Video will be the first video a new client sees when they log in and start up the app.
            </S.DescriptionText>
          </S.DescriptionContainer>
          <S.VideoSelectContainer>
            <Form onSubmit={e => e.preventDefault()}>
              <S.RadioFormField
                name="typeWelcomeVideo"
                control={Radio}
                label="Use Everfit’s welcome video"
                value={CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT}
                checked={type === CUSTOM_ONBOARDING_VIDEO_TYPE.EVERFIT}
                onChange={onChangeVideoType}
                disabled={onUploading || onLinkFetching}
              />
              <S.RadioFormField
                name="typeWelcomeVideo"
                control={Radio}
                label="Upload my own video"
                value={CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN}
                checked={type === CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN}
                onChange={onChangeVideoType}
                disabled={onUploading || onLinkFetching}
              />
              <S.RadioFormField
                name="typeWelcomeVideo"
                control={Radio}
                label="Use Youtube / Vimeo Link"
                value={CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO}
                checked={type === CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO}
                onChange={onChangeVideoType}
                disabled={onUploading || onLinkFetching}
              />
              <S.RadioFormField
                name="typeWelcomeVideo"
                control={Radio}
                label="Skip welcome video"
                value={CUSTOM_ONBOARDING_VIDEO_TYPE.SKIP}
                checked={type === CUSTOM_ONBOARDING_VIDEO_TYPE.SKIP}
                onChange={onChangeVideoType}
                disabled={onUploading || onLinkFetching}
              />
            </Form>
          </S.VideoSelectContainer>
          {type === CUSTOM_ONBOARDING_VIDEO_TYPE.MY_OWN && (
            <S.UploadFileContainer>
              <S.UploadFileTitle>
                <S.IconInfoCircle src={`${CDN_URL}/images/info_circle.svg`} alt="" />
                <S.UploadFileTitleWrapperText>
                  Upload a video file with max size of&nbsp;
                  <b>300MB</b>
                </S.UploadFileTitleWrapperText>
              </S.UploadFileTitle>
              {(!fileUpload || !uploadConfigs) && !attachmentLink ? (
                <Dropzone onDrop={onDropFile} multiple={false}>
                  {({ getRootProps, isDragActive }) => (
                    <S.DropzoneWrapper {...getRootProps()} isDragging={isDragActive}>
                      <PlaySquare />
                      <S.DropzoneWrapperText>
                        Drag and drop your video <br />
                        here or&nbsp;
                        <SelectFile
                          key={VIDEO_VALIDATIONS.name}
                          trigger={<S.WrapperItemTextHightLight>Choose file</S.WrapperItemTextHightLight>}
                          onSelect={onSelectFile}
                          accept={VIDEO_VALIDATIONS.accepts}
                          validateExtentions={VIDEO_VALIDATIONS.validateExtensions}
                          maxSize={VIDEO_VALIDATIONS.maxSizeMB}
                          fileSizeLimitMessage={VIDEO_VALIDATIONS.errorMessage}
                        />
                      </S.DropzoneWrapperText>
                    </S.DropzoneWrapper>
                  )}
                </Dropzone>
              ) : (
                renderUploading()
              )}
            </S.UploadFileContainer>
          )}
          {type === CUSTOM_ONBOARDING_VIDEO_TYPE.YOUTUBE_VIMEO && (
            <S.FormLinkContainer onSubmit={e => e.preventDefault()}>
              <S.FormGroup preview={isPreviewUrl} className={errorMessage ? 'error' : ''}>
                <S.ThumbnailWrapper isLoadedPreview={isPreviewUrl}>
                  {!isPreviewUrl && <LinkTypeIcon width={16} height={16} />}
                  {isPreviewUrl && (
                    <MediaModal
                      video={linkVideoData}
                      validVideo={true}
                      trigger={
                        <S.VideoThumbnailWrapper>
                          <S.VideoThumbnail
                            src={linkVideoThumbnail}
                            alt=""
                            onLoad={onLinkThumbnailLoadSuccess}
                            onError={onLinkThumbnailLoadError}
                            style={{ display: 'none' }}
                          />
                          <PlayIcon />
                        </S.VideoThumbnailWrapper>
                      }
                    />
                  )}
                </S.ThumbnailWrapper>
                <S.Controls>
                  <S.WrapperInput>
                    <input
                      ref={linkRef}
                      defaultValue={linkVideoData || ''}
                      type="url"
                      name="link-video"
                      placeholder="Youtube / Vimeo Link"
                      onChange={onChangeVideoLink}
                    />
                    <div className="btn-remove-link">{!isLinkError && <CloseIcon onClick={handleRemoveLink} />}</div>
                  </S.WrapperInput>
                  {isPreviewUrl && !errorMessage && <S.VideoTitle>{videoName || ''}</S.VideoTitle>}
                  {errorMessage && <S.VideoError>{errorMessage || ''}</S.VideoError>}
                </S.Controls>
              </S.FormGroup>
            </S.FormLinkContainer>
          )}
        </S.VideoSelectWrapper>
        <S.SaveButton disabled={isDisable} onClick={handleSave}>
          Save
        </S.SaveButton>
      </S.WelcomeVideoContainer>
    </S.WelcomeVideoWrapper>
  );
};

const mapStateToProps = state => {
  const {
    cloudfrontList,
    rootReducer: {
      customBranding: { customOnboardingVideoType, customOnboardingYoutube, customOnboardingUpload },
    },
  } = state;
  return {
    cloudfrontList,
    customOnboardingVideoType,
    customOnboardingYoutube,
    customOnboardingUpload,
  };
};

const mapDispatchToProps = dispatch => ({
  updateCustomBrandingPremium: bindActionCreators(updateCustomBrandingPremium, dispatch),
  showPreviewDraft: bindActionCreators(showPreviewDraft, dispatch),
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeVideo);
