import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const DayTooltip = styled.div`
  background: #f4f5f9;
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #575c77;
  position: absolute;
  transform: translate(50%, -50%);
`;

export const NoData = styled.div`
  color: #000000;
  opacity: 0.3;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  .weekly-report__chart {
    position: relative;

    .weekly-report__goal-tooltip {
      position: absolute;
      padding-left: 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #202353;
      background-repeat: no-repeat;
      background-position: 5px;
      background-color: #ffffff;

      &--average-reps {
        left: 35px;
        background-color: transparent;
        width: 100%;
        height: 10px;
        margin-top: 3px;

        .weekly-report__goal-tooltip--value {
          display: flex;
        }
      }

      &--avgLine {
        height: 2px;
        margin-top: 8px;
        width: calc(100% - 40px);
        cursor: pointer;
      }

      &--step-goal {
        background-image: url(${CDN_URL}/images/macros_training_day_bold.svg);
        background-color: transparent;
        background-position: right;
        width: calc(100% - 35px);
        left: 45px;
        height: 10px;
        margin-top: 2px;
      }

      &--line {
        height: 2px;
        margin-top: 6px;
        width: calc(100% - 20px);
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
`;
