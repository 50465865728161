import React from 'react';
import { connect } from 'react-redux';

import SearchInput from '../SearchInput';

import { SearchContainer } from '../styles';

import SectionsList from './SectionsList';
import { bindActionCreators } from 'redux';
import { loadMoreSection, searchSection } from 'redux/workout-builder/actions';

class SectionsTab extends React.PureComponent {
  state = {
    isLoading: false,
    textSearch: this.props.sectionTab.get('textSearch'),
    list: [],
    page: 1,
  };

  componentDidMount() {
    const { sectionTab } = this.props;
    const { list, textSearch } = sectionTab.toJS();
    if (!list.length) {
      this.searchSection(textSearch);
    } else {
      this.setState({ textSearch });
    }
  }

  onChangeTextSearch = textSearch => {
    this.setState({ textSearch }, this.searchSection);
  };

  searchSection = () => {
    const { textSearch, isSearching } = this.state;
    if (isSearching) {
      return;
    }
    this.setState({ isSearching: true });
    this.props.searchSection(textSearch).finally(() => {
      this.setState({ isSearching: false });
    });
  };

  loadMore = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    this.props.loadMoreSection().finally(() => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { textSearch, isLoading, isSearching } = this.state;
    const { sectionTab } = this.props;
    const { list, total, page } = sectionTab.toJS();
    return (
      <>
        <SearchContainer>
          <SearchInput
            defaultValue={textSearch}
            onChange={this.onChangeTextSearch}
            placeholder={`Search for your Sections`}
          />
        </SearchContainer>
        <div className="listItem__wrapper">
          <div className="listItem__container">
            <div className="workout-left-panel-content-title">
              <div className="title">{`${textSearch ? 'Results' : 'Most Recent'} (${total})`}</div>
              <div className="icons"></div>
            </div>
            <SectionsList
              textSearch={textSearch}
              listItem={list}
              isLoading={isLoading}
              isSearching={isSearching}
              loadMore={this.loadMore}
              page={page}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    rootReducer: { workoutBuilder },
  } = state;
  return {
    sectionTab: workoutBuilder.getIn(['sectionTab']),
  };
};

const mapDispatch = dispatch => ({
  searchSection: bindActionCreators(searchSection, dispatch),
  loadMoreSection: bindActionCreators(loadMoreSection, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(SectionsTab);
