import React from 'react';
import { components } from 'react-select';

const CustomDropdownIndicator = args => (
  <components.DropdownIndicator {...args}>
    <i
      aria-hidden="true"
      className="dropdown icon"
      style={{
        margin: '0px 3px 3px 3px',
        width: 14,
        height: 14,
        color: '#5B5B5B',
      }}
    />
  </components.DropdownIndicator>
);

export default CustomDropdownIndicator;
