import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;

  ${props =>
    props.isExpand &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

  ${props =>
    props.hasHabit &&
    css`
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;

      .item:first-child:nth-last-child(even),
      .item:first-child:nth-last-child(even) ~ .item {
        width: calc(50% - 10px);
      }

      .item:first-child:nth-last-child(odd),
      .item:first-child:nth-last-child(odd) ~ .item {
        width: calc(32% - 6px);
      }

      .item:first-child:nth-last-child(1),
      .item:first-child:nth-last-child(1) ~ .item {
        width: 100%;
      }
    `}

  .item {
    width: 100%;
    height: 157px;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #202353;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;

    .icon {
      height: 60px;
      display: flex;
      align-items: center;
    }

    img,
    svg {
      margin-bottom: 10px;
    }

    :hover {
      border-color: #5158cf;
    }
    &.with-upgrade-path {
      position: relative;
      cursor: not-allowed;
      > img {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .__react_component_tooltip {
        padding: 16px !important;
        border-radius: 8px !important;
        max-width: 385px !important;
      }
    }
  }
`;
