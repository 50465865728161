import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Button, Image } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import './style.scss';
import { PopupContainer } from './style';
import { togglePopup } from 'actions/popup';
import { CDN_URL } from 'constants/commonData';

const DEFAULT_RECT = { x:0, y: 0, height: 0, width: 0 };

class ExerciseQuickView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }

    this.triggerRef = React.createRef();
    this.contentRef = React.createRef();
  }

  getCoordinates = (triggerDOMRect = DEFAULT_RECT, contentWidth) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const { width, height, x, y } = triggerDOMRect;
    const rightSpacing = viewportWidth - (x + width);
    let result = {};

    if (x >= contentWidth || rightSpacing >= contentWidth) {
      // The content is displayed on the left or right of the trigger
      result.left = rightSpacing >= contentWidth ? x + width : x - contentWidth;
      
      if (y + height > viewportHeight - y) {
        result.bottom = viewportHeight - (y + height) - 10;
        result.maxHeight = y + height - 10;
      } else {
        result.top = y - 10;
        result.maxHeight = viewportHeight - y - 10;
      }

      if (result.left >= x) {
        result.marginLeft = 10;
        result.side = 'right';
      } else {
        result.marginRight = 10;
        result.side = 'left';
      }

      return  result;
    }

    //The content is displayed above or below the trigger
    if (viewportWidth - x >= contentWidth) {
      result.left = x;
    } else {
      result.right = 10;
    }

    if (y < viewportHeight - (y + height)) {
      result.top = y + height - 2;
      result.maxHeight = viewportHeight - (y + height) - 10;
    } else {
      result.bottom = viewportHeight - y - 2;
      result.maxHeight = y - 10;
    }

    result.side = isNaN(parseInt(result.top)) ? 'top' : 'bottom';

    if (result.side === 'bottom') {
      result.marginTop = 10;
    } else {
      result.marginBottom = 10;
    }

    return result;
  }

  onClosePopup = (event, checkDiff = false) => {
    const { onClose } = this.props;

    if (typeof onClose === 'function') {
      const callback = hidePopup => {
        this.setState({ open: !hidePopup })
      };

      onClose(callback, checkDiff)
    } else {
      this.setState({ open: false });
    }
  }

  renderPopupContent = () => {
    const { open } = this.state;
    const {
      widthInPercent,
      minWidth,
      width,
      className,
      header,
      body,
      footer,
      isModalOpen,
      isConfirmModalOpen,
      disabledCloseListener,
    } = this.props;
    const windowWidth =  window.innerWidth;
    let contentWidth = widthInPercent ? widthInPercent * windowWidth / 100 : width || 100;
    contentWidth = minWidth && contentWidth < minWidth ? minWidth : contentWidth;

    const triggerDOMRect = this.triggerRef.current ? this.triggerRef.current.getBoundingClientRect() : DEFAULT_RECT;
    let coordinates = this.getCoordinates(triggerDOMRect, contentWidth);
    const side = coordinates.side;
    let containerClass = `fixed-popup ${side}`;

    if (className) {
      containerClass += ' ' + className;
    }
    
    delete coordinates.side;

    return ReactDOM.createPortal((
      <RootCloseWrapper
        onRootClose={this.onClosePopup}
        event="click"
        disabled={!open || isModalOpen || isConfirmModalOpen || disabledCloseListener}
      >
        <PopupContainer
          className={containerClass}
          style={{...coordinates, width: contentWidth - 10 }}
          ref={this.contentRef}
          triggerDOMRect={triggerDOMRect}
          onMouseDown={event => {
            event.stopPropagation();
          }}
        >
          <Button
            onClick={event => {
              event.stopPropagation();
              this.onClosePopup(event, true);
            }}
            className="close-button"
          >
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <div className="fixed-popup__header">{header()}</div>
          <div className="fixed-popup__body">{body()}</div>
          <div className="fixed-popup__footer">{footer()}</div>
        </PopupContainer>
      </RootCloseWrapper>
    ), document.body);
  }

  render() {
    const { trigger, isConfirmModalOpen, popup, id, togglePopup, onOpen } = this.props;
    const { open } = this.state;

    if (!id) {
      console.error(" id is required ");
      return null;
    }

    return (
      <>
        {React.cloneElement(trigger, {
          onClick: event => {
            event.stopPropagation();

            if (id === popup && open) {
              this.onClosePopup();
            } else {
              if (!isConfirmModalOpen) {
                setTimeout(() => {
                  togglePopup(id);
                  typeof onOpen === 'function' && onOpen();
                  this.setState({ open: true });
                }, 0)
              }
            }
          },
          ref: this.triggerRef,
        })}
        {open && id === popup ? this.renderPopupContent() : null}
      </>
    )
  }
}

const mapStateToProps = state => {
  const { isModalOpen, isConfirmModalOpen, popup } = state;
  return { isModalOpen, isConfirmModalOpen, popup };
}

const mapDispatchToProps = dispatch => ({
  togglePopup: bindActionCreators(togglePopup, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseQuickView);

