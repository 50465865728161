import React from 'react';

import AddNewForm from '../AddNewForm';

import * as S from './style';
import FirstCreateNewIconSrc from 'assets/icons/first_create_new_product.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

function EmptyProductList(props) {
  const { isHideBanner, onAddNewProduct } = props;
  return (
    <S.EmptyProductList isHideBanner={isHideBanner}>
      <S.FirstCreateNewIcon src={FirstCreateNewIconSrc} />
      <S.EmptyProductTitle>No Sequence Available</S.EmptyProductTitle>
      <S.EmptyProductDescription>
        Create sequences that automatically onboard and roll out training assignments, resources and community forums to
        your clients.
      </S.EmptyProductDescription>

      <AddNewForm
        text="Create My First Sequence"
        heightButton={36}
        widthButton={209}
        icon={<PlusIcon />}
        maxLength={90}
        buttonClassName="buttonClassName"
        titlePopup="Create New Sequence"
        description="Set up a Sequence that can be linked to Packages and automatically assign Autoflows, Studio Programs, Resource Collections, and Forums upon purchase."
        titleInput="Sequence Name"
        placeholderInput="Name your Sequence"
        submitName="Create New"
        onAddNewProduct={onAddNewProduct}
      />
    </S.EmptyProductList>
  );
}

export default EmptyProductList;
