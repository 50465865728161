import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

import { CDN_URL } from 'constants/commonData';

const textBase = `
  color: #202353;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: 150%;
`;

export const Trigger = styled.div`
  width: 42px;
  height: 24px;
  background-color: #e4e5e7;
  border-radius: 3px;
  background-image: url(${CDN_URL}/images/filter.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;
  z-index: 999;
  :hover {
    background-color: #e7e6fe;
    background-image: url(${CDN_URL}/images/filter_purple.svg);
  }
`;

export const TriggerContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

export const PopupTitleHeader = styled.div`
  ${textBase}
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
`;

export const ClearAllFilter = styled.div`
  ${textBase}
  color: #5158cf;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SelectDietary = styled.div`
  padding: 0;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const RecipeFromWrapper = styled.div`
  display: flex;
  gap: 35px;
`;

export const RecipeFromItem = styled.div`
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  .recipe-check-box {
    padding-left: 20px;
    .checkbox-icon {
      width: 20px;
      height: 20px;
      background-size: 60%;
      background-position: 4px 4px;
      border-radius: 4px;
      border: 1px solid #e1e1ea;
    }
  }
`;

export const CheckboxLabel = styled.span`
  ${textBase}
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
`;

export const Checkbox = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #dadbea;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: #fff;
  svg {
    width: 8px;
    height: 8px;
  }
`;

export const SelectRangeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 22px;
  margin-bottom: 10px;
`;

export const FilterTitle = styled.div`
  ${textBase}
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
`;

export const FilterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  &:first-child {
    ${FilterTitle} {
      margin-bottom: 5px;
    }
  }
`;

export const Body = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${props =>
    !props.isShowRecipeStatus &&
    css`
      ${FilterSectionWrapper} {
        &:first-child {
          ${FilterTitle} {
            margin-bottom: unset;
          }
        }
      }
    `}
`;

export const Footer = styled.div`
  text-align: right;
  padding: 14px 20px 15px 15px;
  border-top: 1px solid #f0f0f2;
  .update-button {
    height: 30px;
    min-width: 73px;
    width: 73px;
    font-size: 12px;
    &:hover {
      opacity: 0.9;
    }
  }
`;

export const Popup = styled.div`
  width: 437px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  flex-direction: column;
  justify-content: flex-start;
  overflow: visible;
  display: none;
  position: fixed;
  height: auto;
  z-index: 999;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-right: 10px;
  user-select: none;
  &.open {
    ${Trigger} {
      background-color: #e7e6fe;
      background-image: url(${CDN_URL}/images/filter_purple.svg);
    }
    ${TriggerContainer} {
      ::after {
        display: block;
      }
    }
    ${Popup} {
      display: flex;
    }
  }
  &.filter-recipe-wrapper {
    .popup-content {
      position: absolute;
      left: unset !important;
      top: calc(100% + 17px) !important;
      right: calc(100% - 35px);
    }
  }
`;

const activeTrigger = `
  border-radius: 5px;
  background: #f0f1ff;
  .filter-label {
    color: #5158cf;
  }
  .filter-icon {
    path {
      fill: #5158cf;
    }
  }
`;

export const FilterButton = styled(Button)`
  margin-left: 10px;
  padding: 5px 10px;
  min-width: 85px;
  max-height: 30px;
  border-radius: 5px;
  border: 1px solid #dadbea;
  position: relative;
  .filter-label {
    margin-right: 2px;
    color: #202353;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  :hover {
    ${activeTrigger}
  }
  ${props =>
    props.active &&
    css`
      ${activeTrigger}
    `}
`;

export const FilterNumber = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  right: -9px;
  top: -9px;
  border-radius: 3px;
  box-shadow: 0 0 0 3px #fff;
  background: #ffbe49;
  ${textBase}
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;
`;
