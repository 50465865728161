import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #020202 url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: -6px;
  top: -6px;
  display: none;
`;

export const QuestionPlaceHolder = styled.div`
  width: 14px;
  height: 14px;
  background: transparent url(${CDN_URL}/images/question.svg) no-repeat center;
  background-size: contain;
`;

export const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    color: #6a778a;
    text-transform: uppercase;
    margin-right: 5px;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #6a778a;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .save {
      border-color: #5158cf;
      color: #5158cf;
      padding: 2px 14px;
      background-color: #fff;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      min-width: unset;
      margin-left: 15px;

      :hover {
        background-color: #5158cf;
        color: #fff;
      }
    }

    .cancel {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #ea314a;
      padding: 0;
      box-shadow: none;
      border: none;
      outline: none;
      background: transparent;
      min-width: unset;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

export const Item = styled.div`
  float: left;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
  padding: 8px 10px;
  margin-right: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #202353;
  white-space: nowrap;

  .index {
    color: #979797;
    margin-right: 3px;
  }

  :hover {
    cursor: grab;

    ${RemoveIcon} {
      display: block;
    }
  }

  :last-child {
    margin-right: 0;
  }
`;

export const ListFields = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-flow: row wrap;

  .select-field-dropdown {
    .select-field-dropdown__trigger {
      width: 68px;
      height: 32px;
      border: 1px solid #e0e4e5;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
`;

export const Wrapper = styled.div`
  background: #f6f7fb;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;

  .__react_component_tooltip.app-tooltip {
    font-size: 12px;
    font-weight: 600;
    padding: 7px 10px;
    line-height: 1.43;
    max-width: 260px;
  }
`;
