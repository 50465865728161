/**
 * @flow
 */

import React from 'react';

import { Modal, List, Segment, Button, Image } from 'semantic-ui-react';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import styles from './styles';
import './styles.scss';
import { toggleModal } from 'actions/modal';
import SearchInput from 'shared/SearchInput';
import GeneralButton from 'shared/GeneralButton';
import { escapeRegExp } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';


const getTimezoneLabel = (zone) => {
  return zone ? `(GMT ${moment.tz(zone).format('Z')}) ${zone}` : '';
}

const timezonesDefault = moment_tz.tz.names()
  .map(itm => {
    return { 
      key: itm,
      label: getTimezoneLabel(itm),
      value: itm,
      offset: moment.tz(itm)._offset
    };
  })
  .sort((a, b) => a.offset - b.offset);

export default class FindTimeZone extends React.Component<Props> {
  state = {
    searchQuery: '',
    selected: null
  };
  render() {
    return (
      <Modal
        className="find-timezone-modal"
        open={this.props.isModalOpen}
        onClose={() => this.props.dispatch(toggleModal(false))}
      >
        <Modal.Header style={styles.headerContainer}>
          <span className="assign-workout-title">Set timezone</span>
          <Button
            onClick={() => {
              this.handleCloseAction();
            }}
            className="close-button"
          >
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
        </Modal.Header>
        <Modal.Content>
          <div style={styles.contentContainer}>
            {this.renderSearch()}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <GeneralButton onClick={() => this.handleSelect()}>
              Select
            </GeneralButton>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }

  handleCloseAction() {

    this.props.dispatch(toggleModal(false));
  }

  handleSelect() {
    if (!this.state.selected) {
      return
    }
    const { clientId } = this.props
    this.props.updateTimeZone(this.state.selected, clientId)
  }

  renderSearch() {
    let timezones = timezonesDefault;
    if (this.state.searchQuery != '') {
      timezones = timezones
        .filter(itm => new RegExp(escapeRegExp(this.state.searchQuery), 'gi').test(itm.label))
    }
    return (
      <div className="timezone-container">
        <div className="timezone-search-bar">
          <SearchInput
            value={this.state.searchQuery}
            onChange={(evt, data) =>
              this.setState((p: State) => ({
                searchQuery: data.value,
              }))

            }
            placeholder={'Search timezone'}
          />
        </div>
        <div className="new-workout-col-title most-recent-title">
          Timezones ({timezones.length})
        </div>
        <Segment fluid style={styles.workoutListContainer}>
          <List verticalAlign={'middle'}>
            {timezones.map((item, idx) => (
              <List.Item active={(item.value == this.state.selected)} className='new-workout-list' onClick={() => {
                this.setState((p: State) => ({
                  selected: item.value,
                }))
              }}>
                <div className="new-workout-list-item">
                  {getTimezoneLabel(item.value)}
                </div>
              </List.Item>
            ))}
          </List>
        </Segment>
      </div>
    );
  }
}
