import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
`;

const limitText = `
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const AddCoach = styled(Modal)`
  &.ui.modal {
    width: 575px;
  }
  &.ui.modal > .header {
    padding: 16px 30px 24px;
    border-radius: 8px;
    img {
      margin-right: 16px;
    }
  }
  &.ui.modal > .content {
    padding: 0px 30px 86px;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .select-add-coach {
    margin-bottom: 24px;
  }
  .select-add-coach-prefix__control {
    border: 1px solid #dadfea;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: none;
    &:hover {
      border: 1px solid #dadfea;
    }
  }
  .select-add-coach-prefix__placeholder {
    ${baseText}
    font-weight: 400;
    line-height: 18px;
    color: #969797;
  }
  .select-add-coach-prefix__multi-value {
    background: #e7e7fd;
    border-radius: 4px;
    padding: 2px 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
  }
  .select-add-coach-prefix__multi-value__label {
    padding: 0;
  }
  .select-add-coach-prefix__multi-value__remove {
    padding: 0;
    margin-left: 4px;
    color: #5d5bbe;
    &:hover {
      color: #5d5bbe;
      background-color: transparent;
    }
  }
  .select-add-coach-prefix__menu-list {
    padding: 0;
    border-radius: 4px;
    .select__option {
      padding: 10px 16px;
      border-bottom: 1px solid #dadfea;
      cursor: pointer;
    }
    .select__option--is-focused {
      background: #f4f4ff;
    }
  }
`;

export const ContactInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .sb-avatar {
    margin-right: 14px;
    flex-shrink: 0;
  }
`;

export const SingleLabel = styled.div`
  overflow: hidden;
  .name {
    ${limitText}
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    white-space: nowrap;
  }

  .email {
    ${limitText}
    margin-top: 3px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
    opacity: 0.4;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ListPermission = styled.div`
  display: flex;
  align-items: center;
`;

export const TitlePermission = styled.label`
  ${baseText}
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6a778a;
  margin-right: 24px;
`;

export const ItemPermission = styled.div`
  ${baseText}
  font-weight: 400;
  color: #262626;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #262626;
  border-radius: 5px;
`;

export const Action = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 54px;
  padding: 0 30px;
  background: #ffffff;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  button {
    background: rgba(81, 88, 207, 1);
    height: 34px;
    &:hover:not([disabled]) {
      background: rgba(81, 88, 207, 0.9);
    }
  }
`;

export const Title = styled.h3`
  ${baseText}
  font-size: 18px;
  line-height: 25px;
`;
