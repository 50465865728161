import React from 'react';
import { ReactComponent as IconLightning } from 'assets/icons/icon-lightning.svg';
import { ReactComponent as IconStreak } from 'assets/icons/icon-streak.svg';
import { ReactComponent as IconTarget } from 'assets/icons/icon-target.svg';
import { connect } from 'react-redux';
import { pluralize } from 'utils/commonFunction';
import { TYPE_FREQUENCY } from '../../../../constants';
import * as S from './style';

const Streak = ({ currentStreak = 0, bestStreak = 0, averageHabit = 0, totalHabit = 0, type }) => {
  return (
    <S.Streak>
      <S.Item>
        <S.Icon>
          <IconStreak />
        </S.Icon>
        <S.Content>
          <S.Title>
            <span>{currentStreak}</span> {pluralize(type === TYPE_FREQUENCY.PER_DAY ? 'day' : 'week', currentStreak)}
          </S.Title>
          <S.Desc>current streak</S.Desc>
        </S.Content>
      </S.Item>
      <S.Item>
        <S.Icon bgColor="#FFFCE6">
          <IconLightning />
        </S.Icon>
        <S.Content>
          <S.Title>
            <span>{bestStreak}</span> {pluralize(type === TYPE_FREQUENCY.PER_DAY ? 'day' : 'week', bestStreak)}
          </S.Title>
          <S.Desc>best streak</S.Desc>
        </S.Content>
      </S.Item>
      <S.Item>
        <S.Icon bgColor="#EAF2FF">
          <IconTarget />
        </S.Icon>
        <S.Content>
          <S.Title>
            <span>{averageHabit}</span> /{totalHabit}
          </S.Title>
          <S.Desc>{type === TYPE_FREQUENCY.PER_DAY ? 'daily' : 'weekly'} average</S.Desc>
        </S.Content>
      </S.Item>
    </S.Streak>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Streak);
