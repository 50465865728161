import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const SectionFormat = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  color: #c0c4ca;
  vertical-align: bottom;
`;

export const FormatItem = styled.div`
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 60px 15px 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #202353;
  background-position: center 15px;
  background-size: 61px auto;
  background-repeat: no-repeat;
  background-image: url(${props => `${CDN_URL}/images/section_format_${props.type}.svg`});

  :hover,
  &.selected {
    background-image: url(${props => `${CDN_URL}/images/section_format_${props.type}_hover.svg`});
    border-color: #5158cf;
    cursor: pointer;
  }
`;

export const FormatList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;

  label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
  }
`;

export const CustomizeFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .cancel {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #6a778a;
    margin-right: 20px;

    :hover {
      color: #5158cf;
      cursor: pointer;
    }
  }

  button {
    padding: 5px 24px;
  }
`;

export const CustomizeForm = styled.div`
  background: #f6f7f9;
  border-radius: 5px;
  padding: 10px;
  width: 215px;
  margin: 0 15px 10px;

  .evfSelectBox__value-container {
    padding-left: 11px;
  }

  .evfSelectBox__dropdown-indicator {
    padding-right: 11px;
  }
`;

export const SectionFormatInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .inputContainer {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 10px 4px;
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left: 10px;

    .input {
      min-width: 15px;
      position: relative;
      height: 18px;
      width: auto;
      color: transparent;
      margin-right: 5px;

      input {
        outline: none;
        box-shadow: none;
        border: none;
        background-color: transparent;
        padding: 0;
        color: #202353;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        text-align: left;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    &.readOnly {
      background-color: rgba(255, 255, 255, 0.3);
      color: #fff;

      .input {
        span {
          opacity: 0.4;
          color: #fff;
        }
      }
    }

    &.invalid {
      background: #fef4f6;
      border-color: #ea314a;
    }
  }

  .label {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #c0c4ca;
  }

  .__react_component_tooltip.app-tooltip {
    padding: 15px;
    width: 230px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #ffffff;
    text-align: left;
  }
`;

export const Wrapper = styled.div`
  background: linear-gradient(90.16deg, #2a307c 0.08%, #23233f 99.94%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 10px 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .section__title {
    display: inline-block;
    margin-left: 15px;

    .text-editable__container {
      border: 1px solid transparent;
      padding: 2px 0 2px 7px;
      max-width: 210px;

      .text-editable__input {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #fff;
        text-overflow: clip;
        overflow: hidden;
        overflow-x: scroll;
        white-space: nowrap;

        ::-webkit-scrollbar {
          display: none;
        }
      }

      :hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
        background-color: rgba(244, 244, 255, 0.28);
        padding-right: 10px;

        .text-editable__input {
          color: #ffffff;
        }
      }

      &--active {
        background-color: #fff;
        padding-right: 10px;
        width: 210px;

        .text-editable__input {
          color: #202353;
        }
      }

      &--invalid {
        width: 210px;
        border-color: #ea314a;
        background-color: #fef4f6;
      }

      :not(.text-editable__container--active):not(.text-editable__container--invalid) {
        .text-editable__input {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .section__header__rightSide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .section__header__leftSide {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      &.toggle {
        :hover {
          cursor: pointer;

          path {
            fill: #fff;
          }
        }

        &.minimize {
          transform: rotate(-90deg);
        }

        transition: transform 0.3s ease-in-out;
      }
    }
  }
`;
