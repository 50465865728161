import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { diff } from 'deep-diff';
import classnames from 'classnames';
import Select, { components } from 'react-select';
import Datetime from 'react-datetime';
import { Emoji } from 'emoji-picker-react';
import { Icon } from 'semantic-ui-react';
import { FormGroup, DateInput } from 'shared/FormControl';
import Dropdown from 'shared/Dropdown/Basic';
import DropdownOptions from 'shared/DropdownOptions';
import Reminder from '../Reminder';
import AdvancedSettings from '../AdvancedSettings';
import ChooseIconsPopup from '../ChooseIconsPopup';

import { LIST_DAYS, LIST_ORDER_DAYS } from 'components/TaskRepeat/constants';
import { validateNumberOnly } from 'utils/validations';
import { convertLabelForDropdown, convertReminderTime, generateGroupWeeks } from 'utils/commonFunction';

import * as S from './style';
import {
  REPEAT,
  FREQUENCY,
  DEFAULT_EMOJI,
  LIMIT_TITLE_LENGTH,
  DEFAULT_REMINDER_TIME,
  BACKGROUND_LIST,
  INTERVAL_REPEAT,
} from 'constants/habitData';
import { AUTOFLOW_TYPES } from 'constants/commonData';

const MAX_LENGTH = 90;

const BuilderContent = (props, ref) => {
  const {
    date,
    clientId,
    prevHabit,
    habitUnits,
    viewDetails,
    toggleModal,
    addHabit,
    updateHabit,
    replace,
    habit,
    setHabit,
    submitting,
    editMode,
    invalidDate,
    invalidInput,
    setSubmitting,
    setInvalidDate,
    setInvalidInput,
    setEditMode,
    clientTimezone,
    open,
    setOpen,
    totalWeeks,
    autoflowType,
    autoflowId,
    addAutoflowIntervalHabit,
    updateAutoflowIntervalHabit,
    addAutoflowHabit,
    updateAutoflowHabit,
    setIsShowComment,
  } = props;
  const endDateRef = useRef();
  const [endDatePosition, setEndDatePosition] = useState(0);

  const customHabitUnit = convertLabelForDropdown(habitUnits);

  /*
   - New habit: Default selected date from calendar
   - Update habit: Default selected start date
  */
  const defaultStartDate =
    autoflowType === AUTOFLOW_TYPES.INTERVAL
      ? _.get(prevHabit, 'repeat_setting.autoflow_interval_day', date + 1) % 7
      : moment(_.get(prevHabit, 'start_date', date)).day();
  const [defaultSelected, setDefaultSelected] = useState(defaultStartDate || 7);
  const intervalStartDate = _.get(prevHabit, 'repeat_setting.autoflow_interval_day')
    ? _.get(prevHabit, 'repeat_setting.autoflow_interval_day') - 1
    : date;
  const intervalEndDate = _.get(prevHabit, 'repeat_setting.autoflow_interval_end_day')
    ? _.get(prevHabit, 'repeat_setting.autoflow_interval_end_day') - 1
    : -1;
  const defaultPeriod = {
    startDate: moment(_.get(prevHabit, 'start_date', date)).format('YYYY-MM-DD'),
    endDate: _.get(prevHabit, 'end_date', ''),
    repeat: _.get(prevHabit, 'repeat_setting.type')
      ? convertLabelForDropdown([prevHabit.repeat_setting], 'type')[0]
      : REPEAT[0],
  };
  const defaultIntervalPeriod = {
    startDate: intervalStartDate
      ? { key: intervalStartDate + 1, label: `Day ${intervalStartDate + 1}`, value: intervalStartDate }
      : { key: 1, label: 'Day 1', value: 0 },
    endDate:
      intervalEndDate > 0
        ? { key: intervalEndDate + 1, label: `Day ${intervalEndDate + 1}`, value: intervalEndDate }
        : { key: 0, label: '-', value: -1 },
    repeat: _.get(prevHabit, 'repeat_setting.autoflow_interval_repeat_type')
      ? convertLabelForDropdown([prevHabit.repeat_setting], 'autoflow_interval_repeat_type')[0]
      : INTERVAL_REPEAT[0],
  };
  const chooseIconRef = useRef();

  const [title, setTitle] = useState(_.get(prevHabit, 'title', ''));
  const [selectedEmoji, setSelectedEmoji] = useState(
    _.get(prevHabit, 'color', '') ? _.pick(prevHabit, ['icon', 'icon_unified', 'color']) : DEFAULT_EMOJI,
  );
  const [goalValue, setGoalValue] = useState(_.get(prevHabit, 'goal_value'));
  const [unit, setUnit] = useState(
    customHabitUnit.find(item => item.name === _.get(prevHabit, 'unit.name', '')) || customHabitUnit[0],
  );
  const [frequency, setFrequency] = useState(
    FREQUENCY.find(item => item.value === _.get(prevHabit, 'frequency', '')) || FREQUENCY[0],
  );
  const [period, setPeriod] = useState(
    autoflowType === AUTOFLOW_TYPES.INTERVAL ? defaultIntervalPeriod : defaultPeriod,
  );
  const [selectedDays, setSelectedDays] = useState(
    _.get(prevHabit, 'repeat_setting.date_selected_in_weekly', [defaultSelected]),
  );
  const [reminder, setReminder] = useState(_.get(prevHabit, 'schedule_reminder', false));
  const [reminderTime, setReminderTime] = useState(
    _.get(prevHabit, 'reminder_time')
      ? convertReminderTime({ value: prevHabit.reminder_time, beforeFormat: 'HH:mm:ss', afterFormat: 'hh:mm A' })
      : DEFAULT_REMINDER_TIME,
  );
  const [comment, setComment] = useState(_.get(prevHabit, 'enable_comment', true));
  const withValueLimit = ({ value }) => value.length < 5;
  const diffStartEndDate = period.endDate && moment(period.endDate).diff(moment(period.startDate), 'days');
  const diffIntervalDate = _.get(period, 'endDate.value', 0) - _.get(period, 'startDate.value', 0);
  const hasEnded = _.get(prevHabit, 'has_ended', false);

  useEffect(() => {
    const endDateWrapper = _.get(endDateRef, 'current');
    if (endDateWrapper) {
      const { top = 0 } = endDateWrapper.getBoundingClientRect();
      setEndDatePosition(top);
    }
  }, []);

  useEffect(() => {
    setUnit(customHabitUnit.find(item => item.name === _.get(prevHabit, 'unit.name', '')) || customHabitUnit[0]);
  }, [customHabitUnit.length]);

  useImperativeHandle(ref, () => ({
    handleSave,
    isSameOriginData,
  }));

  const handleChangeTitle = value => {
    const trimmed = value.trim();
    setSubmitting(false);
    setInvalidInput(prev => ({ ...prev, title: !trimmed }));
    if (trimmed.length <= LIMIT_TITLE_LENGTH) {
      setTitle(trimmed);
    } else {
      if (trimmed.length === LIMIT_TITLE_LENGTH + 1) {
        setTitle(title.trim().slice(0, LIMIT_TITLE_LENGTH));
      } else {
        setTitle(trimmed.slice(0, LIMIT_TITLE_LENGTH));
      }
    }
  };

  const handleChangeNumber = useCallback(
    values => {
      const newValue = values.value;
      setGoalValue(parseInt(newValue));
      setSubmitting(false);
      if (_.lowerCase(unit.value) === 'cups') {
        setInvalidInput(prev => ({
          ...prev,
          goalValue: !newValue || parseInt(newValue) < 1 || parseInt(newValue) > 20,
        }));
      } else {
        setInvalidInput(prev => ({
          ...prev,
          goalValue: !newValue || parseInt(newValue) < 1 || parseInt(newValue) > 999,
        }));
      }
    },
    [_.get(unit, 'value')],
  );

  const handleChangeUnit = option => {
    setUnit(option);
    setInvalidInput(prev => ({
      ...prev,
      goalValue:
        !goalValue ||
        parseInt(goalValue) < 1 ||
        (_.lowerCase(option.value) === 'cups' ? goalValue > 20 : goalValue > 999),
    }));
  };

  const handleChangeFrequency = option => {
    setFrequency(option);
    if (
      option.value === 'per_week' &&
      _.get(habit, 'repeat_setting.type') !== 'interval' &&
      !_.get(habit, 'original_autoflow')
    ) {
      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        const validWeeks = period.startDate.value + 7 < totalWeeks * 7;
        diffIntervalDate < 7 &&
          setPeriod({
            ...period,
            endDate: validWeeks
              ? {
                  key: period.startDate.key + 7,
                  label: `Day ${period.startDate.key + 7}`,
                  value: period.startDate.value + 7,
                }
              : { key: 0, label: '-', value: -1 },
          });
        setInvalidDate(!validWeeks);
      } else {
        diffStartEndDate < 7 &&
          setPeriod({ ...period, endDate: moment(period.startDate).add(7, 'days').format('YYYY-MM-DD') });
        setInvalidDate(false);
      }
    }
    if (option.value === 'per_day') {
      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        period.endDate.value >= 0 && setInvalidDate(diffIntervalDate < 1);
      } else {
        period.endDate && setInvalidDate(diffStartEndDate < 1);
      }
    }
  };

  const handleChangePeriod = (value, name) => {
    switch (name) {
      case 'startDate':
        {
          const cloneSelectedDays = [...selectedDays];
          cloneSelectedDays.shift();
          cloneSelectedDays.unshift(value.day() ? value.day() : 7);
          setDefaultSelected(value.day() ? value.day() : 7);
          setSelectedDays(_.uniq(cloneSelectedDays));
          const diffDate = period.endDate && moment(period.endDate).diff(value, 'days');

          setInvalidDate(
            period.endDate &&
              ((frequency.value === 'per_day' && diffDate < 1) || (frequency.value === 'per_week' && diffDate < 7)),
          );
          setPeriod({ ...period, [name]: value.format('YYYY-MM-DD') });
        }

        break;

      case 'endDate':
        {
          const diffDate = value && value.diff(moment(period.startDate), 'days');

          setInvalidDate(
            (frequency.value === 'per_day' && diffDate < 1) || (frequency.value === 'per_week' && diffDate < 7),
          );
          setSubmitting(false);
          setPeriod({ ...period, [name]: value.format('YYYY-MM-DD') });
        }

        break;

      default:
        setPeriod({ ...period, [name]: value });
        setSelectedDays(value === 'daily' ? [] : [defaultSelected]);
        break;
    }
  };

  const onSelectDay = data => {
    const startDate = _.get(data, 'value', 0);
    const endDate = _.get(period, 'endDate.value', 0);
    const diffDate = endDate - startDate;
    const cloneSelectedDays = [...selectedDays];
    cloneSelectedDays.shift();
    cloneSelectedDays.unshift((startDate % 7) + 1);
    endDate >= 0 && setInvalidDate(frequency.value === 'per_day' ? diffDate < 1 : diffDate < 7);
    setPeriod({ ...period, startDate: data });
    setDefaultSelected((startDate % 7) + 1);
    setSelectedDays(_.uniq(cloneSelectedDays));
  };

  const onSelectEndDay = data => {
    const startDate = _.get(period, 'startDate.value', 0);
    const endDate = _.get(data, 'value', 0);
    const diffDate = endDate - startDate;
    setInvalidDate(frequency.value === 'per_day' ? diffDate < 1 : diffDate < 7);
    setPeriod({ ...period, endDate: data });
  };

  const handleChangeReminderTime = data => {
    setReminderTime(data.label);
  };

  const handleUpdateSelectedEmoji = data => {
    chooseIconRef.current && chooseIconRef.current();
    setSelectedEmoji(data);
  };

  const handleRepeatDays = item => () => {
    let clone = _.cloneDeep(selectedDays);
    if (_.includes(clone, item)) {
      clone = _.filter(clone, it => it != item);
    } else {
      clone.push(item);
    }
    setSelectedDays(clone);
  };

  const isChecked = value => {
    return _.includes(selectedDays, value);
  };

  const renderDateTimeInput = props => {
    return (
      <DateInput
        {...props}
        text={props.value ? (period[props.name] ? moment(period[props.name]) : moment()).format('MMM D, YYYY') : '-'}
      />
    );
  };

  const renderDay = (props, currentDate) => {
    const startDateMoment = moment(period.startDate, 'YYYY-MM-DD');
    const endDateMoment = moment(period.endDate, 'YYYY-MM-DD');
    let className = props.className || '';

    const isStartDate = currentDate.isSame(startDateMoment, 'day');
    const isEndDate = currentDate.isSame(endDateMoment, 'day');
    const isValidDate =
      (frequency.value === 'per_day' && diffStartEndDate >= 1) ||
      (frequency.value === 'per_week' && diffStartEndDate >= 7);

    if (isValidDate && currentDate.isBetween(startDateMoment, endDateMoment)) {
      className += ' inRange';
    }

    if (isStartDate && isValidDate && period.endDate) {
      className += ' rdtActive rangeStart';
    }

    if (isEndDate && isValidDate && period.endDate) {
      className += ' rdtActive rangeEnd';
    }

    // Adds 0 to the days in the days view
    return (
      <td {...props} className={className}>
        <div>{currentDate.date() < 10 ? '0' + currentDate.date() : currentDate.date()}</div>
      </td>
    );
  };

  const renderGroup = props => <components.Group {...props} />;

  const getStartEndCalendar = () => {
    const startDate = moment(period.startDate).format('YYYY-MM-DD');
    const endDate = period.endDate ? moment(period.endDate).format('YYYY-MM-DD') : '';
    return {
      start_date: startDate,
      end_date: endDate,
    };
  };

  const handleSave = async isClose => {
    if (submitting) return;

    setInvalidInput({
      title: !title.trim(),
      goalValue: !goalValue,
    });
    let isValidDate = true;

    if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
      if (period.endDate.label === '-') {
        setInvalidDate(true);
        return;
      }
    } else {
      isValidDate = frequency.value === 'per_day' ? diffStartEndDate >= 1 : diffStartEndDate >= 7;
      setInvalidDate(
        period.endDate
          ? (frequency.value === 'per_day' ? diffStartEndDate < 1 : diffStartEndDate < 7) &&
              !_.get(habit, 'original_autoflow')
          : true,
      );
    }

    if (title.trim() && goalValue > 0 && (isValidDate || _.get(habit, 'original_autoflow'))) {
      setSubmitting(true);
      const { start_date, end_date } = getStartEndCalendar();
      const prevRepeatType = _.get(habit, 'repeat_setting.type');
      const prevSelectedDays = _.get(habit, 'repeat_setting.date_selected_in_weekly');
      const repeatType = _.get(period, 'repeat.value', '');
      let repeatSetting = {};

      if (repeatType && prevSelectedDays && (repeatType !== prevRepeatType || diff(prevSelectedDays, selectedDays))) {
        repeatSetting = {
          type: repeatType,
          date_selected_in_weekly: repeatType === 'weekly' ? selectedDays : [],
        };
      }

      let params = {};
      const id = _.get(habit, '_id');
      let data;

      if (id && editMode) {
        params = {
          schedule_reminder: reminder,
          reminder_time: convertReminderTime({ value: reminderTime, beforeFormat: 'hh:mm A', afterFormat: 'HH:mm:ss' }),
          enable_comment: comment,
        };
        if (_.get(habit, 'title', '') !== title) params.title = title;
        if (_.get(habit, 'icon', '') !== selectedEmoji.icon) params.icon = selectedEmoji.icon;
        if (_.get(habit, 'icon_unified', '') !== selectedEmoji.icon_unified)
          params.icon_unified = selectedEmoji.icon_unified;
        if (_.get(habit, 'color', '') !== selectedEmoji.color) params.color = selectedEmoji.color;
        if (_.get(habit, 'goal_value') !== goalValue) params.goal_value = goalValue;
        if (_.get(habit, 'unit._id', '') !== unit._id) params.unit = unit._id;
        if (_.get(habit, 'frequency', '') !== frequency.value) params.frequency = frequency.value;

        if (autoflowType) {
          if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
            repeatSetting = {
              autoflow_interval_day: _.get(period, 'startDate.value', 0) + 1,
              autoflow_interval_end_day: _.get(period, 'endDate.value', 0) + 1,
              autoflow_interval_repeat_type: repeatType,
            };
            if (repeatType === 'week') repeatSetting.date_selected_in_weekly = selectedDays;
            params = {
              ...params,
              repeat_setting: repeatSetting,
            };

            try {
              data = await updateAutoflowIntervalHabit(id, params);
            } catch (error) {
              setSubmitting(false);
            }
          } else {
            if (_.get(habit, 'start_date', '') !== period.startDate) params.start_date = period.startDate;
            if (_.get(habit, 'end_date', '') !== period.endDate) params.end_date = period.endDate;
            if (!_.isEmpty(repeatSetting)) params.repeat_setting = repeatSetting;
            try {
              data = await updateAutoflowHabit(id, params);
            } catch (error) {
              setSubmitting(false);
            }
          }
        } else {
          if (_.get(habit, 'start_date', '') !== period.startDate) params.start_date = period.startDate;
          if (_.get(habit, 'end_date', '') !== period.endDate) params.end_date = period.endDate;
          if (!_.isEmpty(repeatSetting)) params.repeat_setting = repeatSetting;
          try {
            data = await updateHabit(id, params);
          } catch (error) {
            setSubmitting(false);
          }
        }
      } else {
        repeatSetting = {
          type: repeatType,
        };
        if (repeatType === 'weekly') repeatSetting.date_selected_in_weekly = selectedDays;
        params = {
          habit_library: id,
          title,
          icon: selectedEmoji.icon,
          icon_unified: selectedEmoji.icon_unified,
          color: selectedEmoji.color,
          goal_value: goalValue,
          unit: unit._id,
          frequency: frequency.value,
          schedule_reminder: reminder,
          reminder_time: convertReminderTime({ value: reminderTime, beforeFormat: 'hh:mm A', afterFormat: 'HH:mm:ss' }),
          enable_comment: comment,
          repeat_setting: repeatSetting,
        };

        if (autoflowType) {
          params = {
            ...params,
            autoflow: autoflowId,
          };
          if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
            repeatSetting = {
              autoflow_interval_day: _.get(period, 'startDate.value', 0) + 1,
              autoflow_interval_end_day: _.get(period, 'endDate.value', 0) + 1,
              autoflow_interval_repeat_type: repeatType,
            };
            if (repeatType === 'week') repeatSetting.date_selected_in_weekly = selectedDays;
            params = {
              ...params,
              repeat_setting: repeatSetting,
            };

            try {
              data = await addAutoflowIntervalHabit(params);
            } catch (error) {
              setSubmitting(false);
            }
          } else {
            params = {
              ...params,
              start_date,
              end_date,
            };
            try {
              data = await addAutoflowHabit(params);
            } catch (error) {
              setSubmitting(false);
            }
          }
        } else {
          params = {
            ...params,
            client: clientId,
            start_date,
            end_date,
          };
          try {
            data = await addHabit(params);
          } catch (error) {
            setSubmitting(false);
          }
        }
      }

      data = _.get(data, 'data.data');
      if (data) {
        setHabit({ ...habit, ...data });
        setSubmitting(false);
        setEditMode && setEditMode(true);
        setIsShowComment && setIsShowComment(true);
        if (isClose) {
          toggleModal(false);
          if (autoflowType) {
            replace(`/home/autoflow/${autoflowId}/task/calendar`);
          } else {
            replace(`/home/client/${clientId}/task`);
          }
        }
      }
    }
  };

  const isSameOriginData = originData => {
    let repeatSetting = {
      type: 'daily',
      date_selected_in_weekly: [],
    };

    if (_.get(period, 'repeat.value', '') === 'weekly') {
      repeatSetting.type = 'weekly';
      repeatSetting.date_selected_in_weekly = selectedDays;
    }

    if (_.get(originData, 'repeat_setting.type')) {
      repeatSetting.type = _.get(originData, 'repeat_setting.type');
    }

    let habitOriginData = {};
    if (_.isEmpty(originData)) {
      habitOriginData = {
        title: '',
        icon: DEFAULT_EMOJI.icon,
        icon_unified: DEFAULT_EMOJI.icon_unified,
        color: DEFAULT_EMOJI.color,
        goal_value: undefined,
        unit: _.pick(customHabitUnit[0], '_id'),
        frequency: FREQUENCY[0].value,
        start_date: moment(date).format('YYYY-MM-DD'),
        end_date: '',
        repeat_setting: {
          type: REPEAT[0].value,
          date_selected_in_weekly: [],
        },
        reminder_time: convertReminderTime({
          value: DEFAULT_REMINDER_TIME,
          beforeFormat: 'hh:mm A',
          afterFormat: 'HH:mm:ss',
        }),
        schedule_reminder: false,
        enable_comment: true,
      };

      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        habitOriginData.repeat_setting = {
          autoflow_interval_day: date + 1,
          autoflow_interval_end_day: 0,
          autoflow_interval_repeat_type: INTERVAL_REPEAT[0].value,
        };
        if (_.get(period, 'repeat.value', '') === 'week')
          habitOriginData.repeat_setting.date_selected_in_weekly = [defaultSelected];
        habitOriginData = _.omit(habitOriginData, ['start_date', 'end_date']);
      }
    } else {
      habitOriginData = _.pick(originData, [
        'title',
        'icon',
        'icon_unified',
        'color',
        'goal_value',
        'unit._id',
        'frequency',
        'start_date',
        'end_date',
        'repeat_setting.type',
        'repeat_setting.date_selected_in_weekly',
        'reminder_time',
        'schedule_reminder',
        'enable_comment',
      ]);

      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        habitOriginData = _.pick(originData, [
          'title',
          'icon',
          'icon_unified',
          'color',
          'goal_value',
          'unit._id',
          'frequency',
          'reminder_time',
          'schedule_reminder',
          'enable_comment',
        ]);
        habitOriginData.repeat_setting = {
          autoflow_interval_day: _.get(originData, 'repeat_setting.autoflow_interval_day', intervalStartDate + 1),
          autoflow_interval_end_day: _.get(originData, 'repeat_setting.autoflow_interval_end_day', intervalEndDate + 1),
          autoflow_interval_repeat_type: _.get(
            originData,
            'repeat_setting.autoflow_interval_repeat_type',
            INTERVAL_REPEAT[0].value,
          ),
        };
        if (_.get(period, 'repeat.value', '') === 'week')
          habitOriginData.repeat_setting.date_selected_in_weekly = _.get(
            originData,
            'repeat_setting.date_selected_in_weekly',
            [defaultSelected],
          );
        habitOriginData = _.omit(habitOriginData, ['start_date', 'end_date']);
      } else {
        if (_.isEmpty(_.get(habitOriginData, 'start_date'))) {
          habitOriginData.start_date = moment(date).format('YYYY-MM-DD');
        }
        if (_.isEmpty(_.get(habitOriginData, 'end_date'))) {
          habitOriginData.end_date = '';
        }
        if (_.get(originData, 'repeat_setting.autoflow_interval_repeat_type') === 'day') {
          habitOriginData.repeat_setting.date_selected_in_weekly = [];
        }
      }

      if (_.isEmpty(_.get(habitOriginData, 'reminder_time'))) {
        habitOriginData.reminder_time = convertReminderTime({
          value: DEFAULT_REMINDER_TIME,
          beforeFormat: 'hh:mm A',
          afterFormat: 'HH:mm:ss',
        });
      }
      if (_.isEmpty(_.get(habitOriginData, 'repeat_setting'))) {
        habitOriginData.repeat_setting = {
          type: REPEAT[0].value,
          date_selected_in_weekly: REPEAT[0].value === 'daily' ? [] : [defaultSelected],
        };
      }
      if (_.isEmpty(_.get(habitOriginData, 'unit'))) {
        habitOriginData.unit = {
          _id: _.get(originData, 'unit'),
        };
      }
    }

    let currentState = {
      title,
      icon: selectedEmoji.icon,
      icon_unified: selectedEmoji.icon_unified,
      color: selectedEmoji.color,
      goal_value: goalValue,
      unit: _.pick(unit, '_id'),
      frequency: frequency.value,
      start_date: period.startDate,
      end_date: period.endDate,
      repeat_setting: repeatSetting,
      reminder_time: convertReminderTime({
        value: reminderTime,
        beforeFormat: 'hh:mm A',
        afterFormat: 'HH:mm:ss',
      }),
      schedule_reminder: reminder,
      enable_comment: comment,
    };

    if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
      repeatSetting = {
        autoflow_interval_day: _.get(period, 'startDate.value', 0) + 1,
        autoflow_interval_end_day: _.get(period, 'endDate.value', 0) + 1,
        autoflow_interval_repeat_type: _.get(period, 'repeat.value', ''),
      };
      if (_.get(period, 'repeat.value', '') === 'week') repeatSetting.date_selected_in_weekly = selectedDays;
      currentState = _.omit(currentState, ['start_date', 'end_date']);
      currentState = { ...currentState, repeat_setting: repeatSetting };
    }

    const isSame = _.isEqual(habitOriginData, currentState);
    return isSame;
  };

  const checkDate = currentDate => {
    const selectedDate = (clientTimezone ? moment().tz(clientTimezone) : moment()).format('MM/DD/YYYY');
    const checkDate = moment(currentDate.format('MM/DD/YYYY')).isSameOrAfter(selectedDate);
    return checkDate;
  };

  const autoflowHabitId =
    _.get(habit, 'original_autoflow_interval_habit', '') || _.get(habit, 'original_autoflow_exact_date_habit', '');
  const showError = !_.get(habit, 'original_autoflow') && !hasEnded && period.endDate;
  const showLongError =
    autoflowType === AUTOFLOW_TYPES.INTERVAL
      ? _.get(period, 'endDate.label') !== '-' && diffIntervalDate < 0
      : diffStartEndDate < 0;

  const handleOnKeyDown = event => {
    const innerText = event.target.innerText;

    if (innerText.length === MAX_LENGTH && event.keyCode === 32) {
      event.preventDefault();
    }
  };

  return (
    <>
      <FormGroup largeMargin className="input--task-name">
        <div className="title__wrapper">
          <Dropdown
            disabled={submitting || hasEnded}
            closeDropdownRef={chooseIconRef}
            triggerIcon={({ open }) => {
              setOpen(open);
              return (
                <S.HabitIcon
                  background={_.get(
                    BACKGROUND_LIST.find(item => item.border === selectedEmoji.color),
                    'background',
                    '#FCF5DE',
                  )}
                  disabled={submitting || hasEnded}
                >
                  <Emoji unified={selectedEmoji.icon_unified} size="24" />
                </S.HabitIcon>
              );
            }}
          >
            <ChooseIconsPopup open={open} prevEmoji={selectedEmoji} onUpdate={handleUpdateSelectedEmoji} />
          </Dropdown>
          <S.TextEditable
            value={title}
            autoFocus={!title}
            onChange={handleChangeTitle}
            placeholder="Name your Habit"
            invalid={invalidInput.title}
            maxLength={MAX_LENGTH}
            readOnly={open || (submitting && title.trim()) || hasEnded}
            onKeyDown={handleOnKeyDown}
          />
        </div>
        <div className="title__wrapper">
          <div />
          <span className="count">{title.length} / 90</span>
        </div>
      </FormGroup>
      <S.GoalValuesWrapper hasError={invalidInput.goalValue && goalValue}>
        <S.NumberInput
          fullWidth={viewDetails}
          value={goalValue}
          onKeyPress={validateNumberOnly}
          inputMode="numeric"
          onValueChange={handleChangeNumber}
          isNumericString={true}
          decimalSeparator={false}
          placeholder="Goal Value"
          error={invalidInput.goalValue}
          isAllowed={withValueLimit}
          disabled={viewDetails || (submitting && goalValue > 0) || hasEnded}
        />
        <DropdownOptions
          width="125px"
          height="44px"
          options={customHabitUnit}
          selectedValue={_.get(unit, 'value')}
          onSelect={handleChangeUnit}
          isForceUpdate={true}
          className="dropdown--habit-unit"
          readOnly={viewDetails || submitting || hasEnded}
        />
        <DropdownOptions
          width="122px"
          height="44px"
          options={FREQUENCY}
          selectedValue={frequency.value}
          onSelect={handleChangeFrequency}
          isForceUpdate={true}
          className="dropdown--habit-frequency"
          readOnly={viewDetails || submitting || hasEnded}
        />
      </S.GoalValuesWrapper>
      {invalidInput.goalValue && goalValue ? (
        <S.GoalValueErrorMessage viewDetails={viewDetails}>
          {(() => {
            if (_.lowerCase(_.get(unit, 'value')) === 'cups' && parseInt(goalValue) > 20) {
              return 'Maximum value is 20';
            }
            if (parseInt(goalValue) > 999) {
              return 'Maximum value is 999';
            }
            return '';
          })()}
        </S.GoalValueErrorMessage>
      ) : null}
      <S.HabitPeriodWrapper>
        <S.PeriodLabel>Habit Period</S.PeriodLabel>
        <S.PeriodWrapper isInterval={_.get(habit, 'original_autoflow')} longMessage={showError && showLongError}>
          <S.DateTimeContainer isInterval={autoflowType === AUTOFLOW_TYPES.INTERVAL}>
            <S.DateTimeWrapper
              disabled={viewDetails || submitting || hasEnded || _.get(habit, 'original_autoflow')}
              isViewDetails={viewDetails}
              isInterval={false}
            >
              <S.PeriodLabel>START DATE</S.PeriodLabel>
              {autoflowType === AUTOFLOW_TYPES.INTERVAL ? (
                <Select
                  options={generateGroupWeeks(totalWeeks)}
                  components={{
                    DropdownIndicator: () => <Icon name="chevron down" size="small" />,
                    IndicatorSeparator: null,
                    Group: renderGroup,
                  }}
                  onChange={onSelectDay}
                  classNamePrefix="evfSelectBox"
                  className="evfSelectBoxContainer interval-select-date"
                  value={period.startDate}
                  isSearchable={false}
                  styles={{
                    control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 143 }),
                    minWidth: 143,
                  }}
                  menuPosition="fixed"
                />
              ) : (
                <Datetime
                  value={period.startDate ? moment(period.startDate) : null}
                  renderDay={renderDay}
                  renderInput={props => renderDateTimeInput({ ...props, name: 'startDate' })}
                  timeFormat={false}
                  onChange={value => handleChangePeriod(value, 'startDate')}
                  closeOnSelect={true}
                  className="new-ui"
                  {...Object.assign(
                    {},
                    viewDetails || submitting || hasEnded || _.get(habit, 'original_autoflow') ? { open: false } : {},
                  )}
                />
              )}
            </S.DateTimeWrapper>
            <S.DateTimeWrapper
              disabled={(submitting && period.endDate) || hasEnded || _.get(habit, 'original_autoflow')}
              position={viewDetails && endDatePosition}
              ref={endDateRef}
              isInterval={false}
            >
              <S.PeriodLabel>END DATE</S.PeriodLabel>
              {autoflowType === AUTOFLOW_TYPES.INTERVAL ? (
                <Select
                  options={generateGroupWeeks(totalWeeks)}
                  components={{
                    DropdownIndicator: () => <Icon name="chevron down" size="small" />,
                    IndicatorSeparator: null,
                    Group: renderGroup,
                  }}
                  onChange={onSelectEndDay}
                  classNamePrefix="evfSelectBox"
                  className={classnames('evfSelectBoxContainer interval-select-date', { 'invalid-date': invalidDate })}
                  value={period.endDate}
                  isSearchable={false}
                  styles={{
                    control: base => ({ ...base, minHeight: 36, height: 36, minWidth: 143 }),
                    minWidth: 143,
                  }}
                  menuPosition="fixed"
                />
              ) : (
                <Datetime
                  value={period.endDate ? moment(period.endDate) : null}
                  renderDay={renderDay}
                  renderInput={props => renderDateTimeInput({ ...props, name: 'endDate' })}
                  timeFormat={false}
                  onChange={value => handleChangePeriod(value, 'endDate')}
                  closeOnSelect={true}
                  isValidDate={checkDate}
                  className={classnames('new-ui', { error: invalidDate })}
                  {...Object.assign(
                    {},
                    (submitting && period.endDate) || hasEnded || _.get(habit, 'original_autoflow')
                      ? { open: false }
                      : {},
                  )}
                />
              )}
              {invalidDate && (!period.endDate || _.get(period, 'endDate.label') === '-') ? (
                <S.ErrorMessage>Please choose an end date</S.ErrorMessage>
              ) : null}
              {showError &&
              frequency.value === 'per_day' &&
              (autoflowType === AUTOFLOW_TYPES.INTERVAL
                ? _.get(period, 'endDate.label') !== '-' && diffIntervalDate < 1
                : diffStartEndDate < 1) ? (
                <S.ErrorMessage longMessage={showLongError}>
                  {showLongError ? 'The end date should be greater than the start date' : 'Minimum 1 day apart'}
                </S.ErrorMessage>
              ) : null}
              {showError &&
              frequency.value === 'per_week' &&
              (autoflowType === AUTOFLOW_TYPES.INTERVAL
                ? _.get(period, 'endDate.label') !== '-' && diffIntervalDate < 7
                : diffStartEndDate < 7) ? (
                <S.ErrorMessage longMessage={showLongError}>
                  {showLongError ? 'The end date should be greater than the start date' : 'Minimum 7 days apart'}
                </S.ErrorMessage>
              ) : null}
            </S.DateTimeWrapper>
          </S.DateTimeContainer>
          {!_.get(habit, 'original_autoflow') ? (
            <S.RepeatWrapper>
              <S.PeriodLabel paddingRight={autoflowType === AUTOFLOW_TYPES.INTERVAL ? '2px' : '24px'}>
                REPEAT
              </S.PeriodLabel>
              <DropdownOptions
                width="107px"
                height="36px"
                options={autoflowType === AUTOFLOW_TYPES.INTERVAL ? INTERVAL_REPEAT : REPEAT}
                selectedValue={period.repeat.value}
                onSelect={value => handleChangePeriod(value, 'repeat')}
                isForceUpdate={true}
                className="dropdown--habit-repeat"
                readOnly={viewDetails || submitting || hasEnded}
              />
              {['weekly', 'week'].includes(period.repeat.value) ? (
                <>
                  <S.RepeatTitle>{autoflowType === AUTOFLOW_TYPES.INTERVAL ? 'on' : 'every'}</S.RepeatTitle>
                  <S.DayOfWeeksWrapper disabled={viewDetails || submitting || hasEnded}>
                    <div className="list-rest-day">
                      {_.cloneDeep(autoflowType === AUTOFLOW_TYPES.INTERVAL ? LIST_ORDER_DAYS : LIST_DAYS).map(
                        (day, index) => (
                          <div key={`${day.value}-${index}`}>
                            <input
                              type="checkbox"
                              name={day.value}
                              value={day.value}
                              disabled={_.isEqual(day.value, defaultSelected) || viewDetails || submitting || hasEnded}
                              checked={isChecked(day.value)}
                              id={`repeat-day-${day.value}`}
                              onChange={handleRepeatDays(day.value)}
                            />
                            <label className="rest-day-spec" htmlFor={`repeat-day-${day.value}`}>
                              {day.label}
                            </label>
                          </div>
                        ),
                      )}
                    </div>
                  </S.DayOfWeeksWrapper>
                  {autoflowType === AUTOFLOW_TYPES.INTERVAL ? <S.RepeatTitle>day</S.RepeatTitle> : null}
                </>
              ) : null}
            </S.RepeatWrapper>
          ) : (
            <S.SyncedAutoflow>
              <S.GreenDot />
              <S.SyncedAutoflowText>
                This habit is synced from Autoflow{' '}
                <S.AutoflowName
                  href={
                    autoflowHabitId
                      ? `/home/autoflow/${_.get(
                          habit,
                          'original_autoflow._id',
                        )}/task/calendar/${autoflowHabitId}/detail`
                      : `/home/autoflow/${_.get(habit, 'original_autoflow._id')}/task/calendar`
                  }
                >
                  {_.get(habit, 'original_autoflow.name', '')}
                </S.AutoflowName>
              </S.SyncedAutoflowText>
            </S.SyncedAutoflow>
          )}
        </S.PeriodWrapper>
      </S.HabitPeriodWrapper>
      <S.SetTimeWrapper disabled={submitting || hasEnded}>
        <Reminder
          habit
          disabled={submitting || hasEnded}
          className="custom-reminder"
          scheduleReminder={reminder}
          reminderTime={reminderTime}
          onChangeReminderTime={handleChangeReminderTime}
          onToggleScheduleReminder={() => setReminder(!reminder)}
        />
      </S.SetTimeWrapper>
      <S.Breaker />
      <AdvancedSettings
        hideReminder
        highlightTitle
        disabled={submitting || hasEnded}
        task={{ enable_comment: comment }}
        onToggleComment={() => setComment(!comment)}
        expand={viewDetails}
        activeClassName="habit"
      />
    </>
  );
};

export default forwardRef(BuilderContent);
