import Request from 'configs/request';

export const Types = {
  TEAMMATE_SUCCESS_HIDE_BANNER: 'TEAMMATE_SUCCESS_HIDE_BANNER',
  REQUEST_CHANGE_AUTO_WAITING_ENROLL: 'REQUEST_CHANGE_AUTO_WAITING_ENROLL',
  SUCCESS_CHANGE_AUTO_WAITING_ENROLL: 'SUCCESS_CHANGE_AUTO_WAITING_ENROLL',
};

export const hideTeammateBanner = () => {
  return Request.post({ url: '/api/team/hide-banner' }, false, (response, { dispatch }) => {
    dispatch({ type: Types.TEAMMATE_SUCCESS_HIDE_BANNER });
  });
};

export const changeAutoWaitingEnroll = data => {
  return dispatch => {
    dispatch({ type: Types.REQUEST_CHANGE_AUTO_WAITING_ENROLL });

    return dispatch(
      Request.patch({ url: '/api/team/change-auto-waiting-enroll', data }, true, (response, { dispatch }) => {
        dispatch({ type: Types.SUCCESS_CHANGE_AUTO_WAITING_ENROLL });
      }),
    );
  };
};
