import React from 'react';

import { ReactComponent as GiphyIcon } from 'assets/icons/giphy_forum_post_icon.svg';
import * as S from 'shared/Giphy/styles';

function ForumPostTrigger(props) {
  return (
    <S.ForumPostTrigger {...props}>
      <GiphyIcon />
    </S.ForumPostTrigger>
  );
}

export default ForumPostTrigger;
