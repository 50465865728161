import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useMyContext } from '../context/GroupBodyMetricsContext';
import ItemGroupBodyMetric from './ItemGroupBodyMetric';

import * as S from '../style';

const OrganizeGroup = props => {
  const { clientId } = props;
  const {
    groupMetric: { list: groupMetricList = [], textSearch, loading, metrics } = {},
    placeholderProps,
    handleGroupDragEnd,
    handleGroupDragStart,
    handleGroupDragUpdate,
    handleGroupBeforeCapture,
  } = useMyContext();
  const [groupResults, setGroupResults] = useState(groupMetricList);
  const [metricResults, setMetricResults] = useState(0);
  const [metricInGroupResult, setMetricInGroupResult] = useState([]);

  const renderItemGroupBodyMetric = (it = {}, idx, prefixSearch = '') => (
    <ItemGroupBodyMetric
      key={`${prefixSearch}${it._id}`}
      item={it}
      index={idx}
      clientId={clientId}
      textSearch={textSearch}
      prefixId={prefixSearch}
    />
  );

  useEffect(() => {
    setGroupResults(groupMetricList.filter(item => ((item || {}).title || '').toLowerCase().includes(textSearch)));

    if (!isEmpty(metrics)) {
      const metricsByName = Object.keys(metrics).filter(item => {
        const result = metrics[item].name.toLowerCase().includes(textSearch);
        return result;
      });

      let metricNumberResult = 0;
      const groupsHasMetricsResult = groupMetricList
        .map(group => {
          const result = group.metrics.filter(group => metricsByName.includes(group));
          metricNumberResult += result.length;
          return {
            ...group,
            metrics: result,
          };
        })
        .filter(group => !isEmpty((group || {}).metrics || []));
      setMetricResults(metricNumberResult);
      setMetricInGroupResult(groupsHasMetricsResult);
    }
  }, [textSearch, groupMetricList, metrics]);

  return (
    <DragDropContext
      onDragEnd={handleGroupDragEnd}
      onDragStart={handleGroupDragStart}
      onDragUpdate={handleGroupDragUpdate}
      onBeforeCapture={handleGroupBeforeCapture}
    >
      {isEmpty(groupResults) && isEmpty(metricInGroupResult) && !loading ? (
        <S.EmptySearchUI>
          <S.EmptyResults>No results found.</S.EmptyResults>
        </S.EmptySearchUI>
      ) : (
        <Droppable droppableId="droppableGroup" type="GROUP">
          {(provided, snapshot) => (
            <S.GroupBodyMetricWrapper
              ref={provided.innerRef}
              {...provided.droppableProps}
              id="group-body-metric-wrapper"
            >
              {!textSearch ? (
                groupMetricList.map((item, index) => renderItemGroupBodyMetric(item, index, 'group_'))
              ) : (
                <S.ResultWrapper>
                  {!isEmpty(metricInGroupResult) && (
                    <S.MetricResult>
                      <S.ResultLabel> Metrics ({metricResults}) </S.ResultLabel>
                      {metricInGroupResult.map((item, index) => renderItemGroupBodyMetric(item, index, 'metric_'))}
                    </S.MetricResult>
                  )}
                  {!isEmpty(groupResults) && (
                    <S.GroupResult>
                      <S.ResultLabel>Groups ({groupResults.length}) </S.ResultLabel>
                      {groupResults.map((item, index) => renderItemGroupBodyMetric(item, index, 'group_'))}
                    </S.GroupResult>
                  )}
                </S.ResultWrapper>
              )}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <div
                  className="placeholder"
                  style={{
                    top: placeholderProps.clientY,
                    left: placeholderProps.clientX,
                    height: placeholderProps.clientHeight,
                    width: placeholderProps.clientWidth,
                  }}
                />
              )}
            </S.GroupBodyMetricWrapper>
          )}
        </Droppable>
      )}
    </DragDropContext>
  );
};

export default OrganizeGroup;
