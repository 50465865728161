import { Types } from './actions';
import { get, forEach, findIndex, cloneDeep, map, uniqBy } from 'lodash';

const INITITAL_STATE = {
  tasks: [],
  habits: [],
  copying: null,
  isMoving: false,
};

export default (state = INITITAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case Types.AUTOFLOW_INTERVAL_SUCCESS_GET_TASKS:
      let tasks = [];
      let habits = [];
      forEach(payload.data, data => {
        let listDays = [];
        let listHabitsDays = [];
        forEach(data.days_task, day => {
          listDays[day.day_index] = day.day_task.tasks;
          listHabitsDays[day.day_index] = day.day_task.habits;
        });
        tasks[data.weekIndex] = listDays;
        habits[data.weekIndex] = listHabitsDays;
      });
      return Object.assign({}, state, { tasks, habits });

    case Types.AUTOFLOW_INTERVAL_TASK_RESET_DATA:
      return INITITAL_STATE;

    case Types.AUTOFLOW_INTERVAL_COPY_TASK:
      return Object.assign({}, state, { copying: payload.data });

    case Types.AUTOFLOW_INTERVAL_TASK_CLEAR_COPY_ITEM:
      return Object.assign({}, state, { copying: null });

    case Types.AUTOFLOW_INTERVAL_REMOVE_SETTING_REPEAT: {
      const newTasks = map(state.tasks, week => {
        return map(week, day => {
          return day
            ? day.filter(
                item =>
                  !item.repeat_task_setting_id ||
                  (item.repeat_task_setting_id &&
                    (item.repeat_task_setting_id._id || item.repeat_task_setting_id) !== payload.data.repeatId),
              )
            : day;
        });
      });
      return Object.assign({}, state, { tasks: newTasks });
    }

    case Types.AUTOFLOW_INTERVAL_REMOVE_TASK_REPEAT: {
      const { dayIndex, weekIndex } = payload.data;
      const newTasks = map(state.tasks, (week, weekStateIndex) => {
        return map(week, (day, dayStateIndex) => {
          return day && ((weekStateIndex === weekIndex && dayStateIndex >= dayIndex) || weekStateIndex > weekIndex)
            ? day.filter(
                item =>
                  !item.repeat_task_setting_id ||
                  (item.repeat_task_setting_id &&
                    (item.repeat_task_setting_id._id || item.repeat_task_setting_id) !== payload.data.repeatId),
              )
            : day;
        });
      });
      return Object.assign({}, state, { tasks: newTasks });
    }

    case Types.AUTOFLOW_INTERVAL_CREATED_TASK_SUCCESS: {
      const { dayIndex, weekIndex } = payload.data;
      const newTasks = cloneDeep(state.tasks);
      newTasks[weekIndex] &&
        newTasks[weekIndex][dayIndex] &&
        (newTasks[weekIndex][dayIndex] = uniqBy([...newTasks[weekIndex][dayIndex], payload.data], '_id'));
      return { ...state, tasks: newTasks };
    }

    case Types.AUTOFLOW_INTERVAL_REMOVED_TASK_SUCCESS: {
      const { dayIndex, weekIndex } = payload.data;
      const newTasks = cloneDeep(state.tasks);
      newTasks[weekIndex] &&
        newTasks[weekIndex][dayIndex] &&
        (newTasks[weekIndex][dayIndex] = newTasks[weekIndex][dayIndex].filter(
          item => item._id !== payload.data.taskId,
        ));
      return { ...state, tasks: newTasks };
    }

    case Types.AUTOFLOW_INTERVAL_MOVE_TASK_REQUEST: {
      return { ...state, isMoving: true };
    }

    case Types.AUTOFLOW_INTERVAL_MOVE_TASK:
      const { newIndex, newWeekIndex, newDayIndex, oldWeekIndex, oldDayIndex, taskId } = payload.data;
      const newTasks = cloneDeep(state.tasks);
      let movedTask = null;

      if (get(newTasks, `[${oldWeekIndex}][${oldDayIndex}]`)) {
        movedTask = newTasks[oldWeekIndex][oldDayIndex].find(task => task._id === taskId);
        newTasks[oldWeekIndex][oldDayIndex] = newTasks[oldWeekIndex][oldDayIndex].filter(task => task._id !== taskId);
      } else {
        return state;
      }

      if (!newTasks[newWeekIndex]) newTasks[newWeekIndex] = [];

      if (newTasks[newWeekIndex][newDayIndex]) {
        newTasks[newWeekIndex][newDayIndex].splice(newIndex, 0, movedTask);
      } else {
        newTasks[newWeekIndex][newDayIndex] = [movedTask];
      }

      return { ...state, tasks: newTasks };

    case Types.AUTOFLOW_INTERVAL_MOVE_TASK_SUCCESS: {
      return { ...state, isMoving: false };
    }

    case Types.AUTOFLOW_INTERVAL_MOVE_TASK_FAILED: {
      return { ...state, isMoving: false };
    }

    case Types.AUTOFLOW_INTERVAL_ARRANGE_TASK:
      const newArrangeDayTasks = [...state.tasks];
      let selectedTask = null;
      let oldIndex = undefined;

      if (get(newArrangeDayTasks, `[${payload.data.weekIndex}][${payload.data.dayIndex}]`)) {
        selectedTask = newArrangeDayTasks[payload.data.weekIndex][payload.data.dayIndex].find(
          task => task._id === payload.data.taskId,
        );
        oldIndex = findIndex(
          newArrangeDayTasks[payload.data.weekIndex][payload.data.dayIndex],
          task => task._id === payload.data.taskId,
        );
      }

      if (!selectedTask || oldIndex === payload.data.newIndex) return { ...state, tasks: newArrangeDayTasks };

      newArrangeDayTasks[payload.data.weekIndex][payload.data.dayIndex].splice(oldIndex, 1);
      newArrangeDayTasks[payload.data.weekIndex][payload.data.dayIndex].splice(payload.data.newIndex, 0, selectedTask);
      newArrangeDayTasks[payload.data.weekIndex][payload.data.dayIndex] = [
        ...newArrangeDayTasks[payload.data.weekIndex][payload.data.dayIndex],
      ];
      return { ...state, tasks: [...newArrangeDayTasks] };

    case Types.AUTOFLOW_INTERVAL_TASK_RESET_CALENDAR_DATA:
      return INITITAL_STATE;

    case Types.AUTOFLOW_INTERVAL_TASK_UPDATE_MULTIPLE_DAYS: {
      const newArrTasks = [...state.tasks];

      forEach(payload.data, dayData => {
        if (!newArrTasks[dayData.weekIndex]) {
          newArrTasks[dayData.weekIndex] = [];
        }

        newArrTasks[dayData.weekIndex][dayData.day_index] = dayData.tasks;
      });

      return { ...state, tasks: newArrTasks };
    }

    default:
      return state;
  }
};
