/**
 * @flow
 */

import React from 'react';
import _ from 'lodash';
import Avatar from 'react-avatar';
import classNames from 'classnames';
import { Form, Label, Dropdown, Header, Item } from 'semantic-ui-react';
import NoOwnerAvatar from 'assets/icons/no_owner_avatar.svg';
import { TEAM_SHARE, TEAM_SHARE_SHARED, TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import './styles.scss';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

export default class Ownership extends React.Component {
  state = {};

  render() {
    const {
      owner,
      share,
      isEditAble,
      handleChangeOwner,
      handleChangeShare,
      user,
      isTeamAdmin,
      disableOwner,
      disableSharing,
      hasAvatar = false,
      hasSpacing = false,
      hasYou = false,
      isTrainer,
      cloudfrontList,
    } = this.props;

    if (!user) {
      return null;
    }
    let ownerOptions;
    let ownerValue = owner;
    if (!owner || share === TEAM_SHARE_NOOWNER) {
      ownerValue = 'no_owner';
    }
    if ((user.team_member && !isEditAble && ownerValue !== 'no_owner') || isTeamAdmin) {
      ownerOptions = user.team_member.map(item => {
        if (hasAvatar) {
          const fullName = item.full_name || `${item.first_name} ${item.last_name}`;
          return {
            text: hasYou && _.get(user, '_id', '') === _.get(item, '_id', '') ? `${fullName} (You)` : fullName,
            value: item._id,
            content: (
              <Header
                className="ownership-header"
                icon={
                  <Avatar
                    src={convertS3UrlToCloudFrontUrl(item.avatar, cloudfrontList, true) || ''}
                    size="30"
                    color={item.color}
                    name={getUserShortName(item)}
                    alt={fullName}
                  />
                }
                content={hasYou && _.get(user, '_id', '') === _.get(item, '_id', '') ? `${fullName} (You)` : fullName}
                subheader={item.email}
              />
            ),
          };
        }
        return {
          text:
            hasYou && _.get(user, '_id', '') === _.get(item, '_id', '')
              ? `${item.first_name} ${item.last_name} (You)`
              : `${item.first_name} ${item.last_name}`,
          value: item._id,
        };
      });
    } else {
      const fullName = `${user.first_name} ${user.last_name}`;
      let ownerOption = {
        text: hasYou ? `${fullName} (You)` : fullName,
        value: user._id,
      };
      if (hasAvatar) {
        ownerOption = {
          ...ownerOption,
          content: (
            <Header
              className="ownership-header"
              icon={
                <Avatar
                  src={convertS3UrlToCloudFrontUrl(user.avatar, cloudfrontList, true) || ''}
                  color={user.color}
                  size="30"
                  name={fullName}
                  alt={fullName}
                />
              }
              content={hasYou ? `${fullName} (You)` : fullName}
              subheader={user.email}
            />
          ),
        };
      }
      ownerOptions = [ownerOption];
    }

    let noOwnerOption = {
      text: 'No owner (shared)',
      value: 'no_owner',
    };
    if (hasAvatar) {
      noOwnerOption = {
        ...noOwnerOption,
        content: (
          <Header
            className="ownership-header"
            icon={<Avatar src={NoOwnerAvatar} size="30" name="No owner (shared)" alt="No owner (shared)" />}
            content="No owner (shared)"
            subheader=""
          />
        ),
      };
    }
    ownerOptions.push(noOwnerOption);
    let shareOptions = [
      {
        text: TEAM_SHARE[TEAM_SHARE_PRIVATE],
        value: TEAM_SHARE_PRIVATE,
      },
      {
        text: TEAM_SHARE[TEAM_SHARE_SHARED],
        value: TEAM_SHARE_SHARED,
      },
    ];

    return (
      <div>
        <Form.Field className="client_type_field">
          <Label className="client-profile-label">OWNER</Label>
        </Form.Field>
        <Dropdown
          fluid={hasAvatar}
          defaultValue={ownerValue}
          selection
          disabled={!!disableOwner || (!isTeamAdmin && owner !== user._id && ownerValue !== 'no_owner')}
          className={classNames('ownership-dropdown', { 'has-spacing': hasSpacing })}
          options={isTrainer ? ownerOptions.filter(it => it.value === owner || it.value === 'no_owner') : ownerOptions}
          onChange={handleChangeOwner}
        />
        {share !== TEAM_SHARE_NOOWNER && (
          <Form.Field className="client_type_field share-with-org">
            <Label className="client-profile-label">SHARE WITH ORG?</Label>
          </Form.Field>
        )}
        {share !== TEAM_SHARE_NOOWNER && (
          <Dropdown
            defaultValue={share}
            disabled={!!disableSharing || (!isTeamAdmin && owner !== user._id && ownerValue !== 'no_owner')}
            selection
            className={classNames('ownership-dropdown', { 'has-spacing': hasSpacing })}
            options={shareOptions}
            onChange={handleChangeShare}
          />
        )}
      </div>
    );
  }
}
