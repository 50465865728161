import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import { diff } from 'deep-diff';

import { decryptData, genTokenFromMarketplace, getCookie } from 'utils/commonFunction';
import { Wrapper } from './style';

import {
  createNewConversationFromTransferData,
  getRoomIfExists,
  getAllRooms,
  changeProfileId,
  resetSearchRoom,
} from 'redux/inbox-new/actions';
import { C_KEY_TOKEN } from 'constants/commonData';

class InboxRedirectPage extends React.Component {
  componentDidMount() {
    this.handleRedirect();
    this.props.resetSearchRoom();
  }

  componentDidUpdate(prevProps) {
    if (diff(this.props, prevProps)) {
      this.handleRedirect();
    }
  }

  handleRedirect = () => {
    const {
      rooms,
      location: { search, pathname },
      loadedRooms,
      user,
      dispatch,
    } = this.props;

    const params = new URLSearchParams(search);
    const profileId = params.get('profileId');
    const first_name = params.get('ufn');
    const last_name = params.get('uln');
    const firstName = params.get('firstName');
    const lastName = params.get('lastName');

    // MP
    const token = getCookie(C_KEY_TOKEN);
    if (token && search.includes('redirect-platform')) {
      const replacePathname = search.includes('&') && pathname + search.replace('&redirect-platform', '');
      genTokenFromMarketplace({ token, user, pathname, search, dispatch, replacePathname });
      return;
    }

    if (!loadedRooms && !rooms.length) {
      this.props.getAllRooms(null, profileId);
      return;
    }

    if (profileId) {
      setTimeout(() => {
        this.props.getRoomIfExists({ profileId, saveToStore: true }).then(existed => {
          if (existed) {
            this.props.replace(`/home/inbox/${existed.type === 'group' ? existed.id : existed.parnerData.uid}`);
          } else {
            const firstNameData = first_name ? decryptData(first_name) : firstName || '';
            const lastNameData = last_name ? decryptData(last_name) : lastName || '';

            const clientData = {
              _id: profileId,
              value: profileId,
              key: profileId,
              first_name: firstNameData,
              last_name: lastNameData,
              label: `${firstNameData || ''} ${lastNameData || ''}`,
            };

            this.props.createNewConversationFromTransferData(clientData);
            this.props.replace('/home/inbox/new');
            this.props.changeProfileId(profileId);
          }
        });
      }, 0);
    } else {
      if (rooms.length) {
        this.props.replace(`/home/inbox/${rooms[0].type === 'group' ? rooms[0].id : rooms[0].parnerData.uid}`);
      } else {
        this.props.replace('/home/inbox/new');
      }
    }
  };

  render() {
    return <Wrapper></Wrapper>;
  }
}

const mapState = state => {
  const {
    rootReducer: {
      inboxNew: { rooms, loadedRooms, query },
    },
    user,
  } = state;

  return { rooms: rooms || [], loadedRooms, query, user };
};

const mapDispatch = dispatch => ({
  replace: bindActionCreators(replace, dispatch),
  createNewConversationFromTransferData: bindActionCreators(createNewConversationFromTransferData, dispatch),
  getRoomIfExists: bindActionCreators(getRoomIfExists, dispatch),
  getAllRooms: bindActionCreators(getAllRooms, dispatch),
  changeProfileId: bindActionCreators(changeProfileId, dispatch),
  resetSearchRoom: bindActionCreators(resetSearchRoom, dispatch),
  dispatch,
});

export default connect(mapState, mapDispatch)(InboxRedirectPage);
