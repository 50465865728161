import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7B7E91;
`;

export const UnitInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    ${baseText}
    margin-bottom: 2px;
    padding-left: 1px;
    text-transform: uppercase;
    .required {
      color: #ea314a;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      .unit-input {
        pointer-events: none;
        background-color: #f8f8f8;
      }
    `}
`;

export const UnitInput = styled.div`
  border: 1px solid #dadbea;
  border-radius: 5px;
  overflow: hidden;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #202353;
    border: none;
    padding: 0;
    outline: none;
    width: 100%;
    background-color: transparent;
  }
  span {
    ${baseText}
    font-weight: 400;
    font-size: 11px;
    line-height: 16.5px;
    color: #202353;
    padding-left: 10px;
  }
`;
