import React from 'react';

import { ReactComponent as DefaultCategoryBackground } from 'assets/icons/drop_image.svg';
import { ReactComponent as EditSelectedWorkoutIcon } from 'assets/icons/edit-dark-outline.svg';
import { ReactComponent as CloseSelectedWorkoutIcon } from 'assets/icons/close.svg';
import { convertS3UrlToCloudFrontUrl, getWorkoutSummary, pluralize } from 'utils/commonFunction';

import * as S from './style';

export default function SelectedWorkout({
  selectedWorkout,
  onEditWorkout,
  onUnselectWorkout,
  background,
  customBrandingBackground,
  disabled = false,
  cloudfrontList,
}) {
  const { sections, exercises } = getWorkoutSummary(selectedWorkout);
  const getImgUrl = url => convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);

  return (
    <S.SelectedWorkout>
      <div className="left">
        <div className={`background-container ${selectedWorkout.background ? 'hide-border' : ''}`}>
          {background ? (
            <img src={getImgUrl(background)} alt={selectedWorkout.title} />
          ) : selectedWorkout.background ? (
            <img src={getImgUrl(selectedWorkout.background)} alt={selectedWorkout.title} />
          ) : customBrandingBackground ? (
            <img src={getImgUrl(customBrandingBackground.src)} alt={selectedWorkout.title} />
          ) : (
            <DefaultCategoryBackground />
          )}
        </div>
        <div className="workout-base-info-container">
          <div className="title">{selectedWorkout.title}</div>
          <div className="extra-info-text">
            {exercises} {pluralize('Exercise', exercises)} • {sections} {pluralize('Section', sections)}
          </div>
        </div>
      </div>
      {!disabled && (
        <div className="right actions-container">
          <EditSelectedWorkoutIcon onClick={onEditWorkout} />
          <CloseSelectedWorkoutIcon onClick={onUnselectWorkout} className="gray-fill" />
        </div>
      )}
    </S.SelectedWorkout>
  );
}
