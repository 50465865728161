import styled, { css } from 'styled-components';
import { Trigger } from '../Select/style.js';
import { Button } from 'shared/FormControl';
import { NotACoachSection } from '../style.js';

export const Questionnaire = styled.div`
  position: relative;
  margin-left: 130px;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`;

export const NextButton = styled(Button)`
  width: 150px;
  max-width: 150px;
  height: 36px;
  max-height: 36px;

  ${props =>
    props.purple &&
    css`
      background: #7570ee;
      :hover {
        color: #fff;
        background-color: #7570ee;
      }
      :disabled {
        background: #cdcdcd;
        cursor: not-allowed;
      }
    `}
`;

export const QuestionTitle = styled.div`
  width: 370px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #000000;

  margin-top: 78px;
  margin-bottom: 32px;
`;

export const BannerIntro = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionSelect = styled.div`
  ${Trigger} {
    min-width: 156px;
    margin-left: -6px;
  }
  .questionnaire-custom {
    img {
      user-select: none;
    }
    .questionaire__select__menu {
      left: -6px;
      width: 156px;
      overflow-y: scroll;
      max-height: calc(100vh / 2 - 70px);

      .questionaire__select__option {
        font-size: 13px;
        line-height: 100%;
      }

      ${props =>
        props.isMobile &&
        css`
          margin-bottom: 0;
          max-height: calc(100vh / 2 - 120px);
        `}
    }
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 77px;
  right: -220px;

  .actions {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    ${NotACoachSection} {
      position: unset;
      margin-top: 22px;
      color: #000000;
      opacity: 0.6;
    }
  }
`;

const MobileCss = css`
  overflow-y: hidden;

  ${Body} {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  ${Banner} {
    svg {
      width: 253px;
      height: 228px;
    }
  }

  ${BannerIntro} {
    display: none;
  }

  ${Questionnaire} {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }

  ${ButtonWrapper} {
    position: initial;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 139px;

    ${NextButton} {
      width: 335px;
      max-width: 335px;
    }
  }

  ${QuestionSelect} {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;

    ${Trigger} {
      min-width: 215px;
    }
    .questionnaire-custom {
      img {
        user-select: none;
      }
      .questionaire__select__menu {
        padding: 15px;
        width: 215px;
        margin-bottom: 100px;

        .questionaire__select__option {
          border: 1px solid #f0f0f0;
          border-radius: 5px;
          margin-bottom: 10px;
          font-weight: normal;
          font-size: 13px;
          line-height: 100%;
          padding: 15px;

          :last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  ${QuestionTitle} {
    width: 100%;
    margin-top: -20px;
    margin-bottom: 26px;
    text-align: center;
  }
`;

export const QuestionnaireContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;

  ${NotACoachSection} {
    width: 100%;
  }

  ${props => props.isMobile && MobileCss}
`;

export const BannerTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  margin-top: 27px;
  margin-bottom: 5px;
`;

export const BannerDesc = styled.div`
  width: 339px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;

  color: #000000;
  opacity: 0.6;
`;

export const Spacing = styled.div`
  height: 100px;
  background-color: red;
`;
