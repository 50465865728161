import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const GoalCountdownPopupStyled = styled(Modal)`
  &.goal-countdown-popup {
    width: auto !important;

    .content {
      display: flex;
      height: calc(100vh - 198px);
      padding: 0 !important;
      border-radius: 8px !important;
      overflow: hidden;
    }

    .countdown-wrapper {
      display: flex;
      flex: 1;
      height: 100%;

      .tabs {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        min-width: 248px;
        padding: 16px 0 0 24px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: #f0f0f2;
        box-shadow: -8px 0px 12px 0px #00000008 inset;

        h2 {
          font-size: 19px;
          line-height: 28.5px;
          font-weight: 600;
          color: #202353;
          margin-bottom: 11px;
        }

        .tab {
          display: flex;
          gap: 10px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding: 15px;
          align-items: center;
          font-size: 13px;
          line-height: 19.5px;
          font-weight: 600;
          color: #7b7e91;
          cursor: pointer;
          background: linear-gradient(90deg, #ffffff 29.35%, rgba(255, 255, 255, 0) 80.65%);

          &:hover {
            color: #5158cf;

            svg path {
              fill: #5158cf;
            }
          }

          &.active {
            color: #5158cf;
            background: #ffffff;

            svg path {
              fill: #5158cf;
            }
          }
        }
      }

      .tab-content {
        flex: 1;
        min-width: 672px;
        max-width: 672px;
      }
    }
  }
`;
