import styled, { css } from 'styled-components';
import { FormGroup as FG } from 'shared/FormControl';

export const FormGroup = styled(FG)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .label {
    flex: 0 0 57px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;

    .announcement-tooltip {
      padding: 15px;
      width: 226px;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      text-transform: none;
    }

    img {
      margin-left: 8px;
    }
  }

  .controls {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > * {
      margin-right: 6px;
    }

    .selectDayCalendar {
      .selectDayCalendar__trigger {
        background: #fff;
        border-color: transparent;
        padding: 8px 14px;

        :hover {
          border-color: #5158cf;
        }
      }

      &.open {
        .selectDayCalendar__trigger {
          background: #fff;
          border-color: #5158cf;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  background-color: #f5f5f5;
  padding: 15px 30px;
  width: 100%;
  z-index: 2;

  ${props =>
    props.sent &&
    css`
      padding-top: 18px;
      padding-bottom: 18px;
    `}

  .message__title {
    width: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: none;
    padding: 8px 15px;
    outline: none;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000000;

    ::placeholder {
      color: #b4b4b4;
    }

    :focus {
      border-color: #5c5bbd;
    }
  }

  ${FormGroup} {
    margin-bottom: 0;

    :first-child {
      margin-bottom: 10px;
    }
  }

  .__react_component_tooltip.app-tooltip-white {
    padding: 15px;
    width: 226px;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #000000;
    text-transform: none;
  }

  .custom-date-input {
    background-color: #fff;
    border-color: transparent;
    padding: 8px 15px;
    line-height: 18px;

    span {
      :first-child {
        margin-right: 10px;
      }
    }
  }

  .rdt.new-ui {
    margin-right: 5px;
  }

  .evfSelectBoxContainer {
    .evfSelectBox__control {
      background-color: #fff;
      border-color: transparent;

      :hover,
      &--is-focused {
        border-color: transparent;
      }
    }

    .evfSelectBox__menu {
      border: 1px solid #eeeeee;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }

    .evfSelectBox__value-container {
      input {
        width: 1px;
      }

      .evfSelectBox__single-value {
        color: #000;
        font-weight: normal;
      }
    }
  }

  .inAppMessage__schedule__sent {
    font-size: 14px;

    span {
      font-weight: 600;
    }
  }

  &.inAppMessageForm__schedule {
    .disable-editing {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  .card-checkbox {
    color: #6a778a;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    white-space: nowrap;

    &:last-child {
      margin-bottom: unset;
    }
  }

  .checkbox-icon {
    background-size: 10px;
    border-color: #eaeaea;
    border-radius: 2px;
  }
`;

export const selectTypeStyle = {
  control: (base, state) => ({
    ...base,
    width: 136,
    borderRadius: 5,
    boxShadow: 'unset',
    borderColor: state.isFocused ? '#5C5BBD' : '#FFFFFF',
    ':hover': {
      borderColor: state.isDisabled ? '#FFFFFF' : '#5C5BBD',
    },
    cursor: 'pointer',
  }),
  valueContainer: base => ({
    ...base,
    paddingTop: 'unset',
    paddingBottom: 'unset',
  }),
  menu: base => ({
    ...base,
    marginTop: 3,
    width: 257,
    boxShadow: 'none',
    borderColor: '#D4D7D9',
    borderStyle: 'solid',
    borderWidth: 1,
  }),
  menuList: base => ({
    ...base,
    borderRadius: 5,
    maxHeight: 222,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: '#FFFFFF',
    color: state.isSelected ? '#5158CF' : '#000000',
    ':hover': {
      backgroundColor: '#F6F5FF',
      color: '#5158CF',
      fontWeight: 600,
    },
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    fontFamily: 'Open Sans',
    fontWeight: state.isSelected ? 600 : 400,
    fontSize: 13,
    cursor: 'pointer',
  }),
  singleValue: base => ({
    ...base,
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    cursor: 'pointer',
  }),
};

export const selectDelayLaterStyles = {
  control: (base, state) => ({
    ...base,
    width: 132,
    borderRadius: 5,
    boxShadow: 'unset',
    borderColor: state.isFocused ? '#5C5BBD' : '#FFFFFF',
    ':hover': {
      borderColor: state.isDisabled ? '#FFFFFF' : '#5C5BBD',
    },
    cursor: 'pointer',
  }),
  valueContainer: base => ({
    ...base,
    paddingTop: 'unset',
    paddingBottom: 'unset',
  }),
  menu: base => ({
    ...base,
    marginTop: 4,
    width: 132,
    boxShadow: 'none',
    borderColor: '#D4D7D9',
    borderStyle: 'solid',
    borderWidth: 1,
  }),
  menuList: base => ({
    ...base,
    borderRadius: 5,
    maxHeight: 222,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 4,
    marginRight: 4,
    '::-webkit-scrollbar-thumb': {
      background: '#D4D7D9 !important',
    },
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: '#FFFFFF',
    color: state.isSelected ? '#5158CF' : '#000000',
    ':hover': {
      backgroundColor: '#F6F5FF',
      color: '#5158CF',
      fontWeight: 600,
    },
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    fontFamily: 'Open Sans',
    fontWeight: state.isSelected ? 600 : 400,
    fontSize: 13,
    cursor: 'pointer',
  }),
  singleValue: base => ({
    ...base,
    color: '#000000',
    fontFamily: 'Open Sans',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    cursor: 'pointer',
  }),
};
