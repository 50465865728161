import styled, { css, keyframes } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const progress = keyframes`
  0%{
    width: 0%;
  }
  25%{
      width: 25%;
  }
  50%{
      width: 50%;
  }
  75%{
      width: 75%;
  }
  100%{
      width: 100%;
  }
`;

export const MediaItem = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  position: relative;

  img,
  video {
    &.media {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .remove-icon {
    position: absolute;
    right: -7px;
    top: -7px;
    cursor: pointer;
    width: 14px;
    height: 14px;
    display: none;
  }

  :last-child {
    margin-right: 0;
  }

  :hover {
    .remove-icon {
      display: block;
    }
  }
`;

export const MediaContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
`;

export const Input = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  max-height: 90px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none !important;
  word-break: break-word;
  cursor: text;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }

  :empty::before {
    content: ${props => `'${props.placeholder}'`};
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    opacity: 0.2;
  }

  ${props =>
    props.disabled &&
    css`
      display: none;
      :empty::before {
        content: '';
      }
    `}
`;

export const SubmitButton = styled.div`
  width: 27px;
  height: 27px;
  background: url(${CDN_URL}/images/send_message.svg) no-repeat center center;
  background-size: auto;
  cursor: pointer;
  border: none;

  &.disabled {
    cursor: not-allowed;
  }

  #chat-submit-button-tooltip {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    padding: 12px 15px 10px;
    width: 208px;
  }
`;

export const FileInputContainer = styled.div`
  position: relative;
  height: 26px;
  margin-right: 10px;

  :not(.disabled):hover {
    svg {
      rect {
        fill: #e6e6ff;
      }
      path {
        fill: #7470ef;
      }
    }
  }

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 1;
    text-indent: -9999px;
    cursor: pointer;
  }

  &.disabled {
    input {
      cursor: not-allowed;
    }
  }
`;

export const VoiceIconContainer = styled.div`
  position: relative;
  height: 26px;
  margin-right: 10px;
`;

export const InputContainer = styled.div`
  margin: 0 10px;
  flex: 1 1;
`;

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  #chat-input-has-text-tooltip,
  #chat-voice-has-text-tooltip {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    padding: 12px 15px 10px;
    width: 208px;
  }

  svg {
    cursor: pointer;

    &.icon:not(.disabled):hover {
      rect {
        fill: #e6e6ff;
      }
      path {
        fill: #7470ef;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
`;

export const AudioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const AudioContainer = styled.div`
  width: 245px;
  height: 26px;
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px 0 4px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background: ${props => (props.offline ? '#a5a4ac' : '#5158cf')};

  ::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    top: 0;
    transition: width 1s linear;
  }

  ${props => {
    if (props.progress && props.playing) {
      return css`
        background: #ceccfe;

        ::after {
          width: 100%;
          animation: ${progress} ${props.duration}s normal linear;
          animation-play-state: running;
          animation-fill-mode: forwards;
          background: #5158cf;
        }
      `;
    }

    if (props.progress && props.pause) {
      return css`
        background: ${props.progress > 100 ? (props.offline ? '#a5a4ac' : '#5158cf') : '#ceccfe'};

        ::after {
          width: 100%;
          animation: ${progress} ${props.duration}s normal linear;
          animation-play-state: paused;
          animation-fill-mode: forwards;
          background: #5158cf;
        }
      `;
    }

    if (props.recording) {
      return css`
        padding: 0 3px 0 2px;
        background: #a5a4ac;

        ::after {
          width: ${props.progress}%;
          background: ${props.offline ? '#777c8f' : '#5158cf'};
        }
      `;
    }
  }}
`;

export const AudioControl = styled.div`
  background-color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  ${props =>
    props.stop &&
    css`
      width: 22px;
      height: 22px;
      left: 2px;
    `}

  ${props =>
    props.preventStop &&
    css`
      cursor: not-allowed;
    `}

  svg.play {
    path {
      fill: #5158cf;
    }
  }

  svg.pause {
    path {
      fill: #7077f2;
    }
  }
`;

export const AudioDuration = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  color: #202353;
  user-select: none;
  z-index: 1;
  background: #fff;
  border-radius: 90px;
  width: 41px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 12px 15px;
  border-top: 1px solid #e8e8e8;
  position: relative;
`;

export const VoiceMessageText = styled.p`
  position: absolute;
  top: -37px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 100%;
  color: #8c8c8c;
  background: #fff;
  width: 99%;
  height: 36px;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 32px;
`;

export const GiphyTriggerContainer = styled.div`
  margin-left: 10px;
`;

export const ReplyContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  height: 56px;
  background: #f5f5f6;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const ReplyBy = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    flex-shrink: 0;
  }
`;

export const ReplyMessageText = styled.p`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  padding-right: 75px;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  box-orient: vertical;
  overflow: hidden;
  margin: 0;
  white-space: break-spaces;

  ${props =>
    props.isBlur &&
    css`
      opacity: 0.5;
    `}

  ${props =>
    props.isBold &&
    css`
      font-weight: 600;
    `}
`;

export const CloseReply = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .close-icon {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: #000;

    svg path {
      stroke: #fff;
    }
  }
`;

export const FloatingButton = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  bottom: 62px;
  background: #fff;
  box-shadow: 0px 8px 24px rgba(158, 158, 158, 0.16);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;

  ${props =>
    props.isShowNote &&
    css`
      bottom: 98px;
    `}

  ${props =>
    props.hasReply &&
    css`
      bottom: 147px;
    `}


    ${props =>
    props.isAttachedMedia &&
    css`
      bottom: 151px;
    `}

  ${props =>
    props.isAttachedMedia &&
    props.hasReply &&
    css`
      bottom: 223px;
    `}

    ${props =>
    props.isShowNote &&
    props.hasReply &&
    css`
      bottom: 172px;
    `}
`;
