import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import size from 'lodash/size';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import some from 'lodash/some';
import { Loader } from 'semantic-ui-react';

import OwnerFilter from './components/OwnerFilter';
import ClientFilter from './components/ClientFilter';
import GroupFilter from './components/GroupFilter';
import PaymentFilter from './components/PaymentFilter';
import ForumFilter from './components/ForumFilter';
import ItemNotification from './components/ItemNotification';
import WorkoutCompletionButton from 'shared/WorkoutCompletionButton';

import { ReactComponent as ResetGrayIcon } from 'assets/icons/reset_gray.svg';
import { ReactComponent as ResetPurpleIcon } from 'assets/icons/reset_purple.svg';
import { ReactComponent as FilterGrayIcon } from 'assets/icons/filter_gray.svg';
import { ReactComponent as FilterPurpleIcon } from 'assets/icons/filter_purple.svg';
import { ReactComponent as EmptyNotificationIcon } from 'assets/icons/empty_notification_dashboard.svg';
// import { ReactComponent as ArrowCircleUpIcon } from 'assets/icons/arrow_circle_up.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_upward.svg';

import { isSameCurrentDate, isOwnerWorkspace } from 'utils/commonFunction';
import {
  PAYMENT_FILTER_OPTIONS_DEFAULT,
  PAYMENT_FILTER_OPTIONS_OWNER_WORKSPACE,
  CATEGORIES_FILTER_OPTIONS,
} from './constants';
import CoachFilter from './components/CoachFilter';
import SystemFilter from './components/SystemFilter';

import * as S from './style';

const DashboardNotifications = props => {
  const {
    user,
    userId,
    groups,
    forums,
    readNotifications,
    summaries,
    getNotificationSummaries,
    getNotificationWithFilter,
    dashboard,
    timezone,
    openItemNotification,
    markAsReadItemNotification,
    markAsUnreadItemNotification,
    toggleModal,
    loadingGroups,
    loadingForums,
    readCategoryNotifications,
    isHabitPermission = false,
  } = props;
  const { page, total, loading } = dashboard;
  const lastScrollTopRef = useRef(0);
  const arrowScrollTopRef = useRef();
  const mainPanelContentRef = useRef();
  const contentScrollRef = useRef();
  const forceLoadMoreRef = useRef(false);
  const spaceContainerRef = useRef();
  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      const height = get(entries, '[0].contentRect.height', 0);
      if (spaceContainerRef.current && spaceContainerRef.current.style) {
        const currentStyle = spaceContainerRef.current.style.display;
        if (height > 60 && isEmpty(currentStyle)) {
          spaceContainerRef.current.style.display = 'none';
        } else if (height <= 60 && !isEmpty(currentStyle)) {
          spaceContainerRef.current.style.display = null;
        }
      }
    }),
  );

  const [actionPopupId, setActionPopupId] = useState();
  const [selectedOption, setSelectedOption] = useState(CATEGORIES_FILTER_OPTIONS[0].key);
  const [filterOwner, setFilterOwner] = useState(null);
  const [filterGroup, setFilterGroup] = useState([]);
  const [filterClient, setFilterClient] = useState(null);
  const [filterForum, setFilterForum] = useState(null);
  const paymentFilterOptions = useMemo(
    () => (user && isOwnerWorkspace(user) ? PAYMENT_FILTER_OPTIONS_OWNER_WORKSPACE : PAYMENT_FILTER_OPTIONS_DEFAULT),
    [user],
  );
  const [selectedPayment, setSelectedPayment] = useState(paymentFilterOptions);
  const [filterCoach, setFilterCoach] = useState(true);
  const [filterSystem, setFilterSystem] = useState(true);
  const [onlyUnread, setOnlyUnread] = useState(false);

  const list = useMemo(() => {
    if (!onlyUnread) return get(props, 'dashboard.list', []);
    return filter(get(props, 'dashboard.list', []), ['checked', false]);
  }, [props, onlyUnread]);
  const listToday = useMemo(() => filter(list, it => isSameCurrentDate(get(it, 'createdAt', ''), timezone)), [list]);
  const listOlder = useMemo(() => filter(list, it => !isSameCurrentDate(get(it, 'createdAt', ''), timezone)), [list]);
  const isToday = useMemo(() => !isEmpty(listToday), [listToday]);
  const isOlder = useMemo(() => !isEmpty(listOlder), [listOlder]);
  const toggleOnlyUnread = useCallback(() => setOnlyUnread(prevState => !prevState), []);
  const isEmptyNotification = useMemo(() => isEmpty(list), [list]);
  const hasUnread = useMemo(() => (get(summaries, selectedOption, 0) > 0 ? true : some(list, ['checked', false])), [
    summaries,
    selectedOption,
    list,
  ]);
  const isEnd = useMemo(() => !isEmpty(list) && size(list) === total, [total, list]);
  const orderNameGroup = useMemo(() => orderBy(groups, [item => item.name.toLowerCase()], ['asc']), [groups]);
  const orderNameForum = useMemo(() => orderBy(forums, [item => item.name.toLowerCase()], ['asc']), [forums]);
  const orderTitle = useMemo(() => (isToday ? 'TODAY' : !isOlder ? 'TODAY' : 'OLDER'), [isToday, isOlder]);
  const isSelectedFilter = useMemo(() => {
    const isDefaultPaymentState = size(selectedPayment) === size(paymentFilterOptions);
    return (
      !isEmpty(filterGroup) ||
      filterOwner !== null ||
      !!filterClient ||
      !!filterForum ||
      !isDefaultPaymentState ||
      !filterCoach ||
      !filterSystem
    );
  }, [
    filterOwner,
    filterGroup,
    filterClient,
    filterForum,
    selectedPayment,
    userId,
    filterCoach,
    filterSystem,
    paymentFilterOptions,
  ]);
  const isFirstPage = useMemo(() => page === 1, [page]);
  const emptyMessage = useMemo(() => (onlyUnread ? `No unread notifications. You’re all set!` : 'No notifications.'), [
    onlyUnread,
  ]);

  useEffect(() => {
    getNotificationSummaries();
  }, [selectedOption]);

  useEffect(() => {
    getNotificationWithFilter({
      page: 1,
      filters: {
        clientId: get(filterClient, '_id'),
        ownerShip: filterOwner,
        groupIds: map(filterGroup, '_id'),
        forumId: filterForum,
        paymentState: map(selectedPayment, 'value'),
        category: selectedOption,
        showUnread: onlyUnread,
        includeCoaches: filterCoach,
        includeInternal: filterSystem,
      },
    });
    lastScrollTopRef.current = 0;
  }, [
    filterClient,
    filterOwner,
    filterGroup,
    filterForum,
    selectedPayment,
    selectedOption,
    onlyUnread,
    filterCoach,
    filterSystem,
  ]);

  useEffect(() => {
    if (
      !forceLoadMoreRef.current &&
      isFirstPage &&
      !isEnd &&
      !isEmptyNotification &&
      mainPanelContentRef.current &&
      contentScrollRef.current
    ) {
      const mainRect = mainPanelContentRef.current.getBoundingClientRect();
      const contentRect = contentScrollRef.current.getBoundingClientRect();
      if (mainRect.height > contentRect.height) {
        getNotificationWithFilter({ page: page + 1 });
        forceLoadMoreRef.current = true;
      }
    }
  }, [
    forceLoadMoreRef.current,
    isFirstPage,
    isEnd,
    isEmptyNotification,
    mainPanelContentRef.current,
    contentScrollRef.current,
  ]);

  const handleResetFilter = event => {
    event && event.preventDefault();
    if (filterClient !== null) setFilterClient(null);
    if (filterOwner !== null) setFilterOwner(null);
    if (!isEmpty(filterGroup)) setFilterGroup([]);
    if (filterForum !== null && selectedOption === 'forum') setFilterForum(null);
    if (selectedOption === 'payment') setSelectedPayment(paymentFilterOptions);
    if (filterCoach !== true) setFilterCoach(true);
    if (filterSystem !== true) setFilterSystem(true);
  };

  const afterSelectedOptionGeneral = useCallback(() => {
    if (!isSelectedFilter) {
      setFilterCoach(false);
      setFilterSystem(false);
    }
  }, [isSelectedFilter]);

  const handleOptionClick = useCallback(
    type => {
      if (selectedOption === 'forum' && type !== 'forum' && isSelectedFilter) {
        setFilterForum(null);
      }
      if (selectedOption === 'payment' && type !== 'payment' && isSelectedFilter) {
        setSelectedPayment(paymentFilterOptions);
      }
      setSelectedOption(type);
    },
    [selectedOption, isSelectedFilter],
  );

  const backToTop = useCallback(() => {
    const contentBody = document.getElementById('notification-content--body');
    contentBody && contentBody.scrollTo({ top: 0 });
  }, []);

  const handleMarkAsRead = useCallback(() => {
    if (selectedOption === 'all') {
      readNotifications();
    } else {
      readCategoryNotifications(selectedOption);
    }
  }, [selectedOption]);

  const markAllAsRead = debounce(handleMarkAsRead, 300);

  const getMoreNotifications = useCallback(() => {
    !loading && getNotificationWithFilter({ page: page + 1 });
  }, [page, loading]);

  const onFilterClient = (client = null) => {
    if (!client) {
      setFilterClient(null);
      return;
    }
    setFilterClient(client);
  };

  const onBodyScrollAndChangeTitle = () => {
    if (!isEmptyNotification && isToday && isOlder) {
      const olderTitle = document.getElementById('older-notification--title');
      const headerFilter = document.getElementById('header-notification--title');
      const orderTitle = document.getElementById('today-notification--title');

      if (olderTitle && headerFilter && orderTitle) {
        const olderRect = olderTitle.getBoundingClientRect();
        const headerFilterRect = headerFilter.getBoundingClientRect();

        if (olderRect.top > window.innerHeight) return;

        if (Math.round(olderRect.bottom) - 2 > headerFilterRect.bottom) {
          if (orderTitle.firstChild.nodeValue.toUpperCase() === 'OLDER') {
            orderTitle.innerHTML = 'TODAY';
          }
        } else {
          if (orderTitle.firstChild.nodeValue.toUpperCase() === 'TODAY') {
            orderTitle.innerHTML = 'OLDER';
          }
        }
      }
    }
  };

  const onBodyScrollAndLoadMore = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (!isEnd && !loading && scrollTop > lastScrollTopRef.current) {
      const bottom = scrollHeight - (scrollTop + clientHeight);

      if (bottom < 150) {
        getMoreNotifications();
      }
    }

    lastScrollTopRef.current = scrollTop;
  };

  const onScrollDebounce = debounce(onBodyScrollAndLoadMore, 300);

  const onBodyScrollAndShowArrowUp = event => {
    const { scrollTop } = event.target;

    if (arrowScrollTopRef.current) {
      if (scrollTop > 324) {
        arrowScrollTopRef.current.classList.add('show');
      } else {
        arrowScrollTopRef.current.classList.remove('show');
      }
    }
  };

  const resizedContainerRef = useCallback(container => {
    if (container !== null) {
      resizeObserver.current.observe(container);
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    }
  }, []);

  const renderList = items =>
    map(items, item => (
      <ItemNotification
        item={item}
        checked={item.checked}
        key={`notification-${get(item, '_id')}`}
        timezone={timezone}
        openItemNotification={openItemNotification}
        markAsReadItemNotification={markAsReadItemNotification}
        markAsUnreadItemNotification={markAsUnreadItemNotification}
        actionPopupId={actionPopupId}
        setActionPopupId={setActionPopupId}
        isHabitPermission={isHabitPermission}
      />
    ));

  return (
    <S.Wrapper>
      <S.LeftPanel>
        {CATEGORIES_FILTER_OPTIONS.map(option => {
          const { value, key } = option;
          const isSelected = key === selectedOption;
          return (
            <S.FilterOptions checked={isSelected} key={key} onClick={() => handleOptionClick(key)}>
              <S.FilterType checked={isSelected}>{value}</S.FilterType>
              <S.FilterTotal checked={isSelected}>{get(summaries, key, 0)}</S.FilterTotal>
            </S.FilterOptions>
          );
        })}
      </S.LeftPanel>
      <S.MainPanel>
        <S.MainPanelFilter ref={resizedContainerRef}>
          <S.FilterIconWrapper>{isSelectedFilter ? <FilterPurpleIcon /> : <FilterGrayIcon />}</S.FilterIconWrapper>
          <OwnerFilter
            filterOwner={filterOwner}
            setFilterOwner={setFilterOwner}
            user={user}
            afterSelect={afterSelectedOptionGeneral}
          />
          <GroupFilter
            filterGroup={filterGroup}
            setFilterGroup={setFilterGroup}
            groups={orderNameGroup}
            loading={loadingGroups}
            disable={!isEmpty(filterClient)}
            afterSelect={afterSelectedOptionGeneral}
          />
          <ClientFilter
            filterClient={filterClient}
            onFilterClient={onFilterClient}
            user={user}
            disable={!isEmpty(filterGroup)}
            afterSelect={afterSelectedOptionGeneral}
          />
          {selectedOption === 'forum' && (
            <ForumFilter
              filterForum={filterForum}
              setFilterForum={setFilterForum}
              forums={orderNameForum}
              loading={loadingForums}
            />
          )}
          {selectedOption === 'payment' && (
            <PaymentFilter
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              options={paymentFilterOptions}
            />
          )}
          <S.Divider />
          <CoachFilter filterCoach={filterCoach} setFilterCoach={setFilterCoach} />
          <S.Divider />
          <SystemFilter filterSystem={filterSystem} setFilterSystem={setFilterSystem} />
          {isSelectedFilter && (
            <S.ButtonReset onClick={handleResetFilter}>
              <ResetGrayIcon className="icon" />
              <ResetPurpleIcon className="icon hover" />
              <span>Reset</span>
            </S.ButtonReset>
          )}
          <S.DividerSpace ref={spaceContainerRef} />
          {selectedOption === 'training' && (
            <S.WorkoutCompletionButton>
              <WorkoutCompletionButton toggleModal={toggleModal} isDashboardNotification={true} />
            </S.WorkoutCompletionButton>
          )}
          <S.HeaderToggleWrapper>
            <S.ToggleLabel>Only show unread</S.ToggleLabel>
            <S.ToggleButton width={36} height={20} checked={onlyUnread} onChange={toggleOnlyUnread} />
          </S.HeaderToggleWrapper>
        </S.MainPanelFilter>
        {!isEmptyNotification && (
          <S.MainPanelHeader id="header-notification--title" center>
            <S.MainPanelTitle id="today-notification--title">{orderTitle}</S.MainPanelTitle>
            {hasUnread && <S.MarkAsRead onClick={markAllAsRead}>Mark all as read</S.MarkAsRead>}
          </S.MainPanelHeader>
        )}
        <S.MainPanelContent
          id="notification-content--body"
          onScroll={event => {
            event.persist();
            onScrollDebounce.call(null, { ...event });
            onBodyScrollAndChangeTitle();
            onBodyScrollAndShowArrowUp(event);
          }}
          ref={mainPanelContentRef}
        >
          <S.ContentScrollWrapper limitWidth={!isEmptyNotification} center ref={contentScrollRef}>
            {isEmptyNotification && !loading && (
              <S.EmptyWrapper>
                <EmptyNotificationIcon />
                <S.EmptyText>{emptyMessage}</S.EmptyText>
              </S.EmptyWrapper>
            )}
            {!isEmptyNotification && isToday && renderList(listToday)}
            {!isEmptyNotification && isToday && isOlder && (
              <S.MainPanelHeader noBorder>
                <S.MainPanelTitle id="older-notification--title">OLDER</S.MainPanelTitle>
              </S.MainPanelHeader>
            )}
            {!isEmptyNotification && isOlder && renderList(listOlder)}
            {loading && (
              <S.IndicatorWrapper isEmpty={isEmptyNotification}>
                <Loader active inline="centered" className="custom-indicator" size="medium">
                  <span>Loading...</span>
                </Loader>
              </S.IndicatorWrapper>
            )}
            {/* {!isEmptyNotification && isEnd && (
              <S.BottomWrapper>
                <S.BottomIcon>
                  <ArrowCircleUpIcon />
                </S.BottomIcon>
                <S.BottomText>That’s all of your notifications</S.BottomText>
                <S.GoToTopButton textOnly onClick={backToTop}>
                  Back to top
                </S.GoToTopButton>
              </S.BottomWrapper>
            )} */}
          </S.ContentScrollWrapper>
        </S.MainPanelContent>
      </S.MainPanel>
      <S.ScrollTopButton ref={arrowScrollTopRef} onClick={backToTop}>
        <ArrowUpIcon />
      </S.ScrollTopButton>
    </S.Wrapper>
  );
};

export default DashboardNotifications;
