import styled, { css } from 'styled-components';
import { Dropdown as DropdownElement } from 'semantic-ui-react';
import Button from 'shared/FormControl/Button';
import { DEFAULT_FORUM_BANNER } from 'components/CommunityForum/constants';

export const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0px 1px 2px 0px #2a2a3426;
  top: 0;
  z-index: 99;
  position: absolute;
  width: 100%;
  transition: opacity 0.1s ease;
  ${props => (props.isSticky ? 'opacity: 1' : 'opacity: 0')}
`;

export const ForumHeader = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 970px;
  margin: 0 auto;
  z-index: 999;
`;

export const InviteButton = styled(Button)`
  padding: 7px 30px;
  margin-right: 5px !important;
`;

export const Container = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  color: #202353;

  .forum-name {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    margin: 0 12px;
    word-break: break-all;
  }
`;

export const Dropdown = styled(DropdownElement)`
  position: relative;
  width: 59px;
  height: 34px;
  background: #e9ecf2;
  padding: 5px, 20px, 5px, 20px;
  align-self: center;
  border-radius: 5px !important;
  .icon {
    position: absolute;
    top: 10px;
    right: 15px !important;
  }
  .sticky-header-options-tooltip {
    position: absolute;
    top: 0;
    right: 0 !important;
    background-color: transparent;
    width: 59px;
    height: 34px;
    z-index: 2;
  }
  .dropdown-menu.more-actions {
    transform: translate(-9px, 10px);
  }
`;

export const DropItem = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202353;
  padding: 17px 20.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: auto;

  ${props =>
    props.leaveGroup &&
    css`
      color: #ea314a !important;
      svg > path {
        stroke: #ea314a !important;
      }
    `}

  & > span {
    margin-left: 10px;
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
    svg > path {
      stroke: #5158cf;
    }
  }
`;

export const ForumAvatar = styled.button`
  height: 44px;
  width: 44px;
  aspect-ratio: 1;
  border-radius: 7px;
  margin: 10px auto !important;
  position: relative;
  border: none;
  flex-shrink: 0;

  ${props =>
    css`
      background-image: url(${props.src ? props.src : DEFAULT_FORUM_BANNER});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      cursor: pointer;
    `}
`;
