import styled from 'styled-components';

export const PurchaseDropdownWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 6px;
  .purchase-option-wrapper {
    position: relative;
    .purchase-option-button {
      user-select: none;
      height: 44px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      cursor: pointer;
      border: 1px solid #e0e4e5;
      width: 260px;
      justify-content: space-between;

      svg {
        transition: all 0.3s;
        path {
          stroke: #202353;
        }
      }

      &:hover {
        border: 1px solid #5158cf;
        transition: all 0.3s;
      }
      &.purchase-option-button-active {
        border: 1px solid #5158cf;
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .purchase-option-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 8px;
      border: 1px solid #d9d9d9;
      background-color: #ffffff;
      border-radius: 4px;
      z-index: 10;
      box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
      width: 260px;
      .purchase-option-item {
        user-select: none !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        padding: 0 14px;
        cursor: pointer;

        span {
          font-size: 13px;
          line-height: 18px;
          color: #262626 !important;
        }

        svg.info-icon {
          path {
            fill: #c1c4cc;
          }

          &:hover {
            path {
              fill: #5158cf;
            }
          }
        }

        .purchase-option-tooltip {
          height: 241px;
          padding: 8px 8px 0px;
          border-radius: 8px;
          background: #141414;
          margin-top: -5px;
          margin-left: -112px;

          &::after {
            display: none;
          }
        }

        &:hover {
          background-color: #f4f4fc;
          span {
            color: #5158cf !important;
          }
        }

        &.purchase-option-item-active {
          background-color: #f4f4fc;
          span {
            font-weight: 600 !important;
            color: #5158cf !important;
          }
        }
      }
    }
  }
`;
