import React, { useEffect, useRef, useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';

import { ReactComponent as SearchIcon } from 'assets/icons/giphy_search_icon.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/giphy_close_circle_grey.svg';
import * as S from 'shared/Giphy/styles';

function SearchBar(props) {
  const { searchKey, setSearch } = props;
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef('');

  useEffect(() => {
    onFocus();
  }, []);

  function onChange(e) {
    e.preventDefault();
    setSearch(e.target.value);
  }

  function onClear() {
    setSearch('');
    if (inputRef.current) inputRef.current.value = '';
  }

  function onFocus() {
    setIsFocus(true);
    if (inputRef.current) inputRef.current.focus();
  }

  function onBlur() {
    setIsFocus(false);
    if (inputRef.current) inputRef.current.blur();
  }

  return (
    <S.SearchBarContainer>
      <RootCloseWrapper event="click" disabled={!isFocus} onRootClose={onBlur}>
        <S.SearchControl isFocus={isFocus} onClick={onFocus}>
          <SearchIcon />
          <input
            ref={inputRef}
            type="text"
            placeholder="Search GIFs"
            defaultValue={searchKey}
            onChange={onChange}
            autoFocus
          />
          {searchKey && <ClearIcon onClick={onClear} className="giphy-clear-icon" />}
        </S.SearchControl>
      </RootCloseWrapper>
    </S.SearchBarContainer>
  );
}

export default SearchBar;
