import { Types } from './actions';

const INITIAL_STATE = {
  historyListData: [],
  paymentInfo: {},
  pricingPlan: {},
  upcommingInvoice: {},
  loading: true,
  isInited: false,
  scheduledSubscription: null,
  clientSecret: null,
  showPaymentForm: false,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case Types.BILLING_SUCCESS_GET_OVERVIEW_DATA:
      return Object.assign({}, state, { ...payload.data, loading: false, isInited: true });

    case Types.BILLING_SUCCESS_UPDATE_PAYMENT_INFO:
      return Object.assign({}, state, { paymentInfo: payload.data });

    case Types.BILLING_SUCCESS_UPDATE_BILLING_CONTACT:
      return Object.assign({}, state, {
        paymentInfo: { ...state.paymentInfo, billing_contact: payload.data },
      });

    case Types.BILLING_TOGGLE_SHOW_PAYMENT_INFORMATION_FORM:
      return Object.assign({}, state, { showPaymentForm: payload.data, billPaying: payload.billingData });

    default:
      return state;
  }
};
