import React, { Component } from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue, EditableInput } from 'react-color/lib/components/common';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';
import './style.scss';

class ColorPicker extends Component {
  handleChange = color => {
    this.props.onChange({ hex: color });
  };
  render() {
    return (
      <S.ColorPickerContainer onMouseEnter={this.props.mouseEnter} onMouseLeave={this.props.mouseLeave}>
        <S.ColorPickerHeader>
          <S.Header>Choose Color</S.Header>
          <S.Close src={`${CDN_URL}/images/color-picker-remove.svg`} onClick={this.props.closeFloater} alt="" />
        </S.ColorPickerHeader>
        <S.SaturationContainer>
          <Saturation {...this.props} pointer={S.SaturationPointer} />
        </S.SaturationContainer>
        <S.HueContainer>
          <Hue {...this.props} pointer={S.HuePointer} />
        </S.HueContainer>
        <S.EditableInputContainer>
          <EditableInput value={this.props.hex} style={S.InputStyles} onChange={this.handleChange} />
        </S.EditableInputContainer>
      </S.ColorPickerContainer>
    );
  }
}

export default CustomPicker(ColorPicker);
