import React from 'react';
import _ from 'lodash';
import { Icon, Dropdown, Image } from 'semantic-ui-react';
import { Wrapper, Container } from './style';
import Header from './Header';
import Footer from './Footer';
import Columns from './columns';
import AutoflowForm from './Form';
import ConfirmModal from 'shared/ConfirmModal';
import { SharingStatus } from 'shared/Icons';
import ActionTrigger from 'shared/Dropdown/Trigger';
import {
  isTeamAdmin,
  checkAssetAssignedToProduct,
  checkPermissionAccessAutoflow,
  saveLastAutomationRoute,
} from 'utils/commonFunction';
import OverdueAnnuallyPopup from '../../layouts/home/Home/components/OverdueAnnuallyPopup';
import AutoflowHeading from './AutoflowHeading';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { ASSET_TYPE } from 'components/Product/constants';
import { CDN_URL } from 'constants/commonData';
import { SharedTooltip } from 'shared/SharedTooltip';

const ACTIONS = [
  { key: 'duplicate', label: 'Duplicate', icon: 'clone.svg' },
  { key: 'edit', label: 'Edit', icon: 'edit-white.svg' },
  { key: 'delete', label: 'Delete', icon: 'delete.svg' },
];

class AutoflowList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { toggleSideBar, getAutoflowList, teamData } = this.props;
    const canAccessAutoflow = checkPermissionAccessAutoflow(teamData);
    if (canAccessAutoflow) {
      getAutoflowList && getAutoflowList();
    }
    toggleSideBar && toggleSideBar(true);
    saveLastAutomationRoute && saveLastAutomationRoute('/home/autoflow');
  }

  componentWillUnmount() {
    this.props.resetAutoflowListQueryData();
  }

  renderColumnHeader = column => {
    const { sort, sorter, changeAutoflowQueryParam, getAutoflowList } = this.props;
    let sortIcon = null;

    if (column.sortKey) {
      let sortClass = sorter === column.sortKey ? 'chevron active ' : 'chevron ';

      if (sorter === column.sortKey) {
        if (sorter === 'last_used') {
          sortClass += sort === 1 ? 'up' : 'down';
        } else {
          sortClass += sort === -1 ? 'up' : 'down';
        }
      } else {
        sortClass += 'down';
      }

      sortIcon = <Icon className={sortClass} />;
    }

    return (
      <div className={`table__header__content${column.hideLabel ? ' hidden' : ''}`}>
        <div
          className="header"
          onClick={() => {
            if (column.sortKey) {
              changeAutoflowQueryParam({
                sorter: column.sortKey,
                sort: column.sortKey === sorter ? sort * -1 : column.defaultSort || 1,
              });
              getAutoflowList();
            }
          }}
        >
          {!!column.icon && <Image src={column.icon} className="column-icon" />}
          <span>{column.label}</span>
          {sortIcon}
        </div>
      </div>
    );
  };

  duplicate = item => {
    const copyItem = _.cloneDeep(item);
    const { toggleModal } = this.props;
    toggleModal(true, <AutoflowForm originData={copyItem} action="duplicate" />);
  };

  edit = item => {
    const { toggleModal } = this.props;
    toggleModal(true, <AutoflowForm originData={item} />);
  };

  delete = async item => {
    const { toggleConfirmModal, payment, onboarding_flow } = this.props;
    const isAssignedToProduct = payment ? await checkAssetAssignedToProduct(ASSET_TYPE.AUTOFLOW, item._id) : false;
    if (isAssignedToProduct) {
      return toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          assetType={ASSET_TYPE.AUTOFLOW}
          onConfirm={() => this.handleDiscardChanges(item._id)}
        />,
      );
    }

    const isAssignedToOnboardingFlow = onboarding_flow
      ? await checkAssetAssignedToProduct(ASSET_TYPE.AUTOFLOW, item._id, true)
      : false;
    if (isAssignedToOnboardingFlow) {
      return toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          isOnboardingFlow
          title="Autoflow is assigned to an Onboarding Flow"
          content="This Autoflow is in at least 1 onboarding flow. If the Autoflow is removed, it will be removed from all onboarding flows as well."
          assetType={ASSET_TYPE.AUTOFLOW}
          onConfirm={() => this.handleDiscardChanges(item._id)}
        />,
      );
    }

    this.handleDiscardChanges(item._id);
  };

  handleDiscardChanges = id => {
    const { deleteAutoflow, toggleConfirmModal } = this.props;
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Remove Autoflow"
          content="Are you sure that you want to delete this autoflow?"
          onConfirm={() => deleteAutoflow(id)}
        />,
      );
  };

  renderList = () => {
    const { list, user } = this.props;

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {_.map(Columns, column => (
                <th key={column.key} className={column.key} style={column.headerStyles ? column.headerStyles : null}>
                  {this.renderColumnHeader(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.map(list, (item, index) => (
              <tr key={item._id}>
                {_.map(Columns, column => (
                  <td key={column.key} className={column.key} style={column.bodyStyles ? column.bodyStyles : null}>
                    {column.key === 'action' ? (
                      <div className="icons">
                        <SharingStatus shared={item.share} tooltipId={`autoflow-sharing-status__${item._id}`} />
                        <div data-tip data-for="autoflow-more-options-tooltip" className="more-options-wrapper">
                          <SharedTooltip id="autoflow-more-options-tooltip" />
                          <Dropdown icon={null} direction={'left'} closeOnBlur trigger={<ActionTrigger />}>
                            <Dropdown.Menu>
                              {ACTIONS.map(actionItem => {
                                if (actionItem.key === 'delete' || actionItem.key === 'edit') {
                                  const { author } = item;

                                  if (!author || (!isTeamAdmin(user) && author._id !== user._id)) {
                                    return null;
                                  }
                                }

                                return (
                                  <Dropdown.Item onClick={() => this[`${actionItem.key}`](item)} key={actionItem.key}>
                                    <div className="item__content">
                                      <Image src={`${CDN_URL}/images/${actionItem.icon}`} width={14} />
                                      {actionItem.label}
                                    </div>
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ) : column.mutate ? (
                      column.mutate.call(this, item, index)
                    ) : (
                      _.get(item, column.fieldData, '--')
                    )}
                  </td>
                ))}
              </tr>
            ))}
            {!list.length ? (
              <tr className="no-data">
                <td colSpan={Columns.length}>No results found.</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { list, user } = this.props;
    const canAccessAutoflow = checkPermissionAccessAutoflow(this.props.teamData);
    if (!canAccessAutoflow) {
      return <OverdueAnnuallyPopup />;
    }
    return (
      <Wrapper className="autoflow-list">
        {/* <Header /> */}
        <AutoflowHeading header />
        <Container isSetMinHeight={list.length > 6 && !user.is_hide_banner_autoflow}>
          <AutoflowHeading banner />
          {this.renderList()}
        </Container>
        <Footer />
      </Wrapper>
    );
  }
}

AutoflowList.displayName = 'AutoflowList';

export default AutoflowList;
