import styled from 'styled-components';

export const TableContainer = styled.div`
  background: #ffffff;
  border: 1px solid #f4f5f9;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  text-align: left;

  .time {
    width: 65%;
    padding: 15px 23px;
    display: table-cell;
  }

  .value {
    width: 35%;
    padding: 15px 23px;
    display: table-cell;
  }

  .table-header {
    padding: 10px 15px 0;

    .time,
    .value {
      padding: 13px 23px 14px;
    }

    &__content {
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #202353;
      background: #fafbfc;
      border-radius: 5px;
      display: table;
      width: 100%;
    }
  }

  .table-content {
    min-height: 250px;
    overflow: auto;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    width: 100%;

    .list {
      margin-left: 15px;
      width: calc(100% - 30px);

      .item {
        display: table;
        width: 100%;
        box-shadow: inset 0px -1px 0px #ebedf4;
      }
    }

    .time,
    .value {
      padding: 15px 23px;
    }

    .no-data {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #000000;
      opacity: 0.3;
      text-align: center;
    }

    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loading-container {
      margin-top: 5px;
    }
  }
`;
