import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
  gap: 20px;
  padding: 0 30px;
  background-color: #fff;

  ${({ isPermissionAutoflow }) =>
    isPermissionAutoflow &&
    css`
      flex: 1;
      padding: 0 30px 30px;
      height: unset;
      flex-shrink: unset;
      gap: unset;
    `}
`;

export const Title = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-left: 9px;
`;

export const Content = styled.div`
  display: flex;
  gap: 20px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #dadfea;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(146, 146, 210, 0.15);
  width: 100%;
  .acs__control {
    width: 294px;
    &--is-disabled {
      background: #fafbfc;
    }
    .acs__value-container {
      padding-left: 14px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  .select-container {
    width: 338px;
    height: 42px;
    flex: none;
    .off__control {
      background: rgba(250, 251, 252, 1) !important;
      .off__value-container {
        div > div {
          font-weight: 400;
        }
      }
    }
  }
`;

export const SettingValue = styled.p`
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  padding-left: 18px;
`;

export const CardTitleWrapper = styled.div`
  width: 400px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  .assign-client-tooltip {
    margin-top: -1px;
    margin-left: 3px;
    .__react_component_tooltip {
      padding: 15px;
      border-radius: 5px;
      font-weight: 400;
      line-height: 20px;
      margin-top: -13px;
    }
  }
`;

export const CardTitle = styled.p`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
`;

export const Thin = styled.span`
  font-weight: 400;
`;
