import { TextBase } from 'components/MetricGroupLibrary/style';
import { CDN_URL } from 'constants/commonData';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const HeaderWrapper = styled.div`
  margin: 20px 21px 20px 0;
  display: flex;
  justify-content: space-between;

  .metric-group-search {
    width: 266px;

    input {
      background: url(${CDN_URL}/images/search_2.svg) no-repeat;
      padding-left: 40px;
      background-size: auto;
      background-position: 15px center;
      background-color: #f8f8f8;
      :hover {
        border: 1px solid #5158cf;
      }
    }
    input::placeholder {
      font-size: 12px !important;
    }
    svg {
      right: 13px;
      g {
        path {
          fill: #a3a3b5 !important;
        }
      }
    }
  }
`;

export const AddNewButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px !important;
  width: 183px;
  height: 30px !important;

  .plus-icon {
    width: 12px;
    height: 12px;
    fill: #fff;
    margin-right: 10px;
  }

  .label {
    ${TextBase}
    font-size: 12px;
  }

  &:hover {
    background: rgb(81, 88, 207, 0.9) !important;
  }
`;

export const RightActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

export const ManageMetricButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px !important;
  width: 144px;
  height: 30px !important;
  background-color: #fff;
  border: 1px solid #e1e1ea;

  .setting-icon {
    width: 12.97px;
    height: 13.33px;
    margin-right: 7px;
    min-width: 12.97px;
    path {
      stroke: #7b7e91;
    }
  }

  .label {
    ${TextBase}
    font-size: 12px;
    color: #202353;
  }

  &:hover {
    background: #f0f1ff !important;
    border: 1px solid #e1e1ea;
    .label {
      color: #5158cf !important;
    }
    .setting-icon {
      path {
        stroke: #5158cf;
      }
    }
  }
`;
