import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { Tab } from 'semantic-ui-react';
import { find } from 'lodash';

import CustomSplash from './CustomSplash';
import CustomAppIcon from './CustomAppIcon';
import { ReactComponent as Paint } from 'assets/icons/color_setting.svg';
import { ReactComponent as Splash } from 'assets/icons/splash_setting.svg';
import { ReactComponent as AppSetting } from 'assets/icons/app_setting.svg';
import { ReactComponent as Workout } from 'assets/icons/workout_setting.svg';
import { ReactComponent as TodayScreenIcon } from 'assets/icons/today_setting.svg';
import { ReactComponent as WelcomeVideoIcon } from 'assets/icons/welcome_video.svg';
import ColorThemes from './ColorThemes';

import * as S from './style';
import WorkoutBackground from './WorkoutBackground';
import TodayScreen from './TodayScreen';
import WelcomeVideo from './WelcomeVideo';

const urlCustomBranding = [
  '/home/advanced-custom-branding/color-themes',
  '/home/advanced-custom-branding/splash-screen',
  '/home/advanced-custom-branding/workout-background',
  '/home/advanced-custom-branding/today-screen-icon',
  '/home/advanced-custom-branding/app-icon',
  '/home/advanced-custom-branding/welcome-video',
];

const PremiumOptions = props => {
  let [currentIndex, setCurrentIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const {
    theme,
    setScreenActive,
    setSplashLogo,
    setSplashBackground,
    welcomeTextColor,
    setWelcomeTextColor,
    setAppIcon,
    setAppIconReview,
    setAppIconUrl,
    appIconUploading,
    setAppIconUploading,
    splashLogo,
    splashBackground,
    todayScreenLogo,
    setTodayScreenLogo,
    selectedTodayScreenLogo,
    isSubmitLogo,
    onSetIsSubmitLogo,
    originalTodayLogoSize,
    appIcon,
    appIconReview,
    appIconUrl,
    getCustomBrandingData,
    uploadAppIconCustomBranding,
    uploadFilesCutomBranding,
    updateCustomBrandingPremium,
    setWorkoutBackground,
    publishAppIcon,
    publisedAppIcon,
    cancelAppIcon,
    setTheme,
    toggleConfirmModal,
    onCheckSaving,
    saving,
    discard,
    onChangeDiscard,
    user,
    enableTodayScreenLogo,
    permission,
  } = props;

  useEffect(() => {
    const pathname = props.history.location.pathname;
    const indexOf = urlCustomBranding.indexOf(pathname);
    if (indexOf !== -1) {
      setCurrentIndex(indexOf);
      setScreenActive(indexOf);
    }
  }, [props.history.location.pathname]);

  const isWhiteLabelAccount = useMemo(() => {
    return find(user.teams, item => item.team.white_label);
  }, [user]);

  const isOpenWelcomeWhiteLabel = process.env.REACT_APP_ENABLE_ONBOARD_WELCOME_VIDEO_WHITE_LABEL;

  const isEnableTodayScreenLogo = useMemo(() => {
    return enableTodayScreenLogo || process.env.REACT_APP_ENABLE_TODAY_SCREEN_LOGO;
  }, [enableTodayScreenLogo]);

  const panes = [
    {
      menuItem: {
        key: 'colors',
        icon: <Paint />,
        content: 'Color Themes',
        url: urlCustomBranding[0],
      },
      render: () => (
        <S.OptionWrapperContent>
          <ColorThemes
            discard={discard}
            setTheme={setTheme}
            onCheckSaving={onCheckSaving}
            onChangeDiscard={onChangeDiscard}
            getCustomBrandingData={getCustomBrandingData}
            activeIndex={activeIndex}
            setCurrentIndex={setCurrentIndex}
            setScreenActive={setScreenActive}
          />
        </S.OptionWrapperContent>
      ),
    },
    {
      menuItem: {
        key: 'splash',
        icon: <Splash />,
        content: 'Splash Screen',
        url: urlCustomBranding[1],
      },
      render: () => (
        <S.OptionWrapperContent>
          <CustomSplash
            theme={theme}
            discard={discard}
            appIcon={appIcon}
            appIconReview={appIconReview}
            appIconUrl={appIconUrl}
            setSplashLogo={setSplashLogo}
            setSplashBackground={setSplashBackground}
            welcomeTextColor={welcomeTextColor}
            setWelcomeTextColor={setWelcomeTextColor}
            splashLogo={splashLogo}
            splashBackground={splashBackground}
            updateCustomBrandingPremium={updateCustomBrandingPremium}
            onCheckSaving={onCheckSaving}
            onChangeDiscard={onChangeDiscard}
            getCustomBrandingData={getCustomBrandingData}
            activeIndex={activeIndex}
            setCurrentIndex={setCurrentIndex}
            setScreenActive={setScreenActive}
            selectedTodayScreenLogo={selectedTodayScreenLogo}
            saving={saving}
          />
        </S.OptionWrapperContent>
      ),
    },
    {
      menuItem: {
        key: 'workout',
        icon: <Workout />,
        content: 'Workout Background',
        url: urlCustomBranding[2],
      },
      render: () => (
        <S.OptionWrapperContent>
          <WorkoutBackground
            discard={discard}
            setWorkoutBackground={setWorkoutBackground}
            onCheckSaving={onCheckSaving}
            onChangeDiscard={onChangeDiscard}
            activeIndex={activeIndex}
            setCurrentIndex={setCurrentIndex}
            setScreenActive={setScreenActive}
            appIcon={appIcon}
            getCustomBrandingData={getCustomBrandingData}
          />
        </S.OptionWrapperContent>
      ),
    },
    isEnableTodayScreenLogo && {
      menuItem: {
        key: 'today',
        icon: <TodayScreenIcon />,
        content: 'Today Screen Icon',
        url: urlCustomBranding[3],
      },
      render: () => (
        <S.OptionWrapperContent>
          <TodayScreen
            theme={theme}
            appIcon={appIcon}
            splashLogo={splashLogo}
            splashBackground={splashBackground}
            todayScreenLogo={todayScreenLogo}
            selectedTodayScreenLogo={selectedTodayScreenLogo}
            setTodayScreenLogo={setTodayScreenLogo}
            updateCustomBrandingPremium={updateCustomBrandingPremium}
            discard={discard}
            activeIndex={activeIndex}
            onSetCurrentIndex={setCurrentIndex}
            onSetScreenActive={setScreenActive}
            onCheckSaving={onCheckSaving}
            onChangeDiscard={onChangeDiscard}
            isSubmitLogo={isSubmitLogo}
            onSetIsSubmitLogo={onSetIsSubmitLogo}
            originalTodayLogoSize={originalTodayLogoSize}
          />
        </S.OptionWrapperContent>
      ),
    },
    !isWhiteLabelAccount && {
      menuItem: {
        key: 'icon',
        icon: <AppSetting />,
        content: 'App Icon',
        url: urlCustomBranding[4],
      },
      render: () => (
        <S.OptionWrapperContent>
          <CustomAppIcon
            discard={discard}
            appIcon={appIcon}
            appIconReview={appIconReview}
            appIconUrl={appIconUrl}
            setAppIcon={setAppIcon}
            setAppIconReview={setAppIconReview}
            setAppIconUrl={setAppIconUrl}
            appIconUploading={appIconUploading}
            setAppIconUploading={setAppIconUploading}
            publishAppIcon={publishAppIcon}
            publisedAppIcon={publisedAppIcon}
            cancelAppIcon={cancelAppIcon}
            getCustomBrandingData={getCustomBrandingData}
            uploadFilesCutomBranding={uploadFilesCutomBranding}
            updateCustomBrandingPremium={updateCustomBrandingPremium}
            uploadAppIconCustomBranding={uploadAppIconCustomBranding}
            toggleConfirmModal={toggleConfirmModal}
            onCheckSaving={onCheckSaving}
            onChangeDiscard={onChangeDiscard}
            activeIndex={activeIndex}
            setCurrentIndex={setCurrentIndex}
            setScreenActive={setScreenActive}
            theme={theme}
            splashLogo={splashLogo}
            splashBackground={splashBackground}
            selectedTodayScreenLogo={selectedTodayScreenLogo}
          />
        </S.OptionWrapperContent>
      ),
    },
    !!permission.video_welcome_custom_brand &&
      (!isWhiteLabelAccount || isOpenWelcomeWhiteLabel) && {
        menuItem: {
          key: 'welcome-video',
          icon: <WelcomeVideoIcon />,
          content: 'Welcome Video',
          url: urlCustomBranding[5],
        },
        render: () => (
          <S.OptionWrapperContent className="onboard-welcome">
            <WelcomeVideo
              discard={discard}
              onChangeDiscard={onChangeDiscard}
              saving={saving}
              onCheckSaving={onCheckSaving}
              activeIndex={activeIndex}
              setCurrentIndex={setCurrentIndex}
              setScreenActive={setScreenActive}
            />
          </S.OptionWrapperContent>
        ),
      },
  ];

  function handleChange(event, data) {
    props.history.push(data.panes[data.activeIndex].menuItem.url);
    if (saving) {
      currentIndex = currentIndex;
    } else {
      currentIndex = data.activeIndex;
      if (currentIndex === 3) {
        getCustomBrandingData();
      }
    }
    setScreenActive(currentIndex);
    setCurrentIndex(currentIndex);
    setActiveIndex(data.activeIndex);
  }

  return (
    <S.OptionWrapper>
      <Tab activeIndex={currentIndex} panes={panes} className="evf-settings" onTabChange={handleChange} />
    </S.OptionWrapper>
  );
};

export default withRouter(PremiumOptions);
