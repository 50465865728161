import React, { useEffect } from 'react';
import { toggleModal } from 'actions/modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetDataProgressPhoto } from 'redux/progress-photo/actions';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import LeftPanel from './components/LeftPanel';
import MainPanel from './components/MainPanel';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const EditProgressPhoto = ({ toggleModal, resetDataProgressPhoto, handleAddPhoto, onClose }) => {
  useEffect(() => {
    return () => {
      resetDataProgressPhoto && resetDataProgressPhoto();
    };
  }, []);

  useEffect(() => {
    const handlePopstate = () => {
      toggleModal && toggleModal(false);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return (
    <S.EditProgressPhoto
      open={true}
      closeOnDimmerClick={false}
      onClose={() => {
        toggleModal && toggleModal(false);
        onClose && onClose();
      }}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Content>
        <LeftPanel handleAddPhoto={handleAddPhoto} onClose={onClose} />
        <MainPanel handleAddPhoto={handleAddPhoto} onClose={onClose} />
      </Modal.Content>
    </S.EditProgressPhoto>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  resetDataProgressPhoto: bindActionCreators(resetDataProgressPhoto, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProgressPhoto);
