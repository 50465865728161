import React, { Component } from 'react';
import { get, debounce } from 'lodash';
import * as S from './styles.js';
import ColorPicker from './index.js';
import Floater from 'react-floater';

export default class ColorPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleDebounceColorSync = debounce(props.onColorUpdate, 500);
    this.isMouseInside = false;
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.onColorUpdate();
    this.handleFormatInput();
  };

  mouseEnter = () => {
    this.isMouseInside = true;
  };
  mouseLeave = () => {
    this.isMouseInside = false;
  };

  handleChange = color => {
    if (this.isMouseInside) {
      this.props.onChange(get(color, 'hex', '').toUpperCase());
      !this.props.updateOnClose && this.handleDebounceColorSync();
    }
  };

  handleInputChange = e => {
    const color = e.target.value;
    const extractedHex = color.split('#').find(el => el !== ''); //extract the hex code
    if (extractedHex) {
      if (extractedHex.length < 7) {
        // limit 6 characters
        const newValue = `#${extractedHex}`;
        this.props.onChange(newValue, true);
      }
    } else {
      this.props.onChange('#', true);
    }
  };

  handleFormatInput = () => {
    let formatedColorInput = this.props.color;
    // let hexColorRegExp = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i; // valid when string is valid hex color code with 3 or 6 values
    if (this.props.color.includes('#')) {
      if (this.props.color.substring(1).length === 3) {
        formatedColorInput = formatedColorInput
          .substring(1)
          .split('')
          .map(function (hex) {
            return hex + hex;
          })
          .join('');
        formatedColorInput = '#' + formatedColorInput;
      }
    } else {
      // If a three-character hexcolor, make six-character
      if (this.props.color.length === 3) {
        formatedColorInput = formatedColorInput
          .split('')
          .map(function (hex) {
            return hex + hex;
          })
          .join('');
      }
      formatedColorInput = '#' + formatedColorInput;
    }
    // TODO: uncomment this code if need to auto set field to #000000 if invalid
    // const is_valid_color_code = hexColorRegExp.test(formatedColorInput);
    // if (!is_valid_color_code) {
    //   this.props.onChange('#000000', true);
    // } else {
    //   this.props.onChange(formatedColorInput.toUpperCase(), true);
    // }
    this.props.onChange(formatedColorInput.toUpperCase(), true);
  };

  callback = (action, props) => {
    this.setState({
      open: action === 'open',
    });
  };

  handleClick = () => {
    const { open } = this.state;
    if (this.props.disabled) {
      return;
    }

    this.setState({
      open: !open,
    });
    this.props.onColorUpdate();
    this.handleFormatInput();
  };

  render() {
    const { open } = this.state;
    const color = get(this.props, 'color', '');
    return (
      <div>
        <S.swatch>
          <Floater
            offset={13}
            open={open}
            ref={c => (this.tooltip = c)}
            callback={this.callback}
            component={
              <ColorPicker
                onChange={this.handleChange}
                mouseEnter={this.mouseEnter}
                mouseLeave={this.mouseLeave}
                color={color}
                closeFloater={this.handleClose}
              />
            }
            placement="left-start"
            hideArrow
            target={'.color-picker-trigger'}
            styles={this.props.styles ? this.props.styles : {}}
          >
            {this.props.trigger ? (
              <div className="color-picker-trigger" onClick={this.handleClick}>
                {this.props.trigger}
              </div>
            ) : (
              <S.color className="color-picker-trigger" color={color} onClick={this.handleClick} />
            )}
          </Floater>
        </S.swatch>
        {open && <S.Overlay onClick={this.handleClick} />}
      </div>
    );
  }
}
