import React from 'react';
import { TableEmptyWrapper } from './styles';

const TableEmpty = () => {
  return (
    <TableEmptyWrapper>
      <span>No clients found.</span>
    </TableEmptyWrapper>
  );
};

export default TableEmpty;
