import React from 'react';
import * as S from './styles';

const Loading = ({ content = 'Printing...', isOpen = false }) => {
  return (
    <S.Loading open={isOpen}>
      <div class="ui segment">
        <div class="ui active inverted dimmer">
          <div class="ui text loader">{content}</div>
        </div>
      </div>
    </S.Loading>
  );
};

export default Loading;
