import React from 'react';
import { Icon, Popup, Loader, Image } from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import { Checkbox } from 'shared/FormControl';
import ExerciseDetail from 'components/ExerciseDetail';
import SelectProgramModal from 'components/SelectProgramModal/AutoflowAssignProgram';
import { Workout, ExerciseSet } from 'types/model';
import { CDN_URL, SECTION_FORMAT_KEY } from 'constants/commonData';
import SectionOverview from 'components/SectionOverview';
import { maximumRenderedExercises } from 'helpers/workout';
import AssignWorkoutModal from './AssignWorkoutModal';
import { counterBehindExercises, pluralize } from 'utils/commonFunction';
import styles, {
  Content,
  Container,
  Wrapper,
  AssignmentContainer,
  AssignmentHeader,
  AssignmentActionsContainer,
  AssignmentFooter,
  CellHeader,
  SectionContainer,
} from './style';
import { deleteExerciseSet, updateSetNumberInSection, convertFromExerciseSetToSupersetsJson } from 'helpers/workout';
import ConfirmModal from 'shared/ConfirmModal';
import WorkoutDraggable from './WorkoutDraggable';
import { SharedTooltip } from 'shared/SharedTooltip';

const ASSIGNMENT_ACTIONS = [
  { key: 'save', label: 'Save to library', action: 'saveToLibrary' },
  { key: 'duplicate', label: 'Copy', action: 'copyAssignment' },
  { key: 'delete', label: 'Delete', action: 'deleteAssignment' },
];

const DATE_FORMAT = 'MM-DD-YYYY';

const LIMIT_SELECTED_WORKOUTS = 20;

export default class CalendarCell extends React.Component {
  constructor(props) {
    super(props);
    this.workoutCellRef = {};
    this.calendarCell = null;

    this.state = {
      calendarCellHeight: 0,
      isChecked: false,
    };
    this.debouncePasteSingleWorkout = debounce(this.props.pasteWorkout, 100);
    this.debouncePasteMultipleWorkout = debounce(this.props.handlePasteMultipleWorkout, 100);
  }

  componentDidMount() {
    const { toggleSideBar } = this.props;
    toggleSideBar && toggleSideBar(false);
    this.updateCalendarCellHeight();
    window.addEventListener('resize', this.updateCalendarCellHeight);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.calendarCell && this.calendarCell.offsetHeight !== this.state.calendarCellHeight) {
      this.updateCalendarCellHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCalendarCellHeight);
  }

  renderCellContent = () => {
    const { isViewMore } = this.state;
    const { copying, day, currentWorkouts, isCopySelectedWorkout } = this.props;
    const views = [];

    if (currentWorkouts && currentWorkouts.length > 0) {
      let maxRendered = this.getMaximumRenderedWorkouts();
      let isShowFull = maxRendered >= currentWorkouts.length;
      for (let i = 0; i < currentWorkouts.length && (isViewMore || i < maxRendered); i++) {
        views.push(this.renderAssignment(currentWorkouts[i], !isShowFull || i < currentWorkouts.length - 1, i));
      }
    }

    const content = (
      <>
        {views}
        {this.renderFooter()}
      </>
    );

    if (views.length == 0) {
      return (
        <Droppable droppableId={`${day.format('MM-DD-YYYY')}`} type="exercise">
          {(provided, snapshot) => {
            return (
              <Content
                ref={ref => {
                  provided.innerRef(ref);
                  this.calendarCell = ref;
                }}
                className={classNames('calendar-cell__body', {
                  copying: copying,
                  copying_workouts: isCopySelectedWorkout,
                })}
              >
                {content}
                {provided.placeholder}
              </Content>
            );
          }}
        </Droppable>
      );
    } else {
      return (
        <Content
          ref={ref => (this.calendarCell = ref)}
          className={classNames('calendar-cell__body', {
            copying: copying,
            copying_workouts: isCopySelectedWorkout,
          })}
        >
          {content}
        </Content>
      );
    }
  };

  handlePasteWorkout = event => {
    const multiPaste = event.shiftKey;
    const { day } = this.props;
    this.debouncePasteMultipleWorkout({ toDate: day.format('MM-DD-YYYY'), multiPaste });
  };

  renderPasteMultipleWorkout() {
    const { isCopySelectedWorkout, currentWorkouts } = this.props;
    const { isViewMore, calendarCellHeight } = this.state;

    if (isViewMore) {
      return <></>;
    }

    if (isCopySelectedWorkout) {
      const marginTop = !currentWorkouts || currentWorkouts.length === 0 ? calendarCellHeight / 2 - 22 : 0;
      return (
        <div className="paste" style={{ marginTop: marginTop }} onClick={this.handlePasteWorkout}>
          <div>Paste</div>
        </div>
      );
    }
  }

  renderFooter = () => {
    const { currentWorkouts, copying, isLoadingWorkouts } = this.props;
    const { isViewMore, calendarCellHeight } = this.state;

    if (isViewMore) {
      return null;
    }

    let remainItems = null;
    let pasteButton = null;

    if (currentWorkouts) {
      const maxRendered = this.getMaximumRenderedWorkouts();
      let remain = currentWorkouts.length - maxRendered;

      if (remain > 0) {
        const text = `+ ${remain} more workout${remain === 1 ? '' : 's'}`;
        remainItems = (
          <div className="remain-items" onClick={() => this.handleViewMore(true)}>
            {text}
          </div>
        );
      }
    }

    if (copying) {
      const marginTop = !currentWorkouts || currentWorkouts.length === 0 ? calendarCellHeight / 2 - 22 : 0;
      pasteButton = (
        <div className="paste" style={{ marginTop: marginTop }} onClick={this.handlePaste}>
          Paste
        </div>
      );
    }

    return (
      <div className="cell__footer">
        {remainItems}
        {!isLoadingWorkouts && pasteButton}
        {!isLoadingWorkouts && this.renderPasteMultipleWorkout()}
      </div>
    );
  };

  handleOpenWorkoutDetail = (currentWorkout, sectionId) => {
    const { workingAutoflow } = this.props;
    const trigger = sectionId ? { from: 'section', sectionId } : null;
    this.props.viewWorkoutDetail(currentWorkout._id, trigger);
    setTimeout(() => {
      this.props.push(`/home/autoflow/${workingAutoflow._id}/training/calendar/${currentWorkout._id}/detail`);
    }, 200);
  };

  handleViewMore = (isViewMore, id) => {
    this.setState({ isViewMore: isViewMore }, () => {
      if (id) {
        this.workoutCellRef[id].scrollIntoView();
      }
    });
  };

  updateCalendarCellHeight = () => {
    if (this.calendarCell) {
      this.setState({
        calendarCellHeight: this.calendarCell.offsetHeight,
        width: this.calendarCell.offsetWidth,
      });
    }
  };

  onRefInnerDiv = (ref, currentWorkout, provided) => {
    if (provided) {
      provided.innerRef(ref);
    }

    if (currentWorkout) {
      this.workoutCellRef[currentWorkout._id] = ref;
    }
  };

  onSaveExercise = data => {
    const { updateWorkout } = this.props;
    const { exercise, sectionIndex, setIndex, exIndex, assignment } = data;
    let section = assignment.sections[sectionIndex];

    if (!section) {
      return console.error('Cannot find section with index = ' + sectionIndex);
    }

    if (section.exercises[setIndex]) {
      section.exercises[setIndex].supersets[exIndex] = exercise;
    }

    updateSetNumberInSection(assignment.sections[sectionIndex], setIndex, exIndex);
    updateWorkout(assignment, 'EXERCISE_DETAIL');
  };

  handleSelectedWorkout = (currentWorkout, checked) => {
    const {
      handleSelectMultipleWorkout,
      selectedWorkouts,
      selectedWeek,
      handleResetSelectedWeek,
      handleResetSelectedMultipleWorkout,
      copying,
      resetCopyItem,
    } = this.props;

    if (!isEmpty(selectedWeek)) {
      handleResetSelectedWeek();
    }
    if (!isEmpty(copying)) {
      resetCopyItem();
    }

    const lengthWorkout = selectedWorkouts.length;
    const currentWorkoutId = get(currentWorkout, '_id');

    if (checked) {
      if (lengthWorkout < LIMIT_SELECTED_WORKOUTS) {
        handleSelectMultipleWorkout({
          workoutIds: [...selectedWorkouts, currentWorkoutId],
        });
      }
      lengthWorkout === LIMIT_SELECTED_WORKOUTS && this.handlePopupLimitWorkout();
    } else {
      const newSelectedWorkout = filter(selectedWorkouts, id => id !== currentWorkoutId);
      handleSelectMultipleWorkout({
        workoutIds: newSelectedWorkout,
      });
      if (isEmpty(newSelectedWorkout)) {
        handleResetSelectedMultipleWorkout();
      }
    }
  };

  handlePopupLimitWorkout = () => {
    const { selectedWorkouts, toggleConfirmModal } = this.props;

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          headerIcon={`${CDN_URL}/images/warning_purple.svg`}
          title={`Maximum of ${selectedWorkouts.length} workouts selected`}
          content={`You can select up to ${selectedWorkouts.length} workouts at a time. Please complete your action and then select more.`}
          onConfirm={() => {}}
          onDeny={() => {}}
          confirmButtonTitle="Okay"
          hideCancelButton
          noBorder={true}
          hasCloseIcon={true}
          className="calendar--max-select"
        />,
      );
  };

  renderSuperset = (currentWorkout, section, renderable, sectionIndex) => {
    // render exercise outside section : section.type === 'hidden'
    const { updateExerciseLibrary, day, updateWorkout } = this.props;
    const dayFormated = day.format('MM-DD-YYYY');
    const views = [];
    const set = section.exercises ? section.exercises[0] : {};

    forEach(set.supersets, (exSet, idx) => {
      if (idx < renderable) {
        let currentSet = cloneDeep(exSet);
        const pId = `${currentWorkout._id}-${sectionIndex}-${idx}-${dayFormated}`;
        views.push(
          <div key={idx}>
            <ExerciseDetail
              exerciseSet={new ExerciseSet(currentSet)}
              pId={pId}
              day={dayFormated}
              exIndex={idx}
              setIndex={0}
              sectionIndex={sectionIndex}
              mode="popup"
              assignment={new Workout(currentWorkout)}
              onEditExerciseLibrary={(oldExerciseId, newExerciseLibrary) => {
                const data = {
                  day: dayFormated,
                  assignmentId: currentWorkout._id,
                  oldExerciseId,
                  newExerciseLibrary,
                };
                updateExerciseLibrary(data);
              }}
              onDelete={(setIndex, exIndex) => {
                let workout = cloneDeep(currentWorkout);
                deleteExerciseSet(workout, sectionIndex, setIndex, exIndex);
                updateWorkout(workout, 'EXERCISE_DETAIL');
              }}
              onSave={data => this.onSaveExercise({ ...data, sectionIndex, exIndex: idx, setIndex: 0 })}
              onClone={exercise => this.onAddSet({ assignment: currentWorkout, exercise })}
            />
            {idx < renderable - 1 ? <div style={{ backgroundColor: 'gainsboro', height: 1, margin: '0 8px' }} /> : null}
          </div>,
        );
      }
    });

    return views;
  };

  renderSection = (currentWorkout, section, renderable, sectionIndex) => {
    const indexId = `${section._id};${sectionIndex}`;
    const draggableId = `${this.props.day.format('MM-DD-YYYY')};${currentWorkout._id};${section._id}`;
    const sClass = section.type === 'hidden' ? 'workout__section workout__section--hidden' : 'workout__section';

    const content = (
      <React.Fragment>
        {this.props.movingSet ? <Loader /> : null}
        {section.type === 'hidden' ? (
          this.renderSuperset(currentWorkout, section, renderable, sectionIndex)
        ) : renderable ? (
          <SectionOverview
            key={section._id}
            section={section}
            onClick={() => this.handleOpenWorkoutDetail(currentWorkout, section._id)}
          />
        ) : null}
      </React.Fragment>
    );

    return (
      <Draggable key={draggableId} draggableId={draggableId} index={sectionIndex} type="exercise">
        {(provided, snapshot) => (
          <SectionContainer
            className={`${sClass} ${!snapshot.isDragging ? '' : 'drag'}`}
            style={{ ...provided.draggableProps.style }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {content}
          </SectionContainer>
        )}
      </Draggable>
    );
  };

  renderAssignment = (currentWorkout, isShowOverviewOnly, workoutIndex) => {
    if (!currentWorkout) {
      return null;
    }

    const { isViewMore } = this.state;
    const { sections } = currentWorkout;
    const { day, isDragging } = this.props;
    const views = [];
    const maxRendered = isViewMore ? 100 : isShowOverviewOnly ? 0 : this.getMaximumRenderedExercises(currentWorkout);
    let isCompressed = !isShowOverviewOnly || isViewMore;
    let droppableId = `${day.format('MM-DD-YYYY')};${currentWorkout._id}`;

    if (isCompressed && !isDragging) {
      let totalRendered = 0;

      for (let i = 0; i < sections.length; i++) {
        let section = sections[i];

        if (!section) {
          continue;
        }

        let numOfExercises = sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
        if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
          numOfExercises = section.exercise_references.length || 1;
        }

        let rendered = Math.min(numOfExercises, maxRendered - totalRendered);

        if (!rendered) {
          break;
        } else {
          totalRendered += rendered;
          views.push(this.renderSection(currentWorkout, section, rendered, i));
        }
      }
    } else {
      const total = sumBy(sections, section => {
        return sumBy(section.exercises, exercise => {
          return exercise.supersets ? exercise.supersets.length : 0;
        });
      });
      droppableId += `;${total}`;
    }

    return (
      <WorkoutDraggable key={currentWorkout._id} currentWorkout={currentWorkout} day={day} workoutIndex={workoutIndex}>
        <Droppable droppableId={droppableId} type="exercise">
          {(provided, snapshot) => {
            const dragOver = snapshot.isDraggingOver ? { background: '#f3f5f8' } : {};
            return (
              <AssignmentContainer
                ref={ref => this.onRefInnerDiv(ref, currentWorkout, provided)}
                className="workout-cell"
                style={{ ...dragOver }}
              >
                {this.renderAssignmentHeader(currentWorkout, isCompressed)}
                <div>{views}</div>
                {this.renderAssignmentFooter(currentWorkout, maxRendered)}
                {provided.placeholder}
              </AssignmentContainer>
            );
          }}
        </Droppable>
      </WorkoutDraggable>
    );
  };

  renderWorkoutTitle = currentWorkout => {
    const { permission } = this.props;
    let className = 'workout-cell-title';
    let width = this.state.width - 50;
    if (process.env.REACT_APP_COPY_WEEK_ENABLE_V2 && get(permission, 'copy_week')) {
      width -= 14;
    }

    return (
      <div
        // className={className}
        style={{ maxWidth: width, width: 'unset' }}
        data-tip
        data-for={`workout-title-${currentWorkout._id}`}
        className={className}
      >
        {currentWorkout.title ? currentWorkout.title.toUpperCase() : ''}
      </div>
    );
  };

  saveToLibrary = currentWorkout => {
    const { workingAutoflow, saveWorkoutToLibrary } = this.props;

    if (workingAutoflow && currentWorkout) {
      saveWorkoutToLibrary({ autoflow: workingAutoflow._id, id: currentWorkout._id });
    } else {
      console.log('Something wrong. Cannot save assignment to library', currentWorkout);
    }
  };

  copyAssignment = currentWorkout => {
    if (!isEmpty(this.props.selectedWorkouts)) {
      this.props.handleResetSelectedMultipleWorkout();
    }
    if (!isEmpty(this.props.selectedWeek)) {
      this.props.handleResetSelectedWeek();
    }
    if (currentWorkout) {
      this.props.copyWorkout(currentWorkout);
    } else {
      console.log('Something wrong. Cannot copy assignment', currentWorkout);
    }
  };

  deleteAssignment = currentWorkout => {
    const { workingAutoflow } = this.props;

    if (workingAutoflow && currentWorkout) {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Delete workout"
          content={'Are you sure that you want to delete this workout?'}
          onConfirm={() => {
            const { deleteWorkout, selectedWorkouts, handleSelectMultipleWorkout } = this.props;
            const newSelectedWorkouts = filter(selectedWorkouts, id => id !== get(currentWorkout, '_id'));
            handleSelectMultipleWorkout({
              workoutIds: newSelectedWorkouts,
            });
            return deleteWorkout && deleteWorkout(currentWorkout);
          }}
        />,
      );
    }
  };

  toggleActionPopup = id => {
    this.resetActions(() => {
      setTimeout(() => this.setState({ openingPopup: id }), 0);
    });
  };

  resetActions = callback => {
    this.setState({
      openingPopup: '',
    });
    callback && callback();
  };

  renderAssignmentHeader = (currentWorkout, isCompressed) => {
    const { day, selectedWorkouts, permission } = this.props;
    const { openingPopup } = this.state;
    const direction = day.format('ddd') === 'Sun' ? 'bottom right' : 'bottom left';
    const isChecked = selectedWorkouts && selectedWorkouts.includes(currentWorkout._id);

    return (
      <AssignmentHeader className={isCompressed ? '' : 'compressed'}>
        <div className="workout-title-status" onClick={() => this.handleOpenWorkoutDetail(currentWorkout)}>
          {this.renderWorkoutTitle(currentWorkout)}
        </div>
        {process.env.REACT_APP_COPY_WEEK_ENABLE_V2 && get(permission, 'copy_week') && (
          <Checkbox
            checked={isChecked}
            onChange={event => {
              event.stopPropagation();
              this.handleSelectedWorkout(currentWorkout, event.target.checked);
            }}
            className={classNames('calendar-client--checkbox-workout', {
              hidden: isEmpty(selectedWorkouts),
              show: !isEmpty(selectedWorkouts),
            })}
          />
        )}
        <Popup
          trigger={
            <div data-tip data-for={`workout-cell-tooltip-${(currentWorkout || {})._id}`}>
              <Icon color="grey" name="ellipsis horizontal" size="small" className="more-actions-icon" />
              {openingPopup !== (currentWorkout || {})._id && (
                <SharedTooltip id={`workout-cell-tooltip-${(currentWorkout || {})._id}`} />
              )}
            </div>
          }
          on="click"
          style={styles.popup}
          open={openingPopup === currentWorkout._id}
          onOpen={() => this.toggleActionPopup(currentWorkout._id)}
          onClose={() => this.setState({ openingPopup: '' })}
          position={direction}
          basic
          className="action-popup calendar-menu-popup"
        >
          <AssignmentActionsContainer>
            {this.renderMoveUpOption(currentWorkout)}
            {this.renderMoveDownOption(currentWorkout)}
            {map(ASSIGNMENT_ACTIONS, item => (
              <div
                key={item.key}
                className="actions__item"
                onClick={() => {
                  this.setState({ openingPopup: '' });
                  this[item.action](currentWorkout);
                }}
              >
                <Image src={`${CDN_URL}/images/${item.key}.svg`} />
                <div>{item.label}</div>
              </div>
            ))}
          </AssignmentActionsContainer>
        </Popup>
        <ReactTooltip
          className="app-tooltip"
          id={`workout-title-${currentWorkout._id}`}
          effect="solid"
          place={'top'}
          delayShow={1000}
        >
          <span>{currentWorkout.title ? currentWorkout.title : ''}</span>
        </ReactTooltip>
      </AssignmentHeader>
    );
  };

  renderMoveUpOption = currentWorkout => {
    const { currentWorkouts } = this.props;
    const idx = currentWorkouts.findIndex(item => item._id === currentWorkout._id);
    const count = currentWorkouts.length;

    if (count >= 2) {
      if (idx === count - 1 || (count >= 3 && idx > 0 && idx < count - 1)) {
        return (
          <div
            className="actions__item"
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleMoveUp(currentWorkout, idx);
            }}
          >
            <Image src={`${CDN_URL}/images/workout_up.svg`} />
            <div>Move up</div>
          </div>
        );
      }
    }

    return null;
  };

  renderMoveDownOption = currentWorkout => {
    const { currentWorkouts } = this.props;
    const idx = currentWorkouts.findIndex(item => item._id === currentWorkout._id);
    const count = currentWorkouts.length;

    if (count >= 2) {
      if (idx === 0 || (count >= 3 && idx > 0 && idx < count - 1)) {
        return (
          <div
            className="actions__item"
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleMoveDown(currentWorkout, idx);
            }}
          >
            <Image src={`${CDN_URL}/images/workout_down.svg`} />
            <div>Move down</div>
          </div>
        );
      }
    }

    return null;
  };

  handleMoveDown = (currentWorkout, index) => {
    const { day, currentWorkouts, arrangeWorkout, workingAutoflow } = this.props;
    const newIndex = index + 1;

    if (newIndex > currentWorkouts.length - 1) {
      return;
    }

    const params = {
      newIndex,
      id: currentWorkout._id,
      autoflow: workingAutoflow._id,
      day: day.format(DATE_FORMAT),
    };
    arrangeWorkout(params);
  };

  handleMoveUp = (currentWorkout, index) => {
    const { day, arrangeWorkout, workingAutoflow } = this.props;
    const newIndex = index - 1;

    if (newIndex < 0) {
      return;
    }

    const params = {
      newIndex,
      id: currentWorkout._id,
      autoflow: workingAutoflow._id,
      day: day.format(DATE_FORMAT),
    };

    arrangeWorkout(params);
  };

  handlePaste = event => {
    const multiPaste = event.shiftKey;
    const { copying, day, workingAutoflow } = this.props;

    if (copying) {
      const params = {
        id: copying._id,
        autoflow: workingAutoflow._id,
        day: day.format(DATE_FORMAT),
      };

      this.debouncePasteSingleWorkout(params, multiPaste);
    }
  };

  onAddSet = ({ assignment, exercise }) => {
    const { addSetToWorkout, workingAutoflow } = this.props;
    const exerciseJson = convertFromExerciseSetToSupersetsJson(exercise);

    return addSetToWorkout({
      exercise: exerciseJson,
      id: assignment._id,
      autoflow: workingAutoflow._id,
    });
  };

  renderAssignmentFooter = (currentWorkout, maxRendered) => {
    const { day } = this.props;
    const popupId = `${day.format('MM-DD-YYYY')}-${currentWorkout._id}-new-exercise`;
    const footerIcon = (
      <ExerciseDetail
        mode={'new_popup'}
        assignment={currentWorkout}
        pId={popupId}
        onSave={this.onAddSet}
        onEditExerciseLibrary={() => {}}
      />
    );

    const remainTasks = counterBehindExercises(currentWorkout, maxRendered);
    let text = `+ ${remainTasks} more ${pluralize('exercise', remainTasks)}`;

    return (
      <AssignmentFooter>
        <div
          className="view-more-exercises"
          onClick={() => (text ? this.handleViewMore(true, currentWorkout._id) : null)}
        >
          {remainTasks > 0 ? text : ''}
        </div>
        {footerIcon}
      </AssignmentFooter>
    );
  };

  openFindWorkoutModal = () => {
    const { day } = this.props;
    this.props.toggleModal(true, <AssignWorkoutModal date={day.convertToLuxon()} />);
  };

  renderAddDropDown = () => {
    const { toggleModal, day } = this.props;

    return (
      <div className="program-plus-menu">
        <ul className="program-list-dropdown">
          <li className="program-list-dropdown-item" onClick={this.openFindWorkoutModal}>
            <div>Add workout</div>
          </li>
          <li
            className="program-list-dropdown-item"
            onClick={e => {
              e.stopPropagation();
              toggleModal(true, <SelectProgramModal date={day.convertToLuxon()} />);
            }}
          >
            <div>Add program</div>
          </li>
        </ul>
      </div>
    );
  };

  getMaximumRenderedWorkouts = () => {
    const { weekIndex, viewMode, hasSelectedWorkout } = this.props;
    const menuBottomBar = weekIndex + 1 === viewMode && hasSelectedWorkout ? 43 : 0;
    let pasteButtonHeight = 22;
    const containerHeight = this.state.calendarCellHeight - pasteButtonHeight - menuBottomBar;
    const workoutHeight = 51;
    let maxHeigh = parseInt(containerHeight / workoutHeight, 10);
    return maxHeigh;
  };

  getMaximumRenderedExercises = workout => {
    const { weekIndex, viewMode, hasSelectedWorkout } = this.props;
    const menuBottomBar = weekIndex + 1 === viewMode && hasSelectedWorkout ? 43 : 0;
    let factor = 5 + 22; // 6: margin, 24 : paste button's height
    const workoutHeaderHeight = 22;
    const workoutFooterHeight = 22;
    const assignmentContainerHeight = 52;

    factor += workoutHeaderHeight + workoutFooterHeight;
    factor += (this.props.currentWorkouts.length - 1) * assignmentContainerHeight;

    const containerHeight = this.state.calendarCellHeight - factor - menuBottomBar;
    return maximumRenderedExercises(workout, containerHeight, 75);
  };

  renderCellHeader = () => {
    const { day } = this.props;
    const { isViewMore } = this.state;
    const isToday = !isViewMore && day.isSame(moment(), 'day');

    return (
      <CellHeader className="cell__header">
        <div className={isToday ? 'day today' : 'day'}>{day.format(isViewMore ? 'MMM DD' : 'DD')}</div>
        <div className="add-workout-button">
          <div className="plus-icon" onClick={this.openFindWorkoutModal} />
          {this.renderAddDropDown()}
        </div>
      </CellHeader>
    );
  };

  render() {
    const { isViewMore } = this.state;
    const { day } = this.props;

    if (!this.props.workingAutoflow) {
      return null;
    }

    const droppableId = `workout_${day.format('MM-DD-YYYY')}`;

    return (
      <Droppable droppableId={droppableId} type="workout">
        {(provided, snapshot) => (
          <Wrapper ref={provided.innerRef} className={`calendar__cell${isViewMore ? ' view-more' : ''}`}>
            <Container className="calendar-cell__container">
              {this.renderCellHeader()}
              {isViewMore && (
                <Image
                  className="btntask-close-over"
                  src={`${CDN_URL}/images/close_circle.svg`}
                  onClick={() => {
                    this.handleViewMore(false);
                  }}
                  width={16}
                  height={16}
                />
              )}
              {this.renderCellContent()}
            </Container>
            {provided.placeholder}
          </Wrapper>
        )}
      </Droppable>
    );
  }
}
