import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const activeTrigger = css`
  color: #202353;
  background: #f0f0f2;
`;

export const RecipesHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px 24px;
  border-bottom: 1px solid #f0f0f2;
  transition: all 0.5s ease;
`;

export const FilterButton = styled(Button)`
  margin-left: 10px;
  padding: 5px 10px;
  min-width: 81px;
  max-height: 30px;
  border-radius: 5px;
  border: 1px solid #dadbea;
  .filter-label {
    margin-right: 2px;
    color: #202353;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  .filter-icon {
    margin-right: 3px;
  }
  :hover {
    border-radius: 5px;
    background: #f0f1ff;
    .filter-label {
      color: #5158cf;
    }
    .filter-icon {
      path {
        fill: #5158cf;
      }
    }
  }
  ${props =>
    props.active &&
    css`
      background: #f0f1ff;
      .filter-label {
        color: #5158cf;
      }
      .filter-icon {
        path {
          fill: #5158cf;
        }
      }
    `}
`;

export const RecipeFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .search-input-recipes {
    width: 265px;
    height: 30px;
    input {
      background: url(${CDN_URL}/images/search_bold.svg) no-repeat;
      background-size: auto;
      background-position: 15px center;
      background-color: #f8f8f8;
      padding-left: 44px;
      ::placeholder {
        font-size: 13px;
      }
    }
  }
  .clients-filter {
    div:first-child button {
      margin-left: 0;
    }
  }
`;

export const NewRecipeButton = styled(Button)`
  position: relative;
  margin: 0px;
  padding: 0px 24px;
  height: 30px;
  background: #5158cf;
  border: none;
  .plus-icon {
    width: 12px;
    height: 12px;
    fill: #fff;
    margin-right: 10px;
  }
  .label {
    color: #fff;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .features__tooltipUpgradePath.custom-upgrade-path-popup {
    visibility: hidden;
    opacity: 1;
    transition: opacity 0.35s linear;
    right: 0;
    top: calc(100% + 16px);
    z-index: 2;
    left: unset;
    transform: none;
    padding-bottom: 0;
    .tooltip_box {
      width: 259px;
      -webkit-filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      filter: drop-shadow(0px 4px 16px rgba(38, 38, 38, 0.2));
      box-shadow: unset;
      border: unset;
      ::before {
        content: '';
        cursor: default;
        background-color: inherit;
        width: 16px;
        height: 16px;
        border: unset;
        top: -4px;
        bottom: unset;
        left: 78%;
        transform: translateX(-50%) rotate(45deg);
      }
      ::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 16px;
        bottom: 100%;
        left: 0;
        cursor: default;
      }
      .tooltip__heading {
        font-size: 15px;
        font-weight: 600;
        line-height: 23px;
        color: #202353;
      }
      .tooltip__description {
        width: 100%;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #202353;
      }
      .tooltip__upgradePathBtn {
        height: 30px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 5px;
        padding-top: 11px;
        background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
        :hover {
          background: linear-gradient(85.16deg, #fdc830 -18.89%, #f37335 182.76%);
        }
        span {
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
        }
      }
    }
  }
  :hover {
    background-color: rgba(81, 88, 207, 0.9) !important;
    .features__tooltipUpgradePath.custom-upgrade-path-popup {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.75s ease, left 0.25s linear;
    }
  }
`;

export const RecipeCategories = styled.div`
  display: flex;
  height: 72px;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  gap: 75px;
  align-self: stretch;
  border-bottom: 1px solid #f0f0f2;
  @media only screen and (max-width: 1279px) {
    gap: 50px;
  }
  @media only screen and (max-width: 1039px) {
    gap: 20px;
  }
`;

export const RecipeCategory = styled.div`
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  :hover {
    color: #202353;
    border-bottom: 2px solid #5158cf;
    .recipe-category-label {
      color: ${props => (props.active ? '#5158cf' : '#202353')};
    }
  }

  border-bottom: ${props => props.active && '2px solid #5158cf'};
  .category-icon {
    path {
      fill: ${props => props.active && '#5158cf'};
    }
  }

  .recipe-category-label {
    color: ${props => (props.active ? '#5158cf' : '#7B7E91')};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-bottom: 3px;
  margin-top: 3px;
`;

export const FilterWrapper = styled.div`
  margin-top: 23px;
  margin-left: 5px;
  margin-bottom: 27px;
  width: 323px;
`;

export const UpdatedFilterIcon = styled.div`
  position: absolute;
  z-index: 99;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #ffa31a;
  top: 4px;
  right: 3px;
`;

export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 25px;
  width: 100%;
  transition: all 0.5s ease;

  ${({ isShowDraft }) =>
    isShowDraft &&
    css`
      justify-content: flex-end;
    `}
`;

export const RecipesShowByOwner = styled.div`
  display: flex;
  border-radius: 5px;
  border: 1px solid #dadbea;
  height: 30px;
  overflow: hidden;
`;

export const ResultTotal = styled.div`
  color: #202353;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
`;

export const AllRecipes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: #7b7e91;
  padding: 5px 15px;
  white-space: nowrap;
  cursor: pointer;
  ${props =>
    props.active
      ? activeTrigger
      : css`
          :hover {
            color: #5158cf;
            background: #f0f1ff;
          }
        `}
`;

export const YourRecipes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7b7e91;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding-left: 15px;
  border-left: 1px solid #dadbea;
  padding: 5px 15px;
  white-space: nowrap;
  cursor: pointer;
  ${props =>
    props.active
      ? activeTrigger
      : css`
          :hover {
            color: #5158cf;
            background: #f0f1ff;
          }
        `}
`;

export const RecipesHeaderContainer = styled.div`
  width: 100%;

  ${({ isHeaderResize }) =>
    isHeaderResize &&
    css`
      ${RecipesHeader} {
        padding: 0 30px 11px;
      }

      ${HeaderList} {
        padding: 15px 30px;
      }
    `}
`;

export const ActionsRight = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const ButtonContent = styled.div`
  color: #202353;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const ButtonShowDraft = styled.div`
  display: flex;
  height: 30px;
  padding: 0 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 99px;
  border: 1px solid #e1e1ea;
  background: #fff;
  cursor: pointer;

  .custom-close-circle {
    display: none;
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: #f0f1ff;
    ${ButtonContent} {
      color: #5158cf;
    }
  }

  ${({ isShowDraft }) =>
    isShowDraft &&
    css`
      border: 1px solid #5158cf;
      background: #f0f1ff;

      .custom-close-circle {
        display: block;

        path {
          fill: #5158cf;
        }
      }

      ${ButtonContent} {
        color: #5158cf;
      }
    `}
`;
