import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const CompactInformationItem = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 5px 10px;
  margin-right: 5px;
`;

export const ProgramStatus = styled(CompactInformationItem)`
  position: relative;
  padding-left: 20px;
  :before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${props => (props.published ? '#6ACF47' : '#ffc90c')};
    z-index: 1;
  }
`;

export const CompactDataContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${CompactInformationItem} {
    :last-child {
      margin-right: 0;
    }
  }
`;

const getBackgroundImage = coverImage => {
  return coverImage || `${CDN_URL}/images/studio_program_default_cover_image.png`;
};

export const CoverPhotoContainer = styled.div`
  height: 181px;
  width: 100%;
  position: relative;
  background: #cccbc3 url(${props => getBackgroundImage(props.coverImage)}) no-repeat center center;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StudioProgramBaseInformation = styled.div`
  padding: 20px 15px;
  background: #ffffff;
  border: 1px solid #dadfea;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  .studio-program__name {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    margin-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
  .studio-program__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #202353;
    opacity: 0.8;
    margin-bottom: 15px;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    min-height: 40px;
  }
  .studio-program__timeStatus {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #202353;
  }
`;

export const CompletedIcon = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(${CDN_URL}/images/checkbox_greenwhite.svg);
  background-size: cover;
  margin-right: 5px;
`;

export const ActiveCheck = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #5158cf;
  background-image: url(${CDN_URL}/images/check_studio_active.svg);
  background-repeat: no-repeat;
  padding: 6px 12px 6px 28px;
  background-position: 10px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;

export const ProcessProgram = styled.progress`
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  -webkit-appearance: none;
  &::-webkit-progress-bar {
    background-color: #DADFEA;
  }
  &.progress__active::-webkit-progress-value {
      background-color: #22CF7D;
    }
  }
  &.progress__paused::-webkit-progress-value {
      background-color: #FFCE1F;
    }
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 345px;
  margin-right: 30px;
  :hover {
    .studio-program__name {
      color: #5158cf;
    }
  }
  .clientStudio__container {
    color: initial;
    text-decoration: none !important;
    display: block;
  }
`;
