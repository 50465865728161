import React, { PureComponent } from 'react';
import { Area, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmptyLodash from 'lodash/isEmpty';

import CustomTooltipContent from './CustomTooltipContent';
import CustomLineCursor from './CustomLineCursor';
import CustomDot from './CustomDot';
import CustomizedAxisTick from './CustomizedAxisTick';
import LoadingIndicator from 'shared/LoadingIndicator';

// assets
import { ReactComponent as ChartBarIcon } from 'assets/icons/bar-chart.svg';
import { ReactComponent as ArrowNextIcon } from 'assets/icons/arrow-circle.svg';
import { ReactComponent as ChartLineIcon } from 'assets/icons/line-chart.svg';

import { filterChartViewMode } from 'actions/bodyMetric';
import { formatMinValueToHM, mongoObjectId } from 'utils/commonFunction';
import { BOUNDARY_VALUES, CHART_COLORS, DATE_FORMAT, PERIOD_GROUP } from '../constants';
import {
  convertUnitTime,
  formatSecValueToSM,
  formatWeeklyLabel,
  getDataMax,
  getDataMin,
  getMinMaxValue,
  getNewDataMinMax,
  getTicks,
  handleShowCursor,
} from '../chartHelper';
import { Wrapper } from './styles';

const HOUR_LABELS = ['12AM', '4AM', '8AM', '12PM', '4PM', '8PM'];

const containsMinOrHour = str => {
  return str.includes('min') || str.includes('h');
};

const containsSecond = str => {
  return str.includes('sec') && !str.includes('min') && !str.includes('h');
};

const containsMinute = str => {
  return str.includes('min') && !str.includes('h');
};

const containsHour = str => {
  return str.includes('h');
};

// chartData: {time, value, goal}[]
class MetricChartExercise extends PureComponent {
  constructor(props) {
    super(props);
    this.areaRef = null;
    this.chartRef = null;
    this.chartWrapperRef = React.createRef();
    this.state = {
      tooltipPos: { x: undefined, y: undefined },
      filledData: [],
      barWidth: 20,
      xInterval: 0,
    };
    this.DATE_FORMAT = 'YYYY-MM-DD';
  }

  componentDidUpdate() {
    handleShowCursor(true);
  }

  componentDidMount() {
    handleShowCursor(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chartData, rangeTime } = nextProps;
    const isEmpty = chartData.length === 0;
    const filledData = isEmpty ? [] : this.fillChartDataByPeriodTime(chartData, rangeTime);
    const barWidth = this.getSizeOfColumn(filledData.length);
    const xInterval = this.getXAxisInterval(rangeTime);

    const isMinOrHour =
      !isEmptyLodash(filledData) && filledData[0] && containsMinOrHour(get(filledData[0], 'unit.title'));

    const newChartData = filledData.map(item => ({
      ...item,
      value: item.value !== undefined ? convertUnitTime(item.value) : undefined,
    }));

    this.setState({ filledData: isMinOrHour ? newChartData : filledData, barWidth, xInterval });
  }

  updateAreaRef = r => {
    this.areaRef = r || this.areaRef;
  };

  updateTooltipBarChart = data => {
    if (!this.chartRef) return;

    // Logic: Check for showing the tooltip at the bottom when it's outside the chart.
    const chartWrapperElement = this.chartWrapperRef && this.chartWrapperRef.current;
    const chartWrapperRect = chartWrapperElement && chartWrapperElement.getBoundingClientRect();
    const tooltipElement = document.querySelector('.recharts-tooltip-cursor');
    const tooltipRect = tooltipElement && tooltipElement.getBoundingClientRect();
    const HEIGHT_TOOLTIP = 75;
    const POSITION_TOOLTIP_BOTTOM = 90;
    let checkToolTipShowBottom = false;

    if (tooltipRect && chartWrapperRect) {
      const tooltipTop = tooltipRect.top;
      const chartWrapperTop = chartWrapperRect.top + HEIGHT_TOOLTIP;

      if (tooltipTop < chartWrapperTop) {
        checkToolTipShowBottom = true;
      }
    }

    const tooltip = document.getElementById('metric-chart-tooltip-customized');
    const { width: tooltipW, height } = tooltip ? tooltip.getBoundingClientRect() : { width: 0 };
    let x = data.x - tooltipW / 2 + 10;

    if (x + tooltipW > this.chartRef.props.width) {
      x -= x + tooltipW - this.chartRef.props.width;
    }
    const y = data.y - height - 10;
    this.setState({ tooltipPos: { x, y: checkToolTipShowBottom ? y + POSITION_TOOLTIP_BOTTOM : y } });
  };

  fakeChartDataWithGoal = goal => {
    return new Array(7).fill({ goal: goal * 1.5 });
  };

  getSizeOfColumn = len => {
    if (len < 21) return 20;
    if (len < 31) return 10;
    if (len < 60) return 6;
    return 4;
  };

  getXLabels = (filledData, interval) => {
    const labels = [];
    for (let i = filledData.length - 1; i >= 0; i -= interval) {
      labels.push(filledData[i].time);
    }

    return labels;
  };

  formatXAxisLabel = label => {
    const {
      rangeTime: { period_group },
    } = this.props;
    const { xInterval, filledData } = this.state;
    const labels = period_group === PERIOD_GROUP.HOURLY ? HOUR_LABELS : this.getXLabels(filledData, xInterval);

    if (labels.includes(label)) {
      if (period_group === PERIOD_GROUP.WEEKLY) {
        const [finalLabel] = (label || '').split(' - ');
        return finalLabel || '';
      }
      return label;
    }
    return '';
  };

  getDurationTime = (rangeTime, unit = 'd') => {
    const { from_date, to_date } = rangeTime || {};
    return moment(to_date, this.DATE_FORMAT).diff(moment(from_date, this.DATE_FORMAT), unit) + 1;
  };

  fillChartDataByHours = (chartData, rangeTime) => {
    const { from_date } = rangeTime || {};
    const chartItemCloned = cloneDeep(chartData[0]);
    const fromDateMoment = moment(from_date, this.DATE_FORMAT);
    const items = [];

    for (let i = 0; i < 24; i++) {
      const day = fromDateMoment.clone().add(i, 'h');
      const chartItem = chartData.find(item => day.format('hA') === moment(item.date).format('hA'));
      if (chartItem) {
        chartItem.time = moment(chartItem.date).format('hA');
      }
      items.push(
        chartItem || {
          ...chartItemCloned,
          _id: mongoObjectId(),
          time: day.format('hA'),
          value: undefined,
        },
      );
    }
    return items;
  };

  fillChartDataByDays = (chartData, rangeTime) => {
    const { from_date } = rangeTime || {};
    const days = this.getDurationTime(rangeTime);
    const fromDateMoment = moment(from_date, this.DATE_FORMAT);
    const chartItemCloned = cloneDeep(chartData[0]);
    const items = [];

    for (let i = 0; i < days; i++) {
      const day = fromDateMoment.clone().add(i, 'd');
      const chartItem = chartData.find(item => day.format('MM-DD-YYYY') === item.day);
      if (chartItem) {
        chartItem.time = moment(chartItem.day, 'MM-DD-YYYY').format('MMM D');
      }
      items.push(
        chartItem || {
          ...chartItemCloned,
          _id: mongoObjectId(),
          time: day.format('MMM D'),
          value: undefined,
        },
      );
    }
    return items;
  };

  getWeekLabel = (startMM, endMM) => {
    if (startMM.isSame(endMM, 'month')) {
      return `${startMM.format('MMM')} ${startMM.format('D')} - ${endMM.format('D')}`;
    }
    return `${startMM.format('MMM')} ${startMM.format('D')} - ${endMM.format('MMM')} ${endMM.format('D')}`;
  };

  getWeeksInRange = rangeTime => {
    const weeks = [];
    const startDateMM = moment(rangeTime.from_date, this.DATE_FORMAT);
    const startDateMMWeek = moment(startDateMM).startOf('week');
    const endDateMM = moment(rangeTime.to_date, this.DATE_FORMAT);

    while (startDateMMWeek.isSameOrBefore(endDateMM)) {
      const endClone = startDateMMWeek.clone().add(6, 'days').endOf('week');
      weeks.push({
        start: startDateMMWeek.format(this.DATE_FORMAT),
        end: endClone.format(this.DATE_FORMAT),
        group_name: `${startDateMMWeek.year()}-${startDateMMWeek.weeks() > 9 ? '' : '0'}${startDateMMWeek.weeks()}`,
        label: this.getWeekLabel(startDateMMWeek, endClone),
      });
      startDateMMWeek.add(1, 'week');
    }
    return weeks;
  };

  fillChartDataByWeeks = (chartData, rangeTime) => {
    const items = [];
    const weeks = this.getWeeksInRange(rangeTime);
    const chartItemCloned = cloneDeep(chartData[0]);

    for (let week of weeks) {
      const chartItem = chartData.find(item => week.group_name === item.group_name);
      if (chartItem) {
        const start = moment(chartItem.date).startOf('week');
        const end = moment(chartItem.date).endOf('week');
        chartItem.time = this.getWeekLabel(start, end);
        chartItem.start = start.format(this.DATE_FORMAT);
        chartItem.end = end.format(this.DATE_FORMAT);
      }
      items.push(
        chartItem || {
          ...chartItemCloned,
          ...week,
          _id: mongoObjectId(),
          time: week.label,
          value: undefined,
        },
      );
    }
    return items;
  };

  fillChartDataByMonths = (chartData, rangeTime) => {
    const { from_date, to_date } = rangeTime || {};
    const items = [];
    const chartItemCloned = cloneDeep(chartData[0]);
    const startDateMM = moment(from_date, DATE_FORMAT);
    const startDateMMMonth = moment(startDateMM).startOf('month');
    const endDateMM = moment(to_date, DATE_FORMAT);

    while (startDateMMMonth.isSameOrBefore(endDateMM)) {
      const chartItem = chartData.find(item => startDateMMMonth.format('YYYY-MM') === item.group_name);
      if (chartItem) {
        const start = moment(chartItem.date).startOf('month');
        const end = moment(chartItem.date).endOf('month');
        chartItem.start = start.format(this.DATE_FORMAT);
        chartItem.end = end.format(this.DATE_FORMAT);
        chartItem.time = moment(start, this.DATE_FORMAT).format('MMM');
      }
      items.push(
        chartItem || {
          ...chartItemCloned,
          _id: mongoObjectId(),
          time: startDateMMMonth.format('MMM'),
          value: undefined,
        },
      );
      startDateMMMonth.add(1, 'month');
    }

    return items;
  };

  fillChartDataByPeriodTime = (chartData, rangeTime) => {
    switch (rangeTime.period_group) {
      case PERIOD_GROUP.HOURLY:
        return this.fillChartDataByHours(chartData, rangeTime);
      case PERIOD_GROUP.DAILY:
        return this.fillChartDataByDays(chartData, rangeTime);
      case PERIOD_GROUP.WEEKLY:
        return this.fillChartDataByWeeks(chartData, rangeTime);
      default:
        return this.fillChartDataByMonths(chartData, rangeTime);
    }
  };

  getXAxisInterval = rangeTime => {
    if (!rangeTime) return 0;

    switch (rangeTime.period_group) {
      case PERIOD_GROUP.HOURLY:
        return 4;
      case PERIOD_GROUP.DAILY:
        const days = this.getDurationTime(rangeTime, 'd');
        if (days <= 7) return 0;

        const weeks = this.getDurationTime(rangeTime, 'week');
        if (weeks <= 2) return 2;

        const months = this.getDurationTime(rangeTime, 'month');
        if (months <= 1) return 4;
        if (months <= 2) return 8;
        return 12;
      case PERIOD_GROUP.WEEKLY:
        const durationMonths = this.getDurationTime(rangeTime, 'month');
        if (durationMonths <= 1) return 0;
        if (durationMonths <= 2) return 1;
        if (durationMonths <= 3) return 2;
        if (durationMonths <= 4) return 3;
        if (durationMonths <= 8) return 5;
        return 8;
      default:
        return 0;
    }
  };

  tooltipActiveCallback = active => {
    if (this.props.chartType === 'line' && this.state.tooltipActive !== active) {
      this.setState({ tooltipActive: active });
    }
  };

  handleTickFormat = value => {
    const { filledData } = this.state;
    const isHasFilledData = !isEmptyLodash(filledData) && filledData[0];
    const strTitle = isHasFilledData && get(filledData[0], 'unit.title');
    const isUnitTime =
      isHasFilledData && (containsSecond(strTitle) || containsMinute(strTitle) || containsHour(strTitle));

    const { min, max } = getMinMaxValue(filledData);
    const isConvertValue = isUnitTime && max >= 60;

    const dataMin = getDataMin(min, max);
    const dataMax = getDataMax(min, max);

    const [newDataMin] = getNewDataMinMax(dataMin, dataMax, isUnitTime);

    if (isHasFilledData) {
      if (containsSecond(strTitle)) {
        return value === 0 || value === newDataMin ? '' : formatSecValueToSM(value, true, !isConvertValue);
      }
      if (containsMinute(strTitle)) {
        return value === 0 || value === newDataMin ? '' : formatMinValueToHM(value, true, !isConvertValue);
      }
      if (containsHour(strTitle)) {
        return value === 0 || value === newDataMin ? '' : formatMinValueToHM(value, true, !isConvertValue);
      }
    }

    // Default case: return the original value
    return value === 0 || value === newDataMin ? '' : value;
  };

  renderChart = () => {
    const {
      chartData,
      chartType = 'line',
      color = CHART_COLORS[0],
      width = 500,
      height = 400,
      rangeTime: { period_group },
      isLoading,
    } = this.props;
    const { filledData, barWidth, tooltipPos, xInterval, tooltipActive } = this.state;

    const isEmpty = chartData.length === 0;
    const bgColorId = `bgColorExercise`;
    const isChartLine = chartType === 'line';

    const isHasFilledData = !isEmptyLodash(filledData) && filledData[0];
    const strTitle = isHasFilledData && get(filledData[0], 'unit.title');
    const isUnitTime =
      isHasFilledData && (containsSecond(strTitle) || containsMinute(strTitle) || containsHour(strTitle));

    const { min, max } = getMinMaxValue(filledData);
    const isNoData = BOUNDARY_VALUES.includes(min) && BOUNDARY_VALUES.includes(max);

    const dataMin = getDataMin(min, max);
    const dataMax = getDataMax(min, max);

    const [newDataMin, newDataMax, tickCount] = getNewDataMinMax(dataMin, dataMax, isUnitTime);

    const domain = isNoData ? undefined : [newDataMin, newDataMax];
    const ticks = isNoData ? undefined : getTicks(newDataMin, newDataMax, tickCount);

    return (
      <ResponsiveContainer
        width="100%"
        height="100%"
        className={classNames({ 'no-data': isEmpty, 'chart-line': isChartLine })}
      >
        {!isLoading && (
          <ComposedChart width={width} height={height} data={filledData} ref={r => (this.chartRef = r)}>
            <XAxis
              dataKey="time"
              padding={{
                left: isChartLine ? 20 : 0,
              }}
              tickFormatter={xInterval === 0 ? lab => formatWeeklyLabel(lab, period_group) : this.formatXAxisLabel}
              interval={0}
              dy={10}
              tickLine={false}
              tick={<CustomizedAxisTick filledData={filledData} xInterval={xInterval} period_group={period_group} />}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              padding={{ top: 10 }}
              tickFormatter={this.handleTickFormat}
              domain={domain}
              ticks={ticks}
              tickCount={tickCount}
              allowDataOverflow
            />
            <Tooltip
              cursor={
                isChartLine ? (
                  <CustomLineCursor
                    color={color}
                    areaRef={this.areaRef}
                    updateTooltipBarChart={this.updateTooltipBarChart}
                    isHoveringChart
                  />
                ) : (
                  false
                )
              }
              content={
                <CustomTooltipContent
                  isExerciseMetric
                  isChartLine
                  chartData={chartData}
                  tooltipActiveCallback={this.tooltipActiveCallback}
                />
              }
              animationDurationNumber={100}
              coordinate={tooltipPos}
              position={tooltipPos}
            />
            {isChartLine ? (
              <>
                <defs>
                  <linearGradient id={bgColorId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="10%" stopColor={color} stopOpacity={0.3} />
                    <stop offset="99%" stopColor="#FFFFFF" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <Area
                  dataKey="value"
                  stroke={color}
                  fill={`url(#${bgColorId})`}
                  ref={this.updateAreaRef}
                  activeDot={false}
                  connectNulls
                  fillOpacity={0.4}
                  isAnimationActive={false}
                  dot={!tooltipActive && chartData.length === 1 ? p => <CustomDot {...p} color={color} /> : undefined}
                />
              </>
            ) : (
              <Bar
                dataKey="value"
                fill={color}
                animationDuration={500}
                barSize={barWidth}
                radius={[3, 3, 0, 0]}
                onMouseOver={this.updateTooltipBarChart}
              />
            )}
          </ComposedChart>
        )}
      </ResponsiveContainer>
    );
  };

  handleChangeRange = isNext => () => {
    const {
      filterChartViewMode,
      filterTime: { from_date, to_date, period_group },
    } = this.props;
    const fromDateMoment = moment(from_date, this.DATE_FORMAT);
    const toDateMoment = moment(to_date, this.DATE_FORMAT);
    const diff = toDateMoment.diff(fromDateMoment, 'd') + 1;
    const newFromDate = isNext ? fromDateMoment.add(diff, 'd') : fromDateMoment.subtract(diff, 'd');
    const newToDate = isNext ? toDateMoment.add(diff, 'd') : toDateMoment.subtract(diff, 'd');

    filterChartViewMode(newFromDate.format(this.DATE_FORMAT), newToDate.format(this.DATE_FORMAT), period_group);
  };

  render() {
    const {
      isLoading,
      chartData,
      color,
      minHeight = 312,
      filterTime,
      chartType = 'line',
      noDataLabel = 'No data available',
    } = this.props;
    const ChartIcon = chartType === 'line' ? ChartLineIcon : ChartBarIcon;
    const disabledNext = moment().diff(moment(filterTime.to_date, this.DATE_FORMAT), 'd') <= 0;

    return (
      <Wrapper color={color} minHeight={minHeight} className="chart-wrapper" ref={this.chartWrapperRef}>
        <button
          className="chart-arrow-btn exercise-btn chart-prev-btn"
          onClick={this.handleChangeRange(false)}
          disabled={isLoading}
        >
          <ArrowNextIcon />
        </button>
        <button
          className="chart-arrow-btn exercise-btn chart-next-btn"
          onClick={this.handleChangeRange(true)}
          disabled={disabledNext || isLoading}
        >
          <ArrowNextIcon />
        </button>
        {isLoading && <LoadingIndicator title="Loading chart..." className="loading-chart-indicator" />}
        {this.renderChart()}
        {!chartData.length && !isLoading && (
          <div className="chart-no-data">
            <ChartIcon width={32} />
            <p>{noDataLabel}</p>
          </div>
        )}
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  filterChartViewMode: bindActionCreators(filterChartViewMode, dispatch),
});

const mapState = ({ bodyMetric }) => ({
  filterTime: bodyMetric.filterTime,
});

export default connect(mapState, mapDispatchToProps)(MetricChartExercise);
