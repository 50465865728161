import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormGroup } from 'layouts/Login/style';
import { ReactQuillWrapper } from './styles';
import { ErrorMessage } from 'shared/FormControl';
import classNames from 'classnames';

const modules = {
  toolbar: [
    [{ align: '' }, { align: 'center' }],
    ['bold', 'italic'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  'align',
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  // 'link', ignored
];

export default function DescriptionFormItem(props) {
  return (
    <FormGroup
      className={classNames({ error: !!props.isSubmitted && (!props.value || props.value === '<p><br></p>') })}
    >
      <label>Description</label>
      <ReactQuillWrapper
        theme="snow"
        onChange={props.onChange}
        value={props.value || ''}
        modules={modules}
        formats={formats}
        placeholder="Tell us something about your packages"
        className={classNames({ error: !!props.isSubmitted && (!props.value || props.value === '<p><br></p>') })}
      />
      <ErrorMessage>Please enter package description</ErrorMessage>
    </FormGroup>
  );
}
