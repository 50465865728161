import { Modal, Radio } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

const baseText = `
  color: #202353;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  font-size: 13px;
`;

export const Wrapper = styled(Modal)`
  &.ui.modal {
    width: 420px;
    min-height: 428px;
    padding: 26px 25px 0 25px;
    border-radius: 5px;
  }
`;

export const Title = styled.div`
  ${baseText}
  font-size: 17px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 60px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;

  .data-point-wrapper {
    margin-top: -1px;
  }

  .key-metric {
    padding-left: 6px;
  }

  :last-child {
    margin-bottom: 27px;
  }
`;

export const ChartTypeSection = styled.div`
  display: flex;
  height: 31px;
  width: 250px;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  overflow: hidden;
`;

export const ChartOption = styled.div`
  display: flex;
  ${baseText}
  font-size: 12px;
  display: flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  border-left: ${({ right }) => right && '1px solid #e1e1ea'};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ active, disabled }) =>
    active
      ? css`
          color: #202353;
          background: #f0f0f2;

          .line-chart-icon,
          .bar-chart-icon {
            g {
              path {
                stroke: #202353;
              }
            }
          }
        `
      : css`
          color: #7b7e91;
          background: #fff;

          .line-chart-icon,
          .bar-chart-icon {
            g {
              path {
                stroke: #7b7e91;
              }
            }
          }

          :hover {
            background: ${({ disabled }) => (disabled ? '#fff' : '#f0f1ff')};
            color: ${({ disabled }) => (disabled ? '#7b7e91' : '#5158cf')};

            .line-chart-icon,
            .bar-chart-icon {
              g {
                path {
                  stroke: ${({ disabled }) => (disabled ? '#7b7e91' : '#5158cf')};
                }
              }
            }
          }
        `}
`;

export const TitleSettingItem = styled.div`
  display: flex;
  ${baseText}
  font-size: 12px;
  width: 120px;
  text-align: left;
`;

export const ColorPickerWrapper = styled.div`
  display: flex;
  height: 36px;
  max-width: 190px;
  align-items: center;
  padding: 8px 10px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid #e1e1ea;
  overflow: hidden;

  :hover {
    cursor: pointer;
    background: #f0f1ff;
  }
`;

export const ColorSquare = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
`;

export const ColorLine = styled.div`
  width: 140px;
  height: 2px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
  margin-left: 10px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  padding-left: 8px;

  .show-data-point {
    margin-top: 6px;
  }

  .data-point {
    margin-bottom: 9px;
  }
`;

export const UnitItem = styled.div`
  ${baseText}
  font-weight: 400;
  margin-bottom: 10px;

  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  .data-point-radio {
    padding-top: 2px;
  }

  :last-child {
    margin-bottom: 0;
  }

  .ui.radio.checkbox input:checked ~ label:before,
  .ui.radio.checkbox input:focus:checked ~ label:before {
    border-color: #5158cf !important;
  }
  .ui.radio.checkbox input:checked ~ label::after,
  .ui.radio.checkbox input:focus:checked ~ label::after {
    background-color: #5158cf !important;
  }

  .ui.radio.checkbox label:before {
    transform: scale(1.3);
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        .ui.radio.checkbox label:before {
          border-color: #5158cf !important;
          background-color: #f0f1ff !important;
        }
      }
    `};
  .ui.disabled.checkbox label {
    cursor: not-allowed !important;
  }
  .ui.radio.checkbox label:after {
    transform: scale(0.8);
  }
`;

export const UnitRadio = styled(Radio)`
  margin-right: 15px;
`;

export const KeyMetricItem = styled.div`
  ${baseText}
  font-weight: 400;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }

  ${({ isHeartRate, checked }) =>
    isHeartRate && checked
      ? css`
          .evf-checkbox {
            opacity: 1;
          }
        `
      : css`
          .evf-checkbox.disabled {
            opacity: 0.6;
          }
        `}

  .key-metric__checkbox,
  .data-points__checkbox {
    padding-left: 34px;
    ${baseText}
    font-weight: 400;

    .checkbox-icon {
      height: 20px;
      width: 20px;
      border-radius: 5px;
      background-size: 60%;
      background-position: 4px 4px;
    }

    ${({ isHeartRate }) =>
      isHeartRate &&
      css`
        input:checked ~ .checkbox-icon {
          background-color: #eaeef1;
          border-color: #eaeef1;
          background-image: url('/images/checked_grey.svg');
        }
      `}
  }

  .data-points__checkbox {
    margin-left: -2px;
  }
`;

export const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 15px !important;
  border-top: 1px solid #f0f0f2;
`;

export const CancelButton = styled(Button)`
  display: flex;
  background-color: transparent;
  padding: 10px 15px;
  min-width: 72px !important;
  max-height: 36px !important;
  ${baseText}
  color: #7b7e91;
  border: none;
  margin-right: 6px;
`;

export const ApplyButton = styled(Button)`
  display: flex;
  background-color: transparent;
  padding: 10px 15px;
  min-width: 66px !important;
  max-height: 36px !important;
  ${baseText}
  color: #5158cf;
  border: none;

  :hover {
    background-color: #f0f1ff;
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    height: 192px !important;
    border-radius: 8px;
    background: #fff !important;
    .confirm-content-header {
      padding: 31px 30px 0;
      .confirm-header-label {
        line-height: 150%;
        color: #202353;
        font-size: 21px;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 25px 30px 25px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 6px 0 4px;
        font-size: 12px;
        margin-left: 8px !important;
      }
      .confirm-no-button {
        width: 89px;
        min-width: 89px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 146px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;

export const ToggleWrapper = styled.div`
  margin-top: 3px;
  .unit-client-toggle {
    ${baseText}
    font-weight: 400;
    label {
      padding-left: 34px;
      margin-left: -2px;
    }

    input ~ label::after {
      width: 12px;
      height: 12px;
      left: calc(26px - 23px);
    }

    input ~ label::before {
      width: 26px;
      height: 16px;
      background-color: #a3a3b5;
      margin-top: 2px;
    }
  }

  .toggle-input.checked {
    ${baseText}
    font-weight: 400;

    input ~ label::after {
      left: calc(26px - 14px);
    }

    input ~ label::before {
      width: 26px;
      height: 16px;
      background-color: #5158cf;
      margin-top: 2px;
    }
  }
`;

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline;
  .display-data-points-tooltip {
    ${baseText}
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    padding: 8px 10px;
    width: 261px !important;
    background-color: #333;
    color: #fff;
    margin-top: -12px !important;
    border-radius: 4px;
    z-index: 29;
    user-select: none;
    text-align: left;
    &.type-dark.place-top:after {
      border-top-color: #333;
      bottom: -7px;
      margin-left: -8px;
    }
  }
`;

export const IconInfo = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -24px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  z-index: 19;
  svg {
    width: 12px;
    height: 12px;
    path {
      fill: #a3a3b5;
    }
  }
  &:hover {
    background: #f0f1ff !important;
    svg path {
      fill: #5158cf !important;
    }
  }
`;
