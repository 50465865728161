import styled from 'styled-components';
import RegularImage from 'assets/icons/section_format_regular_update.svg';
import RegularImageHover from 'assets/icons/section_format_regular_hover_update.svg';
import IntervalImage from 'assets/icons/section_format_interval_update.svg';
import IntervalImageHover from 'assets/icons/section_format_interval_hover_update.svg';
import AmrapImage from 'assets/icons/section_format_amrap_update.svg';
import AmrapImageHover from 'assets/icons/section_format_amrap_hover_update.svg';
import TimedImage from 'assets/icons/section_format_timed_update.svg';
import TimedImageHover from 'assets/icons/section_format_timed_hover_update.svg';
import FreeStyleImage from 'assets/icons/section_format_freestyle_update.svg';
import FreeStyleImageHover from 'assets/icons/section_format_freestyle_hover_update.svg';

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #202353;
  margin: 0;
  padding-bottom: 4px;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: #202353;
`;

export const OptionLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  margin-bottom: 5px;
  color: #202353;
`;

export const OptionDesc = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 5px;
  color: #202353;
`;

export const Option = styled.div`
  padding: 30px 25px;
  display: inline-block;
  width: 222px;
  height: 189px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #979797;
  cursor: pointer;
  padding: 103px 10px 30px;
  background-repeat: no-repeat;
  background-position: center 23px;
  background-size: 120px auto;
  :hover {
    background-color: #fbfbff;
    border-color: #5158cf;
  }
  &.regular {
    background-image: url(${RegularImage});
    :hover {
      background-image: url(${RegularImageHover});
    }
  }
  &.interval {
    background-image: url(${IntervalImage});
    :hover {
      background-image: url(${IntervalImageHover});
    }
  }
  &.amrap {
    background-image: url(${AmrapImage});
    :hover {
      background-image: url(${AmrapImageHover});
    }
  }
  &.timed {
    background-image: url(${TimedImage});
    :hover {
      background-image: url(${TimedImageHover});
    }
  }
  &.freestyle {
    background-image: url(${FreeStyleImage});
    :hover {
      background-image: url(${FreeStyleImageHover});
    }
  }
`;

export const OptionContainer = styled.div`
  padding: 0 30px 30px;
  width: 758px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
