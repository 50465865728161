import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const FirstAddAsset = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  margin-top: -13px;
  ${props =>
    props.isFirstTime &&
    css`
      padding: 15px;
      background: #fafbfc;
      border: 1px dashed #c8cfe0;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #f3f5f8;
  border-radius: 5px;
  margin-top: -13px;
  padding: 10px 15px 10px 10px;
  .break {
    flex-basis: 100%;
    height: 0;
  }

  .common-select-container__value-container span {
    font-weight: 600;
    color: #202353;
  }
  .common-select-container__control {
    border: 1px solid #d6dae4;
    background-color: #ffffff !important;
    :hover {
      border: 1px solid #5158cf;
    }
  }

  .common-select-container__menu {
    width: 182px;
  }
  .product-asset-dropdown {
    display: inline-flex;
    flex: 0 0 169px;
  }
  .search-input {
    flex: 0 0 304px;
    .single-select__control {
      cursor: text;
      height: 42px;
      border: 1px solid #d6dae4;
      box-shadow: unset;
      :hover {
        border: 1px solid #5158cf;
      }
    }
    .single-select__indicators svg {
      transform: translateX(-13.5px);
    }
    .single-select__control--is-focused {
      border: 1px solid #5158cf;
    }
    .single-select__menu {
      margin-top: 0px;
      box-shadow: none;
    }
    input {
      background-color: #ffffff !important;
      border: 1px solid #d6dae4;
      background: url(${CDN_URL}/images/search_black_thin.svg) no-repeat;
      background-position: 314px;
      padding-left: 15px;
      ::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
        opacity: 0.3;
      }
      transition: border 100ms;
      :active,
      :focus {
        border: 1px solid #5158cf;
      }
    }
    margin-left: 5px;
  }
`;

export const CancelButton = styled.span`
  flex-shrink: 0;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: #76778a;
  margin-top: 15px;
  margin-left: auto;
  :hover {
    color: #5559ff;
  }
`;

export const CustomAssetOption = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  ::after {
    content: '';
    height: 1px;
    width: 90%;
    left: 5%;
    position: absolute;
    top: 0px;
    background: #eeeff2;
  }

  :nth-of-type(2) {
    ::after {
      height: 0px;
    }
  }

  :hover {
    background-color: #f7f6ff;
  }
  padding: 12px 15px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .asset-content {
    width: 100%;
    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
    }
    &.studio-collection {
      display: flex;
      .studio-cover-container {
        margin-right: 9px;
        width: 64px;
        height: 48px;
        flex: 0 0 64px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
      .studio-info-container {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
      }
      .title {
        font-weight: 700;
      }
    }
    &.forum {
      display: flex;
      .forum-cover-container {
        margin-right: 9px;
        width: 54px;
        height: 54px;
        flex: 0 0 54px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
      .forum-info-container {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
      }
      .title {
        margin-bottom: 6.5px;
      }
    }
  }

  .autoflow-info-container {
    display: flex;
    svg {
      margin-left: 4px;
    }
    &.title-container {
      justify-content: space-between;
    }
    .autoflow-author-avatar {
      flex: 0 0 25px;
      margin-left: 5px;
    }
  }
  .asset-info {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #818da1;
  }
  .resource-info-container {
    display: flex;
    justify-content: space-between;
    .clients-count-badge {
      padding: 0px 6px;
      background: #f3f5f8;
      border-radius: 3px;
      font-size: 12px;
      line-height: 150%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        margin-right: 7px;
        opacity: 0.2;
      }
      color: rgba(32, 35, 83, 0.4);
    }
  }
  .program-info-container {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 700;
      margin-bottom: 8px;
      flex: auto;
    }
    .live-sync-badge {
      flex: 0 0 56px;
      margin-left: 10px;
      height: 19px;
      width: 56px;
      font-weight: 600;
      border: 1px solid rgba(80, 136, 226, 0.5);
      border-radius: 3px;
      font-size: 9px;
      line-height: 12px;
      color: #0c6af6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .program-title-container {
      display: flex;
    }
    .program-author-avatar {
      flex: 0 0 25px;
      margin-left: 5px;
    }
  }
  .autoflow-type {
    width: 102px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    line-height: 14px;
    text-transform: uppercase;
    border-radius: 3px;
  }

  .exact_date {
    background: #e3f2ff;
    color: #2480d6;
  }

  .interval {
    background: #eafcf1;
    color: #3bb240;
  }
`;

export const CustomPlaceholder = styled.div``;

export const CustomMenuList = styled.div`
  width: 304px;
  background: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 3px;
  height: 320px;
  overflow: auto;
  .menu-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    margin: 20px 10px 10px 15px;
  }
`;

export const CustomMenuListWrapper = styled.div`
  position: absolute;
  .spacer {
    height: 15px;
  }
`;
