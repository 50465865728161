import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TasksLibrary from 'components/TasksLibrary';
import { MODE } from './constants';

export default () => (
  <Switch>
    <Route exact path="/home/task-library" render={props => <TasksLibrary {...props} />} />
    <Route exact path="/home/task-library/:taskId" render={props => <TasksLibrary {...props} mode={MODE.EDIT} />} />
    <Route
      exact
      path="/home/task-library/:taskId/duplicate"
      render={props => <TasksLibrary {...props} mode={MODE.DUPLICATE} />}
    />
  </Switch>
);
