import { Button, Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import DeleteModalMealPlan from 'components/MealPlans/parts/DeleteModalMealPlan';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #7B7E91;
`;

export const CustomModal = styled(Modal)`
  padding: unset !important;

  &.ui.modal.evf-select-photo-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 691px;
    height: calc(100% - 184px);
    max-height: 692px;
    @media only screen and (max-height: 768px) {
      height: calc(100% - 20px);
    }
  }

  &.ui.modal.evf-select-photo-modal > .header,
  &.ui.modal.evf-select-photo-modal > .content,
  &.ui.modal.evf-select-photo-modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: unset !important;
  }

  &.ui.modal.evf-select-photo-modal > .content {
    background: #ffffff !important;
    height: calc(100% - 146px);
    line-height: unset !important;
    outline: none !important;
  }

  &.ui.modal.evf-select-photo-modal > .isDragActive.content {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
`;

export const HeaderWrapper = styled.div`
  padding: 25px;
  border-radius: 10px 10px 0 0;
  background: #ffffff;
`;

export const HeaderTitle = styled.div`
  ${baseText}
  color: #202353;
  font-size: 19px;
  font-weight: 600;
  line-height: 29px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 19px;
  height: 100%;
  padding: 0 10px 0 25px;
  margin-right: 9px;
  overflow: auto;
  align-content: flex-start;

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    color: #e1e1ea !important;
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  background: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 2px 15px 0px rgba(172, 172, 172, 0.2);

  svg {
    flex-shrink: 1;
    width: 16px;
    height: 16px;
  }
`;

export const PhotoItem = styled.div`
  position: relative;
  width: 201px;
  height: 134px;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 5px #fff inset;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    padding: 3px;
    border: 2px solid #5158cf;

    .image-with-fallback img {
      border-radius: unset;
    }

    ${props =>
      props.isShow &&
      css`
        ${DeleteButton} {
          visibility: visible;
          opacity: 1;
        }
      `}
  }

  .image-with-fallback {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background: #f2f2f2;
      object-fit: cover;
      outline: none;
    }
  }

  ${props =>
    props.noPadding &&
    css`
      &:hover {
        padding: unset;
        border: 2px solid transparent;
      }
    `}

  ${props =>
    props.isChecked &&
    css`
      padding: 3px;
      border: 2px solid #5158cf;

      .image-with-fallback img {
        border-radius: unset;
      }
    `}

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      width: 100%;
      height: 100%;
      padding-bottom: 25px !important;
      padding-right: 6px !important;
    `}
`;

export const TagDefault = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 10px;
  background: rgb(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 56px;
  height: 24px;
`;

export const TagContent = styled.div`
  ${baseText}
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  line-height: 150%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  padding: 15px 25px;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #f0f0f2;
`;

export const ButtonContent = styled.div`
  ${baseText}
  color: #ffffff;
  font-weight: 600;
`;

export const CustomButton = styled(Button)`
  display: flex !important;
  align-items: center !important;
  padding: 10px 20px !important;
  width: 78px;
  height: 36px;
  border-radius: 5px !important;
  background: #5158cf !important;
  margin: unset !important;
  &.ui.disabled.button {
    background-color: #eaeef1 !important;
    opacity: 1 !important;
    ${ButtonContent} {
      color: #7b7e90 !important;
    }
  }
`;

export const CustomConfirmModal = styled(DeleteModalMealPlan)`
  &.ui.modal.confirm-popup-container.delete-recipe-modal.delete-thumbnail-modal {
    .confirm-yes-button {
      min-width: 95px;
    }
  }
  &.ui.modal.confirm-popup-container.delete-recipe-modal .confirm-actions .confirm-yes-button {
    margin-right: 0 !important;
  }
`;

export const UploadContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${baseText}
  color: #7B7E91;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  border-radius: inherit;

  .custom-upload-content {
    color: #5158cf;
    font-size: 12px;
    font-weight: 600;

    :hover {
      text-decoration: underline;
    }
  }

  ${props =>
    props.background &&
    css`
      background: url(${props.background}) no-repeat center;
      background-size: cover;
    `}
`;
