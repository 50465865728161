import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TermPolicy from 'components/PricingPlan/PlanSummary/TermPolicy';
import * as S from './style';
import PromoAmount from './PromoAmount';

function getPriceByPeriod(period, packagePrice) {
  if (!packagePrice) {
    return 0;
  }
  return period === 'monthly' ? _.get(packagePrice, 'monthlyPricing', 0) : _.get(packagePrice, 'anuallyPricing', 0);
}

function filterPricingByPlan(pricings, plan) {
  return pricings
    .map((pricing, index) => ({ ...pricing, index }))
    .filter(pricing => _.get(pricing, 'packageNoVolumes', 0) === plan);
}

const PaymentInfo = props => {
  const selectPackage = props.selectPackage.toJS();
  const addOnsPricing = props.addOnsPricing.toJS();

  const zapierQuantity = _.get(props, 'billing.pricingPlan.zapier_quantity', 0);
  const zapierPricing = addOnsPricing.find(item => item.code === 'zapier');
  const zapierBasePricings = _.get(zapierPricing, 'basePricings', []);
  const filteredPricing = filterPricingByPlan(zapierBasePricings, zapierQuantity);

  return (
    <S.Wrapper>
      <S.PaymentInfo>
        <S.Heading>Your plan</S.Heading>
        <S.BillingDetailContainer>
          <S.BillingItem>
            <div>
              {`${_.upperFirst(selectPackage.tier)} Plan`} - {_.get(selectPackage, 'packagePrice.packageNoClients', 0)}{' '}
              clients{' '}
            </div>
            <div>${getPriceByPeriod(selectPackage.period, selectPackage.packagePrice)}</div>
          </S.BillingItem>
          {addOnsPricing.map(
            addOn =>
              selectPackage.addOns[addOn.code] && (
                <S.BillingItem>
                  <>
                    <div>
                      <span>
                        {addOn.title === 'Zapier' ? `${addOn.title} - API Plugin` : `${addOn.title} - Add-on`}
                      </span>
                    </div>
                    <div>
                      <span>
                        $
                        {addOn.code === 'zapier'
                          ? getPriceByPeriod(selectPackage.period, filteredPricing[0]).toFixed(2)
                          : getPriceByPeriod(selectPackage.period, addOn.basePrice).toFixed(2)}
                      </span>
                    </div>
                  </>
                </S.BillingItem>
              ),
          )}
        </S.BillingDetailContainer>
        <S.TotalDueContainer>
          <PromoAmount billingInfo={props.billingInfo} />
          <S.TotalDueItem>
            <div>Total due </div>
            <div>{Number(props.billingInfo.total).toUSDFormat()}</div>
          </S.TotalDueItem>
        </S.TotalDueContainer>
        <S.PayButton onClick={props.onPay} disabled={props.isExpiredCard}>
          Pay Now
        </S.PayButton>
      </S.PaymentInfo>
      <TermPolicy />
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    paymentInfo: rootReducer.billing.paymentInfo,
    billing: rootReducer.billing,
    is_payment_success: rootReducer.payment.is_payment_success,
    selectPackage: rootReducer.pricing.get('selectPackage'),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing'),
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
