import styled, { css } from 'styled-components';

export const ClientAutocompleteWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -15px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

export const SearchClientInputContainer = styled.div`
  background: #FFFFFF;
  border: 2px solid #E7E7FF;
  border-radius: 5px;
  position: relative;

  input {
    background: #FFFFFF;
    border: 1px solid #7072DD;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #333333;
    padding: 12px 15px;
    padding-right: 30px;
    width: 375px;
    outline: none;
  }

  .searchInput__icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`;