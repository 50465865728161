import { fromJS } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import { formatSameYear } from 'utils/commonFunction';
import { Types } from './actions';

const INITIAL_STATE = fromJS({
  groups: [],
  loading: false,
  lastId: '',
  isEnd: false,
});

const formatTitle = date => {
  const today = moment().format('MM/DD/YYYY');
  const yesterday = moment().subtract(1, 'days').format('MM/DD/YYYY');
  if (today === date) {
    return 'Today';
  } else if (yesterday === date) {
    return 'Yesterday';
  } else {
    return formatSameYear(date);
  }
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.PACKAGE_ACTIVITY_GET_LIST_REQUEST: {
      if (payload.isRefresh) {
        return state.set('groups', fromJS([])).set('loading', true).set('lastId', '');
      }
      return state.set('loading', true);
    }
    case Types.PACKAGE_ACTIVITY_GET_LIST: {
      let items = [];
      const lengthOfList = payload.list.length;


      const lastId = lengthOfList ? payload.list[lengthOfList - 1].id : '';

      payload.list.forEach(element => {
        items.push({
          ...element,
          group_date: moment(element.created_at).format('MM/DD/YYYY'),
        });
      });

      const oldGroups = state.get('groups').toJS();

      // New Groups
      let newGroups = _(items)
        .groupBy('group_date')
        .map(function (items, createdAt) {
          return {
            title: formatTitle(createdAt),
            items: _.map(items),
          };
        })
        .value();

      let allGroups = _([...oldGroups, ...newGroups])
        .groupBy('title')
        .map((item, title) => {
          let invoices = [];
          item.map(it => {
            invoices = [...invoices, ...it.items];
          });
          return {
            title: title,
            items: invoices,
          };
        })
        .value();

      const isEnd = lengthOfList <= 0; // Check end of list

      return state
        .set('groups', fromJS(allGroups))
        .set('loading', false)
        .set('lastId', fromJS(lastId))
        .set('isEnd', isEnd);
    }
    default:
      return state;
  }
};
