import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import CheckBlue from 'assets/icons/MealPlans/check_blue.svg';

const textBase = `
    color: #202353;
    font-family: 'Open sans';
    font-style: normal;
    line-height: 150%;
`;

const lineClamp1 = `
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`;

export const CustomOwnerShip = styled.div`
  .field > .label {
    padding-left: unset;
    margin-bottom: 3px;
    color: #7b7e91;
    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
  }

  .ui.dropdown.ownership-dropdown {
    position: relative;
    min-height: auto;
    height: 42px;
    border-radius: 5px !important;
    padding: 10px 34px 10px 10px;
    border-color: #e1e1ea !important;
    background-color: #ffffff !important;

    &:hover {
      background-color: #f8f8f8 !important;
    }

    .text {
      ${textBase};
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      ${lineClamp1}
      white-space: break-spaces !important;
      user-select: none;
    }

    .dropdown {
      &.icon {
        position: absolute;
        padding: 0;
        top: calc(50% + 7px);
        padding-right: 13px;

        &::before {
          content: '' !important;
          display: block;
          background-size: contain !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          position: absolute;
          background-image: url(${CDN_URL}/images/dropdown-mark-down.svg) !important;
          width: 16px;
          height: 16px;
          top: 50%;
          right: 9px;
          transform: translateY(-50%);
          color: #7b7e91;
        }
      }
    }

    &.has-spacing .menu {
      border: unset !important;
      border-radius: 10px;
      box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2) !important;
      padding: 10px 0 10px 10px;
      margin-top: 9px;
      overflow-y: scroll !important;

      .text {
        font-weight: 400;
      }

      .selected.item .text {
        color: #5158cf;
        font-weight: 600;
      }

      ::-webkit-scrollbar {
        width: 10px !important;
      }

      ::-webkit-scrollbar-thumb {
        background: #e1e1ea !important;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box !important;
        border-radius: 9999px;
      }
    }
  }

  .ui .ownership-header .sb-avatar.sb-avatar--src {
    flex-shrink: 0;
  }

  .ui.selection.visible.dropdown > .text:not(.default) {
    color: #202353;
    font-weight: 600;
  }

  .ui.selection.dropdown .menu > .item {
    display: flex;
    justify-content: space-between;
    border-top: unset;
    padding: 10px !important;
    border-radius: 5px;

    &:hover {
      background: #f0f1ff;

      .text {
        color: #5158cf;
      }
    }
  }

  .ui.dropdown.ownership-dropdown.has-spacing .menu > .item:not(.selected):hover {
    background: #f0f1ff;
  }

  .ui.dropdown .menu .selected.item,
  .ui.dropdown.selected {
    background: unset;

    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url(${CheckBlue});
      flex-shrink: 0;
    }
  }

  .ui.selection.active.dropdown {
    box-shadow: unset !important;
    border-color: #5158cf !important;
  }

  .field.client_type_field.share-with-org {
    margin-top: 20px;
  }
`;
