import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { ReactComponent as RatingIcon } from 'assets/icons/rating.svg';
import { ReactComponent as RatingActiveIcon } from 'assets/icons/rating_active.svg';

const RATES = [1, 2, 3, 4, 5];

function Rating({ rating, onChange }) {
  const [hoveringIndex, setHoveringIndex] = useState(null);
  const [hoveringWrapper, setHoveringWrapper] = useState(false);
  const wrapperRef = useRef(null);

  const handleMouseLeaveWrapper = () => {
    setHoveringWrapper(false);
  };

  const handleMouseEnterWrapper = () => {
    setHoveringWrapper(true);
  };

  useEffect(() => {
    const handlePointerMove = debounce(event => {
      const { clientX, clientY } = event;
      if (wrapperRef.current) {
        const bound = wrapperRef.current.getBoundingClientRect();
        const isBelongX = clientX >= bound.x && clientX <= bound.right;
        const isBelongY = clientY >= bound.y && clientY <= bound.bottom;
        setHoveringWrapper(isBelongX && isBelongY);
      }
    }, 100);

    document.addEventListener('pointermove', handlePointerMove);

    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className="rating-wrap"
      onMouseEnter={handleMouseEnterWrapper}
      onMouseLeave={handleMouseLeaveWrapper}
    >
      {RATES.map(index => {
        const active = hoveringWrapper ? index <= hoveringIndex : index <= rating;
        return (
          <span
            key={index}
            className={classNames('rating-icon', { active })}
            onMouseEnter={() => setHoveringIndex(index)}
            onClick={() => onChange(index)}
          >
            {active ? <RatingActiveIcon /> : <RatingIcon />}
          </span>
        );
      })}
    </div>
  );
}

export default Rating;
