import React from 'react';
import TabsHeader from './TabsHeader';
import Body from './Body';
import Footer from './Footer';
import Blank from './Blank';
import { PAGE_SIZE_PACKAGE } from 'redux/package-list/reducer';
import { ListWrapper } from './style';

const List = ({ counter, shouldHide, total, isMP, hasAlertHeader }) => {
  return (
    <ListWrapper isEmpty={counter.all <= 0} hasAlertHeader={hasAlertHeader}>
      {(counter.all > 0 || shouldHide) && <TabsHeader isMP={isMP} />}
      {counter.all > 0 ? (
        <>
          <Body isMP={isMP} />
          {total > PAGE_SIZE_PACKAGE && <Footer isMP={isMP} />}
        </>
      ) : (
        <Blank />
      )}
    </ListWrapper>
  );
};

export default List;
