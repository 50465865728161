import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Image } from 'semantic-ui-react';
import moment from 'moment';

import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import DateRangePicker from 'shared/DateRangePicker/NewDateRange';
import SelectCalendarType from 'shared/SelectCalendarType';
import { CDN_URL, WEEK_DAYS } from 'constants/commonData';
import { CalendarContainer, DaysContainer, CalendarRows, Wrapper, Navbar, FeatureTurnOff } from './style';
import UpgradePath from 'shared/UpgradePath';
import UnlimitedSchedulingFailBack from 'shared/UpgradePath/components/UnlimitedSchedulingFailBack';
import ButtonPasteWeek from './components/ButtonPasteWeek';
import CalendarActions from './components/CalendarActions';
import AlterWeekSelected from './components/AlterWeekSelected';
import AlterWorkoutSelected from './components/AlterWorkoutSelected';

const DATE_FORMAT = 'MM-DD-YYYY';

class CalendarLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { onDidMount, match, viewDetailStatus } = this.props;
    typeof onDidMount === 'function' && !viewDetailStatus && onDidMount(match);
    document.addEventListener('keydown', this.onPressEscapeKey);
  }

  componentWillUnmount() {
    const { onWillUnmount, match, viewDetailStatus } = this.props;
    typeof onWillUnmount === 'function' && !viewDetailStatus && onWillUnmount(match);
    document.removeEventListener('keydown', this.onPressEscapeKey);
  }

  onPressEscapeKey = event => {
    const { copying, resetCopyItem } = this.props;

    if (
      event.keyCode === 27 &&
      !document.querySelector('.ui.modals') &&
      copying &&
      typeof resetCopyItem === 'function'
    ) {
      resetCopyItem();
    }
    if (event.keyCode === 27 && !document.querySelector('.ui.modal') && !isEmpty(this.props.selectedWorkouts)) {
      this.props.handleResetSelectedMultipleWorkout && this.props.handleResetSelectedMultipleWorkout();
    }
    if (event.keyCode === 27 && !document.querySelector('.ui.modal') && !isEmpty(this.props.selectedWeek)) {
      this.props.handleResetSelectedWeek && this.props.handleResetSelectedWeek();
    }
  };

  renderCalendar() {
    const { viewMode, startDate, cell, isLoadingWorkouts } = this.props;
    const { isDragging } = this.state;
    const rows = Array(viewMode);
    const Cell = cell;

    return (
      <CalendarContainer className="calendar__container">
        <DaysContainer className="calendar__days">
          {map(WEEK_DAYS, day => (
            <div key={day}>{day}</div>
          ))}
        </DaysContainer>
        <CalendarRows viewMode={viewMode} className="calendar__rows" isCopingWeek={!isEmpty(this.props.selectedWeek)}>
          {map(rows, (item, weekIndex) => {
            return (
              <div className="calendar__row" key={weekIndex}>
                <CalendarActions {...this.props} weekIndex={weekIndex} />
                {!isLoadingWorkouts && <ButtonPasteWeek {...this.props} weekIndex={weekIndex} />}
                <div className="calendar__cells">
                  {Cell &&
                    map(WEEK_DAYS, (day, dayIndex) => {
                      const add = weekIndex * 7 + dayIndex;
                      const date = moment(startDate, DATE_FORMAT).add(add, 'day');
                      return <Cell key={dayIndex} day={date} isDragging={isDragging} weekIndex={weekIndex} />;
                    })}
                </div>
              </div>
            );
          })}
        </CalendarRows>
        <UpgradePath
          pathName="unlimitedScheduling"
          fallback={<UnlimitedSchedulingFailBack noWeeks={viewMode} startDate={startDate} />}
        />
      </CalendarContainer>
    );
  }

  renderNavigationBar() {
    const { startDate, viewMode, changeStartDate, changeViewMode, featureTurnOff, name, components } = this.props;
    const NavBarCenter = components ? components.NavBarCenter : null;

    return (
      <Navbar className="calendar-navbar">
        <div className="calendar-navbar__left">
          <button
            className="button--today"
            onClick={() => {
              const startOfCurrentWeek = moment().startOf('isoWeek').format(DATE_FORMAT);

              if (startDate !== startOfCurrentWeek) {
                changeStartDate(startOfCurrentWeek);
              }
            }}
          >
            Today
          </button>
          <DateRangePicker
            startDate={moment(startDate, DATE_FORMAT)}
            weeks={viewMode}
            onChange={(newStartDate, newEndDate) => {
              changeStartDate(newStartDate.format(DATE_FORMAT));
            }}
          />
        </div>
        <div className="calendar-navbar__center">
          <div />
          {featureTurnOff && (
            <FeatureTurnOff>
              <Image src={`${CDN_URL}/images/feature_turned_off.svg`} />
              <span>{name} has been turned off</span>
            </FeatureTurnOff>
          )}
          {NavBarCenter ? <NavBarCenter /> : <div />}
        </div>
        <SelectCalendarType
          selected={viewMode}
          onSelect={value => changeViewMode(parseInt(value))}
          uiOptions={{ newUI: true }}
        />
      </Navbar>
    );
  }

  onBeforeCapture = event => {
    const [type] = event.draggableId.split('_');
    if (type === 'workout') {
      this.setState({ isDragging: true });
    }
  };

  handleDragStart = result => {
    if (get(result, 'type') === 'DEFAULT') {
      this.setState({
        isDragging: true,
      });
    }
  };

  render() {
    return (
      <DragDropContext
        onDragEnd={r => {
          this.setState({ isDragging: false });
          this.props.onDragEnd(r);
        }}
        onBeforeCapture={this.onBeforeCapture}
        onDragStart={this.handleDragStart}
      >
        <Wrapper className="calendar__wrapper">
          {this.renderNavigationBar()}
          {this.renderCalendar()}
          <AlterWeekSelected {...this.props} />
          <AlterWorkoutSelected {...this.props} />
          {this.props.children}
        </Wrapper>
      </DragDropContext>
    );
  }
}

export default CalendarLayout;
