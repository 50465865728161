import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingNoteSkeleton = () => {
  return (
    <ContentLoader
      key={`row-skeleton-note`}
      viewBox="0 0 auto 193"
      width="100%"
      height={58}
      animate={false}
      backgroundColor="#F8F8F8"
      foregroundColor="#F8F8F8"
    >
      <rect x="0" y="15" rx="10" ry="10" width="100%" height="43" />
    </ContentLoader>
  );
};

export default LoadingNoteSkeleton;
