import { Workout } from 'types/model';

export const MOVE_WORKOUT = 'MOVE_WORKOUT';
export const MOVE_WORKOUT_SUCCESS = 'MOVE_WORKOUT_SUCCESS';
export const ADD_WORKOUT = 'ADD_WORKOUT';
export const UPDATE_WORKOUTS = 'UPDATE_WORKOUTS';
export const MOVE_EXERCICE = 'MOVE_EXERCICE';

export const moveWorkout = params => {
  return {
    type: MOVE_WORKOUT,
    payload: params,
  };
};

export const moveWorkoutSuccess = params => {
  return {
    type: MOVE_WORKOUT_SUCCESS,
  };
};

export const addWorkout = (to, workout) => {
  return {
    type: ADD_WORKOUT,
    to,
    workout,
  };
};

export const updateWorkouts = workouts => {
  return {
    type: UPDATE_WORKOUTS,
    workouts: workouts.map(o => new Workout().parseFromAssignment(o)),
  };
};

export const moveExercice = data => {
  return {
    type: MOVE_EXERCICE,
    payload: data,
  };
};
