// Libs
import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import get from 'lodash/get';

// Actions
import { toggleModal } from 'actions/modal';
import { hideError, showError } from 'actions/error';
import { copyToProgramLibrary } from 'redux/studio-program/actions';
import { axiosInstance } from 'configs/request';

// Constants
import { TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER, CDN_URL } from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';

// Parts
import OwnerDropdown from './OwnerDropdown';
import ShareDropdown from './ShareDropdown';

// Shared
import FormItem from 'components/StudioProgram/List/CreateStudioProgramModal/CreateNewStudioProgram/FormItem';
import { Button } from 'shared/FormControl';

// Styles
import * as S from './style';

class CopyToProgramLibraryModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      program: {
        share: TEAM_SHARE_PRIVATE,
        author: get(props, 'user._id') || props.studioProgram.author,
        title: props.studioProgram.program_name,
      },
    };
  }

  handleChangeField = (field, e) => {
    let { program } = this.state;
    program[field] = e.target.value;
    this.setState({ program: { ...program } });
  };

  handleChangeOwner = (event, data) => {
    let { program } = this.state;
    if (!program.author) {
      program.author = {};
    }
    program.author = data.value;
    if (data.value === 'no_owner') {
      program.author = null;
      program.share = TEAM_SHARE_NOOWNER;
    } else if (program.share === TEAM_SHARE_NOOWNER) {
      program.share = TEAM_SHARE_PRIVATE;
    }
    this.setState({ program: { ...program } });
  };

  handleChangeShare = (event, data) => {
    let { program } = this.state;
    program.share = data.value;
    this.setState({ program: { ...program } });
  };

  validateForm = () => {
    const { program } = this.state;

    if (!program.title) {
      return false;
    }

    return true;
  };

  handleClose = () => {
    this.props.toggleModal(false);
    this.setState({
      number_of_weeks: 1,
      program_name: '',
      description: '',
      isSubmited: false,
    });
  };

  onSubmit = () => {
    const { showError, hideError, toggleModal } = this.props;
    this.setState({ isSubmited: true });
    if (this.validateForm()) {
      const { program } = this.state;
      const { studioProgram } = this.props;
      const params = {
        programId: studioProgram._id,
        ...program,
      };
      this.setState({ loading: true });

      axiosInstance
        .post('/api/studio-program/copy-to-library', params)
        .then(response => {
          this.handleClose();
          const { data } = response.data;
          toast('Program has been copied to your library.');
          this.props.push(`/home/program/${data._id}/calendar`);
        })
        .catch(error => {
          const responseData = get(error, 'response.data', {});
          const message = 'You are not authorized to edit this studio program.';

          if (responseData.error === 'E_FORBIDDEN' || responseData.error === 'E_BAD_REQUEST') {
            showError(message, null, null, null, null, () => {
              hideError();
              toggleModal(false);
              window.location.reload();
            });
            return;
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { loading, program } = this.state;
    const { user = {} } = this.props;
    const isDisabledSubmit = loading;

    return (
      <S.CreateNewStudioProgramModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="close button" />
          </CloseButton>
        }
      >
        <Modal.Header>Copy to Program Library</Modal.Header>
        <Modal.Content>
          <form className="addStudioProgram__form">
            <FormItem
              label="Program Name"
              placeholder="Add Program Name"
              required
              inputProps={{ autoFocus: true }}
              isSubmited={this.state.isSubmited}
              value={this.state.program.title}
              onChange={e => this.handleChangeField('title', e)}
            />
            <OwnerDropdown
              owner={this.state.program.author}
              handleChangeOwner={this.handleChangeOwner}
              isEditAble={
                get(program, 'share') === TEAM_SHARE_NOOWNER ||
                (get(program, 'author') || get(program, 'author._id')) === get(user, '_id')
              }
              isTeamAdmin={isTeamAdmin(user)}
            />
            <ShareDropdown share={this.state.program.share} handleChangeShare={this.handleChangeShare} />
          </form>
          <div className="addStudioProgram__footer">
            <Button disabled={isDisabledSubmit} onClick={this.onSubmit} purple>
              Create
            </Button>
          </div>
        </Modal.Content>
      </S.CreateNewStudioProgramModal>
    );
  }
}

const mapState = state => {
  const { user } = state;

  return {
    user,
  };
};

const actionCreators = { toggleModal, push, copyToProgramLibrary, showError, hideError };

export default connect(mapState, actionCreators)(CopyToProgramLibraryModal);
