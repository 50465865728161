/**
 * @flow
 */

import type { Element } from 'react';
export const TOGGLE_MODAL: TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_SECOND_MODAL: TOGGLE_SECOND_MODAL = 'TOGGLE_SECOND_MODAL';

export const toggleModal = (isOpen: boolean, modal: ?Element<any> = null) => {
  return {
    type: TOGGLE_MODAL,
    isOpen,
    modal,
  };
};

export const toggleSecondModal = (isOpen: boolean, modal: ?Element<any> = null) => {
  return {
    type: TOGGLE_SECOND_MODAL,
    isOpen,
    modal,
  };
};

export const toggleConfirmModal = (
  isOpen: boolean,
  modal: ?Element<any> = null
) => {
  return {
    type: 'TOGGLE_CONFIRM_MODAL',
    isOpen,
    modal,
  };
};
