import Request from 'configs/request';
import get from 'lodash/get';

export const Types = {
  GET_WORKOUT_SUMMARY_REQUEST: 'GET_WORKOUT_SUMMARY_REQUEST',
  GET_WORKOUT_SUMMARY_SUCCESS: 'GET_WORKOUT_SUMMARY_SUCCESS',
  GET_WORKOUT_SUMMARY_FAILED: 'GET_WORKOUT_SUMMARY_FAILED',
  GET_ALL_MISSED_WORKOUT_SUMMARY_SUCCESS: 'GET_ALL_MISSED_WORKOUT_SUMMARY_SUCCESS',
  RESET_WORKOUT_SUMMARY_STATE: 'RESET_WORKOUT_SUMMARY_STATE',
};

export const getWorkoutSummaries = (params, isFromSocket = false) => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { workoutCompletionSummaries } = rootReducer;
    const { currentParams = null } = workoutCompletionSummaries;

    dispatch({ type: Types.GET_WORKOUT_SUMMARY_REQUEST, payload: { newParams: params } });

    const socketInvalidDate = isFromSocket && get(params, 'day') !== get(currentParams, 'day', null);
    const newParams = socketInvalidDate ? currentParams : { ...currentParams, ...params };

    return dispatch(
      Request.get(
        {
          url: `/api/workout-summaries`,
          params: !currentParams ? params : newParams,
        },
        false,
        response => {
          const { data, total_missed, total_completed, is_processing_data = false } = response.data;
          dispatch({
            type: Types.GET_WORKOUT_SUMMARY_SUCCESS,
            payload: {
              data,
              total_missed,
              total_completed,
              is_processing_data,
            },
          });
        },
        () => {
          dispatch({ type: Types.GET_WORKOUT_SUMMARY_FAILED });
        },
      ),
    );
  };
};

export const getAllMissedWorkoutSummaries = (params, callback) => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();

    const { workoutCompletionSummaries } = rootReducer;
    const totalMissed = get(workoutCompletionSummaries, 'totalMissed', 0);

    if (totalMissed === 0) {
      return;
    }

    const initParams = {
      page: 1,
      per_page: totalMissed,
      category: 'missed',
      owner_ship: 1,
    };

    return dispatch(
      Request.get(
        {
          url: `/api/workout-summaries`,
          params: { ...initParams, ...params },
        },
        false,
        response => {
          const { data = [], total_missed, total_completed, is_processing_data = false } = response.data;
          dispatch({
            type: Types.GET_ALL_MISSED_WORKOUT_SUMMARY_SUCCESS,
            payload: { data, total_missed, total_completed, is_processing_data },
          });

          !is_processing_data && callback && callback(data);
        },
      ),
    );
  };
};

export const resetWorkoutSummaryState = () => {
  return { type: Types.RESET_WORKOUT_SUMMARY_STATE };
};
