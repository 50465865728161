import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import round from 'lodash/round';
import Avatar from 'react-avatar';
import capitalize from 'lodash/capitalize';
import React, { useEffect, useMemo } from 'react';
import NumberFormat from 'react-number-format';

import Purchase from 'assets/icons/purchase_activity.svg';
import Paid from 'assets/icons/paid_activity.svg';
import NewPayment from 'assets/icons/add_activity.svg';
import Denied from 'assets/icons/denied_activity.svg';
import Scheduled from 'assets/icons/scheduled_activity.svg';
import Undone from 'assets/icons/upcoming_cancel_activity.svg';
import Refund from 'assets/icons/refund_activity.svg';
import Paused from 'assets/icons/paused_activity.svg';
import Resume from 'assets/icons/resume_activity.svg';
import Trial from 'assets/icons/payment_trial.svg';
import NoResponse from 'assets/icons/no_response.svg';
import {
  ACTIVITY_TYPES,
  CURRENCIES,
  PACKAGE_PRICING_TYPES,
  PACKAGE_WISHLIST_TYPES,
  TEXT_CURRENCIES,
} from 'constants/commonData';
import { formatDateSafari, formatMoney, getTrialLeftDays, getUserShortName, pluralize } from 'utils/commonFunction';

import * as S from './style';

const CURRENCY_OPTIONS = map(CURRENCIES, obj => obj);
const queryCurrency = origin => {
  const obj = CURRENCY_OPTIONS.find(item => item.value === origin) || CURRENCY_OPTIONS[0];
  return { ...obj };
};

const DATE_FORMAT = 'MMMM D, YYYY';

const Item = ({ user, item, account, isMP }) => {
  useEffect(() => {
    moment.locale(moment.locale(), {
      relativeTime: {
        future: 'in%s',
        past: '%sago',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '4 weeks',
        y: '1y',
        yy: '%dy',
      },
    });
  }, []);

  if (!item) {
    return null;
  }

  const handleOpenDetail = () => {
    const packageId = get(item, 'package.hash_id') || get(item, 'package.id');
    const path = isMP ? 'marketplace' : 'packages';
    const package_wishlist_type = get(item, 'additionalInfo.package_wishlist_type', '');
    if (isMP && [PACKAGE_WISHLIST_TYPES.WAITLIST, PACKAGE_WISHLIST_TYPES.CONTACT].includes(package_wishlist_type)) {
      const tab = package_wishlist_type === PACKAGE_WISHLIST_TYPES.WAITLIST ? 'waitlist' : 'intro-call';
      return (window.location = `/home/marketplace/${packageId}/${tab}?email=${get(item, 'client_info.email', '')}`);
    }
    if (get(item, 'purchase_id')) {
      window.location = `/home/${path}/${packageId}/analytics?preview=${item.purchase_id}`;
    }
  };

  const icon = useMemo(() => {
    switch (item.action) {
      case ACTIVITY_TYPES.WAITLIST_PURCHASE_PACKAGE:
      case ACTIVITY_TYPES.INTRO_CALL_PURCHASE_PACKAGE:
      case ACTIVITY_TYPES.MADE_PURCHASE:
      case ACTIVITY_TYPES.SUBSCRIPTION_AFTER_TRIAL: {
        return Purchase;
      }
      case ACTIVITY_TYPES.PAID_AND_CLAIM_PURCHASE:
      case ACTIVITY_TYPES.PAID_RECURRING: {
        return Paid;
      }
      case ACTIVITY_TYPES.ADDED_PAYMENT_METHOD: {
        return NewPayment;
      }
      case ACTIVITY_TYPES.WAITLIST_DECLINE_REQUEST:
      case ACTIVITY_TYPES.INTRO_CALL_DECLINE_REQUEST:
      case ACTIVITY_TYPES.PAYMENT_DENIED:
      case ACTIVITY_TYPES.TRIAL_PAYMENT_DENIED: {
        return Denied;
      }
      case ACTIVITY_TYPES.WAITLIST_CANCELLED_REQUEST:
      case ACTIVITY_TYPES.INTRO_CALL_CANCELLED_REQUEST:
      case ACTIVITY_TYPES.CANCELLATION_SCHEDULED:
      case ACTIVITY_TYPES.SUBSCRIPTION_CANCELLED:
      case ACTIVITY_TYPES.PURCHASE_ENDED:
      case ACTIVITY_TYPES.TRIAL_CANCELLED: {
        return Scheduled;
      }
      case ACTIVITY_TYPES.CANCELLATION_UNDONE: {
        return Undone;
      }
      case ACTIVITY_TYPES.REFUND_INVOICE: {
        return Refund;
      }
      case ACTIVITY_TYPES.PAUSED_PURCHASE: {
        return Paused;
      }
      case ACTIVITY_TYPES.RESUMED_PURCHASE: {
        return Resume;
      }
      case ACTIVITY_TYPES.TRIAL_START: {
        return Trial;
      }
      case ACTIVITY_TYPES.WAITLIST_NO_RESPONSE:
      case ACTIVITY_TYPES.INTRO_CALL_NO_RESPONSE: {
        return NoResponse;
      }

      default: {
        return item.client_info.avatar || get(item, 'additionalInfo.client_avatar.s', '');
      }
    }
  }, [item]);

  const message = useMemo(() => {
    // const symbol = queryCurrency(get(item, 'package.price.currency')).symbol;
    const isOneTime = get(item, 'package.price.type') === PACKAGE_PRICING_TYPES.one_time.value;
    const isRecurring = get(item, 'package.price.type') === PACKAGE_PRICING_TYPES.recurring.value;
    const packageName = get(item, 'package.name', '');
    const packageAmount = get(item, 'package.price.amount');
    const clientFullname = get(item, 'client_info.full_name', '');
    const trialPeriod = get(item, 'package.price.trial_period', 0);
    const trialLeftDays = getTrialLeftDays(get(item, 'additionalInfo.ended_trial_at', ''));
    const coachFullname = get(item, 'trainer_info.full_name', '');
    const isClientCancel = get(item, 'additionalInfo.is_self_cancellation', false);
    const recurringType = get(item, 'package.price.recurring_interval_type', '');
    const recurringEvery = get(item, 'package.price.every', 0);
    const currency = get(account, 'currency', 'usd');
    const symbol = TEXT_CURRENCIES.includes(currency)
      ? `${get(CURRENCIES, `${currency}.symbol`)} `
      : get(CURRENCIES, `${currency}.symbol`);

    if (item) {
      switch (item.action) {
        case ACTIVITY_TYPES.MADE_PURCHASE: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> made a {formatMoney(packageAmount, symbol)} purchase for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.PAID_AND_CLAIM_PURCHASE: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> made a {formatMoney(packageAmount, symbol)} purchase for{' '}
              <S.TextBold>{packageName}</S.TextBold> and that purchase is now activated
            </>
          );
        }
        case ACTIVITY_TYPES.PAID_RECURRING: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> paid {formatMoney(packageAmount, symbol)} for a recurring
              payment of <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.ADDED_PAYMENT_METHOD: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> added a new payment method
            </>
          );
        }
        case ACTIVITY_TYPES.PAYMENT_DENIED: {
          return (
            <>
              <S.TextBold>{clientFullname}'s</S.TextBold> payment of {formatMoney(packageAmount, symbol)} was{' '}
              <S.TextBold>declined</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.CANCELLATION_SCHEDULED: {
          const splitDate = user.date_format && user.date_format.split('/');
          const dateFormat = splitDate
            ? `${splitDate[0].slice(1)}/${splitDate[1].slice(1)}/${splitDate[2]}`
            : 'M/D/YYYY';
          return (
            <>
              <S.TextBold>{clientFullname}'s</S.TextBold> subscription for <S.TextBold>{packageName}</S.TextBold> has
              been scheduled for cancellation on {moment(get(item, 'additionalInfo.canceled_at')).format(dateFormat)}
            </>
          );
        }
        case ACTIVITY_TYPES.CANCELLATION_UNDONE: {
          return (
            <>
              <S.TextBold>{clientFullname}'s</S.TextBold> upcoming cancellation for{' '}
              <S.TextBold>{packageName}</S.TextBold> has been undone
            </>
          );
        }
        case ACTIVITY_TYPES.SUBSCRIPTION_CANCELLED: {
          return (
            <>
              <S.TextBold>{clientFullname}'s</S.TextBold> {isOneTime ? 'purchase of ' : 'subscription for '}
              <S.TextBold>{packageName}</S.TextBold> has been {isOneTime ? 'marked as' : ''} cancelled
            </>
          );
        }
        case ACTIVITY_TYPES.PURCHASE_ENDED: {
          return (
            <>
              <S.TextBold>{clientFullname}'s</S.TextBold> subscription of <S.TextBold>{packageName}</S.TextBold> has
              ended
            </>
          );
        }
        case ACTIVITY_TYPES.CLAIMED_PURCHASE: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> activated a purchase for <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.REFUND_INVOICE:
          return (
            <S.RefundContent>
              <div>
                <S.TextBold>{coachFullname}</S.TextBold> made a{' '}
                <S.TextBold>
                  {formatMoney(round(get(item, 'additionalInfo.refund_amount', 0) / 100, 2), symbol)}
                </S.TextBold>{' '}
                refund for <S.TextBold>{clientFullname}'s</S.TextBold> purchase of{' '}
                <S.TextBold>{packageName}</S.TextBold>
              </div>
              Reason: {capitalize(get(item, 'additionalInfo.reason', 'other').replaceAll('_', ' '))}
            </S.RefundContent>
          );
        case ACTIVITY_TYPES.PAUSED_PURCHASE: {
          const activeDate = moment(formatDateSafari(get(item, 'additionalInfo.resume_day', '')));
          const isSame = activeDate.isSame(moment());
          return (
            <S.RefundContent>
              <S.TextBold>{clientFullname}</S.TextBold>'s subscription for <S.TextBold>{packageName}</S.TextBold> has
              been paused by <S.TextBold>{coachFullname} </S.TextBold>{' '}
              {get(item, 'additionalInfo.resume_day', '') && (
                <>until {isSame ? 'Today' : activeDate.format(DATE_FORMAT)}</>
              )}
            </S.RefundContent>
          );
        }
        case ACTIVITY_TYPES.RESUMED_PURCHASE: {
          const activeDate = moment(formatDateSafari(get(item, 'additionalInfo.resume_day', '')));
          const isSame = activeDate.isSame(moment(), 'day');

          return (
            <S.RefundContent>
              <S.TextBold>{clientFullname}</S.TextBold>'s subscription for <S.TextBold>{packageName}</S.TextBold> has
              been set by <S.TextBold>{coachFullname} </S.TextBold> to resume{' '}
              {isSame ? 'Today' : moment(formatDateSafari(get(item, 'additionalInfo.resume_day'))).format(DATE_FORMAT)}
            </S.RefundContent>
          );
        }
        case ACTIVITY_TYPES.TRIAL_START: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> just started a {trialPeriod}-day trial for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.TRIAL_CANCELLED: {
          return (
            <>
              <S.TextBold>{isClientCancel ? clientFullname : coachFullname}</S.TextBold> just cancelled a {trialPeriod}
              -day trial for <S.TextBold>{packageName}</S.TextBold> ({pluralize('day', trialLeftDays, true)} left)
            </>
          );
        }
        case ACTIVITY_TYPES.SUBSCRIPTION_AFTER_TRIAL: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> just{' '}
              {isRecurring ? 'started a subscription' : <>made a {formatMoney(packageAmount, symbol)} payment</>} of{' '}
              <S.TextBold>{packageName}</S.TextBold> after a {trialPeriod}-day trial
              {isRecurring && (
                <>
                  . Payment {recurringEvery === 1 ? '' : 'of'} {formatMoney(packageAmount, symbol)}
                  {recurringEvery === 1
                    ? `/${pluralize(recurringType, recurringEvery)}`
                    : ` every ${pluralize(recurringType, recurringEvery, true)}`}
                </>
              )}
            </>
          );
        }
        case ACTIVITY_TYPES.TRIAL_PAYMENT_DENIED: {
          return (
            <>
              <S.TextBold>{clientFullname}'s</S.TextBold> payment of {formatMoney(packageAmount, symbol)} for{' '}
              <S.TextBold>{packageName}</S.TextBold> failed after a {trialPeriod}-day trial
            </>
          );
        }
        case ACTIVITY_TYPES.WAITLIST_NEW_REQUEST: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> has joined the Waitlist for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.INTRO_CALL_NEW_REQUEST: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> has requested an Intro Call for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.WAITLIST_PURCHASE_PACKAGE: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> accepted your offer for <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.INTRO_CALL_PURCHASE_PACKAGE: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> just accepted your offer for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.WAITLIST_DECLINE_REQUEST: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> declined your offer for <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.INTRO_CALL_DECLINE_REQUEST: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> declined your offer for <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.WAITLIST_CANCELLED_REQUEST: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> removed themselves from the{' '}
              <S.TextBold>{packageName}</S.TextBold> Waitlist
            </>
          );
        }
        case ACTIVITY_TYPES.INTRO_CALL_CANCELLED_REQUEST: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> just cancelled their Intro Call request for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.WAITLIST_NO_RESPONSE: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> did not respond to your offer for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        case ACTIVITY_TYPES.INTRO_CALL_NO_RESPONSE: {
          return (
            <>
              <S.TextBold>{clientFullname}</S.TextBold> did not respond to your offer for{' '}
              <S.TextBold>{packageName}</S.TextBold>
            </>
          );
        }
        default: {
          return null;
        }
      }
    }

    return '';
  }, [item]);

  return (
    <S.Wrapper onClick={handleOpenDetail}>
      <S.ContentWrapper>
        <S.Status>
          {item.client_info && (
            <Avatar
              color={get(item, 'client_info.color')}
              src={icon}
              size="30"
              name={getUserShortName(item.client_info)}
              alt={item.action === ACTIVITY_TYPES.REFUND_INVOICE ? 'refund_invoice' : ''}
            />
          )}
        </S.Status>
        <S.ActivityContent>{message}</S.ActivityContent>
      </S.ContentWrapper>
      <S.TimeLine>{moment(item.created_at).fromNow(true)}</S.TimeLine>
    </S.Wrapper>
  );
};

export default Item;
