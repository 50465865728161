import _ from 'lodash';

export const countSelected = clients => {
  let allClientGroups = [];
  _.forEach(clients, item => {
    allClientGroups = allClientGroups.concat(item.groups || [])
  })

  const count = _.countBy(allClientGroups, typeof allClientGroups[0] === 'string' ? null : '_id');
  let result = {};
  _.forEach(count, (total, groupId) => {
    result[groupId] = { total, state: total === clients.length ? 2 : 1 }
    result[groupId].originState = result[groupId].state;
  })

  //  item state:
  // 0: unchecked
  // 1: half-checked
  // 2: full-checked
  
  return result;
}

export const getGroupsFromClients = (clients, returnObject = false) => {
  let allClientGroups = [];
  _.forEach(clients, item => {
    allClientGroups = allClientGroups.concat(item.groups || [])
  })

  const uniqGroup = _.uniqBy(allClientGroups, typeof allClientGroups[0] === 'string' ? null : '_id');

  if (returnObject) {
    return uniqGroup;
  }

  return _.map(uniqGroup, item => item._id);
}

export const getSelectedGroups = clients => {
  let selectedGroups = [];
  let allClientGroups = [];
  _.forEach(clients, item => {
    allClientGroups = allClientGroups.concat(item.groups || [])
  })

  const countGroups = _.countBy(allClientGroups, typeof allClientGroups[0] === 'string' ? null : '_id');

  _.forEach(countGroups, (total, groupId) => {
    if (total === clients.length) {
      selectedGroups.push(groupId)
    }
  })

  return selectedGroups;
}