import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const CancelViewMore = styled.div`
  width: 16px;
  height: 16px;
  display: block;
  background: url(${CDN_URL}/images/close_circle.svg) no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  z-index: 100;
  right: -6px;
  cursor: pointer;
  top: -6px;
`;

export const ActionItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 5px 0 5px 15px;

  img {
    width: 14px;
    margin-right: 12px !important;
  }

  :hover {
    background-color: #696969;
  }
`;

export const ActionsContainer = styled.div`
  width: 110px;
  padding: 15px 0;
`;

export const DateContainer = styled.div`
  font-size: 11px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  color: #728096;
  text-align: center;
  font-weight: 600;

  ${props =>
    props.today &&
    css`
      background-color: #726ee4;
      color: #fff;
    `}
`;

export const AddNew = styled.div`
  visibility: hidden;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: block;
  background: url(${CDN_URL}/images/plus_circle_white.svg) no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  padding: 0 10px;
`;

export const Content = styled.div`
  border-radius: 6px;
  display: block;
  flex: 1;
  flex-direction: column;
  position: relative;
  /* overflow-y: hidden; */
  padding: 0 5px;

  ${props =>
    props.noItem &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

export const PasteButton = styled.div`
  height: 22px;
  line-height: 19.5px;
  border-radius: 5px;
  background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  display: none;
  user-select: none;
  align-items: center;
  justify-content: center;
`;

export const MoreItems = styled.div`
  display: block;
  font-size: 11px;
  font-weight: 600;
  color: #5a636c;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  line-height: 16px;
`;

export const ContentFooter = styled.div`
  padding: 0;
  width: 100%;
  min-height: 22px;
`;

export const Container = styled.div`
  border-radius: 6px;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  background-color: rgba(243, 245, 248, 1);
  position: relative;
  flex-basis: 0;
  margin: 0;
  height: 100%;
  border: 1px solid transparent;

  :hover {
    ${AddNew} {
      visibility: visible;
    }
  }

  ${props =>
    props.showAll &&
    css`
      border-color: rgb(114, 110, 228);

      ${Content} {
        overflow: auto;
      }

      ${Header} {
        border-bottom: 1px solid #dfe6ee;
      }

      ${DateContainer} {
        color: #2d2e2d;
        width: auto;
      }
    `}

  ${props =>
    props.copying &&
    css`
      :hover {
        .can-paste {
          ${PasteButton} {
            display: flex;
          }

          ${MoreItems} {
            display: none;
          }
        }
      }
    `}
`;
