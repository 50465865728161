import { PER_PAGE } from 'components/MetricGroupLibrary/constants';
import { Types } from './action';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

export const INITIAL_STATE_METRIC_GROUP = {
  loadingMetric: false,
  loading: false,
  metrics: [],
  groups: [],
  total: 0,
  filters: {
    per_page: PER_PAGE,
    page: 1,
    q: '',
  },
  isAdding: false,
};

export default (state = INITIAL_STATE_METRIC_GROUP, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_TRAINER_METRICS_REQUEST: {
      return Object.assign({}, state, {
        loadingMetric: true,
      });
    }

    case Types.GET_TRAINER_METRICS_SUCCESS: {
      return Object.assign({}, state, {
        metrics: payload,
        loadingMetric: false,
      });
    }

    case Types.GET_TRAINER_METRICS_FAILED: {
      return Object.assign({}, state, {
        loadingMetric: false,
      });
    }

    case Types.GET_METRIC_GROUP_LIBRARY_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case Types.GET_METRIC_GROUP_LIBRARY_SUCCESS: {
      const { data, params, total } = payload;
      const updatedGroups = params.page === 1 ? data : [...state.groups, ...data];
      const uniqueGroups = uniqBy(updatedGroups, '_id');
      return Object.assign({}, state, {
        groups: uniqueGroups,
        loading: false,
        total: total,
        filters: {
          ...state.filters,
          ...params,
        },
      });
    }

    case Types.GET_METRIC_GROUP_LIBRARY_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case Types.ADD_METRIC_GROUP_LIBRARY: {
      const { data, isPrivateUpdate } = payload;
      const {
        filters: { q = '' },
      } = state;
      const currentGroups = state.groups.filter(item => item._id !== (data || {})._id);
      const isMatchFilter = get(data, 'title', '').toLowerCase().includes(q.toLowerCase());
      !isPrivateUpdate && isMatchFilter && currentGroups.unshift(data);

      return Object.assign({}, state, {
        groups: currentGroups,
        loading: false,
        isAdding: false,
      });
    }

    case Types.ADD_METRIC_GROUP_LIBRARY_FAILED: {
      return Object.assign({}, state, {
        isAdding: false,
      });
    }

    case Types.ADD_METRIC_GROUP_LIBRARY_REQUEST: {
      return Object.assign({}, state, {
        isAdding: true,
      });
    }

    case Types.UPDATE_METRIC_GROUP_LIBRARY: {
      const { data, isPrivateUpdate } = payload;
      const {
        filters: { q = '' },
      } = state;
      const currentGroups = state.groups.filter(item => item._id !== (data || {})._id);
      const isMatchFilter = get(data, 'title', '').toLowerCase().includes(q.toLowerCase());
      !isPrivateUpdate && isMatchFilter && currentGroups.unshift(data);

      return Object.assign({}, state, {
        groups: currentGroups,
        loading: false,
        isAdding: false,
      });
    }

    case Types.REMOVE_METRIC_GROUP: {
      return Object.assign({}, state, {
        groups: state.groups.filter(item => item._id !== payload._id),
      });
    }

    case Types.CHANGE_QUERY_PARAMS_METRIC_GROUP_LIBRARY: {
      return Object.assign({}, state, {
        loading: false,
        filters: payload,
      });
    }

    case Types.RESET_SEARCH_METRIC_GROUP_LIBRARY: {
      return Object.assign({}, state, {
        groups: [],
      });
    }

    default:
      return state;
  }
};
