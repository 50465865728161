import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .card-checkbox {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    margin-bottom: 15px;
    padding-left: 28px;

    &:last-child {
      margin-bottom: unset;
    }
  }

  .card-checkbox-readonly {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: unset;
    }
  }

  .checkbox-icon {
    background-size: 10px;
    border-color: #e3e3e3;
    border-radius: 4px;
  }
`;

export const NotArchive = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
`;

export const TrialReminderDisable = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`;

export const CheckedDefault = styled.div`
  display: flex;
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 15px;
  b {
    font-weight: 600;
  }
`;

export const CheckedIcon = styled.div`
  display: flex;
  padding-top: 5px;
  padding-left: 3px;
`;

export const LabelDefaultCheckbox = styled.div`
  display: flex;
  padding-left: 13px;
`;
