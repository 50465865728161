import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const CustomModal = styled(Modal)`
  padding: unset !important;

  &.ui.modal.evf-media-slider-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
  }

  &.ui.modal.evf-media-slider-modal > .content {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }

  &.ui.modal.evf-media-slider-modal .close-button {
    position: fixed;
    top: 20px;
    right: 18px;
  }

  &.ui.modal.evf-media-slider-modal .close-button svg {
    width: 20px;
    height: 20px;
  }
`;

export const SliderWrapper = styled.div`
  .custom-slider .slick-dots {
    position: fixed;
    bottom: 3px;

    li {
      width: 36px;
      height: 36px;
      margin: 0 2px;

      &:not(.slick-active)::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        pointer-events: none;
      }

      &:not(.slick-active):hover {
        &::before {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        }
      }

      .image-dots {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }
  }

  .slick-prev {
    left: 4%;
  }

  .slick-next {
    right: 4%;
  }

  .slider-next-arrow,
  .slider-prev-arrow {
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 12px;
    background: #ffffff;
    border: 1px solid #c6c7d7;
    border-radius: 99px;
    z-index: 99;
    opacity: 0.6;

    :before {
      content: none;
    }

    :hover {
      background: #ffffff;
      opacity: 1;
    }

    &.slick-disabled {
      display: none !important;
    }
  }
`;

export const WrapperAttachment = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 80vh !important;
  min-width: 70vw !important;

  video:focus-visible {
    outline: none;
  }

  video,
  img {
    max-height: 80vh;
    max-width: 70vw;
    display: block;
  }

  video {
    background-color: #000000;
  }
`;
