import styled from 'styled-components';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #202353;
`;

export const Streak = styled.div`
  background: #fafcfd;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 44px;
  height: 44px;
  background: ${props => props.bgColor || '#fff4e3'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  margin-left: 12px;
`;

export const Title = styled.div`
  ${baseText}
  margin-bottom: 4px;
  span {
    font-size: 24px;
  }
`;

export const Desc = styled.div`
  ${baseText}
  font-size: 11px;
  line-height: 150%;
  text-transform: uppercase;
  color: #8b8da5;
`;
