import React from 'react';
import PropTypes from 'prop-types';
import Basic from './Basic';

function Slider(props) {
  return <Basic {...props} />;
}

Slider.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  items: PropTypes.array.isRequired,
  startAt: PropTypes.number,
  viewingName: PropTypes.string,
  options: PropTypes.shape({
    style: PropTypes.object,
  }),
};

export default Slider;
