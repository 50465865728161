import styled, { css } from 'styled-components';
import { ReactComponent as CopyIcon } from 'assets/icons/duplicate.svg';

export const Wrapper = styled.div`
  background: #514e9f;
  border-radius: 4px;
  padding: 9px 14px 9px 9px;
  border: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #9593d3;

  ${props =>
    props.copied
      ? css`
          background: #fff;
          color: #5c58c0;
          cursor: pointer;
          justify-content: center;
          font-size: 12px;
        `
      : css`
          :hover {
            background: #6360b1;
            border-color: #8582cb;
            color: #fff;
            cursor: pointer;
            justify-content: center;

            ${Text}, ${Icon} {
              display: none;
            }

            ${TextHover} {
              display: block;
            }
          }
        `}
`;

export const Icon = styled(CopyIcon)`
  flex: 0 0 auto;
`;

export const TextHover = styled.div`
  display: none;
  text-align: center;
  flex: 1 1;
`;

export const Text = styled.div`
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;
`;
