import React, { useState, useRef, useMemo, useContext, useEffect } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import isNumber from 'lodash/isNumber';
import classNames from 'classnames';
import { Modal, Image, Button } from 'semantic-ui-react';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import diff from 'deep-diff';

import {
  checkInvalidNutrient,
  checkNutrientData,
  convertDataCSVFile,
  HEADER_CSV_FILE,
  isAmountPerValid,
  listIngredientValid,
  splitFirstSpace,
} from './helper';
import { getPresignedUploadUrl, mediaLog, pluralize } from 'utils/commonFunction';
import { RECIPE_BUILDER_UPLOAD_URL } from 'components/Recipes/constants';
import { WebSocketContext } from 'libs/socket';
import { SOCKET_EVENTS } from 'libs/socket/constants';
import { PER_PAGE } from 'components/IngredientLibrary/constants';
import { getMacroNutrients } from 'components/MealPlanDayDetail/constants';

import NutrientInfo from './Parts/NutrientInfo';
import LoadingIndicator from 'shared/LoadingIndicator';

import { ReactComponent as FileIcon } from 'assets/icons/Ingredients/file-icon.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/Ingredients/checkmark_circle.svg';

import * as S from './style';

const STEPS = [1, 2, 3];
const LIMIT_NUMBER_INGREDIENT = 500;

const ImportIngredients = ({
  toggleSecondModal,
  categoryIngredient = [],
  unitIngredient = {},
  toggleModal,
  addIngredientByCSV = () => {},
  getIngredients = () => {},
  getListOtherNutrient = () => {},
}) => {
  const { socket } = useContext(WebSocketContext);

  const inputFile = useRef(null);

  const [list, setList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const [csvUrl, setCsvUrl] = useState('');
  const [listOtherNutrients, setListOtherNutrients] = useState([]);
  const [listSuccess, setListSuccess] = useState({});
  const [listFail, setListFail] = useState({});

  useEffect(() => {
    const dataSuccess = list.filter(isValidIngredient) || [];
    const dataFail = list.filter(item => !isValidIngredient(item)) || [];

    setListSuccess({ data: dataSuccess, length: dataSuccess.length });
    setListFail({ data: dataFail, length: dataFail.length });
  }, [list]);

  useEffect(() => {
    getListOtherNutrient().then(res => {
      setListOtherNutrients(get(res, 'data.data', []));
    });
  }, []);

  const dataUnitIngredient = useMemo(() => {
    if (!isEmpty(unitIngredient)) {
      const imperialsUnit = get(unitIngredient, 'imperials', []).map(item => item.units);
      const metricsUnit = get(unitIngredient, 'metrics', []).map(item => item.units);
      const others = get(unitIngredient, 'others', []);
      const result = [...flatten(imperialsUnit), ...flatten(metricsUnit), ...others].map(item =>
        ((item || {}).name || '').toLowerCase(),
      );
      return result;
    }
    return [];
  }, [unitIngredient]);

  const dataCategoryIngredient = useMemo(() => {
    if (!isEmpty(categoryIngredient)) {
      return categoryIngredient.map(item => ((item || {}).name || '').toLowerCase());
    }
    return [];
  }, [categoryIngredient]);

  const isValidIngredient = item => {
    const { name = '', category = [], default_unit = '', amount_per = '', macro_nutrients = [], other_nutrients = [] } =
      item || {};

    const hasNutrientData = checkNutrientData([...macro_nutrients, ...other_nutrients]);
    const invalidNutrient = checkInvalidNutrient([...macro_nutrients, ...other_nutrients]);
    const amountPerValid =
      isAmountPerValid(amount_per, categoryIngredient, category) || (!hasNutrientData && !amount_per);

    const validIngredientName = name;
    const validCategory = category && dataCategoryIngredient.includes(category.toLowerCase());
    const validDefaultUnit = default_unit && dataUnitIngredient.includes(default_unit.toLowerCase());
    const validAmountPer = amountPerValid;

    return validIngredientName && validCategory && validDefaultUnit && validAmountPer && !invalidNutrient;
  };

  const onClose = () => {
    toggleSecondModal(false);
  };

  const submitIngredients = async () => {
    if (isSubmitting) {
      return false;
    }

    setIsSubmitting(true);
    setStep(3);

    const data = (get(listSuccess, 'data', []) || []).map(entry => {
      const servingInfo = (entry || {}).amount_per || '';
      const [value, unit] = splitFirstSpace(servingInfo);
      return {
        ...omit(entry, ['amount_per']),
        name: ((entry || '').name || '').slice(0, 50),
        macro_nutrients: get(entry, 'macro_nutrients', []).map(item => {
          if (isNumber((item || {}).value) && (item || {}).value >= 0) {
            return item;
          }
          return omit(item, ['value']);
        }),
        other_nutrients: get(entry, 'other_nutrients', []).filter(
          item => isNumber((item || {}).value) && (item || {}).value >= 0,
        ),
        serving_size: Number(value)
          ? {
              unit,
              value: Number(value),
            }
          : undefined,
      };
    });

    await addIngredientByCSV({
      data,
      csv_url: csvUrl,
    })
      .then(() => {
        socket.on(SOCKET_EVENTS.RecipeIngredientImported, async data => {
          if (!isEmpty(data)) {
            const { total_failed, total_success, invalid_ingredients = [] } = data || {};

            if (isNumber(total_failed)) {
              setListSuccess(prevState => ({
                ...prevState,
                length: prevState.length - total_failed,
              }));
            }

            setListFail(prevState => {
              if (isNumber(total_success)) {
                const failedItems = (invalid_ingredients || []).map(ingredient => {
                  const { row, message: messageInvalid = '' } = ingredient || {};
                  const failedItem = get(listSuccess, 'data', [])[row - 1];
                  return { ...failedItem, messageInvalid };
                });

                return {
                  ...prevState,
                  length: prevState.length + total_success,
                  data: [...prevState.data, ...failedItems],
                };
              }
              return prevState;
            });

            await getIngredients({
              page: 1,
              per_page: PER_PAGE,
              text_search: '',
              sort: -1,
              sorter: 'last_interacted',
              categories: [],
            });
            setIsSubmitting(false);
          }
        });
      })
      .catch(() => setIsSubmitting(false));
  };

  const onSelectFile = async event => {
    const { target } = event;

    if (!target || !target.files || !target.files.length) return;

    const file = target.files[0];
    const { size, name, type } = file;

    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: 'Send a CSV file to S3',
    });
    const { uploadUrl } = await getPresignedUploadUrl(RECIPE_BUILDER_UPLOAD_URL, file);
    setCsvUrl(uploadUrl);

    await Papa.parse(file, {
      header: true,
      download: true,
      complete: result => {
        const header = get(result, 'meta.fields', []) || [];
        const data = convertDataCSVFile(get(result, 'data', []) || []);
        const checkListIngredientValid = listIngredientValid(data);

        if (type !== 'text/csv' || !!diff(header, HEADER_CSV_FILE) || !checkListIngredientValid) {
          setError(true);
        } else {
          setError(false);
        }

        if ((data || []).length > LIMIT_NUMBER_INGREDIENT) {
          toast.error(`The number of ingredients per upload must be fewer than ${LIMIT_NUMBER_INGREDIENT}.`);
        }

        setList(data);
        setFile(file);
      },
      error: error => {
        console.error('Error reading CSV file:', error);
      },
    });
  };

  const selectStep = selectedStep => {
    if (step === 3 || selectedStep === 3 || (selectedStep === 2 && !list.length)) {
      return false;
    }
    setStep(selectedStep);
  };

  const renderFirstStep = () => {
    if (step !== 1) {
      return null;
    }

    return (
      <S.FistStepContainer>
        <div className="form-group">
          <label>Upload your CSV</label>
          <S.UploadFile className="upload-container">
            <div className="name">
              {file ? (
                <>
                  {file.name} <FileIcon />
                </>
              ) : (
                ''
              )}
            </div>
            <div className="browse" onClick={() => inputFile.current.click()}>
              Browse
            </div>
            <input type="file" accept=".csv" ref={inputFile} onChange={onSelectFile} />
          </S.UploadFile>
          <div className="tip">
            Please download the{' '}
            <a href="/download/Ingredients.csv" download>
              <span>CSV template here</span> <Image src="/images/download.svg" />
            </a>{' '}
            . Then add ingredients info and upload.
          </div>
        </div>
        <div className="desc">
          In the next step, you will get to review the list of uploaded ingredients before saving to our database.
        </div>
      </S.FistStepContainer>
    );
  };

  const renderNutritionItem = item => {
    const { label = '', unit = '', value = '', id } = item;

    return (
      <div className="macro-item" key={id}>
        <div className="macro-dot" />
        {`${label} ${isNumber(value) && value >= 0 ? value : '—'} ${unit}`.trim()}
      </div>
    );
  };

  const renderRecipeNutrition = macro_nutrients => {
    const nutrients = macro_nutrients.length > 0 ? getMacroNutrients(macro_nutrients) : [];
    return <div className="macro-wrapper">{nutrients.length > 0 && nutrients.map(renderNutritionItem)}</div>;
  };

  const renderSecondStep = () => {
    if (step !== 2) {
      return null;
    }

    return (
      <S.SecondStepContainer>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Ingredients ({list.length})</th>
                <th>Category</th>
                <th>Default Unit</th>
                <th>Amount Per</th>
                <th>Nutrition Info</th>
              </tr>
            </thead>
            <tbody>
              {map(list, (item, index) => {
                const {
                  name = '',
                  category = '',
                  default_unit = '',
                  amount_per = '',
                  macro_nutrients = [],
                  other_nutrients = [],
                } = item || {};

                const hasNutrientData = checkNutrientData([...macro_nutrients, ...other_nutrients]);
                const invalidMacroNutrient = checkInvalidNutrient([...macro_nutrients]);
                const invalidOtherNutrient = checkInvalidNutrient([...other_nutrients]);
                const amountPerValid =
                  isAmountPerValid(amount_per, categoryIngredient, category) || (!hasNutrientData && !amount_per);

                return (
                  <tr key={index}>
                    <td className={classNames({ invalid: !name })}>
                      <p className="text-bold limit-3-line ">{name || <span className="text-empty">—</span>}</p>
                    </td>
                    <td
                      className={classNames({
                        invalid: (category && !dataCategoryIngredient.includes(category.toLowerCase())) || !category,
                      })}
                    >
                      <p>{category || <span className="text-empty">—</span>}</p>
                    </td>
                    <td
                      className={classNames({
                        invalid:
                          (default_unit && !dataUnitIngredient.includes(default_unit.toLowerCase())) || !default_unit,
                      })}
                    >
                      <p className="limit-3-line">{default_unit || <span className="text-empty">—</span>}</p>
                    </td>
                    <td
                      className={classNames({
                        invalid: !amountPerValid,
                      })}
                    >
                      <p className="amount-per-text">{amount_per || <span className="text-empty">—</span>}</p>
                    </td>
                    <td
                      className={classNames({
                        'invalid-macro': invalidMacroNutrient,
                        'invalid-other': invalidOtherNutrient,
                      })}
                    >
                      <div className="nutrition-info-wrapper">
                        {renderRecipeNutrition(macro_nutrients)}
                        <NutrientInfo
                          macroNutrients={macro_nutrients}
                          otherNutrients={other_nutrients}
                          listOtherNutrients={listOtherNutrients}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </S.SecondStepContainer>
    );
  };

  const renderThirdStep = () => {
    if (step !== 3) {
      return null;
    }

    if (isSubmitting) {
      return (
        <S.CompletedContainer>
          <LoadingIndicator
            title="Please wait while we process your ingredients..."
            className="loading-indicator-add-ingredient"
          />
        </S.CompletedContainer>
      );
    }

    const isTotallyCompleted = list.length === listSuccess.length;

    if (isTotallyCompleted) {
      return (
        <S.CompletedContainer>
          <CheckMarkIcon />
          <div className="content-third-step">
            <h3>CSV Import is complete!</h3>
            <p>
              You’ve finished adding <span>{pluralize('ingredient', listSuccess.length, true)}</span>
            </p>
          </div>
        </S.CompletedContainer>
      );
    }

    const totalFailed = list.length - listSuccess.length;

    return (
      <S.ThirdStepContainer>
        <div className="info-complete">
          <h3>CSV Import is complete!</h3>
          <p>
            You’ve finished adding <span>{pluralize('ingredient', listSuccess.length, true)}</span> and{' '}
            <span>{totalFailed} failed</span>
          </p>
        </div>
        <div className="title-failed">Failed items</div>
        <S.SecondStepContainer className="list-failed">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Ingredients ({listFail.length})</th>
                  <th>Category</th>
                  <th>Default Unit</th>
                  <th>Amount Per</th>
                  <th>Nutrition Info</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {map(get(listFail, 'data', []), (item, index) => {
                  const {
                    name = '',
                    category = '',
                    default_unit = '',
                    amount_per = '',
                    macro_nutrients = [],
                    other_nutrients = [],
                    messageInvalid = '',
                  } = item || {};
                  const hasNutrientData = checkNutrientData([...macro_nutrients, ...other_nutrients]);
                  const invalidNutrient = checkInvalidNutrient([...macro_nutrients, ...other_nutrients]);
                  const amountPerValid =
                    isAmountPerValid(amount_per, categoryIngredient, category) || (!hasNutrientData && !amount_per);

                  let fieldInvalid = [];
                  if (!name) {
                    fieldInvalid.push('Name');
                  }
                  if ((category && !dataCategoryIngredient.includes(category.toLowerCase())) || !category) {
                    fieldInvalid.push('Category');
                  }
                  if ((default_unit && !dataUnitIngredient.includes(default_unit.toLowerCase())) || !default_unit) {
                    fieldInvalid.push('Unit');
                  }
                  if (!amountPerValid) {
                    fieldInvalid.push('Amount Per');
                  }
                  if (invalidNutrient) {
                    fieldInvalid.push('Nutrition Info');
                  }

                  const textFieldInvalid = fieldInvalid.join(', ');

                  return (
                    <tr key={index}>
                      <td>
                        <p className="text-bold limit-3-line ">{name || <span className="text-empty">—</span>}</p>
                      </td>
                      <td>
                        <p>{category || <span className="text-empty">—</span>}</p>
                      </td>
                      <td>
                        <p className="limit-3-line">{default_unit || <span className="text-empty">—</span>}</p>
                      </td>
                      <td>
                        <p className="amount-per-text">{amount_per || <span className="text-empty">—</span>}</p>
                      </td>
                      <td>
                        <div className="nutrition-info-wrapper">
                          {renderRecipeNutrition(macro_nutrients)}
                          <NutrientInfo
                            macroNutrients={macro_nutrients}
                            otherNutrients={other_nutrients}
                            listOtherNutrients={listOtherNutrients}
                          />
                        </div>
                      </td>
                      <td>
                        <p className="text-invalid">
                          {messageInvalid ? messageInvalid : textFieldInvalid ? `${textFieldInvalid} Invalid` : ''}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </S.SecondStepContainer>
      </S.ThirdStepContainer>
    );
  };

  const renderActionButtons = () => {
    return (
      <>
        <div className="left-actions">
          {step === 1 && error && <span className="text-error">Please check the imported data</span>}
          {step === 2 && listSuccess.length !== list.length && (
            <span className="text-error">Please check the imported data</span>
          )}
        </div>
        <div className="right-actions">
          {step === 1 && (
            <button
              onClick={() => (list.length ? setStep(step + 1) : null)}
              disabled={!list.length || (list || []).length > LIMIT_NUMBER_INGREDIENT || error}
              className="btn-normal"
            >
              Next
            </button>
          )}
          {step === 2 ? (
            <div className="wrapper-action">
              <button disabled={isSubmitting} onClick={() => (isSubmitting ? null : setStep(1))} className="btn-back">
                Back
              </button>
              <button onClick={submitIngredients} disabled={listSuccess.length <= 0} className="btn-normal">
                Add Ingredients ({listSuccess.length})
              </button>
            </div>
          ) : null}
          {step === 3 && (
            <button
              onClick={() => {
                toggleModal(false);
                toggleSecondModal(false);
              }}
              className="btn-normal"
              disabled={isSubmitting}
            >
              Finish
            </button>
          )}
        </div>
      </>
    );
  };

  const title = step === 2 ? 'Review Info' : step === 3 ? 'Confirmation' : 'Import File';

  return (
    <S.Wrapper open={true} onClose={onClose} closeOnDimmerClick={false} step={step}>
      <Modal.Header>
        <Button onClick={onClose} className="close-button">
          <Image src="/images/close_circle.svg" />
        </Button>
        <div className="label">
          <h3>Add Ingredients</h3>
          <h2>{title}</h2>
        </div>
        <div className="progress">
          {map(STEPS, item => (
            <div key={item} className={step >= item ? 'step completed' : 'step'}>
              {item !== 1 && <div className="bar" />}
              <div className="circle" onClick={() => selectStep(item)}>
                {item}
              </div>
            </div>
          ))}
        </div>
        <div className="steps" />
      </Modal.Header>
      <Modal.Content>
        {renderFirstStep()}
        {renderSecondStep()}
        {renderThirdStep()}
      </Modal.Content>
      <Modal.Actions>{renderActionButtons()}</Modal.Actions>
    </S.Wrapper>
  );
};

export default ImportIngredients;
