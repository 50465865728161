import React from 'react';
import _ from 'lodash';
import * as S from './style';
import PropTypes from 'prop-types';
import { CDN_URL } from 'constants/commonData';

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  render() {
    const inputProps = _.omit(this.props, ['onSearch', 'onClear']);

    return (
      <S.Container className="evf-search-box">
        <S.Input {...inputProps} ref={this.inputRef} />
        <S.Button onClick={this.props.onSearch}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path
                d="M7 14C3.14 14 0 10.86 0 7C0 3.14 3.14 0 7 0C10.86 0 14 3.14 14 7C14 10.86 10.86 14 7 14ZM7 2C4.243 2 2 4.243 2 7C2 9.757 4.243 12 7 12C9.757 12 12 9.757 12 7C12 4.243 9.757 2 7 2Z"
                fill="#A1A1A5"
              />
              <path
                d="M15.707 14.2929L13.314 11.8999C12.903 12.4289 12.429 12.9029 11.9 13.3139L14.293 15.7069C14.488 15.9019 14.744 15.9999 15 15.9999C15.256 15.9999 15.512 15.9019 15.707 15.7069C16.098 15.3159 16.098 14.6839 15.707 14.2929Z"
                fill="#A1A1A5"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </S.Button>
        {!!this.props.value && (
          <img
            className="clear-icon"
            src={`${CDN_URL}/images/close_circle.svg`}
            width="12"
            onClick={this.props.onClear}
            alt=""
          />
        )}
      </S.Container>
    );
  }
}

SearchBox.propTypes = {
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool
};

export default SearchBox;
