import React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { Modal } from 'semantic-ui-react';

import * as S from './style';
import { CustomTag } from '../style';

import MediaModal from 'shared/MediaModal/component';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={style} onClick={onClick}></div>;
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={style} onClick={onClick}></div>;
}

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const SlideItem = ({ item }) => {
  return (
    <S.SlideItemWrapper src={item.thumbUrl || item.src} noImage={item.noImage}>
      {item.type === 'video' && <MediaModal video={item.src} presigned />}
    </S.SlideItemWrapper>
  );
};

class ExerciseOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  getItemUrl = async url => {
    const { cloudfrontList, getS3presignedURLFromLocalDatabase } = this.props;
    let newUrl = convertS3UrlToCloudFrontUrl(url, cloudfrontList);
    if (!newUrl) {
      const res = await getS3presignedURLFromLocalDatabase([url]);
      newUrl = res[0];
    }
    return newUrl;
  };

  getExerciseItems = async () => {
    const { exercise } = this.props;

    const items = exercise.picture.map(p => {
      return {
        type: 'image',
        src: p,
      };
    });

    if (exercise.videoLink || exercise.video) {
      items.unshift({
        type: 'video',
        src: exercise.videoLink || exercise.video,
        thumbUrl: exercise.thumbnail_url,
      });
    }

    if (!items.length) {
      this.setState({
        items: [
          {
            type: 'image',
            src: `${CDN_URL}/images/exercise_overview_no_picture.svg`,
            noImage: true,
          },
        ],
      });
    } else {
      for (const item of items) {
        item.src = await this.getItemUrl(item.src);

        if (item.thumbUrl) {
          item.thumbUrl = await this.getItemUrl(item.thumbUrl);
        }
      }
      this.setState({ items });
    }
  };

  componentDidMount() {
    this.getExerciseItems();
  }

  handleCloseAction = () => {
    this.props.onClose();
  };

  render() {
    const { exercise } = this.props;
    const { items } = this.state;

    return (
      <S.ModalWrapper
        open={true}
        onClose={this.handleCloseAction}
        closeOnDimmerClick={false}
        className="exercise-overview"
      >
        <Modal.Header>
          <button onClick={this.handleCloseAction} className="close-button">
            <img alt="close_circle" src={`${CDN_URL}/images/close_circle.svg`} />
          </button>
        </Modal.Header>
        <Modal.Content>
          <S.SlideContainer>
            <Slider {...settings}>
              {items.map(item => (
                <SlideItem key={item.src} item={item} />
              ))}
            </Slider>
          </S.SlideContainer>
          <S.TitleContainer>
            {exercise.custom && <CustomTag className="exercise-overview__custom-tag">Custom</CustomTag>}
            <div className="exercise-overview__title">{exercise.title}</div>
          </S.TitleContainer>
        </Modal.Content>
      </S.ModalWrapper>
    );
  }
}

const mapStateToProps = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapStateToProps, { getS3presignedURLFromLocalDatabase })(ExerciseOverview);
