import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Draggable } from 'react-beautiful-dnd';

import { ReactComponent as PlusGreyIcon } from 'assets/icons/plus_grey.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right_darkest.svg';
import { ReactComponent as DragIcon } from 'assets/icons/MealPlans/trailing_icon.svg';
import { useMyContext } from '../context/GroupBodyMetricsContext';
import OrganizeContentGroup from './OrganizeContentGroup';
import MoreOptionsGroup from './MoreOptionsGroup';
import { DEFAULT_TITLE_GROUP, getStyleLockedDraggable } from '../constant';

import * as S from '../style';

const ItemGroupBodyMetric = props => {
  const { item = {}, index, clientId, textSearch, prefixId = '' } = props;
  const { _id, title, metrics = [], is_default = false } = item;
  const hasSearchMetric = prefixId === 'metric_';

  const {
    handleSelectGroupMetric,
    onRefInnerDiv,
    selectedGroupId,
    expandGroupId,
    handleAddMetricToGroup,
    disableDragGroup,
  } = useMyContext();

  const [expandMetrics, setExpandMetrics] = useState(false);

  useEffect(() => {
    setExpandMetrics(hasSearchMetric);
  }, [prefixId]);

  const isSelected = _id === selectedGroupId;
  const isExpanded = _id === expandGroupId;
  const isEmptyMetric = metrics.length === 0;

  const handleExpandSearch = () => {
    setExpandMetrics(prev => !prev);
  };

  const handleClickGroupMetricHeader = () => {
    if (prefixId === 'metric_') {
      handleExpandSearch();
      return;
    }
    handleSelectGroupMetric(item);
  };

  const focusItemTarget = e => {
    if (e.currentTarget) {
      e.currentTarget.focus();
    }
  };

  return (
    <Draggable draggableId={`group_${_id}`} index={index} isDragDisabled={!!textSearch || disableDragGroup}>
      {(provided, snapshot) => (
        <S.GroupBodyMetricItemWrapper
          id={_id}
          ref={ref => onRefInnerDiv(ref, item, provided)}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames({
            selected: prefixId === 'metric_' ? expandMetrics : isSelected,
            dragging: snapshot.isDragging,
            'show-content': prefixId === 'metric_' ? expandMetrics : isExpanded,
          })}
          style={getStyleLockedDraggable(provided.draggableProps.style)}
          onMouseDown={focusItemTarget}
        >
          <div className="header-group" onClick={handleClickGroupMetricHeader}>
            {!textSearch && <DragIcon className="drag-icon" />}
            <div className="left-side">
              <ArrowRightIcon className="arrow" />
              <div className="title-group-wrapper">
                <div className="title-group">{title || DEFAULT_TITLE_GROUP}</div>
                <div className="title-length">&nbsp;({metrics.length})</div>
              </div>
            </div>
            {!is_default && (hasSearchMetric ? expandMetrics : isExpanded) && (
              <div className="right-side">
                <S.IndividualAddButton onClick={handleAddMetricToGroup(item)}>
                  <PlusGreyIcon data-for={`button-add-metric-to-group__${_id}`} data-tip />
                  <ReactTooltip
                    id={`button-add-metric-to-group__${_id}`}
                    className="button-add-metric-to-group"
                    effect="solid"
                    place="top"
                    disable={false}
                    delayShow={0}
                    eventOff="mouseleave scroll mousewheel blur"
                  >
                    Add Metrics to Group
                  </ReactTooltip>
                </S.IndividualAddButton>
                <MoreOptionsGroup
                  itemId={hasSearchMetric ? `metric_${_id}` : _id}
                  confirmId={_id}
                  isEmptyMetric={isEmptyMetric}
                  metrics={metrics}
                  hasSearchMetric={hasSearchMetric}
                />
              </div>
            )}
          </div>
          <OrganizeContentGroup
            metrics={metrics}
            isEmptyMetric={isEmptyMetric}
            groupId={_id}
            clientId={clientId}
            textSearch={textSearch}
            isDefaultGroup={is_default}
            prefixId={prefixId}
          />
        </S.GroupBodyMetricItemWrapper>
      )}
    </Draggable>
  );
};

export default ItemGroupBodyMetric;
