import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  overflow: auto;
  padding-top: 0px;
  flex: 1 1;

  .no-workouts {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #a6a7ba;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
    width: 100%;
    height: 100%;
    justify-content: center;

    svg {
      margin-bottom: 15px;
    }
  }
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #959daf;
  padding-bottom: 10px;
`;

export const SectionList = styled.div`
  overflow: auto;
`;
