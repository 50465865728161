import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  padding: 0 15px;
`;

export const CategoryItem = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  border-radius: 3px;
  padding: 8px 15px 7px;

  :hover,
  &.active {
    background: #eaebff;
    color: #5158cf;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const ListCategory = styled.div`
  padding: 12px 0;
  flex: 1 1;
`;

export const TemplateItem = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 6px rgba(7, 13, 36, 0.17);
  border-radius: 5px;
  overflow: hidden;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  width: 235px;

  :hover {
    box-shadow: 0px 8px 16px rgba(7, 13, 36, 0.2);
    cursor: pointer;
  }

  .templateInfo {
    padding: 14px 15px 16px;

    &__title {
      font-weight: 600;
    }

    &__description {
      font-weight: normal;
    }
  }

  .coverImage {
    background: #ebedf2;
    height: 142px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > div {
      height: 90px;
      background-color: #fff;
      border-radius: 5px;
    }
  }
`;

export const ListTemplate = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  grid-gap: 25px;
`;

export const ListTemplateContainer = styled.div`
  overflow: auto;
  flex: 1 1;
  padding: 20px 15px 0;
`;

export const LeftSide = styled.div`
  padding: 30px 15px;
  background-color: #f6f7fb;
  flex: 0 0 243px;
  height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const RightSide = styled.div`
  padding: 30px 15px;
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  height: 600px;
`;

export const ChooseTempalateModal = styled(Modal)`
  width: 1068px !important;

  > .content {
    border-radius: 8px !important;
    padding: 0 !important;
  }
`;
