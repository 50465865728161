// Libs
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

// Actions
import {
  getClientMealPlanByDay,
  getClientMealPlanByMonth,
  getClientMealPlanByWeek,
  resetClientMealPlan,
  updateListStatusMealPlanFormat,
} from 'redux/client-meal-plan/actions';
import { toggleModal } from 'actions/modal';
import { updateStatusMealPlanFormat } from 'redux/general-settings/actions';

// Components
import ClientMealPlan from './component';

const mapStateToProps = state => {
  const { rootReducer, user, cloudfrontList } = state;

  const { workingClientDetail } = get(rootReducer, 'client', null);
  const permission = get(rootReducer, 'permission.recipe', false);
  const permissionAddOn = get(rootReducer, 'permission.meal_plan', false);
  const clientTimezone = get(rootReducer, 'client.workingClientDetail.timezone', '');
  const coachTimezone = get(user, 'timezone', '');

  const {
    mealPlanByDay,
    loading,
    assignedMealPlanClients,
    assignedMealPlanClientsByRange,
    manualMealClients,
    listStatusMealPlanFormat,
    viewMode,
  } = get(rootReducer, 'clientMealPlan', {});

  const generalSettings = get(rootReducer, 'generalSettings', {});

  return {
    loading,
    mealPlanByDay,
    selectedClient: workingClientDetail,
    permission,
    clientTimezone,
    coachTimezone,
    cloudfrontList,
    assignedMealPlanClients,
    assignedMealPlanClientsByRange,
    manualMealClients,
    permissionAddOn,
    generalSettings,
    listStatusMealPlanFormat,
    viewMode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    getClientMealPlanByDay: bindActionCreators(getClientMealPlanByDay, dispatch),
    getClientMealPlanByWeek: bindActionCreators(getClientMealPlanByWeek, dispatch),
    getClientMealPlanByMonth: bindActionCreators(getClientMealPlanByMonth, dispatch),
    resetClientMealPlan: bindActionCreators(resetClientMealPlan, dispatch),
    updateStatusMealPlanFormat: bindActionCreators(updateStatusMealPlanFormat, dispatch),
    updateListStatusMealPlanFormat: bindActionCreators(updateListStatusMealPlanFormat, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientMealPlan);
