import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const AddNewIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #5158cf;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px !important;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 647px;
    > :last-child {
      padding: 20px 30px;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    > .content {
      padding: 30px 30px 0 !important;
      border-radius: 8px !important;
      height: 578px;
      overflow: hidden;
    }
    > .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: transparent;
      box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);

      button {
        height: 32px;
        width: 94px;
        margin-left: 5px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
      }

      .ui.button.general-button.create-button {
        width: 127px;
        height: 18px;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #5158cf !important;
        border: none;
        box-shadow: none !important;
        background-color: transparent;
        padding: 0;
        margin: 0 !important;

        :hover {
          cursor: pointer;
          color: #5559ff !important;
          background-color: transparent !important;

          ${AddNewIcon} {
            background-color: #5559ff;
          }
        }
      }
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  margin-bottom: 0;
`;

export const SearchWrapper = styled.div`
  .search-products {
    height: 40px;
    width: 270px;
    input {
      background-color: #fff;
      border-radius: 5px;
      background-image: url(${CDN_URL}/images/activity_search.svg);
      &::placeholder {
        font-size: 13px;
        line-height: 18px;
        color: #a6acc6;
        opacity: 1;
      }
    }
  }
`;

export const TabsContainer = styled.div`
  flex: 0 0 auto;
  padding: 24px 0;
`;

export const HeaderTab = styled.div`
  font-style: normal;
  display: inline-block;
  height: 31px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  opacity: 0.6;
  margin-right: 30px;
  cursor: pointer;
  ${props =>
    props.isActive &&
    css`
      color: #5158cf;
      font-weight: 700;
      opacity: 1;
      box-shadow: inset 0px -2px 0px #5158cf;
    `}
`;

export const ListWrapper = styled.div`
  ::-webkit-scrollbar {
    width: 5px !important;
  }

  .loading-indicator {
    padding-top: 0;
  }
`;

export const List = styled.div`
  max-height: 430px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: -17px;
  padding-right: 17px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const Empty = styled.div`
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FirstCreateNew = styled.div`
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const EmptyBanner = styled.div`
  width: 372px;
  text-align: center;
  margin: 0 auto;

  span {
    color: #202353;
    font-size: 14px;
    line-height: 120%;
    padding-top: 50px;
  }
`;

export const EmptyDesc = styled.p`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #202353;
  max-width: 327px;
  margin: 18px auto 24px;
  opacity: 0.8;
  color: #000;
`;

export const AddNewWrapper = styled.div`
  text-align: center;
  button {
    border: 1px solid #5c5bbd;
    border-radius: 5px;
    height: 34px;
    width: 166px;
    color: #5158cf;
  }
`;

export const NotFound = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b8bfda;
  text-align: center;
`;

export const FormItemWrapper = styled.div`
  padding: 0 20px 0 30px;
  width: 587px;
  height: 99px;
  border: 1px solid #ebedf4;
  border-radius: 8px;
  display: grid;
  align-items: center;
  grid-template-columns: 52px auto;
  cursor: pointer;
  ${props =>
    props.selected &&
    css`
      border: 1px solid #5158cf;
    `}

  &:hover {
    border: 1px solid #5158cf;
  }
  margin-bottom: 8px;

  :last-child {
    margin-bottom: 10px;
  }

  svg {
    width: 36px;
    height: 36px;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const Signature = styled.span`
  background-color: #edf0fa;
  color: #202870;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 2px 4px;
  font-weight: 700;
  line-height: 14px;

  svg {
    fill: #202870;
    position: relative;
    top: 1px;
    width: 10px;
    height: 10px;
  }
`;

export const Desc = styled.div`
  position: relative;

  .form-item {
    position: absolute !important;
    top: 9px;
    right: 4px;

    &.ui.radio.checkbox label:before,
    &.ui.radio.checkbox label:after {
      width: 20px;
      height: 20px;
      top: 1px;
      left: 1px;
    }

    &.ui.radio.checkbox input:checked ~ label:before {
      border-color: #5e59ff !important;
    }

    &.ui.radio.checkbox input:checked ~ label:after {
      background-color: #5e59ff !important;
    }
  }
`;

export const DescTitle = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  max-height: 36px;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  padding-right: 20px;
  white-space: pre-wrap;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const DescCounter = styled.span`
  font-size: 12px;
  line-height: 150%;
  color: #202353;
  opacity: 0.5;
`;

export const TextBold = styled(DescCounter)`
  font-weight: 700;
  opacity: 1;
`;

export const Bull = styled.span`
  opacity: 0.5;
  font-size: 14px;
  margin: 0 10px;
`;
