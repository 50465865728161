import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  background: #f8f8f8;
  border-radius: 3px;
  color: #202353;

  .previous,
  .next {
    height: 100%;
    padding: 8px 15px;
    border: none;
    background-color: transparent;

    :hover {
      cursor: pointer;
      border: none;

      svg {
        path {
          fill: #5158cf;
        }
      }
    }
  }
`;
