import React from 'react';
import NormalMetricProgress from './NormalMetricProgress';
import ManualStepProgress from './ManualStepProgress';

const MetricProgressWrapper = ({
  isManualStepMetric = false,
  refreshManualStepChart,
  getChartDataLatestByDay,
  getBodyMetricEntryHistory,
  removeAllMetricEntryByDay,
  addSleepEntry,
  removeSleepMetricEntry,
  updateSleepMetricEntry,
  ...props
}) => {
  if (props.selectedMetric && !props.selectedMetric.unique_code) {
    return null;
  }
  if (isManualStepMetric) {
    return <ManualStepProgress {...props} refreshManualStepChart={refreshManualStepChart} />;
  }

  return (
    <NormalMetricProgress
      {...props}
      addSleepEntry={addSleepEntry}
      removeSleepMetricEntry={removeSleepMetricEntry}
      updateSleepMetricEntry={updateSleepMetricEntry}
      removeAllMetricEntryByDay={removeAllMetricEntryByDay}
      getChartDataLatestByDay={getChartDataLatestByDay}
      getBodyMetricEntryHistory={getBodyMetricEntryHistory}
    />
  );
};

export default MetricProgressWrapper;
