import { connect } from 'react-redux';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import ClientOverview from './component';
import { updateInitialProgressPhotoFilters } from 'redux/progress-photo/actions';

const mapStateToProps = state => {
  const { rootReducer } = state;

  return {
    selectedClient: rootReducer.client.workingClientDetail,
    timezone: get(rootReducer, 'client.workingClientDetail.timezone'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateInitialProgressPhotoFilters: bindActionCreators(updateInitialProgressPhotoFilters, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientOverview);
