import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Checkbox } from 'shared/FormControl';
import { PER_PAGE } from '../constants';

import * as S from '../style';

const TableHeader = props => {
  const { selected = [], setSelected, getAllClient, startDate, ownerFilter, totalMissed, clients } = props;

  const [allIds, setAllIds] = useState([]);

  useEffect(() => {
    const params = {
      day: startDate,
      owner_ship: ownerFilter,
    };

    if (totalMissed <= PER_PAGE) {
      const ids = clients.map(item => get(item, 'client'));
      setAllIds(ids);
    } else if (selected.length === 1) {
      setAllIds([]);
      getAllClient(params, res => {
        const allIds = res.map(item => get(item, 'client'));
        setAllIds(allIds);
      });
    }
  }, [startDate, ownerFilter, totalMissed, selected]);

  const isSelectedAll = !isEmpty(selected) && !isEmpty(allIds) && selected.length >= allIds.length;

  const handleSelectAllTask = e => {
    const params = {
      day: startDate,
      owner_ship: ownerFilter,
    };

    if (e.target && e.target.checked) {
      if (totalMissed <= PER_PAGE) {
        const ids = clients.map(item => get(item, 'client'));
        setAllIds(ids);
        setSelected(ids);
      } else {
        getAllClient(params, res => {
          const ids = res.map(item => get(item, 'client'));
          setAllIds(ids);
          setSelected(ids);
        });
      }
    } else {
      setSelected([]);
    }
  };

  return (
    <S.TableHeaderWrapper>
      <S.TableHeader>
        <tr>
          <S.TableCellHeader>
            <S.TableCellContent>
              <S.CheckboxClientContainer>
                <S.CheckBoxContainer>
                  <S.CheckboxHeader>
                    <Checkbox checked={isSelectedAll} onChange={handleSelectAllTask} />
                  </S.CheckboxHeader>
                </S.CheckBoxContainer>
                <S.TableHeaderContent>Client Name</S.TableHeaderContent>
              </S.CheckboxClientContainer>
            </S.TableCellContent>
          </S.TableCellHeader>
          <S.TableCellHeader>
            <S.TableCellContent paddingLeft={16}>
              <S.TableHeaderContent>Missed Workouts</S.TableHeaderContent>
            </S.TableCellContent>
          </S.TableCellHeader>
          <S.TableCellHeader className="actions"></S.TableCellHeader>
        </tr>
      </S.TableHeader>
    </S.TableHeaderWrapper>
  );
};

TableHeader.propTypes = {
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default TableHeader;
