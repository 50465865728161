import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeViewMode,
  changeStartDate,
  resetData,
  resetCopyItem,
  getListMessages,
  onDragMessageEnd
} from 'redux/autoflow/autoMessage/actions';
import CalendarLayout from 'components/AutoflowCalendar';

const mapStateToProps = state => {
  const {
    rootReducer: {
      autoflow: {
        autoMessage: { copying },
        common: { viewMode, startDate },
      }
    }
  } = state;

  return {
    viewMode,
    startDate,
    copying
  };
};

const mapDispatchToProps = dispatch => ({
  onDidMount: bindActionCreators(getListMessages, dispatch),
  onDragEnd: bindActionCreators(onDragMessageEnd, dispatch),
  onWillUnmount: bindActionCreators(resetData, dispatch),
  changeViewMode: bindActionCreators(changeViewMode, dispatch),
  changeStartDate: bindActionCreators(changeStartDate, dispatch),
  resetCopyItem: bindActionCreators(resetCopyItem, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLayout);
