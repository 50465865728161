import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import * as S from './style';
import ConfirmModal from 'shared/ConfirmModal';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { duplicateStudioResource, removeStudioResource, updateShareStatus } from 'redux/studio-resource/actions';
import AddResourceFormPopup from '../../AddResourceFormPopup';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';
import { ShareSettingModal } from 'shared/AssetsShareSetting';
import { isTeamAdmin } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { SharedTooltip } from 'shared/SharedTooltip';

function ResourceDropdownOptions(props) {
  const { studioResource = {}, user, updateShareStatus, toggleModal } = props;
  const studioResourceId = studioResource._id;
  const author = typeof studioResource.author_info === 'object' ? studioResource.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = studioResource.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;

  if (!isCreatorOrOwnerOrAdmin) return <div />;

  const onRemoveClick = e => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        newStyle
        confirmButtonTitle="Remove"
        title="Remove Resource?"
        content="Are you sure that you want to remove this resource?"
        onConfirm={() => props.removeStudioResource(studioResource)}
      />,
    );
  };

  const onEdit = () => {
    props.toggleModal(
      true,
      <AddResourceFormPopup source_type={studioResource.source_type} defaultResource={studioResource} />,
    );
  };

  const handleSaveShare = ({ params } = {}) => {
    typeof toggleModal === 'function' && toggleModal(false);
    if (!params || !studioResourceId) return;
    typeof updateShareStatus === 'function' && updateShareStatus({ params, studioResourceId });
  };

  const onShare = () => {
    props.toggleModal(
      true,
      <ShareSettingModal
        user={user}
        owner={author}
        shareStatus={studioResource.share}
        closeModal={onCloseModal}
        onSave={handleSaveShare}
      />,
    );
  };

  const onCloseModal = () => {
    props.toggleModal(false);
  };

  return (
    <S.DropdownWrapper className={props.className}>
      <DropDown
        direction="left"
        triggerIcon={({ open }) => (
          <div data-tip data-for="resources-item-options-tooltip">
            <S.TriggerIcon className="trigger-icon" active={!!open} />
            <SharedTooltip id="resources-item-options-tooltip" />
          </div>
        )}
      >
        <Option key="edit" onClick={onEdit}>
          <img className="icon edit" src={`${CDN_URL}/images/edit-white.svg`} alt="" />
          <span className="text">Edit</span>
        </Option>
        <Option key="share" onClick={onShare}>
          <ShareIcon className="icon share" />
          <span className="text">Sharing settings</span>
        </Option>
        <Option key="delete" onClick={onRemoveClick}>
          <img className="icon" src={`${CDN_URL}/images/delete.svg`} alt="" />
          <span className="text">Remove</span>
        </Option>
      </DropDown>
    </S.DropdownWrapper>
  );
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  push,
  toggleConfirmModal,
  toggleModal,
  duplicateStudioResource,
  removeStudioResource,
  updateShareStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDropdownOptions);
