import styled, { css } from 'styled-components';
import { ReactComponent as CheckedIcon } from 'assets/icons/check_circle.svg';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

const baseText = `
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 15.6px;
  color: #FFFFFF;
`;

export const DeleteButton = styled.button`
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  top: 16px;
  right: 16px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  display: none;

  ${props =>
    props.isCypress &&
    css`
      display: flex;
    `}

  svg {
    width: 11px;
    height: 11px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :hover {
    background: #fff;
    cursor: pointer;
    svg path {
      fill: #5e59ff;
    }
  }
`;

export const ZoomSettingButton = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  bottom: 16px;
  right: 16px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);

  .__react_component_tooltip.show {
    background-color: #2d2e2d;
    border-radius: 5px;
    width: 97px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    color: #ffffff;
  }
  .__react_component_tooltip.type-dark.place-left:after {
    border-left-color: #2d2e2d;
  }

  &:hover {
    background: #fff;
    border-color: #fff;
    svg path {
      fill: #5e59ff;
    }
  }
`;

export const TagWrapper = styled.div`
  width: 100px;
  height: 28px;
  position: absolute;
  top: 16px;
  left: 16px;
  pointer-events: none;
  display: none;
  > div {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    div {
      color: #fff;
    }
    div:last-child {
      display: none;
    }
  }
  ${props =>
    props.isCypress &&
    css`
      pointer-events: none;
      display: block;
      > div {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        div {
          color: #fff;
        }
        div:last-child {
          display: none;
        }
      }
    `}
`;

export const PhotoWrapper = styled.div`
  position: relative;
  height: 535px;
  width: 302px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #494949;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-transform-wrapper {
    height: 100%;
  }

  .react-transform-component {
    height: 100%;
  }

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid transparent;
    border-radius: 8px;
    z-index: 1;
    pointer-events: none;
  }
  @media (max-height: 762px) {
    margin: 0 auto 16px;
    height: calc(100vh - 232px);
    width: calc((302 * calc(100vh - 232px)) / 535);
  }
  ${props =>
    props.isDragging &&
    css`
      -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
      ::before {
        border: 2px solid #5e59ff;
      }
    `}
  ${props =>
    props.isSelected &&
    css`
      -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
      ::before {
        border: 2px solid #5e59ff;
      }
    `}
`;

export const PhotoInfoWrapper = styled.div`
  background-color: transparent;
  ${props =>
    props.loaded &&
    css`
      background-color: #fff;
    `}

  ${props =>
    props.isCypress &&
    css`
      width: 100%;
      height: 100%;
    `}
`;

export const PhotoInfo = styled.div`
  position: relative;
  display: block;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  ${props =>
    props.isCypress &&
    css`
      width: 100%;
      height: 100%;
      background: #f5f5f5;
    `}
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 535px;
    max-width: 302px;
  }
`;

export const CheckedIconWrapper = styled(CheckedIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  z-index: 1;
`;

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 132px;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  pointer-events: none;
`;

export const PostedDate = styled.span`
  ${baseText}
  position: absolute;
  bottom: 16px;
  left: 16px;
  letter-spacing: -0.2px;
`;

export const S3ImageWithFallbackWrapper = styled(S3ImageWithFallback)`
  display: none;
  ${props =>
    props.loaded &&
    css`
      display: flex;
      width: 100%;
      height: 100%;
      img {
        object-fit: contain;
      }
    `}
`;
