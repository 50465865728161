import React, { Component } from 'react';
import { Mixpanel } from 'utils/mixplanel';
import * as S from './style';

import Dropzone from 'react-dropzone';
import classNames from 'classnames';
// import UploadCoverImage from 'components/Package/PackageDetail/Overview/PackageForm/BannerImage';
import BackgroundSelect from './DefaultBackgroundImageSelect';
import BackgroundUpload from './DefaultBackgroundImageUpload';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCustomBranding } from 'redux/custom-branding/actions';

class WorkoutBackground extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workoutBackground: { ...props.selectedWorkoutBackground },
    };
    this.isSaving = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleCancelWorkBackground(nextProps);
  }

  componentDidUpdate(prevProps) {
    const { selectedWorkoutBackground, loading } = this.props;
    const { workoutBackground } = this.state;

    if (!loading && prevProps.loading) {
      this.setState({
        workoutBackground: { ...selectedWorkoutBackground },
      });
    } else if (
      selectedWorkoutBackground._id !== prevProps.selectedWorkoutBackground._id &&
      selectedWorkoutBackground._id !== workoutBackground._id
    ) {
      // Workout background has been deleted
      this.setState({ workoutBackground: { ...selectedWorkoutBackground } });
    }
  }

  handleCancelWorkBackground = data => {
    const {
      discard,
      onChangeDiscard,
      setCurrentIndex,
      activeIndex,
      setScreenActive,
      getCustomBrandingData,
      setWorkoutBackground,
    } = data;

    if (discard) {
      getCustomBrandingData().finally(() => {
        this.setState({
          workoutBackground: { ...data.selectedWorkoutBackground },
        });
        this.isSaving = false;
        this.props.onCheckSaving(false);
        onChangeDiscard(false);
        setCurrentIndex(activeIndex);
        setScreenActive(activeIndex);
        setWorkoutBackground(data.selectedWorkoutBackground);
      });
    }
  };

  onChangeBackground = data => {
    const { setWorkoutBackground } = this.props;

    this.setState({ workoutBackground: { ...data } });
    setWorkoutBackground(data);

    this.props.onCheckSaving(true);
  };

  numOfCustomBackground = () => {
    let numOfCustomBackground = this.props.workoutBackgrounds.filter(item => !item.default).length;
    return numOfCustomBackground;
  };

  onSave = () => {
    if (this.isSaving) {
      return false;
    }

    const { workoutBackground } = this.state;

    this.isSaving = true;
    const data = {
      selected_workout_background: workoutBackground._id,
    };
    const localData = {
      selectedWorkoutBackground: workoutBackground,
    };
    this.props.updateCustomBranding(data, localData).finally(() => {
      Mixpanel.track('advance_custom_brand_set_workout_background');
      this.isSaving = false;
      this.props.onCheckSaving(false);
    });
  };

  renderSaveButton = () => {
    const { selectedWorkoutBackground, loading } = this.props;
    const { workoutBackground } = this.state;

    if (!loading && selectedWorkoutBackground._id !== workoutBackground._id) {
      this.props.onCheckSaving(true);
      return <S.SaveButton onClick={this.onSave}>Save</S.SaveButton>;
    } else {
      this.props.onCheckSaving(false);
      return <S.SaveButton disabled>Save</S.SaveButton>;
    }
  };

  render() {
    return (
      <S.WorkoutBackgroundWrapper>
        <S.BackgroundSelectWrapper>
          <S.DescriptionContainer>
            <S.DescriptionText>
              Set a default background for your workouts and add alternatives to choose from for any workout you create
              and assign.
            </S.DescriptionText>
          </S.DescriptionContainer>
          <S.UploadBackgroundContainer>
            <S.UploadBackgroundText>Choose Default Background</S.UploadBackgroundText>
            <BackgroundSelect data={this.state.workoutBackground} onChange={this.onChangeBackground} />
          </S.UploadBackgroundContainer>
          <S.UploadBackgroundContainer>
            <S.UploadBackgroundText>Your Backgrounds ({this.numOfCustomBackground()})</S.UploadBackgroundText>
            <BackgroundUpload
              data={this.state.workoutBackground}
              onChange={this.onChangeBackground}
              numOfCustomBackground={this.numOfCustomBackground()}
            />
          </S.UploadBackgroundContainer>
        </S.BackgroundSelectWrapper>
        {this.renderSaveButton()}
      </S.WorkoutBackgroundWrapper>
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      customBranding: { loading, selectedWorkoutBackground, workoutBackgrounds },
    },
  } = state;

  return { loading, selectedWorkoutBackground, workoutBackgrounds };
};

const mapDispatch = dispatch => ({
  updateCustomBranding: bindActionCreators(updateCustomBranding, dispatch),
});

export default connect(mapState, mapDispatch)(WorkoutBackground);
