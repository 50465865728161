/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { State, Dispatch } from 'types/redux';
import { getExerciseHistoryEntries } from 'actions/client';
import HistoryExercisePopup from './component';

const mapStateToProps = (state: State) => {
  const { user, rootReducer } = state;
  const { workingClientDetail } = rootReducer.client;
  return {
    user,
    selectedClient: workingClientDetail,
    fields: rootReducer.exercise.fields,
    unit : workingClientDetail ? workingClientDetail.preferences : user.preferences,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    getExerciseHistoryEntries: data => dispatch(getExerciseHistoryEntries(data))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HistoryExercisePopup)
);
