import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import diff from 'deep-diff';
import { isTeamAdmin } from 'utils/commonFunction';
import {
  getWorkoutLibrary,
  resetQueryParams,
  changeQueryParams,
  addBulkAddTagWorkouts,
  resetSelectedWorkout,
} from 'redux/workout_library/workout.actionCreators';
import { ToolbarContainer } from 'shared/LibraryLayout';
import { NewSearchInput } from 'shared/SearchInput';
import { Button } from 'shared/FormControl';
import { toggleModal } from 'actions/modal';
import { FilterTrigger, TagsButton, ToolbarLeft, WrapperActions, ResetFilter } from '../style';
import CreateWorkoutTemplate from 'components/CreateWorkoutTemplate';
import Filter from 'shared/ExerciseFilterPopup';
import Tag from 'assets/icons/tag.svg';
import { ReactComponent as StarsAIIcon } from 'assets/icons/stars-2.svg';
import MenuTags from 'shared/MenuTags';
import { CDN_URL, TAGS_TYPE } from 'constants/commonData';
import ManageTags from 'shared/ManageTags';
import CreateNewTags from './CreateNewTags';
import { createNewTag, getMostRecentTagsList } from 'redux/tags/actions';
import CreateWorkoutTemplateAI from 'components/CreateWorkoutTemplateAIDemo';

function View(props) {
  const [open, setOpen] = useState(false);
  const {
    q,
    isModalOpen,
    workoutTags,
    countTagsSelected,
    tagsSelected,
    onClearSelectedByTags,
    addBulkAddTagWorkouts,
    user,
    listWorkout,
    selectedWorkout,
    search_tags,
    resetSelectedWorkout,
    enableAIworkout,
  } = props;
  const { query, mostRecentList = [] } = workoutTags;
  const [openCreateNewTags, setOpenCreateNewTags] = useState(false);
  const [shouldGetTags, setShouldGetTags] = useState(true);
  const [openManageTags, setOpenManageTags] = useState(false);

  useEffect(() => {
    props.getWorkoutLibrary();

    return () => {
      props.resetQueryParams();
    };
  }, []);

  useEffect(() => {
    if (!isModalOpen && !openCreateNewTags && shouldGetTags) {
      props.getMostRecentTagsList({
        page: query.page,
        sort: -1,
        type: TAGS_TYPE.WORKOUT,
        text_search: query.textSearch,
      });
    }
  }, [query.page, query.textSearch, isModalOpen, openCreateNewTags]);

  const searchWorkout = (event, { value }) => {
    const search = value.toLowerCase().trim();

    if (search !== q) {
      resetSelectedWorkout();
      props.changeQueryParams({ page: 1, q: search });
    }
  };

  const onNew = () => {
    props.toggleModal(true, <CreateWorkoutTemplate />);
  };

  const onCreateNewAI = () => {
    props.toggleModal(true, <CreateWorkoutTemplateAI />);
  };

  const onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      resetSelectedWorkout();
      props.changeQueryParams({ page: 1 });
    }
  };

  const handleOpenManageTags = () => {
    props.toggleModal(true, <ManageTags type={TAGS_TYPE.WORKOUT} onCloseModal={handleCloseModalManageTag} />);
    setOpenManageTags(true);
  };

  const handleCloseModalManageTag = () => {
    setTimeout(() => {
      setOpenManageTags(false);
    }, 100);
  };

  const searchDebounce = _.debounce(searchWorkout, 300);

  const handleApplyFilter = newFilters => {
    resetSelectedWorkout();
    props.changeQueryParams({ search_tags: newFilters.tags });
  };
  const onOpenCreateTags = () => {
    props.toggleModal(
      true,
      <CreateNewTags open={true} onCloseModal={handleCloseModal} onSubmit={props.createNewTag} />,
    );
    setOpenCreateNewTags(true);
  };

  const handleCloseModal = () => {
    props.toggleModal(false);
    setTimeout(() => {
      setOpenCreateNewTags(false);
    }, 100);
  };

  const handleUpdateGeneralApplyTags = data => {
    const defaultSelected = _.get(data, 'defaultSelected', []);
    const currentSelected = _.get(data, 'tagIds', []);
    const workoutIds = _.get(data, 'elementsSelectedByTabs', []);
    let newDataTags = {
      workoutIds: workoutIds,
      newTagIds: currentSelected,
      removedTagIds: [],
    };
    let newTagIds = [];
    let removedTagIds = [];

    // If default selected have tags selected
    if (defaultSelected.length > 0) {
      if (diff(defaultSelected, currentSelected)) {
        removedTagIds = _.filter(defaultSelected, item => !currentSelected.includes(item));
        newTagIds = _.filter(currentSelected, item => !defaultSelected.includes(item));

        newDataTags = {
          ...newDataTags,
          newTagIds: diff(newTagIds, currentSelected) || diff(newTagIds, defaultSelected) ? newTagIds : [],
          removedTagIds: removedTagIds,
        };
      }
    }

    return newDataTags;
  };

  const handleApplyTags = data => {
    if (data) {
      const newDataTags = handleUpdateGeneralApplyTags(data);
      addBulkAddTagWorkouts && addBulkAddTagWorkouts(newDataTags);
      onClearSelectedByTags && onClearSelectedByTags();
    }
  };

  const tagMenuPortalTarget = document.body;
  const tagsStyles = { menuStyles: { margin: '1px 0px' }, menuListStyles: { padding: '10px' } };
  const allowEditTag = useMemo(() => {
    const isAdmin = isTeamAdmin(user);

    if (isAdmin) {
      return true;
    }
    const currentSelectedWorkout = listWorkout.filter(it => selectedWorkout.includes(it._id) && user._id !== it.author);

    return currentSelectedWorkout.length > 0 ? false : true;
  }, [selectedWorkout, listWorkout]);

  const handleResetFilter = () => {
    resetSelectedWorkout();
    props.resetQueryParams(true);
  };

  const hideResetButton = _.isEqual(search_tags, []);

  return (
    <>
      <ToolbarContainer>
        <ToolbarLeft>
          <NewSearchInput
            onChange={searchDebounce}
            onClearSearch={() => {
              resetSelectedWorkout();
              return searchWorkout(null, { value: '' });
            }}
            placeholder="Search workout name"
            onKeyPress={onKeyPress}
          />

          <Filter
            tagMenuPortalTarget={tagMenuPortalTarget}
            menuWidth={379}
            menuMinHeight={211}
            useMinHeight
            tags={workoutTags}
            currentFilters={{ tags: search_tags }}
            tagType={TAGS_TYPE.WORKOUT}
            tagsStyles={tagsStyles}
            title="Workout Filters"
            onApply={handleApplyFilter}
            hideDefaultFilter
            trigger={<FilterTrigger>Filter</FilterTrigger>}
          />
          <ResetFilter onClick={handleResetFilter} hide={hideResetButton}>
            Reset
          </ResetFilter>
        </ToolbarLeft>
        <WrapperActions>
          <TagsButton
            title="Tags"
            img={Tag}
            open={open}
            onClick={() => {
              setOpen(true);
              setShouldGetTags(true);
            }}
          />
          {enableAIworkout && (
            <Button className="ai-btn" onClick={onCreateNewAI}>
              <StarsAIIcon /> Everfit AI
            </Button>
          )}
          <Button purple className="button--add-new-library-instance" onClick={onNew}>
            <img src={`${CDN_URL}/images/plus_white.svg`} alt="" />
            <span>Add New Workout</span>
          </Button>
        </WrapperActions>
      </ToolbarContainer>
      {open && (
        <MenuTags
          open={open}
          onClose={() => {
            setOpen(false);
            setShouldGetTags(false);
          }}
          type={TAGS_TYPE.WORKOUT}
          tags={mostRecentList}
          onClickAddNew={onOpenCreateTags}
          toggleModal={props.toggleModal}
          onClickManage={handleOpenManageTags}
          onSubmit={props.createNewTag}
          countTagsSelected={countTagsSelected}
          elementsSelected={tagsSelected}
          disableCloseMenu={openCreateNewTags || openManageTags}
          onApplyTabs={handleApplyTags}
          containerClassName={enableAIworkout ? 'container-tags-ai' : ''}
        />
      )}
    </>
  );
}

const WorkoutLibraryToolbar = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      workoutLibrary: {
        listWorkout,
        selectedWorkout,
        query: { from, q, search_tags },
      },
      workoutTags,
      permission,
    },
    isModalOpen,
    user,
  } = state;

  const currentFilters = { from };
  const enableAIworkout = _.get(permission, 'workout_builder_ai', false);

  return {
    currentFilters,
    q,
    workoutTags,
    isModalOpen,
    search_tags,
    user,
    listWorkout,
    selectedWorkout,
    enableAIworkout,
  };
};

const mapDispatch = dispatch => {
  return {
    getWorkoutLibrary: bindActionCreators(getWorkoutLibrary, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    resetQueryParams: bindActionCreators(resetQueryParams, dispatch),
    createNewTag: bindActionCreators(createNewTag, dispatch),
    addBulkAddTagWorkouts: bindActionCreators(addBulkAddTagWorkouts, dispatch),
    resetSelectedWorkout: bindActionCreators(resetSelectedWorkout, dispatch),
  };
};

export default connect(mapState, mapDispatch)(WorkoutLibraryToolbar);
