export default [
  { key: 'AF', value: 'AF', label: 'Afghanistan' },
  { key: 'AX', value: 'AX', label: 'Aland Islands' },
  { key: 'AL', value: 'AL', label: 'Albania' },
  { key: 'DZ', value: 'DZ', label: 'Algeria' },
  { key: 'AS', value: 'AS', label: 'American Samoa' },
  { key: 'AD', value: 'AD', label: 'Andorra' },
  { key: 'AO', value: 'AO', label: 'Angola' },
  { key: 'AI', value: 'AI', label: 'Anguilla' },
  { key: 'AQ', value: 'AQ', label: 'Antarctica' },
  { key: 'AG', value: 'AG', label: 'Antigua and Barbuda' },
  { key: 'AR', value: 'AR', label: 'Argentina' },
  { key: 'AM', value: 'AM', label: 'Armenia' },
  { key: 'AW', value: 'AW', label: 'Aruba' },
  { key: 'AU', value: 'AU', label: 'Australia' },
  { key: 'AT', value: 'AT', label: 'Austria' },
  { key: 'AZ', value: 'AZ', label: 'Azerbaijan' },
  { key: 'BS', value: 'BS', label: 'Bahamas' },
  { key: 'BH', value: 'BH', label: 'Bahrain' },
  { key: 'BD', value: 'BD', label: 'Bangladesh' },
  { key: 'BB', value: 'BB', label: 'Barbados' },
  { key: 'BY', value: 'BY', label: 'Belarus' },
  { key: 'BE', value: 'BE', label: 'Belgium' },
  { key: 'BZ', value: 'BZ', label: 'Belize' },
  { key: 'BJ', value: 'BJ', label: 'Benin' },
  { key: 'BM', value: 'BM', label: 'Bermuda' },
  { key: 'BT', value: 'BT', label: 'Bhutan' },
  { key: 'BO', value: 'BO', label: 'Bolivia' },
  { key: 'BQ', value: 'BQ', label: 'Bonaire, Saint Eustatius and Saba ' },
  { key: 'BA', value: 'BA', label: 'Bosnia and Herzegovina' },
  { key: 'BW', value: 'BW', label: 'Botswana' },
  { key: 'BV', value: 'BV', label: 'Bouvet Island' },
  { key: 'BR', value: 'BR', label: 'Brazil' },
  { key: 'IO', value: 'IO', label: 'British Indian Ocean Territory' },
  { key: 'VG', value: 'VG', label: 'British Virgin Islands' },
  { key: 'BN', value: 'BN', label: 'Brunei' },
  { key: 'BG', value: 'BG', label: 'Bulgaria' },
  { key: 'BF', value: 'BF', label: 'Burkina Faso' },
  { key: 'BI', value: 'BI', label: 'Burundi' },
  { key: 'KH', value: 'KH', label: 'Cambodia' },
  { key: 'CM', value: 'CM', label: 'Cameroon' },
  { key: 'CA', value: 'CA', label: 'Canada' },
  { key: 'CV', value: 'CV', label: 'Cape Verde' },
  { key: 'KY', value: 'KY', label: 'Cayman Islands' },
  { key: 'CF', value: 'CF', label: 'Central African Republic' },
  { key: 'TD', value: 'TD', label: 'Chad' },
  { key: 'CL', value: 'CL', label: 'Chile' },
  { key: 'CN', value: 'CN', label: 'China' },
  { key: 'CX', value: 'CX', label: 'Christmas Island' },
  { key: 'CC', value: 'CC', label: 'Cocos Islands' },
  { key: 'CO', value: 'CO', label: 'Colombia' },
  { key: 'KM', value: 'KM', label: 'Comoros' },
  { key: 'CK', value: 'CK', label: 'Cook Islands' },
  { key: 'CR', value: 'CR', label: 'Costa Rica' },
  { key: 'HR', value: 'HR', label: 'Croatia' },
  { key: 'CU', value: 'CU', label: 'Cuba' },
  { key: 'CW', value: 'CW', label: 'Curacao' },
  { key: 'CY', value: 'CY', label: 'Cyprus' },
  { key: 'CZ', value: 'CZ', label: 'Czech Republic' },
  { key: 'CD', value: 'CD', label: 'Democratic Republic of the Congo' },
  { key: 'DK', value: 'DK', label: 'Denmark' },
  { key: 'DJ', value: 'DJ', label: 'Djibouti' },
  { key: 'DM', value: 'DM', label: 'Dominica' },
  { key: 'DO', value: 'DO', label: 'Dominican Republic' },
  { key: 'TL', value: 'TL', label: 'East Timor' },
  { key: 'EC', value: 'EC', label: 'Ecuador' },
  { key: 'EG', value: 'EG', label: 'Egypt' },
  { key: 'SV', value: 'SV', label: 'El Salvador' },
  { key: 'GQ', value: 'GQ', label: 'Equatorial Guinea' },
  { key: 'ER', value: 'ER', label: 'Eritrea' },
  { key: 'EE', value: 'EE', label: 'Estonia' },
  { key: 'ET', value: 'ET', label: 'Ethiopia' },
  { key: 'FK', value: 'FK', label: 'Falkland Islands' },
  { key: 'FO', value: 'FO', label: 'Faroe Islands' },
  { key: 'FJ', value: 'FJ', label: 'Fiji' },
  { key: 'FI', value: 'FI', label: 'Finland' },
  { key: 'FR', value: 'FR', label: 'France' },
  { key: 'GF', value: 'GF', label: 'French Guiana' },
  { key: 'PF', value: 'PF', label: 'French Polynesia' },
  { key: 'TF', value: 'TF', label: 'French Southern Territories' },
  { key: 'GA', value: 'GA', label: 'Gabon' },
  { key: 'GM', value: 'GM', label: 'Gambia' },
  { key: 'GE', value: 'GE', label: 'Georgia' },
  { key: 'DE', value: 'DE', label: 'Germany' },
  { key: 'GH', value: 'GH', label: 'Ghana' },
  { key: 'GI', value: 'GI', label: 'Gibraltar' },
  { key: 'GR', value: 'GR', label: 'Greece' },
  { key: 'GL', value: 'GL', label: 'Greenland' },
  { key: 'GD', value: 'GD', label: 'Grenada' },
  { key: 'GP', value: 'GP', label: 'Guadeloupe' },
  { key: 'GU', value: 'GU', label: 'Guam' },
  { key: 'GT', value: 'GT', label: 'Guatemala' },
  { key: 'GG', value: 'GG', label: 'Guernsey' },
  { key: 'GN', value: 'GN', label: 'Guinea' },
  { key: 'GW', value: 'GW', label: 'Guinea-Bissau' },
  { key: 'GY', value: 'GY', label: 'Guyana' },
  { key: 'HT', value: 'HT', label: 'Haiti' },
  { key: 'HM', value: 'HM', label: 'Heard Island and McDonald Islands' },
  { key: 'HN', value: 'HN', label: 'Honduras' },
  { key: 'HK', value: 'HK', label: 'Hong Kong' },
  { key: 'HU', value: 'HU', label: 'Hungary' },
  { key: 'IS', value: 'IS', label: 'Iceland' },
  { key: 'IN', value: 'IN', label: 'India' },
  { key: 'ID', value: 'ID', label: 'Indonesia' },
  { key: 'IR', value: 'IR', label: 'Iran' },
  { key: 'IQ', value: 'IQ', label: 'Iraq' },
  { key: 'IE', value: 'IE', label: 'Ireland' },
  { key: 'IM', value: 'IM', label: 'Isle of Man' },
  { key: 'IL', value: 'IL', label: 'Israel' },
  { key: 'IT', value: 'IT', label: 'Italy' },
  { key: 'CI', value: 'CI', label: 'Ivory Coast' },
  { key: 'JM', value: 'JM', label: 'Jamaica' },
  { key: 'JP', value: 'JP', label: 'Japan' },
  { key: 'JE', value: 'JE', label: 'Jersey' },
  { key: 'JO', value: 'JO', label: 'Jordan' },
  { key: 'KZ', value: 'KZ', label: 'Kazakhstan' },
  { key: 'KE', value: 'KE', label: 'Kenya' },
  { key: 'KI', value: 'KI', label: 'Kiribati' },
  { key: 'XK', value: 'XK', label: 'Kosovo' },
  { key: 'KW', value: 'KW', label: 'Kuwait' },
  { key: 'KG', value: 'KG', label: 'Kyrgyzstan' },
  { key: 'LA', value: 'LA', label: 'Laos' },
  { key: 'LV', value: 'LV', label: 'Latvia' },
  { key: 'LB', value: 'LB', label: 'Lebanon' },
  { key: 'LS', value: 'LS', label: 'Lesotho' },
  { key: 'LR', value: 'LR', label: 'Liberia' },
  { key: 'LY', value: 'LY', label: 'Libya' },
  { key: 'LI', value: 'LI', label: 'Liechtenstein' },
  { key: 'LT', value: 'LT', label: 'Lithuania' },
  { key: 'LU', value: 'LU', label: 'Luxembourg' },
  { key: 'MO', value: 'MO', label: 'Macao' },
  { key: 'MK', value: 'MK', label: 'Macedonia' },
  { key: 'MG', value: 'MG', label: 'Madagascar' },
  { key: 'MW', value: 'MW', label: 'Malawi' },
  { key: 'MY', value: 'MY', label: 'Malaysia' },
  { key: 'MV', value: 'MV', label: 'Maldives' },
  { key: 'ML', value: 'ML', label: 'Mali' },
  { key: 'MT', value: 'MT', label: 'Malta' },
  { key: 'MH', value: 'MH', label: 'Marshall Islands' },
  { key: 'MQ', value: 'MQ', label: 'Martinique' },
  { key: 'MR', value: 'MR', label: 'Mauritania' },
  { key: 'MU', value: 'MU', label: 'Mauritius' },
  { key: 'YT', value: 'YT', label: 'Mayotte' },
  { key: 'MX', value: 'MX', label: 'Mexico' },
  { key: 'FM', value: 'FM', label: 'Micronesia' },
  { key: 'MD', value: 'MD', label: 'Moldova' },
  { key: 'MC', value: 'MC', label: 'Monaco' },
  { key: 'MN', value: 'MN', label: 'Mongolia' },
  { key: 'ME', value: 'ME', label: 'Montenegro' },
  { key: 'MS', value: 'MS', label: 'Montserrat' },
  { key: 'MA', value: 'MA', label: 'Morocco' },
  { key: 'MZ', value: 'MZ', label: 'Mozambique' },
  { key: 'MM', value: 'MM', label: 'Myanmar' },
  { key: 'NA', value: 'NA', label: 'Namibia' },
  { key: 'NR', value: 'NR', label: 'Nauru' },
  { key: 'NP', value: 'NP', label: 'Nepal' },
  { key: 'NL', value: 'NL', label: 'Netherlands' },
  { key: 'NC', value: 'NC', label: 'New Caledonia' },
  { key: 'NZ', value: 'NZ', label: 'New Zealand' },
  { key: 'NI', value: 'NI', label: 'Nicaragua' },
  { key: 'NE', value: 'NE', label: 'Niger' },
  { key: 'NG', value: 'NG', label: 'Nigeria' },
  { key: 'NU', value: 'NU', label: 'Niue' },
  { key: 'NF', value: 'NF', label: 'Norfolk Island' },
  { key: 'KP', value: 'KP', label: 'North Korea' },
  { key: 'MP', value: 'MP', label: 'Northern Mariana Islands' },
  { key: 'NO', value: 'NO', label: 'Norway' },
  { key: 'OM', value: 'OM', label: 'Oman' },
  { key: 'PK', value: 'PK', label: 'Pakistan' },
  { key: 'PW', value: 'PW', label: 'Palau' },
  { key: 'PS', value: 'PS', label: 'Palestinian Territory' },
  { key: 'PA', value: 'PA', label: 'Panama' },
  { key: 'PG', value: 'PG', label: 'Papua New Guinea' },
  { key: 'PY', value: 'PY', label: 'Paraguay' },
  { key: 'PE', value: 'PE', label: 'Peru' },
  { key: 'PH', value: 'PH', label: 'Philippines' },
  { key: 'PN', value: 'PN', label: 'Pitcairn' },
  { key: 'PL', value: 'PL', label: 'Poland' },
  { key: 'PT', value: 'PT', label: 'Portugal' },
  { key: 'PR', value: 'PR', label: 'Puerto Rico' },
  { key: 'QA', value: 'QA', label: 'Qatar' },
  { key: 'CG', value: 'CG', label: 'Republic of the Congo' },
  { key: 'RE', value: 'RE', label: 'Reunion' },
  { key: 'RO', value: 'RO', label: 'Romania' },
  { key: 'RU', value: 'RU', label: 'Russia' },
  { key: 'RW', value: 'RW', label: 'Rwanda' },
  { key: 'BL', value: 'BL', label: 'Saint Barthelemy' },
  { key: 'SH', value: 'SH', label: 'Saint Helena' },
  { key: 'KN', value: 'KN', label: 'Saint Kitts and Nevis' },
  { key: 'LC', value: 'LC', label: 'Saint Lucia' },
  { key: 'MF', value: 'MF', label: 'Saint Martin' },
  { key: 'PM', value: 'PM', label: 'Saint Pierre and Miquelon' },
  { key: 'VC', value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { key: 'WS', value: 'WS', label: 'Samoa' },
  { key: 'SM', value: 'SM', label: 'San Marino' },
  { key: 'ST', value: 'ST', label: 'Sao Tome and Principe' },
  { key: 'SA', value: 'SA', label: 'Saudi Arabia' },
  { key: 'SN', value: 'SN', label: 'Senegal' },
  { key: 'RS', value: 'RS', label: 'Serbia' },
  { key: 'SC', value: 'SC', label: 'Seychelles' },
  { key: 'SL', value: 'SL', label: 'Sierra Leone' },
  { key: 'SG', value: 'SG', label: 'Singapore' },
  { key: 'SX', value: 'SX', label: 'Sint Maarten' },
  { key: 'SK', value: 'SK', label: 'Slovakia' },
  { key: 'SI', value: 'SI', label: 'Slovenia' },
  { key: 'SB', value: 'SB', label: 'Solomon Islands' },
  { key: 'SO', value: 'SO', label: 'Somalia' },
  { key: 'ZA', value: 'ZA', label: 'South Africa' },
  { key: 'GS', value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { key: 'KR', value: 'KR', label: 'South Korea' },
  { key: 'SS', value: 'SS', label: 'South Sudan' },
  { key: 'ES', value: 'ES', label: 'Spain' },
  { key: 'LK', value: 'LK', label: 'Sri Lanka' },
  { key: 'SD', value: 'SD', label: 'Sudan' },
  { key: 'SR', value: 'SR', label: 'Suriname' },
  { key: 'SJ', value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { key: 'SZ', value: 'SZ', label: 'Swaziland' },
  { key: 'SE', value: 'SE', label: 'Sweden' },
  { key: 'CH', value: 'CH', label: 'Switzerland' },
  { key: 'SY', value: 'SY', label: 'Syria' },
  { key: 'TW', value: 'TW', label: 'Taiwan' },
  { key: 'TJ', value: 'TJ', label: 'Tajikistan' },
  { key: 'TZ', value: 'TZ', label: 'Tanzania' },
  { key: 'TH', value: 'TH', label: 'Thailand' },
  { key: 'TG', value: 'TG', label: 'Togo' },
  { key: 'TK', value: 'TK', label: 'Tokelau' },
  { key: 'TO', value: 'TO', label: 'Tonga' },
  { key: 'TT', value: 'TT', label: 'Trinidad and Tobago' },
  { key: 'TN', value: 'TN', label: 'Tunisia' },
  { key: 'TR', value: 'TR', label: 'Turkey' },
  { key: 'TM', value: 'TM', label: 'Turkmenistan' },
  { key: 'TC', value: 'TC', label: 'Turks and Caicos Islands' },
  { key: 'TV', value: 'TV', label: 'Tuvalu' },
  { key: 'VI', value: 'VI', label: 'U.S. Virgin Islands' },
  { key: 'UG', value: 'UG', label: 'Uganda' },
  { key: 'UA', value: 'UA', label: 'Ukraine' },
  { key: 'AE', value: 'AE', label: 'United Arab Emirates' },
  { key: 'GB', value: 'GB', label: 'United Kingdom' },
  { key: 'US', value: 'US', label: 'United States' },
  { key: 'UM', value: 'UM', label: 'United States Minor Outlying Islands' },
  { key: 'UY', value: 'UY', label: 'Uruguay' },
  { key: 'UZ', value: 'UZ', label: 'Uzbekistan' },
  { key: 'VU', value: 'VU', label: 'Vanuatu' },
  { key: 'VA', value: 'VA', label: 'Vatican' },
  { key: 'VE', value: 'VE', label: 'Venezuela' },
  { key: 'VN', value: 'VN', label: 'Vietnam' },
  { key: 'WF', value: 'WF', label: 'Wallis and Futuna' },
  { key: 'EH', value: 'EH', label: 'Western Sahara' },
  { key: 'YE', value: 'YE', label: 'Yemen' },
  { key: 'ZM', value: 'ZM', label: 'Zambia' },
  { key: 'ZW', value: 'ZW', label: 'Zimbabwe' },
];
