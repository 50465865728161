import styled from 'styled-components';
import { FormGroup } from './style';

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.2px;
  color: #000;
  margin: auto;
  margin-bottom: 7px;
  @media only screen and (max-width: 430px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const SubTitle = styled.h1`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1e0a3c;
  margin: auto;
  margin-bottom: 15px;
  max-width: 390px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
    font-size: 15px;
  }
`;

export const Container = styled.div`
  width: 590px;
  max-width: 100%;
  padding: 15px 0 20px;
  margin: auto;

  img {
    margin: auto;
  }

  ${FormGroup} {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;

    button {
      margin-left: 5px;
      height: 56px;
      width: 151px;
      border-radius: 8px;
      @media screen and (max-width: 430px) {
        width: 100%;
        margin-left: 0;
        height: 50px;
      }
    }

    .error {
      flex: 1 1 100%;
    }
  }

  .phone {
    flex: 1 1 auto;
    margin-bottom: 5px;

    .phone__input {
      position: relative;

      input {
        padding-left: 76px;
      }
    }
  }

  .evfSelectBoxContainer {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 150%;
    color: #1e0a3c;
    border: 1px solid transparent;
    .evfSelectBox__control {
      width: 62px;
      border: 1px solid transparent;
      background-color: #fff;
      height: 46px;
      border-radius: 5px;
      &:hover {
        border-color: ${({ mainColor }) => mainColor || '#7470ef'};
      }
    }
    .evfSelectBox__control .evfSelectBox__value-container {
      padding-left: 13px;
      font-size: 16px;
      line-height: 150%;
      color: #1e0a3c;
    }
    .evfSelectBox__dropdown-indicator {
      padding: 0 7px 0;
    }
    .evfSelectBox__menu {
      width: 155px;
      background: #ffffff;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      border: 0;
      padding: 5px;
    }
    .evfSelectBox__control.evfSelectBox__control--menu-is-open {
      border: 1px solid ${({ mainColor }) => mainColor || '#7470ef'} !important;
    }
  }

  .divider-with-text {
    width: 330px;
    text-align: center;
    border-bottom: 1px solid ${({ mainColor }) => mainColor || '#6965D7'};
    line-height: 0.1em;
    margin: 10px auto 30px;

    span {
      background: #fff;
      padding: 0 15px;
    }
  }

  .send-email {
    margin: auto;
    text-align: center;
    padding: 5px 0;
    width: 100%;
    margin-top: 18px;
    .send-email-link {
      width: auto;
      background: no-repeat;
      padding: 0;
      height: auto;
      color: ${({ mainColor }) => mainColor || '#7470ef'};
    }
  }

  input {
    &:focus,
    &:hover {
      border-color: ${({ mainColor }) => mainColor || '#6965D7'};
    }
  }

  button {
    &,
    &:focus,
    &:hover {
      background: ${({ mainColor }) => mainColor || '#6965D7'};
    }
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 17px;
  }

  .download__links {
    @media only screen and (max-width: 430px) {
      display: flex;
      svg {
        max-width: 100%;
      }
    }
  }
`;

export const CompleteContainer = styled.div`
  width: 590px;
  max-width: 100%;
  padding: 88px 0 90px;
  margin: auto;

  img {
    margin: auto;
  }

  @media only screen and (max-width: 767px) {
    padding: 78px 0 60px;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 100%;
  padding: 9px 10px;
  cursor: pointer;
  border-radius: 3px;
  &.select__option--is-focused,
  &:hover {
    background: rgba(81, 88, 207, 0.1);
    color: #5158cf;
    font-weight: bold;
  }
`;
export const OptionLabel = styled.div``;
export const OptionLabelCode = styled.div``;
