export const Types = {
  TAGS_WORKOUT_GET_LIST_REQUEST: 'TAGS_WORKOUT_GET_LIST_REQUEST',
  TAGS_WORKOUT_GET_LIST_SUCCESS: 'TAGS_WORKOUT_GET_LIST_SUCCESS',
  TAGS_WORKOUT_GET_LIST_FAILED: 'TAGS_WORKOUT_GET_LIST_FAILED',
  TAGS_WORKOUT_GET_MOST_RECENT_REQUEST: 'TAGS_WORKOUT_GET_MOST_RECENT_REQUEST',
  TAGS_WORKOUT_GET_MOST_RECENT_SUCCESS: 'TAGS_WORKOUT_GET_MOST_RECENT_SUCCESS',
  TAGS_WORKOUT_GET_MOST_RECENT_FAILED: 'TAGS_WORKOUT_GET_MOST_RECENT_FAILED',
  TAGS_WORKOUT_GET_MOST_USED_REQUEST: 'TAGS_WORKOUT_GET_MOST_USED_REQUEST',
  TAGS_WORKOUT_GET_MOST_USED_SUCCESS: 'TAGS_WORKOUT_GET_MOST_USED_SUCCESS',
  TAGS_WORKOUT_GET_MOST_USED_FAILED: 'TAGS_WORKOUT_GET_MOST_USED_FAILED',
  TAGS_WORKOUT_CREATE_NEW_REQUEST: 'TAGS_WORKOUT_CREATE_NEW_REQUEST',
  TAGS_WORKOUT_CREATE_NEW_SUCCESS: 'TAGS_WORKOUT_CREATE_NEW_SUCCESS',
  TAGS_WORKOUT_CREATE_NEW_FAILED: 'TAGS_WORKOUT_CREATE_NEW_FAILED',
  TAGS_WORKOUT_EDIT_REQUEST: 'TAGS_WORKOUT_EDIT_REQUEST',
  TAGS_WORKOUT_EDIT_SUCCESS: 'TAGS_WORKOUT_EDIT_SUCCESS',
  TAGS_WORKOUT_EDIT_FAILED: 'TAGS_WORKOUT_EDIT_FAILED',
  TAGS_WORKOUT_DELETE_REQUEST: 'TAGS_WORKOUT_DELETE_REQUEST',
  TAGS_WORKOUT_DELETE_SUCCESS: 'TAGS_WORKOUT_DELETE_SUCCESS',
  TAGS_WORKOUT_DELETE_FAILED: 'TAGS_WORKOUT_DELETE_FAILED',
  TAGS_WORKOUT_CHANGE_QUERY_PARAMS: 'TAGS_WORKOUT_CHANGE_QUERY_PARAMS',
  TAGS_WORKOUT_RESET_QUERY_PARAMS: 'TAGS_WORKOUT_RESET_QUERY_PARAMS',
};
