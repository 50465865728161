// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { get, isEmpty, round } from 'lodash';
import classNames from 'classnames';

import { convertMinToFromHourMin } from 'utils/commonFunction';

// Constants
import { LEGEND_STAGE_ORDER, SLEEP_COLOR, SLEEP_STAGE_KEYS, SLEEP_STATES, SLEEP_VIEW } from './constants';

// Helpers
import { getTimeLabel } from 'components/BodyMetricChartNew/chartHelper';
import { getStartEndSleeps } from './helpers';

// Styles
import { SleepStates } from './SleepStyle';
import * as S from '../../styles';

export const RenderTime = ({ minutes }) => {
  const { min, hour } = convertMinToFromHourMin(minutes);
  return (
    <p className="tooltip-value">
      {hour > 0 && (
        <S.UnitValue>
          {hour}
          <S.UnitStyle> h </S.UnitStyle>
        </S.UnitValue>
      )}
      {min > 0 && (
        <S.UnitValue>
          {round(min)}
          <S.UnitStyle> min</S.UnitStyle>
        </S.UnitValue>
      )}
    </p>
  );
};

export const SleepState = ({ minutes, type }) => {
  const { min, hour } = convertMinToFromHourMin(minutes);

  const renderValueUnit = (value, unit) =>
    value > 0 && (
      <>
        {round(value)}
        <span className="unit"> {unit}</span>
      </>
    );

  return (
    <div className="sleep-state">
      <div className="sleep-state-left">
        <div className="sleep-state-color" style={{ backgroundColor: SLEEP_COLOR[type] }} />
        <span className="sleep-state-title">{SLEEP_STATES[type]}</span>
      </div>
      <div className="sleep-state-time">
        {renderValueUnit(hour, ' h ')}
        {renderValueUnit(min, ' min')}
      </div>
    </div>
  );
};

const HourlyChartTooltip = props => {
  const [isMoveLeft, setIsMoveLeft] = useState(false);

  const { dataPayload, sleepSettings, payloadTooltip } = props;
  const { widthCurrentTarget } = payloadTooltip || {};

  const { viewBy } = sleepSettings;
  const isDurationView = viewBy === SLEEP_VIEW.DURATION;
  const { dataType = [], grouped_sleep_in_day } = !isEmpty(dataPayload)
    ? dataPayload
    : get(props, 'payload.[0].payload', {});

  const label = useMemo(() => getTimeLabel(dataPayload || {}), [dataPayload]);

  const finalDataType = useMemo(() => {
    const newTypes = [...dataType];
    const data = [];

    const resume = newTypes.reduce((obj, item) => {
      const stage = item.type;
      if (obj[stage]) {
        obj[stage].time += item.time;
      } else {
        obj[stage] = { ...item, type: stage };
      }
      return { ...obj };
    }, {});

    LEGEND_STAGE_ORDER.forEach(key => {
      if (resume[key]) {
        data.push(resume[key]);
      }
    });
    return data;
  }, [dataType]);

  useEffect(() => {
    const checkElementOutsideScreen = element => {
      const rect = element.getBoundingClientRect();
      if (rect) {
        const WIDTH_BUFFER_LEFT = 50;
        const value = window.innerWidth - WIDTH_BUFFER_LEFT < rect.right;
        setIsMoveLeft(value);
      }
    };
    const elementSleepTooltipContent = document.getElementById('sleep-tooltip-content');
    if (elementSleepTooltipContent) {
      checkElementOutsideScreen(elementSleepTooltipContent);
    }
  }, [dataPayload && dataPayload._id]);

  const renderSleepState = ({ type, time }, index) => {
    return <SleepState key={[type, index].join('-')} minutes={time} type={type} />;
  };

  const getValueTime = () => {
    if (isDurationView) {
      const { time, type } = dataType[0] || { time: 0, type: '' };
      const total = type === SLEEP_STAGE_KEYS.in_bed ? time : dataType.reduce((tt, item) => tt + item.time, 0);
      return <RenderTime minutes={total} />;
    }

    const { startMM, endMM } = getStartEndSleeps(grouped_sleep_in_day);

    if (!startMM) return null;

    return (
      <p className="tooltip-value">
        <S.UnitValue>{startMM.format('h:mm A')}</S.UnitValue>
        <span className="divider" />
        <S.UnitValue>{endMM.format('h:mm A')}</S.UnitValue>
      </p>
    );
  };

  return (
    <S.TooltipWrapper
      className={classNames('sleep-tooltip normal-hourly')}
      isOutsideRightNormalHourly={isMoveLeft}
      widthCurrentTarget={widthCurrentTarget}
      id="sleep-tooltip-content"
    >
      <S.RangeTimeLabel>{label}</S.RangeTimeLabel>
      {getValueTime()}
      <SleepStates>{finalDataType.map(renderSleepState)}</SleepStates>
    </S.TooltipWrapper>
  );
};

export default HourlyChartTooltip;
