import React from 'react';
import * as S from './style';
import { ReactComponent as DownIcon } from 'assets/icons/mobile-preview-down-button.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/mobile-preview-clock.svg';
import { ReactComponent as AgainIcon } from 'assets/icons/mobile-preview-exercises-screen-again-icon.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/mobile-preview-exercises-screen-message-icon.svg';
import { ReactComponent as OptionIcon } from 'assets/icons/mobile-preview-exercises-screen-option-icon.svg';
import { ReactComponent as Breaker } from 'assets/icons/mobile-preview-breaker.svg';
import { ReactComponent as DurationDots } from 'assets/icons/duration-dots.svg';
import { ReactComponent as BackgroundImage } from 'assets/images/mobile-preview-background.svg';
import { ReactComponent as PlayVideoIcon } from 'assets/icons/play_video_color_theme.svg';
import VideoPlayer from 'assets/images/mobile-preview-video-player.png';
import CustomBrandingStatusBar from 'shared/CustomBrandingStatusBar';

export default props => {
  const TickComponent = ({ fill }) => (
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="active-checkbox"
      style={{ transform: 'translate(0px, 1px)' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.478271 6.87264C0.478271 10.4599 3.38378 13.3654 6.97102 13.3654C10.5583 13.3654 13.4638 10.4599 13.4638 6.87264C13.4638 3.28539 10.5583 0.379883 6.97102 0.379883C3.38378 0.379883 0.478271 3.28539 0.478271 6.87264ZM9.21117 4.74626C9.40596 4.55147 9.71436 4.55147 9.90914 4.74626C10.1039 4.94104 10.1039 5.26568 9.92537 5.46046L6.46798 8.99901C6.2732 9.19379 5.9648 9.19379 5.77001 8.99901L4.0332 7.2135C3.83842 7.01872 3.83842 6.69408 4.0332 6.4993C4.22798 6.30452 4.53639 6.30452 4.73117 6.4993L6.11088 7.9277L9.21117 4.74626Z"
        fill={fill}
      />
    </svg>
  );
  return (
    <S.Wrapper {...props}>
      <BackgroundImage className="background" />
      <CustomBrandingStatusBar light />
      <S.MobileNavBar>
        <DownIcon />
        <S.HeaderTitle>
          <p>
            Total Duration <span>10:23</span>
          </p>
          <DurationDots />
        </S.HeaderTitle>
        <ClockIcon />
      </S.MobileNavBar>
      <S.Header></S.Header>
      <S.Content>
        <S.WrapperVideoPlayer>
          <img src={VideoPlayer} alt="video-player" />
          <PlayVideoIcon fill={props.primaryColor} />
        </S.WrapperVideoPlayer>
        <S.InfoContainer>
          <div>
            <h4>Mountain Climbers</h4>
            <p>Tempo 2-0-3-0 | Rest: 30s</p>
          </div>
          <div className="icons-container">
            <AgainIcon />
            <MessageIcon />
            <OptionIcon />
          </div>
        </S.InfoContainer>
        <Breaker />
        <p className="note">This is the note for your workout. </p>
        <S.SetTable primaryColor={props.primaryColor} secondaryColor={props.secondaryColor}>
          <tr className="table-header">
            <th>SETS</th>
            <th>LB</th>
            <th>REPS</th>
            <th></th>
          </tr>
          <tr className="table-content done">
            <td>1</td>
            <td>40</td>
            <td>2</td>
            <td>
              <TickComponent
                fill={
                  props.primaryColor.toLowerCase() === '#7470ef' && props.secondaryColor.toLowerCase() === '#4fdfac'
                    ? props.primaryColor
                    : props.secondaryColor
                }
              />
            </td>
          </tr>
          <tr className="table-content active">
            <td>2</td>
            <td>45</td>
            <td>2</td>
            <td>
              <TickComponent fill="#E6E6E6" />
            </td>
          </tr>
          <tr className="table-content">
            <td>3</td>
            <td>45</td>
            <td>2</td>
            <td>
              <TickComponent fill="#E6E6E6" />
            </td>
          </tr>
        </S.SetTable>
        <S.MarkAllText>Mark All</S.MarkAllText>
      </S.Content>
    </S.Wrapper>
  );
};
