import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const SupersetIcon = styled.div`
  background-image: url(${CDN_URL}/images/link_set.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  transform: rotate(-45deg);
  position: absolute;
  top: -6px;
  right: -12px;
  background-size: contain;
  visibility: hidden;
`;

export const Wrapper = styled.div`
  padding: 0px 16.6px;

  .supersetItem {
    padding: 9px 0px;
    display: flex;
    align-items: center;
    position: relative;

    &__icon {
      margin-right: 10px;
    }

    &__title {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &--unkown {
        font-style: italic;
        opacity: 0.4;
      }
    }
  }

  :not(:first-child) {
    .supersetItem {
      border-top: 1px solid #97979720;
    }

    ${SupersetIcon} {
      visibility: visible;
    }
  }
`;
