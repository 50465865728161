/**
 * @flow
 */

import { connect } from 'react-redux';
import type { State, Dispatch } from 'types/redux';
import Component from './component';
import { updateProfileThenUploadAvatar } from 'redux/client/client.actionCreators'
const mapStateToProps = (state: State) => {
  const { isModalOpen, modal, rootReducer } = state;
  return {
    isModalOpen,
    modal,
    clientId: rootReducer.client.selected
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: State) => {
  return {
    dispatch,
    updateTimeZone: (
      timezone: string,
      clientId: string
    ) => {
      let params = {
        timezone
      };
      dispatch(updateProfileThenUploadAvatar(params, null, clientId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
