import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Image, Button } from 'semantic-ui-react';
import { toggleModal } from 'actions/modal';
import * as S from './style';
import AutoflowInAppMessage from '../InAppMessage';
import Announcement from '../Announcement';
import './style.scss';
import { CDN_URL, DATE_FORMAT } from 'constants/commonData';

function Popup(props) {
  const { isModalOpen, date } = props;

  return (
    <Modal
      open={isModalOpen}
      closeIcon={
        <Button className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-auto-message-create-new-modal"
    >
      <Modal.Header>
        <h1>Schedule New Auto Message</h1>
        <h3>Please select your channel</h3>
      </Modal.Header>
      <Modal.Content>
        <S.Grid>
          <S.Item onClick={() => props.toggleModal(true, <Announcement date={date} />)}>
            <div className="img-container">
              <Image src={`${CDN_URL}/images/annoucement_logo.svg`} />
            </div>
            <div className="text">
              <div className="name">Announcement</div>
              <div>Broadcast a full-day announcement to clients</div>
            </div>
          </S.Item>
          <S.Item onClick={() => props.toggleModal(true, <AutoflowInAppMessage day={date.format(DATE_FORMAT)} />)}>
            <div className="img-container">
              <Image src={`${CDN_URL}/images/in-app_logo.svg`} />
            </div>
            <div className="text">
              <div className="name">In-app</div>
              <div>Engage your client through our in-app messaging</div>
            </div>
          </S.Item>
        </S.Grid>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => ({
  isModalOpen: state.isModalOpen,
});

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatch)(Popup);
