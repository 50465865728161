import styled, { css } from 'styled-components';
import LoadingIndicator from 'shared/LoadingIndicator';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  color: #7b7e91;
`;

export const DietaryInfoWrapper = styled.div`
  display: flex;
`;

export const Title = styled.div`
  ${baseText}
  font-size: 13px;
  line-height: 20px;
  user-select: none;
  flex-shrink: 0;
  width: 96px;
  height: 90px;
  display: flex;
  align-items: center;
`;

export const DietaryList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0;
  flex-wrap: wrap;

  ${props =>
    props.isEdit &&
    css`
      gap: 10px;
    `}
`;

export const DietaryItem = styled.div`
  ${baseText}
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
  position: relative;
  width: 113.33px;
  height: 90px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    path {
      fill: #a3a3b5;
    }
  }
  ${props =>
    props.isEdit &&
    css`
      width: 108.5px;
      border: 1px dashed #dadbea;
    `}
`;

export const IconItem = styled.div`
  margin-bottom: 3px;
  width: 48px;
  height: 48px;
  border: 1px solid #f0f0f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const AddDietary = styled.div`
  cursor: pointer;
  width: 108.5px;
  height: 90px;
  border: 1px dashed #dadbea;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  svg {
    width: 26px;
    height: 26px;
    path {
      fill: #7b7e91;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    svg {
      path {
        fill: #5158cf;
      }
    }
  }
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      width: 169px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        ${baseText}
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #a3a3b5;
        margin-top: 7px;
      }
    `}
`;

export const DropdownList = styled.div`
  z-index: 1000;
  position: absolute;
  left: 0;
  bottom: calc(100% + 8px);
  padding: 15px;
  width: 437px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 8px 0px #26262633;
  -moz-box-shadow: 0px 2px 8px 0px #26262633;
  box-shadow: 0px 2px 8px 0px #26262633;
`;

export const DropdownTitle = styled.p`
  ${baseText}
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
  margin-bottom: 10px;
`;

export const DietarySelectList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const DietarySelectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0 11.5px;
  height: 36px;
  border-radius: 100px;
  background-color: #f8f8f8;
  border: 0.5px solid transparent;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
  .icon-dietary {
    display: block;
  }
  .icon-dietary-hover {
    display: none;
  }
  &:hover {
    background-color: #f0f1ff;
    .icon-dietary {
      display: none;
    }
    .icon-dietary-hover {
      display: block;
    }
  }
  span {
    ${baseText}
    color: #202353;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  ${props =>
    props.active &&
    css`
      border-color: #5158cf;
      background-color: #f0f1ff;
      .icon-dietary {
        display: none;
      }
      .icon-dietary-hover {
        display: block;
      }
    `}
`;

export const Loading = styled(LoadingIndicator)`
  padding-top: 0;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  .ui.loader:before {
    border-color: #ededed !important;
  }
`;
