import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Modal, Image, Button } from 'semantic-ui-react';
import { List, Tabs } from './style';
import Icons from 'shared/Icons';
import ConfirmModal from 'shared/ConfirmModal';
import './style.scss';
import { CDN_URL, PROGRAM_COMPLETED } from 'constants/commonData';

const DATE_FORMAT = 'M/D/YY';

class ClientViewPrograms extends React.PureComponent {
  state = {
    deleted: false,
    currentTab: 'active',
  };

  onClose = () => {
    const { toggleModal, changeCalendarStartDate } = this.props;
    const { deleted } = this.state;

    if (deleted) {
      changeCalendarStartDate();
    }

    toggleModal(false);
  };

  onDeleteAssignedProgam = assignedId => {
    const { toggleConfirmModal, removeAssignedProgram } = this.props;
    const { deleted } = this.state;

    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove program"
        content="All workouts in this program assigned to today and in the future will be removed."
        onConfirm={() => {
          removeAssignedProgram(assignedId).then(() => {
            if (!deleted) {
              this.setState({ deleted: true });
            }
          });
        }}
        onDeny={() => {}}
        confirmButtonTitle="Remove"
        cancelButtonTitle="Cancel"
        headerIcon={`${CDN_URL}/images/confirm_remove.svg`}
      />,
    );
  };

  renderPrograms = () => {
    const { programs, toggleModal, date_format } = this.props;
    const { currentTab } = this.state;
    const splitDate = date_format && date_format.split('/');
    const dateFormat = splitDate
      ? `${splitDate[0].slice(1)}/${splitDate[1].slice(1)}/${splitDate[2].slice(2)}`
      : DATE_FORMAT;
    let renderedPrograms = [];

    if (currentTab === 'active') {
      renderedPrograms = _.filter(programs, item => item.status !== PROGRAM_COMPLETED);
    } else {
      renderedPrograms = _.filter(programs, item => item.status === PROGRAM_COMPLETED);
    }

    return (
      <List>
        <table>
          <tbody>
            {_.map(renderedPrograms, item => (
              <tr key={item._id}>
                <td className="title">
                  <div className="name">
                    <Link
                      to={`/home/program/${item.program._id}/calendar`}
                      onClick={event => {
                        if (event.metaKey || event.ctrlKey || event.shiftKey) {
                          return false;
                        }

                        toggleModal(false);
                      }}
                    >
                      {item.program.title}
                    </Link>
                    {item.status !== PROGRAM_COMPLETED && item.program.active_sync ? (
                      <span className="live-sync">Live Sync</span>
                    ) : null}
                  </div>
                  <div className="date">
                    {item.startDate.format(dateFormat)} - {item.endDate.utc().format(dateFormat)}
                  </div>
                </td>
                <td className="action">
                  {currentTab === 'active' && (
                    <Icons
                      className="icon"
                      name="delete"
                      width={16}
                      height={16}
                      onClick={() => this.onDeleteAssignedProgam(item._id)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </List>
    );
  };

  renderTabs = () => {
    const { currentTab } = this.state;
    const { programs } = this.props;
    let copyList = programs.slice();
    let competedPrograms = _.remove(copyList, item => item.status === PROGRAM_COMPLETED);

    return (
      <Tabs className="tabs">
        <div
          className={`tab${currentTab === 'active' ? ' active' : ''}`}
          onClick={() => this.setState({ currentTab: 'active' })}
        >
          <div className="name">Active</div>
          <div className="count">({copyList.length})</div>
        </div>
        <div
          className={`tab${currentTab === 'completed' ? ' active' : ''}`}
          onClick={() => this.setState({ currentTab: 'completed' })}
        >
          <div className="name">Completed</div>
          <div className="count">({competedPrograms.length})</div>
        </div>
      </Tabs>
    );
  };

  render() {
    const { isModalOpen } = this.props;

    return (
      <Modal open={isModalOpen} className="assigned-programs-modal" closeOnDimmerClick={false} onClose={this.onClose}>
        <Modal.Header>
          <Button onClick={this.onClose} className="close-button" type="button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </Button>
          <span>Assigned Programs</span>
        </Modal.Header>
        <Modal.Content>
          {this.renderTabs()}
          {this.renderPrograms()}
        </Modal.Content>
      </Modal>
    );
  }
}

export default ClientViewPrograms;
