/**
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';

import { toggleModal } from 'actions/modal';

import AutoflowFallback from './components/AutoflowFallback';
import ProgramFallback from './components/ProgramFallback';
import TeammatesFallBack from './components/TeammatesFallBack';
import FoodJournalsFallBack from './components/FoodJournalsFallBack';
import SegmentFallBack from './components/SegmentFallBack';
import BrandingFallBack from './components/BrandingFallBack';
import StudioFallBack from './components/StudioFallBack/';
import PackageFallBack from './components/PackageFallBack/';
import OnboardingFlowFallback from './components/OnboardingFlowFallback';
import CustomMealPlanFallBack from './components/CustomMealPlanFallBack';
import { isOnwer } from 'utils/validations';

const getFallbackComponent = ({ pathName, permission, ...rest }) => {
  const path = Array.isArray(pathName) ? pathName[0] : pathName;
  switch (path) {
    case 'program':
      return <ProgramFallback />;
    case 'autoflow':
      return <AutoflowFallback {...rest} />;
    case 'onboarding_flow':
      return <OnboardingFlowFallback {...rest} />;
    case 'food_journal':
      return <FoodJournalsFallBack />;
    case 'segment':
      return <SegmentFallBack />;
    case 'team':
      return <TeammatesFallBack />;
    case 'branding':
      return <BrandingFallBack {...rest} />;
    case 'studio_program':
    case 'studio_resource_collection':
    case 'on_demand_workout':
      return <StudioFallBack {...rest} permission={permission} pathName={pathName} />;
    case 'explore_payment':
      return <PackageFallBack {...rest} />;
    case 'meal_plan':
      return <CustomMealPlanFallBack {...rest} permission={permission} />;
    case 'recipe':
      return <CustomMealPlanFallBack {...rest} startIndex={1} permission={permission} />;
    case 'ingredient':
      return <CustomMealPlanFallBack {...rest} startIndex={2} permission={permission} />;
    default:
      return <></>;
  }
};

const isHasPermission = (pathName, permission) => {
  if (Array.isArray(pathName)) {
    return pathName.findIndex(path => permission[path]) !== -1;
  }

  if (typeof pathName === 'string') {
    // Check permission for "ingredient" by key "recipe" because the same basic plan
    if (pathName === 'ingredient' || pathName === 'client_meal_plan') {
      return permission['recipe'];
    }

    return permission[pathName];
  }

  return false;
};

function UpgradePath({ permission, children, pathName, isMember, user, fallback, ...rest }) {
  if (!pathName) {
    return children ? children : null;
  }
  if (!permission.isInited) {
    return <></>;
  }
  if (!isHasPermission(pathName, permission) && (!isMember || (user && isMember && !isOnwer(user)))) {
    const params = {
      pathName,
      permission,
      user,
      ...rest,
    };
    if (pathName === 'autoflow') {
      const index = get(children, 'props.location.state.index', 0);
      params.startIndex = index;
    }
    return fallback ? fallback : getFallbackComponent(params);
  }
  return children ? children : null;
}

const mapStateToProps = (state: State, ownProps: Object) => {
  const { rootReducer, user } = state;
  return {
    permission: rootReducer.permission,
    user,
    paymentOverdue: rootReducer.pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: rootReducer.pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: rootReducer.pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    paymentWhiteLabelOverdue: rootReducer.pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: State) => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpgradePath);
