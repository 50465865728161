import get from 'lodash/get';
import { fromJS } from 'immutable';
import { Types } from './actions';

const INITIAL_STATE = fromJS({
  workingPackage: {
    name: '',
    cover_image: null,
    headline: '',
    description: '',
    product: null,
    products: [],
    total_offered: 0,
    has_survey_list: false,
    trainer: null,
  },
  isEditMode: true,
  isSubmitting: false,
  isError: false,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.PACKAGE_DETAIL_ADD_NEW:
      return state.merge({ workingPackage: payload.data, isEditMode: true });
    case Types.PACKAGE_DETAIL_GET_DATA: {
      const workingPackage = state.get('workingPackage').toJS();
      return state.merge({
        workingPackage: fromJS({
          ...workingPackage,
          ...payload.data,
        }),
        isEditMode: payload.data.is_edit_mode,
        isSubmitting: false,
      });
    }
    case Types.PACKAGE_DETAIL_TOGGLE_EDIT_MODE:
      return state.merge({ isEditMode: payload.data, isSubmitting: false });
    case Types.PACKAGE_DETAIL_STARTING_SUBMIT:
      return state.merge({ isSubmitting: true });
    case Types.PACKAGE_DETAIL_END_SUBMIT:
      return state.merge({ isSubmitting: false });
    case Types.PACKAGE_DETAIL_RESET:
      return state.merge({
        workingPackage: {
          name: '',
          cover_image: null,
          headline: '',
          description: '',
          product: null,
          products: [],
          total_offered: 0,
        },
      });
    case Types.PACKAGE_SETTINGS_UPDATE_REQUEST:
      return state.merge({ isSubmitting: true });
    case Types.PACKAGE_SETTINGS_UPDATE_SUCCESS: {
      return state.merge({
        workingPackage: {
          ...state.workingPackage,
          ...payload,
          name: payload.invoice_display_name,
        },
        isSubmitting: false,
      });
    }
    case Types.PACKAGE_SETTINGS_UPDATE_FAILED: {
      const workingPackage = state.get('workingPackage').toJS();
      const totalSold = get(payload, 'additionalData.total_sold', 0);
      const totalOffered = get(payload, 'additionalData.total_offered', 0);
      return state.merge({
        isSubmitting: false,
        isError: true,
        workingPackage: fromJS({
          ...workingPackage,
          ...(!!totalSold && { total_sold: totalSold }),
          ...(!!totalOffered && { total_offered: totalOffered }),
        }),
      });
    }

    case Types.PACKAGE_DETAIL_UPDATE_TOTAL_OFFER: {
      const workingPackage = state.get('workingPackage').toJS();
      const totalOffered = payload.totalOffered + workingPackage.total_offered || 0;
      const packagePurchaseOption = {
        ...get(workingPackage, 'package_purchase_option', {}),
        has_offered_package_wishlist: !!totalOffered,
      };

      return state.merge({
        workingPackage: fromJS({
          ...workingPackage,
          total_offered: totalOffered,
          package_purchase_option: packagePurchaseOption,
        }),
      });
    }
    case Types.PACKAGE_DETAIL_UPDATE_SURVEY_FLAG: {
      const workingPackage = state.get('workingPackage').toJS();
      return state.merge({
        workingPackage: fromJS({
          ...workingPackage,
          ...payload.data,
        }),
      });
    }
    default:
      return state;
  }
};
