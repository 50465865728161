import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { verifyUnsubscribeEmailInfo } from 'actions/client';
import UnsubscribeEmailComponent from 'components/UnsubscribeEmail/component';

const mapStateToProps = state => {
  const {
    user,
    isConfirmModalOpen,
    confirmModal,
    isModalOpen,
    modal
  } = state;

  return {
    user,
    isConfirmModalOpen,
    confirmModal,
    isModalOpen,
    modal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    verifyUnsubscribeEmailInfo: bindActionCreators(verifyUnsubscribeEmailInfo, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeEmailComponent);
