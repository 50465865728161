import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import styled, { css } from 'styled-components';

const baseText = `
    font-family: Open Sans;
    font-style: normal;
    color: #202353;
`;

export const CustomModal = styled(Modal)`
  &.ui.modal.welcome-form-questions-modal {
    width: 780px;
    height: calc(100% - 136px);
    @media only screen and (max-height: 768px) {
      height: calc(100% - 20px);
    }
    .close-button {
      top: -12px;
      right: -12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  &.ui.modal.welcome-form-questions-modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: unset !important;
    background-color: #fff;
  }
  &.ui.modal.welcome-form-questions-modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal.welcome-form-questions-modal > .header {
    padding: 24px;
  }
  &.ui.modal.welcome-form-questions-modal > .content {
    height: calc(100% - 129px);
    padding: 0 8px 0 24px;
    background: #f9f9f9;
  }
  &.ui.modal.welcome-form-questions-modal > .actions {
    border-top: unset;
    box-shadow: 0px -4px 16px 0px rgba(107, 111, 183, 0.1);
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .action-duplicate-form {
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
      path {
        fill: #5e59ff;
      }
    }
  }
  .__react_component_tooltip.app-tooltip.onboarding-form-duplicate {
    padding: 15px;
    background-color: #2d2e2d !important;
    ${baseText}
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    &.place-top {
      margin-top: -8px;
      &::after {
        bottom: -7px;
        border-top-color: #2d2e2d;
      }
    }
  }
`;

export const Title = styled.div`
  ${baseText}
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
`;

export const FormQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: auto;
  height: 100%;
  padding: 24px 12px 24px 0;
  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    color: #d9d9d9 !important;
  }
  ::-webkit-scrollbar-track {
    margin: 24px 0;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 8px;
`;

export const NumberOfQuestion = styled.div`
  ${baseText}
  width: 26px;
  height: 27px;
  color: #a1a1a1;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 4px 12px 0px rgba(38, 38, 38, 0.05);
  flex: 1;
  .welcome-form-toggle {
    min-width: 32px;
    min-height: 18px;
    position: absolute;
    top: 16px;
    right: 16px;
    label {
      min-width: 32px;
      min-height: 23px;
    }
    input ~ label::before {
      width: 32px;
      height: 18px;
    }
    input:checked ~ label::before,
    input:checked:focus ~ label::before {
      background-color: #5559ff;
    }
    input ~ label::after {
      width: 14px;
      height: 14px;
      top: 40%;
    }
    input:checked ~ label::after,
    input:checked:focus ~ label::after {
      left: calc(32px - 16px);
    }
  }
  .__react_component_tooltip.welcome-form-tooltip {
    background-color: #151619;
    padding: 15px;
  }
  .__react_component_tooltip.app-tooltip.place-top.welcome-form-tooltip:after {
    bottom: -7px;
  }
`;

export const TooltipContent = styled.p`
  ${baseText}
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormTitle = styled.div`
  ${baseText}
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  .form-title__trigger-applied {
    margin-left: 5px;
  }
  ${props =>
    props.isRequired &&
    css`
      width: calc(100% - 67px);
    `}
`;

export const FormSubTitle = styled.div`
  ${baseText}
  width: 100%;
  margin-top: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: rgb(32, 35, 83, 0.6);
  ${props =>
    props.isRequired &&
    css`
      width: calc(100% - 67px);
    `}
`;

export const FormType = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  ${props =>
    props.isSignature &&
    css`
      gap: 16px;
    `}
`;

export const TypeRadio = styled.div`
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  height: 32px;
`;

export const ContentRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16px;
  column-gap: 8px;
  padding-right: 16px;
  .welcome-form-radio {
    min-width: 16px !important;
    min-height: 16px !important;
    line-height: 16px !important;
    input {
      width: 16px !important;
      height: 16px !important;
    }
    input.hidden + label {
      cursor: default !important;
    }
    label::before {
      width: 16px !important;
      height: 16px !important;
      top: 0 !important;
      border: 1px solid #e9e9e9 !important;
    }
    label::after {
      width: 16px !important;
      height: 16px !important;
      top: 0 !important;
    }
  }
  .ui.radio.checkbox.welcome-form-radio input:focus ~ label:before {
    border-color: #e9e9e9 !important;
  }
`;

export const TypeCheckbox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  height: 32px;
`;

export const ContentCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  height: 16px;
  padding-right: 16px;
  .welcome-form-checkbox {
    min-height: 16px !important;
    line-height: 16px !important;
    min-width: 16px !important;
    input {
      width: 16px !important;
      height: 16px !important;
      :focus {
        border: 1px solid #e9e9e9 !important;
      }
    }
    input.hidden + label {
      cursor: default !important;
    }
    label::before {
      width: 16px !important;
      height: 16px !important;
      border-radius: 2px !important;
      border: 1px solid #e9e9e9 !important;
    }
  }
`;

export const TypeInbox = styled.div`
  height: 40px;
  .welcome-form-input {
    height: 100%;
    width: 310px !important;
    &.ui.input > input:hover,
    &.ui.input > input:focus {
      border-color: #e8e8e8 !important;
      cursor: default;
    }
    input {
      padding: 12px 16px !important;
      border: 1px solid #e8e8e8 !important;
      color: #202353;
      font-family: 'Open Sans' !important;
      font-size: 13px !important;
      font-weight: 400;
      line-height: 120% !important;
      ::-webkit-input-placeholder {
        color: #9ea0b2 !important;
      }
    }
  }
`;

export const TypeArea = styled.div`
  height: 129px;
  .welcome-form-textarea {
    height: 100%;
    width: 331px !important;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #dadfea;
    background: #fff;
    color: #9ea0b2;
    font-size: 13px;
    font-weight: 400;
    line-height: 120%;
    resize: none;
    ::-webkit-input-placeholder {
      color: #9ea0b2 !important;
    }
    :focus,
    :focus-visible,
    :hover {
      border-color: #e8e8e8 !important;
      cursor: default;
      outline: none;
    }
  }
`;

export const SignatureBox = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 8px;
  border: 1px dashed #dedede;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignaturePlaceholder = styled.p`
  ${baseText}
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: rgb(32, 35, 83, 0.5);
`;

export const SignatureSign = styled.div`
  width: 100%;
  height: 35px;
  padding: 6px 0;
  border-bottom: 1px solid rgba(32, 35, 83, 0.5);
  ${baseText}
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.5;
`;

export const TypeDatetime = styled.div`
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(32, 35, 83, 0.5);
`;

export const DatetimeWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const DateContent = styled.div`
  width: fit-content;
  ${baseText}
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
  color: rgb(32, 35, 83, 0.5);
`;

export const DateSeparator = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const TypeMetric = styled.div`
  display: flex;
  height: 40px;
`;

export const BorderBox = styled.div`
  height: 100%;
  width: 133px;
  background-color: #fff;
  border: 1px solid #dadfea;
  border-radius: 4px 0 0 4px;
  flex: 1;
  flex-grow: 1;
  ${props =>
    props.isInOrFt &&
    css`
      width: 94px;
    `}
  ${props =>
    props.isInOrFt &&
    props.isLeft &&
    css`
      border-radius: unset;
    `}
`;

export const UnitWrapper = styled.div`
  height: 100%;
  width: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fafbfc;
  flex: 0 0 auto;
  padding: 9px 12px;
  border: 1px solid #dadfea;
  border-radius: 0px 4px 4px 0px;
  border-left: unset;
  ${props =>
    props.isInOrFt &&
    css`
      border-radius: unset;
      border-right: unset;
    `}
`;

export const UnitText = styled.div`
  ${baseText}
  color: #000;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
`;

export const Content = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 400;
  color: rgb(32, 35, 83, 0.8);
  ${props =>
    props.isSemibold &&
    css`
      font-weight: 600;
    `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 32px;
  .ui.button.btn-welcome-form-question {
    height: 32px;
    ${baseText}
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .ui.button.btn-welcome-form-question-cancel {
    width: 92px;
    padding: 7px 25px;
    color: #818da1;
    border: 1px solid #d6dae4;
    background-color: #fff;
    margin-right: 5px;
    :hover {
      background-color: #f5f7f9;
    }
  }
  .ui.button.btn-welcome-form-question-save {
    min-width: 132px;
    padding: 7px 35px;
    background-color: #5158cf;
    color: #fff;
    margin-right: 0;
    :hover {
      background-color: rgba(81, 88, 207, 0.9);
    }
  }
  .ui.button.btn-change-form,
  .ui.button.btn-remove-form {
    width: fit-content;
    padding: 0;
    background-color: #fff;
    color: #818da1;
    border: none;
    svg {
      margin-right: 8px;
    }
    &:hover {
      color: #5e59ff;
      svg g path {
        fill: #5e59ff;
      }
    }
  }
  .ui.button.btn-remove-form {
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: #818da1;
      }
    }
    &:hover {
      color: #ff5e5e;
      svg path {
        stroke: #ff5e5e;
      }
    }
  }
`;

export const FooterLeft = styled.div``;

export const FooterRight = styled.div`
  display: flex;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }

  &.ui.modal.confirm-popup-container.remove-form-popup {
    .confirm-yes-button {
      min-width: 112px;
    }
  }
`;
