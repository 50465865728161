import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { TableHeaderCell } from 'shared/LibraryLayout';
import { Button } from 'shared/FormControl';

import { CDN_URL } from 'constants/commonData';

import ArrowIconURL from 'assets/icons/arrow_corner_icon.svg';

export const CheckBoxContainer = styled.div`
  padding-right: 15px;
  .checkbox-icon {
    width: 15px;
    height: 15px;
    border: 1px solid #b6b6b6;
    border-radius: 2px;
  }

  .evf-checkbox {
    padding-left: 15px;
  }
`;

export const SectionHeaderCell = styled(TableHeaderCell)`
  &:nth-child(1) {
    width: 40% !important;
  }
  &:nth-child(2) {
    width: 30% !important;
  }
  &:nth-child(3) {
    width: 20% !important;
    padding-top: 17px !important;
  }
  ${CheckBoxContainer} {
    margin-bottom: 10px;
  }

  .actions {
    width: '135px';
  }
`;

export const SortWrapper = styled.div`
  display: inline-flex;
`;

export const SortActiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      color: #5158cf;
      stroke-width: 1.5;
      svg.column-symbol path {
        fill: #5158cf;
        stroke-width: 1.5;
      }
    `}

  ${props =>
    props.hideCursor &&
    css`
      cursor: text;
    `}

  svg.arrow {
    path {
      fill: none !important;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 390px;
`;

export const SectionWrapper = styled.div`
  display: flex;
`;

export const SectionName = styled.div`
  margin-top: 3px;
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  font-size: 15px;
  align-items: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SectionDesc = styled.div`
  margin-top: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #0d1419;
  opacity: 0.8;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

export const SectionExercise = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 13px;
  color: #0d1419;
  opacity: 0.4;
`;

export const SectionTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormatType = styled.div`
  padding: 2px 10px;
  background: #deebff;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #5088e2;
  text-transform: capitalize;
`;

export const FormatDescType = styled.div`
  padding-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #0d1419;
  opacity: 0.4;
`;

export const SelectOwner = styled.div`
  width: 400px;
  position: absolute;
  .multi-select-container .multi-select__control {
    max-height: 70px;
    overflow-y: scroll;
  }
`;

export const DeleteButton = styled(Button)`
  color: #777777;
  width: 156px;
  height: 30px;
  font-size: 12px;

  .button--remove-section-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
`;

export const AddSectionButton = styled(Button)``;

export const FilterWrapper = styled.div`
  button.button--custom-purple {
    &:hover,
    &:focus {
      background-color: rgba(81, 88, 207, 0.9) !important;
      border-color: rgba(81, 88, 207, 0.9) !important;
    }
  }
`;

export const Form = styled.form``;

export const ModalShare = styled(Modal)`
  &.ui.modal {
    width: 563px;
    height: initial;
    border-radius: 8px;
    box-sizing: border-box;

    > :last-child {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &.ui.modal > .content {
    line-height: unset !important;
  }

  &.tasks-share-owner .header {
    color: #000 !important;
    background: none !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    padding: 24px 24px 0px !important;
    border-radius: 0;
  }

  &.tasks-share-owner .content {
    background: none !important;
    padding: 20px 24px 24px !important;
    border-radius: 0;

    ${Form} {
      margin-bottom: 46px;

      .client-profile-label {
        color: #6a778a;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        padding-left: 0;
        letter-spacing: 0.01em;
        text-transform: uppercase;
      }

      .field.client_type_field.share-with-org {
        margin-top: 10px;
      }

      .field > .label {
        margin-bottom: 5px;
      }

      .ownership-dropdown {
        position: relative;
        min-height: auto;
        height: 36px;
        border-radius: 4px !important;

        &.dropdown .dropdown.icon::before {
          background-image: url(${CDN_URL}/images/dropdown-mark-down.svg) !important;
          width: 16px;
          height: 16px;
          top: 50%;
          position: absolute;
          transform: translateY(-40%);
          right: 11px;
          color: #000000;
        }

        .text {
          color: #323c47;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
        }

        .active .text {
          font-weight: 700;
          color: rgba(0, 0, 0, 0.95);
        }

        .dropdown {
          &.icon {
            position: absolute;
            padding: 0;
            top: calc(50% + 7px);
            padding-right: 13px;

            &::before {
              content: '';
              display: block;
              width: 6.7px;
              height: 4.6px;
              background-image: url(${ArrowIconURL}) !important;
              background-size: contain !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
            }
          }
        }
      }
    }
  }

  .formControl {
    margin-bottom: 0;

    > div {
      color: #9a9da6;
      font-weight: 700;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.01em;
      margin-bottom: 1px;

      .label__maxLength {
        color: #6a778a;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  .cancel,
  .submit {
    min-width: 80px;
  }

  .submit {
    padding: 10px 27px !important;
    width: 100px;

    &:hover,
    &:active,
    &:focus {
      background: #5559ff;
    }
  }

  .cancel {
    width: 102px;
    height: 42px;
    color: #323c47;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    padding: 12px 30px !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  .cancel {
    margin-right: 12px;
  }
`;
