import styled, { css } from 'styled-components';

const buttonTypes = ['purple', 'green', 'yellow', 'delete', 'grey', 'textOnly', 'borderPurple'];

export default styled.button`
  font-family: 'Open Sans';
  min-width: 80px;
  background-color: #fff;
  text-align: center;
  padding: 6px 10px;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;
  border: 1px solid #d4d7d9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #818da1;
  line-height: 18px;
  white-space: nowrap;

  :hover {
    background-color: #f5f7f9;
  }

  > * {
    margin-right: 6px;

    :last-child {
      margin: 0;
    }
  }

  ${props =>
    props.purple &&
    css`
      color: #fff;
      background-color: #5158cf;
      border-color: transparent;

      :hover {
        color: #fff;
        background-color: #5158cf;
        border-color: transparent;
      }
    `}

  ${props =>
    props.green &&
    css`
      color: #fff;
      background: #49ca8d;
      border-color: transparent;

      :hover {
        color: #fff;
        background: #49ca8d;
        border-color: transparent;
      }
    `}

  ${props =>
    props.yellow &&
    css`
      color: #fff;
      background: #ffc42b;
      border-color: transparent;

      :hover {
        color: #fff;
        background: #ffc42b;
        border-color: transparent;
      }
    `}

  ${props =>
    props.delete &&
    css`
      color: #fff;
      background: #ea314a;
      border-color: transparent;

      :hover {
        color: #fff;
        background: #ea314a;
        border-color: transparent;
      }
    `}

  ${props =>
    props.grey &&
    css`
      color: #202353;
      background: #e5e9ee;
      border-color: transparent;

      :hover {
        color: #202353;
        background: #e5e9ee;
        border-color: transparent;
      }
    `}

  ${props =>
    props.textOnly &&
    css`
      border: none !important;
      padding: 0;

      :hover {
        color: #5158cf;
        background-color: transparent;
      }
    `}
    
  ${props =>
    props.borderPurple &&
    css`
      border-color: #5158cf;
      color: #5158cf;

      :hover {
        background-color: #5158cf;
        color: #fff;
      }
    `}

    ${props =>
    props.borderRed &&
    css`
      border-color: #ea314a;
      color: #ea314a;

      :hover {
        background-color: #fff9f9;
      }
    `}

    ${props =>
    props.btnBackgroundViolet &&
    css`
      border-color: rgb(100, 86, 196);
      background-color: #fff;
      color: rgb(100, 86, 196);
      padding: 7px 40px;
      line-height: 18px;
      :hover {
        background-color: rgb(100, 86, 196);
        color: #fff;
      }
    `}

    ${props =>
    props.w95 &&
    css`
      width: 95px;
    `}

  :disabled {
    cursor: not-allowed;
    opacity: 1;
    background-color: #c4c4c4;

    &:hover {
      color: #fff;
      background-color: #c4c4c4 !important;
    }
  }
`;
