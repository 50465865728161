import cloneDeep from 'lodash/cloneDeep';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import Request from 'configs/request';
import { showError } from 'actions/error';
import { toggleModal } from 'actions/modal';
import omit from 'lodash/omit';

export const TEMPLATES_MODAL_TYPES = {
  FULLSCREEN: 'fullscreen',
  FILTER: 'filter',
  DETAIL: 'detail',
  INSTRUCTION: 'instruction',
  TAGS: 'tags',
  DESCRIPTION: 'description',
};

export const Types = {
  PROGRAM_LIBRARY_FILTER_REQUEST: 'PROGRAM_LIBRARY_FILTER_REQUEST',
  PROGRAM_LIBRARY_FILTER_SUCCESS: 'PROGRAM_LIBRARY_FILTER_SUCCESS',
  PROGRAM_LIBRARY_FILTER_FAILED: 'PROGRAM_LIBRARY_FILTER_FAILED',
  PROGRAM_LIBRARY_CHANGE_QUERY_PARAMS: 'PROGRAM_LIBRARY_CHANGE_QUERY_PARAMS',
  PROGRAM_LIBRARY_RESET_QUERY_PROGRAM: 'PROGRAM_LIBRARY_RESET_QUERY_PROGRAM',
  PROGRAM_LIBRARY_REMOVE_ITEM_REQUEST: 'PROGRAM_LIBRARY_REMOVE_ITEM_REQUEST',
  PROGRAM_LIBRARY_REMOVE_ITEM_SUCCESS: 'PROGRAM_LIBRARY_REMOVE_ITEM_SUCCESS',
  PROGRAM_LIBRARY_REMOVE_ITEM_FAILED: 'PROGRAM_LIBRARY_REMOVE_ITEM_FAILED',
  PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_REQUEST: 'PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_REQUEST',
  PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_SUCCESS: 'PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_SUCCESS',
  PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_FAILED: 'PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_FAILED',
  PROGRAM_LIBRARY_ADD_ITEM_REQUEST: 'PROGRAM_LIBRARY_ADD_ITEM_REQUEST',
  PROGRAM_LIBRARY_ADD_ITEM_SUCCESS: 'PROGRAM_LIBRARY_ADD_ITEM_SUCCESS',
  PROGRAM_LIBRARY_ADD_ITEM_FAILED: 'PROGRAM_LIBRARY_ADD_ITEM_FAILED',
  PROGRAM_LIBRARY_UPDATE_ITEM_REQUEST: 'PROGRAM_LIBRARY_UPDATE_ITEM_REQUEST',
  PROGRAM_LIBRARY_UPDATE_ITEM_SUCCESS: 'PROGRAM_LIBRARY_UPDATE_ITEM_SUCCESS',
  PROGRAM_LIBRARY_UPDATE_ITEM_FAILED: 'PROGRAM_LIBRARY_UPDATE_ITEM_FAILED',
  PROGRAM_LIBRARY_CLONE_ITEM_REQUEST: 'PROGRAM_LIBRARY_CLONE_ITEM_REQUEST',
  PROGRAM_LIBRARY_CLONE_ITEM_SUCCESS: 'PROGRAM_LIBRARY_CLONE_ITEM_SUCCESS',
  PROGRAM_LIBRARY_CLONE_ITEM_FAILED: 'PROGRAM_LIBRARY_CLONE_ITEM_FAILED',
  PROGRAM_LIBRARY_BULK_UPDATE_TAGS_REQUEST: 'PROGRAM_LIBRARY_BULK_UPDATE_TAGS_REQUEST',
  PROGRAM_LIBRARY_BULK_UPDATE_TAG_SUCCESS: 'PROGRAM_LIBRARY_BULK_UPDATE_TAG_SUCCESS',
  PROGRAM_LIBRARY_BULK_UPDATE_TAG_FAILED: 'PROGRAM_LIBRARY_BULK_UPDATE_TAG_FAILED',
  PROGRAM_LIBRARY_TOGGLE_MODAL_TEMPLATES: 'PROGRAM_LIBRARY_TOGGLE_MODAL_TEMPLATES',
  PROGRAM_TEMPLATE_REQUEST: 'PROGRAM_TEMPLATE_REQUEST',
  PROGRAM_TEMPLATE_SUCCESS: 'PROGRAM_TEMPLATE_SUCCESS',
  PROGRAM_TEMPLATE_FAILED: 'PROGRAM_TEMPLATE_FAILED',
  PROGRAM_TEMPLATE_CHANGE_QUERY_PARAMS: 'PROGRAM_TEMPLATE_CHANGE_QUERY_PARAMS',
  PROGRAM_TEMPLATE_RESET_QUERY_PARAMS: 'PROGRAM_TEMPLATE_RESET_QUERY_PARAMS',
  PROGRAM_TEMPLATE_SAVE_REQUEST: 'PROGRAM_TEMPLATE_SAVE_REQUEST',
  PROGRAM_TEMPLATE_SAVE_SUCCESS: 'PROGRAM_TEMPLATE_SAVE_SUCCESS',
  PROGRAM_TEMPLATE_SAVE_FAILED: 'PROGRAM_TEMPLATE_SAVE_FAILED',
  PROGRAM_TEMPLATE_WEEK_DETAIL_REQUEST: 'PROGRAM_TEMPLATE_WEEK_DETAIL_REQUEST',
  PROGRAM_TEMPLATE_WEEK_DETAIL_SUCCESS: 'PROGRAM_TEMPLATE_WEEK_DETAIL_SUCCESS',
  PROGRAM_TEMPLATE_WEEK_DETAIL_FAILED: 'PROGRAM_TEMPLATE_WEEK_DETAIL_FAILED',
  UPDATE_TAG_IN_PROGRAMS: 'UPDATE_TAG_IN_PROGRAMS',
  DELETE_TAG_IN_PROGRAMS: 'DELETE_TAG_IN_PROGRAMS',
  PROGRAM_LIBRARY_CLOSE_MODAL_TEMPLATES: 'PROGRAM_LIBRARY_CLOSE_MODAL_TEMPLATES',
};

export const formatTags = (queryParams = {}) => {
  let clonedQueryParams = cloneDeep(queryParams) || {};
  let shouldFormatTags = typeof (clonedQueryParams.tags || [])[0] !== 'string';
  if (shouldFormatTags) {
    clonedQueryParams.tags = queryParams.tags.map(tag => tag._id);
  }
  return clonedQueryParams;
};

export const formatLevels = (queryParams = {}) => {
  let clonedQueryParams = cloneDeep(queryParams) || {};
  if (clonedQueryParams.levels) {
    clonedQueryParams.levels = [clonedQueryParams.levels];
  }
  return clonedQueryParams;
};

export const formatSearch = (queryParams = {}) => {
  let clonedQueryParams = cloneDeep(queryParams) || {};
  if (!clonedQueryParams.search) {
    clonedQueryParams.search = undefined;
  }
  return clonedQueryParams;
};

export const getProgramWithFilter = params => (dispatch, getState) => {
  const {
    rootReducer: { programLibraryAndTemplates: { query = {} } = {} },
  } = getState();

  let queryParams = params || query;

  queryParams = formatSearch(queryParams);
  queryParams = formatTags(queryParams);
  queryParams = formatLevels(queryParams);

  dispatch({ type: Types.PROGRAM_LIBRARY_FILTER_REQUEST, payload: queryParams });

  return dispatch(
    Request.get(
      { url: '/api/program-library', params: queryParams },
      true,
      response => {
        const {
          data: { data = [], total = 0 },
        } = response;
        dispatch({
          type: Types.PROGRAM_LIBRARY_FILTER_SUCCESS,
          payload: { list: data, total },
        });
      },
      error => {
        dispatch({
          type: Types.PROGRAM_LIBRARY_FILTER_FAILED,
          payload: error,
        });
      },
    ),
  );
};

export const changeQueryParamsProgram = data => (dispatch, getState) => {
  const {
    rootReducer: { programLibraryAndTemplates: { query = {} } = {} },
  } = getState();

  const params = { ...query, ...data };

  dispatch({
    type: Types.PROGRAM_LIBRARY_CHANGE_QUERY_PARAMS,
    payload: params,
  });

  dispatch(getProgramWithFilter(params));
};

export const resetQueryParamsProgram = (params = {}) => ({
  type: Types.PROGRAM_LIBRARY_RESET_QUERY_PROGRAM,
  payload: params,
});

export const removeProgramLibraryItem = programId => dispatch => {
  dispatch({ type: Types.PROGRAM_LIBRARY_REMOVE_ITEM_REQUEST });
  return dispatch(
    Request.delete(
      { url: `/api/program/library/v2/delete/${programId}` },
      false,
      () => {
        dispatch({
          type: Types.PROGRAM_LIBRARY_REMOVE_ITEM_SUCCESS,
          payload: { id: programId },
        });
      },
      error => dispatch({ type: Types.PROGRAM_LIBRARY_REMOVE_ITEM_FAILED, error }),
    ),
  );
};

export const updateProgramSharingStatus = data => dispatch => {
  dispatch({ type: Types.PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_REQUEST });

  return dispatch(
    Request.post(
      { url: `/api/program/library/v2/update/${data._id}`, data },
      true,
      () => {
        dispatch({ type: Types.PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_SUCCESS, payload: { data } });
        dispatch(getProgramWithFilter());
      },
      () => dispatch({ type: Types.PROGRAM_LIBRARY_UPDATE_SHARING_STATUS_FAILED }),
    ),
  );
};

export const addProgram = program => (dispatch, getState) => {
  const {
    user: { _id: currentUser },
  } = getState();

  const { author } = program;

  dispatch({ type: Types.PROGRAM_LIBRARY_ADD_ITEM_REQUEST, payload: { program } });

  return dispatch(
    Request.post(
      { url: '/api/program/library/v2/add', data: program },
      true,
      response => {
        const { data: { data = {} } = {} } = response;
        if (data._id) {
          dispatch({ type: Types.PROGRAM_LIBRARY_ADD_ITEM_SUCCESS, payload: { data } });
          if (currentUser === author || !author) {
            dispatch(push(`/home/program/${data._id}/calendar`));
          } else {
            toast('Program has been saved');
            dispatch(getProgramWithFilter());
          }
        }
      },
      error => {
        dispatch({ type: Types.PROGRAM_LIBRARY_ADD_ITEM_FAILED, payload: { error } });
      },
    ),
  );
};

export const updateProgram = program => dispatch => {
  dispatch({ type: Types.PROGRAM_LIBRARY_UPDATE_ITEM_REQUEST, payload: { program } });

  return dispatch(
    Request.post(
      { url: `/api/program/library/v2/update/${program._id}`, data: program },
      true,
      result => {
        const { data: { data = {} } = {} } = result;
        if (data._id) {
          dispatch({ type: Types.PROGRAM_LIBRARY_UPDATE_ITEM_SUCCESS, payload: { data } });
          toast('Program has been updated successfully.');
          dispatch(getProgramWithFilter());
        }
      },
      error => {
        dispatch({ type: Types.PROGRAM_LIBRARY_UPDATE_ITEM_FAILED, payload: { error } });
      },
    ),
  );
};

export const cloneProgram = program => dispatch => {
  dispatch({ type: Types.PROGRAM_LIBRARY_CLONE_ITEM_REQUEST, payload: { program } });

  return dispatch(
    Request.post(
      { url: `/api/program/library/v2/clone/${program._id}`, data: program },
      true,
      result => {
        const { data: { data = {} } = {} } = result;
        if (data._id) {
          dispatch({ type: Types.PROGRAM_LIBRARY_CLONE_ITEM_SUCCESS, payload: { data } });
          toast('Program has been saved to library.');
          dispatch(getProgramWithFilter());
        }
      },
      error => {
        dispatch({ type: Types.PROGRAM_LIBRARY_CLONE_ITEM_FAILED, payload: { error } });
      },
    ),
  );
};

export const removeProgram = programId => dispatch => {
  dispatch({ type: Types.PROGRAM_LIBRARY_REMOVE_ITEM_REQUEST, payload: { programId } });

  return dispatch(
    Request.delete(
      { url: `/api/program/library/v2/delete/${programId}` },
      true,
      result => {
        dispatch({
          type: Types.PROGRAM_LIBRARY_REMOVE_ITEM_SUCCESS,
          payload: { id: programId },
        });
        dispatch(toggleModal(false));
        dispatch(getProgramWithFilter());
        dispatch(push('/home/program'));
      },
      error => dispatch({ type: Types.PROGRAM_LIBRARY_REMOVE_ITEM_FAILED, error }),
    ),
  );
};

export const addBulkAddTagPrograms = data => dispatch => {
  dispatch({ type: Types.PROGRAM_LIBRARY_BULK_UPDATE_TAGS_REQUEST, payload: { data } });

  return dispatch(
    Request.post(
      { url: `/api/program-library/bulk-update-tags`, data },
      false,
      response => {
        const { data: { data = {} } = {} } = response;
        if (data) {
          dispatch({ type: Types.PROGRAM_LIBRARY_BULK_UPDATE_TAG_SUCCESS, payload: { data } });
          dispatch(getProgramWithFilter());
          if ((data.unauthorized_programs || []).length) {
            const titlesProgram = data.unauthorized_programs.map(it => it.title);
            const message = `You are not authorized to edit these program: ${titlesProgram.join(', ')}`;
            dispatch(showError(message.length > 165 ? `${message.substring(0, 165)}...` : message));
          }
        }
      },
      error => {
        dispatch({ type: Types.PROGRAM_LIBRARY_BULK_UPDATE_TAG_FAILED, payload: { error } });
      },
    ),
  );
};

export const toggleTemplatesModal = type => ({
  type: Types.PROGRAM_LIBRARY_TOGGLE_MODAL_TEMPLATES,
  payload: type,
});

export const toggleFullscreenModal = () => dispatch => {
  if (window.location.pathname === '/home/program') {
    dispatch(push('/home/program/templates'));
  } else if (window.location.pathname === '/home/program/templates') {
    dispatch(push('/home/program'));
  }
  dispatch(toggleTemplatesModal(TEMPLATES_MODAL_TYPES.FULLSCREEN));
};

export const toggleFilterModal = () => dispatch => {
  dispatch(toggleTemplatesModal(TEMPLATES_MODAL_TYPES.FILTER));
};

export const toggleDetailModal = () => dispatch => {
  dispatch(toggleTemplatesModal(TEMPLATES_MODAL_TYPES.DETAIL));
};

export const toggleInstructionModal = () => dispatch => {
  dispatch(toggleTemplatesModal(TEMPLATES_MODAL_TYPES.INSTRUCTION));
};

export const toggleTagsModal = () => dispatch => {
  dispatch(toggleTemplatesModal(TEMPLATES_MODAL_TYPES.TAGS));
};

export const toggleDescriptionModal = () => dispatch => {
  dispatch(toggleTemplatesModal(TEMPLATES_MODAL_TYPES.DESCRIPTION));
};

export const changeQueryParams = params => {
  return dispatch => {
    dispatch({ type: Types.PROGRAM_TEMPLATE_CHANGE_QUERY_PARAMS, payload: params });
    dispatch(getProgramTemplate(params));
  };
};

export const saveAsProgramLibrary = workoutId => {
  if (!workoutId) return;

  return (dispatch, getState) => {
    const {
      rootReducer: { programLibraryAndTemplates: { templateProgram: { list = [] } = {} } = {} },
    } = getState();

    dispatch({ type: Types.PROGRAM_TEMPLATE_SAVE_REQUEST });

    return dispatch(
      Request.post(
        { url: `/api/program-templates/${workoutId}/save-as-program-library` },
        false,
        response => {
          const {
            data: { data },
          } = response;
          toast('This template has been saved to your library.');
          const newTemplateProgramList = list.map(it => {
            if (it._id === workoutId) {
              return { ...it, is_saved: true, total_used: it.total_used + 1 };
            }
            return it;
          });

          dispatch({
            type: Types.PROGRAM_TEMPLATE_SAVE_SUCCESS,
            payload: { data, templateProgramList: newTemplateProgramList },
          });
          dispatch(getProgramWithFilter());
        },
        error => dispatch({ type: Types.PROGRAM_TEMPLATE_SAVE_FAILED, payload: error }),
      ),
    );
  };
};

export const getProgramWeekDetail = (params = {}) => (dispatch, getState) => {
  const { id, week_id } = params;
  if (!id || !week_id) return;

  dispatch({ type: Types.PROGRAM_TEMPLATE_WEEK_DETAIL_REQUEST });

  return dispatch(
    Request.get(
      { url: `/api/program-templates/${id}/weeks/${week_id}` },
      true,
      response => {
        const {
          data: { data = [] },
        } = response;
        dispatch({
          type: Types.PROGRAM_TEMPLATE_WEEK_DETAIL_SUCCESS,
          payload: { data },
        });
      },
      error => {
        dispatch({
          type: Types.PROGRAM_TEMPLATE_WEEK_DETAIL_FAILED,
          payload: error,
        });
      },
    ),
  );
};

export const getProgramTemplate = (params = {}) => (dispatch, getState) => {
  const {
    rootReducer: {
      programLibraryAndTemplates: { query_templates = {} },
    },
  } = getState();

  function transformAndStringify(prop) {
    return prop && prop.length ? JSON.stringify(prop) : null;
  }

  function transformAndStringifyModality(prop = []) {
    const defaultModality = prop.filter(item => item !== 'all');
    return prop && prop.length ? JSON.stringify(defaultModality) : undefined;
  }

  const updatedParams = omit(
    {
      ...query_templates,
      ...params,
      durations: transformAndStringify(query_templates.durations),
      levels: transformAndStringify(query_templates.levels ? [query_templates.levels] : null),
      modalities: transformAndStringifyModality(query_templates.modalities),
      number_of_weeks: transformAndStringify(query_templates.number_of_weeks),
      workout_days_per_weeks: transformAndStringify(query_templates.workout_days_per_weeks),
    },
    ['program_length', 'resetFilter'],
  );

  dispatch({ type: Types.PROGRAM_TEMPLATE_REQUEST, payload: updatedParams });

  return dispatch(
    Request.get(
      { url: '/api/program-templates', params: updatedParams },
      true,
      response => {
        const {
          data: { data = [], total = 0 },
        } = response.data;
        dispatch({
          type: Types.PROGRAM_TEMPLATE_SUCCESS,
          payload: { data, total },
        });
      },
      error => {
        dispatch({
          type: Types.PROGRAM_TEMPLATE_FAILED,
          payload: error,
        });
      },
    ),
  );
};

export const resetQueryPopupFilter = () => ({ type: Types.PROGRAM_TEMPLATE_RESET_QUERY_PARAMS });

export const updateTagInPrograms = (id, name) => ({
  type: Types.UPDATE_TAG_IN_PROGRAMS,
  payload: {
    id,
    name,
  },
});

export const deleteTagInPrograms = id => ({
  type: Types.DELETE_TAG_IN_PROGRAMS,
  payload: {
    id,
  },
});

export const closeFullscreenModal = () => ({
  type: Types.PROGRAM_LIBRARY_CLOSE_MODAL_TEMPLATES,
});
