// Libs
import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { Redirect } from 'react-router';

// Utils
import { isOwnerWorkspace, isShowTrafficPage } from 'utils/commonFunction';

// Components
import Questionnaire from 'components/Questionnaire';
import OnboardingWelcomeVideo from 'components/OnboardingWelcomeVideo';
import MobileOnboardingWelcomeVideo from './MobileOnboardingWelcomeVideo';
import TrackingFrom from 'components/Questionnaire/TrackingUserFrom';
import VerifyEmail from 'components/VerifyEmail';

const steps = { questionnaire: 1, trackingUserFrom: 2, verifyEmail: 3, playVideo: 4, userGuide: 5 };

function Welcome(props) {
  const { location, user, history } = props;
  const [currentStep, setCurrentStep] = useState();
  const [isSkipDirectTraffic, setIsSkipDirectTraffic] = useState(false);
  const { isPortrait, isLandscape } = useMobileOrientation();
  const browserWidth = useMemo(() => document.documentElement.clientWidth || 0, [document, isLandscape]);

  useEffect(() => {
    if (user) {
      const isOwner = isOwnerWorkspace(user);

      if (!isOwner) {
        props.userAnsweredQuestionaire();
        props.userAnsweredTrackingFrom();
      }
    }
  }, [get(user, 'is_answers_questionnaire'), get(user, 'is_answers_questionnaire_direct_traffic_lead_source')]);

  useEffect(() => {
    if (props.user) {
      props.getOnboardingCheckList();
      const isShow = isShowTrafficPage(location.search);
      setIsSkipDirectTraffic(!isShow);
      const isTrackingUserFrom =
        user.is_answers_questionnaire_direct_traffic_lead_source === false && isShow
          ? steps.trackingUserFrom
          : !get(user, 'is_verified_email') && get(user, 'force_verify_email')
          ? steps.verifyEmail
          : steps.playVideo;
      setCurrentStep(user.is_answers_questionnaire ? isTrackingUserFrom : steps.questionnaire);
    }
  }, [user]);

  useEffect(() => {
    if (get(user, 'is_answers_questionnaire')) {
      window.history.replaceState({}, '', `/onboarding${window.location.search}`);
      if (!get(user, 'is_verified_email') && get(user, 'force_verify_email')) {
        window.history.replaceState({}, '', `/verify-email`);
      }
    }
  }, [user]);

  const onAnswered = () => {
    props.userAnsweredQuestionaire();
  };

  const onTrackingUserFrom = () => {
    props.userAnsweredTrackingFrom();
  };

  if (get(user, 'is_watch_video')) {
    return <Redirect to={'/home/client'} />;
  }

  switch (currentStep) {
    case steps.questionnaire:
      return <Questionnaire onFinished={onAnswered} isSkipDirectTraffic={isSkipDirectTraffic} />;
    case steps.trackingUserFrom:
      return <TrackingFrom onFinished={onTrackingUserFrom} />;
    case steps.verifyEmail:
      return <VerifyEmail history={history} />;
    case steps.playVideo:
      if (isMobileOnly || (isTablet && isPortrait) || (isTablet && isLandscape && browserWidth <= 1024)) {
        return <MobileOnboardingWelcomeVideo />;
      }

      if (get(user, 'is_watch_video')) {
        return <Redirect to={'/home/client'} />;
      }

      return <OnboardingWelcomeVideo />;

    default:
      return null;
  }
}

export default Welcome;
