import _ from 'lodash';

const getLocalStorageKey = userId => {
  return userId ? `workout_builder_${userId}` : `workout_builder`;
};

export const saveWorkoutSetting = (userId, data) => {
  const key = getLocalStorageKey(userId);
  // localStorage.removeItem(key);
  let setting = localStorage.getItem(key);

  if (setting) {
    try {
      setting = JSON.parse(setting);
    } catch (err) {
      setting = {};
    }
  }

  const newSetting = _.extend({}, setting, data);
  localStorage.setItem(key, JSON.stringify(newSetting));
};

export const getWorkoutBuilderSetting = userId => {
  const key = getLocalStorageKey(userId);
  // localStorage.removeItem(key);

  const setting = localStorage.getItem(key);

  try {
    return setting ? JSON.parse(setting) : {};
  } catch (error) {
    return {};
  }
};
