import React from 'react';
import { connect } from 'react-redux';

import * as S from '../style';
import TableHeader from './TableHeader';
import TableContent from './TableContent';
import Indicator from '../Indicator';

function InvoiceTable({ loading, isMP }) {
  return (
    <>
      <S.Table>
        <TableHeader isMP={isMP} />
        {!loading && <TableContent isMP={isMP} />}
      </S.Table>
      {loading && <Indicator title="Loading invoices" />}
    </>
  );
}

const mapState = state => {
  const {
    rootReducer: { packageAnalytics },
  } = state;
  return {
    loading: packageAnalytics.toJS().loading,
  };
};

export default connect(mapState, null)(InvoiceTable);
