import Request from 'configs/request';
import { cloneDeep, sortBy } from 'lodash';

export const Types = {
  CATEGORY_GET_LABELS_REQUEST: 'CATEGORY_GET_LABELS_REQUEST',
  CATEGORY_GET_LABELS_SUCCESS: 'CATEGORY_GET_LABELS_SUCCESS',
};

export const getAllLabels = () => {
  return dispatch => {
    dispatch({ type: Types.CATEGORY_GET_LABELS_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/on-demand-workout-labels`,
        },
        true,
        response => {
          const { data } = response.data;
          let clonedLabels = cloneDeep(data);
          let sortedLabels = sortBy(clonedLabels, obj => obj.order);

          dispatch({ type: Types.CATEGORY_GET_LABELS_SUCCESS, payload: { data: sortedLabels } });
        },
      ),
    );
  };
};
