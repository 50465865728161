// Libs
import React, { useState } from 'react';
import debounce from 'lodash/debounce';

// Components
import { NewSearchInput } from 'shared/SearchInput';
import { SharedTooltip } from 'shared/SharedTooltip';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/add_tag_icon.svg';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/file-document.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search_icon_grey.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_currentColor.svg';
import MealPlanBannerImg from 'assets/images/meal-plan-banner.png';

// Styles
import * as S from './style';

const MealPlanBanner = ({ onSearch, onClearSearch, onHideMealPlanBanner, onOpenAddMealPlan, isLoading }) => {
  const [isShowSearch, setIsShowSearch] = useState(false);

  const handleHideBanner = () => {
    if (isLoading) return;

    typeof onHideMealPlanBanner === 'function' && onHideMealPlanBanner();
  };

  return (
    <S.BannerWrapper>
      <S.BannerRight>
        <S.BannerInfo>
          <S.BannerLabel>Nutrition Master Plan</S.BannerLabel>
          <S.BannerHeader>Tailored Meal Plans, Simplified</S.BannerHeader>
          <S.BannerDesc>
            Empower your clients with tailored nutrition strategies, assigning and tracking meal plans seamlessly.
            <S.BannerLearnMore
              href="https://help.everfit.io/en/collections/7747089-meal-plans-and-recipes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LearnMoreIcon className="banner-learn-more-icon" />
              <span>Learn more</span>
            </S.BannerLearnMore>
          </S.BannerDesc>
        </S.BannerInfo>
        <S.BannerActions>
          <S.BannerButtonCreate onClick={onOpenAddMealPlan}>
            <PlusIcon className="banner-plus-icon" />
            <S.BannerButtonContent>Create Meal Plan</S.BannerButtonContent>
          </S.BannerButtonCreate>
          {isShowSearch ? (
            <NewSearchInput
              className="banner-search"
              placeholder="Search by keywords or name"
              autoFocus
              onChange={debounce(onSearch, 300)}
              onClearSearch={onClearSearch}
            />
          ) : (
            <>
              <S.ButtonSearch onClick={() => setIsShowSearch(true)} data-for="meal-plan-banner-search-tooltip" data-tip>
                <SearchIcon className="banner-search-icon" />
              </S.ButtonSearch>
              <SharedTooltip content="Search" id="meal-plan-banner-search-tooltip" />
            </>
          )}
        </S.BannerActions>
      </S.BannerRight>
      <S.BannerLeft>
        <S.ImageBanner src={MealPlanBannerImg} />
      </S.BannerLeft>
      <S.HideButton isDisabled={isLoading} onClick={handleHideBanner}>
        Hide Banner
        <ArrowIcon />
      </S.HideButton>
    </S.BannerWrapper>
  );
};

export default MealPlanBanner;
