import { Workout } from 'types/model';
import {
  REQUEST_GET_ASSIGNMENTS,
  SUCCESS_GET_ASSIGNMENTS,
  SUCCESS_GET_LOGGED_ACTIVITIES,
  Types,
} from 'actions/workout/getWorkouts';
import Request from 'configs/request';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import { convertWorkoutUnits } from 'helpers/workout';
import { DEFAULT_CALENDAR_TYPE } from 'constants/commonData';

export const CHANGE_CALENDAR_TYPE = 'CHANGE_CALENDAR_TYPE';
export const CHANGE_CALENDAR_START_DATE = 'CHANGE_CALENDAR_START_DATE';

export const changeCalendarTypeSuccess = newCalendarType => {
  return (dispatch, getState) => {
    // if have no params, reset the calendar type
    if (isNil(newCalendarType)) {
      const selectedClientId = getState().rootReducer.client.selected;
      if (!isNil(selectedClientId)) {
        const calendarTypePerClient = localStorage.hasOwnProperty('calendar_type_per_client')
          ? JSON.parse(localStorage.getItem('calendar_type_per_client'))
          : {};
        return dispatch({
          type: CHANGE_CALENDAR_TYPE,
          calendarType: get(calendarTypePerClient, selectedClientId, DEFAULT_CALENDAR_TYPE.value),
        });
      } else {
        return dispatch({ type: CHANGE_CALENDAR_TYPE, calendarType: DEFAULT_CALENDAR_TYPE.value });
      }
    }
    return dispatch({ type: CHANGE_CALENDAR_TYPE, calendarType: newCalendarType });
  };
};

// TODO: need to save the activities in this api too
export const changeCalendarType = (params, calendarType, from = '') => {
  return (dispatch, getState) => {
    return dispatch(
      Request.get(
        {
          url:
            getState().rootReducer.calendar.view_mode === 'assignment'
              ? '/api/workout/v2/assignments'
              : '/api/workout/v2/assignments/history',
          params,
        },
        true,
        result => {
          const dataWk = result.data.day_data.map(itm => {
            const workouts = itm.assignments.map(itmAssi => {
              const workout = new Workout();
              return workout.parseFromAssignment(itmAssi);
            });

            return { day: itm.day, workouts: workouts };
          });

          dispatch(getAssignmentsSuccess(dataWk));

          // logic save calendar type per client
          const selectedClientId = getState().rootReducer.client.selected;
          if (!isNil(selectedClientId)) {
            const calendarTypePerClient = localStorage.hasOwnProperty('calendar_type_per_client')
              ? JSON.parse(localStorage.getItem('calendar_type_per_client'))
              : {};
            localStorage.setItem(
              'calendar_type_per_client',
              JSON.stringify({
                ...calendarTypePerClient,
                [selectedClientId]: calendarType,
              }),
            );
          }

          dispatch(changeCalendarTypeSuccess(calendarType));
        },
      ),
    );
  };
};

export const changeCalendarStartDateSuccess = calendarStartDate => ({
  type: CHANGE_CALENDAR_START_DATE,
  calendarStartDate,
});

const getAssignmentsSuccess = workouts => {
  return { type: SUCCESS_GET_ASSIGNMENTS, workouts };
};

const getActivitiesSuccess = data => {
  return { type: SUCCESS_GET_LOGGED_ACTIVITIES, payload: data };
};

const getAssignmentsForUpdateSuccess = data => {
  return {
    type: Types.SUCCESS_GET_ASSIGNMENTS_AND_UPDATE,
    payload: { data },
  };
};

const getActivitiesForUpdateSuccess = data => {
  return {
    type: Types.SUCCESS_GET_LOGGED_ACTIVITIES_AND_UPDATE,
    payload: { data },
  };
};

export const changeCalendarStartDate = (params, calendarStartDate, mode, prevMode, isUpdateCurrentData = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      rootReducer: {
        client: { workingClientDetail },
      },
      user,
    } = state;
    const units = omit(workingClientDetail ? workingClientDetail.preferences : user.preferences, [
      'createdAt',
      'profile',
      'updatedAt',
      '__v',
      '_id',
    ]);
    let tParams = params;
    const currentState = getState();
    let isGetAssignments = mode === 'assignment';

    if (isNil(mode)) {
      isGetAssignments = currentState.rootReducer.calendar.view_mode === 'assignment';
    }

    if (!tParams) {
      calendarStartDate = currentState.calendarStartDate;
      const newEndDate = calendarStartDate.plus({ days: 7 * currentState.calendarType });
      tParams = {
        client: currentState.rootReducer.client.selected,
        start_date: calendarStartDate.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      };
    }

    if (!tParams.client) {
      return false;
    }

    if (!isNil(mode) && mode !== prevMode) {
      dispatch({ type: REQUEST_GET_ASSIGNMENTS });
    }

    return dispatch(
      Request.get(
        { url: `/api/workout/v2/assignments${isGetAssignments ? '' : '/history'}`, params: tParams },
        true,
        result => {
          const dataWk = result.data.day_data.map(itm => {
            const workouts = itm.assignments.map(itmAssi => {
              const workout = new Workout();
              const parsedWorkout = convertWorkoutUnits(workout.parseFromAssignment(itmAssi), units);
              return parsedWorkout;
            });
            const activities = isGetAssignments ? [] : itm.activities;
            return { day: itm.day, workouts: workouts, activities: activities };
          });

          if (isUpdateCurrentData) {
            dispatch(getAssignmentsForUpdateSuccess(dataWk));
            dispatch(getActivitiesForUpdateSuccess(dataWk));
            dispatch(changeCalendarStartDateSuccess(calendarStartDate));
            return;
          }

          dispatch(getAssignmentsSuccess(dataWk));
          dispatch(getActivitiesSuccess(dataWk));
          dispatch(changeCalendarStartDateSuccess(calendarStartDate));
        },
      ),
    );
  };
};
