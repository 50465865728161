import React, { useEffect, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';

import BarChart from 'shared/ChartBar';
import LineChart from 'shared/ChartLine';
import { getPackageStatistics } from 'redux/package-analytics/actions';
import { CURRENCIES, PURCHASE_STATUS } from 'constants/commonData';
import { formatChartData30Days, formatChartDataByWeek, formatChartDataUnlimitedLength } from 'utils/commonFunction';

import { Container, ColumnItem, TextBold, Desc, Wrapper, Currency, TextBoldWrapper } from './style';

const AnalyticsChart = ({ packageId, getPackageStatistics, statistics, account, currentStatus, counter, isMP }) => {
  const isShowTrailChart = useMemo(() => {
    return (
      process.env.REACT_APP_ENABLE_TRIAL_PERIOD &&
      currentStatus === PURCHASE_STATUS.TRIAL &&
      get(counter, 'purchase.trial', 0) > 0
    );
  }, [statistics, currentStatus, counter]);

  useEffect(() => {
    packageId && getPackageStatistics({ id: packageId }, isMP);
  }, [packageId, isMP]);

  const symbol = useMemo(() => {
    const obj = CURRENCIES[get(account, 'currency')] || {};
    return obj.symbol || '';
  });

  const { isFr, isNok, isDh } = useMemo(() => {
    const currency = get(account, 'currency');
    const isFr = ['chf', 'sek'].includes(currency);
    const isNok = currency === 'nok';
    const isDh = currency === 'aed';
    return { isFr, isNok, isDh };
  }, [account]);

  const { trial30Days, purchases30Days, revenue30Days, revenueAll } = useMemo(() => {
    return {
      trial30Days: formatChartData30Days(get(statistics, 'last_30d_trials.data')),
      purchases30Days: formatChartData30Days(get(statistics, 'last_30d_purchases.data')),
      revenue30Days: formatChartDataUnlimitedLength(get(statistics, 'last_30d_revenue.data')),
      revenueAll: formatChartDataByWeek(get(statistics, 'all_time_revenue.data')),
    };
  }, [statistics]);

  return (
    <Wrapper isShowTrailChart={isShowTrailChart}>
      {isShowTrailChart && (
        <Container>
          <ColumnItem>
            <TextBoldWrapper>
              <TextBold data-tip="New trials from the last 30 days" data-for={`tooltip-trials-chart`} className="reset">
                {get(statistics, 'last_30d_trials.total') ? get(statistics, 'last_30d_trials.total') : '-'}
              </TextBold>
            </TextBoldWrapper>
            <Desc>
              Last 30D <strong>Trials</strong>
            </Desc>
            <ReactTooltip id={`tooltip-trials-chart`} effect="solid" place="top" className="app-tooltip" />
          </ColumnItem>
          <ColumnItem>
            <BarChart color="#FFB224" data={trial30Days} isTrial={get(statistics, 'last_30d_trials.total', 0) !== 0} />
          </ColumnItem>
        </Container>
      )}
      <Container>
        <ColumnItem>
          <TextBoldWrapper>
            <TextBold
              data-tip="New purchases from the last 30 days"
              data-for={`tooltip-purchases-chart`}
              className="reset"
            >
              {get(statistics, 'last_30d_purchases.total') ? get(statistics, 'last_30d_purchases.total') : '-'}
            </TextBold>
          </TextBoldWrapper>
          <Desc>
            Last 30D <strong>Purchases</strong>
          </Desc>
          <ReactTooltip id={`tooltip-purchases-chart`} effect="solid" place="top" className="app-tooltip" />
        </ColumnItem>
        <ColumnItem>
          <BarChart color="#5559FF" data={purchases30Days} />
        </ColumnItem>
      </Container>
      <Container>
        <ColumnItem>
          <TextBoldWrapper>
            <TextBold data-tip="Revenue for this package from the last 30 days" data-for={`tooltip-revenue-chart`}>
              <Currency>{symbol}</Currency>
              <NumberFormat
                value={get(statistics, 'last_30d_revenue.total') / 100 || '-'}
                displayType={'text'}
                thousandSeparator={true}
                className={classNames('total-amount', {
                  fr: isFr,
                  nok: isNok,
                  dh: isDh,
                })}
              />
            </TextBold>
          </TextBoldWrapper>
          <Desc>
            Last 30D <strong>Revenue</strong>
          </Desc>
          <ReactTooltip id={`tooltip-revenue-chart`} effect="solid" place="top" className="app-tooltip" />
        </ColumnItem>
        <ColumnItem>
          <LineChart color="#0085FF" data={revenue30Days} currency={symbol} />
        </ColumnItem>
      </Container>
      <Container>
        <ColumnItem>
          <TextBoldWrapper>
            <TextBold data-tip="All time revenue for this package" data-for={`tooltip-chart`}>
              <Currency>{symbol}</Currency>
              <NumberFormat
                value={get(statistics, 'all_time_revenue.total') / 100 || '-'}
                displayType={'text'}
                thousandSeparator={true}
                className={classNames('total-amount', {
                  fr: isFr,
                  nok: isNok,
                  dh: isDh,
                })}
              />
            </TextBold>
          </TextBoldWrapper>
          <Desc>
            All Time <strong>Revenue</strong>
          </Desc>
          <ReactTooltip id={`tooltip-chart`} effect="solid" place="top" className="app-tooltip" />
        </ColumnItem>
        <ColumnItem>
          <LineChart color="#35D64F" data={revenueAll} currency={symbol} />
        </ColumnItem>
      </Container>
    </Wrapper>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageAnalytics, setupPayment },
  } = state;

  return {
    statistics: packageAnalytics.toJS().statistics,
    account: setupPayment.toJS().account,
    currentStatus: packageAnalytics.toJS().query.status,
    counter: packageAnalytics.toJS().counter,
  };
};

export default connect(mapState, { getPackageStatistics })(AnalyticsChart);
