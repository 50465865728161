import React, { useCallback } from 'react';
import _ from 'lodash';

import * as S from './style';

const PasteButtonWeek = props => {
  const { handlePasteWeek, weekId } = props;
  const debouncePasteWeek = useCallback(_.debounce(handlePasteWeek, 100), []);

  const onPasteWeek = event => {
    const multiPaste = event.shiftKey;
    debouncePasteWeek({ weekId, multiPaste });
  };

  return (
    <S.ActionPasteWeek>
      <S.PasteButton onClick={event => onPasteWeek(event)}>Paste Week</S.PasteButton>
    </S.ActionPasteWeek>
  );
};

export default PasteButtonWeek;
