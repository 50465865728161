import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UploadCoverImage from 'components/StudioProgram/List/CreateStudioProgramModal/CreateNewStudioProgram/UploadCoverImage';
import { removeUploadedFileFromServer } from 'utils/commonRequest';
import ProgramNameEditable from './ProgramNameEditable';
import ProgramDescriptionEditable from './ProgramDescriptionEditable';
import { updateStudioProgram } from 'redux/studio-program/actions';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { isTeamAdmin } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';

class OverviewBaseInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.checkOwnerInfo();
  }

  componentDidMount() {
    this.checkOwnerInfo();
  }

  componentDidUpdate(prevProps) {
    const { workingStudio: { author, share } = {} } = this.props;
    const { workingStudio: { author: prevAuthor, share: prevShare } = {} } = prevProps;

    if (author !== prevAuthor || share !== prevShare) {
      this.checkOwnerInfo();
    }
  }

  checkOwnerInfo() {
    const { workingStudio = {}, user = {} } = this.props;
    this.author = typeof workingStudio.author_info === 'object' ? workingStudio.author_info : undefined;
    this.authorId = (this.author || {})._id;
    this.isCreator = user._id === this.authorId;
    this.isOwnerOrAdmin = isTeamAdmin(user);
    this.isNoOwner = workingStudio.share === TEAM_SHARE_NOOWNER;
    this.isCreatorOrOwnerOrAdmin = this.isCreator || this.isOwnerOrAdmin || this.isNoOwner;
  }

  onRemove = () => {
    const { bucketData } = this.state;
    this.setState({}, () => {
      if (bucketData) {
        removeUploadedFileFromServer(bucketData);
      }
    });
  };

  onUploadSuccess = ({ src, bucketData, url }) => {
    this.setState({ src, bucketData, isUploading: false });
    return this.props
      .updateStudioProgram(this.props.workingStudio._id, {
        cover_image: get(bucketData, 'location', url.substring(0, url.indexOf('?X-Amz-Algorithm'))),
      })
      .then(res => {
        if (res) {
          this.showSuccessNotify('image');
        }
      });
  };

  onStartUpload = () => {
    this.setState({ isUploading: true });
  };

  onEndUpload = () => {
    this.setState({ isUploading: false });
  };

  onConfirmChange = (data, type) => {
    return this.props.updateStudioProgram(this.props.workingStudio._id, data).then(res => {
      if (res) {
        this.showSuccessNotify(type);
      }
    });
  };

  showSuccessNotify = type => {
    toast(`Program ${type} has been updated for all assigned clients`);
  };

  render() {
    const { workingStudio } = this.props;
    const coverImage = workingStudio.cover_image || `${CDN_URL}/images/studio_program_default_cover_image.png`;
    return (
      <S.Wrapper>
        <div className="overviewInfo__left">
          <UploadCoverImage
            key="cover_image"
            onRemove={this.onRemove}
            onUploadSuccess={this.onUploadSuccess}
            onStartUpload={this.onStartUpload}
            onEndUpload={this.onEndUpload}
            src={coverImage}
            uploadKey="media"
            disabled={!this.isCreatorOrOwnerOrAdmin}
          />
        </div>
        <div className="overviewInfo__right">
          <ProgramNameEditable
            name={workingStudio.program_name}
            onConfirmChange={this.onConfirmChange}
            disabled={!this.isCreatorOrOwnerOrAdmin}
          />
          <ProgramDescriptionEditable
            description={workingStudio.description}
            onConfirmChange={this.onConfirmChange}
            disabled={!this.isCreatorOrOwnerOrAdmin}
          />
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateTopProps = state => {
  return {
    user: state.user,
    workingStudio: state.rootReducer.studioProgram.workingStudio || {},
  };
};

const mapDispatch = dispatch => ({
  updateStudioProgram: bindActionCreators(updateStudioProgram, dispatch),
});

export default connect(mapStateTopProps, mapDispatch)(OverviewBaseInfo);
