import styled, { css } from 'styled-components';
import { AUTOFLOW_TYPES } from 'constants/commonData';

export const MenuListWrapper = styled.div`
  overflow: hidden;
  width: 304px;
  padding-right: 4px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 4px 16px 0px rgba(38, 38, 38, 0.2);
  &.is-program-and-forum {
    width: 394px;
  }
  &.bottom-right {
    position: absolute;
    right: 0;
  }
  &.top-right {
    position: absolute;
    right: 0;
    bottom: 100%;
  }
`;

export const MenuList = styled.div`
  display: flex;
  height: 320px;
  overflow: auto;
  padding-top: 20px;
  flex-direction: column;
  align-items: flex-start;

  ::-webkit-scrollbar {
    width: 4px !important;
  }

  ::-webkit-scrollbar-track {
    margin-top: 48px;
  }

  .menu-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 0 8px 10px 16px;

    .menu-title {
      color: #000;
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .group-loading {
    padding: 10px;
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }

  .boxLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-height: 50px;
    margin: 10px 0;

    &.textLoading {
      font-size: 13px;
      line-height: 18px;
      color: rgba(32, 35, 83, 0.6);
    }

    .ringLoading div {
      box-sizing: border-box;
      display: block;
      width: 24px;
      height: 24px;
      margin: 8px;
      border: 2px solid #5158cf;
      border-radius: 50%;
      animation: lds-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #5158cf transparent transparent transparent;

      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  cursor: pointer;

  ::after {
    content: '';
    height: 1px;
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    background: #eeeff2;
  }

  :nth-of-type(2) {
    ::after {
      height: 0px;
    }
  }

  :hover {
    background-color: #f6f5ff;
    .title {
      color: #5158cf;
    }
  }

  ${props =>
    props.isSelected &&
    css`
      background-color: #f6f5ff;
      .title {
        color: #5158cf;
      }
    `}
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding: 10px 0;
  position: relative;
`;

export const HorizontalValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 0;
`;

export const ValueTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: break-spaces;
  word-break: break-all;
  margin-bottom: ${props => props.hasAutoflow && '30px'};
  margin-top: ${props => props.hasLabel && '25px'};
`;

export const ValueLabel = styled.div`
  display: flex;
  width: 102px;
  padding: 4px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;

  ${props =>
    props.type === AUTOFLOW_TYPES.INTERVAL &&
    css`
      color: #3bb240;
      background: #eafcf1;
    `}

  ${props =>
    props.type === AUTOFLOW_TYPES.EXACT_DATE &&
    css`
      color: #2480d6;
      background: #e3f2ff;
    `}
`;

export const AutoflowLabel = styled.div`
  display: flex;
  width: 102px;
  position: absolute;
  bottom: 10px;

  .autoflow-setting-icon {
    position: absolute;
    right: -31px;
  }
`;

export const CoverImage = styled.div`
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 4px;
  }
`;

export const DeletedText = styled.p`
  color: #7f88a5;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  position: absolute;
  top: 10px;
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .group-loading {
    padding-top: 0;
    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const EmptyMessage = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  opacity: 0.2;
`;

export const AssignTrigger = styled.button`
  cursor: pointer;
  padding: 5px 7px;

  background: #ffffff;
  color: #5e59ff;
  border: 1px solid #5e59ff;
  border-radius: 4px;

  &:hover {
    border-color: #2b26c1;
    color: #2b26c1;
  }

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const FormWrapper = styled.div`
  padding: 12px 16px;
  gap: 12px;
`;

export const FormTitle = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: break-spaces;
  word-break: break-all;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
  position: relative;
`;

export const QuestionTypeWrapper = styled.div`
  min-width: 39px;
  height: 20px;
  padding: 0px 4px;
  background: #ee586c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  gap: 4px;
  border-radius: 2px;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 700;
    line-height: 17.7px;
  }
`;
