import React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import { MenuTrigger } from 'shared/Icons';

import { CDN_URL } from 'constants/commonData';

import { useMyContext } from '../context/GroupBodyMetricsContext';
import * as S from '../style';

const MoreOptionsGroup = props => {
  const { itemId, isEmptyMetric = false, metrics = [], confirmId = '' } = props;
  const {
    openIndividualPopup,
    handleIndividualTogglePopup,
    handleIconAction,
    handleConfirmRemoveGroup,
    handleSaveGroupToLibrary,
  } = useMyContext();

  return (
    <S.AddIndividualPopup
      trigger={
        <S.IconTrigger className={classNames({ selected: openIndividualPopup === itemId })} onClick={handleIconAction}>
          <MenuTrigger
            itemId={itemId}
            vertical={true}
            size={20}
            active={openIndividualPopup === itemId}
            className="button-more-metric-to-group"
          />
        </S.IconTrigger>
      }
      flowing={true}
      hoverable={true}
      on="click"
      position="bottom right"
      open={openIndividualPopup === itemId}
      onOpen={handleIndividualTogglePopup(itemId)}
      onClose={handleIndividualTogglePopup(itemId)}
      className="individual-action-popup"
      keepInViewPort={true}
      basic={true}
    >
      <RootCloseWrapper
        event="click"
        disabled={openIndividualPopup !== itemId}
        onClick={handleIndividualTogglePopup(itemId)}
      >
        <S.PopupIndividualContent>
          <S.PopupIndividualItem onClick={handleSaveGroupToLibrary(confirmId)}>
            <img className="icon save" src={`${CDN_URL}/images/save_to_library.svg`} alt="" />
            <div className="label">Save to Library</div>
          </S.PopupIndividualItem>
          <S.PopupIndividualItem onClick={handleConfirmRemoveGroup({ groupId: confirmId, isEmptyMetric, metrics })}>
            <img className="icon remove" src={`${CDN_URL}/images/delete.svg`} alt="" />
            <div className="label">Remove</div>
          </S.PopupIndividualItem>
        </S.PopupIndividualContent>
      </RootCloseWrapper>
    </S.AddIndividualPopup>
  );
};

export default MoreOptionsGroup;
