import { TAGS_TYPE } from 'constants/commonData';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMostRecentTagsList } from 'redux/tags/actions';
import Filter from 'shared/ExerciseFilterPopup';

import { FilterStyles } from './styles';
import { SharedTooltip } from 'shared/SharedTooltip';

const FilterBtn = ({
  bodyParts,
  categories,
  currentFilters,
  onChange,
  exerciseTags,
  getMostRecentTagsList,
  equipments,
  modalities,
  muscleGroup,
  movementPattern,
}) => {
  useEffect(() => {
    getMostRecentTagsList({
      page: 1,
      perPage: 20,
      sort: -1,
      type: TAGS_TYPE.EXERCISE,
    });
  }, []);
  const onApplyFilter = data => {
    onChange({ ...currentFilters, ...data });
  };

  return (
    <Filter
      tags={exerciseTags}
      bodyParts={bodyParts}
      categories={categories}
      currentFilters={currentFilters}
      onApply={onApplyFilter}
      menuHeight={585}
      equipments={equipments}
      modalities={modalities}
      muscleGroup={muscleGroup}
      movementPattern={movementPattern}
      trigger={<FilterStyles className="workout-builder__filter-icon" data-tip data-for="filter-button-tooltip" />}
      triggerTooltip={<SharedTooltip content="Filter" id="filter-button-tooltip" position="bottom" />}
    />
  );
};
//     getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),

const mapState = state => {
  const {
    rootReducer: {
      exercise: { body_parts, categories, equipments, modalities, muscleGroup, movementPattern },
      exerciseTags,
    },
  } = state;
  return { bodyParts: body_parts, categories, exerciseTags, equipments, modalities, muscleGroup, movementPattern };
};

const mapDispatch = dispatch => ({
  getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
});

export default connect(mapState, mapDispatch)(FilterBtn);
