import styled, { css } from 'styled-components';

export const SurveyDetailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  z-index: 1000;
  button {
    padding-block: 0;
    padding-inline: 0;
    border-width: 0;
    border-style: none;
    border-color: transparent;
    background: transparent;
  }
`;

export const SurveyDetailHeader = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  padding: 0px 35px;
  border-bottom: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1f2122;
  border-bottom: 1px solid #ededed;
  span {
    font-family: 'Open Sans';
    font-weight: 600;
    color: #ffffff;
  }
  .description {
    font-size: 13px;
    line-height: 19.5px;
  }
  svg {
    cursor: pointer;
  }
  .preview-mode {
    position: absolute;
    top: 0;
    left: 310px;
    right: 300px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans';
    .laptop-mode,
    .mobile-mode {
      width: 40px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
      cursor: pointer;
    }
    .active {
      background-color: #ffffff;
      border: none;
    }
    .laptop-mode {
      border-radius: 5px 0 0 5px;
    }
    .mobile-mode {
      border-radius: 0 5px 5px 0;
    }
  }
`;

export const SurveyDetailContentWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 30px;
  overflow: scroll;
  .survey-detail-content {
    width: 825px;
    height: 700px;
    border-radius: 16px;
    background-color: #ffffff;
    .header {
      border-bottom: 1px solid #f2f2f2;
      width: 100%;
      height: 73px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 32px 0 35px;
      .client-info-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 11px;
        .avatar {
          flex: 0 0 40px;
        }
        .name-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2px;
          .trainer-name {
            font-family: 'Open Sans';
            font-size: 16px;
            font-weight: 600;
            line-height: 21.79px;
            color: #141414;
            max-width: 690px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
          .package-name {
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: 400;
            line-height: 19.07px;
            color: #363636;
            max-width: 690px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
    .body {
      padding: 0 8px;
      .body-content-wrapper {
        .custom-loading {
          padding-top: 223px !important;
        }
        padding: 24px 19px 24px 24px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 548px;
        ::-webkit-scrollbar {
          width: 5px !important;
        }
        ::-webkit-scrollbar-track {
          background: #ffffff !important;
          margin-top: 24px !important;
        }
        ::-webkit-scrollbar-thumb {
          background: #e5e5e5 !important;
          border-radius: 9999px !important;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #e5e5e5 !important;
        }
      }
    }
    .footer {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 0 32px;
      border-top: 1px solid #f2f2f2;
      .step-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        .step {
          width: 20px;
          height: 6px;
          border-radius: 9999px;
          background: #e5e5e5;

          &.active {
            width: 40px;
            background: #141414;
          }
        }
      }
      .button-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        button {
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }
        .back {
          border: 1px solid #e5e5e5;
          width: 70px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #141414;
        }
        .submit {
          width: 86px;
          height: 40px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #0038ff;
          color: #ffffff;
        }
      }
    }
  }
  ${props =>
    props.isMobile &&
    css`
      .survey-detail-content {
        width: 375px;
        height: 750px;
        border-radius: 0;
        .header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 28px 0 18px;
          .client-info-wrapper {
            .name-wrapper {
              .trainer-name {
                max-width: 260px;
              }
              .package-name {
                max-width: 260px;
              }
            }
          }
        }
        .body {
          padding: 0;
          .body-content-wrapper {
            padding: 24px 11px 24px 16px;
            height: 598px;
          }
        }
        .footer {
          padding: 0 14px 0 16px;
        }
      }
    `}
`;

export const SurveyQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .introduction {
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 6px;
    background: #f2f2f2;
    span {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #141414;
    }
  }
  ${props =>
    props.isMobile &&
    css`
      gap: 24px;
    `}
`;

export const SurveyQuestionForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .question-title-wrapper {
    display: flex;
    align-items: baseline;
    gap: 18px;
    .question-index {
      width: 32px;
      height: 20px;
      border-radius: 8px;
      background: #141414;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .question-title {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #141414;
      flex: 1;
    }
  }
  .input-wrapper {
    width: 100%;
    height: 95px;
    padding: 16px 16px 16px 20px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    .placeholder {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #8c8c8c;
    }
  }
`;
