import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import DateTime from 'react-datetime';

import { DateInput } from 'shared/FormControl';
import { DATE_FORMAT } from 'components/FormDetails/constants';

import { DateTimeWrapper } from './styles';

const CustomDateTime = ({ value, onChange, errorMessage, dateFormat = DATE_FORMAT, timezone }) => {
  const today = moment().tz(timezone).format(DATE_FORMAT);

  const renderDateTimeInput = props => {
    return (
      <DateInput
        {...props}
        className={classNames(
          'purchase-date-time',
          !value ? 'placeholder-input' : '',
          errorMessage ? 'error-input' : '',
        )}
        text={!!value ? value : dateFormat}
      />
    );
  };

  const isValidDate = current => {
    return current.isSameOrAfter(today, 'day');
  };

  const isToday = current => {
    return current.isSame(today, 'day');
  };

  const renderDay = (props, currentDate) => {
    let className = props.className;
    if (isToday(currentDate)) {
      className += ' custom-rdtToday';
    }
    return (
      <td {...props} className={className}>
        {currentDate.date()}
      </td>
    );
  };

  return (
    <DateTimeWrapper>
      <DateTime
        value={moment(value, dateFormat).format(DATE_FORMAT)}
        renderInput={renderDateTimeInput}
        timeFormat={false}
        onChange={onChange}
        closeOnSelect
        className="new-ui"
        dateFormat={DATE_FORMAT}
        isValidDate={isValidDate}
        renderDay={renderDay}
      />
    </DateTimeWrapper>
  );
};

export default CustomDateTime;
