import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import classNames from 'classnames';

import { ErrorMessage, FormGroup } from 'shared/FormControl';
import { validateVimeoUrl, getLinkType, validateLink, autoAddHttps, validateYouTubeUrl } from 'utils/validations';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import MediaPlayer from 'shared/MediaPlayer';

import { MEDIA_TYPES } from './helper';
import { ReactComponent as LinkIcon } from 'assets/icons/on-demand-workout-video-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/freestyle-section-close-icon.svg';
import { MEDIA_PLACEHOLDER } from 'constants/commonData';
import * as S from './style';
import { generateVimeoAPIUrl } from 'utils/commonFunction';

class LinkAttachment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      attachmentData: { type: MEDIA_TYPES.LINK, url: '' },
      videoLinkError: false,
    };

    this.linkInput = React.createRef();
    this.uploadingCoverImage = null;
    this.getMetaDataDebounce = _.debounce(this.getLinkMetaData, 300);
  }

  componentDidUpdate(prevProps) {
    const attachmentData = this.props.attachment || {};
    const linkUrl = attachmentData.url || attachmentData.link || '';

    const prevAttachmentData = prevProps.attachment || {};
    const prevLinkUrl = prevAttachmentData.url || prevAttachmentData.link || '';

    if (linkUrl !== prevLinkUrl) {
      const link_type = getLinkType(linkUrl);
      this.setState({ attachmentData: { ...attachmentData, url: linkUrl, link: linkUrl }, link_type });
    }
  }

  componentDidMount() {
    const attachmentData = _.get(this.props, 'attachment');

    if (attachmentData) {
      const link_type = getLinkType(attachmentData.url);
      this.setState({ attachmentData, link_type });
    } else {
      if (this.linkInput.current) {
        this.linkInput.current.focus();
      }
    }
  }

  markUploadDone = () => {
    this.props.updateUploadStatus([{ uploadId: this.uploadingCoverImage, status: true }]);
    this.uploadingCoverImage = null;
  };

  getLinkMetaData = url => {
    this.props.onStartFetchingMeta();
    const linkType = getLinkType(url);

    getPageMetaDataFromURL(url).then(response => {
      const {
        data: {
          data: { image, title },
        },
      } = response;

      const { hostname } = new URL(url);

      this.setState(
        {
          attachmentData: {
            ...this.state.attachmentData,
            thumbnail_url: image,
            name: ['youtube', 'vimeo'].includes(linkType) ? title : hostname,
          },
        },
        () => {
          this.props.onUpdateAttachmentData([this.state.attachmentData]);
          this.props.onEndFetchingMeta();
        },
      );
    });
  };

  onChangeLink = event => {
    const { setVideoLinkError, onUpdateAttachmentData } = this.props;
    let value = event.target.value || '';
    let emptyUrl = value === '';

    const trimmedValue = autoAddHttps(value.trim());
    const link_type = getLinkType(trimmedValue);
    this.setState(
      {
        link_type,
        attachmentData: {
          ...this.state.attachmentData,
          url: value.trim(),
          thumbnail_url: emptyUrl ? null : this.state.attachmentData.thumbnail_url,
        },
      },
      () => {
        if (!value && this.uploadingCoverImage) {
          this.markUploadDone();
        }

        onUpdateAttachmentData(this.state.attachmentData.url ? [this.state.attachmentData] : null);
      },
    );
    if (trimmedValue) {
      if (validateVimeoUrl(trimmedValue)) {
        axios
          .get(generateVimeoAPIUrl(trimmedValue))
          .then(response => {
            const data = response.data || {};
            this.setState(
              {
                attachmentData: {
                  ...this.state.attachmentData,
                  thumbnail_url: data.thumbnail_url || MEDIA_PLACEHOLDER,
                  name: data.title,
                },
                videoLinkError: false,
              },
              () => {
                setVideoLinkError(false);
                onUpdateAttachmentData([this.state.attachmentData]);
              },
            );
          })
          .catch(() => {
            const { attachmentData } = this.state;

            if (attachmentData.link === trimmedValue) {
              this.setState({ videoLinkError: true });
              setVideoLinkError(true);
            }
          });
      } else {
        const youtubeId = validateYouTubeUrl(trimmedValue);
        const invalidLink = !youtubeId;
        if (validateLink(trimmedValue) && !invalidLink) {
          this.setState({ videoLinkError: false });
          setVideoLinkError(false);
          this.getMetaDataDebounce(trimmedValue);
        } else if (validateLink(trimmedValue) && invalidLink) {
          setVideoLinkError(true);
          this.setState({ videoLinkError: true });
        }
      }
    }
  };

  handleCloseLink = () => {
    const { onClose } = this.props;
    this.setState(
      {
        attachmentData: { type: MEDIA_TYPES.LINK, url: '' },
      },
      () => {
        onClose && onClose();
      },
    );
  };

  render() {
    const { attachmentData, link_type, videoLinkError } = this.state;
    const { background, showErrors, disabled = false } = this.props;
    const shouldPlay = ['youtube', 'vimeo'].includes(link_type);
    return (
      <S.LinkAttachmentWrapper>
        <FormGroup
          className={classNames('form-group--link', {
            error:
              (attachmentData.url && !validateLink(attachmentData.url)) ||
              (attachmentData.url === '' && showErrors) ||
              videoLinkError,
            hasUrl: !!attachmentData.url,
          })}
          largeMargin
        >
          <div className="container">
            {['youtube', 'vimeo', 'link'].includes(link_type) &&
            (attachmentData.thumbnail_url || attachmentData.url) ? (
              <S.MediaLinkPreview
                background={
                  background || (attachmentData.url ? attachmentData.thumbnail_url || MEDIA_PLACEHOLDER : null)
                }
                shouldRenderPlayIcon={shouldPlay}
              >
                {shouldPlay && (
                  <MediaPlayer url={attachmentData.url} type="video" thumbnail={attachmentData.thumbnail_url} />
                )}
              </S.MediaLinkPreview>
            ) : (
              <LinkIcon className="link-icon-placeholder" />
            )}
            <input
              className="link-input"
              value={attachmentData.url}
              placeholder="Youtube / Vimeo Link"
              onChange={this.onChangeLink}
              ref={this.linkInput}
              disabled={disabled}
            />
            {!disabled && <CloseIcon onClick={this.handleCloseLink} className="close-icon" />}
          </div>
          {(attachmentData.url && !validateLink(attachmentData.url)) || videoLinkError ? (
            <ErrorMessage className="error-message">Please add a valid Youtube or Vimeo link</ErrorMessage>
          ) : null}
        </FormGroup>
      </S.LinkAttachmentWrapper>
    );
  }
}

export default LinkAttachment;
