import React, { useEffect } from 'react';
import { components } from 'react-select';
import get from 'lodash/get';

const CustomOption = props => {
  useEffect(() => {
    const id = get(props, 'innerProps.id');
    if (props.isSelected && id) {
      const element = document.getElementById(id);
      element && element.scrollIntoView({ block: 'start' });
    }
  }, [props.isSelected]);

  return <components.Option {...props}>{props.children}</components.Option>;
};

export default CustomOption;
