import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Group = styled.div`
  .label {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
  }

  .group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;

    > * {
      margin-right: 10px;

      :last-child {
        margin-right: 0;
      }
    }
  }

  .evfSelectBoxContainer {
    .evfSelectBox__control {
      border-radius: 5px;
      background-color: #fff;

      .evfSelectBox__value-container {
        padding-left: 14px;

        .evfSelectBox__placeholder {
          opacity: 1;
        }
      }

      .evfSelectBox__indicators {
        padding-right: 14px;
      }

      .evfSelectBox__single-value {
        color: #000;
        font-weight: normal;
      }
    }

    &.highlight {
      .evfSelectBox__control {
        border-color: #5158cf;
      }
    }

    &.error {
      .evfSelectBox__control {
        border-color: #ff1b1b;
      }
    }
  }

  .rdt {
    .custom-date-input {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      background: #ffffff;
      border: 1px solid #d4d7d9;
      border-radius: 5px;
      padding: 11px 14px;
      min-width: 134px;
    }

    .rdtPicker {
      margin-top: 0px;
      bottom: calc(100% + 4px);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px;
    }

    &.error {
      .custom-date-input {
        border-color: #ff1b1b;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  background: #f7f8fa;
  border-radius: 8px;
  padding: 25px 20px;

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #202353;
    margin-bottom: 15px;
  }
`;

export const Arrow = styled.div`
  flex: 0 0 16px;
  width: 16px;
  height: 10px;
  margin: 0 15px;
  background: url(${CDN_URL}/images/arrow_long_right.svg) no-repeat center center;
  background-size: auto;
  margin-top: 36px;
`;
