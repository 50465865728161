import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChangeBillingContact from './component';
import { updateBillingContact } from 'redux/billing/actions';
import { updateEmailBilling } from 'actions/profile';

const mapState = (state) => {
  const {
    rootReducer: {
      billing: {
        paymentInfo: { billing_contact },
      },
    },
  } = state;

  return { data: billing_contact || {} };
};

const mapDispatch = (dispatch) => ({
  updateBillingContact: bindActionCreators(updateBillingContact, dispatch),
  updateEmailBilling: bindActionCreators(updateEmailBilling, dispatch),
});

export default connect(mapState, mapDispatch)(ChangeBillingContact);
