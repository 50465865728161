/**
 * @flow
 */

import { connect } from 'react-redux';
import { get } from 'lodash';

import NoteOverview from './component';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const selectedClient = rootReducer.client.workingClientDetail;
  const isHabitPermission = get(rootReducer, 'permission.habit', false);

  return {
    selectedClient: selectedClient,
    isHabitPermission,
  };
};

export default connect(mapStateToProps)(NoteOverview);
