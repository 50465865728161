import React from 'react';
import { get } from 'lodash';
import classnames from 'classnames';
import styled from 'styled-components';

import Superset from '../Superset';
import LinkSet, { LINK_TYPES } from '../LinkSet';
import ExcerciseDroppable from './ExcerciseDroppable';
import ChangeOrderButton from './ChangeOrderButton';

const SupersetWrapper = props => {
  const { supersetId, index, sectionId, exerciseId, size, exerciseIndex } = props;

  return (
    <React.Fragment>
      <Superset supersetId={supersetId} exerciseId={exerciseId} exerciseIndex={exerciseIndex} linkIndex={index} />
      {index < size - 1 && (
        <LinkSet
          type={LINK_TYPES.UNLINK}
          sectionId={sectionId}
          exerciseId={exerciseId}
          supersetId={supersetId}
          linkIndex={index}
        />
      )}
    </React.Fragment>
  );
};

function Exercise(props) {
  const {
    model,
    sectionId,
    exerciseId,
    exerciseIndex,
    exerciseSize,
    draggingItemLeft,
    isHiddenSection,
    droppedExercise,
    supersetEntities,
  } = props;
  const supersets = model.get('supersets');
  const supersetSize = supersets.size;
  const isDragMode = !!draggingItemLeft && draggingItemLeft.get('dragType') === 'exercise';

  const isContainExerciseNotExisted = supersets.some(supersetId => {
    const superset = supersetEntities.get(supersetId);
    if (superset && superset.toJS) {
      const data = superset.toJS();
      return get(data, 'exercise_instance.is_existing') === false;
    }
    return false;
  });

  return (
    <Wrapper className="exercise">
      {isDragMode && !isHiddenSection && (
        <ExcerciseDroppable sectionId={sectionId} exerciseId={exerciseId} exerciseIndex={props.exerciseIndex} />
      )}
      <div
        className={classnames('exercise__supperset', {
          borderBlink: droppedExercise === exerciseId,
          'warning-exercise': isContainExerciseNotExisted,
        })}
      >
        {supersets.map((supersetId, index) => (
          <SupersetWrapper
            index={index}
            supersetId={supersetId}
            sectionId={sectionId}
            exerciseId={exerciseId}
            size={supersetSize}
            key={supersetId}
            exerciseIndex={exerciseIndex}
          />
        ))}
      </div>
      {isDragMode && !isHiddenSection && (
        <ExcerciseDroppable
          bottom
          sectionId={sectionId}
          exerciseId={exerciseId}
          exerciseIndex={props.exerciseIndex + 1}
        />
      )}
      <ChangeOrderButton
        className="exercise__change-order"
        sectionId={sectionId}
        exerciseId={exerciseId}
        exerciseIndex={exerciseIndex}
        exerciseSize={exerciseSize}
      />
    </Wrapper>
  );
}

export default Exercise;

const Wrapper = styled.div`
  position: relative;
  .exercise__supperset {
    border: 1px solid #dadfea;
    box-shadow: 0px 2px 3px rgba(76, 98, 135, 0.25);
    border-radius: 8px;
    background-color: #fff;

    &.warning-exercise {
      border: 1px solid #fd6666;
    }
  }

  .exercise__change-order {
    visibility: hidden;
  }

  :hover {
    .exercise__change-order {
      visibility: visible;
    }
  }
`;
