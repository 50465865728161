// Lib
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';

// Store
import {
  addMealPlanDayDetail,
  changeModeEditByDay,
  getDetailMealPlan,
  getMealPlanDayOfWeek,
  resetDetailMealPlan,
  resetDetailMealPlanByDay,
  resetDetailMealPlanByWeek,
  selectMealPlanDayDetail,
  updateMealPlanByDay,
  updateMealPlanDayOfWeek,
  cancelEditMealPlan,
} from 'redux/meal-plans/actions';
import {
  changeQueryParams,
  getInitTotalRecipe,
  getRecipeLibraries,
  resetCurrentList,
  resetFilters,
} from 'redux/recipes/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { showError } from 'actions/error';

// Share
import MealPlanDayDetail from './component';

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { mealPlans, recipes, pricing },
    sideBarVisible,
    cloudfrontList,
  } = state;

  return {
    detailMealPlanByWeek: get(mealPlans, 'detailMealPlanByWeek', {}),
    detailMealPlanByDay: get(mealPlans, 'detailMealPlanByDay', {}),
    listMealGroup: get(mealPlans, 'detailMealPlanByDay.meal_group', []),
    selected: get(mealPlans, 'detailMealPlanByDay.selected', ''),
    isShowDietary: get(mealPlans, 'detailMealPlanByDay.show_dietary_info', []),
    editModeByDay: get(mealPlans, 'editModeByDay', false),
    loadingByDay: get(mealPlans, 'loadingByDay', false),
    filters: get(recipes, 'filters', {}),
    listRecipes: get(recipes, 'list', []),
    total: get(recipes, 'total', []),
    loadingRecipes: get(recipes, 'loading', false),
    initTotal: get(recipes, 'initTotal', null),
    isChange: get(mealPlans, 'detailMealPlanByDay.isChange', false),
    sideBarVisible,
    detailMealPlan: get(mealPlans, 'detailMealPlan', {}),
    cloudfrontList,
    listDietary: get(recipes, 'listDietary', []),
    user,
    paymentOverdue: pricing.getIn(['teamData', 'payment_base_over_due']) || false,
    addOnsOverdue: pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
    zapierOverdue: pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
    paymentWhiteLabelOverdue: pricing.getIn(['teamData', 'payment_base_over_due_enterprise']) || false,
  };
};

const mapDispatchToProps = dispatch => ({
  addMealPlanDayDetail: bindActionCreators(addMealPlanDayDetail, dispatch),
  updateMealPlanByDay: bindActionCreators(updateMealPlanByDay, dispatch),
  selectMealPlanDayDetail: bindActionCreators(selectMealPlanDayDetail, dispatch),
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  getRecipeLibraries: bindActionCreators(getRecipeLibraries, dispatch),
  getMealPlanDayOfWeek: bindActionCreators(getMealPlanDayOfWeek, dispatch),
  resetDetailMealPlanByDay: bindActionCreators(resetDetailMealPlanByDay, dispatch),
  updateMealPlanDayOfWeek: bindActionCreators(updateMealPlanDayOfWeek, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  changeModeEditByDay: bindActionCreators(changeModeEditByDay, dispatch),
  getDetailMealPlan: bindActionCreators(getDetailMealPlan, dispatch),
  resetDetailMealPlan: bindActionCreators(resetDetailMealPlan, dispatch),
  resetDetailMealPlanByWeek: bindActionCreators(resetDetailMealPlanByWeek, dispatch),
  resetFilters: bindActionCreators(resetFilters, dispatch),
  getInitTotalRecipe: bindActionCreators(getInitTotalRecipe, dispatch),
  push: bindActionCreators(push, dispatch),
  showError: bindActionCreators(showError, dispatch),
  resetCurrentList: bindActionCreators(resetCurrentList, dispatch),
  cancelEditMealPlan: bindActionCreators(cancelEditMealPlan, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanDayDetail);
