import styled from 'styled-components';

export const Overview = styled.div`
  padding: 0 48px 0;
  height: 504px;

  ::-webkit-scrollbar {
    width: 5px !important;
  }

  .title__wrapper {
    .count {
      margin-top: 8px;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #8c8c8c;
      opacity: 1;
    }

    .text-editable__container {
      min-width: 736px;
    }

    .evf-dropdown__menu {
      padding: 0;
      background: #fff;
      overflow: hidden;
      border: 1px solid #dadfea;
    }

    .modal__actions {
      border-top: 1px solid #dadfea;
      padding: 10px 25px;
      box-shadow: none;
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding: 10px 32px 10px 48px;
  position: absolute;
  left: -32px;
  right: -32px;
  bottom: -91px;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
  .actions__saveClose {
    min-width: 90px;
    padding: 7px 10px;
    border: 1px solid #d6dae4;
    line-height: 150%;
    height: 34px;

    :disabled {
      background: #fff;
      opacity: 0.3;
    }
  }

  .actions__save {
    min-width: 140px;
    max-width: 140px;
    padding: 7px 30px;
    :hover:not(&.disabled) {
      background: rgba(81, 88, 207, 0.9);
    }

    &.disabled {
      color: #fff;
      background: #bfbfbf;
      opacity: 0.5;
    }
  }
`;
