// Libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, CartesianGrid, ReferenceLine } from 'recharts';
import classNames from 'classnames';
import moment from 'moment';

// Components
import HourlyTimeConnectedStagesBarShape from './HourlyTimeConnectedStagesBarShape';
import HourlyTimeConnectedStagesTooltip from './HourlyTimeConnectedStagesTooltip';
import { handleHourlyTickFormat } from 'components/BodyMetricChartNew/chartHelper';
import CustomGoalDot from '../../CustomGoal';

import { ASLEEP_COLORS_GRADIENT, LINE_CONNECTS_COLOR, SLEEP_CORE_STAGES, SLEEP_STAGE_KEYS } from './constants';
import { DATE_TIME_SLEEP, getOffsetColor, getRangeOfSleep, getStartEndSleeps } from './helpers';
import { mongoObjectId } from 'utils/commonFunction';
import {
  getHourlyGoalValue,
  getDomainsHourlyWithGoal,
  getFinalDomainsHourly,
  roundUpInteger,
  getTickLabelForHourlyStages,
} from './hourly-helper';

const HourlyTimeConnectedStagesChart = props => {
  const { chartData: originData, width = 500, height = 400, isLoading, bodyMetric } = props;
  const isEmpty = originData.length === 0;
  const {
    metricSettings,
    target,
    filterTime: { to_date },
  } = bodyMetric;
  const [tooltipPosition, setTooltipPosition] = useState(undefined);

  const fillDataTypes = useCallback(dataType => {
    const MINUTE_TO_HOUR = 0.0166667;
    const items = [];

    for (let index = 0; index < (dataType || []).length; index++) {
      const item = dataType[index];
      const minute = moment(item.end).diff(moment(item.start), 'm');
      const hour = Number((minute * MINUTE_TO_HOUR).toFixed(2));

      if (index === 0) {
        items.push({ ...item, time: minute, value: hour });
      } else {
        const prevItem = dataType[index - 1];
        if (item.start !== prevItem.end) {
          const minute = moment(item.start).diff(moment(prevItem.end), 'm');
          const hour = Number((minute * MINUTE_TO_HOUR).toFixed(2));

          const emptyItem = {
            ...prevItem,
            type: 'empty',
            start: prevItem.end,
            end: item.start,
            value: hour,
            time: minute,
          };
          items.push(emptyItem);
        }
        items.push({ ...item, time: minute, value: hour });
      }
    }

    return items;
  }, []);

  const chartData = useMemo(() => {
    const finalChartData = [];
    const allStage = originData.flatMap(item => item.grouped_sleep_in_day.flatMap(child => child));
    const groupStages = allStage.reduce((obj, stage) => {
      const isAsleepRem = [SLEEP_STAGE_KEYS.asleep, SLEEP_STAGE_KEYS.rem].includes(stage.sleep_stage);
      const finalKey = isAsleepRem ? SLEEP_STAGE_KEYS.rem : stage.sleep_stage;
      const finalStage = { ...stage, sleep_stage: finalKey, isAsleep: stage.sleep_stage === SLEEP_STAGE_KEYS.asleep };
      if (obj[finalKey]) {
        obj[finalKey].push(finalStage);
      } else {
        obj[finalKey] = [finalStage];
      }
      return obj;
    }, {});

    SLEEP_CORE_STAGES.filter(key => key !== SLEEP_STAGE_KEYS.asleep).forEach(key => {
      if (groupStages[key]) {
        const dataType = groupStages[key].map(item => {
          const startTimeMm = moment(item.start_time, DATE_TIME_SLEEP);
          const endTimeMm = moment(item.end_time, DATE_TIME_SLEEP);
          return {
            type: key,
            value: 1,
            isAsleep: item.isAsleep,
            start: startTimeMm.toISOString(),
            end: endTimeMm.toISOString(),
          };
        });
        const { startMM, endMM } = getStartEndSleeps([groupStages[key]]);
        const range = getRangeOfSleep(startMM, endMM, to_date);

        finalChartData.push({
          _id: mongoObjectId(),
          stage: key,
          start: startMM.toISOString(),
          end: endMM.toISOString(),
          rangeValues: range,
          dataType: dataType,
        });
      } else {
        finalChartData.push({
          _id: mongoObjectId(),
          stage: key,
          rangeValues: [],
          dataType: [],
        });
      }
    });
    return finalChartData;
  }, [originData, to_date]);

  const goalValue = useMemo(() => {
    return getHourlyGoalValue(metricSettings, false, target);
  }, [metricSettings, target]);

  const getRangeFromHourlyStages = items => {
    const allValues = items.flatMap(item => item.rangeValues);
    const min = Math.min(...allValues);
    const max = Math.max(...allValues);
    return [Math.floor(min), roundUpInteger(max)];
  };

  const [data, domains] = useMemo(() => {
    let domains = getRangeFromHourlyStages(chartData);

    const items = chartData.map(item => {
      // create empty type and convert hour to minute
      const dataType = fillDataTypes(item.dataType);
      return { ...item, dataType };
    });

    if (goalValue) {
      const group = originData[0] ? originData[0].grouped_sleep_in_day : [];
      const { startMM, endMM } = getStartEndSleeps(group || [], true);
      const range = getRangeOfSleep(startMM, endMM, to_date);
      domains = getDomainsHourlyWithGoal(range[0], range[1], goalValue);
    }
    domains = getFinalDomainsHourly(domains, false, true);

    return [items, domains];
  }, [chartData, tooltipPosition, goalValue, originData, to_date]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        // move label & line to top: -37px
        const MOVING_UP_PIXEL = 37;
        const elements = document.querySelectorAll('.grid-hourly');
        const tickValues = document.querySelectorAll('.yAxis .recharts-cartesian-axis-tick-value');
        const total = elements.length;

        for (let i = 0; i < total; i++) {
          const elm = elements[i];
          const y = elm.getAttribute('y1');
          elm.style.opacity = 1;
          elm.setAttribute('y1', y - MOVING_UP_PIXEL - 0.25);
          elm.setAttribute('y2', y - MOVING_UP_PIXEL - 0.25);
          if (i >= total - 2) {
            elm.style.display = 'none';
          }
          if (tickValues[i]) {
            const tick = tickValues[i];
            tick.style.opacity = 1;
            tick.setAttribute('y', tick.getAttribute('y') - MOVING_UP_PIXEL + 5);
          }
        }
      }, 200);
    }
  }, [isLoading]);

  const tickValues = useMemo(() => {
    return getTickLabelForHourlyStages(domains);
  }, [domains]);

  const { entry, payloadTooltip, ...tooltipCoordinate } = tooltipPosition || {};

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className={classNames('metric-chart sleep-hourly-chart', { 'no-data': isEmpty })}
    >
      {!isLoading && (
        <BarChart
          width={width}
          height={height}
          data={data}
          className="recharts-surface-default"
          layout="vertical"
          barSize={48}
          padding={{ left: 30, right: 30 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} className="grid-hourly" />
          <XAxis
            dy={5}
            padding={{ left: 5, right: 5 }}
            type="number"
            interval={0}
            domain={domains}
            tickFormatter={v => handleHourlyTickFormat(v, true)}
            tickCount={tickValues.length}
            ticks={tickValues}
          />
          <YAxis type="category" dataKey="stage" />
          <defs>
            {LINE_CONNECTS_COLOR.map((colors, lineIndex) => {
              const lineID = `color-${colors.join('-')}`;
              const colorLength = colors.length;
              return (
                <linearGradient id={lineID} key={lineID} x1="0" y1="0" x2="0" y2="1">
                  {colors.map((c, cIndex) => (
                    <stop
                      key={[c, lineIndex, cIndex].join('-')}
                      offset={getOffsetColor(colorLength, cIndex)}
                      stopColor={c}
                      stopOpacity={0.15}
                    />
                  ))}
                </linearGradient>
              );
            })}
            <linearGradient id="color-asleep" x1="0" y1="0" x2="0" y2="1">
              {ASLEEP_COLORS_GRADIENT.map((c, cIndex) => (
                <stop offset={cIndex * 0.33} key={cIndex} stopColor={c} stopOpacity={0.15} />
              ))}
            </linearGradient>
          </defs>
          <Tooltip
            isAnimationActive={false}
            cursor={false}
            active={!!tooltipPosition}
            content={<HourlyTimeConnectedStagesTooltip entry={entry} payloadTooltip={payloadTooltip} />}
            coordinate={tooltipCoordinate || {}}
            wrapperStyle={{ ...(tooltipCoordinate || {}), position: 'fixed', transform: 'none', zIndex: 1000 }}
          />
          <Bar
            dataKey="rangeValues"
            shape={<HourlyTimeConnectedStagesBarShape setTooltipPosition={setTooltipPosition} chartData={data} />}
          />
          {goalValue !== null && (
            <ReferenceLine
              x={goalValue}
              stroke="#A3A3B5"
              label={<CustomGoalDot isSleep className="sleep-goal-line" />}
            />
          )}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default HourlyTimeConnectedStagesChart;
