import styled, { css } from 'styled-components';
import { MenuTrigger } from 'shared/Icons';
import ConfirmModalElement from 'shared/ConfirmModal';

// confirm with design and qa about responsive
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(540px, 1fr));
  column-gap: 15px;
  row-gap: 15px;
  margin: 28px 0;

  ${props =>
    props.productTotal === 0 &&
    css`
      grid-template-columns: none;
    `}

  .product-card {
    display: flex;
    width: 100%;
    height: 122px;
    align-items: center;
    overflow: hidden;

    .extra-info {
      margin-top: 15px;
      padding: 0 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #202353;
      svg {
        margin: 0 10px;
        transform: translateY(-3px);
        circle {
          fill: #202353;
        }
      }
    }

    .content-container {
      box-sizing: border-box;
      height: 122px;
      padding: 20px 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
      .title {
        margin: 0px;
        padding: 0px;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .description {
        padding: 0px;
        margin-bottom: 5px;
        min-height: 39px;
      }

      .extra-info {
        padding: 0px;
        white-space: nowrap;
      }
    }

    .banner-container {
      flex: 0 0 198px;
      width: 198px;
      height: 122px;
      background-color: #e2e5ec;

      .banner {
        object-position: center;
      }
    }
  }
`;

export const Status = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background: blue;
`;

export const DropdownArrow = styled.span`
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  right: 33.5px;
  top: 3.5px;
  background: #363636;
  border-top: 1px solid #363636;
  border-left: 1px solid #363636;
  transform: rotateZ(45deg);
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 9px;
  .evf-dropdown.evf-dropdown--basic {
  }
  .evf-dropdown__trigger {
    height: 24px;
    width: 24px;
    background: #000000;
    opacity: 0.1;
    border-radius: 6px;
    :hover {
      background: #ffffff;
      opacity: 1;
    }
  }
  svg {
    width: 15px;
    margin-right: 10px;
    path,
    rect {
      stroke: white;
    }
  }
  .evf-dropdown__menu {
    width: 162px;
    max-height: auto !important;
    background-color: transparent !important;
    transform: translateY(-0.6px) translateX(22px);

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }

    &.last_item {
      ${DropdownArrow} {
        display: none;
      }
    }
  }
  .evf-dropdown__option {
    padding: 7px 20px;
    background: #363636;

    &:hover {
      background-color: #696969;
    }
  }
`;

export const DropdownArea = styled.div`
  padding: 6px 0;
  background: #363636;
  border-radius: 5px;
`;

export const TriggerIcon = styled(MenuTrigger)`
  border-radius: 6px;
  width: 24px;
  height: 24px;

  .dot,
  .dot::before,
  .dot::after {
    background-color: rgba(32, 35, 83, 0.1);
    width: 4px;
    height: 4px;
  }
  .dot {
    ::before {
      left: -6px;
    }
    ::after {
      left: 6px;
    }
  }
`;

export const CompactInformationItem = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 5px 10px;
  margin-right: 5px;
  width: auto;
`;

export const ProductStatus = styled(CompactInformationItem)`
  top: 10px;
  left: 10px;
  position: absolute;
  padding-left: 20px;

  :before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${props => (props.published ? '#6ACF47' : '#ffc90c')};
    z-index: 1;
  }
`;

export const NotFoundText = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  padding: 0 15px;
`;

export const DuplicateWrapper = styled.div`
  width: 637px;
  height: 64px;
  padding: 0 30px;
  position: fixed;
  top: 27px;
  left: 50%;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  background: #2e2525;
  color: #fff;
  border-radius: 6px;
  transform: translateX(-50%);
  align-items: center;
  display: flex;
  z-index: 1;
  span {
    margin-left: 6px;
  }
  .duplicate-indicator {
    display: inline-block;
    margin: 0 !important;
    width: 16px !important;
    height: 16px !important;
    &:before {
      width: 16px !important;
      height: 16px !important;
      border: 0.2em solid #adadad !important;
    }
    &:after {
      width: 16px !important;
      height: 16px !important;
      border-color: #fff transparent transparent !important;
    }
  }
`;

export const EmptyProductList = styled.div`
  display: flex;
  height: calc(100vh - 220px);
  padding-top: 9.5vw;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  ${props =>
    !props.isHideBanner &&
    css`
      height: calc(100vh - 493px);
      padding-top: 4.5vw;
    `}

  .buttonClassName {
    button {
      background: transparent !important;
      border: 1px solid #5158cf !important;

      span,
      svg {
        color: #5158cf !important;
      }
    }

    &:hover {
      button {
        background: #5158cf !important;

        span,
        svg {
          color: white !important;
        }
      }
    }
  }

  @media screen and (max-height: 800px) {
    padding-top: 4.5vw;

    ${props =>
      !props.isHideBanner &&
      css`
        padding-top: 0;
      `}

    .buttonClassName {
      padding-bottom: 30px;
    }
  }
`;

export const EmptyProductTitle = styled.div`
  color: #202353;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-top: 15px;
  margin-bottom: 6px;
`;

export const EmptyProductDescription = styled.div`
  max-width: 367px;
  color: #000000;
  opacity: 0.8;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
  font-weight: 400;
`;

export const ConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    .confirm-header-image {
      width: 25px;
      height: 25px;
    }
    .confirm-content-body {
      padding: 18px 30px 30px 30px;
    }
    .confirm-yes-button,
    .confirm-no-button {
      min-width: 100px;
    }
    &.archive {
      .confirm-yes-button {
        background-color: #5158cf;
        min-width: 160px;
        box-shadow: none;
        &:hover {
          background-color: rgba(81, 88, 207, 0.9) !important;
        }
      }
    }
  }

  .confirm-content-header {
    padding: 30px 30px 0 30px !important;
    margin-bottom: -3px;
  }
`;

export const CustomPopupMessage = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
  margin-top: 15px;
  padding-right: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FirstCreateNewIcon = styled.img`
  width: 111px;
`;

export const LoadingWrapper = styled.div`
  .loading {
    padding-top: 0;
  }
`;
