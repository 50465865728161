// Libs
import React, { PureComponent } from 'react';
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import get from 'lodash/get';

// Components
import CustomTooltipContent from '../../CustomTooltipContent';
import CustomLineBarSpecialChart from '../CustomLineBarSpecialChart';
import LoadingIndicator from 'shared/LoadingIndicator';
import CustomizedAxisTick from '../../CustomizedAxisTick';
import HeartRateBarShape from './HeartRateBarShape';

// Actions
import { filterChartViewMode } from 'actions/bodyMetric';

// Constants
import {
  BOUNDARY_VALUES,
  CHART_COLORS,
  COLOR_HEART_RATE_CHART,
  DATE_FORMAT,
  HOUR_LABELS,
  PERIOD_GROUP,
  SPECIAL_METRICS_CODE,
  getTimeRangeUnitFromRange,
} from 'components/BodyMetricChartNew/constants';
import {
  convertDataToUnit,
  fillChartDataByPeriodTime,
  filledComparePercentage,
  formatWeeklyLabel,
  getDataMax,
  getDataMin,
  getMinMaxHeartRateValue,
  getNewDataMinMax,
  getSizeOfColumn,
  getTicks,
  getXAxisInterval,
  getXLabels,
  handleShowCursor,
  handleUnitFormat,
  updateTooltipPosition,
} from 'components/BodyMetricChartNew/chartHelper';
import { initChartDataEmpty } from './constant';

// Assets
import { ReactComponent as RangeChartIcon } from 'assets/icons/range-chart.svg';
import { ReactComponent as ArrowNextIcon } from 'assets/icons/arrow-circle.svg';

// Styles
import * as S from '../../styles';
import '../../style.scss';

const POSITION_TOOLTIP_BOTTOM = 120;
const STEP_RATIO = 0.55;

class HeartRateChart extends PureComponent {
  constructor(props) {
    super(props);
    this.areaRef = null;
    this.chartRef = null;
    this.chartWrapperRef = React.createRef();
    this.state = {
      tooltipPos: { x: undefined, y: undefined },
      filledData: [],
      barWidth: 20,
      xInterval: 0,
      isHoveringChart: false,
      chartBarData: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { isGroupOverview, isOverview } = this.props;
    if (!isGroupOverview && !isOverview) {
      handleShowCursor();
    }
  }

  componentDidMount() {
    const { isGroupOverview, isOverview } = this.props;

    if (!isGroupOverview && !isOverview) {
      handleShowCursor();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chartData, rangeTime, selectedUnit, isOverview, isDoubleChart, isGroupOverview } = nextProps;
    const { filterTime } = this.props;

    const isEmpty = chartData.length === 0;
    const filledData = fillChartDataByPeriodTime(
      isEmpty ? initChartDataEmpty((filterTime || {}).to_date) : chartData,
      filterTime,
      SPECIAL_METRICS_CODE.HEART_RATE,
    );
    const convertChartData = convertDataToUnit(selectedUnit, filledData);

    const filledComparePercentageData = filledComparePercentage(convertChartData);
    const convertedFilled = filledComparePercentageData.map(item => {
      if (!item.value) {
        return {
          ...item,
          value: [0, 0],
        };
      }
      return item;
    });
    const barWidth = getSizeOfColumn(filledComparePercentageData, isOverview || isGroupOverview, isDoubleChart);
    const xInterval = getXAxisInterval(rangeTime, isGroupOverview, isOverview, isDoubleChart);
    this.setState({ filledData: convertedFilled, barWidth, xInterval, chartBarData: {} });
  }

  updateAreaRef = r => {
    this.areaRef = r || this.areaRef;
  };

  updateTooltipBarChart = data => {
    const { barWidth } = this.state;
    const { isOverview, isGroupOverview } = this.props;
    const isChartOverview = isGroupOverview || isOverview;

    const tooltipPos = updateTooltipPosition(this.chartRef, this.chartId, data, false, isChartOverview, barWidth, true);
    const { x = 0, y = 0 } = tooltipPos || {};

    const shouldShowTooltipAtBottom = !isGroupOverview && !isOverview && this.shouldShowTooltipBottom(data);

    this.setState({ tooltipPos: { x, y: shouldShowTooltipAtBottom ? y + POSITION_TOOLTIP_BOTTOM : y } });
  };

  shouldShowTooltipBottom = data => {
    const chartWrapperElement = this.chartWrapperRef && this.chartWrapperRef.current;
    const chartWrapperRect = chartWrapperElement && chartWrapperElement.getBoundingClientRect();
    const tooltipElement = document.querySelector('.recharts-tooltip-wrapper');
    const tooltipRect = tooltipElement && tooltipElement.getBoundingClientRect();

    if (tooltipRect && chartWrapperRect) {
      const tooltipRectHeight = tooltipRect.height;
      const chartHeight = !!data ? data.height : 0;
      const chartWrapperHeight = chartWrapperRect.height;
      const tooltipTop = chartHeight + tooltipRectHeight + 20; // 20: This is gap between tooltip and chart
      if (tooltipTop > chartWrapperHeight) {
        return true;
      }
    }
    return false;
  };

  handleTickFormat = value => {
    const { selectedUnit, hearRateMetric, isGroupOverview, isOverview } = this.props;
    const { filledData } = this.state;

    const { min = 0, max = 0 } =
      isGroupOverview || isOverview ? getMinMaxHeartRateValue(filledData) : get(hearRateMetric, 'value_range', {});

    const dataMin = getDataMin(min, max, 0);
    const dataMax = getDataMax(min, max, 0);

    const [newDataMin] = getNewDataMinMax(dataMin, dataMax, false, STEP_RATIO, true);

    return handleUnitFormat(selectedUnit, value, newDataMin, false);
  };

  formatXAxisLabel = label => {
    const {
      rangeTime: { period_group },
    } = this.props;
    const { xInterval, filledData } = this.state;
    const labels = period_group === PERIOD_GROUP.HOURLY ? HOUR_LABELS : getXLabels(filledData, xInterval);

    if (labels.includes(label)) {
      return formatWeeklyLabel(label, period_group);
    }
    return '';
  };

  handleHoverChart = ({ status = false, data = {} }) => {
    this.setState({ isHoveringChart: status, chartBarData: { ...data } });
  };

  renderChart = () => {
    const {
      chartData,
      color = CHART_COLORS[0],
      width = 500,
      height = 400,
      isOverview = false,
      bodyMetric,
      isGroupOverview,
      isLoading,
      rangeTime: { period_group },
      hearRateMetric,
    } = this.props;
    const { filledData = [], barWidth, tooltipPos, xInterval, chartBarData, isHoveringChart } = this.state;

    const { name = '', _id: bId, mid } = bodyMetric || {};
    const _id = bId || mid;
    const isEmpty = chartData.length === 0;
    const throttleDelay = period_group === PERIOD_GROUP.MONTHLY ? 300 : 100;
    this.chartId = `metric-chart-tooltip-customized-${_id}`;

    const { min = 0, max = 0 } =
      isGroupOverview || isOverview ? getMinMaxHeartRateValue(filledData) : get(hearRateMetric, 'value_range', {});
    const isNoData = BOUNDARY_VALUES.includes(min) || BOUNDARY_VALUES.includes(max);
    const dataMin = getDataMin(min, max, 0);
    const dataMax = getDataMax(min, max, 0);
    const chartNoData = isNoData || isEmpty;
    const [newDataMin, newDataMax, tickCount] = getNewDataMinMax(dataMin, dataMax, false, STEP_RATIO, true);

    const domain = chartNoData ? undefined : [newDataMin, newDataMax];

    const ticks = chartNoData ? undefined : getTicks(newDataMin, newDataMax, tickCount);

    const filledDataInBar = filledData.map(item => {
      if (item.max === item.min && !get(item, 'value', []).every(it => it === 0)) {
        const drawHeight = item.max - 1;
        return { ...item, value: [drawHeight, item.max] };
      }

      return item;
    });

    return (
      <ResponsiveContainer width="100%" height="100%" className={classNames('metric-chart', { 'no-data': isEmpty })}>
        {!isLoading && (
          <ComposedChart
            width={width}
            height={height}
            data={filledData}
            ref={r => (this.chartRef = r)}
            throttleDelay={throttleDelay}
            onMouseOver={() => {
              if (chartData.length !== 1) return;
            }}
            onMouseOut={() => {
              if (chartData.length !== 1) return;
            }}
            className="recharts-surface-default"
          >
            <XAxis
              dataKey="time"
              padding={{
                left: isGroupOverview || isOverview ? 5 : 10,
                right: 5,
              }}
              tickFormatter={xInterval === 0 ? lab => formatWeeklyLabel(lab, period_group) : this.formatXAxisLabel}
              interval={0}
              dy={5}
              tickLine={false}
              width={10}
              className={isEmpty && !isLoading && `customized-xAxis-${_id}`}
              tick={
                <CustomizedAxisTick
                  filledData={filledData}
                  xInterval={xInterval}
                  period_group={period_group}
                  bodyMetricId={_id}
                />
              }
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              padding={{ top: 10 }}
              tick={isGroupOverview || isOverview ? { dx: -2 } : {}}
              tickFormatter={this.handleTickFormat}
              domain={domain}
              ticks={ticks}
              tickCount={tickCount}
              allowDataOverflow
            />
            <Tooltip
              cursor={
                <CustomLineBarSpecialChart
                  color={COLOR_HEART_RATE_CHART}
                  barWidth={barWidth}
                  chartBarData={chartBarData}
                  isFillTransparent={!isHoveringChart}
                  isOverview={isOverview || isGroupOverview}
                />
              }
              content={
                <CustomTooltipContent
                  id={this.chartId}
                  chartData={filledData}
                  tooltipActiveCallback={this.tooltipActiveCallback}
                  chartName={name}
                  chartColor={color}
                  isHoveringChartBar={isHoveringChart}
                  isSpecialChart={true}
                  hideBottomTag={true}
                />
              }
              isAnimationActive={false}
              animationEasing="ease-out"
              coordinate={tooltipPos}
              position={tooltipPos}
            />

            <Bar
              dataKey="value"
              fill={COLOR_HEART_RATE_CHART}
              animationDuration={500}
              barSize={barWidth}
              radius={3}
              shape={<HeartRateBarShape isOverview={isOverview || isGroupOverview} />}
              onMouseOver={data => {
                this.updateTooltipBarChart(data);
                this.handleHoverChart({ status: true, data });
              }}
              onMouseOut={() => this.handleHoverChart({ status: false, data: {} })}
              activeBar={false}
              data={filledDataInBar}
            />
          </ComposedChart>
        )}
      </ResponsiveContainer>
    );
  };

  handleChangeRange = isNext => () => {
    const {
      filterChartViewMode,
      filterTime: { from_date, to_date, period_group, timeRangeString },
    } = this.props;
    const fromDateMoment = moment(from_date, DATE_FORMAT);
    const toDateMoment = moment(to_date, DATE_FORMAT);
    const diff = toDateMoment.diff(fromDateMoment, 'd') + 1;
    const timeUnit = getTimeRangeUnitFromRange(timeRangeString, diff);
    const newFromDate = isNext
      ? fromDateMoment.add(timeUnit.value, timeUnit.timeTitle)
      : fromDateMoment.subtract(timeUnit.value, timeUnit.timeTitle);
    const newToDate = isNext
      ? toDateMoment.add(timeUnit.value, timeUnit.timeTitle)
      : toDateMoment.subtract(timeUnit.value, timeUnit.timeTitle);

    filterChartViewMode(newFromDate.format(DATE_FORMAT), newToDate.format(DATE_FORMAT), period_group, timeRangeString);
  };

  render() {
    const {
      isLoading,
      chartData,
      minHeight = 337,
      filterTime,
      isOverview,
      noDataLabel = 'No data available',
      isGroupOverview,
    } = this.props;
    const disabledNext = moment().diff(moment(filterTime.to_date, this.DATE_FORMAT), 'd') <= 0;
    const isEmpty = chartData.length === 0;

    return (
      <S.ChartContainer>
        <S.Wrapper
          minHeight={minHeight}
          isOverview={isOverview}
          isOverviewAndOverviewGroup={isGroupOverview || isOverview}
          className="chart-wrapper heart-rate-chart-wrapper"
          ref={this.chartWrapperRef}
          isHideYAxis={isEmpty}
          isHideXAxis={isLoading}
        >
          {isLoading && <LoadingIndicator title="Loading chart..." className="loading-chart-indicator" />}
          {!isOverview && (
            <>
              <button className="chart-arrow-btn chart-prev-btn" onClick={this.handleChangeRange(false)}>
                <ArrowNextIcon />
              </button>
              <button
                className="chart-arrow-btn chart-next-btn"
                onClick={this.handleChangeRange(true)}
                disabled={disabledNext}
              >
                <ArrowNextIcon />
              </button>
            </>
          )}
          {this.renderChart()}
          {!chartData.length && !isLoading && (
            <div className="chart-no-data">
              <div className="no-data-wrapper">
                <RangeChartIcon className="range-chart" width={32} />
                <p>{noDataLabel}</p>
              </div>
            </div>
          )}
        </S.Wrapper>
      </S.ChartContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  filterChartViewMode: bindActionCreators(filterChartViewMode, dispatch),
});

const mapState = state => {
  const { rootReducer } = state;
  const {
    hearRateMetric: { isGettingChartData = false, gettingEntriesByDay = false },
  } = rootReducer;

  return {
    isGettingChartData,
    gettingEntriesByDay,
  };
};

export default connect(mapState, mapDispatchToProps)(HeartRateChart);
