import React from 'react';
import * as S from './style';
import PaymentActivityUpgradePathImage from 'assets/images/payment-activity-upgrade-path.png';
import PaymentActivityFilterUpgradePathImage from 'assets/images/payment-activity-upgrade-filter-path.png';

class NoAccessPaymentActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <S.NoAccessPayment>
        <S.Item>
          <S.DefaultFilterImage src={PaymentActivityFilterUpgradePathImage} />
        </S.Item>
        <S.Item>
          <S.DefaultImage src={PaymentActivityUpgradePathImage} />
          <S.NoAccessBox>
            <S.NoAccessTitle>Payment & Packages</S.NoAccessTitle>
            <S.NoAccessDescription>
              Create one-time or recurring packages to collect payment effortlessly for your training business!
            </S.NoAccessDescription>
            <S.SUpgradePathButton className="upgrade-path-button" text="Get Payment Add-on" />
          </S.NoAccessBox>
        </S.Item>
      </S.NoAccessPayment>
    );
  }
}

export default NoAccessPaymentActivity;
