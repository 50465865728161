// Libs
import styled from 'styled-components';

export const ConditionalAssignmentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fffce4;
  padding: 8px;
  gap: 6px;
  border-radius: 8px;

  .close-trigger {
    background: unset;
    border: unset;
    padding: unset;
    margin: unset;

    width: 20px;
    height: 20px;
    border-radius: 10px;

    position: absolute;
    right: 2px;
    top: 3px;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
    }

    &:hover {
      svg g {
        opacity: 0.8;
      }
    }
  }

  .title-banner {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #000000;

    margin-top: 2px;
  }

  .tag-new-banner {
    position: absolute;
    top: 8px;
    right: 42px;
    padding: 2px 4px;
    width: 30px;
    height: 16px;
    background: #1f1f1f;
    border-radius: 4px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    color: #ffffff;
  }

  .description-banner {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    color: #000000;
  }

  .link-read-more-banner {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    color: rgba(207, 141, 14, 1);

    &:hover {
      text-decoration: unset !important;
    }
  }
`;
