import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import RegularWorkoutInactiveIcon from 'assets/icons/regular-workout-inactive.svg';
import RegularWorkoutActiveIcon from 'assets/icons/regular-workout-active.svg';
import VideoWorkoutInactiveIcon from 'assets/icons/video-workout-inactive.svg';
import VideoWorkoutActiveIcon from 'assets/icons/video-workout-active.svg';

export const ModalWrapper = styled(Modal)`
  border-radius: 8px;
  max-width: 544px;

  .content {
    border-radius: 8px;
    padding: 30px !important;
  }

  .modal-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #202353;
  }

  .upgradeModal__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__description {
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__upgradeBtn {
    margin-top: 30px;
    text-align: center;
  }
  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 213px;
    margin-top: 20px;
    column-gap: 20px;
    .button {
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      border-radius: 5px;
      text-align: center;
      padding-top: 24px;
      cursor: pointer;
      :hover {
        border: 1px solid #5158cf;
        .icon-container svg path {
          stroke: #5158cf;
        }
        .regular {
          background-image: url(${RegularWorkoutActiveIcon}) !important;
        }
        .video {
          background-image: url(${VideoWorkoutActiveIcon}) !important;
        }
      }
      .icon-container {
        width: 61px;
        height: 61px;
        margin: auto;
        background-color: rgba(32, 35, 83, 0.1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        background-repeat: no-repeat;
        background-position: center;
        &.regular {
          background-image: url(${RegularWorkoutInactiveIcon});
        }
        &.video {
          background-image: url(${VideoWorkoutInactiveIcon});
        }
      }
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #000000;
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #202353;
        margin: auto;
        margin-top: 10px;
        &.regular-text {
          width: 164px;
        }
        &.video-text {
          width: 216px;
        }
      }
    }
  }
`;
