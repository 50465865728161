import { clone } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';

const AutoTextArea = props => {
  const textAreaRef = useRef(null);
  const [text, setText] = useState(props.value);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');
  const [lastHeight, setLastHeight] = useState(30);

  useEffect(() => {
    const currentHeight = clone(parentHeight);
    const scaleHeight =
      lastHeight < currentHeight ? textAreaRef.current.scrollHeight - 16 : textAreaRef.current.scrollHeight;
    setParentHeight(scaleHeight);

    setTextAreaHeight(scaleHeight);
  }, [text]);

  const handleOnChange = event => {
    setTextAreaHeight('auto');
    setText(event.target.value);
    setLastHeight(textAreaRef.current.scrollHeight);
    props.onChange(event.target.value);
  };

  return (
    <div
      style={{
        minHeight: `${parentHeight}px`,
      }}
    >
      <textarea
        {...props}
        placeholder="Add description..."
        ref={textAreaRef}
        rows={1}
        value={text}
        style={{
          height: `${textAreaHeight}px`,
        }}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default AutoTextArea;
