import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const baseText = `
    font-family: 'Open Sans';
    font-style: normal;
    line-height: normal;
    color: #202353;
    line-height: 150%;
    font-weight: 600;
`;

export const CustomModal = styled(Modal)`
  &.ui.modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: ${({ width }) => width || '587px'};
    height: ${({ height }) => height || 'auto'};

    border-radius: 10px;
  }
  &.ui.modal > .header,
  &.ui.modal > .content,
  &.ui.modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }

  max-height: calc(100vh - 100px);
  position: relative;
`;

export const HeaderWrapper = styled.div`
  padding: 16px 34px;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
`;

export const HeaderTitle = styled.div`
  ${baseText}
  font-size: 17px;
`;

export const ContentWrapper = styled.div`
  padding: 0 11px 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 10px 10px;
  width: 100%;

  .heart-rate-header {
    padding: 6px 30px 6px 23px;
  }
`;

export const HeaderTable = styled.div`
  display: flex;
  padding: 6px 30px 6px 0;
  align-items: center;
  ${baseText}
  color: #7B7E91;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 7px;
  border-radius: 5px;
`;

export const HeaderDateLabel = styled.div`
  display: flex;
  width: 94px;
  padding-right: 10px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

export const HeaderItem = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  width: ${({ widthItem }) => widthItem || '94px'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '0'};
`;

export const HeaderTimeLabel = styled.div`
  display: flex;
  width: 262px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

export const HeaderResultLabel = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
`;

export const ContentTable = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ hasSyncedData }) => (hasSyncedData ? 'calc(100vh - 100px)' : '100%')};
  max-height: calc(100vh - 194px);
  padding-bottom: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  z-index: 1;

  .loading-wrapper {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    & > div {
      padding-top: 0 !important;
      .ui.custom-indicator {
        &::before {
          border-color: #ededed !important;
        }
        &::after {
          border-color: #5158cf transparent transparent !important;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }

  .entry-by-day-loading {
    padding-top: 29px;

    .custom-indicator.ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }

    span {
      font-size: 13px !important;
      color: #8f99a3;
      font-weight: normal;
    }
  }

  .heart-rate-entries-item {
    padding-left: 23px;
  }
`;

export const EntryAction = styled.div`
  display: flex;
  opacity: 0;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  align-self: stretch;
  width: 72px;
  margin-right: -5px;
  padding-bottom: 3px;
`;

export const HeartRateEntryAction = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  align-self: stretch;
  margin-left: 35px;
  width: 72px;
`;

export const FragmentRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChildItemRows = styled.div`
  height: ${({ isExpanded }) => (isExpanded ? '50px' : '0')};
  padding-left: 24px;
  border-bottom: ${({ isExpanded }) => (isExpanded ? '1px solid #e1e1ea' : 'none')};
  background-color: #fbfbfb;
  transition: all 0.3s;
  overflow: hidden;

  :hover {
    background: #f0f0f2;
    ${EntryAction} {
      opacity: 1;
      visibility: ${({ connectApp }) => (connectApp ? 'hidden' : 'visible')};
    }
  }

  ${({ active, connectApp }) =>
    active &&
    !connectApp &&
    css`
      background: #f0f0f2;
      ${EntryAction} {
        opacity: 1;
      }
    `}
`;

export const EntryItemWrapper = styled.div`
  display: flex;
  height: 50px;
  min-height: 50px;
  align-items: flex-start;
  align-self: stretch;
  padding-top: 3px;
  border-bottom: 1px solid #e1e1ea;
  :last-child {
    border-bottom: none;
  }
  cursor: pointer;
  background-color: ${({ checked }) => checked && '#F0F0F2'};
  ${baseText}
  font-weight: 400;
  font-size: 13px;
  color: #7b7e91;

  :hover {
    background: #f0f0f2;
    ${HeartRateEntryAction} {
      display: flex;
    }
  }

  ${({ active }) =>
    active &&
    `${HeartRateEntryAction} {
      display: flex;
    }`}

  .edit-result-popup {
    position: absolute;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      ${ChildItemRows} {
        display: flex;
      }
      .arrow-down {
        transform: rotate(-180deg);
        transition: all 0.3s;
      }
    `}

  ${({ isNotGroup }) =>
    isNotGroup &&
    css`
      :hover {
        ${EntryAction} {
          opacity: 1;
        }
      }
    `}

    ${({ active }) =>
    active &&
    css`
      background: #f0f0f2;
      ${EntryAction} {
        opacity: 1;
      }
    `}
`;

export const EntryChildWrapper = styled.div`
  display: flex;
  height: 50px;
  min-height: 50px;
  align-items: flex-start;
  align-self: stretch;
  padding-top: 3px;
  cursor: pointer;
  ${baseText}
  font-weight: 400;
  font-size: 13px;
  color: #7b7e91;
`;

export const EntryDate = styled.div`
  display: flex;
  width: ${({ width }) => width || '94px'};
  padding-right: 10px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  .date-label {
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 94px;
  }
`;

export const ContentItem = styled.div`
  display: flex;
  width: ${({ width }) => width || '94px'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '0'};
  padding-right: 10px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  .date-label {
    white-space: nowrap;
  }

  .arrow-down {
    transition: all 0.3s;
    path {
      stroke: #7b7e91;
    }
  }

  :last-child {
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
  }
`;

export const EntryTime = styled.div`
  display: flex;
  width: 262px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  ${({ isDisableSmartWatch }) =>
    isDisableSmartWatch &&
    css`
      .smart-watch-icon {
        path,
        circle {
          stroke: #bbbdcf;
        }
      }
    `}
`;

export const EntryResult = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  align-self: stretch;
  min-width: 54px;
`;

const activeEdit = css`
  background-color: #f0f1ff;
  .edit-entry-icon {
    path {
      fill: #5158cf;
    }
  }
`;

export const EditWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active }) => active && activeEdit}

  :hover {
    ${activeEdit}
  }
`;

export const RemoveWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: #ffd8dd;
    .trash-icon {
      path {
        fill: #ea314a;
      }
    }
  }
`;
