import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  addIntervalMessage,
  updateIntervalMessage,
  getIntervalMessageDetail,
  pauseIntervalInAppMessage,
  resumeIntervalInAppMessage,
} from 'redux/autoflowInterval/auto-message/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import IntervalInAppMessage from './InAppMessage';
import { push } from 'connected-react-router';

import { CDN_URL } from 'constants/commonData';

import * as S from './styles';
import { Mixpanel } from 'utils/mixplanel';

function Wrapper(props) {
  const { match, autoflowId, user, totalWeek, dayIndex, weekIndex } = props;
  const [data, setData] = useState(null);
  const messageId = _.get(match, 'params.messageId');

  useEffect(() => {
    if (messageId) {
      props
        .getIntervalMessageDetail({ messageId, autoflowId })
        .then(response => {
          const { data } = response.data;
          setData(data);
        })
        .catch(() => {
          props.push(`/home/autoflow/${autoflowId}/auto-message`);
        });
    } else {
      setData({ dayIndex, weekIndex });
    }
  }, []);

  const onSubmit = message => {
    const body = { ...message, autoflowId };
    delete body.day;

    if (messageId) {
      body.messageId = messageId || data._id;
      body.newDayIndex = message.day % 7;
      body.newWeekIndex = parseInt(message.day / 7);
      body.oldDayIndex = data.dayIndex;
      body.oldWeekIndex = data.weekIndex;
      return props.updateIntervalMessage(body);
    }

    body.dayIndex = message.day % 7;
    body.weekIndex = parseInt(message.day / 7);
    return props.addIntervalMessage(body).then(response => {
      try {
        Mixpanel.track('create_in_app_message_autoflow_interval');
      } catch {}
      return response;
    });
  };

  const closePopup = () => {
    if (typeof props.onClose === 'function') {
      return props.onClose();
    }

    if (messageId) {
      props.push(`/home/autoflow/${autoflowId}/auto-message`);
    }

    props.toggleModal(false);
  };

  const onClose = data => {
    if (data && !_.isEqual(data.oldValue, data.newValue)) {
      props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={closePopup}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    } else {
      closePopup();
    }
  };

  const onPause = () => {
    return props.pauseIntervalInAppMessage({
      dayIndex: data.dayIndex,
      weekIndex: data.weekIndex,
      type: 'inbox',
      autoflowId,
      messageId,
    });
  };

  const onResume = () => {
    return props.resumeIntervalInAppMessage({
      dayIndex: data.dayIndex,
      weekIndex: data.weekIndex,
      type: 'inbox',
      autoflowId,
      messageId,
    });
  };

  return data ? (
    <IntervalInAppMessage
      source={data}
      onSubmit={onSubmit}
      user={user}
      onClose={onClose}
      totalWeek={totalWeek}
      pauseMessage={onPause}
      resumeMessage={onResume}
      toggleConfirmModal={props.toggleConfirmModal}
    />
  ) : null;
}

const mapState = state => {
  const { user, rootReducer } = state;
  const {
    common: { workingAutoflow },
  } = rootReducer.autoflow;
  const { _id, avatar, first_name, last_name, color } = user;

  return {
    autoflowId: workingAutoflow._id,
    user: { _id, avatar, first_name, last_name, color },
    totalWeek: workingAutoflow.interval_weeks.length,
  };
};

const actionCreators = {
  addIntervalMessage,
  updateIntervalMessage,
  getIntervalMessageDetail,
  toggleModal,
  toggleConfirmModal,
  push,
  pauseIntervalInAppMessage,
  resumeIntervalInAppMessage,
};

export default connect(mapState, actionCreators)(Wrapper);
