export const EXERCISE_CONTAINER_HEIGHT = 53;
export const EXERCISE_CONTAINER_MARGIN = 2;
export const ASSIGNMENT_CONTAINER_HEIGHT = 54;
export const ASSIGNMENT_CONTAINER_MARGIN = 6;

export default {
  exerciseListContainer: {
    margin: '0 4px 6px',
    padding: '0 2px',
    background: 'rgba(255, 255, 255, 0.8)',
  },

  exercisesListFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  popup: {
    borderRadius: '0',
    padding: '10px 30px 10px 10px',
    borderRadius: '8px',
    background: '#2D2E2D',
    color: '#ffffff',
  },

  dropdownList: {
    width: '170px',
    position: 'relative',
    padding: '0px',
    paddingBottom: '15px',
  },

  dropdownListItem: {
    listStyle: 'none',
    float: 'left',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
    cursor: 'pointer',
  },

  dropdownListItemContainer: {
    float: 'left',
    cursor: 'pointer',
    paddingLeft: '15px',
  },

  dropdownListItemImage: {
    float: 'left',
    paddingTop: '3px',
    width: 14,
    height: 19,
  },

  dropdownListItemTitle: {
    float: 'left',
    lineHeight: '19px',
    paddingLeft: '12px',
  },
};
