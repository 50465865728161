/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { State, Dispatch } from 'types/redux';
import PublicProfile from './component';

const mapStateToProps = (state: State) => {
  const { user } = state;
  return {
    user
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicProfile)
);
