import _ from 'lodash';
import { convertDurationToString } from 'helpers/time';
import { roundTwoDigit } from 'helpers/number';
import {
  STRENGTH_AND_BODYWEIGHT,
  TEAM_SHARE_PRIVATE,
  SECTION_FORMAT_KEY,
  EXERCISE_LIMIT_CHARACTER_NOTE,
  LIMIT_CHARACTER_SYNONYM,
} from 'constants/commonData';
import { convertUnit } from 'utils/commonFunction';

const MATCHING_UNIT = {
  heart_rate: 'bpm',
  rpe: 'RPE',
  calories: 'cal',
  cadence: 'rpm',
  orm: '%1RM',
  hr: '%HR',
  rir: 'RIR',
};

const NUMBER_FIELDS = ['float', 'int'];

export const getSetItemDescription = (s, unit, isAcceptNull, itemFields, fields, one_rep_max = null) => {
  let setContent = '';
  let firstSet = false;
  const rowData = _.omit(s, ['rest', 'reps', 'is_completed', '_id', 'breaths']);

  const newRowData = itemFields
    .map(fid => {
      const fieldObj = _.find(fields, f => f._id === fid);
      const { unique_code } = fieldObj || {};
      if (!!unique_code && rowData[unique_code]) {
        return { key: unique_code, data: rowData[unique_code], fieldObj };
      }
      return null;
    })
    .filter(item => !!item);

  _.forEach(newRowData, ({ data, key, fieldObj }) => {
    const finalValue = data.input_value || data.value;
    const finalUnit = data.input_unit || unit[key] || {};

    let value = finalValue && Number(finalValue) ? roundTwoDigit(finalValue) : isAcceptNull ? 0 : undefined;

    // data.set_unit is the old unit, unit[key] is the new unit fetched from user settings
    if (data.input_value === '' || data.input_value === undefined) {
      const newUnits = unit[key] ? unit[key] : data.set_unit;
      value = convertUnit(value, data.set_unit, newUnits);
    }

    const orm = _.get(rowData, 'orm');
    if (orm && one_rep_max && data.set_unit) {
      value = (orm.value / 100) * one_rep_max.value;

      // TODO - Converting Unit by ORM
      const newUnits = unit[key] ? unit[key] : one_rep_max.set_unit;
      value = convertUnit(value, one_rep_max.set_unit, newUnits);
    }

    if (value !== undefined && !isNaN(value)) {
      const fieldUnit = finalUnit.title || MATCHING_UNIT[key] || '';

      if (firstSet) {
        setContent += ' x ';
      }

      value =
        fieldObj.data_type === 'time'
          ? convertDurationToString(value)
          : NUMBER_FIELDS.includes(fieldObj.data_type)
          ? roundTwoDigit(value)
          : value;

      setContent += `${value} ${fieldUnit}`;
      firstSet = true;
    }
  });

  const repsField = fields.find(item => item.unique_code === 'reps');

  if (repsField && itemFields.includes(repsField._id)) {
    const repsData = _.get(s, 'reps.value', isAcceptNull ? 0 : '');
    const parseToNumber = parseInt(repsData);
    let text = '';

    if (repsData && !isNaN(parseToNumber)) {
      if (firstSet) {
        text = repsData ? ` x ${repsData}` : '';
      } else {
        text = `${repsData}`;
        if (parseToNumber.toString().indexOf('-') !== -1 || parseToNumber > 1) {
          text += ' reps';
        } else {
          text += ' rep';
        }
      }
    }

    setContent += text;
  }

  const breathField = fields.find(item => item.unique_code === 'breaths');

  if (breathField && itemFields.includes(breathField._id)) {
    const breathValue = _.get(s, 'breaths.value', '');
    let text = '';

    if (breathValue != '') {
      text = ` x ${breathValue} ${breathValue < 2 ? 'breath' : 'breaths'}`;
    }

    setContent += text;
  }

  return setContent;
};

export const getSetDescription = (itm, unit, fields) => {
  let setContents = [];
  let content = '';

  itm.training_sets.forEach((s, idx) => {
    let one_rep_max = _.get(itm, 'one_rep_max') ? _.get(itm, 'one_rep_max') : null;
    let training_sets_unit = s;
    const checkORMHaveValue = isUseORM(_.get(itm, itm.exercise_instance.fields, []), fields);

    // TODO - Have one_rep_max not have value
    if (one_rep_max) one_rep_max = one_rep_max;
    else {
      if (one_rep_max) return;
      if (training_sets_unit.orm && !checkORMHaveValue && _.has(training_sets_unit, 'weight')) {
        training_sets_unit.weight.value = null;
      }
    }

    content = getSetItemDescription(training_sets_unit, unit, false, itm.exercise_instance.fields, fields, one_rep_max);

    if (content) {
      setContents.push(content);
    }
  });

  return setContents.join(', ');
};

export const getFileExtension = url => {
  return url.split('.').pop();
};

export const convertExerciseToAPIFormat = (exercise, videoData) => {
  let data = {};

  if (exercise._id) {
    data._id = exercise._id;
  }

  data.body_part = exercise.body_part;
  data.picture = exercise.picture;
  data.author = exercise.author ? exercise.author._id : null;
  data.author_name = exercise.author ? exercise.author_name : null;
  data.share = exercise.share;
  data.category_type = exercise.category_type;
  data.category_type_name = exercise.category_type_name;
  data.fields = exercise.fields;
  data.picture = exercise.picture;
  data.instructions = exercise.instructions;
  data.title = exercise.title;
  data.link = exercise.link;
  data.video = exercise.video;
  data.videoLink = exercise.videoLink;
  data.tags = exercise.tags ? exercise.tags.map(tag => tag._id) : [];

  if (!videoData) {
    data.preview_50 = exercise.preview_50;
    data.preview_300 = exercise.preview_300;
    data.thumbnail_url = exercise.thumbnail_url;
  }

  if (exercise.attachment_id) {
    data.attachment_id = exercise.attachment_id;
  }

  // handle properties for api exercise v2
  if (data.body_part) {
    delete data.body_part;
  }

  if (exercise.modality) {
    data.modality = exercise.modality._id;
  }

  if (exercise.muscle_groups) {
    data.muscle_groups = exercise.muscle_groups.map((it, idx) => ({
      is_primary: idx === 0,
      muscle_group: it._id,
    }));
  }

  if (exercise.movement_patterns) {
    data.movement_patterns = exercise.movement_patterns.map((it, idx) => ({
      is_primary: idx === 0,
      movement_pattern: it._id,
    }));
  }

  if (exercise.equipment) {
    data.equipment = exercise.equipment._id;
  } else if (exercise.equipment === null) {
    data.equipment = null;
  }

  if (exercise.synonyms) {
    data.synonyms = exercise.synonyms.reduce((accumulator, currentValue) => {
      const content = (currentValue || '')
        .trim()
        .replace(/(\r\n|\n|\r)/gm, '')
        .substring(0, LIMIT_CHARACTER_SYNONYM);
      if (!_.isEmpty(content)) {
        return [...accumulator, content];
      }
      return accumulator;
    }, []);
  }

  if (exercise.alternatives) {
    data.alternatives = exercise.alternatives.map(it => it._id);
  }

  if (exercise.note && typeof exercise.note === 'string') {
    data.note = (exercise.note || '').trim().substring(0, EXERCISE_LIMIT_CHARACTER_NOTE) || '';
  } else if (exercise.note === '' || exercise.note === null) {
    data.note = '';
  }

  return data;
};

export const initExerciseInstance = exercise => {
  let instance = _.omit(exercise, ['createdAt', 'updatedAt', '__v', 'last_used', 'category']);
  instance.category = exercise.category ? exercise.category._id : undefined;

  if (!instance.fields) instance.fields = [];

  return instance;
};

export const isValidExercise = exercise => {
  return (
    exercise.training_sets.length > 0 &&
    exercise.exercise_instance !== undefined &&
    (typeof exercise.exercise_instance === 'string' ? exercise.exercise_instance.length > 0 : true)
  );
};

export const initIntervalExerciseFields = (exerciseInstance, allCategories, allFields) => {
  let fields = _.cloneDeep(exerciseInstance.fields || []);
  const durationField = _.find(allFields, f => f.unique_code === 'duration');
  const restField = _.find(allFields, f => f.unique_code === 'rest');
  const repField = _.find(allFields, f => f.unique_code === 'reps');
  const oneRepMaxField = _.find(allFields, item => item.unique_code === 'orm');
  const weightField = _.find(allFields, item => item.unique_code === 'weight');

  if (!durationField || !restField) {
    console.error("Can't get rest field or duration field");
    return fields;
  }

  const durationId = durationField._id;
  const restId = restField._id;
  const repId = repField ? repField._id : '';
  const catRemoveRep = _.filter(allCategories, c => STRENGTH_AND_BODYWEIGHT.includes(c.title));
  const catIDs = _.map(catRemoveRep, c => c._id);
  const exCategoryType =
    typeof exerciseInstance.category_type === 'string'
      ? exerciseInstance.category_type
      : _.get(exerciseInstance.category_type, '_id', '');

  fields.splice(fields.indexOf(restId), 1);

  if (exCategoryType && repId && catIDs.length && catIDs.includes(exCategoryType)) {
    const indexOfRepField = fields.indexOf(repId);

    if (indexOfRepField !== -1) {
      fields.splice(fields.indexOf(repId), 1);
    }
  }

  // add duration field
  if (!fields.includes(durationId)) {
    if (fields.includes(oneRepMaxField._id)) {
      fields = [oneRepMaxField._id, weightField._id];
    } else {
      fields = fields.slice(0, 2);
    }

    fields.push(durationId);
  }

  fields.push(restId);

  return fields;
};

export const initSetsData = (data, defaultIntervalData) => {
  //oldFields, newFields : list fieldId
  const { oldSets, newFields, systemFields, units } = data;
  let newSets = [];
  const restField = _.find(systemFields, f => f.unique_code === 'rest');
  let oldSetsCopy = (oldSets || []).slice();
  let newFieldsObj = _.map(newFields, fieldId => {
    return _.find(systemFields, f => f._id === fieldId) || {};
  });

  if (restField && !newFields.includes(restField._id)) {
    newFieldsObj.push(restField);
  }

  if (!oldSetsCopy || !oldSetsCopy.length) {
    oldSetsCopy.push({});
  }

  _.forEach(oldSetsCopy, set => {
    let data = { _id: set._id };

    _.forEach(newFieldsObj, field => {
      let setData = set[field.unique_code];

      if (!setData) {
        setData = { value: '' };
        const unitObj = units[field.unique_code];

        if (unitObj) {
          setData.unit = unitObj._id;
        }
      }

      if (defaultIntervalData && !setData.value) {
        if (field.unique_code === 'duration') {
          setData.value = 20;
        } else if (field.unique_code === 'rest') {
          if (!_.isNumber(setData.value)) {
            setData.value = 10;
          }
        }
      }

      data[field.unique_code] = setData;
    });

    newSets.push(data);
  });

  return newSets;
};

export const initFieldsWithORM = (oldFields, systemFields) => {
  let newFields = oldFields.slice();

  const ormFieldObj = _.find(systemFields, f => f.unique_code === 'orm');
  const weightFieldObj = _.find(systemFields, f => f.unique_code === 'weight');
  const restFieldObj = _.find(systemFields, f => f.unique_code === 'rest');
  const rep = _.find(systemFields, f => f.unique_code === 'reps');
  const hasRep = !!rep && oldFields.includes(rep._id);

  if (hasRep) {
    newFields.splice(0, 0, rep._id);
  }

  if (ormFieldObj && weightFieldObj && restFieldObj) {
    newFields.splice(newFields.indexOf(restFieldObj._id), 1);
    newFields.splice(newFields.indexOf(ormFieldObj._id), 1);
    newFields.splice(newFields.indexOf(weightFieldObj._id), 1);
    newFields = [ormFieldObj._id, weightFieldObj._id].concat(newFields);
    newFields = [...newFields.slice(0, 3), restFieldObj._id];
  }

  return newFields;
};

export const initBaseExerciseData = (authorId, defaultTitle, defaultModality) => {
  return {
    instructions: [],
    picture: [],
    fields: [],
    share: TEAM_SHARE_PRIVATE,
    author: { _id: authorId },
    title: defaultTitle || '',
    modality: defaultModality,
  };
};

export const shouldShowOneRepMaxPercent = (currentFields, systemFields) => {
  const weightField = systemFields.find(item => item.unique_code === 'weight');
  const oneRepMaxField = systemFields.find(item => item.unique_code === 'orm');

  if (!weightField || !oneRepMaxField) {
    return false;
  }

  const existedWeightFromCurrentFields = currentFields.find(item => item === weightField._id);
  const existed1RMFromCurrentFields = currentFields.find(item => item === oneRepMaxField._id);

  if (existedWeightFromCurrentFields || existed1RMFromCurrentFields) {
    return true;
  }

  return false;
};

export const isUseORM = (currentFields, systemFields) => {
  const oneRepMaxField = systemFields.find(item => item.unique_code === 'orm');
  const existed1RMFromCurrentFields = currentFields.find(item => item === _.get(oneRepMaxField, '_id'));

  if (existed1RMFromCurrentFields) {
    return true;
  }

  return false;
};

export const handleFormatFreestyleData = data => {
  const { FREESTYLE } = SECTION_FORMAT_KEY;

  if (data.format === FREESTYLE) {
    const note = data.note ? data.note : '';

    data = {
      note: note,
      attachments: data.attachments,
      exercise_references: data.exerciseReferences || _.map(data.exercise_references, item => item),
      format: data.format,
      title: data.title,
      type: data.type,
      _id: data._id,
    };
    return data;
  }
  return data;
};
