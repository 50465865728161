// Libs
import React from 'react';

// Style
import * as S from './style';
import { checkBlockValueIngredient } from '../helper';

const UnitInputString = props => {
  const {
    className,
    defaultValue,
    onChange = () => {},
    onFocus = () => {},
    value,
    error = false,
    onBlur = () => {},
  } = props;
  const handleChange = e => {
    const { value } = e.target;
    if (checkBlockValueIngredient(value)) return;
    onChange(value);
  };

  const handleKeyDown = event => {
    // Regular expression to match digits, forward slash, comma, and period
    const regex = /[0-9\/.,]/;
    const char = event.key;
    // Allow input if it matches the regex or if it's a backspace or Ctrl+A/Command+A (select all)
    const specialChars = ['Backspace', 'ArrowLeft', 'ArrowRight'];
    const isAChar = char === 'a' || char === 'A';
    if (
      !regex.test(char) &&
      !specialChars.includes(char) &&
      !(event.ctrlKey && isAChar) &&
      !(event.metaKey && isAChar)
    ) {
      event.preventDefault();
    }
  };

  return (
    <S.UnitInputWrapper className={className} error={error}>
      <input
        type="text"
        onChange={handleChange}
        value={value}
        defaultValue={defaultValue}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
      />
    </S.UnitInputWrapper>
  );
};

export default UnitInputString;
