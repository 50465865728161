import React from 'react';
import classNames from 'classnames';
import { DateTime, Interval } from 'luxon';
import moment from 'moment';
import ContentLoader from 'react-content-loader';

import { CDN_URL } from 'constants/commonData';

import SectionItem from './SectionItem';

import * as S from './style';

const SectionContentLoader = () => (
  <ContentLoader
    speed={2}
    width={250}
    height={120}
    viewBox="0 0 250 120"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="15" rx="2" ry="2" width="220" height="12" />
    <rect x="25" y="45" rx="2" ry="2" width="200" height="8" />
    <rect x="25" y="70" rx="2" ry="2" width="200" height="8" />
    <rect x="25" y="95" rx="2" ry="2" width="200" height="8" />
    <rect x="25" y="120" rx="2" ry="2" width="200" height="8" />
  </ContentLoader>
);

const BaseInfoLoader = () => (
  <ContentLoader
    speed={2}
    width={150}
    height={20}
    viewBox="0 0 150 20"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="7" rx="2" ry="2" width="120" height="12" />
  </ContentLoader>
);

const getTotalTime = workout => {
  let start_date = DateTime.fromJSDate(new Date(workout.start_date));
  let end_date = DateTime.fromJSDate(new Date(workout.end_date));
  let totalTime = '00:00';

  if (workout.duration) {
    totalTime = Math.round(workout.duration / 60);
  } else {
    const diff = Interval.fromDateTimes(start_date, end_date).toDuration('seconds').toObject();

    if (diff && diff.seconds) {
      totalTime = Math.round(diff.seconds / 60);
    }
  }
  return totalTime;
};

const WorkoutHistoryDetail = props => {
  const { workout, hide } = props;
  const totalTime = getTotalTime(workout);
  const isMissedWorkout = workout.status !== 2;
  const isLoading = workout.status === 2 && (!workout.sections_complete || !workout.sections_complete.length);
  const sections = isMissedWorkout ? workout.sections : workout.sections_complete;

  if (!moment(workout.day, 'MM-DD-YYYY').isBefore(moment().startOf('day')) && isMissedWorkout) {
    return null;
  }

  return (
    <S.Wrapper className={classNames({ 'workout-overview__hide': hide || isMissedWorkout })}>
      <S.Heading>
        <div>
          <div>{workout.title}</div>
          {isLoading && <BaseInfoLoader />}
          {!isMissedWorkout && !isLoading && (
            <S.BaseTrackedInfo>
              <img src={`${CDN_URL}/images/clock_grey_2.svg`} alt="clock" />
              <span>{totalTime}m</span>
              <img src={`${CDN_URL}/images/tracked_personal_best.svg`} alt="tracked_personal_best" />
              <span>{workout.records ? workout.records.length : 0}</span>
            </S.BaseTrackedInfo>
          )}
        </div>
        {isMissedWorkout ? (
          <S.MissedLabel>Missed</S.MissedLabel>
        ) : (
          <S.ExpandIcon className="workout-overview__expand-icon" onClick={() => props.toggleHide(workout._id)} />
        )}
      </S.Heading>
      <S.Content className="workout-overview__content">
        {isLoading ? <SectionContentLoader /> : null}
        {(sections || []).map(section => (
          <SectionItem section={section} key={section._id} isMissedWorkout={isMissedWorkout} />
        ))}
      </S.Content>
    </S.Wrapper>
  );
};

export default WorkoutHistoryDetail;
