import React, { Fragment, memo, useMemo, useState } from 'react';
import { Rectangle } from 'recharts';
import flatMap from 'lodash/flatMap';

import { SIZES, SLEEP_COLOR, SLEEP_STAGE_KEYS, SLEEP_STAGES_TIME_ORDERED as STAGES_ORDERED } from './constants';
import { getColorsRange } from './hourly-helper';

const HEIGH_OF_CATEGORY = 72;
const ASLEEP_HEIGHT = 154.5;
const COLOR_OPACITY = '26'; // 15%
const Y_BY_STEPS = {
  1: 3.2,
  2: 5.5,
  3: 8,
};
const HEIGHT_BY_STEPS = {
  1: 3.8,
  2: 1.5,
  3: -1,
};

const RenderRadius = memo(({ x, y, color, size = 6, radius = [0, 0, 0, 0], className = '' }) => (
  <Rectangle x={x} y={y} width={size} height={size} radius={radius} fill={color} className={className} />
));

const HourlyTimeConnectedStagesBarShape = props => {
  const { x, y, width, height, payload, setTooltipPosition, chartData } = props;
  const { rangeValues, dataType } = payload;
  const [blockHover, setBlockHover] = useState(null);
  const total = useMemo(() => {
    if (!rangeValues) return 0;
    return Math.abs(rangeValues[0] - rangeValues[1]);
  }, [rangeValues]);
  const allEntries = useMemo(() => flatMap(chartData, 'dataType'), [chartData]);
  const isHovering = Boolean(blockHover);

  const handleMouseEnter = (e, block) => {
    const { entry } = block || {};
    const { type = '' } = entry || {};

    const { top, left, width: widthBlock } = e.currentTarget.getBoundingClientRect();

    setBlockHover(block);
    setTooltipPosition({
      top: type === SLEEP_STAGE_KEYS.deep ? top - 45 : top,
      left,
      entry,
      payloadTooltip: { ...payload, widthBlock },
    });
  };

  const handleMouseLeave = () => {
    setBlockHover(null);
    setTooltipPosition(undefined);
  };

  const renderRadiusBottomRight = (xRadius, yRadius, color) => (
    <>
      <RenderRadius x={xRadius} y={yRadius} color={color + COLOR_OPACITY} className="top-left" />
      <RenderRadius x={xRadius - 2} y={yRadius} size={8} radius={[0, 8, 0, 0]} color="#fff" />
    </>
  );

  const renderRadiusBottomLeft = (xRadius, yRadius, color) => (
    <>
      <RenderRadius x={xRadius} y={yRadius} color={color + COLOR_OPACITY} className="top-right" />
      <RenderRadius x={xRadius} y={yRadius} size={8} radius={[8, 0, 0, 0]} color="#fff" />
    </>
  );

  const renderRadiusTopLeft = (xRadius, yRadius, color) => (
    <>
      <RenderRadius x={xRadius} y={yRadius} color={color + COLOR_OPACITY} className="bottom-right" />
      <RenderRadius x={xRadius} y={yRadius - 2} size={8} radius={[0, 0, 0, 8]} color="#fff" />
    </>
  );

  const renderRadiusTopRight = (xRadius, yRadius, color) => (
    <>
      <RenderRadius x={xRadius + 2} y={yRadius} color={color + COLOR_OPACITY} className="bottom-left" />
      <RenderRadius x={xRadius} y={yRadius - 2} size={8} radius={[0, 0, 8, 0]} color="#fff" />
    </>
  );

  const getPrevNextEntry = currentEntry => {
    let prevEntry = null;
    let nextEntry = null;

    allEntries.forEach(item => {
      if (item.type !== 'empty') {
        if (currentEntry.start === item.end) {
          prevEntry = item;
        }
        if (currentEntry.end === item.start) {
          nextEntry = item;
        }
      }
    });
    return { prevEntry, nextEntry };
  };

  const renderBar = (entry, currIndex) => {
    if (entry.type === 'empty') return null;

    const isHover = isHovering && blockHover.blockHoverIndex === currIndex;
    const { prevEntry, nextEntry } = getPrevNextEntry(entry);
    let step = 0;
    let yOfNextEntry = y;
    let isTop2Bottom = true;
    const borderRadius = {
      top_left: 6,
      top_right: 6,
      bottom_right: 6,
      bottom_left: 6,
    };

    const color = SLEEP_COLOR[entry.type];
    let lineColors = [color];
    let yBuffer = 0;
    let heightBuffer = 0;

    if (prevEntry) {
      if (STAGES_ORDERED[prevEntry.type] > STAGES_ORDERED[entry.type]) {
        borderRadius.bottom_left = 0;
      } else {
        if (prevEntry.isAsleep) {
          borderRadius.bottom_left = 0;
        }
        borderRadius.top_left = 0;
      }
    }

    if (nextEntry) {
      step = Math.abs(STAGES_ORDERED[nextEntry.type] - STAGES_ORDERED[entry.type]);
      if (STAGES_ORDERED[entry.type] > STAGES_ORDERED[nextEntry.type]) {
        yOfNextEntry = y - step * HEIGH_OF_CATEGORY;
        borderRadius.top_right = 0;
        isTop2Bottom = false;
        lineColors = [SLEEP_COLOR[nextEntry.type], ...lineColors];
        if (nextEntry.isAsleep) {
          borderRadius.bottom_right = 0;
        }
      } else {
        borderRadius.bottom_right = 0;
        lineColors.push(SLEEP_COLOR[nextEntry.type]);
        if (step === 1) {
          yBuffer = 0.5;
        }
        if (step === 2) {
          heightBuffer = 4;
        }
      }
    }

    const widthBar = (entry.value / total) * width;
    const xBar = dataType.reduce((tt, item, itemIndex) => {
      if (itemIndex < currIndex) {
        return tt + (item.value / total) * width;
      }
      return tt;
    }, x);

    const yStep = Y_BY_STEPS[step] || 0;
    const hStep = HEIGHT_BY_STEPS[step] || 0;
    const xLine = xBar + widthBar - SIZES.OFFSET_HOVER;
    const yLine = yOfNextEntry + height + SIZES.OFFSET_HOVER - (isTop2Bottom ? 0 : yStep - 1);
    const heightLine = (step + 1) * HEIGH_OF_CATEGORY - 120 - (isTop2Bottom ? yStep : hStep) - yBuffer + heightBuffer;
    const entryAsleepHeight = entry.isAsleep ? ASLEEP_HEIGHT : 0;
    const entryRadius = entry.isAsleep ? (prevEntry ? [3, 0, 3, 0] : [3, 0, 3, 3]) : [3, 3, 3, 3];
    const finalColors = getColorsRange(lineColors);
    const xTop = xBar + (nextEntry ? 3 : 2);
    const yBottom = y - (entry.isAsleep ? 3 : 0) - 9;
    const yTop = y - (entry.isAsleep ? 3 : 0) + height + 3;

    if (entry.isAsleep && !nextEntry) {
      entryRadius[1] = 3;
    }

    return (
      <Fragment key={currIndex}>
        {/** radius at corners */}
        {!isHover && widthBar >= 12 && (
          <>
            {borderRadius.top_left === 0 && renderRadiusTopLeft(xTop, yBottom, color)}
            {borderRadius.top_right === 0 && renderRadiusTopRight(xBar + widthBar - 11, yBottom, color)}
            {borderRadius.bottom_right === 0 && renderRadiusBottomRight(xBar + widthBar - 9, yTop, color)}
            {borderRadius.bottom_left === 0 && !entry.isAsleep && renderRadiusBottomLeft(xTop, yTop, color)}
          </>
        )}

        {/** line connect */}
        {Boolean(nextEntry) && !nextEntry.isAsleep && !entry.isAsleep && (
          <Rectangle
            x={xLine}
            y={yLine}
            width={6}
            height={heightLine}
            fill={`url(#color-${finalColors.join('-')}`}
            className="bar-shape-custom-connect-line"
          />
        )}

        {/** stage buffer for border */}
        {!entry.isAsleep && (
          <Rectangle
            x={xBar - SIZES.OFFSET_HOVER}
            y={y - SIZES.OFFSET_HOVER}
            width={widthBar + SIZES.OFFSET}
            height={height + SIZES.OFFSET}
            fill={color + COLOR_OPACITY}
            radius={[
              borderRadius.top_left,
              borderRadius.top_right,
              borderRadius.bottom_right,
              borderRadius.bottom_left,
            ]}
            className="bar-shape-custom-buffer"
          />
        )}

        {/** main stage */}
        <Rectangle
          key={entry.type + currIndex}
          x={xBar + (entry.isAsleep ? 3 : 0)}
          y={y - (entry.isAsleep ? 3 : 0)}
          width={widthBar - (entry.isAsleep ? 6 : 0)}
          height={height + entryAsleepHeight}
          radius={entryRadius}
          fill={entry.isAsleep ? 'url(#color-asleep)' : color}
          className="bar-shape-custom"
          onMouseEnter={e =>
            handleMouseEnter(e, {
              x: xBar,
              y,
              width: widthBar,
              entry,
              blockHoverIndex: currIndex,
              color: SLEEP_COLOR[entry.type],
            })
          }
          onMouseLeave={handleMouseLeave}
        />
      </Fragment>
    );
  };

  const renderBlockHover = () => {
    const {
      x: xHover,
      y: yHover,
      width: wHover,
      entry: { isAsleep },
      color: cHover,
    } = blockHover;

    return (
      <Rectangle
        x={xHover - SIZES.OFFSET + (isAsleep ? 6 : 0)}
        y={yHover - SIZES.OFFSET}
        width={wHover + SIZES.OFFSET * 2 - (isAsleep ? 12 : 0)}
        height={height + SIZES.OFFSET * 2 + (isAsleep ? ASLEEP_HEIGHT - 6 : 0)}
        fill={cHover + COLOR_OPACITY}
        radius={[6, 6, 6, 6]}
        className="bar-shape-custom-hover"
      />
    );
  };

  return (
    <svg fill="none">
      {isHovering && renderBlockHover()}
      {dataType.map(renderBar)}
    </svg>
  );
};

export default HourlyTimeConnectedStagesBarShape;
