import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import { toggleConfirmModal, toggleModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import { isTeamAdmin } from 'utils/commonFunction';
import UpgradeYourPlanModal from 'shared/UpgradePath/components/UpgradeYourPlanModal';
import { activeClients, deleteClients } from 'redux/waiting-activation-clients/actions';
import CustomerColumn from './CustomerColumn';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import { CDN_URL } from 'constants/commonData';

import * as S from '../style';
import { ReactComponent as ActiveIcon } from 'assets/icons/check.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

const TableContent = props => {
  const { list, loading, maximum, totalActive, isAdmin } = props;
  const clients = useMemo(() => list.toJS(), [list]);

  const hasOverlapByOnboarding = document.querySelector('.onboarding-guide');
  const hasOverlapByIntercom = document.querySelector('.intercom-launcher');

  const today = moment();

  const renderTrigger = ({ open }) => <MenuTrigger vertical active={!!open} />;

  const renderSignUpDate = value => {
    const date = moment(value);
    return date.isSame(today, 'year') ? date.format('MMM D') : date.format('MMM D, YYYY');
  };

  if (loading) {
    return null;
  }

  const handleCloseModal = () => {
    props.toggleModal(false);
  };

  const handleUpgrade = () => {
    if (isAdmin) {
      return props.push('/pricing-plan');
    } else {
      props.toggleModal(true, <UpgradeYourPlanModal onClose={handleCloseModal} />);
    }
  };

  const handleActive = e => {
    const itemId = e.currentTarget.dataset.item;
    const activeUser = clients.find(client => client._id === itemId);
    if (maximum > totalActive) {
      return props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Activate Client"
          content={`You are about to activate ${activeUser.full_name}. Once activated, you will find them in your ‘Connected’ client list.`}
          onConfirm={() => doActive(activeUser)}
          confirmButtonTitle="Activate"
          confirmButtonClass="btn-active"
          cancelButtonTitle="Cancel"
          headerIcon={`${CDN_URL}/images/active_waiting_client_confirm.svg`}
          newStyle
        />,
      );
    } else {
      return props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Client seats are full"
          content={`Please upgrade to a subscription with more client seats or archive some clients first to activate ${activeUser.full_name}.`}
          onConfirm={handleUpgrade}
          confirmButtonTitle="Upgrade"
          cancelButtonTitle="Cancel"
          confirmButtonClass="btn-upgrade"
          headerIcon={`${CDN_URL}/images/active_wating_client_full_confirm.svg`}
          newStyle
        />,
      );
    }
  };

  const doActive = activeUser => {
    props.activeClients([activeUser._id]).then(() => {
      toast(`${activeUser.full_name} has been activated!`);
    });
  };

  const handleDelete = e => {
    const itemId = e.currentTarget.dataset.item;
    const deleteUser = clients.find(client => client._id === itemId);
    return props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete Client"
        content="Client information will be permanently removed from your account. Are you sure?"
        onConfirm={() => doDelete(deleteUser)}
        confirmButtonTitle="Delete"
        src={`${CDN_URL}/images/trash-circle.svg`}
        cancelButtonTitle="Cancel"
        newStyle
      />,
    );
  };

  const doDelete = deleteUser => {
    props.deleteClients([deleteUser._id]).then(() => {
      toast(`Client has been deleted`);
    });
  };

  if (!clients.length) {
    return (
      <S.TableBody>
        <S.TableRow>
          <S.TableCell colspan={5}>No clients found</S.TableCell>
        </S.TableRow>
      </S.TableBody>
    );
  }

  return (
    <S.TableBody>
      {clients.map(item => (
        <S.TableRow key={item._id}>
          <CustomerColumn data={item} />
          <S.TableCell>
            <S.TableCellContent>{renderSignUpDate(item.createdAt)}</S.TableCellContent>
          </S.TableCell>
          <S.TableCell>
            <S.TableCellContent>Waiting</S.TableCellContent>
          </S.TableCell>
          <S.TableCell>
            <S.TableCellContent>{item.trainer_full_name}</S.TableCellContent>
          </S.TableCell>
          <S.TableCell className="actions">
            <S.TableCellContent>
              <DropDown
                triggerIcon={renderTrigger}
                isWaitingClient
                hasOverlap={hasOverlapByOnboarding || hasOverlapByIntercom}
              >
                <Option
                  key="active"
                  onClick={handleActive}
                  data-item={item._id}
                  style={{ minWidth: 130, minHeight: 30 }}
                >
                  <ActiveIcon />
                  <span>Active Client</span>
                </Option>
                <Option
                  key="delete"
                  onClick={handleDelete}
                  data-item={item._id}
                  style={{ minWidth: 130, minHeight: 30 }}
                >
                  <DeleteIcon />
                  <span>Delete Client</span>
                </Option>
              </DropDown>
            </S.TableCellContent>
          </S.TableCell>
        </S.TableRow>
      ))}
    </S.TableBody>
  );
};

const mapState = state => {
  const {
    rootReducer: { client, permission },
    user,
  } = state;
  return {
    maximum: permission.no_clients || 0,
    totalActive: client.total_active_clients || 0,
    list: state.rootReducer.waitingActivationClients.get('list'),
    loading: state.rootReducer.waitingActivationClients.get('loading'),
    isAdmin: isTeamAdmin(user),
  };
};

const mapDispatch = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  push: bindActionCreators(push, dispatch),
  activeClients: bindActionCreators(activeClients, dispatch),
  deleteClients: bindActionCreators(deleteClients, dispatch),
});

export default connect(mapState, mapDispatch)(TableContent);
