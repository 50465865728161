import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import * as S from './style';
import { SelectFile } from 'shared/FormControl';
import {
  revokeObjectURL,
  revokeMultipleObjectURL,
  createObjectURL,
  reorder,
  isNumber,
  isVideo,
  mediaLog,
  getPresignedUploadUrl,
  getMetadataMediaFile,
} from 'utils/commonFunction';
import { removeUploadedFileFromServer } from 'utils/commonRequest';
import { FILE_VALIDATION, DEFAULT_STATE, UPLOAD_KEY, ERROR_MESSAGE } from './constants';
import MediaModal from 'shared/MediaModal/component';
import * as UploadStyles from 'shared/FileUpload/style';
import ProgressRing from 'shared/ProgressRing';
import { axiosInstance } from 'configs/request';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { getFileExtension } from 'helpers/exercise';
import { CONVERSION } from 'constants/commonData';
import { ForumPostTrigger, GiphyPopup } from 'shared/Giphy';

import { ReactComponent as CameraIcon } from 'assets/icons/camera_blue_icon.svg';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import S3Video from 'shared/S3Video';
import PollPostTrigger from 'components/CommunityForum/components/PollPostTrigger';

const CancelToken = axios.CancelToken;

class MediaSide extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mediaData: { ...DEFAULT_STATE.mediaData },
      uploadPhotoProgress: {},
      shouldDisableUpload: false,
      isRawGiphy: false,
      isLoadingGiphy: false,
    };

    this.uploadTasks = {};
    this.counterUploaded = 0; // Counter number of media upload
    this.selectedFiles = 0;
    this.counterRemovedFiles = 0;
    this.uploadConfigs = null;
  }

  componentDidMount() {
    const { mediaData, file } = this.props;
    if (mediaData) {
      this.setState({
        mediaData: { ...this.state.mediaData, list: mediaData },
      });
      if (mediaData.list && mediaData.list.length >= 5) {
        this.setState({ shouldDisableUpload: true });
      }
    }
    if (file) {
      this.selectMedia(file);
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.mediaData.list !== prevState.mediaData.list) {
      const { list } = this.state.mediaData;
      if (list && list.length >= 5) {
        this.setState({ shouldDisableUpload: true });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { mediaData } = this.state;
    let listObjectURL = [];

    const media = _.filter(mediaData.list, item => !!item && item.isLocalFile);
    const localPhotoUrl = _.map(media, item => item.src);

    listObjectURL = listObjectURL.concat(localPhotoUrl);

    if (listObjectURL.length) {
      revokeMultipleObjectURL(listObjectURL);
    }

    _.forEach(this.uploadTasks, task => {
      if (typeof task === 'function') {
        task();
      }
    });
  }

  hasAttachmentGiphy = attachments => {
    return _.findIndex(attachments, ['attachment_source', 'giphy']) !== -1;
  };

  prepareVideoAndPhotoData = async items => {
    const { media } = items;

    try {
      let listURL = _.map(media, 'src');

      const listPresignedURLs = await this.props.getS3presignedURLFromLocalDatabase(listURL);

      const newPhotos = _.map(media, (p, index) => ({ ...p, src: listPresignedURLs[index] }));

      this.setState({
        mediaData: { ...this.state.mediaData, list: newPhotos },
      });
    } catch {
      this.setState({
        mediaData: { ...this.state.mediaData, list: media },
      });
    }
  };

  updatePhotoData = (data, addingPhotos = []) => {
    const { mediaData } = this.state;

    this.setState(
      prev => ({ mediaData: { ...prev.mediaData, ...data } }),
      () => {
        if (addingPhotos.length) {
          this.uploadFilesToServer(addingPhotos);
        }
      },
    );
    this.props.onUpdate({ mediaData: { ...mediaData, ...data } });
  };

  handleCounterUploadMedia = () => {
    const { onEndUpload } = this.props;
    if (onEndUpload) {
      // Uploaded all media
      if (this.selectedFiles - this.counterRemovedFiles - this.counterUploaded <= 0) {
        onEndUpload();
        this.selectedFiles = 0;
        this.counterUploaded = 0;
        this.counterRemovedFiles = 0;
      }
    }
  };

  uploadFilesToServer = async media => {
    this.props.onStartUpload();
    const counterUploadMedia = media.length;

    if (counterUploadMedia) {
      for (let i = 0; i < counterUploadMedia; i++) {
        const { file, _id } = media[i];
        const { uploadUrl, configs } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-forum', file, true);
        if (!uploadUrl || !configs) {
          return;
        }

        this.uploadConfigs = {
          url: uploadUrl,
          method: 'put',
          data: file,
          headers: { 'Content-Type': file.type },
        };

        if (!this.uploadTasks[_id]) {
          const formData = new FormData();
          formData.append(UPLOAD_KEY, file);

          axiosInstance({
            ...this.uploadConfigs,
            onUploadProgress: progressData => {
              const { loaded, total } = progressData;
              const { list } = this.state.mediaData;
              const newProgress = Math.floor((loaded / total) * 100);
              const itemIndex = _.findIndex(list, obj => obj._id === _id);

              if (itemIndex !== -1) {
                this.setState(prevState => ({
                  uploadPhotoProgress: { ...prevState.uploadPhotoProgress, [_id]: newProgress },
                }));
              }
            },
            cancelToken: new CancelToken(
              function executor(c) {
                this.uploadTasks[_id] = c;
              }.bind(this),
            ),
          })
            .then(response => {
              const { data } = response.config;
              const { name, size, type } = data;
              mediaLog({
                status: 2,
                name,
                fileSize: size,
                fileType: type,
                description: 'Upload success file via post',
              });

              this.onUploadPhotoSuccess({ _id, data: { ...data, ...configs } });
              this.counterUploaded++;
              this.handleCounterUploadMedia();
            })
            .catch(error => {
              if (axios.isCancel(error)) {
                if (this._isMounted) {
                  const { list } = this.state.mediaData;
                  const itemIndex = _.findIndex(list, obj => obj._id === _id);
                  this.onRemoveSinglePhoto({ index: itemIndex });
                  this.counterUploaded++;
                  this.handleCounterUploadMedia();
                }
              } else {
                delete this.uploadTasks[_id];
              }
            });
        }
      }
    }
  };

  selectMedia = async originalFiles => {
    let files = originalFiles;
    const { list = [] } = this.state.mediaData;
    const maxSelect = 5 - list.length;
    this.selectedFiles = files.length >= maxSelect ? maxSelect : files.length;
    if (!files.length) {
      return;
    }

    let hasError = false;

    if (list.length < 6) {
      const parsedPhotos = await Promise.all(
        _.map(files, async (file, index) => {
          const { width, height } = await getMetadataMediaFile(file);

          if (file.type.includes('video')) {
            if (file.size > FILE_VALIDATION.MAX_SIZE_VIDEO * CONVERSION.MB_TO_BYTE) {
              hasError = true;
              return toast.error('Please resize and upload a video smaller than 300 MB');
            }
            return {
              src: createObjectURL(file),
              isLocalFile: true,
              file: file,
              videoType: getFileExtension(_.get(file, 'name', '')),
              width: parseInt(width),
              height: parseInt(height),
              _id: `adding_multiple_${new Date().getTime()}_${index}`,
            };
          } else {
            if (file.size > FILE_VALIDATION.MAX_SIZE_IMAGE * CONVERSION.MB_TO_BYTE) {
              hasError = true;
              return toast.error('Please upload an image smaller than 25 MB');
            }
            return {
              src: createObjectURL(file),
              isLocalFile: true,
              file: file,
              width: parseInt(width),
              height: parseInt(height),
              _id: `adding_multiple_${new Date().getTime()}_${index}`,
            };
          }
        }),
      );
      if (!hasError) {
        const addingPhotos = await Promise.all(parsedPhotos.slice(0, 5 - list.length));

        this.updatePhotoData(
          {
            list: [...list, ...addingPhotos],
            error: addingPhotos.length < parsedPhotos.length ? ERROR_MESSAGE.MAX_PHOTO_NUMBER : '',
            shouldDisableUpload: addingPhotos.length === 5 ? true : false,
          },
          addingPhotos,
        );
      }
    } else {
      this.updatePhotoData({ error: ERROR_MESSAGE.MAX_PHOTO_NUMBER, shouldDisableUpload: true });
    }
  };

  onRemoveSinglePhoto = ({ index }) => {
    const { mediaData, uploadPhotoProgress } = this.state;
    const list = mediaData.list.slice();
    if (index !== -1) {
      const data = list[index];

      if (data && data.isLocalFile) {
        revokeObjectURL(data.src);
        const newUploadPhotoProgress = _.omit(uploadPhotoProgress, `${data._id}`);
        this.setState({
          uploadPhotoProgress: newUploadPhotoProgress,
        });
      }

      list.splice(index, 1);
      this.updatePhotoData({ list, error: '' });
    }
    if (list.length < 5) {
      this.setState({ shouldDisableUpload: false });
    }
    this.props.onRemovePhoto(true);
  };

  onUploadPhotoSuccess = ({ data, _id }) => {
    const { mediaData } = this.state;

    if (_id) {
      this.uploadConfigs = null;
      const index = _.findIndex(mediaData.list, item => item._id === _id);
      delete this.uploadTasks[_id];

      if (index !== -1) {
        const list = mediaData.list.slice();
        list[index] = { ...list[index], file: null, apiURL: data.location, bucketData: data };
        this.updatePhotoData({ list });
      } else {
        removeUploadedFileFromServer(data);
      }
    }
  };

  onCancelUploadphoto = photoId => {
    const { list } = this.state.mediaData;
    const itemIndex = _.findIndex(list, obj => obj._id === photoId);
    this.onRemoveSinglePhoto({ index: itemIndex });
    this.uploadConfigs = null;
    this.uploadTasks[photoId]();

    const { uploadPhotoProgress } = this.state;
    const { uploadedAllMedia } = this.props;
    const newUploadPhotoProgress = _.omit(uploadPhotoProgress, `${photoId}`);
    const status = Object.values(newUploadPhotoProgress).some(item => item < 100);
    this.setState({
      uploadPhotoProgress: newUploadPhotoProgress,
    });
    uploadedAllMedia && uploadedAllMedia(!status);

    if (list.length < 5) {
      this.setState({ shouldDisableUpload: false });
    }
    this.props.onRemovePhoto(false);
    this.counterRemovedFiles += 1;
    this.handleCounterUploadMedia();
  };

  onEndingRearrangePhotos = result => {
    const { mediaData } = this.state;
    const oldIndex = _.get(result, 'source.index');
    const newIndex = _.get(result, 'destination.index');

    if (isNumber(oldIndex) && isNumber(newIndex) && mediaData.list[newIndex]) {
      const { mediaData } = this.state;
      const list = mediaData.list.slice();
      const newList = reorder(list, oldIndex, newIndex);
      this.updatePhotoData({ list: newList });
    }
  };

  onGifClick = gif => {
    const url = _.get(gif, 'images.original.url');
    const id = _.get(gif, 'id');
    const height = _.get(gif, 'images.original.height');
    const width = _.get(gif, 'images.original.width');
    if (!_.isEmpty(url) && !_.isEmpty(id)) {
      const data = {
        attachment: url,
        url: url,
        thumbnail_url: url,
        name: id,
        original_name: id,
        attachment_id: id,
        type: 'image/gif',
        attachment_source: 'giphy',
        height,
        width,
      };

      this.updatePhotoData({ list: [data] });
      this.setState({
        isRawGiphy: true,
        isLoadingGiphy: true,
      });
      this.props.onStartUpload();
    }
  };

  handleRenderReviewVideo = (uploadPhotoProgress, data) => {
    const { uploadedAllMedia } = this.props;
    const { file, src } = data;
    const uploading = uploadPhotoProgress[data._id] || 0;

    if (!file || uploading === 0) return;

    const status = Object.values(uploadPhotoProgress).some(item => item < 100);
    uploadedAllMedia && uploadedAllMedia(!status);

    return (
      <video className="s3-video">
        {src ? (
          <>
            <source src={src} />
          </>
        ) : null}
      </video>
    );
  };

  handleLoadRawGiphy = event => {
    event.preventDefault();
    this.setState({
      isLoadingGiphy: false,
    });
    this.props.onEndUpload();
  };

  handleCreatePoll = () => {
    const { onEnablePoll } = this.props;

    typeof onEnablePoll === 'function' && onEnablePoll();
  };

  renderRearrangeUI = () => {
    const { mediaData, uploadPhotoProgress } = this.state;
    const { fileType } = this.props;
    const { list } = mediaData;
    const { isRawGiphy, isLoadingGiphy } = this.state;

    return (
      <DragDropContext onDragEnd={this.onEndingRearrangePhotos}>
        <Droppable droppableId="exercise-photos__droppable" direction="horizontal">
          {provided => (
            <S.ListPhoto ref={provided.innerRef} {...provided.droppableProps}>
              {_.map(list.concat(Array(5)).slice(0, 5), (data, index) => {
                if (!data) return <></>;
                return (
                  <Draggable key={index} draggableId={`draggable;${index}`} index={index}>
                    {provided => (
                      <S.PhotoItemContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={index}
                      >
                        <S.PhotoItem isLoadingGiphy={isLoadingGiphy}>
                          {data.file ? (
                            <>
                              {this.handleRenderReviewVideo(uploadPhotoProgress, data)}
                              <UploadStyles.Wrapper>
                                <UploadStyles.Container>
                                  <ProgressRing
                                    radius={22}
                                    progressBarWidth={3}
                                    progress={uploadPhotoProgress[data._id] || 0}
                                  />
                                  <UploadStyles.CancelButton
                                    onClick={() => this.onCancelUploadphoto(data._id)}
                                    className="file-upload__cancel-button"
                                  />
                                </UploadStyles.Container>
                              </UploadStyles.Wrapper>
                            </>
                          ) : (
                            <>
                              {data.videoType || isVideo(data.url || '') ? (
                                <S.Preview>
                                  <MediaModal
                                    video={data.src || data.url}
                                    presigned={false}
                                    validVideo={true}
                                    fileType={fileType}
                                  />
                                  <S3Video videoLink={_.get(data, 'url') || _.get(data, 'src')} showDuration={true} />

                                  <S.RemoveIcon
                                    className="remove-icon"
                                    onClick={() => {
                                      this.onRemoveSinglePhoto({ index: index });
                                      // this.props.onEndUpload(); // TODO
                                      this.handleCounterUploadMedia();
                                    }}
                                  />
                                </S.Preview>
                              ) : (
                                <>
                                  {data.isLocalFile ? (
                                    <img src={_.get(data, 'src')} alt="" className="thumbnail-item" />
                                  ) : isRawGiphy ? (
                                    <img
                                      src={_.get(data, 'thumbnail_url')}
                                      alt=""
                                      className="thumbnail-item"
                                      onLoad={this.handleLoadRawGiphy}
                                      style={{ display: isLoadingGiphy ? 'none' : 'block' }}
                                    />
                                  ) : (
                                    <S3ImageWithFallback
                                      src={[_.get(data, 'thumbnail_url')]}
                                      className="thumbnail-item"
                                    />
                                  )}
                                  <MediaModal
                                    image={data.thumbnail_url || data.src}
                                    presigned={false}
                                    validVideo={true}
                                    fileType={fileType}
                                  />
                                  <S.RemoveIcon
                                    className="remove-icon"
                                    onClick={() => {
                                      this.onRemoveSinglePhoto({ index: index });
                                      this.handleCounterUploadMedia();
                                      // this.props.onEndUpload(); // TODO
                                    }}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </S.PhotoItem>
                      </S.PhotoItemContainer>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </S.ListPhoto>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  render() {
    const { pollEnable, showPollIcon } = this.props;
    const { mediaData } = this.state;
    const { list } = mediaData;

    const disablePoll = this.hasAttachmentGiphy(list) || list.length >= 1;

    return (
      <S.Content>
        <S.Section>
          {list.length > 0 && this.renderRearrangeUI()}
          <S.WrapperButton>
            <SelectFile
              trigger={
                <S.AddAssetButton
                  disabled={this.state.shouldDisableUpload || this.hasAttachmentGiphy(list) || pollEnable}
                >
                  <CameraIcon />
                  <span>Photo/Video</span>
                </S.AddAssetButton>
              }
              onSelect={this.selectMedia}
              accept={FILE_VALIDATION.ACCEPTED}
              validateExtentions={FILE_VALIDATION.VALIDATE_EXTENTION}
              maxSize={FILE_VALIDATION.MAX_SIZE_VIDEO}
              multiple={true}
            />
            {this.props.giphyEnable && (
              <GiphyPopup
                size="small"
                trigger={ForumPostTrigger}
                onGifClick={this.onGifClick}
                disabled={
                  this.state.shouldDisableUpload || (!this.hasAttachmentGiphy(list) && list.length >= 1) || pollEnable
                }
              />
            )}
            {showPollIcon && (
              <PollPostTrigger
                onClick={this.handleCreatePoll}
                hasTooltip={!pollEnable && !disablePoll}
                pollEnable={pollEnable}
                disabled={disablePoll}
              />
            )}
          </S.WrapperButton>
        </S.Section>
      </S.Content>
    );
  }
}

MediaSide.propTypes = {};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    getS3presignedURLFromLocalDatabase: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(MediaSide);
