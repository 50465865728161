import styled, { keyframes, css } from 'styled-components';

import { Button } from 'shared/FormControl';
import * as Upload from '../../Upload/style';

export const TodayScreenWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between !important;
`;

export const LeftArea = styled.div`
  display: inherit;
  width: calc(100% - 120px);
  height: 100%;
  flex-wrap: wrap;
`;

export const RightArea = styled.div``;

export const DescriptionContainer = styled.div`
  display: inline-block;
  margin-bottom: 0;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const DescriptionText = styled.p`
  display: inline-block;
  color: #202353;
  max-width: 170px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 21px;
`;

export const LinkContentText = styled.span`
  color: #6456c4;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
`;

export const Link = styled.a`
  img {
    margin-bottom: -2px;
  }

  &:hover,
  &:active,
  &:focus {
    color: #6456c4;
  }
`;

export const LinkText = styled.div`
  max-width: 360px;
`;

export const UploadLogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DragBoxItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    transform: translate(2px, 2px);
  }

  ${props => props.withLeftMargin && `margin-left: 50px;`}
  ${props =>
    props.withMarginTopSmallScreen &&
    `
    @media screen and (max-width: 1291px) {
      margin-left: 0px !important;
      margin-top: 30px;
    }
  `}

	label {
    color: #6a778a;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`;

export const TodayLogo = styled.div`
  position: absolute;
  left: 25px;
  top: 18px;
  height: 50px;
  width: 50px;
  background: #fff url(${props => props.src}) no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
`;

export const DragBoxElement = styled.div`
  display: flex;
  position: relative;

  ${Upload.Wrapper} {
    width: 282px;

    ${props =>
      props.src &&
      css`
        border: 1px solid #dadfea;
        border-radius: 5px;
      `}
  }

  ${Upload.Icon} {
    margin-right: 10px;
  }

  ${Upload.Text} {
    width: calc(100% - 120px);
  }

  ${Upload.Thumbnail} {
    background-size: contain;

    ${props =>
      props.horizontalSide &&
      css`
        width: 64px;
        flex: 0 0 64px;
        background-size: cover;
        background-position: left center;
      `}

    ${props =>
      props.verticalSide &&
      css`
        width: 64px;
        flex: 0 0 64px;
        background-size: cover;
        background-position: center;
      `}
  }

  ${Upload.Icon} {
    width: 33px;
  }

  ${Upload.FileInformation} {
    min-height: 85px;
  }
`;

export const ValidationText = styled.span`
  width: calc(100% - 29px);
  color: #868c9a;
  font-size: 13px;
  line-height: 18px;

  .highlight {
    font-weight: 600;
  }
`;

export const ValidationDescriptionArea = styled.div`
  display: flex;
  margin-left: 15px;
  align-items: flex-end;
`;

export const ValidationDescription = styled.div`
  display: flex;
  width: 237px;
  margin-top: 10px;
  padding-bottom: 12px;

  ${ValidationText} {
    margin-left: 5px;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-top: 3px;

    path {
      fill: #ccd3e2;
    }
  }
`;

export const SaveButton = styled(Button)`
  background-color: #5c5bbd;
  color: #fff;
  box-sizing: border-box;
  border-radius: 5px;

  &.save {
    min-width: 110px;
  }

  :hover {
    background-color: #5559ff;
  }

  &.disable {
    background-color: #c4c4c4;
    color: #fff;
    pointer-events: none;
  }
`;
