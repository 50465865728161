import React, { useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { Tab } from 'semantic-ui-react';
import ProductList from '../List';
import AddNewForm from '../AddNewForm';

import * as S from './style';
import { NewSearchInput } from 'shared/SearchInput';
import { debounce } from 'lodash';

const Header = props => {
  const { activeIndex, onTabChange, counter, onAddNewProduct, productTotal, onSearchProduct } = props;
  const publishCount = counter.publish && counter.publish > 0 ? counter.publish : 0;
  const draftCount = counter.draft && counter.draft > 0 ? counter.draft : 0;
  const allProduct = draftCount + publishCount > 0 ? draftCount + publishCount : 0;

  const panes = [
    {
      menuItem: `All Sequences (${allProduct})`,
      name: '',
    },
    {
      menuItem: `Published (${publishCount})`,
      name: 'publish',
    },
    {
      menuItem: `Draft (${draftCount})`,
      name: 'draft',
    },
    {
      menuItem: 'Archived',
      name: 'archive',
    },
  ];

  const handleTabChange = (e, { activeIndex }) => {
    onTabChange(activeIndex);
  };

  const onKeyPress = event => {
    event.persist();
  };

  const onSearch = (event, { value }) => {
    const trimmed = value.toLowerCase().trim();
    onSearchProduct && onSearchProduct(trimmed);
  };

  const searchDebounce = debounce(onSearch, 300);

  return (
    <S.Wrapper>
      <S.HeaderArea>
        <Tab
          menu={{ text: true }}
          panes={panes}
          className="product-list-tabs"
          onTabChange={handleTabChange}
          activeIndex={activeIndex}
        />
        {productTotal > 0 && (
          <NewSearchInput
            onChange={searchDebounce}
            onClearSearch={() => onSearch(null, { value: '' })}
            placeholder="Search by keyword or name"
            onKeyPress={onKeyPress}
          />
        )}
        <S.TabRight>
          <AddNewForm
            text="Create New Sequence"
            heightButton={36}
            widthButton={188}
            icon={<PlusIcon />}
            maxLength={90}
            buttonClassName="buttonClassName"
            titlePopup="Create New Sequence"
            description="Set up a Sequence that can be linked to Packages and automatically assign Autoflows, Studio Programs, Resource Collections, and Forums upon purchase."
            titleInput="Sequence Name"
            placeholderInput="Name your sequence"
            submitName="Create New"
            onAddNewProduct={onAddNewProduct}
          />
        </S.TabRight>
      </S.HeaderArea>
    </S.Wrapper>
  );
};

export default Header;
