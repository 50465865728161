import React from 'react';
import PropTypes from 'prop-types';

import DropDown, { Option } from 'shared/Dropdown/Basic';
import { SharedTooltip } from 'shared/SharedTooltip';

import * as S from './style';

function Card({ banner, actions, status, title, description, children, className = '', onImageError, ...props }) {
  const handleRenderTriggerIcon = open => {
    return <S.TriggerIcon className="trigger-icon" active={!!open} />;
  };

  return (
    <S.CardWrapper className={className || ''} {...props}>
      {actions && (
        <div className="dropdown-wrapper" data-tip data-for="more-option-tooltip">
          <SharedTooltip id="more-option-tooltip" />
          <DropDown direction="left" triggerIcon={({ open }) => handleRenderTriggerIcon(open)}>
            <div className="dropdown-area">
              {actions.map(action => (
                <Option key={`${action.key}`} onClick={action.onClick}>
                  {action.icon ? action.icon : null}
                  <span>{action.label}</span>
                </Option>
              ))}
            </div>
            <div className="dropdown-arrow" />
          </DropDown>
        </div>
      )}
      <div className="banner-container">
        <img className="banner" src={banner} alt={title} loading="lazy" onError={onImageError} />
      </div>
      <div className="content-container">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        {children}
      </div>
    </S.CardWrapper>
  );
}

Card.propTypes = {
  banner: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  onImageError: PropTypes.func,
};

export default Card;
