import React, { useState } from 'react';

import AddNewForm from '../AddNewForm';
import { ReactComponent as PlusIcon } from 'assets/icons/white_plus.svg';
import * as S from './style';

const WorkoutLabelsHeader = ({ addNewAllLabels }) => {
  const [open, setOpen] = useState(false);

  const handleOpenAddNew = () => setOpen(true);

  const handleSubmit = (params, callback = null) => {
    addNewAllLabels && addNewAllLabels(params);
    callback && callback();
  };

  return (
    <S.HeaderArea>
      <S.HeaderLeft>
        <S.HeaderTitle>Label List</S.HeaderTitle>
        <S.HeaderDescription>
          Labels help you organize workouts and help your clients search for workouts in a collection. Define labels for
          your workspace below.
        </S.HeaderDescription>
      </S.HeaderLeft>
      <S.HeaderRight>
        <S.Button
          text="Add New Label"
          icon={<PlusIcon />}
          height={40}
          width={154}
          bgColor="rgb(81, 88, 207)"
          bgHoverColor="rgb(85, 89, 255)"
          className="buttonClassName"
          onClick={handleOpenAddNew}
        />
        <AddNewForm
          open={open}
          titlePopup="Add New Label"
          submitName="Add New"
          titleInput="Label name"
          placeholderInput="Enter Label Name"
          maxLength={30}
          onSetOpen={setOpen}
          onSubmit={handleSubmit}
        />
      </S.HeaderRight>
    </S.HeaderArea>
  );
};

export default WorkoutLabelsHeader;
