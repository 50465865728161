import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #FFFFFF;
  border: 1px solid #EBEDF4;
  border-radius: 5px;
  padding: 18px 15px;
  margin-bottom: 40px;
`;

export const StatisticItemWrapper = styled.div`
  display: flex;

  :not(:last-child) {
    box-shadow: inset -1px 0px 0px #EBEDF4;
  }

  :not(:first-child) {
    padding: 0px 14px;
  }

  .statisticItem {

    &__info {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    &__name {
      font-weight: bold;
      font-size: 11px;
      line-height: 150%;
      text-transform: uppercase;
      color: #202353;
      opacity: 0.3;
    }

    &__value{
      font-weight: bold;
      font-size: 25px;
      line-height: 140%;
      color: #202353;
      display: flex;

      &--number {
        margin-right: 4px;
      }

      &--unit {
        font-weight: 600;
        font-size: 12px;
        margin-top: -4px;
      }
    }
  } 
`