import styled, { css } from 'styled-components';
import S3Images from 'shared/S3Images';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
`;

export const Wrapper = styled.div`
  border-radius: 5px;
  background-color: #f3f3f5;
  padding: 13.5px 14px;
  display: flex;
  gap: 13px;
  border: 1px solid transparent;
  position: relative;
  .custom-dropdown-actions {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 14px;
    right: 4px;
    .drop-down-trigger {
      width: 24px;
      height: 24px;
      border-radius: 5px;
      .dot {
        width: 4px;
        height: 4px;
        background-color: #7b7e91;
        &::before {
          width: 4px;
          height: 4px;
          left: -6px;
          background-color: #7b7e91;
        }
        &::after {
          width: 4px;
          height: 4px;
          left: 6px;
          background-color: #7b7e91;
        }
      }
      .__react_component_tooltip {
        padding: 8px 16px;
        border-radius: 4px;
        width: 107px;
        height: 34px;
      }
      .__react_component_tooltip.place-top {
        margin-top: -14px;
      }
      .__react_component_tooltip.app-tooltip {
        font-size: 12px;
        line-height: 18px;
      }
      .__react_component_tooltip.app-tooltip.place-top:after,
      .__react_component_tooltip.app-tooltip-white.place-top:after {
        bottom: -8px;
      }
      &:hover {
        background: #f0f1ff;
        .dot {
          background: #5158cf;
          &::before {
            background: #5158cf;
          }
          &::after {
            background: #5158cf;
          }
        }
      }
    }
    &.open {
      visibility: visible;
      opacity: 1;
      .drop-down-trigger {
        background: #f0f1ff;
        .dot {
          background: #5158cf;
          &::before {
            background: #5158cf;
          }
          &::after {
            background: #5158cf;
          }
        }
      }
    }
    .evf-dropdown__menu {
      padding: 5px 0;
      background: #2d2e2d;
      .evf-dropdown__option {
        ${baseText}
        font-size: 13px;
        line-height: 20px;
        color: #fff;
        height: 36px;
        .icon {
          display: flex;
          padding-right: 12px;
        }
      }
    }
  }

  &:hover {
    border: 1px solid #5158cf;
    .custom-dropdown-actions {
      visibility: visible;
      opacity: 1;
    }
  }
  ${({ active }) =>
    active &&
    css`
      border: 1px solid #5158cf;
    `}
`;

export const Thumbnail = styled(S3Images)`
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div`
  ${baseText}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 15px;
`;

export const Date = styled.div`
  ${baseText}
  font-size: 11px;
  line-height: 17px;
  color: #7b7e91;
`;
