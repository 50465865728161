import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { MAX_FILE_SIZE, CONVERSION, FILE_ERRORS } from 'constants/commonData';
import * as S from './style';
import { SelectFile } from 'shared/FormControl';
import {
  createObjectURL,
  revokeObjectURL,
  displayFileSize,
  mediaLog,
  getPresignedUploadUrlByParams,
  getPresignedUploadUrl,
  convertS3UrlToCloudFrontUrl,
} from 'utils/commonFunction';
import { validateFiles } from 'utils/validations';
import { axiosInstance } from 'configs/request';

const CancelToken = axios.CancelToken;

const DEFAULT_STATE = {
  src: '',
  isLocalUrl: false,
  file: null,
  name: '',
  bucketData: null,
};

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.uploadTask = null;
    this.maxSize = (props.maxSize || 10) * CONVERSION.MB_TO_BYTE;
    this.validateExtentions = ['png', 'jpg', 'jpeg'];
    this.progressBar = React.createRef();
  }

  componentDidMount() {
    const { src, name } = this.props.data;
    this._isMounted = true;

    if (src) {
      this.setState({ src, name });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePresignedUploadUrl = async file => {
    if (this.props.isEmailBanner) {
      const { uploadUrl, configs } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-web-asset', file);
      if (!uploadUrl || !configs) {
        return;
      }

      this.setState({
        uploadConfig: {
          method: 'PUT',
          url: uploadUrl,
          headers: { 'Content-Type': file.type },
          data: file,
        },
        bucketData: configs,
      });
    } else {
      const { uploadUrl, configs } = await getPresignedUploadUrlByParams(file, {
        method: 'POST',
        url: '/api/file/gen-presigned-urls-custom-brand',
        data: {
          fileNames: [file.name],
        },
      });
      if (!uploadUrl || !configs) {
        return;
      }

      this.setState({
        uploadConfig: {
          method: 'PUT',
          url: uploadUrl,
          headers: { 'Content-Type': file.type },
          data: file,
        },
        bucketData: configs,
      });
    }
  };

  updateFileData = file => {
    const { uploadConfig } = this.state;
    const { onUploadSuccess, onUploading } = this.props;
    const { name, type } = file;
    this.setState({ name, file, isLocalUrl: true, src: createObjectURL(file) }, () => {
      // Uploading
      onUploading && onUploading(true);

      axiosInstance({
        ...uploadConfig,
        onUploadProgress: progressData => {
          const { loaded, total } = progressData;
          const progress = Math.min(Math.floor((loaded / total) * 100), 99);

          if (this.progressBar.current) {
            this.progressBar.current.style.width = `${progress}%`;
          }
        },
        cancelToken: new CancelToken(
          function executor(c) {
            this.uploadTask = c;
          }.bind(this),
        ),
      })
        .then(response => {
          const { source } = this.props;
          const { bucketData } = this.state;
          const fileExtension = type.split('/')[1];
          mediaLog({
            status: 2,
            name: name,
            fileType: fileExtension,
            description: `Upload success file via ${source}`,
          });

          this.setState({ file: null }, () => {
            const { src } = this.state;
            onUploadSuccess && onUploadSuccess({ name, bucketData, src });
            this.uploadTask = null;
            // Uploading
            onUploading && onUploading(false);
          });
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            if (this._isMounted) {
              this.setState({ ...DEFAULT_STATE });
              this.uploadTask = null;
            }
          } else {
            this.uploadTask = null;
          }
          // Uploading
          onUploading && onUploading(false);
        });
    });
  };

  onSelectFile = async file => {
    const { verifyResolution, source } = this.props;
    const minimumWidth = _.get(verifyResolution, 'width');
    const minimumHeight = _.get(verifyResolution, 'height');

    const { size, name, type } = file;
    mediaLog({
      status: 1,
      name,
      fileSize: size,
      fileType: type,
      description: `Send a file via ${source}`,
    });

    await this.handlePresignedUploadUrl(file);

    if (minimumWidth || minimumHeight) {
      const url = createObjectURL(file);
      const img = new Image();
      // let size = Number((file.size / 1024 / 1024).toFixed(2));

      img.onload = () => {
        const { width, height } = img;
        let invalid = false;
        if ((minimumWidth && width < minimumWidth) || (minimumHeight && height < minimumHeight)) {
          invalid = true;
        }

        revokeObjectURL(img.src);

        if (!invalid) {
          this.updateFileData(file);
        } else {
          toast.error(verifyResolution.message);
        }
      };
      img.src = url;
    } else {
      this.updateFileData(file);
    }
  };

  onDropFile = files => {
    if (files.length) {
      const { overSizeMessage = '' } = this.props;
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: this.validateExtentions,
        maxSize: this.maxSize,
      });

      if (!error) {
        this.onSelectFile(file);
      } else {
        let message = '';

        if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = `Please use supported file types (${this.validateExtentions.join(', ')})`;
        } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = overSizeMessage ? overSizeMessage : `File too large. Please upload a smaller file`;
        }

        toast.error(message);
      }
    }
  };

  onRemove = () => {
    if (typeof this.uploadTask === 'function') {
      this.uploadTask();
    } else {
      const { file, isLocalUrl, src } = this.state;

      if (isLocalUrl) {
        revokeObjectURL(src);
      }

      this.setState({ ...DEFAULT_STATE }, () => {
        if (!file) {
          this.props.onRemove();
        }
      });
    }
  };

  onCancelUpload = () => {
    if (typeof this.uploadTask === 'function') {
      this.uploadTask();
    }

    const { file, isLocalUrl, src } = this.state;
    if (isLocalUrl) {
      revokeObjectURL(src);
    }

    this.setState({ ...DEFAULT_STATE }, () => {
      if (!file) {
        this.props.onCancelUpload();
      }
    });
  };

  getCloudUrl = url => {
    const { cloudfrontList } = this.props;
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);
  };

  render() {
    const { src, file, name } = this.state;
    const { onCancelUpload, overSizeMessage = '' } = this.props;

    if (!src) {
      return (
        <Dropzone onDrop={this.onDropFile} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <S.Wrapper {...getRootProps()} isDragging={isDragActive}>
              <S.Placeholder>
                <S.Icon />
                <S.Text>
                  Drag and drop your file here or&nbsp;
                  <SelectFile
                    trigger={<span>Choose file</span>}
                    onSelect={this.onSelectFile}
                    accept="image/png, image/jpeg, image/jpg"
                    validateExtentions={this.validateExtentions}
                    maxSize={this.props.maxSize || MAX_FILE_SIZE}
                    fileSizeLimitMessage={overSizeMessage}
                  />
                </S.Text>
              </S.Placeholder>
              <input {...getInputProps({ onClick: event => event.preventDefault() })} className="dropzone__input" />
            </S.Wrapper>
          )}
        </Dropzone>
      );
    }

    return (
      <S.Wrapper>
        <S.FileInformation>
          <S.Thumbnail thumbnail={this.getCloudUrl(src)} />
          <S.UploadInformation>
            <S.FileNameContainer>
              <S.NameLeftSide>{name}</S.NameLeftSide>
              <S.NameRightSide>
                {file ? <S.FileSize>{displayFileSize(file.size || 0)}</S.FileSize> : null}
                <S.RemoveIcon onClick={onCancelUpload ? this.onCancelUpload : this.onRemove} />
              </S.NameRightSide>
            </S.FileNameContainer>
            {file ? (
              <S.UploadProgress>
                <S.ProgressBar ref={this.progressBar} />
              </S.UploadProgress>
            ) : null}
          </S.UploadInformation>
        </S.FileInformation>
      </S.Wrapper>
    );
  }
}

export default Upload;
