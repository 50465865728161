// Lib
import React, { useEffect, useState, useMemo } from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import isNumber from 'lodash/isNumber';
import { toast } from 'react-toastify';
import { Prompt } from 'react-router';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import sumBy from 'lodash/sumBy';
import round from 'lodash/round';

// Components
import DescriptionEditable from './DescriptionEditable';
import DietaryInfo from './DietaryInfo';
import Header from './Header';
import MealDetail from './MealDetail';
import NutritionOverview from './NutritionOverview';
import Thumbnail from './Thumbnail';
import TimeInfo from './TimeInfo';
import RecipeName from './RecipeName';
import DishType from './Parts/DishType';
import CategoryList from './Parts/CategoryList';
import LoadingIndicator from 'shared/LoadingIndicator';

// Constants
import {
  CATEGORY_RECIPE,
  ENUM_DISH_TYPE,
  ENUM_TIME,
  ENUM_TIME_PREP,
  LIMIT_CHARACTER_NAME,
  LIST_DISH_TYPE,
  MACRO_NUTRIENTS,
} from './constants';
import { RECIPE_STATUS } from 'components/Recipes/constants';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';

// Utils
import { isTeamAdmin } from 'utils/commonFunction';

// helper
import {
  convertTimeToString,
  convertDataMacroNutrients,
  validRecipe,
  formatValueIngredient,
  getFirstItemUnit,
  getUniqueUnitsFromCategoryList,
  getValueCaloriesFromMacro,
  validValueStringIngredient,
  convertStringToNumber,
  convertMacroNutrients,
} from './helper';
import { convertUnitForAutoCalculated } from 'components/IngredientLibrary/helpers';

import * as S from './style';

export const dataEdit = [
  'categories',
  'name',
  'owner',
  'share',
  'description',
  'prep_time',
  'prep_time_text',
  'cooking_time',
  'cooking_time_text',
  'number_of_serving',
  'cover_image',
  'dietaries',
  'ingredients',
  'intro_instruction',
  'prepare_instructions',
  'cooking_instructions',
  'macro_nutrients',
  'other_nutrients',
  'is_edit_mode',
  'status',
  'dish_type',
  'ingredient_text',
  'ingredient_note',
  'is_auto_calculated',
  'auto_calculated_nutrition',
];

const Recipes = props => {
  const {
    isEditMode,
    history,
    getDetailRecipe,
    detailRecipe,
    editRecipe,
    updateDataDetailRecipe,
    resetDetailRecipe,
    resetBackgroundLibrary,
    toggleConfirmModal,
    addRecipeLibraries,
    resetEditMode,
    cancelEditModeRecipe,
    push,
    enableEditModeRecipe,
    isChangeRecipeDefaultImage,
    setUploadLoading,
    isLoading,
    handleSubmitted,
    updateUnitType,
    toggleModal,
    currentUser,
    getListOtherNutrient,
    listOtherNutrients,
    listDietary = [],
    cloudfrontList,
    loadingListDietary,
    getListDietary = () => {},
    updateLoadingVideoInstruction = () => {},
    changeAutoCalculated = () => {},
    listUnitIngredient = {},
    updateIngredientData = () => {},
    updateAutoCalculatedNutrition = () => {},
  } = props;

  const {
    cover_image = '',
    name = '',
    description = '',
    _id = '',
    prep_time = 0,
    cooking_time = 0,
    number_of_serving = 1,
    owner,
    author,
    categories = [],
    dietaries = [],
    macro_nutrients = [],
    other_nutrients = [],
    intro_instruction = {},
    prepare_instructions = [],
    cooking_instructions = [],
    ingredients = [],
    error,
    prep_time_text,
    cooking_time_text,
    isChange = false,
    status,
    isSubmitted = false,
    isSubmittedPublish = false,
    unit: unitType = {},
    share,
    dish_type: dishType = ENUM_DISH_TYPE.MAIN_DISH,
    ingredient_note: ingredientNote = '',
    ingredient_text: ingredientText = '',
    loadingVideoInstruction = false,
    is_auto_calculated: autoCalculated = false,
    auto_calculated_nutrition: autoCalculatedNutrition = {},
    is_edit_mode = false,
  } = detailRecipe;

  const { _id: authorId = '' } = author || {};
  const { _id: ownerId = '' } = owner || {};

  const [localUrl, setLocalUrl] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const resultCategory = CATEGORY_RECIPE.filter(item => categories.includes(get(item, 'value', '')));

  const resultDietary = listDietary.filter(item => dietaries.includes(get(item, 'value', '')));

  const recipeId = get(props, 'match.params.recipeId');
  const params = new URLSearchParams(get(window, 'location.search'));
  const isEdit = params.get('edit');
  const isDuplicate = params.get('duplicate');

  const disabledAction = useMemo(() => {
    const { _id: userId } = currentUser || {};

    const isCreator = userId === authorId;
    const isOwnerOrAdmin = isTeamAdmin(currentUser);
    const isNoOwner = share === TEAM_SHARE_NOOWNER;

    return !(isCreator || isOwnerOrAdmin || isNoOwner) && _id;
  }, [currentUser, detailRecipe]);

  useEffect(() => {
    typeof getListOtherNutrient === 'function' && getListOtherNutrient();
    return () => {
      resetDetailRecipe && resetDetailRecipe();
      resetBackgroundLibrary && resetBackgroundLibrary();
      setUploadLoading && setUploadLoading(false);
    };
  }, []);

  useEffect(() => {
    if (recipeId && recipeId !== 'create') {
      getDetailRecipe && getDetailRecipe(recipeId);
    }
  }, [recipeId]);

  useEffect(() => {
    if (!isEditMode) {
      typeof resetEditMode === 'function' && resetEditMode(true);
    }
  }, []);

  useEffect(() => {
    if (recipeId && recipeId === 'create' && isEmpty(detailRecipe)) {
      push('/home/recipes');
    }
  }, []);

  useEffect(() => {
    if ((isEdit || isDuplicate) && !isEmpty(detailRecipe) && !isEditMode) {
      typeof enableEditModeRecipe === 'function' && enableEditModeRecipe(_id, status === RECIPE_STATUS.PUBLISH);

      history.replace({ pathname: `/home/recipes/${recipeId}` });
    }
  }, [detailRecipe]);

  useEffect(() => {
    if (autoCalculated) {
      const dataIngredients = ingredients.map(item => {
        const { categories = [], unit = {} } = item || {};
        const allowUnit = getUniqueUnitsFromCategoryList(categories, 'allow_units');
        const allowUnitConvert = (allowUnit || []).map(item => (item || {}).unit_id);
        const listUnitAutoCalculated = convertUnitForAutoCalculated(listUnitIngredient, allowUnitConvert);
        const idUnit = (unit || {})._id;
        const checkExistUnit = allowUnitConvert.includes(idUnit);
        if (checkExistUnit) {
          return { ...item, listUnitAutoCalculated };
        }
        const firstItemUnit = getFirstItemUnit(listUnitAutoCalculated, get(allowUnit, '[0].unit_id', ''));
        return { ...item, listUnitAutoCalculated, value_as_text: '', unit: firstItemUnit };
      });
      updateIngredientData(dataIngredients);
    }
  }, [autoCalculated, is_edit_mode]);

  // Utility function to calculate ratio
  const calculateRatio = (value, ratioConvert) => {
    if (!ratioConvert) return 0;
    return (value / get(ratioConvert, 'value', 1)) * get(ratioConvert, 'ratio_value', 1);
  };

  // Utility function to calculate nutrients with ratio
  const calculateNutrients = (nutrients, ratio) => {
    return nutrients.map(nutrient => {
      if ((nutrient || {}).value >= 0) {
        return { ...nutrient, value: ((nutrient || {}).value || 0) * ratio };
      }
      return nutrient;
    });
  };

  // Utility function to merge nutrients by type
  const mergeNutrients = (data, key) => {
    return map(groupBy(flatMap(data, key), 'type'), items => {
      const totalValue = sumBy(items, 'value');
      return { ...items[0], value: totalValue };
    });
  };

  // Main function to process nutrients
  const processNutrients = (ingredients = [], numberOfServing = 1) => {
    const ingredientsFilter =
      (ingredients || []).filter(item => {
        const value = (item || {}).value_as_text;
        return value && validValueStringIngredient(value);
      }) || [];

    if ((ingredientsFilter || []).length === 0) {
      updateAutoCalculatedNutrition({
        macro_nutrients: [
          { type: MACRO_NUTRIENTS.PROTEIN },
          { type: MACRO_NUTRIENTS.CARBS },
          { type: MACRO_NUTRIENTS.FAT },
          { type: MACRO_NUTRIENTS.CALORIES },
        ],
      });
      return;
    }

    if ((ingredientsFilter || []).length === 0) return;

    const dataNutrients = (ingredientsFilter || []).map(item => {
      const { unit = {}, value_as_text = '', serving_size = {} } = item || {};

      const ratio_converts = getUniqueUnitsFromCategoryList(get(item, 'categories', []) || [], 'ratio_converts');

      // data input
      const unitIdInput = (unit || {})._id;
      const valueInput = convertStringToNumber(value_as_text);
      const unitByRatioInput = (ratio_converts || []).find(ratio => (ratio || {}).unit_id === unitIdInput);
      const ratioInput = calculateRatio(valueInput, unitByRatioInput);

      // data init
      const { unit_id: unitIdInit, value: valueInit } = serving_size || {};
      const unitByRatioInit = (ratio_converts || []).find(ratio => (ratio || {}).unit_id === unitIdInit);
      const ratioInit = calculateRatio(valueInit, unitByRatioInit);

      const ratio = ratioInput / ratioInit;

      const macroNutrientDefault = !isEmpty(get(item, 'macro_nutrients'))
        ? get(item, 'macro_nutrients')
        : [
            { type: MACRO_NUTRIENTS.PROTEIN },
            { type: MACRO_NUTRIENTS.CARBS },
            { type: MACRO_NUTRIENTS.FAT },
            { type: MACRO_NUTRIENTS.CALORIES },
          ];

      const macroNutrients = calculateNutrients(macroNutrientDefault, ratio);
      const otherNutrients = calculateNutrients(get(item, 'other_nutrients', []), ratio);

      return { macro_nutrients: getValueCaloriesFromMacro(macroNutrients), other_nutrients: otherNutrients };
    });

    const dataMacroNutrients = mergeNutrients(dataNutrients, 'macro_nutrients').map(item => ({
      ...item,
      value: round((item || {}).value / numberOfServing, 2),
    }));

    const dataOtherNutrients = mergeNutrients(dataNutrients, 'other_nutrients').map(item => ({
      ...item,
      value: round((item || {}).value / numberOfServing, 2),
    }));

    return {
      macro_nutrients: convertMacroNutrients(dataMacroNutrients),
      other_nutrients: convertMacroNutrients(dataOtherNutrients),
    };
  };

  // Calculated nutrient from ingredient
  useEffect(() => {
    if (autoCalculated) {
      const autoCalculatedNutrition = processNutrients(ingredients, number_of_serving);
      if (autoCalculatedNutrition) {
        updateAutoCalculatedNutrition(autoCalculatedNutrition);
      }
    }
  }, [ingredients, autoCalculated, number_of_serving]);

  const handleEditRecipe = (callbackSuccess, allowRedirect = true) => {
    const isValid = validRecipe(detailRecipe);
    if (isValid) {
      handleSubmitted({
        isSubmitted: true,
        isSubmittedPublish: false,
        ingredients: ingredients.map(item => omit(item, ['isAddNew'])),
      });
      return;
    }

    handleSubmitted({
      isSubmitted: false,
      isSubmittedPublish: false,
      ingredients: ingredients.map(item => omit(item, ['isAddNew'])),
    });

    if (isEditMode) {
      const idOwner = isObject(author) ? authorId : isObject(owner) ? ownerId : owner;
      const prepareInstructions = get(detailRecipe, 'prepare_instructions', []);
      const prepareInstructionsConvert = prepareInstructions.map(item => omit(item, ['_id']));
      const prepareInstructionsFilter = prepareInstructionsConvert.filter(
        item => get(item, 'content', '') || get(item, 'attachments.length', 0) > 0,
      );

      const cookingInstructions = get(detailRecipe, 'cooking_instructions', []);

      const cookingInstructionsConvert = cookingInstructions.map(item => omit(item, ['_id']));
      const cookingInstructionsFilter = cookingInstructionsConvert.filter(
        item => get(item, 'content', '') || get(item, 'attachments.length', 0) > 0,
      );

      const listIngredient = get(detailRecipe, 'ingredients', []).map(item => {
        return {
          ingredient: get(item, 'ingredient', ''),
          value_as_text: formatValueIngredient(get(item, 'value_as_text')),
          unit: get(item, 'unit._id'),
        };
      });

      const data = {
        ...detailRecipe,
        owner: get(detailRecipe, 'share') === TEAM_SHARE_NOOWNER ? null : idOwner ? idOwner : undefined,
        prepare_instructions: prepareInstructionsFilter,
        cooking_instructions: cookingInstructionsFilter,
        ingredients: listIngredient,
        other_nutrients: (detailRecipe.other_nutrients || []).map(item => pick(item, ['type', 'value'])),
        macro_nutrients: convertDataMacroNutrients(detailRecipe.macro_nutrients),
        intro_instruction: !!get(detailRecipe, 'intro_instruction.url', '')
          ? get(detailRecipe, 'intro_instruction')
          : null,
      };

      if (!isEmpty(data && data.error)) return;

      const updateRecipeCallback = () => {
        typeof callbackSuccess === 'function' && callbackSuccess();
        toast('Recipe has been saved.');
      };

      // Update type unit
      updateUnitType &&
        updateUnitType({
          unit_type: get(unitType, 'key', ''),
        });

      if (recipeId && recipeId !== 'create') {
        editRecipe && editRecipe(recipeId, pick(data, dataEdit), updateRecipeCallback);
      } else {
        addRecipeLibraries &&
          addRecipeLibraries(pick(data, dataEdit)).then(res => {
            const idRecipe = get(res, 'data.data._id', '');
            if (allowRedirect) {
              push(`/home/recipes/${idRecipe}`);
            }
            updateRecipeCallback();
          });
      }
    }
  };

  const handleChangeName = name => {
    const updatedError = !name ? { ...error, name: true } : omit(error, ['name']);

    updateDataDetailRecipe({
      name: name && name.length <= LIMIT_CHARACTER_NAME ? name : name.slice(0, LIMIT_CHARACTER_NAME),
      error: updatedError,
    });
  };

  const handleChangeDesc = description => {
    updateDataDetailRecipe({ description });
  };

  const handleChooseCategory = data => {
    const categories = data.map(item => item.value);
    updateDataDetailRecipe({ categories });
  };

  const handleChooseDietary = data => {
    const dietaries = data.map(item => item.value);
    updateDataDetailRecipe({ dietaries });
  };

  const handleChangeNumberOfServings = e => {
    updateDataDetailRecipe({ number_of_serving: isNumber(e.floatValue) ? e.floatValue : '' });
  };

  const handlePlusSub = value => {
    if (value) {
      updateDataDetailRecipe({
        number_of_serving: value,
        error: value >= 1 ? omit(error, ['number_of_serving']) : error,
      });
    }
  };

  const onChangeLink = data => {
    const { url, thumbnail_url, name, type } = data || {};
    const { url: urlStore, thumbnail_url: thumbnailStore, name: nameStore, attachment_type: typeStore } =
      intro_instruction || {};

    const isChange =
      !isEqual(urlStore, url) ||
      !isEqual(thumbnailStore, thumbnail_url) ||
      !isEqual(nameStore, name) ||
      !isEqual(typeStore, type);

    updateDataDetailRecipe(
      {
        intro_instruction: { url, thumbnail_url, name, attachment_type: type },
      },
      isChange,
    );
  };

  const convertTime = (value, time, prep_time) => {
    let result;
    const hour = Math.floor(prep_time / 60) || 0;
    const minute = prep_time % 60;
    if (time === 'hour') {
      result = value * 60 + minute;
    }
    if (time === 'minute') {
      result = hour * 60 + value;
    }
    return +result;
  };

  const handleChangeTime = (e, time, type) => {
    let value = get(e, 'target.value');
    if (value) {
      value = Number(get(e, 'target.value'));
    }

    if ((time === ENUM_TIME.HOUR && value > 99) || (time === ENUM_TIME.MINUTE && value > 59)) {
      return;
    }

    if (type === ENUM_TIME_PREP.PREP_TIME) {
      const prepTime = convertTime(value, time, prep_time);
      const prepTimeText = convertTimeToString(prep_time_text, value, time);
      updateDataDetailRecipe({ prep_time: prepTime, prep_time_text: prepTimeText });
    }

    if (type === ENUM_TIME_PREP.COOKING_TIME) {
      const cookingTime = convertTime(value, time, cooking_time);
      const cookingTimeText = convertTimeToString(cooking_time_text, value, time);
      updateDataDetailRecipe({ cooking_time: cookingTime, cooking_time_text: cookingTimeText });
    }
  };

  const handleUpdateStepInstruction = (data, tab) => {
    if (get(tab, 'key', '') === 'preparation') {
      updateDataDetailRecipe({ prepare_instructions: data });
    } else {
      updateDataDetailRecipe({ cooking_instructions: data });
    }
  };

  const handleSelect = item => {
    updateDataDetailRecipe({ cover_image: item.image });
  };

  const handleBlurNumberOfServing = () => {
    updateDataDetailRecipe({
      error: number_of_serving <= 0 ? { ...error, number_of_serving: true } : omit(error, ['number_of_serving']),
    });
  };

  const handleCancelEdit = pathname => {
    typeof cancelEditModeRecipe === 'function' &&
      cancelEditModeRecipe(_id, status === RECIPE_STATUS.PUBLISH, () => {
        push(pathname);
      });
  };

  const handleSaveBeforeExit = async pathname => {
    handleEditRecipe(() => {
      push(pathname);
    }, false);
  };

  const handleCloseAnyway = pathname => {
    updateDataDetailRecipe && updateDataDetailRecipe({ isChange: false });
    typeof resetEditMode === 'function' && resetEditMode(false);

    setTimeout(() => {
      push(pathname);
    }, 300);
  };

  const handleBlockedNavigation = nextLocation => {
    const { pathname = '' } = nextLocation || {};

    if (isEditMode && status === RECIPE_STATUS.PUBLISH) {
      toggleConfirmModal &&
        toggleConfirmModal(
          true,
          <S.CustomConfirmModal
            title="Cancel Edits Made to Recipe?"
            content="Would you like to cancel editing the recipe? Any changes you have made will not be saved."
            onConfirm={() => handleCancelEdit(pathname)}
            confirmButtonTitle="Cancel Edits"
            headerIcon={`${CDN_URL}/images/alert_warning.svg`}
            cancelButtonTitle="Continue Editing"
            className="cancel-edit-modal"
            noBorder
            hasCloseIcon
            isPressEsc
            onClose={() => toggleConfirmModal(false)}
            isCloseOnDimmer={false}
          />,
        );
      return false;
    }
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          title="Save before exiting?"
          content="You have made changes that have not been saved yet. Would you like to save changes before leaving?"
          onConfirm={() => handleSaveBeforeExit(pathname)}
          confirmButtonTitle="Save"
          headerIcon={`${CDN_URL}/images/warning_purple_Icon.svg`}
          cancelButtonTitle="Exit without saving"
          className="save-before-exit-modal"
          onDeny={() => handleCloseAnyway(pathname)}
          noBorder
          hasCloseIcon
          isPressEsc
          onClose={() => toggleConfirmModal(false)}
          isCloseOnDimmer={false}
        />,
      );
    return false;
  };

  const renderDishType = () => {
    const dataDishType = LIST_DISH_TYPE.find(item => get(item, 'key', '') === dishType);
    const { label, key, icon } = dataDishType || LIST_DISH_TYPE[0];

    // TODO: Remove for demo (Update later)
    // if (isEmpty(dataDishType)) return;

    return (
      <S.CategoryItem dishType={key}>
        {icon}
        {label}
      </S.CategoryItem>
    );
  };

  const handleSelectedDishType = (dish_type = '') => {
    updateDataDetailRecipe({
      dish_type,
    });
  };

  if (isEmpty(detailRecipe))
    return (
      <S.LoadingContainer>
        <LoadingIndicator className="recipe-loading-indicator" />
      </S.LoadingContainer>
    );

  return (
    <S.RecipeDetailWrapper>
      <Prompt when={isChange} message={handleBlockedNavigation} />
      <S.HeaderWrapper>
        <Header
          isEditMode={isEditMode}
          history={history}
          handleSaveRecipe={handleEditRecipe}
          handleSubmitted={handleSubmitted}
          updateUnitType={updateUnitType}
          disabledAction={disabledAction}
        />
      </S.HeaderWrapper>
      <S.ContentWrapper>
        <S.Content isEditMode={isEditMode && !disabledAction}>
          <S.Overview isEditMode={isEditMode && !disabledAction}>
            <S.Thumbnail>
              <Thumbnail
                src={cover_image}
                localUrl={localUrl}
                setLocalUrl={setLocalUrl}
                isLoading={loadingUpload}
                setIsLoading={setLoadingUpload}
                onSelect={handleSelect}
                isEditAble={isEditMode && !disabledAction}
              />
              {!isChangeRecipeDefaultImage && isEditMode && (
                <S.Hint>Default image in use. Click to change image</S.Hint>
              )}
            </S.Thumbnail>
            <S.Information>
              <RecipeName
                value={name}
                isEditMode={isEditMode && !disabledAction}
                onChange={handleChangeName}
                maxLength={LIMIT_CHARACTER_NAME}
                isSubmitted={isSubmitted}
                isSubmittedPublish={isSubmittedPublish}
              />
              {(!isEditMode || !!disabledAction) && (
                <S.ListCategory>
                  {renderDishType()}
                  {resultCategory.map(item => {
                    const { label, value } = item;
                    return <S.CategoryItem key={value}>{label}</S.CategoryItem>;
                  })}
                </S.ListCategory>
              )}
              <DescriptionEditable
                description={description}
                isEditMode={isEditMode && !disabledAction}
                onChange={debounce(handleChangeDesc, 300)}
                toggleModal={toggleModal}
              />
              <TimeInfo
                numberOfServing={number_of_serving}
                isEditMode={isEditMode && !disabledAction}
                onChangeNumberOfServings={handleChangeNumberOfServings}
                onChangeTime={handleChangeTime}
                handlePlusSub={handlePlusSub}
                handleBlurNumberOfServing={handleBlurNumberOfServing}
                error={error}
                CookingTime={cooking_time}
                PrepTime={prep_time}
                PrepTimeText={prep_time_text}
                CookingTimeText={cooking_time_text}
                isSubmittedPublish={isSubmittedPublish}
              />
            </S.Information>
          </S.Overview>
          {isEditMode && !disabledAction && (
            <S.DishType>
              <DishType onSelectedDishType={handleSelectedDishType} value={dishType} />
            </S.DishType>
          )}
          {isEditMode && !disabledAction && (
            <S.SelectCategory>
              <CategoryList
                value={resultCategory}
                handleChoose={handleChooseCategory}
                showError={resultCategory && resultCategory.length === 0}
                isSubmittedPublish={isSubmittedPublish}
              />
            </S.SelectCategory>
          )}
          <S.DietaryInfo isEdit={isEditMode}>
            <DietaryInfo
              value={resultDietary}
              isEditMode={isEditMode && !disabledAction}
              dietaryRecipe={listDietary}
              handleChoose={handleChooseDietary}
              cloudfrontList={cloudfrontList}
              loading={loadingListDietary}
              getListDietary={getListDietary}
            />
          </S.DietaryInfo>
          {(!isEditMode || !!disabledAction) && (
            <S.NutritionOverview>
              <NutritionOverview
                MacroNutrients={autoCalculated ? get(autoCalculatedNutrition, 'macro_nutrients', []) : macro_nutrients}
              />
            </S.NutritionOverview>
          )}
          <S.MealDetail isEdit={isEditMode && !disabledAction}>
            <MealDetail
              isEditMode={isEditMode && !disabledAction}
              MacroNutrients={macro_nutrients}
              OtherNutrients={other_nutrients}
              updateDataDetailRecipe={updateDataDetailRecipe}
              IntroInstruction={intro_instruction}
              onChangeLink={onChangeLink}
              PrepareInstructions={prepare_instructions}
              CookingInstructions={cooking_instructions}
              handleUpdateStepInstruction={handleUpdateStepInstruction}
              listIngredient={ingredients}
              setUploadLoading={setUploadLoading}
              isLoading={isLoading}
              isSubmitted={isSubmitted}
              isSubmittedPublish={isSubmittedPublish}
              unitType={unitType}
              listOtherNutrients={listOtherNutrients}
              ingredientNote={ingredientNote}
              ingredientText={ingredientText}
              error={error}
              updateLoadingVideoInstruction={updateLoadingVideoInstruction}
              loadingVideoInstruction={loadingVideoInstruction}
              changeAutoCalculated={changeAutoCalculated}
              autoCalculated={autoCalculated}
              toggleConfirmModal={toggleConfirmModal}
              autoCalculatedNutrition={autoCalculatedNutrition}
            />
          </S.MealDetail>
        </S.Content>
      </S.ContentWrapper>
    </S.RecipeDetailWrapper>
  );
};

export default Recipes;
