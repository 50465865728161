import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';

import { convertS3UrlToCloudFrontUrl, getUserShortName, ownerOfForum } from 'utils/commonFunction';
import Interactions from '../../Post/components/Interactions';
import CommentInput from '../../Post/components/Comments/CommentInput';
import CommentReplyItem from '../../Post/components/Comments/CommentReplyItem';
import ShowError from '../../../../ShowError';
import Button from 'shared/FormControl/Button';
import { TYPES as COMMENT_TYPES } from 'shared/ChatInput';
import { MEDIA_FILE_REJECTED_CONTENT, TOPICS } from 'constants/commonData';
import { TYPE_ERROR } from 'components/CommunityForum/constants.js';
import { getMediaType, pluralize } from 'utils/commonFunction';
import { handleScrollToReply } from 'helpers/replyComment';
import { ReactComponent as PolygonIcon } from 'assets/icons/polygon_grey.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit_post.svg';
import { ReactComponent as RemovePostIcon } from 'assets/icons/remove_post.svg';
import { ReactComponent as PinnedPostIcon } from 'assets/icons/pin_post_op.svg';
import TrashIcon from 'assets/icons/red_trash_circle.svg';
import WarningIcon from 'assets/icons/warning_red_light.svg';
import MentionEditor from 'components/CommunityForum/components/MentionEditor';
import PopUpForm from '../../CreatePostForm/components/PopUpForm';
import { getReplyMessage } from 'components/CommunityForum/helper';
import { PreviewVideoLink, PreviewArticleLink } from 'shared/PreviewLink';
import { SharedTooltip } from 'shared/SharedTooltip';
import PollPost from '../../PollPost';

import * as S from './style';

const likeSummary = (userId, all) => {
  if (!all.length) {
    return '';
  }

  const others = all.slice();
  let text = '';
  const currentUser = _.remove(others, item => item === userId);

  if (!others.length) {
    return 'you';
  }
  if (!currentUser.length) {
    text += `${others.length} ${pluralize('other', others.length)}`;
  } else {
    text += `you and ${others.length} ${pluralize('other', others.length)}`;
  }

  return text;
};

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
      isShowMore: false,
      liker: [props.postData.liker],
      likerInfo: [props.postData.liker_info],
      uploadConfigs: null,
      isSeeMore: 0,
      initHeightCommentList: 0,
      replyMessage: {},
      loading: false,
      listMediaUpload: [],
      openDropdown: false,
      pollData: null,
    };

    this.inputRef = React.createRef();
    this.bodyRef = React.createRef();
    this.captionRef = React.createRef();
    this.noScrollRef = React.createRef();

    this.toggleReadMore = () => {
      this.setState({ isShowMore: !this.state.isShowMore }, () => {
        if (this.noScrollRef && this.noScrollRef.current) {
          this.setState({ isSeeMore: this.noScrollRef.current.scrollHeight });
        }
      });
    };
  }

  componentDidMount() {
    const { postData } = this.props;
    this.getListComments();
    const { poll = null } = postData;
    !_.isEmpty(poll) && this.setState({ pollData: poll });
  }

  getListComments = () => {
    const { getListComments, postData } = this.props;

    getListComments(postData._id).then(response => {
      const { data = [], total_comments } = response.data;

      const dataConvert = data.map(item => {
        const latestReply = _.get(item, 'latest_reply');
        return { ...item, latestReplyList: latestReply ? [latestReply] : [], isViewMore: false };
      });

      this.setState({ list: dataConvert, total: total_comments }, () => {
        if (this.noScrollRef && this.noScrollRef.current) {
          this.setState({ initHeightCommentList: this.noScrollRef.current.scrollHeight + 15 });
        }
        setTimeout(() => {
          if (this.bodyRef && this.bodyRef.current) {
            this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
          }
        }, 500);
      });
    });
    this.setState({ liker: postData.liker, likerInfo: postData.liker_info });
    this.checkCaptionHeight();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.postData.caption !== this.props.postData.caption) {
      this.checkCaptionHeight();
    }
  }

  checkCaptionHeight = () => {
    const {
      postData: { caption },
    } = this.props;
    const textLength = caption.length;
    const currentHeight = this.captionRef.current ? this.captionRef.current.clientHeight : 0;
    const isShowMore = textLength > 333 || currentHeight > 84;
    this.setState({ isShowMore });
  };

  componentWillUnmount() {
    const { postData, updatePostsAfterViewDetail } = this.props;
    const { total } = this.state;
    updatePostsAfterViewDetail(postData._id, { total_comment: total });
  }

  addLocalCommentToList = (localComments, replyMessage) => {
    const { list, listMediaUpload } = this.state;
    const { uploadMediaBackgroundRequest } = this.props;

    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');

    const listMediaUploadConvert = listMediaUpload.concat(localComments.map(item => item._id));

    const ids = localComments.map(item => _.get(item, '_id', ''));
    if (uploadMediaBackgroundRequest) {
      uploadMediaBackgroundRequest(ids);
    }

    const listCommentConvert = list.map(item => {
      const { latestReplyList = [], _id = '', total_replies = 0, isViewMore } = item;

      if (_id === commentId) {
        return {
          ...item,
          total_replies: total_replies + 1,
          latestReplyList: latestReplyList.concat(localComments),
          isViewMore: isViewMore || false,
        };
      }
      return item;
    });

    this.setState(
      { listMediaUpload: listMediaUploadConvert, list: isReply ? listCommentConvert : list.concat(localComments) },
      () => {
        setTimeout(() => {
          if (this.bodyRef && this.bodyRef.current) {
            if (!isReply) {
              this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
            }
          }
        }, 300);
      },
    );
    if (isReply) {
      handleScrollToReply(commentId);
    }
  };

  initLocalCommentData = (comment, index, replyMessage) => {
    const { user } = this.props;
    const { file, objectURL, dimensions } = comment;
    const timestamp = new Date().getTime();
    const data = new FormData();
    data.append('attachment', file);

    return {
      uploadConfigs: this.state.uploadConfigs,
      attachment: objectURL,
      attachment_type: getMediaType(file),
      attachment_width: _.get(dimensions, 'width'),
      attachment_height: _.get(dimensions, 'height'),
      _id: `new_media__${timestamp}__${index}`,
      createdAt: timestamp,
      author: user,
      isLocalFile: true,
      content: MEDIA_FILE_REJECTED_CONTENT,
      loadingReply: true,
      replyMessage,
    };
  };

  cancelUpload = (comment, error) => {
    if (!this.cancelAddComment) {
      const { uploadMediaBackgroundSuccess } = this.props;
      const { replyMessage, _id: commentIdLocal = '' } = comment;
      const { list, listMediaUpload } = this.state;

      const isReply = !_.isEmpty(replyMessage);
      const commentId = _.get(replyMessage, 'commentId', '');

      // Cancel upload
      if (uploadMediaBackgroundSuccess && commentIdLocal) {
        uploadMediaBackgroundSuccess([commentIdLocal]);
      }

      const listMediaUploadConvert = listMediaUpload.filter(item => item !== commentIdLocal);
      this.setState({ listMediaUpload: listMediaUploadConvert });

      if (isReply) {
        const listCommentConvert = list.map(item => {
          const { _id, total_replies = 0, latestReplyList = [] } = item;

          if (commentId === _id) {
            return {
              ...item,
              total_replies: total_replies - 1,
              latestReplyList: _.filter(latestReplyList, c => c._id !== commentIdLocal),
            };
          }
          return item;
        });
        this.setState({ list: listCommentConvert });
      } else {
        this.setState({ list: _.filter(list, c => c._id !== commentIdLocal) });
      }
    }
  };

  uploadSuccess = (item, responseData) => {
    const { replyMessage } = item;
    const { list } = this.state;
    const commentId = _.get(replyMessage, 'commentId', '');
    const isReply = !_.isEmpty(replyMessage);
    const comment = isReply ? item : _.find(list, c => c._id === item._id);

    if (!comment || this.cancelAddComment) {
      return false;
    }

    const { config } = responseData;
    if (config || item) {
      const { url } = config;
      const { postData, updatePostsAfterViewDetail, addComment, uploadMediaBackgroundSuccess } = this.props;

      const { attachment_type, attachment_height, attachment_width, _id: commentLocalId = '' } = comment;
      const attachment = url.substring(0, url.indexOf('?X-Amz-Algorithm'));
      let attachmentID = attachment ? attachment.split('/') : [];

      if (uploadMediaBackgroundSuccess && _.get(item, '_id', '')) {
        uploadMediaBackgroundSuccess([item._id]);
      }

      if (attachmentID.length > 0) {
        attachmentID = attachmentID[attachmentID.length - 1];
        attachmentID = attachmentID.substring(0, attachmentID.indexOf('.'));
      }

      const body = {
        attachment: attachment,
        attachment_id: attachmentID,
        attachment_type,
        attachment_height,
        attachment_width,
        topic: isReply ? TOPICS.REPLY_COMMENT : 'forum_post',
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : postData._id,
      };

      addComment(body, commentLocalId)
        .then(response => {
          const { data, total_comment } = response.data;

          const { listMediaUpload } = this.state;

          if (isReply) {
            const listCommentConvert = list.map(itemComment => {
              if (_.get(itemComment, '_id', '') === _.get(data, 'item', '')) {
                let latestReplyList = _.get(itemComment, 'latestReplyList', []);
                const isMedia = data.content === MEDIA_FILE_REJECTED_CONTENT && data.attachment_source !== 'giphy';
                if (isMedia) {
                  latestReplyList = latestReplyList.map(item => {
                    const { _id = '' } = item;
                    return _id === commentLocalId ? { ...data } : item;
                  });
                } else {
                  latestReplyList.push(data);
                }
                return {
                  ...itemComment,
                  latestReplyList: _.isArray(latestReplyList) ? latestReplyList : [data],
                };
              }
              return itemComment;
            });
            this.setState({ list: listCommentConvert, total: total_comment });
          } else {
            const listCommentConvert = _.map(list, item => {
              const { _id = '' } = item;
              return _id === commentLocalId
                ? {
                    ...data,
                    loadingReply: false,
                  }
                : item;
            });

            this.setState({ list: listCommentConvert, total: total_comment });
          }

          const listMediaUploadConvert = listMediaUpload.filter(i => i !== item._id);
          this.setState({ listMediaUpload: listMediaUploadConvert });

          updatePostsAfterViewDetail(postData._id, { total_comment });
        })
        .catch(err => {
          this.handleErrorResponse(err);
        });
    }
  };

  handleAddComment = (comment, replyMessage, callback) => {
    const { type, data, taggedPeople, tagAll } = comment;
    const { addComment, postData, group, updatePostsAfterViewDetail } = this.props;
    const { list, loading } = this.state;

    const commentId = _.get(replyMessage, 'commentId', '');
    const isReply = !_.isEmpty(replyMessage);

    if (type === COMMENT_TYPES.TEXT && !loading) {
      this.setState({ loading: true });
      let newList = list.slice();

      const body = {
        item: isReply ? commentId : postData._id,
        topic: isReply ? TOPICS.REPLY_COMMENT : 'forum_post',
        content: data,
        tagged_people: taggedPeople,
        tag_all: tagAll,
        groupId: _.get(group, '_id', ''),
      };

      addComment(body)
        .then(response => {
          this.addedComment = true;
          const { data, total_comment } = response.data;
          const { item = '' } = data;
          if (isReply) {
            handleScrollToReply(commentId);
            const listCommentConvert = newList.map(itemComment => {
              const { _id = '', latestReplyList = [], total_replies = 0 } = itemComment;

              if (_id === item) {
                latestReplyList.push(data);
                return {
                  ...itemComment,
                  total_replies: total_replies + 1,
                  latestReplyList: _.isArray(latestReplyList) ? latestReplyList : [data],
                };
              }
              return itemComment;
            });
            newList = listCommentConvert;
          } else {
            newList.push(data);
          }
          this.setState({ list: newList, total: total_comment }, () => {
            if (this.bodyRef && this.bodyRef.current) {
              if (!isReply) {
                this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
              }
            }
          });

          updatePostsAfterViewDetail(postData._id, { total_comment: total_comment });
          this.handleCancelReply();
          callback && callback();
          this.setState({ loading: false });
        })
        .catch(err => {
          this.setState({ loading: false });
          this.handleErrorResponse(err, () => {
            this.handleCancelReply();
            callback && callback();
          });
        });
    }

    if (type === COMMENT_TYPES.FILE) {
      const localComments = _.map(data, (item, index) => this.initLocalCommentData(item, index, replyMessage));
      this.addLocalCommentToList(localComments, replyMessage);
    }

    if (type === 'image/gif') {
      let newList = list.slice();
      const body = {
        ...data,
        topic: isReply ? TOPICS.REPLY_COMMENT : 'forum_post',
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : postData._id,
        groupId: _.get(group, '_id', ''),
      };

      addComment(body)
        .then(response => {
          this.addedComment = true;
          const { data, total_comment } = response.data;
          const { item = '' } = data;

          if (isReply) {
            handleScrollToReply(commentId);
            const listCommentConvert = newList.map(itemComment => {
              const { _id = '', latestReplyList = [], total_replies = 0 } = itemComment;

              if (_id === item) {
                latestReplyList.push(data);
                return {
                  ...itemComment,
                  total_replies: total_replies + 1,
                  latestReplyList: _.isArray(latestReplyList) ? latestReplyList : [data],
                };
              }
              return itemComment;
            });
            newList = listCommentConvert;
          } else {
            newList.push(data);
          }

          this.setState({ list: newList, total: total_comment }, () => {
            if (this.bodyRef && this.bodyRef.current) {
              if (!isReply) {
                this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
              }
            }
          });

          updatePostsAfterViewDetail(postData._id, { total_comment });
        })
        .catch(err => {
          this.handleErrorResponse(err);
        });
    }
    if (type !== COMMENT_TYPES.TEXT) {
      this.handleCancelReply();
    }
  };

  handleSubmitComment = comment => {
    const { deleteComment, postData, updatePostsAfterViewDetail } = this.props;
    const { _id: postId = '' } = postData;
    this.handleCancelReply();
    deleteComment(comment, postId).then(response => {
      const { list, total } = this.state;
      const { total_replies: totalReplies = 0, _id: commentId = '', topic = '', item: itemComment = '' } = comment;
      const newTotal = total - totalReplies - 1;
      let newList = [];
      if (topic === 'comment') {
        newList = list.map(item => {
          const { _id = '', total_replies = 0, latestReplyList = [] } = item;

          if (_id === itemComment) {
            return {
              ...item,
              total_replies: total_replies - 1,
              latestReplyList: latestReplyList.filter(itemReply => _.get(itemReply, '_id', '') !== commentId),
            };
          }
          return item;
        });
      } else {
        newList = list.filter(item => _.get(item, '_id', '') !== commentId);
      }

      this.setState({ list: newList, total: newTotal });

      updatePostsAfterViewDetail(postData._id, { total_comment: newTotal });

      if (response.data) toast('Comment deleted successfully.');
    });
  };
  handleDeleteComment = comment => {
    this.props.toggleConfirmModal(
      true,
      <S.SConfirmModal
        noBorder
        headerIcon={TrashIcon}
        hasCloseIcon
        hasHoverState
        title="Remove Comment"
        content="The comment will be deleted permanently."
        onConfirm={() => this.handleSubmitComment(comment)}
        confirmButtonTitle="Remove"
      />,
    );
  };

  handleOpenDeletePopup = () => {
    this.props.toggleConfirmModal(
      true,
      <S.SConfirmModal
        noBorder
        headerIcon={TrashIcon}
        hasCloseIcon
        hasHoverState
        title="Remove Post"
        content="The post and all comments will be deleted permanently"
        onConfirm={this.handleDeletePost}
        confirmButtonTitle="Remove"
      />,
    );
  };

  handleDeletePost = () => {
    const { postData } = this.props;
    this.props.deletePost(postData._id);
    this.props.getNotifications(postData._id);
    this.props.onRemovePost();
  };

  handleEditPost = () => {
    const { toggleModal, push, forumId, postData } = this.props;
    push(`/home/forums/${forumId}/discussion`);
    toggleModal &&
      toggleModal(true, <PopUpForm {...this.props} isEdit={true} post={postData} pollData={(postData || {}).poll} />);
  };

  handlePinPost = () => {
    const {
      pinPost,
      unPinPost,
      postData: { isPin, _id },
    } = this.props;
    return isPin ? unPinPost(_id) : pinPost(_id);
  };

  handleUpdateConfigsUpload = config => this.setState({ uploadConfigs: config });

  handleChangePoll = () => {
    const { postData, posts } = this.props;
    const changedPost = posts.find(({ _id }) => _id === postData._id);
    if (changedPost) {
      const { poll = {} } = changedPost;
      !_.isEmpty(poll) && this.setState({ pollData: poll });
    }
  };

  renderPostHeader = () => {
    const { postData, group, user, cloudfrontList } = this.props;
    const { author, createdAt, published_at } = postData;
    const currentPostAuthor = _.isObject(author) ? author : user;

    const name = `${currentPostAuthor.first_name} ${currentPostAuthor.last_name}`;

    const renderTimestamp = () => {
      if (published_at) {
        return <span className="created-at">{moment(published_at).fromNow()}</span>;
      } else if (createdAt) {
        return <span className="created-at">{moment(createdAt).fromNow()}</span>;
      }
    };
    const renderTimestampTooltip = () => {
      if (published_at) {
        return <span>{moment(published_at).format('dddd, MMMM DD, YYYY [at] h:mm A')}</span>;
      } else if (createdAt) {
        return <span>{moment(createdAt).format('dddd, MMMM DD, YYYY [at] h:mm A')}</span>;
      }
    };

    return (
      <S.PostAuthor className="post__author">
        <Avatar
          name={getUserShortName(currentPostAuthor)}
          className="author__avatar"
          size="46"
          src={convertS3UrlToCloudFrontUrl(currentPostAuthor.avatar, cloudfrontList, true)}
        />
        <div className="author__name">
          <S.AuthorName>
            <div>{name}</div>
            <span>
              <PolygonIcon />
            </span>
            <div className="group-name">{group.name}</div>
          </S.AuthorName>
          <div className="date" data-tip data-for={postData._id}>
            {renderTimestamp()}
          </div>
          <ReactTooltip className="app-tooltip" id={postData._id} effect="solid" place={'top'}>
            {renderTimestampTooltip()}
          </ReactTooltip>
        </div>
      </S.PostAuthor>
    );
  };

  renderPostInfo = () => {
    const { postData, members, userId, user } = this.props;
    const { author, preview } = postData;
    const { pollData } = this.state;
    const currentPostAuthor = _.isObject(author) ? author : user;

    const isAuthor = userId === currentPostAuthor._id;

    return (
      <S.PostInfo className="post__info" hasPreview={preview}>
        <>
          <S.PostCaption ref={this.captionRef} showMore={this.state.isShowMore}>
            <MentionEditor members={members} initEditor={postData} readOnly={true} />
          </S.PostCaption>
          {this.state.isShowMore && <S.ShowMoreText onClick={this.toggleReadMore}>{'... See more'}</S.ShowMoreText>}

          {!_.isEmpty(pollData) && (
            <div className="poll-detail-wrapper">
              <PollPost
                isAuthorPost={isAuthor}
                authorPostId={author._id}
                pollData={pollData}
                onChangePoll={this.handleChangePoll}
              />
            </div>
          )}
        </>
      </S.PostInfo>
    );
  };

  handleCloseDropdown = () => {
    this.setState({ openDropdown: false });
  };
  handleOpenDropdown = () => {
    this.setState({ openDropdown: true });
  };

  renderPostOption = () => {
    const { postData, group, userId, user } = this.props;
    const { openDropdown = false } = this.state;
    const { author, isPin } = postData;
    const currentPostAuthor = _.isObject(author) ? author : user;

    const isAuthor = userId === currentPostAuthor._id;
    const showRemovePost = isAuthor || ownerOfForum(user, group);
    const isTeammate = user.is_trainer;

    return (
      <S.OptionWrapper>
        <>
          {!openDropdown && <SharedTooltip id={`post-options-tooltip`} />}

          {isTeammate && (
            <S.Dropdown
              direction="middle"
              className="dropdown"
              upward={false}
              onOpen={this.handleOpenDropdown}
              onClose={this.handleCloseDropdown}
              icon="ellipsis horizontal"
              data-tip
              data-for={`post-options-tooltip`}
            >
              <S.Dropdown.Menu className="dropdown-menu more-actions">
                {isAuthor && (
                  <S.Dropdown.Item onClick={this.handleEditPost}>
                    <S.DropItem leaveGroup>
                      <EditIcon />
                      <span>Edit Post</span>
                    </S.DropItem>
                  </S.Dropdown.Item>
                )}
                <S.Dropdown.Item onClick={this.handlePinPost}>
                  <S.DropItem leaveGroup>
                    <PinnedPostIcon />
                    <span>{isPin ? 'Unpin' : 'Pin'} Post</span>
                  </S.DropItem>
                </S.Dropdown.Item>
                {showRemovePost && (
                  <S.Dropdown.Item onClick={this.handleOpenDeletePopup}>
                    <S.DropItem leaveGroup>
                      <RemovePostIcon />
                      <span>Remove Post</span>
                    </S.DropItem>
                  </S.Dropdown.Item>
                )}
              </S.Dropdown.Menu>
            </S.Dropdown>
          )}
        </>
      </S.OptionWrapper>
    );
  };

  renderAvatars = users => {
    return (
      <S.AvatarContainer twoAvatars={users.length >= 2}>
        {users
          .slice(0, 2)
          .map(user =>
            !!user ? (
              <Avatar name={getUserShortName(user)} className="avatar" size="20" src={user.avatar || ''} />
            ) : null,
          )}
      </S.AvatarContainer>
    );
  };

  handleDrop = files => {
    if (files.length && this.inputRef.current) {
      typeof this.inputRef.current.onDropFiles === 'function' && this.inputRef.current.onDropFiles(files);
    }
  };

  handleLikePost = () => {
    const { postData, getPostDetail } = this.props;

    getPostDetail(postData._id).then(response => {
      const { data } = response.data;
      this.setState({ liker: data.liker, likerInfo: data.liker_info });
    });
  };

  handleReplyMessage = data => () => {
    const { userId } = this.props;
    const newData = { ...data, userId };
    const { type, content, owner, authorId, commentId } = getReplyMessage(newData);

    this.setState({
      replyMessage: {
        type,
        content,
        owner,
        authorId,
        commentId,
      },
    });
  };

  handleCancelReply = () => {
    this.setState({
      replyMessage: {},
    });
  };

  handleViewMoreReply = async params => {
    const { getListReply } = this.props;
    const { list } = this.state;
    await getListReply(params, null, false).then(res => {
      const data = _.get(res, 'data.data', []).reverse();

      const listCommentConvert = list.map(item => {
        if (_.get(item, '_id', '') === _.get(params, 'comment_id', '')) {
          const latestReplyList = _.get(item, 'latestReplyList', []);
          const isViewMore = _.get(item, 'isViewMore', false);
          const latestReplyListConvert = [...data, ...latestReplyList];
          return {
            ...item,
            isViewMore: true,
            latestReplyList: latestReplyListConvert,
          };
        }
        return item;
      });
      this.setState({ list: listCommentConvert });
    });
  };

  handleErrorResponse = (err, callbackError) => {
    const { toggleConfirmModal } = this.props;
    const { replyMessage } = this.state;
    const { message = '', additional = {} } = err;
    const { topic = '' } = additional;
    if (topic === TYPE_ERROR.COMMENT || topic === TYPE_ERROR.FORUM_POST) {
      toggleConfirmModal(
        true,
        <ShowError
          noBorder
          title={`${_.isEmpty(replyMessage) ? 'Can not comment' : 'Can not reply'}`}
          content={message}
          onConfirm={() => {
            if (topic === TYPE_ERROR.COMMENT) {
              this.handleConfirmWithComment();
              callbackError && callbackError();
            }
            if (topic === TYPE_ERROR.FORUM_POST) {
              this.handleConfirmWithForum();
            }
          }}
          confirmButtonTitle="Okay"
          headerIcon={WarningIcon}
          hideCancelButton
        />,
      );
    }
  };

  handleConfirmWithComment = () => {
    const { hideError } = this.props;
    this.getListComments();
    hideError(false);
  };

  handleConfirmWithForum = () => {
    const { hideError, toggleModal, getListPost, forumId, push, resetLoadPostsQuery } = this.props;
    resetLoadPostsQuery();
    hideError(false);
    toggleModal(false);
    push(`/home/forums/${forumId}/discussion`);
    getListPost();
  };

  render() {
    const { list, liker, isSeeMore, initHeightCommentList, replyMessage, likerInfo, pollData } = this.state;
    const { postData, userId, members, getNotifications, group, onlyCaption } = this.props;

    const { total_comment, _id, preview } = postData;
    let likeSum = likeSummary(userId, _.compact(liker));
    const liked = _.find(liker, item => item === userId);

    if (likeSum.includes('and')) {
      likeSum = likeSum.replace(/and/g, `<span class="and-text">and</span>`);
    }
    const isPreviewMeta = _.some(['youtube', 'vimeo', 'youtu.be'], keyword =>
      _.includes(_.toLower(_.get(preview, 'url', '')), keyword),
    );

    return (
      <S.Wrapper>
        <Dropzone onDrop={this.handleDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <S.Container {...getRootProps()} isSeeMore={isSeeMore}>
                <S.Content>
                  <div className="no-scroll" ref={this.noScrollRef}>
                    {this.renderPostOption()}
                    {this.renderPostHeader()}
                    {this.renderPostInfo()}
                    {preview && onlyCaption && (
                      <div className="link-preview-detail">
                        {isPreviewMeta && _.get(preview, 'image', '') ? (
                          <PreviewVideoLink attachmentMeta={preview} onPostDetail />
                        ) : (
                          <PreviewArticleLink attachmentMeta={preview} onPostDetail />
                        )}
                      </div>
                    )}
                    {(!!likeSum || !!total_comment || !!_.get(pollData, 'total_votes', 0)) && (
                      <S.PostStatsContainer hasPreview={preview}>
                        {!!likeSum.length && (
                          <S.Liked>
                            {this.renderAvatars(likerInfo)}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: `<span class="name-text">${_.upperFirst(likeSum)}</span> liked`,
                              }}
                            />
                          </S.Liked>
                        )}
                        <div className="stats__right-side">
                          {!!pollData && (
                            <Button textOnly className="comment">
                              <span>{pluralize('vote', _.get(pollData, 'total_votes', 0), true)}</span>
                            </Button>
                          )}
                          {!!total_comment && (
                            <Button textOnly className="comment">
                              <span>
                                {this.state.total} {pluralize('comment', this.state.total)}
                              </span>
                            </Button>
                          )}
                        </div>
                      </S.PostStatsContainer>
                    )}
                    <Interactions
                      {...this.props}
                      onLikeClick={this.handleLikePost}
                      onCommentClick={() => {
                        this.inputRef.current.handleCommentFocus();
                      }}
                      like={!!liked}
                      comments={this.state.total}
                      postId={_id}
                      groupId={group._id}
                      userId={userId}
                    />
                  </div>
                  <S.Comments ref={this.bodyRef} initHeightCommentList={initHeightCommentList}>
                    {_.map(list, item => {
                      const { latestReplyList = [], _id = '' } = item;
                      return (
                        <CommentReplyItem
                          key={_id}
                          item={item}
                          handleDeleteComment={this.handleDeleteComment}
                          uploadSuccess={this.uploadSuccess}
                          cancelUpload={_.debounce(this.cancelUpload, 300)}
                          members={members}
                          postData={postData}
                          handleReplyMessage={this.handleReplyMessage}
                          showAttachItem={true}
                          replyList={latestReplyList}
                          handleViewMoreReply={this.handleViewMoreReply}
                        />
                      );
                    })}
                  </S.Comments>
                </S.Content>
                <CommentInput
                  onUpdateConfigsUpload={this.handleUpdateConfigsUpload}
                  ref={this.inputRef}
                  {...this.props}
                  onPost={this.handleAddComment}
                  onGetNotifications={getNotifications}
                  autoFocus
                  onlyCaption={this.props.onlyCaption}
                  className="wrapper-comment-input"
                  selectorDetectedInput=".fj-detail-modal"
                  isDragActive={isDragActive}
                  replyMessage={replyMessage}
                  onCancelReply={this.handleCancelReply}
                  placeholder={!_.isEmpty(replyMessage) && 'Write a reply...'}
                />
              </S.Container>
            );
          }}
        </Dropzone>
      </S.Wrapper>
    );
  }
}

export default Component;
