import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 5px;
  .nameInput__editor {
    padding: 10px 15px;
    width: 100%;
    /* box-sizing: border-box; */
    border-radius: 3px;
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 23px;
    line-height: 130%;
    color: #202353;
    outline: none;

    :hover {
      background: #f1f1f3;
    }

    :focus {
      border: 1px solid #5158cf;
      background: #ffffff;
    }

    ${props =>
      props.hasError &&
      css`
        background: #fef4f6 !important;
        border: 1px solid #ea314a !important;
      `}
  }

  .nameInput__warning {
    padding: 5px 15px;

    &--label {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
    }

    &--text {
      color: #ea314a;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;
