import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

import CloseIcon from 'assets/icons/close_circle.svg';
import * as S from './style';

class PopupModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      isDisabledSubmit: false,
      isSubmitted: false,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = nextProps;

    if (!open) {
      this.setState({ name: '', isSubmitted: false });
    }
  }

  handleChangeName = e => {
    const value = e.target.value.replace('  ', ' ');
    if (value.length <= 90) {
      this.setState({ name: value, shouldShowError: false });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const { name } = this.state;
    const { onSubmit } = this.props;

    if (!name.trim()) {
      return;
    }

    let params = {
      name,
    };

    if (onSubmit) {
      this.setState({ isDisabledSubmit: true });
      onSubmit && onSubmit(params);
    }
  };

  render() {
    const {
      open,
      onSetOpen,
      titlePopup,
      description,
      titleInput,
      placeholderInput,
      submitName,
      maxLength,
    } = this.props;
    const { isDisabledSubmit, name, isSubmitted: isSubmitted } = this.state;

    return (
      <S.CreateNewPackageModal
        open={open}
        closeIcon={
          <CloseButton className="close-button" onClick={() => onSetOpen(false)}>
            <img src={CloseIcon} />
          </CloseButton>
        }
      >
        <Modal.Header>
          {titlePopup ? titlePopup : 'Create New'}
          <S.Description>{description && description}</S.Description>
        </Modal.Header>
        <Modal.Content>
          <S.Form onSubmit={this.handleSubmit}>
            <S.Input
              label={titleInput ? titleInput : 'Title'}
              placeholder={placeholderInput ? placeholderInput : 'Input title'}
              required
              isHideErrorMessage
              maxLength={maxLength ? maxLength : 90}
              inputProps={{ autoFocus: true, maxLength: maxLength ? maxLength : 90 }}
              isSubmitted={isSubmitted}
              value={name}
              onChange={this.handleChangeName}
            />
          </S.Form>
          <S.Footer>
            <Button className="submit" disabled={isDisabledSubmit} onClick={this.handleSubmit} purple>
              {submitName ? submitName : 'Create New'}
            </Button>
          </S.Footer>
        </Modal.Content>
      </S.CreateNewPackageModal>
    );
  }
}

export default PopupModal;
