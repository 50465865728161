import React from 'react';
import classNames from 'classnames';

import StatusChip from 'shared/StatusChip';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_thin.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate.svg';
import DefaultBackground from 'assets/icons/default-background.svg';
import ConfirmModal from 'shared/ConfirmModal';
import { checkAssetAssignedToProduct, isTeamAdmin, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import DeleteAssetConfirmModal from 'shared/DeleteAssetConfirmModal';
import { ASSET_TYPE } from 'components/Product/constants';
import * as S from '../style';
import { ReactComponent as ShareIcon } from 'assets/icons/icon_share.svg';
import { ShareOwnerAvatar, ShareSettingModal } from 'shared/AssetsShareSetting';
import { CDN_URL, TEAM_SHARE_NOOWNER, TEAM_SHARE_SHARED } from 'constants/commonData';

const ItemWorkoutCollection = props => {
  const {
    workout = {},
    push,
    toggleConfirmModal,
    removeCollection,
    duplicateCollection,
    payment,
    toggleModal,
    user,
    updateShareStatus,
    cloudfrontList,
    onboardingFlow,
  } = props;

  const collectionId = workout._id;
  const author = typeof workout.author_info === 'object' ? workout.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = workout.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;
  const isShare = workout.share === TEAM_SHARE_SHARED;

  const handleClick = () => {
    push(`/home/workout-collections/${workout._id}`);
  };

  const handleRemove = async () => {
    const isAssignedToProduct = payment
      ? await checkAssetAssignedToProduct(ASSET_TYPE.WORKOUT_COLLECTION, workout._id)
      : false;
    const isAssignedOnboarding = onboardingFlow
      ? await checkAssetAssignedToProduct(ASSET_TYPE.WORKOUT_COLLECTION, workout._id, true)
      : false;

    if (payment && isAssignedToProduct) {
      toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          assetType={ASSET_TYPE.WORKOUT_COLLECTION}
          assetTitle="Workout collection"
          onConfirm={() => {
            toggleConfirmModal(
              true,
              <ConfirmModal
                title={'Remove'}
                content={
                  <>
                    Are you sure you want to remove <b>{workout.name}</b>?
                  </>
                }
                onConfirm={() => removeCollection(workout._id)}
                onDeny={() => {}}
                newStyle
                largeSpacing
                hasHoverState
                confirmButtonTitle="Remove"
                headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
              />,
            );
          }}
        />,
      );
    } else if (onboardingFlow && isAssignedOnboarding) {
      toggleConfirmModal(
        true,
        <DeleteAssetConfirmModal
          isExtraLarge
          isCloseAfterConfirm
          title="Workout Collection is assigned to an Onboarding Flow"
          content="This workout collection is in at least 1 onboarding flow. If the workout collection is removed, it will be removed from all onboarding flows as well."
          assetType={ASSET_TYPE.WORKOUT_COLLECTION}
          onConfirm={() => removeCollection(workout._id)}
        />,
      );
    } else {
      toggleConfirmModal(
        true,
        <ConfirmModal
          title={'Remove'}
          content={
            <>
              Are you sure you want to remove <b>{workout.name}</b>?
            </>
          }
          onConfirm={() => removeCollection(workout._id)}
          onDeny={() => {}}
          newStyle
          largeSpacing
          hasHoverState
          confirmButtonTitle="Remove"
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        />,
      );
    }
  };

  const handleDuplicate = () => {
    let newName = `Copy of ${workout.name}`;
    if (newName.length > 30) {
      newName = newName.slice(0, 30);
    }
    duplicateCollection(workout._id, newName);
  };

  const handleSaveShare = ({ params } = {}) => {
    typeof toggleModal === 'function' && toggleModal(false);
    if (!params || !collectionId) return;
    typeof updateShareStatus === 'function' && updateShareStatus({ params, collectionId });
  };

  const onShare = () => {
    toggleModal &&
      toggleModal(
        true,
        <ShareSettingModal
          user={user}
          owner={author}
          shareStatus={workout.share}
          closeModal={onCloseModal}
          onSave={handleSaveShare}
        />,
      );
  };

  const onCloseModal = () => {
    toggleModal && toggleModal(false);
  };

  const actions = isCreatorOrOwnerOrAdmin
    ? [
        {
          label: 'Edit',
          onClick: () => push(`/home/workout-collections/${workout._id}`),
          icon: <EditIcon />,
          key: `edit-${workout._id}`,
        },
        {
          label: 'Duplicate',
          onClick: handleDuplicate,
          icon: <DuplicateIcon />,
          key: `duplicate-${workout._id}`,
        },
        {
          label: 'Sharing settings',
          onClick: onShare,
          icon: <ShareIcon className="stroke-unset" />,
          key: `share-${workout._id}`,
        },
        {
          label: 'Remove',
          onClick: handleRemove,
          icon: <DeleteIcon />,
          key: `remove-${workout._id}`,
        },
      ]
    : [
        {
          label: 'Duplicate',
          onClick: handleDuplicate,
          icon: <DuplicateIcon />,
          key: `duplicate-${workout._id}`,
        },
      ];

  const banner = workout.background_thumbnail || workout.background;
  return (
    <S.CollectionCard
      banner={banner ? convertS3UrlToCloudFrontUrl(banner, cloudfrontList, true) : DefaultBackground}
      title={workout.name}
      description={workout.description}
      actions={actions}
      key={workout._id}
      onClick={handleClick}
    >
      <StatusChip status={workout.status} customClass="status" />
      <div className={classNames('info-container', { 'share-owner-avatar': true })}>
        <div className="info-content">
          <span>
            Workouts: <b>{workout.number_of_workouts}</b>
          </span>
          <span>
            Categories: <b>{workout.number_of_categories}</b>
          </span>
        </div>
        {author && !isCreator && isShare && (
          <ShareOwnerAvatar
            user={author}
            tooltipId={`avatar-tooltip-${collectionId}`}
            cloudfrontList={cloudfrontList}
          />
        )}
      </div>
    </S.CollectionCard>
  );
};

export default ItemWorkoutCollection;
