import US from 'assets/icons/us_flag.svg'; // United States - US or USA
import AU from 'assets/icons/au_flag.svg'; // Australia - AU or AUS
import CA from 'assets/icons/ca_flag.svg'; // Canada - CA or CAN
import UK from 'assets/icons/uk_flag.svg'; // United Kingdom - GB or GBR
import NZ from 'assets/icons/nz_flag.svg'; // New Zealand - NZ or NZL
import EUR from 'assets/icons/eur_flag.svg'; // Germany - DE or DEU
import CHF from 'assets/icons/chf_flag.svg'; // Switzerland - CH or CHE
import IE from 'assets/icons/ie_flag.svg'; // Ireland - IE or IRL
import FI from 'assets/icons/fi_flag.svg'; // Finland - FI or FIN
import NO from 'assets/icons/no_flag.svg'; // Norway - NO or NOR
import AE from 'assets/icons/ae_flag.svg'; // UAE - AE or ARE
import ES from 'assets/icons/es_flag.svg'; // Spain - ES or ESP
import NL from 'assets/icons/nl_flag.svg'; // Netherlands - NL or NLD
import SE from 'assets/icons/se_flag.svg'; // Sweden - SE or SWE

export const MB_SIDE_BAR_HEIGHT = '650px';

export const floaterStyles = {
  wrapper: {
    zIndex: 100000,
    display: 'block',
    height: '100%',
    cursor: 'pointer',
  },
  floater: {
    zIndex: 10000001,
  },
  container: {
    backgroundColor: 'rgb(33, 33, 33, 0.95)',
    borderRadius: 5,
    color: '#fff',
    filter: 'none',
    padding: '7px 10px',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
    minHeight: 'unset',
    minWidth: 'auto',
    lineHeight: 1.43,
  },
  arrow: {
    color: 'rgb(33, 33, 33, 0.95)',
    length: 6,
    spread: 16,
  },
};

export const floaterStylesDelayShow = {
  ...floaterStyles,
  floater: {
    zIndex: 10000001,
    transition: 'opacity 0.25s linear 0.75s',
  },
  container: {
    ...floaterStyles.container,
    minWidth: '80px',
  },
};

export const SECTION_FORMAT = {
  regular: 'Regular',
  amrap: 'AMRAP',
  timed: 'Timed',
  interval: 'Interval',
  freestyle: 'Freestyle',
};

export const SECTION_CUSTOM_FILTER = {
  regular: 'Regular',
  interval: 'Interval',
  freestyle: 'Freestyle',
  amrap: 'AMRAP',
  timed: 'Timed',
};

export const SECTION_TYPE = {
  workout: 'Workout (Regular)',
  warm_up: 'Warm up',
  cool_down: 'Cool down',
  recovery: 'Recovery',
};

export const TEAM_ROLES = ['Owner', 'Trainer', 'Admin'];
export const TEAM_SHARE_PRIVATE = 0;
export const TEAM_SHARE_SHARED = 1;
export const TEAM_SHARE_NOOWNER = 2;
export const TEAM_SHARE = ['Private to owner', 'Shared with others'];

export const SECTION_ACTIONS = {
  UPDATE: 'UPDATE',
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  ADD: 'ADD',
  REORDER: 'REORDER',
  MOVE_EXERCISE: 'MOVE_EXERCISE',
  DUPLICATE: 'DUPLICATE',
  CUSTOMIZE: 'CUSTOMIZE',
};

export const HIDDEN_SECTION = 'hidden';
export const NUMBER_FIELDS = ['float', 'int', 'time'];
export const SECTION_FORMAT_KEY = {
  AMRAP: 'amrap',
  TIMED: 'timed',
  REGULAR: 'regular',
  INTERVAL: 'interval',
  FREESTYLE: 'freestyle',
};

export const REPEAT_TASK_TYPES = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

export const STRENGTH_AND_BODYWEIGHT = ['Strength', 'Bodyweight'];

export const DEFAULT_SUBMIT_STATUS = { status: -1, success: true };

export const SECTION_FORMAT_OPTIONS = [
  { text: 'Regular', value: 'regular' },
  {
    text: 'Interval',
    value: 'interval',
    description:
      'Interval format autoplays a timer on the client app. This format requires a duration and rest time for each exercise. Can be used for HIIT, TABATA and more.',
  },
  {
    text: 'AMRAP',
    value: 'amrap',
    description:
      'AMRAP format tracks total rounds completed based on time assigned and only allows 1 set per exercise.',
  },
  {
    text: 'Timed',
    value: 'timed',
    description: 'Timed format tracks total duration based on rounds assigned and only allows 1 set per exercise.',
  },
];

export const CLIENT_STATUS = {
  archived: -2,
  offline: -1,
  pending: 0,
  connected: 1,
  waiting: 2,
};

export const DEFAULT_CLIENT_FILTERS = {
  client_type: '',
  client_connection: null,
  ownerShip: '',
  groups: [],
  is_archived: false,
  last_activity: '',
  last_activity_type: 1,
  last_assigned_workout: '',
  last_assigned_workout_type: 3,
};

export const PROGRAM_ASSIGNING = 0;
export const PROGRAM_IN_PROGRESS = 1;
export const PROGRAM_COMPLETED = 2;

export const CALENDAR_TYPES = [
  { value: 1, label: '1 Week' },
  { value: 2, label: '2 Week' },
  { value: 4, label: '4 Week' },
];

export const DEFAULT_CALENDAR_TYPE = { value: 2, label: '2 Week' };

export const MAX_FILE_SIZE = 50;
export const WORKOUT_VISIBILITY = {
  STATE: 'workout_visibility_state',
  CURRENT_WEEK: 'workout_visibility_current_week',
  LAST_WEEK: 'workout_visibility_last_week',
  NEXT_WEEK: 'workout_visibility_next_week',
  NEXT_TWO_WEEK: 'workout_visibility_next_two_week',
  NEXT_THREE_WEEK: 'workout_visibility_next_three_week',
  NEXT_FOUR_WEEK: 'workout_visibility_next_four_week',
  SWAP_OUT_EXERCISE: 'swap_out_exercise',
  LIBRARY_GROUP: 'swap_out_exercise_library_group',
  DEFAULT_EXERCISES: 'swap_out_exercise_use_default_exercises',
  TRACKED_EXERCISES: 'swap_out_exercise_use_tracked_exercises',
  ALL_EXERCISES_LIBRARY: 'swap_out_exercise_use_exercises_from_library',
};

export const SHARE_OPTIONS = [
  {
    text: TEAM_SHARE[TEAM_SHARE_PRIVATE],
    value: TEAM_SHARE_PRIVATE,
  },
  {
    text: TEAM_SHARE[TEAM_SHARE_SHARED],
    value: TEAM_SHARE_SHARED,
  },
];

export const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const HIDE_BODY_METRICS = ['step', 'progress_photo'];
export const CLIENT_RATINGS = ['Easy', 'Moderate', 'Challenging', 'Difficult', 'Too Difficult'];
export const ACCEPT_IMAGES = 'image/png, image/jpg, image/jpeg';
export const ACCEPT_VIDEOS = '.avi, .flv, .mp4, .mov, .3gp';
export const ACCEPT_MPEG4_VIDEOS = '.m4v, .m4a';
export const ANNOUNCEMENT_AUTO_MESSAGE = 'announcement';
export const IN_APP_AUTO_MESSAGE = 'inbox';
export const ACCEPTED_DOCUMENTS =
  '.doc,.docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf';

export const CLIENT_CATEGORY = {
  IN_PERSON: 'In-Person',
  ONLINE: 'Online',
  HYBRID: 'Hybrid',
};

export const GENDERS = [
  { label: 'Male', value: '0' },
  { label: 'Female', value: '1' },
  { label: 'Other', value: '2' },
];

export const FILE_ERRORS = {
  FILE_TYPE_INVALID: 'file_type',
  FILE_SIZE_LIMIT: 'file_size_limit',
  FILE_SIZE_LIMIT_IMAGE: 'file_size_limit_image',
  FILE_SIZE_LIMIT_VIDEO: 'file_size_limit_video',
};

export const CONVERSION = {
  MB_TO_BYTE: 1000000,
  MB_TO_KB: 1000,
};

export const AUTOFLOW_CLIENT_STATUS = {
  COMPLETE: 3,
  ACTIVE: 2,
  PAUSE: 1,
  WAITING_TO_START: 0,
  DEACTIVE: -1,
};

export const AUTOFLOW_STATUS = {
  WAITING_TO_START: 0,
  PAUSED: 1,
  ACTIVATED: 2,
};

export const TOPICS = {
  FOOD_JOURNAL: 'food_journal',
  ASSIGNMENT: 'assignment',
  ASSIGNMENT_RESULT: 'assignment_result',
  TASK: 'task',
  TEAM_BODY_METRIC: 'team_body_metrics',
  LOG_ACTIVITY: 'log_activity',
  PERSONAL_TASK: 'personal_task',
  HABIT: 'habit',
  REPLY_COMMENT: 'reply_comment',
  FORM_QUESTIONNAIRES: 'form_questionnaires',
};

export const DATE_FORMAT = 'MM-DD-YYYY';

export const AUTOFLOW_ASSIGN_CLIENT_ERRORS = {
  EXIST_ON_OTHER: 'EXIST_ON_OTHER',
  EXIST_ON_THIS: 'EXIST_ON_THIS',
  CLIENT_PENDING: 'CLIENT_PENDING',
  CLIENT_BELONG_TO_OTHER_TRAINER: 'CLIENT_BELONG_TO_OTHER_TRAINER',
  CLIENT_OFFLINE: 'CLIENT_OFFLINE',
};

export const USER_ROLE = { ADMIN: 2, TRAINER: 1, OWNER: 0 };

export const MEDIA_FILE_REJECTED_CONTENT = '{{file}}';

export const CDN_URL = process.env.REACT_APP_CDN_URL || '';

export const MEDIA_PLACEHOLDER = `${CDN_URL}/images/media_placeholder.svg`;
export const POST_THUMB_DEFAULT = `${CDN_URL}/images/post_thumb_default.png`;

export const DEMO_EMAIL = '@demo';

export const ONBOARDING_STEPS = {
  ADD_CLIENT: 'add_client',
  ASSIGN_WORKOUT: 'assign_workout',
  ASSIGN_PROGRAM: 'assign_program',
  CUSTOMIZE_METRIC: 'customize_metric',
  CUSTOMIZE_METRIC_STEP_2: 'CUSTOMIZE_METRIC_STEP_2',
  USE_COACH_APP: 'use_coach_app',
  ADD_CUSTOM_BRANDING: 'add_custom_branding',
};

export const WORKOUT_BUILDER_GUIDE_STEPS = {
  CreateExerciseSection: 'create_exercise_section',
  CustomOptions: 'custom_options',
  CreateSuperset: 'create_superset',
};

export const TABLE_CELL_CLIENT_NAME_WIDTH = 270;

export const SERVER_ERROR_MESSAGE = {
  GET_DATA: 'Cannot get data!',
};

export const TRIAL_MODE = {
  Expired: 'expired',
  Active: 'active',
  Grace: 'grace',
};

export const BILLING_CYCLE = {
  1: { key: 1, name: 'Monthly' },
  2: { key: 2, name: 'Annually' },
};

export const PLANS = {
  starter: { key: 'starter', name: 'Starter', clients: 5 },
  pro: { key: 'pro', name: 'Pro', clients: 50 },
  studio: { key: 'studio', name: 'Studio', clients: 200 },
};

export const CARD_BRAND = {
  visa: { name: 'Visa', logo: `${CDN_URL}/images/card_visa.svg` },
  mastercard: { name: 'MasterCard', logo: `${CDN_URL}/images/card_mastercard.svg` },
  maestro: { name: 'MasterCard', logo: `${CDN_URL}/images/card_maestro.svg` },
  diners: { name: 'Diners Club', logo: `${CDN_URL}/images/card_diners-club.svg` },
  discover: { name: 'Discover', logo: `${CDN_URL}/images/card_discover.svg` },
  jcb: { name: 'JCB', logo: `${CDN_URL}/images/card_jcb.svg` },
  unionpay: { name: 'UnionPay', logo: `${CDN_URL}/images/card_unionpay.svg` },
  amex: { name: 'American Express', logo: `${CDN_URL}/images/card_amex.svg` },
};

export const STUDIO_PROGRAM_LEVEL = {
  all_level: { key: 'all_level', value: 'all_level', label: 'All Levels' },
  beginner: { key: 'beginner', value: 'beginner', label: 'Beginner' },
  intermediate: { key: 'intermediate', value: 'intermediate', label: 'Intermediate' },
  advanced: { key: 'advanced', value: 'advanced', label: 'Advanced' },
};

export const STUDIO_PROGRAM_STATUS = {
  NotStarted: 'not_started',
  Active: 'active',
  Paused: 'paused',
  Completed: 'completed',
};

export const METRIC_SETTING_MODULE = {
  AUTOFLOW: 'autoflow_setting',
  CLIENT: 'client_setting',
  DEFAULT_SETTING: 'trainer_default_setting',
};

export const WORKOUT_BUILDER_TYPES = { IN_LIBRARY: 'in_library', ASSIGNMENT: 'assignment', TEMPLATE: 'template' };

export const WORKOUT_AI_BUILDER_PAGES = {
  workout_library: 'workout_library',
  program_calendar: 'program_calendar',
  client_calendar: 'client_calendar',
  studio_program: 'studio_program',
  autoflow_interval: 'autoflow_interval',
  autoflow_exact_date: 'autoflow_exact_date',
};

export const AUTOFLOW_TYPES = {
  EXACT_DATE: 'exact_date',
  INTERVAL: 'interval',
};

export const ANNOUNCEMENT_SCHEDULE_TYPES = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
};

export const LOGGING_WORKOUT_MODES = {
  TRACKING: 'tracking',
  UPDATING: 'update_tracking_data',
};

export const SAVE_AS_PROGRAM_CALENDAR_TYPE = {
  AUTOFLOW: 'autoflow_calendar',
  CLIENT: 'client_calendar',
};

export const LEADERBOARD_RANKING_TYPES = [
  { key: 'workout', label: 'Total number of workouts', value: 1 },
  { key: 'task_general', label: 'Only General Task completed', value: 2 },
  { key: 'task_metric', label: 'Total Number of Metrics', value: 3 },
  { key: 'task_progress', label: 'Total number of Progress Picture', value: 4 },
  { key: 'task_all', label: 'All', value: 5 },
];

export const CONNECT_FORUM_TYPES = {
  ACTIVE: 'active',
  ALL: 'all',
  NONE: 'none',
};

export const LEADERBOARD_STATUS = {
  UPCOMING: 0,
  LIVE: 1,
  COMPLETED: 2,
};

export const FILE_MIMETYPES = {
  VIDEO: ['video'],
  IMAGE: ['image'],
  MP3: ['audio/mpeg', 'audio/x-m4a'],
  DOCUMENT: ['application'],
};

export const DOCUMENT_MINETYPE = ['.docx', '.doc', '.pdf', '.xlsx', '.xls', '.csv'];

export const IN_APP_MESSAGE_STATUS = {
  ACTIVE: 1,
  PAUSE: 2,
};

export const STEP_VIEW_MODES = {
  week: { value: 'week', label: 'Week', buffer: 'week' },
  month: { value: 'month', label: 'Month', buffer: 'month' },
  last7: { value: 'last7', label: 'Last 7 days', buffer: 'last7' },
  last14: { value: 'last14', label: 'Last 14 days', buffer: 'last14' },
};

export const STEP_UNIQUE_CODE = 'steps';

export const SET_TYPES = [
  { value: 'warm_up', key: 'warm_up', text: 'Warm up', label: 'W' },
  { value: 'drop_set', key: 'drop_set', text: 'Drop set', label: 'D' },
  { value: 'failure', key: 'failure', text: 'Failure', label: 'F' },
];

export const DEFAULT_TASK_REPEAT_MONTHLY = 2;

export const SIDEBAR_BACK_TO_PARENT_EVENT = 'sidebar_back_to_parent';

export const CLOSE_TASK_REMINDER_POP_UP = 'close_task_reminder_pop_up';
export const OPEN_TASK_REMINDER_POP_UP = 'open_task_reminder_pop_up';

export const EVENT_CLOSE_TASK_REMINDER_POP_UP = new CustomEvent(CLOSE_TASK_REMINDER_POP_UP, { bubbles: true });
export const EVENT_OPEN_TASK_REMINDER_POP_UP = new CustomEvent(OPEN_TASK_REMINDER_POP_UP, { bubbles: true });
export const CLIENT_OWNER_SHIP = { ME: 1, ALL: 2, CERTAIN_USERS: 3 };

export const WAITING_SEGMENT_NAME = 'Waiting Activation';

export const NOTIFICATION_SUB_ACTIONS = {
  NEW_WAITING_CLIENT: 'client_waiting_activate',
  HABIT_COMPLETE_TRAINER: 'habit_complete_trainer',
  ON_STREAK: 'on_streak',
  COMMENT: 'comment',
  UPDATE: 'update',
  CLIENT_SWAPPED_RECIPE: 'client_swapped_recipe',
  CLIENT_LOGGED_MEAL: 'client_logged_meal',
  TRAINER_INVITE_CODE_ADDED: 'trainer_invite_code_added',
};

export const NOTIFICATION_ACTIONS = {
  FORM_QUESTIONNAIRES: 'form_questionnaires',
  LEADER_BOARD: 'leaderboard',
  REFERRAL: 'referral',
  AUTOFLOW: 'autoflow',
  GOAL: 'goal',
  BODY_METRIC: 'body_metric',
  FORUM: 'forum',
  LOG_ACTIVITY: 'log_activity',
  CLIENT_ASSIGNED: 'client_assigned',
  PROGRESS_PHOTO: 'progress_photo',
  RECAP: 'recap',
  MEAL_PLAN_CLIENT: 'meal_plan_client',
  MACRO: 'macro',
  ONBOARDING_FLOW: 'onboarding_flow',
};

export const METRIC_VALUE_LIMIT = 200000;
export const REFERAL_CODE_KEY = 'EVERFIT_REFERAL_CODE';
export const AFFREF_CODE_KEY = 'EVERFIT_AFFREF_CODE';

export const COOKIE_EXPRIRED_DAYS = 30;

export const PACKAGE_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
  ARCHIVE: 'archive',
  NEW: 'new',
};

export const GERMANY_SWITZERLAND = [
  { value: 'de', label: 'Germany', icon: EUR, currency: 'eur' },
  { value: 'ch', label: 'Switzerland', icon: CHF, currency: 'chf' },
  { value: 'ie', label: 'Ireland', icon: IE, currency: 'eur' },
  { value: 'fi', label: 'Finland', icon: FI, currency: 'eur' },
  { value: 'no', label: 'Norway', icon: NO, currency: 'nok' },
  { value: 'ae', label: 'UAE', icon: AE, currency: 'aed' },
  { value: 'es', label: 'Spain', icon: ES, currency: 'eur' },
  { value: 'nl', label: 'Netherland', icon: NL, currency: 'eur' },
  { value: 'se', label: 'Sweden', icon: SE, currency: 'sek' },
];

export const CURRENCIES = {
  usd: { value: 'usd', label: 'USD ($)', symbol: '$', formatOptions: { code: 'en-US', currency: 'USD' } },
  gbp: { value: 'gbp', label: 'GBP (£)', symbol: '£', formatOptions: { code: 'en-GB', currency: 'GBP' } },
  cad: { value: 'cad', label: 'CAD ($)', symbol: '$', formatOptions: { code: 'en-CA', currency: 'CAD' } },
  aud: { value: 'aud', label: 'AUD ($)', symbol: '$', formatOptions: { code: 'en-AU', currency: 'AUD' } },
  nzd: { value: 'nzd', label: 'NZD ($)', symbol: '$', formatOptions: { code: 'en-NZ', currency: 'NZD' } },
  eur: { value: 'eur', label: 'EUR (€)', symbol: '€', formatOptions: { code: 'en-EU', currency: 'EUR' } },
  chf: { value: 'chf', label: 'CHF', symbol: 'CHF', formatOptions: { code: 'en-CH', currency: 'CHF' } },
  nok: { value: 'nok', label: 'NOK', symbol: 'NOK', formatOptions: { code: 'en-NO', currency: 'NOK' } },
  aed: { value: 'aed', label: 'Dh', symbol: 'Dh', formatOptions: { code: 'en-AE', currency: 'AED' } },
  sek: { value: 'sek', label: 'SEK', symbol: 'SEK', formatOptions: { code: 'se-SE', currency: 'SEK' } },
};

export const COUNTRIES = [
  { value: 'au', label: 'Australia', icon: AU, currency: 'aud' },
  { value: 'ca', label: 'Canada', icon: CA, currency: 'cad' },
  { value: 'gb', label: 'United Kingdom', icon: UK, currency: 'gbp' },
  { value: 'us', label: 'United States', icon: US, currency: 'usd' },
  { value: 'nz', label: 'New Zealand', icon: NZ, currency: 'nzd' },
];

export const TEXT_CURRENCIES = ['chf', 'nok', 'aed', 'sek'];

export const PACKAGE_RECURRING_TYPES = {
  month: { value: 'month', label: 'Months' },
  week: { value: 'week', label: 'Weeks' },
};

export const PACKAGE_PRICING_TYPES = {
  one_time: { value: 'one_time', label: 'One-time Payment', shortLabel: 'One Time' },
  recurring: { value: 'recurring', label: 'Recurring Subscription', shortLabel: 'Recurring' },
};

export const SORT_ENUM = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

export const PACKAGE_SORT_BY = {
  NAME: 'name',
  TOTAL_SOLD: 'total_sold',
  ACTIVE_USERS: 'active_users',
  REVENUE: 'revenue',
  MOST_RECENT: 'most_recent',
};

export const PACKAGE_ANALYTICS_SORT_BY = {
  NEXT_INVOICE: 'next_invoice',
  LAST_INVOICE: 'last_invoice',
  CUSTOMER_NAME: 'customer_name',
  END_DATE: 'end_date',
  ID: 'id',
};

export const PURCHASE_STATUS = {
  ACTIVE: 'active',
  CANCEL: 'cancelled',
  OVERDUE: 'overdue',
  PAUSED: 'paused',
  RESUME: 'resume',
  TRIAL: 'trial',
};

export const INVOICE_REFUND_STATUS = {
  PENDING: 'pending',
  SUCCESSED: 'successed',
  FAILED: 'failed',
};

export const INVOICE_STATUS = {
  PAID: 'paid',
  SCHEDULED: 'scheduled',
  FAILED: 'failed',
  ...PURCHASE_STATUS,
};

export const RESUME_TYPE = {
  IMMEDIATELY: 'immediately',
  EXACT_DATE: 'exact_date',
};

export const PACKAGE_ANALYTICS_VIEW_TYPE = { PURCHASE: 'purchase', INVOICE: 'invoice' };

export const PACKAGE_ANALYTICS_INVOICE_SORT_BY = {
  CUSTOMER: 'customer',
  AMOUNT: 'amount',
  PAYMENT_DUE: 'payment_due',
};

export const FILTER_DAYS = {
  all: 'all',
  this_month: 'this_month',
  days_30: 'days_30',
  months_2: 'months_2',
  days_14: 'days_14',
  days_7: 'days_7',
};

export const FILTER_DAYS_OPTIONS = [
  {
    value: FILTER_DAYS.all,
    label: 'All Time',
  },
  {
    value: FILTER_DAYS.this_month,
    label: 'This Month',
  },
  {
    value: FILTER_DAYS.days_30,
    label: 'Last 30 Days',
  },
  {
    value: FILTER_DAYS.months_2,
    label: 'Last 2 Months',
  },
  {
    value: FILTER_DAYS.days_14,
    label: 'Last 2 Weeks',
  },
  {
    value: FILTER_DAYS.days_7,
    label: 'Last 7 Days',
  },
];

// Activity
export const ACTIVITY_TYPES = {
  MADE_PURCHASE: 'MADE_PURCHASE',
  CLAIMED_PURCHASE: 'CLAIMED_PURCHASE',
  PAID_RECURRING: 'PAID_RECURRING',
  ADDED_PAYMENT_METHOD: 'ADDED_PAYMENT_METHOD',
  PAYMENT_DENIED: 'PAYMENT_DENIED',
  CANCELLATION_SCHEDULED: 'CANCELLATION_SCHEDULED',
  CANCELLATION_UNDONE: 'CANCELLATION_UNDONE',
  SUBSCRIPTION_CANCELLED: 'SUBSCRIPTION_CANCELLED',
  PURCHASE_ENDED: 'PURCHASE_ENDED',
  REFUND_INVOICE: 'REFUND_INVOICE',
  PAUSED_PURCHASE: 'PAUSED_PURCHASE',
  RESUMED_PURCHASE: 'RESUMED_PURCHASE',
  TRIAL_START: 'TRIAL_START',
  TRIAL_CANCELLED: 'TRIAL_CANCELLED',
  SUBSCRIPTION_AFTER_TRIAL: 'SUBSCRIPTION_AFTER_TRIAL',
  TRIAL_PAYMENT_DENIED: 'TRIAL_PAYMENT_DENIED',
  PAID_AND_CLAIM_PURCHASE: 'PAID_AND_CLAIM_PURCHASE',
  WAITLIST_NEW_REQUEST: 'WAITLIST_NEW_REQUEST',
  WAITLIST_PURCHASE_PACKAGE: 'WAITLIST_PURCHASE_PACKAGE',
  WAITLIST_DECLINE_REQUEST: 'WAITLIST_DECLINE_REQUEST',
  WAITLIST_CANCELLED_REQUEST: 'WAITLIST_CANCELLED_REQUEST',
  WAITLIST_NO_RESPONSE: 'WAITLIST_NO_RESPONSE',
  INTRO_CALL_NEW_REQUEST: 'INTRO_CALL_NEW_REQUEST',
  INTRO_CALL_PURCHASE_PACKAGE: 'INTRO_CALL_PURCHASE_PACKAGE',
  INTRO_CALL_DECLINE_REQUEST: 'INTRO_CALL_DECLINE_REQUEST',
  INTRO_CALL_CANCELLED_REQUEST: 'INTRO_CALL_CANCELLED_REQUEST',
  INTRO_CALL_NO_RESPONSE: 'INTRO_CALL_NO_RESPONSE',
};

export const FILTER_OPTIONS = [
  {
    title: 'All',
    value: 'all',
    checked: true,
  },
  {
    title: 'Successful Payment',
    value: 'successful_payment',
    checked: true,
  },
  {
    title: 'Declined Payment',
    value: 'denied_payment',
    checked: true,
  },
  {
    title: 'Activate Purchase',
    value: 'claimed_purchase',
    checked: true,
  },
  {
    title: 'Cancellation',
    value: 'cancellation',
    checked: true,
  },
  {
    title: 'Refund',
    value: 'refund',
    checked: true,
  },
  {
    title: 'Trial',
    value: 'trial',
    checked: true,
  },
];

export const MARKETPLACE_FILTER_OPTIONS = [
  {
    title: 'All',
    value: 'all',
    checked: true,
  },
  {
    title: 'Payment',
    value: 'payment',
    checked: true,
    isCollapse: true,
  },
  {
    title: 'Purchased & Activated',
    value: 'successful_payment',
    checked: true,
    parent: 'payment',
  },
  {
    title: 'Declined Payment',
    value: 'denied_payment',
    checked: true,
    parent: 'payment',
  },
  {
    title: 'Cancellation',
    value: 'cancellation',
    checked: true,
    parent: 'payment',
  },
  {
    title: 'Refund',
    value: 'refund',
    checked: true,
    parent: 'payment',
  },
  {
    title: 'Trial',
    value: 'trial',
    checked: true,
    parent: 'payment',
  },
  {
    title: 'Waitlist',
    value: 'waitlist',
    checked: true,
    isCollapse: true,
  },
  {
    title: 'New Request',
    value: 'waitlist_new_request',
    checked: true,
    parent: 'waitlist',
  },
  {
    title: 'Purchased Package',
    value: 'waitlist_purchase_package',
    checked: true,
    parent: 'waitlist',
  },
  {
    title: 'Declined Request',
    value: 'waitlist_decline_request',
    checked: true,
    parent: 'waitlist',
  },
  {
    title: 'Cancelled Request',
    value: 'waitlist_cancelled_request',
    checked: true,
    parent: 'waitlist',
  },
  {
    title: 'No Response',
    value: 'waitlist_no_response',
    checked: true,
    parent: 'waitlist',
  },
  {
    title: 'Intro Call',
    value: 'intro_call',
    checked: true,
    isCollapse: true,
  },
  {
    title: 'New Request',
    value: 'intro_call_new_request',
    checked: true,
    parent: 'intro_call',
  },
  {
    title: 'Purchased Package',
    value: 'intro_call_purchase_package',
    checked: true,
    parent: 'intro_call',
  },
  {
    title: 'Declined Request',
    value: 'intro_call_decline_request',
    checked: true,
    parent: 'intro_call',
  },
  {
    title: 'Cancelled Request',
    value: 'intro_call_cancelled_request',
    checked: true,
    parent: 'intro_call',
  },
  {
    title: 'No Response',
    value: 'intro_call_no_response',
    checked: true,
    parent: 'intro_call',
  },
];

// Forum
export const FORUM_FILTER_POST = [
  { value: 0, label: 'New Posts' },
  { value: 1, label: 'New Activities' },
];

export const FORUM_AUTHOR_ROLE = {
  CLIENT: { key: 0, label: 'Client', memberLabel: 'Client' },
  ADMIN: { key: 2, label: 'Admin', memberLabel: 'Admin' },
  COACH: { key: 1, label: 'Coach', memberLabel: 'Coach' },
  AUTHOR: { key: 3, label: 'Author', memberLabel: 'Forum owner' },
};

export const DEFAULT_TASK_ADVANCED_SETTINGS = {
  BODY_METRIC: {
    enable_comment: false,
    show_reminder_missed: true,
  },
  PROGRESS_PHOTO: {
    enable_comment: false,
    show_reminder_missed: true,
  },
  GENERAL: {
    enable_comment: true,
    show_reminder_missed: true,
  },
  FORM: {
    enable_comment: false,
    show_reminder_missed: true,
  },
};
export const LIMIT_MESSAGES = 31;
export const SECONDS_TIME_LIMIT = 16;

export const INITIAL_WORKING_COLLECTION = {
  name: '',
  type: '',
  description: '',
  background: '',
  number_of_workouts: '',
  number_of_categories: '',
  number_of_clients: '',
  categories: [],
  status: 'draft',
};

export const INITIAL_WORKING_COLLECTION_CATEGORY = {
  _id: '',
  author: '',
  team: '',
  on_demand_workout_collection: '',
  name: '',
  description: '',
  background: '',
  format: '',
  number_of_workouts: 0,
  workouts: {
    list: [],
    total: 0,
  },
  status: 'inactive',
  created_at: '',
  updated_at: '',
  loading: false,
  rearranging: false,
  query: {
    perPage: 20,
    textSearch: '',
    total: 0,
    isEnd: false,
    lastWorkoutId: '',
    isLoading: false,
  },
};

export const WORKOUT_COLLECTION_STATUS = {
  PUBLISH: 'publish',
  DRAFT: 'draft',
};

export const ON_DEMAND_CATEGORY_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const ON_DEMAND_WORKOUT_TYPE = {
  VIDEO: 'video',
  REGULAR: 'regular',
};

export const ON_DEMAND_WORKOUT_MODAL_MODE = {
  ADD: 'add',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};

export const LOG_ACTIVITY_FIELD = {
  CALORIES: {
    title: 'Calories',
    unique_code: 'calories',
    data_type: 'float',
    unit_category_code: 'calories',
    order: 1,
  },
  DISTANCE: {
    title: 'Distance',
    unique_code: 'distance',
    data_type: 'float',
    unit_category_code: 'distance',
    order: 1,
  },
  AVG_HEART_RATE: {
    title: 'Avg Heart Rate',
    unique_code: 'avg_heart_rate',
    data_type: 'float',
    unit_category_code: 'avg_heart_rate',
    order: 7,
  },
  ELEVATION_GAIN: {
    title: 'Elevation Gain',
    unique_code: 'elevation_gain',
    data_type: 'float',
    unit_category_code: 'distance',
    order: 3,
  },
  AVG_CADENCE: {
    title: 'Avg Cadence',
    unique_code: 'avg_cadence',
    data_type: 'float',
    unit_category_code: 'avg_cadence',
    order: 5,
  },
  POWER: {
    title: 'Power',
    unique_code: 'power',
    data_type: 'float',
    unit_category_code: 'power',
    order: 4,
  },
  REPS: {
    title: 'Reps',
    unique_code: 'reps',
    data_type: 'float',
    unit_category_code: 'reps',
    order: 6,
  },
  PACE: {
    title: 'Pace',
    unique_code: 'pace',
    data_type: 'string',
    unit_category_code: 'pace',
    order: 2,
  },
};

// REFUND PACKAGE
export const nullOption = {
  key: 'Select a reason',
  value: null,
  label: 'Select a reason',
};

export const REFUND_REASONS = [
  nullOption,
  {
    key: 'duplicate',
    value: 'duplicate',
    label: 'Duplicate',
  },
  {
    key: 'requested_by_customer',
    value: 'requested_by_customer',
    label: 'Requested by client',
  },
  {
    key: 'service_not_provided',
    value: 'service_not_provided',
    label: 'Service not provided',
  },
  {
    key: 'other',
    value: 'other',
    label: 'Other',
  },
];

// TAGS SYSTEM
export const MAX_TAG_NAME_LENGTH = 20;
export const TAGS_TYPE = {
  EXERCISE: 1,
  WORKOUT: 2,
  PROGRAM: 3,
};

export const CALENDAR_LIMIT_WEEKS = 52;

export const AUTO_FILL_FIELDS = [
  'weight',
  'heart_rate',
  'distance',
  'distance_short',
  'speed',
  'cadence',
  'rpe',
  'calories',
  'watts',
  'rpm',
  'rir',
  'hr',
];

export const DATE_FORMATS = [
  { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
];

export const FORM_VIEW_TYPES = {
  LIST: 'list',
  GRID: 'grid',
};

export const QUESTION_TYPES = {
  WELCOME_SCREEN: 'welcome_screen',
  SINGLE_CHOICE: 'single_choice',
  MULTIPLE_CHOICE: 'multi_choice',
  LINEAR_SCALE: 'linear_scale',
  SHORT_ANSWER: 'short_answer',
  LONG_ANSWER: 'long_answer',
  SIGNATURE: 'signature',
  DATE_TIME: 'date',
  METRIC: 'metric',
  IMAGE_TEXT: 'image_and_text',
};

export const FORM_FETCH_TYPES = {
  YOUR_FORM: 'your_form',
  ALL_FORM: 'all_form',
};

export const FORM_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archive',
};

// FORMS
export const MAX_FORM_NAME_LENGTH = 40;
export const WELCOME_QUESTION_NAME_LIMIT_LENGTH = 40;
export const WELCOME_DESCRIPTION_LIMIT_LENGTH = 255;
export const QUESTION_NAME_LIMIT_LENGTH = 255;
export const OPTION_NAME_LIMIT_LENGTH = 255;
export const DESCRIPTION_LIMIT_LENGTH = 2000;

export const TASK_TYPES = {
  BODY_METRIC: 'body_metric',
  GENERAL: 'general',
  PROGRESS_PHOTO: 'progress_photo',
  FORM: 'form',
  HABIT: 'habit',
};

export const FORMAT_ACCEPT_UPLOAD_IMAGE = 'image/png,image/jpeg,image/jpg,image/gif';
export const FORMAT_VALIDATE_UPLOAD_IMAGE = ['png', 'jpeg', 'jpg', 'gif'];

export const LIMIT_SIZE_IMAGE_UPLOAD_MB = 25;
export const LIMIT_SIZE_IMAGE_UPLOAD_50MB = 50;
export const LIMIT_SIZE_VIDEO_UPLOAD_MB = 300;
export const LIMIT_SIZE_DOCUMENT_UPLOAD_25MB = 25;

export const showMsgForSize = (size = 25, Unit = 'MB') => {
  return `Please upload an image smaller than ${size}${Unit}`;
};

export const FORMAT_ACCEPT_UPLOAD_IMAGE_NOT_GIF = 'image/png,image/jpeg,image/jpg';
export const FORMAT_VALIDATE_UPLOAD_IMAGE_NOT_GIF = ['png', 'jpeg', 'jpg'];

export const FORMAT_ACCEPT_UPLOAD_IMAGE_AND_VIDEO =
  'image/png, image/jpg, image/jpeg, image/gif, .avi, .flv, .mp4, .mov, .3gp';
export const FORMAT_VALIDATE_UPLOAD_IMAGE_AND_VIDEO = ['png', 'jpg', 'jpeg', 'gif', 'avi', 'flv', 'mp4', 'mov', '3gp'];

export const listWarningMessage = [
  '{FIRST_NAME}',
  '{FIRST NAME}',
  '{LAST_NAME}',
  '{LAST NAME}',
  '{FULL_NAME}',
  '{FULL NAME}',
  '{WORKSPACE_NAME}',
  '{WORKSPACE NAME}',
];

export const LIMIT_RESOURCE_COLLECTION = 10;

export const LIST_SECTION_TYPE = [
  {
    key: 'workout',
    value: 'Workout',
  },
  {
    key: 'warm_up',
    value: 'Warm up',
  },
  {
    key: 'cool_down',
    value: 'Cool down',
  },
  {
    key: 'recovery',
    value: 'Recovery',
  },
];

export const KEY_CODE = {
  esc: 27,
  tab: 9,
  enter: 13,
};

export const KEY = {
  TAB: 'Tab',
};

export const TRAFFIC_TRACKING_CASES = [
  'prev',
  'internal_tracking',
  'clickbutton',
  'utm_page',
  'email',
  'billing_cycle',
  'clients',
  'plan',
  'btn',
  'code',
];

export const CLASSNAME_TOAST = {
  TRAINING_CALENDAR: 'toast-training-calendar',
  SURVEY_MANAGEMENT: 'toast-survey-management',
};

export const CANCEL_REASON = {
  CLIENT_SELF_CANCEL: 'client_self_cancel',
  COACH_MANUAL_CANCEL: 'coach_manual_cancel',
  SUBSCRIPTION_ENDED: 'subscription_ended',
  TRIAL_ENDED: 'trial_ended',
};

export const ACTIVE_PACKAGE_STATUS_COUNT = ['ACTIVE', 'TRIAL'];

export const INBOX_SYSTEM_MESSAGE_TYPES = {
  CREATED_GROUP: 0,
  UPDATE_GROUP_INFO: 1,
  ADD_MEMBER: 2,
  REMOVE_MEMBER: 3,
  MEMBER_LEAVE_GROUP: 4,
};

export const EMAIL_SPELL_CHECK = [
  { domain: 'gamil.com', suggestion: 'gmail.com' },
  { domain: 'gmial.com', suggestion: 'gmail.com' },
  { domain: 'gmal.com', suggestion: 'gmail.com' },
  { domain: 'gmai.com', suggestion: 'gmail.com' },
  { domain: 'gmil.com', suggestion: 'gmail.com' },
  { domain: 'gimail.com', suggestion: 'gmail.com' },
  { domain: 'gmsil.com', suggestion: 'gmail.com' },
  { domain: 'gamll.com', suggestion: 'gmail.com' },
  { domain: 'gmall.com', suggestion: 'gmail.com' },
  { domain: 'gmio.com', suggestion: 'gmail.com' },
];

export const SUPPORTED_DOMAIN_EMAIL = [
  'gmail.com',
  // 'zohomail.com',
  'outlook.com',
  // 'aol.com',
  // 'proton.me',
  // 'protonmail.com',
  // 'gmx.com',
  // 'gmax.us',
  'icloud.com',
  'yahoo.ca',
  'yahoo.co.id',
  'yahoo.co.in',
  'yahoo.co.jp',
  'yahoo.co.kr',
  'yahoo.co.nz',
  'yahoo.co.uk',
  'yahoo.com',
  'yahoo.com.ar',
  'yahoo.com.au',
  'yahoo.com.br',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.is',
  'yahoo.com.mx',
  'yahoo.com.ph',
  'yahoo.com.ru',
  'yahoo.com.sg',
  'yahoo.de',
  'yahoo.dk',
  'yahoo.es',
  'yahoo.fr',
  'yahoo.ie',
  'yahoo.in',
  'yahoo.it',
  'yahoo.jp',
  'yahoo.ru',
  'yahoo.se',
  // 'live.be',
  // 'live.ca',
  // 'live.co.uk',
  // 'live.com',
  // 'live.com.ar',
  // 'live.com.au',
  // 'live.com.mx',
  // 'live.de',
  // 'live.fr',
  // 'live.it',
  // 'live.nl',
  'outlook.com.br',
  'hotmail.be',
  'hotmail.co.il',
  'hotmail.co.uk',
  'hotmail.com',
  'hotmail.com.ar',
  'hotmail.com.br',
  'hotmail.com.mx',
  'hotmail.de',
  'hotmail.es',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.kg',
  'hotmail.kz',
  'hotmail.ru',
  'hotmail.ru',
  // 'yandex.com',
  // 'yandex.ru',
  // 'mail.com',
  // 'usa.com',
  // 'fastmail.com',
];

export const DELETED_CLIENT_NAME = 'Deleted user';

export const PAYMENT_NOTIFICATIONS_TYPES = {
  SUCCESSFUL_PAYMENT_RECURRING: 'successful_payment_recurring',
  SUCCESSFUL_PAYMENT_ONETIME: 'successful_payment_onetime',
  PAYMENT_DECLINED: 'payment_declined',
  SUCCESSFUL_CLAIMED: 'claim_success',
  CANCEL_PURCHASE: 'cancel_purchase',
  ENDED_PURCHASE: 'purchase_ended',
  UNDO_CANCEL_PURCHASE: 'undo_cancel_purchase',
  SCHEDULE_CANCEL_PURCHASE: 'schedule_cancel_purchase',
  TRIAL_SUCCESS: 'package_trial_started',
  TRIAL_CANCELLED: 'client_self_cancel_trial',
  TRIAL_SUCCESS_PAYMENT: 'client_started_a_subscription',
  TRIAL_DENIED_PAYMENT: 'payment_failed_after_trial',
  PAYMENT_SUMMARY: 'payment_summary',
};

export const NOTIFICATION_TYPES = {
  REFERRAL: 'referral',
  AUTOFLOW: 'autoflow',
  PERSONAL_TASK: 'personal_task',
  FORM_QUESTIONNAIRE: 'form_questionnaire',
  ...PAYMENT_NOTIFICATIONS_TYPES,
};

export const NOTIFICATION_AVATAR = {
  [NOTIFICATION_TYPES.REFERRAL]: `${CDN_URL}/images/referral_notify_icon.png`,
  [NOTIFICATION_TYPES.AUTOFLOW]: `${CDN_URL}/images/autoflow_notification_icon.svg`,
  [NOTIFICATION_TYPES.SUCCESSFUL_PAYMENT_RECURRING]: `${CDN_URL}/images/paid_activity.svg`,
  [NOTIFICATION_TYPES.SUCCESSFUL_PAYMENT_ONETIME]: `${CDN_URL}/images/purchase_activity.svg`,
  [NOTIFICATION_TYPES.PAYMENT_DECLINED]: `${CDN_URL}/images/denied_activity.svg`,
  [NOTIFICATION_TYPES.CANCEL_PURCHASE]: `${CDN_URL}/images/scheduled_activity.svg`,
  [NOTIFICATION_TYPES.ENDED_PURCHASE]: `${CDN_URL}/images/scheduled_activity.svg`,
  [NOTIFICATION_TYPES.UNDO_CANCEL_PURCHASE]: `${CDN_URL}/images/upcoming_cancel_activity.svg`,
  [NOTIFICATION_TYPES.SCHEDULE_CANCEL_PURCHASE]: `${CDN_URL}/images/scheduled_activity.svg`,
  [NOTIFICATION_TYPES.TRIAL_SUCCESS]: `${CDN_URL}/images/trial_success.svg`,
  [NOTIFICATION_TYPES.TRIAL_CANCELLED]: `${CDN_URL}/images/scheduled_activity.svg`,
  [NOTIFICATION_TYPES.TRIAL_SUCCESS_PAYMENT]: `${CDN_URL}/images/purchase_activity.svg`,
  [NOTIFICATION_TYPES.TRIAL_DENIED_PAYMENT]: `${CDN_URL}/images/denied_activity.svg`,
  [NOTIFICATION_TYPES.PERSONAL_TASK]: `${CDN_URL}/images/person_task_notification_icon.svg`,
  [NOTIFICATION_TYPES.FORM_QUESTIONNAIRE]: `${CDN_URL}/images/form_questionnaire_notification_icon.svg`,
  [NOTIFICATION_TYPES.PAYMENT_SUMMARY]: `${CDN_URL}/images/payment_summary.svg`,
};

export const NOTIFICATION_CATEGORIES = {
  TRAINING: 'training',
  FORUM: 'forum',
  ADMIN: 'admin',
  PAYMENT: 'payment',
  TASK_HABIT: 'task_habit',
  NUTRITION: 'nutrition',
  PROGRESS_PHOTO: 'progress_photo',
};

export const NOTIFICATION_PAYMENT_STATES = {
  SUCCESS: 'successful_payment',
  DECLINED: 'declined_payment',
  ACTIVATE: 'activate_payment',
  CANCELATION: 'cancellation',
  TRAIL: 'trial',
  PAYMENT_SUMMARY: 'payment_summary',
};

export const RECIPES_SHARES_STATUS = {
  shared: { key: 1, status: 'Shared' },
  private: { key: 0, status: 'Private' },
};

export const X_APP_TYPE = 'web-coach';

export const C_KEY_TOKEN = process.env.REACT_APP_MARKETPLACE_ACCESS_TOKEN || 'C_FP_KEY_TOKEN_DEV';

export const E_FORBIDDEN = 'E_FORBIDDEN';

export const METRIC_UNIT_ERRORS = {
  TIME: 'Invalid time format',
  VALUE: 'Value must be greater than 0',
  FT: `Please input a 'ft' value between 3 and 8`,
  IN: `Please input an 'in' value between 0 and 11`,
};

export const PROGRAM_SORT_TYPE = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const PROGRAM_SORTER_TYPE = {
  TITLE: 'title',
  WORKOUT_SETS: 'workout_sets',
  LAST_INTERACTED: 'last_interacted',
};

export const ERROR_CODE_FORBIDDEN = 'E_FORBIDDEN';

export const ERROR_CODE_BAD_REQUEST = 'E_BAD_REQUEST';

export const ERROR_CODE_PACKAGE_SETTING = {
  WAITING_REQUEST_ERROR: 'PACKAGE_PURCHASE_OPTION_SETTING_HAS_REMAIN_WAITING_REQUEST_ERROR',
  QUANTITY_LIMIT_ERROR: 'PACKAGE_PURCHASE_OPTION_CAN_NOT_SET_WAITLIST_QUANTITY_LIMIT_WITH_UNLIMITED_PURCHASE',
  PURCHASE_LIMIT_ERROR: 'PACKAGE_LIMIT_PURCHASE_CAN_NOT_BE_LESS_THAN_TOTAL_SOLD_AND_TOTAL_OFFERED',
  APPLY_SURVEY_ERROR: 'CAN_NOT_BE_APPLIED_ARCHIVED_SURVEY',
  BAD_REQUEST: 'BAD_REQUEST',
};

export const UNDO_EXPIRED_TIME = 'EXPIRED_TIME_UNDO_REJECTED_PACKAGE_WISHLIST';
export const UNDO_REJECTED_ERROR = 'CAN_NOT_UNDO_REJECTED_PACKAGE_WISHLIST';
export const APPROVED_OR_REJECTED_PACKAGE_UNPUBLISH_ERROR = 'CAN_NOT_PROCESS_REQUEST_PACKAGE_UNPUBLISH';
export const STATUS_IS_NOT_NO_RESPONSE = 'PACKAGE_WISHLIST_STATUS_IS_NOT_NO_RESPONSE';

export const PACKAGE_WISHLIST_TYPES = {
  WAITLIST: 'waitlist',
  CONTACT: 'contact_list',
};

export const EXERCISE_LIMIT_CHARACTER_NOTE = 10000;

export const LIMIT_CHARACTER_SYNONYM = 50;

export const HEIC_IMAGE_TYPES = ['.heic', '.HEIC'];

export const NOTIFICATION_PARENT = {
  ALL: 'all',
  PAYMENT: 'payment',
  WAITLIST: 'waitlist',
  INTRO_CALL: 'intro_call',
};

export const SPECIAL_CHARACTERS = `!"#\\$%&'()*+,-.\\/:;<=>?@\\]\\[\\\\_\\^\`{|}~`;
export const TRIGGER_SUB_ACTIONS = [
  'program_library_deleted',
  'forum_deleted',
  'form_question_option_deleted',
  'form_question_deleted',
  'form_question_option_added',
  'form_question_option_updated',
  'form_template_trigger_changed',
  'form_trigger_changed',
];

export const ONBOARDING_FLOW_TYPE = 'onboarding_flow';

export const SOCIAL_STRING = {
  instagram: 'instagram',
};
