import styled, { css } from 'styled-components';
import { Wrapper, Header, Content } from 'shared/Styles/ModalLayout';
import { CDN_URL } from 'constants/commonData';

export const TableContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 25px;
  min-height: 300px;

  table {
    border-spacing: 0 0;
    border-collapse: collapse;
    position: relative;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #222222;
    text-align: left;

    tr {
      td {
        padding: 16px 15px;
        box-shadow: inset 0px -1px 0px #f0f0f0;

        .status {
          font-weight: 600;
          font-size: 11px;
          line-height: 15px;
          color: #ea314a;

          &.paid {
            color: #50c94d;
          }
        }

        &.amount {
          font-weight: 600;
          position: relative;
        }

        &.pay-button {
          padding: 0;
        }
      }
    }

    thead {
      th {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #a1a1a5;
        padding: 17px 15px;
        box-shadow: 0px 2px 0px #f1f3f4;
        position: sticky;
        top: 0;
        z-index: 999;
        cursor: pointer;
      }
    }
  }
`;

export const ContentWrapper = styled(Wrapper)`
  ${Header} {
    padding-bottom: 15px;
  }

  ${Content} {
    padding: 0 5px;
  }
  .loading-history {
    padding-bottom: 120px;
    .custom-indicator.ui.loader::before {
      border-color: rgba(0, 0, 0, 0.1) !important;
    }
    .custom-indicator.ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const DownloadIconBilling = styled.div`
  width: 25px;
  height: 25px;
  background: url(${CDN_URL}/images/download_billing_icon.svg) center no-repeat;
  cursor: pointer;

  :hover {
    background: url(${CDN_URL}/images/download_billing_icon_active.svg) center no-repeat;
  }
`;

export const DownloadingIcon = styled.div`
  display: inline-block;
  border-radius: 50%;
  position: relative;
  width: 25px;
  height: 25px;
  background-color: #f0f1ff;
  cursor: not-allowed;

  .lds-ring {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #7470ef;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #7470ef #7470ef #7470ef transparent;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :nth-child(1) {
    animation-delay: -0.45s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

export const PayButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  color: #5c5bbd;
  cursor: pointer;
  width: 76px;
  position: absolute;
  top: 20px;
  left: 80px;

  svg {
    margin-right: 5px;
  }
`;
