import React from 'react';
import * as S from './style';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export default function AddCollectionArea(props) {
  return (
    <S.Wrapper>
      <div className="collectionArea__content">
        <div className="collectionArea__image">
          <img src={`${CDN_URL}/images/collection_area_cover_img.png`} alt="" />
        </div>
        <div className="collectionArea__info">
          <div className="collectionArea__info--title">Assign a Resource Collection</div>
          <div className="collectionArea__info--description">
            Empower your client with a collection of resources (links and documents)
          </div>
          <Button className="collectionArea__info--button" onClick={props.onSelectCollection}>
            Choose Collection
          </Button>
        </div>
      </div>
    </S.Wrapper>
  );
}
