import { useEffect } from 'react';

import { KEY_CODE } from 'constants/commonData';
import { isESCPress } from 'utils/commonFunction';

const useEscKeyPress = callback => {
  const handleKeyPress = event => {
    if (event.keyCode === KEY_CODE.esc || isESCPress(event.key)) {
      callback && callback();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]);
};

export default useEscKeyPress;
