import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import styled from 'styled-components';
import { ErrorMessage, FormGroup } from 'shared/FormControl';
import { CALENDAR_LIMIT_WEEKS } from 'constants/commonData';

const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  .label__maxLength {
    opacity: 0.3;
  }
`;

const FormControl = styled(FormGroup)`
  margin-bottom: 22px;
  input {
    height: 42px;
    font-weight: normal;
  }

  textarea {
    background: #ffffff;
    border: 1px solid #dcdcde;
    box-sizing: border-box;
    border-radius: 4px;
    color: #323c47;
    font-size: 12px;
    line-height: 18px;
    padding: 5px 15px;
    outline: none !important;
    width: 100%;
    font-weight: normal;
    font-family: Open Sans;
    height: 134px;
    resize: none;

    &::placeholder {
      color: #969fb5;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.error {
    input:hover:not(:disabled),
    input:focus {
      border-color: #ea314a;
    }
  }
`;

export default class FormItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getInvalidMsg = () => {
    const { rules = [], required, value, type } = this.props;
    let msg = '';
    if (required && (!value || _.isEmpty(value.trim()))) {
      return 'Please add a program name';
    }
    if (type === 'number' && parseInt(value) > CALENDAR_LIMIT_WEEKS) {
      return `Enter a number from 1 to ${CALENDAR_LIMIT_WEEKS}`;
    }
    _.forEach(rules, rule => {
      if (rule.regex && !rule.regex.test(value)) {
        msg = rule.message;
        return false;
      }
      if (!rule.valid) {
        msg = rule.message;
        return false;
      }
    });
    return msg;
  };

  renderInput = (type, value, onChange, placeholder, inputProps) => {
    switch (type) {
      case 'textarea':
        return <textarea {...inputProps} value={value} onChange={onChange} placeholder={placeholder} />;
      case 'number':
        return <input {...inputProps} type="number" value={value} onChange={onChange} placeholder={placeholder} />;
      default:
        return <input {...inputProps} value={value} onChange={onChange} placeholder={placeholder} />;
    }
  };

  render() {
    const { label, type, value, onChange, placeholder, isSubmited, inputProps, maxLength } = this.props;
    const errorMsg = this.getInvalidMsg();
    return (
      <FormControl className={classNames({ error: isSubmited && !!errorMsg })}>
        <Label>
          {label}
          {maxLength ? (
            <span className="label__maxLength">
              {value.length} / {maxLength}
            </span>
          ) : null}
        </Label>
        {this.renderInput(type, value, onChange, placeholder, inputProps)}
        {!!isSubmited && (
          <div style={{ marginTop: '5px' }}>
            <ErrorMessage>{errorMsg}</ErrorMessage>
          </div>
        )}
      </FormControl>
    );
  }
}
