import styled, { css } from 'styled-components';
import { AUTOFLOW_TYPES } from 'constants/commonData';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #202353;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  ${props => props.gap && `gap: ${props.gap}px`}
`;

export const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 9px;
`;

export const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  color: #202353;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export const BadgeWrapper = styled.div`
  min-width: 56px;
  height: 19px;
  border-radius: 3px;
  border: 1px solid rgba(80, 136, 226, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0c6af6;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
`;

export const InfoText = styled.div`
  color: #818da1;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
`;

export const CoverImage = styled.div`
  width: 54px;
  height: 54px;
  img {
    width: 54px;
    height: 54px;
    object-fit: cover;
    border-radius: 5px;
  }
`;

export const AutoflowWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const AutoflowLabel = styled.div`
  display: flex;
  width: 102px;
  padding: 4px 5px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;

  ${props =>
    props.type === AUTOFLOW_TYPES.INTERVAL &&
    css`
      color: #3bb240;
      background: #eafcf1;
    `}

  ${props =>
    props.type === AUTOFLOW_TYPES.EXACT_DATE &&
    css`
      color: #2480d6;
      background: #e3f2ff;
    `}
`;

export const QuestionTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  color: #202353;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.7px;
  margin-left: 12px;
`;

export const OptionContainer = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const OptionQuestion = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const QuestionNumber = styled.div`
  ${baseText}
  color: rgb(32, 35, 83, 0.5);
  font-size: 12px;
  font-weight: 700;
`;

export const FormInfoLayout = styled.div`
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  background-color: #edf0fa;
`;
export const SignatureWrapper = styled.div`
  display: flex;
  margin-left: 3px;
  gap: 8px;
`;

export const FormInfoText = styled.span`
  color: #202870;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 13.62px;
  text-transform: uppercase;
`;

export const QuestionTypeWrapper = styled.div`
  /* min-width: 39px; */
  height: 20px;
  padding: 0px 4px;
  background: #ee586c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  gap: 4px;
  border-radius: 2px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  .item-info {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(100% + 8px);
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #dcdcdc;
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
`;
