import React from 'react';
import PropTypes from 'prop-types';

import RenderOwnerShip from '../ShareModal/RenderOwnerShip';

import * as S from './style';

const CustomOwnerShip = props => {
  const {
    userId,
    shareValue,
    onSetShareValue,
    ownerValue,
    onSetOwnerValue,
    hasSpacing = false,
    hasAvatar = false,
    hasYou = false,
    isEditAble = false,
    isTrainer = false,
    disableOwner = false,
  } = props;

  return (
    <S.CustomOwnerShip>
      <RenderOwnerShip
        userId={userId}
        ownerValue={ownerValue}
        shareValue={shareValue}
        onSetShareValue={onSetShareValue}
        onSetOwnerValue={onSetOwnerValue}
        hasSpacing={hasSpacing}
        hasYou={hasYou}
        isEditAble={isEditAble}
        isTrainer={isTrainer}
        hasAvatar={hasAvatar}
        disableOwner={disableOwner}
      />
    </S.CustomOwnerShip>
  );
};

CustomOwnerShip.propTypes = {
  userId: PropTypes.string.isRequired,
  shareValue: PropTypes.object.isRequired,
  onSetShareValue: PropTypes.func.isRequired,
  ownerValue: PropTypes.object.isRequired,
  onSetOwnerValue: PropTypes.func.isRequired,
  hasSpacing: PropTypes.bool,
  hasYou: PropTypes.bool,
  isEditAble: PropTypes.bool,
  isTrainer: PropTypes.bool,
};

export default CustomOwnerShip;
