import React from 'react';
import _ from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';

import { Button } from 'shared/FormControl';
import CloseIcon from 'assets/icons/close_circle.svg';
import * as S from './style';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { generateParams } from 'shared/AssetsShareSetting/constants';

class PopupModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      isDisabledSubmit: false,
      isSubmitted: false,
      author: props.user._id,
      share: TEAM_SHARE_PRIVATE,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open || this.props.fields !== prevProps.fields) {
      const { open, fields } = this.props;
      if (!open) {
        this.setState({ name: '', isSubmitted: false });
      }
      if (fields) {
        const newState = fields.reduce((accumulator, currentValue) => {
          accumulator[currentValue.name] = '';
          return accumulator;
        }, {});
        this.setState(it => ({ ...it, ...newState }));
      }
    }
  }

  handleChangeName = (e, _name) => {
    const value = e.target.value.replace('  ', ' ');
    const _maxLength = this.props.maxLength || 30;

    if (_maxLength === 0 || value.length <= _maxLength) {
      this.setState({ [_name]: value, shouldShowError: false });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const { name, author, share } = this.state;
    const { onSubmit, fields } = this.props;
    const _fieldName = _.map(fields, item => item.name);

    let isValidation = true;
    let params = {
      name,
    };

    if (fields) {
      _.map(_fieldName, item => {
        params = { ...params, [item]: this.state[item] };
      });
    }

    _.forEach(params, item => {
      if (item.trim().length === 0) isValidation = false;
    });

    if (!isValidation) return;
    if (onSubmit) {
      const paramsShare = generateParams({
        ownership: author,
        shareStatus: share,
      });

      onSubmit && onSubmit(params, paramsShare, this.onCloseForm);
    }
  };

  onCloseForm = () => {
    const { onSetOpen } = this.props;

    this.setState({ isDisabledSubmit: false });
    onSetOpen && onSetOpen(false);
  };

  handleChangeOwnershipStatus = ({ author, share }) => {
    this.setState({ author, share });
  };

  handleClosePopup = () => {
    const { onSetOpen, user } = this.props;
    onSetOpen(false);
    this.setState({
      ownership: user._id,
      share: TEAM_SHARE_PRIVATE,
    });
  };

  handleRenderFields = item => {
    const { isSubmitted } = this.state;

    return (
      <S.Input
        label={item.label || 'Title'}
        placeholder={item.placeholder || 'Input title'}
        required={item.required}
        isHideErrorMessage={item.isHideErrorMessage}
        maxLength={item.maxLength}
        inputProps={{ autoFocus: item.autoFocus || false, maxLength: item.maxLength }}
        isSubmitted={isSubmitted}
        value={this.state[item.name]}
        onChange={e => this.handleChangeName(e, item.name)}
      />
    );
  };

  render() {
    const {
      open,
      titlePopup,
      description,
      titleInput,
      placeholderInput,
      submitName,
      maxLength,
      fields,
      isPassedFirstTimeAdd,
      user,
    } = this.props;
    const { isDisabledSubmit, name, isSubmitted, share } = this.state;
    const _maxLength = maxLength || 0;

    return (
      <S.CreateAddNewModal
        open={open}
        closeIcon={
          <CloseButton className="close-button" onClick={this.handleClosePopup}>
            <img src={CloseIcon} alt="" />
          </CloseButton>
        }
        isPassedFirstTimeAdd={isPassedFirstTimeAdd}
      >
        <Modal.Header>
          <div className="header-title">{titlePopup ? titlePopup : 'Create New'}</div>
          <OwnershipPopupStudio
            size={28}
            user={user}
            owner={user}
            shareStatus={share}
            saveChangeOnClose={this.handleChangeOwnershipStatus}
          />
          {description && <S.Description>{description}</S.Description>}
        </Modal.Header>
        <Modal.Content>
          <S.Form onSubmit={this.handleSubmit}>
            <S.Input
              label={titleInput ? titleInput : 'Title'}
              placeholder={placeholderInput ? placeholderInput : 'Input title'}
              required
              isHideErrorMessage
              maxLength={_maxLength > 0 && _maxLength}
              inputProps={{ autoFocus: true, maxLength: _maxLength > 0 && _maxLength }}
              isSubmitted={isSubmitted}
              value={name}
              onChange={e => this.handleChangeName(e, 'name')}
            />
            {fields && _.map(fields, item => this.handleRenderFields(item))}
          </S.Form>
          <S.Footer>
            <Button className="submit" disabled={isDisabledSubmit} onClick={this.handleSubmit} purple>
              {submitName ? submitName : 'Create New'}
            </Button>
          </S.Footer>
        </Modal.Content>
      </S.CreateAddNewModal>
    );
  }
}

export default PopupModal;
