import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button as CloseButton, Image } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import { Formik, Form, Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import _, { get, round } from 'lodash';
// import containers
import AddIntroModal from '../AddIntroModal';
import ManualGoalModal from '../ManualGoalModal';
import CalculateForm from './CalculateForm';
import AddRestDaySection from '../AddRestDaySection';
import ReactSelectFormik from '../ReactSelectFormik';
// import actions
import { toggleModal } from 'actions/modal';
import { getClientInfo, saveMacroPersonalInfo } from 'actions/marcos';

import { CDN_URL } from 'constants/commonData';

// import icons
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip-icon.svg';
// import ultis
import { calculateMacros } from 'utils/macros';
import CalculateMacrosModalStyles, { HeaderStyles, ContentStyles, ActionsStyles } from './styles';

const listHeight = [
  {
    key: 'cm',
    value: 'cm',
    label: 'cm',
  },
  {
    key: 'in',
    value: 'in',
    label: 'in',
  },
];
const listWeight = [
  {
    key: 'kg',
    value: 'kg',
    label: 'kg',
  },
  {
    key: 'lb',
    value: 'lb',
    label: 'lb',
  },
];
const listBodyFat = [
  {
    key: '%',
    value: '%',
    label: '%',
  },
];

const CalculateMacrosModal = ({ isModalOpen, clientId, fetchReport, dispatch }) => {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [heightUnit, setHeightUnit] = useState(listHeight[0]);
  const [weightUnit, setWeightUnit] = useState(listWeight[0]);
  const [bodyFatUnit, setBodyFatUnit] = useState(listBodyFat[0]);
  const [restDayType, setRestDayType] = useState(undefined);
  const [listRestDay, setListRestDay] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [initData, setInitData] = useState({
    age: undefined,
    gender: undefined,
    height: undefined,
    weight: undefined,
    bodyFat: undefined,
    activityLevel: undefined,
    goal: undefined,
    activityLevelRestDay: undefined,
    goalRestDay: undefined,
  });

  useEffect(() => {
    dispatch(getClientInfo(clientId)).then(data => {
      setPersonalInfo(data.data.data);
    });
  }, [clientId]);

  useEffect(() => {
    let currentHeightUnit;
    let currentWeightUnit;
    if (get(personalInfo, 'height.unit.unique_code')) {
      currentHeightUnit =
        listHeight.find(height => height.value === get(personalInfo, 'height.unit.unique_code')) || listHeight[0];
      setHeightUnit(currentHeightUnit);
    }
    if (get(personalInfo, 'weight.unit.unique_code')) {
      currentWeightUnit =
        listWeight.find(weight => weight.value === get(personalInfo, 'weight.unit.unique_code')) || listWeight[0];
      setWeightUnit(currentWeightUnit);
    }
    if (get(personalInfo, 'body_fat.unit.unique_code'))
      setBodyFatUnit(
        listBodyFat.find(bodyFat => bodyFat === get(personalInfo, 'body_fat.unit.unique_code')) || listBodyFat[0],
      );
    if (personalInfo) {
      let newData = {
        ...initData,
        age: get(personalInfo, 'age') || undefined,
        gender: get(personalInfo, 'sex') && get(personalInfo, 'sex') !== '-1' ? get(personalInfo, 'sex') : undefined,
        height: get(personalInfo, 'height.value') ? round(get(personalInfo, 'height.value')) : undefined,
        weight: get(personalInfo, 'weight.value') ? round(get(personalInfo, 'weight.value')) : undefined,
        bodyFat: get(personalInfo, 'body_fat.value') ? round(get(personalInfo, 'body_fat.value')) : undefined,
        activityLevel: get(personalInfo, 'activity_level') || undefined,
        goal: get(personalInfo, 'goal') || undefined,
        activityLevelRestDay: get(personalInfo, 'rest_day_activity_level') || undefined,
        goalRestDay: get(personalInfo, 'rest_day_goal') || undefined,
      };
      if (currentHeightUnit && currentHeightUnit.value === 'in' && newData.height) {
        newData = {
          ...newData,
          height: Math.floor(newData.height / 12),
          heightIn: newData.height - Math.floor(newData.height / 12) * 12,
        };
      }
      const initListRestdays = get(personalInfo, 'rest_days');
      const initRestdayType = get(personalInfo, 'rest_day_type');
      if (initRestdayType) {
        setRestDayType(initRestdayType);
      }
      if (initListRestdays) {
        setListRestDay(initListRestdays.split('_'));
      }

      setInitData(newData);
    }
  }, [personalInfo]);

  useEffect(() => {
    if (heightUnit.value === 'in' && get(currentData, 'height')) {
      let heightIn = round(get(currentData, 'height') / 2.54);
      setInitData({
        ...currentData,
        height: Math.floor(heightIn / 12),
        heightIn: heightIn - Math.floor(heightIn / 12) * 12,
      });
    }
    if (heightUnit.value === 'cm' && get(currentData, 'height')) {
      setInitData({
        ...currentData,
        height: round((get(currentData, 'height') * 12 + get(currentData, 'heightIn')) * 2.54),
      });
    }
  }, [heightUnit]);

  useEffect(() => {
    if (weightUnit.value === 'kg' && get(currentData, 'weight')) {
      setInitData({
        ...currentData,
        weight: round(get(currentData, 'weight') / 2.204623),
      });
    }
    if (weightUnit.value === 'lb' && get(currentData, 'weight')) {
      setInitData({
        ...currentData,
        weight: round(get(currentData, 'weight') * 2.204623),
      });
    }
  }, [weightUnit]);

  useEffect(() => {
    if (!currentData || _.values(currentData).every(_.isUndefined)) {
      setIsDisabledBtn(true);
    } else {
      setIsDisabledBtn(false);
    }
  }, [currentData]);

  const onSubmit = (values, { setSubmitting }) => {
    const data = calculateMacros({
      ...values,
      heightUnit: heightUnit.value,
      weightUnit: weightUnit.value,
      height: heightUnit.value === 'cm' ? values.height : values.height * 12 + (values.heightIn || 0),
    });
    let restDayData;
    if (restDayType) {
      restDayData = {
        ...calculateMacros({
          ...values,
          heightUnit: heightUnit.value,
          weightUnit: weightUnit.value,
          height: heightUnit.value === 'cm' ? values.height : values.height * 12 + (values.heightIn || 0),
          goal: values.goalRestDay,
          activityLevel: values.activityLevelRestDay,
        }),
        type: restDayType,
        rest_days: listRestDay.join('_'),
      };
    }
    dispatch(
      saveMacroPersonalInfo({
        client: clientId,
        age: values.age,
        sex: values.gender,
        height: {
          value: heightUnit.value === 'cm' ? values.height : values.height * 12 + (values.heightIn || 0),
          unit: {
            unique_code: heightUnit.value,
          },
        },
        weight: {
          value: values.weight,
          unit: {
            unique_code: weightUnit.value,
          },
        },
        body_fat: {
          value: values.bodyFat,
          unit: {
            unique_code: '%',
          },
        },
        activity_level: values.activityLevel,
        goal: values.goal,
        rest_day_type: restDayType,
        rest_days: listRestDay.join('_'),
        rest_day_activity_level: values.activityLevelRestDay,
        rest_day_goal: values.goalRestDay,
      }),
    );
    dispatch(
      toggleModal(
        true,
        <ManualGoalModal
          clientId={clientId}
          data={data}
          restDayData={restDayData}
          fetchReport={fetchReport}
          isEnabledBtn={true}
        />,
      ),
    );
    setSubmitting(false);
  };

  return (
    <CalculateMacrosModalStyles
      size={'tiny'}
      open={isModalOpen}
      onClose={() => dispatch(toggleModal(false))}
      className="client-invite-modals"
      closeOnDimmerClick={false}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
    >
      <Formik
        initialValues={initData}
        enableReinitialize={true}
        validate={values => {
          const bodyFat = Number(get(values, 'bodyFat'));
          const errors = {};
          if (!values.age) errors.age = 'Age is required';
          if (!values.gender) errors.gender = 'Gender is required';
          if (!values.height) errors.height = 'Height is required';
          if (!values.weight) errors.weight = 'Weight is required';
          if (!values.activityLevel) errors.activityLevel = 'Activity Level is required';
          if (!values.goal) errors.goal = 'Goal is required';
          if (restDayType) {
            if (!values.activityLevelRestDay) errors.activityLevelRestDay = 'Activity Level is required';
            if (!values.goalRestDay) errors.goalRestDay = 'Goal is required';
          }
          if (values.bodyFat !== undefined && values.bodyFat !== '') {
            if (bodyFat > 99.99 || bodyFat < 0.1) {
              errors.bodyFat = 'Value should be from 0.10 to 99.99';
            }
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => {
          setCurrentData(values);
          return (
            <Form>
              <HeaderStyles>
                <div
                  className="back-icon"
                  onClick={() =>
                    dispatch(toggleModal(true, <AddIntroModal clientId={clientId} fetchReport={fetchReport} />))
                  }
                >
                  <BackIcon />
                </div>
                <div className="title">Calculate Macros</div>
                <div
                  className="calculate-macros-tooltip"
                  data-for="calculate-macros"
                  data-tip="calculate-macros-tooltip"
                >
                  <TooltipIcon />
                </div>
                <ReactTooltip id="calculate-macros" effect="solid" place={'top'}>
                  <div>
                    The Mifflin-St Jeor equation is used to calculate BMR and then multiplied by a basic activity factor
                    and goal multiplier.
                  </div>
                </ReactTooltip>
              </HeaderStyles>
              <ContentStyles>
                <CalculateForm
                  heightUnit={heightUnit}
                  setHeightUnit={setHeightUnit}
                  weightUnit={weightUnit}
                  setWeightUnit={setWeightUnit}
                  bodyFatUnit={bodyFatUnit}
                  setBodyFatUnit={setBodyFatUnit}
                />
                <AddRestDaySection
                  restDayType={restDayType}
                  setRestDayType={setRestDayType}
                  listRestDay={listRestDay}
                  setListRestDay={setListRestDay}
                >
                  <br />
                  <div className="group-inline">
                    <Field
                      name="activityLevelRestDay"
                      label="Activity Level"
                      component={ReactSelectFormik}
                      menuPlacement="top"
                      options={[
                        {
                          value: 'sedentary',
                          label: 'Sedentary (little or no exercise)',
                        },
                        {
                          value: 'lightly-active',
                          label: 'Lightly active (exercise 1-3 days/week)',
                        },
                        {
                          value: 'moderately-active',
                          label: 'Moderately active (exercise 3-5 days/week)',
                        },
                        {
                          value: 'very-active',
                          label: 'Very active (intense exercise 6-7 days a week)',
                        },
                        {
                          value: 'extremely-active',
                          label: 'Extremely active (2+ hrs of intense physical activity daily)',
                        },
                      ]}
                      placeholder="Choose activity level"
                    />
                  </div>
                  <div className="group-inline">
                    <Field
                      name="goalRestDay"
                      label="Goal"
                      component={ReactSelectFormik}
                      menuPlacement="top"
                      options={[
                        {
                          value: 'lose-weight',
                          label: 'Lose weight',
                        },
                        {
                          value: 'maintain-weight',
                          label: 'Maintain weight and build muscle',
                        },
                        {
                          value: 'gain-weight',
                          label: 'Gain weight and build muscle',
                        },
                      ]}
                      placeholder="Choose goal"
                    />
                  </div>
                </AddRestDaySection>
              </ContentStyles>
              <ActionsStyles>
                <Button purple type="submit" disabled={isDisabledBtn}>
                  Submit
                </Button>
              </ActionsStyles>
            </Form>
          );
        }}
      </Formik>
    </CalculateMacrosModalStyles>
  );
};

export default connect(({ isModalOpen }) => ({
  isModalOpen,
}))(CalculateMacrosModal);
