import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { toggleConfirmModal } from 'actions/modal';
import { toggleModal } from 'actions/modal';
import { convertS3UrlToCloudFrontUrl, isTeamAdmin } from 'utils/commonFunction';
import InviteMemberForm from '../../InviteMemberForm';
import LeaveGroup from '../../LeaveGroup';
import ManageNotifications from '../ForumNavBar/components/ManageNotifications';
import { ASSET_TYPE } from 'components/Product/constants';
import { checkAssetAssignedToProduct } from 'utils/commonFunction';
import { SharedTooltip } from 'shared/SharedTooltip';

import { ReactComponent as NotificationIcon } from 'assets/icons/bell_icon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings_gear_icon.svg';
import { ReactComponent as LeaveGroupIcon } from 'assets/icons/leave_group_icon.svg';

import * as S from './style';

const StickyHeader = props => {
  const { group, user, push, payment, onboarding_flow } = props;
  const forumId = props.match.params.id || props.match.params.forumId;
  const isOwner = user._id === group.author;
  const isAdmin = isTeamAdmin(user);
  const handleTogglePopup = () => {
    props.toggleModal(true, <InviteMemberForm {...props} />);
  };
  const [assigned, setAssigned] = useState(false);
  const [assignedOnboardingFlow, setAssignedOnboardingFlow] = useState(false);

  useEffect(() => {
    if (payment) {
      checkAssetAssignedToProduct(ASSET_TYPE.FORUM, forumId).then(res => setAssigned(res));
    }
    if (onboarding_flow) {
      checkAssetAssignedToProduct(ASSET_TYPE.FORUM, forumId, true).then(res => setAssignedOnboardingFlow(res));
    }
  }, []);

  const handleCloseAction = () => {
    props.toggleModal(false);
  };

  const handleLeaveGroup = () => {
    const { toggleConfirmModal } = props;
    toggleConfirmModal &&
      toggleConfirmModal(true, <LeaveGroup assigned={assigned} assignedOnboardingFlow={assignedOnboardingFlow} />);
  };

  const navigateToAdvancedSettings = () => {
    if (!forumId) return;
    push(`/home/forums/${forumId}/settings`);
  };

  const TooltipPortal = ({ children }) => {
    return ReactDOM.createPortal(
      children,
      document.getElementById('root'), // Place render the tooltip
    );
  };

  const manageNotifications = () => {
    props.toggleModal(true, <ManageNotifications onCloseAction={handleCloseAction} groupId={forumId} />);
  };

  const customTrigger = (
    <div data-tip data-for="sticky-header-options-tooltip" className="sticky-header-options-tooltip">
      <TooltipPortal>
        <SharedTooltip id="sticky-header-options-tooltip" />
      </TooltipPortal>
    </div>
  );

  return (
    <S.Wrapper isSticky={props.isSticky}>
      <S.ForumHeader>
        <S.Container>
          <S.ForumAvatar src={convertS3UrlToCloudFrontUrl(props.group.banner, props.cloudfrontList, true)} />
          <div className="forum-name">{props.group.name}</div>
        </S.Container>

        <S.Container>
          <S.InviteButton purple onClick={handleTogglePopup}>
            + Invite
          </S.InviteButton>
          <S.Dropdown button icon="ellipsis horizontal" onOpen={() => {}} trigger={customTrigger}>
            <S.Dropdown.Menu className="dropdown-menu more-actions">
              <S.Dropdown.Item onClick={manageNotifications}>
                <S.DropItem>
                  <NotificationIcon />
                  <span>Manage Notifications</span>
                </S.DropItem>
              </S.Dropdown.Item>
              <S.Dropdown.Item onClick={navigateToAdvancedSettings}>
                <S.DropItem>
                  <SettingsIcon />
                  <span>Advanced Settings</span>
                </S.DropItem>
              </S.Dropdown.Item>
              <S.Dropdown.Item onClick={handleLeaveGroup}>
                <S.DropItem leaveGroup>
                  <LeaveGroupIcon />
                  <span>Leave Group</span>
                </S.DropItem>
              </S.Dropdown.Item>
            </S.Dropdown.Menu>
          </S.Dropdown>
        </S.Container>
      </S.ForumHeader>
    </S.Wrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { user, cloudfrontList } = state;

  return {
    user,
    cloudfrontList,
    group: state.rootReducer.forum.group,
    payment: state.rootReducer.permission.payment,
    onboarding_flow: state.rootReducer.permission.onboarding_flow,
  };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(StickyHeader);
