import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const TypeItem = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #e2e3e8;
  box-sizing: border-box;
  border-radius: 5px;
  height: 104px;
  color: #728096;
  padding-top: 66px;
  background: transparent url(${props => props.icon}) no-repeat;
  background-size: auto;
  background-position: center 24px;

  div {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    margin-top: 10px;
  }

  :hover {
    border-color: #5158cf;
    background-image: url(${props => props.hoverIcon});
    cursor: pointer;
    color: #000000;
  }
`;

export const AttachmentTypeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  box-shadow: none;
  outline: none;

  .drop-zone__input-container {
    z-index: 999;

    .icon {
      margin-bottom: 22px;
    }

    .text {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #6a778a;
    }
  }
`;

export const GeneralTaskWrapper = styled.div`
  .text-editable__input {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000000;
  }

  input {
    color: #000;
    font-weight: normal;

    ::placeholder {
      color: #202353;
      opacity: 0.4;
    }
  }

  .text-editable__wrapper {
    margin: 0;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 1;
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: 14px;
  border-radius: 50%;
`;
