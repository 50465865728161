import styled, { css } from 'styled-components';

export const Button = styled.button`
  width: 100%;
  height: 48px;
  background: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: #fbfbfb;
    border: 1px solid #ececec;
  }

  ${props =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      background: #fbfbfb;
      border: 1px solid #ececec;
    `}
`;

export const ButtonIcon = styled.span`
  display: inline-flex;
`;

export const ButtonTitle = styled.span`
  font-size: 14px;
  line-height: 19px;
  color: #232529;
  font-weight: 600;
`;
