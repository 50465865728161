import React from 'react';
import Slider from 'react-slick';
import { Container } from 'shared/UpgradePath/style';
import FormsUpgradePathSrc from 'assets/images/forms_upgrade_path.png';
import FormsUpgradePath2Src from 'assets/images/forms_upgrade_path2.png';
import { PLANS } from 'constants/commonData';
import * as S from './style';

const settings = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: 'slick-dots',
  customPaging: i => <S.Dots className={'dots-item'} />,
  // nextArrow: <SampleNextArrow />,
  // prevArrow: <SamplePrevArrow />,
};

const data = [
  {
    imageSrc: FormsUpgradePathSrc,
    title: 'Forms and Questionnaires',
    description: 'Create forms, questionnaires, and surveys to check-in and engage your clients',
    className: 'first',
  },
  {
    imageSrc: FormsUpgradePath2Src,
    title: 'Forms and Questionnaires',
    description: 'Create forms, questionnaires, and surveys to check-in and engage your clients ',
    className: 'second',
  },
];

const planWillSelect = { tier: PLANS.pro.key };

const FormsUpgradePath = () => {
  return (
    <S.FallbackWrapperS>
      <Container>
        <S.Section>
          <S.Slider>
            <Slider {...settings}>
              {data.map(obj => (
                <div>
                  <S.Body>
                    <S.UpgradePathBackground src={obj.imageSrc} />
                    <S.Title className="shift-up">{obj.title}</S.Title>
                    <S.Description className="shift-up">{obj.description}</S.Description>
                    <S.SUpgradePathButton
                      className="upgrade-path-button"
                      text="Upgrade to Pro or Studio"
                      planWillSelect={planWillSelect}
                    />
                  </S.Body>
                </div>
              ))}
            </Slider>
          </S.Slider>
        </S.Section>
      </Container>
    </S.FallbackWrapperS>
  );
};

export default FormsUpgradePath;
