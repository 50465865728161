import get from 'lodash/get';
import floor from 'lodash/floor';
import round from 'lodash/round';
import moment from 'moment';

import { mongoObjectId, roundNumberBodyMetric } from 'utils/commonFunction';
import { getRangeOfSleep, getStartEndSleeps, getTimeGoalFromDate } from './helpers';
import { SLEEP_COLOR_RANGES, SLEEP_STAGE_KEYS } from './constants';

export const getColorsRange = colors => {
  return SLEEP_COLOR_RANGES[colors.join('-')];
};

export const checkDataHasStage = chartData => {
  return (chartData || [])
    .flatMap(item => item.stage_details)
    .some(item => item.sleep_stage !== SLEEP_STAGE_KEYS.asleep);
};

export const checkIsOnlyAsleepInBed = chartData => {
  return (chartData || [])
    .flatMap(item => item.stage_details)
    .every(item => [SLEEP_STAGE_KEYS.asleep, SLEEP_STAGE_KEYS.in_bed].includes(item.sleep_stage));
};

export const checkIsAllInBed = chartData => {
  return (chartData || [])
    .flatMap(item => item.stage_details)
    .every(item => item.sleep_stage === SLEEP_STAGE_KEYS.in_bed);
};

export const roundUpInteger = num => {
  return Number(floor(num) + (num % 1 !== 0));
};

export function getDomainsHourlyWithGoal(min, max, goalValue) {
  let finalMin = min;
  let finalMax = max;

  if (goalValue > max) {
    finalMax = goalValue;
  }

  if (goalValue < min) {
    finalMin = goalValue;
  }

  return [floor(finalMin), roundUpInteger(finalMax)];
}

export const convertMinToHour = val => {
  return +Number(val / 60).toFixed(2);
};

export const getHourlyGoalValue = (metricSettings, isDurationView, target) => {
  const showGoalLine = get(metricSettings, 'key_metrics', []).includes('goal');
  if (!showGoalLine) return null;

  const { value: duration, sleep_time_goal: sleep_time } = get(target, 'data') || {};

  if (isDurationView) {
    return duration ? roundNumberBodyMetric(duration / 60, 2) : null;
  }

  if (sleep_time) {
    return getTimeGoalFromDate(sleep_time);
  }

  return null;
};

const convertStageValue = stage => {
  const startStage = moment(stage.start_time, 'YYYY-MM-DD HH:mm');
  const endStage = moment(stage.end_time, 'YYYY-MM-DD HH:mm');
  const minutes = endStage.diff(startStage, 'm');
  return {
    type: stage.sleep_stage,
    time: minutes,
    value: convertMinToHour(minutes),
  };
};

// this is for have multiple group sleep in a day
export const convertGroupSleepToDate = (chartData, to_date, hasStage) => {
  const { grouped_sleep_in_day = [], ...rest } = chartData[0] || {};
  const allInBedStage = [SLEEP_STAGE_KEYS.awake, SLEEP_STAGE_KEYS.in_bed];
  const isAllBed = checkIsAllInBed(chartData);

  return grouped_sleep_in_day.map((itemStage, index) => {
    const _id = mongoObjectId();
    const { startMM, endMM } = getStartEndSleeps([itemStage]);
    const total = itemStage.reduce((tt, item) => tt + item.duration, 0);
    const dataType = [];
    let currentStage = null;

    itemStage.forEach((stage, index) => {
      if (allInBedStage.includes(stage.sleep_stage)) {
        // stop append time of asleep
        if (currentStage) {
          const stageValue = convertStageValue(currentStage);
          dataType.push(stageValue);
          currentStage = null;
        }

        const newType = convertStageValue(stage);
        dataType.push({ ...newType, type: isAllBed ? SLEEP_STAGE_KEYS.in_bed : SLEEP_STAGE_KEYS.empty });
      } else {
        if (currentStage) {
          currentStage.end_time = stage.end_time;
        } else {
          currentStage = { ...stage, sleep_stage: SLEEP_STAGE_KEYS.asleep };
        }

        // calculate data if that's last item
        if (index === itemStage.length - 1) {
          const stageValue = convertStageValue(currentStage);
          dataType.push(stageValue);
        }
      }
    });

    const range = getRangeOfSleep(startMM, endMM, to_date);

    if (hasStage && !isAllBed) {
      dataType.unshift({
        type: SLEEP_STAGE_KEYS.in_bed,
        time: total,
        value: convertMinToHour(total),
      });
    }

    return {
      ...rest,
      _id,
      type: _id,
      grouped_sleep_in_day: grouped_sleep_in_day[index],
      date: rest.group_name,
      rangeValues: range,
      dataType: dataType,
    };
  });
};

export const getFinalDomainsHourly = (domains, isDurationView, isHourlyShowStages = false) => {
  if (!domains) return undefined;
  const [min, max] = domains;
  const newMin = floor(min);
  const newMaxRound = Math.ceil(max);
  let newMax = newMaxRound;
  const gap = newMaxRound - newMin;

  switch (true) {
    case gap <= 8: {
      newMax = isDurationView ? 8 : newMin + 8;
      break;
    }
    case isHourlyShowStages:
      newMax = isDurationView ? gap : newMin + gap;
      break;
    case gap <= 12: {
      newMax = isDurationView ? 12 : newMin + 12;
      break;
    }
    case gap <= 16: {
      newMax = isDurationView ? 16 : newMin + 16;
      break;
    }
    case gap <= 20: {
      newMax = isDurationView ? 20 : newMin + 20;
      break;
    }
    case gap <= 24: {
      newMax = isDurationView ? 24 : newMin + 24;
      break;
    }
    default:
      break;
  }

  return [newMin, newMax];
};

export const getTickLabelForHourlyStages = domains => {
  const gap = domains[1] - domains[0];
  let steps = 2;
  let label = 5;

  switch (gap) {
    case 9:
      steps = 3;
      label = 4;
      break;
    case 10:
    case 11:
    case 12:
      steps = 3;
      label = 5;
      break;
    case 13:
    case 14:
      steps = 5;
      label = 4;
      break;
    case 15:
    case 16:
      steps = 4;
      label = 5;
      break;
    case 17:
    case 18:
      steps = 6;
      label = 4;
      break;
    case 19:
    case 20:
      steps = 5;
      label = 5;
      break;
    case 21:
      steps = 7;
      label = 4;
      break;
    case 22:
    case 23:
    case 24:
      steps = 6;
      label = 5;
      break;
    default:
      break;
  }

  return Array(label)
    .fill(0)
    .map((_, index) => domains[0] + index * steps);
};
