import React from 'react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';

// Parts
import GoToIcon from '../GoToIcon';
import { getTooltipContent, isPrivateFlow } from '../../units/helper';

// Styles
import * as S from './styles';

const DropdownOnboardingFlows = props => {
  const { trigger, open, onboardingFlows, onOpenPopup, onClosePopup, onSelectItem, user, selectItem } = props;

  if (!Array.isArray(onboardingFlows)) return trigger;

  const handleOpenPopup = () => {
    typeof onOpenPopup === 'function' && onOpenPopup();
  };

  const handleClosePopup = () => {
    typeof onClosePopup === 'function' && onClosePopup();
  };

  const renderFlowItem = ({ name, _id, share, author }, idx) => {
    let isDisable = false;
    let tooltipContent;

    if (typeof user === 'object' && typeof author === 'object') {
      isDisable = isPrivateFlow(user, author, share);

      if (isDisable) {
        tooltipContent = getTooltipContent(author);
      }
    }

    return (
      <S.SelectItemWrapper
        key={`item-${_id}`}
        className={classNames({
          scrollable: onboardingFlows.length >= 5,
          active: selectItem === idx,
        })}
        onClick={onSelectItem(idx)}
      >
        <div className="label">{name}</div>
        <GoToIcon
          tooltipId={`go-to-icon-tooltip-${_id}-${idx}`}
          href={`/home/onboarding-flow/${_id}`}
          disable={isDisable}
          tooltipContent={tooltipContent}
        />
      </S.SelectItemWrapper>
    );
  };

  return (
    <S.DropdownOnboardingFlowsWrapper
      trigger={trigger}
      basic={true}
      flowing={true}
      hoverable={true}
      on="click"
      position="bottom right"
      open={open}
      onOpen={handleOpenPopup}
      onClose={() => {}}
    >
      <RootCloseWrapper event="click" onRootClose={handleClosePopup} disabled={false}>
        <div className={classNames('popup-content-wrapper', { scrollable: onboardingFlows.length >= 5 })}>
          <div className="popup-content-scrollbar">{onboardingFlows.map(renderFlowItem)}</div>
        </div>
      </RootCloseWrapper>
    </S.DropdownOnboardingFlowsWrapper>
  );
};

export default DropdownOnboardingFlows;
