import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import * as S from './style';
import { Steps } from './constants';
import Dismiss from './Dismiss';
import { CDN_URL, ONBOARDING_STEPS } from 'constants/commonData';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import ReferralACoach from './ReferralACoach';
import { addResponsiveMetaTag, shouldUseVirtualViewport } from 'utils/event-api';

function Onboarding(props) {
  const { user, onboarding, location, hasCopyWeekOrWorkout } = props;
  const { checkList, maximize, hideFeature, showOnboardingGuide } = onboarding;

  const list = _.map(Steps, item => {
    const apiItem = _.find(checkList, c => c.type === item.type);
    return apiItem ? { ...apiItem, ...item } : item;
  });
  const unfinishedStep = _.find(list, item => !item.state);

  //state
  const [openStep, setOpenStep] = useState(_.get(unfinishedStep, 'type', ''));
  const [zoomRatio, setZoomRatio] = useState(Math.max(1, window.innerWidth / window.screen.width));

  //ref
  const checkListRef = useRef();

  const onClose = () => {
    props.minimizeCheckList();
  };

  const onRootClose = event => {
    typeof event.persist === 'function' && event.persist();
    const classList = _.get(event, 'target.classList');

    if (classList && (classList.contains('Toastify__toast') || classList.contains('Toastify__toast__close-button'))) {
      return false;
    }

    props.minimizeCheckList();
  };

  const onOpen = () => {
    if (window.screen.width < 576) {
      addResponsiveMetaTag();
    }

    props.maximizeCheckList();
  };

  const onToggleOpenStep = step => {
    if (openStep === step.type) {
      setOpenStep('');
    } else {
      setOpenStep(step.type);
    }
  };

  const onChecklistClick = () => {
    if (showOnboardingGuide) {
      props.hideOnboardingUserGuide();
    }
  };

  useEffect(() => {
    if (maximize) {
      setOpenStep(_.get(unfinishedStep, 'type', ''));
    } else {
      if (window.screen.width < 576) {
        shouldUseVirtualViewport();
      }
    }
  }, [maximize]);

  useEffect(() => {
    if (checkList.length) {
      const unfinished = _.find(checkList, item => !item.state);
      const newOpenStep = _.get(unfinished, 'type', '');

      if (maximize && openStep === ONBOARDING_STEPS.ADD_CLIENT && openStep !== newOpenStep) {
        setOpenStep(newOpenStep);
      }
    }
  }, [maximize, checkList]);

  const completedSteps = _.filter(list, item => !!item.state);
  const percent = (completedSteps.length / list.length) * 100;

  if (!user || hideFeature || !checkList.length || _.trimEnd(location.pathname, '/') === '/pricing-plan') {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <S.Trigger hasCopyWeekOrWorkout={hasCopyWeekOrWorkout} onClick={onOpen} className="onboarding-guide">
        <S.TriggerIcon />
        <S.TriggerContent>
          <S.TriggerTitle>{unfinishedStep ? unfinishedStep.name : 'All items completed!'}</S.TriggerTitle>
          <S.TriggerSubTitle>
            {completedSteps.length} of {list.length} completed
          </S.TriggerSubTitle>
        </S.TriggerContent>
      </S.Trigger>
      <RootCloseWrapper disabled={!maximize || showOnboardingGuide} event="click" onRootClose={onRootClose}>
        <S.CheckListWrapper
          className={classNames({ visible: maximize, 'with-user-guide': showOnboardingGuide })}
          ref={checkListRef}
          onClick={onChecklistClick}
          showMobileUI={window.screen.width < 576}
        >
          <S.Container>
            <img src={`${CDN_URL}/images/close_white_large.svg`} className="close" onClick={onClose} alt="" />
            <S.MainContent>
              <S.Header>
                <h1>{unfinishedStep ? `Welcome, ${user.first_name}! 👋` : `Congratulations 🎉`}</h1>
                <h3>
                  {unfinishedStep ? (
                    'Learn how Everfit can help you save time and start training clients in minutes!'
                  ) : (
                    <div>
                      You finished your basic training! Visit our knowledge center at{' '}
                      <a href="http://help.everfit.io/" target="_blank">
                        help.everfit.io
                      </a>{' '}
                      to learn more.
                    </div>
                  )}
                </h3>
              </S.Header>
              <S.Progress>
                <S.ProgressPercent>{_.round(percent, 0)}%</S.ProgressPercent>
                <S.ProgressBarContainer>
                  <S.ProgressBar style={{ width: `${percent}%` }} />
                </S.ProgressBarContainer>
              </S.Progress>
              <S.Steps>
                {_.map(list, (step, index) => (
                  <S.Step key={step.type} open={step.type === openStep} completed={step.state}>
                    <S.StepHeader onClick={() => onToggleOpenStep(step)}>
                      <S.StepStatus>{step.state ? '' : index + 1}</S.StepStatus>
                      <S.StepName>{step.name}</S.StepName>
                      <S.StepCollapseIcon />
                    </S.StepHeader>
                    <S.StepContentWrapper>
                      <S.StepContent>
                        <div className="text">{step.description}</div>
                        <div className="action">{step.action && step.action(props)}</div>
                      </S.StepContent>
                    </S.StepContentWrapper>
                  </S.Step>
                ))}
              </S.Steps>
            </S.MainContent>
            <S.Footer>
              <ReferralACoach onClick={onClose} />
              <Dismiss />
            </S.Footer>
          </S.Container>
        </S.CheckListWrapper>
      </RootCloseWrapper>
    </>,
    document.body,
  );
}

export default Onboarding;
