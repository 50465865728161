import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Image, Icon } from 'semantic-ui-react';
import { toggleModal } from 'actions/modal';
import ClientInviteModal from 'components/ClientInviteModal';
import AddMultipleClients from 'components/AddMultipleClients';
import { MenuContainer, Wrapper } from './style';
import { RootCloseWrapper } from 'react-overlays';
import LimitClientsFailbackPopup from 'shared/UpgradePath/components/LimitClientsFailbackPopup';
import UpgradePath from 'shared/UpgradePath';
import { CDN_URL } from 'constants/commonData';

export const AddClientButton = ({ onInviteSuccess, ...props }) => {
  const [open, setOpen] = useState(false);
  const totalClients = _.get(props.client, 'total_active_clients', 0);
  const limitClients = props.permission.no_clients || 10;
  const exceededNumOfClients = limitClients <= totalClients && props.permission.no_clients >= 0;

  const addSingleClient = () => {
    if (exceededNumOfClients) {
      props.toggleModal(true, <LimitClientsFailbackPopup />);
    } else {
      props.toggleModal(true, <ClientInviteModal onInviteSuccess={onInviteSuccess} />);
    }
  };

  const addMultiClients = () => {
    if (props.permission.bulk_invite_clients) {
      if (exceededNumOfClients) {
        props.toggleModal(true, <LimitClientsFailbackPopup />);
      } else {
        props.toggleModal(true, <AddMultipleClients />);
      }
    } else {
      props.toggleModal(
        true,
        <LimitClientsFailbackPopup
          title="Speed up Growth"
          description="Save time and add or import multiple clients <br/> to your client list in one go."
        />,
      );
    }
  };

  return (
    <RootCloseWrapper event="click" onRootClose={() => setOpen(false)} disabled={!open}>
      <Wrapper className={`add-client-wrapper${open ? ' open' : ''}`}>
        <div className="add-client__button">
          <div className="add-button__single" onClick={addSingleClient}>
            <Image src={`${CDN_URL}/images/add_client.svg`} width={14} />
            <span>Add Client</span>
          </div>
          <div className="add-button__multiple" onClick={() => setOpen(!open)}>
            <Icon name="chevron down" />
          </div>
        </div>
        <MenuContainer>
          <div className="add-client-menu">
            <div className="menu__item" onClick={addSingleClient}>
              <div>
                <Image src={`${CDN_URL}/images/client_thin.svg`} />
              </div>
              <div>Add Single Client</div>
            </div>
            <div className="menu__item" onClick={addMultiClients}>
              <div>
                <Image src={`${CDN_URL}/images/multiple_client_thin.svg`} />
              </div>
              <div>Add Multiple Clients</div>
              <UpgradePath
                pathName="bulk_invite_clients"
                fallback={
                  <img
                    style={{ marginLeft: '10px' }}
                    alt=""
                    src={`${CDN_URL}/images/upgrade_path_invite_multi_clients.svg`}
                  />
                }
              />
            </div>
          </div>
        </MenuContainer>
      </Wrapper>
    </RootCloseWrapper>
  );
};

const mapStateToProps = state => {
  return {
    permission: state.rootReducer.permission,
    client: state.rootReducer.client,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientButton);
