import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import {
  addGroupMembers,
  getContactsList,
  getLatestMessages,
  startWaitingSystemMessage,
} from 'redux/inbox-new/actions';

const mapStateToProps = state => ({
  currentRoomId: get(state, 'rootReducer.inboxNew.currentRoomId'),
});

const mapDispatchToProps = dispatch => ({
  getContactsList: bindActionCreators(getContactsList, dispatch),
  addGroupMembers: bindActionCreators(addGroupMembers, dispatch),
  getLatestMessages: bindActionCreators(getLatestMessages, dispatch),
  startWaitingSystemMessage: bindActionCreators(startWaitingSystemMessage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
