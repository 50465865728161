import React, { memo, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import * as S from './style';
import ProgressRing from 'shared/ProgressRing';
import GiphyWatermark from 'shared/Giphy/components/Watermark';
import { MESSAGE_TYPE, getAttachmentType } from 'components/Inbox/helps';

const AttachmentItem = ({
  thumbnail,
  message,
  progress,
  isLocalFile,
  index,
  length,
  onClick,
  cancelable = false,
  cancelUpload = () => {},
}) => {
  const attachmentType = useMemo(() => getAttachmentType(message), [message]);
  const progressRadius = useMemo(() => (index > 1 && length === 5 ? 22 : 30), [index, length]);

  const onLoadThumbnailFail = useCallback(
    event => {
      event.preventDefault();
      if (!isEmpty(thumbnail)) {
        event.target.src = thumbnail;
      }
    },
    [thumbnail],
  );

  const renderContent = () => {
    if (isEqual(attachmentType, MESSAGE_TYPE.VIDEO)) {
      if (isLocalFile) {
        return (
          <video>
            <source src={get(message, 'attachment')} type={get(message, 'attachment_type')} />
            <source src={get(message, 'attachment')} type="video/mp4" />
          </video>
        );
      }
      return (
        <GiphyWatermark isInbox={length === 1} loaderMaxSize={length !== 1} showWatermark={false}>
          <img src={thumbnail} alt="" onError={onLoadThumbnailFail} />
        </GiphyWatermark>
      );
    } else if (isEqual(attachmentType, MESSAGE_TYPE.IMAGE)) {
      return (
        <GiphyWatermark isInbox={length === 1} loaderMaxSize={length !== 1} showWatermark={false}>
          <img src={isLocalFile ? get(message, 'attachment') : thumbnail} alt="" onError={onLoadThumbnailFail} />
        </GiphyWatermark>
      );
    } else if (isEqual(attachmentType, MESSAGE_TYPE.GIPHY)) {
      return (
        <GiphyWatermark isInbox={true}>
          <img src={thumbnail} alt="" onError={onLoadThumbnailFail} />
        </GiphyWatermark>
      );
    }
  };
  return (
    <S.MultipleMediaItem onClick={onClick}>
      {renderContent()}
      {isLocalFile && (
        <S.MultipleMediaProgressWrapper>
          <S.MultipleMediaProgressContainer>
            <ProgressRing radius={progressRadius} progressBarWidth={3} progress={progress} />
            {cancelable && <S.CancelButton onClick={cancelUpload} />}
          </S.MultipleMediaProgressContainer>
        </S.MultipleMediaProgressWrapper>
      )}
      {!isLocalFile && <S.MediaPlay isVideo={isEqual(attachmentType, MESSAGE_TYPE.VIDEO)} />}
    </S.MultipleMediaItem>
  );
};

export default memo(AttachmentItem);
