import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import * as S from './style';
import Header from './Header';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { getDuration, getVideoType } from 'utils/commonFunction';
import VideoDuration from 'shared/VideoDuration';
import VideoAutoMessage from 'shared/VideoAutoMessage';

export default props => {
  const { message, id, compactView, mIndex, totalMessage, isPlaceholder, draggableId } = props;

  const onClickMessage = () => {
    if (typeof props.onViewDetail === 'function' && !isPlaceholder) {
      props.onViewDetail(message);
    }
  };

  const renderAnnouncementMedia = () => {
    const { attachment, attachment_type, attachment_thumbnail } = message;
    const renderDuration = !compactView && !isPlaceholder;
    let media = null;

    if (attachment) {
      if (attachment_type.includes('image')) {
        media = <S3ImageWithFallback src={[attachment_thumbnail || '', attachment || '']} noSizeLimit />;
      } else {
        media = (
          <>
            <VideoAutoMessage message={message} renderDuration={renderDuration} compactView={compactView} />
          </>
        );
      }
    }

    return media ? <div className="autoMessage--announcement__media">{media}</div> : null;
  };

  if (draggableId) {
    return (
      <Draggable draggableId={draggableId} index={mIndex}>
        {provided => (
          <S.MessageWrapper
            className="autoMessage autoMessage--announcement"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={onClickMessage}
          >
            <S.MessageContainer className="autoMessage__container">
              <Header
                message={message}
                mIndex={mIndex}
                isPlaceholder={false}
                totalMessage={totalMessage}
                onEdit={props.onEdit}
                onDelete={props.onDelete}
                onCopy={props.onCopy}
                onArrange={props.onArrange}
              />
              <S.MessageContent className="autoMessage__content">
                {renderAnnouncementMedia()}
                <div className="autoMessage__title">{message.subject}</div>
              </S.MessageContent>
            </S.MessageContainer>
          </S.MessageWrapper>
        )}
      </Draggable>
    );
  }

  return (
    <S.MessageWrapper id={id} className="autoMessage autoMessage--announcement">
      <S.MessageContainer className="autoMessage__container">
        <Header message={message} mIndex={mIndex} isPlaceholder={isPlaceholder} totalMessage={totalMessage} />
        <S.MessageContent className="autoMessage__content">
          {renderAnnouncementMedia()}
          <div className="autoMessage__title">{message.subject}</div>
        </S.MessageContent>
      </S.MessageContainer>
    </S.MessageWrapper>
  );
};
