// libs
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { Prompt } from 'react-router';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import identity from 'lodash/identity';

// actions
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import { setSavedSurvey } from 'redux/survey-contact/actions';
import { getPackage, updatePackageSettings, updatePackageSurveyFlag } from 'redux/package-detail/actions';

// shared
import { Button } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';

// components
import PackageLayout from '../PackageLayout';
import Card from './components/Card';
import CustomPopup from 'components/Package/components/CustomPopup';
import SurveyDetailModal from './components/SurveyDetailModal';
import SurveyPreviewDetailModal from './components/SurveyPreviewDetailModal';

// constants
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_TIME,
  PURCHASE_OPTIONS,
  PURCHASE_VALUE,
  WAITLIST_OPTION,
  WAITLIST_VALUE,
} from './constants';
import { CDN_URL, C_KEY_TOKEN, ERROR_CODE_PACKAGE_SETTING } from 'constants/commonData';
import { genTokenFromMarketplace, getCookie } from 'utils/commonFunction';

// assets
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/popup-warning-icon.svg';

// styles
import * as S from './styles';

const MODES = {
  EDIT: 'edit',
  OVERVIEW: 'overview',
};

const ARCHIVE_OPTIONS = [
  {
    key: 'is_archive_coach_manual_cancel',
    desc: 'Archive clients when the coach manually cancels a subscription',
    label: 'When the coach manually cancels a subscription',
  },
  {
    key: 'is_archive_subscription_ended',
    desc: 'Archive clients when the subscription period ends',
    label: 'When the subscription period ends',
  },
  {
    key: 'is_archive_client_self_cancel',
    desc: 'Archive clients when the client self-cancels a subscription',
    label: 'When the client self-cancels a subscription',
  },
];

const ARCHIVE_CLIENT_TRIAL_OPTIONS = [
  {
    key: 'is_archive_on_trial_cancellation',
    desc: 'Archive client when cancelling a package within the trial period',
    label: 'When cancelling a package within the trial period',
  },
];

const TRIAL_CONFIGURATION_OPTIONS = [
  {
    key: 'allow_trial_once',
    desc: `Allow client to sign up for a trial <b class="bold-text">once</b>`,
    label: `Allow client to sign up for a trial <b class="bold-text">once</b>`,
    uncheckedDesc: `Allow client to sign up for a trial <b class="bold-text">multiple times</b>`,
    isCustomLabel: true,
    isTrialConfiguration: true,
  },
  {
    key: 'is_trial_end_reminder',
    desc: 'Send reminder <b class="bold-text">1 day before</b> end of trial',
    label: `Send reminder <b class="bold-text">1 day before</b> end of trial`,
    uncheckedDesc: `<b class="bold-text">Do not send</b> reminder 1 day before end of trial`,
    isCustomLabel: true,
    isTrialConfiguration: true,
  },
];

const REACTIVATE_CLIENTS = [
  {
    key: 'is_reactive_client',
    desc: 'Reactivate archived clients when they activate a package',
    label: `When the archived client activate a package`,
    isCustomLabel: true,
  },
];

const OVERDUE_REMINDER_SETTINGS = [
  {
    key: 'is_overdue_reminder_immediately',
    label: `Send email <b>&nbsp;immediately&nbsp;</b> if payment fails`,
    defaultChecked: true,
    isCustomLabel: true,
  },
  {
    key: 'overdue_reminder_after_3_day',
    desc: 'Send email after <b>3 days</b> if invoice is unpaid',
    label: `Send email after <b>3 days</b> if invoice is unpaid`,
    isCustomLabel: true,
  },
  {
    key: 'overdue_reminder_after_5_day',
    desc: 'Send email after <b>5 days</b> if invoice is unpaid',
    label: 'Send email after <b>5 days</b> if invoice is unpaid',
    isCustomLabel: true,
  },
];

function PackageSettings({
  workingPackage,
  pushAction,
  toggleConfirmModal,
  updatePackageSettings,
  isSubmitting,
  group,
  permission,
  match,
  isError,
  toggleModal,
  getPackage,
  savedSurvey,
  updatePackageSurveyFlag,
  setSavedSurvey,
  cloudfrontList,
  user,
  location,
  dispatch,
  toggleSecondModal,
}) {
  const waitlistTimeRef = useRef(null);
  const purchaseLimitRef = useRef(null);
  const surveyRef = useRef(null);

  const [mode, setMode] = useState(MODES.OVERVIEW);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [settings, setSettings] = useState({});
  const [groupTagSettings, setGroupTagSettings] = useState([]);
  const [archiveSettings, setArchiveSettings] = useState({});
  const [trialReminderSettings, setTrialReminderSettings] = useState({
    is_trial_end_reminder: false,
    allow_trial_once: false,
  });
  const [reactivateClientSettings, setReactivateClientSettings] = useState({ is_reactive_client: false });
  const [overdueReminderSettings, setOverdueReminderSettings] = useState({
    overdue_reminder_after_3_day: false,
    overdue_reminder_after_5_day: false,
  });
  const [waitlistTimeErrorMessage, setWaitlistTimeErrorMessage] = useState('');
  const [surveyErrorMessage, setSurveyErrorMessage] = useState('');
  const [onboardingFlow, setOnboardingFlow] = useState(null);

  const isMP = get(match, 'url', '').includes('marketplace');
  const packageId = get(match, 'params.packageId', null);
  const data = useMemo(() => (workingPackage ? workingPackage.toJS() : null), [workingPackage]);
  const groupClients = useMemo(() => orderBy(group, ['name'], ['asc']), [group]);
  const packageName = get(data, 'invoice_display_name', '').trim();
  const limitPurchase = get(data, 'limit_purchase', -1);
  const totalSold = get(data, 'total_sold', 0);
  const isAllowed = get(data, 'is_allow_client_self_cancellation');
  const isRemoved = get(data, 'is_unassign_asset_self_cancellation');
  const isOneTime = get(data, 'price.type') === 'one_time';
  const dataGroups = get(data, 'groups');
  const isManualCancel = get(data, 'is_archive_coach_manual_cancel', false);
  const isSubscriptionEnded = get(data, 'is_archive_subscription_ended', false);
  const isSelfCancel = get(data, 'is_archive_client_self_cancel', false);
  const isEnableTrial = get(data, 'price.enable_trial', false);
  const isArchiveClientCancelledTrial = get(data, 'is_archive_on_trial_cancellation', false);
  const isTrialEndReminder = get(data, 'is_trial_end_reminder', false);
  const isReactivateClient = get(data, 'is_reactive_client', false);
  const isTrialOnce = get(data, 'allow_trial_once', false);
  const onboardingFlowItem = get(data, 'onboarding_flow_data', null);
  const onboardingFlowId = get(data, 'onboarding_flow_id', null);
  const isDraft = get(data, 'status') === 'draft';

  const isOverdue3DaysReminder = get(data, 'overdue_reminder_after_3_day', false);
  const isOverdue5DaysReminder = get(data, 'overdue_reminder_after_5_day', false);

  // MP;
  const packagePurchaseOption = get(data, 'package_purchase_option', null);
  const timezone = get(packagePurchaseOption, 'timezone', '') || get(data, 'trainer.timezone', '');
  const dateFormat = get(packagePurchaseOption, 'date_format', '') || get(data, 'trainer.date_format', '');
  const timeLimit = get(packagePurchaseOption, 'time_limit', '');
  const totalOffered = get(data, 'total_offered', 0);
  const hasOfferedPackageWishlist = get(packagePurchaseOption, 'has_offered_package_wishlist', false);
  const timeLimitFormat = DateTime.fromISO(timeLimit, { zone: timezone }).toISO({ includeOffset: false });
  const hasSurveyList = get(data, 'has_survey_list', false);
  const purchaseOption = get(packagePurchaseOption, 'type', PURCHASE_OPTIONS[0].value);
  const waitlistValue = get(packagePurchaseOption, 'waitlist_type') || WAITLIST_OPTION[0].value;
  const waitlistDate = timeLimit ? moment(timeLimitFormat).format(dateFormat || DATE_FORMAT) : '';
  const isTurnOnSurvey = get(packagePurchaseOption, 'is_turn_on_survey', false);
  const isTurnOnInbox = get(packagePurchaseOption, 'is_turn_on_inbox', false);
  const survey = get(packagePurchaseOption, 'survey', '');
  const currentSurveyId = get(packagePurchaseOption, 'survey_id', null);
  const savedSurveyId = get(savedSurvey, 'id', null);

  const waitlistTimeOption = {
    label: moment(timeLimitFormat).format('hh:mm A'),
    value: moment(timeLimitFormat).format('HH:mm'),
    displayed: moment(timeLimitFormat).format('h:mm A'),
  };
  const waitlistTime = timeLimit ? waitlistTimeOption : DEFAULT_TIME;
  const isNoLimit = get(settings, 'limit_purchase') === -1;
  const isWaitlist = get(settings, 'purchase_option', '') === PURCHASE_VALUE.WAITLIST;
  const isContact = get(settings, 'purchase_option', '') === PURCHASE_VALUE.CONTACT;

  const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;

  // MP
  const getWarningPurchaseLimitContent = ({ total_sold = 0, total_offered = 0 }) => {
    if (!!total_offered) {
      return `Package limit should be greater than or equal to the purchased packages and offered packages.<br />Total sold: <b>${total_sold}</b>&ensp;Total offered: <b>${total_offered}</b>`;
    }
    return `Package limit should be greater than or equal to the purchased packages.<br />Total sold: <b>${total_sold}</b>`;
  };

  const archiveClientOptions = useMemo(() => {
    if (!isEnableTrial && !isOneTime) return ARCHIVE_OPTIONS;
    if (!isEnableTrial && isOneTime) return [];

    const options = isOneTime ? ARCHIVE_CLIENT_TRIAL_OPTIONS : ARCHIVE_OPTIONS.concat(ARCHIVE_CLIENT_TRIAL_OPTIONS);

    return options;
  }, [data]);

  const errorMessage = useMemo(() => {
    if (settings.limit_purchase === 0) {
      return 'Minimum value is 1';
    }

    const newTotalSold = isMP ? totalSold + totalOffered : totalSold;

    if (settings.limit_purchase > 0 && settings.limit_purchase < newTotalSold) {
      const message =
        isMP && hasOfferedPackageWishlist
          ? `Limit can not be less than your existing purchased and offered packages (Total: ${newTotalSold})`
          : `Limit can not be less than your existing purchases (Total: ${newTotalSold})`;
      return message;
    }

    return '';
  }, [settings, limitPurchase, totalSold]);

  const search = get(location, 'search', '');
  const pathname = get(location, 'pathname', '');

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (isMP && token) {
      if (search.includes('redirect-platform')) {
        const replacePathname = search.includes('&') && pathname + search.replace('redirect-platform&', '');
        genTokenFromMarketplace({ token, user, pathname, search, dispatch, replacePathname });
      } else if (search.includes('edit')) {
        handleEdit();
        dispatch(replace(pathname));
      } else if (search.includes('preview-survey') && !!survey) {
        toggleModal(true, <SurveyPreviewDetailModal survey={survey} onClose={() => {}} />);
        dispatch(replace(pathname));
      }
    }
  }, [pathname, search, survey]);

  useEffect(() => {
    setSettings({
      limit_purchase: limitPurchase,
      invoice_display_name: packageName,
      is_allow_client_self_cancellation: isAllowed,
      is_unassign_asset_self_cancellation: isRemoved,
      // MP
      ...(isMP && {
        purchase_option: purchaseOption,
        waitlist_value: waitlistValue,
        waitlist_date: waitlistDate,
        waitlist_time: waitlistTime,
        waitlist_value_previous: waitlistValue,
        is_turn_on_survey: isTurnOnSurvey,
        time_zone: timezone,
        is_turn_on_inbox: isTurnOnInbox,
      }),
    });
  }, [
    limitPurchase,
    packageName,
    isAllowed,
    isRemoved,
    purchaseOption,
    waitlistValue,
    waitlistDate,
    waitlistTime.value,
    isTurnOnInbox,
  ]);

  useEffect(() => {
    if (savedSurvey && packagePurchaseOption) {
      const isSurveyChanged = savedSurveyId !== currentSurveyId;
      if (isSurveyChanged) {
        const data = {
          package_purchase_option: {
            ...packagePurchaseOption,
            survey_id: savedSurvey.id,
            survey: savedSurvey,
          },
          has_survey_list: true,
        };
        updatePackageSurveyFlag(data);
      }
    }

    if ((data || {}).hash_id) {
      getPackage(data.hash_id);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_PACKAGE_GROUP_SETTINGS) {
      if (!isEmpty(group)) {
        const newData = map(dataGroups, it => find(group, { _id: it._id }) || it);
        setGroupTagSettings(newData);
      } else {
        setGroupTagSettings(dataGroups);
      }
    }
  }, [dataGroups, group, permission]);

  useEffect(() => {
    if (onboardingFlowId) {
      setOnboardingFlow({ ...onboardingFlowItem, _id: onboardingFlowId });
    } else {
      setOnboardingFlow(null);
    }
  }, [onboardingFlowItem, onboardingFlowId]);

  useEffect(() => {
    setReactivateClientSettings({
      is_reactive_client: isReactivateClient,
    });
  }, [isReactivateClient]);

  useEffect(() => {
    if (!isOneTime) {
      setArchiveSettings(prevState => ({
        ...prevState,
        is_archive_coach_manual_cancel: isManualCancel,
        is_archive_subscription_ended: isSubscriptionEnded,
        is_archive_client_self_cancel: isSelfCancel,
      }));
    }

    if (isEnableTrial) {
      setArchiveSettings(prevState => ({
        ...prevState,
        is_archive_on_trial_cancellation: isArchiveClientCancelledTrial,
      }));
    }
  }, [isManualCancel, isSubscriptionEnded, isSelfCancel, isArchiveClientCancelledTrial, permission, isOneTime]);

  useEffect(() => {
    if (isEnableTrial) {
      setTrialReminderSettings({ is_trial_end_reminder: isTrialEndReminder, allow_trial_once: isTrialOnce });
    }
  }, [isEnableTrial, isTrialEndReminder, isTrialOnce]);

  useEffect(() => {
    setOverdueReminderSettings({
      overdue_reminder_after_3_day: isOverdue3DaysReminder,
      overdue_reminder_after_5_day: isOverdue5DaysReminder,
    });
  }, [isOverdue5DaysReminder, isOverdue3DaysReminder]);

  useEffect(() => {
    if (hasChanges) {
      onbeforeunload = e => {
        e.preventDefault();
        e.returnValue = '';
      };
    }

    return () => {
      onbeforeunload = null;
    };
  }, [hasChanges]);

  const handleChangeText = e => {
    setSettings({ ...settings, invoice_display_name: e.target.value });
    setHasChanges(e.target.value !== packageName);
  };

  const handleChangeNumber = useCallback(
    values => {
      const newValue = values.value;
      setSettings(prevStates => ({ ...prevStates, limit_purchase: newValue ? parseInt(newValue) : -1 }));
      setHasChanges(newValue !== limitPurchase);
    },
    [limitPurchase],
  );

  const handleChangeStatus = data => {
    setSettings({
      ...settings,
      is_allow_client_self_cancellation: data.value,
      is_unassign_asset_self_cancellation: data.value,
    });
    setHasChanges(data.value !== isAllowed);
  };

  const handleToggleRemoveProduct = () => {
    setSettings({ ...settings, is_unassign_asset_self_cancellation: !settings.is_unassign_asset_self_cancellation });
    setHasChanges(true);
  };

  // MP
  useEffect(() => {
    if (packageId && packageId !== 'create' && isMP) {
      getPackage(packageId, isMP);
    }
  }, []);

  const comparePurchaseOptionData = newData => {
    // this survey_id is old data to compare
    const survey_id = get(packagePurchaseOption, 'survey.id', null);
    const currentData = pick(pickBy(packagePurchaseOption, identity), [
      'type',
      'waitlist_type',
      'time_limit',
      'is_turn_on_survey',
      'is_turn_on_inbox',
    ]);
    if (isEqual(newData, { ...currentData, survey_id })) {
      return;
    }
    return newData;
  };

  const handleResetSurveyData = () => {
    if (!isEmpty(savedSurvey) && isMP) {
      const isSurveyChanged = currentSurveyId !== savedSurvey.id;
      let resolveSurvey = savedSurvey;

      if (isSurveyChanged) {
        resolveSurvey = {
          id: currentSurveyId,
          survey_name: get(survey, 'survey_name', null),
          questions: get(survey, 'questions', []),
        };
      }

      const data = {
        package_purchase_option: {
          ...packagePurchaseOption,
          survey_id: resolveSurvey.id,
          survey: {
            ...packagePurchaseOption.survey,
            survey_name: resolveSurvey.survey_name,
            questions: resolveSurvey.questions,
          },
        },
        has_survey_list: true,
      };
      updatePackageSurveyFlag(data);
      setSettings({ ...settings, is_turn_on_survey: isTurnOnSurvey });
    }
  };

  const handleErrorPurchaseOption = data => {
    const isErrorPurchaseLimit = get(data, 'errorCode', '') === ERROR_CODE_PACKAGE_SETTING.PURCHASE_LIMIT_ERROR;
    const isErrorApplySurvey = get(data, 'errorCode', '') === ERROR_CODE_PACKAGE_SETTING.APPLY_SURVEY_ERROR;
    const isErrorSurveyNotFound = get(data, 'errorMessage', '').includes('Survey not found.');

    switch (true) {
      case isErrorApplySurvey:
        handleCannotApplied();
        break;
      case isErrorSurveyNotFound:
        handleSurveyNotFound();
        break;

      case isErrorPurchaseLimit:
        toggleModal(
          true,
          <CustomPopup
            isShowCancelButton={false}
            title="Warning"
            icon={<WarningIcon />}
            onToggle={() => toggleModal(false)}
            content={getWarningPurchaseLimitContent(data)}
          />,
        );
        break;

      default:
        handleResetSurveyData();
        toggleModal(
          true,
          <CustomPopup
            isShowCancelButton={false}
            title="Warning"
            icon={<WarningIcon />}
            onToggle={() => toggleModal(false)}
            content={`There are remaining requests waiting for your response. Please process the requests before changing the purchase options.`}
          />,
        );
        break;
    }
    setSettings(prev => ({
      ...prev,
      limit_purchase: limitPurchase,
      invoice_display_name: packageName,
      is_allow_client_self_cancellation: isAllowed,
      is_unassign_asset_self_cancellation: isRemoved,
      // MP
      purchase_option: purchaseOption,
      waitlist_value: waitlistValue,
      waitlist_value_previous: waitlistValue,
      waitlist_date: waitlistDate,
      waitlist_time: waitlistTime,
    }));
    handleEdit();
  };

  const handleCannotApplied = () => {
    const package_purchase_option = {
      ...packagePurchaseOption,
      survey_id: null,
      survey: null,
    };
    updatePackageSurveyFlag({ package_purchase_option });
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="error"
        noIcon
        newStyle
        hasCloseIcon
        hideCancelButton
        isPressEsc
        confirmButtonTitle="OK"
        shouldCloseAfterConfirm
        onConfirm={() => {}}
        title={
          <>
            <WarningIcon />
            <span>Cannot be applied</span>
          </>
        }
        content={
          <p>
            This survey is archived and cannot be applied for a package.
            <br />
            Please unarchive the survey to save the setting.
          </p>
        }
      />,
    );
  };

  const handleSurveyNotFound = () => {
    const package_purchase_option = {
      ...packagePurchaseOption,
      survey_id: null,
      survey: null,
    };
    updatePackageSurveyFlag({ package_purchase_option });
    toggleConfirmModal(
      true,
      <S.ConfirmModal
        className="error"
        noIcon
        newStyle
        hasCloseIcon
        hideCancelButton
        isPressEsc
        confirmButtonTitle="OK"
        shouldCloseAfterConfirm
        onConfirm={() => {}}
        title={
          <>
            <WarningIcon />
            <span>Cannot be applied</span>
          </>
        }
        content={
          <p>
            This survey is deleted and cannot be applied for a package.
            <br />
            Please select or create another survey.
          </p>
        }
      />,
    );
  };

  const handleLogicSaveMP = () => {
    const isWaitlistTime = get(settings, 'waitlist_value', null) === WAITLIST_VALUE.TIME;

    let newWaitlistDate = get(settings, 'waitlist_date', '');
    const newWaitlistTime = get(settings, 'waitlist_time.value', '');
    if (dateFormat !== DATE_FORMAT) {
      newWaitlistDate = moment(newWaitlistDate, dateFormat).format(DATE_FORMAT);
    }
    const newDate = moment(newWaitlistDate + ' ' + newWaitlistTime);
    const time_limit = !!newWaitlistDate ? newDate.format(DATE_TIME_FORMAT) : '';
    const is_turn_on_survey = get(settings, 'is_turn_on_survey', false);
    const time_zone = get(settings, 'time_zone', '');
    const todayWithTimezone = !!time_zone ? moment(moment().tz(time_zone).format(DATE_TIME_FORMAT)) : '';
    const isErrorDate = !!time_zone ? moment(newDate).isSameOrBefore(todayWithTimezone, 'minute') : '';

    const isErrorSurvey = isContact && is_turn_on_survey && !currentSurveyId;
    const isErrorDateTime = !!(isWaitlist && isWaitlistTime && (!get(settings, 'waitlist_date', '') || isErrorDate));
    const isErrorPurchaseLimit =
      isWaitlist && get(settings, 'waitlist_value', '') === WAITLIST_VALUE.QUANTITY && isNoLimit;
    const is_turn_on_inbox = get(settings, 'is_turn_on_inbox', false);

    return {
      isErrorDate,
      isErrorSurvey,
      isErrorDateTime,
      isErrorPurchaseLimit,
      timezone: time_zone,
      timeLimit: time_limit,
      isTurnOnSurvey: is_turn_on_survey,
      isTurnOnInbox: is_turn_on_inbox,
    };
  };

  const handleHideButtonSaveMP = () => {
    if (!isMP) {
      return false;
    }
    const { isErrorDateTime, isErrorSurvey, isErrorPurchaseLimit } = handleLogicSaveMP();
    return !!(isErrorDateTime || isErrorSurvey || isErrorPurchaseLimit);
  };

  const handleErrorWaitlistDate = isErrorDate => {
    const timeErrorMessage = isErrorDate
      ? 'The setting time should be later than the current time'
      : 'This field is required';
    setWaitlistTimeErrorMessage(timeErrorMessage);
    setTimeout(() => {
      waitlistTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 300);
  };

  const handleErrorSurvey = () => {
    setSurveyErrorMessage('Please create or select a survey');
    setTimeout(() => {
      surveyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, 300);
  };

  const handleSave = () => {
    // MP
    let time_limit = '',
      time_zone = '',
      is_turn_on_survey = false,
      is_turn_on_inbox = true;
    if (isMP) {
      const {
        timezone,
        timeLimit,
        isTurnOnSurvey,
        isErrorDateTime,
        isErrorDate,
        isErrorPurchaseLimit,
        isErrorSurvey,
        isTurnOnInbox,
      } = handleLogicSaveMP();
      time_zone = timezone;
      time_limit = timeLimit;
      is_turn_on_survey = isTurnOnSurvey;
      is_turn_on_inbox = isTurnOnInbox;
      if (isErrorDateTime) {
        handleErrorWaitlistDate(isErrorDate);
        return;
      }
      if (isErrorPurchaseLimit) {
        handleOpenPurchaseLimitWarningPopup();
        return;
      }

      if (isErrorSurvey) {
        handleErrorSurvey();
        return;
      }
    }
    setMode(MODES.OVERVIEW);
    setIsEditing(false);
    setHasChanges(false);
    let newSettings = settings;
    if (process.env.REACT_APP_ENABLE_PACKAGE_GROUP_SETTINGS) {
      const groups = map(groupTagSettings, it => pick(it, ['_id', 'name']));
      newSettings = { ...newSettings, groups };
    }
    const archive = pick(archiveSettings, [
      'is_archive_coach_manual_cancel',
      'is_archive_subscription_ended',
      'is_archive_client_self_cancel',
      'is_archive_on_trial_cancellation',
    ]);
    const shouldUpdateNewSurvey = currentSurveyId !== savedSurveyId && isContact && is_turn_on_survey;
    let surveyId = shouldUpdateNewSurvey ? currentSurveyId : savedSurveyId;

    if (isContact && !is_turn_on_survey && !currentSurveyId) {
      surveyId = null;
    }

    const purchaseOptionFormat = {
      type: get(settings, 'purchase_option', ''),
      ...(surveyId && {
        survey_id: surveyId,
      }),
      ...(isWaitlist && {
        waitlist_type: get(settings, 'waitlist_value', ''),
        ...(!!time_limit && { time_limit, time_zone }),
      }),
      ...(isContact && {
        is_turn_on_survey,
      }),
      is_turn_on_inbox,
    };
    const purchaseOptionData = comparePurchaseOptionData(purchaseOptionFormat);

    newSettings = {
      ...newSettings,
      ...archive,
      ...trialReminderSettings,
      ...overdueReminderSettings,
      ...reactivateClientSettings,
      ...(isMP && {
        package_purchase_option: purchaseOptionData,
      }),
      onboarding_flow_id: (onboardingFlow || {})._id,
    };
    updatePackageSettings &&
      !isEmpty(newSettings) &&
      updatePackageSettings(get(data, 'id'), newSettings, data, isMP, handleErrorPurchaseOption);
  };

  const handleEdit = () => {
    setMode(MODES.EDIT);
    setIsEditing(true);
    setHasChanges(false);

    if (!isEmpty(survey)) {
      setSavedSurvey(survey);
    }
  };

  const handleChangeGroupTagSettings = options => {
    if (isEditing) {
      setGroupTagSettings(options);
      setHasChanges(true);
    }
  };

  const handleChangeArchiveSettings = ({ key }) => {
    if (isEditing) {
      setArchiveSettings(it => ({ ...it, [key]: !it[key] }));
      setHasChanges(true);
    }
  };

  const handleChangeReminderEndTrialSettings = option => {
    const key = get(option, 'key');

    if (isEditing) {
      setTrialReminderSettings(it => ({ ...it, [key]: !it[key] }));
      setHasChanges(true);
    }
  };

  const handleChangeReactivateClient = () => {
    if (isEditing) {
      setReactivateClientSettings({ is_reactive_client: !get(reactivateClientSettings, 'is_reactive_client') });
      setHasChanges(true);
    }
  };

  const handleOverdueChangeReminderSettings = option => {
    const key = get(option, 'key');

    if (isEditing) {
      setOverdueReminderSettings(it => ({ ...it, [key]: !it[key] }));
      setHasChanges(true);
    }
  };

  const ActionButtons = () => {
    switch (mode) {
      case MODES.EDIT:
        return (
          <Button
            disabled={
              !settings.invoice_display_name.trim() ||
              errorMessage ||
              isSubmitting ||
              !!waitlistTimeErrorMessage ||
              !!surveyErrorMessage
            }
            className="packageActions__saveBtn"
            onClick={handleSave}
          >
            <SaveIcon />
            Save
          </Button>
        );
      case MODES.OVERVIEW:
        return (
          <Button disabled={isSubmitting} className="packageActions__saveBtn" onClick={handleEdit}>
            <EditIcon />
            Edit
          </Button>
        );
      default:
        break;
    }
  };

  const handleConfirmSaveChange = async pathname => {
    handleSave();
    await setHasChanges(false);
    // keep this page if there are errors
    if (isError) {
      return;
    }
    pushAction(pathname);
  };

  const handleCloseAnyway = async pathname => {
    await setHasChanges(false);
    pushAction(pathname);
  };

  const handleBlockedNavigation = nextLocation => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Save before exit?"
        content="You have made changes that have not been saved yet. Would you like save changes before leaving?"
        onConfirm={() => handleConfirmSaveChange(nextLocation.pathname)}
        confirmButtonTitle="Save"
        cancelButtonTitle="Close anyway"
        confirmButtonClass="btn-purple"
        headerIcon={`${CDN_URL}/images/warning_purple_Icon.svg`}
        onDeny={() => handleCloseAnyway(nextLocation.pathname)}
        newStyle
        hasCloseIcon
        disableConfirmButton={
          !settings.invoice_display_name || errorMessage || isSubmitting || handleHideButtonSaveMP()
        }
      />,
    );
    return false;
  };

  // MP
  const handleChangePurchaseOption = value => {
    handleChangeSurvey(true);
    const isWaitlist = value === PURCHASE_VALUE.WAITLIST;
    setWaitlistTimeErrorMessage('');
    const newData = {
      ...settings,
      purchase_option: value,
      ...(isWaitlist && {
        waitlist_value: WAITLIST_OPTION[0].value,
        waitlist_date: null,
        waitlist_time: DEFAULT_TIME,
      }),
    };
    setSettings(newData);
  };

  const handleChangeWaitlistValue = value => {
    setHasChanges(true);
    setWaitlistTimeErrorMessage('');
    setSettings({
      ...settings,
      waitlist_value: value,
      waitlist_value_previous: get(settings, 'waitlist_value', null),
    });
  };

  const handleChangeWaitlistDate = value => {
    setHasChanges(true);
    const newDate = moment(value).format(dateFormat);
    setWaitlistTimeErrorMessage('');
    setSettings({ ...settings, waitlist_date: newDate });
  };

  const handleChangeWaitlistTime = value => {
    setHasChanges(true);
    setWaitlistTimeErrorMessage('');
    setSettings({ ...settings, waitlist_time: value });
  };

  const handleChangeTimezone = timezone => {
    setHasChanges(true);
    setWaitlistTimeErrorMessage('');
    const value = get(timezone, 'value', '');
    setSettings({ ...settings, time_zone: value });
  };

  const handleEditPurchaseLimit = type => {
    const isQuantity =
      get(settings, 'waitlist_value', '') === WAITLIST_VALUE.QUANTITY || type === WAITLIST_VALUE.QUANTITY;
    if (isQuantity && isNoLimit) {
      const purchaseLimitElement = document.getElementById('purchase-limit').focus();
      setTimeout(() => {
        purchaseLimitRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
        if (purchaseLimitElement) {
          purchaseLimitElement.focus();
        }
      }, 300);
      toggleModal(false);
    }
  };

  const handleClosePurchaseLimit = waitlistValuePrevious => {
    setSettings({
      ...settings,
      waitlist_value: waitlistValuePrevious,
    });
  };

  const handleChangeEnableSurvey = isTurnOn => {
    if (isTurnOn && !hasSurveyList) {
      toggleModal(true, <SurveyDetailModal isCreateNew isShowBack={false} onClose={() => {}} />);
    }
    setHasChanges(true);
    setSurveyErrorMessage('');
    setWaitlistTimeErrorMessage('');
    setSettings({ ...settings, is_turn_on_survey: isTurnOn });
  };

  const handleChangeEnableInbox = event => {
    const checked = get(event, 'target.checked', false);
    setHasChanges(true);
    setSettings({ ...settings, is_turn_on_inbox: checked });
  };

  const handleOpenPurchaseLimitWarningPopup = type => {
    const waitlistValuePrevious = get(settings, 'waitlist_value', null);
    toggleModal(
      true,
      <CustomPopup
        isShowCancelButton
        onToggle={() => toggleModal(false)}
        title="Warning"
        icon={<WarningIcon />}
        onClose={() => handleClosePurchaseLimit(waitlistValuePrevious)}
        onSubmit={() => handleEditPurchaseLimit(type)}
        buttonText="Edit the purchase limit"
        content="The purchase limit is <b>no limit</b>. Please edit the purchase limit to a specified number to turn on the Waitlist."
      />,
    );
  };

  const handleChangeSurvey = isChanged => {
    if (surveyErrorMessage) {
      setSurveyErrorMessage('');
    }
    setHasChanges(isChanged);
  };

  const handleChangeOnboardingFlow = (item, isChange) => {
    setOnboardingFlow(item);
    setHasChanges(isChange);
  };

  return (
    <S.Wrapper>
      <PackageLayout isMP={isMP} rightContent={<ActionButtons />}>
        <Prompt when={hasChanges} message={handleBlockedNavigation} />
        <S.SettingsWrapper>
          <Card
            name="Package Name"
            placeholderInput="Name your package"
            description="This will appear on invoices and purchase emails"
            value={settings.invoice_display_name}
            disabled={!isEditing}
            onChange={handleChangeText}
          />
          <Card
            name="Statement Descriptor"
            description="This will appear on a customer's credit card statement"
            value={get(data, 'statement_description', '')}
          />
          {hasPermissionMultiple && (
            <Card
              name="Onboarding Flow"
              description="Assign onboarding assets when the clients activate this package"
              type="select_onboarding_flow"
              value={onboardingFlow}
              onChange={handleChangeOnboardingFlow}
              toggleSecondModal={toggleSecondModal}
              toggleConfirmModal={toggleConfirmModal}
              cloudfrontList={cloudfrontList}
              disabled={!isEditing || isDraft}
              isDraft={isDraft}
            />
          )}
        </S.SettingsWrapper>
        <S.OtherSettingsWrapper>
          <S.OtherHeader>Other Settings</S.OtherHeader>
          <div ref={purchaseLimitRef}>
            <Card
              name="Purchase Limit"
              description="Set maximum number of purchases to sell"
              value={settings.limit_purchase}
              type="number"
              placeholderInput="Set number of purchases"
              isOther={true}
              disabled={!isEditing}
              limit={limitPurchase}
              purchased={totalSold}
              onChange={handleChangeNumber}
              hasError={errorMessage}
              errorMessage={errorMessage}
              isMP={isMP}
              totalOffered={totalOffered}
            />
          </div>

          {process.env.REACT_APP_ENABLE_PACKAGE_GROUP_SETTINGS && (
            <Card
              name="Group Tag"
              placeholderInput="Tag the client"
              description="Add clients to the groups when they activate the package"
              type="select_group_tag"
              disabled={!isEditing}
              onChange={handleChangeGroupTagSettings}
              options={groupClients}
              value={groupTagSettings}
              emptyMessage="No groups"
              emptySearchMessage="No groups found."
            />
          )}

          {isEnableTrial && (
            <Card
              name="Trial Configuration"
              description="Settings for Trial Packages"
              type="checkbox"
              disabled={!isEditing}
              onChange={handleChangeReminderEndTrialSettings}
              options={TRIAL_CONFIGURATION_OPTIONS}
              value={trialReminderSettings}
            />
          )}

          {!isOneTime ? (
            <Card
              name="Self-cancellation"
              description="Allow clients to cancel their recurring subscriptions"
              value={settings.is_allow_client_self_cancellation}
              type="dropdown"
              isOther={true}
              disabled={!isEditing}
              isRemove={settings.is_unassign_asset_self_cancellation}
              onChange={handleChangeStatus}
              onToggle={handleToggleRemoveProduct}
            />
          ) : null}

          {!isEmpty(archiveClientOptions) && (
            <Card
              name="Archive Active Clients"
              description="Automatically archive clients if the subscription is cancelled"
              type="checkbox"
              disabled={!isEditing}
              onChange={handleChangeArchiveSettings}
              options={archiveClientOptions}
              value={archiveSettings}
              emptyMessage="Not archive"
            />
          )}

          <Card
            name="Reactivate Clients"
            description="Automatically reactivate archived clients"
            type="checkbox"
            disabled={!isEditing}
            onChange={handleChangeReactivateClient}
            options={REACTIVATE_CLIENTS}
            value={reactivateClientSettings}
            emptyMessage="<b>Do not</b> reactivate archived clients when they activate a package"
          />

          {!isOneTime && (
            <Card
              name="Overdue Reminder"
              description="Send email reminder if recurring invoice is unpaid"
              type="checkbox"
              disabled={!isEditing}
              onChange={handleOverdueChangeReminderSettings}
              options={OVERDUE_REMINDER_SETTINGS}
              value={overdueReminderSettings}
              emptyMessage="Send email <b>immediately</b> if payment fails"
              checkedTitleDefault="Send email <b>immediately</b> if payment fails"
            />
          )}
        </S.OtherSettingsWrapper>
        {/* MP */}
        {isMP && (
          <S.OtherSettingsWrapper>
            <S.OtherHeader>Purchase Settings</S.OtherHeader>
            <Card
              name="Purchase Options"
              description={`Allow clients to purchase instantly, join your waitlist, or\nrequest an intro call.`}
              type="purchase_option"
              disabled={!isEditing}
              onChange={handleChangePurchaseOption}
              options={PURCHASE_OPTIONS}
              value={get(settings, 'purchase_option', '')}
            />
            {isWaitlist && (
              <div ref={waitlistTimeRef}>
                <Card
                  name="Waitlist Options"
                  description={`Activate the waitlist immediately, on a specified date,\nor after the purchase limit is reached.`}
                  type="purchase_option_waitlist"
                  disabled={!isEditing}
                  onChange={handleChangeWaitlistValue}
                  options={WAITLIST_OPTION}
                  value={get(settings, 'waitlist_value', null)}
                  waitlistDate={get(settings, 'waitlist_date', null)}
                  onChangeWaitlistDate={handleChangeWaitlistDate}
                  waitlistTime={get(settings, 'waitlist_time', null)}
                  onChangeWaitlistTime={handleChangeWaitlistTime}
                  purchaseLimit={get(settings, 'limit_purchase')}
                  onChangeTimezone={handleChangeTimezone}
                  timezone={get(settings, 'time_zone', '')}
                  waitlistTimeErrorMessage={waitlistTimeErrorMessage}
                  onOpenPurchaseLimitWarningPopup={handleOpenPurchaseLimitWarningPopup}
                  dateFormat={dateFormat}
                />
              </div>
            )}
            {isContact && (
              <div ref={surveyRef}>
                <Card
                  name="Intro Call Options"
                  description={`Collect contact information only, or require a brief \nsurvey first.`}
                  type="purchase_option_contact"
                  disabled={!isEditing}
                  onChange={handleChangeEnableSurvey}
                  value={get(settings, 'is_turn_on_survey', false)}
                  survey={survey}
                  surveyErrorMessage={surveyErrorMessage}
                  onChangeSurvey={handleChangeSurvey}
                />
              </div>
            )}
            <Card
              name="Inbox Settings"
              description={`Choose whether clients can message you about this \npackage before purchase.`}
              type="inbox"
              disabled={!isEditing}
              onChange={handleChangeEnableInbox}
              value={get(settings, 'is_turn_on_inbox', false)}
            />
          </S.OtherSettingsWrapper>
        )}
      </PackageLayout>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { packageDetail, group, permission, surveyContact, cloudfrontList },
    router: { location },
    user,
  } = state;

  return {
    workingPackage: packageDetail.get('workingPackage'),
    isSubmitting: packageDetail.get('isSubmitting'),
    isError: packageDetail.get('isError'),
    group: get(group, 'list', []),
    permission,
    savedSurvey: get(surveyContact, 'savedSurvey', {}),
    cloudfrontList,
    user,
    location,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  pushAction: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updatePackageSettings: bindActionCreators(updatePackageSettings, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getPackage: bindActionCreators(getPackage, dispatch),
  updatePackageSurveyFlag: bindActionCreators(updatePackageSurveyFlag, dispatch),
  setSavedSurvey: bindActionCreators(setSavedSurvey, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageSettings);
