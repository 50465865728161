import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import { toggleModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
});

export default connect(null, mapDispatchToProps)(Component);
