import React from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

function BaseCreatePopup(props) {
  return (
    <Modal
      open={true}
      className="modal--create-workout-template"
      closeOnDimmerClick={true}
      onClose={() => props.toggleModal(false)}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <Modal.Header>
        <S.Title>{props.title}</S.Title>
        <S.SubTitle>{props.subTitle}</S.SubTitle>
      </Modal.Header>
      <Modal.Content>
        <S.OptionContainer>
          {props.resources.map(resource => (
            <S.Option
              key={resource.id}
              onClick={() => {
                props.toggleModal(true, resource.component);
              }}
            >
              <S.OptionIcon src={resource.icon} />
              <S.OptionIcon src={resource.iconHover} className="hover" />
              <S.OptionLabel>{resource.title}</S.OptionLabel>
              <div>{resource.subtitle}</div>
            </S.Option>
          ))}
        </S.OptionContainer>
      </Modal.Content>
    </Modal>
  );
}

const actionCreators = { toggleModal };

export default connect(null, actionCreators)(BaseCreatePopup);
