import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

import { capitalizeFirstChar } from 'utils/commonFunction';

import { LIMIT_OPTION_CHARACTER } from './constants';
import { KEY_CODE } from 'constants/commonData';

import * as S from './style';

const OptionInput = props => {
  const {
    placeholder = '',
    initEditor,
    autoFocus = false,
    onInputChange,
    isDisable = false,
    disableEditPoll,
    className = '',
  } = props;

  const { isDuplicate = false, value = '', _id } = initEditor || {};

  const inputRef = useRef();
  const [isFocus, setIsFocus] = useState(false);
  const [valueInput, setValueInput] = useState(value);

  const handleInputChange = () => {
    if (inputRef.current) {
      const { value = '' } = inputRef.current || {};
      const isLimit = `${value}`.trim().length > LIMIT_OPTION_CHARACTER;
      if (isLimit) return;

      const finalValue = capitalizeFirstChar(value);
      inputRef.current.innerText = finalValue;

      setValueInput(finalValue);
      if (typeof onInputChange === 'function') {
        onInputChange(finalValue);
      }
    }
  };

  const handleFocus = () => {
    inputRef.current && inputRef.current.focus();
  };

  const handleOnFocus = () => {
    setIsFocus(true);
  };

  const handleOnBlur = () => {
    setIsFocus(false);
  };

  const onKeyPress = event => {
    if (event.keyCode === KEY_CODE.enter) {
      setIsFocus(false);
      inputRef.current && inputRef.current.blur();
    }
  };

  return (
    <S.OptionEditorWrapper
      className={classNames({ 'has-focus': isFocus, 'error-duplicate': isDuplicate }, className)}
      onClick={handleFocus}
      disableEditPoll={disableEditPoll}
    >
      <TextareaAutosize
        type="text"
        ref={inputRef}
        defaultValue={initEditor.value}
        value={valueInput}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        maxLength={LIMIT_OPTION_CHARACTER}
        className="option-input"
        disabled={disableEditPoll}
        id={`option-input-${_id}`}
        onKeyDown={onKeyPress}
      />
    </S.OptionEditorWrapper>
  );
};

export default OptionInput;
