import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as AutoflowConnected } from 'assets/icons/forum-advanced-settings-go-to-autoflow.svg';
import * as S from './style';

const AddLeaderboard = ({ onConnectAutoflow, connected, connected_autoflow, onCreateLeaderboard }) => {
  const [shouldOpen, setShouldOpen] = useState(true);
  const handleClose = () => setShouldOpen(false);

  return (
    <>
      {shouldOpen && (
        <S.AddNewLeaderboardWrapper connected={connected} className="addNewLeaderboard">
          {connected_autoflow ? (
            <S.AutoflowConnectedHeader>
              <div className="header-text">
                <AutoflowConnected />
                Autoflow Connected!
              </div>
              <S.CloseButton textOnly onClick={handleClose}>
                <CloseIcon />
              </S.CloseButton>
            </S.AutoflowConnectedHeader>
          ) : (
            <S.AddNewLeaderboardHeader>
              <div className="header-text">You can always connect an Autoflow in the Forum settings page</div>
              <S.CloseButton textOnly onClick={handleClose}>
                <CloseIcon />
              </S.CloseButton>
            </S.AddNewLeaderboardHeader>
          )}
          <div className="add-new-leaderboard-content">
            <S.LeaderboardCup />
            {connected_autoflow ? (
              <>
                <S.Title>Start a Leaderboard</S.Title>
                <S.SubTitle>Create a competitive leaderboard to motivate members of this Autoflow</S.SubTitle>
                <S.ConnectToAutoflowBtn textOnly onClick={onCreateLeaderboard}>
                  Create Leaderboard
                </S.ConnectToAutoflowBtn>
              </>
            ) : (
              <>
                <S.Title>Leaderboard</S.Title>
                <S.SubTitle>Create a competitive leaderboard to motivate members of this Autoflow</S.SubTitle>
                <S.ConnectToAutoflowBtn textOnly onClick={onConnectAutoflow}>
                  Connect to Autoflow
                </S.ConnectToAutoflowBtn>
              </>
            )}
          </div>
        </S.AddNewLeaderboardWrapper>
      )}
    </>
  );
};

export default AddLeaderboard;
