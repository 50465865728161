import React from 'react';
import { Radio } from 'semantic-ui-react';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import moment from 'moment';

import * as S from './styles';
import PurchaseDateTime from '../CustomDateTime';

import CustomTimeOption from '../CustomTimeOption';
import { WAITLIST_VALUE } from '../../constants';

import TimezoneSelect from 'shared/TimezoneSelect';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-outline.svg';
import { pluralize } from 'utils/commonFunction';

const RadioPurchaseOption = ({
  options = [],
  value = '',
  disabled = false,
  onChange,
  date,
  onChangeDate,
  time,
  onChangeTime,
  purchaseLimit,
  timezone,
  onChangeTimezone,
  errorMessage,
  onOpenPurchaseLimitWarningPopup,
  dateFormat,
}) => {
  const isNoLimit = purchaseLimit === -1;
  const isQuantity = value === WAITLIST_VALUE.QUANTITY;
  const isTime = value === WAITLIST_VALUE.TIME;
  const timeLabel = get(time, 'label', '');
  const timeDisplayed = get(time, 'displayed', '');
  const findOption = find(options, item => item.value === value) || options[0];

  const handleChange = value => {
    if (value === WAITLIST_VALUE.QUANTITY && isNoLimit) {
      onOpenPurchaseLimitWarningPopup(value);
    }
    onChange(value);
  };

  const handleMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName('exl__option--is-selected')[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
      }
    }, 10);
  };

  if (disabled && !errorMessage) {
    const beWord = purchaseLimit > 1 ? 'are' : 'is';
    const timezoneLabel = `(GMT ${moment.tz(timezone).format('Z')}) ${timezone}`;
    const purchaseLimitLabel = `After ${pluralize('package', purchaseLimit, true)} ${beWord} sold`;
    return (
      <S.WaitListPreviewWrapper>
        <S.PurchasePreviewValue fontWeight={600} color="#202353">
          {findOption.label}
        </S.PurchasePreviewValue>
        {isQuantity && purchaseLimit ? <S.PurchasePreviewValue>{purchaseLimitLabel}</S.PurchasePreviewValue> : null}
        {isTime && !!timeDisplayed && (
          <>
            <S.PurchasePreviewValue>{`After ${timeDisplayed}, ${date}`}</S.PurchasePreviewValue>
            <S.PurchasePreviewValue color="#A6A7BA" fontWeight={600}>
              {timezoneLabel}
            </S.PurchasePreviewValue>
          </>
        )}
      </S.WaitListPreviewWrapper>
    );
  }
  return (
    <S.RadioPurchaseOptionWrapper>
      <S.RadioWrapper>
        {map(options, (item, index) => {
          return (
            <div key={index}>
              <Radio
                label={item.label}
                name={item.name}
                value={item.value}
                onChange={() => handleChange(item.value)}
                checked={findOption.value === item.value}
              />
            </div>
          );
        })}
      </S.RadioWrapper>
      {isTime && (
        <S.PurchaseDateTimeWrapper>
          <div className="date-time-wrapper">
            <PurchaseDateTime
              value={date}
              onChange={onChangeDate}
              errorMessage={errorMessage}
              dateFormat={dateFormat}
              timezone={timezone}
            />
            <CustomTimeOption value={timeLabel} onChange={onChangeTime} errorMessage={errorMessage} />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <S.TimezoneWrapper className={!!errorMessage ? 'timezone-error' : ''}>
            <span className="timezone-label">Time zone</span>
            <TimezoneSelect
              onChange={onChangeTimezone}
              timezone={timezone}
              menuPlacement="top"
              components={{
                DropdownIndicator: () => <ArrowDown />,
                IndicatorSeparator: null,
              }}
              onMenuOpen={handleMenuOpen}
            />
          </S.TimezoneWrapper>
        </S.PurchaseDateTimeWrapper>
      )}
    </S.RadioPurchaseOptionWrapper>
  );
};

export default RadioPurchaseOption;
