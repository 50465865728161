import styled, { css } from 'styled-components';

const BaseText = css`
  color: #fff;
  font-family: 'Open Sans';
  font-weight: 400;
  line-height: 143%;
  font-size: 14px;
`;

export const TooltipWrapper = styled.div`
  ${BaseText}
  display: flex;
  flex-direction: column;
  background-color: #141414;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  padding: 16px 24px;
  border-radius: 8px;

  .label-verify-password-tooltip {
    font-weight: 600;
    margin-bottom: 12px;
  }

  .suggestion-row {
    display: flex;
    align-items: center;
    gap: 12px;

    .checkmark-icon {
      min-width: 20px;
      height: 20px;
    }
  }

  .suggestion-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    ${({ isOverlap }) =>
      isOverlap
        ? css`
            bottom: -5px;
            left: 46%;
          `
        : css`
            top: 46%;
            left: -5px;
          `};
    background-color: #141414;
  }
`;
