import styled, { css } from 'styled-components';
import { Wrapper, Header } from 'shared/Styles/ModalLayout';

export const SectionHeader = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

export const SectionContent = styled.div`
  padding-left: 18px;

  .data {
    margin-bottom: 15px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    :last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    &.current-usage {
      font-size: 11px;
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 15px;
      :last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    &.usage-label {
      margin-bottom: 11px;
    }
  }
`;

export const Section = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px dashed #e9e9e9;
  margin-bottom: 20px;
`;

export const DetailContainer = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 18px;

  ${Section} {
    :last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
`;

export const SummaryContainer = styled.div`
  .total {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 30px;

    .amount {
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
    }
  }

  .note {
    font-weight: normal;
    font-size: 11px;
    line-height: 100%;
    color: #686873;
  }
`;

export const ContentContainer = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #000000;
`;

export const NoBill = styled.div`
  margin-top: 60px;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #000000;
  opacity: 0.6;
`;

export const ContentWrapper = styled(Wrapper)`
  ${Header} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;

    .button--view-plan {
      background: #5158cf;
      border-radius: 5px;
      padding: 6px 35px;

      span {
        font-size: 13px;
        line-height: 18px;
        color: #ffffff;
        font-weight: bold;
        text-transform: none;
      }
    }

    .title {
      margin-bottom: 5px;
    }

    .sub-title {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #000000;
    }

    button {
      padding: 5px 35px;
    }
  }

  .highlight {
    font-weight: 600;
  }

  ${props =>
    props.starterPlan &&
    css`
      min-height: 340px;
    `}
`;
