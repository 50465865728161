import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingMealSkeleton = () => {
  const renderRowSkeleton = (_, idx) => (
    <ContentLoader
      key={`row-skeleton-${idx}`}
      viewBox="0 0 auto 193"
      width="100%"
      height={193}
      animate={false}
      backgroundColor="#F8F8F8"
      foregroundColor="#F8F8F8"
    >
      <rect x="0" y="39" rx="3" ry="3" width="70" height="15" />
      <rect x="0" y="73" rx="10" ry="10" width="100%" height="120" />
    </ContentLoader>
  );

  return Array(4).fill(null).map(renderRowSkeleton);
};

export default LoadingMealSkeleton;
