// libs
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isObject from 'lodash/isObject';

// actions
import { axiosInstance } from 'configs/request';

// components
import OnboardingFlowRemoveModal from 'shared/OnboardingFlowRemoveModal';
import CustomDropdownIndicator from './CustomDropdownIndicator';
import CustomMenuList from './CustomMenuList';
import CustomOption from './CustomOption';
import CustomSingleValue from './CustomSingleValue';
import ConfirmModal from 'components/OnboardingFlowDetail/components/OnboardingFlowSettings/ConfirmModal';

// constants
import { TYPE } from 'shared/TeammateDropdown';

// styles
import * as S from './style';
import ReactTooltip from 'react-tooltip';

const PER_PAGE = 20;
const URL_API_LIST_ONBOARDING_FLOW = '/api/onboarding-flows';

export const ONBOARDING_FLOW_SELECT_MODE = {
  INVITE_CLIENT: 'invite_client',
  PACKAGE: 'package',
};

export const ONBOARDING_NONE_OPTION = {
  _id: 'not_using',
  name: 'Not using Onboarding Flow',
  is_none_option: true,
};

const OnboardingFlowSelect = ({
  type = TYPE.SINGLE,
  value,
  isDraft,
  disabled,
  onChange,
  onConfirm,
  toggleModal,
  cloudfrontList,
  toggleSecondModal,
  toggleConfirmModal,
  mode = ONBOARDING_FLOW_SELECT_MODE.INVITE_CLIENT,
  ...props
}) => {
  const noOptionsMessage = 'No results found.';

  const [menuPlacement, setMenuPlacement] = useState(() => get(props, 'menuPlacement', 'auto'), [props]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [myDefault, setMyDefault] = useState({});
  const [workspaceDefault, setWorkspaceDefault] = useState({});
  const [isLoadingDefault, setIsLoadingDefault] = useState(false);
  const [isLoadingWorkspaceDefault, setIsLoadingWorkspaceDefault] = useState(false);

  const selectRef = useRef();
  const loadingRef = useRef(false);
  const isEndLoadOptions = useRef(false);
  const queryRef = useRef({
    text_search: '',
    page: 1,
    per_page: PER_PAGE,
    sort: -1,
    sorter: 'updated_at',
    statuses: ['publish'],
  });

  const finalOptions = useMemo(() => {
    let newValue = value;
    if (isObject(newValue) && !isNil(newValue)) {
      newValue = options.find(({ _id }) => (newValue || {})._id === _id);
    } else {
      newValue = options.find(({ _id }) => newValue === _id);
    }

    const current = !!newValue ? [ONBOARDING_NONE_OPTION, newValue] : [ONBOARDING_NONE_OPTION];
    const newArr = uniqBy([...current, ...options], '_id').filter(obj => Object.keys(obj).length > 0);
    return newArr;
  }, [options, value]);

  useEffect(() => {
    let ignore = false;
    initOptions(ignore);
    getDefaultOnboardingFlow();
    getWorkspaceOnboardingFlow();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (isLoading || loadingRef.current) return;

    const getDefaultId = () => {
      if (!isEmpty(myDefault)) return myDefault._id;
      if (!isEmpty(workspaceDefault)) return workspaceDefault._id;
      return undefined;
    };

    const defaultId = getDefaultId();
    const isEnabledSetAsDefault = (options || []).some(({ _id }) => _id === defaultId);
    const optionDefault = (options || []).find(({ _id }) => _id === defaultId);

    if (isEnabledSetAsDefault && isNil(value)) {
      handleChange(optionDefault);
    } else if (isNil(value)) {
      handleChange(ONBOARDING_NONE_OPTION);
    }
  }, [options, myDefault, workspaceDefault]);

  // Get my default
  const getDefaultOnboardingFlow = () => {
    setIsLoadingDefault(true);

    axiosInstance({
      url: 'api/onboarding-flows/default',
      method: 'GET',
    })
      .then(response => {
        const { data } = get(response, 'data');
        setMyDefault(data || {});
        setIsLoadingDefault(false);
      })
      .catch(error => {
        setMyDefault({});
        setIsLoadingDefault(false);
      });
  };

  // Get workspace default
  const getWorkspaceOnboardingFlow = () => {
    setIsLoadingWorkspaceDefault(true);

    axiosInstance({
      url: 'api/onboarding-flows/get-default-by-team',
      method: 'GET',
    })
      .then(response => {
        const { data } = get(response, 'data');
        setWorkspaceDefault(data || {});
        setIsLoadingWorkspaceDefault(false);
      })
      .catch(error => {
        setWorkspaceDefault({});
        setIsLoadingWorkspaceDefault(false);
      });
  };

  const processDefaultData = data => {
    return (data || []).map(item => {
      return {
        ...item,
        is_my_default: !(item || {}).is_default_team || undefined,
      };
    });
  };

  const initOptions = useCallback(async ignore => {
    try {
      loadingRef.current = true;
      setIsLoading(true);

      const params = {
        ...queryRef.current,
      };

      // Fetch data
      const res = await axiosInstance.get(URL_API_LIST_ONBOARDING_FLOW, { params });
      const data = get(res, 'data.data.data', []);
      const defaultData = processDefaultData(get(res, 'data.data.default_onboarding_flows', []));
      const newData = [...defaultData, ...data];
      const total = get(res, 'data.data.total', 0);

      if (ignore) return;

      loadingRef.current = false;
      isEndLoadOptions.current = data.length < PER_PAGE;

      setIsLoading(false);
      setOptions(uniqBy(newData, '_id'));
      setTotal(total + defaultData.length);
    } catch (error) {
      loadingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  const handleScroll = event => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && !isLoading.current && !isEndLoadOptions.current) {
      debounceScrollBottom();
    }
  };

  const handleScrollBottom = useCallback(async () => {
    try {
      if (loadingRef.current || isEndLoadOptions.current) return;
      loadingRef.current = true;
      setIsLoading(true);

      queryRef.current = {
        ...queryRef.current,
        page: queryRef.current.page + 1,
      };
      const params = queryRef.current;

      // Fetch data
      const res = await axiosInstance.get(URL_API_LIST_ONBOARDING_FLOW, { params });
      const data = get(res, 'data.data.data', []);
      const defaultData = processDefaultData(get(res, 'data.data.default_onboarding_flows', []));
      const newData = [...defaultData, ...data];
      const total = get(res, 'data.data.total', 0);

      loadingRef.current = false;
      isEndLoadOptions.current = newData.length < PER_PAGE;

      setIsLoading(false);
      setOptions(uniqBy(newData, '_id'));
      setTotal(total + defaultData.length);
    } catch (error) {
      loadingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  const debounceScrollBottom = debounce(handleScrollBottom, 300);

  const onInputChange = useCallback(async (inputValue, actionMeta) => {
    try {
      if (loadingRef.current || actionMeta.action !== 'input-change') return;

      loadingRef.current = true;
      setIsLoading(true);

      queryRef.current = {
        ...queryRef.current,
        page: 1,
        text_search: inputValue,
      };
      const params = queryRef.current;

      // Fetch data
      const res = await axiosInstance.get(URL_API_LIST_ONBOARDING_FLOW, { params });
      const data = get(res, 'data.data.data', []);
      const defaultData = processDefaultData(get(res, 'data.data.default_onboarding_flows', []));
      const newData = [...defaultData, ...data];
      const total = get(res, 'data.data.total', 0);
      loadingRef.current = false;
      isEndLoadOptions.current = newData.length < PER_PAGE;

      setIsLoading(false);
      setOptions(uniqBy(newData, '_id'));
      setTotal(total + defaultData.length);
    } catch (error) {
      loadingRef.current = false;
      setIsLoading(false);
    }
  }, []);

  const debounceInputChange = debounce(onInputChange, 300);

  const checkPlacement = useCallback(() => {
    if (type === TYPE.SINGLE || type === TYPE.MULTIPLE || type === TYPE.IMPORT) {
      if (typeof selectRef.current.getBoundingClientRect === 'function') {
        const rect = selectRef.current.getBoundingClientRect();
        if (window.innerHeight - rect.bottom <= 283) {
          setMenuPlacement('top');
        } else {
          menuPlacement !== get(props, 'menuPlacement', 'auto') &&
            setMenuPlacement(get(props, 'menuPlacement', 'auto'));
        }
      }
    }
  }, [menuPlacement, selectRef, type]);

  const handleChange = useCallback((option, isChange = false) => {
    onChange && onChange(option, isChange);
  }, []);

  const onMenuOpen = useCallback(() => {
    checkPlacement();
    setMenuOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleConfirmRemove = selected => {
    const { _id } = selected || {};

    if (_id === 'my_default') {
      const myDefaultItem = finalOptions.find(({ _id }) => _id === (myDefault || {})._id);
      handleChange(myDefaultItem, true);
    } else if (_id === 'workspace_default') {
      const myDefaultItem = finalOptions.find(({ _id }) => _id === (workspaceDefault || {})._id);
      handleChange(myDefaultItem, true);
    } else {
      handleChange(ONBOARDING_NONE_OPTION, true);
    }
  };

  const onConfirmRemove = () => {
    if (isEmpty(myDefault) && isEmpty(workspaceDefault)) {
      if (mode === ONBOARDING_FLOW_SELECT_MODE.PACKAGE) {
        typeof toggleConfirmModal === 'function' &&
          toggleConfirmModal(
            true,
            <ConfirmModal
              title="Remove Onboarding Flow"
              content="All new clients who purchase this package will not receive the onboarding flow. Would you like to continue?"
              headerIcon="/images/trash-circle.svg"
              confirmBtnTitle="Remove"
              className="multiple-popup-red remove-popup"
              onConfirm={() => {
                handleChange(ONBOARDING_NONE_OPTION, true);
              }}
              onClose={() => toggleConfirmModal(false)}
            />,
          );
      } else {
        handleChange(ONBOARDING_NONE_OPTION);
      }
    } else {
      typeof toggleSecondModal === 'function' &&
        toggleSecondModal(
          true,
          <OnboardingFlowRemoveModal
            isHasMyDefault={!isEmpty(myDefault)}
            isHasWorkspaceDefault={!isEmpty(workspaceDefault)}
            onClose={() => toggleSecondModal(false)}
            onSubmit={handleConfirmRemove}
            mode={mode}
          />,
        );
    }
  };

  return (
    <S.Wrapper ref={selectRef} data-tip data-for="tooltip-onboarding-flow-select">
      <Select
        menuIsOpen={menuOpen}
        blurInputOnSelect
        isMulti={false}
        isLoading={isLoading || isLoadingDefault || isLoadingWorkspaceDefault}
        isSearchable
        onChange={item => handleChange(item, true)}
        value={finalOptions.find(({ _id }) => _id === (value || {})._id) || ''}
        options={finalOptions}
        placeholder="Not using Onboarding Flow"
        isDisabled={disabled}
        styles={S.customStyles}
        classNamePrefix="onboarding-flow"
        className="select-onboarding-flow"
        noOptionsMessage={() => noOptionsMessage}
        getOptionLabel={option => get(option, 'name')}
        getOptionValue={option => get(option, '_id')}
        onInputChange={(inputValue, actionMeta) => {
          debounceInputChange(inputValue, actionMeta);
        }}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: CustomDropdownIndicator,
          MenuList: CustomMenuList,
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        menuPlacement={menuPlacement}
        menuPortalTarget={false}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        // custom Props
        total={total}
        onScroll={handleScroll}
        onConfirmRemove={onConfirmRemove}
        cloudfrontList={cloudfrontList}
      />
      {isDraft && (
        <ReactTooltip
          className="app-tooltip onboarding-flow-select"
          id="tooltip-onboarding-flow-select"
          place="top"
          effect="solid"
        >
          <div>Please publish the package to select Onboarding Flow</div>
        </ReactTooltip>
      )}
    </S.Wrapper>
  );
};

export default OnboardingFlowSelect;
