import React from 'react';
import _ from 'lodash';
import { DateTime as DateTimeLuxon } from 'luxon';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import * as ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { LABEL_STATUS_HABIT, STATUS_HABIT, TYPE_FREQUENCY } from '../../../../constants';
import { formatDate, formatDateWithLuxon } from '../../../../helps';
import ProgressCalendar from '../ProgressCalendar';
import * as S from './style';

const Calendar = ({
  dataDaily,
  type,
  unitName,
  handleChoiceDay,
  handleGetHabitInsight,
  dataCurrentDay,
  dataWeekly,
  goalValue,
  clientTimezone,
  startDate,
}) => {
  const calendarRef = useRef();
  // State for detect reset DOM
  const [updateDom, setUpdateDom] = useState(true);

  useEffect(() => {
    handleRemoveWeek();
  }, [updateDom]);

  // Detect remove days (By DOM)
  const handleRemoveWeek = () => {
    const btnNext = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead .rdtNext');
    const btnPrev = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead .rdtPrev');

    const lisTrInTbody = ReactDOM.findDOMNode(calendarRef.current).querySelectorAll('table tbody tr');
    const currentMonth = ReactDOM.findDOMNode(calendarRef.current).querySelector('table thead tr .rdtSwitch');
    const formatDateToLuxon = DateTimeLuxon.fromFormat(currentMonth.textContent, 'MMMM yyyy');

    if (moment(formatDateToLuxon.toFormat('yyyy-MM-dd')).isAfter(startDate, 'month')) {
      btnPrev.classList.remove('disabledBtnNext');
    } else {
      btnPrev.classList.add('disabledBtnNext');
    }

    if (
      currentMonth.textContent ===
      formatDate(new Date().toLocaleString('en-US', { timeZone: clientTimezone }), 'MMMM YYYY')
    ) {
      btnNext.classList.add('disabledBtnNext');
    } else {
      btnNext.classList.remove('disabledBtnNext');
    }

    // call api with param month-year

    handleGetHabitInsight(formatDateToLuxon.toFormat('yyyy-MM'));

    const firstElement = lisTrInTbody[0];
    const lastElement = lisTrInTbody[lisTrInTbody.length - 1];
    let checkFirst = true;
    let checkLast = true;
    firstElement.querySelectorAll('td').forEach(button => {
      const check = button.classList.contains('rdtOld');
      if (!check) {
        checkFirst = false;
        return;
      }
    });
    lastElement.querySelectorAll('td').forEach(button => {
      const check = button.classList.contains('rdtNew');
      if (!check) {
        checkLast = false;
        return;
      }
    });
    lisTrInTbody.forEach((el, index) => {
      if (index === 0 && checkFirst) {
        el.classList.add('weekHide');
      }
      if (index === lisTrInTbody.length - 1 && checkLast) {
        el.classList.add('weekHide');
      }
    });
  };

  // Detect reset DOM
  const handleRemoveWeekChange = (_, type) => {
    if (type === 'months') {
      const lisTrInTbody = ReactDOM.findDOMNode(calendarRef.current).querySelectorAll('table tbody tr');
      lisTrInTbody.forEach(el => {
        el.removeAttribute('class');
      });
      setUpdateDom(!updateDom);
    }
  };

  const renderDay = (props, currentDate, selectedDate) => {
    let status = '';
    let percent = 0;
    let textDesc = '';
    let amount = null;

    const today = formatDate(new Date().toLocaleString('en-US', { timeZone: clientTimezone }));
    const currentDateFormat = formatDate(currentDate);

    const currentDay = _.find(dataDaily, function (o) {
      return o.day === currentDateFormat;
    });

    const checkDateSameToday = moment(today).isSame(currentDateFormat);

    // Check condition select week
    const selectWeek =
      currentDateFormat === dataCurrentDay.dayCompare && type === TYPE_FREQUENCY.PER_WEEK ? 'selectWeek' : '';

    if (currentDay) {
      let unitFormat = unitName;
      if (currentDay.value == 1) {
        unitFormat = unitName.replace(/s$/, '');
      }
      unitFormat = `${currentDay.value} ${unitFormat.toLowerCase()}`;

      textDesc = unitFormat;

      const checkDayMissed = moment(today).isAfter(currentDateFormat);

      if (type === TYPE_FREQUENCY.PER_DAY) {
        if (currentDay.percent_completed < 100) {
          status = STATUS_HABIT.DOING;
          if (checkDateSameToday && currentDay.value <= 0) {
            status = '';
            textDesc = '';
          }
          if (checkDayMissed && currentDay.percent_completed === 0) {
            textDesc = LABEL_STATUS_HABIT.MISSED;
          }
          percent = currentDay.percent_completed;
        } else {
          status = STATUS_HABIT.DONE;
        }
      } else {
        const weekOfYear = currentDateFormat && formatDateWithLuxon(currentDateFormat);
        const currentWeek = _.find(dataWeekly, function (o) {
          return o.week_of_year === weekOfYear;
        });

        if (currentDay.value <= 0) {
          if (currentWeek && currentWeek.value >= goalValue) {
            status = '';
            textDesc = '';
          } else {
            if (checkDateSameToday) {
              status = '';
              textDesc = '';
            } else {
              percent = 0;
              status = STATUS_HABIT.DOING;
              if (checkDayMissed) {
                textDesc = LABEL_STATUS_HABIT.MISSED;
              }
            }
          }
        } else {
          status = STATUS_HABIT.DONE;
          if (unitName === 'Minutes') {
            amount = 1;
          } else {
            switch (currentDay.value) {
              case 1:
                amount = 1;
                break;
              case 2:
                amount = 2;
                break;
              default:
                amount = 3;
                break;
            }
          }
        }
      }
    }

    const checkAllowClickDate = status || (checkDateSameToday && currentDay && currentDay.value >= 0);

    return (
      <td className={`${props.className} ${checkAllowClickDate ? '' : 'tdNotAllowed'} ${selectWeek}`}>
        <ProgressCalendar
          handleChoiceDay={() => {
            if (checkAllowClickDate) {
              handleChoiceDay(currentDay);
            }
          }}
          day={currentDate.date()}
          percent={percent}
          className={props.className}
          status={status}
          textDesc={textDesc}
          amount={amount}
          daySelected={currentDateFormat === dataCurrentDay.dayCompare && type === TYPE_FREQUENCY.PER_DAY}
        />
      </td>
    );
  };

  return (
    <S.Calendar selectWeek={type === TYPE_FREQUENCY.PER_WEEK}>
      <Datetime
        displayTimeZone={clientTimezone}
        defaultValue={new Date(dataCurrentDay.dayCompare)}
        ref={calendarRef}
        className="calender-habit"
        open
        input={false}
        renderDay={renderDay}
        onNavigateBack={handleRemoveWeekChange}
        onNavigateForward={handleRemoveWeekChange}
        onViewModeChange={view => {
          if (view === 'days') {
            // Detect reset DOM
            setUpdateDom(!updateDom);
          }
        }}
      />
    </S.Calendar>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
