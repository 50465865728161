import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'shared/Pagination';
import { changeQueryParams } from 'redux/exercise/exercise.actionCreators';

function View(props) {
  const { total, page, per_page, length } = props;

  if (!length) {
    return null;
  }

  return (
    <Pagination
      page={page}
      perPage={per_page}
      totalPage={total}
      onPrev={() => {
        props.changeQueryParams({ page: page - 1 });
      }}
      onNext={() => {
        props.changeQueryParams({ page: page + 1 });
      }}
    />
  );
}

const Footer = React.memo(View);

const mapState = (state) => {
  const {
    rootReducer: {
      exercise: {
        listExercise,
        total,
        query: { page, per_page },
      },
    },
  } = state;

  return { total, page, per_page, length: listExercise.length };
};

const mapDispatch = (dispatch) => ({
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
});

export default connect(mapState, mapDispatch)(Footer);
