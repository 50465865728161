import _ from 'lodash';
import { LIMIT_MESSAGES, SECONDS_TIME_LIMIT } from 'constants/commonData';
import { Types, sortRoomsByLastMessage } from './actions';

const INITIAL_STATE = {
  rooms: [],
  currentRoomId: '',
  messages: [],
  newConversation: false,
  totalUnread: 0,
  draftMessages: {},
  roomInformation: null,
  featureOff: false,
  profileId: '',
  loadedRooms: false,
  transferInformation: null,
  draft: {},
  openArchivedMessage: false,
  currentTeam: null,
  viewTeammate: null,
  isFirstLoad: true,
  firstLoadList: true,
  archivedMessages: [],
  lastItem: null,
  endOfList: false,
  loading: false,
  isDisabled: false,
  userRooms: [],
  loadingRooms: false,
  query: { page: 1, per_page: 20, q: '', rooms: [], loading: false, isEnd: false, total: 0 },
  selectedRoom: null,
  members: [],
  searchMembers: [],
  bottomItem: null,
  totalParticipants: 0,
  invalidRoom: false,
  usersInfo: [],
  isLoadMoreRooms: false,
  isWaiting: false,
  selectedTeammate: null,
  loadingMessages: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.INBOX_GET_MESSAGES_REQUEST: {
      return Object.assign({}, state, { loading: true, loadingMessages: true });
    }
    case Types.INBOX_REQUEST_GET_LATEST_MESSAGES: {
      return Object.assign({}, state, { loading: true, bottomItem: null, endOfBottom: false, endOfList: false });
    }
    case Types.INBOX_REQUEST_GET_ALL_ROOMS: {
      return Object.assign({}, state, {
        loadingRooms: true,
      });
    }
    case Types.INBOX_SUCCESS_GET_ALL_ROOMS: {
      if (state.viewTeammate) {
        return state;
      }
      return Object.assign({}, state, {
        rooms: payload.data,
        roomInformation: payload.data.length ? state.roomInformation : null,
        loadedRooms: true,
        isDisabled: false,
        selectedTeammate: null,
        loadingRooms: false,
      });
    }

    case Types.INBOX_ADMIN_SUCCESS_GET_ALL_ROOMS: {
      if (state.viewTeammate) {
        return Object.assign({}, state, {
          rooms: payload.data,
          roomInformation: payload.data.length ? state.roomInformation : null,
          loadedRooms: true,
          query: {
            ...state.query,
            total: payload.total,
          },
          selectedTeammate: payload.trainer,
        });
      } else {
        return state;
      }
    }

    case Types.INBOX_REQUEST_SEARCH_ALL_ROOMS: {
      return Object.assign({}, state, {
        query: {
          ...state.query,
          ...payload.newQuery,
          loading: true,
        },
      });
    }

    case Types.INBOX_SUCCESS_SEARCH_ALL_ROOMS: {
      return Object.assign({}, state, {
        query: {
          ...state.query,
          ...payload.newQuery,
          loading: false,
          rooms:
            state.query.page === 1
              ? payload.data
              : sortRoomsByLastMessage(_.uniqBy([...state.query.rooms, ...payload.data], 'id')),
        },
      });
    }

    case Types.INBOX_FAILED_SEARCH_ALL_ROOMS:
      return Object.assign({}, state, {
        query: {
          ...state.query,
          loading: false,
        },
      });

    case Types.INBOX_RESET_QUERY_PARAMS:
      return Object.assign({}, state, {
        query: INITIAL_STATE.query,
      });

    case Types.INBOX_CHANGE_CLIENT_REQUEST: {
      return Object.assign({}, state, { messages: [], archivedMessages: [], lastItem: null, endOfList: false });
    }

    case Types.INBOX_RESET_ROOMS_REDUX: {
      if (!state.viewTeammate) {
        return state;
      }
      return Object.assign({}, state, { ..._.omit(INITIAL_STATE, ['isFirstLoad', 'totalUnread']) });
    }

    case Types.INBOX_RESET_ALL_INBOX_REDUX: {
      return Object.assign({}, state, { ..._.omit(INITIAL_STATE, ['totalUnread', 'totalUnread']) });
    }

    case Types.INBOX_SELECT_TEAMMATE_REDUX:
      return Object.assign({}, state, {
        ...INITIAL_STATE,
        currentTeam: payload.teamId,
        viewTeammate: payload.trainerId,
        loadedRooms: false,
        isFirstLoad: false,
        archivedMessages: [],
        totalUnread: state.totalUnread,
        firstLoadList: true,
      });

    case Types.INBOX_FAILED_GET_ALL_ROOMS:
      return Object.assign({}, state, { loadedRooms: true, loadingRooms: false });

    case Types.INBOX_SELECT_ROOM:
      return Object.assign({}, state, {
        currentRoomId: payload.data.currentRoomId,
        profileId: payload.data.profileId,
        newConversation: false,
        roomInformation: state.profileId !== payload.data.profileId ? null : state.roomInformation,
        messages: [],
        featureOff: false,
        transferInformation: null,
        archivedMessages: [],
        isFirstLoad: false,
        lastItem: null,
        endOfList: false,
        firstLoadList: true,
        isDisabled: true,
        selectedRoom: payload.data.selectedRoom,
        members: [],
        endOfBottom: false,
        bottomItem: null,
        invalidRoom: false,
        isWaiting: false,
        loading: false,
      });

    case Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM:
      if (_.get(state, 'currentRoomId') === payload.roomId) {
        const lastItem = _.get(state, 'lastItem');
        const first = payload.data.length > 0 ? payload.data[lastItem ? 0 : 1] : null;

        const LIMIT_MESSAGES_LIST = lastItem ? SECONDS_TIME_LIMIT - 1 : LIMIT_MESSAGES;
        const endOfList = payload.data.length < LIMIT_MESSAGES_LIST;
        let newMessages = endOfList ? payload.data : [...payload.data.slice(lastItem ? 0 : 1, LIMIT_MESSAGES_LIST)];
        const systemMessage = newMessages.find(item => item.system_message_type === 0);
        if (systemMessage) {
          newMessages = _.uniqBy([systemMessage, ...newMessages], 'key');
        }
        return Object.assign({}, state, {
          messages: _.uniqBy([...newMessages, ...state.messages], 'key'),
          lastItem: first,
          endOfList: endOfList,
          loading: false,
          firstLoadList: lastItem ? false : true,
          loadingMessages: false,
        });
      } else {
        return state;
      }
    case Types.INBOX_SUCCESS_GET_LATEST_MESSAGES:
      if (_.get(state, 'currentRoomId') === payload.roomId) {
        const lastItem = _.get(state, 'lastItem');
        const first = payload.data.length > 0 ? payload.data[lastItem ? 0 : 1] : null;

        const LIMIT_MESSAGES_LIST = lastItem ? SECONDS_TIME_LIMIT - 1 : LIMIT_MESSAGES;
        const endOfList = payload.data.length < LIMIT_MESSAGES_LIST;
        const newMessages = payload.data;
        const pickLocalMessages = _.filter(state.messages, 'isLocalFile');

        return Object.assign({}, state, {
          messages: _.uniqBy([...newMessages, ...pickLocalMessages], 'key'),
          lastItem: first,
          endOfList: endOfList,
          loading: false,
          firstLoadList: true,
          endOfBottom: false,
          bottomItem: null,
        });
      } else {
        return state;
      }
    case Types.INBOX_REQUEST_GET_LATEST_MESSAGES_ARCHIVED_ROOM:
      return Object.assign({}, state, { loading: true, bottomItem: null, endOfBottom: false, endOfList: false });
    case Types.INBOX_SUCCESS_GET_LATEST_MESSAGES_ARCHIVED_ROOM:
      if (state.archivedMessages.length > 0) {
        const lastItem = _.get(state, 'lastItem');
        const first = payload.data.length > 0 ? payload.data[lastItem ? 0 : 1] : null;

        const LIMIT_MESSAGES_LIST = lastItem ? SECONDS_TIME_LIMIT - 1 : LIMIT_MESSAGES;
        const endOfList = payload.data.length < LIMIT_MESSAGES_LIST;
        const newMessages = payload.data;

        return Object.assign({}, state, {
          archivedMessages: _.uniqBy(newMessages, 'key'),
          lastItem: first,
          endOfList: endOfList,
          loading: false,
          firstLoadList: true,
          endOfBottom: false,
          bottomItem: null,
        });
      } else {
        return state;
      }

    case Types.INBOX_REQUEST_GET_MESSAGE_OF_ROOM_AT_ID:
      return Object.assign({}, state, {
        loading: true,
      });
    case Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_ID:
      if (_.get(state, 'currentRoomId') === payload.roomId) {
        const messages = _.uniqBy(payload.data, 'key');
        return Object.assign({}, state, {
          messages: _.uniqBy(payload.data, 'key'),
          loading: false,
          endOfList: false,
          endOfBottom: false,
          firstLoadList: false,
          bottomItem: messages.length ? messages[messages.length - 1].key : null,
          lastItem: _.head(_.unionBy(payload.data, 'key')),
        });
      } else {
        return state;
      }

    case Types.INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID:
      const messages = _.uniqBy(payload.data, 'key');
      return Object.assign({}, state, {
        archivedMessages: _.uniqBy(payload.data, 'key'),
        loading: false,
        endOfList: false,
        firstLoadList: false,
        bottomItem: messages.length ? messages[messages.length - 1].key : null,
        lastItem: _.head(_.unionBy(payload.data, 'key')),
      });

    case Types.RESET_FIRST_LOAD_LIST:
      return Object.assign({}, state, {
        firstLoadList: true,
      });

    case Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_BOTTOM: {
      if (_.get(state, 'currentRoomId') === payload.roomId) {
        const messages = _.uniqBy(payload.data, 'key');
        const endOfBottom = _.get(payload, 'endOfBottom', false);

        return Object.assign({}, state, {
          messages: _.uniqBy([...state.messages, ...messages], 'key'),
          endOfBottom: endOfBottom,
          bottomItem: !endOfBottom && messages.length ? _.get(messages, `[${messages.length - 1}].key`) : null,
        });
      } else {
        return state;
      }
    }

    // Load message of archived room at bottom
    case Types.INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM: {
      const messages = _.uniqBy(payload.data, 'key');
      const endOfBottom = _.get(payload, 'endOfBottom', false);

      return Object.assign({}, state, {
        archivedMessages: _.uniqBy([...state.archivedMessages, ...messages], 'key'),
        endOfBottom: endOfBottom,
        bottomItem: !endOfBottom && messages.length ? _.get(messages, `[${messages.length - 1}].key`) : null,
      });
    }

    case Types.INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_ID:
      return Object.assign({}, state, {
        loading: false,
      });

    case Types.INBOX_GET_ARCHIVED_MESSAGES_SUCCESS: {
      const lastItem = _.get(state, 'lastItem');
      const first = payload.data.length > 0 ? payload.data[lastItem ? 0 : 1] : null;

      const LIMIT_MESSAGES_LIST = lastItem ? SECONDS_TIME_LIMIT - 1 : LIMIT_MESSAGES;
      const endOfList = payload.data.length < LIMIT_MESSAGES_LIST;
      const newMessages = endOfList ? payload.data : [...payload.data.slice(lastItem ? 0 : 1, LIMIT_MESSAGES_LIST)];
      return Object.assign({}, state, {
        archivedMessages: _.uniqBy([...newMessages, ...state.archivedMessages], 'key'),
        lastItem: first,
        endOfList: endOfList,
        firstLoadList: lastItem ? false : true,
        loading: false,
        loadingMessages: false,
      });
    }

    case Types.INBOX_NEW_MESSAGE_ADDED:
      const trainerId = _.get(state, `roomInformation.trainers[0].trainer`, '');
      const sender = _.get(payload, 'data.sender', '');

      if (sender !== trainerId && !state.endOfBottom && state.bottomItem) {
        return state;
      }

      if (
        _.get(state, 'currentRoomId') === payload.roomId &&
        !_.find(state.messages, m => m.key === payload.data.key)
      ) {
        const localMessage = state.messages.find(item => item.isLocalFile);
        const url = _.get(localMessage, 'uploadConfigs.url', '');
        const attachment = url.substring(0, url.indexOf('?X-Amz-Algorithm='));
        if (
          _.get(localMessage, 'attachment_type', '').includes('audio') &&
          _.get(localMessage, 'localKey', '').startsWith('new_media_message') &&
          attachment === payload.data.attachment
        ) {
          let newData = payload.data;

          let newMessages = [...state.messages];

          // TODO: Handle replace for image
          // if (_.get(localMessage, 'attachment_type', '').includes('image')) {
          //   newData = { ...newData, localKey: localMessage.localKey, attachment_thumbnail: localMessage.attachment };
          // }

          newMessages = newMessages.map(item => {
            const url = _.get(item, 'uploadConfigs.url', '');
            const attachment = url.substring(0, url.indexOf('?X-Amz-Algorithm='));
            if (attachment === payload.data.attachment) {
              return newData;
            }
            return item;
          });

          return Object.assign({}, state, { messages: newMessages });
        }

        // Checking double audio message
        let indexOfAudioLocal = _.findIndex(
          [...state.messages, payload.data],
          e => {
            const url = _.get(e, 'uploadConfigs.url', '');
            const attachment = url.substring(0, url.indexOf('?X-Amz-Algorithm='));
            const isExits = [...state.messages, payload.data].find(it => it.attachment === attachment);

            return isExits && e.isLocalFile && _.get(e, 'attachment_type', '').includes('audio');
          },
          0,
        );

        let fullMessages = [...state.messages, payload.data];
        if (indexOfAudioLocal > -1) {
          fullMessages.splice(indexOfAudioLocal, 1);
        }

        if (_.get(payload, 'data.system_message_type') === 0) {
          fullMessages = _.uniqBy([payload.data, ...fullMessages], 'key');
        }

        return Object.assign({}, state, { messages: fullMessages });
      } else {
        return state;
      }

    case Types.INBOX_TOTAL_UNREAD_CHANGE:
      return Object.assign({}, state, { totalUnread: payload.data });

    case Types.INBOX_SUCCESS_GET_INDIVIDUAL_ROOM_INFORMATION:
      if (state.profileId === payload.userId) {
        return Object.assign({}, state, { roomInformation: payload.data, isDisabled: false });
      } else {
        return state;
      }

    case Types.INBOX_CHANGE_PROFILE_ID:
      return Object.assign({}, state, {
        profileId: payload.data,
        featureOff: false,
        roomInformation:
          !_.isEmpty(_.get(state, 'roomInformation._id')) &&
          _.isEqual(_.get(state, 'profileId'), _.get(state, 'roomInformation._id'))
            ? _.get(state, 'roomInformation')
            : null,
        transferInformation: null,
      });

    case Types.INBOX_SUCCESS_UPDATE_FAVORITE:
      if (state.roomInformation && payload.client === state.roomInformation._id) {
        return Object.assign({}, state, {
          roomInformation: { ...state.roomInformation, favorited: payload.isStar },
        });
      } else {
        return state;
      }

    case Types.INBOX_NEW_CONVERSATION:
      return Object.assign({}, state, {
        newConversation: true,
        currentRoomId: '',
        profileId: '',
        featureOff: false,
        roomInformation: null,
        transferInformation: null,
        isDisabled: true,
        selectedRoom: null,
        members: [],
        messages: [],
      });

    case Types.INBOX_SUCCESS_TURN_ON_FEATURE:
      if (payload.data === state.profileId) {
        return Object.assign({}, state, { featureOff: false });
      } else {
        return state;
      }

    case Types.INBOX_SUCCESS_GET_FEATURE_STATUS:
      if (payload.clientId === state.profileId) {
        return Object.assign({}, state, { featureOff: payload.data });
      } else {
        return state;
      }

    case Types.INBOX_FAILED_GET_FEATURE_STATUS:
      if (payload.clientId === state.profileId) {
        return Object.assign({}, state, { featureOff: true });
      } else {
        return state;
      }

    case Types.INBOX_CREATE_NEW_CONVERSTION_FROM_TRANSFER_DATA:
      return Object.assign({}, state, {
        newConversation: true,
        currentRoomId: '',
        profileId: payload.data._id,
        transferInformation: payload.data,
        featureOff: false,
        roomInformation: null,
        messages: [],
        selectedRoom: null,
      });

    case Types.INBOX_NEW_LOCAL_MESSAGES_ADDED: {
      if (state.newConversation) {
        return Object.assign({}, state, {
          messages: [...state.messages, ...payload.data],
          archivedMessages: [...state.archivedMessages, ...payload.data],
        });
      }
      return Object.assign({}, state, {
        messages: [...state.messages, ...payload.data],
      });
    }

    case Types.INBOX_REMOVE_LOCAL_MESSAGE:
      if (state.currentRoomId === payload.roomId) {
        return Object.assign({}, state, {
          messages: _.filter(state.messages, item => item.key !== payload.messageKey),
          archivedMessages: _.filter(state.archivedMessages, item => item.key !== payload.messageKey),
        });
      } else {
        return state;
      }

    case Types.INBOX_ADD_ROOM_DRAFT_MESSAGE:
      return Object.assign({}, state, { draft: { ...state.draft, [payload.roomId]: payload.message } });

    case Types.INBOX_REMOVE_ROOM_DRAFT_MESSAGE:
      const newDraft = { ...state.draft };
      delete newDraft[payload];
      return Object.assign({}, state, { draft: newDraft });

    case Types.TOGGLE_ARCHIVED_CONVERSATION_SUCCESS: {
      if (_.isEmpty(payload.roomId)) return Object.assign({}, state, { openArchivedMessage: payload.data });
      const newRooms = _.get(state, 'rooms', []).filter(room => room.id !== payload.roomId);
      const newQueryRooms = _.get(state, 'query.rooms', []).filter(room => room.id !== payload.roomId);
      return Object.assign({}, state, {
        openArchivedMessage: payload.data,
        rooms: newRooms,
        currentRoomId: state.currentRoomId === payload.roomId ? '' : state.currentRoomId,
        query: { ...state.query, rooms: newQueryRooms },
      });
    }

    case Types.INBOX_MUTE_CONVERSATION_SUCCESS: {
      const mute_until = _.get(payload, 'mute_until', null);
      const newRooms = _.get(state, 'rooms').map(room =>
        room.id === _.get(payload, 'roomId') ? { ...room, mute_until } : room,
      );
      return Object.assign({}, state, {
        rooms: newRooms,
      });
    }

    case Types.INBOX_UNMUTE_CONVERSATION_SUCCESS: {
      const mute_until = _.get(payload, 'mute_until', null);
      const newRooms = _.get(state, 'rooms').map(room =>
        room.id === _.get(payload, 'roomId') ? { ...room, mute_until } : room,
      );
      return Object.assign({}, state, { rooms: newRooms, selectedRoom: { ...state.selectedRoom, mute_until } });
    }

    case Types.INBOX_CREATE_GROUP_SUCCESS: {
      return Object.assign({}, state, {
        newConversation: false,
        currentRoomId: _.get(payload, 'data.room_id', ''),
        selectedRoom: _.get(payload, 'data'),
      });
    }

    case Types.INBOX_GET_GROUP_MEMBERS_SUCCESS: {
      return Object.assign({}, state, {
        members: _.get(payload, 'data'),
        isDisabled: false,
        totalParticipants: _.get(payload, 'total'),
      });
    }

    case Types.INBOX_SEARCH_GROUP_MEMBERS_SUCCESS: {
      return Object.assign({}, state, {
        searchMembers: _.get(payload, 'data'),
      });
    }

    case Types.INBOX_REMOVE_GROUP_MEMBER_SUCCESS: {
      const members = _.get(state, 'members', []).filter(item => item._id !== _.get(payload, 'memberId'));
      const searchMembers = _.get(state, 'searchMembers', []).filter(item => item._id !== _.get(payload, 'memberId'));
      return Object.assign({}, state, {
        members,
        searchMembers,
        totalParticipants: _.get(state, 'totalParticipants') - 1,
      });
    }

    case Types.INBOX_LEAVE_GROUP_SUCCESS: {
      const newRooms = _.get(payload, 'newRooms', []);
      return Object.assign({}, state, { rooms: newRooms, members: [] });
    }

    case Types.INBOX_UPDATE_GROUP_INFO_SUCCESS: {
      const room_name = _.get(payload, 'room_name', state.selectedRoom.room_name);
      const newRooms = _.get(state, 'rooms', []).map(room =>
        room.id === _.get(payload, 'roomId') ? { ...room, room_name } : room,
      );
      const newSelectedRoom = { ...state.selectedRoom, room_name };
      return Object.assign({}, state, { rooms: newRooms, selectedRoom: newSelectedRoom });
    }

    case Types.INBOX_UPDATE_GROUP_AVATAR_LOCAL: {
      const newRooms = _.get(state, 'rooms', []).map(room =>
        room.id === _.get(payload, 'roomId') ? { ...room, room_icon: payload.data, isLocalFile: true } : room,
      );
      const newSelectedRoom = { ...state.selectedRoom, room_icon: payload.data, isLocalFile: true };
      return Object.assign({}, state, { rooms: newRooms, selectedRoom: newSelectedRoom });
    }

    case Types.INBOX_ENABLE_CHAT_INPUT_SUCCESS: {
      return Object.assign({}, state, { isDisabled: payload.status });
    }

    case Types.INBOX_SUCCESS_UPDATE_ROOMS: {
      const newRooms = sortRoomsByLastMessage(_.uniqBy([...payload.rooms, ...state.rooms], 'id'));
      let newQueryRooms = _.get(state, 'query.rooms', []);
      const isReplaceQuery = !_.isEmpty(_.get(state, 'query.rooms', [])) && _.isEmpty(_.get(state, 'viewTeammate'));
      if (isReplaceQuery) {
        newQueryRooms = [
          ...state.query.rooms
            .concat(payload.rooms)
            .reduce((m, o) => m.set(o.id, Object.assign(m.get(o.id) || {}, o)), new Map())
            .values(),
        ];
      }
      return Object.assign({}, state, {
        rooms: newRooms,
        isDisabled: false,
        selectedRoom: newRooms.find(room => room.id === _.get(state, 'selectedRoom.id')),
        query: {
          ...state.query,
          rooms: newQueryRooms,
        },
      });
    }
    case Types.INBOX_REMOVE_ROOMS_SUCCESS:
      return Object.assign({}, state, {
        rooms: _.filter(state.rooms, item => item.id !== payload.roomId),
      });
    case Types.INBOX_SUCCESS_UPDATE_MESSAGES:
      return Object.assign({}, state, {
        messages: _.map(state.messages, item => {
          if (item.key === payload.key) {
            return payload;
          }
          return item;
        }),
        firstLoadList: false,
      });
    case Types.INBOX_REMOVE_MESSAGES_SUCCESS:
      return Object.assign({}, state, {
        messages: _.filter(state.messages, item => item.key !== payload.key),
        firstLoadList: false,
      });
    case Types.INBOX_SHOW_INVALID_ROOM:
      return Object.assign({}, state, {
        invalidRoom: true,
      });
    case Types.INBOX_SUCCESS_GET_USER_INFO:
      return Object.assign({}, state, {
        usersInfo: _.uniqBy([...state.usersInfo, ...payload.data], '_id'),
      });
    case Types.INBOX_REQUEST_LOAD_MORE_ROOMS:
      return Object.assign({}, state, { isLoadMoreRooms: true });
    case Types.INBOX_SUCCESS_LOAD_MORE_ROOMS:
      if (state.viewTeammate) {
        return state;
      }
      return Object.assign({}, state, {
        rooms: sortRoomsByLastMessage(_.uniqBy([...payload.rooms, ...state.rooms], 'id')),
        isDisabled: false,
        isLoadMoreRooms: false,
      });
    case Types.INBOX_FAILED_LOAD_MORE_ROOMS:
      return Object.assign({}, state, { isLoadMoreRooms: false });
    case Types.INBOX_REQUEST_SEARCH_ROOMS:
      return Object.assign({}, state, {
        query: {
          ...state.query,
          loading: true,
          q: payload.q,
          page: payload.page,
        },
      });
    case Types.INBOX_SUCCESS_SEARCH_ROOMS:
      return Object.assign({}, state, {
        query: {
          ...state.query,
          loading: false,
          page: payload.page,
          total: payload.total,
          q: payload.q,
          isEnd: payload.isEnd,
          rooms: state.query.page === 1 ? payload.rooms : _.uniqBy([...state.query.rooms, ...payload.rooms], 'id'),
        },
      });
    case Types.INBOX_FAILED_SEARCH_ROOMS:
      return Object.assign({}, state, {
        query: {
          ...state.query,
          loading: false,
        },
      });
    case Types.INBOX_RESET_SEARCH_ROOMS:
      return Object.assign({}, state, {
        query: INITIAL_STATE.query,
      });
    case Types.INBOX_RESET_MUTE_STATUS: {
      const newRooms = _.get(state, 'rooms', []).map(room =>
        room.id === _.get(payload, 'roomId') ? { ...room, mute_until: null } : room,
      );
      const newSelectedRoom = { ...state.selectedRoom, mute_until: null };
      return Object.assign({}, state, { rooms: newRooms, selectedRoom: newSelectedRoom });
    }

    case Types.INBOX_START_WAITING_SYSTEM_MESSAGE:
      return Object.assign({}, state, { isWaiting: true });
    case Types.INBOX_STOP_WAITING_SYSTEM_MESSAGE:
      return Object.assign({}, state, { isWaiting: false });
    case Types.INBOX_ADD_SELECTED_ROOM: {
      return Object.assign({}, state, { query: { ...state.query, rooms: [payload.room], total: payload.total } });
    }
    case Types.INBOX_UPDATE_GROUP_MEMBERS: {
      return Object.assign({}, state, {
        members: _.get(payload, 'members'),
        searchMembers: _.get(payload, 'searchMembers'),
        totalParticipants: _.get(state, 'totalParticipants') - 1,
      });
    }

    default:
      return state;
  }
};
