import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import { isEqual } from 'lodash';
import { push } from 'connected-react-router';

import {
  getWorkoutCollectionDetail,
  updateWorkoutCollectionDetail,
  getWorkoutCollectionClients,
  getPreSignedUrl,
  addNewCategory,
  toggleCategory,
  resetWorkoutCollectionDetail,
  toggleCollection,
  removeCategory,
  duplicateCategory,
  rearrangeCategories,
} from 'redux/workout-collection/actions';
import { getCustomBrandingData } from 'redux/custom-branding/actions';

import { toggleConfirmModal } from 'actions/modal';
import { INITIAL_WORKING_COLLECTION } from 'constants/commonData';

import Overview from './Overview';
import Clients from './Clients';
import { updateSettingFormat } from 'redux/workout-collection-category/actions';

const WorkoutCollectionsDetail = ({
  workingCollection,
  loading,
  match,
  getWorkoutCollectionDetail,
  updateWorkoutCollectionDetail,
  addNewCategory,
  getWorkoutCollectionClients,
  push,
  toggleCategory,
  resetWorkoutCollectionDetail,
  toggleCollection,
  toggleConfirmModal,
  removeCategory,
  duplicateCategory,
  rearrangeCategories,
  updateSettingFormat,
  getCustomBrandingData,
  customBrandingBackground,
  user,
  cloudfrontList,
}) => {
  useEffect(() => {
    const workoutCollectionId = match.params.collectionId;
    if (
      workoutCollectionId &&
      (isEqual(workingCollection, INITIAL_WORKING_COLLECTION) || workingCollection._id !== workoutCollectionId)
    ) {
      getWorkoutCollectionDetail(workoutCollectionId);
      getWorkoutCollectionClients(workoutCollectionId);
    }
    return () => {
      resetWorkoutCollectionDetail();
    };
  }, []);

  return (
    <Switch>
      <Route
        exact
        path="/home/workout-collections/:collectionId?"
        render={props => {
          if (loading || isEqual(workingCollection, INITIAL_WORKING_COLLECTION)) {
            return null;
          }
          return (
            <Overview
              {...props}
              cloudfrontList={cloudfrontList}
              workingCollection={workingCollection}
              getPreSignedUrl={getPreSignedUrl}
              updateWorkoutCollectionDetail={updateWorkoutCollectionDetail}
              addNewCategory={addNewCategory}
              toggleConfirmModal={toggleConfirmModal}
              removeCategory={removeCategory}
              duplicateCategory={duplicateCategory}
              push={push}
              toggleCategory={toggleCategory}
              toggleCollection={toggleCollection}
              rearrangeCategories={rearrangeCategories}
              updateSettingFormat={updateSettingFormat}
              getCustomBrandingData={getCustomBrandingData}
              customBrandingBackground={customBrandingBackground}
              user={user}
            />
          );
        }}
      />
      <Route
        exact
        path="/home/workout-collections/:collectionId/clients"
        render={props => <Clients {...props} workingCollection={workingCollection} cloudfrontList={cloudfrontList} />}
      />
    </Switch>
  );
};

const mapState = state => {
  const { workoutCollection } = state.rootReducer;

  return {
    user: state.user,
    cloudfrontList: state.cloudfrontList,
    workingCollection: workoutCollection.workingCollection,
    loading: workoutCollection.loading,
    customBrandingBackground: state.rootReducer.customBranding.selectedWorkoutBackground,
  };
};

const mapDispatch = dispatch => {
  return {
    getWorkoutCollectionDetail: bindActionCreators(getWorkoutCollectionDetail, dispatch),
    getWorkoutCollectionClients: bindActionCreators(getWorkoutCollectionClients, dispatch),
    updateWorkoutCollectionDetail: bindActionCreators(updateWorkoutCollectionDetail, dispatch),
    resetWorkoutCollectionDetail: bindActionCreators(resetWorkoutCollectionDetail, dispatch),
    getPreSignedUrl: bindActionCreators(getPreSignedUrl, dispatch),
    addNewCategory: bindActionCreators(addNewCategory, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    toggleCategory: bindActionCreators(toggleCategory, dispatch),
    toggleCollection: bindActionCreators(toggleCollection, dispatch),
    push: bindActionCreators(push, dispatch),
    removeCategory: bindActionCreators(removeCategory, dispatch),
    duplicateCategory: bindActionCreators(duplicateCategory, dispatch),
    rearrangeCategories: bindActionCreators(rearrangeCategories, dispatch),
    updateSettingFormat: bindActionCreators(updateSettingFormat, dispatch),
    getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
  };
};

export default withRouter(connect(mapState, mapDispatch)(WorkoutCollectionsDetail));
