import React from 'react';
import { connect } from 'react-redux';

import * as S from './style';
import SectionMobilePreview from './SectionMobilePreview';
import PreviewLargeCard from './PreviewLargeCard';
import { FormatLayout } from '../constants';
import PreviewSquares from './PreviewSquares';
import PreviewNarrowCard from './PreviewNarrowCard';

function MobilePreviewCollection(props) {
  const { workingCollection } = props;
  return (
    <S.Wrapper>
      <S.MobilePreviewContainer>
        <div className="mobilePreview__heading">{workingCollection.name}</div>
        <div className="mobilePreview__sections">
          {workingCollection.sections.map((section, index) => {
            switch (section.format) {
              case FormatLayout.LAGER:
                return <PreviewLargeCard key={index} section={section} />;
              case FormatLayout.SQUARE:
                return <PreviewSquares key={index} section={section} />;
              case FormatLayout.NARROW:
                return <PreviewNarrowCard key={index} section={section} />;
              default:
                return <SectionMobilePreview key={index} section={section} />;
            }
          })}
        </div>
      </S.MobilePreviewContainer>
    </S.Wrapper>
  );
}

const mapStateToProps = state => {
  return {
    workingCollection: state.rootReducer.studioCollection.workingCollection,
  };
};

export default connect(mapStateToProps, null)(MobilePreviewCollection);
