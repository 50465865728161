import { connect } from 'react-redux';
import WaitingActivationPopup from './component';
import { getWaitingClients, markSeenWaitingList } from 'redux/waiting-activation-clients/actions';
import { toggleModal } from 'actions/modal';
import { push } from 'connected-react-router';

const mapState = state => {
  const {
    user,
    rootReducer: { segment, client, permission, waitingActivationClients },
  } = state;
  return {
    clients: waitingActivationClients.get('list'),
    maximum: permission.no_clients || 0,
    totalActive: client.total_active_clients || 0,
    user,
    segments: segment.list,
  };
};

export default connect(mapState, { getWaitingClients, markSeenWaitingList, toggleModal, push })(WaitingActivationPopup);
