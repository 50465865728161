import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import classNames from 'classnames';

import { Mixpanel } from 'utils/mixplanel';
import { ReactComponent as HeadIcon } from 'assets/icons/ai/ai-save-fb-head.svg';

import { TextEditable } from '../style';
import './style.scss';

const ReactionStatus = {
  Satisfied: 'Satisfied',
  NotSatisfied: 'Not satisfied',
};

const FeedbackAfterSaved = props => {
  const { hasChanged = false, conversion_id, onClose, workoutAIPage, sendFeedback } = props;
  const [form, setForm] = useState({
    isSending: false,
    feedbackText: '',
    reaction: '',
    hasText: false,
  });
  const inputRef = useRef(null);

  const onSubmitFeedback = async isSatisfied => {
    const { feedbackText, hasText, isSending } = form;
    if (isSending || (!hasText && !isSatisfied)) return;

    const text = feedbackText.trim() || inputRef.current ? inputRef.current.innerText.trim() : '';
    const reaction = isSatisfied ? ReactionStatus.Satisfied : ReactionStatus.NotSatisfied;

    Mixpanel.track('submit_feedback_ai_workout_builder', {
      component: workoutAIPage,
      reaction,
    });

    try {
      setForm(s => ({ ...s, isSending: true }));
      const bodyRequest = { reaction, detail: isSatisfied ? '' : text || '', rating: [], is_edited: hasChanged };
      await sendFeedback(conversion_id, bodyRequest, true);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickPlaceholder = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onInput = event => {
    const text = event.target.innerText;
    setForm(s => ({ ...s, feedbackText: text.trim(), hasText: text.trim().length > 0 }));
  };

  const onKeyDown = event => {
    const { keyCode } = event;

    if (keyCode > 36 && keyCode < 41) {
      event.stopPropagation();
      return;
    }
  };

  const onSatisfiedClick = reaction => () => {
    if (reaction === ReactionStatus.Satisfied) {
      onSubmitFeedback(true);
    } else {
      setForm(s => ({ ...s, reaction }));
      setTimeout(() => {
        if (inputRef.current && inputRef.current.focus) {
          inputRef.current.focus();
        }
      }, 300);
    }
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const { reaction, isSending, hasText } = form;

  return (
    <Modal id="ai-feedback-modal" open className="confirm-popup-container feedback-saved" closeOnDimmerClick={false}>
      <Modal.Content className="confirm-content">
        <HeadIcon className="head-ai" />
        {!hasChanged && <h2>Are you satisfied with the result?</h2>}
        <p className={classNames({ 'des-changed': hasChanged })}>
          {hasChanged
            ? 'We noticed you made changes after we generated the workout. Does this final version match the original workout text you were trying to input?'
            : 'Did you get the exact input you were expecting?'}
        </p>
        <div className="buttons">
          <Button
            className={classNames('confirm-yes-button', { selected: reaction === ReactionStatus.Satisfied })}
            onClick={onSatisfiedClick(ReactionStatus.Satisfied)}
          >
            Yes
          </Button>
          <Button
            className={classNames('confirm-no-button', { selected: reaction === ReactionStatus.NotSatisfied })}
            onClick={onSatisfiedClick(ReactionStatus.NotSatisfied)}
          >
            No
          </Button>
        </div>
        {reaction === ReactionStatus.NotSatisfied && (
          <div className="feedback-input-wrap">
            <TextEditable
              contentEditable
              ref={inputRef}
              onKeyDown={onKeyDown}
              onInput={onInput}
              placeholder=""
              className="feedback-input"
            />
            {!hasText && (
              <span className="input-placeholder" onClick={handleClickPlaceholder}>
                Tell us more about the issues
              </span>
            )}
            <Button
              className="confirm-submit-button"
              onClick={() => onSubmitFeedback(false)}
              disabled={isSending || !hasText}
            >
              Submit
            </Button>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default FeedbackAfterSaved;
