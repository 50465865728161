import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Image, Button } from 'semantic-ui-react';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { diff } from 'deep-diff';
import { getClientCountdownList, getCountdownDetail, updateCountdown } from 'redux/client-countdown/action';
import { toggleConfirmModal, toggleSecondModal } from 'actions/modal';

import AddAndEditCountdown from '../CreateNewCountdown/AddAndEditCountdown';
import { CDN_URL } from 'constants/commonData';
// assets
import * as S from './style';
import { CustomConfirmModal } from '../ConfirmModal/style';

const EditCountdown = props => {
  const {
    toggleSecondModal,
    updateCountdown,
    countdownId,
    toggleConfirmModal,
    getCountdownDetail,
    getClientCountdownList,
    clientId = '',
  } = props;
  const [formDataEdit, setFormDataEdit] = useState({});
  const [originEditData, setOriginEditData] = useState({});
  const [isGetLoading, setGetLoading] = useState(false);
  const pickArr = [
    'date',
    'title',
    'icon',
    'color',
    'is_all_day',
    'remind_at_finish_time',
    'remind_one_day_before',
    'remind_one_week_before',
  ];

  const isChanged = useMemo(() => {
    if (!formDataEdit.is_all_day) {
      pickArr.push('time');
    }
    const oldData = pick(originEditData, pickArr);
    const newData = pick(formDataEdit, pickArr);

    return diff(oldData, newData);
  }, [formDataEdit]);

  const handleGetCountdown = () => {
    setGetLoading(true);
    getCountdownDetail(countdownId)
      .then(res => {
        const { data = {} } = res.data;
        setOriginEditData(pick(data, [...pickArr, 'time']));
      })
      .finally(() => setGetLoading(false));
  };

  useEffect(() => {
    handleGetCountdown();
  }, []);

  const isDisableButton = !isChanged || !(formDataEdit || {}).title || !(formDataEdit || {}).date || isGetLoading;

  const handleClose = () => {
    if (!isChanged) {
      toggleSecondModal(false);
      return;
    }

    toggleConfirmModal(
      true,
      <CustomConfirmModal
        onConfirm={() => toggleSecondModal(false)}
        onDeny={() => toggleConfirmModal(false)}
        headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        confirmButtonTitle="Discard Changes"
        title="Discard Changes?"
        noBorder
        hasCloseIcon
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  const handleChangeFormEdit = formData => {
    setFormDataEdit(formData);
  };

  const handleUpdateCountdown = () => {
    const params = {
      _id: countdownId,
      ...formDataEdit,
    };
    updateCountdown(params, () => {
      getClientCountdownList({ page: 1, client: clientId });
    });
    toggleSecondModal(false);
  };

  return (
    <S.EditPopupStyled open={true} onClose={handleClose} className="edit-countdown-popup" closeOnDimmerClick={false}>
      <Modal.Content>
        <Button onClick={handleClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <S.PopupTitle>Edit countdown</S.PopupTitle>
        {isGetLoading ? (
          <div className="countdown-wrapper"></div>
        ) : (
          <div className="countdown-wrapper">
            <AddAndEditCountdown {...props} editData={originEditData} onChangeFormCreate={handleChangeFormEdit} />
          </div>
        )}
        <Modal.Actions>
          <S.CreateButton onClick={handleUpdateCountdown} purple disabled={isDisableButton}>
            Save Changes
          </S.CreateButton>
        </Modal.Actions>
      </Modal.Content>
    </S.EditPopupStyled>
  );
};

const mapState = ({ rootReducer }) => {
  const { isRequestCountdown, countdownList, templateList } = rootReducer.clientCountdown || {};
  const clientId = get(rootReducer, 'client.selected', '');
  return {
    isRequestCountdown,
    countdownList,
    templateList,
    clientId,
  };
};

const mapDispatch = {
  toggleSecondModal,
  toggleConfirmModal,
  updateCountdown,
  getCountdownDetail,
  getClientCountdownList,
};

export default connect(mapState, mapDispatch)(EditCountdown);
