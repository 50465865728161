import React from 'react';

import { Helmet } from 'react-helmet';
import { Wrapper } from './style';

const StudioHeader = props => {
  return (
    <Wrapper>
      <Helmet>
        <title>{props.title ? `${props.title} - Everfit` : ''}</title>
      </Helmet>
      <div className="studio__name">
        <span>{props.title || ''}</span>
      </div>
    </Wrapper>
  );
};

export default StudioHeader;
