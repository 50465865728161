import styled, { css } from 'styled-components';
import { buttonStyle } from '../ChartHeader/style';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

const baseText = `
        color: #202353;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        font-size: 13px;
        `;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 99;
`;

const activePopup = css`
  background: #f0f1ff;
  color: #5158cf;
`;

export const Trigger = styled.div`
  ${buttonStyle}
  border-left: none;
  border-radius: 0px 5px 5px 0px;
  ${({ active }) => active && activePopup}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed !important;
    `}
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 357px;
  height: 435px;
  position: absolute;
  top: 39px;
  right: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  font-size: 13px;
  font-weight: 400;
`;

export const MiddleLine = styled.div`
  width: 1px;
  height: 100%;
  background-color: #f0f0f2;
`;

export const ShowStartDate = styled.div`
  display: flex;
  width: 170px;
  padding: 7px 14px 4px 14px;
  justify-content: space-between;
  border-radius: 5px;
  background: #fff;
  margin-right: 1px;

  ${({ isFocus }) =>
    isFocus &&
    css`
      box-shadow: #5559ff 0px 0px 2px, #5559ff 0px 0px 0px 1px;
    `}
`;

export const ShowEndDate = styled.div`
  display: flex;
  width: 166px;
  padding: 7px 14px 4px 10px;
  justify-content: space-between;
  border-radius: 5px;
  background: #fff;

  ${({ isFocus }) =>
    isFocus &&
    css`
      box-shadow: #5559ff 0px 0px 2px, #5559ff 0px 0px 0px 1px;
    `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  margin: 15px 16px 20px;
  margin-bottom: 0;
  align-items: flex-start;
  border-radius: 5px;
  width: 327px;
  border: 1px solid #f0f0f2;
  cursor: pointer;

  :hover {
    ${MiddleLine} {
      background-color: transparent;
    }

    ${ShowStartDate}, ${ShowEndDate} {
      :hover {
        box-shadow: #5559ff 0px 0px 2px, #5559ff 0px 0px 0px 1px;
      }
    }
  }
`;

export const HeaderLabel = styled.div`
  ${baseText}
  color: #7b7e91;
  font-size: 10px;
  text-transform: uppercase;
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .remove-icon {
    width: 16px;
    height: 16px;

    path {
      fill: #202353;
    }
  }
`;

export const SelectedDate = styled.div`
  ${baseText}
  font-weight: 400;
  padding-top: 5px;

  .empty-text {
    ${baseText}
    color: #A3A3B5;
    font-weight: 400;
  }
`;

export const DateTabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item {
    border: 1px solid #d4d7d9;
    border-radius: 5px;
    background: #fafbfc;
    padding: 8px 20px 8px 15px;
    min-width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      :hover {
        cursor: pointer;
      }
    }

    :first-child {
      border-right-color: transparent;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -3px;
    }

    :last-child {
      border-left-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.active {
      background: #fff;
      border: 1px solid #5158cf;
      border-radius: 5px;
      z-index: 1;
    }

    .label {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #6a778a;
    }

    .placeholder {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 21px !important;
  border-top: 1px solid #f0f0f2;
  z-index: 2;
`;

export const BodyWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-weight: 600;

  > button {
    background: none !important;
  }

  .rdt.rdtOpen {
    z-index: 1;

    .form-control {
      display: none;
    }
  }

  .rdtSwitch {
    font-size: 14px;
  }

  .rdt div.form-control[type='text'] {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #273d52;
    padding: 0 2px;
    cursor: pointer;
  }

  .rdtPicker {
    margin-top: 13px;
    padding: 7px 24px 24px;
    width: auto;
    min-width: 357px;
    min-height: 295px;
    font-family: 'Open Sans';
    border-radius: 8px;
    box-shadow: none;
    border: none;
    background-color: #fff;

    table {
      border-collapse: collapse;

      tbody {
        tr:first-child {
          td {
            .content {
              margin-top: 5px;
            }
          }
        }
      }

      thead {
        border-bottom: 1px solid #f0f0f2;
        tr:first-child {
          th {
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            ${baseText}
            color: #202353;
            border: none;
            height: auto;
            padding-bottom: 20px;

            :hover {
              background-color: transparent;
            }

            &.rdtPrev,
            &.rdtNext {
              width: 30px;
              span {
                text-indent: -9999px;
                display: block;
                height: 100%;
                background-repeat: no-repeat;
              }
            }
            &.rdtPrev {
              span {
                background-image: url(${CDN_URL}/images/arrow-left-gray-icon.svg);
                background-size: 16px;
                background-position: 0 center;
              }
              :hover span {
                background-image: url(${CDN_URL}/images/left-arrow-bold.svg);
              }
            }

            &.rdtNext {
              span {
                background-image: url(${CDN_URL}/images/arrow-left-gray-icon.svg);
                transform: rotate(180deg);
                background-position: 0 center;
              }
              :hover span {
                background-size: 16px;
                background-image: url(${CDN_URL}/images/right-arrow-bold.svg);
                transform: rotate(0deg);
                background-position: right center;
              }
            }
          }
        }
      }
    }

    .dow {
      ${baseText}
      font-size: 13px;
      text-transform: uppercase;

      padding-bottom: 8px;
    }

    .rdtDay {
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #202353;
      width: 44px;
      height: 36px;
      padding: 1px 0;
      background-color: #fff;

      &:hover {
        background-color: #fff;
        .content {
          > div {
            background-color: #f0f1ff;
          }
        }
      }

      &.rdtOld,
      &.rdtDisabled {
        color: #7b7e91;
      }

      &.rdtDisabled:hover {
        .content {
          > div {
            background-color: transparent !important;
          }
        }
      }

      .content {
        padding-left: 5px;
        > div {
          padding-top: 7px;
          display: flex;
          width: 34px;
          height: 34px;
          text-align: center;
          justify-content: center;
          line-height: 150%;
          border-radius: 50%;
        }
      }

      &.inRange {
        .content {
          background-color: #f0f1ff;
        }
        :hover {
          .content {
            > div {
              background-color: #f0f1ff;
            }
          }
        }
      }

      &.rdtToday {
        ::before {
          display: none;
        }
      }

      &.rdtActive {
        ::before {
          display: none;
        }

        :hover {
          .content {
            > div {
              background-color: #f0f1ff;
              color: #202353;
              box-shadow: none;
            }
          }
        }
      }

      &.rangeEnd {
        .content {
          z-index: 1;
          background-color: transparent;
          > div {
            background-color: #5158cf;
            border-radius: 50%;
            color: #fff;
          }
          position: relative;

          ::after {
            position: absolute;
            content: '';
            width: 34px;
            height: 34px;
            background: #f0f1ff;
            top: 0;
            left: 0;
            z-index: -1;

            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
          }
        }
        :hover {
          .content {
            > div {
              background-color: #5158cf;
              color: #fff;
            }
          }
        }
      }

      &.rangeStart {
        .content {
          z-index: 1;
          background-color: transparent;
          position: relative;

          > div {
            background-color: #5158cf;
            border-radius: 50%;
            color: #fff;
          }

          ::after {
            position: absolute;
            content: '';
            width: 34px;
            height: 34px;
            background: #f0f1ff;
            top: 0;
            right: 0px;
            z-index: -1;

            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
          }
        }
        :hover {
          .content {
            > div {
              background-color: #5158cf;
              color: #fff;
            }
          }
        }
      }
    }
  }

  ${({ isOne }) =>
    isOne &&
    css`
      .rdtDay.rangeEnd .content::after,
      .rdtDay.rangeStart .content::after,
      .rdtDay.inRange .content {
        background: transparent !important;
      }

      .rdtPicker .rdtDay.rdtToday.inRange:hover .content > div {
        background-color: #5158cf;
        color: #fff;
      }
    `}
`;

export const CancelButton = styled(Button)`
  display: flex;
  background-color: transparent;
  padding: 10px 15px;
  min-width: 72px !important;
  max-height: 36px !important;
  ${baseText}
  font-size: 14px;
  color: #7b7e91;
  border: none;
  margin-right: 11px;
`;

export const ApplyButton = styled(Button)`
  display: flex;
  background-color: transparent;
  padding: 10px 15px;
  min-width: 66px !important;
  max-height: 36px !important;
  ${baseText}
  font-size: 14px;
  border: none;
  color: #7b7e91;
  cursor: not-allowed;

  ${({ isCustom }) =>
    isCustom &&
    css`
      cursor: pointer;
      color: #5158cf;
      :hover {
        background-color: #f0f1ff !important;
      }
    `}

  &:disabled {
    background-color: transparent;
    color: #a3a3b5;
  }

  &:hover {
    background-color: transparent;
  }
`;
