import { connect } from 'react-redux';
import SelectProgram from './component';
import { assignProgram } from 'redux/autoflow/training/actions';
import { DEFAULT_SUBMIT_STATUS } from 'constants/commonData';

const mapStateToProps = state => {
  const { isModalOpen, rootReducer, submitStatus, cloudfrontList } = state;
  const { all_items } = rootReducer.program_library;
  return {
    isModalOpen,
    cloudfrontList,
    all_items,
    previewItem: rootReducer.program_library.preview_selected_item,
    submitStatus: submitStatus.SELECT_PROGRAM || DEFAULT_SUBMIT_STATUS,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    assignProgram: (date, programId) => {
      const params = {
        paste_day: date.toFormat('MM-dd-yyyy'),
        program_id: programId,
      };

      return dispatch(assignProgram(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProgram);
