import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Avatar from 'react-avatar';
import { TopUserItem } from './style';
import { formatLeaderboardRanking, getUserShortName } from 'utils/commonFunction';
import { ReactComponent as CupIcon } from 'assets/icons/cup_blur.svg';
import { ReactComponent as UserDefaultAvatar } from 'assets/icons/user_default_avatar.svg';
import { LEADERBOARD_STATUS } from 'constants/commonData';

export default ({ top1, top2, top3, unit, status }) =>
  status === LEADERBOARD_STATUS.UPCOMING || !top1 ? (
    <div className={classnames('empty', { upcoming: status === LEADERBOARD_STATUS.UPCOMING })}>
      <CupIcon />
      {status === LEADERBOARD_STATUS.UPCOMING ? <div>Leaderboard has not started</div> : null}
    </div>
  ) : (
    <div className="topUser">
      <TopUserItem key="top2">
        <div className="userInfo">
          {top2 ? (
            <>
              <Avatar name={getUserShortName(top2.user)} size="42" src={top2.user.avatar} color={top2.user.color} />
              <div className="userName">
                {top2.user.first_name} {top2.user.last_name}
              </div>
              <div className="score">
                {Number(top2.score).toSeperateFormat()} {formatLeaderboardRanking(unit, top2.score)}
              </div>
            </>
          ) : (
            <>
              <UserDefaultAvatar className="sb-avatar" />
              <div className="userName" style={{ opacity: 0.7 }}>
                -
              </div>
            </>
          )}
        </div>
        <div className="rankingPosition top2"></div>
      </TopUserItem>
      <TopUserItem key="top1">
        <div className="userInfo">
          <Avatar name={getUserShortName(top1.user)} size="42" src={top1.user.avatar} color={top1.user.color} />
          <div className="userName">
            {top1.user.first_name} {top1.user.last_name}
          </div>
          <div className="score">
            {Number(top1.score).toSeperateFormat()} {formatLeaderboardRanking(unit, top1.score)}
          </div>
        </div>
        <div className="rankingPosition top1"></div>
      </TopUserItem>
      <TopUserItem key="top3">
        <div className="userInfo">
          {top3 ? (
            <>
              <Avatar name={getUserShortName(top3.user)} size="42" src={top3.user.avatar} color={top3.user.color} />
              <div className="userName">
                {top3.user.first_name} {top3.user.last_name}
              </div>
              <div className="score">
                {Number(top3.score).toSeperateFormat()} {formatLeaderboardRanking(unit, top3.score)}
              </div>
            </>
          ) : (
            <>
              <UserDefaultAvatar className="sb-avatar" />
              <div className="userName" style={{ opacity: 0.7 }}>
                -
              </div>
            </>
          )}
        </div>
        <div className="rankingPosition top3"></div>
      </TopUserItem>
    </div>
  );
