import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FeatureTurnOff = styled.div`
  display: flex;
  background: #fffae6;
  padding: 3px 10px;
  border-radius: 6px;
  border: 1px solid #f3ce8a;
  color: #eea620;
  align-items: center;
  font-size: 12px;

  img {
    margin-right: 5px;
  }
`;

function NavBar({ featureTurnOff }) {
  if (featureTurnOff) {
    return (
      <Wrapper>
        <FeatureTurnOff>
          <img src={`${CDN_URL}/images/feature_turned_off.svg`} alt="" />
          <span>Task has been turned off</span>
        </FeatureTurnOff>
      </Wrapper>
    );
  }

  return null;
}

const mapState = state => {
  const {
    rootReducer: {
      autoflow: {
        settings: { features },
      },
    },
  } = state;

  const taskSetting = features.find(item => item.type === 'task');
  const featureTurnOff = taskSetting ? !taskSetting.state : false;
  return { featureTurnOff };
};

export default connect(mapState, null)(NavBar);
