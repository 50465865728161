import React from 'react';

import { CDN_URL, ONBOARDING_STEPS } from 'constants/commonData';
import EverButton from 'shared/EverButton';
import ClientMetricSetting from 'components/ClientSettings/ClientMetricSetting';

import * as S from './style';

const FooterBodyMetric = props => {
  const { showGuide, runOnboarding, toggleModal, handleRefreshBodyMetric } = props;

  const handleItemClick = () => {
    if (showGuide) {
      runOnboarding(ONBOARDING_STEPS.CUSTOMIZE_METRIC_STEP_2);
    }

    toggleModal(true, <ClientMetricSetting handleRefreshBodyMetric={handleRefreshBodyMetric} />);
  };

  return (
    <S.FooterBodyMetric>
      <EverButton
        icon={`${CDN_URL}/images/manage.svg`}
        iconHover={`${CDN_URL}/images/manage_purple.svg`}
        width={'14px'}
        height={'14px'}
        onClick={handleItemClick}
      >
        Manage Metrics
      </EverButton>
    </S.FooterBodyMetric>
  );
};

export default FooterBodyMetric;
