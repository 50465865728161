import React, { useMemo } from 'react';
import { get } from 'lodash';
import { pluralize, checkingValidUrl, replaceImageURL } from 'utils/commonFunction';
import { CheckBoxIconWrapper, WorkoutCollectionItemWrapper, CheckboxWorkout } from './style';
import { isS3FileURL } from 'utils/validations';

const CheckBoxIcon = ({ active, onChange }) => {
  return (
    <CheckBoxIconWrapper>
      <CheckboxWorkout checked={active} onChange={onChange} />
    </CheckBoxIconWrapper>
  );
};

export default function WorkoutCollectionItem({ workout, active, onSelect }) {
  const onClick = () => {
    onSelect(workout);
  };

  const thumbnail = useMemo(() => {
    const thumbnailImg = get(workout, 'background_thumbnail', false);
    const backgroundImg = get(workout, 'background', false);

    if (thumbnailImg && checkingValidUrl(get(workout, 'background_thumbnail', ''))) {
      return thumbnailImg;
    } else {
      if (backgroundImg && isS3FileURL(backgroundImg)) return replaceImageURL(backgroundImg);
      return;
    }
  }, [workout]);

  const categoryCounter = useMemo(() => {
    const counter = get(workout, 'number_of_categories', 0);
    return `${counter} ${counter === 1 ? 'Category' : 'Categories'}`;
  }, [workout]);

  return (
    <WorkoutCollectionItemWrapper active={active} coverImage={thumbnail} onClick={onClick}>
      <div className="workoutCollectionItem__avatar" />
      <div className="workoutCollectionItem__info">
        <div>
          <div className="workoutCollectionItem__info--title">{workout.name}</div>
          <div className="workoutCollectionItem__info--description">{workout.description}</div>
        </div>
        <div className="workoutCollectionItem__info--statistic">
          {categoryCounter} - {pluralize('Workout', workout.number_of_workouts, true)}
        </div>
      </div>
      <CheckBoxIcon active={active} onChange={onClick} />
    </WorkoutCollectionItemWrapper>
  );
}
