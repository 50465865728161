import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BillHistory from './component';
import { toggleModal, toggleSecondModal } from 'actions/modal';

const mapState = state => {
  const {
    rootReducer: {
      billing: { historyListData, pricingPlan },
    },
  } = state;

  return {
    historyListData: historyListData || [],
    invoice_over_due: pricingPlan.invoice_over_due,
    automation_meter_addons_overdue_invoice: pricingPlan.automation_meter_addons_overdue_invoice,
    zapier_meter_addons_overdue_invoice: pricingPlan.zapier_meter_addons_overdue_invoice,
  };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
});

export default connect(mapState, mapDispatch)(BillHistory);
