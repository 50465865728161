import { connect } from 'react-redux';
import ClientSettings from './component';
import { updateFeaturePreferences, getUnitPreferences, updateUnitPreferences, updateClientInfo } from 'actions/client';
import { getClientListSubCoach } from 'redux/client/client.actionCreators';
import { toggleModal } from 'actions/modal';
import { updateClientPreference, getClientMetrics } from 'redux/client/client.actionCreators';

const mapStateToProps = state => {
  const { user, rootReducer } = state;
  return {
    user,
    selectedClient: rootReducer.client.workingClientDetail,
    unit_categories: rootReducer.unit.unit_categories,
    permission: rootReducer.permission,
  };
};

const actionCreators = {
  updateFeaturePreferences,
  getUnitPreferences,
  updateUnitPreferences,
  updateClientInfo,
  updateClientPreference,
  toggleModal,
  getClientMetrics,
  getClientListSubCoach,
};

export default connect(mapStateToProps, actionCreators)(ClientSettings);
