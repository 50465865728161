import React, { useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { CDN_URL } from 'constants/commonData';
import * as S from './style';

const icons = {
  muted: `url(${CDN_URL}/images/sound_muted.svg)`,
  unmuted: `url(${CDN_URL}/images/sound_active.svg)`,
  play: `url(${CDN_URL}/images/play.svg)`,
  pause: `url(${CDN_URL}/images/pause_video.svg)`,
};

const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hideControls: true,
    };

    this.video = React.createRef();
    this.seekBar = React.createRef();
    this.mute = React.createRef();
    this.tooltip = React.createRef();
    this.playPause = React.createRef();
    this.volume = React.createRef();
  }

  componentDidMount() {
    this.seekBar.current.value = 0;
    this.volume.current.value = 1;
  }

  onPlaySound = event => {
    event.stopPropagation();
    this.setState({ hideControls: false }, () => {
      this.video.current.muted = false;
    });
  };

  updatePauseStatus = () => {
    this.playPause.current.style.backgroundImage = icons.play;
    this.tooltip.current.innerText = 'Play Video';
  };

  updatePlayingStatus = () => {
    this.playPause.current.style.backgroundImage = icons.pause;
    this.tooltip.current.innerText = 'Pause Video';
  };

  tooglePlay = () => {
    if (isVideoPlaying(this.video.current)) {
      this.video.current.pause();
      this.updatePauseStatus();
    } else {
      this.video.current.play();
      this.updatePlayingStatus();
    }
  };

  toogleSound = () => {
    const { muted } = this.video.current;

    if (!muted) {
      this.video.current.muted = true;
      this.mute.current.style.backgroundImage = icons.muted;
    } else if (this.video.current.volume) {
      this.video.current.muted = false;
      this.mute.current.style.backgroundImage = icons.unmuted;
    }
  };

  onVolumeChange = event => {
    event.persist();
    const { value } = event.target;
    this.video.current.volume = value;

    if (Number(value) === 0) {
      this.mute.current.style.backgroundImage = icons.muted;
      this.video.current.muted = true;
    } else if (this.video.current.muted) {
      this.mute.current.style.backgroundImage = icons.unmuted;
      this.video.current.muted = false;
    }
  };

  onTimeUpdate = event => {
    event.persist();
    const { currentTime, duration, ended, paused } = event.target;
    const range = (currentTime / duration) * 100;
    this.seekBar.current.value = range;

    if (ended && paused) {
      this.updatePauseStatus();
    }
  };

  onJump = event => {
    const { value } = event.target;
    const { duration, paused } = this.video.current;
    const time = (value * duration) / 100;
    this.video.current.currentTime = time;

    if (paused) {
      this.video.current.pause();
    }
  };

  onSeekBarMouseDown = () => {
    this.video.current.pause();
  };

  onSeekBarMouseUp = () => {
    this.video.current.play();
  };

  onControlsClick = event => {
    event.stopPropagation();
  };

  onWrapperClick = () => {
    if (!this.state.hideControls) {
      this.tooglePlay();
    }
  };

  getVideoCurrentTime = () => {
    return this.video.current.currentTime;
  };

  onEnded = event => {
    event.persist();

    if (typeof this.props.onEnded === 'function') {
      this.props.onEnded(event);
    }
  };

  render() {
    const { hideControls } = this.state;
    const { src } = this.props;

    return (
      <S.Wrapper className="evf-video-player" onClick={this.onWrapperClick}>
        <video
          src={src}
          autoPlay={true}
          muted={true}
          ref={this.video}
          onTimeUpdate={this.onTimeUpdate}
          onEnded={this.onEnded}
        />
        {hideControls ? (
          <S.ClickToPlaySound onClick={this.onPlaySound}>
            <img src={`${CDN_URL}/images/sound_active.svg`} alt="" />
            <span>Click to play sound</span>
          </S.ClickToPlaySound>
        ) : null}
        <S.ControlsContainer className={classNames('video-controls-container', { show: !hideControls })}>
          <S.Controls className="video-controls" onClick={this.onControlsClick}>
            <S.PlayPause className="play-pause" onClick={this.tooglePlay} ref={this.playPause}>
              <S.Tooltip className="evf-tooltip" ref={this.tooltip}>
                Pause Video
              </S.Tooltip>
            </S.PlayPause>
            <S.SeekBar
              ref={this.seekBar}
              step="any"
              onChange={this.onJump}
              onMouseDown={this.onSeekBarMouseDown}
              onMouseUp={this.onSeekBarMouseUp}
            />
            <S.Volumn>
              <S.Mute
                onClick={this.toogleSound}
                ref={this.mute}
                style={{ backgroundImage: `url(${CDN_URL}/images/sound_active.svg)` }}
              ></S.Mute>
              <S.VolumnBar>
                <input type="range" step="any" min="0" max="1" onChange={this.onVolumeChange} ref={this.volume} />
              </S.VolumnBar>
            </S.Volumn>
          </S.Controls>
        </S.ControlsContainer>
      </S.Wrapper>
    );
  }
}

export default VideoPlayer;
