import styled, { css } from 'styled-components';
import { Toggle, Button } from 'shared/FormControl';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #151619;
`;

export const ToggleWrapper = styled(Toggle)`
  margin-right: 8px;
  display: flex;
  min-width: 36px;
  input {
    width: 36px;
  }
  label {
    min-width: 36px;
  }
  input ~ label::before {
    background-color: #e4e5e7;
    width: 36px;
  }
  input:checked ~ label::after,
  input:checked:focus ~ label::after {
    left: calc(36px - 18px);
  }

  input:checked ~ label::before,
  input:checked:focus ~ label::before {
    background: #5e59ff;
    background: #5e59ff;
  }
`;

export const ButtonWrapper = styled(Button)`
  width: 85px;
  height: 36px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17.7px;
  &:hover {
    background-color: rgba(81, 88, 207, 0.9);
  }
  ${props =>
    props.show &&
    css`
      visibility: hidden;
    `}
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0;
`;
export const CompareMode = styled.div`
  ${baseText}
  color: #728096;
  display: flex;
  align-items: center;
`;
