import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SegmentSidebar from './component';
import { push } from 'connected-react-router';
import { selectSegment, hideSegment, activeSegment, activeWaitingSegment } from 'redux/segment/actions';
import { toggleModal } from 'actions/modal';
import { markSeenWaitingList } from 'redux/waiting-activation-clients/actions';

const mapStateToProps = state => {
  const { rootReducer, user, router } = state;
  const { segment, waitingActivationClients, client } = rootReducer;
  return {
    user,
    router,
    segments: segment.list,
    selectedSegment: segment.selected,
    totalClients: segment.totalClients,
    permission: rootReducer.permission,
    hasNewWaitingClients: waitingActivationClients.get('hasNew'),
    workingClientDetail: client.workingClientDetail,
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  selectSegment: bindActionCreators(selectSegment, dispatch),
  hideSegment: bindActionCreators(hideSegment, dispatch),
  activeSegment: bindActionCreators(activeSegment, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  activeWaitingSegment: bindActionCreators(activeWaitingSegment, dispatch),
  markSeenWaitingList: bindActionCreators(markSeenWaitingList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentSidebar);
