import React from 'react';
import _ from 'lodash';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { Checkbox } from 'shared/FormControl';
import ActionTrigger from 'shared/Dropdown/Trigger';
import { ClientStatus } from './style';
import { AUTOFLOW_CLIENT_STATUS, CDN_URL } from 'constants/commonData';
import { timeSince, getUserShortName } from 'utils/commonFunction';
import { isTeamAdmin } from 'utils/commonFunction';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { SharedTooltip } from 'shared/SharedTooltip';

export default [
  {
    key: 'name',
    fieldData: 'name',
    label: 'Name',
    sortKey: 'name',
    icon: `${CDN_URL}/images/new_person.svg`,
    mutate: function (data) {
      const { client } = data;
      const { user } = this.props;
      const trainer = _.get(client, 'trainer', {});
      // check client is subcoach (start)
      const subTrainers = _.get(client, 'sub_trainers', []);
      const isSubTrainer = _.findIndex(subTrainers, function (o) {
        return o.trainer._id === user._id;
      });
      // check client is subcoach (end)
      const isChecked = this.props.selectedClients.some(item => item._id === client._id);
      const tooltipId = `client-status-tooltip__${data._id}`;
      let clientStatus = 'Waiting to start',
        statusClass = '';

      if (data.is_completed) {
        statusClass = 'completed';
        clientStatus = 'Completed';
      } else {
        if (data.status === AUTOFLOW_CLIENT_STATUS.ACTIVE) {
          statusClass = 'active';
          clientStatus = 'Active';
        }

        if (data.status === AUTOFLOW_CLIENT_STATUS.PAUSE) {
          statusClass = 'pause';
          clientStatus = 'Paused';
        }
      }

      return (
        <div className="autoflow-client__profile">
          <div className="autoflow-client__profile__avatar">
            {(data.canEdit && !data.is_archived) || !data.is_completed ? (
              <Checkbox
                checked={isChecked}
                className="select-checkbox"
                onChange={event => {
                  this.onSelectClient({ ...client, status: data.status }, event.target.checked);
                }}
              />
            ) : null}
            <Avatar name={getUserShortName(client)} size="29" src={client.avatar} color={client.color} />
          </div>
          {!isTeamAdmin(user) && trainer._id !== user._id && isSubTrainer === -1 ? ( // isSubTrainer is check subcoach
            <div className="autoflow-client__profile__name disabled">
              <ClientStatus className={statusClass} data-tip data-for={tooltipId} />
              <div className="autoflow-client__profile__name__value">{client.full_name}</div>
            </div>
          ) : (
            <Link className="autoflow-client__profile__name" to={`/home/client/${client._id}`}>
              <ClientStatus className={statusClass} data-tip data-for={tooltipId} />
              <div className="autoflow-client__profile__name__value">{client.full_name}</div>
            </Link>
          )}
          {data.is_completed ? (
            <>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-tip
                data-for={`tooltip--completed-marker--${data._id}`}
                className="completed-marker"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM7 11.4L3.6 8L5 6.6L7 8.6L11 4.6L12.4 6L7 11.4Z"
                  fill="#716DE0"
                />
              </svg>

              <ReactTooltip
                className="app-tooltip autoflow-exactdate-tooltip"
                id={`tooltip--completed-marker--${data._id}`}
                effect="solid"
                place="top"
              >
                <span>Completed</span>
              </ReactTooltip>
            </>
          ) : null}
          {!data.is_archived && (
            <ReactTooltip className="app-tooltip autoflow-exactdate-tooltip" id={tooltipId} effect="solid" place="top">
              <span>{clientStatus}</span>
            </ReactTooltip>
          )}
        </div>
      );
    },
  },
  {
    key: 'last_activity',
    fieldData: 'last_activity',
    label: 'Last activity',
    sortKey: 'last_activity',
    icon: `${CDN_URL}/images/clock.svg`,
    mutate: function (data) {
      const { last_activity } = data;
      return last_activity ? timeSince(new Date(last_activity)) : '--';
    },
  },
  {
    key: 'last_responded',
    fieldData: 'last_responded',
    label: 'Last Responded',
    sortKey: 'last_responded',
    icon: `${CDN_URL}/images/clock.svg`,
    mutate: function (data) {
      const { last_responded } = data;
      return last_responded ? timeSince(new Date(last_responded)) : '--';
    },
  },
  {
    key: 'last_engaged',
    fieldData: 'last_engaged',
    label: 'Last Engaged',
    sortKey: 'last_engaged',
    icon: `${CDN_URL}/images/clock.svg`,
    mutate: function (data) {
      const { last_engaged } = data;
      return last_engaged ? timeSince(new Date(last_engaged)) : '--';
    },
  },
  {
    key: 'action',
    fieldData: 'action',
    label: 'Actions',
    hideLabel: true,
    mutate: function (data) {
      const { client } = data;
      const { user } = this.props;
      const trainer = _.get(client, 'trainer', {});
      // check client is subcoach (start)
      const subTrainers = _.get(client, 'sub_trainers', []);
      const isSubTrainer = _.findIndex(subTrainers, function (o) {
        return o.trainer._id === user._id;
      });
      // check client is subcoach (end)
      const canAction = isTeamAdmin(user) || trainer._id === user._id || isSubTrainer !== -1; // isSubTrainer is check subcoach

      if (!client || !canAction) return null;

      const interactable = !data.is_archived && !data.is_completed;

      const renderTrigger = ({ open }) => (
        <div data-tip data-for="client-autoflow-table-tooltip">
          <ActionTrigger />
          {!open && <SharedTooltip id="client-autoflow-table-tooltip" />}
        </div>
      );

      return (
        <DropDown triggerIcon={renderTrigger}>
          {interactable && data.status !== AUTOFLOW_CLIENT_STATUS.ACTIVE ? (
            <Option onClick={() => this.onActivateClient(data)}>
              <div className="item__content">
                <Image src={`${CDN_URL}/images/play_white.svg`} />
                Activate
              </div>
            </Option>
          ) : null}
          {interactable && data.status === AUTOFLOW_CLIENT_STATUS.ACTIVE ? (
            <Option onClick={() => this.onPauseClient(data)}>
              <div className="item__content">
                <Image src={`${CDN_URL}/images/pause_white.svg`} width={14} />
                Pause
              </div>
            </Option>
          ) : null}
          {interactable ? (
            <Option onClick={() => this.onMaskAsComplete(data)}>
              <div className="item__content">
                <Image src={`${CDN_URL}/images/mask_as_complete_white.svg`} width={14} />
                Mark as Complete
              </div>
            </Option>
          ) : null}
          <Option onClick={() => this.onDeleteClient(data)}>
            <div className="item__content">
              <Image src={`${CDN_URL}/images/delete.svg`} width={14} />
              Remove
            </div>
          </Option>
        </DropDown>
      );
    },
  },
];
