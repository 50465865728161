import React from 'react';
import classNames from 'classnames';
import * as S from './style';
import ReactTooltip from 'react-tooltip';
import { CDN_URL } from 'constants/commonData';

export default class ProgramDescriptionEditable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.description,
    };
    this.textareaRef = React.createRef();
  }

  componentDidMount() {}

  onChange = e => {
    this.setState({ value: e.target.value });
  };

  onFocus = e => {
    if (this.props.disabled) return;
    this.setState(
      {
        value: this.props.description,
        isEdit: true,
        isViewMode: false,
      },
      () => {
        this.textareaRef.current && this.textareaRef.current.focus();
      },
    );
  };

  onCancel = () => {
    this.setState({ isEdit: false, isViewMode: false });
  };

  onViewMore = () => {
    this.setState({ isViewMode: true });
  };

  cancelViewMode = () => {
    this.setState({ isViewMode: false });
  };

  onConfirmChange = () => {
    const description = this.textareaRef.current && this.textareaRef.current.innerText;
    this.props.onConfirmChange({ description }, 'description').finally(() => {
      this.setState({ isEdit: false });
    });
  };

  render() {
    const { value, isEdit, isViewMode } = this.state;
    let { description, disabled = false } = this.props;
    description = description || '';
    const shortDescription = description.substring(0, 160) + '...';
    const isViewMore = description.length > 180;
    return (
      <S.Wrapper isEdit={isEdit} isViewMode={isViewMode} disabled={disabled}>
        {description || isEdit ? (
          <>
            <div
              ref={this.textareaRef}
              value={value}
              dangerouslySetInnerHTML={{
                __html: isViewMore && !isViewMode && !isEdit ? shortDescription : description,
              }}
              contentEditable={!disabled}
              role="textbox"
              className={classNames('descriptionInput__textArea', {
                'descriptionInput__textArea--empty': !description,
              })}
              onClick={this.onFocus}
            />
            {isViewMode && (
              <div
                className="descriptionInput__cancelViewMode"
                onClick={this.cancelViewMode}
                data-tip
                data-for="cancel-view-mode"
              >
                <ReactTooltip id="cancel-view-mode" effect="solid" place={'top'}>
                  Minimize description
                </ReactTooltip>
              </div>
            )}
            {isViewMore && !isEdit && !isViewMode && (
              <span className="descriptionInput__viewMore" onClick={this.onViewMore}>
                Read More
              </span>
            )}
            {isEdit && (
              <S.EditActionWrapper>
                <span className="editAction__cancel" onClick={this.onCancel}>
                  Cancel
                </span>
                <span className="editAction__confirmChange" onClick={this.onConfirmChange}>
                  Confirm Change
                </span>
              </S.EditActionWrapper>
            )}
          </>
        ) : (
          <div
            className={classNames('descriptionInput__textArea', { 'descriptionInput__textArea--empty': !description })}
            onClick={this.onFocus}
          >
            {description ? (
              description
            ) : (
              <>
                <img src={`${CDN_URL}/images/studio_program_edit_description.svg`} alt="" />
                <span>Add program description</span>
              </>
            )}
          </div>
        )}
      </S.Wrapper>
    );
  }
}
