import React from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import ItemCheckbox from './ItemCheckbox';
import { StatusIcon } from '../Card/styles';

import * as S from './styles';

function CustomCheckbox(props) {
  const { options, value, disabled, emptyMessage, checkedTitleDefault = '' } = props;
  const showEmptyDot = find(options, item => item.showEmptyDot);

  if (disabled) {
    const optionsEnable = filter(options, ({ key }) => get(value, key, false));
    const isTrialConfiguration = find(options, item => item.isTrialConfiguration);
    if (isEmpty(optionsEnable) && emptyMessage) {
      return (
        <S.CheckboxWrapper>
          <S.NotArchive>
            {showEmptyDot ? (
              <S.TrialReminderDisable>
                <StatusIcon statusColor="#818DA1" />
                {emptyMessage}
              </S.TrialReminderDisable>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: emptyMessage }} />
            )}
          </S.NotArchive>
        </S.CheckboxWrapper>
      );
    }

    if (isTrialConfiguration) {
      return options.map(option => (
        <S.CheckboxWrapper>
          <S.NotArchive
            dangerouslySetInnerHTML={{ __html: value[option.key] ? option.desc : option.uncheckedDesc }}
          ></S.NotArchive>
        </S.CheckboxWrapper>
      ));
    }

    return (
      <S.CheckboxWrapper>
        {checkedTitleDefault && (
          <div className="card-checkbox-readonly" dangerouslySetInnerHTML={{ __html: checkedTitleDefault }} />
        )}
        {optionsEnable.map(({ key, desc }, index) => {
          const customDescHtml = { __html: desc };
          return (
            <div key={`${key}_${index}`} className="card-checkbox-readonly" dangerouslySetInnerHTML={customDescHtml} />
          );
        })}
      </S.CheckboxWrapper>
    );
  }

  return (
    <S.CheckboxWrapper>
      {options &&
        options.map((option, index) => <ItemCheckbox option={option} index={index} key={`item_${index}`} {...props} />)}
    </S.CheckboxWrapper>
  );
}

export default CustomCheckbox;
