import React from 'react';
import _ from 'lodash';
import Item from './Item';
import Header from '../Header';

import * as S from './style';
import LoadingIndicator from 'shared/LoadingIndicator';

class StudioProgramList extends React.Component {
  constructor(props) {
    super(props);
    this.handleScrollDebounce = _.debounce(this.handleScrollDebounce, 500);
  }

  handleScroll = event => {
    event.persist();
    this.handleScrollDebounce.call(null, event);
  };

  handleScrollDebounce = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const { list, total, loadMore, isSearching } = this.props;
    const bottom = scrollHeight - (scrollTop + clientHeight);
    if (list.length < total && bottom < 150 && !isSearching) {
      loadMore();
    }
  };

  render() {
    const { list, isSearching } = this.props;

    return (
      <S.Wrapper onScroll={this.handleScroll}>
        <Header banner />
        {!isSearching && !list.length ? <S.NoResult>No results found</S.NoResult> : null}
        <S.List>
          {isSearching ? (
            <S.LoadingWrapper>
              <LoadingIndicator size="medium" title="Loading studio programs" className="loading--studio-programs" />
            </S.LoadingWrapper>
          ) : (
            <>
              {list.map((item, index) => (
                <Item id={`program-${index}`} key={item._id} studioProgram={item} />
              ))}
            </>
          )}
        </S.List>
      </S.Wrapper>
    );
  }
}

export default StudioProgramList;
