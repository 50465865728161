import styled, { css } from 'styled-components';

export const Container = styled.div`
  &${props => props.customClassName} {
    width: 96%;
  }
  ${props =>
    !props.readOnly &&
    css`
      > div {
        max-height: 105px;
        overflow-y: scroll;
      }
    `};
`;

export const MentionEditorWrapper = styled.div`
  .DraftEditor-editorContainer {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #141632;
    word-break: break-word;
  }
  .draftJsMentionPlugin__mentionSuggestions__2DWjA {
    box-shadow: 0 4px 8px -4px rgb(0 0 0 / 30%) !important;
    border: solid 1px #e0e0e8 !important;
    border-radius: 5px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: visible;
    width: auto;
    max-height: 205px;
  }
  .draftJsMentionPlugin__mention__29BEd,
  .draftJsMentionPlugin__mention__29BEd:hover,
  .draftJsMentionPlugin__mention__29BEd:visited {
    background-color: transparent;
    font-weight: 600;
    color: #0068e1;
    word-break: break-all;
    display: initial;
  }
  .public-DraftEditorPlaceholder-inner {
    position: absolute;
    color: rgb(0, 0, 0);
    opacity: 0.2;
  }

  .mention-item {
    display: flex;

    &:hover {
      background-color: #f6f5ff;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      .client-detail-avatar {
        font-size: 10px !important;
        span {
          font-size: 10px !important;
        }
      }

      .name {
        width: 200px;
        padding-left: 10px;
        font-size: 14px;
        font-family: 'Open Sans';
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .role {
        color: #202353;
        background: #eeeff4;
        font-size: 10px;
        line-height: 18px;
        padding: 2px 10px;
        margin-left: 8px;
        text-align: center;
        border-radius: 20px;
        white-space: nowrap;
      }
    }
  }
`;
