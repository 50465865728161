import styled from 'styled-components';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

export const PackageListNavArea = styled.div`
  display: flex;
`;

export const PackageListUpgradePathText = styled.div`
  display: inherit;
  padding-left: 10px;
  align-items: center;
  cursor: pointer;
`;

export const PackageListUpgradePathLink = styled(UpgradePathButton)`
  color: #f4844e;
  background: transparent;
  padding: 0;
  border-radius: 0;

  span {
    color: #f4844e;
    font-weight: 600;
    font-size: 13px;
    text-transform: initial;
  }

  &:active,
  &:focus,
  &:hover {
    color: #f4844e;
    background: transparent;
    text-decoration: none !important;
  }
`;

export const Label = styled.span`
  padding-left: 8px;
  color: #f4844e;
  font-weight: 600;
  font-size: 13px;
  text-transform: initial;
`;
