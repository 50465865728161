import { get } from 'lodash';
import React from 'react';
import Linkify from 'react-linkify';

import * as S from '../styles';

export default function LongAnswerResponse({ answer }) {
  const textAnswer = get(answer, 'answer.text_answer');
  return (
    <S.ShortAnswerContainer>
      <S.AnswerContentWrapper>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {textAnswer}
        </Linkify>
      </S.AnswerContentWrapper>
    </S.ShortAnswerContainer>
  );
}
