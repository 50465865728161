import React, { useMemo } from 'react';
import get from 'lodash/get';

// Store
import ColorPicker from '../ColorPicker';

// Components
import { Checkbox, Toggle } from 'shared/FormControl';
import {
  COLOR_OPTIONS,
  DATA_POINT_KEYS,
  DATA_POINT_OPTIONS,
  KEYS_METRIC,
} from 'components/BodyMetricChartNew/constants';

//Assets
import { ReactComponent as LineChartIcon } from 'assets/icons/line-chart.svg';
import { ReactComponent as BarChartIcon } from 'assets/icons/bar-chart.svg';

import * as S from './style';

const SleepMetricSettingModal = props => {
  const {
    customBrandingColor,
    settings,
    handleClose,
    handleSelectChartType,
    handleSelectDataPointOption,
    customBrandColor,
    handleSelectColor,
    // metricSettings,
    // unitOptions,
    // handleSelectUnit,
    // defaultUnit,
    // isCheckedUpdateUnit,
    // isChangeUnit,
    // handleChangeToggle,
    handleSelectKeyMetric,
    applySetting,
  } = props;
  // const listUnit = useMemo(() => unitOptions.sort(item => (item.name === 'Minute' ? -1 : 1)), [unitOptions]);

  return (
    <S.Wrapper open={true} onClose={handleClose} closeOnDimmerClick={false}>
      <S.Title>Chart Settings</S.Title>
      <S.Body>
        <S.ItemWrapper>
          <S.TitleSettingItem>Chart Type</S.TitleSettingItem>
          <S.ChartTypeSection>
            <S.ChartOption active={false} disabled onClick={handleSelectChartType('line')}>
              <LineChartIcon className="line-chart-icon" />
              Line
            </S.ChartOption>
            <S.ChartOption active onClick={handleSelectChartType('bar')} right>
              <BarChartIcon className="bar-chart-icon" />
              Bar
            </S.ChartOption>
          </S.ChartTypeSection>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Data Point</S.TitleSettingItem>
          <S.SectionWrapper className="data-point-wrapper">
            {DATA_POINT_OPTIONS.map((opt, index) => {
              const { value, label } = opt;
              if (opt.value === DATA_POINT_KEYS.all_values) {
                return null;
              }
              const disabled = opt.value === DATA_POINT_KEYS.average_value;
              return (
                <S.UnitItem
                  key={value}
                  onClick={handleSelectDataPointOption(value)}
                  className="data-point"
                  disabled={disabled}
                >
                  <S.UnitRadio checked={!disabled} className="data-point-radio" disabled={disabled} />
                  {label}
                </S.UnitItem>
              );
            })}
          </S.SectionWrapper>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Chart Color</S.TitleSettingItem>
          <ColorPicker
            disabled
            color={'#4CD9CD' || customBrandingColor}
            options={[customBrandColor, ...COLOR_OPTIONS]}
            onSelectColor={handleSelectColor}
            placeholder="Select color..."
          />
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Unit</S.TitleSettingItem>
          <S.SectionWrapper>
            <S.UnitItem>
              <S.UnitRadio checked />
              Minute (min)
            </S.UnitItem>
          </S.SectionWrapper>
        </S.ItemWrapper>
        <S.ItemWrapper>
          <S.TitleSettingItem>Key Metric</S.TitleSettingItem>
          <S.SectionWrapper className="key-metric">
            {KEYS_METRIC.map(keyMetric => {
              const selectedKey = settings.key_metrics.includes(keyMetric.key);
              const disabled = keyMetric.key === 'current';
              return (
                <S.KeyMetricItem key={keyMetric.key}>
                  <Checkbox
                    disabled={disabled || keyMetric.key === 'average'}
                    title={keyMetric.title}
                    checked={keyMetric.key === 'average' ? true : disabled ? false : selectedKey}
                    className="key-metric__checkbox"
                    onChange={handleSelectKeyMetric(keyMetric.key, selectedKey)}
                  />
                </S.KeyMetricItem>
              );
            })}
          </S.SectionWrapper>
        </S.ItemWrapper>
      </S.Body>
      <S.Action>
        <S.CancelButton onClick={handleClose}>Cancel</S.CancelButton>
        <S.ApplyButton onClick={applySetting}>Apply</S.ApplyButton>
      </S.Action>
    </S.Wrapper>
  );
};

export default SleepMetricSettingModal;
