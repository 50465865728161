import React from 'react';
import styled from 'styled-components';
import { Icon, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getClientList, changeAutoflowClientQueryParam } from 'redux/autoflowInterval/client/actions';

const Wrapper = styled.div``;

const HeaderItem = props => {
  const { sort, sorter, changeAutoflowClientQueryParam, getClientList, column } = props;
  let sortIcon = null;
  const { sortKey } = column;

  if (sortKey) {
    let sortClass = sorter === sortKey ? 'chevron active ' : 'chevron ';
    sortClass += sorter === sortKey && sort === -1 ? 'up' : 'down';
    sortIcon = <Icon className={sortClass} />;
  }

  return (
    <Wrapper className={`table__header__content${column.hideLabel ? ' hidden' : ''}`}>
      <div
        className="header"
        onClick={() => {
          if (sortKey) {
            changeAutoflowClientQueryParam({
              sorter: sortKey,
              sort: sortKey === sorter ? sort * -1 : 1,
            });
            getClientList();
          }
        }}
      >
        {!!column.icon && <Image src={column.icon} className="column-icon" />}
        <span>{column.label}</span>
        {sortIcon}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  const query = rootReducer.autoflowInterval.client.get('query');

  return {
    sort: query.get('sort'),
    sorter: query.get('sorter'),
  };
};

const mapDispatchToProps = dispatch => ({
  getClientList: bindActionCreators(getClientList, dispatch),
  changeAutoflowClientQueryParam: bindActionCreators(changeAutoflowClientQueryParam, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderItem);
