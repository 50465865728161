import { toggleConfirmModal } from 'actions/modal';
import { ReactComponent as NextSlider } from 'assets/icons/slider-next.svg';
import { ReactComponent as PrevSlider } from 'assets/icons/slider-prev.svg';
import classnames from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import omit from 'lodash/omit';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { DateTime } from 'luxon';
import { bindActionCreators } from 'redux';
import {
  deletePhoto,
  selectedPhoto as handleSelectedPhoto,
  updateTag,
  updateProgressPhotoFilters,
  firstLoad,
} from 'redux/progress-photo/actions';
import { formatDate } from '../../helper';
import ComparePhoto from './ComparePhoto';
import InformationUnit from './InformationUnit';
import ItemPhoto from './ItemPhoto';
import PaginationDot from './PaginationDot';
import { PER_PAGE } from '../../constants';
import { handleConvertUnit } from '../../helper';
import * as S from './style';

const WIDTH_PHOTO = 72;
const MainPanel = ({
  toggleConfirmModal,
  compareMode,
  selectedPhoto = '',
  selectedDate = '',
  selectedPhotoListByDate = [],
  updateTag,
  handleSelectedPhoto,
  weight,
  bodyFat,
  weightMetric,
  deletePhoto,
  filters,
  updateProgressPhotoFilters,
  handleAddPhoto,
  firstLoad,
  onClose,
  bodymetricTypes,
  weightUnit,
  tag,
  timezone,
  measurementId,
}) => {
  const sliderRef = useRef(null);

  const weightConvert = handleConvertUnit(weight, weightUnit, bodymetricTypes);

  const PrevArrow = ({ className, style }) => {
    return (
      <div
        onClick={() => {
          sliderRef.current.slickPrev();
          handleScroll(-WIDTH_PHOTO);
        }}
        className={classnames(className, 'slider-prev-arrow')}
        style={{ ...style, display: 'flex' }}
      >
        <PrevSlider />
      </div>
    );
  };

  const NextArrow = ({ className, style }) => {
    return (
      <div
        onClick={() => {
          sliderRef.current.slickNext();
          handleScroll(WIDTH_PHOTO);
        }}
        className={classnames(className, 'slider-next-arrow')}
        style={{ ...style, display: 'flex' }}
      >
        <NextSlider />
      </div>
    );
  };

  const handleScroll = value => {
    const ele = document.getElementById(`photo-by-date-${selectedDate}`);
    if (ele)
      ele.scrollTo({
        left: ele.scrollLeft + value,
        behavior: 'smooth',
      });
  };

  const settings = {
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    centerMode: true,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_, newIndex) => {
      if (get(selectedPhotoListByDate, 'length', 0) > 0) {
        const photo = selectedPhotoListByDate[newIndex];
        if (photo) {
          handleSelectedPhoto &&
            handleSelectedPhoto(
              omit({ ...photo, tags: selectedPhotoListByDate, day: selectedDate, weightUnit: get(photo, 'unit', {}) }, [
                '_id',
              ]),
            );
        }
      }
    },
  };

  useEffect(() => {
    const indexSlide = selectedPhotoListByDate.findIndex(item => get(item, 'image', '') === selectedPhoto);
    if (sliderRef.current) sliderRef.current.slickGoTo(indexSlide);
  }, [sliderRef.current, selectedPhotoListByDate, selectedPhoto, compareMode]);

  const handleDeletePopup = () => {
    toggleConfirmModal(
      true,
      <S.DeleteModal
        noBorder
        noIcon
        title="Are you sure?"
        content={`This image will be removed permanently.`}
        onConfirm={handleSubmitDelete}
        confirmButtonTitle="Remove"
        hasCloseIcon
      />,
    );
  };

  const handleSubmitDelete = () => {
    deletePhoto &&
      deletePhoto(photoList => {
        toggleConfirmModal(false);
        if (!get(photoList, 'length')) {
          if (get(filters, 'tag', null) != null) {
            handleResetPhoto();
          } else {
            handleAddPhoto && handleAddPhoto();
            onClose && onClose();
          }
        }
      });
  };

  const handleUpdateTag = tag => {
    updateTag &&
      updateTag(tag, () => {
        handleResetPhoto();
      });
  };

  const handleResetPhoto = () => {
    firstLoad && firstLoad();
    updateProgressPhotoFilters &&
      updateProgressPhotoFilters(true, {
        day: timezone ? DateTime.now().setZone(timezone).toFormat('MM-dd-yyyy') : DateTime.now().toFormat('MM-dd-yyyy'),
        tag: null,
        no_of_day: PER_PAGE,
      });
  };

  const onChooseTag = ({ value }) => {
    if (value !== tag) {
      handleUpdateTag(value);
    }
  };

  return (
    <S.MainPanel compareMode={compareMode} data-progress-photo="main-panel">
      <S.MainPanelHeader>
        <S.HeaderTitle>Progress Photo</S.HeaderTitle>
        {/* TODO: Add share button */}
        {/* <S.ShareButton>Share</S.ShareButton> */}
      </S.MainPanelHeader>
      {compareMode ? (
        <ComparePhoto />
      ) : (
        <S.MainPanelBody>
          <S.PhotoWrapper>
            <S.SliderWrapper isCypress={window.Cypress}>
              <Slider
                ref={sliderRef}
                key={`${selectedDate}-${selectedPhoto}-${get(selectedPhotoListByDate, 'length', 0)}`}
                {...settings}
                className="custom-slider"
              >
                {get(selectedPhotoListByDate, 'length', 0) > 0 &&
                  selectedPhotoListByDate.map(item => {
                    const parsedDate = formatDate(selectedDate);
                    return (
                      <ItemPhoto
                        key={get(item, '_id', '')}
                        handleDeletePopup={handleDeletePopup}
                        item={item}
                        parsedDate={parsedDate}
                        onChooseTag={onChooseTag}
                      />
                    );
                  })}
              </Slider>
            </S.SliderWrapper>
          </S.PhotoWrapper>
        </S.MainPanelBody>
      )}
      {!compareMode && (
        <>
          {get(selectedPhotoListByDate, 'length', 0) > 1 && <PaginationDot />}
          <S.InformationUnitWrapper>
            <InformationUnit
              weight={weightConvert}
              bodyFat={bodyFat}
              clientMetrics={weightMetric}
              measurementId={measurementId}
              selectedDate={selectedDate}
            />
          </S.InformationUnitWrapper>
        </>
      )}
    </S.MainPanel>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      client: {
        bodymetricTypes,
        workingClientDetail: { timezone },
      },
      progressPhoto: {
        compareMode,
        filters,
        itemPhoto: {
          selectedPhoto,
          selectedDate,
          selectedPhotoListByDate,
          selectedThumbnailPhoto,
          weight,
          bodyFat,
          weightUnit,
          tag,
          measurementId,
        },
      },
    },
  } = state;

  const weightMetric = get(
    find(bodymetricTypes, it => it.unique_code === 'weight'),
    'unit',
    [],
  );

  return {
    compareMode,
    selectedPhoto,
    selectedDate,
    selectedPhotoListByDate,
    selectedThumbnailPhoto,
    weight,
    bodyFat,
    weightMetric,
    filters,
    bodymetricTypes,
    weightUnit,
    tag,
    timezone,
    measurementId,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updateTag: bindActionCreators(updateTag, dispatch),
  handleSelectedPhoto: bindActionCreators(handleSelectedPhoto, dispatch),
  deletePhoto: bindActionCreators(deletePhoto, dispatch),
  updateProgressPhotoFilters: bindActionCreators(updateProgressPhotoFilters, dispatch),
  firstLoad: bindActionCreators(firstLoad, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPanel);
