import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';

import QuestionInput from './QuestionInput';
import ItemOption from './ItemOption';
import AdvancedSettings from './AdvancedSettings';
import { SConfirmModal } from '../ForumTimeline/components/Post/style';

import { mongoObjectId } from 'utils/commonFunction';
import { DEFAULT_ITEM_OPTION, DEFAULT_ADVANCED_SETTINGS, KEY_ADVANCED_SETTINGS, DEFAULT_OPTIONS } from './constants';

import { ReactComponent as CloseIcon } from 'assets/icons/close_circle_grey_2.svg';
import WarningIcon from 'assets/icons/warning_red_light.svg';

import * as S from './style';

const LIMIT_OPTION = 25;
const LAST_OPTION = 1;

const FormPoll = props => {
  const { removePoll, isEdit, pollData, onChangeFormPoll, userId, toggleConfirmModal, originPoll } = props;

  const {
    options: currentOptions = [],
    question: currentQuestion = '',
    allow_member_to_add_option,
    allow_multiple_choices,
  } = pollData || {};

  const disableEditPoll = isEdit && get(originPoll, 'options', []).some(({ votes }) => votes > 0);
  const [question, setQuestion] = useState(currentQuestion);
  const [options, setOptions] = useState(pollData ? currentOptions : DEFAULT_OPTIONS);
  const [settings, setSettings] = useState(DEFAULT_ADVANCED_SETTINGS);

  const isLastItem = options.length === LAST_OPTION;
  const limitItem = options.length >= LIMIT_OPTION;

  useEffect(() => {
    if (pollData) {
      setQuestion(currentQuestion);
      setOptions(currentOptions);
      setSettings({
        allow_member_to_add_option,
        allow_multiple_choices,
      });
    }
  }, []);

  const handleAddOption = () => {
    const idNewOption = mongoObjectId();

    setOptions(it => [
      ...it,
      {
        ...DEFAULT_ITEM_OPTION,
        _id: idNewOption,
        isNew: true,
      },
    ]);

    setTimeout(() => {
      const inputOption = document.getElementById(`option-input-${idNewOption}`);
      if (inputOption && typeof inputOption.focus === 'function') {
        inputOption.focus();
      }
    }, 100);
  };

  const handleRemoveOption = optionId => {
    const newOptions = options.filter(it => it._id !== optionId);
    const dataOption = markDuplicates(newOptions);
    setOptions(dataOption);
    onChangeFormPoll({
      question: question,
      allow_multiple_choices: settings[KEY_ADVANCED_SETTINGS.ALLOW_MULTIPLE_CHOICES],
      allow_member_to_add_option: settings[KEY_ADVANCED_SETTINGS.ALLOW_MEMBER_TO_ADD_OPTION],
      options: dataOption,
    });
  };

  const handleOpenRemovePollPopup = () => {
    if (!(currentOptions || {}).length && !currentQuestion) {
      handleRemovePoll();
      return;
    }

    toggleConfirmModal(
      true,
      <SConfirmModal
        noBorder
        headerIcon={WarningIcon}
        hasCloseIcon
        hasHoverState
        title="Remove Poll?"
        content="This will permanently delete the poll and all options. Would you like to continue?"
        onConfirm={handleRemovePoll}
        confirmButtonTitle="Remove"
      />,
    );
  };

  const handleRemovePoll = () => {
    typeof removePoll === 'function' && removePoll();
  };

  const handleQuestionChange = value => {
    setQuestion(value);
    onChangeFormPoll({
      question: value,
      allow_multiple_choices: settings[KEY_ADVANCED_SETTINGS.ALLOW_MULTIPLE_CHOICES],
      allow_member_to_add_option: settings[KEY_ADVANCED_SETTINGS.ALLOW_MEMBER_TO_ADD_OPTION],
      options: options,
    });
  };

  const handleOptionChange = newOption => {
    const newOptions = [...options].map(it => {
      if (it._id === newOption._id) {
        return newOption;
      }
      return it;
    });

    const dataOption = markDuplicates(newOptions);

    setOptions(dataOption);
    onChangeFormPoll({
      question: question,
      allow_multiple_choices: settings[KEY_ADVANCED_SETTINGS.ALLOW_MULTIPLE_CHOICES],
      allow_member_to_add_option: settings[KEY_ADVANCED_SETTINGS.ALLOW_MEMBER_TO_ADD_OPTION],
      options: dataOption,
    });
  };

  const markDuplicates = (arr = []) => {
    const count = {};
    return arr.map(item => {
      const { value = '' } = item || {};
      const valueCompare = value.trim();
      if (valueCompare === '') {
        return { ...item, isDuplicate: false };
      }
      count[valueCompare] = (count[valueCompare] || 0) + 1;
      return { ...item, isDuplicate: count[valueCompare] > 1 };
    });
  };

  const handleChangeSettings = (key, value) => {
    setSettings(it => ({ ...it, [key]: value }));
    onChangeFormPoll({
      question: question,
      options: options,
      ...settings,
      [key]: value,
    });
  };

  const renderItemOption = (it, idx) => (
    <ItemOption
      key={`option-item-${(it || {})._id || idx}`}
      data={it}
      placeholder={`Option ${idx + 1}`}
      onRemoveOption={handleRemoveOption}
      onOptionChange={handleOptionChange}
      disableEditPoll={disableEditPoll}
      isLastItem={isLastItem}
      userId={userId}
    />
  );

  return (
    <S.FormPollContainer disableEditPoll={disableEditPoll}>
      {!disableEditPoll && (
        <Button className="close-button" onClick={handleOpenRemovePollPopup}>
          <CloseIcon />
        </Button>
      )}
      <S.QuestionWrapper>
        <QuestionInput placeholder="Poll Question" question={question} onInputChange={handleQuestionChange} />
      </S.QuestionWrapper>

      {options.map(renderItemOption)}
      {!disableEditPoll && (
        <S.ActionButtons>
          <S.ActionItemWrapper>
            {!limitItem && <S.ButtonAddOption onClick={handleAddOption}>+ Add another option</S.ButtonAddOption>}
          </S.ActionItemWrapper>

          <S.ActionItemWrapper>
            <AdvancedSettings settings={settings} onChangeSettings={handleChangeSettings} />
          </S.ActionItemWrapper>
        </S.ActionButtons>
      )}
      {disableEditPoll && (
        <ReactTooltip id="disable-edit-poll" className="disable-edit-poll" place="top" effect="solid">
          This option can't be edited as it has already been voted on.
        </ReactTooltip>
      )}
    </S.FormPollContainer>
  );
};

export default FormPoll;
