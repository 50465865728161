import React, { useMemo, useState } from 'react';
import { Rectangle } from 'recharts';

import { SIZES, SLEEP_ASLEEP_STAGES, SLEEP_COLOR, SLEEP_STAGE_KEYS, SLEEP_VIEW } from './constants';

const HourlyChartBarShape = props => {
  const { x, width, height, payload, setTooltipPosition, sleepSettings } = props;
  const { connectedApp, isShowStages, hasAsleepStageInRange, viewBy } = sleepSettings;
  const { rangeValues, dataType } = payload;
  const heightHover = height + SIZES.OFFSET;
  const [isHovered, setIsHovered] = useState(false);

  const hasStage = useMemo(() => (dataType || []).some(item => !SLEEP_ASLEEP_STAGES.includes(item.type)), [dataType]);
  const total = useMemo(() => Math.abs(rangeValues[0] - rangeValues[1]), [rangeValues]);

  const renderBar = (entry, currIndex) => {
    if (entry.type === 'empty') return null;

    let widthBar = (entry.value / total) * width;
    let xBar = dataType.reduce((tt, item, itemIndex) => {
      if (item.type !== SLEEP_STAGE_KEYS.in_bed && itemIndex < currIndex) {
        return tt + (item.value / total) * width;
      }
      return tt;
    }, x);

    let radius =
      dataType.length === 1
        ? [3, 3, 3, 3]
        : currIndex === 0
        ? [3, 0, 0, 3]
        : currIndex === dataType.length - 1
        ? [0, 3, 3, 0]
        : [0, 0, 0, 0];

    if ((connectedApp || hasAsleepStageInRange) && !isShowStages && viewBy === SLEEP_VIEW.DURATION) {
      radius = [3, 3, 3, 3];
    }
    if (entry.type === SLEEP_STAGE_KEYS.in_bed) {
      xBar = x;
      widthBar = width;
    }

    if (SLEEP_ASLEEP_STAGES.includes(entry.type) && !hasStage) {
      radius = [3, 3, 3, 3];
    }

    return (
      <Rectangle
        key={entry.type + currIndex}
        x={xBar}
        y={SIZES.Y_POSITION}
        width={widthBar}
        height={height}
        radius={radius}
        fill={SLEEP_COLOR[entry.type]}
        className="bar-shape-custom"
      />
    );
  };

  const handleMouseEnter = event => {
    const { top, left, width: widthCurrentTarget } = event.currentTarget.getBoundingClientRect();
    const lengthOfType = Object.keys(
      dataType.reduce((obj, item) => {
        if (item.type !== SLEEP_STAGE_KEYS.empty) {
          obj[item.type] = item.type;
        }
        return obj;
      }, {}),
    ).length;
    const widthOfTypes = lengthOfType * SIZES.HEIGHT_OF_TYPE + (lengthOfType - 1) * SIZES.SPACING_OF_TYPE;
    const topBuffer = 92;

    setIsHovered(true);
    setTooltipPosition({
      left: left + widthCurrentTarget / 2, // make sure it center of bar
      top: top - widthOfTypes - topBuffer,
      payload,
      widthCurrentTarget: widthCurrentTarget,
    });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setTooltipPosition(undefined);
  };

  return (
    <svg fill="none" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {isHovered && (
        <Rectangle
          x={x - SIZES.OFFSET_HOVER}
          y={SIZES.Y_POSITION - SIZES.OFFSET_HOVER}
          width={width + SIZES.OFFSET}
          height={heightHover}
          fill={SLEEP_COLOR.hover}
          radius={[6, 6, 6, 6]}
          className="bar-shape-custom-hover"
        />
      )}
      {dataType.map(renderBar)}
    </svg>
  );
};

export default HourlyChartBarShape;
