import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { NewSearchInput } from 'shared/SearchInput';
import { CDN_URL } from 'constants/commonData';

const textBase = `
  font-family: 'Open sans';
  color: #202353;
  font-style: normal;
  line-height: 150%;
`;

const widthHeader = {
  clients: {
    width: '277px',
  },
  progress: {
    width: '114px',
  },
  start_date: {
    width: '96px',
  },
  end_date: {
    width: '89px',
  },
};

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal.evf-assigned-client-modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: 626px;
  }
  &.ui.modal.evf-assigned-client-modal > .header,
  &.ui.modal.evf-assigned-client-modal > .content {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }

  &.ui.modal.evf-assigned-client-modal > .content {
    padding-top: 66px !important;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 21px 25px 15px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 3px 12px 0px #0000000d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .close-button {
    right: -8.5px;
    top: -8.5px;
    img {
      width: 18px;
      height: 18px;
    }
  }
  .assign-meal-plan-tooltip {
    ${textBase}
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    margin-top: -9px !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    max-width: 370px;
    z-index: 1000;
  }
`;

export const HeaderTitle = styled.div`
  ${textBase}
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
`;

export const HeaderAssign = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #e1e1ea;
  background-color: #ffffff;
  cursor: pointer;
  .assign-client {
    flex-shrink: 1;
    width: 16px;
    height: 16px;
  }
  span {
    ${textBase}
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }
  &:hover {
    background-color: #f0f1ff;
    span {
      color: #5158cf;
    }
    svg.assign-client path {
      stroke: #5158cf;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        background-color: #fff;
        span {
          color: #202353;
        }
        svg.assign-client path {
          stroke: #7b7e91;
        }
      }
    `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  padding: 15px 0 0;
  position: relative;
`;

export const InputSearch = styled(NewSearchInput)`
  width: 100%;
  padding: 0 25px;
  input {
    padding: 6px 41px;
    background: url(${CDN_URL}/images/search_bold.svg) no-repeat;
    background-size: 14px;
    background-position: 15px 7px;
    background-color: #f8f8f8;
    font-size: 12px;
    font-weight: 400;
    color: #202353;
    border: 1px solid transparent;

    &::placeholder {
      color: #a3a3b5;
      font-size: 12px;
      font-weight: 400;
      opacity: 1;
    }
    &:focus {
      border-color: #5158cf;
      background-color: transparent;
    }
    + svg {
      right: 39px;
    }
  }
`;

export const TabHeader = styled.div`
  display: flex;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  overflow: hidden;
  height: 32px;
  align-items: center;
  margin: 14px 24px 19px;
  margin-top: 14px;
  margin-bottom: 19px;
`;

export const TabHeaderItem = styled.div`
  ${textBase}
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7b7e91;
  flex: 1;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid #e1e1ea;
  &:last-child {
    border-right: none;
  }
  ${props =>
    props.active &&
    css`
      background-color: #f0f0f2;
      color: #202353;
    `}
`;

export const TabContent = styled.div`
  padding: 0 10px 0 25px;
  position: relative;
`;

export const TableHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  height: 430px;
  padding-right: 15px;
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1ea;
    border-radius: 6px;
  }

  ${({ hasClient }) =>
    hasClient &&
    css`
      padding-bottom: 64px;
      ::-webkit-scrollbar-track {
        margin-bottom: 65px;
      }
    `}

  .__react_component_tooltip.app-tooltip.show {
    background: #333;
    padding: 8px 10px;
    border-radius: 5px;
    ${textBase}
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    width: 116px;
    height: 34px;
  }
  .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #333;
    margin-bottom: -2px !important;
  }
  .__react_component_tooltip.place-top {
    margin-top: -11px;
  }
`;

export const TableCell = styled.div`
  ${textBase}
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  width: ${props => widthHeader[props.styleHeader].width || 'auto'};
  padding: 0;

  .current-week {
    font-weight: 600;
  }
  :first-child {
    ${({ isHeader }) =>
      isHeader &&
      css`
        padding-left: 5px;
      `}
  }
  ${({ isHeader }) =>
    isHeader &&
    css`
      ${textBase}
      font-weight: 600;
      font-size: 11px;
      line-height: 17px;
      color: #7b7e91;
    `}
`;

export const TextEmpty = styled.div`
  ${textBase}
  line-height: 20px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: 200px;
`;

export const ActionWrapper = styled.div`
  padding: 15px 25px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;
  background-color: #f8f8f8;
  border-top: 1px solid #f0f0f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    ${textBase}
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
`;

export const MaskComplete = styled.div`
  ${textBase}
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  width: 155px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    path {
      fill: #202353;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    svg {
      path {
        fill: #5158cf;
      }
    }
  }
`;

export const Remove = styled.div`
  ${textBase}
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  width: 126px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(234, 49, 74, 1);
  border-radius: 5px;
  color: #fff;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: rgba(234, 49, 74, 0.9);
  }
`;
