// Lib
import React from 'react';

// Helper
import { conditionalRoundNutrition } from 'components/RecipeDetail/helper';

// Styles
import * as S from './style';

const RecipeItem = props => {
  const { nutrition, thumbnail, name, rearrangeMode } = props;

  return (
    <S.Wrapper rearrangeMode={rearrangeMode}>
      <S.Thumbnail rearrangeMode={rearrangeMode} src={[thumbnail]} />
      {!rearrangeMode && (
        <S.Content>
          <S.Name>{name}</S.Name>
          <S.NutritionInfo>
            {nutrition.map(item => {
              const { label = '', unit = '', value = '', id } = item;
              const isEmptyValue = +value === 0;

              return (
                <S.NutritionItem key={id}>
                  {`${label} ${isEmptyValue ? '—' : conditionalRoundNutrition(value)} ${unit}`.trim()}
                  <S.SeparateDot />
                </S.NutritionItem>
              );
            })}
          </S.NutritionInfo>
        </S.Content>
      )}
    </S.Wrapper>
  );
};

export default RecipeItem;
