import React from 'react';
import _ from 'lodash';
import { pluralize } from 'utils/commonFunction';
import { CheckBoxIconWrapper, CollectionItemWrapper, Dot } from './style';

const CheckBoxIcon = ({ active }) => {
  return (
    <CheckBoxIconWrapper active={active}>
      <div className="checkbox__circle" />
    </CheckBoxIconWrapper>
  );
};

const countNumberOfResource = collection => {
  return _.reduce(
    collection.sections,
    (sum, section) => {
      return sum + section.resources.length;
    },
    0,
  );
};

export default function StudioCollectionItem({ collection, active, onSelect, isFully }) {
  const onClick = () => {
    onSelect(collection);
  };
  return (
    <CollectionItemWrapper active={active} onClick={onClick} className={isFully && !active && 'disable-item'}>
      <div className="collectionItem__info">
        <div className="collectionItem__info--title">{collection.name}</div>
        <div className="collectionItem__info--statistic">
          <span>Available for </span>
          <b>
            {collection.number_of_clients} {pluralize('client', collection.number_of_clients)}
          </b>
          <Dot className="dot" />
          <span>Resources:&nbsp;</span>
          <b>{countNumberOfResource(collection)}</b>
        </div>
      </div>
      <CheckBoxIcon active={active} onChange={onClick} />
    </CollectionItemWrapper>
  );
}
