import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: fit-content;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.06);

  img.item-background {
    border-radius: 4px 4px 0 0;
    height: 96px;
    object-fit: cover;
  }
  .item-content {
    padding: 9px 13px 14px;
    .item-name {
      height: 36px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #202353;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &:hover {
    border: 1px solid #5158cf;
  }
`;
