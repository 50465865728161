import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const AddWeekButtonWrapper = styled.div`
  position: relative;
`;

export const AddWeekButton = styled.button`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  height: 30px;
  width: 105px;
  border: 1px solid #e1e1ea;
  background: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  .add-icon {
    width: 9.5px;
    height: 9.5px;
    margin-right: 8px;
    path {
      stroke: #202353;
    }
  }
  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
    .add-icon path {
      stroke: #5158cf;
    }
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        color: #202353;
        background-color: #fff;
        .add-icon path {
          stroke: #202353;
        }
      }
    `}
`;

export const DropdownList = styled.div`
  position: absolute;
  background-color: #2d2d2d;
  width: 183px;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  padding: 8px 0;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #2d2e2d;
    border-radius: 1px;
    transform: rotate(45deg) translateX(-50%);
    top: -1px;
    left: 50%;
  }
`;

export const DropdownItem = styled.div`
  ${baseText}
  line-height: 17.7px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 36px;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
  &:hover {
    background-color: #535353;
  }
`;
