import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Image, Button, TextArea } from 'semantic-ui-react';
import get from 'lodash/get';

import { toggleConfirmModal, toggleSecondModal } from 'actions/modal';
import { updateClientGoal } from 'redux/client-countdown/action';
import { capitalizeFirstChar } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

// assets
import { CustomConfirmModal } from '../ConfirmModal/style';
import * as S from './style';

const AddGoalPopup = props => {
  const { toggleSecondModal, updateClientGoal, currentGoal, isEdit, toggleConfirmModal, clientId } = props;
  const [goalValue, setGoalValue] = useState(currentGoal || '');
  const isChanged = isEdit ? goalValue !== currentGoal && !!goalValue.trim() : !!goalValue.trim();

  useEffect(() => {
    if (isEdit) {
      setTimeout(() => {
        const goalInput = document.querySelector('.edit-goal-input');
        if (goalInput) {
          goalInput.focus();
          goalInput.setSelectionRange(goalInput.value.length, goalInput.value.length);
        }
      }, 200);
    }
  }, [isEdit]);

  const handleClose = () => {
    if (isChanged) {
      toggleSecondModal(
        true,
        <CustomConfirmModal
          onConfirm={() => {
            toggleSecondModal(false);
            toggleConfirmModal(false);
          }}
          onDeny={() => {
            toggleSecondModal(false);
          }}
          isNotCloseConfirmModalWhenCancelled
          isCloseOnDimmer={false}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          confirmButtonTitle="Discard Changes"
          title="Discard Changes?"
          noBorder
          hasCloseIcon
          isPressEsc
        />,
      );
      return;
    }
    toggleConfirmModal(false);
  };

  const onSubmitGoal = () => {
    updateClientGoal(clientId, goalValue.trim(), isEdit);
    toggleConfirmModal(false);
  };

  return (
    <S.EditPopupStyled open={true} onClose={handleClose} className="edit-countdown-popup" closeOnDimmerClick={false}>
      <Modal.Content>
        <Button onClick={handleClose} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <S.PopupTitle>{isEdit ? 'Edit' : 'Create'} goal</S.PopupTitle>
        <div className="goal-wrapper">
          <TextArea
            autoFocus={!isEdit}
            className="edit-goal-input"
            value={goalValue}
            rows={10}
            placeholder="Set a goal"
            onChange={(evt, newData) => setGoalValue(capitalizeFirstChar(newData.value || ''))}
          />
        </div>
        <Modal.Actions>
          <S.CreateButton onClick={onSubmitGoal} purple disabled={!isChanged}>
            {isEdit ? 'Update' : 'Create Goal'}
          </S.CreateButton>
        </Modal.Actions>
      </Modal.Content>
    </S.EditPopupStyled>
  );
};

const mapState = ({ rootReducer }) => ({
  clientId: get(rootReducer, 'client.selected'),
});

const mapDispatch = {
  toggleSecondModal,
  toggleConfirmModal,
  updateClientGoal,
};

export default connect(mapState, mapDispatch)(AddGoalPopup);
