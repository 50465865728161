import styled from 'styled-components';

export default styled.div`
  padding: 0 !important;

  .autoflow-modal-header {
    padding-bottom: 5px;
  }

  .autoflow-modal-content {
    .description {
      font-size: 14px;
      line-height: 150%;
    }

    .autoflow-types-selection {
      margin: 30px 0 40px;
      display: flex;
      align-items: stretch;

      .divider {
        width: 15px;
        background: #fff;
      }

      .autoflow-type {
        .visually-hidden {
          display: none;
        }

        .app-tooltip-white {
          max-width: fit-content;
        }

        .autoflow-tooltip {
          position: absolute;
          top: 10px;
          right: 10px;
          svg * {
            fill: #969fb5;
          }
        }

        .autoflow-section {
          cursor: pointer;
        }

        .visually-hidden ~ label .autoflow-section {
          border: 1px solid #e3e3e3;
          border-radius: 5px;
          text-align: center;
          padding: 18px 15px;
          color: #202353;
          position: relative;
        }

        .visually-hidden:checked ~ label .autoflow-section,
        .autoflow-section:hover {
          border-color: #5158cf;
          background: #fbfbff;
        }

        .visually-hidden:checked ~ label .autoflow-section .autoflow-tooltip svg * {
          fill: #5158cf;
        }

        .title {
          margin: 14px 0 5px;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
        }

        .description {
          font-size: 12px;
          line-height: 150%;
        }
      }
    }

    .autoflow-week {
      display: flex;
      align-items: center;

      input {
        width: 120px;
      }

      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #6a778a;
        margin-left: 10px;
      }
    }
  }

  .autoflow-action-btn {
    min-width: 135px;
  }
`;
