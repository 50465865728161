import React, { useState } from 'react';

import AddNewForm from '../AddNewForm';
import { TYPE } from '../constants';

import { ReactComponent as PlusIcon } from 'assets/icons/white_plus.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back_icon.svg';

import * as S from './style';

const WorkoutLabelsHeader = ({ addNewPropertyLabels, name = '', labelID, push, resetSelectedWorkoutLabel, type }) => {
  const [open, setOpen] = useState(false);

  const handleOpenAddNew = () => setOpen(true);

  const handleSubmit = (params, callback = null) => {
    params = { ...params, labelId: labelID };

    addNewPropertyLabels && addNewPropertyLabels(params);
    callback && callback();
  };

  const handleBack = () => {
    resetSelectedWorkoutLabel();
    push('/home/workout-labels');
  };

  return (
    <S.HeaderArea>
      <S.HeaderLeft>
        <S.HeaderTitle>
          <S.BackButton onClick={() => handleBack()}>
            <BackIcon />
          </S.BackButton>
          <S.HeaderText>{name}</S.HeaderText>
        </S.HeaderTitle>
      </S.HeaderLeft>
      {TYPE.CUSTOM === type && (
        <S.HeaderRight>
          <S.Button
            text="Add Label Option"
            icon={<PlusIcon />}
            height={40}
            width={175}
            className="buttonClassName"
            onClick={handleOpenAddNew}
          />
          <AddNewForm
            open={open}
            titlePopup="Add New Label Option"
            submitName="Add New"
            titleInput="Option name"
            placeholderInput="Enter Option Name"
            maxLength={30}
            onSetOpen={setOpen}
            onSubmit={handleSubmit}
          />
        </S.HeaderRight>
      )}
    </S.HeaderArea>
  );
};

export default WorkoutLabelsHeader;
