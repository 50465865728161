import React from 'react';
import * as S from './style';
import PropTypes from 'prop-types';

const statusColors = {
  publish: {
    label: 'Published',
    color: '#6ACF47',
  },
  draft: {
    label: 'Draft',
    color: '#ffc90c',
  },
  active: {
    label: 'Active',
    color: '#49CA8D',
  },
  inactive: {
    label: 'Inactive',
    color: '#818DA1',
  },
  archive: {
    label: 'Archived',
    color: '#BDBDBD',
  },
};

const StatusChip = ({ status = 'draft', callBack, customClass = '', customStatuses }) => {
  const handleClick = () => {
    callBack && callBack();
  };

  return (
    <S.Wrapper
      onClick={handleClick}
      statusColor={customStatuses ? customStatuses[status].color : statusColors[status].color}
      className={customClass}
    >
      {customStatuses ? customStatuses[status].label : statusColors[status].label}
    </S.Wrapper>
  );
};

StatusChip.propTypes = {
  status: PropTypes.oneOf(['publish', 'draft', 'archive', 'active', 'inactive']),
  callBack: PropTypes.func,
  customClass: PropTypes.string,
  customStatuses: PropTypes.object,
};

export default StatusChip;
