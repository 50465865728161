import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const CreateNewStudioProgramModal = styled(Modal)`
  &.ui.modal {
    width: 644px;

    .header {
      border-radius: 8px;
      padding-top: 30px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 0px !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .header-title {
        color: #202353 !important;
        font-family: 'Open Sans' !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
      }
    }
  }

  .content {
    padding: 30px !important;
  }

  .addStudioProgram__form {
    display: flex;
  }

  .addStudioProgram {
    &__left {
      width: 40%;
    }

    &__right {
      width: 60%;
      padding-left: 30px;
    }

    &__footer {
      text-align: right;
      button {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 5px;

  .label__required {
    color: #ea314a;
  }
`;
