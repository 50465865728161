import styled from 'styled-components';

export default styled.div`
  border: 1px solid #f4f5f9;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 100px;

  .overview-section {
    display: flex;
    align-items: center;

    .nutri-section {
      flex: 1;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .nutri-name {
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #202353;
        }

        .total-calo {
          font-size: 15px;
          line-height: 20px;
          color: #202353;

          .current {
            font-weight: 600;
          }
        }
      }

      .process-bar {
        background: #f3f5fb;
        position: relative;
        border-radius: 5px;
        border-radius: 15px;
        height: 5px;
        margin-top: 8px;
        display: flex;
        align-items: center;

        span {
          display: block;
          border-radius: 15px;
          height: 7px;
          position: relative;
          overflow: hidden;

          &.protein {
            background: #57b5ea;
          }

          &.carbs {
            background: #65cc9c;
          }

          &.fat {
            background: #ffbe49;
          }

          &.calo {
            background: #7672f0;
          }
        }
      }
    }

    .divide-div {
      width: 25px;
    }
  }

  .daily-table-section {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    color: #202353;

    .daily-header {
      background: #f6f6f8;
      border-radius: 5px;
      padding: 14px 20px;
      display: flex;
      align-items: center;

      .col {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: #6a778a;
      }
    }

    .col {
      text-align: right;
      padding: 10px 10px;
      font-size: 13px;
      line-height: 18px;

      &.meal-col {
        flex: 1;
        text-align: left;
      }

      &.nutri-col {
        width: 90px;
      }
      &.kcal-col {
        width: 140px;
      }
    }

    .row {
      display: flex;
      padding: 10px 20px;
    }
  }
`;
