import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    width: 396px;
    height: 368px;
    border-radius: 11px;

    .content {
      border-radius: 11px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;

      .content-icon {
        display: flex;
        justify-content: center;
        margin-right: 20px;
        margin-top: 32px;
      }
    }

    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 335px;
        height: 348px;
        ${Description} {
          font-size: 15px;
          letter-spacing: normal;
          width: 253px;
        }
        .content {
          .content-icon {
            svg {
              width: 103px;
              height: 103px;
            }
            margin-right: 13px;
            margin-top: 17px;
          }
        }

        ${Title} {
          margin-top: 0;
          margin-bottom: 16px;
        }

        ${BackToHomeButton} {
          width: 198px;
          height: 56px;
          background: #7470ef;
          border-radius: 28px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
        }
      `}
  }
`;

export const Title = styled.div`
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  letter-spacing: 0.2px;
  margin-top: 2px;
  margin-bottom: 12px;
`;

export const Description = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  opacity: 0.6;
  letter-spacing: 0.2px;
  text-align: center;
`;

export const BackToHomeButton = styled(Button)`
  height: 40px;
  width: 130px;
  text-align: center;
  margin-top: 32px;
  font-size: 14px;
`;
