import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import { toggleSideNav } from 'actions/sidenav';
import { changeCalendarTypeSuccess, changeCalendarStartDateSuccess } from 'actions/calendar';
import { getClientProfile, resetWorkingClientData, getClientMetrics } from 'redux/client/client.actionCreators';
import { handleResetSelectedWeek, handleResetSelectWorkout } from 'redux/calendar/calendar.actionCreators';

import Dashboard from './component';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const {
    client: { bodymetricTypes, workingClientDetail },
    permission,
  } = rootReducer;

  return {
    bodymetricTypes,
    workingClientDetail,
    permission,
  };
};

const changeClient = (dispatch, client) => {
  dispatch({ type: 'SELECT_CLIENT', payload: { client } });
  dispatch(getClientProfile(client, true));
  dispatch(toggleSideNav(false));
  dispatch(getClientMetrics(client));
  dispatch(changeCalendarStartDateSuccess(DateTime.local().startOf('week')));
  dispatch(changeCalendarTypeSuccess());
  dispatch(handleResetSelectedWeek());
  dispatch(handleResetSelectWorkout());
};

const resetCalendarStartDate = dispatch => {
  dispatch(changeCalendarStartDateSuccess(DateTime.local().startOf('week')));
  dispatch(changeCalendarTypeSuccess());
};

const mapDispatchToProps = dispatch => {
  return {
    changeClient: clientId => changeClient(dispatch, clientId),
    resetCalendarStartDate: () => resetCalendarStartDate(dispatch),
    resetWorkingClientData: bindActionCreators(resetWorkingClientData, dispatch),
    getClientMetrics: bindActionCreators(getClientMetrics, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
