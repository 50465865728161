import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { STEP_VIEW_MODES } from 'constants/commonData';
import Weekly from './Weekly';
import Monthly from './Monthly';

function StepProgress(props) {
  const { currentMode } = props;

  return (
    <div className="step-progress-container">
      <div className="content-container">
        <h3>Progress</h3>
        {currentMode === STEP_VIEW_MODES.month.value ? <Monthly /> : <Weekly />}
      </div>
    </div>
  );
}

const mapState = state => {
  const {
    rootReducer: {
      client: { workingClientDetail },
      clientStep: { currentMode },
    },
  } = state;

  return { currentMode, client: _.get(workingClientDetail, '_id') };
};

export default connect(mapState)(StepProgress);
