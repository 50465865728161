import React from 'react';
import _ from 'lodash';
import { Wrapper, Header, Body, Footer, DownLoadContainer } from './style';
import SendLink from './SendLink';
import Complete from './Complete';
import withResponsive from 'hoc/withResponsive';
import { CDN_URL } from 'constants/commonData';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      token: '',
      userData: null,
      complete: false,
      downloadLinkBy: 'mobile',
      phoneNumber: '',
      phoneCountryCode: {},
      loadedData: false,
      linksDownload: {
        ios: '/views/download_ios',
        android: '/views/download_android',
      },
    };

    this.ref = React.createRef();
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;

    if (params.inviteCode) {
      this.props
        .onFetch(params)
        .then(response => {
          const { data } = response.data;
          const { client_info, trainer, team } = data;
          let links = _.clone(this.state.linksDownload);
          if (team && team.white_label) {
            links = {
              ios: team.ios_download_url,
              android: team.android_download_url,
            };
          }
          this.setState({ client_info, trainer, team, step: 1, loadedData: true, linksDownload: links });
        })
        .catch(() => {
          this.setState({ loadedData: true });
        });
    }
  }

  setComplete = complete => {
    this.setState({ complete });
  };

  setDownloadLinkBy = downloadLinkBy => {
    this.setState({ downloadLinkBy });
  };

  setPhoneNumber = phoneNumber => {
    this.setState({ phoneNumber });
  };

  setPhoneCountryCode = countryCode => {
    this.setState({ phoneCountryCode: countryCode });
  };

  renderFooter = () => {
    const { step, team, linksDownload } = this.state;
    let downloads = null;
    if (step > 2) {
      downloads = (
        <DownLoadContainer>
          <h3>Or download from</h3>
          <div className="download__links">
            <a href={linksDownload.ios} target="_blank">
              <img src={`${CDN_URL}/images/appstore.svg`} alt="" />
            </a>
            <a href={linksDownload.android} target="_blank">
              <img src={`${CDN_URL}/images/googleplay.svg`} alt="" />
            </a>
          </div>
        </DownLoadContainer>
      );
    }

    const showCopyright = team && (team.invite_email_banner || team.white_label);
    const isEnableWhiteLabel = _.get(team, 'white_label', false);

    return (
      <Footer>
        {downloads}
        {showCopyright && !isEnableWhiteLabel && (
          <div className="poweredBy">
            Powered by
            <img src={`${CDN_URL}/images/logo_text_light.svg`} alt="" />
          </div>
        )}
        {!showCopyright && (
          <>
            <div className="social">
              <a href="https://www.facebook.com/everfit.io/" target="_blank">
                <img src={`${CDN_URL}/images/logo_facebook.svg`} alt="" />
              </a>
              <a href="https://www.instagram.com/everfit.io/" target="_blank">
                <img src={`${CDN_URL}/images/logo_instagram.svg`} alt="" />
              </a>
              <a href="https://www.linkedin.com/company/everfitio/" target="_blank">
                <img src={`${CDN_URL}/images/logo_linkedin.svg`} alt="" />
              </a>
              <a href="https://everfit.io/" target="_blank">
                <img src={`${CDN_URL}/images/link_grey.svg`} alt="" />
              </a>
            </div>
            <div className="address">
              <p>{new Date().getFullYear()} Everfit</p>
              <p>San Francisco, CA</p>
            </div>
          </>
        )}
      </Footer>
    );
  };

  handleSendLinkSuccess = () => {
    this.ref.current.scrollTop = 0;
    this.setState({ step: 3 });
  };

  handleSignUpSuccess = (token, userData) => {
    this.setState({ token, userData, step: 2 }, () => {
      this.ref.current.scrollTop = 0;
    });
  };

  renderBody = mainColor => {
    const { step, client_info, trainer, token, userData, team, linksDownload } = this.state;
    const { match, components } = this.props;
    switch (step) {
      case 1:
        const inviteCode = _.get(match, 'params.inviteCode', null);
        const SignUpForm = components ? components.SignUpForm : null;

        if (SignUpForm && inviteCode && client_info && trainer) {
          return (
            <SignUpForm
              client={client_info}
              trainer={trainer}
              team={team}
              inviteCode={inviteCode}
              mainColor={mainColor}
              onSuccess={this.handleSignUpSuccess}
            />
          );
        } else {
          return null;
        }

      case 2:
        return (
          <SendLink
            token={token}
            mainColor={mainColor}
            client={userData}
            onSuccess={this.handleSendLinkSuccess}
            phoneNumber={this.state.phoneNumber}
            downloadLinkBy={this.state.downloadLinkBy}
            setPhoneNumber={this.setPhoneNumber}
            setComplete={this.setComplete}
            setPhoneCountryCode={this.setPhoneCountryCode}
            setDownloadLinkBy={this.setDownloadLinkBy}
            linksDownload={linksDownload}
            team={team}
          />
        );

      case 3:
        return (
          <Complete
            client={userData}
            complete={this.state.complete}
            downloadLinkBy={this.state.downloadLinkBy}
            phoneNumber={this.state.phoneNumber}
            countryCode={this.state.phoneCountryCode}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { step, team, loadedData } = this.state;
    const logo = _.get(team, 'invite_email_banner') || null;
    const mainColor = logo ? '#474D66' : '#6965D7';
    return (
      <Wrapper step={step} ref={this.ref}>
        <div className="content">
          <Header step={step} hasLogo={!!logo}>
            {loadedData && <img src={logo || `${CDN_URL}/images/logo_with_text.svg`} alt="" />}
          </Header>
          <Body step={step}>
            <div className={`body__content${step > 1 ? ' success' : ''}`}>{this.renderBody(mainColor)}</div>
          </Body>
          {this.renderFooter(mainColor)}
        </div>
      </Wrapper>
    );
  }
}

export default withResponsive(SignUp);
