import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

import StudioProgramWarningIcon from 'assets/icons/studio_program_warning_icon_gray.svg';

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: transparent url(${CDN_URL}/images/drop_image.svg) no-repeat center;
  background-size: contain;
`;

export const TooltipIcon = styled.div`
  margin-bottom: 10px;
  width: 12px;
  height: 12px;
  background: url(${StudioProgramWarningIcon}) no-repeat center;
  background-size: contain;
  position: absolute;
  border-radius: 50%;
  right: 7px;
  top: 7px;
`;

export const Text = styled.div`
  color: #7a7a7a;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 16px;
  opacity: 0;

  .select-file__trigger {
    color: #5158cf;
    white-space: nowrap;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  ${props =>
    props.isDragging
      ? css`
          opacity: 1;
        `
      : css`
          :hover {
            background-color: #00000030;
            opacity: 1;

            ${Icon} {
              background-image: url(${CDN_URL}/images/drop_image_transparent.svg);
            }

            ${Text} {
              color: #ffffff;
            }

            .select-file__trigger {
              color: #ffffff;
            }
          }
        `}
`;

export const Wrapper = styled.div`
  background: #fafbfc;
  border: 1px dashed #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  height: 125px;
  width: 200px;
  outline: none;
  position: relative;

  ${props =>
    props.isDragging &&
    css`
      border: 1px dashed #5158cf;

      ${Icon} {
        background-image: url(${CDN_URL}/images/drop_image_purple.svg);
      }

      ${Placeholder} {
        opacity: 1;
      }
    `}
  ${props =>
    props.src &&
    !props.isDragging &&
    css`
      background-image: url(${props.src});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      cursor: pointer;
      border: 1px dashed transparent !important;
    `}
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background: transparent url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 2;
`;
