import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'shared/FormControl';
import * as S from './style';
import './style.scss';
import { getUserShortName, revokeObjectURL, revokeMultipleObjectURL, mediaLog } from 'utils/commonFunction';
import { CDN_URL, IN_APP_AUTO_MESSAGE } from 'constants/commonData';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import ConfirmModal from 'shared/ConfirmModal';
import ChatBar from './ChatBar';
import MessageItem from './MessageItem';
import { MESSAGE_TYPES, TimeOptions } from './constants';
import Schedule from './Schedule';
import { getAttachmentIDFromUrl } from 'helpers/replyComment';

const TIME_FORMAT = 'HH:mm';
const DEFAULT_TIME = { label: '08:00 AM', value: '08:00' };
const DATE_FORMAT = 'MM-DD-YYYY';

function InAppMessage(props) {
  const { user } = props;

  const isAddNew = !props.originalData || !Object.keys(props.originalData).length;
  let originalDay = props.day || moment().format(DATE_FORMAT);
  let originalTime = _.get(props.originalData, 'time', DEFAULT_TIME.value);

  const originalDate = moment(`${originalDay} ${originalTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
  const today = moment();

  if (isAddNew && originalDate.isSame(today, 'day') && originalDate.isBefore(today, 'minute')) {
    const currentTime = today.format(TIME_FORMAT);
    let nextTime = _.find(TimeOptions, t => t.value > currentTime);

    if (nextTime) {
      originalTime = nextTime.value;
    } else {
      originalTime = TimeOptions[0].value;
      originalDay = originalDate.add(1, 'day').format(DATE_FORMAT);
    }
  }

  const originalData = {
    subject: _.get(props.originalData, 'subject', ''),
    messages: _.get(props.originalData, 'messages', []),
    time: originalTime,
    day: originalDay,
  };

  const [date, setDate] = useState(moment(originalData.day, DATE_FORMAT));
  const [messages, setMessages] = useState(originalData.messages);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [time, setTime] = useState({
    label: _.get(
      _.find(TimeOptions, o => o.value === originalData.time),
      'label',
      '-',
    ),
    value: originalData.time,
  });
  const [subject, setTitle] = useState(originalData.subject);
  const [showError, setShowError] = useState(false);
  const [invalidDate, setInvalidDate] = useState(
    moment(`${date.format(DATE_FORMAT)} ${time.value}`, `${DATE_FORMAT} ${TIME_FORMAT}`).isBefore(moment(), 'minute'),
  );

  const [uploadingItems, setUploadingItems] = useState([]);
  const [hasTextInInput, setHasTextInInput] = useState(false);
  const removeIds = [];

  const trainerName = getUserShortName(user);
  const status = _.get(props.originalData, 'status', 0);

  useEffect(() => {
    return () => {
      revokeMultipleObjectURL(_.map(uploadingItems, 'objectURL'));
    };
  }, []);

  const onClose = (showConfirmPopup = true) => {
    const data = {
      oldValue: originalData,
      newValue: { subject, messages, time: time.value, day: date.format(DATE_FORMAT) },
    };
    props.onClose(showConfirmPopup ? data : null);
  };

  const onSubmit = () => {
    if (isSubmitting) {
      return false;
    }

    if (!subject.trim() || !messages.length) {
      return setShowError(true);
    }

    if (
      moment(`${date.format(DATE_FORMAT)} ${time.value}`, `${DATE_FORMAT} ${TIME_FORMAT}`).isBefore(moment(), 'minute')
    ) {
      return setInvalidDate(true);
    }

    const data = {
      subject: subject.trim(),
      messages: _.map(messages, m => _.omit(m, ['_id', 'objectURL'])),
      day: date.format(DATE_FORMAT),
      time: time.value,
      type: IN_APP_AUTO_MESSAGE,
    };

    const continueSubmit = () => {
      setIsSubmitting(true);
      Promise.resolve(props.onSubmit(data))
        .then(() => onClose(false))
        .catch(() => setIsSubmitting(false));
    };
    if (hasTextInInput) {
      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Are you done?"
          content="You have typed a message that has not been added to the list of scheduled messages yet. Would you like to add the message first, or continue to save and exit?"
          onConfirm={continueSubmit}
          confirmButtonTitle="Save & exit"
          cancelButtonTitle="Cancel"
          newStyle={true}
        />,
      );
    } else {
      continueSubmit();
    }
  };

  const onSelectTime = data => {
    const newDate = moment(`${date.format(DATE_FORMAT)} ${data.value}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    setTime(data);

    if (newDate.isBefore(moment(), 'minute')) {
      setInvalidDate(true);
    } else {
      setInvalidDate(false);
    }
  };

  const onSelectDate = data => {
    const newDate = moment(`${data.format(DATE_FORMAT)} ${time.value}`, `${DATE_FORMAT} ${TIME_FORMAT}`);
    setDate(data);

    if (newDate.isBefore(moment(), 'minute')) {
      setInvalidDate(true);
    } else {
      setInvalidDate(false);
    }
  };

  const autoScrollMessages = () => {
    setTimeout(() => {
      const element = document.querySelector('.inAppMessageForm__messages');
      if (element) {
        element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  };

  const onSendMessage = message => {
    autoScrollMessages();
    if (messages.length < 3) {
      setMessages(m => [...m, message]);

      if (message.type === MESSAGE_TYPES.FILE) {
        setUploadingItems([...uploadingItems, message._id]);
      }
    }
  };

  const onRemoveMessage = index => {
    const newMessages = messages.slice();
    const removeMessages = newMessages.splice(index, 1);

    removeIds.push(messages[index]._id);

    if (removeMessages[0].objectURL) {
      revokeObjectURL(removeMessages[0].objectURL);
      if (!_.isEmpty(uploadingItems) && uploadingItems.includes(removeMessages[0]._id)) {
        setUploadingItems(uploadingItems.filter(id => id !== removeMessages[0]._id));
      }
    }

    setMessages(newMessages);
  };

  const onUpdateTitle = event => {
    setTitle(event.target.value);
  };

  const isRemovedFromLocal = mId => {
    return removeIds.includes(mId);
  };
  const onUploadSuccess = ({ mId, data }) => {
    const newMessages = messages.slice();

    if (!isRemovedFromLocal(mId)) {
      const index = _.findIndex(messages, m => m._id === mId);
      if (messages[index]) {
        const {
          attachment: name,
          attachment_type: fileType,
          uploadConfigs: { url = '' },
        } = messages[index];
        mediaLog({
          status: 2,
          name,
          fileType,
          description: 'Upload success file via InAppMessage',
        });
        newMessages.splice(index, 1, {
          ..._.omit(messages[index], 'uploadConfigs'),
          attachment: url,
          attachment_id: getAttachmentIDFromUrl(url),
        });
      }
      setMessages(newMessages);
    }

    const newUploadingItems = uploadingItems.slice();
    _.remove(newUploadingItems, id => id === mId);

    setUploadingItems(newUploadingItems);
  };

  const onCancelUpload = ({ mId }) => {
    const index = _.findIndex(messages, m => m._id === mId);

    if (index !== -1) {
      const newUploadingItems = uploadingItems.slice();
      _.remove(newUploadingItems, id => id === mId);

      setUploadingItems(newUploadingItems);
      onRemoveMessage(index);
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      className="modal--in-app-message"
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.InAppMessageWrapper className="inAppMessageForm" sent={status === 1}>
          <ModalLayout.Header className="inAppMessageForm__header">
            <div className="inAppMessageForm__header__title">Schedule an In-app Message</div>
            <div className="inAppMessageForm__header__description">
              In-app messages will be delivered based on the Trainer’s timezone.
            </div>
          </ModalLayout.Header>
          <ModalLayout.Content className="inAppMessageForm__content">
            <Schedule
              subject={subject}
              date={date}
              time={time}
              showError={showError}
              status={status}
              onUpdateTitle={onUpdateTitle}
              onSelectDate={onSelectDate}
              onSelectTime={onSelectTime}
            />
            {invalidDate && status !== 1 ? (
              <S.ErrorContainer>
                <div className="icon" />
                <div>
                  <span className="label">Error: </span>You cant schedule a message in the past
                </div>
              </S.ErrorContainer>
            ) : null}
            <S.Messages className="inAppMessageForm__messages">
              {_.map(messages, (m, index) => (
                <MessageItem
                  key={m._id}
                  message={m}
                  avatarData={{ src: user.avatar, name: trainerName, color: user.color }}
                  onRemoveMessage={onRemoveMessage}
                  onUploadSuccess={onUploadSuccess}
                  onCancelUpload={onCancelUpload}
                  index={index}
                  sent={status === 1}
                />
              ))}
              {!!messages.length && <S.Tip>Each message will be sent 5s apart from each other</S.Tip>}
            </S.Messages>
            {status !== 1 ? (
              <ChatBar
                onSubmit={onSendMessage}
                placeholder="Type a message here"
                disabled={messages.length > 2}
                setHasTextInInput={setHasTextInInput}
              />
            ) : null}
          </ModalLayout.Content>
          <ModalLayout.Actions className="inAppMessageForm__actions">
            {status === 1 ? (
              <div className="allMessageSent">
                <span>All messages sent!</span>
              </div>
            ) : (
              <>
                <div className="remaining">Messages remaining: {3 - messages.length}/3</div>
                <Button disabled={!messages.length || invalidDate || uploadingItems.length} onClick={onSubmit} purple>
                  Schedule
                </Button>
              </>
            )}
          </ModalLayout.Actions>
        </S.InAppMessageWrapper>
      </Modal.Content>
    </Modal>
  );
}

InAppMessage.propTypes = {
  originalData: PropTypes.object.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    _id: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default InAppMessage;
