import styled, { css } from 'styled-components';
import ConfirmModalElement from 'shared/ConfirmModal';
import { Dropdown as DropdownElement, Modal as ModalElement, TextArea } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import LeaderBoardBackground from 'assets/images/forum-advanced-settings-leaderboard-bg.png';

export const Wrapper = styled.div`
  max-width: 720px;
  margin: 42px auto 120px;
  .go-back-btn:hover {
    color: #5558ff;
    svg {
      path {
        stroke: #5558ff;
      }
    }
  }
  .go-back-btn {
    background: transparent;
    justify-content: flex-start;
    color: #5158cf;
    path {
      stroke: #5158cf;
    }
  }
  .delete-btn-container {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    svg {
      margin-right: 10px;
    }
    button {
      width: 380px;
      height: 53px;
    }
  }
`;

export const ConfirmModal = styled(ConfirmModalElement)`
  .confirm-content-header {
    padding-top: 30px !important;
  }
  .confirm-content-body {
    padding-top: 15px !important;
    padding-bottom: 30px !important;
  }
`;

export const SettingContainer = styled.div`
  margin-top: 20px;
  background: #ffffff;
  padding: 30px;
  box-shadow: 0px 2px 4px rgba(42, 42, 52, 0.15);
  border-radius: 8px;

  input.error {
    background: #fff7f7 !important;
    border-color: #ff9791 !important;
  }

  input.error::placeholder {
    color: #ff2e21;
  }

  .save-btn-container {
    float: right;
  }
  .title-container {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #222222;
    margin-bottom: 30px;
  }
  .forum-form-group {
    margin-bottom: 20px;
    position: relative;
    input {
      padding-right: 75px !important;
    }
    .character-count {
      position: absolute;
      right: 15px;
      top: 28px;
      color: rgba(0, 0, 0, 30%);
      font-size: 13px;
    }
    .character-count.not-focused {
      visibility: hidden;
    }
  }
  .forum-form-group.no-bottom-margin {
    margin-bottom: 0px;
  }
  .title-container svg {
    margin-right: 10px;
  }
  .forum-description-textarea {
    min-height: 80px;
    min-width: 660px;
    max-width: 660px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 5px !important;
    }
  }
  .choose-autoflow-select {
    max-width: 370px;
  }
  .manage-discusstion-select {
    width: 181px;
  }
  .breaker {
    width: 100%;
    background: #efefef;
    margin: 15px 0px;
    height: 1px;
  }
  .discussion-settings-container {
    display: flex;
    justify-content: space-between;
  }
  .single-select__control {
    border: 1px solid #d4d7d9;
    box-sizing: border-box;
    border-radius: 5px;
    background: #fafbfc;
    min-height: ${props => (props.isManageDiscussion ? '46px' : '65px')};
    padding: 0 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);

    :hover,
    &.single-select__control--menu-is-open,
    &.single-select__control--is-focused {
      border-color: #5158cf !important;
    }

    .single-select__indicator-separator {
      display: none;
    }

    .single-select__dropdown-indicator svg {
      width: 12px;
      height: 12px;
    }

    .single-select__input {
      input {
        height: auto;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #323c47;
      }
    }

    .single-select__placeholder,
    .single-select__single-value {
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: #777777;
    }
  }

  .single-select__menu {
    margin: 0;
    padding: ${props => (props.isManageDiscussion ? '5px 0px' : '0px')};
    outline: 1px solid #7470ef;
    overflow: hidden;

    .single-select__menu-list {
      ${props => !props.isManageDiscussion && `padding: 0px;`}
      .single-select__option {
        background: #fff;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
        padding: ${props => (props.isManageDiscussion ? '10px' : '0px')};
        cursor: pointer;
        :hover {
          color: #5c5bbd;
          background: #f4f4ff !important;
          font-weight: 600;
        }
        &.single-select__option--is-selected {
          color: #5c5bbd;
          font-weight: 600;
        }
      }
    }
  }
`;

export const DiscussionSettingLabel = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
  margin-bottom: 5px;
`;

export const DiscussionSettingDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;

  color: #323c47;
`;

export const CustomPlaceholder = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #a6acc6;
  }
`;

export const CustomOption = styled.div`
  -webkit-font-smoothing: auto;
  padding: 15px;
  position: relative;
  box-shadow: inset 0px -1px 0px rgba(202, 202, 202, 0.25);
  color: #202353;
  cursor: pointer;
  .autoflow-name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }
  .autoflow-details {
    font-size: 12px;
  }
  .clients-count {
    font-weight: 600;
  }
  :hover {
    background: #f7f6ff;
  }
`;

export const CustomValue = styled.div`
  padding: 15px 5px 15px 15px;
  background: #fafbfc;
  border: 1px solid #dadfea;
  border-radius: 5px;
  height: 65px;
  width: 370px;
  position: relative;

  .autoflow-name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    max-width: 310px;
  }
  .autoflow-details {
    font-size: 12px;
  }
  .clients-count {
    font-weight: 600;
  }
  .invite-clients-btn {
    color: #5158cf;
    cursor: pointer;
    font-weight: 600;
    :hover {
      text-decoration: underline;
    }
  }
  .three-dots-btn {
    cursor: pointer;
    border-radius: 3px;
    :hover {
      background: #f2f4f6;
    }
  }
  .three-dots-btn.active {
    background: #f2f4f6;
  }
  .dropdown-container {
    position: absolute !important;
    top: 5px;
    right: 5px;
  }
`;

export const Dropdown = styled(DropdownElement)`
  .popup-button {
    display: flex;
    align-items: center;
    height: 46px;
    cursor: pointer;
    padding: 0px 20px;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    .go-autoflow {
      color: #5158cf;
    }
    .disconnect-autoflow {
      color: #ea314a;
    }
  }

  .popup-button:hover {
    background: #eaebff;
  }

  .popup-button svg {
    margin-right: 10.5px;
  }
  .item:first-child::before {
    transform: translate(-91px, 0px) rotate(45deg) !important;
  }
  .dropdown-menu.more-actions {
    transform: translate(91px, 4px);
  }
`;

export const Breaker = styled.div`
  margin: auto;
  height: 1px;
  width: 178px;
  background: #dadfea;
`;

export const DropdownMenu = styled(DropdownElement.Menu)`
  padding: 10px 0px 12px 0px;
  font-weight: 600;
  font-size: 14px;
  color: #202353;
`;

export const Modal = styled(ModalElement)`
  .modal-header {
    border-radius: 8px;
  }
  .modal-content {
    border-radius: 8px;
    padding-top: 0px !important;
    -webkit-font-smoothing: auto;
  }
  .actions {
    box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
    background: #ffffff !important;
    border-top: none !important;
  }
  .invite-description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #323c47;
    margin-bottom: 30px;
  }
  .checkbox-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
  }
  .invite-checkbox {
    margin-bottom: 10px;
    &:nth-child(3) {
      margin-bottom: 20px;
    }
  }
  .actions-btn {
    width: 110px;
  }
  .actions-btn:first-of-type {
    margin-right: 10px;
  }
`;

export const SaveButton = styled(Button)`
  ${props =>
    props.disabled &&
    `
  background-color: #5158cf !important;
  color: #ffffff;
  `}
`;

export const LeaderBoardContainer = styled.div`
  padding: 15px 32px 15px 92px;
  width: 370px;
  height: 89px;
  background-image: url(${props => (props.noBackground ? 'none' : LeaderBoardBackground)});
  ${props =>
    props.noBackgroundImage &&
    css`
      display: flex;
      align-items: center;
      padding: 0px !important;
      background-color: #fafbfc;
    `}
  background-repeat: no-repeat;
  outline: 1px solid #dadfea;
  border-radius: 5px;
  .create-leaderboard-btn {
    color: #5158cf;
    cursor: pointer;
    font-weight: 600;
    :hover {
      text-decoration: underline;
    }
    font-size: 13px;
  }
  .create-leaderboard-description {
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;
    color: #202353;
    margin-bottom: 7px;
  }
  .leaderboard-name {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
    margin-bottom: 5px;
  }
  .leaderboard-description {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #202353;
  }
`;

export const LeaderboardTemplateBanner = styled.img`
  width: 89px;
  height: 89px;
  margin-right: 15px;
  object-fit: cover;
`;

export const STextArea = styled(TextArea)`
  overflow-y: hidden;
`;
