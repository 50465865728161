import React from 'react';
import get from 'lodash/get';
import * as S from './Tabs.style';

const Tabs = ({ tabs = [], total, activeTab, onChange, ...restProps }) => {
  return (
    <S.Tabs {...restProps}>
      {tabs.map(tab => {
        const label = get(tab, 'label', '');
        const value = get(tab, 'value', '');
        const isActive = activeTab === value;
        return (
          <S.Tab key={`tab-${value}`} isActive={isActive} onClick={() => onChange && onChange(value)}>
            {label} ({get(total, value, 0)})
          </S.Tab>
        );
      })}
    </S.Tabs>
  );
};

export default Tabs;
