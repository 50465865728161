import styled, { css } from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  .collectionResources {
    &__info {
      padding: 25px 50px;
      width: calc(100% - 330px);
      height: 100%;
      overflow: auto;
      position: relative;
      &.full {
        width: calc(100% - 24px);
      }
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &--saveBtn {
        padding: 7px 40px;
      }
    }

    &__limitResource {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;
      color: #76778a;
      margin-bottom: 15px;
    }

    &__mobilePreview {
      height: auto;
      width: 330px;
      background: #fafbfc;
      box-shadow: inset 1px 0px 0px #e1e5ec;
      margin-left: 10px;
      overflow: auto;
      &.hidden {
        width: 24px;
        > div {
          display: none;
        }
      }
    }
  }
`;

export const CollapseWrapper = styled.div`
  position: absolute;
  width: 29px;
  height: 29px;
  border: 1px solid #e1e5ec;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(92, 91, 189, 0.1);
  top: 40px;
  right: 312px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 4px 0;
  background-color: #fff;
  cursor: pointer;
  &.hidden {
    right: 9px;
  }
  i.icon {
    margin: 0;
    font-size: 10px;
  }
`;

export const NewSection = styled.div`
  border: 1px solid #ebedf4;
  border-radius: 8px;
  margin-top: 15px;
  height: 59px;
  align-items: center;
  display: flex;
  padding: 0 20px;
  color: #c4c4c4;
  cursor: pointer;
  &:hover {
    border: 1px solid #5158cf;
    color: #5158cf;
  }

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    margin-left: 10px;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;

      &:hover {
        border: 1px solid #ebedf4 !important;
        color: #c4c4c4 !important;
      }
    `}
`;

export const OverlapWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;

export const OwnershipPopupWrapper = styled.div`
  margin-right: 5px;
`;
