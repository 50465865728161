import { connect } from 'react-redux';
import StudioProgramCalendar from './component';

import { toggleModal, toggleConfirmModal } from 'actions/modal';
import {
  getListWorkouts,
  changeCalendarViewMode,
  goToWeek,
  resetStudioProgramCopyItem,
  moveStudioProgramWorkout,
  moveStudioProgramSection,
  arrangeStudioProgramWorkoutInDay,
  arrangeSectionInsideStudipProgramWorkout,
  turnOnEditMode,
  cancelStudioProgramChanges,
  publishStudioProgramChanges,
  removeStudioProgramWeek,
  addStudioProgramWeek,
  getStudioProgramDetail,
  handleSelectWeek,
  handleResetWeek,
  handlePasteWeek,
  handleRemoveMultipleWorkout,
  handleResetWorkout,
  handleCopyWorkout,
  handleRemoveWeek,
} from 'redux/studio-program/actions';
import { showError } from 'actions/error';

const mapState = state => {
  const {
    rootReducer: {
      studioProgram: {
        calendarViewMode,
        startWeek,
        workingStudio,
        copying,
        selectedWeek,
        selectedWorkout,
        workoutsByDay,
        loadingPaste,
        isGetWorkoutsLoading,
      },
    },
  } = state;

  return {
    user: state.user,
    calendarViewMode,
    startWeek,
    copying,
    workingStudio,
    selectedWeek,
    selectedWorkout,
    workoutsByDay,
    loadingPaste,
    isGetWorkoutsLoading,
  };
};

const actionCreators = {
  getListWorkouts,
  changeCalendarViewMode,
  goToWeek,
  resetStudioProgramCopyItem,
  moveStudioProgramWorkout,
  moveStudioProgramSection,
  arrangeStudioProgramWorkoutInDay,
  arrangeSectionInsideStudipProgramWorkout,
  turnOnEditMode,
  cancelStudioProgramChanges,
  publishStudioProgramChanges,
  toggleModal,
  toggleConfirmModal,
  removeStudioProgramWeek,
  addStudioProgramWeek,
  showError,
  getStudioProgramDetail,
  handleSelectWeek,
  handleResetWeek,
  handlePasteWeek,
  handleRemoveMultipleWorkout,
  handleResetWorkout,
  handleCopyWorkout,
  handleRemoveWeek,
};

export default connect(mapState, actionCreators)(StudioProgramCalendar);
