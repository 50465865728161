import styled, { css } from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';
import LoadingIndicator from 'shared/LoadingIndicator';

export const MealPlanDetailWrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  height: calc(100% - 60px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background: #e1e1ea !important;
  }
`;

export const WeekNutrition = styled.div`
  margin: 20px 30px 10px;
  height: 50px;
  border-radius: 5px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  padding: 0 5px;

  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      margin-bottom: 30px;
    `}
`;

export const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: #e1e1ea;
  margin: 0 10px;
`;

export const WeekWrapper = styled.div``;

export const DayWrapper = styled.div`
  padding-top: 30px;
  margin-bottom: 10px;
  position: relative;
  &:last-child {
    margin-bottom: 40px;
  }
  ${({ rearrangeMode }) =>
    rearrangeMode &&
    css`
      padding-top: 0;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 30px;
      }
      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        bottom: -10px;
        border-top: 1px dashed var(--color);
        border-bottom: 1px dashed var(--color);
        z-index: 1;
        pointer-events: none;
      }
    `}
`;

export const ConfirmModalCustom = styled(ConfirmModal)`
  &.confirm-popup-container {
    .confirm-content-header {
      padding: 30px 30px 0;
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions button {
      width: 104px;
      height: 36px;
    }
  }
`;

export const AddNoteWrapper = styled.div`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const LoadingWrapper = styled(LoadingIndicator)`
  padding-top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-indicator {
    padding-top: 0;
  }
`;
