import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import * as S from './style';

const MAX_DESCRIPTION_TYPING_HEIGHT = 108;

function WorkoutInfo({ description: originalDescription, onUpdateDescription, disabled = false }) {
  const titleRef = React.createRef();
  const descriptionRef = React.createRef();
  const [description, setDescription] = useState(originalDescription);

  useEffect(() => {
    titleRef.current && titleRef.current.focus();
  }, []);

  useEffect(() => {
    // should only run when name or description
    //  is updated from the parent component (select workout with empty name/description case)
    if (originalDescription !== description) {
      setDescription(originalDescription);
    }
  }, [originalDescription]);

  const handleChangeDescription = value => {
    setDescription(value);
    // oChangeNewData('description', value.trim());
    onUpdateDescription && onUpdateDescription(value);

    // show the new line after click Enter key
    if (descriptionRef.current) {
      const child = get(descriptionRef.current, 'inputRef.current', false);

      if (child && child.clientHeight >= MAX_DESCRIPTION_TYPING_HEIGHT) {
        child.scrollTop = child.scrollHeight;
      }
    }
  };

  const handleBlur = e => {
    // TODO - scroll top after blur of field
    e.target.scrollTop = 0;
  };

  return (
    <>
      <S.Label>DESCRIPTION</S.Label>
      <S.DescriptionField
        ref={descriptionRef}
        breakLine
        readOnly={disabled}
        className="workout-collection-title"
        value={description}
        onChange={handleChangeDescription}
        onBlur={handleBlur}
        placeholder="Add a description"
        lineHeight={19.5}
      />
    </>
  );
}

export default WorkoutInfo;
