import styled, { css } from 'styled-components';

export const DocumentWrapper = styled.div`
  padding: 16px 30px 0;
  width: 100%;

  ${props =>
    !props.sideBarVisible &&
    css`
      padding: 16px 60px 0;
    `}
`;

export const ListWrapper = styled.div`
  height: calc(100% - 110px);
  padding-bottom: 10px;
  overflow-y: scroll;
  ${props =>
    props.isOnePage &&
    css`
      height: calc(100% - 58px);
    `}

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const EmptyUI = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
