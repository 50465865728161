import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #202353;
`;

export const Wrapper = styled(Modal)`
  &.ui.modal {
    width: 630px;
    border-radius: 8px !important;
    .close-button {
      top: -10px;
      right: -10px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const Header = styled.div`
  padding: 30px 30px 20px 30px;
  .title {
    ${baseText}
    color: #151619;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0 30px 34px 30px !important;
`;

export const AddFormFromScratch = styled.div`
  width: 182px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid #ebedf4;
  border-radius: 8px;
  padding: 16px 15px;
  background-color: #fff;
  span {
    ${baseText}
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid #5e59ff;
  }
`;

export const FormTemplateWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const HeaderTemplate = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
`;

export const BodyTemplate = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 8px;
  cursor: pointer;
`;

export const OptionWrapper = styled.div`
  width: 100%;
  height: 184px;
  padding: 19px;
  position: relative;
  border: 1px solid #ebedf4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: unset;
  gap: 16px;
  box-sizing: border-box;
  &:hover {
    border: 1px solid #5e59ff;
  }
  ${({ active }) =>
    active &&
    css`
      border: 1px solid #5e59ff;
    `}
`;

export const OptionEmptyWrapper = styled.div`
  width: 100%;
  height: 184px;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading--form-templates {
    padding-top: 0;
    .custom-indicator::after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const OptionTopContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FormIconWrapper = styled.div`
  display: flex;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CheckBoxCircle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 11px;
  width: 11px;
  background-color: #5e59ff;
  border-radius: 50%;
  opacity: 0;
`;

export const CheckBoxIconWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #c3c5cb;
  background-color: #fff;
  ${props =>
    props.active &&
    css`
      border: 1px solid #5e59ff;
      box-sizing: border-box;
      ${CheckBoxCircle} {
        opacity: 1;
      }
    `}
`;

export const OptionMainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionTitle = styled.div`
  ${baseText}
  color: #202353;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
`;

export const OptionDesc = styled.div`
  ${baseText}
  width: calc(100% - 18px);
  font-size: 12px;
  font-weight: 400;
  color: rgb(32, 35, 83, 0.8);
  margin-bottom: 8px;
`;

export const OptionQuestion = styled.div`
  ${baseText}
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const OptionContainer = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    ${baseText}
    color: rgb(32, 35, 83, 0.5);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const QuestionNumber = styled.div`
  ${baseText}
  color: rgb(32, 35, 83, 0.5);
  font-size: 12px;
  font-weight: 700;
`;

export const SignatureWrapper = styled.div`
  display: flex;
  margin-left: 3px;
  gap: 8px;
  .symbol-bullet {
    font-weight: 700;
    color: #c4c4c4;
  }
`;

export const FormInfoLayout = styled.div`
  width: 81px;
  height: 18px;
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 3px;
  background-color: #edf0fa;
  svg {
    fill: #202870;
  }
`;

export const FormInfoText = styled.div`
  ${baseText}
  color: #202870;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Actions = styled.div`
  height: 52px;
  padding: 0 30px 0 30px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px -4px 16px 0px #6b6fb71a;
`;

export const RightAction = styled.div`
  display: flex;
  gap: 5px;
`;

export const Button = styled.button`
  ${baseText}
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  color: #818da1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 30px;
  width: 92px;
  border: none;
  background: transparent;
  border: 1px solid #d6dae4;
  &.add-btn {
    width: 95px;
    max-width: 95px;
    color: #fff;
    border: 1px solid transparent;
    background: rgba(94, 89, 255, 1);
  }
  &:hover {
    cursor: pointer;
    &.add-btn {
      color: #fff;
      border: 1px solid transparent;
      background: rgba(94, 89, 255, 0.9);
    }
  }
  &:disabled {
    cursor: not-allowed;
    background: rgba(189, 189, 189, 1);
    &:hover {
      &.add-btn {
        background: rgba(189, 189, 189, 0.9);
      }
    }
  }
`;
