import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { MEDIA_FILE_REJECTED_CONTENT } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';

// Define type message
export const MESSAGE_TYPE = {
  DELETED: 'deleted',
  TEXT: 'text',
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE: 'image',
  GIF: 'gif',
  GIPHY: 'giphy',
  MULTIPLE_MEDIA: 'multiple_media',
};

/**
 * Returns the type of attachment for a given message.
 *
 * @param {Object} message - The message object.
 * @returns {String} The type of attachment.
 */
export const getAttachmentType = message => {
  if (!message) return;
  // Giphy
  const attachmentSource = get(message, 'attachment_source');
  if (!isEmpty(attachmentSource)) {
    if (isEqual(attachmentSource, 'giphy')) return MESSAGE_TYPE.GIPHY;
  }
  // Video, Audio, Image, Gif
  const attachmentType = get(message, 'attachment_type');
  if (!isEmpty(attachmentType)) {
    if (attachmentType.startsWith('video')) return MESSAGE_TYPE.VIDEO;
    if (attachmentType.startsWith('audio')) return MESSAGE_TYPE.AUDIO;
    if (attachmentType.startsWith('image')) return MESSAGE_TYPE.IMAGE;
    if (attachmentType.startsWith('image') && attachmentType.includes('gif')) return MESSAGE_TYPE.GIF;
  }
};

/**
 * Returns the type of a given message.
 * @param {Object} message - The message to check.
 * @returns {string} The message type.
 */
export const getMessageType = message => {
  if (!message) return;
  const { was_deleted, was_system_deleted, content, attachments, attachment } = message;

  if (was_deleted || was_system_deleted) {
    return MESSAGE_TYPE.DELETED;
  }

  if (isEqual(content, MEDIA_FILE_REJECTED_CONTENT) && isArray(attachments) && attachments.length) {
    return MESSAGE_TYPE.MULTIPLE_MEDIA;
  }

  if (isEqual(content, MEDIA_FILE_REJECTED_CONTENT) && !isEmpty(attachment)) {
    return getAttachmentType(message);
  }

  return MESSAGE_TYPE.TEXT;
};

// Get message thumbnail
export const getMessageThumbnail = message => {
  return get(message, 'attachment_thumbnail') || get(message, 'attachment') || null;
};

/**
 * Returns a preview message for an incoming message with multiple media attachments.
 *
 * @param {Object} options - Options for generating the preview message.
 * @param {Object} options.message - The message object.
 * @param {boolean} options.isOwner - Whether the current user owns the message.
 * @param {string} options.firstName - The first name of the sender of the message.
 * @return {string} The preview message for the incoming multiple media message.
 */
export const getPreviewIncomingMultipleMediaMessage = ({ message, isOwner, firstName }) => {
  const messageType = getMessageType(message);

  if (messageType !== MESSAGE_TYPE.MULTIPLE_MEDIA) {
    return '';
  }

  const attachments = message.attachments || [];
  const attachmentCount = attachments.length;
  let hasVideo = false;
  let hasImage = false;
  let hasGif = false;

  for (let i = 0; i < attachmentCount; i++) {
    const attachmentType = attachments[i].attachment_type || '';

    if (attachmentType.includes('video')) {
      hasVideo = true;
    }
    if (attachmentType === 'image/gif') {
      hasGif = true;
    } else if (attachmentType.startsWith('image')) {
      hasImage = true;
    }
    if (hasVideo && hasImage && hasGif) {
      break;
    }
  }

  const sender = isOwner ? 'You' : firstName;
  let mediaType = '';

  if ((hasVideo && hasImage && hasGif) || (hasVideo && hasGif) || (hasImage && hasGif) || (hasVideo && hasImage)) {
    mediaType = 'attachment';
  } else if (hasVideo) {
    mediaType = 'video';
  } else if (hasGif) {
    mediaType = 'GIF file';
  } else if (hasImage) {
    mediaType = 'photo';
  } else {
    mediaType = '';
  }

  const attachmentText = `${attachmentCount} ${pluralize(mediaType, attachmentCount)}`;
  return `${sender} sent ${attachmentText}`;
};

// Get message title replied
export const getTitleReplied = ({
  isOwner,
  isRepliedSelf,
  isRepliedMe,
  firstName,
  firstNameReplied,
  viewTeammate,
  room,
}) => {
  // Owner see trainer's chat box
  if (viewTeammate) {
    if (isRepliedSelf) return `${firstName} replied to themself`;
    return `${firstName} replied to ${firstNameReplied}`;
  }

  // Owner
  if (isOwner) {
    if (isRepliedSelf) return `You replied to yourself`;
    if (firstNameReplied) return `You replied to ${firstNameReplied}`;
    return `You replied to ${firstName} `;
  }

  // Client
  if (isRepliedSelf) return `${firstName} replied to themself`;
  if (firstNameReplied && !isRepliedMe) return `${firstName} replied to ${firstNameReplied}`;
  return `${firstName} replied to you`;
};

// Get attachment url without presigned url
export const getAttachmentUrl = (presignedUrl = '') => {
  return presignedUrl.substring(0, presignedUrl.indexOf('?X-Amz-Algorithm='));
};

// Get attachment id from presigned url
export const getAttachmentId = (presignedUrl = '') => {
  let id = presignedUrl.split('/') || [];
  id = id[id.length - 1];
  id = id.substring(0, id.indexOf('.'));
  return id;
};
