import styled from 'styled-components';

export const GoalItemStyled = styled.div`
  border-radius: 8px;
  background-color: transparent;
  color: #202353;
  min-height: 88px;
  position: relative;
  padding-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover .goal-actions {
    display: block;
  }

  .goal-type {
    position: absolute;
    left: 0px;
    top: -3px;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    border: 4px solid #fff;
    border-radius: 8px;
    width: 112px;
    height: 32px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19.5px;
    height: 36px;
    background-color: #edf7fe;
    white-space: nowrap;
    padding: 5px 13px 4px 12px;
  }

  .goal-text {
    position: relative;
    top: -4px;
    width: 100%;
    min-height: 68px;
    padding: 34px 45px 20px 21px;
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
    color: #202353;
    background-color: #fff;
    border-radius: 8px;
    white-space: pre-line;
  }
`;
