import ConfirmModal from 'shared/ConfirmModal';
import styled from 'styled-components';

import { CDN_URL } from 'constants/commonData';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  color: #202353;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
`;

export const HeaderWrapper = styled.div`
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #f0f0f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 15px;
`;

export const BackWrapper = styled.div`
  ${baseText}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 83px;
  border-radius: 5px;
  cursor: pointer;
  svg {
    padding-right: 5px;
  }
  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .__react_component_tooltip.app-tooltip.place-top:after,
  .__react_component_tooltip.app-tooltip-white.place-top:after {
    bottom: -8px;
  }

  .recipe-detail-options-tooltip #recipe-detail-options-tooltip:after {
    margin-left: 10px !important;
  }

  .ui.dropdown.ownership-dropdown {
    min-height: 40px;
    height: auto;
    &::after {
      content: url(${CDN_URL}/images/down_arrowp.svg);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }

  .ui.disabled.dropdown.ownership-dropdown {
    &::after {
      content: none;
    }
  }

  .ui.dropdown.ownership-dropdown:not(disabled) .dropdown.icon {
    display: none;
  }

  .custom-drop-down-actions {
    .evf-dropdown__trigger-container .drop-down-trigger {
      width: 30px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #e1e1ea;
      &:hover {
        background: #f0f1ff;
        .dot,
        .dot::after,
        .dot::before {
          background-color: #5158cf;
        }
      }
    }

    &.open .evf-dropdown__trigger-container .drop-down-trigger {
      border: 1px solid #e1e1ea;
      background: #f0f1ff;
    }

    .evf-dropdown__menu {
      padding: 4px 0;
      .evf-dropdown__option {
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        user-select: none;
        span {
          color: #f7f8f9;
          font-family: 'Open Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }

  .custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: unset;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    ${baseText}
    svg {
      margin-right: 5px;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  .save-button {
    width: 67px;
  }

  .edit-button {
    width: 84px;

    &:disabled {
      color: #7b7e91 !important;
      background-color: #eaeef1 !important;
      .custom-edit-icon path {
        fill: #7b7e91 !important;
      }
      &:hover {
        color: #7b7e91;
        background-color: #eaeef1;

        .custom-edit-icon path {
          fill: #7b7e91;
        }
      }
    }
  }

  .edit-button,
  .save-button,
  .cancel-edit-button {
    border: 1px solid #e1e1ea;
    .custom-edit-icon {
      width: 16px;
      height: 16px;
      path {
        fill: #202353;
      }
    }
    &:hover {
      color: #5158cf;
      background-color: #f0f1ff;
      .custom-edit-icon path {
        fill: #5158cf;
      }
    }
    &:disabled {
      color: #202353;
      background-color: #fff;
      opacity: 0.5;
    }
  }

  .publish-button {
    color: #fff;
    background-color: #36b34b;
    border: unset;
    .custom-publish-icon {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background-color: rgba(54, 179, 75, 0.9);
    }
    &:disabled {
      opacity: 0.5;
      background-color: #36b34b;
    }
    svg {
      margin-right: 5.5px;
    }
  }

  .unpublish-button {
    color: #fff;
    background-color: #ffbe49;
    border: unset;
    .custom-unpublish-icon {
      width: 16px;
      height: 16px;
      path {
        fill: #fff;
      }
    }
    &:hover {
      background-color: rgba(255, 190, 73, 0.9);
    }
    &:disabled {
      background-color: #ffbe49;
      opacity: 0.5;
    }
  }

  .publish-changes-button {
    color: #fff;
    background-color: #5158cf;
    border: unset;
    .custom-publish-changes-icon {
      width: 16px;
      height: 16px;
      path {
        fill: #fff;
      }
    }
    &:hover {
      background-color: rgba(81, 88, 207, 0.9);
    }
    &:disabled {
      background-color: #5158cf;
      opacity: 0.5;
    }
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.unpublish-recipe-modal,
  &.ui.modal.confirm-popup-container.cancel-edit-recipe-modal,
  &.ui.modal.confirm-popup-container.validate-publish-modal {
    width: 467px !important;
    .close-button {
      right: -7px;
    }
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
      .confirm-header-label {
        ${baseText}
        font-size: 21px;
        line-height: 32px;
      }
    }
    .confirm-content-body {
      padding: 25px 30px 25px;
      .confirm-content-label {
        ${baseText}
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .confirm-actions .button {
      border-radius: 5px;
      ${baseText}
      margin-left: 8px !important;
    }
  }

  &.ui.modal.confirm-popup-container.validate-publish-modal {
    &.is-disabled {
      .confirm-yes-button {
        cursor: not-allowed;
      }
    }
  }

  &.ui.modal.confirm-popup-container.unpublish-recipe-modal {
    .confirm-actions {
      .confirm-no-button {
        min-width: unset;
        width: 89px;
        padding: 5px 25px;
        border: 1px solid #e1e1ea !important;
        color: #777 !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        width: 151px;
        padding: 5px 24px;
        color: #fff;
        background-color: #ffbe49 !important;
        &:hover {
          background-color: rgba(255, 190, 73, 0.9) !important;
        }
      }
    }
  }

  &.ui.modal.confirm-popup-container.cancel-edit-recipe-modal {
    .confirm-actions {
      .confirm-no-button {
        min-width: unset;
        width: 146px;
        padding: 5px 24px;
        border: 1px solid #e1e1ea !important;
        color: #777 !important;
      }
      .confirm-yes-button {
        box-shadow: unset;
        min-width: unset;
        padding: 5px;
        width: 120px;
        color: #fff;
        background-color: #ea314a !important;
        &:hover {
          background-color: rgba(234, 49, 74, 0.9) !important;
        }
      }
    }
  }

  &.ui.modal.confirm-popup-container.validate-publish-modal {
    &.is-edit .confirm-actions .confirm-yes-button {
      padding: 5px 35px;
    }
    .confirm-actions .confirm-yes-button {
      box-shadow: unset;
      min-width: unset;
      padding: 5px 24px;
      width: fit-content;
      color: #fff;
      background-color: #ffbe49 !important;
      &:hover {
        background-color: rgba(255, 190, 73, 0.9) !important;
      }
    }
  }
`;

export const OptionIcon = styled.div`
  display: flex;
  justify-content: center;
`;
