import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  padding: unset !important;
  &.ui.modal {
    margin: unset !important;
    border-radius: unset !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    width: ${({ width }) => width || '507px'};
    height: ${({ height }) => height || 'auto'};
  }
  &.ui.modal > .header,
  &.ui.modal > .content,
  &.ui.modal > .actions {
    border: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    background: transparent !important;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #fff;
  padding: 30px 30px 0;
  border-radius: 8px 8px 0 0;

  .trash-icon {
    flex-shrink: 0;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  color: #202353;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background: #fff;
  padding: 15px 30px 30px;
`;

export const Content = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #202353;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .radio-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    cursor: pointer;

    .radio-button {
      width: 16.67px;
      height: 16.67px;
      border: 2px solid #8789a8;
      border-radius: 50%;

      &.checked {
        position: relative;
        border: 2px solid #5158cf;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8.33px;
          height: 8.33px;
          border-radius: 50%;
          background-color: #5158cf;
        }
      }
    }
  }

  .radio-label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #202353;
    cursor: pointer;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  padding: 0 30px 30px;
  border-radius: 0 0 8px 8px;
  background-color: #fff;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: unset;
  border: unset;

  padding: 0 30px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  cursor: pointer;

  &.cancel {
    height: 32px;
    border: 1px solid #d6dae4;
    color: #818da1;
    background-color: #fff;
  }

  &.confirm {
    height: 30px;
    color: #fff;
    background-color: #ea314a;
  }
`;
