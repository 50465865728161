import React, { useEffect, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { Button, CheckboxCircle } from 'shared/FormControl';
import * as S from './styles';
import { get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateGroupSetting } from 'redux/forum/actions';
import { toggleConfirmModal } from 'actions/modal';
import { CDN_URL } from 'constants/commonData';

const ModalStyles = { width: '445px' };
const options = [
  {
    label: 'New posts and reactions',
    value: 1,
  },
  {
    label: 'Only new posts',
    value: 3,
  },
  {
    label: 'Only comments and likes',
    value: 2,
  },

  {
    label: 'None',
    value: 0,
  },
];

function ManageNotifications(props) {
  const originalNotiSetting = get(props, 'groupSettings.notification_mode', 1);
  const [option, setOption] = useState(originalNotiSetting);
  const [isChanged, setIsChanged] = useState(false);

  const handleSelectRadio = value => {
    setOption(value);
    setIsChanged(true);
  };
  const handleCloseAction = () => {
    if (!isChanged) {
      props.onCloseAction();
    } else {
      props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={() => props.onCloseAction()}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    }
  };
  const handleSave = () => {
    props.updateGroupSetting(props.groupId, { notification_mode: option });
    props.onCloseAction();
  };
  return (
    <Modal
      open={true}
      onClose={handleCloseAction}
      closeOnDimmerClick={false}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
      style={ModalStyles}
    >
      <Modal.Header style={{ borderRadius: '8px' }}>
        <div>My Notifications</div>
      </Modal.Header>
      <Modal.Content style={{ borderRadius: '8px' }}>
        <S.OptionsContainer>
          {options.map((el, idx) => (
            <div key={idx}>
              <S.Option key={el.value} onClick={() => handleSelectRadio(el.value)}>
                <span>{el.label}</span>
                <CheckboxCircle
                  className="radio-button"
                  value={el.value}
                  checked={el.value === option}
                  onChange={handleSelectRadio}
                />
              </S.Option>
              {idx !== options.length - 1 && <S.Breaker />}
            </div>
          ))}
        </S.OptionsContainer>
        <S.SaveButtonContainer>
          <Button purple style={{ width: '110px' }} disabled={!isChanged} onClick={handleSave}>
            Save
          </Button>
        </S.SaveButtonContainer>
      </Modal.Content>
    </Modal>
  );
}

const mapStateToProps = state => {
  return {
    groupSettings: state.rootReducer.forum.groupSettings,
  };
};

const mapDispatch = dispatch => ({
  updateGroupSetting: bindActionCreators(updateGroupSetting, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapStateToProps, mapDispatch)(ManageNotifications);
