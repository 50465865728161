import React, { useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Mixpanel } from 'utils/mixplanel';
import { changeAutoflowClientQueryParam, getClientList } from 'redux/autoflowInterval/client/actions';
import AddClients from '../AddClients';
import Actions from '../Actions';
import { NewSearchInput } from 'shared/SearchInput';
import { AUTOFLOW_CLIENT_STATUS } from 'constants/commonData';
import * as S from './style';

const STATISTIC_ITEMS = [
  { key: 'active', label: 'Active', value: AUTOFLOW_CLIENT_STATUS.ACTIVE },
  { key: 'paused', label: 'Paused', value: AUTOFLOW_CLIENT_STATUS.PAUSE },
  { key: 'waiting_to_start', label: 'Waiting to start', value: AUTOFLOW_CLIENT_STATUS.WAITING_TO_START },
  { key: 'total', label: 'Total', value: undefined },
];

const COMPLETED_TAB = {
  key: 'completed',
  label: 'Completed',
  value: { is_completed: true, status: undefined },
};

const Header = props => {
  const { clientStatistic, client } = props;
  const {
    query: { search, status, is_completed },
  } = client.toJS();

  const performNewQuery = newQuery => {
    Mixpanel.track('autoflow_interval_client_listing_screen_search_client');
    props.changeAutoflowClientQueryParam(newQuery);
    props.getClientList();
  };

  const onItemClick = item => {
    const query = _.isObject(item.value) ? item.value : { status: item.value, is_completed: undefined };

    if ((is_completed && item.key !== COMPLETED_TAB.key) || status !== item.value) {
      performNewQuery(query);
    }
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      performNewQuery({ page: 1 });
    }
  };

  const tabs = useMemo(() => {
    if (!clientStatistic.completed) {
      return STATISTIC_ITEMS;
    } else {
      const tmp = STATISTIC_ITEMS.slice();
      tmp.splice(STATISTIC_ITEMS.length - 1, 0, COMPLETED_TAB);
      return tmp;
    }
  }, [clientStatistic]);

  return (
    <S.Container className="autoflow-client-list__header">
      <S.LeftContainer>
        <S.SearchInput
          value={search}
          placeholder="Search clients"
          autoFocus={true}
          onChange={event => props.changeAutoflowClientQueryParam({ search: event.target.value })}
          onClearSearch={() => performNewQuery({ search: '', page: 1 })}
          onSearch={() => performNewQuery({ page: 1 })}
          onKeyPress={onKeyPress}
        />
        <AddClients />
        <Actions />
      </S.LeftContainer>
      <S.StatictisContainer>
        {_.map(tabs, item => (
          <div
            key={item.key}
            className={classNames('item', {
              active: is_completed ? item.key === COMPLETED_TAB.key : item.value === status,
            })}
            onClick={() => onItemClick(item)}
          >
            <div className="value">{clientStatistic[item.key]}</div>
            <div className="label">{item.label}</div>
          </div>
        ))}
      </S.StatictisContainer>
    </S.Container>
  );
};

Header.displayName = 'AutoflowClientListHeader';

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { common } = rootReducer.autoflowInterval;

  return {
    client: rootReducer.autoflowInterval.client,
    clientStatistic: common.get('clientStatistic'),
  };
};

const mapDispatchToProps = dispatch => ({
  changeAutoflowClientQueryParam: bindActionCreators(changeAutoflowClientQueryParam, dispatch),
  getClientList: bindActionCreators(getClientList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
