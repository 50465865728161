import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { PlanPackageWrapper } from './style';
import { updateSelectPackage } from 'redux/pricing/actions';
import PackageItem from './PackageItem';
import { getLowestPackagePrice } from '../helper';

function PlanPackage(props) {
  const [isCompare, setIsCompare] = useState(false);
  const currentPlan = props.selectPackage.get('tier');
  const period = props.selectPackage.get('period');

  const onToggleCompare = () => {
    setIsCompare(!isCompare);
  };

  const onSelectPackage = tier => {
    const { planPackageObj, teamData, selectPackage, addOnsPricing, planPricing, isLoading } = props;
    const currentTier = selectPackage.get('tier');
    const currentAddons = selectPackage.get('addOns').toJS();
    if (currentTier === tier || isLoading) {
      return;
    }
    const { pricings } = planPackageObj[tier];

    const pricingsCanChoose = _.chain(pricings)
      .filter(o => o.packageNoClients > teamData.noClients)
      .orderBy(['packageNoClients'], ['asc'])
      .value();

    let packagePrice;
    if (tier === teamData.tier) {
      packagePrice = getLowestPackagePrice(planPricing, tier, teamData.noClients, teamData.packageNoClients);
    } else {
      packagePrice = _.get(pricingsCanChoose, 0, _.last(pricings));
    }

    const updateData = {
      tier,
      packagePrice,
      addOns: currentAddons,
    };

    // if (currentTier === PLANS.starter.key && teamData.trialMode !== TRIAL_MODE.Active) {
    //   updateData.addOns = { automation: true, payment_package: true, resource_collections: true };
    // }

    addOnsPricing.forEach(addon => {
      const isAvailable = addon.planAvailable && addon.planAvailable.length && addon.planAvailable.includes(tier);
      if (!isAvailable) {
        updateData.addOns[addon.code] = false;
      }
    });

    props.updateSelectPackage(updateData);
  };

  return (
    <PlanPackageWrapper {...props}>
      {props.planPricing.map((planPackage, index) => (
        <PackageItem
          index={index}
          isCurrent={planPackage.code === props.teamData.tier}
          currentTier={props.teamData.tier}
          period={period}
          planPackage={planPackage}
          isCompare={isCompare}
          onToggleCompare={onToggleCompare}
          active={currentPlan === planPackage.code}
          key={planPackage.code}
          onSelect={onSelectPackage}
        />
      ))}
    </PlanPackageWrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    planPricing: rootReducer.pricing.get('planPricing').toJS(),
    planPackageObj: rootReducer.pricing.get('planPackage').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    selectPackage: rootReducer.pricing.get('selectPackage'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanPackage);
