import React from 'react';
import styled from 'styled-components';
import { Modal, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import UpgradePathButton from '../UpgradePathButton';
import { CDN_URL } from 'constants/commonData';

const ModalWrapper = styled(Modal)`
  border-radius: 8px;
  max-width: 425px;

  .content {
    border-radius: 8px;
    padding: 30px !important;
  }

  .upgradeModal__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__description {
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }

  .upgradeModal__upgradeBtn {
    margin-top: 30px;
    text-align: center;
  }
`;

class LimitClientsFailbackPopup extends React.Component {
  state = {};

  onClose = () => {
    if (this.props.isSecondModalOpen) {
      this.props.toggleSecondModal(false);
    } else {
      this.props.toggleModal(false);
    }
  };

  handleUpgrade = () => {
    this.onClose();
  };

  render() {
    const des =
      this.props.description ||
      'Your team has reached the max number of clients for your subscribed plan. To add more clients, please archive some clients first or increase your plan size.';
    return (
      <ModalWrapper
        size={'tiny'}
        open={this.props.isSecondModalOpen || this.props.isModalOpen}
        onClose={this.onClose}
        closeOnDimmerClick={false}
        closeIcon={
          <button className="close-button">
            <Image src={`${CDN_URL}/images/close_circle.svg`} />
          </button>
        }
      >
        <Modal.Content>
          <div className="upgradeModal__title">{this.props.title || 'Business is growing!'}</div>
          <div className="upgradeModal__description" dangerouslySetInnerHTML={{ __html: des }} />
          <div className="upgradeModal__upgradeBtn">
            <UpgradePathButton
              onClick={this.handleUpgrade}
              isShowUpgradePathModal={this.state.isShowUpgradePathModal}
              text="Upgrade Plan"
            />
          </div>
        </Modal.Content>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { isModalOpen, isSecondModalOpen } = state;
  return {
    isModalOpen,
    isSecondModalOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitClientsFailbackPopup);
