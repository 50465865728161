import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Image } from 'semantic-ui-react';
import { Mixpanel } from 'utils/mixplanel';
// import components
import ActionTrigger from 'shared/Dropdown/Trigger';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import DisconnectForum from '../DisconnectForum';
import InviteClient from '../InviteClient';
// import actions
import { toggleModal } from 'actions/modal';
// import assets
import CoverDefault from 'assets/images/forum-cover-default.png';
import { ReactComponent as TooltipIcon } from 'assets/icons/hint.svg';
import DisconnectForumIcon from 'assets/images/forum-disconnect-icon.png';
import InviteIcon from 'assets/images/forum-invite-icon.png';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import ConnectedStyles from './style';
import { SharedTooltip } from 'shared/SharedTooltip';

const Connected = ({ forumDetail, numberClients, dispatch, cloudfrontList }) => {
  const forumInfo = forumDetail.toJS();

  const onDisconnectForum = () => {
    Mixpanel.track('autoflow_leaderboard_screen_disconnect_forum');
    dispatch(toggleModal(true, <DisconnectForum />));
  };

  const onInviteClient = () => dispatch(toggleModal(true, <InviteClient numberClients={numberClients} />));

  const renderTriggerIcon = ({ open }) => {
    return (
      <div data-tip data-for="forum-leaderboard-tooltip">
        <ActionTrigger />
        {!open && <SharedTooltip id="forum-leaderboard-tooltip" />}
      </div>
    );
  };

  return (
    <ConnectedStyles>
      <div className="banner">
        <img src={convertS3UrlToCloudFrontUrl(forumInfo.banner, cloudfrontList, true) || CoverDefault} alt="" />
        <div className="info">
          <div className="title">{forumInfo.name}</div>
          {forumInfo.last_activity_at && (
            <div className="last-activities">
              <div className="label">Last activity:&nbsp;</div>
              <div className="time">{moment(forumInfo.last_activity_at).fromNow()}</div>
            </div>
          )}
        </div>
        <div className="action-btn">
          <DropDown triggerIcon={renderTriggerIcon}>
            <Option onClick={onDisconnectForum}>
              <div className="item__content">
                <Image src={DisconnectForumIcon} width={14} />
                Disconnect Forum
              </div>
            </Option>
            <Option onClick={onInviteClient}>
              <div className="item__content">
                <Image src={InviteIcon} width={14} />
                Invite Clients to Forum
              </div>
            </Option>
          </DropDown>
        </div>
      </div>
      <div className="hint">
        <div className="tooltip">
          <TooltipIcon />
        </div>
        <div className="text">
          Invite clients from the Autoflow to the connected Forum and encourage community engagement.
        </div>
      </div>
    </ConnectedStyles>
  );
};

const mapStateToProps = ({ cloudfrontList }) => ({
  cloudfrontList,
});

export default connect(mapStateToProps)(Connected);
