import React from 'react';
import DropdownOptions from 'shared/DropdownOptions';
import { LIMIT_ACCESS } from './../../constants';
import * as S from './style';

const LimitAccess = ({ onSelect, defaultValue = 'forever' }) => {
  const handleScrollDown = () => {
    setTimeout(() => {
      let div = document.getElementById('productDetail');
      if (div) {
        div.scrollTop = div.scrollHeight - div.clientHeight;
      }
    }, 0);
  };
  return (
    <S.Wrapper>
      <DropdownOptions
        width="292px"
        height="42px"
        options={LIMIT_ACCESS}
        selectedValue={defaultValue}
        onSelect={onSelect}
        maxHeightOfList="380px"
        onScrollBottom={handleScrollDown}
      />
    </S.Wrapper>
  );
};

export default LimitAccess;
