import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import Form from 'components/AutoflowTask/Cell/TaskForm/component';
import { saveToLibrary, addRepeatTask } from 'redux/task/task.actionCreators';
import { fetchListTaskLibrary } from 'redux/task-library/actions';
import { submitTask } from 'redux/autoflowInterval/task/actions';

const mapStateToProps = state => {
  const { isModalOpen, rootReducer } = state;
  const {
    settings: { metrics, features },
  } = rootReducer.autoflow;
  const { listTaskLibrary } = rootReducer.taskLibrary;

  return {
    bodymetricTypes: metrics.filter(item => item.selected),
    isModalOpen,
    features,
    totalWeeks: get(rootReducer, 'autoflow.common.workingAutoflow.interval_weeks.length'),
    autoflowId: get(rootReducer, 'autoflow.common.workingAutoflow._id'),
    listTaskLibrary,
    isFormPermission: get(rootReducer, 'permission.form_questionnaire', false),
    isHabitPermission: get(rootReducer, 'permission.autoflow_habit', false),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    fetchListTaskLibrary: bindActionCreators(fetchListTaskLibrary, dispatch),
    addTask: bindActionCreators(submitTask, dispatch),
    addRepeatTask: bindActionCreators(addRepeatTask, dispatch),
    updateTask: bindActionCreators(submitTask, dispatch),
    saveToLibrary: bindActionCreators(saveToLibrary, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
