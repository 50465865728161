import { Types } from './actions';

const INITIAL_STATE = {
  list: [],
  listDefault: [],
  isLoading: false,
  total: 0,
  currentTab: 0, // OnboardingTabs.onboarding_flows
  filters: {
    page: 1,
    per_page: 20,
    text_search: '',
    sort: -1,
    sorter: 'updated_at',
  },
  isAddLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.CHANGE_ONBOARDING_TAB:
      return { ...state, currentTab: payload.tab };

    case Types.CHANGE_ONBOARDING_QUERY:
      const { query } = payload || {};

      return { ...state, filters: { ...state.filters, ...query }, isLoading: true, list: [] };

    case Types.RESET_ONBOARDING_QUERY:
      return Object.assign({}, state, {
        filters: INITIAL_STATE.filters,
      });

    // Integrate API
    case Types.ONBOARDING_FLOW_LIBRARY_CREATE_REQUEST:
    case Types.ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_REQUEST:
    case Types.ONBOARDING_FLOW_LIBRARY_DELETE_REQUEST:
    case Types.ONBOARDING_FLOW_LIBRARY_DUPLICATE_REQUEST:
    case Types.ONBOARDING_FLOW_LIBRARY_ARCHIVE_REQUEST:
    case Types.ONBOARDING_FLOW_LIBRARY_UNARCHIVE_REQUEST:
      return Object.assign({}, state, {
        isAddLoading: true,
      });

    case Types.ONBOARDING_FLOW_LIBRARY_CREATE_SUCCESS:
    case Types.ONBOARDING_FLOW_LIBRARY_CREATE_FAILED:
    case Types.ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_SUCCESS:
    case Types.ONBOARDING_FLOW_LIBRARY_UPDATE_SHARE_STATUS_FAILED:
    case Types.ONBOARDING_FLOW_LIBRARY_DELETE_SUCCESS:
    case Types.ONBOARDING_FLOW_LIBRARY_DELETE_FAILED:
    case Types.ONBOARDING_FLOW_LIBRARY_DUPLICATE_SUCCESS:
    case Types.ONBOARDING_FLOW_LIBRARY_DUPLICATE_FAILED:
    case Types.ONBOARDING_FLOW_LIBRARY_ARCHIVE_SUCCESS:
    case Types.ONBOARDING_FLOW_LIBRARY_ARCHIVE_FAILED:
    case Types.ONBOARDING_FLOW_LIBRARY_UNARCHIVE_SUCCESS:
    case Types.ONBOARDING_FLOW_LIBRARY_UNARCHIVE_FAILED:
      return Object.assign({}, state, {
        isAddLoading: false,
      });

    case Types.ONBOARDING_FLOW_LIBRARY_FETCH_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case Types.ONBOARDING_FLOW_LIBRARY_FETCH_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case Types.ONBOARDING_FLOW_LIBRARY_FETCH_SUCCESS:
      const { data, total, default_onboarding_flows } = payload || {};

      const defaultOnboardingFlows = (default_onboarding_flows || []).map(item => {
        return {
          ...item,
          is_my_default: !(item || {}).is_default_team || undefined,
        };
      });

      return Object.assign({}, state, {
        list: data,
        total,
        isLoading: false,
        listDefault: defaultOnboardingFlows,
      });

    default:
      return state;
  }
};
