import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  &.ui.modal.workout-setting-modal {
    width: 467px;
    border-radius: 8px;
    .workout-setting-modal-header {
      padding: 0px !important;
      margin: 0px !important;
      height: auto;
    }
    .workout-setting-modal-content {
      padding: 4px 30px 40px;
    }
    .workout-setting-modal-actions {
      > button {
        margin: 0px;
        border-radius: 5px;
        padding: 5px 0;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 150%;
        box-shadow: none;
      }
    }
    .action-save-button {
      min-width: 137px;
      height: 30px;
      background: #5158cf;
      color: #fff;
      margin-left: 5px !important;
      &.is-disabled {
        opacity: 1 !important;
        background: #d9d9d9;
        color: #fff;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    .action-cancel-button {
      min-width: 102px;
      height: 30px;
      background: #fff;
      border: 1px solid #d6dae4;
      color: #818da1;
    }
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
  padding: 30px 30px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
`;

export const WrapperWorkoutSetting = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const ContentWorkoutSetting = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #323c47;
`;

export const ActionWorkoutSetting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  input ~ label::before {
    background-color: #979797;
  }
  input:checked ~ label::before {
    background-color: #5158cf;
  }
`;

export const ActionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
