function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const formatRange = text => {
  let myRE = /^d+(\d?-?)?\d?$/;
  if (myRE.test(text)) {
    return text;
  } else {
    return '';
  }
};

export const roundTwoDigit = number => {
  const parsedNumber = parseFloat(number);

  if (isNaN(parsedNumber)) {
    return number;
  }

  if (parsedNumber) {
    if (isInt(parsedNumber)) {
      return parsedNumber;
    } else if (isFloat(parsedNumber)) {
      return Math.ceil(parsedNumber * 100) / 100;
    }
  }

  return parsedNumber;
};

export const roundTwoDigitInt = number => {
  return Math.floor(number * 100) / 100;
};
