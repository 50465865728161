import React from 'react';
import moment from 'moment';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import forEach from 'lodash/forEach';
import get from 'lodash/get';

import { toggleModal } from 'actions/modal';
import { MultiplePasteMessage } from 'shared/Notifications';
import { togglePopup } from 'actions/popup';
import Request, { axiosInstance } from 'configs/request';
import { Types as AutoflowTypes } from 'redux/autoflow/actions';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import { WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { convertWorkoutUnits } from 'helpers/workout';

const DATE_FORMAT = 'MM-DD-YYYY';

export const Types = {
  AUTOFLOW_SUCCESS_GET_ASSIGNMENTS: 'AUTOFLOW_SUCCESS_GET_ASSIGNMENTS',
  AUTOFLOW_FAILED_GET_ASSIGNMENTS: 'AUTOFLOW_FAILED_GET_ASSIGNMENTS',
  AUTOFLOW_SUCCESS_ASSIGN_WORKOUT: 'AUTOFLOW_SUCCESS_ASSIGN_WORKOUT',
  AUTOFLOW_FAILED_ASSIGN_WORKOUT: 'AUTOFLOW_FAILED_ASSIGN_WORKOUT',
  AUTOFLOW_SUCCESS_ASSIGN_PROGRAM: 'AUTOFLOW_SUCCESS_ASSIGN_PROGRAM',
  AUTOFLOW_FAILED_ASSIGN_PROGRAM: 'AUTOFLOW_FAILED_ASSIGN_PROGRAM',
  AUTOFLOW_COPY_WORKOUT: 'AUTOFLOW_COPY_WORKOUT',
  AUTOFLOW_SUCCESS_DELETE_WORKOUT: 'AUTOFLOW_SUCCESS_DELETE_WORKOUT',
  AUTOFLOW_TRAINING_CLEAR_COPY_ITEM: 'AUTOFLOW_TRAINING_CLEAR_COPY_ITEM',
  AUTOFLOW_TRAINING_SUCCESS_ARRANGE_WORKOUT: 'AUTOFLOW_TRAINING_SUCCESS_ARRANGE_WORKOUT',
  AUTOFLOW_TRAINING_SUCCESS_SAVE_WORKOUT_TO_LIB: 'AUTOFLOW_TRAINING_SUCCESS_SAVE_WORKOUT_TO_LIB',
  AUTOFLOW_TRAINING_SUCCESS_ADD_SET: 'AUTOFLOW_TRAINING_SUCCESS_ADD_SET',
  AUTOFLOW_TRAINING_RESET_DATA: 'AUTOFLOW_TRAINING_RESET_DATA',
  AUTOFLOW_TRAINING_UPDATE_EXERCISE_LIBRARY: 'AUTOFLOW_TRAINING_UPDATE_EXERCISE_LIBRARY',
  AUTOFLOW_MOVE_WORKOUT: 'AUTOFLOW_MOVE_WORKOUT',
  AUTOFLOW_MOVE_EXERCISE: 'AUTOFLOW_MOVE_EXERCISE',
  AUTOFLOW_UPDATE_ASSIGNMENTS: 'AUTOFLOW_UPDATE_ASSIGNMENTS',
  AUTOFLOW_VIEW_DETAIL_STATUS: 'AUTOFLOW_VIEW_DETAIL_STATUS',
  AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_REQUEST: 'AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_REQUEST',
  AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_SUCCESS: 'AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_SUCCESS',
  AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_FAIL: 'AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_FAIL',
};

export const resetData = () => ({ type: Types.AUTOFLOW_TRAINING_RESET_DATA });

export const changeViewMode = mode => {
  return dispatch => {
    dispatch({
      type: AutoflowTypes.AUTOGLOW_CHANGE_VIEW_MODE,
      payload: { mode },
    });
    dispatch(getListWorkouts());
  };
};

export const changeStartDate = startDate => {
  return dispatch => {
    dispatch({
      type: AutoflowTypes.AUTOGLOW_CHANGE_START_DATE,
      payload: { startDate },
    });
    dispatch(getListWorkouts());
  };
};

const successAssignWorkout = (day, data) => ({
  type: Types.AUTOFLOW_SUCCESS_ASSIGN_WORKOUT,
  payload: { day, data },
});

export const getListWorkouts = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { viewMode, startDate } = autoflow.common;
    const { workingAutoflow } = autoflow.common;
    const units = currentState.user.preferences;

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_ITEM' });
    }

    const start = moment(startDate, DATE_FORMAT);
    const params = {
      autoflow: workingAutoflow._id,
      start_date: startDate,
      end_date: start.add(7 * viewMode, 'day').format(DATE_FORMAT),
    };

    dispatch({
      type: Types.AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_REQUEST,
    });

    return dispatch(
      Request.get(
        { url: '/api/autoflow/training/list', params },
        true,
        response => {
          let data = response.data.data;
          forEach(data, date => {
            forEach(date.workouts, (workout, index) => {
              const convertedWorkout = convertWorkoutUnits(workout, units);
              date.workouts[index] = convertedWorkout;
            });
          });
          dispatch({ type: Types.AUTOFLOW_SUCCESS_GET_ASSIGNMENTS, payload: { data } });
        },
        () => {
          dispatch({ type: Types.AUTOFLOW_FAILED_GET_ASSIGNMENTS });
        },
      ),
    );
  };
};

export const addWorkout = (date, workout) => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { workingAutoflow } = rootReducer.autoflow.common;
    const params = {
      day: date.toFormat('MM-dd-yyyy'),
      title: workout.title,
      description: workout.description,
      background: workout.background,
      autoflow: get(workingAutoflow, '_id'),
      sections: workout.sections,
      is_generated_by_ai: workout.is_generated_by_ai || false,
      conversion_id: workout.is_generated_by_ai ? workout.conversion_id || null : null,
      is_edited: workout.is_generated_by_ai ? workout.is_edited || false : undefined,
    };

    return dispatch(
      Request.post({ url: '/api/autoflow/training/add', data: params }, true, response => {
        const { data } = response.data;
        dispatch(successAssignWorkout(params.day, data));
      }),
    );
  };
};

export const updateWorkout = workout => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { workingAutoflow } = rootReducer.autoflow.common;
    const data = {
      title: workout.title,
      description: workout.description,
      background: workout.background,
      autoflow: get(workingAutoflow, '_id'),
      sections: workout.sections,
      id: workout._id,
    };

    return dispatch(
      Request.put({ url: '/api/autoflow/training/update', data }, true, () => {
        dispatch(getListWorkouts());
      }),
    );
  };
};

export const assignWorkout = params => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { workingAutoflow } = rootReducer.autoflow.common;

    return dispatch(
      Request.post(
        {
          url: '/api/autoflow/training/assign',
          data: { ...params, autoflow: get(workingAutoflow, '_id') },
        },
        true,
        response => {
          const { data } = response.data;
          dispatch(successAssignWorkout(params.day, data));
        },
      ),
    );
  };
};

export const assignProgram = params => {
  return (dispatch, getState) => {
    const { rootReducer } = getState();
    const { workingAutoflow } = rootReducer.autoflow.common;

    return dispatch(
      Request.post(
        {
          url: '/api/autoflow-exact-date-training/assign-program',
          data: { ...params, autoflow_id: get(workingAutoflow, '_id') },
        },
        true,
        () => {
          dispatch(getListWorkouts());
        },
        () => {
          dispatch({ type: Types.AUTOFLOW_FAILED_ASSIGN_PROGRAM });
        },
      ),
    );
  };
};

export const copyWorkout = data => {
  toast(<MultiplePasteMessage />);
  return { type: Types.AUTOFLOW_COPY_WORKOUT, payload: { data } };
};

export const viewWorkoutDetail = workoutId => {
  return dispatch => {
    dispatch(updateViewDetailStatus(true));
    return dispatch(
      Request.get(
        { url: `/api/autoflow/training/detail?id=${workoutId}`, method: 'get' },
        true,
        (response, { dispatch, getState }) => {
          const { data } = response.data;
          const currentState = getState();
          const { workingAutoflow } = currentState.rootReducer.autoflow.common;

          dispatch(
            toggleModal(
              true,
              <WorkoutDetailModal
                pdfType="autoflow_workout"
                key="update-autoflow-workout"
                type={WORKOUT_BUILDER_TYPES.IN_LIBRARY}
                workingWorkout={data}
                fetchWorkoutsInRange={fetchAutoflowWorkoutsInRange}
                onSave={async workout => {
                  try {
                    const response = await dispatch(updateWorkout(workout));
                    return response.data.data;
                  } catch (error) {
                    return Promise.resolve(null);
                  }
                }}
                onClose={() => {
                  if (workingAutoflow) {
                    dispatch(push(`/home/autoflow/${workingAutoflow._id}/training/calendar`));
                  } else {
                    dispatch(push('/home/autoflow'));
                  }
                  dispatch(updateViewDetailStatus(false));
                }}
                saveToLibrary={workout =>
                  dispatch(saveWorkoutToLibrary({ id: workout._id, autoflow: workingAutoflow._id }))
                }
                onDelete={workout => {
                  dispatch(deleteWorkout(workout));
                }}
              />,
            ),
          );
        },
      ),
    );
  };
};

export const updateViewDetailStatus = status => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_VIEW_DETAIL_STATUS, payload: { status } });
  };
};

export const deleteWorkout = workout => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { workingAutoflow } = autoflow.common;

    return dispatch(
      Request.delete(
        { url: `/api/autoflow/training/delete?autoflow=${workingAutoflow._id}&id=${workout._id}` },
        true,
        () => {
          dispatch({ type: Types.AUTOFLOW_SUCCESS_DELETE_WORKOUT, payload: { workout } });
        },
      ),
    );
  };
};

export const resetCopyItem = () => ({ type: Types.AUTOFLOW_TRAINING_CLEAR_COPY_ITEM });

export const pasteWorkout = (params, multiPaste) => {
  return dispatch => {
    if (!multiPaste) {
      dispatch(resetCopyItem());
    }

    return dispatch(
      Request.post({ url: '/api/autoflow/training/copy', data: params }, true, response => {
        const { data } = response.data;
        dispatch(successAssignWorkout(params.day, data));
      }),
    );
  };
};

export const arrangeWorkout = params => {
  return Request.put(
    { url: '/api/autoflow/training/arrange_workout', data: params },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;

      dispatch({ type: Types.AUTOFLOW_TRAINING_SUCCESS_ARRANGE_WORKOUT, payload: { day: params.day, data } });
    },
    dispatch => dispatch({ type: 'AUTOFLOW_TRAINING_FAILED_ARRANGE_WORKOUT' }),
  );
};

export const arrangeExerciseInsideWorkout = params => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_MOVE_EXERCISE,
      payload: {
        id: params.id,
        workoutDestinationId: params.id,
        sectionId: params.sectionId,
        newIndex: params.newIndex,
        oldIndex: params.oldIndex,
        oldDay: params.oldDay,
        newDay: params.newDay,
      },
    });
    return dispatch(
      Request.put(
        { url: '/api/autoflow/training/arrange_set', data: params },
        true,
        () => {},
        (e, { dispatch }) => {
          dispatch({
            type: Types.AUTOFLOW_MOVE_EXERCISE,
            payload: {
              id: params.id,
              workoutDestinationId: params.id,
              sectionId: params.sectionId,
              newIndex: params.oldIndex,
              oldIndex: params.newIndex,
              oldDay: params.oldDay,
              newDay: params.newDay,
            },
          });
          dispatch({ type: 'AUTOFLOW_TRAINING_FAILED_ARRANGE_EXERCISE' });
        },
      ),
    );
  };
};

export const moveExerciseOutsideWorkout = data => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_MOVE_EXERCISE, payload: data });
    return dispatch(
      Request.put(
        { url: '/api/autoflow/training/move_set', data },
        true,
        r => {
          dispatch({ type: Types.AUTOFLOW_UPDATE_ASSIGNMENTS, payload: r.data.data });
        },
        e => {
          dispatch({
            type: Types.AUTOFLOW_MOVE_EXERCISE,
            payload: {
              id: data.workoutDestinationId,
              workoutDestinationId: data.id,
              sectionId: data.sectionId,
              newIndex: data.oldIndex,
              oldIndex: data.newIndex,
              oldDay: data.newDay,
              newDay: data.oldDay,
            },
          });
          dispatch({ type: 'AUTOFLOW_TRAINING_FAILED_MOVE_EXERCISE' });
        },
      ),
    );
  };
};

export const saveWorkoutToLibrary = data => {
  return Request.post({ url: '/api/autoflow/training/save_to_library', data }, true, () => {
    toast.success('Workout saved to library!', { autoClose: 3000 });
  });
};

export const addSetToWorkout = data => {
  return Request.post({ url: '/api/autoflow/training/add_set', data }, true, (r, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_TRAINING_SUCCESS_ADD_SET });
    dispatch(getListWorkouts());
    dispatch(togglePopup(null));
  });
};

export const updateExerciseLibrary = data => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { user, rootReducer } = currentState;
    const { exercise } = rootReducer;
    const { categories, fields } = exercise;
    const unit = user.preferences;

    dispatch({
      type: Types.AUTOFLOW_TRAINING_UPDATE_EXERCISE_LIBRARY,
      payload: { data: { ...data, categories, fields, unit } },
    });
  };
};

export const moveWorkout = data => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_MOVE_WORKOUT,
      payload: { data },
    });
    const params = {
      autoflow: data.autoflow,
      newIndex: data.newIndex,
      oldDay: data.oldDay,
      newDay: data.newDay,
      id: data.assignmentId,
    };
    return dispatch(
      Request.put(
        { url: '/api/autoflow/training/move-workout', data: params },
        true,
        () => {},
        () =>
          dispatch({
            type: Types.AUTOFLOW_MOVE_WORKOUT,
            payload: {
              data: {
                ...data,
                fromIndex: data.newIndex,
                newIndex: data.fromIndex,
                oldDay: data.newDay,
                newDay: data.oldDay,
              },
            },
          }),
      ),
    );
  };
};

export const fetchAutoflowWorkoutsInRange = data => {
  return (dispatch, getState) => {
    const state = getState();
    const { workingAutoflow } = state.rootReducer.autoflow.common;
    const params = { ...data, autoflow: workingAutoflow._id };

    dispatch({
      type: Types.AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_REQUEST,
    });

    return axiosInstance
      .get('/api/autoflow/training/list', { params })
      .then(response => {
        dispatch({
          type: Types.AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_SUCCESS,
        });
        const workoutData = {};
        const res = get(response, 'data.data', []);
        res.forEach(itm => {
          const day = moment(get(itm, 'day'), 'MM-DD-YYYY').format('YYYY-MM-DD');
          workoutData[day] = { day, workouts: get(itm, 'workouts') };
        });
        return workoutData;
      })
      .catch(() => {
        dispatch({
          type: Types.AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_FAIL,
        });
      });
  };
};
