import styled from 'styled-components';

export const RadioPurchaseOptionWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Open Sans';
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6px;
`;

export const WaitListPreviewWrapper = styled(RadioPurchaseOptionWrapper)`
  gap: 8px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${props => props.gap || '11px'};
  .checkbox {
    label {
      font-family: 'Open Sans' !important;
      color: #202353 !important;
      font-size: 13px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      padding-left: 32px !important;
    }
  }
  .checked {
    label {
      font-weight: 600 !important;
    }
  }
`;

export const PurchasePreviewValue = styled.span`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-weight: ${props => props.fontWeight || 400};
  color: ${props => props.color || '#4a4d74'};
`;

export const PurchaseDateTimeWrapper = styled.div`
  margin-left: 32px;
  margin-top: 8px;

  .date-time-wrapper {
    display: flex;
    gap: 8px;
  }

  .error-message {
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 18px;
    color: #ea314a;
    margin-top: 8px;
  }
`;

export const TimezoneWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .timezone-label {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: #a6a7ba;
  }

  &.timezone-error {
    .newexercise-dropdown {
      .exl__control {
        border: 1px solid #ea314a !important;
      }
    }
  }
  .newexercise-dropdown {
    width: 296px !important;

    .exl__menu {
      top: unset;
      height: 250px;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 4px 0px rgba(38, 38, 38, 0.2);
      .exl__menu-list {
        max-height: 250px !important;
        margin-bottom: 0 !important;
        ::-webkit-scrollbar {
          width: 6px !important;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 24px !important;
          background: #d9d9d9 !important;
        }
        .exl__option {
          height: 41px !important;
          background: #ffffff !important;
          font-family: 'Open Sans' !important;
          font-size: 13px !important;
          line-height: 18px !important;
          display: flex !important;
          align-items: center !important;
          cursor: pointer !important;
        }
        .exl__option--is-focused,
        .exl__option--is-selected {
          font-weight: 600 !important;
          color: #5158cf !important;
          background: #f4f4fc !important;
        }
      }
    }

    .exl__control {
      cursor: pointer !important;
      background-color: #ffffff;
      padding: 0 17px 0 12px !important;
      border: 1px solid #d6dae4 !important;
      min-height: 36px !important;
      border-radius: 4px !important;
      .exl__value-container {
        background-color: #ffffff;
        padding: 0px !important;
        .exl__single-value {
          margin-left: 0 !important;
          margin-right: 0 !important;
          font-family: 'Open Sans';
          font-size: 13px !important;
          font-weight: 400 !important;
          line-height: 18px !important;
          color: #262626 !important;
        }
        .exl__input > input {
          height: 28px !important;
        }
      }
      .exl__indicators {
        .exl__indicator-separator {
          display: none !important;
        }
        .exl__dropdown-indicator {
          padding: 0 !important;
        }
        .exl__indicator {
          background-color: #ffffff;
        }
      }
      :hover {
        background-color: #f5f5f5 !important;
        .exl__value-container {
          background-color: #f5f5f5 !important;
        }
      }
    }

    .exl__control--menu-is-open {
      border: 1px solid #5158cf !important;

      .exl__indicators {
        transform: rotate(180deg);
      }
    }
  }
`;
