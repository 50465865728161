import React from 'react';
import { Loader } from 'semantic-ui-react';
import ContentLoader from 'react-content-loader';

import * as Layout from 'shared/LibraryLayout';

import * as S from '../styles';

const LoadingProgramSkeleton = () => {
  const renderRowSkeleton = (_, idx) => (
    <S.CustomRow key={`row-skeleton-${idx}`}>
      <Layout.TableCell verticalAlignTextTop>
        <ContentLoader viewBox="0 0 349 60" width={349} height={60} animate={false}>
          <rect x="0" y="0" rx="5" ry="5" width="74" height="60" />
          <rect x="89" y="16" rx="5" ry="5" width="173" height="10" />
          <rect x="89" y="34" rx="5" ry="5" width="260" height="10" />
        </ContentLoader>
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        <ContentLoader viewBox="0 0 64 16" width={64} height={16} animate={false}>
          <rect x="0" y="0" rx="5" ry="5" width="64" height="16" />
        </ContentLoader>
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        <ContentLoader viewBox="0 0 64 16" width={64} height={16} animate={false}>
          <rect x="0" y="0" rx="5" ry="5" width="64" height="16" />
        </ContentLoader>
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        <ContentLoader viewBox="0 0 64 16" width={64} height={16} animate={false}>
          <rect x="0" y="0" rx="5" ry="5" width="64" height="16" />
        </ContentLoader>
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        <ContentLoader viewBox="0 0 64 16" width={64} height={16} animate={false}>
          <rect x="0" y="0" rx="5" ry="5" width="64" height="16" />
        </ContentLoader>
      </Layout.TableCell>
      <Layout.TableCell alignCenter>
        <ContentLoader viewBox="0 0 64 16" width={64} height={16} animate={false}>
          <rect x="0" y="0" rx="5" ry="5" width="64" height="16" />
        </ContentLoader>
      </Layout.TableCell>
    </S.CustomRow>
  );

  return <>{Array(20).fill(null).map(renderRowSkeleton)}</>;
};

export default LoadingProgramSkeleton;
