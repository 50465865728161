import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const GlobalSearchClientsWrapper = styled.div`
  position: relative;
`;

export const GlobalSearchIcon = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-position: center;
  background-size: cover;
  background-image: url(${CDN_URL}/images/search_new.svg);

  :hover {
    background-image: url(${CDN_URL}/images/search_new_purple.svg);
  }
`;
