import get from 'lodash/get';

import { getAllSegments } from 'redux/segment/actions';
import Request from 'configs/request';

export const Types = {
  GET_ALL_GROUPS: 'GET_ALL_GROUPS',
  SUCCESS_GET_ALL_GROUPS: 'SUCCESS_GET_ALL_GROUPS',
  FAILED_GET_ALL_GROUPS: 'FAILED_GET_ALL_GROUPS',
  GET_GROUPS: 'GET_GROUPS',
  SUCCESS_GET_GROUPS: 'SUCCESS_GET_GROUPS',
  FAILED_GET_GROUPS: 'FAILED_GET_GROUPS',
  ADD_NEW_GROUP: 'ADD_NEW_GROUP',
  SUCCESS_ADD_NEW_GROUP: 'SUCCESS_ADD_NEW_GROUP',
  FAILED_ADD_NEW_GROUP: 'FAILED_ADD_NEW_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  SUCCESS_DELETE_GROUP: 'SUCCESS_DELETE_GROUP',
  FAILED_DELETE_GROUP: 'FAILED_DELETE_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  SUCCESS_UPDATE_GROUP: 'SUCCESS_UPDATE_GROUP',
  FAILED_UPDATE_GROUP: 'FAILED_UPDATE_GROUP',
  ASSIGN_CLIENTS: 'ASSIGN_CLIENTS',
  SUCCESS_ASSIGN_CLIENTS: 'SUCCESS_ASSIGN_CLIENTS',
  FAILED_ASSIGN_CLIENTS: 'FAILED_ASSIGN_CLIENTS',
  SEARCH_GROUPS: 'SEARCH_GROUPS',
  SUCCESS_SEARCH_GROUPS: 'SUCCESS_SEARCH_GROUPS',
  FAILED_SEARCH_GROUPS: 'FAILED_SEARCH_GROUPS',
};

export const getGroups = params => {
  return dispatch => {
    dispatch({ type: Types.GET_GROUPS });

    return dispatch(
      Request.get(
        { url: '/api/group/list', params },
        false,
        (response, { dispatch }) => {
          dispatch({ type: Types.SUCCESS_GET_GROUPS });
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.FAILED_GET_GROUPS, error });
        },
      ),
    );
  };
};

const getAllGroupSucess = list => ({ type: Types.SUCCESS_GET_ALL_GROUPS, payload: { list } });

export const getAllGroups = () => {
  return dispatch => {
    dispatch({ type: Types.GET_ALL_GROUPS });

    return dispatch(
      Request.get(
        { url: '/api/group/all' },
        false,
        response => {
          if (response.data && response.data.data) {
            dispatch(getAllGroupSucess(response.data.data));
          }
        },
        error => {
          dispatch({ type: Types.FAILED_GET_ALL_GROUPS, error });
        },
      ),
    );
  };
};

export const autocomplete = params => {
  return dispatch => {
    dispatch({ type: Types.SEARCH_GROUPS });

    return dispatch(
      Request.get(
        { url: '/api/group/search', params },
        false,
        response => dispatch({ type: Types.SUCCESS_SEARCH_GROUPS }),
        error => dispatch({ type: Types.FAILED_SEARCH_GROUPS, error }),
      ),
    );
  };
};

const createGroupSuccess = (group, clientIds) => ({ type: Types.SUCCESS_ADD_NEW_GROUP, payload: { group, clientIds } });

export const createGroup = data => {
  return dispatch => {
    dispatch({ type: Types.ADD_NEW_GROUP });

    return dispatch(
      Request.post(
        { url: '/api/group/add', data },
        true,
        response => {
          if (response.data && response.data.data) {
            const clientIds = get(data, 'clients', []);
            dispatch(createGroupSuccess(response.data.data, clientIds));
          }
        },
        error => dispatch({ type: Types.FAILED_ADD_NEW_GROUP, error }),
      ),
    );
  };
};

export const assignClients = data => {
  return dispatch => {
    dispatch({ type: Types.ASSIGN_CLIENTS });

    return dispatch(
      Request.put(
        { url: '/api/group/assign', data },
        true,
        response => {
          const { groups, unchecked_groups } = response.data.data;
          dispatch({
            type: Types.SUCCESS_ASSIGN_CLIENTS,
            payload: { groups: groups.concat(unchecked_groups) },
          });
        },
        error => dispatch({ type: Types.FAILED_ASSIGN_CLIENTS, error }),
      ),
    );
  };
};

const updateGroupSuccess = group => ({ type: Types.SUCCESS_UPDATE_GROUP, payload: { group } });

export const updateGroup = (groupId, data, callback) => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_GROUP });

    return dispatch(
      Request.put(
        { url: `/api/group/update/${groupId}`, data },
        true,
        response => {
          if (response.data && response.data.data) {
            dispatch(updateGroupSuccess(response.data.data));
          }
        },
        error => {
          dispatch({ type: Types.FAILED_UPDATE_GROUP, error });
          callback && callback();
        },
      ),
    );
  };
};

const deleteGroupSuccess = groupId => ({ type: Types.SUCCESS_DELETE_GROUP, payload: { groupId } });

export const deleteGroup = groupId => {
  return dispatch => {
    dispatch({ type: Types.DELETE_GROUP });

    return dispatch(
      Request.delete(
        { url: `/api/group/delete/${groupId}` },
        true,
        response => {
          dispatch(deleteGroupSuccess(groupId));
          dispatch(getAllSegments());
        },
        error => dispatch({ type: Types.FAILED_DELETE_GROUP, error }),
      ),
    );
  };
};
