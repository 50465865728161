import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { convertUnit, mongoObjectId } from 'utils/commonFunction';
import { SECTION_FORMAT_KEY, HIDDEN_SECTION } from 'constants/commonData';
import { SECTION_TYPE } from 'components/WorkoutDetailModal/components/MainPanel/components/AddSectionForm/SectionForm';

const UNIQUE_CODES = Object.freeze({
  weight: 'weight',
  orm: 'orm',
  reps: 'reps',
  duration: 'duration',
  rest: 'rest',
});
const UNIT_CODE_NAMES = ['weight', 'length', 'distance'];
const UNIQUE_CODES_PRIORITIZED = [UNIQUE_CODES.orm, UNIQUE_CODES.weight, UNIQUE_CODES.reps, UNIQUE_CODES.duration];

function moveItemToEnd(arr, item) {
  const index = arr.indexOf(item);
  if (index !== -1) {
    arr.splice(index, 1);
    arr.push(item);
  }
  return arr;
}

function isValidTempoFormat(text) {
  // Updated pattern to allow single digit or "X" only in the remaining positions
  const pattern = /^[A-Za-z0-9]-[A-Za-z0-9]-[A-Za-z0-9]-[A-Za-z0-9]$/;
  return pattern.test(text);
}

function getSectionType(sectionItem) {
  const { type, format } = sectionItem || {};
  const newFormat = String(format).toLowerCase();

  if (newFormat !== SECTION_FORMAT_KEY.REGULAR && String(type).toLowerCase() === HIDDEN_SECTION) {
    return SECTION_TYPE.workout;
  }
  return !!SECTION_TYPE[type] ? type : HIDDEN_SECTION;
}

const getFieldSpecial = fields => {
  let weightFieldID, timeFieldID, restFieldID;
  (fields || []).forEach(fItem => {
    if (fItem.unique_code === UNIQUE_CODES.weight) {
      weightFieldID = fItem._id;
    }
    if (fItem.unique_code === UNIQUE_CODES.duration) {
      timeFieldID = fItem._id;
    }
    if (fItem.unique_code === UNIQUE_CODES.rest) {
      restFieldID = fItem._id;
    }
    if (fItem.unique_code === UNIQUE_CODES.rest) {
      restFieldID = fItem._id;
    }
  });

  return { weightFieldID, timeFieldID, restFieldID };
};

const convertSections = (sections, fields, userUnit) => {
  const { unitCategoriesByCoach, userSelectedUnit } = userUnit;
  const selectedSectionIds = [];
  const exercises = [];
  const TrainingSet = {};
  const Superset = {};
  const Exercise = {};
  const Section = {};
  let error = false;
  let sectionIndex = 1;

  const { weightFieldID, restFieldID, timeFieldID } = getFieldSpecial(fields);

  try {
    sections.forEach(sectionItem => {
      const sessionId = mongoObjectId();
      const sectionType = getSectionType(sectionItem).toLowerCase();

      const sectionFormat = SECTION_FORMAT_KEY[String(sectionItem.format || '').toUpperCase()]
        ? sectionItem.format
        : SECTION_FORMAT_KEY.REGULAR;
      const isFreeStyle = sectionFormat === SECTION_FORMAT_KEY.FREESTYLE;
      const isIntervalSection = sectionFormat === SECTION_FORMAT_KEY.INTERVAL;
      const exerciseListName = isFreeStyle ? 'exercise_references' : 'exercises';
      const isRegularHidden = sectionFormat === SECTION_FORMAT_KEY.REGULAR && sectionType === HIDDEN_SECTION;

      // create session model
      const selectedSectionId = [sessionId, sectionType, sectionItem.format].join('-');
      selectedSectionIds.push(selectedSectionId);
      Section[selectedSectionId] = {
        _id: sessionId,
        title: isRegularHidden ? '' : sectionItem.title || `Section ${sectionIndex}`,
        type: sectionType,
        format: sectionFormat,
        time: sectionItem.time,
        round: sectionItem.round,
        note: sectionItem.instruction || '',
        [exerciseListName]: [],
      };

      if (!sectionItem.title && sectionType !== HIDDEN_SECTION) {
        sectionIndex += 1;
      }

      (sectionItem.exercises || []).forEach(exerciseItem => {
        const exerciseItemFiltered = exerciseItem.filter(item => !isEmpty(item.title));
        if (exerciseItemFiltered.length === 0) return;

        const exerciseId = mongoObjectId();

        // create exercise model
        Exercise[exerciseId] = {
          _id: exerciseId,
          supersets: [],
        };
        // add exercise into section
        if (!isFreeStyle) {
          Section[selectedSectionId].exercises.push(exerciseId);
        }

        (exerciseItemFiltered || []).forEach(supersetItem => {
          const supersetId = mongoObjectId();
          const ex = supersetItem.exercise_instance || {};
          const isExisting = !!ex._id;
          const newExerciseId = ex._id || mongoObjectId();
          const alternativesInstance = supersetItem.alternatives_instance || [];

          let exerciseInstance = {
            ...(ex || {}),
            _id: newExerciseId,
            fields: isIntervalSection ? [timeFieldID] : [],
            fieldsInSystem: ex.fields || [],
            author: ex.author || {},
            title: ex.title || supersetItem.title,
            label: supersetItem.title,
            is_existing: isExisting,
            alternatives: ex.alternatives || [],
          };

          if (isFreeStyle) {
            Section[selectedSectionId].exercise_references.push(exerciseInstance);
          } else {
            // create superset model
            Superset[supersetId] = {
              _id: supersetId,
              alternatives: alternativesInstance,
              each_side: supersetItem.each_side || false,
              exercise_instance: exerciseInstance,
              type: supersetItem.type,
              tempo: isValidTempoFormat(supersetItem.tempo) ? supersetItem.tempo : '',
              training_sets: [],
            };

            // add exercise into section
            Exercise[exerciseId].supersets.push(supersetId);

            let trainingSets = get(supersetItem, 'training_sets', []) || [];

            if (trainingSets.length === 0) {
              trainingSets.push({ type_rest: '' });
            }

            trainingSets = trainingSets.map(item => {
              if (item.type_rest === undefined || item.type_rest === null) {
                return { ...item, type_rest: '' };
              }
              return item;
            });

            trainingSets.forEach(trainingItem => {
              const trainingId = mongoObjectId();

              const obj = Object.keys(trainingItem).reduce((newObj, key) => {
                const [unitCodeName, ...remainingKeys] = key.split('_');
                const fieldName = remainingKeys.join('_');
                const field = fields.find(fItem => fItem.unique_code === fieldName);
                if (field) {
                  const currentFields = Superset[supersetId].exercise_instance.fields;
                  if (!currentFields.includes(field._id)) currentFields.push(field._id);

                  // The rest field always comes last in the set
                  Superset[supersetId].exercise_instance.fields = moveItemToEnd(currentFields, restFieldID);

                  Superset[supersetId].exercise_instance.fields = [
                    ...new Set(Superset[supersetId].exercise_instance.fields),
                  ];
                }
                let value = fieldName === UNIQUE_CODES.rest && isNaN(trainingItem[key]) ? '0' : trainingItem[key];

                if (key.includes('unit') && UNIT_CODE_NAMES.includes(unitCodeName)) {
                  const unitCode = trainingItem[key];
                  const valueOfUnit = String(trainingItem[`type_${unitCodeName}`]).replace(/,/g, '.');

                  value = convertUnit(+valueOfUnit, unitCategoriesByCoach[unitCode], userSelectedUnit[unitCodeName]);
                }
                newObj[fieldName === 'unit' ? unitCodeName : fieldName] = { value: String(value).replace(/,/g, '.') };

                return newObj;
              }, {});

              const trainingSetItem = {
                _id: trainingId,
                ...obj,
              };

              // set default values for training set item
              if (sectionFormat === SECTION_FORMAT_KEY.INTERVAL) {
                const restValue = get(trainingSetItem, 'rest.value') || 10;
                const timeValue = get(trainingSetItem, 'duration.value') || 20;
                trainingSetItem.rest = { value: restValue };
                trainingSetItem.duration = { value: timeValue };
              }

              // push field weight ID into field when has orm without weight
              if (!!trainingSetItem.orm && !trainingSetItem.weight && weightFieldID) {
                Superset[supersetId].exercise_instance.fields.unshift(weightFieldID);
              }

              // add training set into super set
              Superset[supersetId].training_sets.push(trainingId);
              Superset[supersetId].exercise = Superset[supersetId].exercise_instance;

              // save training set model
              TrainingSet[trainingId] = trainingSetItem;
            });
            exercises.push(Superset[supersetId].exercise_instance);
          }
        });
      });
    });
  } catch (err) {
    console.error(err);
    error = true;
  }

  return {
    entities: {
      TrainingSet,
      Superset,
      Exercise,
      Section,
    },
    exercises,
    selectedSectionIds,
    error,
  };
};

const preHandleSections = (sections = []) => {
  const SpecialSections = [SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED];
  const newSections = [];

  for (const section of sections) {
    const newSectionItem = { ...section };

    if (SpecialSections.includes(section.format)) {
      newSectionItem.exercises = [];

      for (const exercises of section.exercises || []) {
        // make sure each exercise just has ONE exercise and ONLY has ONE set
        (exercises || []).forEach(item => {
          const firstSet = (item.training_sets || []).shift();
          const newExercise = { ...item, training_sets: firstSet ? [firstSet] : [] };
          newSectionItem.exercises.push([newExercise]);
        });
      }
    }
    newSections.push(newSectionItem);
  }
  return newSections;
};

const getUniqueCodes = fields => {
  const listUniqueCodeSorted = sortBy(fields || [], 'unique_code');
  const uniqueCodesPrioritized = UNIQUE_CODES_PRIORITIZED.map(code => {
    return listUniqueCodeSorted.find(item => item.unique_code === code);
  });
  const remainingUniqueCodesOrdered = listUniqueCodeSorted.filter(
    item => !UNIQUE_CODES_PRIORITIZED.includes(item.unique_code),
  );
  return { uniqueCodesPrioritized, remainingUniqueCodesOrdered };
};

const mapFields = (result, listItem, ids) => {
  const newResult = [...result];
  for (const fieldItem of listItem) {
    const field = ids.find(id => fieldItem._id === id);
    if (field) {
      newResult.push(fieldItem._id);
    }
  }
  return newResult;
};

export const orderNewFieldsByOriginFields = (originFields, newFields, restId) => {
  const finalFields = [...newFields];
  const orderMap = new Map();

  originFields.forEach((item, index) => {
    orderMap.set(item, index);
  });

  finalFields.sort((a, b) => {
    const indexA = orderMap.has(a) ? orderMap.get(a) : Number.MAX_SAFE_INTEGER;
    const indexB = orderMap.has(b) ? orderMap.get(b) : Number.MAX_SAFE_INTEGER;
    return indexA - indexB;
  });

  return moveItemToEnd(finalFields, restId);
};

const handleFieldOfSuperset = (superset, { uniqueCodesPrioritized, remainingUniqueCodesOrdered, restId }) => {
  const { exercise_instance: { fields: exerciseFields, fieldsInSystem } = { fields: [] } } = superset || {};
  const fieldsByExercise = fieldsInSystem || [];
  let finalFields = [];

  finalFields = mapFields(finalFields, uniqueCodesPrioritized, exerciseFields);
  finalFields = mapFields(finalFields, remainingUniqueCodesOrdered, exerciseFields);

  if (finalFields.length < 4) {
    fieldsByExercise.forEach(id => {
      if (!finalFields.includes(id)) {
        finalFields.push(id);
      }
    });
  }
  finalFields = moveItemToEnd(finalFields, restId);
  const fieldIds = [...finalFields].slice(0, 4);

  if (!fieldIds.includes(restId)) {
    if (fieldIds.length === 4) {
      fieldIds[3] = restId;
    } else if (fieldIds.length < 4) {
      fieldIds.push(restId);
    }
  }

  return orderNewFieldsByOriginFields(fieldsInSystem, fieldIds, restId);
};

export const convertDataToOurModel = async (item, fields, userUnit) => {
  const { uniqueCodesPrioritized, remainingUniqueCodesOrdered } = getUniqueCodes(fields);
  const sectionData = preHandleSections(item.sections || []);
  const { userPreferences } = userUnit || {};
  const newData = convertSections(sectionData, fields, userUnit);
  const { selectedSectionIds, exercises, entities, error } = newData;
  const {
    Exercise: ExerciseEntities,
    Section: SectionEntities,
    Superset: SupersetEntities,
    TrainingSet: TrainingSetEntities,
  } = entities;
  let newErrorConvert = false;

  const newExercises = [...exercises];

  try {
    // UP-37837: make sure each exercise in suppersets have same training sets
    const exerciseItems = Object.values(ExerciseEntities);

    for (const ex of exerciseItems) {
      if (ex.supersets.length > 1) {
        // find max training sets
        let trainingSetsMax = SupersetEntities[ex.supersets[0]].training_sets.length;

        for (const sId of ex.supersets) {
          if (trainingSetsMax < SupersetEntities[sId].training_sets.length) {
            trainingSetsMax = SupersetEntities[sId].training_sets.length;
          }
        }

        // update training sets by max
        for (const sId of ex.supersets) {
          if (trainingSetsMax > SupersetEntities[sId].training_sets.length) {
            const trainingSetFirst = TrainingSetEntities[SupersetEntities[sId].training_sets[0]] || {};

            while (SupersetEntities[sId].training_sets.length < trainingSetsMax) {
              const newTrainingSetId = mongoObjectId();
              SupersetEntities[sId].training_sets.push(newTrainingSetId);
              TrainingSetEntities[newTrainingSetId] = {
                ...trainingSetFirst,
                _id: newTrainingSetId,
              };
            }
          }
        }
      }
    }
    // END UP-37837

    // UP-37800: check fields in superset if exist ORM field
    const supersetIds = Object.keys(SupersetEntities);
    const { _id: restId } = fields.find(item => item.unique_code === UNIQUE_CODES.rest) || {};
    for (const sId of supersetIds) {
      const newFields = handleFieldOfSuperset(SupersetEntities[sId], {
        uniqueCodesPrioritized,
        remainingUniqueCodesOrdered,
        restId,
      });

      // UP-38735: values of training set should be matched with fields
      const { training_sets: trainingSetIds } = SupersetEntities[sId] || { training_sets: [] };
      (trainingSetIds || []).forEach(tId => {
        newFields.forEach(fId => {
          const field = fields.find(item => item._id === fId);

          if (field) {
            const currentData = JSON.parse(JSON.stringify(TrainingSetEntities[tId] || {}));
            const preferenceUnit = (userPreferences || {})[field.unique_code] || {};

            TrainingSetEntities[tId] = {
              ...currentData,
              [field.unique_code]: {
                ...currentData[field.unique_code],
                value: (currentData[field.unique_code] || {}).value || '',
                ...(preferenceUnit._id ? { unit: preferenceUnit._id } : {}),
              },
            };
          }
        });
      });
      // END UP-38735
      SupersetEntities[sId].exercise_instance.fields = newFields;
    }
    // END UP-37800
  } catch (newError) {
    console.error(newError);
    newErrorConvert = true;
  }

  return {
    title: item.title,
    description: item.description,
    entities: {
      TrainingSet: TrainingSetEntities,
      Exercise: ExerciseEntities,
      Section: SectionEntities,
      Superset: SupersetEntities,
    },
    error: error || newErrorConvert,
    exercises: newExercises,
    selectedSectionIds,
  };
};

export const checkDisabledFromAI = (workoutModel, selectedWorkout) => {
  let isDisabled = false;
  try {
    const { sections = [] } = selectedWorkout.toJS() || {};
    const { Exercise = {}, Section = {}, Superset = {} } = workoutModel.toJS() || {};

    for (const sectionId of sections) {
      const { exercises: exerciseIds = [], format, exercise_references } = Section[sectionId] || {};

      if (format === SECTION_FORMAT_KEY.FREESTYLE) {
        const haveNotExist = (exercise_references || []).some(item => item.is_existing === false);
        if (haveNotExist) {
          isDisabled = true;
        }
      } else {
        for (const exId of exerciseIds) {
          const { supersets: supersetIds = [] } = Exercise[exId] || {};
          for (const supId of supersetIds) {
            const isExist = get(Superset[supId], 'exercise_instance.is_existing');
            if (isExist === false) {
              isDisabled = true;
              break;
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return isDisabled;
};
