import React from 'react';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;

  .termPolicy__text {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #202353;
    max-width: 260px;
    margin-bottom: 15px;

    a {
      color: #202353;
      font-weight: 600;
      /* text-decoration: underline; */
    }
  }

  .termPolicy__stripe {
    
  }
`;

export default function(props) {
  return (
    <Wrapper>
      <div className="termPolicy__text">
        <span>By continuing, you’re agreeing to the&nbsp;</span>
        <div>
          <a href="https://everfit.io/tos/" target="_blank">Everfit Terms of Service</a>&nbsp;
          and&nbsp;
          <a href="https://everfit.io/privacy/" target="_blank">Privacy Policy</a>
          .
        </div>
      </div>
      <img className="termPolicy__stripe" src={`${CDN_URL}/images/power_by_stripe.svg`} alt=""></img>
    </Wrapper>
  )
}