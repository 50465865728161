import React from 'react';
import CustomPopup from 'components/Package/components/CustomPopup';
import {
  APPROVED_OR_REJECTED_PACKAGE_UNPUBLISH_ERROR,
  UNDO_EXPIRED_TIME,
  UNDO_REJECTED_ERROR,
  STATUS_IS_NOT_NO_RESPONSE,
} from 'constants/commonData';
import { ReactComponent as WarningIcon } from 'assets/icons/popup-warning-icon.svg';

const renderActionErrorPopup = ({ errorCode, toggleModal }) => {
  let popup;
  toggleModal(false);
  switch (errorCode) {
    case STATUS_IS_NOT_NO_RESPONSE:
      popup = (
        <CustomPopup
          isShowCancelButton={false}
          title="Oops!"
          icon={<WarningIcon />}
          onToggle={() => toggleModal(false)}
          content={`You have sent the offer to this client.`}
        />
      );
      break;
    case UNDO_REJECTED_ERROR:
      popup = (
        <CustomPopup
          isShowCancelButton={false}
          title="Oops!"
          icon={<WarningIcon />}
          onToggle={() => toggleModal(false)}
          content={`The request has been reverted to Waiting.`}
        />
      );
      break;
    case UNDO_EXPIRED_TIME:
      popup = (
        <CustomPopup
          isShowCancelButton={false}
          title="Oops!"
          icon={<WarningIcon />}
          onToggle={() => toggleModal(false)}
          content={`Sorry, you can only revert status within ${process.env.REACT_APP_UNDO_DURATION_TIME} minutes.`}
        />
      );
      break;
    case APPROVED_OR_REJECTED_PACKAGE_UNPUBLISH_ERROR:
      popup = (
        <CustomPopup
          isShowCancelButton={false}
          title="Warning"
          icon={<WarningIcon />}
          onToggle={() => toggleModal(false)}
          content={`Your package is unpublished. Please publish your package to process the requests.`}
        />
      );
      break;
    default:
      break;
  }

  if (!!popup) {
    toggleModal(true, popup);
  }
};

export { renderActionErrorPopup };
