import styled from 'styled-components';
import Datetime from 'react-datetime';
import { CDN_URL } from 'constants/commonData';

export const HideContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-color: #f6f7fb;
  padding-top: 10px;
  padding-left: 22px;
  padding-right: 22px;
`;

export const HideIcon = styled.div`
  margin: auto;
  background-image: url(${CDN_URL}/images/arrow-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  /* transition: transform 0.2s ease; */
  transform: rotate(180deg);
  cursor: pointer;
  width: 100%;

  :hover {
    background-color: #e8eaf3;
    border-radius: 2px;
  }
`;

export const WorkoutDotContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2px;
`;

export const WorkoutDot = styled.div`
  width: 4px;
  height: 4px;
  margin: 0px 1.5px;
  border-radius: 50%;
  background-color: #3eb37c;
`;

export const DayCalendar = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  padding-top: 6px;
  border-radius: 3px;
  border: 1px solid transparent;

  :hover {
    border: 1px solid #5158cf;
  }
`;

export const Wrapper = styled.div`
  padding-bottom: 30px;
  position: relative;
  max-height: 500px;
  overflow: hidden;
  background-color: #f6f7fb;
  box-shadow: 0px 1px 0px #dadfea;
  transition: max-height 0.2s ease;

  &.hide {
    max-height: 205px;

    ${HideIcon} {
      transform: rotate(0deg);
    }
  }
`;

export const CustomDatetime = styled(Datetime)`
  &.hide {
    display: none;
  }

  .rdtPicker {
    margin: auto;
    background: transparent;
    padding-left: 22px;
    padding-right: 22px;
    width: unset;

    td,
    th {
      height: 30px;
      width: 30px;
    }

    thead {
      tr {
        position: relative;
        height: 30px;

        .rdtPrev {
          position: absolute;
          right: 49px;
          background: #ffffff;
          border: 1px solid #dadfea;
          box-sizing: border-box;
          border-radius: 3px;
          width: 22px;
          height: 22px;
          background-image: url(${CDN_URL}/images/arrow_prev_calendar.svg);
          background-position: center;
          background-repeat: no-repeat;

          &:hover {
            background-image: url(${CDN_URL}/images/arrow_prev_calendar.svg);
            background-position: center;
            background-repeat: no-repeat;
          }

          span {
            display: none;
          }
        }

        .rdtSwitch {
          position: absolute;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-align: left;
          color: #202353;
          width: calc(100% - 120px);
          pointer-events: none;
        }

        .rdtNext {
          position: absolute;
          right: 22px;
          background: #ffffff;
          border: 1px solid #dadfea;
          box-sizing: border-box;
          border-radius: 3px;
          width: 22px;
          height: 22px;
          background-image: url(${CDN_URL}/images/arrow_next_calendar.svg);
          background-position: center;
          background-repeat: no-repeat;

          &:hover {
            background-image: url(${CDN_URL}/images/arrow_next_calendar.svg);
            background-position: center;
            background-repeat: no-repeat;
          }

          span {
            display: none;
          }
        }
      }
    }

    .dow {
      text-transform: uppercase;
      color: #a7a7b6;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 100%;
    }

    td.rdtDay {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;
      border: 1px solid transparent;
    }

    td.rdtOld,
    td.rdtNew {
      color: #a7a7b6;
    }

    td.rdtToday {
      color: #5158cf;
      font-weight: bold;
      &:before {
        display: none;
      }
    }

    td.rdtDay:hover,
    td.rdtHour:hover,
    td.rdtMinute:hover,
    td.rdtSecond:hover,
    .rdtTimeToggle:hover {
      cursor: pointer;
      background: #fafafb;
    }

    td.rdtActive,
    td.rdtActive:hover {
      background: transparent;

      ${DayCalendar} {
        background: #5158cf;
        border-radius: 3px;
        color: #ffffff;
        text-shadow: none;
      }

      ${WorkoutDot} {
        background-color: #ffffff;
      }
    }
  }
`;
