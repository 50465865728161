import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const SetOneRepMaxModalWrapper = styled(Modal)`
  background: #ffffff;
  border: 1px solid #d4d4d5;
  box-sizing: border-box;
  border-radius: 8px;

  &.ui.modal {
    width: 405px !important;

    .header {
      padding: 30px;
      padding-bottom: 0px;
      border-top-left-radius: 8px;
    }
  }

  .search-exercise-container {
    .box {
      background-color: #fff;
      border: 1px solid #dadfea;
      padding-top: 4px;
      padding-bottom: 4px;
      min-height: 42px;
    }
  }

  .orm__inputValue {
    position: relative;

    &--addon {
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      opacity: 0.4;
      position: absolute;
      top: 12px;
      right: 20px;
    }

    input {
      min-height: 42px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 20px 30px 30px;
  }

  .orm__footer {
    padding-top: 15px;
    text-align: right;
  }
`;
