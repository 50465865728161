import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { get } from 'lodash';
import TextEditable from 'shared/TextEditable';
import { setCaretToEnd } from 'utils/commonFunction';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { QUESTION_NAME_LIMIT_LENGTH } from 'constants/commonData';

import * as S from './style';

const LinearScale = ({
  workingQuestion,
  idx,
  isSelected,
  updatePartialQuestion,
  allowEdit,
  onScrollTo,
  selectedQuestion,
  loading,
  isDragging = false,
  mouseLeaveRef,
  numberQuestion,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const storedTitle = get(workingQuestion, 'title', '');
  const storedDescription = get(workingQuestion, 'description', '');
  const isRequire = get(workingQuestion, 'is_require', false);
  const start = `${get(workingQuestion, 'linear_start.value', 0)}`;
  const end = `${get(workingQuestion, 'linear_end.value', 5)}`;
  const range = useMemo(() => parseInt(end) - parseInt(start) + 1, [start, end]);
  const questionNameRef = useRef();
  const descriptionRef = useRef();
  const isFocus = useRef(`name-${idx}`);

  useEffect(() => {
    setTitle(storedTitle);
    setDescription(storedDescription);
  }, [storedTitle, storedDescription]);

  useEffect(() => {
    if (isSelected) {
      onScrollTo();
      mouseLeaveRef.current = handleOnMouseOutParent;
    }
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && questionNameRef.current && isFocus.current === `name-${idx}`) {
      questionNameRef.current.focus();
      setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
    }
  }, []);

  const renderOption = () => (
    <div className="option-container">
      {Array.from({ length: range }, (v, i) => (
        <div className="option">{start === '1' ? i + 1 : i}</div>
      ))}
    </div>
  );

  const handleChangeQuestionName = value => {
    if (!isDragging) {
      const trimmedValue = value.trim();
      if (trimmedValue.length <= QUESTION_NAME_LIMIT_LENGTH) {
        setTitle(trimmedValue);
      } else {
        // handle copy paste case out of chars limit
        if (trimmedValue.length === QUESTION_NAME_LIMIT_LENGTH + 1) {
          setTitle(title.trim().slice(0, QUESTION_NAME_LIMIT_LENGTH));
          questionNameRef.current.focus();
          setCaretToEnd(get(questionNameRef, 'current.inputRef.current'));
        } else {
          setTitle(trimmedValue.slice(0, QUESTION_NAME_LIMIT_LENGTH));
        }
      }
    }
  };

  const handleBlurQuestionName = () => {
    if (workingQuestion.title !== title && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        title,
      });
    }
  };

  const handleBlurDescription = () => {
    if (workingQuestion.description !== description && updatePartialQuestion && !loading && !isDragging) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        description,
      });
    }
  };

  const handleChangeDescription = value => {
    const trimmedValue = value.trim();
    setDescription(trimmedValue);
  };

  const handleFocus = type => () => {
    isFocus.current = type;
  };

  const handleOnMouseOutParent = () => {
    if (questionNameRef.current) {
      const inputRef = get(questionNameRef, 'current.inputRef.current');
      questionNameRef.current.onBlur();
      inputRef && inputRef.blur();
    }
    if (descriptionRef.current) {
      const inputRef = get(descriptionRef, 'current.inputRef.current');
      descriptionRef.current.onBlur();
      inputRef && inputRef.blur();
    }
  };

  const handleOnClickQuestion = () => {
    allowEdit && selectedQuestion && selectedQuestion(workingQuestion);
  };

  return (
    <S.ContentContainer range={range} isRequire={isRequire} onClick={handleOnClickQuestion}>
      <S.IconWrapper>
        <QuestionIcon />
        <span>{numberQuestion}</span>
      </S.IconWrapper>
      <TextEditable
        ref={questionNameRef}
        className="title"
        value={title}
        placeholder="Name your question"
        lineHeight={28}
        onFocus={handleFocus(`name-${idx}`)}
        onBlur={handleBlurQuestionName}
        onChange={handleChangeQuestionName}
        maxLength={QUESTION_NAME_LIMIT_LENGTH}
        notRequired
        readOnly={!allowEdit}
      />
      <TextEditable
        ref={descriptionRef}
        className="description"
        value={description}
        placeholder="Description (optional)"
        lineHeight={22}
        onFocus={handleFocus(`desc-${idx}`)}
        onBlur={handleBlurDescription}
        onChange={handleChangeDescription}
        notRequired
        breakLine
        readOnly={!allowEdit}
      />
      {renderOption()}
      <S.LabelWrapper>
        <span>{get(workingQuestion, 'linear_start.label', '')}</span>
        <span>{get(workingQuestion, 'linear_end.label', '')}</span>
      </S.LabelWrapper>
    </S.ContentContainer>
  );
};

export default memo(LinearScale);
