import React, { useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { Button } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { ReactComponent as FilterIcon } from 'assets/icons/new_filter.svg';
import ButtonIcon from 'shared/ButtonIcon';
import FiltersPopup, { OWNER_TYPE } from 'shared/AssetsShareSetting/components/FiltersPopup';
import { ResetFilter } from '../style';
import { SharedTooltip } from 'shared/SharedTooltip';
import { ReactComponent as MiniSearchIcon } from 'assets/icons/mini-search-icon.svg';

const RenderBanner = ({
  onSetIsHideBanner,
  handleSearch,
  handleClearSearch,
  handleKeyPress,
  textSearch,
  children,
  changeQueryParams,
}) => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ owner: 'all' });

  const counterFilters = useMemo(() => {
    let counter = 0;
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'owner' && value === 'you') {
        counter = 1;
      }
    }
    return counter;
  }, [filters]);

  const handleOpenSearchBox = () => {
    setShowSearchBox(true);
  };

  const handleSetIsHideBanner = () => {
    onSetIsHideBanner(true);
  };

  const updateFilters = filters => {
    setFilters(filters);

    typeof changeQueryParams === 'function' &&
      changeQueryParams({
        page: 1,
        only_my_workout_collection: filters.owner === OWNER_TYPE.YOU,
      });
  };

  const toggleShowFilters = () => {
    setShowFilters(it => !it);
  };

  const resetFilter = () => {
    updateFilters({ owner: OWNER_TYPE.ALL });
  };

  return (
    <S.BannerContainer>
      <S.BannerTextContent>
        <div className="collections__banner__label">Workout Collections</div>
        <div className="collections__banner__title">Netflix-style Training Portal</div>
        <div className="collections__banner__description">
          <span>
            Create an on-demand searchable workout directory with different categories that your clients can browse
            anytime
          </span>
          <span className="collections__banner__learnMoreLink">
            <a
              href="https://help.everfit.io/en/articles/6260935-workout-collections-launch-your-own-on-demand-netflix-style-training-content-portal"
              target="_blank"
            >
              <img src={`${CDN_URL}/images/learn_more_icon.svg`} alt="" /> Learn more
            </a>
          </span>
        </div>
      </S.BannerTextContent>
      <div className="buttons__container">
        {children}

        <>
          {showSearchBox ? (
            <NewSearchInput
              placeholder="Search by keyword or name"
              onChange={debounce(handleSearch, 300)}
              onClearSearch={handleClearSearch}
              onKeyPress={handleKeyPress}
              defaultValue={textSearch}
            />
          ) : (
            <>
              <Button
                className="button--search-box-trigger"
                onClick={handleOpenSearchBox}
                data-tip
                data-for="workout-collection-banner-search-tooltip"
              >
                <MiniSearchIcon />
              </Button>
              <SharedTooltip content="Search" id="workout-collection-banner-search-tooltip" />
            </>
          )}
          <S.FilterButtonWrapper>
            <ButtonIcon
              text="Filter"
              width={104}
              height={40}
              className="filter-options"
              icon={<FilterIcon />}
              color="rgb(119, 119, 119)"
              textHoverColor="rgb(119, 119, 119)"
              bgHoverColor="rgb(248, 248, 248)"
              onClick={toggleShowFilters}
              isShowFilter={showFilters}
            />
            {counterFilters > 0 && <S.CounterFilters>{counterFilters}</S.CounterFilters>}
            {showFilters && (
              <FiltersPopup
                currentFilters={filters}
                onUpdateFilters={updateFilters}
                onCloseFilters={toggleShowFilters}
              />
            )}
          </S.FilterButtonWrapper>
          <ResetFilter onClick={resetFilter} hide={counterFilters === 0}>
            Reset
          </ResetFilter>
        </>
      </div>

      <div className="button-hidden-container">
        <Button className="button--hide-banner" onClick={handleSetIsHideBanner}>
          <span>Hide Banner</span>
          <img src={`${CDN_URL}/images/arrow_up_7e7e7e.svg`} alt="" />
        </Button>
      </div>
      <S.BannerImage />
    </S.BannerContainer>
  );
};

export default RenderBanner;
