import React from 'react';
import * as S from './style';

const Duration = ({ startDate, endDate }) => {
  const isSameDate = endDate.isSame(startDate, 'day');

  if (!isSameDate) {
    const durationText = !endDate.isSame(startDate, 'year')
      ? `${startDate.format('MMM D, YYYY')} - ${endDate.format('MMM D, YYYY')}`
      : !endDate.isSame(startDate, 'month')
      ? `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`
      : `${startDate.format('MMM D')} - ${endDate.format('D')}`;

    return <S.PartialInfo>{durationText}</S.PartialInfo>;
  }

  return (
    <S.PartialDurationInfo>
      <div>{startDate.format('MMM D')}</div>
      <div className="divider" />
      <div>
        {startDate.format('hh:mm a')} - {endDate.format('hh:mm a')}
      </div>
    </S.PartialDurationInfo>
  );
};

export default Duration;
