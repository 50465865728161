import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const StartWorkout = styled.div`
  display: inline-block;
  padding: 12px 22px;
  font-weight: 700;
  font-size: 12.4px;
  line-height: 18px;
  text-align: center;
  color: #7470ef;
  background: #ffffff;
  border-radius: 21px;
`;

export const Header = styled.div`
  position: absolute;
  left: 24px;
  top: 112px;
  margin-bottom: 20px;

  .today {
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.26px;
    margin-bottom: 1.4px;
    text-transform: uppercase;
  }

  .hello {
    display: flex;
    color: #1e0a3c;
    font-weight: 700;
    font-size: 24.6px;
    line-height: 27px;
    letter-spacing: -0.45px;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const WorkoutContent = styled.div`
  z-index: 2;
  position: relative;
`;

export const Workout = styled.div`
  position: relative;
  height: 204px;
  overflow: hidden;
  padding: 44px 25px 20px;
  margin-bottom: 13px;
  text-align: center;
  border-radius: 5px;

  .workout__title {
    font-weight: 700;
    font-size: 11.5px;
    line-height: 18px;
    color: #4fdfac;
    text-align: center;
    letter-spacing: 0.26px;
    margin-bottom: 6px;
  }

  .workout__name {
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    max-width: 250px;
    margin: auto;
    margin-bottom: 23px;
    letter-spacing: -0.26px;
  }
`;

export const Task = styled.div`
  background: #ffffff;
  box-shadow: 0px 5.41063px 12.4444px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 11px 9px;

  .task__header {
    color: #1e0a3c;
    font-weight: bold;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.17px;
    margin-bottom: 11px;
  }
`;

export const Content = styled.div`
  position: absolute;
  width: calc(100% - 47px);
  top: 194px;
  left: 24px;

  .let-do-this {
    color: #1e0a3c;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.18px;
    margin-bottom: 10px;
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 55px;
  left: 24px;
`;

export const DefaultLogo = styled.div`
  color: #a8a8a8;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 38px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #dedede;
  border-radius: 5px;
`;

export const NotificationIcon = styled.div`
  width: 20px;
  height: 24px;
  position: absolute;
  background: transparent url(${CDN_URL}/images/app_notification.svg) no-repeat center;
  background-size: contain;
  top: 55px;
  right: 23px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% + 5px);
  background-color: #f7f8fc;
  transform: translateY(5px);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 1;

  ${StartWorkout} {
    color: ${props => props.primaryColor};
  }

  ${Header} {
    .today {
      color: ${props => props.primaryColor};
    }
  }

  ${Workout} {
    background: transparent url(${props => props.workoutBackground}) no-repeat center;
    background-size: cover;
  }

  ${props =>
    props.useCustomTitle &&
    css`
      ${Workout} {
        .workout__title {
          color: #fff;
          opacity: 0.6;
        }
      }
    `}
  ${props =>
    props.customBackground &&
    css`
      ${Workout} {
        :before {
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          background-color: #131313;
          opacity: 0.2;
          position: absolute;
        }
      }
    `}
`;

export const TodayScreenLogo = styled.div`
  width: 34px;
  height: 34px;
  background: url(${props => props.src || ''});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  ${props =>
    props.horizontalSide &&
    css`
      width: 150px;
      height: 35px;
      background-position: left center;
      background-size: contain;
    `}

  ${props =>
    props.verticalSide &&
    css`
      height: 35px;
      background-position: center;
      background-size: contain;
    `}
`;
