import { Types } from './actions';
import _ from 'lodash';
import { AUTOFLOW_CLIENT_STATUS } from 'constants/commonData';

const INITITAL_STATE = {
  list: [],
  total: 0,
  selectedClients: [],
  firstLoad: true,
  query: {
    search: '',
    page: 1,
    per_page: 50,
    sort: -1,
    sorter: 'last_activity',
    status: AUTOFLOW_CLIENT_STATUS.ACTIVE
  }
};

export default (state = INITITAL_STATE, action) => {
  const { payload, type } = action;
  const { list, query } = state;

  switch (type) {
    case Types.AUTOFLOW_CLIENT_CHANGE_QUERY_PARAM:
      return Object.assign({}, state, {
        query: { ...query, ...payload.newQuery }
      });

    case Types.SUCCESS_GET_AUTOFLOW_CLIENT_LIST:
      return Object.assign({}, state, {
        list: payload.list,
        firstLoad: false,
        total: isNaN(parseInt(payload.total)) ? 0 : payload.total
      });

    case Types.RESET_AUTOFLOW_CLIENT_DATA:
      return INITITAL_STATE;

    case Types.AUTOFLOW_CLIENT_SELECT_CLIENTS:
      return Object.assign({}, state, { selectedClients: payload.data });

    case Types.AUTOFLOW_CLIENT_SUCCESS_REMOVE_CLIENTS:
      return Object.assign({}, state, { selectedClients: [] });

    case Types.AUTOFLOW_CLIENT_SUCCESS_REMOVE_ONE_CLIENT:
      return Object.assign({}, state, {
        selectedClients: _.filter(state.selectedClients, item => item._id !== payload.client)
      });

    case Types.AUTOFLOW_SUCCESS_ACTIVATE_CLIENTS:
    case Types.AUTOFLOW_SUCCESS_PAUSE_CLIENTS:
    case Types.AUTOFLOW_SUCCESS_MARK_CLIENTS_AS_COMPLETE:
      if (payload.multiple) {
        return Object.assign({}, state, { selectedClients: [] });
      } else {
        return Object.assign({}, state, {
          selectedClients: _.filter(state.selectedClients, item => item._id !== payload.data.clients[0])
        });
      }

    default:
      return state;
  }
};
