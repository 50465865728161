import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import moment from 'moment';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

import * as S from './styles';
import { ReactComponent as TriggerIcon } from 'assets/icons/history.svg';
import { getSetItemDescription } from 'helpers/exercise';
import { Button } from 'shared/FormControl';

const popupHeight = 280;

export default props => {
  const [open, setOpen] = useState(false);
  const [popupStyles, setPopupStyles] = useState({});
  const [loading, setLoading] = useState({});
  const [listEntries, setListEntries] = useState([]);

  const triggerRef = useRef();
  const popup = useRef();

  useLayoutEffect(() => {
    if (open) {
      const rect = triggerRef.current.getBoundingClientRect();
      const { x, y, height, width } = rect;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const styles = { right: windowWidth - (x + width + 50) };
      let className = 'bottom';
      const bottomSpacing = windowHeight - y - height - 10;

      if (bottomSpacing > popupHeight || bottomSpacing > y) {
        styles.top = y + height + 10;
      } else {
        className = 'top';
        styles.top = y - popupHeight - 10;
      }

      setPopupStyles({ styles, className });
    }
  }, [open]);

  useEffect(() => {
    if (open && !props.deletedExercise) {
      props
        .getExerciseHistoryEntries({
          client: props.selectedClient._id,
          exercise: props.exerciseId,
        })
        .then(result => {
          if (result && result.data) {
            let linkedEntriesHistory = result.data.data.filter(item => item.link);
            let sortedEntriesHistory = _.orderBy(linkedEntriesHistory, e => (e.link ? e.link.date : ''), 'desc');
            setLoading(false);
            setListEntries(sortedEntriesHistory);
          } else {
            setLoading(false);
          }
        });
    }
  }, [open]);

  const toggleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const closePopup = () => {
    setOpen(false);
  };

  const handleClickViewMore = () => {
    const win = window.open(
      `/home/client/${props.selectedClient._id}/metrics?tab=exercise&exerciseId=${props.exerciseId}`,
      '_blank',
    );
    win.focus();
  };

  const handleFormatHistoryList = data => {
    let newData = data || [];

    if (data) {
      if (data.length > 0) {
        newData = _.orderBy(data, ['updatedAt'], ['desc']);
      }
    }
    return newData;
  };

  const historyListEntries = handleFormatHistoryList(listEntries);

  return (
    <RootCloseWrapper disabled={!open} onRootClose={closePopup} event="click">
      <S.Wrapper className={classnames('exHistory', { 'exHistory--open': open }, popupStyles.className)}>
        <S.Trigger className="exHistory__trigger" onClick={toggleOpen} ref={triggerRef}>
          {props.trigger || <TriggerIcon className="exHistory__trigger__defaultIcon" />}
        </S.Trigger>
        {open && (
          <S.PopupContainer className="exHistory__popup" ref={popup} style={popupStyles.styles}>
            <S.PopupHeader>
              <div>History</div>
              {!!historyListEntries.length && <Button onClick={handleClickViewMore}>View more</Button>}
            </S.PopupHeader>
            <S.PopupContent center={props.deletedExercise || loading || !historyListEntries.length}>
              {!!props.deletedExercise ? (
                <S.DeletedExercise>
                  Original exercise has been removed from library. Exercise history will not be available.
                </S.DeletedExercise>
              ) : loading ? (
                <S.Loading />
              ) : !historyListEntries.length ? (
                <S.NoData>No data available</S.NoData>
              ) : null}
              {historyListEntries.map(data => {
                const date = _.get(data, 'link.end_date') || data.date;
                const tooltipId = `tooltip--workout-name-${data._id}`;

                return (
                  <S.DayDataContainer key={data._id}>
                    <S.DayHeader>
                      <div>{moment(date).format('MMM DD')}</div>
                      <div data-tip={_.get(data, 'link.title')} data-for={tooltipId}>
                        {_.get(data, 'link.title')}
                      </div>
                      <ReactTooltip id={tooltipId} effect="solid" className="app-tooltip-white" />
                    </S.DayHeader>
                    <S.Row>
                      <div></div>
                      <div>
                        <ul>
                          {data.training_sets.map(item => (
                            <li key={item._id}>
                              {getSetItemDescription(
                                item,
                                props.unit,
                                false,
                                data.exercise_instance.fields,
                                props.fields,
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </S.Row>
                  </S.DayDataContainer>
                );
              })}
            </S.PopupContent>
          </S.PopupContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};
