import styled from 'styled-components';

export const Content = styled.div`
  .select-client--select-input {
    flex: 1 1;
    text-indent: 0;
  }

  .multi-select-container.select-client--select-input .multi-select__value-container {
    padding: 0 15px;
  }

  .multi-select-container.select-client--select-input
    .multi-select__value-container.multi-select__value-container--has-value {
    padding: 2.5px 10px;
  }

  .multi-select-container .multi-select__control {
    border-color: #d4d7d9;
  }

  .select-client--select-input .multi-select__placeholder {
    font-size: 12px;
    line-height: 12px;
    color: #777777;
  }

  .select-client--select-input .multi-select__input {
    color: #323c47;
  }

  .multi-select__menu {
    top: auto;
    bottom: 100%;
  }
`;

export const Wrapper = styled.div``;
