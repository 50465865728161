import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

const StatisticItem = props => {
  return (
    <S.StatisticItemWrapper>
      <div className="statisticItem__icon">
        <img src={props.icon} alt="" />
      </div>
      <div className="statisticItem__info">
        <div className="statisticItem__name">{props.name}</div>
        <div className="statisticItem__value">
          <div className="statisticItem__value--number">{props.value || 0}</div>
          <div className="statisticItem__value--unit">{props.unit || ''}</div>
        </div>
      </div>
    </S.StatisticItemWrapper>
  );
};

class StatisticInfo extends React.PureComponent {
  render() {
    const { workingStudio, availableClients } = this.props;
    return (
      <S.Wrapper>
        <StatisticItem
          icon={`${CDN_URL}/images/studio_program_workout_icon.svg`}
          name="Workouts"
          value={workingStudio.number_of_workouts}
        />
        <StatisticItem
          icon={`${CDN_URL}/images/studio_program_weeks_icon.svg`}
          name="Weeks"
          value={workingStudio.number_of_weeks}
        />
        <StatisticItem
          icon={`${CDN_URL}/images/studio_program_client_icon.svg`}
          name="Available for"
          value={availableClients.length}
          unit={pluralize('Client', availableClients.length)}
        />
      </S.Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    workingStudio: state.rootReducer.studioProgram.workingStudio || {},
    availableClients: state.rootReducer.studioProgram.availableClients || [],
  };
};

const mapDispatch = dispatch => ({});

export default connect(mapStateToProps, mapDispatch)(StatisticInfo);
