import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #7B7E91;
`;

export const InstructionWrapper = styled.div`
  padding: 30px 30px 0;
`;

export const VideoLinkWrapper = styled.div`
  margin-bottom: 0;
  ${props =>
    props.isEditMode &&
    css`
      margin-bottom: 30px;
    `}

  ${props =>
    props.hasVideoIntro &&
    css`
      margin-bottom: 30px;
    `}
`;

export const TabHeader = styled.div`
  display: flex;
  border: 1px solid #dadbea;
  border-radius: 5px;
  height: 36px;
  overflow: hidden;
  align-items: center;
`;

export const TabHeaderItem = styled.div`
  ${baseText}
  line-height: 34px;
  flex: 1;
  text-align: center;
  border-left: 1px solid #dadbea;
  cursor: pointer;
  &:first-child {
    border-left: none;
  }
  ${props =>
    props.active &&
    css`
      background-color: #f0f0f2;
      color: #202353;
    `}
`;

export const TabContent = styled.div`
  padding-top: 19px;
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  ${props =>
    props.isEditMode &&
    css`
      padding-top: 14px;
    `}
  ${({ isEmptyStep }) =>
    isEmptyStep &&
    css`
      display: block;
      padding-top: unset;
    `}
`;

export const TabContentEmpty = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 39px 30px;
  ${baseText}
  color: #202353;
  font-size: 13px;
  line-height: 23px;
`;
