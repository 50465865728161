import React from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/asset-user-count.svg';
import { get } from 'lodash';

export default function WorkoutCollectionOption({ data }) {
  const number_of_clients = get(data, 'number_of_clients', 0);
  const number_of_workouts = get(data, 'number_of_workouts', 0);
  return (
    <div className="asset-content">
      <div className="resource-info-container">
        <div className="title">{data.name}</div>
        <div className="clients-count-badge">
          <UserIcon />
          {number_of_clients}
        </div>
      </div>
      <div className="asset-info">Workouts: {number_of_workouts}</div>
    </div>
  );
}
