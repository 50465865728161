import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #000000;
  margin-top: 7px;

  .sub-title {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
  }
`;

export default () => (
  <Container>
    <div>Default Client Settings</div>
    <div className="sub-title">For new clients</div>
  </Container>
);
