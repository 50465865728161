import React, { useEffect, useRef } from 'react';
import * as S from './style';
import * as ModalS from '../style';
import { ReactComponent as FacebookIcon } from 'assets/icons/marketplace-packages/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/marketplace-packages/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/marketplace-packages/instagram.svg';
import { ReactComponent as URLIcon } from 'assets/icons/marketplace-packages/link.svg';
import EverfitBannerImage from 'assets/images/marketplace-packages/everfit-banner.png';
import TextareaEmailContent from '../TextareaEmailContent';

const PreviewEmailSection = ({
  modalContent,
  clientRequest,
  workingPackage,
  isMultipleSelectedRequests,
  errors,
  setErrors,
  formData,
  onChangeForm,
}) => {
  const textareaRef = useRef(null);
  const { declineReason = '' } = formData || {};

  const { client_info } = clientRequest || {};
  const { name: packageName = '', trainer = {} } = workingPackage || {};
  const { first_name: first_name_trainer = '', last_name: last_name_trainer = '' } = trainer;
  const trainerFullName = `${first_name_trainer} ${last_name_trainer}`;

  const { first_name: first_name_client = '', last_name: last_name_client = '' } = client_info || {};
  const clientFullName = `${first_name_client} ${last_name_client}`;

  const { declineReason: emailContentErrorMessage = '' } = errors || {};

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.onFocus();
    }
  }, []);

  const handleChangeEmailContent = e => {
    const { target: { value = '' } = {} } = e;
    let errorMessage = '';
    const MIN_CHARACTER = 25;

    if (value.length < MIN_CHARACTER) {
      errorMessage = `It should be at least ${MIN_CHARACTER} characters.`;
    }

    setErrors(preState => ({ ...preState, declineReason: errorMessage }));
    onChangeForm({ declineReason: value });
  };

  const generateDeclineReason = () => {
    if (!declineReason.trim()) return null;

    const splitReason = declineReason.replace(/\n/g, ':SPACE:').split(':SPACE:');
    let result = '';
    if (splitReason.length > 0) {
      splitReason.forEach(item => (result += `<p>${item}</p>`));
    }
    return result;
  };

  return (
    <S.Wrapper>
      <S.Content>
        <S.PreviewEmailWrapper>
          <ModalS.Title className="large-title">
            Preview email to {isMultipleSelectedRequests ? 'Clients' : 'Client'}
          </ModalS.Title>
          <S.EmailTemplate>
            <div className="preview-email">
              <div className="preview-banner">
                <img src={EverfitBannerImage} alt="" />
              </div>
              <div className="preview-content">
                <div className="preview-content_information">
                  <h6>Dear {isMultipleSelectedRequests ? "[Client's name]" : clientFullName},</h6>
                  <p>
                    {modalContent.emailDescription} <b>{packageName}.</b>
                  </p>
                  <div className="decline-reason" dangerouslySetInnerHTML={{ __html: generateDeclineReason() }} />
                  <p>Best Regards,</p>
                  <p>{trainerFullName}</p>
                </div>
                <div className="preview-content_footer">
                  <div className="footer-social">
                    <span>
                      <FacebookIcon />
                    </span>
                    <span>
                      <LinkedinIcon />
                    </span>
                    <span>
                      <InstagramIcon />
                    </span>
                    <span>
                      <URLIcon />
                    </span>
                  </div>
                  <div className="footer-copyright">
                    <p>{new Date().getFullYear()} Everfit</p>
                    <p>San Francisco, CA</p>
                  </div>
                </div>
              </div>
            </div>
          </S.EmailTemplate>
        </S.PreviewEmailWrapper>
        <S.DeclineReasonWrapper>
          <ModalS.Title>Declined Reason</ModalS.Title>
          <S.DeclineReasonContent>
            <TextareaEmailContent
              value={declineReason}
              maxLength={500}
              warningLength={495}
              errorMessage={emailContentErrorMessage}
              onChange={handleChangeEmailContent}
              ref={textareaRef}
            />
          </S.DeclineReasonContent>
        </S.DeclineReasonWrapper>
      </S.Content>
    </S.Wrapper>
  );
};

export default PreviewEmailSection;
