import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 0px 35px;
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 3px rgba(76, 98, 135, 0.25);
  border-radius: 8px;
  padding: 15px;
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 27px;

  .add-section-form__back-icon {
    cursor: pointer;
    margin-right: 7px;
  }

  .button--chooseFromLib {
    background: transparent;
    border-color: #5158cf;
    color: #5158cf;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    padding: 5px 14px;

    :hover {
      background-color: #5158cf;
      color: #fff;
      cursor: pointer;
    }
  }
`;

export const Body = styled.div``;

export const Footer = styled.div`
  .add-section-form__cancel {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    cursor: pointer;
  }
`;
