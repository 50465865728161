import React, { useState } from 'react';
import { RootCloseWrapper } from 'react-overlays';

// Assets
import { ReactComponent as CheckedIcon } from 'assets/icons/check-mark-freestyle-section.svg';

import * as S from './style';

const ColorPicker = props => {
  const { onSelectColor, options = [], className, placeholder = '', color, disabled } = props;
  const [open, setOpen] = useState(false);

  const handleOpenDropdown = val => () => {
    setOpen(val);
  };

  const handleSelectColor = value => () => {
    onSelectColor(value);
    setOpen(false);
  };

  return (
    <S.Wrapper className={className} disabled={disabled}>
      <S.ColorPickerWrapper
        onClick={disabled ? undefined : handleOpenDropdown(!open)}
        disabled={disabled}
        active={open}
        className={open && 'opened-color-picker'}
      >
        {color ? (
          <>
            <S.ColorSquare color={color} />
            <S.ColorLine color={color} />
          </>
        ) : (
          placeholder
        )}
      </S.ColorPickerWrapper>
      {open && (
        <RootCloseWrapper event="click" onRootClose={handleOpenDropdown(false)}>
          <S.Popup>
            {options.map(item => (
              <S.Option onClick={handleSelectColor(item.color)} active={color === item.color} key={item.color}>
                <S.ColorWrapper>
                  <S.ColorSquare color={item.color} /> <span className="color-label">{item.label}</span>
                </S.ColorWrapper>

                {color === item.color && <CheckedIcon className="checked-icon" />}
              </S.Option>
            ))}
          </S.Popup>
        </RootCloseWrapper>
      )}
    </S.Wrapper>
  );
};

export default ColorPicker;
