import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const IngredientWrapper = styled.div`
  padding: 20px 30px 0 30px;
  .droppable-context-ingredient {
    position: relative;
    > div:last-child {
      .ingredient-item-wrapper {
        .content {
          border-bottom: none;
        }
      }
    }
  }
  ${props =>
    props.isEditMode &&
    css`
      padding-right: 10px;
    `}
`;

export const IngredientHeaderWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-right: 30px;

  .__react_component_tooltip {
    ${baseText}
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    max-width: 184px;
    padding: 8px 16px;
    border-radius: 4px;
  }
`;

export const AddIngredient = styled(Button)`
  ${baseText}
  height: 36px;
  width: 149px;
  border: none;
  user-select: none;
  border: 1px solid #e1e1ea;
  svg {
    margin-right: 10px !important;
    width: 11.67px;
    height: 11.67px;
    path {
      stroke: #202353;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    border: none;
    svg path {
      stroke: #5158cf;
    }
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid #5158cf;
      background-color: #f0f1ff;
      color: #5158cf;
      svg path {
        stroke: #5158cf;
      }
      &:hover {
        border: 1px solid #5158cf;
      }
    `}
`;

export const UnitWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const UnitName = styled.div`
  ${baseText}
  margin-right: 5px;
  font-size: 12px;
  line-height: 18px;
  color: #7b7e91;
`;

export const Placeholder = styled.div`
  position: absolute;
  border: 1px dashed #5158cf;
  border-radius: 5px;
`;

export const NoteWrapper = styled.div`
  margin: 10px 0;
  padding-right: 30px;
  .text-editable__container {
    background: #f8f8f8;
    padding: 9px;
    .text-editable__input {
      line-height: 20px;
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 13px;
      color: #202353;
      opacity: 1;
      &:empty::before {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 13px;
        color: #a3a3b5;
        opacity: 1;
      }
    }
    &:hover {
      border: 1px solid #5158cf;
    }
    &:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
      background: #f8f8f8;
    }
  }
  .text-editable__container--active {
    background-color: #fff;
  }
  ${({ readOnly }) =>
    readOnly &&
    css`
      padding-right: 0;
      .text-editable__container {
        &:hover {
          border: none;
        }
      }
      .text-editable__container--active {
        background-color: #f8f8f8;
      }
    `}
`;

export const AddAsText = styled(Button)`
  ${baseText}
  height: 36px;
  width: 126px;
  border: none;
  user-select: none;
  border: 1px solid #e1e1ea;
  margin-left: 10px;
  svg {
    margin-right: 10px !important;
    width: 11.67px;
    height: 11.67px;
    path {
      stroke: #202353;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    border: none;
    svg path {
      stroke: #5158cf;
    }
  }
  ${({ active }) =>
    active &&
    css`
      border: 1px solid #5158cf;
      background-color: #f0f1ff;
      color: #5158cf;
      svg path {
        stroke: #5158cf;
      }
      &:hover {
        border: 1px solid #5158cf;
      }
    `}
`;

export const AddAsTextWrapper = styled.div`
  padding-right: 20px;
  padding-top: 10px;
`;
