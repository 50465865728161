import React, { useEffect, useState } from 'react';
import DropdownOptions from 'shared/DropdownOptions';
import { isESCPress } from 'utils/commonFunction';
import { CDN_URL, KEY_CODE } from 'constants/commonData';

import * as ModalLayout from 'shared/Styles/ModalLayout';
import * as S from './style';

const MUTE_OPTIONS = [
  {
    label: '8 hours',
    value: '8_hour',
  },
  {
    label: '1 week',
    value: '1_week',
  },
  {
    label: 'Forever',
    value: 'forever',
  },
];

const MuteConversationModal = ({ onMute, onClose }) => {
  const [selectedValue, setSelectedValue] = useState(MUTE_OPTIONS[0]);

  useEffect(() => {
    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, []);

  const onEscPressed = event => {
    if (event.keyCode === KEY_CODE.esc || isESCPress(event.key)) {
      onClose && onClose();
    }
  };

  const handleChange = option => {
    setSelectedValue(option);
  };

  const handleMute = () => {
    onMute && onMute(selectedValue.value);
    onClose && onClose();
  };

  return (
    <S.MuteConversationModal open={true} closeOnDimmerClick={false}>
      <button className="close-button" onClick={onClose}>
        <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
      </button>
      <S.ModalTitle>Mute Conversation</S.ModalTitle>
      <S.MuteConversationModalDesc>How long do you want to mute notifications for?</S.MuteConversationModalDesc>
      <DropdownOptions
        width="440px"
        height="36px"
        options={MUTE_OPTIONS}
        selectedValue={selectedValue.value}
        onSelect={handleChange}
        isForceUpdate={true}
      />
      <ModalLayout.Actions className="confirm-footer">
        <S.ModalButton purple onClick={handleMute}>
          Mute
        </S.ModalButton>
      </ModalLayout.Actions>
    </S.MuteConversationModal>
  );
};

export default MuteConversationModal;
