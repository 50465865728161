import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import debounce from 'lodash/debounce';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import filter from 'lodash/filter';
import classNames from 'classnames';

import { FILTER_OPTIONS, MARKETPLACE_FILTER_OPTIONS, NOTIFICATION_PARENT } from 'constants/commonData';
import { NewSearchInput } from 'shared/SearchInput';
import UpgradePath from 'shared/UpgradePath';
import NoAccessPaymentActivity from 'shared/UpgradePath/components/NoAccessPaymentActivity';
import Group from './components/Group';
import { getActivities } from 'redux/activity/actions';
import { getConnectedAccount } from 'redux/setup-payment/actions';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down.svg';
import * as S from './style';

const DEFAULT_PER_PAGE = 15;

const ACTIVITY_TABS = {
  coaching_platform: 'Coaching Platform',
  marketplace: 'Marketplace',
};

const getFilterTypes = items => {
  return without(
    items.map(item => (item.checked ? item.value : '')),
    '',
  );
};

const Activity = ({ loading, groups = [], payment, getActivities, permission, push, user, getConnectedAccount }) => {
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState(ACTIVITY_TABS.coaching_platform);
  const isMP = activeTab === ACTIVITY_TABS.marketplace;
  const hasMP = permission.marketplace_payment;
  const getFilteredOptions = isMP => {
    let options = isMP ? MARKETPLACE_FILTER_OPTIONS : FILTER_OPTIONS;
    const exclude = [];

    if (!get(permission, 'is_allow_refund', false)) {
      exclude.push('refund');
    }

    if (!get(permission, 'trial_package', false)) {
      exclude.push('trial');
    }
    options = cloneDeep(options.filter(it => !exclude.includes(it.value)));

    return options;
  };
  const [filters, setFilters] = useState(getFilteredOptions);
  const collapsedNumber = isMP && filter(filters, it => it.isCollapse).length;

  useEffect(() => {
    if (searchValue) {
      handleSearch(null, { value: searchValue });
    } else {
      getActivities(
        {
          pageSize: DEFAULT_PER_PAGE,
          isRefresh: true,
        },
        isMP,
      );
    }

    getConnectedAccount();
  }, [isMP]);

  const handleChangeFilter = item => () => {
    if (loading) {
      return;
    }
    const isCheckedAll = item.checked;
    let changeFilters = [];
    if (item.value === NOTIFICATION_PARENT.ALL) {
      changeFilters = filters.map(it => {
        it.checked = !isCheckedAll;
        return it;
      });
    } else {
      changeFilters = filters.map(it => {
        if (it.value === item.value) {
          it.checked = !it.checked;
        }
        if (it.value === NOTIFICATION_PARENT.ALL) {
          it.checked = false;
        }
        return it;
      });
    }

    const types = getFilterTypes(changeFilters);
    setFilters(changeFilters);

    if (types.length) {
      getActivities({ types: types, pageSize: DEFAULT_PER_PAGE, isRefresh: true }, isMP);
    } else {
      getActivities({ pageSize: DEFAULT_PER_PAGE, isRefresh: true }, isMP);
    }
  };

  const handleChangeMarketplaceFilter = (e, item) => {
    if (loading) return;
    e.stopPropagation();
    const isCheckedAll = item.checked;
    let changeFilters = [];

    switch (item.value) {
      case NOTIFICATION_PARENT.ALL:
        changeFilters = filters.map(it => {
          it.checked = !isCheckedAll;
          return it;
        });
        break;
      case NOTIFICATION_PARENT.PAYMENT:
        changeFilters = filters.map(it => {
          if (it.value === NOTIFICATION_PARENT.PAYMENT || it.parent === NOTIFICATION_PARENT.PAYMENT) {
            it.checked = !isCheckedAll;
          }
          if (it.value === NOTIFICATION_PARENT.ALL) {
            const isCheckedPayment =
              !item.checked &&
              getFilterTypes(filters).includes(NOTIFICATION_PARENT.WAITLIST) &&
              getFilterTypes(filters).includes(NOTIFICATION_PARENT.INTRO_CALL);
            it.checked = isCheckedPayment;
          }
          return it;
        });
        break;
      case NOTIFICATION_PARENT.WAITLIST:
        changeFilters = filters.map(it => {
          if (it.value === NOTIFICATION_PARENT.WAITLIST || it.parent === NOTIFICATION_PARENT.WAITLIST) {
            it.checked = !isCheckedAll;
          }
          if (it.value === NOTIFICATION_PARENT.ALL) {
            const isCheckedWaitlist =
              !item.checked &&
              getFilterTypes(filters).includes(NOTIFICATION_PARENT.PAYMENT) &&
              getFilterTypes(filters).includes(NOTIFICATION_PARENT.INTRO_CALL);
            it.checked = isCheckedWaitlist;
          }
          return it;
        });
        break;
      case NOTIFICATION_PARENT.INTRO_CALL:
        changeFilters = filters.map(it => {
          if (it.value === NOTIFICATION_PARENT.INTRO_CALL || it.parent === NOTIFICATION_PARENT.INTRO_CALL) {
            it.checked = !isCheckedAll;
          }
          if (it.value === NOTIFICATION_PARENT.ALL) {
            const isCheckedIntroCall =
              !item.checked &&
              getFilterTypes(filters).includes(NOTIFICATION_PARENT.PAYMENT) &&
              getFilterTypes(filters).includes(NOTIFICATION_PARENT.WAITLIST);
            it.checked = isCheckedIntroCall;
          }
          return it;
        });
        break;
      default:
        changeFilters = filters.map(it => {
          if (it.value === item.value) {
            it.checked = !it.checked;
          }

          if (it.value === NOTIFICATION_PARENT.ALL) {
            it.checked = false;
          }

          if (item.parent === NOTIFICATION_PARENT.PAYMENT && it.value === NOTIFICATION_PARENT.PAYMENT) {
            let checkedNumber = 3;
            if (get(permission, 'is_allow_refund', false)) checkedNumber++;
            if (get(permission, 'trial_package', false)) checkedNumber++;

            const isCheckedLastPayment =
              !item.checked &&
              filters.filter(obj => obj.parent === item.parent && obj.checked).length === checkedNumber;

            it.checked = isCheckedLastPayment;
          }

          if (item.parent === NOTIFICATION_PARENT.WAITLIST && it.value === NOTIFICATION_PARENT.WAITLIST) {
            const isCheckedLastWaitlist =
              !item.checked && filters.filter(obj => obj.parent === item.parent && obj.checked).length === 4;
            it.checked = isCheckedLastWaitlist;
          }

          if (item.parent === NOTIFICATION_PARENT.INTRO_CALL && it.value === NOTIFICATION_PARENT.INTRO_CALL) {
            const isCheckedLastIntroCall =
              !item.checked && filters.filter(obj => obj.parent === item.parent && obj.checked).length === 4;
            it.checked = isCheckedLastIntroCall;
          }

          return it;
        });
        const allParents = changeFilters.filter(
          obj =>
            obj.checked &&
            [NOTIFICATION_PARENT.PAYMENT, NOTIFICATION_PARENT.WAITLIST, NOTIFICATION_PARENT.INTRO_CALL].includes(
              obj.value,
            ),
        );
        if (allParents.length === 3) changeFilters[0].checked = true;
        break;
    }

    let types = getFilterTypes(changeFilters);
    types = types.filter(
      type =>
        ![NOTIFICATION_PARENT.PAYMENT, NOTIFICATION_PARENT.WAITLIST, NOTIFICATION_PARENT.INTRO_CALL].includes(type),
    );
    setFilters(changeFilters);

    if (types.length) {
      getActivities({ types: types, pageSize: DEFAULT_PER_PAGE, isRefresh: true }, isMP);
    } else {
      getActivities({ pageSize: DEFAULT_PER_PAGE, isRefresh: true }, isMP);
    }
  };

  const handleCollapseSection = item => () => {
    if (
      ![NOTIFICATION_PARENT.PAYMENT, NOTIFICATION_PARENT.WAITLIST, NOTIFICATION_PARENT.INTRO_CALL].includes(item.value)
    )
      return;
    const changeFilters = filters.map(it => {
      if (item.value === it.value) {
        it.isCollapse = !item.isCollapse;
      }
      return it;
    });
    setFilters(changeFilters);
  };

  const handleSearch = (event, { value }) => {
    const types = getFilterTypes(filters);
    getActivities({ q: value, pageSize: DEFAULT_PER_PAGE, isRefresh: true, types: types }, isMP);
    setSearchValue(value);
  };

  const handleClearSearch = () => {
    handleSearch(null, { value: '' });
    setSearchValue('');
  };

  const handleScrollEnd = event => {
    const tolerancePercentage = 5;
    const tolerance = (tolerancePercentage / 100) * event.target.clientHeight;
    const bottom = event.target.scrollTop + event.target.clientHeight + tolerance >= event.target.scrollHeight;
    const types = getFilterTypes(filters);
    if (bottom && !loading) {
      getActivities(
        {
          pageSize: DEFAULT_PER_PAGE,
          types: types,
          q: searchValue,
        },
        isMP,
      );
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const handleChangeTab = value => {
    if (value === activeTab) return;
    setActiveTab(value);
    setFilters(getFilteredOptions(!isMP, permission));
  };

  const renderFilter = () => {
    return (
      <S.FilterContainer>
        <S.Title>Filter</S.Title>
        {filters.map((item, index) => (
          <Checkbox
            key={`${item.value}-${index}`}
            label={item.title}
            className="select-checkbox"
            onChange={handleChangeFilter(item)}
            checked={item.checked}
          />
        ))}
      </S.FilterContainer>
    );
  };

  const renderMarketplaceFilter = () => {
    return (
      <S.FilterContainer>
        <S.Title>Filter</S.Title>
        {filters.map((item, index) => {
          const cbParent = filters.find(it => it.value === item.parent);
          return (
            <Fragment key={index}>
              {item.hasOwnProperty('isCollapse') ? <S.VerticalLine /> : null}
              <S.ItemWrapper
                className={classNames({
                  'hidden-checkbox': cbParent && cbParent.isCollapse,
                  'last-item': item.value === NOTIFICATION_PARENT.INTRO_CALL && item.isCollapse,
                })}
                isCollapsed={item.hasOwnProperty('isCollapse') && item.isCollapse}
                onClick={handleCollapseSection(item)}
              >
                <Checkbox
                  key={`${item.value}-${index}`}
                  label={item.title}
                  className={classNames('select-checkbox', {
                    'sub-checkbox': item.hasOwnProperty('parent'),
                  })}
                  onChange={e => handleChangeMarketplaceFilter(e, item)}
                  checked={item.checked}
                />
                {item.hasOwnProperty('isCollapse') ? <ArrowIcon /> : null}
              </S.ItemWrapper>
            </Fragment>
          );
        })}
      </S.FilterContainer>
    );
  };

  return (
    <UpgradePath pathName="payment" fallback={<NoAccessPaymentActivity />}>
      <S.Wrapper>
        <S.Container>
          {hasMP ? (
            <S.TabsWrapper>
              {Object.values(ACTIVITY_TABS).map(value => (
                <S.TabItem key={value} active={activeTab === value} onClick={() => handleChangeTab(value)}>
                  {value}
                </S.TabItem>
              ))}
            </S.TabsWrapper>
          ) : null}
          <S.ContentWrapper>
            <S.FilterWrapper collapsedNumber={collapsedNumber}>
              {isMP ? renderMarketplaceFilter() : renderFilter()}
            </S.FilterWrapper>
            <S.Content>
              <S.HeaderWrapper>
                <NewSearchInput
                  placeholder="Search by name or keyword"
                  className="search"
                  onChange={permission.payment && debounce(handleSearch, 300)}
                  onClearSearch={permission.payment && handleClearSearch}
                />
              </S.HeaderWrapper>
              <S.ListWrapper
                onScroll={event => {
                  event.persist();
                  onScrollDebounce.call(null, event);
                }}
              >
                {!groups.length && !loading && <S.Empty>No records available</S.Empty>}
                {!!groups.length &&
                  groups.map((group, index) => (
                    <Group
                      user={user}
                      account={payment.account}
                      group={group}
                      key={`activity-group-${index}`}
                      isMP={isMP}
                    />
                  ))}
                {loading && (
                  <S.IndicatorWrapper>
                    <Loader active inline="centered" className="custom-indicator">
                      <span>Loading activities</span>
                    </Loader>
                  </S.IndicatorWrapper>
                )}
              </S.ListWrapper>
            </S.Content>
          </S.ContentWrapper>
        </S.Container>
      </S.Wrapper>
    </UpgradePath>
  );
};

const mapState = state => {
  const {
    user,
    rootReducer: { activity, setupPayment, permission },
  } = state;

  const { groups, loading } = activity.toJS();
  return { groups: groups, loading, payment: setupPayment.toJS(), permission, user };
};

const actionCreators = {
  getActivities: getActivities,
  push,
  getConnectedAccount,
};

export default connect(mapState, actionCreators)(Activity);
