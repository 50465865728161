import styled, { css } from 'styled-components';
import { Button as BT } from 'shared/FormControl';
import ConfirmModal from 'shared/ConfirmModal';

export const Button = styled(BT)`
  &.button--assignProgram {
    padding: 5px 25px;
    background-color: #5158cf;
    border-color: transparent;
    color: #fff;

    :hover {
      background-color: #5559ff;
    }

    &.assigned {
      background: #fff;
      border: 1px solid #d6dae4;
      color: #818da1;

      svg {
        path {
          fill: #818da1;
        }
      }

      :hover {
        background-color: #5559ff;
        color: #fff;
        border-color: transparent;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
`;

export const AddWeekButton = styled(BT)`
  background: #e9edf0;
  padding: 5px 20px;
  color: #718094;
  border-color: transparent;

  :hover {
    color: #fff;
    background-color: #5158cf;
  }

  ${props =>
    props.isDisableAddWeek &&
    css`
      &:hover {
        color: #718094;
        background-color: #e9edf0;
      }
    `}

  :disabled {
    background: #e9edf0;
  }
`;

export const AddWeekGuide = styled.div`
  color: #fff;
  opacity: 0.6;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 13px 20px 9px;
  max-width: 200px;
  svg {
    margin: 2px 8px 0 0;
    flex: 0 0 14px;
  }
`;

export const CalendarActionsContent = styled.div`
  flex: 1;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16.5px;
  padding-right: 4px;
  border-right: 1px solid #202353;

  svg {
    cursor: pointer;
  }

  svg:hover {
    path {
      stroke: #5158cf;
    }
  }

  .__react_component_tooltip.place-right {
    margin-left: 13px;
  }

  .__react_component_tooltip.type-dark.place-right:after {
    left: -5px;
  }

  .__react_component_tooltip.app-tooltip {
    padding: 5px 15px;
    font-size: 13px;
  }
`;

export const RemoveWrapper = styled.div`
  margin-left: 5px;
`;

export const ActionWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const WeekTitle = styled.div`
  position: absolute;
  top: 3px;
  left: -7px;
  z-index: 1;
`;

export const WeekAction = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 28px;
  width: 100%;
  height: 100%;
  margin-bottom: 4px;

  svg {
    cursor: pointer;
  }

  &:hover {
    opacity: 1 !important;
    ${CalendarActionsContent} {
      opacity: 1 !important;
      display: flex;
      background-color: #e7e8ff;
      border-radius: 2px;
      border-right: unset;
    }
  }
`;

export const RenderAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 28px;
`;

export const BottomCopyWeekWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 23px 32.5px 22px 23px;
  z-index: 11;
  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
  }
  .cancel-copy {
    cursor: pointer;
    margin-left: 9px;
  }
`;

export const BottomSelectWorkoutWrapper = styled.div`
  background-color: #ffffff;
  max-height: 61px;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px 30px 20px 22px;
  z-index: 11;
`;

export const BottomNumberSelect = styled.div`
  width: 156px;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 5px;
  color: #333333;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
`;

export const BottomNumber = styled.span`
  height: 21px;
  width: 43px;
  background-color: #303030;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
`;
export const BottomText = styled.span``;

export const BottomActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-right: 52px;
  margin-bottom: 1px;

  .tooltip--remove,
  .tooltip--copy {
    width: 100px;
  }
`;

export const IconActions = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextActions = styled.span`
  color: #728096;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const BottomActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    ${IconActions} {
      svg {
        path {
          fill: #5e59ff;
        }
      }
    }
    ${TextActions} {
      color: #5e59ff;
    }
  }
`;

export const BottomCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  svg {
    cursor: pointer;
  }
`;

export const TextCancel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const CancelBold = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const IconWrapper = styled.div`
  margin-left: 5px;
  svg:hover {
    cursor: pointer;
    svg path {
      stroke: #5158cf;
    }
  }
`;

export const RemoveAllWorkoutInWeekPopup = styled(ConfirmModal)`
  .ui.button.confirm-yes-button:hover {
    opacity: 0.9 !important;
  }

  .ui.button.confirm-no-button:hover {
    background-color: #f5f7f9 !important;
  }
`;

export const RemoveMultipleWorkoutPopup = styled(ConfirmModal)`
  height: 200px !important;
  max-height: 200px !important;
  border-radius: 8px !important;

  .confirm-popup-container .confirm-content-header {
    padding: 30px 30px 15px !important;
  }

  .confirm-header-image {
    width: 25px !important;
    height: 25px !important;
  }

  .ui.button.confirm-no-button {
    width: 102px !important;
    min-width: 102px !important;
  }

  .ui.button.confirm-yes-button {
    width: 111px !important;
    min-width: 111px !important;
  }

  .ui.button {
    font-family: 'Open Sans' !important;
  }

  .ui.button.confirm-yes-button:hover {
    opacity: 0.9 !important;
  }

  .ui.button.confirm-no-button:hover {
    background-color: #f5f7f9 !important;
  }
`;
