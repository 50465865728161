import React, { useMemo } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import TIME_OPTIONS from 'constants/time-options';

import * as S from './styles';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-outline.svg';

const CustomTimeOption = ({ value, onChange, errorMessage }) => {
  const findOption = useMemo(() => {
    return TIME_OPTIONS.find(item => item.label === value);
  }, [value]);

  return (
    <S.CustomTimeOptionWrapper>
      <Select
        options={TIME_OPTIONS}
        components={{
          DropdownIndicator: () => <ArrowDown />,
          IndicatorSeparator: null,
        }}
        placeholder="Choose time"
        onChange={onChange}
        classNamePrefix="evfSelectBox"
        className={classNames('evfSelectBoxContainer', !!errorMessage ? 'error' : '')}
        value={findOption}
        isSearchable={false}
        getOptionLabel={option => option.label}
        getOptionValue={option => option.label}
        menuPlacement="top"
        menuPosition="absolute"
      />
    </S.CustomTimeOptionWrapper>
  );
};

export default CustomTimeOption;
