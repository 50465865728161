import React from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  convertDataAddLocal,
  convertDataCancelUpload,
  convertDataGiphyAndText,
  convertDataUploadSuccess,
  convertDataViewMore,
  getAttachmentIDFromUrl,
  initDataList,
  initLocalDataFromS3,
} from 'helpers/replyComment';
import Loader from 'shared/Loader';
import { Mixpanel } from 'utils/mixplanel';
import { mediaLog } from 'utils/commonFunction';
import CommentReplyItem from 'shared/CommentReplyItem';
import { TYPES as COMMENT_TYPES } from 'shared/ChatInput';
import DropZonePlaceholder from 'shared/DropZonePlaceholder';
import { getReplyMessage } from 'components/CommunityForum/helper';
import { CDN_URL, MEDIA_FILE_REJECTED_CONTENT, TOPICS } from 'constants/commonData';
import { addCommentForTask, getCommentsTopic, getListReplies } from 'redux/task/task.actionCreators';
import { uploadMediaBackgroundRequest, uploadMediaBackgroundSuccess } from 'redux/upload-background/actions';

import * as S from './style';

class CommentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total_comments: 0,
      replyMessage: {},
      loading: false,
      loadingComment: false,
      listMediaUpload: [],
    };

    this.bodyRef = React.createRef();
    this.inputRef = React.createRef();
    this.addedComment = false;
    this.cancelAddComment = false;
    this.isScrolledBottom = false;
  }

  componentDidMount() {
    const { getCommentsTopic, taskId } = this.props;
    this.setState({ loading: true });
    getCommentsTopic(taskId, TOPICS.FORM_QUESTIONNAIRES)
      .then(response => {
        const { data, total_comments } = response.data;
        this.setState({ list: initDataList(data), total_comments, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });

    window.addEventListener('beforeunload', this.windowBeforeUnloadCallback);
  }

  componentDidUpdate(prevProps) {
    if (!this.isScrolledBottom && this.props.showing && !prevProps.showing) {
      this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
      this.isScrolledBottom = true;
    }
    this.inputRef.current && this.inputRef.current.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.windowBeforeUnloadCallback);
  }

  windowBeforeUnloadCallback = event => {
    const { list } = this.state;
    const uploading = _.filter(list, c => !!c.uploadConfigs);

    if (uploading.length) {
      if (event) {
        event.returnValue = 'Changes you made may not be saved.';
      }

      return 'Changes you made may not be saved.';
    }
  };

  addLocalCommentToList = (localComments, replyMessage) => {
    const { list, listMediaUpload } = this.state;
    const { uploadMediaBackgroundRequest } = this.props;

    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');
    const ids = localComments.map(item => item._id);
    if (uploadMediaBackgroundRequest) {
      uploadMediaBackgroundRequest(ids);
    }

    const listMediaUploadConvert = listMediaUpload.concat(localComments.map(item => item._id));
    this.setState(
      {
        listMediaUpload: listMediaUploadConvert,
        list: isReply ? convertDataAddLocal(list, commentId, localComments) : list.concat(localComments),
      },
      () => {
        setTimeout(() => {
          if (!isReply) {
            this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
          }
        }, 300);
      },
    );
  };

  onSubmit = (comment, callback) => {
    const { replyMessage, list, loadingComment } = this.state;
    const { addCommentForTask, taskId, user } = this.props;
    const { type, data } = comment;
    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');

    if (type === COMMENT_TYPES.TEXT && !loadingComment) {
      this.setState({ loadingComment: true });
      const body = {
        item: isReply ? commentId : taskId,
        topic: isReply ? TOPICS.REPLY_COMMENT : TOPICS.FORM_QUESTIONNAIRES,
        content: data,
      };

      try {
        Mixpanel.track('forms_questionnaires_add_new_comment', { user_type: 'trainer' });
      } catch (error) {
        console.error('MixPanel Tracking Error', error);
      }

      addCommentForTask(body)
        .then(response => {
          this.addedComment = true;
          const { data, total_comment } = response.data;
          const isCommentReply = _.get(data, 'topic', '') === 'comment';
          let newList = [];

          if (isCommentReply) {
            newList = convertDataGiphyAndText(list, data, commentId);
          } else {
            newList = [...list, data];
          }

          this.setState({ list: newList, total_comments: total_comment }, () => {
            if (!isCommentReply) {
              this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
            }
          });
          this.handleCancelReply();
          callback && callback();
          this.setState({ loadingComment: false });
        })
        .catch(() => {
          this.setState({ loadingComment: false });
        });
    }

    if (type === COMMENT_TYPES.FILE) {
      const localComments = _.map(data, (item, index) => initLocalDataFromS3({ user, item, index, replyMessage }));
      this.addLocalCommentToList(localComments, replyMessage);
    }
    if (type !== COMMENT_TYPES.TEXT) {
      this.handleCancelReply();
    }
  };

  onDrop = files => {
    if (files.length && this.inputRef.current) {
      typeof this.inputRef.current.onDropFiles === 'function' && this.inputRef.current.onDropFiles(files);
    }
  };

  cancelUpload = (comment, error) => {
    if (!this.cancelAddComment) {
      const { replyMessage, _id = '' } = comment;
      const { list, listMediaUpload } = this.state;
      const { uploadMediaBackgroundSuccess } = this.props;
      if (uploadMediaBackgroundSuccess && _id) {
        uploadMediaBackgroundSuccess([_id]);
      }
      const listMediaUploadConvert = listMediaUpload.filter(item => item !== _id);
      this.setState({ listMediaUpload: listMediaUploadConvert });
      const isReply = !_.isEmpty(replyMessage);
      if (isReply) {
        this.setState({ list: convertDataCancelUpload(list, comment) });
      } else {
        this.setState({ list: _.filter(list, c => c._id !== comment._id) });
      }
    }
  };

  uploadSuccess = (item, responseData) => {
    const { taskId, addCommentForTask, uploadMediaBackgroundSuccess } = this.props;

    const { replyMessage } = item;
    const commentId = _.get(replyMessage, 'commentId', '');
    const isReply = !_.isEmpty(replyMessage);
    const comment = isReply ? item : _.find(this.state.list, c => c._id === item._id);

    if (uploadMediaBackgroundSuccess && _.get(item, '_id', '')) {
      uploadMediaBackgroundSuccess([item._id]);
    }

    if (!comment || this.cancelAddComment) {
      return false;
    }

    if ((responseData || {}).status === 200) {
      const { attachment_type, attachment_height, attachment_width, uploadConfigs } = comment;
      const url = (uploadConfigs || {}).url || '';

      const body = {
        attachment: url,
        attachment_id: getAttachmentIDFromUrl(url),
        attachment_type,
        attachment_height,
        attachment_width,
        topic: isReply ? TOPICS.REPLY_COMMENT : TOPICS.FORM_QUESTIONNAIRES,
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : taskId,
      };

      mediaLog({
        status: 2,
        name: url,
        description: 'Upload success file via comment form & questionnaires',
        fileType: attachment_type,
      });

      addCommentForTask(body).then(response => {
        const { list, listMediaUpload } = this.state;
        this.addedComment = true;
        const { data, total_comment } = response.data;
        let listToUpdate = [];

        if (isReply) {
          listToUpdate = convertDataUploadSuccess(list, data, comment);
        } else {
          listToUpdate = _.map(list, item => (item._id === comment._id ? { ...data, loadingReply: false } : item));
        }
        const listMediaUploadConvert = listMediaUpload.filter(i => i !== item._id);

        this.setState(
          { listMediaUpload: listMediaUploadConvert, list: listToUpdate, total_comments: total_comment },
          () => {
            if (!isReply) {
              setTimeout(() => {
                this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
              }, 300);
            }
          },
        );
      });
    }
  };

  onGifClick = gif => {
    const { list, replyMessage } = this.state;
    const { taskId, addCommentForTask } = this.props;
    const url = _.get(gif, 'images.original.url');
    const id = _.get(gif, 'id');
    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');
    if (!_.isEmpty(url) && !_.isEmpty(id)) {
      const body = {
        topic: isReply ? TOPICS.REPLY_COMMENT : TOPICS.FORM_QUESTIONNAIRES,
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : taskId,
        attachment: url,
        attachment_id: id,
        attachment_type: 'image/gif',
        attachment_source: 'giphy',
      };

      let newList = [];

      this.handleCancelReply();

      addCommentForTask(body).then(response => {
        this.addedComment = true;
        const { data, total_comment } = response.data;
        const isCommentReply = _.get(data, 'topic', '') === 'comment';

        if (isCommentReply) {
          newList = convertDataGiphyAndText(list, data, commentId);
        } else {
          newList = [...list, data];
        }
        this.setState({ list: newList, total_comments: total_comment }, () => {
          if (!isCommentReply) {
            this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
          }
        });
      });
    }
  };

  handleGetListViewMore = params => {
    const { getListReplies } = this.props;
    const { list } = this.state;

    getListReplies(params).then(res => {
      const data = _.get(res, 'data.data', []).reverse();
      this.setState({ list: convertDataViewMore(list, params, data) });
    });
  };

  handleReplyMessage = data => () => {
    const { user } = this.props;
    const newData = { ...data, userId: _.get(user, '_id', '') };
    const { type, content, owner, commentId } = getReplyMessage(newData);

    this.setState({
      replyMessage: {
        type,
        content,
        owner,
        commentId,
      },
    });
  };

  handleCancelReply = () => {
    this.setState({
      replyMessage: {},
    });
  };

  render() {
    const { list, total_comments, replyMessage, loading } = this.state;
    const { user, showing } = this.props;

    return (
      <S.Wrapper showing={showing}>
        <Dropzone onDrop={this.onDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <S.Container {...getRootProps()} empty={!list.length}>
                <S.Header>{`Comments${total_comments ? ` (${total_comments})` : ''}`}</S.Header>
                <S.Body ref={this.bodyRef} data-list="list-comment">
                  {loading ? (
                    <S.LoaderWrapper>
                      <Loader />
                    </S.LoaderWrapper>
                  ) : (
                    <>
                      {list && list.length > 0 ? (
                        <S.ListCommentWrapper>
                          {_.map(list, item => {
                            const { latestReplyList } = item;
                            const { _id = '' } = item;
                            return (
                              <CommentReplyItem
                                dataIndex={_id}
                                key={_id}
                                item={item}
                                onReplyMessage={this.handleReplyMessage}
                                showAttachItem={true}
                                onUploadSuccess={this.uploadSuccess}
                                onUploadFail={data => {}}
                                onCancelUpload={_.debounce(this.cancelUpload, 300)}
                                onGetListViewMore={this.handleGetListViewMore}
                                replyList={latestReplyList}
                              />
                            );
                          })}
                        </S.ListCommentWrapper>
                      ) : (
                        <S.LeaveAComment data-empty="empty-comment">
                          <img src={`${CDN_URL}/images/comment_placeholder.svg`} alt="" />
                          <h4>Leave a comment</h4>
                          <p>Share a message with your client</p>
                        </S.LeaveAComment>
                      )}
                    </>
                  )}
                </S.Body>
                <S.Footer>
                  <S.WrapperInput
                    placeholder={!_.isEmpty(replyMessage) ? 'Write a reply...' : 'Add a comment..'}
                    onPost={this.onSubmit}
                    userId={user._id}
                    ref={this.inputRef}
                    autoFocus={true}
                    giphyEnable={true}
                    onGifClick={this.onGifClick}
                    replyMessage={replyMessage}
                    onCancelReply={this.handleCancelReply}
                  />
                </S.Footer>
                <input {...getInputProps({ onClick: event => event.preventDefault() })} />
                <DropZonePlaceholder show={isDragActive} />
              </S.Container>
            );
          }}
        </Dropzone>
      </S.Wrapper>
    );
  }
}

const mapState = state => ({ user: state.user });

const mapDispatchToProps = dispatch => ({
  getCommentsTopic: bindActionCreators(getCommentsTopic, dispatch),
  addCommentForTask: bindActionCreators(addCommentForTask, dispatch),
  getListReplies: bindActionCreators(getListReplies, dispatch),
  uploadMediaBackgroundRequest: bindActionCreators(uploadMediaBackgroundRequest, dispatch),
  uploadMediaBackgroundSuccess: bindActionCreators(uploadMediaBackgroundSuccess, dispatch),
});

export default connect(mapState, mapDispatchToProps)(CommentList);
