import React from 'react';
import { components } from 'react-select';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down_outline.svg';

const DefaultDropdownIndicator = args => (
  <components.DropdownIndicator {...args}>
    <ArrowDownIcon
      style={{
        color: '#000000',
        width: 11,
        height: 11,
        marginRight: 6,
      }}
    />
  </components.DropdownIndicator>
);

export default DefaultDropdownIndicator;
