import { connect } from 'react-redux';
import _ from 'lodash';

import LogWorkoutModalComponent from './component';
import { recordAssignment, updateTrackingData } from 'redux/assignment/assignment.actionCreators';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => {
  const { isModalOpen, rootReducer, deletingExerciseSet } = state;
  const selectedClient = rootReducer.client.workingClientDetail;
  return {
    isModalOpen,
    selectedClient: rootReducer.client.selected,
    selectedClientData: selectedClient,
    deletingExerciseSet,
    fields: rootReducer.exercise.fields,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    recordAssignment: bindActionCreators(recordAssignment, dispatch),
    updateTrackingData: bindActionCreators(updateTrackingData, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogWorkoutModalComponent);
