import { Modal } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Button } from 'shared/FormControl';

const TextBase = css`
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 150%;
`;

export const EditPopupStyled = styled(Modal)`
  &.ui.modal {
    width: 499px;
    min-height: 275px;
    border-radius: 8px;
  }

  &.ui.modal > :last-child {
    padding: 0 !important;
    border-radius: 8px;
  }

  .goal-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 140px;
    padding-right: 6px;

    .edit-goal-input {
      font-size: 15px;
      font-weight: 400;
      line-height: 22.5px;
      padding: 7px 12px 12px 20px;
      min-height: 140px;
      resize: none;
      width: 100% !important;
      outline: none !important;
      border: none !important;
      color: #202353 !important;

      &::-webkit-scrollbar {
        width: 6px !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #00000026 !important;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 66px;
    padding: 15px 20px;
    gap: 16px;
    border-radius: 0px 0px 8px 8px;
    opacity: 0px;
    border-top: 1px solid #f0f0f2;
  }
`;

export const PopupTitle = styled.div`
  ${TextBase}
  font-size: 19px;
  line-height: 28.5px;
  text-align: left;
  padding: 20px;
  height: 69px;
`;

export const CreateButton = styled(Button)`
  display: flex;
  padding: 10px 20px;
  min-height: 36px;
  height: 36px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 19.5px;
  font-weight: 600;
`;
