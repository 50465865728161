import React, { useState } from 'react';
import _ from 'lodash';
import TimeField from 'react-simple-timefield';
import { Input, Image } from 'semantic-ui-react';
import {
  convertSecondToTimeString,
  convertTimeStringToSecond,
  convertSecondToTimeStringForRest,
  convertTimeStringToSecondForRest,
} from 'helpers/time';
import { roundTwoDigit } from 'helpers/number';
import { convertUnit } from 'utils/commonFunction';
import RepRangeInput from 'shared/RepRangeInput';
import RepRangeNumber from 'shared/RepRangeInput/RepRangeNumber';
import { ReactComponent as MarkAllIcon } from 'assets/icons/check_all_grey.svg';
import { CDN_URL } from 'constants/commonData';

import * as S from './styles';

const FIELD_LIMIT = {
  hr: {
    max: 200,
  },
  rir: {
    max: 500,
  },
  rep: {
    max: 999,
  },
  cadence: {
    max: 9999.99,
  },
  heart_rate: {
    max: 9999.99,
  },
  round: {
    max: 500,
  },
  weight: {
    max: 9999.99,
  },
  speed: {
    max: 9999.99,
  },
  distance: {
    max: 9999.99,
  },
  distance_short: {
    max: 9999.99,
  },
  calories: {
    max: 9999.99,
  },
  rpm: {
    max: 9999.99,
  },
  watts: {
    max: 500.9,
  },
};

export function renderFieldInput(fieldObj, setIndex, disabled, isCompletedSet, isLogWorkout) {
  let time, displayValue, inputRepRangeValue;
  const fieldData = fieldObj || { value: 0, unit: '' };

  switch (fieldData.data_type) {
    case 'float':
      displayValue = roundTwoDigit(parseFloat(fieldData.value));
      displayValue = isNaN(displayValue) ? '' : displayValue;

      if (disabled) {
        return (
          <Input
            className={'ui transparent input center set-input'}
            transparent
            type="number"
            step="0.1"
            disabled
            value={displayValue}
            placeholder={'-'}
          />
        );
      } else {
        return (
          <Input
            className={'ui transparent input center set-input'}
            transparent
            type="number"
            step="0.1"
            value={displayValue}
            placeholder={'-'}
            onChange={(evt, data) => {
              const fieldMaxLimit = _.get(FIELD_LIMIT, `${fieldData.unique_code}.max`);
              if (fieldMaxLimit && data.value > fieldMaxLimit) return false;

              this.updateSetData(setIndex, fieldData.unique_code, data.value);
            }}
            onFocus={evt => evt.target.select()}
            error={isCompletedSet && (displayValue === '' || displayValue === null || displayValue === undefined)}
          />
        );
      }

    case 'int':
      if (disabled) {
        return (
          <Input
            className={'ui transparent input center set-input'}
            transparent
            type="number"
            disabled
            defaultValue={fieldData.value}
            placeholder={'-'}
          />
        );
      }
      if (fieldData.unique_code === 'reps' && !isLogWorkout) {
        return (
          <RepRangeInput
            className="fieldInput"
            defaultValue={fieldData.value}
            placeholder="-"
            onChange={value => {
              this.updateSetData(setIndex, fieldData.unique_code, value);
            }}
            disabled={disabled}
            onFocus={event => event.target.select()}
            error={
              isCompletedSet && (fieldData.value === '' || fieldData.value === null || fieldData.value === undefined)
            }
          />
        );
      }

      if (['reps', 'round'].includes(fieldData.unique_code) && isLogWorkout) {
        const value = isCompletedSet || /^\d+$/.test(fieldData.value) ? fieldData.value : '';
        return (
          <Input
            className={'ui transparent input center set-input'}
            transparent
            placeholder={fieldData.value || '-'}
            onChange={(evt, data) => {
              if (data.value !== '' && data.value !== '-' && isNaN(parseInt(data.value))) {
                return false;
              }
              if (data.value > FIELD_LIMIT.rep.max) {
                return false;
              }
              this.updateSetData(setIndex, fieldData.unique_code, data.value, true);
            }}
            value={value}
            step="1"
            onFocus={evt => evt.target.select()}
            error={
              isCompletedSet && (!fieldData.value || parseInt(fieldData.value) === 0 || !/^\d+$/.test(fieldData.value))
            }
            isCompletedSet={isCompletedSet}
          />
        );
      }

      displayValue = isNaN(parseInt(fieldData.value)) ? '' : parseInt(fieldData.value);

      return (
        <Input
          className={'ui transparent input center set-input'}
          transparent
          value={displayValue}
          type="number"
          step="1"
          placeholder={'-'}
          onChange={(evt, data) => {
            if (data.value !== '' && isNaN(parseInt(data.value))) {
              return false;
            }
            const fieldMaxLimit = _.get(FIELD_LIMIT, `${fieldData.unique_code}.max`);
            if (fieldMaxLimit && data.value > fieldMaxLimit) return false;

            this.updateSetData(setIndex, fieldData.unique_code, data.value === '' ? data.value : parseInt(data.value));
          }}
          onFocus={evt => evt.target.select()}
          error={isCompletedSet && (displayValue === '' || displayValue === null || displayValue === undefined)}
        />
      );

    case 'time':
      const isRest = fieldData.unique_code === 'rest';
      time = isRest ? convertSecondToTimeStringForRest(fieldData.value) : convertSecondToTimeString(fieldData.value);

      return (
        <div className="ui transparent input ui transparent input center set-input">
          <TimeField
            value={time}
            onChange={value =>
              this.updateSetData(
                setIndex,
                fieldData.unique_code,
                isRest ? convertTimeStringToSecondForRest(value) : convertTimeStringToSecond(value),
              )
            }
            className={'ui transparent input center set-input'}
            colon=":"
            showSeconds={!isRest}
          />
        </div>
      );

    case 'is_completed':
      if (fieldData.value) {
        return (
          <div>
            {isLogWorkout ? (
              <S.TrackingChecked isChecked={true}>
                <MarkAllIcon onClick={() => this.updateSetCompleted(setIndex, false)} />
              </S.TrackingChecked>
            ) : (
              <Image
                width={15}
                height={15}
                src={`${CDN_URL}/images/checkbox_checked.svg`}
                onClick={() => this.updateSetCompleted(setIndex, false)}
                className="set-complete-checkbox"
              />
            )}
          </div>
        );
      } else {
        return (
          <div>
            {isLogWorkout ? (
              <S.TrackingChecked isChecked={false}>
                <MarkAllIcon onClick={() => this.updateSetCompleted(setIndex, true)} />
              </S.TrackingChecked>
            ) : (
              <Image
                width={15}
                height={15}
                src={`${CDN_URL}/images/checkbox_empty.svg`}
                onClick={() => this.updateSetCompleted(setIndex, true)}
                className="set-complete-checkbox"
              />
            )}
          </div>
        );
      }

    case 'range':
      displayValue = fieldData.value;
      return (
        <Input
          className={'ui transparent input center set-input'}
          transparent
          type="text"
          step="0.1"
          value={displayValue}
          placeholder={'-'}
          onChange={(evt, data) => {
            let regex = /^$|^(\d+-?\d*)$/;
            if (regex.test(data.value)) {
              this.updateSetData(setIndex, fieldData.unique_code, data.value);
            } else {
              console.log('Failed');
            }
          }}
          onFocus={evt => evt.target.select()}
          error={isCompletedSet && (displayValue === '' || displayValue === null || displayValue === undefined)}
        />
      );

    default:
      displayValue = fieldData.value;
      return (
        <Input
          className={'ui transparent input center set-input'}
          transparent
          value={roundTwoDigit(displayValue)}
          placeholder={'-'}
          onChange={(evt, data) => {
            this.updateSetData(setIndex, fieldData.unique_code, data.value);
          }}
          onFocus={evt => evt.target.select()}
          error={isCompletedSet && (displayValue === '' || displayValue === null || displayValue === undefined)}
        />
      );
  }
}

export const autoFillInput = (set, keys) => {
  _.forEach(keys, key => {
    const fieldSet = _.get(set, key);
    if (fieldSet && !fieldSet.value) {
      set[key].value = 0;
    }
  });
};
