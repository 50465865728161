import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Avatar from 'react-avatar';
import { DropdownIndicator } from 'shared/Icons';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import { bindActionCreators } from 'redux';
import { getProfile } from 'actions/profile';
import * as S from './styles';

const SelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select__option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  return (
    <S.ContactInformation {...innerProps} className={className}>
      <Avatar name={getUserShortName(data)} size="40" src={data.avatar_format} color={data.color} />
      <S.SingleLabel className="select__option__label">
        <div className="name">
          {data.first_name} {data.last_name}
        </div>
        <div className="email">{data.email}</div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const SingleValue = ({ innerProps, data }) => {
  return (
    <S.ContactInformation {...innerProps}>
      <Avatar name={getUserShortName(data)} size="32" src={data.avatar_format} color={data.color} />
      <S.SingleLabel className="select__option__label">
        <div className="name">
          {data.label}
          {data.isMe && <span className="is-me">&nbsp;(You)</span>}
        </div>
      </S.SingleLabel>
    </S.ContactInformation>
  );
};

const AssignedTrainer = ({ user, onChange, trainer, isEditMode, getProfile, cloudfrontList }) => {
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    getProfile && getProfile({ isUpdated: true });
  }, []);

  const { options, objectValue } = useMemo(() => {
    const options = (user.team_member || []).map(item => {
      const avatar_format = convertS3UrlToCloudFrontUrl(item.avatar, cloudfrontList, true);

      return {
        ...item,
        key: item._id,
        value: item._id,
        label: `${item.first_name} ${item.last_name}`,
        isMe: item._id === user._id,
        avatar_format,
      };
    });

    const objectValue = options.find(item => item.value === trainer);

    return { options, objectValue };
  }, [user, trainer, cloudfrontList]);

  const handleSelectTrainer = data => {
    if (data.value !== trainer) {
      onChange(data.value);
    }
  };

  const getUrl = useCallback(url => convertS3UrlToCloudFrontUrl(url, cloudfrontList, true), [cloudfrontList]);

  return (
    <S.Wrapper isEditMode={isEditMode && openMenu}>
      <S.Header>Clients will be assigned to</S.Header>
      {isEditMode ? (
        <Select
          options={options}
          onChange={handleSelectTrainer}
          components={{
            Option: SelectOption,
            DropdownIndicator,
            SingleValue: SingleValue,
            IndicatorSeparator: null,
          }}
          placeholder="Choose a team member"
          classNamePrefix="evfSelectBox"
          className="evfSelectBoxContainer"
          value={objectValue}
          isSearchable={false}
          styles={{
            control: base => ({ ...base, minHeight: 52, height: 52 }),
          }}
          onMenuOpen={() => setOpenMenu(true)}
          onMenuClose={() => setOpenMenu(false)}
        />
      ) : (
        <S.ContactInformation>
          <Avatar
            name={getUserShortName(objectValue)}
            size="32"
            color={objectValue.color}
            src={getUrl(objectValue.avatar)}
          />
          <S.SingleLabel>
            <div className="name">
              {objectValue.label}
              {objectValue.isMe && <span className="is-me">&nbsp;(You)</span>}
            </div>
          </S.SingleLabel>
        </S.ContactInformation>
      )}
    </S.Wrapper>
  );
};

const mapState = state => {
  const { user, cloudfrontList } = state;
  return { user, cloudfrontList };
};

const mapDispatchToProps = dispatch => {
  return {
    getProfile: bindActionCreators(getProfile, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(AssignedTrainer);
