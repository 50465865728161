import { Button } from 'shared/FormControl';
import styled from 'styled-components';

export const CountdownListStyled = styled.div`
  .trigger-icon.active {
    background-color: #eaeef1;
  }
  &.countdown-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ isGrayBackground }) => (isGrayBackground ? '#f6f6f6' : '#fff')};
    border-bottom-right-radius: 8px;

    .countdown-heading {
      display: flex;
      height: 58px;
      justify-content: space-between;
      align-items: center;
      padding: 16px 25px;

      .add-btn {
        svg path {
          fill: #5158cf;
        }
        cursor: pointer;
      }

      .header__right-side {
        width: 64px;
        display: flex;
        justify-content: flex-end;
        gap: 16px;

        .pin-icon {
          min-width: 24px;
          cursor: pointer;
        }

        .pin-icon.pinned {
          path {
            fill: #5158cf;
          }
        }
      }

      .back-btn {
        font-weight: 600;
        &:hover {
          background-color: #f0f1ff;
          color: #5158cf;
          cursor: pointer;
          border-radius: 4px;

          svg path {
            fill: #5158cf;
          }
        }
      }

      .back-btn {
        position: relative;
        left: 1px;
        top: 1px;
        font-size: 12px;
        line-height: 18px;
        color: #202353;
        height: 30px;
        padding: 10px 15px;
        display: flex;
        gap: 10px;
        border: none;
        background-color: transparent !important;
        align-items: center;

        svg {
          position: relative;
          left: 1px;
          top: -1px;
          width: 10.39px;
          height: 10.12px;
        }
      }

      .add-btn {
        border: 1px solid #5158cf;
        border-radius: 4px;
        padding: 3px 7px;
        font-size: 13px;
        line-height: 19.5px;
        color: #5158cf;
        cursor: pointer;
        background: transparent;
        font-weight: 600;

        svg {
          position: relative;
          top: 2px;
          left: -1px;
          width: 7px;
        }

        :hover {
          background: transparent;
          border: 1px solid #7773ff;
          color: #7773ff;
          svg path {
            fill: #7773ff;
          }
        }
      }

      h2 {
        font-size: 19px;
        line-height: 28.5px;
        font-weight: 600;
        color: #141414;
        margin-bottom: 0;
      }
    }
    .countdown-list-items {
      flex: 1;
      padding: 0 15px 15px 25px;
      margin-right: 6px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: scroll;
      height: calc(100% - 58px);

      ::-webkit-scrollbar {
        width: 4px !important;
      }
      ::-webkit-scrollbar-track {
        background: #fafbfc !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #e6e8eb !important;
        border-radius: 10px !important;
        cursor: pointer;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #e6e8eb !important;
      }

      .countdown-list-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .loading-countdowns {
          padding-top: 15px;

          .ui.loader:after {
            border-color: #5158cf transparent transparent !important;
          }
        }
      }
    }

    .countdown-footer {
      display: flex;
      justify-content: space-between;
      padding: 10px 25px 10px 10px;
      background-color: #fff;
      border-bottom-right-radius: 8px;
      height: 56px;

      .archived-btn {
        box-shadow: none;
        outline: none;
        background-color: #fff;
        margin-left: 12px;
        border: none;
        display: flex;
        align-items: center;
        gap: 6px;
        color: #7b7e91;

        &:hover {
          background-color: #f0f1ff;
          color: #5158cf;
          cursor: pointer;
          border-radius: 4px;

          svg {
            color: #5158cf;
          }
        }
      }
    }
  }
`;

export const BackIconStyle = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  margin-left: 1px;

  .back-icon {
    min-width: 10.39px;
    height: 10.12px;
    margin-right: 7.67px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  height: 58px;
  align-items: center;
`;

export const MenuActionWrapper = styled.div`
  display: flex;
`;

export const PinSection = styled.div`
  display: flex;
`;

export const MenuAction = styled.div`
  display: flex;
`;

export const CreateButton = styled(Button)`
  display: flex;
  padding: 12.6px 16px;
`;
