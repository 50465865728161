import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

import ButtonIcon from 'shared/ButtonIcon';
import FormItemInput from 'shared/FormItemInput';

export const Button = styled(ButtonIcon)`
  button {
    font-family: 'Open Sans' !important;
    background: #5158cf !important;
    border: none !important;

    svg {
      color: white;
      margin-right: 5px;
    }

    span {
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px;
      border-radius: 5px;
    }
  }
`;

export const CreateAddNewModal = styled(Modal)`
  &.ui.modal {
    width: 452px;

    .close-button {
      right: -8px;
      top: -11px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    ${props =>
      !props.isPassedFirstTimeAdd &&
      css`
        transform: translateY(-20%) !important;
      `}
  }

  .header {
    padding-top: 22px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 0px !important;
    border-radius: 8px;

    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 8px !important;
  }

  .header-title {
    color: #202353 !important;
    font-family: 'Open Sans' !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 25px !important;
  }

  .content {
    padding: 25px 30px 30px 30px !important;
  }

  .formControl {
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .submit {
    padding: 7px 29px !important;
    &:hover,
    &:focus,
    &:active {
      background: #5559ff !important;
    }
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  color: #6a778a;
  margin-bottom: 6px;

  .label__required {
    color: #ea314a;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  margin-top: 4px;
  margin-bottom: 0;
`;

export const Form = styled.form`
  input {
    height: 36px !important;
  }
`;

export const Input = styled(FormItemInput)``;
