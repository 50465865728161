export const SLEEP_STAGE_KEYS = {
  asleep: 'asleep',
  asleep_manual: 'asleep_manual',
  in_bed: 'in_bed',
  deep: 'deep',
  core: 'core',
  rem: 'rem',
  awake: 'awake',
  empty: 'empty',
};

export const SLEEP_ASLEEP_STAGES = [SLEEP_STAGE_KEYS.in_bed, SLEEP_STAGE_KEYS.asleep, SLEEP_STAGE_KEYS.empty];

export const SLEEP_CORE_STAGES = [
  SLEEP_STAGE_KEYS.awake,
  SLEEP_STAGE_KEYS.asleep,
  SLEEP_STAGE_KEYS.rem,
  SLEEP_STAGE_KEYS.core,
  SLEEP_STAGE_KEYS.deep,
];

export const SLEEP_STAGES_TIME_ORDERED = {
  [SLEEP_STAGE_KEYS.awake]: 1,
  [SLEEP_STAGE_KEYS.rem]: 2,
  [SLEEP_STAGE_KEYS.core]: 3,
  [SLEEP_STAGE_KEYS.deep]: 4,
};

export const LEGEND_STAGE_ORDER = [
  SLEEP_STAGE_KEYS.in_bed,
  SLEEP_STAGE_KEYS.awake,
  SLEEP_STAGE_KEYS.asleep,
  SLEEP_STAGE_KEYS.asleep_manual,
  SLEEP_STAGE_KEYS.rem,
  SLEEP_STAGE_KEYS.core,
  SLEEP_STAGE_KEYS.deep,
];

export const SLEEP_COLOR = {
  [SLEEP_STAGE_KEYS.asleep]: '#4CD9CD',
  [SLEEP_STAGE_KEYS.in_bed]: '#DAF1F2',
  [SLEEP_STAGE_KEYS.deep]: '#36349D',
  [SLEEP_STAGE_KEYS.core]: '#3478F6',
  [SLEEP_STAGE_KEYS.rem]: '#72BEF4',
  [SLEEP_STAGE_KEYS.awake]: '#FF7557',
  hover: '#4CD9CD26',
};

export const LINE_CONNECTS_COLOR = [
  [SLEEP_COLOR.awake, SLEEP_COLOR.rem],
  [SLEEP_COLOR.awake, SLEEP_COLOR.rem, SLEEP_COLOR.core],
  [SLEEP_COLOR.awake, SLEEP_COLOR.rem, SLEEP_COLOR.core, SLEEP_COLOR.deep],
  [SLEEP_COLOR.rem, SLEEP_COLOR.core],
  [SLEEP_COLOR.rem, SLEEP_COLOR.core, SLEEP_COLOR.deep],
  [SLEEP_COLOR.core, SLEEP_COLOR.deep],
];

export const SLEEP_COLOR_RANGES = {
  [`${SLEEP_COLOR.awake}-${SLEEP_COLOR.rem}`]: LINE_CONNECTS_COLOR[0],
  [`${SLEEP_COLOR.awake}-${SLEEP_COLOR.core}`]: LINE_CONNECTS_COLOR[1],
  [`${SLEEP_COLOR.awake}-${SLEEP_COLOR.deep}`]: LINE_CONNECTS_COLOR[2],
  [`${SLEEP_COLOR.rem}-${SLEEP_COLOR.core}`]: LINE_CONNECTS_COLOR[3],
  [`${SLEEP_COLOR.rem}-${SLEEP_COLOR.deep}`]: LINE_CONNECTS_COLOR[4],
  [`${SLEEP_COLOR.core}-${SLEEP_COLOR.deep}`]: LINE_CONNECTS_COLOR[5],
};

export const ASLEEP_COLORS_GRADIENT = [SLEEP_COLOR.rem, SLEEP_COLOR.core, SLEEP_COLOR.deep];

export const SLEEP_STATES = {
  [SLEEP_STAGE_KEYS.asleep]: 'Asleep',
  [SLEEP_STAGE_KEYS.in_bed]: 'In bed',
  [SLEEP_STAGE_KEYS.deep]: 'Deep',
  [SLEEP_STAGE_KEYS.core]: 'Core',
  [SLEEP_STAGE_KEYS.rem]: 'REM',
  [SLEEP_STAGE_KEYS.awake]: 'Awake',
};

export const SIZES = {
  OFFSET: 6,
  OFFSET_HOVER: 3,
  Y_POSITION: 141,
  SPACING_OF_TYPE: 5,
  HEIGHT_OF_TYPE: 20,
  SPACING_LEFT: 8,
  SPACING_TOP: 21,
};

export const SLEEP_VIEW = {
  DURATION: 'duration',
  TIME: 'time',
};

export const TIME_RANGE_DATE = [
  {
    value: 0,
    label: '6 PM',
  },
  {
    value: 1,
    label: '5 PM',
  },
  {
    value: 2,
    label: '4 PM',
  },
  {
    value: 3,
    label: '3 PM',
  },
  {
    value: 4,
    label: '2 PM',
  },
  {
    value: 5,
    label: '1 PM',
  },
  {
    value: 6,
    label: '12 PM',
  },
  {
    value: 7,
    label: '11 AM',
  },
  {
    value: 8,
    label: '10 AM',
  },
  {
    value: 9,
    label: '9 AM',
  },
  {
    value: 10,
    label: '8 AM',
  },
  {
    value: 11,
    label: '7 AM',
  },
  {
    value: 12,
    label: '6 AM',
  },
  {
    value: 13,
    label: '5 AM',
  },
  {
    value: 14,
    label: '4 AM',
  },
  {
    value: 15,
    label: '3 AM',
  },
  {
    value: 16,
    label: '2 AM',
  },
  {
    value: 17,
    label: '1 AM',
  },
  {
    value: 18,
    label: '12 AM',
  },
  {
    value: 19,
    label: '11 PM',
  },
  {
    value: 20,
    label: '10 PM',
  },
  {
    value: 21,
    label: '9 PM',
  },
  {
    value: 22,
    label: '8 PM',
  },
  {
    value: 23,
    label: '7 PM',
  },
  {
    value: 24,
    label: '6 PM',
  },
];
