import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';
import { removeClientFromProgram } from 'redux/program_library/program_library.actionCreators';
import ProgramViewClients from './component';

const mapStateToProps = state => {
  const { isModalOpen, rootReducer, user } = state;
  const {
    program_library: { assigned_clients, selected },
  } = rootReducer;

  return {
    isModalOpen,
    clients: cloneDeep(assigned_clients),
    program: selected,
    date_format: user.date_format,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeClientFromProgram: bindActionCreators(removeClientFromProgram, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramViewClients);
