import styled, { keyframes, css } from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';
import { FormGroup as FG, Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export const SaveButton = styled(Button)`
  border: 1px solid #5c5bbd;
  box-sizing: border-box;
  border-radius: 5px;
  color: #5c5bbd;

  :hover {
    background-color: #5c5bbd;
    color: #fff;
  }
`;

export const fadeIn = keyframes`
  0%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const FormGroup = styled(FG)`
  margin-bottom: 30px;

  &.clientMobileApp {
    margin-bottom: 0;
  }
`;

export const Tip = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #868c9a;
  padding-left: 20px;
  background: transparent url(${CDN_URL}/images/new_info.svg) no-repeat;
  background-size: 14px 14px;
  background-position: 0 2px;
  margin-top: 10px;

  .hightlight {
    font-weight: 600;
  }
`;

export const SettingAreaContent = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const SettingArea = styled.div`
  width: 32%;
  min-width: 346px;
  max-width: 480px;
  margin-right: 46px;
`;

export const Phone = styled.div`
  width: ${props => `${props.width || 240}px`};
  height: ${props => `${props.height || 290}px`};
  position: relative;
  overflow: visible;
  background-color: #fff;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;

  * {
    z-index: 3;
    position: relative;
  }

  :before {
    content: '';
    background: transparent url(${CDN_URL}/images/iphone_bg.svg) no-repeat;
    background-position: top left;
    background-size: 100%;
    position: absolute;
    left: -2px;
    top: 0;
    right: -2px;
    bottom: 0;
    z-index: 1;
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 44px;
    background: linear-gradient(180deg, rgba(247, 248, 252, 0) 0%, #f7f8fc 100%);
    z-index: 2;
  }
`;

export const Skeleton = styled.div`
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  background: #ededf1;
  opacity: 0.5;
  border-radius: 1px;
`;

export const Preview = styled.div`
  flex: 1 1;
  overflow: hidden;
  background-color: #f7f8fc;
  padding: 50px;
  min-width: 500px;
`;

export const SectionHeaderTitle = styled.h3`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #202353;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  min-height: 32px;

  .icon {
    margin-right: 12px;
  }
`;

export const SectionDescription = styled.div`
  margin-bottom: 30px;
`;

export const SectionContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: rgba(32, 35, 83, 0.8);
  flex-flow: row wrap;

  @media screen and (max-width: 980px) {
    ${SettingArea} {
      width: 100%;
      max-width: unset;
      margin: 0;
      margin-bottom: 45px;
    }
  }
`;

export const Section = styled.section`
  ${props =>
    props.premiumCustomBrandPermission
      ? css`
          padding: 60px 40px 30px;
          &.isSetting {
            min-height: calc(100vh - 351px);
            @media only screen and (max-height: 780px) {
              padding: 0px 40px 0px !important;
              min-height: auto;
              max-height: calc(100vh - 360px);
            }
          }
        `
      : css`
          padding: 60px 40px 0px;
          &.isSetting {
            min-height: calc(100vh - 368px);
          }
        `}
  ${props =>
    props.screenActive === 3 &&
    css`
      padding: 30px 40px;
    `}
  color: rgba(32, 35, 83, 0.8);
  .breaker {
    display: block;
    width: 100%;
    height: 1px;
    background: #eeeeee;
    transform: translateY(-30px);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: auto;

  ${Section} {
    :last-child {
      border-bottom: none;
    }
  }

  .ui.attached {
    &.segment {
      border: 0;
    }
    &.tabular.menu {
      box-shadow: inset 0px -1px 0px #e1e5ec;
      padding-left: 63px;
      position: sticky;
      top: 0;
      z-index: 3;
      background: #fff;
      .item {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #858585;
        padding: 0;
        margin-right: 30px;
        letter-spacing: 0.5px;
        border: 0;
        &:hover {
          text-decoration: none !important;
        }
      }

      .active.item {
        color: #5158cf !important;
        border-bottom: 2px solid #5158cf;
      }
    }
  }
`;

export const JoinWaitlistButton = styled(Button).attrs({
  type: 'button',
})`
  border: none;
  padding: 10px 32px;
  background: #fff;
  color: #f47735;

  :hover {
    background: linear-gradient(180deg, #ffffff 0%, #ffe8c7 100%);
  }
`;

export const BannerContent = styled.div`
  color: #fff;
  margin-left: 30%;
  margin-right: 10px;

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  .features {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 20px;
      font-weight: normal;
      font-size: 13px;
      line-height: 100%;

      svg {
        margin-right: 4px;
      }
    }

    .addMore {
      font-style: italic;
      font-weight: normal;
      font-size: 13px;
      line-height: 100%;
    }
  }
`;

export const Banner = styled.div`
  width: 873px;
  min-height: 116px;
  max-width: 100%;
  background: transparent url(${CDN_URL}/images/custombranding_banner_bg.png) no-repeat;
  background-size: auto;
  background-position: top left;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  padding: 10px 45px;
  margin-top: 30px;

  ${props =>
    props.sideBarVisible
      ? css`
          @media screen and (max-width: 1080px) {
            ${BannerContent} {
              margin-left: 15%;
            }
          }

          @media screen and (max-width: 930px) {
            padding: 10px 45px;

            ${BannerContent} {
              margin-left: 0;
            }
          }
        `
      : css`
          @media screen and (max-width: 930px) {
            padding: 10px 45px;

            ${BannerContent} {
              margin-left: 15%;
            }
          }
          @media screen and (max-width: 730px) {
            ${BannerContent} {
              margin-left: 0;
            }
          }
        `}
`;

export const TopBannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PremiumWrapper = styled.div`
  width: inherit;
  height: calc(100vh - 110px);

  > section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-height: 780px) {
    height: calc(100vh - 114px);
  }
`;

export const PremiumSetting = styled.div``;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
