import React from 'react';
import get from 'lodash/get';

import { isOnwer } from 'utils/validations';
import { enableOnDemand } from 'utils/commonFunction';

import { ReactComponent as AutoflowIcon } from 'assets/icons/route_autoflow.svg';
import { ReactComponent as StudioIcon } from 'assets/icons/route_studio.svg';
import { ReactComponent as ForumIcon } from 'assets/icons/route_forum.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/route_teammate.svg';
import { ReactComponent as ReferralIcon } from 'assets/icons/route_referal.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/route_payment.svg';

const handleRenderStudioProgram = permission => {
  let subItems = [];
  const isEnableOnDemand = enableOnDemand();

  if (get(permission, 'studio_program') || get(permission, 'studio_resource_collection')) {
    subItems = [
      { route: '/home/studio-collection', title: 'Resource Collections', name: 'studio_collection' },
      { route: '/home/studio-resource', title: 'Resources', name: 'studio_resource', subItem: true },
    ];
  }

  if (isEnableOnDemand) {
    subItems = [
      ...subItems,
      { route: '/home/workout-collections', title: 'Workout Collections', name: 'studio_workout_collection' },
      {
        route: '/home/on-demand-workouts',
        title: 'On-demand Workouts',
        name: 'studio_on_demand_workout',
        subItem: true,
      },
      {
        route: '/home/workout-labels',
        title: 'Workout Labels',
        name: 'studio_workout_labels',
        subItem: true,
      },
    ];
  }

  if (get(permission, 'studio_program') || get(permission, 'studio_resource_collection')) {
    subItems = [...subItems, { route: '/home/studio-programs', title: 'Studio Programs', name: 'studio_program' }];
  }

  if (get(permission, 'studio_resource_collection')) {
    subItems = [...subItems, { route: '/home/on-demand-settings', title: 'Settings', name: 'studio_on_demand_setup' }];
  }

  return subItems;
};

export const MENU = [
  {
    route: '/home/studio-collection',
    name: 'studio',
    icon: <StudioIcon />,
    isActive: activeItem => activeItem.includes('studio'),
    title: 'On-demand',
    renderSubItems: ({ permission }) => handleRenderStudioProgram(permission),
  },
  {
    route: '/home/onboarding-flow',
    name: 'automation',
    icon: <AutoflowIcon />,
    title: 'Automation',
    isActive: activeItem => activeItem.includes('onboarding-flow') || activeItem.includes('autoflow'),
    renderSubItems: ({ isTeamAdmin, permission }) => {
      const hasPermissionMultiple = (permission || {}).multiple_onboarding_flow;
      if (hasPermissionMultiple || !!isTeamAdmin) {
        return [
          { route: '/home/onboarding-flow', title: 'Onboarding Flow', name: 'onboarding-flow' },
          { route: '/home/autoflow', title: 'Autoflow', name: 'autoflow' },
        ];
      }
      return null;
    },
  },
  {
    route: '/home/forums',
    name: 'forums',
    icon: <ForumIcon />,
    title: 'Community Forums',
  },
  {
    route: '/home/packages',
    name: 'package',
    icon: <PaymentIcon />,
    isActive: activeItem =>
      activeItem.includes('package') ||
      activeItem.includes('marketplace') ||
      activeItem.includes('setup') ||
      activeItem.includes('payment-activities') ||
      activeItem.includes('sequences') ||
      activeItem.includes('coach-bios'),
    title: 'Payment',
    renderSubItems: ({ user, permission }) => {
      if (!!isOnwer(user) && !!permission.payment) {
        return [
          { route: '/home/packages', title: 'Packages', name: 'package' },
          { route: '/home/sequences', title: 'Sequence', name: 'sequences', subItem: true },
          { route: '/home/marketplace', title: 'Marketplace', name: 'marketplace' },
          { route: '/home/payment-activities', title: 'Payment Activity', name: 'payment-activities' },
          { route: '/home/setup-payment', title: 'Setup Payment', name: 'setup-payment' },
          { route: '/home/coach-bios', title: 'Coach Bios', name: 'coach-bios' },
        ];
      }
      if (!isOnwer(user) && !!permission.payment) {
        return [
          { route: '/home/packages', title: 'Packages', name: 'package' },
          { route: '/home/sequences', title: 'Sequence', name: 'sequences', subItem: true },
          { route: '/home/marketplace', title: 'Marketplace', name: 'marketplace' },
          { route: '/home/payment-activities', title: 'Payment Activity', name: 'payment-activities' },
        ];
      }
      return [
        { route: '/home/packages', title: 'Packages', name: 'package' },
        { route: '/home/sequences', title: 'Sequence', name: 'sequences', subItem: true },
        { route: '/home/marketplace', title: 'Marketplace', name: 'marketplace' },
      ];
    },
  },
  {
    route: '/home/referral-program',
    name: 'referral-program',
    icon: <ReferralIcon />,
    title: 'Refer a Coach',
  },
  {
    route: '/home/teammates',
    name: 'teammates_settings',
    icon: <TeamIcon />,
    title: 'Team',
    isActive: activeItem => activeItem.includes('setting'),
    canAccess: ({ isTeamAdmin }) => !!isTeamAdmin,
    renderSubItems: () => [],
  },
];
