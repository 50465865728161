import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 15.6px;
  color: #FFFFFF;
`;

export const Tooltip = styled.span`
  ${baseText}
  display: none;
  position: absolute;
  top: -57px;
  left: -16px;
  width: 42px;
  height: 36px;
  border-radius: 5px;
  background: #2d2e2d;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 19.5px;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -3px;
    width: 7px;
    height: 7px;
    background: #2d2e2d;
    transform: translateX(-50%) rotate(45deg);
  }
`;

export const InputRangeWrapper = styled.div`
  display: flex;
  position: relative;
  &:hover {
    ${Tooltip} {
      display: flex;
    }
  }
`;

export const InputRange = styled.input`
  width: 75px;
  -webkit-appearance: none;
  height: 2px;
  background: #fff;
  border-radius: 1px;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: #5158cf;
    cursor: pointer;
    border-radius: 50%;
  }
  ::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #5158cf;
    cursor: pointer;
    border-radius: 50%;
  }
`;

export const ZoomRange = styled.div`
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  gap: 2px;
  height: 32px;
`;

export const ZoomButton = styled.div`
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  &:hover {
    svg path {
      fill: #5e59ff;
    }
  }
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      svg path {
        fill: rgba(255, 255, 255, 0.2);
      }
    `}
`;

export const ConfirmButton = styled.button`
  ${baseText}
  line-height: 19.5px;
  color: #818da1;
  width: 71px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #d6dae4;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;

export const ResetButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    background: #fff;
    border-color: #fff;
    svg path {
      fill: #5e59ff;
    }
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      svg path {
        fill: rgba(255, 255, 255, 0.2);
      }
    `}
`;

export const ZoomSetting = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
