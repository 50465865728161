import React from 'react';
import moment from 'moment';
import * as S from './style';
import { ReactComponent as BackIcon } from 'assets/icons/mobile-back-button.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/mobile-settings.svg';
import CustomBrandingStatusBar from 'shared/CustomBrandingStatusBar';
import PreviewContent from 'assets/images/workout-background-preview-content.png';

export default props => {
  return (
    <S.Wrapper {...props}>
      <CustomBrandingStatusBar light />
      <S.MobileNavBar>
        <BackIcon />
        <SettingIcon />
      </S.MobileNavBar>

      <S.Header>
        <div>
          <div className="today">Today,&nbsp;{moment(new Date()).format('MMM D')}</div>
          <div className="hello">
            <span>Chest and Shoulder Workout</span>
          </div>
          <S.StartWorkout>Start Workout</S.StartWorkout>
        </div>
      </S.Header>
      <S.Content>
        <img src={PreviewContent} alt="content" />
      </S.Content>
    </S.Wrapper>
  );
};
