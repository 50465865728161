// Libs
import React from 'react';

// Assets
import { ReactComponent as TitleIcon } from 'assets/icons/poll-title-icon.svg';

// Styles
import * as S from '../../style';

const PollTitlePost = ({ title = '' }) => {
  return (
    <S.TitleWrapper>
      <TitleIcon className="title-icon" />
      <S.Title>{title}</S.Title>
    </S.TitleWrapper>
  );
};

export default PollTitlePost;
