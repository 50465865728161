import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Wrapper } from './style';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left_fill_black.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right_fill_black.svg';

export const SWITCH_MODE = {
  WEEK: 'week',
  MONTH: 'month',
  LAST7: 'last7',
  LAST14: 'last14',
};

function TimeSwitch({ startDate, mode, onChange, className, notAllowFuture, ...props }) {
  const [date, setDate] = useState(
    [SWITCH_MODE.LAST7, SWITCH_MODE.LAST14].includes(mode)
      ? moment(moment().format()).subtract([SWITCH_MODE.LAST7].includes(mode) ? 6 : 13)
      : moment(startDate || undefined).startOf(mode),
  );
  const handleClickPrevious = () => {
    if ([SWITCH_MODE.LAST7, SWITCH_MODE.LAST14].includes(mode)) {
      return;
    }
    const newDate = moment(date).subtract(1, mode).startOf(mode);
    setDate(newDate);
    onChange(newDate);
  };

  const handleClickNext = () => {
    if ([SWITCH_MODE.LAST7, SWITCH_MODE.LAST14].includes(mode)) {
      return;
    }
    const newDate = moment(date).add(1, mode).startOf(mode);
    setDate(newDate);
    onChange(newDate);
  };

  const renderLabel = useCallback(() => {
    if ([SWITCH_MODE.WEEK, SWITCH_MODE.LAST7, SWITCH_MODE.LAST14].includes(mode)) {
      let startWeek = moment(date).startOf('week');
      let endWeek = moment(date).endOf('week');
      if ([SWITCH_MODE.LAST7, SWITCH_MODE.LAST14].includes(mode)) {
        startWeek = moment(date).subtract([SWITCH_MODE.LAST7].includes(mode) ? 6 : 13, 'days');
        endWeek = moment(date);
      }
      let startText = startWeek.format('MMM D');
      let endText = endWeek.format('D, YYYY');

      if (startWeek.isSame(endWeek, 'year')) {
        if (!startWeek.isSame(endWeek, 'month')) {
          endText = endWeek.format('MMM D, YYYY');
        }
      } else {
        startText = startWeek.format('MMM D, YYYY');
        endText = endWeek.format('MMM D, YYYY');
      }

      return `${startText} — ${endText}`;
    }

    return date.format('MMMM YYYY');
  }, [mode, date]);

  const isDisabled = useMemo(() => {
    if (!notAllowFuture) {
      return false;
    }
    if (mode === SWITCH_MODE.WEEK) {
      const endWeek = moment(date).endOf('week');
      const nowEndWeek = moment().endOf('week');
      return endWeek.isSameOrAfter(nowEndWeek);
    }
    const endMonth = moment(date).endOf('month');
    const nowEndMonth = moment().endOf('month');
    return endMonth.isSameOrAfter(nowEndMonth);
  }, [mode, date, notAllowFuture]);

  return (
    <Wrapper className={classnames('time-switch', className)} {...props}>
      <div className="previous" onClick={handleClickPrevious}>
        <ArrowLeft />
      </div>
      <div className="">{renderLabel()}</div>
      <button className="next" onClick={handleClickNext} disabled={isDisabled}>
        <ArrowRight />
      </button>
    </Wrapper>
  );
}

TimeSwitch.props = {
  startDate: PropTypes.object,
  mode: PropTypes.oneOf[(SWITCH_MODE.MONTH, SWITCH_MODE.WEEK)],
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default TimeSwitch;
