import React from 'react';
import { RootCloseWrapper } from 'react-overlays';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import Ownership from 'components/Ownership';
import ImageButton from 'shared/ImageButton';
import { CDN_URL } from 'constants/commonData';

import './styles.scss';

export default class OwnershipPopup extends React.Component {
  state = {};

  render() {
    const { openMenu } = this.state;
    const { title = '', darkTooltip = false, className = '' } = this.props;

    return (
      <RootCloseWrapper disabled={!openMenu} onRootClose={() => this.setState({ openMenu: false })} event="click">
        <div className={`ownershipWrapper action-menu ${openMenu ? 'open' : ''}`}>
          <div
            className={`${className} ownership action-menu__toggle-button`}
            onClick={() => this.setState(prevState => ({ openMenu: !prevState.openMenu }))}
          >
            <div data-tip data-for="owner-ship-sharing-tooltip" className="teammate-icon" />
            <ReactTooltip
              className={`${darkTooltip ? 'app-tooltip' : 'app-tooltip-white'} custom-arrow`}
              id="owner-ship-sharing-tooltip"
              effect="solid"
              place="top"
            >
              <div>Ownership &amp; Sharing</div>
            </ReactTooltip>
          </div>
          <div className="ownership action-menu__content">
            <ImageButton
              image={`${CDN_URL}/images/teammate_black.svg`}
              title={title || 'Ownership'}
              className="bg-transparent bg-ownership"
            />
            <Ownership
              owner={this.props.owner}
              isEditAble={this.props.isEditAble}
              handleChangeOwner={this.props.handleChangeOwner}
              share={this.props.share}
              handleChangeShare={this.props.handleChangeShare}
              hasYou={this.props.hasYou}
              isTrainer={this.props.isTrainer || false}
            />
          </div>
        </div>
      </RootCloseWrapper>
    );
  }
}
