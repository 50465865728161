import React from 'react';

import * as S from './style';

const AccordionSkeleton = () => {
  return (
    <S.AccordionSkeletonWrapper>
      <div className="skeleton-thumbnail" />
      <div className="skeleton-name" />
    </S.AccordionSkeletonWrapper>
  );
};

export default AccordionSkeleton;
