import React from 'react';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';
import styled from 'styled-components';
import OnboardingTooltip, { Content, Title } from 'components/Onboarding/Tooltip';
import { markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { connect } from 'react-redux';
import { WORKOUT_BUILDER_GUIDE_STEPS } from 'constants/commonData';
import { bindActionCreators } from 'redux';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .onboarding-tooltip__arrow {
    top: 45px !important;
    left: 80% !important;
  }

  .onboarding-tooltip__content {
    visibility: visible;
    right: -50px;
    top: 48px;
    text-transform: none;
  }
`;

function DropdownIcon(props) {
  const { workoutBuilderGuideSteps, setIndex, exIndex, isFirstSectionHasAlterExercise } = props;
  const isDoneWorkoutGuideStep1 = _.get(workoutBuilderGuideSteps, [WORKOUT_BUILDER_GUIDE_STEPS.CreateExerciseSection]);
  const isDoneWorkoutGuideStep2 = _.get(workoutBuilderGuideSteps, [WORKOUT_BUILDER_GUIDE_STEPS.CustomOptions]);
  const isFirst = isFirstSectionHasAlterExercise && setIndex === 0 && exIndex === 0;
  const isShowGuide = isFirst && isDoneWorkoutGuideStep1 && !isDoneWorkoutGuideStep2;

  return (
    <Wrapper>
      <Icon name={'ellipsis horizontal  more-actions-icon'} color={'grey'} />
      {isShowGuide ? (
        <OnboardingTooltip
          place="bottom"
          align="end"
          showCloseButton
          customPosition
          onClick={event => {
            event.stopPropagation();
          }}
          onClose={() => props.markWorkoutBuilderGuide(WORKOUT_BUILDER_GUIDE_STEPS.CustomOptions)}
        >
          <Content maxWidth="290">
            <Title>More customization options</Title>
            Explore options to add <b>alternate exercises</b>, or change the <b>tracking fields</b> (RPE, Resting Heart
            Rate, and more)
          </Content>
        </OnboardingTooltip>
      ) : null}
    </Wrapper>
  );
}

const mapStateToProps = state => {
  return {
    workoutBuilderGuideSteps: state.rootReducer.onboarding.workoutBuilderGuideSteps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markWorkoutBuilderGuide: bindActionCreators(markWorkoutBuilderGuide, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownIcon);
