import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import filter from 'lodash/filter';
import size from 'lodash/size';

import { isSameCurrentDate } from 'utils/commonFunction';
import { CDN_URL, EVENT_CLOSE_TASK_REMINDER_POP_UP } from 'constants/commonData';
import {
  readNotifications,
  openItemNotification,
  countUnread,
  clearNewNotification,
  markAsReadItemNotification,
  markAsUnreadItemNotification,
  getNotificationPopupWithFilter,
} from 'redux/notification/actions';

import * as S from './style';
import NotificationContext from './context/NotificationContext';
import NotificationList from './components/NotificationList';
import { toggleModal } from 'actions/modal';
import { SharedTooltip } from 'shared/SharedTooltip';

function AppNotificationSidebar(props) {
  const {
    readNotifications,
    openItemNotification,
    countUnread,
    page,
    loading,
    total,
    clearNewNotification,
    markAsReadItemNotification,
    markAsUnreadItemNotification,
    timezone,
    toggleModal,
    getNotificationPopupWithFilter,
    permission,
    summaries,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(true);
  const [disableCloseSidebar, setDisableCloseSidebar] = useState(true);
  const [onlyUnread, setOnlyUnread] = useState(false);
  const [actionPopupId, setActionPopupId] = useState();

  const unread = useMemo(() => get(props, 'unread', 0), [props]);
  const list = useMemo(() => {
    if (!onlyUnread) return get(props, 'list', []);
    return filter(get(props, 'list', []), ['checked', false]);
  }, [props, onlyUnread]);
  const listToday = useMemo(() => filter(list, it => isSameCurrentDate(get(it, 'createdAt', ''), timezone)), [list]);
  const listOlder = useMemo(() => filter(list, it => !isSameCurrentDate(get(it, 'createdAt', ''), timezone)), [list]);
  const isToday = useMemo(() => !isEmpty(listToday), [listToday]);
  const isOlder = useMemo(() => !isEmpty(listOlder), [listOlder]);
  const isEmptyNotification = useMemo(() => isEmpty(list), [list]);
  const hasUnread = useMemo(() => (get(summaries, 'all', 0) > 0 ? true : some(list, ['checked', false])), [
    summaries,
    list,
  ]);
  const isEnd = useMemo(() => !isEmpty(list) && size(list) === total, [total, list]);
  const isFirstPage = useMemo(() => page === 1, [page]);

  useEffect(() => {
    countUnread();
  }, []);

  useEffect(() => {
    if (openPopup) {
      getNotificationPopupWithFilter({
        page: 1,
        filters: {
          showUnread: onlyUnread,
        },
      });
    }
  }, [openPopup, onlyUnread]);

  const closePopup = useCallback(() => {
    setOpenPopup(false);
    setDisableCloseSidebar(true);
    setOnlyUnread(false);
    closeTooltip();
  }, []);
  const togglePopup = useCallback(() => {
    if (!openPopup) {
      window.dispatchEvent(EVENT_CLOSE_TASK_REMINDER_POP_UP);
      backToTop();
      clearNewNotification();
    }
    setOpenPopup(prevState => !prevState);
    setDisableCloseSidebar(prevState => !prevState);
  }, [openPopup]);
  const closeTooltip = useCallback(() => setOpenTooltip(false), []);
  const toggleOnlyUnread = useCallback(() => setOnlyUnread(prevState => !prevState), []);
  const backToTop = useCallback(() => {
    const contentBody = document.getElementById('notification-content-body');
    contentBody && contentBody.scrollTo({ top: 0 });
  }, []);
  const getMoreNotifications = useCallback(() => {
    !loading && getNotificationPopupWithFilter({ page: page + 1 });
  }, [page, loading]);

  const context = {
    openPopup,
    closePopup,
    openTooltip,
    closeTooltip,
    onlyUnread,
    toggleOnlyUnread,
    isToday,
    isOlder,
    isEmptyNotification,
    hasUnread,
    list,
    listToday,
    listOlder,
    timezone,
    openItemNotification,
    readNotifications,
    backToTop,
    loading,
    getMoreNotifications,
    isEnd,
    markAsReadItemNotification,
    markAsUnreadItemNotification,
    disableCloseSidebar,
    setDisableCloseSidebar,
    actionPopupId,
    setActionPopupId,
    toggleModal,
    isFirstPage,
    permission,
  };

  return (
    <>
      <S.Trigger onClick={togglePopup} data-tip data-for="notification-trigger-tooltip">
        <img src={`${CDN_URL}/images/notification.svg`} className="icon" alt="" />
        <img src={`${CDN_URL}/images/notification_purple.svg`} className="icon hover" alt="" />
        {!!unread && (
          <div
            className={classNames('counter', {
              large: unread > 99,
              medium: unread >= 20 && unread <= 99,
              small: unread < 20,
            })}
          >
            {unread > 99 ? '99+' : unread}
          </div>
        )}
        <SharedTooltip
          content="Notifications"
          id="notification-trigger-tooltip"
          className="notification-trigger-tooltip"
          position="bottom"
          offset={{ left: 16 }}
        />
      </S.Trigger>
      {createPortal(
        <NotificationContext.Provider value={context}>
          <NotificationList />
        </NotificationContext.Provider>,
        document.body,
      )}
    </>
  );
}

const mapStateToProps = state => {
  const {
    user: { timezone },
    rootReducer: {
      notification: {
        unread = 0,
        popup: { list = [], page = 1, total = 0, loading = false } = {},
        summaries = {},
      } = {},
      permission = {},
    } = {},
  } = state;

  return { timezone, list, page, total, loading, unread, permission, summaries };
};

const mapDispatchToProps = {
  readNotifications,
  openItemNotification,
  countUnread,
  clearNewNotification,
  markAsReadItemNotification,
  markAsUnreadItemNotification,
  toggleModal,
  getNotificationPopupWithFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNotificationSidebar);
