import React from 'react';
import { get } from 'lodash';
import CardThumbnail from './CardThumbnail';
import { TASK_TYPES } from 'constants/commonData';
import * as S from './style';

function PreviewNarrowCard(props) {
  const { section } = props;
  return (
    <S.SectionMobilePreview>
      <S.PreviewTitle>
        <S.ViewName>{section.section_name}</S.ViewName>
        <S.ViewMore>View more</S.ViewMore>
      </S.PreviewTitle>
      <S.PreviewLargeCardWrapper>
        {section.resources.map(resource => {
          if (resource.source_type === TASK_TYPES.FORM && get(resource, 'form.is_archived', false)) {
            return null;
          }
          return (
            <S.NarrowCard key={resource._id}>
              <CardThumbnail resource={resource} showType={true} format={section.format}>
                <S.LargeCardName
                  type={resource.source_type}
                  className={resource.source_type === 'form' ? 'isForm' : ''}
                >
                  {resource.source_type === 'form' ? 'FORM' : resource.name}
                </S.LargeCardName>
                {resource.source_type === 'form' ? <S.LargeCardSubName>{resource.name}</S.LargeCardSubName> : null}
              </CardThumbnail>
            </S.NarrowCard>
          );
        })}
      </S.PreviewLargeCardWrapper>
    </S.SectionMobilePreview>
  );
}

export default PreviewNarrowCard;
