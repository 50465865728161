import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7B7E91;
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    ${baseText}
    margin-bottom: 2px;
    padding-left: 1px;
    text-transform: uppercase;
  }
`;

export const NumberInput = styled.div`
  border: 1px solid #dadbea;
  border-radius: 4px;
  overflow: hidden;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #202353;
    border: none;
    padding: 0;
    outline: none;
    flex: 1;
    width: 100%;
  }
  &:hover {
    border: 1px solid #5158cf;
  }
  ${props =>
    props.isFocus &&
    css`
      border: 1px solid #5158cf;
    `}
`;

export const Button = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f2;
  opacity: 0.3;
  svg path {
    fill: #8789a8;
  }
  &.plus-icon {
    opacity: 1;
    svg path {
      fill: #202353;
    }
  }
  &.subtract-icon {
    opacity: 1;
    svg path {
      fill: #202353;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      &.plus-icon {
        opacity: 0.3;
      }
      &.subtract-icon {
        opacity: 0.3;
      }
      pointer-events: none;
      svg path {
        fill: #bcbdca;
      }
    `}
  &:hover {
    opacity: 1;
    svg path {
      fill: #202453;
    }
  }
`;
