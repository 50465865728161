import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { isEqual } from 'lodash';

import * as S from './style';

const SingleValue = ({ data }) => {
  return (
    <S.SelectedWrapper>
      <S.SelectedLabel>{data.label}</S.SelectedLabel>
    </S.SelectedWrapper>
  );
};

const DropdownOptions = ({
  readOnly = false,
  onSelect,
  options,
  width = '100%',
  height = '100%',
  menuPlacement,
  selectedValue,
  maxHeightOfList,
  onScrollBottom,
  className,
  blurInputOnSelect,
  customOption = null,
  placeholder = null,
  nullOption,
  hasNullOption = false,
  required = false,
  customIndicator = null,
  isForceUpdate = false,
  onOptionDisabled,
  styles,
  defaultValue,
  isDefaultValue,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    if (selectedValue) {
      const option = options.find(it => it.value === selectedValue);
      setSelectedOption(option);
    }
  }, [options]);

  useEffect(() => {
    if (selectedValue && isForceUpdate) {
      const option = options.find(it => it.value === selectedValue);
      setSelectedOption(option);
    }
  }, [selectedValue]);

  const handleSelectOption = value => {
    if (hasNullOption && nullOption && isEqual(value, nullOption)) {
      setSelectedOption(null);
      onSelect && onSelect(value);
      return;
    }
    setSelectedOption(value);
    onSelect && onSelect(value);
  };

  const handelAutoScrollBottom = () => {
    onScrollBottom && onScrollBottom();
  };

  return (
    <S.DropdownWrapper
      width={width}
      height={height}
      maxHeightOfList={maxHeightOfList}
      required={required}
      className={className ? className : ''}
    >
      <Select
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        isDisabled={readOnly}
        options={options}
        className="rootOptions"
        classNamePrefix="common-select-container"
        defaultValue={isDefaultValue ? defaultValue : undefined}
        value={isDefaultValue ? undefined : selectedOption}
        blurInputOnSelect={blurInputOnSelect}
        onChange={handleSelectOption}
        isSearchable={false}
        components={{
          DropdownIndicator: customIndicator
            ? customIndicator
            : readOnly
            ? null
            : () => <Icon name="chevron down" size="small" />,
          SingleValue,
          IndicatorSeparator: null,
          ClearIndicator: null,
          ...(customOption ? { Option: customOption } : {}),
        }}
        onMenuOpen={handelAutoScrollBottom}
        isOptionDisabled={onOptionDisabled}
        styles={styles}
      />
    </S.DropdownWrapper>
  );
};

DropdownOptions.propTypes = {
  readOnly: PropTypes.bool,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  menuPlacement: PropTypes.string,
  selectedValue: PropTypes.string,
  maxHeightOfList: PropTypes.string,
  onScrollBottom: PropTypes.func,
  customOption: PropTypes.element,
  placeholder: PropTypes.string,
  nullOption: PropTypes.object,
  hasNullOption: PropTypes.bool,
  className: PropTypes.string,
  customIndicator: PropTypes.element,
  onOptionDisabled: PropTypes.func,
};

export default DropdownOptions;
