import React from 'react';
import styled from 'styled-components';

import { MODES } from './constant';

const Container = styled.div`
  padding: 0 30px;

  .back {
    cursor: pointer;
    margin-right: 10px;
  }
`;

const SubTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #1f2023;
  margin-top: 4px;
`;

export default props => {
  const { isFromToolbar = false } = props;
  return (
    <Container className="metric-settings__header__content">
      {props.mode === MODES.LIST ? (
        <>
          <div>{props.title || 'Setup list of metrics'}</div>
          {props.subTitle ? <SubTitle>{props.subTitle}</SubTitle> : null}
        </>
      ) : (
        <>
          {!isFromToolbar && (
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="back"
              onClick={props.onBack}
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M13.5625 7.4375H0.4375"
                  stroke="black"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.8125 3.0625L0.4375 7.4375L4.8125 11.8125"
                  stroke="black"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
          {props.mode === MODES.EDIT ? 'Edit metric' : 'Add New Metric'}
        </>
      )}
    </Container>
  );
};
