/**
 * @flow
 */

import React, { Component } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { PricingPlanWrapper } from './style';
import PricingPlanHeading from './Heading';
import ChooseAPlan from './ChooseAPlan';
import PlanPackage from './PlanPackage';
import ClientProgress from './ClientProgress';
import AutomationAddOn from './AutomationAddOn';
import PlanSummary from './PlanSummary';
import EnterprisePlan from './EnterprisePlan';
import ExploreAddOn from './ExploreAddOn';
import ConfirmPayment from './ConfirmPayment';
import CustomEnterprisePricing from './CustomEnterprisePricing';

export default class PricingPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIntersecting: true,
      step: 1,
      downgrade: false,
      isLoading: false,
    };
    this.automationRef = React.createRef();
  }

  componentDidMount() {
    const { paymentInfo, location, selectPackage, teamData } = this.props;
    const params = queryString.parse(window.location.search);
    const code = _.get(params, 'coupon', '');
    this.initIntersectionObserver();
    this.props.resetPaymentReducer();
    const zapierSelected = _.get(location, 'state.zapierPath', '') === '/home/integration';
    if (zapierSelected) {
      this.setState({ step: 2, downgrade: _.get(location, 'state.isDowngrade', null), isLoading: false });
    }

    //Need to re-check carefully with promo code case for this timeout
    const initPricingPromise = this.props.initPricingData(true, code);

    const timeoutPromise = new Promise(resolve => setTimeout(resolve, 500));
    const upgradeZapier = selectPackage.zapier_quantity > teamData.zapier_quantity && zapierSelected;

    Promise.all([initPricingPromise, timeoutPromise]).then(() => {
      if (zapierSelected) {
        this.props.updateSelectPackage({
          zapier_quantity: _.get(location, 'state.zapier_quantity', 0),
        });
        this.props.updateZapierPrice({
          addOnsPricing: _.get(location, 'state.addOnsPricing', 0),
        });
      }
      if (upgradeZapier) {
        this.props.getProratedZapierMonthly();
      } else {
        this.props.getProratedCredit();
        this.props.getProratedCreditPrev();
      }
    });
    this.props.getReferralPromotionCode();
    if (this.props.shouldSelectPlan) {
      this.props.markShouldSelectPlan();
    }
    if (!paymentInfo || !paymentInfo.payment_method_id) {
      this.props.getBillingOverviewData(false);
    }
  }
  componentWillMount() {
    const { location, history } = this.props;
    const isZapier = _.get(location, 'state.zapier_quantity', 0);

    window.onbeforeunload = function (event) {
      if (isZapier) {
        history.replace();
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.invoiceOverDue && nextProps.push) {
      nextProps.push('home/billing');
    }
  }

  initIntersectionObserver = () => {
    if (this.automationRef.current) {
      let observer = new IntersectionObserver(this.handleObserve);
      observer.observe(this.automationRef.current);
    }
  };

  handleObserve = entries => {
    this.setState({ isIntersecting: _.get(entries, [0, 'isIntersecting']) });
  };

  handleScrollToAddOn = () => {
    if (this.automationRef.current) {
      this.automationRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  removeZapierPath = () => {
    const { location, history } = this.props;
    const zapierSelected = _.get(location, 'state.zapierPath', '') === '/home/integration';

    if (zapierSelected) {
      // Reset state downgrade
      this.setState({ downgrade: undefined });

      // Remove location path
      const pathname = window.location.pathname;
      window.history.replaceState({}, '', pathname);
      history.replace();
    }
  };

  onUpgrade = async ({ downgrade }) => {
    this.setState({ isLoading: true });
    const { teamData, checkPromoCode, referralPromotionCode } = this.props;
    if (teamData.promoCode) {
      await checkPromoCode({ code: teamData.promoCode });
    } else if (referralPromotionCode) {
      await checkPromoCode({ code: referralPromotionCode });
    }
    // this.props.getProratedCredit().finally(() => {
    //   this.setState({ step: 2, downgrade, isLoading: false });
    // });

    await this.props.getProratedCredit();
    this.setState({ step: 2, downgrade, isLoading: false });
  };

  onBack = () => {
    this.setState({ step: 1 }, () => {
      this.initIntersectionObserver();
      this.props.removePromoCode();
      this.removeZapierPath();
    });
  };

  componentWillUnmount() {
    this.props.resetPricingData();
  }

  render() {
    const { addOnsPricing, teamData, location } = this.props;
    const { step, isIntersecting, downgrade, isLoading, shouldSelectPlan } = this.state;
    const hasZapierPath = _.get(location, 'state.zapierPath', '');
    if (teamData.is_enterprise) {
      return <CustomEnterprisePricing onBack={this.onBack} />;
    }

    return (
      <PricingPlanWrapper>
        <PricingPlanHeading
          className="pricingPlan__heading"
          step={step}
          onBack={this.onBack}
          zapierPath={hasZapierPath}
          downgrade={downgrade}
        />
        <div className="pricingPlan__container">
          {step === 1 ? (
            <div className="pricingPlan__body">
              <div className="pricingPlan__bodyHeading">
                <ChooseAPlan location={location} />
              </div>
              <div className="pricingPlan__bodyContent">
                <div className="pricingPlan__plans">
                  <PlanPackage isLoading={isLoading} />
                  <ClientProgress isLoading={isLoading} />
                  {(addOnsPricing || []).map(addOn => (
                    <AutomationAddOn key={addOn.code} addOn={addOn} isLoading={isLoading} />
                  ))}
                  <div ref={this.automationRef} id="AutomationAddOn" />
                  <EnterprisePlan />
                </div>
                <div className="pricingPlan__planSummary">
                  <PlanSummary onUpgrade={this.onUpgrade} isLoading={isLoading} shouldSelectPlan={shouldSelectPlan} />
                </div>
              </div>
            </div>
          ) : (
            <ConfirmPayment onBack={this.onBack} downgrade={downgrade} zapierPath={hasZapierPath} />
          )}
        </div>
        <ExploreAddOn visible={!isIntersecting && step === 1} onClick={this.handleScrollToAddOn} />
      </PricingPlanWrapper>
    );
  }
}
