import React from 'react';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const Icon = styled.img.attrs({
  src: `${CDN_URL}/images/comment_placeholder.svg`,
})`
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #333333;
  margin-bottom: 5px;
`;

const SubTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  color: #202353;
  margin-top: 5px;
`;

const LeaveAComment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    margin-bottom: 20px;
  }
`;

export default props => (
  <LeaveAComment {...props}>
    <Icon />
    <Title>Leave a comment</Title>
    <SubTitle>Share a message with your client</SubTitle>
  </LeaveAComment>
);
