import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import SurveyModal from '../SurveyModal';
import { getSurveyDetails, resetSurveyDetails } from 'redux/survey-contact/actions';
import Avatar from 'react-avatar';
import { getUserShortName } from 'utils/commonFunction';

import * as S from './styles';
import { ReactComponent as CloseWhiteIcon } from 'assets/icons/close_current_color.svg';
import { ReactComponent as CloseBlackIcon } from 'assets/icons/close_program_filter.svg';
import { ReactComponent as MobileBlack } from 'assets/icons/mobile_view_black.svg';
import { ReactComponent as MobileWhite } from 'assets/icons/mobile_view_white.svg';
import { ReactComponent as DesktopBlack } from 'assets/icons/desktop_view_black.svg';
import { ReactComponent as DesktopWhite } from 'assets/icons/desktop_view_white.svg';
import LoadingIndicator from 'shared/LoadingIndicator';

const SurveyPreviewDetailModal = ({
  survey,
  surveyId,
  loading,
  onClose,
  toggleModal,
  surveyDetails,
  isPreview = false,
  getSurveyDetails,
  resetSurveyDetails,
  onChangeSurvey,
  trainer,
  packageName,
}) => {
  const resolveSurvey = survey || surveyDetails;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (surveyId) {
      getSurveyDetails(surveyId);
    }
    return () => {
      resetSurveyDetails();
    };
  }, [surveyId]);
  const handleBack = () => {
    toggleModal(true, <SurveyModal onClose={onClose} onChangeSurvey={onChangeSurvey} />);
  };

  const handleClose = () => {
    if (isPreview) {
      return handleBack();
    }

    toggleModal(false);
  };

  const renderQuestions = () => {
    if (loading) {
      return <LoadingIndicator className="custom-loading" />;
    }
    const introText = get(resolveSurvey, 'intro', '');
    return (
      <S.SurveyQuestionWrapper isMobile={isMobile}>
        {!!introText && (
          <div className="introduction">
            <span>{introText}</span>
          </div>
        )}
        {map(get(resolveSurvey, 'questions', []), (item, index) => (
          <S.SurveyQuestionForm key={index}>
            <div className="question-title-wrapper">
              <div className="question-index">{index + 1}</div>
              <p className="question-title">{get(item, 'question', '')}</p>
            </div>
            <div className="input-wrapper">
              <span className="placeholder">Type your answer</span>
            </div>
          </S.SurveyQuestionForm>
        ))}
      </S.SurveyQuestionWrapper>
    );
  };

  return (
    <S.SurveyDetailWrapper>
      <S.SurveyDetailHeader>
        <span className="description">Preview what your clients will see.</span>
        <div className="preview-mode">
          <div onClick={() => setIsMobile(false)} className={classNames('laptop-mode', !isMobile && 'active')}>
            {!isMobile ? <DesktopBlack /> : <DesktopWhite />}
          </div>
          <div onClick={() => setIsMobile(true)} className={classNames('mobile-mode', isMobile && 'active')}>
            {isMobile ? <MobileBlack /> : <MobileWhite />}
          </div>
        </div>
        <CloseWhiteIcon width={16} height={16} onClick={handleClose} />
      </S.SurveyDetailHeader>
      <S.SurveyDetailContentWrapper isMobile={isMobile}>
        <div className="survey-detail-content">
          <div className="header">
            <div className="client-info-wrapper">
              <Avatar
                name={getUserShortName(trainer)}
                size="40"
                src={get(trainer, 'avatar', '')}
                color={get(trainer, 'color')}
                className="avatar"
              />
              <div className="name-wrapper">
                <span className="trainer-name">{get(trainer, 'full_name', '')}</span>
                <span className="package-name">{packageName}</span>
              </div>
            </div>
            <CloseBlackIcon width={16} height={16} />
          </div>
          <div className="body">
            <div className="body-content-wrapper">{renderQuestions()}</div>
          </div>
          <div className="footer">
            <div className="step-wrapper">
              <div className="step" />
              <div className="step active" />
            </div>
            <div className="button-wrapper">
              <button className="back">BACK</button>
              <button className="submit">SUBMIT</button>
            </div>
          </div>
        </div>
      </S.SurveyDetailContentWrapper>
    </S.SurveyDetailWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { surveyContact, packageDetail },
  } = state;

  const surveyDetails = get(surveyContact, 'surveyDetails', {});
  const workingPackage = packageDetail.get('workingPackage').toJS();
  const trainer = get(workingPackage, 'trainer', null);
  const packageName = get(workingPackage, 'name', '');

  return {
    trainer,
    surveyDetails,
    packageName,
    loading: get(surveyContact, 'surveyDetailsIsLoading', false),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getSurveyDetails: bindActionCreators(getSurveyDetails, dispatch),
  resetSurveyDetails: bindActionCreators(resetSurveyDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPreviewDetailModal);
