import styled, { css } from 'styled-components';
import TextEditable from 'shared/TextEditable';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #A3A3B5;
`;

export const Label = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #7b7e91;
  margin-bottom: 3px;
`;

export const UploadIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  border-radius: 4px;
  display: none;
  .app-tooltip-step-instruction {
    ${baseText}
    line-height: 18px;
    font-size: 12px;
    padding: 8px 16px;
    border-radius: 4px;
    color: #fff;
  }
  &:hover {
    background-color: #f0f1ff;
    svg path {
      fill: #5158cf;
    }
  }
`;

export const Content = styled.div`
  padding: 7px 10px;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  user-select: auto;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: #ea314a !important;
    `}
`;

export const Item = styled.div`
  margin-bottom: 15px;
  width: 100%;
  outline: none;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .icon-remove-step {
    display: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 10px;
    right: -8px;
    z-index: 1;
  }
  &:hover {
    .icon-remove-step {
      display: block;
    }
    ${Content} {
      border-color: #5158cf;
    }
  }

  ${props =>
    props.open &&
    css`
      ${UploadIconWrapper} {
        display: flex;
      }
      ${Content} {
        border-color: #5158cf;
        padding: 9px 10px;
      }

      .icon-remove-step {
        display: block;
      }
    `}

  ${props =>
    props.hasContent &&
    css`
      ${UploadIconWrapper} {
        display: flex;
      }
    `}
`;

export const TextEditableWrapper = styled(TextEditable)`
  .text-editable__container {
    padding: 0;
    .text-editable__input {
      ${baseText}
      line-height: 18px;
      color: #202353;
      :empty::before {
        ${baseText}
        line-height: 18px;
      }
    }
  }
  .text-editable__container:hover:not(.text-editable__container--active):not(.text-editable__container--invalid) {
    background-color: transparent;
  }
  .text-editable__container--active {
    border-color: transparent;
  }
`;

export const ViewFile = styled.div`
  position: relative;
  width: 120px;
  height: 80px;
  margin-top: 20px;
  .image-with-fallback {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .icon-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .file-upload__cancel-button {
    width: 24px;
    height: 24px;
    background-size: 10px;
  }
`;

export const ErrorMsg = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  color: #ea314a;
  margin-top: 3px;
`;
