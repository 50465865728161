export const BACKGROUND_LIST = [
  {
    border: '#E15959',
    background: '#FCDEDE',
  },
  {
    border: '#FBA603',
    background: '#FCEDDE',
  },
  {
    border: '#FBB503',
    background: '#FCF5DE',
  },
  {
    border: '#74451A',
    background: '#F0E9E2',
  },
  {
    border: '#10BA36',
    background: '#DEFCE5',
  },
  {
    border: '#0393FB',
    background: '#DEF1FC',
  },
  {
    border: '#5559FF',
    background: '#EAEBFF',
  },
  {
    border: '#5E5E5E',
    background: '#F1F1F1',
  },
];

export const HABIT_UNIT = [
  /* 1 */
  {
    _id: '638041fe51ca1ec1a5570b87',
    name: 'Times',
    type: 'system',
  },

  /* 2 */
  {
    _id: '638041fe51ca1ec1a5570b88',
    name: 'Minutes',
    type: 'system',
  },

  /* 3 */
  {
    _id: '638041fe51ca1ec1a5570b8a',
    name: 'Servings',
    type: 'system',
  },

  /* 4 */
  {
    _id: '638041fe51ca1ec1a5570b89',
    name: 'Cups',
    type: 'system',
  },
];

export const FREQUENCY = [
  { label: 'Per day', value: 'per_day' },
  { label: 'Per week', value: 'per_week' },
];

export const REPEAT = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
];

export const INTERVAL_REPEAT = [
  { label: 'Daily', value: 'day' },
  { label: 'Weekly', value: 'week' },
];

export const DEFAULT_REMINDER_TIME = '08:00 AM';
export const LIMIT_TITLE_LENGTH = 90;

export const DEFAULT_EMOJI = {
  icon: '💪',
  icon_unified: '1f4aa',
  color: '#FBB503',
};

export const CATEGORIES_HABIT = {
  MOST_RECENT: 'Most Recent',
  MOST_POPULAR: 'Most Popular',
  YOUR_HABITS: 'Your Habits',
  YOUR_TEAM_HABITS: `Your Team's Habits`,
};
