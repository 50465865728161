// libraries
import React, { useEffect, useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import union from 'lodash/union';

// store
import { addGroupMetricsFromLibrary, getGroupMetricsLibrary } from 'redux/metric-group-library/action';
import { toggleSecondModal } from 'actions/modal';
import { updateMultipleClientMetric } from 'redux/client/client.actionCreators';

// components
import { NewSearchInput } from 'shared/SearchInput';
import { Button, Checkbox } from 'shared/FormControl';
import LibraryMetricGroupItem from './LibraryMetricGroupItem';
import EnableMetrics from '../EnableMetrics';

// assets
import { ReactComponent as ChartIncreaseIcon } from 'assets/icons/chart_increase.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_bold_circle.svg';
import { ReactComponent as Skeleton } from './skeletons/Skeleton.svg';
import { ReactComponent as TitleSkeleton } from './skeletons/TitleSkeleton.svg';

import * as S from './style';

function AddSavedGroupModal(props) {
  const {
    toggleModal,
    getGroupMetrics,
    addGroupMetricsFromLibrary,
    clientId,
    bodyMetricTypes,
    toggleSecondModal,
    updateMultipleClientMetric,
  } = props;
  const [textSearch, setTextSearch] = useState('');
  const [isSaving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [selectedMetricGroupIds, setSelectedMetricGroupIds] = useState([]);
  const [allMetricGroups, setAllMetricGroups] = useState([]);
  const [currentMetricGroups, setCurrentMetricGroups] = useState([]);

  useEffect(() => {
    getAllMetrics();
  }, []);

  // To check selected metrics existed or not
  useEffect(() => {
    const isSelectedAll =
      allMetricGroups.length > 0 &&
      (selectedMetricGroupIds.length === allMetricGroups.length ||
        currentMetricGroups.every(item => selectedMetricGroupIds.includes(item._id)));
    setSelectedAll(isSelectedAll);
  }, [selectedMetricGroupIds, currentMetricGroups, allMetricGroups.length]);

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const getListToEnableMetrics = () => {
    const selectedMetricsList = currentMetricGroups.filter(({ _id = '' }) => selectedMetricGroupIds.includes(_id));

    const filteredMetrics = selectedMetricsList.flatMap(({ metrics = [] }) =>
      metrics.filter(({ _id: idMetric = '' }) => {
        const compareMetric = bodyMetricTypes.find(({ _id: idBodyMetric = '' }) => idBodyMetric === idMetric);
        return !compareMetric || !compareMetric.selected;
      }),
    );

    return uniqBy(filteredMetrics, '_id');
  };

  const updateSelectionStatus = (items, idsToUpdate) => {
    const idsSet = new Set(idsToUpdate);

    return items.map(item => {
      if (idsSet.has(item._id)) {
        return { ...item, selected: true };
      }
      return item;
    });
  };

  const transformItem = (item, includeLastPinAt = false) => {
    const { pinned, selected, unit, unique_code } = item;
    const transformedItem = { pinned, selected, unique_code, unit: unit._id };

    if (includeLastPinAt && 'last_pin_at' in item) {
      transformedItem.last_pin_at = item.last_pin_at;
    }

    return transformedItem;
  };

  const handleConfirmEnableMetrics = async listEnable => {
    if (isSaving) {
      return false;
    }

    if (!isEmpty(listEnable)) {
      const originalData = map(bodyMetricTypes, item => transformItem(item));
      const dataUpdateSelection = updateSelectionStatus(bodyMetricTypes, listEnable);
      const newData = map(dataUpdateSelection, item => transformItem(item, true));

      const items = differenceWith(newData, originalData, isEqual);

      if (items.length) {
        setSaving(true);

        await updateMultipleClientMetric({ items, clientId })
          .then(() => {})
          .finally(() => {
            setSaving(false);
            toggleModal(false);
            toggleSecondModal(false);
            addGroupMetricsFromLibrary({ client: clientId, libraries: selectedMetricGroupIds });
          });
      } else {
        toggleSecondModal(false);
      }
    } else {
      toggleModal(false);
      toggleSecondModal(false);
      addGroupMetricsFromLibrary({ client: clientId, libraries: selectedMetricGroupIds });
    }
  };

  const handleAddGroups = () => {
    if (!isEmpty(getListToEnableMetrics())) {
      toggleSecondModal(
        true,
        <EnableMetrics
          onClose={() => toggleSecondModal(false)}
          listToEnableMetrics={getListToEnableMetrics()}
          onConfirm={handleConfirmEnableMetrics}
        />,
      );
    } else {
      toggleModal(false);
      addGroupMetricsFromLibrary({ client: clientId, libraries: selectedMetricGroupIds });
    }
  };

  const handleSearch = (_, { value }) => {
    setTextSearch(value);
    const valueLowerCase = value.trim().toLocaleLowerCase();
    const newList = allMetricGroups.filter(({ title, metric_names }) => {
      const matchedTitle = String(title).toLocaleLowerCase().includes(valueLowerCase);
      if (matchedTitle) return true;
      return metric_names.some(name => String(name).toLocaleLowerCase().includes(valueLowerCase));
    });
    setCurrentMetricGroups(newList);
  };

  const handleClearSearch = () => {
    setTextSearch('');
    setCurrentMetricGroups(allMetricGroups);
  };

  const getAllMetrics = async () => {
    try {
      setIsLoading(true);
      const { data } = await getGroupMetrics({}, true);
      const results = get(data, 'data.data', []);

      setAllMetricGroups(results);
      setCurrentMetricGroups(results);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAll = async () => {
    if (isEmpty(currentMetricGroups)) return;

    try {
      let newSelectedMetrics = [];
      const currentMetricIds = currentMetricGroups.map(item => item._id);

      if (isSelectedAll) {
        if (textSearch) {
          newSelectedMetrics = selectedMetricGroupIds.filter(mId => !currentMetricIds.includes(mId));
        } else {
          newSelectedMetrics = [];
        }
      } else {
        if (textSearch) {
          newSelectedMetrics = union(selectedMetricGroupIds, currentMetricIds);
        } else {
          newSelectedMetrics = [...currentMetricIds];
        }
      }

      setSelectedMetricGroupIds(newSelectedMetrics);
      setSelectedAll(!isSelectedAll);
    } catch (error) {
      console.error('Error occurred while fetching group metrics:', error);
    }
  };

  const handleSelectGroup = id => {
    if (selectedMetricGroupIds.includes(id)) {
      setSelectedAll(false);
      setSelectedMetricGroupIds(selectedMetricGroupIds.filter(item => item !== id));
    } else {
      setSelectedMetricGroupIds([...selectedMetricGroupIds, id]);
    }
  };

  const renderEmptyMetric = () => (
    <S.EmptyWrapper>
      {!textSearch && (
        <S.EmptyIcon>
          <ChartIncreaseIcon />
        </S.EmptyIcon>
      )}
      <p>{textSearch ? 'No results found' : 'No Metric Groups Available'}</p>
    </S.EmptyWrapper>
  );

  return (
    <S.CustomModal open onClose={handleCloseModal} closeOnDimmerClick={false} className="evf-add-group-modal">
      <Modal.Header className="modal-add-group-header">
        <S.HeaderWrapper>
          <S.AddMetricHeaderWrapper>
            <S.AddMetricHeaderTitle>
              <label>Saved Metric Group</label>
            </S.AddMetricHeaderTitle>
            <NewSearchInput
              onChange={debounce(handleSearch, 300)}
              placeholder="Search by keyword or name"
              onClearSearch={handleClearSearch}
              className="metric-search"
            />
          </S.AddMetricHeaderWrapper>

          <CloseButton className="close-button" onClick={handleCloseModal}>
            <CloseIcon className="close-icon" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <S.Wrapper>
        <div className="metric-group-header">
          {isLoading ? (
            <TitleSkeleton />
          ) : (
            <p>
              {textSearch ? 'Results' : 'Metric Groups'} (
              {textSearch ? currentMetricGroups.length : allMetricGroups.length})
            </p>
          )}
          <Checkbox
            title="Select All"
            checked={currentMetricGroups.length === 0 ? false : isSelectedAll}
            onChange={handleSelectAll}
            size={20}
            className="checkbox-metric-icon"
          />
        </div>
        <div className="metric-group-list">
          {isLoading ? (
            <Skeleton className="loading" />
          ) : (
            <>
              {isEmpty(currentMetricGroups) ? (
                renderEmptyMetric()
              ) : (
                <>
                  {currentMetricGroups.map(item => (
                    <LibraryMetricGroupItem
                      item={item}
                      key={item._id}
                      checked={selectedMetricGroupIds.includes(item._id)}
                      onChangeSelect={handleSelectGroup}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </S.Wrapper>
      <S.Action>
        <Button className="btn-cancel" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button purple onClick={handleAddGroups} disabled={isEmpty(selectedMetricGroupIds)}>
          Add
        </Button>
      </S.Action>
    </S.CustomModal>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: {
      client: { selected, bodymetricTypes },
    },
  } = state;
  return {
    clientId: selected,
    bodyMetricTypes: bodymetricTypes || [],
  };
};

const mapDispatchToProps = dispatch => ({
  addGroupMetricsFromLibrary: bindActionCreators(addGroupMetricsFromLibrary, dispatch),
  getGroupMetrics: bindActionCreators(getGroupMetricsLibrary, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  updateMultipleClientMetric: bindActionCreators(updateMultipleClientMetric, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSavedGroupModal);
