// Lib
import React, { useEffect } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isObject from 'lodash/isObject';
import classNames from 'classnames';

// Store
import {
  publishRecipe,
  removeRecipe,
  enableEditModeRecipe,
  unpublishRecipe,
  updateDataDetailRecipe,
  cancelEditModeRecipe,
  addRecipeLibraries,
  duplicateRecipe,
  getTotalRecipeInTeam,
} from 'redux/recipes/actions';
import { toggleConfirmModal } from 'actions/modal';

// Components
import { Button } from 'shared/FormControl';
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';
import DeleteRecipeModal from 'components/Recipes/Parts/DeleteRecipeModal';
import { SharedTooltip } from 'shared/SharedTooltip';

// Constants
import { CDN_URL, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';
import { RECIPE_STATUS, TYPE_RECIPE } from 'components/Recipes/constants';
import { dataEdit } from '../component';
import {
  convertDataMacroNutrients,
  formatValueIngredient,
  validRecipePublish,
  validRecipePublishNotSave,
} from '../helper';

// Assets
import { ReactComponent as BackIcon } from 'assets/icons/arrow_back.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PublishIcon } from 'assets/icons/MealPlans/action_publish.svg';
import { ReactComponent as UnpublishIcon } from 'assets/icons/MealPlans/action_unpublish.svg';
import { ReactComponent as PublishChangesIcon } from 'assets/icons/MealPlans/publish_changes.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/MealPlans/action_duplicate.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/MealPlans/action_remove.svg';

import * as S from './style';

const Header = props => {
  const {
    history,
    handleSaveRecipe,
    user,
    toggleConfirmModal,
    removeRecipe,
    detailRecipe,
    updateDataDetailRecipe,
    push,
    isEditMode,
    publishRecipe,
    unpublishRecipe,
    enableEditModeRecipe,
    cancelEditModeRecipe,
    loading,
    addRecipeLibraries,
    duplicateRecipe,
    loadingUpload,
    handleSubmitted,
    updateUnitType,
    disabledAction,
    permission,
    totalRecipeInTeam,
    getTotalRecipeInTeam,
  } = props;

  const {
    _id = '',
    status = '',
    owner,
    author,
    isChange = false,
    is_edit_mode = false,
    unit: unitType = {},
    share,
    ingredients = [],
    type = '',
    loadingVideoInstruction = false,
  } = detailRecipe || {};
  const { no_recipes } = permission || {};

  const { _id: authorId = '' } = author || {};
  const { _id: ownerId = '' } = owner || {};

  const isCustom = type === TYPE_RECIPE.CUSTOM;
  const isSystem = type === TYPE_RECIPE.SYSTEM;
  const canAction =
    (isTeamAdmin(user) || authorId === get(user, '_id', '') || share === TEAM_SHARE_NOOWNER) && isCustom;
  const exceededNumberRecipes = no_recipes >= 0 && no_recipes <= totalRecipeInTeam;

  useEffect(() => {
    getTotalRecipeInTeam && getTotalRecipeInTeam();
  }, []);

  const handleChangeOwner = value => {
    updateDataDetailRecipe({
      owner: get(value, 'owner', ''),
    });
  };

  const handleChangeShare = value => {
    updateDataDetailRecipe({
      share: get(value, 'share', TEAM_SHARE_PRIVATE),
    });
  };

  const handleGoBack = () => {
    history.push('/home/recipes');
  };

  const renderValidateModal = ({ title, description }) => {
    toggleConfirmModal(
      true,
      <S.CustomConfirmModal
        title={title}
        content={description}
        onConfirm={() => {
          if (!canAction) return;

          if (!isEditMode) {
            handleEdit();
          }

          toggleConfirmModal(false);
        }}
        confirmButtonTitle="Edit Recipe"
        className={classNames('validate-publish-modal', { 'is-edit': isEditMode, 'is-disabled': !canAction })}
        headerIcon={`${CDN_URL}/images/warning_yellow_icon.svg`}
        hideCancelButton
        noBorder
        hasCloseIcon
        isPressEsc
        shouldCloseAfterConfirm={false}
        onClose={() => toggleConfirmModal(false)}
      />,
    );
  };

  const handleValidatePublishRecipe = () => {
    const isValid = validRecipePublish(detailRecipe);

    const getValidContent = () => ({
      title: 'Complete basic recipe details',
      description:
        'Before publishing the recipe, please make sure you have included the recipe name, category, cooking time, ingredients, cooking instruction, and macronutrients.',
    });

    const handleValidation = (isValid, content) => {
      if (!isValid) renderValidateModal(content);
      return isValid;
    };

    const validContent = getValidContent();

    return handleValidation(!isValid, validContent);
  };

  const handleEdit = () => {
    typeof enableEditModeRecipe === 'function' && enableEditModeRecipe(_id, status === RECIPE_STATUS.PUBLISH);
  };

  const mapAndFilterInstructions = instructions =>
    instructions
      .map(item => omit(item, ['_id']))
      .filter(item => get(item, 'content', '') || get(item, 'attachments.length', 0) > 0);

  const getDataForPublish = () => {
    const idOwner = isObject(author) ? authorId : isObject(owner) ? ownerId : owner;

    const prepareInstructions = mapAndFilterInstructions(get(detailRecipe, 'prepare_instructions', []));
    const cookingInstructions = mapAndFilterInstructions(get(detailRecipe, 'cooking_instructions', []));

    const listIngredient = get(detailRecipe, 'ingredients', []).map(item => {
      return {
        ingredient: get(item, 'ingredient', ''),
        value_as_text: formatValueIngredient(get(item, 'value_as_text')),
        unit: get(item, 'unit._id'),
      };
    });

    const data = {
      ...detailRecipe,
      owner: get(detailRecipe, 'share') === TEAM_SHARE_NOOWNER ? null : idOwner || undefined,
      prepare_instructions: prepareInstructions,
      cooking_instructions: cookingInstructions,
      ingredients: listIngredient,
      other_nutrients: (detailRecipe.other_nutrients || []).map(item => pick(item, ['type', 'value'])),
      macro_nutrients: convertDataMacroNutrients(detailRecipe.macro_nutrients),
      intro_instruction: !!get(detailRecipe, 'intro_instruction.url', '')
        ? get(detailRecipe, 'intro_instruction')
        : null,
    };
    return data;
  };

  const handlePublishRecipe = data => {
    // Update type unit
    updateUnitType &&
      updateUnitType({
        unit_type: get(unitType, 'key', ''),
      });

    if (_id) {
      typeof publishRecipe === 'function' && publishRecipe(_id, pick(data, dataEdit));
    } else {
      typeof addRecipeLibraries === 'function' &&
        addRecipeLibraries(pick({ ...data, status: RECIPE_STATUS.PUBLISH }, dataEdit)).then(res => {
          const idRecipe = get(res, 'data.data._id', '');
          push(`/home/recipes/${idRecipe}`);
          toast('The recipe has been published. You can assign it to your clients or add it to any meal plans now.', {
            className: 'custom--recipe-toast',
          });
        });
    }
  };

  const handlePublic = (isPublishChange = false) => {
    // Valid field

    if (isPublishChange) {
      const isValidPublishNotSave = validRecipePublishNotSave(detailRecipe);
      handleSubmitted({
        isSubmittedPublish: true,
        isSubmitted: false,
        ingredients: ingredients.map(item => omit(item, ['isAddNew'])),
      });
      if (isValidPublishNotSave) return;
    }

    if (_id && !is_edit_mode && !isPublishChange) {
      const isValidPublish = handleValidatePublishRecipe();
      handleSubmitted({
        isSubmittedPublish: true,
        isSubmitted: false,
        ingredients: ingredients.map(item => omit(item, ['isAddNew'])),
      });
      if (!isValidPublish) return;
    } else {
      const isValidPublishNotSave = validRecipePublishNotSave(detailRecipe);
      handleSubmitted({
        isSubmittedPublish: true,
        isSubmitted: false,
        ingredients: ingredients.map(item => omit(item, ['isAddNew'])),
      });
      if (isValidPublishNotSave) return;
    }

    handleSubmitted({
      isSubmittedPublish: false,
      isSubmitted: false,
      ingredients: ingredients.map(item => omit(item, ['isAddNew'])),
    });

    const data = getDataForPublish();
    handlePublishRecipe(data);
  };

  const handleUnpublish = () => {
    if (!_id) return;

    typeof unpublishRecipe === 'function' && unpublishRecipe(_id);
  };

  const handleCancelEdit = () => {
    typeof cancelEditModeRecipe === 'function' && cancelEditModeRecipe(_id, status === RECIPE_STATUS.PUBLISH);
  };

  const unpublishConfirmModal = () => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          title="Unpublish Recipe"
          content="This will remove the recipe from any clients using it and any meal plans that it's currently in."
          onConfirm={handleUnpublish}
          confirmButtonTitle="Unpublish Recipe"
          cancelButtonTitle="Cancel"
          className="unpublish-recipe-modal"
          headerIcon={`${CDN_URL}/images/recipe_unpublish.svg`}
          noBorder
          hasCloseIcon
          isPressEsc
          onClose={() => toggleConfirmModal(false)}
          isCloseOnDimmer={false}
        />,
      );
  };

  const cancelEditModal = () => {
    if (!isChange) {
      handleCancelEdit();
    } else {
      toggleConfirmModal &&
        toggleConfirmModal(
          true,
          <S.CustomConfirmModal
            title="Cancel Edits Made to Recipe?"
            content="Would you like to cancel editing the recipe? Any changes you have made will not be saved."
            onConfirm={handleCancelEdit}
            confirmButtonTitle="Cancel Edits"
            headerIcon={`${CDN_URL}/images/alert_warning.svg`}
            cancelButtonTitle="Continue Editing"
            className="cancel-edit-recipe-modal"
            noBorder
            hasCloseIcon
            isPressEsc
            onClose={() => toggleConfirmModal(false)}
            isCloseOnDimmer={false}
          />,
        );
    }
  };

  const handleDeleteRecipe = () => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <DeleteRecipeModal
          onConfirm={() =>
            removeRecipe &&
            removeRecipe(_id, () => {
              push(`/home/recipes`);
              toggleConfirmModal(false);
              toast('Recipe has been removed.');
            })
          }
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
          noBorder
          content={
            status === RECIPE_STATUS.PUBLISH &&
            'This will remove the recipe for any clients using the recipe and remove the recipe for all clients'
          }
          hasCloseIcon
          isPressEsc
          isCloseOnDimmer={false}
          onClose={() => toggleConfirmModal(false)}
        />,
      );
  };

  const handleDuplicateRecipe = detailRecipe => {
    if (loading) return;

    const id = get(detailRecipe, '_id');

    let params = {
      name: `Copied - ${get(detailRecipe, 'name', '').trim()}`,
    };

    if (id) {
      typeof duplicateRecipe === 'function' && duplicateRecipe(id, params);
    }
  };

  const renderTrigger = ({ open }) => (
    <div data-tip data-for="recipe-detail-options-tooltip">
      <MenuTrigger className="drop-down-trigger" vertical active={!!open} />
      <SharedTooltip
        id="recipe-detail-options-tooltip"
        className={classNames({ 'recipe-detail-options-tooltip': isSystem })}
        offset={isSystem && { left: 16 }}
      />
    </div>
  );

  const renderActions = () => {
    if (isSystem && exceededNumberRecipes) return;
    return (
      <DropDown className="custom-drop-down-actions" triggerIcon={renderTrigger} direction="left" isNewSpacing>
        {!exceededNumberRecipes && (
          <Option key="duplicate" onClick={() => handleDuplicateRecipe(detailRecipe)}>
            <S.OptionIcon className="icon">
              <DuplicateIcon className="recipe-duplicate-icon" />
            </S.OptionIcon>
            <span>Duplicate</span>
          </Option>
        )}
        {canAction && (
          <Option key="delete" onClick={() => handleDeleteRecipe(detailRecipe)}>
            <S.OptionIcon className="icon">
              <RemoveIcon className="recipe-remove-icon" />
            </S.OptionIcon>
            <span>Remove</span>
          </Option>
        )}
      </DropDown>
    );
  };

  const renderActionsButton = () => {
    if (isSystem) return;
    return (
      <>
        {!isEditMode && (
          <Button
            className="custom-button edit-button"
            onClick={handleEdit}
            disabled={loading || loadingUpload || (status === RECIPE_STATUS.DRAFT && !canAction) || disabledAction}
          >
            <EditIcon className="custom-edit-icon" />
            Edit
          </Button>
        )}
        {status !== RECIPE_STATUS.PUBLISH && isEditMode && (
          <Button
            className="custom-button save-button"
            onClick={handleSaveRecipe}
            disabled={loading || loadingUpload || loadingVideoInstruction}
          >
            Save
          </Button>
        )}
        {status === RECIPE_STATUS.PUBLISH && isEditMode && (
          <Button
            className="custom-button cancel-edit-button"
            onClick={cancelEditModal}
            disabled={loading || loadingUpload || disabledAction}
          >
            Cancel Edit
          </Button>
        )}
        {status === RECIPE_STATUS.PUBLISH && isEditMode && (
          <Button
            className="custom-button publish-changes-button"
            onClick={() => handlePublic(true)}
            disabled={loading || loadingUpload || disabledAction || loadingVideoInstruction}
          >
            <PublishChangesIcon className="custom-publish-changes-icon" />
            Publish Changes
          </Button>
        )}
        {status === RECIPE_STATUS.PUBLISH && !isEditMode && (
          <Button
            className="custom-button unpublish-button"
            onClick={unpublishConfirmModal}
            disabled={loading || loadingUpload || disabledAction}
          >
            <UnpublishIcon className="custom-unpublish-icon" />
            Unpublish
          </Button>
        )}
        {status !== RECIPE_STATUS.PUBLISH && (
          <Button
            className="custom-button publish-button"
            onClick={() => handlePublic()}
            disabled={loading || loadingUpload || disabledAction || loadingVideoInstruction}
          >
            <PublishIcon className="custom-publish-icon" />
            Publish
          </Button>
        )}
      </>
    );
  };

  return (
    <S.HeaderWrapper>
      <S.BackWrapper onClick={handleGoBack}>
        <BackIcon />
        Back
      </S.BackWrapper>
      <S.OptionWrapper>
        {/* Remove share setting in recipe detail */}
        {/* <ShareSetting
          userId={get(user, '_id', '')}
          ownerValue={{ owner: get(detailRecipe, 'author._id') || get(detailRecipe, 'owner._id') }}
          shareValue={{ share: get(detailRecipe, 'share') }}
          onSetOwnerValue={handleChangeOwner}
          onSetShareValue={handleChangeShare}
          hasSpacing
          hasYou
          disableOwner={!isEditMode}
          disableSharing={!isEditMode}
          titleToolTip="Ownership & Sharing"
          isEditAble={
            get(detailRecipe, 'share') === TEAM_SHARE_NOOWNER ||
            (get(detailRecipe, 'author._id') || get(detailRecipe, 'owner._id')) === get(user, '_id', '')
          }
        /> */}
        {!isEditMode && [RECIPE_STATUS.PUBLISH, RECIPE_STATUS.DRAFT].includes(status) && renderActions()}
        {renderActionsButton()}
      </S.OptionWrapper>
    </S.HeaderWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes, permission },
    user,
  } = state;

  return {
    user,
    isEditMode: get(recipes, 'isEditMode', false),
    detailRecipe: get(recipes, 'detailRecipe', {}),
    loading: get(recipes, 'loading', false),
    loadingUpload: get(recipes, 'isLoading', false),
    permission,
    totalRecipeInTeam: (recipes || {}).totalRecipeInTeam,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  removeRecipe: bindActionCreators(removeRecipe, dispatch),
  updateDataDetailRecipe: bindActionCreators(updateDataDetailRecipe, dispatch),
  push: bindActionCreators(push, dispatch),
  publishRecipe: bindActionCreators(publishRecipe, dispatch),
  unpublishRecipe: bindActionCreators(unpublishRecipe, dispatch),
  enableEditModeRecipe: bindActionCreators(enableEditModeRecipe, dispatch),
  cancelEditModeRecipe: bindActionCreators(cancelEditModeRecipe, dispatch),
  addRecipeLibraries: bindActionCreators(addRecipeLibraries, dispatch),
  duplicateRecipe: bindActionCreators(duplicateRecipe, dispatch),
  getTotalRecipeInTeam: bindActionCreators(getTotalRecipeInTeam, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
