import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { toggleModal } from 'actions/modal';
import LoginComponent from 'components/Login/component';
import { addRegistrationNeedToComplete } from 'redux/auth/actions';
import { verifyTeam } from 'actions/client';
import { signUpByGoogle } from 'actions/auth/register';
import { logoutWithoutRedirect } from 'actions/auth/login';

const mapStateToProps = state => {
  const { isModalOpen, modal, workspace = {} } = state;

  return { isModalOpen, modal, workspace };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    push: bindActionCreators(push, dispatch),
    addRegistrationNeedToComplete: bindActionCreators(addRegistrationNeedToComplete, dispatch),
    handleVerifyTeam: bindActionCreators(verifyTeam, dispatch),
    signUpByGoogle: bindActionCreators(signUpByGoogle, dispatch),
    logoutWithoutRedirect: bindActionCreators(logoutWithoutRedirect, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
