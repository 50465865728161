import React, { PureComponent } from 'react';
import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import classNames from 'classnames';

import LoadingIndicator from 'shared/LoadingIndicator';
import { last7dayEmpty } from 'components/TrainingOverview/constant';
// assets
import { ReactComponent as ChartBarIcon } from 'assets/icons/bar-chart-2.svg';

import CustomTooltipContent from './CustomTooltipContent';
import CustomLineBar from 'components/BodyMetricChartNew/components/CustomLineBar';
import { Wrapper } from './styles';

const StepColor = '#FF7557';
const StepBarWidth = 20;

class StepChartOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.chartRef = null;
    this.state = {
      isHoveringBar: false,
      chartBarData: {},
      tooltipPos: { x: undefined, y: undefined },
    };
    this.chartId = `metric-step-chart-tooltip-customized-${props.chartId || 'step'}`;
  }

  moveOutBarChart = () => this.setState({ isHoveringBar: false, chartBarData: {} });

  updateTooltipBarChart = data => {
    if (!this.chartRef) return;

    const tooltip = document.getElementById(this.chartId);
    const { width: tooltipW, height } = tooltip ? tooltip.getBoundingClientRect() : { width: 0 };
    let x = data.x - tooltipW / 2 + 10;

    if (x + tooltipW > this.chartRef.props.width) {
      x -= x + tooltipW - this.chartRef.props.width;
    }
    const y = data.y - height - 10;
    this.setState({ tooltipPos: { x, y }, isHoveringBar: true, chartBarData: data });
  };

  tooltipActiveCallback = active => {
    if (this.props.chartType === 'line' && this.state.tooltipActive !== active) {
      this.setState({ tooltipActive: active });
    }
  };

  handleTickFormat = value => {
    return value === 0 ? '' : value;
  };

  componentDidUpdate() {
    try {
      const { chartData, isLoading, chartId } = this.props;
      const isNoData = chartData.every(item => item.value === 0);
      const xLine = document.querySelector(`.xAxis-no-data-${chartId} .recharts-cartesian-axis-line`);

      if (xLine && isNoData && !isLoading) {
        xLine.setAttribute('x1', 35);
      }
    } catch (error) {}
  }

  renderChart = isNoData => {
    const { chartData, width = 500, height = 400, isLoading, chartId = '' } = this.props;
    const { tooltipPos, isHoveringBar, chartBarData } = this.state;
    const stepChartData = isNoData ? last7dayEmpty() : chartData;

    return (
      <ResponsiveContainer width="100%" height="100%" className={classNames({ 'no-data': isNoData })}>
        <ComposedChart
          width={width}
          height={height}
          data={stepChartData}
          ref={r => (this.chartRef = r)}
          className="step-chart-overview"
        >
          <XAxis
            dataKey="label"
            padding={{ left: 5 }}
            interval={0}
            dy={5}
            className={isNoData && !isLoading && `xAxis-no-data-${chartId}`}
          />
          <YAxis axisLine={false} tickLine={false} padding={{ top: 10 }} tickFormatter={this.handleTickFormat} />
          <Tooltip
            content={
              <CustomTooltipContent
                isExerciseMetric
                chartData={stepChartData}
                tooltipActiveCallback={this.tooltipActiveCallback}
                chartId={this.chartId}
                isHoveringChartBar={isHoveringBar}
              />
            }
            cursor={
              <CustomLineBar
                color={StepColor}
                barWidth={StepBarWidth}
                chartBarData={chartBarData}
                isFillTransparent={!isHoveringBar}
              />
            }
            animationDurationNumber={100}
            coordinate={tooltipPos}
            position={tooltipPos}
          />
          <Bar
            dataKey="value"
            fill={StepColor}
            animationDuration={500}
            barSize={StepBarWidth}
            radius={[3, 3, 0, 0]}
            activeBar={false}
            onMouseOver={this.updateTooltipBarChart}
            onMouseOut={this.moveOutBarChart}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  render() {
    const { isLoading, chartData, minHeight = 332 } = this.props;
    const isNoData = chartData.every(item => item.value === 0);
    return (
      <>
        {isLoading && <LoadingIndicator title="Loading chart..." className="loading-chart-indicator" />}
        {isNoData && !isLoading && (
          <div className="chart-no-data">
            <ChartBarIcon width={32} />
            <p>No data available</p>
          </div>
        )}
        <Wrapper minHeight={minHeight} color={StepColor} className="chart-wrapper ">
          {this.renderChart(isNoData)}
        </Wrapper>
      </>
    );
  }
}

export default StepChartOverview;
