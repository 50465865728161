import styled from 'styled-components';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px
  color: #202353;
`;

export const StepInstructionDetailWrapper = styled.div``;

export const Item = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  ${baseText}
  white-space: pre-line;
  span {
    font-weight: 600;
  }
`;

export const Image = styled(S3ImageWithFallback)`
  margin-top: 12px;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    overflow: hidden;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  .media-player__trigger {
    width: 100% !important;
    height: 100% !important;
  }
`;
