import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { updateMetricData, toggleComparisonMode } from 'actions/bodyMetric';
import {
  selectGroupMetric,
  organizeGroupMetric,
  removeGroupMetric,
  addMetricsToGroup,
  addMultipleMetricToGroup,
  rearrangeMetricInGroup,
  saveGroupToLibrary,
} from 'actions/groupMetric';
import { toggleConfirmModal, toggleModal } from 'actions/modal';

import ConfirmModal from 'shared/ConfirmModal';
import AddMetricsModal from './components/AddMetricsModal';
import GroupBodyMetricsContext from './context/GroupBodyMetricsContext';
import OrganizeGroup from './components/OrganizeGroup';
import { defaultView } from 'components/BodyMetricProgressNew/NormalMetricProgress';
import { getUnitObject } from './constant';
import { CDN_URL } from 'constants/commonData';

const GroupBodyMetrics = props => {
  const {
    user,
    groupMetric,
    selectGroupMetric,
    organizeGroupMetric,
    bodyMetric: { mid: selectedMetricId, isGettingChartData, isGettingTarget, isLoadingCompareChart } = {},
    updateMetricData,
    push,
    match,
    toggleConfirmModal,
    removeGroupMetric,
    toggleModal,
    toggleComparisonMode,
    addMultipleMetricToGroup,
    rearrangeMetricInGroup,
    saveGroupToLibrary,
    location,
  } = props;
  const { metrics: metricsNormalized = {}, selected = {} } = groupMetric;
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [openIndividualPopup, setOpenIndividualPopup] = useState();
  const [expandGroupId, setExpandGroupId] = useState(selected._id);
  const [placeholderMetricProps, setPlaceholderMetricProps] = useState({});
  const [disableDragGroup, setDisableDragGroup] = useState(false);

  const groupMetricRef = useRef({});

  useEffect(() => {
    if (selected._id !== expandGroupId && isEmpty(placeholderProps)) {
      setExpandGroupId(selected._id);
    }
  }, [selected]);

  useEffect(() => {
    //Removed selected view option of Group Body Metrics
    if (!location.search.includes('unique_code') || isEmpty(location.search)) {
      localStorage.removeItem(defaultView);
    }
  }, [location]);

  const handleSelectGroupMetric = (item = {}) => {
    push(`/home/client/${match.params.clientId}/metrics`);
    openIndividualPopup && setOpenIndividualPopup();
    if (expandGroupId === item._id) {
      !selectedMetricId && setExpandGroupId('');
      return;
    }

    setExpandGroupId(id => id !== item._id && item._id);
    typeof selectGroupMetric === 'function' && selectGroupMetric(item);

    if (item._id && groupMetricRef.current[item._id]) {
      setTimeout(() => {
        groupMetricRef.current[item._id].scrollIntoView({ block: 'nearest' });
      }, 0);
    }
  };

  const handleGroupBeforeCapture = (event = {}) => {
    const [type] = event.draggableId.split('_');

    if (type === 'metric') {
      setDisableDragGroup(true);
    }
  };

  const onRefInnerDiv = (ref, currentGroup, provided) => {
    if (provided) {
      provided.innerRef(ref);
    }

    if (currentGroup) {
      groupMetricRef.current[currentGroup._id] = ref;
    }
  };

  const handleGroupDragEnd = (event = {}) => {
    setDisableDragGroup(false);

    if (!event.destination) {
      return;
    }
    const { draggableId, destination: { index: destinationIndex } = {}, source: { index: sourceIndex } = {} } = event;
    const [type] = draggableId.split('_');

    switch (type) {
      case 'group': {
        setPlaceholderProps({});

        typeof organizeGroupMetric === 'function' &&
          organizeGroupMetric({
            startIndex: sourceIndex,
            endIndex: destinationIndex,
          });
        break;
      }
      case 'metric': {
        setPlaceholderMetricProps({});

        rearrangeMetricInGroup({
          startIndex: sourceIndex,
          endIndex: destinationIndex,
          group_id: selected._id,
          metric_id: (selected || {}).metrics[sourceIndex],
        });
        break;
      }
      default:
        break;
    }
  };

  const handleGroupDragStart = (event = {}) => {
    const { draggableId, source: { index: sourceIndex } = {} } = event;
    const draggedDOM = getDraggedDom(draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth, parentNode } = draggedDOM;
    const [type] = draggableId.split('_');

    switch (type) {
      case 'group': {
        const clientY =
          parseFloat(window.getComputedStyle(parentNode).paddingTop) +
          [...parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom + 2;
          }, 0);

        setPlaceholderProps({
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(window.getComputedStyle(parentNode).paddingLeft),
        });
        break;
      }
      case 'metric': {
        const clientY =
          parseFloat(window.getComputedStyle(parentNode).paddingTop) +
          [...parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
          }, 0);

        setPlaceholderMetricProps({
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(window.getComputedStyle(parentNode).paddingLeft),
        });
        break;
      }
      default:
        break;
    }
  };

  const handleGroupDragUpdate = (event = {}) => {
    if (!event.destination) return;

    const { draggableId, destination: { index: destinationIndex } = {}, source: { index: sourceIndex } = {} } = event;
    const draggedDOM = getDraggedDom(draggableId);

    if (!draggedDOM) return;

    const { clientHeight, clientWidth, parentNode } = draggedDOM;
    const [type] = draggableId.split('_');

    const childrenArray = [...parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    switch (type) {
      case 'group': {
        const clientY =
          parseFloat(window.getComputedStyle(parentNode).paddingTop) +
          updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom + 2;
          }, 0);

        setPlaceholderProps({
          clientHeight,
          clientWidth,
          clientY,
          clientX: parseFloat(window.getComputedStyle(parentNode).paddingLeft),
        });
        break;
      }
      case 'metric': {
        const clientY =
          parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
          updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
          }, 0);

        setPlaceholderMetricProps({
          clientHeight,
          clientWidth: 331,
          clientY,
          clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
        });
        break;
      }
      default:
        break;
    }
  };

  const getDraggedDom = draggableId => {
    const queryAttr = 'data-rbd-drag-handle-draggable-id';
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const handleIndividualTogglePopup = id => () => {
    setOpenIndividualPopup(it => it !== id && id);
  };

  const handleIconAction = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelectBodyMetric = metricItem => {
    if (isGettingChartData || isGettingTarget || isLoadingCompareChart || metricItem._id === selectedMetricId) {
      return;
    }
    const unitObject = getUnitObject(metricItem);
    const params = {
      unique_code: metricItem.unique_code || '',
      mid: metricItem._id || '',
      name: metricItem.name,
      unit: unitObject.title,
      unitId: unitObject._id,
    };
    updateMetricData(params);
    typeof toggleComparisonMode == 'function' && toggleComparisonMode(false, {});
    push(`/home/client/${match.params.clientId}/metrics?unique_code=${encodeURIComponent(params.unique_code)}`);
  };

  const handleDenyRemove = () => {
    toggleConfirmModal(false);
  };

  const handleRemoveGroup = ({ groupId, metrics }) => () => {
    toggleConfirmModal(false);
    removeGroupMetric({ groupId });
  };

  const handleCloseModal = () => {
    toggleModal(false);
  };

  const handleConfirmRemoveGroup = ({ groupId, isEmptyMetric, metrics }) => () => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        newStyle
        hasCloseIcon
        hasHoverState
        largeSpacing
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        title="Remove Metric Group"
        content="Are you sure that you want to delete this metric group?"
        confirmButtonTitle="Remove"
        cancelButtonTitle="Cancel"
        className="group-metric"
        onClose={handleDenyRemove}
        onDeny={handleDenyRemove}
        onConfirm={handleRemoveGroup({ groupId, isEmptyMetric, metrics })}
        isPressEsc={true}
      />,
    );
  };

  const handleSaveGroupToLibrary = groupId => () => {
    setOpenIndividualPopup(false);
    saveGroupToLibrary(groupId);
  };

  const handleAddMetricToGroup = group => event => {
    event.preventDefault();
    event.stopPropagation();

    toggleModal(
      true,
      <AddMetricsModal
        onClose={handleCloseModal}
        group={group}
        addMetricsToGroup={addMultipleMetricToGroup}
        clientId={get(match, 'params.clientId', '')}
        metricsNormalized={metricsNormalized}
        toggleConfirmModal={toggleConfirmModal}
      />,
    );
  };

  const contextValue = {
    user,
    selectedGroupId: selected._id,
    isLoadingMetric: isGettingChartData || isGettingTarget || isLoadingCompareChart,
    selectedMetricId,
    groupMetric,
    selectGroupMetric,
    organizeGroupMetric,
    groupMetricRef,
    placeholderProps,
    setPlaceholderProps,
    openIndividualPopup,
    setOpenIndividualPopup,
    handleSelectGroupMetric,
    onRefInnerDiv,
    handleGroupDragEnd,
    handleGroupDragStart,
    handleGroupDragUpdate,
    getDraggedDom,
    handleIndividualTogglePopup,
    handleIconAction,
    handleSelectBodyMetric,
    handleConfirmRemoveGroup,
    handleSaveGroupToLibrary,
    expandGroupId,
    handleAddMetricToGroup,
    handleGroupBeforeCapture,
    metricsNormalized,
    placeholderMetricProps,
    disableDragGroup,
  };

  return (
    <GroupBodyMetricsContext.Provider value={contextValue}>
      <OrganizeGroup clientId={get(match, 'params.clientId', '')} />
    </GroupBodyMetricsContext.Provider>
  );
};

const mapStateToProps = state => {
  const { user, bodyMetric, groupMetric, router } = state;

  return {
    user: user,
    bodyMetric: bodyMetric,
    groupMetric: groupMetric,
    location: router.location,
  };
};

const mapDispatchToProps = {
  selectGroupMetric,
  organizeGroupMetric,
  updateMetricData,
  push,
  toggleConfirmModal,
  removeGroupMetric,
  toggleModal,
  addMetricsToGroup,
  toggleComparisonMode,
  addMultipleMetricToGroup,
  rearrangeMetricInGroup,
  saveGroupToLibrary,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupBodyMetrics));
