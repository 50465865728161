import React from 'react';
import get from 'lodash/get';

import { roundNumberBodyMetric } from 'utils/commonFunction';

import * as S from '../style';

const MetricResponse = ({ answer }) => {
  const renderMetricValue = () => {
    if (answer && answer.additional_data) {
      const ft = get(answer, 'additional_data.metric_ft', '');
      const inch = get(answer, 'additional_data.metric_in', '');

      return `${roundNumberBodyMetric(ft)} ft ${roundNumberBodyMetric(inch)} in`;
    } else {
      const value = get(answer, 'metric_answer.value', '');
      const unit = get(answer, 'metric_answer.unit_unique_code', '');

      return `${roundNumberBodyMetric(value)} ${unit}`;
    }
  };

  return <S.QuestionAnswer>{renderMetricValue()}</S.QuestionAnswer>;
};

export default MetricResponse;
