import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CheckBoxIconWrapper = styled.div`
  height: ${props => (props.custom ? `${props.custom}` : '16px')}
  width: ${props => (props.custom ? `${props.custom}` : '16px')}
  background: #ffffff;
  border: 1px solid #dadfea;
  border-radius: 99999px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  .checkbox__circle {
    opacity: 0;
    height: 8px;
    width: 8px;
    background: #6261ff;
    border-radius: 99999px;
  }

  ${props =>
    props.active &&
    css`
      border: 2px solid #6261ff;
      box-sizing: border-box;
      .checkbox__circle {
        opacity: 1;
      }
    `}

  &:hover {
    border: 1px solid #6261ff;
  }
`;

const CheckboxCircle = ({ value, checked, label, onChange, className, customCheckbox = '' }) => {
  const handleClick = () => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  return (
    <Wrapper onClick={handleClick} className={classNames('evf-checkbox-circle', className)}>
      <CheckBoxIconWrapper active={checked} custom={customCheckbox}>
        <div className="checkbox__circle" />
      </CheckBoxIconWrapper>
      {label}
    </Wrapper>
  );
};

export default CheckboxCircle;
