import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import TaskCreate from 'components/TaskCreate/component';
import TaskCreateAutoflowInterval from 'components/AutoflowInterval/Task/TaskCreateContainer';
import { Container } from './style';
import TurnOnFeature from './TurnOnFeature';
import AutoflowMetrics from 'components/AutoflowMetrics';
import TaskHabit from 'components/TaskHabit';
import TaskLibrary from 'components/TaskLibrary';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { getAvailableMetrics } from 'utils/commonFunction';
import { AUTOFLOW_TYPES, CDN_URL, PLANS, TASK_TYPES } from 'constants/commonData';
import { ReactComponent as TaskForm } from 'assets/icons/task_option_form.svg';
import { ReactComponent as TaskHabitIcon } from 'assets/icons/task_option_habit.svg';
import UpgradePath from 'shared/UpgradePath';
import ImageGotoBilling from 'layouts/home/Home/components/ImageGotoBilling';
import './style.scss';

const TYPES = [
  { type: 'general', label: 'General', icon: `${CDN_URL}/images/task_option_general.svg` },
  {
    type: 'progress_photo',
    label: 'Progress Photo',
    icon: `${CDN_URL}/images/task_option_progress_photo.svg`,
  },
  {
    type: 'body_metric',
    label: 'Body Metrics',
    icon: `${CDN_URL}/images/task_option_body_metric.svg`,
  },
];

const TYPES_EXPAND = [
  { type: 'form', label: 'Form', icon: '' },
  { type: 'habit', label: 'Habit', icon: '' },
];

export default function Form(props) {
  const { mode, features, bodymetricTypes, isFormPermission, isHabitPermission, date, autoflowType } = props;
  const [showForm, setShowForm] = useState(mode === 'edit');
  const [type, setType] = useState(null);
  const [manageMetrics, setManageMetrics] = useState(false);
  const [showTaskLibrary, setShowTaskLibrary] = useState(false);

  useEffect(() => {
    props.fetchListTaskLibrary();
  }, []);

  const onItemClick = selectedType => {
    setType(selectedType);

    if (selectedType === 'body_metric') {
      const bodyMetricSetting = _.find(features, item => item.type === 'body_metric');

      if (!bodyMetricSetting) {
        return false;
      }

      if (!bodyMetricSetting.state) {
        return props.toggleConfirmModal(
          true,
          <TurnOnFeature
            title="Turn on Body Metrics"
            content="Would you like to turn on Body Metrics for all clients participating in this Autoflow?"
            icon={`${CDN_URL}/images/body_metric_green.svg`}
            onSuccess={() => {
              if (!bodymetricTypes.length) {
                setManageMetrics(true);
              } else {
                setShowForm(true);
              }
            }}
            type={selectedType}
          />,
        );
      } else {
        if (!bodymetricTypes.length) {
          return setManageMetrics(true);
        }
      }
    }

    if (selectedType === 'progress_photo') {
      const progressPhotoSetting = _.find(features, item => item.type === 'progress_photo');

      if (!progressPhotoSetting) {
        return false;
      }

      if (!progressPhotoSetting.state) {
        return props.toggleConfirmModal(
          true,
          <TurnOnFeature
            title="Turn on Progress Photo"
            content="Would you like to turn on Progress Photo for all clients participating in this Autoflow?"
            icon={`${CDN_URL}/images/progress_photo_green.svg`}
            onSuccess={() => setShowForm(true)}
            type={selectedType}
          />,
        );
      }
    }

    if (selectedType === 'habit') {
      return props.toggleModal(true, <TaskHabit date={date} type="habit" autoflowType={autoflowType} />);
    }

    setShowForm(true);
  };

  const onBackToList = () => {
    setShowTaskLibrary(false);
  };

  const openTaskLibraryModal = e => {
    e.preventDefault();
    setShowTaskLibrary(true);
  };

  if (showTaskLibrary) {
    return (
      <TaskLibrary
        {...props}
        type={type}
        date={props.date}
        onBack={onBackToList}
        from={props.autoflowType === AUTOFLOW_TYPES.INTERVAL ? AUTOFLOW_TYPES.INTERVAL : AUTOFLOW_TYPES.EXACT_DATE}
      />
    );
  }

  if (manageMetrics) {
    return (
      <AutoflowMetrics
        onClose={newMetrics => {
          setManageMetrics(false);

          const selectedMetrics = _.find(getAvailableMetrics(newMetrics), item => item.selected);

          if (selectedMetrics) {
            setShowForm(true);
          }
        }}
      />
    );
  }

  if (showForm) {
    if (mode === 'edit') {
      return props.autoflowType === AUTOFLOW_TYPES.INTERVAL ? (
        <TaskCreateAutoflowInterval {...props} />
      ) : (
        <TaskCreate {...props} getAttachmentUrl="/api/autoflow/task/v2/get-attachment" />
      );
    }

    return props.autoflowType === AUTOFLOW_TYPES.INTERVAL ? (
      <TaskCreateAutoflowInterval {...props} type={type} onBack={() => setShowForm(false)} />
    ) : (
      <TaskCreate
        {...props}
        getAttachmentUrl="/api/autoflow/task/v2/get-attachment"
        type={type}
        onBack={() => setShowForm(false)}
      />
    );
  }

  const TYPES_DEFAULT =
    !(isFormPermission || process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) &&
    !(isHabitPermission && process.env.REACT_APP_ENABLE_AUTOFLOW_HABIT)
      ? TYPES
      : [...TYPES, ...TYPES_EXPAND];

  return (
    <Modal
      open={true}
      onClose={() => props.toggleModal(false)}
      closeOnDimmerClick={false}
      className={classNames(
        'model--autoflow-task-option',
        {
          expand:
            !!isFormPermission ||
            process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH ||
            (!!isHabitPermission && process.env.REACT_APP_ENABLE_AUTOFLOW_HABIT),
        },
        {
          habit:
            (!!isFormPermission || process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) &&
            !!isHabitPermission &&
            process.env.REACT_APP_ENABLE_AUTOFLOW_HABIT,
        },
      )}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalLayout.Wrapper>
          <ModalLayout.Header className="model--autoflow-task-option__header">
            <div>Create a task</div>
            <a href="#" onClick={openTaskLibraryModal}>
              Choose from Library
            </a>
          </ModalLayout.Header>
          <ModalLayout.Content>
            <Container
              isExpand={
                !!isFormPermission ||
                process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH ||
                (!!isHabitPermission && process.env.REACT_APP_ENABLE_AUTOFLOW_HABIT)
              }
              hasHabit={
                (!!isFormPermission || process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH) &&
                !!isHabitPermission &&
                process.env.REACT_APP_ENABLE_AUTOFLOW_HABIT
              }
            >
              {_.map(TYPES_DEFAULT, item => {
                if (item.type === TASK_TYPES.FORM) {
                  if (!(isFormPermission || process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES_UPGRADE_PATH)) return;
                  return (
                    <UpgradePath
                      pathName="form_questionnaire"
                      fallback={
                        <div key={item.type} className="item with-upgrade-path">
                          <ImageGotoBilling
                            imageTitle={'Upgrade your subscription to use Forms & Questionnaires'}
                            maxWidthContent={373}
                            planWillSelect={{ tier: PLANS.pro.key }}
                            notSendPrev={false}
                            isNotUsePathName={true}
                            onClick={() => {
                              props.toggleModal(false);
                            }}
                          />
                          <div className="icon">
                            <TaskForm />
                          </div>
                          <p>{item.label}</p>
                        </div>
                      }
                    >
                      <div key={item.type} className="item" onClick={() => onItemClick(item.type)}>
                        <div className="icon">
                          <TaskForm />
                        </div>
                        <p>{item.label}</p>
                      </div>
                    </UpgradePath>
                  );
                }
                if (item.type === TASK_TYPES.HABIT) {
                  if (!(isHabitPermission && process.env.REACT_APP_ENABLE_AUTOFLOW_HABIT)) return;
                  return (
                    <UpgradePath
                      fallback={
                        <div className="item with-upgrade-path">
                          <ImageGotoBilling
                            imageTitle={'Upgrade your subscription to use Habit'}
                            maxWidthContent={373}
                            planWillSelect={{ tier: PLANS.pro.key }}
                            notSendPrev={false}
                            isNotUsePathName={true}
                            onClick={() => onItemClick(item.type)}
                          />
                          <TaskHabitIcon />
                          <p>Habit</p>
                        </div>
                      }
                      pathName="habit"
                    >
                      <div onClick={() => onItemClick(item.type)} className="item">
                        <TaskHabitIcon />
                        <p>Habit</p>
                      </div>
                    </UpgradePath>
                  );
                }

                return (
                  <div key={item.type} className="item" onClick={() => onItemClick(item.type)}>
                    <img src={item.icon} height={60} />
                    <p>{item.label}</p>
                  </div>
                );
              })}
            </Container>
          </ModalLayout.Content>
        </ModalLayout.Wrapper>
      </Modal.Content>
    </Modal>
  );
}
