import React from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import DialogAddNew from '../DialogAddNew';
import * as S from './style';

const EmptyList = ({ toggleModal, categoryLabels, addNewOnDemandWorkouts }) => {
  const handleClick = () => {
    toggleModal(
      true,
      <DialogAddNew
        onClose={handleClose}
        toggleModal={toggleModal}
        categoryLabels={categoryLabels}
        addNewOnDemandWorkouts={addNewOnDemandWorkouts}
      />,
    );
  };
  const handleClose = () => {
    toggleModal(false);
  };
  return (
    <S.EmptyList>
      <S.CustomAddFirstIcon />
      <div className="text">Add your first on-demand workout!</div>
      <S.Button
        text="Add New Workout"
        height={30}
        width={167}
        icon={<PlusIcon />}
        className="buttonClassName"
        onClick={handleClick}
      />
    </S.EmptyList>
  );
};

export default EmptyList;
