import React from 'react';

import { ReactComponent as DownArrowIcon } from 'assets/icons/arrow_down_new.svg';

function CustomDropdownIndicator() {
  return (
    <span className="survey__indicator">
      <DownArrowIcon />
    </span>
  );
}

export default CustomDropdownIndicator;
