import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { maximumRenderedExercises } from 'helpers/workout';
import SectionOverview from 'components/SectionOverview';
import { Workout, ExerciseSet } from 'types/model';
import ExerciseDetail from 'components/ExerciseDetail';
import { counterBehindExercises, LIMIT_SELECTED_WORKOUTS, pluralize } from 'utils/commonFunction';
import Dropdown, { Option, Trigger } from 'shared/Dropdown/Basic';
import { DRAGGALE_TYPE } from '../constants';
import { deleteExerciseSet, updateSetNumberInSection } from 'helpers/workout';
import { CDN_URL, SECTION_FORMAT_KEY } from 'constants/commonData';
import { Checkbox } from 'shared/FormControl';
import { SharedTooltip } from 'shared/SharedTooltip';
import {
  handleResetWeek,
  handleResetWorkout,
  handleSelectWorkout,
  resetStudioProgramCopyItem,
} from 'redux/studio-program/actions';
import ConfirmModal from 'shared/ConfirmModal';
import { toggleConfirmModal } from 'actions/modal';

import {
  WorkoutWrapper,
  WorkoutContainer,
  WorkoutHeader,
  WorkoutSectionsContainer,
  WorkoutFooter,
  SectionContainer,
  ViewMoreItem,
  CheckBoxWrapper,
} from './style';

class WorkoutDraggable extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  getMaximumRenderedExercises = () => {
    const { cellContentHeight, currentWorkout, totalWorkouts } = this.props;
    const workoutHeaderHeight = 24;
    const workoutFooterHeight = 22;
    const workoutCompressedHeight = 52;
    let factor = 2; // border

    factor += workoutHeaderHeight + workoutFooterHeight;
    factor += (totalWorkouts - 1) * workoutCompressedHeight;

    const containerHeight = cellContentHeight - factor;
    return maximumRenderedExercises(currentWorkout, containerHeight, 74, 52);
  };

  onSaveExercise = data => {
    const { currentWorkout } = this.props;
    const { exercise, sectionIndex, setIndex, exIndex } = data;
    const newWorkout = _.cloneDeep(currentWorkout);
    let section = newWorkout.sections[sectionIndex];

    if (!section) {
      return console.error('Cannot find section with index = ' + sectionIndex);
    }

    if (section.exercises[setIndex]) {
      section.exercises[setIndex].supersets[exIndex] = exercise;
    }

    updateSetNumberInSection(newWorkout.sections[sectionIndex], setIndex, exIndex);
    this.props.onUpdateSection(newWorkout);
  };

  onDeleteExercise = (sectionIndex, setIndex, exIndex) => {
    const { currentWorkout } = this.props;
    const copyWorkout = _.cloneDeep(currentWorkout);
    deleteExerciseSet(copyWorkout, sectionIndex, setIndex, exIndex);
    this.props.onUpdateSection(copyWorkout);
  };

  renderSuperset = (section, totalRendered, sectionIndex) => {
    const { dayIndex, weekIndex, currentWorkout, cellId, disabled } = this.props;
    const views = [];
    const set = section.exercises ? section.exercises[0] : { supersets: [] };

    set.supersets.map((exSet, exerciseIndex) => {
      if (exerciseIndex < totalRendered) {
        const currentSet = _.cloneDeep(exSet);
        const popupId = `${cellId}_${currentWorkout._id}_${section._id}_${exerciseIndex}`;
        views.push(
          <div key={exerciseIndex}>
            <ExerciseDetail
              exerciseSet={new ExerciseSet(currentSet)}
              assignment={new Workout(currentWorkout)}
              pId={popupId}
              exIndex={exerciseIndex}
              day={dayIndex}
              week={weekIndex}
              setIndex={0}
              sectionIndex={sectionIndex}
              mode="program_library_workout_popup"
              onClone={exercise => this.props.onAddSection({ assignment: currentWorkout, exercise })}
              onSave={data => this.onSaveExercise({ ...data, sectionIndex, exIndex: exerciseIndex, setIndex: 0 })}
              onDelete={() => this.onDeleteExercise(sectionIndex, 0, exerciseIndex)}
              disabled={disabled}
            />
            {exerciseIndex < totalRendered - 1 ? (
              <div style={{ backgroundColor: 'gainsboro', height: 1, margin: '0 8px' }} />
            ) : null}
          </div>,
        );
      }
    });

    return views;
  };

  renderSections = maxSectionsRendered => {
    const { currentWorkout, onlyShowOverview, isShowAllWorkouts, draggingWorkout, cellId, disabled } = this.props;
    const { sections } = currentWorkout;
    const views = [];
    const isRenderSections = !draggingWorkout && (!onlyShowOverview || isShowAllWorkouts);

    if (isRenderSections) {
      let totalRendered = 0;

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        let numOfExercises = _.sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
        if (section.format === SECTION_FORMAT_KEY.FREESTYLE) {
          numOfExercises = section.exercise_references.length || 1;
        }
        const rendered = Math.min(numOfExercises, maxSectionsRendered - totalRendered);

        if (!rendered) {
          break;
        } else {
          totalRendered += rendered;
          const sectionDraggableId = `section_${cellId}_${currentWorkout._id}_${section._id}`;
          views.push(
            <Draggable
              key={sectionDraggableId}
              draggableId={sectionDraggableId}
              index={i}
              type={DRAGGALE_TYPE.SECTION}
              isDragDisabled={disabled}
            >
              {(provided, snapshot) => (
                <SectionContainer
                  className={classnames('workout__section', {
                    'workout__section--hidden': section.type === 'hidden',
                    'workout__section--dragging': snapshot.isDragging,
                  })}
                  style={{ ...provided.draggableProps.style }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {section.type === 'hidden' ? (
                    this.renderSuperset(section, rendered, i)
                  ) : (
                    <SectionOverview
                      key={section._id}
                      section={section}
                      onClick={() => this.props.viewWorkoutDetail(currentWorkout, section._id)}
                    />
                  )}
                </SectionContainer>
              )}
            </Draggable>,
          );
        }
      }
    }

    return <WorkoutSectionsContainer className="workout__sections">{views}</WorkoutSectionsContainer>;
  };

  saveWorkoutToLibrary = () => {
    this.props.saveWorkoutToLibrary(this.props.currentWorkout);
  };

  copyCurrentWorkout = () => {
    const { selectedWorkout, handleResetWorkout } = this.props;
    if (!_.isEmpty(selectedWorkout)) {
      handleResetWorkout();
    }
    this.props.copyWorkout(this.props.currentWorkout);
  };

  deleteCurrentWorkout = () => {
    this.props.deleteWorkout(this.props.currentWorkout);
  };

  moveWorkoutUp = () => {
    const { currentWorkout, workoutIndex } = this.props;
    this.props.arrangeWorkoutInDay(currentWorkout, workoutIndex - 1);
  };

  moveWorkoutDown = () => {
    const { currentWorkout, workoutIndex } = this.props;
    this.props.arrangeWorkoutInDay(currentWorkout, workoutIndex + 1);
  };

  renderTriggerIcon = ({ open }) => {
    const { currentWorkout } = this.props;
    return (
      <>
        <Trigger data-tip data-for={`workout-cell-tooltip-${(currentWorkout || {})._id}`} />
        {!open && <SharedTooltip id={`workout-cell-tooltip-${(currentWorkout || {})._id}`} />}
      </>
    );
  };

  renderActions = () => {
    const { workoutIndex, totalWorkouts, disabled } = this.props;

    if (disabled) {
      return (
        <Dropdown triggerIcon={this.renderTriggerIcon}>
          <Option onClick={this.saveWorkoutToLibrary} key="save">
            <img src={`${CDN_URL}/images/save.svg`} alt="save" />
            <span>Save to library</span>
          </Option>
        </Dropdown>
      );
    }

    return (
      <Dropdown triggerIcon={this.renderTriggerIcon}>
        {totalWorkouts > 1 && workoutIndex > 0 ? (
          <Option onClick={this.moveWorkoutUp} key="move-up">
            <img src={`${CDN_URL}/images/workout_up.svg`} alt="move-up" />
            <span>Move up</span>
          </Option>
        ) : null}
        {totalWorkouts > 1 && workoutIndex < totalWorkouts - 1 ? (
          <Option onClick={this.moveWorkoutDown} key="move-down">
            <img src={`${CDN_URL}/images/workout_down.svg`} alt="move-down" />
            <span>Move down</span>
          </Option>
        ) : null}
        <Option onClick={this.saveWorkoutToLibrary} key="save">
          <img src={`${CDN_URL}/images/save.svg`} alt="save" />
          <span>Save to library</span>
        </Option>
        <Option onClick={this.copyCurrentWorkout} key="copy">
          <img src={`${CDN_URL}/images/duplicate.svg`} alt="copy" />
          <span>Copy</span>
        </Option>
        <Option onClick={this.deleteCurrentWorkout} key="delete">
          <img src={`${CDN_URL}/images/delete.svg`} alt="delete" />
          <span>Delete</span>
        </Option>
      </Dropdown>
    );
  };

  handleChangeWorkout = (currentWorkout, checked) => {
    const {
      handleSelectWorkout,
      selectedWorkout,
      handleResetWeek,
      copying,
      resetStudioProgramCopyItem,
      selectedWeek,
      handleResetWorkout,
    } = this.props;

    if (!_.isEmpty(selectedWeek)) {
      handleResetWeek();
    }

    if (!_.isEmpty(copying)) {
      resetStudioProgramCopyItem();
    }

    const lengthWorkout = selectedWorkout.length;
    const currentWorkoutId = _.get(currentWorkout, '_id');

    if (checked) {
      if (lengthWorkout < LIMIT_SELECTED_WORKOUTS) {
        handleSelectWorkout({
          workoutIds: [...selectedWorkout, currentWorkoutId],
        });
      }
      lengthWorkout === LIMIT_SELECTED_WORKOUTS && this.handlePopupLimitWorkout();
    } else {
      const newSelectedWorkout = _.filter(selectedWorkout, id => id !== currentWorkoutId);
      handleSelectWorkout({
        workoutIds: newSelectedWorkout,
      });
      if (_.isEmpty(newSelectedWorkout)) {
        handleResetWorkout();
      }
    }
  };

  handlePopupLimitWorkout = () => {
    const { selectedWorkout, toggleConfirmModal } = this.props;

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          headerIcon={`${CDN_URL}/images/warning_purple.svg`}
          title={`Maximum of ${selectedWorkout.length} workouts selected`}
          content={`You can select up to ${selectedWorkout.length} workouts at a time. Please complete your action and then select more.`}
          onConfirm={() => {}}
          onDeny={() => {}}
          confirmButtonTitle="Okay"
          hideCancelButton
          noBorder={true}
          hasCloseIcon={true}
          className="calendar--max-select"
        />,
      );
  };

  render() {
    const {
      currentWorkout,
      workoutIndex,
      cellId,
      isShowAllWorkouts,
      onlyShowOverview,
      disabled,
      selectedWorkout = [],
      workingStudio,
    } = this.props;
    const dndId = `${cellId}_${currentWorkout._id}`;
    const workoutDraggableId = `workout_${dndId}`;
    const maxSectionsRendered = isShowAllWorkouts ? 1000 : onlyShowOverview ? 0 : this.getMaximumRenderedExercises();

    const remainExercises = counterBehindExercises(currentWorkout, maxSectionsRendered);
    const isChecked = selectedWorkout.includes(currentWorkout._id);
    const isEnableCopyWeek = !disabled
      ? (workingStudio.isPublished && workingStudio.isEditMode) || !workingStudio.isPublished
      : false;

    return (
      <Draggable
        key={workoutDraggableId}
        draggableId={workoutDraggableId}
        type={DRAGGALE_TYPE.WORKOUT}
        index={workoutIndex}
        isDragDisabled={disabled}
      >
        {provided => (
          <WorkoutWrapper
            className={classnames('studio-program-cell__workout-wrapper workout-draggable-container', {
              disableDraggale: !!disabled,
            })}
            style={{ ...provided.draggableProps.style, margin: '0 5px' }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Droppable droppableId={dndId} type={DRAGGALE_TYPE.SECTION} isDropDisabled={disabled}>
              {(provided, snapshot) => {
                const dragOver = snapshot.isDraggingOver ? { background: '#f3f5f8' } : {};
                return (
                  <WorkoutContainer
                    ref={provided.innerRef}
                    className="studio-program-cell__workout-container workout-droppage-container"
                    style={{ ...dragOver }}
                  >
                    <WorkoutHeader className="workout__header">
                      <div
                        className="workout__title"
                        onClick={() => this.props.viewWorkoutDetail(currentWorkout)}
                        data-tip
                        data-for={`tooltip--${dndId}`}
                      >
                        {currentWorkout.title}
                      </div>
                      <ReactTooltip className="app-tooltip" id={`tooltip--${dndId}`} effect="solid" place={'top'}>
                        <span>{currentWorkout.title || ''}</span>
                      </ReactTooltip>
                      {!disabled && process.env.REACT_APP_COPY_WEEK_ENABLE_V3 && isEnableCopyWeek && (
                        <CheckBoxWrapper>
                          <Checkbox
                            checked={isChecked}
                            onChange={event => {
                              event.stopPropagation();
                              this.handleChangeWorkout(currentWorkout, event.target.checked);
                            }}
                            className={classNames('studio-program--checkbox-workout', {
                              hidden: _.isEmpty(selectedWorkout),
                              show: !_.isEmpty(selectedWorkout),
                            })}
                          />
                        </CheckBoxWrapper>
                      )}
                      {this.renderActions()}
                    </WorkoutHeader>
                    {this.renderSections(maxSectionsRendered)}
                    <WorkoutFooter className="workout__footer">
                      {remainExercises > 0 ? (
                        <ViewMoreItem className="workout__view-more-exercises" onClick={this.props.viewAllData}>
                          {pluralize(`+ ${remainExercises} more exercise`, remainExercises)}
                        </ViewMoreItem>
                      ) : (
                        <div />
                      )}
                      {!disabled ? (
                        <ExerciseDetail
                          mode={'new_popup'}
                          assignment={currentWorkout}
                          pId={`${cellId}_${currentWorkout._id}_new-exercise`}
                          onSave={this.props.onAddSection}
                        />
                      ) : null}
                    </WorkoutFooter>
                    {provided.placeholder}
                  </WorkoutContainer>
                );
              }}
            </Droppable>
          </WorkoutWrapper>
        )}
      </Draggable>
    );
  }
}

const mapState = (state, ownerProps) => {
  const {
    rootReducer: {
      studioProgram: { selectedWorkout, workingStudio, copying, selectedWeek },
    },
  } = state;

  return { selectedWorkout, workingStudio, copying, selectedWeek };
};

const mapDispatch = dispatch => ({
  handleSelectWorkout: bindActionCreators(handleSelectWorkout, dispatch),
  handleResetWeek: bindActionCreators(handleResetWeek, dispatch),
  handleResetWorkout: bindActionCreators(handleResetWorkout, dispatch),
  resetStudioProgramCopyItem: bindActionCreators(resetStudioProgramCopyItem, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
});

export default connect(mapState, mapDispatch)(WorkoutDraggable);
