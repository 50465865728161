import React, { useState, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';

import {
  Header,
  Body,
  Container,
  Gallery,
  Like,
  Caption,
  GalleryItem,
  AuthorNameContainer,
  PostStatsContainer,
  Dropdown,
  DropItem,
  AvatarContainer,
  EmptyComments,
  ShowMoreText,
  SConfirmModal,
  Notification,
  ScheduledBox,
  ScheduledTime,
  UnscheduledTime,
  UnscheduledBox,
  RemoveScheduleConfirmModal,
} from './style';
import Interactions from './components/Interactions';
import ShowError from '../../../ShowError';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import {
  convertS3UrlToCloudFrontUrl,
  getFormatImageUrlWithSML,
  getUserShortName,
  mediaLog,
  ownerOfForum,
} from 'utils/commonFunction';
import Button from 'shared/FormControl/Button';
import { PreviewVideoLink, PreviewArticleLink } from 'shared/PreviewLink';

import {
  deletePost,
  addComment,
  deleteComment,
  getListComments,
  getListReply,
  resetListComment,
  pinPost,
  unPinPost,
  checkPostsIndicator,
  updatePostsAfterViewDetail,
  saveScheduleTemp,
  editPost,
  addCommentWithMedia,
  getListPost,
  resetLoadPostsQuery,
} from 'redux/forum/actions';
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import { showError, hideError } from 'actions/error';
import Comments from './components/Comments';
import { TYPES as COMMENT_TYPES } from 'shared/ChatInput';
import { MEDIA_FILE_REJECTED_CONTENT, TOPICS } from 'constants/commonData';
import { getMediaType, pluralize } from 'utils/commonFunction';
import { FORUM_MOMENT_SETTING, POST_TYPES, TYPE_ERROR } from 'components/CommunityForum/constants.js';
import ShareLeaderboardPost from 'components/CommunityForum/components/ShareLeaderboardPost/index.js';
import MentionEditor from 'components/CommunityForum/components/MentionEditor';
import PopUpForm from '../CreatePostForm/components/PopUpForm';
import GiphyWatermark from 'shared/Giphy/components/Watermark';
import SchedulePicker from 'components/CommunityForum/components/ForumTimeline/components/SchedulePost/SchedulePicker';
import { uploadMediaBackgroundRequest, uploadMediaBackgroundSuccess } from 'redux/upload-background/actions';

import { ReactComponent as RescheduleIcon } from 'assets/icons/reschedule_post.svg';
import { ReactComponent as PostNowIcon } from 'assets/icons/publish_post.svg';
import { ReactComponent as RemovePostIcon } from 'assets/icons/remove_post.svg';
import { ReactComponent as PinnedPostIcon } from 'assets/icons/pin_post_op.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action_edit_post.svg';
import TrashIcon from 'assets/icons/red_trash_circle.svg';
import WarningIcon from 'assets/icons/warning_red_light.svg';
import PollPost from '../PollPost';
import { SharedTooltip } from 'shared/SharedTooltip';

const likeSummary = (userId, all) => {
  if (!all.length) {
    return '';
  }

  const others = all.slice();
  let text = '';
  const currentUser = _.remove(others, item => item === userId);

  if (!others.length) {
    return 'you';
  }
  if (!currentUser.length) {
    text += `${others.length} ${pluralize('other', others.length)}`;
  } else {
    text += `you and ${others.length} ${pluralize('other', others.length)}`;
  }

  return text;
};

function Post(props) {
  const {
    data,
    push,
    userId,
    root,
    onMediaClick,
    onCommentClick,
    members,
    user,
    group,
    updatePostsAfterViewDetail,
    isScheduledPost,
    addCommentWithMedia,
    getListReply,
    enabledAddons,
    openingDropdown,
    onToggleDropdown,
    permission,
    cloudfrontList,
  } = props;
  const {
    caption,
    _id,
    total_comment,
    author,
    isPin,
    published_at,
    status,
    listComment = [],
    createdAt,
    indexStartList,
    liker_info,
    preview,
    poll,
  } = data;
  const listCommentRender = listComment.slice(indexStartList);
  const [shouldOpenComment, setShouldOpenComment] = useState(false);
  const [isShowMore, setIsShowMore] = useState(true);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  const [loading, setLoading] = useState(false);
  const toggleReadMore = () => setIsShowMore(show => !show);
  const captionRef = useRef(null);
  let callbackFunction = useRef(null);

  const position = window.location.pathname.indexOf('post-item/');
  const galleryEle = window.location.pathname.slice(position + 'post-item/'.length);

  const typeMedia = ['image', 'video', 'image/gif'];
  if (!data) {
    return null;
  }

  const handleCallBackFunction = callback => {
    callbackFunction.current = callback;
  };

  const media = _.filter(data.attachments, attachment => typeMedia.includes(attachment.type)) || [];

  // const liker =
  const liker = data.liker;
  const shareLeaderboardPostData =
    data.post_type === POST_TYPES.LEADERBOARD ? _.get(data, 'leaderboard_snapshot', null) : null;

  const renderedPictures = media.slice(0, 5);
  const more = media.length - renderedPictures.length;
  let likeSum = likeSummary(userId, _.compact(liker));
  const liked = _.find(data.liker, item => item === userId);

  const isAuthor = _.isObject(author) ? userId === author._id : userId === user._id;
  const showRemovePost = isAuthor || ownerOfForum(user, group);
  const isTeammate = user.is_trainer;
  const isScheduleAutoflow = isScheduledPost && !enabledAddons.automation && !permission.autoflow;
  const isUnscheduledPost = status === 'UNSCHEDULED';
  const isUnpermitted = (!permission.autoflow && !enabledAddons.automation) || !isAuthor;
  const isPreviewMeta = _.some(['youtube', 'vimeo', 'youtu.be'], keyword =>
    _.includes(_.toLower(_.get(preview, 'url', '')), keyword),
  );
  const hasPreviewData = !_.isEmpty(preview) && _.isEmpty(media) && _.isEmpty(poll);
  const hasPreviewStyle = hasPreviewData && !likeSum.length && !total_comment && _.isEmpty(poll);

  /**
  * NOTE: Forum roles
    FORUM_AUTHOR_ROLE = 3;
    FORUM_ADMIN_ROLE = 2;
    FORUM_COACH_ROLE = 1;
    FORUM_CLIENT_ROLE = 0;
  */

  const member = _.find(members, ['_id', data.author._id]);
  const isClient = member ? member.role === 0 : '';

  if (likeSum.includes('and')) {
    likeSum = likeSum.replace(/and/g, `<span class="and-text">and</span>`);
  }
  const escFunction = event => {
    if (event.key === 'Escape') {
      props.toggleConfirmModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, []);

  useEffect(() => {
    moment.locale(moment.locale(), {
      relativeTime: FORUM_MOMENT_SETTING,
    });
  }, []);

  // NOTE: Remove for improve forum
  // useEffect(() => {
  //   props.getListComments(_id).then(response => {
  //     const { data, total } = response.data;
  //     setComments(data);
  //     setTotalComments(total);
  //   });
  // }, [total_comment]);

  useEffect(() => {
    const textLength = caption.length;

    const currentHeight = captionRef.current ? captionRef.current.clientHeight : 0;
    setIsShowMore(textLength > 333 || currentHeight > 84);
    // NOTE: Remove for improve forum
    // handleMoveToPostPosition();
  }, [caption.length, captionRef.current]);

  const handleMoveToPostPosition = () => {
    const {
      getAllListPost,
      match: {
        params: { postId },
      },
    } = props;
    const position = window.location.pathname.indexOf('post-item/');
    const ele = window.location.pathname.slice(position + 'post-item/'.length);
    const sectionEle = document.getElementById(ele);
    const navBar = document.querySelector('.app-navbar');

    if (position > -1) {
      getAllListPost().then(item => {
        if (sectionEle) {
          // TODO: Remove scroll to post detail when click from Notification
          // onScrollFromTop(sectionEle.offsetTop - navBar.clientHeight * 2, () => {
          //   setTimeout(() => {
          //     const gallery = document.getElementById(`gallery__item_${ele}_0`);
          //     if (gallery) gallery.click();
          //   }, 250);
          // });
        }
      });
    }
  };

  const handleDeletePost = () => {
    props.deletePost(_id);
  };

  const handleOpenDeletePopup = () => {
    props.toggleConfirmModal(
      true,
      <SConfirmModal
        noBorder
        headerIcon={WarningIcon}
        hasCloseIcon
        hasHoverState
        title="Remove Post?"
        content="The post and all comments will be removed permanently."
        onConfirm={handleDeletePost}
        confirmButtonTitle="Remove"
      />,
    );
  };

  const handleDeleteSchedulePopup = () => {
    props.toggleConfirmModal(
      true,
      <RemoveScheduleConfirmModal
        noBorder
        headerIcon={WarningIcon}
        hasCloseIcon
        hasHoverState
        title="Remove post?"
        content="Are you sure? This scheduled post will be removed permanently."
        onConfirm={handleDeletePost}
        confirmButtonTitle="Remove"
        className="remove-schedule-post"
      />,
    );
  };

  const handleOnCommentClick = () => {
    const { getListComments, resetListComment } = props;
    setShouldOpenComment(!shouldOpenComment);
    if (!shouldOpenComment) {
      getListComments(_id);
    } else {
      resetListComment(_id);
    }
  };

  const initLocalCommentData = (comment, index, replyMessage) => {
    const { user } = props;
    const { file, objectURL, dimensions } = comment;
    const timestamp = new Date().getTime();
    const data = new FormData();
    data.append('attachment', file);

    return {
      uploadConfigs: uploadConfigs,
      attachment: objectURL,
      attachment_type: getMediaType(file),
      attachment_width: _.get(dimensions, 'width'),
      attachment_height: _.get(dimensions, 'height'),
      _id: `new_media__${timestamp}__${index}`,
      createdAt: timestamp,
      author: user,
      isLocalFile: true,
      content: MEDIA_FILE_REJECTED_CONTENT,
      loadingReply: true,
      replyMessage,
    };
  };

  const addLocalCommentToList = (localComments, replyMessage) => {
    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');

    const { uploadMediaBackgroundRequest } = props;

    const ids = localComments.map(item => _.get(item, '_id', ''));
    if (uploadMediaBackgroundRequest) {
      uploadMediaBackgroundRequest(ids);
    }

    const listCommentConvert = listComment.map(item => {
      const { latestReplyList = [], _id = '', total_replies = 0, isViewMore } = item;

      if (_id === commentId) {
        return {
          ...item,
          total_replies: total_replies + 1,
          latestReplyList: latestReplyList.concat(localComments),
          isViewMore: isViewMore || false,
        };
      }
      return item;
    });
    addCommentWithMedia(
      { data: isReply ? listCommentConvert : listComment.concat(localComments), postId: _id },
      indexStartList,
    );
  };

  const handleAddComment = (comment, replyMessage, callback) => {
    const { addComment } = props;
    const { type, data, taggedPeople, tagAll } = comment;
    const commentId = _.get(replyMessage, 'commentId', '');

    const isReply = !_.isEmpty(replyMessage);

    if (type === COMMENT_TYPES.TEXT && !loading) {
      setLoading(true);
      const body = {
        item: isReply ? commentId : _id,
        topic: isReply ? TOPICS.REPLY_COMMENT : 'forum_post',
        content: data,
        tagged_people: taggedPeople,
        tag_all: tagAll,
        groupId: group._id,
      };

      addComment(body, '', indexStartList)
        .then(response => {
          const { total_comment } = response.data;
          updatePostsAfterViewDetail(_id, { total_comment: total_comment });
          callbackFunction.current && callbackFunction.current();
          callback && callback();
          setLoading(false);
        })
        .catch(err => {
          handleErrorResponse(err, isReply, () => {
            callbackFunction.current && callbackFunction.current();
            callback && callback();
          });
          setLoading(false);
        });
    }

    if (type === COMMENT_TYPES.FILE) {
      const localComments = _.map(data, (item, index) => initLocalCommentData(item, index, replyMessage));
      addLocalCommentToList(localComments, replyMessage);
    }

    if (type === 'image/gif') {
      const body = {
        ...data,
        topic: isReply ? TOPICS.REPLY_COMMENT : 'forum_post',
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : _id,
      };

      addComment(body, '', indexStartList)
        .then(response => {
          const { total_comment } = response.data;
          updatePostsAfterViewDetail(_id, { total_comment: total_comment });
        })
        .catch(err => {
          handleErrorResponse(err, isReply);
        });
    }

    if (type !== COMMENT_TYPES.TEXT) {
      callbackFunction.current && callbackFunction.current();
    }
  };

  const handleDeleteComment = comment => {
    props.toggleConfirmModal(
      true,
      <SConfirmModal
        hasHoverState
        noBorder
        headerIcon={TrashIcon}
        title="Remove Comment"
        hasCloseIcon
        content="The comment will be deleted permanently."
        onConfirm={() => handleSubmitComment(comment)}
        confirmButtonTitle="Remove"
      />,
    );
  };

  const handleSubmitComment = comment => {
    const { deleteComment } = props;
    callbackFunction.current && callbackFunction.current();
    deleteComment(comment, _id).then(response => {
      if (response.data) toast('Comment deleted successfully.');
    });
  };

  const cancelUpload = (comment, error) => {
    const { uploadMediaBackgroundSuccess } = props;
    const { replyMessage, _id: commentIdLocal = '' } = comment;

    const isReply = !_.isEmpty(replyMessage);
    const commentId = _.get(replyMessage, 'commentId', '');

    if (uploadMediaBackgroundSuccess && commentIdLocal) {
      uploadMediaBackgroundSuccess([commentIdLocal]);
    }

    if (isReply) {
      const listCommentConvert = listComment.map(item => {
        const { _id = '', total_replies = 0, latestReplyList = [] } = item;
        if (commentId === _id) {
          return {
            ...item,
            total_replies: total_replies - 1,
            latestReplyList: _.filter(latestReplyList, c => c._id !== commentIdLocal),
          };
        }
        return item;
      });
      addCommentWithMedia({ data: listCommentConvert, postId: _id }, indexStartList);
    } else {
      addCommentWithMedia({ data: _.filter(listComment, c => c._id !== commentIdLocal), postId: _id }, indexStartList);
    }
  };

  const uploadSuccess = (item, responseData) => {
    const { uploadMediaBackgroundSuccess } = props;

    if (uploadMediaBackgroundSuccess && _.get(item, '_id', '')) {
      uploadMediaBackgroundSuccess([item._id]);
    }

    const { replyMessage } = item;
    const commentId = _.get(replyMessage, 'commentId', '');
    const isReply = !_.isEmpty(replyMessage);
    const comment = isReply ? item : _.find(listComment, c => c._id === item._id);

    if (!comment) {
      return false;
    }

    const { config } = responseData;

    if (config || item) {
      const { url } = config;
      const { addComment } = props;

      const { attachment_type, attachment_height, attachment_width, _id: commentLocalId = '' } = comment;
      const attachment = url.substring(0, url.indexOf('?X-Amz-Algorithm'));
      let attachmentID = attachment ? attachment.split('/') : [];

      if (attachmentID.length > 0) {
        attachmentID = attachmentID[attachmentID.length - 1];
        attachmentID = attachmentID.substring(0, attachmentID.indexOf('.'));
      }

      const body = {
        attachment: attachment,
        attachment_id: attachmentID,
        attachment_type,
        attachment_height,
        attachment_width,
        topic: isReply ? TOPICS.REPLY_COMMENT : 'forum_post',
        content: MEDIA_FILE_REJECTED_CONTENT,
        item: isReply ? commentId : _id,
      };

      mediaLog({
        status: 2,
        name: attachment,
        fileType: attachment_type,
        description: 'Upload success file via forum comment',
      });

      addComment(body, commentLocalId, indexStartList).catch(err => {
        handleErrorResponse(err, isReply);
      });
    }
  };

  const getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, cloudfrontList, true);

  const renderAvatars = users => {
    if (!users) return;
    return (
      <AvatarContainer twoAvatars={_.get(users.length) >= 2}>
        {users.slice(0, 2).map(user => (
          <Avatar
            name={getUserShortName(user)}
            className="avatar"
            size="20"
            src={user ? getCloudUrl(user.avatar) : ''}
          />
        ))}
      </AvatarContainer>
    );
  };

  const handleEditPost = () => {
    const post = _.get(props, 'posts', []).find(it => it._id === _id);
    props.toggleModal(true, <PopUpForm {...props} isEdit={true} post={post} pollData={poll} />);
  };

  const handleEditScheduledPost = () => {
    let schedulePosts = _.get(props, 'schedulePosts', []).find(it => it._id === _id);
    props.saveScheduleTemp(schedulePosts);
    props.toggleModal(
      true,
      <PopUpForm
        {...props}
        isSchedulePost={true}
        isEditSchedule={true}
        isShowReschedule
        pollData={(schedulePosts || {}).poll}
      />,
    );
  };

  const handlePublishPost = async () => {
    let schedulePosts = _.get(props, 'schedulePosts', []).find(it => it._id === _id);
    const params = {
      status: 'PUBLISHED',
      postId: schedulePosts._id,
      preview: schedulePosts.preview,
    };

    if (!isEmpty(schedulePosts.poll)) {
      params.post_type = 3;
      params.poll = schedulePosts.poll;
    }
    await props.editPost(params);
    group._id && push(`/home/forums/${group._id}/post-detail/${schedulePosts._id}`);
  };

  const handleReschedulePost = () => {
    let schedulePosts = _.get(props, 'schedulePosts', []).find(it => it._id === _id);
    let user = props.user;
    props.toggleSecondModal(
      true,
      <SchedulePicker {...props} formDataSchedule={schedulePosts} user={user} isEditSchedule={true} rescheduleTime />,
    );
  };

  const handlePinPost = () => {
    const { pinPost, unPinPost } = props;
    return isPin ? unPinPost(_id) : pinPost(_id);
  };

  const currentPostAuthor = useMemo(() => {
    return _.isObject(data.author) ? data.author : user;
  }, [data.author]);

  const handleRenderNotificationList = id => {
    const posts = _.get(props, 'posts', []);
    const readPosts = _.filter(posts, item => item.isUnread);
    let isUnread = false;

    _.forEach(readPosts, item => {
      if (item._id === id) isUnread = item.isUnread;
    });

    return isUnread;
  };

  const handleClickComment = _id => {
    onCommentClick();
    setShouldOpenComment(false);
    props.checkPostsIndicator({ postId: _id });
  };

  const handleViewMoreReply = params => {
    getListReply(params, _id);
  };

  const renderScheduledTime = () => {
    let selectedTime = _.get(data, 'schedule_time', null);
    const userTimezone = _.get(data, 'author.timezone');

    if (selectedTime) {
      return (selectedTime = moment(selectedTime).tz(userTimezone).format('MMM DD, YYYY - hh:mm A'));
    }
  };

  const renderTooltipScheduledTime = () => {
    const selectedTime = _.get(data, 'schedule_time', null);
    if (selectedTime) {
      return moment(selectedTime).local().format('MMM DD, YYYY - hh:mm A ([GMT]Z)');
    }
    return '';
  };

  const checkReschedulePermission = () => {
    switch (true) {
      case isScheduleAutoflow && isAuthor:
        return false;
      case isScheduleAutoflow && !isAuthor:
        return false;
      case !isScheduleAutoflow && !isAuthor:
        return false;
      default:
        return handleReschedulePost();
    }
  };

  const renderTimestamp = () => {
    if (published_at) {
      return moment(published_at).fromNow();
    } else if (createdAt) {
      return moment(createdAt).fromNow();
    } else return '';
  };

  const renderTimestampTooltip = () => {
    if (published_at) {
      return moment(published_at).format('dddd, MMMM DD, YYYY [at] h:mm A');
    } else if (createdAt) {
      return moment(createdAt).format('dddd, MMMM DD, YYYY [at] h:mm A');
    } else return '';
  };

  const handleErrorResponse = (err, isReply, callbackError) => {
    const { toggleConfirmModal } = props;
    const { message = '', additional = {} } = err;
    const { topic = '' } = additional;
    if (topic === TYPE_ERROR.COMMENT || topic === TYPE_ERROR.FORUM_POST) {
      toggleConfirmModal(
        true,
        <ShowError
          noBorder
          title={`${!isReply ? 'Can not comment' : 'Can not reply'}`}
          content={message}
          onConfirm={() => {
            if (topic === TYPE_ERROR.COMMENT) {
              handleConfirmWithComment();
              callbackError && callbackError();
            }
            if (topic === TYPE_ERROR.FORUM_POST) {
              handleConfirmWithForum();
            }
          }}
          confirmButtonTitle="Okay"
          headerIcon={WarningIcon}
          hideCancelButton
        />,
      );
    }
  };

  const handleConfirmWithComment = () => {
    const { hideError, getListComments } = props;
    getListComments(_id);
    hideError(false);
  };

  const handleConfirmWithForum = () => {
    const { hideError, resetLoadPostsQuery, getListPost } = props;
    resetLoadPostsQuery();
    hideError(false);
    getListPost();
  };

  return (
    <Container className="post" id={_id} data-post-number={[_id].length}>
      <Header>
        <div className="avatar-container">
          <Avatar
            className="client-detail-avatar"
            size="40"
            name={getUserShortName(currentPostAuthor)}
            src={getCloudUrl(currentPostAuthor.avatar)}
            color={currentPostAuthor.color}
          />
        </div>

        <AuthorNameContainer isScheduledPost={isScheduledPost}>
          {currentPostAuthor && (
            <span className="author-name">
              {currentPostAuthor.first_name} {currentPostAuthor.last_name}
            </span>
          )}
          {isUnscheduledPost ? (
            <UnscheduledBox
              unpermitted={isUnpermitted}
              noHover={!enabledAddons.automation}
              onClick={checkReschedulePermission}
            >
              <UnscheduledTime
                data-tip
                data-for={`post-${_id}`}
                className="focus"
                unpermitted={isUnpermitted}
                noHover={!enabledAddons.automation}
              >
                Unscheduled
              </UnscheduledTime>
            </UnscheduledBox>
          ) : isScheduledPost ? (
            <ScheduledBox unpermitted={isUnpermitted} onClick={checkReschedulePermission}>
              <ScheduledTime data-tip data-for={`post-${_id}`} className="focus" unpermitted={isUnpermitted}>
                {renderScheduledTime()}
              </ScheduledTime>
            </ScheduledBox>
          ) : (
            <span className="created-at">
              {isClient ? `Client` : `Trainer`} &bull;{' '}
              <span data-tip data-for={`post-${_id}`} className="focus">
                {renderTimestamp()}
              </span>
            </span>
          )}
          {isScheduledPost ? (
            !isUnscheduledPost &&
            (permission.autoflow || enabledAddons.automation) && (
              <ReactTooltip className="" id={`post-${_id}`} effect="solid">
                <div>
                  <b>
                    <center>Scheduled based on Timezone:</center>
                  </b>
                  <span>{renderTooltipScheduledTime()}</span>
                </div>
              </ReactTooltip>
            )
          ) : (
            <ReactTooltip className="" id={`post-${_id}`} effect="solid">
              <span>{renderTimestampTooltip()}</span>
            </ReactTooltip>
          )}
        </AuthorNameContainer>

        {isTeammate && (
          <>
            <div
              data-tip
              data-for={`post-options-tooltip-${_id}`}
              className="forum-post-options-wrapper"
              onClick={() => onToggleDropdown(_id)}
            >
              <SharedTooltip id={`post-options-tooltip-${_id}`} />
            </div>
            <Dropdown
              defaultCursor={
                (!permission.autoflow && !enabledAddons.automation && isScheduledPost) ||
                (!showRemovePost && isScheduledPost)
              }
              data-btn-actions="posts-action"
              icon="ellipsis horizontal"
              open={_.isEqual(_id, openingDropdown)}
              onOpen={() => onToggleDropdown(_id)}
              onClose={() => onToggleDropdown(null)}
            >
              <Dropdown.Menu className="dropdown-menu more-actions">
                {/* <Dropdown.Item>
              <DropItem>
                <AlarmDisableIcon />
                <span>Turn off Notifications</span>
              </DropItem>
            </Dropdown.Item>
            <Dropdown.Item>
              <DropItem>
                <DownloadMediaIcon />
                <span>Download Media</span>
              </DropItem>
            </Dropdown.Item>
            <Dropdown.Item>
              <DropItem leaveGroup>
                <TurnOffCommentsIcon />
                <span>Turn off Comments</span>
              </DropItem>
            </Dropdown.Item> */}
                {isScheduledPost ? (
                  (permission.autoflow || enabledAddons.automation) && (
                    <>
                      {isAuthor && (
                        <>
                          <Dropdown.Item data-btn-actions="reschedule-post" onClick={handleReschedulePost}>
                            <DropItem leaveGroup>
                              <RescheduleIcon className="item-without-stroke" />
                              <span>Reschedule Post</span>
                            </DropItem>
                          </Dropdown.Item>
                          <Dropdown.Item data-btn-actions="publish-post" onClick={handlePublishPost}>
                            <DropItem leaveGroup>
                              <PostNowIcon className="item-without-stroke" />
                              <span>Publish Now</span>
                            </DropItem>
                          </Dropdown.Item>
                          <Dropdown.Item data-btn-actions="edit-post" onClick={handleEditScheduledPost}>
                            <DropItem leaveGroup>
                              <EditIcon />
                              <span>Edit Post</span>
                            </DropItem>
                          </Dropdown.Item>
                        </>
                      )}
                      {showRemovePost && (
                        <>
                          <Dropdown.Item data-btn-actions="remove-post" onClick={handleDeleteSchedulePopup}>
                            <DropItem leaveGroup>
                              <RemovePostIcon />
                              <span>Remove Post</span>
                            </DropItem>
                          </Dropdown.Item>
                        </>
                      )}
                    </>
                  )
                ) : (
                  <>
                    {isAuthor && (
                      <Dropdown.Item onClick={handleEditPost}>
                        <DropItem leaveGroup>
                          <EditIcon />
                          <span>Edit Post</span>
                        </DropItem>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={handlePinPost}>
                      <DropItem leaveGroup>
                        <PinnedPostIcon />
                        <span>{isPin ? 'Unpin' : 'Pin'} Post</span>
                      </DropItem>
                    </Dropdown.Item>
                    {showRemovePost && (
                      <Dropdown.Item onClick={handleOpenDeletePopup}>
                        <DropItem leaveGroup>
                          <RemovePostIcon />
                          <span>Remove Post</span>
                        </DropItem>
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </Header>
      <Body isScheduledPost={isScheduledPost} hasPreview={hasPreviewStyle}>
        <>
          <Caption showMore={isShowMore} ref={captionRef} hasPreview={hasPreviewData}>
            <MentionEditor members={members} initEditor={data} readOnly={true} />
          </Caption>
          {isShowMore && (
            <ShowMoreText
              disabled={isScheduledPost && !permission.autoflow}
              onClick={isScheduledPost && !permission.autoflow ? null : toggleReadMore}
              hasPreview={hasPreviewData}
            >
              {'... See more'}
            </ShowMoreText>
          )}
        </>
        {shareLeaderboardPostData && <ShareLeaderboardPost leaderboard={shareLeaderboardPostData} />}
        {!!renderedPictures.length ? (
          <Gallery num={Math.min(5, renderedPictures.length)}>
            {renderedPictures.map((media, index) => {
              if (media.type === 'video') {
                let fileType = _.get(media, 'name', '').split('.') || [];

                fileType = _.toUpper(fileType[fileType.length - 1]);

                return (
                  <div className="gallery-item-wrapper">
                    <GalleryItem
                      key={index}
                      more={index === 4 ? more : 0}
                      id={`gallery__item_${galleryEle}_${index}`}
                      className="gallery__item"
                      href={!isScheduledPost && `${root}?startAt=${index}`}
                      unpermitted={isScheduledPost}
                      onClick={
                        !isScheduledPost &&
                        (event => {
                          event.preventDefault();

                          if (
                            typeof onMediaClick === 'function' &&
                            !event.shiftKey &&
                            !event.ctrlKey &&
                            !event.metaKey
                          ) {
                            event.preventDefault();
                            onMediaClick({ mediaIndex: index, data: media }, 'video', renderedPictures.length);
                          }
                        })
                      }
                    >
                      {media.url && (
                        <div
                          className="video-container"
                          data-tip={`${fileType} files are not supported by your browser, but will be converted to MP4 and playable after you save.`}
                          data-for={`video-tooltip-${media._id}`}
                        >
                          <div className="overlay"></div>

                          <S3ImageWithFallback src={[media.thumbnail_url]} />
                          {!media.validVideo && !isScheduledPost && <ReactTooltip id={`video-tooltip-${media._id}`} />}
                        </div>
                      )}
                    </GalleryItem>
                  </div>
                );
              } else if (
                _.isEqual(_.get(media, 'type'), 'image/gif') &&
                _.isEqual(_.get(media, 'attachment_source'), 'giphy')
              ) {
                return (
                  <div className="gallery-item-wrapper">
                    <GalleryItem
                      key={index}
                      more={index === 4 ? more : 0}
                      id={`gallery__item_${galleryEle}_${index}`}
                      className="gallery__item"
                      href={!isScheduledPost && `${root}?startAt=${index}`}
                      unpermitted={isScheduledPost}
                      onClick={
                        !isScheduledPost &&
                        (event => {
                          if (
                            typeof onMediaClick === 'function' &&
                            !event.shiftKey &&
                            !event.ctrlKey &&
                            !event.metaKey
                          ) {
                            event.preventDefault();
                            onMediaClick({ mediaIndex: index, data: media }, 'picture');
                          }
                        })
                      }
                    >
                      <GiphyWatermark showLoader={false}>
                        <S3ImageWithFallback src={[media.thumbnail_url]} />
                      </GiphyWatermark>
                    </GalleryItem>
                  </div>
                );
              } else {
                const urlImage = getFormatImageUrlWithSML(media.thumbnail_url);
                return (
                  <div className="gallery-item-wrapper">
                    <GalleryItem
                      key={index}
                      more={index === 4 ? more : 0}
                      id={`gallery__item_${galleryEle}_${index}`}
                      className="gallery__item"
                      href={!isScheduledPost && `${root}?startAt=${index}`}
                      unpermitted={isScheduledPost}
                      onClick={
                        !isScheduledPost &&
                        (event => {
                          if (
                            typeof onMediaClick === 'function' &&
                            !event.shiftKey &&
                            !event.ctrlKey &&
                            !event.metaKey
                          ) {
                            event.preventDefault();
                            onMediaClick({ mediaIndex: index, data: media }, 'picture');
                          }
                        })
                      }
                    >
                      <S3ImageWithFallback src={[urlImage]} />
                    </GalleryItem>
                  </div>
                );
              }
            })}
          </Gallery>
        ) : (
          !_.isEmpty(poll) && (
            <PollPost
              isAuthorPost={isAuthor}
              authorPostId={author._id}
              isScheduledPost={isScheduledPost}
              pollData={poll}
            />
          )
        )}
        {hasPreviewData && _.isEmpty(poll) && (
          <>
            {isPreviewMeta && _.get(preview, 'image', '') ? (
              <PreviewVideoLink attachmentMeta={preview} onTimeline isScheduledPost={isScheduledPost} />
            ) : (
              <PreviewArticleLink attachmentMeta={preview} onTimeline isScheduledPost={isScheduledPost} />
            )}
          </>
        )}
        {!isScheduledPost && (
          <PostStatsContainer hasPreview={hasPreviewStyle}>
            {!!likeSum.length ? (
              <Like>
                {renderAvatars(liker_info)}
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<span class="name-text">${_.upperFirst(likeSum)}</span> liked`,
                  }}
                />
              </Like>
            ) : (
              <div></div>
            )}
            <div className="stats__right-side">
              {!!poll && (
                <Button textOnly className="comment" onClick={() => handleClickComment(_id)}>
                  <span>
                    {poll.total_votes} {pluralize('vote', poll.total_votes)}
                  </span>
                  {handleRenderNotificationList(_id) && <Notification />}
                </Button>
              )}
              {!!total_comment && (
                <Button textOnly className="comment" onClick={() => handleClickComment(_id)}>
                  <span>
                    {total_comment} {pluralize('comment', total_comment)}
                  </span>
                  {handleRenderNotificationList(_id) && <Notification />}
                </Button>
              )}
            </div>
          </PostStatsContainer>
        )}
      </Body>
      {!isScheduledPost && (
        <Interactions
          like={liked}
          onCommentClick={handleOnCommentClick}
          comments={total_comment}
          postId={_id}
          groupId={props.group._id}
          userId={props.userId}
        />
      )}
      {!isScheduledPost
        ? !total_comment && !shouldOpenComment && <EmptyComments>Be the first to comment on this</EmptyComments>
        : null}
      {!isScheduledPost
        ? shouldOpenComment && (
            <Comments
              {...props}
              post={data}
              onDelete={handleDeleteComment}
              onAdd={handleAddComment}
              list={listCommentRender}
              onCancelUpload={cancelUpload}
              onUploadSuccess={uploadSuccess}
              comments={listComment}
              onUpdateConfigsUpload={setUploadConfigs}
              callBackFunction={handleCallBackFunction}
              handleViewMoreReply={handleViewMoreReply}
            />
          )
        : null}
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { rootReducer, user } = state;
  const members = rootReducer.forum.members;
  const enabledAddons = rootReducer.pricing.getIn(['teamData']).toJS();

  return {
    userId: user._id,
    user,
    members,
    listComment: _.get(rootReducer, 'forum.listCommentOfPost', []),
    enabledAddons,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const forumId = _.get(ownProps, 'match.params.forumId') || _.get(ownProps, 'match.params.id');
  return {
    deletePost: postId => dispatch(deletePost(postId)),
    pinPost: postId => dispatch(pinPost(postId)),
    unPinPost: postId => dispatch(unPinPost(postId)),
    editPost: (formData, postId) => dispatch(editPost(formData, postId)),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    addComment: bindActionCreators(addComment, dispatch),
    addCommentWithMedia: bindActionCreators(addCommentWithMedia, dispatch),
    deleteComment: bindActionCreators(deleteComment, dispatch),
    getListReply: bindActionCreators(getListReply, dispatch),
    resetListComment: bindActionCreators(resetListComment, dispatch),
    getListComments: bindActionCreators(getListComments, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    checkPostsIndicator: bindActionCreators(checkPostsIndicator, dispatch),
    updatePostsAfterViewDetail: bindActionCreators(updatePostsAfterViewDetail, dispatch),
    saveScheduleTemp: bindActionCreators(saveScheduleTemp, dispatch),
    showError: bindActionCreators(showError, dispatch),
    hideError: bindActionCreators(hideError, dispatch),
    getListPost: () => dispatch(getListPost(forumId)),
    resetLoadPostsQuery: bindActionCreators(resetLoadPostsQuery, dispatch),
    uploadMediaBackgroundRequest: bindActionCreators(uploadMediaBackgroundRequest, dispatch),
    uploadMediaBackgroundSuccess: bindActionCreators(uploadMediaBackgroundSuccess, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
