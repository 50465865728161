import React from 'react';
import { components } from 'react-select';

const CustomMenuList = props => {
  const { selectProps, children } = props || {};
  const { onScroll, total } = selectProps || {};

  return (
    <components.MenuList {...props}>
      <div className="custom-menu-list" onScroll={onScroll}>
        {total > 0 && <div className="menu-title">Onboarding Flows ({total})</div>}
        {children}
      </div>
    </components.MenuList>
  );
};

export default CustomMenuList;
