import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import {
  getListTasks,
  arrangeTask,
  moveTask,
  resetCopyItem,
  resetIntervalTaskCalendarData,
} from 'redux/autoflowInterval/task/actions';
import { toggleModal } from 'actions/modal';
import { getDetailAutoflowIntervalHabit } from 'redux/autoflowInterval/habit/actions';
import CalendarLayout from '../Calendar';
import NavBar from './NavBar';
import RepeatTaskConfirm from 'components/TaskRepeat/ConfirmModal';
import HabitsBuilderPopup from 'components/TaskCreate/HabitsBuilderPopup';
import { CONFIRM_TYPES } from 'components/TaskRepeat/ConfirmModal/constants';
import { AUTOFLOW_TYPES } from 'constants/commonData';

function TaskCalendarLayout({
  autoflowId,
  getListTasks,
  resetCopyItem,
  resetIntervalTaskCalendarData,
  moveTask,
  arrangeTask,
  repeatTaskAutoFlowPermission,
  toggleModal,
  getDetailAutoflowIntervalHabit,
  ...props
}) {
  const [isConfirm, setConfirm] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const habitId = get(props, 'match.params.taskId');

  useEffect(() => {
    if (habitId) {
      getDetailAutoflowIntervalHabit &&
        getDetailAutoflowIntervalHabit(habitId).then(response => {
          const data = get(response, 'data.data');
          toggleModal(
            true,
            <HabitsBuilderPopup prevHabit={data} isEdit onClose={onClose} autoflowType={AUTOFLOW_TYPES.INTERVAL} />,
          );
        });
    }
  }, [habitId]);

  const onClose = () => {
    toggleModal(false);
    props.replace(`/home/autoflow/${autoflowId}/task/calendar`);
  };

  const toggleMoveRepeat = () => {
    setConfirm(!isConfirm);
  };

  const handleConfirmMove = () => {
    onDragEnd(selectedTask);
    toggleMoveRepeat();
  };

  const onMoveItem = item => {
    const { destination, source } = item;
    // Check task repeating or normal

    const isRepeating = item.draggableId.indexOf('isRepeating') >= 0 && repeatTaskAutoFlowPermission;
    if (isRepeating && destination.droppableId !== source.droppableId) {
      toggleMoveRepeat();
      setSelectedTask(item);
    } else {
      onDragEnd(item);
    }
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!autoflowId || !source || !destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const [newWeekIndex, newDayIndex] = destination.droppableId.split('_');
    const [oldWeekIndex, oldDayIndex] = source.droppableId.split('_');
    const [taskId] = result.draggableId.split(';');

    if (destination && source.droppableId !== destination.droppableId) {
      const newIndex = parseInt(destination.index);
      const params = { newIndex, autoflowId, newWeekIndex, newDayIndex, oldWeekIndex, oldDayIndex, taskId };
      moveTask(params);
    } else {
      const newIndex = parseInt(destination.index);
      const params = { newIndex, autoflowId, weekIndex: newWeekIndex, dayIndex: newDayIndex, taskId };
      arrangeTask(params);
    }
  };
  return (
    <CalendarLayout
      {...props}
      components={{ NavBar: NavBar }}
      onDragEnd={onMoveItem}
      resetCopyItem={resetCopyItem}
      onDidMount={getListTasks}
      onStartWeekChange={getListTasks}
      onViewModeChange={getListTasks}
      onUpdateWeeks={getListTasks}
      onUnmount={resetIntervalTaskCalendarData}
    >
      {isConfirm && (
        <RepeatTaskConfirm
          type={CONFIRM_TYPES.MOVE}
          title="Move a Repeating Task"
          message={`You are moving a task that is in a repeating sequence. \n\n This change will detach the task from the original sequence.`}
          onClose={toggleMoveRepeat}
          onConfirm={handleConfirmMove}
        />
      )}
    </CalendarLayout>
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
      },
      autoflowInterval: {
        task: { copying },
      },
      permission,
    },
  } = state;

  return {
    copying,
    autoflowId: get(workingAutoflow, '_id'),
    repeatTaskAutoFlowPermission: permission.repeat_task_autoflow,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getListTasks: bindActionCreators(getListTasks, dispatch),
  resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
  onUnmount: bindActionCreators(resetIntervalTaskCalendarData, dispatch),
  moveTask: bindActionCreators(moveTask, dispatch),
  arrangeTask: bindActionCreators(arrangeTask, dispatch),
  replace: bindActionCreators(replace, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getDetailAutoflowIntervalHabit: bindActionCreators(getDetailAutoflowIntervalHabit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskCalendarLayout);
