import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import GoalItem from '../GoalItem';
import AddGoalPopup from '../AddGoalPopup';

// assets
import { ReactComponent as GoalIcon } from 'assets/icons/goal.svg';
import * as S from './styles.js';

const GoalList = props => {
  const { currentGoal = '', isCreateNew, toggleConfirmModal } = props;
  const isEmptyGoal = isEmpty(currentGoal);

  const onClickAddYourGoal = () => {
    toggleConfirmModal(true, <AddGoalPopup />);
  };

  const getContent = () => {
    if (isEmptyGoal) {
      return (
        <>
          <GoalIcon />
          <p>This client has not set any goals yet.</p>
          <button onClick={onClickAddYourGoal} className="add-goal-btn">
            Add goal
          </button>
        </>
      );
    }
    return <GoalItem currentGoal={currentGoal} toggleConfirmModal={toggleConfirmModal} />;
  };

  useEffect(() => {
    if (isCreateNew) {
      setTimeout(onClickAddYourGoal, 300);
    }
  }, []);

  return (
    <S.GoalListStyled className="goal-wrapper">
      <div className="goal-heading">
        <h2>{isEmptyGoal ? 'Goal' : 'Goals'}</h2>
      </div>
      <div className={classNames('goal-items', { 'empty-list': isEmptyGoal })}>{getContent()}</div>
    </S.GoalListStyled>
  );
};

export default GoalList;
