import styled from 'styled-components';

export const Item = styled.div`
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #222222;
  padding: 6px 15px;
  cursor: pointer;

  &.selected {
    color: #99a1b1;
    font-weight: 400;
  }

  :hover {
    color: #5c54c3;
    background-color: #ececfa;
  }
`;

export const Trigger = styled.div`
  margin-top: 0;
  width: 60px;
  height: 28px;
  background: #FAFBFC;
  border: 1px solid #DFE4E5;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Menu = styled.div`
  position: fixed;
  border-radius: 4px;
  box-shadow: 0 4px 8px -4px rgba(0,0,0,.3);
  border: 1px solid #e0e0e8;
  background-color: #fff;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const  Wrapper = styled.div`
`;