import { get } from 'lodash';
import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import ProgramLevelSelect from './ProgramLevelSelect';
import PublishButton from './PublishButton';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { push } from 'connected-react-router';

import {
  updateStudioProgram,
  publishStudioProgram,
  unpublishStudioProgram,
  getStudioProgramDetail,
} from 'redux/studio-program/actions';
import { toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import { isTeamAdmin } from 'utils/commonFunction';
import { CDN_URL, TEAM_SHARE_NOOWNER, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { generateParams } from 'shared/AssetsShareSetting/constants';

class OverviewHeading extends React.PureComponent {
  constructor(props) {
    super(props);

    this.intervalId = undefined;

    this.checkOwnerInfo();
  }

  componentDidMount() {
    this.checkOwnerInfo();
  }

  componentDidUpdate(prevProps) {
    if (!get(this.props, 'workingStudio.isSyncing', false)) {
      this.handleStopCheckSyncing();
    }

    const { workingStudio: { author, share } = {} } = this.props;
    const { workingStudio: { author: prevAuthor, share: prevShare } = {} } = prevProps;

    if (author !== prevAuthor || share !== prevShare) {
      this.checkOwnerInfo();
    }
  }

  componentWillUnmount() {
    this.handleStopCheckSyncing();
  }

  checkOwnerInfo() {
    const { workingStudio = {}, user = {} } = this.props;
    this.author = typeof workingStudio.author_info === 'object' ? workingStudio.author_info : undefined;
    this.authorId = (this.author || {})._id;
    this.isCreator = user._id === this.authorId;
    this.isOwnerOrAdmin = isTeamAdmin(user);
    this.isNoOwner = workingStudio.share === TEAM_SHARE_NOOWNER;
    this.isCreatorOrOwnerOrAdmin = this.isCreator || this.isOwnerOrAdmin || this.isNoOwner;
  }

  onChangeProgramLevel = level => {
    return this.props
      .updateStudioProgram(this.props.workingStudio._id, {
        program_level: level.value,
      })
      .then(res => {
        if (res) {
          toast('Program level has been updated for all assigned clients');
        }
      });
  };

  handleRetryCheckSyncing = () => {
    const isPublic = process.env.REACT_APP_ENABLE_RETRY;

    if (isPublic) {
      this.intervalId = setInterval(() => {
        this.props.getStudioProgramDetail(get(this.props, 'workingStudio._id', ''));
      }, 10000);
    }
  };

  handleStopCheckSyncing = () => {
    clearInterval(this.intervalId);
  };

  handleChangeOwnershipStatus = ({ author, share }) => {
    const { updateStudioProgram, push, workingStudio, user } = this.props;

    const paramsShare = generateParams({
      ownership: author,
      shareStatus: share,
    });

    updateStudioProgram(workingStudio._id, {
      owner: paramsShare.owner,
      share: paramsShare.share,
    }).then(res => {
      if (res) {
        const { data: { data = {} } = {} } = res;
        if (data.author !== user._id && data.share === TEAM_SHARE_PRIVATE) {
          push('/home/studio-programs');
        }
        toast('This program has been updated successfully.');
      }
    });
  };

  togglePublish = () => {
    if (!this.isCreatorOrOwnerOrAdmin) return;
    const { workingStudio } = this.props;
    if (workingStudio.isSyncing) {
      return;
    }
    if (workingStudio.isPublished) {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          title={'Unpublish Program'}
          headerIcon={`${CDN_URL}/images/studio_program_unpublish_yellow.svg`}
          newStyle={true}
          content="This will pause the program for any clients using the program and remove the program for all clients. Any client’s progress in the programs will be deleted."
          confirmButtonTitle="Unpublish Program"
          confirmButtonClass="confirm-unpublish-button"
          onConfirm={() => {
            this.props.unpublishStudioProgram(this.props.workingStudio._id).then(() => {
              toast('The program has been unpublished and removed for all clients');
            });
          }}
        />,
      );
    } else {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          title={'Publish Program'}
          noIcon
          newStyle={true}
          content="Ready - Set - Go! The Studio program will be published live to all clients that the program has been made available for."
          confirmButtonTitle="Publish"
          confirmButtonClass="btn-purple"
          onConfirm={() => {
            this.handleRetryCheckSyncing();
            this.props.publishStudioProgram(this.props.workingStudio._id).then(() => {
              toast(
                'Program has been published. Selected clients will be able to see and start the program in minutes.',
              );
            });
          }}
        />,
      );
    }
  };

  render() {
    const { workingStudio, user } = this.props;

    return (
      <S.Wrapper>
        <div>
          <ProgramLevelSelect
            onChange={this.onChangeProgramLevel}
            value={workingStudio.program_level}
            disabled={!this.isCreatorOrOwnerOrAdmin}
          />
        </div>
        <div className="overviewHeading__right">
          {/* <div>
            {this.isCreatorOrOwnerOrAdmin && (
              <OwnershipPopupStudio
                user={user}
                owner={workingStudio.author_info}
                shareStatus={workingStudio.share}
                saveChangeOnClose={this.handleChangeOwnershipStatus}
              />
            )}
          </div> */}
          {workingStudio.isSyncing && (
            <ReactTooltip
              className="app-tooltip"
              id="tooltip--syncing"
              effect="solid"
              place="top"
              key="publish-tooltip"
            />
          )}
          <PublishButton
            isPublished={workingStudio.isPublished}
            onClick={this.togglePublish}
            isSyncing={workingStudio.isSyncing}
            data-tip="The Studio Program syncing is still in-progress. Please wait a few minutes before unpublishing the program"
            data-for="tooltip--syncing"
            disabled={!this.isCreatorOrOwnerOrAdmin}
          >
            {workingStudio.isPublished ? 'Unpublish' : 'Publish'}
          </PublishButton>
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateTopProps = state => {
  return {
    user: state.user,
    workingStudio: state.rootReducer.studioProgram.workingStudio || {},
  };
};

const mapDispatch = dispatch => ({
  updateStudioProgram: bindActionCreators(updateStudioProgram, dispatch),
  publishStudioProgram: bindActionCreators(publishStudioProgram, dispatch),
  unpublishStudioProgram: bindActionCreators(unpublishStudioProgram, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getStudioProgramDetail: bindActionCreators(getStudioProgramDetail, dispatch),
  push: bindActionCreators(push, dispatch),
});

export default connect(mapStateTopProps, mapDispatch)(OverviewHeading);
