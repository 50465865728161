import React from 'react';

import * as S from '../style';
import * as SettingStyles from './style';
import { THEMES } from '../constants';
import { LogoLoader } from '../Loader';
import TodayScreen from './TodayScreen';
import TodayScreenIcon from './TodayScreenIcon';
import CoachingScreen from './CoachingScreen';
import ExerciseScreen from './ExerciseScreen';
import WorkoutScreen from './WorkoutScreen';
import AppIconScreen from './AppIconScreen';
import { convertS3UrlToCloudFrontUrl, getImageDimensionFromUrl } from 'utils/commonFunction';
import WelcomeVideoScreen from './WelcomeVideoScreen';
import { cloudfrontList } from 'reducers/cloudfrontList';

const DEFAULT_STATE = {
  splashLogo: { name: '', src: '' },
  splashBackground: { name: '', src: '' },
  todayScreenLogo: { name: '', src: null },
  workoutBackground: {},
  theme: { ...THEMES[0] },
};

const deviceWidth = 194;
const maxWidth = (deviceWidth / 100) * 75; //145.5
const maxHeight = (deviceWidth / 100) * 40; // 77.6
const maxTotalLength = (deviceWidth / 100) * 80; // 155px

const calculateLogoWidthAndHeight = (originalWidth, originalHeight, maxWidth, maxHeight, maxTotalLength) => {
  if (originalWidth + originalHeight > maxTotalLength || originalWidth > maxWidth || originalHeight > maxHeight) {
    if (originalWidth > maxWidth || originalHeight > maxHeight || maxWidth + maxHeight > maxTotalLength) {
      const widthRatio = originalWidth / maxWidth;
      const heightRatio = originalHeight / maxHeight;
      let finalWidth;
      let finalHeight;
      if (widthRatio > heightRatio) {
        finalWidth = maxWidth;
        finalHeight = (originalHeight * finalWidth) / originalWidth;
      } else {
        finalHeight = maxHeight;
        finalWidth = (originalWidth * finalHeight) / originalHeight;
      }
      const totalLength = finalWidth + finalHeight;
      if (maxTotalLength < totalLength) {
        finalWidth = (finalWidth * maxTotalLength) / totalLength;
        finalHeight = maxTotalLength - finalWidth;
      }
      return { width: finalWidth, height: finalHeight };
    } else {
      return { width: originalWidth, height: originalHeight };
    }
  }
};

class SettingMobileAppPremium extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    this.defaultTheme = THEMES[0];
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      splashLogo,
      splashBackground,
      workoutBackground,
      theme,
      appIcon,
      appIconReview,
      appIconUrl,
      setAppIcon,
      setAppIconReview,
      setAppIconUrl,
      screenActive,
      todayScreenLogo,
    } = nextProps;
    const isDefaultTheme =
      theme.primaryColor.toLowerCase() === '#7470ef' && theme.secondaryColor.toLowerCase() === '#4fdfac';

    this.setState({
      splashLogo,
      splashBackground,
      workoutBackground,
      todayScreenLogo,
      theme: { ...theme, default: isDefaultTheme },
    });

    if (splashLogo.src && screenActive === 1) {
      try {
        const { width: originalWidth, height: originalHeight } = await getImageDimensionFromUrl(splashLogo.src);
        const { width, height } = calculateLogoWidthAndHeight(
          originalWidth,
          originalHeight,
          maxWidth,
          maxHeight,
          maxTotalLength,
        );
        this.setState({ width: width, height: height });
      } catch {}
    }

    setAppIcon(appIcon);
    setAppIconReview(appIconReview);
    setAppIconUrl(appIconUrl);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedWorkoutBackground,
      selectedSplashLogo,
      selectedSplashBackground,
      selectedAppIconKey,
      selectedAppIconReview,
      selectedAppIconUrl,
      selectedTodayScreenLogo,
      loading,
      originalTheme,
    } = this.props;
    const { workoutBackground } = this.state;

    let {
      setSplashLogo,
      setSplashBackground,
      setAppIcon,
      setAppIconReview,
      setAppIconUrl,
      setTodayScreenLogo,
    } = this.props;

    if (!loading && prevProps.loading) {
      this.setState({
        splashLogo: { ...selectedSplashLogo },
        splashBackground: { ...selectedSplashBackground },
        workoutBackground: { ...selectedWorkoutBackground },
        todayScreenLogo: { ...selectedTodayScreenLogo },
        theme: { ...originalTheme },
      });

      setSplashLogo({ ...selectedSplashLogo });
      setSplashBackground({ ...selectedSplashBackground });
      setAppIcon(selectedAppIconKey);
      setAppIconReview(selectedAppIconReview);
      setAppIconUrl(selectedAppIconUrl);
      setTodayScreenLogo(selectedTodayScreenLogo);
    } else if (
      selectedWorkoutBackground._id !== prevProps.selectedWorkoutBackground._id &&
      selectedWorkoutBackground._id !== workoutBackground._id
    ) {
      // Workout background has been deleted
      this.props.setWorkoutBackground(selectedWorkoutBackground);
      this.setState({ workoutBackground: { ...selectedWorkoutBackground } });
    }
  }

  getCloudUrl = url => {
    const { cloudfrontList } = this.props;
    return convertS3UrlToCloudFrontUrl(url, cloudfrontList) || url;
  };

  render() {
    const { splashLogo, splashBackground, workoutBackground, todayScreenLogo, theme, width, height } = this.state;

    const {
      loading,
      screenActive,
      appIcon,
      appIconReview,
      appIconUrl,
      appIconUploading,
      premiumCustomBrand,
      isSubmitLogo,
      originalTodayLogoSize,
      cloudfrontList,
      currentAppIconDefault,
      appIconKeySystemUrl,
      appIconKeySystem,
      appIconFlowType,
    } = this.props;
    const workoutBackgroundSrc = this.getCloudUrl(workoutBackground.src);

    return (
      <S.Section premiumCustomBrandPermission={premiumCustomBrand} className="isSetting" screenActive={screenActive}>
        <S.SectionContent>
          <SettingStyles.PreviewWrapper>
            <SettingStyles.PreviewContent>
              {(screenActive === 0 || screenActive === 2) && (
                <SettingStyles.PhoneContainer>
                  <TodayScreen
                    useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                    customBackground={!workoutBackground.default}
                    customTheme={!theme.default}
                    secondaryColor={theme.secondaryColor}
                    primaryColor={theme.primaryColor}
                    workoutBackground={workoutBackgroundSrc}
                    loading={loading}
                  />
                </SettingStyles.PhoneContainer>
              )}
              {screenActive === 0 && (
                <SettingStyles.PhoneContainer>
                  <CoachingScreen
                    useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                    customBackground={!workoutBackground.default}
                    customTheme={!theme.default}
                    secondaryColor={theme.secondaryColor}
                    primaryColor={theme.primaryColor}
                    workoutBackground={workoutBackgroundSrc}
                    loading={loading}
                  />
                </SettingStyles.PhoneContainer>
              )}

              {screenActive === 0 && (
                <SettingStyles.PhoneContainer>
                  <ExerciseScreen
                    useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                    customBackground={!workoutBackground.default}
                    customTheme={!theme.default}
                    secondaryColor={theme.secondaryColor}
                    primaryColor={theme.primaryColor}
                    workoutBackground={workoutBackgroundSrc}
                    loading={loading}
                  />
                </SettingStyles.PhoneContainer>
              )}

              {screenActive === 1 && (
                <SettingStyles.PhoneContainer>
                  <SettingStyles.PhoneContent
                    style={{
                      background: `url(${
                        typeof splashBackground !== 'undefined' ? this.getCloudUrl(splashBackground.src) : ''
                      })`,
                    }}
                  >
                    <SettingStyles.SplashLogoPlaceholderContainer
                      logoSize={!loading && !!splashLogo.src}
                      welcomeTextColor={this.props.welcomeTextColor}
                    >
                      {loading ? (
                        <LogoLoader width="92px" height="92px" radius="5px" />
                      ) : splashLogo.src ? (
                        <>
                          <SettingStyles.Logo src={this.getCloudUrl(splashLogo.src)} width={width} height={height} />
                          <div className="hello-text">Hello Stephanie, Welcome back</div>
                        </>
                      ) : (
                        <SettingStyles.SplashLogoPlaceholder>
                          <SettingStyles.PlaceholderText>Splash Screen Logo</SettingStyles.PlaceholderText>
                        </SettingStyles.SplashLogoPlaceholder>
                      )}
                    </SettingStyles.SplashLogoPlaceholderContainer>
                  </SettingStyles.PhoneContent>
                </SettingStyles.PhoneContainer>
              )}

              {screenActive === 2 && (
                <SettingStyles.PhoneContainer>
                  <WorkoutScreen
                    useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                    customBackground={!workoutBackground.default}
                    customTheme={!theme.default}
                    secondaryColor={theme.secondaryColor}
                    primaryColor={theme.primaryColor}
                    workoutBackground={workoutBackgroundSrc}
                    loading={loading}
                  />
                </SettingStyles.PhoneContainer>
              )}

              {/* TODO: Today Screen Icon */}
              {screenActive === 3 && (
                <SettingStyles.TodayScreenIconContainer>
                  <TodayScreenIcon
                    useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                    customBackground={!workoutBackground.default}
                    customTheme={!theme.default}
                    secondaryColor={theme.secondaryColor}
                    primaryColor={theme.primaryColor}
                    cloudfrontList={cloudfrontList}
                    workoutBackground={workoutBackgroundSrc}
                    todayScreenLogo={todayScreenLogo}
                    isSubmitLogo={isSubmitLogo}
                    originalTodayLogoSize={originalTodayLogoSize}
                  />
                </SettingStyles.TodayScreenIconContainer>
              )}

              {screenActive === 4 && (
                <>
                  <SettingStyles.IPhoneAppIconContainer>
                    <AppIconScreen
                      appIcon={appIcon}
                      appIconReview={appIconReview}
                      appIconUrl={appIconUrl}
                      appIconUploading={appIconUploading}
                      useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                      customBackground={!workoutBackground.default}
                      customTheme={!theme.default}
                      getCloudUrl={this.getCloudUrl}
                      secondaryColor={theme.secondaryColor}
                      primaryColor={theme.primaryColor}
                      workoutBackground={workoutBackgroundSrc}
                      loading={loading}
                      currentAppIconDefault={currentAppIconDefault}
                      appIconKeySystemUrl={appIconKeySystemUrl}
                      appIconKeySystem={appIconKeySystem}
                      appIconFlowType={appIconFlowType}
                    />
                  </SettingStyles.IPhoneAppIconContainer>
                  <SettingStyles.AndroidAppIconContainer>
                    <AppIconScreen
                      appIcon={appIcon}
                      appIconReview={appIconReview}
                      appIconUrl={appIconUrl}
                      appIconUploading={appIconUploading}
                      getCloudUrl={this.getCloudUrl}
                      useCustomTitle={workoutBackground._id !== '1' || !theme.default}
                      customBackground={!workoutBackground.default}
                      customTheme={!theme.default}
                      secondaryColor={theme.secondaryColor}
                      primaryColor={theme.primaryColor}
                      workoutBackground={workoutBackgroundSrc}
                      loading={loading}
                      currentAppIconDefault={currentAppIconDefault}
                      isAndroid
                      appIconKeySystemUrl={appIconKeySystemUrl}
                      appIconKeySystem={appIconKeySystem}
                      appIconFlowType={appIconFlowType}
                    />
                  </SettingStyles.AndroidAppIconContainer>
                </>
              )}

              {screenActive === 5 && <WelcomeVideoScreen {...this.props} getCloudUrl={this.getCloudUrl} />}
            </SettingStyles.PreviewContent>
          </SettingStyles.PreviewWrapper>
        </S.SectionContent>
      </S.Section>
    );
  }
}

export default SettingMobileAppPremium;
