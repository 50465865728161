import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Image } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { getPosts, unmount } from 'redux/foodJournal/actions';
import Post from './Post';

import { CDN_URL } from 'constants/commonData';

import { DateContainer, Wrapper, ScrollTop, NoData, FeatureTurnedOff } from './style';

function FoodJournal(props) {
  const { postByDate, push, page, per_page, total, match, getPosts, workingClientDetail, isGettingData } = props;
  const scrollTopRef = useRef();
  const container = useRef();
  let lastScrollTop = 0;
  const {
    params: { clientId },
  } = match;
  const today = moment();
  const getData = page => getPosts({ page, per_page, client: clientId });
  const onScroll = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    if (scrollTop > 300) {
      scrollTopRef.current.classList.add('show');
    } else {
      scrollTopRef.current.classList.remove('show');
    }

    if (page * per_page < total) {
      if (scrollTop > lastScrollTop) {
        // scroll to bottom;
        const bottom = scrollHeight - (scrollTop + clientHeight);

        if (bottom < 100) {
          console.log('scroll bottom', lastScrollTop, scrollTop, scrollHeight);
          getData(page + 1);
        }
      }
    }

    lastScrollTop = scrollTop;
  };
  const onScrollDebounce = _.debounce(onScroll, 100);

  useEffect(() => {
    const { unmount } = props;
    getData(1);
    return () => unmount();
  }, []);

  if (!workingClientDetail || isGettingData) {
    return null;
  }

  const noData = !Object.keys(postByDate).length;

  const foodJournalSetting = _.find(workingClientDetail.feature_preferences, item => item.type === 'food_journal');

  const turnOff = !foodJournalSetting || !foodJournalSetting.state;

  return (
    <Wrapper
      className="food-journal"
      onScroll={event => {
        event.persist();
        onScrollDebounce.call(null, event);
      }}
      ref={container}
      turnedOff={turnOff}
    >
      {turnOff ? (
        <FeatureTurnedOff>
          <Image src={`${CDN_URL}/images/feature_turned_off.svg`} />
          <span>Food Journal has been turned off for this client</span>
        </FeatureTurnedOff>
      ) : null}
      {noData ? (
        <NoData>
          <Image src={`${CDN_URL}/images/empty_food_journal.svg`} />
          <p>
            {workingClientDetail.first_name} {workingClientDetail.last_name} has not added
            <br />
            any food entries yet
          </p>
        </NoData>
      ) : null}
      {_.map(postByDate, (posts, date) => {
        const dateObj = moment(date, 'MM-DD-YYYY');
        const isToday = dateObj.isSame(today, 'day');

        return (
          <DateContainer key={date} className="food-journal__date-container">
            <div className="date-container">
              <div className="date">
                <div className={`dot ${isToday ? 'active' : ''}`} />
                <div>{dateObj.format('MMM D')}</div>
              </div>
              {isToday ? <div className="today">Today</div> : null}
            </div>
            {_.map(posts, item => (
              <Post
                key={item._id}
                id={item._id}
                root={`/home/client/${clientId}/food-journal/detail/${item._id}`}
                onMediaClick={({ mediaIndex }) => {
                  push(`/home/client/${clientId}/food-journal/detail/${item._id}?startAt=${mediaIndex}`);
                }}
                onCommentClick={() => {
                  push(`/home/client/${clientId}/food-journal/detail/${item._id}`);
                }}
              />
            ))}
          </DateContainer>
        );
      })}
      {props.children}
      <ScrollTop
        ref={scrollTopRef}
        onClick={() => {
          container.current.scrollTop = 0;
        }}
      />
    </Wrapper>
  );
}

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { foodJournal, client } = rootReducer;
  const { workingClientDetail } = client;
  const { total, page, per_page, postByDate, isGettingData } = foodJournal;

  return {
    total,
    page,
    per_page,
    postByDate,
    workingClientDetail,
    isGettingData,
  };
};

export default connect(mapStateToProps, { getPosts, unmount, push })(FoodJournal);
