import React, { useImperativeHandle, useRef, forwardRef } from 'react';
import * as S from './style';
import TextareaAutosize from 'react-autosize-textarea';

const TextareaEmailContent = forwardRef(({ errorMessage, warningLength, value = '', ...restProps }, ref) => {
  const textareaRef = useRef(null);
  const currentLength = value ? value.length : 0;

  useImperativeHandle(
    ref,
    () => {
      return {
        onFocus: () => {
          const textarea = textareaRef.current;
          if (textarea) {
            textarea.focus();
            textarea.setSelectionRange(currentLength, currentLength, 0);
          }
        },
      };
    },
    [],
  );

  const handleClickWrapper = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.focus();
    }
  };

  return (
    <div>
      <S.Wrapper onClick={handleClickWrapper} hasError={!!errorMessage}>
        <TextareaAutosize ref={textareaRef} value={value} {...restProps} />
        <S.CharacterCounter>
          <span className={warningLength <= currentLength ? 'is-error' : ''}>{currentLength}</span>/
          {restProps.maxLength}
        </S.CharacterCounter>
      </S.Wrapper>
      {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </div>
  );
});

export default TextareaEmailContent;
