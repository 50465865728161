import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';

import { TAGS_TYPE } from 'constants/commonData';
import { Button } from 'shared/FormControl';
import { getFixedPopupStyles } from 'utils/commonFunction';
import TagsFilter from 'shared/TagsFilter';

import * as S from './styles';

export const DEFAULT_FILTERS = { body_part: [], category_type: [], from: [false, false], video_only: false, tags: [] };

const DEFAULT_OPENED_SECTION = { body_part: true, category_type: true, from: true, video_only: true, tags: true };

export default function WorkoutTags(props) {
  const { currentFilters, tags, createNewTag, allowCreateNew = false, counterHasBackground = false } = props;
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
  const triggerRef = useRef();
  const menuRef = useRef();
  const menuWidth = props.menuWidth || 400;
  const minHeight = props.menuHeight || 149;

  const [isTagsMenuOpen, setIsTagsMenuOpen] = useState(false);

  const [showingFull, setShowingFull] = useState(false); // Open nested dropdown

  useEffect(() => {
    if (!open) {
      setFilters(currentFilters || DEFAULT_FILTERS);
    }
  }, [open, currentFilters]);

  const getMenuStyles = () => {
    if (open && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const styles = getFixedPopupStyles(triggerRect, minHeight, 5);
      let left = triggerRect.x - 100;

      if (left + menuWidth > window.innerWidth) {
        left = window.innerWidth - menuWidth - 10;
      }

      return {
        styles: { ...styles, left },
        className: styles.top < triggerRect.y ? 'top' : 'bottom',
      };
    }

    return { styles: { display: 'none' } };
  };

  const onUpdate = () => {
    typeof props.onApply === 'function' && props.onApply(filters);
    setOpen(false);
  };

  const handleSelectTags = values => {
    setFilters({ ...filters, tags: values });
  };

  const handleCreateNew = (data, selectCreatedTagCallback) => {
    const callback = newTagData => {
      selectCreatedTagCallback(newTagData);
    };
    createNewTag({ name: data, type: TAGS_TYPE.WORKOUT }, callback);
  };

  const { styles, className } = getMenuStyles();
  let count = 0;

  _.forEach(filters, type => {
    count += typeof type === 'object' ? _.sumBy(type, i => !!i) : type ? 1 : 0;
  });

  // const menuPortalTarget = document.querySelector('.exercise-filter__menu');
  const menuPortalTarget = document.querySelector('.ui.page.modals.dimmer');

  const handleMenuOpen = () => {
    setIsTagsMenuOpen(true);
    setShowingFull(true); // Set open nested dropdown
  };

  const handleMenuClose = () => {
    setIsTagsMenuOpen(false);
    // Keep open parent dropdown
    setTimeout(() => {
      setShowingFull(false);
    }, 300);
  };

  const onClear = () => {
    if (!_.isEqual(filters, DEFAULT_FILTERS)) {
      setFilters({ ...DEFAULT_FILTERS });
      typeof props.onApply === 'function' && props.onApply({ ...DEFAULT_FILTERS });
    }
  };

  return (
    <RootCloseWrapper
      event="click"
      onRootClose={() => {
        if (showingFull) {
          return;
        }
        setOpen(false);
      }}
      disabled={isTagsMenuOpen}
    >
      <S.Wrapper className={classNames('exercise-filter', className, { open: open })}>
        {!count ? null : !counterHasBackground ? (
          <S.Count className="exercise-filter__count">
            <span>{count}</span>
          </S.Count>
        ) : (
          <S.CountWithBackground className="exercise-filter__count">
            <div className="background-container">
              <div className="counter">{count}</div>
            </div>
          </S.CountWithBackground>
        )}
        <S.TriggerContainer
          ref={triggerRef}
          className="exercise-filter__trigger"
          onClick={() => {
            setOpen(prev => !prev);
          }}
        >
          {props.trigger || <S.Trigger />}
        </S.TriggerContainer>
        <S.Popup
          ref={menuRef}
          style={styles}
          menuHeight={minHeight}
          menuWidth={menuWidth}
          className="exercise-filter__menu"
        >
          <S.Header>
            <div>Tags</div>
            {props.showClearAll && <S.ClearSelection onClick={onClear}>Clear All</S.ClearSelection>}
          </S.Header>
          <S.Body id="filter-popup-body">
            {/* <S.Section expand={true} className="tags" id="tags-section"> */}
            <TagsFilter
              height={36}
              menuPortalZIndex={2001}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              menuPortalTarget={menuPortalTarget}
              menuStyles={{ margin: '1px 0px' }}
              menuListStyles={{ textAlign: 'initial', padding: '11px 15px' }}
              allowCreateNew={allowCreateNew}
              onCreateNew={handleCreateNew}
              tags={tags}
              selectedTags={filters.tags}
              type={TAGS_TYPE.WORKOUT}
              sorter="most_recent"
              onChange={handleSelectTags}
            />
            {/* </S.Section> */}
          </S.Body>
          <S.Footer>
            <Button purple onClick={onUpdate}>
              Apply
            </Button>
          </S.Footer>
        </S.Popup>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}
