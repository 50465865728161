import React, { useState } from 'react';
import UpgradeAutoflowImage from 'assets/images/upgrade_autoflow.png';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as AutoflowConnected } from 'assets/icons/forum-advanced-settings-go-to-autoflow.svg';
import * as S from './style';

const UpgradePathAutoflow = ({ onGoToLearnMoreAutoflow }) => {
  return (
    <>
      <S.UpgradePath>
        <>
          <S.Top>
            <S.UpgradePathImage src={UpgradeAutoflowImage} />
          </S.Top>
          <S.BodyArea>
            <S.Title>Leaderboard</S.Title>
            <S.SubTitle>Connect an Autoflow and create a leaderboard to motivate the members</S.SubTitle>
            <S.ConnectToAutoflowBtn textOnly onClick={onGoToLearnMoreAutoflow}>
              Learn about Autoflow
            </S.ConnectToAutoflowBtn>
          </S.BodyArea>
        </>
      </S.UpgradePath>
    </>
  );
};

export default UpgradePathAutoflow;
