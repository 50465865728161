import React from 'react';
import get from 'lodash/get';

import * as S from '../style';

const SignatureResponse = ({ answer }) => {
  return (
    <>
      <S.SignatureDesc>{get(answer, 'description', '')}</S.SignatureDesc>
      <S.SignatureContent>
        <img src={get(answer, 'signature_thumbnail_url') || get(answer, 'signature_url')} alt="" />
        {get(answer, 'full_name') ? <S.FullNameText>{get(answer, 'full_name')}</S.FullNameText> : null}
      </S.SignatureContent>
    </>
  );
};

export default SignatureResponse;
