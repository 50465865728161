import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';
import filter from 'lodash/filter';
import map from 'lodash/map';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import unionBy from 'lodash/unionBy';

import Header from './Header';
import Footer from './Footer';
import Columns from './columns';
import { Mixpanel } from 'utils/mixplanel';
import { Checkbox } from 'shared/FormControl';
import {
  RemoveAutoflowClientModal,
  AutoflowPausedWarningModal,
  ActivateClientsModal,
  PauseClientsModal,
  MaskAsCompleteModal,
  StartTodayClientsModal,
  ResumeOptions,
} from 'components/AutoflowConfirmModals';
import ChangeStartDateClientModal from './ChangeStartDateModal';
import { AUTOFLOW_STATUS, AUTOFLOW_TYPES } from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';

import { Wrapper, Container } from './style';

class AutoflowClientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClients: [],
    };
    this.deletingClient = false;
  }

  componentDidMount() {
    const { autoflowStatus } = this.props;

    if (autoflowStatus !== AUTOFLOW_STATUS.ACTIVATED) {
      this.props.changeAutoflowClientQueryParam({ status: undefined });
    }

    this.props.getClientList();
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  inactiveClient = client => {
    return client.is_archived || client.is_completed;
  };

  onSelectClient = (client, checked) => {
    const { selectedClients, selectClients, list, user } = this.props;
    let newList = selectedClients.slice();
    let listClients = filter(list, item => !this.inactiveClient(item));
    listClients = map(listClients, item => ({ ...item.client, status: item.status, started_on: item.started_on }));

    if (client === 'all') {
      if (checked) {
        if (!isTeamAdmin(user)) {
          listClients = filter(listClients, item => {
            const isTrainer = get(item, 'trainer._id') === user._id;
            // check client is subcoach (start)
            const subTrainers = get(item, 'sub_trainers', []);
            const isSubTrainer = findIndex(subTrainers, function (o) {
              return o.trainer._id === user._id;
            });
            // check client is subcoach (end)
            return isTrainer || isSubTrainer !== -1;
          });
        }

        newList = unionBy(newList, listClients, '_id');
      } else {
        newList = newList.filter(item => !listClients.some(lItm => lItm._id === item._id));
      }
    } else {
      if (checked) {
        newList = unionBy(newList, [client], '_id');
      } else {
        newList = newList.filter(item => {
          return item._id !== client._id;
        });
      }
    }

    selectClients(newList);
  };

  onDeleteClient = data => {
    Mixpanel.track('autoflow_interval_client_listing_screen_remove_client');
    const { client } = data;
    this.props.toggleModal(
      true,
      <RemoveAutoflowClientModal isComplete={data.is_completed} type={AUTOFLOW_TYPES.INTERVAL} clients={[client]} />,
    );
  };

  onStartTodayClient = data => {
    Mixpanel.track('autoflow_interval_client_listing_screen_start_today_client');
    const { autoflowStatus } = this.props;

    if (autoflowStatus !== AUTOFLOW_STATUS.ACTIVATED) {
      this.props.toggleModal(
        true,
        <AutoflowPausedWarningModal description="Autoflow is not actively running yet. Please activate Autoflow first before starting the Autoflow for individual clients." />,
      );
    } else {
      this.props.toggleModal(
        true,
        <StartTodayClientsModal type={AUTOFLOW_TYPES.INTERVAL} clients={[{ ...data.client, status: data.status }]} />,
      );
    }
  };

  onResumeClient = data => {
    Mixpanel.track('autoflow_interval_client_listing_screen_resume_client');
    const { autoflowStatus } = this.props;

    if (autoflowStatus !== AUTOFLOW_STATUS.ACTIVATED) {
      this.props.toggleModal(
        true,
        <AutoflowPausedWarningModal description="Autoflow is not actively running yet. Please activate Autoflow first before starting the Autoflow for individual clients." />,
      );
    } else {
      this.props.toggleModal(true, <ResumeOptions data={data} onConfirmResume={this.onConfirmResumeClient} />);
    }
  };

  onConfirmResumeClient = (data, resumedOn) => {
    this.props.toggleModal(
      true,
      <ActivateClientsModal
        type={AUTOFLOW_TYPES.INTERVAL}
        clients={[{ ...data.client, status: data.status }]}
        resumedOn={resumedOn}
      />,
    );
  };

  onChangeStartDateClient = data => {
    Mixpanel.track('autoflow_interval_client_listing_screen_change_start_date_client');
    this.props.toggleModal(
      true,
      <ChangeStartDateClientModal type={AUTOFLOW_TYPES.INTERVAL} clients={[{ ...data.client, status: data.status }]} />,
    );
  };

  onPauseClient = data => {
    Mixpanel.track('autoflow_interval_client_listing_screen_pause_client');
    this.props.toggleModal(
      true,
      <PauseClientsModal type={AUTOFLOW_TYPES.INTERVAL} clients={[{ ...data.client, status: data.status }]} />,
    );
  };

  onMaskAsComplete = data => {
    Mixpanel.track('autoflow_interval_client_listing_screen_mark_as_complete_client');
    this.props.toggleModal(
      true,
      <MaskAsCompleteModal type={AUTOFLOW_TYPES.INTERVAL} clients={[{ ...data.client, status: data.status }]} />,
    );
  };

  renderColumnHeader = column => {
    const { sort, sorter, changeAutoflowClientQueryParam, getClientList } = this.props;
    let sortIcon = null;

    if (column.sortKey) {
      let sortClass = sorter === column.sortKey ? 'chevron active ' : 'chevron ';
      sortClass += sorter === column.sortKey && sort === -1 ? 'up' : 'down';
      sortIcon = <Icon className={sortClass} />;
    }

    return (
      <div className={`table__header__content${column.hideLabel ? ' hidden' : ''}`}>
        <div
          className="header"
          onClick={() => {
            if (column.sortKey) {
              changeAutoflowClientQueryParam({
                sorter: column.sortKey,
                sort: column.sortKey === sorter ? sort * -1 : 1,
              });
              getClientList();
            }
          }}
        >
          {!!column.icon && <Image src={column.icon} className="column-icon" />}
          <span>{column.label}</span>
          {sortIcon}
        </div>
      </div>
    );
  };

  renderList = () => {
    const { list, firstLoad, user, sorter, sort, selectedClients, workingAutoflow } = this.props;
    let isChecked = false;
    let listToSelect = filter(list, item => !this.inactiveClient(item));

    if (listToSelect.length) {
      if (isTeamAdmin(user)) {
        isChecked = listToSelect.every(v => selectedClients.some(item => item._id === v.client._id));
      } else {
        const ownedClients = listToSelect.filter(item => {
          const isTrainer = get(item.client, 'trainer._id') === user._id;
          // check client is subcoach (start)
          const subTrainers = get(item.client, 'sub_trainers', []);
          const isSubTrainer = findIndex(subTrainers, function (o) {
            return o.trainer._id === user._id;
          });
          // check client is subcoach (end)
          return isTrainer || isSubTrainer !== -1;
        });
        if (ownedClients.length) {
          isChecked = ownedClients.every(v => selectedClients.some(item => item._id === v.client._id));
        }
      }
    }

    let sortClass = sorter === 'name' ? 'chevron active ' : 'chevron ';
    sortClass += sorter === 'name' && sort === -1 ? 'up' : 'down';

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th key="name" className="name">
                <div className="table__header__content">
                  <div className="select-all-container" style={{ marginBottom: 7 }}>
                    <Checkbox
                      checked={!!isChecked}
                      className="select-checkbox"
                      onChange={event => this.onSelectClient('all', event.target.checked)}
                    />
                  </div>
                  <div
                    className="header"
                    onClick={() => {
                      this.props.changeAutoflowClientQueryParam({
                        sorter: 'name',
                        sort: sorter === 'name' ? sort * -1 : 1,
                      });
                      this.props.getClientList();
                    }}
                  >
                    <span>Name</span>
                    <Icon className={sortClass} />
                  </div>
                </div>
              </th>
              {map(Columns.slice(1), column => (
                <th key={column.key} className={column.key} style={column.headerStyles ? column.headerStyles : null}>
                  {column.customHeader ? column.customHeader.call(this) : this.renderColumnHeader(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {map(list, item => {
              const trainer = get(item.client, 'trainer', {});
              // check client is subcoach (start)
              const subTrainers = get(item.client, 'sub_trainers', []);
              const isSubTrainer = findIndex(subTrainers, function (o) {
                return o.trainer._id === user._id;
              });
              // check client is subcoach (end)
              const canEdit = isTeamAdmin(user) || user._id === trainer._id || isSubTrainer !== -1;
              const inactive = this.inactiveClient(item);

              return (
                <tr
                  key={item._id}
                  className={classNames({
                    'disable-select': !canEdit || inactive,
                    inactive: inactive,
                  })}
                  data-tip
                  data-for={`archived-client-${get(item, 'client._id')}`}
                >
                  {map(Columns, column => (
                    <td key={column.key} className={column.key} style={column.bodyStyles ? column.bodyStyles : null}>
                      {column.mutate
                        ? column.mutate.call(this, { ...item, canEdit, weeks: workingAutoflow.interval_weeks.length })
                        : get(item, column.fieldData, '--')}
                      {column.key === 'name' && item.is_archived ? (
                        <ReactTooltip
                          className="app-tooltip autoflow-interval-tooltip"
                          id={`archived-client-${get(item, 'client._id')}`}
                          place="top"
                        >
                          <span>Archived Client</span>
                        </ReactTooltip>
                      ) : null}
                    </td>
                  ))}
                </tr>
              );
            })}
            {!list.length && !firstLoad ? (
              <tr className="no-data">
                <td colSpan={Columns.length}>No clients.</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { selectedClients, workingAutoflow } = this.props;

    return (
      <Wrapper className={`autoflow-client-list${selectedClients.length ? ' selecting' : ''}`}>
        <Helmet>
          <title>{workingAutoflow.name} - Clients - Everfit</title>
        </Helmet>
        <Header />
        <Container>{this.renderList()}</Container>
        <Footer />
      </Wrapper>
    );
  }
}

AutoflowClientList.displayName = 'AutoflowClientList';

export default AutoflowClientList;
