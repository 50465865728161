import React, { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';

import * as S from './style';
import { STUDIO_PROGRAM_LEVEL, TEAM_SHARE_NOOWNER, TEAM_SHARE_SHARED } from 'constants/commonData';
import { convertS3UrlToCloudFrontUrl, isTeamAdmin, pluralize } from 'utils/commonFunction';
import DropdownOptions from './DropdownOptions';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { ShareOwnerAvatar } from 'shared/AssetsShareSetting';

function StudioProgramCompactView(props) {
  const { studioProgram = {}, getS3presignedURL, user, cloudfrontList } = props;
  const [thumbnail, setThumbnail] = useState('');
  const author = typeof studioProgram.author_info === 'object' ? studioProgram.author_info : undefined;
  const authorId = (author || {})._id;
  const isCreator = user._id === authorId;
  const isOwnerOrAdmin = isTeamAdmin(user);
  const isNoOwner = studioProgram.share === TEAM_SHARE_NOOWNER;
  const isCreatorOrOwnerOrAdmin = isCreator || isOwnerOrAdmin || isNoOwner;
  const isShare = studioProgram.share === TEAM_SHARE_SHARED;

  useEffect(() => {
    getPresignedURLs(studioProgram.cover_image);
  }, []);

  const getPresignedURLs = useCallback(async url => {
    try {
      if (url) {
        let newUrl = convertS3UrlToCloudFrontUrl(url, cloudfrontList);
        if (!newUrl) {
          const response = await getS3presignedURL([url]);
          newUrl = response[0];
        }
        setThumbnail(newUrl);
      }
    } catch {}
  }, []);

  const goToDetail = () => props.push(`/home/studio-programs/${studioProgram._id}`);

  return (
    <S.Wrapper className="studio-program-compact-view__wrapper" onClick={goToDetail}>
      <S.CoverPhotoContainer coverImage={thumbnail}>
        <S.CompactDataContainer>
          <S.ProgramStatus published={studioProgram.isPublished}>
            {studioProgram.isPublished ? 'Published' : 'Draft'}
          </S.ProgramStatus>
          {studioProgram.program_level && (
            <S.CompactInformationItem>
              {get(STUDIO_PROGRAM_LEVEL, `[${studioProgram.program_level}].label`)}
            </S.CompactInformationItem>
          )}
          <S.CompactInformationItem>
            {pluralize('Week', studioProgram.studio_program_weeks.length, true)}
          </S.CompactInformationItem>
        </S.CompactDataContainer>
      </S.CoverPhotoContainer>
      <S.StudioProgramBaseInformation>
        <div className="studio-program__name">{studioProgram.program_name}</div>
        <div className="studio-program__description">{studioProgram.description}</div>
        <div className={classNames('studio-program__available-clients', { 'share-owner-avatar': true })}>
          <div className="studio-program__available-clients-content">
            Available for <b>{pluralize('client', studioProgram.number_of_clients, true)}</b>
          </div>
          {author && !isCreator && isShare && (
            <ShareOwnerAvatar
              user={author}
              tooltipId={`avatar-tooltip-${get(studioProgram, '_id')}`}
              cloudfrontList={cloudfrontList}
            />
          )}
        </div>
      </S.StudioProgramBaseInformation>
      <DropdownOptions
        className="studio-program__dropdown"
        studioProgram={studioProgram}
        isCreatorOrOwnerOrAdmin={isCreatorOrOwnerOrAdmin}
      />
    </S.Wrapper>
  );
}

const mapState = state => ({
  user: state.user,
  cloudfrontList: state.cloudfrontList,
});

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(mapState, mapDispatch)(StudioProgramCompactView);
