import React from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';

import * as S from '../style';

const MetricForm = ({ defaultMetric, title }) => {
  const lowerCaseTitle = title.toLowerCase();

  const getUnit = (array, value) => {
    let unit = {};
    forEach(array, item => {
      if (get(item, 'unique_code') === value) {
        unit = get(item, 'unit', {});
      }
    });
    return unit;
  };

  const unit = getUnit(defaultMetric, lowerCaseTitle);
  const isInOrFt = get(unit, 'unique_code') === 'in' || get(unit, 'unique_code') === 'ft';

  return (
    <S.FormType>
      <S.TypeMetric>
        {isInOrFt && (
          <>
            <S.BorderBox isInOrFt={isInOrFt} />
            <S.UnitWrapper isInOrFt={isInOrFt}>
              <S.UnitText>ft</S.UnitText>
            </S.UnitWrapper>
          </>
        )}
        <S.BorderBox isInOrFt={isInOrFt} isLeft />
        <S.UnitWrapper>
          <S.UnitText>{isInOrFt ? 'in' : get(unit, 'title', '')}</S.UnitText>
        </S.UnitWrapper>
      </S.TypeMetric>
    </S.FormType>
  );
};

export default MetricForm;
