// Libs
import React, { useState } from 'react';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

// Constants
import { NUTRITION_TAB, MACRO_NUTRIENTS } from '../constants';

// Helper
import { getNutrientValue } from '../helper';

// Components
import Ingredient from '../Ingredient';
import Instruction from '../Instruction';
import NutritionInfo from '../NutritionInfo';
import NutritionInfoDetail from '../NutritionInfoDetail';

// Style
import * as S from './style';

const tabs = [
  {
    key: 'ingredients',
    label: 'Ingredients',
  },
  {
    key: 'instructions',
    label: 'Instructions',
  },
  {
    key: 'nutrition_info',
    label: 'Nutrition Info',
  },
];

const MealDetail = props => {
  const {
    isEditMode,
    MacroNutrients,
    OtherNutrients,
    updateDataDetailRecipe,
    IntroInstruction,
    onChangeLink = () => {},
    PrepareInstructions,
    CookingInstructions,
    handleUpdateStepInstruction,
    listIngredient,
    isLoading,
    setUploadLoading,
    isSubmitted,
    isSubmittedPublish,
    unitType,
    listOtherNutrients,
    ingredientNote = '',
    ingredientText = '',
    error = {},
    updateLoadingVideoInstruction = () => {},
    loadingVideoInstruction = false,
    changeAutoCalculated = () => {},
    autoCalculated = false,
    toggleConfirmModal = () => {},
    autoCalculatedNutrition = {},
  } = props;

  const [tab, setTab] = useState(tabs[0]);

  const onTabClick = data => {
    setTab(data);
  };

  const handleChangeNutrition = (e, type, tab) => {
    const isMacro = tab.key === NUTRITION_TAB.MACRO_NUTRIENTS;
    const value = isNumber(e.floatValue) ? e.floatValue : '';
    let result = isMacro ? MacroNutrients : OtherNutrients;
    const checkExist = (isMacro ? MacroNutrients : OtherNutrients).findIndex(item => get(item, 'type', '') === type);
    if (checkExist === -1) {
      result = [
        ...result,
        {
          type,
          value,
        },
      ];
    } else {
      result = (isMacro ? MacroNutrients : OtherNutrients).map(item => {
        if (get(item, 'type', '') === type) {
          return {
            ...item,
            value,
          };
        }
        return item;
      });
    }

    if (isMacro) {
      result = calculateCalories(result);
      updateDataDetailRecipe && updateDataDetailRecipe({ macro_nutrients: result });
    } else {
      result = result.filter(item => isNumber(item.value));
      updateDataDetailRecipe && updateDataDetailRecipe({ other_nutrients: result });
    }
  };

  const calculateCalories = list => {
    const getValueOrZero = nutrientType => getNutrientValue(list, nutrientType) || 0;

    const valueProtein = getValueOrZero(MACRO_NUTRIENTS.PROTEIN);
    const valueCarbs = getValueOrZero(MACRO_NUTRIENTS.CARBS);
    const valueFat = getValueOrZero(MACRO_NUTRIENTS.FAT);

    const caloriesValue = valueProtein * 4 + valueCarbs * 4 + valueFat * 9;
    const existingCaloriesIndex = list.findIndex(item => item.type === MACRO_NUTRIENTS.CALORIES);

    if (existingCaloriesIndex !== -1) {
      list[existingCaloriesIndex].value = caloriesValue;
    }

    return list;
  };

  const renderTabContent = () => {
    const keyTab = get(tab, 'key', '');

    switch (keyTab) {
      case 'instructions':
        return (
          <Instruction
            isEditMode={isEditMode}
            IntroInstruction={IntroInstruction}
            onChangeLink={onChangeLink}
            PrepareInstructions={PrepareInstructions}
            CookingInstructions={CookingInstructions}
            handleUpdateStepInstruction={handleUpdateStepInstruction}
            setUploadLoading={setUploadLoading}
            isLoading={isLoading}
            isSubmitted={isSubmitted}
            isSubmittedPublish={isSubmittedPublish}
            updateDataDetailRecipe={updateDataDetailRecipe}
            error={error}
            updateLoadingVideoInstruction={updateLoadingVideoInstruction}
            loadingVideoInstruction={loadingVideoInstruction}
          />
        );

      case 'nutrition_info':
        if (isEditMode)
          return (
            <NutritionInfo
              handleChangeNutrition={handleChangeNutrition}
              MacroNutrients={MacroNutrients}
              OtherNutrients={OtherNutrients}
              listOtherNutrients={listOtherNutrients}
              changeAutoCalculated={changeAutoCalculated}
              autoCalculated={autoCalculated}
              toggleConfirmModal={toggleConfirmModal}
              ingredientText={ingredientText}
              autoCalculatedNutrition={autoCalculatedNutrition}
            />
          );
        return (
          <NutritionInfoDetail
            MacroNutrients={autoCalculated ? get(autoCalculatedNutrition, 'macro_nutrients', []) : MacroNutrients}
            OtherNutrients={autoCalculated ? get(autoCalculatedNutrition, 'other_nutrients', []) : OtherNutrients}
            listOtherNutrients={listOtherNutrients}
          />
        );

      default:
        return (
          <Ingredient
            isEditMode={isEditMode}
            listIngredient={listIngredient}
            isSubmitted={isSubmitted}
            isSubmittedPublish={isSubmittedPublish}
            unitType={unitType}
            ingredientText={ingredientText}
            ingredientNote={ingredientNote}
            autoCalculated={autoCalculated}
            changeAutoCalculated={changeAutoCalculated}
          />
        );
    }
  };

  return (
    <S.MealDetailWrapper>
      <S.TabHeader>
        {tabs.map(item => {
          const { key, label } = item;
          return (
            <S.TabHeaderItem onClick={() => onTabClick(item)} active={key === get(tab, 'key', '')} key={key}>
              {label}
            </S.TabHeaderItem>
          );
        })}
      </S.TabHeader>
      <S.TabContent>{renderTabContent()}</S.TabContent>
    </S.MealDetailWrapper>
  );
};

export default MealDetail;
