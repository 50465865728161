import _ from 'lodash';
import { push } from 'connected-react-router';
import { database } from 'database/firebase';
import Request, { axiosInstance } from 'configs/request';
import * as Sentry from '@sentry/browser';
import { INBOX_SYSTEM_MESSAGE_TYPES, LIMIT_MESSAGES, SECONDS_TIME_LIMIT } from 'constants/commonData';
import { mediaLog, getQueryParamsFromObject } from 'utils/commonFunction';
import { isTrainer } from 'utils/validations';
import { initPermission } from 'redux/permission/actions';

export const Types = {
  INBOX_REQUEST_GET_ALL_ROOMS: 'INBOX_REQUEST_GET_ALL_ROOMS',
  INBOX_SUCCESS_GET_ALL_ROOMS: 'INBOX_SUCCESS_GET_ALL_ROOMS',
  INBOX_ADMIN_SUCCESS_GET_ALL_ROOMS: 'INBOX_ADMIN_SUCCESS_GET_ALL_ROOMS',
  INBOX_GET_ROOMS_USER_UNDEFINED: 'INBOX_GET_ROOMS_USER_UNDEFINED',
  INBOX_SUCCESS_GET_MESSAGE_OF_ROOM: 'INBOX_SUCCESS_GET_MESSAGE_OF_ROOM',
  INBOX_SELECT_ROOM: 'INBOX_SELECT_ROOM',
  INBOX_TOTAL_UNREAD_CHANGE: 'INBOX_TOTAL_UNREAD_CHANGE',
  INBOX_SUCCESS_GET_INDIVIDUAL_ROOM_INFORMATION: 'INBOX_SUCCESS_GET_INDIVIDUAL_ROOM_INFORMATION',
  INBOX_CHANGE_PROFILE_ID: 'INBOX_CHANGE_PROFILE_ID',
  INBOX_SUCCESS_UPDATE_FAVORITE: 'INBOX_SUCCESS_UPDATE_FAVORITE',
  INBOX_NEW_CONVERSATION: 'INBOX_NEW_CONVERSATION',
  INBOX_SUCCESS_TURN_ON_FEATURE: 'INBOX_SUCCESS_TURN_ON_FEATURE',
  INBOX_SUCCESS_GET_FEATURE_STATUS: 'INBOX_SUCCESS_GET_FEATURE_STATUS',
  INBOX_FAILED_GET_FEATURE_STATUS: 'INBOX_FAILED_GET_FEATURE_STATUS',
  INBOX_SUCCESS_MARK_AS_UNREAD: 'INBOX_SUCCESS_MARK_AS_UNREAD',
  INBOX_CREATE_NEW_CONVERSTION_FROM_TRANSFER_DATA: 'INBOX_CREATE_NEW_CONVERSTION_FROM_TRANSFER_DATA',
  INBOX_NEW_MESSAGE_ADDED: 'INBOX_NEW_MESSAGE_ADDED',
  INBOX_NEW_LOCAL_MESSAGES_ADDED: 'INBOX_NEW_LOCAL_MESSAGES_ADDED',
  INBOX_REMOVE_LOCAL_MESSAGE: 'INBOX_REMOVE_LOCAL_MESSAGE',
  INBOX_ADD_ROOM_DRAFT_MESSAGE: 'INBOX_ADD_ROOM_DRAFT_MESSAGE',
  INBOX_REMOVE_ROOM_DRAFT_MESSAGE: 'INBOX_REMOVE_ROOM_DRAFT_MESSAGE',
  INBOX_FAILED_GET_ALL_ROOMS: 'INBOX_FAILED_GET_ALL_ROOMS',
  ARCHIVE_CONVERSATION_SUCCESS: 'ARCHIVE_CONVERSATION_SUCCESS',
  TOGGLE_ARCHIVED_CONVERSATION_SUCCESS: 'TOGGLE_ARCHIVED_CONVERSATION_SUCCESS',
  INBOX_SELECT_TEAMMATE_REDUX: 'INBOX_SELECT_TEAMMATE_REDUX',
  INBOX_RESET_ROOMS_REDUX: 'INBOX_RESET_ROOMS_REDUX',
  INBOX_RESET_ALL_INBOX_REDUX: 'INBOX_RESET_ALL_INBOX_REDUX',
  INBOX_GET_ARCHIVED_MESSAGES_SUCCESS: 'INBOX_GET_ARCHIVED_MESSAGES_SUCCESS',
  INBOX_NEW_ARCHIVED_MESSAGE_ADDED: 'INBOX_NEW_ARCHIVED_MESSAGE_ADDED',
  INBOX_GET_MESSAGES_REQUEST: 'INBOX_GET_MESSAGES_REQUEST',
  INBOX_CHANGE_CLIENT_REQUEST: 'INBOX_CHANGE_CLIENT_REQUEST',
  INBOX_MUTE_CONVERSATION_REQUEST: 'INBOX_MUTE_CONVERSATION_REQUEST',
  INBOX_MUTE_CONVERSATION_SUCCESS: 'INBOX_MUTE_CONVERSATION_SUCCESS',
  INBOX_UNMUTE_CONVERSATION_REQUEST: 'INBOX_UNMUTE_CONVERSATION_REQUEST',
  INBOX_UNMUTE_CONVERSATION_SUCCESS: 'INBOX_UNMUTE_CONVERSATION_SUCCESS',
  INBOX_CREATE_GROUP_REQUEST: 'INBOX_CREATE_GROUP_REQUEST',
  INBOX_CREATE_GROUP_SUCCESS: 'INBOX_CREATE_GROUP_SUCCESS',
  INBOX_ADD_GROUP_MEMBERS_REQUEST: 'INBOX_ADD_GROUP_MEMBERS_REQUEST',
  INBOX_ADD_GROUP_MEMBERS_SUCCESS: 'INBOX_ADD_GROUP_MEMBERS_SUCCESS',
  INBOX_GET_GROUP_MEMBERS_REQUEST: 'INBOX_GET_GROUP_MEMBERS_REQUEST',
  INBOX_GET_GROUP_MEMBERS_SUCCESS: 'INBOX_GET_GROUP_MEMBERS_SUCCESS',
  INBOX_REMOVE_GROUP_MEMBER_REQUEST: 'INBOX_REMOVE_GROUP_MEMBER_REQUEST',
  INBOX_REMOVE_GROUP_MEMBER_SUCCESS: 'INBOX_REMOVE_GROUP_MEMBER_SUCCESS',
  INBOX_LEAVE_GROUP_REQUEST: 'INBOX_LEAVE_GROUP_REQUEST',
  INBOX_LEAVE_GROUP_SUCCESS: 'INBOX_LEAVE_GROUP_SUCCESS',
  INBOX_UPDATE_GROUP_INFO_REQUEST: 'INBOX_UPDATE_GROUP_INFO_REQUEST',
  INBOX_UPDATE_GROUP_INFO_SUCCESS: 'INBOX_UPDATE_GROUP_INFO_SUCCESS',
  INBOX_ENABLE_CHAT_INPUT_SUCCESS: 'INBOX_ENABLE_CHAT_INPUT_SUCCESS',
  INBOX_REQUEST_GET_MESSAGE_OF_ROOM_AT_ID: 'INBOX_REQUEST_GET_MESSAGE_OF_ROOM_AT_ID',
  INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_ID: 'INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_ID',
  INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_ID: 'INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_ID',
  INBOX_REQUEST_GET_MESSAGE_OF_ROOM_AT_BOTTOM: 'INBOX_REQUEST_GET_MESSAGE_OF_ROOM_AT_BOTTOM',
  INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_BOTTOM: 'INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_BOTTOM',
  INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_BOTTOM: 'INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_BOTTOM',
  INBOX_REQUEST_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID: 'INBOX_REQUEST_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID',
  INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID: 'INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID',
  INBOX_FAILED_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID: 'INBOX_FAILED_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID',
  INBOX_REQUEST_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM: 'INBOX_REQUEST_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM',
  INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM: 'INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM',
  INBOX_FAILED_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM: 'INBOX_FAILED_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM',
  INBOX_REQUEST_GET_LATEST_MESSAGES: 'INBOX_REQUEST_GET_LATEST_MESSAGES',
  INBOX_SUCCESS_GET_LATEST_MESSAGES: 'INBOX_SUCCESS_GET_LATEST_MESSAGES',
  INBOX_UPDATE_GROUP_AVATAR_LOCAL: 'INBOX_UPDATE_GROUP_AVATAR_LOCAL',
  INBOX_SEARCH_GROUP_MEMBERS_REQUEST: 'INBOX_SEARCH_GROUP_MEMBERS_REQUEST',
  INBOX_SEARCH_GROUP_MEMBERS_SUCCESS: 'INBOX_SEARCH_GROUP_MEMBERS_SUCCESS',
  RESET_FIRST_LOAD_LIST: 'RESET_FIRST_LOAD_LIST',
  INBOX_REQUEST_GET_LATEST_MESSAGES_ARCHIVED_ROOM: 'INBOX_REQUEST_GET_LATEST_MESSAGES_ARCHIVED_ROOM',
  INBOX_SUCCESS_GET_LATEST_MESSAGES_ARCHIVED_ROOM: 'INBOX_SUCCESS_GET_LATEST_MESSAGES_ARCHIVED_ROOM',
  INBOX_SUCCESS_UPDATE_ROOMS: 'INBOX_SUCCESS_UPDATE_ROOMS',
  INBOX_REMOVE_ROOMS_SUCCESS: 'INBOX_REMOVE_ROOMS_SUCCESS',
  INBOX_SUCCESS_UPDATE_MESSAGES: 'INBOX_SUCCESS_UPDATE_MESSAGES',
  INBOX_REMOVE_MESSAGES_SUCCESS: 'INBOX_REMOVE_MESSAGES_SUCCESS',
  INBOX_SHOW_INVALID_ROOM: 'INBOX_SHOW_INVALID_ROOM',
  INBOX_SUCCESS_GET_USER_INFO: 'INBOX_SUCCESS_GET_USER_INFO',
  INBOX_REQUEST_SEARCH_ALL_ROOMS: 'INBOX_REQUEST_SEARCH_ALL_ROOMS',
  INBOX_SUCCESS_SEARCH_ALL_ROOMS: 'INBOX_SUCCESS_SEARCH_ALL_ROOMS',
  INBOX_FAILED_SEARCH_ALL_ROOMS: 'INBOX_FAILED_SEARCH_ALL_ROOMS',
  INBOX_RESET_QUERY_PARAMS: 'INBOX_RESET_QUERY_PARAMS',
  INBOX_REQUEST_LOAD_MORE_ROOMS: 'INBOX_REQUEST_LOAD_MORE_ROOMS',
  INBOX_SUCCESS_LOAD_MORE_ROOMS: 'INBOX_SUCCESS_LOAD_MORE_ROOMS',
  INBOX_FAILED_LOAD_MORE_ROOMS: 'INBOX_FAILED_LOAD_MORE_ROOMS',
  INBOX_REQUEST_SEARCH_ROOMS: 'INBOX_REQUEST_SEARCH_ROOMS',
  INBOX_SUCCESS_SEARCH_ROOMS: 'INBOX_SUCCESS_SEARCH_ROOMS',
  INBOX_FAILED_SEARCH_ROOMS: 'INBOX_FAILED_SEARCH_ROOMS',
  INBOX_RESET_SEARCH_ROOMS: 'INBOX_RESET_SEARCH_ROOMS',
  INBOX_RESET_MUTE_STATUS: 'INBOX_RESET_MUTE_STATUS',
  INBOX_START_WAITING_SYSTEM_MESSAGE: 'INBOX_START_WAITING_SYSTEM_MESSAGE',
  INBOX_STOP_WAITING_SYSTEM_MESSAGE: 'INBOX_STOP_WAITING_SYSTEM_MESSAGE',
  INBOX_ADD_SELECTED_ROOM: 'INBOX_ADD_SELECTED_ROOM',
  INBOX_UPDATE_GROUP_MEMBERS: 'INBOX_UPDATE_GROUP_MEMBERS',
};

const ROOMS_PER_PAGE_LIMIT = 20;

export const getAllRooms = (id, paramProfileId, force = false) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        inboxNew: { loadingRooms },
      },
    } = getState();
    if (loadingRooms) return;
    let forceSelectFirst = force;
    dispatch({ type: Types.INBOX_RESET_ROOMS_REDUX });
    dispatch({ type: Types.INBOX_REQUEST_GET_ALL_ROOMS });
    if (user) {
      const userId = id ? id : user._id;

      database
        .ref('user_rooms')
        .child(userId)
        .orderByChild('last_sent_message_at')
        .limitToLast(100)
        .once('value', async snapshot => {
          let allRoomsData = snapshot.val();
          if (paramProfileId) {
            const roomId = `${userId}_${paramProfileId}`;
            const reverseRoomId = `${paramProfileId}_${userId}`;
            const groupId = paramProfileId;
            const userRoomsRef = database.ref('user_rooms').child(userId).orderByKey();
            const [snapshot, reverseSnapshot, groupSnapshot] = await Promise.all([
              userRoomsRef.equalTo(roomId).once('value'),
              userRoomsRef.equalTo(reverseRoomId).once('value'),
              userRoomsRef.equalTo(groupId).once('value'),
            ]);
            let userRooms = snapshot.exists() ? snapshot.val() : reverseSnapshot.val();
            if (groupSnapshot.exists()) {
              userRooms = groupSnapshot.val();
            }
            if (!_.isEmpty(userRooms)) {
              allRoomsData = { ...allRoomsData, ...userRooms };
            } else {
              const pathname = _.get(getState(), 'router.location.pathname');
              if (pathname !== '/home/inbox/new' && pathname !== '/home/inbox') {
                forceSelectFirst = true;
              }
            }
          }
          const roomIds = _.keys(allRoomsData);
          const promises = roomIds.map(key => {
            return database.ref('rooms').child(key).once('value');
          });

          Promise.all(promises)
            .then(snapshots => {
              const reponsedData = snapshots.map(item => item.val());
              const allRooms = reponsedData.filter(item => item && !!item.id);
              const rooms = sortRoomsByLastMessage(allRooms);

              const parsedData = _.map(rooms, item => {
                const users = item.id.split('_');
                const parnertId = users[0] === userId ? users[1] : users[0];
                const myData =
                  _.get(item, 'type') !== 'group' ? item[userId] || { error: 'no data' } : { error: 'no data' };
                const parnerData =
                  _.get(item, 'type') !== 'group' ? item[parnertId] || { error: 'no data' } : { error: 'no data' };
                const userRoomValue = _.get(allRoomsData, item.id, {});
                return { ...item, parnerData, myData, ...userRoomValue };
              });

              const {
                rootReducer: {
                  inboxNew: { newConversation, profileId },
                },
                router: { location },
              } = getState();

              if (location && location.pathname && location.pathname.includes('/home/inbox')) {
                if (_.isEmpty(parsedData)) {
                  dispatch(push('/home/inbox/new'));
                } else {
                  if (newConversation && profileId) {
                    const existed = _.find(parsedData, item => item.id.includes(profileId));

                    if (existed) {
                      dispatch(push(`/home/inbox/${profileId}`));
                    }
                  } else if (!newConversation && _.isEmpty(profileId) && forceSelectFirst) {
                    let firstProfileId =
                      _.get(parsedData, '[0].id') ||
                      _.get(parsedData, '[0].room_id') ||
                      _.get(parsedData, '[0].parnerData.uid', '');
                    firstProfileId = firstProfileId.replace(userId, '').replace('_', '');
                    firstProfileId && dispatch(push(`/home/inbox/${firstProfileId}`));
                  }
                }
              }

              dispatch({ type: Types.INBOX_SUCCESS_GET_ALL_ROOMS, payload: { data: parsedData } });

              dispatch(subscribeRooms(roomIds));
              dispatch(subscribeUserRooms());
            })
            .catch(error => {
              console.error('Firebase: load rooms error', error);
              Sentry.captureException(error);
              dispatch({ type: Types.INBOX_FAILED_GET_ALL_ROOMS });
            });
        });
    } else {
      return dispatch({ type: Types.INBOX_GET_ROOMS_USER_UNDEFINED });
    }
  };
};

export const getArchivedRoomMessages = roomId => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        inboxNew: { lastItem, loadingMessages },
      },
    } = getState();

    if (loadingMessages) return;
    dispatch({ type: Types.INBOX_GET_MESSAGES_REQUEST });
    const endAtMessage = _.get(lastItem, 'timestamp', '');

    database
      .ref('messages')
      .child(roomId)
      .orderByChild('timestamp')
      .endAt(endAtMessage)
      .limitToLast(endAtMessage ? SECONDS_TIME_LIMIT : LIMIT_MESSAGES)
      .once('value', snapshot => {
        const data = snapshot.val();
        const listMessages = _.map(data, (value, key) => ({ ...value, key }));
        dispatch({
          type: Types.INBOX_GET_ARCHIVED_MESSAGES_SUCCESS,
          payload: { data: listMessages },
        });
      });
  };
};

export const getRoomMessages = roomId => {
  return (dispatch, getState) => {
    if (_.isEmpty(roomId)) return;
    const {
      rootReducer: {
        inboxNew: { lastItem, firstLoadList, loadingMessages },
      },
    } = getState();
    // if (loadingMessages) return;
    dispatch({ type: Types.INBOX_GET_MESSAGES_REQUEST });

    const endAtMessage = _.get(lastItem, 'timestamp', '');

    const now = Date.now() - 60000;

    database
      .ref('messages')
      .child(roomId)
      .orderByChild('timestamp')
      .endAt(endAtMessage)
      .limitToLast(endAtMessage ? SECONDS_TIME_LIMIT : LIMIT_MESSAGES)
      .once('value', snapshot => {
        const data = snapshot.val() || [];

        const listMessages = _.map(data, (value, key) => ({ ...value, key }));
        const user_ids = listMessages.map(item => {
          let ids = [item.sender];
          if (_.get(item, 'reply_message.sender')) {
            ids = [...ids, _.get(item, 'reply_message.sender')];
          }
          ids = [...ids, ..._.get(item, 'metadata.target_users', []).map(item => item._id)];
          return ids;
        });

        dispatch(getUserInfo(_.flatten(user_ids)));
        dispatch({
          type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM,
          payload: { data: listMessages, roomId: snapshot.key },
        });

        !lastItem && firstLoadList && dispatch(subscribeMessages(roomId, now));
      });
  };
};

export const getLatestMessages = () => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        inboxNew: { currentRoomId, bottomItem },
      },
    } = getState();
    if (!currentRoomId || !bottomItem) return;

    dispatch({ type: Types.INBOX_REQUEST_GET_LATEST_MESSAGES });

    database
      .ref('messages')
      .child(currentRoomId)
      .orderByChild('timestamp')
      .limitToLast(LIMIT_MESSAGES)
      .once('value', snapshot => {
        const data = snapshot.val();

        const listMessages = _.map(data, (value, key) => ({ ...value, key }));

        dispatch({
          type: Types.INBOX_SUCCESS_GET_LATEST_MESSAGES,
          payload: { data: listMessages, roomId: snapshot.key },
        });
      });
  };
};

export const getLatestMessagesArchivedRoom = roomId => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        inboxNew: { bottomItem },
      },
    } = getState();
    if (!roomId || !bottomItem) return;

    dispatch({ type: Types.INBOX_REQUEST_GET_LATEST_MESSAGES_ARCHIVED_ROOM });

    database
      .ref('messages')
      .child(roomId)
      .orderByChild('timestamp')
      .limitToLast(LIMIT_MESSAGES)
      .once('value', snapshot => {
        const data = snapshot.val();
        const listMessages = _.map(data, (value, key) => ({ ...value, key }));
        dispatch({
          type: Types.INBOX_SUCCESS_GET_LATEST_MESSAGES_ARCHIVED_ROOM,
          payload: { data: listMessages },
        });
      });
  };
};

export const getIndividualRoomInformation = userId => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        inboxNew: { selectedRoom },
        permission: { group_chat },
      },
    } = getState();
    if (
      group_chat
        ? selectedRoom === null || _.get(selectedRoom, 'type') === 'group'
        : _.get(selectedRoom, 'type') === 'group'
    )
      return;

    return dispatch(
      Request.get(
        { url: '/api/client-management/get-client-inbox-profile', params: { client: userId } },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;

          dispatch({
            type: Types.INBOX_SUCCESS_GET_INDIVIDUAL_ROOM_INFORMATION,
            payload: { data, userId },
          });
        },
      ),
    );
  };
};

export const getFeatureStatus = clientId => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        inboxNew: { selectedRoom },
        permission: { group_chat },
      },
    } = getState();
    if (
      group_chat
        ? selectedRoom === null || _.get(selectedRoom, 'type') === 'group'
        : _.get(selectedRoom, 'type') === 'group'
    )
      return;

    return dispatch(
      Request.get(
        { url: '/api/trainer/client_info/feature_preferences', params: { client: clientId } },
        false,
        (response, { dispatch }) => {
          const { data } = response.data;
          const object = _.find(data, item => item.type === 'inbox');
          const featureOff = !_.get(object, 'state', false);
          dispatch({ type: Types.INBOX_SUCCESS_GET_FEATURE_STATUS, payload: { data: featureOff, clientId } });
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.INBOX_FAILED_GET_FEATURE_STATUS, payload: { clientId } });
        },
      ),
    );
  };
};

export const openChat = roomId => {
  return Request.post({ url: '/api/inbox/openChat', data: { room_id: roomId } });
};

export const changeProfileId = profileId => ({
  type: Types.INBOX_CHANGE_PROFILE_ID,
  payload: { data: profileId },
});

export const selectClientFromSearchBar = clientId => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        inboxNew: { rooms },
      },
      user,
    } = getState();
    dispatch({ type: Types.INBOX_CHANGE_CLIENT_REQUEST });

    const existed = _.find(rooms, item => item.id.includes(clientId));

    if (existed) {
      dispatch(push(`/home/inbox/${clientId}`));
    } else {
      dispatch(changeProfileId(clientId));
      dispatch(getArchivedRoomMessages(`${user._id}_${clientId}`));
    }
  };
};

export const selectRoom = (roomId = '', allowDuplicate = false) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        inboxNew: { currentRoomId, currentTeam, viewTeammate, rooms, query },
      },
    } = getState();
    if (_.isEmpty(roomId) || (currentRoomId === roomId && !allowDuplicate)) return;
    if (currentRoomId && currentRoomId !== roomId) {
      database.ref('messages').child(currentRoomId).off();
    }
    // Only for admin
    const currentChatUser = viewTeammate || user._id;
    const targetChatUser = roomId.split('_').find(i => i !== currentChatUser);
    const splitted = roomId.split('_');
    const profileId = viewTeammate ? targetChatUser : _.find(splitted, id => id !== user._id) || '';
    const allRooms = query.rooms.length ? _.uniqBy([...rooms, ...query.rooms], 'id') : rooms;
    const groupRoom = _.find(allRooms, item => {
      const id = _.get(item, 'id') || _.get(item, 'room_id', '');
      return !_.isEmpty(id) && id.includes(roomId) && item.type === 'group';
    });
    const singleRoom = _.find(allRooms, item => {
      const id = _.get(item, 'id') || _.get(item, 'room_id', '');
      return !_.isEmpty(id) && id.includes(roomId);
    });
    dispatch({
      type: Types.INBOX_SELECT_ROOM,
      payload: {
        data: {
          currentRoomId: roomId,
          profileId: groupRoom ? roomId : profileId,
          selectedRoom: groupRoom || singleRoom,
        },
      },
    });
    !currentTeam && dispatch(openChat(roomId));
    groupRoom && dispatch(getGroupMembers({ roomId }));
    currentTeam ? dispatch(adminGetRoomMessages(currentTeam, roomId)) : dispatch(getRoomMessages(roomId));
  };
};

export const getTotalUnread = userId => {
  return dispatch => {
    database
      .ref('users')
      .child(userId)
      .child('unread')
      .on('value', snapshot => {
        const unread = snapshot.val();

        if (unread) {
          dispatch({ type: Types.INBOX_TOTAL_UNREAD_CHANGE, payload: { data: unread.total } });
        }
      });
  };
};

export const turnOnFeature = clientId => {
  const data = { client: clientId, type: 'inbox', state: true };
  return Request.put(
    { url: '/api/trainer/client_info/feature_preferences/update', data },
    true,
    (response, { dispatch }) => {
      dispatch({ type: Types.INBOX_SUCCESS_TURN_ON_FEATURE, payload: { data: clientId } });
    },
  );
};

export const markAsUnread = roomId => {
  return Request.post({ url: '/api/inbox/markUnread', data: { room_id: roomId } }, false, (result, { dispatch }) =>
    dispatch({ type: Types.INBOX_SUCCESS_MARK_AS_UNREAD, payload: { data: roomId } }),
  );
};

export const archiveConversation = roomId => {
  const END_POINT = 'api/inbox/archiveRoom';
  return Request.post({ url: END_POINT, data: { room_id: roomId } }, false, (result, { dispatch }) =>
    dispatch({ type: Types.TOGGLE_ARCHIVED_CONVERSATION_SUCCESS, payload: { data: true, roomId } }),
  );
};

export const toggleModal = open => {
  return dispatch => dispatch({ type: Types.TOGGLE_ARCHIVED_CONVERSATION_SUCCESS, payload: { data: open } });
};

export const createNewConversation = () => ({ type: Types.INBOX_NEW_CONVERSATION });

export const createNewConversationFromTransferData = data => ({
  type: Types.INBOX_CREATE_NEW_CONVERSTION_FROM_TRANSFER_DATA,
  payload: { data },
});

export const addNewLocalMessages = data => ({
  type: Types.INBOX_NEW_LOCAL_MESSAGES_ADDED,
  payload: { data },
});

export const removeLocalMessage = (roomId, messageKey) => ({
  type: Types.INBOX_REMOVE_LOCAL_MESSAGE,
  payload: { roomId, messageKey },
});

export const resetFirstLoadList = () => ({ type: Types.RESET_FIRST_LOAD_LIST });

export const detachCurrentUser = () => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        inboxNew: { rooms },
      },
    } = getState();
    const userId = user && user._id;

    database.ref('user_rooms').child(userId).off();

    database.ref('users').child(userId).child('unread').off();

    rooms.map(function (room) {
      const key = room.id;

      database.ref('rooms').child(key).off();

      database.ref('messages').child(key).off();
    });
  };
};

export const pingChat = roomId => {
  let data = { room_id: roomId };
  return Request.post({ url: '/api/inbox/ping', data });
};

export const addRoomDraftMessage = (roomId, message) => ({
  type: Types.INBOX_ADD_ROOM_DRAFT_MESSAGE,
  payload: { roomId, message },
});

export const removeRoomDraftMessage = roomId => ({
  type: Types.INBOX_REMOVE_ROOM_DRAFT_MESSAGE,
  payload: roomId,
});

export const resetAllInbox = () => ({
  type: Types.INBOX_RESET_ALL_INBOX_REDUX,
});

export const getAllRoomsByAdmin = (teamId, trainer) => {
  return (dispatch, getState) => {
    const currentRoomId = _.get(getState(), 'rootReducer.inboxNew.currentRoomId');
    const trainerId = _.get(trainer, '_id');
    if (!_.isEmpty(currentRoomId)) {
      const userId = _.get(getState(), 'user._id');
      database.ref('user_rooms').child(userId).off();
      database.ref('rooms').child(currentRoomId).off();
      database.ref('messages').child(currentRoomId).off();
    }
    dispatch({ type: Types.INBOX_SELECT_TEAMMATE_REDUX, payload: { teamId, trainerId } });
    if (trainerId) {
      return dispatch(
        Request.get(
          {
            url: `api/v2/inbox/rooms`,
            params: {
              trainer_id: trainerId,
              include_archived_rooms: false,
            },
          },
          false,
          (result, { dispatch }) => {
            const {
              router: { location },
            } = getState();
            const { data, total } = result.data.data;
            if (data) {
              if (location && location.pathname && location.pathname.includes('/home/inbox') && total) {
                const existed = data[0];

                if (existed) {
                  if (existed.type === 'group') {
                    dispatch(push(`/home/inbox/${existed.room_id}`));
                  } else {
                    const users = existed.room_id.split('_');
                    const partnerId = users[0] === trainerId ? users[1] : users[0];
                    dispatch(push(`/home/inbox/${partnerId}`));
                  }
                }
              }
              dispatch({
                type: Types.INBOX_ADMIN_SUCCESS_GET_ALL_ROOMS,
                payload: {
                  data: data.map(item => ({ ...item, id: item.room_id })).filter(item => item.total_members),
                  total,
                  trainer,
                },
              });
            }
          },
        ),
      );
    } else {
      return dispatch({ type: Types.INBOX_GET_ROOMS_USER_UNDEFINED });
    }
  };
};

export const searchAllRooms = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        inboxNew: { query, rooms },
      },
    } = getState();
    dispatch({
      type: Types.INBOX_REQUEST_SEARCH_ALL_ROOMS,
      payload: { newQuery: { ...query, ...params } },
    });
    return dispatch(
      Request.get(
        {
          url: `api/v2/inbox/rooms`,
          params: _.omit({ ...query, ...params, include_archived_rooms: !!params.q }, ['rooms', 'loading', 'isEnd']),
        },
        false,
        (result, { dispatch }) => {
          const { data, total } = result.data.data;
          if (data) {
            let allRooms = [...rooms, ...query.rooms, ...data];
            if (_.get(params, 'q')) {
              allRooms = _.get(params, 'page') === 1 ? data : [...query.rooms, ...data];
            }
            dispatch({
              type: Types.INBOX_SUCCESS_SEARCH_ALL_ROOMS,
              payload: {
                data: data.map(item => ({ ...item, id: item.room_id })).filter(item => item.total_members),
                newQuery: {
                  ...query,
                  ...params,
                  isEnd: allRooms.length >= total,
                },
              },
            });
          }
        },
        (error, { dispatch }) => {
          dispatch({
            type: Types.INBOX_FAILED_SEARCH_ALL_ROOMS,
          });
        },
      ),
    );
  };
};

export const resetQueryParams = () => ({
  type: Types.INBOX_RESET_QUERY_PARAMS,
});

export const adminGetRoomMessages = (teamId, roomId) => {
  return (dispatch, getState) => {
    if (_.isEmpty(teamId) || _.isEmpty(roomId)) return;
    const {
      rootReducer: {
        inboxNew: { lastItem, firstLoadList, loadingMessages },
      },
    } = getState();

    if (loadingMessages) return;
    dispatch({ type: Types.INBOX_GET_MESSAGES_REQUEST });
    const endAtMessage = _.get(lastItem, 'timestamp', '');

    const now = Date.now() - 60000;

    database
      .ref('team_messages')
      .child(teamId)
      .child(roomId)
      .orderByChild('timestamp')
      .endAt(endAtMessage)
      .limitToLast(endAtMessage ? SECONDS_TIME_LIMIT : LIMIT_MESSAGES)
      .once('value', snapshot => {
        const data = snapshot.val() || [];

        const listMessages = _.map(data, (value, key) => ({ ...value, key }));
        const user_ids = listMessages.map(item => {
          let ids = [item.sender];
          if (_.get(item, 'reply_message.sender')) {
            ids = [...ids, _.get(item, 'reply_message.sender')];
          }
          return ids;
        });

        dispatch(getUserInfo(_.flatten(user_ids)));
        dispatch({
          type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM,
          payload: { data: listMessages, roomId: snapshot.key },
        });
        !lastItem && firstLoadList && dispatch(subscribeTeamMessages({ teamId, roomId, now }));
      });
  };
};

export const sendMessage = data => {
  return dispatch => {
    let url = { url: '/api/inbox/v2/sendMessage', data };

    // Send message with room_id
    if (_.get(data, 'room_id')) {
      url = {
        url: '/api/v2/inbox/messages',
        data: _.omit({ ...data, room_id: data.room_id }, 'chat_id'),
      };
    }
    return dispatch(
      Request.post(
        url,
        false,
        (result, { dispatch }) => {},
        (error, { dispatch }) => {
          dispatch(showInvalidRoom(error));
        },
      ),
    );
  };
};

export const createNewGroup = (memberIds = [], callback) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: {
        inboxNew: { rooms },
      },
    } = getState();
    const client_ids = memberIds.filter(item => item.typeName === 'client').map(item => item._id);
    const group_ids = memberIds.filter(item => item.typeName === 'group').map(item => item._id);
    const member_ids = [user._id, ...client_ids];
    let params = { member_ids };
    if (group_ids.length) params.group_ids = group_ids;

    dispatch({ type: Types.INBOX_CREATE_GROUP_REQUEST });

    return dispatch(
      Request.post({ url: 'api/v2/inbox/rooms', data: params }, false, (result, { dispatch }) => {
        const { data } = result.data;
        if (data) {
          dispatch({ type: Types.INBOX_CREATE_GROUP_SUCCESS, payload: { data } });
          callback && callback(data.room_id);
          const users = data.room_id.split('_');
          const partnerId = users[0] === user._id ? users[1] : users[0];
          if (data.type === 'direct' && partnerId) {
            if (_.find(rooms, item => item.id === data.room_id)) {
              dispatch(push(`/home/inbox/${partnerId}`));
            } else {
              setTimeout(() => {
                dispatch(enableChatInput(false));
              }, 0);
            }
          }
        }
      }),
    );
  };
};

export const getUserInfo = (user_ids = []) => {
  return (dispatch, getState) => {
    if (!user_ids || !user_ids.length) return;
    const uniqueIds = _.uniq([...(getState(), 'rootReducer.inboxNew.usersInfo', []), ...user_ids]);
    return dispatch(
      Request.get(
        {
          url: `api/profile/v2/users`,
          params: {
            user_ids: uniqueIds,
          },
        },
        false,
        (result, { dispatch }) => {
          const { data } = result.data;
          if (data) {
            dispatch({ type: Types.INBOX_SUCCESS_GET_USER_INFO, payload: { data } });
          }
        },
      ),
    );
  };
};

export const updateGroupInfo = (roomId, data) => {
  return async dispatch => {
    if (!roomId) return;
    let params = {};
    if (data.name !== undefined) params.room_name = data.name;
    if (data.icon !== undefined) params.room_icon = data.icon;
    if (_.isEmpty(params)) return;
    dispatch({ type: Types.INBOX_UPDATE_GROUP_INFO_REQUEST });
    return dispatch(
      Request.patch(
        { url: `api/v2/inbox/rooms/${roomId}`, data: params },
        false,
        (result, { dispatch }) => {
          const { success } = result.data;

          if (success) {
            dispatch({ type: Types.INBOX_UPDATE_GROUP_INFO_SUCCESS, payload: { roomId, ...params } });
          }
        },
        (error, { dispatch }) => {
          dispatch(showInvalidRoom(error));
        },
      ),
    );
  };
};

export const updateGroupAvatarLocal = (roomId, data) => ({
  type: Types.INBOX_UPDATE_GROUP_AVATAR_LOCAL,
  payload: { roomId, data },
});

export const muteConversation = (roomId, time) => {
  return dispatch => {
    if (!roomId) return;
    let params = {};
    if (time) params = { option: time };
    return dispatch(
      Request.post(
        { url: `api/v2/inbox/rooms/${roomId}/mute`, data: params },
        false,
        (result, { dispatch }) => {
          const { data } = result.data;

          if (data) {
            dispatch({
              type: Types.INBOX_MUTE_CONVERSATION_SUCCESS,
              payload: { ...data, roomId },
            });
          }
        },
        (error, { dispatch }) => {
          dispatch(showInvalidRoom(error));
        },
      ),
    );
  };
};

export const unmuteConversation = roomId => {
  if (!roomId) return;
  return dispatch => {
    return dispatch(
      Request.post({ url: `api/v2/inbox/rooms/${roomId}/unmute` }, false, (result, { dispatch }) => {
        const { data } = result.data;

        if (data) {
          dispatch({
            type: Types.INBOX_UNMUTE_CONVERSATION_SUCCESS,
            payload: { ...data, roomId },
          });
        }
      }),
    );
  };
};

export const addGroupMembers = (roomId, memberIds = [], callback, resetStatus) => {
  return async dispatch => {
    if (!roomId || !memberIds.length) return;
    const member_ids = memberIds.filter(item => item.typeName === 'client').map(item => item._id);
    const group_ids = memberIds.filter(item => item.typeName === 'group').map(item => item._id);
    let params = {};
    if (member_ids.length) params.member_ids = member_ids;
    if (group_ids.length) params.group_ids = group_ids;

    dispatch({ type: Types.INBOX_ADD_GROUP_MEMBERS_REQUEST });

    return dispatch(
      Request.post(
        { url: `api/v2/inbox/rooms/${roomId}/members`, data: params },
        false,
        (result, { dispatch }) => {
          const { data } = result.data;
          dispatch({ type: Types.INBOX_ADD_GROUP_MEMBERS_SUCCESS, payload: { data } });
          callback && callback();
          dispatch(getGroupMembers({ roomId }, resetStatus));
        },
        (error, { dispatch }) => {
          dispatch(showInvalidRoom(error));
        },
      ),
    );
  };
};

export const removeGroupMember = (roomId, memberId) => {
  return dispatch => {
    if (!roomId || !memberId) return;
    return dispatch(
      Request.delete(
        { url: `api/v2/inbox/rooms/${roomId}/members/${memberId}` },
        false,
        (result, { dispatch }) => {
          const { data } = result.data;
          data && dispatch({ type: Types.INBOX_REMOVE_GROUP_MEMBER_SUCCESS, payload: { memberId } });
        },
        (error, { dispatch }) => {
          if (_.get(error, 'response.data.message') === 'User is not in this group') {
            dispatch({ type: Types.INBOX_REMOVE_GROUP_MEMBER_SUCCESS, payload: { memberId } });
          }
        },
      ),
    );
  };
};

export const leaveGroup = (roomId, callback) => {
  return (dispatch, getState) => {
    if (!roomId) return;
    return dispatch(
      Request.post(
        { url: `api/v2/inbox/rooms/${roomId}/leave-room` },
        false,
        (result, { dispatch }) => {
          const { success } = result.data.data;
          const {
            rootReducer: {
              inboxNew: { rooms },
            },
          } = getState();
          if (success) {
            const newRooms = rooms.filter(room => room.id !== roomId);
            dispatch({ type: Types.INBOX_LEAVE_GROUP_SUCCESS, payload: { newRooms } });
            if (newRooms.length) {
              callback &&
                callback(
                  _.get(newRooms[0], 'type') === 'group' ? newRooms[0].id : _.get(newRooms[0], 'parnerData.uid', ''),
                );
              dispatch(selectRoom(newRooms[0].id));
            }
          }
        },
        (error, { dispatch }) => {
          // dispatch(showInvalidRoom());
        },
      ),
    );
  };
};

export const getGroupMembers = ({ roomId, page }, callback) => {
  return (dispatch, getState) => {
    if (!roomId) return;
    const {
      user,
      rootReducer: {
        inboxNew: { members, viewTeammate, selectedTeammate },
      },
    } = getState();
    let params = {
      page,
      per_page: 20,
    };
    if (viewTeammate) params.trainer_id = viewTeammate;

    dispatch({ type: Types.INBOX_GET_GROUP_MEMBERS_REQUEST });

    return dispatch(
      Request.get(
        { url: `api/v2/inbox/rooms/${roomId}/members`, params },
        false,
        (result, { dispatch }) => {
          const { data, total } = result.data;
          const formattedData = data.map(item => ({ ...item.user, parent_id: item._id }));
          let newData = viewTeammate ? [selectedTeammate, ...formattedData] : [user, ...formattedData];
          if (page > 1) {
            newData = _.uniqBy([...members, ...formattedData], '_id');
          }
          dispatch({ type: Types.INBOX_GET_GROUP_MEMBERS_SUCCESS, payload: { data: newData, total } });
          callback && callback(!data.length || newData.length >= total);
        },
        (error, { dispatch }) => {
          dispatch(showInvalidRoom(error));
        },
      ),
    );
  };
};

export const searchGroupMembers = ({ roomId, page, search }, callback) => {
  return (dispatch, getState) => {
    if (!roomId) return;
    const {
      user,
      rootReducer: {
        inboxNew: { searchMembers },
      },
    } = getState();
    let params = {
      page,
      per_page: 20,
      search,
    };

    dispatch({ type: Types.INBOX_SEARCH_GROUP_MEMBERS_REQUEST });

    return dispatch(
      Request.get(
        { url: `api/v2/inbox/rooms/${roomId}/members`, params },
        false,
        (result, { dispatch }) => {
          const { data, total } = result.data;
          const formattedData = data.map(item => ({ ...item.user, parent_id: item._id }));
          const newData = page > 1 ? _.uniqBy([...searchMembers, ...formattedData], '_id') : [user, ...formattedData];
          dispatch({ type: Types.INBOX_SEARCH_GROUP_MEMBERS_SUCCESS, payload: { data: newData } });
          callback && callback(!data.length || newData.length >= total);
        },
        (error, { dispatch }) => {
          dispatch(showInvalidRoom(error));
        },
      ),
    );
  };
};

export const uploadGroupAvatar = (uploadConfig, callback) => {
  return Request.put(uploadConfig, false, res => {
    if (typeof callback === 'function') {
      callback();
    }
    mediaLog({
      status: 2,
      name: _.get(res, 'config.data.name', ''),
      description: 'Upload success file via Group Image',
    });
  });
};

export const getContactsList = (data = {}) => {
  return (dispatch, getState) => {
    const { user } = getState();
    let params = {
      search: data.search,
      page: 1,
      per_page: 2,
      exclude_ids: data.except,
      only_groups_have_clients: true,
    };
    if (isTrainer(user)) params.only_my_groups = true;
    return Promise.all([
      axiosInstance.get('/api/group/list', {
        params,
      }),
      axiosInstance.get('/api/v2/inbox/rooms/search_users', {
        params: { q: data.search, page: 1, per_page: 50 },
      }),
    ]);
  };
};

export const enableChatInput = status => {
  return dispatch => dispatch({ type: Types.INBOX_ENABLE_CHAT_INPUT_SUCCESS, payload: { status } });
};

export const checkLastTrainer = room_id =>
  room_id && Request.get({ url: `api/v2/inbox/rooms/${room_id}/check-last-trainer` }, false);

export const getMessagesAtId = (roomId, messageId) => async (dispatch, getState) => {
  const state = getState();
  const loading = _.get(state, 'rootReducer.inboxNew.loading', false);
  if (loading) return;
  dispatch({ type: Types.INBOX_REQUEST_GET_MESSAGE_OF_ROOM_AT_ID });
  const LIMIT = 11;
  const ref = database.ref('messages').child(roomId).orderByKey();
  const [messageSnapshot, topSnapshot, bottomSnapshot] = await Promise.all([
    ref.equalTo(messageId).once('value'),
    ref.endAt(messageId).limitToLast(LIMIT).once('value'),
    ref.startAt(messageId).limitToFirst(LIMIT).once('value'),
  ]);
  if (!messageSnapshot.exists()) return dispatch({ type: Types.INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_ID });
  const data = { ...topSnapshot.val(), ...bottomSnapshot.val() };
  const listMessages = _.map(data, (value, key) => ({ ...value, key }));

  dispatch({
    type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_ID,
    payload: { data: listMessages, roomId },
  });
};

export const getMessagesArchivedRoomAtId = (roomId, messageId) => async (dispatch, getState) => {
  const state = getState();
  const loading = _.get(state, 'rootReducer.inboxNew.loading', false);
  if (loading) return;
  dispatch({ type: Types.INBOX_REQUEST_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID });
  const LIMIT = 11;
  const ref = database.ref('messages').child(roomId).orderByKey();
  const [messageSnapshot, topSnapshot, bottomSnapshot] = await Promise.all([
    ref.equalTo(messageId).once('value'),
    ref.endAt(messageId).limitToLast(LIMIT).once('value'),
    ref.startAt(messageId).limitToFirst(LIMIT).once('value'),
  ]);
  if (!messageSnapshot.exists()) return dispatch({ type: Types.INBOX_FAILED_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID });
  const data = { ...topSnapshot.val(), ...bottomSnapshot.val() };
  const listMessages = _.map(data, (value, key) => ({ ...value, key }));
  dispatch({
    type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_ID,
    payload: { data: listMessages, roomId },
  });
};

export const getMessagesAdminRoomAtId = (teamId, roomId, messageId) => async (dispatch, getState) => {
  const state = getState();
  const loading = _.get(state, 'rootReducer.inboxNew.loading', false);
  if (loading) return;
  dispatch({ type: Types.INBOX_REQUEST_GET_MESSAGE_OF_ROOM_AT_ID });
  const LIMIT = 11;
  const ref = database.ref('team_messages').child(teamId).child(roomId).orderByKey();
  const [messageSnapshot, topSnapshot, bottomSnapshot] = await Promise.all([
    ref.equalTo(messageId).once('value'),
    ref.endAt(messageId).limitToLast(LIMIT).once('value'),
    ref.startAt(messageId).limitToFirst(LIMIT).once('value'),
  ]);
  if (!messageSnapshot.exists()) return dispatch({ type: Types.INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_ID });
  const data = { ...topSnapshot.val(), ...bottomSnapshot.val() };
  const listMessages = _.map(data, (value, key) => ({ ...value, key }));

  dispatch({
    type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_ID,
    payload: { data: listMessages, roomId },
  });
};

export const getMessagesBottomAtId = roomId => {
  return (dispatch, getState) => {
    const state = getState();
    const bottomItem = _.get(state, 'rootReducer.inboxNew.bottomItem', '');
    const endOfBottom = _.get(state, 'rootReducer.inboxNew.endOfBottom', '');
    if (bottomItem && !endOfBottom) {
      database
        .ref('messages')
        .child(roomId)
        .orderByKey()
        .startAt(bottomItem)
        .limitToFirst(SECONDS_TIME_LIMIT)
        .once('value', snapshot => {
          const listMessages = _.map(snapshot.val(), (value, key) => ({ ...value, key }));
          const lastLoadItem = _.last(listMessages);
          const lastKey = _.get(lastLoadItem, 'key');
          const lastTimestamp = _.get(lastLoadItem, 'timestamp');
          const timestampSelectedRoom = _.get(getState(), 'rootReducer.inboxNew.selectedRoom.last_message.timestamp');

          const isEnd = lastKey === bottomItem || lastTimestamp === timestampSelectedRoom;
          dispatch({
            type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_BOTTOM,
            payload: { data: listMessages, roomId: snapshot.key, endOfBottom: isEnd },
          });
        });
    } else {
      dispatch({
        type: Types.INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_BOTTOM,
      });
    }
  };
};

export const getMessagesAdminRoomBottomAtId = (teamId, roomId) => {
  return (dispatch, getState) => {
    const state = getState();
    const bottomItem = _.get(state, 'rootReducer.inboxNew.bottomItem', '');
    const endOfBottom = _.get(state, 'rootReducer.inboxNew.endOfBottom', '');
    if (bottomItem && !endOfBottom) {
      database
        .ref('team_messages')
        .child(teamId)
        .child(roomId)
        .orderByKey()
        .startAt(bottomItem)
        .limitToFirst(SECONDS_TIME_LIMIT)
        .once('value', snapshot => {
          const listMessages = _.map(snapshot.val(), (value, key) => ({ ...value, key }));
          const lastLoadItem = _.last(listMessages);
          const lastKey = _.get(lastLoadItem, 'key');
          const lastTimestamp = _.get(lastLoadItem, 'timestamp');
          const timestampSelectedRoom = _.get(getState(), 'rootReducer.inboxNew.selectedRoom.last_message.timestamp');

          const isEnd = lastKey === bottomItem || lastTimestamp === timestampSelectedRoom;
          dispatch({
            type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ROOM_AT_BOTTOM,
            payload: { data: listMessages, roomId: snapshot.key, endOfBottom: isEnd },
          });
        });
    } else {
      dispatch({
        type: Types.INBOX_FAILED_GET_MESSAGE_OF_ROOM_AT_BOTTOM,
      });
    }
  };
};

export const getMessagesArchivedRoomBottomAtId = roomId => {
  return (dispatch, getState) => {
    const state = getState();
    const bottomItem = _.get(state, 'rootReducer.inboxNew.bottomItem', '');
    const endOfBottom = _.get(state, 'rootReducer.inboxNew.endOfBottom', '');
    if (bottomItem && !endOfBottom) {
      database
        .ref('messages')
        .child(roomId)
        .orderByKey()
        .startAt(bottomItem)
        .limitToFirst(SECONDS_TIME_LIMIT)
        .once('value', snapshot => {
          const listMessages = _.map(snapshot.val(), (value, key) => ({ ...value, key }));
          const lastLoadItem = listMessages ? listMessages[listMessages.length - 1].key : null;

          const isEnd = lastLoadItem === bottomItem;
          dispatch({
            type: Types.INBOX_SUCCESS_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM,
            payload: { data: listMessages, roomId: snapshot.key, endOfBottom: isEnd },
          });
        });
    } else {
      dispatch({
        type: Types.INBOX_FAILED_GET_MESSAGE_OF_ARCHIVED_ROOM_AT_BOTTOM,
      });
    }
  };
};

/**
 * Sorts the rooms array by their last message timestamp in descending order.
 *
 * @param {Object} params - An object containing room data
 * @param {Array<Object>} params.rooms - An array of room objects
 * @returns {Array} - The sorted array of rooms
 */
export function sortRoomsByLastMessage(rooms) {
  return rooms.sort((room1, room2) => {
    if (!room1) return -1;
    if (!room2) return 1;
    const r1 =
      _.get(room1, 'last_sent_message_at') ||
      _.get(room1, 'last_message.timestamp', 0) ||
      _.get(room1, 'last_message.time', 0);
    const r2 =
      _.get(room2, 'last_sent_message_at') ||
      _.get(room2, 'last_message.timestamp', 0) ||
      _.get(room2, 'last_message.time', 0);
    return r2 - r1;
  });
}

/**
 * Formats a room detail object by extracting partner and user data.
 *
 * @param {Object} params - An object containing room data and user ID
 * @param {Object} params.room - The room object to format.
 * @param {string} params.userId - The id of the user whose data is to be extracted.
 * @return {Object | undefined} A new room object with partner and user data or undefined if room or userId are falsy.
 */
export function formatRoomDetail({ room, userId, userRoomValue = {} }) {
  if (!room || !userId) return;
  const partnerId = `${room.id}`.replace(userId, '').replace('_', '');
  const myData = _.get(room, 'type') !== 'group' ? room[userId] || { error: 'no data' } : { error: 'no data' };
  const partnerData = _.get(room, 'type') !== 'group' ? room[partnerId] || { error: 'no data' } : { error: 'no data' };
  return { ...room, parnerData: partnerData, myData, ...userRoomValue };
}

/**
 * Subscribes the user to their rooms and moves any new messages to the top of their inbox.
 *
 * @return {Function} A function that dispatches an action with the userRooms and rooms data.
 */
export const subscribeRooms = roomIds => (dispatch, getState) => {
  const userId = _.get(getState(), 'user._id');
  _.forEach(roomIds, id => {
    const roomRef = database.ref('rooms').child(id);
    roomRef.off();

    roomRef.on('child_changed', snapshot => {
      const allRooms = _.get(getState(), 'rootReducer.inboxNew.rooms', []);
      const currentRoom = _.find(allRooms, { id });
      if (!_.isEmpty(currentRoom)) {
        const updatedRoom = { ...currentRoom, [snapshot.key]: snapshot.val() };
        const formattedRoom = formatRoomDetail({ room: updatedRoom, userId });
        dispatch({
          type: Types.INBOX_SUCCESS_UPDATE_ROOMS,
          payload: { rooms: [formattedRoom], from: 'rooms_child_changed' },
        });
      }
    });

    roomRef.on('child_removed', snapshot => {
      const allRooms = _.get(getState(), 'rootReducer.inboxNew.rooms', []);
      const currentRoom = _.find(allRooms, { id });
      if (!_.isEmpty(currentRoom)) {
        const updatedRoom = _.omit(currentRoom, [snapshot.key]);
        const formattedRoom = formatRoomDetail({ room: updatedRoom, userId });
        dispatch({
          type: Types.INBOX_SUCCESS_UPDATE_ROOMS,
          payload: { rooms: [formattedRoom], from: 'rooms_child_removed' },
        });
      }
    });
  });
};

export const subscribeUserRooms = () => (dispatch, getState) => {
  const userId = _.get(getState(), 'user._id');
  const userRoomRef = database.ref('user_rooms').child(userId);
  userRoomRef.off();

  userRoomRef
    .orderByChild('active')
    .startAt(Date.now())
    .on('child_added', snapshot => {
      const roomId = snapshot.key;
      const userRoomValue = snapshot.val();
      const viewTeammate = _.get(getState(), 'rootReducer.inboxNew.viewTeammate');
      const rooms = _.get(getState(), 'rootReducer.inboxNew.rooms', []);
      const loadedRooms = _.get(getState(), 'rootReducer.inboxNew.loadedRooms');
      const exists = _.find(rooms, { id: roomId });
      if (!exists && _.isEmpty(viewTeammate) && loadedRooms) {
        setTimeout(() => {
          database
            .ref('rooms')
            .child(roomId)
            .once('value', snapshot => {
              const formattedRoom = formatRoomDetail({ room: snapshot.val(), userId, userRoomValue });
              dispatch({
                type: Types.INBOX_SUCCESS_UPDATE_ROOMS,
                payload: { rooms: [formattedRoom], from: 'user_rooms_child_added' },
              });
              dispatch(subscribeRooms([roomId]));
              if (
                _.get(formattedRoom, 'created_by') === userId ||
                _.get(formattedRoom, 'last_message.sender') === userId
              ) {
                handleRedirectToProfile(formattedRoom);
              }
            });
        }, 500);
      }
    });

  userRoomRef.on('child_changed', snapshot => {
    const roomId = snapshot.key;
    const userRoomValue = snapshot.val();
    const viewTeammate = _.get(getState(), 'rootReducer.inboxNew.viewTeammate');
    const rooms = _.get(getState(), 'rootReducer.inboxNew.rooms', []);
    const exists = _.find(rooms, { id: roomId });
    if (exists && _.isEmpty(viewTeammate)) {
      setTimeout(() => {
        const rooms = _.get(getState(), 'rootReducer.inboxNew.rooms', []);
        if (rooms.length === 0) return;
        database
          .ref('rooms')
          .child(roomId)
          .once('value', snapshot => {
            const formattedRoom = formatRoomDetail({ room: snapshot.val(), userId, userRoomValue });
            dispatch({
              type: Types.INBOX_SUCCESS_UPDATE_ROOMS,
              payload: { rooms: [formattedRoom], from: 'user_rooms_child_changed' },
            });
          });
      }, 500);
    }
  });

  userRoomRef.on('child_removed', snapshot => {
    const roomId = snapshot.key;
    const viewTeammate = _.get(getState(), 'rootReducer.inboxNew.viewTeammate');
    const rooms = _.get(getState(), 'rootReducer.inboxNew.rooms', []);
    const exists = _.find(rooms, { id: roomId });
    if (exists && _.isEmpty(viewTeammate)) {
      dispatch({
        type: Types.INBOX_REMOVE_ROOMS_SUCCESS,
        payload: { roomId },
      });
      database.ref('rooms').child(roomId).off();
      const selectedRoomId = _.get(getState(), 'rootReducer.inboxNew.selectedRoom.id');
      if (selectedRoomId === roomId) {
        const nextRoom = _.get(getState(), 'rootReducer.inboxNew.rooms[0]');
        if (nextRoom) {
          // dispatch(createNewConversation());
          handleRedirectToProfile(nextRoom, true);
        }
      }
    }
  });

  const handleRedirectToProfile = (room, newConversation) => {
    const roomId = _.get(room, 'id') || _.get(room, 'room_id');
    const rooms = _.get(getState(), 'rootReducer.inboxNew.rooms', []);
    const location = _.get(getState(), 'router.location');
    // const newConversation = _.get(getState(), 'rootReducer.inboxNew.newConversation');
    if (
      location &&
      location.pathname &&
      location.pathname.includes('/home/inbox') &&
      rooms.length &&
      (newConversation || _.get(getState(), 'rootReducer.inboxNew.newConversation')) &&
      roomId
    ) {
      const profileId = _.get(room, 'parnerData.uid') || roomId;
      !_.isEmpty(profileId) && dispatch(push(`/home/inbox/${profileId}`));
      !_.isEmpty(roomId) && dispatch(selectRoom(roomId));
    }
  };
};

export const subscribeMessages = (roomId, now = Date.now()) => (dispatch, getState) => {
  const messageRef = database.ref('messages').child(roomId);
  messageRef.off();
  const startSubscribe = Date.now();

  messageRef
    .orderByChild('timestamp')
    .startAt(now)
    .on('child_added', snapshot => {
      const { system_message_type, room_id, timestamp: newTimestamp } = snapshot.val();
      const currentRoomId = _.get(getState(), 'rootReducer.inboxNew.currentRoomId');
      const rooms = _.get(getState(), 'rootReducer.inboxNew.rooms');
      const sender = _.get(snapshot.val(), 'sender');
      if (currentRoomId === roomId && system_message_type === INBOX_SYSTEM_MESSAGE_TYPES.MEMBER_LEAVE_GROUP) {
        let members = _.get(getState(), 'rootReducer.inboxNew.members', []);
        let searchMembers = _.get(getState(), 'rootReducer.inboxNew.searchMembers', []);
        members = members.filter(item => item._id !== sender);
        searchMembers = searchMembers.filter(item => item._id !== sender);
        dispatch({
          type: Types.INBOX_UPDATE_GROUP_MEMBERS,
          payload: { members, searchMembers },
        });
      }
      dispatch({
        type: Types.INBOX_NEW_MESSAGE_ADDED,
        payload: { data: { ...snapshot.val(), key: snapshot.key }, roomId },
      });
      const existed = rooms.find(room => _.get(room, 'id') === room_id);
      if (existed && newTimestamp) {
        const formattedRoom = {
          ...existed,
          last_sent_message_at: _.max([_.get(existed, 'last_sent_message_at'), newTimestamp]),
        };
        dispatch({
          type: Types.INBOX_SUCCESS_UPDATE_ROOMS,
          payload: { rooms: [formattedRoom], from: 'message_child_added' },
        });
      }
      const timestamp = _.get(snapshot.val(), 'timestamp', 0);
      const userId = _.get(getState(), 'user._id');
      const viewTeammate = _.get(getState(), 'rootReducer.inboxNew.viewTeammate');
      if (currentRoomId === roomId && sender !== userId && _.isEmpty(viewTeammate) && timestamp > startSubscribe) {
        dispatch(pingChat(roomId));
      }
    });

  messageRef
    .orderByKey()
    .limitToLast(1000)
    .on('child_changed', snapshot => {
      dispatch({
        type: Types.INBOX_SUCCESS_UPDATE_MESSAGES,
        payload: { key: snapshot.key, ...snapshot.val() },
      });
    });

  messageRef
    .orderByKey()
    .limitToLast(1000)
    .on('child_removed', snapshot => {
      dispatch({
        type: Types.INBOX_REMOVE_MESSAGES_SUCCESS,
        payload: { key: snapshot.key },
      });
    });
};

export const subscribeTeamMessages = ({ teamId, roomId, now = Date.now() }) => dispatch => {
  const messageRef = database.ref('team_messages').child(teamId).child(roomId);

  messageRef
    .orderByChild('timestamp')
    .startAt(now)
    .on('child_added', snapshot => {
      dispatch({
        type: Types.INBOX_NEW_MESSAGE_ADDED,
        payload: { data: { ...snapshot.val(), key: snapshot.key }, roomId },
      });
    });

  messageRef
    .orderByKey()
    .limitToLast(1000)
    .on('child_changed', snapshot => {
      dispatch({
        type: Types.INBOX_SUCCESS_UPDATE_MESSAGES,
        payload: { key: snapshot.key, ...snapshot.val() },
      });
    });

  messageRef
    .orderByKey()
    .limitToLast(1000)
    .on('child_removed', snapshot => {
      dispatch({
        type: Types.INBOX_REMOVE_MESSAGES_SUCCESS,
        payload: { key: snapshot.key },
      });
    });
};

export const deleteMessage = ({ messageKey, currentRoomId }) => (dispatch, getState) => {
  const userId = _.get(getState(), 'user._id');
  const data = {
    chat_id: userId,
    message_id: messageKey,
    room_id: currentRoomId,
  };
  return dispatch(Request.post({ url: '/api/inbox/deleteMessage', data }, true, response => {}));
};

export const showInvalidRoom = error => dispatch => {
  if (
    _.get(error, 'response.data.message') === '22_1' ||
    _.get(error, 'response.data.message') === 'Please check with your workspace admin for permissions to this feature'
  ) {
    dispatch(initPermission());
  }
};

/**
 * Returns a function that dispatches an action to load more user rooms and updates the inbox state.
 *
 * @return {Function} An async function that takes dispatch and getState as parameters.
 */
export const getLoadMoreRooms = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const selectedRoomId = _.get(state, 'rootReducer.inboxNew.selectedRoom.id');
    const q = _.get(state, 'rootReducer.inboxNew.query.q');
    const isEnd = _.get(state, 'rootReducer.inboxNew.query.isEnd', false);
    const page = _.get(state, 'rootReducer.inboxNew.query.page', 1);
    const searchLoading = _.get(state, 'rootReducer.inboxNew.query.searchLoading', false);
    const total = _.get(state, 'rootReducer.inboxNew.query.total', 0);
    const searchRoomsData = _.get(state, 'rootReducer.inboxNew.query.rooms', []);
    if (!_.isEmpty(q)) {
      if (searchLoading) return;
      if (!isEnd || total > searchRoomsData.length) return dispatch(searchRooms({ q, page }));
      else return;
    }
    const currentUserId = _.get(state, 'user._id');
    let rooms = _.get(state, 'rootReducer.inboxNew.rooms', []);
    rooms = _.filter(rooms, it => it && !!it.id && it.id !== selectedRoomId);
    const longestInactiveUserRoom = _.head(_.orderBy(rooms, ['last_sent_message_at'], ['asc']));
    const longestInactiveUserRoomTimestamp = _.get(longestInactiveUserRoom, 'last_sent_message_at');
    const longestInactiveUserRoomId = _.get(longestInactiveUserRoom, 'id');
    if (!currentUserId || !longestInactiveUserRoomId || !longestInactiveUserRoomTimestamp) {
      return dispatch({
        type: Types.INBOX_FAILED_LOAD_MORE_ROOMS,
        payload: { error: 'Last active not found' },
      });
    }
    dispatch({ type: Types.INBOX_REQUEST_LOAD_MORE_ROOMS });
    const userRoomsData = await getUserRooms({
      userId: currentUserId,
      userRoomId: longestInactiveUserRoomId,
      timestamp: longestInactiveUserRoomTimestamp,
    });
    if (_.isEmpty(userRoomsData)) {
      return dispatch({
        type: Types.INBOX_FAILED_LOAD_MORE_ROOMS,
        payload: { error: 'Not found data' },
      });
    }
    const nextRooms = await getRooms({ userRooms: userRoomsData, userId: currentUserId });
    dispatch({
      type: Types.INBOX_SUCCESS_LOAD_MORE_ROOMS,
      payload: { rooms: nextRooms },
    });
    dispatch(subscribeRooms(Object.keys(userRoomsData)));
  } catch (error) {
    console.error('Firebase: load rooms error', error);
    Sentry.captureException(error);
    dispatch({
      type: Types.INBOX_FAILED_LOAD_MORE_ROOMS,
      payload: { error },
    });
  }
};

/**
 * Searches for rooms based on a given text search query and updates the inbox state with the results.
 *
 * @param {Object} param - An object param.
 * @param {Object} param.q - The text search query.
 * @param {Object} param.page - The page index.
 * @return {Function} A dispatch function that returns a Promise which resolves with the search results.
 */
export const searchRooms = ({ q = '', page = 1 }) => (dispatch, getState) => {
  const state = getState();
  const userId = _.get(state, 'user._id');
  const perPage = _.get(state, 'rootReducer.inboxNew.query.per_page', 20);
  const params = {
    q: q.toString(),
    page,
    per_page: perPage,
    include_archived_rooms: true,
  };
  dispatch({ type: Types.INBOX_REQUEST_SEARCH_ROOMS, payload: { q, page } });
  return dispatch(
    Request.get(
      { url: `/api/v2/inbox/rooms?${getQueryParamsFromObject(params)}` },
      false,
      response => {
        const total = _.get(response, 'data.data.total', 0);
        const roomsData = _.get(response, 'data.data.data', []);
        const rooms = formatSearchedRooms({ rooms: roomsData, userId });

        dispatch({
          type: Types.INBOX_SUCCESS_SEARCH_ROOMS,
          payload: {
            isEnd: total <= perPage * page,
            rooms,
            page: page + 1,
            q,
            total,
          },
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Types.INBOX_FAILED_SEARCH_ROOMS,
        });
      },
    ),
  );
};

export function formatSearchedRooms({ rooms = [], userId }) {
  return _.map(rooms, room => {
    const id = _.get(room, 'room_id');
    const type = _.get(room, 'type');
    let partnerData = { error: 'no data' };
    if (type === 'direct') {
      const chat_user = _.get(room, 'chat_user');
      partnerData = {
        ...chat_user,
        uid: _.get(chat_user, '_id'),
        unread: _.get(chat_user, 'unread'),
      };
    }
    const myData = { error: 'no data' };
    const lastMessage = _.get(room, 'last_message');
    if (!_.isEmpty(lastMessage) && _.isEmpty(_.get(lastMessage, 'timestamp'))) {
      lastMessage.timestamp = _.get(lastMessage, 'time');
    }
    return { ...room, id, parnerData: partnerData, myData, last_message: lastMessage };
  });
}

/**
 * Fetches user rooms data from the database and returns it as a Promise.
 *
 * @param {Object} options - Options for fetching user rooms data.
 * @param {string} options.userId - ID of the current user.
 * @param {string} options.userRoomId - ID of the user's last active room.
 * @param {number} options.timestamp - Timestamp of the user's last active room.
 * @returns {Promise<Object>} A Promise that resolves to an object containing user rooms data.
 */
export async function getUserRooms({ userId, profileId, userRoomId, timestamp }) {
  let userRoomsRef = database.ref('user_rooms').child(userId).orderByChild('last_sent_message_at');
  if (!!userRoomId && !!timestamp) {
    userRoomsRef = userRoomsRef.endAt(timestamp).limitToLast(ROOMS_PER_PAGE_LIMIT + 1);
  } else {
    userRoomsRef = userRoomsRef.limitToLast(ROOMS_PER_PAGE_LIMIT);
  }
  const userRoomsSnapshot = await userRoomsRef.once('value');
  let userRoomsData = userRoomsSnapshot.val();
  if (!!userRoomId) {
    delete userRoomsData[userRoomId];
  }
  if (profileId) {
    const roomId = `${userId}_${profileId}`;
    const reverseRoomId = `${profileId}_${userId}`;
    const groupId = profileId;
    const userRoomsRef = database.ref('user_rooms').child(userId).orderByKey();
    const [snapshot, reverseSnapshot, groupSnapshot] = await Promise.all([
      userRoomsRef.equalTo(roomId).once('value'),
      userRoomsRef.equalTo(reverseRoomId).once('value'),
      userRoomsRef.equalTo(groupId).once('value'),
    ]);
    let userRooms = snapshot.exists() ? snapshot.val() : reverseSnapshot.val();
    if (groupSnapshot.exists()) {
      userRooms = groupSnapshot.val();
    }
    userRoomsData = { ...userRoomsData, ...userRooms };
  }
  return userRoomsData;
}

/**
 * Fetches rooms data from the database and returns it as a Promise.
 *
 * @param {Object} params - an object containing room IDs as keys and room data as values.
 * @param {Array<string>} params.roomIds - an array of room IDs.
 * @param {string} params.userId - the user ID of the current user.
 * @return {Promise<Array<Object>>} A Promise that resolves to an array of rooms objects.
 */
export async function getRooms({ userRooms, userId }) {
  const roomIds = Object.keys(userRooms);
  const roomPromises = roomIds.map(id => database.ref('rooms').child(id).once('value'));
  const roomSnapshots = await Promise.all(roomPromises);
  const roomData = roomSnapshots.map(snapshot => snapshot.val());
  const formattedRooms = _.chain(roomData)
    .filter(room => !_.isEmpty(_.get(room, 'id')))
    .map(room =>
      formatRoomDetail({
        room,
        userId,
        userRoomValue: _.get(userRooms, _.get(room, 'id'), {}),
      }),
    )
    .value();

  return formattedRooms;
}

/**
 * Returns the user's chat room details if it exists, otherwise false.
 *
 * @param {Object} param - An object with currentUserId and profileId.
 * @param {string} param.profileId - The id of the user's profile or group ID.
 * @return {Promise} If the room exists, returns a promise that resolves to an object with userRooms and rooms properties,
 * otherwise returns false.
 */
export const getRoomIfExists = ({ profileId, saveToStore = false }) => async (dispatch, getState) => {
  try {
    const currentUserId = _.get(getState(), 'user._id');
    if (!currentUserId || !profileId) return false;
    const roomId = `${currentUserId}_${profileId}`;
    const reverseRoomId = `${profileId}_${currentUserId}`;
    const groupId = profileId;
    const userRoomsRef = database.ref('user_rooms').child(currentUserId).orderByKey();
    const [snapshot, reverseSnapshot, groupSnapshot] = await Promise.all([
      userRoomsRef.equalTo(roomId).once('value'),
      userRoomsRef.equalTo(reverseRoomId).once('value'),
      userRoomsRef.equalTo(groupId).once('value'),
    ]);
    let userRooms = snapshot.exists() ? snapshot.val() : reverseSnapshot.val();
    if (groupSnapshot.exists()) {
      userRooms = groupSnapshot.val();
    }
    if (!userRooms) return false;
    const rooms = await getRooms({ userRooms, userId: currentUserId });
    if (!rooms) return false;
    if (saveToStore) {
      dispatch({
        type: Types.INBOX_SUCCESS_UPDATE_ROOMS,
        payload: { rooms, from: 'get_room_if_exists' },
      });
    }
    return rooms[0];
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * Resets the direct room search in the inbox.
 *
 * @returns {Object} The action object.
 */
export const resetSearchRoom = () => ({ type: Types.INBOX_RESET_SEARCH_ROOMS });

export const resetMuteStatus = roomId => ({ type: Types.INBOX_RESET_MUTE_STATUS, payload: { roomId } });

export const startWaitingSystemMessage = () => ({ type: Types.INBOX_START_WAITING_SYSTEM_MESSAGE });

export const stopWaitingSystemMessage = () => ({ type: Types.INBOX_STOP_WAITING_SYSTEM_MESSAGE });

export const addSelectedRoomToList = (room, total) => ({
  type: Types.INBOX_ADD_SELECTED_ROOM,
  payload: { room, total },
});
