import _, { get } from 'lodash';
import { logout } from 'actions/auth/login';
import { SUCCESS_LOGIN_USER } from 'actions/auth/login';
import { onSubmitting, onSubmitted } from 'actions/submitStatus';
import moment from 'moment';
import Request from 'configs/request';
import { hideFeature } from 'redux/onboarding/actions';
import { setUserProperties } from 'libs/firebase-analytics';
import { sendFirstWebLoginData } from 'utils/hubspot';
import { isMobile } from 'react-device-detect';
import { mediaLog } from 'utils/commonFunction';
import { getCloudfrontList } from './cloudfrontList';

export const GET_PROFILE = 'GET_PROFILE';
export const FAILED_GET_PROFILE = 'FAILED_GET_USER_PROFILE';
export const ERROR_GET_PROFILE = 'ERROR_GET_PROFILE';

export const Types = {
  USER_ANSWERED_QUESTIONAIRE: 'USER_ANSWERED_QUESTIONAIRE',
  USER_ANSWERED_TRACKING_FROM: 'USER_ANSWERED_TRACKING_FROM',
  UPDATE_SETTING_INVITE_REDUX: 'UPDATE_SETTING_INVITE_REDUX',
  UPDATE_EMAIL_BILLING_REDUX: 'UPDATE_EMAIL_BILLING_REDUX',
  UPDATE_WORKOUT_COLLECTION_ONBOARDING: 'UPDATE_WORKOUT_COLLECTION_ONBOARDING',
};

const requestGetProfile = () => ({ type: GET_PROFILE });

const successGetUserProfile = user => ({ type: SUCCESS_LOGIN_USER, user });

const setGlobalUser = user => {
  window.activeUser = {
    fullName: user.full_name,
    email: user.email,
    teamId: user.current_team,
    userId: user._id,
  };
};

export const getProfile = params => {
  return dispatch => {
    if (!params || !params.isUpdated) dispatch(requestGetProfile());

    return dispatch(
      Request.get(
        { url: `/api/profile?timezone=${moment.tz.guess()}`, headers: { agent: 'react ' } },
        false,
        result => {
          const { user, firebase_token } = result.data;
          localStorage.setItem('firebase-token', firebase_token);

          setGlobalUser(user);

          if (
            !isMobile &&
            user &&
            user.email &&
            !user.logged_on_desktop &&
            localStorage.getItem('login_status') === 'just_now'
          ) {
            sendFirstWebLoginData(user);
            localStorage.removeItem('login_status');
          }

          if ((params || {}).originalImage) {
            user.avatar = (params || {}).originalImage;
          }
          dispatch(successGetUserProfile(user));
          dispatch(getCloudfrontList());
          setUserProperties(user);

          if (!user.show_onboarding) {
            dispatch(hideFeature());
          }
        },
        error => {
          const status = _.get(error, 'response.status');

          if (status !== 401) {
            dispatch(logout());
          }
        },
      ),
    );
  };
};

export const updateUserPreferences = params => {
  return dispatch => {
    dispatch(onSubmitting('TRAINER_UNITS'));

    return dispatch(
      Request.put(
        { url: '/api/profile/preferences/update', data: params },
        false,
        result => {
          dispatch(onSubmitted('TRAINER_UNITS', true));
          dispatch(getProfile({ isUpdated: true }));
        },
        error => {
          dispatch(onSubmitted('TRAINER_UNITS', false));
          dispatch({ type: 'ERROR_UPDATE_CLIENT_PROFILE', error });
        },
      ),
    );
  };
};

export const updateUserProfileThenUploadAvatar = (params, uploadConfig, callback) => {
  return Request.put(
    { url: '/api/profile/update', data: params },
    true,
    async (result, { dispatch }) => {
      if (uploadConfig) {
        await dispatch(uploadUserAvatar(uploadConfig, callback));
        dispatch(getProfile({ isUpdated: true, originalImage: (uploadConfig || {}).url }));
      } else {
        callback && callback();
        dispatch(getProfile({ isUpdated: true }));
      }
    },
    (error, { dispatch }) => {
      dispatch({ type: 'ERROR_UPDATE_CLIENT_PROFILE', error });
    },
  );
};

export const uploadUserAvatar = (uploadConfig, callback) => {
  return Request.put(
    uploadConfig,
    false,
    res => {
      if (typeof callback === 'function') {
        callback();
      }
      mediaLog({
        status: 2,
        name: get(res, 'data.user.avatar', ''),
        description: 'Upload success file via User Profile',
      });
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_UPLOAD_CLIENT_AVATAR', error }),
  );
};

export const userAnsweredQuestionaire = () => ({ type: Types.USER_ANSWERED_QUESTIONAIRE });

export const userAnsweredTrackingFrom = () => ({ type: Types.USER_ANSWERED_TRACKING_FROM });

export const updateSettingInvite = status => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_SETTING_INVITE_REDUX, payload: status });
  };
};

export const updateEmailBilling = param => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_EMAIL_BILLING_REDUX, payload: param.data });
  };
};

export const updateWorkoutCollectionOnboarding = () => {
  return dispatch => {
    dispatch({ type: Types.UPDATE_WORKOUT_COLLECTION_ONBOARDING, payload: true });
  };
};

export const updateTimezoneSettings = params => {
  return Request.put({ url: '/api/profile/update', data: params });
};
