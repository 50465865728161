import _ from 'lodash';
import Request from 'configs/request';
import { METRIC_SETTING_MODULE } from 'constants/commonData';

export const Types = {
  AUTOFLOW_GET_FEATURE_PREFERENCES: 'AUTOFLOW_GET_FEATURE_PREFERENCES',
  AUTOFLOW_SUCCESS_GET_FEATURE_PREFERENCES: 'AUTOFLOW_SUCCESS_GET_FEATURE_PREFERENCES',
  AUTOFLOW_FAILED_GET_FEATURE_PREFERENCES: 'AUTOFLOW_FAILED_GET_FEATURE_PREFERENCES',
  AUTOFLOW_UPDATE_FEATURE_PREFERENCES: 'AUTOFLOW_UPDATE_FEATURE_PREFERENCES',
  AUTOFLOW_SUCCESS_UPDATE_FEATURE_PREFERENCES: 'AUTOFLOW_SUCCESS_UPDATE_FEATURE_PREFERENCES',
  AUTOFLOW_FAILED_UPDATE_FEATURE_PREFERENCES: 'AUTOFLOW_FAILED_UPDATE_FEATURE_PREFERENCES',
  AUTOFLOW_TURN_ON_FEATURING: 'AUTOFLOW_TURN_ON_FEATURING',
  AUTOFLOW_SUCCESS_TURN_ON_FEATURING: 'AUTOFLOW_SUCCESS_TURN_ON_FEATURING',
  AUTOFLOW_FAILED_TURN_ON_FEATURING: 'AUTOFLOW_FAILED_TURN_ON_FEATURING',
  AUTOFLOW_GET_WORKOUT_VISIBILITY_PREFERENCES: 'AUTOFLOW_GET_WORKOUT_VISIBILITY_PREFERENCES',
  AUTOFLOW_SUCCESS_GET_WORKOUT_VISIBILITY_PREFERENCES: 'AUTOFLOW_SUCCESS_GET_WORKOUT_VISIBILITY_PREFERENCES',
  AUTOFLOW_FAILED_GET_WORKOUT_VISIBILITY_PREFERENCES: 'AUTOFLOW_FAILED_GET_WORKOUT_VISIBILITY_PREFERENCES',
  AUTOFLOW_UPDATE_WORKOUT_VISIBILITY_PREFERENCES: 'AUTOFLOW_UPDATE_WORKOUT_VISIBILITY_PREFERENCES',
  AUTOFLOW_SUCCESS_UPDATE_WORKOUT_VISIBILITY_PREFERENCES:
    'AUTOFLOW_SUCCESS_UPDATE_WORKOUT_VISIBILITY_PREFERENCES',
  AUTOFLOW_FAILED_UPDATE_WORKOUT_VISIBILITY_PREFERENCES:
    'AUTOFLOW_FAILED_UPDATE_WORKOUT_VISIBILITY_PREFERENCES',
  AUTOFLOW_GET_METRICS: 'AUTOFLOW_GET_METRICS',
  AUTOFLOW_SUCCESS_GET_METRICS: 'AUTOFLOW_SUCCESS_GET_METRICS',
  AUTOFLOW_FAILED_GET_METRICS: 'AUTOFLOW_FAILED_GET_METRICS',
  AUTOFLOW_METRIC_UPDATE_FEATURING: 'AUTOFLOW_METRIC_UPDATE_FEATURING',
  AUTOFLOW_SUCCESS_METRIC_UPDATE_FEATURING: 'AUTOFLOW_SUCCESS_METRIC_UPDATE_FEATURING',
  AUTOFLOW_FAILED_METRIC_UPDATE_FEATURING: 'AUTOFLOW_FAILED_METRIC_UPDATE_FEATURING',
  AUTOFLOW_UPDATE_METRIC: 'AUTOFLOW_UPDATE_METRIC',
  AUTOFLOW_SUCCESS_UPDATE_METRIC: 'AUTOFLOW_SUCCESS_UPDATE_METRIC',
  AUTOFLOW_FAILED_UPDATE_METRIC: 'AUTOFLOW_FAILED_UPDATE_METRIC',
  AUTOFLOW_ADD_METRIC: 'AUTOFLOW_ADD_METRIC',
  AUTOFLOW_SUCCESS_ADD_METRIC: 'AUTOFLOW_SUCCESS_ADD_METRIC',
  AUTOFLOW_FAILED_ADD_METRIC: 'AUTOFLOW_FAILED_ADD_METRIC',
  AUTOFLOW_DELETE_METRIC: 'AUTOFLOW_DELETE_METRIC',
  AUTOFLOW_SUCCESS_DELETE_METRIC: 'AUTOFLOW_SUCCESS_DELETE_METRIC',
  AUTOFLOW_FAILED_DELETE_METRIC: 'AUTOFLOW_FAILED_DELETE_METRIC',
  AUTOFLOW_SUCCESS_UPDATE_MULTIPLE_METRIC: 'AUTOFLOW_SUCCESS_UPDATE_MULTIPLE_METRIC',
  AUTOFLOW_FAILED_UPDATE_MULTIPLE_METRIC: 'AUTOFLOW_FAILED_UPDATE_MULTIPLE_METRIC',
};

export const getFeaturePreferences = (params) => {
  return (dispatch) => {
    dispatch({ type: Types.AUTOFLOW_GET_FEATURE_PREFERENCES });
    return dispatch(
      Request.get(
        { url: '/api/autoflow/setting/feature_preferences/list', params },
        true,
        (response) => {
          const { data } = response.data;
          dispatch({
            type: Types.AUTOFLOW_SUCCESS_GET_FEATURE_PREFERENCES,
            payload: {
              data: _.map(data, (item) => _.omit(item, ['createdAt', 'updatedAt', '__v'])),
            },
          });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_GET_FEATURE_PREFERENCES })
      )
    );
  };
};

export const turnOnFeaturing = (type) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.AUTOFLOW_TURN_ON_FEATURING });
    const {
      rootReducer: {
        autoflow: {
          common: { workingAutoflow },
        },
      },
    } = getState();

    return dispatch(
      Request.put(
        {
          url: `/api/autoflow/setting/feature_preferences/update`,
          data: { type, autoflow: _.get(workingAutoflow, '_id'), state: true },
        },
        true,
        () => dispatch({ type: Types.AUTOFLOW_SUCCESS_TURN_ON_FEATURING, payload: { type } }),
        () => dispatch({ type: Types.AUTOFLOW_FAILED_TURN_ON_FEATURING })
      )
    );
  };
};

export const updateFeaturePreferences = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.AUTOFLOW_UPDATE_FEATURE_PREFERENCES });
    return dispatch(
      Request.put(
        { url: '/api/autoflow/setting/feature_preferences/updateMany', data },
        true,
        (response) => {
          const { data } = response.data;
          dispatch({
            type: Types.AUTOFLOW_SUCCESS_UPDATE_FEATURE_PREFERENCES,
            payload: {
              data: _.map(data, (item) => _.omit(item, ['createdAt', 'updatedAt', '__v'])),
            },
          });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_UPDATE_FEATURE_PREFERENCES })
      )
    );
  };
};

export const getWorkoutVisibility = (params) => {
  return (dispatch) => {
    dispatch({ type: Types.AUTOFLOW_GET_WORKOUT_VISIBILITY_PREFERENCES });

    return dispatch(
      Request.get(
        { url: '/api/autoflow/setting/workout_visibility_preferences/list', params },
        true,
        (response) => {
          const { data } = response.data;
          dispatch({ type: Types.AUTOFLOW_SUCCESS_GET_WORKOUT_VISIBILITY_PREFERENCES, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_GET_WORKOUT_VISIBILITY_PREFERENCES })
      )
    );
  };
};

export const updateWorkoutVisibility = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.AUTOFLOW_UPDATE_WORKOUT_VISIBILITY_PREFERENCES });

    return dispatch(
      Request.put(
        { url: '/api/autoflow/setting/workout_visibility_preferences/updateMany', data },
        true,
        (response) => {
          const { data } = response.data;
          dispatch({ type: Types.AUTOFLOW_SUCCESS_UPDATE_WORKOUT_VISIBILITY_PREFERENCES, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_UPDATE_WORKOUT_VISIBILITY_PREFERENCES })
      )
    );
  };
};

export const getMetrics = (params) => {
  return (dispatch) => {
    dispatch({ type: Types.AUTOFLOW_GET_METRICS });

    return dispatch(
      Request.get(
        { url: '/api/body-metric/get-list-autoflow-body-metric', params },
        true,
        (response) => {
          const { data } = response.data;
          dispatch({ type: Types.AUTOFLOW_SUCCESS_GET_METRICS, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_GET_METRICS })
      )
    );
  };
};

export const updateMetricFeaturing = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.AUTOFLOW_METRIC_UPDATE_FEATURING });

    return dispatch(
      Request.put(
        { url: '/api/autoflow/metric/unfeaturing', data },
        true,
        (response) => {
          const { data } = response.data;
          dispatch({ type: Types.AUTOFLOW_SUCCESS_METRIC_UPDATE_FEATURING, payload: { data } });
        },
        () => dispatch({ type: Types.AUTOFLOW_FAILED_METRIC_UPDATE_FEATURING })
      )
    );
  };
};

export const updateMetric = (data) => {
  return Request.put(
    {
      url: `/api/body-metric/update-metric`,
      data: { ...data, module: METRIC_SETTING_MODULE.AUTOFLOW },
    },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.AUTOFLOW_SUCCESS_UPDATE_METRIC, payload: { data } });
    },
    (error, { dispatch }) => dispatch({ type: Types.AUTOFLOW_FAILED_UPDATE_METRIC })
  );
};

export const updateMultipleMetric = (data) => {
  return Request.put(
    {
      url: `/api/body-metric/update-autoflow-body-metric-setting`,
      data: { ...data, module: METRIC_SETTING_MODULE.AUTOFLOW },
    },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.AUTOFLOW_SUCCESS_UPDATE_MULTIPLE_METRIC, payload: { data } });
    },
    (error, { dispatch }) => dispatch({ type: Types.AUTOFLOW_FAILED_UPDATE_MULTIPLE_METRIC })
  );
};

export const addMetric = (data) => {
  return Request.post(
    { url: `/api/body-metric/add-new-metric`, data: { ...data, module: METRIC_SETTING_MODULE.AUTOFLOW } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.AUTOFLOW_SUCCESS_ADD_METRIC, payload: { data } });
    },
    (error, { dispatch }) => dispatch({ type: Types.AUTOFLOW_FAILED_ADD_METRIC })
  );
};

export const deleteMetric = (data) => {
  return Request.post(
    { url: `/api/body-metric/remove-metric`, data: { ...data, module: METRIC_SETTING_MODULE.AUTOFLOW } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch({ type: Types.AUTOFLOW_SUCCESS_DELETE_METRIC, payload: { data } });
    },
    (error, dispatch) => dispatch({ type: Types.AUTOFLOW_FAILED_DELETE_METRIC })
  );
};
