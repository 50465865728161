import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const OptionIcon = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  padding: 7px 15px 7px 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;

  ${props => props.shouldHide && `display: none;`}

  ::before {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(${CDN_URL}/images/${props => props.icon}.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  :hover {
    color: #5158cf;
    background-color: #eaebff;
    cursor: pointer;

    ${props =>
      props.hoverIcon &&
      css`
        ::before {
          background-image: url(${CDN_URL}/images/${props => props.hoverIcon}.svg);
        }
      `}
  }

  ${props =>
    props.darkMode &&
    css`
      color: #fff;

      :hover {
        color: #fff;
        background-color: #696969;
      }
    `}
`;
