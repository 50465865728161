import React, { useState, useEffect, useRef } from 'react';
import { convertMsToRawTime, pluralize } from 'utils/commonFunction';

function useTimeRemaining(targetDate, isRawResult = false) {
  const timerRef = useRef();

  const calculateTimeRemaining = () => {
    const currentDate = new Date();
    const endDate = new Date(targetDate);

    const timeDifference = endDate - currentDate;

    if (timeDifference <= 0) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      return null;
    }

    if (isRawResult) {
      return convertMsToRawTime(timeDifference);
    }

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

    if (years >= 1) {
      return years === 1 ? 'A year left' : `${pluralize('year', years, true)} left`;
    }

    if (months >= 1) {
      return `${months} month${months > 1 ? 's' : ''} left`;
    }

    if (days >= 1) {
      return `${days} day${days > 1 ? 's' : ''} left`;
    }
    if (hours >= 1) {
      return `${hours} hour${hours > 1 ? 's' : ''} left`;
    }

    if (minutes >= 1) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} left`;
    }

    return null;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [targetDate]);

  return timeRemaining;
}

export default useTimeRemaining;
