import styled from 'styled-components';
import { Modal, TextArea } from 'semantic-ui-react';

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 482px;
    padding: 30px;
    > .header {
      display: flex;
      border-radius: 8px;
      padding: 0;
    }
    > :last-child {
      padding: 40px 0 0;
      button {
        padding: 6px 27px;
      }
      button.empty {
        background: #ffffff;
        border: 1px solid #d6dae4;
        color: #818da1;
      }
      button.confirm {
        min-width: 170px;
        position: relative;
        height: 32px;
        &.disabled {
          background-color: #c4c4c4;
        }
        .ui.loader {
          width: 20px;
          height: 20px;
          &:before,
          &:after {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    > .content {
      padding: 0;
    }
    > .footer {
    }
  }
`;

export const ModalTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  margin: 0 0 0 4px;
`;

export const ModalDesc = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  margin-top: 15px;
  p {
    margin-bottom: 18px;
  }
`;
