import React, { memo, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import Slider from 'react-slick';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import classnames from 'classnames';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import { isMp3File, isVideoFile } from 'utils/validations';
import { ReactComponent as PrevSlider } from 'assets/icons/slider-prev.svg';
import { ReactComponent as NextSlider } from 'assets/icons/slider-next.svg';

const PrevArrow = ({ className, style, onClick }) => (
  <div onClick={onClick} className={classnames(className, 'slider-prev-arrow')} style={{ ...style, display: 'flex' }}>
    <PrevSlider />
  </div>
);

const NextArrow = ({ className, style, onClick }) => (
  <div onClick={onClick} className={classnames(className, 'slider-next-arrow')} style={{ ...style, display: 'flex' }}>
    <NextSlider />
  </div>
);

const IconClose = props => (
  <CloseButton className="close-button" {...props}>
    <img src={`${CDN_URL}/images/close-slider.svg`} alt="" />
  </CloseButton>
);

const initSettings = {
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
  infinite: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  centerMode: true,
  adaptiveHeight: false,
  variableWidth: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const SliderMediaModal = ({ isOpen, setIsOpen, attachments = [], images = [] }) => {
  const settings = useMemo(() => {
    return {
      ...initSettings,
      customPaging: function (idx) {
        return <img className="image-dots" src={images[idx * 2 + 1]} alt="" />;
      },
      initialSlide: get(isOpen, 'index', 0),
    };
  }, [isOpen, images]);

  useEffect(() => {
    if (isOpen) {
      handlePlayAMediaDebounce();
    }
    return () => {
      handlePlayAMediaDebounce.cancel();
      handlePauseAllMedia();
    };
  }, [isOpen]);

  const handlePauseAllMedia = () => {
    const sliderElement = document.querySelector('.custom-slider');
    const videoElements = sliderElement && sliderElement.querySelectorAll('video');
    videoElements && videoElements.forEach(element => element.pause());
  };

  const handlePlayAMedia = () => {
    const sliderElement = document.querySelector('.slick-active');
    const videoElement = sliderElement && sliderElement.querySelector('video');
    videoElement && videoElement.play();
  };

  const handlePlayAMediaDebounce = debounce(handlePlayAMedia, 100);

  const handleCloseModal = () => setIsOpen(null);

  return (
    <S.CustomModal
      open={!!isOpen}
      closeIcon={<IconClose />}
      onClose={handleCloseModal}
      className="evf-media-slider-modal"
      size="fullscreen"
    >
      <Modal.Content>
        <S.SliderWrapper>
          <Slider
            {...settings}
            className="custom-slider"
            afterChange={handlePlayAMedia}
            beforeChange={handlePauseAllMedia}
          >
            {map(attachments, (it, idx) => {
              return (
                <S.WrapperAttachment key={`attachment_${idx}`}>
                  {get(it, 'attachment_type').startsWith('video') ? (
                    <ReactPlayer
                      url={images[idx * 2]}
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: 'nodownload',
                          },
                          forceVideo: isVideoFile(get(it, 'attachment_type')),
                          forceAudio: isMp3File(get(it, 'attachment_type')),
                        },
                      }}
                    />
                  ) : (
                    <img src={images[idx * 2]} alt="" />
                  )}
                </S.WrapperAttachment>
              );
            })}
          </Slider>
        </S.SliderWrapper>
      </Modal.Content>
    </S.CustomModal>
  );
};

export default memo(SliderMediaModal);
