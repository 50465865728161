import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => `${props.size || 25}px`};
  height: ${props => `${props.size || 25}px`};
  border-radius: 50%;
  background-color: transparent;

  .__react_component_tooltip.app-tooltip {
    background-color: #2d2e2d;
    padding: 8px;
    width: 98px;
    height: 36px;
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    color: #ffffff;
  }

  :hover {
    background-color: #eaeef1;
    cursor: pointer;
  }

  .dot {
    width: 3px;
    height: 3px;
    position: relative;
    border-radius: 50%;
    background-color: #728096;

    ::before,
    ::after {
      content: '';
      width: 3px;
      height: 3px;
      position: absolute;
      border-radius: 50%;
      background-color: #728096;
      top: 0;
      left: -5px;
    }

    ::after {
      left: 5px;
    }
  }

  ${props =>
    props.vertical &&
    css`
      .dot {
        transform: rotate(90deg);
      }
    `}

  ${props =>
    props.active &&
    css`
      background-color: #eaeef1;
    `}
`;

export default props => (
  <Container {...props} data-tip data-for={`more-options__tooltip-${props.itemId}`}>
    <div className="dot" />
    {props.itemId && (
      <ReactTooltip id={`more-options__tooltip-${props.itemId}`} effect="solid" place="top" className="app-tooltip">
        <span>More options</span>
      </ReactTooltip>
    )}
  </Container>
);
