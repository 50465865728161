import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { LEADERBOARD_STATUS } from 'constants/commonData';
import { getLeaderboardUnitLabel } from 'utils/commonFunction';

import * as S from './style';
import Ranking from './Ranking';
import InfoEnding from './InfoEnding';

export default function LeaderboardDetail({ leaderboard, rankingTypes, clients, onGoToleaderboardtab }) {
  const [status, setStatus] = useState(leaderboard.status);
  const { isLoaded, list } = clients;
  const firstClient = list.length > 0 ? list[0] : null;
  const rankingType = rankingTypes.find(item => item.value === leaderboard.ranking_type);
  const unit = useMemo(() => {
    return getLeaderboardUnitLabel(rankingType, firstClient);
  }, [clients]);

  useEffect(() => {
    if (leaderboard) setStatus(leaderboard.status);
  }, [leaderboard]);

  const onCompleted = () => {
    setStatus(LEADERBOARD_STATUS.COMPLETED);
  };

  return (
    <S.Wrapper className="leaderboardDetail" onClick={onGoToleaderboardtab}>
      <S.BaseInforContainer>
        <div className="info">
          <div
            className={classnames('info__ending', {
              upcoming: status === LEADERBOARD_STATUS.UPCOMING,
              completed: status === LEADERBOARD_STATUS.COMPLETED,
            })}
          >
            <InfoEnding
              status={status}
              startDate={leaderboard.start_date}
              endDate={leaderboard.end_date}
              onCompleted={onCompleted}
            />
          </div>
          <div className="info__name">
            <span>{leaderboard.name}</span>
            {status === LEADERBOARD_STATUS.LIVE && <S.MarkLive>Live</S.MarkLive>}
          </div>
        </div>
        {isLoaded && (
          <Ranking firstPlace={list[0]} secondPlace={list[1]} thirdPlace={list[2]} unit={unit} status={status} />
        )}
      </S.BaseInforContainer>
    </S.Wrapper>
  );
}
