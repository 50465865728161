import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import ConfirmModal from 'shared/ConfirmModal';

export const ContentContainer = styled.div`
  flex: 1 1;
  display: flex;
  height: calc(100% - 52px);

  .workout-left-panel {
    position: relative;
    display: block;

    &.hidden {
      display: none;
    }
  }

  &.minimize {
    .workoutBuilder__mainPanelContainer {
      border-top-left-radius: 8px;
    }
  }

  :not(.minimize) {
    .workoutBuilder__mainPanelContainer {
      :before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        box-shadow: -1px 0px 20px rgba(30, 46, 103, 0.1);
      }
    }
  }
`;

export const Footer = styled.div`
  position: relative;
  padding: 10px 30px;
  box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);
  flex: 0 0;
  text-align: right;
  z-index: 10;

  > button {
    margin-left: 5px;
    padding: 6px 30px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #818da1;

    &.save {
      min-width: 90px;
    }

    &.saveAndClose {
      color: #fff;
      padding: 6px 30px;

      :hover {
        background-color: rgba(81, 88, 207, 0.9);
      }
    }
  }
`;

export const MainPanelContainer = styled.div`
  position: relative;
  flex: 1;
  width: 600px;
  height: auto;
  background: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  .arrow-icon {
    position: absolute;
    z-index: 1;
    display: flex;
    height: 29px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: -14px;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;

    &:hover {
      * {
        stroke: #5158cf;
      }
    }
    &.hidden-icon {
      transform: rotate(180deg);
    }
  }
`;

export const ContentStyles = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  background: #ffffff;
  border-radius: 8px;

  .workout-left-panel {
    width: 380px;
    height: 100%;
    background: #ffffff;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

export const ModalWrapper = styled(Modal)`
  &.ui.modal {
    background: transparent;
    box-shadow: none;
    width: auto;
    max-height: 710px;
    height: calc(100vh - 90px);

    .content {
      display: flex;
      flex-direction: row;
      border-top-left-radius: 12px !important;
      background: transparent !important;
      height: 100%;
    }
  }

  .header,
  .content {
    padding: 0 !important;
  }

  @media screen and (min-height: 1100px) {
    &.ui.modal {
      max-height: 930px;
    }
  }

  @media screen and (min-height: 1200px) {
    &.ui.modal {
      max-height: 1000px;
    }
  }

  @media screen and (min-height: 1300px) {
    &.ui.modal {
      max-height: 1100px;
    }
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    border-radius: 8px;
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        line-height: 25px;
        color: #202353;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 5px 0;
      }
      .confirm-no-button {
        min-width: 102px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 163px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;
