import React from 'react';
import * as S from './style';
import { connect } from 'react-redux';
import SearchClients from './SearchClients';
import SelectedClients from './SelectedClients';
import { Helmet } from 'react-helmet';

class AddingClient extends React.PureComponent {
  render() {
    if (!this.props.workingCollection) {
      return null;
    }

    return (
      <S.Wrapper>
        <Helmet>
          <title>{this.props.workingCollection.name} - Resource Collections - Everfit</title>
        </Helmet>
        <div className="addingClient__heading">Assign to</div>
        <div className="addingClient__body">
          <div className="addingClient__search">
            <SearchClients />
          </div>
          <div className="addingClient__selectedClient">
            <SelectedClients />
          </div>
        </div>
      </S.Wrapper>
    );
  }
}

const mapStateTopProps = state => {
  return {
    workingCollection: state.rootReducer.studioCollection.workingCollection,
    user: state.user,
  };
};

const mapDispatch = dispatch => ({});

export default connect(mapStateTopProps, mapDispatch)(AddingClient);
