import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { pauseClients, successPauseClients } from 'redux/autoflow/client/actions';
import {
  pauseClients as pauseClientsInterval,
  successPauseClients as successPauseClientsInterval,
} from 'redux/autoflowInterval/client/actions';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import * as S from './style';
import { AUTOFLOW_CLIENT_STATUS, AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';

function PauseClients(props) {
  const { autoflow, multiple, clients } = props;
  const activeClients = _.filter(clients, item => item.status === AUTOFLOW_CLIENT_STATUS.ACTIVE);
  const pausedClients = _.filter(clients, item => item.status === AUTOFLOW_CLIENT_STATUS.PAUSE);
  const client = clients[0];

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/pause.svg`} />
            <span>Pause {multiple ? 'selected clients' : '1 client'}</span>
          </S.Header>
          <S.Body>
            <div>
              This Autoflow’s workouts, tasks, and auto-messages from today onwards will be removed for{' '}
              {!!multiple ? 'the ' : ''}
              <b>
                {multiple
                  ? `${activeClients.length} active client${activeClients.length !== 1 ? 's' : ''}`
                  : `${client.full_name}`}
              </b>
              . Would you like to continue?
            </div>
            {!!multiple && (
              <>
                <S.Divide />
                <S.Note>
                  <div>
                    <b>
                      {activeClients.length} active client{activeClients.length !== 1 ? 's' : ''}
                    </b>{' '}
                    will have all scheduled items removed
                  </div>
                  {!!pausedClients.length && (
                    <div>
                      <b>
                        {pausedClients.length} paused client{pausedClients.length !== 1 ? 's' : ''}
                      </b>{' '}
                      have no items scheduled
                    </div>
                  )}
                </S.Note>
              </>
            )}
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              yellow
              onClick={() => {
                if (activeClients.length) {
                  props.pauseClients({ autoflow, clients: _.map(activeClients, '_id') }, multiple);
                } else {
                  if (multiple) {
                    props.successPauseClients({ multiple });
                  }
                }

                props.toggleModal(false);
              }}
            >
              Pause
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    autoflow: workingAutoflow && workingAutoflow._id,
  };
};

const mapDispatch = (dispatch, props) => {
  if (props.type === AUTOFLOW_TYPES.INTERVAL) {
    return {
      successPauseClients: bindActionCreators(successPauseClientsInterval, dispatch),
      toggleModal: bindActionCreators(toggleModal, dispatch),
      pauseClients: bindActionCreators(pauseClientsInterval, dispatch),
    };
  }
  return {
    successPauseClients: bindActionCreators(successPauseClients, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    pauseClients: bindActionCreators(pauseClients, dispatch),
  };
};

export default connect(mapState, mapDispatch)(PauseClients);
