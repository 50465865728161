import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { push } from 'connected-react-router';
import MemberList from './component';
import {
  getListUser,
  getGroupDetail,
  removeMember,
  transferOwnership,
  updateGroup,
  getConnectedLeaderboard,
} from 'redux/forum/actions';
import { toggleModal } from 'actions/modal';
import { toggleConfirmModal } from 'actions/modal';

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    cloudfrontList: state.cloudfrontList,
    group: state.rootReducer.forum.group,
    posts: state.rootReducer.forum.posts,
    members: state.rootReducer.forum.members,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const forumId = ownProps.match.params.forumId || ownProps.match.params.id;
  return {
    push: url => dispatch(push(url)),
    getGroupDetail: () => dispatch(getGroupDetail(forumId)),
    getListUser: () => dispatch(getListUser(forumId)),
    removeMember: userIds => dispatch(removeMember(forumId, userIds)),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    transferOwnership: userId => dispatch(transferOwnership(forumId, userId)),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    updateGroup: data => dispatch(updateGroup({ ...data, groupId: forumId })),
    getConnectedLeaderboard: () => dispatch(getConnectedLeaderboard(forumId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberList);
