import { Modal, Tab as TabSem } from 'semantic-ui-react';
import ConfirmModalElement from 'shared/ConfirmModal';
import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
`;

export const TaskModal = styled(Modal)`
  &.ui.tiny.modal {
    background: transparent;
    box-shadow: none;
    width: unset;
  }
`;

export const TaskModalContainer = styled.div`
  display: flex;
`;

export const TaskForm = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  width: 948px;
  height: 696px;
  padding: 32px;
  .ui.pointing.dropdown {
    position: absolute;
    top: 50px;
    right: 32px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    .dropdown.icon {
      display: none;
    }
    .icon.icon-more-action {
      margin-right: 0;
    }
    .menu {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(42, 42, 52, 0.15);
      border-radius: 8px;
      width: 190px;
      left: -87px;
      border: 1px solid #c4c4c4 !important;
      top: 8px;
      padding: 10px 0;
      .item {
        ${baseText}
        padding: 10px 20px !important;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 100%;
        color: #202353;
        svg {
          margin-right: 10px;
        }
        &:hover {
          background: #f6f5ff;
          color: #5158cf;
          svg path {
            stroke: #5158cf;
          }
          svg.icon-end {
            path {
              fill: #5158cf;
              stroke: none;
            }
          }
        }
      }
      &:after {
        top: -4px;
        border-top: 1px solid #c4c4c4 !important;
        border-left: 1px solid #c4c4c4 !important;
        box-shadow: none;
      }
    }
  }
`;

export const HabitDetail = styled(Modal)`
  position: relative;
  &.ui.modal {
    width: 948px;
    height: 696px;
    padding: 32px;
    border-radius: 8px;
  }
  .ui.pointing.dropdown {
    position: absolute;
    top: 50px;
    right: 32px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    .dropdown.icon {
      display: none;
    }
    .icon.icon-more-action {
      margin-right: 0;
    }
    .menu {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(42, 42, 52, 0.15);
      border-radius: 8px;
      width: 190px;
      left: -87px;
      border: 1px solid #c4c4c4 !important;
      top: 8px;
      padding: 10px 0;
      .item {
        ${baseText}
        padding: 10px 20px !important;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 100%;
        color: #202353;
        svg {
          margin-right: 10px;
        }
        &:hover {
          background: #f6f5ff;
          color: #5158cf;
          svg path {
            stroke: #5158cf;
          }
        }
      }
      &:after {
        top: -4px;
        border-top: 1px solid #c4c4c4 !important;
        border-left: 1px solid #c4c4c4 !important;
        box-shadow: none;
      }
    }
  }
`;

export const Tab = styled(TabSem)`
  padding: 0 !important;
  .ui.secondary.pointing.menu {
    width: fit-content;
    margin: auto;
    margin-bottom: 24px;
    border-bottom: 2px solid #ebedf2;
    .item {
      ${baseText}
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #99a1b1;
      padding: 12px 20px;
      &:hover {
        color: #99a1b1;
        text-decoration: none !important;
      }
      &.active {
        border-color: #5c5bbd;
        color: #5c5bbd;
        &:hover {
          color: #5c5bbd;
          border-color: #5c5bbd;
        }
      }
    }
  }
  .ui.segment {
    margin: 0;
    padding: 0;
    box-shadow: none;
    border: none;
  }
`;

export const HabitConfirmModal = styled(ConfirmModalElement)`
  &.ui.modal.confirm-popup-container {
    width: 447px !important;

    .confirm-content-header {
      padding: 30px 0 0 30px !important;
    }

    .confirm-content-body {
      padding: 15px 30px 40px 30px !important;
    }
    .confirm-actions {
      .confirm-yes-button {
        ${baseText};
      }

      .confirm-no-button {
        ${baseText};
      }
    }
  }
`;
