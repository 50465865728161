import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Info } from 'assets/icons/info_gray_out.svg';

import PropTypes from 'prop-types';

import * as S from './style';

const InfoTooltipIcon = ({ tooltipId, message = '', size, maxWidthContent, className = '' }) => {
  return (
    <S.InfoTooltipIcon
      data-tip={message}
      data-for={tooltipId}
      size={size}
      maxWidthContent={maxWidthContent}
      className={className}
    >
      <Info />
      <ReactTooltip id={tooltipId} effect="solid" trigger="click" place={'top'} delayShow={50} />
    </S.InfoTooltipIcon>
  );
};

InfoTooltipIcon.propTypes = {
  tooltipId: PropTypes.string,
  size: PropTypes.number,
  message: PropTypes.string,
  maxWidthContent: PropTypes.string,
  className: PropTypes.string,
};

export default InfoTooltipIcon;
