import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 30px;
  &:first-child {
    padding-top: 0;
  }
`;

export const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #202353;
  line-height: 19px;
  padding-bottom: 9px;
`;
