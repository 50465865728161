import { get, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { diff } from 'deep-diff';
import { push } from 'connected-react-router';
import { Prompt } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { getCoachBiosDetail, resetCoachDetailData, updateCoachBiosDetail } from 'redux/coach-bios-detail/actions';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import useStateCallback from 'hooks/useStateCallback';
import { ReactComponent as LeftArrowCoachBiosIcon } from 'assets/icons/arrow_left_coach_bios.svg';
import FormItemInput from 'shared/FormItemInput';
import GeneralButton from 'shared/GeneralButton';
import ConfirmModal from 'shared/ConfirmModal';
import LoadingIndicator from 'shared/LoadingIndicator';
import CoachBioEditor from './CoachBioEditor';
import { INPUT_MAX_LENGTH } from '../CoachBioList/constants';
import { Toggle } from 'shared/FormControl';

import * as S from './style';

const className = '.DraftEditor-editorContainer';

const CoachBioDetail = props => {
  const {
    match: { params },
    getCoachBiosDetail,
    push,
    coachBioInfo,
    resetCoachDetailData,
    updateCoachBiosDetail,
    toggleConfirmModal,
    updating,
    loading,
    isOwnerEdit,
    location,
  } = props;
  const isFromTeammatePage = location && location.pathname.includes('teammates');

  const [formData, setFormData] = useState(null);
  const [isChanged, setIsChanged] = useStateCallback(false);
  const [hasContent, setHasContent] = useState('');
  const [initBios, setInitBios] = useState('');

  // get coach bios detail
  useEffect(() => {
    if (params && params.coachId) {
      getCoachBiosDetail && getCoachBiosDetail(params.coachId);
    }
    return () => {
      resetCoachDetailData && resetCoachDetailData();
    };
  }, [params.coachId]);

  useEffect(() => {
    setFormData(coachBioInfo);
    setInitBios(get(coachBioInfo, 'bio', '').split(/ +/).join(' ').replaceAll('\n', ' ').replaceAll(/\s\s+/g, ' '));
  }, [coachBioInfo]);

  const showSaveButton = useMemo(() => {
    if (!diff(formData, coachBioInfo)) {
      setIsChanged(false);
      return false;
    }
    setIsChanged(true);
    return true;
  }, [formData]);

  // Start detect change keyboard (Not english)
  useEffect(() => {
    let options = {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true,
    };
    const observer = new MutationObserver(handleCheckTextChange);

    setTimeout(() => {
      const targetNode = document.querySelector(className);

      if (targetNode) {
        observer.observe(targetNode, options);
      }
    }, 1000);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleCheckTextChange = () => {
    const targetNode = document.querySelector(className);
    setHasContent(
      targetNode && targetNode.innerText && targetNode.innerText.trim().replaceAll('\n', ' ').replaceAll(/\s\s+/g, ' '),
    );
  };
  // End detect change keyboard (Not english)

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      role: isUndefined(e.target) ? null : e.target.value,
    });
  };

  const handleUpdate = () => {
    if (params && params.coachId && formData) {
      const coachId = get(params, 'coachId', '');
      const trimmedRole = handleExtraSpace(get(formData, 'role', ''));
      const trimmedBio = handleExtraSpace(get(formData, 'bio', ''));
      const newFormData = { ...formData, role: trimmedRole, bio: trimmedBio };

      updateCoachBiosDetail && updateCoachBiosDetail(coachId, newFormData);
      setHasContent('');
    }
  };

  const handleExtraSpace = text => text.trim().split(/ +/).join(' ');

  const handleGoBack = () => {
    isFromTeammatePage
      ? push({ pathname: '/home/teammates', state: { fromCoachBios: true } })
      : push('/home/coach-bios');
  };

  if (!coachBioInfo) {
    return null;
  }

  const confirmDiscardChange = path => {
    const pathname = isFromTeammatePage ? { pathname: path, state: { fromCoachBios: true } } : path;
    setHasContent('');
    setIsChanged(false, () => push(pathname));
  };

  const handleDiscardChange = nextLocation => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          confirmDiscardChange(nextLocation.pathname);
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
      />,
    );
    return false;
  };

  const handleBiosChange = value => {
    setFormData(formData => ({
      ...formData,
      bio: value.trim().split(/ +/).join(' '),
    }));
  };

  const isChangedDom = useMemo(() => {
    return !!hasContent && hasContent !== initBios;
  }, [hasContent]);

  const handleChangeToggleShowOnClientApp = () => {
    setFormData(formData => ({
      ...formData,
      is_show_client_app: !get(formData, 'is_show_client_app', false),
    }));
  };

  return (
    <S.Wrapper>
      <Prompt when={isChanged || isChangedDom} message={handleDiscardChange} />
      {loading ? (
        <S.LoadingWrapper>
          <LoadingIndicator title="Loading coach bio detail" className="loading-detail" />
        </S.LoadingWrapper>
      ) : (
        <>
          <S.WrapperTop>
            <S.ButtonBackWrapper>
              <S.ButtonContainer onClick={handleGoBack}>
                <LeftArrowCoachBiosIcon />
                <S.ButtonTitle className="button-title">Back</S.ButtonTitle>
              </S.ButtonContainer>
            </S.ButtonBackWrapper>
            {(showSaveButton || isChangedDom) && (
              <GeneralButton onClick={handleUpdate} disabled={updating}>
                Save
              </GeneralButton>
            )}
          </S.WrapperTop>
          <S.WrapperContent>
            <S.CoachDetail>
              <S.Avatar>
                <Avatar
                  name={get(formData, 'full_name', '')}
                  size="40"
                  src={get(formData, 'avatar', '')}
                  color={get(formData, 'color', '')}
                />
              </S.Avatar>
              <S.CoachDetailInfo>
                <S.CoachName>{get(formData, 'full_name', '')}</S.CoachName>
              </S.CoachDetailInfo>
              <S.ToggleShowCoach>
                <div className="toggle-label">Show on Client App</div>
                <Toggle
                  className="toggle-show-coach"
                  disabled={false}
                  checked={get(formData, 'is_show_client_app', false)}
                  width={40}
                  height={20}
                  onChange={handleChangeToggleShowOnClientApp}
                />
              </S.ToggleShowCoach>
            </S.CoachDetail>
            <S.CoachForm>
              <FormItemInput
                label={isOwnerEdit ? 'Your Title' : 'Coach Title'}
                placeholder={isOwnerEdit ? 'Enter your title' : 'Enter coach title'}
                name="role"
                value={get(formData, 'role', '')}
                onChange={handleFieldChange}
                inputProps={{ maxLength: INPUT_MAX_LENGTH }}
                maxLength={INPUT_MAX_LENGTH}
              />
              <CoachBioEditor
                isOwnerEdit={isOwnerEdit}
                initEditor={get(coachBioInfo, 'bio', '')}
                placeholder={isOwnerEdit ? 'Share something about you' : 'Share something about the coach'}
                autoFocus={true}
                loading={loading}
                onInputChange={handleBiosChange}
              />
            </S.CoachForm>
          </S.WrapperContent>
        </>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      coachBioDetails: { coachBioInfo, loading, updating, isOwnerEdit },
    },
  } = state;
  return { coachBioInfo, loading, updating, isOwnerEdit };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  getCoachBiosDetail: bindActionCreators(getCoachBiosDetail, dispatch),
  resetCoachDetailData: bindActionCreators(resetCoachDetailData, dispatch),
  updateCoachBiosDetail: bindActionCreators(updateCoachBiosDetail, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoachBioDetail);
