import moment from 'moment';
import { Types } from 'actions/bodyMetric';
import { DATA_POINT_KEYS, KEYS_METRIC_ARRAY, LINE_CHART, PERIOD_GROUP } from 'components/BodyMetricChartNew/constants';
import { Types as ClientTypes } from 'redux/client/client.actionCreators';
import { SLEEP_VIEW } from 'components/BodyMetricChartNew/components/SpecialChart/Sleep/constants';

const today = moment(new Date());
const FORMAT_STRING = 'yyyy-MM-DD';

const initialState = {
  mid: '',
  unique_code: '',
  name: '',
  unit: '',
  mode: 'last_2_month',
  chartData: [],
  isGettingChartData: false,
  isGettingTarget: false,
  isGettingSetting: false,
  isChangeSetting: false,
  isLoadingCompareChart: false,
  target: {},
  metricSettings: {
    type: LINE_CHART,
    color: null,
    unit: null,
    key_metrics: KEYS_METRIC_ARRAY,
    data_point: DATA_POINT_KEYS.latest_value,
    display_data_points: false,
  },
  filterTime: {
    period_group: PERIOD_GROUP.DAILY,
    from_date: today.clone().subtract(2, 'months').add(1, 'd').format(FORMAT_STRING), // default 2 months = 60 days
    to_date: today.format(FORMAT_STRING),
    timeRangeString: 'last_2_month',
  },
  sleepSettings: {
    viewBy: SLEEP_VIEW.DURATION, // or SLEEP_VIEW.TIME
    hasAsleepStageInRange: false,
    isShowStages: false,
    connectedApp: false,
    isRequesting: false,
  },
  isModeCompare: false,
  bodyMetricCompare: {},
  previousItem: {},
  nextItem: {},
  keyMetricData: {},
  isLoadingExerciseMetric: false,
};

const bodyMetric = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case Types.SELECT_BODY_METRIC:
      return Object.assign({}, state, {
        ...action.data,
        chartData: [],
        target: {},
        sleepSettings: { ...state.sleepSettings },
      });
    case Types.BODY_METRIC_SLEEP_UPDATE_GOAL_REQUEST:
      return Object.assign({}, state, { sleepSettings: { ...state.sleepSettings, isRequesting: true } });
    case Types.BODY_METRIC_SLEEP_UPDATE_GOAL_FAILED:
      return Object.assign({}, state, { sleepSettings: { ...state.sleepSettings, isRequesting: false } });
    case Types.BODY_METRIC_SLEEP_UPDATE_GOAL_SUCCESS:
      return Object.assign({}, state, {
        sleepSettings: { ...state.sleepSettings, isRequesting: false },
        target: {
          ...state.target,
          data: {
            ...(state.target.data || {}),
            ...payload,
          },
        },
      });
    case Types.BODY_METRIC_SLEEP_UPDATE_VIEW:
      return Object.assign({}, state, { sleepSettings: { ...state.sleepSettings, ...payload } });

    case ClientTypes.METRIC_UPDATED_SETTING_SUCCESS: {
      if (payload.metric.unique_code === state.unique_code) {
        return Object.assign({}, state, { name: payload.metric.name });
      }
      return state;
    }

    case Types.RESET_SELECTED_BODY_METRIC:
      return { ...initialState };

    case Types.SUCCESS_GET_BODY_METRIC_SLEEP_CHART_DATA: {
      const { data = [], keyMetricData = {}, unique_code, filterByDate } = payload || {};
      const isGroupView = [PERIOD_GROUP.WEEKLY, PERIOD_GROUP.MONTHLY].includes(filterByDate.period_group);
      if (unique_code === state.unique_code) {
        return Object.assign({}, state, {
          chartData: data,
          isGettingChartData: false,
          filterTime: filterByDate,
          keyMetricData,
          sleepSettings: {
            ...state.sleepSettings,
            viewBy: isGroupView ? SLEEP_VIEW.DURATION : state.sleepSettings.viewBy,
          },
        });
      }
      return state;
    }

    case Types.SUCCESS_GET_BODY_METRIC_CHART_DATA:
      const {
        data = [],
        previous_day = '',
        previous_value = '',
        previous_entry = {},
        average = null,
        previous_item = {},
        next_item = {},
        filterByDate,
      } = payload || {};

      const newData = data.map(item => ({
        ...item,
        previousDay: previous_day,
        previousValue: previous_value,
        previousEntry: previous_entry,
      }));

      if (payload.unique_code === state.unique_code) {
        const isGroupView = [PERIOD_GROUP.WEEKLY, PERIOD_GROUP.MONTHLY].includes((filterByDate || {}).period_group);
        if (payload.isFromNormalChart) {
          return Object.assign({}, state, {
            chartData: newData,
            isGettingChartData: false,
            filterTime: payload.filterByDate,
            average: average,
            previousItem: previous_item,
            nextItem: next_item,
            sleepSettings: {
              ...state.sleepSettings,
              viewBy: isGroupView ? SLEEP_VIEW.DURATION : state.sleepSettings.viewBy,
            },
          });
        }
        return Object.assign({}, state, {
          chartData: newData,
          isGettingChartData: false,
          average: average,
          previousItem: previous_item,
          nextItem: next_item,
        });
      }
      return state;

    case Types.GETTING_BODY_METRIC_CHART_DATA:
      return Object.assign({}, state, {
        isGettingChartData: true,
        chartData: [],
        previousItem: {},
        nextItem: {},
      });

    case Types.UPDATE_FILTER_TIME_METRIC_CHART:
      return Object.assign({}, state, { filterTime: payload });

    case Types.GETTING_BODY_METRIC_TARGET:
      return Object.assign({}, state, { isGettingTarget: true, target: {} });

    case Types.RESET_BODY_METRIC_TARGET:
      return Object.assign({}, state, { isGettingTarget: false, target: {}, average: null });

    case Types.SUCCESS_GET_BODY_METRIC_TARGET:
      if (state.unique_code === payload.unique_code) {
        return Object.assign({}, state, { target: payload.data, isGettingTarget: false });
      }
      return state;

    case Types.SUCCESS_UPDATE_BODY_METRIC_TARGET:
      return Object.assign({}, state, { target: { ...state.target, data: payload.data } });
    case Types.CHANGE_METRIC_SETTING_REQUEST:
      return Object.assign({}, state, { isChangeSetting: true });

    case Types.CHANGE_VIEW_MODE:
      return Object.assign({}, state, { mode: payload.data || initialState.mode });
    case Types.GETTING_BODY_METRIC_SETTING:
      return Object.assign({}, state, { isGettingSetting: true });

    case Types.SUCCESS_GET_BODY_METRIC_SETTING:
      const { bodymetricTypes = [] } = payload;
      const metricType = bodymetricTypes.find(item => item.unique_code === state.unique_code) || {};
      const { unit: defaultUnit } = metricType;

      return Object.assign({}, state, {
        isGettingSetting: false,
        isChangeSetting: false,
        metricSettings: payload.data
          ? { ...initialState.metricSettings, ...payload.data }
          : {
              ...initialState.metricSettings,
              unit: defaultUnit,
            },
      });

    case Types.TOGGLE_COMPARISION_MODE_METRIC_CHART: {
      const { status, bodyMetricCompare } = payload;

      return Object.assign({}, state, { isModeCompare: status, bodyMetricCompare: bodyMetricCompare });
    }

    case Types.BODY_METRIC_CHANGE_GETTING_CHART_DATA: {
      const { status } = payload;

      return Object.assign({}, state, {
        isGettingChartData: status,
      });
    }

    case Types.BODY_METRIC_RESET_FILTER_TIME: {
      return Object.assign({}, state, {
        chartData: [],
        isGettingChartData: false,
        filterTime: initialState.filterTime,
        previousItem: {},
        nextItem: {},
      });
    }

    case Types.BODY_METRIC_UPLOAD_LOADING_EXERCISE: {
      const { status = false } = payload;
      return Object.assign({}, state, {
        isLoadingExerciseMetric: status,
      });
    }
    case Types.BODY_METRIC_LOADING_COMPARE_CHART: {
      const { status = false } = payload;
      return Object.assign({}, state, {
        isLoadingCompareChart: status,
      });
    }

    default:
      return state;
  }
};

export default bodyMetric;
