import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Popup } from 'semantic-ui-react';
import { ReactComponent as AddIcon } from 'assets/icons/consultation-add.svg';
import { ReactComponent as UploadDocIcon } from 'assets/icons/consultation-upload-document.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/consultation-remove.svg';
import { ReactComponent as ViewDocIcon } from 'assets/icons/consultation-view-doc.svg';
import ConfirmModal from 'shared/ConfirmModal';
import { SelectFile } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

import * as S from './styles';
import { SharedTooltip } from 'shared/SharedTooltip';

export default function ConsultationButton({
  uploadConstultationFile,
  deleteConsultationFile,
  consultationDocument,
  client,
  toggleConfirmModal,
}) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (file && !consultationDocument) {
      uploadConstultationFile(file, client._id);
    }
  }, [file]);

  const handleFileSelect = file => {
    setFile(file);
  };

  const handleOpenDoc = () => {
    handleClose();
    const url = consultationDocument.document_url;
    window.open(url, '_blank');
  };

  const handleRemoveDoc = () => {
    handleClose();
    toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Consultation Document"
        content="Would you like to remove your attached document? The document will be permanently removed."
        onConfirm={() => {
          deleteConsultationFile(client._id);
          handleClose();
        }}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {consultationDocument ? (
        <Popup
          trigger={
            <div>
              <ViewDocIcon
                className={`add-icon-button ${open ? 'active' : ''}`}
                data-for="consultation-document-tooltip"
                data-tip="View Consultation Document"
              />
              {!open && <SharedTooltip id="consultation-document-tooltip" content="Consultation Document" />}
            </div>
          }
          on="click"
          position="bottom center"
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <div className="consultation-popup-content">
            <div className="consultation-popup-button" onClick={handleOpenDoc}>
              <UploadDocIcon />
              <p>Open Document</p>
            </div>
            <div className="consultation-popup-button" onClick={handleRemoveDoc}>
              <RemoveIcon />
              <p>Remove</p>
            </div>
          </div>
        </Popup>
      ) : (
        <>
          <SelectFile
            trigger={<AddIcon data-tip="Upload Consultation Document" />}
            onSelect={handleFileSelect}
            accept=".doc, .docx, .xls, .xlsx, application/pdf"
            validateExtentions={['doc', 'docx', 'xls', 'xlsx', 'pdf']}
            maxSize={25}
            fileSizeLimitMessage="Image size limit is 25MB."
          />
          <ReactTooltip effect="solid" className="consultation-tooltip" arrowColor="#2d2e2d" />
        </>
      )}
    </>
  );
}
