import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const PaymentMethodWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #D5D9E9;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;

  .paymentMethod__heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    color: #202353;
    margin-bottom: 25px;

    img {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .paymentMethod__changeMethodBtn {
    width: 100%;
    padding: 15px;
    border: none;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    margin-top: 20px;
    cursor: pointer;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: #6261FF;
    }

    :hover {
      background: #F5F4FF;
      border: 1px solid #FFFFFF;
      border-radius: 3px;
    }
  }
`;

export const PaymentMethodContainer = styled.div`
  padding: 30px 25px;

  span {
    text-align: center;
    line-height: 150%;
  }
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 15px;
  min-height: 46px;
  padding-left: 80px;

  background: #FFFFFF;
  border: 1px solid #D5D9E9;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;

  :before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: 25px;
    z-index: 1;
    background: transparent url(${(props) => props.logo || `${CDN_URL}/images/card_placeholder.svg`}) no-repeat center;
    background-size: 100%;
    width: 40px;
    height: 29px;
  }

  svg {
    margin-right: 16px;
  }
`;

export const Card = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #202353;

  .card-name {
    text-transform: uppercase;
  }

  .expires {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.4;
  }
`;