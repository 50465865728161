// Lib
import React from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';

// Constants
import { pluralize, getUserShortName, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

// Assets
import SearchGroupAvatar from 'assets/icons/search-group.svg';

import * as S from './style';

const CustomOption = props => {
  const { data, innerRef, innerProps, cloudfrontList } = props || {};
  const {
    typeName = '',
    avatar = '',
    color = '',
    name = '',
    total = 0,
    email = '',
    invalid_type: invalidType = false,
  } = data || {};

  const isGroup = typeName === 'group';

  const renderTotalClient = totalClient => {
    const label = pluralize('client', totalClient);

    return String(totalClient).concat(' ', label);
  };

  return (
    <S.CustomOption ref={innerRef} {...innerProps} isAssigned={invalidType}>
      <div className="client-container">
        <div className="client-avatar-wrapper">
          <Avatar
            name={getUserShortName(data)}
            className="client-avatar"
            size={36}
            src={isGroup ? SearchGroupAvatar : convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true)}
            color={color}
          />
        </div>
        {isGroup ? (
          <div className="group-info-wrapper">
            <div className="group-title-wrapper">
              <div className="group-label">Group:&nbsp;</div>
              <div className="group-name">{name}</div>
            </div>
            <div className="group-total">{renderTotalClient(total)}</div>
          </div>
        ) : (
          <div className="client-info-wrapper">
            <div className="client-name">{name}</div>
            <div className="client-email">{email}</div>
            {invalidType && <div className="client-status">(Conflict with the period of the assigned meal plans)</div>}
          </div>
        )}
      </div>
    </S.CustomOption>
  );
};

const mapState = state => {
  const { cloudfrontList } = state;

  return { cloudfrontList };
};

const mapDispatch = dispatch => ({});

export default connect(mapState, mapDispatch)(CustomOption);
