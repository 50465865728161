import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 280px calc(100% - 560px) 280px;
  height: calc(100% - 60px);
`;
