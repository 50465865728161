import React, { useState } from 'react';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { push } from 'connected-react-router';
import { Button, FormGroup } from 'shared/FormControl';
import { DropdownIndicator } from 'shared/Icons';
import { Actions } from 'redux/trainer/actions';
import { toggleModal } from 'actions/modal';
import { getUserShortName } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';
import './style.scss';

const SelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select_option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  const name = `${data.first_name} ${data.last_name}`;

  return (
    <S.SelectOptionContainer {...innerProps} className={className}>
      <Avatar name={getUserShortName(data)} className="avatar" size="26" src={data.avatar} color={data.color} />
      <S.SelectOptionLabel className="select__option__label">
        <div className="name">{name}</div>
        <div className="email">{data.email}</div>
      </S.SelectOptionLabel>
    </S.SelectOptionContainer>
  );
};

function TransferClient(props) {
  const { open, user, client } = props;

  const [confirmScreen, setConfirmScreen] = useState(false);
  const [owner, setOwner] = useState(null);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const originTrainer = _.get(client, 'trainers', [])[0] || {};
  const teamMembers = _.filter(user.team_member || [], item => item._id !== originTrainer.trainer);
  const isOwner = user._id === originTrainer.trainer;

  const options = _.map(teamMembers, item => {
    return Object.assign({}, item, {
      key: item._id,
      value: item._id,
      label: `${item.first_name} ${item.last_name}`,
    });
  });

  const onClose = () => {
    if (typeof props.onClose === 'function') {
      props.onClose();
    } else {
      props.toggleModal(false);
    }
  };

  const onUpdate = () => {
    if (!owner) {
      return setError(true);
    }

    setConfirmScreen(true);
  };

  const onContinue = () => {
    if (!submitting && owner && client) {
      const data = {
        client: client._id,
        newTrainer: owner._id,
      };

      setSubmitting(true);
      props
        .transferClient(data)
        .then(() => {
          props.push('/home/client');
          props.toggleModal(false);
          toast.success(
            <div>
              <div>Success! Client transfer process has begun.</div>
              <div>This may take a few minutes to transfer all the data.</div>
            </div>,
          );
        })
        .catch(() => setSubmitting(false));
    }
  };

  return (
    <Modal
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="Close" />
        </CloseButton>
      }
      closeOnDimmerClick={false}
      onClose={onClose}
      className="transfer-client-modal"
      onOpen={onClose}
      open={open}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <img src={`${CDN_URL}/images/transfer_client.svg`} alt="Transfer" />
            <span>{confirmScreen ? 'Confirm transfer' : 'Change Ownership'}</span>
          </S.Header>
          <S.Body>
            <div className="description">
              {confirmScreen ? (
                <span>
                  <b>{_.get(client, 'full_name')}</b> will be transferred to <b>{_.get(owner, 'full_name')}</b>, and{' '}
                  {isOwner
                    ? 'you will lose access to the client unless you are an admin'
                    : 'the current trainer will lose access to the client unless he or she is an admin'}
                  . Would you like to continue?
                </span>
              ) : (
                <span>
                  Choose a teammate you would like to transfer the client to. All details regarding the client will be
                  transferred to the new trainer besides the inbox conversation.
                </span>
              )}
            </div>
            {!confirmScreen && (
              <FormGroup>
                <label>Change OwnerShip to</label>
                <Select
                  options={options}
                  components={{ Option: SelectOption, DropdownIndicator }}
                  classNamePrefix="single-select"
                  placeholder="Select teammate"
                  onChange={data => {
                    setOwner(data);
                  }}
                  className="single-select-container"
                  noOptionsMessage={() => 'No result'}
                />
                {error && !owner ? <div className="error">Please select the trainer</div> : null}
              </FormGroup>
            )}
          </S.Body>
          <S.Footer>
            {confirmScreen ? (
              <>
                <Button onClick={onClose}>Cancel</Button>
                <Button purple onClick={onContinue}>
                  Continue
                </Button>
              </>
            ) : (
              <Button purple onClick={onUpdate}>
                Update
              </Button>
            )}
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

TransferClient.props = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

const mapState = state => {
  const { user, isModalOpen, rootReducer } = state;
  return {
    user,
    open: isModalOpen,
    client: rootReducer.client.workingClientDetail,
  };
};

export default connect(mapState, { ...Actions, toggleModal, push })(TransferClient);
