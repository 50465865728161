import React from 'react';

import { PRODUCT_STATUS } from 'components/Product/constants';
import { SharedTooltip } from 'shared/SharedTooltip';

import { ReactComponent as EditIcon } from 'assets/icons/action_edit.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/action_duplicate.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/action_archive.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/task_bin.svg';

import DropDown, { Option } from 'shared/Dropdown/Basic';

import * as S from './style';

const handleRenderTriggerIcon = open => {
  return (
    <div data-tip data-for="sequences-detail-options-tooltip">
      <S.TriggerIcon className="trigger-icon" active={!!open} />
      {open && <S.DropdownArrow></S.DropdownArrow>}
      {!open && <SharedTooltip id="sequences-detail-options-tooltip" />}
    </div>
  );
};

const Dropdown = ({ status, onDuplicate, onArchive, onDelete }) => {
  const handleRender = () => {
    switch (status) {
      case PRODUCT_STATUS.DRAFT:
        return (
          <S.DropdownWrapper>
            <DropDown direction="left" triggerIcon={({ open }) => handleRenderTriggerIcon(open)}>
              <Option key="duplicate" onClick={onDuplicate}>
                <DuplicateIcon />
                <span>Duplicate</span>
              </Option>
              <Option key="archive" onClick={onArchive}>
                <ArchiveIcon />
                <span>Archive</span>
              </Option>
              <Option key="delete" onClick={onDelete}>
                <DeleteIcon />
                <span>Delete</span>
              </Option>
            </DropDown>
          </S.DropdownWrapper>
        );
      case PRODUCT_STATUS.PUBLISH:
        return (
          <S.DropdownWrapper>
            <DropDown direction="left" triggerIcon={({ open }) => handleRenderTriggerIcon(open)}>
              <Option key="duplicate" onClick={onDuplicate}>
                <DuplicateIcon />
                <span>Duplicate</span>
              </Option>
              <Option key="archive" onClick={onArchive}>
                <ArchiveIcon />
                <span>Archive</span>
              </Option>
            </DropDown>
          </S.DropdownWrapper>
        );
      default:
        return (
          <S.DropdownWrapper>
            <DropDown direction="left" triggerIcon={({ open }) => handleRenderTriggerIcon(open)}>
              <Option key="duplicate" onClick={onDuplicate}>
                <DuplicateIcon />
                <span>Duplicate</span>
              </Option>
            </DropDown>
          </S.DropdownWrapper>
        );
    }
  };

  return handleRender();
};

export default Dropdown;
