import Request from 'configs/request';
import { toast } from 'react-toastify';

// data: {
//   "reaction": "string",
//   "detail": "string",
//   "rating": "string[]"
// }
export const sendFeedback = (conversionId, data, showToast = true) => {
  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: `/api/workout-ai/conversions/${conversionId}/rating`,
          data,
        },
        false,
        () => {
          if (showToast) {
            toast('Thanks for your feedback.');
          }
        },
      ),
    );
  };
};

// data: {
//   "reaction":"reaction",
//   "detail":"goodd",
//   "rating": ["good", "well"],
//   "user_input":"123",
//   "json_output":"123",
//   "session_id": "65bb7fab4931f592c0bf3490"
// }
// https://api-dev3.everfit.io/api/workout-ai/conversions/example/rating
export const sendFeedbackForExample = (data, showToast = true) => {
  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: '/api/workout-ai/conversions/example/rating',
          data,
        },
        false,
        () => {
          if (showToast) {
            toast('Thanks for your feedback.');
          }
        },
      ),
    );
  };
};

// params: { user_id }
export const getAISession = params => {
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: `/api/workout-ai/sessions`,
          params,
        },
        false,
      ),
    );
  };
};

export const getAIModels = () => {
  return dispatch => dispatch(Request.get({ url: `/api/workout-ai/models` }, false));
};

// data: { feedback: string }
export const submitTellMoreUs = data => {
  return dispatch => dispatch(Request.post({ url: `/api/workout-ai/notify-feedback`, data }, false));
};

// data: { user_id }
export const createNewAISession = data => {
  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: `/api/workout-ai/sessions`,
          data,
        },
        false,
      ),
    );
  };
};

export const getAIConversations = params => {
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: `/api/workout-ai/conversions`,
          params,
        },
        false,
      ),
    );
  };
};

// data: { session_id, user_input }
export const createNewAIConversation = data => {
  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: `/api/workout-ai/conversions`,
          data,
        },
        false,
        undefined,
        error => {
          throw error;
        },
        false,
      ),
    );
  };
};

// data: { session_id, user_input, json_output }
export const getConversationIDForExample = data => {
  return dispatch => {
    return dispatch(
      Request.post(
        {
          url: '/api/workout-ai/conversions/example',
          data,
        },
        false,
      ),
    );
  };
};
