// Lib
import React, { useEffect, useRef, useState } from 'react';

// Assets
import { ReactComponent as NewNoteIcon } from 'assets/icons/note_new_icon.svg';
import { ReactComponent as ViewNoteIcon } from 'assets/icons/note_view_icon.svg';

// Styles
import * as S from './style';

const AddNote = ({
  content = 'Leave a note for Week 1',
  hasNote = false,
  onClick,
  isPublish = false,
  isEditMealPlan = false,
  hasShowContent = false,
  note = '',
}) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [isOneline, setIsOneline] = useState(false);
  const noteRef = useRef(null);

  const disabled = isPublish && !isEditMealPlan && !hasNote;

  const handleClick = () => {
    onClick && onClick();
  };

  useEffect(() => {
    const noteElement = noteRef && noteRef.current;

    if (noteElement) {
      setIsOverflowed(noteElement.scrollHeight > 69);
    }

    if (noteElement) {
      setIsOneline(noteElement.scrollHeight > 23);
    }
  }, [note]);

  return (
    <S.AddNoteWrapper
      hasNote={hasNote}
      hasShowContent={hasShowContent}
      isOneline={isOneline}
      onClick={handleClick}
      disabled={disabled}
      className="meal-plan-add-note"
    >
      {hasNote ? (
        <>
          {hasShowContent ? (
            <>
              <ViewNoteIcon />
              <div className="meal-plan-note-wrapper">
                <div className="meal-plan-note-content" ref={noteRef}>
                  {note}
                </div>
                {isOverflowed ? (
                  <div
                    className="meal-plan-read-more"
                    onClick={e => {
                      e.stopPropagation();
                      onClick();
                    }}
                  >
                    Read more
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <>
              <ViewNoteIcon />
              View Note
            </>
          )}
        </>
      ) : (
        <>
          <NewNoteIcon />
          {content}
        </>
      )}
    </S.AddNoteWrapper>
  );
};

export default AddNote;
