import styled from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
`;

export const NutritionOverviewWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0px 2px 30px 0px rgba(172, 172, 172, 0.2);
  display: flex;
  align-items: center;
  height: 64px;
`;

export const NutritionItem = styled.div`
  flex: 1;
  width: 25%;
  text-align: center;
`;

export const Unit = styled.p`
  ${baseText}
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
  margin-bottom: 3px;
  span {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Name = styled.p`
  ${baseText}
  text-transform: capitalize;
`;
