import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .overviewHeading__right {
    display: flex;
    gap: 5px;
  }

  .overviewHeading__dropdownOptions {
    .trigger-icon {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #d6dae4;
      box-sizing: border-box;
      border-radius: 5px;

      .dot,
      .dot::before,
      .dot::after {
        background-color: #d6dae4;
      }

      &:hover {
        background: #ffffff;

        .dot,
        .dot::before,
        .dot::after {
          background-color: #000000;
        }
      }
    }
  }
`;

export const DotButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #d6dae4;
  box-sizing: border-box;
  border-radius: 5px;

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #d6dae4;
    position: relative;
    ::before,
    ::after {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #d6dae4;
      position: absolute;
      top: 0;
    }

    ::before {
      left: -5px;
    }

    ::after {
      right: -5px;
    }
  }
`;
