import React from 'react';
import { toggleModal } from 'actions/modal';
import Request from 'configs/request';
import { toast } from 'react-toastify';
import { Types as TypesAutoFlow, getAutoflowClientStatistic } from 'redux/autoflow/actions';
import { changeAutoflowClientQueryParam, getClientList } from './client/actions';
import { updateWeekView } from './helper';
import InactiveAutoflowHasClientStartInPastWarning from 'components/AutoflowConfirmModals/InactiveAutoflowHasClientStartInPastWarning';

export const Types = {
  AUTOFLOW_INTERVAL_CHANGE_VIEW_MODE: 'AUTOFLOW_INTERVAL_CHANGE_VIEW_MODE',
  AUTOFLOW_INTERVAL_GO_TO_WEEK: 'AUTOFLOW_INTERVAL_GO_TO_WEEK',
  AUTOFLOW_INTERVAL_SUCCESS_GET_DETAIL: 'AUTOFLOW_INTERVAL_SUCCESS_GET_DETAIL',
  AUTOFLOW_INTERVAL_UPDATE_WEEK_VIEW: 'AUTOFLOW_INTERVAL_UPDATE_WEEK_VIEW',
  AUTOFLOW_INTERVAL_REMOVE_WEEK: 'AUTOFLOW_INTERVAL_REMOVE_WEEK',
  AUTOFLOW_INTERVAL_ADD_WEEK: 'AUTOFLOW_INTERVAL_ADD_WEEK',
  AUTOFLOW_INTERVAL_CHANGE_START_DATE: 'AUTOFLOW_INTERVAL_CHANGE_START_DATE',
  AUTOFLOW_INTERVAL_SUCCESS_COUNT_CLIENTS: 'AUTOFLOW_INTERVAL_SUCCESS_COUNT_CLIENTS',
};

export const successGetClientStatistic = payload => ({ type: Types.AUTOFLOW_INTERVAL_SUCCESS_COUNT_CLIENTS, payload });

export const changeViewMode = mode => ({
  type: Types.AUTOFLOW_INTERVAL_CHANGE_VIEW_MODE,
  payload: { mode },
});

export const goToWeek = week => ({
  type: Types.AUTOFLOW_INTERVAL_GO_TO_WEEK,
  payload: { week },
});

export const updateAutoflowIntervalWeekView = autoflowData => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        autoflowInterval: { common },
      },
    } = getState();

    dispatch({
      type: Types.AUTOFLOW_INTERVAL_UPDATE_WEEK_VIEW,
      payload: {
        data: updateWeekView(autoflowData.interval_weeks.length, common.get('startWeek'), common.get('viewMode')),
      },
    });
  };
};

export const pauseAutoflow = autoflow => {
  return dispatch => {
    toast.success(
      `Autoflow has been paused successfully. Changes to each client’s calendar may take a few minutes to complete.`,
      { className: 'margin-right--small' },
    );

    return dispatch(
      Request.post({ url: `/api/autoflow-interval/deactive`, data: { autoflowId: autoflow } }, true, response => {
        const { data } = response.data;
        dispatch(getAutoflowClientStatistic({ autoflow: data._id }));
        dispatch({ type: TypesAutoFlow.AUTOFLOW_SUCCESS_PAUSE, payload: { data: autoflow } });
        dispatch(changeAutoflowClientQueryParam({ page: 1 }));
        dispatch(getClientList());
      }),
    );
  };
};

export const resumeAutoflow = autoflow => {
  return dispatch => {
    return dispatch(
      Request.post({ url: `/api/autoflow-interval/active`, data: { autoflowId: autoflow } }, true, response => {
        const { data } = response.data;
        if (!data.success) {
          return dispatch(toggleModal(true, <InactiveAutoflowHasClientStartInPastWarning />));
        }
        toast.success(
          `Autoflow has been activated successfully. Changes to each client’s calendar may take a few minutes to complete.`,
          { className: 'margin-right--small' },
        );
        dispatch(getAutoflowClientStatistic({ autoflow: data.autoflow._id }));
        dispatch({ type: TypesAutoFlow.AUTOFLOW_SUCCESS_RESUME, payload: { data: autoflow } });
        dispatch(changeAutoflowClientQueryParam({ page: 1 }));
        dispatch(getClientList());
      }),
    );
  };
};

export const removeAutoflowIntervalWeek = data => {
  return Request.post({ url: '/api/autoflow-interval/remove-week', data }, false, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_INTERVAL_REMOVE_WEEK, payload: { data: response.data.data } });
    dispatch(updateAutoflowIntervalWeekView(response.data.data));
  });
};

export const addAutoflowIntervalWeek = data => {
  return Request.post({ url: '/api/autoflow-interval/add-new-week', data }, false, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_INTERVAL_ADD_WEEK, payload: { data: response.data.data } });
    dispatch(updateAutoflowIntervalWeekView(response.data.data));
  });
};

export const saveAutoflowIntervalToProgram = data =>
  Request.post({ url: '/api/autoflow-interval/save-as-program', data }, true);
