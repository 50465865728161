import React from 'react';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { METRIC_UNIT_ERRORS } from 'constants/commonData';
import * as S from './style';

const TIME_FORMAT = 'hh : mm';

export const getTimeFromInput = (e, isEValue = false) => {
  const newVal = isEValue ? e : e.target.value;
  let [hour, minute = 0] = newVal.split(' : ');

  if (Number(minute) === 0) {
    minute = '00';
  } else if (minute && minute.trim().length === 1) {
    minute = '0' + minute;
  }
  return { hour, minute, newValue: newVal };
};

function TimeInput(props) {
  const { onFocus, value, isAM, onChange, setErrors, isError, className = '' } = props;

  const handleChangeTime = e => {
    const { hour, minute, newValue } = getTimeFromInput(e);
    const timeStr = `${hour} : ${minute}`;
    const newTime = DateTime.fromFormat(timeStr, TIME_FORMAT);

    if ((!newTime.invalid || !newValue) && isError) {
      setErrors && setErrors({ time: undefined });
    }

    onChange({ time: newValue });
  };

  const handleBlurTime = e => {
    if (e.target.value.length === 0) return;

    const { hour, minute } = getTimeFromInput(e);

    const timeStr = `${hour} : ${minute}`;
    const newTime = DateTime.fromFormat(timeStr, TIME_FORMAT);

    if (newTime.invalid) {
      setErrors && setErrors({ time: METRIC_UNIT_ERRORS.TIME });
      return;
    }

    if (newTime.hour >= 13) {
      const hour = newTime.hour - 12;
      const newTimeStr = `${hour > 9 ? '' : '0'}${hour} : ${newTime.minute > 9 ? '' : '0'}${newTime.minute}`;
      onChange({
        time: newTimeStr,
        isAMTime: false,
      });
    } else {
      onChange({ time: timeStr });
    }
  };

  const handleSelectAM = isAMTime => () => {
    onChange({ isAMTime });
  };

  return (
    <S.TimeInputWrap className={classNames('time-input', className, { 'wrap-time-error': !!isError })}>
      <Cleave
        placeholder="HH : MM"
        value={value}
        options={{ time: true, timePattern: ['h', 'm'], delimiter: ' : ' }}
        onChange={handleChangeTime}
        onBlur={handleBlurTime}
        onFocus={onFocus}
        className="time-cleave"
      />
      <span className={classNames('time-ap time-am', { selected: isAM })} onClick={handleSelectAM(true)}>
        AM
      </span>
      <span className={classNames('time-ap time-pm', { selected: !isAM })} onClick={handleSelectAM(false)}>
        PM
      </span>
    </S.TimeInputWrap>
  );
}

export default TimeInput;
