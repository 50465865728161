/**
 * @flow
 */

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { State, Dispatch } from 'types/redux';
import GeneralSettings from './component';
import { editTeam } from 'actions/client';
import { toggleSideBar } from 'actions/sidebar';

const mapStateToProps = (state: State) => {
  const { user } = state;
  let team;
  if (user.teams && user.teams.length > 0) {
    team = user.teams[0].team;
  } else {
    team = {
      name: 'Personal Workspace',
      billingEmail: user.email,
      timezone: user.timezone,
    };
  }

  return {
    user,
    team,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    editTeam: data => {
      return dispatch(editTeam(data));
    },
    toggleSideBar: (visible: boolean) => dispatch(toggleSideBar(visible)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralSettings));
