import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getExerciseLibrary, selectedExercise, resetSelectedExercise } from 'redux/exercise/exercise.actionCreators';
import { toggleSideBar } from 'actions/sidebar';
import { toggleModal } from 'actions/modal';
import ExerciseLibrary from './component';

const mapState = state => {
  const {
    rootReducer: {
      exercise: { listExercise, loading, selectedExercise },
    },
  } = state;

  return { length: listExercise.length, loading, listExercise, selectedExercise };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getExerciseLibrary: bindActionCreators(getExerciseLibrary, dispatch),
    toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
    getExerciseDetail: bindActionCreators(selectedExercise, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resetSelectedExercise: bindActionCreators(resetSelectedExercise, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(ExerciseLibrary);
