import React from 'react';
import { Table } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import { convertUnit, roundUp, roundNumber, convertAutoFillFields } from 'utils/commonFunction';
import { formatDuration } from 'helpers/time';
import SetOneRepMax from './SetOneRepMax';

import { ReactComponent as CupIcon } from 'assets/icons/NormalMetrics/cup.svg';

import './styles.scss';

// type Props = {
//   timezone: string,
//   name: String,
//   clientId: String,
//   units: Array,
//   fields: Array,
//   getExerciseHistoryEntries: Function,
// };

export default class ExerciseHistoryRecord extends React.Component {
  state = {
    dataRecord: [],
  };

  componentDidMount() {
    this.props
      .getExerciseHistoryEntries({
        client: this.props.clientId,
        exercise: this.props.exerciseId,
      })
      .then(data => {
        if (data && data.data && data.data.data) {
          this.setState({ dataRecord: data.data.data });
        }
      });
  }

  render() {
    const { unit } = this.props;
    let results = Object.assign([], this.state.dataRecord);

    if (!results) {
      return null;
    }

    results = _.orderBy(
      results,
      e => {
        return e.link ? e.link.date : '';
      },
      'desc',
    );
    const setting = !!unit && unit.weight;

    let unitTitle = setting ? setting.title : this.props.unit.weight.title;

    let maxTrainingSets = 0;

    for (let i = 0; i < results.length; i++) {
      if (maxTrainingSets < results[i].exercise_instance.fields.length) {
        maxTrainingSets = results[i].exercise_instance.fields.length;
      }
    }

    let have_1rm = false;
    let unitSetting = null;
    if (results.length > 0) {
      unitSetting = results[0].training_sets[0]['weight'];
      have_1rm = _.some(results, rc => rc.estimate_1rm);
    }
    return (
      <div className="exercise-history">
        <div className="exercise-history__header">
          <div className="panel-title">History</div>
          {have_1rm ? (
            <div className="panel-sub-title">
              <SetOneRepMax
                exerciseId={this.props.exerciseId}
                client={this.props.clientId}
                unitTitle={unitTitle}
                exercise={this.props.exercise}
                defaultSetting={unitSetting}
                setting={setting}
              />
              <div className="panel-1rm-est">1RM ESTIMATION</div>
            </div>
          ) : null}
        </div>
        {_.map(results, (item, index) => {
          if (!item.link || !item.training_sets || item.training_sets.length === 0) {
            return null;
          }

          let item_date = item.date;

          if (item.link && item.link.end_date) {
            item_date = item.link.end_date;
          }

          let date = DateTime.fromISO(item_date);

          let isSameYear = DateTime.now().year === date.year;

          const defaultSetting = item.training_sets[0]['weight'];

          return (
            <div className="eve-panel body-metric-progress-container exercise-hisrtory-record-container" key={index}>
              <div className="panel-header">
                <div className="date-name-wrapper">
                  <div className="date">{isSameYear ? date.toFormat('MMM d') : date.toFormat('MMM d, yyyy')}</div>
                  <div className="name">{item.link.title}</div>
                </div>
                <div className="value">
                  {item.estimate_1rm ? (
                    <>
                      {roundUp(convertUnit(item.estimate_1rm, defaultSetting.set_unit, setting), 2)} {unitTitle}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="panel-body">{this.renderTrainingSets(item, index, maxTrainingSets)}</div>
            </div>
          );
        })}
      </div>
    );
  }

  handleConvert = (data, uniqueCode) => {
    const { unit } = this.props;
    const shouldConvert = unit[uniqueCode];

    if (!_.get(data, 'value')) {
      return 0;
    }

    return data.value && shouldConvert && data.set_unit
      ? convertUnit(data.value, data.set_unit, shouldConvert)
      : data.value;
  };

  renderValue(exerciseData, item, field) {
    let value = '';
    const inputtedUnit = _.get(item, `${field.unique_code}.input_unit`);
    const inputtedValue = inputtedUnit
      ? _.get(item, `${field.unique_code}.input_value`)
      : _.get(item, `${field.unique_code}.value`, 0);

    let field_value = inputtedUnit
      ? inputtedValue
      : this.handleConvert(_.get(item, `${field.unique_code}`), field.unique_code);

    switch (field.data_type) {
      case 'float':
        value = roundNumber(field_value);
        break;
      case 'int':
        value = isNaN(parseInt(field_value)) ? '' : parseInt(field_value);
        break;
      case 'time':
        value = formatDuration(field_value);
        break;
      default:
        value = field_value;
        break;
    }
    //
    // if (exerciseData.exercise.category_type_name === 'Strength') {
    //   value = roundUp(field_value, 1);
    // } else if (exerciseData.exercise.category_type_name === 'Timed') {
    //   value = convertSecondToTimeString(field_value);
    // } else if (exerciseData.exercise.category_type_name === 'Distance (Long)') {
    //
    //   value = roundUp(field_value, 1);
    // } else {
    //   value = roundUp(field_value, 1);
    // }

    return <span>{value}</span>;
  }

  renderUnit() {
    return null;
  }

  renderTrainingSets = (exerciseData, entryIndex, maxTrainingSets) => {
    const { unit, fields: allFields } = this.props;
    const fields = exerciseData.exercise_instance.fields;
    const training_sets = exerciseData.training_sets;
    const data = [];
    const remainCell = [];
    const fieldsObject = [];

    fields.forEach(fId => {
      const field = allFields.find(item => item._id === fId);
      if (field && field.unique_code !== 'rest') {
        fieldsObject.push(field);
      }
    });

    _.forEach(training_sets, (item, index) => {
      for (let i = 0; i < maxTrainingSets - fieldsObject.length - 1; i++) {
        remainCell.push(<Table.Cell />);
      }
      data.push(
        <Table.Row key={'set' + index}>
          <Table.Cell className="set-item">
            {index + 1 < 10 ? '0' : ''}
            {index + 1}
          </Table.Cell>
          {fieldsObject.map(field => {
            return (
              <Table.Cell className="set-item set-item-result" key={field._id}>
                {this.renderValue(exerciseData, item, field)}
              </Table.Cell>
            );
          })}
          <Table.Cell className="set-item set-item-cup">{this.renderRecords(item.records)}</Table.Cell>
        </Table.Row>,
      );
    });
    return (
      <Table singleLine className="" key={exerciseData._id}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="set-title">Set</Table.HeaderCell>
            {fieldsObject.map(field => {
              const firstData = training_sets[0] || {};
              const { input_unit, set_unit } = firstData[field.unique_code] || {};
              const setting = input_unit || unit[field.unique_code] || set_unit;

              return (
                <Table.HeaderCell className="set-title" key={field._id}>
                  {field.title.replace(' (short)', '')}
                  {setting ? ` (${setting.title})` : ''}
                </Table.HeaderCell>
              );
            })}
            <Table.HeaderCell className="set-title" />
          </Table.Row>
        </Table.Header>
        <Table.Body>{data}</Table.Body>
      </Table>
    );
  };

  renderRecords(records) {
    if (records) {
      const data = (records || []).map(record => <span key={record.type}>{convertAutoFillFields(record.type)}</span>);
      if (records.length === 1) {
        return (
          <div className="set-item__footer">
            <CupIcon className="cup-icon" />
            &nbsp;{data}
          </div>
        );
      }

      if (records.length > 1) {
        return (
          <div className="set-item__footer">
            <CupIcon className="cup-icon" />
            &nbsp;
            <span>
              <ReactTooltip id="cup-tooltip" effect="solid" place={'top'} delayShow={100}>
                <ol className="cup-tooltip-list">
                  {_.map(records, record => (
                    <li key={record.type}>{record.type}</li>
                  ))}
                </ol>
              </ReactTooltip>
              <span data-tip="tooltip" data-for="cup-tooltip">
                x&nbsp;{records.length}
              </span>
            </span>
          </div>
        );
      }
    }
    return null;
  }

  renderMetricUnit(item) {
    if (item.unit) {
      return item.unit.title;
    } else if (item.type && item.type.category && item.type.category.default_unit) {
      return item.type.category.default_unit.title;
    }
  }
}
