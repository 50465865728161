import Request from 'configs/request';

export const Types = {
  TASK_LIBRARY_FETCH_LIST_TASK: 'TASK_LIBRARY_FETCH_LIST_TASK',
};

export const fetchListTaskLibrary = () => {
  return Request.get({ url: '/api/task/v2/get-task-library' }, false, ({ data }, { dispatch }) => {
    dispatch({ type: Types.TASK_LIBRARY_FETCH_LIST_TASK, payload: data });
  });
};
