import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border: 1.5px dashed #7470ef;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #3b4859;

  img {
    margin-bottom: 15px;
  }

  ${props => props.show && css`
    display: flex;
  `}
`;