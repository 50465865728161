import React from 'react';
import styled from 'styled-components';
// import assets
import PrefixImg from 'assets/images/forum-search-input-prefix.png';

const Container = styled.div`
  display: flex;
  align-items: center;

  &.isDisabled {
    background-color: #fff !important;
  }

  &.multi-select__option {
    .forum-select-prefix {
      width: 45px;
      height: 45px;
      border-radius: 5px;
    }
    border-radius: 5px;
    padding: 10px;
    padding-left: 15px;

    &:hover {
      background: #f7f7ff;
    }
  }

  .multi-select__option__label {
    flex: 1 1;
    padding: 0 10px;
    .name {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #202353;
    }
    .no_of_members {
      font-size: 12px;
      line-height: 16px;
      color: #202353;
    }
  }

  .title {
    font-weight: 600;
    font-size: 11px;
    line-height: 120%;
    text-transform: uppercase;
    color: #202353;
  }
`;

const LabelStyle = styled.div`
  flex: 1 1;
  padding: 0 15px;
  .name {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #202353;
  }
  .no_of_members {
    font-size: 12px;
    line-height: 16px;
    color: #202353;
  }
`;

export const OptionLabel = ({ data }) => (
  <LabelStyle>
    <div className="name">{data.name}</div>
    <div className="no_of_members">
      <b>{data.no_of_members}</b> {data.no_of_members > 1 ? 'Members' : 'Member'}
    </div>
  </LabelStyle>
);

export default ({ innerProps, children, data, isFocused }) => {
  let className = 'multi-select__option';

  if (isFocused) {
    className += ' multi-select__option--is-focused';
  }

  if (data.isDisabled)
    return (
      <Container {...innerProps} className={`${className} isDisabled`}>
        <div className="title">{data.value}</div>
      </Container>
    );

  return (
    <Container {...innerProps} className={className}>
      {data.banner && <img src={data.banner} alt="" className="forum-select-prefix" />}
      {!data.banner && <img src={PrefixImg} alt="" className="forum-select-prefix" />}
      <OptionLabel data={data} />
    </Container>
  );
};
