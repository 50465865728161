import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ASSET_TYPES, ASSET_TYPE } from 'components/Product/constants';
import CardWithText from 'shared/CardWithText';
import UpgradePath from 'shared/UpgradePath';

import { shouldAllowAddAssetType } from '../../AssetSelect/component';

import * as S from './style';
import { get } from 'lodash';

const AddNewAsset = ({ onSelect, isFirstTime, onCancel, addedAssets, limitResource }) => {
  const handleSelectedAddAsset = type => () => {
    //   TODO - Select a type
    onSelect && onSelect(type);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  if (isFirstTime) {
    return (
      <>
        <S.FirstAddAssetTitle>Add your first asset</S.FirstAddAssetTitle>
        <S.OptionsWrapper>
          {ASSET_TYPES.map(it => {
            return (
              <UpgradePath
                pathName={it.pathName}
                fallback={
                  get(it, 'shouldNotRenderWithoutPermission', false) ? (
                    <></>
                  ) : (
                    <CardWithText
                      className="add-asset-card"
                      icon={it.icon}
                      title={it.label}
                      description={it.desc}
                      shouldUpgrade={true}
                      tooltip={it.upgradePathTooltip}
                      planWillSelect={it.planWillSelect}
                    />
                  )
                }
              >
                <CardWithText
                  className="add-asset-card"
                  icon={it.icon}
                  title={it.label}
                  description={it.desc}
                  shouldUpgrade={false}
                  tooltip={it.tooltip}
                  planWillSelect={it.planWillSelect}
                  onClick={handleSelectedAddAsset(it.key)}
                />
              </UpgradePath>
            );
          })}
        </S.OptionsWrapper>
      </>
    );
  }

  return (
    <S.Wrapper>
      <S.WrapperContainer>
        {ASSET_TYPES.map(it => (
          <>
            <UpgradePath
              pathName={it.pathName}
              fallback={
                <S.Container className="disabled" data-for={`${it.key}-tooltip`} data-tip>
                  <S.IconWrapper bgColor={it.color}>{it.icon}</S.IconWrapper>
                  <S.TitleItem>{it.label}</S.TitleItem>
                </S.Container>
              }
            >
              <S.Container
                shouldRender={shouldAllowAddAssetType(it.value, addedAssets, limitResource)}
                data-for={`${it.key}-tooltip`}
                data-tip
                onClick={handleSelectedAddAsset(it.key)}
              >
                {it.key === ASSET_TYPE.WORKOUT_COLLECTION ? (
                  <S.IconWrapper bgColor={it.color} noIconStyle>
                    {it.secondaryIcon}
                  </S.IconWrapper>
                ) : (
                  <S.IconWrapper bgColor={it.color}>{it.icon}</S.IconWrapper>
                )}
                <S.TitleItem>{it.label}</S.TitleItem>
              </S.Container>
            </UpgradePath>
            <ReactTooltip
              className="app-tooltip-asset"
              id={`${it.key}-tooltip`}
              effect="solid"
              place={'top'}
              delayShow={500}
            >
              <S.TooltipTitle>{it.label}</S.TooltipTitle>
              <S.TooltipDesc>{it.tooltip}</S.TooltipDesc>
            </ReactTooltip>
          </>
        ))}
      </S.WrapperContainer>
      <S.CancelButton onClick={handleCancel}>Cancel</S.CancelButton>
    </S.Wrapper>
  );
};

export default AddNewAsset;
