import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { isWindows } from 'react-device-detect';
import EmojiPicker, { EmojiStyle, Categories, SuggestionMode, Emoji } from 'emoji-picker-react';
import { Button } from 'shared/FormControl';
import { BACKGROUND_LIST } from '../../../constants/habitData';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import * as S from './style';

const CATEGORIES = [
  {
    name: 'Recent',
    category: Categories.SUGGESTED,
  },
  {
    name: 'Smileys & People',
    category: Categories.SMILEYS_PEOPLE,
  },
  {
    name: 'Animals & Nature',
    category: Categories.ANIMALS_NATURE,
  },
  {
    name: 'Food & Drink',
    category: Categories.FOOD_DRINK,
  },
  {
    name: 'Travel & Places',
    category: Categories.TRAVEL_PLACES,
  },
  {
    name: 'Activities',
    category: Categories.ACTIVITIES,
  },
  {
    name: 'Objects',
    category: Categories.OBJECTS,
  },
  {
    name: 'Symbols',
    category: Categories.SYMBOLS,
  },
  {
    name: 'Flags',
    category: Categories.FLAGS,
  },
];

const ChooseIconsPopup = ({ prevEmoji, onUpdate, open }) => {
  const [selectedBackground, setSelectedBackground] = useState(
    BACKGROUND_LIST.find(item => item.border === prevEmoji.color),
  );
  const [expandBackground, setExpandBackground] = useState(true);
  const [expandIcon, setExpandIcon] = useState(true);
  const [selectedEmoji, setSelectedEmoji] = useState(prevEmoji.icon);
  const [selectedUnified, setSelectedUnified] = useState(prevEmoji.icon_unified);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    setSelectedBackground(BACKGROUND_LIST.find(item => item.border === prevEmoji.color));
    setSelectedEmoji(prevEmoji.icon);
    setSelectedUnified(prevEmoji.icon_unified);
  }, [open]);

  useEffect(() => {
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    setTimeout(() => {
      const targetNode = document.getElementsByClassName('epr-emoji-list')[0];
      if (targetNode) {
        observer.observe(targetNode, config);
      }
    }, 500);

    return () => {
      observer.disconnect();
    };
  }, []);

  const callback = (mutationList, observer) => {
    const targetNode = document.getElementsByClassName('epr-emoji-list')[0];
    if (targetNode && targetNode.clientHeight > 0) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
  };

  const onToggleBackground = () => setExpandBackground(!expandBackground);
  const onToggleIcon = () => setExpandIcon(!expandIcon);

  const onClickEmoji = data => {
    setSelectedEmoji(data.emoji);
    setSelectedUnified(data.unified);
  };

  const handleUpdateEmoji = () => {
    onUpdate &&
      onUpdate({
        icon: selectedEmoji,
        icon_unified: selectedUnified,
        color: selectedBackground.border,
      });
  };

  return (
    <S.ChooseIconsPopupWrapper unified={selectedUnified}>
      <div className="icon-content">
        <S.LeftSideWrapper>
          <S.BackgroundWrapper>
            <S.TitleWrapper>
              Background Color
              {expandBackground ? <ArrowDown onClick={onToggleBackground} /> : <ArrowUp onClick={onToggleBackground} />}
            </S.TitleWrapper>
            <S.BackgroundList expand={expandBackground}>
              {BACKGROUND_LIST.map(item => (
                <S.BackgroundSelected
                  key={item.background}
                  active={_.get(selectedBackground, 'background', '#FCF5DE') === item.background}
                  onClick={() => setSelectedBackground(item)}
                >
                  <S.BackgroundItem background={item.background} />
                </S.BackgroundSelected>
              ))}
            </S.BackgroundList>
          </S.BackgroundWrapper>
          <S.IconSection>
            <S.TitleWrapper>
              Icon
              {expandIcon ? <ArrowDown onClick={onToggleIcon} /> : <ArrowUp onClick={onToggleIcon} />}
            </S.TitleWrapper>
          </S.IconSection>
          <S.IconWrapper expand={expandIcon}>
            <EmojiPicker
              onEmojiClick={onClickEmoji}
              autoFocusSearch={false}
              width={379}
              height={expandBackground ? 248 : 310}
              // emojiVersion="0.6"
              lazyLoadEmojis={true}
              previewConfig={{
                showPreview: false,
              }}
              suggestedEmojisMode={SuggestionMode.RECENT}
              skinTonesDisabled
              emojiStyle={isWindows ? EmojiStyle.GOOGLE : EmojiStyle.NATIVE}
              categories={CATEGORIES}
              className="custom-emoji-picker"
            />
            {empty && expandIcon && <S.EmptyMessage>No results found</S.EmptyMessage>}
          </S.IconWrapper>
        </S.LeftSideWrapper>
        <S.RightSideWrapper>
          <S.PreviewWrapper border={_.get(selectedBackground, 'border', '#FBB503')}>
            <S.PreviewBackground background={_.get(selectedBackground, 'background', '#FCF5DE')}>
              <Emoji unified={selectedUnified} size="32" />
            </S.PreviewBackground>
          </S.PreviewWrapper>
        </S.RightSideWrapper>
      </div>
      <div className="modal__actions">
        <Button purple className="actions__update" onClick={handleUpdateEmoji}>
          Update
        </Button>
      </div>
    </S.ChooseIconsPopupWrapper>
  );
};

export default ChooseIconsPopup;
