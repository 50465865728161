import React from 'react';
import { PaymentFailWarningWrapper, TextUppercase } from './style';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isTeamAdmin } from '../../../../../utils/commonFunction';

function PaymentOverdueWarning(props) {
  if (
    !(props.payment_base_over_due || props.payment_zapier_over_due || props.payment_addons_over_due) ||
    !isTeamAdmin(props.user)
  ) {
    return null;
  }
  return (
    <PaymentFailWarningWrapper>
      Your payment is <TextUppercase>overdue</TextUppercase>. Please&nbsp;
      <Link className="paymentFailWarning__link" to="/home/billing">
        update your credit card
      </Link>
      &nbsp;to prevent any interruption to your client's training!
    </PaymentFailWarningWrapper>
  );
}

const mapStateToProps = state => ({
  payment_base_over_due: state.rootReducer.pricing.getIn(['teamData', 'payment_base_over_due']) || false,
  payment_addons_over_due: state.rootReducer.pricing.getIn(['teamData', 'payment_addons_over_due']) || false,
  payment_zapier_over_due: state.rootReducer.pricing.getIn(['teamData', 'zapier_meter_addons_overdue']) || false,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOverdueWarning);
