import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientGroup from './component';
import { toggleModal } from 'actions/modal';
import { autocomplete, assignClients, getGroups, createGroup } from 'redux/group/actions';

const mapStateToProps = state => ({
  errorPopup: state.errorPopup,
  isModalOpen: state.isModalOpen,
  groups: state.rootReducer.group.list,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  autocomplete: bindActionCreators(autocomplete, dispatch),
  assignClients: bindActionCreators(assignClients, dispatch),
  getGroups: bindActionCreators(getGroups, dispatch),
  createGroup: bindActionCreators(createGroup, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientGroup);
