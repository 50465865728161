// Libs
import React, { useState, useEffect } from 'react';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import isObject from 'lodash/isObject';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as CloseButton } from 'semantic-ui-react';
import { toast } from 'react-toastify';

// Actions
import {
  getListCategoryIngredient,
  getListIngredient,
  updateFilterIngredient,
  resetFiltersIngredient,
  updateDataDetailRecipe,
  updateDataIngredient,
} from 'redux/recipes/actions';

import { addSingleIngredient, addMultiIngredient } from 'redux/ingredient-library/actions';

// Helper
import { getFirstItemUnit, getUniqueUnitsFromCategoryList, validAllValueStringIngredient } from '../helper';
import { convertUnitForAutoCalculated } from 'components/IngredientLibrary/helpers';

// Components
import FilterCategory from './components/FilterCategory';
import ListIngredient from './components/ListIngredient';
import ListIngredientSelected from './components/ListIngredientSelected';
import AddSingleIngredientModal from 'components/IngredientLibrary/Parts/AddSingleIngredientModal';
import FilterCategoriesPopup from './components/FilterCategoriesPopup';

import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/add_tag_icon.svg';

import * as S from './style';

const ENUM_INGREDIENT_TAB = {
  ALL: 'all',
  CUSTOM: 'custom',
};

const ingredientTab = [
  { label: 'All', value: ENUM_INGREDIENT_TAB.ALL },
  { label: 'Custom', value: ENUM_INGREDIENT_TAB.CUSTOM },
];

const AddIngredient = props => {
  const {
    toggleModal,
    getListIngredient,
    ingredient,
    updateFilterIngredient,
    resetFiltersIngredient,
    updateDataDetailRecipe,
    listIngredientInRecipe = [],
    errors,
    toggleSecondModal,
    callbackAddIngredientSuccess = () => {},
    addSingleIngredient,
    addMultiIngredient,
    updateDataIngredient,
    listUnitIngredient = {},
    autoCalculated = false,
  } = props;

  const [listSelected, setListSelected] = useState(listIngredientInRecipe);
  const [tabActive, setTabActive] = useState(ingredientTab[0].value);

  const isSearch = get(ingredient, 'filters.text_search');

  useEffect(() => {
    return () => {
      resetFiltersIngredient && resetFiltersIngredient();
    };
  }, []);

  useEffect(() => {
    getListIngredient && getListIngredient(get(ingredient, 'filters', {}));
  }, [get(ingredient, 'filters', {})]);

  const handleChooseDietary = data => {
    const checked = listSelected.some(item => get(item, 'ingredient', '') === get(data, 'ingredient', ''));
    let updatedList = [];
    if (checked) {
      updatedList = [...listSelected].filter(item => get(item, 'ingredient', '') !== get(data, 'ingredient', ''));
    } else {
      const { _id: ingredientId } = data || {};
      const currentIngredient = (listIngredientInRecipe || []).find(item => item._id === ingredientId);
      const valueIngredient = get(currentIngredient, 'value', '');
      updatedList = [...listSelected, valueIngredient ? { ...data, value: valueIngredient } : data];
    }
    setListSelected(updatedList);
  };

  const handleRemoveDietary = data => {
    const updatedList = [...listSelected].filter(item => get(item, 'ingredient', '') !== get(data, 'ingredient', ''));
    setListSelected(updatedList);
  };

  const handleSearchText = (e, { value }) => {
    const filterParams = {
      text_search: value,
      page: 1,
    };

    if (tabActive === ENUM_INGREDIENT_TAB.CUSTOM) {
      filterParams.type = undefined;
      setTabActive(ENUM_INGREDIENT_TAB.ALL);
    }

    updateFilterIngredient && updateFilterIngredient(filterParams);
  };

  const handleChooseCategory = id => {
    if (id) {
      updateFilterIngredient && updateFilterIngredient({ categories: [id], page: 1 });
    } else {
      updateFilterIngredient && updateFilterIngredient({ categories: null, page: 1 });
    }
  };

  const handleAddIngredient = () => {
    if (listSelected.length <= 0) return;

    const check = validAllValueStringIngredient(listSelected);

    const updatedError = !check ? { ...errors, ingredients: true } : omit(errors, ['ingredients']);

    const ingredients = listSelected.map(item => {
      const { ingredient, _id, default_unit = {} } = item || {};

      const checkExistIngredient = listIngredientInRecipe.find(
        item => get(item, '_id', '') === _id || get(item, 'ingredient', '') === ingredient,
      );

      if (isObject(checkExistIngredient)) {
        return checkExistIngredient;
      }

      return { ...item, unit: default_unit, isAddNew: true };
    });

    const dataIngredientAutoCalculated = ingredients.map(item => {
      const { categories = [], unit = {} } = item || {};
      const allowUnit = getUniqueUnitsFromCategoryList(categories, 'allow_units');
      const allowUnitConvert = (allowUnit || []).map(item => (item || {}).unit_id);
      const listUnitAutoCalculated = convertUnitForAutoCalculated(listUnitIngredient, allowUnitConvert);
      const idUnit = (unit || {})._id;
      const checkExistUnit = allowUnitConvert.includes(idUnit);
      if (checkExistUnit) {
        return { ...item, listUnitAutoCalculated };
      }
      const firstItemUnit = getFirstItemUnit(listUnitAutoCalculated, get(allowUnit, '[0].unit_id', ''));
      return { ...item, listUnitAutoCalculated, value_as_text: '', unit: firstItemUnit };
    });

    updateDataDetailRecipe &&
      updateDataDetailRecipe({
        ingredients: autoCalculated ? dataIngredientAutoCalculated : ingredients,
        error: updatedError,
        ingredient_text: '',
      });
    toggleModal && toggleModal(false);
    callbackAddIngredientSuccess();
  };

  const handleClearSearch = () => {
    updateFilterIngredient && updateFilterIngredient({ text_search: '', page: 1 });
  };

  const handleScrollList = () => {
    updateFilterIngredient && updateFilterIngredient({ page: get(ingredient, 'filters.page', 0) + 1 });
  };

  const handleOpenNewIngredient = (isSuggestName = true) => {
    toggleSecondModal(
      true,
      <AddSingleIngredientModal
        isSecondModal
        initData={{ name: isSuggestName ? (isSearch || '').trim().slice(0, 50) : '' }}
        optionsCategory={ingredient.category || []}
        onSubmitForm={handleAddSingleIngredient}
        addMultipleIngredient={handleAddMultiIngredient}
      />,
    );
  };

  const handleAddSingleIngredient = data => {
    if (typeof addSingleIngredient === 'function') {
      addSingleIngredient(data, false).then(res => {
        toast('1 ingredient has been added.');
        toggleSecondModal(false);

        const data = get(res, 'data.data', {});
        const dataConvert = { ...data, ingredient: get(data, '_id', '') };

        const list = uniqBy([dataConvert, ...ingredient.list], '_id');
        const total = ingredient.total + 1;
        typeof updateDataIngredient === 'function' && updateDataIngredient({ list, total });
      });
    }
  };

  const handleAddMultiIngredient = data => {
    if (typeof addMultiIngredient === 'function') {
      addMultiIngredient(data, false).then(res => {
        toggleSecondModal(false);

        const data = get(res, 'data.data', {});

        const dataConvert = data.map(item => ({ ...item, ingredient: get(item, '_id', '') }));

        const list = uniqBy([...dataConvert, ...ingredient.list], '_id');
        const total = ingredient.total + 1;
        typeof updateDataIngredient === 'function' && updateDataIngredient({ list, total });
      });
    }
  };

  const handleSelectedFilterApply = filters => {
    const ids = get(filters, 'categories', []);

    updateFilterIngredient && updateFilterIngredient({ categories: ids, page: 1 });
  };

  const appliedFilterCategories = {
    categories: get(ingredient, 'filters.categories', []) || [],
  };

  const handleChangeTab = value => {
    if (value === tabActive || get(ingredient, 'loadingIngredient', false)) return;

    setTabActive(value);
    updateFilterIngredient &&
      updateFilterIngredient({ type: value !== ENUM_INGREDIENT_TAB.ALL ? value : undefined, page: 1 });
  };

  const renderTab = () => {
    return (
      <S.Tab>
        {ingredientTab.map(item => {
          const { label, value } = item || {};
          return (
            <S.TabItem active={value === tabActive} onClick={() => handleChangeTab(value)}>
              {label}
            </S.TabItem>
          );
        })}
      </S.Tab>
    );
  };

  return (
    <S.AddIngredientWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={() => {
        toggleModal && toggleModal(false);
      }}
      closeIcon={
        <CloseButton className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </CloseButton>
      }
    >
      <S.Header>
        <S.Title>Add Ingredients</S.Title>
        <S.SearchWrapper>
          <S.InputSearch
            placeholder="Search ingredient name"
            onChange={debounce(handleSearchText, 300)}
            onClearSearch={handleClearSearch}
          />
          <S.FilterWrapper>
            <FilterCategoriesPopup
              onSelectedFilterApply={handleSelectedFilterApply}
              appliedFilter={appliedFilterCategories}
              allCategory={get(ingredient, 'category', [])}
            />
          </S.FilterWrapper>
        </S.SearchWrapper>
        <FilterCategory
          categoryIngredient={get(ingredient, 'category', [])}
          handleChoose={handleChooseCategory}
          selectedCategories={appliedFilterCategories.categories}
        />
      </S.Header>
      <S.Content>
        <S.ListIngredient>
          {!isSearch && renderTab()}
          {get(ingredient, 'loadingIngredient', false) && get(ingredient, 'filters.page', 0) === 1 ? (
            <S.LoadingIndicatorWrapper title="Loading..." />
          ) : (
            <ListIngredient
              handleChooseDietary={handleChooseDietary}
              listSelected={listSelected}
              ingredient={get(ingredient, 'list', [])}
              total={get(ingredient, 'total', 0)}
              isSearch={isSearch}
              loadingLoadMore={get(ingredient, 'loadingIngredient', false) && get(ingredient, 'filters.page', 0) !== 1}
              handleScrollList={handleScrollList}
              handleOpenNewIngredient={handleOpenNewIngredient}
              tabActive={tabActive}
              filters={get(ingredient, 'filters', 0)}
            />
          )}
        </S.ListIngredient>
        {get(listSelected, 'length', 0) > 0 && (
          <S.ListIngredientSelected>
            <ListIngredientSelected handleRemoveDietary={handleRemoveDietary} list={listSelected} />
          </S.ListIngredientSelected>
        )}
      </S.Content>
      <S.Action>
        <S.NewIngredientBtn onClick={() => handleOpenNewIngredient(false)}>
          <PlusIcon />
          New ingredient
        </S.NewIngredientBtn>
        <S.ButtonSave onClick={handleAddIngredient} active={get(listSelected, 'length', 0) > 0}>
          Add
        </S.ButtonSave>
      </S.Action>
    </S.AddIngredientWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes, ingredientLibrary },
  } = state;

  const ingredient = get(recipes, 'ingredient', {});
  const listIngredientInRecipe = get(recipes, 'detailRecipe.ingredients', []);

  const errors = get(recipes, 'detailRecipe.error', {});

  const listUnitIngredient = get(ingredientLibrary, 'listUnitIngredient', {});

  return {
    ingredient,
    listIngredientInRecipe,
    errors,
    listUnitIngredient,
    autoCalculated: get(recipes, 'detailRecipe.is_auto_calculated', false),
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  getListCategoryIngredient: bindActionCreators(getListCategoryIngredient, dispatch),
  getListIngredient: bindActionCreators(getListIngredient, dispatch),
  updateFilterIngredient: bindActionCreators(updateFilterIngredient, dispatch),
  resetFiltersIngredient: bindActionCreators(resetFiltersIngredient, dispatch),
  updateDataDetailRecipe: bindActionCreators(updateDataDetailRecipe, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  addSingleIngredient: bindActionCreators(addSingleIngredient, dispatch),
  addMultiIngredient: bindActionCreators(addMultiIngredient, dispatch),
  updateDataIngredient: bindActionCreators(updateDataIngredient, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIngredient);
