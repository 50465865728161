import styled from 'styled-components';

export const CommentsContainer = styled.div`
  width: 400px;
  background-color: #fff;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 528px;
  max-height: calc(100vh - 100px);
`;
