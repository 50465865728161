import React from 'react';
import Avatar from 'react-avatar';
import get from 'lodash/get';
import { Button, Image, Modal } from 'semantic-ui-react';
import { getUserShortName } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

import './styles.scss';

const ViewMessagePopup = ({ title, onClose, clientInfo }) => {
  const clientFullName = get(clientInfo, 'first_name', '') + ' ' + get(clientInfo, 'last_name', '');
  const clientEmail = get(clientInfo, 'email', '');
  const clientMessage = get(clientInfo, 'message', '');
  return (
    <Modal open={true} onClose={onClose} closeOnDimmerClick={false} className="view-message-popup-wrapper">
      <Modal.Header>
        <Button className="close-button" onClick={onClose}>
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <span>{title}</span>
      </Modal.Header>
      <Modal.Content>
        <div className="header-content">
          <div className="avatar">
            <Avatar
              name={getUserShortName(clientInfo)}
              size="35"
              src={get(clientInfo, 'avatar.s', '')}
              color={get(clientInfo, 'color')}
            />
          </div>
          <div className="info">
            <p className="info-name">{clientFullName}</p>
            <p className="info-email">{clientEmail}</p>
            <p className="info-content">{clientMessage.trim()}</p>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>OK</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ViewMessagePopup;
