import React from 'react';
import Avatar from 'react-avatar';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

const ItemMember = props => {
  const { user, cloudfrontList, className = '' } = props;

  const name = getUserShortName(user);
  const fullName = user.full_name || `${user.first_name || ''} ${user.last_name || ''}`.trim();
  const avatar = convertS3UrlToCloudFrontUrl(user.avatar || '', cloudfrontList, true);
  const color = user.color || '';

  return (
    <div className="member-item-wrapper">
      <Avatar name={name} className={className} size={40} src={avatar} color={color} />
      <div className="member-name">{fullName}</div>
    </div>
  );
};

export default ItemMember;
