import React from 'react';
import get from 'lodash/get';
import { components } from 'react-select';

import { pluralize } from 'utils/commonFunction';
import { SurveyInformationOptionWrapper } from '../styles';

function CustomOption(props) {
  const surveyName = get(props, 'data.survey_name', '');
  const authorName = get(props, 'data.author_name', '');
  const totalQuestions = get(props, 'data.questions.length', 0);

  return (
    <components.Option {...props}>
      <div className="option__wrapper">
        <h6 className="option__wrapper-name">{surveyName}</h6>
        <SurveyInformationOptionWrapper>
          <span className="option__wrapper-question">
            <b>{totalQuestions}</b> {pluralize('Question', totalQuestions)}
          </span>
          <div className="dot" />
          <span className="author-name">{authorName}</span>
        </SurveyInformationOptionWrapper>
      </div>
    </components.Option>
  );
}

export default CustomOption;
