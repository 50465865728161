// Libs
import React, { useState, useEffect } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// Assets
import { ReactComponent as CheckIcon } from 'assets/icons/MealPlans/check_blue.svg';
import { ReactComponent as CompareMetrics } from 'assets/icons/NormalMetrics/action_compare_metrics.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

// Styles
import * as S from './style';

const ComparisonButton = ({ options = [], toggleComparisonMode, bodyMetric, isLoadingMetric = false }) => {
  const { bodyMetricCompare } = bodyMetric || {};

  const [listMetrics, setListMetrics] = useState(options);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  useEffect(() => {
    setListMetrics(options);
  }, [openDropdown]);

  const handleOpenDropdown = () => {
    if (isLoadingMetric) return;
    setOpenDropdown(true);
  };

  const handleClosePopUp = () => {
    setOpenDropdown(false);
  };

  const handleSelected = data => {
    typeof toggleComparisonMode === 'function' && toggleComparisonMode(true, data);
    setOpenDropdown(false);
  };

  const handleSearchText = (_, { value }) => {
    setTextSearch(value);

    const result = options.filter(({ name = '' }) => name.toLowerCase().includes(value.toLowerCase()));

    setListMetrics(result);
  };

  const handleClearSearch = () => {
    setTextSearch('');
    setListMetrics(options);
  };

  const handleResetCompare = e => {
    e.stopPropagation();
    typeof toggleComparisonMode === 'function' && toggleComparisonMode(false, {});
  };

  return (
    <S.ComparisonButtonWrapper>
      <S.TriggerWrapper
        data-tip
        data-for="tooltip-comparison-metric"
        isSelected={!isEmpty(bodyMetricCompare)}
        isOpen={openDropdown}
        onClick={handleOpenDropdown}
        disabled={isLoadingMetric}
      >
        {!isEmpty(bodyMetricCompare) ? (
          <S.ButtonContent>
            <S.ButtonTitle>
              Compare to:{' '}
              {!isEmpty(bodyMetricCompare) && bodyMetricCompare.name.length > 15
                ? bodyMetricCompare.name.slice(0, 15) + '...'
                : bodyMetricCompare.name}
            </S.ButtonTitle>
            <CloseIcon className="btn-close-icon" onClick={e => handleResetCompare(e)} />
          </S.ButtonContent>
        ) : (
          <CompareMetrics className="btn-comparison-icon" />
        )}
        <ReactTooltip
          className="app-tooltip comparison-metric"
          id="tooltip-comparison-metric"
          place="bottom"
          effect="solid"
        >
          <div>Compare with another Metric</div>
        </ReactTooltip>
      </S.TriggerWrapper>
      {openDropdown && (
        <RootCloseWrapper onRootClose={handleClosePopUp}>
          <S.ListWrapper>
            <S.ListContent>
              <S.InputSearch
                placeholder="Search Metric"
                onChange={debounce(handleSearchText, 300)}
                onClearSearch={handleClearSearch}
              />
              <S.ListHint>
                <span>choose metric to compare</span>
                <span className="desc-hint">The data point setting will align with the current metric</span>
              </S.ListHint>
              <S.List isEmpty={textSearch && get(listMetrics, 'length', 0) <= 0}>
                {textSearch && get(listMetrics, 'length', 0) <= 0 ? (
                  <span className="no-result">No results found.</span>
                ) : (
                  <>
                    {listMetrics.map(item => {
                      const { _id = '', name = '' } = item;
                      const active = !isEmpty(bodyMetricCompare) && bodyMetricCompare._id === _id;
                      return (
                        <S.Item active={active} onClick={() => handleSelected(item)} key={_id}>
                          {name}
                          {active && <CheckIcon />}
                        </S.Item>
                      );
                    })}
                  </>
                )}
              </S.List>
            </S.ListContent>
          </S.ListWrapper>
        </RootCloseWrapper>
      )}
    </S.ComparisonButtonWrapper>
  );
};

export default ComparisonButton;
