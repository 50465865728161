import React from 'react';
import { Header, Image, Icon } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import {
  setTableColumns,
  getTableColumns,
  isDefaultFilters,
  inWorkspace,
  getUserShortName,
  encryptData,
  pluralize,
  convertS3UrlToCloudFrontUrl,
  checkingValidUrl,
  checkingUrlStatusCode,
  getCookie,
  genTokenFromMarketplace,
} from 'utils/commonFunction';
import * as S from './styles';
import './styles.scss';
import { push } from 'connected-react-router';
import AddButton from 'shared/AddButton';
import SearchInput from 'shared/SearchInput';
import Avatar from 'react-avatar';
import _ from 'lodash';
import { Columns } from './constants';
import TableColumns from 'shared/TableColumns';
import { Checkbox, Button } from 'shared/FormControl';
import ClientGroup from 'components/ClientGroup';
import ArchiveClientModal from 'components/ArchiveClientModal';
import ClientFilters from 'components/ClientFilters';
import { parseClientsData } from './helper';
import AddClientButton from 'components/AddClientButton';
import ConfirmModal from 'shared/ConfirmModal';
import Pagination from 'shared/Pagination';
import OnboardingUserGuide from 'components/OnboardingUserGuide';
import Dropdown, { Option } from 'shared/Dropdown/Basic';
import BulkSendInvitation from 'components/BulkSendInvitation';
import SendInvitationSuccess from 'components/BulkSendInvitation/SendInvitationSuccess';
import { CDN_URL, C_KEY_TOKEN, TABLE_CELL_CLIENT_NAME_WIDTH } from 'constants/commonData';
import UpgradePath from 'shared/UpgradePath';
import LimitClientsFailbackPopup from 'shared/UpgradePath/components/LimitClientsFailbackPopup';
import AssignClientButton from './components/AssignClientButton';
import ClientListLoader from './components/ClientListLoader';
import { Link, Redirect } from 'react-router-dom';
import ApplyCouponPopup from '../ApplyCouponPopup';
import WorkoutCompletionButton from 'shared/WorkoutCompletionButton';
import AvatarWrapper from './components/AvatarWrapper';
import { diff } from 'deep-diff';

export default class ClientPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAscending: true,
      columns: [],
      extendWidth: 0,
      selectedClients: [],
      tableMetadata: {
        scrollWidth: 1099,
        columns: [270, 110],
      },
      isOpenInviteSuccessModal: false,
    };
    this.wrapper = React.createRef();
    this.headerTableRef = React.createRef();
  }

  componentDidMount() {
    const { toggleSideBar, getAllSegments, toggleModal } = this.props;
    this.handleMPAutoLogin();
    toggleSideBar(true);
    this.props.getClientsFullData(true, { page: 1, search: '' });
    getAllSegments();
    setTimeout(this.initColumns, 300);
    localStorage.getItem('hasCoupon') &&
      toggleModal(
        true,
        <ApplyCouponPopup
          search={localStorage.getItem('hasCoupon')}
          onClose={() => {
            toggleModal(false);
            localStorage.removeItem('hasCoupon');
          }}
        />,
      );
  }

  componentDidUpdate(prevProps, prevState) {
    if (diff(this.props, prevProps)) {
      this.handleMPAutoLogin();
    }
    if (prevProps.sideBarVisible !== this.props.sideBarVisible) {
      setTimeout(() => {
        this.setState({ extendWidth: this.calculateExtendWidth(this.state.columns) });
      }, 300);
    }

    if (prevProps.selectedSegment !== this.props.selectedSegment) {
      this.setState({ selectedClients: [] });
    }
  }

  componentWillUnmount() {
    if (localStorage.getItem('hasCoupon')) {
      this.props.toggleModal(false);
      localStorage.removeItem('hasCoupon');
    }
  }

  handleMPAutoLogin = () => {
    const {
      location: { search, pathname },
      user,
      dispatch,
    } = this.props;
    const token = getCookie(C_KEY_TOKEN);
    if (token && search.includes('redirect-platform')) {
      genTokenFromMarketplace({ token, user, pathname, search, dispatch });
      return;
    }
  };

  initColumns = () => {
    const { user } = this.props;
    let columns = Columns.slice();
    let localColumns = getTableColumns(user._id, 'clients');

    if (!localColumns.length) {
      const initLocalColumns = [];

      _.forEach(columns, c => {
        if (!c.defaultHide) {
          c.checked = true;
          initLocalColumns.push(c.key);
        }
      });

      if (!inWorkspace(user)) {
        _.remove(initLocalColumns, columnKey => columnKey === 'trainer_name');
        _.forEach(columns, item => {
          if (item.key === 'trainer_name') {
            item.checked = false;
            return false;
          }
        });
      }

      setTableColumns(user._id, 'clients', initLocalColumns);
    } else {
      _.forEach(columns, c => {
        c.checked = !!c.alwaysShow || localColumns.includes(c.key);
      });
    }

    this.setState({
      columns,
      extendWidth: this.calculateExtendWidth(columns),
    });
  };

  calculateExtendWidth = columns => {
    const containerWidth = this.wrapper.current && this.wrapper.current.clientWidth;
    const renderedColumns = _.filter(columns, c => c.checked);

    if (!containerWidth || !renderedColumns.length) {
      return 0;
    }

    const totalWidth = _.sumBy(renderedColumns, c => c.width);
    const extendWidth =
      containerWidth - 32 > totalWidth ? (containerWidth - 32 - totalWidth) / renderedColumns.length : 0;
    return extendWidth;
  };

  handleSelectColumn = (column, checked) => {
    if (!column.alwaysShow) {
      const { user } = this.props;
      const { columns } = this.state;
      let newColumns = columns.slice();
      _.forEach(newColumns, c => {
        if (c.key === column.key) {
          c.checked = checked;
          return false;
        }
      });
      const extendWidth = this.calculateExtendWidth(newColumns);
      this.setState({
        columns: newColumns,
        extendWidth,
      });
      setTableColumns(
        user._id,
        'clients',
        _.map(
          newColumns.filter(c => c.checked),
          c => c.key,
        ),
      );
    }
  };

  getTableMetadata = () => {
    if (this.headerTableRef && this.headerTableRef.current) {
      const columns = _.map(this.headerTableRef.current.children, 'clientWidth');
      const scrollWidth = _.get(this.headerTableRef.current, 'scrollWidth');
      return {
        scrollWidth,
        columns,
      };
    }
    return {
      scrollWidth: 1099,
      columns: [270, 110],
    };
  };

  onSelectClient = (client, checked) => {
    const { selectedClients } = this.state;
    let newList = selectedClients.slice();

    if (client._id === 'all') {
      if (checked) {
        newList = _.unionWith(newList, this.props.clientList, _.isEqual);
      } else {
        newList = newList.filter(item => !this.props.clientList.some(cItem => cItem._id === item._id));
      }
    } else {
      if (checked) {
        newList.push(client);
      } else {
        newList = newList.filter(item => item._id !== client._id);
      }
    }
    this.setState({ selectedClients: newList });
  };

  onInviteSuccess = response => {
    this.props.getClientsFullData(true);
    this.props.getAllSegments();
  };

  handleKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      const { search } = this.props;
      this.setState({ search });
      this.props.getClientsFullData(true, { page: 1 });
    }
  };

  onActionSucess = () => {
    this.props.getAllSegments();
    this.props.getClientsFullData(true, { page: 1 });
    this.setState({ selectedClients: [] });
  };

  onInviteActionSucess = () => {
    this.props.getAllSegments();
    this.props.getClientsFullData(true, { page: 1 });
    this.setState({ selectedClients: [], isOpenInviteSuccessModal: true });
  };

  render() {
    const { page, per_page, total, isPermitDenied, isWaitingSegmentActive } = this.props;
    const { selectedClients } = this.state;

    if (isWaitingSegmentActive) {
      return <Redirect to="/home/client/waiting-activation" />;
    }

    return (
      <div className="client-list-wrapper" ref={this.wrapper}>
        <ClientFilters clients={_.cloneDeep(selectedClients)} />
        {this.renderPageHeader()}
        {this.renderClientsList()}
        <Pagination
          page={page}
          perPage={per_page}
          totalPage={total}
          onPrev={() => this.props.getClientsFullData(true, { page: page - 1 })}
          onNext={() => this.props.getClientsFullData(true, { page: page + 1 })}
        />
        <OnboardingUserGuide />
        {isPermitDenied && (
          <UpgradePath pathName="segment">
            <></>
          </UpgradePath>
        )}
        <SendInvitationSuccess
          isOpen={this.state.isOpenInviteSuccessModal}
          onClose={() => this.setState({ isOpenInviteSuccessModal: false })}
        />
      </div>
    );
  }

  onDeletePermanent = () => {
    const { selectedClients = [] } = this.state;
    const clientsNotSubCoach = selectedClients.filter(item => !item.is_indirect_client);

    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Delete User"
        content={
          <React.Fragment>
            <div>
              {pluralize('client', clientsNotSubCoach.length, true)} will be permanently removed from your account.
            </div>
            <div>Are you sure?</div>
          </React.Fragment>
        }
        onConfirm={() => {
          this.props.deleteClients(_.map(clientsNotSubCoach, '_id')).then(this.onActionSucess);
        }}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        confirmButtonTitle="Delete"
        cancelButtonTitle="Close"
      />,
    );
  };

  onActiveClients = () => {
    const { selectedClients = [] } = this.state;
    const clientsNotSubCoach = selectedClients.filter(item => !item.is_indirect_client);

    const totalClients = _.get(this.props, 'total_active_clients', 0) + clientsNotSubCoach.length;
    const limitClients = this.props.permission.no_clients || 10;
    if (totalClients > limitClients && this.props.permission.no_clients >= 0) {
      this.props.toggleModal(true, <LimitClientsFailbackPopup />);
    } else {
      this.props.activateClients(_.map(clientsNotSubCoach, '_id')).then(this.onActionSucess).catch(this.onActionSucess);
    }
  };

  archivedClientActions = () => {
    const { selectedClients } = this.state;
    const clientsNotSubCoach = selectedClients.filter(item => !item.is_indirect_client);

    if (clientsNotSubCoach.length < 1) return null;

    return (
      <Dropdown
        triggerIcon={({ open }) => (
          <Button className={classnames('button--group-actions', { open })}>
            <img src={`${CDN_URL}/images/setting_fill_grey.svg`} width={14} alt="" />
            <span>Actions</span>
            <img src={`${CDN_URL}/images/arrow-down.svg`} alt="" />
          </Button>
        )}
        className="dropdown--client-group-actions"
        type="light"
      >
        <Option onClick={this.onActiveClients}>
          <S.ActionOptionContainer className="clientActionTrigger__container">
            <S.ActionOptionIcon>
              <img src={`${CDN_URL}/images/active_clients.svg`} alt="" />
            </S.ActionOptionIcon>
            <div>Reactivate Client</div>
          </S.ActionOptionContainer>
        </Option>
        <Option onClick={this.onDeletePermanent}>
          <S.ActionOptionContainer className="clientActionTrigger__container">
            <S.ActionOptionIcon>
              <img src={`${CDN_URL}/images/new_delete.svg`} alt="" />
            </S.ActionOptionIcon>
            <div>Delete Permanently</div>
          </S.ActionOptionContainer>
        </Option>
      </Dropdown>
    );
  };

  normalClientActions = () => {
    const { selectedClients } = this.state;

    return (
      <Dropdown
        triggerIcon={({ open }) => (
          <Button className={classnames('button--group-actions', { open })}>
            <img src={`${CDN_URL}/images/setting_fill_grey.svg`} width={14} alt="" />
            <span>Actions</span>
            <img src={`${CDN_URL}/images/arrow-down.svg`} width={7} alt="" />
          </Button>
        )}
        className="dropdown--client-group-actions"
        type="light"
      >
        <Option>
          <BulkSendInvitation
            trigger={
              <S.ActionOptionContainer className="clientActionTrigger__container">
                <S.ActionOptionIcon>
                  <img src={`${CDN_URL}/images/inbox_line_black.svg`} alt="" />
                </S.ActionOptionIcon>
                <div>Send invite</div>
              </S.ActionOptionContainer>
            }
            clients={selectedClients}
            onSuccess={this.onInviteActionSucess}
          />
        </Option>
        <Option>
          <ArchiveClientModal
            clients={selectedClients}
            onSucess={this.onActionSucess}
            trigger={
              <S.ActionOptionContainer className="clientActionTrigger__container">
                <S.ActionOptionIcon>
                  <img src={`${CDN_URL}/images/archive_white.svg`} alt="" />
                </S.ActionOptionIcon>
                <div>Archive client</div>
              </S.ActionOptionContainer>
            }
          />
        </Option>
      </Dropdown>
    );
  };

  renderActionButtons = () => {
    const { all_clients_total, total_except_search, filters } = this.props;
    const { selectedClients } = this.state;

    const hiddenClients = all_clients_total - total_except_search;

    if (!selectedClients.length) {
      if (hiddenClients) {
        return (
          <div className="hidden-clients-message">
            There {hiddenClients > 1 ? 'are' : 'is'}{' '}
            <span>
              {hiddenClients} {hiddenClients > 1 ? 'clients' : 'client'} not visible
            </span>{' '}
            to you
          </div>
        );
      }

      return null;
    }

    return (
      <S.GroupActions>
        {filters.is_archived ? this.archivedClientActions() : this.normalClientActions()}
        {!filters.is_archived ? (
          <ClientGroup
            clients={_.cloneDeep(selectedClients)}
            updateSuccess={() => {
              this.setState({ selectedClients: [] });
              this.props.getClientsFullData(true);
            }}
          />
        ) : null}
        {!filters.is_archived && (
          <AssignClientButton
            selectedClients={selectedClients}
            onAssignClientToSuccess={() => this.setState({ selectedClients: [] })}
          />
        )}
      </S.GroupActions>
    );
  };

  renderPageHeader = () => {
    const { search, searchClients, toggleModal, permission } = this.props;
    const isShowWorkoutAnalytic = _.get(permission, 'workout_analytics', false);
    const { columns } = this.state;

    return (
      <div className="client-page-header">
        <div className="client-page-header__left">
          <SearchInput
            value={search}
            onChange={(evt, data) => searchClients(data.value)}
            onAddOnIconClick={() => {
              searchClients('');
              this.props.getClientsFullData(true, {
                page: 1,
              });
            }}
            placeholder={'Search client'}
            onKeyPress={this.handleKeyPress}
          />
          {search && (
            <AddButton
              title="Search"
              img={`${CDN_URL}/images/search.svg`}
              onClick={() => {
                const { search } = this.props;
                this.setState({ search });
                this.props.getClientsFullData(true, { page: 1 });
              }}
            />
          )}
          {this.renderActionButtons()}
        </div>
        <div className="client-page-header__right">
          {isShowWorkoutAnalytic && <WorkoutCompletionButton toggleModal={toggleModal} />}
          <AddClientButton onInviteSuccess={this.onInviteSuccess} />
          <TableColumns columns={columns} onSelect={this.handleSelectColumn} />
        </div>
      </div>
    );
  };

  handleOpenClientCalendar = clientId => {
    const { dispatch } = this.props;
    if (clientId && dispatch) {
      dispatch({ type: 'SELECT_CLIENT', payload: { client: clientId } });
      dispatch(push(`/home/client/${clientId}/calendar`));
    }
  };

  renderIconsColumn = user => {
    const { dispatch } = this.props;
    const isInboxFeaturing = user.inbox_preferences && user.inbox_preferences.state;

    return (
      <React.Fragment>
        <div className="shot-menu">
          <div
            data-tip
            data-for={`training-${user._id}`}
            onClick={() => {
              this.handleOpenClientCalendar(user._id);
            }}
          />
          {user.client_connection === 1 && isInboxFeaturing ? (
            <div
              className="inbox-shotcut"
              data-tip
              data-for={`inbox-${user._id}`}
              onClick={e => {
                e.stopPropagation();
                dispatch(push(`/home/inbox?profileId=${user._id}&ufn=${encryptData(user.name)}`));
              }}
            />
          ) : (
            <div />
          )}
        </div>
        <ReactTooltip delayShow={1000} className="app-tooltip" id={`training-${user._id}`} effect="solid" place={'top'}>
          <span>Training Calendar</span>
        </ReactTooltip>
        <ReactTooltip delayShow={1000} className="app-tooltip" id={`inbox-${user._id}`} effect="solid" place={'top'}>
          <span>Chat</span>
        </ReactTooltip>
      </React.Fragment>
    );
  };

  renderNameColumn = user => {
    const { dispatch } = this.props;
    const isChecked = this.state.selectedClients.some(item => item._id === user._id);

    return (
      <React.Fragment>
        <div className="client-avatar-container">
          <Checkbox
            checked={isChecked}
            className="select-client-checkbox"
            onChange={event => {
              this.onSelectClient(user, event.target.checked);
            }}
          />
          <AvatarWrapper user={user} classNames="client-avatar" size={26} />
        </div>
        <Link
          className="detail name-client"
          to={`/home/client/${user._id}`}
          onClick={() => {
            dispatch(push(`/home/client/${user._id}`));
          }}
        >
          {user.name}
        </Link>
      </React.Fragment>
    );
  };

  renderColumnContent = (column, user) => {
    const { changeClientFilter, groups } = this.props;

    switch (column.key) {
      case 'name':
        return this.renderNameColumn(user);

      case 'shortcut-icons':
        return this.renderIconsColumn(user);

      case 'client-groups':
        if (!user.groups || !user.groups.length) {
          return null;
        }

        return (
          <div className="client-groups__container">
            {_.map(user.groups, (g, gIndex) => {
              const gId = typeof g === 'object' ? g._id : g;
              const group = _.find(groups, item => item._id === gId);

              return group ? (
                <React.Fragment key={group._id}>
                  <span className="group-name" onClick={() => changeClientFilter('groups', [group._id])}>
                    {group.name}
                  </span>
                </React.Fragment>
              ) : null;
            })}
          </div>
        );

      default:
        return typeof column.mutate === 'function' ? column.mutate(user) : <div>{user[column.fieldData]}</div>;
    }
  };

  renderClientsList = () => {
    const { isGettingClients, isTeamAdmin, selectedSegment, filters, clientList } = this.props;
    const { search } = this.state;
    let className = 'table-wrapper table-wrapper--clients';

    if (!clientList.length && !selectedSegment && !search && isDefaultFilters(filters) && !isGettingClients) {
      return (
        <div className={className}>
          <Header style={{ textAlign: 'center' }} as={'h5'}>
            Add your first client.
          </Header>
        </div>
      );
    }
    let list = parseClientsData(clientList, this.props.user, isTeamAdmin);

    const { columns, extendWidth, selectedClients } = this.state;
    const renderedColumns = _.filter(columns, c => c.checked);

    if (selectedClients.length) {
      className += ' selected';
    }
    const nameWidth = TABLE_CELL_CLIENT_NAME_WIDTH + extendWidth;

    const tableMetadata = this.getTableMetadata();

    return (
      <S.ClientTableWrapper className={className} data-onboarding-guide="user_guide_1" nameWidth={nameWidth}>
        <div className="client-list-table">
          <div ref={this.headerTableRef} className="table__row table-header">
            {this.renderTableHeader(renderedColumns, list)}
          </div>
          {isGettingClients ? (
            <ClientListLoader tableMetadata={tableMetadata} />
          ) : !list.length ? (
            <div className="no-user-found">No users found</div>
          ) : (
            list.map((user, idx) => (
              <div key={idx} className="table__row">
                {_.map(renderedColumns, column => (
                  <div
                    className={`table__cell ${column.className || column.key} ${column.fixed ? 'fixed' : ''} ${
                      column.align ? ' ' + column.align : ''
                    }`}
                    key={`${user._id}-${column.key}`}
                    style={{
                      minWidth: `${column.width + extendWidth}px`,
                      maxWidth: `${column.width + extendWidth}px`,
                    }}
                  >
                    {this.renderColumnContent(column, user)}
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
      </S.ClientTableWrapper>
    );
  };

  renderTableHeader = (renderedColumns, list) => {
    const { extendWidth, selectedClients } = this.state;
    const { sorter } = this.props;
    let isChecked = false;

    if (selectedClients.length > 0 && selectedClients.length >= list.length) {
      isChecked = list.every(v => selectedClients.some(item => item._id === v._id));
    }

    return _.map(renderedColumns, item => (
      <div
        className={classnames('table__cell', item.key, item.align, {
          sort: sorter === item.sort_key,
          fixed: item.fixed,
        })}
        key={item.key}
        style={{
          minWidth: `${item.width + extendWidth}px`,
          maxWidth: `${item.width + extendWidth}px`,
        }}
      >
        {item.key === 'name' ? (
          <Checkbox
            thirdState={!!selectedClients.length && !isChecked}
            checked={isChecked}
            className="select-client-checkbox"
            onChange={event => {
              this.onSelectClient({ _id: 'all' }, event.target.checked);
            }}
          />
        ) : null}
        <div
          className="header__title"
          onClick={() => {
            item.sort_key && this.handleSortClick(item.sort_key);
          }}
        >
          {item.label && item.icon ? <Image src={item.icon} /> : null}
          <span data-tip data-for={item.key}>
            {item.label}
          </span>
          {item.headerDescription && (
            <ReactTooltip id={item.key} className="app-tooltip client-header-tooltip" place="top" effect="solid">
              {item.headerDescription}
            </ReactTooltip>
          )}
          {item.sort_key ? this.renderSorterIndicator(item.sort_key) : null}
        </div>
      </div>
    ));
  };

  handleSortClick = sortKey => {
    const { sort, sorter } = this.props;

    this.props.getClientsFullData(true, {
      sorter: sortKey,
      sort: sortKey === sorter ? sort * -1 : 1,
    });
  };

  renderSorterIndicator(sortKey) {
    const { sort, sorter } = this.props;

    if (sorter === sortKey) {
      return <Icon name={'chevron ' + (sort === -1 ? 'up' : 'down')} />;
    } else {
      return <Icon name={'chevron down'} />;
    }
  }
}
