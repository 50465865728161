export const hasSyncItem = (newQuestions, conditionFn) => {
  return (newQuestions || []).some(conditionFn);
};

export const findSyncItem = (newQuestions, conditionFn) => {
  return (newQuestions || []).findIndex(conditionFn);
};

export const getSyncItem = (newQuestions, index) => {
  return newQuestions[index] || {};
};

export const checkSyncConditions = (newQuestions, conditionFn) => {
  const hasSync = hasSyncItem(newQuestions, conditionFn);
  const index = findSyncItem(newQuestions, conditionFn);
  const syncItem = getSyncItem(newQuestions, index);
  return { hasSync, syncItem, index };
};
