// Libs
import React, { useMemo } from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import round from 'lodash/round';

// Utils
import { convertS3UrlToCloudFrontUrl, formatMoney, getUserShortName, pluralize } from 'utils/commonFunction';
import { SETTINGS_TYPE_MODAL } from '../../constants';
import { CURRENCIES, TEXT_CURRENCIES } from 'constants/commonData';

// Assets
import { ReactComponent as MarketPlaceIcon } from 'assets/icons/OnboardingFlow/market_place_icon.svg';

// Styles
import * as S from './style';

const Item = ({ type, item, onSelect, isChecked, isDisabled, cloudfrontList, isAssigned }) => {
  const {
    avatar,
    email,
    color,
    full_name,
    first_name,
    last_name,
    _id,
    hash_id,
    id,
    name,
    cover_image,
    price,
    platforms,
  } = item || {};
  const itemId = _id || hash_id || id;

  const { itemName, itemAvatar, itemEmail, itemColor, itemPrice, itemPlatforms } = useMemo(() => {
    switch (type) {
      case SETTINGS_TYPE_MODAL.COACHES:
        const fullName = full_name || `${first_name || ''} ${last_name || ''}`.trim();
        return {
          itemName: fullName,
          itemAvatar: avatar,
          itemEmail: email,
          itemColor: color,
        };
      case SETTINGS_TYPE_MODAL.PACKAGES:
        return {
          itemName: name,
          itemAvatar: cover_image,
          itemPrice: price,
          itemPlatforms: platforms,
        };

      default:
        return {
          itemName: '',
          itemAvatar: '',
          itemEmail: '',
          itemColor: '',
          itemPrice: {},
          itemPlatforms: [],
        };
    }
  }, []);

  const handleSelect = () => {
    if (isDisabled || isAssigned) return;

    onSelect(item, isChecked);
  };

  const contentTooltip = () => {
    switch (type) {
      case SETTINGS_TYPE_MODAL.COACHES:
        return 'This coach is already added to the current onboarding flow.';
      case SETTINGS_TYPE_MODAL.PACKAGES:
        if (isAssigned) {
          return 'This package is already linked to another onboarding flow. Please unlink it from the package settings to attach it to this onboarding flow.';
        }
        return 'This package is already linked to the current onboarding flow.';

      default:
        return '';
    }
  };

  const renderItemByType = () => {
    switch (type) {
      case SETTINGS_TYPE_MODAL.COACHES:
        return renderCoachItem();
      case SETTINGS_TYPE_MODAL.PACKAGES:
        return renderPackageItem();

      default:
        return null;
    }
  };

  const renderCoachItem = () => {
    return (
      <>
        <Avatar
          name={getUserShortName(item)}
          color={itemColor}
          className="custom-avatar"
          size="54"
          src={convertS3UrlToCloudFrontUrl(itemAvatar, cloudfrontList, true)}
        />
        <S.InfoWrapper>
          <S.Name>{itemName}</S.Name>
          <S.InfoPart>
            <S.InforTitle>{itemEmail}</S.InforTitle>
          </S.InfoPart>
        </S.InfoWrapper>
      </>
    );
  };

  const renderPackageItem = () => {
    const { amount, currency, type, every, recurring_interval_type, end_after } = itemPrice || {};

    const isOnetimeType = type === 'one_time';
    const symbol = TEXT_CURRENCIES.includes(currency)
      ? `${get(CURRENCIES, `${currency}.symbol`)} `
      : get(CURRENCIES, `${currency}.symbol`);
    const money = formatMoney(round(amount, 2), symbol);

    const inforStr = isOnetimeType ? (
      `${money} One time`
    ) : (
      <>
        {money} Every {pluralize(recurring_interval_type, every, true)}
        {!!end_after && <>&nbsp;for {pluralize('time', end_after, true)}</>}
      </>
    );

    return (
      <>
        <S.BannerWrapper>
          <img src={convertS3UrlToCloudFrontUrl(itemAvatar, cloudfrontList, true)} alt={itemName} />
          {itemPlatforms.some(item => item === 'marketplace') && (
            <>
              <MarketPlaceIcon className="market-place-icon" data-tip data-for={`market-place-tooltip-${itemId}`} />
              {!(isDisabled || isAssigned) && (
                <ReactTooltip
                  className="app-tooltip market-place-tooltip"
                  id={`market-place-tooltip-${itemId}`}
                  effect="solid"
                  place="top"
                >
                  <p>Package from Marketplace</p>
                </ReactTooltip>
              )}
            </>
          )}
        </S.BannerWrapper>
        <S.InfoWrapper>
          <S.Name>{itemName}</S.Name>
          <S.InfoPart>
            <S.InforTitle>{inforStr}</S.InforTitle>
          </S.InfoPart>
        </S.InfoWrapper>
      </>
    );
  };

  return (
    <S.Container>
      <S.Wrapper
        data-tip
        data-for={`item-tooltip-${itemId}`}
        onClick={handleSelect}
        isChecked={isChecked}
        isDisabled={isDisabled || isAssigned}
      >
        <S.RightSide>{renderItemByType()}</S.RightSide>
        <S.LeftSide>
          <S.Checkbox className="on-demand-checkbox" isChecked={isChecked || isDisabled} isAssigned={isAssigned} />
        </S.LeftSide>
      </S.Wrapper>
      {(isDisabled || isAssigned) && (
        <ReactTooltip
          className="app-tooltip item-tooltip"
          id={`item-tooltip-${itemId}`}
          effect="solid"
          place="top"
          delayShow={100}
        >
          <p>{contentTooltip()}</p>
        </ReactTooltip>
      )}
    </S.Container>
  );
};

export default Item;
