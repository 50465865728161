import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { Button } from 'shared/FormControl';
import { push } from 'connected-react-router';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483100;
  top: 0;
  left: 0;

  .overdueZapier {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 418px;
    height: 200px;
    background: #fff;
    border-radius: 8px;
    z-index: 2147483100;
    padding: 30px;

    position: relative;

    &__content {
      margin-bottom: 32px;
      width: 360px;

      &--title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: normal;
        text-align: center;
        color: #000000;
        margin-bottom: 15px;
      }

      &--description {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        text-align: center;
        color: #323c47;
      }
    }
  }
  .overdueZapier__redirect-button {
    display: flex;
    padding: 5px 30px !important;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background: #5158cf;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    width: 194px !important;
    height: 30px !important;
  }
`;

function OverdueZapier({ user, teamData, ...props }) {
  const path = window.location.pathname;

  if (path.includes('/home/billing')) {
    return null;
  }
  const goToBilling = () => {
    props.push('/home/billing');
  };

  return (
    <Wrapper>
      <div className="overdueZapier">
        <div className="overdueZapier__content">
          <div className="overdueZapier__content--title">Your payment is overdue</div>
          <div className="overdueZapier__content--description">
            <span>Please update your payment method to continue using the Zapier plugin.</span>
          </div>
        </div>
        <Button className="overdueZapier__redirect-button" onClick={goToBilling} purple>
          Update payment info
        </Button>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = state => {
  const { user, rootReducer, router } = state;

  return {
    user,
    shouldSelectPlan: rootReducer.pricing.get('shouldSelectPlan'),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    location: router.location,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverdueZapier);
