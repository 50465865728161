import React from 'react';

import * as S from 'shared/PreviewLink/styles';
import { ReactComponent as CloseMetaIcon } from 'assets/icons/close_thin.svg';
import { ReactComponent as NoPreviewBg } from 'assets/images/not_found_preview.svg';
import { POST_THUMB_DEFAULT } from 'constants/commonData';

function PreviewVideoLink(props) {
  const {
    attachmentMeta = {},
    closePreview = false,
    onTimeline = false,
    isScheduledPost = false,
    onPostDetail = false,
  } = props;
  const {
    title = '',
    description = '',
    publisher = '',
    image = '',
    url = '',
    domain = '',
    isThumbDefault,
  } = attachmentMeta;

  const isMediaDefault = isThumbDefault || image === POST_THUMB_DEFAULT;

  if (!title && !description && !image && !publisher && !domain) return null;

  const handleOpenLink = () => {
    if ((url && onTimeline) || (url && onPostDetail)) {
      window.open(url, '_blank');
    }
  };

  return (
    <S.MetadataWrapper onTimeline={onTimeline} onPostDetail={onPostDetail} isThumbDefault={isMediaDefault}>
      <S.MetaVideoContent
        onTimeline={onTimeline}
        onPostDetail={onPostDetail}
        onClick={handleOpenLink}
        isScheduledPost={isScheduledPost}
        isThumbDefault={isMediaDefault}
      >
        {!onTimeline && !onPostDetail && (
          <S.CloseMetaData onClick={closePreview}>
            <CloseMetaIcon />
          </S.CloseMetaData>
        )}
        {image ? (
          <S.MetaVideoCover alt="" src={image} onTimeline={onTimeline} onPostDetail={onPostDetail} />
        ) : (
          <S.NoPreviewWrapper onTimeline={onTimeline}>
            <NoPreviewBg />
          </S.NoPreviewWrapper>
        )}
        <S.MetaTextWrapper>
          <S.MetaVideoURL>{publisher || domain}</S.MetaVideoURL>
          <S.MetaVideoTitle>{title || domain}</S.MetaVideoTitle>
          <S.MetaVideoDesc>{description}</S.MetaVideoDesc>
        </S.MetaTextWrapper>
      </S.MetaVideoContent>
    </S.MetadataWrapper>
  );
}

export default PreviewVideoLink;
