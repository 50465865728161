import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import AnnouncementPopup from 'components/AnnouncementPopup';
import {
  addIntervalMessage,
  updateIntervalMessage,
  deleteIntervalMessage,
  copyIntervalMessage,
} from 'redux/autoflowInterval/auto-message/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { ANNOUNCEMENT_AUTO_MESSAGE, AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import ConfirmModal from 'shared/ConfirmModal';
import * as S from './styles';

function Announcement(props) {
  const { dayIndex, weekIndex, originData, autoflowId, totalWeek } = props;
  const date = useMemo(() => Number(weekIndex) * 7 + Number(dayIndex), [weekIndex, dayIndex]);

  const closePopup = () => {
    if (typeof props.onClose === 'function') {
      return props.onClose();
    }

    props.toggleModal(false);
  };

  const onClose = data => {
    if (data && !_.isEqual(data.oldValue, data.newValue)) {
      props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={closePopup}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    } else {
      closePopup();
    }
  };

  const onSubmit = submitData => {
    const bodyData = _.omit({ ...submitData, autoflowId, type: ANNOUNCEMENT_AUTO_MESSAGE }, ['date', 'end_date']);

    bodyData.dayDuration = submitData.end_date ? submitData.end_date - submitData.date + 1 : 1;

    if (_.get(originData, '_id')) {
      bodyData.newDayIndex = submitData.date % 7;
      bodyData.newWeekIndex = parseInt(submitData.date / 7);
      bodyData.oldDayIndex = dayIndex;
      bodyData.oldWeekIndex = weekIndex;
      bodyData.messageId = _.get(originData, '_id');
      return props.updateIntervalMessage(bodyData);
    }

    bodyData.dayIndex = submitData.date % 7;
    bodyData.weekIndex = parseInt(submitData.date / 7);

    return props.addIntervalMessage(bodyData);
  };

  const handleDeleteMessage = () => {
    props.toggleModal(false);
    props.deleteIntervalMessage({ weekIndex, dayIndex, autoflowId, messageId: _.get(originData, '_id') });
  };

  const onDelete = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Message"
        content="Are you sure that you want to remove this message?"
        onConfirm={handleDeleteMessage}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  const handleCopyAnnouncement = () => {
    props.toggleModal(false);
    props.copyIntervalMessage(originData);
  };

  return (
    <AnnouncementPopup
      originData={originData || {}}
      onClose={onClose}
      date={date}
      onSubmit={onSubmit}
      onCopy={handleCopyAnnouncement}
      onDelete={onDelete}
      uploadHeader={{ url: '/api/autoflow/message/upload_attachment', key: 'attachment' }}
      announcementType={AUTOFLOW_TYPES.INTERVAL}
      totalWeek={totalWeek}
    />
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const {
    common: { workingAutoflow },
  } = rootReducer.autoflow;

  return {
    autoflowId: workingAutoflow ? workingAutoflow._id : '',
    totalWeek: workingAutoflow.interval_weeks.length,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addIntervalMessage: bindActionCreators(addIntervalMessage, dispatch),
  updateIntervalMessage: bindActionCreators(updateIntervalMessage, dispatch),
  deleteIntervalMessage: bindActionCreators(deleteIntervalMessage, dispatch),
  copyIntervalMessage: bindActionCreators(copyIntervalMessage, dispatch),
});

export default connect(mapState, mapDispatchToProps)(Announcement);
