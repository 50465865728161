import React from 'react';
import styled from 'styled-components';
// import { getPeriodName } from 'utils/commonFunction';

export const Wrapper = styled.div`
  padding: 0px 25px;

  .subTotal {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #e6e9f3;
  }

  .subTotal__wrapper {
    text-align: right;
  }

  .subTotal__name {
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
    color: #202353;
  }

  .subTotal__unit {
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: #202353;
    text-align: right;
  }

  .subTotal__price {
    font-weight: bold;
    font-size: 15px;
    line-height: 140%;
    color: #202353;
  }
`;

function Subtotal(props) {
  const {
    subTotal,
    // period
  } = props;
  return (
    <Wrapper>
      <div className="subTotal">
        <div>
          <strong className="subTotal__name">Subtotal</strong>
        </div>
        <div className="subTotal__wrapper">
          <strong className="subTotal__price">{Number(subTotal).toUSDFormat(false)}</strong>
          {/* <div className="subTotal__unit">/{getPeriodName(period)}</div> */}
        </div>
      </div>
    </Wrapper>
  );
}

export default Subtotal;
