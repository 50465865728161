import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { bgDishType } from '../constants';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #202353;
`;

export const Wrapper = styled(Modal)`
  &.ui.modal {
    width: 740px;
    height: calc(100% - 32px);
    border-radius: 5px !important;
  }
`;

export const Header = styled.div`
  height: 50px;
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${({ isScroll }) =>
    isScroll &&
    css`
      border-bottom: 1px solid #f0f0f2;
    `}

  .evf-dropdown.custom-dropdown-actions {
    .evf-dropdown__trigger-container .dropdown-trigger {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background-color: #fff;
      .dot {
        transform: unset;
      }
      .dot,
      .dot::after,
      .dot::before {
        background-color: #7b7e91;
        width: 5px;
        height: 5px;
      }
      .dot::after {
        left: -8px;
      }
      .dot::before {
        left: 8px;
      }
      &:hover {
        background-color: #f0f1ff;
        .dot,
        .dot::after,
        .dot::before {
          background-color: #5158cf;
        }
      }
      .__react_component_tooltip.app-tooltip.show {
        background: #333;
        padding: 8px 10px;
        border-radius: 5px;
        ${baseText}
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        width: 95px;
        height: 34px;
      }
      .__react_component_tooltip.type-dark.place-top:after {
        border-top-color: #333;
        margin-bottom: -2px !important;
      }
      .__react_component_tooltip.place-top {
        margin-top: -11px;
      }
    }
    &.open .dropdown-trigger {
      background-color: #f0f1ff;
      .dot,
      .dot::after,
      .dot::before {
        background-color: #5158cf;
      }
    }
    .evf-dropdown__menu {
      width: 150px;
      padding: 5px 0;
      .evf-dropdown__option {
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        user-select: none;
        .icon {
          display: flex;
          justify-content: center;
        }
        span {
          color: #f7f8f9;
          font-family: 'Open Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }
    }
  }
`;

export const OptionIcon = styled.div`
  padding: 0 3px;
`;

export const Content = styled.div`
  padding: 0px 15px 50px 30px !important;
  width: 100%;
  height: ${({ isShowFooterActions }) => `calc(100% - ${isShowFooterActions ? '110px' : '50px'})`};
  overflow-y: scroll;
  border-right: 9px solid transparent;

  ::-webkit-scrollbar {
    width: 4px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e1e1ea !important;
  }
`;

export const Overview = styled.div`
  display: flex;
  gap: 20px;
`;

export const Thumbnail = styled.div`
  width: 260px;
  height: 173px;
`;

export const Information = styled.div`
  flex: 1;
`;

export const DietaryInfo = styled.div`
  margin-top: 35px;
`;

export const MealDetail = styled.div`
  margin-top: 15px;
  ${props =>
    props.isEdit &&
    css`
      margin-top: 40px;
    `}
`;

export const NutritionOverview = styled.div`
  margin-top: 20px;
`;

export const ListCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  padding-left: 10px;
  flex-wrap: wrap;
`;

export const CategoryItem = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  background-color: ${({ dishType }) => bgDishType[dishType] || '#f0f1ff'};
  padding: 4px 10px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 5px;
  }
`;

export const Footer = styled.div`
  height: 60px;
  padding: 15px 30px;
  border-top: 1px solid #f0f0f2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FooterButtonActions = styled.div`
  display: flex;
  height: 30px;
  padding: 10px 35px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #5158cf;
  &:hover {
    cursor: pointer;
    background-color: rgba(81, 88, 207, 0.9);
  }
`;

export const FooterButtonContent = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .recipe-loading-indicator {
    padding-top: 0;

    .ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;
