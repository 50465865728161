import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import diff from 'deep-diff';

import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import MediaPlayer from 'shared/MediaPlayer';
import VideoDuration from 'shared/VideoDuration';
import { getDuration, getVideoType } from 'utils/commonFunction';

const VideoAutoMessage = ({ message, compactView, renderDuration, getS3presignedURL }) => {
  const [media, setMedia] = useState({
    thumbnail: '',
    video: '',
  });

  const getPresignedURLs = async list => {
    try {
      const response = await getS3presignedURL(list);
      setMedia({
        thumbnail: response[0],
        video: response[1],
      });
    } catch {}
  };

  useEffect(() => {
    getPresignedURLs([attachment_thumbnail, attachment]);
  }, [message]);

  useEffect(() => {
    let data = { thumbnail: attachment_thumbnail, video: attachment };

    if (diff(media, data)) {
      if (!attachment.includes('blob')) {
        getPresignedURLs([attachment_thumbnail, attachment]);
      }
      setMedia(data);
    }
  }, []);

  const { attachment, attachment_type, attachment_thumbnail } = message;

  if (message && attachment) {
    return (
      <>
        <video
          src={media.video}
          type={getVideoType(attachment_type)}
          key={attachment}
          poster={media.thumbnail || ''}
          onLoadedMetadata={event => {
            if (!renderDuration || compactView) {
              return false;
            }

            if (event.target && event.target.duration) {
              const { duration, nextElementSibling } = event.target;

              if (nextElementSibling && nextElementSibling.className.includes('video-duration')) {
                const time = getDuration(duration);
                const timeEl = nextElementSibling.querySelector('.time');

                if (timeEl) {
                  timeEl.innerText = time;
                }
              }
            }
            event.persist();
          }}
        >
          <source src={media.video} type={getVideoType(attachment_type)} />
          <source src={media.video} type="video/mp4" />
        </video>
        {!!renderDuration && <VideoDuration />}
      </>
    );
  }
  return null;
};

const mapDispatchToProps = dispatch => ({
  getS3presignedURL: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
});

export default connect(null, mapDispatchToProps)(VideoAutoMessage);
