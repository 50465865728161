import React from 'react';
import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px 20px 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 80px;
`;

export default props => (
  <Container {...props}>
    <img src={`${CDN_URL}/images/everfit_new_logo.svg`} alt="" width="171" />
  </Container>
);
