// Libs
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as CloseButton, Modal } from 'semantic-ui-react';
import AsyncSelect from 'react-select/lib/Async';
import moment from 'moment';
import Datetime from 'react-datetime';
import map from 'lodash/map';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

// Actions
import { assignMealPlan, getAssignedList, searchAssignMealPlanList } from 'redux/meal-plans/actions';
import { toggleSecondModal } from 'actions/modal';

// Shared
import CustomMultiValueLabel from './CustomMultiValueLabel';
import CustomOption from './CustomOption';
import AssignNotificationModal from '../AssignNotificationModal';

// Constants
import { ASSIGNED_STATUS } from 'components/MealPlanDetail/constants';

// Assets
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_up_bold.svg';
import CloseIcon from 'assets/icons/close_bold_circle.svg';

import * as S from './style';

const getWeekNumber = date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 4 - (newDate.getDay() || 7));
  const yearStart = new Date(newDate.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((newDate - yearStart) / 86400000 + 1) / 7);
  return weekNumber;
};

const AssignMealPlan = props => {
  const {
    toggleModal,
    detailMealPlan,
    assignMealPlan,
    getAssignedList,
    searchAssignMealPlanList,
    callbackAssignSuccess = () => {},
    toggleSecondModal,
  } = props;

  const [assignTo, setAssignTo] = useState([]);
  const [startingOn, setStartingOn] = useState(moment());
  const [isAssigning, setIsAssigning] = useState(false);

  const handleClose = () => {
    toggleModal && toggleModal(false);
  };

  const searchClient = (inputValue, callback) => {
    const except = map(
      assignTo.filter(({ typeName = '' }) => typeName === 'group'),
      '_id',
    );
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';

    searchAssignMealPlanList({
      search,
      startingDay: moment(startingOn).format('YYYY-MM-DD'),
      mealPlanId: get(detailMealPlan, '_id', ''),
      except,
    })
      .then(response => {
        if (response.length) {
          const data = response.map(item => get(item, 'data.data', {}));

          const clients = data[1].data.map(client => ({
            ...client,
            typeName: 'client',
            name: `${client.first_name} ${client.last_name}`,
          }));

          const groups = data[0]
            .filter(({ total_clients = 0 }) => total_clients > 0)
            .map(group => ({
              ...group,
              typeName: 'group',
            }));

          const results = [...groups, ...clients];

          callback(
            map(results, item => {
              const { _id, typeName, total_clients, name } = item || {};

              return {
                ...item,
                key: _id,
                value: _id,
                label: name,
                total: total_clients,
                typeName: typeName,
              };
            }),
          );
        } else {
          callback([]);
        }
      })
      .catch(error => {
        callback([]);
      });
  };

  const handleAssignToMember = list => {
    setAssignTo(list);
  };

  const handleDisabledOption = ({ invalid_type }) => invalid_type;

  const defaultValue = () => {
    return assignTo.map(item => {
      const { _id, full_name, first_name, last_name } = item || {};

      return {
        ...item,
        key: _id,
        value: _id,
        label: full_name ? full_name : `${first_name} ${last_name}`,
      };
    });
  };

  const searchClientDebounce = debounce(searchClient, 200);

  const renderAssignTo = () => {
    return (
      <S.FormGroup>
        <S.FormTitle>Assign To</S.FormTitle>
        <S.FormActions>
          <AsyncSelect
            key={JSON.stringify(startingOn)}
            value={assignTo}
            isMulti
            defaultOptions
            cacheOptions
            components={{
              Option: CustomOption,
              MultiValueLabel: CustomMultiValueLabel,
              DropdownIndicator: null,
              LoadingIndicator: null,
            }}
            loadOptions={(inputValue, callback) => searchClientDebounce(inputValue, callback)}
            onChange={handleAssignToMember}
            className="multi-select-container"
            classNamePrefix="multi-select"
            placeholder="Add Client..."
            noOptionsMessage={() => 'No results.'}
            defaultValue={defaultValue()}
            styles={S.customStyles}
            isOptionDisabled={handleDisabledOption}
          />
        </S.FormActions>
      </S.FormGroup>
    );
  };

  const renderMealPlan = () => {
    return (
      <S.FormGroup>
        <S.FormTitle>Meal Plan</S.FormTitle>
        <S.FormActions>
          <S.MealPlanName>
            <span>{get(detailMealPlan, 'name', '')}</span>
          </S.MealPlanName>
        </S.FormActions>
      </S.FormGroup>
    );
  };

  const renderDatetimeInput = props => {
    const mondayOfWeek = startingOn.clone().startOf('isoWeek');

    let inputClass = 'meal-plan-starting-on';

    if (props.className) {
      inputClass += ' ' + props.className;
    }

    return (
      <div {...props} className={inputClass}>
        Week of {mondayOfWeek.format('MMMM DD, YYYY')}
        <ArrowDown className="icon-down" />
      </div>
    );
  };

  const renderDay = (props, currentDate) => {
    const checkDateSameToday = getWeekNumber(currentDate) === getWeekNumber(startingOn);
    const selectWeek = checkDateSameToday ? 'active' : '';

    return (
      <td {...props} className={`${props.className} ${selectWeek}`}>
        <div className="rdt__custom-day">
          <div>{currentDate.date()}</div>
        </div>
      </td>
    );
  };

  const renderStartingOn = () => {
    return (
      <S.FormGroup>
        <S.FormTitle>Starting On</S.FormTitle>
        <S.FormActions>
          <S.CustomDatetime>
            <Datetime
              className="new-ui"
              value={startingOn}
              renderInput={renderDatetimeInput}
              timeFormat={false}
              closeOnSelect={true}
              onChange={date => setStartingOn(date)}
              isValidDate={current => current.isSameOrAfter(moment(), 'day')}
              renderDay={renderDay}
            />
          </S.CustomDatetime>
        </S.FormActions>
      </S.FormGroup>
    );
  };

  const handleSubmit = () => {
    if (isEmpty(assignTo) || isAssigning) {
      return;
    }

    setIsAssigning(true);

    const filterAndMap = (array, type) => {
      const filtered = filter(array, ({ typeName = '' }) => typeName === type);
      return map(filtered, '_id');
    };

    const clients = filterAndMap(assignTo, 'client');
    const groups = filterAndMap(assignTo, 'group');
    const starting_day = moment(startingOn).format('YYYY-MM-DD');

    const data = {
      meal_plan_id: get(detailMealPlan, '_id', ''),
      clients,
      groups,
      starting_day,
    };

    assignMealPlan &&
      assignMealPlan(
        data,
        () => {
          toggleModal && toggleModal(false);
          setIsAssigning(false);
          getAssignedList &&
            getAssignedList({
              meal_plan_id: get(detailMealPlan, '_id', ''),
              status: ASSIGNED_STATUS.ACTIVE,
            });
          callbackAssignSuccess();
        },
        true,
      ).then(response => {
        const { valid_clients = [], invalid_clients = [] } = get(response, 'data.data', {});

        const totalAssignSuccess = valid_clients.length;
        const totalAssignFailed = invalid_clients.length;

        const pluralClientsSuccess = totalAssignSuccess !== 1 ? 'clients were' : 'client was';
        const pluralClientsFailed = totalAssignFailed !== 1 ? 'clients were' : 'client was';

        const pluralIsAlready = totalAssignFailed !== 1 ? 'clients are' : 'client is';

        const successMessage = `${totalAssignSuccess} ${pluralClientsSuccess} added to the Meal Plan.`;
        const failedMessage = `${totalAssignFailed} ${pluralClientsFailed} not added:`;

        const conflictedMessage = `${totalAssignFailed} ${pluralIsAlready} already in Meal Plan.`;

        const errorContent = renderErrorContent({
          failedMessage,
          conflictedMessage,
          totalAssignFailed,
        });

        renderAssignNotiModal({
          successMessage,
          errorContent: totalAssignFailed !== 0 && errorContent,
          isShowError: totalAssignFailed !== 0,
        });
      });
  };

  const renderAssignNotiModal = ({ successMessage, errorContent, isShowError }) => {
    toggleSecondModal &&
      toggleSecondModal(
        true,
        <AssignNotificationModal
          onClose={() => toggleSecondModal(false)}
          successMessage={successMessage}
          errorContent={errorContent}
          isShowError={isShowError}
        />,
      );
  };

  const renderErrorContent = ({ failedMessage = '', conflictedMessage = '', totalAssignFailed = 0 }) => {
    return (
      <>
        <S.ErrorMessage isHasSpacing>{`${failedMessage}`}</S.ErrorMessage>
        <S.ErrorList>{totalAssignFailed > 0 && <S.ErrorItem>{conflictedMessage}</S.ErrorItem>}</S.ErrorList>
      </>
    );
  };

  return (
    <S.CustomModal open={true} onClose={handleClose} closeOnDimmerClick={false} className="evf-assign-meal-plan-modal">
      <Modal.Header>
        <S.HeaderWrapper>
          <S.HeaderTitle>Assign Meal Plan</S.HeaderTitle>
          <CloseButton className="close-button" onClick={handleClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </S.HeaderWrapper>
      </Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          {renderAssignTo()}
          {renderMealPlan()}
          {renderStartingOn()}
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ActionsWrapper>
          <S.Button onClick={handleSubmit} isDisabled={isEmpty(assignTo) || isAssigning}>
            Assign
          </S.Button>
        </S.ActionsWrapper>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapState = state => {
  const {
    rootReducer: { mealPlans },
  } = state;

  const detailMealPlan = get(mealPlans, 'detailMealPlan', {});

  return { detailMealPlan };
};

const mapDispatch = dispatch => ({
  assignMealPlan: bindActionCreators(assignMealPlan, dispatch),
  getAssignedList: bindActionCreators(getAssignedList, dispatch),
  searchAssignMealPlanList: bindActionCreators(searchAssignMealPlanList, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
});

export default connect(mapState, mapDispatch)(AssignMealPlan);
