import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const UpdatePaymentConfirmModal = styled(Modal)`
  width: 460px !important;

  .confirm-content-header > img {
    margin-right: 5px;
  }
  .confirm-content-header > p {
    line-height: 1.2em;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }

  .confirm-content-label__second {
    margin-top: 20px;
  }

  .confirm-content-body__feature {
    padding: 10px 20px;
    margin: 10px 0px 20px;
    background: #f3f5f8;
    border-radius: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    color: #4e5662;
  }

  button.ui.button.confirm-downgrade-button {
    background: #ffffff;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #818da1;
    padding: 9px 30px;
    border: 1px solid #d6dae4;
    box-sizing: border-box;
    border-radius: 5px;
  }
  button.ui.button.pay-later-button {
    background: #5158cf;
    border-radius: 5px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    padding: 9px 30px;
  }
`;
