import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import ProgramLibraryDetailModal from 'components/ProgramDetailModal';
import { toggleModal } from 'actions/modal';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button.button {
    border-radius: 4px;
    border: solid 1px #d4d8da;
    background-color: #fff;
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
    margin: 0 0 0 10px;
    display: flex;

    :first-child {
      margin-left: 14px;
    }

    :hover {
      background-color: #eceeef;
      color: #666666;
    }

    > * {
      margin-right: 5px;
      :last-child {
        margin-right: 0;
      }
    }
  }
`;

const ProgramCalendarHeader = ({ program, toggleModal }) =>
  !program ? null : (
    <Container className="program-calendar__header">
      <span>{program.title || ''}</span>
      <Button
        onClick={() => {
          let originItem = _.cloneDeep(program);
          let workingItem = _.cloneDeep(program);
          toggleModal(
            true,
            <ProgramLibraryDetailModal mode={'update_template'} originItem={originItem} workingItem={workingItem} />,
          );
        }}
      >
        Edit Info
      </Button>
    </Container>
  );

const mapStateToProps = state => ({
  program: state.rootReducer.program_library.selected,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCalendarHeader);
