import React, { useState, useEffect, useRef } from 'react';

import { getDuration } from 'utils/commonFunction';
import * as S from './style';
import './style.scss';
import MediaUploadProgress from 'shared/MediaUploadProgress';
import MediaPlayer from 'shared/MediaPlayer';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import { FormGroup } from 'shared/FormControl';
import MediaModal from 'shared/MediaModal/component';
import MediaFile from './MediaFile';
import MediaLink from './MediaLink';

import { CDN_URL, LIMIT_SIZE_VIDEO_UPLOAD_MB } from 'constants/commonData';

import AddImageIcon from 'assets/icons/announcement_add_image.svg';
import AddVideoIcon from 'assets/icons/announcement_add_video.svg';
import AddLinkIcon from 'assets/icons/announcement_add_link.svg';
import AddImageIconHover from 'assets/icons/announcement_add_image_hover.svg';
import AddVideoIconHover from 'assets/icons/announcement_add_video_hover.svg';
import AddLinkIconHover from 'assets/icons/announcement_add_link_hover.svg';

const Attachment = ({
  isUploading,
  attachment,
  cancelUploadFile,
  onUploadSuccess,
  onSelectFile,
  onSelectFileError,
  onRemove,
  uploadConfigs,
  onSelectAttachmentLink,
  onRemoveAttachmentLink,
  onChangeAttachmentLink,
  attachmentLink,
  loadingMetaData = false,
  invalidLink = false,
}) => {
  const linkRef = useRef();
  const [media, setMedia] = useState({ thumbnail: '', video: '' });

  useEffect(() => {
    if (attachment) {
      setMedia({
        thumbnail: attachment.thumbnail_url || '',
        video: attachment.localFile || attachment.url || '',
      });
    } else
      setMedia({
        thumbnail: '',
        video: '',
      });
  }, [attachment]);

  useEffect(() => {
    if (attachmentLink) {
      if (linkRef.current && attachmentLink.attachment) {
        linkRef.current.value = attachmentLink.attachment;
      }
    }
  }, [attachmentLink]);

  const renderAttachmentUploading = () => {
    const { file } = attachment;

    return (
      <MediaUploadProgress
        file={file}
        apiConfigs={uploadConfigs}
        onCancel={cancelUploadFile}
        onError={cancelUploadFile}
        onSuccess={onUploadSuccess}
      />
    );
  };

  const renderSelectAttachmentType = () => {
    return (
      <>
        <MediaFile
          type="image"
          icon={AddImageIcon}
          hoverIcon={AddImageIconHover}
          text="Image"
          onChange={onSelectFile}
          maxSize={25}
          onError={onSelectFileError}
        />
        <MediaFile
          type="video"
          icon={AddVideoIcon}
          hoverIcon={AddVideoIconHover}
          text="Video"
          onChange={onSelectFile}
          maxSize={LIMIT_SIZE_VIDEO_UPLOAD_MB}
          onError={onSelectFileError}
        />
        <MediaLink
          type="link"
          icon={AddLinkIcon}
          hoverIcon={AddLinkIconHover}
          text="Link"
          onClick={onSelectAttachmentLink}
        />
      </>
    );
  };

  const renderAttachmentImage = () => {
    const { url, type, thumbnail_url, localFile } = attachment;

    const attachmentURL = localFile || url;

    return (
      <S.Attachment>
        <S3ImageWithFallback src={[thumbnail_url || '', attachmentURL || '']} />
        <img className="remove" src={`${CDN_URL}/images/close_circle.svg`} width={15} onClick={onRemove} alt="" />
        <MediaPlayer url={attachmentURL} type={type} />
      </S.Attachment>
    );
  };

  const renderAttachmentVideo = () => {
    const { url, type, thumbnail_url, localFile } = attachment;
    const attachmentURL = localFile || url;

    return (
      <S.Attachment video>
        <img className="remove" src={`${CDN_URL}/images/close_circle.svg`} width={15} onClick={onRemove} alt="" />
        <div className="video-container">
          <video
            onLoadedMetadata={event => {
              if (event.target && event.target.duration) {
                const { duration, nextElementSibling } = event.target;

                if (nextElementSibling && nextElementSibling.className === 'shadow') {
                  const time = getDuration(duration);
                  const timeEl = nextElementSibling.querySelector('.time');

                  if (timeEl) {
                    timeEl.innerText = time;
                  }
                }
              }
              event.persist();
            }}
            src={media.video}
            poster={media.thumbnail || ''}
          >
            <source src={media.video} type={type} />
            <source src={media.video} type="video/mp4" />
          </video>
          <div className="shadow">
            <div className="duration">
              <img src={`${CDN_URL}/images/video_duration.svg`} alt="" />
              <span className="time">00:00</span>
            </div>
          </div>
          <MediaPlayer url={attachmentURL} type={type} thumbnail={thumbnail_url} />
        </div>
      </S.Attachment>
    );
  };

  const renderAttachmentMedia = () => {
    const { type } = attachment;

    if (type.includes('image')) {
      return renderAttachmentImage();
    }

    if (type.includes('video')) {
      return renderAttachmentVideo();
    }

    return null;
  };

  const renderAttachmentLink = () => {
    if (!attachmentLink) return;
    const { attachment = '', attachment_thumbnail } = attachmentLink;

    return (
      <S.AttachmentLinkWrapper>
        <img
          className="remove"
          src={`${CDN_URL}}/images/close_circle.svg`}
          width={15}
          onClick={onRemoveAttachmentLink}
          alt=""
        />
        <FormGroup>
          <label>
            <span>Link</span>
          </label>
          <input
            ref={linkRef}
            name="attachment_link"
            type="url"
            pattern="https?://(www\.)?(youtube\.com|vimeo\.com)/.+"
            defaultValue={attachment}
            placeholder="Youtube / Vimeo Link"
            onChange={onChangeAttachmentLink}
          />
        </FormGroup>
        {!loadingMetaData && invalidLink && (
          <div className="invalid-link">Please enter a valid Youtube or Vimeo URL</div>
        )}
        {!invalidLink && attachment_thumbnail && (
          <div className="video-container">
            {!loadingMetaData && <img src={attachment_thumbnail} alt="" />}
            <MediaModal video={attachment} presigned />
          </div>
        )}
      </S.AttachmentLinkWrapper>
    );
  };

  if (isUploading && attachment) {
    return renderAttachmentUploading();
  }

  if (!attachment && !attachmentLink) {
    return renderSelectAttachmentType();
  }

  if (attachment) {
    return renderAttachmentMedia();
  }

  if (attachmentLink) {
    return renderAttachmentLink();
  }

  return null;
};

export default Attachment;
