import React, { useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { Button, ErrorMessage } from 'shared/FormControl';
import DatePicker from 'shared/DatePicker';
import TimeInput, { getTimeFromInput } from 'components/BodyMetricProgressNew/AddResultPopup/TimeInput';
import { CustomConfirmModal } from 'components/BodyMetricChartNew/components/ChartSettings/style';
import { CDN_URL, METRIC_UNIT_ERRORS } from 'constants/commonData';
import { toggleConfirmModal } from 'actions/modal';

import { END_TIME_ERRORS } from 'components/BodyMetricUpdateAll/component';
import * as S from './style';

const TIME_FORMAT = 'hh : mm';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const getTimeData = (time, belong_day, isAM) => {
  const timeMoment = time ? moment(time, DATE_TIME_FORMAT) : moment(belong_day, 'YYYY-MM-DD');
  return {
    isAM: time ? timeMoment.format('A') === 'AM' : isAM,
    time: time ? timeMoment.format(TIME_FORMAT) : '',
    date: timeMoment,
  };
};

const getFormData = data => {
  const { start_time, end_time, belong_day } = data;
  const initStart = getTimeData(start_time, belong_day, false);
  const initEnd = getTimeData(end_time, belong_day, true);

  return {
    start: initStart,
    end: initEnd,
  };
};

function ResultPopupSpecialChart(props) {
  const {
    isOpen = false,
    onClose,
    onSave,
    editData = {},
    style = {},
    toggleConfirmModal,
    newUI = false,
    trigger = undefined,
    onOpen = () => {},
    positionEdit = 'top right',
  } = props;
  const [errors, setErrors] = useState({});
  const [errMessage, setErrMessage] = useState('');
  const [isOpenDisCard, setIsOpenDisCard] = useState(false);
  const [isShowStartCalendar, setIsShowStartCalendar] = useState(false);
  const [isShowEndCalendar, setIsShowEndCalendar] = useState(false);
  const deviceTz = moment.tz.guess();

  const isEditMode = useMemo(() => !isEmpty(editData), [editData]);
  const initAddData = useMemo(() => {
    const nowMoment = moment().tz(deviceTz);
    return {
      start: {
        date: nowMoment.clone().subtract(1, 'd'),
        isAM: false,
        time: '',
      },
      end: { date: nowMoment, isAM: true, time: '' },
    };
  }, []);

  const initFormData = useMemo(() => (isEditMode ? getFormData(editData) : initAddData), [
    editData,
    initAddData,
    isEditMode,
  ]);
  const [form, setForm] = useState(initFormData);

  const isNotChange = useMemo(() => isEqual(initFormData, form), [initFormData, form]);

  const isDisabledSubmit = useMemo(() => {
    if (errors.startTime || errors.endTime || form.start.time === '' || form.end.time === '') return true;
    return isNotChange || !form.start.time || !form.end.time;
  }, [errors, isNotChange, form]);

  const validateDate = newForm => {
    if (errors.startTime || errors.endTime) {
      const startDate = combineDateAndTime(newForm.start);
      const endDate = combineDateAndTime(newForm.end);

      if (!startDate._isValid || !endDate._isValid) {
        return null;
      }

      const isOver24h = endDate.diff(startDate, 'm') > 24 * 60;
      let endError = undefined;
      if (isOver24h || startDate.unix() > endDate.unix()) {
        endError = isOver24h ? END_TIME_ERRORS.OVER_24_HOURS : END_TIME_ERRORS.START_TIME_GREATER_END_TIME;
      }
      setErrors({
        ...errors,
        endTime: endError,
      });
    }
  };

  const handleStartChangeDate = date => {
    const newForm = { ...form, start: { ...form.start, date } };
    setForm(newForm);
    setIsShowStartCalendar(false);
    validateDate(newForm);
  };

  const handleEndChangeDate = date => {
    const newForm = { ...form, end: { ...form.end, date } };
    setForm(newForm);
    setIsShowEndCalendar(false);
    validateDate(newForm);
  };

  const validateTimeForm = newS => {
    const today = moment().unix();
    const startDate = combineDateAndTime(newS.start);
    const endDate = combineDateAndTime(newS.end);

    let startTime = errors.start;
    let endTime = errors.end;
    if (startDate.unix() > today) {
      startTime = END_TIME_ERRORS.OVER_CURRENT_TIME;
    }
    if (endDate.unix() > today) {
      endTime = END_TIME_ERRORS.OVER_CURRENT_TIME;
    }
    setErrors({ startTime, endTime });
  };

  const handleChangeStartTime = ({ time, isAMTime }) => {
    const newS = {
      ...form,
      start: {
        ...form.start,
        time: time === undefined ? form.start.time : time,
        isAM: isAMTime === undefined ? form.start.isAM : isAMTime,
      },
    };
    setForm(() => newS);
    setErrMessage('');
    validateTimeForm(newS);
  };

  const handleChangeEndTime = ({ time, isAMTime }) => {
    const newS = {
      ...form,
      end: {
        ...form.end,
        time: time === undefined ? form.end.time : time,
        isAM: isAMTime === undefined ? form.end.isAM : isAMTime,
      },
    };
    setForm(() => newS);
    setErrMessage('');
    validateTimeForm(newS);
  };

  const handleStartTimeErrors = ({ time }) => {
    setErrors(s => ({ ...s, startTime: time }));
  };

  const handleEndTimeErrors = ({ time }) => {
    setErrors(s => ({ ...s, endTime: time }));
  };

  const combineDateAndTime = item => {
    const { hour, minute } = getTimeFromInput(item.time, true);
    const timeFormat = `h:mm ${item.isAM ? 'AM' : 'PM'}`;
    const finalHour = +hour < 12 && !item.isAM ? +hour + 12 : +hour;
    const dateString = item.date.format('MMM DD, YYYY') + ` ${finalHour}:${minute} ${item.isAM ? 'AM' : 'PM'}`;

    return moment(dateString, `MMM DD, YYYY ${timeFormat}`);
  };

  const getDateTime = () => {
    const startDate = combineDateAndTime(form.start);
    const endDate = combineDateAndTime(form.end);

    if (!startDate._isValid || !endDate._isValid) {
      setErrors({
        startTime: startDate._isValid ? null : METRIC_UNIT_ERRORS.TIME,
        endTime: endDate._isValid ? null : METRIC_UNIT_ERRORS.TIME,
      });
      return null;
    }

    const isOver24h = endDate.diff(startDate, 'm') > 24 * 60;
    if (isOver24h || startDate.unix() >= endDate.unix()) {
      setErrors({
        startTime: null,
        endTime: isOver24h ? END_TIME_ERRORS.OVER_24_HOURS : END_TIME_ERRORS.START_TIME_GREATER_END_TIME,
      });
      return null;
    }
    const format = 'YYYY-MM-DD HH:mm';
    return {
      start_time: startDate.format(format),
      end_time: endDate.format(format),
    };
  };
  const handleSave = () => {
    if (isShowStartCalendar || isShowEndCalendar) {
      closeCalendar();
    }

    const newData = getDateTime();
    if (newData) {
      if (isEditMode) {
        newData.entryId = editData._id;
      }
      onSave(newData);
      onClose();
    }
  };

  const handleClose = () => {
    if (isOpenDisCard) return;

    if (isShowStartCalendar || isShowEndCalendar) {
      closeCalendar();
      return;
    }

    if (!isNotChange) {
      return handleDiscardChange();
    }

    onClose();
  };

  const handleDiscardChange = () => {
    setIsOpenDisCard(true);
    toggleConfirmModal(
      true,
      <CustomConfirmModal
        noBorder
        title="Discard Changes?"
        content={`Are you sure you want to go? Changes have not been saved yet.`}
        onConfirm={handleConfirmDiscardChange}
        onDeny={handleDenyDiscardChange}
        onClose={handleDenyDiscardChange}
        confirmButtonTitle="Discard changes"
        hasCloseIcon
        headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        className="add-result-discard-change"
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  const handleConfirmDiscardChange = () => {
    onClose();
    setTimeout(() => {
      setIsOpenDisCard(false);
    }, 300);
  };

  const handleDenyDiscardChange = () => {
    setTimeout(() => {
      setIsOpenDisCard(false);
    }, 300);
  };

  const handleToggleCalendar = (isStart = true) => () => {
    if (!isShowStartCalendar || !isShowEndCalendar) {
      const contentId = isEditMode ? `edit_result_wrapper_${editData._id}` : `add_result_wrapper`;
      const popupContentElement = document.getElementById(contentId);

      if (popupContentElement) {
        const dateInputElement = popupContentElement.querySelector('.date-input');
        const rdtPicker = popupContentElement.querySelector('.rdtPicker');

        if (dateInputElement && rdtPicker) {
          const dataInputRect = dateInputElement.getBoundingClientRect();

          if (dataInputRect.top < 330) {
            rdtPicker.style.top = '40px';
          }

          if (window.innerWidth - dataInputRect.right < 370 && isEditMode) {
            rdtPicker.style.transform = 'unset';
            rdtPicker.style.left = 'unset';
            rdtPicker.style.right = '0px';
          }
        }
      }
    }

    setIsShowStartCalendar(isStart ? show => !show : false);
    setIsShowEndCalendar(!isStart ? show => !show : false);
    return;
  };

  const closeCalendar = () => {
    setIsShowStartCalendar(false);
    setIsShowEndCalendar(false);
  };

  const renderDateTime = (label, data, errorKey) => {
    const isStart = label === 'Start';
    const { date, time, isAM } = data;

    return (
      <div
        className={classNames({
          'start-time-wrapper': isStart,
          'end-time-wrapper': !isStart,
          'wrap-error': !!errors[errorKey],
        })}
      >
        <div className="result-input-title">{label}</div>
        <div className="datetime-wrapper">
          <DatePicker
            clientTimezone={deviceTz}
            value={date || ''}
            format="MMM D, YYYY"
            onChange={isStart ? handleStartChangeDate : handleEndChangeDate}
            className="date-input"
            isShowArrowBtn={false}
            closeOnClickOutside={true}
            isOpen={isStart ? isShowStartCalendar : isShowEndCalendar}
            onToggleCalendar={handleToggleCalendar(isStart)}
            maxDate={moment()}
            hideNextMonth={true}
            allowDetectElmOutTop
          />
          <div className={classNames('time-input', { 'wrap-time-error': !!errors[errorKey] })}>
            <TimeInput
              isError={!!errors[errorKey]}
              value={time || ''}
              isAM={isAM}
              className="sleep-goal-time"
              onChange={isStart ? handleChangeStartTime : handleChangeEndTime}
              setErrors={isStart ? handleStartTimeErrors : handleEndTimeErrors}
            />
          </div>
        </div>
        {!!errors[errorKey] && (
          <ErrorMessage marginTop="8px" className="value-error">
            {errors[errorKey]}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderContent = ({ newUI = false } = {}) => {
    let contentId = 'add_result_wrapper';
    if (isEditMode) {
      contentId = `edit_result_wrapper_${editData._id}`;
    }

    return (
      <RootCloseWrapper event="click" disabled={isOpenDisCard} onRootClose={handleClose}>
        <S.Wrapper
          className={classNames({
            'add-result-popup': !isEditMode,
            'edit-result-popup': isEditMode,
            error: !isEmpty(errors),
            default: !newUI,
          })}
          style={style}
          id={contentId}
        >
          <div className="content">
            <h3>{!isEmpty(editData) ? 'Edit' : 'Add'} Asleep Time</h3>
            {renderDateTime('Start', form.start, 'startTime')}
            {renderDateTime('End', form.end, 'endTime')}
            {errMessage && <div className="value-error">{errMessage}</div>}
          </div>
          <div className="footer">
            <Button className="btn-cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button purple className="btn-save" onClick={handleSave} disabled={isDisabledSubmit}>
              Save
            </Button>
          </div>
        </S.Wrapper>
      </RootCloseWrapper>
    );
  };

  if (newUI && isEditMode && trigger) {
    return (
      <S.CustomPopup
        trigger={trigger}
        flowing={true}
        hoverable={true}
        on="click"
        position={positionEdit}
        open={isOpen}
        onOpen={onOpen}
        onClose={() => {}}
        className="edit-result-action-popup"
        keepInViewPort={true}
        basic={true}
      >
        {renderContent({ newUI: true })}
      </S.CustomPopup>
    );
  }

  if (newUI && !isEditMode && trigger) {
    return (
      <S.CustomPopup
        trigger={trigger}
        flowing={true}
        hoverable={true}
        on="click"
        position="top left"
        open={isOpen}
        onOpen={onOpen}
        onClose={() => {}}
        className="add-result-action-popup"
        keepInViewPort={true}
        basic={true}
      >
        {renderContent({ newUI: true })}
      </S.CustomPopup>
    );
  }

  return renderContent();
}

const mapStateToProps = state => {
  const { isConfirmModalOpen = false } = state;

  return {
    isConfirmModalOpen,
  };
};

const actionCreators = {
  toggleConfirmModal,
};

export default connect(mapStateToProps, actionCreators)(ResultPopupSpecialChart);
