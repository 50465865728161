import styled, { css } from 'styled-components';
import { ellipsis } from 'polished';

export const GalleryItem = styled.a`
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;

  .image-with-fallback {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    min-width: unset;
    min-height: unset;
  }

  ${props =>
    props.more &&
    css`
      ::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }

      ::after {
        content: ${props => `'+ ${props.more}'`};
        background-color: transparent;
        color: #fff;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

export const Gallery = styled.div`
  width: 400px;
  height: 315px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: ${props => `repeat(${props.num > 1 ? 2 : 1}, auto)`};
  grid-template-rows: ${props => `repeat(${props.num > 2 ? 2 : 1}, auto)`};

  .gallery__item {
    :nth-child(1) {
      grid-column: 1/2;
      grid-row: ${props => (props.num === 3 ? '1/3' : '1/2')};
    }

    :nth-child(2) {
      grid-column: 2/3;
      grid-row: 1/2;
    }

    :nth-child(3) {
      grid-column: ${props => (props.num > 3 ? '1/2' : '2/3')};
      grid-row: 2/3;
    }

    :nth-child(4) {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }
`;

export const Caption = styled.div`
  ${({ showMore }) => showMore && ellipsis(undefined, 4)}
  word-break: break-word;
  white-space: pre-line;
`;

export const Like = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;

  .name {
    font-weight: bold;
  }
`;

export const Header = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding: 15px 15px 5px;

  .created-at {
    opacity: 0.4;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 8px;
      margin-bottom: 2px;
    }
  }
`;

export const Body = styled.div`
  padding: 0 15px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #000000;

  > * {
    margin-bottom: 15px;
  }
`;

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #dde2ed;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .interactions {
    padding: 5px 15px;
  }
`;

export const ShowMoreText = styled.a`
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #828282;

  &:hover {
    text-decoration: none !important;
    cursor: pointer;
  }
`;

export const AvatarContainer = styled.div`
  display: block;
  position: relative;
  width: ${props => (props.twoAvatars ? '35px' : '')};
  margin-right: 5px;
  height: 20px;
  > * {
    &:nth-child(2) {
      img,
      .sb-avatar__text {
        position: absolute;
        left: 15px;
        top: 0;
        display: inline-block;
        box-shadow: 0 0 0 3px white;
      }
    }
  }
`;
