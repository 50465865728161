import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const ChartHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 10px;
  box-shadow: inset 0px 1px 0px #ebedf4;
  margin-bottom: 25px;

  .daily-goal,
  .daily-average {
    padding: 5px 0;
    min-height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 60px;

    .title {
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #ff4c14;
      margin-bottom: 12px;
    }

    .value {
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
      color: #8f91a9;
      vertical-align: text-bottom;

      > span {
        :not(.set-target) {
          :first-child {
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            color: #202353;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .daily-goal {
    .title {
      color: #202353;
    }

    svg {
      path {
        fill: rgba(32, 35, 83, 0.2);
      }

      margin-left: 10px;

      :hover {
        cursor: pointer;

        path {
          fill: #5158cf;
        }
      }
    }

    .set-target {
      font-weight: normal;
      font-size: 13px;
      line-height: 100%;
      color: #8f91a9;

      svg {
        margin-left: 10px;
      }

      :hover {
        cursor: pointer;
        color: #5158cf;

        svg {
          path {
            fill: #5158cf;
          }
        }
      }
    }
  }

  .evf-dropdown {
    margin-right: 5px;
  }

  .evf-dropdown__menu {
    width: 120px;
  }

  .mode__trigger {
    background: #f8f8f8;
    border-radius: 3px;
    padding: 8px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #202353;

    .value {
      font-weight: bold;
      margin-right: 15px;
    }

    :hover {
      cursor: pointer;
    }
  }

  .time-range {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1;
    flex-flow: row wrap;
  }
`;

export const ModalWrapper = styled(Modal)`
  .stepGoal__inputValue {
    position: relative;

    &--addon {
      font-size: 13px;
      line-height: 18px;
      color: #202353;
      opacity: 0.4;
      position: absolute;
      top: 9px;
      right: 20px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &.ui.modal.modal--set-step-goal {
    width: 394px;

    > .content {
      padding: 0 !important;
      border-radius: 8px !important;
    }

    .modal__header {
      padding-bottom: 20px;
    }

    .modal__content {
      .form-group {
        margin-bottom: 0;

        label {
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
          text-transform: uppercase;
          color: #6a778a;
        }
      }
    }
  }
`;
