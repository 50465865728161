import React from 'react';
import _ from 'lodash';
import { pluralize, timeSince } from 'utils/commonFunction';
import { WorkoutNameCell, WorkoutCell, RowItemPopup, SReactTooltip, TooltipTitle } from '../style';
import { SECTION_FORMAT_KEY } from 'constants/commonData';

const renderExerciseCount = item => {
  let exercisesCount = _.sumBy(item.sections, section => {
    if (section) {
      return section.format === SECTION_FORMAT_KEY.FREESTYLE
        ? section.exercise_references.length
        : _.sumBy(section.exercises, set => (set.supersets ? set.supersets.length : 0));
    }

    return 0;
  });
  return (
    <span>
      {exercisesCount} {pluralize('exercise', exercisesCount)}
    </span>
  );
};

const renderTooltop = (text, section, totalEx) => {
  return (
    <React.Fragment key={section._id}>
      <div className="section-count-exercise" data-tip data-for={`section-${section._id}`}>
        {text}
      </div>
      {totalEx > 0 && (
        <SReactTooltip
          className="app-tooltip"
          id={`section-${section._id}`}
          effect="solid"
          place={'top'}
          delayShow={500}
        >
          {section.exercise_references.length > 0 ? <TooltipTitle>Exercise Reference</TooltipTitle> : ''}
          <RowItemPopup>
            {_.map(section.exercise_references, (set, id) => {
              let list = [];
              list.push(<li key={id}>{set.title}</li>);

              return list;
            })}
            {_.map(section.exercises, set => {
              let list = [];
              _.forEach(set.supersets, (ex, idxEx) => {
                list.push(<li key={ex._id}>{_.get(ex, 'exercise_instance.title', '')}</li>);
              });

              return list;
            })}
          </RowItemPopup>
        </SReactTooltip>
      )}
    </React.Fragment>
  );
};

const renderWorkoutItemInfo = item => {
  const { FREESTYLE } = SECTION_FORMAT_KEY;
  let views = [];
  let totalEx = 0;

  _.forEach(item.sections, (section, index) => {
    if (section) {
      let text = '';
      if (section.type === 'hidden') {
        section.exercises[0].supersets.forEach((ex, idxEx, arrayEx) => {
          text += _.get(ex, 'exercise_instance.title', '');
          if (idxEx !== arrayEx.length - 1) {
            text += ', ';
          }
        });
        views.push(
          <span className="workoutName__exerciseName" key={section._id}>
            {text}
          </span>,
        );
      } else {
        if (section.format === FREESTYLE) {
          totalEx = section.exercise_references.length;
        } else {
          totalEx = _.sumBy(section.exercises, set => set.supersets.length);
        }

        text = `${section.title || ''}${totalEx > 0 ? ` (${totalEx})` : ``}`;

        views.push(renderTooltop(text, section, totalEx));
      }
      if (index < item.sections.length - 1) {
        views.push(<span key={index}>&nbsp;/&nbsp;</span>);
      }
    }
  });

  return views;
};

const renderTagsColumn = (tags, onSelectTag) => {
  if (!tags.length) return <span>--</span>;

  return (
    <div className="tags-container">
      {_.map(tags, tag => {
        return tag ? (
          <span className="tag-name" key={tag._id} onClick={e => onSelectTag(e, tag)}>
            {tag.name}
          </span>
        ) : null;
      })}
    </div>
  );
};

export default [
  {
    key: 'title',
    label: 'Workouts',
    customCell: (value, doc, renderCheckbox) => {
      return (
        <WorkoutNameCell key="last_interacted">
          {renderCheckbox && renderCheckbox}
          <div className="workoutContent">
            <div className="workoutName__nameContent">
              <div className="workoutName__name">{doc.title}</div>
            </div>
            {doc.description && <div className="workoutName__description">{doc.description}</div>}
            <div className="workoutName__info">{renderWorkoutItemInfo(doc)}</div>
          </div>
        </WorkoutNameCell>
      );
    },
  },
  {
    key: 'tags',
    label: 'Tags',
    customCell: (value, doc, renderCheckbox, onSelectTag) => {
      return (
        <WorkoutCell key="tags" className="tags" alignCenter>
          {renderTagsColumn(value, onSelectTag)}
        </WorkoutCell>
      );
    },
  },
  {
    key: 'exercise',
    label: 'Exercises',
    customCell: (value, doc) => {
      return <WorkoutCell key="exercises">{renderExerciseCount(doc)}</WorkoutCell>;
    },
  },
  {
    key: 'last_interacted',
    label: 'Most Rencent',
    customCell: (value, doc) => {
      return (
        <WorkoutCell key="most-recent" alignCenter className="most-recent">
          {value ? timeSince(new Date(value)) : '--'}
        </WorkoutCell>
      );
    },
  },
  {
    key: 'author.full_name',
    label: 'Owner',
  },
];
