import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { deleteClients } from 'redux/autoflow/client/actions';
import { deleteClients as deleteClientsInterval } from 'redux/autoflowInterval/client/actions';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import * as S from './style';

function RemoveAutoflowClient(props) {
  const { autoflow, clients, multiple, isComplete } = props;
  const client = clients[0] || {};

  return (
    <Modal
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-action-confirm-modal remove-clients"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={`${CDN_URL}/images/new_delete_red.svg`} />
            <span>Remove {multiple ? 'selected clients' : '1 client'}?</span>
          </S.Header>
          <S.Body>
            <div>
              {multiple ? (
                clients.length !== 1 ? (
                  <span>
                    These <b>{clients.length} clients</b>
                  </span>
                ) : (
                  <span>
                    This <b>1 client</b>
                  </span>
                )
              ) : (
                <b>{client.full_name}</b>
              )}{' '}
              will be removed from the Autoflow completely.
            </div>
            <S.Note className="note">
              <ul>
                {!isComplete && (
                  <li>
                    • Workouts, tasks, and auto-messages from today onwards will be removed for
                    {multiple ? ' each' : ''} client
                  </li>
                )}

                <li>• Client{multiple ? 's' : ''} will be removed from the client list and Autoflow insights.</li>
              </ul>
            </S.Note>
            <div>
              {!isComplete
                ? `Would you like to continue? You may want to instead mark the client${
                    multiple ? 's' : ''
                  } as completed.`
                : 'Would you like to continue?'}
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              delete
              onClick={() => {
                props.deleteClients({ autoflow, clients: _.map(clients, '_id') });
                props.toggleModal(false);
              }}
            >
              Remove
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </Modal>
  );
}

const mapState = state => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;

  return {
    autoflow: workingAutoflow && workingAutoflow._id,
  };
};

const mapDispatch = (dispatch, props) => {
  if (props.type === AUTOFLOW_TYPES.INTERVAL)
    return {
      toggleModal: bindActionCreators(toggleModal, dispatch),
      deleteClients: bindActionCreators(deleteClientsInterval, dispatch),
    };

  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    deleteClients: bindActionCreators(deleteClients, dispatch),
  };
};

export default connect(mapState, mapDispatch)(RemoveAutoflowClient);
