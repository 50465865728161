import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Icon, Popup, Image } from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import TaskForm from './TaskForm';
import ReactTooltip from 'react-tooltip';
import { Content, Container, Wrapper, CellHeader, TaskContainer, CellFooter, ActionsContainer } from './style';
import { AUTOFLOW_TYPES, CDN_URL } from 'constants/commonData';
import RepeatTaskConfirm from 'components/TaskRepeat/ConfirmModal';
import HabitDraggable from 'components/TaskCalendarCell/components/HabitDraggable';
import { CONFIRM_TYPES } from 'components/TaskRepeat/ConfirmModal/constants';
import ConfirmModal from 'shared/ConfirmModal';

const DATE_FORMAT = 'MM-DD-YYYY';
const TASK_CONTAINER_HEIGHT = 57;
const ACTIONS = [
  { key: 'duplicate', label: 'Copy', action: 'copyTask' },
  { key: 'delete', label: 'Delete', action: 'deleteTask' },
];

export default class CalendarCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarCellHeight: 0,
      openingPopup: null,
      isConfirmRemove: false,
      currentTask: {},
    };

    this.calendarCell = null;
    this.directionPopup = this.props.day.format('ddd') == 'Sun' ? 'right bottom' : 'left bottom';
  }

  componentDidMount() {
    const { toggleSideBar } = this.props;
    toggleSideBar && toggleSideBar(false);
    this.updateCalendarCellHeight();
    window.addEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  componentDidUpdate() {
    if (this.calendarCell && this.calendarCell.offsetHeight !== this.state.calendarCellHeight) {
      this.updateCalendarCellHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  updateCalendarCellHeight = () => {
    if (this.calendarCell) {
      this.setState({ calendarCellHeight: this.calendarCell.offsetHeight });
    }
  };

  handlePaste = event => {
    const multiPaste = event.shiftKey;
    const { copying, pasteTask, day, workingAutoflow } = this.props;

    if (copying) {
      const params = {
        id: copying._id,
        autoflow: workingAutoflow._id,
        day: day.format(DATE_FORMAT),
      };

      pasteTask(params, multiPaste);
    }
  };

  getMaximumRenderedTasks = () => {
    const { calendarCellHeight } = this.state;

    // 6: border and margin bottom, 22: footer height
    const outerHeight = 6 + 22;
    // 8: first card not margin top
    const containerHeight = calendarCellHeight + 5;

    let maxRender = parseInt(containerHeight / TASK_CONTAINER_HEIGHT, 10);

    return maxRender;
  };

  toggleRemoveConfirmModal = () => {
    this.setState(state => ({
      isConfirmRemove: !state.isConfirmRemove,
    }));
  };

  handleConfirmRemoveTaskHasRepeat = isOnly => {
    const { currentTask } = this.state;
    const { workingAutoflow, deleteTask } = this.props;
    const params = {
      id: currentTask._id,
      autoflow: workingAutoflow._id,
      is_only_this_task: isOnly,
    };
    deleteTask(params);
    this.toggleRemoveConfirmModal();
  };

  deleteTask = task => {
    const { workingAutoflow, deleteTask } = this.props;
    const params = {
      id: task._id,
      autoflow: workingAutoflow._id,
    };
    if (task.repeat_task_setting_id) {
      this.setState({
        currentTask: task,
      });
      this.toggleRemoveConfirmModal();
    } else {
      this.props.toggleConfirmModal(
        true,
        <ConfirmModal
          title="Delete task"
          content="Are you sure that you want to delete this task?"
          onConfirm={() => deleteTask(params)}
        />,
      );
    }
  };

  copyTask = task => {
    if (task) {
      this.props.copyTask(task);
    } else {
      console.log('Something wrong. Cannot copy task', task);
    }
  };

  onSaveToLibrary = taskId => () => {
    this.setState({ openingPopup: null });
    this.props.saveToLibrary({ taskId: taskId, taskSchema: 'autoflow-task' });
  };

  onPopupToggle = popup => {
    this.onPopupToggleAction(popup);
  };

  onPopupToggleAction(popup) {
    this.resetActions(() => {
      setTimeout(() => this.setState({ openingPopup: popup }), 0);
    });
  }

  resetActions = callback => {
    this.setState({
      openingPopup: null,
    });
    callback && callback();
  };

  renderTaskActions = task => {
    const { day } = this.props;
    const { openingPopup } = this.state;
    const direction = day.format('ddd') == 'Sun' ? 'right bottom' : 'left bottom';

    return (
      <Popup
        trigger={
          <Icon
            color={'grey'}
            name={'ellipsis horizontal'}
            size={'small'}
            className="more-actions-icon"
            onClick={e => e.stopPropagation()}
          />
        }
        on={'click'}
        style={{
          borderRadius: '0',
          padding: '10px 30px 10px 10px',
          borderRadius: '8px',
          background: '#2D2E2D',
          color: '#ffffff',
          marginTop: 0,
        }}
        open={openingPopup === task._id}
        onOpen={() => this.onPopupToggleAction(task._id)}
        onClose={() => this.setState({ openingPopup: null })}
        position={direction}
        basic
        className="action-popup"
      >
        <ActionsContainer>
          <div className="actions__item" onClick={this.onSaveToLibrary(task._id)}>
            <Image src={`${CDN_URL}/images/save.svg`} width={14} />
            <div>Save to library</div>
          </div>
          {_.map(ACTIONS, item => (
            <div
              className="actions__item"
              key={item.key}
              onClick={() => {
                this.setState({ openingPopup: null });
                this[item.action](task);
              }}
            >
              <Image src={`${CDN_URL}/images/${item.key}.svg`} width={14} />
              <div>{item.label}</div>
            </div>
          ))}
        </ActionsContainer>
      </Popup>
    );
  };

  renderTask = (task, index) => {
    const { dayId, day, bodymetricTypes, toggleModal, isMoving } = this.props;
    let draggableId = `${task._id};${day.format(DATE_FORMAT)}`;

    if (dayId) {
      draggableId += `;${dayId}`;
    }

    const indexId = `${task._id};${index}`;
    const taskIdString = `task-title-${task._id}`;
    let metricsName = task.schedule_reminder ? task.reminder_time : '';

    if (task.type === 'body_metric') {
      let listMetricsData = task.metrics.reduce((listMetrics, metricId) => {
        const metricData = bodymetricTypes.find(item => item._id === metricId && item.selected);
        if (metricData) {
          listMetrics.push(metricData.name);
        }
        return listMetrics;
      }, []);

      let metricText = listMetricsData.join(', ');

      if (task.schedule_reminder && metricText) metricsName += ' | ';

      metricsName += metricText;
    }

    if (task.repeat_task_setting_id) {
      draggableId += `${draggableId};isRepeating`;
    }

    return (
      <Draggable key={draggableId + task._id} draggableId={draggableId} index={index} isDragDisabled={isMoving}>
        {provided => (
          <TaskContainer
            className="task__container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() =>
              toggleModal(true, <TaskForm task={task} mode={'edit'} autoflowType={AUTOFLOW_TYPES.EXACT_DATE} />)
            }
          >
            <Image src={`${CDN_URL}/images/new_task_icon_${task.type}.svg`} width={22} className="task__icon" />
            <div className="task__title" data-tip data-for={taskIdString}>
              <div className="title">{task.title}</div>
              <div className="description">{metricsName}</div>
            </div>
            {this.renderTaskActions(task)}
            <ReactTooltip className="app-tooltip" id={taskIdString} effect="solid" place={'top'} delayShow={500}>
              <span>{task.title ? task.title : ''}</span>
            </ReactTooltip>
          </TaskContainer>
        )}
      </Draggable>
    );
  };

  renderCellContent = () => {
    const { day, tasks, dayId, copying, habits } = this.props;
    const { isViewMore } = this.state;
    let droppableId = day.format(DATE_FORMAT);
    let className = '';

    if (copying) {
      className += 'copying';
    }

    if (!tasks.length && !habits.length) {
      className += ' no-tasks';
    }

    if (dayId) {
      droppableId += `;${dayId}`;
    }

    const views = [];
    const maxRendered = this.getMaximumRenderedTasks();

    for (let i = 0; i < tasks.length && (isViewMore || i < maxRendered); i++) {
      const task = tasks[i];

      if (!task) {
        continue;
      }

      views.push(this.renderTask(task, i));
    }

    // logic render habits
    const habitViews = [];
    for (let idx = 0; idx < habits.length && (isViewMore || idx < maxRendered - views.length); idx++) {
      if (_.isEmpty(habits[idx])) {
        continue;
      }
      habitViews.push(
        <HabitDraggable
          key={`habit-${habits[idx]._id}-${idx + views.length}-${day}`}
          habit={habits[idx]}
          directionPopup={this.directionPopup}
          day={day}
          openingPopup={this.state.openingPopup}
          onPopupToggle={this.onPopupToggle}
          isDragging={this.props.isDragging}
          autoflow={AUTOFLOW_TYPES.EXACT_DATE}
        />,
      );
    }

    return (
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <Content
            ref={ref => {
              provided.innerRef(ref);
              this.calendarCell = ref;
            }}
            className={className}
          >
            {views}
            {habitViews}
            {this.renderFooter()}
            {provided.placeholder}
          </Content>
        )}
      </Droppable>
    );
  };

  renderFooter = () => {
    const { tasks, copying, habits } = this.props;
    const { isViewMore } = this.state;

    if (isViewMore) {
      return null;
    }

    const maxRendered = this.getMaximumRenderedTasks();
    const remainTasks = tasks.length + habits.length - maxRendered;
    return (
      <CellFooter>
        {remainTasks > 0 && (
          <div className="more-tasks" onClick={() => this.setState({ isViewMore: true })}>
            + {remainTasks} more task{remainTasks > 1 ? 's' : ''}
          </div>
        )}
        {!!copying && (
          <div className="paste" onClick={this.handlePaste}>
            Paste
          </div>
        )}
      </CellFooter>
    );
  };

  renderCellHeader = () => {
    const { day, toggleModal } = this.props;
    const { isViewMore } = this.state;
    const isToday = !isViewMore && day.isSame(moment(), 'day');

    return (
      <CellHeader className="cell__header">
        <div className={isToday ? 'day today' : 'day'}>{day.format(isViewMore ? 'MMM DD' : 'DD')}</div>
        <div className="add-task-button">
          <div
            className="plus-icon"
            onClick={() =>
              toggleModal(true, <TaskForm date={day.format()} mode="create" autoflowType={AUTOFLOW_TYPES.EXACT_DATE} />)
            }
          />
        </div>
      </CellHeader>
    );
  };

  render() {
    const { isViewMore, isConfirmRemove } = this.state;

    return (
      <Wrapper className={`calendar__cell${isViewMore ? ' view-more' : ''}`}>
        <Container className="calendar-cell__container">
          {this.renderCellHeader()}
          {isViewMore && (
            <Image
              className="btntask-close-over"
              src={`${CDN_URL}/images/close_circle.svg`}
              onClick={() => this.setState({ isViewMore: false })}
              width={16}
              height={16}
            />
          )}
          {this.renderCellContent()}
        </Container>
        {isConfirmRemove && (
          <RepeatTaskConfirm
            title="Remove a Repeating Task"
            isRemove={true}
            isTask={true}
            type={CONFIRM_TYPES.TASK}
            message="You are removing a repeating task. Do you want to remove this and all upcoming occurences of this task, or only the selected task?"
            onClose={this.toggleRemoveConfirmModal}
            onConfirm={this.handleConfirmRemoveTaskHasRepeat}
          />
        )}
      </Wrapper>
    );
  }
}
