import React from 'react';
import styled from 'styled-components';
import ExerciseMediaPreview from 'shared/ExerciseMediaPreview';

export default props => {
  const { data, index } = props;

  const onSaveExercise = (newData) => {
    props.onSaveExercise({ oldExerciseId: data._id, newExercise: newData.exercise });
  };

  return (
    <Wrapper className="alternateExercise__item">
      <ExerciseMediaPreview
        size={{ width: 30, height: 30 }}
        indicatorSize={{ width: 10, height: 10 }}
        exercise={data}
        onSaveExercise={onSaveExercise}
      />
      <div className="index">{index + 1}.</div>
      <div className="title">{data.title}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 9px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #202353;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .index {
    color: #979797;
    margin-right: 3px;
  }

  .media-preview {
    margin-right: 10px;
  }

  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1;
  }
`;
