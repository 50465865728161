import { fromJS } from 'immutable';
import { Types } from './actions';
import { PACKAGE_SORT_BY, SORT_ENUM } from 'constants/commonData';

export const PAGE_SIZE_PACKAGE = 50;

const INITIAL_STATE = fromJS({
  list: [],
  total: 0,
  firstLoad: true,
  counter: {},
  counterMP: {},
  allPackage: 0,
  query: {
    page: 1,
    pageSize: PAGE_SIZE_PACKAGE,
    sort: PACKAGE_SORT_BY.MOST_RECENT,
    order: SORT_ENUM.DESCENDING,
    q: '',
    status: '',
  },
  queryMP: {
    page: 1,
    pageSize: PAGE_SIZE_PACKAGE,
    sort: PACKAGE_SORT_BY.MOST_RECENT,
    order: SORT_ENUM.DESCENDING,
    q: '',
    status: '',
  },
  loadingPackages: false,
  loading: false,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.PAYMENT_COACH_CHANGE_QUERY_PARAMS: {
      return state.merge({ query: fromJS(payload), loadingPackages: true, list: [] });
    }
    case Types.PAYMENT_COACH_CHANGE_QUERY_PARAMS_MARKETPLACE: {
      return state.merge({ queryMP: fromJS(payload), loadingPackages: true, list: [] });
    }
    case Types.PAYMENT_COUNT_PACKAGES_REQUEST: {
      return state.merge({
        loading: true,
      });
    }

    case Types.PAYMENT_COACH_GET_PACKAGE_LIST_SUCCESSFULLY: {
      return state.merge({
        list: fromJS(payload.list),
        total: payload.total,
        loadingPackages: false,
      });
    }

    case Types.PAYMENT_COACH_GET_PACKAGE_LIST_ERROR: {
      return state.merge({
        loadingPackages: false,
      });
    }

    case Types.PAYMENT_COUNT_PACKAGES: {
      return state.merge({ counter: fromJS(payload), loading: false, allPackage: fromJS(payload.all) });
    }

    case Types.PAYMENT_COUNT_MARKETPLACE_PACKAGES: {
      return state.merge({ counterMP: fromJS(payload), loading: false, allPackage: fromJS(payload.all) });
    }

    case Types.PAYMENT_COACH_UPDATE_COUNTER_REDUX: {
      const total = state.get('total');

      return state.merge({ total: fromJS(total + 1) });
    }

    case Types.PAYMENT_RESET_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
