import React from 'react';
import useEscKeyPress from 'hooks/useEscKeyPress';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const ConfirmRemoveMetric = props => {
  const { onConfirm, onClose, metricName } = props;

  useEscKeyPress(() => {
    onClose();
  });

  return (
    <S.CustomConfirmModal
      open
      closeOnDimmerClick={false}
      className="remove-metric-modal"
      title="Remove Metric"
      content={
        <span>
          Are you sure that you want to remove <b>”{metricName}”</b> metric from this group?
        </span>
      }
      onConfirm={onConfirm}
      headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
      confirmButtonTitle="Remove"
      noBorder
      hasCloseIcon
      isPressEsc={true}
    />
  );
};

export default ConfirmRemoveMetric;
