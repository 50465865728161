import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { axiosInstance } from 'configs/request';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import get from 'lodash/get';
import map from 'lodash/map';
import last from 'lodash/last';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';

import LoadingIndicator from 'shared/LoadingIndicator';
import ProgramOption from '../CustomOptions/ProgramOption';
import ForumOption from '../CustomOptions/ForumOption';
import AutoflowOption from '../CustomOptions/AutoflowOption';
import { ASSET_TYPES, MENU_HEIGHT, EMPTY_OPTION_TRIGGER } from '../../constants';
import { autoflowTypeParse, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { AUTOFLOW_STATUS, CDN_URL, TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { handleTriggerScroll } from 'components/OnboardingFlowDetail/constants/helper';
import ForumTriggerOption from '../CustomOptions/ForumTriggerOption';
import { UpgradePathIconTextButton } from 'shared/TriggerForms';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_down_outline.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-onboarding-asset.svg';
import { ReactComponent as AutoflowSetting } from 'assets/icons/asset-autoflow-setting-icon.svg';

import * as S from './style';

const PER_PAGE = 20;

function AssetSelect({
  type = '',
  url = '',
  value = null,
  params,
  disabled = false,
  className = '',
  classNamePrefix = '',
  placeholder = '',
  onSelect,
  cloudfrontList,
  onOpen,
  idSelect = '',
  isEnableTrigger = false,
  isHasPermissionUseTrigger,
}) {
  const [options, setOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [firstTotal, setFirstTotal] = useState(0);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [menuOwnerPlacement, setMenuOwnerPlacement] = useState('auto');

  const initClearable = useMemo(() => {
    if ([ASSET_TYPES.PROGRAM_TRIGGER_FORMS, ASSET_TYPES.FORUM_TRIGGER_FORMS].includes(type) && !!value) {
      if (EMPTY_OPTION_TRIGGER._id === value._id && EMPTY_OPTION_TRIGGER.label === value.label) {
        return false;
      }
    }

    return true;
  }, [type, value]);

  const [isClearable, setIsClearable] = useState(initClearable);

  const query = useRef({
    page: 1,
    per_page: PER_PAGE,
    search: '',
  });

  const selectRef = useRef();
  const isLoading = useRef(false);
  const isEndLoadGroups = useRef(false);

  // Initialize
  useEffect(() => {
    if (!url || isEndLoadGroups.current) return;

    isLoading.current = true;
    setIsLoadingState(true);
    axiosInstance
      .get(url, {
        params: {
          ...query.current,
          ...params,
        },
      })
      .then(response => {
        const { data = [], total } = response.data;
        isLoading.current = false;
        isEndLoadGroups.current = data.length < PER_PAGE || total === PER_PAGE;
        setIsLoadingState(false);
        setTotal(total);
        setFirstTotal(total);
        if (data) {
          const newData = formatDataByType(data);
          setOptions(newData);
        }
      })
      .catch(() => {
        isLoading.current = false;
        setIsLoadingState(false);
      });
  }, []);

  useEffect(() => {
    handleTriggerScroll('.mainOnboardingFlow', handleWindowScroll);
    handleTriggerScroll('.onboarding-flow-container', handleWindowScroll);
  }, []);

  const formatOptions = data => {
    return map(data, item => ({
      ...item,
      key: item._id,
      label: get(item, 'name'),
    }));
  };

  const formatDataByType = data => {
    let newData;
    switch (type) {
      default:
        newData = formatOptions(data);
        break;
    }
    return newData;
  };

  const handleWindowScroll = () => {
    if (selectRef.current) {
      selectRef.current.select.blur();
    }
  };

  const handleScroll = event => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && !isLoading.current && !isEndLoadGroups.current) handleScrollBottom();
  };

  const handleOpenTriggerModal = () => {
    if (typeof onOpen === 'function') {
      if (type === ASSET_TYPES.PROGRAM) {
        onOpen({ type: ASSET_TYPES.PROGRAM_TRIGGER_FORMS });
      } else if (type === ASSET_TYPES.FORUM) {
        onOpen({ type: ASSET_TYPES.FORUM_TRIGGER_FORMS });
      }
    }
  };

  const renderAssignTriggerButton = () => {
    if (!isEnableTrigger) return null;

    if (!isHasPermissionUseTrigger) {
      return <UpgradePathIconTextButton />;
    }

    return <S.AssignTrigger onClick={handleOpenTriggerModal}>Assign using Trigger</S.AssignTrigger>;
  };

  const CustomAssetOption = props => {
    const { data, value, innerRef, innerProps, isSelected } = props;

    return (
      <S.OptionWrapper ref={innerRef} {...innerProps} id={value} isSelected={isSelected}>
        {
          {
            [ASSET_TYPES.PROGRAM]: <ProgramOption data={data} cloudfrontList={cloudfrontList} />,
            [ASSET_TYPES.FORUM]: <ForumOption data={data} />,
            [ASSET_TYPES.AUTOFLOW]: <AutoflowOption data={data} />,
            [ASSET_TYPES.PROGRAM_TRIGGER_FORMS]: <ProgramOption data={data} cloudfrontList={cloudfrontList} />,
            [ASSET_TYPES.FORUM_TRIGGER_FORMS]: <ForumTriggerOption data={data} />,
          }[type]
        }
      </S.OptionWrapper>
    );
  };

  const CustomMenuList = props => {
    const MenuTitle = {
      [ASSET_TYPES.PROGRAM]: 'PROGRAMS',
      [ASSET_TYPES.FORUM]: 'FORUMS',
      [ASSET_TYPES.AUTOFLOW]: 'ACTIVE AUTOFLOWS',
      [ASSET_TYPES.PROGRAM_TRIGGER_FORMS]: 'PROGRAMS',
      [ASSET_TYPES.FORUM_TRIGGER_FORMS]: 'FORUMS',
    };

    const getClassName = (type, menuOwnerPlacement) => {
      return classNames({
        'is-program-and-forum': [
          ASSET_TYPES.PROGRAM,
          ASSET_TYPES.FORUM,
          ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
          ASSET_TYPES.FORUM_TRIGGER_FORMS,
        ].includes(type),
        'bottom-right': [ASSET_TYPES.PROGRAM_TRIGGER_FORMS, ASSET_TYPES.FORUM_TRIGGER_FORMS].includes(type),
        'top-right':
          (type === ASSET_TYPES.PROGRAM_TRIGGER_FORMS || type === ASSET_TYPES.FORUM_TRIGGER_FORMS) &&
          menuOwnerPlacement === 'top',
      });
    };

    return (
      <S.MenuListWrapper className={getClassName(type, menuOwnerPlacement)}>
        <S.MenuList className="select__menu-list" onScroll={handleScroll}>
          <div className="menu-title-wrapper">
            <div className="menu-title">
              {MenuTitle[type]} ({total})
            </div>
            {renderAssignTriggerButton()}
          </div>
          {props.children}
        </S.MenuList>
      </S.MenuListWrapper>
    );
  };

  const CustomSingleValue = ({ data }) => {
    const title = get(data, 'title', '') || get(data, 'label', '') || get(data, 'name', '');
    if (
      get(data, 'is_deleted') ||
      (type === ASSET_TYPES.AUTOFLOW && get(data, 'status') !== AUTOFLOW_STATUS.ACTIVATED)
    ) {
      return (
        <S.ValueWrapper>
          <S.DeletedText className="status-label">{get(data, 'is_deleted') ? 'Deleted' : 'Inactive'}</S.DeletedText>
          <S.ValueTitle hasLabel={true}>{title}</S.ValueTitle>
        </S.ValueWrapper>
      );
    }
    switch (type) {
      case ASSET_TYPES.FORUM:
        const bannerUrl = get(data, 'banner_thumbnail') || get(data, 'banner');
        const banner = bannerUrl
          ? convertS3UrlToCloudFrontUrl(bannerUrl, cloudfrontList, true)
          : `${CDN_URL}/images/default_forum_banner.svg`;

        return (
          <S.HorizontalValueWrapper>
            <S.CoverImage>
              <img src={banner} alt={title} />
            </S.CoverImage>
            <S.ValueTitle>{title}</S.ValueTitle>
          </S.HorizontalValueWrapper>
        );

      case ASSET_TYPES.AUTOFLOW:
        const autoflowType = get(data, 'type', '');
        const isSettingTurnedOff = get(data, 'isSettingTurnedOff', true);
        return (
          <S.ValueWrapper>
            <S.ValueTitle hasAutoflow={autoflowType}>{title}</S.ValueTitle>
            {autoflowType ? (
              <S.AutoflowLabel>
                <S.ValueLabel className="autoflow-type__label" type={autoflowType}>
                  {autoflowTypeParse(autoflowType)}
                </S.ValueLabel>
                {!isSettingTurnedOff && <AutoflowSetting className="autoflow-setting-icon" />}
              </S.AutoflowLabel>
            ) : null}
          </S.ValueWrapper>
        );

      case ASSET_TYPES.FORUM_TRIGGER_FORMS: {
        if (!data._id) {
          return (
            <S.ValueWrapper>
              <S.ValueTitle>{title}</S.ValueTitle>
            </S.ValueWrapper>
          );
        }

        const bannerUrl = get(data, 'banner_thumbnail') || get(data, 'banner');
        const banner = bannerUrl
          ? convertS3UrlToCloudFrontUrl(bannerUrl, cloudfrontList, true)
          : '/images/default_forum_banner.svg';

        return (
          <S.HorizontalValueWrapper>
            <S.CoverImage>
              <img src={banner} alt={title} />
            </S.CoverImage>
            <S.ValueTitle>{title}</S.ValueTitle>
          </S.HorizontalValueWrapper>
        );
      }

      default:
        return (
          <S.ValueWrapper>
            <S.ValueTitle>{title}</S.ValueTitle>
          </S.ValueWrapper>
        );
    }
  };

  const CustomDropdownIndicator = props => {
    if (disabled && !className.includes('disabled')) return null;
    if (props.hasValue && isClearable)
      return (
        <components.DropdownIndicator
          {...props}
          innerProps={{
            ...props.innerProps,
            onMouseDown: e => {
              e.stopPropagation();
              e.preventDefault();
            },
            onTouchEnd: e => {
              e.stopPropagation();
              e.preventDefault();
            },
          }}
        >
          <CloseIcon
            className="close-icon-button"
            onMouseDown={() => {
              selectRef.current.select.onInputChange('');
              props.clearValue();
            }}
            onTouchEnd={() => {
              selectRef.current.select.onInputChange('');
              props.clearValue();
            }}
          />
        </components.DropdownIndicator>
      );

    if ([ASSET_TYPES.PROGRAM_TRIGGER_FORMS, ASSET_TYPES.FORUM_TRIGGER_FORMS].includes(type)) {
      return (
        <ArrowDownIcon
          style={{
            color: '#000000',
            width: 11,
            height: 11,
            marginRight: 0,
          }}
        />
      );
    }

    return <Icon name="chevron down" size="small" />;
  };

  const renderEmptyGroupsList = () => (
    <S.EmptyWrapper className="empty-wrapper">
      {isLoadingState || isLoading.current ? (
        <LoadingIndicator className="group-loading" />
      ) : (
        <S.EmptyMessage>No options</S.EmptyMessage>
      )}
    </S.EmptyWrapper>
  );

  const searchGroups = (inputValue, actionMeta) => {
    const action = (actionMeta || {}).action;
    const shouldRecall = action === 'menu-close' && total !== firstTotal;

    if ((!url || isLoading.current || action !== 'input-change') && !shouldRecall) return;
    setIsLoadingState(true);
    setOptions([]);

    query.current = { ...query.current, search: inputValue, page: 1 };
    axiosInstance
      .get(url, {
        params: { ...query.current, ...params, page: 1, search: inputValue },
      })
      .then(response => {
        const { data = [], total } = response.data;
        setTotal(total);
        if (data) {
          const newData = formatDataByType(data);
          setOptions(newData);
          setIsLoadingState(false);
          isLoading.current = false;
          isEndLoadGroups.current = data.length < PER_PAGE || Math.ceil(total / PER_PAGE) <= get(query, 'current.page');
        }
      })
      .catch(() => {
        setIsLoadingState(false);
        isLoading.current = false;
      });
  };

  // Handle search
  const handleSearchDebounce = debounce((inputValue, actionMeta) => {
    searchGroups(inputValue, actionMeta);
    setTimeout(() => {
      const scrollInput = document.querySelector('.select__menu-list');
      if (scrollInput) {
        scrollInput.scroll({ top: 0, behavior: 'smooth' });
      }
    }, 100);
  }, 300);

  const handleSelected = value => {
    query.current = { ...query.current, page: 1, search: '' };
    isEndLoadGroups.current = false;
    onSelect && onSelect(value, type);

    setTimeout(() => {
      const scrollInput = document.querySelector(`.select-container .${classNamePrefix}__control`);
      if (scrollInput) {
        scrollInput.scroll({ top: scrollInput.clientHeight, behavior: 'smooth' });

        if (selectRef.current) {
          selectRef.current.select.blur();
        }
      }
    }, 100);

    if ([ASSET_TYPES.PROGRAM_TRIGGER_FORMS, ASSET_TYPES.FORUM_TRIGGER_FORMS].includes(type) && !!value) {
      if (EMPTY_OPTION_TRIGGER._id === value._id && EMPTY_OPTION_TRIGGER.label === value.label) {
        isClearable && setIsClearable(false);
      } else {
        !isClearable && setIsClearable(true);
      }
    }
  };

  const handleScrollBottom = debounce(() => {
    if (!url || isLoading.current || isEndLoadGroups.current) return;

    isLoading.current = true;
    query.current = { ...query.current, page: query.current.page + 1 };
    const loadingBox = document.createElement('div');
    loadingBox.classList.add('boxLoading');

    const ringLoading = document.createElement('div');
    const textLoading = document.createElement('div');

    loadingBox.appendChild(ringLoading);

    ringLoading.classList.add('ringLoading');
    const div = document.createElement('div');
    ringLoading.appendChild(div);

    loadingBox.classList.add('textLoading');
    textLoading.innerHTML = 'Loading...';
    loadingBox.appendChild(textLoading);

    const parentData = document.querySelector('.select-container .select__menu-list');
    if (parentData) {
      parentData.appendChild(loadingBox);
      parentData.scrollTop = parentData.scrollHeight;
    }
    axiosInstance
      .get(url, {
        params: { ...query.current, ...params },
      })
      .then(response => {
        const { data = [], total } = response.data;
        setTotal(total);
        if (data) {
          isLoading.current = false;
          setOptions(group =>
            map([...group, ...formatDataByType(data)], item => ({
              ...item,
              key: item._id,
              label: get(item, 'name'),
            })),
          );
          const lastOptionId = get(last(options), '_id');
          const messageElement = document.getElementById(lastOptionId);
          options.length >= 20 && messageElement && messageElement.scrollIntoView({ block: 'center' });
          isEndLoadGroups.current = data.length < PER_PAGE;
          const boxes = document.querySelectorAll('.boxLoading');
          boxes.forEach(box => {
            box.remove();
          });
        }
      });
  }, 300);

  const finalOptions = useMemo(() => {
    const current = value
      ? [
          {
            ...value,
            key: value._id,
            label: get(value, 'name'),
          },
        ]
      : [];

    let emptyOption = [];
    if ([ASSET_TYPES.PROGRAM_TRIGGER_FORMS, ASSET_TYPES.FORUM_TRIGGER_FORMS].includes(type)) {
      emptyOption = [EMPTY_OPTION_TRIGGER];
    }

    return uniqBy([...emptyOption, ...options, ...current], '_id');
  }, [options, value, type]);

  const filterOption = (option = {}, inputValue = '') => {
    const {
      data: { _id },
      label = '',
    } = option;

    if ([ASSET_TYPES.PROGRAM_TRIGGER_FORMS, ASSET_TYPES.FORUM_TRIGGER_FORMS].includes(type)) {
      if (_id === EMPTY_OPTION_TRIGGER._id && EMPTY_OPTION_TRIGGER.label === inputValue.toLocaleLowerCase()) {
        return false;
      }
    }

    return label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const checkPlacementAssetSelect = useCallback(() => {
    const element = document.getElementById(idSelect);
    if (element && typeof element.getBoundingClientRect === 'function') {
      const rect = element.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= MENU_HEIGHT) {
        setMenuOwnerPlacement('top');
      } else {
        const propsMenuPlace = 'auto';
        menuOwnerPlacement !== propsMenuPlace && setMenuOwnerPlacement(propsMenuPlace);
      }
    }
  }, [menuOwnerPlacement]);

  const updateZIndexOfAppBar = isLower => {
    const [appBar] = document.getElementsByClassName('app-navbar');
    if (appBar) {
      appBar.style.zIndex = isLower ? 1 : 999;
    }
  };

  const handleMenuOpen = () => {
    !openMenu && setOpenMenu(true);
    checkPlacementAssetSelect();
  };

  const handleMenuClose = () => {
    openMenu && setOpenMenu(false);
  };

  return (
    <Select
      menuIsOpen={openMenu}
      ref={selectRef}
      isClearable
      isSearchable
      cacheOptions
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: null,
        Option: CustomAssetOption,
        MenuList: CustomMenuList,
        SingleValue: CustomSingleValue,
        LoadingIndicator: null,
      }}
      value={value}
      isDisabled={disabled}
      defaultOptions={finalOptions}
      classNamePrefix={classNamePrefix}
      placeholder={placeholder}
      onChange={handleSelected}
      className={`select-container ${className}`}
      noOptionsMessage={renderEmptyGroupsList}
      onInputChange={(...res) => handleSearchDebounce(...res)}
      options={finalOptions}
      getOptionLabel={option => option.label || option.title}
      getOptionValue={option => option._id}
      styles={customStyles}
      menuPosition="fixed"
      onFocus={() => {
        updateZIndexOfAppBar(true);
      }}
      onBlur={() => {
        updateZIndexOfAppBar(false);
      }}
      menuPlacement={menuOwnerPlacement}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      filterOption={filterOption}
    />
  );
}

const customStyles = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    flex: 1,
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    minHeight: 44,
    height: '100%',
    background:
      get(state, 'selectProps.value.is_deleted') ||
      // (get(state, 'selectProps.value.share') === TEAM_SHARE_PRIVATE &&
      //   get(state, 'selectProps.value.owned_by_trainer')) ||
      (get(state, 'selectProps.classNamePrefix') === 'ofa' &&
        get(state, 'selectProps.value') &&
        get(state, 'selectProps.value.status') !== AUTOFLOW_STATUS.ACTIVATED)
        ? '#EAEDF4'
        : '#FFF',
    borderRadius: '5px',
    border: '1px solid #DADFEA',
    boxShadow: '0px 4px 8px 0px #F1F2F6',
    ':hover': {
      border: '1px solid #5E59FF',
    },
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    padding: '2px 0 2px 15px',
    flexWrap: 'nowrap',
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '18px',
    color: '#000',
    opacity: 0.4,
  }),
  indicatorsContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingRight: '15px',
  }),
  singleValue: (baseStyle, state) => ({
    ...baseStyle,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    color: '#000',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    margin: 0,
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    margin: '3px 0 3px',
    boxShadow: 'none',
  }),
  dropdownIndicator: (baseStyle, state) => ({
    ...baseStyle,
    '.close-icon-button': {
      cursor: 'pointer',
    },
  }),
  noOptionsMessage: (baseStyle, state) => ({
    ...baseStyle,
    width: '100%',
    height: '100%',
    '.empty-wrapper': {
      width: '100%',
      height: '100%',
    },
  }),
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(AssetSelect);
