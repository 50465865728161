export const VERIFY_SESSION_STATUS = {
  CANCELED: 'canceled',
  PROCESSING: 'processing',
  REQUIRES_INPUT: 'requires_input',
  VERIFIED: 'verified',
};

export const TITLE = {
  DEFAULT: 'Verify the Stripe account holder to activate your account for payments',
  VERIFIED: 'Verified identity: ',
};
