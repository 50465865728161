import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: #202353;
`;

export const TotalMacro = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  position: relative;
  cursor: pointer;
  .total-value {
    ${baseText}
    color: #7b7e91;
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    border-bottom: 1px dashed #e0e0e9;
    padding-top: 5px;
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .macro-info {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .macro-info {
      opacity: 1;
      visibility: visible;
    }
    .total-value {
      border-color: #5158cf;
    }
  }
  ${({ isWeekMode }) =>
    isWeekMode &&
    css`
      gap: 5px;
      .total-value {
        display: flex;
        align-items: center;
        gap: 4px;
        height: 27px;
        font-size: 13px;
        line-height: 20px;
        padding-top: unset;
        span {
          font-size: 13px;
          line-height: 20px;
        }
      }
    `}
`;

export const MacroInfo = styled.div`
  position: absolute;
  bottom: calc(100% + 10px);
  transform: translateX(-50%);
  left: 50%;
  display: block;
  background-color: #fff;
  min-width: 249px;
  height: 199px;
  box-shadow: 0px 2px 8px 0px #26262633;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  gap: 25px;
  z-index: 1000;
  .left-panel {
    text-align: center;
  }
  .chart-wrapper {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 5px auto 0;
    .nutrition-chart-icon {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .calories-value {
    ${baseText}
    font-size: 25px;
    line-height: 25px;
    letter-spacing: -1px;
    margin-top: 19px;
    white-space: nowrap;
    .calories-unit {
      font-size: 20px;
      line-height: 20px;
      letter-spacing: -1px;
    }
  }

  .calories-label {
    ${baseText}
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    margin-top: 7px;
    letter-spacing: -1px;
    text-transform: capitalize;
  }

  .list-macro {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 20px;
    .item-macro {
      display: flex;
      flex-direction: column;
      gap: 3px;
      position: relative;
      white-space: nowrap;
      .tick-label {
        position: absolute;
        top: 7px;
        left: -20px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      .label {
        ${baseText}
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding-top: 2px;
        text-transform: capitalize;
      }
      .percent {
        font-weight: 600;
        letter-spacing: -0.05em;
      }
      .percentage {
        font-size: 10px;
        line-height: 15px;
      }
      .value {
        ${baseText}
        font-size: 16px;
        line-height: 15px;
        letter-spacing: -1px;
      }
      .unit {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
`;
