import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { FormGroup, ErrorMessage } from 'shared/FormControl';
import Select from 'react-select';
import { DropdownIndicator } from 'shared/Icons';

const Wrapper = styled(FormGroup)`
  margin-bottom: 20px;

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input,
  textarea {
    height: unset;
  }
`;

export default React.forwardRef(({ title, error, className, inputClass, inputType, components, ...rest }, ref) => {
  const renderInput = () => {
    switch (inputType) {
      case 'select':
        return (
          <Select
            classNamePrefix="evfSelectBox"
            className={classnames('evfSelectBoxContainer', inputClass)}
            components={{ DropdownIndicator, IndicatorSeparator: null, ...components }}
            ref={ref}
            {...rest}
          />
        );

      case 'textarea':
        return <textarea {...rest} className={inputClass} ref={ref} />;

      default:
        return <input {...rest} className={inputClass} ref={ref} />;
    }
  };

  return (
    <Wrapper className={classnames('form-item', className, { error: error })}>
      {title ? <label>{title}</label> : null}
      {renderInput()}
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Wrapper>
  );
});
