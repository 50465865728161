import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Divide = styled.div`
  border-top: 1px solid #e6e9f3;
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;
  overflow: hidden;

  .paymentSummary__heading {
    background: #f9f9fb;
    border-bottom: 1px solid #dadfea;
    padding: 30px 25px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      color: #202353;
    }
  }

  .paymentSummary__body {
    padding-right: 25px;
    padding-left: 25px;
    padding-top: 20px;
  }

  .paymentSummary__selectedPlan {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    padding-top: 0px;

    :last-child {
      border-bottom: 1px dashed #e6e9f3;
    }

    .paymentSummary__planName {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 100%;
      color: #202353;

      &--automation {
        color: #202353;
      }
    }

    &--current {
      font-size: 10px;
      font-weight: 700;
      line-height: 13.62px;
      color: #202353;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      opacity: 0.5;
    }

    &--oldName {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      color: #202353;
    }

    &--oldPrice {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      color: #202353;
      text-align: right;
    }

    &--oldPeriod {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      color: #202353;
    }

    &--newPlan {
      font-size: 10px;
      font-weight: 700;
      line-height: 13.62px;
      color: #f37335;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    &--name {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 100%;
      color: #202353;
    }

    &--oldClient {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      color: #202353;
    }

    &--client {
      color: #202353;
      font-size: 13px;
      font-weight: 700;
      line-height: 18.2px;
      text-align: left;
    }

    &--price {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 100%;
      text-align: right;
      color: #202353;
    }

    &--period {
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #202353;
      padding-top: 5px;
      text-align: right;
    }

    &--addOnsDes {
      margin-top: 4px;
      font-size: 11px;
      line-height: 140%;
      color: #202353;
      max-width: 150px;
      opacity: 0.5;
    }
  }

  .paymentSummary__oneTimeDes {
    margin-right: 25px;
  }

  .paymentSummary__oneTimePrice {
    min-width: 60px;
  }

  .paymentSummary__promoMsg {
    padding-top: 20px;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
  }

  .paymentSummary__waringMsg {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    padding-top: 20px;

    a {
      font-weight: bold;
      color: #6261ff;
    }
  }

  .paymentSummary__totalSumary {
    min-height: 140px;
    padding: 10px 25px;

    .paymentSummary__subTotal {
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;

      &--left-content {
        display: flex;
      }

      &--name {
        font-style: normal;
        font-size: 13px;
        line-height: 100%;
        color: #202353;
      }

      &--price {
        font-style: normal;
        font-size: 15px;
        line-height: 100%;
        text-align: right;
        color: #202353;
      }

      &--credit {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }

  .paymentSummary__creditToAccount {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    margin-top: 10px;
    border-top: 1px dashed #e6e9f3;

    &--leftContent {
      display: flex;
    }

    &--tootipIcon {
      margin-left: 10px;
      background-color: #d7dbe3;
      color: #fff;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      font-weight: 900;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--name {
      font-size: 13px;
      line-height: 100%;
      color: #202353;
    }

    &--price {
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
      text-align: right;
      color: #48c98c;
    }
  }

  .paymentSummary__totalDue {
    display: flex;
    justify-content: space-between;
    margin-right: 25px;
    margin-left: 25px;
    background: #f7f8fc;
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;

    &--name {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: #202353;
    }

    &--day {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 100%;
      margin-top: 4px;
    }

    &--today {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: #202353;
    }

    &--price {
      font-weight: bold;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      color: #202353;
    }
  }

  .__react_component_tooltip.payment-summary-tootip {
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #dfe4e5;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-width: 260px;
    text-align: left !important;
  }

  .paymentSummary__footer {
    text-align: center;
    padding: 25px;

    .paymentSummary__confirmBtn {
      border-radius: 3px;
      padding: 12px 30px;
      border: none;
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
      border: 1px solid #d0d4ed;
      box-sizing: border-box;
      border-radius: 5px;
      background: linear-gradient(100deg, #05b4ff 7.12%, #0085ff 90.44%);

      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      outline: none;

      :hover {
        background: linear-gradient(97.89deg, #05b4ff 7.12%, #00c2ff 90.44%);
      }

      &:disabled {
        background: #ffffff;
        color: #afb4cf;
        :hover {
          background: #ffffff;
        }
      }
    }

    h5 {
      margin-top: 15px;
      font-weight: normal;
      font-size: 11px;
      line-height: 150%;
      text-align: center;
      color: #202353;
    }
  }
`;

export const PromoWrapper = styled.div`
  padding: 0px 25px;

  .promoCode__btnAdd {
    font-size: 12px;
    line-height: 100%;
    text-decoration-line: underline;
    color: #202353;
    opacity: 0.6;
    cursor: pointer;

    :hover {
      opacity: 1;
      color: #5158cf;
      font-weight: 600;
    }
  }

  .promoCode__adding {
    position: relative;
  }

  .promoCode__input {
  }

  .promoCode__btnSubmit {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #5158cf;
    padding: 0;
    background: transparent;

    :disabled {
      color: #a0a3b7;
    }
  }

  .promoCode__removeIcon {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 12px;
    height: 12px;
    background-image: url(${CDN_URL}/images/c_remove.svg);
    transform: translate(50%, -50%);
    background-color: #ffffff;

    :hover {
      background-image: url(${CDN_URL}/images/c_remove_purple.svg);
    }
  }
`;

export const PromoCostWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  .promoCost__code {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #0085ff;
    display: flex;
    align-items: center;

    .promoCost__removeIcon {
      cursor: pointer;
      margin-left: 5px;
      width: 12px;
      height: 12px;
      background-image: url(${CDN_URL}/images/c_remove.svg);
      background-color: #ffffff;

      :hover {
        background-image: url(${CDN_URL}/images/c_remove_purple.svg);
      }
    }
  }

  .promoCost__value {
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
    text-align: right;
    color: #202353;
  }
`;
