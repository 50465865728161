import React from 'react';
import { Grid } from '@giphy/react-components';

// import { GifLoader } from 'shared/Giphy';

function NotFound() {
  return <div className="giphy-not-found">No GIFs found</div>;
}

// function Overlay() {
//   return (
//     <div className="giphy-loader-container">
//       <GifLoader />
//     </div>
//   );
// }

const replaceGifError = () => {
  const parent = document.querySelector('.giphy-grid');
  if (parent) {
    parent.innerHTML = '';
    const child = document.createElement('div');
    child.innerHTML = 'No GIFs found';
    child.className = 'giphy-not-found';
    parent.appendChild(child);
  }
};

function GifGrid(props) {
  const { searchKey, gridWidth, fetchGifs, onGifClick } = props;
  return (
    <>
      <Grid
        key={searchKey}
        columns={3}
        gutter={8}
        borderRadius={8}
        width={gridWidth}
        fetchGifs={fetchGifs}
        onGifClick={onGifClick}
        noLink={false}
        noResultsMessage={<NotFound />}
        hideAttribution={true}
        onGifsFetchError={e => {
          replaceGifError();
        }}
        // backgroundColor="transparent"
        // overlay={Overlay}
      />
    </>
  );
}

export default GifGrid;
