import React from 'react';
import { connect } from 'react-redux';
import * as S from '../style';
import TableHeader from './TableHeader';
import TableContent from './TableContent';
import Indicator from '../Indicator';

function PurchaseTable(props) {
  const { hasPaymentPermission, loading, isMP } = props;
  return (
    <>
      <S.Table>
        <TableHeader isMP={isMP} />
        {!loading && <TableContent isMP={isMP} hasPaymentPermission={hasPaymentPermission} />}
      </S.Table>
      {loading && <Indicator title="Loading purchases" />}
    </>
  );
}

const mapState = state => {
  const {
    rootReducer: { packageAnalytics, permission },
  } = state;

  return {
    loading: packageAnalytics.toJS().loading,
    hasPaymentPermission: permission.payment || false,
  };
};

export default connect(mapState, null)(PurchaseTable);
