import React from 'react';
import { ReactComponent as EmptyUI } from 'assets/images/empty_forms_library.svg';
import * as S from './style';

const EmptyContent = ({ isHideFormBanner = false }) => {
  return (
    <S.EmptyWrapper isHideFormBanner={isHideFormBanner}>
      <EmptyUI />
    </S.EmptyWrapper>
  );
};

export default EmptyContent;
