import styled from 'styled-components';

export const CircleContainer = styled.div`
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    ${props => `
    background-color: ${props.backgroundColor};
    height: ${props.height};
    width: ${props.width};
    `}}
    :hover {
        ${props => `
         background-color: ${props.hoverBackgroundColor};
        .download-icon path {
            stroke: ${props.hoverIconColor};
        }
        `}
    }
    .download-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
            stroke: ${props => props.iconColor};
        }
    }
`;
