import { connect } from 'react-redux';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import Superset from './component';
import { ENTITY_NAME } from 'database/constants';
import { updateEntity, updateEntities } from 'redux/model/actions';
import { ExerciseLibrary, SuperSet } from 'database/schema';
import { addSets, toggleUseORM, updateExerciseLibrary, getExercisesByIds } from 'redux/workout-builder/actions';

const mapStateToProps = (state, ownProps) => {
  const {
    user,
    rootReducer: {
      model,
      workoutBuilder,
      exercise: { fields, categories },
      client: { workingClientDetail },
    },
  } = state;

  return {
    model: denormalize(model.getIn([ENTITY_NAME.Superset, ownProps.supersetId]), SuperSet, model),
    modelExerciseLibrary: denormalize(model.getIn([ENTITY_NAME.ExerciseLibrary]), ExerciseLibrary, model),
    categories,
    systemFields: fields,
    units: _.get(workingClientDetail || user, 'preferences', []),
    showError: workoutBuilder.get('showError'),
  };
};

const actionCreators = {
  updateEntity,
  addSets,
  toggleUseORM,
  updateEntities,
  updateExerciseLibrary,
  getExercisesByIds,
};

export default connect(mapStateToProps, actionCreators)(Superset);
