import { cloneDeep, sortBy } from 'lodash';
import Request from 'configs/request';

import { Mixpanel } from 'utils/mixplanel';
import { toast } from 'react-toastify';

export const Types = {
  GET_WORKOUT_LABELS_REQUEST: 'GET_WORKOUT_LABELS_REQUEST',
  GET_WORKOUT_LABELS_SUCCESS: 'GET_WORKOUT_LABELS_SUCCESS',
  REMOVE_WORKOUT_LABEL_REQUEST: 'REMOVE_WORKOUT_LABEL_REQUEST',
  REMOVE_WORKOUT_LABEL_SUCCESS: 'REMOVE_WORKOUT_LABEL_SUCCESS',
  ADD_NEW_WORKOUT_LABELS_REQUEST: 'ADD_NEW_WORKOUT_LABELS_REQUEST',
  ADD_NEW_WORKOUT_LABELS_SUCCESS: 'ADD_NEW_WORKOUT_LABELS_SUCCESS',
  GET_WORKOUT_LABEL_DETAIL_REQUEST: 'GET_WORKOUT_LABEL_DETAIL_REQUEST',
  GET_WORKOUT_LABEL_DETAIL_SUCCESS: 'GET_WORKOUT_LABEL_DETAIL_SUCCESS',
  GET_WORKOUT_LABEL_DETAIL_FAIL: 'GET_WORKOUT_LABEL_DETAIL_FAIL',
  ADD_NEW_PROPERTY_LABELS_REQUEST: 'ADD_NEW_PROPERTY_LABELS_REQUEST',
  ADD_NEW_PROPERTY_LABELS_SUCCESS: 'ADD_NEW_PROPERTY_LABELS_SUCCESS',
  RESET_WORKOUT_LABEL_DETAIL: 'RESET_WORKOUT_LABEL_DETAIL',
  REMOVE_PROPERTY_LABELS_REQUEST: 'REMOVE_PROPERTY_LABELS_REQUEST',
  REMOVE_PROPERTY_LABELS_SUCCESS: 'REMOVE_PROPERTY_LABELS_SUCCESS',
  EDIT_WORKOUT_LABEL_REQUEST: 'EDIT_WORKOUT_LABEL_REQUEST',
  EDIT_WORKOUT_LABEL_SUCCESS: 'EDIT_WORKOUT_LABEL_SUCCESS',
  EDIT_WORKOUT_LABEL_OPTION_REQUEST: 'EDIT_WORKOUT_LABEL_OPTION_REQUEST',
  EDIT_WORKOUT_LABEL_OPTION_SUCCESS: 'EDIT_WORKOUT_LABEL_OPTION_SUCCESS',
};

const Messages = {
  UPDATE_LABEL: 'This label has been updated successfully.',
  UPDATE_LABEL_OPTION: 'This label option has been updated successfully.',
};

export const getAllLabels = () => {
  return dispatch => {
    dispatch({ type: Types.GET_WORKOUT_LABELS_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/on-demand-workout-labels`,
        },
        true,
        response => {
          const { data } = response.data;
          let clonedLabels = cloneDeep(data);
          let sortedLabels = sortBy(clonedLabels, obj => obj.order);

          dispatch({ type: Types.GET_WORKOUT_LABELS_SUCCESS, payload: { data: sortedLabels } });
        },
      ),
    );
  };
};

export const removeLabelFromList = id => {
  return dispatch => {
    dispatch({ type: Types.REMOVE_WORKOUT_LABEL_REQUEST });
    Mixpanel.track('on_demand_remove_label');

    return dispatch(
      Request.delete({ url: '/api/on-demand-workout-labels/manage-label/remove', data: { labelId: id } }, true, res => {
        dispatch({ type: Types.REMOVE_WORKOUT_LABEL_SUCCESS, payload: { data: id } });
      }),
    );
  };
};

export const addNewAllLabels = data => {
  return dispatch => {
    dispatch({ type: Types.ADD_NEW_WORKOUT_LABELS_REQUEST });
    Mixpanel.track('on_demand_add_label');
    return dispatch(
      Request.post(
        {
          url: `/api/on-demand-workout-labels/manage-label/add`,
          data: data,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.ADD_NEW_WORKOUT_LABELS_SUCCESS, payload: { data: data } });
        },
      ),
    );
  };
};

export const getLabelDetail = id => {
  return dispatch => {
    dispatch({ type: Types.GET_WORKOUT_LABEL_DETAIL_REQUEST });
    return dispatch(
      Request.get(
        {
          url: `/api/on-demand-workout-labels/manage-label/${id}`,
        },
        true,
        response => {
          const { data } = response.data;

          if (data) {
            dispatch({ type: Types.GET_WORKOUT_LABEL_DETAIL_SUCCESS, payload: { data: data } });
          }
        },
        error => {
          dispatch({ type: Types.GET_WORKOUT_LABEL_DETAIL_FAIL });
        },
      ),
    );
  };
};

export const addNewPropertyLabels = data => {
  return dispatch => {
    dispatch({ type: Types.ADD_NEW_PROPERTY_LABELS_REQUEST });
    Mixpanel.track('add_on_demand_label_property');
    return dispatch(
      Request.post(
        {
          url: `/api/on-demand-workout-labels/manage-label/properties/add`,
          data: data,
        },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.ADD_NEW_PROPERTY_LABELS_SUCCESS, payload: { data: data } });
        },
      ),
    );
  };
};

export const removePropertyFromList = id => {
  return dispatch => {
    dispatch({ type: Types.REMOVE_PROPERTY_LABELS_REQUEST });
    Mixpanel.track('remove_on_demand_label_property');

    return dispatch(
      Request.delete(
        { url: '/api/on-demand-workout-labels/manage-label/properties/remove', data: { labelItemId: id } },
        true,
        res => {
          dispatch({ type: Types.REMOVE_PROPERTY_LABELS_SUCCESS, payload: { data: id } });
        },
      ),
    );
  };
};

export const resetSelectedWorkoutLabel = () => {
  return dispatch => {
    dispatch({ type: Types.RESET_WORKOUT_LABEL_DETAIL });
  };
};

export const updateLabelName = data => {
  return dispatch => {
    dispatch({ type: Types.EDIT_WORKOUT_LABEL_REQUEST });

    return dispatch(
      Request.put({ url: '/api/on-demand-workout-labels/manage-label/edit', data }, true, res => {
        dispatch({ type: Types.EDIT_WORKOUT_LABEL_SUCCESS, payload: data });
      }),
      toast(Messages.UPDATE_LABEL),
    );
  };
};

export const updateLabelOptionName = data => {
  return dispatch => {
    dispatch({ type: Types.EDIT_WORKOUT_LABEL_OPTION_REQUEST });

    return dispatch(
      Request.put({ url: '/api/on-demand-workout-labels/manage-label/properties/edit', data }, true, res => {
        dispatch({ type: Types.EDIT_WORKOUT_LABEL_OPTION_SUCCESS, payload: { data } });
      }),
      toast(Messages.UPDATE_LABEL_OPTION),
    );
  };
};
