import React, { Component } from 'react';
import _ from 'lodash';
import { Image } from 'semantic-ui-react';

import { DateTime } from 'luxon';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import ImageButton from 'shared/ImageButton';
import ProgressPhotoWrapper from 'components/ProgressPhotoWrapper';

import { CDN_URL } from 'constants/commonData';

import { ReactComponent as IconCompare } from 'assets/icons/icon-compare.svg';
import { ReactComponent as IconEye } from 'assets/icons/icon-eye.svg';

import './styles.scss';
import { getThumbnailProgressPhoto } from 'components/EditProgressPhoto/helper';

export default class NoteProgressPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listPhoto: [],
    };
  }

  componentDidUpdate() {
    const {
      selectedClient: { _id } = {},
      router: { location },
    } = this.props;

    const query = new URLSearchParams(location.search);
    if (!!_id && query.get('show') === 'progress_photo') {
      this.viewAllProgressPhoto();
    }
  }

  componentDidMount() {
    this.getProgressPhotoList();
  }

  onCloseProgressPhoto = () => {
    const { replaceRouter } = this.props;
    replaceRouter({ search: '' });
    this.getProgressPhotoList();
  };

  getProgressPhotoList() {
    const {
      getProgressPhotoOverviewList,
      selectedClient: { _id = '' },
    } = this.props;
    if (_id) {
      getProgressPhotoOverviewList &&
        getProgressPhotoOverviewList({ number_preview_image: 8, client: _id }).then(res => {
          const {
            data: { data = [] },
          } = res;
          this.setState({ listPhoto: data });
        });
    }
  }

  openCompareMode = () => {
    const { toggleModal, changeMode } = this.props;
    toggleModal(true, <ProgressPhotoWrapper isView={true} onClose={this.onCloseProgressPhoto} />);
    changeMode && changeMode();
    this.updateFilters();
  };

  viewAllProgressPhoto = () => {
    this.props.toggleModal(true, <ProgressPhotoWrapper isView={true} onClose={this.onCloseProgressPhoto} />);
    this.updateFilters();
  };

  handleActivePhoto = (data = {}) => {
    const { selectedPhoto } = this.props;
    selectedPhoto && selectedPhoto(data);
  };

  updateFilters = () => {
    const { updateProgressPhotoFilters, firstLoad, selectedClient } = this.props;
    updateProgressPhotoFilters &&
      updateProgressPhotoFilters(false, {
        day: selectedClient.timezone
          ? DateTime.now().setZone(selectedClient.timezone).toFormat('MM-dd-yyyy')
          : DateTime.now().toFormat('MM-dd-yyyy'),
      });
    firstLoad && firstLoad();
  };

  render() {
    const { listPhoto } = this.state;
    const { date_format } = this.props;
    const dateFirst = date_format ? date_format.slice(0, 2) : 'MM';
    const dateLast = date_format ? date_format.slice(3, 5) : 'DD';
    const dateFormat = `${dateFirst === 'DD' ? _.lowerCase(dateFirst) : dateFirst}/${
      dateLast === 'DD' ? _.lowerCase(dateLast) : dateLast
    }`;

    return (
      <div className="eve-panel note-panel note-panel-progress-photo">
        <div className="panel-header">
          <div
            className={`panel-title-wrapper ${_.get(listPhoto, 'length', 0) <= 0 && 'disabled'}`}
            onClick={() => {
              if (_.get(listPhoto, 'length', 0) > 0) {
                this.viewAllProgressPhoto();
              }
            }}
          >
            <Image src={`${CDN_URL}/images/dashboard_progress.svg`} />
            <div className="panel-title">Progress Photo</div>
          </div>
          <ImageButton
            image={`${CDN_URL}/images/plus_circle.svg`}
            title={'Add'}
            className="add-progress-photo bg-transparent add-new-metric"
            onClick={e => {
              e.stopPropagation();
              this.props.toggleModal(
                true,
                <ProgressPhotoWrapper
                  isView={false}
                  onClose={() => {
                    this.getProgressPhotoList();
                  }}
                />,
              );
            }}
          />
        </div>
        <div id="progress-overview-photo" className={listPhoto.length > 0 ? 'panel-body' : 'panel-body no-photo'}>
          {listPhoto.length > 0 ? (
            <div className="wrapper-exercise-pictures">
              <ul className="exercise-pictures">
                {listPhoto.map(item => {
                  const { image_thumbnail = '', image = '', day = '', measurement = '', weightUnit } = item;
                  return (
                    <li key={image} className="exercise-picture-item">
                      <S3ImageWithFallback
                        src={getThumbnailProgressPhoto([image_thumbnail, image])}
                        onClick={() => {
                          this.props.toggleModal(
                            true,
                            <ProgressPhotoWrapper
                              isView={true}
                              onClose={() => {
                                this.getProgressPhotoList();
                              }}
                            />,
                          );
                          this.updateFilters();
                          this.handleActivePhoto({
                            day,
                            image,
                            tags: [],
                            measurementId: measurement,
                            image_thumbnail,
                            weightUnit,
                          });
                        }}
                      />
                      <div className="posted-date">
                        {DateTime.fromFormat(day, 'MM-dd-yyyy').toLocal().toFormat(dateFormat)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <p>{this.props.selectedClient.first_name} hasn't uploaded any photos.</p>
          )}
        </div>
        {listPhoto.length > 0 && (
          <div className="panel-footer">
            <div className="compare-progress-photo" onClick={this.viewAllProgressPhoto} data-btn="btn-view-all">
              <IconEye /> View All
            </div>
            <div className="compare-progress-photo" onClick={this.openCompareMode} data-btn="btn-compare">
              <IconCompare className="icon-compare" /> Compare
            </div>
          </div>
        )}
      </div>
    );
  }
}
