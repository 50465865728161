// import from lodash
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import React from 'react';
import { toast } from 'react-toastify';
import { MultiplePasteMessage, LiveSyncEnableMessage } from 'shared/Notifications';
import { toggleModal } from 'actions/modal';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import { changeCalendarStartDate } from 'actions/calendar';
import { push } from 'connected-react-router';
import { onSubmitting, onSubmitted } from 'actions/submitStatus';
import { getAssignedPrograms } from 'redux/client/client.actionCreators';
import { database } from 'database/firebase';
import { togglePopup } from 'actions/popup';
import Request from 'configs/request';
import { getOnboardingCheckList } from 'redux/onboarding/actions';
import { CLASSNAME_TOAST, ONBOARDING_STEPS, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { convertWorkoutUnits } from 'helpers/workout';
import { isTeamAdmin, mongoObjectId, pluralize } from 'utils/commonFunction';

export const Types = {
  PROGRAM_CALENDAR_UNMOUNT: 'PROGRAM_CALENDAR_UNMOUNT',
  PROGRAM_LIBRARY_UPDATE_SHARING_STATUS: 'PROGRAM_LIBRARY_UPDATE_SHARING_STATUS',
  PROGRAM_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS: 'PROGRAM_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS',
  PROGRAM_LIBRARY_FAILED_UPDATE_SHARING_STATUS: 'PROGRAM_LIBRARY_FAILED_UPDATE_SHARING_STATUS',
  REMOVE_PROGRAM_WEEK_LIBRARY_REQUEST: 'REMOVE_PROGRAM_WEEK_LIBRARY_REQUEST',
  PROGRAM_LIBRARY_SUCCESS_DELETE_WEEK: 'PROGRAM_LIBRARY_SUCCESS_DELETE_WEEK',
  PROGRAM_LIBRARY_FAILED_DELETE_WEEK: 'PROGRAM_LIBRARY_FAILED_DELETE_WEEK',
  PROGRAM_LIBRARY_REQUEST_REMOVE_CLIENT: 'PROGRAM_LIBRARY_REQUEST_REMOVE_CLIENT',
  PROGRAM_LIBRARY_SUCCESS_REMOVE_CLIENT: 'PROGRAM_LIBRARY_SUCCESS_REMOVE_CLIENT',
  PROGRAM_LIBRARY_FAILED_REMOVE_CLIENT: 'PROGRAM_LIBRARY_FAILED_REMOVE_CLIENT',
  PROGRAM_LIBRARY_MOVE_WORKOUT: 'PROGRAM_LIBRARY_MOVE_WORKOUT',
  PROGRAM_LIBRARY_MOVE_EXERCISE: 'PROGRAM_LIBRARY_MOVE_EXERCISE',
  PROGRAM_LIBRARY_GET_BY_WEEKS_REQUEST: 'PROGRAM_LIBRARY_GET_BY_WEEKS_REQUEST',
  PROGRAM_LIBRARY_GET_BY_WEEKS_SUCCESS: 'PROGRAM_LIBRARY_GET_BY_WEEKS_SUCCESS',
  PROGRAM_LIBRARY_GET_BY_WEEKS_FAILED: 'PROGRAM_LIBRARY_GET_BY_WEEKS_FAILED',
  PROGRAM_LIBRARY_SELECTED_WEEK: 'PROGRAM_LIBRARY_SELECTED_WEEK',
  PROGRAM_LIBRARY_COPY_WEEK_REQUEST: 'PROGRAM_LIBRARY_COPY_WEEK_REQUEST',
  PROGRAM_LIBRARY_COPY_WEEK_SUCCESS: 'PROGRAM_LIBRARY_COPY_WEEK_SUCCESS',
  PROGRAM_LIBRARY_COPY_WEEK_FAILED: 'PROGRAM_LIBRARY_COPY_WEEK_FAILED',
  PROGRAM_LIBRARY_SELECTED_WORKOUTS: 'PROGRAM_LIBRARY_SELECTED_WORKOUTS',
  PROGRAM_LIBRARY_REMOVE_WEEK_REQUEST: 'PROGRAM_LIBRARY_REMOVE_WEEK_REQUEST',
  PROGRAM_LIBRARY_REMOVE_WEEK_SUCCESS: 'PROGRAM_LIBRARY_REMOVE_WEEK_SUCCESS',
  PROGRAM_LIBRARY_REMOVE_WEEK_FAILED: 'PROGRAM_LIBRARY_REMOVE_WEEK_FAILED',
  PROGRAM_LIBRARY_RESET_SELECTED_WEEK: 'PROGRAM_LIBRARY_RESET_SELECTED_WEEK',
  PROGRAM_LIBRARY_RESET_SELECTED_WORKOUT: 'PROGRAM_LIBRARY_RESET_SELECTED_WORKOUT',
  PROGRAM_LIBRARY_COPY_WORKOUTS: 'PROGRAM_LIBRARY_COPY_WORKOUTS',
  PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_REQUEST: 'PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_REQUEST',
  PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_SUCCESS: 'PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_SUCCESS',
  PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_FAILED: 'PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_FAILED',
  PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_REQUEST: 'PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_REQUEST',
  PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_SUCCESS: 'PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_SUCCESS',
  PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_FAILED: 'PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_FAILED',
  PROGRAM_LIBRARY_SOCKET_WORKOUT_ADDED: 'PROGRAM_LIBRARY_SOCKET_WORKOUT_ADDED',
  ADDING_PROGRAM_WEEK_LIBRARY_REQUEST: 'ADDING_PROGRAM_WEEK_LIBRARY_REQUEST',
  ADDING_PROGRAM_WEEK_LIBRARY_SUCCESS: 'ADDING_PROGRAM_WEEK_LIBRARY_SUCCESS',
  ADDING_PROGRAM_WEEK_LIBRARY_ERROR: 'ADDING_PROGRAM_WEEK_LIBRARY_ERROR',
};

export const calendarUnmount = () => ({ type: Types.PROGRAM_CALENDAR_UNMOUNT });

export const arrangeProgramLibraryWorkout = data => {
  const { programId, workoutId } = data;
  return dispatch => {
    const movingParams = {
      ...data,
      oldWeekIndex: data.weekIndex,
      oldDayIndex: data.dayIndex,
      newWeekIndex: data.weekIndex,
      newDayIndex: data.dayIndex,
    };

    const requestBody = {
      from_index: data.fromIndex,
      day_index: data.dayIndex,
      new_index: data.newIndex,
      week_index: data.weekIndex,
    };

    dispatch({
      type: Types.PROGRAM_LIBRARY_MOVE_WORKOUT,
      payload: { data: movingParams },
    });

    return dispatch(
      Request.put(
        { url: `/api/program-library/${programId}/workouts/${workoutId}/arrange`, data: requestBody },
        false,
        () => {},
        () => {
          dispatch({
            type: Types.PROGRAM_LIBRARY_MOVE_WORKOUT,
            payload: {
              data: {
                ...movingParams,
                fromIndex: movingParams.newIndex,
                newIndex: movingParams.fromIndex,
              },
            },
          });
        },
      ),
    );
  };
};

export const removeProgramLibrary = itemId => {
  return Request.delete(
    { url: `/api/program/library/v2/delete/${itemId}` },
    false,
    (result, { dispatch }) => {
      dispatch(toggleModal(false));
      dispatch(removeReduxProgramLibrary(itemId));
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_REMOVE_PROGRAM_LIBRARY', error }),
  );
};

export const getProgramLibrary = () => {
  return dispatch => {
    dispatch({ type: 'LOADING_PROGRAM_LIBRARY_LIST' });

    return dispatch(
      Request.get(
        { url: '/api/program/library/v2/all' },
        true,
        result => {
          dispatch({ type: 'LOADED_GET_PROGRAM_LIBRARY_LIST', payload: result.data });
        },
        error => {
          dispatch({ type: 'FAILED_GET_PROGRAM_LIBRARY_LIST' });
        },
      ),
    );
  };
};

const convertProgramLibraryWorkoutUnits = (program, units) => {
  let clonedProgram = cloneDeep(program);
  forEach(clonedProgram.workout_sets, workout_set => {
    forEach(workout_set.days_workout, ({ day_workout }) => {
      forEach(day_workout.workouts, (workout, index) => {
        day_workout.workouts[index] = convertWorkoutUnits(workout, units);
      });
    });
  });
  return clonedProgram;
};

export const getProgramLibraryDetail = itemId => {
  return Request.get(
    { url: `/api/program-library/${itemId}/get-detail` },
    true,
    (result, { dispatch, getState }) => {
      const state = getState();
      const units = state.user.preferences;
      const program = convertProgramLibraryWorkoutUnits(result.data.data, units);
      dispatch({ type: 'LOADED_GET_PROGRAM_LIBRARY_DETAIL', payload: { program: program } });
    },
    (error, { dispatch }) => {
      dispatch({ type: 'FAILED_GET_PROGRAM_LIBRARY_DETAIL', error: error });
    },
  );
};

export const getProgramLibraryDetailByWeeks = params => {
  return dispatch => {
    dispatch({ type: Types.PROGRAM_LIBRARY_GET_BY_WEEKS_REQUEST });
    return dispatch(
      Request.get(
        { url: `/api/program-library/get-calendar-by-week`, params },
        true,
        (result, { dispatch, getState }) => {
          const state = getState();
          const units = state.user.preferences;
          const program = convertProgramLibraryWorkoutUnits({ workout_sets: result.data.data }, units);
          dispatch({ type: Types.PROGRAM_LIBRARY_GET_BY_WEEKS_SUCCESS, payload: { program: program } });
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.PROGRAM_LIBRARY_GET_BY_WEEKS_FAILED, error: error });
        },
      ),
    );
  };
};

export const createProgramLibrary = data => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = get(state, 'user._id', '');
    const author = get(data, 'author', '');

    dispatch(onSubmitting('PROGRAM_DETAIL'));

    return dispatch(
      Request.post(
        { url: '/api/program/library/v2/add', data },
        false,
        result => {
          dispatch(onSubmitted('PROGRAM_DETAIL', true));
          dispatch(toggleModal(false));
          if (result.data.data) {
            dispatch(appendReduxProgramLibrary(result.data.data, getState().user._id));
            if (currentUser === author || !author) {
              dispatch(push(`/home/program/${result.data.data._id}/calendar`));
            } else {
              toast('Program has been saved');
            }
          }
        },
        error => {
          dispatch(onSubmitted('PROGRAM_DETAIL', false));
          dispatch({ type: 'ERROR_ADDING_PROGRAM_LIBRARY', error });
        },
      ),
    );
  };
};

export const updateProgramLibrary = data => {
  return (dispatch, getState) => {
    const { user } = getState();
    const { author, share } = data;

    const isCreator = user._id === author;
    const isNoOwner = share === TEAM_SHARE_NOOWNER;
    const isCreatorOrNoOwner = isCreator || isNoOwner;

    dispatch(onSubmitting('PROGRAM_DETAIL'));

    return dispatch(
      Request.post(
        { url: `/api/program/library/v2/update/${data._id}`, data },
        false,
        result => {
          dispatch(onSubmitted('PROGRAM_DETAIL', true));
          toast('Program has been updated successfully.');
          dispatch(toggleModal(false));

          if (result.data.data) {
            dispatch(appendReduxProgramLibrary(result.data.data, getState().user._id));
          }

          if (!isCreatorOrNoOwner) {
            dispatch(push(`/home/program`));
          }
        },
        error => {
          dispatch(onSubmitted('PROGRAM_DETAIL', false));
          dispatch({ type: 'ERROR_ADDING_PROGRAM_LIBRARY', error });
        },
      ),
    );
  };
};

export const cloneProgramLibrary = data => {
  if (!data._id) return;
  return (dispatch, getState) => {
    dispatch(onSubmitting('PROGRAM_DETAIL'));

    return dispatch(
      Request.post(
        { url: `/api/program/library/v2/clone/${data._id}`, data },
        false,
        result => {
          dispatch(onSubmitted('PROGRAM_DETAIL', true));

          dispatch(toggleModal(false));
          if (result.data.data) {
            toast('Program has been saved to library.');
            dispatch(appendReduxProgramLibrary(result.data.data, getState().user._id));
          }
        },
        error => {
          dispatch(onSubmitted('PROGRAM_DETAIL', false));
          dispatch({ type: 'ERROR_ADDING_PROGRAM_LIBRARY', error });
        },
      ),
    );
  };
};

export const addWorkoutToProgramLibrary = data => {
  return (dispatch, getState) => {
    dispatch(onSubmitting('WORKOUT_DETAIL'));
    const state = getState();
    const {
      rootReducer: {
        program_library: { selected, selected_current_week, calendar_type },
      },
    } = state;

    return dispatch(
      Request.post(
        { url: '/api/program-library/assign', data },
        true,
        result => {
          dispatch(onSubmitted('WORKOUT_DETAIL', true));
          dispatch(toggleModal(false));
          if (result.data.data) {
            dispatch(
              getProgramLibraryDetailByWeeks({
                pid: selected._id,
                startWeek: selected_current_week,
                endWeek: selected_current_week + calendar_type,
              }),
            );
          }
        },
        error => {
          dispatch(onSubmitted('WORKOUT_DETAIL', false));
          dispatch({ type: 'ERROR_ADDING_PROGRAM_LIBRARY', error });
        },
      ),
    );
  };
};

export const addNewWorkoutToProgramLibrary = data => {
  return Request.post({ url: '/api/program/library/v2/workout/add', data }, true, (result, { dispatch }) => {
    if (result.data.data) {
      const program = result.data.data;
      dispatch(refreshCurrentProgram(program));
    }
  });
};

export const increaseProgramPage = () => ({ type: 'INCREASE_PAGE_PROGRAM_LIBRARY' });

export const decreaseProgramPage = () => ({ type: 'DECREASE_PAGE_PROGRAM_LIBRARY' });

export const sortProgramList = sortBy => ({ type: 'SORT_PROGRAM_LIBRARY_LIST', payload: { sortBy } });

export const searchProgramList = query => ({ type: 'SEARCH_PROGRAM_LIBRARY_LIST', payload: { query } });

export const appendReduxProgramLibrary = (program, userId) => ({
  type: 'APPEND_PROGRAM_LIBRARY_REDUX',
  payload: { program, userId },
});

export const removeReduxProgramLibrary = programId => ({
  type: 'REMOVE_PROGRAM_LIBRARY_REDUX',
  payload: { id: programId },
});

export const removeWorkoutFromReduxProgramLibrary = (workoutId, dayIndex, weekIndex) => ({
  type: 'REMOVE_WORKOUT_FROM_PROGRAM_LIBRARY_REDUX',
  payload: { workoutId, dayIndex, weekIndex },
});

export const exitProgramDetailScreen = () => ({ type: 'REMOVE_SELECTED_PROGRAM' });

export const refreshCurrentProgram = program => ({
  type: 'REFRESH_CURRENT_PROGRAM_LIBRARY',
  payload: { program },
});

export const refreshAfterUpdateWeekRangeProgram = (payload, weekIndex) => ({
  type: 'REFRESH_AFTER_UPDATE_WEEK_PROGRAM_LIBRARY',
  payload: { newPayload: payload, weekIndex },
});

export const refreshWorkoutInCurrentProgram = (weekIndex, dayIndex, workout) => {
  return { type: 'REFRESH_WORKOUT_IN_CURRENT_PROGRAM_LIBRARY', payload: { workout, weekIndex, dayIndex } };
};

export const changeCurrentProgram = programId => {
  return { type: 'CHANGE_CURRENT_PROGRAM_LIBRARY', payload: { id: programId } };
};

export const programLibraryChangeCalendarType = calendarType => {
  return { type: 'CHANGE_PROGRAM_CALENDAR_TYPE', payload: { calendarType } };
};

export const nextCurrentWeek = () => ({ type: 'PROGRAM_LIBRARY_NEXT_CURRENT_WEEK' });

export const previousCurrentWeek = () => ({ type: 'PROGRAM_LIBRARY_PREV_CURRENT_WEEK' });

export const copyWorkout = item => {
  toast(<MultiplePasteMessage />);
  return { type: 'PROGRAM_LIBRARY_COPY_WORKOUT', payload: { item } };
};

export const copiedWorkout = multiPaste => ({ type: 'PROGRAM_LIBRARY_COPIED_WORKOUT', multiPaste });

export const removeWorkoutFromDate = data => {
  return Request.post(
    { url: `/api/program/library/v2/workout/${data.workoutId}/delete`, data },
    false,
    (result, { dispatch }) => {
      dispatch(removeWorkoutFromReduxProgramLibrary(data.workoutId, data.dayIndex, data.weekIndex));
    },
    (error, { dispatch }) => dispatch({ type: 'ERROR_DELETE_ASSIGNMENT', error }),
  );
};

export const copyWorkoutToDate = (params, multiPaste) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      rootReducer: {
        program_library: { selected, selected_current_week, calendar_type },
      },
    } = state;

    dispatch(copiedWorkout(multiPaste));

    return dispatch(
      Request.post(
        { url: '/api/program-library/copy', data: params },
        true,
        result => {
          const program = result.data.data;
          if (program) {
            dispatch(
              getProgramLibraryDetailByWeeks({
                pid: selected._id,
                startWeek: selected_current_week,
                endWeek: selected_current_week + calendar_type,
              }),
            );
          }
        },
        error => dispatch({ type: 'ERROR_COPY_ASSIGNMENT', error }),
      ),
    );
  };
};

export const getProgramWorkoutDetail = (workoutId, weekIndex, dayIndex, programId) => {
  return Request.get(
    { url: `/api/program/library/v2/workout/${workoutId}/detail` },
    true,
    (data, { dispatch, getState }) => {
      const state = getState();
      const { selected_total_week } = state.rootReducer.program_library;
      const woData = data.data.data;

      const saveWorkout = async newWorkout => {
        const bodyData = {
          workoutId: workoutId,
          title: newWorkout.title,
          description: newWorkout.description,
          sections: newWorkout.sections,
          background: newWorkout.background,
          programId,
          weekIndex,
          dayIndex,
        };

        try {
          const response = await dispatch(updateProgramLibraryWorkout(bodyData));
          return response.data.data;
        } catch (error) {
          return Promise.resolve(null);
        }
      };

      const remove = () => {
        dispatch(removeWorkoutFromDate({ weekIndex, dayIndex, workoutId, programId }));
      };

      dispatch(
        toggleModal(
          true,
          <WorkoutDetailModal
            key={`update-program-workout__${workoutId}`}
            maxWeeks={selected_total_week}
            workingWorkout={{ ...woData, day: Number(weekIndex) * 7 + Number(dayIndex) }}
            fetchWorkoutsByWeek={fetchWorkoutsByWeek}
            onDelete={remove}
            onSave={saveWorkout}
            saveToLibrary={() => dispatch(saveWorkoutToWorkoutLibrary({ workoutId }))}
            pdfType="program_workout"
          />,
        ),
      );
    },
  );
};

export const saveWorkoutToWorkoutLibrary = params => {
  return Request.post(
    { url: `/api/program/library/v2/workout/${params.workoutId}/saveToLibrary`, data: {} },
    false,
    () => {
      toast.success('Workout saved to library!', { autoClose: 3000 });
    },
  );
};

export const updateProgramLibraryWorkout = (params, onSuccess) => {
  return dispatch => {
    if (typeof params.formNamespace === 'string') {
      dispatch(onSubmitting(params.formNamespace));
    }

    return dispatch(
      Request.put(
        { url: `/api/program/library/v2/workout/${params.workoutId}/update`, data: params },
        true,
        result => {
          if (typeof params.formNamespace === 'string') {
            dispatch(onSubmitted(params.formNamespace, true));
          }

          dispatch(togglePopup(null));

          if (result.data.data) {
            const workout = result.data.data;

            dispatch(refreshWorkoutInCurrentProgram(params.weekIndex, params.dayIndex, workout));
          }

          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        },
        () => {
          if (typeof params.formNamespace === 'string') {
            dispatch(onSubmitted(params.formNamespace, false));
          }
        },
      ),
    );
  };
};

export const addWeekAtIndex = params => {
  const { weekIndex, programId } = params;
  return (dispatch, getState) => {
    const {
      rootReducer: {
        program_library: { selected, selected_current_week, calendar_type },
      },
    } = getState();
    dispatch({ type: Types.ADDING_PROGRAM_WEEK_LIBRARY_REQUEST });
    return dispatch(
      Request.post(
        { url: `/api/program-library/${programId}/add-week`, data: { week_index: weekIndex } },
        true,
        (result, { dispatch }) => {
          if (result.data.data) {
            const payload = {
              newWeek: get(result, 'data.data.workout_set', {}),
              totalWeek: get(result, 'data.data.total_week', 0),
            };
            dispatch({ type: Types.ADDING_PROGRAM_WEEK_LIBRARY_SUCCESS });
            const params = {
              pid: selected._id,
              startWeek: selected_current_week,
              endWeek: selected_current_week + calendar_type,
            };
            dispatch(refreshAfterUpdateWeekRangeProgram(payload, weekIndex));
            dispatch(getProgramLibraryDetailByWeeks(params));
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.ADDING_PROGRAM_WEEK_LIBRARY_ERROR, error });
        },
      ),
    );
  };
};

export const removeWeekById = params => {
  if (!params.weekSetId) return;
  return (dispatch, getState) => {
    const {
      rootReducer: {
        program_library: { selected, selected_current_week, calendar_type },
      },
    } = getState();

    dispatch({ type: Types.REMOVE_PROGRAM_WEEK_LIBRARY_REQUEST, payload: { removedWeekIndex: params.weekIndex } });
    return dispatch(
      Request.delete(
        { url: `/api/program-library/${params.programId}/weeks/${params.weekSetId}`, data: params },
        true,
        (result, { dispatch }) => {
          if (result.data.data) {
            const payload = {
              newWeek: get(result, 'data.data.workout_set', {}),
              totalWeek: get(result, 'data.data.total_week', 0),
              isDeleted: true,
            };
            dispatch(refreshAfterUpdateWeekRangeProgram(payload, params.weekIndex));
            dispatch({
              type: Types.PROGRAM_LIBRARY_SUCCESS_DELETE_WEEK,
              payload: { changedWeekIndex: params.weekIndex },
            });
            dispatch(
              getProgramLibraryDetailByWeeks({
                pid: selected._id,
                startWeek: selected_current_week,
                endWeek: selected_current_week + calendar_type,
              }),
            );
          }
        },
        (error, { dispatch }) => {
          dispatch({ type: Types.PROGRAM_LIBRARY_FAILED_DELETE_WEEK });
        },
      ),
    );
  };
};

export const addSetProgramLibraryWorkout = params => {
  return Request.put(
    { url: `/api/program/library/v2/workout/${params.workoutId}/addSet`, data: params },
    false,
    (result, { dispatch }) => {
      dispatch(onSubmitted('EXERCISE_DETAIL', true));
      dispatch(toggleModal(false));

      if (result.data.data) {
        const program = result.data.data;
        dispatch(refreshCurrentProgram(program));
      }
    },
    (error, { dispatch }) => {
      dispatch(onSubmitted('EXERCISE_DETAIL', false));
      dispatch({ type: 'ERROR_ADD_SET_PROGRAM_LIBRARY_WORKOUT', error });
    },
  );
};

export const arrangeExerciseSetOnProgramLibraryWorkout = params => {
  return dispatch => {
    dispatch({
      type: Types.PROGRAM_LIBRARY_MOVE_EXERCISE,
      payload: {
        sectionId: params.sectionId,
        newIndex: params.newIndex,
        oldIndex: params.oldIndex,
        workoutId: params.workoutId,
        workoutDestinationId: params.workoutId,
        dayIndexDestination: params.dayIndexDestination,
        dayIndex: params.dayIndex,
        weekIndex: params.weekIndex,
        oldWeekIndex: params.oldWeekIndex,
      },
    });
    return dispatch(
      Request.put(
        { url: `/api/program/library/v2/workout/${params.workoutId}/arrangeSet`, data: params },
        false,
        (result, { dispatch }) => {
          if (result.data.data) {
            const program = result.data.data;
            dispatch(refreshCurrentProgram(program));
          }
        },
        (error, { dispatch }) => {
          dispatch({
            type: Types.PROGRAM_LIBRARY_MOVE_EXERCISE,
            payload: {
              sectionId: params.sectionId,
              newIndex: params.oldIndex,
              oldIndex: params.newIndex,
              workoutId: params.workoutId,
              workoutDestinationId: params.workoutId,
              dayIndexDestination: params.dayIndexDestination,
              dayIndex: params.dayIndex,
              weekIndex: params.weekIndex,
              oldWeekIndex: params.oldWeekIndex,
            },
          });
        },
      ),
    );
  };
};

export const moveExerciseSetOnProgramLibraryWorkout = params => {
  return dispatch => {
    dispatch({ type: Types.PROGRAM_LIBRARY_MOVE_EXERCISE, payload: params });
    return dispatch(
      Request.put(
        { url: `/api/program/library/v2/workout/${params.workoutId}/moveSet`, data: params },
        false,
        (result, { dispatch }) => {
          if (result.data.data) {
            const program = result.data.data;
            dispatch(refreshCurrentProgram(program));
          }
        },
        (error, { dispatch }) =>
          dispatch({
            type: Types.PROGRAM_LIBRARY_MOVE_EXERCISE,
            payload: {
              sectionId: params.sectionId,
              newIndex: params.oldIndex,
              oldIndex: params.newIndex,
              workoutId: params.workoutDestinationId,
              workoutDestinationId: params.workoutId,
              dayIndexDestination: params.dayIndex,
              dayIndex: params.dayIndexDestination,
              weekIndex: params.oldWeekIndex,
              oldWeekIndex: params.weekIndex,
            },
          }),
      ),
    );
  };
};

export const selectPreviewItem = program => ({
  type: 'SELECT_PREVIEW_PROGRAM_LIBRARY',
  payload: { program },
});

export const loadPreviewItem = itemId => {
  if (!itemId) return;
  return Request.get(
    { url: `/api/program-library/${itemId}/lite-detail` },
    true,
    (result, { dispatch }) => {
      // dispatch({ type: 'LOADED_GET_PROGRAM_LIBRARY_DETAIL', payload: { program: result.data.data } });
      dispatch(selectPreviewItem(result.data.data));
    },
    (error, { dispatch }) => {
      dispatch({ type: 'FAILED_GET_PROGRAM_LIBRARY_DETAIL', error: error });
    },
  );
};

export const loadingPreviewItem = () => {
  return dispatch => {
    dispatch({ type: 'LOADING_PROGRAM_LIBRARY_DETAIL' });
  };
};

export const assignProgram = params => {
  return (dispatch, getState) => {
    const {
      rootReducer: {
        client: { workingClientDetail },
      },
    } = getState();

    return dispatch(
      Request.post(
        {
          url: '/api/program/v2/assign',
          data: { ...params, client: workingClientDetail ? workingClientDetail._id : '' },
        },
        false,
        (response, { dispatch, getState }) => {
          dispatch(changeCalendarStartDate(undefined, undefined, undefined, undefined, true));
          dispatch(getAssignedPrograms(workingClientDetail ? workingClientDetail._id : ''));

          const {
            rootReducer: {
              onboarding: { hideFeature, checkList },
            },
          } = getState();

          if (!hideFeature) {
            const unfinished = find(checkList, item => item.type === ONBOARDING_STEPS.ASSIGN_PROGRAM && !item.state);
            dispatch(getOnboardingCheckList(!!unfinished));
          }
        },
      ),
    );
  };
};

export const goToWeek = week => ({ type: 'PROGRAM_GO_TO_WEEK', payload: { week } });

export const getClientsAssignedToProgram = pid => {
  return Request.get(
    { url: '/api/program/v2/list_client', params: { pid } },
    false,
    (result, { dispatch }) => {
      const { data } = result.data;
      dispatch({
        type: 'SUCCESS_GET_CLIENTS_ASSIGNED_TO_PROGRAM',
        payload: { list: data.filter(item => !!item.program), pid },
      });
    },
    (error, { dispatch }) => {
      dispatch({ type: 'FAILED_GET_CLIENTS_ASSIGNED_TO_PROGRAM', error: error });
    },
  );
};

export const programToggleLiveSync = (pId, data) => {
  if (!pId) return;
  return Request.put(
    { url: `/api/program/library/v2/active_sync/${pId}`, data },
    true,
    (result, { dispatch }) => {
      dispatch({ type: 'PROGRAM_TOGGLE_LIVE_SYNC', payload: data });
      data.active_sync && toast.success(<LiveSyncEnableMessage />, { className: 'live-sync-message' });
      return result.data;
    },
    (error, { dispatch }) => dispatch({ type: 'FAILED_TOGGLE_LIVE_SYNC', error: error }),
  );
};

export const programAssignMultiple = data => {
  return Request.post(
    { url: '/api/program/v2/assign_multiple', data },
    true,
    (result, { dispatch, getState }) => {
      dispatch({
        type: 'SUCCESS_GET_CLIENTS_ASSIGNED_TO_PROGRAM',
        payload: { list: result.data.data.filter(item => !!item.program), pid: data.pid },
      });
      dispatch(toggleModal(false));
      toast('Successful assignment! It might take a several minutes for the data to run.');

      const {
        rootReducer: {
          onboarding: { hideFeature, checkList },
        },
      } = getState();

      if (!hideFeature) {
        const unfinished = find(checkList, item => item.type === ONBOARDING_STEPS.ASSIGN_PROGRAM && !item.state);
        dispatch(getOnboardingCheckList(!!unfinished));
      }

      return result.data;
    },
    (error, { dispatch }) => {
      dispatch({ type: 'FAILED_ASSIGN_PROGRAM_TO_MULTIPLE_CLIENTs', error: error });
    },
  );
};

export const fetchJobAssignProgram = (userId, programId) => {
  return (dispatch, getState) => {
    database
      .ref('updates')
      .child(userId)
      .child('job_assign_program')
      .on('value', snapshot => {
        let data = snapshot.val();

        if (!data) {
          return;
        }

        const currentState = getState();
        const { program_library } = currentState.rootReducer;

        if (data.last_updated && program_library.selected) {
          if (!program_library.last_job_run || data.last_updated >= program_library.last_job_run) {
            dispatch({
              type: 'PROGRAM_UPDATE_LAST_JON_RUN',
              payload: { last_job_run: data.last_updated, pid: programId },
            });
            // dispatch(getClientsAssignedToProgram(programId));
          }
        }
      });
  };
};

export const updateExerciseLibraryFromProgramCalendar = data => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { user, rootReducer } = currentState;
    const { exercise } = rootReducer;
    const { categories, fields } = exercise;
    const unit = user.preferences;

    dispatch({
      type: 'UPDATE_EXERCISE_LIBRARY_FROM_PROGRAM_CALENDAR',
      payload: { data: { ...data, categories, fields, unit } },
    });
  };
};

export const resetCopyItem = () => ({ type: 'PROGRAM_CLEAR_COPY_ITEM' });

export const updateProgramSharingStatus = data => {
  return dispatch => {
    dispatch({ type: Types.PROGRAM_LIBRARY_UPDATE_SHARING_STATUS });

    return dispatch(
      Request.post(
        { url: `/api/program/library/v2/update/${data._id}`, data },
        true,
        () => {
          dispatch({ type: Types.PROGRAM_LIBRARY_SUCCESS_UPDATE_SHARING_STATUS, payload: { data } });
          dispatch(getProgramLibrary());
        },
        () => dispatch({ type: Types.PROGRAM_LIBRARY_FAILED_UPDATE_SHARING_STATUS }),
      ),
    );
  };
};

export const removeClientFromProgram = data => {
  return dispatch => {
    return dispatch(
      Request.put(
        { url: '/api/program/delete-program', data },
        true,
        () => {
          dispatch({ type: Types.PROGRAM_LIBRARY_SUCCESS_REMOVE_CLIENT, payload: { data } });
        },
        () => dispatch({ type: Types.PROGRAM_LIBRARY_FAILED_REMOVE_CLIENT }),
      ),
    );
  };
};

export const moveWorkoutProgram = data => {
  const { programId, workoutId, fromIndex, newDayIndex, newIndex, newWeekIndex, oldDayIndex, oldWeekIndex } = data;
  const body = {
    from_index: parseInt(fromIndex),
    new_day_index: parseInt(newDayIndex),
    new_index: parseInt(newIndex),
    new_week_index: parseInt(newWeekIndex),
    old_day_index: parseInt(oldDayIndex),
    old_week_index: parseInt(oldWeekIndex),
  };

  return dispatch => {
    dispatch({
      type: Types.PROGRAM_LIBRARY_MOVE_WORKOUT,
      payload: { data },
    });

    return dispatch(
      Request.put(
        { url: `/api/program-library/${programId}/workouts/${workoutId}/move`, data: body },
        true,
        () => {},
        () =>
          dispatch({
            type: Types.PROGRAM_LIBRARY_MOVE_WORKOUT,
            payload: {
              data: {
                ...data,
                fromIndex: data.newIndex,
                newIndex: data.fromIndex,
                oldWeekIndex: data.newWeekIndex,
                oldDayIndex: data.newDayIndex,
                newWeekIndex: data.oldWeekIndex,
                newDayIndex: data.oldDayIndex,
              },
            },
          }),
      ),
    );
  };
};

export const fetchWorkoutsByWeek = () => {
  return (dispatch, getState) => {
    const state = getState();
    const program = state.rootReducer.program_library.selected;
    const workoutData = {};
    forEach(program.workout_sets, (week, weekIndex) => {
      forEach(week.days_workout, dayData => {
        const dayIndex = weekIndex * 7 + dayData.day_index;
        workoutData[dayIndex] = {
          day: dayIndex,
          workouts: get(dayData, 'day_workout.workouts', []),
        };
      });
    });
    return Promise.resolve(workoutData);
  };
};

export const saveCalendarAsProgram = data => {
  return Request.post({ url: `/api/program-library/save-calendar-as-program`, data }, true);
};

// Select a week to copy or delete
export const handleSelectedWeek = weekIndex => {
  const TEMP_ID = mongoObjectId();
  return dispatch => {
    dispatch({
      type: Types.PROGRAM_LIBRARY_SELECTED_WEEK,
      payload: { weekIndex, weekCopyId: TEMP_ID },
    });
  };
};

// Paste a week
export const handlePasteWeek = (pasteWeekIndex, multiPaste) => {
  return (dispatch, getState) => {
    const state = getState();
    const weekCopyId = get(state, 'rootReducer.program_library.weekCopyId');
    const selectedWeek = get(state, 'rootReducer.program_library.selectedWeek');
    const programId = get(state, 'rootReducer.program_library.selected._id', '');

    if (!programId) return;
    dispatch({
      type: Types.PROGRAM_LIBRARY_COPY_WEEK_REQUEST,
      payload: { multiPaste },
    });

    return dispatch(
      Request.post(
        {
          url: '/api/program-workouts/copy-week',
          data: {
            program_id: programId,
            copy_week: selectedWeek,
            paste_week: pasteWeekIndex,
            copy_id: weekCopyId,
          },
        },
        false,
        response => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.PROGRAM_LIBRARY_COPY_WEEK_SUCCESS });
          }
        },
        error => dispatch({ type: Types.PROGRAM_LIBRARY_COPY_WEEK_FAILED, error }),
      ),
    );
  };
};

// Remove workout in week
export const handleRemoveWorkoutWeek = weekIndex => {
  return (dispatch, getState) => {
    const state = getState();
    const programId = get(state, 'rootReducer.program_library.selected._id', '');
    const weekNumber = weekIndex + 1;

    if (!programId || weekIndex < 0) return;
    dispatch({
      type: Types.PROGRAM_LIBRARY_REMOVE_WEEK_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: '/api/program-workouts/remove-week',
          data: {
            program_id: programId,
            week_index: weekIndex,
          },
        },
        false,
        response => {
          const removedIds = get(response, 'data.data.removed_ids', false);
          if (removedIds) {
            dispatch({ type: Types.PROGRAM_LIBRARY_REMOVE_WEEK_SUCCESS, payload: removedIds });
            const workoutDeleted = removedIds.length;
            const unit = pluralize('Workout', workoutDeleted);
            const verbs = workoutDeleted !== 1 ? 'have' : 'has';
            toast(`All ${unit} from Week ${weekNumber}  ${verbs} been removed.`, {
              className: CLASSNAME_TOAST.TRAINING_CALENDAR,
            });
          }
        },
        error => dispatch({ type: Types.PROGRAM_LIBRARY_REMOVE_WEEK_FAILED, error }),
      ),
    );
  };
};

// Reset selected week
export const handleResetSelectedWeek = () => {
  return dispatch => {
    dispatch({
      type: Types.PROGRAM_LIBRARY_RESET_SELECTED_WEEK,
    });
  };
};

// Select workouts
export const handleSelectWorkout = workoutIds => {
  return dispatch => {
    dispatch({
      type: Types.PROGRAM_LIBRARY_SELECTED_WORKOUTS,
      payload: { workoutIds },
    });
  };
};

// Reset selected week
export const handleResetSelectWorkout = () => {
  return dispatch => {
    dispatch({
      type: Types.PROGRAM_LIBRARY_RESET_SELECTED_WORKOUT,
    });
  };
};

export const handleCopyWorkouts = workouts => {
  const TEMP_ID = mongoObjectId();
  return dispatch =>
    dispatch({
      type: Types.PROGRAM_LIBRARY_COPY_WORKOUTS,
      payload: { workouts, copyId: TEMP_ID },
    });
};

export const handlePasteMultipleWorkout = ({ dayIndex, weekIndex, multiPaste = false }) => {
  return (dispatch, getState) => {
    const state = getState();
    const programId = get(state, 'rootReducer.program_library.selected._id', '');
    const selectedWorkouts = get(state, 'rootReducer.program_library.selectedWorkout');
    const copyId = get(state, 'rootReducer.program_library.workoutsCopyId');
    if (!programId || isEmpty(selectedWorkouts)) return;
    dispatch({
      type: Types.PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_REQUEST,
      payload: { multiPaste },
    });
    return dispatch(
      Request.post(
        {
          url: `/api/program-workouts/bulk-copy`,
          data: {
            program_id: programId,
            workout_ids: selectedWorkouts,
            day_index: dayIndex,
            week_index: weekIndex,
            copy_id: copyId,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          if (success) {
            dispatch({ type: Types.PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_SUCCESS });
          }
        },
        error => {
          dispatch({ type: Types.PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_FAILED, error });
        },
      ),
    );
  };
};

export const handleRemoveWorkouts = () => {
  return (dispatch, getState) => {
    const state = getState();
    const programId = get(state, 'rootReducer.program_library.selected._id', '');
    const selectedWorkout = get(state, 'rootReducer.program_library.selectedWorkout');

    if (!programId || isEmpty(selectedWorkout)) return;
    if (isEmpty(selectedWorkout)) {
      dispatch(handleResetSelectWorkout());
      toast(`0 Workouts have been removed.`, { className: CLASSNAME_TOAST.TRAINING_CALENDAR });
      return;
    }
    dispatch({
      type: Types.PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: `/api/program-workouts/bulk-remove`,
          data: {
            program_id: programId,
            workout_ids: selectedWorkout,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data.success', false);
          const workoutDeleted = get(response, 'data.data.removed_ids', []);
          if (success) {
            dispatch({
              type: Types.PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_SUCCESS,
              payload: workoutDeleted,
            });
            const unit = pluralize('Workout', workoutDeleted.length);
            const verbs = workoutDeleted.length !== 1 ? 'have' : 'has';
            toast(`${workoutDeleted.length} ${unit} ${verbs} been removed.`, {
              className: CLASSNAME_TOAST.TRAINING_CALENDAR,
            });
          }
        },
        error => {
          dispatch({ type: Types.PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_FAILED, error });
          dispatch(handleResetSelectWorkout());
        },
      ),
    );
  };
};

// Socket update workout after copy week/workout
export const socketProgramWorkoutAdded = data => {
  return (dispatch, getState) => {
    const state = getState();
    const programId = get(state, 'rootReducer.program_library.selected._id', '');
    const isAddingWeek = get(state, 'rootReducer.program_library.isAddingWeek', false);
    const weekIndex = get(data, 'week_index');
    const dayIndex = get(data, 'day_index');
    const workout = get(data, 'workout');

    if (isEqual(programId, get(data, 'program_id'))) {
      if (!isAddingWeek) {
        return dispatch({
          type: Types.PROGRAM_LIBRARY_SOCKET_WORKOUT_ADDED,
          payload: {
            programId,
            workout,
            weekIndex,
            dayIndex,
          },
        });
      }
    }
    return;
  };
};
