import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const MultipleMediaItem = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .image-with-fallback {
    height: 100%;
    width: 100%;
  }

  img,
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export const MultipleMediaProgressWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`;

export const MultipleMediaProgressContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const MediaPlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
  cursor: pointer;

  ${props =>
    props.isVideo &&
    css`
      background: rgba(0, 0, 0, 0.5) url(${CDN_URL}/images/play_icon_white.svg) no-repeat center;
      background-size: auto;
    `}
`;

export const CancelButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  background: #fff url(${CDN_URL}/images/close_purple.svg) no-repeat center;
  background-size: auto;
  cursor: pointer;
  border-radius: 500%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
