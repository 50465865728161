export const ranges = [
  { maxPrice: 1, tasks: '0' },
  { maxPrice: 2, tasks: '1000' },
  { maxPrice: 19, tasks: '2000' },
  { maxPrice: 36, tasks: '3000' },
  { maxPrice: 48, tasks: '4000' },
  { maxPrice: 60, tasks: '5000' },
  { maxPrice: 72, tasks: '6000' },
  { maxPrice: 84, tasks: '7000' },
  { maxPrice: 96, tasks: '8000' },
  { maxPrice: 108, tasks: '9000' },
  { maxPrice: 120, tasks: '10000' },
  { maxPrice: 132, tasks: '11000' },
  { maxPrice: 144, tasks: '12000' },
  { maxPrice: 156, tasks: '13000' },
  { maxPrice: 168, tasks: '14000' },
  { maxPrice: 180, tasks: '15000' },
  { maxPrice: 192, tasks: '16000' },
  { maxPrice: 204, tasks: '17000' },
  { maxPrice: 216, tasks: '18000' },
  { maxPrice: 228, tasks: '19000' },
  { maxPrice: 240, tasks: '20000' },
  { maxPrice: 252, tasks: '21000' },
  { maxPrice: 264, tasks: '22000' },
  { maxPrice: 276, tasks: '23000' },
  { maxPrice: 288, tasks: '24000' },
  { maxPrice: 300, tasks: '25000' },
  { maxPrice: 312, tasks: '26000' },
  { maxPrice: 324, tasks: '27000' },
  { maxPrice: 336, tasks: '28000' },
  { maxPrice: 348, tasks: '29000' },
  { maxPrice: 360, tasks: '30000' },
  { maxPrice: 372, tasks: '31000' },
  { maxPrice: 384, tasks: '32000' },
  { maxPrice: 396, tasks: '33000' },
  { maxPrice: 408, tasks: '34000' },
  { maxPrice: 420, tasks: '35000' },
  { maxPrice: 432, tasks: '36000' },
  { maxPrice: 444, tasks: '37000' },
  { maxPrice: 456, tasks: '38000' },
  { maxPrice: 468, tasks: '39000' },
  { maxPrice: 480, tasks: '40000' },
  { maxPrice: 492, tasks: '41000' },
  { maxPrice: Infinity, tasks: '41000' },
];
