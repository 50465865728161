import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import * as S from './style';
import { axiosInstance } from 'configs/request';
import { ReactComponent as CheckDoneIcon } from 'assets/icons/check_done.svg';
import { ReactComponent as SendEmailIcon } from 'assets/icons/send_email.svg';
import CarouselFeatureWeb from './CarouselFeatureWeb';
import CarouselFeatureApp from './CarouselFeatureApp';
import { maximizeCheckList } from 'redux/onboarding/actions';
import { CDN_URL } from 'constants/commonData';

const MobileOnboardingWelcomeVideo = props => {
  const [isSent, setSent] = useState(false);
  const [onSending, setSending] = useState(false);

  useLayoutEffect(() => {
    // remove default theme color
    const themeColorDOM = document.querySelector("[name='theme-color']");
    if (themeColorDOM) {
      themeColorDOM.remove();
    }
    // hidden intercom
    const intercomDOM = document.querySelector("[class='intercom-lightweight-app']");
    if (intercomDOM) {
      intercomDOM.style.display = 'none';
    }
    return () => {
      // show intercom
      if (intercomDOM) {
        intercomDOM.style.display = 'unset';
      }
    };
  }, []);

  const handleSendLink = async () => {
    if (onSending) return;
    try {
      setSending(true);
      const { email } = props;
      const response = await axiosInstance.post('/api/send-mail/send-download-link-coach', { email });
      if (response) {
        toast('The app download link has been sent successfully to your email.');
        setSent(true);
      }
    } catch (error) {
      toast.error('Connection lost. Please try again.');
      setSent(false);
    } finally {
      setSending(false);
    }
  };

  const handleStart = () => {
    props.push('/home/client');
    props.maximizeCheckList();
  };

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#efff39" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Helmet>
      <S.MobileWrapper>
        <S.TitleWrapper>Before you start</S.TitleWrapper>
        <S.DescriptionWrapper>
          Introducing Everfit’s Coaching Duo: <br />
          Web & Mobile
        </S.DescriptionWrapper>

        <S.FeaturesBannerImage src={`${CDN_URL}/images/mobile-onboarding-welcome-video.png`} alt="" />

        <S.SuggestDownloadTittleWrapper>
          Send me the app download <br />
          link via email
        </S.SuggestDownloadTittleWrapper>
        <S.ContentWrapper>
          <S.ButtonSend onClick={handleSendLink} isSent={isSent} disabled={onSending || isSent}>
            <SendEmailIcon
              className={cx({
                hidden: isSent,
              })}
            />
            <CheckDoneIcon
              className={cx({
                hidden: !isSent,
              })}
            />
            <span>{isSent ? 'Sent' : 'Send'}</span>
          </S.ButtonSend>
        </S.ContentWrapper>

        <S.IntroductionWrapper>
          <div>
            Everfit’s <b>Web platform</b> has the full set of features Everfit has to offer and is designed to work best
            from a computer, giving you more room for planning and the ease of typing any message or instruction.
          </div>
          <div>
            The <b>Everfit Coach app</b> is best paired as your on-the-go assistant. The mobile app offers convenience
            for direct messaging, assigning programming for clients, creation of exercises and workouts on the fly, and
            more.
          </div>
        </S.IntroductionWrapper>

        <S.SuggestFeaturesWeb>
          Explore the most powerful <br />
          Features on the Web
        </S.SuggestFeaturesWeb>
        <CarouselFeatureWeb />

        <S.SuggestCoachAppTittleWrapper>Everfit Coach App</S.SuggestCoachAppTittleWrapper>
        <CarouselFeatureApp />

        <S.ContentWrapper contentCenter>
          <S.ButtonStart onClick={handleStart} disabled={onSending}>
            <span>Let's Start</span>
          </S.ButtonStart>
        </S.ContentWrapper>
      </S.MobileWrapper>
    </>
  );
};

const mapStateToProps = state => {
  const {
    user: { email },
  } = state;
  return {
    email,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    maximizeCheckList: bindActionCreators(maximizeCheckList, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileOnboardingWelcomeVideo);
