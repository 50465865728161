import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

const ChartPadding = 10;
const ChartBuffer = 8;
const verticalPadding = 8;
const verticalChartHeight = 233;

const CustomLineCursor = ({
  color,
  className,
  points,
  payload,
  areaRef,
  updateTooltipBarChart,
  isOverview,
  isModeCompare = false,
  isFillTransparent = false,
  lineColor = '',
  isChartCompare = false,
  chartInfor = {},
  payloadIndex = 0,
  bodyMetricId = '',
  isHoveringChart,
}) => {
  const toggleDotsDisplay = () => {
    const dotClass = isModeCompare ? `custom-dot` : `custom-dot-${bodyMetricId}`;
    const dots = document.getElementsByClassName(dotClass);
    for (let i = 0; i < dots.length; i++) {
      if (dots[i]) {
        const dotClass2 = `dot-${payloadIndex}`;
        const isContainClass = dots[i].classList.contains(dotClass2);
        dots[i].style.display = !isContainClass || !isHoveringChart ? 'unset' : 'none';
      }
    }
  };

  if (!!payload && get(payload[0], 'payload.value') === undefined) {
    toggleDotsDisplay();
    return null;
  }

  useEffect(() => {
    toggleDotsDisplay();
  }, [payloadIndex, isHoveringChart]);

  const [{ x }] = points || [{ x: 0 }];
  const { yAxis: { height: chartHeight } = { height: 0 } } = (areaRef || { props: { yAxis: undefined } }).props;
  const pointsAreaRef = areaRef.props && areaRef.props.points;
  const pointAreaRef = pointsAreaRef && pointsAreaRef[payloadIndex];
  const chartWithoutPadding = chartHeight - ChartPadding;
  let y = pointAreaRef && pointAreaRef.y !== undefined ? pointAreaRef.y - ChartBuffer : undefined;
  const finalHeightViewBox =
    chartWithoutPadding - y + ChartPadding + 4 + (isOverview ? 4 : 0) + (isModeCompare ? verticalChartHeight : 0);

  const updateTooltipBarChartCallback = useCallback(data => {
    if (updateTooltipBarChart) {
      updateTooltipBarChart(data);
    }
  }, []);

  const updateTooltipCallbackDebounced = debounce(updateTooltipBarChartCallback, 300);

  useEffect(() => {
    updateTooltipCallbackDebounced({ x: x - 8, y });
  }, [x, y]);

  let height = finalHeightViewBox < 16 ? 16 : finalHeightViewBox;

  // Calculate position Y and the height of the line when comparing charts.
  let finalPositionY = 0;
  let finalLineHeight = 0;

  if (isChartCompare && isModeCompare) {
    const { areaRef, compareAreaRef, boundingAxisBar1, boundingChartRef, boundingCompareChartRef, noDataBar1 } =
      chartInfor || {};

    const pointsAreaRef = get(areaRef, 'props.points', []);
    const pointsCompareAreaRef = get(compareAreaRef, 'props.points', []);
    const heightCompareAreaRef = get(compareAreaRef, 'props.yAxis.height', 0);
    const yCompareAreaRef = get(compareAreaRef, 'props.yAxis.y', 0);

    const findIndexPoint = pointsCompareAreaRef.findIndex(point => get(point, 'x', 0) === x);
    const isNoDataChartRef = findIndexPoint !== -1 && get(pointsAreaRef[findIndexPoint], 'payload.value') === undefined;

    if (findIndexPoint !== -1 && !noDataBar1) {
      const positionYCompareChartRef =
        get(boundingCompareChartRef, 'top', 0) + get(pointsCompareAreaRef[findIndexPoint], 'y', 0);
      const heightCompareChart = heightCompareAreaRef - y;

      if (isNoDataChartRef) {
        const positionYAxisBar1 = get(boundingAxisBar1, 'y');
        finalPositionY = positionYCompareChartRef - positionYAxisBar1 - verticalPadding;
      } else {
        const positionYAreaChartRef = get(boundingChartRef, 'top', 0) + get(pointsAreaRef[findIndexPoint], 'y', 0);
        finalPositionY = positionYCompareChartRef - positionYAreaChartRef;
      }

      finalLineHeight = heightCompareChart + finalPositionY + yCompareAreaRef;
    } else if (noDataBar1) {
      finalPositionY = 0;
      finalLineHeight = finalHeightViewBox - verticalChartHeight;
    }
  }

  const w = isOverview ? 12 : 16;
  let h = isOverview ? height - 4 : height;
  h = h < 12 ? 12 : h;
  const cx = isOverview ? 6 : 8;
  const cr = isOverview ? 3 : 4;
  const cT = isOverview ? 1 : 2;
  const cy = isOverview ? y + 2 : y;

  const renderSVG = () => {
    return (
      <>
        <svg
          width="16"
          height={finalLineHeight}
          x={x - 8}
          y={y}
          viewBox={`0 0 16 ${finalLineHeight}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <circle cx="8" cy="8" r="4" fill="none" stroke={color} strokeWidth="2" />
        </svg>
        <svg
          width="16"
          height={finalLineHeight}
          x={x - 8}
          y={y - finalPositionY}
          viewBox={`0 0 16 ${finalLineHeight}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <rect
            width="16"
            height={finalLineHeight}
            rx="8"
            fill={lineColor || color}
            fillOpacity={isFillTransparent ? 0 : 0.12}
          />
        </svg>
      </>
    );
  };

  return (
    <>
      {isChartCompare ? (
        renderSVG()
      ) : (
        <svg
          width={w}
          height={h}
          x={x - (isOverview ? 6 : 8)}
          y={cy}
          viewBox={`0 0 ${w} ${h}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <rect width={w} height={h} rx={cx} fill={lineColor || color} fillOpacity={isFillTransparent ? 0 : 0.12} />
          <circle
            cx={cx}
            cy={cx}
            r={cr}
            fill="none"
            stroke={color}
            strokeWidth={cT}
            strokeOpacity={isHoveringChart ? 1 : 0}
            fillOpacity={isHoveringChart ? 1 : 0}
          />
        </svg>
      )}
    </>
  );
};

export default CustomLineCursor;
