import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';

import UpgradePath from 'shared/UpgradePath';

import { ForumAvatar, PlusButton, SPopup, ContentPopup, ForumArea, ForumListSidebarContent } from '../../styles';
import { ReactComponent as PlusIcon } from 'assets/icons/sidebar_plus_icon.svg';
import { isS3FileURL } from 'utils/validations';
import { convertS3UrlToCloudFrontUrl, getFormatImageUrlWithSML, replaceImageURL } from 'utils/commonFunction';
import { connect } from 'react-redux';
import { SharedTooltip } from 'shared/SharedTooltip';
import { TooltipPortal } from 'components/SegmentSidebar/TooltipPortal';

const CONVERTED_FOLDER = 'thumbnails-converted';

function SidebarForum(props) {
  const { forumList, onCutStringEllipsis, currentSelectedForumId, cloudfrontList } = props;
  const handleForumWhiteDot = item => {
    const active = _.get(item, 'isUnread', false);
    return `white-dot${active ? ' active' : ''}`;
  };

  const handleResetScroll = () => {
    const forumContainer = document.getElementById('timeLine');
    if (forumContainer) {
      forumContainer.scrollTop = 0;
    }
  };

  return (
    <UpgradePath pathName="forum" fallback={<></>}>
      {forumList.length > 0 && (
        <ForumListSidebarContent className="sidebar-content forum-sidebar" forums={true}>
          <ForumArea loading={props.isLoading ? true : false}>
            {forumList.map(forum => {
              let src = '';

              if (forum && forum.banner_thumbnail) {
                src = forum.banner_thumbnail;
              } else {
                src = forum && forum.banner && isS3FileURL(forum.banner) ? replaceImageURL(forum.banner) : '';
              }
              if (src.includes(CONVERTED_FOLDER)) {
                src = getFormatImageUrlWithSML(src, 'm');
              }

              return (
                <SPopup
                  loading={props.isLoading}
                  trigger={
                    <ForumAvatar
                      src={convertS3UrlToCloudFrontUrl(src, cloudfrontList, true)}
                      onClick={() => {
                        if (props.isLoading) {
                          return;
                        }
                        if (props.currentSelectedForumId !== forum._id) {
                          handleResetScroll();
                          props.resetLoadPostsQuery();
                          props.resetChangeForum();
                          props.selectGroup(forum._id);
                          props.markReadForumIndicator({ groupId: forum._id });
                          props.dispatch(push(`/home/forums/${forum._id}/discussion`));
                        }
                      }}
                      isSelected={forum._id === currentSelectedForumId}
                    >
                      <span className={handleForumWhiteDot(forum)}></span>
                      <div className="border-hover"></div>
                    </ForumAvatar>
                  }
                  content={<ContentPopup>{onCutStringEllipsis(forum.name, 9)}</ContentPopup>}
                  position="right center"
                  inverted
                />
              );
            })}
          </ForumArea>
          <PlusButton
            textOnly
            onClick={() => {
              props.dispatch(push(`/home/forums/create-forum`));
            }}
            data-for="new-forum-icon-tooltip"
            data-tip
          >
            <PlusIcon />
          </PlusButton>
          <TooltipPortal>
            <SharedTooltip id="new-forum-icon-tooltip" content="Create new forum" className="new-forum-icon-tooltip" />
          </TooltipPortal>
        </ForumListSidebarContent>
      )}
    </UpgradePath>
  );
}

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default connect(mapState)(SidebarForum);
