import _ from 'lodash';
import React, { useState } from 'react';
import { Checkbox } from 'shared/FormControl';
import { taskType } from 'components/TasksLibrary/constants';

import * as S from '../ExerciseFilterPopup/style';

function SelectTaskType(props) {
  const { types, onSelectTaskType } = props;
  const [openedSection, setOpenSection] = useState(true);

  return (
    <S.Section expand={openedSection} className="task-type">
      <S.SectionHeader onClick={() => setOpenSection(!openedSection)}>
        <S.SectionTitle>Task Type</S.SectionTitle>
        <S.SectionToggleIcon />
      </S.SectionHeader>
      {openedSection && (
        <S.SectionBody>
          {_.map(taskType, item => (
            <div className="checkbox-container" key={item._id}>
              <Checkbox
                title={item.title}
                checked={types.includes(item._id)}
                onChange={event => onSelectTaskType(item, event.target.checked)}
                size={15}
              />
            </div>
          ))}
        </S.SectionBody>
      )}
    </S.Section>
  );
}

export default SelectTaskType;
