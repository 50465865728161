import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { toggleModal } from 'actions/modal';
import { MultiplePasteMessage } from 'shared/Notifications';
import Request from 'configs/request';
import { Types as AutoflowTypes } from 'redux/autoflow/actions';
import { Mixpanel } from 'utils/mixplanel';

const DATE_FORMAT = 'MM-DD-YYYY';

export const Types = {
  AUTOFLOW_SUCCESS_GET_TASKS: 'AUTOFLOW_SUCCESS_GET_TASKS',
  AUTOFLOW_FAILED_GET_TASKS: 'AUTOFLOW_FAILED_GET_TASKS',
  AUTOFLOW_COPY_TASK: 'AUTOFLOW_COPY_TASK',
  AUTOFLOW_SUCCESS_DELETE_TASK: 'AUTOFLOW_SUCCESS_DELETE_TASK',
  AUTOFLOW_TASK_CLEAR_COPY_ITEM: 'AUTOFLOW_TASK_CLEAR_COPY_ITEM',
  AUTOFLOW_TASK_SUCCESS_ARRANGE_TASK: 'AUTOFLOW_TASK_SUCCESS_ARRANGE_TASK',
  AUTOFLOW_TASK_RESET_DATA: 'AUTOFLOW_TASK_RESET_DATA',
  AUTOFLOW_SUCCESS_ASSIGN_TASK: 'AUTOFLOW_SUCCESS_ASSIGN_TASK',
  AUTOFLOW_SUCCESS_UPDATE_TASK: 'AUTOFLOW_SUCCESS_UPDATE_TASK',
  AUTOFLOW_SUCCESS_ARRANGE_TASK: 'AUTOFLOW_SUCCESS_ARRANGE_TASK',
  AUTOFLOW_MOVE_TASK: 'AUTOFLOW_MOVE_TASK',
  AUTOFLOW_REQUEST_MOVE_TASK: 'AUTOFLOW_REQUEST_MOVE_TASK',
  AUTOFLOW_FAILED_MOVE_TASK: 'AUTOFLOW_FAILED_MOVE_TASK',
  AUTOFLOW_SUCCESS_MOVE_TASK: 'AUTOFLOW_SUCCESS_MOVE_TASK',
  AUTOFLOW_SUCCESS_SUBMIT_TASK: 'AUTOFLOW_SUCCESS_SUBMIT_TASK',
  AUTOFLOW_CREATED_TASK_SUCCESS: 'AUTOFLOW_CREATED_TASK_SUCCESS',
  AUTOFLOW_REMOVED_TASK_SUCCESS: 'AUTOFLOW_REMOVED_TASK_SUCCESS',
  AUTOFLOW_SUCCESS_REMOVE_SETTING_REPEAT: 'AUTOFLOW_SUCCESS_REMOVE_SETTING_REPEAT',
};

export const resetData = () => ({ type: Types.AUTOFLOW_TASK_RESET_DATA });

export const successAssignTask = data => ({ type: Types.AUTOFLOW_SUCCESS_ASSIGN_TASK, payload: { data } });

export const changeViewMode = mode => {
  return dispatch => {
    dispatch({
      type: AutoflowTypes.AUTOGLOW_CHANGE_VIEW_MODE,
      payload: { mode },
    });
    dispatch(getListTasks());
  };
};

export const changeStartDate = startDate => {
  return dispatch => {
    dispatch({
      type: AutoflowTypes.AUTOGLOW_CHANGE_START_DATE,
      payload: { startDate },
    });
    dispatch(getListTasks());
  };
};

export const createdTaskAutoflowExactDateSuccess = data => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_CREATED_TASK_SUCCESS,
      payload: { data },
    });
  };
};

export const removedTaskAutoflowExactDateSuccess = data => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_REMOVED_TASK_SUCCESS,
      payload: { data },
    });
  };
};

export const resetCopyItem = () => ({ type: Types.AUTOFLOW_TASK_CLEAR_COPY_ITEM });

export const pasteTask = (params, multiPaste) => {
  return dispatch => {
    if (!multiPaste) {
      dispatch(resetCopyItem());
    }

    return dispatch(
      Request.post({ url: '/api/autoflow/task/copy', data: params }, true, response => {
        const { data } = response.data;
        dispatch(successAssignTask(data));
        Mixpanel.track('autoflow_exact_date_task_calendar_screen_copy_task');
      }),
    );
  };
};

export const copyTask = data => {
  toast(<MultiplePasteMessage title="tasks" />);
  return { type: Types.AUTOFLOW_COPY_TASK, payload: { data } };
};

export const getListTasks = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { viewMode, startDate } = autoflow.common;
    const { workingAutoflow } = autoflow.common;

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_ITEM' });
    }

    const start = moment(startDate, DATE_FORMAT);
    const params = {
      autoflow: workingAutoflow._id,
      start_date: startDate,
      end_date: start.add(7 * viewMode, 'day').format(DATE_FORMAT),
    };

    return dispatch(
      Request.get(
        { url: '/api/autoflow/task/list', params },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.AUTOFLOW_SUCCESS_GET_TASKS, payload: { data } });
        },
        () => {
          dispatch({ type: Types.AUTOFLOW_FAILED_GET_TASKS });
        },
      ),
    );
  };
};

export const submitTask = (params, useFormData) => {
  return (dispatch, getState) => {
    let requestBody, taskId;
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { workingAutoflow } = autoflow.common;

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_ITEM' });
    }

    if (useFormData) {
      requestBody = params;
      requestBody.append('autoflow', workingAutoflow._id);
      requestBody.delete('client');
      taskId = requestBody.get('taskId');
      const date = requestBody.get('date');

      if (date) {
        requestBody.delete('date');
        requestBody.append('day', moment(date).format(DATE_FORMAT));
      } else {
        return dispatch({ type: 'AUTOFLOW_SUBMIT_TASK_DAY_UNDEFINED' });
      }
    } else {
      requestBody = Object.assign({}, params);
      requestBody.autoflow = workingAutoflow._id;
      delete requestBody.client;
      taskId = requestBody.taskId;
      const date = requestBody.date;

      if (date) {
        delete requestBody.date;
        requestBody.day = moment(date).format(DATE_FORMAT);
      } else {
        return dispatch({ type: 'AUTOFLOW_SUBMIT_TASK_DAY_UNDEFINED' });
      }
    }

    return dispatch(
      Request.dynamicConfigs(
        {
          url: `/api/autoflow/task/v2/${taskId ? 'update' : 'add'}`,
          method: taskId ? 'put' : 'post',
          data: requestBody,
        },
        true,
        () => {
          if (taskId) {
            Mixpanel.track('autoflow_exact_date_task_calendar_screen_update_task');
          } else {
            Mixpanel.track('autoflow_exact_date_task_calendar_screen_add_task');
          }
          dispatch({ type: Types.AUTOFLOW_SUCCESS_SUBMIT_TASK });
          dispatch(getListTasks());
        },
      ),
    );
  };
};

export const submitTaskFromLibrary = (params, useFormData) => {
  return (dispatch, getState) => {
    let requestBody, taskId;
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { workingAutoflow } = autoflow.common;

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_ITEM' });
    }

    if (useFormData) {
      requestBody = params;
      requestBody.append('autoflow', workingAutoflow._id);
      requestBody.delete('client');
      taskId = requestBody.get('taskId');
      const date = requestBody.get('date');

      if (date) {
        requestBody.delete('date');
        requestBody.append('day', moment(date).format(DATE_FORMAT));
      } else {
        return dispatch({ type: 'AUTOFLOW_SUBMIT_TASK_DAY_UNDEFINED' });
      }
    } else {
      requestBody = Object.assign({}, params);
      requestBody.autoflow = workingAutoflow._id;
      delete requestBody.client;
      taskId = requestBody.taskId;
      const date = requestBody.date;

      if (date) {
        delete requestBody.date;
        requestBody.day = moment(date).format(DATE_FORMAT);
      } else {
        return dispatch({ type: 'AUTOFLOW_SUBMIT_TASK_DAY_UNDEFINED' });
      }
    }

    return dispatch(
      Request.dynamicConfigs(
        {
          url: `/api/autoflow/task/add-task-from-library`,
          method: 'post',
          data: requestBody,
        },
        true,
        () => {
          dispatch({ type: Types.AUTOFLOW_SUCCESS_SUBMIT_TASK });
          dispatch(getListTasks());
          dispatch(toggleModal(false));
        },
      ),
    );
  };
};

export const arrangeTask = params => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_MOVE_TASK, payload: { data: params } });
    return dispatch(
      Request.put(
        { url: '/api/autoflow/task/arrange', data: params },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.AUTOFLOW_SUCCESS_ARRANGE_TASK, payload: { data } });
        },
        error => dispatch({ type: 'AUTOFLOW_FAILED_ARRANGE_TASK' }),
      ),
    );
  };
};

export const moveTask = params => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_REQUEST_MOVE_TASK });
    dispatch({ type: Types.AUTOFLOW_MOVE_TASK, payload: { data: params } });
    return dispatch(
      Request.put(
        { url: '/api/autoflow/task/move', data: params },
        true,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.AUTOFLOW_SUCCESS_MOVE_TASK, payload: { data } });
        },
        error => dispatch({ type: Types.AUTOFLOW_FAILED_MOVE_TASK }),
      ),
    );
  };
};

export const removeSettingRepeat = data => {
  const END_POINT = '/api/autoflow/task/v2/remove-repeat-setting';
  return Request.post(
    {
      url: END_POINT,
      data: {
        taskId: data.taskId,
        autoflow: data.autoflowId,
      },
    },
    true,
    (res, { dispatch }) => {
      if (data.repeatId) {
        dispatch({ type: Types.AUTOFLOW_SUCCESS_REMOVE_SETTING_REPEAT, payload: { data } });
        dispatch(getListTasks());
      }
    },
  );
};

export const deleteTask = params => {
  return Request.delete({ url: '/api/autoflow/task/remove', params }, true, (response, { dispatch }) => {
    dispatch({ type: Types.AUTOFLOW_SUCCESS_DELETE_TASK });
    dispatch(getListTasks());
    Mixpanel.track('autoflow_exact_date_task_calendar_screen_delete_task');
  });
};
