import React from 'react';
import classnames from 'classnames';
import Avatar from 'react-avatar';
import { TopUserItem } from './style';
import { formatLeaderboardRanking, getUserShortName } from 'utils/commonFunction';
import { ReactComponent as CupIcon } from 'assets/icons/cup_blur.svg';
import { ReactComponent as UserDefaultAvatar } from 'assets/icons/user_default_avatar.svg';
import { LEADERBOARD_STATUS } from 'constants/commonData';

export default ({ firstPlace, secondPlace, thirdPlace, unit, status }) =>
  status === LEADERBOARD_STATUS.UPCOMING ? (
    <div className={classnames('empty', { upcoming: status === LEADERBOARD_STATUS.UPCOMING })}>
      <CupIcon />
      {status === LEADERBOARD_STATUS.UPCOMING ? <div>Leaderboard has not started</div> : null}
    </div>
  ) : (
    <div className="topUser">
      <TopUserItem key="top2">
        <div className="userInfo">
          {secondPlace ? (
            <>
              <Avatar
                name={getUserShortName(secondPlace.user)}
                size="36"
                src={secondPlace.user.avatar}
                color={secondPlace.user.color}
              />
              <div className="userName">
                {secondPlace.user.first_name} {secondPlace.user.last_name}
              </div>
              <div className="score">
                {Number(secondPlace.score).toSeperateFormat()} {formatLeaderboardRanking(unit, secondPlace.score)}
              </div>
            </>
          ) : (
            <>
              <UserDefaultAvatar className="sb-avatar" />
              <div className="userName" style={{ opacity: 0.7 }}>
                -
              </div>
            </>
          )}
        </div>
        <div className="rankingPosition top2"></div>
      </TopUserItem>
      <TopUserItem key="top1">
        <div className="userInfo">
          {firstPlace ? (
            <>
              <Avatar
                name={getUserShortName(firstPlace.user)}
                size="36"
                src={firstPlace.user.avatar}
                color={firstPlace.user.color}
              />
              <div className="userName">
                {firstPlace.user.first_name} {firstPlace.user.last_name}
              </div>
              <div className="score">
                {Number(firstPlace.score).toSeperateFormat()} {formatLeaderboardRanking(unit, firstPlace.score)}
              </div>
            </>
          ) : (
            <>
              <UserDefaultAvatar className="sb-avatar" />
              <div className="userName" style={{ opacity: 0.7 }}>
                -
              </div>
            </>
          )}
        </div>
        <div className="rankingPosition top1"></div>
      </TopUserItem>
      <TopUserItem key="top3">
        <div className="userInfo">
          {thirdPlace ? (
            <>
              <Avatar
                name={getUserShortName(thirdPlace.user)}
                size="36"
                src={thirdPlace.user.avatar}
                color={thirdPlace.user.color}
              />
              <div className="userName">
                {thirdPlace.user.first_name} {thirdPlace.user.last_name}
              </div>
              <div className="score">
                {Number(thirdPlace.score).toSeperateFormat()} {formatLeaderboardRanking(unit, thirdPlace.score)}
              </div>
            </>
          ) : (
            <>
              <UserDefaultAvatar className="sb-avatar" />
              <div className="userName" style={{ opacity: 0.7 }}>
                -
              </div>
            </>
          )}
        </div>
        <div className="rankingPosition top3"></div>
      </TopUserItem>
    </div>
  );
