import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Wrapper, PackageStatusWrapper } from './style';
// import UpgradePath from 'shared/UpgradePath';
import { Helmet } from 'react-helmet';
import { PACKAGE_STATUS } from 'constants/commonData';

const PackageStatus = ({ status }) => {
  return (
    <PackageStatusWrapper isPublished={status === PACKAGE_STATUS.PUBLISH}>
      {status === PACKAGE_STATUS.PUBLISH ? 'Live' : 'Draft'}
    </PackageStatusWrapper>
  );
};

const PackageDetailHeader = props => {
  const { workingPackage } = props;
  const packageName = workingPackage && workingPackage.get('name') || '';
  const shortPackageName = `${packageName.substring(0, 70)}...`;
  const isLongName = packageName.length > 70;

  if (!workingPackage) {
    return null;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{workingPackage.get('name')} - Package - Everfit</title>
      </Helmet>
      <div className="package-detail__name">
        <span>
          {/* <UpgradePath pathName="package_payment" fallback="Studio"> */}
          {isLongName ? shortPackageName : packageName}
          {/* </UpgradePath> */}
        </span>
        {workingPackage.get('id') && <PackageStatus status={workingPackage.get('status')} />}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { packageDetail },
    router,
  } = state;

  return {
    workingPackage: packageDetail.get('workingPackage'),
    location: router.location,
  };
};

export default connect(mapStateToProps, null)(PackageDetailHeader);
