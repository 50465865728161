import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import styled, { css } from 'styled-components';
import { ReminderSettingWrapper, SettingWrapper, TimeSettingWrapper } from '../CreateNewCountdown/style';

const TextBase = css`
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 150%;
`;

export const EditPopupStyled = styled(Modal)`
  &.ui.modal {
    width: 566px;
    height: 617px;
  }

  &.ui.modal > :last-child {
    padding: 0 !important;
  }

  .countdown-wrapper {
    display: flex;
    flex-direction: column;
    height: 478px;
    padding: 0 24px;
    ${TextBase}

    ${TimeSettingWrapper} {
      margin-bottom: 9px;
    }

    ${SettingWrapper} {
      height: 63px;
    }

    ${ReminderSettingWrapper} {
      margin-top: 7px;
      font-size: 13px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 77px;
    padding: 16px 24px 16px 24px;
    gap: 16px;
    border-radius: 0px 0px 8px 8px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    box-shadow: 0px -1px 7px 0px rgba(0, 0, 0, 0.08);
  }
`;

export const PopupTitle = styled.div`
  ${TextBase}
  font-size: 16px;
  text-align: left;
  padding: 20px 24px;
`;

export const CreateButton = styled(Button)`
  display: flex;
  padding: 8px 16px;
  min-height: 45px;
  height: 45px;
  border-radius: 4px;
  width: 123px;
  font-size: 14px;
`;
