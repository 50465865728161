import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f7f9;
  border-radius: 5px;
  padding: 7px 9px 7px 14px;
  height: 38px;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 5px;

  :hover {
    border-color: #5c5bbd;
  }

  .box__avatar {
    height: 34px;
    width: 34px;
    background-position: center;
    background-size: cover;
    border-radius: 3px;
    margin-right: 8px;
    margin-left: -10px;
    overflow: hidden;
  }

  .box__left {
    position: relative;
    flex: 1 1;
    height: 100%;

    .ex-tooltip {
      padding: 15px;
      z-index: 999;
      height: 50px;
      font-size: 13px;
      line-height: 19.5px;
      font-weight: 400;

      &::after {
        border-top-color: #2d2e2d !important;
        border-top-width: 7px !important;
        bottom: -6px !important;
        margin-bottom: 0px !important;
      }
    }

    &.has-warning {
      height: 38px;

      input.mirror {
        max-width: calc(100% - 24px);
      }
    }

    svg.waring-icon {
      margin-right: 1px;
      position: relative;
      top: 6px;
      left: -7px;
      z-index: 11;
    }

    input {
      background-color: transparent;
      font-size: 13px;
      line-height: 1.43;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #323c47;
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
      color: #2e2e2e;
      z-index: 2;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      padding: 0;

      &.mirror {
        z-index: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #bebebe;
        font-weight: normal;

        :not(:placeholder-shown) {
          color: #202353;
          font-weight: 600;
          ::placeholder {
            color: transparent;
          }
        }
      }
    }
  }

  .box__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-left: 10px;

    button.use_1rm,
    button.create-exercise {
      cursor: pointer;
      padding: 3px 10px;
      border: none;
      border-radius: 5px;
      outline: none;
      background-color: #fff;
      box-shadow: none;
      font-weight: 600;
      font-size: 11px;
      line-height: 15px;
      color: #758094;
    }

    button.use_1rm {
      background-color: rgba(219, 221, 226, 0.5);
      margin-left: 8px;
      &.active {
        color: #fff;
        background-color: #5158cf;
      }
    }

    button.create-exercise {
      background-color: transparent;
      color: #5158cf;
      height: 21px;
      display: flex;
      align-items: center;
      border: 1px solid #c9cbf4;
      border-radius: 4px;
      padding: 4px 6px;
      height: 26px;
      font-size: 13px;
      font-weight: 600;
      line-height: 17.7px;

      &:hover {
        border-color: #dee0ff;
        background-color: #dee0ff;
      }
    }

    .exercise-filter {
      margin-right: 0;

      .exercise-filter__trigger {
        > div {
          width: 32px;
          height: 22px;
          background-size: 10px;
        }
      }

      .exercise-filter__count {
        width: 16px;
        font-size: 9px;
        height: 16px;
      }
    }

    .reset-filter {
      margin-right: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #5c5bbd;
      cursor: pointer;
      display: inline-block;

      :hover {
        text-decoration: underline;
      }
    }
  }

  .divide {
    height: 20px;
    background-color: rgb(204, 204, 204);
    width: 1px;
    box-sizing: border-box;
    margin: auto 4px;
  }
`;

export const ExerciseThumbnail = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 3px;
  background-color: #eaebef;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${props =>
    props.noThumbnail &&
    css`
      img {
        width: auto;
        height: auto;
        object-fit: unset;
      }
    `}

  ${props =>
    props.hasVideo &&
    css`
      ::before {
        content: '';
        position: absolute;
        left: 3px;
        bottom: 3px;
        width: 12px;
        height: 12px;
        background: url(${CDN_URL}/images/video_play.svg) no-repeat;
        background-size: contain;
      }
    `}
`;

export const ExerciseName = styled.div`
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ExerciseCustom = styled.div`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 4px 6px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  color: #c4c4c4;
  margin-left: 5px;
`;

export const ExerciseBasicInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  ${props =>
    props.isShowNameMultiline &&
    css`
      flex: 1 1;
    `}
`;

export const ExerciseOwner = styled.div`
  flex: 0 0 20px;
  margin-left: 10px;
  opacity: 0.4;

  :hover {
    opacity: 1;
  }

  .app-tooltip {
    padding: 15px !important;
    background: #231f20 !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 120% !important;
    color: #ffffff;
    text-align: left !important;

    .title {
      font-weight: normal !important;
    }
  }
`;

export const Exercise = styled.div`
  padding: 8px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .owner {
    margin-left: 10px;
  }

  ${props =>
    props.isShowNameMultiline &&
    css`
      gap: 4px;

      .owner {
        margin-left: unset;
      }
    `}
`;

export const CreateNew = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  background: #f4f4ff;
  border-radius: 5px;
  padding: 12px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #5c5bbd;
  text-transform: none;

  :hover,
  &.selecting {
    background-color: #5c5bbd;
    color: #ffffff;
  }
`;

export const OptionHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000000;
  padding: 10px 8px;
  text-transform: uppercase;

  ${props =>
    props.noResults &&
    css`
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      opacity: 0.3;
      text-align: center;
      text-transform: none;
    `}
`;

export const ListExercise = styled.div`
  flex: 1 1;
  overflow: auto;
  .loading-exercise {
    padding-top: 10px;
    .custom-indicator.ui.loader::before {
      border-color: rgba(0, 0, 0, 0.1) !important;
    }
    .custom-indicator.ui.loader:after {
      border-color: #5158cf transparent transparent !important;
    }
  }
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  > * {
    margin-right: 5px;

    :last-child {
      margin-right: 0;
    }
  }

  .filter-custom-checkbox {
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #a2a8b0;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: ${props => props.maxHeight || '230px'};
  min-width: 370px;
  position: fixed;
  background-color: #fff;
  overflow: hidden;
  z-index: 99999;
  border: 1px solid #dcdcde;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 10px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #323c47;
      text-transform: none;

      :hover,
      &.active {
        color: #5c54c3;
        background: #f4f4ff;
        border-radius: 5px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: block;
  position: relative;
  flex: 1;

  &.input--focus {
    ${SearchBox} {
      border-color: #5c5bbd;
      background-color: #fff;
    }
  }
`;

export const Thumbnail = styled.div`
  background: url(${props => props.thumbnail || `${CDN_URL}/images/exercise_grey.svg`}) no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-blend-mode: multiply;
  position: relative;

  ${props =>
    !props.thumbnail &&
    css`
      background-size: auto;
    `}
`;

export const ExerciseNameMultiline = styled.div`
  flex: 1 1;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  width: 100%;

  .name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #202353;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 60px;
    line-height: 20px;
    white-space: pre-line;
    word-break: break-word;
  }
`;

export const TagCustomLabel = styled.div`
  height: 20px;

  .tag-custom {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    color: rgba(32, 35, 83, 0.314);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    gap: 10px;

    width: 49px;
    height: 19px;

    background: #f6f7f9;
    border-radius: 2px;
  }
`;
