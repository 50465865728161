import styled from 'styled-components';
import { MODE } from './index';

export const Wrapper = styled.div`
  .tags-select__menu {
    margin-top: 1px;
  }
  .tags-select__value-container.tags-select__value-container--is-multi {
    ${'' /* padding: 10px; */}
    padding-left: 10px;
    display: inline-flex;
    max-height: 70px;
    flex-wrap: wrap;
    overflow: auto;
    overflow-y: auto;

    ${'' /* &::-webkit-scrollbar {
      width: 3px !important;
    }

    &::-webkit-scrollbar-track {
      margin-block: 7px;
    } */}
  }
  .tags-select__menu-list.tags-select__menu-list--is-multi {
    padding: 10px;
  }
  .tags-select__placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #000000;
    opacity: 0.3;
  }
  .tags-select__multi-value__label {
    padding-right: 0px;
  }
  .tags-select__multi-value__remove {
    cursor: pointer;
    :hover {
      background-color: initial;
      color: #5d5bbe;
    }
  }
  .tags-select__control,
  .tags-select__control--is-focused {
    border: 1px solid #dcdcde;
    box-shadow: none;
    :hover {
      border: 1px solid #dcdcde;
    }
  }

  .tags-select__control--menu-is-open {
    border: 1px solid #7072dd;
    :hover {
      border: 1px solid #7072dd;
    }
  }
`;

export const TagsMenuListTitle = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  text-transform: uppercase;
  color: #000000;
  margin-left: ${props => (props.mode === MODE.DEFAULT ? '5px' : '10px')};
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 10px 15px;
  background: ${props => (props.isSelected ? '#818DA1' : '#f3f5f8')};
  border: 1px solid #e0e5ed;
  border-radius: 453px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: ${props => (props.isSelected ? '#ffffff' : '#818da1')};
  margin: 4px;
  cursor: pointer;

  :hover {
    background-color: ${props => (props.isSelected ? '#636C7B' : '#e1e6ef')};
    border-color: ${props => (props.isSelected ? '#E0E5ED' : '#e1e6ef')};
  }

  &.selected {
    background-color: #818da1;
    color: #fff;
    font-weight: 600;
  }
`;

export const SearchResultItem = styled.div`
  cursor: pointer;
  color: #000000;
  padding: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  :hover {
    background: #f4f4ff;
    border-radius: 5px;
  }
`;

export const CreateNewTagOptionButton = styled.div`
  background-color: #f4f4ff;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  color: #7072dd;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  :hover {
    background: #5158cf;
    color: #ffffff;
  }
`;
