import { ASSET_TYPE } from 'components/Product/constants';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';

import { searchAsset } from 'redux/product/actions';
import { LIMIT_RESOURCE_COLLECTION } from 'constants/commonData';
import AssetSelect from './component';

const mapDispatchToProps = dispatch => {
  const dispatchSearchAsset = (selectedAssetType, inputValue, callback) => {
    let search = typeof inputValue === 'string' ? inputValue.trim() : '';
    dispatch(searchAsset(selectedAssetType, { search }))
      .then(response => {
        if (selectedAssetType === ASSET_TYPE.PROGRAM || selectedAssetType === ASSET_TYPE.FORUM) {
          // NOTE: temporary client side searching before switch to API based search
          const { data } = response.data;

          const textSearch = inputValue;
          const regex = new RegExp(textSearch, 'i');
          let searchResults = [];
          if (selectedAssetType === ASSET_TYPE.PROGRAM) {
            searchResults = data.filter(program => {
              return regex.test(program.title);
            });
          } else {
            searchResults = data.filter(forum => {
              return regex.test(forum.name);
            });
          }
          callback(orderBy(searchResults, ['last_interacted'], ['desc']));
        } else {
          const { data } = response.data;

          // TODO: SHOULD investigate
          if (data) {
            if (selectedAssetType === ASSET_TYPE.WORKOUT_COLLECTION) {
              callback(data.list);
            } else {
              callback(data);
            }
          } else {
            callback([]);
          }
        }
      })
      .catch(() => {
        callback([]);
      });
  };
  return {
    searchAsset: dispatchSearchAsset,
  };
};

const mapState = ({ cloudfrontList, rootReducer }) => {
  const { permission = {} } = rootReducer;

  return {
    cloudfrontList,
    collections_per_client: permission.collections_per_client || LIMIT_RESOURCE_COLLECTION,
  };
};

export default connect(mapState, mapDispatchToProps)(AssetSelect);
