import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { getAssignedPrograms } from 'redux/client/client.actionCreators';
import { IconContainer, ContentContainer, ProfilePart } from './styles';
import { ReactComponent as GymIcon } from 'assets/icons/gym.svg';
import { PROGRAM_COMPLETED } from 'constants/commonData';

const ActivePrograms = ({ getAssignedPrograms, assignedPrograms, client }) => {
  useEffect(() => {
    getAssignedPrograms(client);
  }, []);

  const activePrograms = useMemo(() => assignedPrograms.filter(item => item.status !== PROGRAM_COMPLETED), [
    assignedPrograms,
  ]);

  if (!activePrograms.length) {
    return null;
  }

  return (
    <ProfilePart className="profile-part">
      <IconContainer>
        <GymIcon />
      </IconContainer>
      <ContentContainer>
        <span>Program:&nbsp;</span>
        {activePrograms.map((item, index) => {
          return (
            <Link to={`/home/program/${get(item, 'program._id')}/calendar`}>
              <span>{get(item, 'program.title')}</span>
              {index < activePrograms.length - 1 && `, `}
            </Link>
          );
        })}
      </ContentContainer>
    </ProfilePart>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      client: { workingClientDetail, assignedPrograms },
    },
  } = state;

  return {
    client: get(workingClientDetail, '_id'),
    assignedPrograms,
  };
};

export default connect(mapStateToProps, { getAssignedPrograms })(ActivePrograms);
