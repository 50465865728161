import React, { useState, useRef, useEffect } from 'react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import Select from 'react-select';
import { Radio } from 'semantic-ui-react';

import { Button } from 'shared/FormControl';
import { getFixedPopupStyles } from 'utils/commonFunction';
import { FILTER_STATUS, OWNER_FILTER } from '../../constants';
import SelectGroupOption from './SelectGroupOption';
import LoadingIndicator from 'shared/LoadingIndicator';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { ReactComponent as NoGroupsIcon } from 'assets/icons/no-contacts-icon.svg';
import * as S from './style';

const PER_PAGE = 20;

function ClientTypeFilter(props) {
  const {
    openSecondToggle,
    useMinHeight = false,
    currentSelectedFilter,
    getGroupsFilterByParams,
    classNameButtonUpdate = '',
  } = props;

  const initStateFilter = {
    status: FILTER_STATUS.connected.value,
    owner_ship: 1,
    groups: [],
  };

  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(initStateFilter);
  const [menuOpen, setMenuOpen] = useState(false);

  const [groups, setGroups] = useState([]);
  const [exceptGroupsId, setExceptGroupsId] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);

  useEffect(() => {
    if (menuOpen && groups.length > 0) {
      const menuListContainer = document.querySelector('.multi-select-container .multi-select__menu-list');
      if (menuListContainer) {
        menuListContainer.addEventListener('scroll', event => {
          const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
          if (bottom) handleScrollBottom();
        });
      }
    }
  }, [groups, menuOpen]);

  const query = useRef({
    page: 1,
    per_page: PER_PAGE,
    only_groups_have_clients: true,
    sort: 1,
  });

  const selectRef = useRef();
  const triggerRef = useRef();
  const menuRef = useRef();
  const menuWidth = props.menuWidth || 435;
  const menuHeight = useMinHeight ? props.menuMinHeight : props.menuHeight || 455;
  const isLoading = useRef(false);
  const isEndLoadGroups = useRef(false);

  useEffect(() => {
    if (!open) {
      setSelectedFilter(currentSelectedFilter);
    }
    openSecondToggle(open);
  }, [open, currentSelectedFilter.groups, currentSelectedFilter.status, currentSelectedFilter.owner_ship]);

  const getMenuStyles = () => {
    if (open && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const styles = getFixedPopupStyles(triggerRect, menuHeight, 5);
      let left = triggerRect.x - 288;

      if (left + menuWidth > window.innerWidth) {
        left = window.innerWidth - menuWidth - 10;
      }

      return {
        styles: { ...styles, left },
        className: styles.top < triggerRect.y ? 'top' : 'bottom',
      };
    }

    return { styles: { display: 'none' } };
  };

  const onUpdate = () => {
    typeof props.onSelectedFilterApply === 'function' && props.onSelectedFilterApply(selectedFilter);
    setOpen(false);
  };

  const renderClientTypeFilter = () => {
    return (
      <S.Section className="from">
        <S.SectionHeader>Client Type</S.SectionHeader>
        <S.SectionBody>
          <S.RadioWrapper className="client-type-radio-wrapper">
            <S.RadioFormField
              name="clientFilter"
              control={Radio}
              label={FILTER_STATUS.connected.label}
              value={FILTER_STATUS.connected.value}
              checked={selectedFilter.status === FILTER_STATUS.connected.value}
              onChange={(e, { value }) => {
                setSelectedFilter({ ...selectedFilter, status: value });
              }}
            />
            <S.RadioFormField
              name="clientFilter"
              control={Radio}
              label={FILTER_STATUS.archived.label}
              value={FILTER_STATUS.archived.value}
              checked={selectedFilter.status === FILTER_STATUS.archived.value}
              onChange={(e, { value }) => {
                setSelectedFilter({ ...selectedFilter, status: value });
              }}
            />
            <S.RadioFormField
              name="clientFilter"
              control={Radio}
              label={FILTER_STATUS.all.label}
              value={FILTER_STATUS.all.value}
              checked={selectedFilter.status === FILTER_STATUS.all.value}
              onChange={(e, { value }) => {
                setSelectedFilter({ ...selectedFilter, status: value });
              }}
            />
          </S.RadioWrapper>
        </S.SectionBody>
      </S.Section>
    );
  };

  const renderOwnerFilter = () => {
    return (
      <S.Section className="from">
        <S.SectionHeader>Owner</S.SectionHeader>
        <S.SectionBody>
          <S.RadioWrapper className="owner-radio-wrapper" r>
            <S.RadioFormField
              name="ownerFilter"
              control={Radio}
              label={OWNER_FILTER.you.label}
              value={OWNER_FILTER.you.value}
              checked={selectedFilter.owner_ship === 1}
              onChange={() => {
                setSelectedFilter({ ...selectedFilter, owner_ship: 1 });
              }}
            />
            <S.RadioFormField
              name="ownerFilter"
              control={Radio}
              label={OWNER_FILTER.all.label}
              value={OWNER_FILTER.all.value}
              checked={selectedFilter.owner_ship === 2}
              onChange={() => {
                setSelectedFilter({ ...selectedFilter, owner_ship: 2 });
              }}
            />
          </S.RadioWrapper>
        </S.SectionBody>
      </S.Section>
    );
  };

  const renderEmptyGroupsList = () => (
    <S.EmptyWrapper>
      {isLoadingState || isLoading.current ? (
        <LoadingIndicator className="group-loading" />
      ) : (
        <>
          <NoGroupsIcon />
          <S.EmptyMessage>No groups found</S.EmptyMessage>
        </>
      )}
    </S.EmptyWrapper>
  );

  // Initialize
  useEffect(() => {
    if (isEndLoadGroups.current) return;

    isLoading.current = true;
    setIsLoadingState(true);

    getGroupsFilterByParams({ ...query.current, exclude_ids: exceptGroupsId }, response => {
      isLoading.current = false;
      setIsLoadingState(false);
      if (response) {
        const { data = [] } = response.data;
        isLoading.current = false;
        setIsLoadingState(false);
        if (!isEmpty(data)) {
          setGroups(data);
        }
      }
    });
  }, [exceptGroupsId]);

  const searchGroups = inputValue => {
    if (isLoading.current) return;
    setIsLoadingState(true);

    query.current = { ...query.current, search: inputValue, page: 1 };

    getGroupsFilterByParams(
      { ...query.current, page: 1, search: inputValue, exclude_ids: exceptGroupsId },
      response => {
        setIsLoadingState(false);
        isLoading.current = false;
        if (response) {
          const { data = [] } = response.data;
          if (!isEmpty(data)) {
            setGroups(data);
            setIsLoadingState(false);
            isLoading.current = false;
            isEndLoadGroups.current = data.length < PER_PAGE;
          }
        }
      },
    );
  };

  // Handle search
  const handleSearchDebounce = debounce(inputValue => {
    searchGroups(inputValue);
    setTimeout(() => {
      const scrollInput = document.querySelector('.multi-select__menu-list');
      if (scrollInput) {
        scrollInput.scroll({ top: 0, behavior: 'smooth' });
      }
    }, 100);
  }, 300);

  const handleSelectedGroups = (list = []) => {
    setSelectedFilter({ ...selectedFilter, groups: [...list] });
    const exceptGroupsId = list.map(group => get(group, '_id', ''));
    setExceptGroupsId(exceptGroupsId);
    query.current = { ...query.current, page: 1, search: '' };
    isEndLoadGroups.current = false;

    setTimeout(() => {
      const scrollInput = document.querySelector('.multi-select-container .multi-select__control');
      if (scrollInput && list.length > get(selectedFilter, 'groups', []).length) {
        scrollInput.scroll({ top: scrollInput.clientHeight, behavior: 'smooth' });
      }
    }, 100);
  };

  const handleScrollBottom = debounce(() => {
    if (isLoading.current || isEndLoadGroups.current) return;
    isLoading.current = true;
    query.current = { ...query.current, page: query.current.page + 1 };
    const loadingBox = document.createElement('div');

    loadingBox.classList.add('boxLoading');

    const ringLoading = document.createElement('div');
    const textLoading = document.createElement('div');

    loadingBox.appendChild(ringLoading);

    ringLoading.classList.add('ringLoading');
    const div = document.createElement('div');
    ringLoading.appendChild(div);

    loadingBox.classList.add('textLoading');
    textLoading.innerHTML = 'Loading...';
    loadingBox.appendChild(textLoading);

    const parentData = document.querySelector('.multi-select-container .multi-select__menu-list');
    if (parentData) {
      parentData.appendChild(loadingBox);
    }

    getGroupsFilterByParams({ ...query.current, exclude_ids: exceptGroupsId }, response => {
      setIsLoadingState(false);
      isLoading.current = false;
      if (response) {
        const { data = [] } = response.data;
        if (!isEmpty(data)) {
          setGroups(group => [...group, ...data]);
          setIsLoadingState(false);
          isLoading.current = false;
          isEndLoadGroups.current = data.length < PER_PAGE;
          const boxes = document.querySelectorAll('.boxLoading');
          boxes.forEach(box => {
            box.remove();
          });
        }
      }
    });
  }, 300);

  const renderGroupFilter = () => {
    return (
      <S.Section className="from">
        <S.SectionHeader>Group</S.SectionHeader>
        <S.SectionBody>
          <Select
            ref={selectRef}
            isMulti
            isSearchable
            cacheOptions
            components={{
              Option: SelectGroupOption,
            }}
            defaultOptions={groups}
            classNamePrefix="multi-select"
            placeholder="Search for a group"
            onChange={handleSelectedGroups}
            className="multi-select-container"
            noOptionsMessage={renderEmptyGroupsList}
            menuPosition="fixed"
            onInputChange={handleSearchDebounce}
            options={groups}
            getOptionLabel={option => option.name}
            getOptionValue={option => option._id}
            onMenuOpen={() => setMenuOpen(true)}
            onMenuClose={() => setMenuOpen(false)}
            styles={{
              control: provided => ({
                ...provided,
                cursor: 'pointer',
                padding: 0,
              }),
              menuPortal: provided => ({
                ...provided,
                zIndex: 9999,
              }),
              menu: styles => ({
                ...styles,
                width: '342px',
                marginTop: '2px',
              }),
            }}
          />
        </S.SectionBody>
      </S.Section>
    );
  };

  const { styles, className } = getMenuStyles();

  const handleCloseFilterPopup = () => {
    setOpen(false);
  };

  const handleResetSelectedFilter = () => {
    if (selectRef.current) {
      selectRef.current.select.clearValue();
    }
    setSelectedFilter({ ...initStateFilter });
  };

  return (
    <RootCloseWrapper event="click" onRootClose={handleCloseFilterPopup}>
      <S.Wrapper className={classNames('clients-filter', className, { open: open })}>
        <S.TriggerContainer
          ref={triggerRef}
          onClick={() => {
            setOpen(prev => !prev);
          }}
        >
          {props.trigger}
        </S.TriggerContainer>
        <S.Popup ref={menuRef} style={styles} menuHeight={menuHeight} menuWidth={menuWidth}>
          <S.Header>
            <S.PopupTitleHeader>Filter by:</S.PopupTitleHeader>
            <S.ClearAllFilter onClick={handleResetSelectedFilter}>Clear All</S.ClearAllFilter>
          </S.Header>
          <S.Body id="filter-popup-body">
            {renderClientTypeFilter()}
            {renderOwnerFilter()}
            {renderGroupFilter()}
          </S.Body>
          <S.Footer className="footer">
            <Button
              purple
              onClick={onUpdate}
              className={classNameButtonUpdate}
              disabled={isEqual(selectedFilter, currentSelectedFilter)}
            >
              Update
            </Button>
          </S.Footer>
        </S.Popup>
      </S.Wrapper>
    </RootCloseWrapper>
  );
}

export default ClientTypeFilter;
