import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { Emoji as EmojiPicker } from 'emoji-picker-react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import get from 'lodash/get';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import {
  formatDateNotification,
  getUserShortName,
  getAvatarNotification,
  isNotificationPaymentSummary,
  formatInSale,
  formatDatePaymentSummary,
  pluralize,
  getItemNotificationUrl,
  isNotificationRecap,
} from 'utils/commonFunction';
import { ONBOARDING_FLOW_TYPE, TOPICS, TRIGGER_SUB_ACTIONS } from 'constants/commonData';
import { BACKGROUND_LIST, DEFAULT_EMOJI } from 'constants/habitData';
import { MenuTrigger } from 'shared/Icons';
import Confetti from 'components/Confetti';
import { ReactComponent as TriggerIcon } from 'assets/icons/onboarding-trigger-form.svg';

import * as S from '../style';

const POSITION_POPUP = {
  TOP_RIGHT: 'top right',
  BOTTOM_RIGHT: 'bottom right',
};

const ItemNotification = props => {
  const {
    timezone,
    openItemNotification,
    markAsReadItemNotification,
    markAsUnreadItemNotification,
    actionPopupId,
    setActionPopupId,
    cloudfrontList,
    item = {},
    isHabitPermission = false,
  } = props;

  const { type, action_sub_type = '' } = item;
  const isShowTriggerIcon = type === ONBOARDING_FLOW_TYPE && TRIGGER_SUB_ACTIONS.includes(action_sub_type);

  const containerRef = useRef();
  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      const height = get(entries, '[0].contentRect.height', 0);
      if (containerRef.current) {
        if (height <= 21) {
          containerRef.current.classList.add('align-items-center');
        } else {
          containerRef.current.classList.remove('align-items-center');
        }
      }
    }),
  );

  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState(POSITION_POPUP.BOTTOM_RIGHT);
  const itemUrl = useMemo(() => getItemNotificationUrl(item, isHabitPermission), [item, isHabitPermission]);

  const id = useMemo(() => get(props, 'item._id'), [props]);
  const checked = useMemo(() => get(props, 'item.checked'), [props]);
  const time = useMemo(() => formatDateNotification(get(props, 'item.createdAt'), timezone), [props, timezone]);
  const isPaymentSummaries = useMemo(() => isNotificationPaymentSummary(item), [item]);
  const isRecap = useMemo(() => isNotificationRecap(item), [item]);
  const inSale = useMemo(() => (isPaymentSummaries ? formatInSale(item) : 0), [isPaymentSummaries, item]);

  useEffect(() => {
    if (open && !isEmpty(actionPopupId) && actionPopupId !== id) {
      closePopupAction(false);
    }
  }, [open, actionPopupId, id]);

  const openItemNotificationDebounce = debounce(openItemNotification, 300);
  const markAsReadDebounce = debounce(markAsReadItemNotification, 300);
  const markAsUnreadDebounce = debounce(markAsUnreadItemNotification, 300);

  const onItemClick = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    closePopupAction(false);
    openItemNotificationDebounce.call(null, item, { ...event });
  }, []);

  const onMarkAsRead = useCallback(event => {
    event.stopPropagation();
    closePopupAction();
    markAsReadDebounce.call(null, item);
  }, []);

  const onMarkAsUnread = useCallback(event => {
    event.stopPropagation();
    closePopupAction();
    markAsUnreadDebounce.call(null, item);
  }, []);

  const openPopupAction = useCallback(
    event => {
      event.stopPropagation();
      if (event.currentTarget) {
        const { bottom } = event.currentTarget.getBoundingClientRect();

        if (window.innerHeight - bottom < 90) {
          setPosition(POSITION_POPUP.TOP_RIGHT);
        }
      }

      setActionPopupId(id);
      setOpen(true);
      const actionBox = document.getElementById(`notification-action-${id}`);
      if (!isEmpty(actionBox)) {
        actionBox.style.visibility = 'visible';
        window.addEventListener('scroll', scrollEvent, true);
      }
    },
    [id],
  );

  const closePopupAction = useCallback(
    (resetState = true) => {
      if (resetState) {
        setActionPopupId();
      }
      setOpen(false);
      const actionBox = document.getElementById(`notification-action-${id}`);
      if (!isEmpty(actionBox)) {
        actionBox.style.visibility = null;
        window.removeEventListener('scroll', scrollEvent, true);
      }

      setPosition(POSITION_POPUP.BOTTOM_RIGHT);
    },
    [id],
  );

  const scrollEvent = useCallback(() => {
    closePopupAction();
  }, []);

  const resizedContainerRef = useCallback(container => {
    if (container !== null) {
      resizeObserver.current.observe(container);
    } else {
      if (resizeObserver.current) resizeObserver.current.disconnect();
    }
  }, []);

  const handleIconAction = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const renderAvatar = () => {
    if (item.action_type === 'habit' && item.type !== 'comment') {
      const emoji = get(item, 'additional_info.icon_unified', DEFAULT_EMOJI.icon_unified);
      const border = get(item, 'additional_info.color', DEFAULT_EMOJI.color);
      let backgroundColor = find(BACKGROUND_LIST, ['border', border]);
      backgroundColor = get(backgroundColor, 'background', BACKGROUND_LIST[2].background);
      return (
        <S.EmojiHabit color={backgroundColor}>
          <EmojiPicker unified={emoji} size="22" />
        </S.EmojiHabit>
      );
    }

    if (isShowTriggerIcon) {
      return <TriggerIcon className="trigger-icon" />;
    }

    return (
      <Avatar
        name={getUserShortName(item.relate_user || item.user)}
        className="avatar-notification-center"
        size="44"
        color={get(item, 'relate_user.color', '')}
        src={getAvatarNotification(item, cloudfrontList)}
      />
    );
  };

  const renderMessage = () => {
    const activityName = get(item, 'additional_info.name', '');
    const accountFullName = get(item, 'relate_user.full_name', '');
    let highlights = get(item, 'highlights', []);
    let message = get(item, 'message', '');

    highlights.forEach(highlight => {
      if (activityName === highlight) return;
      if (get(item, 'action_type', '').includes('log_activity') && ![accountFullName, 'commented'].includes(highlight))
        return;
      if (get(item, 'action_type', '').includes(TOPICS.PERSONAL_TASK)) return;
      message = message.replace(highlight, `<b>${highlight}</b>`);
    });
    return <div className="message" dangerouslySetInnerHTML={{ __html: message }}></div>;
  };

  const renderPaymentSummaryMessage = () => {
    const timeMessage = formatDatePaymentSummary(get(props, 'item.additional_info.report_day'), timezone);
    return (
      <div className="message">
        <b>{timeMessage && timeMessage + ' - '}Payment Summary</b>
      </div>
    );
  };

  const renderPaymentSummaryContent = () => {
    const packageSold = get(item, 'additional_info.packages_sold', 0);
    const newClients = get(item, 'additional_info.new_clients', 0);
    return (
      <>
        <div className="payment-amount in-sales">
          {inSale}
          &nbsp;in sales
        </div>
        <div className="payment-amount">
          {packageSold}&nbsp;{pluralize('package', packageSold)}&nbsp;sold
        </div>
        <div className="payment-amount">
          {newClients}&nbsp;new&nbsp;{pluralize('client', newClients)}
        </div>
      </>
    );
  };

  return (
    <S.Item
      className={classNames({ unread: !checked })}
      onClick={onItemClick}
      ref={containerRef}
      href={itemUrl}
      target="_self"
    >
      {isRecap && (
        <Confetti
          url="https://lottie.host/598ddfba-c606-43f3-a1d6-ad706c9b0577/Bhg5CbMd2Y.json"
          width="380px"
          height="380px"
        />
      )}
      {renderAvatar()}
      <div className="content" ref={resizedContainerRef}>
        {!isPaymentSummaries && renderMessage()}
        {isPaymentSummaries && renderPaymentSummaryMessage()}
        {isPaymentSummaries && renderPaymentSummaryContent()}
      </div>
      <div className="time-box">
        <div className="time">{time}</div>
      </div>
      <div className="action-box">
        <div className="action" id={`notification-action-${id}`}>
          <S.ItemActionPopup
            trigger={
              <S.ItemActionOption disabled={false} onClick={handleIconAction}>
                <MenuTrigger vertical={false} size={20} active={open} />
              </S.ItemActionOption>
            }
            flowing={true}
            hoverable={true}
            on="click"
            position={position}
            open={open}
            onOpen={openPopupAction}
            onClose={closePopupAction}
            className="item-action-popup"
            keepInViewPort={true}
            basic={true}
          >
            <RootCloseWrapper event="click" disabled={!open} onClick={closePopupAction}>
              <S.ItemActionContent>
                {!checked && (
                  <S.ItemActionItem onClick={onMarkAsRead}>
                    <S.ItemActionLabel>Mark as read</S.ItemActionLabel>
                  </S.ItemActionItem>
                )}
                {!!checked && (
                  <S.ItemActionItem onClick={onMarkAsUnread}>
                    <S.ItemActionLabel>Mark as unread</S.ItemActionLabel>
                  </S.ItemActionItem>
                )}
              </S.ItemActionContent>
            </RootCloseWrapper>
          </S.ItemActionPopup>
        </div>
      </div>
    </S.Item>
  );
};

const mapState = ({ cloudfrontList }) => ({ cloudfrontList });

export default memo(connect(mapState)(ItemNotification));
