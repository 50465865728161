import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #F6F6FA;
  border-radius: 5px;
  padding: 10px 70px;
  position: relative;
  margin-bottom: 20px;

  .guideStudio__closeIcon {
    background: url(${CDN_URL}/images/close_icon_new.svg) no-repeat center center;
    width: 8.25px;
    height: 8.25px;
    position: absolute;
    top: 12px;
    right: 12px;
    opacity: 0.3;
    cursor: pointer;
  }

  .guideStudio__title {
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #202353;
    margin-bottom: 5px;
  }

  .guideStudio__description {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #202353;
  }
`;