import React, { useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import * as S from './style';

export default ({ value, copiedLabel, copyLabel, hoverLabel, noIcon, ...props }) => {
  const [copied, setCopied] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const handleCopy = useCallback(() => {
    if (copied) {
      return;
    }

    try {
      let dummy = document.createElement('textarea');
      dummy.value = value;
      document.body.appendChild(dummy);
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);

      setCopied(true);

      setTimeout(() => {
        if (!unmounted.current) {
          setCopied(false);
        }
      }, 5000);
    } catch {}
  }, [copied]);

  return (
    <S.Wrapper
      className={classnames(`${props.className || ''} copyToClipboard`, { 'copyToClipboard--copied': copied })}
      onClick={handleCopy}
      copied={copied}
    >
      {copied ? (
        <span className="copyToClipboard__copiedLabel">{copiedLabel || 'Link Copied!'}</span>
      ) : (
        <>
          <S.Text className="copyToClipboard__text">{copyLabel || value}</S.Text>
          {noIcon ? null : <S.Icon className="copyToClipboard__icon" />}
          <S.TextHover className="copyToClipboard__textHover">{hoverLabel || 'Copy to clipboard'}</S.TextHover>
        </>
      )}
    </S.Wrapper>
  );
};
