import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import * as S from './style';

export const ButtonIcon = ({
  text,
  color,
  className,
  icon,
  bgColor,
  width,
  bgHoverColor,
  textHoverColor,
  onClick,
  height,
  disabled,
  position = '', // TODO: Position of Icon - IF right => show icon right of text
  isShowFilter = false,
  ...otherProps
}) => {
  return (
    <S.ButtonWrapper
      bgColor={bgColor}
      color={color}
      width={width}
      className={className}
      bgHoverColor={bgHoverColor}
      textHoverColor={textHoverColor}
      height={height}
      isShowFilter={isShowFilter}
      {...otherProps}
    >
      <Button onClick={onClick} disabled={disabled}>
        {position.length === 0 && icon}
        <span>{text}</span>
        {position.indexOf('right') > -1 && icon}
      </Button>
    </S.ButtonWrapper>
  );
};

ButtonIcon.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  bgHoverColor: PropTypes.string,
  textHoverColor: PropTypes.string,
  disabled: PropTypes.bool,
  position: PropTypes.string,
};

export default ButtonIcon;
