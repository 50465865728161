import React from 'react';
import { pluralize } from 'utils/commonFunction';
import { CheckBoxIconWrapper, ProgramItemWrapper, CheckboxProgram } from './style';

const CheckBoxIcon = ({ active, onChange }) => {
  return (
    <CheckBoxIconWrapper>
      <CheckboxProgram checked={active} onChange={onChange} />
    </CheckBoxIconWrapper>
  );
};

export default function StudioProgramItem({ program, active, onSelect }) {
  const onClick = () => {
    onSelect(program);
  };
  return (
    <ProgramItemWrapper active={active} coverImage={program.cover_image} onClick={onClick}>
      <div className="programItem__avatar" />
      <div className="programItem__info">
        <div>
          <div className="programItem__info--title">{program.program_name}</div>
          <div className="programItem__info--description">{program.description}</div>
        </div>
        <div className="programItem__info--statistic">
          {pluralize('Week', program.number_of_weeks, true)}
          &nbsp;-&nbsp;
          {pluralize('Workout', program.number_of_workouts || 0, true)}
        </div>
      </div>
      <CheckBoxIcon active={active} onChange={onClick} />
    </ProgramItemWrapper>
  );
}
