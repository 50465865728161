import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import round from 'lodash/round';
import { connect } from 'react-redux';
import { CURRENCIES, TEXT_CURRENCIES } from 'constants/commonData';
import { formatMoney, formatSameYear } from 'utils/commonFunction';

import { DropdownIndicator } from 'shared/Icons';
import InvoiceTable from './InvoiceTable';

import {
  getPurchasesOfClient,
  getInvoiceList,
  getPackageStatistics,
  changeQueryParams,
  countPackages,
} from 'redux/package-analytics/actions';

import * as S from './styles';

const ALL_PACKAGE_OPTION = {
  package_name: 'All Packages',
};

const CustomOptionProgram = ({ innerProps, data, isFocused, selectedPackage }) => {
  let className = 'evfSelectBox__option';
  if (selectedPackage.id === data.id) {
    className += ' evfSelectBox__option--is-focused';
  }

  return (
    <S.PackageSelectOption {...innerProps} className={className}>
      <div className="packageOption__name">{data.package_name}</div>
      <div className="packageOption__purchasedAt">
        {get(data, 'created_at') ? formatSameYear(get(data, 'created_at')) : ''}
      </div>
    </S.PackageSelectOption>
  );
};

const InvoiceList = props => {
  const {
    client,
    defaultPurchase,
    getPurchasesOfClient,
    clientPurchases,
    invoicesOfPackage,
    getInvoiceList,
    totalPaid,
    account,
    onChangePackage,
    currentWorkingPackage,
    onToggleRefundModal,
    permission,
    getDetailCallback,
    changeQueryParams,
    countPackages,
    isMP,
  } = props;
  const [purchases, setPurchases] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [total, setTotal] = useState([totalPaid]);
  const currency = get(account, 'currency', 'usd');
  const symbol = TEXT_CURRENCIES.includes(currency)
    ? `${get(CURRENCIES, `${currency}.symbol`)} `
    : get(CURRENCIES, `${currency}.symbol`);

  useEffect(() => {
    setSelectedPackage(defaultPurchase);
    getInvoiceList(
      {
        package_id: get(defaultPurchase, '_package.id'),
        isInvoiceOfPackage: true,
        client_email: client.email,
        pageSize: 50,
        purchase_id: get(defaultPurchase, 'id'),
        client_id: get(client, '_id', ''),
      },
      isMP,
    );
  }, []);

  useEffect(() => {
    setPurchases([ALL_PACKAGE_OPTION, ...clientPurchases]);
  }, [clientPurchases]);

  useEffect(() => {
    client &&
      getPurchasesOfClient(
        {
          client_id: client._id,
        },
        isMP ? ['marketplace'] : ['everfit'],
      );
  }, []);

  useEffect(() => {
    setTotal(totalPaid);
  }, [totalPaid]);

  const onSelectPackage = item => {
    setSelectedPackage(item);
    onChangePackage(item);
    getInvoiceList(
      {
        package_id: get(item, '_package.id'),
        isInvoiceOfPackage: true,
        client_email: client.email,
        pageSize: 50,
        purchase_id: get(item, 'id'),
        client_id: get(client, '_id', ''),
      },
      isMP,
    );
  };

  const handleRetriedCallBack = params => {
    getPackageStatistics(params, isMP);
    getInvoiceList(
      {
        package_id: get(selectedPackage, '_package.id'),
        isInvoiceOfPackage: true,
        client_email: client.email,
        pageSize: 50,
        purchase_id: get(selectedPackage, 'id'),
        client_id: get(client, '_id', ''),
      },
      isMP,
    );
    getDetailCallback();
  };

  return (
    <S.Wrapper>
      <S.Heading>
        <S.PackageSelectContent>
          <strong>Invoices for</strong>
          <S.PackageSelectWrapper
            options={purchases}
            getOptionLabel={option => option.package_name}
            getOptionValue={option => option.id}
            components={{
              DropdownIndicator,
              Option: props => <CustomOptionProgram {...props} selectedPackage={selectedPackage} />,
              IndicatorSeparator: null,
            }}
            placeholder="-"
            onChange={onSelectPackage}
            classNamePrefix="evfSelectBox"
            className="evfSelectBoxContainer"
            value={selectedPackage}
            isSearchable={false}
            styles={{
              control: base => ({ ...base, minHeight: 26, height: 26, minWidth: 215 }),
              menu: base => ({ ...base, marginTop: 2, marginBottom: 5, maxHeight: 339, minWidth: 406 }),
            }}
            menuPlacement="auto"
          />
        </S.PackageSelectContent>
        <S.TotalPaid>
          Total Paid: <b>{formatMoney(round(total / 100, 2), symbol)}</b>
        </S.TotalPaid>
      </S.Heading>
      <S.Content>
        <InvoiceTable
          isMP={isMP}
          invoices={invoicesOfPackage}
          account={account}
          client={client}
          purchases={purchases}
          selectedPackage={selectedPackage} // Selected package
          currentPackage={currentWorkingPackage} // Current package open analytic
          retriedCallback={handleRetriedCallBack}
          onToggleRefundModal={onToggleRefundModal}
          is_allow_refund={get(permission, 'is_allow_refund', false)}
          changeQueryParams={changeQueryParams}
          countPackages={countPackages}
        />
      </S.Content>
    </S.Wrapper>
  );
};

const mapState = state => {
  const {
    rootReducer: { packageAnalytics, setupPayment, packageDetail, permission },
  } = state;
  const { invoicesOfPackage, totalPaid, purchases } = packageAnalytics.get('clientPurchases').toJS();
  return {
    clientPurchases: purchases,
    invoicesOfPackage: invoicesOfPackage,
    account: setupPayment.toJS().account,
    totalPaid: totalPaid,
    currentWorkingPackage: packageDetail.toJS().workingPackage,
    permission,
  };
};

const actionCreators = { getPurchasesOfClient, getInvoiceList, getPackageStatistics, changeQueryParams, countPackages };

export default connect(mapState, actionCreators)(InvoiceList);
