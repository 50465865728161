import React from 'react';
import Request from 'configs/request';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getAutoflowClientStatistic } from 'redux/autoflow/actions';
import { successGetClientStatistic } from '../actions';

export const Types = {
  AUTOFLOW_INTERVAL_CLIENT_BULK_INVITE: 'AUTOFLOW_INTERVAL_CLIENT_BULK_INVITE',
  AUTOFLOW_INTERVAL_CLIENT_GET_LIST_SUCCESS: 'AUTOFLOW_INTERVAL_CLIENT_GET_LIST_SUCCESS',
  AUTOFLOW_INTERVAL_CLIENT_GET_LIST_FAIL: 'AUTOFLOW_INTERVAL_CLIENT_GET_LIST_FAIL',
  AUTOFLOW_INTERVAL_CLIENT_SELECT_CLIENTS: 'AUTOFLOW_INTERVAL_CLIENT_SELECT_CLIENTS',
  AUTOFLOW_INTERVAL_CLIENT_CHANGE_QUERY_PARAM: 'AUTOFLOW_INTERVAL_CLIENT_CHANGE_QUERY_PARAM',
  AUTOFLOW_INTERVAL_CLIENT_CHANGE_COMPLIANCE_VIEW: 'AUTOFLOW_INTERVAL_CLIENT_CHANGE_COMPLIANCE_VIEW',
  AUTOFLOW_INTERVAL_CLIENT_RESET: 'AUTOFLOW_INTERVAL_CLIENT_RESET',
  AUTOFLOW_INTERVAL_PAUSE_CLIENTS: 'AUTOFLOW_INTERVAL_PAUSE_CLIENTS',
  AUTOFLOW_INTERVAL_SUCCESS_PAUSE_CLIENTS: 'AUTOFLOW_INTERVAL_SUCCESS_PAUSE_CLIENTS',
  AUTOFLOW_INTERVAL_FAILED_PAUSE_CLIENTS: 'AUTOFLOW_INTERVAL_FAILED_PAUSE_CLIENTS',
  AUTOFLOW_INTERVAL_ACTIVATE_CLIENTS: 'AUTOFLOW_INTERVAL_ACTIVATE_CLIENTS',
  AUTOFLOW_INTERVAL_SUCCESS_ACTIVATE_CLIENTS: 'AUTOFLOW_INTERVAL_SUCCESS_ACTIVATE_CLIENTS',
  AUTOFLOW_INTERVAL_FAILED_ACTIVATE_CLIENTS: 'AUTOFLOW_INTERVAL_FAILED_ACTIVATE_CLIENTS',
  AUTOFLOW_INTERVAL_SUCCESS_MARK_CLIENTS_AS_COMPLETE: 'AUTOFLOW_INTERVAL_SUCCESS_MARK_CLIENTS_AS_COMPLETE',
  AUTOFLOW_INTERVAL_FAILED_MARK_CLIENTS_AS_COMPLETE: 'AUTOFLOW_INTERVAL_FAILED_MARK_CLIENTS_AS_COMPLETE',
  AUTOFLOW_INTERVAL_CLIENT_SUCCESS_REMOVE_CLIENTS: 'AUTOFLOW_INTERVAL_CLIENT_SUCCESS_REMOVE_CLIENTS',
  AUTOFLOW_INTERVAL_SUCCESS_START_TODAY: 'AUTOFLOW_INTERVAL_SUCCESS_START_TODAY',
  AUTOFLOW_INTERVAL_SUCCESS_CHANGE_START_DATE: 'AUTOFLOW_INTERVAL_SUCCESS_CHANGE_START_DATE',
};

export const bulkInviteClients = data => {
  return Request.post({ url: `/api/autoflow-interval-client/bulk-invite`, data }, true, (response, { dispatch }) => {
    dispatch(getClientList());
    dispatch(getAutoflowClientStatistic({ autoflow: data.autoflowId }));
    dispatch({ type: Types.AUTOFLOW_INTERVAL_CLIENT_BULK_INVITE });
    // dispatch(assignClientsSuccess(response, data.first_client_name));
  });
};

export const resetData = () => ({ type: Types.AUTOFLOW_INTERVAL_CLIENT_RESET });

export const selectClients = data => ({
  type: Types.AUTOFLOW_INTERVAL_CLIENT_SELECT_CLIENTS,
  payload: { data },
});

const compareRequest = (request, currentState) => {
  if (request.page !== currentState.page) {
    return false;
  }

  if (request.sort !== currentState.sort) {
    return false;
  }

  if (request.sorter !== currentState.sorter) {
    return false;
  }

  if (request.search.trim() !== currentState.search.trim()) {
    return false;
  }

  return true;
};

export const changeAutoflowClientQueryParam = newQuery => ({
  type: Types.AUTOFLOW_INTERVAL_CLIENT_CHANGE_QUERY_PARAM,
  payload: { newQuery },
});

export const changeComplianceView = complianceView => ({
  type: Types.AUTOFLOW_INTERVAL_CLIENT_CHANGE_COMPLIANCE_VIEW,
  payload: { complianceView },
});

const getSorter = (key, view) => {
  if (view === 'all' || !['percent_completed_workout', 'percent_completed_task'].includes(key)) {
    return key;
  }
  return `${key}_${view}`;
};

export const getClientList = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const { autoflowInterval, autoflow } = currentState.rootReducer;
    const client = autoflowInterval.client.toJS();
    const { common } = autoflow;
    const { workingAutoflow } = common;

    return dispatch(
      Request.get(
        {
          url: `/api/autoflow/client/list`,
          params: {
            ...client.query,
            sorter: getSorter(client.query.sorter, client.complianceView),
            search: client.query.search.trim(),
            autoflow: _.get(workingAutoflow, '_id'),
          },
        },
        true,
        response => {
          const { data } = response;
          const state = getState();
          const { query } = state.rootReducer.autoflowInterval.client.toJS();
          const list = _.map(data.data, item => {
            const { client } = item;
            const trainer = _.get(client, 'trainers[0].trainer');

            return {
              ...item,
              client: { ...client, trainer },
            };
          });

          if (compareRequest(client.query, query)) {
            dispatch({
              type: Types.AUTOFLOW_INTERVAL_CLIENT_GET_LIST_SUCCESS,
              payload: { list, total: data.total },
            });
          }

          return response.data;
        },
        error => {
          dispatch({
            type: Types.AUTOFLOW_INTERVAL_CLIENT_GET_LIST_FAIL,
            error: error,
          });
        },
      ),
    );
  };
};

export const successPauseClients = data => ({
  type: Types.AUTOFLOW_INTERVAL_SUCCESS_PAUSE_CLIENTS,
  payload: data,
});

export const pauseClients = (params, multiple) => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_INTERVAL_PAUSE_CLIENTS });
    const data = {
      ...params,
      autoflowId: params.autoflow,
    };
    return dispatch(
      Request.post(
        { url: `/api/autoflow-interval-client/bulk-pause`, data },
        true,
        response => {
          toast.success('The clients have been paused. Please wait a few minutes for all assignments to be updated.');
          dispatch(successPauseClients({ data, multiple }));
          dispatch(refreshData(data.autoflow));
          dispatch(getAutoflowClientStatistic({ autoflow: data.autoflowId }));
        },
        () => dispatch({ type: Types.AUTOFLOW_INTERVAL_FAILED_PAUSE_CLIENTS }),
      ),
    );
  };
};

export const refreshData = autoflow => {
  return dispatch => {
    dispatch(changeAutoflowClientQueryParam({ page: 1 }));
    dispatch(getClientList());
  };
};

const updateClientStatistic = (response, autoflow) => {
  return dispatch => {
    const { data } = response.data;
    dispatch(successGetClientStatistic({ data, autoflow }));
  };
};

export const successActivateClients = data => ({
  type: Types.AUTOFLOW_INTERVAL_SUCCESS_ACTIVATE_CLIENTS,
  payload: data,
});

export const activateClients = (params, multiple) => {
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_INTERVAL_ACTIVATE_CLIENTS });

    const data = {
      ...params,
      autoflowId: params.autoflow,
    };
    return dispatch(
      Request.post(
        { url: `/api/autoflow-interval-client/bulk-resume`, data },
        true,
        response => {
          toast.success('The clients have been resumed. Please wait a few minutes for all assignments to be updated.');
          dispatch(successActivateClients({ data, multiple }));
          dispatch(refreshData(data.autoflow));
          dispatch(getAutoflowClientStatistic({ autoflow: data.autoflowId }));
        },
        () => dispatch({ type: Types.AUTOFLOW_INTERVAL_FAILED_ACTIVATE_CLIENTS }),
      ),
    );
  };
};

export const successMaskClientsAsComplete = data => ({
  type: Types.AUTOFLOW_INTERVAL_SUCCESS_MARK_CLIENTS_AS_COMPLETE,
  payload: data,
});

export const markClientsAsComplete = (params, multiple) => {
  const data = {
    ...params,
    autoflowId: params.autoflow,
  };
  return Request.post(
    { url: '/api/autoflow-interval-client/bulk-mark-complete', data },
    true,
    (response, { dispatch }) => {
      dispatch(successMaskClientsAsComplete({ data, multiple }));
      dispatch(getAutoflowClientStatistic({ autoflow: data.autoflowId }));
      dispatch(refreshData());
    },
    (error, { dispatch }) => {
      dispatch({ type: Types.AUTOFLOW_INTERVAL_FAILED_MARK_CLIENTS_AS_COMPLETE });
    },
  );
};

export const deleteClients = params => {
  const data = {
    ...params,
    autoflowId: params.autoflow,
  };
  return Request.post(
    { url: `/api/autoflow-interval-client/bulk-remove`, data },
    true,
    (response, { dispatch, getState }) => {
      toast.success('The clients have been removed. Please wait a few minutes for all assignments to be updated.');
      dispatch({ type: Types.AUTOFLOW_INTERVAL_CLIENT_SUCCESS_REMOVE_CLIENTS });
      dispatch(getAutoflowClientStatistic({ autoflow: data.autoflowId }));
      dispatch(refreshData(data.autoflow));
    },
  );
};

export const successStartToday = data => ({
  type: Types.AUTOFLOW_INTERVAL_SUCCESS_START_TODAY,
  payload: data,
});

export const startToday = data => {
  return Request.post(
    { url: '/api/autoflow-interval-client/bulk-start-today', data },
    true,
    (response, { dispatch }) => {
      toast.success(
        'The clients will start the Autoflow today. Please wait a few minutes for all assignments to be updated.',
      );
      dispatch(successStartToday({ data }));
      dispatch(refreshData(data.autoflowId));
      dispatch(getAutoflowClientStatistic({ autoflow: data.autoflowId }));
    },
  );
};

export const successChangeStartDate = data => ({
  type: Types.AUTOFLOW_INTERVAL_SUCCESS_CHANGE_START_DATE,
  payload: data,
});

export const changeStartDate = data => {
  return Request.post(
    { url: '/api/autoflow-interval-client/bulk-change-start-date', data },
    true,
    (response, { dispatch }) => {
      toast.success('The start date has been updated. Please wait a few moments for all clients to be updated.');
      dispatch(successChangeStartDate({ data }));
      dispatch(getAutoflowClientStatistic({ autoflow: data.autoflowId }));
      dispatch(refreshData(data.autoflowId));
    },
  );
};

export const assignClientsToIntervalAutoflowFromClientList = data => {
  return Request.post({ url: `/api/autoflow-interval-client/bulk-invite`, data }, true);
};
