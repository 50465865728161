import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

  .choose-start-date__form-item {
    .custom-date-input.form-control {
      line-height: 18px;
      background: #fafbfc;
      border: 1px solid #d4d7d9;
      border-radius: 5px;
      padding: 11px 14px;
    }

    .ui.dropdown.new-ui,
    .ui.dropdown.selection.new-ui {
      line-height: 18px;
      background: #fafbfc;
      border: 1px solid #d4d7d9;
      border-radius: 5px;
      padding: 11px 14px;
    }
  }
  .choose-start-date__form-label {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #6a778a;
    margin-bottom: 5px;
  }
`;

export const Heading = styled.div`
  margin-bottom: 25px;

  .choose-start-date__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }

  .choose-start-date__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #323c47;
    margin-top: 5px;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 7px 42px;
  }
`;

export const Wrapper = styled.div``;
