import React from 'react';
import { toggleSecondModal } from 'actions/modal';
import { ReactComponent as EditIcon } from 'assets/icons/edit_icon.svg';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { roundNumberBodyMetric } from 'utils/commonFunction';
import UpdateMetric from '../../UpdateMetric';
import * as S from './style';

const InformationUnit = ({
  weight = '',
  bodyFat = '',
  clientMetrics = '',
  toggleSecondModal,
  measurementId,
  selectedDate,
  compareMode,
}) => {
  const getWeightUnit = get(clientMetrics, 'title', '');

  const handleUpdateMetric = () => {
    toggleSecondModal &&
      toggleSecondModal(
        true,
        <UpdateMetric weight={weight} bodyFat={bodyFat} measurementId={measurementId} selectedDate={selectedDate} />,
      );
  };

  return (
    <S.UnitWrapper isCompareMode={compareMode}>
      <EditIcon className="compare-mode-edit" onClick={handleUpdateMetric} />
      <S.ItemUnitWrapper>
        <S.ItemUnitInfo>
          <S.Title>Weight</S.Title>
          <S.Value>{weight ? `${roundNumberBodyMetric(weight)} ${getWeightUnit}` : `- ${getWeightUnit}`}</S.Value>
        </S.ItemUnitInfo>
      </S.ItemUnitWrapper>
      <S.ItemUnitWrapper>
        <S.ItemUnitInfo>
          <S.Title>Body Fat</S.Title>
          <S.Value>{bodyFat ? `${roundNumberBodyMetric(bodyFat)}%` : '- %'}</S.Value>
        </S.ItemUnitInfo>
      </S.ItemUnitWrapper>
    </S.UnitWrapper>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      progressPhoto: { compareMode },
    },
  } = state;

  return { compareMode };
};

const mapDispatchToProps = dispatch => ({
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationUnit);
