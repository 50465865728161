/**
 * @flow
 */

import { CHANGE_CALENDAR_TYPE, CHANGE_CALENDAR_START_DATE } from 'actions/calendar';
import { DEFAULT_CALENDAR_TYPE } from 'constants/commonData';
import { DateTime } from 'luxon';

export const calendarType = (state = DEFAULT_CALENDAR_TYPE.value, action) => {
  switch (action.type) {
    case CHANGE_CALENDAR_TYPE:
      return action.calendarType;
    default:
      return state;
  }
};

const getStartDate = () => {
  const today = DateTime.local().startOf('week');
  return today;
};

export const calendarStartDate = (state = getStartDate(), action) => {
  switch (action.type) {
    case CHANGE_CALENDAR_START_DATE:
      return action.calendarStartDate;
    default:
      return state;
  }
};
