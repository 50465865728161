// Libs
import React from 'react';
import ReactTooltip from 'react-tooltip';

// Shared
import { Toggle } from 'shared/FormControl';

// Constants
import { QUESTION_TYPES } from 'constants/commonData';

// Styles
import * as S from './style';

const SyncOption = ({ workingQuestion, onChange, disabled, toggleConfirmModal, hasSync }) => {
  const { _id = '', type = '', is_sync } = workingQuestion || {};

  const isSync = typeof is_sync === 'undefined' ? true : is_sync;

  const getOptionTitle = () => {
    switch (type) {
      case QUESTION_TYPES.DATE_TIME:
        return 'Sync with Date of Birth';
      case QUESTION_TYPES.METRIC:
        return `Sync with client's metric`;
      default:
        return '';
    }
  };

  const getOptionTooltipContent = () => {
    switch (type) {
      case QUESTION_TYPES.DATE_TIME:
        return 'Current Date of Birth has synced with another question';
      case QUESTION_TYPES.METRIC:
        return 'Current metric has synced with another question';
      default:
        return '';
    }
  };

  const handleChange = () => {
    typeof onChange === 'function' &&
      onChange({
        _id,
        type,
        is_sync: !isSync,
      });
  };

  const openSyncPopup = () => {
    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          title="Sync with Date of Birth?"
          content="Enabling this setting will update the client's date of birth when they answer this question."
          onConfirm={handleChange}
          onClose={() => toggleConfirmModal(false)}
          confirmButtonTitle="I understand"
          className="sync-dob-confirm-modal"
          noBorder
          noIcon
          newStyle
          isCloseOnDimmer={false}
          isPressEsc
        />,
      );
  };

  return (
    <S.SyncOptionWrapper data-id="sync-dob-option">
      <S.ActionsWrapper>
        <div>{getOptionTitle()}</div>
        <Toggle
          checked={isSync}
          onChange={isSync || ![QUESTION_TYPES.DATE_TIME].includes(type) ? handleChange : openSyncPopup}
          width={28}
          height={16}
          className="form-builder-required-toggle"
          disabled={disabled}
          data-for={`option-sync-tooltip-${_id}`}
          data-tip
        />
        {hasSync && (
          <ReactTooltip
            className={`app-tooltip option-sync-tooltip ${type}`}
            id={`option-sync-tooltip-${_id}`}
            effect="solid"
            place="top"
          >
            <span>{getOptionTooltipContent()}</span>
          </ReactTooltip>
        )}
      </S.ActionsWrapper>
    </S.SyncOptionWrapper>
  );
};

export default SyncOption;
