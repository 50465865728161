import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { ConfirmPaymentWrapper } from './style';
import { axiosInstance } from 'configs/request';
import { updateSelectPackage, confirmPayment, initPricingData } from 'redux/pricing/actions';
import { updatePaymentStatus } from 'redux/payment/actions';
import { getBillingOverviewData, updatePaymentInfo } from 'redux/billing/actions';
import HeadingConfirmPayment from './HeadingConfirmPayment';
import UpgradeMessage from './UpgradeMessage';
import PaymentMethod from './PaymentMethod';
import PaymentSummary from './PaymentSummary';
import ZapierPaymentSummary from './ZapierPaymentSummary';
import { DEFAULT_INFORMATION } from 'components/StripeBillingInformation';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { validateStripeBillingDetail } from 'utils/validations';
import PopupPaymentSuccess from './PopupPaymentSuccess';
import ProcessingPaymentPopup from './ProcessingPaymentPopup';
import { initPermission } from 'redux/permission/actions';
import { push } from 'connected-react-router';
import { WebSocketContext } from 'libs/socket';
import { SOCKET_EVENTS } from 'libs/socket/constants';
import { calculatePaymentObject } from '../helper';
class ConfirmPayment extends React.Component {
  state = {
    isChangeCard: false,
    billingDetail: { ...DEFAULT_INFORMATION, ...this.props.billingDetail },
    invalidCard: false,
    emptyCard: true,
    submitting: false,
    showError: false,
  };

  toggleChangeCard = () => {
    this.setState({
      isChangeCard: !this.state.isChangeCard,
      submitting: false,
      showError: false,
      invalidCard: false,
      billingDetail: DEFAULT_INFORMATION,
      error: null,
    });
  };

  onBillingInfoChange = data => {
    this.setState(
      {
        billingDetail: { ...data },
        error: null,
      },
      this.checkShowError,
    );
  };

  onCardChange = data => {
    const { empty, error } = data;
    this.setState(
      {
        invalidCard: !!error,
        emptyCard: empty,
        isCardFocus: true,
        error: null,
      },
      this.checkShowError,
    );
  };

  onChangeCardInfo = () => {
    this.setState({ isChangeCard: !this.state.isChangeCard });
  };

  handleAddCreditCard = async () => {
    const { stripe, elements, paymentInfo } = this.props;
    const { isChangeCard, billingDetail, showError } = this.state;

    if (!isChangeCard && paymentInfo.payment_method_id) {
      return paymentInfo;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      console.error('Stripe.js has not loaded yet.');
      return;
    }

    const trimmedValues = _.mapValues(billingDetail, o => (o + '').trim());

    if (!validateStripeBillingDetail(trimmedValues)) {
      if (!showError) {
        this.setState({ showError: true });
      }

      return;
    }

    const billing_details = {
      name: trimmedValues.name,
      address: _.pick(trimmedValues, ['city', 'line1', 'state', 'country', 'postal_code']),
    };

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details,
    });

    if (payload && payload.error) {
      const errMsg = _.get(payload, 'error.message');
      this.setState({ error: errMsg });
      return null;
    }

    const payment_method_id = _.get(payload, 'paymentMethod.id');
    if (!payment_method_id) {
      return null;
    } else {
      const cardInfo = payload
        ? {
            last4: _.get(payload, 'paymentMethod.card.last4'),
            brand: _.get(payload, 'paymentMethod.card.brand'),
            funding: _.get(payload, 'paymentMethod.card.funding'),
            exp_month: _.get(payload, 'paymentMethod.card.exp_month'),
            exp_year: _.get(payload, 'paymentMethod.card.exp_year'),
          }
        : {};
      const submitData = { ...trimmedValues, payment_method_id, ...cardInfo };
      const paymentRes = await this.updatePaymentInfo(submitData);
      return _.get(paymentRes, 'data.data');
    }
  };

  updatePaymentInfo = params => {
    const { stripe } = this.props;
    return axiosInstance.post('/api/pricing/add-team-payment-info', params).catch(async err => {
      const errorType = _.get(err, 'response.data.error');
      const clientSecret = _.get(err, 'response.data.client_secret');
      const errorMessage = _.get(err, 'response.data.message');
      if (errorMessage) {
        if (clientSecret && errorType === 'STRIPE_API_ERROR') {
          const cardAction = await stripe.confirmCardPayment(clientSecret);
          console.log(cardAction);
        } else {
          this.setState({ error: _.get(err, 'response.data.message') });
        }
      }
      throw err;
    });
  };

  checkShowError = () => {
    const { selectPackage, addOnsPricing, paymentInfo, teamData, downgrade } = this.props;
    const paymentObject = calculatePaymentObject(selectPackage, addOnsPricing, paymentInfo, teamData, downgrade);
    const isEmptyForm = this.isEmptyForm();
    if (isEmptyForm && paymentObject.totalDue <= 0) {
      this.setState({ showError: false });
    }
  };

  isEmptyForm = () => {
    const { emptyCard, billingDetail } = this.state;
    const trimmedValues = _.mapValues(billingDetail, o => (o + '').trim());
    return (
      emptyCard &&
      !trimmedValues.name &&
      !trimmedValues.line1 &&
      !trimmedValues.postal_code &&
      !trimmedValues.city &&
      !trimmedValues.state
    );
  };

  handleConfirm = async event => {
    event.preventDefault();
    const { submitting } = this.state;
    const { stripe, selectPackage, addOnsPricing, paymentInfo, teamData, downgrade, zapierPath } = this.props;
    if (submitting) {
      return;
    }
    try {
      this.setState({ submitting: true });
      const paymentObject = calculatePaymentObject(selectPackage, addOnsPricing, paymentInfo, teamData, downgrade);
      const isEmptyForm = this.isEmptyForm();
      let creditPaymentInfo = {};
      if (paymentObject.totalDue > 0 || !isEmptyForm) {
        creditPaymentInfo = await this.handleAddCreditCard();
      }
      if (creditPaymentInfo) {
        this.props.updatePaymentStatus(null);
        // Monthly Zapier will have different API endpoint
        const { period = '', zapier_quantity = 0 } = selectPackage;
        const isZapier = { has_zapier: zapierPath };
        const upgradeZapier = zapier_quantity > teamData.zapier_quantity && period === 'monthly';
        const confirmPaymentPromise = isZapier && upgradeZapier ? isZapier : null;

        this.props
          .confirmPayment(confirmPaymentPromise)
          .then(data => {
            if (paymentObject.totalDue > 0 || !this.props.downgrade) {
              this.setState({
                submitting: false,
                paymentSuccess: true,
              });
            } else {
              this.goHome();
            }
          })
          .catch(async err => {
            const errorType = _.get(err, 'response.data.error');
            const clientSecret = _.get(err, 'response.data.client_secret');
            const errorMessage = _.get(err, 'response.data.message');
            if (errorMessage) {
              if (errorType === 'STRIPE_API_ERROR' && clientSecret) {
                const cardAction = await stripe.confirmCardPayment(clientSecret);
                if (cardAction && cardAction.error) {
                  const errMsg = _.get(cardAction, 'error.message');
                  this.setState({ submitting: false, error: errMsg });
                } else {
                  this.onSocketPaymentSuccess();
                }
              } else {
                this.setState({ submitting: false, error: errorMessage });
              }
            } else {
              this.setState({
                submitting: false,
              });
            }
          });
      } else {
        this.setState({ submitting: false });
      }
    } catch (error) {
      this.setState({
        submitting: false,
      });
    }
  };

  goHome = () => {
    this.props.push('/home/billing');
    setTimeout(() => {
      this.props.initPermission();
    }, 1000);
    this.props.initPricingData();
  };

  onSocketPaymentSuccess = () => {
    const { socket } = this.context;
    socket.removeAllListeners(SOCKET_EVENTS.PaymentAuthenUpdate);
    socket.on(SOCKET_EVENTS.PaymentAuthenUpdate, data => {
      this.props.updatePaymentStatus(data);
    });
  };

  render() {
    const { zapierPath, downgrade, onBack, is_payment_success, selectPackage } = this.props;
    const {
      isChangeCard,
      billingDetail,
      invalidCard,
      showError,
      emptyCard,
      paymentSuccess,
      submitting,
      error,
    } = this.state;
    const { period = '' } = selectPackage;

    const isZapier = zapierPath === '/home/integration';
    const onlyMonthlyZapier = isZapier && period === 'monthly';
    const onlyMonthlyZapierUpgrade = onlyMonthlyZapier && !downgrade;

    return (
      <ConfirmPaymentWrapper>
        <HeadingConfirmPayment disableBack={onlyMonthlyZapierUpgrade} onBack={onBack} />
        {!onlyMonthlyZapierUpgrade && <UpgradeMessage downgrade={downgrade} />}
        <div className="confirmPayment__body">
          <PaymentMethod
            className="confirmPayment__body--paymentMethod"
            isChangeCard={isChangeCard}
            toggleChangeCard={this.toggleChangeCard}
            billingDetail={billingDetail}
            invalidCard={invalidCard}
            onBillingInfoChange={this.onBillingInfoChange}
            onCardChange={this.onCardChange}
            showError={showError}
            emptyCard={emptyCard}
            error={error}
          />
          {onlyMonthlyZapierUpgrade ? (
            <ZapierPaymentSummary
              className="confirmPayment__body--summary"
              onConfirm={this.handleConfirm}
              invalidCard={submitting || (invalidCard && isChangeCard && emptyCard)}
              downgrade={downgrade}
            />
          ) : (
            <PaymentSummary
              className="confirmPayment__body--summary"
              onConfirm={this.handleConfirm}
              invalidCard={submitting || (invalidCard && isChangeCard && emptyCard)}
              downgrade={downgrade}
            />
          )}
          {submitting && <ProcessingPaymentPopup />}
        </div>
        {(paymentSuccess || is_payment_success) && <PopupPaymentSuccess />}
      </ConfirmPaymentWrapper>
    );
  }
}

ConfirmPayment.contextType = WebSocketContext;

const ConfirmPaymentWithStripe = props => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => <ConfirmPayment {...props} elements={elements} stripe={stripe} />}
    </ElementsConsumer>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    planPricing: rootReducer.pricing.get('planPricing').toJS(),
    planPackageObj: rootReducer.pricing.get('planPackage').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    paymentInfo: rootReducer.billing.paymentInfo,
    is_payment_success: rootReducer.payment.is_payment_success,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: bindActionCreators(push, dispatch),
    updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
    confirmPayment: bindActionCreators(confirmPayment, dispatch),
    getBillingOverviewData: bindActionCreators(getBillingOverviewData, dispatch),
    updatePaymentInfo: bindActionCreators(updatePaymentInfo, dispatch),
    updatePaymentStatus: bindActionCreators(updatePaymentStatus, dispatch),
    initPermission: bindActionCreators(initPermission, dispatch),
    initPricingData: bindActionCreators(initPricingData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPaymentWithStripe);
