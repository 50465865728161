import React from 'react';
import { debounce } from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';

import { NewSearchInput } from 'shared/SearchInput';
import { Button } from 'shared/FormControl';
import ButtonIcon from 'shared/ButtonIcon';
import OnDemandFilters from 'shared/OnDemandFilters';

import CloseIcon from 'assets/icons/close_circle.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/add_grey.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/new_filter.svg';
import * as S from './style';
import { OWNER_TYPE } from 'shared/AssetsShareSetting/components/FiltersPopup';

class DialogModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      showFilters: false,
      filterOwner: OWNER_TYPE.ALL,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = nextProps;

    if (!open) {
      this.setState({ name: '', isSubmitted: false });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const { onSubmit } = this.props;

    if (onSubmit) {
      onSubmit && onSubmit(this.onCloseForm);
    }
  };

  onCloseForm = () => {
    const { onSetOpen } = this.props;
    onSetOpen && onSetOpen(false);
  };

  handleSearch = (event, { value }) => {
    const { onChangeSearchWorkouts } = this.props;
    onChangeSearchWorkouts && onChangeSearchWorkouts(value, this.state.filterOwner);
  };

  handleClearSearch = () => {
    const { onClearSearchWorkouts } = this.props;
    onClearSearchWorkouts && onClearSearchWorkouts(this.state.filterOwner);
  };

  handleShowFilters = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  handleUpdateFilters = (options, owner = OWNER_TYPE.ALL) => {
    const { onUpdateFilters } = this.props;
    onUpdateFilters && onUpdateFilters(options, owner);
    this.handleShowFilters();
  };

  handleSelectAll = () => {
    const { onSelectAll, disableSelectAll } = this.props;
    onSelectAll && onSelectAll(!disableSelectAll);
  };

  handleSetOwner = type => {
    this.setState({ filterOwner: type });
  };

  render() {
    const {
      open,
      onSetOpen,
      titlePopup,
      description,
      submitName,
      children,
      showLabel,
      shouldDisable,
      onCreateNewWorkout,
      groupLabels,
      categoryLabels,
      currentFilters,
      disableSelectAll,
    } = this.props;
    const { showFilters, filterOwner } = this.state;
    let counterFilters = 0;
    for (const [key, value] of Object.entries(currentFilters)) {
      if (key === 'others') {
        counterFilters = value.reduce(
          (accumulator, currentValue = {}) => (accumulator += (currentValue.values || []).length),
          counterFilters,
        );
      } else {
        counterFilters += value.length;
      }
    }
    if (filterOwner !== OWNER_TYPE.ALL) {
      counterFilters += 1;
    }

    return (
      <S.CreateAddNewDialog
        open={open}
        closeIcon={
          <CloseButton className="close-button" onClick={() => onSetOpen(false)}>
            <img src={CloseIcon} alt="" />
          </CloseButton>
        }
      >
        <Modal.Header>
          {titlePopup ? titlePopup : 'Create New'}
          {description && <S.Description>{description}</S.Description>}
          <S.SearchWrapper>
            <S.SearchContent>
              <NewSearchInput
                placeholder="Search by keyword or name"
                onChange={debounce(this.handleSearch, 300)}
                onClearSearch={this.handleClearSearch}
              />
              <S.FilterButtonWrapper>
                <ButtonIcon
                  text="Filter"
                  width={106}
                  height={30}
                  className="filter-options"
                  icon={<FilterIcon />}
                  color="rgb(119, 119, 119)"
                  textHoverColor="rgb(119, 119, 119)"
                  bgHoverColor="rgb(248, 248, 248)"
                  onClick={this.handleShowFilters}
                />
                {counterFilters > 0 && (
                  <S.CounterFilters>
                    <span>{counterFilters}</span>
                  </S.CounterFilters>
                )}
              </S.FilterButtonWrapper>

              {showFilters && (
                <OnDemandFilters
                  onUpdateFilters={this.handleUpdateFilters}
                  groupLabels={groupLabels}
                  currentFilters={currentFilters}
                  onCloseFilters={this.handleShowFilters}
                  categoryLabels={categoryLabels}
                  currentFilterOwner={filterOwner}
                  onUpdateFilterOwner={this.handleSetOwner}
                />
              )}
            </S.SearchContent>
            <ButtonIcon
              text={!disableSelectAll ? 'Select all' : 'Remove all'}
              width={88}
              height={30}
              icon={null}
              color="rgb(119, 119, 119)"
              textHoverColor="rgb(119, 119, 119)"
              bgHoverColor="rgb(248, 248, 248)"
              onClick={this.handleSelectAll}
            />
          </S.SearchWrapper>
        </Modal.Header>
        <Modal.Content>{children}</Modal.Content>
        <S.SActions>
          <S.Footer hasLabel={showLabel}>
            {showLabel && (
              <S.FooterLabelWrapper onClick={onCreateNewWorkout}>
                <PlusIcon />
                <S.FooterCreateNewWorkoutLabel>Add New On-Demand Workout</S.FooterCreateNewWorkoutLabel>
              </S.FooterLabelWrapper>
            )}
            <div>
              <Button className="cancel" onClick={this.onCloseForm}>
                Cancel
              </Button>
              <Button className="submit" disabled={shouldDisable} onClick={this.handleSubmit} purple>
                {submitName ? submitName : 'Create New'}
              </Button>
            </div>
          </S.Footer>
        </S.SActions>
      </S.CreateAddNewDialog>
    );
  }
}

export default DialogModal;
