import styled, { css } from 'styled-components';

export const BannerWrapper = styled.div``;

export const BannerSliderContainer = styled.div`
  display: flex;
  height: 260px;
`;

export const Item = styled.div`
  display: flex !important;
  width: 100%;
  height: 260px;
  background: white;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  .nextButton {
    margin-top: 30px;
    margin-left: 30px;

    button {
      border: 1px solid #d4d7d9;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      border-radius: 5px;

      svg {
        margin-left: 10px;
      }
    }
  }
`;

export const Media = styled.div`
  width: 397px;
  height: 260px;
  background-color: #fbcdb4;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  ${props =>
    props.video &&
    css`
      background-color: white;

      iframe {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    `}

  ${props =>
    props.url &&
    css`
      background: url(${props.url});
      background-color: white;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `}
`;

export const Content = styled.div`
  width: inherit;
  padding: 0 30px;
`;

export const Title = styled.div`
  color: #202353;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 2px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const TitleOfDescription = styled.div`
  color: #202353;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 2px;
  text-align: left;
`;

export const TitleLink = styled.a`
  color: #202353;
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 2px;
  text-align: left;

  &:hover,
  &:active,
  &:focus {
    color: #5c5bbd;
    text-decoration: none !important;
    cursor: pointer;
  }
`;

export const Description = styled.div`
  max-width: 350px;
  font-size: 13px;
  line-height: 19.5px;
  margin-bottom: 2px;
  color: #202353;
  opacity: 0.8;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

export const Dots = styled.div`
  width: 8px;
  height: 8px;
  padding: 0;
  background: #e2e2e2;
  border-radius: 50%;

  &:before {
    content: none;
  }
`;

export const Slider = styled.div`
  position: relative;
  width: calc(100% - 397px);

  .slick-dots {
    width: auto;
    right: 20px;
    top: 20px;
    bottom: initial;
    z-index: 1;

    .slick-active {
      ${Dots} {
        background: #5c5bbd;
        border: none;
      }
    }

    li {
      width: initial;
      height: initial;
      margin: 0 4px;
    }
  }

  .slick-list {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const HideBannerButton = styled.div`
  color: #978bb9;
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 4px 16px 4px 10px;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;

  svg {
    margin-left: 11px;
    transform: rotateZ(270deg);
  }

  &:hover {
    color: #5158cf;

    svg {
      path {
        stroke: #5158cf;
      }
    }
  }
`;
