import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getWorkoutVisibilityPreferences, updateWorkoutVisibilityPreferences } from 'actions/client';
import { WorkoutSettings } from 'shared/Settings';

function Component(props) {
  const { client, onClientWorkoutChange, isAllChangeSetting } = props;
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    props.getWorkoutVisibilityPreferences(client).then(response => {
      const { data } = response.data;
      setSettings(data);
    });
  }, []);

  return (
    <WorkoutSettings
      isAllChangeSetting={isAllChangeSetting}
      originSettings={settings}
      onClientWorkoutChange={onClientWorkoutChange}
      onSave={bodyData =>
        props.updateWorkoutVisibilityPreferences({ ...bodyData, client }).then(response => {
          const { data } = response.data;
          setSettings(data);
          return response;
        })
      }
      isClientSettings={true}
    />
  );
}

const actionCreators = { getWorkoutVisibilityPreferences, updateWorkoutVisibilityPreferences };

export default connect(null, actionCreators)(Component);
