// Libs
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import pick from 'lodash/pick';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Actions
import { showError, hideError } from 'actions/error';

// Request
import Request from 'configs/request';

// constants
import { ListUnit } from 'components/RecipeDetail/constants';
import { RECIPE_STATUS } from '../../components/Recipes/constants';
import { RECIPE_HEADER_TABS } from 'components/Recipes/constants';

export const Types = {
  RECIPE_GET_LIST_REQUEST: 'RECIPE_GET_LIST_REQUEST',
  RECIPE_GET_LIST_SUCCESS: 'RECIPE_GET_LIST_SUCCESS',
  RECIPE_GET_LIST_FAILED: 'RECIPE_GET_LIST_FAILED',
  RECIPES_CHANGE_QUERY_PARAMS: 'RECIPES_CHANGE_QUERY_PARAMS',
  RECIPE_ADD_LIBRARIES_SUCCESS: 'RECIPE_ADD_LIBRARIES_SUCCESS',
  RECIPE_GET_LIST_CATEGORY_INGREDIENT_REQUEST: 'RECIPE_GET_LIST_CATEGORY_INGREDIENT_REQUEST',
  RECIPE_GET_LIST_CATEGORY_INGREDIENT_SUCCESS: 'RECIPE_GET_LIST_CATEGORY_INGREDIENT_SUCCESS',
  RECIPE_GET_LIST_CATEGORY_INGREDIENT_FAILED: 'RECIPE_GET_LIST_CATEGORY_INGREDIENT_FAILED',
  RECIPE_GET_LIST_INGREDIENT_REQUEST: 'RECIPE_GET_LIST_INGREDIENT_REQUEST',
  RECIPE_GET_LIST_INGREDIENT_SUCCESS: 'RECIPE_GET_LIST_INGREDIENT_SUCCESS',
  RECIPE_GET_LIST_INGREDIENT_FAILED: 'RECIPE_GET_LIST_INGREDIENT_FAILED',
  RECIPE_UPDATE_FILTER_INGREDIENT: 'RECIPE_UPDATE_FILTER_INGREDIENT',
  RECIPE_GET_DETAIL_SUCCESS: 'RECIPE_GET_DETAIL_SUCCESS',
  RECIPE_EDIT_SUCCESS: 'RECIPE_EDIT_SUCCESS',
  RECIPE_UPDATE_DATA_DETAIL: 'RECIPE_UPDATE_DATA_DETAIL',
  RECIPE_DELETE_REQUEST: 'RECIPE_DELETE_REQUEST',
  RECIPE_DELETE_SUCCESS: 'RECIPE_DELETE_SUCCESS',
  RECIPE_DELETE_FAILED: 'RECIPE_DELETE_FAILED',
  RECIPE_GET_TOTAL_SUCCESS: 'RECIPE_GET_TOTAL_SUCCESS',
  RECIPE_GET_INIT_TOTAL_SUCCESS: 'RECIPE_GET_INIT_TOTAL_SUCCESS',
  RECIPE_GET_INIT_TOTAL_AFTER_UPDATE: 'RECIPE_GET_INIT_TOTAL_AFTER_UPDATE',
  RECIPE_RESET_DETAIL: 'RECIPE_RESET_DETAIL',
  RECIPE_RESET_FILTERS: 'RECIPE_RESET_FILTERS',
  RECIPE_RESET_LISTS: 'RECIPE_RESET_LISTS',
  RECIPE_UPDATE_SHARING_STATUS_REQUEST: 'RECIPE_UPDATE_SHARING_STATUS_REQUEST',
  RECIPE_UPDATE_SHARING_STATUS_SUCCESS: 'RECIPE_UPDATE_SHARING_STATUS_SUCCESS',
  RECIPE_UPDATE_SHARING_STATUS_FAILED: 'RECIPE_UPDATE_SHARING_STATUS_FAILED',
  RECIPE_SELECTED_COVER_IMAGE: 'RECIPE_SELECTED_COVER_IMAGE',
  RECIPE_ADD_BACKGROUND_LIBRARIES_REQUEST: 'RECIPE_ADD_BACKGROUND_LIBRARIES_REQUEST',
  RECIPE_ADD_BACKGROUND_LIBRARIES_SUCCESS: 'RECIPE_ADD_BACKGROUND_LIBRARIES_SUCCESS',
  RECIPE_ADD_BACKGROUND_LIBRARIES_FAILED: 'RECIPE_ADD_BACKGROUND_LIBRARIES_FAILED',
  RECIPE_GET_BACKGROUND_LIBRARIES_REQUEST: 'RECIPE_GET_BACKGROUND_LIBRARIES_REQUEST',
  RECIPE_GET_BACKGROUND_LIBRARIES_SUCCESS: 'RECIPE_GET_BACKGROUND_LIBRARIES_SUCCESS',
  RECIPE_GET_BACKGROUND_LIBRARIES_FAILED: 'RECIPE_GET_BACKGROUND_LIBRARIES_FAILED',
  RECIPE_DELETE_BACKGROUND_LIBRARIES_REQUEST: 'RECIPE_DELETE_BACKGROUND_LIBRARIES_REQUEST',
  RECIPE_DELETE_BACKGROUND_LIBRARIES_SUCCESS: 'RECIPE_DELETE_BACKGROUND_LIBRARIES_SUCCESS',
  RECIPE_DELETE_BACKGROUND_LIBRARIES_FAILED: 'RECIPE_DELETE_BACKGROUND_LIBRARIES_FAILED',
  RECIPE_RESET_INGREDIENT: 'RECIPE_RESET_INGREDIENT',
  RECIPE_DUPLICATE_LIBRARIES_REQUEST: 'RECIPE_DUPLICATE_LIBRARIES_REQUEST',
  RECIPE_DUPLICATE_LIBRARIES_SUCCESS: 'RECIPE_DUPLICATE_LIBRARIES_SUCCESS',
  RECIPE_DUPLICATE_LIBRARIES_FAILED: 'RECIPE_DUPLICATE_LIBRARIES_FAILED',
  RECIPE_RESET_BACKGROUND_LIBRARY: 'RECIPE_RESET_BACKGROUND_LIBRARY',
  RECIPE_ADD_LOCAL_TO_BACKGROUND_LIBRARY: 'RECIPE_ADD_LOCAL_TO_BACKGROUND_LIBRARY',
  RECIPE_REMOVE_LOCAL_TO_BACKGROUND_LIBRARY: 'RECIPE_REMOVE_LOCAL_TO_BACKGROUND_LIBRARY',
  RECIPE_TOGGLE_CREATE_NEW: 'RECIPE_TOGGLE_CREATE_NEW',
  RECIPE_DETAIL_TOGGLE_EDIT_MODE: 'RECIPE_DETAIL_TOGGLE_EDIT_MODE',
  RECIPE_DETAIL_START_SUBMIT: 'RECIPE_DETAIL_START_SUBMIT',
  RECIPE_DETAIL_END_SUBMIT: 'RECIPE_DETAIL_END_SUBMIT',
  RECIPE_PUBLISH_SUCCESS: 'RECIPE_PUBLISH_SUCCESS',
  RECIPE_UNPUBLISH_SUCCESS: 'RECIPE_UNPUBLISH_SUCCESS',
  RECIPE_DETAIL_RESET_EDIT_MODE: 'RECIPE_DETAIL_RESET_EDIT_MODE',
  RECIPE_DETAIL_SET_LOADING: 'RECIPE_DETAIL_SET_LOADING',
  RECIPE_DETAIL_IS_SUBMITTED: 'RECIPE_DETAIL_IS_SUBMITTED',
  RECIPE_GET_UNIT_TYPE: 'RECIPE_GET_UNIT_TYPE',
  RECIPE_UPDATE_UNIT_TYPE: 'RECIPE_UPDATE_UNIT_TYPE',
  RECIPE_SET_HEADER_TABS: 'RECIPE_SET_HEADER_TABS',
  RECIPE_GET_LIST_OTHER_NUTRIENT_SUCCESS: 'RECIPE_GET_LIST_OTHER_NUTRIENT_SUCCESS',
  RECIPE_GET_LIST_DIETARY_REQUEST: 'RECIPE_GET_LIST_DIETARY_REQUEST',
  RECIPE_GET_LIST_DIETARY_SUCCESS: 'RECIPE_GET_LIST_DIETARY_SUCCESS',
  RECIPE_GET_LIST_DIETARY_FAILED: 'RECIPE_GET_LIST_DIETARY_FAILED',
  RECIPE_UPDATE_DATA_INGREDIENT: 'RECIPE_UPDATE_DATA_INGREDIENT',
  RECIPE_GET_TOTAL_RECIPE_IN_TEAM_REQUEST: 'RECIPE_GET_TOTAL_RECIPE_IN_TEAM_REQUEST',
  RECIPE_GET_TOTAL_RECIPE_IN_TEAM_SUCCESS: 'RECIPE_GET_TOTAL_RECIPE_IN_TEAM_SUCCESS',
  RECIPE_UPDATE_LOADING_VIDEO_INSTRUCTION: 'RECIPE_UPDATE_LOADING_VIDEO_INSTRUCTION',
  RECIPE_TOGGLE_AUTO_CALCULATED: 'RECIPE_TOGGLE_AUTO_CALCULATED',
  RECIPE_UPDATE_INGREDIENT_DATA: 'RECIPE_UPDATE_INGREDIENT_DATA',
  RECIPE_UPDATE_AUTO_CALCULATED_NUTRITION: 'RECIPE_UPDATE_AUTO_CALCULATED_NUTRITION',
};

const handleLostPermission = (dispatch, getState, message, status) => {
  if (status === 403 || status === 400) {
    dispatch(showError(message, null, null, null, null, () => backToRecipes(dispatch, getState)));
  }
};

export const getRecipeLibraries = params => {
  const pickParams = pick(params, [
    'page',
    'per_page',
    'text_search',
    'sort',
    'sorter',
    'dietaries',
    'ingredient_ids',
    'only_my_recipes',
    'category',
    'recipe_from',
    'status',
    'from_calories',
    'to_calories',
  ]);

  return dispatch => {
    dispatch({ type: Types.RECIPE_GET_LIST_REQUEST });
    dispatch(
      Request.get(
        {
          url: `/api/recipe-libraries`,
          params: pickParams,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', []);
          const total = get(response, 'data.total', 0);

          if (data) {
            dispatch({
              type: Types.RECIPE_GET_LIST_SUCCESS,
              payload: { data, total, newPage: get(params, 'page', 1) },
            });
          }
        },
        () => {
          dispatch({ type: Types.RECIPE_GET_LIST_FAILED });
        },
      ),
    );
  };
};

export const enableEditModeRecipe = (id, shouldSendRequest) => {
  return (dispatch, getState) => {
    if (!shouldSendRequest) {
      return dispatch({ type: Types.RECIPE_DETAIL_TOGGLE_EDIT_MODE, payload: { status: true } });
    }
    dispatch({ type: Types.RECIPE_DETAIL_START_SUBMIT });
    dispatch(
      Request.patch(
        { url: `/api/recipe-libraries/${id}/edit-mode`, data: { enable: true } },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.data');

          if (success) {
            toast(
              shouldSendRequest
                ? "You're in editing mode. Changes will go live when you published."
                : "You're in editing mode",
            );
            dispatch({ type: Types.RECIPE_DETAIL_TOGGLE_EDIT_MODE, payload: { status: true } });
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          dispatch({ type: Types.RECIPE_DETAIL_END_SUBMIT, payload: { errorCode } });
          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const cancelEditModeRecipe = (id, shouldSendRequest, callback) => {
  return (dispatch, getState) => {
    if (!shouldSendRequest) {
      return dispatch({ type: Types.RECIPE_DETAIL_TOGGLE_EDIT_MODE, payload: { status: false } });
    }
    dispatch({ type: Types.RECIPE_DETAIL_START_SUBMIT });
    dispatch(
      Request.patch(
        { url: `/api/recipe-libraries/${id}/edit-mode`, data: { enable: false } },
        true,
        async (response, { dispatch }) => {
          const success = get(response, 'data.data');

          if (success) {
            await dispatch(getDetailRecipe(id));
            dispatch({ type: Types.RECIPE_DETAIL_TOGGLE_EDIT_MODE, payload: { status: false } });
            callback && callback();
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          dispatch({ type: Types.RECIPE_DETAIL_END_SUBMIT, payload: { errorCode } });
          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const addRecipeLibraries = (params, callBack) => {
  return async (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_DETAIL_START_SUBMIT });

    // Get unit type
    const resUnitType = await dispatch(getUnitType());
    const unitTypeKey = get(resUnitType, 'data.data.unit_type');
    const unitType = ListUnit.find(item => item && item.key === unitTypeKey);

    return dispatch(
      Request.post(
        {
          url: `/api/recipe-libraries`,
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;

          if (!isEmpty(data)) {
            dispatch({ type: Types.RECIPE_ADD_LIBRARIES_SUCCESS, payload: { data: { ...data, unit: unitType } } });
            callBack && callBack();
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          dispatch({ type: Types.RECIPE_DETAIL_END_SUBMIT, payload: { errorCode } });
          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const getDetailRecipe = (id, isDispatch = true) => {
  return async (dispatch, getState) => {
    // Get unit type
    const resUnitType = await dispatch(getUnitType());
    const unitTypeKey = get(resUnitType, 'data.data.unit_type');
    const unitType = ListUnit.find(item => item && item.key === unitTypeKey);

    if (isDispatch) {
      dispatch({ type: Types.RECIPE_DETAIL_START_SUBMIT });
    }
    return dispatch(
      Request.get(
        {
          url: `/api/recipe-libraries/${id}`,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});

          const pattern = /^\/home\/recipes\/[0-9a-f]{24}$/;
          const pathName = window.location.pathname;
          const getIdFromPathName = pathName.replace('/home/recipes/', '');
          if (pattern.test(pathName) && getIdFromPathName === id) {
            if (data && isDispatch) {
              dispatch({
                type: Types.RECIPE_GET_DETAIL_SUCCESS,
                payload: { data, unitType },
              });
            }
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          if (isDispatch) {
            dispatch({ type: Types.RECIPE_DETAIL_END_SUBMIT, payload: { errorCode } });
          }

          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const getDetailRecipeFromMealPlan = (mealPlanId, recipeId) => {
  return Request.get(
    {
      url: `/api/meal-plans/${mealPlanId}/recipes/${recipeId}`,
    },
    true,
  );
};

export const getDetailRecipeFromClientTab = (mealPlanId, recipeId) => {
  return Request.get(
    {
      url: `/api/meal-clients/${mealPlanId}/recipes/${recipeId}`,
    },
    true,
  );
};

export const editRecipe = (id, data, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_DETAIL_START_SUBMIT });
    return dispatch(
      Request.put(
        {
          url: `/api/recipe-libraries/${id}`,
          data,
        },
        true,
        (response, { dispatch }) => {
          const data = get(response, 'data.data', {});
          if (data) {
            dispatch({ type: Types.RECIPE_EDIT_SUCCESS, payload: { data } });
            callback && callback();
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          dispatch({ type: Types.RECIPE_DETAIL_END_SUBMIT, payload: { errorCode } });
          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const getListCategoryIngredient = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_GET_LIST_CATEGORY_INGREDIENT_REQUEST });
    return dispatch(
      Request.get(
        {
          url: '/api/recipe-ingredients/categories',
        },
        false,
        response => {
          dispatch({
            type: Types.RECIPE_GET_LIST_CATEGORY_INGREDIENT_SUCCESS,
            payload: { data: get(response, 'data.data', []) },
          });
        },
        () => {
          dispatch({ type: Types.RECIPE_GET_LIST_CATEGORY_INGREDIENT_FAILED });
        },
      ),
    );
  };
};

export const getListIngredient = params => {
  return (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_GET_LIST_INGREDIENT_REQUEST });
    return dispatch(
      Request.get(
        {
          url: '/api/recipe-ingredients',
          params,
        },
        false,
        response => {
          dispatch({
            type: Types.RECIPE_GET_LIST_INGREDIENT_SUCCESS,
            payload: { data: get(response, 'data.data', []) },
          });
        },
        () => {
          dispatch({ type: Types.RECIPE_GET_LIST_INGREDIENT_FAILED });
        },
      ),
    );
  };
};

export const updateDataDetailRecipe = (data, status = true) => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_UPDATE_DATA_DETAIL, payload: { data, status } });
  };
};

export const changeQueryParams = (params = {}) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { recipes },
    } = getState();
    const filters = get(recipes, 'filters', {});

    const pickParams = pick(filters, [
      'page',
      'per_page',
      'text_search',
      'sort',
      'sorter',
      'dietaries',
      'ingredient_ids',
      'only_my_recipes',
      'category',
      'recipe_from',
      'status',
      'from_calories',
      'to_calories',
    ]);
    const newQuery = {
      ...pickParams,
      ...params,
    };
    dispatch({ type: Types.RECIPES_CHANGE_QUERY_PARAMS, payload: newQuery });
  };
};

export const removeRecipe = (id, callback, isList = false) => {
  return (dispatch, getState) => {
    const { user } = getState();

    dispatch({ type: Types.RECIPE_DELETE_REQUEST });

    return dispatch(
      Request.delete(
        { url: `/api/recipe-libraries/${id}` },
        false,
        response => {
          const { data } = response;

          const isSuccess = get(data, 'data.success', false);

          if (isSuccess) {
            dispatch({ type: Types.RECIPE_DELETE_SUCCESS, payload: { recipeId: id, isList, user } });
            callback && callback();
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '' } = data || {};
          dispatch({ type: Types.RECIPE_DELETE_FAILED, error: error });

          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const updateFilterIngredient = data => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_UPDATE_FILTER_INGREDIENT, payload: data });
  };
};

export const getTotalRecipe = params => {
  const pickParams = pick(params, [
    'page',
    'per_page',
    'text_search',
    'sort',
    'sorter',
    'dietaries',
    'ingredient_ids',
    'category',
    'recipe_from',
    'status',
    'from_calories',
    'to_calories',
  ]);

  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: '/api/recipe-libraries/total',
          params: pickParams,
        },
        false,
        response => {
          const isDraft = get(pickParams, 'status').includes(RECIPE_STATUS.DRAFT);
          dispatch({
            type: Types.RECIPE_GET_TOTAL_SUCCESS,
            payload: { data: get(response, 'data.data', ''), isDraft },
          });
        },
      ),
    );
  };
};

export const getInitTotalRecipe = (hasUpdate = false) => {
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: '/api/recipe-libraries/total',
        },
        false,
        response => {
          if (hasUpdate) {
            dispatch({
              type: Types.RECIPE_GET_INIT_TOTAL_AFTER_UPDATE,
              payload: { data: get(response, 'data.data', {}) },
            });
            return;
          }
          dispatch({
            type: Types.RECIPE_GET_INIT_TOTAL_SUCCESS,
            payload: { data: get(response, 'data.data', {}) },
          });
        },
      ),
    );
  };
};

export const resetDetailRecipe = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_RESET_DETAIL });
  };
};

export const resetFilters = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_RESET_FILTERS });
  };
};

export const resetCurrentList = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_RESET_LISTS });
  };
};

export const updateRecipeSharingStatus = (id, params, callBack) => {
  return (dispatch, getState) => {
    const {
      user,
      rootReducer: { recipes },
    } = getState();

    dispatch({ type: Types.RECIPE_UPDATE_SHARING_STATUS_REQUEST });

    return dispatch(
      Request.put(
        {
          url: `/api/recipe-libraries/${id}/share`,
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: {
              data: { success },
            },
          } = response;
          if (success) {
            dispatch({
              type: Types.RECIPE_UPDATE_SHARING_STATUS_SUCCESS,
              payload: { recipeId: id, updateShare: params, user },
            });
            dispatch(getRecipeLibraries(get(recipes, 'filters', {})));
            dispatch(getTotalRecipe({ ...get(recipes, 'filters', {}), status: [RECIPE_STATUS.PUBLISH] }));
            callBack && callBack();
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '' } = data || {};
          dispatch({ type: Types.RECIPE_UPDATE_SHARING_STATUS_FAILED, payload: error });
          callBack && callBack();

          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const selectedCoverBackground = data => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_SELECTED_COVER_IMAGE, payload: data });
  };
};

export const addBackgroundLibraries = params => {
  return (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_ADD_BACKGROUND_LIBRARIES_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/recipe-background-libraries`,
          params,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;

          if (data) {
            dispatch({
              type: Types.RECIPE_ADD_BACKGROUND_LIBRARIES_SUCCESS,
              payload: data,
            });
          }
        },
        error => {
          dispatch({ type: Types.RECIPE_ADD_BACKGROUND_LIBRARIES_FAILED, payload: error });
        },
      ),
    );
  };
};

export const getRecipeBackgroundLibraries = (params, hasDispatch = true) => {
  return dispatch => {
    if (hasDispatch) {
      dispatch({ type: Types.RECIPE_GET_BACKGROUND_LIBRARIES_REQUEST });
    }
    return dispatch(
      Request.get(
        {
          url: `/api/recipe-background-libraries`,
          params,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;

          if (data) {
            if (hasDispatch) {
              dispatch({
                type: Types.RECIPE_GET_BACKGROUND_LIBRARIES_SUCCESS,
                payload: data,
              });
            }
          }
        },
        error => {
          if (hasDispatch) {
            dispatch({ type: Types.RECIPE_GET_BACKGROUND_LIBRARIES_FAILED, payload: error });
          }
        },
      ),
    );
  };
};

export const deleteRecipeBackgroundLibraries = id => {
  return (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_DELETE_BACKGROUND_LIBRARIES_REQUEST });

    return dispatch(
      Request.delete(
        {
          url: `/api/recipe-background-libraries/${id}`,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data: success },
          } = response;

          if (success) {
            dispatch({
              type: Types.RECIPE_DELETE_BACKGROUND_LIBRARIES_SUCCESS,
              payload: { id },
            });
          }
        },
        error => {
          dispatch({ type: Types.RECIPE_DELETE_BACKGROUND_LIBRARIES_FAILED, payload: error });
        },
      ),
    );
  };
};

export const resetFiltersIngredient = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_RESET_INGREDIENT });
  };
};

export const duplicateRecipe = (id, params, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_DUPLICATE_LIBRARIES_REQUEST });

    return dispatch(
      Request.post(
        {
          url: `/api/recipe-libraries/${id}/duplicate`,
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          const id = get(data, '_id');

          if (id && data) {
            dispatch({
              type: Types.RECIPE_DUPLICATE_LIBRARIES_SUCCESS,
              payload: data,
            });
            dispatch(push(`/home/recipes/${id}?duplicate=true`));
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '' } = data || {};
          dispatch({ type: Types.RECIPE_DUPLICATE_LIBRARIES_FAILED, payload: error });

          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const checkNoFirstRecipe = () => {
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: `/api/recipe-libraries`,
          params: { only_my_recipes: true },
        },
        false,
      ),
    );
  };
};

export const resetBackgroundLibrary = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_RESET_BACKGROUND_LIBRARY });
  };
};

export const addLocalBackgroundLibrary = data => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_ADD_LOCAL_TO_BACKGROUND_LIBRARY, payload: { data } });
  };
};

export const removeLocalBackgroundLibrary = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_REMOVE_LOCAL_TO_BACKGROUND_LIBRARY });
  };
};

export const publishRecipe = (id, params) => {
  return (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_DETAIL_START_SUBMIT });

    return dispatch(
      Request.post(
        {
          url: `/api/recipe-libraries/${id}/publish`,
          data: params,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;

          if (!isEmpty(data)) {
            dispatch({
              type: Types.RECIPE_PUBLISH_SUCCESS,
              payload: { data },
            });
            toast('The recipe has been published. You can assign it to your clients or add it to any meal plans now.', {
              className: 'custom--recipe-toast',
            });
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          dispatch({ type: Types.RECIPE_DETAIL_END_SUBMIT, payload: { errorCode } });
          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const unpublishRecipe = id => {
  return (dispatch, getState) => {
    dispatch({ type: Types.RECIPE_DETAIL_START_SUBMIT });

    return dispatch(
      Request.post(
        {
          url: `/api/recipe-libraries/${id}/unpublish`,
        },
        true,
        (response, { dispatch }) => {
          const {
            data: { data },
          } = response;
          const { success = false } = data || {};

          if (success) {
            dispatch({
              type: Types.RECIPE_UNPUBLISH_SUCCESS,
              payload: { success },
            });
            toast(
              'The recipe has been unpublished and remove from all clients and meal plans that it was assigned to.',
              {
                className: 'custom--recipe-toast',
              },
            );
          }
        },
        error => {
          const { data, status } = get(error, 'response', {});
          const { message = '', errorCode = '' } = data || {};

          dispatch({ type: Types.RECIPE_DETAIL_END_SUBMIT, payload: { errorCode } });
          handleLostPermission(dispatch, getState, message, status);
        },
      ),
    );
  };
};

export const resetEditMode = status => {
  return dispatch => dispatch({ type: Types.RECIPE_DETAIL_RESET_EDIT_MODE, payload: { status } });
};

export const setUploadLoading = status => {
  return dispatch => dispatch({ type: Types.RECIPE_DETAIL_SET_LOADING, payload: { status } });
};

export const backToRecipes = (dispatch, getState) => {
  const {
    rootReducer: { recipes },
  } = getState() || {};
  const { filters } = recipes || {};

  dispatch(hideError(false));
  dispatch(
    changeQueryParams({
      ...filters,
    }),
  );
  dispatch(push('/home/recipes'));
  dispatch(setHeaderTabs(RECIPE_HEADER_TABS.ALL_RECIPE));
};

export const handleSubmitted = data => ({
  type: Types.RECIPE_DETAIL_IS_SUBMITTED,
  payload: { data },
});

export const getUnitType = () => {
  return Request.get({ url: '/api/meal-plan-preference/trainer' });
};

export const updateUnitType = data => {
  return Request.put({ url: '/api/meal-plan-preference', data });
};

export const setHeaderTabs = tab => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_SET_HEADER_TABS, payload: { tab } });
  };
};

export const getListOtherNutrient = () => {
  return dispatch => {
    return dispatch(
      Request.get(
        {
          url: '/api/recipe-libraries/recipe-nutritions',
        },
        false,
        response => {
          const data = get(response, 'data.data', []);
          if (!isEmpty(data)) {
            dispatch({ type: Types.RECIPE_GET_LIST_OTHER_NUTRIENT_SUCCESS, payload: { data } });
          }
        },
      ),
    );
  };
};

export const getListDietary = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_GET_LIST_DIETARY_REQUEST });
    return dispatch(
      Request.get(
        {
          url: '/api/recipe-libraries/dietaries',
        },
        false,
        response => {
          const data = get(response, 'data.data', []);
          if (!isEmpty(data)) {
            const convertData = data.map(item => ({ ...item, value: item.key }));
            dispatch({ type: Types.RECIPE_GET_LIST_DIETARY_SUCCESS, payload: { data: convertData } });
          }
        },
        () => {
          dispatch({ type: Types.RECIPE_GET_LIST_DIETARY_FAILED });
        },
      ),
    );
  };
};

export const updateDataIngredient = data => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_UPDATE_DATA_INGREDIENT, payload: { data } });
  };
};

export const getTotalRecipeInTeam = () => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_GET_TOTAL_RECIPE_IN_TEAM_REQUEST });

    return dispatch(
      Request.get(
        {
          url: '/api/recipe-libraries/total-in-team',
        },
        false,
        response => {
          const { total } = get(response, 'data.data', {});

          dispatch({ type: Types.RECIPE_GET_TOTAL_RECIPE_IN_TEAM_SUCCESS, payload: { total } });
        },
      ),
    );
  };
};

export const updateLoadingVideoInstruction = status => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_UPDATE_LOADING_VIDEO_INSTRUCTION, payload: { status } });
  };
};

export const changeAutoCalculated = status => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_TOGGLE_AUTO_CALCULATED, payload: { status } });
  };
};

export const updateIngredientData = data => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_UPDATE_INGREDIENT_DATA, payload: { data } });
  };
};

export const updateAutoCalculatedNutrition = data => {
  return dispatch => {
    dispatch({ type: Types.RECIPE_UPDATE_AUTO_CALCULATED_NUTRITION, payload: { data } });
  };
};
