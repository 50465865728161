import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import {
  updateGroupInfo,
  uploadGroupAvatar,
  getLatestMessages,
  startWaitingSystemMessage,
} from 'redux/inbox-new/actions';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';

const mapState = state => {
  return {
    cloudfrontList: state.cloudfrontList,
    viewTeammate: get(state, 'rootReducer.inboxNew.viewTeammate'),
    isGroupChatPermission: get(state, 'rootReducer.permission.group_chat', false),
  };
};

const mapDispatch = dispatch => ({
  updateGroupInfo: bindActionCreators(updateGroupInfo, dispatch),
  uploadGroupAvatar: bindActionCreators(uploadGroupAvatar, dispatch),
  getS3presignedURLFromLocalDatabase: bindActionCreators(getS3presignedURLFromLocalDatabase, dispatch),
  getLatestMessages: bindActionCreators(getLatestMessages, dispatch),
  startWaitingSystemMessage: bindActionCreators(startWaitingSystemMessage, dispatch),
});

export default connect(mapState, mapDispatch)(Component);
