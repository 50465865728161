import { Types } from './actions';

const INITITAL_STATE = {};

export default (state = INITITAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    default:
      return state;
  }
};
