import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import Select from 'react-select';

import * as ModalLayout from 'shared/Styles/ModalLayout';
import { Button } from 'shared/FormControl';
import { COUNTRIES as DEFAULT_COUNTRIES, GERMANY_SWITZERLAND } from 'constants/commonData';
import * as S from './style';

const COUNTRIES = process.env.REACT_APP_ENABLE_PAYMENT_GERMANY_SWITZERLAND
  ? [...DEFAULT_COUNTRIES, ...GERMANY_SWITZERLAND]
  : DEFAULT_COUNTRIES;

const SUPPORT_PAYMENT_MULTI_COUNTRIES = process.env.REACT_APP_SUPPORT_PAYMENT_MULTI_COUNTRIES;

const SelectOption = ({ innerProps, data, isFocused }) => {
  let className = 'select__option';

  if (isFocused) {
    className += ' select__option--is-focused';
  }

  const isCustom = ['de', 'ch'].includes(data.value);

  return (
    <S.CountryInformation {...innerProps} className={className}>
      {!isCustom ? <Avatar size="40" src={data.icon} round={2} /> : <S.ImageWrapper background={data.icon} />}
      <S.SingleLabel className="select__option__label">{data.label}</S.SingleLabel>
    </S.CountryInformation>
  );
};

const CountryModal = ({ onClose, onNextStep, onChange }) => {
  const [value, setValue] = useState(COUNTRIES[3]);
  // Default selected country
  useEffect(() => {
    onChange(value);
  }, []);

  const handleOnChange = item => {
    setValue(item);
    onChange(item);
  };
  return (
    <S.ModalWrapper open={true} closeOnDimmerClick={false} className="country-modal">
      <Modal.Header>
        <S.HeaderTitle>Choose Your Country</S.HeaderTitle>
        <S.HeaderMessage>
          Choose the country you are based in. You will need to verify personal details or details of a registered
          company.
        </S.HeaderMessage>
      </Modal.Header>
      <ModalLayout.Content>
        <Select
          options={COUNTRIES}
          components={{
            Option: SelectOption,
            SingleValue: SelectOption,
            IndicatorSeparator: null,
            ...(!SUPPORT_PAYMENT_MULTI_COUNTRIES && { DropdownIndicator: null }),
          }}
          isSearchable={false}
          onChange={handleOnChange}
          value={value}
          classNamePrefix="evfSelectBox"
          className="evfSelectBoxContainer"
          menuPosition="fixed"
          styles={{
            control: base => ({ ...base, height: 46, width: 548 }),
          }}
          {...(!SUPPORT_PAYMENT_MULTI_COUNTRIES && { menuIsOpen: false })}
        />
        <S.Note>*Payments may not be available for your country yet</S.Note>
        <S.Actions>
          <Button onClick={onClose}>Close</Button>
          <Button purple onClick={onNextStep}>
            Next Step
          </Button>
        </S.Actions>
      </ModalLayout.Content>
    </S.ModalWrapper>
  );
};

export default CountryModal;
