import styled from 'styled-components';
import Bg from 'assets/icons/line_chart_empty.svg';

export const LineEmpty = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  font-size: 11px;
  line-height: 100%;
  text-align: center;
  span {
    color: #202353;
    opacity: 0.3;
  }
`;

export const TooltipWrapper = styled.div`
  position: fixed;
  height: 50px;
  background: #2d2e2d;
  border-radius: 5px;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: #fff;
  font-size: 13px;
  line-height: 19.5px;
  justify-content: center;
  z-index: 1;
  padding: 0 22px;
`;

export const TooltipDate = styled.strong`
  font-weight: 700;
  padding-right: 3px;
`;

export const Arrow = styled.div`
  width: 9px;
  height: 9px;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  position: fixed;
  background: #2d2e2d;
`;
