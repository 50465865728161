import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import { CustomizeForm, FormatList, FormatItem, FormGroup, CustomizeFormFooter } from './style';
import Select from 'react-select';
import { SECTION_TYPE, SECTION_FORMAT_OPTIONS, SECTION_FORMAT_KEY, SECTION_FORMAT } from 'constants/commonData';
import { DropdownIndicator } from 'shared/Icons';
import { Button } from 'shared/FormControl';
import { toggleConfirmModal } from 'actions/modal';
import ConfirmModal from 'shared/ConfirmModal';

const SECTION_TYPE_OPTIONS = _.map(SECTION_TYPE, (text, key) => {
  return { key, label: text, value: key, notIncludedChart: key !== 'workout' };
});

function Component(props) {
  const { type, format } = props;
  const [selectedFormat, setSelectedFormat] = useState(format);
  const [selectedType, setSlectedType] = useState(type);

  useEffect(() => {
    if (format !== selectedFormat) {
      setSelectedFormat(format);
    }
  }, [format]);

  useEffect(() => {
    if (type !== selectedType) {
      setSelectedFormat(type);
    }
  }, [type]);

  const onSelectFormat = event => {
    setSelectedFormat(event.currentTarget.dataset.type);
  };

  const onSelectType = data => {
    setSlectedType(data.value);
  };

  const getValue = value => {
    return SECTION_TYPE_OPTIONS.find(o => o.value === value);
  };

  const onUpdate = () => {
    if (!selectedFormat || !selectedType) {
      return console.error(' value undefined', selectedFormat, selectedType);
    }

    if (
      selectedFormat !== format &&
      (selectedFormat === SECTION_FORMAT_KEY.AMRAP || selectedFormat === SECTION_FORMAT_KEY.TIMED)
    ) {
      const content =
        selectedFormat === SECTION_FORMAT_KEY.AMRAP
          ? 'Exercises in an AMRAP section are limited to 1 set per exercise, so any exercise with multiple sets will be split into separate exercises. Would you like to change the format to AMRAP?'
          : 'Exercises in a Timed section are limited to 1 set per exercise, so any exercise with multiple sets will be split into separate exercises. Would you like to change the format to Timed?';

      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title={`Change Section Format to ${SECTION_FORMAT[selectedFormat]}`}
          content={content}
          onConfirm={() => props.onUpdate({ format: selectedFormat, type: selectedType })}
          newStyle={true}
          noIcon
          confirmButtonTitle="I understand. Change Format"
          components={{
            ConfirmButton: (
              <Button purple style={{ padding: '5px 24px' }}>
                I understand. Change Format
              </Button>
            ),
          }}
          largeSpacing
        />,
      );
    } else {
      props.onUpdate({ format: selectedFormat, type: selectedType });
    }
  };

  return (
    <CustomizeForm>
      <FormGroup key="format">
        <label>Section Format</label>
        <FormatList>
          {_.map(SECTION_FORMAT_OPTIONS, obj => (
            <FormatItem
              key={obj.value}
              type={obj.value}
              data-type={obj.value}
              onClick={onSelectFormat}
              className={classnames({ selected: obj.value === selectedFormat })}
            >
              <div>{obj.text}</div>
            </FormatItem>
          ))}
        </FormatList>
      </FormGroup>
      <FormGroup key="tyoe">
        <label>Section Type</label>
        <Select
          options={SECTION_TYPE_OPTIONS}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          onChange={onSelectType}
          classNamePrefix="evfSelectBox"
          className="evfSelectBoxContainer"
          value={getValue(selectedType)}
          isSearchable={false}
          styles={{ control: base => ({ ...base, minHeight: 30, height: 30 }) }}
          menuPosition="fixed"
        />
      </FormGroup>
      <CustomizeFormFooter>
        <div className="cancel" onClick={props.onCancel}>
          Cancel
        </div>
        <Button purple onClick={onUpdate}>
          Update
        </Button>
      </CustomizeFormFooter>
    </CustomizeForm>
  );
}

const actions = { toggleConfirmModal };

export default connect(null, actions)(Component);
