import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperArrow = styled.div`
  display: block;
  margin: 0 14px;
  position: relative;
  height: 23px;
  width: 133px;
  flex: 0 0 133px;
  overflow: hidden;
`;

export const ContainerPlaySquare = styled.div`
  position: relative;
  height: 100%;
`;

export const WrapperPlaySquare = styled.div`
  height: calc(33px / 0.75);
  width: calc(33px / 0.75);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ContainerThumbnail = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: #000000;
  border-radius: 15px;
`;

export const WrapperThumbnail = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img,
  video {
    width: 100%;
    height: 100%;
  }
`;

export const WrapperVideo = styled.div`
  position: relative;
  height: 413px;
  width: 210px;
  flex: 0 0 210px;
  overflow: hidden;

  .hidden {
    display: none;
  }
`;

export const WrapperMediaPlayer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const WrapperLogin = styled.div`
  position: relative;
  height: 413px;
  width: 210px;
  flex: 0 0 210px;
  overflow: hidden;

  .custom-branding-color {
    .show-password-icon {
      stroke: ${props => props.selectedColor} !important;
    }
    .custom-login-btn {
      fill: ${props => props.selectedColor} !important;
    }
  }
`;
