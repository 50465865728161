import { connect } from 'react-redux';
import Exercise from './component';
import { updateEntity } from 'redux/model/actions';
import { ENTITY_NAME } from 'database/constants';

const mapStateToProps = (state, ownProps) => {
  return {
    model: state.rootReducer.model.getIn([ENTITY_NAME.Exercise, ownProps.exerciseId]),
    supersetEntities: state.rootReducer.model.get(ENTITY_NAME.Superset),
    draggingItemLeft: state.rootReducer.workoutBuilder.getIn(['draggingItemLeft']),
    droppedExercise: state.rootReducer.workoutBuilder.getIn(['droppedExercise']),
  };
};

const actionCreators = {
  updateEntity,
};

export default connect(mapStateToProps, actionCreators)(Exercise);
