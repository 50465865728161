import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import AnnouncementPopup from 'components/AnnouncementPopup';
import { getMessageDetail, addMessage, updateMessage, deleteMessage, copy } from 'redux/autoflow/autoMessage/actions';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import { uploadAttachment } from 'redux/autoflow/autoMessage/actions';
import { ANNOUNCEMENT_AUTO_MESSAGE, CDN_URL } from 'constants/commonData';
import { push } from 'connected-react-router';
import ConfirmModal from 'shared/ConfirmModal';
import * as S from './styles';

function Announcement(props) {
  const { date, match, user, originData, autoflow, autoMessage } = props;
  const [data, setData] = useState(null);
  const messageId = _.get(match, 'params.messageId');

  useEffect(() => {
    if (messageId) {
      props.getMessageDetail({ id: messageId, autoflow }).then(response => {
        let { data } = response.data;
        const { messages } = autoMessage;

        messages.map(item =>
          item.messages.map(el => {
            if (el._id === data._id) {
              data.attachment = el.attachment;
              data.attachment_thumbnail = el.attachment_thumbnail;
            }
          }),
        );
        setData(data);
      });
    } else {
      setData(originData || {});
    }
  }, []);

  const closePopup = () => {
    if (typeof props.onClose === 'function') {
      return props.onClose();
    }

    if (messageId) {
      props.push(`/home/autoflow/${autoflow}/auto-message`);
    } else {
      props.toggleModal(false);
    }
  };

  const onClose = data => {
    if (data && !_.isEqual(data.oldValue, data.newValue)) {
      props.toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          noBorder
          title="Discard Changes?"
          content={`Are you sure you want to go? Changes have not been saved yet.`}
          onConfirm={closePopup}
          confirmButtonTitle="Discard changes"
          hasCloseIcon
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
        />,
      );
    } else {
      closePopup();
    }
  };

  const onUploadFile = (file, fileId) => {
    const formData = new FormData();
    formData.append('attachment', file);
    return props
      .uploadAttachment(formData)
      .then(response => {
        const { data } = response.data;
        return { data, fileId };
      })
      .catch(() => {
        throw { fileId };
      });
  };

  const onSubmit = submitData => {
    const bodyData = {
      ...submitData,
      autoflow,
      timezone: user.timezone,
      type: ANNOUNCEMENT_AUTO_MESSAGE,
    };
    if (!messageId) {
      return props.addMessage(bodyData).then(() => props.toggleModal(false));
    }

    bodyData.messageId = messageId;
    return props.updateMessage(bodyData).then(() => props.toggleModal(false));
  };

  return data ? (
    <AnnouncementPopup
      originData={data}
      onClose={onClose}
      date={data.date || date}
      onSubmit={onSubmit}
      onCopy={() => {
        props.copy(data);
        props.push(`/home/autoflow/${autoflow}/auto-message`);
      }}
      onDelete={() => {
        props.toggleConfirmModal(
          true,
          <ConfirmModal
            title="Remove Message"
            content="Are you sure that you want to remove this message?"
            onConfirm={() =>
              props
                .deleteMessage({ autoflow, id: messageId }, moment(data.date || date).format('MM-DD-YYYY'))
                .then(() => {
                  props.push(`/home/autoflow/${autoflow}/auto-message`);
                })
            }
            headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
            newStyle={true}
          />,
        );
      }}
    />
  ) : null;
}

const mapState = state => {
  const { user, rootReducer } = state;
  const {
    common: { workingAutoflow },
    autoMessage,
  } = rootReducer.autoflow;

  return {
    user,
    autoflow: workingAutoflow ? workingAutoflow._id : '',
    autoMessage,
  };
};

const actionCreators = {
  toggleModal,
  toggleConfirmModal,
  uploadAttachment,
  updateMessage,
  addMessage,
  getMessageDetail,
  push,
  copy,
  deleteMessage,
};

export default connect(mapState, actionCreators)(Announcement);
