import _ from 'lodash';
import { ENTITY_NAME } from 'database/constants';
import { mongoObjectId } from 'utils/commonFunction';
import { initExerciseInstance } from 'helpers/exercise';

export const updateTrainingSetsAfterLinking = (model, supersetIds) => {
  const addedSets = [];
  const supersets = supersetIds.map(id => model.getIn([ENTITY_NAME.Superset, id]).toJS());
  const maxSize = _.maxBy(supersets, s => s.training_sets.length).training_sets.length;
  const newSupersets = supersets.map(superset => {
    const trainingSets = superset.training_sets.slice();
    const diff = Math.abs(trainingSets.length - maxSize);

    if (diff) {
      const lastSetId = trainingSets[trainingSets.length - 1];
      const lastSet = model.getIn([ENTITY_NAME.TrainingSet, lastSetId]).toJS();

      for (let i = 0; i < diff; i++) {
        const addedSet = { ..._.cloneDeep(lastSet), _id: mongoObjectId() };
        addedSets.push(addedSet);
        trainingSets.push(addedSet._id);
      }
    }

    return { ...superset, training_sets: trainingSets };
  });

  return { addedSets, newSupersets: newSupersets };
};

export const updateSetInsideSupersetAfterRemoving = (supersets, max) => {
  let removedSets = [];
  const updatedSupersets = [];
  _.forEach(supersets, s => {
    const trainingSets = s.training_sets.slice();

    if (trainingSets.length > max) {
      removedSets = [...removedSets, ...trainingSets.splice(max, trainingSets.length - max)];
    }

    updatedSupersets.push({ ...s, training_sets: trainingSets });
  });

  return { removedSets, updatedSupersets };
};

export const updateSetInsideSupersetAfterAdding = (supersets, max, model) => {
  let addedSets = [],
    updatedSupersets = [];

  _.forEach(supersets, s => {
    let trainingSets = s.training_sets.slice();

    if (trainingSets.length < max) {
      const lastSet = model.getIn([ENTITY_NAME.TrainingSet, _.last(trainingSets)]).toJS();
      const newSets = _.map(Array(max - trainingSets.length), () => {
        return { ..._.cloneDeep(lastSet), _id: mongoObjectId() };
      });
      trainingSets = trainingSets.concat(_.map(newSets, '_id'));
      addedSets = [...addedSets, ...newSets];
    }

    updatedSupersets.push({ ...s, training_sets: trainingSets });
  });

  return { addedSets, updatedSupersets };
};

export const generateNewSuperset = (setId, exercise) => {
  return {
    alternatives: [],
    each_side: false,
    training_sets: [setId],
    exercise: exercise || {},
    exercise_instance: exercise ? initExerciseInstance(exercise) : {},
    _id: mongoObjectId(),
    tempo: '0',
  };
};

export const generateNewExercise = supersetId => {
  return {
    _id: mongoObjectId(),
    supersets: [supersetId],
  };
};

export const breakdownSupersetToMultipleExercises = exercises => {
  let result = [];
  _.forEach(exercises, exercise => {
    _.forEach(exercise.supersets, superset => {
      const newExercises = _.map(superset.training_sets, set => ({
        _id: mongoObjectId(),
        supersets: [{ ...superset, _id: mongoObjectId(), training_sets: [set] }],
      }));
      result = [...result, ...newExercises];
    });
  });

  return result;
};

export const parseTrainingSets = (trainingSets, unit = null, isLog = false) => {
  let result = [];

  _.forEach(trainingSets, set => {
    const setCopy = Object.assign({}, set);

    _.forEach(setCopy, (data, key) => {
      if (_.isObject(data) && data.value !== undefined) {
        const setUnit = _.get(unit, key, '');

        if ((isLog && data.value === '-') || data.value === null) {
          data.value = '';
        }

        if (setUnit && typeof setUnit === 'object') {
          data.set_unit = setUnit._id || '';
        }
      }
    });

    result.push(setCopy);
  });

  return result;
};

export const toExercisesRequestData = (exercises, preferences, systemFields) => {
  const result = [];

  _.forEach(exercises, itm => {
    const supersets = [];
    itm.supersets.map(exercise => {
      const superset = {};
      superset.note = exercise.note;
      superset.exercise = _.get(exercise, 'exercise._id', '') || _.get(exercise, 'exercise_instance.exercise', '');

      let tempoValue = '';
      let tempoStr = exercise.tempo && exercise.tempo !== '0' ? exercise.tempo + '' : '';

      if (tempoStr) {
        let tempoArray = tempoStr.split('-');

        for (let i = 0; i < 4; i++) {
          let value = tempoArray[i];

          if (!value) {
            tempoArray[i] = 'X';
          }
        }

        tempoValue = tempoArray.join('-');
      } else {
        tempoValue = '0';
      }

      superset.tempo = tempoValue.toUpperCase();
      superset.each_side = exercise.each_side;
      superset.exercise_instance = exercise.exercise_instance;
      superset.alternatives = _.map(exercise.alternatives || [], '_id');
      const fields = _.map(superset.exercise_instance.fields, fId => _.find(systemFields, fObj => fObj._id === fId));
      const codes = _.filter(fields, f => !!f).map(f => f.unique_code);
      superset.training_sets = _.map(exercise.training_sets, set => {
        const newSet = _.omitBy(set, (value, key) => _.isObject(value) && !codes.includes(key));
        _.forEach(newSet, (value, key) => {
          if (_.isObject(value) && preferences[key]) {
            newSet[key].set_unit = preferences[key]._id;
          }
        });
  
        return newSet;
      });

      if (superset.exercise_instance) {
        superset.exercise_instance.exercise = superset.exercise;
      }

      supersets.push(superset);
    });

    result.push({ supersets });
  });

  return result;
};
