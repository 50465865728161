// Lib
import React, { useEffect } from 'react';
import { Prompt } from 'react-router';
import omit from 'lodash/omit';
import get from 'lodash/get';

// Shared
import DayDetailHeader from './parts/Header';
import LeftPanelDayDetail from './parts/LeftPanel';
import MainPanelDayDetail from './parts/MainPanel';
import RightPanelDayDetail from './parts/RightPanel';
import InfoModal from 'components/MealPlanDetail/Parts/Menu/InfoModal';

// Constants
import { LIST_DAY_OF_WEEK, MEAL_PLAN_STATUS } from 'components/MealPlanDetail/constants';
import { RECIPE_STATUS } from 'components/Recipes/constants';
import { SORT_BY } from './constants';
import { CDN_URL } from 'constants/commonData';
import { isTeamAdmin } from 'utils/commonFunction';

// Assets
import WarningIcon from 'assets/icons/warning_red_light.svg';

import * as S from './style';

const MealPlanDayDetail = props => {
  const {
    getMealPlanDayOfWeek,
    resetDetailMealPlanByDay,
    getRecipeLibraries,
    filters,
    match,
    getDetailMealPlan,
    resetDetailMealPlan,
    resetDetailMealPlanByWeek,
    detailMealPlanByWeek,
    updateMealPlanByDay,
    resetFilters,
    detailMealPlanByDay,
    toggleConfirmModal,
    push,
    updateMealPlanDayOfWeek,
    isChange,
    resetCurrentList,
    initTotal,
    detailMealPlan,
    cancelEditMealPlan,
    user,
    paymentOverdue,
    zapierOverdue,
    addOnsOverdue,
    paymentWhiteLabelOverdue,
  } = props;

  const { week_index = 0 } = detailMealPlanByWeek || {};
  const { hasGetToTal } = initTotal;

  const mealPlanId = get(match, 'params.mealPlanId', '');
  const weekId = get(match, 'params.weekId', '');
  const dayIndex = get(match, 'params.dayIndex', '');
  const params = new URLSearchParams(get(window, 'location.search'));
  const isEdit = params.get('mode');
  const dayOfWeek = LIST_DAY_OF_WEEK[dayIndex].dayOfWeek;
  const weekIndex = week_index + 1;

  const { is_verified_email, force_send_verification_email_at } = user || {};

  const isOverdue = (paymentOverdue || zapierOverdue || addOnsOverdue) && isTeamAdmin(user);

  const isWhiteLabelOverdue = paymentWhiteLabelOverdue;
  const isShowOverdue = !isWhiteLabelOverdue && isOverdue;
  const isVerifyEmail =
    !isWhiteLabelOverdue && !isShowOverdue && !is_verified_email && force_send_verification_email_at;

  useEffect(() => {
    if (mealPlanId && weekId && dayIndex && isEdit) {
      typeof getMealPlanDayOfWeek === 'function' && getMealPlanDayOfWeek(mealPlanId, weekId, dayIndex);
    }

    return () => {
      typeof resetDetailMealPlanByDay === 'function' && resetDetailMealPlanByDay();
    };
  }, []);

  useEffect(() => {
    getDetailMealPlan && getDetailMealPlan(mealPlanId, weekId);

    return () => {
      typeof resetDetailMealPlan === 'function' && resetDetailMealPlan();
      typeof resetDetailMealPlanByWeek === 'function' && resetDetailMealPlanByWeek();
      typeof resetFilters === 'function' && resetFilters();
      typeof resetCurrentList === 'function' && resetCurrentList();
    };
  }, []);

  useEffect(() => {
    updateMealPlanByDay &&
      updateMealPlanByDay(
        {
          headerTitle: `${dayOfWeek}, Week ${weekIndex}`,
        },
        false,
      );
  }, [weekIndex]);

  useEffect(() => {
    if (hasGetToTal) {
      typeof getRecipeLibraries === 'function' &&
        getRecipeLibraries({
          ...filters,
          status: JSON.stringify([RECIPE_STATUS.PUBLISH]),
          sorter: SORT_BY.MOST_RECENT,
          sort: -1,
        });
    }
  }, [filters, hasGetToTal]);

  const handleSaveBeforeExit = async pathname => {
    if (mealPlanId && weekId && dayIndex) {
      const mealConvert = get(detailMealPlanByDay, 'meal_group', []).map(meal => {
        const { isCreateNew = false, ...restMeal } = meal || {};
        const mealOmitKeys = isCreateNew ? ['_id', 'isCreateNew'] : ['isCreateNew'];
        const mealOmit = omit(restMeal, mealOmitKeys);

        const recipesConvert = get(mealOmit, 'recipes', []).map(({ _id, recipe }) => {
          const { _id: recipeItemId } = recipe || {};

          return { _id, recipe: recipeItemId };
        });

        return { ...mealOmit, recipes: recipesConvert };
      });

      const paramsDetailMealPlanByDay = { ...detailMealPlanByDay, meal_group: mealConvert };

      // Remove 'isCreateNew' and update store
      const mealUpdateStore = mealConvert.map(meal => omit(meal, ['isCreateNew']));

      updateMealPlanByDay && updateMealPlanByDay(mealUpdateStore, false);

      typeof updateMealPlanDayOfWeek === 'function' &&
        updateMealPlanDayOfWeek(
          mealPlanId,
          weekId,
          dayIndex,
          omit(paramsDetailMealPlanByDay, ['selected', 'headerTitle', 'isChange']),
          () => {
            push(pathname);
          },
        );
    }
  };

  const handleCloseAnyway = pathname => {
    updateMealPlanByDay && updateMealPlanByDay({ ...detailMealPlanByDay }, false);

    setTimeout(() => {
      push(pathname);
    }, 300);
  };

  const handleBlockedNavigation = nextLocation => {
    if (isChange) {
      const { pathname = '' } = nextLocation || {};
      if (pathname === get(props, 'location.pathname', '')) {
        return;
      }
      toggleConfirmModal &&
        toggleConfirmModal(
          true,
          <S.CustomConfirmModal
            title="Save before exiting?"
            content="You have made changes that have not been saved yet. Would you like to save changes before leaving?"
            onConfirm={() => handleSaveBeforeExit(pathname)}
            confirmButtonTitle="Save"
            headerIcon={`${CDN_URL}/images/warning_purple_Icon.svg`}
            cancelButtonTitle="Exit without saving"
            className="save-before-exit-modal"
            onDeny={() => handleCloseAnyway(pathname)}
            noBorder
            hasCloseIcon
            isPressEsc
            onClose={() => toggleConfirmModal(false)}
            isCloseOnDimmer={false}
          />,
        );
      return false;
    }
    if (MEAL_PLAN_STATUS.PUBLISH && get(detailMealPlan, 'is_edit_mode', false)) {
      if (nextLocation) {
        const { pathname } = nextLocation;
        const isInMealPlanDetail = pathname.includes('/home/meal-plans') && pathname.includes('/weeks/');

        if (isInMealPlanDetail) {
          return true;
        }
      }

      toggleConfirmModal(
        true,
        <InfoModal
          title="Cancel and Discard Changes"
          content="This edited version of the Meal Plan will be discarded. The Meal Plan will reset to its current live version. Would you like to continue?"
          onConfirm={() => {
            handleCancelEditMealPlan(() => {
              if (nextLocation) {
                props.push(nextLocation.pathname);
              }
            });
          }}
          confirmButtonTitle="Discard Changes"
          headerIcon={WarningIcon}
          className="cancel-meal-plan"
          noBorder
          hasCloseIcon
          isPressEsc
          isCloseOnDimmer={false}
        />,
      );
      if (nextLocation) {
        return false;
      }
    }
  };

  const handleCancelEditMealPlan = callback => {
    cancelEditMealPlan(mealPlanId, callback);
  };

  return (
    <S.Wrapper>
      <Prompt
        when={
          isChange ||
          (get(detailMealPlan, 'status', '') === MEAL_PLAN_STATUS.PUBLISH && get(detailMealPlan, 'is_edit_mode', false))
        }
        message={handleBlockedNavigation}
      />
      <DayDetailHeader {...props} />
      <S.Container isVerifyEmail={isWhiteLabelOverdue || isShowOverdue || isVerifyEmail}>
        <LeftPanelDayDetail {...props} />
        <MainPanelDayDetail {...props} />
        <RightPanelDayDetail {...props} />
      </S.Container>
    </S.Wrapper>
  );
};

export default MealPlanDayDetail;
