import { convertDurationToStringForExerciseHistory } from 'helpers/time';
import { roundUp } from 'utils/commonFunction';

export const chartDataSets = {
  fill: true,
  borderColor: '#49CA8D',
  borderWidth: 2,
  pointBackgroundColor: '#49CA8D',
  pointHoverBackgroundColor: '#48c98c',
  pointBorderWidth: 1,
  pointBorderColor: '#fff',
  pointHoverBorderColor: '#ffffff',
  pointHoverBorderWidth: 2,
  pointRadius: 4,
  spanGaps: true,
  pointHoverRadius: 6,
  pointHitRadius: 6,
  cubicInterpolationMode: 'default',
};

export const chartOptions = (labelsTooltip, unitType, timeRange, unit_title, convertUnit = 1) => ({
  tooltips: {
    // Disable the on-canvas tooltip
    enabled: false,
    callbacks: {
      title: function (tooltipItem) {
        const isDuration = unitType === 'duration';
        let tooltipItemValue = isDuration ? Number(tooltipItem[0].yLabel) : Number(tooltipItem[0].yLabel) * convertUnit;
        let tooltipValue = '';

        if (isDuration) {
          tooltipValue = convertDurationToStringForExerciseHistory(tooltipItemValue).value;
        } else {
          tooltipValue = roundUp(tooltipItemValue, 1);
        }

        return labelsTooltip[tooltipItem[0].index] + '' + tooltipValue + ' ' + unit_title;
      },
      label: function () {
        return null;
      },
    },
    custom: function (tooltipModel) {
      // Tooltip Element
      var tooltipEl = document.getElementById('chartjs-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<div></div>';
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      // Set Text
      if (tooltipModel.body) {
        var titleLines = tooltipModel.title || [];
        var innerHtml = '';

        titleLines.forEach(function (title) {
          innerHtml += '<span>' + title + '</span>';
        });

        var tableRoot = tooltipEl.querySelector('div');
        tableRoot.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      var position = this._chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipModel.width / 2 + 'px';
      tooltipEl.style.top = -7 + position.top + window.pageYOffset + tooltipModel.caretY - tooltipModel.height + 'px';
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      tooltipEl.style.fontSize = '12px';
      tooltipEl.style.fontWeight = '400';
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
      tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
      tooltipEl.style.pointerEvents = 'none';
    },
  },
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: '#ccc',
          lineWidth: 1,
          drawOnChartArea: false,
          drawTicks: true,
          tickMarkLength: 5,
          drawBorder: false,
        },
        ticks: {
          callback: function (label, index, labels) {
            let timeRangeNumber;
            switch (timeRange) {
              case '2m':
                timeRangeNumber = 2;
                break;
              case '6m':
                timeRangeNumber = 6;
                break;
              case '1y':
                timeRangeNumber = 12;
                break;
              default:
                timeRangeNumber = 1;
                break;
            }

            if (timeRangeNumber && (index % (timeRangeNumber * 5) === 0 || index === 0)) {
              return label;
            } else if (index === labels.length - 1) {
              if ((timeRangeNumber === 1 && index % 5 === 4) || (timeRangeNumber === 2 && index % 10 === 9)) {
                return label;
              }
            }
            return null;
          },
          autoSkip: false,
          maxRotation: 0,
          padding: 5,
          fontColor: 'rgb(106, 121, 136)',
          fontSize: 11,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: '#e5eced',
          lineWidth: 1,
          drawTicks: true,
          tickMarkLength: 0,
          drawBorder: false,
          zeroLineWidth: 0.3,
        },
        ticks: {
          beginAtZero: false,
          padding: 20,
          fontColor: 'rgb(106, 121, 136)',
          fontSize: 11,
        },
        scaleLabel: {
          display: false,
          labelString: '',
        },
      },
    ],
  },
});
