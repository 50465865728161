import React from 'react';
import { connect } from 'react-redux';

import ConfirmModal from 'shared/ConfirmModal/component';
import WarningViolet from 'assets/icons/warning_violet.svg';
import * as S from './style';

const CancelSubscriptionWithProduct = ({ onConfirm, dispatch }) => {
  const handleConfirm = () => {
    onConfirm && onConfirm(false);
  };
  const handleRemove = () => {
    onConfirm && onConfirm(true);
  };

  return (
    <ConfirmModal
      isConfirmModalOpen={true}
      title="A Sequence is connected to purchase"
      className="cancel-with-product"
      content={
        <S.Wrapper>
          <S.Message>
            The purchase you are cancelling included a Sequence that was automatically assigned to the client.
          </S.Message>
          <S.Message>
            With the cancellation, would you like to remove the Sequence and its assets from the client? This will
            permanently revoke access.
          </S.Message>
        </S.Wrapper>
      }
      onConfirm={handleConfirm}
      confirmButtonTitle="Keep Sequence assigned"
      cancelButtonTitle="Remove Sequence"
      newStyle
      hasCloseIcon
      headerIcon={WarningViolet}
      onDeny={handleRemove}
      dispatch={dispatch}
    />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};
export default connect(null, mapDispatchToProps)(CancelSubscriptionWithProduct);
