import React from 'react';
import _ from 'lodash';
import { Mixpanel } from 'utils/mixplanel';
import Request from 'configs/request';
import { toast } from 'react-toastify';
import { toggleModal } from 'actions/modal';
import { MultiplePasteMessage } from 'shared/Notifications';

export const Types = {
  AUTOFLOW_INTERVAL_SUCCESS_GET_TASKS: 'AUTOFLOW_INTERVAL_SUCCESS_GET_TASKS',
  AUTOFLOW_INTERVAL_COPY_TASK: 'AUTOFLOW_INTERVAL_COPY_TASK',
  AUTOFLOW_INTERVAL_TASK_CLEAR_COPY_ITEM: 'AUTOFLOW_INTERVAL_TASK_CLEAR_COPY_ITEM',
  AUTOFLOW_INTERVAL_ARRANGE_TASK: 'AUTOFLOW_INTERVAL_ARRANGE_TASK',
  AUTOFLOW_INTERVAL_TASK_RESET_DATA: 'AUTOFLOW_INTERVAL_TASK_RESET_DATA',
  AUTOFLOW_INTERVAL_MOVE_TASK_REQUEST: 'AUTOFLOW_INTERVAL_MOVE_TASK_REQUEST',
  AUTOFLOW_INTERVAL_MOVE_TASK_SUCCESS: 'AUTOFLOW_INTERVAL_MOVE_TASK_SUCCESS',
  AUTOFLOW_INTERVAL_MOVE_TASK: 'AUTOFLOW_INTERVAL_MOVE_TASK',
  AUTOFLOW_INTERVAL_MOVE_TASK_FAILED: 'AUTOFLOW_INTERVAL_MOVE_TASK_FAILED',
  AUTOFLOW_INTERVAL_TASK_RESET_CALENDAR_DATA: 'AUTOFLOW_INTERVAL_TASK_RESET_CALENDAR_DATA',
  AUTOFLOW_INTERVAL_TASK_UPDATE_MULTIPLE_DAYS: 'AUTOFLOW_INTERVAL_TASK_UPDATE_MULTIPLE_DAYS',
  AUTOFLOW_INTERVAL_REMOVE_SETTING_REPEAT: 'AUTOFLOW_INTERVAL_REMOVE_SETTING_REPEAT',
  AUTOFLOW_INTERVAL_REMOVE_TASK_REPEAT: 'AUTOFLOW_INTERVAL_REMOVE_TASK_REPEAT',
  AUTOFLOW_INTERVAL_CREATED_TASK_SUCCESS: 'AUTOFLOW_INTERVAL_CREATED_TASK_SUCCESS',
  AUTOFLOW_INTERVAL_REMOVED_TASK_SUCCESS: 'AUTOFLOW_INTERVAL_REMOVED_TASK_SUCCESS',
};

export const pasteTask = (params, multiPaste) => {
  Mixpanel.track('autoflow_interval_task_calendar_screen_paste_task');
  return dispatch => {
    if (!multiPaste) {
      dispatch(resetCopyItem());
    }

    return dispatch(
      Request.post({ url: '/api/autoflow-interval-task/copy-task', data: params }, true, response => {
        const { data } = response.data;
        dispatch(updateTaskMultipleDays([{ ...data, weekIndex: params.weekIndex }]));
      }),
    );
  };
};

export const resetIntervalTaskCalendarData = () => ({ type: Types.AUTOFLOW_INTERVAL_TASK_RESET_CALENDAR_DATA });

export const resetCopyItem = () => ({ type: Types.AUTOFLOW_INTERVAL_TASK_CLEAR_COPY_ITEM });

export const createdTaskAutoflowIntervalSuccess = data => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_CREATED_TASK_SUCCESS,
      payload: { data },
    });
  };
};

export const removedTaskAutoflowIntervalSuccess = data => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_REMOVED_TASK_SUCCESS,
      payload: { data },
    });
  };
};

export const copyTask = data => {
  Mixpanel.track('autoflow_interval_task_calendar_screen_copy_task');
  toast(<MultiplePasteMessage title="tasks" />);
  return { type: Types.AUTOFLOW_INTERVAL_COPY_TASK, payload: { data } };
};

export const getDetail = params => {
  const { autoflowId, taskId } = params;
  const END_POINT = `/api/autoflow-interval-task/v2/get-task-detail?autoflowId=${autoflowId}&taskId=${taskId}`;
  return Request.get({ url: END_POINT }, true);
};

export const deleteTask = params => {
  Mixpanel.track('autoflow_interval_task_calendar_screen_delete_task');
  return Request.post(
    { url: '/api/autoflow-interval-task/remove-task', data: params },
    true,
    (response, { dispatch }) => {
      params.repeatId &&
        !params.is_only_this_task &&
        dispatch({
          type: Types.AUTOFLOW_INTERVAL_REMOVE_TASK_REPEAT,
          payload: { data: { ...params, ...response.data.data } },
        });
      dispatch(updateTaskMultipleDays([{ ...response.data.data, weekIndex: params.weekIndex }]));
    },
  );
};

export const arrangeTask = params => {
  return dispatch => {
    dispatch({
      type: Types.AUTOFLOW_INTERVAL_ARRANGE_TASK,
      payload: { data: params },
    });
    return dispatch(
      Request.post({ url: '/api/autoflow-interval-task/arrange-task', data: params }, true, response => {
        const { data } = response.data;
        const daysData = [{ ...data, weekIndex: params.weekIndex }];
        dispatch(updateTaskMultipleDays(daysData));
      }),
    );
  };
};

export const moveTask = params => {
  Mixpanel.track('autoflow_interval_task_calendar_screen_move_task');
  return dispatch => {
    dispatch({ type: Types.AUTOFLOW_INTERVAL_MOVE_TASK_REQUEST });

    dispatch({
      type: Types.AUTOFLOW_INTERVAL_MOVE_TASK,
      payload: { data: params },
    });
    return dispatch(
      Request.post(
        { url: '/api/autoflow-interval-task/move-task', data: params },
        true,
        response => {
          const { data } = response.data;
          dispatch(updateTaskMultipleDays(data));
          dispatch({ type: Types.AUTOFLOW_INTERVAL_MOVE_TASK_SUCCESS });
        },
        () => {
          dispatch({ type: Types.AUTOFLOW_INTERVAL_MOVE_TASK_FAILED });
        },
      ),
    );
  };
};

export const getListTasks = () => {
  return (dispatch, getState) => {
    const currentState = getState();
    const {
      autoflow: {
        common: { workingAutoflow },
      },
      autoflowInterval,
    } = currentState.rootReducer;

    const startWeek = autoflowInterval.common.get('startWeek');
    const viewMode = autoflowInterval.common.get('viewMode');

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_ITEM' });
    }

    const params = {
      autoflowId: workingAutoflow._id,
      startWeek: startWeek,
      endWeek: startWeek + viewMode - 1,
    };

    return dispatch(
      Request.get({ url: '/api/autoflow-interval-task/get-autoflow-calendar-by-weeks', params }, true, response => {
        const { data } = response.data;
        dispatch({ type: Types.AUTOFLOW_INTERVAL_SUCCESS_GET_TASKS, payload: { data } });
      }),
    );
  };
};

export const updateTaskMultipleDays = data => {
  return { type: Types.AUTOFLOW_INTERVAL_TASK_UPDATE_MULTIPLE_DAYS, payload: { data } };
};

export const submitTask = (params, useFormData) => {
  return (dispatch, getState) => {
    let requestBody, taskId, weekIndex;
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { workingAutoflow } = autoflow.common;

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_INTERVAL_ITEM' });
    }

    if (useFormData) {
      requestBody = params;
      requestBody.append('autoflowId', workingAutoflow._id);
      requestBody.delete('client');
      taskId = requestBody.get('taskId');
      weekIndex = requestBody.get('weekIndex');
    } else {
      requestBody = Object.assign({}, params);
      requestBody.autoflowId = workingAutoflow._id;
      delete requestBody.client;
      taskId = requestBody.taskId;
      weekIndex = requestBody.weekIndex;
    }

    return dispatch(
      Request.dynamicConfigs(
        {
          url: `/api/autoflow-interval-task/v2/${taskId ? 'update-task' : 'add-new-task'}`,
          method: taskId ? 'put' : 'post',
          data: requestBody,
        },
        true,
        response => {
          if (taskId) {
            Mixpanel.track('autoflow_interval_task_calendar_screen_update_task');
          } else {
            Mixpanel.track('autoflow_interval_task_calendar_screen_add_new_task');
          }
          const daysData = taskId ? response.data.data : [{ ...response.data.data, weekIndex }];
          taskId && !params.should_refresh && dispatch(getListTasks());
          dispatch(updateTaskMultipleDays(daysData));
        },
      ),
    );
  };
};

export const removeSettingRepeat = data => {
  const END_POINT = '/api/autoflow-interval-task/remove-repeat-task/';
  return dispatch => {
    return dispatch(
      Request.delete({ url: END_POINT, data }, true, () => {
        dispatch({ type: Types.AUTOFLOW_INTERVAL_REMOVE_SETTING_REPEAT, payload: { data } });
        dispatch(getListTasks());
      }),
    );
  };
};

export const submitTaskFromLibrary = (params, useFormData) => {
  return (dispatch, getState) => {
    let requestBody, taskId, weekIndex;
    const currentState = getState();
    const { autoflow } = currentState.rootReducer;
    const { workingAutoflow } = autoflow.common;

    if (!workingAutoflow) {
      return dispatch({ type: 'UNDEFINED_AUTOFLOW_INTERVAL_ITEM' });
    }

    if (useFormData) {
      requestBody = params;
      requestBody.append('autoflowId', workingAutoflow._id);
      requestBody.delete('client');
      taskId = requestBody.get('taskId');
      weekIndex = requestBody.get('weekIndex');
    } else {
      requestBody = Object.assign({}, params);
      requestBody.autoflowId = workingAutoflow._id;
      delete requestBody.client;
      taskId = requestBody.taskId;
      weekIndex = requestBody.weekIndex;
    }

    return dispatch(
      Request.dynamicConfigs(
        {
          url: `/api/autoflow-interval-task/add-new-task-from-library`,
          method: 'post',
          data: requestBody,
        },
        true,
        response => {
          const daysData = response.data.data;

          dispatch(updateTaskMultipleDays(daysData));
          dispatch(toggleModal(false));
        },
      ),
    );
  };
};
