import React from 'react';
import * as S from './style';

import UpgradeIcon from 'assets/icons/upgrade-icon.svg';
import UpgradeTransparentIcon from 'assets/icons/upgrade-icon-transparent.svg';

function UpgradePathIcon({
  imageSrc,
  imageTitle,
  imageBackgroundColor,
  isImageBackground,
  onImageEvent,
  push,
  tooltipBackground,
  tooltipTextColor,
  tooltipId,
  maxWidthContent,
  buttonProps,
  ...props
}) {
  const handleImageSrc = () => {
    if (isImageBackground) return UpgradeTransparentIcon;
    if (imageSrc) return imageSrc;
    return UpgradeIcon;
  };

  const handleClickIcon = event => {
    event.stopPropagation();
    onImageEvent && onImageEvent(event);
  };

  return (
    <>
      <S.Images
        {...props}
        {...buttonProps}
        src={handleImageSrc()}
        data-for={tooltipId}
        data-tip={imageTitle}
        onClick={handleClickIcon}
        style={{ backgroundColor: isImageBackground && `${imageBackgroundColor}` }}
        className={isImageBackground && 'color-background'}
      ></S.Images>
      <S.Tooltip
        id={tooltipId}
        effect="solid"
        trigger="click"
        place={'top'}
        delayShow={50}
        tooltipBackground={tooltipBackground}
        tooltipTextColor={tooltipTextColor}
        maxWidthContent={maxWidthContent}
      />
    </>
  );
}

export default UpgradePathIcon;
