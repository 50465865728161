import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';
import { Phone, fadeIn, Preview } from '../style';
import IphoneLayout from 'assets/icons/iphone-screen-smaller-layout.svg';
import AndroidLayout from 'assets/icons/android-screen-layout.svg';

export const PlaceholderText = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #a8a8a8;
`;

const deviceWidth = 194;
const maxWidth = (deviceWidth / 100) * 75;
const maxHeight = (deviceWidth / 100) * 40;

export const Logo = styled.img`
  max-height: ${maxHeight}px;
  max-width: ${maxWidth}px;
  ${props => {
    if (props.width && props.height) {
      return `
        width: ${props.width}px;
        height: ${props.height}px;
      `;
    }
  }}
  object-fit: scale-down;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const SplashLogoPlaceholder = styled.div`
  background: #fafafa;
  border: 1px dashed #dedede;
  box-sizing: border-box;
  border-radius: 5px;
  width: 98.5px;
  height: 98.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const SplashLogoPlaceholderContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${props =>
    props.logoSize &&
    css`
      width: auto;
      height: auto;
      max-height: 70px;
      max-width: 180px;
    `}

  .hello-text {
    margin-top: 15px;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: ${props =>
      !props.welcomeTextColor ? '#1e0a3c' : props.welcomeTextColor === 'none' ? 'transparent' : props.welcomeTextColor};
    width: 110px;
    height: 30px;
  }
`;

export const ThemeContent = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  :before,
  :after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
  }

  :before {
    top: 0;
    background-color: ${props => (props.default ? props.primaryColor : props.secondaryColor)};
  }

  :after {
    bottom: 0;
    background-color: ${props => (props.default ? props.secondaryColor : props.primaryColor)};
  }
`;

export const ThemeItem = styled.div`
  background: linear-gradient(180deg, #ffffff 0%, #f7f8fc 100%);
  border: 1px solid #dadfea;
  box-sizing: border-box;
  /* margin-right: 12px;
  margin-bottom: 12px; */
  padding: 10px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  :hover {
    border-color: #7470ef;
    cursor: pointer;
  }

  ${props =>
    props.active &&
    css`
      border-color: #7470ef;
    `}

  ${props =>
    props.addNew &&
    css`
      width: 72px;
      height: 72px;

      .add-new-theme-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        display: none;
      }

      :hover {
        border-color: transparent;

        .add-new-theme-hover {
          display: block;
        }
      }
    `}

  .__react_component_tooltip.app-tooltip-white {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #323c47 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-color: #e8e8e8;
    position: absolute !important;
    top: unset !important;
    left: 50% !important;
    bottom: 60px !important;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;

export const ThemesContainer = styled.div`
  /* display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(72px, 72px));
  grid-gap: 12px;
`;

export const PhoneContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  justify-content: center;
  align-items: center;
  transform: scale(1.02);
  border-radius: 15px;
`;

export const PhoneContainer = styled.div`
  padding: 6px 8px;
  position: relative;
  height: 413px;
  width: 210px;
  flex: 0 0 210px;
  overflow: hidden;

  :before {
    content: '';
    background: transparent url(${CDN_URL}/images/iphone_layout_dark.svg) no-repeat;
    background-position: top left;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
`;

export const IPhoneAppIconContainer = styled.div`
  padding: 8px 10px 9px;
  margin-right: 40px;
  position: relative;
  width: 311px;
  height: 413px;
  background: transparent url(${IphoneLayout}) no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;

  :after {
    content: '';
    width: 100%;
    height: 159px;
    position: absolute;
    left: 50%;
    bottom: -20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 61.98%);
    transform: translateX(-50%);
    z-index: 0;
  }
`;

export const AndroidAppIconContainer = styled.div`
  padding: 8px 10px 9px;
  position: relative;
  width: 311px;
  height: 413px;
  background: transparent url(${AndroidLayout}) no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;

  :after {
    content: '';
    width: 100%;
    height: 159px;
    position: absolute;
    left: 50%;
    bottom: -20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 61.98%);
    transform: translateX(-50%);
    z-index: 0;
  }
`;

export const PreviewContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${PhoneContainer} {
    margin-right: 25px;

    :last-child {
      margin-right: 0;
    }
  }
`;

export const PreviewWrapper = styled(Preview)`
  background-color: #fff;
  padding: 0 20px;
  @media only screen and (max-height: 780px) {
    transform: scale(0.75);
  }
`;

export const TodayScreenIconContainer = styled.div`
  padding: 8px 10px 9px;
  position: relative;
  width: 400px;
  height: 500px;
  overflow: hidden;

  @media only screen and (max-height: 780px) {
    transform: scale(0.9);
  }

  :before {
    content: '';
    background: transparent url(${CDN_URL}/images/iphone_layout_dark.svg) no-repeat;
    background-position: top left;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  :after {
    content: '';
    width: 100%;
    height: 234px;
    position: absolute;
    left: 50%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    transform: translateX(-50%);
    z-index: 2;
  }
`;
