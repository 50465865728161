// libs
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

// utils
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';

// assets
import { ReactComponent as DragIcon } from 'assets/icons/drag-icon-2.svg';

// styles
import * as S from './style';

const DragItemRecipe = (props, ref) => {
  const {
    attributes,
    cloudfrontList,
    isDragging,
    isLoadingWeek,
    isLogged,
    isOver,
    isSortableDragging,
    listeners,
    recipe,
    style,
  } = props;
  const { _id, cover_image, cover_image_thumbnail } = recipe || {};

  const coverImage = convertS3UrlToCloudFrontUrl(cover_image, cloudfrontList, true);
  const coverImageThumbnail = convertS3UrlToCloudFrontUrl(cover_image_thumbnail, cloudfrontList, true);

  const inlineStyles = {
    border: !!isDragging && 'none',
    ...style,
  };

  return (
    <S.RecipeItem
      ref={ref}
      data-tip
      data-for={_id}
      isLogged={isLogged}
      style={inlineStyles}
      isDragging={!!isDragging}
      isSortableDragging={!!isSortableDragging}
      className={classNames({ 'is-over': isOver })}
      {...props}
    >
      <div className="recipe-background" style={{ backgroundImage: `url(${coverImageThumbnail || coverImage})` }} />
      {!isLogged && (
        <div
          className="drag-container"
          style={{
            cursor: isLoadingWeek ? 'not-allowed' : !!isDragging ? 'grabbing' : 'grab',
          }}
          {...(!isLoadingWeek ? { ...attributes, ...listeners } : {})}
        >
          <DragIcon className="recipe-drag-icon" />
        </div>
      )}
      {isLogged && (
        <ReactTooltip id={_id} className="app-tooltip recipe-item-tooltip" effect="solid" place="top">
          <p>Meal logged</p>
        </ReactTooltip>
      )}
    </S.RecipeItem>
  );
};

export default forwardRef(DragItemRecipe);
