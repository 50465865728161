import React from 'react';
import PropTypes from 'prop-types';
import ProgressRing from 'shared/ProgressRing';
import { axiosInstance } from 'configs/request';
import axios from 'axios';
import * as S from './style';

const CancelToken = axios.CancelToken;

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { progress: 0 };
    this.uploadTask = null;
    this.uploadError = false;
    this.canceled = false;
  }

  componentDidMount() {
    if (this.props.configs) {
      this.uploadFile();
    }
  }

  componentWillUnmount() {
    const { disableAutoCancel, removeCancelUpload = false } = this.props;
    if (!disableAutoCancel && !this.uploadError && this.state.progress < 100 && !this.canceled && !removeCancelUpload) {
      this.onCancel();
    }
  }

  uploadFile = () => {
    const { configs } = this.props;

    axiosInstance({
      ...configs,
      onUploadProgress: progressData => {
        const { loaded, total } = progressData;
        const newProgress = Math.floor((loaded / total) * 100);
        this.setState({ progress: newProgress });
      },
      cancelToken: new CancelToken(
        function executor(c) {
          // An executor function receives a cancel function as a parameter
          this.uploadTask = c;
        }.bind(this),
      ),
    })
      .then(response => {
        this.props.onSuccess(response);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          error.message = 'Canceled';
          this.uploadTask = null;
          this.canceled = true;
          this.props.onCancel(error);
        } else {
          this.props.onError(error);
          this.uploadTask = null;
          this.uploadError = true;
        }
      });
  };

  onCancel = e => {
    if (typeof this.uploadTask === 'function') {
      this.uploadTask();
    } else {
      this.props.onCancel({ message: 'Canceled' });
    }
  };

  handleCancelUpload = () => {
    this.onCancel();
    this.props.onCancel() && this.props.onCancel();
  };

  render() {
    if (!this.props.configs) {
      return null;
    }

    const {
      progressRadius,
      progressBarWidth,
      progressBarBackground,
      smallCancelButton,
      middleCancelButton,
      children,
    } = this.props;

    return (
      children || (
        <S.Wrapper className="evf-file-upload">
          <S.Container>
            <ProgressRing
              radius={progressRadius || 32}
              progressBarWidth={progressBarWidth || 3}
              progressBarBackground={progressBarBackground}
              progress={this.state.progress}
            />
            <S.CancelButton
              onClick={this.handleCancelUpload}
              className="file-upload__cancel-button"
              small={smallCancelButton}
              middle={middleCancelButton}
            />
          </S.Container>
        </S.Wrapper>
      )
    );
  }
}

FileUpload.propTypes = {
  configs: PropTypes.shape({
    url: PropTypes.string,
    data: PropTypes.object,
    method: PropTypes.string,
  }),
};

export default FileUpload;
