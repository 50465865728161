import { ACCEPT_IMAGES, ACCEPT_VIDEOS, ACCEPT_MPEG4_VIDEOS, ACCEPTED_DOCUMENTS } from 'constants/commonData';

export const ATTACHMENT_TYPES = {
  VIDEO: 1,
  AUDIO: 2,
  IMAGE: 3,
  DOCUMENT: 4,
  LINK: 5,
};

export const VALIDATIONS = {
  VIDEO: {
    maxSize: 50000000,
    extensions: ['avi', 'flv', 'mp4', 'mov', '3gp', 'm4v', 'm4a', 'mp3'],
    accepts: `${ACCEPT_VIDEOS}, ${ACCEPT_MPEG4_VIDEOS}, .mp3`,
    errorMessage: 'Video size limit is 50MB. Please compress your video first or attach a video link instead.',
    type: ATTACHMENT_TYPES.VIDEO,
  },
  IMAGE: {
    maxSize: 25000000,
    extensions: ['png', 'jpeg', 'jpg'],
    accepts: ACCEPT_IMAGES,
    errorMessage: 'Image size limit is 25MB.',
    type: ATTACHMENT_TYPES.IMAGE,
  },
  DOCUMENT: {
    maxSize: 25000000,
    extensions: ['xls', 'doc', 'pdf', 'xlsx', 'docx'],
    accepts: ACCEPTED_DOCUMENTS,
    errorMessage: 'Document size limit is 25MB.',
    type: ATTACHMENT_TYPES.DOCUMENT,
  },
  AUDIO: {
    maxSize: 50000000,
    extensions: ['m4a', 'mp3'],
    accepts: ACCEPTED_DOCUMENTS,
    errorMessage: 'Audio size limit is 50MB.',
    type: ATTACHMENT_TYPES.AUDIO,
  },
};

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  LINK: 'link',
};

export const DEFAULT_MEDIA_LINK_DATA = {
  name: '',
  type: MEDIA_TYPES.LINK,
  url: '',
  thumbnail_url: '',
  invalidLink: false,
};
