import styled from 'styled-components';

export const TeamWrapper = styled.div`
  width: 122px;
  max-height: 36px;
  .evfSelectBoxContainer {
    .evfSelectBox__menu {
      width: 290px;
      margin-top: 8px;
      box-shadow: 0px 2px 3px rgba(176, 188, 207, 0.25);
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-width: 1px 0px 0px 1px;
        border-top-style: solid;
        border-left-style: solid;
        border-top-color: #d4d7d9;
        border-left-color: #d4d7d9;
        border-image: initial;
        border-right-style: initial;
        border-right-color: initial;
        border-bottom-style: initial;
        border-bottom-color: initial;
        transform: rotate(45deg);
        position: absolute;
        left: 40px;
        top: -4px;
        background: hsl(0, 0%, 100%);
      }
    }
    .evfSelectBox__menu-list,
    .evfSelectBox__group {
      padding-top: 0;
      padding-bottom: 0;
      max-height: 325px;
    }
    .evfSelectBox__control {
      max-width: 230px;
      min-height: 36px;
      height: 36px;
      background: #eeeeee;
      border: none;
      &:hover,
      &.evfSelectBox__control--menu-is-open {
        border: none !important;
        background: #eeeeee;
      }
      .evfSelectBox__value-container {
        padding-left: 12px;
        display: block;
        height: 36px;
        padding-top: 8px;
        input {
          position: absolute;
        }
      }
      i.icon {
        font-size: 1rem;
        margin-right: 13px;
        margin-bottom: 2px;
      }
    }

    .evfSelectBox__control--is-focused {
      border: none;
    }
  }
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 0;
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
  padding: 8px 15px;
  &:hover {
    background-color: #efeeff;
  }
`;

export const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  color: #272b53;
  padding: 9px 0;
  margin-left: 15px;
  margin-right: 15px;
  border-top: 1px solid #f8f8f8;
  span {
    opacity: 0.7;
  }
`;

export const SingleItemWrapper = styled.div`
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #efeeff;
  }
`;

export const TeamName = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  margin-left: 15px;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-weight: 400;
  }
`;

export const ValueItem = styled.div``;

export const ValueSelected = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemovedLabel = styled.span`
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
`;
