// Lib
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import debounce from 'lodash/debounce';
import Dropzone from 'react-dropzone';

// Store
import {
  getRecipeBackgroundLibraries,
  deleteRecipeBackgroundLibraries,
  selectedCoverBackground,
  addBackgroundLibraries,
  addLocalBackgroundLibrary,
  removeLocalBackgroundLibrary,
} from 'redux/recipes/actions';
import { toggleConfirmModal } from 'actions/modal';
import { hideTextDefaultImage } from 'redux/general-settings/actions';

// Components
import CoverImageContainer from 'components/Recipes/Parts/CoverImage';
import ThumbnailOverlay from './ThumbnailOverlay';
import S3Images from 'shared/S3Images';
import FileUpload from 'shared/FileUpload';

// Constants
import { PER_PAGE_LOAD_MORE, PER_PAGE } from '../constants';
import { revokeObjectURL, mediaLog, createObjectURL, getPresignedUploadUrl, mongoObjectId } from 'utils/commonFunction';
import { RECIPE_BUILDER_UPLOAD_URL } from 'components/Recipes/constants';
import { CDN_URL } from 'constants/commonData';

// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close-reply.svg';

// Helper
import { extractUrlPresigned } from '../helper';

import * as S from './style';

const Thumbnail = props => {
  const {
    src,
    localUrl,
    setLocalUrl,
    isLoading,
    setIsLoading,
    onSelect,
    listCoverImage,
    totalCoverImage,
    addBackgroundLibraries,
    getRecipeBackgroundLibraries,
    deleteRecipeBackgroundLibraries,
    selectedCoverBackground,
    isEditAble,
    toggleConfirmModal,
    addLocalBackgroundLibrary,
    removeLocalBackgroundLibrary,
    hideTextDefaultImage,
    isChangeRecipeDefaultImage,
  } = props;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ image: src });
  const [page, setPage] = useState(1);

  const [file, setFile] = useState(null);
  const [uploadConfigs, setUploadConfigs] = useState(null);

  const contentRef = useRef(null);

  useEffect(() => {
    getRecipeBackgroundLibraries && getRecipeBackgroundLibraries({ page: page, per_page: PER_PAGE });
  }, []);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setSelected({ image: src });
    setOpen(false);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();

    if (!id) {
      return;
    }
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <S.CustomConfirmModal
          className="delete-thumbnail-modal"
          title="Remove Image?"
          content="Are you sure you want to remove this image?"
          onConfirm={() => deleteRecipeBackgroundLibraries && deleteRecipeBackgroundLibraries(id)}
          headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
          confirmButtonTitle="Remove"
          noBorder
        />,
      );
  };

  const handleScroll = () => {
    const listPhoto = contentRef.current;
    if (listPhoto && listCoverImage.length < totalCoverImage) {
      const tolerancePercentage = 10;
      const tolerance = (tolerancePercentage / 100) * listPhoto.clientHeight;

      const isAtBottom = listPhoto.scrollTop + listPhoto.clientHeight + tolerance >= listPhoto.scrollHeight;
      if (isAtBottom) {
        const newPage = page + 1;

        getRecipeBackgroundLibraries && getRecipeBackgroundLibraries({ page: newPage, per_page: PER_PAGE_LOAD_MORE });

        setPage(newPage);
      }
    }
  };

  const handleSelectCoverImage = () => {
    selectedCoverBackground && selectedCoverBackground(selected);
    onSelect && onSelect(selected);
    if (!isChangeRecipeDefaultImage && src !== selected && selected.image) {
      hideTextDefaultImage().then(() => {
        onClose();
      });
    } else {
      onClose();
    }
  };

  const handleUploadSuccess = async response => {
    if (file) {
      const { size, name, type } = file;
      await mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload success file via recipe',
      });
      setFile(null);
    }
    setIsLoading(false);
    setUploadConfigs(null);

    const fileInfo = extractUrlPresigned(get(response, 'config.url', ''));

    if (fileInfo) {
      let params = {
        image: fileInfo,
      };

      addBackgroundLibraries && addBackgroundLibraries(params);
      setSelected({ image: fileInfo });
    }
  };

  const handleCancelUpload = () => {
    revokeObjectURL(localUrl);
    setIsLoading(false);
    setFile(null);
    setLocalUrl(null);
    setUploadConfigs(null);
    removeLocalBackgroundLibrary && removeLocalBackgroundLibrary();
  };

  const handleUploadError = () => {
    setIsLoading(false);
  };

  const handleSelectFile = async file => {
    if (file) {
      const { size, name, type } = file;
      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file to S3',
      });
    }
    if (localUrl) {
      revokeObjectURL(localUrl);
    }

    setIsLoading(true);
    setLocalUrl(createObjectURL(file));

    // Get presigned url
    const { uploadUrl } = await getPresignedUploadUrl(RECIPE_BUILDER_UPLOAD_URL, file);

    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    });

    setFile(file);

    addLocalBackgroundLibrary &&
      addLocalBackgroundLibrary({ id: mongoObjectId(), image: createObjectURL(file), isLocal: true });
  };

  const coverImagesCollection = useMemo(() => {
    return unionBy(listCoverImage, '_id');
  }, [listCoverImage]);

  return (
    <S.CustomModal
      open={open}
      closeOnDimmerClick={false}
      className="evf-select-photo-modal"
      onClose={onClose}
      onOpen={onOpen}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="close_icon" />
        </button>
      }
      trigger={<ThumbnailOverlay isEditAble={isEditAble} onOpen={onOpen} src={src} key="tooltip-thumb-recipe-detail" />}
    >
      <Modal.Header>
        <S.HeaderWrapper>
          <S.HeaderTitle>Select Recipe Image</S.HeaderTitle>
        </S.HeaderWrapper>
      </Modal.Header>
      <Dropzone>
        {({ getRootProps, isDragActive }) => (
          <>
            <Modal.Content {...getRootProps()} className={`${isDragActive ? 'isDragActive' : ''}`}>
              <S.ContentWrapper ref={contentRef} onScroll={debounce(handleScroll, 300)}>
                <S.PhotoItem noPadding isDragActive={isDragActive}>
                  <CoverImageContainer
                    localUrl={localUrl}
                    setLocalUrl={setLocalUrl}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    onSelectFile={handleSelectFile}
                    hideUpload
                    isDragActive={isDragActive}
                  />
                </S.PhotoItem>
                {!isDragActive &&
                  coverImagesCollection &&
                  coverImagesCollection.map(item => {
                    const { _id: id = '', image = '', isLocal, author = '', image_thumbnail } = item;
                    const isDefault = image === selected.image;

                    if (isLocal && image) {
                      return (
                        <S.PhotoItem noPadding>
                          <S.UploadContent background={localUrl}>
                            <FileUpload
                              onSuccess={handleUploadSuccess}
                              onError={handleUploadError}
                              onCancel={debounce(handleCancelUpload, 300)}
                              configs={uploadConfigs}
                            />
                          </S.UploadContent>
                        </S.PhotoItem>
                      );
                    }

                    return (
                      <S.PhotoItem key={id} onClick={() => setSelected(item)} isChecked={isDefault} isShow={!isDefault}>
                        {/* TODO: Remove default label for this ver  */}
                        {/* {isDefault && (
                          <S.TagDefault>
                            <S.TagContent>Default</S.TagContent>
                          </S.TagDefault>
                        )} */}
                        {author && (
                          <S.DeleteButton onClick={e => handleDelete(e, id)}>
                            <CloseIcon />
                          </S.DeleteButton>
                        )}
                        <S3Images src={[image_thumbnail, image]} />
                      </S.PhotoItem>
                    );
                  })}
              </S.ContentWrapper>
            </Modal.Content>
            {!isDragActive && (
              <Modal.Actions>
                <S.ActionsWrapper>
                  <S.CustomButton disabled={isLoading} onClick={handleSelectCoverImage}>
                    <S.ButtonContent>Select</S.ButtonContent>
                  </S.CustomButton>
                </S.ActionsWrapper>
              </Modal.Actions>
            )}
          </>
        )}
      </Dropzone>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { recipes, generalSettings },
  } = state;

  return {
    listCoverImage: get(recipes, 'coverBackground.list', []),
    totalCoverImage: get(recipes, 'coverBackground.total', 0),
    loadingCoverImage: get(recipes, 'coverBackground.loadingCoverBackground', false),
    selectedCoverImage: get(recipes, 'selectedCoverBackground', {}),
    isChangeRecipeDefaultImage: get(generalSettings, 'is_change_recipe_default_image', ''),
  };
};

const mapDispatchToProps = dispatch => ({
  addBackgroundLibraries: bindActionCreators(addBackgroundLibraries, dispatch),
  getRecipeBackgroundLibraries: bindActionCreators(getRecipeBackgroundLibraries, dispatch),
  deleteRecipeBackgroundLibraries: bindActionCreators(deleteRecipeBackgroundLibraries, dispatch),
  selectedCoverBackground: bindActionCreators(selectedCoverBackground, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  addLocalBackgroundLibrary: bindActionCreators(addLocalBackgroundLibrary, dispatch),
  removeLocalBackgroundLibrary: bindActionCreators(removeLocalBackgroundLibrary, dispatch),
  hideTextDefaultImage: bindActionCreators(hideTextDefaultImage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Thumbnail);
