import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProfileTempOnboardingQuestionnaire, logout, submitQuestionnaire } from 'actions/auth/login';
import Component from './component';
import { getClientsFullData } from 'redux/client/client.actionCreators';

const mapDispatch = dispatch => {
  return {
    submitQuestionnaire: bindActionCreators(submitQuestionnaire, dispatch),
    getClientsFullData: bindActionCreators(getClientsFullData, dispatch),
    logout: bindActionCreators(logout, dispatch),
    getProfileTempOnboardingQuestionnaire: bindActionCreators(getProfileTempOnboardingQuestionnaire, dispatch),
  };
};

export default connect(null, mapDispatch)(Component);
