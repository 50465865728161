import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

const TitleTooltip = props => {
  const {
    content,
    className = '',
    tag = 'div',
    delayShow = 0,
    tooltipId,
    tooltipClassName = 'app-tooltip',
    place = 'top',
  } = props;
  if (_.isEmpty(content)) return <></>;

  const CustomTag = tag;

  const [isDisable, setDisable] = useState(false);

  const handleMouseOver = e => {
    const { scrollWidth, clientWidth } = e.currentTarget;
    if (clientWidth < scrollWidth) {
      isDisable && setDisable(false);
    } else {
      !isDisable && setDisable(true);
    }
  };

  const handleMouseOut = () => {
    setDisable(false);
  };

  return (
    <>
      <CustomTag
        className={className}
        data-for={tooltipId}
        data-tip
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        data-iscapture="true"
      >
        {content}
      </CustomTag>
      <ReactTooltip
        className={tooltipClassName}
        id={tooltipId}
        effect="solid"
        place={place}
        disable={isDisable}
        delayShow={delayShow}
        eventOff="mouseleave scroll mousewheel blur"
      >
        <span>{content}</span>
      </ReactTooltip>
    </>
  );
};

export default TitleTooltip;
