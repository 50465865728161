// Libs
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import find from 'lodash/find';

// Shared
import DropdownOptions from 'shared/DropdownOptions';
import { Toggle } from 'shared/FormControl';
import FormItem from 'shared/FormItem';
import ColorPickerWithPreview from 'shared/ColorPicker/Preview';
import ConfirmModal from 'shared/ConfirmModal';

// Components
import ImageLibraryModal from './ImageLibraryModal';
import OtherOption from './OtherOption';
import SyncOption from './SyncOption';
import MetricOption from './MetricOption';

// Constants
import { QUESTION_TYPES } from 'constants/commonData';
import { TYPE_HIDE_ADVANCED_SETTINGS } from 'components/FormDetails/constants';
import { checkSyncConditions } from 'components/FormDetails/helpers';

// Assets
import { ReactComponent as InfoIcon } from 'assets/icons/tooltip-icon.svg';

// Styles
import * as S from './style';

const MIN_OPTIONS = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '1',
    value: '1',
  },
];

const MAX_OPTIONS = [
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
];

const MAX_LENGTH_LABEL = 12;
const MAX_LENGTH_BUTTON_LABEL = 15;
const MAX_LENGTH_BUTTON_COLOR = 7;

const SettingImage = props => {
  const { isSelectedImage, handleOpen, handleRemove, className } = props;

  return (
    <S.SettingImageWrapper className={className}>
      <S.Title>
        Image{' '}
        <S.TooltipWrapper>
          <InfoIcon data-tip data-for="tooltip--image-text" />
          <ReactTooltip id="tooltip--image-text" effect="solid" place-="top" className="app-tooltip tooltip-image-text">
            <S.TooltipContent>
              Png or jpg files. Image width should be at least 335px for best resolution.
            </S.TooltipContent>
          </ReactTooltip>
        </S.TooltipWrapper>
      </S.Title>
      {isSelectedImage ? (
        <div className="change-remove-container">
          <span className="change-btn" onClick={handleOpen}>
            Change
          </span>
          <span className="remove-btn" onClick={handleRemove}>
            Remove
          </span>
        </div>
      ) : (
        <div className="add-btn" onClick={handleOpen}>
          Add
        </div>
      )}
    </S.SettingImageWrapper>
  );
};

const SettingsType = ({
  workingQuestion,
  updatePartialQuestion,
  toggleModal,
  allowEdit,
  imageLibrary,
  getBackgroundLibrary,
  toggleConfirmModal,
  defaultBackgroundColor,
  settingsRef,
  bodyMetrics,
  questions,
}) => {
  const [label, setLabel] = useState({
    linear_start: '',
    linear_end: '',
  });
  const [buttonLabel, setButtonLabel] = useState("Let's start");
  const [buttonBackground, setButtonBackground] = useState(defaultBackgroundColor);
  const start = `${get(workingQuestion, 'linear_start.value', 0)}`;
  const end = `${get(workingQuestion, 'linear_end.value', 5)}`;
  const labelStart = get(workingQuestion, 'linear_start.label', '');
  const labelEnd = get(workingQuestion, 'linear_end.label', '');
  const storedButtonLabel = get(workingQuestion, 'button_label', "Let's start");
  const storedButtonBackground = get(workingQuestion, 'button_background', defaultBackgroundColor);
  const labelStartRef = useRef();
  const labelEndRef = useRef();

  const newQuestions = (questions || []).filter(({ type }) =>
    [QUESTION_TYPES.DATE_TIME, QUESTION_TYPES.METRIC].includes(type),
  );

  const dobCondition = ({ is_sync, _id = '', type }) => {
    return (
      type === QUESTION_TYPES.DATE_TIME &&
      (typeof is_sync === 'undefined' || is_sync) &&
      _id !== get(workingQuestion, '_id')
    );
  };

  const metricCondition = ({ is_sync, _id, type, body_metric_unique_code }) => {
    return (
      type === QUESTION_TYPES.METRIC &&
      (typeof is_sync === 'undefined' || is_sync) &&
      _id !== get(workingQuestion, '_id') &&
      body_metric_unique_code === get(workingQuestion, 'body_metric_unique_code')
    );
  };

  const { hasSync: hasSyncDob } = checkSyncConditions(newQuestions, dobCondition);

  const { hasSync: hasSyncMetric } = checkSyncConditions(newQuestions, metricCondition);

  useEffect(() => {
    setButtonLabel(storedButtonLabel);
    setButtonBackground(storedButtonBackground);
  }, [storedButtonLabel, storedButtonBackground]);

  useEffect(() => {
    getBackgroundLibrary();
  }, []);

  useEffect(() => {
    settingsRef.current = handleMouseLeave;
  }, []);

  const showRequired =
    workingQuestion.is_require !== undefined &&
    [
      QUESTION_TYPES.SINGLE_CHOICE,
      QUESTION_TYPES.MULTIPLE_CHOICE,
      QUESTION_TYPES.LINEAR_SCALE,
      QUESTION_TYPES.SHORT_ANSWER,
      QUESTION_TYPES.LONG_ANSWER,
      QUESTION_TYPES.DATE_TIME,
      QUESTION_TYPES.METRIC,
    ].includes(workingQuestion.type);

  const showOther =
    workingQuestion.has_other_option &&
    [QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.MULTIPLE_CHOICE].includes(workingQuestion.type);

  const showMetricOption = [QUESTION_TYPES.METRIC].includes(workingQuestion.type);

  useEffect(() => {
    setLabel({
      linear_start: labelStart,
      linear_end: labelEnd,
    });
  }, [labelStart, labelEnd]);

  const handleChangeRequired = () => {
    updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        is_require: !workingQuestion.is_require,
      });
  };

  const handleChangeLabel = e => {
    const { name, value } = e.target;
    setLabel({
      ...label,
      [name]: value,
    });
  };

  const handleChangeButtonBackground = e => {
    setButtonBackground(e.target.value);
  };

  const handleChangeButtonLabel = e => {
    // Update realtime text of button
    const value = get(e, 'target.value', '').replace(/\s+/gi, ' ');
    setButtonLabel(value);
    updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        button_label: value.trim(),
      });
  };

  const handleBlurStartLabel = () => {
    if (label.linear_start !== get(workingQuestion, 'linear_start.label')) {
      updatePartialQuestion &&
        updatePartialQuestion({
          _id: workingQuestion._id,
          type: workingQuestion.type,
          linear_start: {
            ...workingQuestion.linear_start,
            label: label.linear_start.trim(),
          },
        });
    }
  };

  const handleBlurEndLabel = () => {
    if (label.linear_end !== get(workingQuestion, 'linear_end.label')) {
      updatePartialQuestion &&
        updatePartialQuestion({
          _id: workingQuestion._id,
          type: workingQuestion.type,
          linear_end: {
            ...workingQuestion.linear_end,
            label: label.linear_end.trim(),
          },
        });
    }
  };

  const handleBlurButtonLabel = () => {
    let tempLabel = buttonLabel;
    if (buttonLabel.trim() === '') {
      tempLabel = "Let's start";
    }

    setButtonLabel(tempLabel.trim());
    if (tempLabel.trim() !== get(workingQuestion, 'button_label'))
      updatePartialQuestion &&
        updatePartialQuestion({
          _id: workingQuestion._id,
          type: workingQuestion.type,
          button_label: tempLabel.trim(),
        });
  };

  const handleChangeMinValue = option => {
    updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        linear_start: {
          ...workingQuestion.linear_start,
          value: option.value,
        },
      });
  };

  const handleChangeMaxValue = option => {
    updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        linear_end: {
          ...workingQuestion.linear_end,
          value: option.value,
        },
      });
  };

  const handleFormatColor = () => {
    const hexColorRegExp = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i; // valid when string is valid hex color code with 3 or 6 values
    let formatButtonBackground = buttonBackground;

    if (hexColorRegExp.test(formatButtonBackground)) {
      if (formatButtonBackground.substring(1).length === 3) {
        formatButtonBackground = formatButtonBackground
          .substring(1)
          .split('')
          .map(function (hex) {
            return hex + hex;
          })
          .join('');
        formatButtonBackground = '#' + formatButtonBackground;
      }
    } else {
      formatButtonBackground = '#5158CF';
    }
    return formatButtonBackground.toUpperCase();
  };

  const onColorUpdate = () => {
    if (buttonBackground === '') {
      setButtonBackground(defaultBackgroundColor);
      updatePartialQuestion &&
        updatePartialQuestion({
          _id: workingQuestion._id,
          type: workingQuestion.type,
          button_background: defaultBackgroundColor,
        });
    } else {
      if (buttonBackground !== get(workingQuestion, 'button_background')) {
        setButtonBackground(handleFormatColor());
        updatePartialQuestion &&
          updatePartialQuestion({
            _id: workingQuestion._id,
            type: workingQuestion.type,
            button_background: handleFormatColor(),
          });
      }
    }
  };

  const onBackgroundUpdate = (data, callback) => {
    updatePartialQuestion &&
      updatePartialQuestion(
        {
          _id: workingQuestion._id,
          type: workingQuestion.type,
          background_image: data.url,
          background_image_thumbnail: data.thumbnail_url,
        },
        callback,
      );
  };

  const handleOpenImageLibrary = () => {
    if (!allowEdit) {
      return;
    }
    toggleModal(
      true,
      <ImageLibraryModal
        toggleConfirmRemoveImageModal={toggleConfirmRemoveImageModal}
        getBackgroundLibrary={getBackgroundLibrary}
        toggleModal={toggleModal}
        onUploadImageChange={onBackgroundUpdate}
        workingQuestion={workingQuestion}
      />,
    );
  };

  const haveSelectedImage = useMemo(() => {
    return get(workingQuestion, 'background_image', null);
  }, [workingQuestion]);

  const getSelectedImage = selectedBackground => {
    if (!selectedBackground) return null;
    const list = get(imageLibrary, 'list', []);
    return find(list, o => o.background === selectedBackground);
  };

  const handleRemoveSelectedImage = () => {
    if (imageLibrary.loading) {
      return;
    }
    const selectedBackground = get(workingQuestion, 'background_image', null);
    const selectedImage = getSelectedImage(selectedBackground);
    if (selectedImage) {
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        background_image: null,
        background_image_thumbnail: null,
      });
    }
  };

  const toggleConfirmRemoveImageModal = onConfirm => {
    toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        hasCloseIcon
        hasHoverState
        title="Cancel Changes"
        content="Your changes will not be saved. Would you like to stop editing the background image without saving? Lorem ipsum!"
        onConfirm={() => {
          toggleConfirmModal(false);
          onConfirm && onConfirm();
        }}
        cancelButtonTitle="Continue editing"
        confirmButtonTitle="Exit without saving"
      />,
    );
  };

  const handleMouseLeave = () => {
    const labelStart = get(labelStartRef, 'current');
    const labelEnd = get(labelEndRef, 'current');
    labelStart && labelStart.blur();
    labelEnd && labelEnd.blur();
  };

  const handleSelectFileImageText = () => {
    const element = document.getElementById(`image-text-${workingQuestion._id}`);
    element && element.click();
  };

  const handleRemoveImageText = () => {
    updatePartialQuestion &&
      updatePartialQuestion({
        _id: workingQuestion._id,
        type: workingQuestion.type,
        background_image: null,
        background_image_thumbnail: null,
        image_width: null,
        image_height: null,
      });
  };

  return (
    <S.SettingsTypeWrapper>
      {!TYPE_HIDE_ADVANCED_SETTINGS.includes(workingQuestion.type) && <S.Title>Advanced Settings</S.Title>}
      {showRequired ? (
        <S.ToggleWrapper disabled={!allowEdit} data-id="required">
          <span>Required</span>
          <Toggle
            width={28}
            height={16}
            checked={workingQuestion.is_require}
            onChange={handleChangeRequired}
            disabled={!allowEdit}
          />
        </S.ToggleWrapper>
      ) : null}
      {showOther ? (
        <OtherOption workingQuestion={workingQuestion} onChange={updatePartialQuestion} disabled={!allowEdit} />
      ) : null}
      {showMetricOption ? (
        <MetricOption
          disabled={!allowEdit}
          workingQuestion={workingQuestion}
          onChange={updatePartialQuestion}
          bodyMetrics={bodyMetrics}
          questions={questions}
        />
      ) : null}
      {workingQuestion.type === QUESTION_TYPES.DATE_TIME ? (
        <SyncOption
          disabled={!allowEdit || (hasSyncDob && [QUESTION_TYPES.DATE_TIME].includes(workingQuestion.type))}
          workingQuestion={workingQuestion}
          onChange={updatePartialQuestion}
          toggleConfirmModal={toggleConfirmModal}
          hasSync={hasSyncDob && [QUESTION_TYPES.DATE_TIME].includes(workingQuestion.type)}
        />
      ) : null}
      {workingQuestion.type === QUESTION_TYPES.METRIC ? (
        <SyncOption
          disabled={!allowEdit || (hasSyncMetric && [QUESTION_TYPES.METRIC].includes(workingQuestion.type))}
          workingQuestion={workingQuestion}
          onChange={updatePartialQuestion}
          toggleConfirmModal={toggleConfirmModal}
          hasSync={hasSyncMetric && [QUESTION_TYPES.METRIC].includes(workingQuestion.type)}
        />
      ) : null}
      {workingQuestion.type === QUESTION_TYPES.WELCOME_SCREEN ? (
        <>
          <div>
            <S.SettingLabel>Button background</S.SettingLabel>
            <S.DropdownInputWrapper isColorPicker data-picker="background-btn">
              <ColorPickerWithPreview
                trigger={<S.Trigger buttonBackground={buttonBackground} disabled={!allowEdit} />}
                color={buttonBackground}
                styles={{
                  floater: {
                    marginTop: '-4px',
                  },
                }}
                onChange={setButtonBackground}
                onColorUpdate={onColorUpdate}
                updateOnClose
                disabled={!allowEdit}
              />
              <S.Divider />
              <FormItem
                placeholder="Color"
                value={buttonBackground}
                onChange={handleChangeButtonBackground}
                className="custom-input"
                name="button_background"
                maxLength={MAX_LENGTH_BUTTON_COLOR}
                onBlur={onColorUpdate}
                disabled={!allowEdit}
              />
            </S.DropdownInputWrapper>
          </div>
          <div>
            <S.SettingLabel>Button label</S.SettingLabel>
            <S.DropdownInputWrapper data-id="label-btn">
              <FormItem
                placeholder="Button label"
                value={buttonLabel}
                onChange={handleChangeButtonLabel}
                className="custom-input"
                name="button_label"
                maxLength={MAX_LENGTH_BUTTON_LABEL}
                onBlur={handleBlurButtonLabel}
                disabled={!allowEdit}
              />
              <S.AddOnLabel>
                {buttonLabel.trim().length}/{MAX_LENGTH_BUTTON_LABEL}
              </S.AddOnLabel>
            </S.DropdownInputWrapper>
          </div>
          {allowEdit && (
            <SettingImage
              isSelectedImage={haveSelectedImage}
              handleOpen={handleOpenImageLibrary}
              handleRemove={handleRemoveSelectedImage}
            />
          )}
        </>
      ) : null}
      {workingQuestion.type === QUESTION_TYPES.IMAGE_TEXT && allowEdit && (
        <SettingImage
          isImageText
          isSelectedImage={workingQuestion.background_image_thumbnail || workingQuestion.background_image}
          handleOpen={handleSelectFileImageText}
          handleRemove={handleRemoveImageText}
          className="setting-image-text"
        />
      )}
      {workingQuestion.linear_start && workingQuestion.type === QUESTION_TYPES.LINEAR_SCALE ? (
        <S.DropdownInputWrapper data-id="linear-start">
          <DropdownOptions
            width="49px"
            height="20px"
            options={MIN_OPTIONS}
            selectedValue={start}
            onSelect={handleChangeMinValue}
            className="custom-dropdown"
            readOnly={!allowEdit}
            isForceUpdate
          />
          <S.Divider />
          <FormItem
            ref={labelStartRef}
            placeholder="Label (optional)"
            value={label.linear_start}
            onChange={handleChangeLabel}
            className="custom-input"
            name="linear_start"
            maxLength={MAX_LENGTH_LABEL}
            onBlur={handleBlurStartLabel}
            disabled={!allowEdit}
          />
          <S.AddOnLabel>
            {label.linear_start.length}/{MAX_LENGTH_LABEL}
          </S.AddOnLabel>
        </S.DropdownInputWrapper>
      ) : null}
      {workingQuestion.linear_end && workingQuestion.type === QUESTION_TYPES.LINEAR_SCALE ? (
        <S.DropdownInputWrapper data-id="linear-end">
          <DropdownOptions
            width="49px"
            height="20px"
            options={MAX_OPTIONS}
            selectedValue={end}
            onSelect={handleChangeMaxValue}
            className="custom-dropdown"
            readOnly={!allowEdit}
            isForceUpdate
          />
          <S.Divider />
          <FormItem
            ref={labelEndRef}
            placeholder="Label (optional)"
            value={label.linear_end}
            onChange={handleChangeLabel}
            className="custom-input"
            name="linear_end"
            maxLength={MAX_LENGTH_LABEL}
            onBlur={handleBlurEndLabel}
            disabled={!allowEdit}
          />
          <S.AddOnLabel>
            {label.linear_end.length}/{MAX_LENGTH_LABEL}
          </S.AddOnLabel>
        </S.DropdownInputWrapper>
      ) : null}
    </S.SettingsTypeWrapper>
  );
};

export default memo(SettingsType);
