import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -7px;
  svg {
    width: 9px;
    cursor: pointer;
    display: flex;
  }
  .prev-day {
    cursor: pointer;
    margin-right: 7.74px;
    svg {
      margin-left: -1px;
      transform: rotate(-90deg);
    }
  }
  .cal-arrow-btn {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #f0f1ff;
      svg path {
        fill: #5158cf;
      }
    }
  }
  .next-day {
    cursor: pointer;
    margin-left: 7.74px;
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
      background-color: transparent;
      svg:hover {
        cursor: not-allowed;
      }
    }
    svg {
      margin-right: -1px;
      transform: rotate(90deg);
    }
  }
  span {
    line-height: 26px;
    font-size: 17px;
    font-weight: 600;
    color: #202353;
  }
`;
