// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';

import { OnboardingTabs } from 'components/OnboardingFlowLibrary/constants';
import { changeOnboardingQuery, changeOnboardingTab } from 'redux/onboarding-flow-library/actions';
import { isTrainer } from 'utils/validations';

// Styles
import * as S from './style';

const Tabs = props => {
  const { user, currentTab, changeOnboardingTab, changeOnboardingQuery, query } = props;

  const handleChangeTab = (_, { activeIndex }) => {
    if (currentTab === activeIndex) return;

    changeOnboardingTab(activeIndex);
    changeOnboardingQuery({
      ...query,
      page: 1,
      only_my_onboarding_flows: activeIndex === 1 || undefined, // OnboardingTabs.your_onboarding_flows
      only_archived_onboarding_flows: activeIndex === 2 || undefined, // OnboardingTabs.archive
    });
  };

  return (
    <S.Wrapper>
      <Tab
        menu={{ text: true }}
        panes={
          isTrainer(user) ? OnboardingTabs.filter(({ name }) => name !== 'default_onboarding_flow') : OnboardingTabs
        }
        className="tabs"
        onTabChange={handleChangeTab}
        activeIndex={currentTab}
      />
    </S.Wrapper>
  );
};

const mapState = ({ user, rootReducer: { onboardingLibrary } = {} }) => ({
  currentTab: onboardingLibrary.currentTab,
  query: onboardingLibrary.filters,
  user,
});

const mapDispatchToProps = {
  changeOnboardingTab,
  changeOnboardingQuery,
};

export default connect(mapState, mapDispatchToProps)(Tabs);
