import React from 'react';
import _ from 'lodash';
import Avatar from 'react-avatar';
import * as S from './style';
import ChangeBillingContact from '../ChangeBillingContact';
import { ContactInformation, SingleLabel } from '../ChangeBillingContact/style';
import { PanelHeader, PanelContent, PanelFooter, ActionButton } from '../style';
import { getUserShortName } from 'utils/commonFunction';

function BillingContact(props) {
  const { data } = props;

  const changeContact = () => {
    props.toggleModal(true, <ChangeBillingContact onClose={() => props.toggleModal(false)} />);
  };

  return (
    <S.CurrentPlanWrapper>
      <PanelHeader>
        <div>Billing Contact</div>
      </PanelHeader>
      <PanelContent>
        <ContactInformation className="billing-contact">
          <Avatar name={getUserShortName(data)} size="50" src={data.avatar} />
          <SingleLabel className="select__option__label">
            <div className="name">
              {data.first_name} {data.last_name}
            </div>
            <div className="email">{data.email}</div>
          </SingleLabel>
        </ContactInformation>
      </PanelContent>
      <PanelFooter>
        <ActionButton data-btn="change-contact" onClick={changeContact}>
          Change Contact
        </ActionButton>
      </PanelFooter>
    </S.CurrentPlanWrapper>
  );
}

export default BillingContact;
