/**
 * @flow
 */

import { connect } from 'react-redux';
import ConfirmModalComponent from './component';

const mapStateToProps = state => {
  const { isConfirmModalOpen, confirmModal } = state;
  return {
    isConfirmModalOpen,
    confirmModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModalComponent);
