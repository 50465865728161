import styled, { css } from 'styled-components';
import background from 'assets/images/upload-cover-bg.png';
import { SelectFile } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  margin-bottom: 16px;
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 8px;
  background: #ffffff;
  .product-info-container {
    padding: 10px;
    margin-top: 5px;
  }
  .product-title {
    margin-bottom: 3px;
    .text-editable__input {
      font-size: 23px;
      padding: 2px 0px;
      ${props =>
        !props.isEdit &&
        css`
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `}
    }
  }
  .product-description {
    span {
      color: #8a8ba4 !important;
    }
    .text-editable__input {
      height: 95px;
      overflow: auto;
      font-size: 13px;
      font-weight: normal;
      line-height: 19.5px;
      ::before {
        padding-left: 25px !important;
        background: url(${CDN_URL}/images/product-description.svg) no-repeat;
      }
    }

    .text-editable__container--empty {
      .text-editable__input {
        height: unset;
      }
    }
    ${props =>
      !props.isEdit &&
      css`
        .text-editable__container--active.text-editable__container--empty {
          .text-editable__input {
            height: unset;
            ::before {
              content: 'No Description';
              font-style: italic;
            }
          }
        }
      `}
    .text-editable__container--active {
      .text-editable__input {
        height: 95px;
        ::before {
          padding-left: 0px !important;
          background: unset;
        }
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #e2e5ec;
  display: flex;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  .evf-file-upload {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  ${props =>
    props.isDragging &&
    css`
      outline: 1px dashed #5158cf;
    `}
  align-items: center;
  justify-content: center;
  min-height: 205px;
  position: relative;
  .hidden {
    visibility: hidden;
  }
  .show-on-hover {
    visibility: hidden;
  }
  :hover {
    .show-on-hover {
      visibility: visible;
    }
  }
`;

export const CoverImage = styled.div``;

export const Banner = styled.div``;

export const SelectBanner = styled(SelectFile)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
`;

export const EditButton = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 8px 15px;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #818da1;
  display: flex;
  align-items: center;
  justify-content: center
  cursor: pointer;

  svg {
    margin-right: 10px;
    path {
      fill: #818da1;
    }
  }

  :hover {
    color: #5158cf;
    svg {
      margin-right: 10px;
      path {
        fill: #5158cf;
      }
    }
  }
`;
