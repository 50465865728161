// Lib
import React from 'react';
import isEmpty from 'lodash/isEmpty';

// Constants
import { roundNumberBodyMetric } from 'utils/commonFunction';

import * as S from '../styles';

export default function MetricAnswerResponse({ answer }) {
  const { answer: answerData } = answer || {};
  const { metric_answer, additional_data } = answerData || {};

  const renderMetricValue = () => {
    const { value = '', unit } = metric_answer || {};
    const { title = '' } = unit || {};

    if (!isEmpty(additional_data)) {
      const { metric_ft: ft = '', metric_in: inch = '' } = additional_data || {};
      const ftStr = ft !== 0 ? `${roundNumberBodyMetric(ft)} ft` : '';
      const inchStr = inch !== 0 ? `${roundNumberBodyMetric(inch)} in` : '';

      return ft === 0 && inch !== 0 ? `${inchStr}` : `${ftStr} ${inchStr}`;
    }

    return `${roundNumberBodyMetric(value)} ${title}`;
  };

  return <S.MetricAnswer>{renderMetricValue() || ''}</S.MetricAnswer>;
}
