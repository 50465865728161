import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7B7E91;
`;

export const ButtonNext = styled.button`
  ${baseText}
  height: 30px;
  width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(81, 88, 207, 1);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(81, 88, 207, 0.9);
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: #eaeef1;
      color: #7b7d91;
      &:hover {
        background-color: #eaeef1;
        color: #7b7d91;
      }
    `}
`;

export const Wrapper = styled.div`
  height: 100%;
  padding: 131px 0 60px;
`;

export const Title = styled.div`
  ${baseText}
  font-size: 10px;
  line-height: 15px;
  color: #7b7e91;
  margin-bottom: 11px;
  text-transform: uppercase;
  padding: 0 29px;
`;

export const ListWrapper = styled.div`
  padding: 0 10px 0 29px;
  position: relative;
  height: calc(100% - 26px);
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -18px;
  overflow-y: scroll;
  height: 100%;
  align-content: flex-start;
  padding-right: 13px;

  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1ea;
    border-radius: 6px;
  }

  ${({ loading }) =>
    loading &&
    css`
      padding: 0 29px;
      overflow: hidden;
    `}
`;

export const Item = styled.div`
  margin-left: 18px;
  width: calc(33.33% - 18px);
  margin-bottom: 18px;
`;

export const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 23px;
  .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f0f0f2;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 36px;
      height: 36px;
      path {
        fill: #7b7e91;
      }
    }
  }
  span {
    ${baseText}
    font-size: 15px;
    line-height: 23px;
    color: #202353;
  }
`;
