import classnames from 'classnames';
import { HIDDEN_SECTION, LIST_SECTION_TYPE, SECTION_FORMAT, SECTION_FORMAT_KEY } from 'constants/commonData';
import { ENTITY_NAME } from 'database/constants';
import { diff } from 'deep-diff';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import ConfirmModal from 'shared/ConfirmModal';
import { Button, ErrorMessage } from 'shared/FormControl';
import UploadAndDragSection from 'shared/UploadAndDragSection';
import TextEditable from 'shared/TextEditable';
import { pluralize } from 'utils/commonFunction';
import { LIST_SECTION_FORMAT } from '../../../../constants';
import FormItem from '../../components/AddSectionForm/SectionForm/FormItem';
import SelectSectionOption from '../../components/SelectSection';
import AddActions from '../AddActions';
import { SectionContext } from '../contexts';
import Exercise from '../Exercise';
import LinkSet, { LINK_TYPES } from '../LinkSet';
import ChangeOrderButton from './ChangeOrderButton';
import ExcerciseEmptyDroppable from './components/ExcerciseEmptyDroppable';
import ExerciseList from './components/ExerciseList';
import * as S from './style';

function Section(props) {
  const { FREESTYLE } = SECTION_FORMAT_KEY;
  const {
    sectionId,
    sectionSize,
    linkable,
    sectionIndex,
    draggingItemLeft,
    showError,
    totalExercises,
    onIsChanging,
    sectionFormat,
  } = props;
  let section = props.model.toJS();
  let sectionDb = props.modelSection.toJS();

  const [sectionOriginal, setSectionOriginal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sectionData, setSectionData] = useState(_.pick(section, ['type', 'format', '_id']));
  const [minimize, setMinimize] = useState(false);
  const [exerciseReferences, setExerciseReferences] = useState(section.exercise_references || []);
  const [originalExerciseReferences, setOriginalExerciseReferences] = useState(section.exercise_references || []);
  const [removeAllExerciseState, setRemoveExerciseState] = useState(false);
  const [dataSectionFormat, setDataSectionFormat] = useState(sectionDb.format);
  const [dataSectionType, setDataSectionType] = useState(sectionDb.type);
  const [round, setRound] = useState(null);
  const [time, setTime] = useState(null);

  const actionFixedRef = useRef(null);

  const valueSectionFormat = _.find(LIST_SECTION_FORMAT, function (o) {
    return o.key === dataSectionFormat;
  });

  const valueSectionType = _.find(LIST_SECTION_TYPE, function (o) {
    return o.key === dataSectionType;
  });

  const isDragMode = !!draggingItemLeft;
  const isDragExercise = draggingItemLeft && draggingItemLeft.get('dragType') === 'exercise';

  useEffect(() => {
    const newSectionData = _.pick(section, ['type', 'format', '_id']);

    if (!loading) {
      setSectionOriginal(section);
      setLoading(true);
    }

    if (!_.isEqual(sectionData, newSectionData)) {
      setSectionData(newSectionData);
    }

    // Check changing
    onIsChanging(diff(section, sectionOriginal));
  }, [section]);

  const isHiddenSection = section.type === HIDDEN_SECTION;

  const onUpdateAttachments = attachments => {
    props.updateEntity(ENTITY_NAME.Section, props.sectionId, { attachments });
  };

  const onUpdatePrefrenceExercise = exercise => {
    let exerciseReferences = _.map(exercise, item => item);
    props.updateEntity(ENTITY_NAME.Section, props.sectionId, { exerciseReferences });
  };

  const onAddExercise = () => {
    props.addExerciseInsideSection({ sectionId });
  };

  const onRemoveAll = () => {
    setRemoveExerciseState(true);
  };

  const handleRenderValidationInstruction = () => {
    if (section.format === FREESTYLE) {
      if (showError && !(section.note || '').trim().length) {
        return (
          <S.NoInstructionsErrorContianer>
            <ErrorMessage className="workoutError--noInstruction">Please add instructions</ErrorMessage>
          </S.NoInstructionsErrorContianer>
        );
      }
    }
  };

  const handleRenderValidationExercise = () => {
    if (section.format !== FREESTYLE) {
      return !isHiddenSection && showError && !section.exercises.length ? (
        <S.NoExerciseErrorContianer>
          <ErrorMessage className="workoutError--noExercise">Please add at least 1 exercise</ErrorMessage>
        </S.NoExerciseErrorContianer>
      ) : null;
    }
  };

  const handleTotalExerciseMinimize = section => {
    if (section.format === FREESTYLE) {
      if (section.exerciseReferences) {
        return pluralize('exercise', section.exerciseReferences.length, true);
      }
      return pluralize('exercise', section.exercise_references.length, true);
    }
    return pluralize('exercise', totalExercises, true);
  };

  const handleChangeSectionFormat = data => {
    const selectedFormat = data.key;

    const { exercises } = section;

    if (!dataSectionFormat || !dataSectionType) {
      return console.error(' value undefined', dataSectionFormat, dataSectionType);
    }

    const confirmChangeSectionFormat = () => {
      if (selectedFormat === SECTION_FORMAT_KEY.FREESTYLE) {
        props.updateEntity(ENTITY_NAME.Section, sectionId, {
          exercises: [],
        });
      } else {
        props.updateEntity(ENTITY_NAME.Section, sectionId, {
          attachments: [],
          exerciseReferences: [],
          exercise_references: [],
        });
      }
      setDataSectionFormat(selectedFormat);
      setRound(null);
      setTime(null);
      props.customizeSection(sectionId, { format: selectedFormat, type: dataSectionType });
    };

    if (
      exercises &&
      exercises.length &&
      selectedFormat !== dataSectionFormat &&
      [SECTION_FORMAT_KEY.AMRAP, SECTION_FORMAT_KEY.TIMED, SECTION_FORMAT_KEY.FREESTYLE].includes(selectedFormat)
    ) {
      let content = '';
      switch (selectedFormat) {
        case SECTION_FORMAT_KEY.AMRAP:
          content =
            'Exercises in an AMRAP section are limited to 1 set per exercise, so any exercise with multiple sets will be split into separate exercises. Would you like to change the format to AMRAP?';
          break;
        case SECTION_FORMAT_KEY.TIMED:
          content =
            'Exercises in a Timed section are limited to 1 set per exercise, so any exercise with multiple sets will be split into separate exercises. Would you like to change the format to Timed?';
          break;
        case SECTION_FORMAT_KEY.FREESTYLE:
          content =
            'Exercises in a Freestyle section are just for reference, so any exercise in the current section will be removed when changing the section format. Would you like to change the format to Freestyle?';
          break;
        default:
          break;
      }

      props.toggleConfirmModal(
        true,
        <ConfirmModal
          title={`Change Section Format to ${SECTION_FORMAT[selectedFormat]}`}
          content={content}
          onConfirm={() => {
            confirmChangeSectionFormat();
          }}
          newStyle={true}
          noIcon
          confirmButtonTitle="I understand. Change Format"
          components={{
            ConfirmButton: (
              <Button purple style={{ padding: '5px 24px' }}>
                I understand. Change Format
              </Button>
            ),
          }}
          largeSpacing
        />,
      );
    } else {
      confirmChangeSectionFormat();
    }
  };

  const handleChangeSectionType = data => {
    const selectedType = data.key;
    setDataSectionType(selectedType);
    props.customizeSection(sectionId, { type: selectedType });
  };

  const renderSectionFormat = () => {
    switch (dataSectionFormat) {
      case SECTION_FORMAT_KEY.AMRAP:
        let value = parseFloat(section.time);
        value = isNaN(value) ? '' : value / 60;
        return (
          <S.SectionFormatAmrap>
            <FormItem
              label="duration (min)"
              inputProps={{ min: 0 }}
              value={value}
              type="number"
              onChange={handleChangeTimeAndRound}
              addOnsButton={<S.AddOnLabel>min</S.AddOnLabel>}
              className={showError && !value ? 'invalid' : ''}
            />
          </S.SectionFormatAmrap>
        );

      case SECTION_FORMAT_KEY.INTERVAL:
        const totalDuration = _.sumBy(sectionDb.exercises, exercise => {
          return _.sumBy(exercise.supersets, superset => {
            const eachEide = superset.each_side ? 2 : 1; // TODO Use to double rest times
            return _.sumBy(superset.training_sets, set => {
              const duration = parseInt(_.get(set, 'duration.value', 0)) || 0;
              const rest = parseInt(_.get(set, 'rest.value', 0)) || 0;
              return (duration + rest) * eachEide;
            });
          });
        });

        const min = Math.floor(totalDuration / 60);
        const seconds = totalDuration % 60;

        return (
          <S.SectionFormatInterval data-tip data-for="section-format-interval">
            <p className="label">Duration (min)</p>
            <div className="inputContainer readOnly">
              <span>
                {min}
                {seconds ? ':' + `0${seconds}`.slice(-2) : ''}
              </span>
              <span>min</span>
            </div>
            <ReactTooltip id={`section-format-interval`} className="app-tooltip" effect="solid">
              <span>The duration is calculated based on the workout and rest times for each of the exercises</span>
            </ReactTooltip>
          </S.SectionFormatInterval>
        );

      case SECTION_FORMAT_KEY.TIMED:
        return (
          <S.SectionFormatTimed>
            <FormItem
              label="rounds"
              inputProps={{ min: 0 }}
              value={section.round}
              type="number"
              onChange={handleChangeTimeAndRound}
              addOnsButton={<S.AddOnLabel>{pluralize('round', section.round)}</S.AddOnLabel>}
              className={showError && !section.round ? 'invalid' : ''}
            />
          </S.SectionFormatTimed>
        );
      default:
        return null;
    }
  };

  const handleChangeTimeAndRound = event => {
    const { value } = event.target;
    const parsedValue = parseFloat(value, 10);
    const newData = {};

    if (section.format === SECTION_FORMAT_KEY.TIMED) {
      if (value > 99) {
        return;
      }

      newData.round = value !== 0 && isNaN(parseInt(parsedValue)) ? '' : parseInt(parsedValue);
    } else {
      if (value > 999) {
        return;
      }

      newData.time = isNaN(parsedValue) ? '' : value * 60;
    }

    props.updateEntity(ENTITY_NAME.Section, sectionId, newData);
  };

  const handleToggleAction = isIntersecting => {
    if (actionFixedRef.current) {
      actionFixedRef.current.style.visibility = isIntersecting ? 'hidden' : 'visible';
    }
  };

  const onUpdateInstructions = note => {
    props.updateEntity(ENTITY_NAME.Section, props.sectionId, { note });
  };

  return (
    <S.Wrapper
      className={classnames('sectionWrapper', {
        'sectionWrapper--hidden': section.type === HIDDEN_SECTION,
        'sectionWrapper--linkable': linkable,
        'sectionWrapper--dragmode': isDragMode,
        'sectionWrapper--minimize': minimize,
      })}
    >
      <div
        className={classnames({
          'sectionWrapper--freestyle': section.format === FREESTYLE,
        })}
      >
        {!isHiddenSection && (
          <>
            <S.InstructionContainer
              className={classnames(
                'section_instructionContainer',
                {
                  error: section.format === FREESTYLE && showError && !(section.note || '').trim().length,
                },
                { minimizeOfFreestyle: section.format === FREESTYLE && minimize },
              )}
            >
              <label>INSTRUCTIONS</label>
              <TextEditable
                value={section.note}
                onChange={_.debounce(onUpdateInstructions, 100)}
                placeholder="Add instructions"
                lineHeight={20}
                breakLine={true}
              />
              {handleRenderValidationInstruction()}
            </S.InstructionContainer>
            <S.WrapperBlockSelectSection>
              <S.BlockSelectSection>
                <S.BlockSelectSectionItem isFormatFreeStyle={section.format === FREESTYLE}>
                  <SelectSectionOption
                    label="Section format"
                    options={LIST_SECTION_FORMAT}
                    classNamePrefix="select-section-lib-prefix"
                    className="select-section-lib"
                    value={valueSectionFormat}
                    onChange={handleChangeSectionFormat}
                    isSearchable={false}
                    hasInfor
                  />
                </S.BlockSelectSectionItem>

                {dataSectionFormat !== SECTION_FORMAT_KEY.FREESTYLE && (
                  <S.BlockSelectSectionItem>
                    <SelectSectionOption
                      label="Section type"
                      options={LIST_SECTION_TYPE}
                      classNamePrefix="select-section-lib-prefix"
                      className="select-section-lib"
                      defaultValue={valueSectionType}
                      isSearchable={false}
                      onChange={handleChangeSectionType}
                    />
                  </S.BlockSelectSectionItem>
                )}

                {renderSectionFormat()}
              </S.BlockSelectSection>
              {showError && section.format === SECTION_FORMAT_KEY.TIMED && !section.round ? (
                <ErrorMessage className="workoutError--invalidSectionValue">
                  Please set number of rounds for the Timed section.
                </ErrorMessage>
              ) : null}
              {showError && section.format === SECTION_FORMAT_KEY.AMRAP && !section.time ? (
                <ErrorMessage className="workoutError--invalidSectionValue">
                  Please set a duration for the AMRAP section.
                </ErrorMessage>
              ) : null}
            </S.WrapperBlockSelectSection>

            <S.WrapperSectionFormatFreeStyle>
              {section.format === FREESTYLE && (
                <S.WrapUploadAndDragSection>
                  <UploadAndDragSection
                    onUpdateAttachments={onUpdateAttachments}
                    originalAttachments={section.attachments}
                    marginTop="15px"
                  />
                </S.WrapUploadAndDragSection>
              )}
              {section.format === FREESTYLE && !minimize && (
                <ExerciseList
                  exerciseReferences={exerciseReferences}
                  originalExerciseReferences={originalExerciseReferences}
                  onSave={setExerciseReferences}
                  onCancel={onRemoveAll}
                  onUpdatePrefrenceExercise={onUpdatePrefrenceExercise}
                  removeAllExerciseState={removeAllExerciseState}
                  setRemoveExerciseState={setRemoveExerciseState}
                />
              )}
            </S.WrapperSectionFormatFreeStyle>
          </>
        )}
        <SectionContext.Provider value={{ ...sectionData, sectionIndex }}>
          <S.Content className="section__content">
            {/* {section.format !== FREESTYLE && handleRenderValidation()} */}
            {section.format !== FREESTYLE && (
              <S.ExerciseList className="section__exerciseList">
                {section.exercises.map((exerciseId, index) => (
                  <React.Fragment key={exerciseId}>
                    <Exercise
                      exerciseId={exerciseId}
                      sectionId={sectionId}
                      exerciseIndex={index}
                      isHiddenSection={isHiddenSection}
                      exerciseSize={section.exercises.length}
                    />
                    {!isDragExercise && index < section.exercises.length - 1 && (
                      <LinkSet type={LINK_TYPES.LINK} sectionId={sectionId} exerciseId={exerciseId} linkIndex={index} />
                    )}
                  </React.Fragment>
                ))}
                {!section.exercises.length && (
                  <ExcerciseEmptyDroppable bottom sectionId={sectionId} exerciseIndex={0} />
                )}
              </S.ExerciseList>
            )}
            {handleRenderValidationExercise()}
            {!isHiddenSection && (
              <>
                <div className="section__actions">
                  <div className="section__moreExercises">
                    {minimize ? (
                      <span>
                        Inside:&nbsp;<span className="count">{handleTotalExerciseMinimize(section)}</span>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {section.format !== FREESTYLE && (
                  <AddActions onShow={handleToggleAction} onAddExercise={onAddExercise} />
                )}
              </>
            )}
          </S.Content>
          {section.format !== FREESTYLE && (
            <S.ActionFixedContainer ref={actionFixedRef}>
              <AddActions className="btn-actions" onAddExercise={onAddExercise} />
            </S.ActionFixedContainer>
          )}
        </SectionContext.Provider>
      </div>
    </S.Wrapper>
  );
}

export default Section;
