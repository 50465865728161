import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';

import InviteMemberForm from '../InviteMemberForm';
import ForumHeader from '../ForumHeader';
import StickyHeader from '../ForumHeader/StickyHeader';
import SearchInput from 'shared/SearchInput';
import { FORUM_AUTHOR_ROLE } from 'constants/commonData';

import { getUserShortName, encryptData, isTeamAdmin, convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import Button from 'shared/FormControl/Button';
import ConfirmModal from 'shared/ConfirmModal';
import { SharedTooltip } from 'shared/SharedTooltip';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_black.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat_grey_icon.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove_forum_member.svg';
import { ReactComponent as RedRemoveIcon } from 'assets/icons/remove_forum_member_red.svg';
import { ReactComponent as LoopIcon } from 'assets/icons/loop_icon.svg';
import TrashIcon from 'assets/icons/red_trash_circle.svg';
import { ReactComponent as NoClientsImage } from 'assets/images/no_clients.svg';

import * as S from './style';

/**
 * NOTE: Forum roles
  FORUM_AUTHOR_ROLE = 3;
  FORUM_ADMIN_ROLE = 2;
  FORUM_COACH_ROLE = 1;
  FORUM_CLIENT_ROLE = 0;
*/

const getFullName = member => {
  return `${member.first_name} ${member.last_name}`;
};

const isOwner = (member, user) => {
  return user._id === member._id;
};

const isCoach = member => {
  return member.role !== 0;
};

const isClient = member => {
  return member.role === 0;
};

export default function ForumTimeline(props) {
  const scrollTopRef = useRef();
  const container = useRef();
  let lastScrollTop = 0;

  useEffect(() => {
    const { getListUser, getGroupDetail, getConnectedLeaderboard } = props;
    getGroupDetail();
    getListUser();
    getConnectedLeaderboard && getConnectedLeaderboard();
  }, [props.match.params.id || props.match.params.forumId]);

  const handleTogglePopup = () => {
    props.toggleModal(true, <InviteMemberForm {...props} />);
  };

  const handleScroll = event => {
    event.persist();
    const { scrollTop } = event.target;

    if (scrollTop > 396) setSticky(true);
    if (scrollTop < 396) setSticky(false);

    if (scrollTopRef.current) {
      if (scrollTop > 324 || (isSticky && scrollTop > 10)) {
        scrollTopRef.current.classList.add('show');
      } else {
        scrollTopRef.current.classList.remove('show');
      }
    }

    lastScrollTop = scrollTop;
  };

  const handleScrollTop = () => {
    container.current.scrollTop = 0;
  };

  const handleChat = (e, member) => {
    e.stopPropagation();
    window.open(`/home/inbox?profileId=${member._id}&ufn=${encryptData(member.first_name)}`, '_blank');
  };

  const handleRemoveMember = async member => {
    await removeMember([member._id]);
    toast(`${getFullName(member)} has been removed from the Forum.`);
  };

  const handleTransferOwnership = async member => {
    await transferOwnership(member._id);
    toast(`The ownership has been transferred from you to ${getFullName(member)}.`);
  };

  const handleOpenDeletePopup = member => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        headerIcon={TrashIcon}
        title="Remove member"
        content={`Are you sure that you want to remove ${getFullName(member)}?`}
        onConfirm={() => handleRemoveMember(member)}
        confirmButtonTitle="Remove"
        hasCloseIcon
      />,
    );
  };

  const handleOpenTransferPopup = member => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        noIcon
        title="Transfer ownership"
        content={`Are you sure that you want to transfer ownership to ${getFullName(member)}?`}
        onConfirm={() => handleTransferOwnership(member)}
        confirmButtonTitle="Transfer ownership"
        hasCloseIcon
        confirmButtonClass="btn-purple"
      />,
    );
  };

  const [isSticky, setSticky] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { members, push, removeMember, transferOwnership, user, group } = props;

  // Move owner of post on top
  const coaches = [
    ..._.filter(members, member => isOwner(member, user)),
    ..._.filter(members, member => !isOwner(member, user) && member.role !== 0),
  ];

  // Handle Get Label of Role
  const handleGetLabelRole = role => {
    const { ADMIN, COACH, AUTHOR } = FORUM_AUTHOR_ROLE;
    let roleLabel = null;

    if (ADMIN.key === role) roleLabel = ADMIN.memberLabel;
    if (COACH.key === role) roleLabel = COACH.memberLabel;
    if (AUTHOR.key === role) roleLabel = AUTHOR.memberLabel;

    return roleLabel;
  };

  // All clients joined
  const clients = _.filter(members, member => {
    return member.role === 0 || (member.email === user.email && member._id !== user._id);
  });

  const isAuthor = group.author === user._id;
  const isClientRole = !isAuthor && user.teams[0].role === 1;
  const isAdmin = !isAuthor && user.teams[0].role === 2;

  const getCloudUrl = url => convertS3UrlToCloudFrontUrl(url, props.cloudfrontList, true);

  const renderSearchResults = () => {
    // Only search results should not include owner
    const searchResults = members.filter(val => {
      const fullName = `${val.first_name} ${val.last_name}`;
      try {
        const regex = new RegExp(searchTerm, 'i');
        if (searchTerm === '') {
          return val;
        } else if (regex.test(fullName) && !isOwner(val, user)) {
          return val;
        }
      } catch (error) {
        return val;
      }
    });

    return (
      <>
        <S.ResultsText>Results ({searchResults.length})</S.ResultsText>
        {searchResults.length > 0 ? (
          _.orderBy(searchResults, 'role', 'desc').map(member => (
            <S.MemberContainer key={member._id}>
              <S.MemberContainerContent>
                <div className="avatar-container">
                  <Avatar
                    className="client-detail-avatar"
                    size="60"
                    name={getUserShortName(member)}
                    src={getCloudUrl(member.avatar)}
                    color={member.color}
                  />
                </div>
                <S.MemberNameContainer>
                  <S.ProfileWrapper>
                    <span className="member-name">
                      {getFullName(member)}
                      {isOwner(member, user) && <S.RoleLabel> (You)</S.RoleLabel>}
                    </span>
                    {handleGetLabelRole(member.role) && <S.MemberRole>{handleGetLabelRole(member.role)}</S.MemberRole>}
                  </S.ProfileWrapper>
                </S.MemberNameContainer>
              </S.MemberContainerContent>
              {!isClientRole && isCoach(member) && (
                <S.Dropdown button icon="ellipsis horizontal" onOpen={() => {}}>
                  <S.Dropdown.Menu className="dropdown-menu more-actions">
                    {isAuthor && (
                      <S.Dropdown.Item onClick={() => handleOpenTransferPopup(member)}>
                        <S.DropItem>
                          <LoopIcon />
                          <span>Transfer Ownership</span>
                        </S.DropItem>
                      </S.Dropdown.Item>
                    )}
                    <S.Dropdown.Item onClick={() => handleOpenDeletePopup(member)}>
                      <S.DropItem remove>
                        <RedRemoveIcon />
                        <span>Remove</span>
                      </S.DropItem>
                    </S.Dropdown.Item>
                  </S.Dropdown.Menu>
                </S.Dropdown>
              )}
              <S.ButtonContainer>
                {!isCoach(member) && (isTeamAdmin(user) || member.isChat || member.isTeam) && (
                  <S.SButton grey onClick={e => handleChat(e, member)}>
                    <ChatIcon />
                  </S.SButton>
                )}

                {isClient(member) && (
                  <S.SButton grey onClick={() => handleOpenDeletePopup(member)}>
                    <RemoveIcon />
                  </S.SButton>
                )}
              </S.ButtonContainer>
            </S.MemberContainer>
          ))
        ) : (
          <S.NoData>
            <div className="title">No clients found</div>
            <div className="subtitle">Try another search</div>
          </S.NoData>
        )}
      </>
    );
  };

  const customTrigger = (
    <div data-tip data-for="coach-options-tooltip" className="coach-options-tooltip">
      <SharedTooltip id="coach-options-tooltip" />
    </div>
  );

  return (
    <S.Wrapper onScroll={handleScroll} ref={container}>
      <ForumHeader {...props} />
      {isSticky && <StickyHeader isSticky={isSticky} {...props} />}
      <S.Container>
        <S.ListContainer>
          <S.Header>
            <div>
              <S.Title>Members</S.Title>
              <S.HeaderSubtile>Here are members that have joined this forum.</S.HeaderSubtile>
            </div>

            <SearchInput
              className="search-input"
              onChange={event => setSearchTerm(event.target.value)}
              placeholder={'Search'}
            />
          </S.Header>
          <S.Content>
            {searchTerm.length ? (
              renderSearchResults()
            ) : (
              <>
                <S.Subtitle>
                  Coaches <span>&bull; {coaches.length}</span>
                </S.Subtitle>
                {coaches.length > 0 &&
                  coaches.map(member => (
                    <S.MemberContainer>
                      <S.MemberContainerContent>
                        <div className="avatar-container">
                          <Avatar
                            className="client-detail-avatar"
                            size="60"
                            name={getUserShortName(member)}
                            src={getCloudUrl(member.avatar)}
                            color={member.color}
                          />
                        </div>

                        <S.MemberNameContainer>
                          <S.ProfileWrapper>
                            <span className="member-name">
                              {getFullName(member)}
                              {isOwner(member, user) && <S.RoleLabel> (You)</S.RoleLabel>}
                            </span>
                            {handleGetLabelRole(member.role) && (
                              <S.MemberRole>{handleGetLabelRole(member.role)}</S.MemberRole>
                            )}
                          </S.ProfileWrapper>
                        </S.MemberNameContainer>
                      </S.MemberContainerContent>
                      {!isClientRole && !isOwner(member, user) && member.role !== 3 && (
                        <S.Dropdown button icon="ellipsis horizontal" trigger={customTrigger}>
                          <S.Dropdown.Menu className="dropdown-menu more-actions">
                            {isAuthor && (
                              <>
                                <S.Dropdown.Item onClick={() => handleOpenTransferPopup(member)}>
                                  <S.DropItem>
                                    <LoopIcon />
                                    <span>Transfer Ownership</span>
                                  </S.DropItem>
                                </S.Dropdown.Item>
                                <S.DropdownDivider />
                              </>
                            )}

                            <S.Dropdown.Item onClick={() => handleOpenDeletePopup(member)}>
                              <S.DropItem remove>
                                <RedRemoveIcon />
                                <span>Remove</span>
                              </S.DropItem>
                            </S.Dropdown.Item>
                          </S.Dropdown.Menu>
                        </S.Dropdown>
                      )}
                    </S.MemberContainer>
                  ))}
                <S.Divider />
                <S.Subtitle>
                  Clients <span>&bull; {clients.length}</span>
                </S.Subtitle>
                {clients.length > 0 ? (
                  clients.map(member => (
                    <S.MemberContainer>
                      <S.MemberContainerContent>
                        <div className="avatar-container">
                          <Avatar
                            className="client-detail-avatar"
                            size="60"
                            name={getUserShortName(member)}
                            src={getCloudUrl(member.avatar)}
                            color={member.color}
                          />
                          <S.MemberNameContainer>
                            <span className="member-name">{getFullName(member)}</span>
                            {handleGetLabelRole(member.role) && (
                              <S.MemberRole>{handleGetLabelRole(member.role)}</S.MemberRole>
                            )}
                          </S.MemberNameContainer>
                        </div>
                      </S.MemberContainerContent>

                      <S.ButtonContainer>
                        {(isTeamAdmin(user) || member.isChat || member.isTeam) && (
                          <S.SButton grey onClick={e => handleChat(e, member)} data-for="chat-icon-forum" data-tip>
                            <ChatIcon />
                          </S.SButton>
                        )}
                        <SharedTooltip id="chat-icon-forum" content="Messages client" className="tooltip-space" />

                        <S.SButton
                          grey
                          onClick={() => handleOpenDeletePopup(member)}
                          data-for="remove-client-icon-forum"
                          data-tip
                        >
                          <RemoveIcon />
                        </S.SButton>
                        <SharedTooltip id="remove-client-icon-forum" content="Remove" className="tooltip-space" />
                      </S.ButtonContainer>
                    </S.MemberContainer>
                  ))
                ) : (
                  <S.NoData>
                    <NoClientsImage />
                    <div className="title">No clients yet</div>
                    <span>This forum is only for the cool coaches</span>
                    <Button borderPurple onClick={handleTogglePopup}>
                      Invite first client
                    </Button>
                  </S.NoData>
                )}
              </>
            )}
          </S.Content>
        </S.ListContainer>

        <S.ScrollTop ref={scrollTopRef} onClick={handleScrollTop}>
          <ArrowUpIcon />
        </S.ScrollTop>
      </S.Container>
    </S.Wrapper>
  );
}
