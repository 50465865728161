import styled from 'styled-components';

export const AnswerContainerAssignToWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
`;

export const AnswerItemAssignToWrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: rgba(246, 246, 246, 1);

  .answer-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #323c47;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .icon-wrapper {
      margin-right: 8px;
      display: flex;
      position: relative;

      .warning-icon {
        cursor: pointer;
      }

      :hover {
        :before {
          content: '';
          position: absolute;
          width: 228px;
          height: 100px;
          bottom: 2px;
          left: -104px;
          background-color: transparent;
          z-index: 1;
        }
      }
    }
    .warning-rename-icon-tooltip {
      .__react_component_tooltip {
        &.app-tooltip {
          text-align: left;
          padding: 15px !important;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #bc7915;
          background: #ffeed4;
          strong {
            font-weight: 600;
          }
        }
        &.type-dark.place-top:after {
          border-top-color: #ffeed4;
        }

        .tooltip-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .button-wrapper {
            display: flex;
            justify-content: flex-end;

            .confirm-warning-button {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 8px;
              width: 35px;
              height: 20px;
              color: #bc7915;
              font-size: 13px;
              font-weight: 600;
              border: 1px solid #bc7915;
              border-radius: 4px;
              background-color: transparent;
              cursor: pointer;
              :hover {
                background: rgba(0, 0, 0, 0.04);
              }
            }
          }
        }
      }
    }
    &.is-deleted {
      color: #fd6666;
    }
  }

  .answer-assign-to-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;

    .left-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    .assign-to-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;

      color: #323c47;
    }
  }
`;
