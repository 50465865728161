import { axiosInstance } from 'configs/request';
import { get } from 'lodash';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import TextEditable from 'shared/TextEditable';

import * as S from '../styles';

export default function ShortAnswerSummary({ data }) {
  const [answers, setAnswers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const total = useRef(0);
  const params = {
    page: page,
    per_page: 20,
    sort: -1,
  };
  useEffect(() => {
    const originalQuestionId = get(data, 'question_original_id', null);
    if (!loading && originalQuestionId && originalQuestionId !== 'null') {
      setLoading(true);
      axiosInstance({
        url: `api/form-questions/${originalQuestionId}/text-answers`,
        method: 'GET',
        params: params,
      }).then(res => {
        const { data } = res.data;
        total.current = data.total;
        const newAnswers = [...answers, ...data.data];
        if (newAnswers.length === data.total) {
          setIsEnd(true);
        }
        setAnswers(newAnswers);
        setLoading(false);
      });
    }
  }, [page]);
  const handleScrollEnd = event => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
    if (bottom && !isEnd && !loading) {
      setPage(page + 1);
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);
  const [titleHeight, setTitleHeight] = useState(28); // default one line
  useEffect(() => {
    updateTitleHeight();
    window.addEventListener('resize', updateTitleHeight);
    return () => window.removeEventListener('resize', updateTitleHeight);
  }, []);
  const updateTitleHeight = () => {
    const headerElement = document.getElementById(`${data._id}-header`);
    if (headerElement) {
      setTitleHeight(headerElement.offsetHeight);
    }
  };
  return (
    <S.ShortAnswerSummary
      onScroll={event => {
        event.persist();
        onScrollDebounce.call(null, event);
      }}
      titleHeight={titleHeight}
    >
      {answers.map(answer => (
        <TextEditable key={answer._id} className="answer" readOnly value={answer.text_answer} />
      ))}
      {/* {loading && <LoadingIndicator className="loading" />} */}
    </S.ShortAnswerSummary>
  );
}
