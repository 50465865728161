import React from 'react';
import { Rectangle } from 'recharts';

const HeartRateBarShape = props => {
  const { x, y, width, height } = props;
  return (
    <g>
      <Rectangle
        x={x + (width / 2 - 3)}
        y={y}
        width={6}
        height={height + 4}
        radius={[3, 3, 3, 3]}
        fill="url(#colorUv)"
        className="bar-shape-custom"
      />
    </g>
  );
};

export default HeartRateBarShape;
