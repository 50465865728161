import React from 'react';
import { TaskItem } from './TaskItem';
import { Button } from 'shared/FormControl';
import { Droppable } from 'react-beautiful-dnd';
import { TaskListContainer } from '../styles';
import _ from 'lodash';
import { ReactComponent as NoTaskIcon } from 'assets/icons/no_task.svg';
import { LIST_OPTIONS } from '../constants';

const ListView = ({
  list,
  followingList,
  completedList,
  doUnmarkAsCompleted,
  doMarkAsCompleted,
  setTaskId,
  currentList,
  toTaskDetail,
}) => {
  const spreadTask = list => {
    return _.map(list, (task, index) => {
      return (
        <TaskItem
          index={index}
          key={task._id}
          task={task}
          currentList={currentList}
          doMarkAsCompleted={doMarkAsCompleted}
          setTaskId={setTaskId}
          toTaskDetail={toTaskDetail}
          doUnmarkAsCompleted={doUnmarkAsCompleted}
        />
      );
    });
  };
  switch (currentList) {
    case 0:
      if (_.isEmpty(list)) {
        return (
          <div className="no_task">
            <NoTaskIcon />
            <span>
              You’re all set.
              <br></br>
              No tasks to complete.
            </span>
          </div>
        );
      } else {
        return spreadTask(list);
      }
    case 2:
      if (_.isEmpty(completedList.list)) {
        return (
          <div className="no_task">
            <NoTaskIcon />
            <span>You don't have any completed tasks.</span>
          </div>
        );
      } else {
        return spreadTask(completedList.list);
      }
    case 1:
      if (_.isEmpty(followingList)) {
        return (
          <div className="no_task">
            <NoTaskIcon />
            <span>You are following no tasks right now</span>
          </div>
        );
      } else {
        return spreadTask(followingList);
      }
  }
};

export const TaskList = ({
  doUnmarkAsCompleted,
  followingList,
  list,
  completedList,
  doMarkAsCompleted,
  setTaskId,
  currentList,
  toTaskDetail,
}) => {
  return (
    <Droppable droppableId={LIST_OPTIONS[currentList].list_label}>
      {provided => (
        <TaskListContainer ref={provided.innerRef} v {...provided.droppableProps}>
          <ListView
            list={list}
            followingList={followingList}
            completedList={completedList}
            doMarkAsCompleted={doMarkAsCompleted}
            setTaskId={setTaskId}
            currentList={currentList}
            toTaskDetail={toTaskDetail}
            doUnmarkAsCompleted={doUnmarkAsCompleted}
          />
          {provided.placeholder}
        </TaskListContainer>
      )}
    </Droppable>
  );
};
