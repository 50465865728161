import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { Dropdown, Icon, Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import TaskHabit from '../../TaskHabit';
import BuilderContent from './BuilderContent';
import TaskComment from '../../HabitDetail/components/TaskComment';
import { AUTOFLOW_TYPES, CDN_URL, KEY_CODE } from 'constants/commonData';

import { ReactComponent as BackIcon } from 'assets/icons/arrow-left-black.svg';
import { ReactComponent as IconSave } from 'assets/icons/icon-save.svg';
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg';
import { ReactComponent as IconEnd } from 'assets/icons/icon-end.svg';
import * as S from './style';

const HabitsBuilderPopup = ({
  date,
  prevHabit,
  isEdit,
  onClose,
  clientId,
  habitUnits,
  addHabit,
  updateHabit,
  replace,
  toggleModal,
  toggleConfirmModal,
  removeCurrentHabit,
  saveHabitToLibrary,
  endCurrentHabit,
  clientTimezone,
  totalWeeks,
  autoflowType,
  autoflowId,
  addAutoflowIntervalHabit,
  removeAutoflowIntervalHabit,
  updateAutoflowIntervalHabit,
  saveAutoflowIntervalHabitToLibrary,
  addAutoflowHabit,
  removeAutoflowHabit,
  updateAutoflowHabit,
  saveAutoflowHabitToLibrary,
  showComment = true,
}) => {
  const contentRef = useRef();
  const [habit, setHabit] = useState(prevHabit);
  const [isShowComment, setIsShowComment] = useState(showComment);
  const [submitting, setSubmitting] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [invalidInput, setInvalidInput] = useState({
    title: false,
    goalValue: false,
  });
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(isEdit);
  const [uploadedAllComment, setUploadedAllComment] = useState(true);
  const disabledSave = submitting || invalidDate || invalidInput.title || invalidInput.goalValue;

  const params = new URLSearchParams(window.location.search);
  const openCommentTab = isEqual(params.get('comment'), 'show');
  const hasEnded = get(habit, 'has_ended', false);

  useEffect(() => {
    window.addEventListener('popstate', handleClose);

    return () => {
      window.removeEventListener('popstate', handleClose);
    };
  }, []);

  useEffect(() => {
    setIsShowComment(showComment);
  }, [showComment]);

  const onBack = () => {
    setIsShowComment(false);
    const isSame =
      contentRef.current && contentRef.current.isSameOriginData && contentRef.current.isSameOriginData(habit);
    if (isSame === false) {
      return toggleConfirmModal(
        true,
        <S.HabitConfirmModal
          noIcon
          title="Discard Changes?"
          content={
            isEmpty(prevHabit)
              ? 'Are you sure you want to go? Changes have not been saved yet.'
              : 'You have unsaved changes. Would you like to leave this page and discard your changes?'
          }
          confirmButtonTitle="Discard Changes"
          onConfirm={() => toggleModal(true, <TaskHabit date={date} autoflowType={autoflowType} />)}
          noBorder
          hasHoverState
        />,
      );
    }

    toggleModal(true, <TaskHabit date={date} autoflowType={autoflowType} />);
  };

  const handleClose = () => {
    toggleModal(false);
    onClose && onClose();
  };

  const handleCloseAction = () => {
    const isOpenGiphy = document.querySelector('.opened-giphy-popup');
    if (isOpenGiphy || open) return null;
    if (!uploadedAllComment) {
      return toggleConfirmModal(
        true,
        <S.HabitConfirmModal
          noIcon
          title="Upload in progress"
          content="If you close the pop-up, all incomplete uploads will be discarded. Would you like to exit without finishing?"
          onConfirm={handleClose}
          newStyle={true}
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          noBorder
          hasHoverState
        />,
      );
    }

    const isSame =
      contentRef.current && contentRef.current.isSameOriginData && contentRef.current.isSameOriginData(habit);
    if (isSame === false) {
      return toggleConfirmModal(
        true,
        <S.HabitConfirmModal
          noIcon
          title="Discard Changes?"
          content={
            isEmpty(prevHabit)
              ? 'Are you sure you want to go? Changes have not been saved yet.'
              : 'You have unsaved changes. Would you like to leave this page and discard your changes?'
          }
          confirmButtonTitle="Discard Changes"
          onConfirm={handleClose}
          noBorder
          hasHoverState
        />,
      );
    }

    handleClose();
  };

  const handleSaveToLibraryHabit = event => {
    event.preventDefault();
    if (autoflowType) {
      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        saveAutoflowIntervalHabitToLibrary(habit._id);
      } else {
        saveAutoflowHabitToLibrary(habit._id);
      }
    } else {
      saveHabitToLibrary(habit._id);
    }
  };

  const handleEnd = () => {
    get(habit, '_id') &&
      endCurrentHabit &&
      endCurrentHabit(habit._id, () => {
        onClose && onClose();
      });
  };

  const handleConfirmEndHabit = event => {
    event.preventDefault();
    toggleConfirmModal(
      true,
      <S.HabitConfirmModal
        noIcon
        title="End Habit"
        content="If you end this habit, all upcoming occurrences today onwards will be removed. Would you like to continue?"
        confirmButtonTitle="End habit"
        onConfirm={handleEnd}
        noBorder
        hasHoverState
      />,
    );
  };

  const handleRemove = () => {
    if (autoflowType) {
      if (autoflowType === AUTOFLOW_TYPES.INTERVAL) {
        removeAutoflowIntervalHabit && removeAutoflowIntervalHabit(get(habit, '_id'));
      } else {
        removeAutoflowHabit && removeAutoflowHabit(get(habit, '_id'));
      }
    } else {
      get(habit, '_id') && removeCurrentHabit && removeCurrentHabit(habit._id);
    }

    onClose && onClose();
  };

  const handleConfirmDeleteHabit = event => {
    event.preventDefault();
    let content =
      'If you delete this habit, all occurrences of this habit (in the past and future) and insights will be permanently lost. Would you like to continue?';
    let confirmButtonTitle = 'Delete habit entirely';

    if (autoflowType) {
      content =
        'If you delete this habit, all occurrences of this habit inside the Autoflow will be permanently lost, and all habits that have been assigned to your clients will be immediately ended. Would you like to continue?';
      confirmButtonTitle = 'Yes, delete';
    }
    toggleConfirmModal(
      true,
      <S.HabitConfirmModal
        noIcon
        title="Delete Habit"
        content={content}
        confirmButtonTitle={confirmButtonTitle}
        onConfirm={handleRemove}
        noBorder
        hasHoverState
      />,
    );
  };

  return (
    <S.TaskModal size={'tiny'} open={true} closeOnDimmerClick={false} onClose={handleCloseAction}>
      <S.TaskModalContainer>
        <S.TaskForm editMode={editMode}>
          <Modal.Header className="modal__header">
            {!editMode ? (
              <div className="button--back" onClick={onBack}>
                <BackIcon />
                <div className="text--back">Back</div>
              </div>
            ) : (
              <Dropdown
                text={<Icon name={'ellipsis horizontal'} color={'grey'} className="icon-more-action" />}
                pointing
                className="link item"
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSaveToLibraryHabit}>
                    <IconSave />
                    Save to Library
                  </Dropdown.Item>
                  {!habit.has_ended && !autoflowType && (
                    <Dropdown.Item onClick={handleConfirmEndHabit}>
                      <IconEnd className="icon-end" />
                      End Habit
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={handleConfirmDeleteHabit}>
                    <IconTrash />
                    Delete Habit
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <button className="close-button" onClick={handleCloseAction}>
              <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
            </button>
          </Modal.Header>
          <Modal.Content>
            <BuilderContent
              ref={contentRef}
              date={date}
              habitUnits={habitUnits}
              prevHabit={prevHabit}
              clientId={clientId}
              toggleModal={toggleModal}
              addHabit={addHabit}
              updateHabit={updateHabit}
              replace={replace}
              habit={habit}
              setHabit={setHabit}
              submitting={submitting}
              invalidDate={invalidDate}
              invalidInput={invalidInput}
              editMode={editMode}
              setSubmitting={setSubmitting}
              setInvalidDate={setInvalidDate}
              setInvalidInput={setInvalidInput}
              setEditMode={setEditMode}
              clientTimezone={clientTimezone}
              open={open}
              setOpen={setOpen}
              totalWeeks={totalWeeks}
              autoflowType={autoflowType}
              autoflowId={autoflowId}
              addAutoflowIntervalHabit={addAutoflowIntervalHabit}
              updateAutoflowIntervalHabit={updateAutoflowIntervalHabit}
              addAutoflowHabit={addAutoflowHabit}
              updateAutoflowHabit={updateAutoflowHabit}
              setIsShowComment={setIsShowComment}
            />
          </Modal.Content>
          {!hasEnded && (
            <Modal.Actions className="modal__actions">
              <Button
                disabled={disabledSave}
                className="actions__saveClose"
                onClick={() => contentRef.current && contentRef.current.handleSave(false)}
              >
                Save
              </Button>
              <Button
                disabled={disabledSave}
                purple
                className={classnames('actions__save', {
                  disabled: disabledSave,
                })}
                onClick={() => contentRef.current && contentRef.current.handleSave(true)}
              >
                {'Save & Close'}
              </Button>
            </Modal.Actions>
          )}
        </S.TaskForm>
        {get(habit, 'enable_comment', false) && isShowComment && (
          <TaskComment
            task={habit}
            onChangeUploadStatus={noUploadedComments => setUploadedAllComment(!noUploadedComments.length)}
            openCommentTab={openCommentTab}
          />
        )}
      </S.TaskModalContainer>
    </S.TaskModal>
  );
};

export default HabitsBuilderPopup;
