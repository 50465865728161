import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Image, Icon } from 'semantic-ui-react';

import styled from 'styled-components';
import { deleteClients } from 'redux/autoflow/client/actions';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import FixedDropdown, { Option } from 'shared/Dropdown/Basic';
import {
  RemoveAutoflowClientModal,
  AutoflowPausedWarningModal,
  ActivateClientsModal,
  PauseClientsModal,
  MaskAsCompleteModal,
} from 'components/AutoflowConfirmModals';
import { AUTOFLOW_STATUS, AUTOFLOW_CLIENT_STATUS, CDN_URL } from 'constants/commonData';

function RemoveClients(props) {
  const { workingAutoflow, clients, status } = props;

  if (!clients.length || !workingAutoflow) {
    return null;
  }

  return (
    <Container>
      <FixedDropdown
        triggerIcon={() => (
          <Button className="">
            <Image src={`${CDN_URL}/images/setting_fill_grey.svg`} />
            <span>Actions</span>
            <Icon className="chevron down" />
          </Button>
        )}
        type="light"
      >
        {status !== AUTOFLOW_CLIENT_STATUS.ACTIVE && (
          <Option
            onClick={() => {
              if (workingAutoflow.status !== AUTOFLOW_STATUS.ACTIVATED) {
                props.toggleModal(true, <AutoflowPausedWarningModal multiple />);
              } else {
                props.toggleModal(true, <ActivateClientsModal multiple clients={clients} />);
              }
            }}
          >
            <div className="image-container">
              <Image src={`${CDN_URL}/images/play_fill_grey.svg`} />
            </div>
            <span>Activate</span>
          </Option>
        )}
        {workingAutoflow.status === AUTOFLOW_STATUS.ACTIVATED &&
          status !== AUTOFLOW_CLIENT_STATUS.PAUSE &&
          status !== AUTOFLOW_CLIENT_STATUS.WAITING_TO_START && (
            <Option onClick={() => props.toggleModal(true, <PauseClientsModal multiple clients={clients} />)}>
              <div className="image-container">
                <Image src={`${CDN_URL}/images/pause_fill_grey.svg`} />
              </div>
              <span>Pause</span>
            </Option>
          )}
        {status !== AUTOFLOW_CLIENT_STATUS.WAITING_TO_START && (
          <Option onClick={() => props.toggleModal(true, <MaskAsCompleteModal multiple clients={clients} />)}>
            <div className="image-container">
              <Image src={`${CDN_URL}/images/mark_as_completed.svg`} />
            </div>
            <span>Mark as Complete</span>
          </Option>
        )}
        <Option onClick={() => props.toggleModal(true, <RemoveAutoflowClientModal multiple clients={clients} />)}>
          <div className="image-container">
            <Image src={`${CDN_URL}/images/trash_bordered_grey.svg`} />
          </div>
          <span>Remove</span>
        </Option>
      </FixedDropdown>
    </Container>
  );
}

const mapStateToProps = state => {
  const { autoflow } = state.rootReducer;
  const { common, client } = autoflow;
  const { workingAutoflow } = common;
  const {
    selectedClients,
    query: { status },
  } = client;

  return {
    workingAutoflow,
    clients: selectedClients,
    status: status,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteClients: bindActionCreators(deleteClients, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveClients);

const Container = styled.div`
  display: inline-block;

  ${Button} {
    display: inline-flex;
    width: 120px;
    padding-left: 15px;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;

    i.icon {
      line-height: 9px;
      color: #a1a1a5;
      font-size: 9px;
    }
  }

  .evf-dropdown__menu {
    width: 200px;
  }

  .evf-dropdown__option {
    padding: 7px 17px;

    .image-container {
      margin-right: 14px;
      width: 14px;
    }
  }
`;
