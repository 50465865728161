import _ from 'lodash';
import Request from 'configs/request';

export const Types = {
  FOOD_JOURNAL_UNMOUNT: 'FOOD_JOURNAL_UNMOUNT',
  FOOD_JOURNAL_GET_LIST: 'FOOD_JOURNAL_GET_LIST',
  FOOD_JOURNAL_SUCCESS_GET_LIST: 'FOOD_JOURNAL_SUCCESS_GET_LIST',
  FOOD_JOURNAL_FAILED_GET_LIST: 'FOOD_JOURNAL_FAILED_GET_LIST',
  FOOD_JOURNAL_GET_COMMENTS: 'FOOD_JOURNAL_GET_COMMENTS',
  FOOD_JOURNAL_SUCCESS_GET_COMMENTS: 'FOOD_JOURNAL_SUCCESS_GET_COMMENTS',
  FOOD_JOURNAL_FAILED_GET_COMMENTS: 'FOOD_JOURNAL_FAILED_GET_COMMENTS',
  FOOD_JOURNAL_ADD_COMMENT: 'FOOD_JOURNAL_ADD_COMMENT',
  FOOD_JOURNAL_SUCCESS_ADD_COMMENT: 'FOOD_JOURNAL_SUCCESS_ADD_COMMENT',
  FOOD_JOURNAL_FAILED_ADD_COMMENT: 'FOOD_JOURNAL_FAILED_ADD_COMMENT',
  FOOD_JOURNAL_LIKE_UNLIKE_POST: 'FOOD_JOURNAL_LIKE_UNLIKE_POST',
  FOOD_JOURNAL_SUCCESS_LIKE_UNLIKE_POST: 'FOOD_JOURNAL_SUCCESS_LIKE_UNLIKE_POST',
  FOOD_JOURNAL_FAILED_LIKE_UNLIKE_POST: 'FOOD_JOURNAL_FAILED_LIKE_UNLIKE_POST',
  FOOD_JOURNAL_GET_POST_DETAIL: 'FOOD_JOURNAL_GET_POST_DETAIL',
  FOOD_JOURNAL_SUCCESS_GET_POST_DETAIL: 'FOOD_JOURNAL_SUCCESS_GET_POST_DETAIL',
  FOOD_JOURNAL_FAILED_GET_POST_DETAIL: 'FOOD_JOURNAL_FAILED_GET_POST_DETAIL',
  FOOD_JOURNAL_GET_DATA_FROM_LOCAL: 'FOOD_JOURNAL_GET_DATA_FROM_LOCAL',
  FOOD_JOURNAL_FAILED_GET_DATA_FROM_LOCAL: 'FOOD_JOURNAL_FAILED_GET_DATA_FROM_LOCAL',
  FOOD_JOURNAL_UPDATE_POSTS_AFTER_VIEW_DETAIL: 'FOOD_JOURNAL_UPDATE_POSTS_AFTER_VIEW_DETAIL',
  FOOD_JOURNAL_HIGH_FIVE_REQUEST: 'FOOD_JOURNAL_HIGH_FIVE_REQUEST',
  FOOD_JOURNAL_HIGH_FIVE_SUCCESS: 'FOOD_JOURNAL_HIGH_FIVE_SUCCESS',
  FOOD_JOURNAL_HIGH_FIVE_FAILED: 'FOOD_JOURNAL_HIGH_FIVE_FAILED',
};

export const unmount = () => ({ type: Types.FOOD_JOURNAL_UNMOUNT });

export const updatePostsAfterViewDetail = (postId, data) => ({
  type: Types.FOOD_JOURNAL_UPDATE_POSTS_AFTER_VIEW_DETAIL,
  payload: { postId, data },
});

export const getPosts = params => {
  return dispatch => {
    dispatch({ type: Types.FOOD_JOURNAL_GET_LIST });

    return dispatch(
      Request.get(
        { url: '/api/food_journal/list', params },
        true,
        response => {
          const { data, page, total } = response.data;
          dispatch({ type: Types.FOOD_JOURNAL_SUCCESS_GET_LIST, payload: { data, page, total } });
        },
        () => dispatch({ type: Types.FOOD_JOURNAL_FAILED_GET_LIST }),
      ),
    );
  };
};

export const likePost = (postId, from = 'list') => {
  return dispatch => {
    dispatch({ type: Types.FOOD_JOURNAL_LIKE_UNLIKE_POST });

    return dispatch(
      Request.put(
        { url: `/api/food_journal/like/${postId}` },
        false,
        response => {
          const { data } = response.data;
          dispatch({ type: Types.FOOD_JOURNAL_SUCCESS_LIKE_UNLIKE_POST, payload: { data, postId, from } });
        },
        () => dispatch({ type: Types.FOOD_JOURNAL_FAILED_LIKE_UNLIKE_POST }),
      ),
    );
  };
};

export const succesGetPostDetail = (data, from) => ({
  type: Types.FOOD_JOURNAL_SUCCESS_GET_POST_DETAIL,
  payload: { data, from },
});

export const getPostDetail = postId => {
  return dispatch => {
    dispatch({ type: Types.FOOD_JOURNAL_GET_POST_DETAIL });

    return dispatch(
      Request.get(
        { url: `/api/food_journal/detail/${postId}` },
        false,
        response => {
          const { data } = response.data;
          dispatch(succesGetPostDetail(data, 'api'));
        },
        () => dispatch({ type: Types.FOOD_JOURNAL_FAILED_GET_POST_DETAIL }),
      ),
    );
  };
};

export const getComments = pId =>
  Request.get({ url: '/api/comment/list', params: { topic: 'food_journal', item: pId } }, true);

export const addCommentToPost = data => Request.post({ url: '/api/comment/add', data }, true);

export const highFiveFoodJournal = id => {
  return dispatch => {
    dispatch({ type: Types.FOOD_JOURNAL_HIGH_FIVE_REQUEST });
    return dispatch(
      Request.post(
        { url: `/api/v2/food_journal/${id}/high-five` },
        true,
        result => {
          const { success } = result.data;
          if (success) {
            dispatch({
              type: Types.FOOD_JOURNAL_HIGH_FIVE_SUCCESS,
              payload: success,
            });
            dispatch(getPostDetail(id));
          }
        },
        () => {
          dispatch({ type: Types.FOOD_JOURNAL_HIGH_FIVE_FAILED });
        },
      ),
    );
  };
};
