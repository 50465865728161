import styled, { css } from 'styled-components';

const createCSS = numOfRows => {
  let styles = 'grid-template-rows: ';
  const minus = `${(numOfRows - 1) * 10}px`;
  styles += `repeat(${numOfRows}, calc((100% - ${minus}) / ${numOfRows}));`;
  return styles;
};

export const FeatureTurnOff = styled.div`
  display: flex;
  background: #fffae6;
  padding: 3px 10px;
  border-radius: 6px;
  border: 1px solid #f3ce8a;
  color: #eea620;
  align-items: center;
  font-size: 12px;

  img.ui {
    margin-right: 5px;
  }
`;

export const DaysContainer = styled.div`
  padding: 0 25px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 10px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  color: #6a7988;
  font-weight: 600;
`;

export const CopyWrapper = styled.div`
  position: absolute;
  height: 100%;
  left: 3px;
  width: 24px;
  align-items: center;
  justify-content: center;
  display: none;
  flex-direction: column;
  gap: 22px;
  cursor: pointer;

  :hover {
    background-color: #e7e8ff;
    border-radius: 2px;
    border-right-color: transparent !important;
  }

  svg:hover {
    path {
      stroke: #5158cf;
    }
  }

  #copy-week-on-tooltip,
  #remove-week-on-tooltip {
    padding: 5px 15px !important;
    border-radius: 5px !important;
    background: #2d2e2d !important;

    div {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #ffffff;
    }
  }

  .__react_component_tooltip.place-right {
    margin-left: 13px;
  }

  .__react_component_tooltip.type-dark.place-right:after {
    left: -5px;
  }
`;

export const PasteWeek = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 60px);
  background-color: rgba(108, 115, 154, 0.1);
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: none;
  border-radius: 2px;
`;

export const PasteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 34px;
  background: linear-gradient(94.6deg, #0c8aff 71.76%, #60b3ff 104.81%);
  border-radius: 5px;
  font-family: 'Open Sans';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
`;

export const CalendarRows = styled.div`
  display: grid;
  row-gap: 10px;
  padding: 10px 0;
  grid-row: 2/4;
  ${props => createCSS(props.viewMode)};

  .calendar__row {
    padding: 0 30px;
    position: relative;
    overflow: visible;
    max-width: calc(100vw - 60px);
    &:hover {
      ${CopyWrapper} {
        display: flex;
        border-right: 1px solid #202353;
      }
      ${PasteWeek} {
        display: flex;
      }
    }

    .week-index {
      position: absolute;
    }

    .calendar__cells {
      display: grid;
      grid-template-columns: repeat(7, calc((100% - 60px) / 7));
      column-gap: 10px;
      grid-template-rows: 100%;
      height: 100%;
    }
  }

  ${props =>
    props.isCopingWeek &&
    css`
      .add-workout-button {
        display: none !important;
        visibility: hidden !important;
      }
    `}
`;

export const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 25px 15px;
  min-height: 58px;

  .calendar-navbar__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .button--today {
      background: #f3f5f8;
      border-radius: 3px;
      font-weight: bold;
      line-height: 16px;
      font-size: 12px;
      text-align: center;
      color: #728096;
      padding: 5px 10px;
      width: 86px;
      outline: none !important;
      box-shadow: none !important;
      margin-right: 10px;
      border: none;
      cursor: pointer;
    }
  }

  .calendar-navbar__center {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
  }
`;

export const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row: 2/4;
  position: relative;
`;

export const Wrapper = styled.div`
  flex: 1 0;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;

  .calendar--max-select.ui.modal.confirm-popup-container {
    width: 467px !important;
    height: 200px;
  }

  .calendar--max-select.ui.modal.confirm-popup-container > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .calendar--max-select.ui.modal.confirm-popup-container > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .calendar--max-select .confirm-content-header {
    padding: 30px 30px 0;
  }

  .calendar--max-select .confirm-content-body {
    padding: 15px 30px 30px;
  }

  .calendar--max-select .confirm-header-image {
    width: 25px;
    height: 25px;
  }
  .calendar--max-select .confirm-content-header > p {
    line-height: 25px;
    color: #000000;
  }

  .calendar--max-select .ui.button.confirm-yes-button {
    font-family: 'Open Sans';
    font-style: normal;
    padding: 5px 30px;
    background-color: #5158cf;
    box-shadow: unset;
    width: 92px;
    min-width: unset;
  }

  .calendar--max-select .ui.button.confirm-yes-button:hover {
    opacity: 0.9;
    box-shadow: unset;
  }
`;

export const BottomCopyWeekWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 23px 30px 22px;
  z-index: 11;
  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
  }
  .cancel-copy {
    cursor: pointer;
    margin-left: 15px;
  }
`;

export const BottomSelectWorkoutWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px 30px;
  z-index: 11;
`;

export const BottomNumberSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  gap: 5px;
`;

export const BottomNumber = styled.span`
  height: 21px;
  width: 43px;
  background-color: #303030;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
`;

export const BottomLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const AlertBottomWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0px -5px 16px rgba(129, 129, 129, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 22px;
  z-index: 1;

  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
  }

  .cancel-copy {
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const SelectedWorkoutNumber = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 43px;
  height: 21px;
  background: #303030;
  border-radius: 999px;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 16px;
  color: white !important;
  margin-right: 5px;
`;

export const SelectedWorkoutAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .tooltip--remove,
  .tooltip--copy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
`;

export const IconActions = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextActions = styled.span`
  color: #728096;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

export const BottomActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    ${IconActions} {
      svg {
        path {
          fill: #5e59ff;
        }
      }
    }
    ${TextActions} {
      color: #5e59ff;
    }
  }
`;

export const BottomActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
  gap: 40px;

  .tooltip--remove,
  .tooltip--copy {
    width: 100px;
  }
`;

export const BottomCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  gap: 15px;
  svg {
    cursor: pointer;
  }
`;

export const EscText = styled.strong``;

export const TextCancel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
`;

export const CancelBold = styled.span`
  font-weight: 700;
`;
