import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';
import { Image } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

export const ContentWrapper = styled.div`
  display: block;
  padding-left: 16px;
  padding-right: 16px;

  ${props =>
    props.contentCenter &&
    css`
      text-align: center;
    `}
`;

export const MobileWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  padding-top: 32px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &:before {
    content: '';
    background-color: #efff39;
    height: 487px;
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const TitleWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
`;

export const DescriptionWrapper = styled.div`
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  color: #000000;
  /* margin-bottom: 26px; */
  margin-bottom: 14px;

  &:after {
    content: '';
    background: url(${CDN_URL}/images/mobile-onboarding-bg-title.svg) no-repeat;
    width: 229px;
    height: 39px;
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    margin: 0 auto;
  }
`;

export const FeaturesBannerImage = styled(Image)`
  z-index: 1;
  position: relative;
`;

export const SuggestDownloadTittleWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #1e0a3c;
  /* margin-top: -24px; */
  margin-top: -17px;
  margin-bottom: 16px;
  z-index: 2;
  position: relative;
`;

export const ButtonSend = styled(Button)`
  background: ${props => (props.isSent ? 'rgba(206, 204, 254, 0.4)' : '#5e59ff')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover,
  &:focus,
  &:active {
    background: ${props => (props.isSent ? 'rgba(206, 204, 254, 0.4)' : 'rgba(85, 89, 255, 0.9)')};
  }
  border-radius: 100px;
  border: none;
  padding: 12px 0;
  width: 100%;

  @supports (-webkit-tap-highlight-color: transparent) {
    & {
      -webkit-tap-highlight-color: transparent;
    }
  }

  > img {
    margin-right: 8px;
  }

  > span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: ${props => (props.isSent ? '#3C39A3' : '#ffffff')};
  }

  .hidden {
    display: none !important;
  }
`;

export const IntroductionWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1e0a3c;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;

  > div {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: unset;
    }
  }
`;

export const SuggestFeaturesWeb = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #1e0a3c;
  margin-top: 40px;
`;

export const SuggestCoachAppTittleWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #1e0a3c;
  margin-top: 40px;
  margin-bottom: 48px;
`;

export const ButtonStart = styled(Button)`
  background: ${props => (props.disabled ? '#c4c4c4' : '#5e59ff')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover,
  &:focus,
  &:active {
    background: ${props => (props.disabled ? '#c4c4c4' : 'rgba(85, 89, 255, 0.9)')};
  }
  border-radius: 50px;
  border: none;
  padding: 12px 96px;
  margin-top: 40px;
  margin-bottom: 70px;

  @supports (-webkit-tap-highlight-color: transparent) {
    & {
      -webkit-tap-highlight-color: transparent;
    }
  }

  > span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;
  }
`;
