import React from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import * as S from './style';

const LoadingIndicator = ({ title = 'Loading...', size = 'small', className = '' }) => {
  return (
    <S.IndicatorWrapper className={className}>
      <Loader active inline="centered" className="custom-indicator" size={size}>
        {!!title && <span>{title}</span>}
      </Loader>
    </S.IndicatorWrapper>
  );
};

LoadingIndicator.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
};

export default LoadingIndicator;
