import React, { useState } from 'react';

import MoreAction from './MoreAction';
import AddGoalPopup from '../AddGoalPopup';
import { GoalTypeLabels, GoalTypes } from '../../constants.js';
import { GoalItemStyled } from './styles.js';

const GoalItem = ({ currentGoal, type = GoalTypes.general, toggleConfirmModal }) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouse = isEnter => () => setIsHovering(isEnter);
  const handleEdit = event => {
    event.persist();
    event.preventDefault();
    toggleConfirmModal(true, <AddGoalPopup isEdit currentGoal={currentGoal} />);
  };

  return (
    <GoalItemStyled onMouseEnter={handleMouse(true)} onMouseLeave={handleMouse(false)} onClick={handleEdit}>
      <MoreAction />
      <div className="goal-type">{GoalTypeLabels[type]}</div>
      <div className="goal-text">{currentGoal}</div>
    </GoalItemStyled>
  );
};

export default GoalItem;
