import styled from 'styled-components';

import ConfirmModal from 'shared/ConfirmModal';

const baseText = `
    font-family: Open Sans;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    color: #000;
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container.ai-beta-agreement-confirm-modal {
    width: 466px !important;
    .close-button {
      right: -10px;
    }
    .confirm-content-header {
      padding: 30px 30px 0;
      .confirm-header-label {
        ${baseText}
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
      }
    }
    .confirm-content-body {
      padding: 15px 30px 30px;
      .confirm-content-label {
        ${baseText}
        font-size: 13px;
        line-height: 20px;
        color: #4a4d56;
      }
    }
    .confirm-actions {
      .button {
        ${baseText}
        font-style: 13px;
        font-weight: 600;
        line-height: 20px;
        padding: 4px 29px;
        border-radius: 5px;
        height: 30px;
      }
      .ui.button.confirm-no-button {
        color: #818da1;
        min-width: unset;
        &:hover {
          background-color: rgba(255, 255, 255, 0.9) !important;
        }
      }
      .ui.button.confirm-yes-button {
        box-shadow: none;
        background-color: #5158cf;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
          box-shadow: none;
          background-color: rgba(81, 88, 207, 0.9);
        }
      }
    }
  }
`;
