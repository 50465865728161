import styled from 'styled-components';

export const NotGroups = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;
