import React from 'react';
import _ from 'lodash';
import { Modal, Button as CloseButton } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import * as S from './style';
import { push } from 'connected-react-router';
import ResourceItem from './ResourceItem';
import { Button } from 'shared/FormControl';
import { axiosInstance } from 'configs/request';
import { addResourcesToRedux } from 'redux/studio-collection/actions';
import { CDN_URL } from 'constants/commonData';

class AddResourcesPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      textSearch: '',
      selectedResource: {},
      page: 1,
      total: 0,
    };
    this.handleScrollDebounce = _.debounce(this.handleScrollDebounce, 300);
    this.searchDebounce = _.debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.handleSearch();
  }

  handleSearch = value => {
    const { resources } = this.props;
    const textSearch = value ? value : '';
    this.setState({ textSearch, isLoading: true });
    const resourceIds = _.map(resources, '_id');

    const queryParams = {
      page: 1,
      per_page: 15,
      search: textSearch || '',
      excludeIds: resourceIds,
      is_published_form: true,
    };

    axiosInstance
      .post('/api/studio-resource/fetch-by-trainer', queryParams)
      .then(response => {
        const { data, total } = response.data;
        this.setState({
          searchResults: data.filter(o => !resourceIds.includes(o._id)),
          page: 1,
          hasMore: data.length,
          total: total,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  loadMore = () => {
    const { resources } = this.props;
    const { textSearch, page, searchResults } = this.state;
    const resourceIds = _.map(resources, '_id');

    const queryParams = {
      page: page + 1,
      per_page: 15,
      search: textSearch || '',
      excludeIds: resourceIds,
      is_published_form: true,
    };
    this.setState({ isLoading: true });
    axiosInstance
      .post('/api/studio-resource/fetch-by-trainer', queryParams)
      .then(response => {
        const { data } = response.data;
        this.setState({
          searchResults: [...searchResults, ...data.filter(o => !resourceIds.includes(o._id))],
          page: response.data.page,
          hasMore: data.length,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleScroll = event => {
    event.persist();
    this.handleScrollDebounce.call(null, event);
  };

  handleScrollDebounce = event => {
    const { scrollLeft, clientWidth, scrollWidth } = event.target;
    const { hasMore, isLoading } = this.state;
    const bottom = scrollWidth - (scrollLeft + clientWidth);
    if (hasMore && bottom < 200 && !isLoading) {
      this.loadMore();
    }
  };

  onSelectResource = program => {
    const { selectedResource } = this.state;
    const { resources_per_collection } = this.props;
    const noSelected = this.countSelectedResource();
    if (noSelected >= resources_per_collection && !selectedResource[program._id]) {
      return;
    }
    selectedResource[program._id] = !selectedResource[program._id];
    this.setState({
      selectedResource: { ...selectedResource },
    });
  };

  onClearSearch = () => {
    this.setState({
      textSearch: '',
      searchResults: [],
    });
    this.handleSearch();
  };

  handleClose = () => {
    this.props.toggleModal(false);
  };

  onSubmit = () => {
    this.setState({ isSubmitting: true });
    const { selectedResource, searchResults } = this.state;
    const { section } = this.props;
    const resources = _.filter(searchResults, o => selectedResource[o._id]);
    this.props.addResourcesToRedux(section._id, resources);
    this.handleClose();
  };

  countSelectedResource = () => {
    const { selectedResource } = this.state;
    const { resources } = this.props;
    const noSelected = _.values(selectedResource).filter(o => !!o).length;
    return noSelected + resources.length;
  };

  onSearch = (event, { value }) => {
    const trimmed = value.toLowerCase().trim();
    this.handleSearch(trimmed);
  };

  render() {
    const { searchResults, selectedResource, isSubmitting, textSearch, total } = this.state;
    const { resources_per_collection } = this.props;

    const noSelected = this.countSelectedResource();
    const isSelected = _.values(selectedResource).filter(o => !!o).length > 0;
    return (
      <S.AddResourcesPopupModal
        open={true}
        closeOnDimmerClick={false}
        onClose={() => this.props.toggleModal(false)}
        closeIcon={
          <CloseButton className="close-button">
            <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
          </CloseButton>
        }
      >
        <Modal.Header>
          <div className="header__title">
            Choose your resources
            <div className="header__title--description">{`Maximum of ${resources_per_collection} resources per section`}</div>
          </div>
          <S.SearchResourceInput
            defaultValue={textSearch}
            onChange={this.searchDebounce}
            onClearSearch={this.onClearSearch}
            placeholder="Search resource name"
          />
        </Modal.Header>
        <Modal.Content>
          <div className="resourceResult__title">
            <div>All Resources ({total})</div>
            <div className="resourceResult__title--selectedNumber">
              {noSelected > 0 ? `${noSelected}/${resources_per_collection} selected` : null}
            </div>
          </div>
          <S.ResourceResultContainer onScroll={this.handleScroll}>
            {!searchResults.length && <div className="resourceResult__noResult">No results</div>}
            {searchResults.map(item => {
              const isGrayOutList = noSelected >= resources_per_collection && !selectedResource[item._id];
              return (
                <ResourceItem
                  key={item._id}
                  resource={item}
                  onSelect={this.onSelectResource}
                  active={selectedResource[item._id]}
                  isGrayOutList={isGrayOutList}
                />
              );
            })}
          </S.ResourceResultContainer>
        </Modal.Content>
        <Modal.Actions>
          <Button className="actions__createBtn" disabled={!isSelected || isSubmitting} purple onClick={this.onSubmit}>
            Add
          </Button>
        </Modal.Actions>
      </S.AddResourcesPopupModal>
    );
  }
}

const mapStateToProps = state => {
  const {
    rootReducer: { permission = {} },
  } = state;

  return {
    resources_per_collection: permission.resources_per_collection || 25,
  };
};

const actionCreators = { toggleModal, push, addResourcesToRedux };

export default connect(mapStateToProps, actionCreators)(AddResourcesPopup);
