import React from 'react';
import PropTypes from 'prop-types';

import { SELECTED_CLIENT_TYPE, TAB_MISSED, WORKOUT_COMPLETION_TAB_ITEMS } from '../constants';
import { ReactComponent as BroadcastIcon } from 'assets/icons/broadcast_icon.svg';
import BroadcastMessage from 'shared/BroadcastMessage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal, toggleSecondModal } from 'actions/modal';
import { resetBroadcastMessageState } from 'redux/broadcast-messages/actions';
import UpgradeStudioPlanModal from './StudioUpgradeModal';
import { getAllMissedWorkoutSummaries } from 'redux/workout-completion/actions';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import * as S from '../style';

const Header = props => {
  const {
    tab,
    setTab,
    startDate,
    isLoading,
    ownerFilter,
    toggleModal,
    totalMissed,
    totalCompleted,
    selectedClients,
    beforeCloseModal,
    toggleSecondModal,
    hasBroadcastPermission,
    getAllMissedWorkoutSummaries,
  } = props;

  const getClients = (clients, filterUnread = false) => {
    if (!filterUnread) {
      return clients.map(item => {
        return get(item, 'client');
      });
    }

    const filtered = clients.filter(item => {
      return !get(item, 'has_unread_message', false);
    });

    return filtered.map(item => {
      if (!get(item, 'has_unread_message', false)) {
        return get(item, 'client');
      }
    });
  };

  const handleSelectTab = event => {
    const { status } = event.currentTarget && event.currentTarget.dataset;
    !isLoading && setTab(Number(status));
  };

  const handleBroadcastMessage = status => {
    const params = {
      day: startDate,
      owner_ship: ownerFilter,
    };

    switch (status) {
      case SELECTED_CLIENT_TYPE.allMissed:
        getAllMissedWorkoutSummaries(params, res => {
          handleOpenBroadcastModal(getClients(res), SELECTED_CLIENT_TYPE.allMissed);
        });
        break;
      case SELECTED_CLIENT_TYPE.withoutUnread:
        getAllMissedWorkoutSummaries(params, res => {
          handleOpenBroadcastModal(
            getClients(res, true).filter(client => !get(client, 'has_unread_message', false)),
            SELECTED_CLIENT_TYPE.withoutUnread,
          );
        });
        break;
      default:
        handleOpenBroadcastModal(selectedClients);
        break;
    }
  };

  const handleCloseBroadcastModal = () => {
    resetBroadcastMessageState();
    toggleModal && toggleModal(false);
    beforeCloseModal && beforeCloseModal();
  };

  const handleOpenBroadcastModal = (selectedClientIds, type) => {
    getAllMissedWorkoutSummaries(
      {
        day: startDate,
        owner_ship: ownerFilter,
      },
      res => {
        const missedWorkoutClients = res.map(item => get(item, 'client'));
        toggleModal &&
          toggleModal(
            true,
            <BroadcastMessage
              onClose={handleCloseBroadcastModal}
              selectedClientProps={selectedClientIds}
              isFromWorkoutSummary={true}
              missedWorkoutClients={missedWorkoutClients}
              selectedClientType={type}
            />,
          );
      },
    );
  };

  const handleOpenUpgradeModal = () => {
    toggleSecondModal &&
      toggleSecondModal(
        true,
        <UpgradeStudioPlanModal onClose={handleCloseUpgradeModal} onCloseAll={handleCloseAllPopup} />,
      );
  };

  const handleCloseUpgradeModal = () => {
    toggleSecondModal && toggleSecondModal(false);
  };

  const handleCloseAllPopup = () => {
    beforeCloseModal && beforeCloseModal({ closeSideBar: true });
    toggleSecondModal && toggleSecondModal(false);
    toggleModal && toggleModal(false);
  };

  return (
    <>
      <S.TabBarSection>
        <S.TabBarContainer>
          {WORKOUT_COMPLETION_TAB_ITEMS.map(item => {
            const isMissed = item.value === TAB_MISSED;
            return (
              <S.TabBarItem
                key={item.key}
                onClick={handleSelectTab}
                data-status={item.value}
                active={item.value === tab}
                isLoading={isLoading}
              >
                <div>{item.label}</div> <div>{isMissed ? totalMissed : totalCompleted}</div>
              </S.TabBarItem>
            );
          })}
        </S.TabBarContainer>
        {tab === TAB_MISSED && totalMissed > 0 && hasBroadcastPermission && (
          <S.BroadcastWrapper hasSelectedClients={!isEmpty(selectedClients)}>
            <S.BroadcastContainer>
              <BroadcastIcon className="broadcast-icon" />
              Send Message
              <S.SendMessageTrigger>
                <S.ItemWrapper>
                  <S.SendMessageItem
                    onClick={() =>
                      hasBroadcastPermission
                        ? handleBroadcastMessage(SELECTED_CLIENT_TYPE.allMissed)
                        : handleOpenUpgradeModal()
                    }
                  >
                    Send to all clients
                  </S.SendMessageItem>
                  <S.SendMessageItem
                    onClick={() =>
                      hasBroadcastPermission
                        ? handleBroadcastMessage(SELECTED_CLIENT_TYPE.withoutUnread)
                        : handleOpenUpgradeModal()
                    }
                  >
                    Send to clients without unread message
                  </S.SendMessageItem>
                </S.ItemWrapper>
              </S.SendMessageTrigger>
            </S.BroadcastContainer>
          </S.BroadcastWrapper>
        )}
      </S.TabBarSection>
    </>
  );
};

Header.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
};

const mapState = state => {
  const { rootReducer } = state;

  return {
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

const mapDispatch = dispatch => ({
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  resetBroadcastMessageState: bindActionCreators(resetBroadcastMessageState, dispatch),
  getAllMissedWorkoutSummaries: bindActionCreators(getAllMissedWorkoutSummaries, dispatch),
});

export default connect(mapState, mapDispatch)(Header);
