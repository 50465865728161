import styled, { css } from 'styled-components';

const baseText = `
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #202353;
`;

export const Progress = styled.div`
  height: 407px;
  border: 1px solid #f7f7f7;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Today = styled.div`
  ${baseText}
  text-transform: uppercase;
  margin-bottom: 32px;
  opacity: 0.3;
  ${props =>
    props.hide &&
    css`
      visibility: hidden;
    `}
`;

export const ProgressBar = styled.div`
  width: 225px;
  height: 225px;
  position: relative;
  .CircularProgressbar {
    transform: rotate(224deg);
    .CircularProgressbar-trail {
      stroke: #f7f7f7;
    }
    .CircularProgressbar-path {
      transition: none;
      stroke: #5559ff;
    }
  }
`;

export const Info = styled.div`
  position: absolute;
  top: calc(50% - 15px);
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    ${baseText}
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: #202353;
    opacity: 0.3;
    margin-bottom: 0;
  }
  .bold {
    font-size: 50px;
    line-height: 60px;
    color: #000000;
    opacity: 1;
  }
`;

export const Time = styled.div`
  ${baseText}
  position: absolute;
  bottom: 12px;
  left: calc(50% - 4px);
  transform: translateX(-50%);
  font-weight: 700;
  text-transform: uppercase;
  color: #5559ff;
`;

export const ProgressBarGoal = styled.div`
  position: relative;
  width: 211px;
  height: 211px;
  border: 3px solid ${props => props.borderColor};
  padding: 23px;
  border-radius: 50%;
`;

export const Icon = styled.div`
  background-color: ${props => props.bgColor};
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimeGoal = styled.div`
  ${baseText}
  margin-top: 25px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #5559ff;
`;
