import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  .filter__dropdown {
    cursor: pointer;
  }
`;

export const MenuContainer = styled.div`
  width: 318px;
  border-radius: 4px;
  box-shadow: 0 5px 12px 2px rgba(0, 0, 0, 0.1);
  border: solid 1px #dfe4e5;
  background-color: #fff;
  padding: 16px 0 0;
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  z-index: 999;

  .form-group {
    padding: 0 24px;
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }

    label {
      font-size: 10px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #99a1b1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 4px;
    }
  }

  .multi-select__input {
    input {
      outline: none !important;
      border: none !important;
      min-width: 2px;
      max-width: 100%;
    }
  }

  .groups-value-container {
    min-height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    border-radius: 4px;
    border: solid 1px #dfe4e5;
    position: relative;
    padding: 2px 4px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;

    &.input-focus {
      border-color: #5c58c0;
    }
    .placeholder {
      position: absolute;
      left: 5px;
      top: 52%;
      transform: translateY(-50%);
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #b4b4b4;
    }
  }

  .groups {
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
    border: solid 1px #e1e4e6;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;

      li {
        padding: 9px 20px 8px;
        border-bottom: solid 1px #e1e4e6;
        cursor: pointer;
        :hover {
          background-color: #edecfc;
          color: #5c58c0;
        }
        :last-child {
          border: none;
        }

        &.no-option {
          background-color: #fff !important;
          text-align: center;
          color: #8d8c92;
        }
      }
    }
  }
`;

export const MenuFooter = styled.div`
  padding: 16px 14px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eceff1;

  .footer_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ui.button.general-button {
    padding: 6px 25px 9px;
    border-radius: 4px;
    margin: 0;
    background-color: #5158cf;
    border: none;
    box-shadow: none !important;
    font-size: 11px;
    line-height: 11px;

    :hover,
    :focus {
      background-color: #5559ff !important;
    }
  }

  .ui.button.general-button.create-button,
  .ui.button.general-button.manage-button {
    background-color: transparent;
    color: #7b82a4 !important;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    padding-right: 20px;

    > * {
      margin-right: 5px;
    }

    :hover,
    :focus {
      color: #5c58c0 !important;
      background-color: transparent !important;

      svg {
        circle {
          fill: #5c58c0;
        }
      }
    }
  }

  .ui.button.general-button.manage-button {
    :hover,
    :focus {
      svg {
        path {
          fill: #5c58c0;
        }
      }
    }
  }
`;
