import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/icons/guide_link.svg';

const Container = styled.a`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #5158cf;

  svg {
    margin-right: 4px;
    vertical-align: sub;
  }

  :hover {
    color: #5158cf;
  }
`;

export default ({ to, title, target }) => (
  <Container href={to} target={target || '_blank'} className="guide-link">
    <Icon />
    <span>{title}</span>
  </Container>
);
