import moment from 'moment';
import { TurnOnFeatureMessage } from 'components/Inbox/Content/style';
import { Types } from './actions';
import { STEP_VIEW_MODES } from 'constants/commonData';

const INITIAL_STATE = {
  chartData: [],
  currentMode: STEP_VIEW_MODES.week.value,
  fromDate: moment().startOf(STEP_VIEW_MODES.week.value).format('MM-DD-YYYY'),
  loadingChartData: TurnOnFeatureMessage,
  dailyAverage: null,
  dailyGoal: null,
  manualStepSelected: null,
  manualStepProgress: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.CHANGE_STEP_METRIC_SELECTED:
      return Object.assign({}, state, { manualStepSelected: payload.stepSelected });

    case Types.MANUAL_STEP_FETCH_PROGRESSING_REQUEST:
      return Object.assign({}, state, { dailyAverage: null });

    case Types.MANUAL_STEP_FETCH_CHART_DATA_REQUEST:
      return Object.assign({}, state, { chartData: [], loadingChartData: true });

    case Types.MANUAL_STEP_FETCH_CHART_DATA_SUCCESS:
      return Object.assign({}, state, { ...payload, loadingChartData: false });

    case Types.MANUAL_STEP_FETCH_PROGRESSING_SUCCESS:
      return Object.assign({}, state, { manualStepProgress: payload });

    case Types.STEP_FETCHING_CHART_DATA:
      return Object.assign({}, state, { loadingChartData: true });

    case Types.STEP_FETCH_CHART_DATA_SUCCESS:
      return Object.assign({}, state, { ...payload, loadingChartData: false });

    case Types.STEP_FETCH_CHART_DATA_ERROR:
    case Types.MANUAL_STEP_FETCH_CHART_DATA_ERROR:
      return Object.assign({}, state, { loadingChartData: false });

    case Types.STEP_GET_GOAL_SUCCESS:
      return Object.assign({}, state, { dailyGoal: payload });

    case Types.STEP_UPDATE_TIME_RANGE:
      return Object.assign({}, state, { ...payload });

    case Types.STEP_SET_GOAL_SUCCESS:
      return Object.assign({}, state, { dailyGoal: payload });

    default:
      return state;
  }
};
