// Lib
import React, { useEffect } from 'react';

// Constants
import { mongoObjectId } from 'utils/commonFunction';
import { MEAL_NAMES } from 'components/MealPlanDayDetail/constants';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/add_tag_icon.svg';

import * as S from './style';

const AddMeal = props => {
  const { addMealPlanDayDetail, listMealGroup, isAdd, setIsAdd } = props;

  useEffect(() => {
    if (listMealGroup && listMealGroup.length === 0) {
      const createMealPlanDayDetail = name => ({
        _id: mongoObjectId(),
        name,
        recipes: [],
        isCreateNew: true,
        is_system: true,
      });

      const mealPlansToAdd = MEAL_NAMES.map(name => createMealPlanDayDetail(name));
      mealPlansToAdd.forEach(mealPlan => addMealPlanDayDetail(mealPlan));
    }
  }, [listMealGroup.length]);

  const handleAddMeal = () => {
    const snackPrefix = 'Snack';
    const snackCount = listMealGroup.filter(({ name = '' }) => name.toLowerCase().startsWith(snackPrefix.toLowerCase()))
      .length;
    const newSnackName = snackCount === 0 ? snackPrefix : `${snackPrefix} ${snackCount}`;

    addMealPlanDayDetail({ _id: mongoObjectId(), name: newSnackName, recipes: [], isCreateNew: true }, true);

    if (!isAdd) {
      setIsAdd(true);
    }
  };

  return (
    <S.AddMealWrapper onClick={handleAddMeal}>
      <span className="add-meal-wrapper">
        <PlusIcon className="add-meal-icon" />
      </span>
      <S.AddMealTitle>Add Meal</S.AddMealTitle>
    </S.AddMealWrapper>
  );
};

export default AddMeal;
