import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeOnboardingQuery } from 'redux/onboarding-flow-library/actions';
import Pagination from 'shared/Pagination';

const OnboardingPagination = props => {
  const { page, per_page, total, changeOnboardingQuery } = props;

  return (
    <Pagination
      page={page}
      perPage={per_page}
      totalPage={total}
      onPrev={() => {
        changeOnboardingQuery({ page: page - 1 });
      }}
      onNext={() => {
        changeOnboardingQuery({ page: page + 1 });
      }}
    />
  );
};

const mapState = ({ rootReducer }) => {
  const {
    onboardingLibrary: {
      total,
      filters: { page, per_page },
    },
  } = rootReducer;

  return {
    total,
    page,
    per_page,
  };
};

const mapDispatch = dispatch => ({
  changeOnboardingQuery: bindActionCreators(changeOnboardingQuery, dispatch),
});

export default connect(mapState, mapDispatch)(OnboardingPagination);
