import { fromJS } from 'immutable';

import { LIMIT_PER_PAGE, PRODUCT_STATUS } from 'components/Product/constants';
import { Types } from './actions';

const INITIAL_WORKING_PRODUCT = {
  name: '',
  description: '',
  cover_image: '',
  activate_product_on: 1,
  access_duration_unit: 1,
  access_duration_value: 0,
  trainer: '',
  assets: [],
  is_edit_mode: false,
  status: null,
};

const INITIAL_STATE = fromJS({
  list: [],
  loading: false,
  total: 0,
  counter: {}, // Counter product for each status
  query: {
    page: 1,
    pageSize: LIMIT_PER_PAGE,
    q: '',
    status: '', // Search product with a status
    isEnd: false,
  },
  workingProduct: INITIAL_WORKING_PRODUCT,
  isEditMode: false,
  isSubmitting: false,
  duplicating: false,
  isEditingDuplicate: false,
  isUploading: false,
  isRequested: false,
  isFetchingCountry: false,
});

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.PRODUCT_DUPLICATE_REQUEST: {
      return state.merge({
        duplicating: true,
        workingProduct: fromJS(payload),
      });
    }

    case Types.PRODUCT_DUPLICATE_SUCCESS: {
      let currentCounter = state.get('counter').toJS();
      let total = state.get('total');
      let currentList = state.get('list').toJS();
      currentCounter.draft = currentCounter.draft + 1;
      currentList.unshift(payload.data);
      return state.merge({
        workingProduct: fromJS(payload.data),
        counter: fromJS(currentCounter),
        duplicating: false,
        isEditingDuplicate: true,
        total: total + 1,
        list: currentList,
      });
    }

    case Types.PRODUCT_UPDATE_SUCCESS: {
      const { id } = payload;
      const currentList = state.get('list').toJS();
      // Update current product into list
      const newList = currentList.map(it => {
        if (it.id === id) {
          it = payload;
        }
        return it;
      });
      return state.merge({
        workingProduct: fromJS(payload),
        list: fromJS(newList),
        isEditingDuplicate: false,
      });
    }

    case Types.PRODUCT_PUBLISH_SUCCESS: {
      let currentCounter = state.get('counter').toJS();
      const currentList = state.get('list').toJS();
      const query = state.get('query').toJS();
      currentCounter.publish = currentCounter.publish + 1;
      currentCounter.draft = currentCounter.draft - 1;
      let newList = [];
      if (query.status) {
        newList = currentList.filter(it => it.id !== payload.data.id);
      } else {
        newList = currentList.map(it => {
          if (it.id === payload.data.id) {
            it = payload.data;
          }
          return it;
        });
      }

      return state.merge({
        list: fromJS(newList),
        workingProduct: fromJS(payload.data),
        counter: fromJS(currentCounter),
      });
    }

    case Types.PRODUCT_ARCHIVE_SUCCESS: {
      const currentList = state.get('list').toJS();
      let workingProduct = !payload.isDetail
        ? currentList.find(it => it.id === payload.id)
        : state.get('workingProduct').toJS();
      let currentCounter = state.get('counter').toJS();
      const newList = currentList.filter(it => it.id !== payload.id);
      if (workingProduct.status === PRODUCT_STATUS.DRAFT) {
        currentCounter.draft = currentCounter.draft - 1;
        workingProduct.prev_status = PRODUCT_STATUS.DRAFT;
      } else {
        currentCounter.publish = currentCounter.publish - 1;
        workingProduct.prev_status = PRODUCT_STATUS.PUBLISH;
      }

      workingProduct.status = PRODUCT_STATUS.ARCHIVE;
      return state.merge({
        list: fromJS(newList),
        counter: currentCounter,
        workingProduct: fromJS(!payload.isDetail ? INITIAL_WORKING_PRODUCT : workingProduct),
      });
    }

    case Types.PRODUCT_UNARCHIVE_SUCCESS: {
      const currentList = state.get('list').toJS();
      let currentCounter = state.get('counter').toJS();
      let workingProduct = state.get('workingProduct').toJS();
      if (payload.prev_status === PRODUCT_STATUS.PUBLISH) {
        workingProduct.status = PRODUCT_STATUS.PUBLISH;
        currentCounter.publish = currentCounter.publish + 1;
      } else {
        workingProduct.status = PRODUCT_STATUS.DRAFT;
        currentCounter.draft = currentCounter.draft + 1;
      }
      const newList = currentList.filter(it => it.id !== payload.id);
      return state.merge({
        list: fromJS(newList),
        counter: fromJS(currentCounter),
        workingProduct: fromJS(workingProduct),
      });
    }

    case Types.PRODUCT_DELETE_SUCCESS: {
      const currentList = state.get('list').toJS();
      let currentCounter = state.get('counter').toJS();
      let total = state.get('total');
      currentCounter.draft = currentCounter.draft - 1;
      const newList = currentList.filter(it => it.id !== payload);
      return state.merge({
        list: fromJS(newList),
        counter: fromJS(currentCounter),
        total: total - 1,
      });
    }

    case Types.PRODUCT_COUNT_REQUEST:
    case Types.PRODUCT_GET_LIST_REQUEST: {
      return state.merge({
        loading: true,
      });
    }

    case Types.PRODUCT_COUNT_SUCCESS: {
      return state.merge({ counter: fromJS(payload), total: fromJS(payload.all) });
    }
    case Types.PAYMENT_COUNT_PACKAGES_REQUEST: {
      return state.merge({
        loading: true,
      });
    }

    case Types.PRODUCT_GET_LIST_SUCCESS: {
      return state.merge({
        list: fromJS(payload.data),
        loading: false,
      });
    }

    case Types.PRODUCT_GET_LIST_FAIL: {
      return state.merge({
        loading: false,
      });
    }

    case Types.PRODUCT_GET_LIST_MORE_REQUEST: {
      return state.merge({
        loading: true,
      });
    }

    case Types.PRODUCT_GET_LIST_MORE_SUCCESS: {
      const isEnd = payload.data.length < LIMIT_PER_PAGE;
      return state.merge({
        list: [...state.get('list').toJS(), ...payload.data],
        loading: false,
        query: {
          ...state.get('query').toJS(),
          page: payload.page,
          isEnd,
        },
      });
    }

    case Types.PRODUCT_DETAIL_GET_DATA_REQUEST:
      return state.merge({
        isSubmitting: true,
        workingProduct: {
          name: '',
          description: '',
          cover_image: '',
          activate_product_on: 1,
          access_duration_unit: 1,
          access_duration_value: 0,
          trainer: '',
          assets: [],
          is_edit_mode: false,
          status: null,
        },
      });

    case Types.PRODUCT_DETAIL_GET_DATA_SUCCESS: {
      return state.merge({
        workingProduct: { ...payload.data },
        isEditMode: payload.data.is_edit_mode,
        isSubmitting: false,
      });
    }

    case Types.PRODUCT_EDIT_GET_DATA_REQUEST:
      return state.merge({ isSubmitting: true });

    case Types.PRODUCT_EDIT_GET_DATA_SUCCESS: {
      return state.merge({
        workingProduct: payload.data,
        isEditMode: payload.data.is_edit_mode,
        isSubmitting: false,
      });
    }

    case Types.PRODUCT_DETAIL_ADD_NEW: {
      return state.merge({
        workingProduct: {
          description: '',
          cover_image: '',
          activate_product_on: 1,
          access_duration_unit: 1,
          access_duration_value: 0,
          trainer: '',
          assets: [],
          is_edit_mode: true,
          status: PRODUCT_STATUS.NEW,
          name: payload.name,
        },
        isEditMode: true,
      });
    }

    case Types.FETCH_PRODUCT_DETAIL: {
      return state.merge({
        workingProduct: {
          ...state.get('workingProduct').toJS(),
          ...payload,
        },
        isEditMode: true,
      });
    }

    case Types.PRODUCT_DETAIL_SUBMIT_REQUEST: {
      return state.merge({ isSubmitting: true });
    }

    case Types.PRODUCT_CREATE_DRAFT_SUCCESS: {
      let currentList = state.get('list').toJS();
      let total = state.get('total');
      let currentCounter = state.get('counter').toJS();
      currentList.unshift(payload.data);
      currentCounter.draft = currentCounter.draft + 1;
      return state.merge({
        workingProduct: fromJS(payload.data),
        total: total + 1,
        counter: currentCounter,
        list: currentList,
        duplicating: false,
      });
    }

    case Types.PRODUCT_DETAIL_SUBMIT_SUCCESS:
      return state.merge({ isSubmitting: false });

    case Types.PRODUCT_UPDATE_QUERY_REDUX: {
      const {
        query: { status },
      } = payload;
      const { status: currentStatus } = state.get('query').toJS();
      let currentList = state.get('list').toJS();
      if (status !== currentStatus) {
        currentList = [];
      }
      return state.merge({ query: fromJS(payload.query), list: fromJS(currentList) });
    }

    case Types.PRODUCT_UPLOAD_IMAGE_REQUEST: {
      return state.merge({ isUploading: true });
    }

    case Types.PRODUCT_UPLOAD_IMAGE_SUCCESS: {
      return state.merge({ isUploading: false });
    }

    case Types.PRODUCT_RESET_DUPLICATE_REDUX: {
      return state.merge({ isEditingDuplicate: false });
    }

    case Types.REQUEST_NEW_COUNTRY_SUCCESS:
      return state.merge({
        isRequested: true,
        isFetchingCountry: false,
      });

    case Types.GET_NEW_COUNTRY_STATUS:
      return state.merge({
        isFetchingCountry: true,
      });

    case Types.REQUEST_NEW_COUNTRY_FAILED: {
      return state.merge({
        isRequested: state.get('isRequested'),
        isFetchingCountry: false,
      });
    }

    case Types.GET_NEW_COUNTRY_STATUS_SUCCESS: {
      const requestStatus = payload.data;
      return state.merge({
        isRequested: requestStatus.isRequested,
        isFetchingCountry: false,
      });
    }

    default:
      return state;
  }
};
