import React from 'react';
import { Modal } from 'semantic-ui-react';

import { ReactComponent as WarrningIcon } from 'assets/icons/alert_warning.svg';

import { ModalWrapper, ModalTitle, ModalDesc, ModalButton } from './style';
import * as ModalLayout from 'shared/Styles/ModalLayout';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL, LIMIT_RESOURCE_COLLECTION } from 'constants/commonData';

const AddClientsModalWarning = ({ clients, onClose }) => {
  return (
    <ModalWrapper
      open={true}
      closeOnDimmerClick={false}
      onClose={onClose}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <ModalTitle>
          <WarrningIcon />
          Unable to Assign for {clients} {pluralize('Client', clients)}
        </ModalTitle>
        <ModalLayout.Content className="confirm-content">
          <ModalDesc>
            Each client can have up to {LIMIT_RESOURCE_COLLECTION} Resource Collections, but {clients} of the clients
            selected already had {LIMIT_RESOURCE_COLLECTION} collections, so this collection could not be assigned for
            them.
          </ModalDesc>
        </ModalLayout.Content>
        <ModalLayout.Actions className="confirm-footer">
          <ModalButton className="rest-btn" onClick={onClose}>
            OK
          </ModalButton>
        </ModalLayout.Actions>
      </Modal.Content>
    </ModalWrapper>
  );
};

export default AddClientsModalWarning;
