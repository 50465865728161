import React from 'react';
import Pagination from 'shared/Pagination';

const Footer = ({ clientId, query, changeQueryParams }) => {
  return (
    <Pagination
      page={query.page}
      perPage={query.per_page}
      totalPage={query.total}
      onPrev={() => {
        changeQueryParams(clientId, { page: query.page - 1 });
      }}
      onNext={() => {
        changeQueryParams(clientId, { page: query.page + 1 });
      }}
    />
  );
};

export default Footer;
