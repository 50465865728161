import React, { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import unionBy from 'lodash/unionBy';
import pick from 'lodash/pick';
import isUndefined from 'lodash/isUndefined';
import forEach from 'lodash/forEach';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import diff from 'deep-diff';
import { Button as CloseButton } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import moment from 'moment';
import { toast } from 'react-toastify';

import * as S from './style.js';
import { getUserShortName } from 'utils/commonFunction';
import { CDN_URL, KEY_CODE } from 'constants/commonData';
import { isESCPress } from 'utils/commonFunction';
import { getPageMetaDataFromURL } from 'utils/commonRequest';
import { getLinkType, findLastURLinText } from 'utils/validations';
import { ReactComponent as CloseCircleIcon } from 'assets/icons/close_circle.svg';
import { ReactComponent as ScheduleIconWhite } from 'assets/icons/forum-schedule-clock-white.svg';
import { ReactComponent as ScheduleIconBlack } from 'assets/icons/forum-schedule-clock-black.svg';
import { POST_TYPES } from 'components/CommunityForum/constants.js';
import ShareLeaderboardPost from 'components/CommunityForum/components/ShareLeaderboardPost/index.js';
import { PreviewVideoLink, PreviewArticleLink, PreviewLoader } from 'shared/PreviewLink';

import MediaSide from '../MediaSide';
import SchedulePicker from 'components/CommunityForum/components/ForumTimeline/components/SchedulePost/SchedulePicker';
import PostEditor from './PostEditor';
import FormPoll from 'components/CommunityForum/components/FormPoll';

const className = '.modal--create-new-post .DraftEditor-editorContainer';

const isShareLeaderboard = postType => {
  return postType === POST_TYPES.LEADERBOARD;
};

const taggedAll = tagged => {
  return tagged.find(it => get(it, 'name', '') === 'everyone') ? true : false;
};

const currentLeaderboard = data => {
  const { post, leaderboard } = data;
  return { leaderboard: post ? post.leaderboard : leaderboard._id };
};

const POST_TYPE_POLL = 3;

const Popup = props => {
  const postType = get(props, 'postType') || get(props, 'post.post_type');
  const isShareLeaderboardPost = isShareLeaderboard(postType);
  const {
    formDataSchedule,
    isSchedulePost,
    saveScheduleTemp,
    isEdit,
    pollData = {},
    user,
    openPollDefault = false,
  } = props;

  const previewMetadata = get(props, 'post.preview', null);

  const [formData, setFormData] = useState({
    groupId: props.match.params.id,
    attachments: [],
    caption: '',
    post_type: postType,
    tagged_people: [],
    tag_all: false,
    status: 'PUBLISHED',
    preview: previewMetadata,
    ...(isShareLeaderboardPost && currentLeaderboard(props)),
    poll: null,
  });
  const [formDataOriginal, setFormDataOriginal] = useState({
    groupId: props.match.params.id,
    attachments: [],
    caption: '',
    post_type: postType,
    tagged_people: [],
    tag_all: false,
    status: 'PUBLISHED',
    preview: previewMetadata,
    poll: null,
    ...(isShareLeaderboardPost && currentLeaderboard(props)),
  });
  const [isUploading, setIsUploading] = useState(false);
  const [hasContent, setHasContent] = useState('');
  const [notAllowPost, setAllowPost] = useState(false);
  const [scheduleTime, setScheduleTime] = useState('');
  const [formDataScheduleOrigin, setFormDataScheduleOrigin] = useState({});
  const [uploadStatus, setUploadStatus] = useState(true);
  const [removedFile, setRemovedFile] = useState(false);
  const [attachmentMeta, setAttachment] = useState(null);
  const [loadingMetaData, setLoadingMetaData] = useState(false);
  const [hideMetadata, setHideMeta] = useState(false);
  const [linkType, setLinkType] = useState('');
  const [enableForumPoll, setEnableForumPoll] = useState(openPollDefault || !isEmpty(pollData));
  const [isDisableSaveByPoll, setIsDisableSaveByPoll] = useState(false);

  const hasMetaURL = get(attachmentMeta, 'url');
  const hasAttachment =
    !isEmpty(get(props, 'formDataSchedule.attachments', null)) || !isEmpty(get(props, 'post.attachments', null));

  useEffect(() => {
    if (isSchedulePost && formDataSchedule) {
      setScheduleTime(get(formDataSchedule, 'schedule_time'));
      const formScheduleOrigin = {
        caption: get(formDataSchedule, 'caption', ''),
        attachments: get(formDataSchedule, 'attachments', []),
        preview: get(formDataSchedule, 'preview', null),
      };
      if (enableForumPoll) formScheduleOrigin.poll = get(formDataSchedule, 'poll', null);
      setFormDataScheduleOrigin(formScheduleOrigin);
    }
  }, [enableForumPoll]);

  const reference = useRef(null);

  useEffect(() => {
    const { post } = props;
    if (props && post) {
      setFormData({
        ...formData,
        attachments: get(post, 'attachments', []),
        caption: get(post, 'caption', ''),
        tagged_people: get(post, 'tagged_people', []),
        tag_all: taggedAll(get(post, 'tagged_people', [])),
        preview: get(post, 'preview', null),
        poll: get(post, 'poll', null),
      });
      setFormDataOriginal({
        ...formDataOriginal,
        attachments: get(post, 'attachments', []),
        caption: get(post, 'caption', ''),
        tagged_people: get(post, 'tagged_people', []),
        tag_all: taggedAll(get(post, 'tagged_people', [])),
        preview: get(post, 'preview', null),
        poll: get(post, 'poll', null),
      });
    }
  }, []);

  useEffect(() => {
    let options = {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true,
    };
    const observer = new MutationObserver(handleCheckTextChange);
    setTimeout(() => {
      const targetNode = document.querySelector(className);
      if (targetNode) {
        observer.observe(targetNode, options);
      }
    }, 100);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleCheckTextChange = () => {
    const targetNode = document.querySelector(className);
    setHasContent(get(targetNode, 'textContent', '').trim());
  };

  // Focus to end of text
  useEffect(() => {
    if (reference) {
      setTimeout(() => {
        const caption = get(props, 'post.caption', '');
        const size = caption.length;
        reference.current && reference.current.setSelectionRange(size, size);
      }, 100);
    }
  }, []);

  const escFunction = event => {
    if (event.keyCode === KEY_CODE.esc || isESCPress(event.key)) {
      const isOpenSchedule = document.querySelector('.schedule-post-button');
      const isHavingPreview = document.querySelector('.media-content');
      const isOpenGiphy = document.querySelector('.opened-giphy-popup');
      !isOpenSchedule && !isHavingPreview && !isOpenGiphy && handleClose && handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  const handleGetType = val => {
    let data = get(val, 'key', '');

    data = data.substring(data.indexOf('/')).replace('/', '');
    return data;
  };

  const handleGetName = val => {
    let data = get(val, 'key', '');

    data = data.substring(data.indexOf('/')).replace('/', '');
    return data;
  };

  const onUpdateMediaData = ({ mediaData }) => {
    const newTempMedias = [];

    forEach(mediaData.list, item => {
      if (item.isLocalFile) {
        const data = get(item, 'bucketData');

        if (data) {
          setHideMeta(true);
          newTempMedias.push({
            thumbnail_url: data.thumbnail_url,
            name: handleGetName(data),
            original_name: data.original_name,
            url: data.url,
            attachment_id: handleGetType(data),
            height: get(item, 'height', null),
            width: get(item, 'width', null),
            type: (data.mimetype || data.fileType).substring(0, (data.mimetype || data.fileType).indexOf('/')),
          });
        }
      } else {
        newTempMedias.push(item);
      }
    });

    setFormData({
      ...formData,
      attachments: unionBy(newTempMedias, 'attachment_id'),
    });
    const { formDataSchedule } = props;
    props.saveScheduleTemp({ ...formDataSchedule, attachments: unionBy(newTempMedias, 'attachment_id') });
  };

  const handleClose = () => {
    if (props.isConfirmModalOpen) {
      return;
    }

    if (isChanged()) {
      props.toggleConfirmModal(
        true,
        <S.SConfirmModal
          title="Discard Changes?"
          content="Are you sure you want to go? Changes have not been saved yet"
          onConfirm={handleCloseCurrentPopup}
          confirmButtonTitle="Discard changes"
          cancelButtonTitle="Cancel"
          headerIcon={`${CDN_URL}/images/alert_warning.svg`}
          noBorder
        />,
      );
    } else {
      props.toggleSecondModal(false);
      props.toggleModal(false);
      handleCloseCurrentPopup();
    }
  };

  const handleFieldChange = (value, tagged) => {
    const isAll = taggedAll(tagged);
    const taggedPeople = tagged.map(it => pick(it, ['_id', 'name']));
    // In case check diff of URL for preview link
    const captionUrl = findLastURLinText(get(formData, 'caption'));
    const valueUrl = findLastURLinText(value);
    const isDiffURL = diff(valueUrl, captionUrl);
    const previewWithoutMediaAndPoll = valueUrl && !hasAttachment && !enableForumPoll;

    if (previewWithoutMediaAndPoll && !hideMetadata) {
      const trimmedValue = valueUrl.trim();
      setLinkType(getLinkType(trimmedValue));
      processMetadata(trimmedValue);
      setLoadingMetaData(true);
    } else if (valueUrl && isDiffURL && hideMetadata && !hasAttachment && !enableForumPoll) {
      const trimmedValue = valueUrl.trim();
      setHideMeta(false);
      setLinkType(getLinkType(trimmedValue));
      processMetadata(trimmedValue);
      setLoadingMetaData(true);
    } else if ((!valueUrl && formDataCaption > 0) || removedFile) {
      setHideMeta(true);
      setLinkType(null);
      setAttachment(null);
    }

    setFormData({
      ...formData,
      caption: value,
      tagged_people: taggedPeople,
      tag_all: isAll,
      preview: attachmentMeta,
    });

    if (formDataSchedule) {
      saveScheduleTemp({
        ...formDataSchedule,
        caption: value,
        tagged_people: taggedPeople,
        tag_all: isAll,
        preview: attachmentMeta,
      });
    }
  };

  const getLinkMetaData = async url => {
    if (url === get(formData, 'preview.url')) return;

    try {
      const response = await getPageMetaDataFromURL(url);
      const data = response.data || {};
      if (data && hasMetaURL !== 'undefined') {
        let newData = { ...data.data };
        if (data.data) {
          newData.url = url;
        }
        setAttachment(newData);
      }
    } finally {
      setLoadingMetaData(false);
    }
  };

  const getMetaDataDebounce = debounce(getLinkMetaData, 300);

  const processMetadata = url => {
    try {
      if (url) {
        getMetaDataDebounce(url);
      }
    } catch (error) {
      console.error('Error processing metadata:', error);
    } finally {
      setLoadingMetaData(false);
    }
  };

  const handleCloseMeta = () => {
    setHideMeta(true);
    setFormData({
      ...formData,
      preview: null,
    });
    props.saveScheduleTemp({
      ...formDataSchedule,
      preview: null,
    });
    setLinkType(null);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const {
      isEdit,
      post,
      push,
      match: {
        params: { forumId },
      },
      resetCreatePost,
      getNotifications,
      toggleModal,
      toggleSecondModal,
    } = props;
    let _groupID = post ? post.group : false;

    // TODO - handle get group ID
    _groupID = get(_groupID, '_id') || formData.groupId || forumId || '';
    isEdit ? props.editPost(formData, post._id) : props.addPost({ ...formData, groupId: _groupID });
    getNotifications();
    toggleSecondModal(false);
    toggleModal(false);
    isEdit && post.group && push(`/home/forums/${_groupID}/discussion`);
    resetCreatePost();
  };

  const handleCloseCurrentPopup = () => {
    const { isEdit, post, push } = props;
    let _groupID = post ? post.group : false;

    // TODO - handle get group ID
    _groupID = get(_groupID, '_id') || formData.groupId || '';
    props.resetCreatePost();
    props.toggleConfirmModal(false);
    props.toggleSecondModal(false);
    props.toggleModal(false);
    isEdit && post.group && push(`/home/forums/${_groupID}/discussion`);
  };

  const isChanged = () => {
    const { scheduledDate, scheduledHour } = props;
    const isRemoveSchedule = isSchedulePost && scheduledDate === false && scheduledHour === false;
    const changedScheduleForm = formDataSchedule && {
      caption: get(formDataSchedule, 'caption', ''),
      attachments: get(formDataSchedule, 'attachments', []),
      preview: get(formDataSchedule, 'preview', null),
    };

    if (enableForumPoll && diff(get(formData, 'poll', null), get(formDataOriginal, 'poll', null))) return true;

    const joinDate =
      isSchedulePost && scheduledDate && scheduledHour
        ? `${scheduledDate.format(moment.HTML5_FMT.DATE)} ${get(scheduledHour, 'value')}`
        : '';

    if (enableForumPoll && isSchedulePost) {
      changedScheduleForm.poll = get(formDataSchedule, 'poll', null);
    }

    const currentScheduleSelected = isSchedulePost ? moment(scheduleTime).format('YYYY-MM-DD HH:mm') : '';

    if (isSchedulePost) {
      if ((joinDate && diff(currentScheduleSelected, joinDate)) || isRemoveSchedule) return true;
      if (diff(changedScheduleForm, formDataScheduleOrigin)) {
        return true;
      }
    }

    if (isShareLeaderboardPost && !props.isEdit) {
      return true;
    }

    if (
      !isSchedulePost &&
      diff(omit(formData, ['tagged_people', 'preview']), omit(formDataOriginal, ['tagged_people', 'preview']))
    )
      return true;

    return false;
  };

  const shouldDisable = () => {
    if (enableForumPoll) {
      const changedCaption = get(formData, 'caption', '') !== get(formDataOriginal, 'caption', '');
      if (isDisableSaveByPoll) {
        return true;
      }
      if (changedCaption) {
        return false;
      }
      return !diff(get(formData, 'poll', null), get(formDataOriginal, 'poll', null)) || !get(formData, 'poll', null);
    }

    if (isShareLeaderboardPost) {
      return false;
    }

    const loadingStatus = get(props, 'loading', false);
    const formDataCaption = formData.caption.trim().length;
    const lengthHasContent = hasContent.trim().length;
    const disableMediaUploading = isUploading && formDataCaption > 0 && lengthHasContent > 0;
    const isReschedulePost =
      get(formDataSchedule, 'attachments.length') > 0 || get(formDataSchedule, 'caption.length') > 0;

    return (
      !uploadStatus ||
      loadingStatus ||
      isUploading ||
      disableMediaUploading ||
      !isReschedulePost ||
      (!isReschedulePost && formDataCaption === 0 && formData.attachments.length === 0 && lengthHasContent === 0)
    );
  };

  const handleStartUpload = () => {
    setIsUploading(true);
  };

  const handleEndUpload = () => {
    setIsUploading(false);
  };

  const isPostingAllowed = () => {
    const { scheduledDate, scheduledHour, formDataSchedule } = props;

    let checkNormalPost =
      (!get(formDataSchedule, 'status') && !get(formDataSchedule, 'schedule_time')) ||
      get(formDataSchedule, 'status') === 'PUBLISHED';

    switch (true) {
      case scheduledDate && scheduledHour:
        const joinDate = `${scheduledDate.format(moment.HTML5_FMT.DATE)} ${get(scheduledHour, 'value')}`;
        const currentTime = moment().format('YYYY-MM-DD HH:mm');
        return joinDate.isAfter(currentTime);
      case checkNormalPost:
        return true;
      default:
        const currentTimeInUTC = moment().utc();
        const getEditableDate = get(formDataSchedule, 'schedule_time');

        if (getEditableDate) {
          const renderDate = moment(getEditableDate);
          return renderDate.isAfter(currentTimeInUTC);
        }
        return false;
    }
  };
  const isEnabledUpload = () => {
    if (isShareLeaderboardPost) {
      return false;
    }
    if (hideMetadata) {
      return true;
    }
    if ((hasMetaURL || loadingMetaData) && formDataCaption) {
      return false;
    }

    return true;
  };

  const handlePostScheduled = async e => {
    e.preventDefault();
    const {
      push,
      post,
      match: {
        params: { forumId },
      },
      formDataSchedule,
      isEditSchedule,
      hideScheduleBanner,
      isHideBanner,
    } = props;

    if (!isPostingAllowed()) {
      setAllowPost(true);
      toast.error(`You can’t schedule a post in the past`);
      return;
    }

    let groupID = post ? post.group : false;
    groupID = get(groupID, '_id') || formData.groupId || forumId || '';

    const basedPick = ['status', 'schedule_time', 'caption', 'tag_all', 'tagged_people', 'preview'];
    const pickedField = isEmpty((formDataSchedule || {}).poll)
      ? basedPick.concat(['attachments'])
      : basedPick.concat(['poll', 'post_type']);
    const pickedSelectedDataSchedule = pick(formDataSchedule, pickedField);

    const selectedDataSchedule = omitBy(pickedSelectedDataSchedule, isEmpty());

    if (isEditSchedule) {
      await props.editPost({
        ...selectedDataSchedule,
        groupId: groupID,
        postId: formDataSchedule._id,
      });
    } else {
      await props.addPost({
        ...selectedDataSchedule,
        groupId: groupID,
      });
      // hide banner from first schedule post
      if (!isHideBanner) {
        await hideScheduleBanner({ group_id: groupID });
      }
    }

    let checkNormalPost =
      (!get(formDataSchedule, 'status') && !get(formDataSchedule, 'schedule_time')) ||
      get(formDataSchedule, 'status') === 'PUBLISHED';

    checkNormalPost
      ? push(`/home/forums/${groupID}/discussion`)
      : push(`/home/forums/${groupID}/upcoming`, { firstSchedulePost: !isHideBanner });
    props.resetCreatePost();
    props.getNotifications();
    props.toggleSecondModal(false);
    props.toggleModal(false);
  };

  const handleSchedule = e => {
    e.preventDefault();
    setAllowPost(false);
    props.toggleSecondModal(true, <SchedulePicker {...props} />);
  };

  const handleRemovePoll = () => {
    setEnableForumPoll(false);
    setFormData({ ...formData, post_type: postType, poll: null });
  };

  const handleEnablePoll = () => {
    setEnableForumPoll(true);
  };

  const renderDate = () => {
    const { scheduledDate, scheduledHour, isSchedulePost, isEditSchedule, formDataSchedule, user } = props;

    if (!scheduledDate && !scheduledHour && !isUndefined(scheduledDate) && !isUndefined(scheduledHour)) return null;
    const date = moment(scheduledDate).tz(user.timezone).format('MMM DD, YYYY');
    const hour = scheduledHour && scheduledHour.label;
    const renderDateEditable = () => {
      if (isSchedulePost && isEditSchedule) {
        const getEditableDate = get(formDataSchedule, 'schedule_time');
        if (getEditableDate) {
          const date = moment(getEditableDate).tz(user.timezone).format('MMM DD, YYYY - hh:mm A');
          return date;
        }
      }
    };

    return (
      <S.ScheduledBox onClick={handleSchedule} enabled={!isPostingAllowed(renderDateEditable()) || notAllowPost}>
        <ScheduleIconBlack />
        <S.ScheduledTime>{renderDateEditable() || `${date} - ${hour}`}</S.ScheduledTime>
      </S.ScheduledBox>
    );
  };

  const getScheduledDate = get(props, 'formDataSchedule.schedule_time') || get(props, 'scheduledDate');

  const renderPostOrEdit = () => {
    if (!props.scheduledDate && props.scheduleTime && !props.isShowReschedule && props.isEditSchedule) {
      return 'Post Now';
    } else if (props.scheduledDate || props.scheduleTime || (props.isEditSchedule && props.isShowReschedule)) {
      return 'Schedule Post';
    } else return 'Post Now';
  };

  const renderPopupTitle = () => {
    if (props.rescheduleTime) {
      return 'Create a post';
    } else if (props.isEdit || (props.isEditSchedule && props.isShowReschedule)) {
      return 'Edit post';
    } else return 'Create a post';
  };

  const initEditorData = props.post ? props.post : props.formDataSchedule;

  const autoflowPermission = get(props, 'permission.autoflow');

  const showScheduleButton = () => {
    if (!props.isEdit && !props.isEditSchedule) {
      return true;
    } else if (props.isEdit && !props.isEditSchedule) {
      return false;
    } else return true;
  };

  const uploadedAllMedia = status => {
    setUploadStatus(status);
  };

  const onRemoveUploaded = status => {
    setRemovedFile(status);
  };

  const handleChangeFormPoll = params => {
    const filteredOptions = params.options.filter(it => !!it.value);

    const disableSave =
      params.options.some(({ isDuplicate }) => isDuplicate) || !params.question || filteredOptions.length < 1;

    setIsDisableSaveByPoll(disableSave);

    const newPoll = {
      ...params,
      options: filteredOptions.map(({ value, _id, isNew = false }) => {
        const option = { value };
        if (!!_id && !isNew) option._id = _id;
        return option;
      }),
    };
    setFormData({ ...formData, poll: newPoll, post_type: POST_TYPE_POLL });
    props.saveScheduleTemp({ ...formDataSchedule, poll: newPoll, post_type: POST_TYPE_POLL });
  };

  const formDataCaption = formData.caption.trim().length;
  // handle case empty thumbnail for Youtube only
  const isYouTubeOrVimeo = linkType === 'youtube' || linkType === 'vimeo';
  const hasNoImage = isYouTubeOrVimeo && !get(attachmentMeta, 'image', '');

  const isVideoMeta = isYouTubeOrVimeo && !hasNoImage;

  const shouldRenderMetadata = formDataCaption > 0 && hasMetaURL && !hideMetadata && !enableForumPoll;
  const isRenderMetadata = shouldRenderMetadata && !isVideoMeta;
  const isRenderVideoMetadata = shouldRenderMetadata && isVideoMeta;

  const isRenderLoadingMeta =
    formDataCaption > 0 &&
    loadingMetaData &&
    (isEqual(attachmentMeta, null) || isEqual(get(formDataSchedule, 'preview'), {})) &&
    !hasMetaURL;

  return (
    <S.SModal
      open={true}
      className="modal--create-new-post"
      closeOnDimmerClick={true}
      closeIcon={
        <CloseButton className="close-button" onClick={handleClose}>
          <CloseCircleIcon />
        </CloseButton>
      }
    >
      <S.SModal.Header>
        <S.Title>{renderPopupTitle()}</S.Title>
      </S.SModal.Header>
      <S.SModal.Content className="content">
        <S.Header>
          <div className="avatar-container">
            <Avatar
              className="client-detail-avatar"
              size="38"
              name={getUserShortName(props.user)}
              src={props.user.avatar}
              color={props.user.color}
            />
          </div>
          <S.AuthorNameContainer>
            <div className="author-wrapper">
              <div className="author-name">{get(props, 'user.full_name', '')}</div>
              &nbsp;
              <div className="author-you">(you)</div>
            </div>
            <div className="author-role">Trainer</div>
          </S.AuthorNameContainer>
          {(props.scheduledDate || props.scheduleTime) && renderDate()}
        </S.Header>
        <S.ContentScrollWrapper>
          <PostEditor
            members={props.members || []}
            placeholder={'Write something...'}
            onInputChange={handleFieldChange}
            initEditor={initEditorData}
            isLeaderboard={isShareLeaderboardPost}
            autoFocus={true}
            isSchedulePost={props.isSchedulePost}
            customClassName={formData.caption.length > 255 ? 'small' : 'normal'}
            enableForumPoll={enableForumPoll}
          />
          {isShareLeaderboardPost && (
            <ShareLeaderboardPost leaderboard={props.leaderboard || props.post.leaderboard_snapshot} />
          )}
          {isRenderMetadata && <PreviewArticleLink attachmentMeta={attachmentMeta} closePreview={handleCloseMeta} />}
          {isRenderVideoMetadata && (
            <PreviewVideoLink attachmentMeta={attachmentMeta} closePreview={handleCloseMeta} linkType={linkType} />
          )}
          {!enableForumPoll && isRenderLoadingMeta && <PreviewLoader />}
          {enableForumPoll && (
            <FormPoll
              removePoll={handleRemovePoll}
              isEdit={isEdit || props.isEditSchedule}
              pollData={props.isEditSchedule ? formDataScheduleOrigin.poll : formData.poll}
              originPoll={pollData}
              onChangeFormPoll={handleChangeFormPoll}
              userId={(user || {})._id}
              toggleConfirmModal={props.toggleConfirmModal}
            />
          )}
        </S.ContentScrollWrapper>
        {isEnabledUpload() && (
          <MediaSide
            file={props.file}
            onUpdate={onUpdateMediaData}
            onStartUpload={handleStartUpload}
            onEndUpload={handleEndUpload}
            mediaData={props.isSchedulePost ? get(props, 'formDataSchedule.attachments') : formData.attachments}
            giphyEnable={true}
            uploadedAllMedia={uploadedAllMedia}
            onRemovePhoto={onRemoveUploaded}
            pollEnable={enableForumPoll}
            showPollIcon={true}
            onEnablePoll={handleEnablePoll}
          />
        )}
      </S.SModal.Content>
      <S.SModal.Actions>
        <S.CancelButton onClick={handleClose} data-btn-cancel="cancel-post">
          Cancel
        </S.CancelButton>
        {props.isSchedulePost && autoflowPermission ? (
          <>
            <S.PostScheduleButton
              type="submit"
              purple={!shouldDisable()}
              disable={shouldDisable()}
              onClick={getScheduledDate ? handlePostScheduled : handleSubmit}
              data-btn-schedule-post="submit"
              normalBtn={props.isEdit}
            >
              {renderPostOrEdit()}
            </S.PostScheduleButton>
            {showScheduleButton() && (
              <S.ScheduleButton
                type="change"
                purple={!shouldDisable()}
                disable={shouldDisable()}
                onClick={handleSchedule}
                data-btn-schedule="schedule"
              >
                <ScheduleIconWhite />
              </S.ScheduleButton>
            )}
          </>
        ) : (
          <S.PostButton
            type="submit"
            purple={!shouldDisable()}
            disable={shouldDisable()}
            onClick={handleSubmit}
            data-btn-post="normal-post"
          >
            {props.isEdit ? 'Save' : 'Post'}
          </S.PostButton>
        )}
      </S.SModal.Actions>
    </S.SModal>
  );
};

export default Popup;
