/**
 * @flow
 */

import { connect } from 'react-redux';
import type { State, Dispatch } from 'types/redux';
import PricingPlan from './component';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import {
  markShouldSelectPlan,
  getProratedCredit,
  getProratedCreditPrev,
  getProratedZapierMonthly,
  confirmPayment,
  initPricingData,
  removePromoCode,
  checkPromoCode,
  resetPricingFlag,
  resetPricingData,
  getReferralPromotionCode,
  updateSelectPackage,
  updateZapierPrice,
} from 'redux/pricing/actions';
import { resetPaymentReducer } from 'redux/payment/actions';
import { getBillingOverviewData } from 'redux/billing/actions';

const mapStateToProps = (state: State) => {
  const { rootReducer } = state;
  return {
    addOnsPricing: rootReducer.pricing.get('addOnsPricing').toJS(),
    shouldSelectPlan: rootReducer.pricing.get('shouldSelectPlan'),
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
    teamData: rootReducer.pricing.get('teamData').toJS(),
    referralPromotionCode: rootReducer.pricing.get('referralPromotionCode'),
    paymentInfo: rootReducer.billing.paymentInfo,
    invoiceOverDue: rootReducer.billing.invoiceOverDue,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    markShouldSelectPlan: bindActionCreators(markShouldSelectPlan, dispatch),
    confirmPayment: bindActionCreators(confirmPayment, dispatch),
    getProratedCredit: bindActionCreators(getProratedCredit, dispatch),
    getProratedCreditPrev: bindActionCreators(getProratedCreditPrev, dispatch),
    getProratedZapierMonthly: bindActionCreators(getProratedZapierMonthly, dispatch),
    initPricingData: bindActionCreators(initPricingData, dispatch),
    getBillingOverviewData: bindActionCreators(getBillingOverviewData, dispatch),
    removePromoCode: bindActionCreators(removePromoCode, dispatch),
    resetPaymentReducer: bindActionCreators(resetPaymentReducer, dispatch),
    checkPromoCode: bindActionCreators(checkPromoCode, dispatch),
    resetPricingFlag: bindActionCreators(resetPricingFlag, dispatch),
    resetPricingData: bindActionCreators(resetPricingData, dispatch),
    getReferralPromotionCode: bindActionCreators(getReferralPromotionCode, dispatch),
    push: bindActionCreators(push, dispatch),
    updateSelectPackage: bindActionCreators(updateSelectPackage, dispatch),
    updateZapierPrice: bindActionCreators(updateZapierPrice, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingPlan);
