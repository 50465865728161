/**
 * @flow
 */

import React from 'react';
import { Form, Label, Dropdown } from 'semantic-ui-react';
import { TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { connect } from 'react-redux';
import * as S from './style';

class OwnerDropdown extends React.PureComponent {
  state = {
  };

  getMemberName(member, user) {
    const name = `${member.first_name} ${member.last_name}`;
    let extraText = '';
    if (member._id === user._id) {
      extraText = ' (You)'
    }
    return <span><b>{name}</b>{extraText}</span>;
  }

  render() {
    const {
      owner,
      share,
      isEditAble,
      handleChangeOwner,
      user, 
      isTeamAdmin,
      disableOwner,
    } = this.props;

    if (!user) {
      return null;
    }
    let ownerOptions;
    let ownerValue = owner;
    if (!owner || share === TEAM_SHARE_NOOWNER) {
      ownerValue = 'no_owner';
    }
    if (user.team_member && (!isEditAble && ownerValue !== 'no_owner') || isTeamAdmin) {
      ownerOptions = user.team_member.map(item => {
        return {
          text: this.getMemberName(item, user),
          value: item._id
        }
      });
    } else {
      ownerOptions = [
        {
          text: this.getMemberName(user, user),
          value: user._id
        }
      ]
    }
    ownerOptions.push(
      {
        text: <span><b>No owner</b> (Shared)</span>,
        value: 'no_owner'
      }
    )
    return (
      <S.FormControl>
        <S.Label>OWNER</S.Label>
        <S.StudioDropdown
          value={ownerValue}
          selection
          disabled={!!disableOwner || (!isTeamAdmin && owner !== user._id && ownerValue !== 'no_owner')}
          className=""
          options={ownerOptions}
          onChange={handleChangeOwner}
        />
      </S.FormControl>
    );
  }
}


const mapStateToProps = (state) => {
  const { user } = state;
  let isTeamAdmin = false;
  if (!user.teams || user.teams.length === 0 || user.teams[0].role === 0 || user.teams[0].role === 2) {
    isTeamAdmin = true;
  }
  return {
    user,
    isTeamAdmin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerDropdown)