import React from 'react';

export const clock = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 12.25C4.1125 12.25 1.75 9.8875 1.75 7C1.75 4.1125 4.1125 1.75 7 1.75C9.8875 1.75 12.25 4.1125 12.25 7C12.25 9.8875 9.8875 12.25 7 12.25Z"
      fill="#A0A6B2"
    />
    <path d="M7.875 3.5H6.125V7.875H10.5V6.125H7.875V3.5Z" fill="#A0A6B2" />
  </svg>
);

export const mail = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.125 0.875H0.875C0.392 0.875 0 1.26613 0 1.75V12.25C0 12.7339 0.392 13.125 0.875 13.125H13.125C13.608 13.125 14 12.7339 14 12.25V1.75C14 1.26613 13.608 0.875 13.125 0.875ZM12.25 11.375H1.75V5.88263L6.566 8.6345C6.8355 8.7885 7.16538 8.7885 7.434 8.6345L12.25 5.88263V11.375ZM12.25 3.8675L7 6.86788L1.75 3.8675V2.625H12.25V3.8675Z"
      fill="#A0A6B2"
    />
  </svg>
);

export const phone = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3744 10.7668L11.1186 8.49701C10.7774 8.15314 10.2218 8.15314 9.87963 8.49526L7.875 10.4999L3.5 6.12489L5.50725 4.11764C5.8485 3.77639 5.84938 3.22251 5.50813 2.88039L3.25062 0.619387C2.9085 0.277262 2.35462 0.277262 2.0125 0.619387L0.0035 2.62751L0 2.62489C0 8.90739 5.0925 13.9999 11.375 13.9999L13.3726 12.0023C13.7139 11.661 13.7148 11.1089 13.3744 10.7668Z"
      fill="#A0A6B2"
    />
  </svg>
);

export const eye = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00001 12.25C10.15 12.25 12.6 9.5375 13.65 7.9625C14.0875 7.35 14.0875 6.5625 13.65 5.95C12.6 4.4625 10.15 1.75 7.00001 1.75C3.85001 1.75 1.40001 4.4625 0.350006 6.0375C-0.0874939 6.65 -0.0874939 7.4375 0.350006 7.9625C1.40001 9.5375 3.85001 12.25 7.00001 12.25ZM7.00001 4.375C8.48751 4.375 9.62501 5.5125 9.62501 7C9.62501 8.4875 8.48751 9.625 7.00001 9.625C5.51251 9.625 4.37501 8.4875 4.37501 7C4.37501 5.5125 5.51251 4.375 7.00001 4.375Z"
      fill="#A0A6B2"
    />
  </svg>
);
