import ReactPixel from 'react-facebook-pixel';

export const sendFacebookSignupData = signupData => {
  if (
    !process.env.REACT_APP_FACEBOOK_PIXEL_ID ||
    !process.env.REACT_APP_FACEBOOK_PIXEL_REGISTER_TRACKING_ENABLED ||
    !signupData ||
    !signupData.email
  )
    return;
  //Track facebook pixel complete sign up
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, {}, options);

  ReactPixel.track('CompleteRegistration', {}, { eventID: 'complete_registration_app' });
};
