import styled, { css } from 'styled-components';
import S3ImageWithFallback from 'shared/S3ImageWithFallback';
import UnitInputString from '../../../UnitInputString';
import { ReactComponent as MoveIcon } from 'assets/icons/nav_icon.svg';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const Remove = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ffd8dd;
    svg path {
      fill: #ea314a;
    }
  }
  .__react_component_tooltip {
    ${baseText}
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }
`;

export const IngredientItemWrapper = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 5px;
  background-color: #fff;
  min-height: 56px;
  &:hover {
    ${Remove} {
      visibility: visible;
    }
  }
  ${props =>
    props.isDragging &&
    css`
      box-shadow: 0px 2px 8px 0px #26262633;
    `}

  ${props =>
    props.isEdit &&
    css`
      cursor: pointer;
    `}
`;

export const MoveIconWrapper = styled(MoveIcon)`
  flex-shrink: 0;
`;

export const Thumbnail = styled(S3ImageWithFallback)`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  img {
    object-fit: contain;
  }
`;

export const Content = styled.div`
  flex: 1;
  border-bottom: 1px solid #f0f0f2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
`;

export const FormWrapper = styled.div`
  position: relative;
`;

export const Error = styled.div`
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  color: #ea314a;
  margin-top: 3px;
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const UnitInputWrapper = styled(UnitInputString)`
  width: 95px;
  height: 30px;
`;

export const Name = styled.div`
  ${baseText}
`;

export const Value = styled.div`
  ${baseText}
`;

export const UnitWrapper = styled.div`
  width: 145px;
  height: 30px;
  .unit-select-trigger-wrapper {
    padding: 0 30px 0 10px;
    svg {
      right: 14px;
    }
  }
`;
