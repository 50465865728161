// Libs
import React from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';

// Shared
import DropDown, { Option } from 'shared/Dropdown/Basic';
import { MenuTrigger } from 'shared/Icons';

// Utils
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';
import { isAdmin, isOnwer } from 'utils/validations';

// Assets
import { ReactComponent as ArrowIcon } from 'assets/icons/OnboardingFlow/arrow_right.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/OnboardingFlow/copy_link.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/delete_message_trash_icon.svg';

// Styles
import * as S from './style';

const INVITE_LINK = process.env.REACT_APP_INVITE_LINK;

const ItemInviteLink = ({ item, cloudfrontList, onRemove, onCopy, user }) => {
  const { trainer, invite_code, created_by } = item || {};
  const { _id = '', avatar = '', color = '', full_name = '', first_name = '', last_name = '' } = trainer || {};

  const fullName = full_name || `${first_name || ''} ${last_name || ''}`.trim();

  const renderActions = () => {
    return (
      <DropDown
        className="custom-dropdown-actions"
        triggerIcon={({ open }) => <MenuTrigger className="dropdown-trigger" vertical active={!!open} itemId={_id} />}
        direction="left"
      >
        <Option key="delete" onClick={() => onRemove(item)}>
          <S.OptionIcon>
            <RemoveIcon className="remove-icon" />
          </S.OptionIcon>
          <span>Remove</span>
        </Option>
      </DropDown>
    );
  };

  return (
    <S.Wrapper>
      <S.CoachWrapper>
        <Avatar
          name={getUserShortName(trainer)}
          color={color}
          size="32"
          src={convertS3UrlToCloudFrontUrl(avatar, cloudfrontList, true)}
        />
        <S.CoachName>{fullName}</S.CoachName>
      </S.CoachWrapper>
      <ArrowIcon className="custom-arrow-icon" />
      <S.CopyWrapper>
        <S.TextUrl>{`${INVITE_LINK}/onboarding/${invite_code}`}</S.TextUrl>
        <S.CopyButton
          data-tip
          data-for={`copy-${_id}`}
          onClick={() => onCopy(`${INVITE_LINK}/onboarding/${invite_code}`)}
        >
          <CopyIcon className="custom-copy-icon" />
        </S.CopyButton>
        <ReactTooltip className="app-tooltip copy-tooltip" id={`copy-${_id}`} effect="solid" place="top">
          <p>Copy link</p>
        </ReactTooltip>
      </S.CopyWrapper>
      {(isOnwer(user) || isAdmin(user) || (user || {})._id === created_by) && <S.Actions>{renderActions()}</S.Actions>}
    </S.Wrapper>
  );
};

export default ItemInviteLink;
