import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .filter__dropdown {
    background-color: #f5f5f5;
    cursor: pointer;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    color: #262626;
    &.active,
    :hover {
      background-color: #f4f4fc !important;
    }
    .filter__dropdown__title {
      height: 100%;
      position: relative;

      .filter-name {
        width: 42px;
        margin-left: 1px;
      }

      .colons {
        margin-left: 1px;
        margin-right: 4px;
        width: 4px;
      }

      .selected {
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        opacity: 1;
      }

      .icon {
        margin-right: 5px;
        flex-shrink: 0;
      }

      .text {
        max-width: 190px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        color: #262626;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .disable {
    background-color: #fafafa !important;
    cursor: not-allowed !important;
    user-select: none !important;

    * {
      color: #bfbfbf !important;
    }
  }
`;

export const MenuContainer = styled.div`
  width: 322px;
  border-radius: 4px;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
  border: solid 1px #e0e4e5;
  background-color: #fff;
  padding: 11px;
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 999;
  .form-group label {
    margin-bottom: 5px;
    color: #96a4b4;
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
  }
  > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
  .ui.dropdown {
    width: 100%;
    padding: 6px 10px;
    border-radius: 4px;
    border: solid 1px #e0e4e5;
    margin-bottom: 6px;
    > .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #343c46;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      svg {
        margin-right: 2px;
        padding-bottom: 1px;
        path {
          stroke: #6f7075;
        }
      }
    }
    .menu {
      width: 100%;
      top: calc(100% + 4px);
      border-radius: 4px;
      border: 1px solid #d9d9d9 !important;
      box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.06);
      .item {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        color: #595959;
        padding: 8px 14px !important;
        :hover,
        &.active {
          font-weight: 400;
          color: #5158cf;
          background-color: #f4f4fc;
        }
      }
    }
    &:hover,
    &.visible.active {
      svg path {
        stroke: #5158cf;
      }
    }
  }
`;

export const SearchInputContainer = styled.div`
  position: relative;
  min-height: 40px;
  max-height: 104px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background: #fff;
  .scroll-container {
    flex: 1;
    max-height: 102px;
    overflow: hidden;
  }
  .scroll-wrapper {
    flex: 1;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 102px;
    max-width: calc(100% - 5px * 2);
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .scroll-wrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15) !important;
  }
  .scroll-wrapper::-webkit-scrollbar {
    width: 6px !important;
  }
  .selected-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    overflow: auto;
    padding: 5px 0px;
    min-height: 38px;
    cursor: text;
  }
`;

export const SearchInput = styled.div`
  ${props =>
    props.isAll &&
    css`
      padding-left: 8px;
    `}
  input {
    outline: none !important;
    border: none !important;
    min-width: 2px;
    max-width: 286px;
    padding: 0;
    line-height: unset !important;
  }
`;

export const SelectedItem = styled.div`
  position: relative;
  max-width: 276px;
  display: flex;
  flex-shrink: 0;
  padding: 6px 8px;
  align-items: flex-end;
  gap: 4px;
  border-radius: 4px;
  background: #f4f4fc;

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #262626;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    white-space: nowrap;
  }

  .icon {
    cursor: pointer;
    flex-shrink: 0;
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  left: 5px;
  color: #bfbfbf;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 8px;
`;

export const ScrollContainer = styled.div`
  flex: 1;
  max-height: 247px;
  overflow: hidden;
`;

export const ScrollWrapper = styled.div`
  flex: 1;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 247px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: ${props => (props.hiddenScrollBar ? '0px' : '6px')};
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15) !important;
  }
  &::-webkit-scrollbar {
    width: 6px !important;
  }
`;

export const ItemGroupWrapper = styled.div`
  background-color: #ffffff;
  :hover {
    background-color: #f4f4fc;
  }
  cursor: pointer;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #dcdcde;
  gap: 6px;
  padding: 8px;

  :last-child {
    border-bottom: unset;
  }
  .group-icon {
    min-width: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .total-clients {
    color: #8c8c8c;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1px;
  }
  .group-content {
    overflow: hidden;
    white-space: nowrap;
    margin-right: 4px;
  }
  .group-name {
    color: #262626;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .group-name strong {
    font-weight: 600;
  }
`;

export const EmptyResultFound = styled.div`
  display: flex;
  height: 192px;
  justify-content: center;
  align-items: center;
  padding-left: 7px;

  .text {
    color: #bfbfbf;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .group-loading {
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
