import styled from 'styled-components';
import ConfirmModal from 'shared/ConfirmModal';
import CopyToClipboard from 'shared/CopyToClipboard';
import { Form, Popup } from 'semantic-ui-react';
import { CoachBiosWrapper } from 'components/CoachBios/CoachBioList/style';

export const Toolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
`;

export const TeammateTab = styled.div`
  color: ${({ active }) => (active ? '#5158cf' : 'rgba(32, 35, 83, 0.6)')};
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
  margin-right: 30px;
  padding-bottom: 12px;
  box-shadow: ${({ active }) => active && '0px -2px 0px 0px #5158cf inset'};

  :hover {
    cursor: pointer;
    color: #5158cf;
  }
`;

export const TableBodyContainer = styled.div`
  flex: 1 1;
  overflow-x: auto;
  overflow-y: auto;
`;

export const TableContainer = styled.div`
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 30px 30px;
  width: 100%;
  ${CoachBiosWrapper} {
    margin-top: 3px;
    padding-left: 0;
  }
`;

export const CopyInviteLink = styled(CopyToClipboard)`
  background: transparent;
  border-radius: 4px;
  color: #263d52;
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  width: 280px;

  :hover {
    background: #eef2f5;
    border-color: transparent;
    font-weight: 600;
    font-size: 12px;
    color: #202353;
  }

  &.copyToClipboard--copied {
    background: #eef2f5;
    border-color: transparent;
    font-weight: 600;
    font-size: 12px;
    color: #202353;
  }
`;

export const IconButton = styled.div`
  svg {
    margin-left: 12px;
    margin-right: 0 !important;
  }

  :hover,
  :active,
  :focus {
    svg {
      rect {
        stroke: #5158cf;
      }

      g > path:nth-child(1) {
        stroke: #5158cf;
        fill: #5158cf;
      }
    }
  }
`;

export const SettingLabel = styled.div`
  color: #202353;
  line-height: 18px;
  margin-bottom: 15px;
  font-family: 'Open Sans';
`;

export const SConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 482px !important;

    .ui.button {
      font-family: 'Open Sans';
    }
  }

  .confirm-content-body {
    padding-bottom: 20px !important;
  }

  .confirm-header-label {
    font-family: 'Open Sans';
  }

  .confirm-content-header {
    padding-bottom: 15px !important;
  }
`;

export const SFormField = styled(Form.Field)`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SFormRadioLabel = styled.label`
  color: #202353 !important;
  font-family: 'Open Sans';
  font-weight: 400 !important;
  line-height: 150%;
`;

export const SFormRadioTextBold = styled.span`
  font-weight: 700;
`;

export const SPopup = styled(Popup)`
  &.action-popup {
    &.ui.popup {
      background: #2d2e2d !important;
      color: white !important;
      padding: 6.5px 12.5px !important;
      border-radius: 5px !important;
      margin-bottom: -1px !important;
      transform: translateX(6px) translateY(-11px) !important;
      box-shadow: none;
    }

    &::before {
      background: #2d2e2d !important;
    }

    &::after {
      background: #2d2e2d !important;
    }
  }

  .content {
    font-size: 0.93rem;
    line-height: 150%;
  }
`;

export const SettingIconWrapper = styled.div`
  > div {
    display: flex;
  }
`;
