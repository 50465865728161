import React from 'react';
import _ from 'lodash';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import queryString from 'query-string';

import { toggleModal } from 'actions/modal';
import ExerciseHistoryRecord from 'components/ExerciseHistoryRecord';
import BodyMetricChartNew from 'components/BodyMetricChartNew';
import ExerciseHistoryChart from 'components/ExerciseHistoryChart';
import BodyMetricProgressNew from 'components/BodyMetricProgressNew';
import GroupBodyMetrics from 'components/GroupBodyMetrics';

import GeneralButton from 'shared/GeneralButton';

import { STEP_UNIQUE_CODE } from 'constants/commonData';
import ClientMetricSetting from 'components/ClientSettings/ClientMetricSetting';
import StepMetric from './components/StepMetric';
import ToolbarBodyMetrics from './components/ToolbarBodyMetrics';
import FooterBodyMetric from './components/FooterBodyMetric';
import GroupMetricOverview from './components/GroupMetricOverview';
import ExerciseSidebar from './components/ExerciseSidebar';

import { ReactComponent as BodyMetricIcon } from 'assets/icons/body_metric_boder_active_new.svg';
import { ReactComponent as ExerciseMetricIcon } from 'assets/icons/exercise_history_boder_new.svg';
import './styles.scss';
import LoadingIndicator from 'shared/LoadingIndicator';

export default class ClientMetric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMetricId: '',
      selectedExercise: null,
      selectMetricHistory: [],
      metricsPage: 1,
      metricHistoryLoading: true,
      metricHistoryTotal: 0,
      isShowingMetric: true,
      typingTimeout: 0,
      openSearch: false,
      textSearch: '',
      showAddMetricPopup: false,
      isFirstTime: true,
    };
    this.selectBodyMetricDebounce = _.debounce(this.selectBodyMetric, 300);
  }

  UNSAFE_componentWillMount() {
    const { getGroupMetrics, match, searchGroupMetricsByName, getClientIntegration } = this.props;
    const clientId = _.get(match, 'params.clientId', '');
    searchGroupMetricsByName && searchGroupMetricsByName('');
    if (typeof getGroupMetrics === 'function') {
      getGroupMetrics(clientId);
    }
    if (clientId && getClientIntegration) {
      getClientIntegration(clientId);
    }
  }

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    this.setState({ isShowingMetric: parsed.tab !== 'exercise' });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      bodymetricTypes,
      selectedMetric,
      resetBodyMetricData,
      location: { search: locationSearch },
    } = nextProps;
    const parsed = queryString.parse(locationSearch);

    if (this.props.location.search !== locationSearch && !locationSearch && nextProps.selectedMetric.mid) {
      nextProps.resetBodyMetricData();
    }

    if (bodymetricTypes.length > 0) {
      // check current metric is selected or not
      // reset selected metric if not
      if (parsed.unique_code) {
        let unique_code = '';
        try {
          unique_code = decodeURIComponent(parsed.unique_code);
        } catch (error) {
          unique_code = parsed.unique_code;
        }
        const metric = _.find(bodymetricTypes, item => item.unique_code === unique_code);

        if (!metric) {
          resetBodyMetricData();
          this.props.dispatch(push(`/home/client/${this.props.match.params.clientId}/metrics`));
          return;
        } else {
          const newSelectedMetric = bodymetricTypes.find(item => {
            return item.unique_code === parsed.unique_code;
          });

          if (newSelectedMetric && !selectedMetric.mid) {
            const unitObject = this.getUnitObject(newSelectedMetric);
            const params = {
              unique_code: newSelectedMetric.unique_code || '',
              mid: newSelectedMetric._id || '',
              name: newSelectedMetric.name,
              unit: unitObject.title,
              unitId: unitObject._id,
            };
            this.props.updateMetricData(params);
          }
        }
      }

      // load steps data
      if (selectedMetric.mid !== this.state.selectedMetricId) {
        this.setState(
          {
            selectedMetricId: selectedMetric.mid,
            selectMetricHistory: [],
            metricsPage: 1,
            metricHistoryLoading: true,
            metricHistoryTotal: 0,
          },
          () => {
            if (selectedMetric.unit === 'steps') {
              const metric = _.find(bodymetricTypes, item => item._id === (selectedMetric || {}).mid);
              this.selectBodyMetricDebounce({ selectedMetric: metric, isReset: true });
            }
          },
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.resetBodyMetricData();
  }

  componentDidUpdate() {
    if (this.state.isShowingMetric && !this.isMetricEnable() && this.state.isFirstTime) {
      this.setState({ isShowingMetric: false, isFirstTime: false });
    }
  }

  isMetricEnable = () => {
    const { isBodyMetricFeaturing, bodymetricTypes } = this.props;
    return isBodyMetricFeaturing && bodymetricTypes && bodymetricTypes.length > 0;
  };

  getUnitObject(item) {
    if (item.unit) {
      return item.unit;
    } else if (item.category && item.category.default_unit) {
      return item.category.default_unit;
    }
    return {};
  }

  fetchMetricHistory = async (params, isLoadMore = false) => {
    try {
      if (!this) return;
      const { selectMetricHistory, metricsPage } = this.state;
      const response = await this.props.getBodyMetricEntryHistory(params);
      const { data = [], total = 0 } = response.data || {};

      this.setState({
        selectMetricHistory: isLoadMore ? selectMetricHistory.concat(data) : data,
        metricHistoryTotal: total,
        metricHistoryLoading: false,
        metricsPage: metricsPage + (isLoadMore ? 1 : 0),
      });
    } catch (error) {
      this.setState({ metricHistoryLoading: false });
    }
  };

  selectBodyMetric = async ({ selectedMetric = {}, callback, isReset = false } = {}) => {
    this.setState({ selectMetricHistory: [], metricsPage: 1, metricHistoryLoading: true, metricHistoryTotal: 0 });

    if (selectedMetric.unique_code === STEP_UNIQUE_CODE) {
      this.props.changeStepMetricSelected(null);
      return;
    }

    this.props.changeStepMetricSelected(selectedMetric.unit.unique_code === STEP_UNIQUE_CODE ? selectedMetric : null);

    let unitObject = this.getUnitObject(selectedMetric);
    let params = {
      unique_code: selectedMetric.unique_code || '',
      mid: selectedMetric._id || '',
      name: selectedMetric.name,
      unit: unitObject.title,
      unitId: unitObject._id,
    };
    if (isReset) {
      params = { ...params, chartData: [], target: {} };
    }
    this.props.updateMetricData(params);
    await this.fetchMetricHistory({
      client: this.props.match.params.clientId,
      timezone: this.props.user.timezone,
      page: 1,
      per_page: 100,
      unique_code: selectedMetric.unique_code || '',
    });

    if (typeof callback === 'function') {
      callback();
    }
  };

  handleLoadMore = () => {
    const selectedMetric = _.find(this.props.bodymetricTypes, item => item._id === this.state.selectedMetricId);
    const { metricsPage } = this.state;
    this.setState({ metricHistoryLoading: true });
    this.fetchMetricHistory(
      {
        client: this.props.match.params.clientId,
        timezone: this.props.user.timezone,
        page: metricsPage + 1,
        per_page: 100,
        unique_code: selectedMetric.unique_code || '',
      },
      true,
    );
  };

  render() {
    if (!this.props.selectedClient) {
      return null;
    }
    return (
      <div className="client-metric-container">
        {this.renderSidebar()}
        {this.renderContent()}
      </div>
    );
  }

  handleRefreshBodyMetric = callback => {
    const { bodymetricTypes, getGroupMetrics, selectedMetric, match } = this.props;
    const clientId = _.get(match, 'params.clientId', '');

    const metric = _.find(bodymetricTypes, item => item._id === (selectedMetric || {}).mid);
    if (metric) {
      this.selectBodyMetricDebounce({ selectedMetric: metric, callback });
    } else {
      getGroupMetrics(clientId);
    }
  };

  refreshManualStepChart = async callback => {
    const { bodymetricTypes, match, user } = this.props;
    const { metricsPage, selectedMetricId } = this.state;
    const selectedMetric = _.find(bodymetricTypes, item => item._id === selectedMetricId) || {};

    this.setState({ metricHistoryLoading: true });
    this.fetchMetricHistory(
      {
        client: match.params.clientId,
        timezone: user.timezone,
        page: metricsPage,
        per_page: 100,
        unique_code: selectedMetric.unique_code || '',
      },
      false,
    );

    if (typeof callback === 'function') {
      callback();
    }
  };

  getLoadingMetricChart = () => {
    const {
      isChangeSetting,
      isGettingChartData,
      isGettingTarget,
      isGettingSetting,
      isLoadingExerciseMetric = false,
      isLoadingCompareChart,
    } = this.props.selectedMetric || {};
    const loadingMetric =
      isGettingChartData || isGettingTarget || isGettingSetting || isChangeSetting || isLoadingCompareChart;
    return this.state.isShowingMetric ? loadingMetric : isLoadingExerciseMetric;
  };

  renderSidebar() {
    if (!this.props.selectedClient) {
      return null;
    }

    const {
      dispatch,
      match,
      location,
      body_parts,
      getExerciseHistoryList,
      selectedClient,
      selectedMetric,
      modalities,
      muscleGroup,
      movementPattern,
    } = this.props;
    const { isShowingMetric, selectedExercise } = this.state;
    const exerciseId = _.get(selectedExercise, 'exercise._id', '');
    const unique_code = _.get(selectedMetric, 'unique_code', '');
    const isLoading = this.getLoadingMetricChart();

    const { isChangeSetting, isGettingChartData, isLoadingExerciseMetric = false } = selectedMetric || {};

    return (
      <div className="metric-sidebar">
        <div className={classNames('metric-history-header', { disabled: isLoading })}>
          <div
            className={classNames('metric-history-header-item', { active: isShowingMetric, disabled: isLoading })}
            onClick={() => {
              this.setState({ isShowingMetric: true, openSearch: false });
              dispatch(
                push(
                  `/home/client/${match.params.clientId}/metrics${
                    unique_code ? `?unique_code=${encodeURIComponent(unique_code)}` : ''
                  }`,
                ),
              );
            }}
          >
            <BodyMetricIcon />
            <span>Body Metrics</span>
          </div>
          <div
            className={classNames('metric-history-header-item', { active: !isShowingMetric, disabled: isLoading })}
            onClick={() => {
              this.props.resetFilterTime();
              this.setState({ isShowingMetric: false });
              dispatch(push(`/home/client/${match.params.clientId}/metrics?tab=exercise&exerciseId=${exerciseId}`));
            }}
          >
            <ExerciseMetricIcon />
            <span>Exercise Metrics</span>
          </div>
        </div>
        {isShowingMetric ? (
          this.renderMetricSidebar()
        ) : (
          <ExerciseSidebar
            location={location}
            match={match}
            getExerciseHistoryList={getExerciseHistoryList}
            selectedClient={selectedClient}
            body_parts={body_parts}
            modalities={modalities}
            muscleGroup={muscleGroup}
            movementPattern={movementPattern}
            dispatch={dispatch}
            setSelectedExercise={selectedExercise => {
              this.setState({ selectedExercise });
            }}
            isLoadingExerciseMetric={isLoading}
          />
        )}
      </div>
    );
  }

  renderMetricSidebar() {
    const {
      showGuide,
      addNewMetricGroup,
      runOnboarding,
      toggleModal,
      match,
      push,
      permission,
      searchGroupMetricsByName,
    } = this.props;

    return (
      <React.Fragment>
        <ToolbarBodyMetrics
          addNewMetricGroup={addNewMetricGroup}
          showGuide={showGuide}
          runOnboarding={runOnboarding}
          toggleModal={toggleModal}
          handleRefreshBodyMetric={this.handleRefreshBodyMetric}
          match={match}
          push={push}
          permission={permission}
          searchGroupMetricsByName={searchGroupMetricsByName}
        />
        <GroupBodyMetrics />
        <FooterBodyMetric
          showGuide={showGuide}
          runOnboarding={runOnboarding}
          toggleModal={toggleModal}
          handleRefreshBodyMetric={this.handleRefreshBodyMetric}
        />
      </React.Fragment>
    );
  }

  renderExerciseHistoryContent = () => {
    const { selectedExercise } = this.state;
    const exerciseId = _.get(selectedExercise, 'exercise._id', '');

    if (!exerciseId) {
      return null;
    }

    return (
      <>
        <ExerciseHistoryChart exercise={selectedExercise} />
        <ExerciseHistoryRecord key={'record' + exerciseId} exerciseId={exerciseId} exercise={selectedExercise} />
      </>
    );
  };

  renderMetricDetails = () => {
    const { isShowingMetric, selectMetricHistory } = this.state;
    const { bodymetricTypes, selectedMetric } = this.props;
    const isFeaturing = this.isMetricEnable();
    let isShowingStep = false;
    let isDefaultStep = false;

    if (bodymetricTypes && bodymetricTypes.length && isShowingMetric) {
      const stepObject = _.find(bodymetricTypes, item => item._id === selectedMetric.mid);
      const unitCode = _.get(stepObject, 'unit.unique_code', '');
      if (stepObject && [stepObject.unique_code, unitCode].includes(STEP_UNIQUE_CODE)) {
        isShowingStep = true;
        isDefaultStep = stepObject.unique_code === STEP_UNIQUE_CODE;
      }
    }

    return (
      <div
        className={classNames('metric-content', {
          'exercise-wrapper': !isShowingMetric,
        })}
      >
        <div
          className={classNames('metric-wrapper', {
            'metric-turn-off': isShowingMetric && !isFeaturing,
            'exercise-tab': !isShowingMetric,
          })}
        >
          {isShowingMetric ? (
            isShowingStep ? (
              <div className="step-chart-scroll">
                <StepMetric isDefaultStep={isDefaultStep} />
                {!isDefaultStep && (
                  <BodyMetricProgressNew
                    dataMetric={selectMetricHistory}
                    onLoadMore={this.handleLoadMore}
                    handleRefreshBodyMetric={this.refreshManualStepChart}
                    loading={this.state.metricHistoryLoading}
                    total={this.state.metricHistoryTotal}
                    isManualStepMetric
                  />
                )}
              </div>
            ) : (
              <>
                <BodyMetricChartNew
                  dataMetric={selectMetricHistory}
                  onLoadMore={this.handleLoadMore}
                  loading={this.state.metricHistoryLoading}
                  total={this.state.metricHistoryTotal}
                />
              </>
            )
          ) : (
            <>{this.renderExerciseHistoryContent()}</>
          )}
        </div>
      </div>
    );
  };

  handleTurnOnFeature = () => {
    const { bodymetricTypes, match, dispatch, updateOneFeaturePreferences } = this.props;

    if (!bodymetricTypes || bodymetricTypes.length === 0) {
      dispatch(toggleModal(true, <ClientMetricSetting handleRefreshBodyMetric={this.handleRefreshBodyMetric} />));
    } else {
      updateOneFeaturePreferences({
        client: match.params.clientId,
        type: 'body_metric',
        state: true,
      });
    }
  };

  renderFeaturing = () => {
    return (
      <div className="metric-content">
        <div className="feature-off-info">
          <div className="title">Turn on Body Metrics?</div>
          <div className="content">
            <p>Body Metrics has been turned off for client.</p>
            <p>Would you like to turn on the feature?</p>
          </div>
          <div>
            <GeneralButton onClick={this.handleTurnOnFeature}>Yes</GeneralButton>
          </div>
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { isShowingMetric } = this.state;
    const { selectedMetric, groupMetric } = this.props;
    const isFeaturing = this.isMetricEnable();

    if (selectedMetric.isChangeSetting) {
      return (
        <div className="metric-content metric-overview">
          <div className="group-metrics-overview">
            <div className="group-metrics-overview__header">
              <div className="group-metrics-overview__header-title">
                <LoadingIndicator title="Loading metric" />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (isShowingMetric && !isFeaturing) {
      return this.renderFeaturing();
    }

    // show metrics overview
    if (isShowingMetric && !selectedMetric.mid) {
      const groupKey = _.get(groupMetric, 'selected._id', 'default_group_metric');

      return (
        <div className="metric-content metric-overview" style={{ display: !isFeaturing ? 'none' : 'block' }}>
          <GroupMetricOverview key={groupKey} />
        </div>
      );
    }

    return this.renderMetricDetails();
  };
}
