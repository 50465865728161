import { get } from 'lodash';
import React from 'react';
import LoadingIndicator from 'shared/LoadingIndicator';
import Pagination from 'shared/Pagination';
import { ReactComponent as NoResultsIcon } from 'assets/icons/on-demand-workout-search-no-results.svg';

import EmptyList from '../EmptyList';
import * as S from './style';
import TableContent from './TableContent';
import TableHeader from './TableHeader';
const PAGE_SIZE = 20;

const OnDemandWorkoutList = ({
  data = [],
  removeWorkout,
  toggleConfirmModal,
  customBrandingBackground,
  push,
  loading,
  query,
  cloudfrontList,
  total,
  changeQueryParams,
  toggleModal,
  categoryLabels,
  addNewOnDemandWorkouts,
  user,
  updateShareStatus,
}) => {
  const isFilter =
    get(query, 'level', []).length || get(query, 'duration', []).length || get(query, 'labels', []).length;

  const renderList = () => {
    if (!!query.textSearch || isFilter) {
      return (
        <S.NotFound>
          <NoResultsIcon />
          <div className="text">No results found</div>
        </S.NotFound>
      );
    }
    return (
      <>
        {data.length <= 0 && loading ? (
          renderLoading()
        ) : (
          <EmptyList
            toggleModal={toggleModal}
            categoryLabels={categoryLabels}
            addNewOnDemandWorkouts={addNewOnDemandWorkouts}
          />
        )}
      </>
    );
  };

  const renderLoading = () => {
    return (
      <S.LoadingWrapper>
        <LoadingIndicator title="Loading On-demand workout list" size="medium" className="loading--on-demand-workout" />
      </S.LoadingWrapper>
    );
  };

  return (
    <>
      <S.WorkoutList isPagination={total > PAGE_SIZE}>
        <S.TableWrapper>
          <S.TableContainer>
            <TableHeader counter={total} query={query} changeQueryParams={changeQueryParams} />
            {loading ? (
              renderLoading()
            ) : (
              <TableContent
                push={push}
                workouts={data}
                cloudfrontList={cloudfrontList}
                removeWorkout={removeWorkout}
                toggleConfirmModal={toggleConfirmModal}
                customBrandingBackground={customBrandingBackground}
                toggleModal={toggleModal}
                user={user}
                updateShareStatus={updateShareStatus}
              />
            )}
            {!loading && data.length <= 0 && renderList()}
          </S.TableContainer>
        </S.TableWrapper>
      </S.WorkoutList>
      <Pagination
        page={query.page}
        perPage={query.perPage}
        totalPage={total}
        onPrev={() => {
          changeQueryParams({ page: query.page - 1 });
        }}
        onNext={() => {
          changeQueryParams({ page: query.page + 1 });
        }}
      />
    </>
  );
};

export default OnDemandWorkoutList;
