import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const DefaultUIContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .search-input {
    width: 265px;
    height: 36px;
  }

  .search-filter-container {
    display: flex;
    flex-direction: row;
  }

  button {
    padding: 8px 20px;
  }
`;

export const BannerTextContent = styled.div`
  max-width: 350px;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #1c1919;

  .studio-programs__banner__label {
    font-size: 12px;
    line-height: 150%;
    font-weight: 600;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  .studio-programs__banner__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 150%;
    margin-bottom: 2px;
  }

  .studio-programs__banner__description {
    opacity: 0.8;
    margin-bottom: 30px;
  }
`;

export const BannerContainer = styled.div`
  background: #eadfcb url(${CDN_URL}/images/studio_program_top_banner.png) no-repeat top right;
  background-size: auto 100%;
  padding: 50px 50px 10px;
  min-height: 267px;
  border-radius: 8px;

  .buttons__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    button {
      :first-child {
        margin-right: 10px;
      }
    }
  }

  button {
    &.button--hide-banner {
      border: none;
      background-color: transparent;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #7e7e7e;
      border-radius: 5px;

      :hover {
        background-color: #bdbab0;
      }

      > * {
        :first-child {
          margin-right: 10px;
        }
      }
    }

    &.button--create-new {
      background-color: #fff;
      padding: 10px 15px;
      color: #000;

      svg {
        margin-right: 10px;
      }
    }

    &.button--search-box-trigger {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
      min-width: unset;

      :hover {
        background: rgba(0, 0, 0, 0.1);
        border: 1px solid #000000;

        svg {
          path {
            stroke: #000000;
          }
        }
      }
    }
  }

  .search-input {
    width: 272px;
    height: 40px;
    border-radius: 5px;

    input {
      background-color: #ffffff;
      border-color: #fff;
      background-image: url(${CDN_URL}/images/search_black.svg);
      background-position: 11px center;
    }
  }

  .button-hidden-container {
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  margin: 0 30px 20px;

  ${props =>
    props.showBanner &&
    css`
      margin: 0 0px 20px;
      margin-bottom: 50px;
    `}
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  .filter-options {
    .ui.button {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      border: none !important;
      :hover {
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.04),
          0px 0px 4px 0px rgba(0, 0, 0, 0.06);
        background-color: rgb(245, 247, 249) !important;
      }

      :focus {
        background-color: #fff !important;
      }
      margin-right: unset;
    }
  }
  .filter-options-header {
    .ui.button {
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 12px;
      border: 1px solid #d4d7d9 !important;
      margin-right: unset;

      :hover {
        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.04),
          0px 0px 4px 0px rgba(0, 0, 0, 0.06);
        background-color: rgb(245, 247, 249) !important;
      }

      :focus {
        background-color: #fff !important;
      }
    }
  }
`;

export const CounterFilters = styled.div`
  right: -11px;
  top: -11px;
  width: 22px;
  height: 22px;
  border: 3px solid #fff;
  position: absolute;
  background: #ffbe49;
  border-radius: 6px;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
`;

export const ResetFilter = styled.div`
  display: inline-flex;
  padding-left: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
  background: transparent url(${CDN_URL}/images/reset_new.svg) no-repeat 0 center;
  background-size: auto;
  align-items: center;

  :hover {
    cursor: pointer;
    background-image: url(${CDN_URL}/images/reset_new_purple.svg);
    color: #5158cf;
  }

  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;
