import React from 'react';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';
import { CDN_URL } from 'constants/commonData';
import { FallbackWrapper } from '../style';

export default function ProgramFallback(props) {
  return (
    <FallbackWrapper>
      <div className="fallback__image fallback__image--program">
        <img src={`${CDN_URL}/images/upgrade_path_program.png`} alt="upgrade_path_program.png" />
      </div>
      <div className="fallback__content fallback__content--program">
        <div className="fallback__contentTitle fallback__contentTitle--program">Templates for Easy Training</div>
        <div className="fallback__contentDescription fallback__contentDescription--program">
          Quickly combine workouts into weekly or monthly programs and assign them easily with just a click.
        </div>
      </div>
      <UpgradePathButton text="Show me the Magic" />
    </FallbackWrapper>
  );
}
