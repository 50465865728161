import React, { useState, useEffect } from 'react';
import * as S from './style';

const FirstTimeWrapper = ({
  editCollectionOnboarding,
  isPassedFirstTimeAdd,
  shouldShowOnBoarding,
  setShouldShowOnBoarding,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isPassedFirstTimeAdd) setOpen(false);
    else setOpen(true);
  }, [isPassedFirstTimeAdd]);

  useEffect(() => {
    if (isPassedFirstTimeAdd) {
      setOpen(shouldShowOnBoarding);
    }
  }, [shouldShowOnBoarding]);

  const handleClose = () => {
    if (!isPassedFirstTimeAdd) {
      setOpen(false);
      editCollectionOnboarding && editCollectionOnboarding();
    } else {
      setShouldShowOnBoarding(false);
    }
  };

  return (
    open && (
      <S.FirstTimeWrapper>
        {!shouldShowOnBoarding && <S.Aura />}
        <S.PlaceholderMessage>
          <S.PlaceholderTitle>Collection Type</S.PlaceholderTitle>
          <S.PlaceholderDesc>
            Add a short label that will show above the Collection Name on the list of On-demand Collections.
          </S.PlaceholderDesc>
          <S.PlaceholderPreview />
          <S.PlaceholderActions>
            <S.PlaceholderConfirm onClick={handleClose}>Got it</S.PlaceholderConfirm>
          </S.PlaceholderActions>
        </S.PlaceholderMessage>
      </S.FirstTimeWrapper>
    )
  );
};

export default FirstTimeWrapper;
