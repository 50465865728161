import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { RootCloseWrapper } from 'react-overlays';
import ReactTooltip from 'react-tooltip';
import * as S from './style';
import { CONVERSION, listWarningMessage } from 'constants/commonData';
import { getMediaType, getPresignedUploadUrl, mediaLog } from 'utils/commonFunction';
import { onlyInsertPlainTextCustom } from 'utils/event-api';
import * as Icons from './icons';
import { MESSAGE_TYPES, AUTOFILL_ITEMS, FILE_VALIDATIONS } from '../constants';
import { SelectedFiles, SelectFileInput } from './SelectedFiles';

class ChatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasText: false,
      selectedFiles: [],
      openAutofill: false,
      triggerFromButton: '',
      autofillItems: AUTOFILL_ITEMS,
      selectingItem: 0,
    };
    this.maxImageSize = FILE_VALIDATIONS.MAX_IMAGE_SIZE * CONVERSION.MB_TO_BYTE;
    this.maxVideoSize = FILE_VALIDATIONS.MAX_VIDEO_SIZE * CONVERSION.MB_TO_BYTE;
    this.inputRef = React.createRef();
    this.autofillRef = React.createRef();
    this.autofillTriggerRef = React.createRef();
    this.autofillPopupStyles = {};
    this.checkingString = '';
    this.currentRange = null;
    this.selection = null;
  }

  componentDidMount() {
    this.inputRef.current.focus();
    document.execCommand('formatBlock', false, 'p');
    this.updateCurrentRange();

    const rect = this.autofillTriggerRef.current.getBoundingClientRect();

    const { x, y } = rect;
    const windowHeight = window.innerHeight;

    this.autofillPopupStyles = { left: x, bottom: windowHeight - y + 5 };
    document.addEventListener('keydown', this.handleDocumentKeyDown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!this.props.disabled && prevProps.disabled) ||
      (!this.state.selectedFiles.length && prevState.selectedFiles.length)
    ) {
      this.inputRef.current.focus();
      document.execCommand('formatBlock', false, 'p');
      this.updateCurrentRange();
    }
    if (this.state.hasText || this.state.selectedFiles.length > 0) {
      this.props.setHasTextInInput(true);
    } else {
      this.props.setHasTextInInput(false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
  }

  handleDocumentKeyDown = event => {
    if (event.target.isContentEditable) {
      if (event.target.id === 'inAppMessage__input') {
        if (event.key === 'Backspace' && !event.target.textContent) {
          event.preventDefault();
          return;
        }
      } else {
        return;
      }
    }

    this.handleKeyDown(event);
  };

  handleKeyDown = event => {
    const { openAutofill, autofillItems, selectingItem } = this.state;

    if (!openAutofill || !autofillItems.length) {
      return;
    }

    const key = event.keyCode;

    if (key === 13) {
      // Enter
      if (!event.shiftKey) {
        event.preventDefault();
        this.onAddAutofill(autofillItems[selectingItem]);
      }

      return;
    }

    if (autofillItems.length === 1) {
      return;
    }

    let newIndex = selectingItem;

    if (key === 40) {
      // arrow down
      event.preventDefault();
      newIndex = selectingItem === autofillItems.length - 1 ? 0 : selectingItem + 1;
    } else if (key === 38) {
      // arrow up
      event.preventDefault();
      newIndex = selectingItem === 0 ? autofillItems.length - 1 : selectingItem - 1;
    }

    this.setState({ selectingItem: newIndex });
  };

  onKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (!event.shiftKey) {
        this.onSubmit();
        this.updateCurrentRange();
      } else {
        if (event.target.textContent) {
          document.execCommand('insertParagraph', false);
        }
      }
    }
  };

  onSubmit = () => {
    const { disabled } = this.props;

    if (disabled) {
      return false;
    }

    const { selectedFiles } = this.state;
    let message;

    if (selectedFiles.length) {
      message = { ...selectedFiles[0], type: MESSAGE_TYPES.FILE };
    } else {
      const value = this.inputRef.current.innerText;
      const trimValue = value.trimAny('↵').trim();

      if (trimValue) {
        if (!this.inputRef.current.children.length) {
          message = { type: MESSAGE_TYPES.TEXT, content: trimValue };
        } else {
          const cloneNode = this.inputRef.current.cloneNode(true);

          const markNodes = [];
          Array.prototype.forEach.call(cloneNode.children, p => {
            Array.prototype.forEach.call(p.children, node => {
              if (node.nodeName === 'SPAN' && node.className.includes('autofill')) {
                markNodes.push(node);
              }
            });
          });

          markNodes.forEach(n => {
            n.replaceWith(document.createTextNode(n.textContent.toUpperCase()));
          });

          const listContent = [];

          Array.prototype.forEach.call(cloneNode.children, p => {
            if (p.textContent) {
              listContent.push(p.textContent);
              listContent.push('\n');
            } else {
              listContent.push('\n');
            }
          });

          message = { type: MESSAGE_TYPES.TEXT, content: listContent.join('').trimAny('↵').trim() };
        }
      }

      this.inputRef.current.innerText = '';
      this.inputRef.current.focus();
      document.execCommand('formatBlock', false, 'p');
      this.updateCurrentRange();
      this.checkingString = '';
    }

    this.setState({ selectedFiles: [], hasText: false, openAutofill: false });

    if (message && message.content) {
      // Convert warning message when copy text
      let contentMain = message.content;
      let contentCheck = message.content;
      const listTag = [];
      let check = true;
      // Loop to get substring "{...}"
      while (check) {
        const first = contentCheck.indexOf('{');
        const last = contentCheck.indexOf('}');
        if (first === -1 || last === -1) {
          check = false;
        } else {
          const text = contentCheck.slice(first, last + 1);
          listTag.push(text);
          contentCheck = contentCheck.replace(text, '');
        }
      }
      // Replace substring convert
      const listTagFilter = listTag.filter(item => listWarningMessage.includes(item.toUpperCase()));
      let arrToString = listTagFilter.join('|');
      const regexConvert = new RegExp(arrToString, 'gi');
      let result = contentMain.replace(regexConvert, function (x) {
        return x.toUpperCase().replace(' ', '_');
      });
      // Update data submit
      message.content = result;
    }

    if (message) {
      message._id = `local_message_${new Date().getTime()}`;
      this.props.onSubmit(message);
    }
  };

  replaceCurrentNodeByTextNode = (replacedNode, endOffset) => {
    const textNode = document.createTextNode(replacedNode.textContent);
    replacedNode.replaceWith(textNode);
    const sel = window.getSelection();
    const range = document.createRange();
    range.setStart(textNode, endOffset);
    sel.removeAllRanges();
    sel.addRange(range);
    this.currentRange = range;

    return textNode;
  };

  isShowAutofill = () => {
    let match = [],
      checkingString = '',
      selection;

    if (!this.currentRange) {
      return false;
    }

    try {
      const caretPos = this.currentRange.endOffset;
      let node = this.currentRange.commonAncestorContainer;
      let start;
      const { children } = this.inputRef.current;

      while (node.nodeName !== 'P' && !node.contentEditable) {
        node = node.parentElement;
      }

      const pIndex = Array.prototype.indexOf.call(children, node);

      if (pIndex !== -1) {
        if (children[pIndex].textContent.includes('{')) {
          const pChildNodes = children[pIndex].childNodes;

          const rangeIndex = Array.prototype.indexOf.call(
            children[pIndex].childNodes,
            this.currentRange.commonAncestorContainer,
          );

          for (let i = rangeIndex; i > -1; i--) {
            if (pChildNodes[i].nodeType === 3) {
              const bracketIndex = pChildNodes[i].textContent.lastIndexOf('{');

              if (
                bracketIndex !== -1 &&
                (bracketIndex === 0 || !pChildNodes[i].textContent[bracketIndex - 1].trim()) &&
                (i < rangeIndex || (i === rangeIndex && bracketIndex < caretPos))
              ) {
                start = { node: pChildNodes[i], offset: bracketIndex };
                break;
              }
            }
          }
        } else {
          for (let i = pIndex - 1; i > -1; i--) {
            const pChildNodes = children[i].childNodes;

            for (let k = pChildNodes.length - 1; k > -1; k--) {
              if (pChildNodes[k].nodeType === 3) {
                const bracketIndex = pChildNodes[k].textContent.lastIndexOf('{');

                if (
                  bracketIndex !== -1 &&
                  (bracketIndex === 0 || !pChildNodes[i].textContent[bracketIndex - 1].trim())
                ) {
                  start = { node: pChildNodes[k], offset: bracketIndex };
                  break;
                }
              }
            }

            if (start) {
              break;
            }
          }
        }
      }

      if (start) {
        selection = document.createRange();
        selection.setStart(start.node, start.offset);
        selection.setEnd(this.currentRange.commonAncestorContainer, this.currentRange.endOffset);
        const str = selection.toString();

        if (str) {
          checkingString = str.length === 1 ? str : str.substr(1).toUpperCase();
          match = _.filter(AUTOFILL_ITEMS, item => item.value.includes(checkingString));
        }
      }
    } catch (error) {
      console.error('*** Handle Error ***', error);
    }

    return { match, checkingString, selection };
  };

  onInput = event => {
    if (!event.nativeEvent.inputType) {
      return;
    }

    const text = event.target.textContent;
    const { hasText, openAutofill } = this.state;
    this.updateCurrentRange();

    if (!text) {
      if (!event.target.children.length) {
        document.execCommand('formatBlock', false, 'p');
      }

      const sel = window.getSelection();
      const range = document.createRange();
      range.setStart(event.target.children[0], 0);
      sel.removeAllRanges();
      sel.addRange(range);
      this.setState({ hasText: false, openAutofill: false });
      this.checkingString = '';
      this.currentRange = range;
    } else {
      const updatedStates = {};

      if (!hasText) {
        updatedStates.hasText = true;
      }

      if (this.currentRange) {
        const { commonAncestorContainer, endOffset } = this.currentRange;
        const workingElement =
          commonAncestorContainer.nodeType === 3 ? commonAncestorContainer.parentElement : commonAncestorContainer;

        if (workingElement.nodeName === 'SPAN' && workingElement.className.includes('autofill')) {
          if (AUTOFILL_ITEMS.find(item => item.title === workingElement.textContent)) {
            return;
          }

          let blankText = '';

          if (event.nativeEvent.data && !event.nativeEvent.data.trim()) {
            blankText = event.nativeEvent.data.replace(new RegExp(' ', 'g'), '\u00A0');
          }

          if (blankText && (endOffset === 1 || endOffset === workingElement.textContent.length)) {
            // insert blank text to before or after current node
            try {
              const text = document.createTextNode(blankText);
              const sel = window.getSelection();
              const newRange = document.createRange();
              const referenceNode = this.currentRange.endOffset === 1 ? workingElement : workingElement.nextSibling;

              workingElement.parentElement.insertBefore(text, referenceNode);
              newRange.setStart(text, blankText.length);
              newRange.collapse(false);
              sel.removeAllRanges();
              sel.addRange(newRange);
              const newContent =
                this.currentRange.endOffset === 1
                  ? workingElement.textContent.slice(1)
                  : workingElement.textContent.slice(0, workingElement.textContent.length - blankText.length);
              workingElement.innerText = newContent;
              this.currentRange = newRange;
            } catch (error) {
              console.error('ERROR: ', error);
            }
          } else {
            // should replace mark element by text node if the text content of element isn't match
            const textNode = this.replaceCurrentNodeByTextNode(workingElement, endOffset);

            if (event.nativeEvent.inputType === 'insertParagraph') {
              /// handle break new line when the caret inside a mark element
              const previousParagraph = textNode.parentElement && textNode.parentElement.previousElementSibling;

              if (previousParagraph && previousParagraph.nodeName === 'P') {
                const totalElement = previousParagraph.childElementCount;
                const lastChildElement = previousParagraph.children[totalElement - 1];

                if (
                  lastChildElement &&
                  lastChildElement.nodeName === 'SPAN' &&
                  lastChildElement.className.includes('autofill') &&
                  !AUTOFILL_ITEMS.find(item => item.title === lastChildElement.textContent)
                ) {
                  lastChildElement.replaceWith(document.createTextNode(lastChildElement.textContent));
                }
              }
            }
          }
        } else if (workingElement.nodeName !== 'P') {
          try {
            let replacedNode = workingElement;

            while (replacedNode.parentElement.nodeName !== 'P') {
              replacedNode = replacedNode.parentElement;
            }

            this.replaceCurrentNodeByTextNode(replacedNode, endOffset);
          } catch (error) {
            console.error('ERROR: ', error);
          }
        }

        const { match, checkingString, selection } = this.isShowAutofill();
        this.checkingString = checkingString;
        this.selection = selection;
        updatedStates.openAutofill = !!match.length;

        if (updatedStates.hasText === hasText && updatedStates.openAutofill === openAutofill) {
          return;
        }

        if (updatedStates.openAutofill) {
          const { x, y } = this.currentRange.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          this.autofillPopupStyles = { left: x - 10, bottom: windowHeight - y + 5 };
          this.checkingString = checkingString;
          this.selection = selection;
          updatedStates.autofillItems = match;
          updatedStates.selectingItem = 0;
        } else {
          this.checkingString = '';
          this.selection = null;
        }
      } else {
        updatedStates.openAutofill = false;
      }

      this.setState({ ...updatedStates });
    }
  };

  onSelectFile = async event => {
    const { files } = event.target;

    if (files.length) {
      let error = '';
      const file = files[0];
      const { size, name, type } = file;

      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file via InAppMessage',
      });

      const fileExtension = name.split('.').pop().toLowerCase();
      if (!FILE_VALIDATIONS.EXTENTIONS.includes(fileExtension)) {
        error = `Please use supported file types (${FILE_VALIDATIONS.EXTENTIONS.join(', ')})`;
      } else if (size > this.maxImageSize && FILE_VALIDATIONS.IMAGE_TYPES.includes(fileExtension)) {
        error = `Please upload an image smaller than ${FILE_VALIDATIONS.MAX_IMAGE_SIZE} MB`;
      } else if (size > this.maxVideoSize && FILE_VALIDATIONS.VIDEO_TYPES.includes(fileExtension)) {
        error = `Please resize and update a video smaller than ${FILE_VALIDATIONS.MAX_VIDEO_SIZE} MB`;
      }

      if (error) {
        return toast.error(error);
      } else {
        const { uploadUrl } = await getPresignedUploadUrl('/api/file/gen-presigned-urls-inbox', file);

        const uploadConfigs = {
          url: uploadUrl,
          method: 'put',
          data: file,
          headers: { 'Content-Type': file.type },
        };

        this.setState({
          selectedFiles: [
            {
              uploadConfigs,
              objectURL: window.URL.createObjectURL(file),
              attachment_type: getMediaType(file),
            },
          ],
        });
      }

      return;
    }

    event.target.value = '';
  };

  onDeleteSelectedFile = index => {
    const newFiles = this.state.selectedFiles.slice();
    newFiles.splice(index, 1);
    this.setState({ selectedFiles: newFiles });
  };

  onAutofillIconClick = () => {
    if (!this.state.openAutofill && !this.props.disabled) {
      const rect = this.autofillTriggerRef.current.getBoundingClientRect();

      const { x, y } = rect;
      const windowHeight = window.innerHeight;

      this.autofillPopupStyles = { left: x, bottom: windowHeight - y + 5 };

      this.setState({
        openAutofill: true,
        triggerFromButton: true,
        autofillItems: AUTOFILL_ITEMS,
        selectingItem: 0,
      });
    }
  };

  onCloseAutofill = () => {
    this.setState({ openAutofill: false, triggerFromButton: false });
  };

  getCurrentRange = () => {
    let range, sel;

    try {
      if (window.getSelection) {
        sel = window.getSelection();

        if (sel.rangeCount) {
          range = sel.getRangeAt(0);
        }
      }
    } catch (err) {
      console.error('Can not get range', err);
    }

    return range;
  };

  updateCurrentRange = () => {
    let range, sel;

    try {
      if (window.getSelection) {
        sel = window.getSelection();

        if (sel.rangeCount) {
          range = sel.getRangeAt(0);
        }
      }
    } catch (err) {
      console.error('Can not get range', err);
    }

    this.currentRange = range;
  };

  onAddAutofill = data => {
    const range = this.selection || this.currentRange;

    if (range) {
      const sel = window.getSelection();
      const span = document.createElement('span');
      span.classList.add('autofill');
      const endTextNode = document.createTextNode('\u00A0');
      span.appendChild(document.createTextNode(data.title));
      range.insertNode(endTextNode);
      range.insertNode(span);
      range.setStartAfter(endTextNode);
      sel.removeAllRanges();
      sel.addRange(range);
      this.currentRange = range;
      document.execCommand('insertText', false, '');
      this.setState({ hasText: true });
    } else {
      console.error('Range undefined', this.currentRange);
    }

    this.setState({ openAutofill: false });
  };

  scrollDownAfterPasteInput = () => {
    setTimeout(() => {
      const { inputRef } = this;
      if (inputRef.current) {
        const { scrollHeight } = inputRef.current;
        inputRef.current.scroll({ top: scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  };

  render() {
    const { placeholder, disabled } = this.props;
    const { selectedFiles, hasText, openAutofill, triggerFromButton, autofillItems, selectingItem } = this.state;

    return (
      <S.Wrapper className="inAppMessage__chatBar">
        <S.Container className="inAppMessage__chatBar__container">
          <S.ControlsContainer className="inAppMessage__chatBar__controls">
            {selectedFiles.length ? (
              <SelectedFiles onDelete={this.onDeleteSelectedFile} selectedFiles={selectedFiles} />
            ) : (
              <>
                <SelectFileInput disabled={disabled} hasText={hasText} onChange={this.onSelectFile} />
                <S.AutofillTrigger
                  onClick={this.onAutofillIconClick}
                  className={classnames('inAppMessage__autofill__trigger', {
                    active: openAutofill && triggerFromButton,
                  })}
                  ref={this.autofillTriggerRef}
                  data-tip
                  data-for="tooltip--inAppMessageAutofill"
                  disabled={disabled}
                >
                  {Icons.Autofill}
                </S.AutofillTrigger>
                <ReactTooltip
                  id="tooltip--inAppMessageAutofill"
                  className="app-tooltip"
                  place="top"
                  effect="solid"
                  globalEventOff="click"
                >
                  <span>Autofill Variables</span>
                </ReactTooltip>
                <S.InputContainer className="inAppMessage__chatBar__input__container">
                  <S.Input
                    contentEditable={!disabled}
                    onKeyPress={this.onKeyPress}
                    onMouseUp={this.updateCurrentRange}
                    onKeyUp={this.updateCurrentRange}
                    ref={this.inputRef}
                    placeholder={placeholder || ''}
                    onPaste={e => {
                      this.setState({ hasText: true });
                      this.scrollDownAfterPasteInput();
                      onlyInsertPlainTextCustom(e);
                    }}
                    onInput={this.onInput}
                    className="input"
                    id="inAppMessage__input"
                  />
                  {!hasText && <div className="inAppMessage__chatBar__input__placeholder">Type a message here</div>}
                </S.InputContainer>
              </>
            )}
          </S.ControlsContainer>
          <S.SubmitButton
            onClick={this.onSubmit}
            className="post"
            disabled={disabled || (!hasText && !selectedFiles.length)}
          />
        </S.Container>
        {openAutofill ? (
          <RootCloseWrapper disabled={!openAutofill} onRootClose={this.onCloseAutofill}>
            <S.AutofillPopup
              className={classnames('inAppMessage__autofill', { open: openAutofill })}
              style={this.autofillPopupStyles}
              ref={this.autofillRef}
            >
              {autofillItems.map((item, index) => (
                <S.AutofillItem
                  key={item.value}
                  className={classnames('inAppMessage__autofill__item', {
                    selecting: index === selectingItem,
                  })}
                  onClick={() => this.onAddAutofill(item)}
                >
                  <div className="title">{item.title}</div>
                  <div className="description">{item.description}</div>
                </S.AutofillItem>
              ))}
            </S.AutofillPopup>
          </RootCloseWrapper>
        ) : null}
      </S.Wrapper>
    );
  }
}

ChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  setHasTextInInput: PropTypes.func.isRequired,
};

export default ChatInput;
