import styled from 'styled-components';
import { Button } from 'shared/FormControl';

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #3eb37c;
  margin: 0 1px;
  flex: 0 0 3px;
`;

export const WorkoutDotContainer = styled.div`
  position: absolute;
  bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  left: 0;
  width: 100%;
`;

export const HeaderLeftContent = styled.div`
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #202353;
    margin-bottom: 10px;
  }

  .subTitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #5a636c;
  }
`;

export const DateTabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item {
    border: 1px solid #d4d7d9;
    border-radius: 5px;
    background: #fafbfc;
    padding: 8px 20px 8px 15px;
    min-width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      :hover {
        cursor: pointer;
      }
    }

    :first-child {
      border-right-color: transparent;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -3px;
    }

    :last-child {
      border-left-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.active {
      background: #fff;
      border: 2px solid #5158cf;
      border-radius: 5px;
      z-index: 1;
    }

    .label {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #6a778a;
    }

    .placeholder {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
    }
  }
`;

export const Header = styled.div`
  padding: 30px;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  padding: 0 0 20px;
  min-height: 300px;
`;

export const Footer = styled.div`
  padding: 10px 26px;
  text-align: right;
  border-top: 1px solid #eceef4;

  button {
    width: unset !important;
    min-width: unset !important;
    height: unset !important;
    margin-left: 20px;

    &.save {
      padding: 4px 34px;
    }
  }
`;

export const PopupContainer = styled.div`
  background: #ffffff;
  border: 1px solid #eceef4;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 340px;
  z-index: 9999;
  display: block;
  position: fixed;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
`;

export const Trigger = styled(Button)`
  background: #fff;
  border: 1px solid #d6dae4;
  border-radius: 5px;
  font-weight: 600;
  color: #818da1;
  padding: 5px 30px;

  svg {
    margin-right: 5px;
  }

  :hover {
    cursor: pointer;
    background-color: #f5f7f9;
  }
`;

export const Wrapper = styled.div`
  position: relative;

  &.open {
    ${PopupContainer} {
      display: block;
    }
    ${Trigger} {
      background-color: #f5f7f9;
    }
  }

  .DayPicker {
    width: 668px !important;

    > div {
      > div {
        width: unset !important ;
      }
    }

    table {
      border-collapse: inherit;
      border-spacing: 0;

      td {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .DayPicker_transitionContainer {
      width: unset !important;
    }

    .CalendarDay {
      border: none;
      padding: 0;
      width: unset !important;
      height: unset !important;
      padding-top: 4px;
      padding-bottom: 4px;
      background: #fff !important;

      .CalendarDay__content {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        line-height: 33px;
        text-align: center;
        font-weight: normal;
        font-size: 13px;
        color: #202353;
        position: relative;

        .__react_component_tooltip.app-tooltip {
          width: 117px;
        }

        &.isToday {
          font-weight: bold;
          color: #5158cf;
        }

        &.isEndDateHovering {
          border-right-color: #fff;
        }
    
        &.isStartDateHovering {
          border-left-color: #fff;
        }
      }

      .CalendarDay__content__dayValue {
        width: 33px;
        height: 33px;
        border: 1px solid transparent;
        border-radius: 3px;
        overflow: hidden;
      }

      &__blocked_calendar {
        .CalendarDay__content {
          color: #a7a7b6;
        }
      }

      &__selected {
        .CalendarDay__content {
          color: #fff;
          font-weight: 600;
          border-color: #f4f4f4;
        }

        .CalendarDay__content__dayValue {
          background: #5158cf;
          color: #fff;
        }

        :not(.CalendarDay__selected_end) {
          .CalendarDay__content {
            border-left-color: #fff;
          }
        }

        &.CalendarDay__selected_end {
          .CalendarDay__content {
            border-right-color: #fff;
          }
        }

        &_span {
          .CalendarDay__content {
            background: #f4f4f4;
            font-weight: 600;
          }

          &.CalendarDay__firstDayOfWeek {
            .CalendarDay__content {
              border-left-color: #fff;
            }
          }
          &.CalendarDay__lastDayOfWeek {
            .CalendarDay__content {
              border-right-color: #fff;
            }
          }
        }
      }

      &__hovered_span {
        .CalendarDay__content {
          background: #f4f4f4;
          font-weight: 600;
        }

        &.CalendarDay__firstDayOfWeek {
          .CalendarDay__content {
            border-left-color: #fff;
          }
        }
        &.CalendarDay__lastDayOfWeek {
          .CalendarDay__content {
            border-right-color: #fff;
          }
        }
      }

      &.CalendarDay__hovered_span {
        :last-child {
          .CalendarDay__content {
            border-right-color: #fff;
          }
        }
      }

      :hover:not(.CalendarDay__blocked_calendar) {
        .CalendarDay__content {
          > .CalendarDay__content__dayValue {
            border: 1px solid #5158cf;
            background-color: #fff;
            color: #202353;
          }
        }
      }
    }

    .CalendarMonth_caption {
      padding-bottom: 60px;
      padding-top: 5px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #202353;
    }

    .DayPicker_weekHeader_li {
      width: 41px !important;
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
      text-align: center;
      text-transform: uppercase;
      color: #202353;

      small {
        font-size: inherit;
      }
    }

    .CalendarMonth {
      padding: 0 20px !important;
    }

    .DayPicker_weekHeaders {
      .DayPicker_weekHeader {
        padding: 0 17px !important;
        top: 55px;

        :nth-child(2) {
          left: 327px !important;
        }
      }
    }

    .CalendarMonthGrid__horizontal {
      left: 6px;
    }

    .DayPickerNavigation {
      .DayPickerNavigation_button {
        position: absolute;
        top: 6px;
        z-index: 2;
        width: auto;

        svg {
          opacity: 0.3;
          path {
            fill: #3d3d66;
          }
        }

        :hover {
          svg {
            opacity: 1;
            path {
              fill: #5158cf;
            }
          }
        }

        :first-child {
          left: 33px;
        }
        :last-child {
          right: 34px;
        }
      }
    }
  }
`;
