import React, { useReducer } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { PurchaseDropdownWrapper } from './styles';
import { ReactComponent as InfoIcon } from 'assets/icons/icon-info.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as InstantBookPreview } from 'assets/icons/instant_book_preview.svg';
import { ReactComponent as WaitlistPreview } from 'assets/icons/waitlist_preview.svg';
import { ReactComponent as IntroCallPreview } from 'assets/icons/intro_call_preview.svg';
import { PURCHASE_VALUE } from '../../constants';

const PurchaseDropdown = ({ options, value, onChange }) => {
  const [isShowDropdown, toggleDropdown] = useReducer(isShow => !isShow, false);
  const findOption = find(options, item => item.value === value) || options[0];

  const handleSelectOption = value => {
    onChange(value);
    toggleDropdown();
  };

  const renderPreviewTooltip = value => {
    let content = <InstantBookPreview />;
    switch (value) {
      case PURCHASE_VALUE.WAITLIST:
        content = <WaitlistPreview />;
        break;

      case PURCHASE_VALUE.CONTACT:
        content = <IntroCallPreview />;
        break;

      default:
        break;
    }

    return (
      <>
        <InfoIcon className="info-icon" width={12} height={12} data-tip data-for={`${value}-tooltip`} />
        <ReactTooltip
          className="app-tooltip purchase-option-tooltip"
          id={`${value}-tooltip`}
          effect="solid"
          place={'top'}
          delayShow={500}
        >
          {content}
        </ReactTooltip>
      </>
    );
  };

  return (
    <PurchaseDropdownWrapper>
      <div className="purchase-option-wrapper">
        <RootCloseWrapper onRootClose={toggleDropdown} event="click" disabled={!isShowDropdown}>
          <div
            className={classNames('purchase-option-button', isShowDropdown ? 'purchase-option-button-active' : '')}
            onClick={toggleDropdown}
          >
            <span>{findOption.label || ''}</span>
            <ArrowDown />
          </div>
          {isShowDropdown && (
            <div className="purchase-option-dropdown">
              {map(options, item => {
                return (
                  <div
                    className={classNames(
                      'purchase-option-item',
                      item.value === value ? 'purchase-option-item-active' : '',
                    )}
                    key={item.value}
                    onClick={() => handleSelectOption(item.value)}
                  >
                    <span>{item.label}</span>
                    {renderPreviewTooltip(item.value)}
                  </div>
                );
              })}
            </div>
          )}
        </RootCloseWrapper>
      </div>
    </PurchaseDropdownWrapper>
  );
};

export default PurchaseDropdown;
