import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import {
  hideProductBanner,
  countProducts,
  getListProduct,
  loadMoreProduct,
  addNewProduct,
  saveProductDraft,
  getPreSignedUrl,
  getProductDetail,
  getProductEdit,
  updateProduct,
  archiveProduct,
  deleteProduct,
  unArchiveProduct,
  duplicateProduct,
  publishProduct,
  updateQuery,
  startUploadCoverImage,
  endUploadCoverImage,
  resetDuplicate,
} from 'redux/product/actions';
import { push } from 'connected-react-router';

import { toggleConfirmModal } from 'actions/modal';
import Product from './component';

const mapState = state => {
  const {
    rootReducer: { product, permission },
    user: { is_hide_banner_product },
  } = state;

  const productDetail = product.get('workingProduct') ? product.get('workingProduct') : null;

  return {
    user: state.user,
    is_hide_banner_product,
    counter: product.get('counter').toJS(),
    total: product.get('total'),
    loading: product.toJS().loading,
    isSubmitting: product.toJS().isSubmitting,
    isUploading: product.toJS().isUploading,
    product: product.get('list').toJS(),
    productDetail,
    isEditMode: product.get('isEditMode'),
    query: product.get('query').toJS(),
    duplicating: product.get('duplicating'),
    isEditingDuplicate: product.get('isEditingDuplicate'),
    permission,
  };
};

const mapDispatch = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  hideProductBanner: bindActionCreators(hideProductBanner, dispatch),
  countProducts: bindActionCreators(countProducts, dispatch),
  addNewProduct: bindActionCreators(addNewProduct, dispatch),
  saveProductDraft: bindActionCreators(saveProductDraft, dispatch),
  getPreSignedUrl: bindActionCreators(getPreSignedUrl, dispatch),
  getListProduct: bindActionCreators(getListProduct, dispatch),
  loadMoreProduct: bindActionCreators(loadMoreProduct, dispatch),
  getProductDetail: bindActionCreators(getProductDetail, dispatch),
  getProductEdit: bindActionCreators(getProductEdit, dispatch),
  updateProduct: bindActionCreators(updateProduct, dispatch),
  archiveProduct: bindActionCreators(archiveProduct, dispatch),
  deleteProduct: bindActionCreators(deleteProduct, dispatch),
  unArchiveProduct: bindActionCreators(unArchiveProduct, dispatch),
  duplicateProduct: bindActionCreators(duplicateProduct, dispatch),
  publishProduct: bindActionCreators(publishProduct, dispatch),
  updateQuery: bindActionCreators(updateQuery, dispatch),
  startUploadCoverImage: bindActionCreators(startUploadCoverImage, dispatch),
  endUploadCoverImage: bindActionCreators(endUploadCoverImage, dispatch),
  resetDuplicate: bindActionCreators(resetDuplicate, dispatch),
  dispatch,
});

export default connect(mapState, mapDispatch)(Product);
