import React from 'react';
import Avatar from 'react-avatar';
import _ from 'lodash';
import { diff } from 'deep-diff';

import * as S from './style';

import ArchivedModal from '../RoomAction/ArchivedModal';

import { ReactComponent as EmptyRoomsIcon } from 'assets/icons/empty_rooms.svg';
import ItemRoom from './ItemRoom';
import { SharedTooltip } from 'shared/SharedTooltip';
import { CDN_URL } from 'constants/commonData';

class InboxRooms extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showInput: false, textSearch: '' };
    this.listRef = React.createRef();
    this.searchRef = React.createRef();

    this.searchRoomsDebounce = _.debounce(this.onSearchRooms, 1000);
    this.onOpenChat = false;
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      rooms,
      newConversation,
      currentRoomId,
      user,
      viewTeammate,
      loadedRooms,
      isFirstLoad,
      push,
      changeProfileId,
    } = nextProps;
    if (!currentRoomId.includes('_')) return;
    const userId = _.get(user, '_id');
    const partnerIds = rooms.map(item => _.get(item, 'parnerData.uid'));
    const currentChatUser = viewTeammate ? viewTeammate : userId;
    const targetChatUser = currentRoomId.replace(currentChatUser, '').replace('_', '');
    if (!newConversation && loadedRooms && !currentRoomId.includes(userId)) {
      if (!partnerIds.includes(targetChatUser)) {
        if (isFirstLoad) {
          changeProfileId(partnerIds[0]);
        } else if (partnerIds.length) {
          push(partnerIds[0]);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.newConversation && this.props.newConversation && this.listRef.current) {
      this.listRef.current.scrollTop = 0;
    }
    if (prevProps.viewTeammate !== this.props.viewTeammate) {
      this.setState({ showInput: false, textSearch: '' });
      this.props.resetQueryParams();
    }

    if (diff(this.props, prevProps)) {
      if (
        this.listRef.current &&
        this.props.loadedRooms &&
        !_.isEmpty(this.props.currentRoomId) &&
        diff(this.props.currentRoomId, prevProps.currentRoomId)
      ) {
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(
          () => {
            const messageElement = document.getElementById(this.props.currentRoomId);
            if (messageElement) {
              messageElement.scrollIntoView({ block: 'nearest' });
            }
          },
          this.props.viewTeammate ? 100 : 2000,
        );
      }
    }
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  onOpenSearchInput = () => {
    if (!this.state.showInput) {
      this.setState({ showInput: true });
    }
    if (this.searchRef.current) {
      this.searchRef.current.focus();
    }
  };

  onSearchChange = event => {
    this.setState({ textSearch: event.target.value });
    this.searchRoomsDebounce();
  };

  onSelectRoom = room => {
    if (this.onOpenChat) return;
    this.onOpenChat = true;
    const { currentRoomId, viewTeammate, push, user, openChat } = this.props;
    const userId = _.get(user, '_id');
    const roomId = _.get(room, 'id') || _.get(room, 'room_id');
    let profileId = roomId;
    const roomType = _.get(room, 'type');
    if (roomType === 'direct' || roomId.includes('_')) {
      profileId = roomId.replace('_', '');
      if (!_.isEmpty(viewTeammate)) {
        profileId = profileId.replace(viewTeammate, '');
      } else {
        profileId = profileId.replace(userId, '');
      }
    }
    const isArchived = _.get(room, 'is_archived');
    if (currentRoomId !== roomId && !_.isEmpty(roomId) && !_.isEmpty(profileId)) {
      if (isArchived) {
        openChat(roomId).then(() => {
          setTimeout(() => {
            push(profileId);
            this.afterSelectRoom(roomId, room);
            this.onOpenChat = false;
          }, 500);
        });
        return;
      } else if (roomType === 'group') {
        push(roomId);
      } else if (roomType === 'direct' || roomId.includes('_')) {
        push(profileId);
      }
    }
    this.afterSelectRoom(roomId, room);
    this.onOpenChat = false;
  };

  afterSelectRoom = (roomId, room) => {
    const { query, resetSearchRoom, addSelectedRoomToList } = this.props;
    const { showInput, textSearch } = this.state;
    if (showInput || !_.isEmpty(textSearch)) {
      this.setState({ showInput: false, textSearch: '' });
      resetSearchRoom();
      addSelectedRoomToList(room, query.total);
    }
    if (roomId) {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const messageElement = document.getElementById(roomId);
        if (messageElement) {
          messageElement.scrollIntoView({ block: 'nearest' });
        }
      }, 500);
    }
  };

  onRemoveNewMessage = () => {
    const { rooms, currentRoomId } = this.props;

    if (rooms.length && _.isEmpty(currentRoomId)) {
      this.onSelectRoom(rooms[0]);
    } else if (_.isEmpty(rooms)) {
      this.props.push('/home/inbox/new');
      this.props.createNewConversation();
    }
  };

  handleArchived = () => {
    this.props.toggleModal(false);
    this.onRemoveNewMessage();
  };

  onScroll = event => {
    event.persist();
    const scrollHeight = _.get(event, 'target.scrollHeight');
    const scrollTop = _.get(event, 'target.scrollTop');
    const clientHeight = _.get(event, 'target.clientHeight');
    const currentHeight = Math.floor(scrollHeight - scrollTop);
    const isAtBottom = currentHeight <= clientHeight;
    const { query, loadedRooms, viewTeammate, searchAllRooms } = this.props;
    const { textSearch } = this.state;
    if (viewTeammate) {
      const shouldLoadMore = loadedRooms && isAtBottom && !query.loading && !query.isEnd;

      if (shouldLoadMore) {
        let params = {
          q: textSearch,
          page: query.page + 1,
          trainer_id: viewTeammate,
        };
        searchAllRooms && searchAllRooms(params);
      }
    } else {
      const shouldLoadMore = loadedRooms && isAtBottom && !query.loading && !query.isEnd;

      if (shouldLoadMore) {
        this.props.getLoadMoreRooms();
      }
    }
  };

  onSearchRooms = () => {
    const { viewTeammate, searchAllRooms } = this.props;
    if (!this.searchRef.current) return;
    const textSearch = this.searchRef.current.value.trim().toLowerCase();
    let params = { q: textSearch, page: 1 };
    if (viewTeammate) {
      params.trainer_id = viewTeammate;
      if (!textSearch) {
        this.props.resetSearchRoom();
      }
      searchAllRooms && searchAllRooms(params);
    } else {
      if (textSearch) {
        this.props.searchRooms({ q: textSearch, page: 1 });
      } else {
        this.props.resetSearchRoom();
      }
    }
    this.listRef.current.scrollTop = 0;
  };

  filterRoomByTextSearch = room => {
    const firstName = _.get(room, 'parnerData.first_name') || _.get(room, 'chat_user.first_name');
    const lastName = _.get(room, 'parnerData.last_name') || _.get(room, 'chat_user.last_name');
    const type = _.get(room, 'type');
    let name = firstName && lastName ? `${firstName} ${lastName}` : _.get(room, 'room_name');
    if (type === 'group') {
      name = _.get(room, 'room_name') || _.get(room, 'room_sub_name');
    }
    return (name || '').trim().toLowerCase().includes(this.state.textSearch.trim().toLowerCase());
  };

  render() {
    const {
      user,
      rooms,
      currentRoomId,
      totalUnreadConversation,
      newConversation,
      openArchivedMessage,
      viewTeammate,
      loadedRooms,
      getS3presignedURLFromLocalDatabase,
      usersInfo,
      query,
      selectedTeammate,
      isGroupChatPermission,
      cloudfrontList,
    } = this.props;
    const { showInput, textSearch } = this.state;
    let allRooms = viewTeammate ? _.uniqBy([...rooms, ...query.rooms], 'id') : rooms;

    if (showInput && !_.isEmpty(textSearch)) {
      allRooms = query.rooms;
    }
    const hasRoomsSearch = !_.isEmpty(textSearch) && _.findIndex(allRooms, this.filterRoomByTextSearch);

    return (
      <S.Wrapper>
        <S.Header>
          <S.Total>
            {viewTeammate ? 'All message' : 'My Messages'} (
            {viewTeammate ? query.total || allRooms.length : totalUnreadConversation})
          </S.Total>
          <S.SearchContainer>
            {showInput && <input type="text" onChange={this.onSearchChange} autoFocus={true} ref={this.searchRef} />}
            <S.SearchIcon onClick={this.onOpenSearchInput} data-for="search-client-inbox-options-tooltip" data-tip />
            <SharedTooltip id="search-client-inbox-options-tooltip" content="Search messages" />
          </S.SearchContainer>
        </S.Header>
        <S.List ref={this.listRef} onScroll={this.onScroll}>
          {newConversation ? (
            <S.Item key="new-conversation" className="inbox-item inbox-item--active">
              <S.ItemLeftContent>
                <Avatar name="New Message" size="38" src={`${CDN_URL}/images/new_avatar.svg`} />
                <S.ClientInformation>
                  <S.ClientName>New Message</S.ClientName>
                </S.ClientInformation>
              </S.ItemLeftContent>
              <S.RemoveIcon onClick={this.onRemoveNewMessage} />
            </S.Item>
          ) : null}
          {hasRoomsSearch === -1 && (
            <S.NoResultsContainer>
              <EmptyRoomsIcon />
              <S.TextNoResults>No results found.</S.TextNoResults>
            </S.NoResultsContainer>
          )}
          {loadedRooms &&
            _.map(allRooms, room => (
              <ItemRoom
                cloudfrontList={cloudfrontList}
                currentRoomId={currentRoomId}
                viewTeammate={viewTeammate}
                room={room}
                user={user}
                key={`item-room-${room.id}`}
                onSelectRoom={this.onSelectRoom}
                textSearch={textSearch}
                getS3presignedURLFromLocalDatabase={getS3presignedURLFromLocalDatabase}
                usersInfo={usersInfo}
                selectedTeammate={selectedTeammate}
                isGroupChatPermission={isGroupChatPermission}
              />
            ))}
        </S.List>
        <ArchivedModal isOpen={openArchivedMessage} onClose={this.handleArchived} />
      </S.Wrapper>
    );
  }
}

export default InboxRooms;
