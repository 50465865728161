import React from 'react';
import { HeadingConfirmPaymentWrapper } from './style';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { CDN_URL } from 'constants/commonData';

export default function (props) {
  const { disableBack = false, onBack } = props;

  const handleBack = () => {
    if (!disableBack && typeof onBack === 'function') {
      onBack();
    }
  };

  return (
    <HeadingConfirmPaymentWrapper>
      <Helmet>
        <title>Confirm Payment - Everfit</title>
      </Helmet>
      <div className="headingConfirm__content">
        <div className="headingConfirm__content--title">Upgrade your business</div>
        <div className="headingConfirm__content--description">Welcome to the exciting world of connected training!</div>
      </div>
      <div className={classNames('headingConfirm__backBtn', { disable: disableBack })} onClick={handleBack}>
        <img src={`${CDN_URL}/images/arrow_back_purple.svg`} alt="" className="arrow-back-icon" />
        Back
      </div>
    </HeadingConfirmPaymentWrapper>
  );
}
