/**
 * @flow
 */

import React from 'react';
import './styles.scss';
import { Image } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

export default class AddButton extends React.Component<Props, State> {
  render() {
    const { title, img } = this.props;
    let className = 'add-button-container';
    if (this.props.className) {
      className = `add-button-container ${this.props.className}`;
    }
    return (
      <div style={this.props.style} onClick={this.props.onClick} className={className}>
        <div className="shared-add-button-wrapper">
          <Image className="shared-add-button-image" src={img ? img : `${CDN_URL}/images/plus_grey.svg`} width="15" />
          <span className="shared-add-button-title">{title ? title : ''}</span>
        </div>
      </div>
    );
  }
}
