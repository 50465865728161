import React from 'react';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { List } from 'immutable';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import * as S from './style';
import ConfirmModal from 'shared/ConfirmModal';
import AutoMessageCompactView from 'shared/AutoMessageCompactView';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import * as CellLayout from '../../Calendar/CellLayout';
import { CalendarAssignWorkoutIcon, CloseIcon } from 'shared/Icons';
import {
  copyIntervalMessage,
  pasteIntervalMessage,
  deleteIntervalMessage,
  arrangeIntervalMessage,
  viewIntervalMessageDetail,
} from 'redux/autoflowInterval/auto-message/actions';
import Announcement from '../Announcement';
import InAppMessage from '../InAppMessageContainer';
import CreateAutoflowMessage from 'shared/CreateAutoflowMessage';
import { IN_APP_AUTO_MESSAGE, ANNOUNCEMENT_AUTO_MESSAGE, CDN_URL } from 'constants/commonData';

const BOTTOM_SPACING = 22;
const HEADER_SPACING = 34;

class AutoMessageCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastRenderedMessage: null,
      showAll: false,
    };
    this.cellContainerRef = React.createRef();
    this.listRef = React.createRef();
    this.idPrefix = `autflowIntervalMessage;${props.weekIndex};${props.dayIndex}`;
    this.resizeObserver = new ResizeObserver(() => {
      setTimeout(this.calculateLastRenderedMessage, 0);
    });
  }

  componentDidMount() {
    if (this.cellContainerRef.current) {
      this.resizeObserver.observe(this.cellContainerRef.current);
    }
  }

  componentDidUpdate(prevProps) {
    if (_.differenceWith(this.props.messages.toJS(), prevProps.messages.toJS(), _.isEqual).length) {
      this.calculateLastRenderedMessage();
    }
    if (this.cellContainerRef.current) {
      this.resizeObserver.observe(this.cellContainerRef.current);
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  onEdit = message => {
    const { weekIndex, dayIndex, autoflowId } = this.props;

    if (message.type === IN_APP_AUTO_MESSAGE) {
      return this.props.push(`/home/autoflow/${autoflowId}/auto-message/${message._id}?type=${message.type}`);
    }

    this.props.viewIntervalMessageDetail({ autoflowId, messageId: message._id }, { weekIndex, dayIndex });
  };

  onCopy = message => {
    this.props.copyIntervalMessage(message);
  };

  onCancelAddNewMesasage = () => {
    this.props.toggleModal(false);
  };

  onSelectMessageTypeToCreate = type => {
    const { weekIndex, dayIndex } = this.props;

    if (type === ANNOUNCEMENT_AUTO_MESSAGE) {
      this.props.toggleModal(true, <Announcement weekIndex={weekIndex} dayIndex={dayIndex} />);
    } else {
      this.props.toggleModal(true, <InAppMessage weekIndex={weekIndex} dayIndex={dayIndex} />);
    }
  };

  onAddNew = () => {
    this.props.toggleModal(
      true,
      <CreateAutoflowMessage onCancel={this.onCancelAddNewMesasage} onSelect={this.onSelectMessageTypeToCreate} />,
    );
  };

  onPaste = event => {
    const multiPaste = event.shiftKey;
    const { copying, weekIndex, dayIndex, autoflowId } = this.props;

    if (copying) {
      const params = {
        autoflowId,
        messageId: copying.get('_id'),
        dayIndex,
        weekIndex,
      };

      this.props.pasteIntervalMessage(params, multiPaste);
    }
  };

  onDelete = message => {
    const { weekIndex, dayIndex, autoflowId } = this.props;
    this.props.toggleConfirmModal(
      true,
      <ConfirmModal
        title="Remove Message"
        content="Are you sure that you want to remove this message?"
        onConfirm={() => this.props.deleteIntervalMessage({ weekIndex, dayIndex, autoflowId, messageId: message._id })}
        headerIcon={`${CDN_URL}/images/new_delete_red.svg`}
        newStyle={true}
      />,
    );
  };

  onArrange = (message, newIndex, oldIndex) => {
    const { weekIndex, dayIndex, autoflowId } = this.props;
    this.props.arrangeIntervalMessage({ weekIndex, dayIndex, autoflowId, newIndex, messageId: message._id, oldIndex });
  };

  onViewMore = () => {
    this.setState({ showAll: true });
  };

  calculateLastRenderedMessage = () => {
    const { messages } = this.props;
    const { lastRenderedMessage, showAll } = this.state;

    if (showAll) {
      return;
    }

    const containerRect = this.cellContainerRef.current.getBoundingClientRect();
    const { x, y, height } = containerRect;
    const messagesContainerHeight = height - BOTTOM_SPACING - HEADER_SPACING;
    const fakeEl = this.cellContainerRef.current.querySelector('.listPlaceholder');
    let index = 0;

    if (fakeEl && fakeEl.clientHeight > messagesContainerHeight) {
      let elements = document.elementsFromPoint(x + 15, y + messagesContainerHeight + 2);
      let messageItem = _.find(elements, el => (el.id ? el.id.startsWith(this.idPrefix) : false));

      if (!messageItem) {
        // each message in a date margin bottom 5px
        //Sometimes, the cordinates point to the margin of element
        elements = document.elementsFromPoint(x + 5, y + messagesContainerHeight);
        messageItem = _.find(elements, e => (e.id ? e.id.startsWith(this.idPrefix) : false));
      }

      if (messageItem) {
        const [, , , , , mIndex] = messageItem.id.split(';');
        index = Number(mIndex) - 1;
      }
    } else {
      index = messages.size - 1;
    }

    if (index !== lastRenderedMessage) {
      this.setState({ lastRenderedMessage: index });
    }
  };

  hideViewAllMode = () => {
    this.setState({ showAll: false }, () => {
      this.listRef.current.scrollTop = 0;
    });
  };

  renderMinimize = () => {
    const { lastRenderedMessage, showAll } = this.state;
    const { messages, weekIndex, dayIndex, compactView, copying } = this.props;
    if (lastRenderedMessage === null) {
      return null;
    }

    const views = [];
    const lastRender = showAll ? messages.size - 1 : lastRenderedMessage;

    for (let i = 0; i <= lastRender; i++) {
      const message = messages.get(i);

      if (message) {
        const draggableId = `${weekIndex};${dayIndex};${message.get('_id')};${message.get('type')}`;

        views.push(
          <AutoMessageCompactView
            key={draggableId}
            data={message}
            compactView={compactView}
            totalMessage={messages.size}
            mIndex={i}
            draggableId={draggableId}
            onCopy={this.onCopy}
            onPaste={this.onPaste}
            onDelete={this.onDelete}
            onArrange={this.onArrange}
            onEdit={this.onEdit}
            onViewDetail={this.onEdit}
          />,
        );
      }
    }

    const remaining = messages.slice(lastRender + 1).size;

    return (
      <div className={classnames('minimize', { 'can-paste': !copying })}>
        {views}
        {!showAll && (
          <CellLayout.CellFooter className="autoMessageCell__footer">
            {!!remaining && (
              <CellLayout.ViewMoreItem onClick={this.onViewMore}>+ {remaining} more</CellLayout.ViewMoreItem>
            )}
            {copying ? <CellLayout.PasteButton onClick={this.onPaste}>Paste</CellLayout.PasteButton> : null}
          </CellLayout.CellFooter>
        )}
      </div>
    );
  };

  render() {
    const { copying, compactView, weekIndex, dayIndex, cellId, messages } = this.props;
    const { showAll } = this.state;

    if (!cellId) {
      return <CellLayout.CellWrapper />;
    }

    return (
      <CellLayout.CellWrapper className="autoflow-message__calendar-cell" copying={!!copying} showAll={showAll}>
        <CellLayout.CellContainer ref={this.cellContainerRef}>
          <CellLayout.CellHeader>
            <div className="day">Day {Number(weekIndex) * 7 + Number(dayIndex) + 1}</div>
            <CalendarAssignWorkoutIcon onClick={this.onAddNew} className="addItemButton" />
            {showAll ? <CloseIcon onClick={this.hideViewAllMode} /> : null}
          </CellLayout.CellHeader>
          <Droppable droppableId={`${cellId}`} key={`${cellId}`}>
            {(provided, snapshot) => (
              <CellLayout.CellContent
                ref={provided.innerRef}
                style={{ overflowY: showAll || snapshot.isDragging ? 'auto' : 'hidden' }}
                {...provided.droppableProps}
              >
                <S.ListMessageContainer
                  ref={this.listRef}
                  showAll={showAll}
                  empty={!messages.size}
                  className={classnames('autoMessageCell__content', { compactView: compactView })}
                >
                  <div className="listPlaceholder">
                    {messages.map((message, index) => {
                      return (
                        <AutoMessageCompactView
                          data={message}
                          compactView={compactView}
                          totalMessage={messages.size}
                          id={`${this.idPrefix};${message.get('type')};${message.get('_id')};${index}`}
                          mIndex={index}
                          isPlaceholder
                          key={message.get('_id')}
                        />
                      );
                    })}
                  </div>
                  {this.renderMinimize()}
                </S.ListMessageContainer>
                {provided.placeholder}
              </CellLayout.CellContent>
            )}
          </Droppable>
        </CellLayout.CellContainer>
      </CellLayout.CellWrapper>
    );
  }
}

const mapState = (state, ownProps) => {
  const {
    rootReducer: {
      autoflow: {
        common: { workingAutoflow },
      },
      autoflowInterval: { autoMessage, common },
    },
  } = state;

  const { weekIndex, dayIndex } = ownProps;
  const messages = autoMessage.getIn(['messages', `${weekIndex}_${dayIndex}`]) || List([]);

  return {
    autoflowId: workingAutoflow._id,
    messages,
    copying: autoMessage.get('copying'),
    compactView: common.get('viewMode') > 2,
  };
};

const actionCreators = {
  toggleModal,
  toggleConfirmModal,
  copyIntervalMessage,
  pasteIntervalMessage,
  deleteIntervalMessage,
  arrangeIntervalMessage,
  viewIntervalMessageDetail,
  push,
};

export default connect(mapState, actionCreators)(AutoMessageCell);
