import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import PopUpForm from './component';
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';
import { getNotifications } from 'redux/notification/actions';
import { addPost, editPost, saveScheduleTemp, resetCreatePost, hideScheduleBanner } from 'redux/forum/actions';

const mapStateToProps = state => {
  const { rootReducer, user, isConfirmModalOpen } = state;
  const members = rootReducer.forum.members;
  const formDataSchedule = rootReducer.forum.formDataSchedule;
  const isHideBanner = get(rootReducer, 'forum.group.is_hide_schedule_post_banner', true);
  const loading = rootReducer.forum.loading;

  return {
    user,
    members,
    formDataSchedule,
    isHideBanner,
    loading,
    isConfirmModalOpen,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
    push: bindActionCreators(push, dispatch),
    addPost: formData => dispatch(addPost(formData, id)),
    editPost: (formData, postId) => dispatch(editPost(formData, postId)),
    toggleConfirmModal: (isOpen, modal) => dispatch(toggleConfirmModal(isOpen, modal)),
    getNotifications: bindActionCreators(getNotifications, dispatch),
    saveScheduleTemp: bindActionCreators(saveScheduleTemp, dispatch),
    resetCreatePost: bindActionCreators(resetCreatePost, dispatch),
    hideScheduleBanner: bindActionCreators(hideScheduleBanner, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpForm);
