import React from 'react';

import * as S from '../style';
import { useMyContext } from '../context/ProgramTemplatesContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close_program_filter.svg';

const TagsModalContent = () => {
  const { toggleTagsModal, detailModalItem } = useMyContext();
  const { tags = [] } = detailModalItem;

  return (
    <S.FullscreenModalContent className="tags">
      <S.FilterModalHeader>
        <S.FilterHeaderTitle>Tags ({tags.length})</S.FilterHeaderTitle>
        <S.CloseFilterButton onClick={toggleTagsModal}>
          <CloseIcon />
        </S.CloseFilterButton>
      </S.FilterModalHeader>
      <S.TagPopupContent>
        <S.TagsPopupWrapper>
          {tags.map((tag, index) => (
            <S.DetailProgramItem key={index}>{tag}</S.DetailProgramItem>
          ))}
        </S.TagsPopupWrapper>
      </S.TagPopupContent>
    </S.FullscreenModalContent>
  );
};

export default TagsModalContent;
