import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px
  color: #7B7E91;
`;

export const VideoLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  ${baseText}
  text-transform: uppercase;
  margin-bottom: 3px;
`;

export const VideoLink = styled.input.attrs({
  type: 'text',
})`
  ${baseText}
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #202353;
  padding: 7px 9px;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  :hover {
    border-color: #5158cf;
  }
  :focus {
    border-color: #5158cf;
  }
  ::placeholder {
    color: #a3a3b5;
  }
  ${props =>
    props.hasError &&
    css`
      border-color: #ea314a !important;
    `}
`;

export const VideoThumbnailWrapper = styled.div`
  margin-top: 0;
  position: relative;
  width: 100%;
  height: 348px;
  display: none;
  ${props =>
    props.isEditMode &&
    css`
      margin-top: 13px;
      width: 242px;
      height: 136px;
    `}

  ${props =>
    props.hasThumbnail &&
    css`
      display: block;
    `}
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
    background: url(${CDN_URL}/images/play_icon_white.svg) no-repeat center;
    background-size: 47px;
  }
  .icon-remove {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    path {
      &:last-child {
        fill: #212353;
      }
    }
  }
  ${props =>
    props.isThumbnailDefault &&
    css`
      &:before {
        content: none;
      }
      img {
        cursor: default;
      }
    `}
`;

export const VideoThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  ${baseText}
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea314a;
  margin-top: 3px;
`;
