import get from 'lodash/get';
import { connect } from 'react-redux';

import { getClientActivity } from 'actions/client';
import UpdateOverview from './component';
import { resetLoadPostsQuery, checkPostsIndicatorAndGotoPostDetail } from 'redux/forum/actions';
import { getClientsList } from 'redux/form-responses/actions';
import { bindActionCreators } from 'redux';
import { navigateToPersonalTask } from 'redux/notification/actions';
import { push } from 'connected-react-router';

const mapStateToProps = state => {
  const { user, rootReducer, cloudfrontList } = state;

  return {
    cloudfrontList,
    timezone: user.timezone,
    user,
    idUser: user._id,
    subTrainers: get(rootReducer, 'client.workingClientDetail.sub_trainers'),
    isHabitPermission: get(rootReducer, 'permission.habit', false),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    getClientActivity: (clientId, params) => dispatch(getClientActivity(clientId, params)),
    getClientsList: bindActionCreators(getClientsList, dispatch),
    resetLoadPostsQuery: bindActionCreators(resetLoadPostsQuery, dispatch),
    checkPostsIndicatorAndGotoPostDetail: bindActionCreators(checkPostsIndicatorAndGotoPostDetail, dispatch),
    navigateToPersonalTask: bindActionCreators(navigateToPersonalTask, dispatch),
    push: bindActionCreators(push, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOverview);
