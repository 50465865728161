// Lib
import React, { useMemo, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import ReactTooltip from 'react-tooltip';

// Share
import ContentTab from './components/ContentTab';
import { NewSearchInput } from 'shared/SearchInput';
import FilterPopup from 'components/Recipes/Parts/FilterPopup';

// Constants
import { CATEGORIES, MAX_CALORIES, MIN_CALORIES } from 'components/Recipes/constants';
import { STATUS_SCROLL } from 'components/MealPlanDetail/constants';

// Assets
import Background from 'assets/icons/search_icon_grey.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/MealPlans/meal_plan_filter_icon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';

import * as S from './style';

const RightPanelDayDetail = props => {
  const { selected, listMealGroup, changeQueryParams, getInitTotalRecipe, sideBarVisible, initTotal } = props;
  const { max_calories, hasGetToTal } = initTotal;

  const [appliedFilter, setAppliedFilter] = useState([]);
  const [filterNumber, setFilterNumber] = useState(0);
  const [activeCategory, setActiveCategory] = useState(CATEGORIES[0].value);
  const [statusScroll, setStatusScroll] = useState(STATUS_SCROLL.LEFT);
  const listCategoryRef = useRef(null);

  const selectedMeal = useMemo(() => {
    return listMealGroup.find(item => get(item, '_id') === selected);
  }, [selected, listMealGroup]);

  useEffect(() => {
    const elementListMeal = listCategoryRef && listCategoryRef.current;

    const handleScroll = () => {
      if (elementListMeal) {
        const { scrollLeft, clientWidth, scrollWidth } = elementListMeal;

        if (scrollLeft === 0) {
          return setStatusScroll(STATUS_SCROLL.LEFT);
        }

        if (scrollLeft + clientWidth >= scrollWidth) {
          return setStatusScroll(STATUS_SCROLL.RIGHT);
        }

        return setStatusScroll(STATUS_SCROLL.CENTER);
      }
    };

    if (elementListMeal) {
      elementListMeal.addEventListener('scroll', handleScroll);

      return () => {
        elementListMeal.removeEventListener('scroll', handleScroll);
      };
    }
  }, [listCategoryRef && listCategoryRef.current]);

  useEffect(() => {
    if (hasGetToTal) {
      const initFilterState = {
        dietaries: [],
        caloriesRange: {
          from: MIN_CALORIES,
          to: max_calories > MAX_CALORIES ? max_calories : MAX_CALORIES,
        },
        recipeFrom: [],
        recipeStatus: [],
      };

      setAppliedFilter(initFilterState);
    }
  }, [max_calories, hasGetToTal]);

  useEffect(() => {
    getInitTotalRecipe();
  }, []);

  const handleSearch = (event, { value }) => {
    changeQueryParams && changeQueryParams({ text_search: value, page: 1 });
  };

  const handleClearSearch = () => {
    handleSearch(null, { value: '' });
  };

  const handleSelectedFilterApply = selectedFilter => {
    const dietaryFilter = get(selectedFilter, 'dietaries', []).map(item => item.value);
    const recipeFrom = JSON.stringify(get(selectedFilter, 'recipeFrom', []));
    // const recipeStatus = JSON.stringify(get(selectedFilter, 'recipeStatus', []));
    const dietaryFilterJson = JSON.stringify(dietaryFilter);

    const newParams = {
      recipe_from: recipeFrom,
      // status: recipeStatus,
      dietaries: dietaryFilterJson,
      from_calories: get(selectedFilter, 'caloriesRange.from', 0),
      to_calories: get(selectedFilter, 'caloriesRange.to', 0),
      page: 1,
    };
    setAppliedFilter(selectedFilter);

    changeQueryParams && changeQueryParams(newParams);
  };
  const handleChangeNumberFilter = number => {
    setFilterNumber(number);
  };

  const handleFilterCategory = category => {
    setActiveCategory(category);
    changeQueryParams && changeQueryParams({ category: category, page: 1 });
  };

  const renderTrigger = open => {
    return (
      <S.FilterButton data-for="filter-action" data-tip active={open}>
        <FilterIcon className="filter-icon" />
        {filterNumber > 0 ? <S.FilterNumber className="filter-number">{filterNumber}</S.FilterNumber> : null}
        <ReactTooltip className="filter-action-tooltip" id="filter-action" effect="solid" place="top">
          Filter
        </ReactTooltip>
      </S.FilterButton>
    );
  };

  const handleArrowButton = actionNext => {
    const elementListMeal = listCategoryRef && listCategoryRef.current;

    if (elementListMeal) {
      const scrollIncrement = actionNext ? elementListMeal.clientWidth : -elementListMeal.clientWidth;

      elementListMeal.scrollTo({
        left: elementListMeal.scrollLeft + scrollIncrement,
        behavior: 'smooth',
      });
    }
  };

  const FilterCategoryOverlay = ({ direction, onClick }) => (
    <>
      <S.Overlay className={`overlay-${direction}`} />
      <S.ButtonArrow className={`button-${direction}`} onClick={onClick}>
        <ArrowIcon />
      </S.ButtonArrow>
    </>
  );

  const RecipeCategory = ({ item = {} }) => {
    const { value = '', label = '', icon = '' } = item;
    return (
      <S.RecipeCategory key={value} active={activeCategory === value} onClick={() => handleFilterCategory(value)}>
        <S.IconWrapper>{icon}</S.IconWrapper>
        <span className="recipe-category-label">{label}</span>
      </S.RecipeCategory>
    );
  };

  const renderFilterCategory = () => (
    <S.FilterCategoryWrapper>
      <S.FilterCategories ref={listCategoryRef}>
        {statusScroll !== STATUS_SCROLL.LEFT && (
          <FilterCategoryOverlay direction="prev" onClick={() => handleArrowButton(false)} />
        )}
        {CATEGORIES.map(item => (
          <RecipeCategory item={item} />
        ))}
        {statusScroll !== STATUS_SCROLL.RIGHT && (
          <FilterCategoryOverlay direction="next" onClick={() => handleArrowButton(true)} />
        )}
      </S.FilterCategories>
    </S.FilterCategoryWrapper>
  );

  return (
    <S.Wrapper sideBarVisible={sideBarVisible}>
      <S.FilterContainer>
        <S.FilterSearch background={Background}>
          <NewSearchInput
            placeholder="Search recipe name"
            className="search-input-recipes"
            onChange={debounce(handleSearch, 300)}
            onClearSearch={handleClearSearch}
          />
          <FilterPopup
            menuHeight={180}
            trigger={renderTrigger}
            onSelectedFilterApply={handleSelectedFilterApply}
            onChangeNumberFilter={handleChangeNumberFilter}
            appliedFilter={appliedFilter}
            changeQueryParams={changeQueryParams}
            maxCalories={max_calories}
          />
        </S.FilterSearch>
        {renderFilterCategory()}
      </S.FilterContainer>
      <ContentTab {...props} meal={selectedMeal} filterNumber={filterNumber} />
    </S.Wrapper>
  );
};

export default RightPanelDayDetail;
