import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeTeamMember } from 'actions/auth/login';
import RemoveTeamMember from './component';

const mapState = (state) => ({
  user: state.user,
});

const mapDispatch = (dispatch) => ({
  removeTeamMember: bindActionCreators(removeTeamMember, dispatch),
});

export default connect(mapState, mapDispatch)(RemoveTeamMember);
