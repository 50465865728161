'use strict';

// import from lodash
import _ from 'lodash';

import { TEAM_SHARE_PRIVATE, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { updateExerciseLibraryInWorkout } from 'helpers/workout';
import { Types } from './program_library.actionCreators';
import { CALENDAR_TYPES } from 'constants/commonData';
import { diff } from 'deep-diff';

export const sortProgramLibrary = (array, sorter, ascending) => {
  let mutable = array;

  if (sorter == 'title') {
    let new_array = mutable.sort((ex1, ex2) => {
      if (ascending) {
        return ex1.title.toLowerCase() > ex2.title.toLowerCase() ? 1 : -1;
      } else {
        return ex1.title.toLowerCase() < ex2.title.toLowerCase() ? 1 : -1;
      }
    });
    return new_array;
  }

  if (sorter == 'author') {
    let new_array = mutable.sort((ex1, ex2) => {
      let name1 =
        ex1.share === TEAM_SHARE_NOOWNER
          ? 'No owner (shared)'
          : ex1.author
          ? `${ex1.author.first_name} ${ex1.author.last_name}`
          : '';
      let name2 =
        ex2.share === TEAM_SHARE_NOOWNER
          ? 'No owner (shared)'
          : ex2.author
          ? `${ex2.author.first_name} ${ex2.author.last_name}`
          : '';
      if (ascending) {
        return name1.toLowerCase() > name2.toLowerCase() ? 1 : -1;
      } else {
        return name1.toLowerCase() < name2.toLowerCase() ? 1 : -1;
      }
    });
    return new_array;
  }

  if (sorter == 'last_updated') {
    let new_array = mutable.sort((ex1, ex2) => {
      if (ascending) {
        return ex1.last_interacted > ex2.last_interacted ? 1 : -1;
      } else {
        return ex1.last_interacted < ex2.last_interacted ? 1 : -1;
      }
    });
    return new_array;
  }

  if (sorter == 'weeks') {
    let new_array = mutable.sort((ex1, ex2) => {
      if (ascending) {
        return ex1.workout_sets.length > ex2.workout_sets.length ? 1 : -1;
      } else {
        return ex1.workout_sets.length < ex2.workout_sets.length ? 1 : -1;
      }
    });
    return new_array;
  }
};

const refreshAfterUpdateWeekRangeProgram = (oldState, payload) => {
  const { selected_current_week, calendar_type } = oldState;
  const currentWorkoutSets = _.cloneDeep(_.get(oldState, 'selected.workout_sets', []));

  let newCurrentWeek = selected_current_week;

  let newCalendarType = calendar_type;
  const { newPayload, weekIndex } = payload;
  const { totalWeek, newWeek, isDeleted = false } = newPayload;

  newWeek && isDeleted ? currentWorkoutSets.splice(weekIndex, 1) : currentWorkoutSets.splice(weekIndex, 0, newWeek);
  if (totalWeek === 1) {
    newCalendarType = 1;
  } else {
    if (calendar_type > 1 && totalWeek / calendar_type < 1) {
      const indexNumber = _.findIndex(CALENDAR_TYPES, item => item.value === calendar_type);
      newCalendarType = indexNumber > 0 ? CALENDAR_TYPES[indexNumber - 1].value : 1;
    }
  }

  if (newCurrentWeek >= totalWeek - newCalendarType) {
    newCurrentWeek = totalWeek - newCalendarType;
  }

  return Object.assign({}, oldState, {
    selected: {
      ...oldState.selected,
      workout_sets: [...currentWorkoutSets],
    },
    selected_total_week: totalWeek,
    calendar_type: newCalendarType,
    selected_current_week: newCurrentWeek,
  });
};

const refreshCurrentProgram = (oldState, payload) => {
  const { selected_current_week, calendar_type } = oldState;
  let newCurrentWeek = selected_current_week;
  let newCalendarType = calendar_type;
  const { program } = payload;
  const totalWeek = program.workout_sets.length;

  if (totalWeek === 1) {
    newCalendarType = 1;
  } else {
    if (calendar_type > 1 && totalWeek / calendar_type < 1) {
      const indexNumber = _.findIndex(CALENDAR_TYPES, item => item.value === calendar_type);
      newCalendarType = indexNumber > 0 ? CALENDAR_TYPES[indexNumber - 1].value : 1;
    }
  }

  if (newCurrentWeek >= totalWeek - newCalendarType) {
    newCurrentWeek = totalWeek - newCalendarType;
  }

  return Object.assign({}, oldState, {
    selected: program,
    selected_total_week: program.workout_sets.length,
    calendar_type: newCalendarType,
    selected_current_week: newCurrentWeek,
  });
};

const handleMoveWorkout = (params, oldState) => {
  const {
    selected: { workout_sets },
  } = oldState;
  const { oldWeekIndex, oldDayIndex, newWeekIndex, newDayIndex, fromIndex, newIndex } = params;

  const oldDayWorkoutIndex = _.get(workout_sets, [oldWeekIndex, 'days_workout'], []).findIndex(
    o => Number(o.day_index) === Number(oldDayIndex),
  );
  const newDayWorkoutIndex = _.get(workout_sets, [newWeekIndex, 'days_workout'], []).findIndex(
    o => Number(o.day_index) === Number(newDayIndex),
  );

  const movingWorkout = _.get(workout_sets, [
    oldWeekIndex,
    'days_workout',
    oldDayWorkoutIndex,
    'day_workout',
    'workouts',
    fromIndex,
  ]);

  //remove from oldDay
  workout_sets[oldWeekIndex].days_workout[oldDayWorkoutIndex].day_workout.workouts.splice(fromIndex, 1);

  //insert to newday
  if (newDayWorkoutIndex !== -1) {
    workout_sets[newWeekIndex].days_workout[newDayWorkoutIndex].day_workout.workouts.splice(newIndex, 0, movingWorkout);
  } else {
    workout_sets[newWeekIndex].days_workout.push({
      _id: movingWorkout._id,
      day_index: Number(newDayIndex),
      day_workout: {
        _id: movingWorkout._id,
        day_index: Number(newDayIndex),
        workouts: [movingWorkout],
      },
    });
  }

  return Object.assign({}, oldState, {
    selected: {
      ...oldState.selected,
      workout_sets: workout_sets,
    },
  });
};

const handleMoveExercise = (params, oldState) => {
  const {
    selected: { workout_sets },
  } = oldState;
  const {
    oldIndex,
    newIndex,
    workoutId,
    workoutDestinationId,
    dayIndexDestination,
    dayIndex,
    weekIndex,
    oldWeekIndex,
  } = params;

  const oldDayWorkoutIndex = _.get(workout_sets, [oldWeekIndex, 'days_workout'], []).findIndex(
    o => Number(o.day_index) === Number(dayIndex),
  );
  const newDayWorkoutIndex = _.get(workout_sets, [weekIndex, 'days_workout'], []).findIndex(
    o => Number(o.day_index) === Number(dayIndexDestination),
  );

  const fromWorkoutIndex = _.get(workout_sets, [
    oldWeekIndex,
    'days_workout',
    oldDayWorkoutIndex,
    'day_workout',
    'workouts',
  ]).findIndex(w => w._id === workoutId);
  const fromWorkout = _.get(workout_sets, [
    oldWeekIndex,
    'days_workout',
    oldDayWorkoutIndex,
    'day_workout',
    'workouts',
    fromWorkoutIndex,
  ]);
  const movingExercise = fromWorkout.sections[oldIndex];

  // remove old exercise
  workout_sets[oldWeekIndex].days_workout[oldDayWorkoutIndex].day_workout.workouts[fromWorkoutIndex].sections.splice(
    oldIndex,
    1,
  );
  if (
    !workout_sets[oldWeekIndex].days_workout[oldDayWorkoutIndex].day_workout.workouts[fromWorkoutIndex].sections.length
  ) {
    workout_sets[oldWeekIndex].days_workout[oldDayWorkoutIndex].day_workout.workouts.splice(fromWorkoutIndex, 1);
  }

  // update new exercise
  if (newDayWorkoutIndex !== -1) {
    const toWorkoutIndex = _.get(workout_sets, [
      weekIndex,
      'days_workout',
      newDayWorkoutIndex,
      'day_workout',
      'workouts',
    ]).findIndex(o => o._id === workoutDestinationId);
    if (toWorkoutIndex !== -1) {
      workout_sets[weekIndex].days_workout[newDayWorkoutIndex].day_workout.workouts[toWorkoutIndex].sections.splice(
        newIndex,
        0,
        movingExercise,
      );
    }
  }

  return Object.assign({}, oldState, {
    selected: {
      ...oldState.selected,
      workout_sets: workout_sets,
    },
  });
};

const removeWorkoutByIds = (removeIds, workoutSet) => {
  const newWorkoutSet = workoutSet;
  workoutSet.forEach(it => {
    if (typeof it === 'string' || !it) return workoutSet;
    const daysWorkout = _.get(it, 'days_workout', []);
    daysWorkout.forEach(dayWorkout => {
      const workouts = _.get(dayWorkout, 'day_workout.workouts', []);
      const newDayWorkout = _.get(dayWorkout, 'day_workout', {});

      newDayWorkout.workouts = workouts.filter(workout => {
        return !_.includes(removeIds, _.get(workout, '_id'));
      });
    });
  });
  return newWorkoutSet;
};

export const DEFAULT_STATE = {
  page: 1,
  per_page: 50,
  items: [],
  all_items: [],
  filtered_items: [],
  query: '',
  sorter: 'last_updated',
  isAscending: false,
  loadingLibrary: false,
  selected: null,
  selected_current_week: 0,
  selected_total_week: 0,
  copying_workout: null,
  calendar_type: 2,
  preview_selected_item: null,
  last_job_run: null,
  assigned_clients: [],
  loadingPreviewItem: false,
  selectedWeek: null,
  weekIndex: 0,
  selectedWorkout: [],
  isCopy: false,
  isLoadingWorkouts: false,
  isAddingWeek: false,
  temporarySocketWorkouts: [],
  programAfterRefresh: {},
};

const WorkoutReducer = (state = DEFAULT_STATE, action) => {
  let currentPage, list_items, list, query, sorter, workout_set, weekIndex, _dayIndex, _weekIndex, newWeek, totalWeek;

  let {
    all_items,
    per_page,
    filtered_items,
    selected_current_week,
    selected,
    calendar_type,
    selected_total_week,
    preview_selected_item,
  } = state;

  const { payload } = action;

  switch (action.type) {
    case 'SORT_PROGRAM_LIBRARY_LIST':
    case 'SEARCH_PROGRAM_LIBRARY_LIST':
      let isAscending = state.isAscending;
      if (action.type === 'SORT_PROGRAM_LIBRARY_LIST') {
        sorter = action.payload.sortBy;
        query = state.query || '';
      } else {
        query = action.payload.query || '';
        sorter = state.sorter;
      }

      if (action.type === 'SORT_PROGRAM_LIBRARY_LIST' && sorter === state.sorter) {
        isAscending = !isAscending;
      }

      currentPage = 1;
      let sorted = sortProgramLibrary(all_items, sorter, isAscending);
      list_items = sorted.filter(item => item.title.toLowerCase().includes(query.toLowerCase()));
      list = list_items.slice((currentPage - 1) * per_page, (currentPage - 1) * per_page + per_page);

      return Object.assign({}, state, {
        items: list,
        filtered_items: list_items,
        page: 1,
        sorter,
        isAscending,
        query,
      });

    case 'LOADED_GET_PROGRAM_LIBRARY_LIST':
      let all = action.payload.data;
      currentPage = state.page;
      sorted = sortProgramLibrary(all, state.sorter, state.isAscending);
      list_items = sorted.filter(item => item.title.toLowerCase().includes(state.query.toLowerCase()));
      list = list_items.slice((currentPage - 1) * per_page, (currentPage - 1) * per_page + per_page);

      return Object.assign({}, state, {
        items: list,
        all_items: sorted,
        filtered_items: list_items,
        loadingLibrary: false,
      });

    case 'FAILED_GET_PROGRAM_LIBRARY_LIST':
      return Object.assign({}, state, {
        loadingLibrary: false,
      });
    case 'LOADING_PROGRAM_LIBRARY_LIST':
      return Object.assign({}, state, {
        loadingLibrary: true,
      });
    case 'REMOVE_PROGRAM_LIBRARY_REDUX':
      let deletedId = action.payload.id;
      let _all = state.all_items;
      let _filter = state.filtered_items;
      let _items = state.items;

      _.remove(_all, item => {
        return item._id === deletedId;
      });
      _.remove(_filter, item => {
        return item._id === deletedId;
      });
      _.remove(_items, item => {
        return item._id === deletedId;
      });

      if (preview_selected_item && preview_selected_item._id === deletedId) {
        preview_selected_item = null;
      }

      return Object.assign({}, state, {
        all_items: _all,
        filtered_items: _filter,
        items: _items,
        preview_selected_item,
      });
    case 'APPEND_PROGRAM_LIBRARY_REDUX':
      query = state.query;
      sorter = state.sorter;
      currentPage = state.page;
      per_page = state.per_page;
      let newItem = action.payload.program;
      _.remove(all_items, item => {
        return item._id === newItem._id;
      });
      if (newItem.author._id === action.payload.userId || newItem.share !== TEAM_SHARE_PRIVATE) {
        all_items.push(newItem);
      }
      list_items = sortProgramLibrary(all_items, sorter, state.isAscending).filter(item => {
        return item.title.toLowerCase().includes(query.toLowerCase());
      });
      list = list_items.slice((currentPage - 1) * per_page, (currentPage - 1) * per_page + per_page);
      selected = state.selected;

      if (selected && selected._id == newItem._id) {
        selected = newItem;
      }
      return Object.assign({}, state, {
        all_items,
        items: list,
        filtered_items: list_items,
        selected,
      });

    case 'LOADING_PROGRAM_LIBRARY_DETAIL': {
      return Object.assign({}, state, {
        loadingPreviewItem: true,
        preview_selected_item: {
          ...state.preview_selected_item,
          workout_sets: [],
        },
      });
    }
    case 'LOADED_GET_PROGRAM_LIBRARY_DETAIL':
      let data = action.payload.program;

      let selectedData = _.omit(data, 'workout_sets');
      return Object.assign({}, state, {
        selected: {
          ...selectedData,
          workout_sets: action.payload.program.workout_sets,
        },
        selected_total_week: data.workout_sets.length,
        selected_current_week: 0,
        calendar_type: payload.program.workout_sets.length === 1 ? 1 : DEFAULT_STATE.calendar_type,
        loadingPreviewItem: false,
      });

    case Types.PROGRAM_LIBRARY_GET_BY_WEEKS_REQUEST: {
      return Object.assign({}, state, {
        isLoadingWorkouts: true,
      });
    }

    case Types.PROGRAM_LIBRARY_GET_BY_WEEKS_SUCCESS: {
      let data = action.payload.program;

      const currentWorkoutSets = _.cloneDeep(_.get(state, 'selected.workout_sets', []));

      currentWorkoutSets.forEach((week, index) => {
        const weekId = _.isObject(week) ? week._id : week;
        const workouts = data.workout_sets.find(it => it._id === weekId);

        if (workouts) {
          currentWorkoutSets[index] = {
            ...workouts,
          };
        }
      });

      return Object.assign({}, state, {
        selected: {
          ...state.selected,
          workout_sets: currentWorkoutSets,
        },
        isLoadingWorkouts: false,
      });
    }

    case 'CHANGE_PROGRAM_CALENDAR_TYPE':
      calendar_type = action.payload.calendarType;
      const maxValue = selected_total_week - calendar_type;

      return Object.assign({}, state, {
        calendar_type,
        selected_current_week: calendar_type === 1 ? selected_current_week : Math.min(maxValue, selected_current_week),
      });

    case 'PROGRAM_LIBRARY_NEXT_CURRENT_WEEK':
      newWeek = selected_current_week + 1;
      totalWeek = selected.workout_sets.length;

      return Object.assign({}, state, {
        selected_current_week: newWeek <= totalWeek - 1 ? newWeek : totalWeek - 1 - ((totalWeek - 1) % calendar_type),
      });

    case 'PROGRAM_LIBRARY_PREV_CURRENT_WEEK':
      return Object.assign({}, state, {
        selected_current_week: selected_current_week - 1 > -1 ? selected_current_week - 1 : 0,
      });

    case 'REFRESH_CURRENT_PROGRAM_LIBRARY':
      return refreshCurrentProgram(state, action.payload);

    case 'REFRESH_AFTER_UPDATE_WEEK_PROGRAM_LIBRARY':
      return refreshAfterUpdateWeekRangeProgram(state, action.payload);

    case 'REFRESH_WORKOUT_IN_CURRENT_PROGRAM_LIBRARY':
      selected = _.cloneDeep(state.selected);
      _dayIndex = action.payload.dayIndex;
      _weekIndex = action.payload.weekIndex;
      if (selected && selected.workout_sets.length > _weekIndex) {
        workout_set = selected.workout_sets[_weekIndex];
        let newSets = [];
        newSets = workout_set.days_workout.map(item => {
          if (item.day_index === _dayIndex) {
            const foundIndex = _.findIndex(item.day_workout.workouts, workout => {
              return workout._id === action.payload.workout._id;
            });
            if (foundIndex !== -1) {
              item.day_workout.workouts[foundIndex] = action.payload.workout;
            } else {
              item.day_workout.workouts.push(action.payload.workout);
            }
          }
          return item;
        });
        selected.workout_sets[_weekIndex].days_workout = newSets;
      } else {
      }
      return Object.assign({}, state, {
        selected,
      });

    case 'REMOVE_WORKOUT_FROM_PROGRAM_LIBRARY_REDUX':
      selected = state.selected;
      _dayIndex = action.payload.dayIndex;
      _weekIndex = action.payload.weekIndex;
      if (selected && selected.workout_sets.length > _weekIndex) {
        workout_set = selected.workout_sets[_weekIndex];
        let newSets = [];
        newSets = workout_set.days_workout.map(item => {
          if (item.day_index === _dayIndex) {
            item.day_workout.workouts = item.day_workout.workouts.filter(wItem => {
              return wItem._id !== action.payload.workoutId;
            });
          }
          return item;
        });
        selected.workout_sets[_weekIndex].days_workout = newSets;
      }
      return Object.assign({}, state, {
        selected,
      });

    case 'SELECT_PREVIEW_PROGRAM_LIBRARY':
      return Object.assign({}, state, {
        preview_selected_item: action.payload.program,
        loadingPreviewItem: false,
      });

    case 'REMOVE_SELECTED_PROGRAM':
      return Object.assign({}, state, {
        selected: null,
        last_job_run: null,
        assigned_clients: [],
      });

    case 'PROGRAM_LIBRARY_SUCCESS_REMOVE_CLIENT':
      return Object.assign({}, state, {
        assigned_clients: state.assigned_clients.filter(o => !action.payload.data.programs.includes(o.program._id)),
      });

    case 'PROGRAM_LIBRARY_COPY_WORKOUT':
      return Object.assign({}, state, {
        copying_workout: action.payload.item,
      });

    case 'PROGRAM_LIBRARY_COPIED_WORKOUT':
    case 'PROGRAM_CLEAR_COPY_ITEM':
      return action.multiPaste ? state : Object.assign({}, state, { copying_workout: null });

    case 'PROGRAM_GO_TO_WEEK':
      return Object.assign({}, state, { selected_current_week: action.payload.week });

    case 'PROGRAM_TOGGLE_LIVE_SYNC':
      return Object.assign({}, state, {
        selected: {
          ...state.selected,
          active_sync: action.payload.active_sync,
        },
      });

    case 'SUCCESS_GET_CLIENTS_ASSIGNED_TO_PROGRAM':
      if (!selected || selected._id === payload.pid) {
        return Object.assign({}, state, { assigned_clients: payload.list });
      }

      return state;

    case 'PROGRAM_UPDATE_LAST_JON_RUN':
      if (!selected || selected._id === payload.pid) {
        return Object.assign({}, state, { last_job_run: payload.last_job_run });
      }

      return state;

    case 'DECREASE_PAGE_PROGRAM_LIBRARY':
      if (state.page === 1) {
        return state;
      }

      return Object.assign({}, state, {
        items: state.filtered_items.slice((state.page - 2) * per_page, (state.page - 2) * per_page + per_page),
        page: state.page - 1,
      });

    case 'INCREASE_PAGE_PROGRAM_LIBRARY':
      if (state.page * per_page < filtered_items.length) {
        return Object.assign({}, state, {
          items: state.filtered_items.slice(state.page * per_page, state.page * per_page + per_page),
          page: state.page + 1,
        });
      }

      return state;

    case 'UPDATE_EXERCISE_LIBRARY_FROM_PROGRAM_CALENDAR':
      if (selected) {
        const {
          dayIndex,
          weekIndex,
          assignmentId,
          oldExerciseId,
          newExerciseLibrary,
          categories,
          fields,
          unit,
        } = payload.data;

        let newSelected = _.cloneDeep(selected);

        _.forEach(newSelected.workout_sets, (weekData, index) => {
          if (index === weekIndex) {
            _.forEach(weekData.days_workout, dayData => {
              if (dayData.day_index === dayIndex) {
                _.forEach(dayData.day_workout.workouts, assignment => {
                  if (assignment._id === assignmentId) {
                    assignment.sections = updateExerciseLibraryInWorkout(
                      assignment.sections,
                      oldExerciseId,
                      newExerciseLibrary,
                      { categories, fields, unit },
                    );

                    return false;
                  }
                });

                return false;
              }
            });

            return false;
          }
        });

        return Object.assign({}, state, { selected: newSelected });
      }

      return state;

    case Types.PROGRAM_CALENDAR_UNMOUNT:
      return Object.assign({}, state, {
        selected_current_week: 0,
        calendar_type: DEFAULT_STATE.calendar_type,
      });

    case Types.PROGRAM_LIBRARY_MOVE_WORKOUT:
      return handleMoveWorkout(payload.data, state);

    case Types.PROGRAM_LIBRARY_MOVE_EXERCISE:
      return handleMoveExercise(payload, state);

    case Types.PROGRAM_LIBRARY_SELECTED_WEEK: {
      const { weekIndex, weekCopyId } = payload;
      return Object.assign({}, state, {
        selectedWeek: weekIndex,
        weekCopyId: weekCopyId,
      });
    }

    case Types.PROGRAM_LIBRARY_COPY_WEEK_REQUEST:
      if (action.payload.multiPaste) return state;
      return Object.assign({}, state, { selectedWeek: null });

    case Types.PROGRAM_LIBRARY_PASTE_MULTIPLE_WORKOUT_REQUEST:
      if (payload.multiPaste) return state;
      return Object.assign({}, state, { selectedWorkout: [], isCopy: false });

    case Types.PROGRAM_LIBRARY_REMOVE_WEEK_SUCCESS:
      const workoutSets = _.get(selected, 'workout_sets');
      return Object.assign({}, state, {
        selected: {
          ...state.selected,
          workout_sets: removeWorkoutByIds(payload, workoutSets),
        },
      });

    case Types.PROGRAM_LIBRARY_SELECTED_WORKOUTS: {
      const payload = action.payload;
      return Object.assign({}, state, {
        selectedWorkout: payload.workoutIds,
      });
    }

    case Types.PROGRAM_LIBRARY_RESET_SELECTED_WEEK: {
      return Object.assign({}, state, { selectedWeek: null, weekCopyId: null });
    }

    case Types.PROGRAM_LIBRARY_RESET_SELECTED_WORKOUT: {
      return Object.assign({}, state, { selectedWorkout: [], isCopy: false, workoutsCopyId: null });
    }

    case Types.PROGRAM_LIBRARY_COPY_WORKOUTS: {
      return Object.assign({}, state, {
        isCopy: _.isEmpty(action.payload.workouts) ? false : true,
        workoutsCopyId: payload.copyId,
      });
    }

    case Types.PROGRAM_LIBRARY_REMOVE_MULTIPLE_WORKOUT_SUCCESS: {
      const workoutSets = _.get(selected, 'workout_sets');
      return Object.assign({}, state, {
        selectedWorkout: [],
        isCopy: false,
        selected: { ...state.selected, workout_sets: removeWorkoutByIds(payload, workoutSets) },
      });
    }

    case Types.PROGRAM_LIBRARY_SOCKET_WORKOUT_ADDED: {
      const { weekIndex, dayIndex, workout } = payload;
      const { isAddingWeek } = state;

      const newWorkoutSet = _.get(state, 'selected.workout_sets', []);

      const isNotFoundDayIndex = !_.get(newWorkoutSet[weekIndex], `days_workout`, []).some(
        item => _.get(item, 'day_index') === dayIndex,
      );

      if (isNotFoundDayIndex) {
        newWorkoutSet[weekIndex].days_workout.push({
          day_workout: { workouts: [] },
          day_index: dayIndex,
        });
      }

      if (
        !isAddingWeek &&
        selected &&
        newWorkoutSet.length > weekIndex &&
        Array.isArray(newWorkoutSet[weekIndex].days_workout)
      ) {
        newWorkoutSet[weekIndex].days_workout = newWorkoutSet[weekIndex].days_workout.map(item => {
          if (_.get(item, 'day_index') === dayIndex) {
            item.day_workout.workouts = [...item.day_workout.workouts, workout];
          }
          return item;
        });
      }

      return Object.assign({}, state, {
        selected: {
          ...state.selected,
          workout_sets: newWorkoutSet,
        },
      });
    }

    case Types.ADDING_PROGRAM_WEEK_LIBRARY_REQUEST:
    case Types.REMOVE_PROGRAM_WEEK_LIBRARY_REQUEST: {
      return Object.assign({}, state, {
        isAddingWeek: true,
      });
    }

    case Types.ADDING_PROGRAM_WEEK_LIBRARY_SUCCESS:
    case Types.PROGRAM_LIBRARY_SUCCESS_DELETE_WEEK:
    case Types.ADDING_PROGRAM_WEEK_LIBRARY_ERROR:
    case Types.PROGRAM_LIBRARY_FAILED_DELETE_WEEK: {
      return Object.assign({}, state, {
        isAddingWeek: false,
      });
    }

    default:
      return state;
  }
};

export default WorkoutReducer;
