import React from 'react';
import { connect } from 'react-redux';
import Pagination from 'shared/Pagination';
// import { changeQueryParams } from 'redux/exercise/exercise.actionCreators';

function View(props) {
  const { total, page, per_page, length } = props;

  const handleClickPrev = () => {
    props.changeQueryParams({ page: page - 1 });
  };

  const handleClickNext = () => {
    props.changeQueryParams({ page: page + 1 });
  };

  if (!length) {
    return null;
  }

  return (
    <Pagination
      page={page}
      perPage={per_page}
      totalPage={total}
      onPrev={handleClickPrev}
      onNext={handleClickNext}
    />
  );
}

const mapState = state => {
  const {
    rootReducer: { waitingActivationClients },
  } = state;

  return {
    total: waitingActivationClients.get('total'),
    page: waitingActivationClients.getIn(['query', 'page']),
    per_page: waitingActivationClients.getIn(['query', 'per_page']),
    length: waitingActivationClients.getIn(['list', 'size']),
  };
};

export default connect(mapState, {})(View);
