import styled from 'styled-components';

export const DateSettingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: ${props => (props.fullWidth ? '100%' : '60%')};
  max-width: 650px;

  .common-select-container__control {
    border-radius: 5px;
    border: 1px solid #dadfea;

    :hover {
      border-color: #5158cf;
    }

    span {
      font-weight: 600;
      color: #323c47;
    }
  }

  .common-select-container__option {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }

  .common-select-container__indicator.common-select-container__dropdown-indicator {
    padding-right: 13px;
    padding-top: 10px;
    color: #323c47;
  }
`;

export const TextBold = styled.p`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #222;
  margin: 0;
`;

export const Text = styled.p`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #888;
  margin: 0;
  margin-top: 8px;
`;
