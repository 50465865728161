import React from 'react';

import * as S from 'shared/Giphy/styles';

function PlaceholderLoader(props) {
  const { repliedSize, isReplyComment, loaderMaxSize, ratioComment, ratioCommentForum, isInbox } = props;

  return (
    <S.PlaceholderLoader
      repliedSize={repliedSize}
      isReplyComment={isReplyComment}
      loaderMaxSize={loaderMaxSize}
      ratioComment={ratioComment}
      ratioCommentForum={ratioCommentForum}
      isInbox={isInbox}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </S.PlaceholderLoader>
  );
}

export default PlaceholderLoader;
