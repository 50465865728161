import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const ModalWrapper = styled(Modal)`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #263d52;

  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 870px;

    > .content {
      padding: 0 !important;
      border-radius: 8px !important;
      display: grid;
      grid-template-columns: 330px 540px;
      min-height: 472px;
    }
  }

  .update-pricing {
    height: 34px;
  }
`;

export const LeftContainer = styled.div`
  background: #f6f7fb;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 30px 0 20px 20px;
`;

export const RightContainer = styled.div`
  background-color: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const PricingTypeItem = styled.div`
  background: linear-gradient(90deg, #ffffff 29.35%, rgba(255, 255, 255, 0) 80.65%);
  margin-bottom: 5px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  :hover {
    background: #fff;
    cursor: pointer;

    .ui.checkbox label::before {
      border-color: #5158cf;
    }
  }

  .ui.radio.checkbox {
    padding: 19px 15px;
    width: 100%;

    label {
      font-weight: 600;
      font-size: 14px;
      color: #202353;
      font-family: Open Sans;
    }
  }

  ${props =>
    props.active &&
    css`
      background: #fff;

      .ui.radio.checkbox {
        label {
          color: #5158cf;
        }
      }
    `}

  ${props =>
    props.disable &&
    css`
      .ui.radio.checkbox {
        label {
          opacity: 0.4;
        }
      }

      :hover {
        background: linear-gradient(90deg, #ffffff 29.35%, rgba(255, 255, 255, 0) 80.65%);
        cursor: not-allowed;
      }

      .ui.checkbox label {
        cursor: not-allowed !important;

        :before {
          border-color: #d9dadf !important;
          background-color: #f6f7fb !important;
        }
      }
    `}
`;

export const Actions = styled.div`
  text-align: right;
  margin-top: 24px;

  button {
    width: 154px;
  }
`;

export const WarningContainer = styled.div``;

export const WarningMessage = styled.div`
  background: #fff4d9;
  border-radius: 5px;
  color: #b56a26;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 12px 10px;
  span {
    font-weight: 600;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  svg {
    margin-right: 15px;
    path {
      fill: #da8b14;
    }
  }
`;

export const WarningType = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  padding: 15px 0 10px;
`;

export const WarningDetail = styled.div`
  font-size: 13px;
  line-height: 150%;
  color: #202353;
  padding-right: 20px;
`;

export const PricingTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  margin-bottom: 25px;
`;
