import styled from 'styled-components';

export const Input = styled.div`
  padding: 15px 10px 15px 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  max-height: 90px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
  outline: none !important;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }

  :empty::before {
    content: ${props => `'${props.placeholder}'`};
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    opacity: .2;
  }
`;

export const ButtonContainer = styled.div`
  padding: 15px 20px 15px 10px;
  display: flex;
  align-items: flex-end;

  button {
    outline: none !important;
    box-shadow: none !important;

    &.button--post {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      color: #5C5BBD;
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #DDE2ED;
`;