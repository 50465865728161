import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  width: 100%;
  min-height: 218px;
  max-height: 420px;
  padding-top: 10px;
  border: 1px dashed #e1e1ea;
  border-radius: 5px;
  background-color: #fff;

  .category-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 13px;
  }

  .category-name {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #202353;
  }

  .category-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-right: 4px;
    padding: 0 5px 12px 13px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px !important;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px !important;
      background: #e1e1ea !important;
    }
    ::-webkit-scrollbar-track {
      margin-bottom: 12px;
    }
  }
`;
