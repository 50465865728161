import { ReactComponent as DeleteIcon } from 'assets/icons/remove_photo_icon.svg';
import { ReactComponent as ZoomIcon } from 'assets/icons/zoom.svg';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useRef, useEffect } from 'react';
import ProgressPhotoTag from 'shared/ProgressPhotoTag';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ReactTooltip from 'react-tooltip';
import { formatDate, getThumbnailProgressPhoto } from '../../../helper';
import PlaceholderLoader from '../../PlaceholderLoader';
import InputRange from '../InputRange';
import * as S from './style';

const ItemCompare = ({
  handleChangePhoto,
  item,
  isSelected,
  selectedPhoto,
  tag,
  comparePhotoList,
  handlePhotoCompare,
  selectedDate,
  isDragging = false,
  updatePhotoZoom,
  openZoomSetting,
  isOpenZoomSettingItem,
  resetChangePhotoCompareMode,
  scale,
}) => {
  const [loaded, setLoaded] = useState(!window.Cypress ? false : true);
  const [showZoomSetting, setShowZoomSetting] = useState(false);

  const zoomRef = useRef(null);

  const onLoad = () => {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  };

  const handleOpenZoomSetting = e => {
    e.stopPropagation();
    setShowZoomSetting(true);
    openZoomSetting && openZoomSetting({ selectedPhoto, isOpenZoomSettingItem: true });
    resetChangePhotoCompareMode && resetChangePhotoCompareMode({ selectedPhoto });
  };

  const handleZoomSetting = e => {
    e.stopPropagation();
  };

  const handleConfirm = value => {
    setShowZoomSetting(false);
    updatePhotoZoom({ selectedPhoto, value });
    openZoomSetting({ selectedPhoto, isOpenZoomSettingItem: false });
  };

  useEffect(() => {
    if (zoomRef.current) {
      const scaleZoom = scale / 100 + 1;
      zoomRef.current.centerView(scaleZoom, 0);
    }
  }, [zoomRef.current]);

  return (
    <TransformWrapper
      ref={zoomRef}
      wheel={{ disabled: true }}
      panning={{ disabled: !isOpenZoomSettingItem || false, velocityDisabled: true }}
      doubleClick={{ disabled: true }}
      alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
    >
      {({ zoomIn, zoomOut, resetTransform, setTransform, centerView, ...rest }) => (
        <S.PhotoWrapper
          isSelected={isSelected}
          onClick={() => {
            if (!isOpenZoomSettingItem) {
              handleChangePhoto(item);
            }
          }}
          isDragging={isDragging}
        >
          <TransformComponent>
            {!window.Cypress && (
              <S.S3ImageWithFallbackWrapper
                loaded={loaded}
                src={getThumbnailProgressPhoto([selectedPhoto])}
                onLoad={onLoad}
              />
            )}
          </TransformComponent>
          {!loaded && <PlaceholderLoader />}
          {isSelected && <S.CheckedIconWrapper />}
          {loaded && (
            <>
              {!showZoomSetting && (
                <>
                  {tag && (
                    <S.TagWrapper className="compare-mode-tag" isCypress={window.Cypress}>
                      <ProgressPhotoTag transparent value={tag || 'No tag'} />
                    </S.TagWrapper>
                  )}
                  {!isSelected &&
                    !isEmpty(get(comparePhotoList, '[0]', {})) &&
                    !isEmpty(get(comparePhotoList, '[1]', {})) && (
                      <S.DeleteButton
                        className="compare-mode-remove"
                        isCypress={window.Cypress}
                        onClick={e => handlePhotoCompare(e, selectedPhoto)}
                      >
                        <DeleteIcon />
                      </S.DeleteButton>
                    )}
                </>
              )}
              <S.Overlay />
              {!showZoomSetting && <S.PostedDate>{formatDate(selectedDate)}</S.PostedDate>}
              {!showZoomSetting ? (
                <S.ZoomSettingButton
                  data-tip
                  data-for={`"zoom-tooltip-${selectedPhoto}"`}
                  onClick={handleOpenZoomSetting}
                  className="compare-mode-zoom"
                >
                  <ZoomIcon />
                  <ReactTooltip
                    className="zoom-tooltip"
                    id={`"zoom-tooltip-${selectedPhoto}"`}
                    effect="solid"
                    place="left"
                  >
                    <span>Zoom to fit</span>
                  </ReactTooltip>
                </S.ZoomSettingButton>
              ) : (
                <InputRange
                  handleZoomSetting={handleZoomSetting}
                  handleConfirm={handleConfirm}
                  handleZoomPhoto={value => {
                    centerView(value);
                  }}
                  onChangeRange={value => {
                    centerView(value);
                  }}
                  handleResetZoom={() => {
                    centerView(1);
                  }}
                  defaultValue={scale}
                />
              )}
            </>
          )}
        </S.PhotoWrapper>
      )}
    </TransformWrapper>
  );
};

export default ItemCompare;
