import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BodyMetricChartNew from './component';
import { changeViewMode, updateBodyMetricTarget } from 'actions/bodyMetric';
import get from 'lodash/get';

const mapStateToProps = state => {
  const {
    user,
    bodyMetric,
    rootReducer: { client, customBranding, hearRateMetric },
  } = state;
  const { workingClientDetail, bodymetricTypes } = client;

  return {
    dateFormat: user.date_format,
    timezone: user.timezone,
    bodyMetric,
    hearRateMetric,
    client: workingClientDetail,
    metricTypes: bodymetricTypes,
    customBrandingColor: get(customBranding, 'originalTheme.primaryColor', ''),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBodyMetricTarget: bindActionCreators(updateBodyMetricTarget, dispatch),
    changeViewMode: bindActionCreators(changeViewMode, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyMetricChartNew);
