import styled, { css } from 'styled-components';

export const SidebarItemContainer = styled.div`
  cursor: pointer;
  position: relative;
  height: 18px;
  width: 18px;
  z-index: 9999;

  .program-library-remove-action {
    margin-left: 5px;
  }

  .list-dropdown {
    padding: 8px 0px;

    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    margin-left: 14px;

    width: 246px;
    height: 89px;
    max-height: 89px;

    background: #2d2e2d;
    border-radius: 5px;
  }

  .list-dropdown-item {
    display: flex;
    align-items: center;
    height: 34px;
    padding: 8px 15px 8px;
    color: #ffffff;
    .remove-icon {
      margin-right: 11px;
      path {
        stroke: #ffffff;
      }
    }
  }

  .list-dropdown-item.entire {
    margin-left: 0;
    margin-top: 5px;
    svg {
      shape-rendering: crispedges;
    }
  }

  .list-dropdown-item:hover svg path {
    stroke: #ffffff;
  }

  .menu-item-dropdown-content {
    top: -35px;
    transition: opacity 0.3s ease-out;
  }

  :hover {
    .menu-item-dropdown-content {
      visibility: visible;
      left: 16px;
      opacity: 1;
    }
    .action-icon path {
      stroke: #5158cf;
    }
  }

  .list-dropdown > .list-dropdown-item:first-child:after {
    top: 40px;
    left: 4px;
    border-width: 5px;
  }

  .list-dropdown::after {
    content: '';
    position: absolute;
    top: 40px;
    left: 4px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #2d2e2d transparent transparent;
  }

  ${props =>
    props.disabled &&
    css`
      .list-dropdown {
        cursor: not-allowed;
      }
      .list-dropdown-item {
        pointer-events: none;
      }
    `}
`;
