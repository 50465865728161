import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleModal } from 'actions/modal';
import {
  changeQueryParams,
  getWaitList,
  approveRequestWaitList,
  rejectRequestWaitList,
  selectedClientIdWaitList,
  selectedClientIdsWaitList,
  resetWaitListState,
} from 'redux/waitlist/actions';
import WaitListView from './component';

const mapState = state => {
  const {
    user,
    router: { location },
    rootReducer: {
      packageDetail,
      waitList: { list, loading, total, queryParam, clientIdsSelected, clientIdSelected },
    },
  } = state;
  return {
    user,
    location,
    list,
    loading,
    total,
    queryParam,
    clientIdSelected,
    clientIdsSelected,
    workingPackage: packageDetail.get('workingPackage').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resetWaitListState: bindActionCreators(resetWaitListState, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
    getWaitList: bindActionCreators(getWaitList, dispatch),
    approveRequestWaitList: bindActionCreators(approveRequestWaitList, dispatch),
    rejectRequestWaitList: bindActionCreators(rejectRequestWaitList, dispatch),
    selectedClientIdWaitList: bindActionCreators(selectedClientIdWaitList, dispatch),
    selectedClientIdsWaitList: bindActionCreators(selectedClientIdsWaitList, dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(WaitListView);
