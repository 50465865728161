import styled from 'styled-components';

export const Top = styled.div`
  margin-bottom: 40px;
`;

export const TopTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
  color: #000000;
`;

export const TopText = styled.p`
  width: 458px;
  margin: auto;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
`;

export const Header = styled.div`
  background: linear-gradient(90deg, #fa709a 0%, #fee140 100%);
  border-radius: 5px;
  color: #fff;
  padding: 0 15px 0 30px;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding-top: 18px;
    flex-direction: column;
  }

  .messages {
    display: flex;
    flex-direction: row;
  }

  .text {
    flex: 1 1;
  }

  .title {
    font-weight: bold;
  }

  .icon {
    margin-right: 18px;
    margin-top: 3px;
    width: 32px;
  }

  .download {
    display: flex;
    margin-top: 18px;

    a {
      display: block;

      :first-child {
        margin-right: -20px;
      }
    }
  }
`;

export const Content = styled.div`
  .guideContainer {
    padding: 20px 0 30px;
    text-align: center;

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #6456c4;

      :hover {
        text-decoration: underline;
      }
    }

    svg {
      margin-right: 4px;
      vertical-align: sub;
    }
  }

  .content {
    width: 100%;
    height: auto;
    position: relative;
  }

  .explore {
    z-index: 1;
    position: absolute;
    left: calc(100% - 42px);
    top: 155px;

    @media only screen and (max-width: 1220px) {
      left: auto;
      top: 47.25%;
      right: 1%;
      width: 30%;
      img {
        width: 100%;
      }
    }

    :hover {
      cursor: pointer;
    }
  }
`;

export const Container = styled.div`
  max-width: 780px;
  margin: auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  background: #fafbfc;
  overflow: auto;
`;

export const ForumBackground = styled.img`
  width: 51.4vw;
`;

export const UpgradeButton = styled.button`
  margin-top: 30px;
  outline: none;
  border: none;
  box-shadow: none;
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  color: #fff;
  padding: 10px 35px;
  font-weight: bold;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
`;
