import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import enGb from 'moment/locale/en-gb';
import { DateTime } from 'luxon';
import { Container, Arrow, CalendarIcon } from './styles';

export default class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  onSelectDate = date => {
    const { startDate, weeks, onChange } = this.props;
    const startDateMoment = startDate.convertToMoment();

    if (!date.isSame(startDateMoment, 'isoWeek')) {
      const selectedDateLuxon = moment(date).convertToLuxon();
      const newStartDate = selectedDateLuxon.startOf('week');
      const newEndDate = newStartDate.plus({ days: 7 * weeks });
      onChange(newStartDate, newEndDate);
    }
  };

  onArrowClick = factor => {
    const { startDate, weeks, onChange } = this.props;
    const newStartDate = startDate.plus({ days: 7 * factor });
    const newEndDate = newStartDate.plus({ days: 7 * weeks });
    onChange(newStartDate, newEndDate);
  };

  renderDay = (props, currentDate, selectedDate) => {
    const { startDate, weeks } = this.props;
    const startDateMoment = startDate.convertToMoment();
    const endDateMoment = moment(startDateMoment).add(7 * weeks, 'days');
    let className = props.className || '';

    const isStartDate = currentDate.isSame(startDateMoment, 'day');
    const isEndDate = currentDate.isSame(moment(endDateMoment).add(-1, 'days'), 'day');

    if (isStartDate || currentDate.isBetween(startDateMoment, endDateMoment)) {
      className += ' inRange';
    }

    if (isStartDate) {
      className += ' rangeStart';
    }

    if (isEndDate) {
      className += ' rangeEnd';
    }

    return (
      <td {...props} className={className}>
        <div className="content">
          <div>{currentDate.date()}</div>
        </div>
      </td>
    );
  };

  renderDate = date => {
    const value = date.convertToMoment();
    let format = 'MMM d, yyyy';
    if (value.isSame(new moment(), 'year')) format = 'MMM d';
    return date.toFormat(format);
  };

  render() {
    const { startDate, weeks } = this.props;
    const value = startDate.convertToMoment();
    let endDate = DateTime.fromISO(startDate.toISODate());
    endDate = endDate.plus({ days: 7 * weeks - 1 });

    return (
      <Container>
        <Arrow left onClick={() => this.onArrowClick(-1)} />
        <Datetime
          value={value}
          renderInput={props => {
            return (
              <div {...props}>
                <CalendarIcon />
                <span style={{ marginLeft: '10px' }}>
                  {this.renderDate(startDate)} - {this.renderDate(endDate)}
                </span>
              </div>
            );
          }}
          timeFormat={false}
          locale={moment.locale()}
          closeOnSelect={true}
          onChange={this.onSelectDate}
          renderDay={this.renderDay}
        />
        <Arrow right onClick={() => this.onArrowClick(1)} />
      </Container>
    );
  }
}
