import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateTime } from 'luxon';
import { changeCalendarType, changeCalendarStartDate } from 'actions/calendar';
import { toggleSideNav } from 'actions/sidenav';
import { toggleSideBar } from 'actions/sidebar';
import {
  arrangeExerciseSet,
  moveExerciseSet,
  resetCopyItem,
  moveAssignment,
} from 'redux/assignment/assignment.actionCreators';
import {
  changeCalendarMode,
  handleCopyWorkout,
  handleRemoveWorkouts,
  handleResetSelectedWeek,
  handleResetSelectWorkout,
  handleHideWorkouts,
} from 'redux/calendar/calendar.actionCreators';
import ListCalendar from 'components/ListCalendar/component';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { saveCalendarAsProgram } from 'redux/program_library/program_library.actionCreators';
import { fetchWorkoutsInRange } from 'actions/workout/getWorkouts';
import { getMostRecentTagsList } from 'redux/tags/actions';

const mapStateToProps = state => {
  const {
    user,
    calendarType,
    calendarStartDate,
    sideNavVisible,
    isModalOpen,
    modal,
    isConfirmModalOpen,
    confirmModal,
    errorPopup,
    isShowingError,
    rootReducer,
    sideBarVisible,
    copyingAssignment,
    rootReducer: {
      calendar: { selectedWeek, selectedWorkout, view_mode },
      permission,
    },
  } = state;
  const { workingClientDetail } = rootReducer.client;
  let training_preference = workingClientDetail
    ? workingClientDetail.feature_preferences.find(item => item.type === 'training')
    : null;
  const isTrainingFeaturing = training_preference ? training_preference.state : false;
  return {
    user,
    calendarType,
    calendarStartDate,
    sideNavVisible,
    isModalOpen,
    modal,
    isConfirmModalOpen,
    confirmModal,
    errorPopup,
    isShowingError,
    selectedClient: rootReducer.client.selected,
    calendarMode: rootReducer.calendar.view_mode,
    isShowingSuccess: rootReducer.modal.isShowingSuccess,
    successPopup: rootReducer.modal.successPopup,
    sideBarVisible,
    assignedPrograms: rootReducer.client.assignedPrograms,
    copyingAssignment,
    isTrainingFeaturing,
    clientName: `${workingClientDetail.first_name} ${workingClientDetail.last_name}`,
    loading: rootReducer.calendar.loading,
    selectedWeek,
    selectedWorkout,
    permission,
    view_mode,
  };
};

const changeCalendarModeView = (dispatch, mode, prevMode, queryData = null, calendarStartDate = null) => {
  dispatch(changeCalendarMode(mode));
  dispatch(changeCalendarStartDate(queryData, calendarStartDate, mode, prevMode));
};

const handleTodayClick = (dispatch, clientId, calendarType) => {
  const today = DateTime.local().startOf('week');
  const newEndDate = today.plus({ days: 7 * calendarType });

  dispatch(
    changeCalendarStartDate(
      {
        client: clientId,
        start_date: today.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      },
      today,
    ),
  );
};

const handleCalendarTypeChange = (dispatch, newStartDate, clientId, calendarType) => {
  const newEndDate = newStartDate.plus({ days: 7 * calendarType });
  dispatch(
    changeCalendarType(
      {
        client: clientId,
        start_date: newStartDate.toFormat('MM-dd-yyyy'),
        end_date: newEndDate.toFormat('MM-dd-yyyy'),
      },
      calendarType,
    ),
  );
};

const toggleSideNavigation = (dispatch, visible) => {
  dispatch(toggleSideNav(visible));
};

const onDragEnd = (dispatch, result, client) => {
  if (result.type === 'exercise') {
    onDragEndExercise(dispatch, result, client);
  } else {
    onDragEndAssignment(dispatch, result, client);
  }
};

const onDragEndAssignment = (dispatch, result, client) => {
  if (!result.source || !result.destination) {
    return;
  }

  const { source, destination } = result;
  const newIndex = destination.index;
  const fromIndex = source.index;
  const oldDay = source.droppableId.split('_')[1];
  const timezone = DateTime.local().zoneName;
  const newDay = destination.droppableId.split('_')[1];
  const currentDate = DateTime.local();
  let toDate = DateTime.fromFormat(newDay, 'MM-dd-yyyy');
  let newDate = toDate.set({
    hour: currentDate.get('hour'),
    minute: currentDate.get('minute'),
    second: currentDate.get('second'),
  });
  const assignmentId = result.draggableId.split('_')[1];
  if (oldDay === newDay && fromIndex === newIndex) {
    return;
  }
  const params = {
    oldDay,
    fromIndex,
    newIndex,
    timezone,
    newDay,
    newDate,
    assignmentId,
  };

  dispatch(moveAssignment(params));
};

const onDragEndExercise = (dispatch, result, client) => {
  if (!result.source || !result.destination) {
    return;
  }

  const source = result.source;
  const destination = result.destination;
  const [to, toAssignment, assignIndex] = destination.droppableId.split(';');
  const [from, fromAssignment, sectionId] = result.draggableId.split(';');
  const fromIndex = source.index;
  const newIndex = assignIndex ? assignIndex : parseInt(destination.index);

  if (from === to && fromIndex === newIndex && toAssignment === fromAssignment) {
    return;
  }

  if (destination && source.droppableId !== destination.droppableId) {
    const currentDate = DateTime.local();
    let toDate = DateTime.fromFormat(to, 'MM-dd-yyyy');
    let newToDate = toDate.set({
      hour: currentDate.get('hour'),
      minute: currentDate.get('minute'),
      second: currentDate.get('second'),
    });
    let params = {
      fromAssignment,
      toAssignment,
      sectionId,
      newIndex,
      from,
      to,
      newDate: newToDate.toString(),
      client,
      timezone: DateTime.local().zoneName,
    };
    dispatch(moveExerciseSet(params));
  } else {
    let params = {
      from,
      sectionId,
      newIndex,
      assignmentId: fromAssignment,
      client,
    };
    dispatch(arrangeExerciseSet(params));
  }
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    changeCalendarModeView: (mode, prevMode, queryData, calendarStartDate) =>
      changeCalendarModeView(dispatch, mode, prevMode, queryData, calendarStartDate),
    handleTodayClick: (clientId, calendarType) => handleTodayClick(dispatch, clientId, calendarType),
    handleCalendarTypeChange: (startDate, clientId, calendarType) =>
      handleCalendarTypeChange(dispatch, startDate, clientId, calendarType),
    toggleSideNavigation: visible => toggleSideNavigation(dispatch, visible),
    onDragEnd: (result, client) => onDragEnd(dispatch, result, client),
    toggleSideBar: visible => dispatch(toggleSideBar(visible)),
    changeCalendarStartDate: bindActionCreators(changeCalendarStartDate, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    resetCopyItem: bindActionCreators(resetCopyItem, dispatch),
    saveCalendarAsProgram: bindActionCreators(saveCalendarAsProgram, dispatch),
    fetchWorkoutsInRange: bindActionCreators(fetchWorkoutsInRange, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
    handleResetSelectedWeek: bindActionCreators(handleResetSelectedWeek, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    handleResetSelectWorkout: bindActionCreators(handleResetSelectWorkout, dispatch),
    handleCopyWorkout: bindActionCreators(handleCopyWorkout, dispatch),
    handleRemoveWorkouts: bindActionCreators(handleRemoveWorkouts, dispatch),
    handleHideWorkouts: bindActionCreators(handleHideWorkouts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCalendar);
