import React from 'react';
import classNames from 'classnames';
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';

import SortableRecipe from './SortableRecipe';

import * as S from './style';

const RearrangeView = ({ id, meals, cloudfrontList, isLoadingWeek }) => {
  const mealIds = meals.map(({ _id }) => _id);
  const hasMeals = meals.length > 0;
  const isRecipesEmpty = (meals || []).some(({ is_recipes_empty }) => !is_recipes_empty);

  const { setNodeRef, isOver: isOverDay } = useSortable({
    id: id,
  });

  return (
    <>
      {hasMeals && isRecipesEmpty && (
        <S.DayCategoryWrapper>
          <SortableContext items={mealIds}>
            {meals.map(meal => {
              const { _id: mealId, name, recipes = [], is_recipes_empty } = meal || {};
              const recipeIds = recipes.map(({ recipe }) => (recipe || {})._id);
              const hasRecipes = recipes.length > 0;

              const { setNodeRef, isOver } = useSortable({
                id: mealId,
              });

              if (!hasRecipes && is_recipes_empty) return null;

              return (
                <S.MealItem key={mealId} ref={setNodeRef} isOver={isOver}>
                  <div className="meal-header">
                    <span className="meal-name">{name}</span>
                  </div>
                  <div className="meal-container">
                    <SortableContext items={recipeIds} strategy={rectSortingStrategy}>
                      {recipes.map(recipeItem => {
                        const { recipe, is_logged } = recipeItem || {};
                        return (
                          <SortableRecipe
                            key={(recipe || {})._id}
                            id={(recipe || {})._id}
                            recipe={recipe}
                            cloudfrontList={cloudfrontList}
                            isLogged={is_logged}
                            isLoadingWeek={isLoadingWeek}
                          />
                        );
                      })}
                    </SortableContext>
                  </div>
                </S.MealItem>
              );
            })}
          </SortableContext>
        </S.DayCategoryWrapper>
      )}
      {!isRecipesEmpty && (
        <S.DayCategoryWrapperEmpty
          ref={setNodeRef}
          className={classNames('non-droppable', { 'is-over-day': isOverDay })}
          isOverDay={isOverDay}
        />
      )}
    </>
  );
};

export default RearrangeView;
