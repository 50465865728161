export const DEFAULT_PRICE_DATA = {
  currency: 'usd',
  amount: null,
  recurring_interval_type: 'month',
  end_after: 0,
  every: null,
};

export const END_PERIOD = {
  FOREVER: 'forever',
  HAS_LIMIT: 'has_limit',
};

export const PERIOD_OPTIONS = process.env.REACT_APP_PAYMENT_TEST_MODE_ENABLED
  ? Array.from(new Array(12)).map((_, index) => ({ value: index + 1, label: index + 1 }))
  : Array.from(new Array(11)).map((_, index) => ({ value: index + 2, label: index + 2 }));

export const TRIAL_PERIOD = [
  {
    label: '3 days',
    value: 3,
    key: 3,
  },
  {
    label: '7 days',
    value: 7,
    key: 7,
  },
  {
    label: '14 days',
    value: 14,
    key: 14,
  },
  {
    label: '30 days',
    value: 30,
    key: 30,
  },
];
