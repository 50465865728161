import Request from 'configs/request';
import { get } from 'lodash';

import { omitEmptyRequestParams } from 'utils/commonFunction';

export const Types = {
  PACKAGE_ACTIVITY_GET_LIST: 'PACKAGE_ACTIVITY_GET_LIST',
  PACKAGE_ACTIVITY_GET_LIST_REQUEST: 'PACKAGE_ACTIVITY_GET_LIST_REQUEST',
};

export const getActivities = (params, isMP) => {
  return (dispatch, getState) => {
    const {
      rootReducer: { activity },
    } = getState();

    const { lastId, isEnd } = activity.toJS();
    const isRefresh = params.isRefresh;
    const refactorParams = !get(params, 'types', []).includes('all')
      ? params
      : {
          ...params,
          types: [],
        };
    const platforms = isMP ? ['marketplace'] : ['everfit'];

    (!isEnd || isRefresh) &&
      dispatch({
        type: Types.PACKAGE_ACTIVITY_GET_LIST_REQUEST,
        payload: {
          isRefresh,
        },
      });

    (!isEnd || isRefresh) &&
      dispatch(
        Request.get(
          {
            url: '/api/payment/activities',
            params: omitEmptyRequestParams({ ...refactorParams, lastId: !isRefresh ? lastId : '', platforms }),
          },
          true,
          (response, { dispatch }) => {
            const { data } = response.data;
            dispatch({
              type: Types.PACKAGE_ACTIVITY_GET_LIST,
              payload: { list: data.list, lastId: data.lastId },
            });
          },
        ),
      );
  };
};
