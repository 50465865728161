import styled from 'styled-components';
import TagsInput from 'react-tagsinput';

import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  background: #f0f5f8;
  padding: 50px 30px;
  min-height: 456px;

  .referralForm__content {
    max-width: 1060px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .referralForm__leftContent {
    max-width: 500px;

    .referralForm__title {
      font-weight: bold;
      font-size: 36px;
      line-height: 120%;
      color: #202353;
      max-width: 360px;
      margin-bottom: 15px;
    }

    .referralForm__description {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #202353;
      max-width: 420px;
      margin-bottom: 35px;
    }

    .referralForm__inviteFriend {
      margin-bottom: 35px;

      &--des {
        margin-top: 15px;
        font-weight: 600;
        font-size: 11px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #202353;
        opacity: 0.4;
      }
    }

    .referralForm__actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;
    }
  }

  .referralForm__rightContent {
    display: flex;
    flex-direction: column-reverse;
    padding-right: 70px;

    img {
      width: 250px;
      z-index: 1;
    }
  }

  .referralForm__earnedCredit {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 430px;
  }

  @media screen and (max-width: 981px) {
    .referralForm__rightContent {
      padding-right: 0;
    }
  }
`;

export const CopyButton = styled(Button)`
  background-image: url(${CDN_URL}/images/copy_icon.svg);
  background-position: right 15px center;
  background-repeat: no-repeat;
  border: 1px solid #ffffff;
  padding: 20px;
  justify-content: left;
  font-size: 13px;
  font-weight: normal;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #202353;
  width: 100%;

  :hover {
    border: 1px solid #5158cf;
    background-image: none;
    font-weight: 600;
    color: #5158cf;
    justify-content: center;
  }
`;

export const InputCopy = styled.input`
  opacity: 0;
  position: absolute;
`;

export const InviteInputWrapper = styled.div`
  width: 500px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 7px 8px 7px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => (props.showError ? '#EA314A' : '#FFFFFF')};

  .inviteInput__sendBtn {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    padding: 13px 40px;
    margin-left: 10px;

    :hover {
      background-color: #5559ff;
    }
  }
`;

export const EmailInput = styled(TagsInput)`
  max-height: 90px;
  overflow: auto;

  .react-tagsinput-tag {
    background: #e7e7fd;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    border: none;
    color: #202353;
    font-family: Open Sans;

    &.invalid {
      background: #fff3f3;
      .react-tagsinput-remove {
        color: #be5b5b;
      }
    }

    .react-tagsinput-remove {
      color: #5d5bbe;
      font-size: 14px;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .react-tagsinput-input {
    width: 250px;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #202353;
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Open Sans;

    ::placeholder {
      font-weight: normal;
      font-size: 13px;
      line-height: 100%;
      color: #202353;
      opacity: 0.4;
    }
  }
`;

export const EarnedCreditWrapper = styled.div`
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  padding: 30px 30px 43px 30px;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;

  .earnedCredit__title {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    color: #202353;
    margin-bottom: 2px;
  }

  .earnedCredit__amount {
    font-weight: bold;
    font-size: 40px;
    line-height: 100%;
    color: #49ca8d;
  }
`;

export const CongratReferralToast = styled.div`
  margin-left: 120px;
  width: 340px;
  font-weight: bold;

  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-20%, -50%);
  }
`;

export const CreditWrapper = styled.div`
  width: 430px;
  height: 150px;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  position: absolute;
  padding: 16px 25px;
  text-align: right;
  top: -12px;
  right: 0;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #202353;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const Price = styled.p`
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  color: #49ca8d;
  margin-bottom: 17px;
`;

export const TotalTile = styled.p`
  color: #202353;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const TotalNumber = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  color: #202353;
  opacity: 0.6;
`;
