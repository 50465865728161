import styled from 'styled-components';

export const NoDataStyles = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-top: 130px;

  p {
    margin-top: 20px;
    color: #000000;
    opacity: 0.5;
  }
  img.ui {
    margin-bottom: 23px;
    height: 124px;
    display: inline-block !important;
  }
  button {
    margin-top: 50px;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    padding: 7px 50px;
  }
`;

export const MyfitnesspalWrapper = styled.div`
  padding: 9px 15px 0 5px;
  background: #f2f9ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

export const MyfitnesspalMessage = styled.p`
  color: #3d5889;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  padding-bottom: 8px;
  padding-left: 15px;
`;

export default styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fafbfc;
  & > div {
    max-width: 930px;
    padding: 0 10px;
    margin: 0 auto;
  }

  .macros__turn-off {
    margin-top: 25px;
    max-width: 361px;
  }

  .nutri-tabs {
    border-bottom: #ebedf2 solid 1px;
    display: flex;
    margin: 50px 0 65px;

    .nutri-tab {
      font-size: 13px;
      line-height: 100%;
      color: #9aa3ad;
      margin-right: 20px;
      padding: 9px 0;

      &.active {
        font-weight: 600;
        color: #202353;
        border-bottom: #5158cf solid 2px;
      }
    }
  }

  .macros-header {
    margin: 40px 0 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 24px;
    }
    .filter {
      display: flex;

      & > div {
        background-color: #fff;
        border: 1px solid #d6dae4;
        border-radius: 4px;
        margin-left: 15px;
      }

      .filter__dropdown {
        padding: 10px 15px;
      }
    }
  }
  .macros-content__bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin: 30px 0px;
  }
`;
