export const OVERLAY_COLORS = [
  { name: 'Purple (Default)', color: '#5158CE' },
  { name: 'Purple 2', color: '#6269E3' },
  { name: 'Purple 3', color: '#7E84F8' },
  { name: 'Purple 4', color: '#8C91E8' },
  { name: 'Purple 5', color: '#A7ABF0' },
  { name: 'Green 1', color: '#9EB66C' },
  { name: 'Greeen 2', color: '#778D48' },
  { name: 'Green 3', color: '#37AB1A' },
  { name: 'Green 4', color: '#278A0E' },
  { name: 'Yellow', color: '#FFE606' },
  { name: 'Orange 1', color: '#FFCC7E' },
  { name: 'Orange 2', color: '#FFAE34' },
  { name: 'Red 1', color: '#F31941' },
  { name: 'Red 2', color: '#D60027' },
  { name: 'Red 3', color: '#A20320' },
  { name: 'Black', color: '#2C0008' },
];

export const THEMES = [
  { primaryColor: '#7470ef', secondaryColor: '#4fdfac', _id: 'default', default: true },
  { primaryColor: '#485363', secondaryColor: '#4f95fe', _id: 'custom_1' },
  { primaryColor: '#4fc79c', secondaryColor: '#1f996d', _id: 'custom_2' },
  { primaryColor: '#4a4a48', secondaryColor: '#fcd81c', _id: 'custom_3' },
  { primaryColor: '#f98e2d', secondaryColor: '#373130', _id: 'custom_4' },
  { primaryColor: '#485263', secondaryColor: '#d84954', _id: 'custom_5' },
  { primaryColor: '#485263', secondaryColor: '#eb497a', _id: 'custom_6' },
];

export const APP_ICON_STATUS = {
  DEFAULT: 'default',
  PENDING: 'pending',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  CANCELLED: 'cancelled',
  REVIEWED: 'reviewed',
  FINAL_REVIEWED: 'final_reviewed',
  PUBLISED: 'published',
};

export const APP_ICON_DESCRIPTION_STATE = {
  newAppIcon: 'Your image must be at least 1024 x 1024 px',
  reviewedAppIcon: 'Your app icon is being prepared to be published',
  publishedAppIcon: 'App icon is final once you publish',
};

export const CUSTOM_ONBOARDING_VIDEO_TYPE = {
  EVERFIT: 'everfit',
  MY_OWN: 'my_own',
  YOUTUBE_VIMEO: 'youtube_vimeo',
  SKIP: 'skip',
};

export const LIMIT_SIZE_IMAGE_UPLOAD_MB = 10;

export const OLD_APP_ICON_FLOW = 1;

export const NEW_APP_ICON_FLOW = 2;
