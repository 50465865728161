import { CDN_URL } from 'constants/commonData';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 178px;

  .collectionArea__heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .collectionArea__content {
    background: #f0f8ff;
    border-radius: 5px;
    display: inline-flex;
    margin-top: 40px;
  }

  .collectionArea__image {
    position: relative;
    width: 240px;

    img {
      position: absolute;
      bottom: 0px;
      left: 15px;
      width: 230px;
    }
  }

  .collectionArea__info {
    padding: 30px;

    &--title {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #202353;
      margin-bottom: 5px;
      max-width: 206px;
    }

    &--description {
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      color: #202353;
      opacity: 0.8;
      margin-bottom: 40px;
      max-width: 280px;
    }
  }

  .collectionArea__actions {
    display: flex;
    flex: 1 1;
    align-items: center;

    &--button {
      background: #419aea;
      border-radius: 5px;
      width: 100%;
      color: #fff;
      border: none;
      margin-right: 15px;
    }

    &--goToCollection {
      display: flex;
      flex: 1 1;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #419aea;

      span {
        width: max-content;
        margin-right: 10px;
      }
    }
  }
`;

export const List = styled.div`
  display: grid;
  grid-auto-columns: 258px;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  overflow-x: auto;
  grid-gap: 20px;
  padding-bottom: 10px;
  margin-bottom: 50px;
  margin-top: 15px;

  ::-webkit-scrollbar {
    height: 5px !important;
  }
`;

export const AddCollectionButton = styled.div`
  max-width: 208px;
  background: #fafbfc;
  background-image: url(${CDN_URL}/images/plus_thin_grey.svg);
  border: 1px solid #dadfea;
  border-radius: 5px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #9fa0ac;
  background-repeat: no-repeat;
  background-position: center 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    margin-top: 25px;
  }

  :hover {
    background: #f8f8ff;
    background-image: url(${CDN_URL}/images/plus_thin_purple.svg);
    background-repeat: no-repeat;
    background-position: center 40%;
    border: 1px solid #5158cf;
    border-radius: 5px;
    color: #5158cf;
  }
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading--resource-collections {
    padding-top: unset;
  }
`;
