import React, { useState, useRef, useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import * as S from './style';

import ForumHeader from '../ForumHeader';
import StickyHeader from '../ForumHeader/StickyHeader';
import UpcomingFeed from '../ForumTimeline/components/UpcomingFeed';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow_up_black.svg';

export default function UpcomingTab(props) {
  const {
    push,
    totalScheduledPost,
    group,
    schedulePosts,
    tab,
    page,
    schedulePostsLoading,
    permission,
    enabledAddons,
  } = props;
  const scrollTopRef = useRef();
  const container = useRef();
  const lastScrollTop = useRef(0);
  const disableFirstPost = get(props, 'location.state.firstSchedulePost');
  const [isScrolling, setIsScrolling] = useState(false);
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResizeScreen);
  }, []);

  useEffect(() => {
    const {
      getGroupDetail,
      getListSchedulePost,
      getListUser,
      getConnectedLeaderboard,
      resetDisableLoadMoreSchedulePosts,
    } = props;
    if (!disableFirstPost) {
      getGroupDetail && getGroupDetail();
      getListUser && getListUser();
      getConnectedLeaderboard && getConnectedLeaderboard();
    }
    resetDisableLoadMoreSchedulePosts && resetDisableLoadMoreSchedulePosts();
    getListSchedulePost && getListSchedulePost();
  }, [get(props, 'match.params.id') || get(props, 'match.params.forumId')]);

  useEffect(() => {
    const groupID = get(group, '_id');
    const noScheduledPosts = totalScheduledPost === 0;
    if (noScheduledPosts && !isEmpty(groupID)) {
      push(`/home/forums/${groupID}/discussion`);
    }
  }, [totalScheduledPost, group]);

  const handleResizeScreen = () => {
    props.toggleSideBar(true);
  };

  const handleScroll = event => {
    event.persist();
    const { scrollTop, id } = event.target;
    handleScrollStartAndEnd();

    if (id.includes('mentions-list')) return;

    if (scrollTop > 396) setSticky(true);
    if (scrollTop < 396) setSticky(false);

    if (scrollTopRef.current) {
      if (scrollTop > 324 || (isSticky && scrollTop > 10)) {
        scrollTopRef.current.classList.add('show');
      } else {
        scrollTopRef.current.classList.remove('show');
      }
    }

    lastScrollTop.current = scrollTop;
    handleScrollEnd(event);
  };

  const handleScrollTop = () => {
    container.current.scrollTop = 0;
  };

  const handleScrollStartAndEnd = () => {
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false);
    }, 500);
  };

  const handleScrollFromTop = (offset, callback) => {
    if (container.current) {
      container.current.scrollTop = offset;
    }
    if (callback) callback();
  };

  const loadMoreScheduledPostDebounce = debounce(props.loadMoreScheduledPost, 250);
  const handleScrollDebounce = debounce(handleScroll, 250);

  const handleScrollEnd = event => {
    const scrollHeight = get(event, 'target.scrollHeight');
    const scrollTop = get(event, 'target.scrollTop');
    const clientHeight = get(event, 'target.clientHeight');
    const currentHeight = Math.floor(scrollHeight - scrollTop);
    const isAtBottom = currentHeight <= clientHeight;
    const isDisabledPermission = !permission.autoflow && !enabledAddons.automation;

    const shouldLoadMore =
      !isDisabledPermission &&
      isAtBottom &&
      schedulePosts.length &&
      !schedulePostsLoading &&
      ['all', 'my_post'].includes(tab);

    if (shouldLoadMore) {
      const nextPage = page + 1;
      loadMoreScheduledPostDebounce({ page: nextPage, tab });
    }
  };

  return (
    <S.Wrapper
      onScroll={event => {
        event.persist();
        handleScrollDebounce.call(null, event);
      }}
      ref={container}
      id="timeLine"
    >
      <ForumHeader {...props} />
      {isSticky && <StickyHeader {...props} isSticky={isSticky} />}
      <UpcomingFeed {...props} onScrollFromTop={handleScrollFromTop} isScrolling={isScrolling} />
      <S.ScrollTop ref={scrollTopRef} onClick={handleScrollTop}>
        <ArrowUpIcon />
      </S.ScrollTop>
    </S.Wrapper>
  );
}
