import React from 'react';
import { Modal } from 'semantic-ui-react';
import * as S from 'shared/Styles/ModalLayout';
import { Button } from 'shared/FormControl';
import { CDN_URL } from 'constants/commonData';

import './style.scss';

export default props => (
  <Modal open={true} closeOnDimmerClick={false} className="modal--confirm-delete-metric">
    <Modal.Content>
      <S.Wrapper>
        <S.Header className="modal--header">
          <S.HeaderIcon icon={`${CDN_URL}/images/new_delete_red.svg`} />
          <span>Delete {props.data.name}</span>
        </S.Header>
        <S.Content>
          This metric may be used by other trainers or in Autoflows. Once this metric is deleted, it will be removed
          everywhere in the workspace. Would you like to proceed?
        </S.Content>
        <S.Actions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            delete
            onClick={() => {
              props.onClose();
              props.onConfirm();
            }}
          >
            Remove
          </Button>
        </S.Actions>
      </S.Wrapper>
    </Modal.Content>
  </Modal>
);
