import styled, { css, keyframes } from 'styled-components';

const progress = keyframes`
 0%{
   width: 0%;
 }
 25%{
     width: 25%;
 }
 50%{
     width: 50%;
 }
 75%{
     width: 75%;
 }
 100%{
     width: 100%;
 }
`;

const scale = keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.05, 1.05);
  }
  50% {
    transform: scale(1.05, 1.05);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const MediaBox = styled.div`
  position: relative;
  max-width: 310px;
  max-height: 310px;
  overflow: hidden;
  border-radius: 5px;

  img,
  video {
    max-width: 310px;
    max-height: 310px;
    object-fit: cover;
  }

  .image-with-fallback {
    max-width: 310px;
    max-height: 310px;
  }

  .media-player__trigger {
    ${props =>
      props.showRecord &&
      css`
        cursor: not-allowed;
      `}
  }
`;

export const Name = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: #3b4859;
  font-weight: normal;
  margin-top: 8px;
  margin-bottom: -3px;
`;

export const Time = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: #3b4859;
  font-weight: normal;
  margin-top: 5px;
`;

export const Message = styled.div`
  background: #f3f3f4;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  border-radius: 16px;
  border-top-left-radius: 0;
  padding: 8px 20px 9px 15px;
  white-space: pre-line;
  word-break: break-word;
  position: relative;
`;

export const AudioMessageWrapper = styled.div`
  position: relative;
`;

export const AudioMessage = styled.div`
  width: 160px;
  height: 35px;
  background: #f3f3f4;
  color: #000;
  border-radius: 16px;
  padding: 4px 16px 4px 4px;
  position: relative;
  overflow: hidden;

  ${props =>
    props.showRecord &&
    css`
      cursor: not-allowed;
    `}

  ::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    top: 0;
    transition: width 1s linear;
  }

  ${props =>
    props.playing &&
    props.progress &&
    css`
      background: #f3f3f4;
      ::after {
        width: 100%;
        animation: ${progress} ${props.duration}s normal linear;
        animation-play-state: running;
        animation-fill-mode: forwards;
        background: #d2d2d2;
      }
    `}

  ${props =>
    props.pause &&
    props.progress &&
    css`
      background: #f3f3f4;
      ::after {
        width: 100%;
        animation: ${progress} ${props.duration}s normal linear;
        animation-play-state: paused;
        animation-fill-mode: forwards;
        background: #d2d2d2;
      }
    `}
`;

export const AudioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const AudioControl = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  ${props =>
    props.showRecord &&
    css`
      cursor: not-allowed;
    `}

  svg.loader {
    animation: spin 2s linear infinite;
    path {
      fill: #fff !important;
    }
  }
`;

export const AudioDuration = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  user-select: none;
  z-index: 1;
`;

export const ReplyMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 8px;
  position: relative;

  .sb-avatar {
    position: absolute;
    left: -45px;
    bottom: 0;
    font-style: normal;
  }

  .highlight {
    animation: ${scale} 0.6s ease-in-out 0.2s forwards;
    transform-origin: center;
    z-index: 999;
  }

  ${props =>
    !props.isOwner &&
    css`
      flex-direction: row-reverse;
    `}

  ${props =>
    props.isReplied &&
    css`
      margin-top: unset;
    `}
`;

export const ReplyIcon = styled.div`
  width: 22px;
  height: 22px;
  padding: 0 3px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: #e5e5e5;

    svg path {
      fill: #65676b;
    }
  }

  ${props =>
    props.showRecord &&
    css`
      display: none;
    `}
`;

export const MoreOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
`;

export const MessageContainer = styled.div`
  padding: 0 60px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;

  :hover {
    ${MoreOptionWrapper} {
      visibility: visible;
    }
  }

  ${props =>
    props.bottomSpace &&
    css`
      margin-bottom: 30px;
    `}

  ${props =>
    props.isOwner &&
    css`
      align-items: flex-end;
      text-align: right;

      a,
      a:hover {
        color: #fff;
      }

      ${Message} {
        padding: 8px 15px 9px 20px;
        background: rgba(86, 82, 199, 1);
        color: #fff;
        border-top-left-radius: 16px;
        border-top-right-radius: 0;
      }

      ${AudioMessageWrapper} {
        ${AudioMessage} {
          background: #5f5bca;
          color: #fff;

          ${props =>
            props.playing &&
            props.progress &&
            css`
              background: #7077f2;
              ::after {
                width: 100%;
                background: #5f5bca;
                animation: ${progress} ${props.duration}s normal linear;
                animation-play-state: running;
                animation-fill-mode: forwards;
              }
            `}

          ${props =>
            props.pause &&
            props.progress &&
            css`
              background: #7077f2;
              ::after {
                width: 100%;
                background: #5f5bca;
                animation: ${progress} ${props.duration}s normal linear;
                animation-play-state: paused;
                animation-fill-mode: forwards;
              }
            `}
        }

        ${AudioControl} {
          svg {
            path {
              fill: #5f5bca;
            }
          }
        }

        ${AudioDuration} {
          color: #fff;
        }
      }

      ${ReplyMessageWrapper} {
        .sb-avatar {
          left: unset;
          right: -45px;
        }
      }
    `}

  ${props =>
    props.isDeleted &&
    css`
      ${Message} {
        color: #818da1;
        font-style: italic;
        font-weight: 400;
        background-color: #e2e5ec !important;
      }

      :hover {
        ${MoreOptionWrapper} {
          visibility: hidden;
        }
      }
    `}
  
  ${props =>
    props.isMedia &&
    !props.isAudio &&
    !props.isDeleted &&
    css`
      ${Message} {
        padding: 0;
        border-radius: 5px;
        background-color: transparent;
      }
    `}

  ${props =>
    props.isMultipleMedia &&
    !props.isAudio &&
    !props.isDeleted &&
    css`
    ${Message} {
      padding: 0;
      border-radius: 5px;
      background-color: transparent;
    }

    ${MediaBox} {
      max-height: unset;
      border-radius: unset;
  `}
`;

export const GroupDate = styled.div`
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66717e;
  margin: 15px 0;
  opacity: 0.8;
`;

export const List = styled.div`
  padding-bottom: 30px;
  .media-player__trigger {
    width: 100% !important;
    height: 100% !important;
  }

  ${props =>
    props.showRecord &&
    css`
      cursor: not-allowed;
    `}
`;

export const ClientName = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #273d52;
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .sb-avatar {
    margin-right: 10px;
  }
`;

export const SelectClientContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;

  .title {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    margin-right: 10px;
    margin-top: 9px;
    align-self: start;
  }

  .isp__input {
    font-size: 12px;

    input {
      line-height: 18px;
    }
  }

  .isp__menu {
    top: 87%;
  }

  .multi-select__option {
    padding: 8px 15px;

    :hover {
      background: #f4f4ff;
      border-radius: 5;
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 288px;
      white-space: nowrap;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .email,
    .totalMember {
      display: none;
    }
  }

  .inbox-search-contact {
    display: flex;
    flex: 1;

    .isc__control {
      border: none;
      box-shadow: none;
      width: 100%;
    }

    .isc__option {
      &--is-focused {
        background-color: #f6f7ff;
        cursor: pointer;
      }
    }

    .isc__value-container {
      padding: 0;

      .isc__placeholder {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  .search-participant-list {
    .isp__menu-list.isp__menu-list--is-multi,
    .isp__menu-list {
      max-height: 165px !important;
    }
  }
  .search-participant-empty {
    height: 135px !important;
  }
`;

export const CreateButton = styled.button`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #726ee4;
  margin: 12px 8px 0 39px;
  align-self: flex-start;
  background: transparent;
  border: none;
  padding: 0;

  :hover {
    cursor: pointer;
  }
`;

export const TurnOnFeatureMessage = styled.div`
  max-width: 300px;
  text-align: center;

  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin: 15px 0 20px;
  }
`;

export const FeatureOffContainer = styled.div`
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 12px;
  border-bottom: 1px solid #e8e8e8;
  min-height: 51px;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

export const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
`;

export const Footer = styled.div`
  height: max-content;
  width: 100%;
  min-height: 50px;
  display: block;

  .evf-chat-input {
    border: none;
  }

  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const DropZonePlaceholderContainer = styled.div`
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  bottom: 0;
  border: 10px solid #fff;
  display: none;

  ${props =>
    props.show &&
    css`
      display: block;
    `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  outline: none !important;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1 1;

  .drop-zone__input-container {
    background-color: rgba(255, 255, 255, 0.95);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const ConversationEnded = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #7b7e91;
  text-align: center;
  padding-top: 15px;
`;

export const ContentWrapper = styled.div`
  text-align: left;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  > div {
    padding-top: 40px;
  }
`;

export const MuteConversationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #f8f8f8;
  width: 100%;
  height: 45px;
`;

export const MutedText = styled.span`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000;
`;

export const UnmuteButton = styled.span`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #726ee4;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const FloatingButtonWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const FloatingButton = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  bottom: 62px;
  background: #fff;
  box-shadow: 0px 8px 24px rgba(158, 158, 158, 0.16);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
`;
