import styled from 'styled-components';

export const CardWrapper = styled.div`
  margin-top: -13px;
  .asset {
    display: flex;
    background: #ffffff;
    border: 1px solid #dadfea;
    box-shadow: 0px 4px 8px #f1f2f6;
    border-radius: 5px;
    padding: 14px;
    padding-right: 32px;
    position: relative;
    overflow: hidden;
    ${props => props.isEdit && `cursor: pointer;`}
    transition: all 100ms;
    :hover {
      ${props => props.isEdit && `border: 1px solid #5158cf;`}
      .asset__delete {
        display: flex;
      }
    }
    &--disabled {
      background: #F5F7F9;
      box-shadow: none;
      .asset__info__title {
        color: rgba(32, 35, 83, 0.5);
      }
      .asset__icon {
        background-color: rgba(117, 131, 146, 0.1) !important;
        &.autoflow {
          path {
            stroke: #A4AAB9 !important;
          }
        }
        &.program {
          rect {
            stroke: #A4AAB9 !important;
          }
          path {
            stroke: #A4AAB9 !important;
          }
        }
      }
    }
    &__icon {
      flex: 0 0 44px;
      height: 44px;
      background: #FAFBFC;
      width: 44px;
      margin-right: 14px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        & {
          background-color: #FAFBFC;
        }
      }
      &.autoflow {
        background: rgba(26, 95, 246, 0.1);
        path {
          stroke: #1a5ff6;
        }
      }
      &.resource_collection {
        background: rgba(88, 197, 115, 0.1);
      }
      &.program {
        background: rgba(238, 89, 42, 0.1);
      }
      &.on_demand_workout_collection {
        background: rgba(94, 89, 255, 0.08);
      }
    }
    &__info {
      .asset__info__title-container {
        display: flex;
        align-items: center;
        .delete-text {
          margin-left: 8px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          text-transform: uppercase;
          color: #818DA1;
        }
      }
      &__title {
        word-break: break-all;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #202353;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      &__description{
        display: flex;
        margin-top: 5px;
      }
      .badge {
        height: fit-content
        padding: 2px 5px;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: #818da1;
        background: #f1f1f1;
        border-radius: 3px;
        margin-right: 5px;
        &:last-child {
          margin-right: 0px;
        }
      }
      .info-text {
        align-self: center;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: #818da1;
      }
      .autoflow-setting {
        width: 18px;
        height: 18px;
      }
    }
    &__delete {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      background: #fafbfc;
      box-shadow: inset 1px 0px 0px #dadfea, inset 0px -1px 0px #dadfea;
      border-bottom-left-radius: 5px;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 100ms;
      :hover {
        background: #f2f3ff;
        box-shadow: inset 1px 0px 0px #5158cf, inset 0px -1px 0px #5158cf;
        path {
          fill: #5158cf;
        }
      }
    }
    .delete-tooltip {
      border-radius: 5px;
      width: 116px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      padding: 8px 15px;
    }
  }
`;
