import React from 'react';

import Item from './../Item';
import { ACTIVITY_TYPES } from 'constants/commonData';

import * as S from './style';

const Group = ({ user, group, account, isMP }) => {
  if (!group) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.GroupTitle>{group.title}</S.GroupTitle>
      {group.items.map((item, index) => {
        if (item.action === ACTIVITY_TYPES.REFUND_INVOICE && !process.env.REACT_APP_ENABLE_PAYMENT_REFUND) {
          return null;
        }
        return <Item user={user} account={account} item={item} key={`activity-${index}`} isMP={isMP} />;
      })}
    </S.Wrapper>
  );
};

export default Group;
