import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Header = styled.div`
  height: 300px;
  background: url(${CDN_URL}/images/download_coach_app_bg.jpg) no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  border: none;
  border-left: 1px solid #d4d7d9;
  background-color: transparent;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 8px 10px;
  outline: none;
  flex: 1 1;

  ::placeholder {
    color: #a1a1a5;
  }
`;

export const CountryCode = styled.div`
  display: inline-block;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding: 8px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #fafbfc;
`;

export const InputContainer = styled.div`
  background: #ffffff;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 5px;
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Button = styled.button`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 9px 25px;
  border: none;
  outline: none;
  background: #7470ef;
  border-radius: 5px;
  cursor: pointer;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Error = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #ea314a;
  padding: 7px 0 9px;
  opacity: 0;
`;

export const Form = styled.form`
  padding: 15px 30px 0;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #000000;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  h3 {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #333333;
    opacity: 0.8;
    margin: 0;
    margin-bottom: 20px;
    font-weight: normal;
  }

  ${props =>
    props.error &&
    css`
      ${Error} {
        opacity: 1;
      }

      ${InputContainer} {
        border-color: #ea314a;
        background-color: rgba(234, 49, 74, 0.05);
        color: #ea314a;

        ${CountryCode},
        ${Input} {
          border-color: inherit;
          color: inherit;
          background-color: inherit;
        }
      }
    `}
`;

export const Footer = styled.div`
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;

  a {
    display: flex;
  }
`;

export const Wrapper = styled.div`
  border-radius: 5px;
`;
