import moment from 'moment';
import TimeOptions from 'constants/time-options';

export const initialValues = (workingItem, template, rankingTypes) => {
  const currentTime = moment().format('HH:mm');

  if (workingItem) {
    const typeObj = rankingTypes.find(item => item.value === workingItem.ranking_type) || rankingTypes[0];

    return {
      title: workingItem.name,
      description: workingItem.description || '',
      type: typeObj,
      orderBy: typeObj.orderBy.find(item => item.value === workingItem.order_by) || typeObj.orderBy[0],
      orderList: typeObj.orderBy,
      duration: {
        startTime: TimeOptions.find(item => item.value >= moment(workingItem.start_date).format('HH:mm')),
        startDate: moment(workingItem.start_date),
        endTime: TimeOptions.find(item => item.value >= moment(workingItem.end_date).format('HH:mm')),
        endDate: moment(workingItem.end_date),
      },
    };
  }

  if (template) {
    const typeObj = rankingTypes.find(item => item.value === template.ranking_type) || rankingTypes[0];

    return {
      title: template.title,
      description: typeObj.placeholder || '',
      type: typeObj,
      orderBy: typeObj.orderBy.find(item => item.value === template.order_by) || typeObj.orderBy[0],
      orderList: typeObj.orderBy,
      duration: {
        startTime: TimeOptions.find(item => item.value > currentTime),
        startDate: moment(),
        endTime: '',
        endDate: '',
      },
    };
  }

  return {
    title: '',
    description: '',
    type: rankingTypes[0],
    orderBy: rankingTypes[0].orderBy[0],
    orderList: rankingTypes[0].orderBy,
    duration: {
      startTime: TimeOptions.find(item => item.value > currentTime),
      startDate: moment(),
      endTime: '',
      endDate: '',
    },
  };
};
