import styled from 'styled-components';

export const SelectOptionLabel = styled.div`
  flex: 1 1;

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #111111;
    margin-bottom: 5px;
  }

  .email {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #8d8c92;
  }
`;

export const SelectOptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 14px;
  border-bottom: 1px solid #DCDCDE;
  cursor: pointer;
  
  .sb-avatar {
    flex: 0 0 26px;
  }

  &.select__option--is-focused {
    background-color: #F6F5FF;
  }

  :last-child {
    border-bottom: none;
  }

  .sb-avatar {
    margin-right: 12px;
  }

  .sb-avatar__image,
  .sb-avatar__text {
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const SingleValue = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #323C47;

  .sb-avatar {
    flex: 0 0 26px;
    margin-right: 10px;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
  }
`;