import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const SaveButton = styled.button`
  outline: none !important;
  box-shadow: none;
  border: 1px solid #5c5bbd;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #5c5bbd;
  padding: 9px 25px 8px;
  background-color: #ffffff;

  :hover {
    color: #fff;
    background-color: #5c5bbd;
    cursor: pointer;
  }
`;

export const Left = styled.div`
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #222222;
  padding-right: 10px;

  .setting__name {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    img.ui {
      margin-right: 10px;
      margin-top: 2px;
    }
  }
`;

export const Right = styled.div`
  text-align: right;
`;

export const Center = styled.div``;

export const SettingName = styled.div`
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
  padding-right: 20px;
  padding-left: 28px;
  background: transparent url(${CDN_URL}/images/features.svg) no-repeat;
  background-position: left center;
  background-size: 18px auto;
`;
