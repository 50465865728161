import ConfirmModal from 'shared/ConfirmModal';
import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #7B7E91;
`;

export const NutritionInfoWrapper = styled.div`
  padding: 20px 30px 0;
`;

export const TabHeader = styled.div`
  display: flex;
  border: 1px solid #dadbea;
  border-radius: 5px;
  height: 32px;
  overflow: hidden;
  align-items: center;
`;

export const TabHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  ${baseText}
  font-size: 12px;
  line-height: 18px;
  flex: 1;
  text-align: center;
  border-left: 1px solid #dadbea;
  cursor: pointer;
  span {
    padding-left: 7px;
    font-size: 11px;
    line-height: 17px;
    color: #7b7e91;
  }
  &:first-child {
    border-left: none;
  }
  ${props =>
    props.active &&
    css`
      background-color: #f0f0f2;
      color: #202353;
    `}
`;

export const TabContent = styled.div`
  padding: 19px 0 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 14px 13px;
  ${({ isMacro }) =>
    !isMacro &&
    css`
      padding-bottom: 0;
    `}
`;

export const Item = styled.div`
  width: calc(25% - 10px);
`;

export const OtherNutrientWrapper = styled.div`
  .nutrient-list {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 13px;
  }
  .toggle-other-nutrient {
    ${baseText}
    font-size: 12px;
    line-height: 18px;
    color: #5158cf;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
    svg {
      margin-bottom: 1px;
      margin-left: 9px;
      path {
        fill: #5158cf;
      }
    }
    &.is-hide {
      margin-bottom: 0;
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const AutoCalculated = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
  ${({ isAutoCalculated }) =>
    isAutoCalculated &&
    css`
      margin-bottom: 0;
    `}
  span {
    ${baseText}
    font-weight: 400;
    line-height: 20px;
    color: #202353;
    margin-right: 10px;
  }
  input:checked ~ label::before,
  input:checked:focus ~ label::before {
    background-color: #6e75e7;
  }
  input ~ label::before {
    background-color: #a3a3b5;
  }
`;

export const InfoModal = styled(ConfirmModal)`
  * &.ui.modal {
    &.confirm-popup-container {
      width: 467px !important;
      border-radius: 8px !important;
      .confirm-content {
        .confirm-content-header {
          padding: 30px 30px 0 30px;
          .confirm-header-image {
            width: 25px;
            height: 25px;
          }
          .confirm-header-label {
            ${baseText}
            font-size: 21px;
            line-height: 32px;
            color: #202353;
          }
        }
        .confirm-content-body {
          padding: 25px 30px;
          .confirm-content-label {
            ${baseText}
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #202353;
          }
        }
      }
      .confirm-actions {
        .confirm-yes-button {
          background-color: #ffbe49;
          box-shadow: none;
          margin-left: 8px !important;
          width: 97px;
        }
        .confirm-no-button {
          min-width: 89px;
          padding: 5px 0px;
        }
      }
    }
  }
`;
