import React, { useState, useRef, useCallback } from 'react';
import Select from 'react-select';

import DefaultDropdownIndicator from './DefaultDropdownIndicator';
import DefaultOption from './DefaultOption';
import CustomOptionModality from './CustomOptionModality';

import * as S from './styles';

function CustomSelect(props) {
  const {
    onChange,
    name,
    options,
    defaultValue,
    placeholder,
    disabled,
    classNamePrefix,
    className,
    isModality = false,
  } = props;

  const selectRef = useRef();
  const [menuPlacement, setMenuPlacement] = useState(props.menuPlacement || 'auto');

  const defaultValueOption = options.filter(it => it.key === defaultValue);

  const checkPlacement = useCallback(() => {
    if (selectRef.current && typeof selectRef.current.getBoundingClientRect === 'function') {
      const rect = selectRef.current.getBoundingClientRect();
      if (window.innerHeight - rect.bottom <= 300) {
        setMenuPlacement('top');
      } else {
        const propsMenuPlace = props.menuPlacement || 'auto';
        menuPlacement !== propsMenuPlace && setMenuPlacement(propsMenuPlace);
      }
    }
  }, [menuPlacement, selectRef]);

  return (
    <div ref={selectRef}>
      <Select
        blurInputOnSelect
        isSearchable={false}
        isMulti={false}
        name={name}
        classNamePrefix={classNamePrefix}
        className={className}
        placeholder={placeholder}
        options={options}
        isDisabled={disabled}
        defaultValue={defaultValueOption}
        getOptionValue={option => option.key}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body}
        onChange={onChange}
        onMenuOpen={checkPlacement}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: DefaultDropdownIndicator,
          Option: isModality ? CustomOptionModality : DefaultOption,
        }}
        styles={S.customSelectOwnerStyles}
      />
    </div>
  );
}

export default CustomSelect;
