import React, { memo } from 'react';

import * as S from '../style';
import { useMyContext } from '../context/NotificationContext';
import MarkAllReadButton from './MarkAllReadButton';

const HeaderFilter = () => {
  const { onlyUnread, toggleOnlyUnread } = useMyContext();

  return (
    <S.HeaderFilterWrapper>
      <S.HeaderToggleWrapper>
        <S.ToggleLabel>Only show unread</S.ToggleLabel>
        <S.ToggleButton width={36} height={20} checked={onlyUnread} onChange={toggleOnlyUnread} />
      </S.HeaderToggleWrapper>
      <S.GroupButtonRight>
        <MarkAllReadButton />
      </S.GroupButtonRight>
    </S.HeaderFilterWrapper>
  );
};

export default memo(HeaderFilter);
