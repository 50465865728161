import styled, { css } from 'styled-components';

export const UploadInfo = styled.div`
  flex: 1 1;
  min-height: 35px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  .download-file-container {
    display: flex;
    align-items: center;
  }

  .download-file-btn {
    margin-left: 8px;
    cursor: pointer;
    color: #5e59ff;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 0.02em;
    line-height: 100%;
    text-transform: uppercase;
  }

  .upload__file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;

    .file__name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1 1;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 150%;
      color: #111111;
    }

    .file__size {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 150%;
      display: flex;
      align-items: center;
      text-align: right;
      color: #979797;

      .try-again {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #5c5bbd;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          margin-left: 6px;
        }
      }

      .file__cancel {
        margin-left: 10px;
        margin-right: 0;
        cursor: pointer;
      }
    }
  }

  .upload__progress {
    margin-bottom: 6px;
    position: relative;
    width: 120px;
    height: 5px;
    background-color: #e3e6ea;
    border-radius: 999px;

    .upload__progress_bar {
      position: absolute;
      left: 0;
      top: 0;
      transition: width 0.25s ease;
      width: 0;
      height: 100%;
      border-radius: 999px;
      background: linear-gradient(270deg, #5158cf 9.77%, #6e75f2 100%);
    }
  }

  .failed_message {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 100%;
    color: #ff2e21;
    display: none;
  }

  .file__size {
    font-weight: 600;
    font-size: 11px;
    line-height: 100%;
    color: #979797;
  }

  ${props =>
    props.noSize &&
    css`
      .upload__file {
        margin-bottom: 0;
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 44px;

  img.icon {
    margin-right: 15px;
    width: 46px;
    height: 44px;
  }

  &.failed {
    .failed_message {
      display: block;
    }

    .upload__progress {
      display: none;
    }
  }
  .duration-text {
    display: flex;
    align-items: center;
    p {
      margin-left: 3px;
    }
  }
`;
