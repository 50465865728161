import React from 'react';
import classNames from 'classnames';
import { Button, TextArea, Image } from 'semantic-ui-react';

import GeneralButton from 'shared/GeneralButton';
import { CDN_URL } from 'constants/commonData';

import Urlify from 'shared/Urlify';

import GoalCountdownContentOverview from './Countdown';
import './styles.scss';

export const NoteTypes = {
  goal_countdown: 'goal_countdown',
  goal: 'goal',
  injury: 'injury',
};

export default class Note extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      data: '',
    };

    this.isSaving = false;
  }

  componentDidMount() {
    if (this.props.selectedClientId) {
      this.getClientInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedClient._id !== prevProps.selectedClient._id) {
      this.getClientInfo();
    }
  }

  getClientInfo() {
    const { getClientInfo, selectedClientId, type, getClientCountdownList, getClientGoalList } = this.props;

    if (type === NoteTypes.goal_countdown) {
      const countdownParams = {
        client: selectedClientId,
        page: 1,
      };
      getClientCountdownList(countdownParams);
      getClientGoalList(selectedClientId);
      return;
    }

    getClientInfo({ client: selectedClientId, type }).then(result => {
      if (result && result.data) {
        this.setState({ data: result.data.data });
      }
    });
  }

  handleSaveClientNote() {
    const { newData, data } = this.state;
    const { addClientInfo, selectedClientId, type } = this.props;

    if (!newData && !data) {
      this.setState({ isEdit: false, newData: null });
    } else {
      if (this.isSaving) {
        return;
      }

      this.isSaving = true;
      addClientInfo({
        client: selectedClientId,
        content: newData,
        type,
      })
        .then(result => {
          if (result && result.data) {
            this.setState({ data: newData, newData: null, isEdit: false });
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }

  renderEdit = () => {
    const { data, newData } = this.state;
    return (
      <React.Fragment>
        <TextArea
          autoFocus
          className="edit-note-input"
          value={newData == null ? data : newData}
          onChange={(evt, newData) => this.setState({ newData: newData.value })}
        />
        <div className="note-actions">
          <Button className="cancel-button" onClick={() => this.setState({ isEdit: false, newData: null })}>
            Cancel
          </Button>
          <GeneralButton
            onClick={() => {
              this.handleSaveClientNote();
            }}
          >
            Save
          </GeneralButton>
        </div>
      </React.Fragment>
    );
  };

  getTypeEmpty = () => {
    const {
      selectedClient: { first_name },
      type,
    } = this.props;
    let typeEmpty = '';

    switch (type) {
      case NoteTypes.goal:
        typeEmpty = `Set goals for ${first_name}...`;
        break;
      case NoteTypes.injury:
        typeEmpty = `Add any medical note or injury about ${first_name}...`;
        break;
      default:
        typeEmpty = `Add a note about ${first_name}...`;
        break;
    }

    return typeEmpty;
  };

  renderContent = () => {
    const { type } = this.props;

    if (type === NoteTypes.goal_countdown) {
      return <GoalCountdownContentOverview />;
    }

    return this.renderOtherContent();
  };

  renderOtherContent = () => {
    const { isEdit, data } = this.state;
    const typeEmpty = this.getTypeEmpty();

    return (
      <p className="note-content">
        {data ? (
          <Urlify>{data}</Urlify>
        ) : (
          <div
            className="no-data"
            disabled={true}
            onClick={() => {
              if (!isEdit) {
                this.setState({ isEdit: true, newData: data });
              }
            }}
            value={typeEmpty}
          >
            {typeEmpty}
          </div>
        )}
      </p>
    );
  };

  handleClickEdit = () => {
    const isGoalCountdown = this.props.type === 'goal_countdown';

    if (isGoalCountdown) {
      return;
    }

    if (!this.state.isEdit) {
      this.setState({ isEdit: true, newData: this.state.data });
    }
  };

  render() {
    const { type, title, className = '' } = this.props;
    const { isEdit } = this.state;
    const isGoalCountdown = type === 'goal_countdown';

    return (
      <div className={classNames('eve-panel note-panel', className, { 'goal-countdown': isGoalCountdown })}>
        <div className="panel-header">
          <Image src={`${CDN_URL}/images/dashboard_${isGoalCountdown ? 'goal' : type}.svg`} />
          <div className="panel-title">{title}</div>
          <span className="edit-icon" onClick={this.handleClickEdit} />
        </div>
        <div className="panel-body">{isEdit ? this.renderEdit() : this.renderContent()}</div>
      </div>
    );
  }
}
