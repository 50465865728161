import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: rgba(32, 35, 83, 1);
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 21px 10px 0;
`;

export const SearchWrapper = styled.div`
  width: 265px;
  height: 30px;
  .ingredients-search {
    width: 100%;
    height: 100%;
    input {
      background: url(${CDN_URL}/images/search_bold.svg) no-repeat;
      background-size: auto;
      background-position: 16px center;
      background-color: #f8f8f8;
      border: 1px solid #f0f0f0;
      padding-left: 45px;
      :hover,
      :focus {
        border: 1px solid #5158cf;
      }
      ::placeholder {
        font-size: 13px;
      }
    }
  }
`;

export const AddNewWrapper = styled.div`
  width: 189px;
  max-width: 189px;
  height: 30px;
  display: flex;
  cursor: pointer;
`;

export const AddNewButton = styled.div`
  border: 1px solid rgb(225, 225, 234);
  border-right: 0;
  border-radius: 5px 0 0 5px;
  padding: 11px 0 10px 13px;
  gap: 8px;
  white-space: nowrap;
  color: rgba(32, 35, 83, 1);
  display: flex;
  align-items: center;
  ${textBase}
  font-size: 12px;
  width: 159px;
  :hover {
    background-color: rgb(240, 241, 255);
    color: #5158cf;
    .plus-icon {
      path {
        stroke: #5158cf;
      }
    }
  }
  .plus-icon {
    width: 9.33px;
    height: 9.33px;
    path {
      stroke: rgba(32, 35, 83, 1);
    }
  }
`;

const activeDropdown = css`
  background-color: rgb(240, 241, 255);
  .arrow-icon {
    path {
      fill: #5158cf !important;
    }
  }
`;

export const AddNewDropdown = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border: 1px solid rgb(225, 225, 234);
  border-radius: 0 5px 5px 0;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ active }) => active && activeDropdown}
  :hover {
    ${activeDropdown}
  }
  .arrow-icon {
    min-width: 16px;
    path {
      fill: rgb(135, 137, 168);
    }
  }
`;

export const AddNewPopup = styled.div`
  width: 215px;
  height: 90px;
  box-shadow: 0px 2px 8px 0px rgba(38, 38, 38, 0.2);
  border-radius: 5px;
  padding: 5px 0px;
  position: absolute;
  top: 38px;
  right: 0;
  z-index: 2;
  background-color: #fff;
`;

export const Option = styled.div`
  padding: 10px 15px;
  gap: 10px;
  display: flex;
  white-space: nowrap;
  display: flex;
  align-items: center;
  ${textBase}
  font-size: 13px;
  line-height: 20px;
  .add-single-icon,
  .add-multiple-icon {
    path {
      stroke: rgb(135, 137, 168);
    }
  }
  .add-single-icon {
    margin-right: 4px;
    margin-left: 3px;
  }
  :hover {
    background: rgba(240, 241, 255, 1);
    color: #5158cf;
    .add-single-icon,
    .add-multiple-icon {
      path {
        stroke: #5158cf;
      }
    }
  }
`;

export const LeftToolbar = styled.div`
  display: flex;
  gap: 5px;
`;

export const RightToolbar = styled.div``;

export const RemoveButton = styled.button`
  display: flex;
  height: 30px;
  gap: 5px;
  padding: 6px 14px;
  ${textBase}
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0em;
  border: 1px solid #e1e1ea;
  border-radius: 5px;
  background-color: #fff;
  svg {
    width: 16px;
    height: 17px;
    path {
      fill: #202353;
      stroke-width: 0.01;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #f0f1ff;
    color: #5158cf;
    svg path {
      fill: #5158cf;
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }
  &:focus,
  &:focus-within {
    outline: none;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  .custom-filter-popup {
    position: relative;
    margin-right: 0;
    .popup-content {
      top: 38px;
      left: 0;
      right: none;
    }
    .filter-button {
      margin-left: 0;
      min-width: 81px;
      :hover {
        border: 1px solid #e1e1ea;
      }
    }
  }
`;

export const ResetFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  height: 30px;
  width: 52px;
  ${textBase}
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0em;
  :hover {
    cursor: pointer;
    color: #5158cf;
    svg path {
      fill: #5158cf;
    }
  }
  ${props =>
    props.hide &&
    css`
      display: none;
    `}
`;
