import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { bindActionCreators } from 'redux';
import Inbox from './component';

import {
  selectRoom,
  changeProfileId,
  createNewConversation,
  getAllRooms,
  getRoomIfExists,
} from 'redux/inbox-new/actions';
import { getClientsFullData } from 'redux/client/client.actionCreators';

const mapState = state => {
  const {
    rootReducer: {
      inboxNew: { currentRoomId, profileId, rooms, newConversation, viewTeammate, query },
    },
    user,
  } = state;

  return { currentRoomId, profileId, newConversation, rooms: rooms || [], viewTeammate, query, user };
};

const mapDispatch = dispatch => ({
  selectRoom: bindActionCreators(selectRoom, dispatch),
  changeProfileId: bindActionCreators(changeProfileId, dispatch),
  createNewConversation: bindActionCreators(createNewConversation, dispatch),
  getClientsFullData: bindActionCreators(getClientsFullData, dispatch),
  push: bindActionCreators(push, dispatch),
  getAllRooms: bindActionCreators(getAllRooms, dispatch),
  getRoomIfExists: bindActionCreators(getRoomIfExists, dispatch),
});

export default connect(mapState, mapDispatch)(Inbox);
