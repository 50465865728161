/**
 * @flow
 */

import React, { connect } from 'react-redux';
import SuccessPopup from './component';
const mapStateToProps = (state: State, ownProps: Object) => {
  const { rootReducer } = state;
  return {
    isShowing: rootReducer.modal.isShowingSuccess,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: State) => {
  return {
    dispatch,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessPopup);
