import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  height: 104px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #e2e3e8;
  background: #fff;
  cursor: pointer;

  .text {
    color: #728096;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .image-container {
    .icon {
      display: block;
      width: 34px;
      height: 34px;
      margin: auto;
    }

    .icon.hover {
      display: none;
    }
  }

  &:hover {
    border-radius: 5px;
    border: 1px solid #5158cf;
    background: #fff;

    .text {
      color: #000;
    }

    .image-container {
      .icon:not(.hover) {
        display: none;
      }

      .icon.hover {
        display: block;
      }
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none !important;
    box-shadow: none !important;
    text-indent: -9999px;
    opacity: 0;
    border: none !important;
    cursor: pointer;
  }

  &.image {
    gap: 8px;
    .icon {
      margin-top: 1px;
      margin-left: 1px;
      width: 35px;
    }
    .text {
      margin-left: 2px;
    }
  }

  &.video {
    gap: 6px;
    .icon {
      margin-top: 2px;
      margin-left: 2px;
      width: 35px;
      height: 35px;
    }
    .text {
      margin-left: 1px;
    }
  }
`;
