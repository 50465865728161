import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Prompt } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import * as S from './style';
import CollectionNameInput from './CollectionNameInput';
import CollectionSection from './CollectionSection';
import CoverImage from './CoverImage';
import {
  updateWorkingCollection,
  updateStudioCollection,
  moveResource,
  resetChangeCollection,
} from 'redux/studio-collection/actions';
import { Button } from 'shared/FormControl';
import MobilePreviewCollection from './MobilePreviewCollection';
import { toggleConfirmModal } from 'actions/modal';
import { ReactComponent as Plus } from 'assets/icons/plus_resource.svg';
import { getProfile } from 'actions/profile';
import { convertS3UrlToCloudFrontUrl, isTeamAdmin, replaceImageURL } from 'utils/commonFunction';
import { OwnershipPopupStudio } from 'shared/AssetsShareSetting';
import { CDN_URL, TEAM_SHARE_NOOWNER } from 'constants/commonData';
import { generateParams } from 'shared/AssetsShareSetting/constants';

const countNumberOfResource = collection => {
  return _.reduce(
    collection.sections,
    (sum, section) => {
      return sum + section.resources.length;
    },
    0,
  );
};

const queryAttr = 'data-rbd-drag-handle-draggable-id';

const INIT_SECTION = {
  format: 'list',
  resources: [],
  section_name: '',
};

const getImageFileName = url => {
  if (!url) {
    return '';
  }
  const urlParts = /^(?:\w+\:\/\/)?([^\/]+)(.*)$/.exec(url);

  return url.indexOf('http') === 0 ? urlParts[2].substring(1) : urlParts[0];
};

const hideCoverImage = process.env.REACT_APP_HIDE_COVER_RESOURCE_COLLECTION;

class CollectionResources extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      placeholderProps: {},
      isConfirmDiscardChange: false,
      isPreview: true,
      isNewSection: false,
      isFirstAdd: false,
    };

    this.checkOwnerInfo();
  }

  componentDidMount() {
    this.setState({
      isFirstAdd: this.props.isFirstTimeAdd || false,
    });

    this.checkOwnerInfo();
  }

  componentDidUpdate(prevProps) {
    const { originCollection: { author, share } = {} } = this.props;
    const { originCollection: { author: prevAuthor, share: prevShare } = {} } = prevProps;

    if (author !== prevAuthor || share !== prevShare) {
      this.checkOwnerInfo();
    }
  }

  componentWillUnmount() {
    const { isFirstTimeAdd, getProfile } = this.props;
    !isFirstTimeAdd && getProfile();
  }

  checkOwnerInfo() {
    const { workingCollection = {}, user = {} } = this.props;
    this.author = typeof workingCollection.author_info === 'object' ? workingCollection.author_info : undefined;
    this.authorId = (this.author || {})._id;
    this.isCreator = user._id === this.authorId;
    this.isOwnerOrAdmin = isTeamAdmin(user);
    this.isNoOwner = workingCollection.share === TEAM_SHARE_NOOWNER;
    this.isCreatorOrOwnerOrAdmin = this.isCreator || this.isOwnerOrAdmin || this.isNoOwner;
  }

  onChangeName = name => {
    this.props.updateWorkingCollection({ name });
  };

  onChangePreview = () => {
    this.setState({ isPreview: !this.state.isPreview });
  };

  isChangeCollection = () => {
    const { originCollection, workingCollection } = this.props;
    if (
      !workingCollection.name ||
      originCollection.name !== workingCollection.name ||
      originCollection.sections.length !== workingCollection.sections.length ||
      originCollection.background !== workingCollection.background ||
      originCollection.author !== workingCollection.author ||
      originCollection.share !== workingCollection.share
    ) {
      return true;
    }

    const isChangeSection =
      _.findIndex(workingCollection.sections, (o, i) => {
        const origiSection = originCollection.sections[i];
        if (origiSection && o.section_name !== origiSection.section_name) {
          return true;
        }

        if (origiSection && o.format !== origiSection.format) {
          return true;
        }

        if (origiSection && o.description !== origiSection.description) {
          return true;
        }

        if (origiSection && !_.isEqual(_.map(o.resources, '_id'), _.map(origiSection.resources, '_id'))) {
          return true;
        }
        return false;
      }) !== -1;

    return isChangeSection;
  };

  isValidForm = () => {
    const { workingCollection } = this.props;
    if (!workingCollection.name) {
      return false;
    }

    if (_.findIndex(workingCollection.sections, s => !s.section_name) !== -1) {
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { workingCollection, originCollection, push } = this.props;
    const newSections = workingCollection.sections;
    const sections = newSections.map(s => {
      return {
        ...s,
        resources: _.map(s.resources, '_id'),
      };
    });

    const paramsShare = generateParams({
      ownership: workingCollection.author,
      shareStatus: workingCollection.share,
    });

    const body = {
      name: workingCollection.name,
      sections,
      background:
        workingCollection.background === originCollection.background
          ? getImageFileName(originCollection.background)
          : workingCollection.background,
      owner: paramsShare.owner,
      share: paramsShare.share,
    };

    this.setState({ isSubmitting: true });
    this.props.updateStudioCollection(workingCollection._id, body).finally(() => {
      this.setState({ isSubmitting: false });
    });
  };

  handleDragEnd = result => {
    const { destination, source } = result;
    if (!destination || !source) {
      return;
    }
    const params = {
      resourceId: result.draggableId,
      fromSection: source.droppableId,
      fromIndex: source.index,
      toSection: destination.droppableId,
      toIndex: destination.index,
    };

    this.props.moveResource(params);
  };

  onBeforeCapture = () => {};

  onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children].slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        const borderTop = parseFloat(style.borderTop);
        const borderBottom = parseFloat(style.borderBottom);
        return total + curr.clientHeight + marginBottom + borderBottom + borderTop;
      }, 0);
    this.setState({
      placeholderProps: {
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
      },
    });
  };

  handleBlockedNavigation = nextLocation => {
    if (this.state.isConfirmDiscardChange) {
      return true;
    }
    this.props.toggleConfirmModal(
      true,
      <S.CustomConfirmModal
        noBorder
        title="Discard Changes?"
        content={`Are you sure you want to go? Changes have not been saved yet.`}
        onConfirm={() => this.handleConfirmDiscardChange(nextLocation.pathname)}
        confirmButtonTitle="Discard changes"
        hasCloseIcon
        headerIcon={`${CDN_URL}/images/alert_warning.svg`}
      />,
    );
    return false;
  };

  handleConfirmDiscardChange = pathname => {
    this.setState({ isConfirmDiscardChange: true }, () => {
      this.props.resetChangeCollection();
      this.props.push(pathname);
    });
  };

  onChangeNewSection = () => {
    this.setState({
      isNewSection: false,
    });
  };

  handleNewSection = () => {
    if (!this.isCreatorOrOwnerOrAdmin) return;
    this.setState({
      isNewSection: true,
    });
  };

  handleSwapItems = (from, to) => {
    const {
      workingCollection: { sections },
    } = this.props;
    let temp = sections[to];
    sections[to] = sections[from];
    sections[from] = temp;

    this.props.updateWorkingCollection({
      workingCollection: {
        sections: sections,
      },
    });
  };

  handleRemove = index => {
    const {
      workingCollection: { sections },
    } = this.props;
    sections.splice(index, 1);
    this.props.updateWorkingCollection({
      workingCollection: {
        sections: sections,
      },
    });
  };

  handleChangeCoverImage = background => {
    const splittedBackground = background.split('/');
    const backgroundParam = background
      ? `${splittedBackground[splittedBackground.length - 2]}/${splittedBackground[splittedBackground.length - 1]}`
      : '';

    this.props.updateWorkingCollection({
      background: backgroundParam,
      background_thumbnail: background ? replaceImageURL(background) : '',
    });
  };

  handleConfirmFirstTime = () => {
    this.setState({
      isFirstAdd: true,
    });
  };

  handleChangeOwnershipStatus = ({ author, share }) => {
    this.props.updateWorkingCollection({ author, share });
  };

  render() {
    const { workingCollection, user, cloudfrontList, resources_per_collection } = this.props;
    const { isSubmitting, placeholderProps, isPreview, isNewSection, isFirstAdd } = this.state;
    const lengthOfSections = workingCollection.sections.length;

    return (
      <>
        <S.Wrapper>
          <Helmet>
            <title>{workingCollection.name} - Resource Collections - Everfit</title>
          </Helmet>
          <Prompt when={this.isChangeCollection()} message={this.handleBlockedNavigation} />
          <div className={`collectionResources__info ${!isPreview && 'full'}`} id="collectionResources__info">
            <div className="collectionResources__heading">
              {!hideCoverImage && (
                <CoverImage
                  onChangeCover={this.handleChangeCoverImage}
                  cover={convertS3UrlToCloudFrontUrl(workingCollection.background_thumbnail, cloudfrontList, true)}
                  onConfirmFirstAdd={this.handleConfirmFirstTime}
                  disabled={!this.isCreatorOrOwnerOrAdmin}
                />
              )}
              <CollectionNameInput
                name={workingCollection.name}
                onConfirmChange={this.onChangeName}
                disabled={!this.isCreatorOrOwnerOrAdmin}
              />
              {/* <S.OwnershipPopupWrapper>
                {this.isCreatorOrOwnerOrAdmin && (
                  <OwnershipPopupStudio
                    user={user}
                    owner={this.author}
                    shareStatus={workingCollection.share}
                    saveChangeOnClose={this.handleChangeOwnershipStatus}
                  />
                )}
              </S.OwnershipPopupWrapper> */}
              <Button
                disabled={
                  !this.isCreatorOrOwnerOrAdmin || !this.isChangeCollection() || isSubmitting || !this.isValidForm()
                }
                onClick={this.handleSubmit}
                className="collectionResources__heading--saveBtn"
                purple
              >
                Save
              </Button>
            </div>
            <div className="collectionResources__limitResource">
              Resource limit:{' '}
              <b>
                {countNumberOfResource(workingCollection)}/{resources_per_collection}
              </b>
            </div>
            <DragDropContext
              onDragEnd={this.handleDragEnd}
              onBeforeCapture={this.onBeforeCapture}
              onDragUpdate={this.onDragUpdate}
            >
              <Droppable droppableId="droppableSection" type="Sections">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="collectionSection">
                    {workingCollection.sections.map((section, index) => (
                      <CollectionSection
                        key={section._id}
                        section={section}
                        placeholderProps={placeholderProps}
                        onSwap={this.handleSwapItems}
                        isFirstSection={index === 0}
                        isLastSection={index === lengthOfSections - 1}
                        currentIndex={index}
                        onRemove={this.handleRemove}
                        disabled={!this.isCreatorOrOwnerOrAdmin}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isNewSection && (
              <CollectionSection
                section={INIT_SECTION}
                placeholderProps="New Section Name"
                isNewSection={true}
                key="new-section"
                onCreateSection={this.onChangeNewSection}
              />
            )}
            {!isNewSection && (
              <S.NewSection onClick={this.handleNewSection} disabled={!this.isCreatorOrOwnerOrAdmin}>
                <Plus />
                <span>Add New Section</span>
              </S.NewSection>
            )}
          </div>
          <div className={`collectionResources__mobilePreview ${isPreview ? 'open' : 'hidden'}`}>
            <MobilePreviewCollection />
          </div>
          <S.CollapseWrapper onClick={this.onChangePreview} className={!isPreview && 'hidden'}>
            <Icon className={`chevron ${isPreview ? 'right' : 'left'}`} />
          </S.CollapseWrapper>
        </S.Wrapper>
        {!hideCoverImage && !isFirstAdd && <S.OverlapWrapper />}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    rootReducer: { permission = {} },
  } = state;

  return {
    user: state.user,
    workingCollection: state.rootReducer.studioCollection.workingCollection,
    originCollection: state.rootReducer.studioCollection.originCollection,
    isFirstTimeAdd: state.user.is_got_it_add_cover_image_studio_collection,
    cloudfrontList: state.cloudfrontList,
    resources_per_collection: permission.resources_per_collection || 25,
  };
};

const mapDispatchToProps = {
  toggleConfirmModal,
  push,
  updateStudioCollection,
  updateWorkingCollection,
  moveResource,
  resetChangeCollection,
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionResources);
