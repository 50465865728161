import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  border: 1px solid #dadfea;
  box-shadow: 0px 2px 4px rgba(146, 146, 210, 0.15);
  border-radius: 5px;
  padding: 25px 20px 18px 20px;
  margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlusIconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #5158cf;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
    background-color: #5559ff;
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #202353;
  margin-bottom: 25px;
`;

export const AddProductTitle = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  opacity: 0.4;
  padding-left: 12px;
`;

export const IconWrapper = styled.div`
  width: 94.48px;
  height: 73px;
  background-image: url(${CDN_URL}/images/add_a_product.svg);
`;

export const NewSection = styled.div`
  border: 1px dashed #c8cfe0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px 0 18px;
  ${props =>
    !props.isEdit &&
    css`
      pointer-events: none;
    `}

  &:hover {
    border: 1px dashed #5158cf;
    ${AddProductTitle} {
      color: #5158cf;
      opacity: 1;
    }
    ${IconWrapper} {
      background-image: url(${CDN_URL}/images/add_a_product_light.svg);
    }
  }
`;

export const AddNewIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #5158cf;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px !important;
  flex-shrink: 0;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ProductListWrapper = styled.div``;
export const ModalWrapper = styled(Modal)`
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #263d52;

  &.ui.modal {
    border-radius: 8px;
    max-width: 100%;
    width: 650px;
    > :last-child {
      padding: 12px 30px;
    }

    > .content {
      padding: 20px 30px 0 30px !important;
      border-radius: 8px !important;
      min-height: 575px;
    }
    > .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: transparent;
      box-shadow: 0px -4px 16px rgba(107, 111, 183, 0.1);

      button {
        height: 32px;
        width: 95px;
        margin-left: 5px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
      }

      .ui.button.general-button.create-button {
        width: 127px;
        height: 18px;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #5158cf !important;
        border: none;
        box-shadow: none !important;
        background-color: transparent;
        padding: 0;
        margin: 0 !important;

        :hover {
          cursor: pointer;
          color: #5559ff !important;
          background-color: transparent !important;

          ${AddNewIcon} {
            background-color: #5559ff;
          }
        }
        .create-label {
          white-space: nowrap;
        }
      }
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #202353;
  margin-bottom: 0;
`;

export const SearchWrapper = styled.div`
  .search-products {
    height: 40px;
    width: 270px;
    background: #f6f7f9;
    input {
      border-radius: 5px;
      background-image: url(${CDN_URL}/images/activity_search.svg);
      &::placeholder {
        font-size: 13px;
        line-height: 18px;
        color: #a6acc6;
        opacity: 1;
      }
    }
  }
`;

export const ListWrapper = styled.div`
  padding-top: 30px;
`;

export const ListTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ListTitle = styled.h5`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #202353;
  margin-bottom: 8px !important;
`;

export const ListSubTitle = styled.p`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #202353;
`;

export const Empty = styled.div``;

export const EmptyBanner = styled.div`
  width: 372px;
  padding-top: 55px;
  /* background-color: #c4c4c4; */
  margin: 0 auto 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #202353;
    font-size: 14px;
    line-height: 120%;
    padding-top: 50px;
  }
`;

export const EmptyDesc = styled.p`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #202353;
  max-width: 360px;
  margin: 0 auto 40px;
  opacity: 0.8;
  color: #000000;
`;

export const AddNewWrapper = styled.div`
  text-align: center;
  button {
    border: 1px solid #5c5bbd;
    border-radius: 5px;
    height: 34px;
    width: 166px;
    color: #5158cf;
  }
`;

export const RemoveIcon = styled.img`
  position: absolute;
  display: none;
  top: -7px;
  right: -7px;
  cursor: pointer;
  width: 15px;
`;

export const Link = styled.div`
  font-weight: 600;
  font-size: 11px;
  color: #5158cf;
  line-height: 15px;
  display: none;
  svg {
    margin-left: 5px;
  }
`;

export const DescCounter = styled.span`
  font-size: 12px;
  line-height: 150%;
  color: #202353;
  opacity: 0.5;
`;

export const OriginalProduct = styled.div`
  border: 1px solid #dadfea;
  box-shadow: 0px 4px 8px #f1f2f6;
  border-radius: 5px;
  min-height: 83px;
  display: grid;
  grid-template-columns: 134px auto;
  position: relative;
  ${props =>
    props.isEdit &&
    css`
      &:hover {
        border: 1px solid #5158cf;
        cursor: pointer;
        ${RemoveIcon} {
          display: flex;
        }
        ${Link} {
          display: flex;
        }
        ${DescCounter} {
          display: none;
        }
      }
    `}
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }
`;

export const Thumbnail = styled.div`
  height: 83px;
  width: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaecf2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  ${props =>
    props.thumbnail &&
    css`
      background-image: url(${props.thumbnail});
      background-position: center;
      background-size: cover;
      svg {
        display: none;
      }
    `}
`;

export const Desc = styled.div`
  padding: 10px 25px 12px 10px;
  position: relative;
  overflow: hidden;
  .product-item {
    position: absolute !important;
    top: 9px;
    right: 4px;
    width: 16px;
    height: 16px;

    input {
      left: 3px;
      z-index: 10;
      width: auto;
      height: auto;
    }

    .checkbox-icon {
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }

    input:checked ~ .checkbox-icon {
      background: #5e59ff;
      border-color: #5e59ff;
      box-shadow: 0px 0px 1px rgba(58, 57, 76, 0.24);
      background-image: url(${CDN_URL}/images/new_check_white.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const DescTitle = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #202353;
  max-height: 36px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  padding-right: 20px;
  white-space: pre-wrap;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const TextBold = styled(DescCounter)`
  font-weight: 700;
  opacity: 1;
`;

export const Bull = styled.span`
  opacity: 0.5;
  font-size: 14px;
  margin: 0 10px;
`;

export const ArchivedStatus = styled.div`
  background: #fff5de;
  border: 1px solid #ffc445;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: bold;
  font-size: 11px;
  line-height: 150%;
  color: #e69f08;
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 2px 7px 1px;
`;

export const List = styled.div`
  max-height: 475px;
  overflow-y: scroll;
  margin-right: -10px;
  padding-right: 10px;
`;

export const ProductItemWrapper = styled.div`
  border: 1px solid #dadfea;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 134px auto;
  cursor: pointer;
  ${props =>
    props.selected &&
    css`
      border: 1px solid #5158cf;
    `}

  ${props =>
    props.disabled &&
    !props.selected &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      .product-item,
      .checkbox-icon,
      input[type='checkbox'] {
        cursor: not-allowed;
      }
    `}
  &:hover {
    border: 1px solid #5158cf;
  }
  margin-bottom: 10px;
`;

export const ProductItemMedia = styled.div``;

export const NotFound = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  opacity: 0.3;
  margin: 0 0 90px;
  text-align: center;
`;
