export const LIST_SECTION_FORMAT = [
  {
    key: 'regular',
    value: 'Regular',
    desc: 'Exercise by exercise, mostly used for strength workouts.',
  },
  {
    key: 'amrap',
    value: 'AMRAP',
    desc: 'Track total rounds completed based on time assigned.',
  },
  {
    key: 'timed',
    value: 'Timed',
    desc: 'Track total duration based on rounds assigned.',
  },
  {
    key: 'interval',
    value: 'Interval',
    desc: 'Runs built-in timer for exercise and rest (HIIT, Tabata, Circuit).',
  },
  {
    key: 'freestyle',
    value: 'Freestyle',
    desc: 'Best for warmups, Crossfit, or any follow-along videos.',
  },
];
