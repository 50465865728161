// Libs
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close-form-banner.svg';

// Redux
import { hideOnboardingTriggerBanner } from 'redux/general-settings/actions';

// Styles
import * as S from './styles';

const ConditionalAssignment = props => {
  const { isHideBanner, hideOnboardingTriggerBanner } = props;

  const handleCloseTrigger = () => {
    typeof hideOnboardingTriggerBanner === 'function' && hideOnboardingTriggerBanner();
  };

  if (isHideBanner) return null;

  return (
    <S.ConditionalAssignmentWrapper>
      <button className="close-trigger" onClick={handleCloseTrigger}>
        <CloseIcon />
      </button>
      <div className="title-banner">Conditional Assignment</div>
      <div className="tag-new-banner">New</div>
      <div className="description-banner">
        Automatically assign options based on client answers using question triggers
        <br />
        <a
          className="link-read-more-banner"
          href="https://help.everfit.io/en/articles/9595362-form-triggers"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more
        </a>
      </div>
    </S.ConditionalAssignmentWrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  const { generalSettings, pricing, permission } = rootReducer || {};
  const { loaded, is_hide_onboarding_flow_trigger_form_note } = generalSettings || {};

  const enabledAddons = pricing && pricing.getIn(['teamData']).toJS();
  const hasPermissionUseTrigger = get(permission, 'autoflow') || get(enabledAddons, 'automation');
  const isHideBanner = hasPermissionUseTrigger ? loaded && is_hide_onboarding_flow_trigger_form_note : true;

  return {
    isHideBanner,
  };
};

const mapDispatchToProps = {
  hideOnboardingTriggerBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionalAssignment);
