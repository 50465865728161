import difference from 'lodash/difference';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import union from 'lodash/union';
import React from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Checkbox } from 'shared/FormControl';
import * as Layout from 'shared/LibraryLayout';
import { selectedClientIdsContactList, changeQueryParams } from 'redux/contact-list/actions';
import { columns, CONTACT_STATUS } from '../constants';
import * as S from '../../../components/CustomTable.style';
import { ContactListHeader } from '../styles';
import { SORT_ENUM } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';

function View({
  list,
  total,
  sort,
  order,
  workingPackage,
  changeQueryParams,
  clientIdsSelected,
  selectedClientIdsContactList,
}) {
  const ids = map(
    filter(list, item => item.status === CONTACT_STATUS.WAITING),
    'id',
  );
  const hasWaitingRequest = !!get(ids, 'length', 0);
  const hasSelected = !!get(clientIdsSelected, 'length', 0);
  const idsSelected = hasSelected ? clientIdsSelected : [];
  const isSelectedAll = hasWaitingRequest && hasSelected && difference(ids, idsSelected).length === 0;
  const hasSurveyResponse = !!get(workingPackage, 'package_purchase_option.survey_id', false);

  const onSort = key => {
    changeQueryParams({
      sort: key,
      order: key === sort && order === SORT_ENUM.DESCENDING ? SORT_ENUM.ASCENDING : SORT_ENUM.DESCENDING,
    });
  };

  const renderSortIcon = key => {
    if (key !== sort) {
      return <ArrowDown className="column-symbol arrow" />;
    }

    const arrow = order === SORT_ENUM.DESCENDING ? 'up' : 'down';

    return arrow === 'up' ? (
      <ArrowUp className="column-symbol arrow" active />
    ) : (
      <ArrowDown className="column-symbol arrow" active />
    );
  };

  const handleSelectAllTask = event => {
    event.stopPropagation();

    if (event.target && event.target.checked) {
      selectedClientIdsContactList(union([...clientIdsSelected, ...ids]));
    } else {
      selectedClientIdsContactList(filter(clientIdsSelected, id => !ids.includes(id)));
    }
  };

  const customColumns = filter(columns, item => {
    if (!hasSurveyResponse) {
      return item.key !== 'survey';
    }
    return item;
  });

  return (
    <S.TableHeaderWrapper>
      <Layout.TableRow>
        <ContactListHeader key="client_name">
          <S.CustomTableCellWrapper>
            <S.CheckBoxContainer isHasSelectedPrev={hasSelected}>
              <div className="checkbox__header">
                <Checkbox checked={isSelectedAll} onChange={handleSelectAllTask} />
              </div>
              <S.TableHeaderCellContentWrapper
                className="sortable"
                active={sort === 'client_name'}
                onClick={() => onSort('client_name')}
              >
                <Layout.CellTitle>{pluralize('Client', total, true)}</Layout.CellTitle>
                {renderSortIcon('client_name')}
              </S.TableHeaderCellContentWrapper>
            </S.CheckBoxContainer>
          </S.CustomTableCellWrapper>
        </ContactListHeader>
        {map(customColumns, item => {
          return (
            <ContactListHeader key={item.key} hasSurveyResponse={hasSurveyResponse}>
              <S.TableHeaderCellContentWrapper
                className={classNames(item.sortable ? 'sortable' : '')}
                active={sort === item.key}
                onClick={() => item.sortable && onSort(item.key)}
              >
                <Layout.CellTitle>{item.name}</Layout.CellTitle>
                {get(item, 'sortable', false) && renderSortIcon(item.key)}
              </S.TableHeaderCellContentWrapper>
            </ContactListHeader>
          );
        })}
        <ContactListHeader key="actions" />
      </Layout.TableRow>
    </S.TableHeaderWrapper>
  );
}

const TableHeader = React.memo(View);

const mapState = state => {
  const {
    rootReducer: {
      packageDetail,
      contactList: {
        list,
        loading,
        total,
        clientIdsSelected,
        queryParam: { sort, order },
      },
    },
  } = state;
  return {
    list,
    loading,
    total,
    order,
    sort,
    clientIdsSelected,
    workingPackage: packageDetail.get('workingPackage').toJS(),
  };
};

const mapDispatch = dispatch => {
  return {
    selectedClientIdsContactList: bindActionCreators(selectedClientIdsContactList, dispatch),
    changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  };
};

export default connect(mapState, mapDispatch)(TableHeader);
