import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Chart, Bar } from 'react-chartjs-2';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

// import containers
import * as S from './style';
import ReactTooltip from 'react-tooltip';
import { extendChartBorderRadius } from 'helpers/chart';
import GoalStepTooltip from '../GoalStepTooltip';
import AverageStepTooltip from '../AverageStepTooltip';
import { pluralize } from 'utils/commonFunction';

const datasetsBarDefault = {
  type: 'bar',
  label: 'bar',
  backgroundColor: '#FF4C14',
  borderColor: '#FF4C14',
  hoverBorderColor: '#FF4C14',
  borderSkipped: 'bottom',
  hoverBorderWidth: 0,
  borderWidth: 0,
  data: [],
};
const datasetsLineDefault = {
  type: 'line',
  label: 'line',
  borderColor: '#E9A38F',
  borderWidth: 2,
  fill: false,
  spanGaps: true,
  tension: 0,
  borderWidth: 1,
  borderDash: [5, 3],
  pointRadius: 0,
  data: [],
};

const getAverageGoal = data => {
  const validData = _.filter(data || [], o => _.isNumber(o.value));
  if (!data.length) {
    return 0;
  }
  const sum = _.sumBy(validData, 'value');
  return sum / validData.length;
};

const initChartData = (chartData, fromDate, toDate) => {
  const isNoData = !chartData || !chartData.length;
  const labels = [];
  const startDate = moment(fromDate, 'MM-DD-YYYY').startOf('month');
  const endDate = moment(fromDate, 'MM-DD-YYYY').endOf('month');
  const chartDataObj = _.mapKeys(chartData, 'day');

  const daysOfMonth = _.range(startDate.date(), endDate.date() + 1);
  const datasets = {};

  datasets.line = _.cloneDeep(datasetsLineDefault);
  datasets.bar = _.cloneDeep(datasetsBarDefault);
  _.forEach(daysOfMonth, day => {
    const thisDate = moment(fromDate, 'MM-DD-YYYY').add(day - 1, 'day');
    const thisDay = thisDate.format('MM-DD-YYYY');
    if (day % 7 === 1) {
      labels.push(day === 1 ? startDate.format('MMM D') : day);
    } else {
      labels.push(`hide_${day}`);
    }

    if ((day % 7 === 4 || (day === daysOfMonth.length && day % 7 !== 0)) && !isNoData) {
      const currentWeek = _.floor(thisDate.date() / 7);
      const nowWeek = _.floor(moment().date() / 7);
      const nowStartOfMonth = moment().startOf('month');
      const nowEndOfMonth = moment().endOf('month');
      const isPastMonth = nowStartOfMonth.isAfter(startDate);
      const isCurrentMonth = nowEndOfMonth.isSame(endDate);
      if (isPastMonth || (isCurrentMonth && currentWeek <= nowWeek)) {
        const currentWeekDays = _.filter(chartData, d => {
          const dateOfMonth = moment(d.day, 'MM-DD-YYYY').date();
          return _.floor((dateOfMonth - 1) / 7) === currentWeek;
        });

        const avarageStep = getAverageGoal(currentWeekDays);
        datasets.line.data.push(avarageStep);
      }
    } else {
      datasets.line.data.push(null);
    }
    datasets.bar.data.push(_.get(chartDataObj, [thisDay, 'value']));
  });

  return {
    labels,
    datasets: _.values(datasets),
  };
};

class StepTrackingMonthlyChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.goalRef = React.createRef();
    this.averageRepsRef = React.createRef();
    this.dayRef = {};
    this.weekRef = {};
  }

  componentDidMount() {
    this.handlePositionAnnotations();
    this.handlePositionDayHover();
    this.handlePositionWeekView();
  }

  UNSAFE_componentWillMount() {
    Chart.helpers.extend(Chart.elements.Rectangle.prototype, extendChartBorderRadius());
  }

  componentDidUpdate() {
    this.handlePositionAnnotations();
    this.handlePositionDayHover();
    this.handlePositionWeekView();
  }

  handlePositionAnnotations = () => {
    if (this.chartRef.current) {
      const { dailyGoal } = this.props;
      const stepGoal = dailyGoal;
      const { chartInstance } = this.chartRef.current;
      const maxValue = chartInstance.scales['y-axis-0'].end;
      const heighYAxis = chartInstance.scales['y-axis-0'].height;

      let top;
      if (this.goalRef.current) {
        top = (heighYAxis / maxValue) * (maxValue - stepGoal);
        this.goalRef.current.style.top = `${top}px`;
      }
      if (this.averageRepsRef.current) {
        const topRestDay = (heighYAxis / maxValue) * maxValue - 2;
        this.averageRepsRef.current.style.top = `${topRestDay}px`;
        if (Math.abs(topRestDay - top) < 12) {
          if (top <= topRestDay) {
            this.goalRef.current.style.top = `${top - (12 - Math.abs(topRestDay - top)) / 2}px`;
          } else {
            this.goalRef.current.style.top = `${top + (12 - Math.abs(topRestDay - top)) / 2}px`;
          }
        }
      }
    }
  };

  handlePositionDayHover = () => {
    if (this.chartRef.current) {
      const { chartData } = this.props;
      const isNoData = !chartData || !chartData.length;
      const { chartInstance } = this.chartRef.current;
      const maxYValue = chartInstance.scales['y-axis-0'].end;
      const heighYAxis = chartInstance.scales['y-axis-0'].height;
      const paddingLeftXAxis = chartInstance.scales['x-axis-0'].paddingLeft;
      const widthXaxis = chartInstance.scales['x-axis-0'].width;

      if (this.dayRef && !isNoData) {
        const { toDate } = this.props;
        const endDate = moment(toDate, 'MM-DD-YYYY').endOf('month');
        const daysOfMonth = _.range(0, endDate.date());
        const dataChart = chartInstance.getDatasetMeta(1).data;
        const barWidth = dataChart[0]._model.width;
        const spacingWidth = dataChart[1]._model.x - dataChart[0]._model.x - barWidth;

        _.forEach(daysOfMonth, day => {
          const refKey = `day_${day}`;
          const ref = this.dayRef[refKey];
          if (ref) {
            const value = Number(ref.dataset.value || 0);
            ref.style.visibility = value > 0 ? 'visible' : 'hidden';
            const top = dataChart[day]._model.y;
            ref.style.top = `${top}px`; // 5 is paddding bottom
            const right = widthXaxis - paddingLeftXAxis - barWidth * day - spacingWidth * day - spacingWidth / 2;
            ref.style.right = `${right}px`;
            ref.style.width = `${barWidth}px`;
            ref.style.height = `${(heighYAxis / maxYValue) * value}px`;
          }
        });
      }
    }
  };

  handlePositionWeekView = () => {
    if (this.chartRef.current) {
      const { chartData } = this.props;
      const isNoData = !chartData || !chartData.length;
      const { chartInstance } = this.chartRef.current;
      const { elements } = chartInstance.annotation;
      const heighYAxis = chartInstance.scales['y-axis-0'].height;
      const paddingRightXAxis = chartInstance.scales['x-axis-0'].paddingRight;
      const widthXaxis = chartInstance.scales['x-axis-0'].width;

      if (this.weekRef && !isNoData) {
        const { toDate } = this.props;
        const endDate = moment(toDate, 'MM-DD-YYYY').endOf('month');
        const daysOfMonth = _.range(0, endDate.date());
        const weekViews = _.filter(daysOfMonth, d => d % 7 === 0);
        const firstElm = elements[`week_${weekViews[0]}`];
        const secondElm = elements[`week_${weekViews[1]}`];
        const baseWidth = secondElm._model.x1 - firstElm._model.x1;
        _.forEach(weekViews, (day, index) => {
          const refKey = `week_${day}`;
          const ref = this.weekRef[refKey];
          if (ref) {
            const element = elements[refKey];
            const nextElement = elements[`week_${weekViews[index + 1]}`];

            const currentX = _.get(element, '_model.x1', chartInstance.width);
            const nextX = _.get(nextElement, '_model.x1', chartInstance.width);
            const width = nextX - currentX;
            const right = widthXaxis + paddingRightXAxis - baseWidth * index;
            ref.style.top = `${element._model.y1}px`;
            ref.style.right = `${right}px`;
            ref.style.width = `${width}px`;
            ref.style.height = `${heighYAxis}px`;
          }
        });
      }
    }
  };

  render() {
    const { chartData, dailyGoal, fromDate, toDate, loadingChartData } = this.props;
    const data = initChartData(chartData, fromDate, toDate);
    const stepGoal = dailyGoal;
    const isNoData = !chartData || !chartData.length;
    const endDate = moment(fromDate, 'MM-DD-YYYY').endOf('month');
    const daysOfMonth = _.range(0, endDate.date());
    const weekViews = _.filter(daysOfMonth, d => d % 7 === 0);
    const weekAnnotations = isNoData
      ? [
          {
            type: 'line',
            mode: 'vetical',
            drawTime: 'beforeDatasetsDraw',
            scaleID: 'x-axis-0',
            value: -0.5,
            borderColor: '#8A8A8A',
            borderWidth: 1,
            borderDash: [5, 3],
          },
        ]
      : _.map(weekViews, dayWeek => {
          return {
            id: `week_${dayWeek}`,
            type: 'line',
            mode: 'vertical',
            drawTime: 'beforeDatasetsDraw',
            scaleID: 'x-axis-0',
            value: dayWeek - 0.5,
            borderColor: '#DADEE4',
            borderWidth: 1,
            borderDash: [5, 3],
          };
        });

    return (
      <S.Wrapper>
        <div className="monthly-report__chart">
          {stepGoal > 0 && (
            <div ref={this.goalRef} className="monthly-report__goal-tooltip monthly-report__goal-tooltip--step-goal">
              <GoalStepTooltip id="step_goal" effect="float" place={'top'} delayShow={200} steps={dailyGoal} />
              <div className="weekly-report__goal-tooltip--line" data-tip data-for="step_goal">
                &nbsp;
              </div>
            </div>
          )}
          {!isNoData &&
            daysOfMonth.map(day => {
              return (
                <>
                  <S.DayHover
                    ref={ref => (this.dayRef[`day_${day}`] = ref)}
                    data-value={_.get(data.datasets, `1.data.${day}`)}
                    data-tip
                    data-for={`day_hover_${day}`}
                  >
                    <ReactTooltip id={`day_hover_${day}`} effect="solid" place={'top'} delayShow={100}>
                      {moment(fromDate, 'MM-DD-YYYY').add(day, 'day').format('MMM DD')}&nbsp;-&nbsp;
                      <span style={{ fontWeight: 600 }}>
                        {(_.get(data.datasets, `1.data.${day}`) || 0).toLocaleString()}&nbsp;
                        {pluralize('step', _.get(data.datasets, `1.data.${day}`))}
                      </span>
                    </ReactTooltip>
                  </S.DayHover>
                </>
              );
            })}
          {!isNoData &&
            weekViews.map((weekDay, index) => {
              return (
                <>
                  <S.WeekHover
                    ref={ref => (this.weekRef[`week_${weekDay}`] = ref)}
                    data-tip
                    data-for={`average_reps_${weekDay}`}
                  />
                  <AverageStepTooltip
                    id={`average_reps_${weekDay}`}
                    effect="float"
                    place={'top'}
                    delayShow={100}
                    steps={_.get(data.datasets, `0.data.${index === 4 ? _.last(daysOfMonth) : weekDay + 3}`)}
                    fromDate={moment(fromDate, 'MM-DD-YYYY').add(weekDay, 'day')}
                    toDate={moment(fromDate, 'MM-DD-YYYY').add(
                      index === weekViews.length - 1 ? _.last(daysOfMonth) : weekDay + 6,
                      'day',
                    )}
                  />
                </>
              );
            })}
          <div className="monthly-report__line-break"></div>
          {(!chartData || !chartData.length) && !loadingChartData && <S.NoData>No data available</S.NoData>}
          <Bar
            ref={this.chartRef}
            data={data}
            height={300}
            width={700}
            options={{
              annotation: {
                events: ['mouseover', 'mouseleave'],
                annotations: [
                  {
                    type: 'line',
                    mode: 'horizontal',
                    drawTime: 'beforeDatasetsDraw',
                    scaleID: 'y-axis-0',
                    value: stepGoal,
                    borderColor: '#8A8A8A',
                    borderWidth: 1,
                    borderDash: [5, 3],
                  },
                  ...weekAnnotations,
                ],
              },
              cornerRadius: 2,
              noRadiusBottom: true,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              drawOnChartArea: false,
              responsive: false,
              bezierCurve: false,
              scaleShowLabels: false,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      drawOnChartArea: false,
                      drawTicks: false,
                    },
                    stepSize: 7,
                    barPercentage: 0.5,
                    display: 'auto',
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#20235350',
                      fontSize: 11,
                      fontStyle: 'bold',
                      padding: 20,
                      autoSkip: false,
                      maxRotation: 0,
                      stepSize: 4,
                      callback: function (label) {
                        return label.toString().startsWith('hide') ? '' : label;
                      },
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: false,
                      drawOnChartArea: false,
                    },
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#20235350',
                      fontSize: 11,
                      suggestedMax: 50,
                      fontStyle: 'bold',
                      suggestedMax: stepGoal + 10,
                      padding: 20,
                      callback: function (label) {
                        return Number(label).toSeperateFormat();
                      },
                    },
                  },
                ],
              },
            }}
            plugins={[ChartAnnotation]}
          />
        </div>
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    rootReducer: {
      clientStep: { loadingChartData },
    },
  } = state;

  return { loadingChartData };
};

export default connect(mapState, null)(StepTrackingMonthlyChart);
