import React, { memo } from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_upward.svg';
import { Tag } from './styles';
import { METRIC_CHANGED_STATUS } from '../constants';

const TagChanged = ({ info, className = '' }) => {
  return (
    <Tag
      className={classNames(className, {
        'no-change': info.status === METRIC_CHANGED_STATUS.NO,
        'increase-change': info.status === METRIC_CHANGED_STATUS.INCREASE,
        'decrease-change': info.status === METRIC_CHANGED_STATUS.DECREASE,
      })}
    >
      {info.status !== METRIC_CHANGED_STATUS.NO && <ArrowIcon width={12} height={12} />} {Number(info.percent) || 0}%
    </Tag>
  );
};

export default memo(TagChanged);
