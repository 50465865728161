// Lib
import React from 'react';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';

// Styles
import * as S from './style';

const MealEmptyList = ({ onAddMeal = () => {}, disabled }) => {
  const listEmpty = new Array(7).fill(null);

  const handleAddMeal = () => {
    if (disabled) return;
    onAddMeal();
  };

  return (
    <S.Wrapper>
      <S.AddMeal onClick={handleAddMeal} disabled={disabled}>
        <PlusIcon />
        Add Meal
      </S.AddMeal>
      {listEmpty.map((_, index) => (
        <S.MealEmpty key={index} />
      ))}
    </S.Wrapper>
  );
};

export default MealEmptyList;
