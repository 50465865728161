import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div``;

export const Heading = styled.div`
  padding: 10px;
  display: flex;

  > * {
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  background: #f6f7fb;
  border: 1px solid #f0f1f2;
  box-sizing: border-box;
  border-radius: 8px;
  width: 295px;
  height: calc(100% - 56px);
  margin-left: 10px;
  overflow: hidden;
`;

export const ActionIcon = styled.div`
  background: #ffffff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  box-shadow: 2px 2px 0px #393939;

  .__react_component_tooltip.left-content-tooltip {
    min-width: unset;
  }

  :hover {
    background-image: url(${props => props.hoverSrc});
    background-color: #5158cf;
  }

  ${props =>
    props.active
      ? css`
          background-image: url(${props => props.hoverSrc});
          background-color: #5158cf;

          :hover {
            /* background-image: url(${CDN_URL}/images/close_purple.svg); */
          }
        `
      : css`
          background-image: url(${props => props.src});
        `}
`;
