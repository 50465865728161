import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash';

import { CDN_URL } from 'constants/commonData';

import * as S from './style';

import { Button } from 'shared/FormControl';
import { NewSearchInput } from 'shared/SearchInput';
import { changeStudioProgramQueryParams, hideStudioProgramBanner } from 'redux/studio-program/actions';
import CreateStudioProgramModal from '../CreateStudioProgramModal';
import { toggleModal } from 'actions/modal';
import { ReactComponent as FilterIcon } from 'assets/icons/new_filter.svg';
import ButtonIcon from 'shared/ButtonIcon';
import FiltersPopup, { OWNER_TYPE } from 'shared/AssetsShareSetting/components/FiltersPopup';
import { SharedTooltip } from 'shared/SharedTooltip';
import { ReactComponent as MiniSearchIcon } from 'assets/icons/mini-search-icon.svg';

class StudioProgramListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSearchBox: false, showFilters: false, filters: { owner: 'all' } };
    this.searchDebounce = debounce(this.onSearch, 300);
  }

  onSearch = (event, { value }) => {
    const { search } = this.props;
    const trimmed = value.toLowerCase().trim();

    if (trimmed !== search) {
      this.props.changeStudioProgramQueryParams({ page: 1, search: trimmed });
    }
  };

  onOpenSearchBox = () => {
    this.setState({ showSearchBox: true });
  };

  onCreateNew = () => {
    this.props.toggleModal(true, <CreateStudioProgramModal />);
  };

  onKeyPress = event => {
    event.persist();

    if (event.key === 'Enter') {
      this.props.changeStudioProgramQueryParams({ page: 1 });
    }
  };

  updateFilters = filters => {
    this.setState({
      filters,
    });

    this.props.changeStudioProgramQueryParams({
      page: 1,
      only_my_studio_program: filters.owner === OWNER_TYPE.YOU,
    });
  };

  resetFilter = () => {
    this.setState({
      filters: { owner: OWNER_TYPE.ALL },
    });

    this.props.changeStudioProgramQueryParams({
      page: 1,
      only_my_studio_program: false,
    });
  };

  toggleShowFilters = () => {
    this.setState(it => ({ showFilters: !it.showFilters }));
  };

  onCounterFilters = filters => {
    let counter = 0;
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'owner' && value === 'you') {
        counter = 1;
      }
    }
    return counter;
  };

  render() {
    const { showBanner, header, banner } = this.props;
    const { showSearchBox, filters } = this.state;
    const isMobile = window.innerWidth <= 768;
    const isShowBanner = showBanner && !isMobile;
    if ((isShowBanner && header) || (!isShowBanner && banner)) {
      return null;
    }
    const counterFilters = this.onCounterFilters(filters);

    return (
      <S.Wrapper className="studio-programs__header" showBanner={showBanner && !isMobile}>
        {showBanner && !isMobile ? (
          <S.BannerContainer>
            <S.BannerTextContent>
              <div className="studio-programs__banner__label">Studio Program</div>
              <div className="studio-programs__banner__title">Flexible Programs for Clients</div>
              <div className="studio-programs__banner__description">
                Create and sell programs your clients can start and stop anytime, without you lifting a finger.
              </div>
            </S.BannerTextContent>
            <div className="buttons__container">
              <Button purple onClick={this.onCreateNew} className="button--create-new">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 4.99902V0.499023H4.5V4.99902H0V6.99902H4.5V11.499H6.5V6.99902H11V4.99902H6.5Z"
                    fill="black"
                  />
                </svg>
                <span>Create Studio Program</span>
              </Button>
              <>
                {showSearchBox ? (
                  <NewSearchInput
                    onChange={this.searchDebounce}
                    onClearSearch={() => this.onSearch(null, { value: '' })}
                    placeholder="Search by keyword or name"
                    onKeyPress={this.onKeyPress}
                  />
                ) : (
                  <>
                    <Button
                      className="button--search-box-trigger"
                      onClick={this.onOpenSearchBox}
                      data-tip
                      data-for="studio-banner-search-tooltip"
                    >
                      <MiniSearchIcon />
                    </Button>
                    <SharedTooltip content="Search" id="studio-banner-search-tooltip" />
                  </>
                )}
                <S.FilterButtonWrapper>
                  <ButtonIcon
                    text="Filter"
                    width={104}
                    height={40}
                    className="filter-options"
                    icon={<FilterIcon />}
                    color="rgb(119, 119, 119)"
                    textHoverColor="rgb(119, 119, 119)"
                    bgHoverColor="rgb(248, 248, 248)"
                    onClick={this.toggleShowFilters.bind(this)}
                    isShowFilter={this.state.showFilters}
                  />
                  {counterFilters > 0 && <S.CounterFilters>{counterFilters}</S.CounterFilters>}
                  {this.state.showFilters && (
                    <FiltersPopup
                      currentFilters={filters}
                      onUpdateFilters={this.updateFilters.bind(this)}
                      onCloseFilters={this.toggleShowFilters.bind(this)}
                    />
                  )}
                </S.FilterButtonWrapper>
              </>
              <S.ResetFilter onClick={this.resetFilter} hide={counterFilters === 0}>
                Reset
              </S.ResetFilter>
            </div>
            <div className="button-hidden-container">
              <Button className="button--hide-banner" onClick={this.props.hideStudioProgramBanner}>
                <span>Hide Banner</span>
                <img src={`${CDN_URL}/images/arrow_up_7e7e7e.svg`} alt="" />
              </Button>
            </div>
          </S.BannerContainer>
        ) : (
          <S.DefaultUIContainer>
            <div className="search-filter-container">
              {
                <NewSearchInput
                  onChange={this.searchDebounce}
                  onClearSearch={() => this.onSearch(null, { value: '' })}
                  placeholder="Search by keyword or name"
                  onKeyPress={this.onKeyPress}
                />
              }
              <S.FilterButtonWrapper>
                <ButtonIcon
                  text="Filter"
                  width={104}
                  height={36}
                  className="filter-options-header"
                  icon={<FilterIcon />}
                  color="rgb(119, 119, 119)"
                  textHoverColor="rgb(119, 119, 119)"
                  bgHoverColor="rgb(248, 248, 248)"
                  onClick={this.toggleShowFilters.bind(this)}
                  isShowFilter={this.state.showFilters}
                />
                {counterFilters > 0 && <S.CounterFilters>{counterFilters}</S.CounterFilters>}
                {this.state.showFilters && (
                  <FiltersPopup
                    currentFilters={filters}
                    onUpdateFilters={this.updateFilters.bind(this)}
                    onCloseFilters={this.toggleShowFilters.bind(this)}
                  />
                )}
              </S.FilterButtonWrapper>

              <S.ResetFilter onClick={this.resetFilter} hide={counterFilters === 0}>
                Reset
              </S.ResetFilter>
            </div>
            <Button purple onClick={this.onCreateNew}>
              <img src={`${CDN_URL}/images/plus_white.svg`} alt="" />
              <span>Create Studio Program</span>
            </Button>
          </S.DefaultUIContainer>
        )}
      </S.Wrapper>
    );
  }
}

const mapState = state => {
  const {
    user: { is_hide_banner_studio_program },
    rootReducer: {
      studioProgram: {
        query: { search },
      },
    },
  } = state;
  return { search, showBanner: !is_hide_banner_studio_program };
};

const mapDispatch = dispatch => ({
  changeStudioProgramQueryParams: bindActionCreators(changeStudioProgramQueryParams, dispatch),
  hideStudioProgramBanner: bindActionCreators(hideStudioProgramBanner, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
});

export default connect(mapState, mapDispatch)(StudioProgramListHeader);
