import React from 'react';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as S from './style';
import SelectFieldDropdown from 'shared/SelectFieldDropdown';
import { reorder } from 'utils/commonFunction';

function ExerciseFields(props) {
  const { fields, systemFields } = props;
  const restField = _.find(systemFields, (f) => f.unique_code === 'rest') || {};
  const listFieldObject = fields.map((item) => systemFields.find((tFields) => tFields._id === item));
  const renderedFields = _.filter(listFieldObject, (item) => item._id !== restField._id);

  const onDragEnd = (data) => {
    const oldIndex = _.get(data, 'source.index');
    const newIndex = _.get(data, 'destination.index');

    if (_.isNumber(oldIndex) && _.isNumber(newIndex)) {
      const newFields = reorder([..._.map(renderedFields, '_id')], oldIndex, newIndex);
      props.onChange([...newFields, restField._id]);
    }
  };

  const onRemoveField = (index) => {
    const newFields = renderedFields.slice();
    newFields.splice(index, 1);
    props.onChange([..._.map(newFields, '_id'), restField._id]);
  };

  const onChooseField = (data) => {
    if (!fields.includes(data._id)) {
      props.onChange([..._.map(renderedFields, '_id'), data._id, restField._id]);
    }
  };

  return (
    <S.FieldsContainer>
      {!!renderedFields.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="exercise-fields__droppable" direction="horizontal">
            {(provided) => (
              <S.Fields ref={provided.innerRef} {...provided.droppableProps}>
                {_.map(renderedFields, (item, index) => (
                  <Draggable key={index} draggableId={`exerise-field__draggable;${index}`} index={index}>
                    {(provided) => (
                      <S.FieldItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item._id}
                      >
                        <span className="index">{index + 1}.&nbsp;</span>
                        <span>{item.title}</span>
                        {renderedFields.length > 1 && <S.RemoveIcon onClick={() => onRemoveField(index)} />}
                      </S.FieldItem>
                    )}
                  </Draggable>
                ))}
              </S.Fields>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {renderedFields.length < 3 && <SelectFieldDropdown selectedFields={fields} onSelect={onChooseField} />}
    </S.FieldsContainer>
  );
}

export default ExerciseFields;
