import styled, { css } from 'styled-components';

export const TimeInputWrap = styled.div`
  &.time-input {
    display: flex;
    align-items: center;
    height: 36px;
    position: relative;
    overflow: hidden;

    &.sleep-goal-time {
      input {
        width: 100%;
        font-size: 13px;
        line-height: 19.5px;
        font-weight: 400;
        letter-spacing: 1.1px;
      }

      span.time-ap {
        width: 36px;

        &.time-am {
          right: 38px;
        }
      }
    }

    &.wrap-time-error input {
      border-color: #ea314a !important;
    }

    input {
      color: #202353;
      border: none;
      background-color: transparent;
      height: 36px;
      width: 110px;
      padding-left: 8px;
      border: 1px solid #e1e1ea;
      border-radius: 4px;

      &:focus {
        border-color: #5158cf;
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        font-size: 11px;
      }
    }

    span.time-ap {
      display: inline-block;
      padding: 0 4px;
      width: 27px;
      text-align: center;
      font-size: 10px;
      color: #a3a3b5;
      height: 34px;
      line-height: 34px;
      cursor: pointer;
      position: absolute;
      right: 1px;

      &.time-am {
        right: 28px;
      }

      &.time-pm {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:first-child {
        border-left: 1px solid #e1e1ea;
        border-right: 1px solid #e1e1ea;
      }

      &:hover {
        background-color: #f0f0f0;
        color: #202353;
      }
      &.selected {
        background-color: #f0f0f2;
        color: #202353;
      }
    }
  }
`;
