// Libs
import React, { useState, useEffect } from 'react';
import { Blurhash } from 'react-blurhash';

const ImageBlurHashComponent = ({
  className = '',
  alt = '',
  src = '',
  hash = '',
  resolutionX = 32,
  resolutionY = 32,
  punch = 1,
  width = '100%',
  height = '100%',
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
  }, [src]);

  return (
    <>
      {imageLoaded ? (
        <img className={className} src={src} alt={alt} />
      ) : (
        <Blurhash
          hash={hash}
          width={width}
          height={height}
          resolutionX={resolutionX}
          resolutionY={resolutionY}
          punch={punch}
        />
      )}
    </>
  );
};

export default ImageBlurHashComponent;
