import React from 'react';
import Form from './Form';
import Layout from './component';
import { register } from 'actions/auth/register';
import { axiosInstanceWithoutToken } from 'configs/request';

const SignUpForm = props => <Form {...props} onSubmit={register} autoFocus="password" />;

export default props => {
  const handleFetchData = queryParams => {
    return axiosInstanceWithoutToken.get(`/api/auth/fetch_code?code=${queryParams.inviteCode}`);
  };

  return <Layout {...props} components={{ SignUpForm }} onFetch={handleFetchData} />;
};
