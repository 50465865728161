import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import ExerciseDetailComponent from 'components/ExerciseDetail/component';
import { updateAssignment } from 'actions/workout/getWorkouts';
import { addExerciseSetToAssignment } from 'actions/workout/save';
import { selectedExercise } from 'redux/exercise/exercise.actionCreators';
import { convertFromWorkoutToAPIExercise, deleteExerciseSet, updateSetNumberInSection } from 'helpers/workout';
import {
  updateProgramLibraryWorkout,
  addSetProgramLibraryWorkout,
} from 'redux/program_library/program_library.actionCreators';
import _ from 'lodash';
import { onResetStatus } from 'actions/submitStatus';
import { DEFAULT_SUBMIT_STATUS } from 'constants/commonData';
import { bindActionCreators } from 'redux';
import { markWorkoutBuilderGuide } from 'redux/onboarding/actions';
import { getExercisesByIds, getOneRepMax } from 'redux/workout-builder/actions';
import { ENTITY_NAME } from 'database/constants';
import { getMostRecentTagsList } from 'redux/tags/actions';
import { ExerciseLibrary } from 'database/schema';

const mapStateToProps = state => {
  const { sideBarVisible, rootReducer, popup, user, submitStatus } = state;
  const {
    program_library,
    exercise,
    model,
    client: { workingClientDetail },
    exerciseTags,
  } = rootReducer;
  const selectedClient = rootReducer.client.workingClientDetail;
  const {
    rootReducer: {
      exercise: { body_parts, categories, equipments, modalities, muscleGroup, movementPattern },
    },
  } = state;

  return {
    sideBarVisible,
    selectedProgram: program_library.selected,
    selectedClient: rootReducer.client.selected,
    categories: rootReducer.exercise.categories,
    selectedClientData: selectedClient,
    fields: exercise.fields,
    popup,
    unit: selectedClient ? selectedClient.preferences : user.preferences,
    submitStatus: submitStatus.EXERCISE_DETAIL || DEFAULT_SUBMIT_STATUS,
    mostRecents: _.map(exercise.mostRecents || [], item =>
      Object.assign({}, item, {
        key: item._id,
        label: item.title,
        value: item._id,
      }),
    ),
    workoutBuilderGuideSteps: rootReducer.onboarding.workoutBuilderGuideSteps,
    OneRepMaxModel: model.getIn([ENTITY_NAME.OneRepMax]),
    client: workingClientDetail,
    bodyParts: body_parts,
    categories,
    exerciseTags,
    equipments,
    modalities,
    muscleGroup,
    movementPattern,
    modelExerciseLibrary: denormalize(model.getIn([ENTITY_NAME.ExerciseLibrary]), ExerciseLibrary, model),
  };
};

const mapDispatchToProps = (dispatch, ownerProps) => {
  return {
    dispatch,
    resetSubmitStatus: () => dispatch(onResetStatus('EXERCISE_DETAIL')),
    addExerciseAndSave: params => dispatch(addExerciseSetToAssignment(params)),
    saveCurrentExercise: data => {
      let { exercise, sectionIndex, setIndex, exIndex, assignment, client } = data;
      let params = { assignment: assignment._id };
      let section = assignment.sections[sectionIndex];

      if (!section) {
        return console.error('Cannot find section with index = ' + sectionIndex);
      }

      let set = section.exercises[setIndex];

      if (set) {
        set.supersets[exIndex] = exercise;
      }

      updateSetNumberInSection(assignment.sections[sectionIndex], setIndex, exIndex);
      const sections_target = convertFromWorkoutToAPIExercise(assignment, client.preferences);

      params = Object.assign({}, params, {
        sections_target,
        client: client._id,
      });
      params.formNamespace = 'EXERCISE_DETAIL';
      dispatch(updateAssignment(params));
    },
    removeCurrentExercise: (sectionIndex, setIndex, exIndex, assignment, client) => {
      let params = { assignment: assignment._id };
      let copyAssigment = _.cloneDeep(assignment);
      deleteExerciseSet(copyAssigment, sectionIndex, setIndex, exIndex);
      const callback = () => {
        deleteExerciseSet(assignment, sectionIndex, setIndex, exIndex);
      };

      const sections_target = convertFromWorkoutToAPIExercise(copyAssigment, client.preferences);
      params = Object.assign({}, params, {
        sections_target,
        client: client._id,
      });
      params.formNamespace = 'EXERCISE_DETAIL';
      dispatch(updateAssignment(params, callback));
    },
    addExerciseAndSaveForProgramLibraryWorkout: params => dispatch(addSetProgramLibraryWorkout(params)),
    removeCurrentExerciseFromProgramLibraryWorkout: (
      sectionIndex,
      setIndex,
      exIndex,
      workout,
      programId,
      trainer_unit,
    ) => {
      let params = {
        workoutId: workout._id,
      };

      let copyWorkout = _.cloneDeep(workout);
      deleteExerciseSet(copyWorkout, sectionIndex, setIndex, exIndex);
      const onSuccess = () => {
        deleteExerciseSet(workout, sectionIndex, setIndex, exIndex);
      };

      const sections = convertFromWorkoutToAPIExercise(copyWorkout, trainer_unit);
      params = Object.assign({}, params, {
        sections,
        programId,
        dayIndex: ownerProps.day,
        weekIndex: ownerProps.week,
      });
      params.formNamespace = 'EXERCISE_DETAIL';
      dispatch(updateProgramLibraryWorkout(params, onSuccess));
    },
    saveCurrentExerciseForProgramLibraryWorkout: data => {
      let { exercise, sectionIndex, setIndex, exIndex, workout, programId, trainer_unit } = data;

      let params = {
        workoutId: workout._id,
      };
      let section = workout.sections[sectionIndex];

      if (!section) {
        return console.error('Cannot find section with index = ' + sectionIndex);
      }

      let set = section.exercises[setIndex];

      if (set) {
        set.supersets[exIndex] = exercise;
      }

      updateSetNumberInSection(workout.sections[sectionIndex], setIndex, exIndex);
      const sections = convertFromWorkoutToAPIExercise(workout, trainer_unit);
      params = Object.assign({}, params, { sections, programId });
      params.formNamespace = 'EXERCISE_DETAIL';
      dispatch(updateProgramLibraryWorkout(params));
    },
    viewExercise: (exerciseId, callback) => dispatch(selectedExercise(exerciseId, callback, 'calendar')),
    markWorkoutBuilderGuide: bindActionCreators(markWorkoutBuilderGuide, dispatch),
    getOneRepMax: bindActionCreators(getOneRepMax, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
    getExercisesByIds: bindActionCreators(getExercisesByIds, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseDetailComponent);
