import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SectionLibrary from 'components/SectionLibrary';

export default class SectionLibraryContainer extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/home/sections/" render={props => <SectionLibrary {...props} />} />
        <Route
          exact
          path="/home/sections/:sectionId/detail"
          render={props => <SectionLibrary {...props} mode="edit" />}
        />
        <Route
          exact
          path="/home/sections/:sectionId/duplicate"
          render={props => <SectionLibrary {...props} mode="duplicate" />}
        />
      </Switch>
    );
  }
}
