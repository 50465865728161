import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { Types } from './actions';
import { Types as TypesCopyRemove } from './copy-remove-week-workout.actions';
import { updateExerciseLibraryInWorkout } from 'helpers/workout';
import { mongoObjectId } from 'utils/commonFunction';

const INITITAL_STATE = {
  assignments: [],
  copying: null,
  selectedWeek: null,
  selectedWorkouts: [],
  viewDetailStatus: false,
  isCopySelectedWorkout: false,
  weekCopyId: null,
  workoutCopyId: null,
  isLoadingWorkouts: false,
};

const updateExerciseLibrary = (data, oldState) => {
  const { day, assignmentId, oldExerciseId, newExerciseLibrary, categories, fields, unit } = data;
  const assignments = oldState.assignments.slice();

  forEach(assignments, dayData => {
    if (dayData.day === day) {
      forEach(dayData.workouts, workout => {
        if (workout._id === assignmentId) {
          workout.sections = updateExerciseLibraryInWorkout(workout.sections, oldExerciseId, newExerciseLibrary, {
            categories,
            fields,
            unit,
          });

          return false;
        }
      });

      return false;
    }
  });

  return Object.assign({}, oldState, { assignments });
};

const handleMoveWorkout = (params, oldState) => {
  const assignments = oldState.assignments;
  const { oldDay, newDay } = params;
  const fromDayWorkoutIndex = findIndex(oldState.assignments, o => o.day === oldDay);
  const toDayWorkoutIndex = findIndex(oldState.assignments, o => o.day === newDay);
  const workoutMoving = assignments[fromDayWorkoutIndex].workouts[params.fromIndex];
  workoutMoving.day = newDay;
  assignments[fromDayWorkoutIndex].workouts.splice(params.fromIndex, 1);
  if (toDayWorkoutIndex !== -1) {
    assignments[toDayWorkoutIndex].workouts.splice(params.newIndex, 0, workoutMoving);
  } else {
    assignments.push({
      autoflow: workoutMoving.autoflow,
      day: newDay,
      workouts: [workoutMoving],
      _id: workoutMoving._id,
    });
  }
  return Object.assign({}, oldState, { assignments });
};

const handleMoveExercise = (params, oldState) => {
  const assignments = oldState.assignments;
  const { oldDay, newDay, id, oldIndex, newIndex, workoutDestinationId } = params;
  const fromDayWorkoutIndex = findIndex(oldState.assignments, o => o.day === oldDay);
  const toDayWorkoutIndex = findIndex(oldState.assignments, o => o.day === newDay);

  const fromWorkoutIndex = assignments[fromDayWorkoutIndex].workouts.findIndex(o => o._id === id);

  const fromWorkout = assignments[fromDayWorkoutIndex].workouts[fromWorkoutIndex];
  const movingExercise = fromWorkout.sections[oldIndex];

  // remove old exercise
  assignments[fromDayWorkoutIndex].workouts[fromWorkoutIndex].sections.splice(oldIndex, 1);
  if (!assignments[fromDayWorkoutIndex].workouts[fromWorkoutIndex].sections.length) {
    assignments[fromDayWorkoutIndex].workouts.splice(fromWorkoutIndex, 1);
  }

  // update new exercise
  if (toDayWorkoutIndex !== -1) {
    const toWorkoutIndex = assignments[toDayWorkoutIndex].workouts.findIndex(o => o._id === workoutDestinationId);
    if (toWorkoutIndex !== -1) {
      assignments[toDayWorkoutIndex].workouts[toWorkoutIndex].sections.splice(newIndex, 0, movingExercise);
    }
  }

  return Object.assign({}, oldState, { assignments });
};

export default (state = INITITAL_STATE, action) => {
  const { payload, type } = action;
  const { assignments } = state;
  let newAssignments = assignments.slice();

  switch (type) {
    case Types.AUTOFLOW_SUCCESS_GET_ASSIGNMENTS:
      return Object.assign({}, state, { assignments: payload.data, isLoadingWorkouts: false });
    case Types.AUTOFLOW_VIEW_DETAIL_STATUS:
      return Object.assign({}, state, { viewDetailStatus: payload.status });
    case Types.AUTOFLOW_TRAINING_RESET_DATA:
      return INITITAL_STATE;
    case Types.AUTOFLOW_SUCCESS_ASSIGN_WORKOUT:
      let existedDay = false;
      forEach(newAssignments, item => {
        if (item.day === payload.day) {
          item.workouts.push(payload.data);
          existedDay = true;
          return false;
        }
      });
      if (!existedDay) {
        newAssignments.push({ day: payload.day, workouts: [payload.data] });
      }
      return Object.assign({}, state, { assignments: newAssignments });
    case Types.AUTOFLOW_COPY_WORKOUT:
      return Object.assign({}, state, { copying: payload.data });
    case Types.AUTOFLOW_TRAINING_CLEAR_COPY_ITEM:
      return Object.assign({}, state, { copying: null });
    case Types.AUTOFLOW_SUCCESS_DELETE_WORKOUT:
      forEach(newAssignments, item => {
        if (item.day === payload.workout.day) {
          remove(item.workouts, w => w._id === payload.workout._id);
          return false;
        }
      });
      return Object.assign({}, state, { assignments: newAssignments });
    case Types.AUTOFLOW_TRAINING_SUCCESS_ARRANGE_WORKOUT:
      const index = findIndex(newAssignments, item => item.day === payload.day);
      if (index !== -1) {
        newAssignments[index] = payload.data;
      }
      return Object.assign({}, state, { assignments: newAssignments });
    case Types.AUTOFLOW_TRAINING_UPDATE_EXERCISE_LIBRARY:
      return updateExerciseLibrary(payload.data, state);

    case Types.AUTOFLOW_MOVE_WORKOUT:
      return handleMoveWorkout(payload.data, state);
    case Types.AUTOFLOW_MOVE_EXERCISE:
      return handleMoveExercise(payload, state);
    case Types.AUTOFLOW_UPDATE_ASSIGNMENTS: {
      forEach(payload, assignment => {
        const foundIndex = findIndex(newAssignments, item => item.day === assignment.day);
        if (foundIndex !== -1) {
          newAssignments[foundIndex] = assignment;
        } else {
          newAssignments.push(assignment);
        }
      });
      return Object.assign({}, state, { assignments: newAssignments });
    }
    // copy and remove weeks, workouts
    case TypesCopyRemove.AUTOFLOW_TRAINING_SELECT_MULTIPLE_WORKOUT:
      return Object.assign({}, state, {
        selectedWorkouts: payload.workoutIds,
      });
    case TypesCopyRemove.AUTOFLOW_TRAINING_START_COPY_MULTIPLE_WORKOUT:
      return Object.assign({}, state, {
        isCopySelectedWorkout: true,
        workoutCopyId: payload.workoutCopyId,
      });
    case TypesCopyRemove.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST:
      if (payload.multiPaste) return state;
      return Object.assign({}, state, {
        selectedWorkouts: [],
        isCopySelectedWorkout: false,
        workoutCopyId: null,
      });
    case TypesCopyRemove.AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS:
      forEach(newAssignments, it => {
        remove(it.workouts, w => includes(payload, get(w, '_id')));
      });
      return Object.assign({}, state, {
        assignments: newAssignments,
        selectedWorkouts: [],
        isCopySelectedWorkout: false,
      });
    case TypesCopyRemove.AUTOFLOW_TRAINING_RESET_SELECTED_WEEK:
      return Object.assign({}, state, { selectedWeek: null, workoutCopyId: null });
    case TypesCopyRemove.AUTOFLOW_TRAINING_SELECT_WEEK_TO_COPY:
      return Object.assign({}, state, {
        selectedWeek: payload.selectedWeek,
        weekCopyId: payload.weekCopyId,
      });
    case TypesCopyRemove.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_REQUEST:
      if (payload.multiPaste) return state;
      return Object.assign({}, state, {
        selectedWeek: null,
        weekCopyId: null,
      });
    case TypesCopyRemove.AUTOFLOW_TRAINING_REMOVE_WEEK_SUCCESS:
      forEach(newAssignments, it => {
        remove(it.workouts, w => includes(payload, get(w, '_id')));
      });
      return Object.assign({}, state, { assignments: newAssignments });
    case TypesCopyRemove.AUTOFLOW_TRAINING_RESET_SELECTED_WORKOUTS:
      return Object.assign({}, state, {
        selectedWorkouts: [],
        isCopySelectedWorkout: false,
        workoutCopyId: null,
      });
    case TypesCopyRemove.AUTOFLOW_TRAINING_SOCKET_WORKOUT_ADDED:
      const foundIndex = findIndex(newAssignments, item => item.day === payload.day);
      if (foundIndex !== -1) {
        newAssignments[foundIndex]['workouts'] = [...newAssignments[foundIndex]['workouts'], payload.workout];
      } else {
        newAssignments = [
          ...newAssignments,
          {
            _id: mongoObjectId(),
            autoflow: payload.autoflow,
            day: payload.day,
            date: payload.workout.date,
            createdAt: payload.workout.createdAt,
            updatedAt: payload.workout.updatedAt,
            workouts: [payload.workout],
          },
        ];
      }
      return Object.assign({}, state, { assignments: newAssignments });

    case Types.AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_REQUEST: {
      return Object.assign({}, state, { isLoadingWorkouts: true });
    }

    case Types.AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_SUCCESS:
    case Types.AUTOFLOW_TRAINING_FETCH_WORKOUT_IN_RANGE_FAIL:
    case Types.AUTOFLOW_FAILED_GET_ASSIGNMENTS: {
      return Object.assign({}, state, { isLoadingWorkouts: false });
    }

    default:
      return state;
  }
};
