// Libs
import React, { useRef, useState } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/add_tag_icon.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/MealPlans/Ingredient/apple_icon.svg';

// Constants
import { ENUM_TYPE_INGREDIENT } from 'components/IngredientLibrary/constants';

// Style
import * as S from './style';

const ListIngredient = props => {
  const {
    handleChooseDietary,
    listSelected,
    isSearch = '',
    ingredient,
    loadingLoadMore,
    handleScrollList = () => {},
    total,
    handleOpenNewIngredient = () => {},
    tabActive,
    filters,
  } = props;

  const listRef = useRef(null);

  const isShowNoIngredientYet = tabActive === 'custom' && total === 0 && !filters.text_search;

  const onScroll = () => {
    if (loadingLoadMore) return;
    const listPhoto = listRef && listRef.current;
    if (listPhoto && get(ingredient, 'length', 0) < total) {
      const isAtBottom = listPhoto.scrollTop + listPhoto.clientHeight >= listPhoto.scrollHeight - 25;

      if (isAtBottom) {
        handleScrollList();
      }
    }
  };

  const renderNewIngredientButton = (hasResult = false) => {
    return (
      <S.AddIngredientBtn hasResult={hasResult} onClick={handleOpenNewIngredient}>
        <PlusIcon />
        Add “{isSearch}” as new ingredient
      </S.AddIngredientBtn>
    );
  };

  return (
    <S.ListIngredientWrapper>
      {isSearch && <S.Result>RESULTS ({total})</S.Result>}
      <S.List isSearch={isSearch} ref={listRef} onScroll={debounce(onScroll, 300)}>
        {get(ingredient, 'length', 0) > 0 ? (
          ingredient.map(item => {
            const { ingredient, image_thumbnail, type } = item;
            const selected = listSelected.some(item => get(item, 'ingredient', '') === ingredient);
            const data = { ...item, src: image_thumbnail };
            return (
              <S.DietaryInfoItemWrapper
                key={ingredient}
                selected={selected}
                item={data}
                handleChooseDietary={handleChooseDietary}
                isShowLabelCustom={type === ENUM_TYPE_INGREDIENT.CUSTOM && tabActive === 'all'}
              />
            );
          })
        ) : (
          <>
            {isShowNoIngredientYet ? (
              <S.NoResult>
                <div className="apple-icon">
                  <AppleIcon />
                </div>
                <span>No ingredients yet</span>
              </S.NoResult>
            ) : (
              <S.NoResult>
                <span>No results found.</span>
                {isSearch && isSearch.length >= 3 && renderNewIngredientButton()}
              </S.NoResult>
            )}
          </>
        )}
        {isSearch &&
          isSearch.length >= 3 &&
          get(ingredient, 'length', 0) >= total &&
          total !== 0 &&
          renderNewIngredientButton(true)}
        {loadingLoadMore && <S.LoadingIndicatorWrapper title="Loading more..." />}
      </S.List>
    </S.ListIngredientWrapper>
  );
};

export default ListIngredient;
