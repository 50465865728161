// Libs
import React, { useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

// Constants
import { CATEGORY_RECIPE } from 'components/RecipeDetail/constants';

// Style
import * as S from './style';

const CategoryList = ({
  handleChoose = () => {},
  value = [],
  showError = false,
  isSubmittedPublish,
  label = 'Category',
  list = CATEGORY_RECIPE,
  errorMsg = 'Please select category',
}) => {
  const [listSelected, setListSelected] = useState(value);

  const handleOnClick = data => {
    const checked = listSelected.some(item => get(item, 'value', '') === get(data, 'value', ''));
    let updatedList = [];
    if (checked) {
      updatedList = [...listSelected].filter(item => get(item, 'value', '') !== get(data, 'value', ''));
    } else {
      updatedList = [...listSelected, data];
    }
    setListSelected(updatedList);
    handleChoose(updatedList);
  };

  return (
    <S.Wrapper>
      <div className="content">
        <div className="label">{label}</div>
        <div className="list">
          {list.map(item => {
            const { label, value } = item || {};
            const active = listSelected.some(item => get(item, 'value', '') === value);
            return (
              <div className={classNames('item', { active })} key={value} onClick={() => handleOnClick(item)}>
                {label}
              </div>
            );
          })}
        </div>
      </div>
      {showError && isSubmittedPublish && <span className="error">{errorMsg}</span>}
    </S.Wrapper>
  );
};

export default CategoryList;
