import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import classNames from 'classnames';

// store
import { resendInvitationClient, inviteToConnect } from 'redux/client/client.actionCreators';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { toggleSideBar } from 'actions/sidebar';
import { getStatusMealPlanFormat, resetStatusMealPlanFormat } from 'redux/client-meal-plan/actions';

// hooks
import useWindowDimensions from 'hooks/useWindowDimensions';
// components
import ConfirmModal from 'shared/ConfirmModal';
import { Button } from 'shared/FormControl';
import CopyText from 'shared/CopyText';
import UpgradePath from 'shared/UpgradePath';
import EmailMissing from './EmailMissing';
// constants
import { CDN_URL, CLIENT_STATUS } from 'constants/commonData';
import { convertS3UrlToCloudFrontUrl } from 'utils/commonFunction';
import { validateEmail } from 'utils/validations';

// asset & styles
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_up_bold.svg';
import { Container, ButtonArrow } from './style';
import AvatarWrapper from 'components/ClientPage/components/AvatarWrapper';

const ClientNavs = [
  { label: 'Overview', route: '', _id: 'overview' },
  { label: 'Training', route: '/calendar' },
  { label: 'Tasks', route: '/task' },
  { label: 'Metrics', route: '/metrics' },
  { label: 'Food Journal', route: '/food-journal' },
  { label: 'Macros', route: '/macros', upgradePath: 'macro' },
  { label: 'Meal Plan', route: '/meal-plan' },
  {
    label: 'On-demand',
    route: '/on-demand',
    upgradePath: ['studio_program', 'studio_resource_collection'],
    client_connection: 1,
    active: true,
  },
  { label: 'Documents', route: '/documents' },
  { label: 'Settings', route: '/settings' },
];

const CONFIG_SIZES = {
  MIN_DESKTOP: 1000,
  TABLET: 680,
  LEFT_SIDE_BAR_WIDTH: 60,
  SUB_SIDE_BAR_WIDTH: 230,
  CLIENT_HEADER_PADDING: 60,
};

const ClientDetailHeader = props => {
  const {
    selectedClient,
    location,
    permission,
    cloudfrontList,
    sideBarVisible,
    toggleSideBar,
    getStatusMealPlanFormat,
    resetStatusMealPlanFormat,
  } = props;

  const tabListRef = useRef(null);
  const [shouldShowArrowBtn, setShouldShowArrowBtn] = useState(false);
  const { width: windowWidth } = useWindowDimensions();

  const avtUrl = useMemo(() => {
    if (selectedClient) {
      return convertS3UrlToCloudFrontUrl(selectedClient.avatar, cloudfrontList, true);
    }
    return '';
  }, [selectedClient, cloudfrontList]);

  const handleResizeWindow = width => {
    const [trialText] = document.getElementsByClassName('trialExpired__text');
    const [hasAction] = document.getElementsByClassName('action-beside-avatar');

    if (
      (sideBarVisible && !!trialText && !!hasAction && width < 1150) ||
      (width <= CONFIG_SIZES.MIN_DESKTOP && sideBarVisible)
    ) {
      toggleSideBar(false);
    }

    if (tabListRef.current) {
      const { width: menuWidth } = tabListRef.current.getBoundingClientRect();
      const subSideBarWidth = sideBarVisible ? CONFIG_SIZES.SUB_SIDE_BAR_WIDTH : 0;
      const combineWidthOfOtherElements =
        subSideBarWidth + CONFIG_SIZES.CLIENT_HEADER_PADDING + CONFIG_SIZES.LEFT_SIDE_BAR_WIDTH + 42;
      const isOverMenuList = windowWidth - menuWidth - combineWidthOfOtherElements < 0;
      setShouldShowArrowBtn(isOverMenuList);
    }
  };

  useEffect(() => {
    handleResizeWindow(windowWidth);
  }, [windowWidth]);

  useEffect(() => {
    const { _id } = selectedClient || {};
    if (_id) {
      getStatusMealPlanFormat({ client: _id });
    }
    return () => {
      resetStatusMealPlanFormat();
    };
  }, [selectedClient]);

  if (!selectedClient) {
    return null;
  }

  const prefix = `/home/client/${selectedClient._id}`;
  const { pathname } = location;
  const route = (pathname || '').trimAnyRight('/').split(prefix).pop();

  const { client_connection, invitation_link } = selectedClient;
  const isClientPending = client_connection === CLIENT_STATUS.pending;
  const isClientOffline = client_connection === CLIENT_STATUS.offline;

  const inviteToConnect = () => {
    const { email } = selectedClient;

    if (email && validateEmail(email)) {
      props.inviteToConnect(selectedClient._id, email);
    } else {
      props.toggleModal(true, <EmailMissing client={selectedClient} />);
    }
  };

  const resendInvitation = () => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        title={'Resend Invitation'}
        content={'Resend an invitation to the client?'}
        confirmButtonClass="general-button"
        onConfirm={() => props.resendInvitation(selectedClient._id)}
        onDeny={() => {}}
        headerIcon={`${CDN_URL}/images/checkbox_greenwhite.svg`}
      />,
    );
  };

  const onCopySuccess = () => {
    toast('Invite link copied!', { autoClose: 3000 });
  };

  const navs = ClientNavs.filter(nav => {
    if (!_.isNil(nav.client_connection) && selectedClient.client_connection !== nav.client_connection) {
      return false;
    }
    if (!_.isNil(nav.active) && nav.active && selectedClient.is_archived) {
      return false;
    }
    if (!process.env.REACT_APP_NUTRITION_ENABLED && nav.route === '/macros') return false;
    if (
      (!process.env.REACT_APP_ENABLE_FORMS_QUESTIONNAIRES || !permission.form_questionnaire) &&
      nav.route === '/documents'
    )
      return false;

    return true;
  });

  const renderAvatar = size => (
    <AvatarWrapper user={selectedClient} classNames="client-avatar" size={size} onClick={() => props.push(prefix)} />
  );

  const handleArrowButton = actionNext => {
    if (tabListRef.current) {
      const { scrollLeft, clientWidth } = tabListRef.current;
      const left = actionNext ? scrollLeft + clientWidth : scrollLeft - clientWidth;

      tabListRef.current.scrollTo({ left, behavior: 'smooth' });
    }
  };

  const renderSendInvitationBtn = () => (
    <Button className="button--send-invitation" onClick={inviteToConnect} grey>
      Send Invitation
    </Button>
  );

  const renderBtnWithStatusPending = () => (
    <>
      <Button className="button--resend-invitation" onClick={resendInvitation} grey>
        Resend Invitation
      </Button>
      <CopyText value={invitation_link} onSuccess={onCopySuccess}>
        {copyProps => (
          <Button className="button--copy-invite-link" {...copyProps}>
            Copy Invite Link
          </Button>
        )}
      </CopyText>
    </>
  );

  const renderClientActions = (className = '', style = {}) => (
    <div className={classNames('client-actions', className)} style={style}>
      {isClientPending && renderBtnWithStatusPending()}
      {isClientOffline && renderSendInvitationBtn()}
    </div>
  );

  return (
    <Container
      className={classNames({
        'has-actions': isClientOffline || isClientPending,
        'show-arrow-header': shouldShowArrowBtn,
      })}
    >
      {renderAvatar(50)}
      <div className="header-info">
        <div className="name-container">
          {renderAvatar(40)}
          <div className="name">
            {selectedClient.first_name.trim()}&nbsp;{selectedClient.last_name.trim()}
          </div>
          {windowWidth > CONFIG_SIZES.TABLET && renderClientActions('action-beside-avatar')}
        </div>
        {windowWidth <= CONFIG_SIZES.TABLET && <div>{renderClientActions('', { marginTop: 13 })}</div>}
        <div className="client-menu-wrap">
          {shouldShowArrowBtn && (
            <ButtonArrow className="button-prev" onClick={() => handleArrowButton(false)}>
              <ArrowIcon />
            </ButtonArrow>
          )}
          <div className="client-menu" ref={tabListRef}>
            {_.map(navs, (nav, index) =>
              nav.upgradePath ? (
                <UpgradePath key={index} pathName={nav.upgradePath} fallback={<></>}>
                  <NavLink
                    className="client-menu-item"
                    exact={nav.route === ''}
                    to={`${prefix}${nav.route}`}
                    isActive={() => (!nav.route ? route === nav.route : route.includes(nav.route))}
                  >
                    <span>{nav.label}</span>
                    <div></div>
                  </NavLink>
                </UpgradePath>
              ) : (
                <NavLink
                  key={index}
                  className="client-menu-item"
                  exact={nav.route === ''}
                  to={`${prefix}${nav.route}`}
                  isActive={() => (!nav.route ? route === nav.route : route.includes(nav.route))}
                >
                  <span>{nav.label}</span>
                  <div></div>
                </NavLink>
              ),
            )}
          </div>
          {shouldShowArrowBtn && (
            <ButtonArrow className="button-next" onClick={() => handleArrowButton(true)}>
              <ArrowIcon />
            </ButtonArrow>
          )}
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  cloudfrontList: state.cloudfrontList,
  selectedClient: state.rootReducer.client.workingClientDetail,
  location: state.router.location,
  permission: state.rootReducer.permission,
  sideBarVisible: state.sideBarVisible,
});

const mapDispatchToProps = dispatch => ({
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  resendInvitation: bindActionCreators(resendInvitationClient, dispatch),
  push: bindActionCreators(push, dispatch),
  inviteToConnect: bindActionCreators(inviteToConnect, dispatch),
  getStatusMealPlanFormat: bindActionCreators(getStatusMealPlanFormat, dispatch),
  resetStatusMealPlanFormat: bindActionCreators(resetStatusMealPlanFormat, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetailHeader);
