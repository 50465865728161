import { BACKGROUND_LIST } from 'constants/habitData';
import { CDN_URL } from 'constants/commonData';
import { Emoji } from 'emoji-picker-react';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getListHabitOverview } from 'redux/habits/actions';
import TitleTooltip from 'shared/TitleTooltip';
import { getHabitDescription } from 'utils/commonFunction';
import * as S from './style';

const HabitOverview = ({ match, getListHabitOverview, listHabitOverview, isHabitPermission }) => {
  const { clientId } = match.params;

  const handleRedirectDetailHabit = (id, has_tracked) => {
    const today = moment().format('MM-DD-YYYY');
    window.open(`/home/client/${clientId}/habit/${id}${has_tracked ? `?tracked=${today}` : ''}`, '_blank');
  };

  useEffect(() => {
    isHabitPermission && getListHabitOverview && getListHabitOverview({ client_id: clientId });
  }, []);

  if (listHabitOverview.length === 0) return null;

  return (
    <S.HabitOverview>
      <S.Header>Habits</S.Header>
      <S.List>
        {listHabitOverview.map(item => {
          const borderColor = _.find(BACKGROUND_LIST, function (o) {
            return o.border === item.color;
          });

          const renderStreak = value => {
            switch (value) {
              case 0:
                return null;
              case 1:
                return <img src={`${CDN_URL}/images/icon-streak.svg`} alt="" />;
              default:
                return (
                  <>
                    <img src={`${CDN_URL}/images/icon-streak.svg`} alt="" />x{item.current_streak}
                  </>
                );
            }
          };

          const percent = Math.round(
            Number(
              (Number(item.current_average_habit_completed) / Number(item.current_total_habit_completed)) * 100 || 0,
            ),
          );

          return (
            <S.Item key={item._id} onClick={() => handleRedirectDetailHabit(item._id, item.has_tracked)}>
              <S.Icon
                data-tip
                data-for={`tooltip-habit-${item._id}`}
                bgColor={_.get(borderColor, 'background', 'transparent')}
              >
                <CircularProgressbar
                  value={percent}
                  strokeWidth={6}
                  styles={buildStyles({
                    pathColor: `${_.get(borderColor, 'border', 'transparent')}`,
                    trailColor: `${_.get(borderColor, 'background', 'transparent')}`,
                  })}
                />
                <Emoji unified={item.icon_unified} size="18" />
              </S.Icon>
              <S.Content>
                <TitleTooltip
                  tooltipClassName="tooltip-title-habit"
                  tooltipId={`tooltip-habit-title-${item._id}`}
                  content={item.title}
                  tag={S.Title}
                />
                <S.Desc>{getHabitDescription(item)}</S.Desc>
              </S.Content>
              <S.Streak data-tip="On a streak!" data-for={`tooltip-habit-streak-${item._id}`}>
                {item.current_streak ? renderStreak(item.current_streak) : ''}
              </S.Streak>
              <S.Tooltip offset="{'top': -14}" effect="solid" id={`tooltip-habit-streak-${item._id}`} />
              <S.TooltipIcon offset="{'top': -4}" id={`tooltip-habit-${item._id}`} effect="solid">
                <span className="textBold">Completed {percent || 0}%</span> this month
              </S.TooltipIcon>
            </S.Item>
          );
        })}
      </S.List>
    </S.HabitOverview>
  );
};

const mapStateToProps = state => {
  const { rootReducer } = state;
  return {
    listHabitOverview: _.get(rootReducer, 'habits.habitOverview.data'),
    isHabitPermission: _.get(rootReducer, 'permission.habit', false),
  };
};

const mapDispatchToProps = dispatch => ({
  getListHabitOverview: bindActionCreators(getListHabitOverview, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HabitOverview));
