/**
 * @flow
 */

import { connect } from 'react-redux';
import TaskCalendarComponent from './component';

const mapStateToProps = state => {
  const { rootReducer, calendarType, calendarStartDate } = state;
  return {
    taskCalendarType: calendarType,
    taskCalendarStartDate: calendarStartDate,
    calendarMode: rootReducer.calendar.view_mode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskCalendarComponent);
