import React from 'react';
import { ReactComponent as DairyFree } from 'assets/icons/MealPlans/dairy-free.svg';
import { ReactComponent as GlutenFree } from 'assets/icons/MealPlans/gluten-free.svg';
import { ReactComponent as HighFat } from 'assets/icons/MealPlans/high-fat.svg';
import { ReactComponent as HighLowProtein } from 'assets/icons/MealPlans/high-low-protein.svg';
import { ReactComponent as KetoDiet } from 'assets/icons/MealPlans/keto-diet.svg';
import { ReactComponent as LowCarbs } from 'assets/icons/MealPlans/low-carbs.svg';
import { ReactComponent as LowSugar } from 'assets/icons/MealPlans/low-sugar.svg';
import { ReactComponent as NutFree } from 'assets/icons/MealPlans/nut-free.svg';
import { ReactComponent as LowProtein } from 'assets/icons/MealPlans/low-protein.svg';
import { ReactComponent as LowCalories } from 'assets/icons/MealPlans/low-calories.svg';
import { ReactComponent as LowSodium } from 'assets/icons/MealPlans/low-sodium.svg';
import { ReactComponent as Pescatarian } from 'assets/icons/MealPlans/pescatarian.svg';
import { ReactComponent as ShellfishFree } from 'assets/icons/MealPlans/shellfish-free.svg';
import { ReactComponent as Vegan } from 'assets/icons/MealPlans/vegan.svg';
import { ReactComponent as Vegetarian } from 'assets/icons/MealPlans/vegetarian.svg';

// Dish type
import { ReactComponent as MainDish } from 'assets/icons/MealPlans/DishType/main_dish.svg';
import { ReactComponent as SideDish } from 'assets/icons/MealPlans/DishType/side_dish.svg';

export const RECIPE_DIETARY = {
  DAIRY_FREE: 'dairy_free',
  GLUTEN_FREE: 'gluten_free',
  HIGH_FAT: 'high_fat',
  HIGH_PROTEIN: 'high_protein',
  KETO_DIET: 'keto_diet',
  LOW_CALORIES: 'low_calories',
  LOW_CARBS: 'low_carbs',
  LOW_PROTEIN: 'low_protein',
  LOW_SODIUM: 'low_sodium',
  LOW_SUGAR: 'low_sugar',
  NUT_FREE: 'nut_free',
  PESCATARIAN: 'pescatarian',
  SHELLFISH_FREE: 'shellfish_free',
  VEGAN: 'vegan',
  VEGETARIAN: 'vegetarian',
};

export const RECIPE_CATEGORY = {
  POPULAR: 'popular',
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  DINNER: 'dinner',
  SNACK: 'snack',
  SOUP: 'soup',
  SALAD_BOWL: 'salad_bowl',
  OTHERS: 'others',
};

export const MACRO_NUTRIENTS = {
  CALORIES: 'calories',
  FAT: 'fat',
  CARBS: 'carbs',
  PROTEIN: 'protein',
};

export const OTHER_NUTRIENTS = {
  SATURATED_FAT: 'saturated_fat',
  SUGAR: 'sugar',
  FIBER: 'fiber',
  SODIUM: 'sodium',
  VITAMIN_A: 'vitamin_a',
  VITAMIN_C: 'vitamin_c',
  THIAMINE: 'thiamine',
  RIBOFLAVIN: 'riboflavin',
  NIACIN: 'niacin',
  PANTOTHENIC_ACID: 'pantothenic_acid',
  PYRIDOXINE: 'pyridoxine',
  FOLATE: 'folate',
  BIOTIN: 'biotin',
  CYANOCOBALAMIN: 'cyanocobalamin',
  VITAMIN_D: 'vitamin_d',
  VITAMIN_E: 'vitamin_e',
  VITAMIN_K: 'vitamin_k',
  CALCIUM: 'calcium',
  IRON: 'iron',
  PHOSPHORUS: 'phosphorus',
  IODINE: 'iodine',
  MAGNESIUM: 'magnesium',
  ZINC: 'zinc',
  SELENIUM: 'selenium',
  COPPER: 'copper',
  MANGANESE: 'manganese',
  CHROMIUM: 'chromium',
  MOLYBDENUM: 'molybdenum',
  CHLORIDE: 'chloride',
  CHOLESTEROL: 'cholesterol',
  POTASSIUM: 'potassium',
};

export const CATEGORY_RECIPE = [
  {
    label: 'Breakfast',
    value: RECIPE_CATEGORY.BREAKFAST,
  },
  {
    label: 'Lunch',
    value: RECIPE_CATEGORY.LUNCH,
  },
  {
    label: 'Dinner',
    value: RECIPE_CATEGORY.DINNER,
  },
  {
    label: 'Snack',
    value: RECIPE_CATEGORY.SNACK,
  },
  {
    label: 'Soup',
    value: RECIPE_CATEGORY.SOUP,
  },
  {
    label: 'Salad/Bowl',
    value: RECIPE_CATEGORY.SALAD_BOWL,
  },
  {
    label: 'Others',
    value: RECIPE_CATEGORY.OTHERS,
  },
];

export const DIETARY_RECIPE = [
  {
    icon: <DairyFree />,
    name: 'Dairy Free',
    value: RECIPE_DIETARY.DAIRY_FREE,
  },
  {
    icon: <GlutenFree />,
    name: 'Gluten Free',
    value: RECIPE_DIETARY.GLUTEN_FREE,
  },
  {
    icon: <HighFat />,
    name: 'High Fat',
    value: RECIPE_DIETARY.HIGH_FAT,
  },
  {
    icon: <HighLowProtein />,
    name: 'High Protein',
    value: RECIPE_DIETARY.HIGH_PROTEIN,
  },
  {
    icon: <KetoDiet />,
    name: 'Keto Diet',
    value: RECIPE_DIETARY.KETO_DIET,
  },
  {
    icon: <LowCalories />,
    name: 'Low Calories',
    value: RECIPE_DIETARY.LOW_CALORIES,
  },
  {
    icon: <LowCarbs />,
    name: 'Low Carbs',
    value: RECIPE_DIETARY.LOW_CARBS,
  },
  {
    icon: <LowProtein />,
    name: 'Low Protein',
    value: RECIPE_DIETARY.LOW_PROTEIN,
  },
  {
    icon: <LowSodium />,
    name: 'Low Sodium',
    value: RECIPE_DIETARY.LOW_SODIUM,
  },
  {
    icon: <LowSugar />,
    name: 'Low Sugar',
    value: RECIPE_DIETARY.LOW_SUGAR,
  },
  {
    icon: <NutFree />,
    name: 'Nut Free',
    value: RECIPE_DIETARY.NUT_FREE,
  },
  {
    icon: <Pescatarian />,
    name: 'Pescatarian',
    value: RECIPE_DIETARY.PESCATARIAN,
  },
  {
    icon: <ShellfishFree />,
    name: 'Shellfish Free',
    value: RECIPE_DIETARY.SHELLFISH_FREE,
  },
  {
    icon: <Vegan />,
    name: 'Vegan',
    value: RECIPE_DIETARY.VEGAN,
  },
  {
    icon: <Vegetarian />,
    name: 'Vegetarian',
    value: RECIPE_DIETARY.VEGETARIAN,
  },
];

export const RECIPE_DEFAULT_UPLOAD_SETTINGS = {
  maxSize: 25,
  validateExtensions: ['png', 'jpg', 'jpeg'],
  maxSizeMessage: 'Image size limit is 25MB.',
  acceptedImage: 'image/png, image/jpeg, image/jpg',
};

export const PER_PAGE = 20;

export const listMacronutrients = [
  { label: 'protein', unit: 'g', type: MACRO_NUTRIENTS.PROTEIN },
  { label: 'carbs', unit: 'g', type: MACRO_NUTRIENTS.CARBS },
  { label: 'fat', unit: 'g', type: MACRO_NUTRIENTS.FAT },
  { label: 'calories', unit: 'cal', type: MACRO_NUTRIENTS.CALORIES },
];

export const listOtherNutrients = [
  { label: 'saturated fat', unit: 'g', type: OTHER_NUTRIENTS.SATURATED_FAT },
  { label: 'sugar', unit: 'g', type: OTHER_NUTRIENTS.SUGAR },
  { label: 'fiber', unit: 'g', type: OTHER_NUTRIENTS.FIBER },
  { label: 'sodium', unit: 'mg', type: OTHER_NUTRIENTS.SODIUM },
  { label: 'Vitamin A', unit: 'mcg', type: OTHER_NUTRIENTS.VITAMIN_A },
  { label: 'vitamin C', unit: 'mg', type: OTHER_NUTRIENTS.VITAMIN_C },
  { label: 'Vitamin B1', unit: 'mg', type: OTHER_NUTRIENTS.THIAMINE },
  { label: 'Vitamin B2', unit: 'mg', type: OTHER_NUTRIENTS.RIBOFLAVIN },
  { label: 'Vitamin B3', unit: 'mg', type: OTHER_NUTRIENTS.NIACIN },
  { label: 'Vitamin B5', unit: 'mg', type: OTHER_NUTRIENTS.PANTOTHENIC_ACID },
  { label: 'Vitamin B6', unit: 'mg', type: OTHER_NUTRIENTS.PYRIDOXINE },
  { label: 'Vitamin B9', unit: 'mcg', type: OTHER_NUTRIENTS.FOLATE },
  { label: 'Vitamin B7', unit: 'mcg', type: OTHER_NUTRIENTS.BIOTIN },
  { label: 'Vitamin B12', unit: 'mcg', type: OTHER_NUTRIENTS.CYANOCOBALAMIN },
  { label: 'vitamin D', unit: 'mcg', type: OTHER_NUTRIENTS.VITAMIN_D },
  { label: 'vitamin E', unit: 'mg', type: OTHER_NUTRIENTS.VITAMIN_E },
  { label: 'vitamin K', unit: 'mcg', type: OTHER_NUTRIENTS.VITAMIN_K },
  { label: 'calcium', unit: 'mg', type: OTHER_NUTRIENTS.CALCIUM },
  { label: 'iron', unit: 'mg', type: OTHER_NUTRIENTS.IRON },
  { label: 'phosphorus', unit: 'mg', type: OTHER_NUTRIENTS.PHOSPHORUS },
  { label: 'iodine', unit: 'mcg', type: OTHER_NUTRIENTS.IODINE },
  { label: 'magnesium', unit: 'mg', type: OTHER_NUTRIENTS.MAGNESIUM },
  { label: 'zinc', unit: 'mg', type: OTHER_NUTRIENTS.ZINC },
  { label: 'selenium', unit: 'mcg', type: OTHER_NUTRIENTS.SELENIUM },
  { label: 'copper', unit: 'mg', type: OTHER_NUTRIENTS.COPPER },
  { label: 'manganese', unit: 'mg', type: OTHER_NUTRIENTS.MANGANESE },
  { label: 'chromium', unit: 'mcg', type: OTHER_NUTRIENTS.CHROMIUM },
  { label: 'molybdenum', unit: 'mcg', type: OTHER_NUTRIENTS.MOLYBDENUM },
  { label: 'chloride', unit: 'mg', type: OTHER_NUTRIENTS.CHLORIDE },
  { label: 'cholesterol', unit: 'mg', type: OTHER_NUTRIENTS.CHOLESTEROL },
  { label: 'potassium', unit: 'mg', type: OTHER_NUTRIENTS.POTASSIUM },
];

export const PER_PAGE_LOAD_MORE = 20;

export const SEE_MORE_HEIGHT = 71;

export const SEE_MORE_3_ROWS_LENGTH = 350;

export const SHORT_LENGTH = 155;

export const ENUM_UNIT = {
  DEFAULT: 'default',
  US_IMPERIAL: 'imperial',
  METRIC: 'metric',
};

export const ListUnit = [
  { key: ENUM_UNIT.DEFAULT, label: 'Default' },
  { key: ENUM_UNIT.US_IMPERIAL, label: 'Imperial US' },
  { key: ENUM_UNIT.METRIC, label: 'Metric' },
];

export const NUTRITION_TAB = {
  MACRO_NUTRIENTS: 'macro_nutrients',
  OTHER_NUTRIENTS: 'other_nutrients',
};

export const ENUM_TIME_PREP = {
  PREP_TIME: 'prep_time',
  COOKING_TIME: 'cooking_time',
};

export const ENUM_TIME = {
  HOUR: 'hour',
  MINUTE: 'minute',
};

export const MAX_VALUE_MINUTE = 59;

export const LIMIT_CHARACTER_NAME = 90;

export const LIMIT_CHARACTER_STEP_INSTRUCTION = 1000;

export const ENUM_DISH_TYPE = {
  MAIN_DISH: 'main_dish',
  SIDE_DISH: 'side_dish',
};

export const LIST_DISH_TYPE = [
  {
    label: 'Main dish',
    key: ENUM_DISH_TYPE.MAIN_DISH,
    icon: <MainDish />,
  },
  {
    label: 'Side dish',
    key: ENUM_DISH_TYPE.SIDE_DISH,
    icon: <SideDish />,
  },
];

export const bgDishType = {
  [ENUM_DISH_TYPE.MAIN_DISH]: '#FFF6EB',
  [ENUM_DISH_TYPE.SIDE_DISH]: '#E0FCE2',
};
