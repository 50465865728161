import React from 'react';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { resetPricingData } from 'redux/pricing/actions';
import { hideError, showError } from 'actions/error';
import { CDN_URL } from 'constants/commonData';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  background-color: #fff;
  align-items: center;
  box-shadow: inset 0px -1px 0px #eceef1;

  .heading__left {
    background: #f1f2f6;
    border-radius: 5px;
    padding: 10px 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100%;

    color: #000000;
  }

  .heading__center {
    .heading__step {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 100%;
      color: #202353;
      opacity: 0.6;
      padding-right: 21px;

      :first-child {
        cursor: pointer;
      }

      &--active {
        color: #6261ff;
        opacity: 1;
      }
    }
    .is_zapier {
      :first-child {
        cursor: not-allowed;
      }
    }
  }

  .heading__right {
    width: 200px;
    text-align: right;
    img {
      cursor: pointer;
    }
  }
`;

function PricingPlanHeading(props) {
  const { zapierPath, downgrade, onBack, selectPackage } = props;
  const { period = '' } = selectPackage || {};

  const isZapier = zapierPath === '/home/integration';
  const onlyMonthlyZapier = isZapier && period === 'monthly';
  const onlyMonthlyZapierUpgrade = onlyMonthlyZapier && !downgrade;

  const closePopup = () => {
    const params = new URLSearchParams(window.location.search);
    const isMP = window.location.pathname.includes('marketplace');
    const path = isMP ? 'marketplace' : 'packages';
    const isMarketplacePayment = get(props, 'permission.marketplace_payment', false);
    if (isZapier) {
      props.push('/home/integration');
    } else {
      if (params.get('prev')) {
        if (params.get('prev').indexOf(`/home/${path}/create`) > -1) {
          props.push(`/home/${path}`);
        } else if (!isMarketplacePayment && params.get('prev').includes('marketplace')) {
          props.dispatch(push('/home/packages'));
          props.dispatch(
            showError(
              'Please check with your workspace admin for permission to use this feature',
              null,
              null,
              null,
              null,
              () => props.dispatch(hideError()),
            ),
          );
        } else {
          props.push(params.get('prev'));
        }
      } else {
        props.push('/home/client');
      }
    }

    props.resetPricingData();
  };

  const handleBack = () => {
    if (!onlyMonthlyZapierUpgrade && typeof onBack === 'function') {
      onBack();
    }
  };

  return (
    <Wrapper {...props}>
      <div className="heading__left">
        <span>Clients in Workspace: </span>
        <strong>{props.teamData.noClients || 0}</strong>
      </div>
      <div className="heading__center">
        <span
          onClick={handleBack}
          className={classNames('heading__step', {
            'heading__step--active': props.step === 1,
            is_zapier: onlyMonthlyZapierUpgrade,
          })}
        >
          1. Choose Plan
        </span>
        <span className="heading__step">
          <img src={`${CDN_URL}/images/slide_arrow_right_grey.svg`} alt="" />
        </span>
        <span className={classNames('heading__step', { 'heading__step--active': props.step === 2 })}>
          2. Confirm Payment
        </span>
      </div>
      <div className="heading__right">
        <img src={`${CDN_URL}/images/remove_icon.svg`} onClick={closePopup} alt="" />
      </div>
    </Wrapper>
  );
}

const mapState = state => {
  const { rootReducer } = state;

  return {
    permission: rootReducer.permission,
    teamData: rootReducer.pricing.get('teamData').toJS(),
    selectPackage: rootReducer.pricing.get('selectPackage').toJS(),
  };
};

const actionCreators = dispatch => {
  return {
    dispatch,
    push: bindActionCreators(push, dispatch),
    resetPricingData: bindActionCreators(resetPricingData, dispatch),
  };
};

export default connect(mapState, actionCreators)(PricingPlanHeading);
