import styled, { css } from 'styled-components';

import { FallbackWrapper } from 'shared/UpgradePath/style';
import UpgradePathButton from 'shared/UpgradePath/components/UpgradePathButton';

export const Section = styled.div`
  padding: 0;
  font-size: 14px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  color: #000000;
  z-index: 2;
  font-size: 20px;
  line-height: 150%;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  display: block;
  width: 316px;
  color: #000000;
  margin: auto;
  opacity: 0.6;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
`;

export const ItemLink = styled.a`
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const ItemButton = styled.button`
  display: block;
  padding: 11px 13px;
  border: none;
  cursor: pointer;
  outline: none;
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 100%;
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }
`;

export const FallbackWrapperS = styled(FallbackWrapper)`
  overflow: hidden;
  padding-top: 20px;
`;

export const ComingSoon = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.18px;
  padding: 8px 67px;
  margin-bottom: 59px;
  background: #f7f7f7;
  border-radius: 4px;
  align-items: center;
`;

export const ComingText = styled.span`
  padding-left: 8px;
`;

export const SUpgradePathButton = styled(UpgradePathButton)`
  background: linear-gradient(87.08deg, #fdc830 -18.89%, #f37335 99.03%);
  border-radius: 3px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 100%;
  display: flex;
  border: none;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  :hover {
    background: linear-gradient(74.79deg, #fdc830 -18.69%, #f37335 182.76%);
  }

  width: 226px;
  padding: 13.5px 13px;

  span {
    display: block;
    margin: auto;
  }
`;

export const UpgradePathImg = styled.img`
  width: 75%;
  height: 630px;
  object-fit: contain;
`;

export const UpgradePathBackground = styled.div`
  background-image: ${({ src }) => `url(${src})`};
  width: 674px;
  height: 442px;
  margin-top: 16px;
  margin-bottom: 24px;

  background-size: cover;
  background-repeat: no-repeat;
  &.second {
    ${'' /* width: 600px;
    margin-bottom: 83px;
    height: 400px; */}
    position: relative;
    ::after {
      content: '';
      width: 600px;
      height: 90px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      position: absolute;
      bottom: -3px;
    }
  }

  @media only screen and (max-height: 768px) {
    height: auto;
    aspect-ratio: 1.524;
    width: 50%;
  }

  @media only screen and (max-height: 700px) {
    height: auto;
    aspect-ratio: 1.524;
    width: 45%;
  }

  @media only screen and (max-height: 638px) {
    height: auto;
    aspect-ratio: 1.524;
    width: 40%;
  }
`;

export const RequestCountryLink = styled.span`
  color: #5158cf;
`;

export const Dots = styled.div`
  width: 10px;
  height: 10px;
  padding: 0;
  border: 1px solid #e8ebed;
  border-radius: 50%;

  &:before {
    content: none;
  }
`;

export const Slider = styled.div`
  display: block;

  .slick-dots {
    ${'' /* top: 47px; */}

    .slick-active {
      ${Dots} {
        background: #5c5bbd;
        border: none;
      }
    }

    li {
      width: initial;
      height: initial;
      margin: 0 4px;
    }
  }

  .slick-list {
    ${'' /* top: 72px; */}
    z-index: 1;
    overflow: visible;
  }
  .slick-slider {
    height: calc(100vh - 140px);
  }
`;
