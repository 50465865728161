import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import CustomBranding from './component';
import { getCustomBrandingData, revokeLocalURL, changeActiveTab } from 'redux/custom-branding/actions';
import { maximizeCheckList, updateStepOnboarding } from 'redux/onboarding/actions';
import { toggleConfirmModal } from 'actions/modal';

const mapState = state => ({
  user: state.user,
  sideBarVisible: state.sideBarVisible,
  onboarding: state.rootReducer.onboarding,
  premiumCustomBrand: state.rootReducer.permission.premiumCustomBrand,
  currentActiveTab: state.rootReducer.customBranding.currentTab,
});

const mapDispatch = dispatch => ({
  getCustomBrandingData: bindActionCreators(getCustomBrandingData, dispatch),
  revokeLocalURL: bindActionCreators(revokeLocalURL, dispatch),
  updateStepOnboarding: bindActionCreators(updateStepOnboarding, dispatch),
  maximizeCheckList: bindActionCreators(maximizeCheckList, dispatch),
  push: bindActionCreators(push, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
});

export default connect(mapState, mapDispatch)(CustomBranding);
