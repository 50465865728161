import get from 'lodash/get';
import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';

import Menu from './Menu';
import SelectClientOption from './Option';
import CustomControl from './CustomControl';

import * as S from './style';

function SearchParticipant(props) {
  const {
    onChange,
    loadOptions,
    name,
    defaultOptions,
    placeholder,
    emptyIcon,
    emptySearchMessage = 'No contacts found',
    disabled,
    autoFocus,
    autoOpen,
    value,
    className,
    classNamePrefix,
    isMulti = true,
  } = props;

  const renderEmptyContactsList = () => (
    <S.EmptyWrapper className="search-participant-empty">
      {emptyIcon && emptyIcon}
      <S.EmptyMessage>{emptySearchMessage}</S.EmptyMessage>
    </S.EmptyWrapper>
  );

  const renderMultiValueLabel = props => {
    const isGroup = get(props, 'data.typeName', '') === 'group';
    return (
      <components.MultiValueLabel {...props}>
        {isGroup ? (
          <>
            <strong>Group:&nbsp;</strong>
            <span>{props.children}</span>
          </>
        ) : (
          props.children
        )}
      </components.MultiValueLabel>
    );
  };

  const SingleValue = ({ innerProps, data }) => {
    return (
      <S.ClientName {...innerProps}>
        {data.first_name} {data.last_name}
      </S.ClientName>
    );
  };

  return (
    <AsyncSelect
      key={JSON.stringify(value)}
      defaultMenuIsOpen={autoOpen}
      isMulti={isMulti}
      isSearchable
      onChange={onChange}
      name={name}
      className={className}
      classNamePrefix={classNamePrefix}
      isDisabled={disabled}
      isOptionDisabled={disabled}
      isClearable={true}
      placeholder={placeholder}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      autoFocus={autoFocus}
      styles={customStyles}
      value={value}
      noOptionsMessage={renderEmptyContactsList}
      getOptionLabel={option => option.label}
      getOptionValue={option => option._id}
      menuPlacement="auto"
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: null,
        ClearIndicator: null,
        Menu,
        Option: SelectClientOption,
        MultiValueLabel: renderMultiValueLabel,
        SingleValue,
        Control: CustomControl,
      }}
    />
  );
}

export const customStyles = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    flex: 1,
    '&.select-participants': {
      border: '1px solid #dcdcde',
      borderColor: state.isFocused ? '#726ee4' : '#dcdcde',
    },
    '&.select-participants::-webkit-scrollbar': {
      width: '4px !important',
    },
  }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    minHeight: 30,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    '.scroll-container': {
      flex: 1,
      maxHeight: '94px',
      overflow: 'hidden',
    },
    '.scroll-wrapper': {
      flex: 1,
      flexWrap: 'wrap',
      overflow: 'auto',
      maxHeight: '94px',
    },
  }),
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    padding: 0,
    gap: 5,
    marginTop: 5,
    // maxHeight: 82,
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(196, 196, 196, 0.4) !important',
    },
  }),
  menu: (baseStyle, state) => ({
    ...baseStyle,
    marginTop: 5,
    marginBottom: 'unset',
    padding: 10,
    width: 382,
    borderRadius: 5,
    backgroundColor: '#fff',
    border: '1px solid #DCDCDE',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.07)',
  }),
  menuList: (baseStyle, state) => ({
    ...baseStyle,
    height: 255,
    maxHeight: 205,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    '::-webkit-scrollbar-thumb': {
      background: 'rgba(196, 196, 196, 0.4) !important',
    },
    '::-webkit-scrollbar': {
      width: '4px !important',
    },
    '.multi-select__option': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '.client-avatar': {
      marginLeft: 0,
    },
    '.name b': {
      fontWeight: 700,
    },
  }),
  option: (baseStyle, state) => ({
    ...baseStyle,
    backgroundColor: state.isFocused ? '#f4f4ff !important' : '#fff',
    ':hover': {
      backgroundColor: '#f4f4ff',
    },
    cursor: 'pointer',
    maxHeight: 41,
    padding: '8px 15px',
    borderRadius: 5,
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '18px',
    color: 'rgba(0,0,0,0.2)',
  }),
  multiValue: (baseStyle, state) => ({
    ...baseStyle,
    background: '#E7E7FD',
    borderRadius: 4,
    margin: 0,
  }),
  multiValueLabel: (baseStyle, state) => ({
    ...baseStyle,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '12px',
    borderRadius: 4,
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '10px',
    paddingRight: 0,
  }),
  multiValueRemove: (baseStyle, state) => ({
    ...baseStyle,
    color: '#5D5BBE',
    cursor: 'pointer',
    paddingRight: 5,
    borderRadius: 4,
    ':hover': {
      color: '#5D5BBE',
      background: '#E7E7FD',
    },
  }),
};

export default SearchParticipant;
