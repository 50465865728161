import React from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { Button } from 'shared/FormControl';
import './style.scss';

function ClientSignUpWarning(props) {
  return (
    <Modal open={true} closeOnDimmerClick={false} className="modal--client-signup-warning">
      <Modal.Content>
        <Container>
          <Header>Hi there!</Header>
          <Content>
            Please wait for your trainer's invitation and sign up through the Everfit mobile app. We have an
            awesome experience awaiting you.
          </Content>
          <Button purple onClick={props.onConfirm}>
            Got it
          </Button>
        </Container>
      </Modal.Content>
    </Modal>
  );
}

export default ClientSignUpWarning;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #191753;
  margin-bottom: 5px;
`;

const Content = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #191753;
  margin-bottom: 40px;
`;

const Container = styled.div`
  padding: 30px;
  text-align: center;

  ${Button} {
    padding: 11px 55px;
  }
`;
