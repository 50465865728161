import React, { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

import UpgradePath from 'shared/UpgradePath';
import ProductDetail from './ProductDetail/component';
import ProductList from './ProductList';
import ProductUpgradePath from './ProductUpgradePath';
import { handleCloseDropdown } from './helper';
import { genTokenFromMarketplace, getCookie } from 'utils/commonFunction';
import { C_KEY_TOKEN } from 'constants/commonData';

import * as S from './style';

const isDetailPage = params => {
  return params.productId ? true : false;
};

const Product = props => {
  const {
    match: { params },
    addNewProduct,
    saveProductDraft,
    location,
    hideProductBanner,
    is_hide_banner_product,
    user,
    push,
    counter,
    loading,
    productDetail,
    isEditMode,
    getPreSignedUrl,
    getListProduct,
    product,
    total,
    loadMoreProduct,
    query,
    getProductDetail,
    getProductEdit,
    toggleConfirmModal,
    updateProduct,
    isSubmitting,
    archiveProduct,
    deleteProduct,
    unArchiveProduct,
    duplicateProduct,
    publishProduct,
    updateQuery,
    duplicating,
    permission,
    isUploading,
    startUploadCoverImage,
    endUploadCoverImage,
    isEditingDuplicate,
    resetDuplicate,
    // MP
    dispatch,
    location: { search, pathname },
  } = props;

  const isHideBanner = is_hide_banner_product || false;
  const isDetailRoute = useMemo(() => isDetailPage(params), [params]);

  useEffect(() => {
    if (!permission.payment || search.includes('redirect-platform')) return;

    getListProduct();
    // Get detail product
    if (isDetailRoute) {
      if (params.productId === 'create') {
        push('/home/sequences');
      } else {
        getProductDetail(params.productId);
      }
    }
  }, [search]);

  const handleLoadMore = event => {
    if (!isDetailRoute) {
      const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight;
      if (bottom && product.length < total && !loading && !query.isEnd) {
        loadMoreProduct();
      }
    }
  };

  const onScrollDebounce = debounce(handleLoadMore, 300);

  const handleScroll = event => {
    // TODO - Close Dropdown
    handleCloseDropdown(event);

    event.persist();
    onScrollDebounce.call(null, event);
  };

  const productData = useMemo(() => (productDetail ? productDetail.toJS() : null), [productDetail]);

  // MP
  useEffect(() => {
    const token = getCookie(C_KEY_TOKEN);
    if (token && search.includes('redirect-platform')) {
      genTokenFromMarketplace({ token, user, pathname, search, dispatch });
      return;
    }
  }, [pathname, search]);

  return (
    <UpgradePath pathName="payment" fallback={<ProductUpgradePath />}>
      <S.Wrapper id="productDetail" onScroll={event => handleScroll(event)}>
        {isDetailRoute ? (
          <ProductDetail
            isSubmitting={isSubmitting}
            user={user}
            toggleConfirmModal={toggleConfirmModal}
            push={push}
            location={location}
            isEditMode={isEditMode}
            productDetail={productData}
            saveProductDraft={saveProductDraft}
            getPreSignedUrl={getPreSignedUrl}
            updateProduct={updateProduct}
            publishProduct={publishProduct}
            getProductDetail={getProductDetail}
            archiveProduct={archiveProduct}
            deleteProduct={deleteProduct}
            unArchiveProduct={unArchiveProduct}
            duplicateProduct={duplicateProduct}
            duplicating={duplicating}
            updateQuery={updateQuery}
            getListProduct={getListProduct}
            isUploading={isUploading}
            isEditingDuplicate={isEditingDuplicate}
            startUploadCoverImage={startUploadCoverImage}
            endUploadCoverImage={endUploadCoverImage}
            resetDuplicate={resetDuplicate}
          />
        ) : (
          <ProductList
            loading={loading}
            product={product}
            onHideProductBanner={hideProductBanner}
            isHideBanner={isHideBanner}
            counter={counter}
            onAddNewProduct={addNewProduct}
            onGetListProduct={getListProduct}
            query={query}
            push={push}
            onGetProductEdit={getProductEdit}
            getProductDetail={getProductDetail}
            archiveProduct={archiveProduct}
            deleteProduct={deleteProduct}
            unArchiveProduct={unArchiveProduct}
            duplicateProduct={duplicateProduct}
            duplicating={duplicating}
            productTotal={total}
            toggleConfirmModal={toggleConfirmModal}
            updateQuery={updateQuery}
          />
        )}
      </S.Wrapper>
    </UpgradePath>
  );
};

export default Product;
