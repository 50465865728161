import React, { useMemo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import { DateTime } from 'luxon';

import ButtonRemoveWeek from './ButtonRemoveWeek';
import ButtonCopyWeek from './ButtonCopyWeek';
import * as S from '../style';
import { formatDateSafari } from 'utils/commonFunction';

function CalendarActions(props) {
  const { selectedWeek, selectedWorkouts, permission, assignments, weekIndex, startDate } = props;
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V2 || !get(permission, 'copy_week')) return <></>;
  if (!isEmpty(selectedWeek) || !isEmpty(selectedWorkouts)) return <></>;
  const isAvailableCopyWeek = useMemo(() => {
    const day = DateTime.fromISO(new Date(formatDateSafari(startDate)).toISOString());
    for (let dayIdx = 0; dayIdx < 7; dayIdx++) {
      const date = day.plus({ days: 7 * weekIndex + dayIdx });
      const foundIndex = findIndex(assignments, it => isEqual(get(it, 'day'), date.toFormat('MM-dd-yyyy')));
      if (get(assignments[foundIndex], 'workouts', []).length > 0) return true;
    }
    return false;
  }, [JSON.stringify(assignments), selectedWeek, weekIndex, startDate]);
  if (!isAvailableCopyWeek) return <></>;
  return (
    <S.CopyWrapper>
      <ButtonRemoveWeek {...props} />
      <ButtonCopyWeek {...props} />
    </S.CopyWrapper>
  );
}

export default CalendarActions;
