import styled, { css } from 'styled-components';
import { Button } from 'shared/FormControl';

export const ListLabel = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: #6a778a;
  margin-bottom: 10px;
`;

export const WorkoutTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #151515;
  margin-bottom: 5px;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;
`;

export const WorkoutSummary = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #828180;
`;

export const WorkoutBaseInfo = styled.div`
  padding-right: 15px;
  flex: 1 1;
  overflow: hidden;
`;

export const WorkoutExtendedInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 52px;

  .last-used {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #66717e;
  }

  .sb-avatar {
    opacity: 0.4;

    :hover {
      opacity: 1;
    }
  }
`;

export const Item = styled.div`
  padding: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
  .main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tags-container {
    margin: 10px -4px 0px -4px;
  }
  border-bottom: 1px solid #f0f0f0;

  :hover {
    background: #f8f8f8;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ${({ hasSeeMore, isSeeMore }) =>
    hasSeeMore &&
    !isSeeMore &&
    css`
      padding-bottom: 0px;
      position: relative;
      .tags-container {
        max-height: 88px;
      }
    `}

  ${({ hasScroll, isSeeMore }) =>
    hasScroll &&
    isSeeMore &&
    css`
      .tags-container {
        max-height: 133px;
        overflow-y: scroll;
      }
    `}

 

  &.selecting {
    background: #ecebff;
    color: #5158cf;

    ${WorkoutTitle} {
      font-weight: bold;
      color: #5158cf;
    }

    ${WorkoutSummary} {
      color: #7072dd;
    }
  }
`;

export const List = styled.div`
  position: relative;

  .workoutList {
    position: absolute;
    opacity: ${props => (props.loading ? 0 : 1)};
    transition: opacity 0.3s ease-in-out;
    width: 100%;
  }
  .workoutList__loader {
    opacity: ${props => (props.loading ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    height: ${props => (props.loading ? 'unset' : 0)};
    overflow: hidden;
    width: 100%;
    background-color: #ffffff;
  }
`;

export const ListContainer = styled.div`
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #f0f0f0;
  border-bottom: none;
  overflow-y: overlay;
  overflow-x: hidden;
  flex: 1 1;

  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2) !important;
  }
`;

export const LoadingItem = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px -1px 0px #f0f0f0;
`;

export const LoadingWrapper = styled.div``;

export const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #151515;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actions,
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .actions {
    justify-content: flex-end;
    gap: 8px;
  }

  .back {
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const WorkoutListWrapper = styled.div`
  width: 430px;
  padding: 0 50px 0 30px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &.with-ai-btn {
    padding: 0 30px;
    width: 428px;

    ${Button} {
      width: 98px;

      &.everfit-ai-btn {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;

        svg {
          margin-right: 6px !important;
        }
      }
      &.create-new {
        padding: 6px 12px;
      }
    }
  }

  .ui.input.search-input {
    width: 100% !important;
    margin-bottom: 20px;
    height: 36px !important;
    border-color: #f0f0f0;
    border-radius: 5px !important;

    > input {
      background-color: #f8f8f8 !important;
      background-size: 12px !important;
      padding-left: 37px !important;

      ::placeholder {
        font-size: 12px;
        color: #777;
        opacity: 0.5;
      }

      :focus {
        background-color: transparent !important;
        border-color: #5c5bbd;
      }
    }
  }
`;

export const Content = styled.div`
  flex: 1 1;
  overflow: hidden;
  height: 510px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Actions = styled.div`
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f0f0f0;
`;

export const Wrapper = styled.div`
  ${Button} {
    &.save {
      padding: 6px 54px;
    }

    &.create-new {
      border: 1px solid #5158cf;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: transparent;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #5158cf;
      padding: 6px 20px;

      img {
        margin-right: 10px;
      }
    }
  }
`;

export const TagsFilterTrigger = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 93px;
  ${'' /* padding: 10px 20px; */}
  background: #f8f8f8;
  border: 1px solid #d4d7d9;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  ${'' /* line-height: 100%; */}
  color: #777777;
  svg {
    margin-right: 10px;
  }
  .tags-filter-btn-title {
    transform: translate(-1px, 0px);
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  .exercise-filter {
    height: 36px;
    margin-left: 5px;
    margin-right: 0px;
  }
`;

export const TagItem = styled.div`
  display: inline-block;
  padding: 4px 8px;
  background: #f3f5f8;
  border: 1px solid #e0e5ed;
  border-radius: 453px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #818da1;
  margin: 4px;
  cursor: pointer;
`;

export const SeeMore = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  position: absolute;
  width: 100%;
  height: 48px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  top: calc(100% - 48px);
  left: 0px;
  padding-top: 24px;
  display: flex;
  justify-content: center;
  svg {
    margin-right: 8px;
  }
  :hover {
    color: #5e59ff;
    svg path {
      fill: #5e59ff;
    }
  }
`;
