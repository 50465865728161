import React, { useMemo, useState, useRef, useEffect } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';

import { ReactComponent as GroupTagIcon } from 'assets/icons/filter_group_tag.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/filter_close.svg';
import ItemGroup from './ItemGroup';
import LoadingIndicator from 'shared/LoadingIndicator';
import * as S from './style';

const GroupFilter = props => {
  const { filterGroup, setFilterGroup, groups, disable, loading = false, afterSelect } = props;
  const [open, setOpen] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const inputRef = useRef();
  const scrollWrapperRef = useRef();

  const selectedValueLength = useMemo(() => filterGroup.length, [filterGroup]);
  const isAll = useMemo(() => isEmpty(filterGroup), [filterGroup]);
  const title = useMemo(() => {
    if (size(filterGroup) > 1) return `${size(filterGroup)} Groups`;
    else if (size(filterGroup) === 1) return get(filterGroup, '[0].name', 'All');
    else return 'All';
  }, [filterGroup]);
  const selectedGroupIds = useMemo(() => map(filterGroup, '_id'), [filterGroup]);
  const filterGroups = useMemo(() => {
    const search = textSearch.trim().toLowerCase();
    return filter(groups, it => it.name.toLowerCase().includes(search) && !selectedGroupIds.includes(it._id));
  }, [groups, textSearch, selectedGroupIds]);

  useEffect(() => {
    if (scrollWrapperRef.current && selectedValueLength > 0) {
      const childrenHeight = get(scrollWrapperRef, 'current.children[0].offsetHeight', 0);
      scrollWrapperRef.current.scrollTo(0, childrenHeight);
    }
  }, [selectedValueLength]);

  const onSearchClick = () => {
    inputRef.current && inputRef.current.focus();
  };

  const handleChangeInput = event => {
    event.preventDefault();
    setTextSearch(get(event, 'target.value', ''));
  };

  const calculatorInputWidth = () => {
    let width = 2;
    forEach(textSearch, character => {
      width += character === ' ' ? 3 : 10;
    });

    return width + 'px';
  };

  const handleItemClick = item => {
    setFilterGroup(it => [...it, item]);
    setTextSearch('');
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
    afterSelect && afterSelect();
  };

  const removeSelectedGroup = removeId => {
    setFilterGroup(it => it.filter(g => g._id !== removeId));
    afterSelect && afterSelect();
  };

  const handleToggle = () => {
    if (disable) return;
    setOpen(it => !it);
  };

  const handleClose = () => {
    setOpen(false);
    setTextSearch('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <RootCloseWrapper onRootClose={handleClose} event="click" disabled={!open}>
      <S.Wrapper>
        <div className={classNames('filter__dropdown', { active: open, disable: disable })} onClick={handleToggle}>
          <div className="filter__dropdown__title">
            {isAll ? (
              <>
                <span className="filter-name">Group</span>
                <span className="colons">:</span>
                <span className="selected">{title}</span>
              </>
            ) : (
              <>
                <GroupTagIcon className="icon" />
                <span className="text">{title}</span>
              </>
            )}
          </div>
        </div>

        {open && (
          <S.MenuContainer>
            <S.SearchInputContainer onClick={onSearchClick}>
              <div className="scroll-container">
                <div className="scroll-wrapper" ref={scrollWrapperRef}>
                  <div className="selected-list">
                    {map(filterGroup, it => (
                      <S.SelectedItem key={it._id}>
                        <span className="text">{it.name}</span>
                        <CloseIcon className="icon" onClick={removeSelectedGroup.bind(null, it._id)} />
                      </S.SelectedItem>
                    ))}
                    <S.SearchInput isAll={isAll}>
                      <input
                        ref={inputRef}
                        onChange={handleChangeInput}
                        style={{ width: calculatorInputWidth() }}
                        autoFocus
                      />
                    </S.SearchInput>
                    {isEmpty(filterGroup) && isEmpty(textSearch) && <S.Placeholder>Enter group</S.Placeholder>}
                  </div>
                </div>
              </div>
            </S.SearchInputContainer>
            <S.ScrollContainer>
              <S.ScrollWrapper hiddenScrollBar={filterGroups.length <= 4}>
                {map(filterGroups, it => (
                  <ItemGroup key={it._id} {...it} onClick={() => handleItemClick(it)} />
                ))}
                {isEmpty(filterGroups) && !loading && (
                  <S.EmptyResultFound>
                    <div className="text">{textSearch ? 'No results found.' : 'No groups found.'}</div>
                  </S.EmptyResultFound>
                )}
                {loading && (
                  <S.LoadingBox>
                    <LoadingIndicator className="group-loading" />
                  </S.LoadingBox>
                )}
              </S.ScrollWrapper>
            </S.ScrollContainer>
          </S.MenuContainer>
        )}
      </S.Wrapper>
    </RootCloseWrapper>
  );
};

export default GroupFilter;
