import ConfirmModal from 'shared/ConfirmModal';
import styled from 'styled-components';

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
    height: 192px !important;
    border-radius: 8px;
    background: #fff !important;
    .confirm-content-header {
      padding: 31px 30px 0;
      .confirm-header-label {
        line-height: 150%;
        color: #202353;
        font-size: 21px;
      }
      .confirm-header-image {
        width: 25px;
        height: 25px;
        border: unset;
      }
    }
    .confirm-content-body {
      padding: 25px 30px 25px;
    }
    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 6px 0 4px;
        font-size: 12px;
        margin-left: 8px !important;
      }
      .confirm-no-button {
        width: 89px;
        min-width: 89px;
        :hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        min-width: 146px;
        box-shadow: unset !important;
        :hover {
          background-color: #ec465c !important;
        }
      }
    }
  }
`;

export const RemoveConfirmModal = styled(CustomConfirmModal)`
  &.ui.modal.confirm-popup-container {
    width: 459px !important;
    height: 180px !important;
    min-height: 180px !important;
    border-radius: 8px;
    background: #fff !important;

    .confirm-content-header {
      padding: 29px 32px 0;
      .confirm-header-label {
        font-size: 18px;
      }
    }

    .confirm-content-body {
      padding: 15px 31px 30px;
    }

    .confirm-actions {
      > button {
        font-family: 'Open Sans';
        border-radius: 5px;
        padding: 6px 0 4px;
        font-size: 12px;
        margin-left: 8px !important;
        font-size: 13px !important;
      }

      .confirm-no-button {
        width: 102px;
        min-width: 30px;
        padding: 5px 30px 5px 30px;

        :hover {
          background-color: #f5f7f9 !important;
        }
      }

      .confirm-yes-button {
        width: 111px;
        min-width: 111px;
        height: 30px;
        padding: 5px 30px 5px 26px;
        box-shadow: unset !important;

        :hover {
          background-color: #ec465c !important;
          opacity: 0.9;
        }
      }
    }
  }
`;
