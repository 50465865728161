import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  .studio-program-detail__name {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    color: #202353;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;

    .__react_component_tooltip.tooltip--latest-edit-by {
      width: 193px;
      padding: 15px;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .studio-program-detail__nav-menu {
    margin-top: 0;

    .studio-program-detail__nav-menu__item {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      padding-bottom: 9px;
      color: #858585;
      position: relative;
      margin: 0 18px;
      text-decoration: none !important;

      :first-child {
        margin-left: 0;
      }

      ::after {
        content: '';
        position: absolute;
        display: none;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #5158cf;
        left: 0;
      }

      &.active {
        color: #5158cf;

        ::after {
          display: block;
        }
      }
    }
  }
`;

export const StudioProgramStatusWrapper = styled.span`
  background: #ebedf4;
  border: 1px solid #d8dceb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 9px;
  margin-left: 10px;

  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #202353;

  ${(props) =>
    props.isPublished &&
    css`
      color: #36b24b;
      background: #dff5e1;
      border-color: #36b24b;
      border-radius: 5px;
    `}
`;

export const LastEditBy = styled.div`
  border: 1px solid #d8dceb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 9px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #202353;
  text-align: center;
  margin-left: 5px;
`;
