import styled from 'styled-components';
import { Arrow } from '../DateRangePicker/styles';
import { CDN_URL } from 'constants/commonData';

export const CalendarIcon = styled.div`
  width: 16px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url(${CDN_URL}/images/calendar_grey.svg);
`

export const Container = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
  background-color: #fff;
  padding: 12px 15px 15px;

  label {
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a778a;
    text-transform: uppercase;
    margin-bottom: 8px;
    display: block;
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;

    > span {
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin: 0 28px 0 5px;
    }

    button.button {
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
      background-color: #5c5bbd;
      padding: 7px 25px;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      margin: 0;
    }
  }

  .ui.dropdown.selection {
    border-radius: 4px !important;
    min-width: 57px;
    min-height: unset;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: normal;
    color: #858585;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    > i.icon {
      text-align: right;
      margin: 0;
      font-size: 11px;
      line-height: 11px;
    }

    .menu {
      top: calc(100% + 4px);
      
      > .item {
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #222;
        padding: 8px 12px!important;
        text-align: left;

        &.selected,
        :hover {
          color: #5c54c3;
          background-color: #ececfa;
        }
      }
    }

    :hover,
    &.active {
      border-color: #5c5bbd !important;
      border-bottom-left-radius: 4px!important;
      border-bottom-right-radius: 4px!important;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;

  .weeks {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 25px;

    button {
      background: none !important;
      padding: 12px 0px !important;
      margin: 0px !important;
    }

    ${Arrow} {
      width: 15px;
    }
  }

  .weeks-range {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 12px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #273d52;

    ${CalendarIcon} {
      margin-right: 12px;
    }

    :hover {
      color: #5c5bbd;
      ${CalendarIcon} {
        background-image: url(${CDN_URL}/images/calendar_purple.svg);
      }
    }
  }
`;