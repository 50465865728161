import React from 'react';
import OverdueZapier from './OverdueZapier';
import { connect } from 'react-redux';

function OverdueZapierPopup(props) {
  const pathname = props.location.pathname;

  if (!pathname.startsWith('/home/integration')) {
    return null;
  }

  return <OverdueZapier />;
}

const mapStateToProps = state => {
  const { user, router, rootReducer } = state;

  return {
    user,
    location: router.location,
    teamData: rootReducer.pricing.get('teamData').toJS(),
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OverdueZapierPopup);
