import { CDN_URL } from 'constants/commonData';
import styled, { css } from 'styled-components';

export const TittleRepliedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 4px;
  cursor: default;
`;

export const TittleReplied = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
  opacity: 0.5;
`;

export const MessageRepliedText = styled.div`
  display: flex;
  align-items: center;
  max-width: 495px;
  padding: 8px 18px 12px;
  background: #f7f9fa;
  border-radius: 16px 16px 0px 16px;
  margin-bottom: -8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  ${props =>
    !props.isOwner &&
    css`
      border-radius: 16px 16px 16px 0px;
    `}

  > div {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #65676b;
    overflow: hidden;

    > a {
      color: #65676b;

      &:hover {
        color: #65676b;
      }
    }
  }

  ${props =>
    props.isDeleted &&
    css`
      cursor: pointer;
      > div {
        font-family: 'Open Sans';
        font-style: italic;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #818da1;
        overflow: visible;
      }

      > svg {
        margin-left: 8px;
      }
    `}

  ${props =>
    props.showRecord &&
    css`
      * {
        cursor: not-allowed;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default !important;
      * {
        cursor: default !important;
      }
    `}
`;

export const MessageRepliedMedia = styled.div`
  position: relative;
  max-height: 148px;
  max-width: 148px;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: -8px;
  cursor: pointer;

  img,
  video {
    max-height: 148px;
    max-width: 148px;
    opacity: 0.5;
    object-fit: cover;
  }

  .image-with-fallback {
    max-height: 148px;
    max-width: 148px;
  }

  ${props =>
    props.showRecord &&
    css`
      * {
        cursor: not-allowed;
      }
    `}
`;

export const VideoPlayButton = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4) url(${CDN_URL}/images/play_icon_white.svg) no-repeat center;
  background-size: 23px;
`;
