// Lib
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import debounce from 'lodash/debounce';

// Constants
import { axiosInstance } from 'configs/request';
import { TITLE_HEIGHT_ONE_LINE } from 'components/FormDetails/constants';

// Assets
import * as S from '../styles';

export default function DateAnswerSummary({ data, dateFormat }) {
  const { _id, question_original_id: originalQuestionId = '' } = data || {};

  const [answers, setAnswers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [titleHeight, setTitleHeight] = useState(TITLE_HEIGHT_ONE_LINE);

  const total = useRef(0);

  useEffect(() => {
    if (!loading && originalQuestionId !== 'null') {
      getAnswers();
    }
  }, [page]);

  useEffect(() => {
    updateTitleHeight();
    window.addEventListener('resize', updateTitleHeight);
    return () => window.removeEventListener('resize', updateTitleHeight);
  }, []);

  const getAnswers = () => {
    setLoading(true);
    axiosInstance({
      url: `api/form-questions/${originalQuestionId}/text-answers`,
      method: 'GET',
      params: {
        page,
        per_page: 20,
        sort: -1,
      },
    })
      .then(res => {
        const { data: { data = {} } = {} } = res || {};
        total.current = data.total;
        const newAnswers = [...answers, ...data.data];
        if (newAnswers.length === data.total) {
          setIsEnd(true);
        }
        setAnswers(newAnswers);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScrollEnd = event => {
    if (event.target) {
      const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 5;
      if (bottom && !isEnd && !loading) {
        setPage(page + 1);
      }
    }
  };

  const onScrollDebounce = debounce(handleScrollEnd, 300);

  const updateTitleHeight = () => {
    const headerElement = document.getElementById(`${_id}-header`);
    if (headerElement) {
      setTitleHeight(headerElement.offsetHeight);
    }
  };

  return (
    <S.DateAnswerSummary
      onScroll={event => {
        event.persist();
        onScrollDebounce.call(null, event);
      }}
      titleHeight={titleHeight}
    >
      {answers.map(answer => {
        const { _id = '', date_answer = '' } = answer || {};

        return (
          <S.DateAnswerItem key={_id} readOnly>
            {moment(date_answer).utc().format(dateFormat)}
          </S.DateAnswerItem>
        );
      })}
    </S.DateAnswerSummary>
  );
}
