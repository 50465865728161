import styled from 'styled-components';

export const BulkActionWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const BulkActionButton = styled.button`
  display: flex;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 5px;
  border: 1px solid #d6dae4;
  background: #fff;
  height: 34px;
  cursor: pointer;

  :hover {
    background-color: #f5f7f9;
  }

  span {
    color: #818da1;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;
