import styled from 'styled-components';

export const Wrapper = styled.div`
  ${props => !props.active && `margin-bottom: 20px;`};
  .task-accordion .title.task-advanced-settings-title {
    margin: 0px !important;
    display: inline-flex;
    align-items: center;
    font-family: 'Open Sans' !important;
  }
  .task-advanced-settings-title {
    font-weight: 600 !important;
    font-size: 10px !important;
    line-height: 14px !important;
    text-transform: uppercase;
    margin: 0px 5px;
    :hover {
      span {
        color: #5158cf !important;
      }
      path {
        fill: #5158cf;
      }
    }
  }
  .task-advanced-settings-title.highlight-title {
    span {
      color: #5158cf !important;
    }
    path {
      fill: #5158cf;
    }
  }
  .task-settings-icon {
    margin-right: 5px !important;
  }
  .task-accordion-icon {
    margin-left: 8px;
    transition: transform 0.2s ease, opacity 0.2s ease;
    ${props => props.active && `transform: rotate(180deg) translateY(2px);`};
  }
  span {
    font-weight: 600;
    -webkit-font-smoothing: auto;
    color: #758094 !important;
  }
  .task-accordion .content.active {
    margin-top: 15px;
    margin-bottom: 0px;
    background: rgba(167, 173, 184, 0.1);
    padding: 23px 16px !important;
    border-radius: 4px;
  }
  .task-accordion .content.active.habit {
    margin-top: 6px;
  }
  .with-bottom-margin {
    margin-bottom: 18px;
  }
`;

export const ToggleRow = styled.div`
  display: flex;
  align-items: center;
  .task-toggle-option {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #202353;
    transform: translate(1px, 1px);
    -webkit-font-smoothing: auto;
  }
  input ~ label::before {
    background-color: #d3d5dd;
  }

  input:checked ~ label::before,
  input:checked:focus ~ label::before {
    background-color: #5158cf;
  }
`;
