import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CustomMenu from './CustomMenu';
import CustomOption from './CustomOption';
import CustomControl from './CustomControl';
import CustomSingleValue from './CustomSingleValue';
import CustomPlaceholder from './CustomPlaceholder';
import CustomDropdownIndicator from './CustomDropdownIndicator';
import CustomNoOptionsMessage from './CustomNoOptionsMessage';
import CustomLoadingMessage from './CustomLoadingMessage';

import { getSurveyList, resetSurveyList } from 'redux/survey-contact/actions';

const initFilter = {
  page: 1,
  pageSize: 20,
  sort: 'last_activity',
  order: 'DESC',
  active: true,
};

function CustomSelect({
  onChange,
  name,
  value,
  placeholderInput,
  surveyList,
  getSurveyList,
  loading,
  resetSurveyList,
  error,
  onChangeSurvey,
}) {
  const surveys = get(surveyList, 'data');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filters, setFilters] = useState(initFilter);
  const [isTyping, setIsTyping] = useState(false);
  const [isAssignedEvent, setIsAssignedEvent] = useState(false);

  const surveyListRef = useRef();
  const surveyLoading = useRef();
  surveyListRef.current = surveyList;
  surveyLoading.current = loading;

  useEffect(() => {
    if (menuIsOpen) {
      getSurveyList(filters);
    } else {
      resetSurveyList();
      setFilters(initFilter);
      setIsAssignedEvent(false);
    }
  }, [menuIsOpen, filters]);

  useEffect(() => {
    if (menuIsOpen && !isAssignedEvent) {
      setTimeout(() => {
        const menuListEl = document.querySelector('.survey__menu .survey__menu-list');
        if (menuListEl) {
          setIsAssignedEvent(true);
          menuListEl.addEventListener('scroll', debounce(handleScroll, 300));
        }
      }, 10);
    }
  }, [menuIsOpen]);

  useEffect(() => {
    surveyListRef.current = surveyList;
  }, [surveyList]);

  useEffect(() => {
    surveyLoading.current = loading;
  }, [loading]);

  const selectedOption = useMemo(() => {
    return surveys.find(option => option.id === value);
  }, [surveys, value]);

  const handleScroll = () => {
    const menuListEl = document.querySelector('.survey__menu .survey__menu-list');
    const currentSurveyList = get(surveyListRef, 'current', { data: [], total: 0 });

    const isLoading = get(surveyLoading, 'current', false);
    const isAtBottom = menuListEl.scrollTop + menuListEl.clientHeight >= menuListEl.scrollHeight - 5;
    const isLoadedAll = get(currentSurveyList, 'data.length', 0) >= get(currentSurveyList, 'total', 0);

    if (isLoading || isLoadedAll) return;

    if (isAtBottom) {
      setFilters(prev => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  };

  const handleSearchSurvey = useCallback(inputValue => {
    const searchText = typeof inputValue === 'string' ? inputValue.trim() : '';
    setIsTyping(true);
    debounceSearchSurvey(searchText);
  }, []);

  const debounceSearchSurvey = debounce(searchText => {
    setFilters(prev => ({
      ...prev,
      page: 1,
      q: searchText,
    }));
    setIsTyping(false);
  }, 500);

  return (
    <Select
      blurInputOnSelect
      closeOnSelect
      isSearchable
      name={name}
      isClearable={false}
      onChange={onChange}
      isLoading={loading}
      key={JSON.stringify(value)}
      value={selectedOption}
      options={surveys}
      placeholder={placeholderInput}
      styles={customStyles}
      classNamePrefix="survey"
      className={`select-survey ${error ? 'error' : ''}`}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      getOptionLabel={option => `${option.survey_name} ${option.author_name}`}
      getOptionValue={option => option.id}
      menuPlacement="top"
      onInputChange={handleSearchSurvey}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        Option: CustomOption,
        Control: CustomControl,
        SingleValue: CustomSingleValue,
        Placeholder: CustomPlaceholder,
        LoadingIndicator: null,
        Menu: CustomMenu,
        LoadingMessage: CustomLoadingMessage,
        NoOptionsMessage: props => <CustomNoOptionsMessage {...props} isLoading={loading || isTyping} />,
      }}
      onChangeSurvey={onChangeSurvey}
    />
  );
}

const mapStateToProps = state => {
  const {
    rootReducer: { surveyContact },
  } = state;

  return {
    surveyList: get(surveyContact, 'surveyList', { total: 0, data: [] }),
    loading: get(surveyContact, 'loading', false),
  };
};

const mapDispatchToProps = dispatch => ({
  getSurveyList: bindActionCreators(getSurveyList, dispatch),
  resetSurveyList: bindActionCreators(resetSurveyList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSelect);

export const customStyles = {
  container: (baseStyle, state) => {
    return {
      ...baseStyle,
      background: '#ffffff',
      borderRadius: '4px',
      pointerEvents: 'auto',
      '&.error': {
        '.survey__control': {
          borderColor: 'rgba(234, 49, 74, 1)',
        },
      },
      '.survey__info-name': {
        maxWidth: '322px',
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px',
        color: 'rgba(32, 35, 83, 1)',
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
      },
      '.survey__info-questions': {
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '15px',
        color: 'rgba(151, 151, 151, 1)',
        marginTop: '2px',
      },
      '.survey__indicator': {
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)',
      },
      '.survey__placeholder': {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '18px',
        color: 'rgba(50, 60, 71, 0.3)',
        userSelect: 'none',
        marginLeft: 'unset',
        marginRight: 'unset',
      },
    };
  },
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? '#5C5BBD' : '#DADFEA',
    boxShadow: 'unset',
    ':hover': {
      borderColor: state.isDisabled ? '#DADFEA' : '#5C5BBD',
    },
    minHeight: '52px',
    maxHeight: '117px',
    backgroundColor: state.isDisabled ? '#FAFBFC' : '#FFFFFF',
    '.control__wrapper': {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      maxHeight: '115px',
      overflow: 'hidden',
      paddingRight: '24px',
    },
    '.control__wrapper-icon': {
      paddingLeft: '11px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        width: '24px',
        height: '24px',
      },
    },
    '.control__wrapper-value': {
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      overflow: 'auto',
      maxHeight: '117px',
      maxWidth: 'calc(100% - 3px * 2)',
      margin: '0 auto',
      '.survey__indicator-separator': {
        display: 'none',
      },
      '.survey__indicator.survey__dropdown-indicator': {
        padding: 0,
      },
    },
    '.scroll-wrapper::-webkit-scrollbar-thumb': {
      background: '#DADFEA !important',
    },
    '.scroll-wrapper::-webkit-scrollbar': {
      width: '4px !important',
    },
    '.survey__single-value': {
      '.survey__placeholder': {
        margin: 0,
        display: state.isFocused ? 'block' : 'none',
      },
      '.survey__info': {
        display: state.isFocused ? 'none' : 'block',
      },
    },
  }),
  input: () => ({
    position: 'absolute',
    input: {
      height: '22px !important',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '18px',
      color: '#202353',
    },
  }),
  menu: baseStyle => ({
    ...baseStyle,
    marginTop: '8px',
    marginBottom: '8px',
    border: '1px solid #DCDCDE',
    borderRadius: '4px',
    overflow: 'hidden',
    padding: '5px',
    boxShadow: '0px 1px 4px 0px rgba(38, 38, 38, 0.2)',
    width: '350px',
    maxHeight: '319px',
    '.survey__menu-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      overflowY: 'auto',
      '::-webkit-scrollbar-thumb': {
        background: '#DADFEA !important',
      },
      '::-webkit-scrollbar': {
        width: '4px !important',
      },
    },
    '.survey__menu-list': {
      paddingTop: '0px',
      paddingBottom: '0px',
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
    },
    '.create__action': {
      '.create__action-icon': {
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        svg: {
          width: '9px',
          height: '9px',
          path: {
            fill: 'rgba(81, 88, 207, 1)',
          },
        },
      },
      position: 'sticky',
      top: '0px',
      width: 'calc(100% - 3px)',
      minHeight: '53px',
      border: 'none',
      background: '#fff',
      height: '53px',
      display: 'flex',
      alignItems: 'center',
      padding: '8px',
      gap: '4px',
      fontSize: '13px',
      fontWeight: 600,
      color: 'rgba(81, 88, 207, 1)',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(244, 244, 252, 1)',
      },
    },
  }),
  option: baseStyle => {
    return {
      ...baseStyle,
      backgroundColor: '#ffffff',
      ':hover': {
        backgroundColor: 'rgba(244, 244, 252, 1)',
        '.option__wrapper-name': {
          color: 'rgba(81, 88, 207, 1)',
        },
      },
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '12px',
      padding: '10px 12px',
      '.option__wrapper': {
        display: 'flex',
        flexDirection: 'column',
      },
      '.option__wrapper-name': {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '18px',
        color: 'rgba(32, 35, 83, 1)',
        margin: 0,
        whiteSpace: 'pre-wrap',
      },
      '.option__wrapper-question': {
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '15px',
        color: 'rgba(151, 151, 151, 1)',
      },
      '&.survey__option--is-selected': {
        background: 'rgba(244, 244, 252, 1)',
        '.option__wrapper-name': {
          fontSize: '13px',
          fontWeight: 700,
          lineHeight: '18px',
          color: 'rgba(81, 88, 207, 1)',
          margin: 0,
        },
      },
    };
  },
  valueContainer: (baseStyle, state) => ({
    ...baseStyle,
    paddingTop: 'unset',
    paddingBottom: 'unset',
    paddingLeft: '12px',
    gap: '8px',
    cursor: 'text',
    '> div:last-child': {
      margin: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
      display: state.isDisabled ? 'none' : 'inline-block',
    },
  }),
  noOptionsMessage: (baseStyle, state) => {
    return {
      height: state.customLoading ? '34.5px' : '53px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 12px',
      color: '#202353',
      fontSize: '13px',
      fontWeight: 400,
    };
  },
  loadingMessage: baseStyle => ({
    ...baseStyle,
    fontSize: '13px',
    fontWeight: 400,
  }),
  menuList: baseStyle => ({
    ...baseStyle,
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '250px',
  }),
  singleValue: () => ({}),
  placeholder: () => ({}),
};
