import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button as CloseButton } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_left_coach_bios.svg';
import CloseIcon from 'assets/icons/close_bold_circle.svg';

import * as S from './style';

function ModalSingleField({
  open,
  value,
  onSubmit,
  onClose,
  titlePopup,
  description,
  titleInput,
  placeholderInput,
  submitName,
  maxLength,
  isSubmitted,
  isDisabledSubmit,
  onChangeValue,
  inputHeight,
  isShowCancelButton = false,
  isHideCloseButton = false,
  childrenForm,
  isShowDefaultInput = true,
  elementModal = null,
  isShowBackButton = false,
  onClickBackButton,
  ...props
}) {
  // elementModal => component style element of modal
  const Modal = elementModal ? elementModal : S.ModalSingleField;

  return (
    <Modal
      open={open}
      closeIcon={
        !isHideCloseButton && (
          <CloseButton className="close-button" onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </CloseButton>
        )
      }
      inputHeight={inputHeight}
      onClose={onClose}
    >
      <Modal.Header>
        <S.HeaderWrapper>
          {isShowBackButton && <ArrowLeftIcon className="back-icon" onClick={onClickBackButton} />}
          {titlePopup ? titlePopup : 'Create New'}
        </S.HeaderWrapper>
        {description && <S.Description>{description}</S.Description>}
      </Modal.Header>
      <Modal.Content>
        <S.Form onSubmit={onSubmit}>
          {isShowDefaultInput && (
            <S.Input
              label={titleInput ? titleInput : 'Title'}
              placeholder={placeholderInput ? placeholderInput : 'Input title'}
              required
              isHideErrorMessage
              maxLength={maxLength}
              inputProps={{ autoFocus: true, maxLength: maxLength }}
              isSubmitted={isSubmitted}
              value={value}
              onChange={onChangeValue}
            />
          )}
          {childrenForm && childrenForm}
        </S.Form>
        <S.Footer>
          {isShowCancelButton && (
            <Button className="cancel" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            className={classnames('submit', { disabled: isDisabledSubmit })}
            disabled={isDisabledSubmit}
            onClick={onSubmit}
            purple
          >
            {submitName ? submitName : 'Create New'}
          </Button>
        </S.Footer>
      </Modal.Content>
    </Modal>
  );
}

ModalSingleField.prototype = {
  open: PropTypes.bool,
  value: PropTypes.value,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  titlePopup: PropTypes.string,
  description: PropTypes.string,
  titleInput: PropTypes.string,
  placeholderInput: PropTypes.string,
  submitName: PropTypes.string,
  maxLength: PropTypes.number,
  isSubmitted: PropTypes.bool,
  isDisabledSubmit: PropTypes.bool,
  onChangeValue: PropTypes.func,
  inputHeight: PropTypes.number,
  isShowCancelButton: PropTypes.bool,
};

export default ModalSingleField;
