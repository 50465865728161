// Lib
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Image, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import omit from 'lodash/omit';

// Store
import { toggleConfirmModal, toggleModal, toggleSecondModal } from 'actions/modal';
import { axiosInstance } from 'configs/request';
import { addNewForms } from 'redux/forms/actions';
import { duplicateForm } from 'redux/forms/actions';

// Share
import WelcomeFormQuestions from './WelcomeFormQuestions';
import { TABS, DEFAULT_QUERY } from 'components/OnboardingFlowDetail/constants';
import FormTemplatesPopup from '../DuplicateFormTemplates';
import ShareOwnerAvatar from 'shared/AssetsShareSetting/components/ShareOwnerAvatar';
import {
  TriggerIcon,
  RequestDuplicateFormModal,
  DUPLICATE_ACTION_TYPES,
  TypingModal,
  TYING_TYPES,
} from 'shared/TriggerForms';
import AddNewForm from 'components/Forms/components/AddNewForm';
import { getFormProperties } from 'components/OnboardingFlowDetail/constants/helper';

// Constants
import { omitEmptyRequestParams, pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';
import { ASSET_TYPES } from 'components/OnboardingFlowDetail/constants';

// Assets
import { getFormTemplatesDetail, handleSelectedForm } from 'redux/onboarding-flow/actions';
import { ReactComponent as EmptyForm } from 'assets/icons/onboarding-empty-forms.svg';
import { ReactComponent as FormDocumentSvg } from 'assets/icons/onboarding-forms-document.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/onboarding-flow-signature.svg';
import { ReactComponent as EmptySearchForm } from 'assets/icons/onboarding-forms-no-results.svg';
import { ReactComponent as FormDoc } from 'assets/icons/form_doc.svg';
import { ReactComponent as Duplicate } from 'assets/icons/action_duplicate_left.svg';

import * as S from './style';

const WelcomeForm = props => {
  const {
    onClose,
    toggleSecondModal,
    handleSelectedForm,
    onboardingForm,
    formData,
    getFormTemplatesDetail,
    toggleModal,
    questionsTemplates,
    formTemplates,
    getFormTemplates,
    firstFormId,
    isEditForm,
    forms,
    user,
    addNewForms,
    cloudfrontList,
    selectedAsset,
    handleChangeAsset,
    toggleConfirmModal,
    duplicateForm,
    isChangeForm,
  } = props;

  const defaultValue = get(formData, '[0]._id', '');
  const currentFormId = get(onboardingForm, 'form._id', '') || get(onboardingForm, 'custom_form._id', '');
  const anotherFormId = get(
    (formData || []).find(item => item._id !== firstFormId),
    '_id',
  );
  const [selectedId, setSelectedId] = useState(isEditForm ? currentFormId : anotherFormId || defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  const [customForm, setCustomForm] = useState(get(onboardingForm, 'custom_form') || {});
  const [query, setQuery] = useState(DEFAULT_QUERY);
  const [formQuestionnaire, setFormQuestionnaire] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const requestResetText = useRef(null);
  const observer = useRef();
  const listRef = useRef(null);

  const lastFormElRef = useCallback(
    node => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          // Last element is in view
          setQuery({ ...query, page: query.page + 1 });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    getForms();
  }, [query.page, query.fetch_type, query.text_search]);

  const CheckBoxIcon = ({ isCustom, active }) => {
    return (
      <S.CheckBoxIconWrapper isCustom={isCustom} active={active}>
        <S.CheckBoxCircle />
      </S.CheckBoxIconWrapper>
    );
  };

  const handleChangeTab = header => {
    if (header === query.fetch_type) {
      return;
    }
    requestResetText && requestResetText.current();
    setQuery({ ...query, ...omit(DEFAULT_QUERY, ['all_form', 'your_form']), fetch_type: header });
    listRef.current && listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const createCustomForm = customForm => {
    return {
      custom_form: {
        _id: get(customForm, '_id', ''),
        name: get(customForm, 'name', ''),
        number_of_questions: get(customForm, 'number_of_questions', 0),
        hasSignature: get(customForm, 'has_signature', false),
        is_archived: get(customForm, 'is_archived', false),
        hasTrigger: get(customForm, 'total_single_choice_questions', 0) > 0,
        total_single_choice_questions: get(customForm, 'total_single_choice_questions', 0),
      },
    };
  };

  const closeConfirmModal = () => {
    typeof toggleConfirmModal === 'function' && toggleConfirmModal(false);
  };

  const handleKeepUsingCustomForm = () => {
    closeConfirmModal();
    handleNextAction();
  };

  const handleDuplicateCustomForm = () => {
    closeConfirmModal();

    typeof toggleSecondModal === 'function' &&
      toggleSecondModal(
        true,
        <AddNewForm
          user={user}
          type="duplicate"
          workingItem={customForm}
          toggleModal={toggleSecondModal}
          onSubmit={(data, callback) => {
            typeof duplicateForm === 'function' && duplicateForm({ _id: customForm._id, ...data }, callback, true);
          }}
        />,
      );
  };

  const handleApproveChangeForm = (isAssignTriggerProgram, isAssignTriggerForum) => () => {
    if (typeof handleChangeAsset === 'function') {
      isAssignTriggerProgram &&
        handleChangeAsset(
          {
            ...selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0],
            is_form_deleted: true,
          },
          ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
        );
      isAssignTriggerForum &&
        handleChangeAsset(
          {
            ...selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0],
            is_form_deleted: true,
          },
          ASSET_TYPES.FORUM_TRIGGER_FORMS,
        );
    }

    handleNextAction();
  };

  const confirmDuplicateForm = () => {
    if (!!customForm && !isEditForm) {
      const { author, _id } = customForm || {};
      const isShowConfirmDuplicate =
        !!author && typeof author === 'object' ? (author || {})._id !== (user || {})._id : false;

      if (isShowConfirmDuplicate && _id === selectedId && typeof toggleConfirmModal === 'function') {
        return toggleConfirmModal(
          true,
          <RequestDuplicateFormModal
            type={DUPLICATE_ACTION_TYPES.REQUEST_DUPLICATE_FORM}
            owner={author}
            closeModal={closeConfirmModal}
            cancelModal={handleKeepUsingCustomForm}
            submitAction={handleDuplicateCustomForm}
          />,
        );
      }
    }

    if (currentFormId !== selectedId && isEditForm && selectedId === customForm._id) {
      // Check form assign to trigger
      const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(
        it => it.form === currentFormId || it.custom_form === currentFormId,
      );
      const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(
        it => it.form === currentFormId || it.custom_form === currentFormId,
      );
      if ((isAssignTriggerProgram || isAssignTriggerForum) && typeof toggleConfirmModal === 'function') {
        return toggleConfirmModal(
          true,
          <TypingModal
            isCloseAfterSubmit
            type={TYING_TYPES.CHANGE_FORM}
            closeModal={closeConfirmModal}
            submitAction={handleApproveChangeForm(isAssignTriggerProgram, isAssignTriggerForum)}
          />,
        );
      }
    }

    handleNextAction();
  };

  const handleNextAction = () => {
    onClose && onClose();
    const questionsDetail = questionsTemplates.find(item => item._id === selectedId);
    const questionsCustom = formQuestionnaire.find(item => item._id === get(customForm, '_id'));
    const questionsSystem = formData.find(item => item._id === selectedId);

    if (!isEmpty(questionsCustom) && isEmpty(questionsSystem)) {
      let newOnboardingForm = [];

      if (!isEmpty(onboardingForm)) {
        if (firstFormId && !isEditForm) {
          newOnboardingForm = [onboardingForm, createCustomForm(customForm)];
        } else {
          newOnboardingForm = forms.map(item =>
            [get(item, 'form._id'), get(item, 'custom_form._id')].includes(currentFormId)
              ? createCustomForm(customForm)
              : item,
          );
        }
      } else {
        newOnboardingForm = [createCustomForm(customForm)];
      }

      handleSelectedForm && handleSelectedForm(newOnboardingForm);
      updateTriggerState(newOnboardingForm);
    } else {
      if (!isEmpty(questionsTemplates) && questionsDetail) {
        onOpenWelcomeFormQuestions({ formDetailData: questionsDetail });
      } else {
        setIsLoading(true);
        getFormTemplatesDetail &&
          getFormTemplatesDetail(selectedId)
            .then(response => {
              const formDetailData = get(response, 'data.data');
              if (formDetailData) {
                onOpenWelcomeFormQuestions({ formDetailData });
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
      }
    }
  };

  const onOpenWelcomeFormQuestions = ({ formDetailData }) => {
    toggleSecondModal(
      true,
      <WelcomeFormQuestions
        onCloseSecondModal={() => toggleSecondModal(false)}
        handleSelectedForm={handleSelectedForm}
        formDetailData={formDetailData}
        onboardingForm={onboardingForm}
        isEditForm={isEditForm}
        toggleModal={toggleModal}
        formTemplates={formTemplates}
        getFormTemplates={getFormTemplates}
        forms={forms}
        firstFormId={firstFormId}
        selectedAsset={selectedAsset}
        handleChangeAsset={handleChangeAsset}
        isChangeForm={isChangeForm}
      />,
    );
  };

  const updateTriggerState = newOnboardingForm => {
    try {
      // recheck status trigger
      if (selectedAsset) {
        newOnboardingForm.forEach(form => {
          const { _id: formId } = getFormProperties(form);

          // Check form assign to trigger
          const isAssignTriggerProgram = selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS].find(
            it => it.form === formId || it.custom_form === formId,
          );
          const isAssignTriggerForum = selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS].find(
            it => it.form === formId || it.custom_form === formId,
          );

          if (typeof handleChangeAsset === 'function') {
            isAssignTriggerProgram &&
              handleChangeAsset(
                {
                  ...selectedAsset[ASSET_TYPES.PROGRAM_TRIGGER_FORMS][0],
                  is_form_deleted: false,
                },
                ASSET_TYPES.PROGRAM_TRIGGER_FORMS,
              );
            isAssignTriggerForum &&
              handleChangeAsset(
                {
                  ...selectedAsset[ASSET_TYPES.FORUM_TRIGGER_FORMS][0],
                  is_form_deleted: false,
                },
                ASSET_TYPES.FORUM_TRIGGER_FORMS,
              );
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSelect = formId => {
    setSelectedId(formId);
  };

  const onSelectCustomForm = form => {
    setCustomForm(form);
    setSelectedId(get(form, '_id'));
  };

  const handleCancelChangeForm = () => {
    if (!isEmpty(onboardingForm)) {
      onClose();
      return;
    }

    if (isEditForm) {
      onClose();
      onOpenWelcomeFormQuestions({ formDetailData: onboardingForm.form });
    } else {
      onClose();
    }
  };

  const handleSearchForm = (event, { value }) => {
    const trimmed = value.toLowerCase().trim();
    setQuery({ ...query, text_search: trimmed, page: 1 });
  };

  const handleClearSearchForm = () => {
    setQuery({ ...query, ...omit(DEFAULT_QUERY, ['your_form', 'all_form']), fetch_type: query.fetch_type });
  };

  const onKeyPress = event => {
    event.persist();
  };

  const getForms = () => {
    if (loading) {
      return;
    }
    if (query.page === 1) {
      setFormQuestionnaire([]);
    }
    setLoading(true);
    axiosInstance
      .get('api/forms', { params: omitEmptyRequestParams(omit(query, 'total', 'your_form', 'all_form')) })
      .then(res => {
        const { data } = res.data;
        const newData = query.page === 1 ? data.data : [...formQuestionnaire, ...data.data];
        if (newData.length >= data.total) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setQuery({ ...query, ...omitEmptyRequestParams(omit(data, 'data', 'archived_form')) });
        setFormQuestionnaire(newData);
      })
      .catch(err => {
        toast.error('Could not connect to server, please try again later.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderEmptySearch = () => {
    return (
      <S.EmptySearchWrapper>
        <EmptySearchForm />
        <S.EmptySearchText>No results found</S.EmptySearchText>
      </S.EmptySearchWrapper>
    );
  };

  const handleOpenDuplicateForm = (event, data) => {
    event.stopPropagation();

    toggleSecondModal(
      true,
      <FormTemplatesPopup
        onSubmit={handleDuplicateForm}
        onClose={() => toggleSecondModal(false)}
        user={user}
        data={data}
      />,
    );
  };

  const handleDuplicateForm = (params, callback) => {
    addNewForms && addNewForms(params, true);
    callback && callback();
  };

  const renderSystemForm = () => {
    return (
      <>
        <S.FormHeader>Form Templates</S.FormHeader>
        <S.BodyTemplates>
          {formData &&
            formData.map(data => {
              const id = get(data, '_id', '');
              const isDisabled = id === firstFormId;

              const isHasTrigger = get(data, 'has_trigger');

              return (
                <S.OptionWrapper
                  key={id}
                  active={id === selectedId}
                  disabled={isDisabled}
                  onClick={() => id !== firstFormId && onSelect(id)}
                >
                  <S.FormTemplatesHeader disabled={isDisabled}>
                    <S.HeaderLeft>
                      <S.FormIconWrapper isTemplates>
                        <FormDocumentSvg />
                      </S.FormIconWrapper>
                      {isEditForm && currentFormId === id ? <S.OptionLabel>current form</S.OptionLabel> : null}
                    </S.HeaderLeft>
                    {id !== firstFormId ? <CheckBoxIcon active={id === selectedId} /> : null}
                  </S.FormTemplatesHeader>
                  <S.OptionMainContent>
                    <S.OptionTitle disabled={isDisabled}>{get(data, 'title', '')}</S.OptionTitle>
                    <S.OptionDesc disabled={isDisabled}>{get(data, 'description', '')}</S.OptionDesc>
                    <S.OptionQuestion>
                      <S.OptionContainer disabled={isDisabled}>
                        <span>{pluralize('Question', get(data, 'number_of_questions', 0))}:</span>
                        <S.QuestionNumber>{get(data, 'number_of_questions', 0)}</S.QuestionNumber>
                        {(isHasTrigger || get(data, 'has_signature', false)) && (
                          <S.SignatureWrapper>
                            <span className="symbol-bullet">•</span>

                            {get(data, 'has_signature', false) && (
                              <S.FormInfoLayout>
                                <SignatureIcon />
                                <S.FormInfoText>Signature</S.FormInfoText>
                              </S.FormInfoLayout>
                            )}

                            {isHasTrigger && <TriggerIcon />}
                          </S.SignatureWrapper>
                        )}
                      </S.OptionContainer>
                      <Duplicate
                        onClick={event => handleOpenDuplicateForm(event, data)}
                        data-tip
                        data-for={`onboarding-form-duplicate-${id}`}
                        className="action-duplicate-form"
                      />
                      <ReactTooltip
                        id={`onboarding-form-duplicate-${id}`}
                        className="app-tooltip onboarding-form-duplicate"
                        effect="solid"
                        place="top"
                      >
                        Duplicate and Edit in Library
                      </ReactTooltip>
                    </S.OptionQuestion>
                  </S.OptionMainContent>
                </S.OptionWrapper>
              );
            })}
        </S.BodyTemplates>
      </>
    );
  };

  const renderCustomForm = () => {
    return (
      <>
        <S.Body>
          {formQuestionnaire &&
            formQuestionnaire.map(formQuestionnaire => {
              const id = get(formQuestionnaire, '_id', '');
              const isDisabled = id === firstFormId;
              const author = get(formQuestionnaire, 'author');

              const isHasTrigger = get(formQuestionnaire, 'total_single_choice_questions', 0) > 0;

              const isShowAvatarOwner = query.fetch_type === 'all_form' && !!author ? author._id !== user._id : false;

              return (
                <S.OptionWrapper
                  key={id}
                  active={id === selectedId}
                  disabled={isDisabled}
                  onClick={() => id !== firstFormId && onSelectCustomForm(formQuestionnaire)}
                  isCustom
                  ref={lastFormElRef}
                >
                  <S.FormIconWrapper isCustom disabled={isDisabled}>
                    <FormDoc />
                  </S.FormIconWrapper>
                  <S.OptionMainContent isCustom>
                    {id !== firstFormId ? <CheckBoxIcon isCustom={true} active={id === selectedId} /> : null}
                    <S.OptionTitleWrapper>
                      <S.OptionTitle disabled={isDisabled}>{get(formQuestionnaire, 'name', '')}</S.OptionTitle>
                      {isEditForm && currentFormId === id ? <S.OptionLabel>current form</S.OptionLabel> : null}
                    </S.OptionTitleWrapper>
                    <S.OptionQuestion disabled={isDisabled}>
                      <span>{pluralize('Question', get(formQuestionnaire, 'number_of_questions', 0))}:</span>
                      <S.QuestionNumber>{get(formQuestionnaire, 'number_of_questions', 0)}</S.QuestionNumber>
                      {(isHasTrigger || get(formQuestionnaire, 'has_signature', false)) && (
                        <S.SignatureWrapper>
                          <span className="symbol-bullet">•</span>

                          {get(formQuestionnaire, 'has_signature', false) && (
                            <S.FormInfoLayout>
                              <SignatureIcon />
                              <S.FormInfoText>Signature</S.FormInfoText>
                            </S.FormInfoLayout>
                          )}

                          {isHasTrigger && <TriggerIcon />}
                        </S.SignatureWrapper>
                      )}
                      {isShowAvatarOwner && (
                        <S.OwnerWrapper>
                          <span className="symbol-bullet">•</span>
                          <ShareOwnerAvatar
                            user={author}
                            tooltipId={`avatar-tooltip-${author._id}`}
                            cloudfrontList={cloudfrontList}
                          />
                        </S.OwnerWrapper>
                      )}
                    </S.OptionQuestion>
                  </S.OptionMainContent>
                </S.OptionWrapper>
              );
            })}
        </S.Body>
      </>
    );
  };

  const renderCustomFormEmpty = () => {
    return (
      <>
        <S.FormHeader>Custom Forms</S.FormHeader>
        <S.Body>
          <S.EmptyFormWrapper>
            <EmptyForm />
          </S.EmptyFormWrapper>
        </S.Body>
      </>
    );
  };

  const renderTab = () => {
    return (
      <S.TabWrapper>
        {TABS.map(item => {
          return (
            <S.TabItem
              key={item.key}
              enableTab={query.fetch_type === item.key}
              onClick={() => handleChangeTab(item.key)}
            >
              <span className="tab-container">
                <span>{`${startCase(item.value)}`}&nbsp;</span>
                <span>{`(${query.text_search && query.fetch_type === item.key ? query.total : query[item.key]})`}</span>
              </span>
            </S.TabItem>
          );
        })}
      </S.TabWrapper>
    );
  };

  return (
    <S.CustomModal
      open={true}
      closeOnDimmerClick={false}
      className="welcome-form-modal"
      onClose={handleCancelChangeForm}
    >
      <Modal.Header>
        <Button onClick={handleCancelChangeForm} className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </Button>
        <S.Header>
          <S.HeaderWrapper>
            <S.Title>Onboarding Forms</S.Title>
            <S.NewSearchForm
              onChange={debounce(handleSearchForm, 300)}
              onClearSearch={debounce(handleClearSearchForm, 300)}
              placeholder="Search by name"
              onKeyPress={onKeyPress}
              requestResetText={requestResetText}
            />
          </S.HeaderWrapper>
        </S.Header>
      </Modal.Header>
      <Modal.Content>
        {query.text_search ? (
          query.total ? (
            <S.ListWrapper ref={listRef}>
              {renderTab()}
              {renderCustomForm()}
            </S.ListWrapper>
          ) : (
            <S.ListWrapper ref={listRef}>
              {renderTab()}
              {renderEmptySearch()}
            </S.ListWrapper>
          )
        ) : query.total ? (
          <S.ListWrapper ref={listRef}>
            {renderSystemForm()}
            {renderTab()}
            {renderCustomForm()}
          </S.ListWrapper>
        ) : (
          <S.ListWrapper ref={listRef}>
            {renderSystemForm()}
            {renderTab()}
            {renderCustomFormEmpty()}
          </S.ListWrapper>
        )}
      </Modal.Content>
      <Modal.Actions>
        <S.Footer>
          <Button onClick={handleCancelChangeForm} type="button" className="btn-welcome-form btn-welcome-form-cancel">
            Cancel
          </Button>
          <S.ButtonWrapper isLoading={isLoading}>
            <Button onClick={confirmDuplicateForm} className="btn-welcome-form btn-welcome-form-next">
              Add
            </Button>
          </S.ButtonWrapper>
        </S.Footer>
      </Modal.Actions>
    </S.CustomModal>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: {
      onboardingFlow: { listForms, questionsTemplates, formTemplates },
    },
    user,
    cloudfrontList,
  } = state;

  return {
    listForms,
    questionsTemplates,
    formTemplates,
    user,
    cloudfrontList,
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  handleSelectedForm: bindActionCreators(handleSelectedForm, dispatch),
  getFormTemplatesDetail: bindActionCreators(getFormTemplatesDetail, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  addNewForms: bindActionCreators(addNewForms, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  duplicateForm: bindActionCreators(duplicateForm, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeForm);
