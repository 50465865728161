import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

export const ErrorMessage = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: #ea314a;
  margin-top: 10px;
`;

export const Input = styled(NumberFormat)`
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #dadfea;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: none;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #323c47;
  padding: 11px 14px;
  border-right-color: transparent;
  z-index: 1;
  position: relative;
  width: 100%;

  :focus {
    border-color: #5158cf;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .evfSelectBoxContainer {
    .evfSelectBox__control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-color: #dadfea;
    }
  }

  ${props =>
    props.invalidValue &&
    css`
      ${Input} {
        border-color: #ea314a;
        border-right-color: transparent;
      }

      .evfSelectBoxContainer {
        .evfSelectBox__control {
          border-color: #ea314a;
          border-left-color: #dadfea;
        }
      }
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
`;
