// Lib
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { get, isObject, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';

// Shared
import ViewRecipeDetail from 'components/RecipeDetail/ViewRecipeDetail';
import RecipeItems from 'components/Recipes/Parts/RecipeItems';
import RemoveWeek from 'components/MealPlanDetail/Parts/RemoveWeek';
import RecipeListModal from '../RecipeListModal';
import MacroInfo from './components/MacroInfo';
import LoadingMealSkeleton from './components/LoadingMealSkeleton';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_white.svg';
import EmptyIcon from 'assets/icons/MealPlans/empty-meal-assign.png';

// Utils
import { mongoObjectId } from 'utils/commonFunction';

// constants
import { DAY_FORMAT } from 'constants/time-format';
import { ACTION_TYPE } from 'components/ClientMealPlan/constants';
import { calculateMacroNutrients } from 'components/MealPlanDayDetail/constants';
import { CDN_URL } from 'constants/commonData';

// Style
import * as S from './style';

const mealCategory = [
  {
    _id: mongoObjectId(),
    name: 'Breakfast',
    recipes: [],
    is_system: true,
  },
  {
    _id: mongoObjectId(),
    name: 'Lunch',
    recipes: [],
    is_system: true,
  },
  {
    _id: mongoObjectId(),
    name: 'Dinner',
    recipes: [],
    is_system: true,
  },
  {
    _id: mongoObjectId(),
    name: 'Snack',
    recipes: [],
    is_system: true,
  },
];

const MealCategory = ({
  toggleModal,
  selectedDay,
  mealPlanByDay,
  toggleConfirmModal,
  workingClientDetail,
  getClientMealPlanByMonth,
  removeRecipeTrainer,
  loading,
  cloudfrontList,
  listDietary = [],
  assignedMealPlanClientsByRange = [],
  clientTimezone,
}) => {
  const { is_manual, meal_group = [], meal_client_completed = false, _id: idMealClient } = mealPlanByDay || {};
  const { _id: idClient = '' } = workingClientDetail || {};

  const isPastDate = useMemo(() => {
    const isToday = moment().tz(clientTimezone).format('MM-DD-YYYY') === moment(selectedDay).format('MM-DD-YYYY');
    return !isToday && moment(selectedDay).isBefore(moment(), 'day');
  }, [selectedDay]);

  const selectedDayBelongMealAssigned = useMemo(() => {
    return assignedMealPlanClientsByRange.find(item => {
      const { day, end_day } = item || {};
      const startDate = moment(day);
      const endDate = moment(end_day);
      const currentDate = moment(selectedDay).startOf('day');
      return currentDate.isBetween(startDate, endDate, null, '[]');
    });
  }, [selectedDay, assignedMealPlanClientsByRange]);

  const checkExistRecipe = useMemo(() => {
    return meal_group.every(item => {
      return get(item, 'recipes.length', 0) <= 0;
    });
  }, [meal_group]);

  const isEmptyMeal = isEmpty(selectedDayBelongMealAssigned) && isPastDate && checkExistRecipe;

  const getListMealCategory = useMemo(() => {
    const hasAtLeastOneCustom = meal_group.some(({ is_system }) => !is_system);
    if ((is_manual && !hasAtLeastOneCustom) || get(meal_group, 'length', 0) <= 0) {
      const data = mealCategory.map(item => {
        const { name } = item;
        const check = meal_group.find(itemMeal => itemMeal && itemMeal.name === name);
        if (isObject(check)) {
          return check;
        }
        return item;
      });
      return data;
    } else {
      return meal_group;
    }
  }, [meal_group]);

  const handleOpenRecipeList = (dataMeal, mode, mealClientRecipe = '', isLogged) => {
    if ((mode === ACTION_TYPE.ADD && isPastDate) || meal_client_completed || isLogged) return;

    toggleModal(
      true,
      <RecipeListModal
        mode={mode}
        selectedDay={selectedDay}
        dataMeal={dataMeal}
        mealClientRecipe={mealClientRecipe}
        idMealClient={idMealClient}
      />,
    );
  };

  const handleOpenRecipeDetail = (mealGroup, item, isLogged) => {
    typeof toggleModal === 'function' &&
      toggleModal(
        true,
        <ViewRecipeDetail
          isShowHeaderActions
          recipeId={get(item, 'recipe._id')}
          mealPlanClient={get(item, '_id')}
          onClose={() => toggleModal(false)}
          onRemove={mealPlanClient => handleRemove(mealPlanClient, isLogged)}
          mealGroup={mealGroup}
          onReplace={(mealGroup, mealPlanClient) =>
            handleOpenRecipeList(mealGroup, ACTION_TYPE.REPLACE, mealPlanClient, isLogged)
          }
          isLogged={isLogged}
          mealClientCompleted={meal_client_completed}
          recipeDetail={item && item.tracking_recipe}
          isGetFromClientTab
          mealPlanId={idMealClient}
        />,
      );
  };

  const handleRemove = (idRecipe, isLogged) => {
    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <RemoveWeek
          confirmButtonTitle="Remove"
          title="Remove Recipe?"
          content="Are you sure you want to remove this recipe?"
          onConfirm={() => handleRemoveRecipe(idRecipe, isLogged)}
          noBorder
          headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
          shouldCloseAfterConfirm={false}
        />,
      );
  };

  const handleRemoveRecipe = (idRecipe, isLogged) => {
    if (meal_client_completed || isLogged) return;

    const params = {
      meal_client_recipe: idRecipe,
    };

    typeof removeRecipeTrainer === 'function' &&
      removeRecipeTrainer(idMealClient, params, () => {
        const fromDay = selectedDay.clone().set('D', 1).subtract(7, 'd').format(DAY_FORMAT.YYYY_MM_DD);
        const toDay = selectedDay
          .clone()
          .set('D', 1)
          .add(1, 'month')
          .subtract(1, 'day')
          .add(7, 'd')
          .format(DAY_FORMAT.YYYY_MM_DD);

        toast('Recipe has been removed.');
        toggleModal(false);
        toggleConfirmModal(false);
        getClientMealPlanByMonth({
          client: idClient,
          from_day: fromDay,
          to_day: toDay,
        });
      });
  };

  const renderTotalMacro = recipes => {
    const newRecipes = recipes.filter(({ is_disable, is_logged }) => !(is_disable && !is_logged));
    const macroNutrients = calculateMacroNutrients([{ recipes: newRecipes }]);

    if (macroNutrients.length <= 0) return null;

    return (
      <S.MacroInfoWrapper>
        <MacroInfo macroNutrients={macroNutrients} />
      </S.MacroInfoWrapper>
    );
  };

  if (loading) {
    return <LoadingMealSkeleton />;
  }

  if (isEmptyMeal) {
    return (
      <S.EmptyWrapper>
        <img src={EmptyIcon} alt="No meals were scheduled" />
        <span>No meals were scheduled</span>
      </S.EmptyWrapper>
    );
  }

  return (
    <S.Wrapper>
      {getListMealCategory.map(item => {
        const { _id, name, recipes } = item;
        const isHasRecipes = get(recipes, 'length', 0) > 0;

        return (
          <S.MealCategory key={_id}>
            <S.Label>
              {name}
              {isHasRecipes && renderTotalMacro(recipes)}
              {!meal_client_completed && !isPastDate && (
                <>
                  <div
                    className="icon-wrapper"
                    data-tip
                    data-for={`add-recipe-${_id}`}
                    onClick={() => handleOpenRecipeList(item, ACTION_TYPE.ADD)}
                  >
                    <PlusIcon />
                  </div>
                  <ReactTooltip id={`add-recipe-${_id}`} effect="solid" place="top" delayShow={300}>
                    Add Recipe
                  </ReactTooltip>
                </>
              )}
            </S.Label>
            {isHasRecipes ? (
              <S.ListRecipe>
                {recipes.every(({ is_disable, is_logged }) => is_disable && !is_logged) ? (
                  <S.Empty
                    isDisabled={isPastDate || meal_client_completed || loading}
                    onClick={() => handleOpenRecipeList(item, ACTION_TYPE.ADD)}
                  >
                    <PlusIcon />
                    Add Recipe
                  </S.Empty>
                ) : (
                  <>
                    {recipes.map(itemRecipe => {
                      const { recipe, _id: idItemRecipe = '', is_logged = false, is_disable = false } =
                        itemRecipe || {};
                      if (is_disable && !is_logged) return <></>;
                      return (
                        <S.RecipeItemWrapper
                          key={idItemRecipe}
                          onClick={() => {
                            handleOpenRecipeDetail(item, itemRecipe, is_logged);
                          }}
                        >
                          <RecipeItems
                            recipe={recipe}
                            isShowRemoveIcon={false}
                            isFromClient
                            isShowDietary={false}
                            showDesc={false}
                            hasMoreOption
                            handleRemove={() => handleRemove(idItemRecipe, is_logged)}
                            handleReplace={() =>
                              handleOpenRecipeList(item, ACTION_TYPE.REPLACE, idItemRecipe, is_logged)
                            }
                            isLogged={is_logged}
                            mealClientCompleted={meal_client_completed}
                            cloudfrontList={cloudfrontList}
                            listDietary={listDietary}
                          />
                        </S.RecipeItemWrapper>
                      );
                    })}
                  </>
                )}
              </S.ListRecipe>
            ) : (
              <S.Empty
                isDisabled={isPastDate || meal_client_completed || loading}
                onClick={() => handleOpenRecipeList(item, ACTION_TYPE.ADD)}
              >
                <PlusIcon />
                Add Recipe
              </S.Empty>
            )}
          </S.MealCategory>
        );
      })}
    </S.Wrapper>
  );
};

export default MealCategory;
