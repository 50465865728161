import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

import { getPresignedUploadUrl, createObjectURL, revokeObjectURL, mediaLog } from 'utils/commonFunction';
import { ReactComponent as UploadIcon } from 'assets/icons/workout-collection-upload-banner-icon.svg';
import { BannerWrapper, SelectBanner, EditButton, Icon, RemoveIcon } from './style';
import FileUpload from 'shared/FileUpload';
import { validateFiles } from 'utils/validations';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';

const PRODUCT_COVER_UPLOAD_URL = '/api/file/gen-presigned-urls-on-demand-workout';
const VALID_EXTENSIONS = ['png', 'jpeg', 'jpg'];

export default function Banner(props) {
  const [file, setFile] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(props.coverImage);
  const [localUrl, setLocalUrl] = useState(null);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  const originalAttachment = props.coverImage;

  useEffect(() => {
    setAttachmentUrl(props.coverImage);
    if (props.coverImage !== attachmentUrl) {
      setLocalUrl(null);
    }
  }, [props.coverImage]);

  useEffect(() => {
    return () => {
      if (localUrl) {
        revokeObjectURL(localUrl);
      }
    };
  }, []);

  const handleSelectFile = async file => {
    if (file) {
      const { size, name, type } = file;
      mediaLog({
        status: 1,
        name,
        fileSize: size,
        fileType: type,
        description: 'Send a file to S3',
      });
    }
    setFile(file);
    if (localUrl) {
      revokeObjectURL(localUrl);
    }
    setLocalUrl(createObjectURL(file));
    const { configs: fileInfo, uploadUrl } = await getPresignedUploadUrl(PRODUCT_COVER_UPLOAD_URL, file);
    props.startUploadCoverImage();
    setUploadConfigs({
      method: 'PUT',
      url: uploadUrl,
      headers: { 'Content-Type': file.type },
      data: file,
    });
    setAttachmentUrl(fileInfo.url);
  };

  const handleSuccess = async () => {
    if (file) {
      const { size, name, type } = file;
      await mediaLog({
        status: 2,
        name,
        fileSize: size,
        fileType: type,
        description: 'Upload success a file to S3',
      });
      setFile(null);
    }
    setUploadConfigs(null);
    props.onUploadImageChange(attachmentUrl);
    props.endUploadCoverImage();
  };

  const handleCancel = () => {
    setFile(null);
    setLocalUrl(null);
    setUploadConfigs(null);
    setAttachmentUrl(originalAttachment);
    props.endUploadCoverImage();
  };

  const handleImageUrl = () => {
    if (props.isSubmitting || !attachmentUrl) {
      return null;
    }
    return localUrl ? localUrl : attachmentUrl;
  };

  const handleDropFile = files => {
    if (files.length === 0) {
      return toast.error('Please upload one file at a time');
    }

    if (files.length) {
      const file = files[0];
      const error = validateFiles({
        files: [file],
        validateExtentions: VALID_EXTENSIONS,
        maxSize: 25 * CONVERSION.MB_TO_BYTE,
      });

      if (error) {
        let message = '';

        if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
          message = 'File type not supported.';
        } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
          message = 'Image size limit is 25MB.';
        }

        toast.error(message);
      } else {
        handleSelectFile(file);
      }
    }
  };

  return (
    <Dropzone onDrop={handleDropFile} multiple={false} disabled={!props.isEdit}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <BannerWrapper src={handleImageUrl()} {...getRootProps()} isDragging={isDragActive}>
          {props.isEdit && attachmentUrl && !props.isSubmitting && <RemoveIcon onClick={props.onRemove} />}
          {(!attachmentUrl || props.isSubmitting) && (
            <UploadIcon className={props.isEdit ? 'default-upload-icon' : ''} />
          )}
          {uploadConfigs ? (
            <FileUpload onSuccess={handleSuccess} onError={() => {}} onCancel={handleCancel} configs={uploadConfigs} />
          ) : (
            <SelectBanner
              trigger={
                props.isEdit ? (
                  <EditButton className={props.isEdit ? 'show-on-hover' : 'hidden'}>
                    <Icon />
                  </EditButton>
                ) : (
                  <></>
                )
              }
              isDragging={isDragActive}
              onSelect={handleSelectFile}
              accept="image/png, image/jpeg, image/jpg, image/*'"
              validateExtentions={VALID_EXTENSIONS}
              maxSize={25}
              fileSizeLimitMessage={'Image size limit is 25MB.'}
            />
          )}
        </BannerWrapper>
      )}
    </Dropzone>
  );
}
