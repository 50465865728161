import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { showOnboardingUserGuide } from 'redux/onboarding/actions';
import { userAnsweredQuestionaire, userAnsweredTrackingFrom } from 'actions/profile';
import { getOnboardingCheckList } from 'redux/onboarding/actions';
import Component from './component';

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  showOnboardingUserGuide: bindActionCreators(showOnboardingUserGuide, dispatch),
  userAnsweredQuestionaire: bindActionCreators(userAnsweredQuestionaire, dispatch),
  userAnsweredTrackingFrom: bindActionCreators(userAnsweredTrackingFrom, dispatch),
  getOnboardingCheckList: bindActionCreators(getOnboardingCheckList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
