import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { range } from 'lodash';
import { Field } from 'formik';
// import components
import { FormGroup } from 'shared/FormControl';
import { DropdownIndicator } from 'shared/Icons';
import ReactSelectFormik from '../ReactSelectFormik';

const listHeight = [
  {
    key: 'cm',
    value: 'cm',
    label: 'cm',
  },
  {
    key: 'in',
    value: 'in',
    label: 'in',
  },
];
const listWeight = [
  {
    key: 'kg',
    value: 'kg',
    label: 'kg',
  },
  {
    key: 'lb',
    value: 'lb',
    label: 'lb',
  },
];
const listBodyFat = [
  {
    key: '%',
    value: '%',
    label: '%',
  },
];
const listHeightFt = range(3, 9).map(number => ({
  key: number,
  value: number,
  label: number,
}));
const listHeightIn = range(12).map(number => ({
  key: number,
  value: number,
  label: number,
}));

const CalculateForm = ({ heightUnit, setHeightUnit, weightUnit, setWeightUnit, bodyFatUnit, setBodyFatUnit }) => {
  return (
    <>
      <div className="group-inline">
        <Field name="age" className="age-group">
          {({ field, meta: { touched, error } }) => (
            <FormGroup>
              <label>Age</label>
              <input
                className={classNames({ error: error && touched })}
                type="number"
                placeholder="Eg: 20"
                {...field}
              />
              {error && touched && <div className="error error-msg">{error}</div>}
            </FormGroup>
          )}
        </Field>
        <span className="macros-model-divider" />
        <Field
          name="gender"
          label="Gender"
          component={ReactSelectFormik}
          options={[
            {
              value: '0',
              label: 'Male',
            },
            {
              value: '1',
              label: 'Female',
            },
            {
              value: '2',
              label: 'Other',
            },
          ]}
          className="gender-group"
        />
      </div>
      <div className="group-inline">
        {heightUnit.value === 'in' && (
          <>
            <Field name={'height'} label="Height" component={ReactSelectFormik} options={listHeightFt} placeholder="" />
            <span className="macros-model-divider" />
            <FormGroup className="macros-unit">
              <label dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
              <input value="ft" disabled />
            </FormGroup>
            <span className="macros-model-divider" />
            <Field name={'heightIn'} component={ReactSelectFormik} options={listHeightIn} placeholder="" />
          </>
        )}
        {heightUnit.value === 'cm' && (
          <Field name="height" className="height-group">
            {({ field, meta: { touched, error } }) => (
              <FormGroup>
                <label>Height</label>
                <input className={classNames({ error: error && touched })} type="number" {...field} />
                {error && touched && <div className="error error-msg">{error}</div>}
              </FormGroup>
            )}
          </Field>
        )}
        <span className="macros-model-divider" />
        <FormGroup className="macros-unit">
          <label dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
          <Select
            value={heightUnit}
            key="height-unit"
            options={listHeight}
            onChange={setHeightUnit}
            components={{ DropdownIndicator }}
            classNamePrefix="single-select"
            className="single-select-container"
          />
        </FormGroup>
      </div>
      <div className="group-inline">
        <Field name="weight" className="weight-group">
          {({ field, meta: { touched, error } }) => (
            <FormGroup>
              <label>Weight</label>
              <input className={classNames({ error: error && touched })} type="number" {...field} />
              {error && touched && <div className="error error-msg">{error}</div>}
            </FormGroup>
          )}
        </Field>
        <span className="macros-model-divider" />
        <FormGroup className="macros-unit">
          <label dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
          <Select
            value={weightUnit}
            key="weight-unit"
            options={listWeight}
            onChange={setWeightUnit}
            components={{ DropdownIndicator }}
            classNamePrefix="single-select"
            className="single-select-container"
          />
        </FormGroup>
      </div>
      <div className="group-inline">
        <Field name="bodyFat" className="body-fat-group">
          {({ field, meta: { touched, error } }) => (
            <FormGroup>
              <label>Body Fat</label>
              <input className={classNames({ error: error && touched })} type="number" {...field} />
              {error && touched && <div className="error error-msg">{error}</div>}
            </FormGroup>
          )}
        </Field>
        <span className="macros-model-divider" />
        <FormGroup className="macros-unit">
          <label dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
          <Select
            value={bodyFatUnit}
            key="body-fat-unit"
            isDisabled
            options={listBodyFat}
            onchange={setBodyFatUnit}
            components={{ DropdownIndicator: null }}
            classNamePrefix="single-select"
            className="single-select-container"
          />
        </FormGroup>
      </div>
      <div className="group-inline">
        <Field
          name="activityLevel"
          label="Activity Level"
          component={ReactSelectFormik}
          menuPlacement="top"
          options={[
            {
              value: 'sedentary',
              label: 'Sedentary (little or no exercise)',
            },
            {
              value: 'lightly-active',
              label: 'Lightly active (exercise 1-3 days/week)',
            },
            {
              value: 'moderately-active',
              label: 'Moderately active (exercise 3-5 days/week)',
            },
            {
              value: 'very-active',
              label: 'Very active (intense exercise 6-7 days a week)',
            },
            {
              value: 'extremely-active',
              label: 'Extremely active (2+ hrs of intense physical activity daily)',
            },
          ]}
          placeholder="Choose activity level"
        />
      </div>
      <div className="group-inline">
        <Field
          name="goal"
          label="Goal"
          component={ReactSelectFormik}
          menuPlacement="top"
          options={[
            {
              value: 'lose-weight',
              label: 'Lose weight',
            },
            {
              value: 'maintain-weight',
              label: 'Maintain weight and build muscle',
            },
            {
              value: 'gain-weight',
              label: 'Gain weight and build muscle',
            },
          ]}
          placeholder="Choose goal"
        />
      </div>
    </>
  );
};

CalculateForm.propTypes = {};

export default CalculateForm;
