import { HIDDEN_SECTION } from 'constants/commonData';
import Request from 'configs/request';
import { toast } from 'react-toastify';

export const Types = {
  LOAD_SECTION_LIBRARY_SUCCESS: 'LOAD_SECTION_LIBRARY_SUCCESS',
  LOAD_SECTION_LIBRARY_FAILED: 'LOAD_SECTION_LIBRARY_FAILED',
  ADD_SECTION_TO_LIBRARY_SUCCESS: 'ADD_SECTION_TO_LIBRARY_SUCCESS',
  ADD_SECTION_TO_LIBRARY_FAILED: 'ADD_SECTION_TO_LIBRARY_FAILED',
  SELECT_SECTION_SUCCESS: 'SELECT_SECTION_SUCCESS',
  SELECT_SECTION_FAILED: 'SELECT_SECTION_FAILED',
  SUCCESS_DELETE_SECTION: 'SUCCESS_DELETE_SECTION',
  FAILED_DELETE_SECTION: 'FAILED_DELETE_SECTION',
  SELECT_SECTION_RESET: 'SELECT_SECTION_RESET',
};

const loadSectionSuccess = list => ({ type: Types.LOAD_SECTION_LIBRARY_SUCCESS, payload: { list } });

const loadSectionFailed = error => ({ type: Types.LOAD_SECTION_LIBRARY_FAILED, payload: { error } });

const saveSectionToLibrarySuccess = section => ({
  type: Types.ADD_SECTION_TO_LIBRARY_SUCCESS,
  payload: { section },
});

const saveSectionToLibraryFailed = error => ({
  type: Types.ADD_SECTION_TO_LIBRARY_FAILED,
  payload: { error },
});

const selectSectionSuccess = section => ({ type: Types.SELECT_SECTION_SUCCESS, payload: { section } });

const selectSectionFailed = error => ({ type: Types.SELECT_SECTION_FAILED, payload: { error } });

export const getSections = params => {
  return Request.get(
    { url: '/api/training_section/list', params },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(loadSectionSuccess(data.filter(s => s.title && s.type !== HIDDEN_SECTION)));
    },
    (error, { dispatch }) => dispatch(loadSectionFailed(error)),
  );
};

export const saveSectionToLibrary = section => {
  return Request.post(
    { url: '/api/training_section/saveToLibrary', data: { section } },
    true,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(saveSectionToLibrarySuccess(data));
      toast.success('Section saved to library!', { autoClose: 3000 });
    },
    (error, { dispatch }) => dispatch(saveSectionToLibraryFailed(error)),
  );
};

export const selectSection = sectionid => {
  return Request.post(
    { url: '/api/training_section/use', data: { section: sectionid } },
    false,
    (response, { dispatch }) => {
      const { data } = response.data;
      dispatch(selectSectionSuccess(data));
    },
    (error, { dispatch }) => dispatch(selectSectionFailed(error)),
  );
};

export const deleteSection = sectionId => {
  return Request.delete(
    { url: `/api/training_section/delete/${sectionId}` },
    true,
    (response, { dispatch }) => dispatch({ type: Types.SUCCESS_DELETE_SECTION, payload: { sectionId } }),
    (error, { dispatch }) => dispatch(dispatch({ type: Types.FAILED_DELETE_SECTION })),
  );
};

export const resetSection = () => ({
  type: Types.SELECT_SECTION_RESET,
});
