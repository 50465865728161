import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { STATUS_HABIT } from '../../../../constants';
import { connect } from 'react-redux';
import * as S from './style';

const ProgressCalendar = ({
  day,
  percent = 0,
  status = '',
  amount = null,
  textDesc = '',
  strokeWidth = 5,
  width = 34,
  height = 34,
  className,
  handleChoiceDay,
  daySelected,
}) => {
  return (
    <S.ProgressCalendar
      daySelected={daySelected}
      status={status}
      width={width}
      height={height}
      className={className}
      onClick={handleChoiceDay}
    >
      <S.ProgressBar
        status={status}
        removeToday={!amount}
        removeTextToday={!amount && status === STATUS_HABIT.DONE}
        className={className}
      >
        <CircularProgressbar value={percent} strokeWidth={strokeWidth} />
        <span>{day}</span>
      </S.ProgressBar>
      {amount && amount >= 2 && (
        <S.AmountDone>
          {Array.from(Array(amount)).map((_, index) => {
            return <span key={index}></span>;
          })}
        </S.AmountDone>
      )}
      {textDesc && <S.TooltipCustom>{textDesc}</S.TooltipCustom>}
    </S.ProgressCalendar>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressCalendar);
