// Libs
import React, { useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

// Constants
import { NUTRITION_TAB } from '../constants';

import { hasNutrientData } from '../helper';

// Components
import UnitInput from '../UnitInput';
import MacroNutrient from '../MacroNutrient';
import NutritionInfoDetail from '../NutritionInfoDetail';
import Toggle from 'shared/FormControl/Toggle';

// Assets
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow_up_bold.svg';

// Style
import * as S from './style';

const tabs = [
  {
    key: NUTRITION_TAB.MACRO_NUTRIENTS,
    label: 'Macronutrients',
  },
  {
    key: NUTRITION_TAB.OTHER_NUTRIENTS,
    label: 'Other Nutrients',
  },
];

const NutritionInfo = props => {
  const {
    handleChangeNutrition = () => {},
    MacroNutrients,
    OtherNutrients,
    listOtherNutrients,
    changeAutoCalculated = () => {},
    autoCalculated = false,
    toggleConfirmModal = () => {},
    ingredientText = '',
    autoCalculatedNutrition = {},
  } = props;

  const [tab, setTab] = useState(tabs[0]);
  const [hideOtherNutrient, setHideOtherNutrient] = useState(true);

  const isMacro = tab.key === NUTRITION_TAB.MACRO_NUTRIENTS;

  const onTabClick = data => {
    setTab(data);
  };

  const onChange = (e, type) => {
    handleChangeNutrition(e, type, tab);
  };

  const handleToggleOtherNutrient = () => {
    setHideOtherNutrient(!hideOtherNutrient);
  };

  const renderOtherNutrient = () => {
    const renderNutrientList = nutrients => {
      return nutrients.map(item => {
        const { name, key, unit } = item;
        const { value } = OtherNutrients.find(nutrient => nutrient.type === key) || {};

        return (
          <S.Item key={key}>
            <UnitInput label={name} unit={unit} defaultValue={value} onChange={e => onChange(e, key)} />
          </S.Item>
        );
      });
    };

    const baseNutrient = listOtherNutrients.filter(item => item.type === 'other' && item.is_pin_on_top);
    const otherNutrient = listOtherNutrients.filter(item => item.type === 'other' && !item.is_pin_on_top);

    const resultBase = renderNutrientList(baseNutrient);
    const resultOther = !hideOtherNutrient ? renderNutrientList(otherNutrient) : <></>;

    return (
      <S.OtherNutrientWrapper>
        <div className="nutrient-list">{resultBase}</div>
        <div
          className={classNames('toggle-other-nutrient', { ['is-hide']: hideOtherNutrient })}
          onClick={handleToggleOtherNutrient}
        >
          More Nutrients <ArrowDownIcon className="toggle-icon" />
        </div>
        <div className="nutrient-list">{resultOther}</div>
      </S.OtherNutrientWrapper>
    );
  };

  const handleToggleAutoCalculated = () => {
    return toggleConfirmModal(
      true,
      <S.InfoModal
        title={autoCalculated ? 'Switch to Manual Calculation' : 'Switch to Auto-Calculation'}
        content={
          autoCalculated
            ? 'This will reset all auto-calculated nutritional information, requiring you to input them manually. Would you like to continue?'
            : `This will overwrite your manual inputs to the recipe's nutrition information and remove any unsupported ingredient units for automatic nutrition calculation. Would you like to continue?`
        }
        onConfirm={handleConfirmToggleAutoCalculated}
        confirmButtonTitle="Confirm"
        headerIcon="/images/warning_yellow_icon.svg"
        noBorder
        hasCloseIcon
        isPressEsc
        isCloseOnDimmer={false}
      />,
    );
  };

  const handleConfirmToggleAutoCalculated = () => {
    changeAutoCalculated(!autoCalculated);
  };

  return (
    <S.NutritionInfoWrapper>
      <S.AutoCalculated isAutoCalculated={autoCalculated}>
        <span>Auto-Calculated</span>
        <Toggle
          width={26}
          height={16}
          checked={autoCalculated}
          disabled={ingredientText}
          onChange={handleToggleAutoCalculated}
        />
      </S.AutoCalculated>
      {autoCalculated ? (
        <>
          <NutritionInfoDetail
            MacroNutrients={get(autoCalculatedNutrition, 'macro_nutrients', [])}
            OtherNutrients={get(autoCalculatedNutrition, 'other_nutrients', [])}
            listOtherNutrients={listOtherNutrients}
            autoCalculated={autoCalculated}
          />
        </>
      ) : (
        <>
          <S.TabHeader>
            {tabs.map(item => {
              const { key, label } = item;
              return (
                <S.TabHeaderItem onClick={() => onTabClick(item)} active={key === tab.key} key={key}>
                  {label}
                  <span>(per serving)</span>
                </S.TabHeaderItem>
              );
            })}
          </S.TabHeader>
          <S.TabContent isMacro={isMacro}>
            {isMacro ? <MacroNutrient MacroNutrients={MacroNutrients} onChange={onChange} /> : renderOtherNutrient()}
          </S.TabContent>
        </>
      )}
    </S.NutritionInfoWrapper>
  );
};

export default NutritionInfo;
