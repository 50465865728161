import { Types } from './actions';

const INITIAL_STATE = {
  listTaskLibrary: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.TASK_LIBRARY_FETCH_LIST_TASK:
      return {
        ...state,
        listTaskLibrary: [...payload.data],
      };

    default:
      return state;
  }
};
