import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const Wrapper = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  .macro-icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
`;

export const LabelNutrition = styled.div`
  ${baseText}
  margin-right: 5px;
  user-select: none;
`;

export const List = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  ${baseText}
  position: relative;
  font-weight: 400;
  line-height: 23px;
  padding: 0 10px 0 8px;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #a3a3b5;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`;

export const Label = styled.div`
  ${baseText}
  margin-right: 5px;
  font-size: 15px;
  line-height: 23px;
`;

export const Value = styled.div`
  ${baseText}
  margin-right: 3px;
  font-size: 15px;
  line-height: 23px;
  ${props =>
    props.isEmpty &&
    css`
      color: #a3a3b5;
    `}
`;
