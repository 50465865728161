import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import { Emoji } from 'emoji-picker-react';

import { getHabitDescription } from 'utils/commonFunction';
import TitleTooltip from 'shared/TitleTooltip';
import {
  getListMostPopular,
  getListMostRecent,
  getListCustom,
  changeQueryParams,
  removeHabitFromLibrary,
  getHabitUnits,
  getListSystem,
} from 'redux/habits/actions';
import { toggleModal, toggleSecondModal, toggleConfirmModal } from 'actions/modal';
import { NewSearchInput } from 'shared/SearchInput';
import TaskAddNew from 'components/TaskAddNew';
import TaskForm from 'components/AutoflowTask/Cell/TaskForm';
import HabitsBuilderPopup from '../TaskCreate/HabitsBuilderPopup';
import { CATEGORIES_HABIT } from '../../constants/habitData';
import { CDN_URL } from 'constants/commonData';

// import icons
import { ReactComponent as EmptyResult } from 'assets/images/no_results_habit.svg';
import { ReactComponent as EmptyHabit } from 'assets/images/no_habit.svg';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left-black.svg';
import { ReactComponent as AddHabit } from 'assets/icons/add_custom_habit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/close_circle.svg';
import HabitSearchIcon from 'assets/icons/habit_search.svg';
import * as S from './style';

const TaskHabit = ({
  onBack,
  date,
  listMostRecent,
  listMostPopular,
  listCustom,
  listYourTeam,
  getListCustom,
  getListMostPopular,
  getListMostRecent,
  changeQueryParams,
  toggleConfirmModal,
  onPopupToggle,
  loading,
  query,
  isEnd,
  total,
  removeHabitFromLibrary,
  data,
  habitUnits,
  getHabitUnits,
  user,
  listSystem,
  getListSystem,
  autoflowType,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');
  const hasTeam = _.get(user, 'team_member', []).length > 1;

  useEffect(() => {
    !listMostPopular.length && getListMostPopular && getListMostPopular();
    getListMostRecent && getListMostRecent();
    if (getListCustom) {
      getListCustom({
        reset: true,
        isSearch: false,
        type: 'custom',
      });
      getListCustom({
        reset: true,
        isSearch: false,
        type: 'in_team',
      });
    }
    getListSystem && getListSystem();
  }, []);

  const onSearchChange = (event, { value }) => {
    const trimmed = value.toLowerCase().trim();
    setSearchText(trimmed);
    if (!_.isEmpty(trimmed)) {
      changeQueryParams({ text_search: trimmed, isSearch: true });
    } else {
      changeQueryParams({ reset: true, isSearch: false, type: 'custom' });
    }
  };
  // In case reuse Handle load more & search
  // const handleLoadMore = event => {
  //   const bottom = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 270;
  //   if (bottom && !loading && !isEnd) {
  //     changeQueryParams({
  //       page: query.page + 1,
  //       per_page: query.per_page,
  //       isSearch: false,
  //       type: 'custom',
  //       sort: query.sort,
  //       sorter: query.sorter,
  //     });
  //   }
  // };
  // const onScrollDebounce = _.debounce(handleLoadMore, 300);
  // const handleScroll = event => {
  //   event.persist();
  //   onScrollDebounce.call(null, event);
  // };

  const handleRemoveHabitFromLibrary = (event, item) => {
    event.stopPropagation();
    toggleConfirmModal(
      true,
      <S.HabitConfirmModal
        date={date}
        noIcon
        title="Delete Custom Habit"
        content="The habit will be removed from your library. Are you sure you want to delete this custom habit?"
        confirmButtonTitle="Delete habit"
        onConfirm={() => removeHabitFromLibrary(item._id)}
        noBorder
        hasHoverState
      />,
    );
  };

  const onClose = () => {
    props.toggleModal(false);
  };

  const onBackToList = () => {
    if (typeof onBack === 'function') {
      onBack();
    } else {
      if (autoflowType) {
        props.toggleModal(true, <TaskForm date={date} autoflowType={autoflowType} />);
      } else {
        props.toggleModal(true, <TaskAddNew date={date} autoflowType={autoflowType} />);
      }
    }
  };

  const onOpenHabitBuilder = e => {
    e.preventDefault();
    if (habitUnits.length) {
      props.toggleModal(true, <HabitsBuilderPopup date={date} autoflowType={autoflowType} onClose={onClose} />);
    } else {
      getHabitUnits &&
        getHabitUnits().then(() => {
          props.toggleModal(true, <HabitsBuilderPopup date={date} autoflowType={autoflowType} onClose={onClose} />);
        });
    }
  };

  const onOpenCustomHabitBuilder = item => {
    if (habitUnits.length) {
      props.toggleModal(
        true,
        <HabitsBuilderPopup
          date={date}
          prevHabit={item}
          showComment={false}
          autoflowType={autoflowType}
          onClose={onClose}
        />,
      );
    } else {
      getHabitUnits &&
        getHabitUnits().then(() => {
          props.toggleModal(
            true,
            <HabitsBuilderPopup
              date={date}
              prevHabit={item}
              showComment={false}
              autoflowType={autoflowType}
              onClose={onClose}
            />,
          );
        });
    }
  };

  const renderList = lists => {
    return lists.map((list, _listId) => (
      <S.SectionContent
        key={_listId}
        disabled={
          list.data.length === 0 ||
          (searchText && list.title !== CATEGORIES_HABIT.YOUR_HABITS) ||
          (!hasTeam && list.title === CATEGORIES_HABIT.YOUR_TEAM_HABITS)
        }
      >
        <S.Section>
          {searchText ? (
            <>Results ({listCustom.length})</>
          ) : (
            <>
              {list.title}
              {[CATEGORIES_HABIT.YOUR_HABITS, CATEGORIES_HABIT.YOUR_TEAM_HABITS].includes(list.title)
                ? ` (${list.data.length})`
                : ''}
            </>
          )}
        </S.Section>
        <div className="habit-wrapper">
          {list.data.map((item, index) => (
            <S.Item key={item._id} onClick={() => onOpenCustomHabitBuilder(item)}>
              <S.Icon>
                <Emoji unified={item.icon_unified} size="32" />
              </S.Icon>
              <S.ItemBox>
                <TitleTooltip
                  className="item-title"
                  tooltipId={`habit-title-lib-${item._id}-${index}-${_listId}`}
                  content={item.title}
                  tag={S.Title}
                  tooltipClassName="title-lib"
                />
                <S.Desc key={item.unit._id}>{getHabitDescription(item)}</S.Desc>
              </S.ItemBox>
              {item.type === 'custom' &&
              user._id === _.get(item, 'author') &&
              [CATEGORIES_HABIT.YOUR_HABITS, CATEGORIES_HABIT.YOUR_TEAM_HABITS].includes(list.title) ? (
                <RemoveIcon
                  className="close-button-item"
                  onClick={event => handleRemoveHabitFromLibrary(event, item)}
                />
              ) : null}
            </S.Item>
          ))}
        </div>
      </S.SectionContent>
    ));
  };

  return (
    <S.ModalWrapper
      size={'tiny'}
      open={true}
      onClose={onClose}
      className="modal--task-library"
      closeOnDimmerClick={false}
      closeIcon={
        <button className="close-button">
          <img src={`${CDN_URL}/images/close_circle.svg`} alt="" />
        </button>
      }
    >
      <Modal.Content>
        <S.Wrapper>
          <S.Header>
            <div className="habit-header-wrapper">
              <div className="button-back">
                <BackIcon onClick={onBackToList} />
                <span>Add new habit</span>
              </div>
              <div className="habit-modal-title" onClick={onOpenHabitBuilder}>
                <AddHabit />
                <a href="OpenHabitBuilder">Create Custom Habit</a>
              </div>
            </div>
            <NewSearchInput
              onChange={_.debounce(onSearchChange, 300)}
              onClearSearch={() => onSearchChange(null, { value: '' })}
              placeholder="Search for a habit"
              searchIconPath={HabitSearchIcon}
            />
          </S.Header>
          <S.Content // Search case and 1st time render case
            noResult={(searchText && !total) || (!searchText && !total && !listMostRecent.length && !listSystem.length)}
          >
            {!loading && (
              <S.SectionWrapper>
                {(searchText && total) ||
                (!searchText && (total || listMostPopular.length || listMostRecent.length || listSystem.length)) ? (
                  <>
                    {renderList([
                      {
                        title: CATEGORIES_HABIT.MOST_RECENT,
                        data: listMostRecent,
                      },
                      {
                        title: CATEGORIES_HABIT.MOST_POPULAR,
                        data: listMostPopular,
                      },
                      {
                        title: CATEGORIES_HABIT.YOUR_HABITS,
                        data: listCustom,
                      },
                      {
                        title: CATEGORIES_HABIT.YOUR_TEAM_HABITS,
                        data: listYourTeam,
                      },
                      ...listSystem.map(item => ({
                        title: item.name,
                        data: item.libraries,
                      })),
                    ])}
                  </>
                ) : searchText && !total ? (
                  <>
                    <EmptyResult />
                    <S.NotFoundText>No results found</S.NotFoundText>
                  </>
                ) : (
                  <>
                    <EmptyHabit />
                    <S.NotFoundText>There is no habit</S.NotFoundText>
                  </>
                )}
              </S.SectionWrapper>
            )}
          </S.Content>
        </S.Wrapper>
      </Modal.Content>
    </S.ModalWrapper>
  );
};

const mapStateToProps = state => {
  const { rootReducer, user } = state;
  return {
    listMostRecent: rootReducer.habits.listMostRecent,
    listMostPopular: rootReducer.habits.listMostPopular,
    listCustom: rootReducer.habits.listCustom,
    listYourTeam: rootReducer.habits.listYourTeam,
    isEnd: rootReducer.habits.isEnd,
    page: rootReducer.habits.page,
    query: rootReducer.habits.query,
    total: rootReducer.habits.total,
    data: rootReducer.habits.data,
    loading: rootReducer.habits.loading,
    user,
    habitUnits: _.get(rootReducer, 'habits.habitUnits'),
    listSystem: _.get(rootReducer, 'habits.listSystem'),
  };
};

const mapDispatchToProps = dispatch => ({
  changeQueryParams: bindActionCreators(changeQueryParams, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleSecondModal: bindActionCreators(toggleSecondModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  getListMostPopular: bindActionCreators(getListMostPopular, dispatch),
  getListMostRecent: bindActionCreators(getListMostRecent, dispatch),
  getListCustom: bindActionCreators(getListCustom, dispatch),
  removeHabitFromLibrary: params => dispatch(removeHabitFromLibrary(params)),
  dispatch,
  getHabitUnits: bindActionCreators(getHabitUnits, dispatch),
  getListSystem: bindActionCreators(getListSystem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskHabit);
