import React from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { Modal } from 'semantic-ui-react';

import * as S from './style';

import MediaModal from 'shared/MediaModal/component';
import { getS3presignedURLFromLocalDatabase } from 'redux/model/actions';
import { CDN_URL } from 'constants/commonData';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={style} onClick={onClick}></div>;
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={style} onClick={onClick}></div>;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const SlideItem = ({ item }) => {
  return (
    <S.SlideItemWrapper src={item.thumbUrl || item.src} noImage={item.noImage}>
      {item.type === 'video' && <MediaModal video={item.src} presigned />}
    </S.SlideItemWrapper>
  );
};

class ExerciseOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    const { exercise } = this.props;

    const items = exercise.picture.map(p => {
      return {
        type: 'image',
        src: p,
      };
    });

    if (exercise.videoLink || exercise.video) {
      items.unshift({
        type: 'video',
        src: exercise.videoLink || exercise.video,
        thumbUrl: exercise.thumbnail_url,
      });
    }

    if (!items.length) {
      this.setState({
        items: [
          {
            type: 'image',
            src: `${CDN_URL}/images/exercise_overview_no_picture.svg`,
            noImage: true,
          },
        ],
      });
    } else {
      const listURLs = [];

      forEach(items, o => {
        listURLs.push(o.src);

        if (o.thumbUrl) {
          listURLs.push(o.thumbUrl);
        }
      });

      this.props
        .getS3presignedURLFromLocalDatabase(listURLs)
        .then(newURls => {
          let counter = 0;
          forEach(items, o => {
            o.src = newURls[counter++];

            if (o.thumbUrl) {
              o.thumbUrl = newURls[counter++];
            }
          });

          this.setState({ items });
        })
        .catch(() => {
          this.setState({ items });
        });
    }
  }

  handleCloseAction = () => {
    this.props.onClose();
  };

  render() {
    const exercise = get(this.props, 'exercise', {});
    const items = get(this.state, 'items', []);

    return (
      <S.ModalWrapper
        open={true}
        onClose={this.handleCloseAction}
        closeOnDimmerClick={false}
        className="exercise-overview"
      >
        <Modal.Header>
          <button
            onClick={() => {
              this.handleCloseAction();
            }}
            className="close-button"
          >
            <img alt="close_circle" src={`${CDN_URL}/images/close_circle.svg`} />
          </button>
        </Modal.Header>
        <Modal.Content>
          <S.SlideContainer>
            <Slider {...settings}>
              {items.map(item => (
                <SlideItem key={item.src} item={item} />
              ))}
            </Slider>
          </S.SlideContainer>
          <S.TitleContainer>
            {exercise.custom && <S.CustomTag className="exercise-overview__custom-tag">Custom</S.CustomTag>}
            <div className="exercise-overview__title">{exercise.title}</div>
          </S.TitleContainer>
        </Modal.Content>
      </S.ModalWrapper>
    );
  }
}

export default connect(null, { getS3presignedURLFromLocalDatabase })(ExerciseOverview);
