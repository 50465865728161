import styled from 'styled-components';

export const FormInfoContent = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
  .tag-wrapper {
    font-family: 'Open Sans';
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    color: #ff6433;
    text-transform: uppercase;
    background: linear-gradient(91.25deg, rgba(255, 76, 0, 0.14) 0.48%, rgba(224, 241, 27, 0.14) 98.93%), #ffffff;
    display: flex;
    align-items: center;
    height: 18px;
    border-radius: 4px;
    padding: 0 4px;
    svg {
      margin-right: 4px;
    }
  }
`;

export const FormInfoLayout = styled.div`
  &.default {
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 3px;
    background-color: #edf0fa;
  }

  &.trigger {
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;

    background: linear-gradient(91.25deg, rgba(255, 76, 0, 0.14) 0.48%, rgba(224, 241, 27, 0.14) 98.93%), #ffffff;
  }
`;

export const FormInfoText = styled.span`
  &.default {
    color: #202870;
    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &.trigger {
    color: #ff6433;
    font-family: 'Open Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;
