import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as S from './style';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import Button from 'shared/FormControl/Button';
import SelectClients from './components/SelectClients';
import BannerImage from './components/BannerImage';
import ConfirmModal from 'shared/ConfirmModal';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow_left_grey.svg';
import { CREATE_NEW_FORUM_BANNER } from '../../constants';
import { Prompt } from 'react-router';

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

function CreateNewForum(props) {
  const { user, push } = props;
  const [assignToGroup, setAssignToGroup] = useState([]);
  const [focusName, setFocusName] = useState(false);
  const [isChanged, setIsChanged] = useStateCallback(false);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    banner: null,
    members: [],
  });
  useEffect(() => {
    for (let key in _.omit(formData, ['banner'])) {
      if (formData.banner || formData[key].length !== 0) {
        setIsChanged(true);
        return;
      }
    }
    setIsChanged(false);
  }, [formData]);

  const [isUploading, setIsUploading] = useState(false);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleTyping = e => {
    setFocusName(true);
    if (formData.name.length === 0) {
      setFocusName(false);
    }
  };

  const handleGoBack = () => {
    props.push('/home/forums');
  };

  const handleDiscardChange = nextLocation => {
    props.toggleConfirmModal(
      true,
      <ConfirmModal
        noBorder
        title="Discard Changes?"
        content={`You have unsaved changes. Would you like to leave this page and discard your changes?`}
        onConfirm={() => {
          setIsChanged(false, () => props.push(nextLocation.pathname));
        }}
        confirmButtonTitle="Discard Changes"
        hasCloseIcon
        hasHoverState
      />,
    );
    return false;
  };

  const handleSelectClient = clients => {
    setFormData({ ...formData, members: clients });
  };

  const handleCreateForum = e => {
    e.preventDefault();
    if (isUploading) return;
    setIsUploading(true);
    let banner = '';
    if (!formData.banner) {
      banner = null;
    } else {
      banner = formData.banner;
    }

    if (assignToGroup.length > 0) {
      _.map(formData.members, item => {
        if (item.typeName !== 'group') assignToGroup.push(item);
      });

      formData.members = assignToGroup;
    }

    props
      .addNewGroup({ ...formData, banner })
      .then(res => {
        const data = _.get(res, 'data.data');
        setIsChanged(false);
        push(`/home/forums/${data._id}/discussion`);
      })
      .finally(() => setIsUploading(false));
  };

  const handleStartUpload = () => {
    setIsUploading(true);
  };

  const handleEndUpload = () => {
    setIsUploading(false);
  };

  const handleUploadSuccess = ({ src }) => {
    setFormData({ ...formData, banner: src });
    setIsUploading(false);
  };

  return (
    <S.Wrapper>
      <Prompt when={isChanged} message={handleDiscardChange} />
      <S.FormContainer>
        <Form onSubmit={handleCreateForum}>
          <S.FormHeader>
            <div className="title">Create New Forum</div>

            <BannerImage
              notAllowImgGif
              key="banner_image"
              onUploadSuccess={handleUploadSuccess}
              onStartUpload={handleStartUpload}
              onEndUpload={handleEndUpload}
              src={formData.banner ? formData.banner : CREATE_NEW_FORUM_BANNER}
              uploadKey="media"
            />
          </S.FormHeader>
          <S.FormContent>
            <Form.Field>
              <S.Label>FORUM NAME</S.Label>
              <S.InputContainer>
                <S.Input
                  id="name"
                  placeholder="e.g. Everfit community"
                  onChange={handleFieldChange}
                  onKeyUp={handleTyping}
                  maxLength={255}
                  autoFocus
                />
                {focusName && <span class="character-count">{`${formData.name.length}/255`}</span>}
              </S.InputContainer>
            </Form.Field>
            <Form.Field>
              <S.Label>DESCRIPTION</S.Label>
              <S.DescriptionInput
                id="description"
                onChange={handleFieldChange}
                placeholder="Tell us something about your group"
              />
            </Form.Field>
            <Form.Field>
              <S.Label>ADD MEMBERS</S.Label>
              <SelectClients
                id="members"
                onSelectClient={handleSelectClient}
                onSetAssignToGroup={setAssignToGroup}
                currentEmail={user.email || null}
              />
            </Form.Field>
          </S.FormContent>
          <S.FormNavigation>
            <Button textOnly onClick={handleGoBack} type="button">
              <LeftArrowIcon />
              <span> Go back</span>
            </Button>
            <S.SubmitButton disabled={!formData.name || isUploading} purple type="submit">
              Create Forum
            </S.SubmitButton>
          </S.FormNavigation>
        </Form>
      </S.FormContainer>
    </S.Wrapper>
  );
}

export default CreateNewForum;
