import styled, { css } from 'styled-components';
import TextEditable from 'shared/TextEditable';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
`;

export const ImageWrapper = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  transform: translate(8px);
  z-index: 1;

  &:first-child {
    transform: translate(32px);
    z-index: 3;
  }

  &:nth-child(2) {
    transform: translate(20px);
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #fff;
  }

  .sb-avatar__text {
    border: 4px solid #fff;
    border-radius: 50%;
  }
`;

export const OthersImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ececec;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #273d52;
  margin-top: 4px;

  span {
    width: 100%;
  }
`;

export const UploadImageButton = styled.div`
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 15px 8px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #777;
  position: absolute;
  top: 29px;
  left: 131px;
  z-index: 4;
  opacity: 0;
`;

export const GroupImageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 5px;
  transform: translateX(-18px);
  width: 100%;

  ${ImageWrapper} {
    &.only-one {
      transform: translateX(18px);
      img {
        object-fit: cover;
      }
    }
    &.only-two {
      &:first-child {
        transform: translateX(24px);
      }
      &:nth-child(2) {
        transform: translateX(12px);
      }
    }
  }

  input {
    width: 0;
  }

  ${props =>
    !props.viewTeammate &&
    css`
      &:hover {
        cursor: pointer;
        opacity: 1 !important;

        ${ImageWrapper} {
          opacity: 0.7;
        }

        ${OthersImage} {
          opacity: 0.7;
        }

        ${UploadImageButton} {
          opacity: 1;
        }
      }
    `}
`;

export const GroupNameInput = styled(TextEditable)`
  &.text-editable__wrapper {
    width: 100%;

    .text-editable__container {
      padding: 5px 15px 6px 16px;

      &:hover:not(.text-editable__container--active):not(.text-editable__container--invalid),
      &--active {
        border: 1px solid #f2f2f2;
        border-radius: 5px;
        background-color: #fff;

        ${props =>
          props.readOnly &&
          css`
            border-color: transparent;
          `}
      }

      ${props =>
        props.value &&
        css`
          padding-top: 2px;
        `}

      .text-editable__input {
        font-family: 'Open Sans';
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #273d52;
        word-break: break-word;
        caret-color: transparent;

        ::before {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #273d52;
          opacity: 0.4;
        }
      }
      &.text-editable__container--active {
        .text-editable__input {
          caret-color: #000;
        }
      }
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 10px;

  .btn {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    min-width: 30px;

    :hover {
      text-decoration: underline;
    }

    &__cancel,
    &__cancel:hover {
      color: #000;
    }

    &__save,
    &__save:hover {
      color: #5f5bca;
      font-weight: 600;
    }
  }
`;
