import { MenuTrigger } from 'shared/Icons';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 0 0;
  overflow: hidden;
  background: #fff;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000;
  }
`;

export const Content = styled.div`
  padding-top: 16px;
  padding-right: 15px;
  padding-left: 20px;
  max-height: calc(100% - 30px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px !important;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px 4px 8px 12px;

  ${props =>
    props.active &&
    css`
      padding: 7px 3px 7px 11px;
      border: 1px solid #5e59ff;
    `}
  ${props =>
    props.isWelcome &&
    css`
      margin-bottom: 16px;
    `}

  svg {
    width: 24px;
  }

  .evf-dropdown__option {
    padding: 6px 26px 6px 12px;
  }

  .sync-icon {
    flex-shrink: 0;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #202353;

  ${props =>
    props.bold &&
    css`
      font-weight: 600;
      display: inline-block;
      width: 20px;
    `}

  ${props =>
    props.name &&
    css`
      width: 160px;
      white-space: pre-wrap;
      word-break: break-word;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
    `}
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: transparent;
  width: 3px;
  height: 3px;
  transform: rotate(90deg);

  .dot,
  .dot::before,
  .dot::after {
    background-color: #728096;
  }

  &:hover {
    .dot,
    .dot::before,
    .dot::after {
      background-color: #000;
    }
  }
`;

export const Trigger = styled.div`
  width: 20px;
  height: 12px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  transform: rotate(90deg);

  .dots {
    width: 3px;
    height: 3px;
    position: relative;
    border-radius: 50%;
    background-color: #dedede;

    ::before,
    ::after {
      content: '';
      width: 3px;
      height: 3px;
      position: absolute;
      border-radius: 50%;
      background-color: inherit;
      top: 0;
      left: -5px;
    }

    ::after {
      left: 5px;
    }
  }

  :hover {
    background-color: #f2f2f2;

    .dots {
      background-color: #726ee4;
    }
  }
`;

export const ItemWrapper = styled.div``;
