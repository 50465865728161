import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { getMediaType } from 'utils/commonFunction';
import { MEDIA_FILE_REJECTED_CONTENT } from 'constants/commonData';

const FEATURE_REPLY_AT = ['log_activity', 'assignment_result'];

export const initLocalData = (params, feature) => {
  const { user, item, index, replyMessage } = params;
  const { file, objectURL, dimensions, configs, uploadUrl } = item;
  const timestamp = new Date().getTime();
  const data = new FormData();
  data.append('attachment', file);
  const isHasFeature = !isEmpty(feature);
  return {
    uploadConfigs:
      isHasFeature && FEATURE_REPLY_AT.includes(feature)
        ? {
            url: uploadUrl,
            method: 'put',
            data: file,
            headers: { 'Content-Type': file.type },
          }
        : { url: '/api/comment/upload', data, method: 'post' },
    attachment: objectURL,
    attachment_type: getMediaType(file),
    attachment_width: get(dimensions, 'width'),
    attachment_height: get(dimensions, 'height'),
    _id: `new_media__${timestamp}__${index}`,
    createdAt: timestamp,
    author: user,
    isLocalFile: true,
    content: MEDIA_FILE_REJECTED_CONTENT,
    replyMessage,
    loadingReply: true,
    configs: isHasFeature && FEATURE_REPLY_AT.includes(feature) ? configs : null,
  };
};

export const initLocalDataFromS3 = (params, feature) => {
  const { user, item, index, replyMessage } = params;
  const { file, objectURL, dimensions, configs, uploadUrl } = item;
  const timestamp = new Date().getTime();

  const isHasFeature = !isEmpty(feature);
  return {
    uploadConfigs: {
      url: uploadUrl,
      method: 'put',
      data: file,
      headers: { 'Content-Type': file.type },
    },
    attachment: objectURL,
    attachment_type: getMediaType(file),
    attachment_width: get(dimensions, 'width'),
    attachment_height: get(dimensions, 'height'),
    _id: `new_media__${timestamp}__${index}`,
    createdAt: timestamp,
    author: user,
    isLocalFile: true,
    content: MEDIA_FILE_REJECTED_CONTENT,
    replyMessage,
    loadingReply: true,
    configs: isHasFeature && FEATURE_REPLY_AT.includes(feature) ? configs : null,
  };
};

export const getAttachmentIDFromUrl = (url = '') => {
  const attachment = url.substring(0, url.indexOf('?X-Amz-Algorithm'));
  const attachmentID = attachment ? attachment.split('/') : [];
  if (attachmentID.length === 0) {
    return attachmentID;
  }
  const lastItem = attachmentID[attachmentID.length - 1];
  return lastItem.substring(0, lastItem.indexOf('.'));
};

export const initDataList = (data = []) => {
  const result = data.map(item => {
    const latestReply = get(item, 'latest_reply');
    return { ...item, latestReplyList: latestReply ? [latestReply] : [], isViewMore: false };
  });
  return result;
};

export const convertDataAddLocal = (data = [], commentId = '', localComments = []) => {
  handleScrollToReply(commentId);
  const result = data.map(item => {
    const latestReplyList = get(item, 'latestReplyList', []);
    if (item._id === commentId) {
      return {
        ...item,
        latestReplyList: latestReplyList.concat(localComments),
        total_replies: item.total_replies + localComments.length,
        isViewMore: item.isViewMore || false,
      };
    }
    return item;
  });
  return result;
};

export const convertDataCancelUpload = (data = [], comment = {}) => {
  const commentId = get(comment, 'replyMessage.commentId', '');
  const result = data.map(item => {
    if (commentId === get(item, '_id', '')) {
      return {
        ...item,
        total_replies: item.total_replies - 1,
        latestReplyList: get(item, 'latestReplyList', []).filter(c => c._id !== get(comment, '_id', '')),
      };
    }
    return item;
  });
  return result;
};

export const convertDataUploadSuccess = (list = [], data = {}, comment = {}) => {
  const { _id: commentIdLocal = '' } = comment;
  const { content = '', attachment_source = '', _id: commentIdResponse = '' } = data;
  const result = list.map(itemComment => {
    if (get(itemComment, '_id', '') === get(data, 'item', '')) {
      let latestReplyList = get(itemComment, 'latestReplyList', []);
      const isMedia = content === MEDIA_FILE_REJECTED_CONTENT && attachment_source !== 'giphy';
      if (isMedia) {
        latestReplyList = latestReplyList.map(item => {
          const { _id = '' } = item;
          return _id === commentIdLocal ? { ...data } : item;
        });
      } else {
        latestReplyList.push(data);
      }
      return {
        ...itemComment,
        latestReplyList: isArray(latestReplyList) ? latestReplyList : [data],
      };
    }
    return itemComment;
  });
  return result;
};

export const convertDataGiphyAndText = (list = [], data = {}, commentId = '') => {
  handleScrollToReply(commentId);
  const result = list.map(item => {
    if (get(item, '_id', '') === commentId) {
      const latestReplyList = get(item, 'latestReplyList', []);
      latestReplyList.push(data);
      return {
        ...item,
        total_replies: item.total_replies + 1,
        latestReplyList: isArray(latestReplyList) ? latestReplyList : [data],
      };
    }
    return item;
  });
  return result;
};

export const convertDataViewMore = (list = [], params = {}, data = []) => {
  const result = list.map(item => {
    if (get(item, '_id', '') === get(params, 'comment_id', '')) {
      const latestReplyList = get(item, 'latestReplyList', []);
      const isViewMore = get(item, 'isViewMore', false);
      const latestReplyListConvert = [...data, ...latestReplyList];
      return {
        ...item,
        isViewMore: true,
        latestReplyList: latestReplyListConvert,
      };
    }
    return item;
  });
  return result;
};

export const handleScrollToReply = (
  idComment = '',
  time = 1000,
  option = {
    behavior: 'smooth',
    block: 'end',
  },
) => {
  setTimeout(() => {
    const ele = document.getElementById(idComment);
    ele && ele.scrollIntoView(option);
  }, time);
};
