import React from 'react';
import { ReactComponent as MetricRowIcon } from 'assets/icons/metric_sketeton_row.svg';

import { MetricItemWrapper } from '../NewGroupModal/style';

function MetricSkeleton() {
  return (
    <MetricItemWrapper>
      <MetricRowIcon />
    </MetricItemWrapper>
  );
}

export default MetricSkeleton;
