// Lib
import React from 'react';

// Shared
import SingleChoiceSummary from './SingleChoiceSummary';
import LinearScaleSummary from './LinearScaleSummary';
import ShortAnswerSummary from './ShortAnswerSummary';
import SignatureSummary from './SignatureSummary';
import DateAnswerSummary from './DateAnswerSummary';
import MetricAnswerSummary from './MetricAnswerSummary';

// Constants
import { QUESTION_TYPES } from 'constants/commonData';
import { pluralize } from 'utils/commonFunction';

// Assets
import * as S from '../styles';

const renderAnswerByType = (data, dateFormat) => {
  const { type = '' } = data || {};

  switch (type) {
    case QUESTION_TYPES.SINGLE_CHOICE:
      return <SingleChoiceSummary data={data} />;
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return <SingleChoiceSummary data={data} />;
    case QUESTION_TYPES.LINEAR_SCALE:
      return <LinearScaleSummary data={data} />;
    case QUESTION_TYPES.SHORT_ANSWER:
      return <ShortAnswerSummary data={data} />;
    case QUESTION_TYPES.LONG_ANSWER:
      return <ShortAnswerSummary data={data} />;
    case QUESTION_TYPES.SIGNATURE:
      return <SignatureSummary data={data} />;
    case QUESTION_TYPES.DATE_TIME:
      return <DateAnswerSummary data={data} dateFormat={dateFormat} />;
    case QUESTION_TYPES.METRIC:
      return <MetricAnswerSummary data={data} />;

    default:
      return null;
  }
};

export default function Summary({ summaryData, dateFormat }) {
  return (
    <S.FormRespondContainer>
      {summaryData.map((question, index) => {
        const { _id = '', title = '', summary } = question || {};
        const { no_responses = 0 } = summary || {};

        return (
          <S.AnswerContainer key={_id} className="summary">
            <div className="header" id={`${_id}-header`}>
              <div className="title">
                {index + 1}. {title}
              </div>
              <div className="responses-count">
                {no_responses} {pluralize('response', no_responses)}
              </div>
            </div>
            {renderAnswerByType(question, dateFormat)}
          </S.AnswerContainer>
        );
      })}
    </S.FormRespondContainer>
  );
}
