import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import { withRouter } from 'react-router';

import ImageButton from 'shared/ImageButton';
import UpgradePath from 'shared/UpgradePath';
import { convertSegmentToFilters } from 'utils/commonFunction';
import { CDN_URL, DEFAULT_CLIENT_FILTERS } from 'constants/commonData';
import LastActivityFilter from 'components/LastActivityFilter';
import LastAssignedFilter from 'components/LastAssignedFilter';
import OwnerShipFilter from 'components/OwnerShipFilter';
import GroupFilter from 'components/GroupFilter';
import SegmentModal from 'components/SegmentModal';

import BasicFilter from './BasicFilter';
import './style.scss';

const Categories = [
  { value: null, label: 'All' },
  { value: 'Online', label: 'Online' },
  { value: 'In-Person', label: 'In-Person' },
  { value: 'Hybrid', label: 'Hybrid' },
];

const Status = [
  { value: null, label: 'All' },
  { value: 1, label: 'Connected' },
  { value: 0, label: 'Pending' },
  { value: -1, label: 'Offline' },
];

class ClientFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimize: false,
    };
  }

  componentDidMount() {
    this.setMinimize();
    this.handleFilterGroupFromURL();
  }

  componentDidUpdate(prevProps) {
    const { filters, groups, user } = this.props;

    if (user !== prevProps.user || groups !== prevProps.groups || filters !== prevProps.filters) {
      this.setMinimize();
    }
  }

  handleFilterGroupFromURL = () => {
    const { changeClientFilter } = this.props;
    const searchParams = new URLSearchParams(window.location.search);
    const purchase = searchParams.get('groupId');

    purchase && changeClientFilter && changeClientFilter('groups', [purchase]);

    searchParams && this.handleReplaceUrl();
  };

  handleReplaceUrl = () => {
    const { history } = this.props;
    history.replace('/home/client');
  };

  isSegmentChange = () => {
    const { selectedSegment, filters, user } = this.props;
    let originFilter = selectedSegment ? convertSegmentToFilters(selectedSegment, user) : DEFAULT_CLIENT_FILTERS;

    if (typeof originFilter.ownerShip === 'string' && typeof filters.ownerShip === 'string') {
      originFilter.ownerShip = filters.ownerShip;
    }

    if (!isEqual(filters.groups, originFilter.groups)) {
      return true;
    }

    if (
      originFilter.last_activity !== filters.last_activity ||
      originFilter.last_activity_type !== filters.last_activity_type
    ) {
      return true;
    }

    if (
      originFilter.last_assigned_workout !== filters.last_assigned_workout ||
      originFilter.last_assigned_workout_type !== filters.last_assigned_workout_type
    ) {
      return true;
    }

    if ((filters.client_type || originFilter.client_type) && filters.client_type !== originFilter.client_type) {
      return true;
    }

    const originStatus =
      originFilter.client_connection || parseInt(originFilter.client_connection) === 0
        ? parseInt(originFilter.client_connection)
        : '';
    const newStatus =
      filters.client_connection || parseInt(filters.client_connection) === 0 ? parseInt(filters.client_connection) : '';

    if (newStatus !== originStatus) {
      return true;
    }

    if (!isEqual(filters.ownerShip, originFilter.ownerShip)) {
      return true;
    }

    return false;
  };

  setMinimize = () => {
    const { minimize } = this.state;

    if (!minimize) {
      return;
    }

    const { filters, user, groups } = this.props;
    const hasTeam = get(user, 'team_member', []).length > 1;
    const unSelecteds = [];
    const exceptFields = ['is_archived', 'last_activity_type', 'last_activity'];

    if (groups && groups.length) {
      exceptFields.push('groups');
    }

    if (!hasTeam) {
      exceptFields.push('ownerShip');
    }

    forEach(filters, (value, key) => {
      if (!exceptFields.includes(key)) {
        if (!value || (isArray(value) && !value.length)) {
          unSelecteds.push(key);
        }
      }
    });

    if (isNaN(parseInt(filters.last_activity))) {
      unSelecteds.push('last_activity');
    }

    this.setState({ minimize: !!unSelecteds.length });
  };

  renderSegmentActions = () => {
    const { user, toggleModal, selectedSegment, selectSegment } = this.props;
    const viewingSharedSegment = !!selectedSegment && selectedSegment.trainer._id !== user._id;
    const segmentChange = this.isSegmentChange();

    return (
      <>
        {segmentChange ? (
          <ImageButton
            image={`${CDN_URL}/images/reset.svg`}
            title={'Reset'}
            className="bg-transparent"
            onClick={() => {
              if (!selectedSegment) {
                return selectSegment(null, Object.assign({}, DEFAULT_CLIENT_FILTERS, { ownerShip: user._id }));
              }

              let oldFilters = convertSegmentToFilters(selectedSegment, user);

              return selectSegment(selectedSegment._id, oldFilters);
            }}
          />
        ) : null}
        {viewingSharedSegment || segmentChange ? (
          <UpgradePath pathName="segment" fallback={<></>}>
            <ImageButton
              image={`${CDN_URL}/images/segment.svg`}
              title={'Save segment'}
              className="bg-transparent save-segment"
              onClick={() => toggleModal(true, <SegmentModal />)}
            />
          </UpgradePath>
        ) : null}
      </>
    );
  };

  renderFilters = () => {
    const { filters, changeClientFilter, groups, user, clients } = this.props;
    const { minimize } = this.state;
    const hasTeam = user.team_member && user.team_member.length > 1;

    return (
      <>
        {!minimize || filters.client_type ? (
          <BasicFilter
            type="client_type"
            value={filters.client_type}
            name="Category"
            options={Categories}
            onChange={changeClientFilter}
          />
        ) : null}
        {!minimize || !isNaN(parseInt(filters.client_connection)) ? (
          <BasicFilter
            type="client_connection"
            value={filters.client_connection}
            name="Status"
            options={Status}
            onChange={changeClientFilter}
          />
        ) : null}
        {hasTeam && (!minimize || filters.ownerShip) ? <OwnerShipFilter /> : null}
        {groups && groups.length && (!minimize || filters.groups.length) ? <GroupFilter clients={clients} /> : null}
        {!minimize || !isNaN(parseInt(filters.last_activity)) ? <LastActivityFilter /> : null}
        {!minimize || !isNaN(parseInt(filters.last_assigned_workout)) ? <LastAssignedFilter /> : null}
        <div className="minimize" onClick={() => this.setState({ minimize: !minimize })}>
          {minimize ? 'Show filters' : 'Hide filters'}
        </div>
        {this.renderSegmentActions()}
      </>
    );
  };

  renderArchivedFilters = () => {
    const { user } = this.props;
    const hasTeam = user.team_member && user.team_member.length > 1;

    if (hasTeam) {
      return <OwnerShipFilter />;
    }

    return null;
  };

  render() {
    const { selectedSegment } = this.props;
    const isArchivedFilter = !!selectedSegment && selectedSegment.is_archived;

    return (
      <div className="client-filter">{isArchivedFilter ? this.renderArchivedFilters() : this.renderFilters()}</div>
    );
  }
}

export default withRouter(ClientFilters);
