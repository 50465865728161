import React from 'react';
import { Icon, Popup, Loader, Label, Image } from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { push } from 'connected-react-router';
import moment from 'moment';

import { getAssignmentDetail, WORKOUT_TYPE } from 'actions/workout/getWorkouts';
import { CDN_URL, SECTION_FORMAT_KEY } from 'constants/commonData';
import ExerciseDetail from 'components/ExerciseDetail';
import SelectProgramModal from 'components/SelectProgramModal';
import { Workout, ExerciseSet } from 'types/model';
import { toggleModal, toggleConfirmModal } from 'actions/modal';
import SectionOverview from 'components/SectionOverview';
import { maximumRenderedExercises } from 'helpers/workout';
import AutoflowInfo from 'shared/AutoflowInfo';
import AssignWorkoutModal from './AssignWorkoutModal';
import OnboardingTooltip, { Content } from 'components/Onboarding/Tooltip';
import ConfirmModal from 'shared/ConfirmModal';
import AssignmentDraggable from './components/AssignmentDraggable';
import UpgradePath from 'shared/UpgradePath';
import { axiosInstance } from 'configs/request';
import { counterBehindExercises, handleMixpanelTrackingPrintPDF, pluralize } from 'utils/commonFunction';
import { Checkbox } from 'shared/FormControl';
import ProgramInfo from 'shared/ProgramInfo';
import { SharedTooltip } from 'shared/SharedTooltip';
import { ReactComponent as EyeHideIcon } from 'assets/icons/eye_hide_white.svg';
import { ReactComponent as EyeUnhideIcon } from 'assets/icons/eye_unhide_white.svg';

import './styles.scss';
import styles from './styles';
import * as S from './style';

const LIMIT_SELECTED_WORKOUTS = 20;

export default class CalendarCell extends React.Component {
  calendarCell;

  constructor(props) {
    super(props);
    this.workoutCellRef = {};
    this.handleChangeWorkout = this.handleChangeWorkout.bind(this);

    this.debouncePasteSingleWorkout = _.debounce(this.props.pasteCopiedAssignment, 100);
    this.debouncePasteMultipleWorkout = _.debounce(this.props.handlePasteMultipleWorkout, 100);
  }

  state = {
    calendarCellHeight: 0,
  };

  componentDidMount() {
    this.updateCalendarCellHeight();
    window.addEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.calendarCell && this.calendarCell.offsetHeight !== this.state.calendarCellHeight) {
      this.updateCalendarCellHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCalendarCellHeight.bind(this));
  }

  handleMouseEnter = () => {
    const { onMouseEnter } = this.props;
    if (onMouseEnter && typeof onMouseEnter === 'function') {
      onMouseEnter();
    }
  };

  handleChangeWorkout = (currentWorkout, checked) => {
    const {
      handleSelectWorkout,
      selectedWorkout,
      selectedWeek,
      handleResetSelectedWeek,
      handleResetSelectWorkout,
      excludeWorkout,
      copyingAssignment,
      resetCopyAssignment,
    } = this.props;

    if (!_.isEmpty(selectedWeek)) {
      handleResetSelectedWeek();
    }
    if (!_.isEmpty(copyingAssignment)) {
      resetCopyAssignment();
    }

    const lengthWorkout = selectedWorkout.length;
    const currentWorkoutId = _.get(currentWorkout, '_id');
    const currentWorkoutStatus = _.get(currentWorkout, 'status');

    if (checked) {
      if (lengthWorkout < LIMIT_SELECTED_WORKOUTS) {
        const excludeWorkoutIds = [...excludeWorkout];
        if ([1, 2].includes(currentWorkoutStatus) && !_.includes(excludeWorkout, currentWorkoutId)) {
          excludeWorkoutIds.push(currentWorkoutId);
        }
        handleSelectWorkout({
          workoutIds: [...selectedWorkout, currentWorkoutId],
          excludeWorkoutIds: _.union(excludeWorkoutIds, []),
        });
      }
      lengthWorkout === LIMIT_SELECTED_WORKOUTS && this.handlePopupLimitWorkout();
    } else {
      const newSelectedWorkout = _.filter(selectedWorkout, id => id !== currentWorkoutId);
      const newExcludeWorkout = _.filter(excludeWorkout, id => id !== currentWorkoutId);
      handleSelectWorkout({
        workoutIds: newSelectedWorkout,
        excludeWorkoutIds: newExcludeWorkout,
      });
      if (_.isEmpty(newSelectedWorkout)) {
        handleResetSelectWorkout();
      }
    }
  };

  handlePopupLimitWorkout = () => {
    const { selectedWorkout, toggleConfirmModal } = this.props;

    toggleConfirmModal &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          headerIcon={`${CDN_URL}/images/warning_purple.svg`}
          title={`Maximum of ${selectedWorkout.length} workouts selected`}
          content={`You can select up to ${selectedWorkout.length} workouts at a time. Please complete your action and then select more.`}
          onConfirm={() => {}}
          onDeny={() => {}}
          confirmButtonTitle="Okay"
          hideCancelButton
          noBorder={true}
          hasCloseIcon={true}
          className="calendar--max-select"
        />,
      );
  };

  handlePasteWorkout = event => {
    const multiPaste = event.shiftKey;
    const { day } = this.props;
    this.debouncePasteMultipleWorkout({ toDate: day.toFormat('MM-dd-yyyy'), multiPaste });
  };

  render() {
    const {
      day,
      currentWorkouts,
      calendarType,
      selectedClient,
      timezone,
      showAssignWorkoutGuide,
      showAssignProgramGuide,
      weekIndex,
    } = this.props;

    if (!selectedClient) {
      return null;
    }

    const { isViewMore } = this.state;
    const active = DateTime.local().setZone(timezone).toFormat('MM-dd-yyyy') === day.toFormat('MM-dd-yyyy');
    const views = [];
    const showWorkoutGuide =
      showAssignWorkoutGuide && DateTime.local().toFormat('MM-dd-yyyy') === day.toFormat('MM-dd-yyyy');
    let showProgramGuide = false;

    if (showAssignProgramGuide && day.weekdayShort === 'Mon') {
      showProgramGuide = calendarType === 1 ? true : weekIndex === 2;
    }

    if (currentWorkouts && currentWorkouts.length > 0) {
      let maxRendered = this.getMaximumRenderedWorkouts();
      let isShowFull = maxRendered >= currentWorkouts.length;

      for (let i = 0; i < currentWorkouts.length && (isViewMore || i < maxRendered); i++) {
        views.push(this.renderAssignment(currentWorkouts[i], !isShowFull || i < currentWorkouts.length - 1, i));
      }
    }

    const droppableId = `assignment_${day.toFormat('MM-dd-yyyy')}`;

    return (
      <Droppable droppableId={droppableId} type="assignment">
        {(provided, snapshot) => (
          <>
            <div
              ref={provided.innerRef}
              className="calendar-cell"
              style={{ height: `${100 / calendarType}%` }}
              onMouseEnter={this.handleMouseEnter}
            >
              <div
                style={{
                  background: isViewMore ? '#ffffff' : '#f3f5f8',
                  border: isViewMore ? 'solid 1px #726ee4' : '1px solid transparent',
                }}
                className="calendar-cell__content"
              >
                {isViewMore ? (
                  <div className="day-info-over">
                    <div>{day.toFormat('LLL dd')}</div>
                    <div className="add-workout-button">
                      <div
                        className="add-new"
                        onClick={() => this.props.dispatch(toggleModal(true, <AssignWorkoutModal date={day} />))}
                      />
                      {this.renderAddDropDown(day.weekday === 7)}
                    </div>
                  </div>
                ) : (
                  <div className="day-info-over view-more">
                    <Label
                      color={active ? 'purple' : 'transparent'}
                      circular={active}
                      inverted={active}
                      style={{ ...styles.day }}
                      className={active ? 'day-label active' : 'day-label'}
                    >
                      {day.toFormat('dd')}
                    </Label>
                    <div
                      className={classNames('add-workout-button', {
                        show: showWorkoutGuide || showProgramGuide,
                      })}
                    >
                      <div
                        className="add-new"
                        onClick={() => {
                          if (showAssignWorkoutGuide || showAssignProgramGuide) {
                            this.props.hideTooltip();
                          }

                          this.props.dispatch(toggleModal(true, <AssignWorkoutModal date={this.props.day} />));
                        }}
                      />
                      {this.renderAddDropDown(day.weekday === 7, showAssignWorkoutGuide || showAssignProgramGuide)}
                    </div>
                    {showWorkoutGuide ? (
                      <OnboardingTooltip onClose={() => this.props.hideTooltip()}>
                        <Content>
                          Click the ‘+’ to <b>assign a workout</b> to today. Choose a demo workout we’ve added to your
                          library, or choose option to create your own.
                        </Content>
                      </OnboardingTooltip>
                    ) : null}
                    {showProgramGuide ? (
                      <OnboardingTooltip onClose={() => this.props.hideTooltip()}>
                        <Content maxWidth={278}>
                          <div>
                            Hover on the ‘+’ and choose the option to <b>Add a Program</b> starting next Monday. This
                            will show you how easy programming for a client can be.
                          </div>
                        </Content>
                      </OnboardingTooltip>
                    ) : null}
                  </div>
                )}
                {isViewMore && (
                  <Image
                    className="btntask-close-over"
                    src={`${CDN_URL}/images/close_circle.svg`}
                    onClick={() => {
                      this.handleViewMore(false);
                    }}
                    width={16}
                    height={16}
                  />
                )}
                {this.renderCellWithViews(views, isViewMore)}
              </div>
              {provided.placeholder}
            </div>
          </>
        )}
      </Droppable>
    );
  }

  renderCellWithViews = views => {
    const { isViewMore } = this.state;
    const { copyingAssignment, isCopySelectedWorkout } = this.props;

    if (views.length === 0) {
      const { day } = this.props;
      return (
        <Droppable droppableId={`${day.toFormat('MM-dd-yyyy')}`} type="exercise">
          {(provided, snapshot) => {
            return (
              <div
                ref={ref => this.onRefInnerCalendarDiv(ref, provided)}
                style={{
                  background: isViewMore ? '#ffffff' : '#f3f5f8',
                  marginBottom: isViewMore ? 0 : 4,
                  overflowY: isViewMore || snapshot.isDragging ? 'auto' : 'unset',
                  height: isViewMore ? this.state.calendarCellHeight : 'unset',
                }}
                className={classNames('calendar-cell__main-content', {
                  copying: copyingAssignment,
                  copying_workouts: isCopySelectedWorkout,
                })}
              >
                {views}
                {this.renderViewMore()}
                {this.renderBottom()}
                {this.renderPasteMultipleWorkout()}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      );
    } else {
      return (
        <div
          ref={ref => (this.calendarCell = ref)}
          style={{
            background: isViewMore ? '#ffffff' : '#f3f5f8',
            marginBottom: isViewMore ? 0 : 4,
            overflowY: isViewMore ? 'auto' : 'unset',
            height: isViewMore ? this.state.calendarCellHeight : 'unset',
          }}
          className={classNames('calendar-cell__main-content', {
            copying: copyingAssignment,
            copying_workouts: isCopySelectedWorkout,
          })}
        >
          {views}
          {this.renderViewMore()}
          {this.renderBottom()}
          {this.renderPasteMultipleWorkout()}
        </div>
      );
    }
  };

  renderPasteMultipleWorkout() {
    const { isCopySelectedWorkout, currentWorkouts } = this.props;
    const { isViewMore, calendarCellHeight } = this.state;

    if (isViewMore) {
      return <></>;
    }

    if (isCopySelectedWorkout) {
      const marginTop = !currentWorkouts || currentWorkouts.length === 0 ? calendarCellHeight / 2 - 22 : 0;

      return (
        <div className="calendar-cell--paste-multiple-workout" style={{ marginTop }} onClick={this.handlePasteWorkout}>
          <div>Paste</div>
        </div>
      );
    }
  }

  renderBottom() {
    const { currentWorkouts, copyingAssignment } = this.props;
    const { isViewMore, calendarCellHeight } = this.state;

    if (isViewMore) {
      return null;
    }

    if (copyingAssignment) {
      const marginTop = !currentWorkouts || currentWorkouts.length === 0 ? calendarCellHeight / 2 - 22 : 0;

      return (
        <div className="calendar-cell_paste-button" style={{ marginTop }} onClick={this.handlePaste}>
          <div>Paste</div>
        </div>
      );
    }

    return null;
  }

  renderViewMore() {
    const { currentWorkouts } = this.props;
    const { isViewMore } = this.state;

    if (!currentWorkouts || isViewMore) {
      return null;
    }

    const maxRendered = this.getMaximumRenderedWorkouts();
    let remainTasks = currentWorkouts.length - maxRendered;

    if (remainTasks <= 0) {
      return null;
    }

    let taskText = `+ ${remainTasks} more ${pluralize('workout', remainTasks)}`;

    return (
      <div
        className="view-more-task"
        onClick={() => {
          this.handleViewMore(true);
        }}
      >
        {taskText}
      </div>
    );
  }

  handleOpenWorkoutDetail = (currentWorkout, sectionId) => {
    const { selectedClient, dispatch } = this.props;
    const trigger = sectionId ? { from: 'section', sectionId } : null;
    if (currentWorkout.status == 2) {
      dispatch(getAssignmentDetail(currentWorkout._id, 'history', WORKOUT_TYPE.HISTORY));
      dispatch(push(`/home/client/${selectedClient}/calendar/${currentWorkout._id}/history`));
    } else {
      dispatch(getAssignmentDetail(currentWorkout._id, 'update', WORKOUT_TYPE.DETAIL, trigger));
      dispatch(push(`/home/client/${selectedClient}/calendar/${currentWorkout._id}/detail`));
    }
  };

  handleViewMore(isViewMore, id) {
    this.setState({ isViewMore: isViewMore }, () => {
      if (id) {
        this.workoutCellRef[id].scrollIntoView();
      }
    });
  }

  updateCalendarCellHeight() {
    if (this.calendarCell) {
      this.setState({
        calendarCellHeight: this.calendarCell.offsetHeight,
        width: this.calendarCell.offsetWidth,
      });
    }
  }

  onRefInnerDiv(ref, currentWorkout, provided) {
    if (provided) {
      provided.innerRef(ref);
    }

    if (currentWorkout) {
      this.workoutCellRef[currentWorkout._id] = ref;
    }
  }

  onRefInnerCalendarDiv(ref, provided) {
    provided.innerRef(ref);
    this.calendarCell = ref;
  }

  renderSuperset = (currentWorkout, section, renderable, sectionIndex) => {
    // render exercise outside section : section.type === 'hidden'

    const { updateExerciseLibFromCalendar, day } = this.props;
    const dayFormated = day.toFormat('MM-dd-yyyy');
    const views = [];
    const set = section.exercises ? section.exercises[0] : {};
    set.supersets.map((exSet, idx) => {
      if (idx < renderable) {
        let currentSet = _.cloneDeep(exSet);

        const pId = `${currentWorkout._id}-${sectionIndex}-${idx}-${dayFormated}`;

        views.push(
          <div key={idx}>
            <ExerciseDetail
              style={{ marginTop: 16 }}
              containerStyle={{ marginLeft: 12, marginRight: 12 }}
              exerciseSet={new ExerciseSet(currentSet)}
              pId={pId}
              day={dayFormated}
              exIndex={idx}
              setIndex={0}
              sectionIndex={sectionIndex}
              mode={currentWorkout.status === 2 ? 'preview' : 'popup'}
              assignment={new Workout(currentWorkout)}
              onPreviewClick={() => (currentWorkout.status === 2 ? this.handleOpenWorkoutDetail(currentWorkout) : null)}
              onEditExerciseLibrary={(oldExerciseId, newExerciseLibrary) => {
                const data = {
                  day: dayFormated,
                  assignmentId: currentWorkout._id,
                  oldExerciseId,
                  newExerciseLibrary,
                };
                updateExerciseLibFromCalendar(data);
              }}
            />

            {idx < renderable - 1 ? (
              <div
                style={{
                  backgroundColor: 'gainsboro',
                  height: 1,
                  margin: '0 8px',
                }}
              />
            ) : null}
          </div>,
        );
      }
    });

    return views;
  };

  renderSection = (currentWorkout, section, renderable, sectionIndex) => {
    const indexId = `${section._id};${sectionIndex}`;
    const draggableId = `${this.props.day.toFormat('MM-dd-yyyy')};${currentWorkout._id};${section._id}`;
    const sClass = section.type !== 'hidden' ? 'ui segment small section-wrapper' : 'ui segment small';

    const content = (
      <React.Fragment>
        {this.props.movingSet ? <Loader /> : null}
        {section.type === 'hidden' ? (
          this.renderSuperset(currentWorkout, section, renderable, sectionIndex)
        ) : renderable ? (
          <SectionOverview
            key={section._id}
            section={section}
            onClick={() => this.handleOpenWorkoutDetail(currentWorkout, section._id)}
          />
        ) : null}
      </React.Fragment>
    );

    if (currentWorkout.status === 2) {
      return <div className={`${sClass} exercise-cell`}>{content}</div>;
    }

    return (
      <Draggable key={draggableId} draggableId={draggableId} index={sectionIndex} type="exercise">
        {(provided, snapshot) => (
          <div
            className={`${sClass} ${!snapshot.isDragging ? 'exercise-cell' : 'drag'}`}
            style={{ ...provided.draggableProps.style }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {content}
          </div>
        )}
      </Draggable>
    );
  };

  renderAssignment = (currentWorkout, isShowOverviewOnly, workoutIndex) => {
    const { isViewMore } = this.state;

    if (!currentWorkout) {
      return null;
    }

    const { FREESTYLE } = SECTION_FORMAT_KEY;
    const { sections } = currentWorkout;
    const { day, isDragging, dragingAssignmentId } = this.props;
    const views = [];
    const maxRendered = isViewMore ? 100 : isShowOverviewOnly ? 0 : this.getMaximumRenderedExercises(currentWorkout);
    let isCompressed = !isShowOverviewOnly || isViewMore;
    let droppableId = `${day.toFormat('MM-dd-yyyy')};${currentWorkout._id}`;

    if (isCompressed && !isDragging) {
      let totalRendered = 0;

      for (let i = 0; i < sections.length; i++) {
        let section = sections[i];

        if (!section) {
          continue;
        }

        let numOfExercises = _.sumBy(section.exercises, set => (set && set.supersets ? set.supersets.length : 0));
        if (section.format === FREESTYLE) {
          numOfExercises = section.exercise_references.length || 1;
        }

        let rendered = Math.min(numOfExercises, maxRendered - totalRendered);

        if (!rendered) {
          break;
        } else {
          totalRendered += rendered;
          views.push(this.renderSection(currentWorkout, section, rendered, i));
        }
      }
    } else {
      let total = _.sumBy(sections, section => {
        return _.sumBy(section.exercises, exercise => {
          return exercise.supersets ? exercise.supersets.length : 0;
        });
      });
      droppableId += `;${total}`;
    }

    if (currentWorkout.status === 2) {
      return (
        <div
          ref={ref => this.onRefInnerDiv(ref, currentWorkout, null)}
          className={'ui segment small workout-cell workout-cell--tracked'}
          style={{ ...styles.exerciseListContainer }}
        >
          {this.renderExercisesHeader(currentWorkout, isCompressed)}
          <div>{views}</div>
          {this.renderExercisesFooter(currentWorkout, maxRendered)}
        </div>
      );
    }

    return (
      <AssignmentDraggable
        currentWorkout={currentWorkout}
        day={day}
        workoutIndex={workoutIndex}
        dragingAssignmentId={dragingAssignmentId}
      >
        <Droppable droppableId={droppableId} type="exercise">
          {(provided, snapshot) => {
            const dragOver = snapshot.isDraggingOver ? { background: '#f3f5f8' } : {};
            return (
              <div
                ref={ref => this.onRefInnerDiv(ref, currentWorkout, provided)}
                className={'ui segment small workout-cell'}
                style={{ ...styles.exerciseListContainer, ...dragOver }}
              >
                {this.renderExercisesHeader(currentWorkout, isCompressed)}
                <div>{views}</div>
                {this.renderExercisesFooter(currentWorkout, maxRendered)}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </AssignmentDraggable>
    );
  };

  renderWorkoutStatus(item) {
    if (item.status == 2) {
      return <Image className="workout-status-icon" src={`${CDN_URL}/images/checkbox_greenwhite.svg`} />;
    }
    return null;
  }

  renderWorkoutTitle(currentWorkout, isHideWorkout = false) {
    let className = 'workout-cell-title';
    let width = this.state.width;
    if (isHideWorkout) {
      width -= 20;
    }
    if (currentWorkout.status === 2) {
      width -= 63;
    } else {
      width -= 75;
    }
    return (
      <div
        className={className}
        style={{ maxWidth: width, width: 'unset' }}
        data-tip
        data-for={`workout-title-${currentWorkout._id}`}
      >
        {currentWorkout.title ? currentWorkout.title.toUpperCase() : ''}
      </div>
    );
  }

  renderExercisesHeader(currentWorkout, isCompressed) {
    const { selectedClient, dayofWeek, selectedWorkout, permission } = this.props;
    const { openingPopup = '' } = this.state;
    const dropdownIcon = (
      <div data-tip data-for={`workout-cell-tooltip-${(currentWorkout || {})._id}`}>
        <Icon color={'grey'} name={'ellipsis horizontal'} size={'small'} className="more-actions-icon" />
        {openingPopup !== (currentWorkout || {})._id && (
          <SharedTooltip id={`workout-cell-tooltip-${(currentWorkout || {})._id}`} />
        )}
      </div>
    );
    let direction = dayofWeek == 'Sun' ? 'bottom right' : 'bottom left';
    const isChecked = selectedWorkout.includes(currentWorkout._id);
    const isMonday = dayofWeek === 'Mon';
    const isHideWorkout = _.get(currentWorkout, 'workout_settings.hide_workout', false);

    return (
      <S.ExerciseListHeader className={isCompressed ? 'exercise-list-header' : 'exercise-list-header-compressed'}>
        {isHideWorkout ? (
          <S.HiddenEye>
            <EyeHideIcon />
          </S.HiddenEye>
        ) : null}
        {currentWorkout.originalProgram && (
          <ProgramInfo
            elementId={currentWorkout._id}
            program={currentWorkout.originalProgram}
            isMonday={isMonday}
            isWorkout={true}
          />
        )}
        {!!currentWorkout.originalAutoflow && (
          <AutoflowInfo
            elementId={currentWorkout._id}
            autoflow={currentWorkout.originalAutoflow}
            isMonday={isMonday}
            isWorkout={true}
          />
        )}
        <div
          className="workout-title-status"
          onClick={() => {
            if (currentWorkout.status == 2) {
              this.props.dispatch(push(`/home/client/${selectedClient}/calendar/${currentWorkout._id}/history`));
            } else {
              this.handleOpenWorkoutDetail(currentWorkout);
            }
          }}
        >
          {this.renderWorkoutTitle(currentWorkout, isHideWorkout)}
          {this.renderWorkoutStatus(currentWorkout)}
        </div>
        {process.env.REACT_APP_COPY_WEEK_ENABLE_V1 && _.get(permission, 'copy_week') && (
          <Checkbox
            checked={isChecked}
            onChange={event => {
              event.stopPropagation();
              this.handleChangeWorkout(currentWorkout, event.target.checked);
            }}
            className={classNames('calendar-client--checkbox-workout', {
              hidden: _.isEmpty(selectedWorkout),
              show: !_.isEmpty(selectedWorkout),
            })}
          />
        )}
        <Popup
          trigger={dropdownIcon}
          on={'click'}
          style={styles.popup}
          open={this.state.openingPopup === currentWorkout._id}
          onOpen={() => this.onPopupToggle(currentWorkout._id)}
          onClose={() => this.onPopupToggle(null)}
          position={direction}
          basic
          className="action-popup calendar-menu-popup"
        >
          {this.renderPopupContent(currentWorkout, isHideWorkout)}
        </Popup>
        <ReactTooltip
          className="app-tooltip"
          id={`workout-title-${currentWorkout._id}`}
          effect="solid"
          place={'top'}
          delayShow={1000}
        >
          <span>{currentWorkout.title ? currentWorkout.title : ''}</span>
        </ReactTooltip>
      </S.ExerciseListHeader>
    );
  }

  renderPopupContent(currentWorkout, isHideWorkout) {
    return (
      <ul style={styles.dropdownList}>
        {this.renderMoveUpWorkout(currentWorkout)}
        {this.renderMoveDownWorkout(currentWorkout)}
        {currentWorkout.status !== 2 ? (
          <li
            style={styles.dropdownListItem}
            className={'list-item'}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleTrackingAction(currentWorkout);
            }}
          >
            <div style={styles.dropdownListItemContainer}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/logworkout.svg`} />{' '}
              <div style={styles.dropdownListItemTitle}>Log workout</div>
            </div>
          </li>
        ) : null}
        <li
          style={styles.dropdownListItem}
          className={'list-item'}
          onClick={() => {
            this.setState({ openingPopup: '' });
            this.handleExportPDF(currentWorkout);
          }}
        >
          <div style={styles.dropdownListItemContainer}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/print-pdf.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Print workout</div>
          </div>
        </li>
        <li
          style={styles.dropdownListItem}
          className={'list-item'}
          onClick={() => {
            this.setState({ openingPopup: '' });
            this.handleSaveToLibraryAction(currentWorkout);
          }}
        >
          <div style={styles.dropdownListItemContainer}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/save.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Save to library</div>
          </div>
        </li>
        {currentWorkout.status !== 2 && !isHideWorkout ? (
          <li
            style={styles.dropdownListItem}
            className={'list-item'}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleHideWorkoutAction(true, _.get(currentWorkout, '_id', ''));
            }}
          >
            <div style={styles.dropdownListItemContainer}>
              <div style={styles.dropdownListItemImage}>
                <EyeHideIcon />
              </div>{' '}
              <div style={styles.dropdownListItemTitle}>Hide from client</div>
            </div>
          </li>
        ) : null}
        {currentWorkout.status !== 2 && isHideWorkout ? (
          <li
            style={styles.dropdownListItem}
            className={'list-item'}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleHideWorkoutAction(false, _.get(currentWorkout, '_id', ''));
            }}
          >
            <div style={styles.dropdownListItemContainer}>
              <div style={styles.dropdownListItemImage}>
                <EyeUnhideIcon />
              </div>{' '}
              <div style={styles.dropdownListItemTitle}>Unhide from client</div>
            </div>
          </li>
        ) : null}
        <li
          style={styles.dropdownListItem}
          className={'list-item'}
          onClick={() => {
            this.setState({ openingPopup: '' });
            this.handleCopyAction(currentWorkout);
          }}
        >
          <div style={styles.dropdownListItemContainer}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/duplicate.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Copy</div>
          </div>
        </li>
        <li
          style={styles.dropdownListItem}
          className={'list-item'}
          onClick={() => {
            this.setState({ openingPopup: '' });
            this.handleDeleteAction(currentWorkout);
          }}
        >
          <div style={styles.dropdownListItemContainer}>
            <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/delete.svg`} />{' '}
            <div style={styles.dropdownListItemTitle}>Delete</div>
          </div>
        </li>
      </ul>
    );
  }

  onPopupToggle(popup) {
    this.resetActions(() => {
      setTimeout(() => this.setState({ openingPopup: popup }), 0);
    });
  }

  resetActions = callback => {
    this.setState({
      openingPopup: '',
    });
    callback && callback();
  };

  renderMoveUpWorkout(currentWorkout) {
    const { currentWorkouts } = this.props;
    let idx = currentWorkouts.findIndex(item => {
      return item._id == currentWorkout._id;
    });
    let count = currentWorkouts.length;
    if (count >= 2) {
      if (idx == count - 1 || (count >= 3 && idx > 0 && idx < count - 1)) {
        return (
          <li
            style={styles.dropdownListItem}
            className={'list-item'}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleMoveUp(currentWorkout, idx);
            }}
          >
            <div style={styles.dropdownListItemContainer}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/workout_up.svg`} />{' '}
              <div style={styles.dropdownListItemTitle}>Move up</div>
            </div>
          </li>
        );
      }
    }
    return null;
  }

  renderMoveDownWorkout(currentWorkout) {
    const { currentWorkouts } = this.props;
    let idx = currentWorkouts.findIndex(item => {
      return item._id == currentWorkout._id;
    });
    let count = currentWorkouts.length;
    if (count >= 2) {
      if (idx === 0 || (count >= 3 && idx > 0 && idx < count - 1)) {
        return (
          <li
            style={styles.dropdownListItem}
            className={'list-item'}
            onClick={() => {
              this.setState({ openingPopup: '' });
              this.handleMoveDown(currentWorkout, idx);
            }}
          >
            <div style={styles.dropdownListItemContainer}>
              <Image style={styles.dropdownListItemImage} src={`${CDN_URL}/images/workout_down.svg`} />{' '}
              <div style={styles.dropdownListItemTitle}>Move down</div>
            </div>
          </li>
        );
      }
    }
    return null;
  }

  handleMoveDown = (currentWorkout, index) => {
    const { day, currentWorkouts } = this.props;
    let newIndex = index + 1;
    if (newIndex > currentWorkouts.length - 1) {
      return;
    }
    let params = {
      assignmentId: currentWorkout._id,
      newIndex: newIndex,
      dayAssignment: day.toFormat('MM-dd-yyyy'),
    };
    this.props.arrangeAssignments(params);
  };

  handleMoveUp = (currentWorkout, index) => {
    const { day } = this.props;
    let newIndex = index - 1;
    if (newIndex < 0) {
      return;
    }
    let params = {
      assignmentId: currentWorkout._id,
      newIndex: newIndex,
      dayAssignment: day.toFormat('MM-dd-yyyy'),
    };
    this.props.arrangeAssignments(params);
  };

  handleTrackingAction = currentWorkout => {
    const { selectedClient } = this.props;
    this.props.dispatch(getAssignmentDetail(currentWorkout._id, 'update', WORKOUT_TYPE.LOGWORKOUT));
    this.props.dispatch(push(`/home/client/${selectedClient}/calendar/${currentWorkout._id}/tracking`));
  };

  handleCopyAction = currentWorkout => {
    if (currentWorkout) {
      if (!_.isEmpty(this.props.selectedWorkout)) {
        this.props.handleResetSelectWorkout();
      }
      if (!_.isEmpty(this.props.selectedWeek)) {
        this.props.handleResetSelectedWeek();
      }
      this.props.copyCurrentAssignment(currentWorkout);
    } else {
      console.log('Something wrong. Cannot copy assignment', currentWorkout);
    }
  };

  handleExportPDF = async workout => {
    if (workout) {
      let currentWorkout;
      this.props.getPdfRequest();
      try {
        const response = await axiosInstance.get(`/api/workout/v2/assignment/detail/${workout._id}`);
        currentWorkout = new Workout().parseFromAssignment(response.data.data);
      } catch (error) {
        return;
      }
      const { location, workingClientDetail } = this.props;
      handleMixpanelTrackingPrintPDF(location.pathname, 'calendar');
      await this.props.getPdf(currentWorkout, 'assignment', workingClientDetail._id);
      this.props.getPdfFinish();
    } else {
      console.log('Something wrong. Cannot print workout', workout);
    }
  };

  handleSaveToLibraryAction = currentWorkout => {
    if (currentWorkout) {
      this.props.saveCurrentAssignmentToLibrary(currentWorkout);
    } else {
      console.log('Something wrong. Cannot save assignment to library', currentWorkout);
    }
  };

  handlePaste = event => {
    const multiPaste = event.shiftKey;
    const { copyingAssignment, day, selectedClient, trainerTimeZone } = this.props;

    let currentDate = DateTime.local();
    let date = day.set({
      hour: currentDate.get('hour'),
      minute: currentDate.get('minute'),
      second: currentDate.get('second'),
    });
    let params = {
      timezone: DateTime.local().zoneName || moment.tz.guess() || trainerTimeZone,
      assignment: copyingAssignment._id,
      date: date.toString(),
      client: selectedClient,
    };

    this.debouncePasteSingleWorkout(params, multiPaste);
  };

  handleDeleteAction = currentWorkout => {
    if (currentWorkout) {
      const params = {
        assignment: currentWorkout,
        client: this.props.selectedClient,
      };

      this.props.dispatch(
        toggleConfirmModal(
          true,
          <ConfirmModal
            title="Delete workout"
            content={'Are you sure that you want to delete this workout?'}
            onConfirm={() => this.props.removeCurrentAssignment(params)}
          />,
        ),
      );
    }
  };

  handleHideWorkoutAction = (status, assignmentId) => {
    this.props.hideWorkout(status, assignmentId);
  };

  renderExercisesFooter = (currentWorkout, maxRendered) => {
    const { day, updateExerciseLibFromCalendar } = this.props;
    const popupId = `${day.toFormat('MM-dd-yyyy')}-${currentWorkout._id}-new-exercise`;
    const footerIcon =
      currentWorkout.status === 2 ? null : (
        <ExerciseDetail
          mode={'new_popup'}
          assignment={currentWorkout}
          pId={popupId}
          onEditExerciseLibrary={(oldExerciseId, newExerciseLibrary) => {
            const data = {
              day: day.toFormat('MM-dd-yyyy'),
              assignmentId: currentWorkout._id,
              oldExerciseId,
              newExerciseLibrary,
            };
            updateExerciseLibFromCalendar(data);
          }}
        />
      );

    const remainTasks = counterBehindExercises(currentWorkout, maxRendered);

    if (!currentWorkout || currentWorkout.sections.length === 0 || remainTasks < 1) {
      return (
        <div className="exercises-list-footer" style={styles.exercisesListFooter}>
          {footerIcon}
        </div>
      );
    }

    const extraStyle = {
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    let taskText = `+ ${remainTasks} more ${pluralize('exercise', remainTasks)}`;

    return (
      <div style={{ ...styles.exercisesListFooter, ...extraStyle }} className="exercises-list-footer">
        <div
          style={{
            cursor: 'pointer',
            marginLeft: 4,
            fontSize: 11,
            color: '#5a636c',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            this.handleViewMore(true, currentWorkout._id);
          }}
          className="text"
        >
          {taskText}
        </div>
        {footerIcon}
      </div>
    );
  };

  renderAddDropDown = (isLastDay, hideGuide) => {
    let menuClassName = 'program-plus-menu';

    if (isLastDay) {
      menuClassName += ' last-day';
    }

    return (
      <div className={menuClassName}>
        <ul className="program-list-dropdown">
          <li
            className="program-list-dropdown-item"
            onClick={() => {
              if (hideGuide) {
                this.props.hideTooltip();
              }

              this.props.dispatch(toggleModal(true, <AssignWorkoutModal date={this.props.day} />));
            }}
          >
            <div>Add workout</div>
          </li>
          <UpgradePath pathName="program" fallback={<></>}>
            <li
              className="program-list-dropdown-item"
              onClick={e => {
                e.stopPropagation();

                if (hideGuide) {
                  this.props.hideTooltip();
                }

                this.props.dispatch(
                  toggleModal(
                    true,
                    <SelectProgramModal date={this.props.day} from="training_calendar" showEndingOption={true} />,
                  ),
                );
              }}
            >
              <div>Add program</div>
            </li>
          </UpgradePath>
        </ul>
      </div>
    );
  };

  getMaximumRenderedWorkouts = () => {
    const { weekIndex, calendarType, hasSelectedWorkout } = this.props;
    const menuBottomBar = weekIndex === calendarType && hasSelectedWorkout ? 42 : 0; // 42: care overlap menuBottomBar
    let wrapperMargin = 4 + 2; // 2: border width
    let pasteButtonHeight = 22;
    const containerHeight = this.state.calendarCellHeight - (wrapperMargin + pasteButtonHeight + menuBottomBar);
    const workoutHeight = 53;
    let maxHeigh = parseInt(containerHeight / workoutHeight, 10);
    return maxHeigh;
  };

  getMaximumRenderedExercises = workout => {
    const { weekIndex, calendarType, hasSelectedWorkout } = this.props;
    const menuBottomBar = weekIndex === calendarType && hasSelectedWorkout ? 42 : 0;
    let factor = 6 + 22; // 6: margin, 24 : paste button's height
    const workoutHeaderHeight = 22;
    const workoutFooterHeight = 27;
    const assignmentContainerHeight = 53;

    factor += workoutHeaderHeight + workoutFooterHeight;
    factor += (this.props.currentWorkouts.length - 1) * assignmentContainerHeight;

    // 10: first and last card not margin top and bottom
    const containerHeight = this.state.calendarCellHeight - factor + 10 - menuBottomBar;
    return maximumRenderedExercises(workout, containerHeight);
  };
}
