import styled, { css, keyframes } from 'styled-components';
import { Popup } from 'semantic-ui-react';

const BASE_Z_INDEX = 1100;
const FULLSCREEN_Z_INDEX = BASE_Z_INDEX + 50;
const FILTER_Z_INDEX = BASE_Z_INDEX + 100;
const DETAIL_Z_INDEX = BASE_Z_INDEX + 150;
const INSTRUCTION_Z_INDEX = BASE_Z_INDEX + 200;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FullscreenModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: unset;
  left: 0;
  z-index: ${BASE_Z_INDEX};

  &.open {
    top: 0;
  }

  &.fullscreen {
    z-index: ${FULLSCREEN_Z_INDEX};
  }

  &.filter {
    z-index: ${FILTER_Z_INDEX};
  }

  &.detail {
    z-index: ${DETAIL_Z_INDEX};
  }

  &.instruction {
    z-index: ${INSTRUCTION_Z_INDEX};
  }

  &.tags {
    z-index: ${INSTRUCTION_Z_INDEX};
  }

  &.description {
    z-index: ${INSTRUCTION_Z_INDEX};
  }
`;

export const FullscreenModalContainer = styled.div`
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out;
  animation: ${fadeIn} 0.3s ease-in-out;
  opacity: 0;

  &.open {
    display: block;
    opacity: 1;
  }

  &.fullscreen {
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0px, 0px);
  }

  &.filter {
    max-width: 726px;
    width: 100%;
    height: calc(100vh - 48px);
  }

  &.detail {
    max-width: 1030px;
    width: 100%;
    height: calc(100vh - 47px);
  }

  &.instruction {
    max-width: 550px;
    width: 100%;
    height: 550px;
  }

  &.tags {
    max-width: 550px;
    width: 100%;
    height: 550px;
  }

  &.description {
    max-width: 550px;
    width: 100%;
    height: 550px;
  }

  /* Small screen styles (up to 1023px) */
  @media screen and (max-width: 1023px) {
    &.fullscreen {
    }

    &.filter {
    }

    &.detail {
      max-width: calc(100vw - 48px);
      .detail-header {
        max-width: 976px;

        .about-skeleton-wrapper.is-about {
          padding-right: 0px;
          min-width: 422px;
        }
      }
    }

    &.instruction {
    }
  }

  /* Medium screen styles (1024px to 1439px) */
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    &.fullscreen {
    }

    &.filter {
    }

    &.detail {
    }

    &.instruction {
    }
  }

  /* Large screen styles (1440px to 1919px) */
  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    &.fullscreen {
    }

    &.filter {
    }

    &.detail {
    }

    &.instruction {
    }
  }

  /* Extra-large screen styles (1920px and above) */
  @media screen and (min-width: 1920px) {
    &.fullscreen {
    }

    &.filter {
    }

    &.detail {
    }

    &.instruction {
    }
  }
`;

export const FullscreenModalContent = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &.fullscreen {
    overflow-y: ${props => (props.openedFilter ? 'hidden' : 'auto')};

    &::-webkit-scrollbar {
      width: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0 !important;
    }

    .background-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 425px;
      transition: 0.3s;

      &.sticky {
        height: 212px;
      }
    }

    .close-icon-button {
      position: fixed;
      right: 40px;
      top: 40px;
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 50px;
      background: #fff;
      border: none;
      cursor: pointer;
      z-index: ${FULLSCREEN_Z_INDEX + 4};
      border: 1px solid #d9d9d9;

      &.sticky {
        top: 30.5px;
      }
    }

    .header-title-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-top: 95px;
      transition: 0.3s;

      .title {
        color: #fff;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }

      .description {
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        font-family: 'Open Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &.sticky {
        display: none;
      }
    }

    .filter-search-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;

      &.default {
        margin-top: 64px;
        margin-bottom: 96px;
      }

      &.sticky {
        position: sticky;
        top: 0;
        z-index: ${FULLSCREEN_Z_INDEX + 3};
        background: #fff;
        padding: 16px 0;
        transition: 0.3s;
      }
    }

    .search-wrapper {
      position: relative;
      box-sizing: border-box;

      input {
        position: relative;
        box-sizing: border-box;
        width: 580px;
        height: 64px;
        padding: 22px 24px;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(217, 217, 217, 0.25);

        color: #141414;
        font-family: 'Open Sans';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        box-shadow: none;
        outline: none;

        :focus {
          border: 2px solid #5559ff;
        }

        ::placeholder {
          color: #8c8c8c;
          font-family: 'Open Sans';
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .search-input svg {
        width: 16px;
        height: 16px;
        margin-right: 9px;
      }

      /* .search-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: #5559ff;
        border: none;
        cursor: pointer;
        &:hover {
          background-color: #676aff;
        }

        svg {
          width: 16px;
          height: 16px;

          path {
            stroke: #fff;
          }
        }
      } */
    }

    .overlay-wrapper {
      position: relative;
    }

    .category-filter-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      overflow: hidden;
      padding: 0 40px;

      /* &.sticky {
        position: sticky;
        top: 80px;
        z-index: ${FULLSCREEN_Z_INDEX + 1};
        background: #fff;
      } */
    }

    .category-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 1px;

      .arrow-button-left,
      .arrow-button-right {
        position: absolute;
        top: 0;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
          border: none;
          background: none;
          padding: 0;
          cursor: pointer;

          &:hover {
            svg circle {
              stroke: #141414;
            }
          }
        }

        &.hidden {
          visibility: hidden;
        }
      }

      .arrow-button-left {
        left: 0;
        background: linear-gradient(90deg, #fff 35.83%, rgba(255, 255, 255, 0) 100%);

        button {
          transform: rotateY(180deg);
        }
      }

      .arrow-button-right {
        right: 0;
        background: linear-gradient(270deg, #fff 35.83%, rgba(255, 255, 255, 0) 100%);
      }

      .category-scrollable {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        overflow-x: auto;
        padding: 16px 16px 24px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .category-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 12px 19px 17px 19px;
        border: 1px solid transparent;
        background: #ffffff;
        cursor: pointer;
        height: 94px;

        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .label {
          color: #595959;
          text-align: center;
          font-family: 'Open Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          white-space: nowrap;
          word-break: keep-all;
        }

        &:hover,
        &.selected {
          border-radius: 12px;
          background: #fff;
          border: 1px solid #f0f0f0;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
        }

        &.selected {
          .icon {
            color: #141414;

            svg path {
              fill: #141414;
            }
          }

          .label {
            color: #141414;
          }
        }
      }
    }

    .filter-wrapper {
      position: relative;

      .filter-button {
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        background: #fff;
        cursor: pointer;
        padding: 24px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 64px;
        height: 64px;
        outline: none;

        .icon {
          width: 20px;
          height: 20px;
        }

        .label {
          color: #16161a;
          font-family: 'Open Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        :hover {
          background: #f0f0f0;
        }
      }
      .filter-button-template {
        width: 103px;
        height: 50px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        color: #141414;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding: 15px;

        ::after {
          bottom: -10px !important;
          border-top-color: #fff !important;
        }
      }
    }

    .recommended-most-popular-time-sort-wrapper {
      position: relative;
      padding: 24px 12px 8px 18px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #111;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */
      &.enabled {
        padding: 0 12px 8px 18px;
      }
    }

    .switch-item {
      border: none;
      background: none;
      cursor: pointer;
      height: 37px;
    }

    .recommended-most-popular-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      /* .label {
        padding: 6px 16px;

        color: #6f6f71;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      } */

      /* .selected {
        border-radius: 8px;
        background: #fff;
        box-shadow: 2px 2px 8px 0px rgba(56, 56, 56, 0.04);

        color: #16161a;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      } */
    }

    .time-sort-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }

    .time-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 3px;
      border-radius: 8px;
      background: #f6f6f6;

      .label {
        padding: 6px 16px;

        color: #6f6f71;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .selected {
        border-radius: 8px;
        background: #fff;
        box-shadow: 2px 2px 8px 0px rgba(56, 56, 56, 0.04);

        color: #16161a;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .sort-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .sort-trigger {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        border: 1px solid #f6f6f6;
        height: 43px;
        padding: 6px 16px;
        border-radius: 8px;
        background: #f6f6f6;
        box-shadow: 2px 2px 8px 0px rgba(56, 56, 56, 0.04);

        color: #191919;
        text-overflow: ellipsis;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        strong {
          font-weight: 700;
        }

        &:hover {
          background: #eee;
        }

        &.show {
          background: #fff;
          color: #5559ff;
          box-shadow: 2px 2px 8px 0px rgba(56, 56, 56, 0.04);
          border: 1px solid #f2f2f2;

          .icon {
            transform: rotateX(180deg);

            path {
              fill: #5559ff;
            }
          }
        }
      }
    }

    .content-scrollable {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0px 16px 0 28px;
      right: 4px;
    }

    .templates-scrollable {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 8px 2px;
    }

    .empty-program-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      pointer-events: none;
      margin-bottom: 16px;
      margin-top: -40px;
      height: 673px;

      .empty-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
        gap: 40px 32px;
        padding-left: 28px;
        margin-right: 20px;
        width: 100%;
      }

      /* Small screen styles (up to 1023px) */
      @media screen and (max-width: 1023px) {
        .empty-wrapper {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      /* Medium screen styles (1024px to 1439px) */
      @media screen and (min-width: 1024px) and (max-width: 1439px) {
        .empty-wrapper {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      /* Large screen styles (1440px to 1919px) */
      @media screen and (min-width: 1440px) and (max-width: 1919px) {
        .empty-wrapper {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      /* Extra-large screen styles (1920px and above) */
      @media screen and (min-width: 1920px) {
        .empty-wrapper {
          grid-template-columns: repeat(5, 1fr);
        }
      }

      .empty-icon-wrapper {
        display: flex;
        gap: 24px;
        flex-direction: column;
        align-items: center;
        position: absolute;
        color: #9a9da6;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .template-item {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      padding: 14px;
      background: transparent;
      border: 1px solid transparent;

      &:hover {
        background: #fff;
        border-radius: 12px;
        border: 1px solid #f5f6f6;
        box-shadow: 0px 4px 56px 0px rgba(159, 159, 159, 0.24);
      }

      .thumbnail-header {
        position: relative;
        width: 100%;
      }

      .thumbnail {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 200px;
        background-color: #f7f8fc;
        border-radius: 8px;
        width: 100%;

        img {
          display: block;
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .tags {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        align-content: flex-end;
        gap: 8px;
        padding: 8px;
      }

      .template-tag-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
        border-radius: 2px;
        background: rgba(6, 6, 40, 0.5);
        backdrop-filter: blur(10px);
        text-transform: capitalize;

        color: #fff;
        font-family: 'Open Sans';
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }

      .template-content {
        padding-bottom: 12px;
      }

      .title-wrapper {
        display: grid;
        grid-template-columns: 1fr 40px;
        align-items: start;
        gap: 8px;
        margin-bottom: 5px;
      }

      .title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        color: #111;
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        width: 268px;
        height: 48px;
      }

      .total-download {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        position: relative;
        top: 4px;
        right: 2px;
        color: #202353;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        svg {
          flex-shrink: 0;
        }
      }

      .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        color: #757575;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }

    .loading-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      gap: 40px 32px;
      padding: 0 40px;
      margin-top: 16px;
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 56px 0;

      .logo {
        object-fit: contain;
        display: block;
      }
    }
  }

  &.filter {
    border-radius: 12px;
  }

  &.detail {
    border-radius: 15px;
    background-color: transparent;
    .close-button {
      right: -40px !important;
      top: -11px !important;
      border: unset !important;
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: #fff;
        }
      }
    }
  }

  &.instruction {
    border-radius: 12px;
  }

  &.tags {
    border-radius: 12px;
  }

  &.description {
    border-radius: 12px;
  }
  /* Small screen styles (up to 1023px) */
  @media screen and (max-width: 1023px) {
    &.fullscreen {
      .search-wrapper input {
        width: 380px;
      }

      .templates-scrollable {
        grid-template-columns: repeat(2, 1fr);
      }
      .loading-wrapper {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.filter {
    }

    &.detail {
    }
  }

  /* Medium screen styles (1024px to 1439px) */
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    &.fullscreen {
      .search-wrapper input {
        width: 380px;
      }

      .templates-scrollable {
        grid-template-columns: repeat(3, 1fr);
      }

      .loading-wrapper {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &.filter {
    }

    &.detail {
    }
  }

  /* Large screen styles (1440px to 1919px) */
  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    &.fullscreen {
      .templates-scrollable {
        grid-template-columns: repeat(4, 1fr);
      }

      .loading-wrapper {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.filter {
    }

    &.detail {
      border-radius: 12px;
      position: fixed;
      width: 1029px;
    }

    &.instruction {
      border-radius: 12px;
      width: 550px;
    }

    &.tags {
      border-radius: 12px;
      width: 550px;
    }

    &.description {
      border-radius: 12px;
      width: 550px;
    }
  }

  /* Extra-large screen styles (1920px and above) */
  @media screen and (min-width: 1920px) {
    &.fullscreen {
      .templates-scrollable {
        grid-template-columns: repeat(5, 1fr);
      }

      .loading-wrapper {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &.filter {
    }

    &.detail {
    }
  }
`;

export const ExperienceDurationWrapper = styled.div`
  position: relative;
  display: flex;
  width: 350px;
  height: 64px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  cursor: pointer;
  justify-content: flex-end;

  .label {
    color: #8c8c8c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .value {
    overflow: hidden;
    color: #141414;
    text-overflow: ellipsis;
    /* text-transform: capitalize; */
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    span {
      text-transform: lowercase;
      display: inline-block;
      &::first-letter {
        text-transform: capitalize !important;
      }
    }
  }

  .filter-experience,
  .filter-duration {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 12px;
    width: 175px;
    background: #fff;
    cursor: pointer;
    text-align: left;
    padding: 16px 23px;
    outline: none;

    &.show,
    &:hover.show {
      background: #fff;
      box-shadow: #5559ff 0px 0px 2px, #5559ff 0px 0px 0px 2px;
      border-radius: 12px;
      border-right: unset;
    }
  }

  .filter-experience {
    border-right-width: 1px;
    position: relative;
    margin-right: 2px;
    border-radius: 12px 0 0 12px;
    &:before {
      height: 100%;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: -2px;
      border-right: 1px solid #d9d9d9;
    }
  }

  .filter-duration {
    border-radius: 0px 12px 12px 0px;
    border-left-width: 0px;
  }

  .filter-experience:hover,
  .filter-duration:hover {
    background: #f0f0f0;
    box-shadow: #d9d9d9 0px 0px 1px, #d9d9d9 0px 0px 0px 1px;
    border-radius: 12px;
  }

  :hover > button:before {
    border-right: unset;
  }

  :has(.show) > button:before {
    border-right: unset;
  }
`;

export const MainFilterPopup = styled(Popup)`
  &.ui.popup {
    border-radius: 16px !important;
    border: 1px solid #f2f2f2 !important;
    background: #fff !important;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04) !important;
    z-index: 3000 !important;
  }
  &.ui.bottom.popup {
    margin: 8px 0 0 !important;
  }

  .sort-popup-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 10px;
    padding: 8px 24px 12px 24px;
  }

  .sort-popup {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 230px;
    border-radius: 16px;
  }

  .sort-option {
    /* :first-child {
      margin-top: 16px;
    } */
    :last-child {
      margin-bottom: 6px;
    }

    justify-content: space-between;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    background: none;
    padding: 16px 24px;
    color: #141414;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    height: 48px;
    width: 100%;
    text-align: left;

    &:hover {
      background: #f2f2f2;
    }

    .evf-checkbox.duration-checkbox {
      padding-left: 16px !important;
      input:checked ~ .checkbox-icon {
        background-color: #5559ff;
        border-color: #5559ff;
        background-size: 9px;
      }
      .checkbox-icon {
        pointer-events: none;
      }
    }
  }

  .clear-filter {
    border-top: 1px solid #f0f0f0;
    text-align: center;
    padding: 16px 0px;
    color: #b6b5b5;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    &:hover {
      background: #f2f2f2;
    }
  }
`;

export const FilterModalHeader = styled.div`
  display: flex;
  padding: 19px 40px;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  justify-content: center;
  color: #141414;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const FilterHeaderTitle = styled.div``;

export const CloseFilterButton = styled.div`
  display: flex;
  cursor: pointer;

  svg {
    position: absolute;
    right: 45px;
    top: 25px;
  }
`;

export const FilterModalBody = styled.div`
  overflow: auto;
  max-height: calc(100% - 148px);
  margin-right: 4px;

  ::-webkit-scrollbar-thumb {
    background: #eaecf0 !important;
  }

  ::-webkit-scrollbar {
    width: 8px !important;
  }
`;

export const PopupGroup = styled.div``;

export const CustomBorder = styled.hr`
  background: #f0f0f0;
  border: 0;
  height: 1px;
  width: calc(100% - 80px);
  margin: 0 auto;
  ${props =>
    props.noBorderBottom &&
    css`
      display: none;
    `}
`;

export const PopupGroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 30px 40px;

  color: #141414;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const PopupGroupContent = styled.div`
  display: none;
  &.expanded {
    display: block;
    padding: 0px 28px 31px 40px;
  }

  &.modalities.expanded,
  &.levels.expanded,
  &.durations.expanded,
  &.number_of_weeks.expanded {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 24px;
  }

  &.workout_days_per_weeks.expanded {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &.experience_level.expanded {
    margin-top: 16px;
  }

  .evf-checkbox.system-modalities,
  .evf-checkbox.system-durations,
  .evf-checkbox.system-program-length {
    color: #141414;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 16px 0px 16px 40px;

    input:checked ~ .checkbox-icon {
      background-color: #5e59ff;
      border-color: #5e59ff;
      background-size: 14px;
    }
  }

  .evf-checkbox.system-modalities:hover,
  .evf-checkbox.system-durations:hover,
  .evf-checkbox.system-program-length:hover {
    .checkbox-icon {
      border-color: #141414;
    }
  }

  .ui.radio.checkbox label {
    padding-left: 36px !important;
  }
  // Custom Radio color
  .ui.radio.checkbox input:checked ~ label::before {
    border: 2px solid #5e59ff !important;
  }

  .ui.radio.checkbox input:checked ~ label::after {
    background-color: #5e59ff !important;
  }

  .ui.radio.checkbox input:focus:checked ~ label:after {
    background-color: #5e59ff !important;
  }

  .ui.checkbox label:hover::before {
    border-color: #141414 !important;
  }

  .system-experience-level {
    min-height: 56px !important;
    display: flex !important;
    align-items: center !important;

    label {
      color: #141414 !important;
      font-family: 'Open Sans' !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: 24px !important;

      ::before {
        width: 24px !important;
        height: 24px !important;
      }

      ::after {
        width: 30px !important;
        height: 30px !important;
        margin-top: -3px;
        margin-left: -3px;
      }
    }
  }

  .system-workout-days-per-weeks {
    cursor: pointer;
    display: flex;
    width: 60px;
    padding: 7px 20px;
    margin-top: -7px;
    margin-right: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 1px solid #ddd;
    background: #fff;

    color: #141414;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    :hover {
      color: #5e59ff;
      border: 1px solid #ddd;
      background: rgba(94, 89, 255, 0.1);
    }

    &.selected {
      background: #5e59ff;
      border: unset;
      color: #fff;
    }
  }
`;

export const FilterModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 20px 16px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  button {
    border-radius: 8px;
    background: #5e59ff;
    color: #fff;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    cursor: pointer;
    width: 161px;
    height: 51px;
    margin-right: unset !important;
    border: none;
    &:hover {
      background: #6b66ff;
      color: #fff;
    }
    &.disable {
      cursor: not-allowed;
      background: #c4c4c4;
    }
  }
`;

export const ClearLabel = styled.div`
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px 24px;
  gap: 8px;
  align-self: stretch;

  ${props =>
    !props.disabled &&
    css`
      :hover {
        border-radius: 8px;
        background: rgba(94, 89, 255, 0.1);
        color: #5e59ff;
      }
    `};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `};
`;

export const SortPopup = styled(Popup)`
  &.ui.popup {
    border-radius: 8px !important;
    border: 1px solid #f2f2f2 !important;
    background: #fff !important;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04) !important;
    z-index: 3000 !important;
  }
  &.ui.bottom.popup {
    margin: 3px 0 0 !important;
  }

  .sort-popup {
    width: 127px;
    height: 98px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 7px 0;
  }

  .sort-option {
    cursor: pointer;
    border: none;
    background: none;
    padding: 11px 24px;

    color: #141414;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-align: start;

    &:hover {
      background: #f2f2f2;
    }

    &.selected {
      color: #5e59ff;
    }
  }
`;
export const DetailModalContainer = styled.div`
  height: 100%;
  border-radius: 12px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }
`;

export const DetailModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 1030px;
  height: 396px;
  background: linear-gradient(180deg, rgba(12, 18, 38, 0.4) 0%, rgba(12, 22, 56, 0) 100%),
    ${props => (props.coverImg ? `url(${props.coverImg})` : '#fff')} no-repeat center center fixed;
  background-size: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 86.5px 40px;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 210px;

  .loading-skeleton-header {
    padding-top: 153px;
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  gap: 15px;
  svg path {
    stroke: #fff;
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  min-height: 108px;

  .detail-title {
    color: #f2f2f2;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    width: 508px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .download-button {
    border-radius: 5px;
    background: #5559ff;
    gap: 3.5px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    padding: 7px 30px;

    cursor: pointer;
    width: 276px;
    height: 58px;
    border: none;

    &:hover {
      background: #4247ff;
    }
    svg {
      margin-right: 15px;
    }
  }

  .download-button.downloaded {
    /* background: #cacaca; */
    cursor: not-allowed;
    /* svg {
      margin-right: 4px;
      margin-left: 3px;
    } */
  }
  .download-button.downloading {
    background-color: #5559ff;
  }

  .download-indicator {
    ::before {
      width: 16px;
      height: 16px;
    }
  }

  .downloaded-template {
    width: 11px;
    height: 11px;
    margin-right: 4px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 8px;
  padding-top: 52px;
  align-items: stretch;

  .is-about {
    padding-right: 74px;
    min-width: 476px;
  }
  .is-equipment {
    padding-right: 31px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(159, 159, 159, 0.15);
  gap: 10px;
  min-height: 298px;
  max-width: 511px;
`;

export const ContentTitle = styled.div`
  color: #202353;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

// export const DetailsInfo = styled.div`
//   color: rgba(32, 35, 83, 0.8);
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 150%;
//   width: 370px;
//   overflow: hidden;
//   display: -webkit-box;
//   -webkit-line-clamp: 3; /* number of lines to show */
//   line-clamp: 3;
//   -webkit-box-orient: vertical;
// `;

export const DetailsProgram = styled.div`
  color: rgba(32, 35, 83, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ProgramItemWrapper = styled.div`
  display: flex;
  gap: 11px;
  padding-top: 6px;
  flex-direction: column;
  align-items: flex-start;
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
`;

export const ProgramItemTitle = styled.div`
  width: 146px;
  margin-right: 2px;
`;

export const ProgramItem = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: #202353;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.13px;
`;

export const DetailProgramItem = styled.span`
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(32, 35, 83, 0.1);
  color: #202353;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;

  ${props =>
    props.moreItem &&
    css`
      font-weight: 600;
      :hover {
        cursor: pointer;
        background: #e4e4e4;
      }
    `}
`;

export const ShowMoreBtn = styled.div`
  color: #202353;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.13px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin-top: -7px;
  :hover {
    text-decoration: underline;
  }
`;

export const TagsItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 220px;
  overflow: hidden;
  width: 370px;
`;

export const TagsPopupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 484px;
  overflow: scroll;
  padding: 32px 40px 32px 40px;

  ::-webkit-scrollbar {
    width: 8px !important;
  }
`;

export const DescriptionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 484px;
  overflow: scroll;
  color: rgba(32, 35, 83, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 32px 36px 0 40px;
  white-space: pre-line;

  ::-webkit-scrollbar {
    width: 8px !important;
  }
`;

export const DetailModalBody = styled.div``;

export const ProgramOverview = styled.div`
  .weeks-skeleton-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px 0;
  }
`;

export const ProgramOverviewTitle = styled.div`
  color: #202353;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.2px;
`;

export const ProgramWeekWrapper = styled.div`
  display: flex;
  height: 43px;
  max-width: 748px;
  width: fit-content;
  padding: 3px;
  align-items: center;
  border-radius: 8px;
  background: #f6f6f6;
  margin: 16px auto 24px auto;

  .week-slider {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    .week-arrow-button-left,
    .week-arrow-button-right {
      position: absolute;
      top: 0;
      z-index: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        border: none;
        height: 37px;
        width: 32px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
        background: #f6f6f6;

        svg path {
          fill: #6f6f71;
        }
        &:hover {
          box-shadow: 0px 4px 16px 0px rgba(159, 159, 159, 0.48);
        }
      }

      &.hidden {
        visibility: hidden;
      }
    }

    .week-arrow-button-left {
      left: -1px;

      button {
        transform: rotateY(180deg);
      }
    }

    .week-arrow-button-right {
      right: -1px;
    }

    .week-scrollable {
      position: relative;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      overflow-x: auto;
      max-width: 748px;

      &::-webkit-scrollbar {
        display: none;
      }

      .week-wrapper {
        display: flex;
        /* width: 748px; */
      }
    }
  }
`;

export const ProgramWeek = styled.div`
  color: #6f6f71;
  font-size: 13px;
  font-weight: 600;
  height: 37px;
  width: 78px;
  cursor: pointer;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;

  :hover {
    color: #000;
  }

  ${props =>
    props.isSelected &&
    css`
      border-radius: 8px;
      background: #000;
      box-shadow: 2px 2px 8px 0px rgba(56, 56, 56, 0.04);
      color: #fff;
      :hover {
        color: #fff;
      }
    `}

  &:not(:first-child) {
    margin-left: 1px;

    :after {
      content: '';
      background: #d9d9d9;
      position: absolute;
      bottom: 9px;
      left: -1px;
      height: 19px;
      width: 1px;
      border-radius: 1px;
    }
  }
`;

export const WeekOverviewContainer = styled.div`
  display: flex;
  gap: 16px;

  .workout-slider {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 1px;

    .arrow-button-left,
    .arrow-button-right {
      position: absolute;
      top: 0;
      z-index: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        border: none;
        background: #fff;
        padding: 0;
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 9999px;
        align-items: center;
        display: flex;
        justify-content: center;
        box-shadow: 0px 4px 32px 0px rgba(159, 159, 159, 0.15);

        svg path {
          stroke: #000;
        }
        &:hover {
          box-shadow: 0px 4px 16px 0px rgba(159, 159, 159, 0.48);
        }
      }

      &.hidden {
        visibility: hidden;
      }
    }

    .arrow-button-left {
      left: 24px;

      button {
        transform: rotateY(180deg);
      }
    }

    .arrow-button-right {
      right: 24px;
    }

    .workout-scrollable {
      position: relative;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;
      overflow-x: auto;
      padding: 0 40px;
      width: 100%;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const WeekdayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  padding-bottom: 16px;

  .program-skeleton-wrapper {
    padding: 8px 0;
  }
`;

export const SkeletonWeekday = styled.div`
  display: flex;
  gap: 216px;
  width: 3000px;
`;

export const SkeletonProgram = styled.div`
  display: flex;
  gap: 16px;
  width: 3000px;
`;

export const WeekdayHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .weekday-skeleton-wrapper {
    padding: 0;
  }
`;

export const TotalWorkout = styled.div`
  color: #5f5f5f;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export const WeekdayLabel = styled.div`
  display: flex;
  padding: 3px 14px;
  width: 74px;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  background: #f9f9f9;
  box-shadow: 0px 4px 32px 0px rgba(159, 159, 159, 0.15);
  color: #202353;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 15px */
  letter-spacing: -0.1px;
  text-transform: uppercase;
`;

export const WorkoutWrapper = styled.div`
  display: flex;
  width: 320px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(159, 159, 159, 0.15);
  margin-bottom: 8px;
  &:nth-child(2) {
    border-top-left-radius: 0px;
  }
  > div.exercises-main-wrapper:not(:last-child):after {
    content: '';
    position: absolute;
    left: 2px;
    top: -25px;
    border-left: 1px dotted #d3d3d3;
    height: calc(100% + 50px);
  }
`;

export const WorkoutName = styled.div`
  color: #202353;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .workout-type--tooltip {
    padding: 8px 15px;
  }
`;

export const SectionName = styled.div`
  color: #202353;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.14px;
  text-transform: capitalize;
  padding-left: 24px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: #e4e4e4;
    top: 8px;
    left: 0;
    border-radius: 50%;
    z-index: 1;
  }

  /* :not(:last-child):after {
    content: '';
    position: absolute;
    left: -22px;
    top: 5px;
    border-left: 1px dotted #d3d3d3;
    height: calc(100% + 28px);
  } */
`;

export const SectionNameWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const SectionType = styled.div`
  color: rgba(158, 158, 158, 0.8);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const SectionFormat = styled.div`
  display: flex;
  margin-left: 18px;

  svg {
    width: 16px;
    height: 16px;
  }

  .custom-program-freestyle:hover {
    cursor: pointer;
    path {
      stroke: #5559ff;
    }
  }
`;

export const ExerciseWrapper = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
  flex-direction: column;
  padding-left: 24px;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 18px;
    &:before {
      width: 248px;
      content: '';
      position: absolute;
      bottom: 6px;
      left: 24px;
      border-bottom: 1px solid rgba(118, 118, 118, 0.1);
    }
  }

  /* ${props =>
    props.isSuperset &&
    css`
      > div:not(:last-child):after {
        content: '';
        position: absolute;
        left: -22px;
        top: 5px;
        border-left: 1px dotted #d3d3d3;
        height: calc(100% + 28px);
      }
    `} */

  div:last-child > .custom-sections:before {
    border-bottom: unset;
  }
  div:last-child > .custom-sections:after {
    border-left: unset;
  }
  div:not(:last-child) > div > div.custom-superset:before {
    width: 248px;
    content: '';
    position: absolute;
    bottom: -12px;
    border-bottom: 1px solid rgba(118, 118, 118, 0.1);
  }
`;

export const ExerciseContentWrapper = styled.div`
  display: flex;
  position: relative;

  /* ${props =>
    props.hasOneValue &&
    css`
      > div::after {
        content: '';
        position: absolute;
        left: -22px;
        top: 3px;
        border-left: 1px dotted #d3d3d3;
        height: 100%;
      }
    `} */

  .custom-sections {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    gap: 25px;

    .exercise-wrapper {
      display: flex;
      /* &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background: #e4e4e4;
        top: 3px;
        left: -24px;
        border-radius: 50%;
        z-index: 1;
      } */
    }

    /* &::after {
      content: '';
      position: absolute;
      left: -22px;
      top: 7px;
      border-left: 1px dotted #d3d3d3;
      height: calc(100% + 25px);
    } */

    &::before {
      width: 100%;
      content: '';
      position: absolute;
      bottom: -13px;
      border-bottom: 1px solid rgba(118, 118, 118, 0.1);
    }
  }
`;

export const ExerciseContentContainer = styled.div`
  display: flex;
`;

export const ExerciseContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 4px;
  padding-right: 8px;
`;

export const ExerciseTitle = styled.div`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  /* &::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: #e4e4e4;
    top: 2px;
    left: -24px;
    border-radius: 50%;
    z-index: 1;
  } */
`;

export const ExerciseRep = styled.div`
  width: 219px;
  color: #5f5f5f;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  height: 13px;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
`;

export const ExerciseAmount = styled.div`
  color: #7b7e91;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  align-items: center;
`;

export const SupersetWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;

  .custom-superset {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }

  .custom-superset > div:nth-child(2):before {
    content: '';
    position: absolute;
    width: 10px;
    height: calc(100% - 45px);
    border: 1px dotted #d3d3d3;
    border-radius: 5px;
    left: -11px;
    top: 25px;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
  }
`;

export const SupersetLabel = styled.div`
  color: #7b7e91;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: -4px;
  position: relative;

  /* &::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: #e4e4e4;
    top: 3px;
    left: -24px;
    border-radius: 50%;
    z-index: 1;
  } */
`;

export const InstructionWrapper = styled.div`
  display: flex;
  padding: 32px 40px 0 40px;
  align-items: flex-start;
  gap: 8px;
  height: calc(100% - 95px);
`;

export const TagPopupContent = styled.div`
  display: flex;
  padding-right: 4px;
  padding-bottom: 32px;
  align-items: flex-start;
`;

export const InstructionContent = styled.div`
  color: #5f5f5f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const LoadingItem = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  pointer-events: none;

  svg {
    width: 100%;
  }
`;

export const BgOverlay = styled.div`
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: -1px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;

  ${props =>
    props.show &&
    css`
      display: block;
    `}

  ${props =>
    props.sticky &&
    css`
      z-index: 1152;
    `}
`;

export const ModalityWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    display: block;
    margin-left: 8px;
    cursor: pointer;
    path {
      opacity: 0.4;
    }
    &:hover path {
      fill: #000;
      opacity: 1;
    }
  }
  .__react_component_tooltip {
    padding: 15px;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 309px;
  }
`;

export const CounterFilters = styled.div`
  right: -12px;
  top: -11px;
  width: 24px;
  height: 24px;
  border: 3px solid #fff;
  position: absolute;
  background: #5559ff;
  border-radius: 15px;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  color: rgb(255, 255, 255);
  text-align: center;
  z-index: 1;
`;

export const DetailModalWrapper = styled.div`
  background-color: #fff;
  border-radius: 15px;
`;
