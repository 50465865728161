import React from 'react';
import ReactDOM from 'react-dom';
import { Image } from 'semantic-ui-react';
import { RootCloseWrapper } from 'react-overlays';
import classNames from 'classnames';
import { CDN_URL } from 'constants/commonData';

import './style.scss';

class ContentEditable extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isFocus: false,
      isError: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.inputRef.current.innerText || nextState.isFocus !== this.state.isFocus;
  }

  componentDidUpdate() {
    const { requestResetValue } = this.props;

    if (requestResetValue) {
      requestResetValue.current = this.handleResetValue;
    }
  }

  handleResetValue = () => {
    const { value } = this.props;
    this.inputRef.current.innerText = value;
    this.lastHtml = value;
    this.inputRef.current.blur();
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (this.inputRef.current) {
        this.inputRef.current.blur();
      } else {
        this.handleSave();
      }
    } else {
      const { maxLength } = this.props;
      const value = this.inputRef.current.innerText;

      if (maxLength && value.length > maxLength) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  handleSave = event => {
    const { onChange, required } = this.props;
    const value = this.inputRef.current.innerText;

    if (required && (!value || !value.trim())) {
      this.setState({
        isError: true,
      });
      return;
    }

    this.setState(
      {
        isFocus: false,
        isError: false,
      },
      () => {
        if (this.inputRef.current) {
          this.inputRef.current.scrollLeft = 0;
        }

        if (onChange && value !== this.lastHtml) {
          onChange({ value });
        }

        this.lastHtml = value;
      },
    );
  };

  handleOnPaste = event => {
    let text = '';

    if (event.clipboardData && event.clipboardData.getData) {
      text = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData && window.clipboardData.getData) {
      text = window.clipboardData.getData('Text');
    }

    const { maxLength } = this.props;
    const value = this.inputRef.current.innerText;

    const remainingChars = maxLength + 1 - value.length;

    if (remainingChars < text.length) {
      const subText = text.substring(0, remainingChars);
      document.execCommand('insertHTML', false, subText);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  render() {
    const { id, value, editable = true } = this.props;
    const { isFocus, isError } = this.state;

    return (
      <div
        className={classNames('content-editable', isFocus ? 'focus' : '', isFocus && isError ? 'error' : '')}
        id={id}
      >
        <div
          className="content-editable__input"
          contentEditable={editable}
          onKeyPress={this.handleKeyPress}
          onFocus={() => {
            this.setState({ isFocus: true });
          }}
          dangerouslySetInnerHTML={{ __html: value }}
          ref={this.inputRef}
          onBlur={this.handleSave}
          onDrop={e => e.preventDefault()}
          onPaste={this.handleOnPaste}
        />
        {isFocus && (
          <div className="content-editable__buttons">
            <button
              type="button"
              className="button"
              onClick={() => this.inputRef.current && this.inputRef.current.blur()}
              onMouseDown={e => e.preventDefault()}
            >
              <Image src={`${CDN_URL}/images/checkbox_greenwhite.svg`} width={16} height={16} />
            </button>
            <button
              type="button"
              className="button"
              onClick={event => {
                this.setState({ isFocus: false, isError: false }, () => {
                  this.inputRef.current.innerText = value;
                  this.inputRef.current.blur();
                });
              }}
              onMouseDown={e => e.preventDefault()}
            >
              <Image src={`${CDN_URL}/images/close_circle.svg`} width={16} height={16} />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ContentEditable;
