import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import ConfirmModal from 'shared/ConfirmModal';
import { pluralize } from 'utils/commonFunction';
import { ReactComponent as CloseIconCopyWeek } from 'assets/icons/close-copy-week.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-copy-workouts.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-workouts.svg';
import { ReactComponent as HideIcon } from 'assets/icons/hide-workouts.svg';
import { KEY_CODE, CLASSNAME_TOAST, CDN_URL } from 'constants/commonData';

import '../styles.scss';
import * as S from '../styles';

function AlterWorkoutSelected(props) {
  const {
    selectedWorkout,
    handleResetSelectWorkout,
    handleCopyWorkout,
    toggleConfirmModal,
    handleRemoveWorkouts,
    calendarMode,
    permission,
    handleHideWorkouts,
  } = props;
  useEffect(() => {
    window.addEventListener('keydown', onEscPressed);
    return () => {
      window.removeEventListener('keydown', onEscPressed);
    };
  }, []);
  const onEscPressed = event => {
    const { toggleConfirmModal } = props;
    if (
      event.keyCode === KEY_CODE.esc &&
      document.querySelector('.remove-week-popup, .remove-workout-popup, .calendar--max-select')
    ) {
      toggleConfirmModal && toggleConfirmModal(false);
    }
  };
  if (!process.env.REACT_APP_COPY_WEEK_ENABLE_V1 || !get(permission, 'copy_week')) return <></>;
  if (isEmpty(selectedWorkout) || isEqual(calendarMode, 'history')) return <></>;
  function onCopyWorkout() {
    handleCopyWorkout();
    toast(
      `${selectedWorkout.length} ${pluralize(
        'Workout',
        selectedWorkout.length,
      )} copied. Click on date to paste ${pluralize('workout', selectedWorkout.length)}.`,
      { className: CLASSNAME_TOAST.TRAINING_CALENDAR },
    );
  }
  function onRemoveWorkout() {
    toggleConfirmModal(
      true,
      <ConfirmModal
        headerIcon={`${CDN_URL}/images/remove_icon_bg_red.svg`}
        title={`Remove ${selectedWorkout.length} ${pluralize('workout', selectedWorkout.length)}`}
        content={`The selected workouts will be permanently deleted. Would you like to continue?`}
        onConfirm={handleRemoveWorkouts}
        onDeny={() => {}}
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        noBorder={true}
        hasCloseIcon={true}
        className="remove-workout-popup"
      />,
    );
  }
  return (
    <S.BottomSelectWorkoutWrapper>
      <S.BottomNumberSelect>
        <S.BottomNumber>{selectedWorkout.length}</S.BottomNumber>
        {`${pluralize('Workout', selectedWorkout.length)} selected`}
      </S.BottomNumberSelect>
      <S.BottomActionsContainer>
        <S.BottomActions onClick={onRemoveWorkout}>
          <S.IconActions>
            <TrashIcon />
          </S.IconActions>
          <S.TextActions>Remove</S.TextActions>
        </S.BottomActions>
        <S.BottomActions onClick={onCopyWorkout}>
          <S.IconActions>
            <CopyIcon />
          </S.IconActions>
          <S.TextActions>Copy</S.TextActions>
        </S.BottomActions>
        {handleHideWorkouts ? (
          <S.BottomActions onClick={handleHideWorkouts}>
            <S.IconActions>
              <HideIcon />
            </S.IconActions>
            <S.TextActions>Hide from client</S.TextActions>
          </S.BottomActions>
        ) : null}
      </S.BottomActionsContainer>
      <S.BottomCancel>
        <S.TextCancel>
          <S.CancelBold>Esc</S.CancelBold>&nbsp;to cancel
        </S.TextCancel>
        <CloseIconCopyWeek onClick={handleResetSelectWorkout} />
      </S.BottomCancel>
    </S.BottomSelectWorkoutWrapper>
  );
}

export default AlterWorkoutSelected;
