import _ from 'lodash';
import moment from 'moment';
import { Types } from './actions';
import { removeAllParamsFromURL } from 'utils/commonFunction';

const INITIAL_STATE = {
  postByDate: {},
  allPosts: [],
  page: 1,
  per_page: 20,
  total: 0,
  viewing: null,
  isGettingData: true,
  isHighFive: false,
  isLoadingHighFive: false,
  highFives: [],
};

const getListSuccess = (oldState, payload) => {
  const { allPosts } = oldState;
  const { data = [], total, page } = payload;
  const formatData = data.map(it => {
    const pictures = _.get(it, 'pictures', []).map(i => removeAllParamsFromURL(i));
    const pictures_thumbnail = _.get(it, 'pictures_thumbnail', []).map(i => removeAllParamsFromURL(i));
    return {
      ...it,
      pictures,
      pictures_thumbnail,
    };
  });
  const newPosts = _.unionBy(formatData, allPosts, '_id');
  const ordered = _.orderBy(newPosts, 'date', ['desc']);
  const ids = _.map(ordered, item => ({ _id: item._id, date: item.date }));
  const postByDate = _.groupBy(ids, item => moment.utc(item.date).format('MM-DD-YYYY'));

  return Object.assign({}, oldState, {
    allPosts: ordered,
    page: parseInt(page),
    total: parseInt(total),
    postByDate,
    isGettingData: false,
  });
};

const likeUnlikePost = (oldState, payload) => {
  const { data, from } = payload;

  if (from === 'detail') {
    return Object.assign({}, oldState, { viewing: data });
  }

  const { allPosts } = oldState;
  const newPosts = allPosts.slice();
  const index = _.findIndex(newPosts, item => item._id === data._id);

  if (index !== -1) {
    newPosts[index] = data;
  }

  return Object.assign({}, oldState, { allPosts: newPosts });
};

const updatePostsAfterViewDetail = (oldState, payload) => {
  const { viewing, allPosts } = oldState;

  if (!viewing || !allPosts.length) {
    return oldState;
  }

  const { data } = payload;
  const newPosts = allPosts.slice();
  const index = _.findIndex(newPosts, item => item._id === viewing._id);
  if (index !== -1) {
    const newData = { ...viewing, ...data };
    newPosts[index] = { ...viewing, ...data };
  }

  return Object.assign({}, oldState, { allPosts: newPosts, viewing: null });
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.FOOD_JOURNAL_SUCCESS_GET_LIST:
      return getListSuccess(state, payload);

    case Types.FOOD_JOURNAL_UNMOUNT:
      return INITIAL_STATE;

    case Types.FOOD_JOURNAL_SUCCESS_LIKE_UNLIKE_POST:
      return likeUnlikePost(state, payload);

    case Types.FOOD_JOURNAL_SUCCESS_GET_POST_DETAIL:
      return Object.assign({}, state, {
        viewing: payload.data,
        from: payload.from,
        highFives: _.get(payload, 'data.high_fives', []),
        isHighFive: _.get(payload, 'data.is_high_five', []),
      });

    case Types.FOOD_JOURNAL_UPDATE_POSTS_AFTER_VIEW_DETAIL:
      return updatePostsAfterViewDetail(state, payload);

    case Types.FOOD_JOURNAL_HIGH_FIVE_REQUEST:
      return Object.assign({}, state, { isLoadingHighFive: true });

    case Types.FOOD_JOURNAL_HIGH_FIVE_SUCCESS:
      return Object.assign({}, state, {
        isHighFive: payload,
        isLoadingHighFive: false,
      });

    case Types.FOOD_JOURNAL_HIGH_FIVE_FAILED:
      return Object.assign({}, state, { isLoadingHighFive: false });

    default:
      return state;
  }
};
