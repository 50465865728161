import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'semantic-ui-react';
import _ from 'lodash';
import classNames from 'classnames';
import { parsePhoneNumberFromString, parseIncompletePhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FormGroup, DownLoadContainer, FormTitle } from './style';
import {
  Container,
  Title,
  SubTitle,
  CompleteContainer,
  OptionContainer,
  OptionLabel,
  OptionLabelCode,
} from './SendLink.style';
import { generateApiUrl } from 'utils/commonFunction';
import IOS from 'assets/images/download_ios.png';
import { ReactComponent as Android } from 'assets/images/download_android.svg';
import Select from 'react-select';
import { DropdownIndicator } from 'shared/Icons';
import { CDN_URL } from 'constants/commonData';

const COUNTRY_CODES = [
  // { key: 1, value: '+61', label: '+61', codeLabel: 'AUS', code: 'AT' },
  { key: 2, value: '+1', label: '+1', codeLabel: 'CAN', code: 'CA' },
  { key: 3, value: '+44', label: '+44', codeLabel: 'UK', code: 'GB' },
  { key: 4, value: '+1', label: '+1', codeLabel: 'US', code: 'US' },
];

const SelectOption = ({ innerProps, data, isFocused }) => {
  return (
    <OptionContainer
      {...innerProps}
      className={classNames('select_option', { 'select__option--is-focused': isFocused })}
    >
      <OptionLabel>{data.codeLabel}</OptionLabel>
      <OptionLabelCode>{data.label}</OptionLabelCode>
    </OptionContainer>
  );
};

function SendLink({
  token,
  onSuccess,
  client,
  mainColor,
  phoneNumber,
  downloadLinkBy,
  setPhoneNumber,
  setComplete,
  setDownloadLinkBy,
  linksDownload,
  setPhoneCountryCode,
  team,
}) {
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [country, setCountry] = useState(COUNTRY_CODES[2]);

  useEffect(() => {
    setPhoneCountryCode(country);
  }, []);

  const onChange = event => {
    const { value } = event.target;
    let newValue = parseIncompletePhoneNumber(value);

    // By default, if a value is something like `"(123)"`
    // then Backspace would only erase the rightmost brace
    // becoming something like `"(123"`
    // which would give the same `"123"` value
    // which would then be formatted back to `"(123)"`
    // and so a user wouldn't be able to erase the phone number.
    // Working around this issue with this simple hack.
    if (newValue === phoneNumber) {
      if (format(newValue).indexOf(value) === 0) {
        // Trim the last digit (or plus sign).
        newValue = newValue.slice(0, -1);
      }
    }

    setPhoneNumber(newValue);
  };

  const format = value => formatIncompletePhoneNumber(value, country.code);

  const parsePhone = value => parsePhoneNumberFromString(value, country.code);

  const onSubmit = event => {
    event.preventDefault();

    if (isSubmitting) {
      return false;
    }
    const parsed = parsePhone(phoneNumber);
    setShowError(true);

    if (parsed && parsed.isValid()) {
      const apiUrl = generateApiUrl('/api/auth/send_link');
      const headers = { 'x-access-token': token };
      setDownloadLinkBy('mobile');
      setIsSubmitting(true);
      axios
        .post(apiUrl, { phone: parsed.number, countryCode: country.code, teamId: team._id }, { headers })
        .then(response => {
          setIsSubmitting(false);
          setComplete(true);
          typeof onSuccess === 'function' && onSuccess();
        })
        .catch(error => {
          let messaging = _.get(error, 'response.data.message', 'Can not send link');
          toast(<div style={{ marginRight: 30 }}>{messaging}</div>);
          setIsSubmitting(false);
        });
    }
  };

  const handleOnChangeCountry = newValue => {
    setCountry(newValue);
    setPhoneCountryCode(newValue);
    setShowError(false);
  };

  const onSendEmail = () => {
    const apiUrl = generateApiUrl('/api/send-mail/send-download-link-email');
    const headers = { 'x-access-token': token };
    setDownloadLinkBy('email');
    setIsSubmitting(true);
    axios
      .post(apiUrl, { email: _.get(client, 'email'), first_name: _.get(client, 'first_name') }, { headers })
      .then(response => {
        setIsSubmitting(false);
        setComplete(true);
        typeof onSuccess === 'function' && onSuccess();
      })
      .catch(error => {
        let messaging = _.get(error, 'response.data.message', 'Can not send link');
        toast(<div style={{ marginRight: 30 }}>{messaging}</div>);
        setIsSubmitting(false);
      });
  };

  const countryName = useMemo(() => {
    return country.code === 'CA' ? 'Canada' : country.codeLabel;
  }, [country]);

  const error = showError && (!parsePhone(phoneNumber) || !parsePhone(phoneNumber).isValid());

  const appName = useMemo(() => {
    return _.get(team, 'white_label_app_name', 'Everfit');
  }, [team]);

  return (
    <Container mainColor={mainColor}>
      <Title>
        Success! Account created
        <br />
        Welcome to {appName}.
      </Title>
      <SubTitle>Install the {appName} app to begin training.</SubTitle>
      <DownLoadContainer>
        <div className="download__links">
          <a href={linksDownload.ios} target="_blank">
            <img src={IOS} alt="iOS" />
          </a>
          <a href={linksDownload.android} target="_blank">
            <Android />
          </a>
        </div>
      </DownLoadContainer>
      <Image src={`${CDN_URL}/images/register_success_1.svg`} />
      <form onSubmit={onSubmit}>
        <FormGroup className="send_link">
          <FormTitle>
            Or receive the download links
            <br /> through text or email:
          </FormTitle>
          <div className="phone">
            <div className="phone__input">
              <Select
                options={COUNTRY_CODES}
                components={{ DropdownIndicator, IndicatorSeparator: null, Option: SelectOption }}
                classNamePrefix="evfSelectBox"
                className="evfSelectBoxContainer"
                value={country}
                onChange={handleOnChangeCountry}
                isSearchable={false}
              />
              <input
                value={format(phoneNumber)}
                onChange={event => onChange(event)}
                placeholder="mobile number"
                autoFocus={true}
              />
            </div>
            {!!error && <div className="error">{countryName} mobile numbers only</div>}
          </div>
          <button type="submit">Send link</button>
          <div className="send-email">
            <button
              onClick={onSendEmail}
              disabled={isSubmitting && downloadLinkBy === 'email'}
              className="send-email-link"
            >
              Email me link
            </button>
          </div>
        </FormGroup>
      </form>
    </Container>
  );
}

export default SendLink;
