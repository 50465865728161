import React from 'react';
import { CONVERSION, FILE_ERRORS } from 'constants/commonData';
import get from 'lodash/get';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { FILE_VALIDATION } from './components/MediaSide/constants';
import PopUpForm from './components/PopUpForm';
import { convertS3UrlToCloudFrontUrl, getUserShortName } from 'utils/commonFunction';

import { ReactComponent as PollIcon } from 'assets/icons/poll_forum_post_icon.svg';
import { ReactComponent as CameraIcon } from 'assets/icons/camera_blue_icon.svg';

import * as S from './style.js';

const validateFiles = ({ files, validateExtentions }) => {
  let error = null;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const { size, name, type } = file;

    if (validateExtentions) {
      const fileExtension = name.split('.').pop().toLowerCase();

      if (!validateExtentions.includes(fileExtension)) {
        error = { type: FILE_ERRORS.FILE_TYPE_INVALID };
        break;
      }
    }

    if (
      (type.includes('video') && size > FILE_VALIDATION.MAX_SIZE_VIDEO * CONVERSION.MB_TO_BYTE) ||
      (type.includes('image') && size > FILE_VALIDATION.MAX_SIZE_IMAGE * CONVERSION.MB_TO_BYTE)
    ) {
      error = { type: FILE_ERRORS.FILE_SIZE_LIMIT };
      break;
    }
  }

  return error;
};

function CreatePostForm(props) {
  const getPermissionStatus = get(props, 'permissions.autoflow') || get(props, 'enabledAddons.automation');
  const handleTogglePopup = () => {
    props.toggleModal(true, <PopUpForm {...props} isSchedulePost={getPermissionStatus} firstCreate />);
  };

  const handleTogglePopupAndUpload = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', FILE_VALIDATION.ACCEPTED);
    await input.click();
    await input.addEventListener(
      'change',
      function () {
        const { files } = this;
        if (files.length) {
          const error = validateFiles({
            files,
            validateExtentions: FILE_VALIDATION.VALIDATE_EXTENTION,
          });
          if (error) {
            let message = '';
            if (error.type === FILE_ERRORS.FILE_TYPE_INVALID) {
              message = 'File type not supported.';
            } else if (error.type === FILE_ERRORS.FILE_SIZE_LIMIT) {
              message = 'File too large. Please upload a smaller file';
            }
            toast.error(message);
          } else {
            props.toggleModal(true, <PopUpForm {...props} file={this.files} isSchedulePost={getPermissionStatus} />);
          }
        }
      },
      false,
    );
  };

  const handleTogglePollForm = () => {
    props.toggleModal(true, <PopUpForm {...props} isSchedulePost={getPermissionStatus} openPollDefault={true} />);
  };

  return (
    <S.CreatePostForm>
      <S.Container>
        <div className="avatar-container">
          <Avatar
            className="client-detail-avatar"
            size="38"
            name={getUserShortName(props.user)}
            src={convertS3UrlToCloudFrontUrl(props.user.avatar, props.cloudfrontList, true)}
            color={props.user.color}
          />
        </div>
        <S.WriteSomethingField onClick={handleTogglePopup}>Write something...</S.WriteSomethingField>
      </S.Container>
      <S.AssetButtonWrapper>
        <S.AddAssetButton onClick={handleTogglePopupAndUpload}>
          <CameraIcon />
          <S.AddAssetText>Photo/Video</S.AddAssetText>
        </S.AddAssetButton>
        <S.AddAssetButton onClick={handleTogglePollForm}>
          <PollIcon />
          <S.AddAssetText>Poll</S.AddAssetText>
        </S.AddAssetButton>
      </S.AssetButtonWrapper>
    </S.CreatePostForm>
  );
}

export default CreatePostForm;
