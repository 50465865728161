import _ from 'lodash';
import { Mixpanel } from 'utils/mixplanel';
import Request from 'configs/request';
import { getListTasks } from '../task/actions';
import { toast } from 'react-toastify';

export const Types = {
  ADD_AUTOFLOW_HABIT: 'ADD_AUTOFLOW_HABIT',
  REMOVE_AUTOFLOW_HABIT: 'REMOVE_AUTOFLOW_HABIT',
  GET_DETAIL_AUTOFLOW_HABIT: 'GET_DETAIL_AUTOFLOW_HABIT',
  UPDATE_AUTOFLOW_HABIT: 'UPDATE_AUTOFLOW_HABIT',
  SAVE_TO_LIBRARY_AUTOFLOW_HABIT: 'SAVE_TO_LIBRARY_AUTOFLOW_HABIT',
};

export const addAutoflowHabit = data => {
  return Request.post({ url: '/api/autoflow-habits', data }, true, (result, { dispatch }) => {
    const data = _.get(result, 'data.data');
    if (data) {
      Mixpanel.track('add_habit_autoflow_exact_date');
      dispatch({ type: Types.ADD_AUTOFLOW_HABIT });
      dispatch(getListTasks());
    }
  });
};

export const removeAutoflowHabit = id => {
  if (!id) return;

  return Request.delete({ url: `/api/autoflow-habits/${id}` }, true, (result, { dispatch }) => {
    if (_.get(result, 'data.data.success')) {
      toast('The habit has been deleted.');
      Mixpanel.track('remove_habit_autoflow_exact_date');
      dispatch({ type: Types.REMOVE_AUTOFLOW_HABIT });
      dispatch(getListTasks());
    }
  });
};

export const getDetailAutoflowHabit = id => {
  if (!id) return;

  return Request.get({ url: `/api/autoflow-habits/${id}` }, true, (result, { dispatch }) => {
    if (_.get(result, 'data.data')) {
      dispatch({ type: Types.GET_DETAIL_AUTOFLOW_HABIT });
    }
  });
};

export const updateAutoflowHabit = (id, data) => {
  if (!id) return;

  return Request.patch({ url: `/api/autoflow-habits/${id}`, data }, true, (result, { dispatch }) => {
    const data = _.get(result, 'data.data');
    if (data) {
      dispatch({ type: Types.UPDATE_AUTOFLOW_HABIT });
      dispatch(getListTasks());
    }
  });
};

export const saveAutoflowHabitToLibrary = id => {
  if (!id) return;

  return Request.post({ url: `/api/autoflow-habits/${id}/save-to-library` }, true, (result, { dispatch }) => {
    if (_.get(result, 'data.data.success')) {
      dispatch({ type: Types.SAVE_TO_LIBRARY_AUTOFLOW_HABIT });
      toast('Habit has been added to your Library');
    }
  });
};
