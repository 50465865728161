import styled, { css, keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  width: 25px;
  height: 25px;
  border: 4px solid rgba(255, 255, 255, 0.5);
  border-left: 4px solid #fff;
  border-radius: 100%;
  animation: ${loading} 1s linear infinite;
`;

export const NoData = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.4;
`;

export const Trigger = styled.div`
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 30px;
  height: 30px;

  .exHistory__trigger__defaultIcon {
    color: #758094;
  }

  :hover {
    background-color: #eaebff;
    cursor: pointer;

    .exHistory__trigger__defaultIcon {
      color: #5158cf;
    }
  }
`;

export const PopupHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  button {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #fff;
    padding: 5px 14px;
    background: #606060;
    border-radius: 4px;
    border: none;

    :hover {
      cursor: pointer;
      background: #fff;
      color: #2d2e2d;
    }
  }
`;

export const DeletedExercise = styled.div`
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  padding: 13px;
  text-transform: none;
`;

export const PopupContent = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;
  flex: 1 1;
  overflow: auto;

  ${props =>
    props.center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

export const Row = styled.div`
  padding: 10px 14px 14px;
  display: flex;
  justify-content: space-between;

  > div {
    :nth-child(1) {
      width: 70px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      font-stretch: normal;
      letter-spacing: normal;
      color: #fff;
    }

    :nth-child(2) {
      flex: 1 1;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: lowercase;
      list-style-type: decimal;
      font-stretch: normal;
      letter-spacing: normal;
    }
  }

  ul {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: lowercase;
    list-style-type: decimal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #fff;
    margin: 0;
    padding-left: 14px;

    li {
      margin-bottom: 6px;

      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const DayHeader = styled(Row)`
  padding: 6px 14px;
  background-color: #404140;

  > div {
    :nth-child(2) {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #b3b3b3;
      letter-spacing: normal;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const DayDataContainer = styled.div``;

export const PopupContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 9999;
  height: 280px;
  width: 325px;
  overflow: visible;
  background-color: #2d2e2d;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  :before {
    content: '';
    position: absolute;
    border: 1px solid transparent;
    right: 56px;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background-color: #2d2e2d;
  }
`;

export const Wrapper = styled.div`
  &.exHistory--open {
    ${PopupContainer} {
      visibility: visible;
      opacity: 1;
    }

    ${Trigger} {
      border-color: #5158cf;
    }
  }

  &.top {
    ${PopupContainer} {
      :before {
        bottom: -3px;
      }
    }
  }

  &.bottom {
    ${PopupContainer} {
      :before {
        top: -3px;
      }
    }
  }
`;
