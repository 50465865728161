import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const HeaderStyles = styled(Modal.Header)`
  border-top-left-radius: 12px;
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
    color: #202353;
  }
`;

export const ContentStyles = styled(Modal.Content)`
  display: flex !important;
  margin-bottom: 25px;

  .macros-type-modal {
    flex: 1;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    text-align: center;
    padding: 25px 35px 35px;
    cursor: pointer;

    .text {
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
    }
  }

  .divine {
    width: 20px;
  }
`;

export default styled(Modal)`
  width: 450px !important;
`;
