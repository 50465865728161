import styled, { css } from 'styled-components';

const baseText = `
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  color: #202353;
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const List = styled.div`
  position: absolute;
  top: 100%;
  left: 5px;
  box-shadow: 0px 2px 8px 0px #26262633;
  padding: 10px;
  border-radius: 10px;
  min-width: 187px;
  background-color: #fff;
  z-index: 2;
`;

export const Item = styled.div`
  ${baseText}
  padding: 10px 30px 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
  }
  ${props =>
    props.active &&
    css`
      color: #5158cf;
      font-weight: 600;
    `}
`;

export const TriggerWrapper = styled.div`
  ${baseText}
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px 9px 6px 5px;
  height: 30px;
  border-radius: 5px;

  svg {
    transform: rotate(180deg);
    margin-left: 4px;
    path {
      fill: #202353;
    }
  }
  &:hover {
    background-color: #f0f1ff;
    color: #5158cf;
    svg path {
      fill: #5158cf;
    }
  }
`;
