import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button as CloseButton, Image } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Button } from 'shared/FormControl';
// import actions
import { toggleModal } from 'actions/modal';
import { autoflowDisconnectForum } from 'redux/autoflow/forum/actions';

import { CDN_URL } from 'constants/commonData';

// import assets
import ForumDisconnectImg from 'assets/images/forum-disconnect.png';
import * as S from './style';

const PauseModal = props => {
  const { autoflowId, forumDetail, autoflowDisconnectForum } = props;
  const forumInfo = forumDetail ? forumDetail.toJS() : {};

  return (
    <S.ModalStyle
      open={true}
      closeIcon={
        <CloseButton className="close-button">
          <Image src={`${CDN_URL}/images/close_circle.svg`} />
        </CloseButton>
      }
      onClose={() => props.toggleModal(false)}
      className="autoflow-disconnect-forum-modal"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <S.Container>
          <S.Header>
            <Image src={ForumDisconnectImg} />
            <span>Disconnect Forum</span>
          </S.Header>
          <S.Body>
            <div>
              Once disconnected, the members of ‘{forumInfo.name}’ will no longer have access to the leaderboard. But
              members will still remain in the forum. Would you like to disconnect?
            </div>
          </S.Body>
          <S.Footer>
            <Button onClick={() => props.toggleModal(false)}>Cancel</Button>
            <Button
              className="action-btn"
              onClick={() => {
                autoflowDisconnectForum(autoflowId).then(() => toast('Forum has been disconnected'));
              }}
            >
              Disconnect
            </Button>
          </S.Footer>
        </S.Container>
      </Modal.Content>
    </S.ModalStyle>
  );
};

const mapState = (state, props) => {
  const { rootReducer } = state;
  const { workingAutoflow } = rootReducer.autoflow.common;
  const { forum } = rootReducer.autoflow;

  return {
    autoflowId: workingAutoflow && workingAutoflow._id,
    forumDetail: forum.get('forumDetail'),
  };
};

const mapDispatch = (dispatch, props) => {
  return {
    toggleModal: bindActionCreators(toggleModal, dispatch),
    autoflowDisconnectForum: bindActionCreators(autoflowDisconnectForum, dispatch),
  };
};

export default connect(mapState, mapDispatch)(PauseModal);
