import styled from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const DayTooltip = styled.div`
  background: #f4f5f9;
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #575c77;
  position: absolute;
  transform: translate(50%, -50%);
`;

export const NoData = styled.div`
  color: #000000;
  opacity: 0.3;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Wrapper = styled.div`
  .monthly-report__chart {
    position: relative;

    .monthly-report__goal-tooltip {
      position: absolute;
      padding-left: 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #202353;
      background-repeat: no-repeat;
      background-position: 5px;
      background-color: #ffffff;
      z-index: 2;

      &--step-goal {
        left: 35px;
        background-image: url(${CDN_URL}/images/macros_training_day_bold.svg);
        background-color: transparent;
        background-position: right;
        width: calc(100% - 25px);
        height: 10px;
        cursor: pointer;
      }

      &--line {
        height: 2px;
        margin-top: 6px;
        width: calc(100% - 20px);
        margin-left: 20px;
        cursor: pointer;
      }
    }

    .monthly-report__line-break {
      width: 2px;
    }
  }
`;

export const DayHover = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 1;

  .__react_component_tooltip {
    font-weight: bold;
    background: #2d2e2d;
    border-radius: 5px;
    font-size: 13px;
    line-height: 150%;
    padding: 6px 10px;
  }
`;

export const WeekHover = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  transform: translateX(100%);

  &:hover {
    background: linear-gradient(180deg, rgba(255, 76, 20, 0) 0%, #ff5a26 100%);
    opacity: 0.1;
  }
`;
