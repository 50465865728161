// libs
import React from 'react';

// shared
import { ErrorMessage, Toggle } from 'shared/FormControl';
import FormItemInput from 'shared/FormItemInput';
import StatusDropdown from 'shared/StatusDropdown';
import OnboardingFlowSelect, { ONBOARDING_FLOW_SELECT_MODE } from 'shared/OnboardingFlowSelect';

// components
import CustomSelect from '../CustomSelect';
import CustomCheckbox from '../CustomCheckbox';
import RadioPurchaseOption from '../RadioPurchaseOption';
import DropdownPurchaseOption from '../DropdownPurchaseOption';
import SurveyPurchaseOption from '../SurveyPurchaseOption';

// utils
import InboxOption from '../InboxOption';
import { pluralize } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';
import { validateNumberOnly } from 'utils/validations';

// styles
import * as S from './styles';

const statusOptions = [
  { value: false, label: 'Not Allowed', color: '#818DA1' },
  { value: true, label: 'Allowed', color: '#49CA8D' },
];

function Card({
  isMP = false,
  name,
  value,
  description,
  placeholderInput,
  disabled = true,
  type = 'string',
  isOther = false,
  limit = -1,
  purchased = 0,
  onChange,
  hasError = false,
  errorMessage = '',
  height,
  isRemove = true,
  onToggle,
  options = [],
  emptyMessage = '',
  emptySearchMessage = '',
  checkedTitleDefault = '',
  waitlistDate,
  onChangeWaitlistDate,
  waitlistTime,
  onChangeWaitlistTime,
  purchaseLimit,
  waitlistTimeErrorMessage = '',
  onOpenPurchaseLimitWarningPopup,
  totalOffered,
  onChangeTimezone,
  timezone,
  survey,
  dateFormat,
  onOpenSurveyModal,
  surveyErrorMessage,
  onChangeSurvey,
  toggleConfirmModal,
  cloudfrontList,
  toggleSecondModal,
  isDraft,
}) {
  const withValueLimit = ({ value }) => value.length < 7;

  const renderContent = () => {
    if (type === 'dropdown' && typeof value === 'boolean') {
      if (isOther && disabled) {
        return isRemove ? (
          <S.CardContentWrapper>
            <S.StatusWrapper>
              <S.StatusIcon statusColor={value ? statusOptions[1].color : statusOptions[0].color} />
              <S.CardText>{value ? statusOptions[1].label : statusOptions[0].label}</S.CardText>
            </S.StatusWrapper>
            <S.CardText>The sequence will be removed when the subscription is ended</S.CardText>
          </S.CardContentWrapper>
        ) : (
          <S.CardContentWrapper>
            <S.StatusWrapper>
              <S.StatusIcon statusColor={value ? statusOptions[1].color : statusOptions[0].color} />
              <S.CardText>{value ? statusOptions[1].label : statusOptions[0].label}</S.CardText>
            </S.StatusWrapper>
          </S.CardContentWrapper>
        );
      }

      return value ? (
        <S.CardContentWrapper>
          <StatusDropdown width={141} options={statusOptions} value={value} onChange={onChange} />
          <S.RemoveOptionWrapper>
            <S.CardTextBold>Remove sequence when the subscription is ended</S.CardTextBold>
            <Toggle checked={isRemove} onChange={onToggle} />
          </S.RemoveOptionWrapper>
        </S.CardContentWrapper>
      ) : (
        <S.CardContentWrapper>
          <StatusDropdown width={141} options={statusOptions} value={value} onChange={onChange} />
        </S.CardContentWrapper>
      );
    }

    const renderPurchaseLimitPreview = () => {
      if (isMP) {
        return (
          <S.CardSubTextMP>
            (Sold: <strong>{purchased}</strong>, Offered: <strong>{totalOffered}</strong>, Total:&nbsp;
            <strong>
              {purchased + totalOffered}/{limit}
            </strong>
            )
          </S.CardSubTextMP>
        );
      }
      return (
        <S.CardSubText>
          ({purchased}/{limit})
        </S.CardSubText>
      );
    };

    if (isOther && disabled) {
      return (
        <S.CardContentWrapper>
          <S.CardText>
            {value <= 0 ? (
              'No limit'
            ) : limit > 0 ? (
              <>
                Limit to <strong>{limit}</strong> {pluralize('purchase', limit)}
                &nbsp;
                {renderPurchaseLimitPreview()}
              </>
            ) : null}
          </S.CardText>
        </S.CardContentWrapper>
      );
    }

    if (type === 'number') {
      return (
        <S.NumberInput
          id="purchase-limit"
          value={value <= 0 ? undefined : value}
          onKeyPress={validateNumberOnly}
          inputmode="numeric"
          onValueChange={onChange}
          isNumericString={true}
          decimalSeparator={false}
          placeholder={placeholderInput || 'Input title'}
          error={hasError}
          isAllowed={withValueLimit}
        />
      );
    }

    if (type === 'select_group_tag') {
      return (
        <CustomSelect
          onChange={onChange}
          name={name}
          value={value}
          options={options}
          placeholderInput={placeholderInput}
          emptyMessage={emptyMessage}
          emptySearchMessage={emptySearchMessage}
          disabled={disabled}
        />
      );
    }

    if (type === 'checkbox') {
      return (
        <CustomCheckbox
          options={options}
          value={value}
          disabled={disabled}
          emptyMessage={emptyMessage}
          checkedTitleDefault={checkedTitleDefault}
          onChange={onChange}
        />
      );
    }

    if (type === 'select_onboarding_flow') {
      return (
        <div className="custom-package-onboarding">
          <OnboardingFlowSelect
            value={value}
            onChange={onChange}
            cloudfrontList={cloudfrontList}
            toggleSecondModal={toggleSecondModal}
            toggleConfirmModal={toggleConfirmModal}
            mode={ONBOARDING_FLOW_SELECT_MODE.PACKAGE}
            disabled={disabled}
            isDraft={isDraft}
          />
        </div>
      );
    }

    // MP
    if (type === 'purchase_option') {
      return <DropdownPurchaseOption value={value} options={options} disabled={disabled} onChange={onChange} />;
    }

    if (type === 'purchase_option_waitlist') {
      return (
        <RadioPurchaseOption
          value={value}
          options={options}
          disabled={disabled}
          onChange={onChange}
          date={waitlistDate}
          onChangeDate={onChangeWaitlistDate}
          time={waitlistTime}
          onChangeTime={onChangeWaitlistTime}
          purchaseLimit={purchaseLimit}
          errorMessage={waitlistTimeErrorMessage}
          onOpenPurchaseLimitWarningPopup={onOpenPurchaseLimitWarningPopup}
          timezone={timezone}
          onChangeTimezone={onChangeTimezone}
          dateFormat={dateFormat}
        />
      );
    }

    if (type === 'purchase_option_contact') {
      return (
        <SurveyPurchaseOption
          value={value}
          options={options}
          disabled={disabled}
          onChange={onChange}
          survey={survey}
          onOpenSurveyModal={onOpenSurveyModal}
          onChangeSurvey={onChangeSurvey}
          errorMessage={surveyErrorMessage}
        />
      );
    }

    if (type === 'inbox') {
      return <InboxOption value={value} disabled={disabled} onChange={onChange} />;
    }

    return (
      <FormItemInput
        type={type}
        placeholder={placeholderInput || 'Input title'}
        inputProps={{ disabled, maxLength: 90 }}
        value={value || ''}
        onChange={onChange}
        required
        isSubmitted={!disabled}
        isHideErrorMessage
      />
    );
  };

  return (
    <S.Wrapper>
      <div>
        <S.CardName>
          {name}
          {type === 'select_group_tag' && (
            <S.LearnMore
              href="https://help.everfit.io/en/articles/3366822-manage-your-list-of-groups"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.LearnMoreIcon src={`${CDN_URL}/images/learn_more_icon.svg`} alt="learn more group tag" />
              Learn more
            </S.LearnMore>
          )}
        </S.CardName>
        <S.CardDescription>{description}</S.CardDescription>
      </div>
      <S.InputWrapper disabled={disabled}>
        {renderContent()}
        {hasError && (
          <S.ErrorMessageWrapper>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </S.ErrorMessageWrapper>
        )}
      </S.InputWrapper>
    </S.Wrapper>
  );
}

export default Card;
