import React from 'react';
import { Grid } from 'semantic-ui-react';

import './styles.scss';
import TrainingOverView from 'components/TrainingOverview';
import NoteOverview from 'components/NoteOverview';
import UpdateOverview from 'components/UpdateOverview';
import ClientProfileOverview from 'components/ClientProfileOverview';

export default class ClientOverview extends React.Component {
  componentDidMount() {
    const { updateInitialProgressPhotoFilters, timezone } = this.props;
    updateInitialProgressPhotoFilters && updateInitialProgressPhotoFilters(timezone);
  }

  render() {
    const { selectedClient } = this.props;

    if (!selectedClient) {
      return null;
    }

    return (
      <div className="client-overview-container">
        <Grid columns={16} className="client-overview__grid">
          <Grid.Row columns={16}>
            <Grid.Column width="8">
              <TrainingOverView />
            </Grid.Column>
            <Grid.Column width="4">
              <NoteOverview />
            </Grid.Column>
            <Grid.Column width="4">
              <ClientProfileOverview />
              <UpdateOverview selectedClient={selectedClient} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
