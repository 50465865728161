import React from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as S from './style';
import SettingMobileApp from './SettingMobileApp';
import SettingEmailBanner from './SettingEmailBanner';
import { isTeamAdmin } from 'utils/commonFunction';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check_mark.svg';
import { sendAdvancedCustomizationRequest } from 'utils/hubspot';
import { ONBOARDING_STEPS } from 'constants/commonData';

class Standard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { user, onboarding, updateStepOnboarding, maximizeCheckList } = this.props;

    if (!user || !isTeamAdmin(user)) {
      this.props.push('/home/client');
    } else {
      this.props.getCustomBrandingData();
    }
    const onboardingCustomBrandingStep = _.find(
      onboarding.checkList,
      o => o.type === ONBOARDING_STEPS.ADD_CUSTOM_BRANDING,
    );
    if (onboardingCustomBrandingStep && !onboardingCustomBrandingStep.state) {
      updateStepOnboarding(ONBOARDING_STEPS.ADD_CUSTOM_BRANDING).then(() => {
        maximizeCheckList();
      });
    }
  }

  handleClick = () => {
    this.props.onRedirectToTab(1); // index 1 is the premium tab
  };

  componentWillUnmount() {
    this.props.revokeLocalURL();
  }

  render() {
    const { onCheckSaving, discard, getCustomBrandingData, onChangeDiscard } = this.props;

    return (
      <>
        <S.TopBannerContainer>
          {!this.props.premiumCustomBrandPermission && (
            <S.Banner sideBarVisible={this.props.sideBarVisible}>
              <S.BannerContent>
                <div className="title">Advanced Customization</div>
                <div className="features">
                  <div className="item" key="app-icon">
                    <CheckMarkIcon />
                    <span>App Icon</span>
                  </div>
                  <div className="item" key="custom-theme">
                    <CheckMarkIcon />
                    <span>Custom Themes</span>
                  </div>
                  <div className="addMore">And more...</div>
                </div>
              </S.BannerContent>
              <S.JoinWaitlistButton onClick={this.handleClick}>Learn more</S.JoinWaitlistButton>
            </S.Banner>
          )}
        </S.TopBannerContainer>
        <SettingMobileApp
          premiumCustomBrandPermission={this.props.premiumCustomBrandPermission}
          onRedirectToTab={this.props.onRedirectToTab}
          onCheckSaving={onCheckSaving}
          discard={discard}
          getCustomBrandingData={getCustomBrandingData}
          onChangeDiscard={onChangeDiscard}
        />
        <SettingEmailBanner />
      </>
    );
  }
}

export default Standard;
