// Libs
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

// Actions
import { axiosInstance } from 'configs/request';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import { updateOnboardingFlowInfo } from 'redux/onboarding-flow/actions';

// Shared
import TitleTooltip from 'shared/TitleTooltip';
import CreateModal from 'components/OnboardingFlowLibrary/components/CreateModal';
import ConfirmModal from 'components/OnboardingFlowDetail/components/OnboardingFlowSettings/ConfirmModal';

// Constants
import { ONBOARDING_FLOW_STATUS } from 'components/OnboardingFlowDetail/constants';
import { TEAM_SHARE_PRIVATE } from 'constants/commonData';
import { TYPE_OF_MODAL } from 'components/OnboardingFlowLibrary/constants';
import { isAdmin, isOnwer } from 'utils/validations';

// Styles
import * as S from './style';

const OnboardingFlowHeaderDetail = ({
  user,
  name,
  push,
  share,
  author,
  status,
  toggleModal,
  isPermission,
  isLoadingAction,
  onboardingFlowId,
  toggleConfirmModal,
  isLoadingOnboarding,
  updateOnboardingFlowInfo,
}) => {
  const { _id: userId } = user || {};

  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [hasAtLeastValidField, setHasAtLeastValidField] = useState(false);

  const isPublished = useMemo(() => status === ONBOARDING_FLOW_STATUS.PUBLISH, [status]);

  const isAccessEdit = isOnwer(user) || isAdmin(user) || userId === author;

  useEffect(() => {
    if (onboardingFlowId) {
      getSettingsOnboarding(onboardingFlowId);
    }
  }, [onboardingFlowId]);

  const getSettingsOnboarding = async id => {
    try {
      setIsFetchLoading(true);

      const response = await axiosInstance.get(`/api/onboarding-flow-settings/${id}`);
      const { data } = get(response, 'data', {});

      if (!isEmpty(data)) {
        const { invite_codes, packages, trainer_default_settings } = data;

        const hasInviteCodes = invite_codes.length > 0;
        const hasPackages = packages.length > 0;
        const hasTrainerDefaultSettings = trainer_default_settings.length > 0;

        setHasAtLeastValidField(hasInviteCodes || hasPackages || hasTrainerDefaultSettings);
      }
    } catch (error) {
      // TODO: Handle error
    } finally {
      setIsFetchLoading(false);
    }
  };

  const handleSubmit = async data => {
    if (isLoadingAction || isFetchLoading) return;

    const params = {
      owner: data.author,
      name: data.name,
      share: data.share,
    };

    const shouldRedirect = params.owner !== (user || {})._id && params.share === TEAM_SHARE_PRIVATE;

    typeof updateOnboardingFlowInfo === 'function' &&
      updateOnboardingFlowInfo(
        onboardingFlowId,
        params,
        () => {
          toast('Onboarding flow has been saved');
          toggleModal(false);

          // Redirect when changing sharing to others and set private
          if (shouldRedirect) {
            typeof push === 'function' && push(`/home/onboarding-flow`);
          }
        },
        shouldRedirect,
      );
  };

  const handleOpenEditModal = () => {
    toggleModal(
      true,
      <CreateModal
        onClose={() => toggleModal(false)}
        onSubmit={params => {
          const isChangeToPrivate =
            share !== TEAM_SHARE_PRIVATE && params.share === TEAM_SHARE_PRIVATE && share !== params.share;

          if (hasAtLeastValidField && isChangeToPrivate) {
            handleOpenConfirmModal(params);
          } else {
            handleSubmit(params);
          }
        }}
        currentName={name}
        currentParamShare={{ author, share }}
        type={TYPE_OF_MODAL.UPDATE}
      />,
    );
  };

  const handleOpenConfirmModal = params => {
    typeof toggleConfirmModal === 'function' &&
      toggleConfirmModal(
        true,
        <ConfirmModal
          title="Set Onboarding Flow to Private"
          content="This will revoke access for all coaches in the workspace, and any coaches currently added to the onboarding flow will be removed. Would you like to continue?"
          headerIcon="/images/alert_recycle.svg"
          confirmBtnTitle="Yes"
          className="multiple-popup-yellow"
          onConfirm={() => handleSubmit(params)}
          onClose={() => toggleConfirmModal(false)}
        />,
      );
  };

  const renderLoadingSkeleton = () => {
    return <S.LoadingSkeleton />;
  };

  return (
    <S.Wrapper>
      {isLoadingOnboarding && renderLoadingSkeleton()}
      {!isLoadingOnboarding && (
        <S.TitleWrapper isPermission={isPermission}>
          <S.HeaderButton>
            <TitleTooltip
              tooltipId="onboarding-flow-name"
              content={name}
              tag={S.Title}
              tooltipClassName="custom-onboarding-tooltip-name-tooltip"
              place="bottom"
              className="custom-onboarding-tooltip-name"
            />
          </S.HeaderButton>
          {isPermission && (
            <S.StatusLabel isPublished={isPublished}>{isPublished ? 'Published' : 'Draft'}</S.StatusLabel>
          )}
          {isPermission && isAccessEdit && <S.EditButton onClick={handleOpenEditModal}>Edit Info</S.EditButton>}
        </S.TitleWrapper>
      )}
    </S.Wrapper>
  );
};

const mapStateToProps = state => {
  const {
    user,
    rootReducer: { onboardingFlow },
  } = state;

  return {
    user,
    name: get(onboardingFlow, 'workingData.name', ''),
    share: get(onboardingFlow, 'workingData.share', TEAM_SHARE_PRIVATE),
    author: get(onboardingFlow, 'workingData.author', (user || {})._id),
    isLoadingOnboarding: get(onboardingFlow, 'isLoadingOnboarding'),
    isLoadingAction: get(onboardingFlow, 'isLoadingAction'),
    onboardingFlowId: get(onboardingFlow, 'workingData._id', ''),
  };
};

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
  updateOnboardingFlowInfo: bindActionCreators(updateOnboardingFlowInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingFlowHeaderDetail);
