import styled, { css } from 'styled-components';
import { CDN_URL } from 'constants/commonData';

export const Input = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  max-height: 90px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
  outline: none !important;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  :hover {
    ::-webkit-scrollbar {
      opacity: 1;
    }
  }

  :empty::before {
    content: ${props => `'${props.placeholder}'`};
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    opacity: 0.2;
    cursor: text;
  }
`;

export const CameraButton = styled.button`
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  background: none;
  margin-left: 5px;
  height: 27px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  box-shadow: -2px 0px 16px 0px #6c6c6c1a;
  box-shadow: 0px 1px 0px 0px #dde2ed inset;
`;

export const InputContainer = styled.div`
  flex: 1 1;
  border-radius: 8px;
  background: #f6f6f6;
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-left: 10px;
  min-height: 44px;
  border: 1px solid transparent;

  &:focus-within {
    border: 1px solid #5158cf;
    background: white;
  }

  .notranslate.public-DraftEditor-content {
    outline: 1px solid transparent !important;
  }
`;

export const FileInputContainer = styled.div`
  position: relative;
  height: 27px;

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 1;
    text-indent: -9999px;
    cursor: pointer;
  }

  &.disabled {
    input {
      cursor: not-allowed;
    }
  }

  &:hover {
    svg path {
      stroke: #7470ef;
    }
  }
`;

export const MediaItem = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  position: relative;

  img,
  video {
    &.media {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .remove-icon {
    position: absolute;
    right: -7px;
    top: -7px;
    cursor: pointer;
    width: 14px;
    height: 14px;
    display: none;
  }

  :last-child {
    margin-right: 0;
  }

  :hover {
    .remove-icon {
      display: block;
    }
  }
`;

export const MediaContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  flex: 1 1;
`;

export const SubmitButton = styled.div`
  width: 27px;
  height: 27px;
  background: url(${CDN_URL}/images/send_message.svg) no-repeat center center;
  background-size: auto;
  cursor: pointer;
  border: none;

  &.disabled {
    cursor: not-allowed;
  }

  #chat-submit-button-tooltip {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    padding: 12px 15px 10px;
    width: 208px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ReplyWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`;

export const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 8px;
  padding: 8px;
  height: 56px;
  background: #f5f5f6;
`;

export const ReplyBy = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    flex-shrink: 0;
  }
`;

export const ReplyMessageText = styled.p`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding-right: 42px;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  box-orient: vertical;
  overflow: hidden;

  ${props =>
    props.isBlur &&
    css`
      color: rgba(20, 22, 50, 0.5);
    `}
`;

export const TextBold = styled.span`
  font-weight: 600;
`;

export const CloseReply = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 10px;
    height: 10px;
  }

  &:hover {
    cursor: pointer;
    background: #000;

    svg path {
      fill: #fff;
    }
  }
`;
