import React, { useEffect } from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { Button, Image } from 'semantic-ui-react';
import { copyToClipboard } from 'utils/commonFunction';
import { CDN_URL } from 'constants/commonData';

import * as S from './style';

const ApplyCouponPopup = ({ search, onClose }) => {
  const params = new URLSearchParams(search);
  const coupon = params.get('coupon');

  useEffect(() => {
    if (isSafari) {
      window.addEventListener('mousedown', () => {
        copyToClipboard(coupon);
      });
    } else {
      copyToClipboard(coupon);
    }
    return () => {
      isSafari && window.removeEventListener('mousedown', () => {});
    };
  }, []);

  const handleChoosePlan = () => {
    window.location = `/pricing-plan${search}`;
    localStorage.removeItem('hasCoupon');
  };

  return (
    <S.Container showDownloadBanner={isMobile && localStorage.getItem('mobileReminder') === 'show'}>
      <Button className="close-button" onClick={onClose}>
        <Image src={`${CDN_URL}/images/close_circle.svg`} />
      </Button>
      <S.Wrapper>
        <span>
          Your coupon has been copied to clipboard: <b>{coupon}</b>
        </span>
        <S.SubmitButton onClick={handleChoosePlan}>Choose your plan</S.SubmitButton>
      </S.Wrapper>
    </S.Container>
  );
};

export default ApplyCouponPopup;
