import React from 'react';
import { Modal } from 'semantic-ui-react';

import * as ModalLayout from 'shared/Styles/ModalLayout';
import { ModalWrapper, ModalTitle, ModalDesc, ModalButton } from './style';
import { ReactComponent as ArchivedIcon } from 'assets/icons/archived_icon.svg';

const ArchivedModal = ({ isOpen, onClose }) => {
  return (
    <ModalWrapper open={isOpen} closeOnDimmerClick={false}>
      <Modal.Content>
        <ModalTitle>
          <ArchivedIcon />
          Conversation has been archived
        </ModalTitle>
        <ModalLayout.Content className="confirm-content">
          <ModalDesc>
            Conversation has been hidden from your Inbox, but you can always use search to find and unarchive a
            conversation.
          </ModalDesc>
        </ModalLayout.Content>
        <ModalLayout.Actions className="confirm-footer">
          <ModalButton purple onClick={onClose}>
            OK
          </ModalButton>
        </ModalLayout.Actions>
      </Modal.Content>
    </ModalWrapper>
  );
};

export default ArchivedModal;
