import React from 'react';
import { toggleModal } from 'actions/modal';
import { Button } from 'shared/FormControl';
import { AUTOFLOW_CLIENT_STATUS } from 'constants/commonData';
import { ReactComponent as SummaryIcon } from 'assets/icons/summary-change-date.svg';
import * as S from './style';
import { connect } from 'react-redux';

function Summary({ clients = [], startDate, toggleModal }) {
  const listClientActiveAndComplete = clients.filter(
    client => client.status === AUTOFLOW_CLIENT_STATUS.ACTIVE || client.status === AUTOFLOW_CLIENT_STATUS.COMPLETE,
  );
  return (
    <S.Container>
      <S.Header>
        <SummaryIcon />
        <span>Summary</span>
      </S.Header>
      <S.Body>
        <div>
          {clients.length > 1 ? `${clients.length} clients` : '1 client'} were updated to start on day{' '}
          {startDate.started_on} of the Autoflow on {startDate.started_date}.
        </div>
        <S.Divide />
        <div>
          <strong>{listClientActiveAndComplete.length} active</strong> or <strong>completed</strong>{' '}
          {listClientActiveAndComplete.length > 1 ? `clients` : 'client'} will not have their start date changed.
        </div>
      </S.Body>
      <S.Footer>
        <Button green onClick={() => toggleModal(false)}>
          OK
        </Button>
      </S.Footer>
    </S.Container>
  );
}

export default connect(null, { toggleModal })(Summary);
