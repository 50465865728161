import { connect } from 'react-redux';
import ExerciseFilter from 'shared/ExerciseFilterPopup';

const mapState = state => {
  const {
    rootReducer: {
      exercise: { body_parts, categories, equipments, modalities, muscleGroup, movementPattern },
      exerciseTags,
    },
    user,
  } = state;

  return {
    bodyParts: body_parts,
    categories,
    tags: exerciseTags,
    user,
    equipments,
    modalities,
    muscleGroup,
    movementPattern,
  };
};

export default connect(mapState, null)(ExerciseFilter);
