import styled, { css } from 'styled-components';

const InputStyles = `
  font-size: 13px;
  font-weight: 400;
  line-height: 17.7px;
  min-width: 316px;
  height: 38px;
  outline: none;
  border: 1px solid #d4d7d9;
  border-radius: 3px;
  padding-left: 30px;

  &:active,
  &:focus,
  &:hover {
    border-color: #5158cf;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  line-height: 150%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 56px;

  > div:first-child {
    position: relative;
    ::after {
      content: '';
      border: 1px solid #eeeeee;
      position: absolute;
      width: 100%;
      bottom: -32px;
    }
  }
`;

export const TitleWrapper = styled.div`
  max-width: 510px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TotalTask = styled.div`
  margin-top: 9px;
  display: flex;
  justify-content: space-between;

  color: rgba(32, 35, 83, 0.7);
  font-size: 14px;
  font-weight: 600;
`;

export const BoldText = styled.b`
  color: #202353;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
`;

export const Description = styled.div`
  color: #202353;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

export const ValueInputBudget = styled.div.attrs(props => ({
  limitBudget: props.limitBudget,
}))`
  position: relative;
  top: -1px;

  .threshold-value-input {
    ${InputStyles}
  }

  .input-error {
    border-color: #ea314a !important;
  }

  &:after,
  &:before {
    color: rgba(32, 35, 83, 0.7);
    position: absolute;
    top: 11px;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.7px;
  }

  &:after {
    content: ${props => (props.limitBudget ? `' / ${props.limitBudget}'` : '')};
    left: 57px;
  }

  &:before {
    content: '$';
    left: 15px;
  }
`;

export const ValueInputThreshold = styled.div.attrs(props => ({
  limitThreshold: props.limitThreshold,
}))`
  position: relative;
  top: -1px;
  .threshold-value-input {
    ${InputStyles}
  }

  .input-error {
    border-color: #ea314a !important;
  }

  &:after,
  &:before {
    color: rgba(32, 35, 83, 0.7);
    position: absolute;
    top: 11px;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.7px;
  }

  &:after {
    content: ${props => (props.limitThreshold ? `' / ${props.limitThreshold}'` : '')};
    left: 57px;
  }

  &:before {
    content: '$';
    left: 15px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const SaveButton = styled.div`
  width: 62px;
  height: 38px;
  padding: 10px 16px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: ${props => (props.disabled ? '#c3c5cB' : ' #6456c4')};
  text-align: center;
  background: rgba(21, 22, 25, 0.04);
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${props => {
    !props.disabled &&
      css`
        :hover {
          background: #f0f1ff;
          color: #6456c4;
          cursor: pointer;
        }
      `;
  }}
`;

export const ErrorMessage = styled.div`
  color: #ea314a;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
`;

export const Estimation = styled.div`
  color: #aaacae;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
`;

export const EstimationTask = styled.span`
  color: #202353;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const ThresholdWrapper = styled.div`
  .single-select-container__threshold {
    .single-select__control {
      background: #fff;
      z-index: unset !important;
      min-width: 316px;
      border-color: #d4d7d9;
      cursor: pointer;

      &--is-focused {
        border-color: #d4d7d9;
        box-shadow: unset;
      }

      :hover,
      &--menu-is-open {
        border-color: #5158cf;
      }

      .single-select__placeholder {
        color: #969fb5;
        font-size: 13px;
        font-weight: 400;
      }
      .single-select__value-container {
        padding: 2px 13px !important;
      }
      .single-select__input input {
        font-weight: 400;
      }
      .single-select__single-value {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #202353;
      }
    }
    .single-select__menu {
      border-radius: 8px;
      box-shadow: 0px 12px 16px -4px #10182814;
      border: 1px solid #f2f4f7;
      padding-right: 4px;
      margin-top: 5px;

      .single-select__menu-list {
        max-height: 198px;
        min-height: 98px;
        padding: 4px 4px 4px 0;

        ::-webkit-scrollbar {
          width: 4px !important;
        }
        ::-webkit-scrollbar-thumb {
          background: #eaecf0 !important;
        }

        .single-select__option {
          font-size: 13px;
          line-height: 17.7px;
          color: #000000;
          padding: 10px 14px;
          font-weight: 400;
          cursor: pointer;

          &.single-select__option--is-selected {
            font-weight: 600;
            color: #5158cf;
          }

          &.single-select__option--is-selected,
          &.single-select__option--is-focused {
            background: #f7f7ff !important;
          }
        }
        .single-select__menu-notice--no-options {
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }

    .single-select__menu-portal {
      z-index: 2001;
    }

    > div:last-child {
      z-index: 3;
    }

    .single-select__indicators {
      padding-right: 9px;
      padding-top: 2px;
    }

    .single-select__clear-indicator {
      svg {
        width: 12px;
        height: 12px;

        path {
          fill: #7b7e91;
        }
      }

      &:hover {
        svg path {
          fill: #5158cf;
        }
      }
    }
  }
`;

export const FeatureLearnMore = styled.a`
  position: relative;
  padding-left: 11px;
  top: 1px;
  display: inline-flex;

  :hover {
    color: #6456c4 !important;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    path {
      fill: #6456c4;
    }
  }
`;

export const FeatureLinkTitle = styled.span`
  color: #6456c4;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.7px;
  padding-left: 17px;
  position: relative;
  bottom: 2px;
  left: 3px;
`;
