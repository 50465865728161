import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getComments,
  addCommentToPost,
  likePost,
  updatePostsAfterViewDetail,
  highFiveFoodJournal,
} from 'redux/foodJournal/actions';
import { changeCalendarStartDate } from 'actions/calendar';
import Component from './component';

const mapState = state => {
  const {
    user,
    cloudfrontList,
    rootReducer: {
      foodJournal: { isHighFive, isLoadingHighFive, highFives },
    },
  } = state;
  return { userId: user._id, isHighFive, isLoadingHighFive, highFives, cloudfrontList };
};

const mapDispatchToProps = dispatch => ({
  getComments: bindActionCreators(getComments, dispatch),
  addCommentToPost: bindActionCreators(addCommentToPost, dispatch),
  changeCalendarStartDate: bindActionCreators(changeCalendarStartDate, dispatch),
  likePost: bindActionCreators(likePost, dispatch),
  updatePostsAfterViewDetail: bindActionCreators(updatePostsAfterViewDetail, dispatch),
  highFiveFoodJournal: bindActionCreators(highFiveFoodJournal, dispatch),
});

export default connect(mapState, mapDispatchToProps)(Component);
