// libs
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';

// actions
import { addAssignment } from 'redux/assignment/assignment.actionCreators';
import { onSubmitted } from 'actions/submitStatus';
import { assignWorkout, saveAssignmentToLibrary } from 'actions/workout/save';
import { toggleModal } from 'actions/modal';
import {
  getAssignmentDetail,
  removeAssignmentFromDate,
  updateAssignment,
  WORKOUT_TYPE,
} from 'actions/workout/getWorkouts';
import { fetchWorkoutsInRange } from 'actions/workout/getWorkouts';

// components
import ChooseWorkoutModal from 'components/ChooseWorkoutModal';
import WorkoutDetailModal from 'components/WorkoutDetailModal';
import CreateWorkoutTemplateAIDemo from 'components/CreateWorkoutTemplateAIDemo';
import LogWorkoutModal from 'components/LogWorkoutModal';

// utils
import { DEFAULT_SUBMIT_STATUS, TAGS_TYPE, WORKOUT_AI_BUILDER_PAGES } from 'constants/commonData';
import { axiosInstance } from 'configs/request';
import { WORKOUT_BUILDER_TYPES } from 'constants/commonData';
import { getMostRecentTagsList } from 'redux/tags/actions';
import { omitEmptyRequestParams } from 'utils/commonFunction';
import { prepareDataToTrackWorkout } from 'helpers/workout';
import { Workout } from 'types/model';

const DEFAULT_PARAMS = { page: 1, per_page: 10 };

class AssignWorkoutModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: 0,
      workouts: [],
      isCreateNew: false,
    };
    this.search = '';
    this.tags = [];
    this.searchDebounce = _.debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.searchWorkout();
    this.props.getMostRecentTagsList({ type: TAGS_TYPE.WORKOUT });
  }

  searchWorkout = searchParams => {
    const q = _.get(searchParams, 'q');
    const tags = _.get(searchParams, 'tags', []);
    const params = omitEmptyRequestParams({
      ...DEFAULT_PARAMS,
      q,
      search_tags: _.join(
        tags.map(tag => tag._id),
        ',',
      ),
    });
    this.setState({ loading: true, searching: true });
    axiosInstance
      .get('/api/workout/v2/list', { params })
      .then(response => {
        const { data, total } = response.data;
        this.setState({
          workouts: data,
          total,
          page: params.page,
          hasMore: data.length < response.data.total,
          loading: false,
          searching: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false, searching: false });
      });
  };

  onSearch = params => {
    this.search = params.q;
    this.tags = params.tags;
    this.searchWorkout(params);
  };

  loadMore = () => {
    const { hasMore, page, isLoading } = this.state;
    const params = omitEmptyRequestParams({
      page: page + 1,
      per_page: DEFAULT_PARAMS.per_page,
      q: this.search,
      search_tags: _.join(
        this.tags.map(tag => tag._id),
        ',',
      ),
    });

    if (hasMore && !isLoading) {
      this.setState({ loading: true });
      axiosInstance
        .get('/api/workout/v2/list', { params })
        .then(response => {
          const { data } = response.data;
          const newWorkouts = _.unionBy(this.state.workouts, data, '_id');
          this.setState({
            workouts: newWorkouts,
            total: response.data.total,
            page: params.page,
            hasMore: newWorkouts.length < response.data.total,
            loading: false,
          });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onSelect = workout => {
    const { client, date, user } = this.props;

    const trainerTimeZone = _.get(user, 'timezone', '');

    const currentDate = DateTime.local();

    const newDate = date.set({
      hour: currentDate.get('hour'),
      minute: currentDate.get('minute'),
      second: currentDate.get('second'),
    });

    const params = {
      timezone: currentDate.zoneName || moment.tz.guess() || trainerTimeZone,
      date: newDate.toString(),
      day: date.toFormat('MM-dd-yyyy'),
      workout: workout._id,
      client: client._id,
    };

    this.props.assignWorkout(params);
  };

  onClose = () => {
    this.props.onSubmitted('WORKOUT_DETAIL', true);
    this.props.toggleModal(false);
  };

  onCreateNew = () => {
    this.setState({ isCreateNew: true });
  };

  handleCreateNewWorkout = async workout => {
    const { date, client, user, addAssignment } = this.props;
    const otherData = {
      day: date.toFormat('MM-dd-yyyy'),
      author: (user || {})._id,
      client: (client || {})._id,
    };

    try {
      const response = await addAssignment(workout, otherData);
      return response.data.data;
    } catch (err) {
      return Promise.resolve(null);
    }
  };

  handleUpdateAssignment = async workout => {
    const { client, updateAssignment } = this.props;
    const { _id, title, description, author, share, sections, background } = workout || {};
    const data = {
      assignment: _id,
      title: title,
      description: description,
      author: author ? author._id : null,
      share: share,
      sections_target: sections,
      client: client._id,
      background: background,
    };
    try {
      const response = await updateAssignment(data);
      return response.data.data;
    } catch (error) {
      return Promise.resolve(null);
    }
  };

  handleDeleteAssignment = (__, workout) => {
    const { client, removeAssignmentFromDate } = this.props;
    const params = { assignment: workout, client: (client || {})._id };
    removeAssignmentFromDate(params);
  };

  handleSaveToLibrary = workout => {
    const { saveAssignmentToLibrary } = this.props;
    const { _id } = workout || {};
    saveAssignmentToLibrary({ assignment: _id });
  };

  handleLogWorkout = workout => {
    const { fields, client, toggleModal, push } = this.props;
    const trackingWorkout = prepareDataToTrackWorkout(workout, fields);
    push(`/home/client/${client._id}/calendar/${(trackingWorkout || {})._id}/tracking`);
    toggleModal(
      true,
      <LogWorkoutModal
        tracking
        date={DateTime.fromISO(workout.date)}
        day={workout.day}
        originWorkout={new Workout(trackingWorkout)}
        workingWorkout={new Workout(trackingWorkout)}
      />,
    );
  };

  handleEverfitAIClick = () => {
    const { toggleModal } = this.props;
    if (toggleModal) {
      toggleModal(
        true,
        <CreateWorkoutTemplateAIDemo
          isTrackable
          onAssignment={this.handleCreateNewWorkout}
          onUpdateAssignment={this.handleUpdateAssignment}
          onSaveToLibrary={this.handleSaveToLibrary}
          onAssignmentDelete={this.handleDeleteAssignment}
          onLogWorkout={this.handleLogWorkout}
          pdfType="assignment"
          type={WORKOUT_BUILDER_TYPES.ASSIGNMENT}
          onSavedWithoutClose={this.onSavedWithoutClose}
          workoutAIPage={WORKOUT_AI_BUILDER_PAGES.client_calendar}
        />,
      );
    }
  };

  onSavedWithoutClose = assignment => {
    const { client } = this.props;
    this.props.getAssignmentDetail(assignment._id, 'update', WORKOUT_TYPE.DETAIL);
    this.props.push(`/home/client/${client._id}/calendar/${assignment._id}/detail`);
  };

  render() {
    const { submitStatus, date, workoutTags, enableAIworkout } = this.props;
    const { isCreateNew, hasMore, loading, searching, total } = this.state;

    if (isCreateNew) {
      return (
        <WorkoutDetailModal
          hasTrackedWorkout
          key="create-new-assignment"
          onSave={this.handleCreateNewWorkout}
          onSavedWithoutClose={this.onSavedWithoutClose}
          type={WORKOUT_BUILDER_TYPES.ASSIGNMENT}
          fetchWorkoutsInRange={fetchWorkoutsInRange}
          date={date.toFormat('MM-dd-yyyy')}
          pdfType="assignment"
          isCreateNew={isCreateNew}
        />
      );
    }

    return (
      <ChooseWorkoutModal
        workoutTags={workoutTags}
        workouts={this.state.workouts}
        onClose={this.onClose}
        onSearch={params => {
          this.search = params.q;
          this.tags = params.tags;
          this.searchDebounce(params);
        }}
        loadMore={this.loadMore}
        isLoading={loading}
        searching={searching}
        hasMore={hasMore}
        total={total}
        onSelect={this.onSelect}
        onCreateNew={this.onCreateNew}
        title="Find a Workout"
        displayOwner
        displayLastUsed
        disableSelectButton={!submitStatus.status}
        onEverfitAIClick={enableAIworkout ? this.handleEverfitAIClick : undefined}
      />
    );
  }
}

const mapState = state => {
  const {
    user,
    rootReducer: {
      client,
      workoutTags,
      permission,
      exercise: { fields },
    },
    submitStatus,
  } = state;
  const enableAIAllWorkoutBuilder = process.env.REACT_APP_AI_ENABLE_ALL_WORKOUT_BUILDER || false;
  const enableAIworkout = enableAIAllWorkoutBuilder && _.get(permission, 'workout_builder_ai', false);

  return {
    client: client.workingClientDetail,
    submitStatus: submitStatus.WORKOUT_DETAIL || DEFAULT_SUBMIT_STATUS,
    user,
    workoutTags,
    enableAIworkout,
    fields,
  };
};

const mapDispatch = dispatch => {
  return {
    assignWorkout: bindActionCreators(assignWorkout, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    onSubmitted: bindActionCreators(onSubmitted, dispatch),
    addAssignment: bindActionCreators(addAssignment, dispatch),
    updateAssignment: bindActionCreators(updateAssignment, dispatch),
    removeAssignmentFromDate: bindActionCreators(removeAssignmentFromDate, dispatch),
    saveAssignmentToLibrary: bindActionCreators(saveAssignmentToLibrary, dispatch),
    getAssignmentDetail: bindActionCreators(getAssignmentDetail, dispatch),
    push: bindActionCreators(push, dispatch),
    getMostRecentTagsList: bindActionCreators(getMostRecentTagsList, dispatch),
  };
};

export default connect(mapState, mapDispatch)(AssignWorkoutModal);
