import React from 'react';
import classNames from 'classnames';

import { Wrapper } from './style';
import { ACCEPT_VIDEOS, ACCEPT_IMAGES, FILE_ERRORS, CONVERSION, CDN_URL } from 'constants/commonData';
import { isNumber } from 'utils/commonFunction';

const MAX_SIZE = 25;

export default function FileInput(props) {
  const { accept, icon, hoverIcon, text, type, maxSize } = props;
  const acceptedFiles = accept || (type === 'video' ? ACCEPT_VIDEOS : ACCEPT_IMAGES);
  const showedIcon = icon || (type === 'video' ? `${CDN_URL}/images/add_video.svg` : `${CDN_URL}/images/add_image.svg`);
  const showedText = text || (type === 'video' ? 'Add video' : 'Add Image');
  // maxSize: size as MB;
  const maxFileSize = (isNumber(maxSize) ? parseInt(maxSize) : MAX_SIZE) * CONVERSION.MB_TO_BYTE;

  const onChange = event => {
    const { files } = event.target;

    if (files.length) {
      const file = files[0];

      const fileExtension = file.name.split('.').pop();

      if (!acceptedFiles.includes(fileExtension.toLowerCase())) {
        event.target.value = '';
        return props.onError({ error: FILE_ERRORS.FILE_TYPE_INVALID });
      }

      if (file.size <= maxFileSize) {
        props.onChange({ file });
      } else {
        props.onError({
          error: type === 'video' ? FILE_ERRORS.FILE_SIZE_LIMIT_VIDEO : FILE_ERRORS.FILE_SIZE_LIMIT_IMAGE,
        });
      }
    }

    event.target.value = '';
  };

  return (
    <Wrapper className={classNames('add-media-input', type)}>
      <div className="image-container">
        <img src={showedIcon} alt="" className="icon" />
        <img src={hoverIcon || showedIcon} alt="" className="icon hover" />
      </div>
      <div className="text">{showedText}</div>
      <input type="file" accept={acceptedFiles} onChange={onChange} />
    </Wrapper>
  );
}
