import ConfirmModal from 'shared/ConfirmModal';
import styled from 'styled-components';

const textBase = `
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 11px;
  padding: 13px 0;
  background-color: #ebedf4;
  .custom-icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 4px;
  ${textBase}
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #202353;
  .content-bold {
    font-weight: 700;
  }
`;

export const ButtonRequest = styled.button`
  font-weight: 700;
  color: #5158cf;
  border: none;
  background: none;
  outline: none;
  padding: unset;
  margin: unset;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &::selection {
    color: #5158cf;
  }
  &:disabled {
    cursor: not-allowed;
    color: #61646d;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const CustomConfirmModal = styled(ConfirmModal)`
  &.ui.modal > :first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  &.ui.modal > :last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  &.ui.modal.confirm-popup-container {
    width: 467px !important;
  }
  &.verification-email-confirm-modal {
    .confirm-content-header {
      padding: 32px 32px 0;
      .confirm-header-image {
        width: 24px;
        height: 24px;
        margin-right: 7px;
      }
    }
    .confirm-content-body {
      padding: 17px 31px 32px;
      .confirm-content-label {
        line-height: 20px;
      }
    }
    .confirm-actions {
      .button {
        font-family: 'Open Sans';
        border-radius: 5px;
      }
      .confirm-no-button {
        min-width: unset;
        padding: 5px 29px;
        &:hover {
          background-color: #f5f7f9 !important;
        }
      }
      .confirm-yes-button {
        padding: 4px 30px;
        background-color: #f9b811;
        line-height: 20px;
        box-shadow: unset;
        &:hover {
          background-color: rgba(249, 184, 17, 0.9);
          box-shadow: unset;
        }
      }
    }
    .no-border {
      padding: 0 32px 32px 0 !important;
    }
  }
`;
