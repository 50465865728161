// Lib
import React, { useState } from 'react';

// Shared
import Accordion from './components/Accordion';
import AddMeal from './components/AddMeal';

import * as S from './style';

const LeftPanelDayDetail = props => {
  const { sideBarVisible } = props;
  const [isAdd, setIsAdd] = useState(false);

  return (
    <S.Wrapper sideBarVisible={sideBarVisible}>
      <S.Container>
        <Accordion {...props} isAdd={isAdd} setIsAdd={setIsAdd} />
        <AddMeal {...props} isAdd={isAdd} setIsAdd={setIsAdd} />
      </S.Container>
    </S.Wrapper>
  );
};

export default LeftPanelDayDetail;
