import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 31.5px;
  margin-top: 37px;

  ${({ isRearrangeMode }) =>
    isRearrangeMode &&
    css`
      gap: 36.5px;
      margin-top: 50px;
    `}
`;

export const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  ${({ isRearrangeMode }) =>
    isRearrangeMode &&
    css`
      gap: 18px;
    `}
`;

export const DayHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;

  .error-message {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #ea314a;
  }
`;

export const TitleOfWeek = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  color: #202353;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 43px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 10px;
  background-color: #f0f0f2;
  cursor: pointer;

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #202353;

  &:hover {
    color: #5158cf;
    background-color: #f0f1ff;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(240, 240, 242, 0.9);
    color: #202353;
  }
`;
