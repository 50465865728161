import styled from 'styled-components';

export const ExerciseItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .index {
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    color: #979797;
    margin-right: 4px;
  }

  .media-preview {
    flex: 0 0 30px;
    margin-right: 10px;
  }

  .info {
    background: #ffffff;
    border: 1px solid #dadfea;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 5px;
    position: relative;
    flex: 1 1;
    overflow: hidden;

    .title {
      font-weight: 600;
      font-size: 13px;
      line-height: 150%;
      color: #444444;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1;
      padding: 0px !important;
    }

    .exercise-item__sort {
      margin: 0 10px;
      flex: 0 0 15px;

      :hover {
        cursor: grab;

        rect {
          fill: #5158cf;
        }
      }
    }

    :hover {
      border-color: #5158cf;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  .remove {
    display: none;
  }

  :hover {
    .remove {
      display: block;
    }
  }
`;

export const ExerciseMenu = styled.div`
  border: solid 1px #dcdcde;
  background-color: #f7f8fc;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: overlay;
  padding: 14px 15px;
  margin-top: 5px;
  max-height: 220px;
`;
