// Libs
import styled from 'styled-components';

export const NoneTriggerWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 9px;
  padding: 11px 11px 10px 11px;

  background: linear-gradient(3.79deg, #ffeae1 0%, #fffcf2 68.87%), #ffffff;
  border: 1px solid #ff6433;
  border-radius: 4px;

  .title-banner {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }

  .description-banner {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }

  .link-read-more-banner {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: rgba(234, 145, 31, 1);

    &:hover {
      color: rgba(234, 145, 31, 0.9);
      text-decoration: unset !important;
    }
  }

  .upgrade-icon-wrapper {
    position: absolute;
    right: 11px;
    top: 11px;
  }
`;
