import styled, { css } from 'styled-components';

import { SelectFile } from 'shared/FormControl';
import { MenuTrigger } from 'shared/Icons';
import * as S from './MobilePreviewCollection/style';
import { CDN_URL } from 'constants/commonData';

export const Wrapper = styled.div`
  padding: 32px 0 10px;
  width: 100%;
  max-width: calc(100% - 70px);
  margin: 0 auto;
`;

export const BaseInfoWrapper = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid rgba(198, 198, 198, 0.38);
  padding-bottom: 21px;

  .base-info-container {
    display: flex;
    flex: auto;

    .info-container {
      flex: 1;
    }
    .workout-collection-type {
      .text-editable__input {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        text-transform: uppercase;
        color: rgba(32, 35, 83, 0.6);
      }
      .text-editable__container {
        padding: 4px;
        width: 450px;
      }
    }
    .workout-collection-title {
      .text-editable__input {
        font-weight: 600;
        font-size: 23px;
      }
      .text-editable__container {
        width: 100%;
        padding: 0 4px;
      }
      .text-editable__container--active {
        padding: 3px 4px;
      }
    }
    .collection-description {
      width: 550px;

      .text-editable__container {
        padding: 0 7px;
      }

      span {
        color: #8a8ba4 !important;
      }

      .text-editable__input {
        display: -webkit-box;
        position: relative;
        height: 39px;
        overflow: hidden;
        font-size: 13px;
        font-weight: normal;
        line-height: 19.5px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .text-editable__container--empty {
        .text-editable__input {
          height: unset;
        }
      }

      .text-editable__container--active {
        width: 550px;

        .text-editable__input {
          display: block;
          max-height: 97.5px;
          height: auto;
          overflow: auto !important;
          text-overflow: initial !important;
          -webkit-line-clamp: initial;
          -webkit-box-orient: inherit;
          ::before {
            padding-left: 0px !important;
            background: unset;
          }
        }
      }
    }
    .banner-container {
      width: 110px;
      height: 78px;
      margin-right: 20px;
    }
  }
  .status-container {
    flex: 0 0 137px;
    margin-left: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    .status-text {
      background-color: transparent;
      position: relative;
      color: black;
      transform: translate(0px, -9px);
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-right: 10px;
      &:before {
        width: 6px;
        height: 6px;
        top: 12px;
        left: 6px;
      }
    }
    .toggle-input {
      input {
        width: 36px;
      }
      label {
        min-width: 36px;
        min-height: 20px;
      }
      input ~ label::before {
        background-color: #c4c4c4;
      }
      input:checked ~ label::before,
      input:checked:focus ~ label::before {
        background-color: #36b24b;
      }
    }

    .status-dropdown__single-value--is-disabled {
      .custom-status-chip {
        background: transparent !important;
      }
    }
  }
  .ownership-container {
    margin-left: 15px;
    height: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const BannerWrapper = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f3f6f9;
  display: flex;
  border-radius: 4px;
  height: 100%;
  ${props =>
    props.isDragging &&
    css`
      outline: 1px dashed #5158cf;
    `}
  align-items: center;
  justify-content: center;
  position: relative;
  .hidden {
    visibility: hidden;
  }
  .show-on-hover {
    visibility: hidden;
  }
  :hover {
    .show-on-hover {
      visibility: visible;
    }
    .default-upload-icon {
      visibility: hidden;
    }
  }
`;

export const SelectBanner = styled(SelectFile)`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
`;

export const Icon = styled.div`
  width: 33px;
  height: 35px;
  background-image: url(${CDN_URL}/images/drop_image_transparent.svg);
  background-size: contain;
  z-index: 1;
`;

export const EditButton = styled.div`
  background-color: #00000030;
  height: 100%;
  width: 110px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 8px 15px;
  font-family: Open Sans;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #818da1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const StatusSelect = styled.div`
  background: #ffffff;
  border: 1px solid #d6dae4;
  box-sizing: border-box;
  border-radius: 4px;
  ${props => (props.open ? 'height: 72px;' : 'height: 36px;')}
  width: 100%;
  display: flex;
  flex-direction: column;
  div {
    flex: 1;
    padding: 7px 18px;
    user-select: none;
    cursor: pointer;
  }
  .option {
    border-top: 1px solid #eeeeee;
    :hover {
      background-color: #eeefff;
    }
    ${props => {
      return !props.open ? 'display: none;' : 'display: block';
    }};
  }
`;

export const CategoriesListWrapper = styled.div`
  display: block;
  height: calc(100% - 180px);
  margin-top: 20px;
  overflow-y: auto;

  .category-list {
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(89px, 1fr));
    .wrapper-category:last-child {
      margin-bottom: 20px;
    }
  }

  .category-item {
    cursor: pointer;
    padding: 10px;
    background: #fafbfc;
    border: 1px solid #ebedf4;
    border-radius: 8px;
    height: 89px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .category-left {
      display: flex;
      flex: 1;
    }

    .category-arrange {
      display: inherit;
      height: 100%;
      margin-right: 10px;
      align-items: center;
    }

    .category-right {
      position: relative;

      .category-status-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .__react_component_tooltip {
          text-transform: capitalize;
          padding: 4px 8px;
          border-radius: 4px;
        }

        .status-text {
          margin-right: 0px;
          top: 0px;
          background-color: transparent;
          position: relative;
          color: black;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          margin-right: 10px;
          &:before {
            width: 6px;
            height: 6px;
            top: 12px;
            left: 6px;
          }
        }
        input {
          width: 36px;
        }
        label {
          min-width: 36px;
          min-height: 20px;
        }
        input ~ label::before {
          background-color: #c4c4c4;
        }
        input:checked ~ label::before,
        input:checked:focus ~ label::before {
          background-color: #36b24b;
        }
      }

      .category-actions-dropdown {
        width: fit-content;

        &.open {
          .trigger-icon {
            background: #eaeef1;
          }
        }

        .evf-dropdown__menu {
          min-width: 141px;
          background: transparent;
          transform: translateY(-2px) translateX(27.5px);

          &.last_item {
            .dropdown-arrow {
              display: none;
            }
          }
        }

        .dropdown-area {
          padding: 6px 0;
          background: #2d2e2d;
          border-radius: 5px;
        }

        .dropdown-arrow {
          display: block;
          position: absolute;
          width: 7px;
          height: 7px;
          right: 36.5px;
          top: 2.5px;
          background: #2d2e2d;
          border-top: 1px solid #363636;
          border-left: 1px solid #363636;
          transform: rotateZ(45deg);
        }

        .evf-dropdown__option {
          padding: 8px 40px 8px 20px;

          svg {
            width: 12px;
            margin-right: 13px;

            path {
              stroke: #ffffff;
            }
          }
        }
      }
    }

    .category-background-wrapper {
      display: flex;
      width: 111px;
      min-width: 111px;
      height: 69px;
      margin-right: 12px;
      background: #f3f6f9;
      justify-content: center;
      align-items: center;

      .category-background {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      svg {
        width: 27px;

        path {
          fill: #d3d7da;
        }

        rect {
          stroke: #d3d7da;
        }
      }
    }
    .category-name {
      display: -webkit-box;
      color: #151619;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      margin-bottom: 10px;
      margin-right: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .category-no-of-workouts {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #9a9da6;
    }
  }
`;

export const RemoveIcon = styled.div`
  width: 12px;
  height: 12px;
  background: transparent url(${CDN_URL}/images/close_circle.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 2;
`;

export const TriggerIcon = styled(MenuTrigger)`
  background: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transform: rotate(90deg);

  .dot,
  .dot::before,
  .dot::after {
    background-color: #728096;
  }

  &:hover {
    background: #dadfea;

    .dot,
    .dot::before,
    .dot::after {
      background-color: #000000;
    }
  }
`;

export const CollectionsHeader = styled.div`
  position: relative;
  display: flex;
  margin: 32px 0;
  justify-content: flex-end;
  align-items: center;

  .buttonClassName {
    button {
      margin-right: 0;
      &:hover,
      &:focus,
      &:active {
        background: #5559ff !important;
      }
    }
  }
`;

export const CountCharacter = styled.span`
  display: none;
  position: absolute;
  top: 50%;
  right: 10px;
  color: #0000004d;
  font-size: 13px;
  transform: translateY(-50%);
`;

export const TextEditableArea = styled.div`
  position: relative;
  width: 450px;
  height: 37px;

  .text-editable__input {
    width: calc(100% - 51px);
    overflow-wrap: normal;
    overflow-y: hidden;
    overflow-x: visible;
    white-space: nowrap;
    text-overflow: initial;

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &:focus-within,
  &:focus,
  &:hover {
    background: #f1f1f3;
    border-radius: 5px;

    ${CountCharacter} {
      display: initial;
    }
  }
`;

export const ReadMore = styled.span`
  position: absolute;
  left: 6px;
  bottom: 1px;
  text-align: right;
  padding: 0 1px;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  color: #5c5bbd !important;
  cursor: pointer;
`;

export const DescriptionArea = styled.div`
  position: relative;
  width: 550px;
  padding-bottom: ${props => (props.hasReadMore ? '19.5px' : '0')};

  &:hover {
    background-color: #f1f1f3;
  }

  ${props =>
    props.isView &&
    css`
      &:hover {
        background-color: transparent;
      }
    `}
`;

export const DescriptionView = styled.div`
  position: relative;
  width: 550px;
  margin-left: 6px;

  &:hover {
    .view {
      background: #f1f1f3;
      border-radius: 5px;
    }
  }

  &.active {
    display: block;
    padding: 5px;

    .view {
      display: block !important;
      width: auto;
      height: 110px;
      border: 1px solid #ebedf4;
      padding: 5px 15px;
      text-overflow: initial !important;
      overflow: auto;
      border-radius: 5px;
      -webkit-line-clamp: initial !important;
      -webkit-box-orient: vertical !important;
    }
  }

  .view {
    display: block;
    width: 550px;
    white-space: pre-wrap;
    overflow: hidden;
    padding: 5px 5px 4px;
    transform: translateX(-5px);

    &.haveBreakLine {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .descriptionInput__cancelViewMode {
    width: 16px;
    height: 16px;
    background: url(${CDN_URL}/images/close_circle_new.svg) no-repeat;
    position: absolute;
    top: 0;
    right: 4px;
    background-position: center;
    border-radius: 50%;
    cursor: pointer;

    :hover {
      background: url(${CDN_URL}/images/close_circle_new_hover.svg) no-repeat;
    }
  }
`;

export const LeftCategory = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 32px;
`;

export const RightCategory = styled.div`
  display: flex;
  width: 24px;
  background: #f7f8f9;
  align-items: center;
  justify-content: center;
`;

export const CollapseWrapper = styled.div`
  position: absolute;
  width: 29px;
  height: 29px;
  border: 1px solid #e1e5ec;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(92, 91, 189, 0.1);
  top: 26px;
  left: calc(100% - 345px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 4px 0;
  background-color: #fff;
  cursor: pointer;

  i.icon {
    margin: 0;
    font-size: 10px;
  }
`;

export const CategoryBodyArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100vh - 257px);
  flex-direction: row;

  ${RightCategory} {
    ${props =>
      props.isPreview &&
      css`
        display: flex;
        width: 330px;
        overflow: hidden;
        overflow-y: auto;
      `}

    ${props =>
      props.isEmpty ||
      (!props.isPreview &&
        css`
          ${S.Wrapper} {
            display: none;
          }
        `)}
  }

  ${props =>
    props.isEmpty &&
    css`
      ${RightCategory} {
        display: none;
      }
    `}

  ${LeftCategory} {
    ${props =>
      props.isPreview &&
      css`
        width: calc(100% - 330px);
      `}

    ${props =>
      props.isEmpty &&
      css`
        padding-right: 0;
      `}
  }

  ${CollapseWrapper} {
    ${props =>
      !props.isPreview &&
      css`
        left: initial;
        right: 9px;
      `}

    ${props =>
      props.isEmpty &&
      css`
        display: none;
      `}
  }

  ${CategoriesListWrapper} {
    position: relative;
    width: 100%;
    height: calc(100vh - 367px);

    .category-list {
      padding: 0 8px 0 0 !important;

      .category-item {
        .default-background,
        .category-background {
          display: block;
        }

        .hover-background {
          display: none;
          height: 24px;
        }

        &:hover {
          border: 1px solid #7773ff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 0px 16px rgba(0, 0, 0, 0.08);

          .category-background-wrapper {
            background: #f7f8f9;
          }
        }
      }

      .wrapper-category {
        &:last-child {
          .category-item {
            margin-bottom: 0;
            align-items: center;
          }
        }

        .category-left {
          .category-info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .category-name {
              margin-bottom: 0;
            }
          }
        }

        .category-right {
          display: flex;
          align-items: center;
        }

        &.isDragging {
          .category-background,
          .default-background {
            display: none;
          }

          .hover-background {
            display: block;
          }
        }
      }
    }
  }

  ${CollectionsHeader} {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CategoryTotal = styled.div`
  color: #151619;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
`;

export const EmptyCategoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 350px);
`;
