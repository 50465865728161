import React, { useMemo } from 'react';
import { components } from 'react-select';
import get from 'lodash/get';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';

import { getUserShortName } from 'utils/commonFunction';
import { TEAM_ROLES } from 'constants/commonData';

function CustomOption(props) {
  const avatar = useMemo(() => get(props, 'data.avatar'), [props]);
  const color = useMemo(() => get(props, 'data.color'), [props]);
  const isYourself = get(props, 'data._id', null) === get(props, 'user._id', null);

  const role = useMemo(() => {
    if (get(props, 'data.inviter')) {
      return TEAM_ROLES[get(props, 'data.role')];
    } else {
      if (get(props, 'data.teams') && get(props, 'data.teams').length > 0) {
        return TEAM_ROLES[get(props, 'data.teams[0].role')];
      } else {
        return 'Owner';
      }
    }
  }, [props]);

  return (
    <components.Option {...props}>
      <div className="member-icon">
        <Avatar name={getUserShortName(props.data)} size={30} src={avatar} color={color} />
      </div>
      <div className="member-content">
        <span className="member-name">
          {props.label}
          {isYourself && <span className="you">&nbsp;(You)</span>}
        </span>
        <div className="member-role">{role}</div>
      </div>
    </components.Option>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomOption);
