// Lib
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';

// Actions
import { getReportMacrosDaily, getReportMacrosWeekly } from 'actions/marcos';
import { toggleConfirmModal, toggleModal } from 'actions/modal';
import {
  updateClientMealPlanNote,
  getClientMealPlanByMonth,
  removeRecipeTrainer,
  changeClientMealPlanViewMode,
  toggleRearrangeMode,
  updateClientMealPlanWeekNote,
  reArrangeMealClientRecipe,
} from 'redux/client-meal-plan/actions';

// Helpers
import { getDayTitle } from 'utils/commonFunction';

// Parts
import MarcoGoal from './MarcoGoal';
import ClientMealPlanNote from './Note';
import ChangeTimeButton from './ChangeTimeButton';
import ChangeModeButton from './ChangeModeButton';
import RearrangeButton from './RearrangeButton';
import MealCategory from '../MealCategory';
import WeeklyCategory from '../WeeklyCategory';

// Constants
import { ADD_ONS_PRICING_CODE } from 'components/PricingPlan/constants';
import { getTotalAveragesMacroNutrients, getWeekTitle } from 'components/ClientMealPlan/helper';
import { CLIENT_MEAL_PLAN_VIEW_MODE } from 'components/ClientMealPlan/constants';

// Assets
import { ReactComponent as GenerateAiIcon } from 'assets/icons/MealPlans/generate_ai.svg';
import { ReactComponent as UpcomingTabIcon } from 'assets/icons/MealPlans/upcoming_tab.svg';

// Styles
import * as S from './style';

const YMD_FORMAT = 'YYYY-MM-DD';

const RightPanel = props => {
  const {
    macro_nutrients,
    getReportMacrosDaily,
    selectedClient,
    selectedDay,
    toggleModal,
    toggleConfirmModal,
    mealPlanByDay = {},
    updateClientMealPlanNote,
    loading = false,
    push,
    workingClientDetail,
    getClientMealPlanByMonth,
    assignedMealPlanClientsByRange,
    removeRecipeTrainer,
    cloudfrontList,
    listDietary = [],
    onChangeDay,
    mealPlanScheduling,
    clientTimezone,
    viewMode,
    changeClientMealPlanViewMode,
    isRearrangeMode,
    toggleRearrangeMode,
    isLoadingWeek,
    weekMealClients,
    getReportMacrosWeekly,
    weekNote,
    updateClientMealPlanWeekNote,
    reArrangeMealClientRecipe,
  } = props;
  const { note = '' } = mealPlanByDay || {};

  const dayTitle = useMemo(() => getDayTitle(selectedDay, clientTimezone), [selectedDay]);
  const weekTitle = useMemo(() => getWeekTitle(selectedDay, clientTimezone), [selectedDay]);
  const weekNutrients = uniqBy(weekMealClients || [], 'day').flatMap(({ meal_group }) =>
    meal_group.flatMap(({ recipes }) => recipes.flatMap(({ recipe }) => (recipe || {}).macro_nutrients)),
  );
  const totalNutrients = getTotalAveragesMacroNutrients(weekNutrients);
  const weekMacroNutrientsAverages = Object.entries(totalNutrients).map(([type, { total }]) => {
    const weekMealsWithRecipes = uniqBy(weekMealClients || [], 'day').filter(({ meal_group }) =>
      meal_group.some(({ recipes }) => recipes.length > 0),
    );
    const daysWithRecipesCount = weekMealsWithRecipes.length;

    return {
      type,
      value: total / daysWithRecipesCount || 0,
    };
  });

  const disabledNextDay = useMemo(() => {
    if (mealPlanScheduling < 0) return false;

    const dayBlocked = moment().add(mealPlanScheduling, 'week').startOf('w').subtract(1, 'd');
    return dayBlocked.format(YMD_FORMAT) === moment(selectedDay).format(YMD_FORMAT);
  }, [mealPlanScheduling, selectedDay]);

  const handleChangeDay = offset => {
    if (disabledNextDay) return;

    const dateString = moment(selectedDay).format(YMD_FORMAT);
    const newDateString = moment(dateString).add(offset, 'days').format(YMD_FORMAT);
    onChangeDay(moment(newDateString));
  };

  const handleChangeWeek = offset => {
    if (disabledNextDay) return;

    const dateString = moment(selectedDay).format(YMD_FORMAT);
    const newDateString = moment(dateString)
      .add(7 * offset, 'day')
      .format(YMD_FORMAT);
    onChangeDay(moment(newDateString));
  };

  const handleRedirectToDate = date => {
    const dateRedirect = moment(date).format(YMD_FORMAT);
    onChangeDay(moment(dateRedirect));
  };

  return (
    <S.ContentRightContainer>
      <S.Header>
        <S.HeaderLeftContent>
          {viewMode.key === 'day' ? (
            <ChangeTimeButton title={dayTitle} disabled={disabledNextDay} onClick={handleChangeDay} />
          ) : (
            <ChangeTimeButton title={weekTitle} disabled={false} onClick={handleChangeWeek} />
          )}
          <ChangeModeButton viewMode={viewMode} onChangeViewMode={changeClientMealPlanViewMode} />
        </S.HeaderLeftContent>
        <S.HeaderRightContent>
          {viewMode.key === 'week' && (
            <RearrangeButton isRearrangeMode={isRearrangeMode} disabled={false} onClick={toggleRearrangeMode} />
          )}
          {/* TODO: show with week view */}
          {/* <S.GenerateAI>
          <div className="wrapper-button">
          <button>
          <GenerateAiIcon className="generate-ai-icon" />
          Generate Meals
          </button>
          </div>
          <UpcomingTabIcon className="upcoming-tab-icon" />
          </S.GenerateAI> */}
        </S.HeaderRightContent>
      </S.Header>
      {!isRearrangeMode && (
        <>
          <MarcoGoal
            macroNutrients={viewMode.key === 'day' ? macro_nutrients : weekMacroNutrientsAverages}
            getReportMacrosDaily={getReportMacrosDaily}
            getReportMacrosWeekly={getReportMacrosWeekly}
            selectedClient={selectedClient}
            selectedDay={selectedDay}
            push={push}
            viewMode={viewMode}
          />
          <ClientMealPlanNote
            toggleModal={toggleModal}
            toggleConfirmModal={toggleConfirmModal}
            selectedClient={selectedClient}
            selectedDay={selectedDay}
            note={viewMode.key === 'day' ? note : weekNote}
            updateClientMealPlanNote={updateClientMealPlanNote}
            updateClientMealPlanWeekNote={updateClientMealPlanWeekNote}
            loading={loading || isLoadingWeek}
            assignedMealPlanClientsByRange={assignedMealPlanClientsByRange}
            mealPlanByDay={mealPlanByDay}
            viewMode={viewMode}
            clientTimezone={clientTimezone}
          />
        </>
      )}
      {viewMode.key === 'day' ? (
        <MealCategory
          toggleModal={toggleModal}
          selectedDay={selectedDay}
          mealPlanByDay={mealPlanByDay}
          toggleConfirmModal={toggleConfirmModal}
          workingClientDetail={workingClientDetail}
          getClientMealPlanByMonth={getClientMealPlanByMonth}
          removeRecipeTrainer={removeRecipeTrainer}
          loading={loading}
          cloudfrontList={cloudfrontList}
          listDietary={listDietary}
          assignedMealPlanClientsByRange={assignedMealPlanClientsByRange}
          clientTimezone={clientTimezone}
        />
      ) : (
        <WeeklyCategory
          selectedDay={selectedDay}
          viewMode={viewMode}
          isRearrangeMode={isRearrangeMode}
          isLoadingWeek={isLoadingWeek}
          weekMealClients={weekMealClients}
          clientTimezone={clientTimezone}
          onAddMeals={item => {
            handleRedirectToDate(item);
            changeClientMealPlanViewMode(CLIENT_MEAL_PLAN_VIEW_MODE[0]);
          }}
          cloudfrontList={cloudfrontList}
          reArrangeMealClientRecipe={reArrangeMealClientRecipe}
          workingClientDetail={workingClientDetail}
        />
      )}
    </S.ContentRightContainer>
  );
};

const mapStateToProps = state => {
  const {
    rootReducer: { clientMealPlan, client, recipes, permission, pricing },
    cloudfrontList,
  } = state;
  const mealPlanScheduling = permission.meal_plan_scheduling;
  const {
    macro_nutrients,
    mealPlanByDay,
    loading,
    assignedMealPlanClientsByRange,
    viewMode,
    isRearrangeMode,
    isLoadingWeek,
    weekMealClients,
    weekNote,
  } = clientMealPlan || {};
  const { workingClientDetail } = client || {};
  const listDietary = get(recipes, 'listDietary', []);

  const addOnsPricing = pricing.get('addOnsPricing').toJS() || [];
  const mealPlanAddOn = addOnsPricing.find(it => it.code === ADD_ONS_PRICING_CODE.MEAL_PLAN);

  const { beta_date } = mealPlanAddOn || {};

  return {
    macro_nutrients,
    mealPlanByDay,
    loading,
    workingClientDetail,
    assignedMealPlanClientsByRange,
    cloudfrontList,
    listDietary,
    mealPlanScheduling,
    beta_date,
    viewMode,
    isRearrangeMode,
    isLoadingWeek,
    weekMealClients,
    weekNote,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReportMacrosDaily: bindActionCreators(getReportMacrosDaily, dispatch),
    getReportMacrosWeekly: bindActionCreators(getReportMacrosWeekly, dispatch),
    toggleModal: bindActionCreators(toggleModal, dispatch),
    toggleConfirmModal: bindActionCreators(toggleConfirmModal, dispatch),
    updateClientMealPlanNote: bindActionCreators(updateClientMealPlanNote, dispatch),
    updateClientMealPlanWeekNote: bindActionCreators(updateClientMealPlanWeekNote, dispatch),
    getClientMealPlanByMonth: bindActionCreators(getClientMealPlanByMonth, dispatch),
    push: bindActionCreators(push, dispatch),
    removeRecipeTrainer: bindActionCreators(removeRecipeTrainer, dispatch),
    changeClientMealPlanViewMode: bindActionCreators(changeClientMealPlanViewMode, dispatch),
    toggleRearrangeMode: bindActionCreators(toggleRearrangeMode, dispatch),
    reArrangeMealClientRecipe: bindActionCreators(reArrangeMealClientRecipe, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
