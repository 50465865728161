import styled from 'styled-components';

export const Wrapper = styled.div`
  .meal-plan-title {
    display: flex;
    align-items: center;
  }
  .trial-add-on__button {
    margin-left: 15px;
  }
`;
