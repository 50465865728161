import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Component from './component';
import { toggleSideBar } from 'actions/sidebar';
import { resetCurrentList, resetFilters, resetDetailMealPlan } from 'redux/meal-plans/actions';
import { toggleModal } from 'actions/modal';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  toggleSideBar: bindActionCreators(toggleSideBar, dispatch),
  resetFilters: bindActionCreators(resetFilters, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  resetCurrentList: bindActionCreators(resetCurrentList, dispatch),
  resetDetailMealPlan: bindActionCreators(resetDetailMealPlan, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
