import styled, { css } from 'styled-components';

export const ClientProgressWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #DADFEA;
  box-shadow: 0px 2px 4px rgba(73, 70, 153, 0.1);
  border-radius: 5px;
  margin-top: 20px;

  .clientProgress__selectPlan {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;

    &--text {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;

      color: #202353;
      margin-right: 5px;
    }
  }

  .clientProgress__description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    color: #202353;
    margin-bottom: 35px;
  }

  .clientProgress_warningMsg {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    color: #202353;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out, padding-top 0.5s ease-in-out;

    &--active {
      border-top: 1px solid #DADFEA;
      padding-top: 20px;
      margin-top: 20px;
      max-height: 400px;
    }

    a {
      font-weight: bold;
      color: #6261FF;
    }
  }
`;

export const ProgressBarWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${props => `repeat(${props.dotsLength}, 1fr)`};
  grid-gap: 1px;
  height: 20px;
  align-items: center;
  margin-bottom: 10px;
`;

export const ProgressSlider = styled.input`
  width: 102%;
  position: absolute;
  background: transparent;
  -webkit-appearance: none;
  outline: none;
  top: -5px;
  left: -1%;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
    background: #FFFFFF;
    border: 6px solid #6261FF;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: transparent;
    cursor: pointer;
  }
`;

export const ProgressDot = styled.div`
  background: ${props => props.isOver ? '#F1F3F6' : '#6261FF'} ;
  width: 100%;
  height: 8px;

  ${props => props.isFirst && css`
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  `}

  ${props => props.isLast && css`
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  `}
`;

export const ProgressQuantity = styled.div`
  width: 100%;
  height: 8px;
  padding-top: 10px;
  display: flex;
  
  :last-child {
    justify-content: space-between;
  }

  .dotValue {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 100%;
    color: #202353;
    margin-left: -8px;

    &--lastItem {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;
      color: #202353;
      /* float: right; */
      margin-left: -5px;
      margin-right: -10px;
    }
  }
`;