import React, { useEffect, useRef, useState } from 'react';
import { capitalize, debounce, replace } from 'lodash';
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';

import { ASSET_TYPES, ASSET_TYPE } from 'components/Product/constants';
import DropdownOptions from 'shared/DropdownOptions';
import { ReactComponent as DropdownIndicator } from 'assets/icons/search_black_thin.svg';

import AutoflowOption from './CustomOptions/AutoflowOption';
import * as S from './style';
import ResourceCollectionOption from './CustomOptions/ResourceCollectionOption';
import AddNewAsset from '../Asset/AddNewAsset';
import StudioProgramOption from './CustomOptions/StudioProgramOption';
import ForumOption from './CustomOptions/ForumOption';
import ProgramOption from './CustomOptions/ProgramOption';
import UpgradePath from 'shared/UpgradePath';
import WorkoutCollectionOption from './CustomOptions/WorkoutCollectionOption';

const options = ASSET_TYPES.map(asset => ({
  value: asset.value,
  label: asset.label,
  pathName: asset.pathName,
}));

const renderOptions = (addedAssets, collectionPerClient) => {
  return options.filter(option => shouldAllowAddAssetType(option.value, addedAssets, collectionPerClient));
};

export const shouldAllowAddAssetType = (assetType, addedAssets, collectionPerClient) => {
  switch (assetType) {
    case ASSET_TYPE.AUTOFLOW:
      const foundAutoflow = addedAssets.filter(asset => asset.asset_type === ASSET_TYPE.AUTOFLOW);
      if (foundAutoflow.length > 0) return false;
      return true;

    case ASSET_TYPE.RESOURCE:
      const foundResources = addedAssets.filter(asset => asset.asset_type === ASSET_TYPE.RESOURCE);
      if (foundResources.length === collectionPerClient) return false;
      return true;

    default:
      return true;
  }
};

function AssetSelect({
  onChange,
  onChangeAssetType,
  onToggleAdding,
  onAddAsset,
  searchAsset,
  isFirstTime,
  addedAssets,
  user,
  cloudfrontList,
  collections_per_client,
}) {
  const [assetType, setAssetType] = useState(null);

  const assetSelectRef = useRef(null);

  useEffect(() => {
    if (addedAssets.length > 4 && assetSelectRef.current) {
      assetSelectRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  const handleChangeAssetType = type => {
    setAssetType(type.value);
  };

  const handleCancel = () => {
    setAssetType(null);
  };

  const handleSelectAsset = item => {
    onAddAsset({ asset_id: item._id, asset_data: item, asset_type: assetType });
    handleCancel();
  };

  const handleSearchAsset = (inputValue, callback) => {
    searchAsset(assetType, inputValue, callback);
  };

  const handleFilterOption = option => {
    for (let i = 0; i < addedAssets.length; i++) {
      if (addedAssets[i].asset_data._id === option.data._id) {
        return false;
      }
    }
    return true;
  };

  const handleFilterSelectedOption = option => {
    for (let i = 0; i < addedAssets.length; i++) {
      if (addedAssets[i].asset_data._id === option._id) {
        return false;
      }
    }
    return true;
  };

  const searchAssetDebounce = debounce(handleSearchAsset, 200);

  const CustomAssetOption = props => {
    const { data, innerRef, innerProps } = props;
    return (
      <S.CustomAssetOption ref={innerRef} {...innerProps}>
        {
          {
            [ASSET_TYPE.AUTOFLOW]: <AutoflowOption data={data} user={user} cloudfrontList={cloudfrontList} />,
            [ASSET_TYPE.RESOURCE]: <ResourceCollectionOption data={data} />,
            [ASSET_TYPE.STUDIO]: <StudioProgramOption data={data} cloudfrontList={cloudfrontList} />,
            [ASSET_TYPE.FORUM]: <ForumOption data={data} cloudfrontList={cloudfrontList} />,
            [ASSET_TYPE.PROGRAM]: <ProgramOption data={data} user={user} cloudfrontList={cloudfrontList} />,
            [ASSET_TYPE.WORKOUT_COLLECTION]: <WorkoutCollectionOption data={data} />,
          }[assetType]
        }
      </S.CustomAssetOption>
    );
  };

  const CustomAssetTypeOption = props => {
    const { data } = props;
    return (
      <UpgradePath pathName={data.pathName} fallback={<></>}>
        <components.Option {...props} />
      </UpgradePath>
    );
  };

  const CustomMenuList = props => {
    const { options } = props;
    const filteredOptions = options.filter(option => handleFilterSelectedOption(option));
    const MenuTitle = {
      [ASSET_TYPE.AUTOFLOW]: 'ACTIVE AUTOFLOWS',
      [ASSET_TYPE.RESOURCE]: 'RESOURCE COLLECTIONS',
      [ASSET_TYPE.STUDIO]: 'PUBLISHED STUDIO PROGRAMS',
      [ASSET_TYPE.PROGRAM]: 'PROGRAMS',
      [ASSET_TYPE.FORUM]: 'FORUMS',
      [ASSET_TYPE.WORKOUT_COLLECTION]: 'WORKOUT COLLECTIONS',
    };
    return (
      <S.CustomMenuListWrapper>
        <S.CustomMenuList>
          <div className="menu-title">
            {MenuTitle[assetType]} ({filteredOptions.length})
          </div>
          {props.children}
        </S.CustomMenuList>
        <div className="spacer"></div>
      </S.CustomMenuListWrapper>
    );
  };

  const handleScrollDown = () => {
    setTimeout(() => {
      let div = document.getElementById('productDetail');
      if (div && div.scrollHeight > window.innerHeight) {
        div.scrollTop = div.scrollHeight - div.clientHeight;
      }
    }, 0);
  };

  const handleScrollUp = () => {
    setTimeout(() => {
      let div = document.getElementById('productDetail');
      if (div && div.scrollHeight < window.innerHeight) {
        div.scrollTop = 0;
      }
    }, 0);
  };

  if (!assetType) {
    return (
      <S.FirstAddAsset isFirstTime={isFirstTime} ref={assetSelectRef}>
        <AddNewAsset
          addedAssets={addedAssets}
          onToggleAdding={onToggleAdding}
          onSelect={setAssetType}
          isFirstTime={isFirstTime}
          onCancel={onToggleAdding}
          limitResource={collections_per_client}
        />
      </S.FirstAddAsset>
    );
  } else {
    const assetTypePlaceholder = `Search for ${capitalize(
      replace(assetType === ASSET_TYPE.WORKOUT_COLLECTION ? 'Workout collection' : assetType, '_', ' '),
    )}`;
    return (
      <S.Wrapper>
        <DropdownOptions
          className="product-asset-dropdown"
          width="169px"
          height="42px"
          options={renderOptions(addedAssets, collections_per_client)}
          selectedValue={assetType}
          onSelect={handleChangeAssetType}
          customOption={CustomAssetTypeOption}
          onScrollBottom={handleScrollDown}
        />
        <AsyncSelect
          ref={assetSelectRef}
          defaultOptions
          cacheOptions
          filterOption={handleFilterOption}
          key={assetType}
          loadOptions={(inputValue, callback) => searchAssetDebounce.call(this, inputValue, callback)}
          className="search-input"
          placeholder={assetTypePlaceholder}
          components={{
            DropdownIndicator,
            IndicatorSeparator: null,
            LoadingIndicator: null,
            Option: CustomAssetOption,
            MenuList: CustomMenuList,
          }}
          classNamePrefix="single-select"
          onChange={handleSelectAsset}
          onMenuOpen={handleScrollDown}
          onMenuClose={handleScrollUp}
          openMenuOnFocus
          autoFocus
        />
        <div className="break" />
        <S.CancelButton onClick={handleCancel}>Cancel</S.CancelButton>
      </S.Wrapper>
    );
  }
}

export default AssetSelect;
