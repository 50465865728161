import { toast } from 'react-toastify';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

import Request from 'configs/request';
import { CLASSNAME_TOAST as CLASSNAMES_TOAST } from 'constants/commonData';
import { pluralize, mongoObjectId } from 'utils/commonFunction';

export const Types = {
  AUTOFLOW_TRAINING_SELECT_MULTIPLE_WORKOUT: 'AUTOFLOW_TRAINING_SELECT_MULTIPLE_WORKOUT',
  AUTOFLOW_TRAINING_START_COPY_MULTIPLE_WORKOUT: 'AUTOFLOW_TRAINING_START_COPY_MULTIPLE_WORKOUT',
  AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST: 'AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST',
  AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_SUCCESS: 'AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_SUCCESS',
  AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_FAILED: 'AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_FAILED',
  AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST: 'AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST',
  AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS: 'AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS',
  AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED: 'AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED',
  AUTOFLOW_TRAINING_RESET_SELECTED_WORKOUTS: 'AUTOFLOW_TRAINING_RESET_SELECTED_WORKOUTS',
  AUTOFLOW_TRAINING_SELECT_WEEK_TO_COPY: 'AUTOFLOW_TRAINING_SELECT_WEEK_TO_COPY',
  AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_REQUEST: 'AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_REQUEST',
  AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_SUCCESS: 'AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_SUCCESS',
  AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_FAILED: 'AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_FAILED',
  AUTOFLOW_TRAINING_REMOVE_WEEK_REQUEST: 'AUTOFLOW_TRAINING_REMOVE_WEEK_REQUEST',
  AUTOFLOW_TRAINING_REMOVE_WEEK_SUCCESS: 'AUTOFLOW_TRAINING_REMOVE_WEEK_SUCCESS',
  AUTOFLOW_TRAINING_REMOVE_WEEK_FAILED: 'AUTOFLOW_TRAINING_REMOVE_WEEK_FAILED',
  AUTOFLOW_TRAINING_RESET_SELECTED_WEEK: 'AUTOFLOW_TRAINING_RESET_SELECTED_WEEK',
  AUTOFLOW_TRAINING_SOCKET_WORKOUT_ADDED: 'AUTOFLOW_TRAINING_SOCKET_WORKOUT_ADDED',
};

// Select multiple workout to copy or delete
export const handleSelectMultipleWorkout = ({ workoutIds }) => {
  return {
    type: Types.AUTOFLOW_TRAINING_SELECT_MULTIPLE_WORKOUT,
    payload: { workoutIds },
  };
};

// Start action copy multiple workout
export const handleStartCopyMultipleWorkout = () => {
  const TEMP_ID = mongoObjectId();
  return {
    type: Types.AUTOFLOW_TRAINING_START_COPY_MULTIPLE_WORKOUT,
    payload: { workoutCopyId: TEMP_ID },
  };
};

// Paste multiple workout
export const handlePasteMultipleWorkout = ({ toDate, multiPaste = false }) => {
  return (dispatch, getState) => {
    const state = getState();
    const autoflowId = get(state, 'rootReducer.autoflow.common.workingAutoflow._id');
    const selectedWorkout = get(state, 'rootReducer.autoflow.training.selectedWorkouts');
    const workoutCopyId = get(state, 'rootReducer.autoflow.training.workoutCopyId');
    if (isEmpty(autoflowId) || isEmpty(selectedWorkout) || isEmpty(toDate)) return;
    dispatch({
      type: Types.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_REQUEST,
      payload: { multiPaste },
    });
    return dispatch(
      Request.post(
        {
          url: `/api/autoflow-exact-date-training/bulk-copy`,
          data: {
            autoflow_id: autoflowId,
            workout_ids: selectedWorkout,
            paste_date: toDate,
            copy_id: workoutCopyId,
          },
        },
        true,
        (response, { dispatch }) => {
          const success = get(response, 'data.success', false);
          if (success) {
            dispatch({ type: Types.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_SUCCESS });
          }
        },
        error => {
          dispatch({ type: Types.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_FAILED, error });
        },
      ),
    );
  };
};

// Delete all selected multiple workout
export const handleDeleteMultipleWorkout = () => {
  return (dispatch, getState) => {
    const state = getState();
    const autoflowId = get(state, 'rootReducer.autoflow.common.workingAutoflow._id');
    const selectedWorkout = get(state, 'rootReducer.autoflow.training.selectedWorkouts');
    if (isEmpty(autoflowId) || isEmpty(selectedWorkout)) return;
    if (isEmpty(selectedWorkout)) {
      dispatch(handleResetSelectedMultipleWorkout());
      toast(`0 Workouts have been removed.`, { className: CLASSNAMES_TOAST.TRAINING_CALENDAR });
      return;
    }
    dispatch({
      type: Types.AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: `/api/autoflow-exact-date-training/bulk-remove`,
          data: {
            autoflow_id: autoflowId,
            workout_ids: selectedWorkout,
          },
        },
        true,
        (response, { dispatch }) => {
          const removedWorkoutIds = get(response, 'data.data.workout_ids', []);
          dispatch({
            type: Types.AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_SUCCESS,
            payload: removedWorkoutIds,
          });
          const unit = pluralize('Workout', removedWorkoutIds.length);
          const verbs = removedWorkoutIds.length !== 1 ? 'have' : 'has';
          toast(`${removedWorkoutIds.length} ${unit} ${verbs} been removed.`, {
            className: CLASSNAMES_TOAST.TRAINING_CALENDAR,
          });
        },
        error => {
          dispatch({ type: Types.AUTOFLOW_TRAINING_REMOVE_MULTIPLE_WORKOUT_FAILED, error });
          dispatch(handleResetSelectedMultipleWorkout());
        },
      ),
    );
  };
};

// Reset selected multiple workout
export const handleResetSelectedMultipleWorkout = () => {
  return { type: Types.AUTOFLOW_TRAINING_RESET_SELECTED_WORKOUTS };
};

// Action of choosing a week to copy
export const handleSelectWeekToCopy = ({ from, to }) => {
  const TEMP_ID = mongoObjectId();
  return {
    type: Types.AUTOFLOW_TRAINING_SELECT_WEEK_TO_COPY,
    payload: {
      selectedWeek: { from, to },
      weekCopyId: TEMP_ID,
    },
  };
};

// Paste a week
export const handlePasteMultipleWorkoutToWeek = ({ startDatePaste, multiPaste }) => {
  return (dispatch, getState) => {
    const state = getState();
    const autoflowId = get(state, 'rootReducer.autoflow.common.workingAutoflow._id');
    const fromDate = get(state, 'rootReducer.autoflow.training.selectedWeek.from');
    const weekCopyId = get(state, 'rootReducer.autoflow.training.weekCopyId');
    if (isEmpty(autoflowId) || isEmpty(fromDate) || isEmpty(startDatePaste) || isEmpty(weekCopyId)) return;
    dispatch({
      type: Types.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_REQUEST,
      payload: { multiPaste },
    });
    return dispatch(
      Request.post(
        {
          url: '/api/autoflow-exact-date-training/copy-week',
          data: {
            autoflow_id: autoflowId,
            copy_date: fromDate.toFormat('MM-dd-yyyy'),
            paste_date: startDatePaste,
            copy_id: weekCopyId,
          },
        },
        false,
        response => {
          const workoutIds = get(response, 'data.data.workout_ids', []);
          if (!isEmpty(workoutIds)) {
            dispatch({ type: Types.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_SUCCESS });
          }
        },
        error => dispatch({ type: Types.AUTOFLOW_TRAINING_PASTE_MULTIPLE_WORKOUT_TO_WEEK_FAILED, error }),
      ),
    );
  };
};

// Delete all workout of week
export const handleRemoveWeek = ({ startDateRemove, deleteFromDateFormat, deleteToDateContentFormat }) => {
  return (dispatch, getState) => {
    const state = getState();
    const autoflowId = get(state, 'rootReducer.autoflow.common.workingAutoflow._id');
    if (isEmpty(autoflowId) || isNil(startDateRemove)) return;
    dispatch({
      type: Types.AUTOFLOW_TRAINING_REMOVE_WEEK_REQUEST,
    });
    return dispatch(
      Request.post(
        {
          url: '/api/autoflow-exact-date-training/remove-week',
          data: {
            autoflow_id: autoflowId,
            from_date: startDateRemove,
          },
        },
        false,
        response => {
          const removedIds = get(response, 'data.data.workout_ids', []);
          if (!isEmpty(removedIds)) {
            dispatch({ type: Types.AUTOFLOW_TRAINING_REMOVE_WEEK_SUCCESS, payload: removedIds });
            const workoutDeleted = removedIds.length;
            const unit = pluralize('Workout', workoutDeleted);
            const verbs = workoutDeleted !== 1 ? 'have' : 'has';
            toast(`All ${unit} from ${deleteFromDateFormat} to ${deleteToDateContentFormat} ${verbs} been removed.`, {
              className: CLASSNAMES_TOAST.TRAINING_CALENDAR,
            });
          }
        },
        error => dispatch({ type: Types.AUTOFLOW_TRAINING_REMOVE_WEEK_FAILED, error }),
      ),
    );
  };
};

// Reset selected week
export const handleResetSelectedWeek = () => {
  return { type: Types.AUTOFLOW_TRAINING_RESET_SELECTED_WEEK };
};

// Socket update workout after copy week/workout
export const socketAutoflowTrainingWorkoutAdded = data => {
  return (dispatch, getState) => {
    const state = getState();
    const autoflowId = get(state, 'rootReducer.autoflow.common.workingAutoflow._id');
    const day = get(data, 'day');
    const workout = get(data, 'workout');
    if (isEqual(autoflowId, get(data, 'autoflow_id'))) {
      return dispatch({
        type: Types.AUTOFLOW_TRAINING_SOCKET_WORKOUT_ADDED,
        payload: {
          autoflowId,
          day,
          workout,
        },
      });
    }
    return;
  };
};
