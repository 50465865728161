import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button } from 'shared/FormControl';
import { formatSameYear } from 'utils/commonFunction';

import * as S from './styles';

export default function ConfirmRefundPopup({
  open,
  onClose,
  onCloseRefund,
  invoiceAmount,
  formattedCurrency,
  invoice,
  reason,
  details,
}) {
  const handleConfirm = () => {
    onClose && onClose();
    onCloseRefund && onCloseRefund();
  };
  const handleClose = () => {
    onCloseRefund && onCloseRefund();
  };

  return (
    <S.RefundConfirmPopup
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={false}
      className="second-modal select-form"
    >
      <Modal.Header>Confirm refund</Modal.Header>
      <Modal.Content>
        <S.ContentWrapper>
          <p>
            <strong>Package:</strong> {get(invoice, 'package.name', '')}
          </p>
          <p>
            <strong>Invoice:</strong> #{get(invoice, 'invoice_number', '-')} on {formatSameYear(invoice.payment_due)}
          </p>
          <p>
            <strong>Refund:</strong> {formattedCurrency}
            {invoiceAmount}
          </p>
          <p>
            <strong>Reason:</strong> {get(reason, 'value') ? capitalize(get(reason, 'label', 'other')) : 'Other'}
          </p>
          <p>
            <strong>Details:</strong> {details || 'N/A'}
          </p>
        </S.ContentWrapper>
        <S.InfoText>
          Once the refund is processed, it will take 5-10 days to show up for the client. Only one refund can be made
          per invoice.
        </S.InfoText>
        <S.InfoText>
          Would you like to process a refund of{' '}
          <strong>
            {formattedCurrency}
            {invoiceAmount}
          </strong>
          ?
        </S.InfoText>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} className="cancel-btn">
          Cancel
        </Button>
        <Button onClick={handleConfirm} purple className="process-refund-btn">
          Process Refund
        </Button>
      </Modal.Actions>
    </S.RefundConfirmPopup>
  );
}
