import styled from 'styled-components';

export const ConversationItem = styled.div`
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  line-height: normal;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.me {
    margin-left: 16px;
    width: calc(100% - 16px);

    .message {
      background-color: #e9ecf5;
      color: #000;
      border-radius: 8px;
      padding: 12px;
      gap: 4px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding: 12px;
      margin-left: 0;
      position: relative;

      .user-input {
        padding-right: 26px;
        line-height: 20px;
        word-break: break-word;
      }

      .user-input,
      .timestamp {
        position: relative;
        top: -1px;
      }

      .copy-text-btn {
        position: absolute;
        top: 13px;
        right: 16px;
        cursor: pointer;
        z-index: 1;
        :hover {
          svg path {
            stroke: #5158cf;
          }
        }
      }
      .__react_component_tooltip.place-top {
        margin-top: -8px;
      }
    }
  }

  .timestamp {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    color: #636d7a;
  }

  .ai-header {
    display: flex;
    align-items: center;
    height: 36px;
    position: relative;
    left: -20px;

    .ai-beta {
      position: relative;
      top: -4px;
      margin-left: 9px;
    }

    .timestamp {
      position: absolute;
      top: 6px;
      right: -20px;
    }

    h6 {
      margin-left: -13px;
      margin-top: 0;
      margin-bottom: 8px;
      color: #000;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .message {
    border-radius: 4px;
    margin-left: 38px;
    background-color: #ffffff;
    font-size: 13px;
    line-height: 17.7px;
    font-weight: 400;
    white-space: pre-wrap;

    &.ai-msg {
      position: relative;
      top: -4px;
      margin-left: 35px;
    }

    span.standard-builder-text,
    a.prompt-link {
      color: #5158cf;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 35px;
    margin-top: 12px;

    .reactions {
      display: flex;
      gap: 4px;

      button {
        color: #000;
        width: 36px;
        height: 32px;
        min-width: unset;

        &:hover {
          border-color: #5158cf;
          background-color: #fff;
          svg path {
            stroke: #5158cf;
          }
        }

        &.active.like-btn svg path {
          fill: #5158cf;
          stroke: #5158cf;
        }

        svg {
          flex-shrink: 0;
        }

        &.start-btn {
          width: auto;
          color: #fff;
        }
      }
    }

    .feedback-btn {
      color: #000;
      width: 102px !important;
      font-size: 13px;
      font-weight: 400;
      border-radius: 4px;
      height: 32px;
      padding: 0 8px;

      &:hover,
      &.active {
        border: 1px solid #5158cf;
      }

      svg {
        margin-right: 8px;
      }
    }

    @keyframes flashAnimation {
      0% {
        background-position: 0% 20%;
      }
      50% {
        background-position: 100% 81%;
      }
      100% {
        background-position: 0% 20%;
      }
    }

    .start-btn {
      border: none;
      color: #fff;
      background: linear-gradient(332deg, #ff1fa2, #8349ff, #00f0ff, #0011e0);
      background-size: 800% 800%;
      animation: flashAnimation 8s ease infinite;
      height: 32px;
      width: 93px;
    }
  }

  .feedback-wrap {
    display: flex;
    height: 180px;
    padding: 12px 8px 8px 15px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #efefef;
    background: #fff;
    margin-top: 9px;

    .feedback-input {
      color: #000;
      font-size: 13px;
      font-weight: 400;
      flex: 1;
      width: 100%;
      max-height: 100%;
    }

    .send-btn {
      border-radius: 5px;
      background: #5158cf;
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      min-width: 62px;
      height: 32px;

      &:disabled {
        opacity: 0.7;
      }
    }
  }
`;
