import { DateTime } from 'luxon';
import moment from 'moment';

DateTime.prototype.convertToMoment = function () {
  if (this) {
    let dateString = this.toString();
    return moment(dateString);
  }

  return null;
};

moment.prototype.convertToLuxon = function () {
  if (this) {
    let dateString = this.format();
    return DateTime.fromISO(dateString);
  }

  return null;
};

String.prototype.trimAnyLeft = function (charlist = 's') {
  return this.replace(new RegExp('^[' + charlist + ']+'), '');
};

String.prototype.trimAnyRight = function (charlist = 's') {
  return this.replace(new RegExp('[' + charlist + ']+$'), '');
};

String.prototype.trimAny = function (charlist = 's') {
  return this.trimAnyLeft(charlist).trimAnyRight(charlist);
};

Number.prototype.toUSDFormat = function (isCent = true) {
  const convertedValue = isCent ? this / 100 : this;
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(convertedValue);
};

Number.prototype.toSeperateFormat = function () {
  return new Intl.NumberFormat('en-US').format(this);
};

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

Number.prototype.toCurrencyFormat = function (option = {}) {
  if (!option.code || !option.currency) {
    return this;
  }

  if (option.code === 'en-AE') {
    let parts = new Intl.NumberFormat(option.code, { style: 'currency', currency: option.currency }).formatToParts(
      this,
    );
    parts = parts.map(part => (part.value === 'AED' ? 'Dh' : part.value));
    return parts.join('');
  }

  if (option.code === 'se-SE') {
    let formattedNumber = new Intl.NumberFormat(option.code, { style: 'currency', currency: option.currency }).format(
      this,
    );
    if (formattedNumber.includes('kr')) {
      return `SEK ${formattedNumber.replace('kr', '').trimEnd()}`;
    }
    return formattedNumber;
  }

  return new Intl.NumberFormat(option.code, { style: 'currency', currency: option.currency }).format(this);
};
