// Libs
import React, { useRef, useState } from 'react';

// Constants
import { ENUM_TIME } from '../constants';
import { setupWheelEventListener } from 'utils/commonFunction';

// helper
import { onlyAllowNumberTime } from '../helper';

// Style
import * as S from './style';

const TimeInput = props => {
  const {
    label = '',
    defaultHour,
    defaultMinute,
    onChange = () => {},
    valueHour,
    valueMinute,
    min = 0,
    max = 99,
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const refHour = useRef(null);
  const refMinute = useRef(null);

  const onClickHour = () => {
    refHour && refHour.current && refHour.current.focus();
  };

  const onClickMinute = () => {
    refMinute && refMinute.current && refMinute.current.focus();
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const onFocus = event => {
    setIsFocus(true);

    // Fix: Safari scroll increases/decreases value
    const inputField = event.target;
    setupWheelEventListener(inputField);
    inputField.select();
  };

  const onKeyDown = e => {
    // Fix: Safari input type number allows letters
    const keyCode = e.keyCode || e.which;

    const isAllowedKey =
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      keyCode === 8 ||
      keyCode === 46 ||
      keyCode === 37 ||
      keyCode === 39 ||
      keyCode === 9;

    if (!isAllowedKey) {
      e.preventDefault();
    }
  };

  return (
    <S.TimeInputWrapper isFocus={isFocus} onFocus={onFocus} onBlur={onBlur}>
      {label && <label>{label}</label>}
      <S.TimeInput className="time-input">
        <S.TimeInputItem onClick={onClickHour}>
          <input
            ref={refHour}
            defaultValue={defaultHour}
            value={valueHour}
            type="number"
            min={min}
            max={max}
            onChange={e => {
              onChange(e, ENUM_TIME.HOUR);
            }}
            onClick={e => {
              e.stopPropagation();
            }}
            onKeyPress={onlyAllowNumberTime}
            onKeyDown={onKeyDown}
          />
          <span>h</span>
        </S.TimeInputItem>
        <S.TimeInputItem onClick={onClickMinute}>
          <input
            ref={refMinute}
            defaultValue={defaultMinute}
            value={valueMinute}
            type="number"
            min={min}
            max={max}
            onChange={e => {
              onChange(e, ENUM_TIME.MINUTE);
            }}
            onClick={e => {
              e.stopPropagation();
            }}
            onKeyPress={onlyAllowNumberTime}
            onKeyDown={onKeyDown}
          />
          <span>min</span>
        </S.TimeInputItem>
      </S.TimeInput>
    </S.TimeInputWrapper>
  );
};

export default TimeInput;
