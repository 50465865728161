import React from 'react';
import Select from 'react-select';
import { SHARE_OPTIONS } from 'constants/commonData';
import { DropdownIndicator } from 'shared/Icons';

const selectBoxStyles = {
  control: base => ({ ...base, minHeight: 36, height: 36 }),
  menu: base => ({ ...base, margin: 0 }),
};

export default ({ onChange, value, disabled, fixedPosition }) => {
  return (
    <Select
      isDisabled={disabled}
      options={SHARE_OPTIONS}
      getOptionLabel={option => option.text || '-'}
      getOptionValue={option => option.value}
      components={{ DropdownIndicator, IndicatorSeparator: null }}
      placeholder="-"
      onChange={onChange}
      classNamePrefix="evfSelectBox"
      className="evfSelectBoxContainer"
      value={value}
      isSearchable={false}
      styles={selectBoxStyles}
      menuPosition={fixedPosition ? 'fixed' : 'absolute'}
    />
  );
};
